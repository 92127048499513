import { Service } from '@roc/client-portal-shared/services';

const url = {
  GET_TASK_BY_ID: '/api/v1/tasks/getBorrowerTaskByTaskId',
  SYNC_RESOLUTION: '/api/v1/document/formDocument/syncTaskResolution',
};

export class BorrowerTaskService extends Service {
  getTaskById(taskId: number) {
    return this.get(`${url.GET_TASK_BY_ID}?taskId=${taskId}`);
  };

  syncTaskResolution(taskId: number, comment: string) {
    return this.post(`${url.SYNC_RESOLUTION}?taskId=${taskId}`, { comment });
  }
};