import { Service } from '@roc/client-portal-shared/services';

const url = {
  GET_LOAN_INFO_FOR_CDA_PAYMENT:
    '/nportal/rest/pub/payments/getLoanInfoForCDAPaymentLink?loanId=',
  GET_LOAN_INFO_FOR_ARR_PAYMENT:
    '/nportal/rest/pub/payments/getLoanInfoForARRPaymentLink?loanId=',
  FETCH_STANDALONE_INFO:
    '/nportal/rest/pub/payments/getStandaloneInfoForTamariskPayment?propertyAppraisalId=',
  FETCH_STANDALONE_INFO_FOR_RECERTIFICATION:
    '/nportal/rest/pub/payments/getStandaloneInfoForRecertificationTamariskPayment?propertyAppraisalId=',
  FETCH_BORROWER_INFO:
    '/nportal/rest/pub/payments/getCardholderInfo?id=',
  CHECK_LINK:
    '/api/v1/loan/public/checkIfValidLink',
  UPDATE_DROP_DOWN_OPTION:
    '/nportal/rest/pub/payments/updateDropDownOption',
};

export class CdaArrPaymentsService extends Service {

  updateDropDownOption(loanId: string, borrowerName: string, rushed: boolean){
    return this.post(`${url.UPDATE_DROP_DOWN_OPTION}?loanId=${loanId}&borrowerName=${borrowerName}&rushed=${rushed}`, null);
  }
  getLoanInfoForCDAPaymentLink(loanId: string, borrowerName: string) {
    return this.get(`${url.GET_LOAN_INFO_FOR_CDA_PAYMENT}${loanId}&borrowerName=${borrowerName}`);
  }

  getLoanInfoForARRPaymentLink(loanId: string, borrowerName: string) {
    return this.get(`${url.GET_LOAN_INFO_FOR_ARR_PAYMENT}${loanId}&borrowerName=${borrowerName}`);
  }

  getStandaloneInfoForTamariskPayment(propertyAppraisalId: string, borrowerName: string) {
    return this.get(`${url.FETCH_STANDALONE_INFO}${propertyAppraisalId}&borrowerName=${borrowerName}`);
  }

  getStandaloneInfoForRecertificationTamariskPayment(propertyAppraisalId: string, borrowerName: string) {
    return this.get(`${url.FETCH_STANDALONE_INFO_FOR_RECERTIFICATION}${propertyAppraisalId}&borrowerName=${borrowerName}`);
  }

  getCardholderInfo(id: string, type: string) {
    return this.get(`${url.FETCH_BORROWER_INFO}${id}&type=${type}`);
  }

  checkIfValidLink(id: string, tamariskFlowType: string) {
    return this.get(`${url.CHECK_LINK}?id=${id}&tamariskFlowType=${tamariskFlowType}`);
  }
}
