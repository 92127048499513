import React from 'react';
import { Grid } from '@material-ui/core';
import {
  FirstName,
  LastName,
  CellPhone,
  EmailAddress
} from '@roc/ui/formComponents';
import { BorrowerFormStore } from '../stores/borrowerFormStore';

interface BorrowerFormProps {
  store: BorrowerFormStore;
}

export const BorrowerForm = (props: BorrowerFormProps) => {
  const { store } = props;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FirstName store={store} />
      </Grid>
      <Grid item xs={12}>
        <LastName store={store} />
      </Grid>
      <Grid item xs={12}>
        <CellPhone store={store} />
      </Grid>
      <Grid item xs={12}>
        <EmailAddress store={store} />
      </Grid>
    </Grid>
  );
};

export default BorrowerForm;
