import { propertyTypes } from "@roc/feature-utils";
import { EstimatedAsIsValue, FieldContainer, PropertyAddressDetails, PropertyType, PurchaseDate, PurchasePrice, Unit } from "@roc/ui/formComponents";
import { observer } from "mobx-react";
import { CapitalImprovements } from "../../components/formComponents";
import { useRentalPortfolioStore } from "../../hooks/useRentalPortfolioStore";
import { RuralPropertyWarning } from "../../components/ruralPropertyWarning";
import { useEffect } from "react";
import { FormStore } from "@roc/feature-app-core";

  const buildAddress = (fields) => {
    const address = fields.address?.value || '';
    const city = fields.city?.value || '';
    const state = fields.state?.value || '';
    const zipCode = fields.zipCode?.value || '';

    const fullAddress = `${address}, ${city}, ${state} ${zipCode}`.trim();

    return fullAddress;
}

const refreshRuralWarning = async (store: any, formStore: FormStore) => {
  const fullAddress = buildAddress(formStore.form.fields);
  if (fullAddress) {
    await store.checkIfPropertyIsRural(fullAddress);
  }
}

export const PropertyInformation = observer(() => {
  const { propertyDetailsStore, pricerStore } = useRentalPortfolioStore().rentalPortfolioStore;
  const { propertyInformationStore } = propertyDetailsStore;

  useEffect(() => {
    refreshRuralWarning(pricerStore, propertyInformationStore);
  }, [propertyInformationStore.form.fields.zipCode.value]);

  return (
    <>
      <FieldContainer>
        <PropertyType
          store={propertyInformationStore}
          disabled={true}
          propertyTypeOptions={propertyTypes}
        />
      </FieldContainer>
      <FieldContainer>
        <PropertyAddressDetails
          store={propertyInformationStore}
        />
      </FieldContainer>
      {pricerStore.isPropertyMSARural &&
        <FieldContainer>
          <RuralPropertyWarning store={propertyInformationStore} />
        </FieldContainer>
      }
      <FieldContainer>
        <PurchasePrice
          store={propertyInformationStore}
          required
        />
      </FieldContainer>
      <FieldContainer>
        <PurchaseDate
          store={propertyInformationStore}
        />
      </FieldContainer>
      <FieldContainer>
        <CapitalImprovements
          store={propertyInformationStore}
          required
        />
      </FieldContainer>
      <FieldContainer>
        <EstimatedAsIsValue
          store={propertyInformationStore}
          required
        />
      </FieldContainer>
    </>
  )
})

export const PropertyInformationPurchase = observer(() => {
  const { propertyDetailsStore, pricerStore } = useRentalPortfolioStore().rentalPortfolioStore;
  const { propertyInformationStorePurchase } = propertyDetailsStore;

  useEffect(() => {
    refreshRuralWarning(pricerStore, propertyInformationStorePurchase);
  }, [propertyInformationStorePurchase.form.fields.zipCode.value]);

  return (
    <>
      <FieldContainer>
        <PropertyType
          store={propertyInformationStorePurchase}
          disabled={true}
          propertyTypeOptions={propertyTypes}
        />
      </FieldContainer>
      <FieldContainer>
        <PropertyAddressDetails
          store={propertyInformationStorePurchase}
        />
      </FieldContainer>
      {pricerStore.isPropertyMSARural &&
        <FieldContainer>
          <RuralPropertyWarning store={propertyInformationStorePurchase} />
        </FieldContainer>
      }
      <FieldContainer>
        <PurchasePrice
          store={propertyInformationStorePurchase}
          required
        />
      </FieldContainer>
      <FieldContainer>
        <EstimatedAsIsValue
          store={propertyInformationStorePurchase}
          required
        />
      </FieldContainer>
    </>
  )
})