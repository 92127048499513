import { Box, Grid, Link, Typography } from '@material-ui/core';
import { lighten } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { Layout, useAppConfigurations, useUserStore } from '@roc/feature-app-core';
import { Paper } from '@roc/ui';
import { Link as RouterLink } from 'react-router-dom';
import { useRoutes } from '../hooks';
import { useLenderPortalStore } from '../../../../../apps/lender-portal/src/app/hooks/useLenderPortalStore';
import { insertIf } from '../utils';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(4),
  },
  loanTypePaper: {
    padding: theme.spacing(4),
    border: `1px solid ${theme.palette.primary.light}`,
    '&:hover': {
      background: lighten(theme.palette.primary.main, 0.9),
    },
    height: '100%',
  },
  loanTypePaperStbBridge: {
    padding: theme.spacing(4),
    border: `1px solid ${theme.palette.primary.light}`,
    '&:hover': {
      background: lighten(theme.palette.primary.main, 0.9),
    },
    paddingBottom: '2.8em'
  },
  icon: {
    width: 20,
    height: 20,
  },
}));

export const CalculatorSelection = () => {
  const classes = useStyles();
  const { globalStore } = useLenderPortalStore();
  const { userStore } = useUserStore();
  const allowLoanPricing = userStore.allowLoanPricing;
  const {
    dscrCalculatorRoutesConfig,
    liquidityCalculatorRoutesConfig,
    multifamilyLiquidityCalculatorRoutesConfig,
    internalPricerRoutesConfig,
    stabilizedBridgeAnalyzerRoutesConfig,
    bridgeProfitAndDscrCalculatorRoutesConfig,
    bridgePricerRoutesConfig,
    fixFlipSizerRoutesConfig,
    quoteGeneratorRoutesConfig
  } = useRoutes();

  const bridgeTools = [
    // ...insertIf(!globalStore.userFeatures?.isBroker, [
    //   <Grid item xs={12} md={6}>
    //     <RouterLink
    //       to={
    //         stabilizedBridgeAnalyzerRoutesConfig.stabilizedBridgeAnalyzer.url
    //       }
    //     >
    //       <Paper className={classes.loanTypePaper}>
    //         <Typography align="center" variant="h6" gutterBottom>
    //           STABILIZED BRIDGE ANALYZER
    //         </Typography>
    //         <Typography
    //           align="center"
    //           variant="body2"
    //           color="textSecondary"
    //         >
    //           Calculate the Max Loan Amount and DSCR for 1-4 Unit Stabilized Bridge Loans
    //         </Typography>
    //       </Paper>
    //     </RouterLink>
    //   </Grid>
    // ]),
    ...insertIf(allowLoanPricing && globalStore.userFeatures?.bridgePricerToolEnabled, [
      <Grid item xs={12} md={6}>
        <RouterLink
          to={
            bridgePricerRoutesConfig.bridgePricer.url
          }
        >
          <Paper className={classes.loanTypePaper}>
            <Typography align="center" variant="h6" gutterBottom>
              BRIDGE LOAN PRICER
            </Typography>
            <Typography
              align="center"
              variant="body2"
              color="textSecondary"
            > See the floor rate, point split, and yield spread available for your bridge loans
            </Typography>
          </Paper>
        </RouterLink>
      </Grid>
    ]),
    ...insertIf(allowLoanPricing && globalStore.userFeatures?.legacyBridgeCalcEnabled, [
      <Grid item xs={12} md={6}>
        <RouterLink
          to={
            fixFlipSizerRoutesConfig.sizerHome.url
          }
        >
          <Paper className={classes.loanTypePaper}>
            <Typography align="center" variant="h6" gutterBottom>
              FIX FLIP SIZER
            </Typography>
            <Typography
              align="center"
              variant="body2"
              color="textSecondary"
            > Analyze the expected profit and exit DSCR for 1-4 unit fix and flip loans to determine appropriate leverage
            </Typography>
          </Paper>
        </RouterLink>
      </Grid>
    ]),
    ...insertIf(globalStore.userFeatures?.showMultifamilyLiquidityCalculator, [
      <Grid item xs={12} md={6}>
        <RouterLink
          to={
            multifamilyLiquidityCalculatorRoutesConfig.multifamilyLiquidityCalculator.url
          }
        >
          <Paper className={classes.loanTypePaper}>
            <Typography align="center" variant="h6" gutterBottom>
              MULTIFAMILY BRIDGE LIQUIDITY
            </Typography>
            <Typography
              align="center"
              variant="body2"
              color="textSecondary"
            >
              Determine Estimated Closing Costs and Borrower Liquidity Needed for a Loan
            </Typography>
          </Paper>
        </RouterLink>
      </Grid>
    ]),
  ]

  const termTools = [
    // ...insertIf(allowLoanPricing, [
    //   <Grid item xs={12} md={6}>
    //     <RouterLink
    //       to={
    //         internalPricerRoutesConfig.internalPricer.url
    //       }
    //     >
    //       <Paper className={classes.loanTypePaper}>
    //         <Typography align="center" variant="h6" gutterBottom>
    //           RENTAL LOAN PRICER
    //         </Typography>
    //         <Typography
    //           align="center"
    //           variant="body2"
    //           color="textSecondary"
    //         >
    //           Price new scenarios of submitted rental loans
    //         </Typography>
    //       </Paper>
    //     </RouterLink>
    //   </Grid>
    // ]),
    // ...insertIf(!globalStore.userFeatures?.isBroker, [
    //   <Grid item xs={12} md={6}>
    //     <RouterLink
    //       to={dscrCalculatorRoutesConfig.dscrCalculator.url}
    //     >
    //       <Paper className={classes.loanTypePaper}>
    //         <Typography align="center" variant="h6" gutterBottom>
    //           DSCR CALCULATOR
    //         </Typography>
    //         <Typography
    //           align="center"
    //           variant="body2"
    //           color="textSecondary"
    //         >
    //           Debt-Service Coverage
    //           Ratio Calculator
    //         </Typography>
    //       </Paper>
    //     </RouterLink>
    //   </Grid>
    // ]),
  ]

  return (
    <Layout maxWidth={'md'}>
      <Paper className={classes.paper}>
        <Box mb={4}>
          <Typography align="center" variant="h3">
            Calculators & Pricers
          </Typography>
        </Box>
        {bridgeTools.length > 0 &&
          <Box my={4} mt={8}>
            <Typography align="center" variant="h4" color="primary">
              BRIDGE LOAN TOOLS
            </Typography>
          </Box>
        }
        <Box mx={'auto'} mb={4} maxWidth={500}>
          <Grid container direction="row" justifyContent="center" spacing={4}>
            {bridgeTools.map(bridgeTool => (bridgeTool))}
          </Grid>
        </Box>
        {termTools.length > 0 &&
          <Box my={4} mt={8}>
            <Typography align="center" variant="h4" color="primary">
              TERM LOAN TOOLS
            </Typography>
          </Box>
        }
        <Box mx={'auto'} mb={4} maxWidth={500}>
          <Grid container direction="row" justifyContent="center" spacing={4}>
            {termTools.map(bridgeTool => (bridgeTool))}
          </Grid>
        </Box>
      </Paper>
    </Layout>
  );
};
