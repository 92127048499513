import { Box, Grid, Typography, Tooltip, Card, CardHeader, CardContent } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import { FieldLabel, Button, createTextField } from '@roc/ui';
import { observer } from 'mobx-react';
import { FC, SyntheticEvent, useState } from 'react';
import { CreditReportExampleDialog } from 'libs/feature-borrower-review/src/components/utils/creditReportExampleDialog';
import { Theme, makeStyles, useTheme } from '@material-ui/core/styles';
import { isNil } from '@roc/feature-utils';
import { useDropzone } from 'react-dropzone';
import { Close, CloudUpload } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import { BorrowerSetupRepull } from './borrowerSetupRepull';

const useStyles = makeStyles(() => ({
  description: {
    whiteSpace: 'pre'
  },
  buttonsGrid: {
    marginTop: '7px',
    display: 'flex',
    alignItems: 'center',
  },
  inputContainer: {
    marginTop: '24px'
  },
  inputField: {
    marginTop: '6px'
  },
  icon: {
    color: "transparent",
    "& path": {
      stroke: "currentColor",
      strokeWidth: 1.5,
      fill: "transparent"
    }
  },
  alertWrapper: {
    width: '100%',
    marginBottom: '16px'
  }
}));

export const CreditInformation = observer(({ store, showError = false, disabled = false }) => {

  const { creditInformationStore, currentBorrower } = store;
  const classes = useStyles();
  const [openExampleModal, setOpenExampleModal] = useState(false);
  const theme = useTheme();

  const closeExampleModal = () => {
    setOpenExampleModal(false);
  }

  return (
    <Grid container >
      {!store?.personalGuarantor ?
        <Alert severity="info" className={classes.alertWrapper}>
          {'Good news! For Non Guarantors the credit information is not required.'}
        </Alert>
        :
        <>
          {currentBorrower && currentBorrower.sendIdVerification && currentBorrower.areCreditScoresFrozen && currentBorrower.cafSignedDate ? (
            <BorrowerSetupRepull store={store} />
          ) : (
            <>
              <Grid item xs={12}>
                <Box display="flex" alignItems="center">
                  <FieldLabel>
                    File Number
                  </FieldLabel>
                  <Tooltip title='Open Credit Report Example'>
                    <span
                      style={{ cursor: 'pointer', marginLeft: '8px' }}
                      onClick={() => setOpenExampleModal(true)}
                    >
                      <HelpIcon style={{ color: theme.palette.primary.main }} fontSize="small" />
                    </span>
                  </Tooltip>
                </Box>
                {createTextField({
                  store: creditInformationStore,
                  testId: 'creditReportFileId',
                  fieldName: 'creditReportFileId',
                  label: '',
                })}
              </Grid>
              <CreditReportExampleDialog open={openExampleModal} onClose={closeExampleModal} />
              {!disabled &&
                <>
                  <Grid item xs={12}>
                    <Typography
                      component="span"
                      variant='subtitle2'
                      style={{ display: 'inline', padding: '0px', margin: '0px' }}
                      color="textSecondary"
                    >
                      Please upload a Credit Report (Xactus Report recommended), and enter the File #(as suggested in the
                      {<span
                        style={{ cursor: 'pointer', color: theme.palette.primary.main }}
                        onClick={() => setOpenExampleModal(true)}> image</span>}). Uploading a Xactus Report will speed up your file's processing time.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <CreditReportUpload
                      file={creditInformationStore.creditReport}
                      onChange={file => creditInformationStore.setCreditReport(file)}
                      showError={showError && isNil(creditInformationStore.creditReport)}
                    />
                  </Grid>
                </>
              }
            </>
          )}
        </>
      }
    </Grid>
  );
}
);

const useFileUploadStyles = makeStyles((theme: Theme) => ({
  card: {
    marginTop: theme.spacing(2)
  },
  cardContent: {
    paddingTop: 0
  },
  fileLine: {
    display: 'flex',
    alignItems: 'center'
  },
  dragArea: {
    border: '2px dotted #427ED1',
    width: '100%',
    backgroundColor: '#EAF4FA',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: 20,
    borderRadius: 5,
    cursor: 'pointer',
  },
  errorDragArea: {
    border: '2px dotted #D10A0A',
    width: '100%',
    minHeight: '10vh',
    backgroundColor: '#EAF4FA',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: 20,
    marginBottom: 10,
    borderRadius: 5,
    cursor: 'pointer',
    marginTop: 8,
  },
  icon: {
    minWidth: '20px',
    minHeight: '20px',
    width: '3vw',
    height: '3vw',
    color: '#4B89DA',
  },
  fileContainer: {
    border: '1px solid grey',
    backgroundColor: '#EAF4FA',
    padding: '10px 20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '10px 0',
    borderRadius: 5,
  },

  fileName: {
    marginLeft: 5,
    marginRight: 2,
    flexGrow: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  removeButton: {
    marginLeft: theme.spacing(2),
    alignSelf: 'flex-end',
    alignItems: 'flex-end',
    float: 'right',
  },
}));

interface CreditReportUploadProps {
  file: File | null;
  onChange: (file: File | null) => void;
  showError?: boolean;
}

export const CreditReportUpload: FC<CreditReportUploadProps> = ({ file, onChange, showError = false }) => {

  function onDrop(newFiles: File[]) {
    onChange(newFiles?.[0]);
  }

  function handleOnRemove(event: SyntheticEvent) {
    event.stopPropagation();
    onChange(null)
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
  });

  const classes = useFileUploadStyles();
  return (
    <Card variant="outlined" className={classes.card}>
      <CardHeader title={'Credit Report'} />
      <CardContent className={classes.cardContent}>
        <Box {...getRootProps()} className={showError ? classes.errorDragArea : classes.dragArea}>
          <input {...getInputProps()} />
          <CloudUpload className={classes.icon} />
          {file ? (
            <Box className={classes.fileLine}>
              <Typography>{file?.name}</Typography>
              <Button
                testId={`removeFile-${file?.name}`}
                size="small"
                variant="outlined"
                className={classes.removeButton}
                onClick={handleOnRemove}
              >
                REMOVE <Close />
              </Button>
            </Box>
          ) : isDragActive ? (
            <p>Drop the file(s) here...</p>
          ) : (
            <>
              <p>Drag File to Upload</p>
              <p>or Browse file</p>
            </>
          )}
        </Box>
        <Box></Box>
      </CardContent>
    </Card>
  );
};