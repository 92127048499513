import { action, computed, flow, makeObservable, observable } from 'mobx';
import { GlobalStore } from '@roc/feature-app-core';
import { FormStore } from '@roc/feature-app-core';
import { GridStore } from '@roc/feature-app-core';
import { Document } from '@roc/feature-types';
import { formatDate, parseAddress } from '@roc/feature-utils';
import { BorrowerEntityService } from './../../../services/documentForms/borrowerEntityService';
import { DocumentStore } from '../../documentStore';
import { differenceInYears } from 'date-fns';
import EditEntityBorrowerStore from './editEntityBorrowerStore';
import { LoanStore } from '@roc/feature-loans';

const REQUIRED_MESSAGE = 'This field is required';

const form = {
  fields: {
    // Entity Information
    name: {
      value: '',
      error: null,
      rule: '',
    },
    type: {
      value: '',
      error: null,
      rule: 'required',
      message: REQUIRED_MESSAGE,
    },
    ein: {
      value: '',
      error: null,
      rule: 'required|regex:/^\\d{2}-\\d{7}$/',
      message: 'The EIN must have the format xx-xxxxxxx',
    },

    //Entity Address
    address: {
      value: '',
      error: null,
      rule: 'required',
      message: REQUIRED_MESSAGE,
    },
    streetNumber: {
      value: '',
      error: null,
      rule: 'required',
      message: REQUIRED_MESSAGE,
    },
    streetName: {
      value: '',
      error: null,
      rule: 'required',
      message: REQUIRED_MESSAGE,
    },
    addressLine2: {
      value: '',
      error: null,
      rule: '',
    },
    state: {
      value: '',
      error: null,
      rule: 'required',
      message: REQUIRED_MESSAGE,
    },
    city: {
      value: '',
      error: null,
      rule: 'required',
      message: REQUIRED_MESSAGE,
    },
    zip: {
      value: '',
      error: null,
      rule: 'required',
      message: REQUIRED_MESSAGE,
    },

    //Entity Details
    cogsDate: {
      value: null,
      error: null,
      rule: '',
    },

    jurisdictionState: {
      value: '',
      error: null,
      rule: '',
    },
    operatingAgreement: {
      value: false,
      error: null,
      rule: '',
    },
    operatingAgreementDate: {
      value: null,
      error: null,
      rule: '',
    },
    childEntityName: {
      value: '',
      error: null,
      rule: '',
    },
    childEntityAddress: {
      value: '',
      error: null,
      rule: '',
    },
    childEntityRole: {
      value: '',
      error: null,
      rule: '',
    },
  },
  meta: {
    isValid: true,
    error: null,
  },
};

export class BorrowerEntityStore extends FormStore {
  globalStore: GlobalStore;
  loanStore: LoanStore;
  documentStore: DocumentStore;

  editEntityBorrowerStore: EditEntityBorrowerStore;
  entityBorrowersGridStore: GridStore;

  borrowerEntityService: BorrowerEntityService;

  showCertificateOfGoodStandingWarning: boolean = false;
  showForeignEntityStatusWarning: boolean = false;

  // role === 'closingAttorney'

  private borrowerEntity: any;
  private entityBorrowers: Array<any>;
  private document: Document;

  constructor(
    loanStore: LoanStore,
    documentStore: DocumentStore,
    globalStore: GlobalStore
  ) {
    super(form, globalStore);
    this.loanStore = loanStore;
    this.documentStore = documentStore;
    this.globalStore = globalStore;

    this.editEntityBorrowerStore = new EditEntityBorrowerStore(globalStore);
    this.entityBorrowersGridStore = new GridStore(
      this.fetchBorrowerEntityBorrowers
    );

    this.borrowerEntityService = new BorrowerEntityService();
    makeObservable(this, {
      canEditName: computed,
      canEditEin: computed,
      canEditType: computed,
      canEdit: computed,
      showCertificateOfGoodStandingWarning: observable,
      showForeignEntityStatusWarning: observable,
      checkForWarnings: action,
      hideWarnings: action,
      closeDialog: action,
      editBorrower: action,
      updateAddressFromFields: action,
      saveBorrower: flow,
      initForm: flow,
      submit: flow,
      isClosingAttorney: computed,
    });
  }

  fetchBorrowerEntityBorrowers = async () => {
    if (!this.entityBorrowers) {
      const response = await this.borrowerEntityService.getBorrowerEntityBorrowers(
        this.document.loanId
      );
      const responseData = JSON.parse(response.data.data);
      this.entityBorrowers = responseData.data.documentBorrowerEntityMembers;
    }
    const rows = this.entityBorrowers;
    return { headers: null, data: { data: { rows } } };
  };

  private getBorrowerEntityFromLoanDetails() {
    const entity = this.loanStore.loanDetails.borrowerEntity;
    let borrowerEntity = { ...(entity ?? {}) };
    const company = this.loanStore.loanDetails.borrowerEntity?.company;

    borrowerEntity.entityName = company?.name ?? '';
    borrowerEntity.name = company?.name ?? '';

    borrowerEntity.address =
      company.noMatchGoogleAddress || (company?.address ?? '');
    borrowerEntity.streetNumber = company?.streetNumber ?? '';
    borrowerEntity.streetName = company?.streetName ?? '';
    borrowerEntity.addressLine2 = company?.aptNumber ?? '';
    borrowerEntity.state = company?.state ?? '';
    borrowerEntity.city = company?.city ?? '';
    borrowerEntity.zip = company?.zipCode ?? '';

    return borrowerEntity;
  }

  *initForm(document: Document) {
    this.document = document;
    this.reset();
    this.entityBorrowers = null;
    this.borrowerEntity = null;

    const borrowerEntity = this.getBorrowerEntityFromLoanDetails();

    this.borrowerEntity = borrowerEntity;

    this.loadForm({
      ...borrowerEntity,
      cogsDate: borrowerEntity.cogsDate ?? null,
      operatingAgreementDate: borrowerEntity.operatingAgreementDate ?? null,
    });
    yield this.entityBorrowersGridStore.resetAndFetchGridData();
  }

  closeDialog = () => {
    this.documentStore.documentFormStore.setCurrentForm(null);
  };

  *submit() {
    this.runFormValidationWithMessage();
    if (this.form.meta.isValid) {
      try {
        const formValues = this.getFormValues();
        yield this.borrowerEntityService.updateBorrowerEntityForm({
          loanDocument: this.document,
          loanId: this.document.loanId,
          borrowerEntityFormDetails: {
            borrowerEntityId: this.document.borrowerEntityId,
            noMatchGoogleAddress: this.borrowerEntity.noMatchGoogleAddress,
            borrowerEntityAddress: this.borrowerEntity.borrowerEntityAddress,
            ...formValues,
            cogsDate: formatDate(formValues.cogsDate, 'MM/dd/yyyy'),
            operatingAgreementDate: formatDate(
              formValues.operatingAgreementDate,
              'MM/dd/yyyy'
            ),
          },
        });
        yield this.borrowerEntityService.saveEntityBorrowers(
          this.document.loanId,
          {
            documentBorrowerEntityMembers: this.entityBorrowers,
          }
        );
        this.globalStore.notificationStore.showSuccessNotification({
          message: 'Borrower entity form saved successfully',
        });
        yield this.loanStore.fetchLoanDetails(this.document.loanId);
        this.closeDialog();
        this.checkForWarnings();
      } catch (error) {
        this.globalStore.notificationStore.showErrorNotification({
          message: 'Error while submitting borrower entity form',
        });
      }
    }
  }

  editBorrower(data) {
    this.editEntityBorrowerStore.openDialog(data);
  }

  *saveBorrower() {
    const formValues = this.editEntityBorrowerStore.getFormValues();
    const oldBorrower = this.editEntityBorrowerStore.entityBorrower;
    const newBorrower = { ...oldBorrower, ...formValues };

    const borrowerIndex = this.entityBorrowers.findIndex(
      b => b.borrowerId === newBorrower.borrowerId
    );
    const newBorrowerList = [...this.entityBorrowers];
    newBorrowerList[borrowerIndex] = newBorrower;

    this.entityBorrowers = newBorrowerList;
    this.editEntityBorrowerStore.closeDialog();
    this.entityBorrowersGridStore.fetchGridData();
  }

  saveAddressField(address: string, addressDetails, geometryDetails) {
    const parsedAddress = parseAddress(
      address,
      addressDetails,
      geometryDetails
    );

    this.onFieldChange('streetNumber', parsedAddress.street_number);
    this.onFieldChange('streetName', parsedAddress.street);
    this.onFieldChange('city', parsedAddress.city);
    this.onFieldChange('state', parsedAddress.state);
    this.onFieldChange('zip', parsedAddress.zip);
  }

  checkForWarnings() {
    const formValues = this.getFormValues();
    const years = differenceInYears(
      new Date(),
      new Date(formValues.operatingAgreementDate)
    );
    if (years > 1) {
      this.showCertificateOfGoodStandingWarning = true;
    }

    const properties = this.loanStore.loanDetails.properties;
    if (properties.some(p => p.state !== formValues.state)) {
      this.showForeignEntityStatusWarning = true;
    }
  }

  hideWarnings() {
    this.showCertificateOfGoodStandingWarning = false;
    this.showForeignEntityStatusWarning = false;
  }

  updateAddressFromFields() {
    const val = this.getFormValues();
    const part1 = [val.streetNumber, val.streetName, val.addressLine2]
      .filter(Boolean)
      .join(' ');
    const address = [part1, val.city, val.state, val.zip, val.city, 'USA']
      .filter(Boolean)
      .join(', ');
    this.onFieldChange('address', address);
  }

  get canEditName() {
    return !this.borrowerEntity.disableEditing && (
      this.isClosingAttorney ||
      !this.borrowerEntity?.entityName ||
      !this.document?.borrowerEntityId
    );
  }

  get canEditEin() {
    const isEinLength10 =
      (this.borrowerEntity?.ein ?? '').replace(/\D/, '').length === 10;
    return !this.borrowerEntity.disableEditing && (
      this.isClosingAttorney ||
      !isEinLength10 ||
      !this.borrowerEntity?.entityName ||
      !this.document?.borrowerEntityId
    );
  }

  get canEditType() {
    return !this.borrowerEntity.disableEditing && (
      this.isClosingAttorney ||
      !this.borrowerEntity?.type ||
      !this.borrowerEntity?.entityName ||
      !this.document?.borrowerEntityId
    );
  }

  get canEdit() {
    const entitlements = this.globalStore.userFeatures.underwritingEntitlements;
    return this.isClosingAttorney || entitlements?.isSubmissionAllowed;
  }

  get isClosingAttorney() {
    return this.globalStore.userFeatures?.isClosingAttorney;
  }
}

export default BorrowerEntityStore;
