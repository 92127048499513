import { Route } from 'react-router';
import { Page } from '@roc/client-portal-shared/components';
import { ScopeOfWorkRevisionInternal } from '../components';

export const getScopeOfWorkRevisionInternalRoutesConfig = (
  basePath: string,
  baseUrl: string
) => {
  return {
    scopeOfWorkRevision: {
      path: `${basePath}/scope-of-work-revision/:drawId`,
      url: `${baseUrl}/scope-of-work-revision/:drawId`,
      documentTitle: 'Scope of Work Revision',
    },
  };
};

export const getScopeOfWorkRevisionInternalRoutes = (
  basePath: string,
  baseUrl: string
) => {
  const config = getScopeOfWorkRevisionInternalRoutesConfig(basePath, baseUrl);
  return {
    scopeOfWorkRevision: (
      <Route exact path={config.scopeOfWorkRevision.path}>
        <Page routeDefinition={config.scopeOfWorkRevision}>
          <ScopeOfWorkRevisionInternal />
        </Page>
      </Route>
    ),
  };
};
