import { Page } from "@roc/feature-app-core";
import { Route } from "react-router";
import { CMTReportInternal } from "../components/cmtInternal";

export const getCMTInternalRoutesConfig = (
  basePath: string,
  baseUrl: string
)=> {
  return {
    cmtReport: {
      path: `${basePath}/cmt-report/:encodedParams`,
      url: `${baseUrl}/cmt-report/:encodedParams`,
      documentTitle: 'Construction Feasibility Review'
    },
  };
};

export const getCMTInternalRoutes = (
  basePath: string,
  baseUrl: string
) => {
  const config = getCMTInternalRoutesConfig(basePath, baseUrl);
  return {
    cmtReport: (
      <Route exact path={config.cmtReport.path}>
        <Page routeDefinition={config.cmtReport}>
          <CMTReportInternal/>
        </Page>
      </Route>
    )
  }
}