import { Grid, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import { TextField, Button, PhoneField, DateField } from '@roc/ui';
import { isValid } from 'date-fns';
import { isNotBlank } from '@roc/feature-utils';
import { usePayoffRequestStore } from '../hooks/usePayoffRequestStore';
import { useState, useEffect } from 'react';
import { RadioField, trueFalseOptions } from '@roc/ui';
import { useBaseStore } from '@roc/feature-app-core';


const useStyles = makeStyles(() => ({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginLeft: 24,
  },
}));

interface payoffRequestFormProps {
  onConfirm: () => void;
  onClose: () => void;
}

const PayoffRequestForm = observer(
  ({ onConfirm, onClose }: payoffRequestFormProps) => {
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

    useEffect(() => {
      if (globalStore.userFeatures.isServicer && form.fields.goodThroughDate.value
        && form.fields.requesterEmail.value) {
        setIsSubmitDisabled(false);
      }
      else if (!globalStore.userFeatures.isServicer && form.fields.goodThroughDate.value) {
        setIsSubmitDisabled(false);
      } else {
        setIsSubmitDisabled(true);
      }
    });

    useEffect(() => {
      payoffRequestStore.setSaved(false);
    }, []);

    const classes = useStyles();
    const { payoffRequestStore } = usePayoffRequestStore();
    const { form } = payoffRequestStore;
    const { globalStore } = useBaseStore();

    const manageDate = date => {
      const selectedDate = isValid(date) ? date : null;
      payoffRequestStore.onFieldChange('goodThroughDate', selectedDate);
    };

    const manageRequestedDate = date => {
      const selectedDate = isValid(date) ? date : null;
      payoffRequestStore.onFieldChange('requestedDate', selectedDate);
    };

    const setMaxDate = () => {
      const maxDate = new Date();
      maxDate.setMonth(maxDate.getMonth() + 1);
      return maxDate;
    };

    const isWeekend = (date: Date) => {
      const day = date.getDay();
      return day === 0 || day === 6;
    };

    return (
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <DateField
            testId="goodThroughDate"
            inputVariant="outlined"
            format="MM/dd/yyyy"
            standaloneLabel
            label="Payoff Good Through Date"
            value={form.fields.goodThroughDate.value}
            onChange={date => manageDate(date)}
            fullWidth
            disablePast={true}
            maxDate={setMaxDate()}
            shouldDisableDate={isWeekend}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <DateField
            testId="requestedDate"
            inputVariant="outlined"
            format="MM/dd/yyyy"
            standaloneLabel
            label="Requested Date"
            value={form.fields.requestedDate.value}
            onChange={date => manageRequestedDate(date)}
            fullWidth
            disablePast={false}
            shouldDisableDate={isWeekend}
          />
        </Grid>
        <Grid item xs={12}>
          <RadioField
            standaloneLabel
            label="Rush?"
            name="rush"
            value={form.fields.rush.value}
            options={trueFalseOptions}
            onChange={e =>
              payoffRequestStore.onFieldChange('rush', e === 'true')
            }
            fullWidth
            row
            testId="rushOption"
          />
        </Grid>
        {globalStore.userFeatures.isServicer &&
          <Grid item xs={12} sm={12}>
            <TextField
              testId="requesterEmail"
              variant="outlined"
              standaloneLabel
              label="Requester Email"
              value={form.fields.requesterEmail.value}
              onChange={e =>
                payoffRequestStore.onFieldChange('requesterEmail', e.target.value)
              }
              fullWidth
            />
          </Grid>
        }
        <Grid item xs={12} sm={12}>
          <TextField
            testId="comments"
            variant="outlined"
            standaloneLabel
            label="Comments"
            value={form.fields.comments.value}
            onChange={e =>
              payoffRequestStore.onFieldChange('comments', e.target.value)
            }
            multiline
            minRows={2}
            fullWidth
          />
        </Grid>
        <Grid item container className={classes.buttonContainer}>
          <Box mr={2}>
            <Button
              className={classes.button}
              size="small"
              variant="contained"
              color="secondary"
              onClick={onClose}
              testId="cancel"
            >
              CANCEL
            </Button>
            {globalStore.userFeatures.isServicer ?
              <Button
                disabled={isSubmitDisabled}
                className={classes.button}
                size="small"
                variant="contained"
                color="primary"
                onClick={() => {
                  payoffRequestStore.onFormSubmitV3();
                  onClose();
                }}
                testId="save"
              >
                SUBMIT
              </Button> :
              <Button
                disabled={isSubmitDisabled}
                className={classes.button}
                size="small"
                variant="contained"
                color="primary"
                onClick={() => {
                  payoffRequestStore.onFormSubmitV2();
                  onClose();
                }}
                testId="save"
              >
                SUBMIT
              </Button>}
          </Box>
        </Grid>
      </Grid>
    );
  }
);

export default PayoffRequestForm;
