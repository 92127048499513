import { formatCurrency, formatPercentage } from '@roc/ui/utils';
import { observer } from 'mobx-react';
import {
  ItemDisplay,
  LeverageCard,
  LeverageCardRow,
  PercentagePicker,
} from './leverageComponents';
import { LeverageCardProps } from '../leverageStep';
import { GroundUpLoanEconomicsStore } from '../../../stores/loanEconomics/groundUpLoanEconomicsStore';
import { QuoteTypeStore } from '../../../stores/quoteTypeStore';
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { LTC_CATCHUP_PERCENTAGE } from '../../../utils/utils';

export const GroundUpLeverageCard = observer(
  ({ store, disabled, instaQuote = false, showEmptyValues = false, LeverageMatrixCard, quoteTypeStore }: LeverageCardProps) => {
    const calculated = store.calculatedFields;
    const ranges = store.ranges as any;

    return (
      <LeverageCard
        title={
          instaQuote ? 'Insta-Leverage Quote' : 'Request Leverage'
        }
        disabled={disabled}
        instaQuote={instaQuote}
        LeverageMatrixCard={LeverageMatrixCard}
        quoteTypeStore={quoteTypeStore}
        disabledWarning={showEmptyValues}
      >
        <LeverageCardRow
          dividend={
            <ItemDisplay
              label={instaQuote ? 'Initial Loan Amount' : 'Initial Advance'}
              value={showEmptyValues ? '-' : formatCurrency(calculated.initialAdvance)}
              instaQuote={instaQuote}
            />
          }
          divisor={
            <ItemDisplay
              label={quoteTypeStore.isMidConstructionRefinance() ? 'Cost Spent to Date' : 'Purchase Price'}
              value={showEmptyValues ? '-' : formatCurrency(calculated.totalPurchasePrice)}
            />
          }
          result={
            <PercentagePicker
              fieldName={'loanToCostPercentage'}
              label="Loan to Cost"
              disabled={disabled}
              instaQuote={instaQuote}
              value={showEmptyValues ? 0 : store.form.fields.loanToCost.value}
              min={ranges?.minLoanToCost}
              max={ranges?.maxLoanToCost}
              onChange={value => store.onFieldChange('loanToCost', value)}
            />
          }
        />
        <LeverageCardRow
          dividend={
            <ItemDisplay
              label="Construction Holdback"
              value={showEmptyValues ? '-' : formatCurrency(calculated.constructionHoldback)}
              instaQuote={instaQuote}
            />
          }
          divisor={
            <ItemDisplay
              label="Proposed Construction Budget"
              value={showEmptyValues ? '-' : formatCurrency(calculated.totalProposedConstructionBudget)}
            />
          }
          result={
            <PercentagePicker
              label="Construction Reserve"
              fieldName="constructionReserve"
              disabled={disabled}
              instaQuote={instaQuote}
              value={showEmptyValues ? 0 : store.form.fields.constructionReserve.value}
              min={ranges?.minConstructionReserve}
              max={ranges?.maxConstructionReserve}
              onChange={value =>
                store.onFieldChange('constructionReserve', value)
              }
            />
          }
        />
        <LeverageCardRow
          dividend={
            <ItemDisplay
              label="Total Loan Amount"
              value={showEmptyValues ? '-' : formatCurrency(calculated.totalLoanAmount)}
              instaQuote={instaQuote}
            />
          }
          divisor={
            <ItemDisplay
              label="Total Cost"
              value={showEmptyValues ? '-' : formatCurrency(calculated.costBasis)}
            />
          }
          result={
            <ItemDisplay
              label="Total Loan To Cost"
              value={showEmptyValues ? '-' : formatPercentage(calculated.calculatedLTFC)}
              instaQuote={instaQuote}
            />
          }
        />
        <LeverageCardRow
          dividend={
            <ItemDisplay
              label="Total Loan Amount"
              value={showEmptyValues ? '-' : formatCurrency(calculated.totalLoanAmount)}
              instaQuote={instaQuote}
            />
          }
          divisor={
            <ItemDisplay
              label="After Repair Value"
              value={showEmptyValues ? '-' : formatCurrency(calculated.totalAfterRepairValue)}
            />
          }
          result={
            <ItemDisplay
              label="ARLTV"
              value={showEmptyValues ? '-' : formatPercentage(calculated.afterRepairLTV)}
              instaQuote={instaQuote}
            />
          }
        />
      </LeverageCard>
    );
  }
);

const useFinancedInterestReserveStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    height: '100%',
  },
  container: {
    background: '#0000001A',
    borderRadius: '14px',
    padding: '18px',
    paddingTop: '10px',
    paddingBottom: '2px',
  },
  radioGroup: {
    width: '100%',
    justifyContent: 'space-around',
  },
}));

export const FinancedInterestReserve = observer(
  ({ store }: { store: QuoteTypeStore }) => {
    const classes = useFinancedInterestReserveStyles();
    const loanEconomicsStore = store.loanEconomicsStore as GroundUpLoanEconomicsStore;
    const value = loanEconomicsStore.interestReserveFinanced;
    return (
      <Box className={classes.wrapper}>
        <Box className={classes.container}>
          <FormControl component="fieldset">
            <FormLabel focused={false}>
              Add financed interest reserve?
            </FormLabel>
            <RadioGroup
              row
              className={classes.radioGroup}
              name={'addFinancedInterestReserve'}
              value={value}
              onChange={e => {
                const value = e.target.value === 'true';
                loanEconomicsStore.setAddFinancedInterestReserve(value);
                console.log({ loanEconomicsStore: loanEconomicsStore });
                loanEconomicsStore.caclulateFields(
                  store.propertiesStore.getProperties(),
                  loanEconomicsStore.instaLeverageQuoteFormStore.getFormValues()
                );
              }}
            >
              <FormControlLabel
                value={true}
                control={<Radio color="primary" />}
                label="Yes"
                id="Yes"
                checked={value === true}
              />
              <FormControlLabel
                value={false}
                control={<Radio color="primary" />}
                label="No"
                id="No"
                checked={value === false}
              />
            </RadioGroup>
          </FormControl>
        </Box>
      </Box>
    );
  }
);
