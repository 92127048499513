import { Box, FormHelperText, Grid } from '@material-ui/core';
import { observer } from 'mobx-react';
import {
  createNumberFormatField,
  createSelectField,
  createSliderField,
  createTextField,
  FieldLabel,
} from '@roc/ui';
import { makeStyles } from '@material-ui/core/styles';
import { estimatedFFExperienceOptions } from '@roc/feature-utils';
import {
  Bankruptcy,
  BankruptcyDate,
  CitizenshipStatus,
  ExperienceLevel,
  Foreclosure,
  ForeclosureDate,
  QualifyingFicoScore,
} from '@roc/client-portal-shared/components';
import { rentalExperienceOptions } from 'libs/feature-borrower-loan-submission/src/modules/newLoanApplication/utils/loanApplicationConstants';

const useStyles = makeStyles(theme => ({
  slider: {
    paddingTop: '1px',
  },
}));

export const BorrowerFormRental = observer(
  ({ store, mainBorrower = false, readOnly = false }) => {
    const classes = useStyles();
    return (
      <Grid container direction="row" spacing={2}>
        <Grid item xs={12}>
          {createTextField({
            store,
            testId: 'firstName',
            fieldName: 'firstName',
            label: 'First Name',
            disabled: readOnly,
          })}
        </Grid>
        <Grid item xs={12}>
          {createTextField({
            store,
            testId: 'lastName',
            fieldName: 'lastName',
            label: 'Last Name',
            disabled: readOnly,
          })}
        </Grid>
        <Grid item xs={12}>
          {createTextField({
            store,
            testId: 'emailAddress',
            fieldName: 'emailAddress',
            label: 'Email Address',
            disabled: readOnly,
          })}
        </Grid>
        <Grid item xs={12}>
          {createTextField({
            store,
            testId: 'confirmEmailAddress',
            fieldName: 'confirmEmailAddress',
            label: 'Confirm Email Address',
            disabled: readOnly,
          })}
        </Grid>
        <Grid item xs={12}>
          {createNumberFormatField({
            store,
            testId: 'cellPhone',
            fieldName: 'cellPhone',
            label: 'Phone Number',
            format: '###-###-####',
          })}
        </Grid>
        <Grid item xs={12}>
          {createSelectField({
            store,
            testId: 'rentalExperience',
            fieldName: 'rentalExperience',
            label: 'Rental Properties Owned in Last 24 months',
            options: rentalExperienceOptions,
          })}
        </Grid>
        <Grid item xs={12}>
          <CitizenshipStatus store={store} />
        </Grid>
        <Grid item xs={12}>
          <Box
            display="flex"
            alignItems="center"
            style={{ height: '40px', marginBottom: '-16px' }}
          >
            <FieldLabel>Estimated FICO Score</FieldLabel>
          </Box>
          <Box style={{ paddingLeft: '8px' }}>
            {createSliderField({
              store,
              testId: 'ficoProvidedAtOrigination',
              fieldName: 'ficoProvidedAtOrigination',
              label: '',
              min: 350,
              max: 800,
              step: 10,
              marks: [
                {
                  value: 350,
                  label: '350',
                },
                {
                  value: 800,
                  label: '800',
                },
              ],
              valueLabelDisplay: 'on',
              className: classes.slider,
            })}
          </Box>
          {store.form.fields.ficoProvidedAtOrigination.error && (
            <Box pt={1}>
              <FormHelperText error>
                {store.form.fields.ficoProvidedAtOrigination.error}
              </FormHelperText>
            </Box>
          )}
        </Grid>
        {mainBorrower && (
          <>
            <Grid item xs={12}>
              <Bankruptcy store={store} />
            </Grid>
            {store.form.fields.bankruptcy.value === 'Y' && (
              <Grid item xs={12}>
                <BankruptcyDate store={store} />
              </Grid>
            )}
            <Grid item xs={12}>
              <Foreclosure store={store} />
            </Grid>
            {store.form.fields.foreclosure.value === 'Y' && (
              <Grid item xs={12}>
                <ForeclosureDate store={store} />
              </Grid>
            )}
          </>
        )}
      </Grid>
    );
  }
);
