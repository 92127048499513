import { Box, Table, TableBody, TableCell, TableHead, TableRow, Theme, Typography, makeStyles } from '@material-ui/core';
import { alpha } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { TextField } from '@roc/ui';
import { BackgroundItemStore } from '../stores/backgroundItemStore';
import { BorrowerInfoCardInternalStore } from 'apps/roc-internal-portal/src/app/modules/borrowerInfoCard/stores/borrowerInfoCardInternalStore';
import { BorrowerBaseballCardStore } from '@roc/feature-borrowers';

const modalStyles = makeStyles((theme: Theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '10px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    minWidth: '400px',
  },
}));

const useStyles = makeStyles((theme: Theme) => ({
  fixedWidthText: {
    fontSize: '1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%'
  },
  ssnCell: {
    textAlign: 'center',
    minWidth: '120px',
  },
  tableContainer: {
    borderRadius: '10px',
    border: '1px #e0e0e0 solid',
    overflow: 'hidden',
  },
  table: {
    minWidth: 750,
    width: '100%',
    '& td': {
      height: '40px',
      padding: 0,
      paddingTop: '8px',
      paddingBottom: '8px',
      border: '1px solid #e0e0e0',
    },
    '& th:first-child, td[rowSpan], $addNewItemRow td': {
      paddingLeft: theme.spacing(2),
      paddingRight: '3px',
    },
    '& table tr:last-child td, & table td[rowSpan]': {
      borderBottom: 'none',
    },
  },

  editableRow: {
    position: 'relative',
    cursor: 'pointer',
    textAlign: 'center',
    '& td': {
      textAlign: 'center'
    },
  },

  headerCell: {
    height: '41px',
    fontWeight: 'bold',
    color: '#757575',
    padding: 0,
    backgroundColor: '#F0FAFF',
    textAlign: 'center',
    border: '1px solid #e0e0e0',
  },

  titleCell: {
    height: '46px',
    padding: 0,
    overflow: 'hidden',
    backgroundColor: '#E2F6FF'
  },

  descriptionCell: {
    whiteSpace: 'pre-wrap',
  },

  inputBase: {
    width: '100%',
    '& input': {
      backgroundColor: '#fdefbf',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      fontSize: 14,
      color: 'black',
      fontWeight: theme.typography.fontWeightBold,
      padding: '8px 4px',
      cursor: 'pointer',
      '&:focus': {
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  autocompletePaper: {
    marginLeft: 0,
    marginRight: 0,
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    fontSize: 13,
  },
  autocompleteOption: {
    minHeight: 'auto',
    alignItems: 'flex-start',
    padding: 8,
    '&[data-focus="true"]': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  addNewItemCell: {
    padding: 0,
    height: '40px',
    backgroundColor: '#fdefbf',
  },
  addNewItemRow: {
    '&:hover': {
      backgroundColor: '#fff',
    },
  },
  rowActions: {
    visibility: 'hidden',
    position: 'absolute',
    right: 0,
    top: 0,
    bottom: 0,
    zIndex: 99,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      visibility: 'visible',
    },
  },
  horizontalScroll: {
    [theme.breakpoints.down('md')]: {
      overflowX: 'scroll',
    },
  },
  clickHereToEdit: {
    color: theme.palette.grey[400],
    fontSize: theme.typography.fontSize * 0.8,
  },
  clickHereToEditHidden: {
    visibility: 'hidden',
  },
  title: {
    color: '#757575',
    fontSize: 20
  },
}));

interface BorrowerExperienceDataProps {
  store: BorrowerInfoCardInternalStore | BorrowerBaseballCardStore;
  isInternal?: boolean;
  isExperienceEditable?: boolean;
}

const BorrowerExperienceData = observer(({ store, isInternal, isExperienceEditable }: BorrowerExperienceDataProps) => {
  const columns = [
    {
      name: 'Bridge Experience',
      width: '25%',
    },
    {
      name: 'Term Experience',
      width: '25%',
    },
    {
      name: 'Term Experience (5+ units)',
      width: '25%',
    },
    {
      name: 'Total # Properties Owned',
      width: '25%',
    },
  ];

  const classes = useStyles();

  useEffect(() => {

  }, []);

  return (
    <Box style={{ paddingTop: '15px' }}>
      <div className={classes.tableContainer}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {columns.map(({ name, ...props }) => (
                <TableCell key={name} className={classes.headerCell} style={{ paddingLeft: '8px' }}>
                  {name}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody className={classes.editableRow}>
            <TableRow>
              <TableCell style={{ paddingRight: '5px', paddingLeft: '5px' }}>
                <TextField
                  type="number"
                  variant="outlined"
                  value={store.baseballCardDetails?.bridgeExp}
                  onChange={(e) => store.setExperience(Number(e.target.value))}
                  fullWidth
                  margin="normal"
                  required
                  testId="shareUrl"
                  disabled={!(isInternal && isExperienceEditable)}
                />
              </TableCell>
              <TableCell style={{ paddingRight: '5px', paddingLeft: '5px' }}>
                <TextField
                  type="number"
                  variant="outlined"
                  value={store.baseballCardDetails?.rentalExp}
                  onChange={(e) => store.setRentalExperience(Number(e.target.value))}
                  fullWidth
                  margin="normal"
                  required
                  testId="shareUrl"
                  disabled={!(isInternal && isExperienceEditable)}
                />
              </TableCell>
              <TableCell style={{ paddingRight: '5px', paddingLeft: '5px' }}>
                <TextField
                  type="number"
                  variant="outlined"
                  value={store.baseballCardDetails?.rentalMfExperience}
                  onChange={(e) => store.setRentalMfExperience(Number(e.target.value))}
                  fullWidth
                  margin="normal"
                  required
                  testId="shareUrl"
                  disabled={!(isInternal && isExperienceEditable)}
                />
              </TableCell>
              <TableCell style={{ paddingRight: '5px', paddingLeft: '5px' }}>
                <TextField
                  type="number"
                  variant="outlined"
                  value={store.baseballCardDetails?.totalPropertiesOwned}
                  onChange={(e) => store.setTotalPropertiesOwned(Number(e.target.value))}
                  fullWidth
                  margin="normal"
                  required
                  testId="shareUrl"
                  disabled={!(isInternal && isExperienceEditable)}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    </Box>
  );
});

export default BorrowerExperienceData;