import { useEffect } from 'react';
import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import { ClosingSummaryAccordion } from '../../summary/components/closing/closingSummaryAccordion';
import { QuoteTypeStore } from '../../../stores/quoteTypeStore';
import { PreflightStep } from '../../../utils/constants';
import { ClosingForm } from '../../summary/components/closing/closingForm';
import { PrefligthCard } from './preflightCard';
import { BRIDGE, TERM } from '@roc/feature-utils';
import { useBaseStore } from '@roc/feature-app-core';

export const PrefligthClosingCard = observer(({ store }) => {
  const entityFormStore = store.entityStore.entityFormStore;
  const editMode = entityFormStore.form.meta.editMode;
  const { globalStore } = useBaseStore();
  const allowParticipation = globalStore.userFeatures?.allowParticipation;
  const { preflightStore } = store;

  useEffect(() => {
    const properties = store.propertiesStore.getProperties();
    const states = properties.map(p => p.state);

    if (store.isBridgeLoan) {
      store.closingStore.fetchAttorneysByState(states, BRIDGE);
    } else {
      store.closingStore.fetchAttorneysByState(states, TERM);
    }
  }, []);

  const step = PreflightStep.CLOSING;

  return (
    <PrefligthCard
      store={store}
      step={step}
      onEdit={() => store.preflightStore.goToStep(step)}
      onReset={() => {
        store.closingStore.closingFormStore.reset();
      }}
      onSave={() => {
        store.preflightStore.goToNextStep();
      }}
      FormComponent={() => (
        <ClosingForm
          quoteTypeStore={store}
          store={store.closingStore?.closingFormStore}
          closingStore={store.closingStore}
          allowParticipation={allowParticipation}
        />
      )}
      AccordionComponent={props => (
        <ClosingSummaryAccordion
          {...props}
          allowParticipation={allowParticipation}
          store={store.closingStore}
        />
      )}
    />
  );
});
