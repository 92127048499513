import { Box, Grid, Typography, Tooltip } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import { FileUpload, TextField, FileUploadArea, FieldLabel } from '@roc/ui';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { CreditReportExampleDialog } from 'libs/feature-borrower-review/src/components/utils/creditReportExampleDialog';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { isBlank } from '@roc/feature-utils';

const useStyles = makeStyles(() => ({
  description: {
    whiteSpace: 'pre'
  },
  buttonsGrid: {
    marginTop: '7px',
    display: 'flex',
    alignItems: 'center',
  },
  inputContainer: {
    marginTop: '24px'
  },
  inputField: {
    marginTop: '6px'
  },
  icon: {
    color: "transparent",
    "& path": {
      stroke: "currentColor",
      strokeWidth: 1.5,
      fill: "transparent"
    }
  }
}));

export const TpoCreditInformation = observer(
  ({ store, showError = false, disabled = false }) => {
    const classes = useStyles();
    const [uploads, setUploads] = useState<FileUpload[]>([]);
    const [openExampleModal, setOpenExampleModal] = useState(false);
    const theme = useTheme();

    useEffect(() => {
      store.setCreditUploads(uploads);
    }, [uploads]);


    const closeExampleModal = () => {
      setOpenExampleModal(false);
    }

    return (
      <Grid container >
        <Grid item xs={12}>
          <Box display="flex" alignItems="center">
            <FieldLabel>
              File Number
            </FieldLabel>
            <Tooltip title='Open Credit Report Example'>
              <span
                style={{ cursor: 'pointer', marginLeft: '8px' }}
                onClick={() => setOpenExampleModal(true)}
              >
                <HelpIcon style={{ color: theme.palette.primary.main }} fontSize="small" />
              </span>
            </Tooltip>
          </Box>
          <TextField
            type="text-area"
            variant="outlined"
            label=""
            value={store.form.fields.fileNumber.value}
            onChange={e => store.onFieldChange('fileNumber', e.target.value)}
            fullWidth
            multiline={false}
            minRows={4}
            margin="normal"
            testId="fileNumber"
            className={classes.inputField}
            disabled={disabled}
            error={showError && isBlank(store.form.fields.fileNumber.value)}
          />
        </Grid>
        <CreditReportExampleDialog open={openExampleModal} onClose={closeExampleModal} />
        {!disabled &&
          <>
            <Grid item xs={12}>
              <Typography
                component="span"
                variant='subtitle2'
                style={{ display: 'inline', padding: '0px', margin: '0px' }}
                color="textSecondary"
              >
                Please upload a Credit Report (Xactus Report recommended), and enter the File #(as suggested in the
                {<span
                  style={{ cursor: 'pointer', color: theme.palette.primary.main }}
                  onClick={() => setOpenExampleModal(true)}> image</span>}). Uploading a Xactus Report will speed up your file's processing time.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FileUploadArea
                items={uploads}
                setItems={(items) => setUploads(items)}
                multiple={false}
                showError={showError && uploads.length === 0}
              />
            </Grid>
          </>
        }
      </Grid>
    );
  }
);

export default TpoCreditInformation;