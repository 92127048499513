import { Box, Divider, Grid, Typography, makeStyles } from "@material-ui/core";
import { DateFormat, formatDate, isNil } from "@roc/feature-utils";
import { Button, Card, createTextField, EditableDialog, HtmlContainer, StandardDialog } from "@roc/ui";
import { observer } from "mobx-react";
import { createHtmlField } from '../leadDetails/htmlField'
import { useStore } from "@roc/client-portal-shared/hooks";
import { ContactNotesCard } from "./contactNotesCard";
import { useEffect } from "react";
import { BORROWER_OBJECT_TYPE, BROKER_OBJECT_TYPE, LEAD_OBJECT_TYPE, TPO_OBJECT_TYPE } from "../leads/constants/constants";
import { Note } from "./note";

const useStyles = makeStyles(theme => ({
  button: {
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(2),
    minWidth: '150px',
  },
  notesScroll: {
    backgroundColor: '#FAFAFA',
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    maxHeight: '800px',
    overflowY: 'auto',
    overflowX: 'hidden',
    boxShadow: '0 0 2px #DDD inset'
  },
}));


const ViewAndAddNotesDialogContent = observer(({ recordId, objectType }) => {

  const classes = useStyles();

  const { salesforceLeadStore } = useStore();
  const { leadsStore, gridBusinessUnit } = salesforceLeadStore;
  const { leadDetailsStore } = leadsStore;
  const { editLeadNoteStore } = leadDetailsStore;

  const store = editLeadNoteStore;

  const { leadNote } = editLeadNoteStore;

  useEffect(() => {
    leadDetailsStore.initialize(recordId, objectType);
    leadDetailsStore.fetchLeadNotes(gridBusinessUnit);
  }, [recordId])

  const handleClose = () => {
    editLeadNoteStore.closeLeadNoteDialog();
  }

  const handleAdd = () => {
    editLeadNoteStore.saveLeadNote(gridBusinessUnit);
  }

  return (
    <Grid container spacing={6} justifyContent="space-between">
      <Grid item xs={12} md={6}>
        <Card title="Add New Note">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {createTextField({
                store,
                testId: 'subject',
                fieldName: 'subject',
                label: 'Subject',
              })}
            </Grid>
            <Grid item xs={12}>
              {createHtmlField({
                store,
                testId: 'body',
                fieldName: 'body',
                label: 'Note',
                multiline: true,
                minRows: 5
              })}
            </Grid>
            <Grid container xs={12} justifyContent="flex-end" style={{ marginTop: '24px' }}>
              <Button
                style={{ marginRight: '8px' }}
                onClick={handleClose}
                color="primary"
                variant="outlined"
                testId="cancel"
              >
                Cancel
              </Button>
              <Button
                onClick={handleAdd}
                color="primary"
                variant="contained"
                testId="save"
              >
                Add
              </Button>

            </Grid>
          </Grid>

        </Card>
      </Grid>

      <Grid item xs={12} md={6}>
        <Card
          title="Existing Notes"
          cardContentProps={{ className: classes.notesScroll }}
        >
          {leadDetailsStore.leadNotes.length > 0 ? (
            <Grid container spacing={2}>
              {leadDetailsStore.leadNotes.map(leadNote => (
                <Grid item xs={12}>
                  <Note
                    title={leadNote.subject}
                    onEdit={() => editLeadNoteStore.openEditLeadNote(leadNote)}
                    createdDate={leadNote.createdDate}
                    lastUpdatedDate={leadNote.lastUpdatedDate}
                    createdBy={leadNote.createdByName}
                    lastModifiedBy={leadNote.lastUpdatedByName}
                  >
                    <HtmlContainer html={leadNote.body} />
                  </Note>
                </Grid>
              ))}
            </Grid>
          ) : (
            <Box textAlign="center">No records.</Box>
          )}
        </Card>
      </Grid>
    </Grid>
  );
});


export const ViewAndAddNotesDialog = observer(({ leadId, leadName, objectType, contactId }) => {

  const { salesforceLeadStore } = useStore();
  const { leadsStore } = salesforceLeadStore;
  const { leadDetailsStore } = leadsStore;
  const { editLeadNoteStore } = leadDetailsStore;

  const handleClose = () => {
    editLeadNoteStore.closeLeadNoteDialog();
  }


  let recordId;
  switch (objectType) {
    case LEAD_OBJECT_TYPE:
      recordId = leadId
      break;
    case TPO_OBJECT_TYPE:
    case BROKER_OBJECT_TYPE:
    case BORROWER_OBJECT_TYPE:
      recordId = contactId;
      break;
  }
  return (
    <Grid container spacing={2}>
      <StandardDialog
        maxWidth={'lg'}
        open={!isNil(editLeadNoteStore.dialogState)}
        handleClose={handleClose}
        title={`Notes - ${leadName}`}
        dialogContent={<ViewAndAddNotesDialogContent recordId={recordId} objectType={objectType} />}
      />
    </Grid>
  );


});
