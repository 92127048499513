import { Box, FormControlLabel, Grid, InputAdornment, Radio, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { isBlank, isNotBlank } from '@roc/feature-utils';
import { Card, ConfirmationButton, CopyText, TextField } from '@roc/ui';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import swal from 'sweetalert';
import { useLogoutStore } from '../../logout';
import { useWhiteLabelStore } from './../hooks/useWhiteLabelStore';

export const DomainSetup = observer(() => {

  const [radioState, setRadioState] = useState<"SUBDOMAIN" | "CUSTOM_DOMAIN" | undefined>();
  const { whiteLabelConfigStore } = useWhiteLabelStore();
  const { logoutStore } = useLogoutStore();

  useEffect(() => {
    whiteLabelConfigStore.whiteLabelStore.fetchPortalConfiguration();
    whiteLabelConfigStore.populateForm();
  }, []);

  return (
    <>
      <Card title="WHITE LABEL SETUP" style={{ marginTop: 16 }}>
        <Grid container>
          <Grid item xs={12}>
            <Box mb={2}>
              <Box>
                <FormControlLabel
                  value="end"
                  control={<Radio color="primary" checked={radioState == 'SUBDOMAIN'} onClick={() => setRadioState("SUBDOMAIN")} />}
                  label="Lite White Label"
                  labelPlacement="end"
                />
              </Box>
              {
                radioState == 'SUBDOMAIN' ?
                  <Box ml={4} my={4}>
                    <TextField
                      testId="subDomain"
                      variant="outlined"
                      value={whiteLabelConfigStore.subDomain}
                      onChange={e => whiteLabelConfigStore.setSubDomain(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start" style={{
                            background: '#eee',
                            height: '100%',
                            padding: 16,
                            margin: 0,
                          }}>
                            <Typography>https://</Typography>
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="start" style={{
                            background: '#eee',
                            height: '100%',
                            padding: 16,
                            margin: 0,
                          }}>
                            <Typography>.privatelenderportal.com</Typography>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Box mt={2} textAlign="left">
                      <ConfirmationButton
                        color="primary"
                        variant="contained"
                        onClick={async () => {
                          await whiteLabelConfigStore.saveSubDomain();
                          swal({
                            title: "Success!",
                            text: `Lite White Label setup completed! Click below button to redirect to ${whiteLabelConfigStore.host}`,
                            icon: "success",
                            buttons: {
                              redirect: {
                                text: "Redirect",
                              },
                            },
                            closeOnEsc: false,
                            closeOnClickOutside: false,
                          }).then(async (v) => {
                            await logoutStore.logoutWithoutRedirect();
                            window.location.href = whiteLabelConfigStore.host;
                          });
                        }}
                        testId="publish_changes"
                        disabled={!whiteLabelConfigStore.subDomain}
                        confirmDialogProps={{
                          title: 'Confirm Setup & User Migration',
                          body: (
                            <>
                              <Alert severity='warning'>
                                By setting up white label, all of your existing portal users will be migrated to <strong>{whiteLabelConfigStore.host}</strong>
                              </Alert>
                              <br />
                              Please confirm if you want to continue with the white label setup.
                              <br /><br />
                              <Typography color='error'>Also note that, on successful setup, you will be redirected to <strong>{whiteLabelConfigStore.host}</strong> and you can login with your same credentials.</Typography>
                            </>
                          ),
                          confirmButtonText: 'Yes',
                        }}
                      >
                        Save
                      </ConfirmationButton>
                    </Box>
                  </Box> : null
              }
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box>
              <Box>
                <FormControlLabel
                  value="end"
                  control={<Radio color="primary" checked={radioState == 'CUSTOM_DOMAIN'} onClick={() => setRadioState("CUSTOM_DOMAIN")} />}
                  label="Full White Label"
                  labelPlacement="end"
                />
              </Box>
              {
                radioState == 'CUSTOM_DOMAIN' &&
                <Box m={2}>
                  <Alert severity="warning">
                    <span>Please reach out to </span>
                    {/*<ListItem>
                      <a href="mailto:whitelabel-support@privatelenderportal.com" target="_top">
                        whitelabel-support@privatelenderportal.com
                      </a>
                      </ListItem>*/}
                    <CopyText tooltipText="Copy Email Address">
                      <strong>whitelabel-support@privatelenderportal.com</strong>
                    </CopyText>
                  </Alert>
                </Box>
              }
            </Box>
          </Grid>
        </Grid>
      </Card>
    </>
  );

});


export const DomainSetupLite = observer(() => {

  const { whiteLabelConfigStore } = useWhiteLabelStore();
  const { logoutStore } = useLogoutStore();
  const [errorText, setErrorText] = useState('');

  useEffect(() => {
    whiteLabelConfigStore.whiteLabelStore.fetchPortalConfiguration();
    whiteLabelConfigStore.populateForm();
  }, []);

  return (
    <>
      <Card title="WHITE LABEL SETUP" style={{ marginTop: 16 }}>
        <Grid container>
          <Grid item xs={12}>
            <Box mb={2}>
              <Box my={4}>
                <TextField
                  testId="subDomain"
                  variant="outlined"
                  value={whiteLabelConfigStore.subDomain}
                  subLabel={"Allowed only alphanumeric characters and hyphens"}
                  error={isNotBlank(errorText)}
                  helperText={errorText}
                  inputProps={{ maxLength: 40 }}
                  onChange={e => {
                    const regEx = /^[a-zA-Z0-9-]+$/;
                    if (isBlank(e.target.value) || regEx.test(e.target.value)) {
                      setErrorText('');
                      whiteLabelConfigStore.setSubDomain(e.target.value.toLowerCase());
                    } else {
                      setErrorText('Subdomain can only contain alphanumeric characters and hyphens');
                    }
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" style={{
                        background: '#eee',
                        height: '100%',
                        padding: 16,
                        margin: 0,
                      }}>
                        <Typography>https://</Typography>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="start" style={{
                        background: '#eee',
                        height: '100%',
                        padding: 16,
                        margin: 0,
                      }}>
                        <Typography>.privatelenderportal.com</Typography>
                      </InputAdornment>
                    ),
                  }}
                />
                <Box mt={2} textAlign="left">
                  <ConfirmationButton
                    color="primary"
                    variant="contained"
                    onClick={async () => {
                      await whiteLabelConfigStore.saveSubDomain();
                      swal({
                        title: "Success!",
                        text: `White Label setup completed! Click below button to redirect to ${whiteLabelConfigStore.host}`,
                        icon: "success",
                        buttons: {
                          redirect: {
                            text: "Redirect",
                          },
                        },
                        closeOnEsc: false,
                        closeOnClickOutside: false,
                      }).then(async (v) => {
                        await logoutStore.logoutWithoutRedirect();
                        window.location.href = whiteLabelConfigStore.host;
                      });
                    }}
                    testId="publish_changes"
                    disabled={!whiteLabelConfigStore.subDomain}
                    confirmDialogProps={{
                      title: 'Confirm Setup & User Migration',
                      body: (
                        <>
                          <Alert severity='warning'>
                            By setting up white label, all of your existing portal users will be migrated to <strong>{whiteLabelConfigStore.host}</strong>
                          </Alert>
                          <br />
                          Please confirm if you want to continue with the white label setup.
                          <br /><br />
                          <Typography color='error'>Also note that, on successful setup, you will be redirected to <strong>{whiteLabelConfigStore.host}</strong> and you can login with your same credentials.</Typography>
                        </>
                      ),
                      confirmButtonText: 'Yes',
                    }}
                  >
                    Save
                  </ConfirmationButton>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Card>
    </>
  );

});