import React, { useState, useEffect } from 'react';
import { Layout } from '@roc/feature-app-core';
import { observer } from 'mobx-react';
import { Box, Chip, TextField, Grid, Typography, Paper, Switch, FormControlLabel } from '@material-ui/core'; // Switch import
import { useStore } from '@roc/client-portal-shared/hooks';
import { DateField } from '@roc/ui';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { LoanVolumeChart } from './loanVolumeChart';
import { useUserStore } from '@roc/feature-app-core';
import { Autocomplete } from "@material-ui/lab";

const useStyles = makeStyles((theme) =>
  createStyles({
    dialogContent: {
      height: 'calc(100vh - 100px)',
      width: '100%',
    },
    filterContainer: {
      padding: '20px',
      marginTop: theme.spacing(2),
    },
    root: {
      margin: '10px 0px',
      padding: '10px',
      fontSize: '14px',
      fontFamily: 'open-sans, sans-serif',
      width: '300px'
    },
    autoComplete: {
      width: '100%',
      height: '150px',
      marginTop: '2px',
      '& input': {
        fontWeight: 'bold',
      },
      '& input::placeholder': {
        opacity: 1,
      },
      '& input:focus::placeholder': {
        opacity: 0.5,
      },
    },
    title: {
      marginBottom: theme.spacing(2),
    },
    paper: {
      padding: theme.spacing(4),
      marginBottom: theme.spacing(2),
    },
    calculatedValuesBorder: {
      border: '1px solid #BEBEBE',
      borderRadius: '15px',
      padding: 16,
    },
  })
);

export const LoanVolumeReport = observer(() => {
  const { loanVolumeReportStore } = useStore();
  const { companyDropdownStore, userStore } = useUserStore();
  const classes = useStyles();
  const { companies } = companyDropdownStore;
  const thisYearStart = moment().startOf('year');
  const initialStartDate = thisYearStart.format('MM/DD/YYYY');
  const [startDate, setStartDate] = useState<string>(initialStartDate);
  const initialEndDate = moment().format('MM/DD/YYYY');
  const formattedEndDate = moment(initialEndDate, 'MM/DD/YYYY').format('MM/DD/YYYY');
  const [endDate, setEndDate] = useState<string>(formattedEndDate);
  const [quarterly, setQuarterly] = useState(false);
  const [annually, setAnnually] = useState(false);
  const [currentCompany, setCurrentCompany] = useState('');
  const [selectedIndexes, setSelectedIndexes] = useState([]);
  const { relationshipManagers } = loanVolumeReportStore;
  const [relationshipManager, setRelationshipManager] = useState(userStore?.userInformation?.fullName);
  const [currentRelationshipManager, setCurrentRelationshipManager] = useState('');
  const [lineData, setLineData] = useState([
    {
      label: 'Submitted loans',
      color: '#EB25E1',
      dataKey: 'submittedFiltered',
      checked: true,
    },
    {
      label: 'Agreed Loans',
      color: '#827B39',
      dataKey: 'agreedFiltered',
      checked: true,
    },
  ]);


  const onChipClick = (clickedIndex) => {
    setSelectedIndexes((prevIndexes) => {
      const currentIndexes = [...prevIndexes];
      const indexFound = currentIndexes.findIndex((elem) => elem === clickedIndex);

      if (indexFound !== -1) {
        currentIndexes.splice(indexFound, 1);
      } else {
        currentIndexes.push(clickedIndex);
      }
      return currentIndexes;
    });
  };

  useEffect(() => {
    loanVolumeReportStore.fetchLoanVolumeReport(
      startDate,
      endDate,
      currentCompany,
      relationshipManager,
      quarterly,
      annually,
      selectedIndexes);
  }, [selectedIndexes, currentCompany, startDate, endDate, quarterly, annually,relationshipManager]);

  useEffect(() => {
    companyDropdownStore.getUserCompanies();
    loanVolumeReportStore.fetchRelationshipManagerAndLenderMap();
  }, [])

  const options = loanVolumeReportStore.columns ? loanVolumeReportStore.columns
    .map(column => ({ value: column.name, label: column.name, color: column.color, stackName: column.stackName }))
    .reduce((acc, current) => {
      const filteredElem = acc.find(item => item.value === current.value);
      if (!filteredElem) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []).reverse() : []

  const handleMenuItemClick = company => {
    setCurrentCompany(company.label);
  };

  const handleRMItemClick = rm => {
    setRelationshipManager(rm.label);
    setCurrentRelationshipManager(rm.label);
  };

  const onUpdateLineData = (index: number) => {
    setLineData((prevLineData) => [
      ...prevLineData.slice(0, index),
      { ...prevLineData[index], checked: !prevLineData[index].checked },
      ...prevLineData.slice(index + 1),
    ]);
  };

  const handleQuarterlyChange = () => {
    setQuarterly(prevQuarterly => !prevQuarterly);
    setAnnually(false);
    setStartDate(moment().subtract(1, 'year').startOf("month").format("MM/DD/YYYY"));
    setEndDate(moment().format("MM/DD/YYYY"));
  };

  const handleAnnuallyChange = () => {
    setAnnually(prevAnnually => !prevAnnually);
    setQuarterly(false);
    const currentYear = moment().year();
    const previousYearStart = moment().subtract(1, 'year').startOf('year');
    const startDate = previousYearStart.format('MM/DD/YYYY');
    const endDate = moment().format('MM/DD/YYYY');
    setStartDate(startDate);
    setEndDate(endDate);
  };

  return (
    <Layout title="Loan Volume Report" maxWidth={'xl'}>
      <Paper className={classes.paper}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant={'h5'}>Graph Options</Typography>
              </Grid>
              <Grid container item xs={12} spacing={2} style={{height:'100px'}}>
                <Grid item xs={3}>
                  <DateField
                    testId="startDate"
                    inputVariant="outlined"
                    standaloneLabel
                    format={annually ? "yyyy" : "MM/dd/yyyy"}
                    label="Start Date"
                    value={new Date(startDate)}
                    onChange={(date) =>
                      setStartDate(moment(date, 'MM/DD/YYYY').format('MM/DD/YYYY'))
                    }
                    disableFuture={true}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={3}>
                  <DateField
                    testId="endDate"
                    inputVariant="outlined"
                    standaloneLabel
                    format={annually ? "yyyy" : "MM/dd/yyyy"}
                    label="End Date"
                    value={new Date(endDate)}
                    onChange={(date) =>
                      setEndDate(moment(date, 'MM/DD/YYYY').format('MM/DD/YYYY'))
                    }
                    disableFuture={true}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={3}>
                  <Typography>Lender</Typography>
                  <Autocomplete
                    className={classes.autoComplete}
                    id="menu-appbar"
                    options={companies}
                    size="medium"
                    disableClearable
                    componentName="menu-appbar"
                    renderInput={(options) => (
                      <TextField
                        {...options}
                        placeholder={currentCompany != '' ? currentCompany : 'Select...'}
                        variant={'outlined'}
                      />
                    )}
                    blurOnSelect={true}
                    getOptionLabel={(option) => option?.label || ''}
                    onChange={(event, value) => handleMenuItemClick(value)}
                    value={currentCompany}
                    getOptionSelected={(option, value) =>
                      option.label === value.label
                    }
                  />
                </Grid>
                <Grid item xs={3}>
                  <Typography>Relationship Manager</Typography>
                  <Autocomplete
                    className={classes.autoComplete}
                    id="menu-appbar"
                    options={relationshipManagers}
                    size="medium"
                    disableClearable
                    componentName="menu-appbar"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={currentRelationshipManager !== '' ? currentRelationshipManager : 'Select...'}
                        variant={'outlined'}
                      />
                    )}
                    blurOnSelect={true}
                    getOptionLabel={(option) => option?.label || ''}
                    onChange={(event, value) => handleRMItemClick(value)}
                    value={relationshipManager}
                    getOptionSelected={(option, value) =>
                      option.label === value.label
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <Grid container justify="center" spacing={2}>
              <Grid item>
                <FormControlLabel
                  control={<Switch checked={quarterly} onChange={handleQuarterlyChange} />}
                  label="Quarterly"
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={<Switch checked={annually} onChange={handleAnnuallyChange} />}
                  label="Annually"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={8}>
            <Grid container spacing={2} className={classes.calculatedValuesBorder}>
              <LoanVolumeChart data={loanVolumeReportStore.data} columns={loanVolumeReportStore.columns} lineData={lineData} />
              <Grid container item xs={12} justify="center">
                {lineData.map((item, index) => (
                  <div key={item.dataKey} style={{ textAlign: 'center', margin: '10px' }}>
                    <Switch
                      checked={item.checked}
                      onChange={() => onUpdateLineData(index)}
                      color="primary"
                    />
                    <span style={{ color: item.color }}>{item.label}</span>
                  </div>
                ))}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Grid container spacing={2} direction="column" >
              <Grid item xs={12}>
                <Typography variant={'h6'}>Filter By:</Typography>
              </Grid>
              {options.map((elem, index) => {
                if (elem.stackName === 'Ignore') {
                  return <h5 key={index} style={{ padding: '0 10px' }}>{elem.value}</h5>;
                }
                const isOutlined = selectedIndexes.find(selectedIndex => selectedIndex === elem.value) !== undefined;
                const bgColor = isOutlined ? 'transparent' : elem.color;
                const fgColor = isOutlined ? elem.color : 'white';
                return (
                  <Chip
                    key={index}
                    style={{ backgroundColor: bgColor, border: `1px solid ${elem.color}`, color: fgColor, margin: '0 0 10px 0' }}
                    label={elem.label}
                    onClick={() => onChipClick(elem.value)}
                    variant={isOutlined ? 'outlined' : undefined}
                    onDelete={() => { }}
                    className={classes.root}
                  />
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Layout>
  );
});
