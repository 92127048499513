import { Service } from '@roc/feature-app-core';

const url = {
  GET_REQUEST_STATUS: '/api/v1/loan/public/getPlaidRequestStatus?url=',
  GET_LINK_TOKEN: '/api/v1/vendor/public/getPlaidLinkToken?param=',
  STORE_PLAID_ACCOUNT_INFORMATION: '/api/v1/vendor/public/storePlaidAccountInformation?params=',
  STORE_PLAID_ACH_INFORMATION: '/api/v1/vendor/public/storePlaidACHInformation',
  GET_PLAID_ACH_LINK: '/api/v1/vendor/public/getPlaidAuthLink?param=',
  GET_ACH_DATA: '/api/v1/vendor/public/getACHData?param=',
  UPDATE_ACH_DATA: '/api/v1/vendor/public/updateACHData',
  ACH_DOCUSIGN: '/api/v1/vendor/public/achDocusign',
  ACH_LINK: '/api/v1/vendor/plaid/getACHLink',
  ACH_EMAIL: '/api/v1/vendor/plaid/sendACHLink',
  GET_LOAN_ACH_DATA: '/api/v1/vendor/plaid/getLoanACHData',
  SAVE_BANK_INFORMATION: '/api/v1/vendor/public/ach/saveBankInformation',
  UPDATE_SELECTED_BORROWER: '/api/v1/vendor/public/ach/updateSelectedBorrower',
  GET_ACH_DATA_FOR_BORROWER_PORTAL: '/api/v1/vendor/plaid/getACHDataForBorrowerPortal',
  SENT_BOFA_VALIDATION: '/api/v1/vendor/public/sentBofaValidation',
};

export class PlaidService extends Service {

  getLinkToken(param) {
    return this.get(`${url.GET_LINK_TOKEN}${param}`);
  }

  getPlaidACHLinkToken(param) {
    return this.get(`${url.GET_PLAID_ACH_LINK}${param}`);
  }

  getACHData(param) {
    return this.get(`${url.GET_ACH_DATA}${param}`);
  }

  getRequestStatus(id) {
    return this.get(`${url.GET_REQUEST_STATUS}${id}`);
  }

  storePlaidAccountInformation(publicToken, id) {
    return this.post(`${url.STORE_PLAID_ACCOUNT_INFORMATION}${id}`, publicToken, {
      'Content-Type': 'application/json',
    });
  }

  storePlaidACHInformation(request: any) {
    return this.post(`${url.STORE_PLAID_ACH_INFORMATION}`, request, {
      'Content-Type': 'application/json',
    });
  }

  updateACHData(request: any) {
    return this.post(`${url.UPDATE_ACH_DATA}`, request, {
      'Content-Type': 'application/json',
    });
  }

  submitDocusign(request: any) {
    return this.post(`${url.ACH_DOCUSIGN}`, request, {
      'Content-Type': 'application/json',
    });
  }

  getACHLink(loanId: any, borrowerId: any) {
    return this.get(`${url.ACH_LINK}?loanId=${loanId}&borrowerId=${borrowerId}`);
  }

  sendACHEmail(loanId: any, borrowerId: any) {
    return this.get(`${url.ACH_EMAIL}?loanId=${loanId}&borrowerId=${borrowerId}`);
  }

  saveBankInformation(data: any) {
    return this.post(url.SAVE_BANK_INFORMATION, data)
  }

  getLoanACHData(loanId: any) {
    return this.get(`${url.GET_LOAN_ACH_DATA}?loanId=${loanId}`);
  }

  updateSelectedBorrower(loanId: any, borrowerId: any){
    return this.post(url.UPDATE_SELECTED_BORROWER, { loanId, borrowerId })
  }

  getACHDataForBorrowerPortal(param) {
    return this.get(url.GET_ACH_DATA_FOR_BORROWER_PORTAL, {param});
  }

  sentBofaValidation(accountNumber: any, routingNumber: any, loanId: any) {
    return this.get(`${url.SENT_BOFA_VALIDATION}?accountNumber=${accountNumber}&routingNumber=${routingNumber}&loanId=${loanId}`);
  }
}
