import { SelectField, ConfirmDialog } from "@roc/ui";
import { ICellEditor, ICellEditorParams, ICellEditorComp, ColDef } from "ag-grid-community";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { useReceivablesDashboardStore } from '../../hooks/useReceivablesDasboardStore';

const useStyles = makeStyles((theme: Theme) => ({
  selectField: {
    borderRadius: '0',
    '& .MuiSelect-root': {
      paddingTop: '11px',
      paddingBottom: '11px',
      fontSize: '14px',
    },
  },
}));

export const StatusEditorReceivablesBills = forwardRef<ICellEditor, ICellEditorParams>((props, ref) => {
  const { value } = props;
  const { receivablesBillsStore } = useReceivablesDashboardStore();
  const [editableValue, setEditableValue] = useState(value);
  const [prevEditableValue, setPrevEditableValue] = useState(value);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const classes = useStyles();

  useImperativeHandle(ref, () => ({
    getValue: () => {
      return editableValue;
    },
  }));

  useEffect(() => {
    if (inputRef.current) inputRef.current.focus();
  }, []);

  const handleCancel = () => {
    setEditableValue(prevEditableValue);
    setOpenConfirmModal(false)
  }

  return (
    <>
      {
        editableValue !== 'Paid' ? (
          <SelectField
            options={[
              { label: 'Billed', value: 'Billed' },
              { label: 'Paid', value: 'Paid' },
              { label: 'Rejected', value: 'Rejected' },
            ]}
            testId={'foundation'}
            value={editableValue}
            onChange={(value) => {
              setPrevEditableValue(editableValue);
              setEditableValue(value);
              setOpenConfirmModal(true);
            }}
            fullWidth
            variant="outlined"
            className={classes.selectField}
          />
        ) : (
          <SelectField
            options={[
              { label: 'Paid', value: 'Paid' },
            ]}
            testId={'foundation'}
            value={editableValue}
            onChange={(value) => {
              setEditableValue(value);
            }}
            fullWidth
            variant="outlined"
            className={classes.selectField}
          />
        )
      }
      <ConfirmDialog
        open={openConfirmModal}
        title={editableValue === 'Paid' ? 'Mark bill as paid' : 'Reject bill'}
        body={editableValue === 'Paid' ? 'Are you sure you want to mark this bill as paid?' : 'Are you sure you want to reject this bill?'}
        confirmButtonText={'Yes'}
        handleCancel={handleCancel}
        handleClose={handleCancel}
        handleConfirm={async () => {
          await receivablesBillsStore.performAction(props?.data?.recordId, editableValue);
          receivablesBillsStore.counterPartyExpenseGridStore.resetAndFetchGridData();
          setOpenConfirmModal(false);
        }}
      />
    </>


  );
});
