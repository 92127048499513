import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { CurrencyField } from '@roc/ui';
import { observer } from 'mobx-react';
import { FundingTemplateStore } from '../../../../../stores/documentForms/fundingTemplateStore';

export type LenderFeesFormProps = {
  store: FundingTemplateStore;
};

const useStyles = makeStyles({
  title: {
    fontWeight: 'bold',
  },
});

export const LenderFeesForm = observer((props: LenderFeesFormProps) => {
  const { store } = props;
  const { fundingTemplate } = store;

  const classes = useStyles();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography color="primary" className={classes.title}>
          Lender Fees
        </Typography>
      </Grid>
      <Grid item xs={12} md={4}>
        <Grid container spacing={2} direction="row">
          <Grid item xs={12}>
            <CurrencyField
              label="Lender Origination Fee"
              standaloneLabel
              value={fundingTemplate?.lenderPointsAmount}
              variant="outlined"
              testId="lenderPointsAmount"
              fullWidth
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <CurrencyField
              label="Lender UW/Processing Fee"
              standaloneLabel
              value={fundingTemplate?.lenderProcessingFee}
              variant="outlined"
              testId="lenderProcessingFee"
              fullWidth
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <CurrencyField
              label="Lender Yield Premium Amount"
              standaloneLabel
              value={fundingTemplate?.lenderYieldPremiumAmount}
              variant="outlined"
              testId="lenderYieldPremiumAmount"
              fullWidth
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <CurrencyField
              label="Lender Fee Total"
              standaloneLabel
              value={fundingTemplate?.lenderFeesTotal}
              variant="outlined"
              testId="lenderFeesTotal"
              fullWidth
              disabled
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
});

export default LenderFeesForm;
