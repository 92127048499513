import { GlobalStore } from "@roc/feature-app-core";
import { flow, makeObservable } from "mobx";
import { TitleAgentService } from "../../../services/documentForms/titleAgentService";
import { DocumentStatus, WIMBA_NAME } from "../../../constants";
import { Document } from '@roc/feature-types';
import { TitleAgentFormBaseStore } from "./titleAgentFormBaseStore";
import { LoanStore } from "@roc/feature-loans";
import { DocumentStore } from '@roc/feature-documents';

const form = {
  fields: {
    name: {
      value: '',
      error: null,
      rule: 'required',
    },
    contactName: {
      value: '',
      error: null,
      rule: '',
    },
    phone: {
      value: '',
      error: null,
      rule: 'required|regex:/^\\d{3}-?\\d{3}-?\\d{4}$/|alpha_dash',
      message: 'Phone must be a number with the format xxx-xxx-xxxx',
    },
    address: {
      value: '',
      error: null,
      rule: '',
    },
    email: {
      value: '',
      error: null,
      rule: 'required|email',
    },
    state: {
      value: '',
      error: null,
      rule: '',
    },
    streetName: {
      value: '',
      error: null,
      rule: '',
    },
    streetNumber: {
      value: '',
      error: null,
      rule: '',
    },
    zipCode: {
      value: '',
      error: null,
      rule: '',
    },
    city: {
      value: '',
      error: null,
      rule: '',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export class TitleAgentOtherFormStore extends TitleAgentFormBaseStore {
  constructor(
    globalStore: GlobalStore,
    loanStore: LoanStore,
    documentStore: DocumentStore,
  ) {
    super(form, globalStore, loanStore, documentStore);
    this.titleAgentService = new TitleAgentService();
    makeObservable(this, {
      submitData: flow,
    })
  }

  *submitData(document: Document, loanId: string, wimbaCompanyId: any) {
    this.runFormValidationWithMessage();
    if (this.form.meta.isValid) {
      try {
        const { phone, ...formValues } = this.getFormValues();
        if (formValues.name != WIMBA_NAME) {
          const loanDocument = {
            ...document, status: DocumentStatus.PENDING,
          };
          const company = this.getTitleCompany(wimbaCompanyId);
          this.submit(loanDocument, loanId, company, formValues, phone);
        } else {
          this.globalStore.notificationStore.showErrorNotification({
            message: `${WIMBA_NAME} ${' cannot be the name for other title provider'}`,
          });
        }
      } catch (error) {
        this.globalStore.notificationStore.showErrorNotification({
          message: 'Error while submitting title agent form',
        });
      }
    }
  }

  private getTitleCompany(wimbaCompanyId: any) {
    const titleCompany = this.loanStore.loanDetails?.titleCompany;
    const titleCompanyId = titleCompany?.companyId;

    if (wimbaCompanyId && wimbaCompanyId !== titleCompanyId) {
      return titleCompany;
    } else {
      // Create a new company if the current title company is Wimba
      return null;
    }
  }



}