export const loanTermsTermColsStb = {
  values: [
    {
      fieldname: "Lender",
      mapvalue: "lenderName",
      specialtype: "CustomLink",
    },
    {
      fieldname: "Loan Status",
      mapvalue: "status",
      specialtype: "TextBox",
    },
    {
      fieldname: "Note Int Rate",
      mapvalue: "rate",
      specialtype: "percentage",
    },
    { fieldname: "Prepayment Penalty Period (Months)", mapvalue: "prepayPenaltyMonths", specialtype: "Integer" },
    { fieldname: "Total Orig. Fee", mapvalue: "sfrTotalOriginationFee", specialtype: "percentage" },
    { fieldname: "Total Fees", mapvalue: "totalFees", specialtype: "currency" },
    {
      fieldname: "Loan Amount",
      mapvalue: "amount",
      specialtype: "currency",
    },
    {
      fieldname: "Loan Subtype",
      mapvalue: "loanSubtype",
      specialtype: "DropDown",
    },
    {
      fieldname: "Spread on Pricing Date",
      mapvalue: "spreadRate",
      specialtype: "percentage",
    },
    { fieldname: "Prepayment Description", mapvalue: "prepaymentDescription", specialtype: "DropDown" },
    { fieldname: "Roc Orig. Fee", mapvalue: "rocPointsIn", specialtype: "percentage" },
    { fieldname: "Prepayment Description", mapvalue: "prepaymentDescription", specialtype: "DropDown" },
    {
      fieldname: "Loan Purpose",
      mapvalue: "loanPurpose",
      specialtype: "DropDown",
    },
    {
      fieldname: "Loan Rate-Type",
      mapvalue: "rateType",
      specialtype: "DropDownDisable",
    },
    {
      fieldname: "Current Index Value For Loan",
      mapvalue: "currentSwapRate",
      specialtype: "percentage",
    },
    { fieldname: "Underwritten LTV", mapvalue: "aggregateLoanUnderwrittenToAsIs", specialtype: "percentage" },
    { fieldname: "Broker Orig. Fee", mapvalue: "brokerPointsIn", specialtype: "percentage" },
    {
      fieldname: "Insurance Escrow Collected at Close",
      mapvalue: "insuranceEscrowAtClose",
      specialtype: "currency",
    },
    {
      fieldname: "Borrower Cash Out at Close",
      mapvalue: "borrowerCashAtClose",
      specialtype: "currency",
    },
    {
      fieldname: "Amortization",
      mapvalue: "amortization",
      specialtype: "DropDown",
      testId: "Amortization",
    },
    {
      fieldname: "Index Value on Pricing Date",
      mapvalue: "swapRateAtPricing",
      specialtype: "percentage",
    },
    { fieldname: "Loan LTC", mapvalue: "aggregateLoanToCurrentCost", specialtype: "percentage" },
    { fieldname: "Broker/Lender Fees", mapvalue: "totalLenderFees", specialtype: "currency" },
    {
      fieldname: "Tax Escrow Collected at Close",
      mapvalue: "taxEscrowAtClose",
      specialtype: "currency",
    },
    {
      fieldname: "Monthly P&I Payment",
      mapvalue: "interestPayment",
      specialtype: "currency",
    },
    {
      fieldname: "Int Only Periods (Months)",
      mapvalue: "interestOnlyMonths",
      specialtype: "Integer",
    },
    {
      fieldname: "Pricing Index",
      mapvalue: "swapIndex",
      specialtype: "DropDown"
    },
    { fieldname: "Origination Source", mapvalue: "originationSource", specialtype: "Integer" },
    { fieldname: "ROC Fees", mapvalue: "totalRocFees", specialtype: "currency" },
    {
      fieldname: "Appraisal Reviewed",
      mapvalue: "appraisalReviewed",
      htmlfield: "checkbox",
      specialtype: "icon",
    },
    {
      fieldname: "P&I Reserves at close (Months)",
      mapvalue: "interestReserveMonths",
      specialtype: "Integer",
    },
    {
      fieldname: "Recourse",
      mapvalue: "recourseStructure",
      specialtype: "DropDown",
      testId: "recourseStructure",
    },
    {
      fieldname: "Orig. Loan Terms (Months)",
      mapvalue: "duration",
      specialtype: "DropDown",
    },
    { fieldname: "Program Type", mapvalue: "programType", specialtype: "DropDown" },
    { fieldname: "Lender Orig. Fee", mapvalue: "sfrLenderPointsIn", specialtype: "Percent" },
    {
      fieldname: "Background Reviewed",
      mapvalue: "backgroundReviewed",
      htmlfield: "checkbox",
      specialtype: "icon",
    },
    {
      fieldname: "P&I Reserves at close",
      mapvalue: "interestReserve",
      specialtype: "currency",
    },
    {
      fieldname: "Pledge of Shares",
      mapvalue: "pledgeOfShares",
      specialtype: "IconCheckBox"
    },
    {
      fieldname: "Orig. Amortization Terms (Months)",
      mapvalue: "originalAmortizationTerms",
      specialtype: "Integer",
    },
    { fieldname: "Lender Premium", mapvalue: "lenderPremium", specialtype: "TextBox" },
    {
      fieldname: "Partners Approval Required",
      mapvalue: "partnersApprovalRequired",
      specialtype: "icon"
    },
    {
      fieldname: "Credit Reviewed",
      mapvalue: "creditReviewed",
      htmlfield: "checkbox",
      specialtype: "icon",
    },
  ]
};

export const collateralsColsSFR = {
  values:
    [
      { fieldname: "Property Type", mapvalue: "useCode", specialtype: "DropDown" },
      { fieldname: "Number of Units", mapvalue: "units", specialtype: "TextBox" },
      { fieldname: "Occupancy (%)", mapvalue: "occupancy", specialtype: "percentage" },
      { fieldname: "Property Management", mapvalue: "managerType", specialtype: "DropDown" },
      { fieldname: "Arms Length", mapvalue: "rentalArmsLength", specialtype: "Radio" },
      { fieldname: "Acquisition price", mapvalue: "acquisitionPrice", specialtype: "currency" },
      { fieldname: "WholeSale Fee", mapvalue: "wholesaleFee", specialtype: "currency" },
      { fieldname: "WholeSale %", mapvalue: "wholesalePercentage", specialtype: "percentage" },
      { fieldname: "Arms Comment", mapvalue: "rentalArmsLengthComment", specialtype: "TextBox" },
      { fieldname: "Seller's Credit Amount", mapvalue: "sellerCreditAmount", specialtype: "currency" },
      { fieldname: "Appraised As-Is Value", mapvalue: "propertyValuation", specialtype: "currency" },
      { fieldname: "Annual Gross Rent", mapvalue: "annualGrossRent", specialtype: "currency" },
      { fieldname: "Annual Debt Service", mapvalue: "annualDebtService", specialtype: "currency" },
      { fieldname: "AC", mapvalue: "approved", specialtype: "IconCheckBox" },
      { fieldname: "Rural Census", mapvalue: "ruralProperty", specialtype: "TextBox" }
    ]
}

export const collateralsColsRentalPortfo = {
  values: [
    { fieldname: "Address", mapvalue: "addressLine1", specialtype: "customlink" },
    { fieldname: "Unit #", mapvalue: "units", specialtype: "TextBox" },
    { fieldname: "City", mapvalue: "city", specialtype: "TextBox" },
    { fieldname: "State", mapvalue: "state", specialtype: "TextBox" },
    { fieldname: "ZIP", mapvalue: "zipCode", specialtype: "TextBox" },
    { fieldname: "Property Type", mapvalue: "useCode", specialtype: "DropDown" },
    { fieldname: "Annual Gross Rent", mapvalue: "annualGrossRent", specialtype: "currency" },
    { fieldname: "Annual PITIA", mapvalue: "annualPITIA", specialtype: "currency" },
    { fieldname: "Annual NOI", mapvalue: "annualNOI", specialtype: "currency" },
    { fieldname: "Annual NCF", mapvalue: "annualNCF", specialtype: "currency" },
    { fieldname: "Annual Debt Service", mapvalue: "annualDebtService", specialtype: "currency" },
    { fieldname: "Net DSCR", mapvalue: "netDSCR", specialtype: "TextBox" },
    { fieldname: "Most Recent Valuation", mapvalue: "propertyValuation", specialtype: "currency" },
    { fieldname: "Property Loan Amount", mapvalue: "propertyLoanAmount", specialtype: "currency" },
  ]
};

export const generalStatsColsTerm = {
  values: [
    { fieldname: "Property Count", mapvalue: "propertyCount", specialtype: "Integer" },
    { fieldname: "Annual Gross Rent", mapvalue: "annualGrossRent", specialtype: "currency" },
    { fieldname: "NCF DSCR", mapvalue: "netDSCR", specialtype: "TextBox" },
    { fieldname: "Most Recent Valuation", mapvalue: "mostRecentValuation", specialtype: "currency" },
    { fieldname: "Annual Insurance", mapvalue: "annualInsurance", specialtype: "currency" },
    { fieldname: "PITIA DSCR", mapvalue: "pitiaDSCR", specialtype: "TextBox" },
    { fieldname: "Total Cost Basis", mapvalue: "totalCostBasis", specialtype: "currency" },
    { fieldname: "PITIA", mapvalue: "pitia", specialtype: "currency" },
    { fieldname: "NOI DSCR", mapvalue: "noiDSCR", specialtype: "TextBox" },
    { fieldname: "Total Debt Payoff Amount", mapvalue: "totalDebtPayoffAmount", specialtype: "currency" },
    { fieldname: "NOI", mapvalue: "noi", specialtype: "currency" },
    { fieldname: "Amortizing NCF DSCR", mapvalue: "amortizingNCFDSCR", specialtype: "TextBox" },
    { fieldname: "Total Debt Payoff Amount", mapvalue: "totalDebtPayoffAmount", specialtype: "currency" },
    { fieldname: "NCF DSCR", mapvalue: "netDSCR", specialtype: "TextBox" },
    { fieldname: "App. Deal Econ.", mapvalue: "approvedExitStrategy", specialtype: "IconCheckBox" },
  ]
};
