import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
export const BorrowerDetailsVerificationDocusignCallback = observer(() => {
  useEffect(() => {
    const { search } = location || {};
    if (search) {
      const { origin } = window.parent.location;
      const parsed = search.replace('?', '').split('=');
      if (parsed[1] === 'signing_complete') {
        window.parent.postMessage(
          'borrower-details-verification-docusign-done',
          origin
        );
      } else {
        window.parent.postMessage(
          'borrower-details-verification-docusign-incomplete',
          origin
        );
      }
    }
  }, []);
  return <></>;
});