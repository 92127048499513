import { Box, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Theme, Typography } from "@material-ui/core";
import { observer } from "mobx-react";
import { fieldTypes } from "./appraisalReviewConstants";
import { AppraisalReviewStore } from "../stores/appraisalReviewStore";
import { AutocompleteField, CurrencyField, DateField, NumberFormat, SelectField, TextField } from "@roc/ui";
import { formatDate } from "@roc/feature-utils";
import { AppraisalReviewType, appraisalTableStyles } from "../utils/utils";
import { AppraisalReviewTermForm } from "./appraisalReviewTermForm";

const SummaryDataField = observer(({ item, onChange, store }) => {
  switch (item.type) {
    case fieldTypes.TEXT:
      return <TextField
        testId={item.mapping}
        value={item.value}
        onChange={(e) => onChange(item.mapping, e.target.value)}
        style={{
          width: '100%'
        }}
        variant="outlined"
        disabled={item.disabled}
      />
    case fieldTypes.CURRENCY:
      return <CurrencyField
        testId={item.mapping}
        disabled={item.disabled}
        variant='outlined'
        value={item.value}
        onChange={(name, value) => onChange(item.mapping, value)}
        fullWidth
        allowNegative={true}
      />
    case fieldTypes.DATE:
      return <DateField
        testId={item.mapping}
        disabled={item.disabled}
        inputVariant="outlined"
        format='MM/dd/yyyy'
        value={item.value ? new Date(item.value) : null}
        onChange={(value) => onChange(item.mapping, value ? formatDate(value, 'MM/dd/yyyy') : value)}
        fullWidth
      />
    case fieldTypes.DROPDOWN:
      return <SelectField
        disabled={item.disabled}
        standaloneLabel
        value={item.value}
        options={item?.options}
        onChange={value => onChange(item.mapping, value)}
        variant='outlined'
        fullWidth
        testId={item.value}
      />
    case fieldTypes.NUMERIC:
      return <NumberFormat
        testId={item.mapping}
        disabled={item.disabled}
        variant='outlined'
        customInput={TextField}
        value={item.value}
        onValueChange={values => {
          onChange &&
            onChange(item.mapping, values.floatValue ? values.floatValue : values.value);
        }}
        fullWidth
        thousandSeparator={true}
        allowNegative={false}
      />
    case fieldTypes.DROPDOWN_DYNAMIC_OPTIONS:
      return <SelectField
        disabled={item.disabled}
        standaloneLabel
        value={item.value}
        options={item.getOptions(store, item.mapping) ?? []}
        onChange={value => item.onChange ? item.onChange(store, item.mapping, value) : onChange(item.mapping, value)}
        variant='outlined'
        fullWidth
        testId={`${item.value}_${store.dropDownSelectionCounter}`}
      />
    case fieldTypes.APPRAISER_NAME:
      return <AutocompleteField
        label=""
        placeHolder='Select Appraiser Firm'
        options={store.getDynamicDropdownOptions(item.mapping)}
        value={store.currentFirmLabel ?? ''}
        onChange={(newValue) => {
          store.handleAppraiserFirmChange(newValue)
        }}
        onSelect={(option: any) => {
          store.handleAppraiserFirmSelected(item.mapping, option)
        }}
      />
  }
})

const AppraisalSummaryDataTitle = () => {
  const classes = appraisalTableStyles();
  return (
    <Grid container >
      <Grid item xs={12}>
        <Typography className={classes.titleCell}>
          Appraisal Summary Data
        </Typography>
      </Grid>
    </Grid>
  )
}

const AppraisalSummaryTable = observer(({ rows, onChange, store }) => {
  const classes = appraisalTableStyles();

  return (
    <Grid item xs={12} md={6}>
      <TableContainer>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.headerCell}>
                Item
              </TableCell>
              <TableCell className={classes.headerCell}>
                Review
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={classes.bodyRow}>
            {rows?.map((item, index) => {
              return (
                <TableRow>
                  <TableCell style={{ padding: '10px' }}>
                    <Typography className={classes.fixedWidthText}>
                      {item?.title}
                    </Typography>
                  </TableCell>
                  <TableCell style={{ padding: '10px' }}>
                    <SummaryDataField item={item} onChange={onChange} store={store} />
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  )
})

interface SummaryDataProps {
  store: AppraisalReviewStore;
}

export const getAppraisalForm = (store: AppraisalReviewStore) => {


  const handleOnChangeFirstColumn = (name, value) => {
    store.onChangeSummaryDataFirstColumn(name, value);
  }

  const handleOnChangeSecondColumn = (name, value) => {
    store.onChangeSummaryDataSecondColumn(name, value);
  }

  switch (store.reviewType) {
    case AppraisalReviewType.BRIDGE:
      return (
        <Grid container direction="row">
          <AppraisalSummaryTable rows={store.summaryDataFirstCol} onChange={handleOnChangeFirstColumn} store={store} />
          <AppraisalSummaryTable rows={store.summaryDataSecondCol} onChange={handleOnChangeSecondColumn} store={store} />
        </Grid>
      )
    case AppraisalReviewType.TERM:
      return <AppraisalReviewTermForm store={store} />
  }
}

export const AppraisalReviewSummaryData = observer(({ store }: SummaryDataProps) => {
  const classes = appraisalTableStyles();
  return (
    <Box style={{ paddingTop: '15px' }}>
      <div className={classes.tableContainer}>
        <AppraisalSummaryDataTitle />
        {getAppraisalForm(store)}
      </div>
    </Box>
  )
});