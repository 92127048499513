import { Card, CardContent, CardHeader, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { useDropzone } from 'react-dropzone';
import { Box } from '@material-ui/core';
import { Close, CloudUpload } from '@material-ui/icons';
import { FC, MouseEventHandler, SyntheticEvent } from 'react';
import { Button, IconButton } from '@roc/ui';
import Grid from '@material-ui/core/Grid';

const useFileUploadStyles = makeStyles((theme: Theme) => ({
  card: {
    marginTop: theme.spacing(2)
  },
  cardContent: {
    paddingTop:0
  },
  fileLine:{
    display:'flex',
    alignItems:'center'
  },
  dragArea: {
    border: '2px dotted #427ED1',
    width: '100%',
    minHeight: '10vh',
    backgroundColor: '#EAF4FA',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: 20,
    borderRadius: 5,
    cursor: 'pointer',
  },
  icon: {
    minWidth: '20px',
    minHeight: '20px',
    width: '3vw',
    height: '3vw',
    color: '#4B89DA',
  },
  fileContainer: {
    border: '1px solid grey',
    backgroundColor: '#EAF4FA',
    padding: '10px 20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '10px 0',
    borderRadius: 5,
  },

  fileName: {
    marginLeft: 5,
    marginRight: 2,
    flexGrow: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  removeButton: {
    marginLeft: theme.spacing(2),
    alignSelf: 'flex-end',
    alignItems: 'flex-end',
    float: 'right',
  },
}));

interface DriversLicenseUploadProps {
  title?: string,
  file: File | null;
  onChange: (file: File | null) => void;
}

export const DriversLicenseUpload: FC<DriversLicenseUploadProps> = ({ title = 'Document Upload - Passport Image*', file, onChange }) => {
  function onDrop(newFiles: File[]) {
    onChange(newFiles?.[0]);
  }

  function handleOnRemove(event: SyntheticEvent) {
    event.stopPropagation();
    onChange(null)
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
  });

  const classes = useFileUploadStyles();
  return (
    <Card variant="outlined" className={classes.card}>
      <CardHeader title={title} />
      <CardContent className={classes.cardContent}>
        <Box {...getRootProps()} className={classes.dragArea}>
          <input {...getInputProps()} />
          <CloudUpload className={classes.icon} />
          {file ? (
            <Box className={classes.fileLine}>
                <Typography>{file?.name}</Typography>
                <Button
                  testId={`removeFile-${file?.name}`}
                  size="small"
                  variant="outlined"
                  className={classes.removeButton}
                  onClick={handleOnRemove}
                >
                  REMOVE <Close />
                </Button>
            </Box>
          ) : isDragActive ? (
            <p>Drop the file(s) here...</p>
          ) : (
            <>
              <p>Drag File to Upload</p>
              <p>or Browse file</p>
            </>
          )}
        </Box>
        <Box></Box>
      </CardContent>
    </Card>
  );
};
