import { Page } from '@roc/feature-app-core';
import { Route } from 'react-router';
import { DocumentLibrary } from '../documentLibrary';

export const getDocumentLibraryRoutesConfig = (
  basePath: string,
  baseUrl: string
) => {
  return {
    documentLibrary: {
      path: `${basePath}/document-library`,
      url: `${baseUrl}/document-library`,
      documentTitle: 'Document Library',
    },
  };
};

export const getDocumentLibraryRoutes = (basePath: string, baseUrl: string) => {
  const config = getDocumentLibraryRoutesConfig(basePath, baseUrl);
  return {
    documentLibrary: (
      <Route exact path={config.documentLibrary.path}>
        <Page routeDefinition={config.documentLibrary}>
          <DocumentLibrary />
        </Page>
      </Route>
    ),
  };
};
