import { Box, Typography } from '@material-ui/core';
import React from 'react';

import NotFoundImageV2 from './../../../assets/404_v2.svg';

export const NotFoundV2 = ({ style = {} }) => {
  return (
    <Box display="flex" justifyContent="center" height="100%" style={style}>
      <Box display="flex" flexDirection="column" height="100%" alignItems="center" justifyContent="center" maxWidth={800}>
        <Box display="flex" alignItems="center" justifyContent="center" mt={4} mb={2}>
          <Typography variant="h4" align='center'>
            Sorry, page not found!
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="center" mb={4}>
          <Typography variant="body2" align='center'>
            Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve mistyped the URL? Be sure to check your spelling.
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="center" my={4}>
          <img
            alt="Internal Server Error"
            src={NotFoundImageV2}
            style={{
              width: '80%',
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};
