import { Grid, Typography } from '@material-ui/core';
import { Card } from '@roc/ui';
import { formatCurrency } from '@roc/ui/utils';
import { observer } from 'mobx-react';
import {
  AddressNoLicensedText,
  AnnualExpenses,
  AnnualIncome,
  AnnualNoi,
  UnitsOccupied,
  UnitsOccupiedHave12MonthsLease,
} from '../../components/formComponents';
import { SinglePropertyStore } from '../../stores';

interface PropertyInformationSinglePropertyProps {
  store: SinglePropertyStore;
}

export const PropertyInformationSingleProperty = observer(
  ({ store }: PropertyInformationSinglePropertyProps) => {
    const { pricerStore } = store;
    const propertyData = pricerStore.getProperty();

    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h4">Property</Typography>
            <br />
          </Grid>
        </Grid>

        <br />

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card title={'Property Details'}>
              <Grid item container direction="row" spacing={2}>
                <Grid item xs={6} md={3}>
                  <Typography>
                    <b>Property Type</b>
                  </Typography>
                </Grid>
                <Grid item xs={6} md={3}>
                  <Typography>{propertyData.propertyType}</Typography>
                </Grid>
                <Grid item xs={6} md={3}>
                  <Typography>
                    <b>Number of Units</b>
                  </Typography>
                </Grid>
                <Grid item xs={6} md={3}>
                  <Typography>{propertyData.units}</Typography>
                </Grid>
                <Grid item xs={6} md={3}>
                  <Typography>
                    <b>Estimated As Is Value</b>
                  </Typography>
                </Grid>
                <Grid item xs={6} md={3}>
                  <Typography>
                    {formatCurrency(propertyData.propertyValuation)}
                  </Typography>
                </Grid>
                <Grid item xs={6} md={3}>
                  <Typography>
                    <b>Gross Monthly Rent</b>
                  </Typography>
                </Grid>
                <Grid item xs={6} md={3}>
                  <Typography>
                    {formatCurrency(propertyData.monthlyGrossRent)}
                  </Typography>
                </Grid>
                <Grid item xs={6} md={3}>
                  <Typography>
                    <b>Annual Taxes</b>
                  </Typography>
                </Grid>
                <Grid item xs={6} md={3}>
                  <Typography>
                    {formatCurrency(propertyData.annualTaxes)}
                  </Typography>
                </Grid>
                <Grid item xs={6} md={3}>
                  <Typography>
                    <b>Annual Insurance</b>
                  </Typography>
                </Grid>
                <Grid item xs={6} md={3}>
                  <Typography>
                    {formatCurrency(propertyData.annualInsurance)}
                  </Typography>
                </Grid>
                <Grid item xs={6} md={3}>
                  <Typography>
                    <b>Annual HOA Dues</b>
                  </Typography>
                </Grid>
                <Grid item xs={6} md={3}>
                  <Typography>
                    {formatCurrency(propertyData.annualHOA)}
                  </Typography>
                </Grid>
                <Grid item xs={6} md={3}>
                  <Typography>
                    <b>Annual Utilities Expenses</b>
                  </Typography>
                </Grid>
                <Grid item xs={6} md={3}>
                  <Typography>
                    {formatCurrency(propertyData.totalAnnualUtilities)}
                  </Typography>
                </Grid>
                <Grid item xs={6} md={3}>
                  <Typography>
                    <b>Annual Repair/Maintenance Expenses</b>
                  </Typography>
                </Grid>
                <Grid item xs={6} md={3}>
                  <Typography>
                    {formatCurrency(propertyData.totalAnnualRepairsMaintenance)}
                  </Typography>
                </Grid>
                <Grid item xs={6} md={3}>
                  <Typography>
                    <b>Annual Property Management Fees</b>
                  </Typography>
                </Grid>
                <Grid item xs={6} md={3}>
                  <Typography>
                    {formatCurrency(
                      propertyData.totalAnnualPropertyManagementFees
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={6} md={3}>
                  <Typography>
                    <b>Target LTV</b>
                  </Typography>
                </Grid>
                <Grid item xs={6} md={3}>
                  <Typography>{`${propertyData.targetLTV}%`}</Typography>
                </Grid>
                <Grid item xs={6} md={3}>
                  <Typography>
                    <b>Loan Purpose</b>
                  </Typography>
                </Grid>
                <Grid item xs={6} md={3}>
                  <Typography>{propertyData.loanPurpose}</Typography>
                </Grid>
                <Grid item xs={6} md={3}>
                  <Typography>
                    <b>Purchase Price</b>
                  </Typography>
                </Grid>
                <Grid item xs={6} md={3}>
                  <Typography>
                    {formatCurrency(propertyData.acquisitionPrice)}
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>

        <br />

        <Grid
          container
          direction="row"
          spacing={4}
          style={{
            width: '100%',
            border: 'solid 3px',
            margin: '32px 0px 32px 0px',
            borderColor: '#CECECE',
            borderRadius: '8px',
            padding: '3vw 5vw',
          }}
        >
          <AnnualIncome store={store} />
          <AnnualExpenses store={store} />
          <AnnualNoi store={store} required={false} />
        </Grid>

        <br />

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6">Property Address</Typography>
            <br />
          </Grid>
        </Grid>
        <Grid item container md={12} lg={8}>
          <Grid item>
            <AddressNoLicensedText store={store.pricerStore} />
          </Grid>
        </Grid>

        <br />
      </>
    );
  }
);
