import { action, computed, flow, makeObservable, observable } from 'mobx';
import { GlobalStore } from '@roc/feature-app-core';
import { BridgeProfitAndDscrCalculatorService } from '../services';
import { BridgeProfitAndDscrCalculatorFormStore } from './bridgeProfitAndDscrCalculatorFormStore';
import { formatDate } from '@roc/feature-utils';

export class BridgeProfitAndDscrCalculatorStore {
  globalStore: GlobalStore;
  protected bridgeProfitAndDscrCalculatorService: BridgeProfitAndDscrCalculatorService;
  bridgeProfitAndDscrCalculatorFormStore: BridgeProfitAndDscrCalculatorFormStore;
  public saved: boolean;
  public selectedTabIndex: number;
  public calculatedValues: any;
  isDownloading: boolean;
  isDisabled: boolean;

  constructor(globalStore: GlobalStore) {
    this.globalStore = globalStore;
    this.bridgeProfitAndDscrCalculatorFormStore = new BridgeProfitAndDscrCalculatorFormStore(
      globalStore
    );
    this.bridgeProfitAndDscrCalculatorService = new BridgeProfitAndDscrCalculatorService();
    makeObservable(this, {
      selectedTabIndex: observable,
      setSelectedTabIndex: action,
      calculateResult: flow,
      isValidForm: computed,
      calculatedValues: observable,
      totalCosts: computed,
      totalProfitOnFlip: computed,
      setIsDisabled: action,
      setIsDownloading: action,
      isDisabled: observable,
      isDownloading: observable
    }, { autoBind: true });
    this.initialize();
  }

  initialize() {
    this.selectedTabIndex = 0;
    this.calculatedValues = {};
  }

  reset() {
    this.initialize();
  }

  setSelectedTabIndex(selectedTabIndex) {
    this.selectedTabIndex = selectedTabIndex;
  }

  generateCalculateRequestObject() {
    const mainFields = this.bridgeProfitAndDscrCalculatorFormStore.form.fields;
    const refiExitStrategyFields = this.bridgeProfitAndDscrCalculatorFormStore.refinanceExitStrategyFormStore.form.fields;
    const saleExitStrategyFields = this.bridgeProfitAndDscrCalculatorFormStore.saleExitStrategyFormStore.form.fields;
    const refiLoanPurposeFields = this.bridgeProfitAndDscrCalculatorFormStore.refinanceLoanPurposeFormStore.form.fields;

    let refiLoanPurposeRequest = {};
    if (mainFields.loanPurpose.value === 'Refi') {
      refiLoanPurposeRequest = {
        purchaseDate: refiLoanPurposeFields.purchaseDate.value ? formatDate(refiLoanPurposeFields.purchaseDate.value, 'MM/dd/yyyy') : null,
        payoffAmount: refiLoanPurposeFields.payoffAmount.value,
        loanClosingDate: refiLoanPurposeFields.loanClosingDate.value ? formatDate(refiLoanPurposeFields.loanClosingDate.value, 'MM/dd/yyyy') : null,
      };
    }

    let saleExitStrategyRequest = {};
    if (mainFields.exitStrategy.value === 'Sale') {
      saleExitStrategyRequest = {
        interestRate: saleExitStrategyFields.interestRate.value,
        points: saleExitStrategyFields.points.value,
        mortgageTaxPercent: saleExitStrategyFields.mortgageTax.value,
        transferTaxPercent: saleExitStrategyFields.transferTax.value,
        mansionTaxPercent: saleExitStrategyFields.mansionTax.value,
        titleInsurancePercent: saleExitStrategyFields.titleInsurance.value,
        brokerFeePercent: saleExitStrategyFields.brokerFee.value,
        propertyTaxAndMaintenancePercent: saleExitStrategyFields.propertyTaxAndMaintenance.value,
        ...refiLoanPurposeRequest
      };
    };



    let refiExitStrategyRequest = {};
    if (mainFields.exitStrategy.value === 'Refinance') {
      refiExitStrategyRequest = {
        exitLoanRate: refiExitStrategyFields.exitLoanRate.value,
        rent: refiExitStrategyFields.monthlyRent.value,
        annualTaxes: refiExitStrategyFields.annualTaxes.value,
        annualHOADues: refiExitStrategyFields.annualHOADues.value,
        annualInsurance: refiExitStrategyFields.annualInsurance.value,
      };
    }

    return {
      loanPurpose: mainFields.loanPurpose.value,
      purchasePrice: mainFields.acquisition.value,
      value: mainFields.asIsValue.value,
      completedCapitalImprovements: mainFields.capitalImprovements.value,
      renovationBudget: mainFields.renovationBudget.value,
      salesPrice: mainFields.afterRepairValue.value,
      loanTerm: mainFields.loanTerm.value,
      initialLoanAmount: this.getCalculateInitialLoanAmount(),
      constructionHoldback: this.getCalculateConstructionHoldback(),
      loanAmount: this.getCalculateTotalLoanAmount(),
      exitStrategy: mainFields.exitStrategy.value,
      ...refiExitStrategyRequest,
      ...saleExitStrategyRequest,
      useNewDealEconomicsCalculations: true,
    };
  }

  *calculateResult() {
    try {
      const requestObject = this.generateCalculateRequestObject();
      const response = yield this.bridgeProfitAndDscrCalculatorService.calculate(requestObject);
      this.calculatedValues = response.data;
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error in Bridge Profit and DSCR calculated values retrieval',
      });
    }
  }

  get isValidForm() {
    if (this.bridgeProfitAndDscrCalculatorFormStore.exitStrategy === 'Sale') {
      if (this.bridgeProfitAndDscrCalculatorFormStore.loanPurpose === 'Refi') {
        return this.bridgeProfitAndDscrCalculatorFormStore.form.meta.isValid
          && this.bridgeProfitAndDscrCalculatorFormStore.saleExitStrategyFormStore.form.meta.isValid
          && this.bridgeProfitAndDscrCalculatorFormStore.refinanceLoanPurposeFormStore.form.meta.isValid;
      }
      return this.bridgeProfitAndDscrCalculatorFormStore.form.meta.isValid
        && this.bridgeProfitAndDscrCalculatorFormStore.saleExitStrategyFormStore.form.meta.isValid;
    } else if (this.bridgeProfitAndDscrCalculatorFormStore.exitStrategy === 'Refinance') {
      return this.bridgeProfitAndDscrCalculatorFormStore.form.meta.isValid
        && this.bridgeProfitAndDscrCalculatorFormStore.refinanceExitStrategyFormStore.form.meta.isValid;
    } else {
      return false;
    }
  }

  getCalculateTotalLoanAmount() {
    return this.getCalculateInitialLoanAmount() + this.getCalculateConstructionHoldback();
  }

  getCalculateConstructionHoldback() {
    const { renovationBudget, constructionHoldback } = this.bridgeProfitAndDscrCalculatorFormStore.form.fields;
    return renovationBudget.value * (constructionHoldback.value / 100);
  }

  getCalculateInitialLoanAmount() {
    const {
      acquisition,
      initialLoanAmount,
      loanPurpose,
      capitalImprovements
    } = this.bridgeProfitAndDscrCalculatorFormStore.form.fields;
    const totalPurchase = loanPurpose.value === 'Purchase'
      ? acquisition.value
      : acquisition.value + +(capitalImprovements.value ?? 0);
    return totalPurchase * (initialLoanAmount.value / 100);
  }

  get totalCosts() {
    const {
      purchasePrice,
      renovationBudget,
      interestAndOriginationPoints,
      propertyTaxAndMaintenanceAmount,
      taxesAndOtherCosts,
    } = this.calculatedValues;

    return (purchasePrice ?? 0) + (renovationBudget ?? 0) + (interestAndOriginationPoints ?? 0) + (propertyTaxAndMaintenanceAmount ?? 0) + (taxesAndOtherCosts ?? 0)
  }

  get totalProfitOnFlip() {
    const {
      salesPrice
    } = this.calculatedValues;

    return (salesPrice ?? 0) - this.totalCosts;
  }

  setIsDisabled(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }

  setIsDownloading(isDownloading: boolean) {
    this.isDownloading = isDownloading;
  }
}

export default BridgeProfitAndDscrCalculatorStore;
