import { Message } from "@twilio/conversations";
import { ChatRoomType, ConversationAttribute, MessageAttribute, ChatRoomStatus, ConversationMessage, ParticipantDetails, RoleInfoForAuthor } from "./types/communicationTypes";
import { Conversation as TwilioConversation } from "@twilio/conversations";

import ClosingIco from './../assets/closing.svg';
import groupChatIco from './../assets/groups.svg';
import insuranceIco from './../assets/insurance.svg';
import appraisalsIco from './../assets/propertyAppraisals.svg';
import underwritingIco from './../assets/underwriting.svg';
import extensionsIco from './../assets/extensions.svg';
import payoffIco from './../assets/payoff.svg';
import drawsIco from './../assets/draws.svg';
import initialPayments from './../assets/initial-payments.svg';
import tamariskIco from './../assets/tamarisk_appraisal.svg';
import techSuppotIco from './../assets/tech_support.svg';
import salesSuppotIco from './../assets/sales_support.svg';
import newLoanRequestIco from './../assets/new_loan_request.svg';
import newLoanRequest1Ico from './../assets/new_loan_request-1.svg';
import { format } from "date-fns";

export const getIcon = (conversation: TwilioConversation): string => {
  const conversationAttributes = conversation.attributes as ConversationAttribute;
  const chatRoomType = conversationAttributes.chatRoomType;
  if (chatRoomType == ChatRoomType.UNDERWRITING) {
    return underwritingIco;
  } else if (chatRoomType == ChatRoomType.CLOSING) {
    return ClosingIco;
  } else if (chatRoomType == ChatRoomType.INSURANCE) {
    return insuranceIco;
  } else if (chatRoomType == ChatRoomType.APPRAISAL) {
    return appraisalsIco;
  } else if (chatRoomType == ChatRoomType.EXTENSIONS) {
    return extensionsIco;
  } else if (chatRoomType == ChatRoomType.PAYOFF) {
    return payoffIco;
  } else if (chatRoomType == ChatRoomType.DRAWS) {
    return drawsIco;
  } else if (chatRoomType == ChatRoomType.INITIAL_PAYMENTS) {
    return initialPayments;
  } else if (chatRoomType == ChatRoomType.TAMARISK_APPRAISAL) {
    return tamariskIco;
  } else if (chatRoomType == ChatRoomType.TECH_SUPPORT) {
    return techSuppotIco;
  } else if (chatRoomType == ChatRoomType.SALES_SUPPORT) {
    return salesSuppotIco;
  } else if (chatRoomType == ChatRoomType.CLOSING_SUPPORT) {
    return ClosingIco;
  } else if (chatRoomType == ChatRoomType.NEW_LOAN_REQUEST) {
    return newLoanRequest1Ico;
  } else {
    return groupChatIco;
  }
};

export function getLastMessageAuthor(messages: Message[], typingData?: string[]) {
  if (messages === undefined || messages === null) {
    return undefined;
  }
  if (typingData?.length) {
    return undefined;
  }
  if (messages.length === 0) {
    return undefined;
  }
  const message = messages[messages.length - 1];
  return getAuthorName(message);
}

export function getAuthorName(message: Message | ConversationMessage) {
  const messageAttributes = message.attributes as MessageAttribute;
  return messageAttributes?.authorName ?? message.author;
}

export function getRoleInfoForAuthor(author: string, roles: Record<number, ParticipantDetails>): RoleInfoForAuthor {
  for (const [key, d] of Object.entries(roles)) {
    if (author === d.userId.toString()) {
      return {
        role: d.roleDisplayName ? d.roleDisplayName : d.role,
        roleColor: d.roleDisplayColor,
        hasRole: d.hasRole ? d.hasRole : d.roleDisplayName ? true : false,
        isActive: d.active,
      }
    }
  }
  return {
    role: undefined,
    roleColor: undefined,
    hasRole: false,
    isActive: true,
  };
}

export function isInternal(userId: string, users: Record<number, ParticipantDetails>): boolean {
  for (const [key, d] of Object.entries(users)) {
    if (userId === d.userId.toString()) {
      return (d.isRoleInternal !== undefined) ? d.isRoleInternal : d.isInternal;
    }
  }
  return false;
}

export function getUserFromAllowedParticipants(userId: number, users: Record<number, ParticipantDetails>): number {
  for (const [key, d] of Object.entries(users)) {
    if (userId == d.userId) {
      return (d.userId)
    }
  }
  return null;
}

export function checkIfExistingRating(ratings, receiverId) {
  const existingRating = ratings.find(r => r.receiverUserId === receiverId);
  if (existingRating) {
    return true;
  }
}

export function filterByTagsSelected(message: Message, conversationTagsSelected: string[]) {
  // if no tags are selected, then don't filter
  if (!conversationTagsSelected || conversationTagsSelected.length == 0) {
    return true;
  }
  const messageAttributes = message.attributes as MessageAttribute;
  // if no tags are defined for a message, then filter them
  if (!messageAttributes?.tags || messageAttributes.tags.length == 0) {
    return false;
  }
  return messageAttributes.tags.some(t => conversationTagsSelected.includes(t));
}

export function getLastMessage(messages: Message[], typingData: string[]) {
  if (messages === undefined || messages === null) {
    return "Loading...";
  }
  if (typingData.length) {
    return getTypingMessage(typingData);
  }
  if (messages.length === 0) {
    return "No messages";
  }
  if (!!messages[messages.length - 1].media) {
    return "Media message";
  }
  return messages[messages.length - 1].body;
}

export function getMessagePreviewText(messages: Message[], typingData: string[]) {
  if (messages === undefined || messages === null) {
    return "Loading...";
  }
  if (typingData.length) {
    return getTypingMessage(typingData);
  }
  if (messages.length === 0) {
    return "No messages";
  }
  return "";
}

export const getTypingMessage = (typingData: string[]): string => {
  if (!typingData || typingData.length === 0) {
    return undefined;
  }
  return typingData.length > 1
    ? `${typingData.length + " participants are typing..."}`
    : `${typingData[0] + " is typing..."}`;
}


export const getMessageTime = (message: Message) => {
  const dateCreated: Date = message.dateCreated;
  return getChatFormattedDate(dateCreated);
};

export const getChatFormattedDate = (dateCreated: Date) => {
  return (
    dateCreated.toLocaleString('en-US', {
      timeZone: 'America/New_York',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    }) + ' EST'
  );
};


export const getMessageHourAndMinute = (message: Message) => {
  const dateCreated: Date = message.dateCreated;
  return (format(new Date(dateCreated), 'h:mm aa'));
};

export const getLastMessageTime = (lastMessageDate: Date) => {
  if (lastMessageDate == null || lastMessageDate == undefined) {
    return "";
  }
  const today = new Date();
  const diffInDates = Math.floor(today.getTime() - lastMessageDate.getTime());
  const dayLength = 1000 * 60 * 60 * 24;
  const weekLength = dayLength * 7;
  const yearLength = weekLength * 52;
  let diffInDays = Math.floor(diffInDates / dayLength);
  const diffInWeeks = Math.floor(diffInDates / weekLength);
  const diffInYears = Math.floor(diffInDates / yearLength);
  if (diffInDays < 0) {
    diffInDays = 0;
  }
  if (diffInDays === 0) {
    const minutesLessThanTen = lastMessageDate.getMinutes() < 10 ? "0" : "";
    const isPM = lastMessageDate.getHours() >= 12;
    return (
      (isPM ? `${(lastMessageDate.getHours() - 12)}` : lastMessageDate.getHours().toString()) +
      ":" +
      minutesLessThanTen +
      lastMessageDate.getMinutes().toString() +
      (isPM ? ' PM' : ' AM')
    );
  }
  if (diffInDays === 1) {
    return "1 day ago";
  }
  if (diffInDays < 7) {
    return diffInDays + " days ago";
  }
  if (diffInDays < 14) {
    return "1 week ago";
  }
  if (diffInWeeks < 52) {
    return diffInWeeks + " weeks ago";
  }
  if (diffInYears < 2) {
    return "1 year ago";
  }
  return diffInYears + " years ago";
};

export const handlePromiseRejection = async (
  func: () => void,
  onRejectCallback: (msg: string) => void
): Promise<void> => {
  try {
    await func();
  } catch (e) {
    console.error(e);
    onRejectCallback("Something went wrong. Please try again.");
    return Promise.reject("Something went wrong. Please try again.");
  }
};

export const cleanHtmlText = (htmlString) => {
  var div = document.createElement('div');
  div.innerHTML = htmlString;
  return div.textContent || div.innerText || '';
}