import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { StatusChip, StatusType } from '@roc/ui';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { useGeneralTasksStore } from '../../hooks/useGeneralTasksStore';
import { RelatedTaskInfo } from '../../types/generalTasksTypes';

const useStyles = makeStyles(theme => ({
  container: {
    borderRadius: theme.spacing(1),
    padding: theme.spacing(2),
    border: '1px solid rgba(0, 0, 0, 0.12)',
  },
  link: {
    color: theme.palette.primary.dark,
    textDecoration: 'underline',
  },
}));

export const RelatedTask = observer(
  ({ taskInfo }: { taskInfo: RelatedTaskInfo }) => {
    const classes = useStyles();
    const { generalTasksStore } = useGeneralTasksStore();
    const { editTaskStore } = generalTasksStore;

    return (
      <Box
        className={classes.container}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography>
          <Link
            className={classes.link}
            to={'#'}
            onClick={() => editTaskStore.openEditTask(taskInfo.taskId)}
          >
            {taskInfo.taskId}
          </Link>
        </Typography>
        <Box pl={2} flex={1}>
          <Typography>{taskInfo.title}</Typography>
        </Box>
        <Box>
          <StatusChip
            statusType={StatusType.GENERAL_TASK_STATUS}
            label={taskInfo?.status}
            size="small"
          />
        </Box>
      </Box>
    );
  }
);
