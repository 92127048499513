import CompanyFormStore from "./companyFormStore";

const form = {
  fields: {
    name: {
      value: '',
      error: null,
      rule: '',
    },
    contactName: {
      value: '',
      error: null,
      rule: '',
    },
    cellPhone: {
      value: '',
      error: null,
      rule: 'regex:/^\\d{3}-?\\d{3}-?\\d{4}$/|alpha_dash',
      message: 'Cellphone must be a number with the format xxx-xxx-xxxx',
    },
    address: {
      value: '',
      error: null,
      rule: '',
    },
    emailAddress: {
      value: '',
      error: null,
      rule: 'email',
    },
    latitude: {
      value: 0,
      error: null,
      rule: '',
    },
    longitude: {
      value: 0,
      error: null,
      rule: '',
    },
    streetName: {
      value: '',
      error: null,
      rule: '',
    },
    streetNumber: {
      value: '',
      error: null,
      rule: '',
    },
    city: {
      value: '',
      error: null,
      rule: '',
    },
    state: {
      value: '',
      error: null,
      rule: '',
    },
    zipcode: {
      value: '',
      error: null,
      rule: '',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export class InsuranceCompanyFormStore extends CompanyFormStore {
  constructor(globalStore) {
    super({ ...form }, globalStore);
  }
}