import { action, flow, makeObservable, observable } from 'mobx';
import { GlobalStore, ApiResponse } from '@roc/feature-app-core';
import { ForeclosureRequestStore, CounterPartyExpense } from '@roc/feature-loans'

export class ForeclosureInvoiceStore {
  globalStore: GlobalStore;
  foreclosureRequestStore: ForeclosureRequestStore;
  constructor(globalStore: GlobalStore) {
    this.globalStore = globalStore;
    this.foreclosureRequestStore = new ForeclosureRequestStore(globalStore);
    makeObservable(this, {
    });
  }
}
