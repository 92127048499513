import { StandardDialog } from '@roc/ui';
import { FundingTemplate } from './fundingTemplate';

export type EstimateFundingTemplateDialogProps = {
  open?: boolean;
  onClose?: () => void;
};

export const EstimateFundingTemplateDialog = (props: EstimateFundingTemplateDialogProps) => {
  const { open, onClose } = props;

  return (
    <StandardDialog
      open={open}
      title=""
      maxWidth="xl"
      handleClose={onClose}
      dialogContent={<FundingTemplate />}
    />
  );
};

export default EstimateFundingTemplateDialog;
