import React, { useEffect } from 'react';
import { usePlaidLink } from 'react-plaid-link';
import { useStore } from '@roc/client-portal-shared/hooks';
import { useStore as publicUseStore } from 'apps/client-portal-public/src/app/hooks/useStore'
import { Button } from '@material-ui/core';

interface LinkProps {
  linkToken: string | null;
  isPublic?: boolean;
  isDisabledLinked?: boolean;
}

export const PlaidLink: React.FC<LinkProps> = (props: LinkProps) => {
  const { plaidStore } = useStore();
  const { plaidStore: publicStore } = publicUseStore();
  const onSuccess = React.useCallback((public_token, metadata) => {
    // send public_token to server
    publicStore.exchangeToken(public_token);
  }, []);
  const config: Parameters<typeof usePlaidLink>[0] = {
    token: props.linkToken!,
    onSuccess,
  };

  const { open, ready } = usePlaidLink(config);
  return (
    <Button color='primary' variant='contained' onClick={() => open()} style={{ marginLeft: '20px' }} disabled={!ready || props.isDisabledLinked}>
      Link accounts
    </Button>
  );
};