import { Box, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    color: 'black',
    fontSize: '34px',
  },
  subtitle: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    color: 'gray',
    fontSize: '16px',
  },
}));

export const StepTitle = ({ title, subtitle }) => {
  const classes = useStyles();
  return (
    <Box>
      <Typography variant="h3" className={classes.title}>
        {title}
      </Typography>
      <Typography variant="body1" className={classes.subtitle}>
        {subtitle}
      </Typography>
    </Box>
  );
};
