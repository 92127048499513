import {
  Grid,
  Input,
  Slider as MuiSlider,
  SliderProps as MuiSliderProps,
  Typography,
} from '@material-ui/core';
import { withTestDataAttribute } from '../testDataAttribute';
import { ReactNode } from 'react';
import ReactNumberFormat from 'react-number-format';
import { InputAdornment } from '@material-ui/core';
import {
  TextField,
} from '@roc/ui';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useCustomSliderStyles = makeStyles(theme =>
  createStyles({
    percentageInput: {
      maxWidth: '95px',
      '& input': {
        padding: '10px',
      }
    }
  })
);

export type SliderWithInputsProps = MuiSliderProps & {
  standaloneLabel?: boolean;
  label?: string | ReactNode;
  testId: string;
};

export const NumberSliderWithInput = withTestDataAttribute((props: SliderWithInputsProps) => {
  const { standaloneLabel, label, ...muiComponentProps } = props;
  const classes = useCustomSliderStyles();

  const handleInputChange = (event) => {
    props.onChangeCommitted(event, event.target.value === '' ? 0 : Number(event.target.value));
  }

  const handleBlur = () => {
    const value = typeof props.value === 'number' ? props.value : 0;
    if (value < props.min) {
      props.onChangeCommitted({} as React.ChangeEvent<{}>, props.min);
    }
    if (value > props.max) {
      props.onChangeCommitted({} as React.ChangeEvent<{}>, props.max);
    }
  }

  return (
    <>
      {standaloneLabel && label ? <Typography>{label}</Typography> : null}
      <Grid container spacing={2} alignItems='center'>
        <Grid item xs>
          <MuiSlider aria-labelledby="input-slider" {...muiComponentProps} />
        </Grid>
        <Grid item style={{ marginBottom: '9px' }}>
          <ReactNumberFormat
            className={classes.percentageInput}
            variant="outlined"
            value={typeof props.value === 'number' ? props.value : 0}
            customInput={TextField}
            onChange={handleInputChange}
            onBlur={handleBlur}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
          />
        </Grid>
      </Grid>
    </>
  );
});
