import { Grid } from '@material-ui/core';
import { useLoanStore } from '@roc/feature-loans';
import { observer } from 'mobx-react';
import { LoanDetailsCustomCard } from '../loanDetailsCustomCard';
import { CustomCardDividers, CustomCardRow } from '../customCard';
import { LoanApplicationDetailsCardActionsButton } from '../loanApplicationDetailsCardActionsButton';

export const LoanApplicationDetailsCard = observer(() => {
  const { loanStore } = useLoanStore();
  const { loanDetails } = loanStore;

  return (
    <LoanDetailsCustomCard contentStyle={{
      minWidth: 1100
    }} cardContentProps={{
      style: {
        overflow: 'auto'
      }
    }}>
      <Grid item xs={12}>
        <Grid container spacing={2} direction='row' justifyContent='space-between'>
          <Grid item xs={9}>
            <CustomCardDividers>
              <CustomCardRow label="Loan Type" value={loanDetails?.loanType} />
              <CustomCardRow label="Loan Subype" value={loanDetails.loanSubType} />
            </CustomCardDividers>
          </Grid>
          <Grid item>
            <LoanApplicationDetailsCardActionsButton />
          </Grid>
        </Grid>
      </Grid>
    </LoanDetailsCustomCard>
  );
});