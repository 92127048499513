import { Grid, ListItemIcon, ListItemText, Menu, Typography } from "@material-ui/core"
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Button, IconButton, MenuItem, Paper, StandardDialog, TextField, Tooltip } from "@roc/ui";
import { useState } from "react"
import { useCreateListStore } from "../hooks/useCreateListStore";
import { observer } from "mobx-react";
import { FilterModalContent } from "./filterBuilderComponents/filterModalContent";
import { FilterItem } from "./filterBuilderComponents/filterItem";
import { MoreVert, Tune } from "@material-ui/icons";
import { LogicFilterModalContent } from "./filterBuilderComponents/logicFilterModalContent";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2),
    },
    textAreaContainer: {
      paddingTop: theme.spacing(2),
    },
    addFilterContainer: {
      paddingTop: theme.spacing(2),
    },
    buttonsContainer: {
      padding: theme.spacing(2),
    },
    firterItemContainer: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    tooltipContainer: {
      display: 'flex',
      flexDirection: 'row',
    },
    removeAllContainer: {
      paddingRight: theme.spacing(1),
    },

  })
);

export const FilterBuilder = observer(() => {
  const classes = useStyles();
  const { createListStore } = useCreateListStore();
  const [openFilterModal, setOpenFilterModal] = useState<boolean>(false);
  const [openEditFilterModal, setOpenEditFilterModal] = useState<boolean>(false);
  const [openLogicFilterModal, setOpenLogicFilterModal] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const [editIndex, setEditIndex] = useState<number>(null);

  const closeModal = () => {
    setOpenFilterModal(false);
    createListStore.addFilterFormStore.reset();
  };
  const closeEditModal = () => {
    setOpenEditFilterModal(false);
    createListStore.addFilterFormStore.reset();
  };
  const closeLogicFilterModal = () => {
    setOpenLogicFilterModal(false);
  };
  const onAddFilter = () => {
    createListStore.addFilter();
    closeModal();
  };
  const onSaveEditFilter = index => {
    createListStore.editFilter(index);
    closeEditModal();
  };

  const onEditFilter = (index: number) => {
    setEditIndex(index);
    createListStore.setFilterValuesByIndex(index);
    setOpenEditFilterModal(true);
  };

  const openMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const openFilterLogicModal = () => {
    createListStore.filterLogicInputValue = createListStore.filterLogicText;
    setOpenLogicFilterModal(true);
    handleMenuClose()
  }

  const onSave = () => {
    createListStore.filterLogicText = createListStore.filterLogicInputValue;
    setOpenLogicFilterModal(false);
  }

  return (
    <>
      <Paper className={classes.paper}>
        <Grid container>
          <Grid item container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h6">Filter Criteria</Typography>
            </Grid>
            <Grid item>
              {
                createListStore.filters.length > 0 ? (
                  <IconButton onClick={e => openMenu(e)} testId="actions">
                    <MoreVert color="primary" />
                  </IconButton>
                ) : null
              }
            </Grid>
          </Grid>
          <Grid item container justifyContent="center">
            {createListStore.filters.length > 0 ? (
              <Grid item container justifyContent="center">
                {
                  createListStore.filterLogicText !== '' ? (
                    <Grid item>
                      <Typography variant="body1">{`Filter Logic: ${createListStore.filterLogicText}`}</Typography>
                    </Grid>
                  ) : null
                }
                <Grid item container>
                  {createListStore.filters.map((filter, index) => (
                    <Grid item xs={12} key={index} className={classes.firterItemContainer}>
                      <FilterItem index={index} onEdit={onEditFilter} />
                    </Grid>))}
                </Grid>
              </Grid>
            ) : (<Grid item>
              <Typography variant="body1">There are no added filters</Typography>
            </Grid>)
            }
          </Grid>
          <Grid item container>
            <Grid item container justifyContent="flex-end" className={classes.addFilterContainer}>
              {createListStore.filters.length > 0 ? (<Grid item className={classes.removeAllContainer}>
                <Button
                  color="primary"
                  fullWidth
                  variant="outlined"
                  onClick={() => createListStore.removeAllFilters()}
                  testId="removeFilters"
                >Remove All</Button>
              </Grid>) : null}
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() => setOpenFilterModal(true)}
                  testId="addFilter"
                >Add Filter</Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <StandardDialog
          open={openFilterModal}
          title="Create Filter"
          maxWidth="xs"
          handleClose={closeModal}
          dialogContent={
            <FilterModalContent onAddFilter={onAddFilter} />
          }
        />
        <StandardDialog
          open={openEditFilterModal}
          title="Edit Filter"
          maxWidth="xs"
          handleClose={closeEditModal}
          dialogContent={
            <FilterModalContent onEditFilter={onSaveEditFilter} index={editIndex} isEdit />
          }
        />
        <StandardDialog
          open={openLogicFilterModal}
          title="Filter Logic"
          maxWidth="xs"
          handleClose={closeLogicFilterModal}
          dialogContent={
            <LogicFilterModalContent onSave={onSave} />
          }
        />
      </Paper>
      <Menu
        id="menu-appbar"
        getContentAnchorEl={null}
        anchorEl={anchorEl}
        keepMounted
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={menuOpen}
        onClose={handleMenuClose}
      >
        <MenuItem
          testId="add-filter-logic"
          onClick={openFilterLogicModal}
        >
          <ListItemIcon>
            <Tune fontSize="small" />
          </ListItemIcon>
          <ListItemText>Edit filter logic</ListItemText>
        </MenuItem>
      </Menu>
    </>
  )
});