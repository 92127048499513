import { Service } from '@roc/feature-app-core';

const url = {
  SEND_ID_VERIFICATION_EMAIL_REMINDER: '/api/v1/loan/borrowerDetailsVerification/sendIdVerificationEmailReminder',
  GET_UNSUBSCRIBE_LINK: '/api/v1/loan/borrowerDetailsVerification/getUnsubscribeLink',
  GET_BORROWER_SETUP_FORM_LINK: '/api/v1/loan/borrowerDetailsVerification/getBorrowerSetupFormLink',
  GET_BORROWER_PRE_SCREEN_INFO: '/api/v1/validation/getBorrowerPrescreenInfoByBorrowerId',
};

export class BorrowerSetupService extends Service {

  sendIdVerificationEmailReminder(data) {
    return this.post(`${url.SEND_ID_VERIFICATION_EMAIL_REMINDER}`, data);
  }

  getUnsubscribeLink(lenderId, requestToken) {
    return this.get(`${url.GET_UNSUBSCRIBE_LINK}`, { lenderId, requestToken });
  }

  getBorrowerSetupFormLink(requestId: number) {
    return this.get(`${url.GET_BORROWER_SETUP_FORM_LINK}?requestId=${requestId}`, {});
  }

  getBorrowerPrescreenInfoByBorrowerId(borrowerId: number, personalGuarantor: boolean, loanType: string) {
    return this.get(`${url.GET_BORROWER_PRE_SCREEN_INFO}`, {
      borrowerId,
      personalGuarantor,
      loanType
    });
  }
}
