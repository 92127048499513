import { Grid, Typography } from '@material-ui/core';
import { AlertDialog } from '@roc/ui';
import { observer } from 'mobx-react';
import { ScopeOfWorkV2FormBaseStore } from '@roc/feature-sow-shared/stores/v2';

export const SuccessDialog = observer(
  ({ store }: { store: ScopeOfWorkV2FormBaseStore }) => {
    return (
      <AlertDialog
        title="Success"
        open={store.showSuccessDialog}
        handleOk={() => store.closeSuccessDialog()}
        handleClose={() => store.closeSuccessDialog()}
        body={
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography>
                Scope of work <b>#{store.submittedScopeOfWorkId}</b> saved
                succesfully
              </Typography>
            </Grid>
          </Grid>
        }
      />
    );
  }
);
