import {
  Box,
  Grid,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, ChartOptions } from 'chart.js';
import { formatCurrency } from '@roc/ui/utils';
import { Paper } from '@roc/ui';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

const useStyles = makeStyles(theme => ({
  container: {
    padding: 32,
  },
  paper: {
    padding: 24,
  },
  graphContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  graph: {
    width: 300
  }
}));

export const LoanValuesChartColor = {
  chRemaining: '#49db4e',
  chReimbursedToDate: '#7c90ff',
}

export const LoanValuesChartOnly = ({ chRemaining, chReimbursedToDate }) => {

  const data = {
    labels: ['Const. Holdback Reimbursed to Date', 'Const. Holdback Remaining'],
    datasets: [
      {
        label: 'Loan Values',
        data: [chReimbursedToDate, chRemaining],
        backgroundColor: [
          LoanValuesChartColor.chReimbursedToDate,
          LoanValuesChartColor.chRemaining,
        ],
        borderColor: [
          LoanValuesChartColor.chReimbursedToDate,
          LoanValuesChartColor.chRemaining,
        ],
        borderWidth: 0,
        spacing: 1,
      },
    ],
  };
  const options: ChartOptions<'doughnut'> = {
    cutout: 75,
    radius: "75%",
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
        align: 'start'
      },
      tooltip: {
        callbacks: {
          //@ts-ignore
          label: function (tooltipItem, data) {
            const label = tooltipItem.label || '';
            return label;
          },
          //@ts-ignore
          afterLabel: function (tooltipItem, data) {
            let afterLabel = '';
            if (tooltipItem.parsed !== null) {
              afterLabel = formatCurrency(tooltipItem.parsed);
            }
            return afterLabel;
          }
        },
        titleFontSize: 16,
        titleFontColor: '#0066ff',
        bodyFontColor: '#000',
        bodyFontSize: 16,
        displayColors: false,
      }
    },
  };
  return (
    <Doughnut data={data} options={options} />
  )
};

const LoanValuesChart = ({ chRemaining, chReimbursedToDate }) => {
  const classes = useStyles();
  return (
    <Grid item xs={12} md={6}>
      <Paper title="LOAN VALUES">
        <Box className={classes.graphContainer}>
          <Box className={classes.graph}>
            <LoanValuesChartOnly chRemaining={chRemaining} chReimbursedToDate={chReimbursedToDate} />
          </Box>
        </Box>
      </Paper>
    </Grid>
  )
};

export default LoanValuesChart;