import { observer } from "mobx-react";
import { Grid } from '@material-ui/core';
import { SelectField, TextField } from "@roc/ui";
import { originatorSubLabel } from "@roc/feature-utils";
export const LoanParticipantsModal = observer(props => {
  const { store } = props;
  const { originatorOptions, selectedOriginator } = store;
  const { loanUnderwriter, loanCoordinator, loanSeniorUnderwriter, primarySupport, secondarySupport } = props.data;

  const handleOriginatorChange = (originatorId) => {
    const result = originatorOptions.find(({ value }) => value === originatorId);
    store.setSelectedOriginator(result.label, originatorId)
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            testId="loanSeniorUnderwriter"
            disabled={true}
            variant="outlined"
            standaloneLabel
            label="Senior Underwriter"
            value={loanSeniorUnderwriter.name}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            testId="loanUnderwriter"
            disabled={true}
            variant="outlined"
            standaloneLabel
            label="Underwriter"
            value={loanUnderwriter.name}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            testId="loanCoordinator"
            disabled={true}
            variant="outlined"
            standaloneLabel
            label="Coordinator"
            value={loanCoordinator.name}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            testId="primarySupport"
            disabled={true}
            variant="outlined"
            standaloneLabel
            label="Primary Originator Support"
            value={primarySupport}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            testId="secondarySupport"
            disabled={true}
            variant="outlined"
            standaloneLabel
            label="Secondary Originator Support"
            value={secondarySupport}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <SelectField
            disabled={false}
            required={false}
            standaloneLabel
            label="Originator"
            value={selectedOriginator.originatorId ?? null}
            options={originatorOptions}
            subLabel={originatorSubLabel}
            onChange={handleOriginatorChange}
            variant="outlined"
            fullWidth
            testId="loanOriginator"
          />
        </Grid>
      </Grid>
    </>
  );
});