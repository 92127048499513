
import { useEffect, useState } from 'react';

import clsx from 'clsx';

import { Box, Button, Card, CardActions, CardContent, CircularProgress, Grid, Paper, Step, StepConnector, StepLabel, Stepper, Typography } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { CheckCircle, Close } from '@material-ui/icons';


import { appStoreBadge, playStoreBadge } from '@roc/feature-app-core/assets';
import { IconButton } from '@roc/ui';
import { observer } from 'mobx-react';
import { useTwoFactorStore } from '../hooks/useTwoFactorStore';
import { TwoFactorType } from '../types';
import { TwoFactorOtpInput } from './twoFactorOtp';

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      borderColor: '#3f51b5',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#3f51b5',
    },
  },
  line: {
    borderColor: '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
  root: {
    color: '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  active: {
    color: '#3f51b5',
  },
  circle: {
    width: 12,
    height: 12,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  completed: {
    color: '#3f51b5',
    zIndex: 1,
    fontSize: 28,
  },
});

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? <CheckCircle className={classes.completed} /> : <div className={classes.circle} />}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: 16
  },
  button: {
    marginRight: theme.spacing(1),
  }
}));

function getSteps() {
  return ['Download App', 'Scan QR Code', 'Backup Key', 'Set up Two-Factor Authentication'];
}


const EnableTwoFactor = ({ twoFactorType, onSuccess, onCancel }: {
  twoFactorType: TwoFactorType,
  onSuccess: () => void,
  onCancel?: () => void,
}) => {
  const classes = useStyles();

  const [activeStep, setActiveStep] = useState(0);
  const [qrImage, setQrImage] = useState('');
  const [code, setCode] = useState('');
  const [loading, setLoading] = useState(false);
  const { twoFactorStore } = useTwoFactorStore();

  useEffect(() => {
    setLoading(true);
    twoFactorStore.enableTwoFactor(twoFactorType)
      .catch(e => {
        handleCancel();
      })
      .finally(() => {
        setLoading(false);
      });
  }, [])

  const steps = getSteps();

  const handleNext = () => {
    if (activeStep === 0 && code.length === 0) {
      setLoading(true);
      Promise.all([twoFactorStore.getTwoFactorCode(twoFactorType), twoFactorStore.getQrImage(twoFactorType)])
        .then((resp1) => {
          setCode(resp1[0]);
          setQrImage(resp1[1]);
        })
        .finally(() => {
          setLoading(false);
        })
    }
    else if (activeStep === steps.length - 1) {
      // do nothing
      // checkValidationAndHandleSubmit(value);
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleCancel = () => {
    onCancel && onCancel();
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSubmit = (value) => {
    setLoading(true);
    return twoFactorStore.activateTwoFactor(twoFactorType, value)
      .then(() => {
        onSuccess();
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function getStepContent(stepIndex) {
    if (loading) {
      return (
        <Box my={4} textAlign={'center'}>
          <CircularProgress color='primary' size={24} />
        </Box>
      );
    }
    switch (stepIndex) {
      case 0:
        return <Box component="div">
          <Typography variant="h5" align="center" style={{ margin: "32px 0" }}>
            Step 1
          </Typography>
          <Typography variant="body1" align="center" >
            Get the Google Authenticator App from the App store (iOS or Android)
          </Typography>
          <Box display="flex" flexDirection={"column"} justifyContent="center" textAlign={"center"} mt={1} p={1}>
            <Box mt={2}>
              <a href='https://apps.apple.com/us/app/google-authenticator/id388497605' target="_blank">
                <img alt='Download on App Store' src={appStoreBadge} style={{ height: '65px', margin: '15px' }}></img>
              </a>
            </Box>
            <Box mt={1} mb={2}>
              <a href='https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2' target="_blank">
                <img alt='Get it on Google Play' src={playStoreBadge} style={{ height: '100px' }} />
              </a>
            </Box>
          </Box>
        </Box>;
      case 1:
        return <Box component="div">
          <Typography variant="h5" align="center" style={{ margin: "32px 0" }}>
            Step 2
          </Typography>
          <Typography variant="body1" align="center" >
            Scan below QR code in the Google Authenticator app
          </Typography>
          <Box display="flex" flexDirection={"column"} justifyContent="center" textAlign={"center"} mt={1} p={1}>
            <Box mt={2}>
              <img alt='QR' src={qrImage} style={{ height: '300px' }} />
            </Box>
            <Box mt={1} mb={2} maxWidth={500} mx={'auto'}>
              <Typography variant="body1" align="center" >
                If you are unable to scan the QR code, please enter this code manually into the app.
              </Typography>
              <Paper elevation={3} style={{ maxWidth: 350, margin: "32px auto" }}>
                <Typography variant="h5" align="center" color="textSecondary">
                  {code}
                </Typography>
              </Paper>
            </Box>
          </Box>
        </Box>;
      case 2:
        return <Box component="div" maxWidth={500} margin={'auto'}>
          <Typography variant="h5" align="center" style={{ margin: "32px 0" }}>
            Step 3
          </Typography>
          <Typography variant="body1" align="center" >
            Please save this "Secret Key" securely as this will allow you to recover your Google Authenticator in case of phone loss.
          </Typography>
          <Paper elevation={3} style={{ maxWidth: 350, margin: "32px auto" }}>
            <Typography variant="h5" align="center" color="textSecondary">
              {code}
            </Typography>
          </Paper>
        </Box>;
      case 3:
        return <Box component="div">
          <Typography variant="h5" align="center" style={{ margin: "32px 0" }}>
            Step 4
          </Typography>
          <Typography variant="body1" align="center" >
            Enter code to verify & activate Two-Factor Authentication
          </Typography>
          <Box m={4} display="flex" justifyContent="center">
            <TwoFactorOtpInput onValidate={handleSubmit} />
          </Box>
        </Box>;
      default:
        return 'Unknown step';
    }
  }

  return (
    <>
      <Card className={classes.root}>
        <CardContent>
          <Box display={'flex'}>
            <Box flex={1}>
              <Typography variant="h5" align="center">
                Set Up Two-Factor Authentication
              </Typography>
            </Box>
            <Box>
              <IconButton size='small' testId="close" onClick={handleCancel}>
                <Close fontSize='small' />
              </IconButton>
            </Box>
          </Box>
          <div className={classes.root}>
            <Stepper alternativeLabel activeStep={activeStep} connector={<QontoConnector />}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <div>
              {getStepContent(activeStep)}
            </div>
          </div>
        </CardContent>

        <CardActions>
          <Box display='flex' flex='1' p={4}>
            <Grid
              justifyContent="space-between"
              container
              spacing={2}
            >
              <Grid item>
                {
                  activeStep == 0 ?
                    (
                      onCancel ?
                        <Button onClick={handleCancel} className={classes.button}>
                          Cancel
                        </Button> : null
                    ) :
                    <Button onClick={handleBack} className={classes.button}>
                      Back
                    </Button>
                }
              </Grid>
              <Grid item>
                {activeStep === steps.length - 1 ? null :
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    className={classes.button}
                  >
                    Next
                  </Button>
                }
              </Grid>
            </Grid>
          </Box>

        </CardActions>
      </Card>
    </>
  );
}

export default observer(EnableTwoFactor);