import { useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { Paper } from '@roc/ui';
import { observer } from 'mobx-react';
import { useParams } from 'react-router';
import { Layout, StepperComponent } from '@roc/client-portal-shared/components';

import { useStore } from '../../hooks';
import { InformationStep } from './informationStep';
import PaymentStep from './paymentStep';
import AcknowledmentStep from './acknowledmentStep'
import { decodeData } from './arrPaymentsUtils';
import { WarningOutlined } from '@material-ui/icons';
import { PortalBannerMessages } from '@roc/feature-app-core';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(3, 0, 4),
      borderRadius: theme.spacing(2),
      boxShadow:
        'rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px',
    },
    stepper: {
      maxWidth: 900,
      margin: 'auto',
      [theme.breakpoints.down('xs')]: {
        paddingLeft: theme.spacing(.5),
        paddingRight: theme.spacing(.5),
      },
    },
    stepContentWrapper: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      [theme.breakpoints.down('xs')]: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
      },
    },
  })
);

const useStyle = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(4),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    backgroundColor: '#FEF0ED',
    color: '#642B20',
  },
  content: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(1),
  },
  icon: {
    color: theme.palette.warning.light,
  }
}));

export type EncodedParams = {
  encodedParams: string;
};

export const Appraisal1004dPayments = observer(() => {
  const [activeStep, setActiveStep] = useState(0);
  const [disableStepperNavigation, setDisableStepperNavigation] = useState(
    false
  );
  const [showBanner, setShowBanner] = useState(false);
  const [entityData, setEntityData] = useState(null);
  const { encodedParams } = useParams<EncodedParams>();
  const { cdaPaymentsStore, globalStore } = useStore();
  const { routeBaseUrl } = globalStore;
  const { docusignStatus } = cdaPaymentsStore;
  const { entityId, tamariskFlowType } = entityData || {};
  const classes = useStyles();

  const messages = globalStore?.portalBannerMessages;

  const loanTermsSteps = [
    {
      title: 'Appraisal Recertification Information',
      icon: null,
      onBack: () => { },
      onNext: () => { },
      allowBack: false,
      allowNext: true,
    },
    {
      title: 'Acknowledgment',
      icon: null,
      onBack: () => { },
      onNext: () => { },
      allowBack: false,
      allowNext: true,
    },
    {
      title: 'Payment',
      icon: null,
      onBack: () => { },
      onNext: () => { },
      allowBack: false,
      allowNext: true,
    },
  ];

  const handleBackStep = () => setActiveStep(activeStep - 1);

  const handleNextStep = () => setActiveStep(activeStep + 1);

  const handleStep = (index: number) => setActiveStep(index);

  useEffect(() => {
    setEntityData(decodeData(encodedParams));
    return () => {
      cdaPaymentsStore.reset();
      cdaPaymentsStore.cdaDocumentStore.reset();
    };
  }, []);

  useEffect(() => {
    if (entityId) {
      cdaPaymentsStore.fetchDocusignAndPaymentStatus(entityId, 'ARR_CDA_Loan');
    }
  }, [entityId]);

  useEffect(() => {
    setDisableStepperNavigation(!docusignStatus);
  }, [docusignStatus]);

  if (entityId) {
    return (
      <Layout>
        <Box my={3}>
          <Typography variant="h3" gutterBottom>
            {'Appraisal 1004D Payment'}
          </Typography>
        </Box>
        {showBanner && <Banner />}
        <Paper className={classes.paper}>
          <StepperComponent
            activeStep={activeStep}
            disableNavigation={disableStepperNavigation}
            handleStep={handleStep}
            steps={loanTermsSteps}
            className={classes.stepper}
          />
          <Box mt={3} className={classes.stepContentWrapper}>
            {activeStep === 0 && (
              <InformationStep
                handleNextStep={handleNextStep}
                entityId={entityId}
                tamariskFlowType={tamariskFlowType}
                setShowBanner={setShowBanner}
              />
            )}
            {activeStep === 1 && (
              <AcknowledmentStep
                handleBackStep={handleBackStep}
                handleNextStep={handleNextStep}
                entityId={entityId}
                tamariskFlowType={tamariskFlowType}
              />
            )}
            {activeStep === 2 && (
              <PaymentStep
                handleDisableStepperNavigation={setDisableStepperNavigation}
                entityData={entityData}
              />
            )}
          </Box>
        </Paper>
      </Layout>
    );
  } else {
    return null;
  }
});

export const Banner = observer(() => {
  const classes = useStyle();
  const { globalStore } = useStore();
  const { routeBaseUrl } = globalStore;

  const bannerMessages: Array<PortalBannerMessages> = [
    {
      title: "Before ordering",
      content: "Please make sure all repairs are completed before ordering a 1004D."
    }
  ];


  let messageComponents = [];
  messageComponents = bannerMessages && bannerMessages.map(message => {
    const content: string = message.content.replace(':portal-base-url', routeBaseUrl);

    return (
      <Grid container className={classes.content} spacing={2}>
        <Grid item>
          <WarningOutlined className={classes.icon} />
        </Grid>
        <Grid item xs={11}>
          <Typography gutterBottom>
            <strong>

              {'Before ordering'}
            </strong>
            <br />
            <div dangerouslySetInnerHTML={{ __html: content }}></div>

          </Typography>
        </Grid>
      </Grid >
    );
  }


  );
  return (
    <>
      {bannerMessages?.length > 0 ? (
        <>
          <Paper className={classes.paper} title="">
            {messageComponents}
          </Paper>
          <br />
        </>) : 'test'
      }
    </>
  );
})
