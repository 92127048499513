import react, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,

  Typography,
  Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { PercentageField, StandardDialog } from '@roc/ui';
import { alpha } from '@material-ui/core/styles';
import { formatCurrency } from '@roc/ui/utils';
import { observer } from 'mobx-react';
import CommentDialog from '../common/commentDialog';

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    minWidth: 750,
    border: '2px #e0e0e0 solid',
    '& td': {
      height: '40px',
      padding: 0,
      paddingTop: '8px',
      paddingBottom: '8px',
    },
    '& th:first-child, td[rowSpan], $addNewItemRow td': {
      paddingLeft: theme.spacing(2),
      paddingRight: '3px',
    },
    '& table tr:last-child td, & table td[rowSpan]': {
      borderBottom: 'none',
    },
  },

  editableRow: {
    position: 'relative',
    cursor: 'pointer',
    '&:hover td': {
      backgroundColor: '#fdefbf',
      transition: theme.transitions.create(['background-color', 'transform'], {
        duration: theme.transitions.duration.standard,
      }),
    },
    '&:hover $rowActions': {
      visibility: 'visible',
    },
    '& td:last-child': {
      [theme.breakpoints.down('md')]: {
        paddingRight: '100px',
      },
    },
  },
  headerCell: {
    height: '41px',
    fontWeight: 'bold',
    padding: '0 4px 0 0',
  },
  titleCell: {
    height: '46px',
    padding: 0,
    borderBottom: '2px #e0e0e0 solid',
  },

  descriptionCell: {
    whiteSpace: 'pre-wrap',
  },

  inputBase: {
    width: '100%',
    '& input': {
      backgroundColor: '#fdefbf',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      fontSize: 14,
      color: 'black',
      fontWeight: theme.typography.fontWeightBold,
      padding: '8px 4px',
      cursor: 'pointer',
      '&:focus': {
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  autocompletePaper: {
    marginLeft: 0,
    marginRight: 0,
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    fontSize: 13,
  },
  autocompleteOption: {
    minHeight: 'auto',
    alignItems: 'flex-start',
    padding: 8,
    '&[data-focus="true"]': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  addNewItemCell: {
    padding: 0,
    height: '40px',
    backgroundColor: '#fdefbf',
  },
  addNewItemRow: {
    '&:hover': {
      backgroundColor: '#fff',
    },
  },
  rowActions: {
    visibility: 'hidden',
    position: 'absolute',
    right: 0,
    top: 0,
    bottom: 0,
    zIndex: 99,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      visibility: 'visible',
    },
  },
  horizontalScroll: {
    [theme.breakpoints.down('md')]: {
      overflowX: 'scroll',
    },
  },
  clickHereToEdit: {
    color: theme.palette.grey[400],
    fontSize: theme.typography.fontSize * 0.8,
  },
  clickHereToEditHidden: {
    visibility: 'hidden',
  },
  title: {
    color: '#333333',
    fontSize: 20,
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  percentageInput: {
    marginLeft: 10,
    marginRight: 48,
  },
  activeChatIcon: {
    color: '#F2BC16',
    cursor: 'pointer',
  },
  disabledChatIcon: {
    color: '#e0e0e0',
  },
}));

const columns: any[] = [
  {
    name: 'LINE ITEM',
    width: '15%',
    align: 'left',
  },
  {
    name: 'DETAIL',
    width: '15%',
    align: 'left',
  },
  {
    name: 'ORIGINAL COST',
    width: '8%',
    align: 'left',
  },
  {
    name: 'DRAW DISBURSED',
    width: '8%',
    align: 'left',
  },
];

const PreviousDrawInformationCategoryTable = observer(({ category, store }) => {
  const classes = useStyles();
  const [openCommentModal, setOpenCommentModal] = useState<boolean>(false);
  const [comment, setComment] = useState<string>('');

  const handleDrawDisbursedPercentageChange = (
    categoryName,
    lineItemName,
    isDetail,
    detailIndex,
    value
  ) => {
    if (isDetail) {
      store.onTableContentChange(
        categoryName,
        lineItemName,
        detailIndex,
        value,
        'loanFunderLLCApprovedPercentage'
      );
    } else {
      store.onTableContentChange(
        categoryName,
        lineItemName,
        null,
        value,
        'loanFunderLLCApprovedPercentage'
      );
    }
  };

  return (
    <Grid item xs={12} md={12}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell colSpan={columns.length} className={classes.titleCell}>
              <Typography className={classes.title}>{category.name}</Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            {columns.map(({ name, ...props }) => (
              <TableCell key={name} className={classes.headerCell} {...props}>
                {name}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {category.subCategories.map((row, index) => {
          const itemized =
            row.itemized.length > 0
              ? row.itemized
              : [
                {
                  cost: row.general.cost,
                  loanFunderLLCApprovedPercentage: row.general.loanFunderLLCApprovedPercentage,
                },
              ];
          return (
            <TableBody key={`${row?.id}_${row?.name}_${index}`}>
              {itemized.map((item, idx) => {
                return (
                  <TableRow
                    key={`${row?.id}_${row?.name}_'${item.name}_${index}`}
                  >
                    {idx === 0 && (
                      <TableCell
                        width={columns[0].width}
                        rowSpan={itemized.length}
                      >
                        {row.name}
                      </TableCell>
                    )}
                    <TableCell width={columns[1].width}>{item.name}</TableCell>
                    <TableCell width={columns[2].width}>
                      {formatCurrency(item.cost, 0)}
                    </TableCell>
                    <TableCell width={columns[3].width}>
                      <div>
                        <PercentageField
                          value={item.loanFunderLLCApprovedPercentage ? item.loanFunderLLCApprovedPercentage : ''}
                          variant="outlined"
                          testId="loanFunderLLCApprovedPercentage"
                          onChange={(name, value) =>
                            handleDrawDisbursedPercentageChange(
                              category.name,
                              row.name,
                              !!row.itemized.length,
                              idx,
                              value
                            )
                          }
                          fullWidth
                          limitedPercentage
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          );
        })}
      </Table>
      <StandardDialog
        open={openCommentModal}
        title="Borrower's Comments"
        maxWidth="sm"
        handleClose={() => setOpenCommentModal(false)}
        dialogContent={
          <CommentDialog
            onClose={() => setOpenCommentModal(false)}
            subTitle={"Borrower's Comment"}
            comment={comment}
          />
        }
      />
    </Grid>
  );
});
export default PreviousDrawInformationCategoryTable;
