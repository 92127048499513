import { Theme } from "@material-ui/core";
import { useAppConfigurations, useBaseStore } from "@roc/feature-app-core";
import { createTheme, lighten, ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { useEffect, useMemo } from "react";
import { PortalConfiguration } from "@roc/feature-types";

const COLOR_PRIMARY = 'rgb(33, 153, 224)';
const OUTLINED_BUTTON_BACKGROUND = 'rgb(226, 246, 255)'

const deepClone = (obj) => JSON.parse(JSON.stringify(obj))

const extendTheme = (theme: Theme): Theme => {
  return createTheme({
    ...theme,
    overrides: {
      ...theme.overrides,
      MuiButton: {
        ...theme.overrides.MuiButton,
        outlined: {
          backgroundColor: OUTLINED_BUTTON_BACKGROUND
        }
      },
    },
    palette: {
      ...theme.palette,
      primary: {
        ...theme.palette.primary,
        main: '#2199E0'
      }
    }
  })
}


export const BorrowerReviewCustomTheme = ({ children }) => {
  const { globalStore } = useBaseStore();
  const { muiTheme } = useAppConfigurations();
  const theme = extendTheme(muiTheme);

  useEffect(() => {
    const portalConfiguration: PortalConfiguration = deepClone(globalStore.portalConfiguration)
    portalConfiguration.portalConfiguration.uiThemeConfiguration.palette.primary.main = COLOR_PRIMARY;
    globalStore.setPortalConfiguration(portalConfiguration)
  }, [])

  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
}