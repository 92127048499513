import { FormStore } from '@roc/feature-app-core';
import { GlobalStore } from '@roc/feature-app-core';
import { borrowerDefaults, isNil } from '@roc/feature-utils';
import { action, computed, makeObservable, observable } from 'mobx';

const borrowerForm = {
  fields: {
    borrowerId: {
      value: null,
      error: null,
      rule: '',
    },
    firstName: {
      value: '',
      error: null,
      rule: 'required',
    },
    lastName: {
      value: '',
      error: null,
      rule: 'required',
    },
    emailAddress: {
      value: '',
      error: null,
      rule: 'required|email',
    },
    personalGuarantor: {
      value: borrowerDefaults.personalGuarantor,
      error: null,
      rule: '',
    },
    creditBackgroundCheck: {
      value: true,
      error: null,
      rule: 'required',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export class NewBorrowerFormStore extends FormStore {
  public globalStore: GlobalStore;

  constructor(globalStore) {
    super({ ...borrowerForm }, globalStore);
    this.globalStore = globalStore;

    makeObservable(this, {
      initCreditBackgroundCheck: action,
      isEditDisabled: computed,
    })
  }

  initCreditBackgroundCheck() {
    const creditProvidedByTpo = this.globalStore.lenderInfo.creditProvidedByTpo;
    if (creditProvidedByTpo) {
      this.onFieldChange('creditBackgroundCheck', !creditProvidedByTpo);
    }
  }

  get isEditDisabled() {
    return !isNil(this.form?.fields?.borrowerId?.value);
  }
}

export default NewBorrowerFormStore;
