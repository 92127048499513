import { Service } from '@roc/client-portal-shared/services';

const url = {
  UPLOAD_DOCUMENT_INTERNAL: '/nportal/rest/priv/loanDocs/uploadLoanDocument/',
  DOWNLOAD_DOCUMENT_INTERNAL: '/nportal/rest/priv/loanDocs/downloadLoanDocument',
  UPLOAD_XML_DOCUMENT_INTERNAL: '/nportal/rest/priv/loanDocs/uploadAppraisalXml/',
  GET_DOCUMENT: '/nportal/rest/priv/loanDocs/getLoanDocumentById',
  UPLOAD_DOCUMENT: '/api/v1/loan/loanDocuments/uploadLoanDocument/',
  UPLOAD_XML_DOCUMENT: '/api/v1/loan/loanDocuments/uploadAppraisalXml/',

};

export class AppraisalReportService extends Service {

  uploadFile(internal: boolean, documentId: number, formData) {
    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }
    return this.post(`${internal ? url.UPLOAD_DOCUMENT_INTERNAL : url.UPLOAD_DOCUMENT}${documentId}`, formData, config);
  }
  uploadAndParseDocument(internal: boolean, documentId: number, formData) {
    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }
    return this.post(`${internal ? url.UPLOAD_XML_DOCUMENT_INTERNAL : url.UPLOAD_XML_DOCUMENT}${documentId}`, formData, config);
  }

  downloadDocumentInternal(document) {
    return this.post(url.DOWNLOAD_DOCUMENT_INTERNAL, document, null, {
      responseType: 'arraybuffer',
    });
  }

  getLoanDocumentById(documentId: number) {
    return this.get(`${url.GET_DOCUMENT}?loanDocumentId=${documentId}`);
  }
}