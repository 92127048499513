import { Service } from '@roc/feature-app-core';

const url = {
  BORROWER_ENTITIES: '/api/v1/loan/borrowerEntity/getAllEntities',
  SUBMIT_LOAN_APPLICATION: '/api/v1/loan/loanApplications/submitLoanApplication',
  GET_CURRENT_USER_BORROWER: '/api/v1/loan/loanApplications/getCurrentUserBorrower'
};

export class NewLoanApplicationService extends Service {
  getAllEntities(
    filters?: string,
    page?: number,
    pageSize?: number,
    sortDir?: string,
    sortBy?: string,
  ) {
    return this.get(
      url.BORROWER_ENTITIES,
      {
        page,
        pageSize,
        sortDir,
        sortBy,
        filters,
      });
  };

  submitLoanApplication(data){
    return this.post(url.SUBMIT_LOAN_APPLICATION, data)
  }

  getCurrentUserBorrower(){
    return this.get(url.GET_CURRENT_USER_BORROWER);
  }
}
