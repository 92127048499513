import { DialogState } from '@roc/ui';
import { action, computed, makeObservable, observable, flow } from 'mobx';
import { GlobalStore } from 'libs/feature-app-core/src/stores/index';
import { Originator } from '../types/originatorTypes';
import { OriginatorsStore } from './originatorStore';
import { UserStore } from 'libs/feature-app-core/src/stores/index';

export class SelectOriginatorsStore {
  private globalStore: GlobalStore;
  userStore: UserStore;
  originatorsStore: OriginatorsStore;
  selectDialogState: DialogState = null;
  editDialogState: DialogState = null;

  originators: Originator[];

  allowMultiple: boolean;


  constructor(originatorsStore: OriginatorsStore, globalStore: GlobalStore) {
    this.globalStore = globalStore;
    this.originatorsStore = originatorsStore;
    this.originators = [];

    makeObservable(this, {
      originators: observable,
      reset: action,
      selectDialogState: observable,
      editDialogState: observable,
      allowMultiple: observable,
      canAddMore: computed,
      obtainCommissionPercentageTotal: observable,
      validOriginatorPercentages: action,
      calculateAndSetSubmitterPercentage: action,
    });
  }

  reset() {
    this.selectDialogState = null;
    this.editDialogState = null;
    this.originators = [];
  }

  openSelectOriginatorsDialog() {
    { console.log(this.originators) }
    this.originatorsStore.originatorsGridStore.resetSelected();
    this.selectDialogState = DialogState.ADD;
  }

  closeSelectOriginatorsDialog() {
    this.selectDialogState = null;
  }

  confirmSelectedOriginators() {
    const selected = this.normalize(this.originatorsStore.originatorsGridStore.selected[0]);
    this.originators.push(selected);
    this.originators = Array.from(new Set(this.originators.map(o => o.lenderId))).map(lenderId => { return this.originators.find(o => o.lenderId === lenderId) })
    this.closeSelectOriginatorsDialog();
  }

  normalize(item) {
    return {
      ...item, ...{
        showEdit: true,
        showDelete: true
      }
    };
  }


  openAddNewOriginatorDialog() {
    this.originatorsStore.originatorFormStore.reset();
    this.editDialogState = DialogState.ADD;
  }

  editOriginator(originator: Originator) {
    this.originatorsStore.originatorFormStore.setOriginator(originator);
    this.editDialogState = DialogState.EDIT;
  }

  closeEditOriginatorDialog() {
    this.editDialogState = null;
  }

  getFormOriginator() {
    const { originatorFormStore } = this.originatorsStore;
    return originatorFormStore.getOriginator();
  }

  confirmEditOriginatorDialog() {
    const { originatorFormStore } = this.originatorsStore;
    originatorFormStore.runFormValidationWithMessage();
    if (originatorFormStore.form.meta.isValid) {
      const originator = originatorFormStore.getOriginator();
      if (this.editDialogState === DialogState.EDIT) {
        this.originators = this.originators.map(current =>
          this.isSameOriginator(current, originator)
            ? { ...current, ...originator }
            : current
        );
      } else {
        this.originators.push(originator);
      }
      this.calculateAndSetSubmitterPercentage();
      this.closeEditOriginatorDialog();
    }
  }

  obtainCommissionPercentageTotal() {
    let comissionTotal = 0
    this.originators.forEach(originator => {
      if (originator.percentage != null) {
        comissionTotal += originator.percentage
      }
    });
    return comissionTotal;
  }

  setExistingOriginatorsFromExternal(originators: Originator[]) {
    this.originators = [...originators];
  }

  validOriginatorPercentages() {
    return this.originators.every(orig => { return orig.percentage != 0 })
  }

  removeOriginator(originator: Originator) {
    this.originators = this.originators.filter(o => !this.isSameOriginator(o, originator));
    this.calculateAndSetSubmitterPercentage();
  }

  calculateAndSetSubmitterPercentage() {
    const percentage = this.originators
      .filter(x => !x.isLoanSubmitter && x.percentage)
      .map(x => x.percentage)
      .reduce((sum, current) => sum + current, 0);
    const index = this.originators.findIndex(x => x.isLoanSubmitter);
    this.originators[index].percentage = 100 - percentage;
  }

  private isSameOriginator(originator1: Originator, originator2: Originator) {
    return originator1.lenderId === originator2.lenderId
  }

  get canAddMore() {
    return this.allowMultiple || this.originators.length === 0;
  }
}

export default SelectOriginatorsStore;
