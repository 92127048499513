import {
  Stepper,
  Step,
  StepLabel,
  StepContent,


} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { Button } from '@roc/ui';
import { FieldContainer } from '@roc/client-portal-shared/components';
import { useStore } from '@roc/client-portal-shared/hooks';
import PropertyStore from '../stores/fixFlip/propertyStore';
import { PropertyModalState } from '../fixflipGroundUp/components/propertyModal';
import PricerStoreStbBridge from '../stores/stabilizedBridge/pricerStoreStbBridge';
import { PropertyDetails, PropertyInformation, PurchaseInformation, PurposeInformation, Refinance_PurchaseInformation } from '../stabilizedBridge/loanPricerStabilizedBridge';
import { AppraisalInformation } from '../stabilizedBridge/loanPricerStabilizedBridge';
import PricerStore from '../stores/singleProperty/pricerStore';
import propertyInformation from '../fixflipGroundUp/propertyInformation';

function getSteps() {
  return [
    'Appraisal information',
    'Property information',
    'Purpose',
    'Other details'
  ];
}

function getViewEditSteps() {
  return [
    'Property information',
    'Purpose',
    'Other details'
  ];
}

function getViewEditStepContent(step: number, isPurchase: boolean, stabilizedBridgeStore: any) {
  switch (step) {
    case 0:
      return <PropertyDetails store={stabilizedBridgeStore} />
    case 1:
      return isPurchase ? (
        <PurchaseInformation />
      ) : (
        <Refinance_PurchaseInformation />
      );
    case 2:
      return <PropertyInformation store={stabilizedBridgeStore} />
    default:
      return 'Unknown step';
  }
}

function getStepContent(step: number, isPurchase: boolean, stabilizedBridgeStore: any) {
  switch (step) {
    case 0:
      return <AppraisalInformation />
    case 1:
      return <PropertyDetails store={stabilizedBridgeStore} />
    case 2:
      return isPurchase ? (
        <PurchaseInformation />
      ) : (
        <Refinance_PurchaseInformation />
      );
    case 3:
      return <PropertyInformation store={stabilizedBridgeStore} />
    default:
      return 'Unknown step';
  }
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    button: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    actionsContainer: {
      marginBottom: theme.spacing(2),
    },
    resetContainer: {
      padding: theme.spacing(3),
    },
  })
);

function isAddFormValid(
  step: number,
  isPurchase: boolean,
  store: PricerStoreStbBridge
) {
  switch (step) {
    case 0:
      return store.appraisalInformationStore.form.meta.isValid;
    case 1:
      return store.propertyDetailsStore.form.meta.isValid;
    case 2:
      return isPurchase
        ? store.purchaseInformationStore.form.meta.isValid
        : store.refinance_purchaseInformationStore.form.meta.isValid
    case 3:
      return store.form.meta.isValid
    default:
      return 'Unknown step';
  }
}

function isViewEditFormValid(
  step: number,
  isPurchase: boolean,
  store: PricerStoreStbBridge
) {
  switch (step) {
    case 0:
    case 1:
      store.propertyDetailsStore.runFormValidation();
      return store.propertyDetailsStore.form.meta.isValid;
    case 2:
      store.purchaseInformationStore.runFormValidation();
      store.refinance_purchaseInformationStore.runFormValidation();
      return isPurchase
        ? store.purchaseInformationStore.form.meta.isValid
        : store.refinance_purchaseInformationStore.form.meta.isValid
    case 3:
      store.runFormValidation();
      return store.form.meta.isValid
    default:
      return 'Unknown step';
  }
}

export const PropertyStepsStabilizedBridge = observer(
  ({ modalState, handleAdd, handleEdit }) => {
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const steps =
      modalState == PropertyModalState.ADD ? getSteps() : getViewEditSteps();
    const {
      stabilizedBridgeStore: { pricerStore },
      appraisalsStore,
    } = useStore();
    const { stabilizedBridgeStore } = useStore();
    const isPurchase = stabilizedBridgeStore.pricerStore.propertyDetailsStore.form.fields.loanPurpose.value === 'Purchase';


    useEffect(() => {
      appraisalsStore.fetchStandaloneAppraisals();
    }, [appraisalsStore]);

    const handleNext = () => {
      if (activeStep === steps.length - 1) {
        modalState == PropertyModalState.ADD ? handleAdd() : handleEdit();
        return;
      }
      setActiveStep(prevActiveStep => prevActiveStep + 1);
    };

    const handleBack = () => {
      setActiveStep(prevActiveStep => prevActiveStep - 1);
    };

    const validateStep = () => {
      if (pricerStore.appraisalId && activeStep != 3) {
        return true
      }
      return false
    };

    return (
      <div className={classes.root}>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
              <StepContent TransitionProps={{ unmountOnExit: false }}>
                {modalState == PropertyModalState.ADD
                  ? getStepContent(index, isPurchase, stabilizedBridgeStore)
                  : getViewEditStepContent(index, isPurchase, stabilizedBridgeStore)}
                <div className={classes.actionsContainer}>
                  <FieldContainer>
                    <>
                      <Button
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        className={classes.button}
                        testId={`back-${activeStep + 1}`}
                      >
                        Back
                      </Button>
                      <Button
                        variant="contained"
                        disabled={
                          validateStep()
                            ? false
                            : modalState == PropertyModalState.ADD
                              ? !isAddFormValid(index, isPurchase, pricerStore)
                              : !isViewEditFormValid(
                                index,
                                isPurchase,
                                pricerStore
                              )
                        }
                        color="primary"
                        onClick={handleNext}
                        className={classes.button}
                        testId={activeStep === steps.length - 1 ? `finish` : `next-${activeStep + 1}`}
                      >
                        {activeStep === steps.length - 1
                          ? modalState == PropertyModalState.ADD
                            ? 'Finish & Add Property'
                            : 'Finish'
                          : 'Next'}
                      </Button>
                    </>
                  </FieldContainer>
                </div>
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </div>
    );
  }
);
