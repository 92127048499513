import {
  downloadAsPdf as _downloadAsPdf,
  getPdfBlobUrl as _getPdfBlobUrl,
  GeneratePdfOptions,
} from '@roc/feature-utils';
import { HTMLAttributes, useImperativeHandle, useRef, forwardRef } from 'react';

export interface PrintableAreaProps extends HTMLAttributes<HTMLDivElement> {
  printClass?: string;
}

export interface PrintableAreaRef {
  downloadAsPdf: (documentName: string, options?: GeneratePdfOptions) => void;
  getPdfBlobUrl: (options?: GeneratePdfOptions) => Promise<URL>;
}

export const PrintableArea = forwardRef<PrintableAreaRef, PrintableAreaProps>(
  (props, ref) => {
    const containerRef = useRef<HTMLDivElement>();

    const getOptions = options => {
      return {
        onclone: (doc, el) => {
          return (el.className += ' ' + (props.printClass ?? ''));
        },
        scale: 2,
        ...options,
      };
    };

    const downloadAsPdf = (documentName, options) => {
      return _downloadAsPdf(
        containerRef.current,
        documentName,
        getOptions(options)
      );
    };

    const getPdfBlobUrl = options => {
      return _getPdfBlobUrl(containerRef.current, getOptions(options));
    };

    useImperativeHandle(ref, () => ({
      downloadAsPdf,
      getPdfBlobUrl,
    }));

    return <div {...props} ref={containerRef} />;
  }
);
