import { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { formatCurrency } from '@roc/ui/utils';
import { observer } from 'mobx-react';
import {
  DataGrid as Grid,
  GridColumnProps,
  SelectMode,
  GridRowSelectedEvent
} from '@roc/ui';
import { GridStore } from '@roc/feature-app-core';

const ListenForSelectedRow = observer(({ gridStore, params, promotionId }) => {
  useEffect(() => {
    params.node.setSelected(params.node.data?.id == promotionId);
  }, [gridStore.gridData.data.rows, promotionId]);
  return null;
});

const noOpComparator = () => 0;

export type PromotionGridProps = {
  store: GridStore;
  promotionData: {
    setPromotion: (promotionId: number) => void;
    promotionId: number;
  }
};

const useStyles = makeStyles(() => ({
  container: {
    height: 435,
  },
}));

export const SelectPromotionGrid = observer(
  (props: PromotionGridProps) => {
    const { store, promotionData } = props;
    const classes = useStyles();

    const columns: GridColumnProps[] = [
      {
        field: 'promotionName',
        headerName: 'Name',
        minWidth: 100,
        cellRenderer: 'listenerCellRenderer',
        comparator: noOpComparator,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
        },
      },
      {
        field: 'value',
        headerName: 'Value',
        minWidth: 100,
        comparator: noOpComparator,
        cellRenderer: 'currencyCellRenderer',
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
        },
      },
      {
        field: 'creditType',
        headerName: 'Promotion Type',
        minWidth: 250,
        comparator: noOpComparator,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
        },
      },
    ];

    const onRowSelected = (e) => {
      if (e.node.isSelected()) {
        promotionData.setPromotion(e.node.data.id);
        return;
      }
      promotionData.setPromotion(null);
    };

    const frameworkComponents = {
      currencyCellRenderer: params => params.value ? formatCurrency(params.value) : '',
      listenerCellRenderer: params => (<>{params.value}<ListenForSelectedRow gridStore={store} params={params} promotionId={promotionData.promotionId} /></>)
    };

    const onFilterChanged = params => {
      const filterModel = params.api.getFilterModel();
      store.setFilterModel(filterModel);
    };

    const onSortChanged = params => {
      const sortModel = params.api.getSortModel();
      store.setSortModel(sortModel);
    };

    return (
      <>
        <Grid
          className={classes.container}
          onRowSelected={onRowSelected}
          selectMode={SelectMode.SINGLE}
          columns={columns}
          rows={store.gridData.data.rows}
          frameworkComponents={frameworkComponents}
          isLoading={store.isLoading}
          onFilterChanged={onFilterChanged}
          showFilters={store.showFilters}
          onSortChanged={onSortChanged}
          sortModel={store.sortModel}
          filterModel={store.filterModel}
          paginationData={store.gridData.meta}
          setPageNumber={store.setPageNumber}
        />
      </>
    );
  }
);

export default SelectPromotionGrid;
