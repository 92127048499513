import { Box, Grid } from '@material-ui/core';
import { observer } from "mobx-react";
import { useUserStore } from '@roc/feature-app-core';
import { BasicDetailsCard } from "./basicDetailsCard";
import { UserPreferenceCard } from './userPreferenceCard';
import { RingCentralAuthCard } from 'apps/crm-portal/src/app/modules/ringCentral/ringCentralAuthCard';
import { PhoneBurnerAuthCard } from 'apps/crm-portal/src/app/modules/phoneBurner/phoneBurnerAuthCard'
import { LinkedInAuthCard } from 'apps/crm-portal/src/app/modules/linkedIn/linkedInAuthCard'


interface GeneralProps {
  children?: JSX.Element
  profileTitle?: string
  userPreferenceCardTitle?: string
}

export const General = observer(({ children, profileTitle, userPreferenceCardTitle }: GeneralProps) => {
  const { userStore } = useUserStore();
  return (
    <Grid container spacing={2} alignItems="stretch">
      {/* <Grid item xs={12} md={5} >
        <ProfilePictureCard />
      </Grid> */}
      {userStore?.allowLoanPricing && !userStore?.isBackOfficeUser ? (
        <>
          <Grid item md={7} lg={7} xl={8} xs={12}>
            <BasicDetailsCard title={profileTitle} />
          </Grid>
          <Grid item md={5} lg={5} xl={4} xs={12}>
            <UserPreferenceCard title={userPreferenceCardTitle} />
          </Grid>
          {userStore?.isMarketingUser &&
            <Grid item xs={12} md={6}>
              <LinkedInAuthCard />
            </Grid>}
        </>
      ) : (
        <>
          <Grid item md={12} lg={12} xs={12}>
            <BasicDetailsCard title={profileTitle} />
          </Grid>
          {userStore?.isBackOfficeUser &&
            <Grid item xs={12} md={6}>
              <RingCentralAuthCard />
            </Grid>}
          {userStore?.isBackOfficeUser &&
            <Grid item xs={12} md={6}>
              <PhoneBurnerAuthCard />
            </Grid>}
        </>
      )
      }
    </Grid>
  );
});