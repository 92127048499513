import { Button, StandardDialog, AgGridColumnProps, DataGrid } from '@roc/ui';
import { observer } from 'mobx-react';
import { Tabs } from '@roc/ui';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Divider, Grid, Typography, IconButton } from '@material-ui/core';
import { formatCurrency } from '@roc/ui/utils';
import { useFastTrackModalStyles } from '@roc/feature-loans';
import BorrowerClosingAgent from '../../borrowerClosingAgent/borrowerClosingAgent';
import { Property } from '@roc/feature-types';
import { Publish } from '@material-ui/icons';
import LoanFastrackPaymentStore from 'libs/feature-documents/src/documents/stores/documentForms/loanFastrackPaymentStore';
import CompanyForm from 'libs/feature-loans/src/loanSubmission/components/companyForm';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';

const noOpComparator = () => 0;

const columns: AgGridColumnProps[] = [
  {
    field: 'label',
    headerName: 'Document Upload Category',
    minWidth: 300,
    comparator: noOpComparator,
    sortable: false,
  },
  {
    field: 'actions',
    headerName: 'Actions',
    maxWidth: 100,
    cellRenderer: 'actionsCellRenderer',
    comparator: noOpComparator,
    sortable: false,
  },
];

export type AddtionalFastTrackDataProps = {
  open?: boolean;
  onClose?: () => void;
  fastTrackFees: any;
  fastTrackInfo: any;
  properties: Property[];
  canEditBorrowerInfo: boolean;
  onSubmit: () => void;
  underwritingDocuments: any,
  closingDocuments: any,
  onDocumentUpload: (file: any, docRef: any, loanProcess: any, onSuccess?: () => any, drawId?: any) => any;
  loanFastrackPaymentStore: LoanFastrackPaymentStore,
  loan: any,
};

const useStyles = makeStyles((theme: Theme) => {
  return {
    borrowerAttorneySubTitle: {
      margin: theme.spacing(0, 0, 2)
    },
    subTitle: {
      marginTop: '-25px',
      marginBottom: '15px'
    },
    tabContainer: {
      border: 'none',
      margin: theme.spacing(0, 0, 3)
    },
  };
});

const TABS = [
  { label: 'BORROWER INFORMATION', subTitle: 'To help the fast track process, please provide the following information, if you have it.' },
  { label: 'ADDITIONAL DOCUMENTS', subTitle: 'To help the fast track process, please provide the following information, if you have it.' }
];

const TITLE_INFORMATION_TABS = [
  { label: 'BORROWER INFORMATION', subTitle: 'To help the fast track process, please provide the following information, if you have it.' },
  { label: 'PREVIOUS TITLE INFORMATION', subTitle: 'To help the fast track process, please provide the following information, if you have it.' },
  { label: 'ADDITIONAL DOCUMENTS', subTitle: 'To help the fast track process, please provide the following information, if you have it.' }
];

const AddtionalFastTrackData = observer((props: AddtionalFastTrackDataProps) => {
  const {
    open,
    onClose,
    fastTrackFees,
    fastTrackInfo,
    properties,
    canEditBorrowerInfo,
    onSubmit,
    underwritingDocuments,
    closingDocuments,
    onDocumentUpload,
    loanFastrackPaymentStore,
    loan,
  } = props;

  const getPropertyFile = (address, section, fileName) => {
    if (!underwritingDocuments) {
      return null;
    }
    const keyFile = Object.keys(underwritingDocuments).find(key => key.includes(address) && key.includes(section));

    if (!keyFile) {
      return null;
    }
    const file = underwritingDocuments[keyFile].find(lineDoc => lineDoc.documentName === fileName);
    return file;
  };

  const getClosingFile = (section, fileName) => {
    if (!closingDocuments) {
      return null;
    }
    const keyFile = Object.keys(closingDocuments).find(key => key === section);

    if (!keyFile) {
      return null;
    }
    const file = closingDocuments[keyFile].find(lineDoc => lineDoc.documentName === fileName);
    return file;
  }

  const propertiesQuantity = properties?.length;
  const [selectedTab, setSelectedTab] = useState(0);
  const [total, setTotal] = useState(0);
  const styles = useStyles();
  const fastTrackClasses = useFastTrackModalStyles();
  const inputRef = useRef(null);
  const [activeDocData, setActiveDocData] = useState(null);
  const [rows, setRows] = useState([]);

  const onUpload = useCallback(
    ({ doc, process }) => {
      setActiveDocData(doc ? { doc, process } : null);
      inputRef.current.click();
    },
    [inputRef]
  );

  const frameworkComponents = {
    actionsCellRenderer: params => {
      return (
        <IconButton onClick={e => onUpload(params.node.data)}>
          <Publish color="action" />
        </IconButton>
      );
    },
  };

  useEffect(() => {
    if (fastTrackInfo && fastTrackFees) {
      let totalCalc = 0;

      if (fastTrackInfo.insuranceReview) {
        totalCalc += fastTrackFees.insuranceReviewFee * propertiesQuantity;
      }
      if (fastTrackInfo.insurance) {
        totalCalc += fastTrackFees.insuranceFee * propertiesQuantity;
      }
      if (fastTrackInfo.titleSearch) {
        totalCalc += fastTrackFees.titleSearchFee * propertiesQuantity;
      }
      if (fastTrackInfo.rushedAppraisal) {
        totalCalc += fastTrackFees.rushedAppraisalFee * propertiesQuantity;
      }
      setTotal(totalCalc);
    }
  }, [fastTrackInfo, fastTrackFees]);

  useEffect(() => {
    if (properties?.length && Object.keys(underwritingDocuments).length
      && Object.keys(closingDocuments).length && !rows.length) {
      const newRows = [];

      const existingInsuranceDoc = getClosingFile('Insurance', 'Existing Insurance Document')
      if (existingInsuranceDoc) {
        newRows.push({ label: 'Existing Insurance Document', doc: existingInsuranceDoc, process: 'closing' });
      }

      properties?.forEach(property => {
        const purchaseContract = getPropertyFile(property.address, 'Loan Documents', 'Purchase Contract');
        if (purchaseContract) {
          newRows.push({ label: `${property.address} - Purchase Contract`, doc: purchaseContract, process: 'underwriting' });
        }
        const titleDocument = getLatestDocumentVersion(property.address, 'Loan Documents', 'Existing Title Document');
        if (titleDocument) {
          newRows.push({ label: `${property.address} - Existing Title Document`, doc: titleDocument, process: 'underwriting' });
        }
      });

      if (newRows.length) {
        setRows([...rows, ...newRows]);
      }
    }
  }, [properties, underwritingDocuments, closingDocuments]);

  const getLatestDocumentVersion = (address: string, documentSection: string, documentName: string) => {
    const document = getPropertyFile(address, documentSection, documentName);
    if (documentName == 'Exisiting Title Document') {
      return document == null ? getPropertyFile(address, documentSection, 'Existing Title Document (Optional to speed up title searches)') : document;
    }
  }

  const uploadHandler = (file, docData) => {
    if (docData) {
      onDocumentUpload(file, docData.doc, docData.process);
    }
  };

  const onTabChange = (e, index) => setSelectedTab(index);

  const renderAdditionalInformation = () => (
    <>
      <Grid item xs={12}>
        <Typography variant="h6" className={styles.borrowerAttorneySubTitle}>Borrower Attorney Information</Typography>
        <BorrowerClosingAgent />
      </Grid>
    </>
  );

  const renderAdditionalFiles = () => (
    <Grid item xs={12}>
      <input
        accept="*"
        type="file"
        onChange={event => uploadHandler(event.target.files[0], activeDocData)}
        hidden
        ref={inputRef}
      />
      <DataGrid
        columns={columns}
        rows={rows}
        frameworkComponents={frameworkComponents}
        domLayout="autoHeight"
      />
    </Grid>
  )

  const renderTitleInformation = () => (
    <CompanyForm
      store={loanFastrackPaymentStore.otherTitleFormStore}
    />
  );

  const renderButtons = () => (
    <>
      <Grid
        item
        container
        className={fastTrackClasses.buttonsSection}
        xs={12}
        spacing={1}
        justifyContent="center"
      >
        <Grid item>
          <Button
            variant="outlined"
            color="primary"
            onClick={onClose}
            testId="closeFastTrackModal"
            className={fastTrackClasses.buttonStyle}
          >
            Close
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={onSubmit}
            testId="closeFastTrackModal"
            className={fastTrackClasses.buttonStyle}
            disabled={!canEditBorrowerInfo}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </>
  )

  const renderContent = () => {
    let tabs = TABS;
    if (fastTrackInfo && fastTrackInfo.titleAttorneySearch && !loan?.titleCompany.companyId) {
      tabs = TITLE_INFORMATION_TABS;
    }
    return (
      <>
        <Typography className={styles.subTitle}>{tabs[selectedTab].subTitle}</Typography>
        <Tabs
          tabs={tabs}
          selectedTab={selectedTab}
          onTabChange={onTabChange}
          className={styles.tabContainer}
        />
        <Grid container spacing={2}>
          {tabs.length > 2 ? (
            <>
              {selectedTab === 0 && renderAdditionalInformation()}
              {selectedTab === 1 && renderTitleInformation()}
              {selectedTab === 2 && renderAdditionalFiles()}
            </>
          ) : (
            <>
              {selectedTab === 0 && renderAdditionalInformation()}
              {selectedTab === 1 && renderAdditionalFiles()}
            </>
          )}

          {renderButtons()}
        </Grid>
      </>
    )
  };

  return (
    <StandardDialog
      open={open}
      title="Fast Track Loan"
      maxWidth="md"
      handleClose={onClose}
      dialogContent={renderContent()}
    />
  );
});

export default AddtionalFastTrackData;
