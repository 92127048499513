import { Page } from '@roc/feature-app-core';
import { Route } from 'react-router';
import { HtmlDocument } from '../components/htmlDocument';
import { DocumentLibraryHome } from '../documentLibraryHome';
import { useDocumentLibraryStore } from '../hooks/useDocumentLibraryStore';

const DOCUMENT_PATH = ':contentId';

export const getDocumentLibraryRoutesConfig = (
  basePath: string,
  baseUrl: string
) => {
  return {
    documentLibraryHome: {
      path: `${basePath}/document-library-home`,
      url: `${baseUrl}/document-library-home`,
      documentTitle: 'Document Library-home'
    },
    documentLibraryDocument: (contentId?: string) => ({
      path: `${basePath}/document-library/${DOCUMENT_PATH}`,
      url: `${baseUrl}/document-library/${contentId}`,
      documentTitle: `Document Library - ${contentId}`
    }),
    documentLibraryDocumentPreview: (contentId?: string) => ({
      path: `${basePath}/document-library/${DOCUMENT_PATH}/preview`,
      url: `${baseUrl}/document-library/${contentId}/preview`,
      documentTitle: `Document Library - ${contentId} - Preview`
    }),
  };
};

export const getDocumentLibraryRoutes = (basePath: string, baseUrl: string) => {
  const config = getDocumentLibraryRoutesConfig(basePath, baseUrl);
  const { documentLibraryStoreNew } = useDocumentLibraryStore();

  return {
    documentLibraryHome: (
      <Route exact path={config.documentLibraryHome.path}>
        <Page routeDefinition={config.documentLibraryHome}>
          <DocumentLibraryHome documentLibraryStoreNew={documentLibraryStoreNew} />
        </Page>
      </Route>
    ),
    documentLibraryDocument: (
      <Route exact path={config.documentLibraryDocument().path}>
        <HtmlDocument documentLibraryStoreNew={documentLibraryStoreNew} />
      </Route>
    ),
    documentLibraryDocumentPreview: (
      <Route exact path={config.documentLibraryDocumentPreview().path}>
        <HtmlDocument preview documentLibraryStoreNew={documentLibraryStoreNew} />
      </Route>
    ),
  };
};
