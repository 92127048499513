import { ApiResponse } from '@roc/client-portal-shared/services';
import { observable, action, flow, makeObservable } from 'mobx';
import { GlobalStore } from '@roc/client-portal-shared/stores';
import { LoanTermsService } from '@roc/feature-loan-fasttrack';

export class LoanTermsStore {
  private globalStore: GlobalStore;
  private loanTermsService: LoanTermsService;
  docusignStatus: boolean = false;
  paymentStatus: boolean = false;
  loanTermsUrl: string = null;

  constructor(globalStore) {
    this.globalStore = globalStore;
    this.loanTermsService = new LoanTermsService();
    makeObservable(this, {
      docusignStatus: observable,
      fetchDocusignAndPaymentStatus: flow,
      fetchGFDDocument: flow,
      paymentStatus: observable,
      setDocusignStatus: action,
      setLoanTermsUrl: action,
      loanTermsUrl: observable,
      reset: action
    });
  }

  setDocusignStatus(status: boolean) {
    this.docusignStatus = status;
  }

  setLoanTermsUrl(url: string) {
    this.loanTermsUrl = url;
  }

  *fetchDocusignAndPaymentStatus(loanId: string, entityType: string) {
    try {
      const response: ApiResponse = yield this.loanTermsService.getDocusignAndPaymentStatus(
        loanId,
        entityType
      );
      const { data } = response;

      if (data) {
        this.docusignStatus = data.docusignStatus;
        this.paymentStatus = data.paymentStatus;
      }
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'There was an error while retrieving payment status information',
      });
    }
  }

  *fetchGFDDocument(loanId: string, callbackUrl: string) {
    try {
      const response: ApiResponse = yield this.loanTermsService.getGFDDocusign(
        loanId,
        callbackUrl
      );
      const { data } = response;

      this.loanTermsUrl = data ? data : null;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'There was an error while retrieving acknowledgment document',
      });
    }
  }

  reset() {
    this.docusignStatus = false;
    this.paymentStatus = false;
    this.loanTermsUrl = null;
  }

}

export default LoanTermsStore;
