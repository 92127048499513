import { GlobalStore, UserStore } from '@roc/feature-app-core';
import { RentalPricerService } from '../services/rentalPricerService';
import { RentalPricerBaseStore } from './rentalPricerBaseStore';
import { RentalPricerDetailsStore } from './rentalPricerDetailsStore';
import { RentalPricerGridStore } from './rentalPricerGridStore';
import {
  RentalPortfolioStore,
  SinglePropertyStore,
} from '@roc/feature-loans';
export class RentalPricerStore extends RentalPricerBaseStore {
  constructor(globalStore: GlobalStore, userStore: UserStore, singlePropertyStore: SinglePropertyStore, rentalPortfolioStore: RentalPortfolioStore) {
    super(globalStore);
    this.rentalPricerService = new RentalPricerService();
    this.rentalPricerGridStore = new RentalPricerGridStore(
      globalStore,
      this.rentalPricerService,
    );
    this.rentalPricerDetailsStore = new RentalPricerDetailsStore(
      globalStore,
      singlePropertyStore,
      rentalPortfolioStore,
       this.rentalPricerService,
    );
  }
}
