import { Box, Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { Close, CloudUpload, Description, Height } from '@material-ui/icons';
import { Button, StandardDialog, TextField } from '@roc/ui';
import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { ExtensionRequestStore } from '../../stores';
import { useExtensionRequestStore } from './../modules/loanRequests/hooks/useExtensionRequestStore';

const EXTENSION_REGEX = /.[^.]+$/;

const useStyles = makeStyles(() => ({
  button: {
    minWidth: '10vw',
  },
  top: {
    marginBottom: 10,
  },
  dragArea: {
    border: '2px dotted #427ED1',
    width: '100%',
    minHeight: '25vh',
    backgroundColor: '#EAF4FA',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: 20,
    marginBottom: 38,
    borderRadius: 5,
    cursor: 'pointer',
  },
  icon: {
    minWidth: '20px',
    minHeight: '20px',
    width: '3vw',
    height: '3vw',
    color: '#4B89DA',
  },
  fileContainer: {
    border: '1px solid grey',
    backgroundColor: '#EAF4FA',
    padding: '10px 20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '10px 0',
    borderRadius: 5,
  },

  fileName: {
    marginLeft: 5,
    marginRight: 2,
    flexGrow: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  title: {
    // fontWeight: 500,
    // marginTop: -35,
    // paddingBottom: 12,
    // fontFamily: 'Oswald, sans-serif',
    // lineHeight: 1.235,
    // fontSize: '1.5rem',
    // color: '#000000',
    fontWeight: 500,
    marginTop: -35,
    paddingBottom: 12,
    fontFamily: 'Oswald, sans-serif',
    lineHeight: 1.235,
    fontSize: 34,
    color: '#000000',
  },
  buttonCancel: {
    minWidth: '200px',
    marginRight: '100px'
  },
  buttonSubmit: {
    minWidth: '200px',
    marginRight: '200px'
  },
}));

export interface FileUpload {
  name: string;
  file: File;
}

interface ExtensionsFileUploadModalProps {
  title: string;
  open: boolean;
  top?: React.ReactNode;
  multiple?: boolean;
  disabled?: boolean;
  onClose: () => void;
  onUpload: (uploads: FileUpload[]) => void;
}

interface FileUploadItemProps {
  name: string;
  file: File;
  onSaveName: (name: string) => void;
  onRemove: () => void;
}

const FileUploadItem = (props: FileUploadItemProps) => {
  const classes = useStyles();
  const [isEditing, setIsEditing] = useState(false);
  const [newName, setNewName] = useState(() =>
    props.name.replace(EXTENSION_REGEX, '')
  );

  const onEditName = () => {
    setIsEditing(true);
  };

  const onSaveName = () => {
    const extension = props.name.match(EXTENSION_REGEX)?.[0] ?? '';
    props.onSaveName(newName + extension);
    setIsEditing(false);
  };

  const onRemove = () => {
    props.onRemove();
  };

  return (

    <Box className={classes.fileContainer}>
      <Description />
      {isEditing ? (
        <>
          <TextField
            testId="file-name"
            value={newName}
            fullWidth
            onChange={e => setNewName(e.target.value)}
          />
          <Button testId="save" variant="outlined" onClick={onSaveName}>
            Save
          </Button>
        </>
      ) : (
        <>
          <div className={classes.fileName}>{props.name}</div>
          <Button
            testId="save"
            size="small"
            variant="outlined"
            onClick={onEditName}
          >
            Rename
          </Button>
        </>
      )}
      <Close onClick={onRemove} />
    </Box>
  );
};

export const ExtensionsFileUploadModal = ({
  title,
  open,
  top,
  multiple,
  disabled,
  onClose,
  onUpload,
}: ExtensionsFileUploadModalProps) => {
  const [items, setItems] = useState<FileUpload[]>([]);
  const { extensionRequestStore } = useExtensionRequestStore();
  const classes = useStyles();

  const uploadAction = () => {
    onUpload(items);
    extensionRequestStore.onFormSubmit();
    closeAction();
  };

  const closeAction = () => {
    onClose();
    setItems([]);
  };

  const onDrop = (acceptedFiles: File[]) => {
    const newItems = acceptedFiles.map(file => ({
      name: file.name,
      file: file,
    }));
    setItems(prevItems => [...(multiple ? prevItems : []), ...newItems]);
  };

  const onRemove = (index: number) => {
    setItems(prevFiles => {
      const newFiles = [...prevFiles];
      newFiles.splice(index, 1);
      return newFiles;
    });
  };

  const onRename = (index: number, newName: string) => {
    setItems(prevItems => {
      const items = [...prevItems];
      items[index].name = newName;
      return items;
    });
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple,
    maxFiles: multiple ? 0 : 1,
  });

  return (

    <StandardDialog
      title={title}
      open={open}
      handleClose={closeAction}
      maxWidth="md"
      dialogActions={

        <Box>
          <Button className={classes.buttonCancel}
            testId="cancel"
            variant="outlined"
            color="primary"
            onClick={closeAction}
          >
            Cancel
          </Button>
          <Button className={classes.buttonSubmit}
            testId="upload"
            variant="contained"
            color="primary"
            disabled={disabled || !items.length}
            onClick={uploadAction}
          >
            Save
          </Button>
        </Box>
      }
      dialogContent={
        <>
          <div style={{ height: '429px' }}>
            <Typography className={classes.title}>Request Extension</Typography>
            <Typography>Thank you for requesting the loan extension. Please upload the <strong>Insurance Documents </strong>
              to help<br /> the request process.
            </Typography>
            {top && <Box className={classes.top}>{top}</Box>}
            <Box {...getRootProps()} className={classes.dragArea}>
              <input {...getInputProps()} />
              <CloudUpload className={classes.icon} />
              {isDragActive ? (
                <p>Drop the file(s) here...</p>
              ) : (
                <>
                  <p>Drag and drop here</p>
                  <p>or browse</p>
                </>
              )}
            </Box>
            <Box>
              {items.map((item, i) => (
                <FileUploadItem
                  key={item.file.name}
                  name={item.name}
                  file={item.file}
                  onSaveName={newName => onRename(i, newName)}
                  onRemove={() => onRemove(i)}
                />
              ))}
            </Box>
          </div>
        </>
      }
    />
  );
};
