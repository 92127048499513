import { observer } from 'mobx-react';
import {
  NewBorrowerBaseballCard,
  StandardDialog,
} from '@roc/ui';

import { Document } from '@roc/feature-types';
import { useCreditReviewStore } from 'libs/feature-borrower-review/src/hooks/useCreditReviewStore';
import { useBorrowerBaseballCardStore } from 'libs/feature-borrowers/src/borrowers/hooks/useBorrowerBaseballCardStore';


interface BorrowerBaseballCardDialogProps {
  open: boolean;
  onClose: () => void;
  loanId: any;
  document: Document;
}

export const NewBorrowerBaseballCardDialog = observer(({ open, onClose, loanId, document }: BorrowerBaseballCardDialogProps) => {
  const { borrowerBaseballCardStore } = useBorrowerBaseballCardStore();

  borrowerBaseballCardStore.currentBorrower = document?.borrowerId;
  borrowerBaseballCardStore.loanId = Number(document?.loanId);

  return (
    <StandardDialog
      open={open}
      maxWidth="xl"
      handleClose={onClose}
      title={
        `${borrowerBaseballCardStore.baseballCardDetails?.firstName} ${borrowerBaseballCardStore.baseballCardDetails?.lastName} - Track Record Review`
      }
      removeContentBox
      dialogContent={
        <NewBorrowerBaseballCard store={borrowerBaseballCardStore}
          onClose={onClose}
          hideTitle={true}
        />
      }
    />)
});