import { Container, Grid } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { useRentalPortfolioStore } from '../../loanSubmission';
import { SectionName } from '../components/rentalPricerComponents';
import {
  MaximumAsIsValue,
  MinimumAsIsValue,
  PricerTargetLtv,
  PropertyManagement,
  TotalEstimatedAsIsValue,
  TotalMixUseEstimatedAsIsValue
} from '../components/rentalPricerFields';

export const PortfolioInformationStep = observer(() => {
  const { rentalPortfolioStore } = useRentalPortfolioStore();
  const { pricerStore } = rentalPortfolioStore;
  const { form } = pricerStore;
  const store = rentalPortfolioStore;

  const hasMixedUseProperties = false;

  return (
    <Container maxWidth="md">
      <Grid container spacing={2}>
        <SectionName name="Portfolio Information" />
        <PricerTargetLtv store={store} />
        <MinimumAsIsValue />
        <MaximumAsIsValue />
        <TotalEstimatedAsIsValue />
        <PropertyManagement />
        {hasMixedUseProperties && <TotalMixUseEstimatedAsIsValue />}
      </Grid>
    </Container>
  );
});
