import { Grid, Box, Paper, Typography } from "@material-ui/core";
import { Card } from "@roc/ui";
import { observer } from "mobx-react";
import { activityTypeOptions } from "../leads/constants/constants";
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@roc/ui';
import { Add } from '@material-ui/icons';
import { HtmlContainer } from '../leadDetails/htmlContainer';
import { EditLeadActivityDialog } from "./editLeadActivityDialog";
import { Note } from "./note";
import { EditLeadTaskDialog } from "./editLeadTaskDialog";
import { formatPhoneNumber } from "@roc/ui/utils";
import { DeletableItem } from "./deletableItem";

const useStyles = makeStyles(theme => ({
  button: {
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(2),
    minWidth: '150px',
  },
  notesScroll: {
    backgroundColor: '#FAFAFA',
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    maxHeight: '800px',
    overflowY: 'auto',
    overflowX: 'hidden',
    boxShadow: '0 0 2px #DDD inset'
  },
  noteCard: {
    borderRadius: 0,
    boxShadow: '0px 2px 3px #EEE',
    backgroundColor: '#FFFFFF',
  },
}));

export const AdditionalContactsCard = observer(({ leadDetailsStore }) => {
  const classes = useStyles();

  const handleDelete = (data, reason) => {
    leadDetailsStore.deleteAdditionalContactData(data, reason);
  };

  const phoneReasonOptions = [
    { label: 'Invalid contact info', value: 'Invalid contact info' },
    { label: 'Lead does not want to be contacted', value: 'Lead does not want to be contacted' },
    { label: 'Number not in use', value: 'Number not in use' }
  ]

  const emailReasonOptions = [
    { label: 'Invalid contact info', value: 'Invalid contact info' },
    { label: 'Lead does not want to be contacted', value: 'Lead does not want to be contacted' },
    { label: 'Email not in use', value: 'Email not in use' }
  ]

  return (
    <>
      <Card
        title="Additional Emails"
        cardContentProps={{ className: classes.notesScroll }}
      >


        {leadDetailsStore.additionalEmails.length > 0 ? (
          <Grid item xs={12} className={classes.noteCard}>
            {leadDetailsStore.additionalEmails.map((email, index) => (
              <DeletableItem reasonOptions={emailReasonOptions} key={email} item={email} onDelete={handleDelete} itemLabel={email} />
            ))}
          </Grid>
        ) : (
          <Box textAlign="center">No records.</Box>
        )
        }

      </Card>
      <Box pt={2}>
        <Card
          title="Additional Phones"
          cardContentProps={{ className: classes.notesScroll }}
        >

          {leadDetailsStore.additionalPhones.length > 0 ? (
            <Grid item xs={12} className={classes.noteCard}>
              {leadDetailsStore.additionalPhones.map((phone, index) => (
                <DeletableItem key={phone} item={phone} onDelete={handleDelete} reasonOptions={phoneReasonOptions} itemLabel={formatPhoneNumber(phone)} />
              ))}
            </Grid>
          ) : (
            <Box textAlign="center">No records.</Box>
          )}

        </Card>
      </Box>
    </>
  );
});
