import { flow, makeObservable, action, observable, computed } from 'mobx';
import { SubmissionVsUnderwritingBaseStore } from './submissionVsUnderwritingBaseStore';

export class SubmissionVsUnderwritingStore extends SubmissionVsUnderwritingBaseStore {

  globalStore;
  loanStore;
  loanDetails;
  constructor(
    globalStore, loanStore
  ) {
    super(globalStore, loanStore);
    makeObservable(this, {
      loanDetails: observable,
    });
  }

  *getSubmissionVsUnderwriting(loanId, loanDetails) {
    this.loanDetails = loanDetails;
  }

  reset() {
    // No reset logic required yet.
  }


  get amount() {
    return this.loanDetails?.amount;
  }

  get rate() {
    return this.loanDetails?.rate;
  }

  get aggregateLoanToAsIs() {
    return this.loanDetails?.aggregateLoanToAsIs;
  }

  get primaryProperty() {
    return this.loanDetails?.primaryProperty;
  }

  get loanSubtype() {
    return this.loanDetails?.loanSubtype;
  }

  get calculatedLoanData() {
    return this.loanDetails?.calculatedLoanData;
  }

  get loanComparisonValues() {
    return this.loanDetails?.loanComparisonValues;
  }

}

export default SubmissionVsUnderwritingStore;
