import { Service } from "@roc/feature-app-core";

const url = {
  GET_COMP_ALL_PHOTOS:
    '/api/v1/property/compTool/getCompAllPhotos'
}

export class CompToolPhotosService extends Service {

  getCompAllPhotos(mlsId: number) {
    return this.get(`${url.GET_COMP_ALL_PHOTOS}?mlsId=${mlsId}`);
  }

}