import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,

  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { Paper } from '@roc/ui';
import clsx from 'clsx';
import { ReactNode } from 'react';
import { TableCardHeader } from './tableCardHeader';

const useStyles = makeStyles((theme: Theme) => ({
  column: {
    fontWeight: 'bold',
  },
  container: {
    padding: theme.spacing(1, 2),
  },
  table: {
    minWidth: 650,
  },
  row: {
    '&:last-child': {
      '& td': {
        borderBottom: 'none',
      },
    },
    '&:hover': {
      backgroundColor: '#f1f1f1',
    },
  },
  headerCell: {
    padding: theme.spacing(1, .5),
  },
  cell: {
    padding: theme.spacing(.5),
  },
}));

interface ITableCard {
  title: string | JSX.Element;
  optionsTitle?: string;
  options: Array<{ name: string; action: () => void, icon?:ReactNode }>;
  helperText?: string;
  helperTextClass?: any;
  rows: Array<any>;
  columns: Array<any>;
  afterTitle?: JSX.Element;
  classNames?: { table?: string };
  showHeader?: boolean;
  emptyText?: string;
}

export const TableCard = ({
  columns,
  helperText,
  helperTextClass,
  optionsTitle,
  options,
  title,
  rows,
  afterTitle,
  classNames,
  showHeader = true,
  emptyText,
}: ITableCard) => {
  const classes = useStyles();

  return (
    <TableContainer component={Paper} className={classes.container}>
      {showHeader && (
        <TableCardHeader
          afterTitle={afterTitle}
          helperText={helperText}
          helperTextClass={helperTextClass}
          optionsTitle={optionsTitle}
          options={options}
          title={title}
        />
      )}
      <Table className={clsx([classes.table, classNames?.table])}>
        <TableHead>
          <TableRow>
            {columns.map(({ name, ...props }) => (
              <TableCell key={name} className={clsx([classes.headerCell, classes.column])} {...props}>
                {name}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {
            emptyText && rows.length == 0 &&
            <TableRow className={classes.row}>
              <TableCell colSpan={columns.length}>
                <Typography color="textSecondary" align="center">{emptyText}</Typography>
              </TableCell>
            </TableRow>
          }
          {rows.map((row, index) => (
            <TableRow key={row[index]?.value} className={classes.row}>
              {row.map(({ value, component: MapComponent }) => (
                <TableCell className={classes.cell} key={`${value}-${index}`}>
                  {MapComponent ? <MapComponent>{value}</MapComponent> : value}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
