import { observable, flow, makeObservable, action } from 'mobx';
import { GlobalStore } from '@roc/feature-app-core';
import { BorrowerDetailsVerificationService } from '@roc/feature-credit-background-check';
import { TrackRecordPropertyFormStore } from './trackRecordPropertyFormStore';
import { TrackRecordEntityFormStore } from './trackRecordEntityFormStore';
import { TrackRecordOtherFormStore } from './trackRecordOtherFormStore';
import { TRACK_RECORD_BORROWER_INPUTTED, TRACK_RECORD_USER_INPUTTED, formatDate, isNil, isNotBlank, parseMMDDYYYYDate } from '@roc/feature-utils';
import { FileUpload } from '@roc/ui';

export class BorrowerTrackRecordVerificationStore {
  globalStore: GlobalStore;
  private borrowerDetailsVerificationService: BorrowerDetailsVerificationService;
  trackRecordPropertyFormStore: TrackRecordPropertyFormStore;
  trackRecordEntityFormStore: TrackRecordEntityFormStore;
  trackRecordOtherFormStore: TrackRecordOtherFormStore;
  activeStep = 0;
  loading = false;
  properties: any[];
  isPropertyFormActive: boolean;
  currentTasks: any[];
  currentExpandedRowIndex: number;
  currentPropertyOnEdit: any;
  currentEntityOnEdit: any;
  currentDocumentsToUpload: any[];
  currentProperty: any;
  currentPropertyIndex: number;
  entities: any[];
  isEntityFormActive: boolean;
  currentEntity: any;
  currentEntityIndex: number;
  borrowerInfo: any;
  pdfTitle: string;
  pdfData: any;
  isPdfPreviewModalOpen: boolean;
  generalContractorUploads: FileUpload[];
  brokerUploads: FileUpload[];
  currentToken: string;
  borrowerTier;
  digitalTrackRecord: boolean;
  templateUploads: FileUpload[];
  trackRecordTemplateParsingAllowed: boolean;
  trackRecordsErrors: any;
  isTpoFlow: boolean;

  constructor(globalStore, borrowerDetailsVerificationService: BorrowerDetailsVerificationService) {
    this.globalStore = globalStore;
    this.borrowerDetailsVerificationService = borrowerDetailsVerificationService;
    this.trackRecordPropertyFormStore = new TrackRecordPropertyFormStore(globalStore);
    this.trackRecordEntityFormStore = new TrackRecordEntityFormStore(globalStore);
    this.trackRecordOtherFormStore = new TrackRecordOtherFormStore(globalStore);
    this.properties = [];
    this.currentTasks = [];
    this.currentExpandedRowIndex = null;
    this.currentProperty = {};
    this.currentPropertyIndex = null;
    this.entities = [];
    this.borrowerInfo = {};
    this.pdfTitle = '';
    this.pdfData = null;
    this.isPdfPreviewModalOpen = false;
    this.generalContractorUploads = [];
    this.brokerUploads = [];
    this.currentToken = null;
    this.currentDocumentsToUpload = [];
    this.digitalTrackRecord = true;
    this.templateUploads = [];
    this.trackRecordTemplateParsingAllowed = false;
    this.trackRecordsErrors = {};


    makeObservable(this, {
      activeStep: observable,
      loading: observable,
      properties: observable,
      isPropertyFormActive: observable,
      currentTasks: observable,
      currentExpandedRowIndex: observable,
      currentPropertyOnEdit: observable,
      currentEntityOnEdit: observable,
      currentDocumentsToUpload: observable,
      currentProperty: observable,
      currentPropertyIndex: observable,
      entities: observable,
      currentEntity: observable,
      currentEntityIndex: observable,
      isEntityFormActive: observable,
      fetchBorrowerInfo: flow,
      fetchTrackRecordProperties: flow,
      fetchBorrowerInfoAndEntities: flow,
      getBorrowerBaseballCardData: flow,
      getVerifiedTrackRecordProperties: flow,
      getUnverifiedTrackRecordProperties: flow,
      resetStore: flow,
      goNextStep: flow,
      goPrevStep: flow,
      setCurrentExpandedRowIndex: flow,
      saveTrackRecordProperty: flow,
      addTrackRecordProperty: flow,
      editTrackRecordProperty: flow,
      handleAddNewTrackRecordProperty: action,
      handleEditExistingTrackRecordProperty: action,
      removeTrackRecordProperty: flow,
      handleRemoveTrackRecordProperty: flow,
      saveEntityFromProperty: flow,
      getTrackRecordPropertyJson: action,
      saveTrackRecordEntity: flow,
      addTrackRecordEntity: flow,
      editTrackRecordEntity: flow,
      removeTrackRecordEntity: flow,
      handleAddNewTrackRecordEntity: action,
      handleEditExistingTrackRecordEntity: action,
      handleRemoveTrackRecordEntity: flow,
      getTrackRecordEntityJson: action,
      getTasks: flow,
      createTaskWithFile: flow,
      uploadTaskFile: flow,
      downloadTaskFile: flow,
      pdfTitle: observable,
      pdfData: observable,
      isPdfPreviewModalOpen: observable,
      saveAndUploadGCAndBrokerData: flow,
      getGCAndBrokerBody: action,
      saveAndUploadTask: flow,
      generalContractorUploads: observable,
      brokerUploads: observable,
      setGeneralContractorUploads: flow,
      setBrokerUploads: flow,
      currentToken: observable,
      setCurrentToken: flow,
      borrowerTier: observable,
      setBorrowerInfo: flow,
      resetActiveStep: action,
      digitalTrackRecord: observable,
      setDigitalTrackRecord: flow,
      templateUploads: observable,
      setTemplateUploads: flow,
      validateTrackRecordTemplate: flow,
      parseTrackRecordTemplate: flow,
      isTrackRecordTemplateParsingAllowed: flow,
      trackRecordsErrors: observable,
      doesPropertyHasParsingError: action,
      areValidParsedTrackRecordProperties: action,
      continueFromTrackRecordStep: flow,
      setIsTpoFlow: action,
      isTpoFlow: observable,
      checkBorrowerId: action,
    });
    this.setDefaults();
  }

  private setDefaults() {
    this.activeStep = 0;
    this.loading = false;
    this.isPropertyFormActive = false;
    this.currentTasks = [];
    this.currentExpandedRowIndex = null;
    this.currentProperty = {};
    this.currentPropertyIndex = null;
    this.isEntityFormActive = false;
    this.currentEntity = {};
    this.currentEntityIndex = null;
    this.digitalTrackRecord = true;
  }

  *resetStore() {
    this.trackRecordPropertyFormStore.reset();
    this.trackRecordEntityFormStore.reset();
    this.trackRecordOtherFormStore.reset();
    this.setDefaults();
  }

  *goPrevStep() {
    this.activeStep--;
    this.currentExpandedRowIndex = null;
  }

  *goNextStep() {
    this.activeStep++;
    this.currentExpandedRowIndex = null;
  }

  *setCurrentExpandedRowIndex(value: number) {
    this.currentExpandedRowIndex = value;
  }

  *fetchBorrowerInfo() {
    try {
      if (isNil(this.borrowerInfo.borrowerId)) {
        const response = yield this.borrowerDetailsVerificationService.getBorrowerInfoByToken(this.currentToken);
        this.borrowerInfo = response.data.data;
      }
      yield this.fetchBorrowerInfoAndEntities();
      yield this.fetchTrackRecordProperties();
    } catch (e) {
      console.log(e);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'We had an issue while retrieving borrower info.',
      });
    }
  }

  *fetchBorrowerInfoAndEntities() {
    const baseballCard = yield this.getBorrowerBaseballCardData(this.borrowerInfo.borrowerId);
    this.entities = [...(baseballCard?.data?.borrowingEntities || [])];
  }

  *fetchTrackRecordProperties() {
    const verifiedTrackRecord = yield this.getVerifiedTrackRecordProperties(this.borrowerInfo.borrowerId);
    const unverifiedTrackRecord = yield this.getUnverifiedTrackRecordProperties(this.borrowerInfo.borrowerId);
    this.properties = [...verifiedTrackRecord?.data, ...unverifiedTrackRecord?.data];
    const borrowerTierResponse = yield this.borrowerDetailsVerificationService.getBorrowerUnverifiedTierRating(this.currentToken, this.borrowerInfo?.borrowerId);
    this.borrowerTier = borrowerTierResponse?.data?.data;
  }

  *getBorrowerBaseballCardData(borrowerId: number, loanId?: number) {
    const response = yield this.borrowerDetailsVerificationService.getBorrowerBaseballCardDetails(
      borrowerId,
      loanId
    );
    return { data: JSON.parse(response?.data?.data || '[]') };
  }

  *getVerifiedTrackRecordProperties(borrowerId: number) {
    const response = yield this.borrowerDetailsVerificationService.getVerifiedTrackRecordProperties(borrowerId);
    return { data: JSON.parse(response?.data?.data || '[]') };
  }

  *getUnverifiedTrackRecordProperties(borrowerId: number) {
    const response = yield this.borrowerDetailsVerificationService.getUnverifiedTrackRecordProperties(borrowerId);
    return { data: JSON.parse(response?.data?.data || '[]') };
  }

  handleAddNewTrackRecordProperty() {
    this.currentDocumentsToUpload = [];
    this.currentPropertyOnEdit = {};
    this.isPropertyFormActive = true;
    this.currentProperty = {};
    this.currentPropertyIndex = this.properties.length + 1;
    this.trackRecordPropertyFormStore.reset();
  }

  handleEditExistingTrackRecordProperty(property, currentIndex) {
    this.currentDocumentsToUpload = this.currentTasks.filter(task => task.originalFileName)
      .map(task => {
        return {
          disabled: true,
          name: task.originalFileName,
          file: {
            path: task.originalFileName,
            name: task.originalFileName,
          }
        }
      });

    this.currentPropertyOnEdit = { ...property };
    this.isPropertyFormActive = true;
    this.currentProperty = { ...property };
    this.currentPropertyIndex = currentIndex + 1;
    this.trackRecordPropertyFormStore.loadForm(property);
    if (this.doesPropertyHasParsingError(property.purchaseAddress)) {
      this.trackRecordPropertyFormStore.runFormValidation();
    }
  }

  *saveTrackRecordProperty() {
    try {
      if (this.currentProperty.verifiedTrackRecordsId != null) {
        yield this.editTrackRecordProperty();
      }
      else {
        yield this.addTrackRecordProperty();
      }
    } catch (error) {
      console.log(error);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error occurred while saving track record property',
      });
    }
  }

  *addTrackRecordProperty() {
    try {
      this.loading = true;
      this.trackRecordPropertyFormStore.runFormValidation();
      if (this.trackRecordPropertyFormStore.form.meta.isValid) {
        const data = this.getTrackRecordPropertyJson();
        const response = yield this.borrowerDetailsVerificationService.saveTrackRecordProperty(data);

        this.currentPropertyOnEdit.verifiedTrackRecordsId = JSON.parse(response.data.data).verifiedTrackRecordsId;

        this.currentDocumentsToUpload.forEach((document) => {
          if (!document.disabled) {
            const taskPropertyBody = {
              sectionProcess: 'TRACK_RECORD_REVIEW',
              objectType: 'PROPERTY_TRACK_RECORD',
              taskTemplateType: 'PROPERTY_TRACK_RECORD',
              objectId: this.currentPropertyOnEdit.verifiedTrackRecordsId,
            };
            this.createTaskWithFile(taskPropertyBody, document);
            document.disabled = true;
          }
        });
        yield this.saveEntityFromProperty(data);
        yield this.fetchTrackRecordProperties();
        this.resetStore();
      }
    } catch (error) {
      console.log(error);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error occurred while adding track record property',
      });
    } finally {
      this.loading = false;
    }
  }

  getTrackRecordPropertyJson() {
    const propertyFields = this.trackRecordPropertyFormStore.form.fields;
    return {
      ...this.currentPropertyOnEdit,
      ...this.trackRecordPropertyFormStore.getFormValues(),
      purchaseDate: formatDate(propertyFields.purchaseDate.value, 'MM/dd/yyyy'),
      saleDate: formatDate(propertyFields.saleDate.value, 'MM/dd/yyyy'),
      borrowerId: this.borrowerInfo?.borrowerId,
      recordSourceType: this.isTpoFlow ? TRACK_RECORD_USER_INPUTTED : TRACK_RECORD_BORROWER_INPUTTED,
      purchaseSecondaryAddress: propertyFields?.address2.value,
    }
  }

  *editTrackRecordProperty() {
    try {
      this.loading = true;
      this.trackRecordPropertyFormStore.runFormValidation();
      if (this.trackRecordPropertyFormStore.form.meta.isValid) {
        const data = this.getTrackRecordPropertyJson();
        yield this.borrowerDetailsVerificationService.editTrackRecordProperty(data);
        this.currentDocumentsToUpload.forEach((document) => {
          if (!document.disabled) {
            const taskPropertyBody = {
              sectionProcess: 'TRACK_RECORD_REVIEW',
              objectType: 'PROPERTY_TRACK_RECORD',
              taskTemplateType: 'PROPERTY_TRACK_RECORD',
              objectId: this.currentPropertyOnEdit.verifiedTrackRecordsId,
            };
            this.createTaskWithFile(taskPropertyBody, document);
            document.disabled = true;
          }
        });
        yield this.fetchTrackRecordProperties();
        this.resetStore();
        if (this.doesPropertyHasParsingError(data.purchaseAddress)) {
          this.trackRecordsErrors = {
            ...this.trackRecordsErrors,
            [data.purchaseAddress]: []
          }
        }
      }
    } catch (error) {
      console.log(error);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error occurred while editing track record property',
      });
    } finally {
      this.loading = false;
    }
  }

  *handleRemoveTrackRecordProperty(property) {
    this.currentProperty = { ...property };
    yield this.removeTrackRecordProperty();
  }

  *removeTrackRecordProperty() {
    try {
      this.loading = true;
      if (this.currentProperty.verifiedTrackRecordsId != null) {
        yield this.borrowerDetailsVerificationService.deleteTrackRecordProperty(this.currentProperty.verifiedTrackRecordsId);
        yield this.fetchTrackRecordProperties();
      }
      this.resetStore();
    } catch (error) {
      console.log(error);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error occurred while removing track record property',
      });
    } finally {
      this.loading = false;
    }
  }

  *saveEntityFromProperty(property: any) {
    try {
      const newEntity = {
        entityName: property.entityName,
        entityState: property.purchaseState
      };
      this.handleEditExistingTrackRecordEntity(newEntity, this.entities.length + 1);
      this.addTrackRecordEntity();
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'We had an issue while loading entities.',
      });
    }
  }

  handleAddNewTrackRecordEntity() {
    this.currentDocumentsToUpload = [];
    this.currentEntityOnEdit = {};
    this.isEntityFormActive = true;
    this.currentEntity = {};
    this.currentEntityIndex = this.entities.length + 1;
    this.trackRecordEntityFormStore.reset();
  }

  handleEditExistingTrackRecordEntity(entity, currentIndex) {
    this.currentDocumentsToUpload = this.currentTasks.filter(task => task.originalFileName)
      .map(task => {
        return {
          disabled: true,
          name: task.originalFileName,
          file: {
            path: task.originalFileName,
            name: task.originalFileName,
          }
        }
      });

    this.currentEntityOnEdit = { ...entity };
    this.isEntityFormActive = true;
    this.currentEntity = { ...entity };
    this.currentEntityIndex = currentIndex + 1;
    this.trackRecordEntityFormStore.loadForm(entity);
  }

  *saveTrackRecordEntity() {
    try {
      if (this.currentEntity.borrowerEntityBorrowerId != null) {
        yield this.editTrackRecordEntity();
      }
      else {
        yield this.addTrackRecordEntity();
      }

      this.currentDocumentsToUpload.forEach((document) => {
        if (!document.disabled) {
          const taskEntityBody = {
            sectionProcess: 'TRACK_RECORD_REVIEW',
            objectType: 'BORROWER_ENTITY_BORROWER',
            taskTemplateType: 'BORROWER_ENTITY_BORROWER',
            objectId: this.currentEntityOnEdit.borrowerEntityBorrowerId,
          };
          this.createTaskWithFile(taskEntityBody, document);
          document.disabled = true;
        }
      });
    } catch (error) {
      console.log(error);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error occurred while saving track record entity',
      });
    }
  }

  *addTrackRecordEntity() {
    try {
      this.loading = true;
      this.trackRecordEntityFormStore.runFormValidation();
      if (this.trackRecordEntityFormStore.form.meta.isValid) {
        const data = this.getTrackRecordEntityJson();
        const response = yield this.borrowerDetailsVerificationService.saveTrackRecordEntity(data, this.borrowerInfo.borrowerId);
        this.currentEntityOnEdit.borrowerEntityBorrowerId = JSON.parse(response.data.data).saveBorrowerEntityBorrowerTrackRecord;
        yield this.fetchBorrowerInfoAndEntities();
        this.resetStore();
      }
    } catch (error) {
      console.log(error);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error occurred while saving track record entity',
      });
    } finally {
      this.loading = false;
    }
  }

  getTrackRecordEntityJson() {
    const entityFields = this.trackRecordEntityFormStore.form.fields;
    return {
      ...this.currentEntity,
      ...this.trackRecordEntityFormStore.getFormValues(),
      name: entityFields.entityName.value,
      state: entityFields.entityState.value,
      borrowerId: this.borrowerInfo?.borrowerId,
    }
  }

  *editTrackRecordEntity() {
    try {
      this.loading = true;
      this.trackRecordEntityFormStore.runFormValidation();
      if (this.trackRecordEntityFormStore.form.meta.isValid) {
        const data = this.getTrackRecordEntityJson();
        yield this.borrowerDetailsVerificationService.editTrackRecordEntity(data, this.currentEntity.borrowerEntityId);
        yield this.fetchBorrowerInfoAndEntities();
        this.resetStore();
      }
    } catch (error) {
      console.log(error);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error occurred while editing track record entity',
      });
    } finally {
      this.loading = false;
    }
  }

  *handleRemoveTrackRecordEntity(entity) {
    this.currentEntity = { ...entity };
    yield this.removeTrackRecordEntity();
  }

  *removeTrackRecordEntity() {
    try {
      this.loading = true;
      if (this.currentEntity.borrowerEntityBorrowerId != null) {
        yield this.borrowerDetailsVerificationService.removeTrackRecordEntity(this.currentEntity.borrowerEntityBorrowerId);
        yield this.fetchBorrowerInfoAndEntities();
      }
      this.resetStore();
    } catch (error) {
      console.log(error);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error occurred while removing track record entity',
      });
    } finally {
      this.loading = false;
    }
  }

  *getTasks(taskBody) {
    const response = yield this.borrowerDetailsVerificationService.getTasks(taskBody);
    this.currentTasks = response.data.data;
  }

  *createTaskWithFile(taskBody, file) {
    try {
      const taskResponse = yield this.borrowerDetailsVerificationService.createTask(taskBody);
      const task = taskResponse.data?.data[0];

      yield this.uploadTaskFile(task, file);

      yield this.getTasks(taskBody);
      return task;
    } catch (e) {
      console.log(e);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while uploading the file' + e.error.message,
      });
    }
  }

  *uploadTaskFile(task, fileUpload: FileUpload) {
    const blob = fileUpload.file.slice(0, fileUpload.file.size, fileUpload.file.type);
    const newFile = new File([blob], fileUpload.name, { type: fileUpload.file.type });
    const formData = new FormData();
    formData.append('file', newFile);
    formData.append('task', JSON.stringify(task));
    yield this.borrowerDetailsVerificationService.uploadTaskFile(formData);
  }

  *downloadTaskFile(id, name, fileExtension) {
    try {
      const blob = yield this.borrowerDetailsVerificationService.downloadTaskFile(id);
      const reader = new FileReader();

      reader.readAsArrayBuffer(blob);

      reader.onloadend = () => {
        if (fileExtension === 'pdf') {
          // Preview the PDF
          this.pdfTitle = name;
          this.pdfData = reader.result; // The ArrayBuffer
          this.isPdfPreviewModalOpen = true;
        } else {
          // Download the file if not a PDF
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = name;
          a.click();
          window.URL.revokeObjectURL(url);
        }
      };

    } catch (error) {
      console.error(error);
    }
  }

  *saveAndUploadGCAndBrokerData(fetchBorrowerDetailsVerification) {
    try {
      this.loading = true;
      this.trackRecordOtherFormStore.runFormValidation();
      if (this.trackRecordOtherFormStore.form.meta.isValid) {
        const data = this.getGCAndBrokerBody();
        yield this.borrowerDetailsVerificationService.saveBorrowerGCAndBrokerData(data, this.currentToken);
        if (this.trackRecordOtherFormStore.form.fields.generalContractor.value === 'Yes') {
          const taskBody = {
            sectionProcess: 'BORROWER_GC_LICENSE',
            objectType: 'BORROWER',
            taskTemplateType: 'BORROWER_GC_LICENSE',
            objectId: this.borrowerInfo.borrowerId,
          };
          yield this.saveAndUploadTask(taskBody, this.generalContractorUploads);
        }
        if (this.trackRecordOtherFormStore.form.fields.broker.value === 'Yes') {
          const taskBody = {
            sectionProcess: 'BORROWER_BROKER_LICENSE',
            objectType: 'BORROWER',
            taskTemplateType: 'BORROWER_BROKER_LICENSE',
            objectId: this.borrowerInfo.borrowerId,
          };
          yield this.saveAndUploadTask(taskBody, this.brokerUploads);
        }
        this.resetStore();
        fetchBorrowerDetailsVerification(this.currentToken);
        this.goNextStep();
      }
    } catch (error) {
      console.log(error);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error occurred while saving borrower GC and Broker details',
      });
    } finally {
      this.loading = false;
    }
  }

  *saveAndUploadTask(taskBody, files) {
    if (files.length > 0) {
      yield this.createTaskWithFile(taskBody, files[0]);
    }
  }

  getGCAndBrokerBody() {
    const borrowerFields = this.trackRecordOtherFormStore.form.fields;

    return {
      generalContractor: borrowerFields.generalContractor.value === 'Yes',
      broker: borrowerFields.broker.value === 'Yes',
      borrowerId: this.borrowerInfo?.borrowerId,
    }
  }

  *setGeneralContractorUploads(values) {
    this.generalContractorUploads = values;
  }

  *setBrokerUploads(values) {
    this.brokerUploads = values;
  }

  *setCurrentToken(token) {
    this.currentToken = token;
  }

  *setBorrowerInfo(borrower) {
    this.borrowerInfo = borrower;
  }

  resetActiveStep() {
    this.activeStep = 0;
    this.currentExpandedRowIndex = null;
  }

  *setDigitalTrackRecord(value) {
    this.digitalTrackRecord = value;
  }

  *setTemplateUploads(values) {
    this.templateUploads = values;
  }

  *validateTrackRecordTemplate() {
    if (!this.digitalTrackRecord) {
      if (this.templateUploads.length === 0) {
        this.globalStore.notificationStore.showErrorNotification({
          message: 'Please upload the track record template to continue',
        });
      }
      else {
        this.continueFromTrackRecordStep();
      }
    }
    else {
      this.continueFromTrackRecordStep();
    }
  }

  *continueFromTrackRecordStep() {
    if (this.areValidParsedTrackRecordProperties()) {
      this.goNextStep();
    }
    else {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Please check the properties in red and resolve the issues in order to continue.',
      });
    }
  }

  *parseTrackRecordTemplate(callback?: () => {}) {
    try {
      this.loading = true;
      if (!this.digitalTrackRecord) {
        if (this.templateUploads.length > 0) {
          const fileUpload = this.templateUploads[0];
          const blob = fileUpload.file.slice(0, fileUpload.file.size, fileUpload.file.type);
          const newFile = new File([blob], fileUpload.name, { type: fileUpload.file.type });
          const formData = new FormData();
          formData.append('file', newFile);
          const response = yield this.borrowerDetailsVerificationService.parseTrackRecordTemplate(formData, this.borrowerInfo?.borrowerId);
          const errorMsg = response.data.data;
          console.log('errorMsg', errorMsg);
          this.trackRecordsErrors = JSON.parse(errorMsg);
          if (Object.keys(this.trackRecordsErrors).length !== 0) {
            this.globalStore.notificationStore.showErrorNotification({
              message: 'Please check the properties in red and resolve the issues in order to continue.',
            });
          }
          yield this.fetchTrackRecordProperties();
          yield this.fetchBorrowerInfoAndEntities();
          if (callback) {
            callback();
          }
          this.setDigitalTrackRecord(!this.digitalTrackRecord);
        }
      }
    } catch (error) {
      console.log(error);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error occurred while parsing track record template',
      });
    } finally {
      this.loading = false;
    }
  }

  *isTrackRecordTemplateParsingAllowed() {
    try {
      const response = yield this.borrowerDetailsVerificationService.isTrackRecordTemplateParsingAllowed();
      this.trackRecordTemplateParsingAllowed = response.data.data;
    } catch (e) {
      console.log(e);
    }
  }

  doesPropertyHasParsingError = (address: string) => {
    return this.trackRecordsErrors[address]?.length > 0;
  }

  areValidParsedTrackRecordProperties = () => {
    let validParsedProperties = true;
    this.properties.forEach(property => {
      if (this.doesPropertyHasParsingError(property.purchaseAddress)) {
        this.trackRecordPropertyFormStore.loadForm(property);
        this.trackRecordPropertyFormStore.runFormValidation();
        if (!this.trackRecordPropertyFormStore.form.meta.isValid) {
          validParsedProperties = false;
        }
      }
    });
    return validParsedProperties;
  }

  setIsTpoFlow(isTpo: boolean) {
    this.isTpoFlow = isTpo;
  }

  checkBorrowerId(borrowerId?: number) {
    if (borrowerId) {
      this.borrowerInfo = { borrowerId };
    }
  }
}