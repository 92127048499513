import { formatPercentage } from '@roc/ui/utils';
import { observer } from 'mobx-react';
import { LoanDetailsCardProps } from '../../summary/summaryStep';
import { LoanDetailsCard } from './loanDetailsCard';

export const StabilizedBridgeLoanDetailsCard = observer(
  ({ store }: LoanDetailsCardProps) => {
    const leverage = store.loanEconomicsStore.getLoanEconomics();
    const ltcPurchasePricePercentage = formatPercentage(leverage.loanToCost);

    return (
      <LoanDetailsCard
        store={store}
        keyRatios={`${ltcPurchasePricePercentage}`}
        keyRatiosTooltip={'LTC Purchase Price'}
      />
    );
  }
);
