import { DashboardRounded } from '@material-ui/icons';
import { Page, useBaseStore } from '@roc/feature-app-core';
import { ConversationsDialog, SelectConversation, useCommunicationRoutesConfig, useLoanCommunicationStore } from '@roc/feature-communication';
import { LoanType } from '@roc/feature-utils';
import { observer } from 'mobx-react';
import { PropsWithChildren, useEffect } from 'react';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom';
import { BorrowerTodos } from '@roc/feature-borrower-todos';
import { useBorrowerLoanApplicationsRoutes } from 'libs/feature-borrower-loans/src/hooks/useBorrowerLoanApplicationsRoutes';
import { BorrowerLoanOverviewBridgeLoanApplications } from '../overview/borrowerLoanOverviewBridgeLoanApplications';
import { useBorrowerLoanApplicationsStore } from 'libs/feature-borrower-loans/src/hooks/useBorrowerLoanApplicationsStore';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { BorrowerLoanOverviewTermLoanApplications } from '../overview/borrowerLoanOverviewTermLoanApplications';

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    '& .MuiDialog-paper': {
      maxWidth: 1000,
    },
  },
}));

export const LoanOverview = observer(({ loanDetails }) => {
  const { globalStore } = useBaseStore();
  const { breadCrumbsStore } = globalStore;
  const { loanId } = useParams<{ loanId: string }>();
  const { borrowerLoanApplicationRoutesConfig: loanRoutesConfig } = useBorrowerLoanApplicationsRoutes();

  useEffect(() => {
    breadCrumbsStore.setBreadCrumbItems([
      {
        link: true,
        title: 'Dashboard',
        url: globalStore.routeBaseUrl,
        icon: <DashboardRounded />,
      },
      {
        link: true,
        title: `Loan Application# ${loanId}`,
        url: loanRoutesConfig.loans(loanId).url,
      },
      {
        link: false,
        title: 'Loan Application Summary',
      },
    ]);
  });

  switch (loanDetails?.loanType) {
    case LoanType.RESIDENTIAL_BRIDGE:
      return <BorrowerLoanOverviewBridgeLoanApplications />;
    case LoanType.RESIDENTIAL_TERM:
      return <BorrowerLoanOverviewTermLoanApplications />;
  }
});

export function ConversationsDialogWrapper({ loanDetails, children }: PropsWithChildren<{ loanDetails: any }>) {
  return <>
    <LoanOverview loanDetails={loanDetails} />
    {children}
  </>;
}

export const BorrowerLoanApplicationDetailsChildRoutes = observer(({ isNew = false }) => {
  const { loanId } = useParams<{ loanId: string; drawId: string }>();
  const { borrowerLoanApplicationsStore } = useBorrowerLoanApplicationsStore();
  const { loanDetails } = borrowerLoanApplicationsStore;
  const { borrowerLoanApplicationRoutesConfig: loanRoutesConfig } = useBorrowerLoanApplicationsRoutes();
  const loanDetailsChildRoutes = loanRoutesConfig.loans(loanId).children;
  const { communicationRoutesConfig } = useCommunicationRoutesConfig();
  const { push, replace } = useHistory();
  const { pathname, state } = useLocation<{ from?: string }>();
  const { loanCommunicationStore } = useLoanCommunicationStore();
  const classes = useStyles();

  function handleCloseConversationsDialog() {
    push(state?.from || loanRoutesConfig.loans(loanId).children.dashboard.url);
    loanCommunicationStore.fetchUnreadMessagesCount(loanId);
  }

  function handleConversationSelect(conversationId: string) {
    const path = communicationRoutesConfig.loanApplicationConversations(loanId).children.conversation(conversationId).url;
    try {
      replace(path, state);
    } catch (e) {
      push(path, { from: loanDetailsChildRoutes.dashboard.url });
    }
  }

  if (!loanDetails) {
    return null;
  }

  return (
    <Switch>
      <Route exact path={`${loanRoutesConfig.loans(loanId).path}/`}
        render={() => <Redirect to={loanDetailsChildRoutes.dashboard.url} />}
      />
      <Route exact path={loanDetailsChildRoutes.dashboard.path}>
        <Page routeDefinition={loanDetailsChildRoutes.dashboard}>
          <LoanOverview loanDetails={loanDetails} />
        </Page>
      </Route>
      {loanDetails?.toDosFrameworkEnabled &&
        <Route exact path={loanDetailsChildRoutes.borrowerTodos.path}>
          <Page routeDefinition={loanDetailsChildRoutes.borrowerTodos}>
            <BorrowerTodos loanId={loanId} isLoanApplication={true} />
          </Page>
        </Route>
      }
      <Route path={communicationRoutesConfig.loanApplicationConversations(loanId).path}>
        <Page routeDefinition={communicationRoutesConfig.loanApplicationConversations(loanId)}>
          <ConversationsDialogWrapper loanDetails={loanDetails}>
            <ConversationsDialog
              open={true}
              loanId={Number(loanId)}
              onClose={handleCloseConversationsDialog}
              communicationStore={loanCommunicationStore}
              onConversationSelect={handleConversationSelect}
              hideSidebar={true}
              allowManageParticipants={true}
              allowNewConversations={false}
              allowAddParticipants={false}
              allowAddInternalParticipants={false}
              allowRemoveParticipants={false}
              allowJoinConversation={false}
              showSnippet={false}
              showEmailAddress={false}
              dialogClass={classes.dialog}
              routePath={communicationRoutesConfig.loanApplicationConversations(loanId).children.conversation().path}
            />
          </ConversationsDialogWrapper>
        </Page>
      </Route>
    </Switch>
  );
});
