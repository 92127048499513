import { Box, Checkbox, Chip, Popper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CheckBoxOutlineBlankSharp, CheckBoxSharp } from '@material-ui/icons';
import { sanitizeTestId } from '@roc/feature-utils';
import {
  DateField,
  MultiSelectField,
  RadioField,
  SelectField,
  TextField,
  useVirtualizedAutocomplete,
} from '@roc/ui';
import clsx from 'clsx';
import { useMemo } from 'react';

const useStyles = makeStyles(theme => ({
  multiselectFilterHideClear: {
    '& .MuiChip-deleteIconSmall': {
      display: 'none',
    },
  },

  userFilterPopper: {
    minWidth: '450px',
  },
  selectFilter: {
    '& .MuiSelect-select': {
      paddingTop: '10.5px',
      paddingBottom: '10.5px',
    },
  },
}));

const ALL = 'ALL';
const ALL_OPTION = { label: 'All', value: ALL };

const removePlaceholder = opts => opts.filter(o => o.value !== ALL);
const isAllSelected = (currentOptions, selectedOptions) =>
  currentOptions.length > 0 && selectedOptions.some(o => o.value === ALL);

const ucheckedIcon = <CheckBoxOutlineBlankSharp fontSize="small" />;
const checkedIcon = <CheckBoxSharp fontSize="small" />;

export const MultiselectFilter = ({ label, value, onChange, options, minWidth = undefined }) => {
  const classes = useStyles();
  const currentOptions = value ?? [];
  const isEmpty = currentOptions.length === 0;

  const mapLabelFromValue = option => ({
    value: option.value,
    label: options.find(item => item.value == option.value)?.label,
  });

  return (
    <MultiSelectField
      testId={`filter-${sanitizeTestId(label)}`}
      size="small"
      label={label}
      className={clsx(isEmpty && classes.multiselectFilterHideClear)}
      value={isEmpty ? [ALL_OPTION] : currentOptions.map(mapLabelFromValue)}
      standaloneLabel
      blurOnSelect={false}
      style={{ minWidth }}
      onChange={(e, selectedOptions) => {
        const shouldClear = isAllSelected(currentOptions, selectedOptions);
        onChange(shouldClear ? [] : removePlaceholder(selectedOptions));
      }}
      getOptionSelected={(option, selectedValue) => {
        return option.value == selectedValue.value;
      }}
      renderInput={params => (
        <TextField
          testId={`filter-${sanitizeTestId(label)}-input`}
          {...params}
          variant="outlined"
        />
      )}
      options={[ALL_OPTION].concat(options)}
    />
  );
};

export const DateFilter = ({ label, value, onChange }) => {
  return (
    <DateField
      standaloneLabel
      testId={`filter-${sanitizeTestId(label)}`}
      size="small"
      inputVariant="outlined"
      format="MM/dd/yyyy"
      label={label}
      clearable
      value={value ?? null}
      onChange={date => onChange(date)}
      fullWidth
    />
  );
};

export const TextFilter = ({
  label,
  value,
  onChange,
  maxWidth = undefined,
}) => {
  return (
    <TextField
      testId={`filter-${sanitizeTestId(label)}`}
      variant="outlined"
      size="small"
      label={label}
      standaloneLabel
      value={value ?? ''}
      style={{ maxWidth }}
      onChange={e => onChange(e.target.value)}
    />
  );
};

export const UserFilter = ({ label, value, onChange, options }) => {
  const classes = useStyles();
  const currentOptions = value ?? [];
  const isEmpty = currentOptions.length === 0;
  const { ListboxComponent } = useVirtualizedAutocomplete(48);
  const PopperComponent = useMemo(() => {
    return props => <Popper {...props} className={classes.userFilterPopper} />;
  }, []);

  return (
    <MultiSelectField
      testId={`filter-${sanitizeTestId(label)}`}
      ListboxComponent={ListboxComponent}
      size="small"
      label={label}
      className={clsx(isEmpty && classes.multiselectFilterHideClear)}
      value={currentOptions.length === 0 ? [ALL_OPTION] : currentOptions}
      standaloneLabel
      PopperComponent={PopperComponent}
      onChange={(e, selectedOptions) => {
        const shouldClear = isAllSelected(currentOptions, selectedOptions);
        onChange(shouldClear ? [] : removePlaceholder(selectedOptions));
      }}
      getOptionSelected={(option, selectedValue) => {
        return option.value == selectedValue.value;
      }}
      renderInput={params => (
        <TextField
          testId={`filter-${sanitizeTestId(label)}-input`}
          {...params}
          variant="outlined"
        />
      )}
      options={[ALL_OPTION].concat(options)}
      renderOption={(option, { selected }) => (
        <>
          <Checkbox
            icon={ucheckedIcon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.value === ALL ? (
            <>All</>
          ) : (
            <Box display={'flex'} alignItems={'center'}>
              <Typography>{`${option.firstName} ${option.lastName}`}</Typography>
              <Chip
                variant="default"
                size="small"
                label={option.role}
                style={{
                  height: 18,
                  marginLeft: '4px',
                  backgroundColor: '#d3fbac',
                  color: 'black',
                  fontWeight: 'bold',
                }}
              />
            </Box>
          )}
        </>
      )}
    />
  );
};

export const RadioFilter = ({ label, value, onChange, options }) => {
  return (
    <RadioField
      label={label}
      value={value === true}
      options={options}
      onChange={value => onChange(value === 'true')}
      row={true}
      testId={label}
      standaloneLabel={true}
    />
  );
};

export const SelectFilter = ({ label, value, onChange, options }) => {
  const classes = useStyles();
  return (
    <SelectField
      label={label}
      value={value}
      className={classes.selectFilter}
      options={options}
      variant={'outlined'}
      onChange={onChange}
      testId={label}
      standaloneLabel={true}
    />
  );
};
