import { GlobalStore } from '@roc/feature-app-core';
import { formatPercentage } from '@roc/ui/utils';
import { makeObservable, override, action } from 'mobx';
import { sum } from '../../utils/utils';
import { QuoteTypeStore } from '../quoteTypeStore';
import { LoanEconomicsStore } from './loanEconomicsStore';
import {
  PURCHASE,REFINANCE,getDaysBetween
} from '@roc/feature-utils';

export class StabilizedBridgeLoanEconomicsStore extends LoanEconomicsStore {
  constructor(globalStore: GlobalStore, quoteTypeStore: QuoteTypeStore) {
    super(globalStore, quoteTypeStore);
    makeObservable(this, {
      calculateKeyRatios: override,
      mapCalculatedFields: override,
    });
  }

  caclulateFields(properties: any[], leverage: any): Record<string, number> {
    //Leverage
    const totalSellerCredit = sum(properties.filter(p => !!p.sellerCredit).map(p => p.sellerCreditAmount));

    const { loanToCost, constructionReserve } = leverage;
    let totalPurchasePrice = 0;
    if(properties?.[0]?.loanPurpose === PURCHASE) {
      totalPurchasePrice = sum(properties.map(p => p.purchasePrice)) - totalSellerCredit;
    } else {
      const seasoningDays = getDaysBetween(new Date(), new Date(properties?.[0]?.purchaseDate));
      if(seasoningDays <= 180) {
        // <= 180 days seasoning
        totalPurchasePrice = sum(properties.map(p => p.purchasePrice)) +  sum(properties.map(p => p.constructionRenoSpendToDate || 0)) ;
      } else {
        // > 180 days seasoning
        totalPurchasePrice = sum(properties.map(p => p.currentMarketValue)) ;
      }

    }


    const totalProposedConstructionBudget = sum(
      properties.map(p => p.renovationBudget)
    );
    const totalAfterRepairValue = sum(
      properties.map(p => p.marketValueAfterCompletion)
    );

    const initialAdvance = totalPurchasePrice * (loanToCost / 100);
    const constructionHoldback =
      totalProposedConstructionBudget * (constructionReserve / 100);
    const totalLoanAmount = initialAdvance ;
    const afterRepairLTV = (totalLoanAmount / totalAfterRepairValue) * 100;

    return {
      totalPurchasePrice,
      totalProposedConstructionBudget,
      totalAfterRepairValue,
      initialAdvance,
      constructionHoldback,
      totalLoanAmount,
      afterRepairLTV,
    };
  }


  mapCalculatedFields() {
    const manualExceptionQuoteData = this.manualExceptionQuoteFormStore.calculatedFields;
    const instaLeverageQuote = this.instaLeverageQuoteFormStore.calculatedFields;

    return {
      totalLoanAmount: instaLeverageQuote.totalLoanAmount,
      exceptionTotalLoanAmount: manualExceptionQuoteData.totalLoanAmount,
    }
  }

  calculateKeyRatios() {
    const leverage = this.getLoanEconomics();
    const calculatedFields = this.calculatedFields;

    const ltcPurchasePricePercentage = formatPercentage(leverage.loanToCost);

    return `${ltcPurchasePricePercentage}`;
  }

  validateLeverageAmounts() {
    return [];
  }
}
