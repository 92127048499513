import { observer } from "mobx-react";
import { Alert } from "@material-ui/lab";
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { ForeignNationalOptions } from "libs/client-portal-shared/src/app/modules/internalPricer/utils/internalPricerConstants";
import { isNil } from "lodash";
import { DialogState } from "@roc/ui";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    alertWrapper: {
      width: '100%',
      marginBottom: '16px'
    },
    errorColor: {
      color: '#EB5463'
    },
  })
);

export const ErrorMessages = observer((props) => {
  const { dialogState, currentBorrower } = props;
  const classes = useStyles();

  const foreignNationalPGNoSSN = currentBorrower?.citizenshipStatus == ForeignNationalOptions.FOREIGN_NATIONAL && currentBorrower?.personalGuarantor == true && isNil(currentBorrower?.socialSecurityNumber) || (currentBorrower?.socialSecurityNumber == '');

  const newBorrower = (isNil(currentBorrower?.dob) || (currentBorrower?.dob == '')) && (isNil(currentBorrower?.socialSecurityNumber) || (currentBorrower?.socialSecurityNumber == ''));

  const sendIdVerification = !isNil(currentBorrower?.sendIdVerification) ? currentBorrower?.sendIdVerification : currentBorrower?.creditBackgroundCheck;

  const showCreditError = currentBorrower?.creditExpired || currentBorrower?.reissueFailed || currentBorrower?.creditPending || currentBorrower?.areCreditScoresFrozen || foreignNationalPGNoSSN || newBorrower

  return (
    <>
      {dialogState != DialogState.ADD && !isNil(currentBorrower) && showCreditError &&
        <Alert severity="warning" className={classes.alertWrapper}>
          {currentBorrower?.areCreditScoresFrozen ?
            'We were unable to pull the credit report for this borrower because two or more credit scores are frozen. Please provide a new report to proceed.'
            : currentBorrower?.reissueFailed ? 'We ran into an error pulling the report, please provide file number and report again to continue.'
              : currentBorrower?.creditExpired ?
                'The credit report has expired. Please send the authorization form to the borrower so we can pull fresh report or provide your own new report'
                : foreignNationalPGNoSSN ?
                  'Foreign national with no SSN cannot be a Personal Guarantor. Please go to the first step and unmark this borrower as a Personal Guarantor.'
                  : newBorrower ?
                    sendIdVerification ? 'Please inform the borrower to fill out the form sent, so the reports can be pulled.' :
                      'Please complete the borrower information so we can pull the reports.'
                    : 'Borrower Information is still missing - Please fill out the borrower information or choose the option for us to send an authorization form on your behalf.'
          }
        </Alert>
      }
    </>
  );
});

export default ErrorMessages;