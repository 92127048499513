import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import { usePayoffRequestStore } from '../hooks/usePayoffRequestStore';
import { ProgressBarComponent } from '@roc/ui';

const useStyles = makeStyles(theme => ({
  container: {
    padding: 32,
  },
  dealName: {
    display: 'flex',
    justifyContent: 'center',
  },
  paper: {
    padding: 24,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  title: {
    marginLeft: '-8px',
  },
  gridContainer: {
    paddingTop: 24,
  },
}));

export const PayoffDetails = observer(({ loanId }: { loanId: string }) => {
  const { payoffRequestStore } = usePayoffRequestStore();
  const classes = useStyles();

  return (
    <>
      <Grid item xs={12} md={12} className={classes.gridContainer}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            {payoffRequestStore.currentPayoff.loanPayoffStages?.length > 0 && (
              <ProgressBarComponent
                progressArray={
                  payoffRequestStore.currentPayoff.loanPayoffStages
                }
                active={payoffRequestStore.currentPayoff.payoffStatus}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
});
