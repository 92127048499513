import { Box } from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import {
  ConfirmationButton,
  DataGrid,
  GridColumnProps,
  LinkColumn,
  Toolbar,
} from '@roc/ui';
import { formatDate } from '@roc/feature-utils';
import { Button, IconButton, FilterButton } from '@roc/ui';
import { filterProps, formatCurrency } from '@roc/ui/utils';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useQuoteGeneratorRoutes } from './hooks/useQuoteGeneratorRoutes';
import { useQuoteGeneratorStore } from './hooks/useQuoteGeneratorStore';
import { Layout } from '@roc/feature-app-core';

const columns: GridColumnProps[] = [
  {
    field: 'quoteId',
    headerName: 'Number',
    maxWidth: 120,
    cellRenderer: 'addressCellRenderer',
    ...filterProps(),
  },
  {
    field: 'propertyAddress',
    headerName: 'Property Address',
    minWidth: 225,
    cellRenderer: 'addressCellRenderer',
    ...filterProps(),
  },
  {
    field: 'acquisitionPrice',
    headerName: 'Acquisition Price',
    minWidth: 225,
    cellRenderer: 'currencyCellRenderer',
    ...filterProps(),
  },
  {
    field: 'creation',
    headerName: 'Creation',
    minWidth: 225,
    cellRenderer: 'dateCellRenderer',
    filter: 'agDateColumnFilter',
    ...filterProps(),
  },
  {
    field: 'lastUpdate',
    headerName: 'Last Update',
    minWidth: 225,
    ...filterProps(),
  },
  {
    field: 'quoteId',
    headerName: 'Actions',
    minWidth: 225,
    cellRenderer: 'actionCellRenderer',
  },
];

export const QuoteGeneratorDashboard = observer(() => {
  const history = useHistory();
  const { quoteGeneratorRoutesConfig } = useQuoteGeneratorRoutes();
  const { quoteGeneratorStore } = useQuoteGeneratorStore();
  const { quoteGeneratorGridStore } = quoteGeneratorStore;
  const gridStore = quoteGeneratorGridStore;

  const createQuote = () => {
    const url = quoteGeneratorRoutesConfig.createQuote.url;
    history.push(url);
  };

  const editQuote = quoteId => {
    const url = quoteGeneratorRoutesConfig.viewQuote(quoteId).url;
    history.push(url);
  };

  const deleteQuote = (quoteId) => {
    quoteGeneratorStore.deleteQuote(quoteId);
  }

  useEffect(() => {
    gridStore.resetAndFetchGridData();
  }, []);

  const toolbar = (
    <Toolbar>
      <Box mr={2}>
        <FilterButton
          onToggleFilters={() => gridStore.toggleFilters()}
          onClearFilters={() => gridStore.resetFilters()}
        />
      </Box>
      <Box>
        <Button
          variant="contained"
          size="small"
          onClick={() => createQuote()}
          color="primary"
          testId="creqte-new-quote"
        >
          Create Quote
        </Button>
      </Box>
    </Toolbar>
  );

  const frameworkComponents = {
    addressCellRenderer(params) {
      const quoteId = params.node.data?.quoteId;
      return LinkColumn({
        ...params,
        url: quoteGeneratorRoutesConfig.viewQuote(quoteId).url,
      });
    },
    currencyCellRenderer(params) {
      return params.value ? formatCurrency(params.value) : '';
    },
    dateCellRenderer(params) {
      return params.value
        ? formatDate(params.value, 'MM/dd/yyyy hh:mm:ss')
        : null;
    },
    actionCellRenderer(params) {
      const quoteId = params.node.data?.quoteId;
      return (
        <>
          <IconButton
            testId={`edit-quote-${quoteId}`}
            onClick={() => {
              editQuote(quoteId);
            }}
          >
            <Edit color="primary" />
          </IconButton>

          <ConfirmationButton
            testId={`delete-quote-${quoteId}`}
            onClick={() => deleteQuote(quoteId)}
            confirmDialogProps={{
              body: 'Are you sure you want to remove this quote?',
            }}
          >
            <Delete color="primary" />
          </ConfirmationButton>
        </>
      );
    },
  };

  const onFilterChanged = params => {
    const filterModel = params.api.getFilterModel();
    gridStore.setFilterModel(filterModel);
  };

  const onSortChanged = params => {
    const sortModel = params.api.getSortModel();
    gridStore.setSortModel(sortModel);
  };

  return (
    <Layout title="Bridge Quotes">
      <DataGrid
        columns={columns}
        rows={gridStore.gridData.data.rows}
        frameworkComponents={frameworkComponents}
        toolbar={toolbar}
        onSortChanged={onSortChanged}
        onFilterChanged={onFilterChanged}
        isLoading={gridStore.isLoading}
        showFilters={gridStore.showFilters}
        sortModel={gridStore.sortModel}
        filterModel={gridStore.filterModel}
        paginationData={gridStore.gridData.meta}
        setPageNumber={gridStore.setPageNumber}
      />
    </Layout>
  );
});
