import { Grid } from '@material-ui/core';
import {
  createCurrencyField,
  createSelectField,
  createTextField,
  createRadioField,
  createDateField,
  createSliderField,
} from '@roc/client-portal-shared/components';
import { observer } from 'mobx-react';
import { StabilizedBridgeAnalyzerStore } from '../stores/stabilizedBridgeAnalyzerStore';
import { loanPurposeOptions, loanPropertyTypes, rateOptions, exitRateOptions, useStyles } from '../utils/stabilizedBridgeAnalyzerConstants';

interface StabilizedBridgeAnalyzerFieldProps {
  store: StabilizedBridgeAnalyzerStore;
  tooltip: string;
}

const radioFieldOptions = [
  {
    label: 'Yes',
    value: true,
  },
  {
    label: 'No',
    value: false,
  },
];


const field = FieldComponent => {
  const WrappedFieldComponent = observer(FieldComponent);
  return props => (
    <Grid item xs={6} md={props.md}>
      <WrappedFieldComponent {...props} />
    </Grid>
  );
};

export const LoanId = field(({ store }: StabilizedBridgeAnalyzerFieldProps) => {
  return createTextField({
    store,
    label: 'Loan Id',
    testId: 'loanId',
    fieldName: 'loanId',
  });
});


export const AnnualTaxes = field(({ store }: StabilizedBridgeAnalyzerFieldProps) => {
  return createCurrencyField({
    store,
    label: 'Annual Taxes',
    testId: 'annualTaxes',
    fieldName: 'annualTaxes',
  });
});

export const AnnualInsurance = field(({ store }: StabilizedBridgeAnalyzerFieldProps) => {
  return createCurrencyField({
    store,
    label: 'Annual Insurance',
    testId: 'annualInsurance',
    fieldName: 'annualInsurance',
  });
});

export const LoanPurpose = field(({ store }: StabilizedBridgeAnalyzerFieldProps) => {
  return createSelectField({
    store,
    label: 'Loan Purpose',
    testId: 'loanPurpose',
    fieldName: 'loanPurpose',
    options: loanPurposeOptions,
  });
});


export const LoanPropertyType = field(({ store }: StabilizedBridgeAnalyzerFieldProps) => {
  return createSelectField({
    store,
    label: 'Property Type',
    testId: 'propertyType',
    fieldName: 'propertyType',
    options: loanPropertyTypes
  });
});

export const EstimatedAsIsValue = field(({ store }: StabilizedBridgeAnalyzerFieldProps) => {
  return createCurrencyField({
    store,
    label: 'Estimated As Is Value',
    testId: 'estimatedAsIsValue',
    fieldName: 'estimatedAsIsValue',
  });
});

export const GrossMonthlyRent = field(({ store }: StabilizedBridgeAnalyzerFieldProps) => {
  return createCurrencyField({
    store,
    label: 'Monthly Gross Rent',
    testId: 'monthlyGrossRent',
    fieldName: 'monthlyGrossRent',
  });
});

export const MonthlyMarketRent = field(({ store }: StabilizedBridgeAnalyzerFieldProps) => {
  return createCurrencyField({
    store,
    label: 'Monthly Market Rent',
    testId: 'monthlyMarketRent',
    fieldName: 'monthlyMarketRent',
  });
});

export const AnnualHOADues = field(({ store }: StabilizedBridgeAnalyzerFieldProps) => {
  return createCurrencyField({
    store,
    label: 'Annual HOA Dues',
    testId: 'annualHOA',
    fieldName: 'annualHOA',
  });
});

export const LoanRate = field(({ store }: StabilizedBridgeAnalyzerFieldProps) => {
  return createSelectField({
    store,
    label: 'Bridge Loan Rate',
    testId: 'rate',
    fieldName: 'rate',
    options: rateOptions,
  });
});

export const ExitRate = field(({ store }: StabilizedBridgeAnalyzerFieldProps) => {
  return createSelectField({
    store,
    label: 'Exit Rate',
    testId: 'exitRate',
    fieldName: 'exitRate',
    options: exitRateOptions,
  });
});

export const VacantProperty = field(({ store }: StabilizedBridgeAnalyzerFieldProps) => {
  return createRadioField({
    store,
    testId: 'occupancy',
    fieldName: 'occupancy',
    label: 'Is the Property currently vacant?',
    options: radioFieldOptions,
    row: true,
  });
});


// Purchase
export const PurchasePrice = field(({ store }: StabilizedBridgeAnalyzerFieldProps) => {
  return createCurrencyField({
    store,
    label: 'Purchase Price',
    testId: 'purchasePrice',
    fieldName: 'purchasePrice',
  });
});

export const PurchaseDate = field(({ store }: StabilizedBridgeAnalyzerFieldProps) => {
  return createDateField({
    store,
    label: 'Purchase Date',
    testId: 'purchaseDate',
    fieldName: 'purchaseDate',
  });
});

export const CapitalImprovements = field(({ store }: StabilizedBridgeAnalyzerFieldProps) => {
  return createCurrencyField({
    store,
    label: 'Completed Renovations',
    testId: 'capitalImprovements',
    fieldName: 'capitalImprovements',
  });
});

export const QualifyingFicoScore = field(({ store }: StabilizedBridgeAnalyzerFieldProps) => {
  return createSliderField({
    store,
    testId: 'ficoProvidedAtOrigination',
    label: "Borrower's Fico Score",
    fieldName: 'ficoProvidedAtOrigination',
    valueLabelDisplay: 'on',
    className: useStyles().slider,
    min: 660,
    max: 800,
    step: 10,
    marks: true,
  });
});
