import { Box, Switch, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useParams } from "react-router";
import { useForeclosureSummaryStore } from "../../hooks/useForeclosureSummaryStore";

const useStyles = makeStyles(() =>
  createStyles({
    defaultLabel: {
      fontFamily: 'Oswald, sans-serif;',
    },
    boldLabel: {
      fontFamily: 'Oswald, sans-serif;',
      fontWeight: 'bold'
    },
    repurchaseBox: {
      paddingLeft: 8,
    },
  }),
);

export const ForeclosureRepurchase = ({ isRepurchaseRequested }) => {
  const classes = useStyles();
  const { loanId } = useParams<{ loanId: string }>();
  const { foreclosureSummaryStore } = useForeclosureSummaryStore();

  const onRepurchaseRequestedChange = () => {
    foreclosureSummaryStore.saveForeclosureRepurchaseRequested(
      {
        loanId: Number(loanId),
        repurchaseRequested: !isRepurchaseRequested
      }
    )
  }

  return (
    <Box display='flex' alignItems='center' className={classes.repurchaseBox}>
      <Typography variant='h6' className={classes.boldLabel}>
        Repurchase Requested
      </Typography>
      <Switch
        color="primary"
        size="medium"
        checked={isRepurchaseRequested}
        onChange={onRepurchaseRequestedChange}
      />
    </Box>
  )
}