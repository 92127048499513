import { Grid } from '@material-ui/core';
import { Document } from '@roc/feature-types';
import { Button, StandardDialog } from '@roc/ui';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { useDocumentStore } from '../../../hooks/useDocumentStore';
import BorrowerEntity from './borrowerEntity';
import BorrowerEntityWarnings from './borrowerEntityWarnings';
import EditBorrowerDialog from './editBorrowerDialog';
import SaveButtonWithPermissions from 'libs/ui/src/lib/components/shared/save-button-with-permissions';

export type BorrowerEntityDialogProps = {
  open?: boolean;
  onClose?: () => void;
  loanId: any;
  document: Document;
};

export const BorrowerEntityDialog = observer(
  (props: BorrowerEntityDialogProps) => {
    const { open, onClose, loanId, document } = props;
    const { documentStore } = useDocumentStore();
    const { documentFormStore } = documentStore;
    const { borrowerEntityStore } = documentFormStore;

    const onSubmit = async () => {
      borrowerEntityStore.submit();
    };

    useEffect(() => {
      if (open) {
        borrowerEntityStore.initForm(document);
      }
    }, [open]);

    return (
      <>
        <StandardDialog
          open={open}
          title="Borrower Entity"
          maxWidth="lg"
          handleClose={onClose}
          removeContentBox
          dialogContent={<BorrowerEntity store={borrowerEntityStore} />}
          dialogActions={
            <Grid container spacing={1} justifyContent="flex-end">
              <Grid item>
                <Button
                  onClick={onClose}
                  color="primary"
                  variant="outlined"
                  testId="cancel"
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <SaveButtonWithPermissions
                  onClick={onSubmit}
                  disabled={!borrowerEntityStore.canEdit}
                  color="primary"
                  variant="contained"
                  testId="confirm"
                  label="Submit"
                  loanId={loanId}
                />
              </Grid>
            </Grid>
          }
        />
        <EditBorrowerDialog />
        <BorrowerEntityWarnings />
      </>
    );
  }
);

export default BorrowerEntityDialog;
