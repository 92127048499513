import React from 'react';
import { TextField } from '@roc/ui';
import CompanyFormStore from '../stores/common/companyFormStore';
import { Grid } from '@material-ui/core';
import { observer } from 'mobx-react';
import { isNotBlank } from '@roc/feature-utils';
import {
  CellPhone,
  EmailAddress,
  Address,
} from '@roc/ui/formComponents';

interface CompanyFormProps {
  store: CompanyFormStore;
}

const CompanyForm = (props: CompanyFormProps) => {
  const { store } = props;
  const { form } = store;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          testId="name"
          variant="outlined"
          label="Name"
          type="text"
          value={form.fields.name.value}
          onChange={e => store.onFieldChange('name', e.target.value)}
          helperText={form.fields.name.error}
          error={isNotBlank(form.fields.name.error)}
          standaloneLabel
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          testId="contactName"
          variant="outlined"
          label="Contact Name"
          type="text"
          value={form.fields.contactName.value}
          onChange={e => store.onFieldChange('contactName', e.target.value)}
          helperText={form.fields.contactName.error}
          error={isNotBlank(form.fields.contactName.error)}
          standaloneLabel
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <CellPhone store={store} />
      </Grid>
      <Grid item xs={12}>
        <Address store={store} />
      </Grid>
      <Grid item xs={12}>
        <EmailAddress store={store} />
      </Grid>
    </Grid>
  );
};

export default observer(CompanyForm);
