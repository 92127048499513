import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Box, CardActions, Grid, MenuItem, Select, Toolbar, lighten } from '@material-ui/core';
import GaugeChart from 'react-gauge-chart';
import { Button, TextField } from '@roc/ui';
import { useState } from 'react';
import { Edit } from '@material-ui/icons';
import clsx from 'clsx';

const useStyles = makeStyles({
  root: {
    height: '100%',
    '& .gauge-container': {
      '& text': {
        fontWeight: 'bold'
      }
    }
  },
  paperShadow: {
    boxShadow: '0px 0px 16px 0px #757373',
  }
});

const needleColor = '#777';
const textColor = '#111';
const redColor = '#EA4228';
const lighten_redColor = lighten('#EA4228', 0.9);
const yellowColor = '#F5CD19';
const lighten_yellowColor = lighten('#F5CD19', 0.9);
const lightGreenColor = '#a2d716';
const lighten_lightGreenColor = lighten('#a2d716', 0.9);
const darkGreenColor = '#008305';
const lighten_darkGreenColor = lighten('#008305', 0.9);
const grayColor = '#EEE';

enum AppraisalQuality {
  NotRated = 'Not Rated',
  Excellent = 'Excellent',
  Good = 'Good',
  Fair = 'Fair',
  Poor = 'Poor'
};

const AppraisalQualityData = {
  [AppraisalQuality.Poor]: {
    arcLength: [0.25, 0.25, 0.25, 0.25],
    needlePointer: 0.125,
    colors: [redColor, lighten_yellowColor, lighten_lightGreenColor, lighten_darkGreenColor],
    needleColor,
    textColor
  },
  [AppraisalQuality.Fair]: {
    arcLength: [0.25, 0.25, 0.25, 0.25],
    needlePointer: 0.375,
    colors: [lighten_redColor, yellowColor, lighten_lightGreenColor, lighten_darkGreenColor],
    needleColor,
    textColor
  },
  [AppraisalQuality.Good]: {
    arcLength: [0.25, 0.25, 0.25, 0.25],
    needlePointer: 0.625,
    colors: [lighten_redColor, lighten_yellowColor, lightGreenColor, lighten_darkGreenColor],
    needleColor,
    textColor
  },
  [AppraisalQuality.Excellent]: {
    arcLength: [0.25, 0.25, 0.25, 0.25],
    needlePointer: 0.875,
    colors: [lighten_redColor, lighten_yellowColor, lighten_lightGreenColor, darkGreenColor],
    needleColor,
    textColor
  },
}

const AppraisalQualityGauge = ({ appraisalQuality }: {
  appraisalQuality: AppraisalQuality;
}) => {
  const {
    arcLength,
    colors,
    textColor,
    needleColor,
    needlePointer
  } = AppraisalQualityData[appraisalQuality];
  return (
    <GaugeChart
      animate={false}
      arcsLength={arcLength}
      colors={colors}
      textColor={textColor}
      needleColor={needleColor}
      needleBaseColor={needleColor}
      percent={needlePointer}
      arcPadding={0.02}
      formatTextValue={() => appraisalQuality.toString()}
      className={'gauge-container'}
      id={'appraisal-quality-guage'}
    />
  )
};

enum PropertyComplexity {
  NotRated = 'Not Rated',
  Extreme = 'Extreme',
  High = 'High',
  Moderate = 'Moderate',
  Low = 'Low'
};

const PropertyComplexityData = {
  [PropertyComplexity.Low]: {
    arcLength: [0.25, 0.25, 0.25, 0.25],
    needlePointer: 0.125,
    colors: [darkGreenColor, lighten_lightGreenColor, lighten_yellowColor, lighten_redColor],
    needleColor,
    textColor
  },
  [PropertyComplexity.Moderate]: {
    arcLength: [0.25, 0.25, 0.25, 0.25],
    needlePointer: 0.375,
    colors: [lighten_darkGreenColor, lightGreenColor, lighten_yellowColor, lighten_redColor],
    needleColor,
    textColor
  },
  [PropertyComplexity.High]: {
    arcLength: [0.25, 0.25, 0.25, 0.25],
    needlePointer: 0.625,
    colors: [lighten_darkGreenColor, lighten_lightGreenColor, yellowColor, lighten_redColor],
    needleColor,
    textColor
  },
  [PropertyComplexity.Extreme]: {
    arcLength: [0.25, 0.25, 0.25, 0.25],
    needlePointer: 0.875,
    colors: [lighten_darkGreenColor, lighten_lightGreenColor, lighten_yellowColor, redColor],
    needleColor,
    textColor
  },
}

const PropertyComplexityGauge = ({ propertyComplexity }: {
  propertyComplexity: PropertyComplexity;
}) => {
  const {
    arcLength,
    colors,
    textColor,
    needleColor,
    needlePointer
  } = PropertyComplexityData[propertyComplexity];
  return (
    <GaugeChart
      animate={false}
      arcsLength={arcLength}
      colors={colors}
      textColor={textColor}
      needleColor={needleColor}
      needleBaseColor={needleColor}
      percent={needlePointer}
      arcPadding={0.02}
      formatTextValue={() => propertyComplexity.toString()}
      className={'gauge-container'}
      id={'property-complexity-guage'}
    />
  )
};

enum OvervaluationRisk {
  NotRated = 'Not Rated',
  High = 'High',
  Moderate = 'Moderate',
  Low = 'Low'
};

const OvervaluationRiskData = {
  [OvervaluationRisk.Low]: {
    arcLength: [0.33, 0.33, 0.34],
    needlePointer: 0.165,
    colors: [darkGreenColor, lighten_yellowColor, lighten_redColor],
    needleColor,
    textColor
  },
  [OvervaluationRisk.Moderate]: {
    arcLength: [0.33, 0.33, 0.34],
    needlePointer: 0.495,
    colors: [lighten_darkGreenColor, yellowColor, lighten_redColor],
    needleColor,
    textColor
  },
  [OvervaluationRisk.High]: {
    arcLength: [0.33, 0.33, 0.34],
    needlePointer: 0.83,
    colors: [lighten_darkGreenColor, lighten_yellowColor, redColor],
    needleColor,
    textColor
  },
}

const OvervaluationRiskGauge = ({ overvaluationRisk }: {
  overvaluationRisk: OvervaluationRisk;
}) => {
  const {
    arcLength,
    colors,
    textColor,
    needleColor,
    needlePointer
  } = OvervaluationRiskData[overvaluationRisk];
  return (
    <GaugeChart
      animate={false}
      arcsLength={arcLength}
      colors={colors}
      textColor={textColor}
      needleColor={needleColor}
      needleBaseColor={needleColor}
      percent={needlePointer}
      arcPadding={0.02}
      formatTextValue={() => overvaluationRisk.toString()}
      className={'gauge-container'}
      id={'overvaluation-risk-guage'}
    />
  )
};

const GrayGauge = ({ text }) => {
  return (
    <GaugeChart
      arcsLength={[1]}
      colors={[grayColor]}
      textColor={textColor}
      needleColor={needleColor}
      needleBaseColor={needleColor}
      percent={0}
      arcPadding={0.02}
      formatTextValue={() => text}
    />
  )
};

export const ValuationReviewCard = ({ layout }: {
  layout: 1 | 2
}) => {
  const classes = useStyles();
  const [comments, setComments] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [appraisalQuality, setAppraisalQuality] = useState<AppraisalQuality>(AppraisalQuality.NotRated);
  const [propertyComplexity, setPropertyComplexity] = useState<PropertyComplexity>(PropertyComplexity.NotRated);
  const [overvaluationRisk, setOvervaluationRisk] = useState<OvervaluationRisk>(OvervaluationRisk.NotRated);

  return (
    <Card className={clsx(classes.root, editMode ? classes.paperShadow : '')} elevation={0} variant='outlined'>
      <Toolbar style={{
        minHeight: 52,
        padding: '8px 16px',
      }}>
        <Typography variant="h6" component="div">
          Valuation Review
        </Typography>
        {
          !editMode &&
          <Box ml={2}>
            <Button testId='save' size="small" color="primary"
              variant="contained"
              startIcon={<Edit />}
              onClick={() => {
                setEditMode(true);
              }}>
              Edit
            </Button>
          </Box>
        }
      </Toolbar>
      <CardContent style={{
        padding: 16
      }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={layout == 1 ? 4 : 12} lg={layout == 1 ? 6 : 12}>
            <Typography variant='h6'>
              Comments
            </Typography>
            <Box>
              <TextField
                testId='valuationReviewComments'
                label=""
                placeholder="Type your comments here"
                multiline
                minRows={layout == 1 ? 4 : 8}
                maxRows={10}
                defaultValue=""
                value={comments}
                onChange={(e) => {
                  setComments(e.target.value);
                }}
                variant="outlined"
                fullWidth
                InputProps={{
                  readOnly: !editMode,
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={layout == 1 ? 8 : 12} lg={layout == 1 ? 6 : 12}>
            <Grid container>
              <Grid item xs={4} md={layout == 1 ? 4 : 12} lg={4} style={{
                textAlign: 'center'
              }}>
                <Typography variant='h6'>
                  Appraisal Quality
                </Typography>
                <Box width={'100%'} maxWidth={250} margin={'auto'}>
                  {
                    editMode ?
                      <Box p={2}>
                        <Select
                          fullWidth
                          variant='outlined'
                          value={appraisalQuality}
                          onChange={(e) => {
                            setAppraisalQuality(e.target.value as AppraisalQuality);
                          }}
                          renderValue={(selected: AppraisalQuality) => {
                            let bgColor;
                            if (selected == AppraisalQuality.Excellent) {
                              bgColor = darkGreenColor;
                            } else if (selected == AppraisalQuality.Good) {
                              bgColor = lightGreenColor;
                            } else if (selected == AppraisalQuality.Fair) {
                              bgColor = yellowColor;
                            } else if (selected == AppraisalQuality.Poor) {
                              bgColor = redColor;
                            } else if (selected == AppraisalQuality.NotRated) {
                              bgColor = grayColor;
                            }
                            return (
                              <Box display={'flex'} justifyContent={'center'}>
                                <Box component={'span'} mr={1} width={20} height={20} bgcolor={bgColor} />
                                {
                                  selected ? selected : 'Not Rated'
                                }
                              </Box>
                            )
                          }}
                          label=""
                        >
                          <MenuItem value={AppraisalQuality.NotRated}>
                            <Box component={'span'} mr={1} width={20} height={20} bgcolor={grayColor} />
                            Not Rated
                          </MenuItem>
                          <MenuItem value={AppraisalQuality.Poor}>
                            <Box component={'span'} mr={1} width={20} height={20} bgcolor={redColor} />
                            {AppraisalQuality.Poor}
                          </MenuItem>
                          <MenuItem value={AppraisalQuality.Fair}>
                            <Box component={'span'} mr={1} width={20} height={20} bgcolor={yellowColor} />
                            {AppraisalQuality.Fair}
                          </MenuItem>
                          <MenuItem value={AppraisalQuality.Good}>
                            <Box component={'span'} mr={1} width={20} height={20} bgcolor={lightGreenColor} />
                            {AppraisalQuality.Good}
                          </MenuItem>
                          <MenuItem value={AppraisalQuality.Excellent}>
                            <Box component={'span'} mr={1} width={20} height={20} bgcolor={darkGreenColor} />
                            {AppraisalQuality.Excellent}
                          </MenuItem>
                        </Select></Box> :
                      <>
                        {
                          appraisalQuality == AppraisalQuality.NotRated ? <GrayGauge text={'Not Rated'} /> :
                            <AppraisalQualityGauge appraisalQuality={appraisalQuality} />
                        }
                      </>
                  }
                </Box>
              </Grid>
              <Grid item xs={4} md={layout == 1 ? 4 : 6} lg={4} style={{
                textAlign: 'center'
              }}>
                <Typography variant='h6'>
                  Property Complexity
                </Typography>
                <Box width={'100%'} maxWidth={250} margin={'auto'}>
                  {
                    editMode ?
                      <>
                        <Box p={2}>
                          <Select
                            fullWidth
                            variant='outlined'
                            value={propertyComplexity}
                            onChange={(e) => {
                              setPropertyComplexity(e.target.value as PropertyComplexity);
                            }}
                            renderValue={(selected: PropertyComplexity) => {
                              let bgColor;
                              if (selected == PropertyComplexity.Extreme) {
                                bgColor = redColor;
                              } else if (selected == PropertyComplexity.High) {
                                bgColor = yellowColor;
                              } else if (selected == PropertyComplexity.Moderate) {
                                bgColor = lightGreenColor;
                              } else if (selected == PropertyComplexity.Low) {
                                bgColor = darkGreenColor;
                              } else if (selected == PropertyComplexity.NotRated) {
                                bgColor = grayColor;
                              }
                              return (
                                <Box display={'flex'} justifyContent={'center'}>
                                  <Box component={'span'} mr={1} width={20} height={20} bgcolor={bgColor} />
                                  {
                                    selected ? selected : 'Not Rated'
                                  }
                                </Box>
                              )
                            }}
                            label=""
                          >
                            <MenuItem value={PropertyComplexity.NotRated}>
                              <Box component={'span'} mr={1} width={20} height={20} bgcolor={grayColor} />
                              Not Rated
                            </MenuItem>
                            <MenuItem value={PropertyComplexity.Low}>
                              <Box component={'span'} mr={1} width={20} height={20} bgcolor={darkGreenColor} />
                              {PropertyComplexity.Low}
                            </MenuItem>
                            <MenuItem value={PropertyComplexity.Moderate}>
                              <Box component={'span'} mr={1} width={20} height={20} bgcolor={lightGreenColor} />
                              {PropertyComplexity.Moderate}
                            </MenuItem>
                            <MenuItem value={PropertyComplexity.High}>
                              <Box component={'span'} mr={1} width={20} height={20} bgcolor={yellowColor} />
                              {PropertyComplexity.High}
                            </MenuItem>
                            <MenuItem value={PropertyComplexity.Extreme}>
                              <Box component={'span'} mr={1} width={20} height={20} bgcolor={redColor} />
                              {PropertyComplexity.Extreme}
                            </MenuItem>
                          </Select></Box>
                      </> :
                      <>
                        {
                          propertyComplexity == PropertyComplexity.NotRated ? <GrayGauge text={'Not Rated'} /> :
                            <PropertyComplexityGauge propertyComplexity={propertyComplexity} />
                        }
                      </>
                  }
                </Box>
              </Grid>
              <Grid item xs={4} md={layout == 1 ? 4 : 6} lg={4} style={{
                textAlign: 'center'
              }}>
                <Typography variant='h6'>
                  Overvaluation Risk
                </Typography>
                <Box width={'100%'} maxWidth={250} margin={'auto'}>
                  {
                    editMode ?
                      <>
                        <Box p={2}>
                          <Select
                            fullWidth
                            variant='outlined'
                            value={overvaluationRisk}
                            onChange={(e) => {
                              setOvervaluationRisk(e.target.value as OvervaluationRisk);
                            }}
                            renderValue={(selected: OvervaluationRisk) => {
                              let bgColor;
                              if (selected == OvervaluationRisk.High) {
                                bgColor = redColor;
                              } else if (selected == OvervaluationRisk.Moderate) {
                                bgColor = yellowColor;
                              } else if (selected == OvervaluationRisk.Low) {
                                bgColor = darkGreenColor;
                              } else if (selected == OvervaluationRisk.NotRated) {
                                bgColor = grayColor;
                              }
                              return (
                                <Box display={'flex'} justifyContent={'center'}>
                                  <Box component={'span'} mr={1} width={20} height={20} bgcolor={bgColor} />
                                  {
                                    selected ? selected : 'Not Rated'
                                  }
                                </Box>
                              )
                            }}
                            label=""
                          >
                            <MenuItem value={OvervaluationRisk.NotRated}>
                              <Box component={'span'} mr={1} width={20} height={20} bgcolor={grayColor} />
                              Not Rated
                            </MenuItem>
                            <MenuItem value={OvervaluationRisk.Low}>
                              <Box component={'span'} mr={1} width={20} height={20} bgcolor={darkGreenColor} />
                              {OvervaluationRisk.Low}
                            </MenuItem>
                            <MenuItem value={OvervaluationRisk.Moderate}>
                              <Box component={'span'} mr={1} width={20} height={20} bgcolor={yellowColor} />
                              {OvervaluationRisk.Moderate}
                            </MenuItem>
                            <MenuItem value={OvervaluationRisk.High}>
                              <Box component={'span'} mr={1} width={20} height={20} bgcolor={redColor} />
                              {OvervaluationRisk.High}
                            </MenuItem>
                          </Select></Box>
                      </> :
                      <>
                        {
                          overvaluationRisk == OvervaluationRisk.NotRated ? <GrayGauge text={'Not Rated'} /> :
                            <OvervaluationRiskGauge overvaluationRisk={overvaluationRisk} />
                        }
                      </>
                  }
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
      {
        editMode &&
        <CardActions style={{
          display: 'flex',
          justifyContent: 'flex-end',
          margin: '0 8px 8px 0',
        }}>
          <Button testId='cancel' size="small" color="default" variant="outlined" onClick={() => {
            // need to reset the values
            setEditMode(false);
          }}>
            Cancel
          </Button>
          <Button testId='save' size="small" color="primary" variant="contained" onClick={() => {
            setEditMode(false);
          }}>
            Save
          </Button>
        </CardActions>
      }
    </Card>
  );
}
