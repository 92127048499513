import { Route } from 'react-router';
import { SigmaDashboards } from '../sigmaDashboards';
import { Page } from '@roc/feature-app-core';
import { useStore } from '../../../hooks/useStore';

export const getSigmaDashboardsRoutesConfig = (
  basePath: string,
  baseUrl: string
) => {
  return {
    sigmaDashboards: {
      path: `${basePath}/sigma-dashboards`,
      url: `${baseUrl}/sigma-dashboards`,
      documentTitle: 'Sigma Dashboards',
    },
  };
};

export const getSigmaDashboardsRoutes = (basePath: string, baseUrl: string) => {
  const config = getSigmaDashboardsRoutesConfig(basePath, baseUrl);
  const { sigmaDashboardsStore } = useStore();

  return {
    sigmaDashboards: (
      <Route exact path={config.sigmaDashboards.path}>
        <Page routeDefinition={config.sigmaDashboards}>
          <SigmaDashboards sigmaDashboardsStore={sigmaDashboardsStore}/>
        </Page>
      </Route>
    ),
  };
};
