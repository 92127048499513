import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { Layout } from '@roc/feature-app-core';
import { Card, IconFilterButton, Toolbar } from '@roc/ui';
import { observer } from 'mobx-react';
import { MyPayoffsGrid } from '@roc/feature-loans';
import { useLoanStore } from '@roc/feature-loans';
import { usePayoffRequestStore } from 'libs/feature-loans/src/loans/loanDetails/modules/loanRequests/hooks/usePayoffRequestStore';
const useStyles = makeStyles(theme => ({
  container: {
    padding: 32,
  },
}));
export const PayoffsDashboard = observer(() => {
  const classes = useStyles();
  const { loanStore } = useLoanStore();
  const { payoffRequestStore } = usePayoffRequestStore();

  const toolbar = (
    <Toolbar
      toolbarContainerStyle={{
        border: 0,
        padding: 0,
      }}
    >
      <IconFilterButton
        onToggleFilters={() => payoffRequestStore.myPayoffsGridStore.toggleFilters()}
        onClearFilters={() => payoffRequestStore.myPayoffsGridStore.resetFilters()}
        showFilters={payoffRequestStore.myPayoffsGridStore.showFilters}
      />
    </Toolbar>
  );
  return (
    <Grid
      container
      direction="column"
      spacing={2}
      className={classes.container}
    >
      <Grid item>
        <Typography variant="h4">PAYOFFS</Typography>
      </Grid>
      <Grid
        item
        style={{ display: 'flex', flexGrow: 1, flexDirection: 'column' }}
      >
        <MyPayoffsGrid toolbar={toolbar} />
      </Grid>
    </Grid>
  );
});
