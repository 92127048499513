import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { StandardDialog, Button } from '@roc/ui';
import { observer } from 'mobx-react';
import { ScopeOfWorkV2Link } from './scopeOfWorkV2Link';
import {
  ScopeOfWorkV2LinkStore,
  ScopeOfWorkV2Option,
} from '@roc/feature-sow-shared/stores/v2';
import { useRocInternalPortalStore } from 'apps/roc-internal-portal/src/app/hooks';
import { useParams } from 'react-router';
import { useUserStore } from '@roc/feature-app-core';
import { useDocumentStore } from '@roc/feature-documents';

export type ScopeOfWorkV2LinkInternalDialog = {
  open?: boolean;
  onClose?: () => void;
  loanId: number;
  propertyId: number;
  propertyAddress: string;
  store: ScopeOfWorkV2LinkStore;
};

export const ScopeOfWorkV2LinkInternalDialog = observer(
  (props: ScopeOfWorkV2LinkInternalDialog) => {
    const { open, onClose, loanId, propertyId, propertyAddress, store } = props;
    const {
      scopeOfWorkInternalStore,
      scopeOfWorkV2LinkInternalStore,
    } = useRocInternalPortalStore();
    const { userStore } = useUserStore();
    const { documentStore } = useDocumentStore();

    const { linkedSowData } = scopeOfWorkV2LinkInternalStore;

    const username = userStore.userInformation.username;

    useEffect(() => {
      if (open) {
        scopeOfWorkInternalStore.setRenderScopeOfWorkV2LinkFromUW(true);
        scopeOfWorkInternalStore.setRenderScopeOfWorkV2Link(true);
        scopeOfWorkInternalStore.setScopeOfWorkInternalDialogParams(
          loanId,
          propertyId,
          username,
          propertyAddress
        );
        scopeOfWorkV2LinkInternalStore.setValues(
          loanId,
          propertyId,
          propertyAddress
        );
      }
    }, [open]);

    const tryAndLinkScopeOfWork = async () => {
      try {
        scopeOfWorkV2LinkInternalStore.setValues(
          loanId,
          propertyId,
          propertyAddress
        );
        scopeOfWorkV2LinkInternalStore.tryAndLinkScopeOfWork();
      } catch (error) {
        console.error('Error linking scope of work:', error);
      }
    };

    useEffect(() => {
      if (linkedSowData) {
        documentStore.documentFormStore.openScopeOfWorkForm(linkedSowData);
        scopeOfWorkV2LinkInternalStore.setLinkedSowData(null);
      }
    }, [linkedSowData]);

    return (
      <StandardDialog
        open={open}
        title="Link Scope of Work"
        maxWidth="lg"
        handleClose={onClose}
        dialogContent={
          <ScopeOfWorkV2Link
            loanId={loanId}
            propertyId={propertyId}
            propertyAddress={propertyAddress}
            store={scopeOfWorkV2LinkInternalStore}
          />
        }
        dialogActions={
          <Grid container spacing={1} justifyContent="flex-end">
            <Grid item>
              <Button
                onClick={onClose}
                color="primary"
                variant="outlined"
                testId="cancel"
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={tryAndLinkScopeOfWork}
                color="primary"
                variant="contained"
                testId="confirm"
                disabled={!scopeOfWorkV2LinkInternalStore.canSubmit}
              >
                {store.scopeOfWorkOption ===
                ScopeOfWorkV2Option.COPY_SCOPE_OF_WORK
                  ? 'Create copy & Link Scope Of Work '
                  : 'Link & Open Scope Of Work'}
              </Button>
            </Grid>
          </Grid>
        }
      />
    );
  }
);
