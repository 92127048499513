import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Box, Grid, Typography } from '@material-ui/core';
import { Borrower } from '../../types';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import { formatCurrency } from '@roc/ui/utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rootContainer: {
      paddingTop: '24px'
    },
    labelText: {
      fontWeight: 'bold',
      color: 'gray',
      paddingRight: '8px'
    },
    valueText: {
      fontWeight: 'bold',
    },
    infoContainer: {
      paddingBottom: '16px',
    },
  })
);

export type PersonalInfoTabProps = {
  borrower?: Borrower;
};

export const PersonalInfoTab = observer((props: PersonalInfoTabProps) => {
  const { borrower } = props;
  const classes = useStyles();

  useEffect(() => {
    //
  }, [borrower]);

  return (
    <Grid container className={classes.rootContainer}>
      <Grid item xs={12}>
        <Box style={{ paddingBottom: '16px' }}>
          <Alert severity="info" style={{
            fontSize: '0.875rem',
          }}>
            Personal Information can only be updated via Pre-Screen process. Please reach out to tech support in case of any queries.
          </Alert>
        </Box>
        <Grid container className={classes.infoContainer}>
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant='body2' className={classes.labelText}>Citizenship Status</Typography>
            <Typography className={classes.valueText}>US Citizen</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={9}>
            <Typography variant='body2' className={classes.labelText}>Address</Typography>
            <Typography className={classes.valueText}>{borrower?.address || '-'}</Typography>
          </Grid>
        </Grid>
        <Grid container className={classes.infoContainer}>
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant='body2' className={classes.labelText}>Driver's License State</Typography>
            <Typography className={classes.valueText}>{borrower?.driverLicenseState || '-'}</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant='body2' className={classes.labelText}>Driver's License Number</Typography>
            <Typography className={classes.valueText}>{borrower?.driverLicenseNumber || '-'}</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant='body2' className={classes.labelText}>Driver's License Expiration Date</Typography>
            <Typography className={classes.valueText}>{borrower?.driverLicenseExpirationDate || '-'}</Typography>
          </Grid>
        </Grid>
        <Grid container className={classes.infoContainer}>
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant='body2' className={classes.labelText}>Assets</Typography>
            <Typography className={classes.valueText}>{borrower?.assets ? formatCurrency(borrower?.assets) : '-'}</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant='body2' className={classes.labelText}>Liabilities</Typography>
            <Typography className={classes.valueText}>{borrower?.liabilities ? formatCurrency(borrower?.liabilities) : '-'}</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant='body2' className={classes.labelText}>Net Worth</Typography>
            <Typography className={classes.valueText}>{borrower?.netWorth ? formatCurrency(borrower?.netWorth) : '-'}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
});

export default PersonalInfoTab;
