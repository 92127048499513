import { Layout } from '@roc/feature-app-core';
import { EmbeddedFrameBoundaries, NewBorrowerTrackRecord } from '@roc/ui';
import { BorrowerInfoCardCustomTheme } from './components/borrowerInfoCardCustomTheme';
import { BorrowerInfoCardInternalStore } from './stores/borrowerInfoCardInternalStore';


export const BorrowerTrackRecordComponent = (store: BorrowerInfoCardInternalStore, borrowerId: string, loanId: string) => {
  store.currentBorrower = parseInt(borrowerId);
  store.loanId = loanId;
  if (!borrowerId) {
    return (<></>);
  }
  let parsedLoanId;
  try {
    parsedLoanId = loanId ? parseInt(loanId) : null;
  } catch { }
  store.fetchBaseballCardData(parseInt(borrowerId), parsedLoanId);

  return (
    <Layout>
      <BorrowerInfoCardCustomTheme>
        <EmbeddedFrameBoundaries>
          <NewBorrowerTrackRecord store={store} />
        </EmbeddedFrameBoundaries>
      </BorrowerInfoCardCustomTheme>
    </Layout>
  );
}
