import { InputBaseComponentProps, TextField } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { FieldLabel, TextFieldProps, withTestDataAttribute, HtmlContainer } from '@roc/ui';
import {
  ContentState,
  convertFromHTML,
  convertToRaw,
  EditorState,
  Modifier,
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { useEffect, useState, useRef } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

// Default
const LINE_HEIGHT = '1.1876em';
const PARAGRAPH_MARGIN = '1em';

const calcEditorHeight = (rows: any) => {
  return `calc(${rows} * ${LINE_HEIGHT} + ${rows + 1} * ${PARAGRAPH_MARGIN})`;
};

const toolbar = {
  options: ['inline', 'blockType', 'list'],
  list: {
    inDropdown: false,
    options: ['unordered', 'ordered'],
  },
  blockType: {
    inDropdown: false,
    options: ['Blockquote', 'Code'],
  },
};

const useStyles = makeStyles<Theme, InputBaseComponentProps>(theme => ({
  wrapperClassName: {
    width: '100%',
    '& .rdw-editor-toolbar': {
      border: 0,
      padding: 0,
    },
    '& .rdw-option-wrapper': {
      padding: '14px 6px',
    },
  },
  toolbarClassName: {
    margin: 0,
  },
  editorClassName: props => ({
    minHeight: props.minRows ? calcEditorHeight(props.minRows) : undefined,
    maxHeight: props.maxRows ? calcEditorHeight(props.maxRows) : undefined,
  }),
}));

export type HtmlFieldProps = TextFieldProps & {
  standaloneLabel?: boolean;
  subLabel?: string;
  testId: string;
  tooltip?: string;
};

const convertToHtml = editorState =>
  draftToHtml(convertToRaw(editorState.getCurrentContent()));

const getEditorStateFromHTML = html => {
  const blocksFromHTML = convertFromHTML(html);
  const contentState = ContentState.createFromBlockArray(
    blocksFromHTML.contentBlocks,
    blocksFromHTML.entityMap
  );
  return EditorState.createWithContent(contentState);
};

const InputComponent = (props: InputBaseComponentProps) => {
  const classes = useStyles(props);
  const [currentValue, setCurrentValue] = useState('');
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  useEffect(() => {
    if (props.value != currentValue) {
      const html = props.value as string;
      const newEditorState = getEditorStateFromHTML(html);
      setEditorState(newEditorState);
      setCurrentValue(html);
    }
  }, [props.value]);

  const handleFiles = files => {
    //  uploadFiles(files);
  };

  const handlePastedText = () => {
    const currentContent = editorState.getCurrentContent();
    const currentSelection = editorState.getSelection();
    const newContent = Modifier.applyInlineStyle(
      currentContent,
      currentSelection,
      'background-color: inherit;'
    );
    setEditorState(
      EditorState.push(editorState, newContent, 'change-inline-style')
    );
  };

  if(props.disabled){
    return <HtmlContainer html={currentValue} />;
  }

  return (
    <Editor
      key="editor"
      toolbar={toolbar}
      handlePastedText={handlePastedText}
      handlePastedFiles={handleFiles}
      handleDroppedFiles={(data, files) => handleFiles(files)}
      editorState={editorState}
      wrapperClassName={classes.wrapperClassName}
      toolbarClassName={classes.toolbarClassName}
      editorClassName={classes.editorClassName}
      onEditorStateChange={editorState => {
        const html = convertToHtml(editorState);
        props.onChange(html);
        setEditorState(editorState);
        setCurrentValue(html);
      }}
      onBlur={props.onBlur}
    />
  );
};

export const HtmlField = withTestDataAttribute(
  ({
    standaloneLabel,
    subLabel,
    tooltip,
    ...muiComponentProps
  }: HtmlFieldProps) => {
    return (
      <>
        {standaloneLabel && muiComponentProps.label ? (
          <FieldLabel tooltip={tooltip}>
            {muiComponentProps.label}
            {muiComponentProps.required && ' *'}
          </FieldLabel>
        ) : null}
        {subLabel && (
          <FieldLabel style={{ fontSize: '.8rem', color: '#aaa' }}>
            {subLabel}
          </FieldLabel>
        )}
        <div style={{ cursor: 'text' }}>
          <TextField
            {...muiComponentProps}
            minRows={100}
            label={standaloneLabel ? undefined : muiComponentProps.label}
            value={muiComponentProps.value ?? ''}
            InputProps={{
              inputProps:{
                minRows: muiComponentProps.minRows,
                maxRows: muiComponentProps.maxRows
              },
              inputComponent: InputComponent,
            }}
          />
        </div>
      </>
    );
  }
);
