
import { StandardDialog } from '@roc/ui';
import { Button, FormControl, Grid, InputLabel, Select, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import { formatDate, usdAmount } from './utils';
import MatDataGrid from '../matDataGrid/matDataGrid';
import { useEffect, useMemo, useState } from 'react';


const useStyles = makeStyles(() =>
  createStyles({
    typography: {
      marginTop: 0,
    },
    dialogContent: {
      padding: 0,
    },
    buttonContainer: {
      marginTop: 32,
    },
  })
);

export const PublicTrackRecordDialog = observer(
  (props: { store, isOpen, handleClose }) => {
    const { store, isOpen, handleClose } = props;
    const classes = useStyles();

    const [entityFilter, setEntityFilter] = useState('');
    const [propertyAdressFilter, setPropertyAdressFilter] = useState('');


    const handleEntityFilterChange = event => {
      setEntityFilter(event.target.value);
    };

    const handlePropertyAdressFilterChange = (event) => {
      setPropertyAdressFilter(event.target.value);
    };

    const filteredTrackRecords = useMemo(() => {
      return store.publicTrackRecord?.filter(record => {
        const entityMatch =
          !entityFilter ||
          (record.entityName &&
            record.entityName.toLowerCase().includes(entityFilter.toLowerCase()));

        const propertyAddressMatch =
          !propertyAdressFilter ||
          (record.purchaseAddress &&
            record.purchaseAddress.toLowerCase().includes(propertyAdressFilter.toLowerCase()));

        return entityMatch && propertyAddressMatch;
      });
    }, [store.publicTrackRecord, entityFilter, propertyAdressFilter]);

    const handleSave = async () => {
      for (let property of store.selectedPublicTrackRecords) {
        property.purchaseDate = formatDate(property.purchaseDate);
        property.saleDate = formatDate(property.saleDate);

        const response = await store.savePropertyInformation(property) as any;
        property.verifiedTrackRecordsId = response.data.verifiedTrackRecordsId;
      }
      handleClose();
      store.publicTrackRecord = [];
      store.selectedPublicTrackRecords = [];
      store.publicTrackRecordsMatGridStore.selectedRows = [];
    }

    useEffect(() => {
      if (isOpen) {
        store.getPublicTrackRecords(store.currentBorrower);
      }
    }, [isOpen]);

    const trackRecordsHeaders = [
      'Entity Name',
      'Buyer Name',
      'Property Address',
      'Purchase Date',
      'Purchase Amount',
      'Rehab Budget',
      'Seller Name',
      'Sale Date',
      'Sale Price',
      'Exit Strategy'
    ];

    const trackRecordsRows = filteredTrackRecords?.reduce((rows, property) => {
      rows.push({
        id: property.temporaryId,
        object: property,
        type: 'property',
        cells: [
          property.entityName ? property.entityName : '-',
          property.soldTo ? property.soldTo : '-',
          property.fullAddress ? property.fullAddress : `${property.purchaseAddress}, ${property.purchaseCity}, ${property.purchaseState}, ${property.purchaseZipcode}`,
          property.purchaseDate ? property.purchaseDate : '-',
          property.purchasePrice
            ? usdAmount({ value: property.purchasePrice })
            : '$0.00',
          property.rehabBudget
            ? usdAmount({ value: property.rehabBudget })
            : '$0.00',
          property.purchasedFrom ? property.purchasedFrom : '-',
          property.saleDate ? property.saleDate : '-',
          property.salePrice ? usdAmount({ value: property.salePrice }) : '$0.00',
          property.exitStrategy ? property.exitStrategy : '-',
        ],
        expandableContent: (
          <Grid container>
            <Grid item xs={12} style={{ marginBottom: '3rem', display: 'flex', justifyContent: 'space-between' }}>


              <Grid >
                <Grid style={{ marginRight: '4rem', marginBottom: '4rem' }}>
                  <strong>Mortgage Amount: </strong>
                  <Grid style={{ display: 'flex', flexDirection: "column" }}>
                    {property.mortgageAmount
                      ? usdAmount({ value: property.mortgageAmount })
                      : '$0.00'}
                  </Grid>
                </Grid>
                <Grid style={{ marginRight: '4rem' }}>
                  <strong>Verification Source: </strong>
                  <Grid style={{ display: 'flex', flexDirection: "column" }}>
                    {property.verificationSource ?
                      property.verificationSource
                      : 'Not Selected'}
                  </Grid>
                </Grid>
              </Grid>
              <Grid>
                <Grid style={{ marginRight: '4rem', marginBottom: '4rem' }}>
                  <strong>Source Type: </strong>
                  <Grid style={{ display: 'flex', flexDirection: "column" }}>
                    {property.recordSourceType ? property.recordSourceType : 'None'}
                  </Grid>
                </Grid>
                <Grid style={{ marginRight: '4rem' }}>
                  <strong>Lender Name: </strong>
                  <Grid style={{ display: 'flex', flexDirection: "column" }}>
                    {property.lenderName ?
                      property.lenderName
                      : 'None'}
                  </Grid>
                </Grid>
              </Grid>
              <Grid>
                <Grid style={{ marginRight: '4rem' }}>
                  <strong>Property Type: </strong>
                  <Grid style={{ display: 'flex', flexDirection: "column" }}>
                    {property.propertyType ?
                      property.propertyType
                      : 'None'}
                  </Grid>
                </Grid>
              </Grid>

            </Grid>
          </Grid>
        ),
      });

      return rows;
    }, []);

    return (
      <StandardDialog
        open={isOpen}
        maxWidth={'xl'}
        handleClose={() => {
          handleClose();
          store.publicTrackRecord = [];
          store.selectedPublicTrackRecords = [];
          store.publicTrackRecordsMatGridStore.selectedRows = [];
        }}
        className={classes.dialogContent}
        dialogContent={
          <div className={classes.dialogContent}>
            <Grid>
              <Typography variant="h4">{'Public Track Records'}</Typography>
              <Grid container spacing={2} style={{ marginTop: '20px' }}>
                <Grid item xs={12}>
                  <Typography variant="body2">
                    This is the list of <strong>public track records</strong> associated with your current entities, select which ones you want to add.
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{ display: 'flex', alignItems: 'center', marginTop: '2rem' }}
                >
                  <strong>Filters:</strong>
                  <FormControl style={{ marginLeft: 20, width: 200 }}>
                    <TextField
                      variant="outlined"
                      label="Entity Name"
                      id="entityFilter"
                      value={entityFilter}
                      onChange={handleEntityFilterChange}
                    />
                  </FormControl>

                  <FormControl style={{ marginLeft: 20, width: 200 }}>

                    <TextField
                      variant="outlined"
                      label="Property Address"
                      id="propertyAdressFilter"
                      value={propertyAdressFilter}
                      onChange={handlePropertyAdressFilterChange}
                    />
                  </FormControl>

                </Grid>

                <MatDataGrid
                  store={store.publicTrackRecordsMatGridStore}
                  isExpandable={true}
                  enableSelection={true}
                  enablePagination={true}
                  onSelectedRowsChange={(rows) => { store.selectedPublicTrackRecords = rows.map((row) => row.object) }}
                  headers={trackRecordsHeaders}
                  rows={store.publicTrackRecord ? trackRecordsRows : []}
                />



              </Grid>
              <Grid item xs={12} className={classes.buttonContainer}>
                <Grid container justifyContent="flex-end" spacing={2}>
                  <Grid item xs={6} md={2}>
                    <Button
                      onClick={() => {
                        handleClose();
                        store.publicTrackRecord = [];
                        store.selectedPublicTrackRecords = [];
                        store.publicTrackRecordsMatGridStore.selectedRows = [];
                      }}
                      color="primary"
                      size="large"
                      variant="outlined"
                      fullWidth
                    >
                      CANCEL
                    </Button>
                  </Grid>
                  <Grid item xs={6} md={2}>


                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      fullWidth
                      onClick={handleSave}
                      disabled={store.selectedPublicTrackRecords.length === 0}
                    >
                      ADD
                    </Button>

                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        }
      />
    );
  }
);

export default PublicTrackRecordDialog;
