import React from 'react';
import {
  Card as MuiCard,
  CardContent,
  CardContentProps,
  CardHeader,
  CardHeaderProps,
  IconButton,
  IconButtonProps,
  PropTypes,
  TypographyProps,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { DeleteOutline, Visibility } from '@material-ui/icons';

export interface CardProps<
  TitleTypographyComponent extends React.ElementType = 'span'
> {
  title: React.ReactNode;
  value?: any;
  color?: 'primary' | 'secondary';
  onRemove?: (value: any) => void;
  onEdit?: (value: any) => void;
  onView?: (value: any) => void;
  children?: React.ReactNode;
  className?: string;
  titleTypographyProps?: TypographyProps<
    TitleTypographyComponent,
    { component?: TitleTypographyComponent }
  >;
  cardHeaderProps?: CardHeaderProps;
  cardContentProps?: CardContentProps;
  editButtonProps?: IconButtonProps;
  style?: any;
}

export const Card: React.FC<CardProps> = ({
  color,
  title,
  titleTypographyProps,
  value,
  onRemove,
  onEdit,
  onView,
  children,
  cardHeaderProps,
  cardContentProps,
  editButtonProps,
  ...rest
}) => {
  return (
    <MuiCard variant="outlined" {...rest}>
      <CardHeader
        title={title}
        titleTypographyProps={titleTypographyProps}
        action={
          <>
            {onEdit && (
              <IconButton onClick={() => onEdit(value)} {...editButtonProps}>
                <EditIcon color={color} />
              </IconButton>
            )}
            {onRemove && (
              <IconButton onClick={() => onRemove(value)}>
                <DeleteOutline color={color} />
              </IconButton>
            )}
            {onView && (
              <IconButton onClick={() => onView(value)}>
                <Visibility color={color} />
              </IconButton>
            )}
          </>
        }
        {...cardHeaderProps}
      />
      {children && <CardContent {...cardContentProps}>{children}</CardContent>}
    </MuiCard>
  );
};
