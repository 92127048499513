import { observer } from 'mobx-react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Address, Identification, NetworthInformation, PersonalDetails } from './borrowerDetailsVerificationHelper';
import { Grid, Typography } from '@material-ui/core';


const useStyles = makeStyles((theme: Theme) => {
  return {
    container: {
      display: 'flex',
      position: 'relative',
      maxWidth: 1300,
      margin: 'auto',
    }
  };
});

export const BorrowerDetailsVerificationForm = observer(({ isInternal, store }) => {
  const classes = useStyles();
  return (
    <Grid container spacing={2} className={classes.container}>
      <PersonalDetails store={store} isInternal={isInternal} />
      <Identification store={store} isInternal={isInternal} />
      <Grid item xs={12}>
        <Typography variant="h5">Address</Typography>
      </Grid>
      <Address store={store} isInternal={isInternal} />
      <NetworthInformation store={store} isInternal={isInternal} />
    </Grid>
  );
});