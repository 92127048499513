import { Grid } from '@material-ui/core';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { FabActionsButton, FabActionsMenuItem, ProgressBarComponent } from '@roc/ui';
import {
  Documents,
  useDocumentStore,
} from '@roc/feature-documents';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useBaseStore } from '@roc/feature-app-core';
import { useExtensionRequestStore } from '../../../../../feature-loans/src/loans/loanDetails/modules/loanRequests/hooks/useExtensionRequestStore';
import { useLoanStore } from '@roc/feature-loans';

export const ExtensionDetailsShared = observer(
  ({ loanId, extensionId }: { loanId: string; extensionId: string }) => {
    const { globalStore } = useBaseStore();
    const {
      extensionRequestStore
    } = useExtensionRequestStore();
    const { documentStore } = useDocumentStore();
    const { loanStore } = useLoanStore();

    useEffect(() => {
      extensionRequestStore.fetchExtensionById(extensionId);
      return () => {
        extensionRequestStore.resetExtensionByNumber();
      };
    }, []);

    useEffect(() => {
      if (extensionRequestStore.extensionByNumber) {
        documentStore.fetchExtensionDocuments(loanId, extensionId);
      }
    }, [extensionRequestStore.extensionByNumber]);

    if (!extensionRequestStore.extensionByNumber || !documentStore.extensionDocuments) {
      return <></>;
    }

    return (
      <>
        <Grid item xs={12}>
          <ProgressBarComponent
            progressArray={["Extension Initiated", "Insurance under review", "Insurance Review Completed",
              "Extension In Process", "Extension Completed"]}
            active={extensionRequestStore.extensionByNumber.status}
          />
        </Grid>
        <Grid item container spacing={2}>
          {documentStore.documentSections.map((element, index) => {
            if (element.loanProcess === "Extensions") {
              return (
                <Grid key={element.sectionName} item xs={12}>
                  <Documents
                    section={element.sectionName}
                    loanProcess={"Extensions"}
                    loanId={loanId}
                    extensionNumber={extensionId}
                    loanStore={loanStore}
                  />
                </Grid>
              );
            }
          })}
          {extensionRequestStore.extensionByNumber.status === 'Insurance under review' && globalStore.userFeatures.isInsuranceReviewerAlbertRisk &&
            <FabActionsButton>
              <FabActionsMenuItem
                icon={<CheckCircleIcon />}
                label={'Approve Insurance'}
                onClick={() => extensionRequestStore.approveInsurance(documentStore.insuranceExtensionDocumentId)}
              />
            </FabActionsButton>
          }
        </Grid>
      </>
    );
  }
);

export default ExtensionDetailsShared;
