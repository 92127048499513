import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { CheckCircle } from '@material-ui/icons';
import { formatCurrency, formatPercentage } from '@roc/ui/utils';
import { observer } from 'mobx-react';
import { CustomCard } from './customCard';

export const useLoanDetailsInfoItemStyles = makeStyles((theme: Theme) => ({
  loanDetailsCardItem: {
    fontSize: '20px',
    fontWeight: 'bold',
  },
  loanDetailsCardLabel: {
    fontSize: '14px',
    color: '#555',
  }
}));

export const LoanDetailsInfoItem = observer(({ value, secondValue, secondValueStyle, valueComponent, label, labelComponent, valueStyle, labelStyle, secondaryLabel, secondaryLabelComponent, secondaryLabelStyle, boxStyle, thirdValue, thirdLabel, thirdValueStyle }: {
  value?: any;
  secondValue?: any;
  secondValueStyle?: any;
  valueComponent?: any;
  valueStyle?: any;
  label?: any;
  labelComponent?: any;
  labelStyle?: any;
  secondaryLabel?: any;
  secondaryLabelComponent?: any;
  secondaryLabelStyle?: any;
  boxStyle?: any;
  thirdValue?: any;
  thirdLabel?: any;
  thirdValueStyle?: any;
}) => {
  const classes = useLoanDetailsInfoItemStyles();

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems={'center'}
        py={1}
        pr={1}
        style={boxStyle}
      >
        <Box width={'100%'}>
          {
            valueComponent ?
              valueComponent :
              secondValue ?
                <Box display="flex" flexDirection="row" height={'100%'} alignItems={'center'}>
                  <Box>
                    <Typography className={classes.loanDetailsCardItem} style={valueStyle}>
                      {value ? value : '-'}
                    </Typography>
                  </Box>
                  <Box mx={2} mt={0.5} mb={1} alignSelf={'normal'} borderLeft={'2px solid #bbb'}></Box>
                  <Box>
                    <Typography style={secondValueStyle}>
                      {secondValue}
                    </Typography>
                  </Box>
                </Box>
                :
                <Typography className={classes.loanDetailsCardItem} style={valueStyle}>
                  {value ? value : '-'}
                </Typography>
          }
          {
            labelComponent ?
              labelComponent :
              <Typography className={classes.loanDetailsCardLabel} style={labelStyle}>
                {label}
              </Typography>
          }
          {
            secondaryLabelComponent ?
              secondaryLabelComponent :
              <Typography className={classes.loanDetailsCardLabel} style={labelStyle}>
                {secondaryLabel}
              </Typography>
          }
          {thirdValue &&
            <>
              <Typography className={classes.loanDetailsCardItem} style={thirdValueStyle ? thirdValueStyle : valueStyle}>
                {thirdValue}
              </Typography>
              {thirdLabel && <Typography className={classes.loanDetailsCardLabel} style={labelStyle}>
                {thirdLabel}
              </Typography>}
            </>

          }
        </Box>
      </Box>
    </>
  );
});

export const LoanDetailsCustomCard = observer(
  ({ cardContentProps = {}, contentStyle = {}, children }) => {
    return (
      <CustomCard title={undefined} cardContentProps={cardContentProps}>
        <Box px={1} pb={.25} style={contentStyle}>
          <Grid container spacing={0}>
            {children}
          </Grid>
        </Box>
      </CustomCard>
    );
  }
);
