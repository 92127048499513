import { fix_flip_2_icon } from '@roc/feature-app-core/assets';
import { ground_up_icon } from '@roc/feature-app-core/assets';
import { mf_1_icon, mf_2_icon, mf_3_icon } from '@roc/feature-app-core/assets';
import { sfr_2_icon } from '@roc/feature-app-core/assets';
import {
  rental_portfolios_1_icon,
  rental_portfolios_2_icon,
} from '@roc/feature-app-core/assets';

export const getLoanTypeIcon = (loanSubType: string) => {
  if (loanSubType == 'Fix and Flip (1-4)') {
    return fix_flip_2_icon;
  } else if (loanSubType == 'Groundup (1-4)') {
    return ground_up_icon;
  } else if (loanSubType == 'Multifamily Bridge (5+)') {
    return mf_1_icon;
  } else if (loanSubType == 'Single Property Rental') {
    return sfr_2_icon;
  } else if (loanSubType == 'Single Property Multifamily Term') {
    return mf_2_icon;
  } else if (loanSubType == 'Rental Portfolios') {
    return rental_portfolios_1_icon;
  } else if (loanSubType == 'Multifamily Term Portfolios') {
    return rental_portfolios_2_icon;
  } else {
    return null;
  }
};
