import {
  Box,
  Button,
  CircularProgress,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { Close, Delete, FileCopyOutlined } from '@material-ui/icons';
import { downloadDocument, formatBytes, isElectronApp } from '@roc/feature-utils';
import { IconButton, PDFPreview, StandardDialog } from '@roc/ui';
import { Media } from '@twilio/conversations';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { ImagePreview } from './imagePreview';

interface Props {
  media: Media;
}

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
    backgroundColor: theme.palette.grey[50],
    border: '1px solid',
    borderColor: theme.palette.grey[300],
    borderRadius: '4px',
    cursor: 'pointer',
    position: 'relative',
    margin: theme.spacing(1, 0),
  },
  fileInfo: {
    flexBasis: '500px',
  },
  fileName: {
    color: theme.palette.text.primary,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    minWidth: '100px',
    maxWidth: '200px',
  },
  fileSize: {
    color: theme.palette.grey[500],
    fontSize: theme.typography.fontSize * 0.8,
  },
  fileIcon: {
    color: theme.palette.primary.light,
    fontSize: '32px',
  },
}));

const isPdf = (media: Media) => {
  return (
    media.contentType === 'application/pdf' || media.filename.endsWith('.pdf')
  );
};

const isImage = (media: Media) => {
  return (
    media.contentType.startsWith('image') ||
    media.filename.endsWith('.png') ||
    media.filename.endsWith('.jpg') ||
    media.filename.endsWith('.jpeg')
  );
};



export const AttachmentPreview = observer(({ media }: Props) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [showPdf, setShowPdf] = useState(false);
  const [showImage, setShowImage] = useState(false);
  const [downloadData, setDownloadData] = useState<any>();
  const [fileName, setFileName] = useState('');
  const [headers, setHeaders] = useState<Headers>();

  const openPdfPopup = async () => {
    setLoading(true);
    const url = await media.getContentTemporaryUrl();
    const response = await fetch(url);
    const bytes = await response.arrayBuffer();
    setDownloadData(bytes);
    setFileName(media.filename);
    setHeaders(response.headers);
    setShowPdf(true);
    setLoading(false);
  };

  const openImagePopup = async () => {
    setLoading(true);
    const url = await media.getContentTemporaryUrl();
    const response = await fetch(url);
    const bytes = await response.arrayBuffer();
    setDownloadData(bytes);
    setFileName(media.filename);
    setHeaders(response.headers);
    setShowImage(true);
    setLoading(false);
  };

  const download = async () => {
    setLoading(true);
    const url = await media.getContentTemporaryUrl();
    const response = await fetch(url);
    const bytes = await response.arrayBuffer();
    setLoading(false);
    downloadDocument(bytes, response.headers, 'download', media.filename);
  };

  const resetState = () => {
    setShowPdf(false)
    setShowImage(false);
    setDownloadData(undefined);
    setFileName('');
    setHeaders(undefined);
  }

  const onAttachmentClick = () => {
    if (isElectronApp()) {
      if(isPdf(media)){
        openPdfPopup()
      } else if(isImage(media)) {
        openImagePopup();
      } else {
        download();
      }
    } else {
      download();
    }
  }

  return (
    <>
      <Box className={classes.container} onClick={onAttachmentClick}>
        <Box mr={1}>
          {loading ? (
            <CircularProgress size={32} />
          ) : (
            <FileCopyOutlined className={classes.fileIcon} />
          )}
        </Box>
        <Box className={classes.fileInfo}>
          <Typography className={classes.fileName}>{media.filename}</Typography>
          <Typography className={classes.fileSize}>
            {formatBytes(media.size)}
          </Typography>
        </Box>
      </Box>
      <PDFPreview
        open={showPdf}
        title={''}
        data={downloadData}
        headers={headers}
        fileName={fileName}
        onClose={() => resetState()}
      />
      <ImagePreview
        open={showImage}
        title={''}
        data={downloadData}
        headers={headers}
        fileName={fileName}
        onClose={() => resetState()}
      />
    </>
  );
});
