import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import { Business, Info, Money } from '@material-ui/icons';
import { Layout, StepsContainer } from '@roc/client-portal-shared/components';
import { useStore } from '@roc/client-portal-shared/hooks';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useParams } from 'react-router';
import BorrowersAndLoanTerms from './fixFlipSizerDetails/borrowersAndLoanTerms';
import PropertyAndProject from './fixFlipSizerDetails/propertyAndProject';
import Summary from './fixFlipSizerDetails/summary';

const useStyles = makeStyles(theme =>
  createStyles({
    paper: {
      padding: theme.spacing(4),
    },
  })
);

const FixFlipSizerDetails = observer(() => {
  const { sizerId } = useParams<{ sizerId: string }>();
  const { fixFlipSizerStore } = useStore();
  const classes = useStyles();
  const { detailsStore } = fixFlipSizerStore;
  const isEditing = Boolean(sizerId);

  const steps = [
    {
      title: 'Property & Project',
      icon: <Business color="primary" fontSize="large" />,
      onBack: () => detailsStore.goPrevStep(),
      onNext: () => detailsStore.goNextStep(),
      allowBack: false,
      allowNext: true,
    },
    {
      title: 'Borrowers & Loan Terms',
      icon: <Money color="primary" fontSize="large" />,
      onBack: () => detailsStore.goPrevStep(),
      onNext: () => detailsStore.goNextStep(),
      allowBack: true,
      allowNext: true,
    },
    {
      title: 'Summary',
      icon: <Info color="primary" fontSize="large" />,
      onBack: () => detailsStore.goPrevStep(),
      onNext: () => detailsStore.saveSizer(),
      allowBack: false,
      allowNext: false,
    },
  ];

  useEffect(() => {
    detailsStore.initForm(sizerId);
  }, []);

  return (
    <Layout title="Fix & Flip Sizer" maxWidth="md">
      <Paper className={classes.paper}>
        <StepsContainer
          disableNavigation={false}
          activeStep={detailsStore.activeStep}
          handleStep={step => detailsStore.moveToStep(step)}
          steps={steps}
        >
          <PropertyAndProject />
          <BorrowersAndLoanTerms />
          <Summary />
        </StepsContainer>
      </Paper>
    </Layout>
  );
});

export default FixFlipSizerDetails;
