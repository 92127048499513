import React, { useState, useEffect } from 'react';
import { GoogleMap } from '@roc/ui';
import { geocode } from '@roc/ui/utils';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/';

const useStyles = makeStyles(theme => ({
  mapStyles: {
    position: `relative`,
    width: `100%`,
    height: `200px`,
  },
}));

interface PropertyMapProps {
  latitude: number;
  longitude: number;
  address: string;
}

export const PropertyMap: React.FC<PropertyMapProps> = ({
  latitude,
  longitude,
  address,
}) => {
  const classes = useStyles();
  const [coordinates, setCoordinates] = useState({ lat: 0, lng: 0 });

  useEffect(() => {
    if (latitude != null && longitude != null) {
      setCoordinates({
        lat: latitude,
        lng: longitude,
      });
    } else {
      geocode(address).then(response => setCoordinates(response));
    }
  }, []);

  return (
    <Box className={classes.mapStyles}>
      <GoogleMap
        mapStyles={classes.mapStyles}
        latitude={coordinates.lat}
        longitude={coordinates.lng}
      />
    </Box>
  );
};
