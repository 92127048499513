import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { StepperComponent, StepObj } from './../../components';
import { Button, Tooltip } from '@roc/ui';
import { useUserStore } from '@roc/feature-app-core';

const repriceLoanTooltip = 'To change any of your loan inputs, you can return to the pricing screens';

export interface StepsContainerProps {
  title?: string;
  activeStep: number;
  disableNavigation?: boolean;
  handleStep?: (value: number) => void;
  steps: Array<StepObj>;
  children: Array<JSX.Element>;
  stepperClassName?: string;
  scrollToTopOnStepChange?: boolean;
}

const useStyle = makeStyles(theme =>
  createStyles({
    stepBody: {
      minHeight: 450,
    },
    stepContainer: {
      height: '100%',
      padding: theme.spacing(4),
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(2),
      }
    }
  }));

export const StepsContainer = ({
  title,
  disableNavigation,
  activeStep,
  handleStep,
  steps,
  children,
  stepperClassName,
  scrollToTopOnStepChange,
}: StepsContainerProps) => {
  const classes = useStyle();
  const [showBack, setShowBack] = useState(false);
  const [showNext, setShowNext] = useState(false);
  const [showSave, setShowSave] = useState(false);
  const [showReprice, setShowReprice] = useState(false);
  const [showReject, setShowReject] = useState(false);
  const { userStore } = useUserStore();
  const { userInformation } = userStore;

  useEffect(() => {
    setShowBack(
      steps[activeStep].allowBack && Boolean(steps[activeStep].onBack)
    );
    setShowNext(
      steps[activeStep].allowNext && Boolean(steps[activeStep].onNext)
    );
    setShowSave(
      steps[activeStep].allowSave && Boolean(steps[activeStep].onSave)
    );
    setShowReprice(
      steps[activeStep].allowReprice && Boolean(steps[activeStep].onReprice)
    );
    setShowReject(
      steps[activeStep].allowReject && Boolean(steps[activeStep].onReject)
    );
  }, [activeStep, steps]);

  const getSubmitButtonLabel = (
    steps[activeStep].nextButtonText ?
      steps[activeStep].nextButtonText
      : (activeStep == children.length - 1 && userStore.allowLoanPricing ? 'Submit'
        : activeStep == children.length - 1 && !userStore.allowLoanPricing ? 'Submit Request'
          : 'Next')
  )

  const getSaveButtonLabel = (
    steps[activeStep].saveButtonText ?
      steps[activeStep].saveButtonText
      : (showReject ? 'Save Request' : 'Save as Draft')
  )

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          {title && (
            <Grid item xs={12} md={6}>
              <Typography variant="h3" gutterBottom>
                {title}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12} md={title ? 6 : 12}>
            <StepperComponent
              activeStep={activeStep}
              disableNavigation={disableNavigation}
              steps={steps}
              handleStep={handleStep}
              className={stepperClassName}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.stepBody}>
        <Box className={classes.stepContainer}>
          {children[activeStep]}
        </Box>
      </Grid>
      {(showBack || showNext) && (
        <Grid item xs={12}>
          <Box p={4}>
            <Grid container direction="row" justifyContent="center" spacing={2}>
              {showBack && (
                <Grid item xs={6} md={3}>
                  <Button
                    variant="outlined"
                    color="primary"
                    fullWidth
                    onClick={() => {
                      steps[activeStep].onBack();
                      if (scrollToTopOnStepChange) {
                        (window as any).scrollTo({
                          behavior: 'instant',
                          top: 0
                        });
                      }
                    }}
                    testId="previous"
                  >
                    Back
                  </Button>
                </Grid>
              )}
              {showReprice && (
                <Tooltip title={repriceLoanTooltip} placement="top" arrow>
                  <Grid item xs={6} md={3}>
                    <Button
                      variant="outlined"
                      color="primary"
                      fullWidth
                      onClick={() => {
                        steps[activeStep].onReprice();
                      }}
                      testId="reprice"
                    >
                      Reprice Loan
                    </Button>
                  </Grid>
                </Tooltip>
              )}
              {showReject && (
                <Grid item xs={6} md={3}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={() => {
                      steps[activeStep].onReject();
                    }}
                    testId="rejectRequest"
                  >
                    Reject Request
                  </Button>
                </Grid>
              )}
              {showSave && (
                <Grid item xs={6} md={3}>
                  <Button
                    variant="outlined"
                    color="primary"
                    fullWidth
                    onClick={() => {
                      steps[activeStep].onSave();
                    }}
                    testId="saveAsDraft"
                  >
                    {getSaveButtonLabel}
                  </Button>
                </Grid>
              )}
              {showNext && (
                <Grid item xs={6} md={3}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={() => {
                      steps[activeStep].onNext();
                      if (scrollToTopOnStepChange) {
                        (window as any).scrollTo({
                          behavior: 'instant',
                          top: 0
                        });
                      }
                    }}
                    testId={steps[activeStep].nextButtonText ? steps[activeStep].nextButtonText.toLowerCase() : (children.length - 1 ? 'submit' : 'next')}
                    disabled={steps[activeStep]?.disableNextButton}
                  >
                    {getSubmitButtonLabel}
                  </Button>
                </Grid>
              )}
            </Grid>
          </Box>
        </Grid>
      )}
    </Grid>
  );
};
