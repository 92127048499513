import { observer } from 'mobx-react';
import { DataGrid as Grid, GridColumnProps } from '@roc/ui';
import { formatCurrency } from '@roc/ui/utils';
import { useBorrowerLoansStore } from '../../../hooks/useBorrowerLoansStore';

const noOpComparator = () => 0;

const usdAmount: any = params => {
  const value = params.value ?? 0;
  return formatCurrency(Number(value));
};

const decimal: any = params => params.value && Number(params.value).toFixed(2);

export const columns: GridColumnProps[] = [
  {
    field: 'addressLine1',
    headerName: 'Address',
    minWidth: 300,
    comparator: noOpComparator,
  },
  {
    field: 'units',
    headerName: 'Unit #',
    minWidth: 100,
    comparator: noOpComparator,
  },
  {
    field: 'city',
    headerName: 'City',
    minWidth: 100,
    comparator: noOpComparator,
  },
  {
    field: 'state',
    headerName: 'State',
    minWidth: 100,
    comparator: noOpComparator,
  },
  {
    field: 'zipCode',
    headerName: 'Zip',
    minWidth: 100,
    comparator: noOpComparator,
  },
  {
    field: 'propertyType',
    headerName: 'Property Type',
    minWidth: 200,
    comparator: noOpComparator,
  },
  {
    field: 'annualGrossRent',
    headerName: 'Annual Gross Rent',
    minWidth: 200,
    valueFormatter: usdAmount,
    comparator: noOpComparator,
  },
  {
    field: 'annualPITIA',
    headerName: 'Annual PITIA',
    minWidth: 200,
    valueFormatter: usdAmount,
    comparator: noOpComparator,
  },
  {
    field: 'annualNOI',
    headerName: 'Annual NOI',
    minWidth: 200,
    valueFormatter: usdAmount,
    comparator: noOpComparator,
  },
  {
    field: 'annualNCF',
    headerName: 'Annual NCF',
    minWidth: 200,
    valueFormatter: usdAmount,
    comparator: noOpComparator,
  },
  {
    field: 'annualDebtService',
    headerName: 'Annual Debt Service',
    minWidth: 200,
    valueFormatter: usdAmount,
    comparator: noOpComparator,
  },
  {
    field: 'netDSCR',
    headerName: 'Net DSCR',
    minWidth: 100,
    valueFormatter: decimal,
    comparator: noOpComparator,
  },
  {
    field: 'propertyValuation',
    headerName: 'Most Recent Valuation',
    minWidth: 200,
    valueFormatter: usdAmount,
    comparator: noOpComparator,
  },
  {
    field: 'propertyLoanAmount',
    headerName: 'Property Loan Amount',
    minWidth: 200,
    valueFormatter: usdAmount,
    comparator: noOpComparator,
  },
  {
    field: 'floodZone',
    headerName: 'Flood Zone',
    minWidth: 150,
    comparator: noOpComparator,
  },
  {
    field: 'releaseAmountTerm',
    headerName: 'Minimum release',
    minWidth: 200,
    valueFormatter: usdAmount,
    comparator: noOpComparator,
  },
];

export const BorrowerTermPropertiesGrid = observer(() => {
  const { borrowerLoansStore } = useBorrowerLoansStore();
  const propertiesGridStore = borrowerLoansStore.propertiesGridStore;

  const onSortChanged = params => {
    const sortModel = params.api.getSortModel();
    propertiesGridStore.setSortModel(sortModel);
  };

  return (
    <Grid
      columns={columns}
      rows={propertiesGridStore.gridData.data.rows}
      onSortChanged={onSortChanged}
      isLoading={propertiesGridStore.isLoading}
      sortModel={propertiesGridStore.sortModel}
      domLayout="autoHeight"
      paginationData={propertiesGridStore.gridData.meta}
      setPageNumber={propertiesGridStore.setPageNumber}
    />
  );
});
