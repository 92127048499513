import { action, flow, makeObservable, observable } from 'mobx';
import { GlobalStore, ApiResponse } from '@roc/feature-app-core';
import { LoanForeclosure } from '../types/loanForeclosure';
import { CounterPartyExpense } from '../types/counterPartyExpense';
import { LoanForeclosureService } from '../services/loanForeclosureService';
import { ForeclosureInvoiceFormStore } from './foreclosureInvoiceFormStore';
import { ForeclosureDetailsFormStore } from './foreclosureDetailsFormStore';
import { LoanDqStageData } from '../types/loanDqStageData';
import { FileUpload } from '@roc/ui';

export class ForeclosureRequestStore {
  globalStore: GlobalStore;
  currentForeclosure: LoanForeclosure = null;
  listForeclosureStages: LoanDqStageData[] = [];
  invoiceList: CounterPartyExpense[] = [];
  foreclosureInvoiceFormStore: ForeclosureInvoiceFormStore;
  foreclosureDetailsFormStore: ForeclosureDetailsFormStore;
  loanForeclosureService: LoanForeclosureService;
  foreclosureSummary: any;

  constructor(globalStore: GlobalStore) {
    this.globalStore = globalStore;
    this.loanForeclosureService = new LoanForeclosureService();
    this.foreclosureInvoiceFormStore = new ForeclosureInvoiceFormStore(globalStore, this.loanForeclosureService);
    this.foreclosureDetailsFormStore = new ForeclosureDetailsFormStore(globalStore);
    makeObservable(this, {
      currentForeclosure: observable,
      invoiceList: observable,
      foreclosureSummary: observable,
      getActiveForeclosureDataByLoanId: flow,
      getInvoicesByLoanId: flow,
      getSummary: flow,
      initForeclosureDetailsForm: action,
      requestLoanBreakdown: flow,
      updateForeclosureDetails: flow,
      saveForeclosureStages: flow,
      listForeclosureStages: observable,
      uploadMilestoneFile: flow,
    });
  }

  *getInvoicesByLoanId(loanId: string, typeOfFee: string) {
    try {
      const response: ApiResponse = yield this.loanForeclosureService.getInvoicesByLoanId(loanId, typeOfFee);
      this.invoiceList = response.data;
    } catch (err) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while getting invoice information.',
      });
    }
  };

  *getSummary(loanId: string) {
    try {
      const response: ApiResponse = yield this.loanForeclosureService.getSummary(loanId);
      console.log(response.data);
      this.foreclosureSummary = response.data;
    } catch (err) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while getting loan foreclosure summary.',
      });
    }
  };

  *getActiveForeclosureDataByLoanId(loanId: string) {
    try {
      const response: ApiResponse = yield this.loanForeclosureService.getActiveForeclosureByLoanId(loanId);
      const data = response?.data;
      this.currentForeclosure = data || null;
      this.listForeclosureStages = data?.loanDqStagesList || [];
    } catch (err) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while getting foreclosure information.',
      });
    }
  };

  *saveForeclosureStages(loanId: string, stages: LoanDqStageData) {
    try {
      yield this.loanForeclosureService.saveForeclosureStages(loanId, stages);
      yield this.getActiveForeclosureDataByLoanId(loanId);
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Foreclosure Stages were updated successfully.',
      });
    } catch (err) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while updating Foreclosure Stages information.',
      });
    }
  };

  *requestLoanBreakdown(formattedDate: string) {
    try {
      yield this.loanForeclosureService.requestForeclosureBreakdown(
        this.currentForeclosure.loanId,
        formattedDate
      );

      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Loan breakdown has been successfully requested.',
      });
    } catch (err) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while requesting loan foreclosure breakdown.',
      });
    }
  };

  initForeclosureDetailsForm() {
    const settlementDate = this.currentForeclosure?.settlementDate ? this.currentForeclosure.settlementDate : null;
    const foreclosureDetailsForm = {
      settlementDate: settlementDate != null ? new Date(settlementDate) : null
    }
    this.foreclosureDetailsFormStore.loadForm(foreclosureDetailsForm);
  };

  *updateForeclosureDetails() {
    try {
      const { settlementDate } = this.foreclosureDetailsFormStore.form.fields;
      yield this.loanForeclosureService.updateActiveForeclosure({
        loanId: this.currentForeclosure.loanId,
        loanForeclosureId: this.currentForeclosure.loanForeclosureId,
        settlementDate: settlementDate.value
      });
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Data was updated successfully.',
      });
      this.getActiveForeclosureDataByLoanId(this.currentForeclosure.loanId.toString());
    } catch (error) {
      console.log(error);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while updating data.',
      });
    }
  };

  *uploadMilestoneFile(loanId: string, stageName: string, fileUpload: FileUpload,) {
    try {
      const file = fileUpload.file;
      const blob = file.slice(0, file.size, file.type);
      const newFile = new File([blob], fileUpload.name, { type: file.type });
      yield this.loanForeclosureService.uploadMilestoneFile(loanId, stageName, newFile);
      yield this.getActiveForeclosureDataByLoanId(loanId);
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'File uploaded successfully.',
      });
    } catch (err) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while uploading Foreclosure Stage file.',
      });
    }
  };
}

export default ForeclosureRequestStore;