import { makeObservable, override } from 'mobx';
import { GlobalStore } from '@roc/feature-app-core';
import BackgroundReviewFormBaseStore from './backgroundReviewFormBaseStore';

export class BackgroundReviewFormStore extends BackgroundReviewFormBaseStore {

  constructor(globalStore: GlobalStore) {
    super(globalStore);
    makeObservable(this, {

    });
  }
}