import { Box, Divider, Grid, Typography } from '@material-ui/core';
import { CloudDownload } from '@material-ui/icons';
import { Button, IconButton, FileUpload, TextField, FileUploadArea } from '@roc/ui';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useResolutionStore } from 'libs/feature-borrower-review/src/hooks/useResolutionStore';
import { useBorrowerTaskStore } from '../../hooks/useBorrowerTaskStore';

const useStyles = makeStyles(() => ({
  description: {
    whiteSpace: 'pre'
  }
}))

export const ResolutionTaskItemForm = observer(
  ({ task, internal = false, store, onClose }) => {
    const classes = useStyles();
    const { resolution } = store;
    const { borrowerTaskStore } = useBorrowerTaskStore();
    const [uploads, setUploads] = useState<FileUpload[]>([]);

    useEffect(() => {
      if (task?.taskId) {
        borrowerTaskStore.getTaskByTaskId(task?.taskId);
      }
    }, [task]);

    const onSave = async () => {
      if (uploads[0]) {
        await borrowerTaskStore.uploadTaskDocument(uploads[0]);
      }
      if (borrowerTaskStore.task?.comment) {
        await borrowerTaskStore.syncTaskResolution(task?.taskId);
      }
      if (onClose) {
        onClose();
      }
    };
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={10}>
          <Typography
            component="span"
            style={{ display: 'inline-block', padding: '10px 0px' }}
          >
            <Typography component="span" color="textSecondary">
              {/* Item:{' '} */}
            </Typography>
            <strong>
              {task?.taskName || ''}
            </strong>
          </Typography>
        </Grid>
        <Grid item xs={2}>
          {store.canDownloadFile && (
            <Box display="flex" justifyContent="flex-end">
              <IconButton
                type="submit"
                color="primary"
                testId="save"
                onClick={() => {
                  // store.downloadDocument()
                }}
              >
                <CloudDownload />
              </IconButton>
            </Box>
          )}
        </Grid>
        <Grid item xs={12}>
          <Typography
            component="span"
            style={{ display: 'inline-block', padding: '10px 0px' }}
          >
            <Typography component="span" color="textSecondary">
              Description:{' '}
            </Typography>
            <strong className={classes.description}>{task?.taskDescription}</strong>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            type="text-area"
            variant="outlined"
            label="Item Explanation"
            value={borrowerTaskStore.task?.comment || ''}
            onChange={e => borrowerTaskStore.setTaskComment(e.target.value)}
            fullWidth
            multiline={true}
            minRows={4}
            margin="normal"
            testId="shareUrl"
          />
        </Grid>
        <Grid item xs={12}>
          <FileUploadArea
            items={uploads}
            setItems={(items) => setUploads(items)}
            multiple={false} />
        </Grid>
        <Grid item xs={12}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            testId="save"
            fullWidth
            onClick={onSave}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    );
  }
);
