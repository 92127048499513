import { Service } from '@roc/feature-app-core';

const url = {
  QUALIFY_BORROWER: '/api/v1/validation/sendBorrowerPrequalification',
  CREATE_TASK: '/api/v1/tasks/createTasks',
  UPLOAD_TASK_FILE: '/api/v1/tasks/uploadTaskFile',
};

export class QualifyBorrowerService extends Service {
  qualifyBorrower(body: any) {
    return this.post(url.QUALIFY_BORROWER, body);
  }

  createTask(body: any) {
    return this.post(url.CREATE_TASK, body);
  }

  uploadTaskFile(body: any) {
    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }
    return this.post(url.UPLOAD_TASK_FILE, body, config);
  }
}
