import { BackgroundReviewForm, useBackgroundReviewStore } from "@roc/feature-borrower-review";
import { observer } from "mobx-react"

export const BackgroundInfoTab = observer(({ borrower }) => {
  const { backgroundReviewStore } = useBackgroundReviewStore();
  const { backgroundReviewFormStore, backgroundItemStore } = backgroundReviewStore;

  if (!borrower?.borrowerId) {
    return null;
  }

  backgroundItemStore.currentBorrower = borrower.borrowerId;
  backgroundItemStore.isInternal = false;
  backgroundItemStore.initializeService();

  return (
    <BackgroundReviewForm
      showEmptySections={false}
      showBorrowerInformation={false}
      showBorrowerExperience={false}
      showResolutions={false}
      showButtons={false}
      store={backgroundItemStore} />
  )
})