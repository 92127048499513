import { action, flow, makeObservable, observable, computed } from 'mobx';
import { ApiResponse, GridStore } from '@roc/feature-app-core';
import { GlobalStore } from '../../../stores';
import { CompanyProfileService } from '../services/CompanyProfileService';
import {
  //FixAndFlipPricerLink,
  GroupEmailSubscription,
  LenderFees,
  LenderSubscriptionDetails,
  MailingListSubscription,
  UserNotificationInfo,
  NewLender,
} from '../types/companyProfileTypes';
import { GENERIC_ERROR_MESSAGE, getPageFromObject } from '@roc/feature-utils';

const MAILING_LIST_MAX_LENGTH = 255;
export abstract class BaseLenderStore {
  public globalStore: GlobalStore;
  companyProfileService: CompanyProfileService;
  public gridStore: GridStore;
  public lenderWithNoRmGrid: GridStore;

  public salesForceLender: NewLender;
  relationshipManagers = [];
  internalUser = null;
  portal = null;
  isDecisionCommittee = false;
  isLimitedRelationshipManager = false;
  loanVolumeStats = {}

  constructor(globalStore) {
    this.globalStore = globalStore;
    this.companyProfileService = new CompanyProfileService();
    this.gridStore = new GridStore(() => this.getAllLenders());
    this.lenderWithNoRmGrid = new GridStore(() => this.getAllLendersWithNoRm())
    makeObservable(this, {
      salesForceLender: observable,
      relationshipManagers: observable,
      getMailingLisGridPage: action,
      getFeesFromSalesforceLender: action,
      getUpdatedMalingLists: action,
      getSubscriptionDetails: action,
      getRelationshipManagersUsers: flow,
      fetchSalesforceLender: flow,
      updateLenderFields: flow,
      updateMailSubscriptions: flow,
      updateSalesforceLenderFees: flow,
      getBankInformationsForLender: action,
      checkIsInternalDecisionCommitteeOrLead: action,
      isDuplicateLenderAlias: flow,
      portal: observable,
      internalUser: observable,
      isDecisionCommittee: observable,
      requiredDocsUploaded: action,
      fetchLenderById: flow,
      updateCommissions: flow,
      fetchLoanVolumeStats: flow,
      loanVolumeStats: observable,
      updateTamariskPreferences: flow,
      updateDesktopAppraisalPreferences: flow,
      addLenderBrokerRelation: flow,
      turnBrokerIntoTPO: flow
    });
  }

  abstract fetchSalesforceLender();
  abstract fetchLenderById(id);
  abstract updateLenderFields(fields);
  abstract updateSalesforceLenderFees(fees: LenderFees);
  abstract getAllLenders();
  abstract getAllLendersWithNoRm();
  abstract getRelationshipManagersUsers();
  abstract getBankInformationsForLender();
  abstract checkIsInternalDecisionCommitteeOrLead();
  abstract requiredDocsUploaded(lenderId);
  abstract updateCommissions(lenderId);
  abstract fetchLoanVolumeStats(id: number, type: string);
  abstract updateTamariskPreferences(preferences: any): Generator<any, boolean, any>;
  abstract updateDesktopAppraisalPreferences(desktopAppraisalPreference: string, lenderId: number);

  *isDuplicateLenderAlias(lenderId: number, lenderName: string) {
    const response: ApiResponse = yield this.companyProfileService.isDuplicateLenderAlias(lenderId, lenderName);
    return response;
  }

  getMailingLisGridPage(gridMeta) {
    const mailingLists = this.getMailingListsFromSalesforceLender();
    const data = getPageFromObject(mailingLists, gridMeta);
    return new ApiResponse({ data }, null);
  }

  private getMailingListsFromSalesforceLender() {
    const lender = this.salesForceLender;
    const mailsMap = {};
    const mapMailingList = (list, property) => {
      if (list != null) {
        list.split(',').map(mail => {
          if (!mailsMap[mail]) {
            mailsMap[mail] = {
              email: mail,
              loanStatus: false,
              dailyReport: false,
              extensions: false,
              wireConfirm: false,
            };
          }
          mailsMap[mail][property] = true;
        });
      }
    };

    mapMailingList(lender.loanStatusEmails, 'loanStatus');
    mapMailingList(lender.dailyReportEmails, 'dailyReport');
    mapMailingList(lender.wireConfirmEmails, 'wireConfirm');
    mapMailingList(lender.extensions, 'extensions');

    const mailingLists: MailingListSubscription[] = Object.values(mailsMap);
    return mailingLists;
  }

  getUpdatedMalingLists(subscriptionInfo: MailingListSubscription) {
    const email = subscriptionInfo.email;
    const lender = this.salesForceLender;

    const updateList = (list, shouldInclude) => {
      if (list != null) {
        let emails = list.split(',');
        emails = emails.filter(e => e !== email);
        if (shouldInclude) {
          emails = emails.concat(email);
        }
        return emails.join(',');
      }
      if (list == null && shouldInclude) {
        return email;
      }
    };

    return {
      loanStatusEmails: updateList(
        lender.loanStatusEmails,
        subscriptionInfo.loanStatus
      ),

      dailyReportEmails: updateList(
        lender.dailyReportEmails,
        subscriptionInfo.dailyReport
      ),

      extensions: updateList(
        lender.extensions,
        subscriptionInfo.extensions
      ),

      wireConfirmEmails: updateList(
        lender.wireConfirmEmails,
        subscriptionInfo.wireConfirm
      ),
    };
  }
  validateMailingListsLength = (values: MailingListSubscription) => {
    const lists = this.getUpdatedMalingLists(values);

    const isValidMailingList = (list) => {
      return list != null && list.length > MAILING_LIST_MAX_LENGTH;
    }

    const invalidLists = [];

    if (isValidMailingList(lists.loanStatusEmails)) {
      invalidLists.push('Loan Status');
    }
    if (isValidMailingList(lists.dailyReportEmails)) {
      invalidLists.push('Daily Report');
    }
    if (isValidMailingList(lists.extensions)) {
      invalidLists.push('Extensions');
    }
    if (isValidMailingList(lists.wireConfirmEmails)) {
      invalidLists.push('Wire Confirm');
    }

    if (invalidLists.length > 0) {
      this.globalStore.notificationStore.showErrorNotification({
        message: `Limit exceeded for the following lists: ${invalidLists.join(
          ', '
        )}`,
      });
      return false;
    } else {
      return true;
    }
  };

  *updateMailSubscriptions(subscriptionInfo: MailingListSubscription) {
    const mailingLists = this.getUpdatedMalingLists(subscriptionInfo);
    yield this.updateLenderFields(mailingLists);
  }

  getFeesFromSalesforceLender() {
    const lender = this.salesForceLender;

    const fees: LenderFees = {
      servicingSetupFees: lender.servicingSetupFee,
      adminFees: lender.adminFee,
      servicingFees: lender.servicingFee,
      wireFees: lender.wireFee,
      valuationReviewFees: lender.valuationReviewFee,
      commitmentFees: lender.commitmentFee,
      legalReviewFees: lender.legalReviewFee,
      appraisal: lender.appraisal,
      processingFees: lender.processingFee,
      custodianFees: lender.custodianFee,
      underWritingFees: lender.underwritingFee,
      constructionDrawFees: lender.constructionDrawFee,
      defaultTermPoints: lender.defaultTermPoints,
    };
    return fees;
  }

  getSubscriptionDetails(
    lender: NewLender,
    userNotificationInfo: UserNotificationInfo,
    groupEmailSubscripton: GroupEmailSubscription
  ) {
    const subscriptionDetails: LenderSubscriptionDetails = {
      subscribeToUnderwritingEmails: groupEmailSubscripton.underwriting,
      subscribeToInsuranceEmails: groupEmailSubscripton.insurance,
      subscribeToClosingEmails: groupEmailSubscripton.closing,
      subscribeToPendingDocumentsEmails: groupEmailSubscripton.pendingDocsEmail,
      notifyInsuranceAgent: lender.notifyInsuranceAgent,
      notifyBorrowerClosingAgent: lender.notifyBorrowerClosingAgent,
      borrowersParticipateInHomeDepotProgram: lender.borrowersParticipateHomeDepotProgram,
      sendDrawBoardedNotification: lender.sendDrawNotification,
      fullEmailSynchronization: userNotificationInfo.emailSyncEnabled,
      loanStatusMailingList: lender.loanStatusEmails,
      dailyReportMailingList: lender.dailyReportEmails,
      extensionsMailingList: lender.extensions,
      wireConfirmMailingList: lender.wireConfirmEmails,

    };
    return subscriptionDetails;
  }

  setPortal(portal: string) {
    this.portal = portal;
  }

  formatDocumentList(documents) {
    return documents.map(doc => `- ${doc}`).join('\n');
  }

  formatUserDocuments(users) {
    return users.map(userMissingDocs =>
      `\n${userMissingDocs.user}:\n${this.formatDocumentList(userMissingDocs.userDocuments)}`).join('\n');
  }

  *addLenderBrokerRelation(newOwnerId: number, recordId: number) {
    try {
      yield this.companyProfileService.addLenderBrokerRelation(newOwnerId, recordId);
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Lender Broker Relation added successfully'
      });
    } catch (error) {
      const { message } = error?.error?.response?.data?.error;
      this.globalStore.notificationStore.showErrorNotification({
        message: message || GENERIC_ERROR_MESSAGE
      });
    }
  }

  *turnBrokerIntoTPO(newOwnerId: number, recordId: number) {
    try {
      yield this.companyProfileService.turnBrokerIntoTPO(newOwnerId, recordId);
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Broker added as TPO successfully'
      });
    } catch (error) {
      const { message } = error?.error?.response?.data?.error;
      this.globalStore.notificationStore.showErrorNotification({
        message: message || GENERIC_ERROR_MESSAGE
      });
    }
  }
}
