import { Service } from "@roc/feature-app-core";

export const url = {
  GET_ALL_TEMPLATES: '/api/v1/utility/sendgrid/getAllTemplatesFromSendgrid',
  GET_ALL_OWNERS: '/api/v1/utility/sendgrid/getAllOwners',
  GET_ALL_ASSIGNED_TEMPLATES: '/api/v1/utility/sendgrid/getAllAssignedTemplates',
  ASSIGN_TEMPLATE: '/api/v1/utility/sendgrid/assignTemplate',
  SEND_EMAILS_BY_OWNER_AND_AUDIENCE: '/api/v1/utility/sendgrid/processSelectedMarketingEmails',
  SEND_EMAILS_BY_BRAND_AND_AUDIENCE: '/api/v1/utility/sendgrid/processMarketingEmailsByBrandAndAudience',
  SEND_CORPORATE_EMAILS: '/api/v1/utility/sendgrid/processCorporateMarketingEmails',
  GET_DAYS_NOT_SENT_VALUE: '/api/v1/utility/sendgrid/getDaysNotSentValue',
  UPDATE_DAYS_NOT_SENT_VALUE: '/api/v1/utility/sendgrid/updateDaysNotSentValue',
  SEND_LO_EMAILS: '/api/v1/utility/sendgrid/sendLOEmails',
  GET_ALL_TEMPLATES_BY_USER: '/api/v1/vendor/ringCentral/getAllTemplates',
  GET_TEMPLATE_HTML: '/api/v1/utility/sendgrid/getTemplateContentsFromSendgrid'
};

export class SendgridService extends Service {

  getAllTemplates() {
    return this.get(url.GET_ALL_TEMPLATES);
  }

  getAllAssignedTemplates() {
    return this.get(url.GET_ALL_ASSIGNED_TEMPLATES);
  }

  assignTemplate(templateId, brand, audience) {
    const requestBody = {
      templateId: templateId,
      brand: brand.toLowerCase(),
      audience: audience.trim()
    };

    return this.post(url.ASSIGN_TEMPLATE, requestBody, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  getAllOwners() {
    return this.get(url.GET_ALL_OWNERS);
  }

  sendEmail(owners, audience) {
    const requestBody = {
      ownerEmails: owners,
      audience: audience,
    };

    return this.post(url.SEND_EMAILS_BY_OWNER_AND_AUDIENCE, requestBody, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  sendEmailByBrand(brand, audience) {
    const requestBody = {
      brand: brand,
      audience: audience,
    };

    return this.post(url.SEND_EMAILS_BY_BRAND_AND_AUDIENCE, requestBody, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  sendCorporateEmails(brand, audience) {
    const requestBody = {
      brand: brand,
      audience: audience,
    };

    return this.post(url.SEND_CORPORATE_EMAILS, requestBody, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }


  getGeneralEmailSettingsValues() {
    return this.get(url.GET_DAYS_NOT_SENT_VALUE);
  }

  updateGeneralEmailSettingsValues(daysInterval) {
    return this.get(`${url.UPDATE_DAYS_NOT_SENT_VALUE}?newValue=${daysInterval}`);
  }

  sendLOEmails(form: FormData) {
    return this.post(url.SEND_LO_EMAILS, form);
  }

  getAllTemplatesByUser() {
    return this.get(url.GET_ALL_TEMPLATES_BY_USER);
  }

  getTemplateDataFromSendgrid(ownerEmail) {
    return this.get(url.GET_TEMPLATE_HTML, { ownerEmail });
  }
}