import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  createRadioField,
  createSliderField,
  createSelectField,
  createTextField,
} from '@roc/ui/componentBuilder';
import { DialogState, EditableDialog } from '@roc/ui';
import { observer } from 'mobx-react';
import React from 'react';
import { statesNames, statesAbbreviation } from '@roc/feature-utils';
import FundingPreferenceStore from 'libs/feature-documents/src/documents/stores/documentForms/fundingPreference/fundingPreferenceStore';

interface Props {
  fundingPreferenceStore: FundingPreferenceStore;
}

export const EditBorrowerDialog = observer((props: Props) => {
  const { fundingPreferenceStore } = props;
  const { editBorrowerStore } = fundingPreferenceStore;
  const store = editBorrowerStore;

  return (
    <EditableDialog
      title={'Borrower'}
      open={store.dialogState !== null}
      dialogState={store.dialogState}
      handleAdd={() => { }}
      handleEdit={() => store.submit()}
      handleClose={() => store.closeDialog()}
      dialogContent={
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {createTextField({
              store,
              testId: 'roleInEntity',
              fieldName: 'roleInEntity',
              label: 'Title',
            })}
          </Grid>
          <Grid item xs={12}>
            {createTextField({
              store,
              label: 'First Name',
              fieldName: 'firstName',
              testId: 'firstName',
            })}
          </Grid>
          <Grid item xs={12}>
            {createTextField({
              store,
              label: 'Middle Name',
              fieldName: 'middleName',
              testId: 'middleName',
            })}
          </Grid>
          <Grid item xs={12}>
            {createTextField({
              store,
              label: 'Last Name',
              fieldName: 'lastName',
              testId: 'lastName',
            })}
          </Grid>
          <Grid item xs={12}>
            {createTextField({
              store,
              label: 'Suffix',
              fieldName: 'suffix',
              testId: 'suffix',
            })}
          </Grid>
        </Grid>
      }
    />
  );
});

export default EditBorrowerDialog;
