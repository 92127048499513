import {
  Box,
  Button,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu
} from '@material-ui/core';
import {
  Block,
  Check,
  CheckCircle,
  CheckCircleOutline,
  Close,
  CloudCircle,
  Error,
  FormatAlignCenter,
  Link,
  MoreVert,
} from '@material-ui/icons';
import {
  StatusChip,
  StatusType,
  Toolbar,
} from '@roc/client-portal-shared/components';
import { formatDate } from '@roc/client-portal-shared/utils';
import {
  AgGridColumnProps,
  AlertMenuItem,
  ConfirmationMenuItem,
  MenuItem,
  StandardDialog,
} from '@roc/ui';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import {
  FilterButton,
  SelectFilterComponent,
  SelectFloatingFilterComponent,
} from '../../../components/grid';
import { Grid } from '../../../components/grid/grid';
import { useStore } from '../../../hooks';
import { BorrowerDetailsFormInternal } from 'libs/feature-borrowers/src/borrowerDetailsVerification/components/borrowerDetailsFormInternal';

const noOpComparator = () => 0;

const yesNo = [
  { label: 'Yes', value: 'true' },
  { label: 'No', value: 'false' },
];

const columns: AgGridColumnProps[] = [
  {
    field: 'requestId',
    headerName: 'Request Id',
    minWidth: 80,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'borrowerEmail',
    headerName: 'Borrower Email',
    minWidth: 250,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'borrowerName',
    headerName: 'Borrower Name',
    minWidth: 250,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'runCredit',
    headerName: 'Run Credit',
    minWidth: 50,
    cellRenderer: 'checkCellRenderer',
    comparator: noOpComparator,
    filter: 'selectFilter',
    floatingFilter: true,
    floatingFilterComponent: 'selectFloatingFilter',
    floatingFilterComponentParams: {
      suppressFilterButton: true,
      options: yesNo,
      testId: 'run_credit',
    },
  },
  {
    field: 'runBackground',
    headerName: 'Run Background',
    minWidth: 50,
    cellRenderer: 'checkCellRenderer',
    comparator: noOpComparator,
    filter: 'selectFilter',
    floatingFilter: true,
    floatingFilterComponent: 'selectFloatingFilter',
    floatingFilterComponentParams: {
      suppressFilterButton: true,
      options: yesNo,
      testId: 'run_background',
    },
  },
  {
    field: 'createdDate',
    headerName: 'Requested Date',
    minWidth: 100,
    cellRenderer: 'dateCellRenderer',
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'requestedBy',
    headerName: 'Requested By',
    minWidth: 100,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'status',
    headerName: 'Status',
    minWidth: 150,
    cellRenderer: 'statusCellRenderer',
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'originatorName',
    headerName: 'Originator',
    minWidth: 150,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'requestId',
    headerName: 'Action',
    minWidth: 75,
    cellRenderer: 'actionCellRenderer',
    comparator: noOpComparator,
    width: 80,
    pinned: 'right',
    lockPinned: true
  },
];

/*const MenuItemWithConfirmationDialog = withConfirmDialog<MenuItemProps>(props => {
  return <MenuItem onClick={props.onClick}>{props.children}</MenuItem>;
});

const MenuItemWithAlertDialog = withAlertDialog<MenuItemProps>(props => {
  return <MenuItem onClick={props.onClick}>{props.children}</MenuItem>;
});*/

export const CreditBackgroundRequestsGrid = observer(() => {
  const { creditBackgroundStore, globalStore } = useStore();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const [requestId, setRequestId] = useState('');
  const [selectedRecord, setSelectedRecord] = useState<any>({});
  const [showDownloadReports, setShowDownloadReports] = useState(true);
  const [showMarkAsVoidOption, setShowMarkAsVoidOption] = useState(true);
  const [showFormLink, setShowFormLink] = useState(true);
  const [isIDVerification, setIDVerification] = useState(false);
  const [showMarkAsCompleteOption, setShowMarkAsCompleteOption] = useState(
    true
  );
  const [isBorrowerVerificationFormOpen, setIsBorrowerVerificationFormOpen] = useState(
    false
  );
  const [token, setToken] = useState('')

  const openMenu = (event: React.MouseEvent<HTMLElement>, record) => {
    const { requestId, status, type, requestToken } = record;
    setRequestId(requestId);
    setToken(requestToken);
    setSelectedRecord(record);
    setShowDownloadReports(status == 'Report(s) Delivered' || status == 'Background Completed');
    setShowFormLink(status == 'Waiting Authorization' || status == 'Background Completed');
    setShowMarkAsVoidOption(status == 'Waiting Authorization');
    setShowMarkAsCompleteOption(status == 'Report Request Error');
    setIDVerification(type === 'ID_VERIFICATION')
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const frameworkComponents = {
    selectFilter: SelectFilterComponent,
    selectFloatingFilter: SelectFloatingFilterComponent,
    dateCellRenderer: ({ value }) => formatDate(value, 'MM/dd/yyyy') ?? null,
    checkCellRenderer: ({ value }) => {
      return value ? (
        <Check style={{ color: 'green', verticalAlign: 'middle' }} />
      ) : (
        <Close style={{ color: 'red', verticalAlign: 'middle' }} />
      );
    },
    statusCellRenderer: ({ value }) =>
      value ? (
        <StatusChip
          statusType={StatusType.CREDIT_BACKGROUND}
          label={value}
          size="small"
        />
      ) : null,
    actionCellRenderer: params => {
      return params.node.data?.status == 'Manually Closed' ? null : (
        <IconButton onClick={e => openMenu(e, params.node.data)}>
          <MoreVert color="primary" />
        </IconButton>
      );
    },
  };

  const onSortChanged = params => {
    const sortModel = params.api.getSortModel();
    creditBackgroundStore.gridStore.setSortModel(sortModel);
  };

  const onFilterChanged = params => {
    const filterModel = params.api.getFilterModel();
    creditBackgroundStore.gridStore.setFilterModel(filterModel);
  };

  const getFormLink = (requestId) => {
    const response = Promise.resolve(creditBackgroundStore.getFormLink(requestId));
    response.then((value) => {
      navigator.clipboard.writeText(value.toString());
    });
  }

  const closeIdVerificationForm = () => {
    setIsBorrowerVerificationFormOpen(false);
  };

  const toolbar = (
    <Toolbar>
      <FilterButton
        onToggleFilters={() => creditBackgroundStore.gridStore.toggleFilters()}
        onClearFilters={() => creditBackgroundStore.gridStore.resetFilters()}
      />
    </Toolbar>
  );

  return (
    <>
      <Grid
        columns={columns}
        rows={creditBackgroundStore.gridStore.gridData.data.rows}
        frameworkComponents={frameworkComponents}
        toolbar={toolbar}
        onSortChanged={onSortChanged}
        isLoading={creditBackgroundStore.gridStore.isLoading}
        sortModel={creditBackgroundStore.gridStore.sortModel}
        showFilters={creditBackgroundStore.gridStore.showFilters}
        filterModel={creditBackgroundStore.gridStore.filterModel}
        onFilterChanged={onFilterChanged}
        paginationData={creditBackgroundStore.gridStore.gridData.meta}
        setPageNumber={creditBackgroundStore.gridStore.setPageNumber}
      />
      <Menu
        id="menu-appbar"
        getContentAnchorEl={null}
        anchorEl={anchorEl}
        keepMounted
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={menuOpen}
        onClose={handleMenuClose}
      >
        {showDownloadReports && (
          <MenuItem
            testId={`download-reports-${requestId}`}
            onClick={() => {
              creditBackgroundStore.downloadReports(requestId);
              handleMenuClose();
            }}
          >
            <ListItemIcon>
              <CloudCircle fontSize="small" />
            </ListItemIcon>
            <ListItemText>Download Reports</ListItemText>
          </MenuItem>
        )}
        {showMarkAsVoidOption && (
          <ConfirmationMenuItem
            testId={`mark-as-void-${requestId}`}
            onClick={() => {
              creditBackgroundStore.markAsVoid(requestId);
              handleMenuClose();
            }}
            confirmDialogProps={{
              body: 'Are you sure you want to mark the request as void?',
            }}
          >
            <ListItemIcon>
              <Block fontSize="small" />
            </ListItemIcon>
            <ListItemText>Mark as Void</ListItemText>
          </ConfirmationMenuItem>
        )}
        {showMarkAsCompleteOption && (
          <AlertMenuItem
            testId={`view-error-${requestId}`}
            onClick={handleMenuClose}
            alertDialogProps={{
              title: `Error message of request: ${selectedRecord.requestId}`,
              body:
                selectedRecord.errorReasonMessage ||
                `No error message available for this request.`,
            }}
          >
            <ListItemIcon>
              <Error color="error" fontSize="small" />
            </ListItemIcon>
            <ListItemText>View Error</ListItemText>
          </AlertMenuItem>
        )}
        {showMarkAsCompleteOption && (
          <ConfirmationMenuItem
            testId={`mark-as-complete-${requestId}`}
            onClick={() => {
              creditBackgroundStore.markAsManuallyClosed(requestId);
              handleMenuClose();
            }}
            confirmDialogProps={{
              body: 'Are you sure you want to mark the request as complete?',
            }}
          >
            <ListItemIcon>
              <CheckCircleOutline fontSize="small" />
            </ListItemIcon>
            <ListItemText>Mark as Complete</ListItemText>
          </ConfirmationMenuItem>
        )}
        {isIDVerification && showFormLink && (
          <MenuItem
            testId={`copy-verification-form-${requestId}`}
            onClick={() => { getFormLink(requestId); }}
          >
            <ListItemIcon>
              <Link />
            </ListItemIcon>
            <ListItemText>Copy Authorization Form Link</ListItemText>
          </MenuItem>
        )}
        {isIDVerification && showFormLink && (
          <ConfirmationMenuItem
            testId={`manage-reminders-${requestId}`}
            onClick={() => {
              creditBackgroundStore.manageDailyReminder(requestId);
              handleMenuClose();
            }}
            confirmDialogProps={{
              title: `Manage email reminders`,
              body: `Are you sure you want to ${selectedRecord.enableDailyReminders === true ? "deactivate " : "activate"} daily reminder email for this borrower?`
            }}
          >
            <ListItemIcon>
              {selectedRecord.enableDailyReminders === true ? <Close /> : <CheckCircleOutline />}
            </ListItemIcon>
            <ListItemText>{selectedRecord.enableDailyReminders === true ? "Stop reminders" : "Enable reminders"} </ListItemText>
          </ConfirmationMenuItem>
        )}
        {isIDVerification && (showMarkAsCompleteOption || showDownloadReports) && (
          <MenuItem
            testId={`copy-verification-form-${requestId}`}
            onClick={() => { setIsBorrowerVerificationFormOpen(true); }}
          >
            <ListItemIcon>
              <FormatAlignCenter />
            </ListItemIcon>
            <ListItemText>View Borrower Form</ListItemText>
          </MenuItem>
        )}
        <StandardDialog
          open={isBorrowerVerificationFormOpen}
          maxWidth="xl"
          onClose={closeIdVerificationForm}
          handleClose={closeIdVerificationForm}
          dialogContent={
            <>
              <BorrowerDetailsFormInternal token={token} isInternal={true} />
              <Box flex="1" display="flex" justifyContent="flex-end">
                <Button variant='outlined' color='primary' onClick={closeIdVerificationForm}>
                  Close
                </Button>
              </Box>
            </>

          }
        />
      </Menu>
    </>
  );
});
