import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { useLoanStore } from '@roc/feature-loans-shared';
import { Paper } from '@roc/ui';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { useDrawsStore } from '../hooks';
import { DrawSuccess } from './components/drawSuccess';
import { DrawRequestShared } from './drawRequestShared';

const useStyles = makeStyles(theme => ({
  paper: {
    margin: 24,
  },
  container: {
    padding: 32,
  },
  dealName: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

export const DrawRequest = observer(() => {
  const { loanStore } = useLoanStore();
  const classes = useStyles();
  const [showSuccessScreen, setShowSuccessScreen] = useState(false);

  if (showSuccessScreen) {
    return (
      <Paper className={classes.paper}>
        <DrawSuccess />;
      </Paper>
    );
  }

  return (
    <>
      <Grid
        container
        direction="column"
        spacing={2}
        className={classes.container}
      >
        <Grid item className={classes.dealName}>
          <Typography variant="h6" color="textSecondary">
            {loanStore?.loanDetails.dealName}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h4">REQUEST DRAW</Typography>
        </Grid>
        <DrawRequestShared setShowSuccessScreen={setShowSuccessScreen} />
      </Grid>
    </>
  );
});

export default DrawRequest;
