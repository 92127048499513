export const formatPhoneNumber = value => {
  if (value) {
    const num = value.toString().replace(/\D/g, '');
    if (num.length > 6) {
      return (
        '(' +
        num.substring(0, 3) +
        ') ' +
        num.substring(3, 6) +
        '-' +
        num.substring(6, 10)
      );
    } else if (num.length > 3) {
      return '(' + num.substring(0, 3) + ') ' + num.substring(3, 6);
    } else {
      return num;
    }
  } else {
    return '';
  }
};

export const getCompletePropertyAddress = property => {
  if (property) {
    const streetNumber = property?.streetNumber ? property?.streetNumber : '';
    const streetName = property?.streetName ? property?.streetName : '';
    const aptNumber = property?.aptNumber ? property?.aptNumber : '';
    const city = property?.city ? property?.city : '';
    const state = property?.state ? property.state : '';
    const zipCode = property?.zipCode ? property?.zipCode : '';

    return [
      `${streetNumber} ${streetName} ${aptNumber}`.trim(),
      `${city}`.trim(),
      `${state} ${zipCode}`.trim(),
    ]
      .filter(Boolean)
      .join(', ');
  } else {
    return '';
  }
};
