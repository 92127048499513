import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Box, Grid, lighten, makeStyles, Theme, Typography, useTheme } from '@material-ui/core';
import { LoginLogoImg, Page } from '@roc/feature-app-core';
import { Paper, PaperBorderType, PreviewBackdrop } from '@roc/ui';
import clsx from 'clsx';
import jsPDF from 'jspdf';
import { useEffect, useRef, useState } from 'react';
import { useDocumentLibraryRoutes } from '../../hooks/useDocumentLibraryRoutes';

const useStyles = makeStyles((theme: Theme) => ({
  documentTitle: {
    color: lighten(theme.palette.primary.main, 0.1),
  },
  documentContainer: {
    paddingLeft: '64px',
    paddingRight: '64px',
    position: 'relative',
    background: '#FFF',
    fontSize: 16,
    overFlowX: 'auto',
    '& ul,ol': {
      marginLeft: theme.spacing(3),
      marginBottom: theme.spacing(0.5),
    },
    '& h6': {
      fontSize: 16,
      padding: 4,
      fontWeight: 'bold',
    },
    '& hr': {
      marginBottom: '10px'
    },
    '@media (max-width: 800px)': {
      fontSize: 8,
      padding: 0,
    },
  },
  documentCard: {
    minWidth: '650px',
    borderRadius: 0,
    borderColor: '#aaa',
    marginBottom: '50px'
  },
  documentCardTitle: {
    justifyContent: 'center',
    textAlign: 'center',
    color: lighten(theme.palette.primary.main, 0.1),
    fontWeight: 'bold',
    padding: theme.spacing(0.5),
    textDecoration: 'underline',
    marginBotton: '20px'
  }, 
  titleContainer: { 
    textAlign: 'center', 
    display: 'flex', 
    flexDirection: 'column', 
    alignItems: 'center', 
    marginBottom: '30px' 
  },
  title: { 
    marginTop: '10px', 
    marginBottom: '20px' 
  },
  divider: { 
    backgroundColor: lighten(theme.palette.primary.main, 0.5),
    height: '10px',
    width: '400px', 
  }, 
  headerBand: {
    backgroundColor: lighten(theme.palette.primary.main, 0.5),
    height: '10px',
    width: '100%'
  }
}));

const usePrintModeStyles = makeStyles((theme: Theme) => ({
  documentContainer: {
    fontSize: 12,
    padding: 0,
    '& ul,ol': {
      marginLeft: theme.spacing(2),
    },
    '& h6': {
      fontSize: 12,
    },
    '& h5': {
      textDecoration: 'underline'
    },
  },
}));

const DocumentCard = ({
  title = undefined,
  data,
  paperBorder = PaperBorderType.SOLID_BORDER,
  paperClassName = '',
  paperTitleClassName = '',
  paperBodyClassName = '',
  paperStyle = {},
}) => {
  const classes = useStyles();
  return (
    <Paper
      title={title}
      titleClass={clsx(classes.documentCardTitle, paperTitleClassName)}
      titleVariant={'h5'}
      borderType={paperBorder}
      className={clsx(classes.documentCard, paperClassName)}
      style={paperStyle}>
      <Box className={paperBodyClassName}>
        {documentToReactComponents(data)}
      </Box>
    </Paper>
  )
}

export const ServicerInformationHtml = ({
  contentId,
  data,
  preview = false,
  printMode = false,
  setContainerRef,
}: {
  contentId: string,
  data: any,
  preview?: boolean,
  printMode?: boolean,
  setContainerRef: any,
}) => {
  const theme = useTheme();
  const { documentLibraryRoutesConfig } = useDocumentLibraryRoutes();
  const classes = useStyles();
  const containerRef = useRef(null);

  const [printModeContainerStyle, setPrintModeContainerStyle] = useState<any>();
  const [containerStyle, setContainerStyle] = useState<any>();
  const printModeClasses = usePrintModeStyles();

  useEffect(() => {
    const doc = new jsPDF("p", "mm", "a4");
    let width: any = doc.internal.pageSize.getWidth();
    let height: any = doc.internal.pageSize.getHeight();
    width = `${Math.floor(width)}mm`;
    height = `${Math.floor(height)}mm`;
    setPrintModeContainerStyle({
      margin: 'auto',
      minWidth: width,
      maxWidth: width,
      height: height
    });
    setContainerStyle({
      margin: 'auto',
      maxWidth: theme.breakpoints.values.lg,
    });
  }, []);

  useEffect(() => {
    setContainerRef(containerRef);
  }, [containerRef])

  if (!data) {
    return null;
  }

  return (
    <div ref={containerRef} style={printMode ? printModeContainerStyle : containerStyle}>
      <Page routeDefinition={documentLibraryRoutesConfig.documentLibraryDocument(contentId)}>
        <Box padding={'16px'} className={clsx(classes.documentContainer, printMode ? printModeClasses.documentContainer : undefined)}>
          {preview && <PreviewBackdrop />}
          <Box className={classes.headerBand} /> 

          <LoginLogoImg imgStyle={{ maxWidth: 200, marginLeft: '58px' }}/>
          <Box my={2} className={classes.titleContainer}> 
            <Typography variant={'h4'} className={clsx(classes.documentTitle, classes.title)}>
              <strong>{data.title}</strong>
            </Typography>
            <Box className={classes.divider} /> 
          </Box>

          <Box mb={2} paddingLeft={'5%'} paddingRight={'5%'} paddingBottom="5%" overflow={'auto'} >
            <Grid container >
              <Grid item xs={12}>
                <DocumentCard
                  title={data.paymentInformationTitle}
                  data={data.paymentInformationBody}
                  paperBorder={PaperBorderType.NO_BORDER}
                />
                <DocumentCard
                  title={data.servicerContactsTitle}
                  data={data.servicerContactsBody}
                  paperBorder={PaperBorderType.NO_BORDER}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Page>
    </div>
  );
}