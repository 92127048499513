import { Home } from '@material-ui/icons';
import { DialogState } from '@roc/ui';
import { observer } from 'mobx-react';
import { useNewLoanApplicationStore } from '../hooks/useNewLoanApplicationStore';
import {
  LoanApplicationCardTitle,
  LoanApplicationDialog
} from './loanApplicationComponents';
import { AddPropertyForm, EditPropertiesForm } from './propertiesForms';

export const PropertyInformationModal = observer(() => {
  const { newLoanApplicationStore } = useNewLoanApplicationStore();
  const { propertyStore } = newLoanApplicationStore;
  const { dialogState } = propertyStore;

  const handleSave = () =>
    dialogState === DialogState.ADD
      ? propertyStore.saveAddProperty()
      : propertyStore.saveEditProperties();

  return (
    <LoanApplicationDialog
      title={
        <LoanApplicationCardTitle
          title={'Property Information'}
          icon={<Home />}
        />
      }
      dialogState={dialogState}
      handleClose={() => propertyStore.closeDialog()}
      handleSave={handleSave}
      dialogContent={
        <>
          {dialogState === DialogState.ADD && <AddPropertyForm />}
          {dialogState === DialogState.EDIT && <EditPropertiesForm />}
        </>
      }
    />
  );
});
