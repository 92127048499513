import { Document } from '@roc/feature-types';
import {
  Button,
  PrintableAreaRef,
  StandardDialog,
  VerticalBarLoader,
} from '@roc/ui';
import GetAppIcon from '@material-ui/icons/GetApp';
import { useCallback, useEffect, useRef, useState } from 'react';
import { TermSheetHtml } from './termSheetHtml';
import { TermSheet } from './termSheet';
import PrintIcon from '@material-ui/icons/Print';
import { useDocumentStore } from '../../../../hooks/useDocumentStore';
import { observer } from 'mobx-react';
import { LoanSubType, LoanType } from '@roc/feature-utils';

const DOCUMENT_NAME = 'Term Sheet.pdf';

export type TermSheetDialogProps = {
  open?: boolean;
  onClose?: () => void;
  loan: any;
  document: Document;
};

export const TermSheetDialogInternal = observer(
  (props: TermSheetDialogProps) => {
    const { documentStore } = useDocumentStore();
    const { termSheetStore } = documentStore.documentFormStore;

    const { loanId, lenderId } = props.loan || {};
    const portalId = termSheetStore.portalId;

    useEffect(() => {
      if (props.open) {
        termSheetStore.fetchPortalId(lenderId);
      }
    }, [props.open]);

    return (
      !!(loanId && portalId) && (
        <InternalPortalDialog
          open={props.open && portalId}
          onClose={props.onClose}
          title={'Term Sheet'}
          src={`/internal-portal/term-sheet-dialog?loanId=${loanId}&portalId=${portalId}`}
        />
      )
    );
  }
);

const InternalPortalDialog = ({ open, src, onClose, ...iframeProps }) => {
  const [isLoading, setIsLoading] = useState(true);

  const iframeRef = useRef();

  const handleMessage = useCallback(e => {
    const action = e.data?.action;
    if (action === 'modal-ready') {
      setIsLoading(false);
    } else if (action === 'close-modal') {
      onClose();
    }
  }, []);

  useEffect(() => {
    addEventListener('message', handleMessage);
    return () => removeEventListener('message', handleMessage);
  }, [iframeRef.current]);

  useEffect(() => {
    setIsLoading(true);
  }, [open]);

  return open ? (
    <div onClick={onClose}>
      <iframe
        ref={iframeRef}
        style={{
          visibility: isLoading ? 'hidden' : 'visible',
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          border: 0,
        }}
        src={src}
        {...iframeProps}
      />
      {isLoading && <VerticalBarLoader />}
    </div>
  ) : null;
};
