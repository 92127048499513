import { Grid, Paper, Theme, Typography } from "@material-ui/core"

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    margin: theme.spacing(4),
    width: '90%',
    height: '90%',
    borderRadius: theme.spacing(2),
  },
  grid: {
    width: '100%',
    height: '100%',
  }
}));

const CancelledOrderLink = () => {
  const classes = useStyles()
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Paper className={classes.paper}>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          className={classes.grid}
        >
          <Typography variant="h2">Sorry, this link is no longer valid.</Typography>
        </Grid>
      </Paper>
    </Grid>

  )
}
export default CancelledOrderLink