import {
  Box,
  Grid,
  IconButton,
  ListItem,
  ListItemText,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { useStore } from '@roc/client-portal-shared/hooks';
import { FormStore } from '@roc/feature-app-core';
import { isBlank, isNil } from '@roc/feature-utils';
import { Button, CurrencyField, Paper, TextField } from '@roc/ui';
import { formatCurrency } from '@roc/ui/utils';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import { useEffect } from 'react';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(2),
  },
  tableContainer: {},
  table: {
    '& td': {
      padding: theme.spacing(2, 4),
    },
    '& tr td:nth-child(1), & tr td:nth-child(3)': {
      width: 'auto',
    },
    '& tr td:nth-child(2), & tr td:nth-child(4)': {
      width: 'auto',
      textAlign: 'right',
      padding: theme.spacing(0, 4)
    },
  },
  row: {
    borderTop: '1px solid #BEBEBE',
  },
  bold: {
    fontWeight: 'bold',
  },
  fieldCell: {
    padding: theme.spacing(0, 1),
  },
  totalRow: {
    fontWeight: 'bold',
    backgroundColor: '#FAFAFA',
    '& tr td:nth-child(1), & tr td:nth-child(3)': {
      textAlign: 'center',
    },
  },
  headerRow: {
    backgroundColor: '#EEE',
  },
  rateFieldInput: {
    '& input': {
      padding: theme.spacing(1),
    },
  },
}));

export const DefaultFees = observer(({ companyProfileStore }) => {
  const { defaultFeesStore } = companyProfileStore;
  const { defaultFees, isEditing } = defaultFeesStore;

  const classes = useStyles();

  useEffect(() => {
    defaultFeesStore.fetchDefaultFees();
  }, []);

  return (
    <Paper className={classes.paper}>
      {defaultFees && (
        <Box p={4}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Typography variant="h5">DEFAULT FEES</Typography>
            </Grid>
            <Grid item xs={12} md={6} container justifyContent="flex-end">
              {isEditing ? (
                <>
                  <Button
                    style={{ marginRight: '8px' }}
                    onClick={() => defaultFeesStore.cancel()}
                    color="primary"
                    variant="outlined"
                    testId="cancel"
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={() => defaultFeesStore.saveDefaultFees()}
                    color="primary"
                    variant="contained"
                    testId="save"
                  >
                    Apply Changes
                  </Button>
                </>
              ) : (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => defaultFeesStore.edit()}
                  testId="save"
                >
                  Edit
                </Button>
              )}
            </Grid>
            <Grid item xs={12}>
              <TableContainer className={classes.tableContainer}>
                <Table className={classes.table} aria-label="Pricing Economics">
                  <TableRow className={classes.headerRow}>
                    <TableCell>
                      <strong>Originator Fees</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Amount</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Capital Provider Fees</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Amount</strong>
                    </TableCell>
                  </TableRow>
                  {defaultFees.map((fee, i) => {
                    if (!isEditing && fee.isPlaceholder) {
                      return null;
                    }

                    return (
                      <TableRow key={i}>
                        <TableCell>
                          {isEditing && fee.isCustomFee ? (
                            <TextField
                              className={classes.rateFieldInput}
                              variant={'outlined'}
                              testId={'customFee'}
                              value={fee.tpoFeeLabel}
                              placeholder={'+ Add custom fee'}
                              onChange={e =>
                                defaultFeesStore.onCustomFeeNameChange(
                                  fee,
                                  e.target.value
                                )
                              }
                            />
                          ) : (
                            <>{fee.tpoFeeLabel}</>
                          )}
                        </TableCell>
                        <TableCell className={classes.fieldCell}>
                          {isEditing && !fee.isPlaceholder ? (
                            <CurrencyField
                              inputClassname={classes.rateFieldInput}
                              variant={'outlined'}
                              testId={fee.tpoFeeLabel}
                              value={fee.tpoFeeAmount}
                              onChange={(_, val) =>
                                defaultFeesStore.onFeeChange(fee, val)
                              }
                            />
                          ) : (
                            <>
                              {!!fee.tpoFeeAmount && formatCurrency(fee.tpoFeeAmount)}
                            </>
                          )}
                        </TableCell>
                        <TableCell>{fee.rocFeeLabel}</TableCell>
                        <TableCell className={classes.fieldCell}>
                          {!!fee.rocFeeLabel && formatCurrency(fee.rocFeeAmount)}
                        </TableCell>
                      </TableRow>
                    )
                  })}
                  <TableRow className={classes.totalRow}>
                    <TableCell>
                      Total Originator Fees
                    </TableCell>
                    <TableCell>
                      {formatCurrency(defaultFeesStore.totalOriginatorFees)}
                    </TableCell>
                    <TableCell>
                      Total Capital Provider Fees
                    </TableCell>
                    <TableCell>
                      {formatCurrency(defaultFeesStore.totalRocFees)}
                    </TableCell>
                  </TableRow>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Box>
      )}
    </Paper>
  );
});
