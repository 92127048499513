import { Box, Grid } from '@material-ui/core';
import { Button, Toolbar } from '@roc/ui';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useExpectedClosingsStore } from '../hooks/useExpectedClosingsStore';
import { GridName } from '../utils/expectedClosingTypes';
import { ExpectedClosingGrid } from './expectedClosingGrid';
import { ExpectedClosingsCard } from './expectedClosingsCard';

export const TodayClosingDataTab = observer(() => {
  const { expectedClosingsStore } = useExpectedClosingsStore();
  const { todayClosingData } = expectedClosingsStore;

  useEffect(() => {
    expectedClosingsStore.fetchTodayClosingLoansData();
  }, []);

  if (!todayClosingData) {
    return null;
  }

  return (
    <Box py={2}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <ExpectedClosingsCard data={todayClosingData.totalClosed} />
        </Grid>
        <Grid item xs={12} md={6}>
          <ExpectedClosingsCard
            data={todayClosingData.netFunded}
            showInRed={['totalAmountNotFunded']}
            showInGreen={['totalAmount']}
          />
        </Grid>
        <Grid item xs={12}>
          <ExpectedClosingGrid
            toolbar={
              <Toolbar>
                <Button
                  testId="downloadFundedLoans"
                  color="primary"
                  variant="contained"
                  size="small"
                  onClick={() =>
                    expectedClosingsStore.downloadGridAsExcel(
                      GridName.FUNDED_LOANS
                    )
                  }
                >
                  Download as Excel
                </Button>
              </Toolbar>
            }
            data={todayClosingData.fundedLoans}
            gridStore={expectedClosingsStore.fundedLoansGridStore}
            showTotalRow
          />
        </Grid>
        <Grid item xs={12}>
          <ExpectedClosingGrid
            toolbar={
              <Toolbar>
                <Button
                  testId="downloadWaitingLoans"
                  color="primary"
                  variant="contained"
                  size="small"
                  onClick={() =>
                    expectedClosingsStore.downloadGridAsExcel(
                      GridName.WAITING_LOANS
                    )
                  }
                >
                  Download as Excel
                </Button>
              </Toolbar>
            }
            data={todayClosingData.waitingLoans}
            gridStore={expectedClosingsStore.waitingLoansGridStore}
            showTotalRow
          />
        </Grid>
        <Grid item xs={12}>
          <ExpectedClosingGrid
            data={todayClosingData.nonProjectedFundedLoans}
            gridStore={expectedClosingsStore.nonProjectedFundedLoansGridStore}
            showTotalRow
          />
        </Grid>
        <Grid item xs={12}>
          <ExpectedClosingGrid
            data={todayClosingData.retractedLoans}
            gridStore={expectedClosingsStore.retractedLoansGridStore}
            showTotalRow
          />
        </Grid>
      </Grid>
    </Box>
  );
});
