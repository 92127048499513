import { Box } from '@material-ui/core';
import {
  FilterButton,
  Toolbar,
} from '@roc/ui';
import {
  Layout,
} from '@roc/feature-app-core';
import { useBaseStore } from '@roc/feature-app-core';
import { Button, DialogState, EditableDialog } from '@roc/ui';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { BorrowersGrid } from './components/borrowersGrid';
import { useBorrowersStore } from './hooks/useBorrowersStore';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useUserStore } from '@roc/feature-app-core';
import { BorrowerPortalBanner } from './components/borrowerPortalBanner';

import swal from 'sweetalert';
export const Borrowers = observer(() => {
  const { globalStore } = useBaseStore();
  const { borrowersStore } = useBorrowersStore();
  const { userStore } = useUserStore();
  const { automaticBorrowerPortalInvite, lenderTradeName, borrowerPortalEnabled } = borrowersStore;


  useEffect(() => {
    borrowersStore.gridStore.resetAndFetchGridData();
    getBorrowerPortalPreferencesByTpo();
  }, [automaticBorrowerPortalInvite, lenderTradeName, borrowerPortalEnabled]);

  const handleToggleFilters = () => {
    borrowersStore.gridStore.toggleFilters();
  };

  const handleClearFilters = () => {
    borrowersStore.gridStore.resetFilters();
  };

  const handleAddNewBorrower = () => {
    borrowersStore.addNewBorrower();
  };

  const getBorrowerPortalPreferencesByTpo = () => {
    borrowersStore.getBorrowerPortalPreferencesByTpo(userStore.userInformation.companyId ?? globalStore?.selectedCompanyId);
  }

  const useStyles = makeStyles(theme => ({
    paper: {
      marginBottom: theme.spacing(2),
      paddingLeft: '10px',
      paddingTop: '4px'
    },
    button: {
      marginTop: '13px',
      marginBottom: theme.spacing(2),
      width: '100px'
    },
    tickSize: {
      transform: "scale(1.2)",
    },
    alerts: {
      fontSize: '1rem',
    },
    gridAlerts: {
      marginBottom: '15px'
    },
    alertsQuestions: {
      marginBottom: '8px'
    },
    coninueResponseModalButton: {
      backgroundColor: theme.palette.primary.main
    }
  }));
  const classes = useStyles();
  const theme = useTheme();
  useEffect(() => {
    if (borrowersStore.isBorrowerResponseModalOpen) {
      swal({
        title: borrowersStore.borrowerResponseStatus === 'success' ? 'Success!' : 'Error',
        text: borrowersStore.borrowerResponseMessage,
        icon: borrowersStore.borrowerResponseStatus,
        buttons: {
          close: {
            text: "Close"
          }
        },
        closeOnEsc: false,
        closeOnClickOutside: false,
      }).then(async (v) => {
        borrowersStore.handleCloseStatusModal();
      });
    }
    const button = document.querySelector('.swal-button--close') as HTMLElement;
    if (button) {
      button.style.backgroundColor = theme.palette.primary.main;
    }
  }, [
    borrowersStore.isBorrowerResponseModalOpen,
    borrowersStore.borrowerResponseStatus,
    borrowersStore.borrowerResponseMessage,
  ]);

  return (
    <Layout title="Borrowers">
      <BorrowerPortalBanner editEnabled={true} />
      <BorrowersGrid
        toolbar={
          <Toolbar>
            <Box mr={2}>
              <FilterButton
                onToggleFilters={handleToggleFilters}
                onClearFilters={handleClearFilters}
              />
            </Box>
            {globalStore.userFeatures?.addNewBorrower ? (
              <Box>
                <Button
                  variant="contained"
                  size="small"
                  onClick={handleAddNewBorrower}
                  color="primary"
                  testId="add_new_borrower"
                >
                  Add New Borrower
                </Button>
              </Box>
            ) : null}
          </Toolbar>
        }
      />
    </Layout>
  );
});
