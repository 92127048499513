import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Box, Grid, lighten, makeStyles, Theme, Typography, useTheme } from '@material-ui/core';
import { LoginLogoImg, Page } from '@roc/feature-app-core';
import { Paper, PaperBorderType, PaperTitle, PreviewBackdrop } from '@roc/ui';
import clsx from 'clsx';
import jsPDF from 'jspdf';
import { useEffect, useRef, useState } from 'react';
import { useDocumentLibraryRoutes } from './../../hooks/useDocumentLibraryRoutes';
import { format } from 'date-fns';
import { isEmptyString } from '@roc/feature-utils';

const useStyles = makeStyles((theme: Theme) => ({
  documentContainer: {
    paddingLeft: '32px',
    paddingRight: '32px',
    position: 'relative',
    background: '#FFF',
    fontSize: 12,
    '& table': {
      minHeight: '40px',
      width: '100%',
      textAlign: 'left',
      borderCollapse: "collapse",
    },
    '& table th': {
      textAlign: 'center',
      fontWeight: 'bold',
      background: lighten(theme.palette.primary.main, 0.5),
      padding: '4px',
      border: '0.25px solid black',
    },
    '& table th:nth-child(1)': {
      minWidth: '150px',
      width: '20%'
    },
    '& table th:nth-child(2)': {
      width: '200px',
    },
    '& table td:nth-child(1)': {
      width: '200px',
    },
    '& table td': {
      padding: '2px',
      borderBottom: '0.25px solid black',
      borderRight: '0.25px solid black',
      borderLeft: '0.25px solid black',
    },
    '& table td p': {
      marginLeft: '5px'
    },
    '@media (max-width: 800px)': {
      fontSize: 8,
      padding: 0,
    },
    '& h6': {
      fontSize: 10,
      padding: 4,
      fontWeight: 'bold',
    },
  },
  documentCard: {
    borderRadius: 0,
    borderColor: '#aaa',
    minWidth: '650px',
  },
  documentCardTitle: {
    borderBottom: '0.25px solid black',
    borderLeft: '0.25px solid black',
    minWidth: '150px',
    width: '20%',
    justifyContent: 'center',
    textAlign: 'center',
    background: lighten(theme.palette.primary.main, 0.8),
    padding: theme.spacing(0.5),
    alignItems: 'center',
    '& h6': {
      fontSize: 10,
      '@media (max-width: 800px)': {
        fontSize: 8,
        padding: 0,
      },
    },
  }
}));

const usePrintModeStyles = makeStyles((theme: Theme) => ({
  documentContainer: {
    fontSize: 10,
    '& table th': {
      fontSize: 10
    },
    '& table td': {
      fontSize: 10
    },
    '& h6': {
      fontSize: 10,
    },
  },
}));


const DocumentCard = ({
  title = undefined,
  data,
  paperBorder = PaperBorderType.SOLID_BORDER,
  paperClassName = '',
  paperTitleClassName = '',
  paperBodyClassName = '',
  paperStyle = {},
}) => {
  const classes = useStyles();
  return (
    <Paper
      title={title}
      titleComponent={
        <Box display="flex" flexDirection="row">
          <PaperTitle
            title={title}
            titleClass={clsx(classes.documentCardTitle, paperTitleClassName)}
            titleVariant={'h6'}
          />
          <Box flex={1}>{<Box className={paperBodyClassName}>
            {documentToReactComponents(data)}
          </Box>}</Box>
        </Box>}

      borderType={paperBorder}
      className={clsx(classes.documentCard, paperClassName)}
      style={paperStyle}>
    </Paper>
  )
}

export const AppraisalGuidelinesHtml = ({
  contentId,
  data,
  preview = false,
  printMode = false,
  setContainerRef,
}: {
  contentId: string,
  data: any,
  preview?: boolean,
  printMode?: boolean,
  setContainerRef: any,
}) => {
  const theme = useTheme();
  const { documentLibraryRoutesConfig } = useDocumentLibraryRoutes();
  const classes = useStyles();
  const containerRef = useRef(null);
  const printModeClasses = usePrintModeStyles();
  const [printModeContainerStyle, setPrintModeContainerStyle] = useState<any>();
  const [containerStyle, setContainerStyle] = useState<any>();


  useEffect(() => {

    const doc = new jsPDF("p", "mm", "a4");
    let width: any = doc.internal.pageSize.getWidth();
    let height: any = doc.internal.pageSize.getHeight();
    width = `${Math.floor(width)}mm`;
    height = `${Math.floor(height)}mm`;
    setPrintModeContainerStyle({
      margin: 'auto',
      minWidth: width,
      maxWidth: width,
      height: height
    });
    setContainerStyle({
      margin: 'auto',
      maxWidth: theme.breakpoints.values.lg,
    });
  }, []);

  useEffect(() => {
    setContainerRef(containerRef);
  }, [containerRef])

  if (!data) {
    return null;
  }

  return (
    <div ref={containerRef} style={printMode ? printModeContainerStyle : containerStyle}>
      <Page routeDefinition={documentLibraryRoutesConfig.documentLibraryDocument(contentId)}>
        <Box padding={'16px'} className={clsx(classes.documentContainer, printMode ? printModeClasses.documentContainer : undefined)}>
          {preview && <PreviewBackdrop />}
          <LoginLogoImg imgStyle={{ maxWidth: 200 }} />
          <Box my={2} textAlign='center'>
            <Typography variant={'h5'}>
              <p style={{ color: 'grey' }}>{data.documentTitle}</p>
            </Typography>
            <Typography variant={'h4'}>
              <strong>{data.residentialBridgeTitle}</strong>
            </Typography>
            <Typography style={{ marginTop: '10px' }} variant={'h4'}>
              <strong>{data.fixFlipRtlTitle}</strong>
            </Typography>
          </Box>

          <Box mb={2} paddingBottom="5%" overflow={'auto'}>
            <Grid container >
              <Grid item xs={12}>
                <DocumentCard
                  data={data.fixAndFlipRtlCardHeader}
                  paperClassName={classes.documentContainer}
                  paperBorder={PaperBorderType.NO_BORDER}
                />
                <DocumentCard
                  title={data.generalTitle}
                  data={data.fixAndFlipRtlGeneralCard}
                  paperClassName={classes.documentContainer}
                  paperBorder={PaperBorderType.NO_BORDER}
                />

                <DocumentCard
                  title={data.subjectSectionTitle}
                  data={data.fixAndFlipRtlSubjectSectionCard}
                  paperClassName={classes.documentContainer}
                  paperBorder={PaperBorderType.NO_BORDER}
                />

                <DocumentCard
                  title={data.reconciliationTitle}
                  data={data.fixAndFlipRtlReconciliationCard}
                  paperClassName={classes.documentContainer}
                  paperBorder={PaperBorderType.NO_BORDER}
                />

                <DocumentCard
                  title={data.thirdPartyReportersTitle}
                  data={data.fixAndFlipRtl3rdPartyReportersCard}
                  paperClassName={classes.documentContainer}
                  paperBorder={PaperBorderType.NO_BORDER}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Page>
      <Page routeDefinition={documentLibraryRoutesConfig.documentLibraryDocument(contentId)}>
        <Box padding={'16px'} className={clsx(classes.documentContainer, printMode ? printModeClasses.documentContainer : undefined)}>
          {preview && <PreviewBackdrop />}
          <LoginLogoImg imgStyle={{ maxWidth: 200 }} />
          <Box my={2} textAlign='center'>
            <Typography variant={'h5'}>
              <p style={{ color: 'grey' }}>{data.documentTitle}</p>
            </Typography>
            <Typography variant={'h4'}>
              <strong>{data.residentialBridgeTitle}</strong>
            </Typography>
            <Typography style={{ marginTop: '10px' }} variant={'h4'}>
              <strong>{data.groundUpConstructionTitle}</strong>
            </Typography>
          </Box>

          <Box mb={2} paddingBottom="5%" overflow={'auto'}>
            <Grid container >
              <Grid item xs={12}>
                <DocumentCard
                  data={data.groundUpConstructionCardHeader}
                  paperClassName={classes.documentContainer}
                  paperBorder={PaperBorderType.NO_BORDER}
                />
                <DocumentCard
                  title={data.generalTitle}
                  data={data.groundUpConstructionGeneralCard}
                  paperClassName={classes.documentContainer}
                  paperBorder={PaperBorderType.NO_BORDER}
                />

                <DocumentCard
                  title={data.subjectSectionTitle}
                  data={data.groundUpConstructionSubjectSectionCard}
                  paperClassName={classes.documentContainer}
                  paperBorder={PaperBorderType.NO_BORDER}
                />

                <DocumentCard
                  title={data.reconciliationTitle}
                  data={data.groundUpConstructionReconciliationCard}
                  paperClassName={classes.documentContainer}
                  paperBorder={PaperBorderType.NO_BORDER}
                />

                <DocumentCard
                  title={data.thirdPartyReportersTitle}
                  data={data.groundUpConstruction3rdPartyReportsCard}
                  paperClassName={classes.documentContainer}
                  paperBorder={PaperBorderType.NO_BORDER}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Page>

      <Page routeDefinition={documentLibraryRoutesConfig.documentLibraryDocument(contentId)}>
        <Box padding={'16px'} className={clsx(classes.documentContainer, printMode ? printModeClasses.documentContainer : undefined)}>
          {preview && <PreviewBackdrop />}
          <LoginLogoImg imgStyle={{ maxWidth: 200 }} />
          <Box my={2} textAlign='center'>
            <Typography variant={'h5'}>
              <p style={{ color: 'grey' }}>{data.documentTitle}</p>
            </Typography>
            <Typography variant={'h4'}>
              <strong>{data.residentialBridgeTitle}</strong>
            </Typography>
            <Typography style={{ marginTop: '10px' }} variant={'h4'}>
              <strong>{data.stabilizedBridgeTitle}</strong>
            </Typography>
          </Box>

          <Box mb={2} paddingBottom="5%" overflow={'auto'}>
            <Grid container >
              <Grid item xs={12}>
                <DocumentCard
                  data={data.stabilizedBridgeCardHeader}
                  paperClassName={classes.documentContainer}
                  paperBorder={PaperBorderType.NO_BORDER}
                />
                <DocumentCard
                  title={data.generalTitle}
                  data={data.stabilizedBridgeGeneralCard}
                  paperClassName={classes.documentContainer}
                  paperBorder={PaperBorderType.NO_BORDER}
                />

                <DocumentCard
                  title={data.subjectSectionTitle}
                  data={data.stabilizedBridgeSubjectSectionCard}
                  paperClassName={classes.documentContainer}
                  paperBorder={PaperBorderType.NO_BORDER}
                />

                <DocumentCard
                  title={data.reconciliationTitle}
                  data={data.stabilizedBridgeReconciliationCard}
                  paperClassName={classes.documentContainer}
                  paperBorder={PaperBorderType.NO_BORDER}
                />

                <DocumentCard
                  title={data.thirdPartyReportersTitle}
                  data={data.stabilizedBridge3rdPartyReportsCard}
                  paperClassName={classes.documentContainer}
                  paperBorder={PaperBorderType.NO_BORDER}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Page>

      <Page routeDefinition={documentLibraryRoutesConfig.documentLibraryDocument(contentId)}>
        <Box padding={'16px'} className={clsx(classes.documentContainer, printMode ? printModeClasses.documentContainer : undefined)}>
          {preview && <PreviewBackdrop />}
          <LoginLogoImg imgStyle={{ maxWidth: 200 }} />
          <Box my={2} textAlign='center'>
            <Typography variant={'h5'}>
              <p style={{ color: 'grey' }}>{data.documentTitle}</p>
            </Typography>
            <Typography style={{ marginTop: '10px' }} variant={'h4'}>
              <strong>{data.residentialTermTitle}</strong>
            </Typography>
          </Box>

          <Box mb={2} paddingBottom="5%" overflow={'auto'}>
            <Grid container >
              <Grid item xs={12}>
                <DocumentCard
                  data={data.residentialTermCardHeader}
                  paperClassName={classes.documentContainer}
                  paperBorder={PaperBorderType.NO_BORDER}
                />
                <DocumentCard
                  title={data.generalTitle}
                  data={data.residentialTermGeneralCard}
                  paperClassName={classes.documentContainer}
                  paperBorder={PaperBorderType.NO_BORDER}
                />

                <DocumentCard
                  title={data.subjectSectionTitle}
                  data={data.residentialTermSubjectSectionCard}
                  paperClassName={classes.documentContainer}
                  paperBorder={PaperBorderType.NO_BORDER}
                />

                <DocumentCard
                  title={data.reconciliationTitle}
                  data={data.residentialTermReconciliationCard}
                  paperClassName={classes.documentContainer}
                  paperBorder={PaperBorderType.NO_BORDER}
                />

                <DocumentCard
                  title={data.thirdPartyReportersTitle}
                  data={data.residentialTerm3rdPartyReportsCard}
                  paperClassName={classes.documentContainer}
                  paperBorder={PaperBorderType.NO_BORDER}
                />
              </Grid>
            </Grid>
          </Box>
          <Box m={1} position={'absolute'} right={0} bottom={0} textAlign='right' color={'#ccc'}>
            <Typography variant={'body2'}>
              {`Last Updated: ${format(new Date(data.lastUpdatedDate), 'MM-dd-yyyy')}`}
            </Typography>
          </Box>
        </Box>
      </Page>

      {!isEmptyString(data.multifamilyBridgeTitle) && <Page routeDefinition={documentLibraryRoutesConfig.documentLibraryDocument(contentId)}>
        <Box padding={'16px'} className={clsx(classes.documentContainer, printMode ? printModeClasses.documentContainer : undefined)}>
          {preview && <PreviewBackdrop />}
          <LoginLogoImg imgStyle={{ maxWidth: 200 }} />
          <Box my={2} textAlign='center'>
            <Typography variant={'h5'}>
              <p style={{ color: 'grey' }}>{data.documentTitle}</p>
            </Typography>
            <Typography style={{ marginTop: '10px' }} variant={'h4'}>
              <strong>{data.multifamilyBridgeTitle}</strong>
            </Typography>
          </Box>

          <Box mb={2} paddingBottom="5%" overflow={'auto'}>
            <Grid container >
              <Grid item xs={12}>
                <DocumentCard
                  data={data.multifamilyBridgeCardHeader}
                  paperClassName={classes.documentContainer}
                  paperBorder={PaperBorderType.NO_BORDER}
                />
                <DocumentCard
                  title={data.generalTitle}
                  data={data.multifamilyBridgeGeneralCard}
                  paperClassName={classes.documentContainer}
                  paperBorder={PaperBorderType.NO_BORDER}
                />

                <DocumentCard
                  title={data.subjectSectionTitle}
                  data={data.multifamilyBridgeSubjectSectionCard}
                  paperClassName={classes.documentContainer}
                  paperBorder={PaperBorderType.NO_BORDER}
                />

                <DocumentCard
                  title={data.reconciliationTitle}
                  data={data.multifamilyBridgeReconciliationCard}
                  paperClassName={classes.documentContainer}
                  paperBorder={PaperBorderType.NO_BORDER}
                />

                <DocumentCard
                  title={data.thirdPartyReportersTitle}
                  data={data.multifamilyBridge3rdPartyReportsCard}
                  paperClassName={classes.documentContainer}
                  paperBorder={PaperBorderType.NO_BORDER}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Page>}
    </div>
  );
}