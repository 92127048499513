import {
  Chip, createStyles, makeStyles, Theme
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chipManual: {
      backgroundColor: theme.palette.success.main,
      color: 'white',
      marginLeft: theme.spacing(2),
    },
    chipAutomated: {
      backgroundColor: theme.palette.warning.main,
      color: 'white',
      marginLeft: theme.spacing(2),
    },
  })
);

export const ManualOrAutomatedChip = ({ automated }) => {
  const classes = useStyles();
  return (
    <Chip
      size="small"
      className={automated ? classes.chipAutomated : classes.chipManual}
      label={automated ? 'Automated' : 'Manual'}
    />
  );
};
