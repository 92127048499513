export const INSPECTION_BY_APPRAISER = 'Appraiser';
export const INSPECTION_BY_BORROWER = 'Borrower';

export const inspectionByOptions = [
  { label: 'Appraiser', value: INSPECTION_BY_APPRAISER },
  { label: "Borrower/Borrower's Agent", value: INSPECTION_BY_BORROWER },
];

export const noAccessSources = [
  'REO',
  'Foreclosure Auction',
  "Sheriff's Sale",
  'Online Auction',
  'Bankruptcy Sale',
];
