import { Grid } from "@material-ui/core";
import { observer } from "mobx-react";
import { makeStyles } from '@material-ui/core/styles';
import { useDocumentStore } from "libs/feature-documents/src/documents/hooks/useDocumentStore";
import { Button } from "@roc/ui";

const useStyles = makeStyles({
  container: {
    paddingTop: 16
  },
});
export const ConfirmCloseButtons = observer(({ isInternal, hasConfirmButton, onConfirm = null, secondOrder = false, showCloseButton = true }) => {
  const classes = useStyles();

  const { documentStore } = useDocumentStore();
  const { documentFormStore } = documentStore;
  const { cdaAndAppraisalOrderDetailsStore } = documentFormStore;

  const { radioValue, secondRadioValue, isCdaRushed } = cdaAndAppraisalOrderDetailsStore;


  const handleClose = () => {
    if (isInternal) {
      window.parent.postMessage('close-modal', '*');
      window.parent.postMessage('clear-style', '*');
    } else {
      documentStore.documentFormStore.setCurrentForm(null);
    }
  };

  const getDisabledButton = () => {
    if (secondOrder) {
      return !secondRadioValue;
    }
    return radioValue === null;
  };

  const getSecondOrderRadioValue = () => {
    return cdaAndAppraisalOrderDetailsStore.loanStore.loanDetails?.cdaEnabled ? 'arr' : 'cda';
  }

  return (
    <Grid item container xs={11} justifyContent='flex-end' spacing={1} className={classes.container} direction='row'>
      {hasConfirmButton && <Grid item>
        <Button
          variant="contained"
          color="primary"
          onClick={() => onConfirm(secondOrder ? getSecondOrderRadioValue() : radioValue, isCdaRushed)}
          testId="cancel"
          disabled={getDisabledButton()}
        >
          Confirm
        </Button>
      </Grid>}

      {showCloseButton && <Grid item>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleClose}
          testId="close"
        >
          Close
        </Button>
      </Grid>}
    </Grid>
  )
});