import { Box, Divider, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { isNil, sanitizeTestId } from '@roc/feature-utils';
import { Card, CardProps, TestDataAttribute, Tooltip } from '@roc/ui';
import { formatCurrency, formatPercentage } from '@roc/ui/utils';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import React from 'react';
import { LoanDetailsPaper } from './loanDetailsPaper';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

import { Tooltip as MuiTooltip } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    border: 0,
    '& .MuiCardHeader-root': {
      padding: theme.spacing(2),
      paddingBottom: theme.spacing(2.5),
    },
    '& .MuiCardContent-root': {
      paddingTop: 0,
    },
  },
  cardWithoutHeader: {
    '& .MuiCardHeader-root': {
      padding: 0,
    },
    '& .MuiCardContent-root': {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(1),
    },
  }
}));

interface CustomCardProps extends Omit<CardProps, 'title'> {
  title?: string | React.ReactNode;
  paperStyle?: CSSProperties;
}

export const CustomCard = observer((props: CustomCardProps) => {
  const classes = useStyles();

  return (
    <LoanDetailsPaper style={props.paperStyle}>
      <Card
        className={clsx(
          classes.card,
          !props.title ? classes.cardWithoutHeader : ''
        )}
        title={props.title}
        {...props}
      />
    </LoanDetailsPaper>
  );
});

const useRowStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: theme.spacing(1.5, 0),
    display: 'flex',
    justifyContent: 'space-between',
  },
  label: {},
  value: {},
  valueBold: {
    fontWeight: 'bold',
  },
  tooltip: {
    whiteSpace: 'pre-line',
    fontSize: theme.typography.pxToRem(14),
    maxWidth: '75vw',
  }
}));

export const CustomCardRow = observer(
  ({
    label,
    value,
    bold = false,
    currency = false,
    customTestId = undefined,
    percentage = false,
    tooltip = undefined,
    tooltipIfValueOverflows = false
  }) => {
    const classes = useRowStyles();
    const testId = sanitizeTestId(customTestId || label);

    let displayValueTooltip = false;
    let valueToDisplay = value;
    if (tooltipIfValueOverflows && !isNil(value)) {
      if (value.length > 10) {
        valueToDisplay = value.substring(0, 10) + '...';
        displayValueTooltip = true;
      }
    }

    return (
      <Box className={classes.container}>
        <Typography className={classes.label}>
          {label}
          {!!tooltip && <Tooltip title={tooltip} />}
        </Typography>
        <TestDataAttribute id={testId}>
          {!displayValueTooltip ? (
            <Typography className={bold ? classes.valueBold : classes.value}>
              {isNil(valueToDisplay)
                ? '-'
                : currency
                  ? formatCurrency(valueToDisplay)
                  : percentage
                    ? formatPercentage(valueToDisplay)
                    : valueToDisplay}
            </Typography>) : (
            <MuiTooltip title={value} classes={{ tooltip: classes.tooltip }} placement="right">
              <Typography className={bold ? classes.valueBold : classes.value}>
                {isNil(valueToDisplay)
                  ? '-'
                  : currency
                    ? formatCurrency(valueToDisplay)
                    : percentage
                      ? formatPercentage(valueToDisplay)
                      : valueToDisplay}
              </Typography>
            </MuiTooltip>
          )}
        </TestDataAttribute>
      </Box>
    );
  }
);

const useDividerStyles = makeStyles((theme: Theme) => ({
  divider: {
    height: 0, //Divider looks inconsistent when using height
    borderBottom: '1px solid rgba(238, 238, 238, 1)',
  },
}));

export const CustomCardDividers = ({ children, ...props }) => {
  const classes = useDividerStyles();
  const divider = <Divider className={classes.divider} {...props} />;

  const map = children =>
    React.Children.map(children, (child, i) => (
      <>
        {i !== 0 && child && divider}
        {child?.type === React.Fragment ? map(child.props.children) : child}
      </>
    ));

  return map(children);
};
