import { Box, Grid } from '@material-ui/core';
import { observer } from 'mobx-react';
import { createNumberFormatField, createSelectField, createSliderField, createTextField, FieldLabel } from '@roc/ui';
import { makeStyles } from '@material-ui/core/styles'
import { estimatedFFExperienceOptions } from '@roc/feature-utils';

const useStyles = makeStyles(theme => ({
  slider: {
    paddingTop: '1px',
  },
}));


export const BorrowerFormBridge = observer(({ store, readOnly = false }) => {
  const classes = useStyles();
  return (
    <Grid container direction="row" spacing={2}>
      <Grid item xs={12}>
        {createTextField({
          store,
          testId: 'firstName',
          fieldName: 'firstName',
          label: 'First Name',
          disabled: readOnly,
        })}
      </Grid>
      <Grid item xs={12}>
        {createTextField({
          store,
          testId: 'lastName',
          fieldName: 'lastName',
          label: 'Last Name',
          disabled: readOnly,
        })}
      </Grid>
      <Grid item xs={12}>
        {createTextField({
          store,
          testId: 'emailAddress',
          fieldName: 'emailAddress',
          label: 'Email Address',
          disabled: readOnly,
        })}
      </Grid>
      <Grid item xs={12}>
        {createTextField({
          store,
          testId: 'confirmEmailAddress',
          fieldName: 'confirmEmailAddress',
          label: 'Confirm Email Address',
          disabled: readOnly,
        })}
      </Grid>
      <Grid item xs={12}>
        {createNumberFormatField({
          store,
          testId: 'cellPhone',
          fieldName: 'cellPhone',
          label: 'Phone Number',
          format: '###-###-####',
        })}
      </Grid>
      <Grid item xs={12}>
        {createSelectField({
          store,
          testId: 'experienceProvidedAtOrigination',
          fieldName: 'experienceProvidedAtOrigination',
          label: 'Estimated Experience',
          options: estimatedFFExperienceOptions,
        })}
      </Grid>
      <Grid item xs={12}>
        <Box
          display="flex"
          alignItems="center"
          style={{ height: '40px', marginBottom: '-16px' }}
        >
          <FieldLabel>Estimated FICO Score</FieldLabel>
        </Box>
        <Box style={{ paddingLeft: '8px' }}>
          {createSliderField({
            store,
            testId: 'ficoProvidedAtOrigination',
            fieldName: 'ficoProvidedAtOrigination',
            label: '',
            min: 350,
            max: 800,
            step: 10,
            marks: [
              {
                value: 350,
                label: '350',
              },
              {
                value: 800,
                label: '800',
              },
            ],
            valueLabelDisplay: 'on',
            className: classes.slider,
          })}
        </Box>
      </Grid>
    </Grid>
  );
});