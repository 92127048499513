import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Button, StandardDialog } from '@roc/ui';
import { createTextField } from '@roc/ui/componentBuilder';
import { Alert } from '@material-ui/lab';
import { useLoginStore } from '../../hooks/useLoginStore';
import { useAuthenticationStore } from '../../hooks/useAuthenticationStore';
import { useBaseStore } from '../../hooks/useBaseStore';
import { differenceInMilliseconds } from 'date-fns';

const useStyles = makeStyles(theme => ({
  gridContainer: {
    maxWidth: 500,
    margin: 'auto',
  },
}));

export const TimeoutLoginButtonModal = observer(({ open, msg, handleLogin }) => {
  const classes = useStyles();
  return (
    <StandardDialog
      open={open}
      title="Session Expired"
      maxWidth="md"
      BackdropProps={{
        style: {
          backgroundColor: 'black',
          opacity: .75,
        }
      }}
      dialogContent={
        <Grid
          container
          spacing={2}
          justifyContent="center"
          className={classes.gridContainer}
        >
          <Grid item xs={12}>
            <Alert severity="error">
              {msg}
            </Alert>
            <br />
          </Grid>
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <Button
              size="large"
              variant="contained"
              color="primary"
              type="submit"
              onClick={handleLogin}
              testId="submit"
            >
              <span>LOG IN</span>
            </Button>
          </Grid>
        </Grid>
      }
    />
  );
});

export const TimeoutLoginModal = observer(({ open, msg }) => {
  const classes = useStyles();
  const { loginStore } = useLoginStore();
  const { authenticationStore } = useAuthenticationStore();

  useEffect(() => {
    if (open) {
      loginStore.reset();
    }
  }, [open]);

  const handleFormSubmit = () => {
    loginStore.onFormSubmit((oauthResponse) => {
      authenticationStore.doPostSuccessfulAuthentication(oauthResponse);
    });
  };

  const keyDownHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleFormSubmit();
    }
  };

  return (
    <StandardDialog
      open={open}
      title="Session Expired"
      maxWidth="md"
      BackdropProps={{
        style: {
          backgroundColor: 'black',
          opacity: .75,
        }
      }}
      dialogContent={
        <Grid
          container
          spacing={2}
          justifyContent="center"
          className={classes.gridContainer}
        >
          <Grid item xs={12}>
            <Alert severity="error">
              {msg}
            </Alert>
            <br />
          </Grid>
          <Grid item xs={12}>
            {createTextField({
              label: 'Email',
              type: 'email',
              fieldName: 'username',
              testId: 'email',
              store: loginStore,
              standaloneLabel: false,
              onKeyDown: keyDownHandler
            })}
          </Grid>
          <Grid item xs={12}>
            {createTextField({
              label: 'Password',
              type: 'password',
              fieldName: 'password',
              testId: 'password',
              store: loginStore,
              standaloneLabel: false,
              onKeyDown: keyDownHandler
            })}
          </Grid>
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <Button
              size="large"
              variant="contained"
              color="primary"
              type="submit"
              onClick={handleFormSubmit}
              testId="submit"
            >
              <span>LOG IN</span>
            </Button>
          </Grid>
        </Grid>
      }
    />
  );
});

export const SessionTimeout = observer(() => {
  const [timeoutId, setTimeoutId] = useState<
    ReturnType<typeof setTimeout> | undefined
  >();
  const [sessionTimedout, setSessionTimedout] = useState(false);
  const { globalStore } = useBaseStore();
  const { sessionExpiresAt, refreshTokenExpired } = globalStore;

  useEffect(() => {
    if (sessionExpiresAt) {
      clearTimeout(timeoutId);
      const _expireAt = differenceInMilliseconds(sessionExpiresAt, new Date());
      console.log("Session will expire in: " + _expireAt + " milliseconds");
      const _timeoutId = setTimeout(() => {
        if (new Date() > sessionExpiresAt) {
          console.log("Session Expired.");
          setSessionTimedout(true);
          clearTimeout(_timeoutId);
        }
      }, _expireAt);
      setTimeoutId(_timeoutId);
    }
    return () => clearTimeout(timeoutId);
  }, [sessionExpiresAt]);

  // if refreshTokenExpired, no need to check for session timeout so clear it
  useEffect(() => {
    if (refreshTokenExpired) {
      clearTimeout(timeoutId);
      setSessionTimedout(false);
    }
  }, [refreshTokenExpired]);

  return <TimeoutLoginModal open={sessionTimedout} msg="Your session has expired. Please login again to continue." />;
});
