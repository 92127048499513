import {
  Badge,
  Box,
  Chip,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { ImportExport, MoreVert, PriorityHigh } from '@material-ui/icons';
import {
  GridStore,
  InfiniteScrollGrid
} from '@roc/feature-app-core';
import { PORTAL_COLORS } from '@roc/feature-utils';
import { LinkColumn, SelectFilterComponent,
  SelectFloatingFilterComponent, StatusChip,
  StatusType, SubmitMenuItem, TextColumn, FastForwardIcon, tooltipHeaderTemplate
} from '@roc/ui';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { ChangeLoanStatusForm } from 'libs/feature-loans/src/loans/components/changeLoanStatusForm';
import { useUserStore } from '@roc/feature-app-core';
import { formatCurrency } from '@roc/ui/utils';
import { GridColumnProps } from '@roc/client-portal-shared/components';
import { tooltips } from '@roc/feature-loans';
import { useLoanStore } from '@roc/feature-loans';
import { useRoutes } from '../../hooks/useRoutes';
import { Utility } from '@roc/feature-utils';
import { useProposalsRoutes } from '@roc/feature-proposals';

const usdAmount: any = params => {
  return params.value && formatCurrency(Number(params.value));
};
const noOpComparator = () => 0;

export const StyledBadge = withStyles(theme => ({
  badge: {
    right: -12,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
    fontSize: '11px',
    backgroundColor: PORTAL_COLORS.unreadMessages,
    color: 'white',
    fontWeight: 700,
    userSelect: 'none',
  },
}))(Badge);
export const getUnreadMessagesCount = unreadMessages => {
  const { userStore } = useUserStore();
  const allowLoanPricing = userStore.allowLoanPricing;
  if (unreadMessages && allowLoanPricing) {
    return unreadMessages > 99 ? '99+' : unreadMessages;
  }
  return undefined;
};


export interface MyLoansInBackOfficeGridProps {
  store: GridStore;
  openMenu?: (event: React.MouseEvent<HTMLElement>, record: any) => void;
}

export const MyLoansInBackOfficeViewGrid = observer((props: MyLoansInBackOfficeGridProps) => {
  const { loanStore } = useLoanStore();
  const { loanRoutesConfig } = useRoutes();
  const { proposalsRoutesConfig } = useProposalsRoutes();
  const [loanId, setLoanId] = useState(0);
  const [anchorEl, setAnchorEl] = useState<HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const { userStore } = useUserStore();
  const allowLoanPricing = userStore.allowLoanPricing;
  const frameworkComponents = {
    selectFilter: SelectFilterComponent,
    selectFloatingFilter: SelectFloatingFilterComponent,
    loanIdCellRenderer: params =>
      loanStore.isLoanSubtypeSupported(params.node.data?.loanSubType) ? (
        <StyledBadge
          badgeContent={getUnreadMessagesCount(
            params.node.data?.unreadMessages
          )}
        >
          {LinkColumn({
            ...params,
            url: getUrl(params.node.data),
          })}
        </StyledBadge>
      ) : (
        <StyledBadge
          badgeContent={getUnreadMessagesCount(
            params.node.data?.unreadMessages
          )}
        >
          {TextColumn(params)}
        </StyledBadge>
      ),
    dealNameCellRenderer: params => {
      const loanId = params.node.data?.dealid;
      return LinkColumn({
        ...params,
        url: loanRoutesConfig.loans(loanId).children.dashboard.url,
      });
    },
    fastTrackCellRenderer: ({ value }) => {
      if (!value) {
        return null;
      }
      return (<FastForwardIcon value={value} />)
    },
    actionCellRenderer: params => {
      if (loanStore.isLoanStatusRetractedOrOnHold(params.node.data?.status)) {
        return (
          <IconButton onClick={e => openMenu(e, params.node.data)}>
            <MoreVert color="primary" />
          </IconButton>
        );
      }
      return null;
    },
    statusCellRenderer: ({ value }) =>
      value ? (
        <StatusChip statusType={StatusType.LOAN} label={value} size="small" />
      ) : null,
  };

  useEffect(() => {
    props.store.resetAndFetchGridData();
  }, [loanStore.selectedTabIndex]);

  const openMenu = (event: React.MouseEvent<HTMLElement>, record) => {
    const {
      dealid,
      loanSubType,
    } = record;
    setLoanId(dealid);
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const checkLoanPricingFlag = (columns) => {
    if (!allowLoanPricing) {
      return columns.filter(c => c.field != "interestEconomics" && c.field != "keyRatios");
    } else {
      return columns;
    }
  }

  const isDealRoom = (data) => {
    return Utility.PROPOSAL_INFORMATION === data?.utility;
  }

  const getUrl = (row) => {
    const loanId = row?.dealid;
    return !isDealRoom(row)
      ? loanRoutesConfig.loans(loanId).children.dashboard.url
      : proposalsRoutesConfig.viewProposal(loanId).url;
  }


  const columns: GridColumnProps[] = [
    {
      field: 'dealid',
      headerName: 'Loan Id',
      minWidth: 100,
      cellRenderer: 'loanIdCellRenderer',
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      pinned: 'left',
      lockPinned: true,
    },
    {
      field: 'dealName',
      headerName: 'Deal Name',
      minWidth: 275,
      cellRenderer: 'dealNameCellRenderer',
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      minWidth: 120,
      cellRenderer: 'statusCellRenderer',
      comparator: noOpComparator,
      filter: 'selectFilter',
      floatingFilter: true,
      floatingFilterComponent: 'selectFloatingFilter',
      floatingFilterComponentParams: {
        suppressFilterButton: true,
        options: [
          { label: 'Declined', value: 'DECLINED' },
          { label: 'Funded', value: 'FUNDED' },
          { label: 'Lost', value: 'LOST' },
          { label: 'On Hold', value: 'ON_HOLD' },
          { label: 'Paid Off', value: 'PAID_OFF' },
          { label: 'Paid Off Buyout', value: 'PAID_OFF_BUYOUT' },
          { label: 'Retracted', value: 'RETRACTED' },
          { label: 'Unknown', value: 'UNKNOWN' },
        ],
      },
    },
    {
      field: 'loanSubType',
      headerName: 'Loan Type',
      minWidth: 200,
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    {
      field: 'amount',
      headerName: 'Amount',
      minWidth: 130,
      valueFormatter: usdAmount,
      comparator: noOpComparator,
    },
    {
      field: 'lender',
      headerName: 'Lender',
      minWidth: 200,
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    {
      field: 'fastrackPaymentComplete',
      headerName: 'Fast Tracked Loan',
      minWidth: 125,
      cellRenderer: 'fastTrackCellRenderer',
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    {
      field: 'submissionDate',
      headerName: 'Submission Date',
      minWidth: 185,
      comparator: noOpComparator,
      filter: 'agDateColumnFilter',
      floatingFilter: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    {
      field: 'closingDate',
      headerName: 'Closing Date',
      minWidth: 185,
      comparator: noOpComparator,
      filter: 'agDateColumnFilter',
      floatingFilter: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    {
      field: 'borrowerEntity',
      headerName: 'Borrower Entity',
      minWidth: 135,
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    {
      field: 'interestEconomics',
      headerName: 'Interest Economics',
      minWidth: 200,
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      headerTooltip: tooltips.interestEconomics,
      headerComponentParams: {
        template: tooltipHeaderTemplate,
      },
    },
    {
      field: 'keyRatios',
      headerName: 'Key Ratios',
      minWidth: 200,
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      headerTooltip: tooltips.keyRatios,
      headerComponentParams: {
        template: tooltipHeaderTemplate,
      },
    },
    {
      field: 'payoffDate',
      headerName: 'Payoff Date',
      minWidth: 125,
      comparator: noOpComparator,
      filter: 'agDateColumnFilter',
      floatingFilter: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    {
      field: 'submittedBy',
      headerName: 'Submitted By',
      minWidth: 300,
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    {
      field: 'coordinator',
      headerName: 'Processor',
      minWidth: 300,
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    {
      field: 'underwriter',
      headerName: 'Underwriter',
      minWidth: 300,
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    {
      field: 'lenderOriginatorName',
      headerName: 'Originator',
      minWidth: 300,
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    {
      headerName: 'Action',
      minWidth: 80,
      cellRenderer: 'actionCellRenderer',
      comparator: noOpComparator,
      width: 80,
      pinned: 'right',
      lockPinned: true,
      field: 'dealid'
    },
  ];

  return (
    <>
      <InfiniteScrollGrid
        columns={checkLoanPricingFlag(columns)}
        frameworkComponents={frameworkComponents}
        store={props.store}
      />
      <Menu
        id="menu-appbar"
        getContentAnchorEl={null}
        anchorEl={anchorEl}
        keepMounted
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={menuOpen}
        onClose={handleMenuClose}
      >
      <SubmitMenuItem
        testId={`change-loan-status-${loanId}`}
        onClick={() => {
          loanStore.submitFormStatus(loanId);
          handleMenuClose();
        }}
        submitDialogProps={{
          title: `Change Status for Loan ${loanId}`,
          body: <ChangeLoanStatusForm loanId={loanId} />,
          okButtonText: 'Submit',
          submitValidation: () => {
            loanStore.loanStatusStore.runFormValidation();
            return loanStore.loanStatusStore.form.meta.isValid;
          },
        }}
        >
          <ListItemIcon>
            <ImportExport fontSize="small" />
          </ListItemIcon>
          <ListItemText>Change Status</ListItemText>
        </SubmitMenuItem>
      </Menu>
    </>
  );
});