import { observer } from "mobx-react"
import { Layout } from "@roc/feature-app-core";
import { DataGrid, AgGridColumnProps, SelectMode } from "@roc/ui";
import { useEffect, useRef, useState } from "react";
import { useStore } from "@roc/client-portal-shared/hooks";
import { Typography } from "@material-ui/core";
import { LinkColumn } from "@roc/ui";
import { useNewLeadStore } from "../../hooks/useNewLeadStore";
import { StatusChip, StatusType } from "@roc/ui";
import { Box, Grid, ListItemIcon, ListItemText, Menu, MenuItem, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Roles } from '@roc/feature-utils';
import { Button, ConfirmDialog, StandardDialog, Toolbar } from '@roc/ui';
import { useCRMPortalStore } from 'apps/crm-portal/src/app/hooks/useCRMPortalStore';
import { useCreateListStore } from 'apps/crm-portal/src/app/modules/createList/hooks/useCreateListStore';
import { SendBulkMessagesModal } from 'apps/crm-portal/src/app/modules/ringCentral/sendBulkMessages';
import { Add, ArrowDropDown, CopyrightOutlined, Edit, FileCopy, ScreenShare, Star } from '@material-ui/icons';
import { SendBulkEmailsModal } from "../leadsDashboard/sendBulkEmails";
import { PhoneBurnerModal } from "apps/crm-portal/src/app/modules/phoneBurner/phoneBurnerModal";
import { BORROWER_OBJECT_TYPE, BROKER_OBJECT_TYPE, LEAD_OBJECT_TYPE, MIXED_OBJECT_TYPE, TPO_OBJECT_TYPE } from "../leads/constants/constants";



const columns: AgGridColumnProps[] = [
  {
    field: 'leadName',
    headerName: 'Record Name',
    minWidth: 160,
    maxWidth: 250,
    cellRenderer: 'nameRenderer',
    comparator: () => 0,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'title',
    headerName: 'Title',
    minWidth: 60,
    comparator: () => 0,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'status',
    headerName: 'Status',
    minWidth: 160,
    maxWidth: 250,
    cellRenderer: 'statusCellRenderer',
    comparator: () => 0,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'dueDate',
    headerName: 'Due Date',
    minWidth: 160,
    maxWidth: 250,
    comparator: () => 0,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'createdDate',
    headerName: 'Created On',
    minWidth: 160,
    maxWidth: 250,
    comparator: () => 0,
    filter: 'agTextColumnFilter',
    cellRenderer: 'contentRenderer',
    floatingFilter: true,
    hide: false,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'lastUpdatedDate',
    headerName: 'Last Updated On',
    minWidth: 160,
    maxWidth: 250,
    comparator: () => 0,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  }
];

const frameworkComponents = {
  nameRenderer: (params) => {
    const url = getRouteURL(params);
    return LinkColumn({
      value: params.node.data?.leadName,
      url: url,
      ...params
    })
  },
  statusCellRenderer: (params) =>
    <StatusChip
      statusType={StatusType.GENERAL_TASK_STATUS}
      label={params.value}
      size="small"
    />
};

const getRouteURL = (params) => {
  if (params.node.data?.leadId) {
    return `lead-details/${params.node.data?.leadId}`;
  } else if (params.node.data?.borrowerId) {
    return `borrower-details/${params.node.data?.borrowerId}`;
  } else if (params.node.data?.companyContactId) {
    return `lender-contact-details/${params.node.data?.companyContactId}`;
  }
}

export const CRMTaskDashboard = observer(() => {
  const { crmTaskStore } = useStore();
  const { newLeadStore } = useNewLeadStore();
  const anchorRef = useRef(null);
  const [showBulkActionsMenu, setShowBulkActionsMenu] = useState(false);
  const { globalStore } = useCRMPortalStore();
  const [smsRecordList, setSmsRecordList] = useState(0);
  const [emailRecordList, setEmailRecordList] = useState(0);

  const { salesforceLeadStore, userStore } = useStore();
  const [openSendBulkMessagesModal, setOpenSendBulkMessagesModal] = useState(false);
  const [openPhoneBurnerModal, setOpenPhoneBurnerModal] = useState(false);
  const [openCreateTemplateModal, setOpenCreateTemplateModal] = useState(false);
  const { createListStore } = useCreateListStore();
  const { createListFormStore } = createListStore;
  const { sendEmailsStore, ringCentralStore } = salesforceLeadStore;
  const [openSendBulkEmailModal, setOpenSendBulkEmailModal] = useState(false);
  const { phoneBurnerStore } = useStore();
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [openBulkLeadChangeStatusModal, setOpenBulkLeadChangeStatusModal] = useState(false);
  const [openBulkLeadChangeOwnerModal, setOpenBulkLeadChangeOwnerModal] = useState(false);
  const { leadsDashboardStore } = salesforceLeadStore;
  const dashboardType = MIXED_OBJECT_TYPE;

  const onSortChanged = params => {
    const sortModel = params.api.getSortModel();
    crmTaskStore.crmTaskGridStore.setSortModel(sortModel);
  };

  const onFilterChanged = params => {
    const filterModel = params.api.getFilterModel();
    crmTaskStore.crmTaskGridStore.setFilterModel(filterModel);
  };

  useEffect(() => {
    newLeadStore.crmTaskDashboard = true;
    crmTaskStore.crmTaskGridStore.fetchGridData();
  }, []);


  useEffect(() => {
    setSmsRecordList(ringCentralStore.getRecordList(MIXED_OBJECT_TYPE)?.length);
  }, [ringCentralStore.leads, ringCentralStore.borrowers, ringCentralStore.companyContacts, ringCentralStore.mixedRecords]);

  useEffect(() => {
    setEmailRecordList(sendEmailsStore.bulkEmails?.length);
  }, [sendEmailsStore.bulkEmails]);


  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setShowBulkActionsMenu(true);
  };

  const handleClose = () => {
    setShowBulkActionsMenu(false);
  };

  const handleSendBulkMessages = () => {
    setShowBulkActionsMenu(false);
    onSendBulkMessages();
  }

  const handleSendBulkEmail = () => {
    setShowBulkActionsMenu(false);
    onSendBulkEmail();
  }

  const onSendBulkMessages = () => {
    setOpenSendBulkMessagesModal(true);
    ringCentralStore.setRecordType('TPO');
  }

  const onSendBulkEmail = () => {
    setOpenSendBulkEmailModal(true);
  }

  const onOpenPhoneBurnerModal = () => {
    setOpenPhoneBurnerModal(true);
  }


  const closeBulkSMSModal = () => {
    setOpenSendBulkMessagesModal(false);
  }

  const closePhoneBurnerModal = () => {
    setOpenPhoneBurnerModal(false);
  }

  const closeBulkChangeOwnerModal = () => {
    setOpenBulkLeadChangeOwnerModal(false);
  }

  const closeBulkEmailModal = () => {
    setOpenSendBulkEmailModal(false);
    sendEmailsStore.handleModalClose();
  }

  const handleStartDialSession = () => {
    if (globalStore.userFeatures?.connectedWithPhoneBurner) {
      if (phoneBurnerStore.records && phoneBurnerStore.records.length > 0) {
        setShowBulkActionsMenu(false);
        setIsConfirmOpen(true);
      } else {
        salesforceLeadStore.globalStore.notificationStore.showErrorNotification({
          message: 'Please select at least 1 lead'
        });
      }
    } else {
      onOpenPhoneBurnerModal()
    }
  }

  function handleConfirm() {
    phoneBurnerStore.startDialSession()
    setIsConfirmOpen(false);
  }


  const closeModal = () => {
    setOpenSendBulkMessagesModal(false);
    setOpenCreateTemplateModal(false);
    setOpenBulkLeadChangeStatusModal(false);
    setOpenBulkLeadChangeOwnerModal(false);
    createListFormStore.reset();
    createListStore.removeAllFilters();
    ringCentralStore.setMessage('');
    ringCentralStore.setLabel('');
    ringCentralStore.setTemplateValue('');
    ringCentralStore.setTemplateId('');
    ringCentralStore.setTemplateLabel('');
    ringCentralStore.sendBulkSMSFormStore.onFieldChange('message', '');
    salesforceLeadStore.setLeadStatus('');
    salesforceLeadStore.setNewLeadOwner('');
    salesforceLeadStore.resetLeadsIds();
    salesforceLeadStore.resetBorrowerIds();
    salesforceLeadStore.resetCompanyContactIds();
    ringCentralStore.resetLists();
    sendEmailsStore.resetbulkEmails();
  }

  function onRowSelected(params) {
    setRowIds(params);
    updateRingCentralAndEmailsLists(dashboardType);
  }

  function setRowIds(params) {
    if (params?.data.leadId) {
      crmTaskStore.setRecordIds(params?.data.leadId, params?.node.selected);
    } else if (params?.data.borrowerId) {
      crmTaskStore.setRecordIds(params?.data.contactId, params?.node.selected);
    } else if (params?.data.companyContactId) {
      crmTaskStore.setRecordIds(params?.data.contactId, params?.node.selected);
    }
  }

  function getCurrentDashboardSelectedRow(dashboardType) {
    if (dashboardType === MIXED_OBJECT_TYPE) {
      return crmTaskStore.selectedMixedRecords;
    }
    if (dashboardType === BORROWER_OBJECT_TYPE) {
      return salesforceLeadStore.selectedBorrowerRecords;
    } else if (dashboardType === TPO_OBJECT_TYPE || dashboardType === BROKER_OBJECT_TYPE) {
      return salesforceLeadStore.selectedCompanyContactRecords;
    } else {
      return salesforceLeadStore.selectedLeadRecords;
    }
  }


  function updateRingCentralAndEmailsLists(dashboardType) {
    const selectedRecords = getCurrentDashboardSelectedRow(dashboardType);
    ringCentralStore.setMixedRecordsFromGrid(selectedRecords, dashboardType);
    sendEmailsStore.setBulkEmailsFromMixedGrid(selectedRecords);
    phoneBurnerStore.setRecordsFromGrid(selectedRecords, dashboardType);
  }

  return (
    <Layout>
      <Typography variant='h4' style={{ padding: 8 }}>
        My CRM Tasks
      </Typography>
      <Toolbar>
        <Grid container spacing={2}>
          <Grid item>
            <div ref={anchorRef} style={{ display: 'inline-block' }}>
              <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                testId="open_menu"
                variant="contained"
                size="small"
                color="primary"
              >
                Bulk Actions <ArrowDropDown style={{ marginRight: '-6px' }} />
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorRef.current}
                open={showBulkActionsMenu}
                onClose={handleClose}
                keepMounted={true}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                getContentAnchorEl={null}
              >
                {smsRecordList != 0 && <MenuItem onClick={handleSendBulkMessages}>Bulk SMS - {smsRecordList}</MenuItem>}
                {emailRecordList != 0 && <MenuItem onClick={handleSendBulkEmail}>Bulk Email - {emailRecordList}</MenuItem>}
                <MenuItem onClick={handleStartDialSession}> Start Dial Session</MenuItem>
              </Menu>
            </div>
          </Grid>
        </Grid>
      </Toolbar>
      <DataGrid
        frameworkComponents={frameworkComponents}
        columns={columns}
        rows={crmTaskStore.crmTaskGridStore.gridData.data.rows}
        onSortChanged={onSortChanged}
        onFilterChanged={onFilterChanged}
        isLoading={crmTaskStore.crmTaskGridStore.isLoading}
        showFilters={crmTaskStore.crmTaskGridStore.showFilters}
        sortModel={crmTaskStore.crmTaskGridStore.sortModel}
        filterModel={crmTaskStore.crmTaskGridStore.filterModel}
        paginationData={crmTaskStore.crmTaskGridStore.gridData.meta}
        setPageNumber={crmTaskStore.crmTaskGridStore.setPageNumber}
        selectMode={SelectMode.MULTIPLE}
        onRowSelected={e => onRowSelected(e)}
      />
      <ConfirmDialog
        open={isConfirmOpen}
        body={'You will be redirected to PhoneBurner to start the dial session'}
        handleCancel={() => setIsConfirmOpen(false)}
        handleClose={() => setIsConfirmOpen(false)}
        handleConfirm={handleConfirm}
        confirmButtonText={'Continue'}
        cancelButtonText={'Cancel'}
      />
      <StandardDialog open={openSendBulkMessagesModal} title={"Send Bulk SMS"} maxWidth="md" handleClose={closeBulkSMSModal} dialogContent={<SendBulkMessagesModal handleClose={closeModal} salesforceLeadStore={salesforceLeadStore} type={dashboardType} />} />
      <StandardDialog open={openSendBulkEmailModal} title={"Send Bulk Emails"} maxWidth="xl" handleClose={closeBulkEmailModal} dialogContent={<SendBulkEmailsModal handleClose={closeBulkEmailModal} salesforceLeadStore={salesforceLeadStore} type={dashboardType} />} />
      <StandardDialog open={openPhoneBurnerModal} title={"Connect with PhoneBurner"} maxWidth="md" handleClose={closePhoneBurnerModal} dialogContent={<PhoneBurnerModal handleClose={closeModal} />} />
    </Layout>);
})