import { Service } from '@roc/feature-app-core';

const url = {
  GET_WIMBA_TITLE_COMPANY: '/api/v1/document/formDocument/getWimbaTitleCompany',
  UPDATE_TITLE_AGENT_FORM: '/api/v1/document/formDocument/updateTitleAgentForm',
};

export class TitleAgentService extends Service {
  getWimbaTitleCompany = () => {
    return this.get(url.GET_WIMBA_TITLE_COMPANY);
  };

  updateTitleAgentForm = payload => {
    return this.put(url.UPDATE_TITLE_AGENT_FORM, payload);
  };
}
