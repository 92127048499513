import { GlobalStore, GridStore } from '@roc/feature-app-core';
import { makeObservable, observable, action, flow } from 'mobx';
import { MarketingService } from '../services/marketingService';


export class MarketingPromotionsStore {
  globalStore: GlobalStore;
  marketingPromotionsGridStore: GridStore;
  marketingService: MarketingService;
  selectedPromotion: number;
  showPromotions: boolean;
  constructor(globalStore: GlobalStore) {
    this.globalStore = globalStore;
    this.marketingPromotionsGridStore = new GridStore(() => this.fetchApplicableMarketingPromotionsByUser(this.globalStore.lenderInfo.isInternal));
    this.marketingService = new MarketingService();
    makeObservable(this, {
      fetchApplicableMarketingPromotionsByUser: action,
      selectedPromotion: observable,
      setSelectedPromotion: action,
      fetchGridData: flow,
      showPromotions: observable
    });
  }

  fetchApplicableMarketingPromotionsByUser = async (isInternalTPO: boolean) => {
    try {
      const response = await this.marketingService.getMyAplicableMarketingPromotionsGrid(
        this.marketingPromotionsGridStore.gridData.meta.pageNumber,
        this.marketingPromotionsGridStore.gridData.meta.pageSize,
        this.marketingPromotionsGridStore.gridData.meta.sortDir,
        this.marketingPromotionsGridStore.gridData.meta.sortBy,
        this.marketingPromotionsGridStore.gridData.meta.filters,
        isInternalTPO
      );
      return response;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: `Error while retrieving the list of applicable promotions`,
      });
      throw error;
    }
  };

  setSelectedPromotion(promotionId: number) {
    this.selectedPromotion = promotionId;
  }

  *fetchGridData() {
    yield this.marketingPromotionsGridStore.fetchGridData();
    this.showPromotions = this.marketingPromotionsGridStore.gridData.meta.totalRecords > 0;
  }
}
