import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import {
  AssignmentTurnedIn, Business,
  Description, Person,
  Room, LocalOffer
} from '@material-ui/icons';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { BrokerInformation, LoanSubmissionStep, useLoanSubmissionRoutes, useSinglePropertyStore } from '../../../../../../../feature-loans/src';
import { useBaseStore, useUserStore } from '../../../../../../../feature-app-core/src';
import { PURCHASE, insertIf } from '../../../../../../../feature-utils/src';
import { Layout } from '../../../../../../../client-portal-shared/src/app/components';
import { Paper, StepsContainer } from '../../../../../../../ui/src/lib/components';
import PricerSummaryTerm_new from '../../../../../../../feature-loans/src/loanSubmission/components/pricerSummaryTerm_new';
import EntityInformation from '../../../../../../../feature-loans/src/loanSubmission/components/entityInformation';
import BorrowerInformationTerm from '../../../../../../../feature-loans/src/loanSubmission/components/borrowerInformationTerm';
import { PropertyInformationSingleProperty } from '../../../../../../../feature-loans/src/loanSubmission/singleProperty/components/propertyInformationSingleProperty';
import LoanDetailsTerm from '../../../../../../../feature-loans/src/loanSubmission/components/loanDetailsTerm';
import LoanSummary from '../../../../../../../feature-loans/src/loanSubmission/components/loanSummary';
import LoanPricerSingleProperty from '../../../../../../../feature-loans/src/loanSubmission/singleProperty/components/loanPricerSingleProperty';
import { useRoutes } from '@roc/client-portal-shared/hooks';
import { SelectPromotionGrid } from '@roc/feature-loans';

const useStyles = makeStyles(theme =>
  createStyles({
    paper: {
      padding: theme.spacing(4),
    },
  })
);

export const EditSingleProperty = observer(() => {
  const { singlePropertyStore } = useSinglePropertyStore();
  const { loanId } = useParams<{ loanId: string }>();
  const classes = useStyles();
  const { push } = useHistory();
  const { loanRoutesConfig } = useRoutes();
  const { globalStore } = useBaseStore();
  const { userStore } = useUserStore();
  const allowLoanPricing = userStore.allowLoanPricing;
  const isBroker = globalStore?.userFeatures?.isBroker;
  const showBrokerInformation = !isBroker;
  const { marketingPromotionsStore } = singlePropertyStore;


  useEffect(() => {
    singlePropertyStore.loadStore(loanId);
    singlePropertyStore.selectBorrowersStore.setAllowMultiple(false);
    marketingPromotionsStore.fetchGridData();
  }, [loanId]);

  useEffect(() => {
    singlePropertyStore.loadStore();
  }, [singlePropertyStore]);

  const onSubmit = () => {
    const isAllStepsValid = singlePropertyStore.allErrors.length === 0;
    if (singlePropertyStore.allErrors.length > 0) {
      globalStore.notificationStore.showErrorNotification({
        message: 'Please correct the errors listed above',
      });
    }

    if (isAllStepsValid) {
      if (singlePropertyStore.pricerStore.form.fields['isPropertyLeased'].value === 'N' &&
        singlePropertyStore.pricerStore.form.fields['loanPurpose'].value === PURCHASE)
        singlePropertyStore.pricerStore.onFieldChange('monthlyGrossRent', Number(singlePropertyStore.pricerStore.form.fields['monthlyGrossRent'].value) * 0.9);
      singlePropertyStore.onEditLoanSubmit(() => push(loanRoutesConfig.loans(singlePropertyStore.editLoanJson.loanId).children.dashboard.url));
    }
  };

  const onReprice = () => {
    singlePropertyStore.onReprice();
  };

  const goNextStep = () => {
    const currentStep = loanSubmissionSteps[singlePropertyStore.activeStep].type;
    singlePropertyStore.goNextStep(currentStep);
  }

  const goPrevStep = () => {
    const currentStep = loanSubmissionSteps[singlePropertyStore.activeStep].type;
    singlePropertyStore.goPrevStep(currentStep);
  }

  const moveToStep = (stepIndex) => {
    const currentStep = loanSubmissionSteps[singlePropertyStore.activeStep].type;
    singlePropertyStore.moveToStep(stepIndex, currentStep);
  }

  const setPromotion = (promotionId: number) => {
    singlePropertyStore.loanDetailsStore.onFieldChange('marketingPromotionId', promotionId);
  }

  const loanSubmissionSteps = [
    {
      title: 'Loan Pricer',
      icon: <Room color="primary" fontSize="large" />,
      type: LoanSubmissionStep.LOAN_PRICER,
      onBack: () => push(loanRoutesConfig.loans(singlePropertyStore.editLoanJson.loanId).children.dashboard.url),
      onNext: () => goNextStep(),
      allowBack: true,
      allowNext: true,
      allowReprice: false,
    },
    ...insertIf(isBroker || allowLoanPricing,
      [
        {
          title: 'Loan Pricer Summary',
          icon: <Description color="primary" fontSize="large" />,
          type: LoanSubmissionStep.LOAN_PRICER_SUMMARY,
          onBack: () => goPrevStep(),
          onNext: () => goNextStep(),
          allowBack: true,
          allowNext: false,
          allowReprice: false,
        },
      ]
    ),
    ...insertIf(showBrokerInformation, [{
      title: 'Broker Information',
      icon: <Person color="primary" fontSize="large" />,
      type: LoanSubmissionStep.BROKER_INFORMATION,
      onBack: () => goPrevStep(),
      onNext: () => goNextStep(),
      onReprice: () => onReprice(),
      allowBack: true,
      allowNext: true,
      allowReprice: true,
    }]),
    {
      title: 'Entity Information',
      icon: <Business color="primary" fontSize="large" />,
      type: LoanSubmissionStep.ENTITY_INFORMATION,
      onBack: () => goPrevStep(),
      onNext: () => {
        goNextStep();
      },
      onReprice: () => onReprice(),
      allowBack: true,
      allowNext: true,
      allowReprice: true,
    },
    {
      title: 'Borrower Information',
      icon: <Person color="primary" fontSize="large" />,
      type: LoanSubmissionStep.BORROWER_INFORMATION,
      onBack: () => goPrevStep(),
      onNext: () => goNextStep(),
      onReprice: () => onReprice(),
      allowBack: true,
      allowNext: true,
      allowReprice: true,
    },
    {
      title: 'Property Information',
      icon: <Business color="primary" fontSize="large" />,
      type: LoanSubmissionStep.PROPERTY_INFORMATION,
      onBack: () => goPrevStep(),
      onNext: () => goNextStep(),
      onReprice: () => onReprice(),
      allowBack: true,
      allowNext: true,
      allowReprice: true,
    },
    {
      title: 'Loan Details',
      icon: <Business color="primary" fontSize="large" />,
      type: LoanSubmissionStep.LOAN_DETAILS,
      onBack: () => goPrevStep(),
      onNext: () => {
        goNextStep()
      },
      onReprice: () => onReprice(),
      allowBack: true,
      allowNext: true,
      allowReprice: true,
    },
    ...insertIf(marketingPromotionsStore.showPromotions, [{
      title: 'Promotions',
      icon: <LocalOffer color="primary" fontSize="large" />,
      type: LoanSubmissionStep.PROMOTIONS,
      onBack: () => goPrevStep(),
      onNext: () => {
        goNextStep()
      },
      onReprice: () => onReprice(),
      allowBack: true,
      allowNext: true,
      allowReprice: true,
    }]),
    {
      title: 'Summary',
      icon: <AssignmentTurnedIn color="primary" fontSize="large" />,
      type: LoanSubmissionStep.SUMMARY,
      onBack: () => goPrevStep(),
      onNext: () => onSubmit(),
      onReprice: () => onReprice(),
      allowBack: true,
      allowNext: true,
      allowReprice: true,
      disableNextButton: singlePropertyStore.disableSubmitButton,
    },
  ];

  return (
    <Layout title={`Edit Single Property Rental Loan #${singlePropertyStore.editLoanJson.loanId}`}>
      <Paper className={classes.paper}>
        <>
          <StepsContainer
            activeStep={singlePropertyStore.activeStep}
            handleStep={moveToStep}
            steps={loanSubmissionSteps}
            scrollToTopOnStepChange={true}
          >
            {[
              <LoanPricerSingleProperty store={singlePropertyStore} />,
              ...insertIf(isBroker || allowLoanPricing, [<PricerSummaryTerm_new store={singlePropertyStore} onNext={() => goNextStep()} />]),
              ...insertIf(showBrokerInformation, [<BrokerInformation />]),
              <EntityInformation store={singlePropertyStore} />,
              <BorrowerInformationTerm store={singlePropertyStore} />,
              <PropertyInformationSingleProperty store={singlePropertyStore} />,
              <LoanDetailsTerm store={singlePropertyStore} />,
              ...insertIf(marketingPromotionsStore.showPromotions, [<SelectPromotionGrid store={marketingPromotionsStore.marketingPromotionsGridStore} promotionData={{ setPromotion: setPromotion, promotionId: singlePropertyStore.loanDetailsStore.getValue('marketingPromotionId') }}
              />]),
              <LoanSummary store={singlePropertyStore} />
            ]}
          </StepsContainer>
        </>
      </Paper>
    </Layout>
  );
});
