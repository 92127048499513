import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import {
  Box,

  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  InputBase,

  ListItem,
  ListItemText,
  Paper,
  Typography,
} from '@material-ui/core';
import { lighten } from '@material-ui/core/styles';
import { Search, UndoRounded } from '@material-ui/icons';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import { debounce } from '@roc/client-portal-shared/utils';
import { Button, FieldLabel, Slider, TextField } from '@roc/ui';
import { observer } from 'mobx-react';
import { useEffect, useMemo, useState } from 'react';
import { AutocompleteField } from '../../dscrCalculator/components/autocompleteField';
import { InternalPricerBaseStore } from '../stores/internalPricerBaseStore';
import { useHeaderHeight } from '@roc/feature-app-core';

const useStyles = makeStyles(theme => ({
  paper: {
    border: '1px solid',
    borderColor: theme.palette.grey['200'],
        padding: theme.spacing(4),

  },
  paperCalculatedValues: {
    padding: theme.spacing(4),
  },
  calculatedValuesBorder: {
    border: `2px solid ${theme.palette.primary.dark}`,
    borderRadius: '4px',
    marginTop: 14,
  },
  divider: {
    listStyle: 'none',
  },
  autocompletePaper: {
    marginLeft: 0,
    marginRight: 0,
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    fontSize: 13,
  },
  autocompleteOption: {
    minHeight: 'auto',
    alignItems: 'flex-start',
    padding: 8,
    '&[data-focus="true"]': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}));

export const PaperList = ({ children }) => {
  const classes = useStyles();
  return <Paper className={classes.paper}>{children}</Paper>;
};

export const PaperListTitle = ({ text, last = false }) => {
  const classes = useStyles();
  return (
    <>
      <ListItem>
        <ListItemText>
          <Typography variant="h6">{text}</Typography>
        </ListItemText>
      </ListItem>
    </>
  );
};

export const PaperListItem = ({ label, value, last = false }) => {
  const classes = useStyles();
  return (
    <>
      <Divider variant="middle" component="li" className={classes.divider} />
      <ListItem>
        <ListItemText>
          <Box display="flex">
            <Box flexGrow={1}>{label}</Box>
            <Typography variant="body1">{value}</Typography>
          </Box>
        </ListItemText>
      </ListItem>
    </>
  );
};

export const PricerLayout = ({ left, right }) => {
  const classes = useStyles();
  const theme = useTheme();
  const headerHeight = useHeaderHeight();
  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={6}>
        <Grid container spacing={2}>
          {left}
        </Grid>
      </Grid>
      <Grid item xs={12} md={6}>
        <Box className={classes.calculatedValuesBorder} position={'sticky'} top={headerHeight} bgcolor={lighten(theme.palette.primary.main, .95)}>
          <Box bgcolor={theme.palette.primary.dark} color={'white'} p={1.5} textAlign={'center'}>
            <Typography variant="h5">Results</Typography>
          </Box>
          <Box p={2}>
            <Grid container spacing={2}>
              {right}
            </Grid>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export const LoanAutocomplete = observer(
  ({ store }: { store: InternalPricerBaseStore }) => {
    const fetchOptions = useMemo(
      () => debounce(searchText => store.fetchOptions(searchText), 500),
      []
    );

    return (
      <AutocompleteField
        value={store.loanSearchText}
        options={store.loanSearchOptions}
        onChange={text => {
          store.loanSearchText = text;
          fetchOptions(store.loanSearchText);
        }}
        onSelect={opt => {
          store.loanSearchText = opt.label;
          if (opt.value && typeof opt.value === 'string' && opt.value.includes('DRAFT')) {
            store.fetchLoanValues(opt.value.replace('DRAFT', ''), true);
          }
          else {
            store.fetchLoanValues(opt.value, false);
          }
        }}
      />
    );
  }
);

export const YieldSpreadPremium = observer(
  ({ store }: { store: InternalPricerBaseStore }) => {
    return (
      <>
        <Grid item xs={12} md={6}>
          <Slider
            testId="oneTimeYieldSpreadPremiumSlider"
            standaloneLabel
            label="One Time Yield Spread Premium"
            disabled={false}
            onChangeCommitted={(event, newValue) => {
              if (
                store.form.fields.yieldSpread.value !== newValue
              ) {
                handleOnChangeYsp(store, newValue);
              }
            }}
            value={store.form.fields.yieldSpread.value}
            valueLabelDisplay="on"
            marks={buildYieldSpreadPremiumMarks(store.maxYsp)}
            step={0.05}
            min={0}
            max={store.maxYsp}
          />
        </Grid>
      </>
    )
  });

const buildYieldSpreadPremiumMarks = (maxYsp) => {
  return [
    {
      value: 0,
      label: '0%',
    },
    {
      value: maxYsp,
      label: `${maxYsp}%`,
    },
  ];
};

const handleOnChangeYsp = (store, value) => {
  store.onFieldChange('yieldSpread', value);
  store.fetchRatesDictionary();
};