import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import {
  DataGrid as Grid,
  GridColumnProps,
  SelectMode,
  GridRowSelectedEvent,
  Toolbar,
  FilterButton,
} from '@roc/ui';
import { GridStore } from '@roc/feature-app-core';
import { useEffect } from 'react';

const noOpComparator = () => 0;

const columns: GridColumnProps[] = [
  {
    field: 'name',
    headerName: 'Name',
    minWidth: 100,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'type',
    headerName: 'Type',
    minWidth: 100,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'ein',
    headerName: 'EIN',
    minWidth: 250,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
];

export type SelectBorrowerEntityGridProps = {
  multiple?: boolean;
  store: GridStore;
  onRowSelected: (e: GridRowSelectedEvent) => void;
};

const useStyles = makeStyles(() => ({
  container: {
    height: 435,
  },
}));

export const SelectBorrowerEntityGrid = observer(
  (props: SelectBorrowerEntityGridProps) => {
    const { multiple, onRowSelected, store } = props;
    const classes = useStyles();

    useEffect(() => {
      store.fetchGridData();
    }, []);

    const toolbar = (
      <Toolbar>
        <FilterButton
          onClearFilters={() => store.resetFilters()}
          onToggleFilters={() => store.toggleFilters()}
        />
      </Toolbar>
    );

    return (
      <Grid
        className={classes.container}
        selectMode={multiple ? SelectMode.MULTIPLE : SelectMode.SINGLE}
        onRowSelected={onRowSelected}
        toolbar={toolbar}
        columns={columns}
        rows={store.gridData.data.rows}
        isLoading={store.isLoading}
        showFilters={store.showFilters}
        onFilterChanged={params =>
          store.setFilterModel(params.api.getFilterModel())
        }
        // onSortChanged={params =>
        //   store.setSortModel(params.api.getSortModel())
        // }
        filterModel={store.filterModel}
        // sortModel={store.filterModel}
        domLayout="normal"
        paginationData={store.gridData.meta}
        setPageNumber={store.setPageNumber}
      />
    );
  }
);
