import { Service } from '@roc/feature-app-core';
const url = {
  GET_PENDING_DOCUSIGN_LENDER_BANK_INFORMATIONS: '/api/v1/loan/getPendingDocusignLenderBankInformation',
  GET_PENDING_APPROVAL_LENDER_BANK_INFORMATIONS: '/api/v1/loan/getPendingApprovalLenderBankInformation',
  APPROVE_BANK_INFORMATION: '/api/v1/loan/approveBankInformation',
  APPROVE_ENTITY_BANK_INFO: `/api/v1/loan/approveEntityBankInformation`,
  VERIFY_BANK_INFORMATION: '/api/v1/loan/verifyBankInformation',
  REJECT_BANK_INFORMATION: '/api/v1/loan/rejectBankInformation',
  GET_CURRENT_COMPANY_EMAIL: '/api/v1/loan/companyprofile/getCurrentCompanyEmailByCompanyId',
  GET_VERIFY_BANK_INFO_DOCUSIGN: '/api/v1/loan/companyprofile/verifyBankInformationDocusign',
};
export class LenderBankInformationService extends Service {

  getPendingDocusignLenderBankInformation(
    page: number,
    pageSize: number,
    sortDir: string,
    sortBy: string,
    filters: string,
    dropdownFilters: object
  ) {
    return this.get(
      url.GET_PENDING_DOCUSIGN_LENDER_BANK_INFORMATIONS,
      {
        page,
        pageSize,
        sortDir,
        sortBy,
        filters,
        dropdownFilters,
      }
    );
  }

  getPendingApprovalLenderBankInformation(
    page: number,
    pageSize: number,
    sortDir: string,
    sortBy: string,
    filters: string,
    dropdownFilters: object
  ) {
    return this.get(
      url.GET_PENDING_APPROVAL_LENDER_BANK_INFORMATIONS,
      {
        page,
        pageSize,
        sortDir,
        sortBy,
        filters,
        dropdownFilters,
      }
    );
  }

  approveBankInformation(
    lenderId
  ) {
    return this.get(
      url.APPROVE_BANK_INFORMATION,
      {
        lenderId
      }
    );
  }

  approveEntityBankInformation(
    entityId,
    entityType
  ) {
    return this.get(
      url.APPROVE_ENTITY_BANK_INFO,
      {
        entityId,
        entityType
      }
    );
  }

  verifyBankInformation(
    companyId
  ) {
    return this.get(
      url.VERIFY_BANK_INFORMATION,
      {
        companyId
      }
    );
  }

  rejectBankInformation(
    lenderId, reason
  ) {
    return this.get(
      url.REJECT_BANK_INFORMATION,
      {
        lenderId,
        reason
      }
    );
  }

  getCurrentCompanyEmail(companyId: number) {
    return this.get(url.GET_CURRENT_COMPANY_EMAIL, { companyId })
  }

  submitDocusign(request: any) {
    return this.post(`${url.GET_VERIFY_BANK_INFO_DOCUSIGN}`, request, {
      'Content-Type': 'application/json',
    });
  }

}
