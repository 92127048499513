import {
  Typography
} from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { useBorrowerPortalMediaQueries } from '@roc/feature-app-core';
import { CopyText } from '@roc/ui';
import clsx from 'clsx';
import { useHistory } from 'react-router';

const useStyles = makeStyles((theme: Theme) => ({
  headingClass: {
    color: theme.palette.primary.main,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  }
}));

export const LoanDetailsHeader = ({ loanDetails, overviewUrl }) => {
  const classes = useStyles();
  const { push } = useHistory();

  const returnToOverview = () => {
    push(overviewUrl)
  }

  return (
    <Typography
      variant="h4"
      className={clsx([classes.headingClass])}
    >
      <>
        <span>Loan#</span>
        <CopyText tooltipText="Copy Loan Number to clipboard" callBackFunction={returnToOverview}>
          {loanDetails?.loanId}
        </CopyText>
        <span> - </span>
        <span title={loanDetails?.dealName}>
          <CopyText tooltipText="Copy Deal Name to clipboard" callBackFunction={returnToOverview}>
            {loanDetails?.dealName}
          </CopyText>
        </span>
      </>
    </Typography>
  )
}