import { observer } from 'mobx-react';
import {
  StandardDialog,
} from '@roc/ui';
import { useDrawsStore } from '../hooks';
import { DrawReviewReportShared } from '../drawReviewReport/components/drawReviewReportShared';

interface DrawReportDialogProps {
  open: boolean;
  onClose: () => void;
}

export const DrawReportDialog = observer(({ open, onClose }: DrawReportDialogProps) => {
  const { drawReviewReportStore } = useDrawsStore();

  return (
    <StandardDialog
      open={open}
      title="Draw Report"
      maxWidth="xl"
      handleClose={onClose}
      dialogContent={
        <DrawReviewReportShared
            store={drawReviewReportStore}
            isInternal={false}
        />
      }
    />)
});
