import { Service } from "@roc/feature-app-core";

const url = {
  GET_ALL_COMPANY_CONTACTS: '/api/v1/user/getAllCompanyContacts',
  UPDATE_LENDER_CONTACT: '/api/v1/user/updateLenderContact',
  CREATE_LENDER_USER: '/api/v1/user/createLenderUser',
  CHANGE_USER_ROLE: '/api/v1/user/changeUserRole',
  DEACTIVATE_USER: '/api/v1/user/deactivateUser',
  FORGOT_PASSWORD: '/api/v1/user/public/forgotPassword',
  UPDATE_ALLOWED_CHAT_ROOM_TYPES: '/api/v1/user/updateAllowedChatRoomTypes',
  GET_PROCESSOR_ORIGINATOR_RELATIONSHIPS: '/api/v1/user/getProcessorOriginatorRelationships',
  SAVE_PROCESSORS_ORIGINATOR_RELATIONSHIPS: '/api/v1/user/saveProcessorsOriginatorsRelationships',
  GET_TOTAL_COMPANY_OWNERSHIP: '/api/v1/user/getCompanyTotalOwnership',
  UPDATE_LENDER_OWNERSHIP_PERCERTAGE: '/api/v1/user/updateLenderOwnershipPercentageAndRoleInEntity',
  BACKGROUND_REQUEST: '/api/v1/user/requestUserBackground',
  REACTIVATE_USER: '/api/v1/user/reactivateUser',
  COPY_SETUP_EMAIL_LINK: '/api/v1/user/setupEmailLink',
};

export class ManageUsersService extends Service {
  url;
  constructor() {
    super();
    this.url = url;
  }

  getAllCompanyUsers(pageNumber: number, pageSize: number, sortBy: string, sortDir: string, filters: string, dropdownFilters: string, companyId) {
    return this.get(this.url.GET_ALL_COMPANY_CONTACTS,
      { pageNumber, pageSize, sortBy, sortDir, filters, dropdownFilters, companyId },
      { disableGlobalLoading: true }
    );
  }

  updateLenderContact(lenderContact) {
    return this.put(this.url.UPDATE_LENDER_CONTACT, lenderContact);
  }

  createLenderUser(lenderUser) {
    return this.post(this.url.CREATE_LENDER_USER, lenderUser);
  }

  changeRole(changeRoleRequest) {
    return this.put(this.url.CHANGE_USER_ROLE, changeRoleRequest);
  }

  deactivateUser(deactivateUserRequest) {
    return this.post(this.url.DEACTIVATE_USER, deactivateUserRequest);
  }

  requestBackgroundForUser(userId) {
    return this.get(url.BACKGROUND_REQUEST, { userId });
  }

  getProcessorOriginatorRelationships(userId) {
    return this.get(this.url.GET_PROCESSOR_ORIGINATOR_RELATIONSHIPS, { userId });
  }

  getTotalCompanyOwnership(companyId) {
    return this.get(this.url.GET_TOTAL_COMPANY_OWNERSHIP, { companyId });
  }

  forgotPasswordSendEmail(username: string, portalId: string) {
    return this.post(this.url.FORGOT_PASSWORD, {
      username,
      portalId,
    });
  }

  updateAllowedChatRoomTypes(userId, allowedChatRoomTypes) {
    return this.post(this.url.UPDATE_ALLOWED_CHAT_ROOM_TYPES, {
      userId,
      allowedChatRoomTypes,
    });
  }

  saveProcessorsOriginatorsRelationships(
    saveProcessorsOriginatorsRelationshipsRequest
  ) {
    return this.put(
      this.url.SAVE_PROCESSORS_ORIGINATOR_RELATIONSHIPS,
      saveProcessorsOriginatorsRelationshipsRequest
    );
  }

  updateLenderOwnershipAndRoleInEntity(
    updateOwnershipRequest, companyId
  ) {
    return this.put(
      this.url.UPDATE_LENDER_OWNERSHIP_PERCERTAGE,
      updateOwnershipRequest
    );
  }

  reactivateUser(reactivateUserRequest) {
    return this.post(url.REACTIVATE_USER, reactivateUserRequest);
  }

  copySetupEmailLink(userId){
    return this.get(this.url.COPY_SETUP_EMAIL_LINK, userId);
  }
}