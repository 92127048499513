import { Box } from '@material-ui/core';
import { useDocumentCommunicationStore } from '@roc/feature-documents';
import { useLoanStore } from '@roc/feature-loans';
import { ThreadMeta } from '@roc/feature-types';
import { SelectField } from '@roc/ui';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';

interface SelectCategoryProps {
  process: string;
  drawList: any[];
}

export const SelectCategory = observer((props: SelectCategoryProps) => {
  const { process, drawList } = props;
  const { documentCommunicationStore } = useDocumentCommunicationStore();
  const { loanStore } = useLoanStore();
  const { loanId } = loanStore.loanDetails;
  const [selectedSection, setSelectedSection] = useState<any>();
  const [sections, setSections] = useState(documentCommunicationStore.getDisplaySections(process, drawList));

  useEffect(() => {
    const sections = documentCommunicationStore.getDisplaySections(process, drawList);
    setSections(sections)
    setSelectedSection(null);
  }, [process, documentCommunicationStore.displaySectionsNumber]);

  const setSection = (sectionName: string) => {
    const section = sections.find(s => s.sectionName === sectionName);

    const { collateralId, borrowerId, drawId, sectionId } = section;
    const meta: ThreadMeta = {
      loanId,
      drawId,
      sectionId,
      borrowerId,
      collateralId,
    };
    setSelectedSection(section);
    documentCommunicationStore.setThreadMeta(meta);
  };

  const categoryOptions = sections.map(s => ({
    label: s.sectionName,
    value: s.sectionName,
  }));

  return (
    <Box pr={2} minWidth={400}>
      <SelectField
        label={'Message Category'}
        fullWidth
        name={'commentCategory'}
        value={selectedSection?.sectionName || ''}
        options={categoryOptions}
        onChange={sectionName => setSection(sectionName)}
        variant="outlined"
        testId="commentCategory"
      />
    </Box>
  );
});
