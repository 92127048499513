import { Grid, Link, Box, Typography, List, ListItem } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Rural } from "./formComponents";

export const RuralPropertyWarning = ({ store, showMessagePartially = false }: { store: any, showMessagePartially?: boolean }) => {

  return (
    <Box m={'16px 0'}>
      <Alert severity='warning'>
        <Grid container spacing={2} direction="column">
          <Grid item>
          Based on <Link href="https://www.consumerfinance.gov/rural-or-underserved-tool/" target="_blank">Consumer Financial Protection Bureau</Link>, there is a chance this property is rural or underserved. Please see below guidelines before proceeding:
          <List style={{
            listStyleType: 'disc',
            listStylePosition: 'inside'
          }}>
              <ListItem style={{ display: 'list-item' }}>
                If the appraisal says rural, <strong>we will not be able to proceed.</strong>
              </ListItem>
              <ListItem style={{ display: 'list-item' }}>
                If the appraisal comes back suburban, we can likely proceed but this loan is subject to higher pricing. The underwriter will make the final decision once the loan is in underwriting.
              </ListItem>
          </List>
          </Grid>
        </Grid>
      </Alert>
    </Box>)
}