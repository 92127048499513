import { Box } from '@material-ui/core';
import { SelectField, Toolbar } from '@roc/ui';
import { observer } from 'mobx-react-lite';
import { LeadListViewGrid } from './leadListViewGrid';



export const LeadListViewDashBoard = observer(() => {


  const options = [{ label: 'All Leads', value: 'option 1' }, { label: 'Borrower Leads', value: 'option 2' }]


  return (
    <LeadListViewGrid
      toolbar={
        <Toolbar >
          <Box display="flex" alignItems="center" justifyContent="space-between" mr={2}>
            <SelectField
              standaloneLabel
              value={options[0].value}
              name={'foreclosure'}
              label=''
              placeholder='None'
              options={options}
              onChange={(value) => console.log('hello ', value)}
              testId="foreclosure"
              disabled={false}
            />
          </Box>
        </Toolbar>
      }
    />
  );
});