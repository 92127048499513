import { IconButton, Menu, ListItemIcon, ListItemText } from '@material-ui/core';
import { MoreVert, Send, Close } from '@material-ui/icons';
import {
  DataGrid as Grid, GridColumnProps,
  SelectFilterComponent,
  SelectFloatingFilterComponent, TextColumn, ConfirmationMenuItem, SubmitMenuItem
} from '@roc/ui';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useLenderBankInformationStore } from '../../../hooks/useLenderBankInformationStore';
import { LenderBankInforRejectionReason } from '../lenderBankInforRejectionReason';


const columns: GridColumnProps[] = [
  {
    field: 'lender',
    headerName: 'Name',
    minWidth: 100,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'beneficiaryAccountName',
    headerName: 'Beneficiary Account Name',
    minWidth: 110,
    cellRenderer: 'orderIdCellRenderer',
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'beneficiaryAccountNumber',
    headerName: 'Beneficiary Account Number',
    minWidth: 110,
    cellRenderer: 'borrowerNameCellRenderer',
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'beneficiaryBankName',
    headerName: 'Beneficiary Bank Name',
    minWidth: 300,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'beneficiaryRoutingNumber',
    headerName: 'Beneficiary Routing Number',
    minWidth: 120,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'displayEntityType',
    headerName: 'Entity',
    minWidth: 120,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'actions',
    headerName: 'Actions',
    minWidth: 125,
    cellRenderer: 'actionsCellRenderer',
  },
];

export const LenderBankInfoGridPending = observer(() => {
  const [lenderId, setLenderId] = useState();
  const [lender, setLender] = useState();
  const [type, setType] = useState();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);

  const { lenderBankInformationStore } = useLenderBankInformationStore();

  const frameworkComponents = {
    selectFilter: SelectFilterComponent,
    selectFloatingFilter: SelectFloatingFilterComponent,
    actionsCellRenderer: params => {
      return (
        <IconButton onClick={e => openMenu(e, params.node.data)}>
          <MoreVert color="primary" />
        </IconButton>
      );
    },
  };

  const openMenu = (event: React.MouseEvent<HTMLElement>, record) => {
    const { lenderId, lender, entityType } = record;
    setLenderId(lenderId);
    setLender(lender);
    setType(entityType);
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setLenderId(null);
    setLender(lender);
  };

  const onFilterChanged = params => {
    const filterModel = params.api.getFilterModel();
    lenderBankInformationStore.pendingApprovalLenderBankInfoGridStore.setFilterModel(filterModel);
  };

  const handleBankApproval = (lenderId, type) => {
    lenderBankInformationStore.approveBankInformation(lenderId, type);
  }

  const onSortChanged = params => {
    const sortModel = params.api.getSortModel();
    lenderBankInformationStore.pendingApprovalLenderBankInfoGridStore.setSortModel(sortModel);
  };


  useEffect(() => {
    lenderBankInformationStore.pendingApprovalLenderBankInfoGridStore.resetAndFetchGridData();
  }, []);

  return (
    <>
      <Grid
        columns={columns}
        rows={lenderBankInformationStore.pendingApprovalBankRequests}
        frameworkComponents={frameworkComponents}
        onSortChanged={onSortChanged}
        onFilterChanged={onFilterChanged}
        isLoading={lenderBankInformationStore.pendingApprovalLenderBankInfoGridStore.isLoading}
        showFilters={lenderBankInformationStore.pendingApprovalLenderBankInfoGridStore.showFilters}
        sortModel={lenderBankInformationStore.pendingApprovalLenderBankInfoGridStore.sortModel}
        filterModel={lenderBankInformationStore.pendingApprovalLenderBankInfoGridStore.filterModel}
        paginationData={lenderBankInformationStore.pendingApprovalLenderBankInfoGridStore.gridData.meta}
        setPageNumber={lenderBankInformationStore.pendingApprovalLenderBankInfoGridStore.setPageNumber}
      />
      <Menu
        id="menu-appbar"
        getContentAnchorEl={null}
        anchorEl={anchorEl}
        keepMounted
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left', }}
        transformOrigin={{ vertical: 'top', horizontal: 'left', }}
        open={menuOpen}
        onClose={handleMenuClose}
      >
        <ConfirmationMenuItem
          testId={`approveBankInfo`}
          onClick={() => {
            handleBankApproval(lenderId, type);
            handleMenuClose();
          }}
          confirmDialogProps={{
            body: `Are you sure want to approve the bank information for ${lender}?`,
          }}
        >
          <ListItemIcon>
            <Send fontSize="small" />
          </ListItemIcon>
          <ListItemText>Approve Bank Information</ListItemText>
        </ConfirmationMenuItem>

        {/* <SubmitMenuItem
          testId={`rejectBankInformation`}
          onClick={() => {
            lenderBankInformationStore.rejectBankInformation(lenderId, formFields.rejectReason.value);
            handleMenuClose();
          }}
          submitDialogProps={{
            title: `Reject Bank Info for ${lender}`,
            body: (
              <LenderBankInforRejectionReason />
            ),
            okButtonText: 'Reject',
            cancelButtonText: 'Close',
          }}
        >
          <ListItemIcon>
            <Close fontSize="small" />
          </ListItemIcon>
          <ListItemText>
            Reject Bank Information
          </ListItemText>
        </SubmitMenuItem> */}
      </Menu>
    </>
  );
});
