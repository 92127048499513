import { Layout } from '@roc/client-portal-shared/components';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { Redirect } from 'react-router';
import { Tabs } from '@roc/ui';
import { useRoutes, useStore } from '../../hooks';
import { CompanyDetails } from './components/companyDetails';
import { Documents } from './components/documents';
import { OwnershipAndEmployees } from './components/ownershipAndEmployees';
import { Products } from './components/products';
import { Summary } from './components/summary';
import { lenderStatus } from './utils/constants';
import { useRoutes as useBackofficeRoutes } from 'libs/feature-backoffice-users/src/backOfficeUsers/hooks/useRoutes'

const TABS = [
  {
    label: 'Company Details',
  },
  {
    label: 'Products',
  },
  {
    label: 'Documents',
  },
  {
    label: 'Summary',
  },
];

export const LenderDetails = observer(() => {
  const { companyProfileRoutesConfig } = useRoutes();
  const { lenderRoutesConfig } = useBackofficeRoutes();
  const { companyProfileStore } = useStore();
  const { lenderDetailsStore } = companyProfileStore;
  const {
    lenderDetailsFormStore,
  } = lenderDetailsStore;

  useEffect(() => {
    lenderDetailsStore.initializeLenderOnboardingForm();
  }, []);

  const onTabChange = (e, index) => {
    lenderDetailsStore.setSelectedTabIndex(index);
  };

  const tabIndex = lenderDetailsStore.selectedTabIndex;

  const isReady =
    lenderDetailsFormStore.isReady;

  const lender = lenderDetailsStore.salesforceLenderStore.salesForceLender;
  if (lender && lender.status !== lenderStatus.APPLICATION_IN_PROGRESS) {
    return companyProfileStore.displayCompanyProfile ? <Redirect to={lenderRoutesConfig.lendersHome.url} /> : <Redirect to={companyProfileRoutesConfig.companyProfile.url} />;
  }

  return (
    <Layout title="Lender Details" maxWidth={'lg'}>
      {isReady && (
        <>
          <Tabs tabs={TABS} selectedTab={tabIndex} onTabChange={onTabChange} />
          {tabIndex === 0 && (
            <CompanyDetails companyProfileStore={companyProfileStore} />
          )}
          {tabIndex === 1 && (
            <Products companyProfileStore={companyProfileStore} />
          )}
          {tabIndex === 2 && (
            <Documents companyProfileStore={companyProfileStore} />
          )}
          {tabIndex === 3 && (
            <Summary companyProfileStore={companyProfileStore}  />
          )}
        </>
      )}
    </Layout>
  );
});
