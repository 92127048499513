import { action, observable, flow, makeObservable } from 'mobx';
import { ApiResponse, GlobalStore, GridStore } from '@roc/feature-app-core';
import { SpecialServicingService } from '../services/specialServicingService';
import { CounterPartyExpensesTab } from '../types/types';
import { SpecialServicingBaseStore } from './specialServicingBaseStore';
import { ProtectiveAdvanceFormStore } from './protectiveAdvanceFormStore';

export class PayablesDashboardStore extends SpecialServicingBaseStore {
  constructor(globalStore: GlobalStore) {
    super(globalStore);
    this.tab = CounterPartyExpensesTab.PENDING_PAYABLE;

    makeObservable(this, {
      fetchEntityNames: flow,
      performAction: flow,
      performActions: flow,
    });
  };

  *fetchEntityNames() {
    try {
      const response: ApiResponse = yield this.service.getVendorsNames();
      this.entityList = response.data.data.map(value => (
        { label: value.vendorName, value: value.companyId }
      ));
    }
    catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching vendor names.',
      });
    }
  };


  reset() {
    this.tab = CounterPartyExpensesTab.PENDING_PAYABLE;
    this.counterPartyExpenseGridStore.reset();
    this.recordsIds = [];
  };

  *performActions(status: string) {
    try {
      const response = yield this.service.performPayableAction(this.recordsIds, status);
      if (response.data.status === 'OK') {
        this.globalStore.notificationStore.showSuccessNotification({
          message: 'Status updated successfully.'
        });
      }
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while updating status.',
      });
    }
  }

  *performAction(id: number, status: string) {
    const idList: number[] = [id];
    try {
      const response = yield this.service.performPayableAction(idList, status);
      if (response.data.status === 'OK') {
        this.globalStore.notificationStore.showSuccessNotification({
          message: 'Status updated successfully.'
        });
      }
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while updating status.',
      });
    }
  }
}
