import { Grid, Typography } from '@material-ui/core';
import { Button, TestDataAttribute } from '@roc/ui';
import { observer } from 'mobx-react';
import React, { useMemo } from 'react';
import { INSPECTION_BY_BORROWER } from '../../utils/constants';

export const SubmitAppraisalSuccess = observer(({ store }) => {
  const { isNoAccessAndPurchase, submittedAppraisalId } = store;
  const {
    interiorAccessAvailable,
    inspectionBy,
  } = store.form.fields;

  const startAnotherOrder = () => {
    store.reset();
  };

  const getSuccessMessage = () => {
    let message = 'Your appraisal has been ordered. Thank You.';

    if (
      interiorAccessAvailable.value === 'Y' &&
      inspectionBy.value === INSPECTION_BY_BORROWER
    ) {
      message =
        'Your appraisal has been ordered. You still need to upload the getownerinsight report ASAP or your appraisal will be delayed. Thank You.';
    } else if (
      interiorAccessAvailable.value === 'N' &&
      !isNoAccessAndPurchase
    ) {
      message =
        'The appraisal will not be ordered until an underwriter approves the reason provided.';
    }

    return message;
  };

  const successMessage = useMemo(getSuccessMessage, [
    interiorAccessAvailable.value,
    inspectionBy.value,
    isNoAccessAndPurchase,
  ]);

  return (
    <Grid container direction="column" alignItems="center" spacing={4}>
      <Grid item>
        <Typography variant="h4">{successMessage}</Typography>
      </Grid>
      {submittedAppraisalId && (
        <Grid item>
          <Typography>
            Your appraisal number is
            <TestDataAttribute id="submittedAppraisalId">
              <b>#{submittedAppraisalId}</b>
            </TestDataAttribute>
          </Typography>
        </Grid>
      )}
      <Grid item>
        <Button
          variant="contained"
          type="button"
          color="primary"
          onClick={startAnotherOrder}
          testId="startOrder"
        >
          Start another Order
        </Button>
      </Grid>
    </Grid>
  );
});
