import {
  Box,
  Dialog as MuiDialog,
  DialogActions as MuiDialogActions,
  DialogContent,
  DialogContentClassKey,
  DialogProps,
  DialogTitle as MuiDialogTitle,
  IconButton,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import { WithStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { Close } from '@material-ui/icons';
import { ClassNameMap } from '@material-ui/styles';
import React, { ReactNode } from 'react';

export enum DialogState {
  ADD,
  EDIT,
}

export interface StandardDialogProps extends DialogProps {
  dialogContentclasses?: Partial<ClassNameMap<DialogContentClassKey>>;
  title?: any;
  subTitle?: React.ReactNode | string;
  rephraseClass?: boolean;
  handleClose?: React.ReactEventHandler<{}>;
  dialogContent: ReactNode;
  dialogActions?: ReactNode;
  removeContentBox?: boolean;
  overflowDialog?: boolean;
  dialogContentRef?: React.RefObject<HTMLElement>;
}

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  subTitle?: React.ReactNode | string;
  children: React.ReactNode;
  onClose: React.MouseEventHandler<{}>;
}

const styles = (theme: Theme) =>
  createStyles({
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  let subTitle = props.subTitle;
  if (subTitle && typeof subTitle === 'string') subTitle = (<Typography style={{ fontSize: 'smaller' }}>{subTitle}</Typography>);

  return (
    <MuiDialogTitle disableTypography {...other}>
      <Typography variant="h4">{children}</Typography>
      {subTitle}
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <Close />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2, 3),
  },
}))(MuiDialogActions);

const useStyles = makeStyles(() => ({
  dialogContent: {
    display: 'flex'
  },
  dialogContentWithOverflow: {
    display: 'flex',
    overflow: 'visible'
  },
  dialogPaper: {
    overflow: 'visible'
  },
}))

export const StandardDialog = ({
  title,
  handleClose,
  dialogActions,
  dialogContent,
  dialogContentclasses,
  dialogContentRef,
  overflowDialog,
  removeContentBox,
  ...props
}: StandardDialogProps) => {
  const theme = useTheme();
  const classes = useStyles();
  const { fullWidth, fullScreen, maxWidth, ...otherProps } = props;

  const defaultFullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const handleDialogClose = (e, reason) => {
    if (reason == 'backdropClick') return;
    handleClose && handleClose(e);
  };

  return (
    <MuiDialog
      classes={props.rephraseClass ? { paper: classes.dialogPaper } : {}}
      onClose={handleDialogClose}
      fullWidth={fullWidth ?? true}
      fullScreen={fullScreen ?? defaultFullScreen}
      maxWidth={maxWidth ?? 'md'}
      aria-labelledby="form-dialog-title"
      {...otherProps}
    >
      <DialogTitle
        id="form-dialog-title"
        onClose={handleClose ? () => handleClose(null) : null}
        subTitle={props.subTitle}
      >
        {title}
      </DialogTitle>

      <DialogContent ref={dialogContentRef} className={overflowDialog ? classes.dialogContentWithOverflow : classes.dialogContent} classes={dialogContentclasses}>
        {removeContentBox ? (
          dialogContent
        ) : (
          <Box className={`dialog-content-box`} m={4} flexGrow={1}>
            {dialogContent}
          </Box>
        )}
      </DialogContent>

      {dialogActions && (
        <DialogActions disableSpacing>{dialogActions}</DialogActions>
      )}
    </MuiDialog>
  );
};
