import { Box, Typography } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { CheckCircle } from '@material-ui/icons';
import { Layout } from '@roc/feature-app-core';
import { VerticalBarLoader, StepsContainer } from '@roc/ui';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useStore } from '../../hooks';
import { WireAuthorizationState } from '../../types';
import { WireAuthorizationForm } from './components/wireAuthorizationForm';
import { DocumentSigning } from './components/documentSigning';
import { ConsentVerificationV2, Paper } from '@roc/ui';
import { DocumentVerificationState } from '@roc/feature-types';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(3, 0, 4),
      minHeight: 900
    },
    stepper: {
      maxWidth: 900,
      margin: 'auto',
      [theme.breakpoints.down('xs')]: {
        '& .MuiStepLabel-label': {
          display: 'none',
        },
        '& .MuiStepLabel-label.MuiStepLabel-active': {
          display: 'block',
        },
      },
    },
  })
);



export const WireAuthorizationV2 = observer(() => {
  const classes = useStyles();
  const { wireAuthorizationStore, globalStore } = useStore();
  const { wireAuthorizationState } = wireAuthorizationStore;

  useEffect(() => {
    const handleWindowMessage = (event: MessageEvent) => {
      if (event.data === 'wire-authorization-docusign-done') {
        wireAuthorizationStore.confirmDocusign();
      }
    };
    window.addEventListener('message', handleWindowMessage);

    return () => window.removeEventListener('message', handleWindowMessage);
  }, [wireAuthorizationStore]);

  const steps = [
    {
      title: 'Identity Verification',
      icon: null,
      onBack: null,
      onNext: () => {
        if (!wireAuthorizationStore.identityVerificationCompleted) {
          if (wireAuthorizationStore.experienceTransactionId) {
            wireAuthorizationStore.identityVerification();
            wireAuthorizationStore.setIdentityVerificationCompleted(true);
          } else {
            globalStore.notificationStore.showErrorNotification({
              message: "Error storing identity information",
            });
          }
        } else {
          wireAuthorizationStore.goNextStep();
        }
      },
      allowBack: false,
      allowNext: wireAuthorizationStore.documentVerificationState === DocumentVerificationState.IN_REVIEW,
    },
    {
      title: 'Bank Information',
      icon: null,
      onBack: () => {
        wireAuthorizationStore.goPrevStep();
      },
      onNext: () => {
        if (wireAuthorizationStore.form.meta.isValid) {
          wireAuthorizationStore.goNextStep();
        } else {
          wireAuthorizationStore.runFormValidationWithMessage();
        }
      },
      allowBack: true,
      allowNext: true,
    },
    {
      title: 'Electronic Signature',
      icon: null,
      onBack: () => {
        wireAuthorizationStore.goPrevStep();
      },
      onNext: () => {/**nothing to do here**/ },
      allowBack: false,
      allowNext: false,
    },
  ];

  return (
    <Layout maxWidth="lg">
      <Box my={3}>
        <Typography variant="h3" gutterBottom>
          {'Wire Authorization'}
        </Typography>
      </Box>
      {wireAuthorizationStore.loading && <VerticalBarLoader />}
      <Paper className={classes.paper}>
        {WireAuthorizationState.DONE === wireAuthorizationState && <WireAuthCompleteView />}
        {WireAuthorizationState.DONE !== wireAuthorizationState && (
          <StepsContainer
            activeStep={wireAuthorizationStore.activeStep}
            disableNavigation={true}
            steps={steps}
            stepperClassName={classes.stepper}
          >
            {wireAuthorizationStore.activeStep === 0 && (
              <ConsentVerificationV2 />
            )}
            {wireAuthorizationStore.activeStep === 1 && (
              <WireAuthorizationForm />
            )}
            {wireAuthorizationStore.activeStep === 2 && (
              <DocumentSigning />
            )}
          </StepsContainer>
        )}
      </Paper>
    </Layout>
  );
});


const WireAuthCompleteView = () => {
  return (
    <Box mt={4} mx={'auto'} textAlign="center">
      <CheckCircle style={{ fontSize: 60, color: green[500] }} />
      <br />
      <Typography variant="h4" color="textPrimary">
        Thank you
      </Typography>
      <br />
      <Typography>
        Your wire authorization has been completed successfully
      </Typography>
      <br />
    </Box>
  );
};
