import { Box } from '@material-ui/core';
import { Button, DialogState, EditableDialog, FilterButton, Toolbar } from '@roc/ui';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { ManageUsersGrid } from './components/manageUsersGrid';
import { AddNewUser, EditUser } from './components/userForm';
import { useManageUsersStore } from './hooks/useManageUsersStore';
import { ManageUsersStore } from './stores/manageUsersStore';

export const ManageUsers = observer(({ manageUsersStore }: { manageUsersStore: ManageUsersStore }) => {

  useEffect(() => {
    manageUsersStore.gridStore.resetAndFetchGridData();
  }, [manageUsersStore]);

  const handleToggleFilter = () => {
    manageUsersStore.gridStore.toggleFilters();
  };

  const handleClearFilter = () => {
    manageUsersStore.gridStore.resetFilters();
  };

  const handleAddNewUser = () => {
    manageUsersStore.addNewUser();
  };

  const handleEditUser = (record) => {
    manageUsersStore.editUser(record);
  };

  const closeModal = () => {
    manageUsersStore.resetDialogState();
  };

  const getDialogContent = () => {
    return manageUsersStore.dialogState === DialogState.ADD ? (
      <AddNewUser store={manageUsersStore} />
    ) : (
      <EditUser store={manageUsersStore} />
    );
  };

  return (
    <>
      <ManageUsersGrid
        toolbar={
          <Toolbar>
            <Box mr={2}>
              <FilterButton
                onToggleFilters={handleToggleFilter}
                onClearFilters={handleClearFilter}
              />
            </Box>
            <Box>
              <Button
                variant="contained"
                size="small"
                onClick={handleAddNewUser}
                color="primary"
                testId="add_new_user"
              >
                Add New User
              </Button>
            </Box>
          </Toolbar>
        }
        manageUsersStore={manageUsersStore}
      />
      <EditableDialog
        title={'User'}
        open={manageUsersStore.showDialog}
        dialogState={manageUsersStore.dialogState}
        handleClose={closeModal}
        dialogContent={getDialogContent()}
        handleAdd={() => manageUsersStore.createUser()}
        handleEdit={() => manageUsersStore.updateUser()}
      />
    </>
  );
});