import { Page } from "@roc/feature-app-core";
import { Route } from "react-router";
import { LiquidityReport } from "../components";
import { useStore } from '@roc/client-portal-shared/hooks';

export const getLiquidityReportRoutesConfig = (
  basePath: string,
  baseUrl: string
) => {
  return {
    liquidityReport: {
      path: `${basePath}/liquidity-report`,
      url: `${baseUrl}/liquidity-report`,
      documentTitle: 'Liquidity report'
    }
  }
};

export const getLiquidityReportRoutes = (basePath: string, baseUrl: string) => {
  const config = getLiquidityReportRoutesConfig(basePath, baseUrl);
  const { liquidityReportStore } = useStore();
  return {
    liquidityReport: (
      <Route exact path={config.liquidityReport.path}>
        <Page routeDefinition={config.liquidityReport}>
          <LiquidityReport store={liquidityReportStore}/>
        </Page>
      </Route>
    )
  }
}