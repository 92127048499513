import React, { useEffect, useState } from 'react';
import { createStyles, darken, lighten, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import WimbaModal from './components/wimbaModal';
import { observer } from 'mobx-react';

import { Card, GoogleMap, TestDataAttribute, Paper, Button, Tooltip } from '@roc/ui';
import { formatCurrency, formatPercentage, getCompletePropertyAddress } from '@roc/ui/utils';
import { green, red } from '@material-ui/core/colors';
import { Box, Chip, Divider, ListItem, ListItemText } from '@material-ui/core';
import { INTEREST_ACCRUAL_TOOLTIP, LoanSubType, Roles, WIMBA, isFixAndFlip } from '@roc/feature-utils';
import { useFixFlipStore } from './hooks/useFixFlipStore';
import ResubmitAsStabilizedModal from './components/resubmitAsStabilizedModal';
import { useBaseStore, useUserStore } from '@roc/feature-app-core';
import { CloudDownload } from '@material-ui/icons';
import { LoanSubmissionStep } from './utils/constants';
import { useLoanCommissionStore } from 'libs/feature-loan-summary/src/loanSummary/hooks/useCommissionStore';
import { ErrorsAlert, WarningsAlert } from './components/alerts';
import { QuoteGeneratorTermSheetDialog } from '@roc/feature-quote-generator';

export interface PropertyFormProps {
  property: any;
}

const BooleanChip = ({ value }) => {
  const color = value ? green : red;
  return (
    <Chip
      label={value ? 'Yes' : 'No'}
      style={{
        fontWeight: 0,
        backgroundColor: color[200],
        color: color[900],
      }}
    />
  );
};

const useStyles = makeStyles(theme =>
  createStyles({
    card: {
      background: theme.palette.background.default,
      borderColor: darken(theme.palette.background.default, 0.1),
    },
    mapStyles: {
      position: `relative`,
      width: `100%`,
      height: `150px`,
    },
    cardSpacing: {
      paddingRight: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    termSheetContainer: {
      padding: theme.spacing(2),
      marginBottom: '24px',
      backgroundColor: lighten(theme.palette.primary.main, 0.8),
      marginTop: '16px',
      marginLeft: '8px',
    },
    termSheetDownload: {
      display: 'flex',
      alignItems: 'center',
    },
    paper: {
      border: '1px solid',
      borderColor: theme.palette.grey['200'],
      width: '100%',
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
      padding: theme.spacing(1),
      boxShadow: 'none !important'
    },
    divider: {
      listStyle: 'none',
    },
    label: {
      width: '60%',
    },
    textValue: {
      width: '20%',
    },
    totalText: {
      fontWeight: 'bold',
    },
    rightBox: {
      marginRight: 36,
    },
    totalRightBox: {
      marginRight: 36,
      fontWeight: 'bold',
    },
    profitContainer: {
      padding: '8px'
    },
  })
);

export const PaperList = ({ children }) => {
  const classes = useStyles();
  return <Paper className={classes.paper}>{children}</Paper>;
};

export const PaperListTitle = ({ text }) => {
  return (
    <ListItem>
      <ListItemText>
        <Typography variant="h6">{text}</Typography>
      </ListItemText>
    </ListItem>
  );
};

export const PaperListItem = ({ label, value, secondValue = '', last = false, first = false, total = false }) => {
  const classes = useStyles();
  return (
    <>
      {
        !first ? <Divider variant="middle" component="li" className={classes.divider} /> : null
      }
      <ListItem>
        <ListItemText>
          {
            secondValue !== '' ? (
              <Box display="flex" justifyContent="space-between">
                <Box className={classes.label}>{label}</Box>
                <Box className={classes.textValue}>{value}</Box>
                <Box className={classes.textValue}>{secondValue}</Box>
              </Box>
            ) : (
              <Box display="flex" justifyContent="space-between">
                <Box className={total ? classes.totalText : ''}>{label}</Box>
                <Box className={total ? classes.totalRightBox : classes.rightBox}>{value}</Box>
              </Box>
            )
          }

        </ListItemText>
      </ListItem>
      {
        last ? <Divider variant="middle" component="li" className={classes.divider} /> : null
      }
    </>
  );
};

const usdAmount = value => {
  return value && formatCurrency(Number(value));
};

const percentage = (value, decimals = 2) => {
  return value && `${Number(value).toFixed(2)}%`;
};

const Summary = () => {
  const classes = useStyles();
  const { fixFlipStore } = useFixFlipStore();
  const {
    entity,
    selectBorrowersStore,
    properties,
    loanTerms,
    allErrors,
    allWarnings,
    stepErrors,
    stepWarnings,
    loanInformationStore,
    resubmitAsStabilized,
    goPrevStep,
    loanSubtype,
    quoteGeneratorDetailsStore,
  } = fixFlipStore;
  const { quoteCalculationDetails: { bridgeProfitAndDscr }, calculationStarted } = quoteGeneratorDetailsStore;
  const { borrowers } = selectBorrowersStore;
  const [termSheetOpen, setTermSheetOpen] = useState(false);
  const { loanCommissionStore } = useLoanCommissionStore();

  const { lenderAttorneysOptionList } = loanInformationStore;

  const preferredClosingAttorneyName = lenderAttorneysOptionList?.find(
    o => o.value == loanTerms.preferredClosingAttorney
  )?.label;

  const closeModal = () => {
    if (!loanTerms.wimbaAcknowledged) {
      goPrevStep(LoanSubmissionStep.SUMMARY);
    }
  };

  const closeModalResubmitStb = () => {
    goPrevStep(LoanSubmissionStep.SUMMARY);
  }

  const confirmModal = () => {
    loanInformationStore.onChange('wimbaAcknowledged', true);
  };

  const { globalStore } = useBaseStore();
  const { userStore } = useUserStore();
  const allowLoanPricing = userStore.allowLoanPricing;
  const isInternal = globalStore.lenderInfo?.isInternal;
  const isBackOfficeProcessor = userStore.userInformation?.roles?.includes(Roles.BACK_OFFICE_PROCESSOR);
  const isBackOfficeUser = userStore.isBackOfficeUser;
  const isBrokerUser = userStore.userInformation?.roles?.includes(Roles.BROKER);

  const isOffHudBroker = () =>{
    return loanTerms?.referralFee && loanTerms?.referralFee > 0 ? true : false;
  }

  useEffect(() => {
    fixFlipStore.validateBridgeSubmission();
    fixFlipStore.validateBorrowerErrors();
    fixFlipStore.validateEntityErrors();
    fixFlipStore.getAreBorrowersPreQualifiedFromPrescreenValidations();
    fixFlipStore.validateProtectedBorrowers();
  }, []);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4">Summary</Typography>
          <br />
          <Typography variant="body1">
            Here we will list the entity information, participants and
            properties and loan terms you specified.
          </Typography>
        </Grid>
      </Grid>

      <br />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography color="primary" variant="h6">
            ENTITY
          </Typography>
          <Grid item xs={12} md={6} lg={4} className={classes.cardSpacing}>
            <TestDataAttribute id='entity'>
              <Card
                color="secondary"
                title={entity.name}
                value={entity}
                className={classes.card}
              >
                <div>
                  Ein:&nbsp;
                  <strong>{entity.ein}</strong>
                  <br />
                  Type:&nbsp;
                  <strong>{entity.type}</strong>
                </div>
              </Card>
            </TestDataAttribute>
          </Grid>
        </Grid>
        <br />
        <br />

        <Grid item xs={12}>
          <Typography color="primary" variant="h6">
            BORROWERS
          </Typography>
          {borrowers.length > 0 && (
            <Grid container spacing={2}>
              {borrowers.map(borrower => (
                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={4}
                  className={classes.cardSpacing}
                  key={borrower.borrowerId}
                >
                  <TestDataAttribute id='borrower'>
                    <Card
                      color="secondary"
                      title={`${borrower.firstName} ${borrower.lastName}`}
                      value={borrower}
                      className={classes.card}
                    >
                      <div>
                        Phone:&nbsp;
                        <strong>{borrower.cellPhone}</strong>
                        <br />
                        Email:&nbsp;
                        <strong>{borrower.emailAddress}</strong>
                      </div>
                    </Card>
                  </TestDataAttribute>
                </Grid>
              ))}
            </Grid>
          )}
        </Grid>
      </Grid>

      <br />
      <br />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography color="primary" variant="h6">
            PROPERTIES
          </Typography>
        </Grid>
        {properties.map(location => {
          return (
            <Grid
              item
              xs={12}
              md={6}
              lg={4}
              className={classes.cardSpacing}
              key={getCompletePropertyAddress(location)}
            >
              <TestDataAttribute id='property'>
                <Card
                  color="secondary"
                  title={getCompletePropertyAddress(location)}
                  value={location}
                  className={classes.card}
                >
                  {location.latitude && location.longitude ? (
                    <GoogleMap
                      mapStyles={classes.mapStyles}
                      latitude={location.latitude}
                      longitude={location.longitude}
                    />
                  ) : null}
                </Card>
              </TestDataAttribute>
            </Grid>
          );
        })}
      </Grid>

      <br />
      <br />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card title="Loan Details">
            <Grid item container direction="row" spacing={2}>
              <Grid item xs={6} md={3}>
                <Typography>
                  <b>Initial Loan Amount</b>
                </Typography>
              </Grid>
              <Grid item xs={6} md={3}>
                <TestDataAttribute id='initial_loan_amount'>
                  <Typography>
                    {usdAmount(loanTerms.initialLoanAmount)}
                  </Typography>
                </TestDataAttribute>
              </Grid>
              <Grid item xs={6} md={3}>
                <Typography>
                  <b>Construction Holdback</b>
                </Typography>
              </Grid>
              <Grid item xs={6} md={3}>
                <TestDataAttribute id='construction_holdback'>
                  <Typography>
                    {usdAmount(loanTerms.constructionHoldback)}
                  </Typography>
                </TestDataAttribute>
              </Grid>
              <Grid item xs={6} md={3}>
                <Typography>
                  <b>Total Loan Amount</b>
                </Typography>
              </Grid>
              <Grid item xs={6} md={3}>
                <TestDataAttribute id='total_loan_amount'>
                  <Typography>
                    {usdAmount(loanTerms.amount)}
                  </Typography>
                </TestDataAttribute>
              </Grid>
              {allowLoanPricing &&
                <>
                  <Grid item xs={6} md={3}>
                    <Typography>
                      <b>Interest Rate</b>
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <TestDataAttribute id='interest_rate'>
                      <Typography>
                        {percentage(loanTerms.rate)}
                      </Typography>
                    </TestDataAttribute>
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <Typography>
                      <b>{isInternal ? 'Capital Provider Points' : 'Points'}</b>
                    </Typography>
                  </Grid><Grid item xs={6} md={3}>
                    <TestDataAttribute id='points'>
                      <Typography>
                        {percentage(loanTerms.points)}
                      </Typography>
                    </TestDataAttribute>
                  </Grid>
                  {isInternal ? (
                    isOffHudBroker() ? (
                      <>
                        <Grid item xs={6} md={3}>
                          <Typography>
                            <b>Off Hud Broker Points</b>
                          </Typography>
                        </Grid>
                        <Grid item xs={6} md={3}>
                          <TestDataAttribute id="broker_points">
                            <Typography>
                              {percentage(loanTerms.referralFee)}
                            </Typography>
                          </TestDataAttribute>
                        </Grid>
                      </>
                    ) : (
                      <>
                        <Grid item xs={6} md={3}>
                          <Typography>
                            <b>On Hud Broker Points</b>
                          </Typography>
                        </Grid>
                        <Grid item xs={6} md={3}>
                          <TestDataAttribute id="broker_points">
                            <Typography>
                              {percentage(loanTerms.brokerPoints)}
                            </Typography>
                          </TestDataAttribute>
                        </Grid>
                      </>
                    )
                  ) : (
                    <>
                      <Grid item xs={6} md={3}>
                        <Typography>
                          <b>Broker Points</b>
                        </Typography>
                      </Grid>
                      <Grid item xs={6} md={3}>
                        <TestDataAttribute id="broker_points">
                          <Typography>
                            {percentage(loanTerms.brokerPoints)}
                          </Typography>
                        </TestDataAttribute>
                      </Grid>
                    </>
                   )}
                  </>
              }
              <Grid item xs={6} md={3}>
                <Typography>
                  <b>Term (Duration)</b>
                </Typography>
              </Grid>
              <Grid item xs={6} md={3}>
                <TestDataAttribute id='term_duration'>
                  <Typography>
                    {loanTerms.duration && `${loanTerms.duration} months`}
                  </Typography>
                </TestDataAttribute>
              </Grid>
              <Grid item xs={6} md={3}>
                <Typography>
                  <b>Interest Accrual method</b>
                  {isFixAndFlip(loanSubtype) && (
                    <Tooltip title={INTEREST_ACCRUAL_TOOLTIP} />
                  )}
                </Typography>
              </Grid>
              <Grid item xs={6} md={3}>
                <TestDataAttribute id='interest_accrual_method'>
                  <Typography>{loanTerms.interestAccrualMethod}</Typography>
                </TestDataAttribute>
              </Grid>
              <Grid item xs={6} md={3}>
                <Typography>
                  <b>Interest Reserve</b>
                  {isFixAndFlip(loanSubtype) && (
                    <Tooltip title={'The interest reserve is calculated based on the FICO of the Personal Guarantor'} />
                  )}
                </Typography>
              </Grid>
              <Grid item xs={6} md={3}>
                <TestDataAttribute id='interest_reserve'>
                  <Typography>
                    {loanTerms.interestReserveMonths &&
                      `${loanTerms.interestReserveMonths} months`}
                  </Typography>
                </TestDataAttribute>
              </Grid>
              {allowLoanPricing &&
                <>
                  {globalStore.userFeatures?.allowParticipation &&
                    <>
                      <Grid item xs={6} md={3}>
                        <Typography>
                          <b>Participation</b>
                        </Typography>
                      </Grid><Grid item xs={6} md={3}>
                        <TestDataAttribute id='participation'>
                          <Typography>{loanTerms.participation}</Typography>
                        </TestDataAttribute>
                      </Grid>
                      <Grid item xs={6} md={3}>
                        <Typography>
                          <b>Participation Percentage</b>
                        </Typography>
                      </Grid><Grid item xs={6} md={3}>
                        <TestDataAttribute id='participation_percentage'>
                          <Typography>
                            {percentage(loanTerms.origLenderSubordination)}
                          </Typography>
                        </TestDataAttribute>
                      </Grid>
                    </>
                  }
                  <Grid item xs={6} md={3}>
                    <Typography>
                      <b>Preferred Closing Attorney</b>
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <TestDataAttribute id="preferred_closing_attorney">
                      <Typography>{preferredClosingAttorneyName}</Typography>
                    </TestDataAttribute>
                  </Grid>
                </>
              }
              {(globalStore.userFeatures?.tamariskAppraisal || loanTerms.submitAppraisalWithTamarisk) && (
                <>
                  <Grid item xs={6} md={3}>
                    <Typography>
                      <b>Property Appraisal ordered with</b>
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <TestDataAttribute id="property_appraisal">
                      <Typography>Tamarisk</Typography>
                    </TestDataAttribute>
                  </Grid>
                </>
              )}
            </Grid>
            {isBackOfficeUser && !isBackOfficeProcessor && isInternal && (
              <>
                <br />
                <Grid item container direction="row" spacing={2}>
                  <Grid item xs={6} md={3}>
                    <Typography>
                      <b>Commission %</b>
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <TestDataAttribute id="commission_percent">
                      <Typography>{formatPercentage(loanCommissionStore.commissionData?.commissionPercentage ?? 0, 4)}</Typography>
                    </TestDataAttribute>
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <Typography>
                      <b>Commission Amount</b>
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <TestDataAttribute id="commission_amount">
                      <Typography>{formatCurrency(loanCommissionStore.commissionData?.commissionAmount ?? 0)}</Typography>
                    </TestDataAttribute>
                  </Grid>
                </Grid>
              </>
            )}
          </Card>
        </Grid>
        {!isBrokerUser && isInternal && loanSubtype === LoanSubType.GROUND_UP && properties.length > 0 && (
          <Grid container spacing={2} className={classes.profitContainer}>
            {properties[0].exitStrategy === 'Sale' ? (
              <Grid item xs={12}>
                <Card title="Flip Economics">
                  <PaperList>
                    <PaperListItem label="Sales Price" value={usdAmount(bridgeProfitAndDscr?.salesPrice || 0)} first />
                    <PaperListItem label="Total Project Costs" value={usdAmount(bridgeProfitAndDscr?.totalCosts || 0)} />
                    <PaperListItem label="Total Profit on Flip" value={usdAmount(quoteGeneratorDetailsStore.totalProfitOnFlip || 0)} total />
                  </PaperList>
                  <PaperList>
                    <PaperListItem label="Borrower Cash in Deal At Purchase" value={usdAmount(bridgeProfitAndDscr?.borrowerCashInDealAtPurchase || 0)} first />
                    <PaperListItem label="Borrower Cash in Deal (months)" value={usdAmount(bridgeProfitAndDscr?.borrowerCashInDeal || 0)} />
                    <PaperListItem label="Borrower ROI Cash in Deal in (months)" value={percentage(bridgeProfitAndDscr?.borrowerROIOnCashInDeal || 0)} total />
                  </PaperList>
                </Card>
              </Grid>
            ) : (
              <Grid item xs={12}>
                <Card title="Exit DSCR Calculation">
                  <PaperListItem label="Annual PITIA" value={usdAmount(bridgeProfitAndDscr?.annualPITIA || 0)} first />
                  <PaperListItem label="Monthly Principal & Interest Payment" value={usdAmount(bridgeProfitAndDscr?.monthlyPAndIP)} />
                  <PaperListItem label="Exit DSCR" value={percentage(bridgeProfitAndDscr?.dscr || 0)} total />
                </Card>
              </Grid>
            )}
          </Grid>
        )}
        {loanSubtype === LoanSubType.GROUND_UP && isInternal && (
          <QuoteGeneratorTermSheetDialog
            open={termSheetOpen}
            propQuoteGeneratorDetailsStore={quoteGeneratorDetailsStore}
            onClose={() => setTermSheetOpen(false)}
          />
        )}
        {isInternal && calculationStarted && loanSubtype === LoanSubType.GROUND_UP && (
          <Paper className={classes.termSheetContainer}>
            <Grid container style={{ padding: '0px 24px' }}>
              <Grid xs={12}>
                <Grid item xs={12} className={classes.termSheetDownload}>
                  <Typography style={{ marginRight: '8px' }}>
                    The term sheet is available for
                  </Typography>
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    startIcon={<CloudDownload />}
                    testId="download"
                    onClick={() => { setTermSheetOpen(true) }}
                  >
                    Download
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        )}
        <ErrorsAlert errors={[...allErrors, ...stepErrors]} />
        <WarningsAlert warnings={[...allWarnings, ...stepWarnings]} />
      </Grid>

      <ResubmitAsStabilizedModal
        open={resubmitAsStabilized}
        handleClose={closeModalResubmitStb}
      />

      <WimbaModal
        open={loanTerms.preferredTitle === WIMBA && !loanTerms.wimbaAcknowledged}
        handleClose={closeModal}
        handleConfirm={confirmModal}
      />
    </>
  );
};

export default observer(Summary);
