import { IconButton, ListItemText, Menu } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import { FilterList } from '@material-ui/icons';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import React from 'react';
import { MenuItem } from '../menuItem/menuItem';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popper: {
      // zIndex should be bigger than 1300 to work on modals
      zIndex: 2000,
    },
  })
);

export interface FilterButtonProps {
  onClearFilters: () => void;
  onToggleFilters: () => void;
}

export interface IconFilterButtonProps {
  onClearFilters: () => void;
  onToggleFilters: () => void;
  showFilters: boolean;
}

export const FilterButton = (props: FilterButtonProps) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleMenuItemClick = () => {
    props.onClearFilters();
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <ButtonGroup
        variant="contained"
        color="primary"
        size="small"
        ref={anchorRef}
        aria-label="split button"
        disableElevation
      >
        <Button size="small" onClick={() => props.onToggleFilters()}>
          Filter
        </Button>
        <Button
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        open={open}
        className={classes.popper}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  <MenuItem testId="clear-filters" onClick={handleMenuItemClick}>
                    Clear filters
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export const IconFilterButton = (props: IconFilterButtonProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isProfileMenuOpen = Boolean(anchorEl);

  const openProfileMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClearFilterMenuItemClick = () => {
    props.onClearFilters();
    setAnchorEl(null);
  };

  const handleToggle = () => {
    props.onToggleFilters();
    setAnchorEl(null);
  };

  const handleProfileMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        onClick={openProfileMenu}
        color="inherit"
      >
        <FilterList />
      </IconButton>
      <Menu
        getContentAnchorEl={null}
        anchorEl={anchorEl}
        keepMounted
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={isProfileMenuOpen}
        onClose={handleProfileMenuClose}
      >
        <MenuItem
          testId="clear-filter"
          onClick={() => handleClearFilterMenuItemClick()}
        >
          Clear Filters
        </MenuItem>
        <MenuItem
          testId={`${props.showFilters ? 'hide' : 'show'}-filter`}
          onClick={handleToggle}
        >
          {`${props.showFilters ? 'Hide' : 'Show'} Filter`}
        </MenuItem>
      </Menu>
    </>
  );
};
