import { GlobalStore, GridStore } from "@roc/feature-app-core";
import { makeObservable, observable, action } from "mobx";
import { TradeService } from '../service/tradeService';
import { ApiResponse } from '@roc/feature-app-core';
export class CreateTradeStore {
  public entityList: any[];
  public selectedTrade: any;
  public tradeGridStore: GridStore;
  private tradeService: TradeService;
  private globalStore: GlobalStore;
  public selectedEntity: any;
  constructor(globalStore: GlobalStore) {
    this.tradeService = new TradeService();
    this.globalStore = globalStore;
    this.tradeGridStore = new GridStore(
      () => this.fetchTradesCreateScreen(),
      null,
      50
    );

    // Hardcoded value. Will change to list fetched from the backend once new entities are included
    this.entityList = [{ label: 'Angsana', value: 38 }];

    makeObservable(this, {
      selectedTrade: observable,
      selectedEntity: observable,
      entityList: observable,
      reset: action
    });
  }

  fetchTradesCreateScreen = async () => {
    const gridFilters =
      this.tradeGridStore.gridData.meta.filters ?? {};
    const filters = {
      ...gridFilters,
    };
    const dropDownFilters =
      this.tradeGridStore.gridData.meta.dropdownFilters ?? {};
    try {
      const response: ApiResponse = await this.tradeService.getTradesCreateScreen(
        dropDownFilters,
        filters,
        this.tradeGridStore.gridData.meta.pageNumber,
        this.tradeGridStore.gridData.meta.pageSize,
        this.tradeGridStore.gridData.meta.sortBy,
        this.tradeGridStore.gridData.meta.sortDir,
      );
      return response;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching trades.',
      });
    }
  }

  selectTradeRow = (param) => {
    if (this.selectedTrade == param) {
      this.selectedTrade = null;
      return;
    }
    this.selectedTrade = param;
  }

  getLabelFromValue = (value) => {
    return this.entityList.find((entity) => entity.value === value).label;
  }

  submitNewTrade = async () => {
    try {
      const request = {
        entity: this.getLabelFromValue(this.selectedEntity),
        trade: {
          buyerId: this.selectedEntity,
          tradeId: this.selectedTrade.tradeId
        }
      };
      const response = await this.tradeService.createTrade(request);
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Trade was saved successfully.',
      });
      return response;
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while saving trade information.',
      });
    }
  }

  reset() {
    this.selectedTrade = null;
    this.selectedEntity = null;
  }
}