import { action, computed, flow, makeObservable, observable } from 'mobx';
import { GlobalStore } from '@roc/client-portal-shared/stores';

export abstract class SigmaDashboardsBaseStore {
  protected globalStore: GlobalStore;

  abstract getDashboardEmbededUrl(menuId: string);

  embededUrl: string;

  constructor(globalStore) {
    this.globalStore = globalStore;

    makeObservable(this, {
      getDashboardEmbededUrl: flow,
      embededUrl: observable,
      setEmbededUrl: action,
    });
  }

  setEmbededUrl(url: string) {
    this.embededUrl = url;
  }

}
