import { Box, Grid, Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { Add, CloudUpload, Delete } from '@material-ui/icons';
import { PDFPreview } from '@roc/client-portal-shared/components';
import { useStore } from '@roc/client-portal-shared/hooks';
import { Button, FileUpload, FileUploadModal, IconButton, Paper, TableCard } from '@roc/ui';
import { observer } from 'mobx-react';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { Grid as DataGrid, GridColumnProps } from '../../../components';
import { NextBackButtons } from '../../lenderDetails/components/lenderDetailsFormComponents';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(4),
  },
  circleWrapper: {
    height: '48px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  smallGreenCircle: {
    width: 12,
    height: 12,
    borderRadius: 8,
    backgroundColor: '#48AC00',
  },
  smallRedCircle: {
    width: 12,
    height: 12,
    borderRadius: 8,
    backgroundColor: '#E60909',
  },
  root: {
    '.ag-react-container': {
      border: '1px solid black',
    },
  },
  grid: {
    minHeight: '200px',
  },
}));

const columns: GridColumnProps[] = [
  {
    field: 'uploaded',
    headerName: 'Status',
    maxWidth: 100,
    cellRenderer: 'statusCellRenderer',
  },
  {
    field: 'documentLabel',
    headerName: 'Document',
    minWidth: 300,
    cellRenderer: 'documentCellRenderer',
    equals: () => false
  },
  {
    field: 'documentLabel',
    headerName: 'Actions',
    maxWidth: 200,
    cellRenderer: 'actionsCellRenderer',
    equals: () => false
  },
];

export const Documents = observer(({ companyProfileStore }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <DocumentsForm companyProfileStore={companyProfileStore} />
      <NextBackButtons
        renderBack
        renderNext
        companyProfileStore={companyProfileStore}
      />
    </Paper>
  );
});

export const DocumentsForm = observer(({ companyProfileStore }) => {
  const { lenderDetailsStore } = companyProfileStore;
  const {
    lenderDetailsFormStore,
    lenderDocumentsStore,
  } = lenderDetailsStore;

  const formStore = lenderDetailsFormStore;
  const gridStore = lenderDocumentsStore.gridStore;

  const classes = useStyles();
  const [activeDocumentType, setActiveDocumentType] = useState();

  const fileInputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    lenderDocumentsStore.loadData();
  }, []);

  const frameworkComponents = {
    statusCellRenderer: params => {
      if (!params.node.data) {
        return null;
      }
      const { uploaded } = params.node.data;
      const cssClass = uploaded
        ? classes.smallGreenCircle
        : classes.smallRedCircle;
      return (
        <Box className={classes.circleWrapper}>
          <div className={cssClass}></div>
        </Box>
      );
    },
    documentCellRenderer: params => {
      if (!params.node.data) {
        return null;
      }
      const { uploaded, documentId, documentType } = params.node.data;

      if (uploaded) {
        return (
          <Link
            component="button"
            variant="body2"
            onClick={() => {
              lenderDocumentsStore.previewDocument(documentId, documentType);
            }}
          >
            {params.value}
          </Link>
        );
      } else {
        return <Box>{params.value}</Box>;
      }
    },
    actionsCellRenderer: params => {
      if (!params.node.data) {
        return null;
      }
      const { uploaded, documentId, documentType } = params.node.data;
      return uploaded ? (
        <IconButton
          testId={`delete-document-${documentId}`}
          onClick={() => lenderDocumentsStore.deleteDocument(documentId)}
        >
          <Delete color="primary" />
        </IconButton>
      ) : (
        <IconButton
          testId={`upload-document-${documentId}`}
          onClick={() => {
            if (fileInputRef.current) {
              setActiveDocumentType(documentType);
              fileInputRef.current.click();
            }
          }}
        >
          <CloudUpload color="primary" />
        </IconButton>
      );
    },
  };

  const onFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const file = e.target.files[0];
    fileInputRef.current.value = '';
    if (file) {
      lenderDocumentsStore.uploadDocument(activeDocumentType, file);
    }
  };

  const closeFileUploadModal = () => {
    setUploadOpen(false);
  };

  const allowUpload = true;
  const [isUploadOpen, setUploadOpen] = useState(false);

  const onDocumentUpload = (files: FileUpload[]) => {
    for (const file of files) {
      lenderDocumentsStore.uploadDocument(removeAfterPeriod(file.name), file.file);
    }
  };

  function removeAfterPeriod(inputString) {
    const index = inputString.indexOf('.');
    return index >= 0 ? inputString.substring(0, index) : inputString;
  }

  return (
    <>
      <input
        ref={fileInputRef}
        id="fileInput"
        type="file"
        style={{ display: 'none' }}
        onChange={onFileChange}
        accept="application/pdf"
      />
      <Box sx={{ pt: 2, pb: 2, display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h6">UPLOAD DOCUMENTS</Typography>
        <Button
          testId='finish'
          variant='contained'
          color='primary'
          onClick={() => setUploadOpen(true)}
        >Add New Documents</Button>
      </Box>
      <DataGrid
        columns={columns}
        rows={gridStore.gridData.data.rows}
        frameworkComponents={frameworkComponents}
        isLoading={gridStore.isLoading}
        domLayout="autoHeight"
      />
      <PDFPreview
        open={lenderDocumentsStore.documentPreview != null}
        title={lenderDocumentsStore.documentPreview?.title}
        data={lenderDocumentsStore.documentPreview?.data}
        headers={lenderDocumentsStore.documentPreview?.headers}
        onClose={() => lenderDocumentsStore.closeDocumentPreview()}
      />
      <FileUploadModal
        title={`ADD NEW DOCUMENTS`}
        open={isUploadOpen}
        multiple
        onClose={closeFileUploadModal}
        onUpload={files => onDocumentUpload(files)}
      />
    </>
  );
});