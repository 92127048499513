import { flow, makeObservable } from 'mobx';
import { ApiResponse, AuthenticationStore, EnvironmentStore, GlobalStore } from '@roc/feature-app-core';
import { isLocalHost } from '@roc/feature-utils';

export class PublicAuthenticationStore extends AuthenticationStore {

  constructor(globalStore: GlobalStore, environmentStore: EnvironmentStore) {
    super(globalStore, environmentStore);
    makeObservable(this, {
      checkAuthentiationPublic: flow,
    });
  }

  *checkAuthentiationPublic(successCallback) {
    try {
      if (isLocalHost()) {
        this.isAuthenticated = false;
      }
      else {
        const refreshTokenResponse: ApiResponse = yield this.authenticationService.refreshToken(false);
        this.isAuthenticated = true;
        successCallback && successCallback();
      }
    } catch (e) {
      this.isAuthenticated = false;
    }
  }

};
