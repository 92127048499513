import { Box, Dialog, DialogTitle, DialogContent, DialogActions, FormControl, Grid, Typography } from '@material-ui/core';
import { Layout } from '@roc/feature-app-core';
import { Button, SelectField } from '@roc/ui';
import { Tabs } from '@roc/ui';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { LenderBankInfoGridPending } from './lenderBankInfoGrids/LenderBankInfoGridPending';
import { makeStyles, Theme } from '@material-ui/core/styles';
//import { LenderBankInfoGridRejected } from './lenderBankInfoGrids/lenderBankInfoGridRejected';
import { useLenderBankInformationStore } from '../../hooks/useLenderBankInformationStore';
import { LenderBankInfoPendingDocusign } from './lenderBankInfoGrids/lenderBankInfoPendingDocusign';

const TABS = [
  {
    label: 'Pending Docusign',
  },
  {
    label: 'Pending Approval',
  },
];


const useStyles = makeStyles((theme: Theme) => ({
  dialogStyle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  buttonStyle: {
    marginRight: '10px',
    height: '40px'
  }
}));


export const LenderBankInformationHome = observer(() => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [companyName, setCompanyName] = useState('');
  const { lenderBankInformationStore } = useLenderBankInformationStore();
  const { globalStore } = lenderBankInformationStore;
  const { notificationStore } = globalStore;
  const formFields = lenderBankInformationStore.verifyBankInformationDocusignFormStore.form.fields;
  const classes = useStyles();

  const onTabChange = (e, index) => setSelectedTab(index);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setConfirmationModalOpen(false);
    lenderBankInformationStore.setDefaults();
  };

  const handleConfirmation = () => {
    const companyId = formFields.companyId.value;
    getCompanyNameFromId(companyId);
    if (lenderBankInformationStore.checkRequest(companyId)) {
      setConfirmationModalOpen(true);
    } else {
      notificationStore.showErrorNotification({
        message: "You need to select a lender in order to send the request the bank information message"
      });
    }
  };

  const handleRequest = () => {
    const companyId = formFields.companyId.value;
    lenderBankInformationStore.verifyBankInformation(companyId);
    handleCloseModal();
  }

  const getCompanyNameFromId = (companyId) => {
    const company = lenderBankInformationStore.companies.filter(company => company.value === companyId)
    setCompanyName(company[0].label)
  }

  useEffect(() => {
    lenderBankInformationStore.getUserCompanies();
  }, []);

  return (
    <Layout>
      <Tabs
        tabs={TABS}
        selectedTab={selectedTab}
        onTabChange={onTabChange}
        right={
          <Box py={1} pr={2}>
            <Button style={{ marginRight: '10px', height: '40px' }}
              size='small'
              variant="contained"
              type="submit"
              onClick={handleOpenModal}
              testId="submit"
              color='primary'
            >
              <span>Request Bank Info</span>
            </Button>
          </Box>
        }
      />
      {selectedTab === 0 && (
        <LenderBankInfoPendingDocusign />
      )}
      {selectedTab === 1 && (
        <LenderBankInfoGridPending />
      )}
      <Dialog
        open={openModal || confirmationModalOpen}
        onClose={handleCloseModal}
        PaperProps={{
          style: {
            minWidth: '800px',
            maxWidth: '800px',
            minHeight: '270px',
            maxHeight: '270px',
          },
        }}
      >
        <DialogTitle>{confirmationModalOpen ? 'Confirm Bank Information Request' : 'Request Lender Bank Information'}</DialogTitle>
        <DialogContent className={classes.dialogStyle}>
          {confirmationModalOpen ? (
            <Box py={1} pr={2}>
              <Typography variant="h6">
                Are you sure you want to send the request bank verification information message to: {companyName} ?
              </Typography>
            </Box>
          ) : (
            <FormControl fullWidth>
              <Grid container alignItems="center" justify="center">
                <Grid item xs={12} sm={12}>
                  <SelectField
                    standaloneLabel
                    label={'Select a Lender to send the Bank Verification Message'}
                    name={'companyId'}
                    value={formFields.companyId.value}
                    errorText={formFields.companyId.error}
                    options={lenderBankInformationStore.companies}
                    onChange={value => lenderBankInformationStore.verifyBankInformationDocusignFormStore.onChange('companyId', value)}
                    variant="outlined"
                    fullWidth
                    shrink
                    required
                    testId="companyId"
                  />
                </Grid>
              </Grid>
            </FormControl>
          )}
        </DialogContent>
        <DialogActions>
          {confirmationModalOpen ? (
            <div>
              <Button
                className={classes.buttonStyle}
                size='small'
                variant="contained"
                type="submit"
                onClick={() => setConfirmationModalOpen(false)}
                color='primary'
                testId="rejectionButton"
              >
                No
              </Button>
              <Button
                className={classes.buttonStyle}
                size='small'
                variant="contained"
                type="submit"
                onClick={handleRequest}
                color='primary'
                testId="acceptanceButton"
              >
                Yes
              </Button>
            </div>
          ) : (
            <div>
              <Button
                className={classes.buttonStyle}
                size='small'
                variant="contained"
                type="submit"
                onClick={handleCloseModal}
                color='primary'
                testId="cancelButton"
              >
                Cancel
              </Button>
              <Button
                className={classes.buttonStyle}
                size='small'
                variant="contained"
                type="submit"
                onClick={handleConfirmation}
                color='primary'
                testId="requestingButton"
              >
                Request Bank Information
              </Button>
            </div>
          )}
        </DialogActions>
      </Dialog>
    </Layout>
  );
});
