import {
  ActionsColumn,
  DataGrid as Grid,
  GridColumnProps,
  StatusChip,
  StatusType
} from '@roc/ui';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useDocumentStore } from '@roc/feature-documents';
import { CloudUpload, Chat, FileCopy, Send } from '@material-ui/icons';
import { Badge } from '@material-ui/core';
import { useStore } from '@roc/client-portal-shared/hooks';

const noOpComparator = () => 0;

const columns: GridColumnProps[] = [
  {
    field: 'orderId',
    headerName: 'Order Id',
    maxWidth: 350,
    comparator: noOpComparator,
    sortable: false,
  },
  {
    field: 'propertyAddress',
    headerName: 'Property Address',
    minWidth: 300,
    comparator: noOpComparator,
    sortable: false,
  },
  {
    field: 'orderStatus',
    headerName: 'Status',
    maxWidth: 150,
    comparator: noOpComparator,
    cellRenderer: 'statusCellRenderer',
    sortable: false,
  },
  {
    field: 'creationDate',
    headerName: 'Order Creation Date',
    minWidth: 120,
    sortable: false,
    comparator: noOpComparator,
  },
  {
    field: 'paymentStatus',
    headerName: 'Payment Status',
    maxWidth: 120,
    comparator: noOpComparator,
    cellRenderer: 'paymentStatusCellRenderer',
    sortable: false,
  },
  {
    field: 'estimatedDeliveryDate',
    headerName: 'Delivery Date',
    maxWidth: 150,
    comparator: noOpComparator,
    sortable: false,
  },
  {
    field: 'actions',
    headerName: 'Actions',
    minWidth: 125,
    cellRenderer: 'actionsCellRenderer',
  },
];

export const AppraisalGrid1004d = observer(({ isCda }) => {
  const { documentStore } = useDocumentStore();
  const { documentFormStore } = documentStore;
  const { appraisalRecertificationOrderDetailsStore } = documentFormStore;
  const { appraisalRecertificationOrderGridStore, arrOrderGridStore } = appraisalRecertificationOrderDetailsStore;
  const { globalStore } = useStore();
  appraisalRecertificationOrderDetailsStore.setIsInternal(globalStore.isInternalPortal);

  const frameworkComponents = {
    statusCellRenderer: ({ value }) =>
      value ? (
        <StatusChip statusType={StatusType.DOCUMENT} label={value} size="small" />
      ) : null,
    paymentStatusCellRenderer: ({ value }) =>
      value === true ? (
        "Paid"
      ) : "Pending",
    actionsCellRenderer: params =>
      ActionsColumn({
        ...params,
        actions: getActions(params),
      }),
  };

  const getActions = params => [
    {
      icon: (
        <Badge badgeContent={params.node.data?.unreadMessages} color="primary">
          <FileCopy color="primary" />
        </Badge>
      ),
      onClick: () => { handleCopy(params.node.data?.paymentLink) },
    },
    {
      icon: <Send color="primary" />,
      onClick: () => { appraisalRecertificationOrderDetailsStore.requestRecertificationPayment(params.node.data?.propertyAppraisalId, 'Appraisal_Revision_Loan') },
    }
  ];

  const handleCopy = (link) => {
    navigator.clipboard.writeText(link);
    if (globalStore.isInternalPortal) {
      window.parent.postMessage(link, '*');
    }

    globalStore.notificationStore.showSuccessNotification({
      message: 'Link was copied to clipboard!',
    });
  };

  useEffect(() => {
    appraisalRecertificationOrderGridStore.fetchGridData();
  }, [appraisalRecertificationOrderDetailsStore]);

  return (
    <Grid
      columns={columns}
      rows={appraisalRecertificationOrderGridStore.gridData.data.rows}
      frameworkComponents={frameworkComponents}
      isLoading={appraisalRecertificationOrderGridStore.isLoading}
      showFilters={appraisalRecertificationOrderGridStore.showFilters}
      domLayout="autoHeight"
    />
  );
})