import React from 'react';
import {
  Box,
  Button,
  Drawer,
  IconButton,
  Typography,

  useMediaQuery,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { Close, SendOutlined } from '@material-ui/icons';
import { observer } from 'mobx-react';
import { ChatMessages } from './components/chatMessages';
import { MessageEditor } from './components/editor';
import { SectionList } from './components/sectionList';
import { ThreadType } from '@roc/feature-types';
import { useDocumentCommunicationStore } from './hooks/useDocumentCommunicationStore';

const useStyles = makeStyles((theme: Theme) => ({
  drawer: {
    '& .MuiDrawer-paper': {
      [theme.breakpoints.up('md')]: {
        width: 1000,
      },
      [theme.breakpoints.up('lg')]: {
        width: 1200,
      },
    },
  },
  drawerContent: {
    [theme.breakpoints.up('md')]: {
      width: 1000,
    },
    [theme.breakpoints.up('lg')]: {
      width: 1200,
    },
  },
}));

export const DocumentCommunication = observer(() => {
  const { documentCommunicationStore } = useDocumentCommunicationStore();
  const {
    isDrawerOpen,
    thread,
    editorState,
    isEditorContentEmpty,
  } = documentCommunicationStore;
  const classes = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const threadType = documentCommunicationStore?.thread?.type;

  const closeDrawer = event => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    documentCommunicationStore.reset();
  };



  return (
    <Drawer
      className={classes.drawer}
      anchor="right"
      open={isDrawerOpen}
      onClose={closeDrawer}
    >
      <Box display="flex" height="100%" className={classes.drawerContent}>
        {threadType === ThreadType.DOCUMENT && !isSmallScreen ? <SectionList /> : null}
        <Box
          display="flex"
          flexDirection="column"
          flexGrow="1"
        >
          <Box
            px={3}
            py={1}
            borderBottom="1px solid #eee"
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h4">{thread.title}</Typography>
            <IconButton
              aria-label="Close Drawer"
              component="span"
              onClick={closeDrawer}
            >
              <Close />
            </IconButton>
          </Box>
          <Box px={3} borderBottom="1px solid #eee" flexGrow="1" overflow="auto">
            <ChatMessages />
          </Box>
          <Box borderBottom="1px solid #eee">
            <MessageEditor />
          </Box>
          <Box p={2}>
            <Button
              disabled={isEditorContentEmpty}
              variant="contained"
              color="primary"
              onClick={() => documentCommunicationStore.sendComment()}
              endIcon={<SendOutlined />}
            >
              Send
            </Button>
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
});
