import { Route } from 'react-router';
import {
  Page,
} from '@roc/feature-app-core';
import { ValuationReviewDesign, ValuationReviewDesign2 } from '../valuationReviewDesign';

export const getValuationReviewInternalRoutesConfig = (
  basePath: string,
  baseUrl: string
) => {
  return {
    valuationReview: {
      path: `${basePath}/valuation-review1`,
      url: `${baseUrl}/valuation-review1`,
      documentTitle: 'Valuation Review1',
    },
    valuationReview2: {
      path: `${basePath}/valuation-review2`,
      url: `${baseUrl}/valuation-review2`,
      documentTitle: 'Valuation Review2',
    },
  };
};

export const getValuationReviewInternalRoutes = (
  basePath: string,
  baseUrl: string
) => {
  const config = getValuationReviewInternalRoutesConfig(basePath, baseUrl);
  return {
    valuationReview: (
      <Route exact path={config.valuationReview.path}>
        <Page routeDefinition={config.valuationReview}>
          <ValuationReviewDesign />
        </Page>
      </Route>
    ),
    valuationReview2: (
      <Route exact path={config.valuationReview2.path}>
        <Page routeDefinition={config.valuationReview2}>
          <ValuationReviewDesign2 />
        </Page>
      </Route>
    ),
  };
};
