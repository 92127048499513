import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Grid } from '@material-ui/core';
import { Borrower } from '../types';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import BorrowerInformation from './components/borrowerInformation';
import BorrowerTasks from './components/borrowerTasks';
import BorrowerTabs from './components/borrowerTabs';
import { BorrowersStore } from '../stores';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    stepsContentContainer: {
      margin: theme.spacing(4),
    },
  })
);

export type BorrowerPreScreenProps = {
  borrower?: Borrower;
  borrowersStore: BorrowersStore;
  companyId: number;
};

export const BorrowerPreScreen = observer((props: BorrowerPreScreenProps) => {
  const { borrower, borrowersStore, companyId } = props;
  const classes = useStyles();

  useEffect(() => {
    //
  }, [borrower]);

  return (
    <Grid container spacing={2}>
      <BorrowerInformation borrower={borrower} borrowersStore={borrowersStore} companyId={companyId} />

      <BorrowerTasks borrower={borrower} borrowersStore={borrowersStore} />

      <BorrowerTabs borrower={borrower} />
    </Grid>
  );
});

export default BorrowerPreScreen;
