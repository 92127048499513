import React, { useRef, useState } from 'react';
import {
  Box,

  Fab,
  Grid,

  Typography,
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {Theme} from '@material-ui/core/styles';
import {createStyles} from '@material-ui/core/styles';
import {observer} from 'mobx-react';
import {ConfirmDialog,Tabs} from '@roc/ui';
import {propertyOwnership} from '../../utils/constants';
import ProjectDetailsTab from './summaryPurchaseTab';
import DealSummaryTab from './summaryDealEconomics';
import {AlertDialog,Button} from '@roc/ui';
import {useProposalsStore} from '../../hooks/useProposalsStore';
import {proposalStatus} from '../../utils/constants';
import {useHistory,useParams} from 'react-router-dom';
import {useRoutes} from '@roc/client-portal-shared/hooks';
import {DocumentUpload} from '../documentComponents/documentUpload';
import {insertIf} from '@roc/feature-utils';
import { DealRoomConversation } from '@roc/feature-communication';
import {
  useCommunicationRoutesConfig,
} from '@roc/feature-communication';
import {Link as RouterLink} from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      margin: 0,
      top: 'auto',
      right: 20,
      bottom: 20,
      left: 'auto',
      position: 'fixed',
    },
  })
);

const Summary=observer(({onlyView=false,showConversation=false}) => {
  const history=useHistory();
  // const { proposalId } = useParams<{ proposalId: string }>();
  const {proposalsRoutesConfig}=useRoutes();
  const {proposalsStore}=useProposalsStore();
  const {detailsStore}=proposalsStore;
  const {propertyStore}=detailsStore;
  const propertyValues=propertyStore.propertyForm.form.fields;
  const {push}=useHistory();
  const [openDealRoomChatModal,setOpenDealRoomChatModal]=useState(false);
  const {communicationRoutesConfig}=useCommunicationRoutesConfig();
  const classes=useStyles();
  const {proposalId}=useParams<{proposalId: string}>();

  const store=propertyValues;

  const aquisition=propertyValues.acquisition.value;
  const isPurchase=propertyOwnership.PURCHASED===aquisition;
  const isOwned=propertyOwnership.OWNED_BY_BORROWER===aquisition;

  const [tab,setTab]=useState(0);
  const [isConfirmOpen,setIsConfirmOpen]=useState<boolean>(false);

  const onSubmit=() => {
    detailsStore.setProposalsDashboardUrl(proposalsRoutesConfig.proposalsHome.url);
    detailsStore.submitProposal();
  };

  return (
    <>
      <Grid container spacing={2}>
        {showConversation && (
          <Fab
            className={classes.fab}
            variant="extended"
            color="primary"
            aria-label="add"
            onClick={() =>
              push(
                communicationRoutesConfig.dealRoomConversations(
                  proposalId,
                  'view'
                ).url
              )
            }
          >
            Conversation
          </Fab>
        )}
        <Grid item xs={12}>
          <Tabs
            tabs={[
              { label: 'PROJECT DETAILS' },
              { label: 'DEAL ECONOMICS' },
              ...insertIf(onlyView, [
                {
                  label: 'DOCUMENTS UPLOAD',
                },
              ]),
            ]}
            selectedTab={tab}
            onTabChange={(e, index) => setTab(index)}
          />
        </Grid>
        {tab == 0 && <ProjectDetailsTab />}
        {tab == 1 && <DealSummaryTab />}
        {tab == 2 && <DocumentUpload />}
        {!onlyView ? (
          <Grid item xs={12}>
            <Box p={4}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                spacing={2}
              >
                <Grid item xs={6} md={3}>
                  <Button
                    variant="outlined"
                    color="primary"
                    fullWidth
                    onClick={() => {
                      detailsStore.goPrevStep();
                    }}
                    testId="previous"
                  >
                    Back
                  </Button>
                </Grid>
                <Grid item xs={6} md={3}>
                  <Button
                    variant="outlined"
                    color="primary"
                    fullWidth
                    onClick={() => setIsConfirmOpen(true)}
                    testId="previous"
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <Box p={4}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                spacing={2}
              >
                <Grid item xs={12} md={6}>
                  <Button
                    variant="outlined"
                    color="primary"
                    fullWidth
                    onClick={() =>
                      history.push(proposalsRoutesConfig.proposalsHome.url)
                    }
                    testId="backToDealRoom"
                  >
                    Back to Deal Room
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        )}
        <ConfirmDialog
          open={isConfirmOpen}
          title={`Submit Proposal`}
          body={`Are you sure you want to submit this proposal?`}
          handleCancel={() => setIsConfirmOpen(false)}
          handleClose={() => setIsConfirmOpen(false)}
          handleConfirm={onSubmit}
          confirmButtonText={'Confirm'}
        />
      </Grid>
      <DealRoomConversation proposalId={proposalId} tab={'view'} />
    </>
  );
});

export default Summary;
