import { Document } from '@roc/feature-types';
import { StandardDialog } from '@roc/ui';
import { observer } from 'mobx-react';
import { EscrowDepositCalculator } from '@roc/feature-escrow-deposit-calculator';
import { useStore } from '@roc/client-portal-shared/hooks';

export type TaxEscrowDialogProps = {
  open?: boolean;
  onClose?: () => void;
  loan: any;
  document: Document;
  refreshLoan?: () => void;
};

export const TaxEscrowDialog = observer((props: TaxEscrowDialogProps) => {
  const { open, onClose, loan, document, refreshLoan } = props;
  const { escrowDepositCalculatorStore } = useStore();

  return (
    <StandardDialog
      open={open}
      maxWidth="lg"
      handleClose={onClose}
      dialogContent={
        <EscrowDepositCalculator
          store={escrowDepositCalculatorStore}
          loanId={loan?.loanId}
          loanDocumentId={document?.loanDocumentId}
          refreshAndClose={() => {
            onClose();
            refreshLoan();
          }}
        />
      }
      removeContentBox
    />
  );
});

export default TaxEscrowDialog;