import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { Layout, Page } from '@roc/feature-app-core';
import { MyChatContainer, SelectConversation } from '@roc/feature-communication';
import { CopyText, Paper } from '@roc/ui';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import { Route, Switch, useHistory, useParams } from 'react-router-dom';
import { useCommunicationRoutesConfig } from './hooks';
import { CommunicationStore } from './stores/communicationStore';

const useStyles = makeStyles(theme => ({
  containerWrapper: {
    overflow: 'auto',
    flexGrow: 1,
    margin: theme.spacing(2, 0),
  },
  containerWrapperInternal: {
    overflow: 'auto',
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  layoutBody: {
    display: 'flex',
    height: '100%',
    margin: 0,
  },
  headingClass: {
    color: '#4B89DA',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
}));

interface ConversationHomeProps {
  communicationStore: CommunicationStore;
  isInternal?: boolean;
  showSnippet?: boolean;
  showEmailAddress?: boolean;
  allowNewConversations?: boolean;
  allowAddInternalParticipants?: boolean;
  allowRemoveParticipants?: boolean;
  allowJoinConversation?: boolean;
  onUserNewConversation?: (inviteUserId: number) => void;
  onConversationSelect?: (conversationId: string) => void;
  onOpenAddTask?: (userId: number) => void;
}

export const ConversationsHome = observer(({
  communicationStore,
  isInternal = false,
  showSnippet = false,
  showEmailAddress = false,
  allowNewConversations = false,
  allowAddInternalParticipants = false,
  allowRemoveParticipants = false,
  allowJoinConversation = false,
  onUserNewConversation,
  onConversationSelect,
  onOpenAddTask,
}: ConversationHomeProps) => {
  const classes = useStyles();
  const { communicationRoutesConfig } = useCommunicationRoutesConfig();
  const { showTitleCard } = communicationStore;
  const { loanId } = useParams<{ loanId: string }>();
  const { push } = useHistory();

  const _onConversationSelect = onConversationSelect ? onConversationSelect :
    (conversationId) => push(communicationRoutesConfig.conversations(loanId).children.conversation(conversationId).url)

  return (
    <Route path={communicationRoutesConfig.conversations(loanId).path}>
      <Page routeDefinition={communicationRoutesConfig.conversations(loanId)}>
        <Layout bodyContentBoxClass={classes.layoutBody} maxWidth={isInternal ? undefined : "lg"} minHeight={400}>
          {
            showTitleCard &&
            <Paper className={isInternal ? undefined : classes.paper}>
              <Box display="flex" alignItems="center">
                <Box display="flex" flexDirection="column" overflow="hidden">
                  <Typography
                    variant="h5"
                    className={clsx([classes.headingClass])}
                  >
                    <>
                      <span>#</span>
                      <CopyText tooltipText="Copy Loan Number to clipboard">
                        {loanId}
                      </CopyText>
                      {/* <span> - </span>
                      <span title={"Deal Name"}>
                        <CopyText tooltipText="Copy Deal Name to clipboard">
                            {"Deal Name"}
                        </CopyText>
                      </span> */}
                    </>
                  </Typography>
                </Box>
              </Box>
            </Paper>
          }
          <MyChatContainer
            id={Number(loanId)}
            communicationStore={communicationStore}
            className={isInternal ? classes.containerWrapperInternal : classes.containerWrapper}
            allowManageParticipants={true}
            allowNewConversations={allowNewConversations}
            allowAddInternalParticipants={allowAddInternalParticipants}
            allowRemoveParticipants={allowRemoveParticipants}
            allowJoinConversation={allowJoinConversation}
            showSnippet={showSnippet}
            showEmailAddress={showEmailAddress}
            onConversationSelect={_onConversationSelect}
            onUserNewConversation={onUserNewConversation} 
            onOpenAddTask={onOpenAddTask}/>
          <Switch>
            <Route exact path={communicationRoutesConfig.conversations().children.conversation().path}>
              <SelectConversation communicationStore={communicationStore} />
            </Route>
          </Switch>
        </Layout>
      </Page>
    </Route>
  )
});