import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { DropdownMenu } from '../dropdownMenu/dropdownMenu';

const useStyles = makeStyles((theme: Theme) => ({
  titleContainer: {
    width: '40%',
  },
  title: {
    color: '#333333',
    fontSize: 20,
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  menu: {
    marginLeft: theme.spacing(2),
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.primary.main,
  },
  actionsContainer: {
    alignItems: 'center',
    width: 360,
    justifyContent: 'flex-end',
    display: 'flex',
  },
}));

type TableCardHeader = {
  afterTitle?: JSX.Element;
  helperText: string;
  helperTextClass?: any;
  optionsTitle: string;
  options: Array<{ name: string; action: () => void }>;
  title: string | JSX.Element;
};

export const TableCardHeader = ({
  afterTitle,
  helperText,
  helperTextClass,
  optionsTitle,
  options,
  title,
}: TableCardHeader) => {
  const classes = useStyles();

  return (
    <Box
      display="flex"
      py={1}
      px={.5}
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid
        container
        alignItems="center"
        className={classes.titleContainer}
        spacing={2}
      >
        <Grid item>
          <Typography className={classes.title}>{title}</Typography>
        </Grid>
        <Grid item>{afterTitle}</Grid>
      </Grid>
      <div className={classes.actionsContainer}>
        <Typography className={helperTextClass}>
          <strong>{helperText}</strong>
        </Typography>
        {options.length > 0 && (
          <DropdownMenu
            options={options}
            containerClass={classes.menu}
            showArrow
          >
            {optionsTitle}
          </DropdownMenu>
        )}
      </div>

    </Box>
  );
};
