import { useLoanStore } from '@roc/feature-loans';
import { LoanProcess } from '@roc/feature-utils';
import { useLocation } from 'react-router-dom';

export const useExtractLoanProcessFromUrl = (): LoanProcess => {
  const { loanStore } = useLoanStore();
  const { pathname } = useLocation();
  const toDosFrameworkEnabled = loanStore.loanDetails?.toDosFrameworkEnabled;
  if (toDosFrameworkEnabled) {
    const loanProcess = location.pathname.match(/\/loans\/\d+\/([a-z]+)/)?.[1];
    return Object.values(LoanProcess).find(p => loanProcess === p);
  } else {
    const loanProcess = pathname.match(/([a-z]+)-documents/)?.[1];
    return loanProcess as LoanProcess;
  }
};
