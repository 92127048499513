import { EditableDialog } from '@roc/ui';
import { observer } from 'mobx-react';

import { OriginatorForm } from '../../originatorForm/originatorForm';
import SelectOriginatorsStore from '../../stores/selectOriginatorStore';

export type EditOriginatorsProps = {
  store: SelectOriginatorsStore;
};

export const EditOriginatorDialog = observer((props: EditOriginatorsProps) => {
  const { editDialogState } = props.store;

  const confirm = () => props.store.confirmEditOriginatorDialog();
  const close = () => props.store.closeEditOriginatorDialog();

  return (
    <EditableDialog
      open={editDialogState !== null}
      dialogState={editDialogState}
      title="Originator"
      handleClose={close}
      dialogContent={<OriginatorForm store={props.store} />}
      handleAdd={confirm}
      handleEdit={confirm}
    />
  );
});

export default EditOriginatorDialog;
