import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: 12,
      boxShadow: '0 0 2px 0 rgb(145 158 171 / 20%), 0 12px 24px -4px rgb(145 158 171 / 12%)'
    },
    cardHeader: {
      padding: '24px 24px 0px'
    }
  }),
);

interface PortalCardProps {
  children?: any
  cardTitle?: any
  cardSubTitle?: string
  cardHeaderClassName?: string
  cardHeaderStyle?: CSSProperties
  cardHeaderAction?: React.ReactNode
  cardContentClassName?: string
  cardFooterActions?: React.ReactNode
}

export const PortalCard = ({
  children,
  cardTitle,
  cardSubTitle,
  cardHeaderAction,
  cardHeaderClassName,
  cardHeaderStyle,
  cardContentClassName,
  cardFooterActions
}: PortalCardProps) => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card className={classes.root}>
      {
        (cardTitle || cardSubTitle || cardHeaderAction) &&
        <CardHeader
          action={cardHeaderAction}
          className={clsx(classes.cardHeader, cardHeaderClassName)}
          style={cardHeaderStyle ?? {}}
          title={cardTitle}
          titleTypographyProps={{
            variant: 'h6'
          }}
          subheader={cardSubTitle}
          subheaderTypographyProps={{
            variant: 'body2'
          }}
        />
      }
      <CardContent className={cardContentClassName}>
        {children}
      </CardContent>
      {
        cardFooterActions &&
        <CardActions disableSpacing>
          {cardFooterActions}
        </CardActions>
      }
    </Card>
  );
}
