import { action, flow, makeObservable, observable } from 'mobx';
import { ApiResponse } from '@roc/client-portal-shared/services';
import { GlobalStore, FormStore } from '@roc/feature-app-core';
import { ArrDetails } from '../modules/arrPaymentModule/types/arrPayments';
import { CdaArrPaymentsService } from '../services/cdaArrPaymentsService';
import { isNil } from '@roc/feature-utils';

const form = {
  fields: {
    fullName: {
      value: '',
      error: null,
      rule: 'required|regex:/^[A-Za-z\\s]+$/',
      message: 'Name is required (use only alphabetic characters)',
    },
    email: {
      value: '',
      error: null,
      rule: 'required|email',
      message: 'Email is required in correct format',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export class Appraisal1004dDocumentStore extends FormStore {
  private globalStore: GlobalStore;
  private cdaPaymentsService: CdaArrPaymentsService;
  fullName: string = null;
  email: string = null;
  properties: ArrDetails[] = null;
  totalAppraisalCost: number = null;
  feeCalcError: string = null;
  cardKnoxURL: string = null;
  loading: boolean = true;
  isLinkValid: boolean;
  feeAmount: number;
  rushed: boolean;
  businessDays: string;

  constructor(globalStore) {
    super({ ...form }, globalStore);
    this.cdaPaymentsService = new CdaArrPaymentsService();
    makeObservable(this, {
      loading: observable,
      isLinkValid: observable,
      getLoanInfoForARRPaymentLink: flow,
      getLoanInfoForCDAPaymentLink: flow,
      fetchBorrowerInfo: flow,
      checkIfValidLink: flow,
      setCardKnoxUrl: action,
      setProperties: action,
      setLoading: action,
      setTotalAppraisalCost: action,
      resetStore: action,
      setIsLinkValid: action,
      updateRushedNormalOption: flow,
    });
  }

  setLoading(value: boolean) {
    this.loading = value;
  }

  setCardKnoxUrl(url: string) {
    this.cardKnoxURL = url;
  }

  setProperties(properties: ArrDetails[]) {
    this.properties = properties;
  }

  setTotalAppraisalCost(cost: number) {
    this.totalAppraisalCost = cost;
  }

  setIsLinkValid(isValid: boolean) {
    this.isLinkValid = isValid;
  }

  setInitialData(data) {
    const { cardKnoxURL, appraisalDetailsList, totalAppraisalCost, feeCalcError, businessDays, feeAmount, rushed, asIsValue } = data;
    this.setCardKnoxUrl(cardKnoxURL);
    this.setProperties(appraisalDetailsList);
    this.setTotalAppraisalCost(totalAppraisalCost);
    this.feeCalcError = feeCalcError;
    this.businessDays = businessDays;
    this.feeAmount = feeAmount;
    this.rushed = rushed;
  }

  *updateRushedNormalOption(propertyAppraisalId: string, borrowerName: string, rushed: boolean) {
    this.setLoading(true);
    try {
      const response: ApiResponse = yield this.cdaPaymentsService.updateDropDownOption(
        propertyAppraisalId,
        borrowerName,
        rushed
      );
      const { data } = response;

      if (data) {
        this.setInitialData(data);
        this.setLoading(false);
      }
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'There was an error while retrieving payment information after update dropdown',
      });
    }
  }

  *getLoanInfoForARRPaymentLink(propertyAppraisalId: string, borrowerName: string, setShowBanner?: any) {
    this.setLoading(true);
    try {
      const response: ApiResponse = yield this.cdaPaymentsService.getStandaloneInfoForRecertificationTamariskPayment(
        propertyAppraisalId,
        borrowerName
      );
      const { data } = response;

      if (data) {
        const { appraisalDetailsList} = data;
        if(!isNil(appraisalDetailsList) && !isNil(setShowBanner)) {
          const type = appraisalDetailsList?.[0]?.appraisalType;
            if(!isNil(type) && type.includes('1004D')) {
              setShowBanner(true);
            }
        }
        this.setInitialData(data);
        this.setLoading(false);
      }
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'There was an error while retrieving payment information',
      });
    }
  }

  *getLoanInfoForCDAPaymentLink(propertyAppraisalId: string, borrowerName: string) {
    this.setLoading(true);
    try {
      const response: ApiResponse = yield this.cdaPaymentsService.getLoanInfoForCDAPaymentLink(
        propertyAppraisalId,
        borrowerName
      );
      const { data } = response;

      if (data) {
        this.setInitialData(data);
        this.setLoading(false);
      }
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'There was an error while retrieving payment information',
      });
    }
  }


  *fetchBorrowerInfo(id: string, type: string) {
    try {
      const response: ApiResponse = yield this.cdaPaymentsService.getCardholderInfo(
        id,
        type,
      );
      const { data } = response;

      if (data) {
        const { cardholderName, cardholderEmail } = data;
        this.onFieldChange('fullName', cardholderName);
        this.onFieldChange('email', cardholderEmail);
      }
    } catch (error) {
    }
  }

  *checkIfValidLink(id: string, tamariskFlowType: string) {
    this.setLoading(true);
    try {
      const response: ApiResponse = yield this.cdaPaymentsService.checkIfValidLink(id, tamariskFlowType);
      const { data } = response;
      this.setIsLinkValid(data);
      return data;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'There was an error while verifying payment link',
      });
    } finally {
      this.setLoading(false);
    }
  }

  resetStore() {
    this.form.fullName = null;
    this.form.email = null;
    this.fullName = null;
    this.properties = null;
    this.totalAppraisalCost = null;
    this.feeCalcError = null;
    this.cardKnoxURL = null;
    this.loading = false;
  }
}

export default Appraisal1004dDocumentStore;
