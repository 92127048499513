import { Box, Typography } from '@material-ui/core';
import React from 'react';

import internalServerErrorImage2 from './../../../assets/500.svg';

export const InvalidCertificate = ({ style = {} }) => {
  return (
    <Box display="flex" justifyContent="center" height="100%" bgcolor={"#f7f7f7"} style={style}>
      <Box display="flex" flexDirection="column" height="100%" alignItems="center" justifyContent="center" maxWidth={800}>
        <Box display="flex" alignItems="center" justifyContent="center" mb={4}>
          <img
            alt="Invalid Certificate Error"
            src={internalServerErrorImage2}
            style={{
              width: '80%',
            }}
          />
        </Box>
        <Box display="flex" alignItems="center" justifyContent="center" mb={4}>
          <Typography variant="h3" align='center'>
            SSL Certificate Error
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="center">
          <Typography paragraph variant="h6" align='center'>
            The server could not validate SSL Certificates or are invalid. Please contact IT Support
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
