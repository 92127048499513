import { Toolbar, FilterButton, GridRowSelectedEvent } from '@roc/ui';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { SelectBorrowersDialogStore } from '../stores/selectBorrowersDialogStore';
import { SelectBorrowersGrid } from './selectBorrowersGrid';

export type SelectBorrowerGridsProps = {
  multiple?: boolean;
  store: SelectBorrowersDialogStore;
};

export const SelectBorrowerGrids = observer(
  (props: SelectBorrowerGridsProps) => {
    const { multiple, store } = props;
    const { borrowerStore } = store;

    const onRowSelected = (e: GridRowSelectedEvent) => {
      const { node } = e;
      store.handleBorrowerSelect(node.data, node.isSelected());
    };

    useEffect(() => {
      store.reset();
      store.initSelected();
    }, [store]);

    useEffect(() => {
      store.fetch();
    }, [store]);

    const toolbar = (
      <Toolbar>
        <FilterButton
          onClearFilters={() => store.resetFilters()}
          onToggleFilters={() => store.toggleFilters()}
        />
      </Toolbar>
    );

    return (
      <SelectBorrowersGrid
        multiple={multiple}
        toolbar={toolbar}
        onRowSelected={onRowSelected}
        store={borrowerStore}
      />
    );
  }
);

export default SelectBorrowerGrids;
