import { useBaseStore } from '@roc/feature-app-core';
import {
  getProposalsRoutes,
  getProposalsRoutesConfig
} from '../routes/proposalsRoutes';

export const useProposalsRoutes = () => {
  const { globalStore } = useBaseStore();
  const { routeBasePath, routeBaseUrl } = globalStore;

  return {
    proposalsRoutes: getProposalsRoutes(routeBasePath, routeBaseUrl),
    proposalsRoutesConfig: getProposalsRoutesConfig(
      routeBasePath,
      routeBaseUrl
    ),
  };
};
