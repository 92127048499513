import { Grid, Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import {
  MultiFamilyStore,
} from '../../stores';
import { SelectBorrowers, SelectBorrowerMode, useBorrowersRoutes } from '@roc/feature-borrowers';
import { useBaseStore } from '@roc/feature-app-core';
import { BorrowerFormMultifamily } from './borrowerFormMultifamily';
import { LoanType } from '@roc/feature-utils';

interface BorrowerInformationProps {
  store: MultiFamilyStore;
}

const MultiFamilyBorrowerInformation = ({ store }: BorrowerInformationProps) => {
  const { globalStore } = useBaseStore();
  const { isNewEntity } = store;
  const { borrowersRoutesConfig } = useBorrowersRoutes();

  // const { allowEditBorrowers } = useAppConfigurations();

  let modes = [SelectBorrowerMode.EXISTING];
  if (globalStore.userFeatures?.addNewBorrowerDuringSubmission) {
    modes = [...modes, SelectBorrowerMode.NEW];
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4">Borrower information</Typography>
          <br />
          <Typography variant="body1">
            {isNewEntity
              ? 'Please add the borrowers of this loan. '
              : 'Please review the borrowers of this loan. '}
            <>
              To add a new borrower, please navigate to
              <Link
                href={borrowersRoutesConfig.borrowers.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span> View Borrowers.</span>
              </Link>
            </>
          </Typography>
        </Grid>
      </Grid>

      <br />

      <Grid container spacing={2}>
        <SelectBorrowers
          multiple={true}
          modes={modes}
          store={store.selectBorrowersStore}
          borrowerForm={(borrowerFormStore, currentBorrower) => (
            <BorrowerFormMultifamily
              store={borrowerFormStore}
              selectBorrowersStore={store.selectBorrowersStore}
              multiFamilyStore={store}
              currentBorrower={currentBorrower}
            />
          )}
          borrowerCardDisplayFields={{
            firstName: true,
            lastName: true,
            cellPhone: true,
            emailAddress: true,
            personalGuarantor: true,
            pctOwnership: true,
            experience: true,
            citizenshipStatus: true,
            roleInTransaction: true,
          }}
          loanType={LoanType.RESIDENTIAL_BRIDGE}
        />
      </Grid>
    </>
  );
};

export default observer(MultiFamilyBorrowerInformation);
