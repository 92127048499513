import { BorrowerReviseScopeOfWork } from "@roc/feature-borrower-draws";

export interface ScopeOfWorkTabProps {
  loanId: string
}

// The redesign work should be done here
export const ScopeOfWorkTab = ({ loanId }: ScopeOfWorkTabProps) => {
  return (
    <BorrowerReviseScopeOfWork loanId={loanId} />
  );
};
