import { GlobalStore } from '@roc/feature-app-core';
import { DateFormat, formatDate, generateUUID } from '@roc/feature-utils';
import { action, makeObservable, observable } from 'mobx';
import { PropertyFormStore } from './propertyFormStore';

export abstract class PropertiesStore {
  protected globalStore: GlobalStore;
  protected loanSubType: string;

  public propertyFormStores: PropertyFormStore[];

  public key = generateUUID();

  abstract createPropertyForm(): PropertyFormStore;

  constructor(globalStore, loanSubType) {
    this.globalStore = globalStore;
    this.loanSubType = loanSubType;
    makeObservable(this, {
      reset: action,
      propertyFormStores: observable,
      createPropertyForm: action,
      addNewProperty: action,
      removeProperty: action,
      removePropertyInAccordion: action,
      validateAllProperties: action,
      turnOffPropertiesEditMode: action,
      getProperties: action,
      loadProperties: action,
    });
  }

  reset() {
    this.propertyFormStores = [];
    this.addNewProperty();
  }

  addNewProperty() {
    this.propertyFormStores.push(this.createPropertyForm());
  }

  removeProperty(propertyKey: string) {
    this.propertyFormStores = this.propertyFormStores.filter(
      property => property.form.meta.key !== propertyKey
    );
  }

  removePropertyInAccordion(propertyKey: string) {
    this.propertyFormStores = this.propertyFormStores.filter(
      property => property.key !== propertyKey
    );
  }

  validateAllProperties() {
    let isValid = true;

    this.propertyFormStores.forEach(store => {
      store.validateAndCleanupProperty();
      isValid = isValid && store.form.meta.isValid;
      store.setEditMode(!isValid);
    });

    if (!isValid) {
      this.globalStore.notificationStore.showWarningNotification({
        message: 'Please complete all the required fields for the properties.',
      });
    }
    return isValid;
  }

  turnOffPropertiesEditMode() {
    this.propertyFormStores.forEach(propertyFormStore => {
      propertyFormStore.form.meta.editMode = false;
    });
  }

  getProperties() {
    return this.propertyFormStores.map(form => form.getProperty());
  }

  loadProperties(properties) {
    if (properties?.length > 0) {
      this.propertyFormStores = properties?.map(borrower => {
        const form = this.createPropertyForm();
        form.loadProperty(borrower);
        form.setEditMode(false);
        return form;
      });
    }
  }
}
