import { Route } from 'react-router';
import { Page } from '@roc/feature-app-core';
import { ReferralFeeLink } from './../components/referralFeeLink';
import { useRocInternalPortalStore } from 'apps/roc-internal-portal/src/app/hooks';

export const getReferralFeeLinkRoutesConfig = (
  basePath: string,
  baseUrl: string,
) => {
  return {
    referralFeeLink: {
      path: `${basePath}/referral-fee-link`,
      url: `${baseUrl}/referral-fee-link`,
    },
  };
};

export const getReferralFeeLinkRoutes = (
  basePath: string,
  baseUrl: string,
  loanId: string,
  brokerId: string
) => {
  const config = getReferralFeeLinkRoutesConfig(basePath, baseUrl);
  const { referralFeeInternalStore } = useRocInternalPortalStore();

  const onClose = () => {
    window.parent.postMessage('approve-bank-info', '*');
  }

  return {
    referralFeeLink: (
      <Route exact path={config.referralFeeLink.path}>
        <Page routeDefinition={config.referralFeeLink}>
          <ReferralFeeLink onClose={onClose} loanId={loanId} referralFeeStore={referralFeeInternalStore} brokerId={brokerId} />
        </Page>
      </Route>
    ),
  };
};
