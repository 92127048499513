import { InfiniteScrollGrid } from '@roc/feature-app-core';
import { useLoanRoutes } from '@roc/feature-loans-routes-config';
import { formatDate } from '@roc/feature-utils';
import {
  AgGridColumnProps,
  DataGrid,
  LinkColumn,
  SelectFilterComponent,
  SelectFloatingFilterComponent,
  StatusChip,
  StatusType,
} from '@roc/ui';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { usePayoffRequestStore } from '../loanDetails/modules/loanRequests/hooks/usePayoffRequestStore';
import { Box } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

const noOpComparator = () => 0;

const columns: AgGridColumnProps[] = [
  {
    field: 'payoffNumber',
    headerName: 'Payoff #',
    minWidth: 130,
    cellRenderer: 'payoffNumberCellRenderer',
    comparator: noOpComparator,
    pinned: 'left',
    lockPinned: true,
    sortable: false,
  },
  {
    field: 'loanId',
    headerName: 'Loan Id',
    minWidth: 100,
    cellRenderer: 'loanIdCellRenderer',
    filter: 'agTextColumnFilter',
    comparator: noOpComparator,
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'payoffStatus',
    headerName: 'Status',
    minWidth: 190,
    maxWidth: 300,
    comparator: noOpComparator,
    filter: 'selectFilter',
    cellRenderer: 'statusCellRenderer',
    floatingFilter: true,
    floatingFilterComponent: 'selectFloatingFilter',
    floatingFilterComponentParams: {
      suppressFilterButton: true,
      options: [
        { label: 'Payoff Requested', value: 'Payoff Requested' },
        {
          label: 'Forwarded to Servicer',
          value: 'Forwarded to Servicer',
        },
        { label: 'Approval & Forwarding Pending', value: 'Approval & Forwarding Pending' },
        { label: 'Approved', value: 'Approved' },
        { label: 'Retracted', value: 'Retracted' },
      ],
    },
  },
  {
    field: 'requestedDate',
    headerName: 'Request Date',
    minWidth: 120,
    filter: 'agTextColumnFilter',
    comparator: noOpComparator,
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'goodThroughDate',
    headerName: 'Good Through Date',
    minWidth: 130,
    cellRenderer: 'goodThroughDateCellRenderer',
    comparator: noOpComparator,
    filter: 'agDateColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
];

export const MyPayoffsGrid = observer(props => {
  const { payoffRequestStore } = usePayoffRequestStore();
  const { loanRoutesConfig } = useLoanRoutes();

  return (
    <InfiniteScrollGrid
      toolbar={props.toolbar}
      columns={columns}
      frameworkComponents={getFrameworkComponents(loanRoutesConfig)}
      store={payoffRequestStore.myPayoffsGridStore}
    />
  );
});

export const MyPayoffsGridPaginated = observer(props => {
  const { payoffRequestStore } = usePayoffRequestStore();
  const { myPayoffsGridStore } = payoffRequestStore;
  const { loanRoutesConfig } = useLoanRoutes();

  useEffect(() => {
    myPayoffsGridStore.resetAndFetchGridData();
  }, []);

  const onSortChanged = params => {
    const sortModel = params.api.getSortModel();
    myPayoffsGridStore.setSortModel(sortModel, false);
  };

  const onFilterChanged = params => {
    const filterModel = params.api.getFilterModel();
    myPayoffsGridStore.setFilterModel(filterModel, false);
  };

  return (
    <DataGrid
      toolbar={props.toolbar}
      columns={columns}
      rows={myPayoffsGridStore.gridData.data.rows}
      frameworkComponents={getFrameworkComponents(loanRoutesConfig)}
      onSortChanged={onSortChanged}
      onFilterChanged={onFilterChanged}
      isLoading={myPayoffsGridStore.isLoading}
      showFilters={myPayoffsGridStore.showFilters}
      sortModel={myPayoffsGridStore.sortModel}
      filterModel={myPayoffsGridStore.filterModel}
      paginationData={myPayoffsGridStore.gridData.meta}
      setPageNumber={myPayoffsGridStore.setPageNumber}
      domLayout="autoHeight"
      isError={myPayoffsGridStore.isError}
    />
  );
});

const getFrameworkComponents = (loanRoutesConfig) => {
  return {
    selectFilter: SelectFilterComponent,
    selectFloatingFilter: SelectFloatingFilterComponent,
    dateCellRenderer: ({ value }) => {
      return value ? formatDate(value, 'MM/dd/yyyy') : '';
    },
    payoffNumberCellRenderer: params => {
      if (!params.value) {
        return '';
      }
      const payoffId = params.node.data?.loanPayoffData;
      const loanId = params.node.data?.loanId;
      const { onClick } = params;
      const value = `${loanId} - Payoff ${params.value}`;
      return LinkColumn({
        value: value,
        onClick,
        url: loanRoutesConfig.loans(loanId).children.payoffRequest.url,
      });
    },
    loanIdCellRenderer: params => {
      if (!params.value) {
        return '';
      }
      const { onClick } = params;
      return LinkColumn({
        value: params.value,
        onClick,
        url: loanRoutesConfig.loans(params.value).children.payoffRequest.url,
      });
    },
    statusCellRenderer: ({ value }) =>
      value ? (
        <StatusChip
          statusType={StatusType.PAYOFFS}
          label={value}
          size='small'
          variant={(value == 'Approved') ? 'filled' : 'outlined'} />
      ) : null,
  }
};

export const RecentPayoffsGrid = observer(({
  onDataLoad
}: {
  onDataLoad?: (rows: any[], totalCount: number) => void
}) => {
  const { payoffRequestStore } = usePayoffRequestStore();
  const { myPayoffsGridStore } = payoffRequestStore;
  const { loanRoutesConfig } = useLoanRoutes();
  const [dataRows, setDataRows] = useState([]);

  useEffect(() => {
    myPayoffsGridStore.resetAndFetchGridData();
  }, []);

  useEffect(() => {
    if (myPayoffsGridStore.gridData?.data?.rows?.length) {
      const slicedData = myPayoffsGridStore.gridData?.data?.rows.slice(0, 5);
      setDataRows(slicedData);
      onDataLoad && onDataLoad(slicedData, myPayoffsGridStore.gridData?.data?.rows?.length);
    } else {
      setDataRows([]);
      onDataLoad && onDataLoad([], 0)
    }
  }, [myPayoffsGridStore.gridData.data.rows]);

  const recentPayoffsGridColumns = columns.filter((col) => {
    return col.field != 'loanId'
  }).map((col) => {
    return {
      ...col,
      sortable: false,
      floatingFilter: false,
      lockPinned: false,
      pinned: false,
    }
  });

  return (
    <>
      {
        myPayoffsGridStore.isLoading &&
        <Box m={2}>
          Loading ...
        </Box>
      }
      {
        !myPayoffsGridStore.isLoading && dataRows?.length == 0 &&
        <Box m={2}>
          <Alert severity="info">
            You haven't requested a payoff for any of your loans recently.
          </Alert>
        </Box>
      }
      {
        !myPayoffsGridStore.isLoading && dataRows?.length > 0 &&
        <DataGrid
          columns={recentPayoffsGridColumns}
          rows={dataRows}
          frameworkComponents={getFrameworkComponents(loanRoutesConfig)}
          isLoading={myPayoffsGridStore.isLoading}
          domLayout="autoHeight"
          isError={myPayoffsGridStore.isError}
        />
      }
    </>
  );
});

export default MyPayoffsGrid;
