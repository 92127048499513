import { GlobalStore, UserStore } from '@roc/feature-app-core';
import { FixFlipQuoteStore } from '../quote/stores/fixFlipQuoteStore';
import { GroundUpQuoteStore } from '../quote/stores/groundUpQuoteStore';
import { QuotesDashboardStore } from '../quote/stores/dashboard/quotesDashboardStore';
import { StabilizedBridgeQuoteStore } from '../quote/stores/stabilizedBridgeQuoteStore';

export class OneToolStore {
  private globalStore: GlobalStore;
  private userStore: UserStore;

  quotesDashboardStore: QuotesDashboardStore;

  fixFlipQuoteStore: FixFlipQuoteStore;
  groundUpQuoteStore: GroundUpQuoteStore;
  stabilizedBridgeQuoteStore: StabilizedBridgeQuoteStore;

  constructor(globalStore: GlobalStore, userStore: UserStore) {
    this.globalStore = globalStore;
    this.userStore = userStore;

    this.quotesDashboardStore = new QuotesDashboardStore(globalStore);
    this.fixFlipQuoteStore = new FixFlipQuoteStore(globalStore, this.userStore);
    this.groundUpQuoteStore = new GroundUpQuoteStore(globalStore, this.userStore);
    this.stabilizedBridgeQuoteStore = new StabilizedBridgeQuoteStore(
      globalStore, this.userStore
    );
  }

  reset() {
    this.fixFlipQuoteStore.reset();
    this.groundUpQuoteStore.reset();
    this.stabilizedBridgeQuoteStore.reset();
  }
}
