import {
  CreditReviewForm,
  useCreditReviewExternalStore,
} from '@roc/feature-borrower-review';
import { observer } from 'mobx-react';

export const CreditInfoTab = observer(({ borrower }) => {
  const { creditReviewStore } = useCreditReviewExternalStore();
  const { creditItemStore } = creditReviewStore;

  if (!borrower?.borrowerId) {
    return null;
  }
  creditItemStore.currentBorrower = borrower.borrowerId;
  creditItemStore.isInternal = false;
  creditItemStore.initializeService();

  return (
    <CreditReviewForm
      showEmptySections={false}
      showBorrowerInformation={false}
      showResolutions={false}
      showButtons={false}
      store={creditItemStore}
    />
  );
});
