import React, { useEffect } from 'react';
import { Box, Grid } from '@material-ui/core';
import { StandardDialog, Button } from '@roc/ui';
import { observer } from 'mobx-react';
import {
  ScopeOfWorkV2LinkStore,
  ScopeOfWorkV2Option,
} from '@roc/feature-sow-shared/stores/v2';
import { useBaseStore } from '@roc/feature-app-core';
import { ScopeOfWorkV2LinkInternalDialog } from './scopeOfWorkV2LinkInternalDialog';
import { ScopeOfWorkV2LinkExternalDialog } from './scopeOfWorkV2LinkExternalDialog';
import { Alert } from '@material-ui/lab';
import { useDocumentStore } from '@roc/feature-documents';
import { Document } from '@roc/feature-types';

export type ScopeOfWorkV2LinkDialogProps = {
  open?: boolean;
  onClose?: () => void;
  loanId: number;
  propertyId: number;
  propertyAddress: string;
  store: ScopeOfWorkV2LinkStore;
};

export type BorrowerScopeOfWorkV2LinkDialogProps = {
  open?: boolean;
  onClose?: () => void;
  loanId: number;
  document: Document;
};

export const ScopeOfWorkV2LinkDialog = observer(
  (props: ScopeOfWorkV2LinkDialogProps) => {
    const { globalStore } = useBaseStore();
    if (globalStore.isInternalPortal) {
      return <ScopeOfWorkV2LinkInternalDialog {...props} />;
    } else {
      return <ScopeOfWorkV2LinkExternalDialog {...props} />;
    }
  }
);

export const BorrowerScopeOfWorkV2LinkDialog = observer(
  (props: BorrowerScopeOfWorkV2LinkDialogProps) => {
    const { globalStore } = useBaseStore();
    const { documentStore } = useDocumentStore();
    const store = documentStore.documentFormStore.scopeOfWorkV2LinkStore;
    const document = props.document;

    const createScopeOfWork = () => {
      store.setValues(document?.loanId, document?.collateralId, document?.collateralAddress);
      store.setScopeOfWorkOption(ScopeOfWorkV2Option.NEW_SCOPE_OF_WORK);
      store.tryAndLinkScopeOfWork();
    }
    

    return (
      <StandardDialog
        open={props.open}
        title="Scope of Work"
        maxWidth="sm"
        handleClose={props.onClose}
        removeContentBox
        dialogContent={
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Alert severity={'info'}>
                No Scope of Work is yet associated with this property.
              </Alert>
            </Grid>
            <Grid item xs={12}>
              Click on the button below to create a new scope of work or reach out
              to your originator to link an existing scope of work.
            </Grid>
            <Grid item xs={12}>
              <Box width={'100%'} mb={2} display="flex" justifyContent="center">
                <Button
                  variant="contained"
                  color="primary"
                  testId={'create-new-sow'}
                  onClick={createScopeOfWork}
                >
                  Create A New Scope Of Work
                </Button>
              </Box>
            </Grid>
          </Grid>
        }
      />
    );
  }
);

export default ScopeOfWorkV2LinkDialog;
