import { Box, Grid, Link} from '@material-ui/core';
import FileIcon from './../assets/icon-file.png';
import { makeStyles, lighten, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    cursor: 'pointer',
    display: 'block',
    padding: 8,
    color: theme.palette.grey[900],
    '&:hover': {
      color: theme.palette.primary.dark,
      backgroundColor: lighten(theme.palette.primary.main, .9),
      textDecoration: 'none',
    },
  },
}));


export const ReportItem = ({ name, onClick }) => {
  const classes = useStyles();

  return (
    <Link
      variant="button"
      className={classes.link}
      onClick={() => onClick()}
    >
      <Grid item container alignItems="center" style={{
        flexWrap: 'nowrap'
      }}>
        <Grid item>
          <Box p={1} display="flex" alignItems="center">
            <img
              src={FileIcon}
              style={{
                maxWidth: 48,
              }}
            />
          </Box>
        </Grid>
        <Grid item>
          {name}
        </Grid>
      </Grid>
    </Link>
  );
};
