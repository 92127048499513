import { SigmaDashboardsBaseStore } from '../stores/sigmaDashboardsBaseStore';
import { SigmaDashboardsService } from '../services/sigmaDashboardsService';
import { makeObservable, override } from 'mobx';

export class SigmaDashboardsStore extends SigmaDashboardsBaseStore {
  private sigmaDashboardsService: SigmaDashboardsService;

  constructor(globalStore) {
    super(globalStore);
    this.sigmaDashboardsService = new SigmaDashboardsService();

    makeObservable(this, {
      getDashboardEmbededUrl: override,
    });
  }

  *getDashboardEmbededUrl(menuId: string) {
    try {
      const response = yield this.sigmaDashboardsService.getEmbededUrl(menuId);
      this.setEmbededUrl(response.data.data);
    } catch (err) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while getting dashboard embeded url',
      });
    }
  }

}
