import { useDocumentStore } from '@roc/feature-documents';
import { DialogState, EditableDialog, TextField } from '@roc/ui';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';

export const RenameModal = observer(
  ({ open, onClose, document, loanId, loanProcess }) => {
    const { documentStore } = useDocumentStore();
    const { loanTodosStore } = documentStore;
    const [value, setValue] = useState('');

    useEffect(() => {
      if (open) {
        setValue(document.documentName);
      }
    }, [open]);

    const handleRename = async () => {
      if (value) {
        await loanTodosStore.renameDocument(document, value);
        loanTodosStore.fetchTodos(loanId, loanProcess);
        onClose();
      }
    };

    const dialogContent = (
      <TextField
        type={'text'}
        testId={'todo-name'}
        variant={'outlined'}
        label={'Edit To-do Name'}
        standaloneLabel
        value={value}
        onChange={e => setValue(e.target.value)}
        fullWidth
      />
    );

    return (
      <EditableDialog
        open={open}
        title={'To-do'}
        dialogState={DialogState.EDIT}
        dialogContent={dialogContent}
        handleClose={onClose}
        onClose={onClose}
        editButtonText={'Save'}
        handleEdit={handleRename}
        maxWidth={'sm'}
      />
    );
  }
);
