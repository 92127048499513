import { action, flow, makeObservable, observable } from 'mobx';
import { format } from 'date-fns';
import { FormStore, GlobalStore, ApiResponse, GridStore } from '@roc/feature-app-core';
import { LoanRequestsService } from './../services/loanRequestsService';
import { LoanStore } from './../../../../stores/loanStore';
import { Payoff } from '../../../payoffs/types/payoffs';
import { LoanService } from 'libs/feature-loans/src/loans/services/loanService';
import { GENERIC_ERROR_MESSAGE } from '@roc/feature-utils';
import { ServicerFeesFormStore } from './servicerFeesFormStore';

const form = {
  fields: {
    goodThroughDate: {
      value: null,
      error: null,
      rule: '',
    },
    requestedDate: {
      value: Date.now(),
      error: null,
      rule: '',
    },
    comments: {
      value: '',
      error: null,
      rule: 'required',
    },
    rush: {
      value: false,
      error: null,
      rule: '',
    },
    requesterEmail: {
      value: '',
      error: null,
      rule: '',
    }
  },
  meta: {
    isValid: false,
    error: null,
  },
};


const loanPayoffForm = {
  fields: {
    expectedPayoffDate: {
      value: null,
      error: null,
      rule: '',
    },
    totalInterestReceived: {
      value: '',
      error: null,
    },
    totalProceeds: {
      value: '',
      error: null,
      rule: '',
    },
    refundToBorrower: {
      value: '',
      error: null,
      rule: '',
    }
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export class PayoffRequestStore extends FormStore {
  globalStore: GlobalStore;
  servicerFeesFormStore: ServicerFeesFormStore;
  private loanRequestsService: LoanRequestsService;
  private loanStore: LoanStore;
  public saved: boolean;
  currentPayoff: Payoff = null;
  public loanPayoffs: Payoff[] = [];
  public defaultAllocation = null;
  public foreclosureInterestAllocation = [];
  disableCreatePayoff: boolean;
  myPayoffsGridStore: GridStore;
  private loanService: LoanService;
  loanPayoffForm: FormStore;
  isForeclosure: boolean;

  constructor(globalStore, loanStore) {
    super({ ...form }, globalStore);
    this.globalStore = globalStore;
    this.loanRequestsService = new LoanRequestsService();
    this.loanStore = loanStore;
    this.saved = false;
    this.disableCreatePayoff = false;
    this.loanService = new LoanService();
    this.myPayoffsGridStore = new GridStore(
      () => this.fetchMyPayoffs(),
      null,
      50,
    );
    this.loanPayoffForm = new FormStore(
      loanPayoffForm,
      globalStore
    );
    this.servicerFeesFormStore = new ServicerFeesFormStore(this.globalStore, this.loanRequestsService);
    makeObservable(this, {
      onFormSubmit: flow,
      onFormSubmitV2: flow,
      onFormSubmitV3: flow,
      saved: observable,
      setSaved: action,
      currentPayoff: observable,
      getActivePayoffDataByLoanId: flow,
      loanPayoffs: observable,
      defaultAllocation: observable,
      foreclosureInterestAllocation: observable,
      getPayoffsByLoanId: flow,
      retractPayoff: flow,
      disableCreatePayoff: observable,
      onLoanPayoffDetailsSubmit: flow,
      initPayoffDetails: flow,
      requestPayoffAssistance: flow,
      addServicerAccountingTeam: flow,
      getDefaultAllocationDashboard: flow,
      setIsForeclosure: action,
      isForeclosure: observable,
      getForeclosureInterestAllocationDashboard: flow,
    });
  }

  setSaved(saved) {
    this.saved = saved;
  }

  setIsForeclosure(isForeclosure) {
    this.isForeclosure = isForeclosure;
  }

  private async fetchMyPayoffs() {
    const gridFilters = this.myPayoffsGridStore.gridData.meta.filters ?? {};
    const filters = {
      ...gridFilters,
      ...this.myPayoffsGridStore.gridData.meta.dropdownFilters
    };
    try {
      const response: ApiResponse = await this.loanService.getMyPayoffs(
        this.myPayoffsGridStore.gridData.meta.pageNumber,
        this.myPayoffsGridStore.gridData.meta.pageSize,
        this.myPayoffsGridStore.gridData.meta.sortDir,
        this.myPayoffsGridStore.gridData.meta.sortBy,
        filters,
      );
      return response;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE
      });
    }
  }

  *onFormSubmit() {
    this.form.meta.error = '';
    try {
      this.saved = false;
      const { goodThroughDate, comments, requestedDate } = this.form.fields;
      yield this.loanRequestsService.requestPayoff({
        loanId: this.loanStore.loanDetails.loanId,
        payoffThroughDate: format(goodThroughDate.value, 'yyyy-MM-dd'),
        requestedDate: format(requestedDate.value, 'yyyy-MM-dd'),
        comment: comments.value,
      });
      this.reset();
      this.saved = true;
    } catch (error) {
      this.form.meta.error = 'Error!';
    }
  }

  *onFormSubmitV2() {
    this.form.meta.error = '';
    try {
      this.saved = false;
      const { goodThroughDate, comments, rush, requestedDate } = this.form.fields;
      yield this.loanRequestsService.requestPayoffV2(
        format(goodThroughDate.value, 'MM/dd/yyyy'),
        format(requestedDate.value, 'MM/dd/yyyy'),
        comments.value,
        rush.value,
        this.loanStore.loanDetails.loanId
      );
      this.saved = true;
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Payoff requested successfully.',
      });
      this.reset();
      this.getActivePayoffDataByLoanId(this.loanStore.loanDetails.loanId);
      this.loanStore.fetchLoanDetails(this.loanStore.loanDetails.loanId);
      window.location.reload();
    } catch (error) {
      this.form.meta.error = 'Error!';
    }
  }

  *onFormSubmitV3() {
    this.form.meta.error = '';
    try {
      this.saved = false;
      const { goodThroughDate, comments, rush, requesterEmail, requestedDate } = this.form.fields;
      yield this.loanRequestsService.requestPayoffV3(
        format(goodThroughDate.value, 'MM/dd/yyyy'),
        format(requestedDate.value, 'MM/dd/yyyy'),
        comments.value,
        rush.value,
        requesterEmail.value,
        this.loanStore.loanDetails.loanId
      );
      this.saved = true;
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Payoff requested successfully.',
      });
      this.reset();
      this.getActivePayoffDataByLoanId(this.loanStore.loanDetails.loanId);
      this.loanStore.fetchLoanDetails(this.loanStore.loanDetails.loanId);
      window.location.reload();
    } catch (error) {
      this.form.meta.error = 'Error!';
    }
  }

  *onLoanPayoffDetailsSubmit(isLoanForeclosure: boolean) {
    try {
      this.saved = false;
      const { expectedPayoffDate, totalInterestReceived, totalProceeds, refundToBorrower } = this.loanPayoffForm?.form?.fields;
      const payoffDate = expectedPayoffDate === undefined ? null : format(expectedPayoffDate?.value, 'yyyy-MM-dd');
      const body = {
        loanId: this.loanStore.loanDetails?.loanId,
        expectedPayoffDate: payoffDate,
        totalInterestReceived: totalInterestReceived?.value,
        totalProceeds: totalProceeds?.value,
        refundToBorrower: refundToBorrower?.value
      };
      if (isLoanForeclosure) {
        yield this.loanRequestsService.submitForeclosurePayoff(
          body
        );
      } else {
        yield this.loanRequestsService.updateLoanPayoffDetails(
          body
        );
      }
      this.saved = true;
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Loan Payoff Details successfully.',
      });
      this.reset();
      this.getActivePayoffDataByLoanId(this.loanStore.loanDetails.loanId);
      this.loanStore.fetchLoanDetails(this.loanStore.loanDetails.loanId);
      window.location.reload();
    } catch (error) {
      this.form.meta.error = 'Error!';
    }
  }

  *getActivePayoffDataByLoanId(loanId: string) {
    try {
      const response: ApiResponse = yield this.loanRequestsService.getActivePayoffByLoanId(
        loanId
      );
      const { data } = response;
      this.currentPayoff = data.data.payoffNumber !== 0 ? data.data : null;
      const isToEnableRequest = (this.loanStore.loanDetails?.status === "FUNDED") && !(this.currentPayoff == null);
      this.disableCreatePayoff = isToEnableRequest;
      if (this.currentPayoff != null && this.currentPayoff?.foreclosure) {
        this.setIsForeclosure(true);
      }
      this.servicerFeesFormStore.setServicerFees(data.data.servicerFeesJson);
    } catch (err) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while getting payoff information.',
      });
    }
  }

  *getPayoffsByLoanId(loanId: string, callback?: (success: boolean) => void) {
    try {
      const response: ApiResponse = yield this.loanRequestsService.getPayOffsByLoanId(
        loanId
      );
      this.loanPayoffs = response.data.data;
      callback && callback(true);
    } catch (err) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while getting payoffs information.',
      });
      callback && callback(false);
    }
  }

  *retractPayoff(loanId: string) {
    this.form.meta.error = '';
    try {
      const { comments } = this.form.fields;
      const response: ApiResponse = yield this.loanRequestsService.retractPayoff(
        loanId, comments.value
      );
      if (response.data?.status === 'OK') {
        this.globalStore.notificationStore.showSuccessNotification({
          message: 'Payoff Retracted successfully',
        });
      }
      this.getPayoffsByLoanId(loanId);
      window.location.reload();
    } catch (err) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while getting payoffs information.',
      });
    }
  }

  resetCurrentPayoff() {
    this.currentPayoff = null;
  }

  *initPayoffDetails() {
    try {
      const settlementDate = this.loanStore.loanDetails?.settlementDate;
      const totalSettlementAmount = this.loanStore.loanDetails?.totalSettlementAmount;
      if (this.isForeclosure) {
        const foreclosurePayoffDetailsForm = {
          expectedPayoffDate: this.currentPayoff?.expectedPayoffDate ? new Date(this.currentPayoff?.expectedPayoffDate) : new Date(settlementDate),
          totalInterestReceived: this.currentPayoff?.totalInterestReceived ? this.currentPayoff?.totalInterestReceived : totalSettlementAmount
        };
        this.loanPayoffForm.loadForm(foreclosurePayoffDetailsForm);
      } else {
        const loanPayoffDetailsForm = {
          expectedPayoffDate: this.currentPayoff?.expectedPayoffDate ? new Date(this.currentPayoff?.expectedPayoffDate) : null,
          totalInterestReceived: this.currentPayoff?.totalInterestReceived,
          totalProceeds: this.currentPayoff?.totalProceeds,
          refundToBorrower: this.currentPayoff?.refundToBorrower,
        };
        this.loanPayoffForm.loadForm(loanPayoffDetailsForm);
      }

    } catch (err) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while getting payoffs information.',
      });
    }
  }

  *requestPayoffAssistance(loanId: string) {
    try {
      const response: ApiResponse = yield this.loanRequestsService.requestPayoffAssistance(
        loanId
      );
      if (response.data?.status === 'OK') {
        this.globalStore.notificationStore.showSuccessNotification({
          message: 'Assistance Payoff Requested',
        });
      }
    } catch (err) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Unable to request assistance',
      });
    }
  }

  *addServicerAccountingTeam(loanId: string) {
    try {
      const response: ApiResponse = yield this.loanRequestsService.addServicerAccountingTeam(
        loanId
      );
      if (response.data?.status === 'OK') {
        this.globalStore.notificationStore.showSuccessNotification({
          message: 'Accounting team added',
        });
      }
    } catch (err) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error Adding Accounting Team',
      });
    }
  }

  *getDefaultAllocationDashboard(loanId: string) {
    try {
      const response: ApiResponse = yield this.loanRequestsService.getDefaultAllocationDashboard(
        loanId
      );
      this.defaultAllocation = JSON.parse(response?.data?.data);
    } catch (err) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while getting default Allocation.',
      });
    }
  }

  *getForeclosureInterestAllocationDashboard(loanId: string) {
    try {
      const response: ApiResponse = yield this.loanRequestsService.getForeclosureInterestAllocationDashboard(
        loanId
      );
      this.foreclosureInterestAllocation = JSON.parse(response?.data?.data);
    } catch (err) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while getting default Allocation.',
      });
    }
  }

}

export default PayoffRequestStore;