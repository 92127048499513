import { Service } from '@roc/feature-app-core';

export abstract class QuoteGeneratorBaseService extends Service {
  abstract getMyQuotesGrid(
    page: number,
    pageSize: number,
    sortDir: string,
    sortBy: string,
    filters: string
  ): Promise<any>;
  abstract getQuoteById(quoteId);
  abstract saveQuote(quoteData);
  abstract deleteQuote(quoteId);
  abstract calculateQuote(quoteData);
  abstract getBrokerEconomicsByUserId(userId, type);
  abstract getQuotePrescreen(quoteData);
}
