import { Route } from 'react-router';
import { ForeclosureDetailsOverview } from '@roc/feature-loans';
import { Page } from '@roc/feature-app-core';
import { LoanValuations } from '@roc/feature-loans';

export const getForeclosureOverviewInternalRoutesConfig = (
  basePath: string,
  baseUrl: string
) => {
  return {
    foreclosureOverview: {
      path: `${basePath}/foreclosure-overview/:loanId`,
      url: `${baseUrl}/foreclosure-overview/:loanId`,
      documentTitle: 'Loan Level Information',
    },
  };
};

export const getForeclosureOverviewInternalRoutes = (
  basePath: string,
  baseUrl: string
) => {
  const config = getForeclosureOverviewInternalRoutesConfig(basePath, baseUrl);
  return {
    foreclosureOverview: (
      <Route exact path={config.foreclosureOverview.path}>
        <Page routeDefinition={config.foreclosureOverview}>
          <ForeclosureDetailsOverview />
        </Page>
      </Route>
    ),
  };
};


export const getValuationDashboardRoutesConfig = (
  basePath: string,
  baseUrl: string
) => {
  return {
    loanValuations: {
      path: `${basePath}/loan-valuations/:loanId`,
      url: `${baseUrl}/loan-valuations/:loanId`,
      documentTitle: 'Loan Valuations',
    },
  };
};

export const getValuationDashboardRoutes = (
  basePath: string,
  baseUrl: string
) => {
  const config = getValuationDashboardRoutesConfig(basePath, baseUrl);
  return {
    loanValuations: (
      <Route exact path={config.loanValuations.path}>
        <Page routeDefinition={config.loanValuations}>
          <LoanValuations />
        </Page>
      </Route>
    ),
  };
};