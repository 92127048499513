import { InputAdornment, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CalendarToday } from '@material-ui/icons';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import { MonthFilter } from '../types/types';


const useStyles = makeStyles(theme => ({
  searchIcon: {
    color: theme.palette.text.secondary,
  },
}));


interface AutocompleteFieldProps {
  value: string;
  placeHolder: string;
  onChange: (text: string) => void;
  onSelect: (option: MonthFilter) => void;
  options: MonthFilter[];
  className?: string;
  onBlur?: () => void;
}

const filter = createFilterOptions({
  matchFrom: 'any',
});

export const SearchableDateAutocomplete = (props: AutocompleteFieldProps) => {
  const classes = useStyles();
  const { value, onChange, onSelect, options, onBlur } = props;

  const filterOptions = (options, params) => {
    const filtered = filter(options, params);
    return filtered;
  };

  const renderInput = params => (
    <>
      <TextField
        {...params}
        InputProps={{
          ...params.InputProps,
          endAdornment: (
            <InputAdornment position="end" className={classes.searchIcon} >
              <CalendarToday />
            </InputAdornment>
          ),
        }}
        testId="autocomplete"
        onChange={e => onChange(e.target.value)}
        variant="outlined"
        placeholder={props.placeHolder}
        fullWidth
        onBlur={onBlur}
      />
    </>
  );

  return (
    <Autocomplete
      id="loan-autocomplete"
      selectOnFocus
      handleHomeEndKeys
      forcePopupIcon={false}
      disableClearable
      options={options}
      value={null}
      inputValue={value}
      blurOnSelect={true}
      onChange={(event, option: MonthFilter) => onSelect(option)}
      renderInput={renderInput}
      getOptionLabel={(option: MonthFilter) => option.label}
      getOptionSelected={(option: MonthFilter) => true}
      filterOptions={filterOptions}
      fullWidth
      className={props.className}
    />
  );
};