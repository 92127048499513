import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { ExpandMore } from '@material-ui/icons';
import { useCallback, useState } from 'react';

interface IMenuItem {
  title: string;
  handleClick?: (isOpen: boolean) => void;
  defaultOpen?: boolean;
  children?: any;
}

const useStyles = makeStyles(() => ({
  details: {
    padding: 0,
    display: 'block',
  },
  accordion: {
    marginBottom: 16,
    '&.Mui-expanded': {
      margin: 0,
      marginBottom: 16,
    },
    '&::before': {
      display: 'none',
    },
  },
  accordionSummary: {
    fontWeight: 700,
    minHeight: 48,
    '&.Mui-expanded': {
      minHeight: 48,
    },
    '& .MuiAccordionSummary-content': {
      margin: '12px 0',
    },
  },
}));

export const ExpandableMenu = ({
  children,
  defaultOpen = false,
  handleClick,
  title,
}: IMenuItem) => {
  const classes = useStyles();
  const [isOpen, setOpen] = useState(defaultOpen);

  const onClick = useCallback(() => {
    setOpen(!isOpen);
    if (handleClick) handleClick(!isOpen);
  }, [isOpen]);

  return (
    <Accordion defaultExpanded={isOpen} elevation={0} onClick={onClick} className={classes.accordion}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls={`${title}-content`}
        id={title}
        className={classes.accordionSummary}
      >
        <p>{title}</p>
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        {children}
      </AccordionDetails>
    </Accordion>
  );
};
