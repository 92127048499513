import { ScopeOfWorkV2FormParams } from "@roc/feature-sow-shared/types";


const params_regex_v1 = /^([A-z0-9_@.-]+)(;[A-z0-9_@.-]+)*$/;
const params_regex_v2 = /^([\w]+:[A-z0-9_@.\- ,]+)(;[\w]+:[A-z0-9_@.\- ,]+)*$/;


const parseV1Params = (decodedParams: string) => {
  const params = decodedParams.split(';');
  return {
    loanId: params[0],
    propertyId: decodeURIComponent(params[1]),
    username: decodeURIComponent(params[2]),
    isInternal: !!params[3],

  };
};

const parseValue = (key: string, value: string) => {
  return (key === 'isInternal' || key === 'isReviewAllowed' || key === 'isApprovalAllowed') ? value === 'true' : value;
};

const parseV2Params = (decodedParams: string) => {
  const response: ScopeOfWorkV2FormParams = {};
  const params = decodedParams.split(';');
  params.forEach(param => {
    const [key, value] = param.split(':');
    response[key] = parseValue(key, value);
  });
  return {
    loanId: response.loanId ? decodeURIComponent(response.loanId) : null,
    propertyId: response.propertyId
      ? decodeURIComponent(response.propertyId)
      : null,
    scopeOfWorkId: response.scopeOfWorkId
      ? decodeURIComponent(response.scopeOfWorkId)
      : null,
    lenderId: response.lenderId ? decodeURIComponent(response.lenderId) : null,
    username: response.username ? decodeURIComponent(response.username) : null,
    isInternal: response.isInternal,
    isApprovalAllowed: response.isApprovalAllowed,
    isReviewAllowed: response.isReviewAllowed,
    propertyAddress: response.propertyAddress ? decodeURIComponent(response.propertyAddress) : null
  };
};


export const encodeParams = (data: string): string => {
  return window.btoa(encodeURIComponent(data));
};

export const decodeParams = (data: string): ScopeOfWorkV2FormParams => {
  const decodedParams = window.atob(data.replace('-', '/'));
  return parseV2Params(decodedParams);
};
