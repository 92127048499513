import { action, flow, makeObservable, observable } from 'mobx';
import { ApiResponse } from '@roc/client-portal-shared/services';
import { GlobalStore, FormStore } from '@roc/feature-app-core';
import { RegisterService } from '../services/registerService';
import { PortalConfigurationService } from '@roc/feature-app-core';

const form = {
  fields: {
    firstName: {
      value: '',
      error: null,
      rule: 'required',
    },
    lastName: {
      value: '',
      error: null,
      rule: 'required',
    },
    email: {
      value: '',
      error: null,
      rule: 'required|email',
    },
    phoneNumber: {
      value: '',
      error: null,
      rule: 'required|regex:/^\\d{3}-?\\d{3}-?\\d{4}$/|alpha_dash',
      message: 'Phone must be a number with the format xxx-xxx-xxxx',
    },
    company: {
      value: '',
      error: null,
      rule: 'required',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export class RegisterStore extends FormStore {
  private globalStore: GlobalStore;
  private registerService: RegisterService;
  private portalConfiguration: PortalConfigurationService;
  successUrl = undefined;
  isRegistrationEnabled: boolean;

  constructor(globalStore) {
    super({ ...form }, globalStore);
    this.globalStore = globalStore;
    this.registerService = new RegisterService();
    this.portalConfiguration = new PortalConfigurationService();
    makeObservable(this, {
      successUrl: observable,
      onFormSubmit: flow,
      setSuccessUrl: action,
      isRegistrationEnabled: observable,
      getRegistrationEnabled: flow,
    });
  }

  setSuccessUrl(successUrl) {
    this.successUrl = successUrl;
  }

  *onFormSubmit() {
    this.runFormValidation();
    if (!this.form.meta.isValid) return;
    try {
      const data = this.getFormValues();
      const portalConfiguration: ApiResponse = yield this.portalConfiguration.getPortalConfigurationByHost(
        window.location.origin
      );
      const newData = { ...data, "portalId": portalConfiguration.data.data.id, nportalUser: false };
      const response: ApiResponse = yield this.registerService.register(newData);
      this.globalStore.history.push(this.successUrl);
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Registered successfully.',
      });
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while registration.',
      });
    }
  }

  *getRegistrationEnabled() {
    try {
      const response: ApiResponse = yield this.registerService.getRegistrationEnabled();
      this.isRegistrationEnabled = response?.data?.data;
    }
    catch (error) {
      console.log(error);
    }
  }
}

export default RegisterStore;
