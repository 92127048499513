import { action, computed, flow, makeObservable } from 'mobx';
import { GlobalStore } from '@roc/feature-app-core';
import { DocumentStore } from '@roc/feature-documents';
import { LoanStore } from '@roc/feature-loans';
import { LoanPaymentsService } from '../../services/documentForms/loanPaymentsService';
import { DocumentService } from '../../services/documentService';
import { Document } from '@roc/feature-types';
import { LoanSubType, downloadDocument } from '@roc/feature-utils';

export class LoanPaymentsStore {
  private loanStore: LoanStore;
  private documentStore: DocumentStore;
  private globalStore: GlobalStore;
  private loanPaymentsService: LoanPaymentsService;
  private documentService: DocumentService;
  loanId: string;
  public fastTrackFees: any = {};
  public loanFastTrackInfo: any = {};

  constructor(
    loanStore: LoanStore,
    documentStore: DocumentStore,
    globalStore: GlobalStore
  ) {
    this.loanStore = loanStore;
    this.documentStore = documentStore;
    this.globalStore = globalStore;
    this.loanPaymentsService = new LoanPaymentsService();
    this.documentService = new DocumentService();
    makeObservable(this, {
      acceptDocument: flow,
      updatePaymentOptions: flow,
      downloadSignedDocument: flow,
      updateLoanFastrackInfo: flow,
      reset: action,
      isBridgeSubType: computed,
      loadFastTrackFees: flow,
      loadFastTrackInfo: flow
    });
  }

  isBorrowerPayingGfd = () => {
    return !this.isBridgeSubType && (this.globalStore.userFeatures.tamariskGfd)
  }

  closeDialog = () => {
    this.documentStore.documentFormStore.setCurrentForm(null);
  }

  *acceptDocument(document: Document) {
    try {
      const loanDocument = {
        ...document,
      };
      const paymentOptions = {
        loanId: document.loanId,
        gfdPayment: false,
        tamariskPayment: false
      };
      this.updatePaymentOptions(paymentOptions);
      yield this.documentService.updateDocument(loanDocument);
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Document was accepted',
      });
      this.documentStore.fetchDocuments(this.loanStore.loanDetails.loanId);
    } catch (err) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while changing document status',
      });
    }
  }

  *acceptFastrackDocument(document: Document) {
    try {
      const loanDocument = {
        ...document,
      };
      // const fastTrackOptions = {
      //   loanId: document.loanId,
      //   insurance: false,
      //   insuranceReview: false,
      //   titleSearch: false,
      // };
      // this.updateLoanFastrackInfo(fastTrackOptions);
      yield this.documentService.updateDocument(loanDocument);
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Document was accepted',
      });
      this.documentStore.fetchDocuments(this.loanStore.loanDetails.loanId);
    } catch (err) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while changing document status',
      });
    }
  }

  *updatePaymentOptions(paymentOptions: any) {
    try {
      yield this.loanPaymentsService.updateLoanPaymentFlowData(paymentOptions);
      yield this.loanStore.fetchLoanDetails(this.loanStore.loanDetails.loanId);
    }
    catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while saving the modal state',
      });
    }
    this.closeCurrentModal();
  }

  *updateLoanFastrackInfo(paymentOptions: any) {
    try {
      yield this.loanPaymentsService.updateLoanFastrackInfo(paymentOptions);
      yield this.loanStore.fetchLoanDetails(this.loanStore.loanDetails.loanId);
    }
    catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while saving the modal state',
      });
    }
    this.closeCurrentModal();
  }

  *downloadSignedDocument(entityId: string, entityType: string) {
    try {
      const response = yield this.loanPaymentsService.downloadSignedDocument(entityId, entityType);
      downloadDocument(
        response?.data,
        response?.headers,
        'download',
        'signed_document.pdf'
      );
    } catch (err) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while downloading signed document.',
      });
    }
  }

  closeCurrentModal() {
    return this.documentStore.documentFormStore.setCurrentForm(null);
  }

  reset() {
    // No reset logic required yet.
  }

  get isBridgeSubType() {
    const { loanSubType } = this.loanStore.loanDetails;
    return (
      loanSubType === LoanSubType.FIX_AND_FLIP || loanSubType === LoanSubType.FIX_AND_FLIP_PRO || loanSubType === LoanSubType.STABILIZED_BRIDGE || loanSubType === LoanSubType.GROUND_UP
    );
  }

  *loadFastTrackFees() {
    try {
      const response = yield this.loanPaymentsService.getLoanFastTrackFees();

      this.fastTrackFees = response.data.data;
    } catch (err) {
    }
  }

  *loadFastTrackInfo(loanId) {
    try {
      const response = yield this.loanPaymentsService.getLoanFastTrackInfo(loanId);

      this.loanFastTrackInfo = response.data.data;
    } catch (err) {
    }
  }
}

export default LoanPaymentsStore;
