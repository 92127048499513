import { ApiResponse, FormStore, GlobalStore, UserStore } from '@roc/feature-app-core';
import { GENERIC_ERROR_MESSAGE, insertIf } from '@roc/feature-utils';
import { DialogState } from '@roc/ui';
import { CreateListService } from 'apps/crm-portal/src/app/modules/createList/service/createListService';
import { CreateListFormStore } from 'apps/crm-portal/src/app/modules/createList/stores/CreateListFormStore';
import {
  action,
  computed,
  flow,
  makeObservable,
  observable,
  override,
} from 'mobx';
import { FilterType } from '../components/leads/constants/constants';
import { LeadsDashboardService } from '../services/leadsDashboardService';
import { LeadsDashboardStore } from './leadsDashboardStore';

export enum ObjectType {
  LEAD = 'Lead',
  BORROWER = 'Borrower',
  TPO = 'TPO',
}

export enum FieldType {
  DATE_SELECT = 'DateSelect',
}

export interface FieldDefinition {
  fieldDisplayName: string;
  fieldName: string;
  fieldType: string;
  optionValueFields: any;
  optionValueFieldsDisplay: any;
}

export const isBackOfficeApp = () =>
  window.location.pathname.includes('/backoffice');

export const recordTypeLeadOptions = [
  {
    label: 'TPO',
    value: 'TPO',
  },
  {
    label: 'Borrower',
    value: 'Borrower',
  },
  {
    label: 'Broker',
    value: 'Broker',
  },
];

export const recordTypeContactOptions = [
  {
    label: 'Lender Principal',
    value: 'Lender Principal',
  },
  {
    label: 'Borrower',
    value: 'Borrower',
  },
];

export const objectTypeOptions = [
  {
    label: 'Lead',
    value: ObjectType.LEAD,
  },
  {
    label: 'Borrower',
    value: ObjectType.BORROWER,
  },
  {
    label: 'TPO',
    value: ObjectType.TPO,
  },
];

const form = {
  fields: {
    name: {
      value: '',
      error: null,
      rule: 'required',
      message: 'This value is required',
    },
    baseObject: {
      value: ObjectType.LEAD,
      error: null,
      rule: 'required',
      message: 'This value is required',
    },
    columns: {
      value: [],
      error: null,
      rule: 'required',
      message: 'This value is required',
    },
    sortBy: {
      value: 'lastUpdatedDate',
      error: null,
      rule: 'required',
      message: 'This value is required',
    },
    sortDir: {
      value: 'asc',
      error: null,
      rule: 'required',
      message: 'This value is required',
    },
    shareWith: {
      value: [],
      error: null,
      rule: '',
      message: '',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export class EditLeadsDashboardStore extends FormStore {
  private globalStore: GlobalStore;
  private leadsDashboardStore: LeadsDashboardStore;
  private leadsDashboardService: LeadsDashboardService;

  dialogState: DialogState;
  dashboard;
  fields: FieldDefinition[] = [];
  query: any;
  advanced = false;
  sharedWithOwners = [];
  constructor(
    globalStore: GlobalStore,
    leadsDashboardStore: LeadsDashboardStore
  ) {
    super(form, globalStore);
    this.globalStore = globalStore;
    this.leadsDashboardStore = leadsDashboardStore;
    this.leadsDashboardService = new LeadsDashboardService();

    this.reset();

    makeObservable(this, {
      dialogState: observable,
      dashboard: observable,
      fields: observable,
      query: observable,
      advanced: observable,
      onFieldChange: override,
      setQuery: action,
      closeDialog: action,
      initialize: flow,
      openEdit: flow,
      openClone: flow,
      openCreate: flow,
      fetchFilterFields: flow,
      saveDashboard: flow,
      deleteDashboard: flow,
      columnOptions: computed,
      shareDashboard: flow,
      getUsersWithDashboard: flow,
      sharedWithOwners: observable
    });
  }

  *openCreate() {
    yield this.initialize();
    yield this.fetchFilterFields();
    this.dashboard = {};
    this.dialogState = DialogState.ADD;
  }

  *openEdit(dashboard) {
    yield this.initialize();
    this.dashboard = dashboard;

    const columns = this.dashboard.columns.split(',');
    this.loadForm({
      ...this.dashboard,
      columns,
    });
    this.getUsersWithDashboard(
      dashboard
    );
    yield this.fetchFilterFields();
    this.query = dashboard.filters;
    this.advanced = dashboard.filterType === FilterType.ADVANCED;
    this.dialogState = DialogState.EDIT;
  }

  *openClone(dashboard) {
    yield this.initialize();
    this.dashboard = dashboard;
    this.dashboard.id = null;
    const columns = this.dashboard.columns.split(',');
    this.loadForm({
      ...this.dashboard,
      columns,
      name: this.dashboard.name + " - clone",
    });
    yield this.fetchFilterFields();
    this.query = dashboard.filters;
    this.advanced = dashboard.filterType === FilterType.ADVANCED;
    this.dialogState = DialogState.ADD;
  }

  closeDialog() {
    this.dialogState = null;
  }

  *initialize() {
    this.reset();
    this.fields = [];
    this.query = null;
    this.advanced = false;
  }

  *fetchFilterFields() {
    try {
      const baseObject = this.form.fields.baseObject.value;
      const response = yield this.leadsDashboardService.getFilterFields(
        baseObject
      );
      this.fields = response.data.data;
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE,
      });
    }
  }

  setQuery(value) {
    this.query = value;
  }

  setAdvanced(advanced) {
    if (this.advanced && !advanced) {
      this.query = null;
    }
    this.advanced = advanced;
  }

  *saveDashboard(userName, allOwners) {
    try {
      if (!this.runFormValidationWithMessage()) {
        return;
      }

      if (!this.query) {
        this.globalStore.notificationStore.showErrorNotification({
          message: 'Add at least one filter criteria before saving',
        });
        return;
      }
      const formValues = this.getFormValues();
      let owners = formValues.shareWith;
      if (formValues.shareWith != null && formValues.shareWith.length > 0) {
        if ("all" === formValues.shareWith[0].value) {
          owners = allOwners;
        }
      }
      console.log(owners);
      const ids = owners.map(owner => owner.value).join(',');
      const body = {
        ...this.dashboard,
        name: formValues.name,
        columns: formValues.columns.join(','),
        baseObject: formValues.baseObject,
        groupBy: '',
        sortBy: formValues.sortBy,
        sortDir: formValues.sortDir,
        filterType: this.advanced ? FilterType.ADVANCED : FilterType.SIMPLE,
        // brand: this.createListFormStore.form.fields.brand.value,
        // filterLogic: this.filterLogicText,
        filters: this.query,
        createdBy: userName,
        ids: ids
      };
      console.log('body: ', body);
      const response = yield this.leadsDashboardService.saveCustomDashboard(
        body
      );
      const dashboard = JSON.parse(response.data.data);
      yield this.leadsDashboardStore.fetchDashboards();
      yield this.leadsDashboardStore.selectDashboard(dashboard?.id);

      this.closeDialog();
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'List saved successfully!',
      });
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE,
      });
    }
  }

  * shareDashboard(currentDashboard, selectedOwners) {
    try {
      this.dashboard = currentDashboard;
      const columns = this.dashboard.columns.split(',');

      this.loadForm({
        ...this.dashboard,
        columns,
      });
      this.query = this.dashboard.filters;
      console.log(selectedOwners)
      const ids = selectedOwners.map(owner => owner.value).join(',');
      const formValues = this.getFormValues();
      const body = {
        ...this.dashboard,
        name: formValues.name,
        columns: formValues.columns.join(','),
        baseObject: formValues.baseObject,
        groupBy: '',
        sortBy: formValues.sortBy,
        sortDir: formValues.sortDir,
        filterType: this.advanced ? FilterType.ADVANCED : FilterType.SIMPLE,
        // brand: this.createListFormStore.form.fields.brand.value,
        // filterLogic: this.filterLogicText,
        filters: this.query,
        ids: ids
      };
      console.log('body: ', body);
      const response = yield this.leadsDashboardService.shareCustomDashboard(
        body
      );
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'List shared successfully!',
      });
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE,
      });
    }
  }


  * deleteDashboard(dashboard) {
    try {
      const response = yield this.leadsDashboardService.deleteCustomDashboard(
        dashboard.id
      );
      this.leadsDashboardStore.fetchDashboards();
      this.leadsDashboardStore.leadsDashboardGridStore.resetAndFetchGridData();
      this.closeDialog();
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'List deleted successfully!',
      });
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE,
      });
    }
  }

  * getUsersWithDashboard(dashboard) {
    try {
      const response = yield this.leadsDashboardService.getUsersWithDashboard(
        dashboard.id
      );
      this.sharedWithOwners = response.data;
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE,
      });
    }
  }


  get columnOptions() {
    const hiddenOptions = ['firstName', 'lastName', 'createdDate'];
    return this.leadsDashboardStore.columns
      .filter(c => !hiddenOptions.includes(c.fieldName))
      .map(field => ({
        label: field.fieldDisplayName,
        value: field.fieldName,
      }));
  }
}
