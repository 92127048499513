import { ApiResponse, FormStore, GlobalStore, GridStore } from '@roc/feature-app-core';
import {
  DateFormat,
  formatDate,
  GENERIC_ERROR_MESSAGE, parseDate
} from '@roc/feature-utils';
import { DialogState } from '@roc/ui';
import { flow, makeObservable, observable, override } from 'mobx';
import { LeadService } from '../../services/leadService';
import { LeadDetailsStore } from './leadDetailsStore';
import { LEAD_OBJECT_TYPE } from '../../components/leads/constants/constants';
import { TaskStatus } from 'libs/feature-general-tasks/src/generalTasks/types/generalTasksTypes';
import { RecordHistoryService } from '../../services/recordHistoryService';

export class RecordHistoryStore {
  private globalStore: GlobalStore;
  protected leadDetailsStore: LeadDetailsStore;
  protected recordHistoryService: RecordHistoryService;

  dialogState: DialogState;
  recordHistoryGrid: GridStore;

  constructor(globalStore: GlobalStore, leadDetailsStore: LeadDetailsStore) {
    this.globalStore = globalStore;
    this.leadDetailsStore = leadDetailsStore;
    this.recordHistoryService = new RecordHistoryService();
    this.recordHistoryGrid = new GridStore(() => this.fetchRows(), null, 50);

    makeObservable(this, {
      dialogState: observable,
      fetchRows: observable,
      resetAndFetchGridData: flow,

    });
  }

  fetchRows = async () => {
    try {
      if (this.leadDetailsStore.objectId != null) {
        return await this.fetchDashboardData(this.leadDetailsStore.objectId, this.leadDetailsStore.type);
      }
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while loading history grid',
      });
    }
  };
  fetchDashboardData = async (recordId, type) => {
    const response: ApiResponse
      = await this.recordHistoryService.getHistoryRecordById(
        this.recordHistoryGrid.gridData.meta.pageNumber,
        this.recordHistoryGrid.gridData.meta.pageSize,
        this.recordHistoryGrid.gridData.meta.sortBy,
        this.recordHistoryGrid.gridData.meta.sortDir,
        this.recordHistoryGrid.gridData.meta.filters,
        this.recordHistoryGrid.gridData.meta.dropdownFilters,
        recordId,
        type,
      );
    return response;
  };

  *resetAndFetchGridData() {
    this.recordHistoryGrid = yield new GridStore(
      () => this.fetchRows(),
      null,
      50
    );
    yield this.recordHistoryGrid.reset();
  }
}