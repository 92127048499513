import { Container, Grid } from '@material-ui/core';
import { observer } from 'mobx-react';
import React from 'react';
import { SectionName } from '../components/rentalPricerComponents';
import { BorrowerInformationFields } from '../components/rentalPricerFields';
import { useRentalPricerStore } from '../hooks/useRentalPricerStore';

export const BorrowerInformationStep = observer(() => {
  const { rentalPricerStore } = useRentalPricerStore();
  const { rentalPricerDetailsStore } = rentalPricerStore
  const store = rentalPricerDetailsStore.termStore;

  return (
    <Container maxWidth="md">
      <Grid container spacing={2}>
        <SectionName name="Borrower Information" />
        <BorrowerInformationFields store={store} />
      </Grid>
    </Container>
  );
});
