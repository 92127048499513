import { AppBar, Box, Grid, Tabs, Toolbar, Typography } from "@material-ui/core";
import Tab from '@material-ui/core/Tab';
import { makeStyles } from '@material-ui/core/styles';
import { Button, StandardDialog, TextField } from "@roc/ui";
import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { ComparableProp, createComparableData } from "./compAnalysisTable";
import { Add, CheckCircle, CheckCircleOutline } from "@material-ui/icons";
import { formatCurrency } from "@roc/ui/utils";

const useStyles = makeStyles({
  root: {
  },
  dialogContentclasses: {
    padding: '8px 0'
  },
  tableContainer: {
    maxHeight: 400,
    '& .MuiTableRow-head th': {
      fontWeight: 'bold',
    },
    '& .MuiTableRow-head.subject-property-row th': {
      fontWeight: 'normal',
      background: '#555',
      color: 'white',
    },
  },
});

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      {...other}
    >
      {value === index && (children)}
    </div>
  );
}

const CompSelectionTabs = ({ onSelect, subjectPropertyComp }: {
  onSelect: (data: ComparableProp) => void;
  subjectPropertyComp: ComparableProp;
}) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };
  return (
    <>
      <AppBar position="static" color="transparent">
        <Tabs
          value={value}
          onChange={handleChange}
          variant="standard"
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="Roc Comps" />
          <Tab label="Manual" />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <DataScienceCompsTable subjectPropertyComp={subjectPropertyComp} onSelect={onSelect} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Box minHeight={400} display={'flex'} flexDirection={'column'}>
          <Box flexGrow={1}>
            <Grid container spacing={3} style={{
              width: '100%',
              margin: 'auto',
              padding: 24
            }}>
              <Grid item xs={12}>
                <TextField standaloneLabel fullWidth size="small" variant="outlined" testId="address-field" label="Address" defaultValue="" />
              </Grid>
              <Grid item xs={4}>
                <TextField standaloneLabel fullWidth size="small" variant="outlined" testId="distance-field" label="Distance" defaultValue="" />
              </Grid>
              <Grid item xs={4}>
                <TextField standaloneLabel fullWidth size="small" variant="outlined" testId="price-field" label="Price" defaultValue="" />
              </Grid>
              <Grid item xs={4}>
                <TextField standaloneLabel fullWidth size="small" variant="outlined" testId="lastSold-field" label="Last Sold" defaultValue="" />
              </Grid>
              <Grid item xs={4}>
                <TextField standaloneLabel fullWidth size="small" variant="outlined" testId="daysOnMarket-field" label="Days on Market" defaultValue="" />
              </Grid>
              <Grid item xs={4}>
                <TextField standaloneLabel fullWidth size="small" variant="outlined" testId="yearBuilt-field" label="Year Built" defaultValue="" />
              </Grid>
              <Grid item xs={4}>
                <TextField standaloneLabel fullWidth size="small" variant="outlined" testId="beds-field" label="Beds" defaultValue="" />
              </Grid>
              <Grid item xs={4}>
                <TextField standaloneLabel fullWidth size="small" variant="outlined" testId="baths-field" label="Baths" defaultValue="" />
              </Grid>
              <Grid item xs={4}>
                <TextField standaloneLabel fullWidth size="small" variant="outlined" testId="halfBaths-field" label="Half Baths" defaultValue="" />
              </Grid>
              <Grid item xs={4}>
                <TextField standaloneLabel fullWidth size="small" variant="outlined" testId="gla-field" label="GLA" defaultValue="" />
              </Grid>
            </Grid>
          </Box>
          <Box display={'flex'} justifyContent={'flex-end'}>
            <Box mr={2} my={2}>
              <Button testId='cancel' size="small" color="default"
                variant="outlined"
                onClick={() => {

                }}>
                Cancel
              </Button>
            </Box>
            <Box mr={2} my={2}>
              <Button testId='save' size="small" color="primary"
                variant="contained"
                onClick={() => {

                }}>
                Save
              </Button>
            </Box>
          </Box>
        </Box>
      </TabPanel>
    </>
  )
}

const rows: ComparableProp[] = [
  createComparableData('Appraisal Comp Address 1', '1 mi.', 175000, '08/27/2023', '21 days', '2023', '3', '3', '3', ''),
  createComparableData('Roc Comp Address 1', '1 mi.', 175000, '08/27/2023', '21 days', '2023', '3', '3', '3', ''),
  createComparableData('Roc Comp Address 2', '1 mi.', 175000, '08/27/2023', '21 days', '2023', '3', '3', '3', ''),
  createComparableData('Roc Comp Address 3', '1 mi.', 175000, '08/27/2023', '21 days', '2023', '3', '3', '3', ''),
  createComparableData('Roc Comp Address 4', '1 mi.', 175000, '08/27/2023', '21 days', '2023', '3', '3', '3', ''),
  createComparableData('Roc Comp Address 5', '1 mi.', 175000, '08/27/2023', '21 days', '2023', '3', '3', '3', ''),
  createComparableData('Roc Comp Address 6', '1 mi.', 175000, '08/27/2023', '21 days', '2023', '3', '3', '3', ''),
  createComparableData('Roc Comp Address 7', '1 mi.', 175000, '08/27/2023', '21 days', '2023', '3', '3', '3', ''),
  createComparableData('Roc Comp Address 8', '1 mi.', 175000, '08/27/2023', '21 days', '2023', '3', '3', '3', ''),
  createComparableData('Roc Comp Address 9', '1 mi.', 175000, '08/27/2023', '21 days', '2023', '3', '3', '3', ''),
  createComparableData('Roc Comp Address 10', '1 mi.', 175000, '08/27/2023', '21 days', '2023', '3', '3', '3', ''),
];

export const DataScienceCompsTable = ({ onSelect, subjectPropertyComp }: {
  onSelect: (data: ComparableProp) => void;
  subjectPropertyComp: ComparableProp;
}) => {
  const classes = useStyles();
  return (
    <TableContainer className={classes.tableContainer}>
      <Table size="small" stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>Address</TableCell>
            <TableCell>Distance</TableCell>
            <TableCell>Price</TableCell>
            <TableCell>Last Sold</TableCell>
            <TableCell>Days on Market</TableCell>
            <TableCell>Year Built</TableCell>
            <TableCell>Beds</TableCell>
            <TableCell>Baths</TableCell>
            <TableCell>Half Baths</TableCell>
            <TableCell>GLA</TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow className="subject-property-row">
            <TableCell component="th" scope="row">
              {subjectPropertyComp.address}
            </TableCell>
            <TableCell>{subjectPropertyComp.distance}</TableCell>
            <TableCell>{formatCurrency(subjectPropertyComp.salePrice)}</TableCell>
            <TableCell>{subjectPropertyComp.dateOfSale}</TableCell>
            <TableCell>{subjectPropertyComp.daysOnMarket}</TableCell>
            <TableCell>{subjectPropertyComp.yearBuilt}</TableCell>
            <TableCell>{subjectPropertyComp.beds}</TableCell>
            <TableCell>{subjectPropertyComp.baths}</TableCell>
            <TableCell>{subjectPropertyComp.halfBaths}</TableCell>
            <TableCell>{subjectPropertyComp.gla}</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, i) => (
            <TableRow hover key={i}>
              <TableCell component="th" scope="row">
                {row.address}
              </TableCell>
              <TableCell>{row.distance}</TableCell>
              <TableCell>{formatCurrency(row.salePrice)}</TableCell>
              <TableCell>{row.dateOfSale}</TableCell>
              <TableCell>{row.daysOnMarket}</TableCell>
              <TableCell>{row.yearBuilt}</TableCell>
              <TableCell>{row.beds}</TableCell>
              <TableCell>{row.baths}</TableCell>
              <TableCell>{row.halfBaths}</TableCell>
              <TableCell>{row.gla}</TableCell>
              <TableCell>
                <Button testId='selectComp' size="small" color="primary"
                  variant="outlined"
                  startIcon={<CheckCircleOutline />}
                  onClick={() => {
                    onSelect(row);
                  }}>
                  Select
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export const CompSelectionDialog = ({ open, onClose, onSelect, subjectPropertyComp }: {
  open: boolean;
  onClose: () => void;
  onSelect: (data: ComparableProp) => void;
  subjectPropertyComp: ComparableProp;
}) => {
  const classes = useStyles();
  return (
    <StandardDialog
      open={open}
      maxWidth="lg"
      title={'Select Comps'}
      handleClose={onClose}
      removeContentBox
      dialogContentclasses={{
        root: classes.dialogContentclasses
      }}
      dialogContent={(
        <Box width={'100%'}>
          <CompSelectionTabs subjectPropertyComp={subjectPropertyComp} onSelect={onSelect} />
        </Box>
      )}
    />
  )
}