import { Service } from '@roc/client-portal-shared/services';

const url = {
  GET_RESOLUTION_INTERNAL: '/nportal/rest/priv/BorrowerDataCollection/getResolution',
  SAVE_RESOLUTION_INTERNAL: '/nportal/rest/priv/BorrowerDataCollection/saveResolution',
  UPLOAD_DOCUMENT_INTERNAL: '/nportal/rest/priv/loanDocs/uploadLoanDocument/',
  DOWNLOAD_DOCUMENT_INTERNAL: '/nportal/rest/priv/loanDocs/downloadLoanDocument',
  DOWNLOAD_DOCUMENT_BY_PATH_INTERNAL: '/nportal/rest/priv/loanDocs/downloadLoanDocumentByPathForResolution',
  DOWNLOAD_DOCUMENT_BY_PATH: '/api/v1/loan/loanDocuments/downloadLoanDocumentByPathForResolution',
  GET_LOAN_DOCS_INTERNAL: '/nportal/rest/priv/loanDocs',
  CREATE_TASK_INTERNAL: '/nportal/rest/priv/borrower/createTasks',
  UPLOAD_TASK_FILE_INTERNAL: '/nportal/rest/priv/borrower/uploadTaskFile',
  GET_RESOLUTION: '/api/v1/document/formDocument/getResolution',
  SAVE_RESOLUTION: '/api/v1/document/formDocument/saveResolutionFromExternal',
  UPLOAD_DOCUMENT: '/api/v1/loan/loanDocuments/uploadLoanDocument/',
  CREATE_TASK: '/api/v1/tasks/createTasks',
  UPLOAD_TASK_FILE: '/api/v1/tasks/uploadTaskFile',
  GET_TASK_BY_ID: '/api/v1/tasks/getTaskById',
  GET_RESOLUTION_BY_ID_INTERNAL: '/nportal/rest/priv/BorrowerDataCollection/getResolutionById',
  GET_RESOLUTION_BY_ID: '/api/v1/document/formDocument/getResolutionById',
  UPDATE_RESOLUTION_STATUS_INTERNAL: '/nportal/rest/priv/BorrowerDataCollection/changeResolutionStatus',
};

export class ResolutionService extends Service {

  getTaskById(taskId: number) {
    return this.get(`${url.GET_TASK_BY_ID}?taskId=${taskId}`);
  }

  createTask(body: any, isInternal: boolean) {
    return this.post(isInternal ? url.CREATE_TASK_INTERNAL : url.CREATE_TASK, body);
  }

  uploadTaskFile(body: any, isInternal: boolean) {
    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }
    return this.post(isInternal ? url.UPLOAD_TASK_FILE_INTERNAL : url.UPLOAD_TASK_FILE, body, config);
  }


  getResolution(internal: boolean, documentId: string) {
    return this.get(`${internal ? url.GET_RESOLUTION_INTERNAL : url.GET_RESOLUTION}?loanDocumentId=${documentId}`);
  }

  saveResolution(internal: boolean, documentId: number, comment: string) {
    return this.post(`${internal ? url.SAVE_RESOLUTION_INTERNAL : url.SAVE_RESOLUTION}?loanDocumentId=${documentId}`, { comment });
  }

  uploadFile(internal: boolean, documentId: number, formData) {
    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }
    return this.post(`${internal ? url.UPLOAD_DOCUMENT_INTERNAL : url.UPLOAD_DOCUMENT}${documentId}`, formData, config);
  }

  downloadDocumentInternal(document) {
    return this.post(url.DOWNLOAD_DOCUMENT_INTERNAL, document, null, {
      responseType: 'arraybuffer',
    });
  }

  downloadDocumentByPath(path, internal: boolean) {
    return this.post(`${internal ? url.DOWNLOAD_DOCUMENT_BY_PATH_INTERNAL : url.DOWNLOAD_DOCUMENT_BY_PATH}`, path, {}, {
      responseType: 'arraybuffer',
    });
  }

  getLoanDocsInternal(loanId, loanProcess) {
    return this.get(`${url.GET_LOAN_DOCS_INTERNAL}/${loanId}`, { loanProcess });
  }

  getResolutionInfoById(internal, resolutionId) {
    return this.get(`${internal ? url.GET_RESOLUTION_BY_ID_INTERNAL : url.GET_RESOLUTION_BY_ID}`, { resolutionId });
  }

  updateResolutionStatus(request: any) {
    return this.put(url.UPDATE_RESOLUTION_STATUS_INTERNAL, request);
  }
}
