import { ApiResponse, GlobalStore, UserStore } from '@roc/feature-app-core';
import { isElectronApp, isElectronAppLocalHost, isMobileApp, isMobileAppLocalHost } from '@roc/feature-utils';
import { action, computed, flow, makeObservable, observable } from 'mobx';
import SockJS from 'sockjs-client';
import Stomp, { Subscription } from 'stompjs';
import { WebSocketService } from '../services/webSocketService';
import { VideoRoom, UserOnlineStatusNotification, ConversationUpdatesNotification } from '@roc/feature-types';

export enum SubscriptionTopic {
  TOPIC_CONVERSATION_MESSAGE_UPDATES = '/user/topic/private-messages',
  TOPIC_USER_ONLINE_STATUS_UPDATES = '/user/topic/user-online-status-updates',
  TOPIC_USER_VIDEO_MEET_INVITATION = '/user/topic/video-meet-invitation',
  TOPIC_USER_VIDEO_ROOM_UPDATES = '/user/topic/video-room-updates',
}

interface SubscriptionRegistration<T> {
  subscriptionTopic: SubscriptionTopic,
  callBack: (messageData: T) => void;
}

type SubscriptionRegistrationType = SubscriptionRegistration<ConversationUpdatesNotification | UserOnlineStatusNotification | VideoRoom>;

export class WebSocketStore {

  private globalStore: GlobalStore;
  private userStore: UserStore;
  private webSocketService: WebSocketService;
  private stompClientPromise: Promise<Stomp.Client>;
  private timeoutId: any;
  private subscriptions: Record<SubscriptionTopic | string, Subscription>;
  private subscriptionRegistrations: Record<SubscriptionTopic | string, SubscriptionRegistrationType>;

  onlineUsers: Record<number, boolean>;

  constructor(globalStore, userStore) {
    this.globalStore = globalStore;
    this.userStore = userStore;
    this.webSocketService = new WebSocketService();
    this.onlineUsers = {};
    this.subscriptions = {};
    this.subscriptionRegistrations = {};
    makeObservable(this, {
      onlineUsers: observable,
      getOnlineUsers: flow,
      connect: action,
      isUserOnline: action,
      subscribeToConversationUpdates: action,
      subscribeToUsersOnlineStatusUpdates: action,
      subscribeToVideoMeetInvitation: action,
      subscribeToVideoRoomUpdates: action,
      unsubscribe: action
    });
  }

  private _getConnectedStompClient(): Promise<Stomp.Client> {
    if (!this.stompClientPromise) {
      throw new Error("STOMP: Tying to use the client before it is created. Make sure to websocket is connected before subscribe");
    }
    return this.stompClientPromise;
  }

  async connect(): Promise<Stomp.Client> {

    const userId = this.userStore?.userInformation?.userId;

    if (!userId) {
      throw new Error('STOMP: user session is required for connecting to websockets');
    }

    if (this.stompClientPromise) {
      console.warn('STOMP: Application is trying to connect to websocket again. Returning same client instance.');
      return this.stompClientPromise;
    }

    this.stompClientPromise = new Promise((resolve, reject) => {
      console.log('STOMP: Connecting');
      clearTimeout(this.timeoutId);
      let restApiBaseURL = '';
      if (isMobileApp() && !isMobileAppLocalHost()) {
        restApiBaseURL = (window as any).MOBILE_UI_GLOBAL_DATA.restApiBaseURL;
      }
      if (isElectronApp() && !isElectronAppLocalHost()) {
        restApiBaseURL = (window as any).CHAT_UI_GLOBAL_DATA.restApiBaseURL;
      }
      var sock = new SockJS(restApiBaseURL + '/api/v1/vendor/public/websocket-connect?userId=' + userId);
      const client = Stomp.over(sock);
      sock.onopen = function () { }
      client.connect({}, (frame) => {
        console.log('STOMP: Connected');
        console.log('STOMP: ' + frame);
        resolve(client);
      }, (error) => {
        this.stompFailureCallback(error);
        reject();
      });
    });
    return this.stompClientPromise;
  }

  private disconnect() {
    console.log("STOMP: Disconnecting");
    this.stompClientPromise?.then(client => {
      if (client.connected) {
        try {
          client.disconnect(() => {
            console.log("STOMP: Disconnected");
          });
        } catch (e) {
          console.error("STOMP: Error disconnecting");
          console.error(e);
        }
      }
    })
  }

  *getOnlineUsers() {
    try {
      let response: ApiResponse = yield this.webSocketService.getOnlineUsers();
      const users = response.data.data as number[];
      let temp = {};
      users.forEach(u => {
        temp[u] = true;
      });
      this.onlineUsers = temp;
    } catch (e) {
      console.error("Error fetching online users list");
      this.onlineUsers = {};
    }
  }

  isUserOnline(userId: number): boolean {
    return this.onlineUsers[userId] ?? false;
  }

  async subscribeToConversationUpdates(onMessageReceived?: (notification: ConversationUpdatesNotification) => void): Promise<Stomp.Subscription> {
    const subscription = this._getConnectedStompClient().then((stompClient) => {
      return stompClient.subscribe(SubscriptionTopic.TOPIC_CONVERSATION_MESSAGE_UPDATES, (message) => {
        const notification: {
          userIdentity: number,
          twilioIdentity: string,
          conversationSid: string,
          hasMentions: boolean,
          lastMentionedDate: string,
        } = JSON.parse(message.body);
        console.log('STOMP: notification', notification);
        onMessageReceived && onMessageReceived(notification);
      });
    }).then((subscription) => {
      this.registerSubscription(subscription, {
        subscriptionTopic: SubscriptionTopic.TOPIC_CONVERSATION_MESSAGE_UPDATES,
        callBack: onMessageReceived
      });
      return subscription;
    });
    return subscription;
  }

  async subscribeToUsersOnlineStatusUpdates(onMessageReceived?: (userOnlineStatus: UserOnlineStatusNotification) => void): Promise<Stomp.Subscription> {
    this.getOnlineUsers();
    const subscription = this._getConnectedStompClient().then((stompClient) => {
      return stompClient.subscribe(SubscriptionTopic.TOPIC_USER_ONLINE_STATUS_UPDATES, (message) => {
        const userOnlineStatus: {
          userId: number,
          online: boolean,
        } = JSON.parse(message.body);
        console.log('STOMP: userOnlineStatus', userOnlineStatus);
        if (userOnlineStatus.online) {
          this.onlineUsers[userOnlineStatus.userId] = true;
        } else {
          delete this.onlineUsers[userOnlineStatus.userId];
        }
        onMessageReceived && onMessageReceived(userOnlineStatus);
      });
    }).then((subscription) => {
      this.registerSubscription(subscription, {
        subscriptionTopic: SubscriptionTopic.TOPIC_USER_ONLINE_STATUS_UPDATES,
        callBack: onMessageReceived
      });
      return subscription;
    });
    return subscription;
  }

  async subscribeToVideoMeetInvitation(onMessageReceived?: (videoRoom: VideoRoom) => void): Promise<Stomp.Subscription> {
    const subscription = this._getConnectedStompClient().then((stompClient) => {
      return stompClient.subscribe(SubscriptionTopic.TOPIC_USER_VIDEO_MEET_INVITATION, (message) => {
        const videoRoomResponse: VideoRoom = JSON.parse(message.body);
        console.log('STOMP: videoMeetInvitation', videoRoomResponse);
        onMessageReceived && onMessageReceived(videoRoomResponse);
      });
    }).then((subscription) => {
      this.registerSubscription(subscription, {
        subscriptionTopic: SubscriptionTopic.TOPIC_USER_VIDEO_MEET_INVITATION,
        callBack: onMessageReceived
      });
      return subscription;
    });
    return subscription;
  }

  async subscribeToVideoRoomUpdates(onMessageReceived?: (videoRoom: VideoRoom) => void): Promise<Stomp.Subscription> {
    const subscription = this._getConnectedStompClient().then((stompClient) => {
      return stompClient.subscribe(SubscriptionTopic.TOPIC_USER_VIDEO_ROOM_UPDATES, (message) => {
        const videoRoomResponse: VideoRoom = JSON.parse(message.body);
        console.log('STOMP: videoRoomUpdate', videoRoomResponse);
        onMessageReceived && onMessageReceived(videoRoomResponse);
      });
    }).then((subscription) => {
      this.registerSubscription(subscription, {
        subscriptionTopic: SubscriptionTopic.TOPIC_USER_VIDEO_ROOM_UPDATES,
        callBack: onMessageReceived
      });
      return subscription;
    });
    return subscription;
  }

  unsubscribe(subscriptionTopic: SubscriptionTopic) {
    this.unRegisterSubscription(subscriptionTopic);
  }

  private stompFailureCallback(error) {
    console.error('STOMP: Connection Failure.', error);
    this.stompClientPromise = undefined;
    this.timeoutId = setTimeout(() => {
      this.connect().then((client) => {
        Object.keys(this.subscriptionRegistrations).forEach((x: SubscriptionTopic) => {
          if (x == SubscriptionTopic.TOPIC_CONVERSATION_MESSAGE_UPDATES) {
            this.subscribeToConversationUpdates(this.subscriptionRegistrations[x].callBack);
          } else if (x == SubscriptionTopic.TOPIC_USER_ONLINE_STATUS_UPDATES) {
            this.subscribeToUsersOnlineStatusUpdates(this.subscriptionRegistrations[x].callBack);
          } else if (x == SubscriptionTopic.TOPIC_USER_VIDEO_MEET_INVITATION) {
            this.subscribeToVideoMeetInvitation(this.subscriptionRegistrations[x].callBack);
          } else if (x == SubscriptionTopic.TOPIC_USER_VIDEO_ROOM_UPDATES) {
            this.subscribeToVideoRoomUpdates(this.subscriptionRegistrations[x].callBack);
          }
        })
      });
    }, 30000);
    console.log('STOMP: Reconnecting in 30 seconds');
  }

  private registerSubscription(subscription: Subscription, subscriptionRegistration: SubscriptionRegistrationType) {
    this.subscriptions[subscriptionRegistration.subscriptionTopic] = subscription;
    this.subscriptionRegistrations[subscriptionRegistration.subscriptionTopic] = subscriptionRegistration
  }

  private unRegisterSubscription(subscriptionTopic: SubscriptionTopic) {
    this.subscriptions[subscriptionTopic]?.unsubscribe();
    delete this.subscriptions[subscriptionTopic];
    delete this.subscriptionRegistrations[subscriptionTopic];
  }

}