import { Grid } from '@material-ui/core';
import {
  ELMSURE, insuranceOptions,

  OTHER
} from '@roc/feature-utils';
import { useBaseStore } from '@roc/feature-app-core';
import { RadioField } from '@roc/ui';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { useDocumentStore } from '../../../hooks/useDocumentStore';
import { ElmsureInsurance } from './components/elmsureInsurance';
import { OtherInsurance } from './components/otherInsurance';

export const InsuranceProvider = observer(() => {
  const { documentStore } = useDocumentStore();
  const { globalStore } = useBaseStore();
  const { documentFormStore } = documentStore;
  const { insuranceProviderStore } = documentFormStore;
  const { insurance } = insuranceProviderStore;

  const setInsurance = (insurance: string) => {
    insuranceProviderStore.setInsurance(insurance);
  };

  useEffect(() => {
    insuranceProviderStore.initialize();
  }, [insuranceProviderStore]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <RadioField
          standaloneLabel
          label="Please make your Insurance selection"
          name="selectedInsurance"
          value={insurance}
          options={insuranceOptions}
          onChange={setInsurance}
          fullWidth
          row
          testId="selectedInsurance"
        />
      </Grid>
      <Grid item xs={12}>
        {insurance === ELMSURE && <ElmsureInsurance />}
        {insurance === OTHER && <OtherInsurance />}
      </Grid>
    </Grid>
  );
});

export default InsuranceProvider;
