import { observer } from 'mobx-react';
import { Button } from '@roc/ui';
import { Done, Delete } from '@material-ui/icons';
import { Grid, makeStyles } from '@material-ui/core';
import { deleteButtonStyle, beigeButtonColorStyle } from '../utils/constants';

const useStyles = makeStyles(() => ({
  deleteButton: deleteButtonStyle,
  beigeButtonColor: beigeButtonColorStyle,
}));

interface EditControlsProps {
  onDeleteClick?: () => void;
  onSaveClick?: () => void;
  onResetClick?: () => void;
  showDeleteButton?: boolean;
  hasCustomClass?: boolean;
  removeButtonDisabled?: boolean;
}

export const EditControls = observer(
  ({
    onDeleteClick = () => {
      /** */
    },
    onSaveClick = () => {
      /** */
    },
    removeButtonDisabled = false,
    showDeleteButton = true,
    hasCustomClass,
    onResetClick,
  }: EditControlsProps) => {
    const classes = useStyles();

    return (
      <>
        {onResetClick && (
          <Grid item>
            <Button
              size="medium"
              variant="outlined"
              color="primary"
              testId="reset-button"
              onClick={onResetClick}
            >
              RESET
            </Button>
          </Grid>
        )}
        <Grid item>
          <Button
            size="medium"
            variant="outlined"
            color="primary"
            startIcon={<Delete />}
            testId="delete-borrower"
            onClick={onDeleteClick}
            className={classes.deleteButton}
            disabled={removeButtonDisabled}
          >
            REMOVE
          </Button>
        </Grid>
        <Grid item>
          <Button
            size="medium"
            variant="outlined"
            color="primary"
            startIcon={<Done />}
            testId="save-borrower"
            onClick={onSaveClick}
          >
            SAVE
          </Button>
        </Grid>
      </>
    );
  }
);

interface EditControlsPreflightCardsProps {
  onResetClick?: () => void;
  onSaveClick?: () => void;
  onSkipClick?: () => void;
  disableButtons?: boolean;
}

export const EditControlsPreflightCards = observer(
  ({
    onResetClick = () => {
      /** */
    },
    onSaveClick = () => {
      /** */
    },
    onSkipClick = () => {
      /** */
    },
    disableButtons = false,
  }: EditControlsPreflightCardsProps) => {
    const classes = useStyles();

    return (
      <>
        <Grid item>
          <Button
            size="medium"
            variant="outlined"
            color="primary"
            testId="reset-button"
            onClick={onResetClick}
            disabled={disableButtons}
          >
            RESET
          </Button>
        </Grid>
        <Grid item>
          <Button
            size="medium"
            variant="outlined"
            color="primary"
            testId="save-button"
            onClick={onSaveClick}
            disabled={disableButtons}
          >
            SAVE
          </Button>
        </Grid>
        <Grid item>
          <Button
            size="medium"
            variant="outlined"
            color="primary"
            testId="reset-button"
            onClick={onSkipClick}
          >
            SKIP
          </Button>
        </Grid>
      </>
    );
  }
);
