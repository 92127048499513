import { Divider, List, ListItem, ListItemText } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { formatCurrency } from '@roc/ui/utils';
import { useLoanStore } from './../../../../hooks/useLoanStore';
import { observer } from 'mobx-react';


const listItem = (label, value) => {
  return (
    <ListItem disableGutters>
      <ListItemText>
        <Box display="flex">
          <Box flexGrow={1}>{label}</Box>
          <Box>{value}</Box>
        </Box>
      </ListItemText>
    </ListItem>
  );
};

const divider = <Divider component="li" />;

export const RequestKeyDates = observer(() => {
  const { loanStore } = useLoanStore();
  const { loanDetails } = loanStore;

  let totalFunded = 0;
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h5">Key Dates</Typography>
      </Grid>
      <Grid item xs={12}>
        <List>
          {listItem('Submission', loanDetails.submissionDate ?? '-')}
          {divider}
          {listItem('Exp. Closing', loanDetails.expectedClosingDate ?? '-')}
          {divider}
          {listItem('Closing Date', loanDetails.closingDate ?? '-')}
          {divider}
          {listItem(
            'Maturity',
            loanDetails.extendedMaturityDate ?? loanDetails.maturityDate ?? '-'
          )}
          {loanStore.previousDraws &&
            loanStore.previousDraws.map((draw, index) => {
              totalFunded += draw.totalAmount;
              return (
                <div key={draw}>
                  {divider}
                  <ListItem disableGutters>
                    <ListItemText>
                      <Box display="flex">
                        <Box flexGrow={1}>{`${draw.totalAmount > 0 ? 'Draw' : 'Paydown'
                          } #${index + 1}`}</Box>
                        <Box flexGrow={1}>
                          {draw.totalAmount > 0
                            ? draw.transactionEffectiveDate
                            : draw.effectiveDate}
                        </Box>
                        <Box>{formatCurrency(draw.totalAmount)}</Box>
                      </Box>
                    </ListItemText>
                  </ListItem>
                </div>
              );
            })}
          {totalFunded > 0 && (
            <>
              {divider}
              {listItem('Total Funded', formatCurrency(totalFunded))}
            </>
          )}
        </List>
      </Grid>
    </Grid>
  );
});

export default RequestKeyDates;
