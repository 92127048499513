import React from 'react';
import { Grid as MuiGrid, Checkbox, Typography } from '@material-ui/core';
import { formatCurrency } from '@roc/ui/utils';
import { observer } from 'mobx-react';
import { Button, DataGrid as Grid } from '@roc/ui';
import { formatDate } from '@roc/client-portal-shared/utils';
import InsuranceApprovalStore from '../../../../../../stores/documentForms/insuranceApproval/insuranceApprovalStore';

const columns = [
  {
    field: 'quoteType',
    headerName: 'Quote Type',
    cellRenderer: 'quoteTypeCellRenderer',
    minWidth: 260,
  },
  {
    field: 'cost',
    headerName: 'Cost',
    cellRenderer: 'currencyCellRenderer',
  },
  {
    field: 'paidAlreadyAmount',
    headerName: 'Paid Already Amount',
    cellRenderer: 'currencyCellRenderer',
  },
  {
    field: 'remainingAmount',
    headerName: 'Remaining Amount',
    cellRenderer: 'currencyCellRenderer',
  },
  {
    field: 'paymentType',
    headerName: 'Payment Type',
  },
  {
    field: 'policyExpirationDate',
    cellRenderer: 'dateCellRenderer',
    headerName: 'Policy Expiration Date',
  },
  {
    field: 'selectedQuote',
    headerName: '',
    cellRenderer: 'selectedCellRenderer',
    minWidth: 80,
    maxWidth: 80,
  },
];

export type FinalChoiceQuotesGridProps = {
  insuranceApprovalStore: InsuranceApprovalStore;
  disableSelection?: boolean;
};

export const FinalChoiceQuotesGrid = observer(
  (props: FinalChoiceQuotesGridProps) => {
    const { insuranceApprovalStore, disableSelection } = props;
    const { insuranceApprovalFormStore } = insuranceApprovalStore;

    const setQuoteSelected = (quote, checked) => {
      insuranceApprovalFormStore.toggleSelectedQuote(quote, checked);
    };

    const frameworkComponents = {
      quoteTypeCellRenderer: params => {
        const { data: quote } = params;
        return quote ? quote.insuranceCompany + ' - ' + quote.quoteType : '';
      },
      currencyCellRenderer: params => {
        return formatCurrency(params.value);
      },
      dateCellRenderer: ({ value }) => formatDate(value, 'MM/dd/yyyy') ?? null,
      selectedCellRenderer: params => {
        return (
          <Checkbox
            checked={params.value}
            // Disabled by default since this is an Insurance Reviewer specific feature
            // TODO: Remove when adding Insurance Reviewer role.
            disabled={disableSelection}
            onChange={(e, checked) =>
              setQuoteSelected(params.node.data, checked)
            }
          />
        );
      },
    };

    return (
      <MuiGrid container spacing={1}>
        <MuiGrid item xs={12}>
          <Typography color="primary">Final Choice</Typography>
        </MuiGrid>
        <MuiGrid item xs={12}>
          <Grid
            columns={columns}
            rows={insuranceApprovalFormStore.finalQuotes}
            frameworkComponents={frameworkComponents}
            domLayout="autoHeight"
          />
        </MuiGrid>
      </MuiGrid>
    );
  }
);

export default FinalChoiceQuotesGrid;
