import { Grid, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { FixFlipStore } from '../stores';

const useStyles = makeStyles(theme =>
  createStyles({
    tierColumn: {
      textAlign: 'center',
    },
    sharedCellStyles: {
      fontSize: '0.8rem',
      padding: '4px',
    },
    oddRow: {
      backgroundColor: '#F7F7F7'
    },
    headerCell: {
      fontWeight: 'bold',
    },
  })
);

const COLUMNS_2 = [
  {
    headerName: 'Experience Tier',
  },
  {
    headerName: 'Renovation Type',
  },
  {
    headerName: 'FICO',
  },
  {
    headerName: 'Max LTC',
  },
  {
    headerName: 'Max LTFC',
  },
  {
    headerName: 'Max LTARV',
  },
  {
    headerName: 'Exit Test',
  },
];

const DATA_2 = [
  {
    experience: '5',
    rows: [
      {
        renovation: 'Light (<25% of Purchase)',
        fico: '>= 660',
        ltc: '90%',
        ltfc: '-',
        ltarv: '75%',
        exit: '>=0% ROI or 1.1 DSCR',
      },
      {
        renovation: 'Moderate (>25% & <=50% of Purchase)',
        fico: '>= 660',
        ltc: '90%',
        ltfc: '-',
        ltarv: '75%',
        exit: '>=0% ROI or 1.1 DSCR',
      },
      {
        renovation: 'Heavy (>50% & <100% of Purchase)',
        fico: '>= 660',
        ltc: '90%',
        ltfc: '-',
        ltarv: '75%',
        exit: '>=0% ROI or 1.1 DSCR',
      },
      {
        renovation: 'Extensive (>=100% of Purchase) Addition, Expansion, Conversion, ADU',
        fico: '>= 680',
        ltc: '85%',
        ltfc: '90%',
        ltarv: '70%',
        exit: '>=10% ROI',
      },
    ]
  },
  {
    experience: '4',
    rows: [
      {
        renovation: 'Light (<25% of Purchase)',
        fico: '>= 660',
        ltc: '90%',
        ltfc: '-',
        ltarv: '75%',
        exit: '>=0% ROI or 1.1 DSCR',
      },
      {
        renovation: 'Moderate (>25% & <=50% of Purchase)',
        fico: '>= 660',
        ltc: '90%',
        ltfc: '-',
        ltarv: '75%',
        exit: '>=0% ROI or 1.1 DSCR',
      },
      {
        renovation: 'Heavy (>50% & <100% of Purchase)',
        fico: '>= 660',
        ltc: '85%',
        ltfc: '-',
        ltarv: '75%',
        exit: '>=0% ROI or 1.1 DSCR',
      },
      {
        renovation: 'Extensive (>=100% of Purchase) Addition, Expansion, Conversion, ADU',
        fico: '>= 680',
        ltc: '85%',
        ltfc: '90%',
        ltarv: '70%',
        exit: '>=10% ROI',
      },
    ]
  },
  {
    experience: '3',
    rows: [
      {
        renovation: 'Light (<25% of Purchase)',
        fico: '>= 660',
        ltc: '85%',
        ltfc: '-',
        ltarv: '75%',
        exit: '>=10% ROI or 1.1 DSCR',
      },
      {
        renovation: 'Moderate (>25% & <=50% of Purchase)',
        fico: '>= 660',
        ltc: '85%',
        ltfc: '-',
        ltarv: '75%',
        exit: '>=10% ROI or 1.1 DSCR',
      },
      {
        renovation: 'Heavy (>50% & <100% of Purchase)',
        fico: '>= 660',
        ltc: '85%',
        ltfc: '-',
        ltarv: '75%',
        exit: '>=10% ROI or 1.1 DSCR',
      },
      {
        renovation: 'Extensive (>=100% of Purchase) Addition, Expansion, Conversion, ADU',
        fico: '>= 680',
        ltc: '80%',
        ltfc: '85%',
        ltarv: '70%',
        exit: '>=20% ROI',
      },
    ]
  },
  {
    experience: '2',
    rows: [
      {
        renovation: 'Light (<25% of Purchase)',
        fico: '>= 680',
        ltc: '80%',
        ltfc: '-',
        ltarv: '70%',
        exit: '>=20% ROI or 1.1 DSCR',
      },
      {
        renovation: 'Moderate (>25% & <=50% of Purchase)',
        fico: '>= 680',
        ltc: '80%',
        ltfc: '-',
        ltarv: '70%',
        exit: '>=20% ROI or 1.1 DSCR',
      },
      {
        renovation: 'Heavy (>50% & <100% of Purchase)',
        fico: '>= 680',
        ltc: '75%',
        ltfc: '-',
        ltarv: '70%',
        exit: '>=20% ROI or 1.1 DSCR',
      },
      {
        renovation: 'Extensive (>=100% of Purchase) Addition, Expansion, Conversion, ADU',
        fico: '-',
        ltc: '-',
        ltfc: '-',
        ltarv: '-',
        exit: '-',
      },
    ]
  },
  {
    experience: '1',
    rows: [
      {
        renovation: 'Light (<25% of Purchase)',
        fico: '>= 660',
        ltc: '75%',
        ltfc: '-',
        ltarv: '70%',
        exit: '>=30% ROI or 1.1 DSCR',
      },
      {
        renovation: 'Light (<25% of Purchase)',
        fico: '>= 720',
        ltc: '80%',
        ltfc: '-',
        ltarv: '70%',
        exit: '>=30% ROI or 1.1 DSCR',
      },
      {
        renovation: 'Moderate (>25% & <=50% of Purchase)',
        fico: '-',
        ltc: '-',
        ltfc: '-',
        ltarv: '-',
        exit: '-',
      },
      {
        renovation: 'Heavy (>50% & <100% of Purchase)',
        fico: '-',
        ltc: '-',
        ltfc: '-',
        ltarv: '-',
        exit: '-',
      },
      {
        renovation: 'Extensive (>=100% of Purchase) Addition, Expansion, Conversion, ADU',
        fico: '-',
        ltc: '-',
        ltfc: '-',
        ltarv: '-',
        exit: '-',
      },
    ]
  },
];

export const ExperienceMatrix = observer(({ store }: { store: FixFlipStore }) => {
  const classes = useStyles();


  return (

    <Grid container>
      <Grid item xs={12}>
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                {COLUMNS_2.map(col => (
                  <TableCell className={`${classes.sharedCellStyles} ${classes.headerCell}`}>{col.headerName}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {DATA_2.map(tier => (
                <>
                  <TableRow>
                    <TableCell rowSpan={tier.rows.length + 1} className={`${classes.sharedCellStyles} ${classes.tierColumn}`} >
                      {tier.experience}
                    </TableCell>
                  </TableRow>
                  {tier.rows.map((row, index) => (
                    <TableRow className={index % 2 !== 0 ? classes.oddRow : ''}>
                      <TableCell className={`${classes.sharedCellStyles}`}>{row.renovation}</TableCell>
                      <TableCell className={`${classes.sharedCellStyles}`}>{row.fico}</TableCell>
                      <TableCell className={`${classes.sharedCellStyles}`}>{row.ltc}</TableCell>
                      <TableCell className={`${classes.sharedCellStyles}`}>{row.ltfc}</TableCell>
                      <TableCell className={`${classes.sharedCellStyles}`}>{row.ltarv}</TableCell>
                      <TableCell className={`${classes.sharedCellStyles}`}>{row.exit}</TableCell>
                    </TableRow>
                  ))}
                </>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </Grid>
    </Grid>
  );
});

export default ExperienceMatrix;
