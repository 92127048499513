import { ApiResponse, GlobalStore, UserStore } from "@roc/feature-app-core";
import { LoanService } from "@roc/feature-loans";
import { formatCurrency, formatPercentage } from "@roc/ui/utils";
import { action, flow, makeObservable, observable } from "mobx";

export class LoanCommissionStore {
  private globalStore: GlobalStore;
  private loanService: LoanService;
  private userStore: UserStore;
  commissionData: any = undefined;

  constructor(globalStore: GlobalStore, userStore: UserStore) {
    this.globalStore = globalStore;
    this.loanService = new LoanService();
    this.userStore = userStore;

    makeObservable(this, {
      getBridgeCommission: flow,
      getTermCommission: flow,
      getTermCommissionAPI: flow,
      commissionData: observable,
      setCommissions: action,
    });
  }

  async getBridgeCommission(data) {
    try {
      if (data.loanAmount === undefined || data.loanAmount === 0) {
        this.resetStore();
        throw new Error("Loan amount is 0");
      }
      const companyId = this.userStore?.userInformation?.companyId ?? this.globalStore?.selectedCompanyId;
      const request = { ...data, companyId };
      const apiResponse: ApiResponse = await this.loanService.getBridgeCommmission(
        request
      );
      const response = apiResponse.data?.data;
      this.commissionData = response;
      return apiResponse;
    } catch (error) {
      console.log(error);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching commission details.',
      });
    }
  }

  *getTermCommission(data) {
    try {
      if (data.loanAmount === undefined || data.loanAmount === 0) {
        this.resetStore();
        throw new Error("Loan amount is 0");
      }

      const apiResponse: ApiResponse = yield this.getTermCommissionAPI(data);
      const response = apiResponse.data?.data;
      this.commissionData = response;
      return apiResponse;
    } catch (error) {
      console.log(error);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching commission details.',
      });
    }
  }

  *getTermCommissionAPI(data) {
    try {
      const companyId = this.userStore?.userInformation?.companyId ?? this.globalStore?.selectedCompanyId;
      const request = { ...data, companyId };
      return yield this.loanService.getTermCommission(
        request
      );
    } catch (error) {
      console.log(error);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching commission details.',
      });
    }
  }

  convertApiResponse(response) {
    return {
      ...response,
      appraisalReviewed: response?.customFields?.appraisalReviewed,
      creditReviewed: response?.customFields?.creditReviewed,
      backgroundReviewed: response?.customFields?.backgroundReviewed
    };
  };

  resetStore() {
    this.commissionData = {};
  }

  setCommissions(commissions) {
    this.commissionData = commissions;
  }
}
