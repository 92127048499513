import { observer } from 'mobx-react';
import React, { ReactNode, useEffect } from 'react';
import { AgGridColumnProps, DataGrid, StandardDialog } from '@roc/ui';
import { useStore } from '@roc/client-portal-shared/hooks';
import { IconButton } from '@material-ui/core';
import { Layout } from '@roc/feature-app-core';
import { makeStyles } from '@material-ui/core/styles';
import { Edit } from '@material-ui/icons';
import { CreateTemplateModal } from 'apps/crm-portal/src/app/modules/ringCentral/createTemplate';
import { Theme } from '@material-ui/core/styles';



const noOpComparator = () => 0;

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  gridContainer: {
    display: 'flex',
  },
  button: {
    margin: '10px',
    minWidth: '150px',
  },
}));

const columns: AgGridColumnProps[] = [
  {
    field: 'templateId',
    headerName: 'Template Id',
    minWidth: 150,
    maxWidth: 150,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'label',
    headerName: 'Label',
    minWidth: 160,
    maxWidth: 280,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'type',
    headerName: 'Type',
    minWidth: 150,
    maxWidth: 150,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'value',
    headerName: 'Content',
    minWidth: 60,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    cellRenderer: 'contentRenderer',
    floatingFilter: true,
    hide: false,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'subject',
    headerName: 'subject',
    minWidth: 150,
    maxWidth: 150,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'action',
    headerName: 'Action',
    minWidth: 25,
    maxWidth: 80,
    cellRenderer: 'actionCellRenderer',
    comparator: noOpComparator,
  },
];

export const ManageTemplatesGrid = observer(({ toolbar }: { toolbar: ReactNode }) => {
  const { companyProfileStore, companyDropdownStore, ringCentralStore } = useStore();

  const onSortChanged = params => {
    const sortModel = params.api.getSortModel();
    ringCentralStore.templatesGrid.setSortModel(sortModel);
  };

  const onFilterChanged = params => {
    const filterModel = params.api.getFilterModel();
    ringCentralStore.templatesGrid.setFilterModel(filterModel);
  };


  const handleOnclick = async (params) => {
    await companyDropdownStore.changeCompany(params.data?.companyId);
    companyProfileStore.setDisplayCompanyProfile(true);
  }

  const openMenu = (event: React.MouseEvent<HTMLElement>, record) => {
    const { templateId, label, value, type, subject, templateAttachments, bombBombVideoLink, imageInsertions } = record;
    ringCentralStore.setTemplateId(templateId);
    ringCentralStore.setLabel(label);
    ringCentralStore.setTemplateValue(value);
    ringCentralStore.setTemplateSubject(subject);
    ringCentralStore.setTemplateType(type);
    ringCentralStore.setTemplateAttachments(templateAttachments);
    ringCentralStore.setImageInsertions(imageInsertions);
    ringCentralStore.setOpenCreateTemplateModal(true);
    ringCentralStore.setBombBombVideoLinkValue(bombBombVideoLink);
  };

  const handleMenuClose = () => {
    ringCentralStore.setTemplateId('');
    ringCentralStore.setLabel('');
    ringCentralStore.setTemplateValue('');
    ringCentralStore.setTemplateType('');
    ringCentralStore.setTemplateSubject('');
    ringCentralStore.setTemplateAttachments([]);
    ringCentralStore.setOpenCreateTemplateModal(false);
    ringCentralStore.setBombBombVideoLinkValue('');
    ringCentralStore.setImageInsertions('');
  };

  const getTextFromHTML = (htmlString: string) => {
    const tempElement = document.createElement('div');
    tempElement.innerHTML = htmlString;
    return tempElement.innerText
  }


  const frameworkComponents = {
    actionCellRenderer: params => {
      return (
        <IconButton onClick={e => openMenu(e, params.node.data)} >
          <Edit color="primary" />
        </IconButton>
      );
    },
    contentRenderer: ({ value }) => getTextFromHTML(value)
  };

  const onCreateTemplate = () => {
    ringCentralStore.setOpenCreateTemplateModal(true);
  }

  return (

    <Layout>
      <StandardDialog open={ringCentralStore.openCreateTemplateModal} title="Manage Text/Email Templates" maxWidth="xl" handleClose={handleMenuClose} dialogContent={<CreateTemplateModal handleClose={handleMenuClose} />} />
      <DataGrid
        suppressRowClickSelection={false}
        frameworkComponents={frameworkComponents}
        toolbar={toolbar}
        columns={columns}
        rows={ringCentralStore.templatesGrid.gridData.data.rows}
        onSortChanged={onSortChanged}
        onFilterChanged={onFilterChanged}
        isLoading={ringCentralStore.templatesGrid.isLoading}
        showFilters={ringCentralStore.templatesGrid.showFilters}
        sortModel={ringCentralStore.templatesGrid.sortModel}
        filterModel={ringCentralStore.templatesGrid.filterModel}
        paginationData={ringCentralStore.templatesGrid.gridData.meta}
        setPageNumber={ringCentralStore.templatesGrid.setPageNumber}
        rowHeight={60}
      />
    </Layout>
  );
});
