import { ApiResponse, GlobalStore } from '@roc/feature-app-core';
import { GENERIC_ERROR_MESSAGE } from '@roc/feature-utils';
import { action, computed, flow, makeObservable, observable } from 'mobx';
import { CreateListService } from '../service/createListService';
import { AddFilterFormStore } from './AddFilterFormStore';
import { CreateListFormStore } from './CreateListFormStore';

export enum ObjectType {
  LEAD = 'Lead',
  CONTACT = 'Contact'
}

export interface FieldDefinition {
  fieldDisplayName: string,
  fieldName: string,
  fieldType: string,
  optionValueFields: any,
  optionValueFieldsDisplay: any
}

export class CreateListStore {
  public globalStore: GlobalStore;
  createListService: CreateListService;
  public addFilterFormStore: AddFilterFormStore;
  public createListFormStore: CreateListFormStore;
  public showFilterLogic: boolean;
  public filterLogicText: string;
  public filters: any[];
  public filterCriteriaOptions: any[];
  public fieldsDropdownValues: any[];
  public filterLogicInputValue: string;
  public objectType: string;
  public recordType: string;
  public brand: string;

  public fields: FieldDefinition[] = [];
  public query: any;

  constructor(globalStore) {
    this.globalStore = globalStore;
    this.createListService = new CreateListService();
    this.addFilterFormStore = new AddFilterFormStore(globalStore);
    this.createListFormStore = new CreateListFormStore(globalStore);
    this.setDefaults();
    makeObservable(this, {
      showFilterLogic: observable,
      filterLogicText: observable,
      filterCriteriaOptions: observable,
      fieldsDropdownValues: observable,
      save: flow,
      addFilter: action,
      editFilter: action,
      removeAllFilters: action,
      removeFilterByIndex: action,
      setFilterValuesByIndex: action,
      reset: action,
      filters: observable,
      filterLogicByExistingFilter: computed,
      typeBySelectedField: computed,
      selectedField: computed,
      setFilterCriteriaOptions: flow,
      getFilterCriteriaOptions: flow,
      currentOperatorsValue: computed,
      filterLogicInputValue: observable,
      fields: observable,
      query: observable,
      setQuery: action,
      fetchFilterFields: flow,
      saveDashboard: flow
    });
  }

  setDefaults() {
    this.filterLogicText = '';
    this.filterLogicInputValue = '';
    this.objectType = '';
    this.recordType = '';
    this.brand = '';
    this.filters = [];
    this.filterCriteriaOptions = [];
    this.fieldsDropdownValues = [];
    this.showFilterLogic = false;
    this.query = null;
  }

  *save() {
    try {
      const body = {
        name: this.createListFormStore.form.fields.listName.value,
        objectType: this.createListFormStore.form.fields.objectType.value,
        recordType: this.createListFormStore.form.fields.recordType.value,
        brand: this.createListFormStore.form.fields.brand.value,
        filterLogic: this.filterLogicText,
        filter: this.filters.map(filter => {
          if (filter.type === 'multiselect') {
            return {
              fieldName: filter.field,
              operator: filter.operator,
              value: filter.value,
              type: filter.type,
            }
          }
          return {
            fieldName: filter.field,
            operator: filter.operator,
            value: filter.value[0],
            type: filter.type,
          }
        }),
      };
      const response = yield this.createListService.submitNewList(body);
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'New List Submitted Successfully',
      });
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error Submitting a new List.',
      });
    }

  }

  addFilter() {
    const value = Array.isArray(this.addFilterFormStore.form.fields.value.value)
      ? this.addFilterFormStore.form.fields.value.value
      : [this.addFilterFormStore.form.fields.value.value];

    this.filters.push({
      field: this.addFilterFormStore.form.fields.field.value,
      value: value,
      operator: this.addFilterFormStore.form.fields.operator.value,
      type: this.typeBySelectedField
    });

    this.filterLogicText = this.filterLogicByExistingFilter;
    this.addFilterFormStore.reset();
  }

  editFilter(index: number) {
    const value = Array.isArray(this.addFilterFormStore.form.fields.value.value)
      ? this.addFilterFormStore.form.fields.value.value
      : [this.addFilterFormStore.form.fields.value.value];

    this.filters[index] = {
      field: this.addFilterFormStore.form.fields.field.value,
      value: value,
      operator: this.addFilterFormStore.form.fields.operator.value,
      type: this.typeBySelectedField
    };

    this.filterLogicText = this.filterLogicByExistingFilter;
    this.addFilterFormStore.reset();
  }

  removeAllFilters() {
    this.filters = [];
    this.filterLogicText = '';
  }

  removeFilterByIndex(index: number) {
    this.filters.splice(index, 1);
    this.filterLogicText = this.filterLogicByExistingFilter;
  }

  setFilterValuesByIndex(index: number) {
    this.addFilterFormStore.form.fields.field.value = this.filters[index].field;
    this.addFilterFormStore.form.fields.operator.value = this.filters[index].operator;
    this.addFilterFormStore.form.fields.value.value = this.filters[index].value;
  }

  get filterLogicByExistingFilter() {
    let outputString = '';
    if (this.filters.length > 0) {
      outputString += '1';
      for (let i = 2; i < this.filters.length + 1; i++) {
        outputString += ` AND ${i}`;
      }
    }
    return outputString;
  }

  get typeBySelectedField() {
    const selectedField = this.addFilterFormStore.form.fields.field.value;
    if (selectedField) {
      const selectedOption = this.filterCriteriaOptions.find(option => option.dbName === selectedField);
      return selectedOption?.type ? selectedOption.type : 'text';
    }
    return 'text'
  }

  get selectedField() {
    const selectedField = this.addFilterFormStore.form.fields.field.value;
    if (selectedField) {
      const selectedOption = this.filterCriteriaOptions.find(option => option.dbName === selectedField);
      return selectedOption;
    }
    return 'text';
  }


  getFieldsDropdownValues(objectType) {
    return this.filterCriteriaOptions
      .filter(option => option.objectType === objectType)
      .map(option => {
        return {
          label: option.name,
          value: option.dbName
        };
      });
  }

  *getFilterCriteriaOptions() {
    try {
      const response = yield this.createListService.getFilterCriteriaOptions();
      this.filterCriteriaOptions = response?.data?.data;
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error getting Filter Criteria for Create List.',
      });
    }
  }

  *setFilterCriteriaOptions(objectType) {
    try {
      this.fieldsDropdownValues = this.getFieldsDropdownValues(objectType);
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error getting Filter Criteria for Create List.',
      });
    }
  }




  get currentOperatorsValue() {
    const selectedField = this.addFilterFormStore.form.fields.field.value;
    if (selectedField) {
      const selectedOption = this.filterCriteriaOptions.find(option => option.dbName === selectedField);
      return selectedOption.operators ? JSON.parse(selectedOption.operators).map(operator => {
        return {
          label: operator.label,
          value: operator.value,
        }
      }) : [];
    }
    return [];
  }

  reset() {
    this.setDefaults()
  }

  *fetchFilterFields(){
    try {
      const objectType = this.createListFormStore.form.fields.objectType.value;
      const response = yield this.createListService.getFilterFields(objectType);
      this.fields = response.data.data;
    } catch(e){
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE,
      });
    }
  }

  setQuery(value){
    this.query = value
    console.log('setQuery', value)
  }

  *saveDashboard(){
    try {
      if(!this.createListFormStore.runFormValidationWithMessage()){
        return false;
      }

      if(!this.query){
        this.globalStore.notificationStore.showErrorNotification({
          message: 'Add at least one filter criteria before saving'
        })
        return false;
      }

      const formValues = this.createListFormStore.getFormValues();

      const body = {
        name: formValues.listName,
        objectType: formValues.objectType,
        recordType: formValues.recordType,
        columns: 'firstName,lastName,name,leadSource,status',
        baseObject:'Lead',
        groupBy: '',
        sortBy:'firstName',
        sortDir:'asc',
        // brand: this.createListFormStore.form.fields.brand.value,
        // filterLogic: this.filterLogicText,
        filters: this.query,
      };

      const response = yield this.createListService.saveCustomDashboard(body, null);
      return true;
    } catch(e){
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE,
      });
    }

  }
}