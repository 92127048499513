import { Grid } from '@material-ui/core';
import { DateFormat, formatDate } from '@roc/feature-utils';
import { Button, createCurrencyField, createDateField, StandardDialog } from '@roc/ui';
import { formatCurrency } from '@roc/ui/utils';
import { observer } from 'mobx-react';
import { useForeclosureSummaryStore } from '../../hooks/useForeclosureSummaryStore';
import { DetailsCard } from './detailsCard';
import moment from 'moment';
import { foreclosureStages } from '../../utils/constans';

export const SoldAtForeclosureDetails = observer(() => {
  const { foreclosureSummaryStore } = useForeclosureSummaryStore();
  const { soldAtForeclosureDetailsStore } = foreclosureSummaryStore;
  const data = foreclosureSummaryStore?.foreclosureSummary?.soldAtForeclosureDetails;

  return (
    <>
      <DetailsCard
        title={'Sold at Foreclosure Details'}
        onEdit={e => {
          e.stopPropagation();
          soldAtForeclosureDetailsStore.openSoldAtDetailsDialog(data);
        }}
        data={[
          {
            name: 'Sale Date',
            value: data?.saleDate
              ? formatDate(data?.saleDate, DateFormat.MMDDYYYY)
              : 'N/A',
          },
          {
            name: 'Sale Price',
            value: data?.salePrice
              ? formatCurrency(data?.salePrice)
              : 'N/A',
          },
        ]}
        columnsNum={1}
        expanded={foreclosureSummaryStore?.foreclosureSummary?.foreclosureStage == foreclosureStages.PROPERTY_SOLD_AT_FORECLOSURE}
      />
      <EditSoldAtForeclosureDetailsModal />
    </>
  );
});

const EditSoldAtForeclosureDetailsModal = observer(() => {
  const { foreclosureSummaryStore } = useForeclosureSummaryStore();
  const { soldAtForeclosureDetailsStore } = foreclosureSummaryStore;
  const store = soldAtForeclosureDetailsStore.soldAtDetailsFormStore;

  const handleCancel = () => {
    soldAtForeclosureDetailsStore.closeSoldAtDetailsDialog();
  };

  const handleSave = async () => {
    await soldAtForeclosureDetailsStore.saveSoldAtDetails(foreclosureSummaryStore?.foreclosureSummary?.loanId);
    foreclosureSummaryStore.getSummary(foreclosureSummaryStore?.foreclosureSummary?.loanId);
  };

  const onDateChange = (fieldName, value) => {
    store.onFieldChange(fieldName, moment(value).format("MM/DD/YYYY"));
  }

  const dialogContent = (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {createDateField({
          store,
          testId: 'saleDate',
          fieldName: 'saleDate',
          label: 'Sale Date',
          onChange: onDateChange,
        })}
      </Grid>
      <Grid item xs={12}>
        {createCurrencyField({
          store,
          testId: 'salePrice',
          fieldName: 'salePrice',
          label: 'Sale Price',
        })}
      </Grid>
    </Grid>
  );

  return (
    <StandardDialog
      title={'Sold at Foreclosure Details'}
      open={soldAtForeclosureDetailsStore.isSoldAtDetailDialogOpen}
      handleClose={handleCancel}
      maxWidth="md"
      dialogContent={dialogContent}
      removeContentBox
      dialogActions={
        <>
          <Button
            style={{ marginRight: '8px' }}
            onClick={handleCancel}
            color="primary"
            variant="outlined"
            testId="cancel"
          >
            Cancel
          </Button>
          <Button
            onClick={handleSave}
            color="primary"
            variant="contained"
            testId="save"
          >
            Save
          </Button>
        </>
      }
    />
  );
});
