import {
  Box,
  Grid,
  List,
  Typography
} from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { SubdirectoryArrowRight } from '@material-ui/icons';
import { BooleanChip, GoogleMap, StandardDialog } from '@roc/ui';
import { formatCurrency as formatCurrencyUtil, formatNumberWithCommas } from '@roc/ui/utils';
import React from 'react';
import { Dividers, listItem } from '../overview/borrowerLoanOverviewComponents';
import { LoanSubType, isFixAndFlip } from '@roc/feature-utils';

const useStyles = makeStyles(theme =>
  createStyles({
    mapStyles: {
      position: `relative`,
      width: `100%`,
      height: `95%`,
    },
    paper: {
      padding: theme.spacing(1),
      marginBottom: theme.spacing(2),
    },
  })
);

const formatCurrency = value => (value ? formatCurrencyUtil(value) : null);

export interface PropertiesProps {
  loanSubtype: string;
  property?: any;
}

const BorrowerPropery: React.FC<PropertiesProps> = ({
  loanSubtype,
  property
}) => {
  const classes = useStyles();

  const isFixFlipPro = loanSubtype === LoanSubType.FIX_AND_FLIP_PRO;

  return (
    property ?
      <Box width={'100%'}>
        <Typography variant="h5" gutterBottom>
          {property.address}
        </Typography>
        <Grid container direction="row" style={{
          width: '100%',
          margin: 0,
        }}>
          {property.latitude && property.longitude ? (
            <Grid item xs={12}>
              <Box height={200}>
                <GoogleMap
                  mapStyles={classes.mapStyles}
                  latitude={property.latitude}
                  longitude={property.longitude}
                />
              </Box>
            </Grid>
          ) : null}
          <Grid item xs={12} md={6}>
            <List dense>
              <Dividers variant="middle" component="li">
                {listItem('Acquisition', property.propertyOwnership)}
                {listItem(
                  'Purchase Price',
                  formatCurrency(property.purchasePrice)
                )}
                {listItem(
                  'Reno Budget',
                  formatCurrency(property.renovationBudget)
                )}
                {listItem('Purchase Date', property.purchaseDate)}
                {listItem('Property sourcing', property.propertySourcing)}
                {property.wholesalerPurchase && (
                  <>
                    {listItem(
                      'Sourcing',
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                      >
                        {property.wholesalerPurchase}
                      </Typography>,
                      <Box flexGrow={1}>
                        <SubdirectoryArrowRight fontSize="small" />
                      </Box>
                    )}
                  </>
                )}
                {property.propertySourcing === 'Other' && (
                  <>
                    {listItem(
                      'Explanation',
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                      >
                        {property.propertySourcingExplanation}
                      </Typography>,
                      <Box flexGrow={1}>
                        <SubdirectoryArrowRight fontSize="small" />
                      </Box>
                    )}
                  </>
                )}
                {property.wholesalerPurchase === 'Other' && (
                  <>
                    {listItem(
                      'Explanation',
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                      >
                        {property.propertySourcingExplanation}
                      </Typography>,
                      <Box flexGrow={1}>
                        <SubdirectoryArrowRight fontSize="small" />
                      </Box>
                    )}
                  </>
                )}
                {listItem('Exit Strategy', property.exitStrategy)}
                {listItem('Completed Renovations', formatCurrency(property.sunkCost))}
                {listItem(
                  'Refinance Amount',
                  formatCurrency(property.refiAmount)
                )}
                {listItem(
                  'Describe Renovation',
                  property.describeRenovation
                )}
                {listItem(
                  'Arms Length',
                  <BooleanChip value={property.armsLength} />
                )}
                {listItem('Arms Comment', property.armsLengthComment)}
                {listItem(
                  'WholeSale',
                  formatCurrency(property.wholesaleAmount)
                )}
                {listItem('Occupancy', property.occupancy)}
                {isFixAndFlip(loanSubtype) && listItem(
                  'Square Footage Added',
                  <BooleanChip value={property.squareFootageAdded} />
                )}
                {isFixFlipPro && listItem('Pre-Rehab', '')}
                {isFixFlipPro && listItem('Bedroom count', formatNumberWithCommas(property.preRehabBedroomsCount))}
                {isFixFlipPro && listItem('Bathroom count', formatNumberWithCommas(property.preRehabBathroomsCount))}
                {isFixFlipPro && listItem('Approx. sq ft above grade', formatNumberWithCommas(property.preRehabAproxSquareFootageAboveGrade))}
                {isFixFlipPro && listItem('Approx. sq ft below grade', formatNumberWithCommas(property.preRehabAproxSquareFootageBelowGrade))}
              </Dividers>
            </List>
          </Grid>
          <Grid item xs={12} md={6}>
            <List dense>
              <Dividers variant="middle" component="li">
                {listItem(
                  'Monthly Rent',
                  formatCurrency(property.monthlyRent)
                )}
                {listItem(
                  'As-is-Value',
                  formatCurrency(property.asIsValue)
                )}
                {listItem(
                  'Min Release',
                  formatCurrency(property.releaseAmount)
                )}
                {listItem('Ins Exp Date', property.insuranceExpiryDate)}
                {listItem('ARV', formatCurrency(property.afterRepairValue))}
                {listItem(
                  'Full Net Proceeds',
                  <BooleanChip value={property.fullNetProceeds} />
                )}
                {listItem('Release Comment', property.releaseComment)}
                {listItem('Zillow', formatCurrency(property.zEstimate))}
                {listItem(
                  'Zillow Low',
                  formatCurrency(property.zEstimateLow)
                )}
                {listItem(
                  'Zillow High',
                  formatCurrency(property.zEstimateHigh)
                )}
                {listItem(
                  'Median',
                  formatCurrency(property.zEstimateMedian)
                )}
                {listItem('Additional Comments', property.additionalComments)}
                {listItem('Lien Pos', property.lienPosition)}
                {listItem('Sr Amt', property.lienAmount)}
                {listItem('Flood Zone', property.floodZone)}
                {isFixFlipPro && listItem('Post-Rehab', '')}
                {isFixFlipPro && listItem('Bedroom Count', formatNumberWithCommas(property.postRehabBedroomsCount))}
                {isFixFlipPro && listItem('Bathroom count', formatNumberWithCommas(property.postRehabBathroomsCount))}
                {isFixFlipPro && listItem('Approx. sq ft above grade', formatNumberWithCommas(property.postRehabAproxSquareFootageAboveGrade))}
                {isFixFlipPro && listItem('Approx. sq ft below grade', formatNumberWithCommas(property.postRehabAproxSquareFootageBelowGrade))}

              </Dividers>
            </List>
          </Grid>
        </Grid>
      </Box> : null
  );
};

export const BorrowerPropertiesModal = ({
  loanSubtype,
  property,
  open,
  handleClose
}: {
  loanSubtype: string,
  property: any,
  open: boolean,
  handleClose: () => void
}) => {

  return (
    <StandardDialog
      open={open}
      title="Property"
      maxWidth="md"
      handleClose={handleClose}
      removeContentBox
      dialogContent={
        <BorrowerPropery loanSubtype={loanSubtype} property={property} />
      }
    />
  )
}
