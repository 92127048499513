import { Grid } from '@material-ui/core';
import {
  AuthorizedSignatory, CellPhone, CitizenshipStatus,
  CollectCreditReport,
  EmailAddressDuplicated,
  ExperienceLevel,
  FirstName,
  LastName,
  PercentOwnership,
  QualifyingFicoScore,
  PersonalGuarantor,
  transactionRolesTerm
} from '@roc/ui/formComponents';
import { FormStore, useBaseStore } from '@roc/feature-app-core';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { RentalPortfolioStore, SinglePropertyStore } from '../stores';
import BorrowerFormStore from '../stores/common/borrowerFormStore';
import { LoanSubType } from '@roc/feature-utils';
import { Borrower } from '@roc/feature-types';

interface BorrowerFormProps {
  store: FormStore | BorrowerFormStore;
  termStore: SinglePropertyStore | RentalPortfolioStore;
  currentBorrower?: Borrower;
}

function BorrowerFormTerm({ store, termStore, currentBorrower }: BorrowerFormProps) {
  const { globalStore } = useBaseStore();

  const { form } = store;
  const { selectBorrowersStore, borrowerFormStore, pricerStore } = termStore;
  const { borrowerFormDialogStore } = selectBorrowersStore;

  currentBorrower = currentBorrower ?? selectBorrowersStore.currentBorrower;

  //TODO: Do we need these for rental?
  const checkBorrowerExistingInFunded =
    currentBorrower?.portalProjectsCompleted > 0 ||
    currentBorrower?.portalProjectsActive > 0;
  const disableEdit =
    !globalStore.userFeatures?.addNewBorrowerDuringSubmission || checkBorrowerExistingInFunded;

  const validExistingEmail = email => {
    return (
      globalStore.userFeatures?.allowBorrowerDuplicateEmail ||
      !selectBorrowersStore.borrowers
        .filter(borrower => borrower.tempId != currentBorrower?.tempId)
        .some(borrower => borrower.emailAddress === email)
    );
  };

  useEffect(() => {
    borrowerFormStore.initFormValues(
      borrowerFormDialogStore.dialogState,
      pricerStore.form.fields
    );
  }, [borrowerFormStore, borrowerFormDialogStore.dialogState]);

  return (
    <Grid container justifyContent={'center'} spacing={2}>
      <Grid item xs={12}>
        <FirstName store={store} disabled={checkBorrowerExistingInFunded} />
      </Grid>
      <Grid item xs={12}>
        <LastName store={store} disabled={checkBorrowerExistingInFunded} />
      </Grid>
      <Grid item xs={12}>
        <EmailAddressDuplicated
          store={store}
          disabled={disableEdit && !!currentBorrower?.emailAddress}
          onChanged={(name, value) =>
            store.onFieldChange(
              'emailAddressDuplicated',
              validExistingEmail(value)
            )
          }
        />
      </Grid>
      <Grid item xs={12}>
        <CellPhone store={store} disabled={disableEdit} />
      </Grid>
      <Grid item xs={12}>
        <CitizenshipStatus store={store} />
      </Grid>
      <Grid item xs={12}>
        <PercentOwnership store={store} disableEdit={false} />
      </Grid>
      {form.fields.pctOwnership.value >= 20 && (
        <>
          <Grid item xs={12}>
            <ExperienceLevel
              store={store}
              label={'Rental Properties Owned in Last 24 months'}
              disabled={true}
            />
          </Grid>
          <Grid item xs={12}>
            <QualifyingFicoScore
              store={store}
              label={'FICO Score'}
              disabled={true}
            />
          </Grid>
          {termStore.loanSubtype !== LoanSubType.SINGLE_PROPERTY && termStore.loanSubtype !== LoanSubType.RENTAL_PORTFOLIO &&
            <Grid item xs={12}>
              <CollectCreditReport store={store} />
            </Grid>
          }
        </>
      )}
      <Grid item xs={12}>
        <AuthorizedSignatory store={store} />
      </Grid>
      <Grid item xs={12}>
        <PersonalGuarantor store={store} />
      </Grid>
    </Grid>
  );
}

export default observer(BorrowerFormTerm);
