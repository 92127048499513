import { Box, Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import {
  useAppConfigurations,
  useRoutes,
} from '@roc/client-portal-shared/hooks';
import { useLoanStore } from '@roc/feature-loans';
import { useLoanRoutes } from '@roc/feature-loans-routes-config';
import { Button } from '@roc/ui';
import { useHistory } from 'react-router-dom';
import { useDrawsStore } from '../../hooks';
import { observer } from 'mobx-react';

const useStyles = makeStyles(theme =>
  createStyles({
    centerText: {
      textAlign: 'center',
    },
    paper: {
      margin: 24,
    },
    spacedTypography: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    container: {
      paddingTop: 24,
    },
  })
);

export const DrawSuccess = observer(() => {
  const classes = useStyles();
  const history = useHistory();
  const { dashboardRoutesConfig } = useRoutes();
  const { loanRoutesConfig } = useLoanRoutes();
  const {
    loanStore: { loanDetails },
  } = useLoanStore();
  const { drawStore } = useDrawsStore();
  const dashboardURL = dashboardRoutesConfig.dashboard.url;
  const drawDetailsURL = loanRoutesConfig
    .loans(loanDetails.loanId)
    .children.drawDetails(drawStore?.currentDraw?.drawId.toString()).url;
  return (
    <>
      <Grid
        container
        justifyContent="space-around"
        className={classes.container}
      >
        <Grid item xs={12}>
          <Box className={classes.centerText}>
            <Typography variant="h5">
              Your Draw Request Was Successfully Submitted!
            </Typography>
          </Box>
          <Box className={classes.centerText}>
            <Typography className={classes.spacedTypography} variant="body1">
              Thank you for submitting your Draw Request.
            </Typography>
          </Box>
          <Box className={classes.centerText}>
            <Typography className={classes.spacedTypography} variant="body1">
              {`Your Draw Number is ${drawStore?.currentDraw?.drawNumber}`}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid
        container
        item
        xs={12}
        direction="row"
        justifyContent="center"
        spacing={2}
      >
        <Grid item xs={10} md={3}>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            onClick={() => history.push(drawDetailsURL)}
            testId="uploadDocumentsButton"
          >
            UPLOAD DOCUMENTS
          </Button>
        </Grid>
        <Grid item xs={10} md={3}>
          <Button
            variant="outlined"
            color="secondary"
            fullWidth
            onClick={() => history.push(dashboardURL)}
            testId="returnToHomeButton"
          >
            RETURN TO HOME
          </Button>
        </Grid>
      </Grid>
    </>
  );
});
