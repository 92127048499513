import { LoanSubtypeOption } from "../../dscrCalculator/utils/dscrCalculatorConstants";

export enum LoanPurposeOption {
  CASHOUT = 'Cashout',
  PURCHASE = 'Purchase',
  RATE_TERM_REFI = 'Rate-term refi',
}

export const loanPurposeOptions = [
  {
    label: 'Cashout',
    value: LoanPurposeOption.CASHOUT,
  },
  {
    label: 'Purchase',
    value: LoanPurposeOption.PURCHASE,
  },
  {
    label: 'Rate-term refi',
    value: LoanPurposeOption.RATE_TERM_REFI,
  },
];

export const amortizationOptions = [
  { label: 'Fully Amortizing', value: 'Fully Amortizing' },
  { label: 'Partial Interest Only ', value: 'Partial Interest Only' },
];

export const loanSubtypeOptions = [
  {
    label: 'Single Property Rental',
    value: LoanSubtypeOption.SINGLE_PROPERTY,
  },
  {
    label: '5+ Units',
    value: LoanSubtypeOption.FIVE_PLUS,
  },
  {
    label: 'Rental Portfolios',
    value: LoanSubtypeOption.PORTFOLIO,
  },
];
