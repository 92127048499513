import { createBrowserHistory } from 'history';
import { action, computed, flow, makeObservable, observable } from 'mobx';
import { NotificationStore } from '../modules/notification/stores/notificationStore';
import {
  GlobalService,
  ApiResponse,
  BreadCrumbsStore,
} from '@roc/feature-app-core';
import { DashboardView, PortalConfiguration } from '@roc/feature-types';
import { addSeconds } from 'date-fns';
import { PortalContextPath } from '@roc/feature-utils';

export interface ProcessEntitlements {
  isCommunicationAllowed: boolean;
  isSubmissionAllowed: boolean;
  isSubmissionNotAllowedOnly: string[];
  isSubmissionAllowedOnly: string[];
  areBrokerDetailsRequired: boolean;
  isInsuranceReviewerAdvocate: boolean;
  canSubmitInsurance: boolean;
  isScopeOfWorkFormEnabled: boolean;
  isUploadAllowed: boolean;
  isUploadAllowedForSection: string[];
  isUploadAllowedOnly: string[];
  isUploadNotAllowedOnly: string[];
  showLoanActions: boolean;
  approveAdditionalClosingReview: boolean;
  allowInsuranceApproval: boolean;
}

export interface SigmaDashboard {
  sigmaEmbedDashboardId: string;
  embedIdentifier: string;
  menuId: string;
  menuText: string;
  routePath: string;
  rolesEntitled: string;
  reportAccess: string[];
}

export interface UserFeatures {
  isEstimatedClosingCostEnabled: boolean;
  allowUserToJoinChat: boolean;
  tamariskAppraisal: boolean;
  creditBackgroundChooseBorrower: boolean;
  addNewBorrower: boolean;
  addNewBorrowerDuringSubmission: boolean;
  adminPortalConfiguration: boolean;
  allowBorrowerDuplicateEmail: boolean;
  displayLeads: boolean;
  allowCommunication: boolean;
  allowManageBorrowerAccounts: boolean;
  underwritingEntitlements: ProcessEntitlements;
  closingEntitlements: ProcessEntitlements;
  drawsEntitlements: ProcessEntitlements;
  servicingEntitlements: ProcessEntitlements;
  tradeEntitlements: ProcessEntitlements;
  tamariskGfd: boolean;
  showRatesWarning: boolean;
  showHausRatesWarning: boolean;
  showTierWarningMessage: boolean;
  showCurrentNotification: boolean;
  showTools: boolean;
  showProspectProperties: boolean;
  showBackUpLoans: boolean;
  showSigmaDashboards: boolean;
  availableSigmaDashboards: SigmaDashboard[];
  showDscrCalculator: boolean;
  showFreeAppraisalPromotion: boolean;
  showLiquidityCalculator: boolean;
  showMultifamilyLiquidityCalculator: boolean;
  showStabilizedBridgeAnalyzer: boolean;
  showInternalPricer: boolean;
  showLms: boolean;
  isLenderOriginator: boolean;
  isProcessor: boolean;
  isBorrower: boolean;
  isInsuranceReviewer: boolean;
  isInsuranceReviewerAlbertRisk: boolean;
  resendInsuranceQuote: boolean;
  isClosingAttorney: boolean;
  showForeclosureRequest: boolean;
  isBroker: boolean;
  isPricingBroker: boolean;
  isClosingReviewer: boolean;
  isInsuranceReviewerAdvocate: boolean;
  addFacoOriginators: boolean;
  plaidRegistration: boolean;
  fastTrack: boolean;
  oldMessagesAccess: boolean;
  baseballCard: boolean;
  showMultiCompanyDropdown: boolean;
  allowProcessorSelection: boolean;
  allowLenderUnderwritingFee: boolean;
  showMailingLists: boolean;
  isServicer: boolean;
  isTitleAgent: boolean;
  bridgeProfitCalculator: boolean;
  allowParticipation: boolean;
  allowLoanSearch: boolean;
  showApps: boolean;
  uwMatrixEnabled: boolean;
  showLoanFundingPreference: boolean;
  showQuoteGenerator: boolean;
  pricerFeesEnabled: boolean;
  backOfficeUser: boolean;
  closingConditionBannerAllowed: boolean;
  hideCreditBackgroundChecks: boolean;
  internalClosingTeamOptionEnabled: boolean;
  internalInsuranceReviewer: boolean;
  internalInsuranceReviewerAdmin: boolean;
  allowACHForm: boolean;
  isDecisionCommittee: boolean;
  showLoanSummary: boolean;
  allowRenderLendersWithNoRm: boolean;
  uploadDuringSubmissionEnabled: boolean;
  backOfficeLead: boolean;
  showLeads: boolean;
  connectedWithPhoneBurner: boolean;
  showDecisionComments: boolean;
  showOwnerColumns: boolean;
  allowContactEdit: boolean;
  showTpoBorrowerActionButton: boolean;
  isBackOfficeProcessor: boolean;
  isBackOfficeUser: boolean;
  legacyBridgeCalcEnabled: boolean;
  bridgePricerToolEnabled: boolean;
  enableFixFlipPro: boolean;
  enableOneToolPricer: boolean;
}

export interface PortalBannerMessages {
  title: string;
  content: string;
}

export interface LenderInfo {
  lenderId: number;
  lenderPointsShare: number;
  tamariskDefaultOption: boolean;
  tamariskAppraisal: boolean;
  primaryRelationshipManagerEmail: string;
  secondaryRelationshipManagerEmail: string;
  primaryRelationshipManagerName: string;
  secondaryRelationshipManagerName: string;
  rocBasePoints: number;
  hausLender: boolean;
  enableBorrowerPortal: boolean;
  status: string;
  limitedFunctionalitiesList: string;
  restrictCustomCreditReport: boolean;
  allowBorrowerPortalManagement: boolean;
  brokerPortalEnabled: boolean;
  companyName: string;
  isInternal: boolean;
  rocOwnedLender: boolean;
  portalId: number;
  bridgeRocBasePoints: number;
  lenderTradeName: string;
  stateLicenseList: any[];
  showTPOLicenseValidation: boolean;
  creditProvidedByTpo: boolean;
  businessUnit: string;
  showFixFlipProSubtype: boolean;
}

export class GlobalStore {
  globalService: GlobalService;
  constructor(routeBasePath: string, routeBaseUrl: string) {
    this.notificationStore = new NotificationStore();
    this.breadCrumbsStore = new BreadCrumbsStore();
    this.routeBasePath = routeBasePath;
    this.routeBaseUrl = routeBaseUrl;
    this.globalService = new GlobalService();
    makeObservable(this, {
      portalConfiguration: observable,
      history: observable,
      loadingRequestsCount: observable,
      selectedCompanyId: observable,
      userCompanies: observable,
      jwtTokenInactivePeriod: observable,
      fcmToken: observable,
      sessionExpiresAt: observable,
      refreshTokenExpired: observable,
      queryParams: observable,
      hashParams: observable,
      dashboardView: observable,
      routeBasePath: observable,
      routeBaseUrl: observable,
      userFeatures: observable,
      impersonatedUser: observable,
      showNavigation: observable,
      slimNavigation: observable,
      lastHistoryAction: observable,
      incrementLoadingRequestsCount: action,
      decrementLoadingRequestsCount: action,
      isLoading: computed,
      toggleView: action,
      setSelectedCompanyId: action,
      setUserCompanies: action,
      setJwtTokenInactivePeriod: action,
      resetSessionActivity: action,
      setRefreshTokenExpired: action,
      redirectPath: computed,
      isPreviewMode: computed,
      setUserFeatures: action,
      setImpersonatedUser: action,
      setBannerMessages: action,
      setLenderInfo: action,
      isListView: computed,
      setPortalConfiguration: action,
      setQueryParams: action,
      setShowNavigation: action,
      setSlimNavigation: action,
      isBorrowerPortal: computed,
      isLenderPortal: computed,
      isCrmPortal: computed,
      isBrokerPortal: computed,
      isLoanChatApp: computed,
      isInternalPortal: computed,
      isCertificateValid: observable,
      validateHostCertificate: flow,
      isBackOfficeView: computed,
      isBorrowerUser: computed,
    });
    const searchParams = new URLSearchParams(location.search);
    searchParams.forEach((v, k) => {
      this.queryParams[k] = v;
    });
    this.hashParams = location.hash;
    this.history.listen((location, action) => {
      this.lastHistoryAction = action;
    });
  }

  notificationStore: NotificationStore;
  breadCrumbsStore: BreadCrumbsStore;
  portalConfiguration: PortalConfiguration;
  selectedCompanyId = null;
  userCompanies = [];
  jwtTokenInactivePeriod: number;
  fcmToken: string;
  sessionExpiresAt: Date;
  refreshTokenExpired = false;
  queryParams: Record<string, string> = {};
  hashParams: string = '';
  history = createBrowserHistory();
  loadingRequestsCount = 0;
  dashboardView = DashboardView.LIST;
  routeBasePath = '';
  routeBaseUrl = '';
  userFeatures?: UserFeatures;
  impersonatedUser: boolean;
  portalBannerMessages?: Array<PortalBannerMessages>;
  alertMessages?: Array<PortalBannerMessages>;
  lenderInfo?: LenderInfo;
  showNavigation = true;
  slimNavigation = false;
  lastHistoryAction: string;
  isCertificateValid = true;

  setShowNavigation(flag: boolean) {
    this.showNavigation = flag;
  }

  setSlimNavigation(flag: boolean) {
    this.slimNavigation = flag;
  }

  get isLoading() {
    return this.loadingRequestsCount > 0;
  }

  incrementLoadingRequestsCount() {
    this.loadingRequestsCount++;
  }

  decrementLoadingRequestsCount() {
    if (this.loadingRequestsCount > 0) this.loadingRequestsCount--;
  }

  setSelectedCompanyId(selectedCompanyId: number) {
    this.selectedCompanyId = selectedCompanyId;
  }

  setUserCompanies(userCompanies: string[]) {
    this.userCompanies = userCompanies;
  }

  setJwtTokenInactivePeriod(jwtTokenInactivePeriod: number) {
    this.jwtTokenInactivePeriod = jwtTokenInactivePeriod;
    this.resetSessionActivity();
  }

  setFcmToken(fcmToken: string) {
    this.fcmToken = fcmToken;
  }

  resetSessionActivity() {
    if (this.jwtTokenInactivePeriod) {
      this.sessionExpiresAt = addSeconds(
        new Date(),
        this.jwtTokenInactivePeriod
      );
    }
  }

  setRefreshTokenExpired(refreshTokenExpired: boolean) {
    this.refreshTokenExpired = refreshTokenExpired;
  }

  setQueryParams(queryParams) {
    this.queryParams = queryParams;
  }

  get redirectPath() {
    const { redirectPath } = this.queryParams;
    return redirectPath;
  }

  get isPreviewMode() {
    return this.queryParams.previewMode == `true`;
  }

  setPortalConfiguration(portalConfiguration: PortalConfiguration) {
    this.portalConfiguration = portalConfiguration;
  }

  toggleView() {
    this.dashboardView =
      this.dashboardView === DashboardView.LIST
        ? DashboardView.CARDS
        : DashboardView.LIST;
  }

  get isListView() {
    return this.dashboardView == DashboardView.LIST;
  }

  get isBorrowerPortal() {
    return this.routeBaseUrl.includes(PortalContextPath.borrowerPortal);
  }

  get isLenderPortal() {
    return this.routeBaseUrl.includes(PortalContextPath.lenderPortal);
  }

  get isCrmPortal() {
    return this.routeBaseUrl.includes(PortalContextPath.crmPortal);
  }

  get isLoanChatApp() {
    return this.routeBaseUrl.includes(PortalContextPath.chatUi);
  }

  get isInternalPortal() {
    return this.routeBaseUrl.includes(PortalContextPath.rocInternalPortal);
  }

  get isBrokerPortal() {
    return this.routeBaseUrl.includes(PortalContextPath.brokerPortal);
  }

  setUserFeatures(data: UserFeatures) {
    this.userFeatures = data;
  }

  setImpersonatedUser(data: boolean) {
    this.impersonatedUser = data;
  }

  setBannerMessages(
    bannerMessages: Array<PortalBannerMessages>,
    alertMessages: Array<PortalBannerMessages>
  ) {
    this.portalBannerMessages = bannerMessages;
    this.alertMessages = alertMessages;
  }

  setLenderInfo(data: LenderInfo) {
    this.lenderInfo = data;
  }

  get allowUserToJoinChat() {
    return this.userFeatures?.allowUserToJoinChat;
  }

  *validateHostCertificate() {
    try {
      // let host = window.location.origin;
      // const apiResponse: ApiResponse = yield this.globalService.validateHostCertificates(
      //   host
      // );
      this.isCertificateValid = true;
    } catch (e) {
      console.error('Error validating certificate.', e);
      this.isCertificateValid = false;
    }
  }

  get isBackOfficeView() {
    const currentPath = window.location.pathname;
    return currentPath.includes('/backoffice/');
  }

  get isBorrowerUser() {
    return this.userFeatures?.isBorrower;
  }
}
