import {
  FormStore,
  GENERIC_VALIDATION_NOTIFICATION,
  GlobalStore,
} from '@roc/feature-app-core';
import { PropertyStore } from '@roc/feature-loans';
import { getUniqueId, PropertyOwnership } from '@roc/feature-utils';
import { action, computed, makeObservable, override } from 'mobx';

export class PropertyFormStore extends PropertyStore {
  private _globalStore: GlobalStore;
  constructor(globalStore) {
    super(globalStore, () => null);
    this._globalStore = globalStore;

    makeObservable(this, {
      isPurchase: computed,
      runAllFormValidations: action,
      getProperty: override,
    });
  }

  runAllFormValidations() {
    const formStores: FormStore[] = this.isPurchase
      ? [
          this.propertyInformationStore,
          this.purchaseInformationStore,
          this.renovationDetailsStore,
          this.otherDetailsStore,
        ]
      : [
          this.propertyInformationStore,
          this.refinance_purchaseInformationStore,
          this.refinance_renovationDetailsStore,
          this.refinance_otherDetailsStore,
        ];
    formStores.forEach(formStore => formStore.runFormValidation());
    return formStores.every(formStore => formStore.form.meta.isValid);
  }

  runAllFormValidationsWithMessage() {
    const isValid = this.runAllFormValidations();
    if (!isValid) {
      this._globalStore.notificationStore.showWarningNotification({
        message: GENERIC_VALIDATION_NOTIFICATION,
      });
    }
    return isValid;
  }

  getProperty() {
    if (this.isPurchase) {
      return {
        propertyId: this.propertyId ?? getUniqueId(),
        appraisalId: this.appraisalId,
        ...this.propertyInformationStore.getFormValues(),
        ...this.purchaseInformationStore.getFormValues(),
        ...this.renovationDetailsStore.getFormValues(),
        ...this.otherDetailsStore.getFormValues(),
      };
    } else {
      return {
        propertyId: this.propertyId ?? getUniqueId(),
        appraisalId: this.appraisalId,
        ...this.propertyInformationStore.getFormValues(),
        ...this.refinance_purchaseInformationStore.getFormValues(),
        ...this.refinance_renovationDetailsStore.getFormValues(),
        ...this.refinance_otherDetailsStore.getFormValues(),
      };
    }
  }

  get isPurchase() {
    const { propertyOwnership } = this.propertyInformationStore.form.fields;
    return propertyOwnership.value == PropertyOwnership.PURCHASED;
  }
}
