import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Box, CardActions, Grid, Toolbar } from '@material-ui/core';
import { Button } from '@roc/ui';
import clsx from 'clsx';
import { ValuationReviewReportStore } from '../stores/valuationReviewReportStore';
import { observer } from 'mobx-react';
import { createTextField, createCurrencyField } from '@roc/ui/componentBuilder';
import { PropertyAddressDetails } from '@roc/ui/formComponents';

const useStyles = makeStyles({
  root: {
    height: '100%',
    '& .gauge-container': {
      '& text': {
        fontWeight: 'bold'
      }
    }
  },
  paperShadow: {
    boxShadow: '0px 0px 16px 0px #757373',
  }
});

export type Props = {
  store: ValuationReviewReportStore,
  layout: number,
  propertyId: number,
  loanId: number,
  disabled?: boolean
}

export const ValuationReviewCard = observer((props: Props) => {
  const { store, layout, propertyId, loanId, disabled = false } = props;
  const classes = useStyles();

  return (
    <Card className={clsx(classes.root)} elevation={0} variant='outlined'>
      <Toolbar style={{
        minHeight: 52,
        padding: '8px 16px',
      }}>
        <Typography variant="h6" component="div">
          Valuation Review
        </Typography>
      </Toolbar>
      <CardContent style={{
        padding: 16
      }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {createCurrencyField({
              store,
              testId: 'internalValue',
              fieldName: 'internalValue',
              label: 'Internal Value',
              groupSeparator: ',',
              disabled
            })}
          </Grid>
          <Grid item xs={12} md={layout == 1 ? 4 : 12} lg={layout == 1 ? 6 : 12}>
            <Box>
              {createTextField({
                store,
                testId: 'comments',
                fieldName: 'comments',
                label: 'Comments',
                multiline: true,
                minRows: 8,
                disabled
              })}
            </Box>
          </Grid>
          <Grid item xs={12} md={layout == 1 ? 4 : 12} lg={layout == 1 ? 6 : 12}>
            <Typography variant='h6'>
              Best Comparable Address
            </Typography>
            <Box>
              <PropertyAddressDetails store={store} showMap={false} required={false} disabled={disabled} />
            </Box>
          </Grid>
        </Grid>
      </CardContent>
      {!disabled && <CardActions style={{
        display: 'flex',
        justifyContent: 'flex-end',
        margin: '0 8px 8px 0',
      }}>
        <Button testId='cancel' size="small" color="default" variant="outlined" onClick={() => { store.reset() }}>
          Cancel
        </Button>
        <Button testId='save' size="small" color="primary" variant="contained" onClick={() => { store.saveValuationDetails(propertyId, loanId) }}>
          Save
        </Button>
      </CardActions>}
    </Card>
  );
})
