import { Grid } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/styles';
import { useAppConfigurations } from '../../modules/portalConfiguration';

const useStyles = makeStyles(theme =>
  createStyles({
    grid: {
      alignItems: 'center',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(4),
    },
    img: {
      marginLeft: 'auto',
      marginRight: 'auto',
      display: 'block',
      padding: 10,
      maxWidth: 240,
      height: 'auto',
      textAlign: 'center',
    },
  })
);

export const LoginLogoImg = ({ containerClassName, imgStyle, appLogo, appLogoBackground }: {
  containerClassName?: string,
  imgStyle?: CSSProperties,
  appLogo?: string,
  appLogoBackground?: string,
}) => {
  const classes = useStyles();
  const appConfigurations = useAppConfigurations();
  const appLogoFromConfiguration = appConfigurations.appLogo;
  const appLogoBackgroundFromConfiguration = appConfigurations.appLogoBackground;

  return (
    <Grid
      container
      justifyContent="center"
      className={containerClassName ?? classes.grid}
    >
      <Grid item xs={12}>
        {(appLogo || appLogoFromConfiguration) && (
          <img
            className={classes.img}
            alt="App Logo"
            src={appLogo ? appLogo : appLogoFromConfiguration}
            style={{
              background: appLogoBackground ? appLogoBackground :
                (appLogoBackgroundFromConfiguration ? appLogoBackgroundFromConfiguration : 'inherit'),
              ...imgStyle
            }}
          />
        )}
      </Grid>
    </Grid>
  );
};
