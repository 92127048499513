import { Layout } from '@roc/feature-app-core';
import { Toolbar, FilterButton, Button, EditableDialog } from '@roc/ui';
import { observer } from 'mobx-react';
import { ManageBrokersGrid } from './components/manageBrokersGrid';
import { Box } from '@material-ui/core';
import { useBrokersStore } from './hooks/useBrokersStore';
import { BrokerForm } from './brokerForm/brokerForm';
import { Broker } from './types/brokerTypes';
import { useEffect } from 'react';
import { BrokerDialog } from './components/brokerDialog';
import BrokerDialogV2 from './components/brokerDialogV2';

export const ManageBrokers = observer(() => {
  const { brokersStore } = useBrokersStore();
  const {
    selectBrokersStore,
    brokerContactsGridStore,
    manageBrokersStore,
    brokersGridStore,
    brokerFeesStore
  } = brokersStore;
  const { editDialogState } = selectBrokersStore;
  const brokers: Broker[] = brokersGridStore.gridData.data.rows;

  useEffect(() => {
    brokersGridStore.fetchGridData();
  }, []);

  useEffect(() => {
    manageBrokersStore.getAttorneysForBrokers();
  }, []);

  const handleToggleFilter = () => {
    brokerContactsGridStore.toggleFilters();
  };

  const handleClearFilter = () => {
    brokerContactsGridStore.resetFilters();
  };

  const handleAddNewBroker = () => selectBrokersStore.openAddNewBrokerDialog();

  const close = () => {
    selectBrokersStore.closeEditBrokerDialog();
  };

  return (
    <>
      <Layout title="Manage Brokers">
        <ManageBrokersGrid
          showManageBrokersMenu
          showPortalStatus
          toolbar={
            <Toolbar>
              <Box mr={2}>
                <FilterButton
                  onToggleFilters={handleToggleFilter}
                  onClearFilters={handleClearFilter}
                />
              </Box>
              <Box>
                <Button
                  variant="contained"
                  size="small"
                  onClick={handleAddNewBroker}
                  color="primary"
                  testId="add_new_broker"
                >
                  Add Broker Contact
                </Button>
              </Box>
            </Toolbar>
          }
        />

        <BrokerDialogV2
          open={editDialogState !== null}
          handleClose={close}/>
      </Layout>
    </>
  );
});
