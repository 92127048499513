const mapper = {
  'Swimming Pools, Spas': {
    helperText: 'Please provide the cost and details of the following items',
    sections: [
      {
        title: 'Swimming Pool',
        fields: [
          {
            label: 'New',
            name: 'newSwimmingPool',
          },
          {
            label: 'Repair',
            name: 'repairSwimmingPool',
          },
        ],
      },
      {
        title: 'Spa',
        fields: [
          {
            label: 'New',
            name: 'newSpa',
          },
          {
            label: 'Repair',
            name: 'repairSpa',
          },
        ],
      },
      {
        title: 'Other',
        tooltip: 'Any other relevant items related to this category',
        fields: [
          {
            label: 'Amount',
            name: 'otherAmount',
          },
        ],
      },
    ],
  },
};

export default mapper;
