import { Route } from 'react-router';
import { RentalPricer } from '../rentalPricer';
import { RentalPricerHome } from '../rentalPricerHome';
import { PricerDashboard } from '../pricerDashboard';
export const getRentalPricerRoutesConfig = (
  basePath: string,
  baseUrl: string
) => {
  const rentalPricerPath = `${basePath}/rental-pricer`;
  const rentalPricerUrl = `${baseUrl}/rental-pricer`;
  return {
    pricerHome: {
      path: `${rentalPricerPath}/loan-pricers`,
      url: `${rentalPricerUrl}/loan-pricers`,
      documentTitle: 'Rental Quote',
    },
    singleProperty: {
      path: `${rentalPricerPath}/single-property`,
      url: `${rentalPricerUrl}/single-property`,
      documentTitle: 'Rental Quote - Single Property',
    },
    pricerDashboard: {
      path: `${rentalPricerPath}`,
      url: `${rentalPricerUrl}`,
      documentTitle: 'Rental Quote',
    },
    singlePropertyEdit:(RentalPricerId?) => ({
      path: `${rentalPricerPath}/single-property/:rentalPricerId`,
      url: `${rentalPricerUrl}/single-property/${RentalPricerId}`,
      documentTitle: 'Rental Quote - Single Property',
    }),
    portfolioEdit:(RentalPricerId?) => ({
      path: `${rentalPricerPath}/portfolio/:rentalPricerId`,
      url: `${rentalPricerUrl}/portfolio/${RentalPricerId}`,
      documentTitle: 'Rental Quote - Porfolio',
    }),
    portfolio: {
      path: `${rentalPricerPath}/portfolio`,
      url: `${rentalPricerUrl}/portfolio`,
      documentTitle: 'Rental Quote - Portfolio',
    },
  };
};

export const getRentalPricerRoutes = (basePath: string, baseUrl: string) => {
  const config = getRentalPricerRoutesConfig(basePath, baseUrl);
  return {
    pricerHome: (
      <Route exact path={config.pricerHome.path}>
        <RentalPricerHome />
      </Route>
    ),
    singleProperty: (
      <Route exact path={config.singleProperty.path}>
        <RentalPricer isSingleProperty={true}/>
      </Route>
    ),
    pricerDashboard: (
      <Route exact path={config.pricerDashboard.path}>
        <PricerDashboard />
      </Route>
    ),
    singlePropertyEdit: (
      <Route exact path={config.singlePropertyEdit().path}>
        <RentalPricer isSingleProperty={true}/>
      </Route>
    ),
    portfolioEdit: (
      <Route exact path={config.portfolioEdit().path}>
        <RentalPricer isSingleProperty={false}/>
      </Route>
    ),
    portfolio: (
      <Route exact path={config.portfolio.path}>
        <RentalPricer isSingleProperty={false} />
      </Route>
    ),
  };
};
