import { Paper, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ItemDisplay } from '../../../components/itemDisplay';
import { formatCurrency } from '@roc/ui/utils';
import { PointsAndFeesDetailsAccordion } from './pointsAndFeesDetailsAccordion';
import { QuoteTypeStore } from '../../../stores/quoteTypeStore';
import { observer } from 'mobx-react';

const useStyles = makeStyles(theme => ({
  paper: {
    border: `1px solid lightgray`,
    borderRadius: '16px !important',
    padding: theme.spacing(4),
  },
}));

interface EconomicsSummaryCardProps {
  store: QuoteTypeStore;
}

export const EconomicsSummaryCard = observer(
  ({ store }: EconomicsSummaryCardProps) => {
    const classes = useStyles();
    const outputs = store.loanEconomicsStore.outputs;

    return (
      <Paper className={classes.paper} elevation={0}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            style={{ marginLeft: '1rem', marginRight: '24px' }}
          >
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <ItemDisplay
                  label="Lender Fees"
                  value={formatCurrency(outputs?.totalLenderFees)}
                />
              </Grid>
              <Grid item>
                <ItemDisplay
                  label="Estimated Third Party Fees"
                  value={formatCurrency(outputs?.totalThirdPartyFees)}
                  tooltip="These are estimates based on historical data of similar loans."
                />
              </Grid>
              <Grid item>
                <ItemDisplay
                  label="Estimated Borrower Cash-to-Close"
                  value={formatCurrency(outputs?.estimatedBorrowerCashToClose)}
                  tooltip="This is an estimate of the cash the borrower will need to bring to the closing table."
                />
              </Grid>
              <Grid item>
                <ItemDisplay
                  label="Interest Reserve"
                  value={formatCurrency(outputs?.interestReserve)}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <PointsAndFeesDetailsAccordion store={store} />
          </Grid>
        </Grid>
      </Paper>
    );
  }
);
