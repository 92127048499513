import {Box} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {Theme} from '@material-ui/core/styles';
import {observer} from 'mobx-react';
import {MyChatContainer,SelectConversation} from './myChatContainer';
import {useUserStore} from '@roc/feature-app-core';
import {FilterType} from './types/communicationTypes';
import {useStore} from '@roc/client-portal-shared/hooks';
import {useProposalsStore} from '@roc/feature-proposals';

const useStyles=makeStyles((theme: Theme) => ({
  chatContainer: {
    flex: 1,
  },
}));

interface DealRoomTopicConversationProps {
  proposalTopicId: any;
  conversationSid: string;
}

export const DealRoomTopicConversation=observer(
  ({
    proposalTopicId,
    conversationSid,
  }: DealRoomTopicConversationProps) => {
    const classes=useStyles();
    const {userStore}=useUserStore();
    const {proposalTopicStore}=useProposalsStore();
    const {dealRoomTopicCommunicationStore}=proposalTopicStore;
    const params = new URLSearchParams(location.search);
    const proposalParamId = params.get('proposalTopicId');
    if(proposalParamId) {
      proposalTopicId = proposalParamId;
    }

    return (
      <>

        <Box display="flex" height={'80vh'}>
          <MyChatContainer
            id={Number(proposalTopicId)}
            communicationStore={dealRoomTopicCommunicationStore}
            allowNewConversations={false}
            allowJoinConversation={true}
            showEmailAddress={false}
            showSnippet={false}
            className={classes.chatContainer}
            allowRemoveParticipants={true}
            allowManageParticipants={true}
            allowAddInternalParticipants={true}
            filterType={FilterType.DEAL_ROOM_TOPIC}
            onConversationSelect={conversationId => {
              dealRoomTopicCommunicationStore?.setproposalsConversationSid(conversationId)
            }
            }
            hideSidebar={dealRoomTopicCommunicationStore?.responseValues?.length==1}
          />
          <SelectConversation communicationStore={dealRoomTopicCommunicationStore} />
        </Box>

      </>
    );
  }
);
