import React, { useState } from 'react';
import {
  Grid,
  Box,
  Typography,
  ListItemIcon,
  ListItemText,
  Menu, Button,
  IconButton
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import {
  DataGrid as DrawGrid,
  AgGridColumnProps,
  FieldLabel,
  LinkColumn,
  MenuItem,
} from '@roc/ui';
import { formatCurrency } from '@roc/ui/utils';
import { useEffect } from 'react';
import { observer } from 'mobx-react';
import { formatDate } from '@roc/feature-utils';
import ExtensionKeyDates from './extensionKeyDates';
import ExtensionsWarnings from './extensionsWarnings';
import { Cancel, MoreVert } from '@material-ui/icons';
import { ConfirmDialog, Paper, StandardDialog } from '@roc/ui';
import { useLoanStore } from '@roc/feature-loans-shared';
import { useExtensionRequestStore } from '../../../../../feature-loans/src/loans/loanDetails/modules/loanRequests/hooks/useExtensionRequestStore';
import ExtensionRequestModal from './loanRequests/extensionRequestModal';
import { LoanStore } from '../../stores';
import { useLoanRoutes } from '@roc/feature-loans-routes-config';
import { useBaseStore } from '@roc/feature-app-core';


const noOpComparator = () => 0;

const columns: AgGridColumnProps[] = [
  {
    field: 'extensionNumber',
    headerName: 'Extension #',
    minWidth: 100,
    cellRenderer: 'extensionNumberCellRenderer',
    comparator: noOpComparator,
    sortable: false,
  },
  {
    field: 'requestDate',
    headerName: 'Requested Date',
    minWidth: 100,
    comparator: noOpComparator,
    sortable: false,
  },
  {
    field: 'extensionDuration',
    headerName: 'Extension Duration',
    minWidth: 100,
    cellRenderer: 'extensionDurationCellRenderer',
    comparator: noOpComparator,
    sortable: false,
  },
  {
    field: 'status',
    headerName: 'Status',
    minWidth: 100,
    // cellRenderer: 'amountRenderer',
    comparator: noOpComparator,
    sortable: false,
  },

];

const useStyles = makeStyles(theme => ({
  paper: {
    padding: 24,
  },
  gridContainer: {
    padding: 8,
  },

}));


export const ExtensionsDashboardShared = observer(
  ({ loanId }: { loanId: string }) => {
    const { loanStore } = useLoanStore();
    const { loanRoutesConfig } = useLoanRoutes();
    const {
      extensionRequestStore,
      extensionRequestStore: { extensionList, currentExtension, disabledCreateExtesion },
    } = useExtensionRequestStore();
    const [openRequestExtensionModal, setopenRequestExtensionModal] = useState(
      false
    );
    const { globalStore } = useBaseStore();
    const closeModal = () => {
      setopenRequestExtensionModal(false);
    }
    const [anchorEl, setAnchorEl] = useState<HTMLElement>(null);
    const classes = useStyles();
    const menuOpen = Boolean(anchorEl);
    const [extensionId, setExtensionId] = useState(null);
    const [isConfirmOpen, setIsConfirmOpen] = useState(false);
    useEffect(() => {
      extensionRequestStore.setSaved(false);
      extensionRequestStore.fetchExtensionsByLoanId(loanId);
    }, []);
    const frameworkComponents = {
      dateCellRenderer: ({ value }) => {
        return value ? formatDate(value) : '';
      },
      extensionNumberCellRenderer: params => {
        const extensionId = params.node.data?.extensionId;
        const { onClick } = params;
        const value = `Extension ${params.value}`;
        return LinkColumn({
          value,
          onClick,
          url: loanRoutesConfig
            .loans(loanId)
            .children.extensionDetails(params.node.data?.loanExtensionId).url,
        });
      },
      amountRenderer: ({ value }) => {
        return value ? formatCurrency(value) : '';
      },
      actionsCellRenderer: params => {

        return (
          <IconButton onClick={e => openMenu(e, params.node.data.extensionId)}>
            <MoreVert color="primary" />
          </IconButton>
        );
      },
      extensionDurationCellRenderer: ({ value }) => {
        return `${value} months`;
      },
    };
    const openMenu = (event: React.MouseEvent<HTMLElement>, record) => {
      setExtensionId(record);
      setAnchorEl(event.currentTarget);
    };

    return (
      <>
        <Grid item container direction="row" spacing={3} xs={12} md={12} style={{
          margin: 0
        }}>
          <ExtensionKeyDates />
          {extensionRequestStore.checkWarningsForDashboard(loanStore.loanDetails.calculatedLoanData.nextDueDate, loanStore.loanDetails.extendedMaturityDate) === true &&
            <Grid item xs={12} md={6}>
              <ExtensionsWarnings />
            </Grid>
          }
        </Grid>
        <Grid item xs={12} md={12} className={classes.gridContainer}>
          <Paper className={classes.paper}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <Typography variant="h5">EXTENSIONS</Typography>
                  </Grid>
                  {!globalStore.userFeatures.isInsuranceReviewer &&
                    <Grid item>
                      <Box display="flex" justifyContent="flex-end">
                        <Button
                          variant="contained"
                          size="small"
                          color="primary"
                          disabled={disabledCreateExtesion || globalStore.userFeatures.isClosingAttorney}
                          onClick={() =>
                            setopenRequestExtensionModal(true)
                          }
                        >
                          Request Extensions
                        </Button>
                      </Box>
                    </Grid>
                  }
                </Grid>
              </Grid>
              <StandardDialog
                open={openRequestExtensionModal}
                title=""
                maxWidth="md"
                handleClose={() => setopenRequestExtensionModal(false)}
                dialogContent={
                  <ExtensionRequestModal closeModal={() => closeModal()} />
                }
              />
              <Grid item xs={12} md={12} style={{ marginTop: '30px' }}>

                <DrawGrid
                  columns={columns}
                  rows={extensionList}
                  frameworkComponents={frameworkComponents}
                  domLayout="autoHeight"
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </>
    );
  }
);
