import {

  Divider,
  Grid,

  Typography,
  Paper
} from '@material-ui/core';
import { lighten } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {
  RentalPortfolioStore,
  SinglePropertyStore,
} from './../../../loanSubmission';
import { Tooltip } from '@roc/ui';
import { observer } from 'mobx-react';
import React from 'react';
import { PricerPaper } from '../../components/rentalPricerComponents';
import {
  FROM_0_TO_5,
  FROM_6_TO_10,
  FROM_11_TO_15,
  FROM_16_TO_20,
  FROM_21_TO_25,
  FROM_26_TO_30,
  FROM_31_TO_35,
  FROM_36_TO_40,
  FROM_41_TO_45,
  FROM_46_TO_50,
  FROM_50_TO_55,
  FROM_55_TO_60,
  FROM_61_TO_65,
  FROM_66_TO_70,
  FROM_71_TO_75,
  FROM_76_TO_80,
  ltvMapper,
  US_TREASURY_5_YR_INDEX,
  US_TREASURY_5_YR_DESC,
  US_TREASURY_10_YR_INDEX,
  US_TREASURY_10_YR_DESC,
} from '../../utils/constants';
import { InlineWrapper } from '@material-ui/pickers/wrappers/InlineWrapper';
import { ExpandableLoanRatesTable } from './expandableLoanRatesTable';
import { ProgramOptionsTable } from './programOptionsTable';
import { RateProgramType } from 'libs/feature-loans/src/loanSubmission/components/pricerSummaryTerm';

interface PricerSummaryTermProps {
  store: SinglePropertyStore | RentalPortfolioStore;
}

const useStyles = makeStyles(theme =>
  createStyles({
    table: {},
    row: {
      borderTop: '1px solid #BEBEBE',
    },
    cell: {
      border: 0,
      padding: theme.spacing(1),
      lineHeight: '1em',
      textAlign: 'center',
    },
    headerText: {
      fontWeight: 'bold',
      whiteSpace: 'pre',
      padding: theme.spacing(0.5),
    },
    headerTextSelected: {
      borderRadius: 4,
      backgroundColor: lighten(theme.palette.primary.main, 0.7),
    },
    cellText: {
      padding: theme.spacing(0.5),
    },
    cellTextSelected: {
      fontWeight: 'bold',
      borderRadius: 4,
      backgroundColor: lighten(theme.palette.primary.main, 0.3),
    },

    errorContainer: {
      color: theme.palette.error.main,
    },
    selectedRate: {
      backgroundColor: `#48AD2533`,
      border: '2px solid #48AD25',
      paddingTop: '3px',
      paddingBottom: '3px'
    },
    lowestRate: {
      backgroundColor: `${theme.palette.primary.main}50`,
      paddingTop: '3px',
      paddingBottom: '3px'
    },
    borderRadiusLeft: {
      borderRadius: '18px 0px 0px 18px',
      borderRight: 'none',
      marginRight: '-16px',
      paddingRight: '16px'
    },
    borderRadiusRigth: {
      borderRadius: '0px 18px 18px 0px',
      borderLeft: 'none',
      marginLeft: '-16px',
      paddingLeft: '16px'
    },
    paper: {
      width: '100%',
      backgroundColor: '#F6F6F6',
      padding: '10px 50px 10px 50px',
      margin: '10px 10px 20px'
    },
    list: {
      display: "flex",
      flexDirection: "row",
      listStyle: 'none',
    },
    listItem: {
      margin: '15px 25px 0px 5px'
    }
  })
);
export const AvailableLoanRatesTable = observer(({ store, showRatesTable=true, showSelectRateAndProgramTypes=true }) => {
  const classes = useStyles();
  const {
    globalStore, pricerSummaryStore
  } = store;
  const { rateTypeColumns, loanRatesRows, loanStandardRatesRows, form, swapRates, currentInvestorLtvBands, lowestLtvRates } = store.pricerSummaryStore;
  const { rateType, loanToValue, programType, amortization } = form.fields;

  const renderColumnCell = column => {
    const isSelectedRateValue = column.value === ltvMapper[loanToValue.value];

    return (
      <Typography variant="body2">
        {isSelectedRateValue ? <strong>{column.label}</strong> : column.label}
      </Typography>
    );
  };

  const formatRate = (rate, value) => {
    return rate ? (
      value.warningMessage && value.warningMessage.length > 0 ? (
        <>
          {rate.toFixed(4)}%
          <strong
            style={{ fontSize: '20px' }}
            className={classes.errorContainer}
          >
            *
          </strong>{' '}
        </>
      ) : (
        `${rate.toFixed(4)}%`
      )
    ) : (
      'N/A'
    );
  };

  const formatTooltip = value => {
    return value
      ? (value.errorMessage ?? []).join('\n')
      : 'Ltv band/ Rate Term Type not supported';
  };

  const isSelectedRate = (row, value) => {
    return (
      value &&
      value.swapRate &&
      value.spreadRate &&
      row['rateType'] &&
      row['rateType'] === rateType.value &&
      ltvMapper[loanToValue.value] &&
      row[ltvMapper[loanToValue.value]]['swapRate'] &&
      row[ltvMapper[loanToValue.value]]['spreadRate'] &&
      (row[ltvMapper[loanToValue.value]]['swapRate'] + row[ltvMapper[loanToValue.value]]['spreadRate']) === (value.swapRate + value.spreadRate) && row['programType'] === value.programType
      && value.programType === programType.value
    );
  };

  const isLowestRate = (value, range) => {
    return (
      lowestLtvRates[range] &&
      lowestLtvRates[range][amortization.value] &&
      value.spreadRate === lowestLtvRates[range][amortization.value].spreadRate &&
      value.swapRate === lowestLtvRates[range][amortization.value].swapRate
    );
  };

  const renderRowCell = (row, value, swapOrSpread, range) => {
    let highlightClassname = '';
    const isLowestRateValue = isLowestRate(value, range);
    if (isLowestRateValue) {
      highlightClassname = classes.lowestRate;
    }
    const isSelectedRateValue = isSelectedRate(row, value);
    if (isSelectedRateValue) {
      pricerSummaryStore.fetchRateForDSCR(value);
      highlightClassname = classes.selectedRate;
    }
    const tooltip = formatTooltip(value);
    const displayValue = swapOrSpread ? formatRate(value.spreadRate, value) : formatRate(value.spreadRate + value.swapRate, value);
    return (
      <Typography
        variant="body2"
        className={highlightClassname ?
          (swapOrSpread ?
            `${highlightClassname} ${classes.borderRadiusLeft}`
            : `${highlightClassname} ${classes.borderRadiusRigth}`)
          : ''}
      >
        <Tooltip title={tooltip}>
          {value.swapIndex === null ? (
            <div style={{ padding: '5px 0', textAlign: 'center' }}>{isSelectedRateValue ?
              <strong>{'N/A'}</strong>
              : <span> {'N/A'}</span>}</div>) :
            isSelectedRateValue ?
              <strong>{displayValue}</strong>
              : <span> {displayValue}</span>
          }
        </Tooltip>
      </Typography>
    );
  };

  const renderRowTypeCell = (row, value) => {
    const isSelectedRateValue = value === rateType.value && row['programType'] === programType.value;

    return (
      <Typography variant="body2">
        {isSelectedRateValue ? <strong>{value}</strong> : value}
      </Typography>
    );
  };

  const renderProgramTypeCell = (row, value) => {
    const isSelectedRateValue = value === programType.value && (row['rateType'] === rateType.value);

    return (
      <Typography variant="body2">
        <strong>{value}</strong>
      </Typography>
    );
  };

  const getSwapIndexLabel = index => {
    if (index === US_TREASURY_5_YR_INDEX) {
      return (<Typography variant="body2">
        <strong>{US_TREASURY_5_YR_DESC}</strong>
      </Typography>);
    } else if (index === US_TREASURY_10_YR_INDEX) {
      return (<Typography variant="body2">
        <strong>{US_TREASURY_10_YR_DESC}</strong>
      </Typography>);
    }
  }

  return (
    <>
    {showRatesTable && (
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="avilable loan rates">
          <TableHead>
            <TableRow>
              {rateTypeColumns.map(column => (
                column.label === 'Loan To Value' ? <TableCell colSpan={3} style={{ textAlign: 'center' }}>{renderColumnCell(column)}</TableCell> :
                  currentInvestorLtvBands.includes(column.value) && <TableCell colSpan={2} style={{ textAlign: 'center' }}>{renderColumnCell(column)}</TableCell>
              ))}
            </TableRow>
            <TableRow>
              {rateTypeColumns.map(column => (
                column.label === 'Loan To Value' ? (
                  <React.Fragment>
                    <TableCell colSpan={1} style={{ textAlign: 'center' }}>Program</TableCell>
                    <TableCell colSpan={1} style={{ textAlign: 'center' }}>Index</TableCell>
                    <TableCell colSpan={1} style={{ textAlign: 'center' }}>Rate Type</TableCell>
                  </React.Fragment>
                ) : currentInvestorLtvBands.includes(column.value) && (
                  <React.Fragment>
                    <TableCell colSpan={1} style={{ textAlign: 'center' }}>Spread Over Index</TableCell>
                    <TableCell colSpan={1} style={{ textAlign: 'center' }}>Today's Gross Rate</TableCell>
                  </React.Fragment>
                )
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loanRatesRows.map(row => (
              <TableRow key={row.rateType}>
                {(row.rateType === '5/6 ARM' || row.rateType === '10/6 ARM') && <TableCell rowSpan={2} style={{ textAlign: 'center' }}>{renderProgramTypeCell(row, row[FROM_46_TO_50].programType)}</TableCell>}
                {(row.rateType === '5/6 ARM' || row.rateType === '10/6 ARM') && <TableCell rowSpan={2} style={{ textAlign: 'center' }}>{getSwapIndexLabel(row[FROM_46_TO_50].swapIndex)}</TableCell>}
                <TableCell rowSpan={1} style={{ textAlign: 'center' }}>{renderRowTypeCell(row, row.rateType)}</TableCell>
                {currentInvestorLtvBands.includes(FROM_0_TO_5) &&
                  <React.Fragment>
                    <TableCell style={{ textAlign: 'center' }}>{renderRowCell(row, row[FROM_0_TO_5], true, FROM_0_TO_5)}</TableCell>
                    <TableCell style={{ textAlign: 'center' }}>{renderRowCell(row, row[FROM_0_TO_5], false, FROM_0_TO_5)}</TableCell>
                  </React.Fragment>}
                {currentInvestorLtvBands.includes(FROM_6_TO_10) &&
                  <React.Fragment>
                    <TableCell style={{ textAlign: 'center' }}>{renderRowCell(row, row[FROM_6_TO_10], true, FROM_6_TO_10)}</TableCell>
                    <TableCell style={{ textAlign: 'center' }}>{renderRowCell(row, row[FROM_6_TO_10], false, FROM_6_TO_10)}</TableCell>
                  </React.Fragment>}
                {currentInvestorLtvBands.includes(FROM_11_TO_15) &&
                  <React.Fragment>
                    <TableCell style={{ textAlign: 'center' }}>{renderRowCell(row, row[FROM_11_TO_15], true, FROM_11_TO_15)}</TableCell>
                    <TableCell style={{ textAlign: 'center' }}>{renderRowCell(row, row[FROM_11_TO_15], false, FROM_11_TO_15)}</TableCell>
                  </React.Fragment>}
                {currentInvestorLtvBands.includes(FROM_16_TO_20) &&
                  <React.Fragment>
                    <TableCell style={{ textAlign: 'center' }}>{renderRowCell(row, row[FROM_16_TO_20], true, FROM_16_TO_20)}</TableCell>
                    <TableCell style={{ textAlign: 'center' }}>{renderRowCell(row, row[FROM_16_TO_20], false, FROM_16_TO_20)}</TableCell>
                  </React.Fragment>}
                {currentInvestorLtvBands.includes(FROM_21_TO_25) &&
                  <React.Fragment>
                    <TableCell style={{ textAlign: 'center' }}>{renderRowCell(row, row[FROM_21_TO_25], true, FROM_21_TO_25)}</TableCell>
                    <TableCell style={{ textAlign: 'center' }}>{renderRowCell(row, row[FROM_21_TO_25], false, FROM_21_TO_25)}</TableCell>
                  </React.Fragment>}
                {currentInvestorLtvBands.includes(FROM_26_TO_30) &&
                  <React.Fragment>
                    <TableCell style={{ textAlign: 'center' }}>{renderRowCell(row, row[FROM_26_TO_30], true, FROM_26_TO_30)}</TableCell>
                    <TableCell style={{ textAlign: 'center' }}>{renderRowCell(row, row[FROM_26_TO_30], false, FROM_26_TO_30)}</TableCell>
                  </React.Fragment>}
                {currentInvestorLtvBands.includes(FROM_31_TO_35) &&
                  <React.Fragment>
                    <TableCell style={{ textAlign: 'center' }}>{renderRowCell(row, row[FROM_31_TO_35], true, FROM_31_TO_35)}</TableCell>
                    <TableCell style={{ textAlign: 'center' }}>{renderRowCell(row, row[FROM_31_TO_35], false, FROM_31_TO_35)}</TableCell>
                  </React.Fragment>}
                {currentInvestorLtvBands.includes(FROM_36_TO_40) &&
                  <React.Fragment>
                    <TableCell style={{ textAlign: 'center' }}>{renderRowCell(row, row[FROM_36_TO_40], true, FROM_36_TO_40)}</TableCell>
                    <TableCell style={{ textAlign: 'center' }}>{renderRowCell(row, row[FROM_36_TO_40], false, FROM_36_TO_40)}</TableCell>
                  </React.Fragment>}
                {currentInvestorLtvBands.includes(FROM_41_TO_45) &&
                  <React.Fragment>
                    <TableCell style={{ textAlign: 'center' }}>{renderRowCell(row, row[FROM_41_TO_45], true, FROM_41_TO_45)}</TableCell>
                    <TableCell style={{ textAlign: 'center' }}>{renderRowCell(row, row[FROM_41_TO_45], false, FROM_41_TO_45)}</TableCell>
                  </React.Fragment>}
                {currentInvestorLtvBands.includes(FROM_46_TO_50) &&
                  <React.Fragment>
                    <TableCell style={{ textAlign: 'center' }}>{renderRowCell(row, row[FROM_46_TO_50], true, FROM_46_TO_50)}</TableCell>
                    <TableCell style={{ textAlign: 'center' }}>{renderRowCell(row, row[FROM_46_TO_50], false, FROM_46_TO_50)}</TableCell>
                  </React.Fragment>}
                {currentInvestorLtvBands.includes(FROM_50_TO_55) &&
                  <React.Fragment>
                    <TableCell style={{ textAlign: 'center' }}>{renderRowCell(row, row[FROM_50_TO_55], true, FROM_50_TO_55)}</TableCell>
                    <TableCell style={{ textAlign: 'center' }}>{renderRowCell(row, row[FROM_50_TO_55], false, FROM_50_TO_55)}</TableCell>
                  </React.Fragment>}
                {currentInvestorLtvBands.includes(FROM_55_TO_60) &&
                  <React.Fragment>
                    <TableCell style={{ textAlign: 'center' }}>{renderRowCell(row, row[FROM_55_TO_60], true, FROM_55_TO_60)}</TableCell>
                    <TableCell style={{ textAlign: 'center' }}>{renderRowCell(row, row[FROM_55_TO_60], false, FROM_55_TO_60)}</TableCell>
                  </React.Fragment>}
                {currentInvestorLtvBands.includes(FROM_61_TO_65) &&
                  <React.Fragment>
                    <TableCell style={{ textAlign: 'center' }}>{renderRowCell(row, row[FROM_61_TO_65], true, FROM_61_TO_65)}</TableCell>
                    <TableCell style={{ textAlign: 'center' }}>{renderRowCell(row, row[FROM_61_TO_65], false, FROM_61_TO_65)}</TableCell>
                  </React.Fragment>}
                {currentInvestorLtvBands.includes(FROM_66_TO_70) &&
                  <React.Fragment>
                    <TableCell style={{ textAlign: 'center' }}>{renderRowCell(row, row[FROM_66_TO_70], true, FROM_66_TO_70)}</TableCell>
                    <TableCell style={{ textAlign: 'center' }}>{renderRowCell(row, row[FROM_66_TO_70], false, FROM_66_TO_70)}</TableCell>
                  </React.Fragment>}
                {currentInvestorLtvBands.includes(FROM_71_TO_75) &&
                  <React.Fragment>
                    <TableCell style={{ textAlign: 'center' }}>{renderRowCell(row, row[FROM_71_TO_75], true, FROM_71_TO_75)}</TableCell>
                    <TableCell style={{ textAlign: 'center' }}>{renderRowCell(row, row[FROM_71_TO_75], false, FROM_71_TO_75)}</TableCell>
                  </React.Fragment>}
                {currentInvestorLtvBands.includes(FROM_76_TO_80) &&
                  <React.Fragment>
                    <TableCell style={{ textAlign: 'center' }}>{renderRowCell(row, row[FROM_76_TO_80], true, FROM_76_TO_80)}</TableCell>
                    <TableCell style={{ textAlign: 'center' }}>{renderRowCell(row, row[FROM_76_TO_80], false, FROM_76_TO_80)}</TableCell>
                  </React.Fragment>}

              </TableRow>
            ))}

            {loanStandardRatesRows.map(row => row.programType && (
              <TableRow key={row.rateType}>
                {(row.rateType === '5/6 ARM' || row.rateType === '10/6 ARM') && <TableCell rowSpan={2} style={{ textAlign: 'center' }}>{renderProgramTypeCell(row, row[FROM_46_TO_50].programType)}</TableCell>}
                {(row.rateType === '5/6 ARM' || row.rateType === '10/6 ARM') && <TableCell rowSpan={2} style={{ textAlign: 'center' }}>{getSwapIndexLabel(row[FROM_46_TO_50].swapIndex)}</TableCell>}
                <TableCell rowSpan={1} style={{ textAlign: 'center' }}>{renderRowTypeCell(row, row.rateType)}</TableCell>
                {currentInvestorLtvBands.includes(FROM_0_TO_5) &&
                  <React.Fragment>
                    <TableCell style={{ textAlign: 'center' }}>{renderRowCell(row, row[FROM_0_TO_5], true, FROM_0_TO_5)}</TableCell>
                    <TableCell style={{ textAlign: 'center' }}>{renderRowCell(row, row[FROM_0_TO_5], false, FROM_0_TO_5)}</TableCell>
                  </React.Fragment>}
                {currentInvestorLtvBands.includes(FROM_6_TO_10) &&
                  <React.Fragment>
                    <TableCell style={{ textAlign: 'center' }}>{renderRowCell(row, row[FROM_6_TO_10], true, FROM_6_TO_10)}</TableCell>
                    <TableCell style={{ textAlign: 'center' }}>{renderRowCell(row, row[FROM_6_TO_10], false, FROM_6_TO_10)}</TableCell>
                  </React.Fragment>}
                {currentInvestorLtvBands.includes(FROM_11_TO_15) &&
                  <React.Fragment>
                    <TableCell style={{ textAlign: 'center' }}>{renderRowCell(row, row[FROM_11_TO_15], true, FROM_11_TO_15)}</TableCell>
                    <TableCell style={{ textAlign: 'center' }}>{renderRowCell(row, row[FROM_11_TO_15], false, FROM_11_TO_15)}</TableCell>
                  </React.Fragment>}
                {currentInvestorLtvBands.includes(FROM_16_TO_20) &&
                  <React.Fragment>
                    <TableCell style={{ textAlign: 'center' }}>{renderRowCell(row, row[FROM_16_TO_20], true, FROM_16_TO_20)}</TableCell>
                    <TableCell style={{ textAlign: 'center' }}>{renderRowCell(row, row[FROM_16_TO_20], false, FROM_16_TO_20)}</TableCell>
                  </React.Fragment>}
                {currentInvestorLtvBands.includes(FROM_21_TO_25) &&
                  <React.Fragment>
                    <TableCell style={{ textAlign: 'center' }}>{renderRowCell(row, row[FROM_21_TO_25], true, FROM_21_TO_25)}</TableCell>
                    <TableCell style={{ textAlign: 'center' }}>{renderRowCell(row, row[FROM_21_TO_25], false, FROM_21_TO_25)}</TableCell>
                  </React.Fragment>}
                {currentInvestorLtvBands.includes(FROM_26_TO_30) &&
                  <React.Fragment>
                    <TableCell style={{ textAlign: 'center' }}>{renderRowCell(row, row[FROM_26_TO_30], true, FROM_26_TO_30)}</TableCell>
                    <TableCell style={{ textAlign: 'center' }}>{renderRowCell(row, row[FROM_26_TO_30], false, FROM_26_TO_30)}</TableCell>
                  </React.Fragment>}
                {currentInvestorLtvBands.includes(FROM_31_TO_35) &&
                  <React.Fragment>
                    <TableCell style={{ textAlign: 'center' }}>{renderRowCell(row, row[FROM_31_TO_35], true, FROM_31_TO_35)}</TableCell>
                    <TableCell style={{ textAlign: 'center' }}>{renderRowCell(row, row[FROM_31_TO_35], false, FROM_31_TO_35)}</TableCell>
                  </React.Fragment>}
                {currentInvestorLtvBands.includes(FROM_36_TO_40) &&
                  <React.Fragment>
                    <TableCell style={{ textAlign: 'center' }}>{renderRowCell(row, row[FROM_36_TO_40], true, FROM_36_TO_40)}</TableCell>
                    <TableCell style={{ textAlign: 'center' }}>{renderRowCell(row, row[FROM_36_TO_40], false, FROM_36_TO_40)}</TableCell>
                  </React.Fragment>}
                {currentInvestorLtvBands.includes(FROM_41_TO_45) &&
                  <React.Fragment>
                    <TableCell style={{ textAlign: 'center' }}>{renderRowCell(row, row[FROM_41_TO_45], true, FROM_41_TO_45)}</TableCell>
                    <TableCell style={{ textAlign: 'center' }}>{renderRowCell(row, row[FROM_41_TO_45], false, FROM_41_TO_45)}</TableCell>
                  </React.Fragment>}
                {currentInvestorLtvBands.includes(FROM_46_TO_50) &&
                  <React.Fragment>
                    <TableCell style={{ textAlign: 'center' }}>{renderRowCell(row, row[FROM_46_TO_50], true, FROM_46_TO_50)}</TableCell>
                    <TableCell style={{ textAlign: 'center' }}>{renderRowCell(row, row[FROM_46_TO_50], false, FROM_46_TO_50)}</TableCell>
                  </React.Fragment>}
                {currentInvestorLtvBands.includes(FROM_50_TO_55) &&
                  <React.Fragment>
                    <TableCell style={{ textAlign: 'center' }}>{renderRowCell(row, row[FROM_50_TO_55], true, FROM_50_TO_55)}</TableCell>
                    <TableCell style={{ textAlign: 'center' }}>{renderRowCell(row, row[FROM_50_TO_55], false, FROM_50_TO_55)}</TableCell>
                  </React.Fragment>}
                {currentInvestorLtvBands.includes(FROM_55_TO_60) &&
                  <React.Fragment>
                    <TableCell style={{ textAlign: 'center' }}>{renderRowCell(row, row[FROM_55_TO_60], true, FROM_55_TO_60)}</TableCell>
                    <TableCell style={{ textAlign: 'center' }}>{renderRowCell(row, row[FROM_55_TO_60], false, FROM_55_TO_60)}</TableCell>
                  </React.Fragment>}
                {currentInvestorLtvBands.includes(FROM_61_TO_65) &&
                  <React.Fragment>
                    <TableCell style={{ textAlign: 'center' }}>{renderRowCell(row, row[FROM_61_TO_65], true, FROM_61_TO_65)}</TableCell>
                    <TableCell style={{ textAlign: 'center' }}>{renderRowCell(row, row[FROM_61_TO_65], false, FROM_61_TO_65)}</TableCell>
                  </React.Fragment>}
                {currentInvestorLtvBands.includes(FROM_66_TO_70) &&
                  <React.Fragment>
                    <TableCell style={{ textAlign: 'center' }}>{renderRowCell(row, row[FROM_66_TO_70], true, FROM_66_TO_70)}</TableCell>
                    <TableCell style={{ textAlign: 'center' }}>{renderRowCell(row, row[FROM_66_TO_70], false, FROM_66_TO_70)}</TableCell>
                  </React.Fragment>}
                {currentInvestorLtvBands.includes(FROM_71_TO_75) &&
                  <React.Fragment>
                    <TableCell style={{ textAlign: 'center' }}>{renderRowCell(row, row[FROM_71_TO_75], true, FROM_71_TO_75)}</TableCell>
                    <TableCell style={{ textAlign: 'center' }}>{renderRowCell(row, row[FROM_71_TO_75], false, FROM_71_TO_75)}</TableCell>
                  </React.Fragment>}
                {currentInvestorLtvBands.includes(FROM_76_TO_80) &&
                  <React.Fragment>
                    <TableCell style={{ textAlign: 'center' }}>{renderRowCell(row, row[FROM_76_TO_80], true, FROM_76_TO_80)}</TableCell>
                    <TableCell style={{ textAlign: 'center' }}>{renderRowCell(row, row[FROM_76_TO_80], false, FROM_76_TO_80)}</TableCell>
                  </React.Fragment>}

              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      )}

      <br />

      <TargetLtvErrors store={store} />
      <br />

      <Grid item xs={12} md={6} lg={6}>
        <Typography color="secondary" variant="h6">
          PROGRAM OPTIONS
        </Typography>
      </Grid>

      <br />

      <ProgramOptionsTable />

      <br />
      <RateAndRateLockTerms store={store} />

      <LoanRatesWarnings store={store} />
      <br />
      <RateProgramType store={store} />
    </>

  );
});

export const RateAndRateLockTerms = observer(({ store }) => {
  const classes = useStyles();
  const { globalStore, pricerSummaryStore } = store;
  const { swapRates } = store.pricerSummaryStore;

  const renderSwapRatesText = label => {
    if (label === US_TREASURY_5_YR_INDEX.toString()) return (
      <li className={classes.listItem}>{US_TREASURY_5_YR_DESC} : <strong>{swapRates[label].toFixed(4)}%</strong></li>
    )
    else if (label === US_TREASURY_10_YR_INDEX.toString()) return (
      <li className={classes.listItem}>{US_TREASURY_10_YR_DESC} : <strong>{swapRates[label].toFixed(4)}%</strong></li>
    )
  };

  return (
    <ExpandableLoanRatesTable title={'Rate and Rate Lock Terms'}>
      <Grid container spacing={2}>
        <Paper className={classes.paper} variant='elevation' elevation={0}>
          <Grid item xs={12}>
            <Typography variant="body1">
              <ul className={classes.list}>
                <li className={classes.listItem}><strong>USD 3M Libor US Treasury Rates as of {new Date().toDateString()}</strong></li>
                {Object.keys(swapRates).map((label) => renderSwapRatesText(label))}
              </ul>
            </Typography>
          </Grid>
          <br />
          <Divider variant='middle' />
          <br />
          <Grid item xs={12}>
            <Typography>
              <ul style={{ marginLeft: '15px' }}>
                <li>Indicative Gross Rates are calculated as the sum of (1) value of the Index today + (2) Spread over Index. <strong>They represent today's rates, based on the information submitted at pricing.</strong></li>
                <br />
                <li>Spread over Index will remain locked for a period of 30 days from loan submission date, provided <strong>the information submitted at pricing is verified during document collection and underwriting.</strong></li>
                <br />
                <li>Gross rates are determined at the time of credit approval, calculated as the sum of the (1) value of the Index as of the date of credit approval, and (2) Spread over Index. </li>
                <br />
                <li> Spread over Index will remain unchanged from loan submission if (1) rate setting parameters for the loan do not change from submission and (2) credit approval occurs within 30 days of loan submission</li>
                <br />
                {
                  globalStore.lenderInfo?.limitedFunctionalitiesList?.includes('borrower rate lock extension') &&
                  <li>Gross rates are locked for a period of 15 days from credit approval date for no fee. If a loan closes after this 15-day period, rates are subject to change and require re-approval for closing. There are two rate lock extension options available for purchase: Option 1 with two 15 day rate lock extensions or Option 2 with one 30 day rate lock extension. </li>
                }
                {
                  !globalStore.lenderInfo?.limitedFunctionalitiesList?.includes('borrower rate lock extension') &&
                  <li>Gross rates are locked for a period of 30 days from credit approval date. If a loan closes after this 30-days period, rates are subject to change and is require re-approval for closing. </li>
                }
              </ul>
            </Typography>
          </Grid>
          <br />
          <Grid item>
            <Typography variant="body1">
              <strong>
                Please refer to Rental Loan Rates{' '}
                <a
                  style={{ textDecoration: 'underline', cursor: 'pointer' }}
                  onClick={() => pricerSummaryStore.downloadLoanFaqs()}
                >
                  FAQs
                </a>{' '}
                for more information
              </strong>
            </Typography>
          </Grid>
        </Paper>
      </Grid>
    </ExpandableLoanRatesTable>
  )
});

export const TargetLtvErrors = store => {
  const classes = useStyles();
  const serverExceptions = store?.store.pricerSummaryStore.serverExceptions;

  return (
    <Grid
      container
      className={classes.errorContainer}
      direction="column"
      spacing={1}
    >
      {serverExceptions &&
        serverExceptions.length > 0 &&
        serverExceptions.map(exception => (
          <Grid item key={exception}>
            <strong>{exception}</strong>
          </Grid>
        ))}
      {serverExceptions && serverExceptions.length > 0 && (
        <Grid item>
          <Typography variant="body1">
            <strong>
              Download Loan Rates to see specific Error for Given Rate Type and
              LTV.
            </strong>
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export const LoanRatesWarnings = observer(({ store }) => {
  const classes = useStyles();
  const { warningExceptions } = store.pricerSummaryStore;

  return (
    <Grid
      container
      className={classes.errorContainer}
      direction="column"
      spacing={1}
    >
      {warningExceptions &&
        warningExceptions.length > 0 &&
        warningExceptions.map(exception => (
          <Grid item key={exception}>
            <strong>*{exception}</strong>
          </Grid>
        ))}
    </Grid>
  );
});
