import { GlobalStore } from '@roc/feature-app-core';
import { makeObservable } from 'mobx';
import { BrokerService } from '../services/brokerService';
import { BrokerFormStore } from './brokerFormStore';
import { BrokersGridStore } from './brokersGridStore';
import { ManageBrokersStore } from './manageBrokersStore';
import { SelectBrokersStore } from './selectBrokersStore';
import { ManageBrokerFormStore } from './manageBrokerFormStore';
import { BrokerContactsGridStore } from './brokerContactsGridStore';
import { BrokerContactsSummaryGridStore } from './brokerContactsSummaryGridStore';
import { BrokerFeesStore } from './brokerFeesStore';
import { BaseBrokersStore } from './baseBrokersStore'

export class BrokersStore extends BaseBrokersStore {
  constructor(globalStore: GlobalStore) {
    super(globalStore, new BrokerService());
  }
}
