import { action, computed, flow, makeObservable, observable } from 'mobx';
import { FormStore } from '@roc/feature-app-core';

const REQUIRED_MESSAGE = 'This field is required';

const form = {
  fields: {
    pctOwnership: {
      value: '',
      error: null,
      rule: '',
    },
    roleInEntity: {
      value: '',
      error: null,
      rule: '',
    },
    authSignatory: {
      value: '',
      error: null,
      rule: '',
    },
    personalGuarantor: {
      value: '',
      error: null,
      rule: '',
    },
    stateForSigning: {
      value: '',
      error: null,
      rule: '',
    },
    countyForSigning: {
      value: '',
      error: null,
      rule: '',
    },
  },
  meta: {
    isValid: true,
    error: null,
  },
};

export class EditEntityBorrowerStore extends FormStore {
  open = false;
  entityBorrower: any;

  constructor(globalStore) {
    super(form, globalStore);
    makeObservable(this, {
      open: observable,
      openDialog: action,
      closeDialog: action,
    });
  }

  openDialog = entityBorrower => {
    this.reset();
    this.entityBorrower = entityBorrower;
    this.loadForm(entityBorrower);
    this.open = true;
  };

  closeDialog = () => {
    this.open = false;
  };
}

export default EditEntityBorrowerStore;
