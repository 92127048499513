import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Entity } from '@roc/feature-types';

import { Box } from '@material-ui/core';
import { Borrower } from '@roc/feature-types';
import { BorrowerCard, EntityCard, SubmitButton } from '@roc/ui';
import { EditBorrowersForm } from '.';
import { Edit } from '@material-ui/icons';
import { useLoanStore } from '../../hooks/useLoanStore';

const useStyles = makeStyles(theme =>
  createStyles({
    paper: {
      borderRadius: theme.spacing(2),
      boxShadow:
        'rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px',
      marginBottom: theme.spacing(2),
    },
    borrowersGrid: {
      marginTop: theme.spacing(4),
    },
    borrowersInnerGrid: {
      marginTop: theme.spacing(0),
    },
  })
);
interface BorrowerEntityProps {
  hideEditBorrowers?: boolean;
  entity: Entity;
  borrowers: Array<Borrower>;
}

const BorrowerEntityOverview: React.FC<BorrowerEntityProps> = ({
  hideEditBorrowers,
  entity,
  borrowers,
}) => {
  const { loanStore } = useLoanStore();
  const classes = useStyles();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Box mr={3}>
              <EntityCard entity={entity} shadow={true} />
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.borrowersGrid}>
        <Grid
          container
          alignItems="center"
          alignContent="center"
          justifyContent="space-between"
        >
          <Grid item>
            <Typography variant="h5">BORROWERS</Typography>
          </Grid>
          <Grid item>
            <Box mr={3}>
              {!hideEditBorrowers && (
                <SubmitButton
                  size="small"
                  variant="contained"
                  color="primary"
                  testId="editBorrowers"
                  startIcon={<Edit />}
                  onCancelClick={() => {
                    loanStore.selectBorrowersStore.reset();
                    loanStore.setExistingBorrowers();
                  }}
                  onClick={() => loanStore.saveBorrowers()}
                  submitDialogProps={{
                    title: 'Edit Borrowers',
                    body: <EditBorrowersForm store={loanStore} />,
                    okButtonText: 'Submit',
                  }}
                >
                  Edit
                </SubmitButton>
              )}
            </Box>
          </Grid>
        </Grid>
        {borrowers.length > 0 && (
          <Grid container spacing={2} className={classes.borrowersInnerGrid}>
            {borrowers.map((borrower, index) => (
              <Grid item xs={12} key={borrower.borrowerId}>
                <BorrowerCard
                  id={index + 1}
                  title={`Borrower #${index + 1}`}
                  borrower={borrower}
                  largeCard
                />
              </Grid>
            ))}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default BorrowerEntityOverview;
