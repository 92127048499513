import { borrowerExpDictionary, LoanSubType } from '@roc/feature-utils';

export enum LoanApplicationStep {
  LOAN_TYPE,
  ENTITY_INFORMATION,
  PROPERTY_INFORMATION,
  SUMMARY,
  THANK_YOU,
}

export const loanTypeOptions = [
  {
    label: 'Fix & Flip',
    value: LoanSubType.FIX_AND_FLIP,
  },
  // {
  //   label: 'Ground Up',
  //   value: LoanSubType.GROUND_UP,
  // },
  // {
  //   label: 'Multi Family Bridge',
  //   value: LoanSubType.MULTIFAMILY_BRIDGE_5PLUS
  // },
  // {
  //   label: 'Stabilized Bridge',
  //   value: LoanSubType.STABILIZED_BRIDGE
  // },
  // {
  //   label: 'Single Property Rental',
  //   value: LoanSubType.SINGLE_PROPERTY
  // },
  // {
  //   label: 'Rental Portfolio',
  //   value: LoanSubType.RENTAL_PORTFOLIO
  // }
];

export const rentalExperienceOptions = Object.keys(borrowerExpDictionary).map(
  key => ({
    label: key,
    value: borrowerExpDictionary[key],
  })
);
