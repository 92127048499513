import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Box, Grid, lighten, makeStyles, Theme, Typography, useTheme } from '@material-ui/core';
import { Menu } from '@material-ui/icons';
import { LoginLogoImg, Page } from '@roc/feature-app-core';
import { IconButton, Paper, PaperBorderType, PreviewBackdrop } from '@roc/ui';
import clsx from 'clsx';
import { format } from 'date-fns';
import jsPDF from 'jspdf';
import { useEffect, useRef, useState } from 'react';
import { HtmlDocumentMenu } from './../../components/htmlDocumentMenu';
import { useDocumentLibraryRoutes } from './../../hooks/useDocumentLibraryRoutes';

const useStyles = makeStyles((theme: Theme) => ({
  documentContainer: {
    position: 'relative',
    height: '100%',
    padding: '16px',
    background: '#FFF',
    fontSize: 16,
    '& table': {
      width: '100%',
      textAlign: 'center',
    },
    '& ul,ol': {
      marginLeft: theme.spacing(3),
      marginBottom: theme.spacing(0.5),
    },
    '& h6': {
      fontSize: 16,
      padding: 4,
      fontWeight: 'bold',
    },
  },
  documentCard: {
    borderRadius: 0,
    marginBottom: theme.spacing(1),
    borderColor: '#aaa',
  },
  documentCardTitle: {
    justifyContent: 'center',
    textAlign: 'center',
    background: lighten(theme.palette.primary.main, 0.5),
    fontWeight: 'bold',
    padding: theme.spacing(0.5),
  },
  maxLtvsCard: {
    '& table th': {
      background: lighten(theme.palette.primary.main, 0.8),
      padding: '4px',
    },
  },
  maxLtvsCardBody: {
    padding: 0,
  },
  borrowerGuarantorChildCardTitle: {
    background: lighten(theme.palette.primary.main, 0.8),
    backgroundImage: 'none',
  },
}));

const usePrintModeStyles = makeStyles((theme: Theme) => ({
  documentContainer: {
    fontSize: 8,
    '& ul,ol': {
      marginLeft: theme.spacing(2),
    },
    '& h6': {
      fontSize: 12,
    },
  },
}));

const DocumentCard = ({
  title,
  data,
  paperBorder = PaperBorderType.SOLID_BORDER,
  paperClassName = '',
  paperTitleClassName = '',
  paperBodyClassName = '',
  paperStyle = {},
}) => {
  const classes = useStyles();
  return (
    <Paper
      title={title}
      titleClass={clsx(classes.documentCardTitle, paperTitleClassName)}
      titleVariant={'h6'}
      borderType={paperBorder}
      className={clsx(classes.documentCard, paperClassName)}
      style={paperStyle}>
      <Box p={0.5} className={paperBodyClassName}>
        {documentToReactComponents(data)}
      </Box>
    </Paper>
  )
}

export const RentalPortfolioTearSheetHtml = ({
  contentId,
  data,
  preview = false,
  printMode = false,
  setContainerRef,
}: {
  contentId: string,
  data: any,
  preview?: boolean,
  printMode?: boolean,
  setContainerRef: any,
}) => {
  const theme = useTheme();
  const { documentLibraryRoutesConfig } = useDocumentLibraryRoutes();
  const classes = useStyles();
  const printModeClasses = usePrintModeStyles();
  const containerRef = useRef(null);

  const [printModeContainerStyle, setPrintModeContainerStyle] = useState<any>();
  const [containerStyle, setContainerStyle] = useState<any>();

  useEffect(() => {
    const doc = new jsPDF("p", "mm", "a4");
    let width: any = doc.internal.pageSize.getWidth();
    let height: any = doc.internal.pageSize.getHeight();
    width = `${Math.floor(width)}mm`;
    height = `${Math.floor(height)}mm`;
    setPrintModeContainerStyle({
      margin: 'auto',
      minWidth: width,
      maxWidth: width,
      height: height
    });
    setContainerStyle({
      margin: 'auto',
      maxWidth: theme.breakpoints.values.lg,
    });
  }, []);

  useEffect(() => {
    setContainerRef(containerRef);
  }, [containerRef])

  if (!data) {
    return null;
  }

  return (
    <div ref={containerRef} style={printMode ? printModeContainerStyle : containerStyle}>
      <Page routeDefinition={documentLibraryRoutesConfig.documentLibraryDocument(contentId)}>
        <Box className={clsx(classes.documentContainer, printMode ? printModeClasses.documentContainer : undefined)}>
          {preview && <PreviewBackdrop />}
          <LoginLogoImg imgStyle={{ maxWidth: 200 }} />
          <Box my={2} textAlign='center'>
            <Typography variant={'h5'}>
              <strong>{data.documentTitle}</strong>
            </Typography>
          </Box>
          <Box mb={2}>
            <Grid container spacing={1}>
              <Grid item lg={8} xs={printMode ? 8 : 12}>
                <Grid container spacing={1}>
                  <Grid item sm={6} xs={printMode ? 6 : 12}>
                    <Grid item xs={12}>
                      <Paper
                        titleVariant={'h6'}
                        borderType={PaperBorderType.SOLID_BORDER}
                        className={classes.documentCard}
                      >
                        <Grid container>
                          <Grid item sm={12} xs={printMode ? 6 : 12} style={{
                            borderBottom: '1px solid #aaa',
                          }}>
                            <DocumentCard
                              title={data.maximumLtvsCardTitle}
                              data={data.maximumLtvsCardData}
                              paperClassName={classes.maxLtvsCard}
                              paperBodyClassName={classes.maxLtvsCardBody}
                              paperBorder={PaperBorderType.NO_BORDER}
                              paperStyle={{
                                borderColor: '#aaa',
                              }}
                            />
                          </Grid>
                          <Grid item>
                            <Box m={0.5}>
                              {documentToReactComponents(data.maximumLtVsFooterCardData)}
                            </Box>
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                    <DocumentCard title={data.reservesEscrowsCardTitle} data={data.reservesEscrowsCardData} />
                    <DocumentCard title={data.maximumLtcForCoRefiLoansCardTitle} data={data.maximumLtcForCoRefiLoansCardData} />
                  </Grid>
                  <Grid item sm={6} xs={printMode ? 6 : 12}>
                    <DocumentCard title={data.loanProductsAmortizationCardTitle} data={data.loanProductsAmortizationCardData} />
                    <DocumentCard title={data.prepaymentPenaltiesCardTitle} data={data.prepaymentPenaltiesCardData} />
                  </Grid>
                  <Grid item xs={12}>
                    <Paper
                      title={data.borrowerGuarantorRequirementsCardTitle}
                      titleClass={classes.documentCardTitle}
                      titleVariant={'h6'}
                      borderType={PaperBorderType.SOLID_BORDER}
                      className={classes.documentCard}
                    >
                      <Grid container>
                        <Grid item sm={6} xs={printMode ? 6 : 12} style={{
                          borderBottom: '1px solid #aaa',
                          borderRight: '1px solid #aaa',
                        }}>
                          <DocumentCard
                            title={data.borrowerGuarantorCreditAndBackgroundCardTitle}
                            data={data.borrowerGuarantorCreditAndBackgroundCardData}
                            paperBorder={PaperBorderType.NO_BORDER}
                            paperStyle={{
                              borderColor: '#aaa',
                            }}
                            paperTitleClassName={classes.borrowerGuarantorChildCardTitle} />
                        </Grid>
                        <Grid item sm={6} xs={printMode ? 6 : 12} style={{
                          borderBottom: '1px solid #aaa',
                        }}>
                          <DocumentCard
                            title={data.borrowerGuarantorLiquidityCardTitle}
                            data={data.borrowerGuarantorLiquidityCardData}
                            paperBorder={PaperBorderType.NO_BORDER}
                            paperStyle={{
                              borderColor: '#aaa',
                            }}
                            paperTitleClassName={classes.borrowerGuarantorChildCardTitle} />
                        </Grid>
                        <Grid item>
                          <Box m={0.5}>
                            {documentToReactComponents(data.borrowerGuarantorRequirementsFooterCardData)}
                          </Box>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item lg={4} xs={printMode ? 4 : 12}>
                <DocumentCard title={data.loanGeneralCardTitle} data={data.loanGeneralCardData} />
                <DocumentCard title={data.debtServiceCoverageRatioDscrCardTitle} data={data.debtServiceCoverageRatioDscrCardData} />
                <DocumentCard title={data.stateRestrictionsCardTitle} data={data.stateRestrictionsCardData} />
              </Grid>
            </Grid>
          </Box>
          <Box m={1} position={'absolute'} right={0} bottom={0} textAlign='right' color={'#ccc'}>
            <Typography variant={'body2'}>
              {`Last Updated: ${format(new Date(data.lastUpdated), 'MM-dd-yyyy')}`}
            </Typography>
          </Box>
        </Box>
      </Page>
    </div>
  );
}