import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import {
  TextField,
  SelectField,
  CurrencyField,
  RadioField,
  yesNoOptions,
  NumberFormat,
} from '@roc/ui';
import { observer } from 'mobx-react';
import {
  MIXED_USE,
  MULTIFAMILY_5_PLUS,
  propertyTypes,
} from '@roc/feature-utils';
import { RentalPortfolioStore } from './../../stores';
import Typography from '@material-ui/core/Typography';
import { isNotBlank } from '@roc/feature-utils';

interface PropertyTypesFormProps {
  store: RentalPortfolioStore;
}

const propertyBlankDetails = {
  [MULTIFAMILY_5_PLUS]: { numberOfUnits: 5 },
  [MIXED_USE]: {
    residentialUnits: null,
    residentialUnitsSqFtArea: null,
    commercialUnits: null,
    commercialUnitsSqFtArea: null,
    commercialUnitsCurrentOnRent: null,
    residentialIncome: null,
    commercialIncome: null,
  },
};

const propertiesObjName = {
  [MULTIFAMILY_5_PLUS]: 'mfProperties',
  [MIXED_USE]: 'muProperties',
};

const PropertyTypesForm = (props: PropertyTypesFormProps) => {
  const { store } = props;
  const { propertyTypesStore } = store;
  const { form } = propertyTypesStore;

  const propertyTypeOptions = () => {
    return propertyTypes;
  };

  const onNumberOfPropertiesChange = numberOfProperties => {
    const propertyType = form.fields.propertyType.value;
    if (propertyType === MULTIFAMILY_5_PLUS || propertyType === MIXED_USE) {
      const propertiesDetails = [];
      for (let i = 0; i < numberOfProperties; i++) {
        propertiesDetails.push(propertyBlankDetails[propertyType]);
      }
      propertyTypesStore.onFieldChange(
        propertiesObjName[propertyType],
        propertiesDetails
      );
    }
    propertyTypesStore.onFieldChange('numberOfProperties', numberOfProperties);
  };

  const handlePropertyTypeChange = (name, value) => {
    if (value === MULTIFAMILY_5_PLUS || value === MIXED_USE) {
      store.pricerStore.handlePropertyTypeChange(value);
    } else{
      propertyTypesStore.onFieldChange(name, value)
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={6}>
          <SelectField
            standaloneLabel
            label={'Select a Property Type included in the Portfolio'}
            name={'propertyType'}
            value={form.fields.propertyType.value}
            errorText={form.fields.propertyType.error}
            options={propertyTypeOptions()}
            onChange={value =>
              handlePropertyTypeChange('propertyType', value)
            }
            variant="outlined"
            fullWidth
            shrink
            required
            testId="propertyType"
          />
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <NumberFormat
            customInput={TextField}
            required={true}
            variant="outlined"
            label="Number of Properties"
            testId="numberOfProperties"
            value={form.fields.numberOfProperties.value}
            onChange={e => onNumberOfPropertiesChange(e.target.value)}
            error={isNotBlank(form.fields.numberOfProperties.error)}
            helperText={form.fields.numberOfProperties.error}
            standaloneLabel
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <CurrencyField
            variant="outlined"
            standaloneLabel
            label="Estimated As Is Value"
            value={form.fields.estimatedAsIsValue.value}
            onChange={(name, value) =>
              propertyTypesStore.onFieldChange('estimatedAsIsValue', value)
            }
            fullWidth
            testId="estimatedAsIsValue"
            required
            errorText={form.fields.estimatedAsIsValue.error}
          />
        </Grid>
      </Grid>
      {form.fields.propertyType.value === MULTIFAMILY_5_PLUS &&
        form.fields.mfProperties.value?.length > 0 && (
          <MFPropertyDetailsForm store={store} />
        )}
      {form.fields.propertyType.value === MIXED_USE &&
        form.fields.muProperties.value?.length > 0 && (
          <MUPropertyDetailsForm store={store} />
        )}
    </>
  );
};

const MFPropertyDetailsForm = (props: PropertyTypesFormProps) => {
  const { store } = props;
  const { propertyTypesStore } = store;
  const { form } = propertyTypesStore;

  const onChange = (name, value, index) => {
    const newMFProperties = [...form.fields.mfProperties.value];
    newMFProperties[index][name] = value;
    propertyTypesStore.onFieldChange('mfProperties', newMFProperties);
  };

  const renderMFPropertiesDetails = () => {
    return form.fields.mfProperties.value.map((propertyDetail, index) => (
      <Grid container spacing={2} key={`mfPropertyDetail-${index}`}>
        <Grid item xs={5} md={2} lg={2}>
          <Typography>Property #{index + 1}</Typography>
        </Grid>
        <Grid item xs={7} md={3} lg={3}>
          <NumberFormat
            customInput={TextField}
            required={true}
            variant="outlined"
            label=""
            testId="numberOfUnits"
            value={propertyDetail.numberOfUnits}
            onValueChange={values =>
              onChange('numberOfUnits', values?.floatValue, index)
            }
            helperText={form.fields.mfProperties.error}
            standaloneLabel
            fullWidth
          />
        </Grid>
      </Grid>
    ));
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5" color="primary">
          Details for each Property
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={5} md={2} lg={2} />
          <Grid item xs={7} md={3} lg={3}>
            <Typography>Number of Units</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {renderMFPropertiesDetails()}
      </Grid>
    </Grid>
  );
};

const MUPropertyDetailsForm = (props: PropertyTypesFormProps) => {
  const { store } = props;
  const { propertyTypesStore } = store;
  const { form } = propertyTypesStore;

  const onChange = (name, value, index) => {
    const newMUProperties = [...form.fields.muProperties.value];
    newMUProperties[index][name] = value;
    propertyTypesStore.onFieldChange('muProperties', newMUProperties);
  };

  const renderMUPropertiesDetails = () => {
    return form.fields.muProperties.value.map((propertyDetail, index) => (
      <Grid container spacing={2} key={`muPropertyDetail-${index}`}>
        <Grid item xs={12} md={1} lg={1}>
          <Typography>Property #{index + 1}</Typography>
        </Grid>
        <Grid item xs={2} md={1} lg={1}>
          <NumberFormat
            customInput={TextField}
            required={true}
            variant="outlined"
            label=""
            testId="residentialUnits"
            value={propertyDetail.residentialUnits}
            onValueChange={values =>
              onChange('residentialUnits', values?.floatValue, index)
            }
            helperText={form.fields.muProperties.error}
            standaloneLabel
            fullWidth
          />
        </Grid>
        <Grid item xs={2} md={1} lg={1}>
          <NumberFormat
            customInput={TextField}
            required={true}
            variant="outlined"
            label=""
            testId="residentialUnitsSqFtArea"
            value={propertyDetail.residentialUnitsSqFtArea}
            onValueChange={values =>
              onChange('residentialUnitsSqFtArea', values?.floatValue, index)
            }
            standaloneLabel
            fullWidth
          />
        </Grid>
        <Grid item xs={2} md={1} lg={1}>
          <NumberFormat
            customInput={TextField}
            required={true}
            variant="outlined"
            label=""
            testId="commercialUnits"
            value={propertyDetail.commercialUnits}
            onValueChange={values =>
              onChange('commercialUnits', values?.floatValue, index)
            }
            standaloneLabel
            fullWidth
          />
        </Grid>
        <Grid item xs={2} md={1} lg={1}>
          <NumberFormat
            customInput={TextField}
            required={true}
            variant="outlined"
            label=""
            testId="commercialUnitsSqFtArea"
            value={propertyDetail.commercialUnitsSqFtArea}
            onValueChange={values =>
              onChange('commercialUnitsSqFtArea', values?.floatValue, index)
            }
            standaloneLabel
            fullWidth
          />
        </Grid>
        <Grid item xs={2} md={1} lg={1}>
          <NumberFormat
            customInput={TextField}
            required={true}
            variant="outlined"
            label=""
            testId="residentialIncome"
            value={propertyDetail.residentialIncome}
            onValueChange={values =>
              onChange('residentialIncome', values?.floatValue, index)
            }
            standaloneLabel
            fullWidth
          />
        </Grid>
        <Grid item xs={2} md={1} lg={1}>
          <NumberFormat
            customInput={TextField}
            required={true}
            variant="outlined"
            label=""
            testId="commercialIncome"
            value={propertyDetail.commercialIncome}
            onValueChange={values =>
              onChange('commercialIncome', values?.floatValue, index)
            }
            standaloneLabel
            fullWidth
          />
        </Grid>
        <Grid item xs={4} md={3} lg={3}>
          <RadioField
            standaloneLabel
            label=""
            value={propertyDetail.commercialUnitsCurrentOnRent}
            name={'commercialUnitsCurrentOnRent'}
            options={yesNoOptions}
            onChange={value =>
              onChange('commercialUnitsCurrentOnRent', value, index)
            }
            fullWidth
            required
            error={isNotBlank(propertyDetail.commercialUnitsCurrentOnRent)}
            row
            testId="commercialUnitsCurrentOnRent"
          />
        </Grid>
      </Grid>
    ));
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5" color="primary">
          Details for each Property
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={1} lg={1} />
          <Grid item xs={12} md={1} lg={1}>
            <Typography>Residential Units</Typography>
          </Grid>
          <Grid item xs={12} md={1} lg={1}>
            <Typography>Residential Units Area</Typography>
          </Grid>
          <Grid item xs={12} md={1} lg={1}>
            <Typography>Commercial Units</Typography>
          </Grid>
          <Grid item xs={12} md={1} lg={1}>
            <Typography>Commercial Units Area</Typography>
          </Grid>
          <Grid item xs={12} md={1} lg={1}>
            <Typography>Residential Income</Typography>
          </Grid>
          <Grid item xs={12} md={1} lg={1}>
            <Typography>Commercial Income</Typography>
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <Typography>
              Are commercial units current on rent for the most recent 3 months?
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {renderMUPropertiesDetails()}
      </Grid>
    </Grid>
  );
};

export default observer(PropertyTypesForm);
