import {
  Box,
  Chip,
  Grid,
  ListItemIcon,
  ListItemText,
  Menu,
  Tooltip,
  Typography,
} from '@material-ui/core';
import {
  Delete,
  Edit,
  NavigateNext,
  Visibility,
  Replay,
  Close,
  MessageOutlined,
} from '@material-ui/icons';
import {
  DataGrid,
  GridColumnProps,
  LinkColumn,
  Button,
  FilterButton,
  Toolbar,
  ConfirmDialog,
  IconButton,
  MenuItem,
} from '@roc/ui';
import { Layout } from '@roc/feature-app-core';
import { formatDate, insertIf, PORTAL_COLORS } from '@roc/feature-utils';
import { formatCurrency } from '@roc/ui/utils';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { MoreVert, CheckCircleOutline } from '@material-ui/icons';
import { StatusChip } from '@roc/ui';
import { StatusType } from '@roc/ui';
import TopicModal from './components/topicsComponents/topicModal';
import { FilterType } from '@roc/feature-communication';
import {
  useFixFlipStore,
  useLoanSubmissionRoutes,
} from '@roc/feature-loans';
import { useProposalsStore } from './hooks/useProposalsStore';
import { useProposalsRoutes } from './hooks/useProprosalsRoutes';
import { proposalStatus } from './utils/constants';
import { ChatOutlined } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';

import {
  useCommunicationRoutesConfig, useLoanCommunicationStore,
} from '@roc/feature-communication';
import { Link as RouterLink } from 'react-router-dom';
import { StandardDialog } from '@roc/ui';
import { ChatBubbleOutlineOutlined } from '@material-ui/icons';
import { DealRoomTopicConversation } from '@roc/feature-communication';
import { MyChatContainer, SelectConversation } from '@roc/feature-communication';
import { LoanSubType } from '@roc/feature-utils';

const noOpComparator = () => 0;

const filter = {
  filter: 'agTextColumnFilter',
  floatingFilter: true,
  floatingFilterComponentParams: {
    suppressFilterButton: true,
  },
};

const currentPath = window.location.pathname;

const columns: GridColumnProps[] = [
  {
    field: 'dealName',
    headerName: 'Topics',
    minWidth: 225,
    cellRenderer: 'addressCellRenderer',
    ...filter,
  },
  {
    ...filter,
    field: 'status',
    headerName: 'Status',
    minWidth: 150,
    cellRenderer: 'statusCellRenderer',
    comparator: noOpComparator,
  },
  ...insertIf(currentPath.includes("/backoffice/"), [
    {
      field: 'lender',
      headerName: 'Originator',
      maxWidth: 175,
      ...filter,
    }
  ]),
  {
    field: 'loanSubtype',
    headerName: 'Loan Subtype',
    minWidth: 100,
    ...filter,
  },
  {
    field: 'loanAmount',
    headerName: 'Amount',
    minWidth: 180,
    cellRenderer: 'currencyCellRenderer',
    ...filter,
  },
  {
    ...filter,
    field: 'creation',
    headerName: 'Creation Date',
    minWidth: 180,
    cellRenderer: 'dateCellRenderer',
    filter: 'agDateColumnFilter',
  },
  {
    ...filter,
    field: 'lastMessageDate',
    headerName: 'Last Message Date',
    minWidth: 180,
    cellRenderer: 'dateCellRenderer',
    filter: 'agDateColumnFilter',
  },
  {
    ...filter,
    field: 'lastMessageFrom',
    headerName: 'Responded by',
    minWidth: 180,
  },
  {
    ...filter,
    field: 'unreadMessages',
    headerName: 'Messages',
    maxWidth: 175,
    cellRenderer: 'unreadMessagesCellRenderer',
  },
  {
    headerName: 'Action',
    maxWidth: 100,
    cellRenderer: 'actionCellRenderer',
    comparator: noOpComparator,
    width: 80,
    pinned: 'right',
    lockPinned: true
  },
];


const useStyles = makeStyles(theme => ({
  topic: {
    display: 'flex',
    justifyContent: 'space-beetween'
  },
  button: {
    height: '32px',
    marginRight: '41px',
    alignSelf: 'center'
  },
  chatContainer: {
    flex: 1,
  },

}));


export const Proposals = observer(() => {
  const history = useHistory();
  const { proposalsRoutesConfig } = useProposalsRoutes();
  const { loanCommunicationStore } = useLoanCommunicationStore();
  const {
    proposalsStore,
    proposalsDetailsStore,
    proposalsStore: { proposalsGridStore },
  } = useProposalsStore();
  const { proposalTopicsStore } = proposalsStore;
  const { dealRoomTopicCommunicationStore } = proposalTopicsStore;
  const { loanSubmissionRoutesConfig } = useLoanSubmissionRoutes();
  const { fixFlipStore } = useFixFlipStore();
  const [isConfirmOpen, setIsConfirmOpen] = useState(null);
  const [isRetractModalOpen, setIsRetractModalOpen] = useState(null);
  const [showMenuItems, setShowMenuItems] = useState(false);
  const [proposalId, setProposalId] = useState("");
  const [status, setStatus] = useState("");
  const [showView, setShowView] = useState(false);
  const [showContinue, setShowContinue] = useState(false);
  const [showAppeal, setShowAppeal] = useState(false);
  const [showRemove, setShowRemove] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showConversationTopic, setShowConversationTopic] = useState(false);
  const [showDecline, setShowDecline] = useState(false);
  const style = useStyles();
  const [isOptionsModalOpen, setIsOptionsModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const params = new URLSearchParams(location.search);
  const [openDealConversation, setOpenDealConversation] = useState(false);
  const [loanId, setLoanId] = useState(0);
  const [showMarkProposalAsCompleted, setShowMarkProposalAsCompleted] = useState(false);


  const editSizer = proposalId => {
    const url = proposalsRoutesConfig.proposalDetails(proposalId).url;
    history.push(url);
  };

  const navigateToLoanSubmissionFlow = proposalId => {
    fixFlipStore.setProposalId(proposalId);

    const loanSubtype = proposalsStore.proposalsGridStore.gridData.data.rows.find(proposal => proposal.proposalId === proposalId)?.loanSubtype;
    if (loanSubtype === LoanSubType.GROUND_UP) {
      fixFlipStore.setLoanType(LoanSubType.GROUND_UP);
      history.push(loanSubmissionRoutesConfig.submitloan.children.groundUp.url);
    } else if (loanSubtype === LoanSubType.FIX_AND_FLIP) {
      fixFlipStore.setLoanType(LoanSubType.FIX_AND_FLIP);
      history.push(loanSubmissionRoutesConfig.submitloan.children.fixFlip.url);
    } else if (loanSubtype === LoanSubType.FIX_AND_FLIP_PRO) {
      fixFlipStore.setLoanType(LoanSubType.FIX_AND_FLIP_PRO);
      history.push(loanSubmissionRoutesConfig.submitloan.children.fixFlipPro.url);
    }
  };

  const createProposal = (loanSubtype: string) => {
    history.push(proposalsRoutesConfig.submitProposal.url, { loanSubtype });
  };

  const viewProposal = id => {
    const url = proposalsRoutesConfig.viewProposal(id).url;
    history.push(url);
  };

  const documentUpload = id => {
    const url = proposalsRoutesConfig.documentUpload(id).url;
    history.push(url);
  };

  const reappealProposal = async id => {
    const isAbleToAppeal = await proposalsStore.getIsAbleToAppeal();
    if (isAbleToAppeal) {
      const url = proposalsRoutesConfig.proposalDetails(id).url;
      history.push(url);
    }
  };

  const handleTopicModal = () => {
    setIsOptionsModalOpen(true);
    proposalTopicsStore.resetForm();
  };

  const closeTopicModal = () => {
    setIsOptionsModalOpen(false);
    proposalsGridStore.resetAndFetchGridData();
    proposalTopicsStore.resetForm();
  };


  const handleMenuClose = () => {
    setAnchorEl(null);
    setLoanId(null);
  };

  useEffect(() => {
    const conversationSid = params.get('openConversation');
    if (conversationSid) {
      dealRoomTopicCommunicationStore.setCurrentConversationId(conversationSid)
      dealRoomTopicCommunicationStore.setModalOpen(true)
    }
    proposalsStore.proposalsGridStore.resetAndFetchGridData();
  }, []);


  const toolbar = (
    <Toolbar>
      <Box mr={2}>
        <FilterButton
          onToggleFilters={() =>
            proposalsStore.proposalsGridStore.toggleFilters()
          }
          onClearFilters={() =>
            proposalsStore.proposalsGridStore.resetFilters()
          }
        />
      </Box>
    </Toolbar>
  );

  const handleOpenTopicConversation = (proposalTopicId) => {
    dealRoomTopicCommunicationStore.setModalOpen(true)
    proposalTopicsStore.setProposalTopicId(proposalTopicId)
  }

  const frameworkComponents = {
    unreadMessagesCellRenderer(params) {
      const proposalId = params.node.data?.proposalId;
      const unread = params.node.data?.unreadMessages;
      const topic = params.node.data?.topic;
      const noDraft = params.node.data?.status != proposalStatus.DRAFT;
      return (
        <Box display="flex" justifyContent="center" alignItems="center">
          {noDraft && (<>
            <IconButton
              color='primary'
              testId={'messageButtonIcon'}
              onClick={topic ? () => handleOpenTopicConversation(proposalId) : () => { setProposalId(proposalId); setOpenDealConversation(true) }}>
              <MessageOutlined />
            </IconButton>
            {unread}
          </>)
          }
        </Box>
      );
    },
    addressCellRenderer(params) {
      const proposalId = params.node.data?.proposalId;
      const isDraft = params.node.data?.status == proposalStatus.DRAFT;
      const isTopic = params.node.data?.topic;
      if (!isTopic) {
        return LinkColumn({
          ...params,
          url: isDraft ? proposalsRoutesConfig.proposalDetails(proposalId).url : proposalsRoutesConfig.viewProposal(proposalId).url,
        });
      } else if (isTopic) {
        return LinkColumn({
          ...params,
          onClick: () => handleOpenTopicConversation(proposalId),
        });
      }
    },
    currencyCellRenderer(params) {
      return formatCurrency(params.value);
    },
    dateCellRenderer(params) {
      return params.value ? formatDate(params.value, 'MM/dd/yyyy') : null;
    },
    actionCellRenderer(params) {
      return (
        <>
          <IconButton
            testId={'proposalActions'}
            onClick={e => openMenu(e, params.node.data)}>
            <MoreVert />
          </IconButton>
        </>
      );
    },
    statusCellRenderer: ({ value }) =>
      value ? (
        <StatusChip statusType={StatusType.PROPOSAL} label={value} size='small' />
      ) : null,
  };

  const openMenu = (event: React.MouseEvent<HTMLElement>, record) => {
    const { status, proposalId, topic } = record;
    setLoanId(proposalId);
    setProposalId(proposalId);
    setStatus(status);
    initializeMenuItems();
    if (!topic) {
      switch (status) {
        case proposalStatus.NEW:
        case proposalStatus.PENDING:
          setShowView(true);
          setShowMarkProposalAsCompleted(true);
          break;
        case proposalStatus.COMPLETED:
          setShowView(true);
          setShowMarkProposalAsCompleted(false);
          break;
        case proposalStatus.AGREED:
          setShowView(true);
          setShowContinue(true);
          setShowMarkProposalAsCompleted(true);
          break;
        case proposalStatus.DRAFT:
          setShowEdit(true);
          setShowRemove(true);
          setShowMarkProposalAsCompleted(true);
          break;
      }
    } else {
      setShowConversationTopic(true)
    }
    setShowMenuItems(true);
    setAnchorEl(event.currentTarget);
  }

  const initializeMenuItems = () => {
    setShowView(false);
    setShowContinue(false);
    setShowAppeal(false);
    setShowDecline(false);
    setShowEdit(false);
    setShowRemove(false);
    setShowConversationTopic(false);
  }

  const onFilterChanged = params => {
    const filterModel = params.api.getFilterModel();
    proposalsStore.proposalsGridStore.setFilterModel(filterModel);
  };

  const onSortChanged = params => {
    const sortModel = params.api.getSortModel();
    proposalsStore.proposalsGridStore.setSortModel(sortModel);
  };

  return (
    <Layout>
      <Box display={'flex'} justifyContent='space-between' >
      </Box>
      <Grid
        container
        direction="column"
        spacing={2}
        style={{ display: 'flex', flexGrow: 1, flexDirection: 'column', marginTop: '20px' }}
      >
        <Grid
          item
          style={{ display: 'flex', flexGrow: 1, flexDirection: 'column' }}
        >
          <DataGrid
            columns={columns}
            rows={proposalsStore.proposalsGridStore.gridData.data.rows}
            frameworkComponents={frameworkComponents}
            toolbar={toolbar}
            onSortChanged={onSortChanged}
            onFilterChanged={onFilterChanged}
            isLoading={proposalsStore.proposalsGridStore.isLoading}
            showFilters={proposalsStore.proposalsGridStore.showFilters}
            sortModel={proposalsStore.proposalsGridStore.sortModel}
            filterModel={proposalsStore.proposalsGridStore.filterModel}
            paginationData={proposalsStore.proposalsGridStore.gridData.meta}
            setPageNumber={proposalsStore.proposalsGridStore.setPageNumber}
          />
          <ConfirmDialog
            open={isConfirmOpen}
            title={`Remove Proposal`}
            body={`Are you sure you want to remove this proposal?`}
            handleCancel={() => setIsConfirmOpen(null)}
            handleClose={() => setIsConfirmOpen(null)}
            handleConfirm={() => {
              proposalsStore.deleteProposal(isConfirmOpen);
              setIsConfirmOpen(null);
            }}
            confirmButtonText={'Confirm'}
          />
          {showMenuItems &&
            <Menu
              id="menu-appbar"
              getContentAnchorEl={null}
              anchorEl={anchorEl}
              keepMounted
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left', }}
              transformOrigin={{ vertical: 'top', horizontal: 'left', }}
              open={menuOpen}
              onClose={handleMenuClose}>
              {showView &&
                <MenuItem
                  testId='view-item'
                  onClick={() => {
                    viewProposal(proposalId);
                    handleMenuClose();
                  }}
                >
                  <ListItemIcon>
                    <Visibility />
                  </ListItemIcon>
                  <ListItemText>View Submission</ListItemText>
                </MenuItem>
              }
              {showContinue &&
                <MenuItem
                  testId='continue-item'
                  onClick={() => {
                    navigateToLoanSubmissionFlow(proposalId);
                    handleMenuClose();
                  }}>
                  <ListItemIcon>
                    <NavigateNext />
                  </ListItemIcon>
                  <ListItemText>Continue</ListItemText>
                </MenuItem>
              }
              {showAppeal &&
                <MenuItem
                  testId='appeal-item'
                  onClick={() => {
                    reappealProposal(proposalId);
                    handleMenuClose();
                  }}>
                  <ListItemIcon>
                    <Replay />
                  </ListItemIcon>
                  <ListItemText>Appeal</ListItemText>
                </MenuItem>
              }
              {showEdit &&
                <MenuItem
                  testId='edit-item'
                  onClick={() => {
                    editSizer(proposalId);
                    handleMenuClose();
                  }}>
                  <ListItemIcon>
                    <Edit />
                  </ListItemIcon>
                  <ListItemText>Edit</ListItemText>
                </MenuItem>
              }
              {showRemove &&
                <MenuItem
                  testId='remove-item'
                  onClick={() => {
                    setIsConfirmOpen(proposalId);
                    handleMenuClose();
                  }}>
                  <ListItemIcon>
                    <Delete />
                  </ListItemIcon>
                  <ListItemText>Remove Proposal</ListItemText>
                </MenuItem>
              }
              {showConversationTopic &&
                <MenuItem
                  testId='edit-item'
                  onClick={() => {
                    handleOpenTopicConversation(proposalId);
                    handleMenuClose();
                  }}>
                  <ListItemIcon>
                    <MessageOutlined />
                  </ListItemIcon>
                  <ListItemText>Open Conversation</ListItemText>
                </MenuItem>
              }
              {showMarkProposalAsCompleted &&
                <MenuItem
                  testId='mark-completed-proposal'
                  onClick={async () => {
                    await fixFlipStore.markProposalAsCompleted(loanId);
                    proposalsGridStore.resetAndFetchGridData();
                    handleMenuClose();
                  }}>
                  <ListItemIcon>
                    <CheckCircleOutline />
                  </ListItemIcon>
                  <ListItemText>Mark Proposal as Completed</ListItemText>
                </MenuItem>
              }
            </Menu>
          }
        </Grid>
      </Grid>
      <StandardDialog
        open={isOptionsModalOpen}
        title={'Ask The Deal Room'}
        handleClose={closeTopicModal}
        dialogContent={<TopicModal createProposal={createProposal} onClose={closeTopicModal} />}
      />
      <StandardDialog
        open={dealRoomTopicCommunicationStore?.openModal}
        maxWidth="lg"
        handleClose={() => {
          closeTopicModal();
          handleMenuClose();
          dealRoomTopicCommunicationStore.setModalOpen(false)
        }}
        dialogContent={
          <>
            <DealRoomTopicConversation proposalTopicId={proposalTopicsStore?.proposalTopicId} conversationSid={proposalTopicsStore?.dealRoomTopicCommunicationStore?.currentConversationSid} ></DealRoomTopicConversation>
          </>
        }
      />
      <StandardDialog
        open={openDealConversation}
        maxWidth="lg"
        handleClose={() => {
          handleMenuClose();
          setOpenDealConversation(false);
        }}
        dialogContent={
          <Box display="flex" height={'80vh'}>
            <MyChatContainer
              id={Number(proposalId)}
              communicationStore={loanCommunicationStore}
              allowNewConversations={false}
              allowJoinConversation={true}
              showEmailAddress={false}
              showSnippet={false}
              className={style.chatContainer}
              allowRemoveParticipants={true}
              allowManageParticipants={true}
              filterType={FilterType.LOAN_ID}
              allowAddInternalParticipants={true}
              onConversationSelect={conversationId => {
                loanCommunicationStore?.setCurrentConversationId(conversationId)
              }
              }
            />
            <SelectConversation communicationStore={loanCommunicationStore} />
          </Box>
        }
      />
    </Layout>
  );
});

export default Proposals;