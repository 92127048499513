import { GlobalStore } from "@roc/feature-app-core";
import { action, makeObservable, observable } from "mobx";
import { SpecialServicingBaseStore } from "./specialServicingBaseStore";
import { CounterPartyExpensesTab } from "../types/types";
import { PayablesDashboardStore } from "./payablesDashboardStore";
import { ReceivablesDashboardStore } from "./receivablesDashboardStore";
import { ProtectiveAdvanceFormStore } from "./protectiveAdvanceFormStore";

export class LoanDashboardStore extends SpecialServicingBaseStore {
  payablesDashboardStore: PayablesDashboardStore;
  protectiveAdvanceFormStore: ProtectiveAdvanceFormStore;

  constructor(globalStore: GlobalStore) {
    super(globalStore);
    this.protectiveAdvanceFormStore = new ProtectiveAdvanceFormStore(globalStore, this.service);
    this.payablesDashboardStore = new PayablesDashboardStore(globalStore);

    this.tab = CounterPartyExpensesTab.LOAN_PAYABLE;

    makeObservable(this, {
      protectiveAdvanceFormStore: observable,
      payablesDashboardStore: observable,
    });
  };

  fetchEntityNames() {
    throw new Error('Method not implemented.');
  }

  reset() {
    this.tab = CounterPartyExpensesTab.LOAN_PAYABLE;
    this.counterPartyExpenseGridStore.reset();
    this.recordsIds = [];
  };

  performAction(id: number, status: string) {
    throw Error('Not Implemented!');
  }
  performActions(status: string) {
    throw Error('Not Implemented!');
  }
}