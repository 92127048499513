import { Service } from '@roc/feature-app-core';
import { isNil } from '@roc/feature-utils';

const url = {
  GET_CUSTOM_DASHBOARDS: '/api/v1/lender/customDashboards/getCustomDashboards',
  GET_CUSTOM_DASHBOARD_DATA_BY_ID:
    '/api/v1/lender/customDashboards/getCustomDashboardDataById',
  GET_FILTER_CRITERIA_OPTIONS:
    '/api/v1/lender/dashboardBuilder/getFilterCriteriaOptions',
  SAVE_DASHBOARD_LIST: '/api/v1/lender/dashboardBuilder/saveDashboardList',
  GET_FILTER_FIELDS: '/api/v1/lender/customDashboards/getFilterFields',
  GET_COLUMN_FIELDS: '/api/v1/lender/customDashboards/getColumnFields',
  GET_DASHBOARD_ACTIONS: '/api/v1/lender/customDashboards/getDashboardActions',
  SAVE_CUSTOM_DASHBOARD: '/api/v1/lender/customDashboards/saveCustomDashboard',
  DELETE_CUSTOM_DASHBOARD:
    '/api/v1/lender/customDashboards/deleteCustomDashboard',
  SHARE_CUSTOM_DASHBOARD: '/api/v1/lender/customDashboards/shareCustomDashboard',
  GET_USERS_WITH_DASHBOARD: '/api/v1/lender/customDashboards/getUsersWithDashboard',
};

const omitNilKeys = object => {
  return Object.keys(object).reduce((newObject, key) => {
    if (!isNil(object[key])) {
      newObject[key] = object[key];
    }
    return newObject;
  }, {});
};

export class LeadsDashboardService extends Service {
  getCustomDashboards() {
    return this.get(url.GET_CUSTOM_DASHBOARDS);
  }

  getCustomDashboardDataById(
    customDashboardData,
    page: number,
    pageSize: number,
    sortDir: string,
    sortBy: string,
    filters: string,
    dropdownFilters: string,
    businessUnit: string
  ) {
    const params = new URLSearchParams(
      omitNilKeys({
        page,
        pageSize,
        sortDir,
        sortBy,
        filters: JSON.stringify(filters),
        dropdownFilters: JSON.stringify(dropdownFilters),
        businessUnit
      })
    );

    params.forEach((val, key) => {
      if (val === undefined) params.delete(key);
    });

    return this.post(
      `${url.GET_CUSTOM_DASHBOARD_DATA_BY_ID}?${params}`,
      customDashboardData
    );
  }

  getFilterCriteriaOptions() {
    return this.get(url.GET_FILTER_CRITERIA_OPTIONS);
  }

  submitNewList(body) {
    return this.post(url.SAVE_DASHBOARD_LIST, body, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  getFilterFields(objectType) {
    return this.get(url.GET_FILTER_FIELDS, { objectType: objectType.toLowerCase() });
  }

  getColumnFields(objectType) {
    return this.get(url.GET_COLUMN_FIELDS, { objectType: objectType.toLowerCase() });
  }

  saveCustomDashboard(data) {
    return this.post(url.SAVE_CUSTOM_DASHBOARD, data);
  }

  shareCustomDashboard(data) {
    return this.post(url.SHARE_CUSTOM_DASHBOARD, data);
  }

  deleteCustomDashboard(id) {
    return this.delete(url.DELETE_CUSTOM_DASHBOARD, { id });
  }

  getUsersWithDashboard(id) {
    return this.get(url.GET_USERS_WITH_DASHBOARD, { id });
  }

  getDashBoardActions(baseObject) {
    return this.get(url.GET_DASHBOARD_ACTIONS, { baseObject });
  }
}
