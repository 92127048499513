import { Page } from '@roc/feature-app-core';
import { Route } from 'react-router';
import { BorrowerDetailsFormInternal } from '../components/borrowerDetailsFormInternal';

export const getBorrowerVerificationRoutesConfig = (basePath: string, baseUrl: string) => {
  return {
    borrowerVerification: {
      path: `${basePath}/borrower-verification-form`,
      url: `${baseUrl}/borrower-verification-form`,
      documentTitle: 'Borrower Verification',
    },
  };
}

export const getBorrowerVerificationRoutes = (basePath: string, baseUrl: string, token: string) => {
  const config = getBorrowerVerificationRoutesConfig(basePath, baseUrl);
  return {
    borrowerVerification: (
      <Route exact path={config.borrowerVerification.path}>
        <Page routeDefinition={config.borrowerVerification}>
          <BorrowerDetailsFormInternal token={token} isInternal={isInternal(baseUrl)}/>
        </Page>
      </Route>
    ),
  };
};

const isInternal = (baseUrl: string) => {
  return baseUrl.includes('internal');
}
