import { Box } from '@material-ui/core';
import { useLoanStore } from '@roc/feature-loans';
import { LoanSubType, LoanType } from '@roc/feature-utils';
import { observer } from 'mobx-react';
import { LoanSummaryBridge } from '../components/bridge/loanSummaryBridge';
import { LoanSummaryMultifamilyBridge } from '../components/bridge/loanSummaryMultifamilyBridge';
import { LoanSummaryStabilizedBridge } from '../components/bridge/loanSummaryStabilizedBridge';
import { LoanSummaryTerm } from '../components/term/loanSummaryTerm';

export const LoanSummaryTab = observer(() => {
  const { loanStore } = useLoanStore();
  const { loanDetails } = loanStore;

  const loanType: LoanType = loanDetails?.loanType;
  const loanSubType: LoanSubType = loanDetails?.loanSubType;

  if (loanType === LoanType.RESIDENTIAL_BRIDGE) {
    if (loanSubType === LoanSubType.STABILIZED_BRIDGE) {
      return (
        <>
          <LoanSummaryStabilizedBridge />
        </>
      );
    } else if (loanSubType === LoanSubType.MULTIFAMILY_BRIDGE_5PLUS) {
      return (
        <>
          <LoanSummaryMultifamilyBridge />
        </>
      );
    } else if (loanSubType === LoanSubType.GROUND_UP) {
      return (
        <>
          <LoanSummaryBridge groundUp />
        </>
      );
    } else {
      return (
        <>
          <LoanSummaryBridge />
        </>
      );
    }
  }

  if (loanType === LoanType.RESIDENTIAL_TERM) {
    return (
      <>
        <LoanSummaryTerm />
      </>
    );
  }

  return null;
});
