
const prefix = 'client.portal.'

export enum SessionStorageKeys {
  selectedCompanyId = 'selectedCompanyId',
  portalId = 'portalId',
}

const setValue = (key: SessionStorageKeys, value: any) => {
  window.sessionStorage.setItem(`${prefix}${key}`, value);
};
const getValue = (key: SessionStorageKeys) => {
  return window.sessionStorage.getItem(`${prefix}${key}`);
};
const removeValue = (key: SessionStorageKeys) => {
  return window.sessionStorage.removeItem(`${prefix}${key}`);
};

export const appSessionStorage = {
  setValue,
  getValue,
  removeValue
};
