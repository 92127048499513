import { StandardDialog } from '@roc/ui';
import { IndividualProperty } from './individualProperty';
import { ComparableProperty } from '../../types/propertyTypes';
import { EditIndividualProperty } from './editIndividualProperty';
import { observer } from 'mobx-react';
import { useStore } from '@roc/client-portal-shared/hooks';

export type IndividualPropertyDialogProps = {
  open?: boolean;
  onClose?: () => void;
  property: ComparableProperty;
};

export const IndividualPropertyDialog = observer((props: IndividualPropertyDialogProps) => {
  const { open, onClose, property } = props;
  const { compToolStore } = useStore();
  return (
    <StandardDialog
      open={open}
      title={property?.is_subject ? 'Subject Property' : 'Comparable Property'}
      maxWidth="md"
      onClose={onClose}
      handleClose={onClose}
      dialogContent={
        compToolStore.editSubjectPropertyFormStore.areEditFieldsOpened ?
          <EditIndividualProperty property={property} />
          :
          <IndividualProperty property={property} />
      }
      removeContentBox={true}
    />
  );
});

export default IndividualPropertyDialog;
