import { Box, Grid } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { Button, ConfirmationButton, StandardDialog, Toolbar } from '@roc/ui';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useExpectedClosingsStore } from '../hooks/useExpectedClosingsStore';
import { ChooseLoanDialog } from './chooseLoanDialog';
import { ExpectedClosingGrid } from './expectedClosingGrid';
import { ExpectedClosingsCard } from './expectedClosingsCard';

export const TomorrowClosingDataTab = observer(() => {
  const { expectedClosingsStore } = useExpectedClosingsStore();
  const { tomorrowClosingData } = expectedClosingsStore;
  const [showPickLoanDialog, setShowPickLoanDialog] = useState(false);

  useEffect(() => {
    expectedClosingsStore.fetchTomorrowExpectedClosingLoansData();
  }, []);

  if (!tomorrowClosingData) {
    return null;
  }

  return (
    <Box py={2}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <ExpectedClosingsCard
            data={tomorrowClosingData.totalClosedForToday}
          />
        </Grid>
        <Grid item xs={12}>
          <ExpectedClosingGrid
            data={tomorrowClosingData.futureFundRequirement}
            gridStore={expectedClosingsStore.futureFundRequirementGridStore}
          />
        </Grid>
        <Grid item xs={12}>
          <ExpectedClosingGrid
            toolbar={
              <Toolbar>
                <Button
                  testId="addLoansToClosingList"
                  color="primary"
                  variant="contained"
                  size="small"
                  disabled={tomorrowClosingData.closingTomorrow.isEmailSent}
                  onClick={() => setShowPickLoanDialog(true)}
                >
                  <Add scale={'small'} />
                  Add loans to the closing list
                </Button>
              </Toolbar>
            }
            renderAction={row => (
              <ConfirmationButton
                testId="removeFromClosingList"
                color="primary"
                variant="outlined"
                size="small"
                disabled={tomorrowClosingData.closingTomorrow.isEmailSent}
                confirmDialogProps={{
                  body: `Are you sure you want to remove the loan ${row?.loanId} from tomorrow's closing list ?`,
                }}
                onClick={() => expectedClosingsStore.removeLoanFromClosing(row.loanId)}
              >
                Remove
              </ConfirmationButton>
            )}
            data={tomorrowClosingData.closingTomorrow}
            gridStore={expectedClosingsStore.closingTomorrowGridStore}
            showTotalRow
          />
        </Grid>
      </Grid>
      <ChooseLoanDialog
        open={showPickLoanDialog}
        onClose={() => setShowPickLoanDialog(false)}
      />
    </Box>
  );
});
