import { observer } from 'mobx-react';
import { useParams } from 'react-router';
import { Grid, Typography, Button, Box } from '@material-ui/core';
import { useManageNotificationsStore } from '../hooks/useManageNotificationsStore';
import image from '../assets/notificationAsset.jpeg';
import { Paper } from '@roc/ui';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { Layout, useAppConfigurations } from '@roc/feature-app-core';

export type EncodedParams = {
  encodedParams: string;
};

const ManageNotifications = observer(() => {
  const { manageNotificationsStore } = useManageNotificationsStore();
  const { encodedParams } = useParams<EncodedParams>();
  const { siteName } = useAppConfigurations();

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      paper: {
        padding: '20px 15px',
      },
      button: {
        margin: '30px 20px',
        color: 'white',
        backgroundColor: 'black',
        height: '45px'
      },
      image: {
        [theme.breakpoints.up('sm')]: {
          width: '400px',
        },
        [theme.breakpoints.up('xs')]: {
          width: '350px',
        },
      }
    })
  );
  const classes = useStyles();

  return (
    <Layout title="Off Market Listings Subscription">
      <Paper className={classes.paper}>
        <Grid md={12} container>
          <Grid item md={6} xs={12}>
            {!manageNotificationsStore.unsubscribeSuccessful ?
              <>
                <Typography style={{ padding: '0 20px' }}>You're about to unsubscribe from the {siteName} Off Market Listings. If you do, you will no longer receive {siteName}'s exclusive internal listings of Fix & Flip and Fix & Rent investment properties. Are you sure you want to continue?</Typography>
                <Button
                  size='large'
                  className={classes.button}
                  onClick={() => manageNotificationsStore.optOutFromNotifications(encodedParams)}
                >Unsubscribe</Button>
              </>
              : <>
                <Typography variant='h5' style={{ padding: '20px' }}>We’re sad to see you go, but thank you for your time</Typography>
                <Typography style={{ padding: '0 20px' }}>You are now unsubscribed from {siteName}'s Off Market Listings Subscription.</Typography>
              </>
            }
          </Grid>
          <Grid item md={6} xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
            <img className={classes.image} src={image} />
          </Grid>
        </Grid>
      </Paper>
    </Layout>)
});

export default ManageNotifications;