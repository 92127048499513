import React, { MouseEvent, ReactNode } from 'react';
import { EditableDialog } from '@roc/ui';
import { observer } from 'mobx-react';
import { BorrowerFormDialogStore } from '../stores/borrowerFormDialogStore';
import { FormStore } from '@roc/feature-app-core';

export type BorrowerFormDialogProps = {
  open: boolean;
  handleClose: (e: MouseEvent) => void;
  handleConfirm: () => void;
  store: BorrowerFormDialogStore;
  borrowerForm: (store: FormStore) => ReactNode;
};

export const BorrowerFormDialog = observer((props: BorrowerFormDialogProps) => {
  const { open, handleClose, handleConfirm, store, borrowerForm } = props;
  const { dialogState, borrowerFormStore } = store;

  const submit = () => {
    store.validate();
    if (!store.isValid) return;
    handleConfirm();
  };

  return (
    <EditableDialog
      open={open}
      dialogState={dialogState}
      title="Borrower"
      handleClose={handleClose}
      dialogContent={borrowerForm(borrowerFormStore)}
      handleAdd={submit}
      handleEdit={submit}
    />
  );
});

export default BorrowerFormDialog;
