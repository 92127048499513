import { GlobalStore } from '@roc/feature-app-core';
import { FormStore } from '@roc/feature-app-core';

const borrowerInformation = {
  fields: {
    borrowerId: {
      value: '',
      error: null,
      rule: '',
    },
    firstName: {
      value: '',
      error: null,
      rule: 'required',
      message: 'This field is required.',
    },
    lastName: {
      value: '',
      error: null,
      rule: 'required',
      message: 'This field is required.',
    },
    emailAddress: {
      value: '',
      error: null,
      rule: 'required|email',
      attribute: 'email address',
    },
    cellPhone: {
      value: '',
      error: null,
      rule: 'required|regex:/^\\d{3}-?\\d{3}-?\\d{4}$/|alpha_dash',
      message: 'Cellphone must be a number with the format xxx-xxx-xxxx',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export class BorrowerFormStore extends FormStore {
  constructor(globalStore: GlobalStore) {
    super({ ...borrowerInformation }, globalStore);
  }
}
