import { Box, Grid, Typography } from '@material-ui/core';
import { useBorrowerLoansStore } from '@roc/feature-borrower-loans';
import { getBorrowerPortalFullScreenDialogStyles } from '@roc/feature-borrower-portal-core';
import { useDocumentStore } from '@roc/feature-documents';
import {
  DrawDetailsShared,
  useDrawsStore,
} from '@roc/feature-draws';
import { useLoanRoutes } from '@roc/feature-loans-routes-config';
import { PDFPreview, StandardDialog } from '@roc/ui';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export const BorrowerDrawDetailsModal = observer(({ loanId, drawId }) => {

  const { drawStore } = useDrawsStore();
  const { documentStore } = useDocumentStore();
  const [open, setOpen] = useState(false);
  const { push, replace, goBack } = useHistory();
  const location = useLocation();
  const { loanRoutesConfig } = useLoanRoutes();
  const { currentDraw, drawDTO } = drawStore;
  const { drawNumber } = currentDraw || {};
  const { propertyAddress } = drawDTO || {};
  const classes = getBorrowerPortalFullScreenDialogStyles();

  useEffect(() => {
    setOpen(drawId ? true : false);
  }, [drawId]);

  const handleClose = () => {
    setOpen(false);
    drawStore.resetCurrentDraw();
    documentStore.resetDrawDocuments();
    documentStore.resetDrawComments();
    /* if (location.key !== 'default') {
      goBack();
    } else { */
    replace(loanRoutesConfig.loans(loanId).children.draws.url);
    /* } */
  }

  return (
    <StandardDialog
      className={classes.dialogRoot}
      open={open}
      removeContentBox
      title={
        <Box>
          <Typography variant="h5" gutterBottom>
            {`Loan# ${loanId} - Draw# ${drawNumber ?? ''}`}
          </Typography>
          {
            propertyAddress &&
            <Typography variant="body1">
              {propertyAddress}
            </Typography>
          }
        </Box>
      }
      fullScreen
      handleClose={handleClose}
      dialogContent={
        <BorrowerDrawDetailsV2 loanId={loanId} drawId={drawId} />
      }
    />
  )
});

export const BorrowerDrawDetailsV2 = observer(({ loanId, drawId }) => {
  const { borrowerLoansStore } = useBorrowerLoansStore();
  const { documentStore } = useDocumentStore();

  return (
    <Box>
      <Grid container spacing={2}>
        <DrawDetailsShared loanId={loanId} drawId={drawId} adminSpecialist={borrowerLoansStore.loanDetails?.drawAdminSpecialistFullName} reviewSpecialist={borrowerLoansStore.loanDetails?.drawReviewSpecialistFullName} />
      </Grid>
      <PDFPreview
        open={documentStore.documentPreview != null}
        title={documentStore.documentPreview?.title}
        data={documentStore.documentPreview?.data}
        headers={documentStore.documentPreview?.headers}
        onClose={() => documentStore.closeDocumentPreview()}
      />
    </Box>
  );
});
