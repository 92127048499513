import { Grid, Box, Typography, Button } from "@material-ui/core";
import { observer } from "mobx-react";
import { useACHFormStore } from "../hooks/useACHStore";
import { DateField } from "@roc/ui";
import { makeStyles } from '@material-ui/core/styles';
import { LoanInfo } from "./loanInfo";


const useStyles = makeStyles(theme => ({
  datePicker: {
    '& .MuiPickersCalendarHeader-switchHeader, & .MuiPickersCalendarHeader-daysHeader': {
      display: 'none'
    },
    '& .MuiPickersBasePicker-pickerView': {
      minHeight: 'auto'
    }
  }
}));

export const DateSelection = observer(() => {
  const { achFormStore } = useACHFormStore();
  const classes = useStyles();

  return (
    <Box maxHeight={450} display={'flex'} justifyContent={'center'} alignItems={'center'} flexDirection={'column'}>
      <LoanInfo achData={achFormStore.achData} />
      <Grid style={{ paddingTop: '50px' }} item xs={12} md={6}>
        <Box>
          <Typography variant="h6">Please choose a date</Typography>
        </Box>
        <Box>
          <DateField
            testId="withdrawalDay"
            inputVariant="outlined"
            standaloneLabel
            format="dd"
            value={achFormStore.achData?.withdrawalDay ? new Date(new Date().getFullYear(), new Date().getMonth(), achFormStore.achData.withdrawalDay) : null}
            onChange={date => achFormStore.setWithdrawalDay(date.getDate())}
            fullWidth
            minDate={new Date(new Date().getFullYear(), new Date().getMonth(), 1)}
            maxDate={new Date(new Date().getFullYear(), new Date().getMonth(), 10)}
            disableToolbar
            placeholder=""
            DialogProps={{
              className: classes.datePicker
            }}
          />
        </Box>
        <Box pt={4}>
          <Typography style={{ textAlign: 'center' }} variant="h6">This is the date on which we will automatically debit the monthly payment for the loan</Typography>
        </Box>
        <Box display={'flex'} justifyContent={'center'} pt={8}>
          <Button color={'primary'} size="large" variant={'contained'} onClick={() => achFormStore.updateACHData()}>Next</Button>
        </Box>
      </Grid>
    </Box>
  );
});