import { FormStore, GlobalStore } from "@roc/feature-app-core";
import { action, makeObservable } from "mobx";

const dqDataForm = {
  fields: {
    tempId: {
      value: '',
      error: null,
      rule: 'required',
      message: 'Required',
    },
    drawsDqDataId: {
      value: '',
      error: null,
      rule: 'required',
      message: 'Required',
    },
    loanId: {
      value: '',
      error: null,
      rule: 'required',
      message: 'Required',
    },
    drawId: {
      value: '',
      error: null,
      rule: 'required',
      message: 'Required',
    },
    address: {
      value: '',
      error: null,
      rule: 'required',
      message: 'Required',
    },
    dqAmount: {
      value: null,
      error: null,
      rule: 'required',
      message: 'Required',
    },
    appliedDqAmount: {
      value: null,
      error: null,
      rule: 'required',
      message: 'Required',
    },
    comments: {
      value: '',
      error: null,
      rule: 'required',
      message: 'Required',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export class EditDqDataStore extends FormStore {
  private globalStore: GlobalStore;

  constructor(globalStore) {
    super({ ...dqDataForm }, globalStore);
    this.globalStore = globalStore;
    makeObservable(this, {
      loadDqData: action,
    });
    this.initialize();
  }

  initialize() { }

  loadDqData(dqData) {
    this.loadForm(dqData)
  }

}

export default EditDqDataStore;