import { Box } from '@material-ui/core';
import { CurrencyFormatter } from '@roc/ui';
import { formatCurrency } from '@roc/ui/utils';
import { green, red } from '@material-ui/core/colors';
import { Theme, alpha, makeStyles, useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  green: {
    color: green[500],
  },
  red: {
    color: red[500],
  },
}));

export const ExpectedClosingsValueCell = ({ type, value }) => {
  if (type === 'dollarWithDecimal') {
    return <CurrencyFormatter amount={formatCurrency(value, 2)} />;
  }
  return value;
};
