import { FormStore, GlobalStore } from '@roc/feature-app-core';
import {
  DateFormat,
  formatDate,
  GENERIC_ERROR_MESSAGE, parseDate
} from '@roc/feature-utils';
import { DialogState } from '@roc/ui';
import { flow, makeObservable, observable, override } from 'mobx';
import { LeadService } from '../../services/leadService';
import { LeadDetailsStore } from './leadDetailsStore';
import { LEAD_OBJECT_TYPE } from '../../components/leads/constants/constants';

const form = {
  fields: {
    activityType: {
      value: '',
      error: null,
      rule: 'required',
    },
    activityTime: {
      value: '',
      error: null,
      rule: 'required',
    },
    text: {
      value: '',
      error: null,
      rule: '',
    },
    emailContent: {
      value: '',
      error: null,
      rule: '',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export class EditLeadActivityStore extends FormStore {
  private globalStore: GlobalStore;
  protected leadDetailsStore: LeadDetailsStore;
  protected leadService: LeadService;

  dialogState: DialogState;
  leadActivity: any;

  constructor(globalStore: GlobalStore, leadDetailsStore: LeadDetailsStore) {
    super(form, globalStore);
    this.globalStore = globalStore;
    this.leadDetailsStore = leadDetailsStore;
    this.leadService = new LeadService();

    makeObservable(this, {
      dialogState: observable,
      leadActivity: observable,
      reset: override,
      openAddLeadActivity: flow,
      openAddLeadActivityFromDashboard: flow,
      openEditLeadActivity: flow,
      closeLeadActivityDialog: flow,
      saveLeadActivity: flow,
      removeLeadActivity: flow,
    });
  }

  reset() {
    super.reset();
    this.leadActivity = null;
  }

  *openEditLeadActivity(leadActivity) {
    this.reset();
    this.leadActivity = leadActivity;
    this.loadForm({
      ...leadActivity,
      activityTime: parseDate(leadActivity.sentDate)
    });
    this.dialogState = DialogState.EDIT;
  }

  *openAddLeadActivity() {
    this.reset();
    this.initializeLeadActivity(this.leadDetailsStore.objectId, this.leadDetailsStore.type);
    this.loadForm({
      activityTime: new Date(),
    });
    this.dialogState = DialogState.ADD;
  }

  *openAddLeadActivityFromDashboard(objectId: number, type: string) {
    this.reset();
    this.initializeLeadActivity(objectId, type);
    this.loadForm({
      activityTime: new Date(),
    });
    this.dialogState = DialogState.ADD;
  }

  *closeLeadActivityDialog() {
    this.dialogState = null;
  }

  *saveLeadActivity(businessUnit: string) {
    try {
      if (!this.runFormValidationWithMessage()) {
        return;
      }
      const values = this.getFormValues();
      const leadActivity = {
        ...this.leadActivity,
        activityType: values.activityType,
        sentDate: formatDate(values.activityTime, DateFormat.MMDDYYYY),
        text: values.text,
        emailContent: values.emailContent,
      };

      yield this.leadService.saveLeadActivity(leadActivity);
      yield this.closeLeadActivityDialog();
      if (this.leadDetailsStore.objectId) {
        yield this.leadDetailsStore.fetchLeadActivities(businessUnit);
      }
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Activity saved successfully!',
      });
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE,
      });
    }
  }

  *removeLeadActivity(leadActivity, businessUnit: string) {
    try {
      yield this.leadService.deleteLeadActivity(leadActivity.leadActivityId);
      yield this.closeLeadActivityDialog();
      yield this.leadDetailsStore.fetchLeadActivities(businessUnit);
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Activity removed successfully!',
      });
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE,
      });
    }
  }

  private initializeLeadActivity(objectId: number, type: string) {
    this.leadActivity = {
      leadId: type === LEAD_OBJECT_TYPE ? objectId : null,
      contactId: type !== LEAD_OBJECT_TYPE ? objectId : null,
    };
  }
}
