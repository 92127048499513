import { Service } from '@roc/feature-app-core';

const url = {
  GET_PUBLIC_BORROWER_PRE_SCREEN_INFO: '/api/v1/loan/public/borrowerSetup/getPublicBorrowerPrescreenInfo',
  GET_PUBLIC_BORROWER_DETAILS_VERIFICATION: '/api/v1/loan/public/borrowerSetup/getPublicBorrowerDetailsVerification',
  GENERATE_PUBLIC_AU10TIX_ID_VERIFICATION: '/api/v1/loan/public/borrowerSetup/generatePublicAu10tixIdVerification',
  SAVE_PUBLIC_PERSONAL_INFORMATION: '/api/v1/loan/public/borrowerSetup/savePublicPersonalInformation',
  SAVE_PUBLIC_ID_INFORMATION: '/api/v1/loan/public/borrowerSetup/savePublicIdInformation',
  SAVE_PUBLIC_FINANCIAL_INFORMATION: '/api/v1/loan/public/borrowerSetup/savePublicFinancialInformation',
  SAVE_SOME_PUBLIC_PERSONAL_FINANCIAL_INFORMATION: '/api/v1/loan/public/borrowerSetup/saveSomePublicPersonalFinancialInformation',
  CONFIRM_PUBLIC_DOCUSIGN: '/api/v1/loan/public/borrowerSetup/confirmPublicDocusign',
  SAVE_PUBLIC_BORROWER_IDENTITY_VERIFICATION_ID: '/api/v1/loan/public/borrowerSetup/savePublicBorrowerIdentityVerificationId',
};

export class PublicBorrowerSetupToDoService extends Service {

  getBorrowerPrescreenInfo(borrowerId: number, personalGuarantor: boolean) {
    return this.get(`${url.GET_PUBLIC_BORROWER_PRE_SCREEN_INFO}`, { borrowerId, personalGuarantor });
  }

  getBorrowerDetailsVerification(requestToken: string) {
    return this.get(url.GET_PUBLIC_BORROWER_DETAILS_VERIFICATION, { requestToken });
  }

  generateAu10tixIdVerification(borrowerId: number) {
    return this.get(url.GENERATE_PUBLIC_AU10TIX_ID_VERIFICATION, { borrowerId });
  }


  savePersonalInformation(data: any) {
    return this.post(url.SAVE_PUBLIC_PERSONAL_INFORMATION, data);
  }

  saveIdInformation(data: any, driversLicense: File) {
    const borrowerDetailsForm = new Blob([JSON.stringify(data)], {
      type: 'application/json',
    });

    const formData = new FormData();
    formData.append('borrowerDetailsForm', borrowerDetailsForm);
    formData.append('driversLicense', driversLicense);

    return this.post(url.SAVE_PUBLIC_ID_INFORMATION, formData);
  }

  saveFinancialInformation(data: any) {
    return this.post(url.SAVE_PUBLIC_FINANCIAL_INFORMATION, data);
  }

  saveSomePersonalFinancialInformation(data: any) {
    return this.post(url.SAVE_SOME_PUBLIC_PERSONAL_FINANCIAL_INFORMATION, data);
  }

  confirmDocusign(token) {
    return this.post(`${url.CONFIRM_PUBLIC_DOCUSIGN}?token=${token}`, null);
  }

  saveBorrowerIdentityVerificationId(token, borrowerIdentityVerificationId) {
    const borrowerIdentityVerificationIdUrlParam = borrowerIdentityVerificationId ? `&borrowerIdentityVerificationId=${borrowerIdentityVerificationId}` : '';
    return this.post(`${url.SAVE_PUBLIC_BORROWER_IDENTITY_VERIFICATION_ID}?token=${token}${borrowerIdentityVerificationIdUrlParam}`, null);
  }
}