import { action, computed, makeObservable, observable } from 'mobx';
import { GlobalStore, GridStore } from '@roc/feature-app-core';
import { ApiResponse } from '@roc/feature-app-core';
import { BorrowersService } from '../services/borrowersService';
import { Borrower } from '../types';

export type Selectable = {
  isSelected: boolean;
};

export type SelectableBorrower = Selectable & Borrower;

export class SelectBorrowersDialogStore {
  private globalStore: GlobalStore;
  private borrowerService: BorrowersService;
  borrowersGridStore: GridStore;
  borrowersAndLeadsGridStore: GridStore;
  selectedBorrowers: SelectableBorrower[];
  private getSelectedBorrowers: () => SelectableBorrower[];
  private pageSize = 8;
  allowMultiple: boolean;
  showLeadsTab: boolean;

  constructor(
    globalStore: GlobalStore,
    getSelectedBorrowers?: () => SelectableBorrower[]
  ) {
    this.getSelectedBorrowers = getSelectedBorrowers;
    this.globalStore = globalStore;
    this.borrowersGridStore = new GridStore(
      () => this.fetchBorrowersData(),
      () => this.processBorrowersData(),
      this.pageSize
    );
    this.borrowersAndLeadsGridStore = new GridStore(
      () => this.fetchBorrowersAndLeadsData(),
      () => this.processBorrowersAndLeadsData(),
      this.pageSize
    )
    this.borrowerService = new BorrowersService();
    this.selectedBorrowers = [];
    this.allowMultiple = true;
    makeObservable(this, {
      reset: action,
      fetchBorrowers: action,
      fetchBorrowersAndLeads: action,
      handleBorrowerSelect: action,
      resetFilters: action,
      toggleFilters: action,
      fetch: action,
      borrowersGridStore: observable,
      borrowersAndLeadsGridStore: observable,
      borrowerStore: computed,
    });
  }

  reset() {
    this.selectedBorrowers = [];
    this.borrowersGridStore.reset();
  }

  resetFilters() {
    this.globalStore.userFeatures?.displayLeads
      ? this.borrowersAndLeadsGridStore.resetFilters()
      : this.borrowersGridStore.resetFilters();
  }

  toggleFilters() {
    this.globalStore.userFeatures?.displayLeads
      ? this.borrowersAndLeadsGridStore.toggleFilters()
      : this.borrowersGridStore.toggleFilters();
  }

  setAllowMultiple(allowMultiple: boolean) {
    this.reset();
    this.allowMultiple = allowMultiple;
  }

  initSelected() {
    if (!this.getSelectedBorrowers) return;
    const selectedBorrowers = this.getSelectedBorrowers();
    this.selectedBorrowers = [...selectedBorrowers];
  }

  get borrowerStore() {
    return this.globalStore.userFeatures?.displayLeads
      ? this.borrowersAndLeadsGridStore
      : this.borrowersGridStore;
  }

  fetch() {
    this.globalStore.userFeatures?.displayLeads
      ? this.fetchBorrowersAndLeads()
      : this.fetchBorrowers();
  }

  fetchBorrowers() {
    this.borrowersGridStore.fetchGridData();
  }

  fetchBorrowersAndLeads() {
    this.borrowersAndLeadsGridStore.fetchGridData();
  }

  private async fetchBorrowersData() {
    try {
      const response: ApiResponse = await this.borrowerService.getAllBorrowers(
        this.borrowersGridStore.gridData.meta.pageNumber,
        this.borrowersGridStore.gridData.meta.pageSize,
        this.borrowersGridStore.gridData.meta.sortDir,
        this.borrowersGridStore.gridData.meta.sortBy,
        this.borrowersGridStore.gridData.meta.filters,
        this.borrowersGridStore.gridData.meta.dropdownFilters
      );
      return response;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching borrowers',
      });
    }
  }

  private async fetchBorrowersAndLeadsData() {
    try {
      const response: ApiResponse = await this.borrowerService.getAllBorrowersAndLeads(
        this.borrowersAndLeadsGridStore.gridData.meta.pageNumber,
        this.borrowersAndLeadsGridStore.gridData.meta.pageSize,
        this.borrowersAndLeadsGridStore.gridData.meta.sortDir,
        this.borrowersAndLeadsGridStore.gridData.meta.sortBy,
        this.borrowersAndLeadsGridStore.gridData.meta.filters,
        this.borrowersAndLeadsGridStore.gridData.meta.dropdownFilters
      );
      return response;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching list of borrowers',
      });
    }
  }

  private processBorrowersData() {
    this.borrowersGridStore.gridData.data.rows = this.borrowersGridStore.gridData.data.rows.map(
      x => this.processBorrower(x)
    );
  }

  private processBorrowersAndLeadsData() {
    this.borrowersAndLeadsGridStore.gridData.data.rows = this.borrowersAndLeadsGridStore.gridData.data.rows.map(
      x => this.processBorrower(x)
    );
  }

  private processBorrower(row: SelectableBorrower) {
    const index = this.findBorrowerIndex(row);
    row.isSelected = index !== -1;
    return this.setBorrowerDefaults(row);
  }

  private setBorrowerDefaults(row) {
    return {
      ...row,
      pctOwnership: row.pctOwnership ?? 0,
    };
  }

  private findBorrowerIndex(borrower: SelectableBorrower) {
    return this.selectedBorrowers.findIndex(
      selectedBorrower => selectedBorrower.borrowerId === borrower.borrowerId
    );
  }

  private findBorrower(borrower: SelectableBorrower) {
    const index = this.findBorrowerIndex(borrower);
    return index !== -1 ? this.selectedBorrowers[index] : null;
  }

  private removeOrReplaceBorrower(
    borrower: SelectableBorrower,
    replacement?: SelectableBorrower
  ) {
    const index = this.findBorrowerIndex(borrower);
    if (replacement) {
      this.selectedBorrowers.splice(index, 1, replacement);
    } else {
      this.selectedBorrowers.splice(index, 1);
    }
  }

  private addBorrowerToSelected(borrower: SelectableBorrower) {
    if (typeof borrower?.borrowerId === 'string' && borrower?.borrowerId.includes('LEAD')) {
      borrower.sendIdVerification = true;
      borrower.creditBackgroundCheck = true;
    }
    borrower.isSelected = true;
    if (!this.allowMultiple) {
      this.selectedBorrowers = [borrower];
    } else {
      const existing = this.findBorrower(borrower);
      if (existing) {
        this.removeOrReplaceBorrower(borrower, existing);
      } else {
        this.selectedBorrowers.push(borrower);
      }
    }
  }

  private removeBorrowerFromSelected(borrower: SelectableBorrower) {
    const existing = this.findBorrower(borrower);
    if (!existing) return;
    borrower.isSelected = false;
    this.removeOrReplaceBorrower(existing);
  }

  handleBorrowerSelect(borrower: SelectableBorrower, isSelected: boolean) {
    if (isSelected) {
      this.addBorrowerToSelected(borrower);
    } else {
      this.removeBorrowerFromSelected(borrower);
    }
  }
}

export default SelectBorrowersDialogStore;
