import { UserService } from './../services';
import { ApiResponse } from '@roc/client-portal-shared/services';
import { observable, flow, makeObservable } from 'mobx';
import { CreditAuthorizationState } from '../types';
import { DocumentVerificationState } from '@roc/feature-types';
import { parseAddress } from '@roc/client-portal-shared/utils';
import { GlobalStore, FormStore } from '@roc/feature-app-core';

const creditAuthorizationForm = {
  fields: {
    firstName: {
      value: '',
      error: null,
      rule: 'required',
    },
    lastName: {
      value: '',
      error: null,
      rule: 'required',
    },
    dob: {
      value: null,
      error: null,
      rule: 'required',
    },
    ssn: {
      value: '',
      error: null,
      rule: 'required|regex:/^\\d{3}-?\\d{2}-?\\d{4}$/|alpha_dash',
      message: 'SSN must be a number with the format xxx-xx-xxxx',
    },
    address: {
      value: '',
      error: null,
      rule: 'required',
    },
    streetNumber: {
      value: '',
      error: null,
      rule: '',
    },
    streetName: {
      value: '',
      error: null,
      rule: '',
    },
    aptNumber: {
      value: '',
      error: null,
      rule: '',
    },
    city: {
      value: '',
      error: null,
      rule: 'required',
    },
    state: {
      value: '',
      error: null,
      rule: 'required',
    },
    zipcode: {
      value: '',
      error: null,
      rule: 'required',
    },
    netWorth: {
      value: '',
      error: null,
      rule: 'required',
    },
    experience: {
      value: '',
      error: null,
      rule: 'required',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export class CreditAuthorizationStore extends FormStore {
  private globalStore: GlobalStore;
  private userService: UserService;
  creditAuthorizationState: CreditAuthorizationState;
  documentVerificationState: DocumentVerificationState;
  docusignUrl: string;
  docusignError: boolean;
  activeStep: number;

  constructor(globalStore) {
    super({ ...creditAuthorizationForm }, globalStore);
    this.globalStore = globalStore;
    this.userService = new UserService();
    makeObservable(this, {
      creditAuthorizationState: observable,
      documentVerificationState: observable,
      setCreditAuthorizationState: flow,
      setDocumentVerificationState: flow,
      docusignUrl: observable,
      docusignError: observable,
      activeStep: observable,
      resetStore: flow,
      goNextStep: flow,
      goPrevStep: flow,
      submitFormToDocusign: flow,
    });
    this.setDefaults();
  }

  private setDefaults() {
    this.activeStep = 0;
    this.creditAuthorizationState = CreditAuthorizationState.IN_PROGRESS;
    this.documentVerificationState = DocumentVerificationState.NOT_STARTED;
    this.docusignError = false;
  }

  *resetStore() {
    super.reset();
    this.setDefaults();
  }

  *goPrevStep() {
    this.activeStep--;
  }

  *goNextStep() {
    //this.runFormValidation();
    //if (this.form.meta.isValid) {
    this.activeStep++;
    //}
  }

  saveAddressField(address: string, addressDetails, geometryDetails) {
    const parsedAddress = parseAddress(
      address,
      addressDetails,
      geometryDetails
    );
    this.onFieldChange('address', address);
    this.onFieldChange('streetNumber', parsedAddress.street_number);
    this.onFieldChange('streetName', parsedAddress.street);
    this.onFieldChange('city', parsedAddress.city);
    this.onFieldChange('state', parsedAddress.state);
    this.onFieldChange('zipcode', parsedAddress.zip);
  }

  *submitFormToDocusign() {
    try {
      const request = {
        signerObjectList: [
          {
            index: 0,
            role: 'Borrower',
            name:
              this.form.fields.firstName.value +
              ' ' +
              this.form.fields.lastName.value,
            email: 'firstname.lastname@abc.com',
          },
        ],
        tabs: [
          {
            type: 'TEXT',
            label: 'Name',
            value:
              this.form.fields.firstName.value +
              ' ' +
              this.form.fields.lastName.value,
          },
          {
            type: 'TEXT',
            label: 'Address',
            value:
              this.form.fields.streetNumber.value +
              ' ' +
              this.form.fields.streetName.value,
          },
          {
            type: 'TEXT',
            label: 'City',
            value: this.form.fields.city.value,
          },
          {
            type: 'TEXT',
            label: 'NetWorth',
            value: String(this.form.fields.netWorth.value),
          },
          {
            type: 'DATE',
            label: 'DOB',
            value: this.form.fields.dob.value,
          },
          {
            type: 'SSN',
            label: 'SSN',
            value: this.form.fields.ssn.value,
          },
          {
            type: 'ZIP',
            label: 'Zipcode',
            value: this.form.fields.zipcode.value,
          },
          {
            type: 'LIST',
            label: 'StateCode',
            value: this.form.fields.state.value,
          },
          {
            type: 'NUMBER',
            label: 'Experience',
            value: this.form.fields.experience.value,
          },
        ],
        returnUrl:
          'http://localhost:9000/credit-authorization-docusign-callback',
      };

      const response: ApiResponse = yield this.userService.submitCreditAuthorizationFormToDocusign(
        request
      );
      this.docusignUrl = response.data.data.viewUrl;
    } catch (error) {
      this.docusignError = true;
    }
  }

  *setCreditAuthorizationState(state: CreditAuthorizationState) {
    this.creditAuthorizationState = state;
  }

  *setDocumentVerificationState(state: DocumentVerificationState) {
    this.documentVerificationState = state;
  }
}

export default CreditAuthorizationStore;
