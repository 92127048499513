import { Service } from '@roc/feature-app-core';

const url = {
  GET_PROPERTY_DATA_COLLECTION_BY_PROPERTY_AND_LOAN: "/nportal/rest/priv/appraisalReview/getAppraisalReviewForm",
};

export class PropertyDataCollectionService extends Service {

  getPropertyDataCollectionByPropertyAndLoanId(propertyId, loanId){
    return this.get(url.GET_PROPERTY_DATA_COLLECTION_BY_PROPERTY_AND_LOAN + '?propertyId=' + propertyId + '&loanId=' + loanId);
  }
}
