import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Box, useTheme } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    position: 'relative',
    maxWidth: 1300,
    margin: 'auto',
  },
  connector: {
    height: '60px',
    width: '6px',
    margin: '-8px',
  },
  activeConnector: {
    backgroundColor: theme.palette.primary.main,
  },
  inactiveConnector: {},
  timelineDot: {
    boxShadow: 'none',
  },
  timelineContent: {
    padding: '0 8px',
    '&:before': {
      content: '▶',

      //◀
    },
  },
}));

export const PropertyInformationTimeline = observer(({ items }) => {
  const classes = useStyles();

  return (
    <Timeline style={{ paddingInline: 0 }}>
      {items.map((item, i) => (
        <PropertyInformationTimelineItem
          left={item.left}
          right={item.right}
          icon={item.icon}
          last={i == items.length - 1}
          gray={item.gray}
          connectorGray={items[i + 1]?.gray}
        />
      ))}
    </Timeline>
  );
});

export const PropertyInformationTimelineItem = observer(
  ({
    left = undefined,
    right = undefined,
    icon,
    last = false,
    gray = false,
    connectorGray = false,
  }) => {
    const classes = useStyles();
    const theme = useTheme();
    return (
      <TimelineItem style={{ paddingInline: 0 }}>
        <TimelineOppositeContent className={classes.timelineContent}>
          {!!left && (
            <Box display="flex">
              <Box flex={1}>{left}</Box>
              <Box
                style={{
                  marginTop: '14px',
                  marginLeft: '8px',
                }}
              >
                <svg
                  width="5"
                  height="10"
                  viewBox="0 0 5 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M-0.000488281 10L4.99951 5L-0.000488281 0V10Z"
                    fill={
                      gray
                        ? theme.palette.text.disabled
                        : theme.palette.primary.main
                    }
                  />
                </svg>
              </Box>
            </Box>
          )}
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot
            color={gray ? undefined : 'primary'}
            className={classes.timelineDot}
          >
            {icon}
          </TimelineDot>
          {!last && (
            <TimelineConnector
              className={clsx(
                classes.connector,
                connectorGray
                  ? classes.inactiveConnector
                  : classes.activeConnector
              )}
            />
          )}
        </TimelineSeparator>
        <TimelineContent className={classes.timelineContent}>
          {!!right && (
            <Box display="flex">
              <Box
                style={{
                  marginTop: '14px',
                  marginRight: '8px',
                }}
              >
                <svg
                  width="6"
                  height="10"
                  viewBox="0 0 6 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.96071 0L0.974915 5L5.96071 10V0Z"
                    fill={
                      gray
                        ? theme.palette.text.disabled
                        : theme.palette.primary.main
                    }
                  />
                </svg>
              </Box>
              <Box flex={1}>{right}</Box>
            </Box>
          )}
        </TimelineContent>
      </TimelineItem>
    );
  }
);

export const TimelineIconPurchasePrice = () => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.2359 12.5H22.2298L12.2501 3.5L2.27046 12.5H5.26436V15.5H3.26843V17.5H5.26436V20.5H7.26029V17.5H11.2521V20.5H13.2481V17.5H17.2399V20.5H19.2359V17.5H21.2318V15.5H19.2359V12.5ZM7.26029 15.5V10.69L11.2521 7.09V15.5H7.26029ZM13.2481 15.5V7.09L17.2399 10.69V15.5H13.2481Z"
      fill="white"
    />
  </svg>
);

export const TimelineIconMarketValue = () => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.25825 4L2.27046 10V20H14.246V10L8.25825 4ZM12.2501 18H9.25622V15H7.26029V18H4.26639V10.83L8.25825 6.83L12.2501 10.83V18ZM9.25622 13H7.26029V11H9.25622V13ZM18.2379 20V8.35L13.8968 4H11.0725L16.242 9.18V20H18.2379ZM22.2298 20V6.69L19.5452 4H16.721L20.2338 7.52V20H22.2298Z"
      fill="white"
    />
  </svg>
);

export const TimelineIconRenoSpent = () => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.7454 12.5H22.7434L12.7502 3.5L2.75696 12.5H5.75492V20.5H7.75356V18.5H17.7468V20.5H19.7454V12.5ZM7.96342 10.5H17.5369L17.7468 10.69V12.5H7.75356V10.69L7.96342 10.5ZM15.3184 8.5H10.1819L12.7502 6.19L15.3184 8.5ZM7.75356 16.5V14.5H17.7468V16.5H7.75356Z"
      fill="white"
    />
  </svg>
);

export const TimelineIconAdditionalConstructionBudget = () => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.1461 13.1602L14.0291 15.2815L20.0131 21.2777L22.1301 19.1564L16.1461 13.1602Z"
      fill="white"
    />
    <path
      d="M17.7388 10.1097C19.6649 10.1097 21.2317 8.53967 21.2317 6.60967C21.2317 6.02967 21.072 5.48967 20.8225 5.00967L18.128 7.70967L16.6411 6.21967L19.3356 3.51967C18.8565 3.26967 18.3176 3.10967 17.7388 3.10967C15.8128 3.10967 14.2459 4.67967 14.2459 6.60967C14.2459 7.01967 14.3258 7.40967 14.4555 7.76967L12.6093 9.61967L10.8329 7.83967L11.5415 7.12967L10.1343 5.71967L12.25 3.59967C11.0824 2.42967 9.18627 2.42967 8.01865 3.59967L4.48585 7.13967L5.89298 8.54967H3.07872L2.37016 9.25967L5.90296 12.7997L6.61152 12.0897V9.25967L8.01865 10.6697L8.7272 9.95967L10.5036 11.7397L3.10866 19.1497L5.22434 21.2697L16.5812 9.89967C16.9405 10.0297 17.3297 10.1097 17.7388 10.1097Z"
      fill="white"
    />
  </svg>
);

export const TimelineIconConstructionBudget = () => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.7454 12.5H22.7434L12.7502 3.5L2.75696 12.5H5.75492V20.5H7.75356V18.5H17.7468V20.5H19.7454V12.5ZM7.96342 10.5H17.5369L17.7468 10.69V12.5H7.75356V10.69L7.96342 10.5ZM15.3184 8.5H10.1819L12.7502 6.19L15.3184 8.5ZM7.75356 16.5V14.5H17.7468V16.5H7.75356Z"
      fill="white"
    />
  </svg>
);

export const TimelineIconAfterCompletionValue = () => (
  <svg
    width="21"
    height="18"
    viewBox="0 0 21 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.2359 6.8V1.5H14.242V4.1L10.2501 0.5L0.270462 9.5H3.26436V17.5H9.25215V11.5H11.2481V17.5H17.2359V9.5H20.2298L17.2359 6.8ZM15.2399 15.5H13.244V9.5H7.25622V15.5H5.26029V7.69L10.2501 3.19L15.2399 7.69V15.5Z"
      fill="white"
    />
    <path
      d="M8.25418 7.5H12.246C12.246 6.4 11.3479 5.5 10.2501 5.5C9.15235 5.5 8.25418 6.4 8.25418 7.5Z"
      fill="white"
    />
  </svg>
);
