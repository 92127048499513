import { Service } from '@roc/feature-app-core';

const url = {
  DOWNLOAD_TERMSHEET: '/api/v1/document/formDocument/downloadTermSheet',
  UPDATE_LOAN_DOCUMENT_TERMSHEET:
    '/api/v1/document/formDocument/updateLoanDocumentTermSheet',
  UPDATE_TERMSHEET_MODAL_STATE:
    '/api/v1/document/formDocument/updateTermSheetModalState',
  REQUEST_BORROWER_TERMSHEET:
    '/api/v1/document/formDocument/requestBorrowersTermSheet',
  GET_TERM_SHEET_DOCUMENT_FIELDS:
    '/api/v1/document/formDocument/getTermSheetFields',
  GET_TERM_SHEET_DOCUMENT_FIELDS_BRIDGE_SUBMISSION:
    '/api/v1/document/formDocument/getTermSheetDocumentFieldsBridgeSubmission',
  GET_RENTAL_TERM_SHEET_DOCUMENT_FIELDS:
    '/api/v1/document/formDocument/getRentalTermSheetFields',
  GET_RENTAL_TERM_SHEET_DOCUMENT_FIELDS_TERM_SUBMISSION:
    '/api/v1/document/formDocument/getRentalTermSheetDocumentFieldsTermSubmission',
  GET_LENDER_BY_ID: '/nportal/rest/priv/lender/getLenderById',
};

export class TermSheetService extends Service {
  downloadTermSheet(loanId: string) {
    return this.get(
      url.DOWNLOAD_TERMSHEET,
      {
        loanId,
      },
      {
        responseType: 'arraybuffer',
      }
    );
  }

  updateLoanDocumentTermSheet(data: any) {
    return this.put(url.UPDATE_LOAN_DOCUMENT_TERMSHEET, data);
  }

  updateTermSheetModalState(
    loanId: string,
    borrowerPayingGfd: boolean,
    termSheetOnBehalfOfTheBorrower: boolean
  ) {
    return this.put(
      `${url.UPDATE_TERMSHEET_MODAL_STATE
      }?loanId=${loanId}&borrowerPayingGfd=${!!borrowerPayingGfd}&termSheetOnBehalfOfTheBorrower=${!!termSheetOnBehalfOfTheBorrower}`,
      null,
      null,
      null,
      {
        disableGlobalLoading: true,
      }
    );
  }

  requestBorrowerTermSheet(loanId: string) {
    return this.get(url.REQUEST_BORROWER_TERMSHEET, {
      loanId,
    });
  }

  getTermSheetFields(loanId) {
    return this.get(url.GET_TERM_SHEET_DOCUMENT_FIELDS, {
      loanId,
    });
  }

  getTermSheetFieldsBridgeSubmission(loanJson) {
    return this.post(url.GET_TERM_SHEET_DOCUMENT_FIELDS_BRIDGE_SUBMISSION, loanJson);
  }

  getRentalTermSheetFields(loanId) {
    return this.get(url.GET_RENTAL_TERM_SHEET_DOCUMENT_FIELDS, {
      loanId,
    });
  }

  getRentalTermSheetFieldsTermSubmission(loanJson) {
    return this.post(
      url.GET_RENTAL_TERM_SHEET_DOCUMENT_FIELDS_TERM_SUBMISSION,
      loanJson
    );
  }

  getLenderById(lenderId) {
    return this.get(url.GET_LENDER_BY_ID, {lenderId});
  }
}
