import { makeObservable, action, flow, observable, computed } from 'mobx';
import { FileUpload } from '@roc/ui';
import { GlobalStore } from '@roc/feature-app-core';
import { DocumentStore } from '@roc/feature-documents';
import { Document } from '@roc/feature-types';
import { downloadDocument, UNDERWRITING } from '@roc/feature-utils';
import { isFileAlreadyUploaded } from 'libs/feature-documents/src/documents/utils/documents';
import { AppraisalReportService } from '../../services/documentForms/appraisalReportService';
export class AppraisalReportFormStore {
  globalStore: GlobalStore;
  public isInternal: boolean;
  documentStore: DocumentStore;
  document: Document;
  protected appraisalReportService: AppraisalReportService;

  constructor(globalStore: GlobalStore, documentStore: DocumentStore) {
    this.globalStore = globalStore;
    this.documentStore = documentStore;
    this.appraisalReportService = new AppraisalReportService();
    this.isInternal = false;
    makeObservable(this, {
      isInternal: observable,
      setIsInternal: action,
      uploadDocument: flow,
      document: observable,
      canDownloadFile: computed,
      documentName: computed,
      downloadDocument: flow,
      downloadDocumentInternal: flow,
      uploadAndParseDocument: flow,
      showErrorMessage: action,
      loadReport: flow
    });
  }

  *downloadDocumentInternal() {
    try {
      const response = yield this.appraisalReportService.downloadDocumentInternal(this.document);
      downloadDocument(
        response?.data,
        response?.headers,
        'download',
        this.document.originalFileName
      );
    } catch (err) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while downloading document.',
      });
    }
  }

  *downloadDocument() {
    if (this.isInternal) {
      this.downloadDocumentInternal();
    } else {
      yield this.documentStore.downloadDocument(this.documentStore.currentDocument);
    }
  }
  get canDownloadFile() {
    if (this.isInternal) {
      return isFileAlreadyUploaded(this.document);
    } else {
      return this.documentStore.currentDocument?.canDownloadFile;
    }
  }

  get documentName() {
    return this.documentStore.currentDocument?.originalFileName;
  }

  *uploadDocument(fileUploads: FileUpload[], documentId: any) {
    const [fileUpload] = fileUploads;
    if (fileUpload.file) {
      try {
        const blob = fileUpload.file.slice(0, fileUpload.file.size, fileUpload.file.type);
        const newFile = new File([blob], fileUpload.name, { type: fileUpload.file.type });
        const formData = new FormData();
        formData.append('file', newFile);
        yield this.appraisalReportService.uploadFile(this.isInternal, documentId, formData);
      } catch {
        this.globalStore.notificationStore.showErrorNotification({
          message: 'Error uploading file',
        });
      } finally {
        if (!this.isInternal) {
          let loanId = this.documentStore.currentDocument.loanId;
          this.documentStore.fetchProcessDocuments(loanId.toString(), UNDERWRITING);
        }
      }
    }
  }

  showErrorMessage(message: string) {
    this.globalStore.notificationStore.showErrorNotification({
      message,
    });
  }

  *uploadAndParseDocument(fileUploads: FileUpload[], documentId: any) {
    const [fileUpload] = fileUploads;
    if (fileUpload.file) {
      try {
        const blob = fileUpload.file.slice(0, fileUpload.file.size, fileUpload.file.type);
        const newFile = new File([blob], fileUpload.name, { type: fileUpload.file.type });
        const formData = new FormData();
        formData.append('file', newFile);
        yield this.appraisalReportService.uploadAndParseDocument(this.isInternal, documentId, formData)
      } catch (error){
        this.globalStore.notificationStore.showErrorNotification({
          message: 'Error uploading file',
        });
        window.parent.postMessage('error-xml', '*')
      }
    }
  }

  *loadReport(documentId) {
    try {
      const response = yield this.appraisalReportService.getLoanDocumentById(documentId);
      this.document = response.data;
    } catch {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error getting loan document',
      });
    }
  }

  setIsInternal(isInternal: boolean) {
    this.isInternal = isInternal;
  }
}
