import { makeObservable, observable } from 'mobx';

export class MatGridStore {

  selectedRows = [];
  currentSelectedIndex = null;

  constructor() {
    this.selectedRows = [];
    this.currentSelectedIndex = null;

    makeObservable(this, {
      selectedRows: observable,
      currentSelectedIndex: observable
    });
  }

  setCurrentSelectedIndex(currentSelectedIndex) {
    this.currentSelectedIndex = currentSelectedIndex;
  }

  setSelectedRows(rows) {
    this.selectedRows = rows;
  }

  resetSelectedRows() {
    this.selectedRows = [];
  }
}