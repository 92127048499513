import { Accordion, AccordionDetails, AccordionSummary, Box, Dialog, DialogContent, DialogTitle, Grid, IconButton, List, ListItem, Typography, useMediaQuery } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { observer } from "mobx-react";
import { createStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { Button } from "@roc/ui";
import { ExpandMore } from "@material-ui/icons";
import { useState } from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    title: {
      paddingRight: theme.spacing(6),
    },
    dialogContent: {
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(1, 3, 3),
    },
    bulletsContainer: {
      marginTop: '-15px',
    }
  })
);

interface Props {
  isOpen: boolean;
  handleClose: () => void;
}

const GuidelinesModal = observer((props: Props) =>{
  const { isOpen, handleClose } = props;
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const [expanded, setExpanded] = useState<string | false>('panel1');

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  return (
    <Dialog
    disableEscapeKeyDown
      fullScreen={fullScreen}
      fullWidth
      maxWidth="md"
      open={isOpen}
      scroll="paper"
      onClose={handleClose}
      aria-labelledby="reset-sow-dialog"
    >
      <DialogTitle disableTypography>
        <Typography className={classes.title} variant="h4">
          Guidelines
        </Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
            <AccordionSummary
              expandIcon={<ExpandMore/>}
            >
              Best Practices
            </AccordionSummary>
            <AccordionDetails>
              <Grid container>
                <Typography>
                  For Ground Up projects and Enlargements, both horizontal and vertical:
                </Typography>
                <List>
                  <ListItem>&#x2022;
                    To fast track your review, provide the 'Permit Set’ of drawings.  These are the drawings required to get a permit to build and can be uploaded under the Plans line item of the todos. We cannot use sketches or low resolution drawings. Please note that we cannot review Ground Up loans without a construction set of drawings. If these are made available to us early in the loan submission process, we do not need to wait for an appraisal to complete our review.
                  </ListItem>
                  <ListItem>&#x2022;
                    Remember to include Foundation, Framing, Roof, Drywall, Paint, Siding, Windows, Electric, Plumbing, HVAC, Flooring, Kitchen, and Bathrooms
                  </ListItem>
                </List>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
            <AccordionSummary
              expandIcon={<ExpandMore/>}
            >
              BUDGETS THAT NEED REVIEW
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={3}>
                <Grid item md={12}>
                  <Typography>
                    Fix & Flip loans with:
                  </Typography>
                  <List>
                    <ListItem>&#x2022;
                      Renovation Budgets greater than or equal to $250,000 (greater than or equal to $350,000 in CA)
                    </ListItem>
                    <ListItem>&#x2022;
                      All Vertical and Horizontal enlargements
                    </ListItem>
                    <ListItem>&#x2022;
                      All Refinance loans where completed renovation greater than or equal to $50,000
                    </ListItem>
                    <ListItem>&#x2022;
                      Imbalanced/Front Loaded budgets, that do not allocate sufficient funding for the finish materials like floors, kitchen and baths
                    </ListItem>
                    <ListItem>&#x2022;
                      Soft Costs, [Demolition + Dumpster] or Contingency line items individually exceeds 10% of the budget or if the sum of these line items exceeds 25% of the total budget
                    </ListItem>
                  </List>
                </Grid>
                <Grid item md={12}>
                  <Typography>
                    All Ground Up loans
                  </Typography>
                </Grid>
                <Grid item md={12}>
                  <Typography>
                    Loans where the Digital Scope of Work is incomplete subject to underwriting review and missing key line items.
                  </Typography>
                </Grid>
                <Grid item md={12}>
                  <Typography>
                    *Please note that this review will happen via the portal "Construction Data Review" chat room.
                  </Typography>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
            <AccordionSummary
              expandIcon={<ExpandMore/>}
            >
               COSTS THAT ARE NOT ALLOWED
            </AccordionSummary>
            <AccordionDetails>
              <Grid container>
                <Typography>
                  The following costs are not eligible for reimbursement:
                </Typography>
                <List>
                  <ListItem>&#x2022;
                    Interior design
                  </ListItem>
                  <ListItem>&#x2022;
                    Renderings
                  </ListItem>
                  <ListItem>&#x2022;
                    Furniture
                  </ListItem>
                  <ListItem>&#x2022;
                    Staging
                  </ListItem>
                  <ListItem>&#x2022;
                    Ground Maintenance
                  </ListItem>
                  <ListItem>&#x2022;
                    Mowing
                  </ListItem>
                  <ListItem>&#x2022;
                    Any loan related costs: legal, attorney, carry costs, inspection fees, etc
                  </ListItem>
                </List>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Grid item container justifyContent="center">
            <Button
              color="primary"
              onClick={handleClose}
              variant="outlined"
              testId="close">
              Close
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
});

export default GuidelinesModal;