// ----------------------------------------------------------------------

export default {
  MuiTableCell: {
    /*head: {
      color: theme.palette.text.secondary,
      backgroundColor: theme.palette.background.neutral,
    },*/
  },
}
