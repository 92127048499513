import { Box, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Layout } from '@roc/feature-app-core';
import { Paper, StepsContainer, VerticalBarLoader } from '@roc/ui';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useStore } from '@roc/client-portal-shared/hooks';
import { LenderBankInformationVerificationForm } from './LenderBankInformationVerificationForm';
import { VerifyLenderBankInfoDocusignIframe } from './verifyLenderBankInfoDocusignIframe';
import { ThankYouVerifyBankInfo } from './thankYou';


const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    padding: theme.spacing(3, 0, 4),
    borderRadius: theme.spacing(2),
  },
  stepper: {
    maxWidth: 800,
    margin: 'auto',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5),
    },
  },
}));

export const LenderBankInformationVerification = observer(({ companyProfileStore }) => {
  const {
    lenderDetailsStore,
    lenderBankInformationStore, salesforceLenderStore
  } = companyProfileStore;
  const { salesForceLender } = salesforceLenderStore;


  const classes = useStyles();
  const { borrowerDetailsVerificationStore, globalStore } = useStore();
  const { uuid } = useParams<{ uuid: string }>();
  const { activeStep } = lenderBankInformationStore;
  //const finished = lenderBankInformationStore.formInfo?.status === FormStatus.FINISHED;
  const { companyId, company } = salesForceLender;

  const steps = [
    {
      title: 'Verify Bank Information Form',
      icon: null,
      onBack: () => {
        lenderBankInformationStore.goPrevStep();
      },
      onNext: async () => {
        if (lenderBankInformationStore.verifyBankInformationDocusignFormStore.form.meta.isValid) {
          lenderBankInformationStore.verifyBankInformationDocusignFormStore.onFieldChange('companyId', companyId)
          lenderBankInformationStore.goNextStep();
        } else {
          lenderBankInformationStore.verifyBankInformationDocusignFormStore.runFormValidationWithMessage();
        }
      },
      allowBack: false,
      allowNext: true,
    },
    {
      title: `${'Docusign'}`,
      icon: null,
      onBack: () => {
        lenderBankInformationStore.goPrevStep();
      },
      //allowBack: finished ? false : true,
    },
    {
      title: `${'Thank you'}`,
      icon: null,
      allowBack: false,
    },
  ];


  const getStepsContent = () => {
    const stepsContent = [];

    stepsContent.push(<LenderBankInformationVerificationForm store={lenderBankInformationStore} />)
    stepsContent.push(<VerifyLenderBankInfoDocusignIframe lenderBankInformationStore={lenderBankInformationStore} />);
    stepsContent.push(<ThankYouVerifyBankInfo />);

    return stepsContent;
  };

  return (
    <Layout maxWidth={'lg'}>
      {(
        <>
          <Box my={3}>
            <Typography variant="h3" gutterBottom>
              {'Please fill the bank information form'}
            </Typography>
          </Box>

          <Paper className={classes.paper}>
            <StepsContainer
              activeStep={activeStep}
              disableNavigation={true}
              steps={steps}
              stepperClassName={classes.stepper}
            >
              {getStepsContent()}
            </StepsContainer>
          </Paper>
        </>
      )}
      {lenderBankInformationStore.loading && <VerticalBarLoader />}
    </Layout>
  );
});
