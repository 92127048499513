import { Service } from '@roc/feature-app-core';

const url = {
  UPDATE_LOANPAYMENTS:
    '/api/v1/document/formDocument/updateLoanPaymentDocumentState',
  UPDATE_LOAN_FAST_TRACK_PAYMENTS:
    '/api/v1/document/formDocument/updateLoanFastrackInfoState',
  DOWNLOAD_SIGNED_DOCUMENT_LOANPAYMENTS:
    '/api/v1/document/formDocument/downloadSignedDocument',
  GET_LOAN_FAST_TRACK_FEES: '/api/v1/loan/fastTrackFees',
  GET_LOAN_FAST_TRACK_INFO: '/api/v1/loan/loanFastTrackInfo'
};

export class LoanPaymentsService extends Service {

  updateLoanPaymentFlowData(paymentOptions: any) {
    return this.put(url.UPDATE_LOANPAYMENTS, paymentOptions);
  }

  updateLoanFastrackInfo(fastTrackOptions: any) {
    return this.put(url.UPDATE_LOAN_FAST_TRACK_PAYMENTS, fastTrackOptions);
  }

  downloadSignedDocument(entityId: string, entityType: string) {
    return this.get(
      url.DOWNLOAD_SIGNED_DOCUMENT_LOANPAYMENTS,
      {
        entityId,
        entityType,
      },
      {
        responseType: 'arraybuffer',
      }
    );
  }

  getLoanFastTrackFees() {
    return this.get(url.GET_LOAN_FAST_TRACK_FEES);
  }

  getLoanFastTrackInfo(loanId) {
    return this.get(url.GET_LOAN_FAST_TRACK_INFO, { loanId });
  }
}
