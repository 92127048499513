import { StandardDialog } from '@roc/ui';
import { Document } from '@roc/feature-types';
import DocumentHistory from './documentHistory';

type DocumentHistoryDialog = {
  document: Document;
  open: boolean;
  onClose: () => void;
};

export const DocumentHistoryDialog = ({
  document,
  open,
  onClose,
}: DocumentHistoryDialog) => {
  return (
    <StandardDialog
      open={open}
      handleClose={onClose}
      title="File history"
      dialogContent={<DocumentHistory document={document} />}
      maxWidth="md"
    />
  );
};

export default DocumentHistoryDialog;
