import { Grid } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { observer } from 'mobx-react';

export const ErrorsAlert = observer(({ errors }) => {
  return (
    <>
      {
        errors.length > 0 && (
          <Grid item xs={12}>
            <Alert severity="error">
              <AlertTitle>ERRORS</AlertTitle>
              {errors.map(error => (
                <Grid>{error}</Grid>
              ))}
            </Alert>
          </Grid>
        )
      }
    </>
  );
});

export const WarningsAlert = observer(({ warnings }) => {
  return (
    <>
      {
        warnings.length > 0 && (
          <Grid item xs={12}>
            <Alert severity="warning">
              <AlertTitle>WARNINGS</AlertTitle>
              {warnings.map(warning => (
                <Grid>{warning}</Grid>
              ))}
            </Alert>
          </Grid>
        )
      }
    </>
  );
});