import { action, observable, flow, makeObservable } from 'mobx';
import { ApiResponse, GlobalStore, GridStore } from '@roc/feature-app-core';
import { SpecialServicingService } from '../services/specialServicingService';
import { downloadDocument } from '@roc/feature-utils';
import { CounterPartyExpensesTab, Entity } from '../types/types';

export abstract class SpecialServicingBaseStore {
  globalStore: GlobalStore;
  service: SpecialServicingService;

  tab: CounterPartyExpensesTab;
  counterPartyExpenseGridStore: GridStore;
  recordsIds: number[] = [];
  entityList: Entity[] = [];
  entity: string = '';
  totalAmount: number;

  constructor(globalStore: GlobalStore) {
    this.globalStore = globalStore;
    this.service = new SpecialServicingService();
    this.recordsIds = [];
    this.totalAmount = 0;
    this.counterPartyExpenseGridStore = new GridStore(
      () => this.fetchCounterPartyExpenses(),
      null,
      50
    );
    makeObservable(this, {
      reset: action,
      setTab: action,
      tab: observable,
      resetAndFetchGridData: flow,
      exportServicingDashboardData: flow,
      setRecordsId: action,
      removeRecordsId: action,
      clearRecordsIds: action,
      entityList: observable,
      entity: observable,
      setEntity: action,
      recordsIds: observable,
      totalAmount: observable,
      setTotalAmount: action,
    });
  };

  private async fetchCounterPartyExpenses() {
    try {
      this.clearRecordsIds();
      this.totalAmount = 0;
      const filters = {
        ...this.counterPartyExpenseGridStore.gridData.meta.filters
      };
      const response: ApiResponse = await this.service.getCounterPartyExpensesData(
        this.tab,
        !this.entity ? null : this.entity,
        this.counterPartyExpenseGridStore.gridData.meta.pageNumber,
        this.counterPartyExpenseGridStore.gridData.meta.pageSize,
        this.counterPartyExpenseGridStore.gridData.meta.sortDir,
        this.counterPartyExpenseGridStore.gridData.meta.sortBy,
        filters,
        this.counterPartyExpenseGridStore.gridData.meta.dropdownFilters
      );
      return response;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching counter party expenses report.',
      });
    }
  };

  *exportServicingDashboardData() {
    try {
      const filters = {
        ...this.counterPartyExpenseGridStore.gridData.meta.filters
      };
      const response: ApiResponse = yield this.service.exportServicingDashboardData(
        this.tab,
        !this.entity ? null : this.entity,
        filters,
        this.counterPartyExpenseGridStore.gridData.meta.dropdownFilters
      );
      downloadDocument(response.data, response.headers, 'download', `${this.tab}_REPORT.xlsx`);
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while exporting special servicing dashboard data.',
      });
    }
  };

  setTab(tab: CounterPartyExpensesTab) {
    this.tab = tab;
  };

  // overridden method
  abstract fetchEntityNames();

  // overridden method
  abstract reset();

  // overridden method
  abstract performActions(status: string);

  // overridden method
  abstract performAction(id: number, status: string);

  *resetAndFetchGridData() {
    this.counterPartyExpenseGridStore = yield new GridStore(() => this.fetchCounterPartyExpenses(), null, 50);
    this.counterPartyExpenseGridStore.reset();
  };

  onSelectEntity = (option) => {
    this.setEntity(option.value);
    this.counterPartyExpenseGridStore.resetAndFetchGridData();

  }

  setRecordsId = (id, selected) => {
    if (id != null && !selected) {
      this.removeRecordsId(id);
    } else if (id != null && !this.recordsIds.includes(id)) {
      this.recordsIds.push(id);
    }
  }

  clearRecordsIds = () => {
    this.recordsIds = [];
  }

  removeRecordsId(id) {
    this.recordsIds = this.recordsIds.filter(recordsId => recordsId != id);
  }

  setEntity(entity: string) {
    this.entity = entity;
  };

  setTotalAmount = (amount, selected) => {
    if (this.recordsIds.length == 0) {
      this.totalAmount = 0
    } else {
      if (amount != null && selected) {
        this.totalAmount = parseFloat(this.totalAmount.toFixed(2)) + amount;
      } else if (amount != null) {
        this.totalAmount = parseFloat(this.totalAmount.toFixed(2)) - amount;
      }
    }
  }

}
