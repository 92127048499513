import { Box, Grid, ListItemIcon, ListItemText, Menu, MenuItem, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useStore } from '@roc/client-portal-shared/hooks';
import { Layout } from '@roc/feature-app-core';
import { Roles } from '@roc/feature-utils';
import { Button, ConfirmDialog, StandardDialog, Toolbar } from '@roc/ui';
import { useCRMPortalStore } from 'apps/crm-portal/src/app/hooks/useCRMPortalStore';
import { useCreateListStore } from 'apps/crm-portal/src/app/modules/createList/hooks/useCreateListStore';
import { SendBulkMessagesModal } from 'apps/crm-portal/src/app/modules/ringCentral/sendBulkMessages';
import { observer } from 'mobx-react';
import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { useRoutes } from '../../hooks/useRoutes';
import { LeadsDashboardGrid } from '../leads/listViews/leadsDashboardGrid';
import { BulkLeadChangeOwner } from './bulkLeadChangeOwner';
import { BulkLeadChangeStatus } from './bulkLeadChangeStatus';
import { EditLeadDashboardModal } from './createList/editLeadDashboardModal';
import { DashboardAutocompleteField } from './DashboardsAutocompleteField';
import { SendBulkEmailsModal } from './sendBulkEmails';
import { PhoneBurnerModal } from 'apps/crm-portal/src/app/modules/phoneBurner/phoneBurnerModal'
import { Add, ArrowDropDown, CopyrightOutlined, Edit, FileCopy, ScreenShare, Star } from '@material-ui/icons';
import { useNewLeadStore } from '../../hooks/useNewLeadStore';
import { ShareDashboardView } from './shareDashboardView';
import { SelectField } from '@roc/ui';
import { isLeadDashboard } from '../leads/listViews/leadsDashboardGrid';
import { BusinessUnit } from '@roc/feature-utils';

const useStyles = makeStyles(theme => ({
  button: {},
  paper: {},
  pageLayoutContainer: {
    padding: '2.5px',
  },
  title: {
    marginLeft: '15px',
    marginTop: '-20px'
  }
}));

export const businessUnitOptions = [
  { label: BusinessUnit.ROC, value: BusinessUnit.ROC },
  { label: BusinessUnit.CIVIC, value: BusinessUnit.CIVIC },
  { label: BusinessUnit.FACO, value: BusinessUnit.FACO },
]

export const AllLeadDashboard = observer(() => {
  const classes = useStyles();

  const { salesforceLeadStore, userStore } = useStore();
  const [openSendBulkMessagesModal, setOpenSendBulkMessagesModal] = useState(false);
  const [openPhoneBurnerModal, setOpenPhoneBurnerModal] = useState(false);
  const [openCreateTemplateModal, setOpenCreateTemplateModal] = useState(false);
  const { createListStore } = useCreateListStore();
  const { createListFormStore } = createListStore;
  const { sendEmailsStore, ringCentralStore } = salesforceLeadStore;
  const [openBulkLeadChangeStatusModal, setOpenBulkLeadChangeStatusModal] = useState(false);
  const [openBulkLeadChangeOwnerModal, setOpenBulkLeadChangeOwnerModal] = useState(false);
  const [openBulkTaskModal, setOpenBulkTaskModal] = useState(false);
  const [openShareDashboardViewModal, setOpenShareDashboardViewModal] = useState(false);
  const [openSendBulkEmailModal, setOpenSendBulkEmailModal] = useState(false);
  const { leadsDashboardStore, globalStore } = salesforceLeadStore;
  const { currentDashboard } = leadsDashboardStore
  const { phoneBurnerStore } = useStore();
  const dashboardType = (leadsDashboardStore?.currentDashboard?.objectType || leadsDashboardStore?.currentDashboard?.baseObject?.toUpperCase()) || 'Lead';
  const { leadsStore } = salesforceLeadStore;
  const { leadDetailsStore } = leadsStore;
  const { editLeadTaskStore } = leadDetailsStore;

  useEffect(() => {
    leadsDashboardStore.fetchDashboards();
    ringCentralStore.getAllTemplates();
    ringCentralStore.resetLists();
    sendEmailsStore.resetbulkEmails();
    salesforceLeadStore.gridBusinessUnit = globalStore.lenderInfo?.businessUnit;
  }, []);

  useEffect(() => {
    if (isLeadDashboard(leadsDashboardStore)) {
      leadsDashboardStore.leadsDashboardGridStore.resetAndFetchGridData();
    }
  }, [salesforceLeadStore.gridBusinessUnit]);

  const onSendBulkMessages = () => {
    setOpenSendBulkMessagesModal(true);
    ringCentralStore.setRecordType('TPO');
  }

  const onSendBulkEmail = () => {
    setOpenSendBulkEmailModal(true);
  }

  const onOpenPhoneBurnerModal = () => {
    setOpenPhoneBurnerModal(true);
  }

  const onOpenShareDashboardViewModal = () => {
    setOpenShareDashboardViewModal(true);
  }

  const closeBulkEmailModal = () => {
    setOpenSendBulkEmailModal(false);
    sendEmailsStore.handleModalClose();
  }

  const closeBulkSMSModal = () => {
    setOpenSendBulkMessagesModal(false);
  }

  const closePhoneBurnerModal = () => {
    setOpenPhoneBurnerModal(false);
  }

  const closeBulkChangeOwnerModal = () => {
    setOpenBulkLeadChangeOwnerModal(false);
  }

  const closeBulkChangeStatusModal = () => {
    setOpenBulkLeadChangeStatusModal(false);
    salesforceLeadStore.setLeadStatus(null)
  }

  const onCreateTemplate = () => {
    setOpenCreateTemplateModal(true);
  }

  const onChangeBulkLeadOwner = () => {
    setOpenBulkLeadChangeOwnerModal(true);
  }

  const onChangeBulkLeadStatus = () => {
    setOpenBulkLeadChangeStatusModal(true);
  }

  const onOpenBulkTask = () => {
    setOpenBulkTaskModal(true);
    editLeadTaskStore.openAddLeadTasksForBulk(dashboardType)
  }

  const closeModal = () => {
    setOpenSendBulkMessagesModal(false);
    setOpenCreateTemplateModal(false);
    setOpenBulkLeadChangeStatusModal(false);
    setOpenBulkLeadChangeOwnerModal(false);
    setOpenBulkTaskModal(false);
    createListFormStore.reset();
    createListStore.removeAllFilters();
    ringCentralStore.setMessage('');
    ringCentralStore.setLabel('');
    ringCentralStore.setTemplateValue('');
    ringCentralStore.setTemplateId('');
    ringCentralStore.setTemplateLabel('');
    ringCentralStore.sendBulkSMSFormStore.onFieldChange('message', '');
    salesforceLeadStore.setLeadStatus('');
    salesforceLeadStore.setNewLeadOwner('');
    salesforceLeadStore.resetLeadsIds();
    salesforceLeadStore.resetBorrowerIds();
    salesforceLeadStore.resetCompanyContactIds();
    ringCentralStore.resetLists();
    sendEmailsStore.resetbulkEmails();
  }

  return (
    <Layout containerClass={classes.pageLayoutContainer}>
      <Typography className={classes.title} variant="h5">
        CRM Dashboard
      </Typography>
      <LeadsDashboardGrid toolbar={<CustomToolbar onSendBulkMessages={onSendBulkMessages}
        onCreateTemplate={onCreateTemplate}
        onChangeBulkLeadOwner={onChangeBulkLeadOwner}
        onChangeBulkLeadStatus={onChangeBulkLeadStatus}
        ringCentralStore={ringCentralStore}
        sendEmailsStore={sendEmailsStore}
        phoneBurnerStore={phoneBurnerStore}
        onSendBulkEmail={onSendBulkEmail}
        onOpenPhoneBurnerModal={onOpenPhoneBurnerModal}
        onOpenShareDashboardViewModal={onOpenShareDashboardViewModal}
        onAddBulkTask={onOpenBulkTask} />}
        columns={leadsDashboardStore.dashboardColumns}
        gridStore={leadsDashboardStore.leadsDashboardGridStore} />
      <Paper className={classes.paper}>
        <EditLeadDashboardModal onClose={closeModal} />
        {/* <StandardDialog open={openCreateListModal} title="Create List" maxWidth="md" handleClose={closeModal} dialogContent={<CreateListModal handleClose={closeModal} />} /> */}
        <StandardDialog open={openSendBulkMessagesModal} title={"Send Bulk SMS"} maxWidth="md" handleClose={closeBulkSMSModal} dialogContent={<SendBulkMessagesModal handleClose={closeModal} salesforceLeadStore={salesforceLeadStore} type={dashboardType} fromGrid={true} />} />
        <StandardDialog open={openSendBulkEmailModal} title={"Send Bulk Emails"} maxWidth="xl" handleClose={closeBulkEmailModal} dialogContent={<SendBulkEmailsModal handleClose={closeBulkEmailModal} salesforceLeadStore={salesforceLeadStore} type={dashboardType} fromGrid={true} />} />
        <StandardDialog open={openPhoneBurnerModal} title={"Connect with PhoneBurner"} maxWidth="md" handleClose={closePhoneBurnerModal} dialogContent={<PhoneBurnerModal handleClose={closeModal} />} />
        <BulkLeadChangeStatus open={openBulkLeadChangeStatusModal} handleClose={closeBulkChangeStatusModal} salesforceLeadStore={salesforceLeadStore} />
        <BulkLeadChangeOwner open={openBulkLeadChangeOwnerModal} handleClose={closeBulkChangeOwnerModal} salesforceLeadStore={salesforceLeadStore} isFromGlobalSearch={false} />
        <ShareDashboardView open={openShareDashboardViewModal} handleClose={() => setOpenShareDashboardViewModal(false)} salesforceLeadStore={salesforceLeadStore} dashboard={leadsDashboardStore.currentDashboard} />
      </Paper>
    </Layout>
  );
});

const CustomToolbar = observer(({ onSendBulkMessages, onCreateTemplate, onChangeBulkLeadOwner, onChangeBulkLeadStatus, ringCentralStore, sendEmailsStore, onSendBulkEmail, phoneBurnerStore, onOpenPhoneBurnerModal, onAddBulkTask, onOpenShareDashboardViewModal }) => {

  const { push } = useHistory();
  const { newLeadRoutesConfig } = useRoutes();
  const anchorRef = useRef(null);
  const viewActionsButtonRef = useRef(null);
  const [showBulkActionsMenu, setShowBulkActionsMenu] = useState(false);
  const [showViewActionsMenu, setShowViewActionsMenu] = useState(false);
  const { salesforceLeadStore, userStore } = useStore();
  const classes = useStyles();
  const { leadsDashboardStore } = salesforceLeadStore;
  const { editLeadsDashboardStore, currentDashboard, isCustomDashboard } = leadsDashboardStore;
  const { globalStore } = useCRMPortalStore();
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [smsRecordList, setSmsRecordList] = useState(0);
  const [emailRecordList, setEmailRecordList] = useState(0);
  const [bulkTaskRecordList, setBulkTaskRecordList] = useState(0);
  const { newLeadStore } = useNewLeadStore();
  const { leadsStore } = salesforceLeadStore;
  const { leadDetailsStore } = leadsStore;
  const { editLeadTaskStore } = leadDetailsStore;

  useEffect(() => {
    setSmsRecordList(ringCentralStore.getRecordList(currentDashboard?.objectType || currentDashboard?.baseObject?.toUpperCase())?.length);
  }, [ringCentralStore.leads, ringCentralStore.borrowers, ringCentralStore.companyContacts]);

  useEffect(() => {
    setEmailRecordList(sendEmailsStore.bulkEmails?.length);
  }, [sendEmailsStore.bulkEmails]);

  useEffect(() => {
    setBulkTaskRecordList(editLeadTaskStore.leadTasksForBulk?.length);
  }, [editLeadTaskStore.leadTasksForBulk]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setShowBulkActionsMenu(true);
  };

  const handleClose = () => {
    setShowBulkActionsMenu(false);
  };

  const handleSendBulkMessages = () => {
    setShowBulkActionsMenu(false);
    onSendBulkMessages();
  }

  const handleSendBulkEmail = () => {
    setShowBulkActionsMenu(false);
    onSendBulkEmail();
  }

  const handleStartDialSession = () => {
    if (globalStore.userFeatures?.connectedWithPhoneBurner) {
      if (phoneBurnerStore.records && phoneBurnerStore.records.length > 0) {
        setShowBulkActionsMenu(false);
        setIsConfirmOpen(true);
      } else {
        salesforceLeadStore.globalStore.notificationStore.showErrorNotification({
          message: 'Please select at least 1 lead'
        });
      }
    } else {
      onOpenPhoneBurnerModal()
    }
  }

  const handleChangeBulkLeadOwner = () => {
    setShowBulkActionsMenu(false);
    verifyIfLeadSelected() && onChangeBulkLeadOwner();
  }

  const handleChangeBulkLeadStatus = () => {
    setShowBulkActionsMenu(false);
    verifyIfLeadSelected() && onChangeBulkLeadStatus();
  }

  const handleAddBulkTask = () => {
    setShowBulkActionsMenu(false);
    verifyIfLeadSelected() && onAddBulkTask();
  }

  function handleConfirm() {
    phoneBurnerStore.startDialSession()
    setIsConfirmOpen(false);
  }


  const verifyIfLeadSelected = () => {
    if (bulkActions) {
      return true;
    } else {
      salesforceLeadStore.globalStore.notificationStore.showErrorNotification({
        message: 'Please select at least 1 lead'
      });
      return false
    }
  }

  const createdByCurrentUser = () => {
    const createdBy = leadsDashboardStore.currentDashboard?.createdBy;
    const username = userStore.userInformation?.username;
    console.log("createdBy:", createdBy);
    console.log("username:", username);
    return (
      leadsDashboardStore.currentDashboard?.createdBy === userStore.userInformation?.username
    );
  };
  const bulkActions = salesforceLeadStore.leadIds.length > 0;

  return (
    <>
      <Grid container alignItems="center">
        <Grid item xs={3}>
          <Box p={1.5}>
            <DashboardAutocompleteField store={salesforceLeadStore} />
          </Box>
        </Grid>
        <Grid item>
          <div ref={viewActionsButtonRef} style={{ display: 'inline-block' }}>
            <Button
              id="basic-button"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={() => { setShowViewActionsMenu(true) }}
              testId="open_menu"
              variant="contained"
              size="small"
              color="primary"
            >
              View Actions <ArrowDropDown style={{ marginRight: '-6px' }} />
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={viewActionsButtonRef.current}
              open={showViewActionsMenu}
              onClose={() => { setShowViewActionsMenu(false) }}
              keepMounted={true}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              getContentAnchorEl={null}
            >
              {(isCustomDashboard && createdByCurrentUser()) && (
                <MenuItem
                  onClick={() => editLeadsDashboardStore.openEdit(currentDashboard)}
                >

                  <ListItemIcon>
                    <Edit fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Edit view</ListItemText>
                </MenuItem>
              )}
              <MenuItem onClick={() => editLeadsDashboardStore.openCreate()}
              >
                <ListItemIcon>
                  <Add fontSize="small" />
                </ListItemIcon>
                <ListItemText>Create new view</ListItemText>
              </MenuItem>
              <MenuItem onClick={() => editLeadsDashboardStore.openClone(currentDashboard)}>
                <ListItemIcon>
                  <FileCopy fontSize="small" />
                </ListItemIcon>
                <ListItemText>Clone view</ListItemText>
              </MenuItem>

              <MenuItem onClick={() => userStore.saveUserPreference({ defaultCrmDashboard: leadsDashboardStore.currentDashboardId })}>
                <ListItemIcon>
                  <Star fontSize="small" />
                </ListItemIcon>
                <ListItemText>Set view as default</ListItemText>
              </MenuItem>
              {isCustomDashboard && (
                <MenuItem onClick={() => onOpenShareDashboardViewModal()}>
                  <ListItemIcon>
                    <ScreenShare fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Share view</ListItemText>
                </MenuItem>)}
            </Menu>
          </div>
        </Grid>
      </Grid>
      <Toolbar
        toolbarContainerStyle={{ padding: '10px' }}
        left={<>
          {globalStore.userFeatures.backOfficeLead && isLeadDashboard(leadsDashboardStore) &&
            <Box>
              <SelectField
                value={salesforceLeadStore.gridBusinessUnit}
                options={businessUnitOptions}
                onChange={value => salesforceLeadStore.gridBusinessUnit = value}
                fullWidth
                variant={'outlined'}
                testId={'businessUnit'}
                style={{ height: '35px' }}
              />
            </Box>
          }
        </>}>
        <Grid container spacing={2}>
          <Grid item>
            <Button
              className={null}
              variant="contained"
              size="small"
              onClick={() => {
                newLeadStore.setNoLeadsFound(false);
                push(newLeadRoutesConfig.newLeadsHome.url)
              }}
              color="primary"
              testId="create_leads"
            >
              Create Lead
            </Button>
          </Grid>
          {
            globalStore?.userFeatures?.backOfficeLead && (currentDashboard?.objectType === 'LEAD' || currentDashboard?.baseObject === 'Lead') &&
            <Grid item>
              <Button
                className={null}
                variant="contained"
                size="small"
                onClick={handleChangeBulkLeadOwner}
                color="primary"
                testId="bulk-change-owner"
              >
                Change Owner
              </Button>
            </Grid>
          }

          <Grid item>
            <Button
              className={null}
              variant="contained"
              size="small"
              onClick={handleChangeBulkLeadStatus}
              color="primary"
              testId="bulk-change-status"
            >
              Change Status
            </Button>
          </Grid>

          <Grid item>
            <Button
              className={null}
              variant="contained"
              size="small"
              onClick={handleStartDialSession}
              color="primary"
              testId="start-dial-session"
            >
              Start Dial Session
            </Button>
          </Grid>

          <Grid item>
            <Button
              className={null}
              variant="contained"
              size="small"
              onClick={handleAddBulkTask}
              color="primary"
              testId="bulk-task"
              disabled={!bulkTaskRecordList || bulkTaskRecordList === 0}
            >
              {`Add Task${bulkTaskRecordList ? ` - ${bulkTaskRecordList}` : ''}`}
            </Button>
          </Grid>

          <Grid item>
            <Button
              className={null}
              variant="contained"
              size="small"
              onClick={handleSendBulkEmail}
              color="primary"
              testId="send_bulk_emails"
              disabled={emailRecordList === 0}
            >
              {`Send Email${emailRecordList !== 0 ? ` - ${emailRecordList}` : ''}`}
            </Button>
          </Grid>

          <Grid item>
            <Button
              className={null}
              variant="contained"
              size="small"
              onClick={handleSendBulkMessages}
              color="primary"
              testId="send_bulk_messages"
              disabled={smsRecordList === 0}
            >
              {`Send Text${smsRecordList !== 0 ? ` - ${smsRecordList}` : ''}`}
            </Button>
          </Grid>

          {/*<Grid item>
             <div ref={anchorRef} style={{ display: 'inline-block' }}>
              <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                testId="open_menu"
                variant="contained"
                size="small"
                color="primary"
              >
                Bulk Actions <ArrowDropDown style={{ marginRight: '-6px' }} />
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorRef.current}
                open={showBulkActionsMenu}
                onClose={handleClose}
                keepMounted={true}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                getContentAnchorEl={null}
              >
                {smsRecordList != 0 && <MenuItem onClick={handleSendBulkMessages}>Bulk SMS - {smsRecordList}</MenuItem>}
                {emailRecordList != 0 && <MenuItem onClick={handleSendBulkEmail}>Bulk Email - {emailRecordList}</MenuItem>}
                <MenuItem onClick={handleStartDialSession}> Start Dial Session</MenuItem>
                {globalStore?.userFeatures?.backOfficeLead && (currentDashboard?.objectType === 'LEAD' || currentDashboard?.baseObject === 'Lead') &&
                  <MenuItem onClick={handleChangeBulkLeadOwner}>Bulk Change Owner</MenuItem>
                }
                <MenuItem onClick={handleChangeBulkLeadStatus}>Bulk Change Status</MenuItem>
                {bulkTaskRecordList > 0 && <MenuItem onClick={handleAddBulkTask}>Bulk Task - {bulkTaskRecordList}</MenuItem>}
              </Menu>
            </div>
          </Grid> */}
        </Grid>
      </Toolbar>
      <ConfirmDialog
        open={isConfirmOpen}
        body={'You will be redirected to PhoneBurner to start the dial session'}
        handleCancel={() => setIsConfirmOpen(false)}
        handleClose={() => setIsConfirmOpen(false)}
        handleConfirm={handleConfirm}
        confirmButtonText={'Continue'}
        cancelButtonText={'Cancel'}
      />
    </>
  );
}
);
