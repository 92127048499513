import { Group } from '@material-ui/icons';
import { useStore } from '@roc/client-portal-shared/hooks';
import { observer } from 'mobx-react';
import { AddOrSelect } from 'libs/ui/src/lib/components/addOrSelect/addOrSelect';
import { OriginatorCard } from './components/originatorCard';
import { EditOriginatorDialog } from './components/editOriginatorDialog';
import { SelectOriginatorsDialog } from './components/selectOriginatorsDialog';
import { SelectOriginatorsStore } from '../stores/selectOriginatorStore';

export type SelectOriginatorsProps = {
  store: SelectOriginatorsStore;
};

export const SelectOriginators = observer((props: SelectOriginatorsProps) => {
  const { store } = props;
console.log(store)
  return (
    <>
      <AddOrSelect
        entity={'originator'}
        entities={store.originators}
        renderEntity={originator => (
          <OriginatorCard
            key={originator.lenderId}
            originator={originator}
            onEdit={() => store.editOriginator(originator)}
            onRemove={() => store.removeOriginator(originator)}
          />
        )}
        showSelect={true}
        selectButtonProps={{
          startIcon: <Group />,
          testId: 'selectOriginatorButton',
          onClick: () => store.openSelectOriginatorsDialog(),
          disabled: store.originators.length>1
        }}
        showAdd={false}
        addButtonProps={{
          testId: 'addOriginatorButton',
          onClick: () => store.openAddNewOriginatorDialog(),
          disabled: false,
        }}
      />
      <SelectOriginatorsDialog store={store} />
      <EditOriginatorDialog store={store} />
    </>
  );
});