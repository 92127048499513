import { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { observer } from 'mobx-react';
import { RelationshipManagerInfo } from '@roc/feature-loans';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { StepsContainer} from '@roc/ui';
import { LoanKeyInformation } from './components/loanKeyInformation/loanKeyInformation';
import { ResendInsuranceQuote } from './components/resendInsuranceQuote/resendInsuranceQuote';
import { LoanDetailsLayout } from '@roc/feature-loans';
import DocumentForms from '../documentForms/documentForms';
import { useLoanActionsStore } from 'libs/feature-documents/src/loanActions';
import { LoanActionsPadding } from 'libs/feature-documents/src/loanActions/components/loanActionsPadding';
import { Layout, StepperComponent } from '@roc/client-portal-shared/components';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(3, 0, 4),
      borderRadius: theme.spacing(2),
      boxShadow:
        'rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px',
    },
    stepContentWrapper: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      [theme.breakpoints.down('xs')]: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
      },
    },
  })
);


export const Overview = observer(
  ({ loanStore, isResendInsuranceEnable, loanId, loanProcess }) => {

    const stepNames = [
    "Loan Agreed",
    "Title Contacted",
    "Closing Scheduled",
    "Pending Approval",
    "Approved to Fund",
    "Docs Sent for Signing",
    "Wire Requested",
    "Wire Sent",
    "Loan Closed & Funded",
    ];

    const closingIndex = loanStore.closingRequirements?.loanClosingStage === null ||
      loanStore.closingRequirements?.loanClosingStage === undefined ? -1 :
      stepNames.findIndex(
        closingStep => closingStep.trim() === loanStore.closingRequirements?.loanClosingStage?.trim()
        );

    const loanClosingSteps = stepNames.map((name, index) => ({
    title: name,
    completed: closingIndex > index,
    icon: null
    }))

    return (
      <LoanDetailsLayout title="OVERVIEW" minHeight="auto">
        <StepperComponent
        activeStep={closingIndex}
          disableNavigation={true}
          steps={loanClosingSteps}
          alternativeLabel={true}/>
        <RelationshipManagerInfo />
        <LoanKeyInformation loanStore={loanStore} />
        <DocumentForms loanId={loanId} />
        <LoanActionsPadding />
      </LoanDetailsLayout>
    );
  }
);
