import { Box, Button, Grid, Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { CloudUpload, Delete } from '@material-ui/icons';
import { PDFPreview } from '@roc/client-portal-shared/components';
import { useStore } from '@roc/client-portal-shared/hooks';
import { FileUpload, FileUploadModal, IconButton, Paper } from '@roc/ui';
import { observer } from 'mobx-react';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { Grid as DataGrid, GridColumnProps } from '@roc/client-portal-shared/components';
import { useLenderUserDocumentStore } from '../hooks/useLenderUserDocumentStore';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(4),
  },
  circleWrapper: {
    height: '48px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  smallGreenCircle: {
    width: 12,
    height: 12,
    borderRadius: 8,
    backgroundColor: '#48AC00',
  },
  smallRedCircle: {
    width: 12,
    height: 12,
    borderRadius: 8,
    backgroundColor: '#E60909',
  },
  root: {
    '.ag-react-container': {
      border: '1px solid black',
    },
  },
  grid: {
    minHeight: '200px',
  },
}));

const columns: GridColumnProps[] = [
  /*  {
     field: 'uploaded',
     headerName: 'Status',
     maxWidth: 100,
     cellRenderer: 'statusCellRenderer',
   }, */
  {
    field: 'documentLabel',
    headerName: 'Document',
    minWidth: 300,
    cellRenderer: 'documentCellRenderer',
  },
  {
    field: 'documentLabel',
    headerName: 'Actions',
    maxWidth: 200,
    cellRenderer: 'actionsCellRenderer',
  },
];


export const LenderUserDocumentsForm = observer(({ lenderUserDocumentStore }) => {
  //const { lenderUserDocumentsStore } = useLenderUserDocumentStore();

  const gridStore = lenderUserDocumentStore.gridStore;

  const classes = useStyles();
  const [activeDocumentType, setActiveDocumentType] = useState();

  const fileInputRef = useRef<HTMLInputElement>();

  const [isUploadOpen, setIsUploadOpen] = useState(false);

  const frameworkComponents = {
    statusCellRenderer: params => {
      if (!params.node.data) {
        return null;
      }
      const { uploaded } = params.node.data;
      const cssClass = uploaded
        ? classes.smallGreenCircle
        : classes.smallRedCircle;
      return (
        <Box className={classes.circleWrapper}>
          <div className={cssClass}></div>
        </Box>
      );
    },
    documentCellRenderer: params => {
      if (!params.node.data) {
        return null;
      }
      const { uploaded, documentLabel } = params.node.data;

      if (uploaded) {
        return (
          <Link
            component="button"
            variant="body2"
            onClick={() => {
              lenderUserDocumentStore.previewDocument(documentLabel);
            }}
          >
            {params.value}
          </Link>
        );
      } else {
        return <Box>{params.value}</Box>;
      }
    },
    actionsCellRenderer: params => {
      if (!params.node.data) {
        return null;
      }
      const { uploaded, documentId, documentType, documentLabel } = params.node.data;
      return uploaded ? (
        <IconButton
          testId={`delete-document-${documentLabel}`}
          onClick={() => lenderUserDocumentStore.deleteDocument(documentLabel)}
        >
          <Delete color="primary" />
        </IconButton>
      ) : (
        <IconButton
          testId={`upload-document-${documentId}`}
          onClick={() => {
            if (fileInputRef.current) {
              setActiveDocumentType(documentType);
              fileInputRef.current.click();
            }
          }}
        >
          <CloudUpload color="primary" />
        </IconButton>
      );
    },
  };

  const onFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const file = e.target.files[0];
    fileInputRef.current.value = '';
    if (file) {
      lenderUserDocumentStore.uploadDocument(file, file.name);
    }
  };

  const onDocumentUpload = (files: FileUpload[]) => {
    for (const file of files) {
      lenderUserDocumentStore.uploadDocument(file.file, file.name);
    }

  }

  const closeFileUploadModal = () => {
    setIsUploadOpen(false);
  }
  return (
    <>
      <input
        ref={fileInputRef}
        id="fileInput"
        type="file"
        style={{ display: 'none' }}
        onChange={onFileChange}
        accept="application/pdf"
      />
      <FileUploadModal
        title={`ADD NEW DOCUMENTS`}
        open={isUploadOpen}
        multiple
        onClose={closeFileUploadModal}
        onUpload={files => onDocumentUpload(files)}
      />
      <Grid container>
        <Grid item xs={12}>
          <Grid container justifyContent='space-between' alignItems='center'>
            <Grid item>
              <Box sx={{ pt: 2, pb: 2 }}>
                <Typography variant="h6">UPLOAD DOCUMENTS</Typography>
              </Box>
            </Grid>
            <Grid item>
              <Button variant='contained' color='primary' onClick={() => setIsUploadOpen(true)}>Add Other Documents</Button>
            </Grid>
          </Grid>


        </Grid>
        <Grid item xs={12}>
          <DataGrid
            columns={columns}
            rows={gridStore.gridData.data.rows}
            frameworkComponents={frameworkComponents}
            isLoading={gridStore.isLoading}
            domLayout="autoHeight"
          />
          <PDFPreview
            open={lenderUserDocumentStore.documentPreview != null}
            title={lenderUserDocumentStore.documentPreview?.title}
            data={lenderUserDocumentStore.documentPreview?.data}
            headers={lenderUserDocumentStore.documentPreview?.headers}
            onClose={() => lenderUserDocumentStore.closeDocumentPreview()}
          />
        </Grid>
      </Grid>




    </>
  );
});
