import { Grid, Typography } from '@material-ui/core';
import { Originator } from '@roc/feature-loans';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { INSPECTION_BY_BORROWER } from '../../../utils/constants';
import {
  InspectionBy, InteriorAccessAvailable,

  NoInteriorAccessComment, SubmitRushedAppraisal
} from './formComponents';
import { LoanSubType } from '@roc/feature-utils';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    color: theme.palette.primary.main,
  },
}));

export const AppraisalDetailsForm = observer(({ submitAppraisalStore }) => {
  const { isNoAccessAndPurchase, selectedLoanSubType } = submitAppraisalStore;
  const {
    interiorAccessAvailable,
    inspectionBy,
  } = submitAppraisalStore.form.fields;
  const { allowOriginatorSelection } = submitAppraisalStore.isClientPortal ? submitAppraisalStore.loanParticipantsStore : false;
  const styles = useStyles();

  const onInteriorAccessAvailableChange = () => {
    submitAppraisalStore.handleInteriorAccessAvailableChange();
  };

  const onNoInteriorAccessCommentChange = () => {
    submitAppraisalStore.handleNoInteriorAccessCommentChange();
  };

  useEffect(() => {
    if (submitAppraisalStore.isClientPortal) {
      submitAppraisalStore.loadStore();
    }
  }, []);

  const isFixAndFlipLoan = (loanSubType) => (loanSubType === LoanSubType.FIX_AND_FLIP || loanSubType === LoanSubType.FIX_AND_FLIP_PRO);

  return (
    <Grid item container justifyContent="center" spacing={2}>
      <Grid item xs={12}>
        <SubmitRushedAppraisal store={submitAppraisalStore} />
      </Grid>
      {isFixAndFlipLoan(selectedLoanSubType) && <Grid item xs={12}>
        <InteriorAccessAvailable
          store={submitAppraisalStore}
          onChange={onInteriorAccessAvailableChange}
        />
      </Grid>}
      {interiorAccessAvailable.value === 'Y' && (
        <>
          <Grid item xs={12}>
            <InspectionBy store={submitAppraisalStore} />
          </Grid>
          {inspectionBy.value === INSPECTION_BY_BORROWER && (
            <Grid item xs={12}>
              <Typography variant="button">
                Go to this page{' '}
                <a
                  className={styles.link}
                  href="https://getownerinsight.com/"
                  rel="noreferrer"
                  target="_blank"
                >
                  getownerinsight
                </a>{' '}
                to create this inspection report.
              </Typography>
            </Grid>
          )}
        </>
      )}
      {interiorAccessAvailable.value === 'N' && !isNoAccessAndPurchase && (
        <Grid item xs={12}>
          <NoInteriorAccessComment
            store={submitAppraisalStore}
            onChange={onNoInteriorAccessCommentChange}
          />
        </Grid>
      )}
      {allowOriginatorSelection && (
        <Grid item xs={12}>
          <Originator store={submitAppraisalStore} label='Originator' name='originatorId' />
        </Grid>
      )}
    </Grid>
  );
});
