import React, { ReactNode, useEffect, useState } from 'react';
import { Button } from '@roc/ui';
import { observer } from 'mobx-react';
import { BorrowersStore, QualifyBorrowerStore } from '../stores';
import { QualifyBorrowerForm } from './qualifyBorrowerForm';
import { Grid, Step, StepContent, StepLabel, Stepper } from '@material-ui/core';
import { Borrower } from '../types';
import { isNil, prequalificationStatusProperties } from '@roc/feature-utils';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { BorrowerSetupForm } from '../borrowerSetup';
import BorrowerSetupLink from '../borrowerSetup/borrowerSetupLink';
import { useStore } from '@roc/client-portal-shared/hooks';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    stepsContentContainer: {
      margin: theme.spacing(4),
    },
  })
);

const STEPS = [
  'Personal Information',
  'Borrower Setup',
];

export type BorrowerWithPreQualificationStepsFormProps = {
  handlePreQualifyBorrower: () => void;
  store: QualifyBorrowerStore;
  borrower?: Borrower;
  personalInformation: ReactNode;
  handleSaveBorrower: (onSuccess?: () => void) => void;
  initialStep: number;
  handleClose: () => void;
  borrowersStore: BorrowersStore;
};

export const BorrowerWithPreQualificationStepsForm = observer((props: BorrowerWithPreQualificationStepsFormProps) => {
  const {
    handlePreQualifyBorrower,
    store,
    borrower,
    personalInformation,
    handleSaveBorrower,
    initialStep,
    handleClose,
    borrowersStore,
  } = props;
  const { qualifyBorrowerRequestFormStore } = store;
  const [activeStep, setActiveStep] = useState(initialStep);
  const classes = useStyles();
  const { borrowerSetupStore } = borrowersStore;
  const personalGuarantor = !isNil(borrower?.personalGuarantor) ? borrower?.personalGuarantor : borrower?.runCreditOnCreation;
  const { borrowerDetailsVerificationStore } = useStore();

  useEffect(() => {
    store.reset();
    if (borrower) {
      store.setInitialValues(borrower);
    }
  }, [borrower]);

  const getSetupForm = () => {
    if (!borrowerSetupStore.currentBorrower) {
      return <></>;
    }
    return (
      <Grid container justifyContent={'center'} spacing={2}>
        <Grid item xs={12}>
          <BorrowerSetupForm borrowerSetupStore={borrowerSetupStore} handleSave={handleNext} onBack={handleBack} isInternal={false} hideBack={!!borrowersStore.initialBorrowerFormStep} />
        </Grid>
      </Grid >
    )
  };

  const getStepContent = (step: number) => {
    if (step === 0) {
      return personalInformation;
    }
    if (step === 1) {
      return <QualifyBorrowerForm store={qualifyBorrowerRequestFormStore} borrower={borrower} />;
    }
    return <></>;
  }

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  }

  const handleNext = () => {
    if (activeStep === 0) {
      handleSaveBorrower(() => { setActiveStep(activeStep + 1) });
    } else if (activeStep === 1) {
      handlePreQualifyBorrower();
    }
  }

  const renderButtons = () => {
    let buttons = [];

    if (activeStep === 0) {
      buttons = buttons.concat(
        (<Button
          onClick={handleClose}
          testId={`close-${activeStep + 1}`}
          className={classes.button}
          color='primary'
          variant='outlined'
        >
          Close
        </Button>),
        (<Button
          variant="contained"
          color="primary"
          onClick={handleNext}
          testId={`next-${activeStep + 1}`}
          className={classes.button}
        >
          {activeStep === STEPS.length - 1 ? 'Pre-Qualify' : 'Save'}
        </Button>)
      )
    }

    if (activeStep === 1) {
      buttons.push(<Button
        onClick={handleBack}
        testId={`back-${activeStep + 1}`}
        className={classes.button}
        color='primary'
        variant='outlined'
      >
        Back
      </Button>);

      if (!borrower?.preQualificationStatus || borrower?.preQualificationStatus === prequalificationStatusProperties.PENDING.name) {
        buttons = buttons.concat(
          (<Button
            variant="contained"
            color="primary"
            onClick={handleNext}
            testId={`finish`}
            className={classes.button}
          >
            APPLY CHANGES
          </Button>),
        );
      } else {
        buttons.push(<Button
          onClick={handleClose}
          testId={`close-${activeStep + 1}`}
          className={classes.button}
          color='primary'
          variant='outlined'
        >
          Close
        </Button>);
      }
    }

    return buttons;
  };

  return (
    <Grid xs={12} style={{ marginTop: '-24px' }}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {STEPS.map((label, index) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
            <StepContent TransitionProps={{ unmountOnExit: false }} >
              <Grid xs={12} className={classes.stepsContentContainer}>
                {getStepContent(index)}
                <Grid xs={12}>
                  {renderButtons()}
                </Grid>
              </Grid>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </Grid>
  );
});

export default BorrowerWithPreQualificationStepsForm;
