//TODO: Delete this
import { observable, action, makeObservable } from 'mobx';

interface InitOpenData {
  confirmText?: string;
  cancelButtonText?: string;
  submitButtonText?: string;
  handleSubmit?: any;
  dataToHandleOnSubmit?: any;
  titleText?: string;
}

export class ConfirmPopupStore {
  isOpen: boolean;
  confirmText: string;
  handleClose: any;
  handleSubmit: any;
  dataToHandleOnSubmit: any;
  cancelButtonText: string;
  submitButtonText: string;
  titleText: string;

  constructor() {
    this.isOpen = false;
    this.confirmText = null;
    makeObservable(this, {
      closeModal: action,
      isOpen: observable,
      openModal: action,
      reset: action,
      dataToHandleOnSubmit: observable,
      confirmText: observable,
      cancelButtonText: observable,
      submitButtonText: observable,
      titleText: observable,
    });
  }

  closeModal() {
    this.isOpen = false;
  }

  openModal(initData: InitOpenData = {}) {
    this.isOpen = true;
    this.confirmText = initData.confirmText;
    this.handleSubmit = initData.handleSubmit;
    this.dataToHandleOnSubmit = initData.dataToHandleOnSubmit;
    this.cancelButtonText = initData.cancelButtonText;
    this.submitButtonText = initData.submitButtonText;
    this.titleText = initData.titleText;
  }

  reset() {
    this.isOpen = false;
  }
}

export default ConfirmPopupStore;
