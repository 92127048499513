import { FormStore, GlobalStore, GridStore } from "@roc/feature-app-core";
import { isNil } from "@roc/feature-utils";
import { action, flow, makeObservable, observable } from 'mobx';


const propertyForm = {
  fields: {
    rooms: {
      value: null,
      error: null,
      rule: '',
    },
    bedrooms: {
      value: null,
      error: null,
      rule: '',
    },
    full_bathrooms: {
      value: null,
      error: null,
      rule: '',
    },
    half_bathrooms: {
      value: null,
      error: null,
      rule: '',
    },
    area: {
      value: null,
      error: null,
      rule: '',
    },
    condition_tag: {
      value: null,
      error: null,
      rule: '',
    },
    lot_size: {
      value: null,
      error: null,
      rule: '',
    },
    taxes: {
      value: null,
      error: null,
      rule: '',
    },
    year_built: {
      value: null,
      error: null,
      rule: '',
    },
    property_type_group: {
      value: 'single',
      error: null,
      rule: '',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export class EditSubjectPropertyFormStore extends FormStore {
  public globalStore: GlobalStore;
  areEditFieldsOpened: boolean;
  editPropertyFields: any;

  constructor(globalStore: GlobalStore) {
    super({ ...propertyForm }, globalStore);
    this.areEditFieldsOpened = false;
    this.editPropertyFields = {};

    makeObservable(this, {
      areEditFieldsOpened: observable,
      setAreEditFieldsOpened: action,
      getEditSubjectProperty: action,
      editPropertyFields: observable,
    });
  }

  setAreEditFieldsOpened(editSubjectProperty: boolean) {
    this.areEditFieldsOpened = editSubjectProperty;
  }

  loadSubjectProperty(record) {
    this.reset();
    this.loadForm(record);
  }

  getEditSubjectProperty() {
    try {
      const editPropertyFields = this.getFormValues()

      const {
        rooms,
        bedrooms,
        full_bathrooms,
        half_bathrooms,
        area,
        condition_tag,
        lot_size,
        taxes,
        year_built,
        property_type_group
      } = editPropertyFields;

      const editProperty = {
      };

      const fields = {
        "ROOMS": rooms,
        "BEDROOMS": bedrooms,
        "FULL_BATHS": full_bathrooms,
        "HALF_BATHS": half_bathrooms,
        "AREA": area,
        "CONDITION_TAG": condition_tag,
        "LOTSIZE": lot_size,
        "ASSESSOR_TAX": taxes,
        "YEARBUILT": year_built,
        "PROPERTY_TYPE_GROUP": property_type_group
      };

      for (const [key, value] of Object.entries(fields)) {
        if (!isNaN(value) && !isNil(value)) {
          editProperty[key] = parseInt(value);
        } else if (!isNil(value) && value != '') {
          editProperty[key] = value;
        }
      }
      this.editPropertyFields = editProperty;
      this.setAreEditFieldsOpened(false)
      return this.editPropertyFields;
    }
    catch (error) {
      console.log(error);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while saving property values',
      });
      return false;
    }
  }

}