import { QuoteGeneratorBaseService } from './quoteGeneratorBaseService';

const url = {
  GET_QUOTES_GRID: '/api/v1/loan/quoteGenerator/getMyQuotesGrid',
  GET_QUOTE_BY_ID: '/api/v1/loan/quoteGenerator/getQuoteById',
  SAVE_QUOTE: '/api/v1/loan/quoteGenerator/saveQuote',
  DELETE_QUOTE: '/api/v1/loan/quoteGenerator/removeQuote',
  CALCULATE_QUOTE: '/api/v1/validation/bridgeQuote/calculateBridgeQuoteDetails',
  GET_BROKER_ECONOMICS_BY_USER_ID: '/api/v1/loan/brokers/getBrokerEconomicsByUserId',
  GET_BRIDGE_QUOTE_PRESCREEN: '/api/v1/validation/bridgeQuote/getPrescreenStatusForBridgeQuote',
};

export class QuoteGeneratorService extends QuoteGeneratorBaseService {
  getMyQuotesGrid(
    page: number,
    pageSize: number,
    sortDir: string,
    sortBy: string,
    filters: string
  ): Promise<any> {
    return this.get(
      url.GET_QUOTES_GRID,
      {
        page,
        pageSize,
        sortDir,
        sortBy,
        filters,
      },
      {
        disableGlobalLoading: true,
      }
    );

    // const rows = [{ quoteId: 1 }, { quoteId: 2 }, { quoteId: 3 }];
    // return Promise.resolve({ data: { data: { rows } } });
  }

  getQuoteById(quoteId: number) {
    return this.get(url.GET_QUOTE_BY_ID, { quoteId });
  }

  saveQuote(quoteData: any) {
    return this.post(url.SAVE_QUOTE, quoteData);
  }

  deleteQuote(quoteId: number) {
    return this.delete(url.DELETE_QUOTE, { quoteId });
  }

  calculateQuote(quoteData: any) {
    return this.post(url.CALCULATE_QUOTE, quoteData).then(
      response => response.data
    );
  }

  getBrokerEconomicsByUserId(userId: number, type: string) {
    return this.get(url.GET_BROKER_ECONOMICS_BY_USER_ID, { userId, type });
  }

  getQuotePrescreen(quoteData: any) {
    return this.post(url.GET_BRIDGE_QUOTE_PRESCREEN, quoteData, {}, {
      disableGlobalLoading: true,
    }).then(
      response => response.data
    );
  }
}
