import { GlobalStore } from '@roc/feature-app-core';
import { action, makeObservable, observable } from 'mobx';
import { BorrowerEmailFormStore } from './borrowerEmailFormStore';
import { QuoteTypeStore } from '../quoteTypeStore';
import { LoanCommentsFormStore } from './loanCommentsFormStore';

export class SummaryStore {
  private globalStore: GlobalStore;
  private quoteTypeStore: QuoteTypeStore;

  showLoanCommentsDialog = false;

  borrowerEmailFormStore: BorrowerEmailFormStore;
  loanCommentsFormStore: LoanCommentsFormStore;

  constructor(globalStore: GlobalStore, quoteTypeStore: QuoteTypeStore) {
    this.globalStore = globalStore;
    this.borrowerEmailFormStore = new BorrowerEmailFormStore(
      globalStore,
      quoteTypeStore
    );
    this.loanCommentsFormStore = new LoanCommentsFormStore(
      globalStore,
      quoteTypeStore
    );
    makeObservable(this, {
      reset: action,
    });
  }

  loadLoanCommentsData(loanComments, leverageExceptionComments) {
    const form = this.loanCommentsFormStore;
    form.loadForm({
      loanComments: loanComments ?? '',
      leverageExceptionComments: leverageExceptionComments ?? ''
    });
    return form;
  }

  reset() {
    this.borrowerEmailFormStore.reset();
    this.loanCommentsFormStore.reset();
  }
}
