import { Alert } from '@material-ui/lab';
import { Button, StandardDialog } from '@roc/ui';
import { observer } from 'mobx-react';
import { useBaseStore } from '../../hooks';

export const WrongCompanyBanner = observer(() => {
  const baseStore = useBaseStore() as any;
  const showWrongCompanyBanner =
    baseStore?.companyDropdownStore?.showWrongCompanyBanner;

  const refreshPage = () => {
    window.location.reload();
  };

  return (
    <StandardDialog
      open={showWrongCompanyBanner}
      title="Please refresh your page"
      maxWidth={'sm'}
      dialogActions={
        <Button
          onClick={refreshPage}
          color="primary"
          variant="contained"
          testId="cancel"
        >
          Refresh
        </Button>
      }
      dialogContent={
        <Alert severity="warning" style={{ fontSize: '0.9rem' }}>
          <p>
            You have selected a different company in another window.
            <br />
            To continue, please reload the page.
          </p>
        </Alert>
      }
    />
  );
});
