import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { Layout } from '@roc/feature-app-core';
import { AutocompleteAddressField, Paper } from '@roc/ui';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { useFindAppraiserStore } from '../hooks';
import { AppraisersGrid } from './components/appraisersGrid';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    padding: theme.spacing(4),
  },
  instructionsContainer: {
    marginBottom: theme.spacing(3),
  },
  note: {
    fontWeight: 'bold',
    color: theme.palette.primary.dark,
  },
  autocompleteContainer: {
    marginBottom: theme.spacing(2),
  },
  gridContainer: {
    marginBottom: theme.spacing(3),
  },
}));

export const FindAppraiser = observer(() => {
  const [address, setAddress] = useState('');
  const { findAppraiserStore } = useFindAppraiserStore();
  const styles = useStyles();

  const onAddressChange = event => {
    setAddress(event?.target?.value);
    if (event?.target?.fullInfo) {
      findAppraiserStore.findAppraisers(event?.target?.fullInfo);
    }
  };

  return (
    <Layout title="Find Appraiser">
      <Paper className={styles.paper}>
        <Box className={styles.instructionsContainer}>
          <Typography gutterBottom>
            We know it can be challenging to find a good, independent appraiser.
            You can search for up to 10 appraisers who have worked with nearby
            properties in the past by typing in an address below (select from
            google's suggested auto-completions).
          </Typography>
          <Typography className={styles.note}>
            Note: To be used for 1-4 unit residential properties only, not
            including mixed use.
          </Typography>
        </Box>
        <Box className={styles.autocompleteContainer}>
          <AutocompleteAddressField
            label="Address"
            value={address}
            name="address"
            onChange={onAddressChange}
            fullWidth
            testId="address"
          />
        </Box>
        <Box className={styles.gridContainer}>
          <AppraisersGrid />
        </Box>
        <Box>
          <Typography>
            If you are unable to find an available appraiser from this list of
            suggestions, please use Tamarisk Appraisals. If you have any additional
            questions, please contact your relationship manager.
          </Typography>
        </Box>
      </Paper>
    </Layout>
  );
});
