export enum AppraisalType {
  LOAN_APPRAISAL,
  STANDALONE_APPRAISAL,
}

export enum AppraisalEvents {
  ITEM_FLAGGED_EVENT = 'ITEM FLAGGED',
  ITEM_UNFLAGGED_EVENT = 'ITEM UNFLAGGED',
  CANCEL_FORM = 'CANCELLED FORM',
  ITEM_UPDATED_COMMENT_EVENT = 'ITEM UPDATED COMMENT',
  ITEM_REVIEW_FLAGGED_EVENT = 'ITEM REVIEW FLAGGED',
  ITEM_REVIEW_UNFLAGGED_EVENT = 'ITEM REVIEW UNFLAGGED',
  CUSTOM_REVISION_ADDED = 'CUSTOM REVISION ADDED',
  REVISION_REQUESTED = 'REVISION REQUESTED'
}

export const APPRAISAL_REVIEW_EVENT_TYPE = 'Appraisal Review';