import { GridRowSelectedEvent } from '@roc/ui';
import { ApiResponse, GlobalStore } from '@roc/feature-app-core';
import { action, makeObservable, observable } from 'mobx';
import { GridStore } from '@roc/feature-app-core';
import { QuoteGeneratorBaseService } from '../services/quoteGeneratorBaseService';

export class QuoteGeneratorGridStore extends GridStore {
  private globalStore: GlobalStore;
  private quoteGeneratorService: QuoteGeneratorBaseService;

  constructor(
    globalStore: GlobalStore,
    quoteGeneratorService: QuoteGeneratorBaseService
  ) {
    super(() => this.fetchQuotesGrid());
    this.globalStore = globalStore;
    this.quoteGeneratorService = quoteGeneratorService;

    makeObservable(this, {});
  }

  private async fetchQuotesGrid() {
    try {
      const response = await this.quoteGeneratorService.getMyQuotesGrid(
        this.gridData.meta.pageNumber,
        this.gridData.meta.pageSize,
        this.gridData.meta.sortDir,
        this.gridData.meta.sortBy,
        this.gridData.meta.filters
      );
      return response;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: `Error while fetching the quotes`,
      });
    }
  }
}
