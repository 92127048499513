import {
  makeStyles,
  Theme,
  createStyles,
} from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  Divider,
  GridSize,
} from '@material-ui/core';
import { observer } from 'mobx-react';
import { ForeclosureDetailsCard } from './foreclosureDetailsCard';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    innerContainer: {
      textAlign: 'center',
    },
    content: {
      fontWeight: 'bold',
    },
    dataPoint: {
      marginTop: '5px',
    },
  })
);

interface Props {
  title: string;
  data: any[];
  columnsNum: 1 | 2 | 3 | 4 | 5 | 6;
  onEdit?: (e) => void;
  expanded?: boolean;
}

export const DetailsCard = observer(({ title, data, columnsNum, onEdit, expanded }: Props) => {
  const classes = useStyles();

  const renderDataPoint = (label: string, content: string) => {
    return (
      <Grid
        key={label}
        item
        xs={(12 / columnsNum) as GridSize}
        className={classes.dataPoint}
      >
        <Typography variant="body1" component="p" className={classes.content}>
          {content}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          {label}
        </Typography>
      </Grid>
    );
  };

  return (
    <ForeclosureDetailsCard title={title} onEdit={onEdit} expanded={expanded}>
      {
        <Grid container className={classes.innerContainer} spacing={1}>
          {data?.map((record, index) => (
            <>
              {renderDataPoint(record.name, record.value)}
              {index + 1 !== data.length && (index + 1) % columnsNum === 0 ? (
                <Grid key={`divider-${index}`} xs={12}>
                  <Divider />
                </Grid>
              ) : null}
            </>
          ))}
        </Grid>
      }
    </ForeclosureDetailsCard>
  );
});
