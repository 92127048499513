import { Service } from '@roc/feature-app-core';

const url = {
  GET_DRAWS_BY_LOAN: '/api/v1/draws/getDrawsByLoanId',
  GET_DRAW_BY_ID: '/api/v1/draws/getDrawDTO',
  GET_ALL_DRAWS: '/api/v1/draws/getAllDraws',
  GET_POINT_OF_CONTACT: '/api/v1/draws/getPointOfContactDetails',
  UPDATE_POINT_OF_CONTACT: '/api/v1/draws/updatePointOfContactDetails',
  GET_SOW_DOCUMENTS: '/api/v1/draws/getSOWDocuments',
  CANCEL_REQUEST: '/api/v1/draws/cancelDrawRequest'
};

export class DrawService extends Service {
  getDrawsByLoanId(loanId: string) {
    return this.get(`${url.GET_DRAWS_BY_LOAN}?loanId=${loanId}`);
  }

  getDrawById(drawId: string) {
    return this.get(`${url.GET_DRAW_BY_ID}?drawId=${drawId}`);
  }

  getAllDraws(
    page: number,
    pageSize: number,
    sortDir: string,
    sortBy: string,
    filters: object
  ) {
    return this.get(
      url.GET_ALL_DRAWS,
      {
        page,
        pageSize,
        sortDir,
        sortBy,
        filters,
      },
      {
        disableGlobalLoading: page > 1,
      }
    );
  }

  getPointOfContact(loanId: string) {
    return this.get(`${url.GET_POINT_OF_CONTACT}?loanId=${loanId}`);
  }

  updatePointOfContact(drawId: number, pointOfContact) {
    return this.put(`${url.UPDATE_POINT_OF_CONTACT}?drawId=${drawId}`, pointOfContact);
  }

  getSOWDocuments(loanId: string) {
    return this.get(`${url.GET_SOW_DOCUMENTS}?loanId=${loanId}`);
  }

  cancelDrawRequest(drawId: number) {
    return this.post(`${url.CANCEL_REQUEST}?drawId=${drawId}`, {});
  }
}
