import { StoreContext } from '@roc/feature-app-core';
import { useContext } from 'react';
import { UserStore } from '../stores';
import { CompanyDropdownStore } from '../stores/companyDropdownStore';

export const useUserStore = () =>
  useContext<{
    userStore: UserStore;
    companyDropdownStore: CompanyDropdownStore;
  }>(StoreContext);
