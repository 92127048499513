import { action, makeObservable } from 'mobx';
import { ApiResponse, GlobalStore } from '@roc/feature-app-core';
import { FormStore } from '@roc/feature-app-core';
import { useStore } from '@roc/client-portal-shared/hooks';
import { NewLeadService } from '@roc/feature-new-lead';

const form = {
  fields: {
    accountName: {
      value: '',
      error: null,
      rule: 'required',
      message: 'Account name is a required field',
    },
    ein: {
      value: '',
      error: null,
      rule: '',
      message: '',
    },
    ownerEmail: {
      value: '',
      error: null,
      rule: [{ required_if: ['__ownerEmailRequired__', true] }],
      message: 'Owner email is required',
    },
    __ownerEmailRequired__: {
      value: false,
      error: null,
      rule: '',
    },
    recordType: {
      value: null,
      error: null,
      rule: 'required',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export class ConvertLeadToLenderStore extends FormStore {
  globalStore: GlobalStore;
  private newLeadService: NewLeadService;
  constructor(globalStore) {
    super({ ...form }, globalStore);
    this.newLeadService = new NewLeadService();

    makeObservable(this, {
    });
  }

}
export default ConvertLeadToLenderStore;
