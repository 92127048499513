import { ApiResponse, Service } from '@roc/feature-app-core';

const url = {
  GET_TRADES: '/api/v1/assetm/trade/getTradeList',
  PROCESS_TRADE: '/api/v1/assetm/trade/processTrade',
  GET_LOANS_IN_TRADE: '/api/v1/assetm/trade/getTradeLoanList',
  GET_TRADE_BY_ID: '/api/v1/assetm/trade/getTradeById',
  CREATE_TRADE: '/api/v1/assetm/trade/createTrade',
  RETRACT_OR_REJECT_LOAN: '/api/v1/assetm/trade/retractOrRejectLoan',
  GENERATE_DOCS: '/api/v1/assetm/trade/generateTradeDocs',
  UPDATE_TRADE: '/api/v1/assetm/trade/updateTrade',
  GET_TRADES_CREATE_SCREEN: '/api/v1/assetm/trade/getTradesForTradeCreation'
};

export class TradeService extends Service {
  getTrades(
    dropdownFilters?: object,
    filters?: object,
    page?: number,
    pageSize?: number,
    sortBy?: string,
    sortDir?: string,
  ) {
    return this.get(
      url.GET_TRADES,
      {
        dropdownFilters,
        filters,
        page,
        pageSize,
        sortBy,
        sortDir,
      },
      {
        disableGlobalLoading: false,
      }
    );
  }

  getLoansInTrade(
    tradeId: number,
    page: number,
    pageSize: number,
    sortDir: string,
    sortBy: string,
    filters: object
  ) {
    return this.get(url.GET_LOANS_IN_TRADE,
      {
        tradeId: tradeId,
        filters: filters,
        page: page,
        pageSize: pageSize,
        sortBy: sortBy,
        sortDir: sortDir,
      },
      {
        disableGlobalLoading: true,
      });
  }

  getLoansToAdd(
    page: number,
    pageSize: number,
    sortDir: string,
    sortBy: string,
    filters: object
  ) {
    return new ApiResponse({
      data: {
        totalCount: 4,
        rows: [
          {
            tempId: 1002920293,
            loanId: 40000,
            amount: 400000,
            fundedAmount: 500000,
            originationDate: "07/10/2023",
            term: 12,
            interestRate: 10,
            wholeANote: "Whole Loan",
            tradeProceeds: 50000
          },
          {
            tempId: 1323220293,
            loanId: 41000,
            amount: 400000,
            fundedAmount: 500000,
            originationDate: "07/10/2023",
            term: 12,
            interestRate: 10,
            wholeANote: "Whole Loan",
            tradeProceeds: 0
          },
          {
            tempId: 49494948484,
            loanId: 57777,
            amount: 200000,
            fundedAmount: 500000,
            originationDate: "07/10/2023",
            term: 12,
            interestRate: 10,
            wholeANote: "Whole Loan",
            tradeProceeds: 1000
          },
          {
            tempId: 444444444444,
            loanId: 21000,
            amount: 200000,
            fundedAmount: 500000,
            originationDate: "07/10/2023",
            term: 12,
            interestRate: 10,
            wholeANote: "Whole Loan",
            tradeProceeds: 1000
          },
        ]
      }
    }, {});
  }

  getTradeById(tradeId: any) {
    return this.get(`${url.GET_TRADE_BY_ID}?tradeId=${tradeId}`);
  }

  createTrade(request) {
    return this.post(url.CREATE_TRADE, request);
  }

  processTrade(tradeId: any) {
    this.get(`${url.PROCESS_TRADE}?tradeId=${tradeId}`)
  }

  rejectLoan(request: any) {
    this.post(url.RETRACT_OR_REJECT_LOAN, request);
  }

  generateDocs(tradeId: any, buyerName: string, documentType: string) {
    this.get(`${url.GENERATE_DOCS}?tradeId=${tradeId}&buyer=${buyerName}&documentType=${documentType}`);
  }

  getTradesCreateScreen(
    dropdownFilters?: object,
    filters?: object,
    page?: number,
    pageSize?: number,
    sortBy?: string,
    sortDir?: string,
  ) {
    return this.get(
      url.GET_TRADES_CREATE_SCREEN,
      {
        dropdownFilters,
        filters,
        page,
        pageSize,
        sortBy,
        sortDir,
      },
      {
        disableGlobalLoading: true,
      }
    );
  }

  updateTrade(trade) {
    return this.post(url.UPDATE_TRADE, trade);
  }
}