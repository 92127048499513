import { Box, Grid, makeStyles, Radio } from '@material-ui/core';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { StepTitle } from '../../components/stepTitle';
import { LeverageFormStore } from '../../stores/loanEconomics/leverageFormStore';
import { QuoteTypeStore } from '../../stores/quoteTypeStore';
import { sum } from '../../utils/utils';
import { LeverageExceptionAlert } from '../summary/components/leverageExceptionAlert';
import { ErrorsAlert } from '../preflight/preflightStep';
import { MIN_FINANCED_IR_TOTAL_PURCHASE_PRICE } from '../../utils/constants';

const useStyles = makeStyles(theme => ({
  label: {
    fontWeight: 'bold',
    fontSize: '1rem',
  },
  activeLabel: {
    color: theme.palette.primary.main,
  },
  inactiveLabel: {
    color: theme.palette.text.secondary,
  },
}));

export interface LeverageCardProps {
  store: LeverageFormStore;
  disabled: boolean;
  instaQuote?: boolean;
  showEmptyValues?: boolean;
  LeverageMatrixCard?: React.FC<LeverageMatrixCardProps>;
  quoteTypeStore: QuoteTypeStore;
}
export interface ITableMatrixData {
  COLUMNS_2: Record<string, any>;
  DATA_2: Record<string, any>;
  ROW_VALUE_KEYS: string[];
}
export interface LeverageMatrixCardProps {
  store: QuoteTypeStore;
  data?: ITableMatrixData;
}

interface LeverageStepProps {
  store: QuoteTypeStore;
  allowInstaQuote: boolean;
  showFinancedIR?: boolean;
  LeverageCard: React.FC<LeverageCardProps>;
  LeverageMatrixCard?: React.FC<LeverageMatrixCardProps>;
  FinancedInterestReserve?: React.FC<LeverageMatrixCardProps>;
}

export const LeverageStep = observer(
  ({
    store,
    allowInstaQuote,
    LeverageMatrixCard,
    LeverageCard,
    FinancedInterestReserve,
  }: LeverageStepProps) => {
    const classes = useStyles();
    const { loanEconomicsStore } = store;
    const { enableManualQuote, userInputOnLeverage, instaQuoteRanges } = loanEconomicsStore;
    const isManualLeverageRequired = store.isManualLeverageRequired(loanEconomicsStore.instaLeverageQuoteFormStore?.calculatedFields?.totalLoanAmount || 0) || !allowInstaQuote;

    const showFinancedInterestReserve = !!loanEconomicsStore.showFinancedInterestReserve && !!FinancedInterestReserve;

    const totalPurchasePrice = sum(
      store.propertiesStore.propertyFormStores.map(
        formStore => formStore.form.fields.purchasePrice.value || 0
      )
    );

    useEffect(() => {
      loanEconomicsStore.checkFinancedInterestReserveRules(totalPurchasePrice);
    },[totalPurchasePrice])

    useEffect(() => {
      if (!isManualLeverageRequired && enableManualQuote && !userInputOnLeverage) {
        loanEconomicsStore.setEnableManualQuote(false);
      }
    }, [instaQuoteRanges?.maxLoanToCost]);

    const propertyFormHash = JSON.stringify([
      store.propertiesStore.propertyFormStores.map(form =>
        form.getFormValues()
      ),
    ]);

    useEffect(() => {
      loanEconomicsStore.checkPropertyFormHash(propertyFormHash);
    }, [propertyFormHash])

    const hash = JSON.stringify([
      store.borrowersStore.borrowerFormStores.map(form => form.getFormValues()),
      store.propertiesStore.propertyFormStores.map(form =>
        form.getFormValues()
      ),
    ]);

    useEffect(() => {
      if (totalPurchasePrice > 0) {
        loanEconomicsStore.fecthInstaQuoteRanges();
      }
    }, [totalPurchasePrice, hash]);

    const do_enableManualQuote = () => {
      loanEconomicsStore.setUserInputOnLeverage(true);
      loanEconomicsStore.setEnableManualQuote(true);
    };

    const do_enableInstaQuote = () => {
      if (!isManualLeverageRequired) {
        loanEconomicsStore.setUserInputOnLeverage(true);
        loanEconomicsStore.setEnableManualQuote(false);
      }
    };

    useEffect(() => {
      if (isManualLeverageRequired && !enableManualQuote) {
        loanEconomicsStore.setEnableManualQuote(true);
      }
    }, [isManualLeverageRequired, loanEconomicsStore.instaLeverageQuoteFormStore?.calculatedFields?.totalLoanAmount]);

    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={showFinancedInterestReserve ? 8 : 12}>
            <StepTitle
              title={<>Leverage</>}
              subtitle={
                <>
                  Below in green is the maximum leverage we can offer based on the
                  borrower's FICO score and experience. You can adjust this using the (+)
                  and (-) buttons or request leverage if desired.
                </>
              }
            />
          </Grid>
          {!!showFinancedInterestReserve && (
            <Grid item xs={4}>
              <FinancedInterestReserve store={store} />
            </Grid>
          )}
          <Grid item xs={12}>
            <Box display="flex" flexDirection={'row'} alignItems={'center'}>
              <Box>
                <Radio
                  checked={!enableManualQuote}
                  onChange={() => do_enableInstaQuote()}
                  value=""
                  name=""
                  color='primary'
                  disabled={isManualLeverageRequired}
                />
              </Box>
              <Box style={isManualLeverageRequired ? { flexGrow: 1 } : { cursor: 'pointer', flexGrow: 1 }} onClick={() => {
                do_enableInstaQuote()
              }}>
                <LeverageCard
                  store={loanEconomicsStore.instaLeverageQuoteFormStore}
                  disabled={enableManualQuote}
                  instaQuote
                  showEmptyValues={isManualLeverageRequired}
                  LeverageMatrixCard={LeverageMatrixCard}
                  quoteTypeStore={store}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" flexDirection={'row'} alignItems={'center'}>
              <Box>
                <Radio
                  checked={enableManualQuote}
                  onChange={() => do_enableManualQuote()}
                  value=""
                  name=""
                  color='primary'
                />
              </Box>
              <Box style={{ cursor: 'pointer', flexGrow: 1 }} onClick={() => {
                do_enableManualQuote()
              }}>
                <LeverageCard
                  store={loanEconomicsStore.manualExceptionQuoteFormStore}
                  disabled={!enableManualQuote}
                  quoteTypeStore={store}
                />
              </Box>
            </Box>
          </Grid>
          {
            enableManualQuote ? (
              <Grid item xs={12}>
                <Box ml={5}>
                  <LeverageExceptionAlert />
                </Box>
              </Grid>
            ) : null
          }
          {
            store.stepErrors?.length > 0 ? (
              <Grid item xs={12}>
                <Box ml={5}>
                  <ErrorsAlert errors={store.stepErrors} />
                </Box>
              </Grid>
            ) : null
          }
        </Grid>
      </>
    );
  }
);
