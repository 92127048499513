import { Grid } from '@material-ui/core';
import { isFixAndFlip, LoanSubType, REFINANCE, renovationDescriptionsByLoanSubtype } from '@roc/feature-utils';

import {
  PurchasePrice,
  ArmsLength,
  ArmsLengthDescribe,
  PropertySource,
  PropertySourceOther,
  WholesalerPropertyPaid,
  WholesalerPropertySource,
  WholesalerPropertySourceOther,
  RenovationBudget,
  RenovationDescription,
  AfterRepairValue,
  ExitStrategy,
  AdditionalComments,
  WholesalerRelationship,
  IsSellerCredit,
  SellerCreditAmount,
  MonthlyMarketRent,
  AnnualTaxes,
  AnnualInsurance,
  AnnualHoa,
  PermitsInPlace,
  SquareFootageAdded,
  RehabDetails,
  FireDamage
} from '@roc/ui/formComponents';
import { observer } from 'mobx-react';
import { useEffect } from 'react';



export const PropertyPurchaseFields = observer(({ propertyStore, loanSubtype }) => {
  const { purchaseInformationStore, appraisalId } = propertyStore;
  const isArmsLengthNo =
    purchaseInformationStore.form.fields.armsLength.value === 'N';
  const isPropertySourceOther =
    purchaseInformationStore.form.fields.propertySourcing.value === 'Other';
  const isWholesalerProperty =
    purchaseInformationStore.form.fields.propertySourcing.value ===
    'Wholesaler';
  const isWholesalerPropertySourceOther =
    purchaseInformationStore.form.fields.wholesalerPurchase.value === 'Other';
  const isSellerCredit =
    purchaseInformationStore.form.fields.sellerCredit.value === true;
  return (
    <>
      <Grid item xs={12}>
        <PurchasePrice store={purchaseInformationStore} />
      </Grid>
      <Grid item xs={12}>
        <ArmsLength store={purchaseInformationStore} />
      </Grid>
      {isArmsLengthNo && (
        <Grid item xs={12}>
          <ArmsLengthDescribe store={purchaseInformationStore} showError />
        </Grid>
      )}
      <Grid item xs={12}>
        <PropertySource store={purchaseInformationStore} disabled={false} />
      </Grid>
      {isPropertySourceOther && (
        <Grid item xs={12}>
          <PropertySourceOther store={purchaseInformationStore} disabled={false} />
        </Grid>
      )}
      {isWholesalerProperty && (
        <Grid item xs={12}>
          <WholesalerPropertyPaid store={purchaseInformationStore} disabled={false} />
        </Grid>
      )}
      {isWholesalerProperty && (
        <Grid item xs={12}>
          <WholesalerPropertySource store={purchaseInformationStore} disabled={false} />
        </Grid>
      )}
      {isWholesalerProperty && isWholesalerPropertySourceOther && (
        <Grid item xs={12}>
          <WholesalerPropertySourceOther store={purchaseInformationStore} disabled={false} />
        </Grid>
      )}
      {isWholesalerProperty && isFixAndFlip(loanSubtype) && (
        <Grid item xs={12}>
          <WholesalerRelationship store={purchaseInformationStore} disabled={false} />
        </Grid>
      )}
      {isFixAndFlip(loanSubtype) &&
        <>
          <Grid item xs={12}>
            <IsSellerCredit store={purchaseInformationStore} disabled={false} />
          </Grid>
          {isSellerCredit && (
            <Grid item xs={12}>
              <SellerCreditAmount store={purchaseInformationStore} disabled={false} />
            </Grid>
          )}
        </>
      }
    </>
  );
})


export const PurchaseRenovationFields = observer(({ propertyStore, loanSubtype }) => {
  const { renovationDetailsStore } = propertyStore;
  const totalRenovationBudget =
    renovationDetailsStore.form.fields.totalRenovationBudget.value;
  const renovationDescriptionOptions = renovationDescriptionsByLoanSubtype(
    loanSubtype
  );

  const showFixFlipProFields = loanSubtype === LoanSubType.FIX_AND_FLIP_PRO;

  useEffect(() => {
    renovationDetailsStore.onFieldChange('loanSubType', loanSubtype);
  }, [loanSubtype]);

  return (
    <>
      <Grid item xs={12}>
        <RenovationBudget
          store={renovationDetailsStore}
          disabled={false}
          onChange={() =>
            renovationDetailsStore.handleRenovationBudgetChange(loanSubtype)
          }
        />
      </Grid>
      <Grid item xs={12}>
        {(totalRenovationBudget > 0 && !(LoanSubType.GROUND_UP == loanSubtype)) && (
          <RenovationDescription
            store={renovationDetailsStore}
            disabled={false}
            renovationDescriptionOptions={renovationDescriptionOptions}
            showError
          />
        )}
      </Grid>
      {isFixAndFlip(loanSubtype) && (
        <>
          <Grid item xs={12}>
            <FireDamage store={renovationDetailsStore} />
          </Grid>
          <Grid item xs={12}>
            <SquareFootageAdded store={renovationDetailsStore} />
          </Grid>
        </>
      )}
      {showFixFlipProFields && (
        <Grid item xs={12}>
          <RehabDetails store={renovationDetailsStore} postRehabGradeFieldsDisabled={!renovationDetailsStore.form.fields.squareFootageAdded.value} />
        </Grid>
      )}
      {loanSubtype === LoanSubType.GROUND_UP && (
        <Grid item xs={12}>
          <PermitsInPlace store={renovationDetailsStore} />
        </Grid>
      )}
    </>
  );
});


export const PurchaseOtherFields = observer(({ propertyStore }) => {
  const { otherDetailsStore, globalStore } = propertyStore;
  const exitStrategy = otherDetailsStore.form.fields.exitStrategy.value;

  return (
    <>
      <Grid item xs={12}>
        <AfterRepairValue store={otherDetailsStore} disabled={false} />
      </Grid>
      <Grid item xs={12}>
        <ExitStrategy store={otherDetailsStore} disabled={false} />
      </Grid>
      {exitStrategy === REFINANCE ? (
        <>
          <Grid item xs={6}>
            <MonthlyMarketRent store={otherDetailsStore} />
          </Grid>
          <Grid item xs={6}>
            <AnnualTaxes store={otherDetailsStore} />
          </Grid>
          <Grid item xs={6}>
            <AnnualInsurance store={otherDetailsStore} />
          </Grid>
          <Grid item xs={6}>
            <AnnualHoa store={otherDetailsStore} />
          </Grid>
        </>
      ) : null}
    </>
  );
});