import { Box, Button, Grid, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { Delete, Edit } from '@material-ui/icons';
import { AgGridColumnProps, AgGridReact, Paper } from '@roc/ui';
import { formatCurrency } from '@roc/ui/utils';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import {
  PropertyTypesModal,
  PropertyTypesModalState,
} from '@roc/feature-loans';
import { useRentalPortfolioStore } from './../../../loanSubmission';

const noOpComparator = () => 0;

const usdAmount: any = params => {
  return formatCurrency(Number(params.value ?? 0));
};

const propertyTypesColumns: AgGridColumnProps[] = [
  {
    field: 'propertyType',
    headerName: 'Property Type',
    maxWidth: 250,
    comparator: noOpComparator,
  },
  {
    field: 'numberOfProperties',
    headerName: 'Number Of Properties',
    minWidth: 200,
    comparator: noOpComparator,
  },
  {
    field: 'estimatedAsIsValue',
    headerName: 'Estimated As Is Value',
    minWidth: 200,
    valueFormatter: usdAmount,
    comparator: noOpComparator,
  },
  {
    field: 'propertyId',
    headerName: 'Actions',
    minWidth: 100,
    cellRenderer: 'actionsCellRenderer',
    comparator: noOpComparator,
  },
];

const useStyles = makeStyles(theme => ({
  grid: {
    width: '100%',
    height: '100%',
    '& .ag-root-wrapper': {
      borderColor: '#ddd',
    },
  },
  addPropertyButton: {
    color: theme.palette.secondary.main,
  },
}));

const PropertyTypesActions = ({ onEdit, onDelete, ...rest }) => {
  return (
    <Grid>
      <IconButton onClick={() => onEdit(rest)}>
        <Edit fontSize="small" />
      </IconButton>
      <IconButton onClick={() => onDelete(rest)}>
        <Delete fontSize="small" />
      </IconButton>
    </Grid>
  );
};

export const PropertyTypesGrid = observer(() => {
  const { rentalPortfolioStore } = useRentalPortfolioStore();
  const classes = useStyles();
  const [modalState, setModalState] = useState<PropertyTypesModalState>(null);
  const {
    propertyTypesStore,
    deleteProperty,
    properties,
  } = rentalPortfolioStore;

  const closeModal = () => {
    setModalState(null);
    propertyTypesStore.reset();
  };

  const onEdit = property => {
    setModalState(PropertyTypesModalState.EDIT);
    propertyTypesStore.loadProperty(property);
  };

  const onDelete = ({ propertyId }) => {
    deleteProperty(propertyId);
  };

  return (
    <>
      <Grid item xs={12}>
        <Button
          variant="outlined"
          color="primary"
          fullWidth
          className={classes.addPropertyButton}
          onClick={() => setModalState(PropertyTypesModalState.ADD)}
        >
          Add Property Type
        </Button>
      </Grid>

      <Grid item xs={12}>
        <Box className={`ag-theme-alpine ${classes.grid}`}>
          <Paper>
            <AgGridReact
              defaultColDef={{
                flex: 1,
                minWidth: 100,
                sortable: false,
                resizable: false,
                suppressMenu: true,
              }}
              columnDefs={propertyTypesColumns}
              rowData={[...properties]}
              domLayout="autoHeight"
              suppressCellSelection={true}
              frameworkComponents={{
                actionsCellRenderer: ({ data }) => (
                  <PropertyTypesActions
                    onEdit={onEdit}
                    onDelete={onDelete}
                    {...data}
                  />
                ),
              }}
              getRowHeight={() => {
                return 48;
              }}
            />
          </Paper>
        </Box>
      </Grid>

      <PropertyTypesModal
        open={modalState != null}
        modalState={modalState}
        handleClose={closeModal}
        store={rentalPortfolioStore}
        handleAdd={() => {
          propertyTypesStore.setPropertyId(Date.now());
          if (propertyTypesStore.areValidPropertyDetails()) {
            rentalPortfolioStore.addProperty(propertyTypesStore.getProperty());
            closeModal();
          }
        }}
        handleEdit={() => {
          if (propertyTypesStore.areValidPropertyDetails()) {
            rentalPortfolioStore.updateProperty(
              propertyTypesStore.getProperty()
            );
            closeModal();
          }
        }}
      />
    </>
  );
});
