import { StandardDialog } from "@roc/ui";
import { observer } from "mobx-react";
import { makeStyles } from '@material-ui/core/styles';
import { Box } from "@material-ui/core";

const useStyles = makeStyles({
  dialogContentclasses: {
    padding: '8px 0'
  },
});

export type Props = {
  open: boolean,
  onClose: any
}

export const ScopeOfWorkDialog = observer((props: Props) => {
  const {open, onClose} = props
  const classes = useStyles();
  return(
    <StandardDialog
      open={open}
      maxWidth="lg"
      handleClose={onClose}
      removeContentBox
      dialogContentclasses={{
        root: classes.dialogContentclasses
      }}
      dialogContent={(
        <Box width={'100%'}>
        </Box>
      )}
    />
  )
});