
export const getDealName = (borrowers, properties) => {
  let dealName = borrowers.map(borrower => borrower.lastName).join(' ');

  if (properties.length > 0) {
    dealName += ' - '
    properties.forEach((property, index) => {
      if (index != 0) {
        dealName += '/';
      }
      let stringProperty = '';
      const { streetName, streetNumber, state } = property;

      if (streetNumber) {
        stringProperty += `${streetNumber} `;
      }
      if (streetName) {
        stringProperty += `${streetName} `;
      }
      if (state) {
        stringProperty += `(${state})`;
      }

      dealName += stringProperty.trim();
    });
  }

  return dealName;
};
