import { Service } from '@roc/client-portal-shared/services';

const url = {
  SUBMIT_DRAW_REQUEST: '/nportal/rest/pub/draws/submitDrawRequest',
  SAVE_DRAW_REQUEST: '/nportal/rest/pub/draws/saveDrawRequest',
  GET_DRAW_REQUEST_PUBLIC: '/nportal/rest/pub/draws/getRequestDraw',
};

const addQueryParam = (params: string, key: string, value: string) => {
  if (!key || !value) {
    return params;
  }
  return `${params ? `${params}&` : ''}${key}=${value}`;
};

export class DrawRequestService extends Service {

  getDrawDetailsPub(drawId: number) {
    return this.get(`${url.GET_DRAW_REQUEST_PUBLIC}?drawId=${drawId}`);
  }

  submitDrawRequest(drawid: number, body: any, silent = false) {
    const options = { disableGlobalLoading: silent };
    return this.post(
      `${url.SUBMIT_DRAW_REQUEST}?drawId=${drawid}`,
      body,
      {},
      options
    );
  }

  saveDrawRequest(drawid: number, body: any, silent = false) {
    const options = { disableGlobalLoading: silent };
    return this.post(
      `${url.SAVE_DRAW_REQUEST}?drawId=${drawid}`,
      body,
      {},
      options
    );
  }
}
