import { observer } from 'mobx-react';
import { OneToolQuoteStepper } from './components/oneToolQuoteStepper';
import { useOneToolStore } from './hooks/useOneToolStore';
import { BorrowerInformationStep } from './steps/borrowerInformation/borrowerInformationStep';
import { FixFlipLeverageCard } from './steps/leverage/components/fixFlipLeverageCard';
import { FixFlipGULeverageMatrixCard } from './steps/leverage/components/fixFlipGULeverageMatrixCard';
import { LeverageStep } from './steps/leverage/leverageStep';
import { FixFlipLoanDetailsCard } from './steps/loanEconomics/components/fixFlipLoanDetailsCard';
import { EconomicsStep } from './steps/loanEconomics/economicsStep';
import { FixFlipPropertyInformationForm } from './steps/propertyInformation/forms/fixFlipPropertyInformationForm';
import { PropertyInformationStep } from './steps/propertyInformation/propertyInformationStep';
import { SummaryStep } from './steps/summary/summaryStep';
import { PropertySource, quoteSteps } from './utils/constants';
import { PreflightStep } from './steps/preflight/preflightStep';
import { LoanSuccessStep } from './steps/common/loanSuccess';

export const FixFlipQuote = observer(() => {
  const { oneToolStore } = useOneToolStore();
  const { fixFlipQuoteStore } = oneToolStore;

  const propertyCount =
    fixFlipQuoteStore.propertiesStore.propertyFormStores?.length;
  const isWholesale = fixFlipQuoteStore.propertiesStore.propertyFormStores?.some?.(
    form =>
      PropertySource.WHOLESALER === form.form.fields.propertySourcing.value
  );

  return (
    <OneToolQuoteStepper
      title={'Create a Quote - Fix and Flip Loan'}
      store={fixFlipQuoteStore}
      preflightStep={
        <PreflightStep
          store={fixFlipQuoteStore}
          LoanDetailsCard={FixFlipLoanDetailsCard}
          PropertyInformationForm={FixFlipPropertyInformationForm}
        />
      }
      successStep={<LoanSuccessStep store={fixFlipQuoteStore} />}
    >
      <BorrowerInformationStep store={fixFlipQuoteStore} />
      <PropertyInformationStep
        store={fixFlipQuoteStore}
        PropertyInformationForm={FixFlipPropertyInformationForm}
      />
      <LeverageStep
        store={fixFlipQuoteStore}
        allowInstaQuote={propertyCount <= 1}
        LeverageMatrixCard={FixFlipGULeverageMatrixCard}
        LeverageCard={FixFlipLeverageCard}
      />
      <EconomicsStep
        store={fixFlipQuoteStore}
        LoanDetailsCard={FixFlipLoanDetailsCard}
      />
      <SummaryStep
        store={fixFlipQuoteStore}
        LoanDetailsCard={FixFlipLoanDetailsCard}
      />
    </OneToolQuoteStepper>
  );
});
