import { action, flow, makeObservable, observable } from 'mobx';
import { GlobalStore } from '../../../stores';
import { FormStore } from '@roc/feature-app-core';

const REQUIRED_MESSAGE = 'This field is required';
const RESTRICTED_STATUSES = ['Approved', 'Committee Decision'];

const form = {
  fields: {
    status: {
      value: '',
      error: null,
      rule: '',
      message: REQUIRED_MESSAGE,
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export class ApprovalFlowStepperStore extends FormStore {
  private globalStore: GlobalStore;

  lenderStore;
  errorModalOpen: boolean;
  errorModalMessage: string;

  constructor(globalStore, lenderStore) {
    super(form, globalStore);
    this.globalStore = globalStore;
    this.lenderStore = lenderStore;
    makeObservable(this, {
      reloadLenderDetails: action,
      setStatus: action,
      setErrorModalOpen: action,
      setErrorModalMessage: action,
      loadLenderDetails: action,
      saveLenderDetails: flow,
      updateLenderAndShowNotification: flow,
      isStatusChangeAllowed: flow,
      errorModalOpen: observable,
      errorModalMessage: observable
    });
  }

  setStatus(status: string) {
    this.form.fields.status.value = status;
  }

  setErrorModalOpen(open) {
    this.errorModalOpen = open
  }

  setErrorModalMessage(message) {
    this.errorModalMessage = message
  }

  reloadLenderDetails() {
    this.loadLenderDetails();
  }

  loadLenderDetails() {
    this.lenderStore.salesForceLender && this.loadForm(this.lenderStore.salesForceLender);
  }

  *saveLenderDetails(status) {
    try {
      const isAllowed = yield this.isStatusChangeAllowed(status)
      if (isAllowed) {
        const lender = { ...this.lenderStore.salesForceLender, status };
        yield this.updateLenderAndShowNotification(
          lender,
          'Lender Status has been updated successfully.'
        );
        this.loadForm({ status });
      }
    } catch (error) {
      console.log('Error while saving Lender Status', error);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while saving Lender Status',
      });
    }
  }

  *isStatusChangeAllowed(status) {
    const lenderIsInternal = this.lenderStore.salesForceLender.isInternal;
    const isRestrictedStatus = RESTRICTED_STATUSES.includes(status);

    if (this.globalStore.isInternalPortal || this.lenderStore.portal === 'SalesForce') {
      const isDecisionCommittee = yield this.lenderStore.checkIsInternalDecisionCommitteeOrLead();
      if (status === RESTRICTED_STATUSES[0] && isDecisionCommittee === false) {
        this.showCommitteeErrorMessage();
        return false;
      }
      if (RESTRICTED_STATUSES.includes(status) && isDecisionCommittee === true && !lenderIsInternal) {
        if (!(this.lenderStore.salesForceLender.lenderName.length <= 10)) {
          this.showAliasErrorMessage();
          return false;
        }
      }
    } else if (status === RESTRICTED_STATUSES[0] && !(this.globalStore.userFeatures.isDecisionCommittee || this.globalStore.userFeatures.backOfficeLead)) {
      this.showCommitteeErrorMessage();
      return false;
    }
    else if (RESTRICTED_STATUSES.includes(status) && this.globalStore?.userFeatures?.backOfficeUser && !lenderIsInternal) {
      if (!(this.lenderStore.salesForceLender.lenderName.length <= 10)) {
        this.showAliasErrorMessage();
        return false;
      }
    }

    // if (isRestrictedStatus && lenderIsInternal === false) {
    //   const { ableToUpdate, errorMessage } = yield this.lenderStore.requiredDocsUploaded(this.lenderStore.salesForceLender.lenderId)
    //   !ableToUpdate ? this.errorModalOpen = true : this.errorModalOpen = false
    //   !ableToUpdate ? this.errorModalMessage = errorMessage : this.errorModalMessage = '';
    //   return ableToUpdate;
    // }

    return true;
  }

  showCommitteeErrorMessage() {
    this.globalStore.notificationStore.showErrorNotification({
      message: 'Only Committee can move forward to this status',
    });
  }

  showAliasErrorMessage() {
    this.globalStore.notificationStore.showErrorNotification({
      message: 'Alias must be less or equal to 10 characters',
    });
  }

  *updateLenderAndShowNotification(lender, successMessage) {
    const response = yield this.lenderStore.updateLenderFields(lender);
    this.globalStore.notificationStore.showSuccessNotification({
      message: successMessage,
    });
    return response;
  }
}
