import { useBaseStore } from '@roc/feature-app-core';
import { getAppsRoutes, getAppsRoutesConfig } from '../routes/appsRoutes';

export const useAppsRoutes = () => {
  const { globalStore } = useBaseStore();
  const { routeBasePath, routeBaseUrl } = globalStore;

  return {
    appsRoutes: getAppsRoutes(routeBasePath, routeBaseUrl),
    appsRoutesConfig: getAppsRoutesConfig(routeBasePath, routeBaseUrl),
  };
};