import {
  Box,
  Grid,
  InputLabel,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { NumberFormat } from '@roc/ui';

const useStyles = makeStyles(theme => ({
  label: {
    fontWeight: 600,
  },
}));

export const Label = props => {
  const { label, xs, md } = props;
  const classes = useStyles();

  return (
    <Grid item xs={xs} md={md}>
      <Typography className={classes.label}>{label}</Typography>
    </Grid>
  );
};

export const Value = props => {
  const { value, xs, md, testId } = props;
  return (
    <Grid item xs={xs} md={md}>
      <Typography id={testId}>{value}</Typography>
    </Grid>
  );
};

export const Currency = props => {
  const { value, xs, md, testId } = props;
  return (
    <Grid item xs={xs} md={md}>
      <NumberFormat
        testId={testId}
        displayType="text"
        value={value}
        thousandSeparator={true}
        decimalScale={2}
        fixedDecimalScale={true}
        prefix={'$'}
      />
    </Grid>
  );
};

export const Percentage = props => {
  const { value, xs, md, decimals = 2, testId } = props;
  return (
    <Grid item xs={xs} md={md}>
      <NumberFormat
        testId={testId}
        displayType="text"
        value={value}
        decimalScale={decimals}
        fixedDecimalScale={true}
        suffix={'%'}
      />
    </Grid>
  );
};

export const LabeledText = props => {
  const { label, value, currency, percentage, number, testId } = props;

  return (
    <>
      <Label label={label} xs={12} md={3} />
      {currency ? (
        <Currency value={value} xs={12} md={9} testId={testId} />
      ) : percentage ? (
        <Percentage value={value} xs={12} md={9} testId={testId} />
      ) : (
        <Value value={value} xs={12} md={9} testId={testId} />
      )}
    </>
  );
};

export const Section = props => {
  const { label, children, xs, md } = props;
  return (
    <Grid item xs={12}>
      <Box sx={{ pt: 2 }}>
        <Typography variant="h5">{label}</Typography>
      </Box>
    </Grid>
  );
};
