import { Box } from '@material-ui/core';
import { QuoteTypeStore } from '../../../stores/quoteTypeStore';
import { formatPercentage } from '@roc/ui/utils';
import Slider from '@material-ui/core/Slider';
import { observer } from 'mobx-react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { fontWeight } from 'html2canvas/dist/types/css/property-descriptors/font-weight';
import { position } from 'html2canvas/dist/types/css/property-descriptors/position';
import { textAlign } from 'html2canvas/dist/types/css/property-descriptors/text-align';

interface Props {
  store: QuoteTypeStore;
}

const styles: any = (theme: Theme) => ({
  root: ({ min, max, interestRate, floorRate, capitalProviderRate }) => {
    const leftPos = ((capitalProviderRate - min) / (max - min)) * 100;
    const trackWidth =
      ((interestRate - capitalProviderRate) / (max - min)) * 100;
    return {
      paddingBottom: '30px',
      position: 'relative',
      '& .MuiSlider-root': {
        padding: '20px 0  !important',
      },
      '& .MuiSlider-rail': {
        backgroundColor: '#CEABEA',
        height: '4px',
        borderRadius: '2px',
      },
      '& .MuiSlider-track': {
        backgroundColor: '#852DCA',
        height: '4px',
        left: `${leftPos}% !important`,
        width: `${trackWidth}%  !important`,
      },
      '& .MuiSlider-mark': {
        display: 'none',
      },
      '& .yieldSpread': {
        position: 'absolute',
        left: `${leftPos}%`,
        top: '55px',
        fontSize: '12px',
        textAlign: 'center',
        textWrap: 'nowrap',
        width: '0px',
        direction: leftPos > 70 ? 'rtl' : 'ltr',
        textIndent: '10px',
      },
      '& $floorRateThumb': {
        border: '1px solid red',
      },
    };
  },

  thumb: {
    position: 'absolute',
    width: 0,
    height: 0,
    color: theme,
    outline: 'none',
  },
  floorRateThumb: {
    color: 'rgba(0,0,0,0.8)',
    top: '51px',
    pointerEvents: 'none',
    marginTop: '4px',
    '& $thumbIcons': {
      transform: 'rotate(180deg)',
    },
  },
  borrowerRateThumb: {
    color: theme.palette.primary.main,
    marginTop: '-67px',
  },
  thumbContent: {
    width: '200px',
    height: '70px',
    marginLeft: '-100px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  thumbBubble: {
    padding: '0 12px',
    backgroundColor: 'currentColor',
    color: 'inherit',
    textAlign: 'center',
    borderRadius: '4px',
  },
  thumbText: {
    color: 'white',
    fontSize: '10px',
    marginTop: '-4px',
  },
  thumbValue: {
    color: 'white',
    fontSize: '14px',
    fontWeight: 'bold',
    paddingBottom: '2px',
    marginTop: '-5px',
  },
  thumbArrow: {
    width: 12,
    height: 6,
    display: 'block',
  },
  thumbPin: {
    width: 20,
    height: 17,
    display: 'block',
  },
  thumbIcons: {
    height: 25,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

const useStyles = makeStyles(styles);

const ThumbArrow = ({ className }) => (
  <svg viewBox="0 0 4 2" className={className}>
    <path d="M0,0 L2,2 L4,0 Z" fill="currentColor" />
  </svg>
);

const ThumbIcon = ({ className }) => (
  <svg viewBox="0 0 9 12" className={className}>
    <path
      d="M 4.5,12
      L 1.5,4.4
      C 0.7,2.2 2.3,0.0 4.5,0.0
      C 6.8,0.0 8.3,2.7 7.6,4.3
      L 4.5,12 Z"
      fill="currentColor"
    />
  </svg>
);

const ThumbComponent = ({ muiProps, isFloorRate, interestRate, floorRate }) => {
  const classes = useStyles({});
  const className = isFloorRate
    ? classes.floorRateThumb
    : classes.borrowerRateThumb;

  const icons = (
    <div className={classes.thumbIcons}>
      <ThumbArrow className={classes.thumbArrow} />
      <ThumbIcon className={classes.thumbPin} />
    </div>
  );

  const props = isFloorRate ? { style: muiProps.style } : muiProps;

  return (
    <div {...props} className={`${classes.thumb} ${className}`}>
      <div className={classes.thumbContent}>
        {isFloorRate && icons}
        <div className={classes.thumbBubble}>
          <span className={classes.thumbText} style={{ fontSize: '12px' }}>
            {isFloorRate ? `Capital Provider Rate` : `Borrower Rate`}
          </span>
          <div className={classes.thumbValue}>
            {isFloorRate
              ? `${formatPercentage(floorRate)}`
              : `${formatPercentage(interestRate)}`}
          </div>
        </div>
        {!isFloorRate && icons}
      </div>
    </div>
  );
};

export const InterestRateSlider = observer(({ store }: Props) => {
  const { loanEconomicsStore } = store;
  const formStore = store.loanEconomicsStore.loanEconomicsFormStore;

  const interestRate = formStore.form.fields.interestRate.value ?? 0;
  const floorRate = loanEconomicsStore.floorRate ?? 0;
  const capitalProviderRate =
    loanEconomicsStore.outputs?.capitalProviderRate ?? floorRate;
  const yieldSpread = loanEconomicsStore.outputs?.lenderYieldSpread ?? 0;

  const min = floorRate;
  const max = 14;
  const classes = useStyles({
    min,
    max,
    interestRate,
    floorRate,
    capitalProviderRate,
  });

  const handleSliderChange = (e, value) => {
    const interestRate = value > floorRate ? value : floorRate;
    formStore.onFieldChange('interestRate', interestRate);
  };

  return (
    <Box pt={1} px={4}>
      <Box className={classes.root}>
        {loanEconomicsStore.outputs && (
          <>
            <div className="yieldSpread">
              Yield spread: <b>{formatPercentage(yieldSpread)}</b>
            </div>
            <ThumbComponent
              muiProps={{
                style: {
                  left: `${((capitalProviderRate - min) / (max - min)) * 100}%`,
                },
              }}
              interestRate={interestRate}
              floorRate={capitalProviderRate}
              isFloorRate={true}
            />
          </>
        )}
        <Slider
          value={interestRate}
          onChange={handleSliderChange}
          step={0.25}
          min={min}
          max={max}
          track="inverted"
          style={{ minWidth: '400px', marginTop: '2rem' }}
          marks={[
            {
              value: min,
              label: min + `%`,
            },
            {
              value: max,
              label: max + '%',
            },
          ]}
          valueLabelDisplay="on"
          ThumbComponent={props => (
            <ThumbComponent
              muiProps={props}
              interestRate={interestRate}
              floorRate={floorRate}
              isFloorRate={false}
            />
          )}
        />
      </Box>
    </Box>
  );
});
