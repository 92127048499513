import { action, flow, makeObservable, observable } from 'mobx';
import { ApiResponse, GlobalStore, GridStore } from '@roc/feature-app-core';
import { RevenueRecognitionService } from '../services/revenueRecognitionService';
import { SelectOption } from "@roc/ui";
import { REVENUE_RECOGNITION_MODULES } from '../utils/constants';
import { MonthFilter, ReveneuRecognitionModules } from '../types/types';
import { getFirstDayOfCurrentMonth } from '../utils/utils';
import { downloadDocument } from '@roc/feature-utils';

export class RevenueRecognitionStore {
  globalStore: GlobalStore;
  service: RevenueRecognitionService;
  revenueRecognitionGridStore: GridStore;
  selectedModule: SelectOption;
  params: any[] = [null, null];
  months: MonthFilter[] = []


  constructor(globalStore: GlobalStore) {
    this.globalStore = globalStore;
    this.service = new RevenueRecognitionService();
    this.selectedModule = REVENUE_RECOGNITION_MODULES.find(module => module.value === ReveneuRecognitionModules.MONTHLY_TRADE_MODULE);
    this.revenueRecognitionGridStore = new GridStore(
      () => this.fetchRevenueRecognitionDashboard(),
      null,
      50
    );
    this.setParam(0, getFirstDayOfCurrentMonth())
    makeObservable(this, {
      resetAndFetchGridData: flow,
      selectedModule: observable,
      setSelectedModule: action,
      params: observable,
      setParam: action,
      months: observable,
      getMonths: flow,
      exportDashboardData: flow,
    });
  };

  private async fetchRevenueRecognitionDashboard() {
    try {
      const filters = {
        ...this.revenueRecognitionGridStore.gridData.meta.filters
      };
      const response: ApiResponse = await this.service.getRevenueRecognitionDashboard(
        this.selectedModule.value,
        this.params,
        this.revenueRecognitionGridStore.gridData.meta.pageNumber,
        this.revenueRecognitionGridStore.gridData.meta.pageSize,
        this.revenueRecognitionGridStore.gridData.meta.sortDir,
        this.revenueRecognitionGridStore.gridData.meta.sortBy,
        filters,
        this.revenueRecognitionGridStore.gridData.meta.dropdownFilters
      );
      return response;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching revenue recognition dashboard.',
      });
    }
  };

  *getMonths() {
    try {
      const response: ApiResponse = yield this.service.getMonths();
      this.months = response.data.data.map(value => (
        { label: value.label, value: value.value }
      ));
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching filters.',
      });
    }
  }

  *exportDashboardData() {
    try {
      const filters = {
        ...this.revenueRecognitionGridStore.gridData.meta.filters
      };
      const response: ApiResponse = yield this.service.exportDashboardData(
        this.selectedModule.value,
        this.params,
        filters,
      );
      downloadDocument(response.data, response.headers, 'download', `${this.selectedModule.value}_REPORT.xlsx`);
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while exporting revenue recognition dashboard data.',
      });
    }
  };


  *resetAndFetchGridData() {
    this.revenueRecognitionGridStore = yield new GridStore(() => this.fetchRevenueRecognitionDashboard(), null, 50);
    this.revenueRecognitionGridStore.reset();
  };

  setSelectedModule(module: SelectOption) {
    this.selectedModule = REVENUE_RECOGNITION_MODULES.find(m => m.value = module);
  }

  setParam(index: number, value: any) {
    this.params[index] = value;
  }
}
