import {
  Chip, createStyles, makeStyles, Theme
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    xmlCounterChip: {
      backgroundColor: theme.palette.success.main,
      color: 'white',
      marginLeft: theme.spacing(2),
    },
    itemsShownCounter: {
      backgroundColor: '#2199E0',
      color: 'white',
      marginLeft: theme.spacing(2),
    },
  })
);

export const ItemsShownCounterChip = ({ value }) => {
  const classes = useStyles();
  return (
    <Chip
      size='small'
      className={classes.itemsShownCounter}
      label={`Listed: ${value}`}
    />
  );
};

export const XmlCounterChip = ({ value }) => {
  const classes = useStyles();
  return (
    <Chip
      size='small'
      className={classes.xmlCounterChip}
      label={`Found on file: ${value}`}
    />
  );
};
