import { observer } from 'mobx-react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { TodoItem, TodoStatus } from '../utils/loanDetailsTypes';
import { Box, Grid } from '@material-ui/core';
import { TitleWithTodoCount, TodoCard } from '../components/cards/todoCard';
import {
  LoanDetailsStepper,
  LoanStatusStepper,
} from '../components/loanDetailsStepper';
import { useEffect } from 'react';
import { useLoanStore } from '@roc/feature-loans';
import { formatDate, LoanProcess, UNDERWRITING } from '@roc/feature-utils';
import { DocumentForms, useDocumentStore } from '@roc/feature-documents';
import { Paper, PaperBorderType } from '@roc/ui';
import { LoanDetailsPaper } from '../components/loanDetailsPaper';
import { RelationshipManagerCardContent } from '../components/cards/relationshipManagerCard';
import { isPendingTodoStatus } from '../utils/todoUtils';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    border: '1px solid #d5d5d5 !important',
    overflow: 'hidden',
  },
}));

export const UnderwritingTab = observer(() => {
  const classes = useStyles();
  const { documentStore } = useDocumentStore();
  const { loanStore } = useLoanStore();
  const { loanDetails } = loanStore;
  const loanId = loanDetails?.loanId;
  const { loanTodosStore } = documentStore;
  const currentForm = documentStore.documentFormStore.currentForm;
  const loanProcess = LoanProcess.UNDERWRITING;

  const todos =
    documentStore.loanTodosStore.todosByLoanProcess[loanProcess] ?? [];
  const pendingTodos = todos.filter(t => isPendingTodoStatus(t.status));

  useEffect(() => {
    documentStore.loanTodosStore.fetchTodos(loanId, LoanProcess.UNDERWRITING);
  }, [loanId]);

  useEffect(() => {
    if (!currentForm && todos.length > 0) {
      loanTodosStore.fetchTodos(loanId, loanProcess);
    }
  }, [loanId, currentForm]);

  return (
    <>
      <Grid item xs={12}>
        <Paper
          borderType={PaperBorderType.SOLID_BORDER}
          className={classes.paper}
        >
          <LoanStatusStepper />
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <LoanDetailsPaper>
          <Box display="flex" pt={0} pb={1} px={2}>
            <RelationshipManagerCardContent />
          </Box>
        </LoanDetailsPaper>
      </Grid>
      <Grid item xs={12}>
        <TodoCard
          loanProcess={loanProcess}
          title={<TitleWithTodoCount count={pendingTodos.length} />}
          showFilter
          showSectionActions
          showFileName
          showDownloadAll
          todos={todos}
        />
      </Grid>
      <DocumentForms loanId={loanDetails.loanId} />
    </>
  );
});
