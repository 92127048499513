import { observer } from "mobx-react";
import { automatedColumns } from "./appraisalReviewConstants";
import { AppraisalReviewTable } from "./appraisalReviewTable";

interface AppraisalAutomatedReviewSectionProps {
  section: any;
  store: any;
  columns?: any;
}

export const AppraisalAutomatedReviewSection = observer(({ section, store, columns = automatedColumns }: AppraisalAutomatedReviewSectionProps) => {
  const { sectionName, items } = section;

  return (
    <AppraisalReviewTable
      store={store}
      columns={columns}
      items={items}
      sectionName={sectionName}
    />
  )
});