import { observer } from "mobx-react";
import { Typography } from "@material-ui/core";
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from "@material-ui/core";
import { formatCurrency, formatPercentage } from '@roc/ui/utils';
import { TestDataAttribute } from "@roc/ui";
import { sanitizeTestId } from "@roc/feature-utils";
import { useMemo } from "react";

const renderHeading = value => {
  return (
    <TestDataAttribute id={sanitizeTestId(value)}>
      <Typography
        variant="body1"
      >
        <strong>{value}</strong>
      </Typography>
    </TestDataAttribute>

  );
};

const TableItem = ({ testId, data, className }) => {
  return (
    <TestDataAttribute id={sanitizeTestId(testId)}>
      <TableCell className={className}>{data}</TableCell>
    </TestDataAttribute>
  )
}

const useStyles = makeStyles(theme => ({
  tableBorder: {
    borderStyle: "none"
  },
  boldFont: {
    fontWeight: 'bold',
  },
  greenFont: {
    color: theme.palette.success.dark,
    fontWeight: 'bold',
  },
  redFont: {
    color: theme.palette.error.dark,
    fontWeight: 'bold',
  },
}));

export const PricingModelTable = observer(
  (props) => {
    const classes = useStyles();
    const { data } = props;

    const rows = useMemo(() => {
      const parsedRows = (data?.criteriaRateAdjustmentData?.slice() || []);

      parsedRows.sort((a, b) => a.adjustment - b.adjustment);
      return parsedRows;
    }, [data]);

    return (
      <Grid>
        <Grid item xs={12}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{renderHeading('Criteria')}</TableCell>
                <TableCell>{renderHeading('Input')}</TableCell>
                <TableCell >{renderHeading('Adjustment')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableItem
                  testId='base-rate-label'
                  data={'Base Rate'}
                  className={classes.boldFont}
                />
                <TableCell className={''}></TableCell>
                <TableItem
                  testId='base-rate-adjustment'
                  data={formatPercentage(data?.baseRate || 0)}
                  className={classes.boldFont}
                />
              </TableRow>
              {rows.map(row => (
                <TableRow>
                  <TableItem
                    testId='borrower-experience-label'
                    data={row.criteria}
                    className={''}
                  />
                  <TableItem
                    testId='borrower-experience-input'
                    data={row.input}
                    className={''}
                  />
                  <TableItem
                    testId='borrower-experience-adjustment'
                    data={typeof row.adjustment === 'number' ? formatPercentage(row.adjustment) : 'N/A'}
                    className={row.adjustment > 0 ? classes.redFont : row.adjustment < 0 ? classes.greenFont : ''}
                  />
                </TableRow>
              ))}
              <TableRow>
                <TableItem
                  testId='floor-rate-label'
                  data={'Adjusted Floor Rate'}
                  className={`${classes.tableBorder} ${classes.boldFont}`}
                />
                <TableCell className={`${classes.tableBorder} ${classes.boldFont}`}></TableCell>
                <TableItem
                  testId='floor-rate-adjustment'
                  data={formatPercentage(data?.floorRate || 0)}
                  className={`${classes.tableBorder} ${classes.boldFont}`}
                />
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    );
  }
);