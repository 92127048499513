import { useBaseStore } from '@roc/feature-app-core';
import { getCommunicationRoutesConfig } from '../routes/communicationRoutes';

export const useCommunicationRoutesConfig = () => {
  const { globalStore } = useBaseStore();
  const { routeBasePath, routeBaseUrl } = globalStore;

  return {
    communicationRoutesConfig: getCommunicationRoutesConfig(routeBasePath, routeBaseUrl),
  };
};
