import React, { useEffect } from 'react';
import { CardMedia } from '@material-ui/core';
import { observer } from 'mobx-react';
import { IVideo } from '../types';
import { useWhatsNewStore } from '../hooks/useWhatsNewStore';

type VideoCardProps = {
  video: IVideo;
};

export const Video = observer((props: VideoCardProps) => {
  const { video } = props;

  const { whatsNewStore } = useWhatsNewStore();
  const { videoUrls } = whatsNewStore;

  useEffect(() => {
    whatsNewStore.getVideoUrl(video);
  }, [whatsNewStore, video]);

  return (
    <CardMedia
      component="video"
      height="600"
      src={videoUrls[video.displayFileName]}
      controls
      autoPlay
    />
  );
});

export default Video;
