import { Route } from 'react-router';
import { useRocInternalPortalStore } from '../../../hooks';
import { PreviousDrawInformation } from '@roc/feature-draws';
import { Page } from '@roc/client-portal-shared/components';

export const getPreviousDrawInformationInternalRoutesConfig = (
  basePath: string,
  baseUrl: string
) => {
  return {
    previousDrawInformation: {
      path: `${basePath}/previous-draw-information/:drawId`,
      url: `${baseUrl}/previous-draw-information/:drawId`,
      documentTitle: 'Previous Draw Information',
    },
  };
};

export const getPreviousDrawInformationInternalRoutes = (
  basePath: string,
  baseUrl: string
) => {
  const config = getPreviousDrawInformationInternalRoutesConfig(basePath, baseUrl);
  const { previousDrawInformationInternalStore } = useRocInternalPortalStore();

  return {
    previousDrawInformation: (
      <Route exact path={config.previousDrawInformation.path}>
        <Page routeDefinition={config.previousDrawInformation}>
          <PreviousDrawInformation store={previousDrawInformationInternalStore} />
        </Page>
      </Route>
    ),
  };
};
