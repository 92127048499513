import { Route } from 'react-router';
import { Page } from '@roc/feature-app-core';
import { useRocInternalPortalStore } from '../../../hooks';
import { EscrowDepositCalculator } from '@roc/feature-escrow-deposit-calculator';

export const getEscrowCalculatorInternalRoutesConfig = (
  basePath: string,
  baseUrl: string,
  loanId: any,
  loanDocumentId: any
) => {
  return {
    escrowDepositCalculator: {
      path: `${basePath}/escrow-deposit-calculator`,
      url: `${baseUrl}/escrow-deposit-calculator`,
      documentTitle: 'Escrow Deposit Calculator',
    },
  };
};

export const getEscrowCalculatorInternalRoutes = (
  basePath: string,
  baseUrl: string,
  loanId: any,
  loanDocumentId: any
) => {
  const config = getEscrowCalculatorInternalRoutesConfig(basePath, baseUrl, loanId, loanDocumentId);
  const { escrowDepositCalculatorStore } = useRocInternalPortalStore();
  return {
    escrowDepositCalculator: (
      <Route exact path={config.escrowDepositCalculator.path}>
        <Page routeDefinition={config.escrowDepositCalculator}>
          <EscrowDepositCalculator
            store={escrowDepositCalculatorStore}
            loanId={loanId}
            loanDocumentId={loanDocumentId}
          />
        </Page>
      </Route>
    ),
  };
};
