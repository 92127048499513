import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    "& tr:last-child th": {
      fontWeight: "bold",
    },
    "& tr:last-child td": {
      fontWeight: "bold",
      background: "#dffdff",
      fontSize: 18,
    },
  },
});



const PaidOffInterestAllocationTable = ({ loanData }) => {
  const classes = useStyles();
  return (

    <Table className={classes.table}>
      <TableHead>
        <TableRow>
          <TableCell>Description</TableCell>
          <TableCell>Total Interest</TableCell>
          <TableCell>Loan Serv</TableCell>
          <TableCell>Loan Funder</TableCell>
          <TableCell>{loanData?.tpo}</TableCell>
          <TableCell>{loanData?.investor}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {loanData.data.map((row, index) => (
          <TableRow key={index}>
            <TableCell>{row.name}</TableCell>
            <TableCell>{row.n1 ?? "-"}</TableCell>
            <TableCell>{row.n2 ?? "-"}</TableCell>
            <TableCell>{row.n3 ?? "-"}</TableCell>
            <TableCell>{row.n4 ?? "-"}</TableCell>
            <TableCell>{row.n5 ?? "-"}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default PaidOffInterestAllocationTable;