import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,

  Typography,
  Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { TextField, PercentageField, TestDataAttribute } from '@roc/ui';
import { alpha } from '@material-ui/core/styles';
import { formatCurrency } from '@roc/ui/utils';
import { observer } from 'mobx-react';
import { useDrawsStore } from '../hooks';
import { formatPrecision, roundHalf, sanitizeTestId } from '@roc/feature-utils';

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    minWidth: 750,
    border: '2px #e0e0e0 solid',
    '& td': {
      height: '40px',
      padding: 0,
      paddingTop: '8px',
      paddingBottom: '8px',
    },
    '& th:first-child, td[rowSpan], $addNewItemRow td': {
      paddingLeft: theme.spacing(2),
      paddingRight: '3px',
    },
    '& table tr:last-child td, & table td[rowSpan]': {
      borderBottom: 'none',
    },
  },

  editableRow: {
    position: 'relative',
    cursor: 'pointer',
    '&:hover td': {
      backgroundColor: '#fdefbf',
      transition: theme.transitions.create(['background-color', 'transform'], {
        duration: theme.transitions.duration.standard,
      }),
    },
    '&:hover $rowActions': {
      visibility: 'visible',
    },
    '& td:last-child': {
      [theme.breakpoints.down('md')]: {
        paddingRight: '100px',
      },
    },
  },
  headerCell: {
    height: '41px',
    fontWeight: 'bold',
    padding: '0 4px 0 0',
  },
  titleCell: {
    height: '46px',
    padding: 0,
    borderBottom: '2px #e0e0e0 solid',
  },

  descriptionCell: {
    whiteSpace: 'pre-wrap',
  },

  inputBase: {
    width: '100%',
    '& input': {
      backgroundColor: '#fdefbf',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      fontSize: 14,
      color: 'black',
      fontWeight: theme.typography.fontWeightBold,
      padding: '8px 4px',
      cursor: 'pointer',
      '&:focus': {
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  autocompletePaper: {
    marginLeft: 0,
    marginRight: 0,
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    fontSize: 13,
  },
  autocompleteOption: {
    minHeight: 'auto',
    alignItems: 'flex-start',
    padding: 8,
    '&[data-focus="true"]': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  addNewItemCell: {
    padding: 0,
    height: '40px',
    backgroundColor: '#fdefbf',
  },
  addNewItemRow: {
    '&:hover': {
      backgroundColor: '#fff',
    },
  },
  rowActions: {
    visibility: 'hidden',
    position: 'absolute',
    right: 0,
    top: 0,
    bottom: 0,
    zIndex: 99,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      visibility: 'visible',
    },
  },
  horizontalScroll: {
    [theme.breakpoints.down('md')]: {
      overflowX: 'scroll',
    },
  },
  clickHereToEdit: {
    color: theme.palette.grey[400],
    fontSize: theme.typography.fontSize * 0.8,
  },
  clickHereToEditHidden: {
    visibility: 'hidden',
  },
  title: {
    color: '#333333',
    fontSize: 20,
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  percentageInput: {
    marginLeft: 10,
    marginRight: 48,
  },
}));

const columns: any[] = [
  {
    name: 'LINE ITEM',
    width: '10%',
    align: 'left',
  },
  {
    name: 'DETAIL',
    width: '10%',
    align: 'left',
  },
  {
    name: 'DESCRIPTION',
    width: '10%',
    align: 'left',
  },
  {
    name: 'ORIGINAL COST',
    width: '10%',
    align: 'left',
  },
  {
    name: 'COMPLETED RENOVATIONS',
    width: '10%',
    align: 'left',
  },
  {
    name: 'PREVIOUSLY DISBURSED',
    width: '15%',
    align: 'left',
  },
  {
    name: '% COMPLETE TO DATE',
    width: '10%',
    align: 'center',
  },
  {
    name: 'REQUESTED THIS DRAW',
    width: '12%',
    align: 'center',
  },
  {
    name: 'COMMENTS',
    width: '13%',
    align: 'center',
  },
];

const calculatePercentage = (partialValue: number, totalValue: number) =>
  roundHalf((partialValue / totalValue) * 100);

interface DrawRequestCategoryTableProps {
  category: any;
}

export const DrawRequestCategoryTable = observer(
  ({ category }: DrawRequestCategoryTableProps) => {
    const classes = useStyles();
    const { drawRequestStore } = useDrawsStore();

    const handlePercentageCompleteToDateChange = (
      categoryName,
      lineItemName,
      isDetail,
      detailIndex,
      value
    ) => {
      if (isDetail) {
        drawRequestStore.onTableContentChange(
          categoryName,
          lineItemName,
          detailIndex,
          value,
          'percentageCompleteToDate'
        );
      } else {
        drawRequestStore.onTableContentChange(
          categoryName,
          lineItemName,
          null,
          value,
          'percentageCompleteToDate'
        );
      }
    };

    const handleCommentsChange = (
      categoryName,
      lineItemName,
      isDetail,
      detailIndex,
      value
    ) => {
      if (isDetail) {
        drawRequestStore.onTableContentChange(
          categoryName,
          lineItemName,
          detailIndex,
          value,
          'comments'
        );
      } else {
        drawRequestStore.onTableContentChange(
          categoryName,
          lineItemName,
          null,
          value,
          'comments'
        );
      }
    };

    return (
      <Grid item xs={12} md={12}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell colSpan={columns.length} className={classes.titleCell}>
                <Typography className={classes.title}>
                  {category.name}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              {columns.map(({ name, ...props }) => (
                <TableCell key={name} className={classes.headerCell} {...props}>
                  {name}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {category.subCategories.map((row, index) => {
            const itemized =
              row.itemized.length > 0
                ? row.itemized
                : [
                  {
                    cost: row.general.cost,
                    previouslyDisbursed: row.general.previouslyDisbursed,
                    percentageCompleteToDate:
                      row.general.percentageCompleteToDate,
                    requestedThisDraw: row.general.requestedThisDraw,
                    capex: row.general.capex,
                    comments: row.general.comments,
                  },
                ];
            return (
              <TableBody key={`${row?.id}_${row?.name}_${index}`}>
                {itemized.map((item, idx) => (
                  <TableRow
                    key={`${row?.id}_${row?.name}_'${item.name}_${index}`}
                  >
                    {idx === 0 && (
                      <TestDataAttribute
                        id={sanitizeTestId(
                          category.name + row.name + `_lineItem`
                        )}
                      >
                        <TableCell
                          width={columns[0].width}
                          rowSpan={itemized.length}
                        >
                          {row.name}
                        </TableCell>
                      </TestDataAttribute>
                    )}
                    <TestDataAttribute
                      id={sanitizeTestId(category.name + row.name + `_detail`)}
                    >
                      <TableCell width={columns[1].width}>
                        {item.name}
                      </TableCell>
                    </TestDataAttribute>
                    <TestDataAttribute
                      id={sanitizeTestId(category.name + row.name + `_description`)}
                    >
                      <TableCell width={columns[2].width}>
                        {item.description}
                      </TableCell>
                    </TestDataAttribute>
                    <TestDataAttribute
                      id={sanitizeTestId(
                        category.name + row.name + `_originalCost`
                      )}
                    >
                      <TableCell width={columns[3].width}>
                        {formatCurrency(item.cost, 0)}
                      </TableCell>
                    </TestDataAttribute>
                    <TestDataAttribute
                      id={sanitizeTestId(
                        category.name + row.name + `_capex`
                      )}
                    >
                      <TableCell width={columns[4].width}>
                        {item.capex ? (`${item.capex}% | ${formatCurrency(((item.capex * item.cost) / 100), 2)}`) : '0% | $0'}
                      </TableCell>
                    </TestDataAttribute>
                    <TestDataAttribute
                      id={sanitizeTestId(
                        category.name + row.name + `_previouslyDisbursed`
                      )}
                    >
                      <TableCell width={columns[5].width}>
                        {`${calculatePercentage(
                          item.previouslyDisbursed,
                          item.cost
                        )}% | ${formatCurrency(item.previouslyDisbursed, 0)}`}
                      </TableCell>
                    </TestDataAttribute>
                    <TableCell width={columns[6].width} align="center">
                      <div>
                        <PercentageField
                          value={
                            item.percentageCompleteToDate
                              ? item.percentageCompleteToDate
                              : ''
                          }
                          variant="outlined"
                          disabled={drawRequestStore.saved}
                          testId={sanitizeTestId(
                            category.name +
                            row.name +
                            `_percentageCompleteToDate`
                          )}
                          onChange={(name, value) =>
                            handlePercentageCompleteToDateChange(
                              category.name,
                              row.name,
                              !!row.itemized.length,
                              idx,
                              value
                            )
                          }
                          fullWidth
                          limitedPercentage
                        />
                      </div>
                    </TableCell>
                    <TestDataAttribute
                      id={sanitizeTestId(
                        category.name + row.name + `_requestThisDraw`
                      )}
                    >
                      <TableCell width={columns[7].width} align="center">
                        {`${formatPrecision(roundHalf(item.percentageCompleteToDate -
                          calculatePercentage(
                            item.previouslyDisbursed || 0,
                            item.cost
                          )), 2)
                          }% |
                      ${formatCurrency(
                            roundHalf(
                              (item.percentageCompleteToDate / 100) * item.cost
                            ) - (item.previouslyDisbursed || 0),
                            0
                          )}`}
                      </TableCell>
                    </TestDataAttribute>
                    <TableCell width={columns[8].width}>
                      <TextField
                        testId={sanitizeTestId(
                          category.name + row.name + `_comments`
                        )}
                        multiline
                        maxRows={3}
                        variant="outlined"
                        disabled={drawRequestStore.saved}
                        value={item.comments ? item.comments : ''}
                        type="text"
                        onChange={e =>
                          handleCommentsChange(
                            category.name,
                            row.name,
                            !!row.itemized.length,
                            idx,
                            e.target.value
                          )
                        }
                        fullWidth
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            );
          })}
        </Table>
      </Grid>
    );
  }
);
