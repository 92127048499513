import { ApiResponse, GlobalStore, GridStore } from '@roc/feature-app-core';
import { BorrowerLoanApplicationsService } from '../services/borrowerLoanApplicationsService';
import { GENERIC_ERROR_MESSAGE } from '@roc/feature-utils';

const PAGE_SIZE = 10;
export class BorrowerLoanApplicationsGridStore extends GridStore {
  globalStore: GlobalStore;
  borrowerLoanApplicationsService: BorrowerLoanApplicationsService;

  constructor(globalStore: GlobalStore) {
    super(() => this.fetchBorrowerLoanApplications(), undefined, PAGE_SIZE);
    this.globalStore = globalStore;
    this.borrowerLoanApplicationsService = new BorrowerLoanApplicationsService();
  }

  fetchBorrowerLoanApplications = async () => {
    try {
      const response = await this.borrowerLoanApplicationsService.getBorrowerLoansGrid(
        this.gridData.meta.pageNumber,
        this.gridData.meta.pageSize,
        this.gridData.meta.sortDir,
        this.gridData.meta.sortBy,
        this.gridData.meta.filters,
        this.gridData.meta.dropdownFilters
      );
      return response;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE
      });
    }
  };
}
