import { Layout } from "@roc/feature-app-core";
import { observer } from "mobx-react";
import { AppraisalReviewStore } from "../stores/appraisalReviewStore";
import { BorrowerReviewCustomTheme } from "@roc/feature-borrower-review";
import { UwAppraisalReviewReportForm } from "./uwAppraisalReviewForm";


interface AppraisalReviewReportFormProps {
  loanId: number;
  propertyId: number;
  store: AppraisalReviewStore;
}

export const UwAppraisalReview = observer(({ loanId, propertyId, store }: AppraisalReviewReportFormProps) => {
  return (
    <div style={{ background: 'white', width: '100%' }}>
      <Layout>
        <BorrowerReviewCustomTheme>
          <UwAppraisalReviewReportForm propertyId={propertyId} loanId={loanId} store={store} />
        </BorrowerReviewCustomTheme>
      </Layout>
    </div>
  )
});