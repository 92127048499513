import { GlobalStore } from '@roc/feature-app-core';
import { action, flow, makeObservable, observable, override } from 'mobx';
import { LeverageFormStore } from './leverageFormStore';
import { PropertiesStore } from '../properties/propertiesStore';
import { QuoteTypeStore } from '../quoteTypeStore';
import { sum } from '../../utils/utils';
import { LoanEconomicsStore } from './loanEconomicsStore';
import { formatPercentage } from '@roc/ui/utils';
import { format, differenceInMonths } from "date-fns";

export class GroundUpLoanEconomicsStore extends LoanEconomicsStore {
  constructor(globalStore: GlobalStore, quoteTypeStore: QuoteTypeStore) {
    super(globalStore, quoteTypeStore);
    makeObservable(this, {
      mapCalculatedFields: override
    });
  }

  private isPrincipalInterest(loanAmount) {
    const MAX_LOAN_AMOUNT_FOR_PRINCIPAL_INTEREST = 120000;
    return loanAmount < MAX_LOAN_AMOUNT_FOR_PRINCIPAL_INTEREST;
  }

  private calculateMonthsBetween(purchaseDate, submissionDate) {
    if (purchaseDate) {
      const startDate = new Date(purchaseDate);
      const endDate = new Date(submissionDate);
      const monthsDifference = differenceInMonths(startDate, endDate);
      return Math.abs(monthsDifference);
    }
    else {
      return 12
    }
  }
  calculateInterest(totalLoanAmount, initialAdvance, purchaseDate, constructionHoldback, rate) {
    let calculatedLoanAmount = initialAdvance;
    const submissionDate = format(new Date(), "MM/dd/yyyy");
    const MONTHS_DIVIDER = 1 / 12;
    const isFullPrincipalInterest = this.isPrincipalInterest(totalLoanAmount);
    const timeToFlip = this.calculateMonthsBetween(purchaseDate, submissionDate)
    if (!isFullPrincipalInterest) {
      calculatedLoanAmount = initialAdvance + (constructionHoldback * 0.66);
    }
    const interest = calculatedLoanAmount * (rate / 100) * MONTHS_DIVIDER * timeToFlip;
    return interest;
  }
  caclulateFields(properties: any[], leverage: any): Record<string, number> {
    //Leverage
    const totalConstructionRenoSpendToDate = this.quoteTypeStore.isMidConstructionRefinance() ?
      sum(properties.map(p => p.constructionRenoSpendToDate)) : 0;

    const totalSellerCredit = sum(properties.filter(p => !!p.sellerCredit).map(p => p.sellerCreditAmount));

    const { loanToCost, constructionReserve } = leverage;
    const totalPurchasePrice = sum(properties.map(p => Number(p.purchasePrice))) + totalConstructionRenoSpendToDate - totalSellerCredit;
    const totalProposedConstructionBudget = sum(
      properties.map(p => Number(p.renovationBudget))
    );
    const totalAfterRepairValue = sum(
      properties.map(p => Number(p.marketValueAfterCompletion))
    );
    const initialAdvance = totalPurchasePrice * (loanToCost / 100);
    const costBasisPreInterest = totalPurchasePrice + totalProposedConstructionBudget;
    const arltv70 = totalAfterRepairValue * 0.70;
    const preInterestLtfc85 = costBasisPreInterest * 0.85;
    const lesserOfArltvAndLtfc = arltv70 < preInterestLtfc85 ? arltv70 : preInterestLtfc85;
    const initialLoanAmount = lesserOfArltvAndLtfc - totalProposedConstructionBudget;
    const constructionHoldback =
      totalProposedConstructionBudget * (constructionReserve / 100);
    const totalLoanAmount = initialAdvance + constructionHoldback;
    const interestExpense = this.calculateInterest(totalLoanAmount, initialLoanAmount, properties[0]?.purchaseDate, totalProposedConstructionBudget, 9.99);
    const costBasis = totalPurchasePrice + totalProposedConstructionBudget;
    const calculatedLTFC = (totalLoanAmount / costBasis) * 100;
    const afterRepairLTV = (totalLoanAmount / totalAfterRepairValue) * 100;

    return {
      totalPurchasePrice,
      totalProposedConstructionBudget,
      totalAfterRepairValue,
      initialAdvance,
      constructionHoldback,
      costBasis,
      interestExpense,
      totalLoanAmount,
      calculatedLTFC,
      afterRepairLTV,
      loanToCost,
    };
  }

  mapCalculatedFields() {
    const manualExceptionQuoteData = this.manualExceptionQuoteFormStore.calculatedFields;
    const instaLeverageQuote = this.instaLeverageQuoteFormStore.calculatedFields;

    return {
      totalLoanAmount: instaLeverageQuote.totalLoanAmount,
      exceptionTotalLoanAmount: manualExceptionQuoteData.totalLoanAmount,

      totalLoanCostPercentage: instaLeverageQuote.calculatedLTFC,
      exceptionTotalLoanCostPercentage: manualExceptionQuoteData.calculatedLTFC,

      totalARLTVPercentage: instaLeverageQuote.afterRepairLTV,
      exceptionTotalARLTVPercentage: manualExceptionQuoteData.afterRepairLTV,
    }
  }

  calculateKeyRatios() {
    const leverage = this.getLoanEconomics();
    const calculatedFields = this.calculatedFields;

    const ltcPurchasePricePercentage = formatPercentage(leverage.loanToCost);
    const ltcConstructionBudgetHoldbackPercentage = formatPercentage(
      leverage.constructionReserve
    );
    const ARLTV = formatPercentage(calculatedFields.afterRepairLTV);

    return `${ltcPurchasePricePercentage} / ${ltcConstructionBudgetHoldbackPercentage} / ${ARLTV}`;
  }

  validateLeverageAmounts() {
    const errors = [];

    if (!this.enableManualQuote) {
      const maxLTC = this.instaQuoteRanges?.maxLoanToCost;
      const ltc = this.calculatedFields?.loanToCost || 0;

      if (ltc > maxLTC) {
        errors.push(`The loan amount you are requesting is over ${maxLTC}% LTC. Please reduce either the LTC or holdback so the loan request is ${maxLTC}% LTC or less.`);
      }

      const maxLTFC = this.instaQuoteRanges?.maxLTFC;
      const ltfc = this.calculatedFields?.calculatedLTFC || 0;

      if (ltfc > maxLTFC) {
        errors.push(`The loan amount you are requesting is over ${maxLTFC}% LTFC. Please reduce either the LTC or holdback so the loan request is ${maxLTFC}% LTFC or less.`);
      }

      const maxARLTV = this.instaQuoteRanges.maxARLTV;
      const afterRepairLTV = this.calculatedFields?.afterRepairLTV || 0;

      if (afterRepairLTV > maxARLTV) {
        errors.push(`At your expected ARV, the loan amount you are requesting is over ${maxARLTV}% ARLTV. Please reduce either the LTC or holdback so the loan request is ${maxARLTV}% ARLTV or less.`);
      }
    }

    return errors;
  }
}
