import { Page } from '../../../components';
import { Route } from 'react-router';
import { useStore } from '@roc/client-portal-shared/hooks';
import { InternalPricer } from '../internalPricer';

export const getRentalLoanPricerRoutesConfig = (
  basePath: string,
  baseUrl: string
) => {
  return {
    internalPricer: {
      path: `${basePath}/rental-loan-pricer`,
      url: `${baseUrl}/rental-loan-pricer`,
      documentTitle: 'Rental Loan Pricer',
    },
  };
};

export const getRentalLoanPricerRoutes = (
  basePath: string,
  baseUrl: string
) => {
  const config = getRentalLoanPricerRoutesConfig(basePath, baseUrl);
  const { internalPricerStore } = useStore();
  return {
    internalPricer: (
      <Route exact path={config.internalPricer.path}>
        <Page routeDefinition={config.internalPricer}>
          <InternalPricer internalPricerStore={internalPricerStore} />
        </Page>
      </Route>
    ),
  };
};
