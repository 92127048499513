import { Grid, Box } from "@material-ui/core";
import { Card } from "@roc/ui";
import { observer } from "mobx-react";
import { activityTypeOptions } from "../leads/constants/constants";
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@roc/ui';
import { Add } from '@material-ui/icons';
import { HtmlContainer } from '../leadDetails/htmlContainer';
import { EditLeadActivityDialog } from "./editLeadActivityDialog";
import { Note } from "./note";
import { EditLeadTaskDialog } from "./editLeadTaskDialog";
import { LeadDetailsStore } from "../../stores/leadDetails/leadDetailsStore";

const useStyles = makeStyles(theme => ({
  button: {
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(2),
    minWidth: '150px',
  },
  notesScroll: {
    backgroundColor: '#FAFAFA',
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    maxHeight: '800px',
    overflowY: 'auto',
    overflowX: 'hidden',
    boxShadow: '0 0 2px #DDD inset'
  },
}));

interface contactTasksParams {
  leadDetailsStore: LeadDetailsStore;
  borrowerId?: number;
  companyContactId?: number;
}

const changeCase = (status) => {
  let newStatus = status.replace("_", " ");
  return newStatus.toLowerCase()
    .split(' ')
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ');
}

export const ContactTasksCard = observer(({ leadDetailsStore, borrowerId, companyContactId }: contactTasksParams) => {
  const classes = useStyles();
  const { editLeadTaskStore } = leadDetailsStore;
  const action = (
    <Box p={1} mb={-10}>
      <Button
        testId="add-lead-task"
        className={classes.button}
        variant="contained"
        size="small"
        color="primary"
        onClick={() => editLeadTaskStore.openAddTaskFromContactDetails(borrowerId, companyContactId)}
      >
        <Add /> Add Task
      </Button>
    </Box>
  );

  const getActivityType = leadActivity => {
    return activityTypeOptions.find(
      opt => opt.value === leadActivity.activityType
    )?.label;
  };

  return (
    <Card
      title="Tasks"
      cardHeaderProps={{ action }}
      cardContentProps={{ className: classes.notesScroll }}
    >
      {leadDetailsStore.leadTasks.length > 0 ? (
        <Grid container spacing={2}>
          {leadDetailsStore.leadTasks.map(leadTask => (
            <Grid item xs={12}>
              <Note
                title={leadTask.title + " - " + changeCase(leadTask.status)}
                onEdit={() =>
                  editLeadTaskStore.openEditLeadTask(leadTask)
                }
                createdDate={leadTask.createdDate}
                lastUpdatedDate={leadTask.lastUpdatedDate}
                createdBy={leadTask.createdByName}
                lastModifiedBy={leadTask.lastUpdatedByName}
              >
                <HtmlContainer html={leadTask.description} />
              </Note>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Box textAlign="center">No records.</Box>
      )}
      <EditLeadTaskDialog editLeadTaskStore={editLeadTaskStore} />
    </Card>
  );
});
