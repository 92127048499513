import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  '@global': {
    '*': {
      boxSizing: 'border-box',
      margin: 0,
      padding: 0,
    },
    html: {
      '-webkit-font-smoothing': 'antialiased',
      '-moz-osx-font-smoothing': 'grayscale',
      height: '100%',
      width: '100%',
    },
    body: {
      ...theme.typography.body1,
      background: props => props.bodyBackgroundColor ?? theme.palette.background.default,
      margin: 0,
      height: '100%',
      width: '100%',
    },
    a: {
      textDecoration: 'none',
    },
    '#root': {
      height: '100%',
      width: '100%',
    },
    // '.pac-container' zIndex is overriden to show google maps autocomplete options in Modal
    '.pac-container': {
      zIndex: 9999,
    },
    'input[type="number"]': {
      '-moz-appearance': 'textField',
      '&::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0
      },
      '&::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0
      }
    },
  },
});

export interface AppCssBaselineProps {
  bodyBackgroundColor?: string;
}

const AppCssBaseline = (props: AppCssBaselineProps) => null;

export default withStyles(styles)(AppCssBaseline);
