import { action, computed, flow, makeObservable, observable } from 'mobx';
import {
  ApiResponse,
  GridStore,
  PortalConfigurationService,
  UserStore,
} from '@roc/feature-app-core';
import { GlobalStore } from '../../../../stores';
import {
  NewLender,
  ReasonToRemoveLeadFromQueue,
} from '../../types/companyProfileTypes';
import { NewLeadService } from '@roc/feature-new-lead';
import { ConvertLeadToLenderStore } from 'libs/feature-backoffice-users/src/backOfficeUsers/stores/convertLeadToLenderStore';
import { EditLeadDetailsFormStore } from '../editLeadDetailsFormStore';
import {
  CONTACTED_SUB_STATUS_C,
  NOT_YET_CONTACTED_SUB_STATUS_C,
  TPO,
  Borrower,
  Broker,
} from 'libs/feature-backoffice-users/src/backOfficeUsers/utils/leadConstants';
import { isBlank } from '@roc/feature-utils';
import { leadSourceOptions } from 'libs/feature-backoffice-users/src/backOfficeUsers/components/leadDetails/leadDetails';
import {
  LeadsStore,
  NewLeadBaseStore,
  RemoveLeadFromQueueStore,
} from '@roc/feature-backoffice-users';
import { LeadsDashboardStore } from 'libs/feature-backoffice-users/src/backOfficeUsers/stores/leadsDashboardStore';
import { PortalConfiguration } from '@roc/feature-types';
import { SendEmailsStore } from '@roc/feature-sendgrid';
import {
  BORROWER_OBJECT_TYPE,
  BROKER_OBJECT_TYPE,
  TPO_OBJECT_TYPE,
} from 'libs/feature-backoffice-users/src/backOfficeUsers/components/leads/constants/constants';
import { BusinessUnit } from '@roc/feature-utils';

export abstract class BaseLeadStore {
  public globalStore: GlobalStore;
  public userStore: UserStore;
  protected newLeadService: NewLeadService;
  protected portalConfigurationService: PortalConfigurationService;
  public convertLeadToLenderStore: ConvertLeadToLenderStore;
  public editLeadDetailsFormStore: EditLeadDetailsFormStore;
  public removeLeadFromQueueStore: RemoveLeadFromQueueStore;
  public leadsStore: LeadsStore;
  public sendEmailsStore: SendEmailsStore;
  public leadsDashboardStore: LeadsDashboardStore;
  public newLeadBaseStore: NewLeadBaseStore;
  public allTPOLeadsGridStore: GridStore;
  public notYetContactedTPOLeadsGridStore: GridStore;
  public contactedTPOLeadsGridStore: GridStore;
  public allBorrowerLeadsGridStore: GridStore;
  public notYetContactedBorrowerLeadsGridStore: GridStore;
  public contactedBorrowerLeadsGridStore: GridStore;
  public incomingNotContactedLeadsGridStore: GridStore;
  public incomingLeadsGridStore: GridStore;
  public genericGridStore: GridStore;
  public allLeadsGridStore: GridStore;
  public leadDetails;
  salesForceLender: NewLender;
  public isEdit: boolean;
  public option: any;
  public options: any;
  public filteredOptions: any;
  public searchText: string;
  public currentRole: string;
  public emailAddress: string;
  public owners = [];
  public rocOwners = [];
  public civicOwners = [];
  public facoOwners = [];
  public allOwners = [];
  public rocActiveOwners = [];
  public civicActiveOwners = [];
  public facoActiveOwners = [];
  public allActiveOwners = [];
  public leadIds: string[];
  public borrowerIds: number[];
  public companyContactIds: number[];
  public newLeadStatus: string;
  public newLeadOwner: string;
  public searchOwnerText: string;
  public connectedWithRingcentral: boolean;
  public portalId: string | number;
  public userId: number;
  public isSalesForce = false;
  public isFromPossibleMatches: boolean;
  public isBorrower: boolean;
  public moveFundedLoans: boolean;
  public sendNotification: boolean;
  public moveNonFundedLoans: boolean;
  public recordType: string;
  public gridBusinessUnit: string;

  constructor(globalStore, newLeadBaseStore?: NewLeadBaseStore, userStore?) {
    this.globalStore = globalStore;
    this.userStore = userStore;
    this.newLeadService = new NewLeadService();
    this.newLeadBaseStore = newLeadBaseStore;
    this.portalConfigurationService = new PortalConfigurationService();
    this.allTPOLeadsGridStore = new GridStore(() => this.getLeads(TPO, ''));
    this.notYetContactedTPOLeadsGridStore = new GridStore(() =>
      this.getLeads(TPO, NOT_YET_CONTACTED_SUB_STATUS_C)
    );
    this.contactedTPOLeadsGridStore = new GridStore(() =>
      this.getLeads(TPO, CONTACTED_SUB_STATUS_C)
    );
    this.allBorrowerLeadsGridStore = new GridStore(() =>
      this.getLeads(Borrower, '')
    );
    this.notYetContactedBorrowerLeadsGridStore = new GridStore(() =>
      this.getLeads(Borrower, NOT_YET_CONTACTED_SUB_STATUS_C)
    );
    this.contactedBorrowerLeadsGridStore = new GridStore(() =>
      this.getLeads(Borrower, CONTACTED_SUB_STATUS_C)
    );
    this.genericGridStore = new GridStore(() => this.getLeadsListById());
    this.incomingNotContactedLeadsGridStore = new GridStore(() =>
      this.getIncomingLeads(false)
    );
    this.incomingLeadsGridStore = new GridStore(() =>
      this.getIncomingLeads(true)
    );
    this.allLeadsGridStore = new GridStore(() => this.getAllLeads());
    this.editLeadDetailsFormStore = new EditLeadDetailsFormStore(
      this.globalStore
    );
    this.convertLeadToLenderStore = new ConvertLeadToLenderStore(
      this.globalStore
    );
    this.removeLeadFromQueueStore = new RemoveLeadFromQueueStore(
      this.globalStore
    );
    this.leadsDashboardStore = new LeadsDashboardStore(
      this.globalStore,
      this.userStore,
      this
    );
    this.leadsStore = new LeadsStore(this.globalStore);
    this.sendEmailsStore = new SendEmailsStore(this.globalStore);

    this.leadIds = [];
    this.borrowerIds = [];
    this.companyContactIds = [];

    this.setDefaults();
    makeObservable(this, {
      assignOwner: action,
      getLeadDetails: flow,
      editLeadDetails: flow,
      editLeadStatus: flow,
      convertLeadToLender: flow,
      option: observable,
      leadDetails: observable,
      isEdit: observable,
      options: observable,
      filteredOptions: observable,
      searchText: observable,
      emailAddress: observable,
      currentRole: observable,
      closeForm: flow,
      getAllOwners: flow,
      removeLeadFromQueue: flow,
      removeFromQueue: flow,
      markLeadAsContacted: flow,
      setOption: flow,
      setLeadsIds: action,
      setBorrowerIds: action,
      setCompanyContactsIds: action,
      removeLeadsIds: action,
      leadIds: observable,
      borrowerIds: observable,
      companyContactIds: observable,
      bulkChangeOwner: flow,
      bulkChangeStatus: flow,
      newLeadStatus: observable,
      setLeadStatus: flow,
      newLeadOwner: observable,
      setNewLeadOwner: flow,
      resetLeadsIds: flow,
      resetBorrowerIds: flow,
      resetCompanyContactIds: flow,
      selectedLeadRecords: computed,
      selectedBorrowerRecords: computed,
      selectedCompanyContactRecords: computed,
      searchOwnerText: observable,
      allOwners: observable,
      connectedWithRingcentral: observable,
      setEmailAddress: flow,
      setCurrentRole: flow,
      setConnectedWithRingcentral: flow,
      portalId: observable,
      userId: observable,
      setPortalId: flow,
      setUserId: flow,
      getHostByPortaId: flow,
      isSalesForce: observable,
      setIsSalesForce: flow,
      isFromPossibleMatches: observable,
      setIsFromPossibleMatches: action,
      moveFundedLoans: observable,
      moveNonFundedLoans: observable,
      setMoveFundedLoans: flow,
      setMoveNonFundedLoans: flow,
      recordType: observable,
      setRecordType: flow,
      isBorrower: observable,
      setIsBorrower: flow,
      getLeadForEmailPreview: action,
      getSelectedRowByRecordType: action,
      gridBusinessUnit: observable,
      getOwnerOptionsByBrand: action,
      sendNotification: observable,
      setSendNotification: flow,
      changeOwnerFlow: flow,
      saveTag: flow,
      deleteTag: flow
    });
  }

  setDefaults() {
    //this.option = { label: 'All Leads', value: '0' };
    this.searchText = '';
    this.filteredOptions = [];
    this.leadDetails = null;
    this.isEdit = false;
    this.sendNotification = false;
    this.currentRole = '';
    this.emailAddress = '';
    this.newLeadOwner = '';
    this.newLeadStatus = '';
    this.searchOwnerText = '';
    this.gridBusinessUnit = this.globalStore.lenderInfo?.businessUnit;
  }

  setOption(value) {
    this.option = value;
  }

  setEmailAddress(value) {
    this.emailAddress = value;
  }

  setCurrentRole(value) {
    this.currentRole = value;
  }

  setConnectedWithRingcentral(value) {
    this.connectedWithRingcentral = value;
  }

  setPortalId(value) {
    this.portalId = value;
  }

  setUserId(value) {
    this.userId = value;
  }

  setIsSalesForce(value) {
    this.isSalesForce = value;
  }

  setIsFromPossibleMatches(value) {
    this.isFromPossibleMatches = value;
  }

  setMoveFundedLoans(value) {
    this.moveFundedLoans = value;
  }

  setMoveNonFundedLoans(value) {
    this.moveNonFundedLoans = value;
  }

  setRecordType(value) {
    this.recordType = value;
  }

  setSendNotification(value) {
    this.sendNotification = value;
  }

  async getLeads(recordType, subStatus) {
    let gridStore;
    if (recordType === 'TPO') {
      gridStore = this.allTPOLeadsGridStore;
    } else {
      gridStore = this.allBorrowerLeadsGridStore;
    }
    try {
      const response: ApiResponse = await this.newLeadService.getLeads(
        gridStore.gridData.meta.pageNumber,
        gridStore.gridData.meta.pageSize,
        gridStore.gridData.meta.sortBy,
        gridStore.gridData.meta.sortDir,
        gridStore.gridData.meta.filters,
        gridStore.gridData.meta.dropdownFilters,
        recordType,
        subStatus,
        this.gridBusinessUnit
      );
      return response;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'An error occurred while fetching list of all leads',
      });
    }
  }

  async getAllLeads() {
    try {
      const gridStore = this.allLeadsGridStore;
      const response: ApiResponse = await this.newLeadService.getAllLeads(
        gridStore.gridData.meta.pageNumber,
        gridStore.gridData.meta.pageSize,
        gridStore.gridData.meta.sortBy,
        gridStore.gridData.meta.sortDir,
        gridStore.gridData.meta.filters,
        gridStore.gridData.meta.dropdownFilters,
        this.gridBusinessUnit
      );
      return response;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'An error occurred while fetching list of all leads',
      });
    }
  }

  async getIncomingLeads(allRecords) {
    let gridStore;
    if (allRecords) {
      gridStore = this.incomingLeadsGridStore;
    } else {
      gridStore = this.incomingNotContactedLeadsGridStore;
    }
    try {
      const response: ApiResponse = await this.newLeadService.getIncomingLeads(
        gridStore.gridData.meta.pageNumber,
        gridStore.gridData.meta.pageSize,
        gridStore.gridData.meta.sortBy,
        gridStore.gridData.meta.sortDir,
        gridStore.gridData.meta.filters,
        gridStore.gridData.meta.dropdownFilters,
        allRecords,
        this.gridBusinessUnit
      );
      return response;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'An error occurred while fetching list of all incoming leads',
      });
    }
  }

  async getAllLeadsLists() {
    try {
      const response: ApiResponse = await this.newLeadService.getAllLeadsLists();
      return response;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'An error occurred while fetching list of all leads',
      });
    }
  }

  private fillLeadDetailsForm() {
    this.editLeadDetailsFormStore.onFieldChange(
      'firstName',
      this.leadDetails.firstName
    );
    this.editLeadDetailsFormStore.onFieldChange(
      'lastName',
      this.leadDetails.lastName
    );
    this.editLeadDetailsFormStore.onFieldChange(
      'phoneNumber',
      this.handlePhoneNumber(this.leadDetails.phoneNumber)
    );
    this.editLeadDetailsFormStore.onFieldChange(
      'email',
      this.leadDetails.email
    );
    this.editLeadDetailsFormStore.onFieldChange(
      'leadSource',
      this.handleLeadSource(this.leadDetails.leadSource)
    );
    this.editLeadDetailsFormStore.onFieldChange(
      'status',
      this.leadDetails.status
    );
    this.editLeadDetailsFormStore.onFieldChange(
      'subStatus',
      this.leadDetails.subStatus
    );
    this.editLeadDetailsFormStore.onFieldChange(
      'ownerName',
      this.leadDetails.ownerName ?? ''
    );
    this.editLeadDetailsFormStore.onFieldChange(
      'ownerId',
      this.handleOwner(this.leadDetails.ownerId)
    );
    this.editLeadDetailsFormStore.onFieldChange(
      'doNotCall',
      this.leadDetails.doNotCall
    );
    this.editLeadDetailsFormStore.onFieldChange(
      'doNotSendEmail',
      this.leadDetails.doNotSendEmail
    );
    this.editLeadDetailsFormStore.onFieldChange(
      'doNotSms',
      this.leadDetails.doNotSms
    );
    this.editLeadDetailsFormStore.onFieldChange(
      'doNotMail',
      this.leadDetails.doNotMail
    );
    this.editLeadDetailsFormStore.onFieldChange(
      'company',
      this.leadDetails.company
    );
    this.editLeadDetailsFormStore.onFieldChange(
      'secondaryPhone',
      this.leadDetails.secondaryPhone
    );
    this.editLeadDetailsFormStore.onFieldChange(
      'secondaryEmail',
      this.leadDetails.secondaryEmail
    );
    this.editLeadDetailsFormStore.onFieldChange(
      'experience',
      this.leadDetails.experience
    );
    this.editLeadDetailsFormStore.onFieldChange(
      'profession',
      this.leadDetails.profession
    );
    this.editLeadDetailsFormStore.onFieldChange('type', this.leadDetails.type);
    this.editLeadDetailsFormStore.onFieldChange(
      'recordType',
      this.leadDetails.recordType
    );
    this.editLeadDetailsFormStore.onFieldChange(
      'howDidYouHearAboutUs',
      this.leadDetails.howDidYouHearAboutUs
    );
    this.editLeadDetailsFormStore.onFieldChange(
      'brokered',
      this.leadDetails.brokered
    );
    this.editLeadDetailsFormStore.onFieldChange(
      'promoCode',
      this.leadDetails.promoCode
    );
    this.editLeadDetailsFormStore.onFieldChange(
      'dataSource',
      this.leadDetails.dataSource
    );
    this.editLeadDetailsFormStore.onFieldChange(
      'dataSourceGroup',
      this.leadDetails.dataSourceGroup
    );
    this.editLeadDetailsFormStore.onFieldChange(
      'leadFeedback',
      this.leadDetails.leadFeedback
    );
  }

  private handlePhoneNumber(phone) {
    if (phone) {
      const numericPhoneNumber = phone.replace(/\D/g, '');
      let phoneNumber = parseInt(numericPhoneNumber);
      return phoneNumber;
    }
    return null;
  }

  private handleOwner(ownerName) {
    return (
      this.allActiveOwners.find(owner => {
        return owner.value === ownerName;
      }) || ''
    );
  }

  private handleLeadSource(leadSource) {
    return (
      leadSourceOptions.find(lead => {
        return lead.value.toLowerCase() === leadSource?.toLowerCase();
      }) || ''
    );
  }

  async getLeadDetails(leadId: string) {
    try {
      this.editLeadDetailsFormStore.reset();
      const response: ApiResponse = await this.newLeadService.getLeadDetails(
        leadId,
        this.gridBusinessUnit
      );
      this.leadDetails = response.data?.data;
      this.fillLeadDetailsForm();
    } catch (error) {
      console.log(error);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'An error occurred while fetching lead details',
      });
    }
  }

  async editLeadDetails(leadId: string) {
    try {
      const body = {
        leadId: leadId,
        firstName: this.editLeadDetailsFormStore.form.fields.firstName.value,
        lastName: this.editLeadDetailsFormStore.form.fields.lastName.value,
        phone: this.editLeadDetailsFormStore.form.fields.phoneNumber.value,
        email: this.editLeadDetailsFormStore.form.fields.email.value,
        leadSource: this.editLeadDetailsFormStore.form.fields.leadSource.value
          .value,
        status: this.editLeadDetailsFormStore.form.fields.status.value,
        subStatus: this.editLeadDetailsFormStore.form.fields.subStatus.value,
        ownerId: this.editLeadDetailsFormStore.form.fields.ownerId.value.value,
        doNotCall: this.editLeadDetailsFormStore.form.fields.doNotCall.value,
        doNotSendEmail: this.editLeadDetailsFormStore.form.fields.doNotSendEmail
          .value,
        doNotSms: this.editLeadDetailsFormStore.form.fields.doNotSms.value,
        doNotMail: this.editLeadDetailsFormStore.form.fields.doNotMail.value,
        company: this.editLeadDetailsFormStore.form.fields.company.value,
        secondaryPhone: this.editLeadDetailsFormStore.form.fields.secondaryPhone
          .value,
        secondaryEmail: this.editLeadDetailsFormStore.form.fields.secondaryEmail
          .value,
        experience: this.editLeadDetailsFormStore.form.fields.experience.value,
        profession: this.editLeadDetailsFormStore.form.fields.profession.value,
        type: this.editLeadDetailsFormStore.form.fields.type.value,
        recordType: this.editLeadDetailsFormStore.form.fields.recordType.value,
        howDidYouHearAboutUs: this.editLeadDetailsFormStore.form.fields
          .howDidYouHearAboutUs.value,
        brokered: this.editLeadDetailsFormStore.form.fields.brokered.value,
        promoCode: this.editLeadDetailsFormStore.form.fields.promoCode.value,
        dataSource: this.editLeadDetailsFormStore.form.fields.dataSource.value,
        dataSourceGroup: this.editLeadDetailsFormStore.form.fields
          .dataSourceGroup.value,
        leadFeedback: this.editLeadDetailsFormStore.form.fields.leadFeedback
          .value,
        businessUnit: this.gridBusinessUnit,
      };
      await this.newLeadService.updateLeadDetails(body);
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Updated Lead Details Successfully',
      });
      this.getLeadDetails(leadId);
      this.leadsStore.leadDetailsStore.recordHistoryStore.resetAndFetchGridData();
      this.isEdit = false;
    } catch (error) {
      console.log(error);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'An error occurred while updating lead details',
      });
    }
  }

  async editLeadStatus(leadId: string) {
    try {
      const body = {
        leadId: leadId,
        status: this.editLeadDetailsFormStore.form.fields.status.value,
        businessUnit: this.gridBusinessUnit,
      };
      await this.newLeadService.updateLeadStatusById(body);
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Updated Lead Status Successfully',
      });
      this.isEdit = false;
      const currentPage = this.leadsDashboardStore.leadsDashboardGridStore
        .gridData.meta.pageNumber;
      this.leadsDashboardStore.leadsDashboardGridStore.reset();
      this.leadsDashboardStore.leadsDashboardGridStore.setPageNumber(
        currentPage,
        true
      );
    } catch (error) {
      console.log(error);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'An error occurred while updating lead status',
      });
    }
  }

  *convertLeadToLender(record: any) {
    const values = this.convertLeadToLenderStore.getFormValues();
    const accountName = values['accountName'];
    const ein = values['ein'];
    const owner = values['ownerEmail'] ? values['ownerEmail'].value : this.userStore.userInformation?.username;
    const id = record.leadId;
    const recordType = values['recordType'];
    try {
      const response: ApiResponse = yield this.newLeadService.convertLeadToLender(
        {
          accountName,
          ein,
          leadId: id,
          ownerEmail: owner,
          recordType
        }
      );
      const success = response.data?.data.success;
      console.log('Success', response);
      if (success) {
        this.globalStore.notificationStore.showSuccessNotification({
          message: `Lead converted to ${recordType} Account successfully`,
        });
      } else {
        this.globalStore.notificationStore.showErrorNotification({
          message: response.data?.data?.message,
        });
      }
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: `Error while converting Lead to ${recordType}`,
      });
    }
    if (!record.fromLeadDetails) {
      this.getLeadsListById();
    }
  }

  async getLeadsListById() {
    try {
      const response: ApiResponse = await this.newLeadService.getLeadsListById(
        this.option.value,
        this.genericGridStore.gridData.meta.pageNumber,
        this.genericGridStore.gridData.meta.pageSize,
        this.genericGridStore.gridData.meta.sortBy,
        this.genericGridStore.gridData.meta.sortDir,
        this.genericGridStore.gridData.meta.filters,
        this.genericGridStore.gridData.meta.dropdownFilters,
        this.gridBusinessUnit
      );
      return response;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'An error occurred while fetching list of all leads',
      });
    }
  }

  *closeForm() {
    this.isEdit = false;
    this.fillLeadDetailsForm();
  }

  *getAllOwners() {
    const response = yield this.newLeadService.getAllOwners();
    this.owners = response?.data?.data;
    this.allOwners = this.getOwnerOptionsByBrand('all');
    this.rocOwners = this.getOwnerOptionsByBrand('roc');
    this.facoOwners = this.getOwnerOptionsByBrand('faco');
    this.civicOwners = this.getOwnerOptionsByBrand('civic');
    this.rocActiveOwners = this.getActiveOwnerOptionsByBrand('roc');
    this.facoActiveOwners = this.getActiveOwnerOptionsByBrand('faco');
    this.civicActiveOwners = this.getActiveOwnerOptionsByBrand('civic');
    this.allActiveOwners = this.getActiveOwnerOptionsByBrand('all');
  }

  getOwnersByBusinessUnit() {
    switch (this.gridBusinessUnit) {
      case BusinessUnit.FACO:
        return this.facoOwners;
      case BusinessUnit.CIVIC:
        return this.civicOwners;
      case BusinessUnit.ROC:
        return this.rocOwners;
      default:
        return [];
    }
  }

  getActiveOwnersByBusinessUnit() {
    switch (this.gridBusinessUnit) {
      case BusinessUnit.FACO: return this.facoActiveOwners;
      case BusinessUnit.CIVIC: return this.civicActiveOwners;
      case BusinessUnit.ROC: return this.rocActiveOwners
      default: return [];
    }
  }

  getActiveOwnerOptionsByBrand(brand: string) {
    const owners = this.filterOwnersByBrand(brand).filter(
      owner => owner.active === true
    );

    return this.sortOwners(owners);
  }

  getOwnerOptionsByBrand = brand => {
    return this.sortOwners(this.filterOwnersByBrand(brand));
  };

  filterOwnersByBrand(brand: string) {
    switch (brand) {
      case 'faco':
        return this.owners.filter(owner =>
          owner.userName.includes('facolending.com')
        );
      case 'civic':
        return this.owners.filter(owner =>
          owner.userName.includes('civicfs.com')
        );
      case 'roc':
        return this.owners.filter(
          owner =>
            !owner.userName.includes('facolending.com') &&
            !owner.userName.includes('civicfs.com')
        );
      case 'all':
        return this.owners;
    }
  }

  sortOwners(owners) {
    owners = owners.sort((a, b) => {
      const nameA = (a.firstName + ' ' + a.lastName).toUpperCase();
      const nameB = (b.firstName + ' ' + b.lastName).toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });

    return owners.map(filteredOwner => ({
      value: filteredOwner.userId,
      label: filteredOwner.firstName + ' ' + filteredOwner.lastName,
      userName: filteredOwner.userName,
    }));
  }

  *removeLeadFromQueue(
    record: any,
    removalReason?: ReasonToRemoveLeadFromQueue
  ) {
    try {
      const leadId = record.leadId;
      if (!removalReason) {
        const values = this.removeLeadFromQueueStore.getFormValues();
        removalReason = values['reasonForRemoval'];
      }
      const response: ApiResponse = yield this.newLeadService.removeLeadFromQueue(
        leadId,
        removalReason,
        this.gridBusinessUnit
      );

      if (response.data.data === true) {
        this.globalStore.notificationStore.showSuccessNotification({
          message: 'Lead were move out from queue successfully ',
        });
      }
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while moving from queue',
      });
    }
  }

  *removeFromQueue(requestId: Number, dashboardListId: Number) {
    try {
      const response: ApiResponse = yield this.newLeadService.removeFromQueue(
        requestId,
        dashboardListId
      );

      if (response.data.data === true) {
        this.globalStore.notificationStore.showSuccessNotification({
          message: 'Removed from the list successfully ',
        });
      }
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while moving from queue',
      });
    }
  }

  *markLeadAsContacted(record: any) {
    try {
      const incomingLeadId = record.incomingLeadId;
      const response: ApiResponse = yield this.newLeadService.markLeadAsContacted(
        incomingLeadId
      );
      if (response.data.data === true) {
        this.incomingNotContactedLeadsGridStore.resetAndFetchGridData();
        this.globalStore.notificationStore.showSuccessNotification({
          message: 'Lead were marked as contacted',
        });
      }
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while mark lead as contacted.',
      });
    }
  }

  setLeadsIds(value, selected) {
    if (value != null && !selected) {
      this.removeLeadsIds(value);
    } else if (value != null && !this.leadIds.includes(value)) {
      this.leadIds.push(value);
    }
  }

  setBorrowerIds(value, selected) {
    if (value != null && !selected) {
      this.removeBorrowerIds(value);
    } else if (value != null) {
      this.borrowerIds.push(value);
    }
  }

  setCompanyContactsIds(value, selected) {
    if (value != null && !selected) {
      this.removeCompanyContacts(value);
    } else if (value != null) {
      this.companyContactIds.push(value);
    }
  }
  resetLeadsIds() {
    this.leadIds = [];
  }

  removeLeadsIds(value) {
    this.leadIds = this.leadIds.filter(leadId => leadId != value);
  }

  resetBorrowerIds() {
    this.borrowerIds = [];
  }

  removeBorrowerIds(value) {
    this.borrowerIds = this.borrowerIds.filter(
      borrowerId => borrowerId != value
    );
  }

  resetCompanyContactIds() {
    this.companyContactIds = [];
  }

  removeCompanyContacts(value) {
    this.companyContactIds = this.companyContactIds.filter(
      companyContactId => companyContactId != value
    );
  }

  *bulkChangeOwner() {
    try {
      if (this.verifyData(this.newLeadOwner)) {
        const leadBulkRequest = {
          leadIds: this.leadIds,
          newStatus: null,
          newOwner: this.newLeadOwner,
          moveFundedLoans: this.moveFundedLoans,
          moveNonFundedLoans: this.moveNonFundedLoans,
          recordType: this.recordType,
          businessUnit: this.gridBusinessUnit,
          sendNotification: this.sendNotification,
        };

        const response: ApiResponse = yield this.bulkChangeEndpointCall(leadBulkRequest);

        if (response.data.data === true) {
          if (!this.isFromPossibleMatches) {
            const currentPage = this.leadsDashboardStore.leadsDashboardGridStore
              .gridData.meta.pageNumber;
            this.leadsDashboardStore.leadsDashboardGridStore.reset();
            this.leadsDashboardStore.leadsDashboardGridStore.setPageNumber(
              currentPage,
              true
            );
          }
          this.globalStore.notificationStore.showSuccessNotification({
            message: 'Leads owners were changed successfully',
          });
        }
      } else {
        this.globalStore.notificationStore.showErrorNotification({
          message: 'You should select an owner to proceed.',
        });
      }
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while updating the lead owner for leads.',
      });
    }
  }

  async bulkChangeEndpointCall(leadBulkRequest: any) {
    switch (leadBulkRequest.recordType) {
      case Borrower: return await this.newLeadService.changeBorrowerOwner(leadBulkRequest);
      case Broker: return await this.newLeadService.changeBrokerOwner(leadBulkRequest);
      default: return await this.newLeadService.bulkChangeLeadOwner(leadBulkRequest);
    }
  }

  verifyData(data) {
    if (isBlank(data)) {
      return false;
    }
    return true;
  }

  *bulkChangeStatus() {
    try {
      if (this.verifyData(this.newLeadStatus)) {
        const leadBulkRequest = {
          leadIds: this.leadIds,
          newStatus: this.newLeadStatus,
          newOwner: null,
          businessUnit: this.gridBusinessUnit,
        };

        const response: ApiResponse = yield this.newLeadService.bulkChangeStatus(
          leadBulkRequest
        );

        if (response.data.data === true) {
          const currentPage = this.leadsDashboardStore.leadsDashboardGridStore
            .gridData.meta.pageNumber;
          this.leadsDashboardStore.leadsDashboardGridStore.reset();
          this.leadsDashboardStore.leadsDashboardGridStore.setPageNumber(
            currentPage,
            true
          );
          this.globalStore.notificationStore.showSuccessNotification({
            message: 'Leads statuses were changed successfully',
          });
        }
      } else {
        this.globalStore.notificationStore.showErrorNotification({
          message: 'You should select a status to proceed.',
        });
      }
    } catch (error) {
      console.log(error);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while updating statuses for leads',
      });
    }
  }

  setLeadStatus(value) {
    this.newLeadStatus = value;
  }

  setNewLeadOwner(value) {
    this.newLeadOwner = value;
  }

  setIsBorrower(value) {
    this.isBorrower = value;
  }

  get selectedLeadRecords() {
    return this.leadsDashboardStore.leadsDashboardGridStore.gridData.data.rows.filter(
      row => this.leadIds.includes(row.leadId)
    );
  }

  get selectedBorrowerRecords() {
    return this.leadsDashboardStore.leadsDashboardGridStore.gridData.data.rows.filter(
      row => this.borrowerIds.includes(row.contactId)
    );
  }

  get selectedCompanyContactRecords() {
    return this.leadsDashboardStore.leadsDashboardGridStore.gridData.data.rows.filter(
      row => this.companyContactIds.includes(row.contactId)
    );
  }

  *getHostByPortaId(portalId) {
    const apiResponse: ApiResponse = yield this.portalConfigurationService.getPortalConfigurationById(
      portalId
    );
    const portalConfigurationResponse: PortalConfiguration =
      apiResponse.data.data;
    return portalConfigurationResponse.host;
  }

  getLeadForEmailPreview() {
    return (
      this.leadDetails ??
      this.getSelectedRowByRecordType(this.recordType)?.[0] ??
      this.leadsDashboardStore.leadsDashboardGridStore.gridData.data.rows?.[0]
    );
  }

  getSelectedRowByRecordType(recordType) {
    if (recordType === BORROWER_OBJECT_TYPE) {
      return this.selectedBorrowerRecords;
    } else if (
      recordType === TPO_OBJECT_TYPE ||
      recordType === BROKER_OBJECT_TYPE
    ) {
      return this.selectedCompanyContactRecords;
    } else {
      return this.selectedLeadRecords;
    }
  }

  getCurrentContactDetails() {
    return this.leadDetails;
  }

  public *changeOwnerFlow(
    params: any,
    callback: () => void
  ) {
    this.leadIds = [params.node.data.id];
    if (
      this.globalStore.userFeatures.backOfficeLead ||
      this.globalStore.userFeatures.isBackOfficeProcessor
    ) {
      this.newLeadBaseStore.setOpenBulkLeadChangeOwnerModal(true);
    } else {
      this.changeOwner(params, callback);
    }
  }

  public changeOwner(
    params: any,
    callback: () => void
  ) {
    if (
      params.node.data?.recordType &&
      (params.node.data.recordType.includes('Borrower') ||
        params.node.data.recordType.includes('Lead') ||
        params.node.data.recordType.includes('Broker')
      )
    ) {
      this.assignOwner();
    } else {
      this.newLeadBaseStore.sendMakeMeOwnerAlert();
    }
    callback();
  }

  assignOwner = async () => {
    try {
      if (await this.isRecordAvailable()) {
        const leadBulkRequest = {
          leadIds: this.leadIds,
          newStatus: null,
          newOwner: this.newLeadOwner,
          moveFundedLoans: this.moveFundedLoans,
          moveNonFundedLoans: this.moveNonFundedLoans,
          recordType: this.recordType,
          businessUnit: this.gridBusinessUnit,
          sendNotification: this.sendNotification,
        };
        const response: ApiResponse = await this.newLeadService.changeOwner(
          this.leadIds[0],
          leadBulkRequest.recordType || 'Lead',
          this.globalStore.userFeatures.backOfficeLead
            ? leadBulkRequest.newOwner
            : null,
          this.moveNonFundedLoans,
          this.moveFundedLoans
        );

        this.newLeadBaseStore.handleAssignOwnerSuccess(
          response,
          this.leadIds[0]
        );
      }
    } catch (error) {
      const { message } = error?.error?.response?.data?.error;
      this.globalStore.notificationStore.showErrorNotification({
        message:
          message ||
          'An error occurred when trying to assign a new owner for this record',
      });
    }
  };

  *saveTag() {
    try {
      if (this.editLeadDetailsFormStore.getValue('leadTag')) {
        yield this.newLeadService.saveTag({
          tag: this.editLeadDetailsFormStore.getValue('leadTag'),
          leadId: this.leadDetails?.id,
          ownerId: this.leadDetails?.ownerId
        });
        this.editLeadDetailsFormStore.onFieldChange('leadTag', null);
        this.getLeadDetails(this.leadDetails?.id);
      }
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message:
          'An error occurred saving the tag for the lead',
      });
    }
  }

  *deleteTag(recordId: number) {
    try {
      yield this.newLeadService.deleteTag(recordId);
      this.getLeadDetails(this.leadDetails?.id);
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message:
          'An error occurred deleting the tag for the lead',
      });
    }
  }

  async isRecordAvailable() {
    if (!this.globalStore.userFeatures.backOfficeLead) {
      const result: ApiResponse = await this.newLeadService.isRecordAvailableForNewOwner(
        this.leadIds[0],
        this.globalStore.lenderInfo?.businessUnit,
        this.recordType || 'Lead',
      );
      return result.data?.data;
    }
    return true;
  }
}
