import {
  Box,
  Chip,
  Tab,
  Tabs,
  TabsActions,
} from '@material-ui/core';
import {
  makeStyles, Theme
} from '@material-ui/core/styles';
import { getBorrowerPortalTabStyles } from '@roc/feature-borrower-portal-core';
import { sanitizeTestId } from '@roc/feature-utils';
import { TestDataAttribute } from '@roc/ui';
import clsx from 'clsx';
import { useEffect, useRef } from 'react';

const useTabsStyles = makeStyles(({ spacing, palette }: Theme) => {
  return {
    disabled: {
      color: palette.text.disabled,
    },
  };
});

export const LoanDetailsTabs = ({ tabs, tabIndex, onTabChange }) => {
  const classes = getBorrowerPortalTabStyles();
  const containerRef = useRef(null);
  const actionRef = useRef<TabsActions | null>(null);
  const tabClasses = useTabsStyles();

  useEffect(() => {
    const ro = new (window as any).ResizeObserver(() => {
      if (actionRef.current && containerRef.current) {
        actionRef.current.updateIndicator();
      }
    });
    if (containerRef.current) {
      ro.observe(containerRef.current);
    }
  }, [containerRef.current, actionRef.current]);

  return (
    <div ref={containerRef}>
      <Tabs
        value={tabIndex}
        indicatorColor="primary"
        textColor="primary"
        onChange={onTabChange}
        action={actionRef}
        variant="scrollable"
        scrollButtons="auto"
        className={classes.tabRoot}
      >
        {tabs.map(tab => (
          <Tab
            key={tab.label}
            disabled={tab.disabled}
            label={
              <Box display={'flex'} alignItems={'center'}>
                <TestDataAttribute id={sanitizeTestId(tab.label)}>
                  <Box component={'span'} className={clsx(tab.disabled && tabClasses.disabled)}>
                    {tab.label}
                  </Box>
                </TestDataAttribute>
                {
                  tab.badgeCount ?
                    <Box ml={1} component={'span'} display={'flex'}>
                      <Chip variant="default"
                        size="small"
                        label={tab.badgeCount}
                        style={{
                          backgroundColor: '#d94452',
                          color: '#fff',
                          fontSize: 16,
                        }} />
                    </Box> : null
                }
              </Box>
            }
          />
        ))}
      </Tabs>
    </div>
  )
}