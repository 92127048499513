import { action, flow, makeObservable } from 'mobx';
import { GlobalStore, UserStore } from '@roc/feature-app-core';
import { SubmitAppraisalService } from '../services/submitAppraisalService';
import { SubmitAppraisalBaseStore } from './submitAppraisalBaseStore';
import { LoanOriginatorStore } from '@roc/feature-loans';
import { LoanSubType, SegmentTrackerEvent, segmentAnalytics } from '@roc/feature-utils';
import { SelectBorrowersStore } from '@roc/feature-borrowers';
import { BorrowerFormStore } from '@roc/feature-borrowers';
import { LoanParticipantsStore } from '@roc/feature-loan-participants';

const appraisalInformation = {
  fields: {
    selectedLoanSubType: {
      value: null,
      error: null,
      rule: '',
    },
    __isValidNoInteriorAccessComment__: {
      value: null,
      error: null,
      rule: '',
    },
    useCode: {
      value: '',
      error: null,
      rule: 'required',
      message: 'This field is required.',
    },
    address: {
      value: '',
      error: null,
      rule: 'required',
      message: 'This field is required.',
    },
    __isAddressInCorrectFormat__: {
      value: true,
      error: null,
      rule: 'accepted',
      message: 'Invalid Address Format',
    },
    streetNumber: {
      value: '',
      error: null,
      rule: 'required',
      message: 'This field is required.',
    },
    streetName: {
      value: '',
      error: null,
      rule: 'required',
      message: 'This field is required.',
    },
    city: {
      value: '',
      error: null,
      rule: 'required',
      message: 'This field is required.',
    },
    state: {
      value: '',
      error: null,
      rule: 'required',
      message: 'This field is required.',
    },
    zipCode: {
      value: '',
      error: null,
      rule: 'required',
      message: 'This field is required.',
    },
    latitude: {
      value: 0,
      error: null,
      rule: '',
      message: '',
    },
    longitude: {
      value: 0,
      error: null,
      rule: '',
      message: '',
    },
    propertyOwnership: {
      value: '',
      error: null,
      rule: 'required',
      message: 'This field is required.',
    },
    propertySourcing: {
      value: '',
      error: null,
      rule: [{ required_if: ['propertyOwnership', 'Purchase'] }],
      message: 'This field is required.',
      attribute: 'property sourcing',
    },
    wholesaleAmount: {
      value: '',
      error: null,
      rule: [
        { required_if: ['propertySourcing', 'Wholesaler'] },
        'min:0',
        'max:1000000000000',
      ],
      attribute: 'wholesale amount',
    },
    purchasePrice: {
      value: '',
      error: null,
      rule: 'required|min:0|max:1000000000000',
      attribute: 'purchase price',
    },
    totalRenovationBudget: {
      value: '',
      error: null,
      rule: 'required|min:0|max:1000000000000',
      attribute: 'renovation budget',
    },
    scopeOfWorkFile: {
      value: null,
      error: null,
      rule: '',
    },
    afterRepairValue: {
      value: '',
      error: null,
      rule: 'required|min:0|max:1000000000000',
      attribute: 'after repair value',
    },
    exitStrategy: {
      value: '',
      error: null,
      rule: 'required',
      message: 'This field is required.',
    },
    purchaseDate: {
      value: null,
      error: null,
      rule: [{ required_if: ['propertyOwnership', 'Owned By Borrower'] }],
      message: 'This field is required.',
    },
    sunkCost: {
      value: '',
      error: null,
      rule: [{ required_if: ['propertyOwnership', 'Owned By Borrower'] }],
      message: 'This field is required.',
    },
    renovationRemaining: {
      value: '',
      error: null,
      rule: [{ required_if: ['propertyOwnership', 'Owned By Borrower'] }],
      message: 'This field is required.',
    },
    payoffAmount: {
      value: '',
      error: null,
      rule: [{ required_if: ['propertyOwnership', 'Owned By Borrower'] }],
      message: 'This field is required.',
    },
    // Appraisal Details
    submitRushedAppraisal: {
      value: '',
      error: null,
      rule: 'required',
      message: 'This field is required.',
    },
    interiorAccessAvailable: {
      value: '',
      error: null,
      rule: [{ required_if_one_of: ['selectedLoanSubType', LoanSubType.FIX_AND_FLIP, LoanSubType.FIX_AND_FLIP_PRO] }],
      message: 'This field is required.',
    },
    inspectionBy: {
      value: '',
      error: null,
      rule: [{ required_if: ['interiorAccessAvailable', 'Y'] }],
      message: 'This field is required.',
    },
    noInteriorAccessComment: {
      value: '',
      error: null,
      rule: [{ required_if: ['__isValidNoInteriorAccessComment__', false] }],
      message: 'This field is required.',
    },
    // Borrower Information
    borrower: {
      value: null,
      error: null,
      rule: 'required',
      message: 'This field is required.',
    },
    // originator info
    originatorId: {
      value: null,
      error: null
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export class SubmitAppraisalStore extends SubmitAppraisalBaseStore {
  submitAppraisalService: SubmitAppraisalService;
  loanParticipantsStore: LoanParticipantsStore;
  userStore: UserStore;
  selectBorrowersStore: SelectBorrowersStore;

  constructor(globalStore: GlobalStore, userStore: UserStore) {
    super(appraisalInformation, globalStore);
    this.userStore = userStore;
    this.submitAppraisalService = new SubmitAppraisalService();
    this.loanParticipantsStore = new LoanParticipantsStore(this.globalStore);
    this.isClientPortal = true;
    this.selectBorrowersStore = new SelectBorrowersStore(
      globalStore,
      new BorrowerFormStore(globalStore),
      undefined,
      undefined,
      x => this.handleBorrowerChange(x),
      undefined,
      () => new BorrowerFormStore(globalStore)
    );

    makeObservable(this, {
      loadStore: flow,
      submitAppraisal: flow,
    });
  }

  *loadStore() {
    yield this.loanParticipantsStore.fetchLoanParticipants();
    const { userId } = this.userStore.userInformation;
    if (this.globalStore.userFeatures?.isLenderOriginator) {
      this.onFieldChange('originatorId', userId);
    }
  }

  handleSubmitStabilizedOrTerm() {
    const { selectedLoanSubType } = this.getFormValues();
    const isLoanSubTypeStabilizedOrTerm =
      selectedLoanSubType === LoanSubType.STABILIZED_BRIDGE ||
      selectedLoanSubType === LoanSubType.SINGLE_PROPERTY ||
      selectedLoanSubType === LoanSubType.RENTAL_PORTFOLIO;
    if (isLoanSubTypeStabilizedOrTerm) {
      this.setFormFieldValue('afterRepairValue', 0);
      this.setFormFieldValue('exitStrategy', "None");
      this.setFormFieldValue('totalRenovationBudget', 0);
      this.setFormFieldValue('renovationRemaining', 0);
      this.setFormFieldValue('sunkCost', 0);
    }
  }

  *submitAppraisal() {
    this.handleSubmitStabilizedOrTerm();
    this.runFormValidationWithMessage();
    if (this.form.meta.isValid) {
      try {
        const formValues = this.getFormValues();
        const formInformation = this.parseFormInformation(formValues);
        const scopeOfWorkFile = this.getScopeOfWorkFile(formValues);
        const response = yield this.submitAppraisalService.submitAppraisal(
          formInformation,
          scopeOfWorkFile
        );
        this.isSubmitted = true;
        this.submittedAppraisalId = response.data?.data?.propertyAppraisalId;
        this.globalStore.notificationStore.showSuccessNotification({
          message: 'Appraisal order submitted successfully.',
        });
        segmentAnalytics.trackEvent({
          name: SegmentTrackerEvent.APPRAISAL_SUBMISSION,
          userName: this.userStore?.userInformation?.username,
        });
      } catch (error) {
        console.log('error', error);
        this.globalStore.notificationStore.showErrorNotification({
          message: 'Error while submitting appraisal order.',
        });
      }
    }
  }

  reset() {
    super.reset();
    this.selectBorrowersStore.reset();
  }
}
