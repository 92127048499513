import { ApiResponse } from '@roc/client-portal-shared/services';
import { DrawRequestService } from '../services';
import { GlobalStore } from '@roc/client-portal-shared/stores';
import { DrawStore, DrawRequestBaseStore } from '@roc/feature-draws';
import { action, makeObservable } from 'mobx';


export class DrawRequestStorePublic extends DrawRequestBaseStore {

  private drawRequestService: DrawRequestService;

  constructor(globalStore: GlobalStore, drawStore: DrawStore) {
    super(globalStore, drawStore);

    this.drawRequestService = new DrawRequestService();
    this.isPublic = true;

    makeObservable(this, {
      loadTableData: action,
    })
  }

  *saveForm(drawId: any) {
    const response: ApiResponse = yield this.drawRequestService.saveDrawRequest(
      drawId,
      this.tableData
    );
    this.showResponse(response, 'Draw request information saved successfully', 'Error while saving draw request');
    this.saved = true;
  }

  *submitForm(drawId: any) {
    const response: ApiResponse = yield this.drawRequestService.submitDrawRequest(
      drawId,
      this.tableData
    );
    this.showResponse(response, 'Draw request information submitted for review successfully', 'Error while submitting draw request');
  }

  async loadTableData(drawId: number) {
    try {
      const response: ApiResponse = await this.drawRequestService.getDrawDetailsPub(
        drawId
      );
      const draw = JSON.parse(response?.data?.data);
      const drawDTO = draw.data;
      const drawRequest = drawDTO.draw.drawRequest;
      this.initializeDrawRequest(drawRequest, drawDTO);
      this.tableData = drawRequest.dataContent;
      this.drawDetailsData = drawDTO;
      this.isPublic = true;
    } catch (error) {
      console.error(error);
    }
  }

  async getURL() {
    const params = 'drawId:' + this.drawDetails.drawId;
    const encodedParams = encodeURIComponent(params);
    this.url = '/draw-request/' + encodedParams;
  }

}
