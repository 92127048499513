import { Service } from '@roc/feature-app-core';

const url = {
  REQUEST_BORROWER_DEPOSIT:
    '/api/v1/document/formDocument/requestBorrowersDeposit',
};

export class GoodFaithDepositService extends Service {
  requestBorrowerDeposit = (loanId: string) => {
    return this.get(url.REQUEST_BORROWER_DEPOSIT, {
      loanId,
    });
  };
}
