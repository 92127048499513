export enum PasswordChangeType {
  CREATE,
  CREATE_MIGRATED_USER,
  UPDATE,
}

export enum CreditAuthorizationState {
  IN_PROGRESS,
  IN_REVIEW,
  DONE,
}

export enum WireAuthorizationState {
  LOADING,
  IN_PROGRESS,
  IN_REVIEW,
  DONE,
}

export type UrlParameters = {
  uuid: string;
}

export interface WireAuthInfo {
  docusignCompleted: boolean;
  accessTokenURL: string;
  publicKey: string;
  identityVerificationCompleted: boolean;
  secureMeLink: string;
  identityVerificationVersion: number;
}

export enum IdentityVerificationVersion {
  TRULIO = 1,
  AU10TIX = 2,
}
