import ReactNumberFormat, {
  NumberFormatProps as ReactNumberFormatProps,
} from 'react-number-format';
import { FieldLabel } from '../fieldLabel/fieldLabel';
import { TestDataAttribute } from '../testDataAttribute';

export type NumberFormatProps = ReactNumberFormatProps & {
  standaloneLabel?: boolean;
  testId: string;
};

export const NumberFormat = (props: NumberFormatProps) => {
  const { standaloneLabel, testId, ...reactNumberFormatComponentProps } = props;
  return (
    <>
      {standaloneLabel && reactNumberFormatComponentProps.label ? (
        <FieldLabel>
          {reactNumberFormatComponentProps.label}
          {props.required && ' *'}
        </FieldLabel>
      ) : null}
      <TestDataAttribute id={testId}>
        <div>
          <ReactNumberFormat
            {...reactNumberFormatComponentProps}
            label={
              standaloneLabel
                ? undefined
                : reactNumberFormatComponentProps.label
            }
          />
        </div>
      </TestDataAttribute>
    </>
  );
};
