

import { flow, makeObservable, action, observable, computed } from 'mobx';
import { roundHalf } from '@roc/feature-utils';

export abstract class SubmissionVsUnderwritingBaseStore {
  globalStore;
  loanStore;

  constructor(globalStore, loanStore) {
    this.globalStore = globalStore;
    this.loanStore = loanStore;
    makeObservable(this, {
      calculatedLoanData: computed,
      loanComparisonValues: computed,
      reset: action,
      amount: computed,
      rate: computed,
      aggregateLoanToAsIs: computed,
      getSubmissionVsUnderwriting: flow,
      primaryProperty: computed,
      loanSubtype: computed,
    });
  }

  abstract getSubmissionVsUnderwriting(id, loanDetails);


  reset() {
    // No reset logic required yet.
  }

  get primaryProperty() {
    return this.loanStore?.loanDetails?.primaryProperty;
  }

  get loanSubtype() {
    return this.loanStore?.loanDetails?.loanSubtype;
  }

  get amount() {
    return this.loanStore?.loanDetails?.amount;
  }

  get rate() {
    return this.loanStore?.loanDetails?.rate;
  }

  get aggregateLoanToAsIs() {
    return this.loanStore?.loanDetails?.aggregateLoanToAsIs;
  }
  

  get calculatedLoanData() {
    return this.loanStore?.loanDetails?.calculatedLoanData;
  }

  get loanComparisonValues() {
    return this.loanStore?.loanDetails?.loanComparisonValues;
  }


}
