import { Box } from '@material-ui/core';
import { useLoanStore } from '@roc/feature-loans';
import { observer } from 'mobx-react';

export const PayoffsTab = observer(({ renderPayoffScreen }) => {
  const { loanStore } = useLoanStore();
  return (
    <Box width="100%">{renderPayoffScreen(loanStore.loanDetails.servicer)}</Box>
  );
});
