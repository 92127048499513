import { FileUploadModal } from '@roc/ui';
import { observer } from 'mobx-react';
import { useLoanActionsStore } from '../hooks/useLoanActionsStore';

export const UploadHUDModal = observer(({ open, handleClose }) => {
  const { loanActionsStore } = useLoanActionsStore();

  const upload = uploads => {
    loanActionsStore.uploadHUD(uploads[0].file);
  };

  return (
    <FileUploadModal
      title={'Upload HUD'}
      open={open}
      onClose={handleClose}
      onUpload={upload}
      multiple={false}
    />
  );
});
