import { Box, CircularProgressProps, CircularProgress as MuiCircularProgress } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
    },
    bottom: {
      color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    top: {
      color: theme.palette.primary.main,
      animationDuration: '550ms',
      position: 'absolute',
      left: 0,
    },
    circle: {
      strokeLinecap: 'round',
    },
  }),
);

interface CircularLoaderProps extends CircularProgressProps {
  position?: 'absolute' | 'fixed' | 'relative',
  boxStyle?: any
}

export const CircularLoader = ({ position = 'fixed', boxStyle, ...rest }: CircularLoaderProps) => {
  const classes = useStyles();

  return (
    <Box
      position={position}
      width="100%"
      height="100%"
      top="0"
      left="0"
      display="flex"
      flexDirection="column"
      alignItems={"center"}
      justifyContent={"center"}
      zIndex="99999"
      style={boxStyle ? boxStyle : { backgroundColor: 'rgb(255 255 255 / 20%)' }}
    >

      <div className={classes.root}>
        <MuiCircularProgress
          variant="determinate"
          className={classes.bottom}
          size={30}
          thickness={4}
          {...rest}
          value={100}
        />
        <MuiCircularProgress
          variant="indeterminate"
          disableShrink
          className={classes.top}
          classes={{
            circle: classes.circle,
          }}
          size={30}
          thickness={4}
          {...rest}
        />
      </div>
    </Box>
  );
}
