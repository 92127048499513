import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  BorderColorOutlined,
  EmailOutlined,
  InsertEmoticonOutlined,
  PersonOutlined,
} from '@material-ui/icons';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from '@material-ui/lab';
import { useBaseStore } from '@roc/feature-app-core';
import { Paper } from '@roc/ui';
import { observer } from 'mobx-react';

const useTimelineItemStyles = makeStyles(theme => ({
  missingOppositeContent: {
    '&::before': {
      display: 'none',
    },
  },
  content: {
    paddingBottom: theme.spacing(3),
  },
}));

const flowData = [
  {
    title: 'Email',
    body:
      'An automated DocuSign email from credit@creditbackgroundchecker.com is sent to your borrower',
    icon: <EmailOutlined />,
  },
  {
    title: 'DocuSign',
    body:
      'Through DocuSign, borrower reads the terms, fills identifying information (e.g., SSN) and grants authorization',
    icon: <BorderColorOutlined />,
  },
  {
    title: 'Internal Review',
    body: 'Our internal review team runs a full credit and/or background check',
    icon: <PersonOutlined />,
  },
  {
    title: 'Result',
    body:
      'Credit & background analysis results are relayed to applicable parties',
    icon: <InsertEmoticonOutlined />,
  },
];

const borrowerIdVerificationFlowData = [
  {
    title: 'Email',
    body: 'A form will be sent via email to your borrower',
    icon: <EmailOutlined />,
  },
  {
    title: 'DocuSign',
    body:
      'The borrower will have to fill a DocuSign, where the borrower can read the terms, and sign to grant authorization ' +
      '(This step is required only if credit is requested)',
    icon: <BorderColorOutlined />,
  },
  {
    title: 'Internal Review',
    body: 'Our internal review team runs a soft credit pull and/or background check',
    icon: <PersonOutlined />,
  },
  {
    title: 'Result',
    body:
      'Credit & background analysis results are relayed to applicable parties',
    icon: <InsertEmoticonOutlined />,
  },
];

export const ProcessFlowCard = observer(() => {
  const classes = useTimelineItemStyles();
  const { globalStore } = useBaseStore();
  const flow = borrowerIdVerificationFlowData;
  return (
    <Paper title="Process Flow">
      <Box p={2} pb={3}>
        <Timeline>
          {flow.map((x, index) => {
            return (
              <TimelineItem key={x.title} classes={classes}>
                <TimelineSeparator>
                  <TimelineDot color="primary">{x.icon}</TimelineDot>
                  {index != flowData.length - 1 && <TimelineConnector />}
                </TimelineSeparator>
                <TimelineContent>
                  <Typography variant="h6" component="h6">
                    {x.title}
                  </Typography>
                  <Typography>{x.body}</Typography>
                </TimelineContent>
              </TimelineItem>
            );
          })}
        </Timeline>
      </Box>
    </Paper>
  );
});
