import { useLoanRoutes } from "@roc/feature-loans-routes-config";
import { StandardDialog } from "@roc/ui";
import { observer } from "mobx-react";
import React from "react";
import { useHistory } from "react-router-dom";
import { useExtensionsNewRequestStore } from '../hooks/useExtensionsNewRequestStore';
import { LoansForExtensionsRequestForm } from "./loansForExtensionsRequestForm";

export const LoansForExtensionsRequestModal = observer(() => {
  const { push } = useHistory();
  const { extensionsNewRequestStore } = useExtensionsNewRequestStore();
  const { loanRoutesConfig } = useLoanRoutes();

  return (
    <StandardDialog
      open={extensionsNewRequestStore.showLoansForExtensionsRequestModal}
      title="Extension Request Form"
      maxWidth="md"
      handleClose={() => extensionsNewRequestStore.setShowLoansForExtensionsRequestModal(false)}
      dialogContent={
        <LoansForExtensionsRequestForm
          onSubmit={(loan) => {
            push(loanRoutesConfig.loans(loan).children.extensions.url);
            extensionsNewRequestStore.setShowLoansForExtensionsRequestModal(false);
          }}
          onClose={() => extensionsNewRequestStore.setShowLoansForExtensionsRequestModal(false)}
        />
      }
    />
  );
})