import { Service } from '@roc/feature-app-core';

const url = {
  GET_SERVICING_DASHBOARD: '/api/v1/loan/specialServicing/getServicingDashboard',
  EXPORT_SERVICING_DASHBOARD: '/api/v1/loan/specialServicing/exportServicingDashboard',
  GET_INVESTOR_NAMES: '/api/v1/loan/specialServicing/getInvestorNames',
  PERFORM_RECEIVABLE_ACTION: '/api/v1/loan/specialServicing/performReceivableAction',
  PERFORM_RECEIVABLE_BILLS_ACTION: '/api/v1/loan/specialServicing/performReceivableBillsAction',
  PERFORM_PAYABLE_ACTION: '/api/v1/loan/specialServicing/performPayableAction',
  GET_VENDOR_NAMES: '/api/v1/loan/specialServicing/getVendorNames',
  GET_PAYABLES_DATA: '/api/v1/loan/specialServicing/getPayablesData',
  ADD_PROTECTIVE_ADVANCE: '/api/v1/loan/specialServicing/saveProtectiveAdvance',
  DOWNLOAD_BILL_DOCUMENT_BUNDLE: '/api/v1/loan/specialServicing/downloadBillDocuments',
  MARK_PAYABLE_AS_PAID: '/api/v1/loan/specialServicing/markPayableAsPaid',
  UPDATE_BILLS_COMMENTS: '/api/v1/loan/specialServicing/updateBillsComments',
  GET_ATTESTATION_DASHBOARD: '/api/v1/loan/specialServicing/getAttestationDashboard',
  ATTEST: '/api/v1/loan/specialServicing/attestPayable',
};

export class SpecialServicingService extends Service {

  getCounterPartyExpensesData(
    type: string,
    param: string,
    page: number,
    pageSize: number,
    sortDir: string,
    sortBy: string,
    filters: object,
    dropdownFilters: object
  ) {
    return this.get(
      url.GET_SERVICING_DASHBOARD,
      {
        type,
        param,
        page,
        pageSize,
        sortDir,
        sortBy,
        filters,
        dropdownFilters
      },
      {
        disableGlobalLoading: true,
      }
    );
  }

  getAttestationDashboard(
    loanId: number,
    type: string,
    page: number,
    pageSize: number,
    sortDir: string,
    sortBy: string,
    filters: object,
    dropdownFilters: object
  ) {
    return this.get(
      url.GET_ATTESTATION_DASHBOARD,
      {
        loanId,
        type,
        page,
        pageSize,
        sortDir,
        sortBy,
        filters,
        dropdownFilters
      },
      {
        disableGlobalLoading: true,
      }
    );
  }

  exportServicingDashboardData(
    type: string,
    param: string,
    filters: object,
    dropdownFilters: object) {
    return this.get(
      `${url.EXPORT_SERVICING_DASHBOARD}`,
      {
        type,
        param,
        filters,
        dropdownFilters
      },
      {
        responseType: 'arraybuffer',
      }
    );
  };

  downloadBillDocuments(servicingBillId: number) {
    return this.get(`${url.DOWNLOAD_BILL_DOCUMENT_BUNDLE}`, { servicingBillId },
      {
        responseType: 'arraybuffer',
      }
    );
  };

  getInvestorNames() {
    return this.get(url.GET_INVESTOR_NAMES, {},
      {
        disableGlobalLoading: true,
      }
    );
  }

  performReceivableAction(data, status) {
    return this.put(
      `${url.PERFORM_RECEIVABLE_ACTION}?status=${status}`,
      data
    );
  }

  performReceivableBillsAction(recordId, status) {
    return this.put(
      `${url.PERFORM_RECEIVABLE_BILLS_ACTION}/${recordId}?status=${status}`, null
    );
  }

  performPayableAction(data, status) {
    return this.put(
      `${url.PERFORM_PAYABLE_ACTION}?status=${status}`,
      data
    );
  }

  attest(loanId, type) {
    return this.put(
      `${url.ATTEST}/${loanId}?type=${type}`, null
    );
  }


  getPayablesData(
    type: string,
    entity: string,
    page: number,
    pageSize: number,
    sortDir: string,
    sortBy: string,
    filters: object
  ) {

    const queryParams = { type, entity, page, pageSize, sortDir, sortBy, filters };
    const metadata = { disableGlobalLoading: true };

    return this.get(url.GET_PAYABLES_DATA, queryParams, metadata);
  }

  getVendorsNames() {
    return this.get(url.GET_VENDOR_NAMES, {}, { disableGlobalLoading: true });
  }

  saveProtectiveAdvance(
    payableId: any,
    loanId: string,
    invoice: string,
    amount: string,
    typeOfFee: string,
    vendorId: string,
    file: File,
  ) {
    const formData = new FormData();
    payableId && formData.append('payableId', payableId)
    formData.append('loanId', loanId);
    formData.append('invoice', invoice);
    formData.append('amount', amount);
    formData.append('typeOfFee', typeOfFee);
    formData.append('vendorId', vendorId);
    formData.append('file', file);
    return this.post(url.ADD_PROTECTIVE_ADVANCE, formData);
  };

  markPayableAsPaid(data, reference) {
    return this.put(
      `${url.MARK_PAYABLE_AS_PAID}?reference=${reference}`,
      data
    );
  }

  updateBillsComments(servicingBillId, comments) {
    return this.put(
      `${url.UPDATE_BILLS_COMMENTS}/${servicingBillId}?comments=${comments}`, null
    );
  }
}
