import { Container, Grid } from '@material-ui/core';
import {
  MULTIFAMILY_5_PLUS
} from '@roc/feature-utils';
import { observer } from 'mobx-react';
import React from 'react';
import { SectionName } from '../components/rentalPricerComponents';
import {
  AnnualAdministrativeExpense,
  AnnualHoa,
  AnnualInsurance,
  AnnualMarketingExpense,
  AnnualPayrollExpense,
  AnnualPropertyManagementFees,
  AnnualRepairMaintenanceExpenses,
  AnnualReplacementReserve,
  AnnualTaxes,
  AnnualUtilityExpenses,
  AsIsValue,
  GrossMonthlyRent,
  PortfolioCashFlowInformation,
  PricerTargetLtv
} from '../components/rentalPricerFields';
import { useRentalPricerStore } from '../hooks/useRentalPricerStore';
import { NetOperatingIncome } from '../pricerSteps/components/netOperatingIncome';

export const PropertyValuationStep = observer(() => {
  const { rentalPricerStore } = useRentalPricerStore();
  const { rentalPricerDetailsStore } = rentalPricerStore;
  const store = rentalPricerDetailsStore.termStore;
  const { fields } = store.pricerStore.form;
  const { isSingleProperty } = rentalPricerDetailsStore;

  return (
    <Container maxWidth="md">
      <Grid container spacing={2}>
        <SectionName name="Property Valuation" />
        {isSingleProperty ? (
          <SinglePropertyFields store={store} />
        ) : (
          <PortfolioFields store={store} />
        )}
        <Grid item xs={12} style={{ marginTop: 16 }}>
          <NetOperatingIncome store={store} />
        </Grid>
      </Grid>
    </Container>
  );
});

const SinglePropertyFields = ({ store }) => {
  const { fields } = store.pricerStore.form;
  const isMultifamily5Plus = fields.propertyType?.value === MULTIFAMILY_5_PLUS;
  return (
    <>
      <AsIsValue store={store} />
      <GrossMonthlyRent store={store} />
      <AnnualTaxes store={store} />
      <AnnualInsurance store={store} />
      <AnnualHoa store={store} />
      <AnnualUtilityExpenses store={store} />
      <AnnualRepairMaintenanceExpenses store={store} />
      <AnnualPropertyManagementFees store={store} />

      {isMultifamily5Plus && (
        <>
          <AnnualAdministrativeExpense store={store} />
          <AnnualPayrollExpense store={store} />
          <AnnualMarketingExpense store={store} />
          <AnnualReplacementReserve store={store} />
        </>
      )}
      <PricerTargetLtv store={store} />
    </>
  );
};

const PortfolioFields = ({ store }) => {
  return (
    <>
      <PortfolioCashFlowInformation />
    </>
  );
};
