import React from 'react';
import {
  Typography,
  FormControl,
  FormHelperText,
  GridSize,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import { isNotBlank } from '@roc/feature-utils';
import {
  TextField,
  RadioField,
  yesNoOptions,
  FileInput,
  FieldLabel,
} from '@roc/ui';
import {
  SelectBorrowers,
  SelectBorrowerMode,
  BorrowerForm,
} from '@roc/feature-borrowers';
import { inspectionByOptions } from '../../../utils/constants';
import { BorrowerCardDisplayFields } from '@roc/feature-types';

const FileUploadInput = props => {
  const { standaloneLabel, inputLabel, ...rest } = props;

  return (
    <>
      {standaloneLabel && rest.label ? (
        <FieldLabel>
          {rest.label}
          {props.required && ' *'}
        </FieldLabel>
      ) : null}
      <FileInput {...rest} label={inputLabel || 'Click to upload a file'} />
    </>
  );
};

const useScopeOfWorkFileUploadStyles = makeStyles((theme: Theme) => ({
  note: {
    marginTop: theme.spacing(1),
  },
}));

export const ScopeOfWorkFileUpload = observer(
  ({ store, disabled, note }: { store: any; disabled?: boolean, note?: string }) => {
    const formFields = store.form.fields;

    const classes = useScopeOfWorkFileUploadStyles();

    return (
      <>
        <FileUploadInput
          standaloneLabel
          label="Please upload the scope of work."
          inputLabel="Upload File"
          testId="scopeOfWorkFile"
          values={formFields.scopeOfWorkFile.value}
          onChange={value => store.onFieldChange('scopeOfWorkFile', value)}
          showValues
          allowFileDeletion
          disabled={disabled}
        />
        {note && (
          <Typography
            className={classes.note}
            component="div"
            variant="caption"
          >
            {note}
          </Typography>
        )}
      </>
    );
  }
);

export const SubmitRushedAppraisal = observer(
  ({ store, disabled }: { store: any; disabled?: boolean }) => {
    const formFields = store.form.fields;

    return (
      <>
        <RadioField
          testId="submitRushedAppraisal"
          standaloneLabel
          label="Do you want to submit a rushed appraisal?"
          value={formFields.submitRushedAppraisal.value}
          errorText={formFields.submitRushedAppraisal.error}
          error={isNotBlank(formFields.submitRushedAppraisal.error)}
          name="submitRushedAppraisal"
          options={yesNoOptions}
          onChange={value =>
            store.onFieldChange('submitRushedAppraisal', value)
          }
          fullWidth
          row
          required
          disabled={disabled}
        />
        <Typography variant="caption">
          Rushed Appraisal cost extra $600 and appraisal report will be
          delivered within 3 business days, subject to access to the property.
        </Typography>
      </>
    );
  }
);

export const InteriorAccessAvailable = observer(
  ({
    store,
    onChange,
    disabled,
  }: {
    store: any;
    onChange?: (name: string, value: string) => void;
    disabled?: boolean;
  }) => {
    const formFields = store.form.fields;

    const _onChange = value => {
      store.onFieldChange('interiorAccessAvailable', value);
      onChange && onChange('interiorAccessAvailable', value);
    };

    return (
      <RadioField
        testId="interiorAccessAvailable"
        standaloneLabel
        label="Is interior access available?"
        value={formFields.interiorAccessAvailable.value}
        errorText={formFields.interiorAccessAvailable.error}
        error={isNotBlank(formFields.interiorAccessAvailable.error)}
        name="interiorAccessAvailable"
        options={yesNoOptions}
        onChange={_onChange}
        fullWidth
        row
        required
        disabled={disabled}
      />
    );
  }
);

export const InspectionBy = observer(
  ({ store, disabled }: { store: any; disabled?: boolean }) => {
    const formFields = store.form.fields;

    return (
      <RadioField
        testId="inspectionBy"
        standaloneLabel
        label="Who would you like to do the interior inspection?"
        value={formFields.inspectionBy.value}
        errorText={formFields.inspectionBy.error}
        error={isNotBlank(formFields.inspectionBy.error)}
        name="inspectionBy"
        options={inspectionByOptions}
        onChange={value => store.onFieldChange('inspectionBy', value)}
        fullWidth
        row
        required
        disabled={disabled}
      />
    );
  }
);

export const NoInteriorAccessComment = observer(
  ({ store, onChange = null }) => {
    const formFields = store.form.fields;

    const handleChange = e => {
      const {
        target: { value },
      } = e;
      store.onFieldChange('noInteriorAccessComment', value);
      onChange && onChange('noInteriorAccessComment', value);
    };

    return (
      <TextField
        testId="noInteriorAccessComment"
        variant="outlined"
        multiline
        standaloneLabel
        label="We need to understand why interior access is not available. This is typically OK in cases where the borrower is purchasing a distressed property. Please provide a reason."
        value={formFields.noInteriorAccessComment.value}
        helperText={formFields.noInteriorAccessComment.error}
        error={isNotBlank(formFields.noInteriorAccessComment.error)}
        onChange={handleChange}
        fullWidth
        minRows={3}
        inputProps={{ maxLength: 1000 }}
        maxRows={Infinity}
        required
      />
    );
  }
);

const useSelectBorrowerInputStyles = makeStyles((theme: Theme) => ({
  helperText: {
    marginTop: theme.spacing(2),
  },
}));

interface SelectBorrowerInputProps {
  store: any;
  modes?: SelectBorrowerMode[];
  subTitle?: React.ReactNode | string;
  borrowerCardGridSize?: GridSize;
  borrowerCardDisplayFields?: BorrowerCardDisplayFields;
}

export const SelectBorrowerInput = observer(
  ({
    store,
    modes = [SelectBorrowerMode.NEW, SelectBorrowerMode.EXISTING],
    subTitle,
    borrowerCardGridSize = 6,
    borrowerCardDisplayFields,
  }: SelectBorrowerInputProps) => {
    const formFields = store.form.fields;
    const error = formFields.borrower.error;
    const hasError = isNotBlank(error);

    const classes = useSelectBorrowerInputStyles();

    return (
      <FormControl fullWidth error={hasError}>
        <SelectBorrowers
          subTitle={subTitle}
          multiple={false}
          store={store.selectBorrowersStore}
          modes={modes}
          borrowerCardGridSize={borrowerCardGridSize}
          borrowerCardDisplayFields={borrowerCardDisplayFields}
          borrowerForm={borrowerFormStore => (
            <BorrowerForm store={borrowerFormStore} />
          )}
        />
        {hasError && (
          <FormHelperText className={classes.helperText}>
            {error}
          </FormHelperText>
        )}
      </FormControl>
    );
  }
);
