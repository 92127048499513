
import { useTheme } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Typography, useMediaQuery } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { Accordion, AccordionDetails, AccordionSummary, DenseTable } from '@roc/ui';
import { useLoanSummaryStore } from '../../hooks/useLoanSummaryStore';
import { collateralValuesColsBridge } from '../../utils/ColumnsBridgeLoanSummary';
import { groundUpCollateralValues } from '../../utils/ColumnsGroundUpLoanSummary';
import { LoanSubType, LoanType } from '@roc/feature-utils';
import { mfbCollateralValues } from '../../utils/ColumnsMfbLoanSummary';
import { collateralValuesStbBridge } from '../../utils/ColumnsStbLoanSummary';
import { collateralsColsRentalPortfo, collateralsColsSFR } from '../../utils/ColumnsTermLoanSummary';

export const CollateralsCard = (loanDetails) => {
  const [expanded, setExpanded] = React.useState(true);
  const [innerExpanded, setInnerExpanded] = React.useState(true);
  const theme = useTheme();
  const lgUp = useMediaQuery(theme.breakpoints.up('lg'), { noSsr: true });
  const collaterals = loanDetails?.loanDetails?.propertiesMap.rows;
  const loanSubtype = loanDetails?.loanDetails?.loanSubtype;

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleInnerExpandClick = () => {
    setInnerExpanded(!innerExpanded);
  };
  const { loanSummaryStore } = useLoanSummaryStore();

  const getCollateralsColumns = () => {
    const loanType = loanDetails?.loanDetails?.loanType;
    switch (loanType) {
      case LoanType.RESIDENTIAL_BRIDGE:
        return getCollateralsColumnsBridge();
      case LoanType.RESIDENTIAL_TERM:
        return getCollateralsColumnsTerm();
      default:
        return collateralsColsSFR.values;
    }
  };

  const getCollateralsColumnsTerm = () => {
    switch (loanSubtype) {
      case LoanSubType.RENTAL_PORTFOLIO:
        return collateralsColsRentalPortfo.values;
      case LoanSubType.SINGLE_PROPERTY:
      case LoanSubType.SINGLE_PROPERTY_MIXED_USE:
      case LoanSubType.SINGLE_PROPERTY_MULTIFAMILY:
        return collateralsColsSFR.values;
      default:
        return collateralsColsSFR.values;
    }
  };

  const getCollateralsColumnsBridge = () => {
    switch (loanSubtype) {
      case LoanSubType.GROUND_UP:
        return groundUpCollateralValues.values;
      case LoanSubType.FIX_AND_FLIP:
      case LoanSubType.FIX_AND_FLIP_PRO:
        return collateralValuesColsBridge.values;
      case LoanSubType.STABILIZED_BRIDGE:
        return collateralValuesStbBridge.values;
      case LoanSubType.MULTIFAMILY_BRIDGE_5PLUS:
        return mfbCollateralValues.values;
      default:
        return collateralValuesColsBridge.values;
    }
  };

  useEffect(() => {
    if (LoanSubType.RENTAL_PORTFOLIO === loanSubtype) {
      setExpanded(false);
    }
  }, []);

  useEffect(() => {
    if (collaterals?.length > 5) {
      setInnerExpanded(false);
    }
  }, [collaterals]);

  const collateralsColumns = getCollateralsColumns();
  return (
    <Accordion square expanded={expanded} onChange={handleExpandClick}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}>
        <Typography style={{
          fontSize: 18,
          fontWeight: 'bold'
        }}>
          Collaterals
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2} style={{
          margin: 'auto',
          width: '100%'
        }}>
          <Grid container spacing={2}>
            {collaterals && collaterals.map((borrower, index) => (
              <Grid item xs={12} key={index}>
                <Accordion square expanded={innerExpanded} onChange={handleInnerExpandClick}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    style={{
                      background: 'none'
                    }}>
                    <Typography>
                      {borrower.address}
                    </Typography>
                  </AccordionSummary>

                  <AccordionDetails>
                    <Grid container spacing={2} style={{
                      margin: 'auto',
                      width: '100%'
                    }}>
                      {Object.keys(loanSummaryStore.transformDataForCollaterals(borrower, collateralsColumns, 6)).map((columnKey) => (
                        <Grid item xs={12} md={4} lg={2} key={columnKey} style={{
                          borderRight: (lgUp ? '1px dashed #eee' : 'none')
                        }}>
                          <DenseTable data={loanSummaryStore.transformDataForCollaterals(borrower, collateralsColumns, 6)[columnKey]} />
                        </Grid>
                      ))}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </AccordionDetails >
    </Accordion>
  );
}