import { Service } from '@roc/feature-app-core';

const url = {
  GET_LINK_TOKEN: '/api/v1/vendor/plaid/getLinkToken',
  EXCHANGE_TOKEN: '/api/v1/vendor/plaid/exchangeToken',
  GRID: '/api/v1/vendor/plaid/getPlaidAccountGrid',
  UNLINK: '/api/v1/vendor/plaid/unlinkAccount'
};

export class PlaidService extends Service {

  getLinkToken() {
    return this.get(url.GET_LINK_TOKEN);
  }

  getAccessToken(publicToken) {
    return this.post(url.EXCHANGE_TOKEN, publicToken, { 'Content-Type': 'application/json' });
  }

  async getPlaidGrid(
    page: number,
    pageSize: number,
    sortDir: string,
    sortBy: string,
    filters: string
  ) {
    const response = await this.get(
      url.GRID,
      {
        page,
        pageSize,
        sortDir,
        sortBy,
        filters,
      },
      {
        disableGlobalLoading: true,
      }
    );

    const {
      data: { data },
    } = response;

    const rows = data.rows.map(a => ({
      accountId: a.accountId,
      mask: a.mask,
      bankName: a.bankName,
      requestedDate: a.requestedDate
    }));

    response.data.data.rows = rows;
    return response;
  }

  unlinkAccount(accountId) {
    return this.get(url.UNLINK + '?accountId=' + accountId);
  }
}
