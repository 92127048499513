import { Grid } from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { useDocumentStore } from '../../../hooks/useDocumentStore';
import { FundingTemplate as BridgeFundingTemplate } from './components/bridge/fundingTemplate';
import { FundingTemplateNotAvailable } from './components/fundingTemplateNotAvailable';
import { FundingTemplate as TermFundingTemplate } from './components/term/fundingTemplate';

export const FundingTemplate = observer(() => {
  const {
    documentStore: { documentFormStore },
  } = useDocumentStore();
  const { fundingTemplateStore } = documentFormStore;
  const {
    fundingTemplateLoaded,
    showBridgeFundingTemplate,
    showTermFundingTemplate,
  } = fundingTemplateStore;
  const { documentStore } = useDocumentStore();

  useEffect(() => {
    fundingTemplateStore.initialize();
    if (documentStore.openEstimateFundingTemplate) {
      const formattedDate = new Date().toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      });
      fundingTemplateStore.getEstimatedFundingTemplate(formattedDate);
    } else {
      fundingTemplateStore.getFundingTemplate();
    }
  }, [fundingTemplateStore]);

  return (
    <Grid container spacing={2}>
      {fundingTemplateLoaded && (
        <>
          {showBridgeFundingTemplate && <BridgeFundingTemplate />}
          {showTermFundingTemplate && <TermFundingTemplate />}
        </>
      )}
      {fundingTemplateLoaded === false && <FundingTemplateNotAvailable />}
    </Grid>
  );
});

export default FundingTemplate;
