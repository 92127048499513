import { ListItemIcon, ListItemText, Menu } from '@material-ui/core';
import { CloudDownload, Link } from '@material-ui/icons';
import { useBaseStore } from '@roc/feature-app-core';
import { MenuItem } from '@roc/ui';
import { useDocumentLibraryRoutes } from './../hooks/useDocumentLibraryRoutes';

export const HtmlDocumentMenu = ({
  anchorEl,
  show,
  onClose,
  contentId,
  onDownloadPrintPdf,
  allowPrint
}) => {
  const { documentLibraryRoutesConfig } = useDocumentLibraryRoutes();
  const { globalStore } = useBaseStore();

  const downloadOrPrintPdf = () => {
    onDownloadPrintPdf();
  };

  const copyLink = () => {
    let url = globalStore.portalConfiguration.host + documentLibraryRoutesConfig.documentLibraryDocument(contentId).url;
    url = url.replace(globalStore.routeBaseUrl, '');
    navigator.clipboard.writeText(url);
    window.parent.postMessage(url, '*');
    globalStore.notificationStore.showSuccessNotification({
      message: 'Copied to Clipboard!',
      options: {
        autoHideDuration: 1000,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      }
    })
  };

  return (
    <Menu
      id="document-library-document-menu"
      getContentAnchorEl={null}
      anchorEl={anchorEl}
      keepMounted
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left', }}
      transformOrigin={{ vertical: 'top', horizontal: 'left', }}
      open={show}
      onClose={onClose}
    >
      <MenuItem
        testId={`copyLink`}
        onClick={() => {
          onClose();
          copyLink();
        }}
      >
        <ListItemIcon>
          <Link fontSize="small" />
        </ListItemIcon>
        <ListItemText>Copy link</ListItemText>
      </MenuItem>

      {allowPrint &&
        <MenuItem
          testId={`downloadPrintPdf`}
          onClick={() => {
            onClose();
            downloadOrPrintPdf();
          }}
        >
          <ListItemIcon>
            <CloudDownload fontSize="small" />
          </ListItemIcon>
          <ListItemText>Download / Print PDF</ListItemText>
        </MenuItem>
      }

    </Menu>
  )
}