import { Table, TableBody, TableCell, TableHead, TableRow, Theme, Typography, makeStyles, Grid } from '@material-ui/core';
import { alpha } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { TextField, NumberFormat, DateField } from '@roc/ui';
import { useState } from 'react';
import { BackgroundItemStore } from '../stores/backgroundItemStore';
import moment from 'moment';
import { ReviewType } from '../types/reviewType';

const useStyles = makeStyles((theme: Theme) => ({
  fixedWidthText: {
    fontSize: '1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%'
  },
  ssnCell: {
    textAlign: 'center',
    minWidth: '150px',
    justifyContent: 'center',
  },
  tableContainer: {
    borderRadius: '10px',
    border: '2px #e0e0e0 solid',
    overflow: 'hidden',
  },
  table: {
    minWidth: 750,
    width: '100%',
    '& td': {
      height: '40px',
      padding: 0,
      paddingTop: '8px',
      paddingBottom: '8px'
    },
    '& th:first-child, td[rowSpan], $addNewItemRow td': {
      paddingLeft: theme.spacing(2),
      paddingRight: '3px',
    },
    '& table tr:last-child td, & table td[rowSpan]': {
      borderBottom: 'none',
    },
  },

  editableRow: {
    position: 'relative',
    cursor: 'pointer',
    textAlign: 'center',
    '&:hover td': {
      backgroundColor: '#F0FAFF',
      transition: theme.transitions.create(['background-color', 'transform'], {
        duration: theme.transitions.duration.standard,
      }),
    },
    '&:hover $rowActions': {
      visibility: 'visible',
    },
    '& td': {
      textAlign: 'center'
    },
  },

  headerCell: {
    height: '41px',
    fontWeight: 'bold',
    color: '#757575',
    padding: 0,
    backgroundColor: '#F0FAFF',
    textAlign: 'center'
  },

  titleCell: {
    height: '46px',
    padding: 0,
    borderBottom: '2px #e0e0e0 solid',
  },

  descriptionCell: {
    whiteSpace: 'pre-wrap',
  },

  inputBase: {
    width: '100%',
    '& input': {
      backgroundColor: '#fdefbf',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      fontSize: 14,
      color: 'black',
      fontWeight: theme.typography.fontWeightBold,
      padding: '8px 4px',
      cursor: 'pointer',
      '&:focus': {
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  autocompletePaper: {
    marginLeft: 0,
    marginRight: 0,
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    fontSize: 13,
  },
  autocompleteOption: {
    minHeight: 'auto',
    alignItems: 'flex-start',
    padding: 8,
    '&[data-focus="true"]': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  addNewItemCell: {
    padding: 0,
    height: '40px',
    backgroundColor: '#fdefbf',
  },
  addNewItemRow: {
    '&:hover': {
      backgroundColor: '#fff',
    },
  },
  rowActions: {
    visibility: 'hidden',
    position: 'absolute',
    right: 0,
    top: 0,
    bottom: 0,
    zIndex: 99,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      visibility: 'visible',
    },
  },
  horizontalScroll: {
    [theme.breakpoints.down('md')]: {
      overflowX: 'scroll',
    },
  },
  clickHereToEdit: {
    color: theme.palette.grey[400],
    fontSize: theme.typography.fontSize * 0.8,
  },
  clickHereToEditHidden: {
    visibility: 'hidden',
  },
  title: {
    color: '#333333',
    fontSize: 20,
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
}));

interface BorrowerInformationProps {
  store: BackgroundItemStore;
  type: ReviewType
}

const BorrowerInformation = observer(({ store, type }: BorrowerInformationProps) => {
  const columns = type === ReviewType.CREDIT ? [
    {
      name: 'BORROWER NAME',
      width: '20%',
    },
    {
      name: 'REPORT AS OF DATE',
      width: '20%',
    },
    {
      name: 'DATE OF BIRTH',
      width: '20%',
    },
    ...(store.isInternal
      ? [
        {
          name: 'SSN',
          width: '20%',
        },
      ]
      : []),
    {
      name: 'BORROWER ADDRESS',
      width: '25%'
    }
  ] : [
    {
      name: 'BORROWER NAME',
      width: '25%',
    },
    {
      name: 'REPORT AS OF DATE',
      width: '25%',
    },
    {
      name: 'DATE OF BIRTH',
      width: '25%',
    },
    ...(store.isInternal
      ? [
        {
          name: 'SSN',
          width: '25%',
        },
      ]
      : []),
  ];

  const classes = useStyles();
  const [showDateOfBirth, setShowDateOfBirth] = useState(false);
  const [showSSN, setShowSSN] = useState(false);

  return (
    <>
      <div className={classes.tableContainer}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {columns.map(({ name, ...props }) => (
                <TableCell key={name} className={classes.headerCell} style={{ paddingLeft: '8px' }}>
                  {name}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody className={classes.editableRow}>
            <TableRow>
              <TableCell>
                <span className={classes.fixedWidthText}>{store.borrowerName}</span>
              </TableCell>
              <TableCell>
                {type === ReviewType.CREDIT ? (
                  <DateField
                    inputVariant="outlined"
                    value={store.creditDate ? new Date(store.creditDate) : null}
                    onChange={(date) => {
                      store.setCreditDate(date);
                    }}
                    required
                    testId="reportDate"
                    format="MM/dd/yyyy"
                    fullWidth={false}
                    style={{ width: '214px' }}
                    disabled={!store.isInternal}
                  />
                ) : (
                  <DateField
                    inputVariant="outlined"
                    value={store.backgroundDate ? new Date(store.backgroundDate) : null}
                    onChange={(date) => {
                      store.setBackgroundDate(date);
                    }}
                    required
                    testId="reportDate"
                    format="MM/dd/yyyy"
                    fullWidth={false}
                    style={{ width: '214px' }}
                    disabled={!store.isInternal}
                  />
                )}
              </TableCell>
              <TableCell className={classes.ssnCell}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  {!showDateOfBirth ? (
                    <DateField
                      inputVariant="outlined"
                      placeholder='**********'
                      disabled={true}
                      value={null}
                      onChange={(date) => {
                        store.setBirthdayDate(date)
                      }}
                      required
                      testId="dob"
                      format="MM/dd/yyyy"
                      fullWidth={false}
                      style={{ width: '214px' }}

                    />
                  ) : (
                    <DateField
                      inputVariant="outlined"
                      value={store.birthdayDate ? new Date(store.birthdayDate) : null}
                      onChange={(date) => {
                        store.setBirthdayDate(date)
                      }}
                      required
                      testId="dob"
                      format="MM/dd/yyyy"
                      fullWidth={false}
                      style={{ width: '214px' }}
                      disabled={!store.isInternal}
                    />
                  )}
                  <IconButton onClick={() => setShowDateOfBirth(!showDateOfBirth)} style={{ width: '24px', marginRight: '8px', alignItems: 'center' }}>
                    {showDateOfBirth ? <VisibilityIcon fontSize='small' /> : <VisibilityOffIcon fontSize='small' />}
                  </IconButton>
                </div>
              </TableCell>
              {store.isInternal &&
                <TableCell className={classes.ssnCell}>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {!showSSN ? (
                      <TextField
                        type="text"
                        variant="outlined"
                        value={'**********'}
                        required
                        testId="shareUrl"
                        disabled={true}
                      />
                    ) : (
                      <NumberFormat
                        customInput={TextField}
                        format="###-##-####"
                        mask="_"
                        required={true}
                        variant="outlined"
                        testId="ssn"
                        value={store.ssn}
                        onChange={(e) => {
                          store.setSsn(e.target.value)
                        }}
                      />
                    )}
                    <IconButton onClick={() => setShowSSN(!showSSN)} style={{ width: '24px', marginRight: '8px', alignItems: 'center' }}>
                      {showSSN ? <VisibilityIcon fontSize='small' /> : <VisibilityOffIcon fontSize='small' />}
                    </IconButton>
                  </div>
                </TableCell>
              }
              <TableCell>
                <span className={classes.fixedWidthText}>{store.borrowerAddress}</span>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    </>
  );
});

export default BorrowerInformation;