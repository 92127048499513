import { Service } from '@roc/feature-app-core';
import { DealEconomics } from '../../types/documentForms/dealEconomics';

const url = {
  GET_DEAL_ECONOMICS: '/api/v1/document/formDocument/getDealEconomics',
  SAVE_DEAL_ECONOMICS: '/api/v1/document/formDocument/saveDealEconomics',
};

export class DealEconomicsService extends Service {
  getDealEconomics(loanId: string) {
    return this.get(url.GET_DEAL_ECONOMICS, {
      loanId,
    });
  }

  saveDealEconomics(loanId: string, data: DealEconomics) {
    return this.post(`${url.SAVE_DEAL_ECONOMICS}?loanId=${loanId}`, data);
  }
}
