import { Page } from '../../../components';
import { Route } from 'react-router';
import { CompanyProfile } from '../companyProfile';
import { useStore } from '@roc/client-portal-shared/hooks';
import { MersOnboardingDocusignCallback } from '../loanFundingPreferences/mersOnboardingDocusignCallback';
import { CompanyProfileCRMWrapper } from 'libs/feature-backoffice-users/src/backOfficeUsers/components/companyProfile/companyProfileCRMWrapper';
import { LenderBankInfoCallBackSuccess } from '@roc/feature-backoffice-users';

export const getCompanyProfileRoutesConfig = (
  basePath: string,
  baseUrl: string,
) => {
  return {
    companyProfile: {
      path: `${basePath}/company-profile/:selectedTab?`,
      url: `${baseUrl}/company-profile`,
      documentTitle: 'Company Profile',
    },
    mersOnboardingCallback: {
      path: `${basePath}/mers-onboarding-docusign-callback`,
      url: `${baseUrl}/mers-onboarding-docusign-callback`,
      documentTitle: 'Mers Onboarding Form Callback',
    },
  };
};

export const getVerifyBankInformationCallBackRoutesConfig = (
  basePath: string,
  baseUrl: string,
) => {
  return {
    verifyBankInformationCallBack: {
      path: `${basePath}/verifyBankInfo/docusign`,
      url: `${baseUrl}/verifyBankInfo/docusign`,
      documentTitle: 'Verify Bank Info Form',
    },
  };
};

export const getVerifyBankInformationCallBackRoutes = (basePath: string, baseUrl: string) => {
  const config = getVerifyBankInformationCallBackRoutesConfig(basePath, baseUrl);
  return {
    verifyBankInformationCallBack: (
      <Route exact path={config.verifyBankInformationCallBack.path}>
        <LenderBankInfoCallBackSuccess />
      </Route>
    )
  };
};


export const getCompanyProfileRoutes = (basePath: string, baseUrl: string) => {
  const config = getCompanyProfileRoutesConfig(basePath, baseUrl);
  const { globalStore } = useStore();
  return {
    ...(!globalStore.userFeatures?.isLenderOriginator
      ? {
        companyProfile: (
          <Route exact path={config.companyProfile.path}>
            <Page routeDefinition={config.companyProfile}>
              {globalStore.userFeatures?.backOfficeUser ? <CompanyProfileCRMWrapper /> : <CompanyProfile />}
            </Page>
          </Route>
        ),
        mersOnboardingCallback: (
          <Route exact path={config.mersOnboardingCallback.path}>
            <Page routeDefinition={config.mersOnboardingCallback}>
              <MersOnboardingDocusignCallback />
            </Page>
          </Route>
        ),
      }
      : {}),
  };
};