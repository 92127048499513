import { Page } from '@roc/feature-app-core';
import { Route } from 'react-router';
import { LoansHome } from '../titleAgent/loansHome';
import { LoanDetails } from '@roc/feature-loans';
import { getLoanRoutesConfig } from '@roc/feature-loans-routes-config';

export const getTitleAgentRoutes = (
  basePath: string,
  baseUrl: string
) => {
  const config = getLoanRoutesConfig(basePath, baseUrl);
  return {
    loansHome: (
      <Route exact path={config.loansHome.path}>
        <Page routeDefinition={config.loansHome}>
          <LoansHome />
        </Page>
      </Route>
    ),
    loanDetails: (
      <Route path={config.loans().path} >
        <LoanDetails
          hideEditBorrowers
          renderLoanSummary={() => <></>}
        />
      </Route >
    ),

  };
};
