import { GlobalStore, UserStore } from '@roc/feature-app-core';
import {
  GENERIC_ERROR_MESSAGE,
  isNil,
  LoanSubType,
  segmentAnalytics,
  SegmentTrackerEvent,
} from '@roc/feature-utils';
import { NewLoanApplicationService } from 'libs/feature-borrower-loan-submission/src/modules/newLoanApplication/services/newLoanApplicationService';
import { mapLoanApplicationPayload } from 'libs/feature-borrower-loan-submission/src/modules/newLoanApplication/utils/loanApplicationUtils';
import { flow, makeObservable, override } from 'mobx';
import { LoanService, LoanSubmissionStep } from '@roc/feature-loans';
import { BaseLoanTypeStore } from '../baseLoanTypeStore';
import { BridgeBorrowerInformationStore } from './bridgeBorrowerInformationStore';
import { BorrowerEntityInformationStore } from '../borrowerEntityInformationStore';
import { BridgePropertiesStore } from './bridgePropertiesStore';
import { OneToolService } from 'libs/feature-one-tool/src/quote/services/oneToolService';
import { getQuoteFromBorrowerLoanApplication } from 'libs/feature-loans/src/loanSubmission/stores/common/mapLoanToQuoteHelper';

export class BorrowerFixFlipStore extends BaseLoanTypeStore {
  private newLoanApplicationService: NewLoanApplicationService;
  private loanService: LoanService;
  private userStore: UserStore;
  private oneToolService: OneToolService;

  constructor(globalStore: GlobalStore, userStore: UserStore) {
    super(globalStore);
    this.isPublic = false;

    this.borrowerInformationStore = new BridgeBorrowerInformationStore(
      globalStore
    );
    this.borrowerEntityInformationStore = new BorrowerEntityInformationStore(
      globalStore,
      this.borrowerInformationStore
    );
    this.propertiesStore = new BridgePropertiesStore(globalStore);

    this.setDefaults();
    this.newLoanApplicationService = new NewLoanApplicationService();
    this.loanService = new LoanService();
    this.userStore = userStore;
    this.oneToolService = new OneToolService();

    makeObservable(this, {
      submitLoan: override,
      submitLoanApplication: flow,
      saveLoanQuoteForApplication: flow,
    });
  }

  *submitLoan() {
    yield this.submitLoanApplication();
  }

  *submitLoanApplication() {
    try {
      if (
        this.globalStore.userFeatures.enableFixFlipPro &&
        this.loanSubtype === LoanSubType.FIX_AND_FLIP
      ) {
        this.loanSubtype = LoanSubType.FIX_AND_FLIP_PRO;
      }
      this.newPublicLoanId = null;
      const data = mapLoanApplicationPayload(
        this.loanSubtype,
        this.borrowerEntityInformationStore.mainEntity,
        this.borrowerInformationStore.borrowers,
        this.propertiesStore.getProperties()
      );
      const response = yield this.newLoanApplicationService.submitLoanApplication(
        data
      );

      if (response.data?.data?.success === false) {
      } else {
        this.newPublicLoanId = response.data?.data?.loanId;
        segmentAnalytics.trackEvent({
          name: SegmentTrackerEvent.LOAN_BORROWER_SUBMISSION,
          userName: this.userStore?.userInformation?.username,
        });
      }
    } catch (error) {
      console.log(error);
      this.sendLoanSubmissionErrorEmail(
        error?.error?.response?.data?.error?.message ||
        error?.error?.message ||
        error
      );
    } finally {
      this.setActiveStep(LoanSubmissionStep.SUCCESS);
      this.saved = true;
    }
    this.saveLoanQuoteForApplication(this.loanSubtype,
      this.borrowerEntityInformationStore.mainEntity,
      this.borrowerInformationStore.borrowers,
      this.propertiesStore.getProperties());
  }

  sendLoanSubmissionErrorEmail(error: string) {
    this.loanService.sendErrorEmail(
      'Borrower Bridge Loan Submission Failed',
      `Error occured while submitting ${this.loanType} (${this.loanSubtype}) loan`,
      error || 'Error occured when submitting loan'
    );
  }

  *saveLoanQuoteForApplication(loanSubtype, entity, borrowers, properties) {
    try {
      if (!isNil(this.newPublicLoanId)) {
        const quoteData = {
          ...getQuoteFromBorrowerLoanApplication(this.newPublicLoanId, loanSubtype, entity, borrowers, properties),
          lenderId: this.globalStore.lenderInfo.lenderId,
        };
        const response = yield this.oneToolService.saveQuote(quoteData);
        console.log('Got quote with id', response?.data?.data?.quoteId)
      }
      else {
        console.log('Skipping saveLoanQuote for borrower application because loanId is null');
      }
    }
    catch (error) {
      console.log('Error while saving borrower application as loan quote');
      console.log(error);
    }
  }
}
