import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import { Originator } from '../../types/originatorTypes';
import { CardItem, EntityCard } from './entityCard';
import { formatPercentage } from '@roc/ui/utils';

export interface OriginatorCardProps {
  originator: Originator;
  onRemove?: (value: any) => void;
  onEdit?: (value: any) => void;
}

export const OriginatorCard = (props: OriginatorCardProps) => {
  const { originator, onEdit, onRemove } = props;
  const { lenderName, percentage } = originator;
  return (
    <Grid item xs={12} md={6} key={originator.lenderId}>
      <EntityCard
        testId={`originator-${originator.lenderId}`}
        title={'ORIGINATOR INFORMATION'}
        largeCard={false}
        onEdit={originator.showEdit ? onEdit : null}
        onRemove={originator.showDelete ? onRemove : null}
      >
        <List>
          <CardItem label="Name" value={lenderName} divider={false} />
          <CardItem
            label="Commission Percentage"
            value={
              percentage == null
                ? formatPercentage(0)
                : formatPercentage(percentage)
            }
          />
        </List>
      </EntityCard>
    </Grid>
  );
};
