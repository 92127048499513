import { ChatContainer, MainContainer, Sidebar } from "@chatscope/chat-ui-kit-react";
import { Box, CircularProgress, Typography, useMediaQuery } from "@material-ui/core";
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { Add } from "@material-ui/icons";
import { PageWrapper } from "@roc/feature-app-core";
import { Button, StandardDialog } from "@roc/ui";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { ChatSection } from "./components/chatSection";
import { CreateNewConversationDialog } from "./components/createNewConversationDialog";
import { ConversationSections } from "./conversationSections";
import { CommunicationStore } from "./stores/communicationStore";
import { ChatRoomStatus, ChatRoomType, ConversationAttribute, FilterType } from "./types/communicationTypes";

import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import { useCommunicationRoutesConfig } from "./hooks";
import "./styles/chatStyle.css";
import { downloadAsPdf } from "./utils/printToPdf";
import { Conversation } from "@twilio/conversations";

const useStyles = makeStyles(theme => ({
  containerWrapper: {
    height: '100%',
    position: 'relative',
    [theme.breakpoints.between(769, 'sm')]: {
      paddingTop: 24,
    },
  },
  chatMainContainer: {
    borderRadius: 16,
    '& .cs-button': {
      color: theme.palette.primary.dark + ' !important',
    },
    '& .cs-typing-indicator__dot': {
      backgroundColor: theme.palette.primary.dark + ' !important',
    },
    '& .cs-typing-indicator__text': {
      color: theme.palette.primary.dark + ' !important',
    },
    [theme.breakpoints.down(769)]: {
      '& .cs-sidebar.cs-sidebar--left': {
        flexGrow: 1,
        maxWidth: '100%',
        overflow: 'auto',
      },
      '& .cs-conversation__content': {
        display: 'block !important',
      },
    },
  },
  print: {
    width: '210mm',
    minHeight: '297mm',
    height: 'auto',
    display: 'inline-table',
    ['& .cs-sidebar']: {
      display: 'none !important',
    },
    ['& .best-practices']: {
      display: 'none !important',
    },
    ['& .cs-conversation-header__actions']: {
      display: 'none !important',
    },
    ['& .cs-message-list__scroll-wrapper']: {
      position: 'initial',
      display: 'inline-table',
    },
    ['& .chat-messages-container']: {
      // Remove position:absolute inside scroll
      position: 'initial !important',
      display: 'inline-table',
      width: '100%'
    },
    // Hide message input
    ['& .cs-message-input, & .cs-message-list + div']: {
      display: 'none !important',
    },
    '& *': {
      zoom: 'normal !important',
    },
    '& .cs-main-container': {
      display: 'inline-table',
    },
    '& .cs-chat-container': {
      display: 'inline-table',
    },
    '& .cs-message-list': {
      display: 'inline-table',
    },
  },
}));

export const SelectConversation = observer(({ communicationStore, shouldFetch = false }: {
  communicationStore: CommunicationStore,
  shouldFetch?: boolean
}) => {
  const { conversationId } = useParams<{ conversationId: string }>();
  const { conversations } = communicationStore;

  useEffect(() => {
    if (conversationId) {
      if (shouldFetch) {
        if (conversations[conversationId]) {
          communicationStore.setCurrentConversation(conversations[conversationId]);
        } else {
          communicationStore.loadOneConversation(conversationId, () => { });
        }
      }
      communicationStore.setCurrentConversationId(conversationId);
    }
    else {
      communicationStore.clearCurrentConversation();
    }
  }, [conversationId]);

  return (
    (conversationId) ?
      <PageWrapper title={`Conversation - ${conversationId}`}></PageWrapper>
      : null
  );
});

interface MyChatContainerParentProps extends MyChatContainerProps {
  renderAsModalInSmallerScreens?: boolean;
  onModalClose?: () => void;
}

interface MyChatContainerProps {
  id?: number; // this will be loanId, appraisalId etc., based on conversationType in the store
  showLoading?: boolean;
  communicationStore: CommunicationStore;
  style?: React.CSSProperties;
  className?: string;
  hideSidebar?: boolean;
  onConversationSelect?: (conversationId: string) => void;
  showSnippet?: boolean;
  showEmailAddress?: boolean;
  allowManageParticipants?: boolean;
  allowNewConversations?: boolean;
  allowAddParticipants?: boolean;
  allowAddInternalParticipants?: boolean;
  allowRemoveParticipants?: boolean;
  allowJoinConversation?: boolean;
  filterType?: FilterType;
  onUserNewConversation?: (inviteUserId: number) => void;
  sidebarComponent?: React.ReactNode;
  chatSectionOnBackClick?: () => void;
  onConversationsLoaded?: () => void;
  onOpenAddTask?: (userId: number) => void;
}

interface Attributes {
  firstName: string;
  lastName: string
}


const useDialogStyles = makeStyles((theme: Theme) => ({
  dialogContent: {
    padding: theme.spacing(0.5),
  }
}));

export const MyChatContainer = observer(({
  renderAsModalInSmallerScreens = false,
  onModalClose = () => { },
  sidebarComponent,
  onConversationsLoaded = () => { },
  ...rest
}: MyChatContainerParentProps) => {
  const classes = useDialogStyles();
  const { communicationStore } = rest;
  const theme = useTheme();
  const isTabletSize = useMediaQuery(theme.breakpoints.down(769));
  const { id, filterType } = rest;
  const [showLoading, setShowLoading] = useState<boolean>(false);

  useEffect(sidebarComponent ? () => { } : () => {
    setShowLoading(true);
    communicationStore.reset();
    communicationStore.setFilter({
      type: filterType ?? FilterType.LOAN_ID,
      value: id
    });
    if (communicationStore.isInternal) {
      communicationStore.getAllMessageSnippets();
    }
    communicationStore.loadMyConversations(1, 100, (totalCount) => {
      setShowLoading(false);
      onConversationsLoaded();
    }, () => {
      setShowLoading(false);
      onConversationsLoaded();
    });
    return () => {
      communicationStore.reset();
    };
  }, []);

  return (
    renderAsModalInSmallerScreens && isTabletSize ?
      <StandardDialog
        title={'Conversations'}
        open={true}
        handleClose={() => onModalClose()}
        removeContentBox={true}
        fullScreen={true}
        dialogContentclasses={{
          root: classes.dialogContent,
        }}
        dialogContent={
          <Box flexGrow={1}>
            <_MyChatContainer sidebarComponent={sidebarComponent} {...rest} showLoading={showLoading} />
          </Box>
        }
      /> : <_MyChatContainer sidebarComponent={sidebarComponent} {...rest} showLoading={showLoading} />
  );
});

const CircularLoader = ({ height = 'auto', style }) => {
  return (
    <Box height={height} display={'flex'} alignItems={'center'} justifyContent={'center'} p={2} style={style}>
      <CircularProgress color='primary' size={30} thickness={4} />
    </Box>
  )
}

const _MyChatContainer = observer(({
  id,
  showLoading,
  communicationStore,
  style,
  className,
  hideSidebar,
  onConversationSelect,
  showSnippet = false,
  showEmailAddress = false,
  allowManageParticipants = false,
  allowNewConversations = false,
  allowAddParticipants = true,
  allowAddInternalParticipants = false,
  allowRemoveParticipants = false,
  allowJoinConversation = false,
  onUserNewConversation,
  onOpenAddTask,
  sidebarComponent,
  chatSectionOnBackClick,
}: MyChatContainerProps) => {

  const classes = useStyles();
  const containerRef = useRef(null);
  const [toggleMobileSideBar, setToggleMobileSideBar] = useState(true);
  const [showServicing, setShowServicing] = useState(false);
  const [showArchived, setShowArchived] = useState(false);
  const [showLoanProcessing, setShowLoanProcessing] = useState(false);
  const [showOtherSection, setShowOtherSection] = useState(false);
  const [showDrawsSection, setShowDrawsSection] = useState(false);
  const [showTechSupportSection, setShowTechSupportSection] = useState(false);
  const [showSalesSupportSection, setShowSalesSupportSection] = useState(false);
  const [showClosingSupportSection, setShowClosingSupportSection] = useState(false);
  const [showProposalTopicSection, setShowProposalTopicSection] = useState(false);
  const [showTaskSection, setShowTaskSection] = useState(false);
  const [showLeadNotificationSection, setShowLeadNotificationSection] = useState(false);
  const { push } = useHistory();
  const { communicationRoutesConfig } = useCommunicationRoutesConfig();
  const {
    createNewConversationStore,
    conversations,
    messages,
    currentConversationSid,
    manageParticipantsStore
  } = communicationStore;
  const sidRef = useRef("");
  sidRef.current = currentConversationSid;

  const theme = useTheme();
  const isTabletSize = useMediaQuery(theme.breakpoints.down(769));

  useEffect(() => {
    showServicingSection();
    showArchivedStatusConversations();
    showLoanProcessingSection();
    showLoanOtherSection();
    showLoanDrawsSection();
    showSupportSection(ChatRoomType.TECH_SUPPORT, () => { setShowTechSupportSection(true) });
    showSupportSection(ChatRoomType.SALES_SUPPORT, () => { setShowSalesSupportSection(true) });
    showSupportSection(ChatRoomType.CLOSING_SUPPORT, () => { setShowClosingSupportSection(true) });
    showSupportSection(ChatRoomType.TASKS, () => { setShowTaskSection(true) });
    showSupportSection(ChatRoomType.LEAD_NOTIFICATIONS, () => { setShowLeadNotificationSection(true) });
    showDealRoomTopicSection(ChatRoomType.DEAL_ROOM_TOPIC_ROOM, () => { setShowProposalTopicSection(true) });
  }, [conversations]);

  useEffect(() => {
    if (isTabletSize) {
      setToggleMobileSideBar(currentConversationSid ? false : true);
    } else {
      setToggleMobileSideBar(false);
    }
  }, [isTabletSize, currentConversationSid]);

  const downloadConversation = async () => {
    await communicationStore.loadAllPreviousMessages();
    downloadAsPdf(containerRef.current, 'conversation.pdf', {
      scale: 1, // Prevent scren resolution interference on the final document
      onclone: (doc, el) => el.className += ' ' + classes.print,
    });
  };

  const unarchiveConversation = () => {
    communicationStore.unarchiveConversation()
  }

  const doesIdMatch = (conversation: Conversation) => {
    const attributes = conversation.attributes as ConversationAttribute;
    return attributes.loanId ? id == attributes.loanId : true;
  }

  const showArchivedStatusConversations = () => {
    const rooms = [ChatRoomStatus.ARCHIVED];
    Object.keys(conversations)
      .filter(x => doesIdMatch(conversations[x]))
      .map(cSid => {
        const chatRoomStatus = communicationStore.conversationChatRoomStatus[cSid];
        if (rooms.includes(chatRoomStatus)) {
          setShowArchived(true);
        }
      })
  }
  const showServicingSection = () => {
    const rooms = [ChatRoomType.EXTENSIONS, ChatRoomType.PAYOFF, ChatRoomType.INITIAL_PAYMENTS, ChatRoomType.FORECLOSURE];
    Object.keys(conversations)
      .filter(x => doesIdMatch(conversations[x]))
      .map(cSid => {
        const chatRoomType = conversations[cSid].attributes.chatRoomType;
        const isActive = communicationStore.conversationChatRoomStatus[cSid] === ChatRoomStatus.ACTIVE;
        if (rooms.includes(chatRoomType) && isActive) {
          setShowServicing(true);
        }
      })
  }
  const showLoanProcessingSection = () => {
    const rooms = [ChatRoomType.UNDERWRITING, ChatRoomType.CLOSING, ChatRoomType.INSURANCE, ChatRoomType.TAMARISK_APPRAISAL, ChatRoomType.INTERNAL_ROOM, ChatRoomType.NEW_LOAN_REQUEST, ChatRoomType.POST_CLOSING_EXTERNAL, ChatRoomType.TITLE, ChatRoomType.INTERNAL_VALUATION_REVIEW];
    Object.keys(conversations)
      .filter(x => doesIdMatch(conversations[x]))
      .map(cSid => {
        const chatRoomType = conversations[cSid].attributes.chatRoomType;
        const isActive = communicationStore.conversationChatRoomStatus[cSid] === ChatRoomStatus.ACTIVE;
        if (rooms.includes(chatRoomType) && isActive) {
          setShowLoanProcessing(true);
        }
      })
  }
  const showLoanOtherSection = () => {
    const rooms = [ChatRoomType.ADHOC, ChatRoomType.DEAL_ROOM];
    Object.keys(conversations)
      .filter(x => doesIdMatch(conversations[x]))
      .map(cSid => {
        const chatRoomType = conversations[cSid].attributes.chatRoomType;
        const isActive = communicationStore.conversationChatRoomStatus[cSid] === ChatRoomStatus.ACTIVE;
        if (rooms.includes(chatRoomType) && isActive) {
          setShowOtherSection(true);
        }
      })
  }

  const showLoanDrawsSection = () => {
    const rooms = [ChatRoomType.DRAWS, ChatRoomType.DRAWS_INTERNAL, ChatRoomType.DRAWS_CONSTRUCTION_DATA_REVIEW];
    Object.keys(conversations)
      .filter(x => doesIdMatch(conversations[x]))
      .map(cSid => {
        const chatRoomType = conversations[cSid].attributes.chatRoomType;
        const isActive = communicationStore.conversationChatRoomStatus[cSid] === ChatRoomStatus.ACTIVE;
        if (rooms.includes(chatRoomType) && isActive) {
          setShowDrawsSection(true);
        }
      });
  };

  const showSupportSection = (chatRoomType: ChatRoomType, setSupportSection: () => void = () => { }) => {
    const rooms = [chatRoomType];
    Object.keys(conversations)
      .map(cSid => {
        const chatRoomType = conversations[cSid].attributes.chatRoomType;
        const isActive = communicationStore.conversationChatRoomStatus[cSid] === ChatRoomStatus.ACTIVE;
        if (rooms.includes(chatRoomType) && isActive) {
          setSupportSection();
        }
      });
  };

  const showDealRoomTopicSection = (chatRoomType: ChatRoomType, setDealRoomTopicSection: () => void = () => { }) => {
    const rooms = [chatRoomType];
    Object.keys(conversations)
      .map(cSid => {
        const chatRoomType = conversations[cSid].attributes.chatRoomType;
        const isActive = communicationStore.conversationChatRoomStatus[cSid] === ChatRoomStatus.ACTIVE;
        if (rooms.includes(chatRoomType) && isActive) {
          setDealRoomTopicSection();
        }
      });
  };

  useEffect(() => {
    manageParticipantsStore.getParticipantsCalendlyLinks();
  }, []);


  return (
    <div ref={containerRef} className={clsx(classes.containerWrapper, className)} style={style ?? {}}>
      <MainContainer responsive className={classes.chatMainContainer}>
        {!hideSidebar && (
          <Sidebar position="left" scrollable={false} style={{
            display: isTabletSize ? (toggleMobileSideBar ? 'flex' : 'none') : 'flex',
            overflowY: 'auto',
          }}>
            {
              sidebarComponent ? sidebarComponent :
                <>
                  {
                    showLoading ? <CircularLoader height="100%" style={{
                      position: 'absolute',
                      width: '100%'
                    }} /> :
                      <>
                        {
                          allowNewConversations ?
                            <Button
                              testId="create-new-converstation"
                              variant="outlined"
                              color="primary"
                              startIcon={<Add />}
                              style={{
                                margin: 16
                              }}
                              onClick={() => createNewConversationStore.openAdd()}
                            >
                              New Conversation
                            </Button> :
                            (
                              isTabletSize ? null :
                                <Typography variant="h5" style={{
                                  padding: '18px 18px 17px 20px',
                                  borderBottom: '1px solid #ccc',
                                  color: '#777'
                                }}>
                                  Conversations
                                </Typography>
                            )
                        }
                        {Object.keys(conversations).length > 0 && showArchived &&
                          <ConversationSections
                            title="Archived"
                            chatRoomStatus={[ChatRoomStatus.ARCHIVED]}
                            classes={classes}
                            communicationStore={communicationStore}
                            conversations={conversations}
                            messages={messages}
                            onConversationSelect={onConversationSelect}
                          />
                        }
                        {Object.keys(conversations).length > 0 && showServicing &&
                          <ConversationSections
                            title="Servicing "
                            chatRoomTypes={[ChatRoomType.EXTENSIONS, ChatRoomType.PAYOFF, ChatRoomType.INITIAL_PAYMENTS, ChatRoomType.FORECLOSURE]}
                            classes={classes}
                            communicationStore={communicationStore}
                            conversations={conversations}
                            messages={messages}
                            onConversationSelect={onConversationSelect}
                          />
                        }
                        {Object.keys(conversations).length > 0 && showLoanProcessing &&
                          <ConversationSections
                            title="Loan Processing"
                            chatRoomTypes={[ChatRoomType.UNDERWRITING, ChatRoomType.CLOSING, ChatRoomType.INSURANCE, ChatRoomType.TAMARISK_APPRAISAL, ChatRoomType.INTERNAL_ROOM, ChatRoomType.NEW_LOAN_REQUEST, ChatRoomType.POST_CLOSING_EXTERNAL, ChatRoomType.TITLE, ChatRoomType.INTERNAL_VALUATION_REVIEW]}
                            classes={classes}
                            communicationStore={communicationStore}
                            conversations={conversations}
                            messages={messages}
                            onConversationSelect={onConversationSelect}
                          />
                        }
                        {Object.keys(conversations).length > 0 && showOtherSection &&
                          <ConversationSections
                            title="Other"
                            chatRoomTypes={[ChatRoomType.ADHOC, ChatRoomType.DEAL_ROOM]}
                            classes={classes}
                            communicationStore={communicationStore}
                            conversations={conversations}
                            messages={messages}
                            onConversationSelect={onConversationSelect}
                          />
                        }
                        {Object.keys(conversations).length > 0 && showDrawsSection &&
                          <ConversationSections
                            title="Draws"
                            chatRoomTypes={[ChatRoomType.DRAWS, ChatRoomType.DRAWS_INTERNAL, ChatRoomType.DRAWS_CONSTRUCTION_DATA_REVIEW]}
                            classes={classes}
                            communicationStore={communicationStore}
                            conversations={conversations}
                            messages={messages}
                            onConversationSelect={onConversationSelect}
                          />
                        }
                        {Object.keys(conversations).length > 0 && showTechSupportSection &&
                          <ConversationSections
                            title="Tech Support"
                            chatRoomTypes={[ChatRoomType.TECH_SUPPORT]}
                            classes={classes}
                            communicationStore={communicationStore}
                            conversations={conversations}
                            messages={messages}
                            onConversationSelect={onConversationSelect}
                          />
                        }
                        {Object.keys(conversations).length > 0 && showSalesSupportSection &&
                          <ConversationSections
                            title="Sales Support"
                            chatRoomTypes={[ChatRoomType.SALES_SUPPORT]}
                            classes={classes}
                            communicationStore={communicationStore}
                            conversations={conversations}
                            messages={messages}
                            onConversationSelect={onConversationSelect}
                          />
                        }
                        {Object.keys(conversations).length > 0 && showTaskSection &&
                          <ConversationSections
                            title="Tasks"
                            chatRoomTypes={[ChatRoomType.TASKS]}
                            classes={classes}
                            communicationStore={communicationStore}
                            conversations={conversations}
                            messages={messages}
                            onConversationSelect={onConversationSelect}
                          />
                        }
                        {Object.keys(conversations).length > 0 && showClosingSupportSection &&
                          <ConversationSections
                            title="Closing Support"
                            chatRoomTypes={[ChatRoomType.CLOSING_SUPPORT]}
                            classes={classes}
                            communicationStore={communicationStore}
                            conversations={conversations}
                            messages={messages}
                            onConversationSelect={onConversationSelect}
                          />
                        }
                        {Object.keys(conversations).length > 0 && showProposalTopicSection &&
                          <ConversationSections
                            title="Deal Room"
                            chatRoomTypes={[ChatRoomType.DEAL_ROOM_TOPIC_ROOM]}
                            classes={classes}
                            communicationStore={communicationStore}
                            conversations={conversations}
                            messages={messages}
                            onConversationSelect={onConversationSelect}
                          />
                        }
                        {Object.keys(conversations).length > 0 && showLeadNotificationSection &&
                          <ConversationSections
                            title="Lead Notification"
                            chatRoomTypes={[ChatRoomType.LEAD_NOTIFICATIONS]}
                            classes={classes}
                            communicationStore={communicationStore}
                            conversations={conversations}
                            messages={messages}
                            onConversationSelect={onConversationSelect}
                          />
                        }
                      </>
                  }
                </>
            }
          </Sidebar>
        )}
        {
          !toggleMobileSideBar &&
          <ChatSection
            as={ChatContainer}
            showLoading={showLoading}
            communicationStore={communicationStore}
            allowJoinConversation={allowJoinConversation}
            allowLeaveConversation={allowJoinConversation}
            showSnippet={showSnippet}
            onBackClick={chatSectionOnBackClick ? chatSectionOnBackClick : () => {
              communicationStore.clearCurrentConversation();
              push(communicationRoutesConfig.conversations(`${id}`).url);
            }}
            showEmailAddress={showEmailAddress}
            allowManageParticipants={allowManageParticipants}
            allowAddParticipants={allowAddParticipants}
            allowAddInternalParticipants={allowAddInternalParticipants}
            allowRemoveParticipants={allowRemoveParticipants}
            onDownloadConversation={downloadConversation}
            onUnarchiveConversation={unarchiveConversation}
            onUserNewConversation={allowNewConversations ? onUserNewConversation : undefined}
            onOpenAddTask={allowNewConversations ? onOpenAddTask : undefined} />
        }
      </MainContainer>
      {
        id && <CreateNewConversationDialog
          id={id}
          communicationStore={communicationStore}
          onSuccessfulCreation={
            (conversationSid) => push(communicationRoutesConfig.conversations(`${id}`).children.conversation(conversationSid).url)
          }
        />
      }
    </div>
  );
});
