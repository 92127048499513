import {
  Typography,
  Grid,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import {
  Button,
  ConfirmationButton,
  TextField,
} from '@roc/ui';
import { CollectCreditReportWithMessageWithCustomOptions } from '@roc/ui/formComponents';
import { useBaseStore } from '@roc/feature-app-core';
import { CloudDownload } from '@material-ui/icons';
import { isBlank, isNil, prequalificationStatusProperties } from '@roc/feature-utils';
import { Borrower } from '../types';
import { Alert } from '@material-ui/lab';
import TpoAlreadyHaveInformationStep from '../components/tpoAlreadyHaveInformationStep';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    instruction: {
      fontWeight: 600,
    },
    underline: {
      fontWeight: 600,
      textDecorationLine: 'underline',
    },
    button: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    alertWrapper: {
      width: '100%',
      marginBottom: '16px'
    }
  })
);

interface BorrowerSetupLinkProps {
  borrowerSetupStore: any;
  isInternal?: boolean;
  onBack: () => void;
  handleNext: () => void;
  handleClose: () => void;
  handleBack: () => void;
  tpoAlreadyHaveInformationStore: any
}

export const BorrowerSetupLink = observer(({ borrowerSetupStore, isInternal = false, onBack, handleNext, handleClose, handleBack, tpoAlreadyHaveInformationStore }: BorrowerSetupLinkProps) => {

  const classes = useStyles();
  const { globalStore } = useBaseStore();
  const { currentBorrower } = borrowerSetupStore;
  const sendIdVerification = !isNil(currentBorrower?.sendIdVerification) ? currentBorrower?.sendIdVerification : currentBorrower?.creditBackgroundCheck;
  const personalGuarantor = !isNil(currentBorrower?.personalGuarantor) ? currentBorrower?.personalGuarantor : currentBorrower?.runCreditOnCreation;

  const handleCopy = () => {
    const url = borrowerSetupStore.borrowerDetailsVerificationUrl;
    navigator.clipboard.writeText(url);
    if (isInternal) {
      window.parent.postMessage(url, '*');
    }
    globalStore.notificationStore.showSuccessNotification({
      message: 'Link was copied to clipboard!',
    });
  };

  const handleConfirm = () => {
    borrowerSetupStore.sendIdVerificationEmailReminder(currentBorrower?.emailAddress);
  };

  const renderButtons = () => {
    let buttons = [];

    buttons = buttons.concat(
      (<Grid item>
        <Button
          onClick={onBack}
          testId={`back-lastStep`}
          className={classes.button}
          color='primary'
          variant='outlined'
        >
          Back
        </Button>
      </Grid>)
    )

    if (sendIdVerification) {
      buttons = buttons.concat(
        (<Grid item>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleClose}
            testId={`close-lastStep`}
            className={classes.button}
          >
            CLOSE
          </Button>
        </Grid>)
      );
    } else {
      buttons = buttons.concat(
        (<Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={handleClose}
            testId={`close-lastStep`}
            className={classes.button}
          >
            I will do it later
          </Button>
        </Grid>),
        (<Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={handleNext}
            testId={`save-lastStep`}
            className={classes.button}
          >
            SAVE
          </Button>
        </Grid>)
      );
    }

    return buttons;
  };

  return (
    <Grid container spacing={2}>
      {(sendIdVerification && currentBorrower?.preQualificationStatus === prequalificationStatusProperties.ACCEPTED.name) ? (
        <>
          <Alert severity="success" className={classes.alertWrapper}>
            Borrower Setup is Completed
          </Alert>
          <Grid item xs={12} container justifyContent="flex-end" spacing={1}>
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleBack}
                testId={`back-lastStep`}
                className={classes.button}
              >
                BACK
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={handleClose}
                testId={`close-lastStep`}
                className={classes.button}
              >
                CLOSE
              </Button>
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <Alert severity="info" className={classes.alertWrapper}>
            {sendIdVerification ? `You have chosen the option to send a link to the borrower to sign the authorization form to run a ${currentBorrower?.personalGuarantor ? 'credit & ' : ''}background report.`
              : `You have chosen the option to fill in the borrower information yourself. ${currentBorrower?.personalGuarantor ? 'Please have the credit report of the borrower in authorized Xactus360 format ready. Please note if you are providing us with your own Xactus360 format it must be a hard pull.' : ''}`}
            <p style={{ marginTop: '16px' }}></p>
            Click "Back" if you would like to change the option.
          </Alert>
          {
            sendIdVerification ? (
              <Grid item xs={12} container justifyContent="center" spacing={2}>
                <Grid
                  alignItems="center"
                  container
                  item
                  justifyContent="center"
                  xs={12}
                >
                  <Grid item container xs={11} spacing={2}>
                    <Grid item>
                      <Typography className={classes.instruction}>
                        We have already sent the authorization form to the borrower. You can click on "copy link" and send to borrower yourself if you prefer.
                      </Typography>
                    </Grid>
                    <Grid item container alignItems="center" spacing={2}>
                      <Grid item xs={12} sm>
                        <TextField
                          testId="idVerificationLink"
                          variant="outlined"
                          value={borrowerSetupStore.borrowerDetailsVerificationUrl}
                          fullWidth
                          disabled
                        />
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={handleCopy}
                          testId="copyIdVerificationUrlButton"
                          fullWidth
                        >
                          COPY LINK
                        </Button>
                      </Grid>
                    </Grid>
                    <Grid item container spacing={2}>
                      <Grid item>
                        <Typography className={classes.instruction}>
                          {`You can also click "email link to borrower" and we will send the link automatically to the borrower.`}<span className={classes.underline}>{borrowerSetupStore.currentBorrower?.emailAddress}</span>
                        </Typography>
                      </Grid>
                      <Grid item container justifyContent="center">
                        <ConfirmationButton
                          variant="contained"
                          onClick={handleConfirm}
                          color="primary"
                          testId="sendIdVerificationButton"
                          confirmDialogProps={{
                            title: 'Confirmation',
                            body: 'Email will be sent out. Please confirm',
                          }}
                        >
                          Email Link to Borrower
                        </ConfirmationButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )
              : (
                <TpoAlreadyHaveInformationStep token={borrowerSetupStore?.token} showCredit={personalGuarantor} creditReportFileId={currentBorrower?.creditReportFileId} currentBorrower={currentBorrower} tpoAlreadyHaveInformationStore={tpoAlreadyHaveInformationStore} />
              )
          }
          <Grid item xs={12} container justifyContent="flex-end" spacing={1}>
            {renderButtons()}
          </Grid>
        </>
      )}
    </Grid>
  );
});

export default BorrowerSetupLink;