import { useContext } from 'react';
import { StoreContext } from '@roc/feature-app-core';
import { BackgroundReviewStore } from '../stores/backgroundReviewStore';
import { BackgroundItemBaseStore } from '../stores/backgroundItemBaseStore';
import { BackgroundItemStore } from '../stores/backgroundItemStore';
import { BackgroundItemExternalStore } from '../stores/backgroundItemExternalStore';
import { BackgroundReviewExternalStore } from '../stores';

export const useBackgroundReviewExternalStore = () =>
  useContext<{
    backgroundReviewStore: BackgroundReviewExternalStore;
    backgroundItemStore: BackgroundItemExternalStore;
  }>(StoreContext);