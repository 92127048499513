import { GlobalStore } from '@roc/feature-app-core';
import { appLocalStorage, appSessionStorage, getPortalContextPath, isElectronApp, isLocalHost, isMobileApp, LocalStorageKeys, PortalName, SessionStorageKeys } from '@roc/feature-utils';
import { action, makeObservable } from 'mobx';
import { LogoutService } from '../services/logoutService';

export class LogoutStore {
  protected globalStore: GlobalStore;
  protected logoutService: LogoutService;

  constructor(globalStore) {
    this.globalStore = globalStore;
    this.logoutService = new LogoutService();
    makeObservable(this, {
      logout: action,
      logoutWithoutRedirect: action,
    });
  }

  clearLocalStorage() {
    appLocalStorage.removeValue(LocalStorageKeys.userName);
    appLocalStorage.removeValue(LocalStorageKeys.selectedCompanyId);
    appLocalStorage.removeValue(LocalStorageKeys.portalId);
    appLocalStorage.removeValue(LocalStorageKeys.portalContextPath);
    appLocalStorage.removeValue(LocalStorageKeys.refreshTokenExpiresAt);
  }

  clearSessionStorage() {
    appSessionStorage.removeValue(SessionStorageKeys.selectedCompanyId);
    appSessionStorage.removeValue(SessionStorageKeys.portalId);
  }

  async logoutWithoutRedirect() {
    this.clearLocalStorage();
    this.clearSessionStorage();
    try {
      await this.logoutService.logout();
    } catch (error) {
      // do nothing
    }
  }

  async logout(redirectPath?: string) {
    this.clearLocalStorage();
    this.clearSessionStorage();
    try {
      await this.logoutService.logout();
    } catch (error) {
      // do nothing
    }

    let _url = '/login'
    if (isMobileApp()) {
      _url = `/${getPortalContextPath(PortalName.MOBILE_UI)}/login`
    }
    else if (isElectronApp()) {
      _url = `/${getPortalContextPath(PortalName.CHAT_UI)}/login`;
    }
    else if (isLocalHost()) {
      _url = 'http://localhost:9000/login';
    }

    window.location.href = redirectPath ? `${_url}?redirectPath=${redirectPath}` : _url;
  }
}

export default LogoutStore;
