import { Page } from '@roc/feature-app-core';
import { Route } from 'react-router';
import { useRocInternalPortalStore } from '../../../hooks';
import { ValuationReview } from '@roc/feature-valuation-review';

export const getValuationReviewInternalRoutesConfig = (
  basePath: string,
  baseUrl: string,
) => {
  return {
    valuationReview: {
      path: `${basePath}/valuation-review`,
      url: `${baseUrl}/valuation-review`,
      documentTitle: 'valuation-review',
    },
  };
};

export const getValuationReviewInternalRoutes = (
  basePath: string,
  baseUrl: string,
  loanId: any,
  propertyId: any,
) => {
  const config = getValuationReviewInternalRoutesConfig(basePath, baseUrl);
  const { valuationReviewReportStore } = useRocInternalPortalStore();

  return {
    valuationReview: (
      <Route exact path={config.valuationReview.path}>
        <Page routeDefinition={config.valuationReview}>
          <ValuationReview store={valuationReviewReportStore} loanId={loanId} propertyId={propertyId} />
        </Page>
      </Route>
    ),
  };
}