export const residentialProductsOptions = [
  'Single Family Rental (SFR)',
  'Small Balance Multi-Family',
  'Ground Up',
  'Residential Fix and Flip',
];

export const rolesOptions = [
  'Mortgage Broker',
  'Realtor',
  'Real Estate Developer',
  'General Contractor',
  'Broker',
  'Other',
];

export const tabIndex = {
  TAB_COMPANY_DETAILS: 0,
  TAB_PRODUCTS: 1,
  TAB_ROLES: 2,
  TAB_OWNERSHIP_AND_EMPLOYEES: 3,
  TAB_DOCUMENTS: 4,
  TAB_SUMMARY: 5,
};

export const lenderStatus = {
  APPLICATION_IN_PROGRESS: 'Application in Progress',
  PROSPECTING: 'Prospecting',
};
