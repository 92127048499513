import { GlobalStore } from '@roc/client-portal-shared/stores';
import { action, flow, makeObservable, observable } from 'mobx';
import { FormStore } from '@roc/feature-app-core';
import { checkIfFirstDateGreaterThanSecondDate, formatDateIgnoreTimezone } from '@roc/feature-utils';

const form = {
  fields: {
    loanId: {
      value: '',
      error: null,
      rule: '',
    },
    nextTaxPaymentDue: {
      value: '',
      error: null,
      rule: '',
      attribute: 'Next Tax Payment Due (assuming taxes w/I 60 days PAC)'
    },
    state: {
      value: '',
      error: null,
      rule: '',
      attribute: 'State'

    },
    numberOfMonthsPaymentCovers: {
      value: '',
      error: null,
      rule: '',
      attribute: 'Number of months payment covers'

    },
    firstPaymentDueDate: {
      value: '',
      error: null,
      rule: '',
      attribute: 'First (legal) Payment Due Date'

    },
    paymentsPriorToDue: {
      value: '',
      error: null,
      rule: '',
      attribute: 'Number of Payments Borrower will make prior to Taxes due'
    },
    expectedClosingDate: {
      value: '',
      error: null,
      rule: '',
      attribute: 'Expected Closing Date'
    },
    taxEscrowMonths: {
      value: '',
      error: null,
      rule: '',
      attribute: 'Tax Escrow'
    },
    taxEscrowMonthsOverride: {
      value: 3,
      error: null,
      rule: 'required|numeric|min:3',
      message: 'Value must be equal or higher than 3'
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export abstract class EscrowDepositCalculatorBaseStore extends FormStore {
  protected globalStore: GlobalStore;
  calculatedValues: any;

  abstract getAutocompleteOptions(searchTerm: string);
  abstract getPaymentValuesForState(state: string);
  abstract getLoanValues(id: string);
  abstract updateLoan(loanId: string, taxEscrowMonths: string, loanDocumentId: string, callback?: () => void);
  abstract calculateNewNumberOfPayments(firstPaymentDueDate, nextTaxPaymentDue);
  abstract calculateFirstPaymenDuetDate(expectedClosingDate);

  loanSearchText = '';
  loanSearchOptions: { label: string; value: string }[] = [];

  constructor(globalStore) {
    super(form, globalStore);
    this.globalStore = globalStore;

    makeObservable(this, {
      loanSearchText: observable,
      calculatedValues: observable,
      loanSearchOptions: observable,
      getAutocompleteOptions: flow,
      getPaymentValuesForState: flow,
      getLoanValues: flow,
      fetchOptions: flow,
      fetchLoanValues: flow,
      clearCalculatedValues: action,
      checkForValidations: action,
      setTaxEscrowMonths: action,
      setTaxEscrowMonthsOverride: action,
      calculateNewNumberOfPayments: flow,
      calculateFirstPaymenDuetDate: flow,
      updateLoan: flow,
      calculatePaymentsMonths: flow,
      calculateFirstPaymentDueDate: flow
    });
  }

  *fetchOptions(searchText: string) {
    try {
      if (!searchText) {
        this.loanSearchOptions = [];
        return;
      }
      const options = yield this.getAutocompleteOptions(searchText);
      this.loanSearchOptions = options;
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'An error occurred while loading the list of loans.',
      });
    }
  }

  *fetchLoanValues(loanId: string) {
    try {
      const loanValues = yield this.getLoanValues(loanId);
      const values = {
        nextTaxPaymentDue: loanValues.nextTaxPaymentDate,
        loanId: loanValues.loanId,
        paymentsPriorToDue: loanValues.paymentsMadePriorToTaxesDue,
        state: loanValues.state,
        expectedClosingDate: loanValues.expectedClosingDate,
        numberOfMonthsPaymentCovers: loanValues.noOfMonthsPaymentCovers,
        firstPaymentDueDate: loanValues.firstLegalPaymentDueDate ? loanValues.firstLegalPaymentDueDate : ''
      }
      this.loadForm(values);
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'An error occurred while loading the loan data.',
      });
    }
  }

  clearCalculatedValues() {
    this.calculatedValues = null;
  }


  calculateTaxEscrowMonths() {
    if (!this.checkForValidations()) {
      const fields = this.form.fields;
      const paymentCovers = fields.numberOfMonthsPaymentCovers.value ? fields.numberOfMonthsPaymentCovers.value : 0;
      const paymentPriorToTaxesDue = fields.paymentsPriorToDue.value ? fields.paymentsPriorToDue.value : 0;

      const monthsEscrow = (paymentCovers - paymentPriorToTaxesDue) < 4 ? 3 : (paymentCovers - paymentPriorToTaxesDue);
      this.form.fields.taxEscrowMonths.value = monthsEscrow;
    }
  }

  checkForValidations() {
    const firstTaxPaymentDue = new Date(this.form.fields.firstPaymentDueDate.value);
    const nextTaxPaymentDue = new Date(this.form.fields.nextTaxPaymentDue.value);
    if (this.validateDates(firstTaxPaymentDue, nextTaxPaymentDue)) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Next Tax Payment Due Date cannot be prior to the First (Legal) Payment Due Date',
      });
      return true;
    }
  }

  validateDates(firstTaxPaymentDue, nextTaxPaymentDue) {
    return checkIfFirstDateGreaterThanSecondDate(firstTaxPaymentDue, nextTaxPaymentDue);
  }

  onNextTaxPaymentDateChange(fieldName, value) {
    this.onFieldChange(fieldName, value);
    this.calculatePaymentsMonths();
  }

  onExpectedClosingDateChange(fieldName, value) {
    this.onFieldChange(fieldName, value);
    const formFields = this.form.fields;
    const expectedClosingDate = formatDateIgnoreTimezone(formFields['expectedClosingDate'].value);
    this.calculateFirstPaymentDueDate(expectedClosingDate);

    this.calculatePaymentsMonths();
  }

  *calculatePaymentsMonths() {
    const formFields = this.form.fields;
    const firstPaymentDueDate = formatDateIgnoreTimezone(formFields['firstPaymentDueDate'].value);
    const nextTaxPaymentDue = formatDateIgnoreTimezone(formFields['nextTaxPaymentDue'].value);

    const response = yield this.calculateNewNumberOfPayments(firstPaymentDueDate, nextTaxPaymentDue);
    this.onFieldChange('paymentsPriorToDue', response)
    return response;
  }

  *calculateFirstPaymentDueDate(expectedClosingDate) {
    const response = yield this.calculateFirstPaymenDuetDate(expectedClosingDate);
    this.onFieldChange('firstPaymentDueDate', response)
    return response;
  }

  setTaxEscrowMonths(value) {
    this.form.fields.taxEscrowMonths.value = value;
  }

  setTaxEscrowMonthsOverride(value) {
    this.form.fields.taxEscrowMonthsOverride.value = value;
  }

}
