import { LoanGrid } from '@roc/feature-loans-shared';
import { formatDate, isNil, LoanSubType } from '@roc/feature-utils';
import { Button, DataGrid, SelectMode, StandardDialog } from '@roc/ui';
import {
  filterProps,
  formatCurrency,
  formatPercentage,
  selectFilterProps,
} from '@roc/ui/utils';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Label } from 'recharts';
import { useExpectedClosingsStore } from '../hooks/useExpectedClosingsStore';

const loanSubTypeOptions = Object.values(LoanSubType).map(value => ({
  label: value,
  value,
}));

const columns = [
  {
    field: 'dealid',
    headerName: 'Deal Id',
    ...filterProps(),
  },
  {
    field: 'dealname',
    headerName: 'Deal Description',
    minWidth: 300,
    ...filterProps(),
  },
  {
    field: 'loanType',
    headerName: 'Loan Type',
    minWidth: 150,
    ...selectFilterProps([
      {
        label: 'Residential Bridge',
        value: 'Residential Bridge',
      },
      {
        label: 'Commercial',
        value: 'Commercial',
      },
      {
        label: 'Residential Term',
        value: 'Residential Term',
      },
    ]),
  },
  {
    field: 'loanSubtype',
    headerName: 'Loan Sub Type',
    minWidth: 150,
    ...selectFilterProps(loanSubTypeOptions),
  },
  {
    field: 'borrowerEntity',
    headerName: 'Borrower Entity',
    minWidth: 200,
    ...filterProps(),
  },
  {
    field: 'lender',
    headerName: 'Lender',
    minWidth: 150,
    ...filterProps(),
  },
  {
    field: 'loanamount',
    headerName: 'Amount',
    cellRenderer: 'currencyCellRenderer',
    ...filterProps(),
  },
  {
    field: 'rate',
    headerName: 'Rate',
    cellRenderer: 'percentageCellRenderer',
    ...filterProps(),
  },
  {
    field: 'pointsIn',
    headerName: 'Points In',
    cellRenderer: 'percentageCellRenderer',
    ...filterProps(),
  },
  {
    field: 'pointsOut',
    headerName: 'Points Out',
    cellRenderer: 'percentageCellRenderer',
    ...filterProps(),
  },
  {
    field: 'submissionDate',
    headerName: 'Submission Date',
    cellRenderer: 'dateCellRenderer',
    minWidth: 200,
    ...filterProps(),
  },
  {
    field: 'closingDate',
    headerName: 'Closing Date',
    cellRenderer: 'dateCellRenderer',
    minWidth: 200,
    ...filterProps(),
  },
  {
    field: 'status',
    headerName: 'Status',
    ...filterProps(),
  },
  {
    field: 'subStatus',
    headerName: 'Sub Status',
    ...filterProps(),
  },
  {
    field: 'entity',
    headerName: 'Entity',
    ...filterProps(),
  },
  {
    field: 'fastrackPaymentComplete',
    headerName: 'Fast Tracked Loan',
    ...filterProps(),
  },
  {
    field: 'submittedFrom',
    headerName: 'Submitted From',
    ...filterProps(),
  },
];

export const ChooseLoanDialog = observer(({ open, onClose }) => {
  const { expectedClosingsStore } = useExpectedClosingsStore();
  const gridStore = expectedClosingsStore.selectLoanGridStore;
  const [selectedLoanId, setSelectedLoanId] = useState();

  useEffect(() => {
    if (open) {
      gridStore.fetchGridData();
    }
  }, [open]);

  const onSortChanged = params => {
    const sortModel = params.api.getSortModel();
    gridStore.setSortModel(sortModel);
  };

  const onFilterChanged = params => {
    const filterModel = params.api.getFilterModel();
    gridStore.setFilterModel(filterModel);
  };

  const addLoanToClosingList = () => {
    expectedClosingsStore.addLoanToClosing(selectedLoanId);
    onClose();
  };

  return (
    <StandardDialog
      title="Select a loan"
      open={open}
      maxWidth={'xl'}
      handleClose={onClose}
      dialogContent={
        <>
          <DataGrid
            frameworkComponents={{
              dateCellRenderer: params =>
                params.value ? formatDate(params.value) : '',
              currencyCellRenderer: params =>
                !isNil(params.value) ? formatCurrency(params.value) : '',
              percentageCellRenderer: params =>
                !isNil(params.value) ? formatPercentage(params.value, 3) : '',
            }}
            columns={columns}
            rows={gridStore.gridData.data.rows}
            isLoading={gridStore.isLoading}
            paginationData={gridStore.gridData.meta}
            setPageNumber={gridStore.setPageNumber}
            selectMode={SelectMode.SINGLE}
            onSortChanged={onSortChanged}
            onFilterChanged={onFilterChanged}
            showFilters={gridStore.showFilters}
            sortModel={gridStore.sortModel}
            filterModel={gridStore.filterModel}
            domLayout="autoHeight"
            onRowSelected={params => {
              const loan = params.node.data;
              setSelectedLoanId(loan.dealid);
            }}
          />
        </>
      }
      dialogActions={
        <>
          <Button
            style={{ marginRight: '8px' }}
            onClick={onClose}
            color="primary"
            variant="outlined"
            testId="cancel"
          >
            Cancel
          </Button>
          <Button
            onClick={addLoanToClosingList}
            color="primary"
            variant="contained"
            testId="save"
            disabled={!selectedLoanId}
          >
            Choose
          </Button>
        </>
      }
    />
  );
});
