import React, { useState, useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import {
  LocationCity,
  BusinessCenter,
  AssignmentTurnedIn,
  Build,
} from '@material-ui/icons';
import { observer } from 'mobx-react';

import { StepsContainer, ScreenshotGrid, VerticalBarLoader } from '@roc/ui';
import Categories from '../common/scopeOfWorkV2Form/components/categories/categories';
import Subcategories from '../common/scopeOfWorkV2Form/components/subcategories/subcategories';
import ReviewAndSubmit from '../common/scopeOfWorkV2Form/components/reviewAndSubmit/reviewAndSubmit';

import GeneralInformation from '../common/scopeOfWorkV2Form/components/generalInformation/generalInformation';
import { ScopeOfWorkV2FormBaseStore } from '@roc/feature-sow-shared/stores/v2';
import { Box, Grid, Typography } from '@material-ui/core';
import ConfirmPopup from '../common/scopeOfWorkV2Form/components/confirmPopup/confirmPopup';
import { formatCurrency } from '@roc/ui/utils';
import { isNil } from '@roc/feature-utils';
import ReviewAndSubmitPDF from '../common/scopeOfWorkV2Form/components/reviewAndSubmit/reviewAndSubmitPDF';
import { useLoanStore } from '@roc/feature-loans';

const useStyles = makeStyles(theme =>
  createStyles({
    stepper: {
      [theme.breakpoints.down('xs')]: {
        paddingLeft: theme.spacing(0.5),
        paddingRight: theme.spacing(0.5),
      },
    },
    projectTotalLabel: {
      backgroundColor: theme.palette.secondary.main,
      border: `1px solid ${theme.palette.primary.main}`,
      borderRadius: theme.spacing(1.5, 0, 0, 1.5),
      color: theme.palette.secondary.contrastText,
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    infoBottom: {
      fontFamily: 'Oswald, sans-serif;',
    },
  })
);

interface Props {
  store: ScopeOfWorkV2FormBaseStore;
  screenshotContainerRef: any;
}

export const ScopeOfWorkV2Stepper = observer((props: Props) => {
  const { store, screenshotContainerRef } = props;
  const classes = useStyles();
  const { loanStore } = useLoanStore();
  const { loanDetails } = loanStore;

  useEffect(() => {
    if(loanDetails?.ltcCatchupDraw){
      store.setLtcCatchupAmount(loanDetails.ltcCatchupDrawAmount)
    }
  }, [loanDetails])

  useEffect(() => {
    return () => {
      if (!store.isPublicLink)
        store.reset();
    };
  }, [store]);

  const isStandalone = !store?.scopeOfWork?.assigned;

  const handleConfirmSave = () => {
    store.confirmPopupStore.openModal({
      confirmText: `Are you sure you want to save this Scope of Work?`,
      handleSubmit: () => handleSave(),
      cancelButtonText: 'Cancel',
      submitButtonText: 'Save',
    });
  };

  const handleConfirmSubmit = () => {
    store.confirmPopupStore.openModal({
      confirmText: `Are you sure you want to submit this Scope of Work?`,
      handleSubmit: () => handleSubmit(),
      cancelButtonText: 'Cancel',
      submitButtonText: 'Submit',
    });
  };

  const handleSave = () => {
    store.saveAuditLogs(() => {
      store.saveScopeOfWorkData();
      store.handleSplitedCategories();
      store.confirmPopupStore.closeModal();
    }, false);
  };

  const submitSow = async () => {
    const response: any = await store.submitScopeOfWorkData();
    store.savePdfFile(response);
    if (store.isPublicLink) {
      store.scopeOfWorkShareUrl = response.scopeOfWorkShareUrl;
    }
    else {
      store.confirmPopupStore.closeModal();
    }
  }

  const handleSubmit = async () => {
    store.setShowLoadingMask(true);
    const hasErrors = store.submissionErrors.length > 0;
    if(!hasErrors){
      store.saveAuditLogs(() => {
        submitSow();
      }, true)
    }else{
      store.manageExeptions()
    }
    store.setShowLoadingMask(false)
  };

  const getGeneralInfoTitle = () =>
    window.innerWidth > 390 ? 'General Information' : 'General Info';

  const scopeOfWorkV2Steps = [
    {
      title: getGeneralInfoTitle(),
      icon: <LocationCity color="primary" fontSize="large" />,
      onBack: () => {
        store.goPrevStep();
      },
      onNext: () => {
        if (!store.isDisabled) store.runFormValidationWithMessage();
        if (store.form.meta.isValid || store.isDisabled) store.goNextStep();
      },
      onSave: () => handleConfirmSave(),
      allowBack: false,
      allowNext: true,
      allowSave: store.showSaveButton && !store.isDisabled,
      nextButtonText: 'Next',
    },
    {
      title: 'Categories',
      icon: <BusinessCenter color="primary" fontSize="large" />,
      onBack: () => store.goPrevStep(),
      onNext: () => {
        store.goNextStep();
      },
      onSave: () => handleConfirmSave(),
      allowBack: true,
      allowNext: true,
      allowSave: store.showSaveButton && !store.isDisabled,
      nextButtonText: 'Next',
    },
    {
      title: 'Subcategories',
      icon: <Build color="primary" fontSize="large" />,
      onBack: () => store.goPrevStep(),
      onNext: () => {
        store.goNextStep();
      },
      onSave: () => handleConfirmSave(),
      allowBack: true,
      allowNext: true,
      allowSave: store.showSaveButton && !store.isDisabled,
      nextButtonText: 'Next',
    },
    {
      title: 'Review and Submit',
      icon: <AssignmentTurnedIn color="primary" fontSize="large" />,
      onBack: () => store.goPrevStep(),
      onNext: () => handleConfirmSubmit(),
      onSave: () => handleConfirmSave(),
      onReject: store.onReject,
      onApprove: store.onApprove,
      allowBack: true,
      allowReject: store.allowReject,
      allowApprove: store.allowApprove,
      allowNext: !store.isDisabled,
      allowSave: store.showSaveButton && !store.isDisabled,
      nextButtonText: isStandalone ? 'Save' : 'Submit',
    },
  ];
  return (
    <>
      <StepsContainer
        disableNavigation={false}
        activeStep={store.activeStep}
        handleStep={step => store.moveToStep(step)}
        steps={scopeOfWorkV2Steps}
        stepperClassName={classes.stepper}
      >
        <GeneralInformation store={store} />
        <Categories store={store} />
        <Subcategories store={store} />
        <ScreenshotGrid
          fileName="SOW_Review_Details.pdf"
          ref={screenshotContainerRef}
          id={'scope-of-work'}
        >
          {store.showLoadingMask && <VerticalBarLoader />}
          <ReviewAndSubmit store={store} />
          <div id="screenshotParent" style={{ overflow: 'hidden', height: 0, width: '1280px' }}>
            <ReviewAndSubmitPDF store={store} />
          </div>
        </ScreenshotGrid>
      </StepsContainer>
      <ConfirmPopup store={store} />
      {store.activeStep > 1 && (
        <Box
          className={classes.projectTotalLabel}
          pl={3}
          pr={5}
          py={1}
          position={!isNil(store.params?.isInternal) ? 'absolute' : 'fixed'}
          right="0"
          top={!isNil(store.params?.isInternal) ? '250px' : '140px'}
          zIndex="1200"
        >
          <Typography color="inherit" variant="h6">
            Project Total
          </Typography>
          <Box fontSize="22px" className={classes.infoBottom}>
            {formatCurrency(store.categoryStore.totalCost)}
          </Box>
          <Typography color="inherit" variant="h6">
            Completed Renovations
          </Typography>
          <Box fontSize="22px" className={classes.infoBottom}>
            {formatCurrency(store.categoryStore.totalCapex)}
          </Box>
        </Box>
      )}
    </>
  );
});
