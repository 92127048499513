import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import {
  AssignmentTurnedIn,
  Business,
  Description,
  MonetizationOn,
  Person,
  Room
} from '@material-ui/icons';
import { LoanSuccess } from '@roc/feature-loans';
import { Layout, useBaseStore, useUserStore } from '@roc/feature-app-core';
import { DRAFT_LOAN_TYPE_LOAN, insertIf, isNil, LoanSubType, REJECTED_STATE, REQUEST_LOAN_TYPE_LOAN } from '@roc/feature-utils';
import { Paper, StandardDialog, StepsContainer } from '@roc/ui';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import BorrowerInformation from '../components/borrowerInformation';
import { BrokerInformation } from '../components/brokerInformation';
import EntityInformation from '../components/entityInformation';
import { useFixFlipStore } from '../hooks/useFixFlipStore';
import { useLoanSubmissionRoutes } from '../hooks/useLoanSubmissionRoutes';
import Summary from '../summary';
import { LocalOffer } from '@material-ui/icons';
import LoanInformation from './components/loanInformation';
import { PropertySteps } from './components/propertySteps';
import PropertyInformation from './propertyInformation';
import { useRoutes } from '@roc/client-portal-shared/hooks';
import { RejectRequestForm } from '../components/rejectRequestForm';
import LoanRequestSuccess from '../../loans/components/loanSuccess/loanRequestSuccess';
import { LoanSubmissionStep } from '../utils/constants';
import { LoanDocuments } from '../components/loanDocuments';
import SelectPromotionGrid from '../components/selectPromotionGrid';

const useStyles = makeStyles(theme =>
  createStyles({
    container: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    paper: {
      padding: theme.spacing(4),
    },
  })
);

export const GroundUp = observer(() => {
  const { globalStore } = useBaseStore();
  const { fixFlipStore } = useFixFlipStore();
  const classes = useStyles();
  const { push } = useHistory();
  const { loanSubmissionRoutesConfig } = useLoanSubmissionRoutes();
  const { dashboardRoutesConfig } = useRoutes();
  const [openRejectReason, setOpenRejectReason] = useState(false)
  const { userStore } = useUserStore();
  const showBrokerInformation = !globalStore.userFeatures.isBroker;
  const showDocuments = globalStore.userFeatures.uploadDuringSubmissionEnabled;
  const { marketingPromotionsStore } = fixFlipStore;

  useEffect(() => {
    fixFlipStore.loadStore();
    fixFlipStore.setLoanType(LoanSubType.GROUND_UP);
    marketingPromotionsStore.fetchGridData();
  }, []);

  const onSubmit = () => {
    const isLoanDetailsValid =
      fixFlipStore.loanInformationStore.form.meta.isValid;
    const isAllStepsValid = fixFlipStore.allErrors.length === 0;
    if (!isLoanDetailsValid) {
      globalStore.notificationStore.showErrorNotification({
        message: 'Please correct the errors in Loan Details',
      });
    } else if (fixFlipStore.allErrors.length > 0) {
      globalStore.notificationStore.showErrorNotification({
        message: 'Please correct the errors listed above',
      });
    }

    const isValid = isAllStepsValid && isLoanDetailsValid;
    if (isValid) {
      fixFlipStore.onLoanSubmit();
    }
  };

  const onSave = (showSuccessNotification = true) => {
    return fixFlipStore.onLoanSave(DRAFT_LOAN_TYPE_LOAN, showSuccessNotification);
  };

  const onSaveRequest = (showSuccessNotification = true) => {
    if (!isNil(fixFlipStore?.draftLoanInfo?.draftLoanId)) {
      return fixFlipStore.onLoanSave(REQUEST_LOAN_TYPE_LOAN, showSuccessNotification);
    }
    else {
      return onLoanApplicationSave();
    }
  };

  const onLoanApplicationSave = () => {
    return fixFlipStore.onLoanApplicationSave();
  }

  const saveDraftLoan = (showSuccessNotification = true) => {
    return fixFlipStore.isLoanRequest ? onSaveRequest(showSuccessNotification) : onSave(showSuccessNotification)
  }

  const onRejectRequest = () => {
    setOpenRejectReason(true);
  }

  const rejectRequest = () => {
    if (!isNil(fixFlipStore?.draftLoanInfo?.draftLoanId)) {
      fixFlipStore.draftLoanInfo.status = REJECTED_STATE
      fixFlipStore.onLoanSave(REQUEST_LOAN_TYPE_LOAN);
    }
    else {
      fixFlipStore.rejectLoanApplication();
    }
    push(
      dashboardRoutesConfig.dashboard.url
    )
  }

  const rejectLoanBorrowerApplicationRequest = () => {
    fixFlipStore.rejectLoanApplication();
    push(
      dashboardRoutesConfig.dashboard.url
    )
  }

  const goNextStep = () => {
    const currentStep = loanSubmissionSteps[fixFlipStore.activeStep].type;
    fixFlipStore.goNextStep(currentStep);
  }

  const goPrevStep = () => {
    const currentStep = loanSubmissionSteps[fixFlipStore.activeStep].type;
    fixFlipStore.goPrevStep(currentStep);
  }

  const moveToStep = (stepIndex) => {
    const currentStep = loanSubmissionSteps[fixFlipStore.activeStep].type;
    fixFlipStore.moveToStep(stepIndex, currentStep);
  }

  const setPromotion = (promotionId: number) => {
    fixFlipStore.loanInformationStore.onChange('marketingPromotionId', promotionId);
  }

  const loanSubmissionSteps = [
    ...insertIf(showBrokerInformation, [{
      title: 'Broker Information',
      icon: <Person color="primary" fontSize="large" />,
      type: LoanSubmissionStep.BROKER_INFORMATION,
      onBack: () => {
        push(loanSubmissionRoutesConfig.submitloan.url);
      },
      onNext: () => goNextStep(),
      onSave: () => saveDraftLoan(),
      onReject: () => onRejectRequest(),
      allowBack: true,
      allowNext: true,
      allowSave: !fixFlipStore.isBorrowerLoanApplication,
      allowReject: fixFlipStore.isLoanRequest || fixFlipStore.isBorrowerLoanApplication,
    }]),
    {
      title: 'Entity Information',
      icon: <Business color="primary" fontSize="large" />,
      type: LoanSubmissionStep.ENTITY_INFORMATION,
      onBack: () => goPrevStep(),
      onNext: () => {
        goNextStep();
      },
      onSave: () => saveDraftLoan(),
      onReject: () => onRejectRequest(),
      allowBack: true,
      allowNext: true,
      allowSave: !fixFlipStore.isBorrowerLoanApplication,
      allowReject: fixFlipStore.isLoanRequest || fixFlipStore.isBorrowerLoanApplication,
    },
    {
      title: 'Borrower information',
      icon: <Person color="primary" fontSize="large" />,
      type: LoanSubmissionStep.BORROWER_INFORMATION,
      onBack: () => goPrevStep(),
      onNext: () => goNextStep(),
      onSave: () => saveDraftLoan(),
      onReject: () => onRejectRequest(),
      allowBack: true,
      allowNext: true,
      allowSave: !fixFlipStore.isBorrowerLoanApplication,
      allowReject: fixFlipStore.isLoanRequest || fixFlipStore.isBorrowerLoanApplication,
    },
    {
      title: 'Property information',
      icon: <Room color="primary" fontSize="large" />,
      type: LoanSubmissionStep.PROPERTY_INFORMATION,
      onBack: () => goPrevStep(),
      onNext: () => goNextStep(),
      onSave: () => saveDraftLoan(),
      onReject: () => onRejectRequest(),
      allowBack: true,
      allowNext: true,
      allowSave: !fixFlipStore.isBorrowerLoanApplication,
      hidden: false,
      allowReject: fixFlipStore.isLoanRequest || fixFlipStore.isBorrowerLoanApplication,
    },
    {
      title: 'Loan Details',
      icon: <MonetizationOn color="primary" fontSize="large" />,
      type: LoanSubmissionStep.LOAN_DETAILS,
      onBack: () => goPrevStep(),
      onNext: () => {
        goNextStep()
      },
      onSave: () => saveDraftLoan(),
      onReject: () => onRejectRequest(),
      allowBack: true,
      allowNext: true,
      allowSave: !fixFlipStore.isBorrowerLoanApplication,
      allowReject: fixFlipStore.isLoanRequest || fixFlipStore.isBorrowerLoanApplication,
    },
    ...insertIf(globalStore.userFeatures.uploadDuringSubmissionEnabled, [{
      title: 'Documents',
      icon: <Description color="primary" fontSize="large" />,
      type: LoanSubmissionStep.LOAN_DOCUMENTS,
      onBack: () => {
        goPrevStep();
      },
      onNext: () => {
        goNextStep();
      },
      onReject: () => onRejectRequest(),
      onSave: () => saveDraftLoan(),
      allowBack: true,
      allowNext: true,
      allowSave: !fixFlipStore.isBorrowerLoanApplication,
      allowReject: fixFlipStore.isLoanRequest || fixFlipStore.isBorrowerLoanApplication,
    }]),
    ...insertIf(marketingPromotionsStore.showPromotions, [{
      title: 'Promotions',
      icon: <LocalOffer color="primary" fontSize="large" />,
      type: LoanSubmissionStep.PROMOTIONS,
      onBack: () => {
        goPrevStep();
      },
      onNext: () => {
        goNextStep();
      },
      onReject: () => onRejectRequest(),
      onSave: () => saveDraftLoan(),
      allowBack: true,
      allowNext: true,
      allowSave: !fixFlipStore.isBorrowerLoanApplication,
      allowReject: fixFlipStore.isLoanRequest || fixFlipStore.isBorrowerLoanApplication,
    }]),
    {
      title: 'Summary',
      icon: <AssignmentTurnedIn color="primary" fontSize="large" />,
      type: LoanSubmissionStep.SUMMARY,
      onBack: () => goPrevStep(),
      onNext: () => userStore?.allowLoanPricing ? onSubmit() : onLoanApplicationSave(),
      onSave: () => saveDraftLoan(),
      onReject: () => onRejectRequest(),
      allowBack: true,
      allowNext: true,
      allowSave: !fixFlipStore.isBorrowerLoanApplication,
      allowReject: fixFlipStore.isLoanRequest || fixFlipStore.isBorrowerLoanApplication,
      disableNextButton: fixFlipStore.disableSubmitButton,
    },
  ];

  const { loanInformationStore } = fixFlipStore;

  return (
    <Layout title="Submit Ground-Up Loan">
      <Paper className={classes.paper}>
        {fixFlipStore.saved ? (
          <>
            <LoanSuccess
              loanId={fixFlipStore.savedLoanId}
              hasFastTrack={fixFlipStore.hasFastTrack}
              isFastTrackPaymentComplete={fixFlipStore.isFastTrackPaymentComplete}
              showDocsAndNewLoanButtons
              onStartAnotherLoan={() => {
                fixFlipStore.reset();
                push(loanSubmissionRoutesConfig.submitloan.children.groundUp.url);
              }}
              fastTrackStore={loanInformationStore}
              sendPaymentEmail={() => fixFlipStore.sendFastTrackPayment()}
              propertiesQuantity={fixFlipStore.properties.length}
              fastTrackEnabled={globalStore.userFeatures.fastTrack}
              areBorrowersPreQualified={fixFlipStore.areBorrowersPreQualified}
            />
          </>
        ) : fixFlipStore.requestSaved ? (
          <LoanRequestSuccess
            loanRequestId={fixFlipStore?.savedLoanId ?? fixFlipStore?.draftLoanInfo?.draftLoanId}
            onStartAnotherLoan={() => {
              fixFlipStore.reset();
              push(loanSubmissionRoutesConfig.submitloan.children.groundUp.url);
            }}
          />
        ) : (
          <>
            <StepsContainer
              disableNavigation={false}
              activeStep={fixFlipStore.activeStep}
              handleStep={moveToStep}
              steps={loanSubmissionSteps}
            >
              {[
                ...insertIf(showBrokerInformation, [<BrokerInformation />]),
                <EntityInformation store={fixFlipStore} />,
                <BorrowerInformation store={fixFlipStore} />,
                <PropertyInformation
                  store={fixFlipStore}
                  renderSteps={(modalState, handleAdd, handleEdit, store) => (
                    <PropertySteps
                      modalState={modalState}
                      handleAdd={handleAdd}
                      handleEdit={handleEdit}
                      store={store}
                    />
                  )}
                />,
                <LoanInformation store={fixFlipStore} />,
                ...insertIf(showDocuments, [<LoanDocuments store={fixFlipStore} saveDraftLoan={() => saveDraftLoan(false)} />]),
                ...insertIf(marketingPromotionsStore.showPromotions, [<SelectPromotionGrid store={marketingPromotionsStore.marketingPromotionsGridStore} promotionData={{ setPromotion: setPromotion, promotionId: fixFlipStore.loanInformationStore.getValue('marketingPromotionId') }} />]),
                <Summary />
              ]}
            </StepsContainer>
          </>
        )}
      </Paper>
      <StandardDialog
        open={openRejectReason}
        title="Reject Request Reason"
        maxWidth="sm"
        handleClose={() => setOpenRejectReason(false)}
        dialogContent={
          <RejectRequestForm onConfirm={() => fixFlipStore.isBorrowerLoanApplication ? rejectLoanBorrowerApplicationRequest() : rejectRequest()} onClose={() => setOpenRejectReason(false)} draftLoan={fixFlipStore.draftLoanInfo} />
        }
      />
    </Layout>
  );
});
