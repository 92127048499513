import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Skeleton } from '@material-ui/lab';
import { useBaseStore } from '@roc/feature-app-core';
import { Loan } from '@roc/feature-types';
import {
  PropertyMap, StatusChip,
  StatusType
} from '@roc/ui';
import { formatCurrency } from '@roc/ui/utils';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { useLoanRoutes } from '@roc/feature-loans-routes-config';
import { useLoanStore } from '../hooks/useLoanStore';
const useStyles = makeStyles(theme => ({
  grid: {
    width: '100%',
    height: 700,
  },
  gridCardsContainer: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  media: {
    height: 200,
    backgroundColor: '#ddd',
  },
  cardContent: {
    minHeight: 200,
  },
  actionArea: {
    '&:hover': {
      backgroundColor: lighten(theme.palette.primary.main, 0.8),
    },
  },
}));

const MyLoansCard = observer(
  ({ loan, loading }: { loan: Loan; loading: boolean }) => {
    const classes = useStyles();
    const history = useHistory();
    const { loanStore } = useLoanStore();
    const property = loan.properties?.length ? loan.properties[0] : undefined;
    const { loanRoutesConfig } = useLoanRoutes();

    return (
      <Card variant="outlined">
        {loading ? (
          <Skeleton animation="wave" variant="rect" className={classes.media} />
        ) : (
          <CardMedia className={classes.media} title="">
            {property ? (
              <PropertyMap
                latitude={property.latitude}
                longitude={property.longitude}
                address={property.address}
              />
            ) : (
              <span />
            )}
          </CardMedia>
        )}
        <CardActionArea
          classes={{ root: classes.actionArea }}
          onClick={() =>
            !loading &&
            loanStore.isLoanSubtypeSupported(loan.loanSubType) &&
            history.push(
              loanRoutesConfig.loans(loan.loanId).children.dashboard.url
            )
          }
        >
          <CardContent className={classes.cardContent}>
            <Typography align="center" gutterBottom variant="h6" noWrap={true}>
              {loading ? <Skeleton /> : loan.dealName}
            </Typography>
            <br></br>
            <Grid container>
              <Grid item xs>
                <Typography
                  align="center"
                  variant="body2"
                  color="textSecondary"
                  component="p"
                >
                  {loading ? <Skeleton /> : 'Loan Amount'}
                </Typography>
                <Typography
                  align="center"
                  variant="body1"
                  color="primary"
                  component="p"
                  style={{ fontSize: '1.5em' }}
                >
                  {loading ? (
                    <Skeleton />
                  ) : (
                    <strong>{formatCurrency(loan.amount)}</strong>
                  )}
                </Typography>
              </Grid>
              <Grid item xs>
                <Typography
                  align="center"
                  variant="body2"
                  color="textSecondary"
                  component="p"
                >
                  {loading ? <Skeleton /> : 'Status'}
                </Typography>
                <Typography align="center" variant="body1" component="div">
                  {loading ? (
                    <Skeleton />
                  ) : (
                    <StatusChip
                      statusType={StatusType.LOAN}
                      label={loan.status}
                    />
                  )}
                </Typography>
              </Grid>
            </Grid>
            <br></br>
            <Grid container>
              <Grid item xs>
                <Typography
                  align="center"
                  variant="body2"
                  color="textSecondary"
                  component="p"
                >
                  {loading ? <Skeleton /> : 'Loan Type'}
                </Typography>
                <Typography align="center" variant="body1" component="p">
                  {loading ? <Skeleton /> : loan.loanType}
                </Typography>
              </Grid>
              <Grid item xs>
                <Typography
                  align="center"
                  variant="body2"
                  color="textSecondary"
                  component="p"
                >
                  {loading ? <Skeleton /> : 'Submission Date'}
                </Typography>
                <Typography align="center" variant="body1" component="p">
                  {loading ? <Skeleton /> : loan.submissionDate}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </CardActionArea>
      </Card>
    );
  }
);

export const MyLoansCards = observer(({ isForBackUpLoans = false}) => {
  const classes = useStyles();
  const { loanStore } = useLoanStore();
  const { globalStore } = useBaseStore();

  return (
    <Grid container spacing={4} className={classes.gridCardsContainer}>
      {isForBackUpLoans ? loanStore.myBackUpLoansGridStore.gridData.data.rows : loanStore.myLoansGridStore.gridData.data.rows.map(loan => {
        return (
          <Grid key={loan.loanId} item xs={12} md={6} lg={4} xl={3}>
            <MyLoansCard
              loan={loan}
              loading={globalStore.loadingRequestsCount > 0}
            />
          </Grid>
        );
      })}
    </Grid>
  );
});
