import {
  Box,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Business, Event, Warning, CheckCircle } from '@material-ui/icons';
import { useLoanStore } from '@roc/feature-loans-shared';
import { Paper, BooleanChip, Button } from '@roc/ui';
import { formatCurrency } from '@roc/ui/utils';
import { useState } from 'react';
import { LoanPayoffDetailstDialog } from './loanPayoffDetailsDialog';
import { usePayoffRequestStore } from '../hooks/usePayoffRequestStore';
import { insertIf } from '@roc/feature-utils';
import { APPROVED_STAGE } from '../constants/payoffs';
import { PayoffActionsButton } from './payoffActionsButton';

const useStyles = makeStyles(theme => ({
  container: {
    padding: 32,
  },
  paperTitle: {
    padding: 16,
  },
}));

const listItem = (label, value, divider: boolean = true) => {
  return (
    <>
      {divider && <Divider variant="middle" component="li" />}
      <ListItem>
        <ListItemText>
          <Box display="flex">
            <Box flexGrow={1}>{label}</Box>
            <span />
            <Box>{value}</Box>
          </Box>
        </ListItemText>
      </ListItem>
    </>
  );
};

function convertTZ(date, tzString) {
  return new Date(
    (typeof date === 'string' ? new Date(date) : date).toLocaleString('en-US', {
      timeZone: tzString,
    })
  );
}

function isLoanDelinquent(currentDate: Date, nextDueDate: Date) {
  const dueDatePlus1Month = new Date(nextDueDate);
  dueDatePlus1Month.setMonth(nextDueDate.getMonth() + 1);

  if (currentDate <= dueDatePlus1Month) {
    return null;
  } else if (currentDate > dueDatePlus1Month) {
    return 'Yes';
  }
}

interface PayoffRequestLoanInformationProps { }

const PayoffLoanInformation = () => {
  const { loanStore } = useLoanStore();
  const {
    payoffRequestStore: { currentPayoff, globalStore },
  } = usePayoffRequestStore();
  const { payoffRequestStore } = usePayoffRequestStore();
  const classes = useStyles();
  const nextDueDate = loanStore?.loanDetails?.calculatedLoanData.nextDueDate
    ? new Date(loanStore?.loanDetails?.calculatedLoanData.nextDueDate)
    : new Date();
  const currentNyDate = convertTZ(new Date(), 'America/New_York');
  const isDelinquent = isLoanDelinquent(currentNyDate, nextDueDate);
  const isCEMA = currentPayoff?.cema ? 'Yes' : null;
  const isServicer = globalStore?.userFeatures?.isServicer;
  const showLoanPayoffDetails = isServicer && currentPayoff !== null;
  const showActionsButtons = isServicer && currentPayoff?.loanId != null;
  const isForeclosurePayoffRequest = payoffRequestStore.isForeclosure;

  const [
    isPayoffDetailsModalOpen,
    setIsPayoffDetailsModalOpen,
  ] = useState(false);

  const closePayoffDetailsDialog = () => {
    setIsPayoffDetailsModalOpen(false);
  }

  return (
    <Grid container spacing={2} style={{
      margin: 0
    }}>
      <Grid item xs={12} lg={6}>
        <Paper title="LOAN KEY INFORMATION">
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <Grid container direction="column">
                <List>
                  {
                    listItem(
                      'Servicer',
                      loanStore?.loanDetails.servicer || 'N/A',
                      false
                    ) // TODO: REMOVE HARDCODE
                  }
                  {listItem('Current Payoff Status', currentPayoff?.payoffStatus || 'N/A')}
                  {listItem('Good Through Date', currentPayoff?.goodThroughDate || 'N/A')}
                  {
                    listItem(
                      'Maturity Date',
                      loanStore?.loanDetails.maturityDate || 'N/A'
                    ) // TODO: REMOVE HARDCODE
                  }
                  {
                    listItem(
                      'Is delinquent',
                      <BooleanChip value={isDelinquent} />
                    ) // TODO: REMOVE HARDCODE
                  }
                  {
                    listItem(
                      'Is CEMA',
                      <BooleanChip value={isCEMA} />
                    )
                  }
                </List>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      {[
        ...insertIf(showLoanPayoffDetails, [
          <Grid item xs={12} lg={6}>
            <Paper >
              <Grid container >
                <Grid item xs={12} md={12}>
                  <Grid container justifyContent="space-between" className={classes.paperTitle}>
                    <Grid item>
                      <Typography variant="h5">LOAN PAYOFF DETAILS</Typography>
                    </Grid>
                    <Grid item>
                      {[...insertIf(currentPayoff?.loanPayoffDataId !== null, [
                        <Box display="flex" justifyContent="flex-end">
                          <Button
                            testId="createPayoffRequest"
                            variant="contained"
                            size="small"
                            color="primary"
                            onClick={() => setIsPayoffDetailsModalOpen(true)}
                          >
                            EDIT
                          </Button>
                        </Box>
                      ])
                      ]}

                    </Grid>
                  </Grid>
                  <Grid container direction="column">
                    <List>
                      {
                        listItem(
                          isForeclosurePayoffRequest ? 'Settlement Date' : 'Payoff Date',
                          currentPayoff?.expectedPayoffDate || 'N/A',
                          false
                        )
                      }
                      {
                        listItem(
                          isForeclosurePayoffRequest ? 'Settlement Amount Received' : 'Total Interest Amount Received',
                          currentPayoff?.totalInterestReceived ? formatCurrency(currentPayoff?.totalInterestReceived) : 'N/A'
                        )
                      }
                      {!payoffRequestStore.isForeclosure && (listItem(
                        'Total Proceeds',
                        currentPayoff?.totalProceeds ? formatCurrency(currentPayoff?.totalProceeds) : 'N/A'
                      ))
                      }
                      {!payoffRequestStore.isForeclosure && (listItem(
                        'Refund To Borrower',
                        currentPayoff?.refundToBorrower ? formatCurrency(currentPayoff?.refundToBorrower) : 'N/A'
                      ))
                      }
                    </List>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        ]),
      ]}
      {!!showActionsButtons && <PayoffActionsButton version='v1' />}


      <LoanPayoffDetailstDialog loanId={loanStore?.loanDetails?.loanId} open={isPayoffDetailsModalOpen} onClose={closePayoffDetailsDialog} />
    </Grid>
  );
};

export const PayoffLoanInformationNew = (props: PayoffRequestLoanInformationProps) => {
  const { loanStore } = useLoanStore();
  const classes = useStyles();
  const nextDueDate = loanStore?.loanDetails?.calculatedLoanData.nextDueDate
    ? new Date(loanStore?.loanDetails?.calculatedLoanData.nextDueDate)
    : new Date();
  const currentNyDate = convertTZ(new Date(), 'America/New_York');
  const isDelinquent = isLoanDelinquent(currentNyDate, nextDueDate);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={5}>
        <Paper style={{
          background: 'linear-gradient(135deg, rgba(118, 176, 241, 0.2), rgba(32, 101, 209, 0.2)) rgb(255, 255, 255)',
          boxShadow: 'none',
          height: '100%',
          display: 'flex',
          alignItems: 'center'
        }}>
          <Box p={4} width={'100%'} textAlign={'center'}>
            <Box mb={.5}>
              <Business />
            </Box>
            <Typography variant='h4' gutterBottom>
              {loanStore?.loanDetails?.servicer ?? '-'}
            </Typography>
            <Typography variant='body2'>
              Servicer
            </Typography>
          </Box>
        </Paper>
      </Grid>
      <Grid item xs={12} md={4}>
        <Paper style={{
          background: 'linear-gradient(135deg, rgba(97, 243, 243, 0.2), rgba(0, 184, 217, 0.2)) rgb(255, 255, 255)',
          boxShadow: 'none',
          height: '100%',
          display: 'flex',
          alignItems: 'center'
        }}>
          <Box p={4} width={'100%'} textAlign={'center'}>
            <Box mb={.5}>
              <Event />
            </Box>
            <Typography variant='h4' gutterBottom>
              {loanStore?.loanDetails.maturityDate ?? '-'}
            </Typography>
            <Typography variant='body2'>
              Maturity Date
            </Typography>
          </Box>
        </Paper>
      </Grid>
      <Grid item xs={12} md={3}>
        <Paper style={{
          background: 'linear-gradient(135deg, rgba(255, 214, 102, 0.2), rgba(255, 171, 0, 0.2)) rgb(255, 255, 255)',
          boxShadow: 'none',
          height: '100%',
          display: 'flex',
          alignItems: 'center'
        }}>
          <Box p={4} width={'100%'} textAlign={'center'}>
            <Box mb={.5}>
              {isDelinquent ? <Warning style={{
                color: 'red'
              }} /> : <CheckCircle style={{
                color: 'green'
              }} />}
            </Box>
            <Typography variant='h4' gutterBottom>
              {isDelinquent ? 'No' : 'Yes'}
            </Typography>
            <Typography variant='body2'>
              Is Current
            </Typography>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default PayoffLoanInformation;
