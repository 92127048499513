import { ApiResponse, UserStore } from '@roc/feature-app-core';
import { LenderFees, } from '../types/companyProfileTypes';
import { BaseLenderStore } from './baseLenderStore';
import { GENERIC_ERROR_MESSAGE, isNotBlank } from '@roc/feature-utils';
import { flow, makeObservable } from 'mobx';

export class ExternalLenderStore extends BaseLenderStore {
  userStore: UserStore;

  constructor(globalStore, userStore) {
    super(globalStore);
    this.userStore = userStore;
    makeObservable(this, {
      getRelationshipManagersForProcessor: flow
    });
  }

  *fetchSalesforceLender(shouldUpdate = false) {
    if (!this.salesForceLender || shouldUpdate) {
      const response: ApiResponse = yield this.companyProfileService.getMyLender();
      this.salesForceLender = response.data.data;
    }
    return this.salesForceLender;
  }

  *fetchLenderById(id) {
    const response: ApiResponse = yield this.companyProfileService.getLenderById(id);
    this.salesForceLender = response.data.data;
    return this.salesForceLender;
  }

  *updateCommissions(lenderId) {
    const response: ApiResponse = yield this.companyProfileService.updateCommissions(lenderId);
    return response;
  }



  *updateLenderFields(fields) {
    yield this.companyProfileService.updateLender(fields);
    this.salesForceLender = fields;
  }


  * updateSalesforceLenderFees(fees: LenderFees) {
    const newLender = {
      ...this.salesForceLender,
      defaultTermPoints: fees.defaultTermPoints,
    };

    newLender.servicingSetupFee = fees.servicingSetupFees;
    newLender.adminFee = fees.adminFees;
    newLender.servicingFee = fees.servicingFees;
    newLender.wireFee = fees.wireFees;
    newLender.valuationReviewFee = fees.valuationReviewFees;
    newLender.commitmentFee = fees.commitmentFees;
    newLender.legalReviewFee = fees.legalReviewFees;
    newLender.appraisal = fees.appraisal;
    newLender.processingFee = fees.processingFees;
    newLender.custodianFee = fees.custodianFees;
    newLender.underwritingFee = fees.underWritingFees;
    newLender.constructionDrawFee = fees.constructionDrawFees;

    yield this.companyProfileService.updateLender(newLender);
    this.salesForceLender = newLender;
  }


  async getAllLenders() {
    try {
      const response: ApiResponse = await this.companyProfileService.getAllLenders(
        this.gridStore.gridData.meta.pageNumber,
        this.gridStore.gridData.meta.pageSize,
        this.gridStore.gridData.meta.sortBy,
        this.gridStore.gridData.meta.sortDir,
        this.gridStore.gridData.meta.filters,
        this.gridStore.gridData.meta.dropdownFilters,
      );
      return response;
    }
    catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'An error occurred while fetching list of lenders'
      });
    }
  }

  async getAllLendersWithNoRm() {
    try {
      const response: ApiResponse = await this.companyProfileService.getAllLendersWithNoRM(
        this.lenderWithNoRmGrid.gridData.meta.pageNumber,
        this.lenderWithNoRmGrid.gridData.meta.pageSize,
        this.lenderWithNoRmGrid.gridData.meta.sortBy,
        this.lenderWithNoRmGrid.gridData.meta.sortDir,
        this.lenderWithNoRmGrid.gridData.meta.filters,
        this.lenderWithNoRmGrid.gridData.meta.dropdownFilters,
      );
      return response;
    }
    catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'An error occurred while fetching list of lenders with no Rm'
      });
    }
  }

  *getRelationshipManagersUsers() {
    try {
      const response: ApiResponse = yield this.companyProfileService.getAllRelationshipManagersUsers();
      const mappedUsers = response.data.data.map(user => ({
        label: user.contact.fullName,
        value: user.userName
      }));
      this.relationshipManagers = mappedUsers;
    } catch (error) {
      console.error("Error while loading the users: ", error);
    }
  }

  *getRelationshipManagersForProcessor() {
    try {
      const response: ApiResponse = yield this.companyProfileService.getAllRelationshipManagersForProcessor();
      const mappedUsers = response.data.data.map(user => ({
        label: user.contact.fullName,
        value: user.userName
      }));
      this.relationshipManagers = mappedUsers;
    } catch (error) {
      console.error("Error while loading the users: ", error);
    }
  }

  async getBankInformationsForLender() {
    try {
      const companyId = this.userStore.userInformation.companyId ?? this.globalStore.selectedCompanyId;
      const response = await this.companyProfileService.getLenderBankInformationForLender(
        companyId
      );
      return response?.data?.data;
    } catch (error) {
      console.log(error)
      this.globalStore.notificationStore.showErrorNotification({
        message: 'There was an error trying to load the bank info data',
      });
    }
  }

  async checkIsInternalDecisionCommitteeOrLead() {
    try {
      const response: ApiResponse = await this.companyProfileService.getIfIsInternalDecisionCommitteeOrLead();
      return response.data.data;
    } catch (error) {
      console.error("Error while checking for role: ", error);
    }
  }

  async requiredDocsUploaded(lenderId) {
    try {
      const response: ApiResponse = await this.companyProfileService.checkRequiredDocsUploadedForLender(lenderId);
      const { entityDocumentsNotUploaded, userDocumentsNotUploaded } = response.data;

      let errorMessage = '';
      let ableToUpdate = true;

      if (entityDocumentsNotUploaded.length > 0) {
        errorMessage += `Lender Status cannot be moved due to missing documents:\n\n${this.formatDocumentList(entityDocumentsNotUploaded)}`;
        ableToUpdate = false;
      }

      if (userDocumentsNotUploaded.length > 0) {
        errorMessage += isNotBlank(errorMessage)
          ? `\n\nUsers Missing Documents:\n${this.formatUserDocuments(userDocumentsNotUploaded)}`
          : `Users Missing Documents:\n${this.formatUserDocuments(userDocumentsNotUploaded)}`;
        ableToUpdate = false;
      }

      return {
        errorMessage,
        ableToUpdate
      };

    } catch (error) {
      console.error("Error while checking for documents: ", error);
    }
  }

  *fetchLoanVolumeStats(id: number, type: string) {
    try {
      const response = yield this.companyProfileService.getLoanVolume(id, type);
      this.loanVolumeStats = response.data.data;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE
      })
    }
  }

  *updateTamariskPreferences(preferences): Generator<any, boolean, any> {
    try {
      const response = yield this.companyProfileService.updateTamariskPreferences(preferences);
      if (response.data.status === 'OK') {
        this.globalStore.notificationStore.showSuccessNotification({
          message: 'Tamarisk Preferences Successfully Saved'
        });
        return true;
      } else {
        return false;
      }
    } catch (ex) {
      console.log(ex);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error While Saving Tamarisk Preference'
      });
      return false;
    }
  }

  *updateDesktopAppraisalPreferences(desktopAppraisalPreference: string, lenderId: number) {
    try {
      yield this.companyProfileService.updateDesktopAppraisalPreferences(desktopAppraisalPreference, lenderId);
    } catch (error) {

    }
  }
}
