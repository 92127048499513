import React, { useEffect } from 'react';
import WebFont from 'webfontloader';
import { useAppConfigurations } from './useAppConfigurations';

export const useFontFamilies = (additionalFontFamilies = []) => {
  const { fontFamilies } = useAppConfigurations();
  useEffect(() => {
    const fontsToLoad = fontFamilies?.length ? [...fontFamilies, ...additionalFontFamilies] : additionalFontFamilies;
    if (fontsToLoad?.length > 0) {
      fontsToLoad.forEach(x => {
        WebFont.load({
          google: {
            families: [x + "&display=swap"],
          },
          active: () => {
            sessionStorage.fontsLoaded = true
          },
        });
      })
    }
  }, []);
};
