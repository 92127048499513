import { FormStore } from "@roc/feature-app-core";
import { BaseBorrowerInformationStore } from "../baseBorrowerInformationStore";

const borrowerForm = {
  fields: {
    firstName: {
      value: '',
      error: null,
      rule: 'required',
      attribute: 'first name',
    },
    lastName: {
      value: '',
      error: null,
      rule: 'required',
      attribute: 'last name',
    },
    emailAddress: {
      value: '',
      error: null,
      rule: 'required|email',
      attribute: 'email address',
    },
    confirmEmailAddress: {
      value: '',
      error: null,
      rule: 'required|same:emailAddress',
      message: 'The email address does not match.',
    },
    cellPhone: {
      value: '',
      error: null,
      rule: 'required|regex:/^\\d{3}-?\\d{3}-?\\d{4}$/|alpha_dash',
      message: 'Phone must be a number with the format xxx-xxx-xxxx.',
    },
    pctOwnership: {
      value: '',
      error: null,
      rule: '',
    },
    experienceProvidedAtOrigination: {
      value: '',
      error: null,
      rule: '',
    },
    ficoProvidedAtOrigination: {
      value: '',
      error: null,
      rule: '',
    },
    borrowerId: {
      value: '',
      error: null,
      rule: '',
    }
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export class BridgeBorrowerInformationStore extends BaseBorrowerInformationStore {
  createBorrowerForm(): FormStore {
    return new FormStore(borrowerForm, this.globalStore);
  }
}