import { Route } from 'react-router';
import { Page } from '@roc/client-portal-shared/components';
import { InternalPricer } from './../../internalPricer';
import { useStore } from '@roc/client-portal-shared/hooks';

export const getClientInternalPricerRoutesConfig = (
  basePath: string,
  baseUrl: string
) => {
  return {
    internalPricer: {
      path: `${basePath}/internal-pricer`,
      url: `${baseUrl}/internal-pricer`,
      documentTitle: 'Rental Loan Pricer',
    },
  };
};

export const getClientInternalPricerRoutes = (
  basePath: string,
  baseUrl: string
) => {
  const config = getClientInternalPricerRoutesConfig(basePath, baseUrl);
  const { clientInternalPricerStore } = useStore();
  return {
    internalPricer: (
      <Route exact path={config.internalPricer.path}>
        <Page routeDefinition={config.internalPricer}>
          <InternalPricer internalPricerStore={clientInternalPricerStore} />
        </Page>
      </Route>
    ),
  };
};
