import { Box, makeStyles } from '@material-ui/core';
import { useBaseStore } from '@roc/feature-app-core';
import { getPdfBlobUrl, isNil } from '@roc/feature-utils';
import axios from 'axios';
import { NotFound, IconButton, PDFPreview } from '@roc/ui';
import { Menu as MenuIcon } from '@material-ui/icons';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDocumentLibraryContentfulStore } from '../hooks/useDocumentLibraryContentfulStore';
import { SfrTearSheetHtml } from '../htmlTemplates/sfrTearSheet/sfrTearSheetHtml';
import { HtmlDocumentMenu } from './htmlDocumentMenu';
import { HtmlDocumentPdfViewModal } from './htmlDocumentPdfViewModal';
import { RentalPortfolioTearSheetHtml } from '../htmlTemplates/rentalPortfolioTearSheet/rentalPortfolioTearSheetHtml';
import { MFBridgeTearSheetHtml } from '../htmlTemplates/mfBridgeTearSheet/mfBridgeTearSheetHtml';
import { DocumentLibraryStore } from '../store/documentLibraryStore';
import { MultifamilyTearSheetHtml } from '../htmlTemplates/multifamilyTearSheet/multifamilyTearSheetHtml';
import { AttorneyDirectoryHtml } from '../htmlTemplates/attorneyDirectory/attorneyDirectoryHtml';
import { FNFAndGUCTearSheetHtml } from '../htmlTemplates/fnfAndGucTearSheet/fnfAndGucTearSheet';
import { StabilizedBridgeTearSheetHtml } from '../htmlTemplates/stabilizedBridgeProgram/stabilizedBridgeProgram';
import { AppraisalGuidelinesHtml } from '../htmlTemplates/appraisalGuidelines/appraisalGuidelines';
import { InsuranceGuidelinesHtml } from '../htmlTemplates/insuranceGuidelines/insuranceGuidelines';
import { BrochureHtml } from '../htmlTemplates/brochure/brochure';
import { MFBridgeFAQSHtml } from '../htmlTemplates/mfBridgeFaqs/mfBridgeFaqs';
import { TermLoansInsuranceGuidelinesHtml } from '../htmlTemplates/termLoansInsuranceGuidelines/termLoansInsuranceGuidelines';
import { TermLoansAppraisalGuidelinesHtml } from '../htmlTemplates/termLoansAppraisalGuidelines/termLoansAppraisalGuidelines';
import { RentalLoansUWAndClosingChecklistHtml } from '../htmlTemplates/rentalLoansUWAndClosingChecklist/rentalLoansUWAndClosingChecklist';
import { ScopeOfWorkRevisionHtml } from '../htmlTemplates/scopeOfWorkRevision/scopeOfWorkRevision';
import { ScopeOfWorkAndDrawsTeachingVideosHtml } from '../htmlTemplates/scopeOfWorkAndDrawsTeachingVideos/scopeOfWorkAndDrawsTeachingVideos';
import { InitialScopeOfWorkHtml } from '../htmlTemplates/initialScopeOfWork/initialScopeOfWork';
import { TutorialVideosHtml } from '../htmlTemplates/tutorialVideos/tutorialVideos';
import { GroundUpConstructionProgram } from '../htmlTemplates/groundUpConstructionProgram/groundUpConstructionProgram';
import { AppraisalTransferPolicyHtml } from '../htmlTemplates/appraisalGuidelines/appraisalTransferPolicy';

import { FixAndFlipProgram } from '../htmlTemplates/fixAndFlipDocuments/fixAndFlipProgram'
import { FixAndFlipProductBox } from '../htmlTemplates/fixAndFlipDocuments/fixAndFlipProductBox'
import { CanvaTemplateHtml } from '../htmlTemplates/canvaTemplates/canvaTemplatesHtml';
import { ServicerInformationHtml } from '../htmlTemplates/servicerInformation/servicerInformation';
import { StabilizedProductBox } from '../htmlTemplates/stabilizedDocuments/stabilizedProductBox';
import { GroundUpProductBox } from '../htmlTemplates/groundUpDocuments/groundUpProductBox';

const usePrintStyles = makeStyles(() => ({
  print: {
    width: '210mm',
    minHeight: '297mm',
    height: 'auto',
    ['& [data-testid="documentMenuButton"]']: {
      display: 'none !important',
    },
  },
}));

export const HtmlDocument = observer(({
  contentId,
  preview,
  documentLibraryStoreNew
}: {
  contentId?: string,
  preview?: boolean,
  documentLibraryStoreNew: DocumentLibraryStore
}) => {

  const { contentId: contentIdFromUrl } = useParams<{ contentId: string }>();
  contentId = contentId ? contentId : contentIdFromUrl;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [initialized, setInitialized] = useState(false);
  const [openDownloanPrintModal, setOpenDownloanPrintModal] = useState(false);
  const [pdfLoading, setPdfLoading] = useState(false);
  const [pdfData, setPdfData] = useState<ArrayBuffer | null>(null);
  const [iframeSrc, setIframeSrc] = useState<URL | string>();
  const [data, setData] = useState<any>();
  const [containerRef, setContainerRef] = useState<any>();
  const [displayMenu, setDisplayMenu] = useState<boolean>(true);

  const { documentLibraryContentfulStore } = useDocumentLibraryContentfulStore();
  const { globalStore } = useBaseStore();

  const printClasses = usePrintStyles();

  useEffect(() => {
    const fetchData = async () => {
      const client = preview ? documentLibraryContentfulStore.getPreviewClient() : documentLibraryContentfulStore.getClient();
      const responses = await Promise.all([client.getEntry(contentId), documentLibraryStoreNew.loadData(preview)]);
      setData(responses[0].fields);
      documentLibraryStoreNew.setCurrentDataByContentId(contentId);
      setInitialized(true);
    };
    fetchData();

    return () => {
      setInitialized(false);
    };
  }, []);

  useEffect(() => {
    if (openDownloanPrintModal) {
      getPdfBlobUrl(containerRef.current, {
        scale: isBigCanvas() ? 1.5 : 4,
        onclone: (doc, el) => el.className += ' ' + printClasses.print,
        useCORS: true
      }).then((url) => {
        axios.get(url.toString(), { responseType: 'arraybuffer' })
          .then(response => {
            setPdfData(response.data);
            setPdfLoading(false);
          })
          .catch(error => {
            console.error('Error fetching PDF data:', error);
          });
      });
    }
  }, [openDownloanPrintModal]);

  useEffect(() => {
    if (documentLibraryStoreNew.currentData?.template === 'TUTORIAL_VIDEOS' ||
      documentLibraryStoreNew.currentData?.template === 'CANVA_TEMPLATE'
    )
      setDisplayMenu(false);
  }, []);

  if (!initialized) {
    return null;
  }

  if (!documentLibraryStoreNew.currentData) {
    return <NotFound />;
  }

  const onDownloadPrintPdf = () => {
    setOpenDownloanPrintModal(true);
    setPdfLoading(true);
  };

  const isBigCanvas = () => {
    return documentLibraryStoreNew.currentData?.template === "FIX_AND_FLIP_PRODUCT_BOX"
  }

  const template = (() => {
    switch (documentLibraryStoreNew.currentData?.template) {
      case "SFR_TEAR_SHEET":
        return <SfrTearSheetHtml
          contentId={contentId}
          data={data}
          preview={preview}
          printMode={openDownloanPrintModal}
          setContainerRef={setContainerRef} />
      case "RENTAL_PORTFOLIO_TEAR_SHEET":
        return <RentalPortfolioTearSheetHtml
          contentId={contentId}
          data={data}
          preview={preview}
          printMode={openDownloanPrintModal}
          setContainerRef={setContainerRef} />
      case "MULTIFAMILY_TERM_TEAR_SHEET":
        return <MultifamilyTearSheetHtml
          contentId={contentId}
          data={data}
          preview={preview}
          printMode={openDownloanPrintModal}
          setContainerRef={setContainerRef} />
      case "MF_BRIDGE_TEAR_SHEET":
        return <MFBridgeTearSheetHtml
          contentId={contentId}
          data={data}
          preview={preview}
          printMode={openDownloanPrintModal}
          setContainerRef={setContainerRef} />
      case "ATTORNEY_DIRECTORY":
        return <AttorneyDirectoryHtml
          contentId={contentId}
          data={data}
          preview={preview}
          printMode={openDownloanPrintModal}
          setContainerRef={setContainerRef} />
      case "FNF_GUC_TEAR_SHEET":
        return <FNFAndGUCTearSheetHtml
          contentId={contentId}
          data={data}
          preview={preview}
          printMode={openDownloanPrintModal}
          setContainerRef={setContainerRef} />
      case "STABILIZED_BRIDGE_TEAR_SHEET":
        return <StabilizedBridgeTearSheetHtml
          contentId={contentId}
          data={data}
          preview={preview}
          printMode={openDownloanPrintModal}
          setContainerRef={setContainerRef} />
      case "APPRAISAL_GUIDELINES":
        return <AppraisalGuidelinesHtml
          contentId={contentId}
          data={data}
          preview={preview}
          printMode={openDownloanPrintModal}
          setContainerRef={setContainerRef} />
      case "INSURANCEL_GUIDELINES":
        return <InsuranceGuidelinesHtml
          contentId={contentId}
          data={data}
          preview={preview}
          printMode={openDownloanPrintModal}
          setContainerRef={setContainerRef} />
      case "BROCHURE":
        return <BrochureHtml
          contentId={contentId}
          data={data}
          preview={preview}
          printMode={openDownloanPrintModal}
          setContainerRef={setContainerRef} />
      case "MF_BRIDGE_FAQS":
        return <MFBridgeFAQSHtml
          contentId={contentId}
          data={data}
          preview={preview}
          printMode={openDownloanPrintModal}
          setContainerRef={setContainerRef} />
      case "TERM_LOANS_INSURANCE_GUIDELINES":
        return <TermLoansInsuranceGuidelinesHtml
          contentId={contentId}
          data={data}
          preview={preview}
          printMode={openDownloanPrintModal}
          setContainerRef={setContainerRef} />
      case "TERM_LOANS_APPRAISAL_GUIDELINES":
        return <TermLoansAppraisalGuidelinesHtml
          contentId={contentId}
          data={data}
          preview={preview}
          printMode={openDownloanPrintModal}
          setContainerRef={setContainerRef} />
      case "RENTAL_LOANS_UW_CLOSING_CHECKLIST":
        return <RentalLoansUWAndClosingChecklistHtml
          contentId={contentId}
          data={data}
          preview={preview}
          printMode={openDownloanPrintModal}
          setContainerRef={setContainerRef} />
      case "SCOPE_OF_WORK_REVISION":
        return <ScopeOfWorkRevisionHtml
          contentId={contentId}
          data={data}
          preview={preview}
          printMode={openDownloanPrintModal}
          setContainerRef={setContainerRef} />
      case "SCOPE_OF_WORK_AND_DRAWS_TEACHING_VIDEOS":
        return <ScopeOfWorkAndDrawsTeachingVideosHtml
          contentId={contentId}
          data={data}
          preview={preview}
          printMode={openDownloanPrintModal}
          setContainerRef={setContainerRef}
          allowPrint={false} />
      case "INITIAL_SCOPE_OF_WORK":
        return <InitialScopeOfWorkHtml
          contentId={contentId}
          data={data}
          preview={preview}
          printMode={openDownloanPrintModal}
          setContainerRef={setContainerRef} />
      case "TUTORIAL_VIDEOS":
        return <TutorialVideosHtml
          contentId={contentId}
          data={data}
          preview={preview}
          printMode={openDownloanPrintModal}
          setContainerRef={setContainerRef}
          allowPrint={false} />
      case "GROUND_UP_CONSTRUCTION_TEAR_SHEET":
        return <GroundUpConstructionProgram
          contentId={contentId}
          data={data}
          preview={preview}
          printMode={openDownloanPrintModal}
          setContainerRef={setContainerRef} />
      case "APPRAISAL_TRANSFER_POLICY":
        return < AppraisalTransferPolicyHtml
          contentId={contentId}
          data={data}
          preview={preview}
          printMode={openDownloanPrintModal}
          setContainerRef={setContainerRef} />
      case "FIX_AND_FLIP_PRODUCT_BOX":
        return <FixAndFlipProductBox
          contentId={contentId}
          data={data}
          preview={preview}
          printMode={openDownloanPrintModal}
          setContainerRef={setContainerRef}
        />
      case "STABILIZED_BRIDGE_PRODUCT_BOX":
        return <StabilizedProductBox
          contentId={contentId}
          data={data}
          preview={preview}
          printMode={openDownloanPrintModal}
          setContainerRef={setContainerRef}
        />
      case "GROUND_UP_PRODUCT_BOX":
        return <GroundUpProductBox
          contentId={contentId}
          data={data}
          preview={preview}
          printMode={openDownloanPrintModal}
          setContainerRef={setContainerRef}
        />
      case "FIX_AND_FLIP_PROGRAM":
        return <FixAndFlipProgram
          contentId={contentId}
          data={data}
          preview={preview}
          printMode={openDownloanPrintModal}
          setContainerRef={setContainerRef}
        />
      case "CANVA_TEMPLATE":
        return <CanvaTemplateHtml
          contentId={contentId}
          data={data}
          setContainerRef={setContainerRef}
        />
      case "SERVICER_INFORMATION":
        return <ServicerInformationHtml
          contentId={contentId}
          data={data}
          preview={preview}
          printMode={openDownloanPrintModal}
          setContainerRef={setContainerRef}
        />
      default:
        globalStore.notificationStore.showErrorNotification({
          message: 'Document not found!'
        });
        console.error("HTML Template not found: " + documentLibraryStoreNew.currentData?.template, documentLibraryStoreNew.currentData);
        return null;
    }
  })();

  return (
    !template ? <NotFound /> :
      <Box m={'auto'}>
        {displayMenu ? (
          <Box m={0} pt={2} pr={2} bgcolor={'#fff'} display='flex' justifyContent={'flex-end'}>
            <IconButton testId='documentMenuButton' onClick={(e) => setAnchorEl(e.currentTarget)}>
              <MenuIcon />
            </IconButton>
            <HtmlDocumentMenu
              show={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(null)}
              contentId={contentId}
              onDownloadPrintPdf={onDownloadPrintPdf}
              allowPrint={isNil(template.props?.allowPrint) ? true : template.props?.allowPrint} />
          </Box>)
          : null
        }
        {template}
        <PDFPreview
          open={openDownloanPrintModal}
          title={documentLibraryStoreNew.currentData?.name}
          data={pdfData} // pass the ArrayBuffer
          headers={{}} // you need to determine what headers to pass
          fileName={documentLibraryStoreNew.currentData?.name + '.pdf'}
          onClose={() => {
            setOpenDownloanPrintModal(false);
          }}
        />

      </Box>
  );

});
