import { Grid, Typography } from "@material-ui/core";
import { DateFormat, formatDate, isNil } from "@roc/feature-utils";
import { createSelectField, createTextField, DialogState, EditableDialog } from "@roc/ui";
import { observer } from "mobx-react";
import { useLeadsStore } from "../../hooks/useLeadsStore";
import { createHtmlField } from './htmlField'
import { LEAD_OBJECT_TYPE, leadStatusOptions } from "../leads/constants/constants";
import { useEffect } from "react";


export const EditLeadStatusDialog = observer(({ salesforceLeadStore, leadId, handleClose, handleEdit }) => {
  const { leadsStore } = salesforceLeadStore;
  const { leadDetailsStore } = leadsStore
  const { editLeadNoteStore } = leadDetailsStore

  const formStore = salesforceLeadStore.editLeadDetailsFormStore;

  const editStatus = () => {
    salesforceLeadStore.editLeadStatus(leadId);
    handleEdit();
  };

  useEffect(() => {
    leadDetailsStore.fetchLeadDetails(leadId, LEAD_OBJECT_TYPE, salesforceLeadStore.gridBusinessUnit);
    fetchData();
  }, []);

  const fetchData = async () => {
    await salesforceLeadStore.getLeadDetails(leadId, salesforceLeadStore.gridBusinessUnit);
  };


  const dialogContent = (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12}>
        {createSelectField({
          label: 'Status',
          fieldName: 'status',
          testId: 'status',
          store: formStore,
          options: leadStatusOptions,
          placeholder: 'Choose Status',
        })}
      </Grid>
    </Grid>
  )

  return (
    <EditableDialog
      open={true}
      dialogState={DialogState.EDIT}
      handleClose={() => handleClose()}
      handleAdd={() => editStatus()}
      handleEdit={() => editStatus()}
      title={'Lead Status'}
      dialogContent={dialogContent}
    />
  )
})
