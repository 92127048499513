import { GlobalStore } from '@roc/feature-app-core';
import { action, computed, flow, makeObservable, observable } from 'mobx';
import { OneToolService } from '../../services/oneToolService';

const EMPTY_OPTION = { label: 'None', value: null };

const ALL_OPTION = { label: 'All', value: null };

export class LoanOriginatorStore {
  private globalStore: GlobalStore;
  private oneToolService: OneToolService;

  originators: any[] = [];
  processors: any[] = [];
  selectedOriginator: any;

  constructor(globalStore: GlobalStore) {
    this.globalStore = globalStore;
    this.oneToolService = new OneToolService();
    makeObservable(this, {
      originators: observable,
      processors: observable,
      selectedOriginator: observable,
      setSelectedOriginator: flow,
      setParticipants: flow,
      fetchLoanParticipants: flow,
      allowOriginatorSelection: computed,
      allowProcessorSelection: computed,
      originatorOptions: computed,
      processorOptions: computed,
      reset: action,
      fetchOriginators: flow,
      userCanSelectOriginator: computed,
      loadOriginators: flow,
    });
  }

  get allowOriginatorSelection() {
    return !this.globalStore.userFeatures?.isLenderOriginator && this.originatorOptions.length > 1;
  }

  get allowProcessorSelection() {
    return !this.globalStore.userFeatures.isProcessor;
  }

  get originatorOptions() {
    const options = this.originators
      .map(originator => ({
        label: `${originator.contact?.firstName ?? ''} ${originator.contact?.lastName ?? ''
          }`,
        value: originator.userId,
      }))
      .sort((optionA, optionB) => optionA.label.localeCompare(optionB.label));
    return [EMPTY_OPTION, ...options];
  }

  get processorOptions() {
    const options = this.processors
      .map(processor => ({
        label: `${processor.contact?.firstName ?? ''} ${processor.contact?.lastName ?? ''
          }`,
        value: processor.userId,
      }))
      .sort((optionA, optionB) => optionA.label.localeCompare(optionB.label));
    return [ALL_OPTION, ...options];
  }

  get isFacoOriginator() {
    return this.globalStore.userFeatures.allowProcessorSelection;
  }
  reset() {
    this.originators = [];
  }

  *fetchLoanParticipants() {
    if (this.originators.length === 0) {
      try {
        const response = yield this.oneToolService.getLoanParticipants();
        this.originators = response.data.data.loanOriginators;
      } catch (e) {
        this.globalStore.notificationStore.showErrorNotification({
          message: 'Error while fetching the loan participants',
        });
      }
    }
  }

  *setParticipants(loanData) {
    const { lenderOriginatorName, lenderOriginatorId } = loanData
    this.selectedOriginator = {
      fullName: lenderOriginatorName,
      originatorId: lenderOriginatorId
    }
  }

  *setSelectedOriginator(name: string, id: number) {
    this.selectedOriginator = {
      fullName: name,
      originatorId: id
    }
  }

  get userCanSelectOriginator() {
    return (
      !this.globalStore.userFeatures.isLenderOriginator &&
      this.originators.length > 0
    );
  }

  *fetchOriginators() {
    if (this.originators.length === 0) {
      try {
        const response = yield this.oneToolService.getOriginators();
        this.originators = response.data.data;
      } catch (e) {
        this.globalStore.notificationStore.showErrorNotification({
          message: 'Error while fetching the loan originators',
        });
      }
    }
  }

  *loadOriginators() {
    if (this.isFacoOriginator) {
        this.fetchOriginators();
      } else {
        this.fetchLoanParticipants();
      }
  }
}





