import { Box } from '@material-ui/core';
import { StepperComponent } from '@roc/client-portal-shared/components';
import { observer } from 'mobx-react';
import { useNewLeadStore } from '../../hooks/useNewLeadStore';
import { PossibleMatches } from './possibleMatches';
import { Submit } from './submit';
import { UserDetails } from './userDetails';
import { useEffect } from 'react';

export const NewLeadComponent = observer(() => {
  const { newLeadStore } = useNewLeadStore();

  useEffect(() => {
    newLeadStore.setGlobalSearch(false);
  }, []);

  return (
    <>
      <Stepper />
      <Box p={4}>
        {(newLeadStore.activeStep === 0) ? (
          <PossibleMatches />
        ) : newLeadStore.activeStep === 1 ? (
          <UserDetails />
        ) : (
          <Submit />
        )}
      </Box>
    </>
  );
});

const Stepper = () => {
  const { newLeadStore } = useNewLeadStore();
  const stepNumber = {
    0: 0,
    1: 1,
    2: 2,
  }[newLeadStore.activeStep];

  const steps = [
    {
      title: 'Search',
      icon: null,
      completed: stepNumber > 0,
    },
    {
      title: 'Enter Information',
      icon: null,
      completed: stepNumber > 1,
    },
    {
      title: 'Review',
      icon: null,
      completed: stepNumber > 2,
    },
  ];

  return (
    <StepperComponent
      activeStep={stepNumber}
      disableNavigation={true}
      steps={steps}
    />
  );
};
