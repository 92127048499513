import { useBaseStore } from '@roc/feature-app-core';
import { getBorrowerLiteLoanRoutesConfig } from '../routes/borrowerLiteRoutesConfig';

export const useBorrowerLiteLoanRoutes = () => {
  const { globalStore } = useBaseStore();
  const { routeBasePath, routeBaseUrl } = globalStore;

  return {
    borrowerLiteLoanRoutesConfig: getBorrowerLiteLoanRoutesConfig(routeBasePath, routeBaseUrl),
  };
};
