import { observer } from 'mobx-react';
import { OneToolQuoteStepper } from './components/oneToolQuoteStepper';
import { useOneToolStore } from './hooks/useOneToolStore';
import { BorrowerInformationStep } from './steps/borrowerInformation/borrowerInformationStep';
import {
  FinancedInterestReserve,
  GroundUpLeverageCard,
} from './steps/leverage/components/groundUpLeverageCard';
import { LeverageStep } from './steps/leverage/leverageStep';
import { GroundUpLoanDetailsCard } from './steps/loanEconomics/components/groundUpLoanDetailsCard';
import { EconomicsStep } from './steps/loanEconomics/economicsStep';
import { GroundUpPropertyInformationForm } from './steps/propertyInformation/forms/groundUpPropertyInformationForm';
import { PropertyInformationStep } from './steps/propertyInformation/propertyInformationStep';
import { SummaryStep } from './steps/summary/summaryStep';
import { PropertySource } from './utils/constants';
import { PreflightStep } from './steps/preflight/preflightStep';
import { LoanSuccessStep } from './steps/common/loanSuccess';

import { FixFlipGULeverageMatrixCard } from './steps/leverage/components/fixFlipGULeverageMatrixCard';

export const GroundUpQuote = observer(() => {
  const { oneToolStore } = useOneToolStore();
  const { groundUpQuoteStore } = oneToolStore;

  const propertyCount =
    groundUpQuoteStore.propertiesStore.propertyFormStores?.length;
  const isWholesale = groundUpQuoteStore.propertiesStore.propertyFormStores?.some?.(
    form =>
      PropertySource.WHOLESALER === form.form.fields.propertySourcing.value
  );

  return (
    <OneToolQuoteStepper
      title={'Create a Quote - Ground Up Loan'}
      store={groundUpQuoteStore}
      preflightStep={
        <PreflightStep
          store={groundUpQuoteStore}
          LoanDetailsCard={GroundUpLoanDetailsCard}
          PropertyInformationForm={GroundUpPropertyInformationForm}
        />
      }
      successStep={<LoanSuccessStep store={groundUpQuoteStore} />}
    >
      <BorrowerInformationStep store={groundUpQuoteStore} />
      <PropertyInformationStep
        store={groundUpQuoteStore}
        PropertyInformationForm={GroundUpPropertyInformationForm}
      />
      <LeverageStep
        store={groundUpQuoteStore}
        allowInstaQuote={propertyCount <= 1}
        LeverageCard={GroundUpLeverageCard}
        FinancedInterestReserve={FinancedInterestReserve}
        LeverageMatrixCard={FixFlipGULeverageMatrixCard}
      />
      <EconomicsStep
        store={groundUpQuoteStore}
        LoanDetailsCard={GroundUpLoanDetailsCard}
      />
      <SummaryStep
        store={groundUpQuoteStore}
        LoanDetailsCard={GroundUpLoanDetailsCard}
      />
    </OneToolQuoteStepper>
  );
});
