import { Box } from "@material-ui/core";
import { Layout } from "@roc/feature-app-core";
import { Broker, useBrokersStore } from "@roc/feature-brokers";
import { Toolbar, FilterButton, Button, EditableDialog } from "@roc/ui";
import { BrokerDialog } from "libs/feature-brokers/src/brokers/components/brokerDialog";
import { ManageBrokersGrid } from "libs/feature-brokers/src/brokers/components/manageBrokersGrid";
import { observer } from "mobx-react";
import { useEffect } from "react";

export const ManageBrokersInternal = observer(() => {
  const { brokersStore } = useBrokersStore();
  const { selectBrokersStore, brokerContactsGridStore, brokerFeesStore } = brokersStore;

  const handleToggleFilter = () => {
    brokerContactsGridStore.toggleFilters();
  };

  const handleClearFilter = () => {
    brokerContactsGridStore.resetFilters();
  };

  const handleAddNewBroker = () => selectBrokersStore.openAddNewBrokerDialog();

  return (
    <>
      <Layout title="Manage Brokers">
        <ManageBrokersGrid
          showLenderName
          toolbar={
            <Toolbar>
              <Box mr={2}>
                <FilterButton
                  onToggleFilters={handleToggleFilter}
                  onClearFilters={handleClearFilter}
                />
              </Box>
            </Toolbar>
          }
        />
        <BrokerDialog
          canEditBroker={false}
          canEditFees={true}
        />
      </Layout>
    </>
  )
});