import {
  Badge,
  Box,
  Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { SendOutlined } from '@material-ui/icons';
import { useStore } from '@roc/client-portal-shared/hooks';
import {
  CLOSING,
  DRAWS, PORTAL_COLORS,
  SERVICING, UNDERWRITING
} from '@roc/feature-utils';
import { useLoanStore } from '@roc/feature-loans';
import { Paper, Tabs, Tooltip } from '@roc/ui';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { ChatMessages } from '../../../documentCommunication/components/chatMessages';
import { MessageEditor } from '../../../documentCommunication/components/editor';
import { useDocumentCommunicationStore } from '../../../documentCommunication/hooks/useDocumentCommunicationStore';
import { LoanDetailsLayout } from '@roc/feature-loans';
import { useDocumentStore } from '../../hooks/useDocumentStore';
import { SelectCategory } from './selectCategory';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
    },
    badge: {
      '& .MuiBadge-root': {
        display: 'flex',
      },
      '& .MuiBadge-badge': {
        position: 'relative',
        top: 0,
        right: 0,
        transform: 'initial',
        transformOrigin: 'initial',
        color: theme.palette.common.white,
        fontWeight: 700,
      },
    },
    unreadMessagesBadge: {
      '& .MuiBadge-badge': {
        marginLeft: theme.spacing(1),
        backgroundColor: PORTAL_COLORS.unreadMessages,
      },
    },
  })
);

const CustomBadge = ({ numMessages }: { numMessages: number }) => {
  const classes = useStyles();
  return numMessages ? (
    <Tooltip title={`${numMessages} unread messages`} arrow>
      <Badge
        className={clsx([classes.badge, classes.unreadMessagesBadge])}
        badgeContent={numMessages}
      />
    </Tooltip>
  ) : null;
};

export const AllMessages = observer(() => {
  const [tabIndex, setTabIndex] = useState(0);

  const classes = useStyles();
  const {
    drawStore,
  } = useStore();
  const { documentStore } = useDocumentStore();
  const { documentCommunicationStore } = useDocumentCommunicationStore();
  const { loanStore } = useLoanStore();
  const { loanId } = documentStore.loanStore.loanDetails;
  const { unreadComments, showServicing } = documentStore;
  const drawList = drawStore.drawList || [];
  const { isEditorContentEmpty } = documentCommunicationStore;
  const sectionId = documentCommunicationStore.thread.meta.sectionId || '';
  const [selectedDrawId, setSelectedDrawId] = useState<string | undefined>();

  const showDraws = loanStore?.loanDetails?.showDraws;

  const tabs = [
    documentStore.isCommunicationAllowed(UNDERWRITING) && {
      process: UNDERWRITING,
      label: 'UNDERWRITING',
      right: (
        <CustomBadge numMessages={unreadComments?.underwriting?.totalUnread} />
      ),
    },
    documentStore.isCommunicationAllowed(CLOSING) && {
      process: CLOSING,
      label: 'CLOSING',
      right: <CustomBadge numMessages={unreadComments?.closing?.totalUnread} />,
    },
    showServicing &&
    documentStore.isCommunicationAllowed(SERVICING) && {
      process: SERVICING,
      label: 'SERVICING',
      right: (
        <CustomBadge numMessages={unreadComments?.servicing?.totalUnread} />
      ),
    },
    showDraws &&
    documentStore.isCommunicationAllowed(DRAWS) && {
      process: DRAWS,
      label: 'DRAWS',
      right: <CustomBadge numMessages={unreadComments?.draws?.totalUnread} />,
    },
  ].filter(Boolean);

  const process = tabs[tabIndex].process;

  useEffect(() => {
    if (showDraws && documentStore.isCommunicationAllowed(DRAWS)) {
      drawStore.fetchDraws(loanId);
    }
  }, []);

  useEffect(() => {
    documentCommunicationStore.getAllProcessComments(process);
    return () => {
      documentStore.fetchProcessUnreadComments(loanId, process);
    };
  }, [process, selectedDrawId]);

  const findLatestDraw = () => {
    return drawList.reduce(
      (a, b) => (a?.drawNumber > b?.drawNumber ? a : b),
      undefined
    );
  };

  const onTabSelect = (e, tabIndex) => {
    setTabIndex(tabIndex);
    if (tabs[tabIndex].process === DRAWS) {
      const latestDraw = findLatestDraw();
      setSelectedDrawId(latestDraw.drawId.toString());
    } else {
      setSelectedDrawId(undefined);
    }
  };

  return (
    <LoanDetailsLayout title="MESSAGES">
      <Box pb={2}>
        <Tabs tabs={tabs} selectedTab={tabIndex} onTabChange={onTabSelect} />
      </Box>
      <Paper className={classes.paper}>
        <Box p={2} flexGrow={1} overflow="auto" minHeight={400} flexBasis={400}>
          <ChatMessages />
        </Box>
        <Box p={2} borderTop="1px solid #eee" borderBottom="1px solid #eee">
          <MessageEditor />
        </Box>
        <Box p={2} display={'flex'}>
          <SelectCategory process={process} drawList={drawList} />
          <Button
            disabled={!sectionId || isEditorContentEmpty}
            variant="contained"
            size="small"
            color="primary"
            onClick={() => documentCommunicationStore.sendComment()}
            endIcon={<SendOutlined />}
          >
            Send
          </Button>
        </Box>
      </Paper>
    </LoanDetailsLayout>
  );
});
