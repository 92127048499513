import { Grid, Table, TableBody, TableCell, TableFooter, TableHead, TableRow, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { Layout } from '@roc/client-portal-shared/components';
import { observer } from 'mobx-react';
import { Paper, TestDataAttribute, Tooltip } from '@roc/ui';
import React, { useEffect, useState, useMemo } from 'react';
import {
  InPlaceMonthlyExpenses,
  InPlaceMonthlyIncome,
  PurchasePrice,
  Rate,
  RenovationAmount,
  VerifiedLiquidity
} from './components/multifamilyLiquidityCalculatorFields';
import { AutocompleteField } from '../dscrCalculator/components/autocompleteField';
import { debounce } from '@roc/client-portal-shared/utils';
import { MultifamilyLiquidityCalculatorBaseStore } from './stores/multifamilyLiquidityCalculatorBaseStore';
import { SmallCalculateButton } from '../dscrCalculator/components/dscrCalculatorComponents';
import { red, green } from '@material-ui/core/colors';
import { Help } from '@material-ui/icons';

interface LiquidityCalcSectionProps {
  store: MultifamilyLiquidityCalculatorBaseStore;
}

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  tabs: {},
  calculatedValuesBorder: {
    border: '1px solid #BEBEBE',
    borderRadius: '15px',
    padding: 16,
  },
  redText: {
    color: red[500]
  },
  greenText: {
    color: green[500]
  },
  tr: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '0.750 rem',
    paddingLeft: '2px'
  },
  footerTopCell: {
    padding: '15px 0 0 18px',
    borderBottom: 'none',

  },
  footerDownCell: {
    padding: '0 0 15px 18px',
    borderBottom: 'none'
  },
  noBottomBorder: {
    borderBottom: 'none'
  },
  helpIcon: {
    height: '18px',
    margin: theme.spacing(0, 0, 0.5, 0.5),
    width: '18px',
  },
}
));

export const MultifamilyLiquidityCalculator = observer(
  (props: LiquidityCalcSectionProps) => {
    const classes = useStyles();
    const multifamilyLiquidityCalculatorStore = props.store;
    const formStore = multifamilyLiquidityCalculatorStore;

    const calculateLiquidity = () => {
      multifamilyLiquidityCalculatorStore.fetchCalculatedLiquidity();
    };

    const fetchOptions = useMemo(
      () => debounce(searchText => formStore.fetchOptions(searchText), 500),
      [formStore]
    );

    return (
      <Layout title="Multifamily Bridge Liquidity Calculator" maxWidth={'xl'}>
        <Paper className={classes.paper}>
          <Grid container spacing={4}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container xs={12} spacing={2}>
                  <Grid item xs={12}>
                    <AutocompleteField
                      value={formStore.loanSearchText}
                      options={formStore.loanSearchOptions}
                      onChange={text => {
                        formStore.loanSearchText = text;
                        fetchOptions(formStore.loanSearchText);
                      }}
                      onSelect={opt => {
                        formStore.loanSearchText = opt.label;
                        formStore.fetchLoanValues(opt.value);
                      }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <LoanValuesSection store={formStore} />
                  </Grid>

                  <Grid item xs={12} container justifyContent="flex-end">
                    <SmallCalculateButton onClick={calculateLiquidity} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid
                  container
                  spacing={2}
                  className={classes.calculatedValuesBorder}
                >
                  <Grid item xs={12}>
                    <Typography variant={'h4'}>
                      Total Multi Bridge Liquidity
                    </Typography>
                  </Grid>
                  <CalculatedValues store={formStore} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Layout>
    );
  }
);

const CalculatedValues = observer(({ store }: LiquidityCalcSectionProps) => {
  const { calculatedValues } = store;
  const classes = useStyles();
  const formatCurr = (value) =>
    value || value === 0 ? formatCurrency(value) : '';

  const renderHeading = (value, tooltip = '') => {
    return (
      <Typography
        variant="body1"
      >
        <strong>{value}</strong>{tooltip !== '' && <Tooltip title={tooltip}><Help className={classes.helpIcon} color="primary" /></Tooltip>}
      </Typography>
    );
  };

  const checkIfNullOrUndefined = (field, defaultValue) => {
    const updatedField =
      field === null || field === undefined ? defaultValue : field;
    return updatedField;
  };

  const formatPercentage = (value, digits) =>
    checkIfNullOrUndefined(value, '') === ''
      ? ''
      : new Intl.NumberFormat('en-US', {
        style: 'percent',
        maximumFractionDigits: digits || 0,
      }).format(value / 100);

  const roundHalf = (num: number) => {
    if ((num * 100) % 10 != 5) {
      num = parseFloat((Math.round(num * 10) / 10).toFixed(2));
    }
    return num;
  };

  const formatCurrency = (number) =>
    new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
    }).format(number);

  return (
    <>
      <Grid item xs={12}>
        <Paper title="Loan Values">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{renderHeading('Value')}</TableCell>
                <TableCell align='right'>{renderHeading('Monthly')}</TableCell>
                <TableCell align='right'>{renderHeading('Annual')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{renderHeading('In-place Income')}</TableCell>
                <TestDataAttribute id='in_place_monthly_income'>
                  <TableCell align='right'>{calculatedValues ? formatCurr(calculatedValues.inPlaceMonthlyIncome.toFixed(0)) : '-'}</TableCell>
                </TestDataAttribute>
                <TestDataAttribute id='in_place_annual_income'>
                  <TableCell align='right'>{calculatedValues ? formatCurr(calculatedValues.inPlaceAnnualIncome.toFixed(0)) : '-'}</TableCell>
                </TestDataAttribute>
              </TableRow>
              <TableRow>
                <TableCell>{renderHeading('In-place Expenses')}</TableCell>
                <TestDataAttribute id='in_place_monthly_expenses'>
                  <TableCell align='right'>{calculatedValues ? formatCurr(calculatedValues.inPlaceMonthlyExpenses.toFixed(0)) : '-'}</TableCell>
                </TestDataAttribute>
                <TestDataAttribute id='in_place_annual_expenses'>
                  <TableCell align='right'>{calculatedValues ? formatCurr(calculatedValues.inPlaceAnnualExpenses.toFixed(0)) : '-'}</TableCell>
                </TestDataAttribute>
              </TableRow>
              <TableRow>
                <TableCell>{renderHeading('Cashflow')}</TableCell>
                <TestDataAttribute id='monthly_cashflow'>
                  <TableCell align='right'>{calculatedValues ? formatCurr(calculatedValues.monthlyCashFlow.toFixed(0)) : '-'}</TableCell>
                </TestDataAttribute>
                <TestDataAttribute id='annual_cashflow'>
                  <TableCell align='right'>{calculatedValues ? formatCurr(calculatedValues.annualCashFlow.toFixed(0)) : '-'}</TableCell>
                </TestDataAttribute>
              </TableRow>
              <TableRow>
                <TableCell>{renderHeading('Debt Service')}</TableCell>
                <TestDataAttribute id='monthly_debt_service'>
                  <TableCell align='right'>{calculatedValues ? formatCurr(calculatedValues.monthlyDebtService.toFixed(0)) : '-'}</TableCell>
                </TestDataAttribute>
                <TestDataAttribute id='annual_debt_service'>
                  <TableCell align='right'>{calculatedValues ? formatCurr(calculatedValues.annualDebtService.toFixed(0)) : '-'}</TableCell>
                </TestDataAttribute>
              </TableRow>
              <TableRow>
                <TableCell className={calculatedValues?.monthlyPartialDSCR > 1 && classes.greenText}>{renderHeading('DSCR')}</TableCell>
                <TestDataAttribute id='partial_monthly_dscr'>
                  <TableCell align='right' className={calculatedValues?.monthlyPartialDSCR > 1 && classes.greenText}>{calculatedValues ? calculatedValues.monthlyPartialDSCR.toFixed(2) : '-'}</TableCell>
                </TestDataAttribute>
                <TestDataAttribute id='partial_annual_dscr'>
                  <TableCell align='right' className={calculatedValues?.monthlyPartialDSCR > 1 && classes.greenText}>{calculatedValues ? calculatedValues.annualPartialDSCR.toFixed(2) : '-'}</TableCell>
                </TestDataAttribute>
              </TableRow>
              <TableRow>
                <TableCell className={calculatedValues?.monthlyDeficitTo1xDSC < 0 && classes.redText}>{renderHeading('Deficit to 1.0x DSCR', 'or your interest Reserve')}</TableCell>
                <TestDataAttribute id='monthly_deficit'>
                  <TableCell className={calculatedValues?.monthlyDeficitTo1xDSC < 0 && classes.redText} align='right'>{calculatedValues ? formatCurr(calculatedValues.monthlyDeficitTo1xDSC.toFixed(0)) : '-'}</TableCell>
                </TestDataAttribute>
                <TestDataAttribute id='annual_deficit'>
                  <TableCell className={calculatedValues?.monthlyDeficitTo1xDSC < 0 && classes.redText} align='right'>{calculatedValues ? formatCurr(calculatedValues.annualDeficitTo1xDSC.toFixed(0)) : '-'}</TableCell>
                </TestDataAttribute>
              </TableRow>
              <TableRow>
                <TableCell>{renderHeading('CF + Interest Reserve')}</TableCell>
                <TestDataAttribute id='monthly_cf_plus'>
                  <TableCell align='right'>{calculatedValues ? formatCurr(calculatedValues.monthlyCashFlowPlusInterestReserve.toFixed(0)) : '-'}</TableCell>
                </TestDataAttribute>
                <TestDataAttribute id='annual_cf_plus'>
                  <TableCell align='right'>{calculatedValues ? formatCurr(calculatedValues.annualCashFlowPlusInterestReserve.toFixed(0)) : '-'}</TableCell>
                </TestDataAttribute>
              </TableRow>
              <TableRow>
                <TableCell>{renderHeading('Debt Service')}</TableCell>
                <TestDataAttribute id='monthly_debt_service'>
                  <TableCell align='right'>{calculatedValues ? formatCurr(calculatedValues.monthlyDebtService.toFixed(0)) : '-'}</TableCell>
                </TestDataAttribute>
                <TestDataAttribute id='annual_debt_service'>
                  <TableCell align='right'>{calculatedValues ? formatCurr(calculatedValues.annualDebtService.toFixed(0)) : '-'}</TableCell>
                </TestDataAttribute>
              </TableRow>
              <TableRow>
                <TableCell>{renderHeading('DSCR')}</TableCell>
                <TestDataAttribute id='monthly_dscr'>
                  <TableCell align='right'>{calculatedValues ? calculatedValues.monthlyDSCR : '-'}</TableCell>
                </TestDataAttribute>
                <TestDataAttribute id='annual_dscr'>
                  <TableCell align='right'>{calculatedValues ? calculatedValues.annualDSCR : '-'}</TableCell>
                </TestDataAttribute>
              </TableRow>
            </TableBody>
          </Table>
        </Paper>
      </Grid>
      <Grid item xs={6}>
        <Paper title="Sources of Capital Acquisition">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{renderHeading('Value')}</TableCell>
                <TableCell align='right'>{renderHeading('Amount')}</TableCell>
                <TableCell align='right'>{renderHeading('Percentage')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{renderHeading('Loan Amount', '75% price / 100% reno')}</TableCell>
                <TestDataAttribute id='loan_amount'>
                  <TableCell align='right'>{calculatedValues ? formatCurr(calculatedValues.loanAmount.toFixed(0)) : '-'}</TableCell>
                </TestDataAttribute>
                <TestDataAttribute id='loan_amount_percentage'>
                  <TableCell align='right'>{calculatedValues ? formatPercentage(roundHalf((calculatedValues.loanAmount / calculatedValues.totalSourcesOfCapital) * 100), 0) : '-'}</TableCell>
                </TestDataAttribute>
              </TableRow>
              <TableRow>
                <TableCell>{renderHeading('Equity', 'Cash required to close on the transaction')}</TableCell>
                <TestDataAttribute id='equity'>
                  <TableCell align='right'>{calculatedValues ? formatCurr(calculatedValues.equity.toFixed(0)) : '-'}</TableCell>
                </TestDataAttribute>
                <TestDataAttribute id='equity_percentage'>
                  <TableCell align='right'>{calculatedValues ? formatPercentage(roundHalf((calculatedValues.equity / calculatedValues.totalSourcesOfCapital) * 100), 0) : '-'}</TableCell>
                </TestDataAttribute>
              </TableRow>
              <TableRow>
                <TableCell>{renderHeading('Total')}</TableCell>
                <TestDataAttribute id='total'>
                  <TableCell align='right'>{calculatedValues ? formatCurr(calculatedValues.totalSourcesOfCapital.toFixed(0)) : '-'}</TableCell>
                </TestDataAttribute>
                <TestDataAttribute id='total_percentage'>
                  <TableCell align='right'>{calculatedValues ? formatPercentage(roundHalf((calculatedValues.totalSourcesOfCapital / calculatedValues.totalSourcesOfCapital) * 100), 0) : '-'}</TableCell>
                </TestDataAttribute>
              </TableRow>
            </TableBody>
          </Table>
        </Paper>
      </Grid>
      <Grid item xs={6}>
        <Paper title="Uses of Capital Acquisition">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{renderHeading('Value')}</TableCell>
                <TableCell align='right'>{renderHeading('Amount')}</TableCell>
                <TableCell align='right'>{renderHeading('Percentage')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{renderHeading('Purchase Price')}</TableCell>
                <TestDataAttribute id='purchase_price'>
                  <TableCell align='right'>{calculatedValues ? formatCurr(calculatedValues.purchasePrice.toFixed(0)) : '-'}</TableCell>
                </TestDataAttribute>
                <TestDataAttribute id='purchase_price_percentage'>
                  <TableCell align='right'>{calculatedValues ? formatPercentage((calculatedValues.purchasePrice / calculatedValues.totalUsesOfCapital) * 100, 0) : '-'}</TableCell>
                </TestDataAttribute>
              </TableRow>
              <TableRow>
                <TableCell>{renderHeading('Renovation Budget')}</TableCell>
                <TestDataAttribute id='renovation_budget'>
                  <TableCell align='right'>{calculatedValues ? formatCurr(calculatedValues.renovationAmount.toFixed(0)) : '-'}</TableCell>
                </TestDataAttribute>
                <TestDataAttribute id='renovation_budget'>
                  <TableCell align='right'>{calculatedValues ? formatPercentage((calculatedValues.renovationAmount / calculatedValues.totalUsesOfCapital) * 100, 0) : '-'}</TableCell>
                </TestDataAttribute>
              </TableRow>
              <TableRow>
                <TableCell>{renderHeading('Interest Reserve')}</TableCell>
                <TestDataAttribute id='interest_reserve'>
                  <TableCell align='right'>{calculatedValues ? formatCurr(calculatedValues.interestReserve.toFixed(0)) : '-'}</TableCell>
                </TestDataAttribute>
                <TestDataAttribute id='interest_reserve_percentage'>
                  <TableCell align='right'>{calculatedValues ? formatPercentage(roundHalf((calculatedValues.interestReserve / calculatedValues.totalUsesOfCapital) * 100), 0) : '-'}</TableCell>
                </TestDataAttribute>
              </TableRow>
              <TableRow>
                <TableCell>{renderHeading('Origination Fees')}</TableCell>
                <TestDataAttribute id='origination_fees'>
                  <TableCell align='right'>{calculatedValues ? formatCurr(calculatedValues.originationFees.toFixed(0)) : '-'}</TableCell>
                </TestDataAttribute>
                <TestDataAttribute id='origination_fees'>
                  <TableCell align='right'>{calculatedValues ? formatPercentage(roundHalf((calculatedValues.originationFees / calculatedValues.totalUsesOfCapital) * 100), 0) : '-'}</TableCell>
                </TestDataAttribute>
              </TableRow>
              <TableRow>
                <TableCell>{renderHeading('Closing Costs')}</TableCell>
                <TestDataAttribute id='closing_costs'>
                  <TableCell align='right'>{calculatedValues ? formatCurr(calculatedValues.closingCosts.toFixed(0)) : '-'}</TableCell>
                </TestDataAttribute>
                <TestDataAttribute id='closing_costs_percentage'>
                  <TableCell align='right'>{calculatedValues ? formatPercentage(roundHalf((calculatedValues.closingCosts / calculatedValues.totalUsesOfCapital) * 100), 0) : '-'}</TableCell>
                </TestDataAttribute>
              </TableRow>
              <TableRow>
                <TableCell className={classes.noBottomBorder}>{renderHeading('Total')}</TableCell>
                <TestDataAttribute id='total'>
                  <TableCell className={classes.noBottomBorder} align='right'>{calculatedValues ? formatCurr(calculatedValues.totalUsesOfCapital.toFixed(0)) : '-'}</TableCell>
                </TestDataAttribute>
                <TestDataAttribute id='total_percentage'>
                  <TableCell className={classes.noBottomBorder} align='right'>{calculatedValues ? formatPercentage(roundHalf((calculatedValues.totalUsesOfCapital / calculatedValues.totalUsesOfCapital) * 100), 0) : '-'}</TableCell>
                </TestDataAttribute>
              </TableRow>
            </TableBody>
          </Table>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper title="Cash to close">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{renderHeading('Value')}</TableCell>
                <TableCell align='right'>{renderHeading('Amount')}</TableCell>
                <TableCell align='right'>{renderHeading('Percentage')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{renderHeading('Cash to close on transaction')}</TableCell>
                <TestDataAttribute id='equity'>
                  <TableCell align='right'>{calculatedValues ? formatCurr(calculatedValues.equity.toFixed(0)) : '-'}</TableCell>
                </TestDataAttribute>
                <TableCell />
              </TableRow>
              <TableRow>
                <TableCell>{renderHeading('Post closing Liquidity Requirement', '10% of the loan amount')}</TableCell>
                <TableCell align='right'>{calculatedValues ? formatCurr(calculatedValues.postClosingLiquidityRequirements.toFixed(0)) : '-'}</TableCell>
                <TableCell />
              </TableRow>
              <TableRow>
                <TableCell>{renderHeading('Total requirement')}</TableCell>
                <TestDataAttribute id='total'>
                  <TableCell align='right'>{calculatedValues ? formatCurr(calculatedValues.totalRequirement.toFixed(0)) : '-'}</TableCell>
                </TestDataAttribute>
                <TableCell />
              </TableRow>
              <TableRow>
                <TableCell>{renderHeading('Verified liquidity')}</TableCell>
                <TestDataAttribute id='total'>
                  <TableCell align='right'>{calculatedValues ? formatCurr(calculatedValues.verifiedLiquidity.toFixed(0)) : '-'}</TableCell>
                </TestDataAttribute>
                <TableCell />
              </TableRow>
              <TableRow>
                <TableCell className={calculatedValues ?
                  calculatedValues.shortfallOrSurplus < 0 ?
                    classes.redText :
                    classes.greenText :
                  ''}>{renderHeading(calculatedValues ?
                    calculatedValues.shortfallOrSurplus < 0 ?
                      'Shortfall' :
                      'Surplus' :
                    'Shortfall/Surplus')}</TableCell>
                <TestDataAttribute id='total'>
                  <TableCell className={calculatedValues ?
                    calculatedValues.shortfallOrSurplus < 0 ?
                      classes.redText :
                      classes.greenText :
                    ''} align='right'>{calculatedValues ? formatCurr(calculatedValues.shortfallOrSurplus.toFixed(0)) : '-'}</TableCell>
                </TestDataAttribute>
                <TableCell align='right' className={calculatedValues ?
                  calculatedValues.shortfallOrSurplus < 0 ?
                    classes.redText :
                    classes.greenText :
                  ''}>{calculatedValues ? formatPercentage(calculatedValues.shortfallOrSurplusPct, 0) : '-'}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Paper>
      </Grid>


    </>
  );
});

export const LoanValuesSection = ({ store }: LiquidityCalcSectionProps) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant={'h5'}>Loan Values</Typography>
      </Grid>
      <PurchasePrice store={store} />
      <RenovationAmount store={store} />
      <Rate store={store} />
      <InPlaceMonthlyIncome store={store} />
      <InPlaceMonthlyExpenses store={store} />
      <VerifiedLiquidity store={store} />
    </Grid>
  );
};
