import { useEffect, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { Paper } from '@roc/ui';
import { observer } from 'mobx-react';
import { useParams } from 'react-router';
import { Layout, StepperComponent } from '@roc/client-portal-shared/components';

import { useStore } from '../../hooks';
import { decodeData } from './loanTermsUtils';

import GoodFaithDeposit from './components/goodFaithDeposit';
import Payment from './components/payment';
import GFDDocusign from './components/GFDDocusign';
import { GFD_LOAN_FLOW } from './constants/loanTerms';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(3, 0, 4),
      borderRadius: theme.spacing(2),
      boxShadow:
        'rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px',
    },
    stepper: {
      maxWidth: 900,
      margin: 'auto',
      [theme.breakpoints.down('xs')]: {
        paddingLeft: theme.spacing(.5),
        paddingRight: theme.spacing(.5),
      },
    },
    stepContentWrapper: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      [theme.breakpoints.down('xs')]: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
      },
    },
  })
);

export type EncodedParams = {
  encodedParams: string;
};

export const LoanTerms = observer(() => {
  const [activeStep, setActiveStep] = useState(0);
  const [disableStepperNavigation, setDisableStepperNavigation] = useState(
    false
  );
  const [loanData, setLoanData] = useState(null);
  const { encodedParams } = useParams<EncodedParams>();
  const { loanTermsStore } = useStore();
  const { docusignStatus } = loanTermsStore;
  const { loanId, borrowerFirstName, borrowerLastName } = loanData || {};
  const classes = useStyles();
  const subTitle: string = loanData
    ? `Loan #${loanId} - ${loanData.propertyAddress}`
    : '';

  const loanTermsSteps = [
    {
      title: 'Acknowledgement',
      icon: null,
      onBack: () => { },
      onNext: () => { },
      allowBack: true,
      allowNext: true,
    },
    {
      title: 'Good Faith Deposit',
      icon: null,
      onBack: () => { },
      onNext: () => { },
      allowBack: true,
      allowNext: true,
    },
    {
      title: 'Payment',
      icon: null,
      onBack: () => { },
      onNext: () => { },
      allowBack: true,
      allowNext: true,
    },
  ];

  const handleBackStep = () => setActiveStep(activeStep - 1);

  const handleNextStep = () => setActiveStep(activeStep + 1);

  const handleStep = (index: number) => setActiveStep(index);

  useEffect(() => {
    setLoanData(decodeData(encodedParams));
    return () => {
      loanTermsStore.reset();
    };
  }, []);

  useEffect(() => {
    if (loanId) {
      loanTermsStore.fetchDocusignAndPaymentStatus(loanId, GFD_LOAN_FLOW);
    }
  }, [loanId]);

  useEffect(() => {
    setDisableStepperNavigation(!docusignStatus);
  }, [docusignStatus]);

  return (
    <Layout>
      <Box my={3}>
        <Typography variant="h3" gutterBottom>
          {'Good Faith Deposit'}
        </Typography>
      </Box>
      <Paper className={classes.paper}>
        <StepperComponent
          activeStep={activeStep}
          disableNavigation={disableStepperNavigation}
          handleStep={handleStep}
          steps={loanTermsSteps}
          className={classes.stepper}
        />
        <Box mt={3} className={classes.stepContentWrapper}>
          {activeStep === 0 && (
            <GFDDocusign
              handleNextStep={handleNextStep}
              loanId={loanId}
              subTitle={subTitle}
              firstName={borrowerFirstName}
              lastName={borrowerLastName}
            />
          )}
          {activeStep === 1 && (
            <GoodFaithDeposit
              handleBackStep={handleBackStep}
              handleNextStep={handleNextStep}
              subTitle={subTitle}
            />
          )}
          {activeStep === 2 && (
            <Payment
              handleDisableStepperNavigation={setDisableStepperNavigation}
              loanData={loanData}
              subTitle={subTitle}
            />
          )}
        </Box>
      </Paper>
    </Layout>
  );
});
