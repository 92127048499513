import {
  ConfirmationButton,
  StandardDialog,
  Button,
  TextField,
  createTextField,
  FieldLabel,
  AddressDetailsField,
  createNumberFormatField,
} from '@roc/ui';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { isNil, isNotBlank } from '@roc/feature-utils';
import { QuoteTypeStore } from 'libs/feature-one-tool/src/quote/stores/quoteTypeStore';
import { Grid } from '@material-ui/core';
import { TitleFormStore } from 'libs/feature-one-tool/src/quote/stores/closing/titleFormStore';
import { FormStore } from '@roc/feature-app-core';

interface Props {
  store: QuoteTypeStore;
}

export const OtherTitleDialog = observer(({ store }: Props) => {
  const { closingStore } = store;
  const { titleFormStore } = closingStore;

  return (
    <StandardDialog
      open={titleFormStore.showTitleDialog}
      title={`Title`}
      maxWidth="md"
      handleClose={() => titleFormStore.closeDialog()}
      dialogContent={<OtherTitleForm store={titleFormStore} />}
      dialogActions={
        <>
          <Button
            style={{ marginRight: '8px' }}
            onClick={() => titleFormStore.closeDialog()}
            color="primary"
            variant="outlined"
            testId="cancel"
          >
            Cancel
          </Button>
          <Button
            onClick={() => titleFormStore.saveTitle()}
            color="primary"
            variant="contained"
            testId="save"
          >
            Confirm
          </Button>
        </>
      }
    />
  );
});

const OtherTitleForm = observer(({ store }: { store: TitleFormStore }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {createTextField({
          store: store,
          label: 'Name',
          fieldName: 'name',
          testId: 'name',
        })}
      </Grid>
      <Grid item xs={12}>
        {createTextField({
          store: store,
          label: 'Contact Name',
          fieldName: 'contactName',
          testId: 'contactName',
        })}
      </Grid>
      <Grid item xs={12}>
        {createNumberFormatField({
          store,
          testId: 'cellPhone',
          fieldName: 'cellPhone',
          label: 'Phone Number',
          format: '###-###-####',
        })}
      </Grid>
      <Grid item xs={12}>
        <Address store={store} />
      </Grid>
      <Grid item xs={12}>
        {createTextField({
          store: store,
          label: 'Email Address',
          fieldName: 'emailAddress',
          testId: 'emailAddress',
        })}
      </Grid>
    </Grid>
  );
});

const Address = observer(({ store }: { store: FormStore }) => {
  const fieldName = 'address';
  const field = store.form.fields[fieldName];
  return (
    <>
      <FieldLabel>Address</FieldLabel>
      <AddressDetailsField
        value={field.value}
        onChange={value => store.onFieldChange(fieldName, value)}
        name={fieldName}
        error={!store.form.meta.isValid && isNotBlank(field.error)}
        errorText={!store.form.meta.isValid && field.error}
        standaloneLabel={false}
      />
    </>
  );
});
