import { Layout } from '@roc/feature-app-core';
import { EmbeddedFrameBoundaries, BorrowerUWReviewCard } from '@roc/ui';
import { BorrowerInfoCardCustomTheme } from './components/borrowerInfoCardCustomTheme';
import { BorrowerInfoCardInternalStore } from './stores/borrowerInfoCardInternalStore';


export const BorrowerUWReviewComponent = (store: BorrowerInfoCardInternalStore, borrowerId: string, loanId: string, reviewSection: string) => {
  store.currentBorrower = parseInt(borrowerId);
  store.loanId = loanId;
  store.reviewSection = reviewSection;
  if (!borrowerId) {
    return (<></>);
  }
  let parsedLoanId;
  try {
    parsedLoanId = loanId ? parseInt(loanId) : null;
  } catch {
  }
  store.fetchBaseballCardData(parseInt(borrowerId), parsedLoanId);

  return (
    <Layout>
      <BorrowerInfoCardCustomTheme>
        <EmbeddedFrameBoundaries>
          <BorrowerUWReviewCard store={store} />
        </EmbeddedFrameBoundaries>
      </BorrowerInfoCardCustomTheme>
    </Layout>
  );
};
