import {
  Badge,
  Box,
  Chip,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { ImportExport, MoreVert, PriorityHigh } from '@material-ui/icons';
import { bridgeLoanSubTypes } from '@roc/client-portal-shared/utils';
import {
  ConfirmationMenuItem,
  LinkColumn,
  SelectField,
  SelectFilterComponent,
  SelectFloatingFilterComponent,
  StatusChip,
  StatusType,
  SubmitMenuItem,
  TextColumn, FastForwardIcon,
} from '@roc/ui';
import { PORTAL_COLORS } from '@roc/client-portal-shared/utils';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { ChangeLoanStatusForm } from './changeLoanStatusForm';
import { LoanPriority, loanPriorityOptions } from '../utils/loansConstants';
import { InfiniteScrollGrid } from '@roc/feature-app-core';
import { useLoanRoutes } from '@roc/feature-loans-routes-config';
import { useLoanStore } from '../hooks/useLoanStore';

export const StyledBadgeFunded = withStyles(theme => ({
  badge: {
    right: -12,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
    fontSize: '11px',
    backgroundColor: PORTAL_COLORS.unreadMessages,
    color: 'white',
    fontWeight: 700,
    userSelect: 'none',
  },
}))(Badge);

export const getUnreadMessagesCountFunded = unreadMessages => {
  if (unreadMessages) {
    return unreadMessages > 99 ? '99+' : unreadMessages;
  }
  return undefined;
};

export const FundedPaidOffLoansGrid = observer(({ columns }) => {
  const { loanStore } = useLoanStore();
  const { loanRoutesConfig } = useLoanRoutes();
  const [loanId, setLoanId] = useState(0);
  const [anchorEl, setAnchorEl] = useState<HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const [showMarkAsHighPriority, setShowMarkAsHighPriority] = useState(true);
  const [showChangePriority, setShowChangePriority] = useState(false);
  const [
    selectPriorityValue,
    setSelectPriorityValue,
  ] = useState<LoanPriority>();

  const frameworkComponents = {
    selectFilter: SelectFilterComponent,
    selectFloatingFilter: SelectFloatingFilterComponent,
    loanIdCellRenderer: params =>
      loanStore.isLoanSubtypeSupported(params.node.data?.loanSubType) ? (
        <StyledBadgeFunded
          badgeContent={getUnreadMessagesCountFunded(
            params.node.data?.unreadMessages
          )}
        >
          {LinkColumn({
            ...params,
            url: loanRoutesConfig.loans(params.value).children.dashboard.url,
          })}
        </StyledBadgeFunded>
      ) : (
        <StyledBadgeFunded
          badgeContent={getUnreadMessagesCountFunded(
            params.node.data?.unreadMessages
          )}
        >
          {TextColumn(params)}
        </StyledBadgeFunded>
      ),
    fastTrackCellRenderer: ({ value }) => {
      if (!value) {
        return null;
      }
      return (<FastForwardIcon value={value} />)
    },
    pendingDocumentsCellRenderer: ({ value }) => {
      if (!value) {
        return null;
      }
      return (
        <Box pt={1} display="flex" justifyContent="center">
          <Chip
            size="small"
            label={value}
            style={{
              backgroundColor: PORTAL_COLORS.pendingDocuments,
              color: 'white',
              fontWeight: 700,
            }}
          />
        </Box>
      );
    },
    dealNameCellRenderer: params => {
      const loanId = params.node.data?.dealid;
      return LinkColumn({
        ...params,
        url: loanRoutesConfig.loans(loanId).children.dashboard.url,
      });
    },
    statusCellRenderer: ({ value }) =>
      value ? (
        <StatusChip label={value} size="small" statusType={StatusType.LOAN} />
      ) : null,
    actionCellRenderer: params => {
      return (
        <IconButton onClick={e => openMenu(e, params.node.data)}>
          <MoreVert color="primary" />
        </IconButton>
      );
    },
  };

  const openMenu = (event: React.MouseEvent<HTMLElement>, record) => {
    const {
      dealid,
      priority,
      loanSubType,
      keyDocumentsCompletedForSFRLoans,
    } = record;

    const isBridgeLoan = bridgeLoanSubTypes.includes(loanSubType);

    setLoanId(dealid);

    setSelectPriorityValue(priority);
    if (isBridgeLoan) {
      setShowChangePriority(true);
      setShowMarkAsHighPriority(false);
    } else {
      setShowChangePriority(false);
      setShowMarkAsHighPriority(
        priority?.toLowerCase() === 'low' && keyDocumentsCompletedForSFRLoans
      );
    }
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <InfiniteScrollGrid
        columns={columns}
        frameworkComponents={frameworkComponents}
        store={loanStore.myLoansGridStore}
      />
      <Menu
        id="menu-appbar"
        getContentAnchorEl={null}
        anchorEl={anchorEl}
        keepMounted
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={menuOpen}
        onClose={handleMenuClose}
      >
        {showChangePriority && (
          <SubmitMenuItem
            testId={`select-priority-${loanId}`}
            onClick={() => {
              if (selectPriorityValue) {
                loanStore.changeLoanPriority(loanId, selectPriorityValue);
                handleMenuClose();
              }
            }}
            submitDialogProps={{
              title: `Loan ${loanId}`,
              body: (
                <SelectField
                  standaloneLabel
                  label={'Please set the urgency of the deal:'}
                  value={selectPriorityValue}
                  required={true}
                  options={loanPriorityOptions}
                  onChange={value => setSelectPriorityValue(value)}
                  variant={'outlined'}
                  fullWidth
                  testId={'select-priority-dropdown'}
                />
              ),
              okButtonText: 'Submit',
              cancelButtonText: 'Close',
            }}
          >
            <ListItemIcon>
              <PriorityHigh fontSize="small" />
            </ListItemIcon>
            <ListItemText>Change Priority</ListItemText>
          </SubmitMenuItem>
        )}
        {showMarkAsHighPriority && (
          <ConfirmationMenuItem
            testId={`mark-as-high-${loanId}`}
            onClick={() => {
              loanStore.changeLoanPriority(loanId, LoanPriority.HIGH);
              handleMenuClose();
            }}
            confirmDialogProps={{
              body: `Are you sure you want to mark loan ${loanId} as high priority?`,
            }}
          >
            <ListItemIcon>
              <PriorityHigh fontSize="small" />
            </ListItemIcon>
            <ListItemText>Mark as High Priority</ListItemText>
          </ConfirmationMenuItem>
        )}
        <SubmitMenuItem
          testId={`change-loan-status-${loanId}`}
          onClick={() => {
            loanStore.submitFormStatus(loanId);
            handleMenuClose();
          }}
          submitDialogProps={{
            title: `Change Status for Loan ${loanId}`,
            body: <ChangeLoanStatusForm loanId={loanId} />,
            okButtonText: 'Submit',
            submitValidation: () => {
              loanStore.loanStatusStore.runFormValidation();
              return loanStore.loanStatusStore.form.meta.isValid;
            },
          }}
        >
          <ListItemIcon>
            <ImportExport fontSize="small" />
          </ListItemIcon>
          <ListItemText>Change Status</ListItemText>
        </SubmitMenuItem>
      </Menu>
    </>
  );
});
