import { FormStore } from '@roc/feature-app-core';
import { isNil, parseAddress } from '@roc/feature-utils';

const personalInformationForm = {
  fields: {
    firstName: {
      value: '',
      error: null,
      rule: 'required',
      message: 'The first name field is required.',
    },
    middleName: {
      value: '',
      error: null,
      rule: '',
      message: '',
    },
    lastName: {
      value: '',
      error: null,
      rule: 'required',
      message: 'The last name field is required.',
    },
    phoneNumber: {
      value: '',
      error: null,
      rule: 'required|regex:/^\\d{3}-?\\d{3}-?\\d{4}$/|alpha_dash',
      message: 'Phone must be a number with the format xxx-xxx-xxxx',
    },
    dateOfBirth: {
      type: 'DATE',
      value: null,
      error: null,
      rule: 'required',
      format: 'MM/dd/yyyy',
      message: 'Date of birth is required.',
    },
    ssn: {
      value: '',
      error: null,
      message: 'Invalid SSN number.',
      rule: [
        {
          required_if_one_of: [
            'citizenshipStatus',
            'US Citizen',
            'US Permanent Resident(Green Card Holder)',
            'US Resident with Valid Visa',
          ],
        },
        { regex: '/^\\d{3}-?\\d{2}-?\\d{4}$/' },
      ],
    },
    address: {
      value: '',
      error: null,
      message: 'Address Line 1 is required',
      rule: 'required',
    },
    __isAddressInCorrectFormat__: {
      value: true,
      error: null,
      rule: 'accepted',
      message: 'Invalid Address Format',
    },
    streetNumber: {
      value: '',
      error: null,
      rule: '',
    },
    streetName: {
      value: '',
      error: null,
      rule: '',
    },
    aptNumber: {
      value: '',
      error: null,
      rule: '',
    },
    city: {
      value: '',
      error: null,
      rule: 'required',
      message: 'The city field is required.',
    },
    state: {
      value: '',
      error: null,
      rule: 'required',
      message: 'The state field is required.',
    },
    zipCode: {
      value: '',
      error: null,
      message: 'Zip code must be 5 digits',
      rule: 'required|regex:/^\\d{5}$/|alpha_dash',
    },
    citizenshipStatus: {
      value: 'US Citizen',
      error: null,
      rule: 'required',
    }
  },
  meta: {
    isValid: false,
    error: null,
  },
};
export class PersonalInformationFormStore extends FormStore {

  constructor(globalStore) {
    super({ ...personalInformationForm }, globalStore);
  }

  saveAddressField(address: string, addressDetails, geometryDetails) {
    if (!isNil(addressDetails) && !isNil(geometryDetails)) {
      const parsedAddress = parseAddress(
        address,
        addressDetails,
        geometryDetails
      );
      const streetAddress = parsedAddress.street_number === null ? parsedAddress.street : parsedAddress.street_number + " " + parsedAddress.street;
      this.updateField('address', streetAddress);
      this.updateField('streetNumber', parsedAddress.street_number);
      this.updateField('streetName', parsedAddress.street);
      this.updateField('city', parsedAddress.city);
      this.updateField('state', parsedAddress.state);
      this.updateField('zipCode', parsedAddress.zip);
      this.updateField('aptNumber', parsedAddress.aptNumber);
    } else {
      this.onFieldChange('address', address);
      this.onFieldChange('streetName', address);
    }
  }

  updateField(fieldName, parsedValue) {
    const value = isNil(parsedValue) ? '' : parsedValue;
    this.onFieldChange(fieldName, value);
  }

}