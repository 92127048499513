import { Grid, Typography } from '@material-ui/core';
import { StandardDialog, Button } from '@roc/ui';
import { ExpectedClosingDate } from './expectedClosingDate';
import { ExpectedClosingDateStore } from '../../../stores/documentForms/expectedClosingDateStore';

export type ExpectedClosingDateDialogProps = {
  open?: boolean;
  onClose?: () => void;
  loanId: string;
  store: ExpectedClosingDateStore;
};

export const ExpectedClosingDateDialog = (
  props: ExpectedClosingDateDialogProps
) => {
  const { open, onClose, loanId, store } = props;

  const onSubmit = () => {
    store.submitexpectedClosingDateState();
    if (!onClose) return;
    onClose();
  };

  return (
    <StandardDialog
      open={open}
      title="Expected Closing Date"
      maxWidth="sm"
      handleClose={onClose}
      dialogContent={
        <Grid container spacing={4}>
          <Grid item md={12}>
            <Typography>
              This will generate the offical numbers for the Funding Template and will schedule the loan for closing.
            </Typography>
          </Grid>
          <Grid item md={12}>
            <ExpectedClosingDate store={store} />
          </Grid>
        </Grid>}
      dialogActions={
        <Grid container spacing={1} justifyContent="flex-end">
          <Grid item>
            <Button
              onClick={onClose}
              color="primary"
              variant="outlined"
              testId="cancel"
            >
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={onSubmit}
              color="primary"
              variant="contained"
              testId="confirm"
              disabled={!store.shouldBeAbleToSubmitExpectedClosingDate()}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      }
    />
  );
};

export default ExpectedClosingDateDialog;
