import { CONDOMINIUM, LoanSubType, propertyTypesByLoanSubtype } from '@roc/feature-utils';
import { observer } from 'mobx-react';
import { Box, Grid } from '@material-ui/core';
import { Button } from '@roc/ui';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';

import {
  PropertyType,
  Unit,
  PropertyOwnership,
  PropertyAddress,
  ParcelNumber,
  MultipleLots,
  IsOriginalAddress,
  OriginalAddress,
  NumberOfLots,
} from '@roc/ui/formComponents';
import {
  PropertyPurchaseFields,
  PurchaseOtherFields,
  PurchaseRenovationFields,
} from './propertyFields/propertyPurchaseFields';
import {
  RefinanceOtherFields,
  RefinancePurchaseFields,
  RefinanceRenovationFields,
} from './propertyFields/propertyRefinanceFields';
import { Alert } from '@material-ui/lab';

export const PropertyForm = observer(
  ({ propertyStore, propertiesStore, loanSubtype }) => {
    const { propertyInformationStore, isPurchase } = propertyStore;
    const propertyTypeOptions = propertyTypesByLoanSubtype(loanSubtype);
    const isCondo =
      propertyInformationStore.form.fields.useCode.value === CONDOMINIUM;
    const ownershipValue = propertyInformationStore.form.fields.propertyOwnership.value;
    const multipleLots = propertyInformationStore.form.fields.multipleLots.value;
    const isOriginalAddress = propertyInformationStore.form.fields.isOriginalAddress.value;

    return (
      <>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={12}>
            <PropertyAddress
              key={'address-' + propertiesStore.editPropertyActiveIndex}
              store={propertyInformationStore}
              required
            />
          </Grid>
          {loanSubtype == LoanSubType.GROUND_UP &&
            <Grid item xs={12}>
              <ParcelNumber
                store={propertyInformationStore}
                disabled={false}
              />
            </Grid>
          }
          <Grid item xs={12}>
            <PropertyType
              store={propertyInformationStore}
              propertyTypeOptions={propertyTypeOptions}
              disabled={false}
            />
          </Grid>
          <Grid item xs={12}>
            <PropertyOwnership
              store={propertyInformationStore}
              disabled={false}
            />
          </Grid>
          {loanSubtype == LoanSubType.GROUND_UP && <>
            {ownershipValue == 'Purchase' &&
              <Grid item xs={12}>
                <MultipleLots
                  store={propertyInformationStore}
                  disabled={false}
                  loanSubType={loanSubtype}
                />
              </Grid>}
            {ownershipValue == 'Owned By Borrower' &&
              <Grid item xs={12}>
                <IsOriginalAddress
                  store={propertyInformationStore}
                  disabled={false}
                  loanSubType={loanSubtype}
                />
              </Grid>}
            {(isOriginalAddress === false && ownershipValue == 'Owned By Borrower') &&
              <Grid item xs={12}>
                <OriginalAddress
                  store={propertyInformationStore}
                  required
                  disabled={false}
                />
              </Grid>}
            {((multipleLots && ownershipValue == 'Purchase') || (isOriginalAddress === false && ownershipValue == 'Owned By Borrower')) &&
              <>
                <Grid item xs={12}>
                  <NumberOfLots
                    store={propertyInformationStore}
                    disabled={false}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Alert severity="info">Please make sure to click on "Add New Property" for each lot</Alert>
                </Grid>
              </>
            }
          </>}
          <Grid item xs={12}>
            {isCondo && (
              <Unit store={propertyInformationStore} disabled={false} />
            )}
          </Grid>
          {isPurchase ? (
            <>
              <PropertyPurchaseFields propertyStore={propertyStore}
                loanSubtype={loanSubtype}
              />
              <PurchaseRenovationFields
                propertyStore={propertyStore}
                loanSubtype={loanSubtype}
              />
              <PurchaseOtherFields propertyStore={propertyStore} />
            </>
          ) : (
            <>
              <RefinancePurchaseFields propertyStore={propertyStore} />
              <RefinanceRenovationFields
                propertyStore={propertyStore}
                loanSubtype={loanSubtype}
              />
              <RefinanceOtherFields propertyStore={propertyStore} />
            </>
          )}
        </Grid>
      </>
    );
  }
);
