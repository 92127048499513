import { Service } from '@roc/feature-app-core';
import { PlaidService } from './plaidService';

const url = {
  ACH_DOCUSIGN: '/api/v1/vendor/plaid/achDocusign',
  SAVE_BANK_INFORMATION: '/api/v1/vendor/plaid/saveBankInformation',
  GET_ACH_DATA_FOR_BORROWER_PORTAL:
    '/api/v1/vendor/plaid/getACHDataForBorrowerPortal',
};

export class PrivatePlaidService extends PlaidService {
  submitDocusign(request: any) {
    return this.post(`${url.ACH_DOCUSIGN}`, request, {
      'Content-Type': 'application/json',
    });
  }

  saveBankInformation(data: any) {
    return this.post(url.SAVE_BANK_INFORMATION, data);
  }

  getACHDataForBorrowerPortal(param) {
    return this.get(url.GET_ACH_DATA_FOR_BORROWER_PORTAL, { param });
  }
}
