import { action, computed, flow, makeObservable, observable } from 'mobx';
import { FormStore } from '@roc/feature-app-core';
import { GlobalStore } from '@roc/feature-app-core';
import { GENERIC_ERROR_MESSAGE } from '@roc/feature-utils';

const form = {
  fields: {
    email: {
      value: '',
      attribute: 'email Address',
      error: null,
      rule: 'required|email',
    },
    userId: {
      value: '',
      attribute: 'user Id',
      error: null,
    },
    firstName: {
      value: '',
      attribute: 'first name',
      error: null,
      rule: 'required',
    },

    lastName: {
      value: '',
      attribute: 'last name',
      error: null,
      rule: 'required',
    },
    phoneNumber: {
      value: '',
      attribute: 'phone number',
      error: null,
      rule: 'phone',
    },
    companyName: {
      value: '',
      attribute: 'lender name',
      error: null,
    },
    leadSource: {
      value: '',
      attribute: 'lead source',
      error: null,
      rule: 'required',
    },
    userDetails: {
      value: '',
      attribute: 'user details',
      error: null,
      rule: null,
    },
    ownerEmail: {
      value: '',
      attribute: 'owner email',
      error: 'This field is required',
      rule: [{ required_if: ['ownerRequired', true] }],
    },
    ownerRequired: {
      value: false,
      error: null,
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export interface Company {
  companyId: number;
  companyName: string;
}

export abstract class UserDetailsBaseStore extends FormStore {
  protected globalStore: GlobalStore;

  abstract getLeadMetaData(leadtype: string);
  abstract getCompanyNamesOptions();
  abstract getUserBusinessUnit();

  lenderOptions = [];
  companyNamesOptions = [];
  response: string;
  disabled: boolean;
  leadSourceOptions: [];
  recordTypes: {};
  selectedCompany: Company;
  businessUnit: string;
  sessionUser: string;

  constructor(globalStore) {
    super(form, globalStore);
    this.globalStore = globalStore;

    makeObservable(this, {
      response: observable,
      lenderOptions: observable,
      disabled: observable,
      fetchMetadata: flow,
      getLeadMetaData: observable,
      onCompanyFieldChange: flow,
      leadSourceOptions: observable,
      recordTypes: observable,
      companyNamesOptions: observable,
      getCompanyNamesOptions: observable,
      selectedCompany: observable,
      checkCompany: computed,
      getUserBusinessUnit: flow,
      businessUnit: observable,
      sessionUser: observable,
    });
    this.disabled = true;
  }

  resetValues() {
    this.disabled = true;
  }

  setDisabled = disabled => {
    this.disabled = disabled;
  }

  fetchCompanyNamesOptions() {
    try {
      this.getCompanyNamesOptions();
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'An error occurred while loading the companies options',
      });
    }
  }

  fetchMetadata(leadType: string) {
    try {
      this.getLeadMetaData(leadType);
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'An error ocurred while loading lead data options',
      });
    }
  }

  reset = () => {
    this.form.fields.firstName.value = '';
    this.form.fields.lastName.value = '';
    this.form.fields.phoneNumber.value = '';
    this.form.fields.companyName.value = '';
    this.form.fields.email.value = '';
    this.form.fields.leadSource.value = '';
    this.selectedCompany = {
      companyName: '',
      companyId: null
    };
    this.form.fields.userDetails.value = '';
    this.form.fields.ownerEmail.value = '';
    this.disabled = true;
  };

  getUserDetails() {
    const data = this.getFormValues();
    data.companyName = this.selectedCompany?.companyName;
    return data;
  }

  onCompanyFieldChange = (field, value) => {
    this.selectedCompany = null;
    this.onFieldChange(field, value);
  };

  setSelectedCompany = (company: Company) => {
    this.selectedCompany = company;
  };

  onSelectedCompany = (option: any) => {
    this.setSelectedCompany({
      companyId: option.value,
      companyName: option.value === -1 ? option.originalName : option.label,
    });
    this.onFieldChange('companyName', option.label);
  };

  get checkCompany() {
    return this.selectedCompany?.companyId === -1;
  }
}
