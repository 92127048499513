import { Service } from '@roc/feature-app-core';

const url = {
  UPDATE_LOAN_LENDER_DETAILS:
    '/api/v1/document/formDocument/updateLoanLenderDetails',
};

export class LenderFeesService extends Service {
  updateLoanLenderDetails = payload => {
    return this.post(url.UPDATE_LOAN_LENDER_DETAILS, payload);
  };
}
