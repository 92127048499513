import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { observer } from 'mobx-react';
import { DealEconomicsForm } from './components/dealEconomicsForm';
import { CostsTables } from './components/costsTables';
import { Snapshot } from './components/snapshot';
import { useDocumentStore } from '../../../hooks/useDocumentStore';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';



export const DealEconomics = observer(({ dealEconomicsEl }) => {
  const {
    documentStore: { documentFormStore },
  } = useDocumentStore();
  const useStyles = makeStyles(() =>
    createStyles({
      mainContainerForScreenShot: {
        '&.in-process *': {
          boxShadow: 'none',
        },
      },
    })
  );
  const { dealEconomicsStore } = documentFormStore;
  const classes = useStyles();

  useEffect(() => {
    if (!dealEconomicsStore) return;
    dealEconomicsStore.fetchDealEconomics();
    return () => {
      dealEconomicsStore.resetStore();
    };
  }, [dealEconomicsStore]);

  return (
    <Grid container spacing={3} className={classes.mainContainerForScreenShot} ref={dealEconomicsEl}>
      <Grid item>
        <DealEconomicsForm store={dealEconomicsStore} />
      </Grid>
      <Grid item>
        <CostsTables store={dealEconomicsStore} />
      </Grid>
      <Grid item container>
        <Snapshot store={dealEconomicsStore} />
      </Grid>
    </Grid>
  );
});

export default DealEconomics;
