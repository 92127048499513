import React, { ReactNode } from 'react';
import { Grid, GridSize, GridSpacing } from '@material-ui/core';

export const FieldContainer = ({ children, spacing }: { children: ReactNode, spacing?: GridSpacing }) => {
  return (
    <Grid
      container
      spacing={spacing ?? 4}
      justifyContent="space-around"
      style={{ margin: 0 }}
    >
      {Array.isArray(children) ? (
        children.map((c, i) => (
          <Grid key={`carousel-grid-item-${i}`} item xs={10}>
            {c}
          </Grid>
        ))
      ) : (
        <Grid item xs={10}>
          {children}
        </Grid>
      )}
    </Grid>
  );
};

export const FieldContainerNoSpacing = ({ children }) => (
  <FieldContainer spacing={1}>
    {children}
  </FieldContainer>
);

interface ColumnContainerProps {
  mdSize?: GridSize,
  children: any
}

export const ColumnContainer = ({ mdSize = 5, children }: ColumnContainerProps) => {
  return (
    <Grid
      container
      spacing={4}
      justifyContent="flex-start"
      style={{ margin: 0 }}
    >
      {Array.isArray(children) ? (
        children.map((c, i) => (
          <Grid key={`carousel-grid-item-${i}`} item sm={12} md={mdSize}>
            {c}
          </Grid>
        ))
      ) : (
        <Grid item sm={12} md={10}>
          {children}
        </Grid>
      )}
    </Grid>
  );
};
