import { Grid } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Layout } from '@roc/feature-app-core';
import { observer } from 'mobx-react';
import { ClosingTab } from './tabs/closingTab';

const useStyles = makeStyles((theme: Theme) => ({}));

export const ClosingInternal = observer(() => {
  const classes = useStyles();
  return (
    <Layout maxWidth="lg">
      <Grid container spacing={2}>
        <ClosingTab />
      </Grid>
    </Layout>
  );
});
