import { useStore } from '@roc/client-portal-shared/hooks';
import { observer } from 'mobx-react';
import { useEffect, useMemo, useState } from 'react';
import { InternalPricerBaseStore } from '../stores/internalPricerBaseStore';

interface Props {
  store: InternalPricerBaseStore;
}

export const InternalPricerFieldCalculations = observer((props: Props) => {
  const internalPricerStore = props.store;
  const { fields } = props.store.form;

  useEffect(() => {
    internalPricerStore.fetchMarketTier();
  }, [fields.county.value]);

  return null;
});
