import { GlobalStore, UserStore } from '@roc/feature-app-core';
import { GENERIC_ERROR_MESSAGE, LoanSubType, SegmentTrackerEvent, segmentAnalytics } from '@roc/feature-utils';
import { action, flow, makeObservable, observable } from 'mobx';
import { NewLoanApplicationService } from '../services/newLoanApplicationService';
import { LoanApplicationStep } from '../utils/loanApplicationConstants';
import { mapLoanApplicationPayload } from '../utils/loanApplicationUtils';
import { EntityStore } from './borrowerEntityFormStore';
import { BorrowersStore } from './borrowerFormStore';
import { PropertiesStore } from './propertiesStore';

export class NewLoanApplicationStore {
  private globalStore: GlobalStore;
  private newLoanApplicationService: NewLoanApplicationService;
  borrowerEntityFormStore: EntityStore;
  borrowerStore: BorrowersStore;
  propertyStore: PropertiesStore;
  userStore: UserStore;

  step: LoanApplicationStep;
  loanType: LoanSubType;
  loanApplicationModalOpen = false;

  newLoanId: number;

  constructor(globalStore: GlobalStore, userStore: UserStore) {
    this.globalStore = globalStore;
    this.newLoanApplicationService = new NewLoanApplicationService();
    this.borrowerStore = new BorrowersStore(globalStore);
    this.borrowerEntityFormStore = new EntityStore(
      globalStore,
      this.borrowerStore
    );
    this.propertyStore = new PropertiesStore(this.globalStore);
    this.userStore = userStore;

    makeObservable(this, {
      step: observable,
      loanType: observable,
      loanApplicationModalOpen: observable,
      newLoanId: observable,
      openNewLoanApplicationModal: action,
      closeNewLoanApplicationModal: action,
      setLoanType: action,
      reset: flow,
      submitLoanApplication: flow,
    });
  }

  openNewLoanApplicationModal() {
    this.reset();
    this.loanApplicationModalOpen = true;
  }

  closeNewLoanApplicationModal() {
    this.loanApplicationModalOpen = false;
  }

  startLoanApplication() {
    this.closeNewLoanApplicationModal();
    this.goToStep(LoanApplicationStep.ENTITY_INFORMATION);
  }

  goToStep(step: LoanApplicationStep) {
    this.step = step;
  }

  setLoanType(loanType: LoanSubType) {
    this.loanType = loanType;
  }

  *reset() {
    this.step = LoanApplicationStep.LOAN_TYPE;
    this.loanType = null;
    this.borrowerStore.reset();
    this.propertyStore.reset();
  }

  *submitLoanApplication() {
    try {
      let loanType = this.loanType;
      if(this.globalStore.userFeatures.enableFixFlipPro && this.loanType === LoanSubType.FIX_AND_FLIP){
        loanType = LoanSubType.FIX_AND_FLIP_PRO;
      }

      this.newLoanId = null;
      const data = mapLoanApplicationPayload(
        loanType,
        this.borrowerEntityFormStore.selectedBorrowerEntity,
        this.borrowerStore.borrowers,
        this.propertyStore.properties
      );
      const response = yield this.newLoanApplicationService.submitLoanApplication(
        data
      );
      this.newLoanId = response.data?.data?.loanId;
      this.goToStep(LoanApplicationStep.THANK_YOU);
      segmentAnalytics.trackEvent({
        name: SegmentTrackerEvent.LOAN_BORROWER_SUBMISSION,
        userName: this.userStore?.userInformation?.username
      });
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE,
      });
    }
  }
}
