import {
  Box,
  Checkbox,
  Grid,
  Typography,
  FormControlLabel,
  Link,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { debounce, originatorSubLabel } from '@roc/client-portal-shared/utils';
import {
  BorderColorOutlined,
  EmailOutlined,
  HelpOutlined,
  DescriptionOutlined,
} from '@material-ui/icons';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from '@material-ui/lab';
import { useRoutes, useStore } from '@roc/client-portal-shared/hooks';
import { isNotBlank } from '@roc/client-portal-shared/utils';
import {
  AlertDialog,
  Button,
  createRadioField,
  createSelectField,
  createTextField,
  Paper,
  TestDataAttribute,
  TextField,
} from '@roc/ui';
import { Layout } from '@roc/client-portal-shared/components';
import { observer } from 'mobx-react';
import { useEffect, useMemo } from 'react';
import { SelectBorrowerInput } from '@roc/feature-appraisals';
import { SelectBorrowerMode } from '@roc/feature-borrowers';
import { InputAdornment } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';

const useStyles = makeStyles(theme => ({
  orderFormCard: {
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(0),
    },
  },
  spacedTypography: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  link: {
    color: theme.palette.primary.dark,
  },
  button: {
    marginTop: theme.spacing(1),
  },
  searchIcon: {
    color: theme.palette.text.secondary,
  },
  autoCompleteContainer: {
    paddingTop: theme.spacing(2),
  }
}));

interface Option {
  label: string;
  value: string;
}

interface AutocompleteFieldProps {
  value: string;
  onChange: (text: string) => void;
  onSelect: (option: Option) => void;
  options: Option[];
}

const filter = createFilterOptions({
  matchFrom: 'any',
});

const AutocompleteField = observer((props: AutocompleteFieldProps) => {
  const classes = useStyles();
  const { value, onChange, onSelect, options } = props;

  const filterOptions = (options, params) => {
    const filtered = filter(options, params);
    return filtered;
  };

  const renderInput = params => (
    <TextField
      {...params}
      InputProps={{
        ...params.InputProps,
        endAdornment: undefined,
        startAdornment: (
          <InputAdornment position="start" className={classes.searchIcon}>
            <Search />
          </InputAdornment>
        ),
      }}
      testId="lender-autocomplete"
      onChange={e => onChange(e.target.value)}
      variant="outlined"
      placeholder="Select Lender"
      fullWidth
    />
  );

  return (
    <Autocomplete
      id="lender-autocomplete"
      selectOnFocus
      handleHomeEndKeys
      options={options}
      value={null}
      inputValue={value}
      blurOnSelect={true}
      onChange={(event, option: Option) => onSelect(option)}
      renderInput={renderInput}
      getOptionLabel={(option: Option) => option.label}
      getOptionSelected={(option: Option) => true}
      filterOptions={filterOptions}
      fullWidth
    />
  );
});


const useTimelineItemStyles = makeStyles(theme => ({
  missingOppositeContent: {
    '&::before': {
      display: 'none',
    },
  },
  content: {
    paddingBottom: theme.spacing(3),
  },
}));

const Email = observer(
  ({ store, disabled }: { store: any; disabled?: boolean }) => {
    const formFields = store.form.fields;
    return (
      <TextField
        testId="email"
        disabled={disabled}
        variant="outlined"
        standaloneLabel
        label=""
        value={formFields.email.value}
        onChange={e => store.onFieldChange('email', e.target.value)}
        error={isNotBlank(formFields.email.error)}
        helperText={formFields.email.error}
        fullWidth
        required
      />
    );
  }
);

const OrderFormCard = observer(({ store }) => {
  const classes = useStyles();
  const { globalStore } = useStore();
  const { loanParticipantsStore } = store;

  useEffect(() => {
    if(!store.isInternal){
      store.loadParticipants();
    }
  },[])

  const fetchOptions = useMemo(
    () => debounce(searchText => store.fetchLenders(searchText), 500),
    [store]
  );

  return (
    <Paper title="Order Form" className={classes.orderFormCard}>
      <Box p={2}>
        <Grid container justifyContent={'center'} spacing={2}>
          {!store.isInternal ? (
            <Grid item container spacing={2}>
              <Grid item xs={12}>
                Who will be receiving the authorization form?
              </Grid>
              <Grid item xs={12}>
                <SelectBorrowerInput
                  store={store}
                  modes={[SelectBorrowerMode.EXISTING]}
                  borrowerCardGridSize={12}
                  borrowerCardDisplayFields={{
                    firstName: true,
                    lastName: true,
                    emailAddress: true,
                  }}
                />
              </Grid>
              {loanParticipantsStore.allowOriginatorSelection && (
                <Grid item xs={12}>
                  {createSelectField({
                    store,
                    testId: 'originatorId',
                    fieldName: 'originatorId',
                    label: 'Originator',
                    subLabel: originatorSubLabel,
                    options: loanParticipantsStore.originatorOptions
                  })}
                </Grid>
              )}
            </Grid>
          ) : (
            <Grid item xs={12}>
              <Grid container alignItems="center">
                {createRadioField({
                  store,
                  testId: 'borrowerOrEmail',
                  fieldName: 'borrowerOrEmail',
                  label: 'Who will be receiving the authorization form?',
                  options: [
                    { label: 'Borrower Id', value: 'borrowerId' },
                    { label: 'Email', value: 'email' },
                  ],
                  row: true,
                })}
              </Grid>
              {store.form.fields.borrowerOrEmail.value === 'borrowerId' && (
                <Grid container alignItems="center">
                  <Grid item xs={12}>
                    {createTextField({
                      label: 'Borrower Id',
                      fieldName: 'borrowerId',
                      testId: 'borrowerId',
                      store: store,
                      standaloneLabel: true,
                    })}
                  </Grid>
                  <Grid item xs={12} className={classes.autoCompleteContainer}>
                    <AutocompleteField
                      value={store.searchText}
                      options={store.lenders}
                      onChange={text => {
                        store.searchText = text;
                        fetchOptions(store.searchText);
                      }}
                      onSelect={opt => {
                        store.searchText = opt.label;
                        store.selectedLender = opt;
                      }}
                    />
                  </Grid>
                </Grid>
              )}
              {store.form.fields.borrowerOrEmail.value === 'email' && (
                <Grid container alignItems="center">
                  <Grid item xs={12}>
                    {createTextField({
                      label: 'Email Address',
                      fieldName: 'email',
                      testId: 'email',
                      store: store,
                      standaloneLabel: true,
                      type: 'email',
                    })}
                  </Grid>
                  <Grid item xs={12} className={classes.autoCompleteContainer}>
                    <AutocompleteField
                      value={store.searchText}
                      options={store.lenders}
                      onChange={text => {
                        store.searchText = text;
                        fetchOptions(store.searchText);
                      }}
                      onSelect={opt => {
                        store.searchText = opt.label;
                        store.selectedLender = opt;
                      }}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
          )}
          <Grid item xs={12}>
            <Button
              variant="contained"
              size="large"
              onClick={() => store.submitLiquidityVerificationRequest()}
              color="primary"
              testId="request_liquidity_verification"
              disabled={!store.isValidForm}
              className={classes.button}
            >
              Request Liquidity Verification
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
});

const flowData = [
  {
    title: 'Email',
    body:
      'An automated email from the Lender has been sent to your borrower',
    icon: <EmailOutlined />,
  },
  {
    title: 'Authorization',
    body:
      'Through Plaid the borrower links their bank accounts and grants authorization for the lender to receive consolidated liquidity reports',
    icon: <BorderColorOutlined />,
  },
  {
    title: 'Reports',
    body: 'A Plaid liquidity report that is delivered to portal and linked to active loans for the borrower to satisfy liquidity requirements in lieu of providing bank statements',
    icon: <DescriptionOutlined />,
  },
  {
    title: 'Questions',
    body:
      <p>Please visit <Link href={`https://plaid.com/safety/`} target="_blank" rel="noopener">here</Link> if there are any questions regarding Plaid</p>,
    icon: <HelpOutlined />,
  },
];

const ProcessFlowCard = () => {
  const classes = useTimelineItemStyles();
  return (
    <Paper title="What happens next?">
      <Box p={2} pb={3}>
        <Timeline>
          {flowData.map((x, index) => {
            return (
              <TimelineItem key={x.title} classes={classes}>
                <TimelineSeparator>
                  <TimelineDot color="primary">{x.icon}</TimelineDot>
                  {index != flowData.length - 1 && <TimelineConnector />}
                </TimelineSeparator>
                <TimelineContent>
                  <Typography variant="h6" component="h6">
                    {x.title}
                  </Typography>
                  <Typography>{x.body}</Typography>
                </TimelineContent>
              </TimelineItem>
            );
          })}
        </Timeline>
      </Box>
    </Paper>
  );
};

export const PlaidLiquidityVerification = observer(({ store }) => {
  const { creditBackgroundRoutesConfig } = useRoutes();
  const classes = useStyles();
  useEffect(() => {
    store.reset();
    if (store.isInternal) {
      store.getAllLenders();
    }
  }, []);
  return (
    <Layout title="Plaid Liquidity Verification" maxWidth={'md'}>
      <Grid container>
        <Grid item md={6} lg={6} xs={12}>
          <OrderFormCard store={store} />
        </Grid>
        <Grid item md={6} lg={6} xs={12}>
          <ProcessFlowCard />
        </Grid>
      </Grid>
    </Layout>
  );
});
