import { useState, useEffect } from 'react';
import {
  Box,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography
} from '@material-ui/core';
import { darken } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Paper,
  SubmitButton,
  TestDataAttribute,
  StandardDialog,
} from '@roc/ui';
import { formatCurrency } from '@roc/ui/utils';
import { useHistory } from 'react-router';
import {
  EntityCard,
  StatusChip,
  StatusType,
  Layout,
} from '@roc/client-portal-shared/components';
import { SubdirectoryArrowRight } from '@material-ui/icons';
import { useRoutes } from '@roc/client-portal-shared/hooks';
import {
  EditBorrowersForm,
  CreateAppraisalForm,
  useLoanStore,
  StabilizedBridgePropertiesGrid,
} from '@roc/feature-loans';
import { sanitizeTestId, isFundedOrAgreedLoan, isFundedLoan, isPreagreedLoanStatus, LoanStatus, isNil } from '@roc/client-portal-shared/utils';
import { BooleanChip } from './components/loanSummaryComponents';
import { useBaseStore, useUserStore } from '@roc/feature-app-core';
import { LoanParticipants } from 'libs/feature-loans/src/loanSubmission/components/loanParticipants';
import { mapFundingEntity } from './components/loanSummaryHelper';
import { EditLoanButton, LoanStatusChangeReasonBanner, LoanSummaryBrokerSection, LoanSummaryLoanParticipantsSection } from '@roc/feature-loans-shared';
import { LoanPrescreenBanner } from '@roc/feature-loans-shared';
import { EditSubmissionButton } from '@roc/feature-edit-loans';

const useStyles = makeStyles(theme => ({
  paper: {
    marginBottom: theme.spacing(2),
  },
  card: {
    background: theme.palette.background.default,
    borderColor: darken(theme.palette.background.default, 0.1),
  },
}));

const formatDigit = (value, digits) => value?.toFixed(3, digits);

const listItem = (label, value, icon = null, customTestId = null) => {
  const testId = sanitizeTestId(customTestId || label);
  return (
    <ListItem>
      <ListItemText>
        <Box display="flex">
          {icon && <Box ml={1}>{icon}</Box>}
          <Box flexGrow={1}>{label}</Box>
          <TestDataAttribute id={testId}>
            <Box>{value}</Box>
          </TestDataAttribute>
        </Box>
      </ListItemText>
    </ListItem>
  );
};

const divider = <Divider variant="middle" component="li" />;

export const LoanSummaryStabilizedBridge = ({ data }) => {
  const classes = useStyles();
  const {
    loanId,
    amount,
    rate,
    status,
    loanType,
    loanSubType,
    borrowerNames,
    borrowerEntity,
    submissionDate,
    expectedClosingDate,
    agreedDate,
    closingDate,
    payoffDate,
    maturityDate,
    attorneyName,
    aggregateLoanToCurrentCostInitial,
    minNumber,
    aggregateLoanToFutureCost,
    fullPrincipalInterest,
    pointsIn,
    interestReserve,
    brokerPointsIn,
    prepayPenaltyMonths,
    fullPrincipalInterestTime,
    replacementCost,
    source,
    highHazardFloodZone,
    rocRate,
    rocPointsIn,
    rocPointsOut,
    origLenderSubordination,
    buybackSkimReduction,
    sellAs,
    fundingPreference,
    pledgeOfShares,
    servicer,
    propertyCount,
    mostRecentValuation,
    totalCostBasis,
    annualGrossRent,
    pitia,
    pitiaDSCR,
    pitiaExitDSCR,
    totalDebtPayoffAmount,
    loanPurpose,
    lenderOriginatorNames,
    preAgreed,
    lenderName,
    underwritingFee,
    calculatedLoanData,
    servicerId,
    fullySubmitted,
    freeAppraisalPromotion,
    firstServicerPaymentDueDate,
    referralFeePct
  } = data;

  const { push } = useHistory();

  const { loanRoutesConfig } = useRoutes();
  const { globalStore } = useBaseStore();
  const { loanStore } = useLoanStore();
  const { userStore } = useUserStore();
  const [openCreateAppraisalModal, setOpenCreateAppraisalModal] = useState(
    false
  );

  var flag = false;
  var percentageLo
  lenderOriginatorNames.map((lender, i) => { percentageLo = lender.percentage, flag = true });
  console.log(percentageLo);

  const isCreateAppraisalOrderAllowed = () =>
    !!loanStore.loanDetails?.enableAppraisalCreation && !loanStore.loanDetails?.submitAppraisalWithTamarisk && !loanStore.loanDetails?.arrEnabled;

  const isFundedOrPaidoffOrAgreedLoan = isFundedOrAgreedLoan(status);
  const showLoanExpirationDates = !isFundedLoan(status);
  const allowLoanPricing = userStore.allowLoanPricing;
  const isLoanSubmissionEditable = loanStore.loanDetails?.status === LoanStatus.PENDING_SUBMISSION || loanStore.loanDetails?.status === LoanStatus.PENDING

  useEffect(() => {
    loanStore.setPropertiesData(data.properties);
    loanStore.propertiesGridStore.fetchGridData();
  }, []);

  return (
    <Grid container spacing={2}>
      <LoanPrescreenBanner loanStore={loanStore} reloadLoan={() => loanStore.refreshLoanDetails()} />
      <LoanStatusChangeReasonBanner loan={data} />
      <Grid item container justifyContent="flex-end" spacing={1}>
        {isCreateAppraisalOrderAllowed() && (
          <Grid item>
            <Box display="flex" justifyContent="flex-end">
              <Button
                variant="contained"
                size="small"
                onClick={() => setOpenCreateAppraisalModal(true)}
                color="primary"
                testId="create_appraisal_order"
              >
                Create Appraisal Order
              </Button>
            </Box>
          </Grid>
        )}
        {allowLoanPricing && fullySubmitted && isLoanSubmissionEditable &&
          <Grid item>
            <EditSubmissionButton loanStore={loanStore} loanId={loanId} />
          </Grid>
        }
      </Grid>
      <Grid item xs={12} lg={6}>
        <Paper title="LOAN TERMS" className={classes.paper}>
          {userStore.isBackOfficeUser && isPreagreedLoanStatus(status) &&
            <EditLoanButton loanStore={loanStore} data={data} />
          }
          <Box>
            <List>
              {listItem('Loan Type', loanType)}
              {divider}
              {listItem('Loan SubType', loanSubType)}
              {divider}
              {fullySubmitted && listItem(
                'Loan Status',
                <StatusChip
                  statusType={StatusType.LOAN}
                  label={status}
                  variant="filled"
                />
              )}
              {divider}
              {listItem('Loan Purpose', loanPurpose)}
              {divider}
              {listItem('Loan Amount', formatCurrency(amount))}
              {listItem('Initial LTC', `${aggregateLoanToCurrentCostInitial}%`)}
              {divider}
              {listItem('Attorney', attorneyName)}
              {divider}
              {isFundedOrPaidoffOrAgreedLoan &&
                listItem('Servicer', servicer)}
              {isFundedOrPaidoffOrAgreedLoan && listItem(
                'Servicer Id',
                <Typography variant="body2" color="textSecondary" component="p">
                  {servicerId}
                </Typography>,
                <Box flexGrow={1}>
                  <SubdirectoryArrowRight fontSize="small" />
                </Box>
              )
              }
              {isFundedOrPaidoffOrAgreedLoan && listItem(
                'First Payment Date',
                <Typography variant="body2" color="textSecondary" component="p">
                  {firstServicerPaymentDueDate}
                </Typography>,
                <Box flexGrow={1}>
                  <SubdirectoryArrowRight fontSize="small" />
                </Box>
              )
              }
              {isFundedOrPaidoffOrAgreedLoan && divider}
              {listItem('Total LTFC', `${aggregateLoanToFutureCost}%`)}
              {divider}
              {listItem('MERS MIN Number', minNumber)}
              {divider}
              {listItem('Free Appraisal Promotion',
                !isNil(freeAppraisalPromotion) && (
                  <BooleanChip value={freeAppraisalPromotion} />
                ))}
              {divider}
              {listItem(
                'Full Princ Interest',
                <BooleanChip value={fullPrincipalInterest} />
              )}
              {divider}
              {allowLoanPricing && listItem('Interest Rate', `${rate}%`)}
              {allowLoanPricing && divider}
              {listItem('FPI Time', fullPrincipalInterestTime)}
              {divider}
              {allowLoanPricing && listItem('Points', pointsIn)}
              {allowLoanPricing && divider}
              {allowLoanPricing &&
                (referralFeePct && referralFeePct > 0
                  ? listItem('Off Hud Broker Fee', referralFeePct)
                  : listItem('On Hud Broker Fee', brokerPointsIn)
                )
              }
              {allowLoanPricing && divider}
              {listItem('Interest Reserve', interestReserve)}
              {divider}
              {listItem('Pre Penalty (Months)', prepayPenaltyMonths)}
            </List>
          </Box>
        </Paper>
        <Paper title="KEY DATES" className={classes.paper}>
          <Box>
            <List>
              {listItem('Submission', submissionDate)}
              {divider}
              {listItem('Exp. Closing', expectedClosingDate)}
              {divider}
              {listItem('Agreed Date', agreedDate)}
              {divider}
              {listItem('Closing Date', closingDate)}
              {divider}
              {listItem('PayOff Date', payoffDate)}
              {divider}
              {listItem('Maturity', maturityDate)}
              {showLoanExpirationDates && (
                <>
                  {divider}
                  {listItem('COGS Expiration Date', loanStore?.cogsExpirationDate)}
                  {divider}
                  {listItem('Credit Expiration Date', loanStore?.creditExpirationDate)}
                  {divider}
                  {listItem('Appraisal Expiration Date', loanStore?.appraisalExpirationDate)}
                </>
              )}
              {listItem('Next Due Date', calculatedLoanData.nextDueDate)}
            </List>
          </Box>
        </Paper>
      </Grid>
      <Grid item xs={12} md={6}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <LoanSummaryLoanParticipantsSection loanData={data} loanStore={loanStore} disableEditButton={globalStore.userFeatures?.isLenderOriginator} />
          </Grid>
          <Grid item xs={12}>
            <Paper title="TERMS" className={classes.paper}>
              <Box>
                <List>
                  {allowLoanPricing &&
                    listItem('Capital Provider Int Rate', `${rocRate}%`)
                  }
                  {allowLoanPricing && divider}
                  {allowLoanPricing &&
                    listItem(
                      'Capital Provider Points (I|O)',
                      rocPointsIn || rocPointsOut
                        ? `${rocPointsIn ?? ''} | ${rocPointsOut ?? ''}`
                        : null
                    )}
                  {allowLoanPricing && divider}
                  {allowLoanPricing && listItem(
                    'Capital Provider Underwriting Fee',
                    formatCurrency(underwritingFee ?? 0)
                  )}
                  {allowLoanPricing && divider}
                  {listItem('Subordination', `${origLenderSubordination}%`)}
                  {divider}
                  {listItem('Buy. Skim Red.', buybackSkimReduction)}
                  {divider}
                  {allowLoanPricing && listItem('Participation', sellAs)}
                  {allowLoanPricing && divider}
                  {listItem('Funding Entity', mapFundingEntity(fundingPreference))}
                  {divider}
                  {listItem(
                    'Pledge Of Shares',
                    <BooleanChip value={pledgeOfShares} />
                  )}
                </List>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={6}>
        <Paper title="GENERAL STATS" className={classes.paper}>
          <Box>
            <List>
              {listItem('Property Count', propertyCount)}
              {divider}
              {listItem(
                'Most Recent Valuation',
                formatCurrency(mostRecentValuation)
              )}
              {divider}
              {listItem('Total Cost Basis', formatCurrency(totalCostBasis))}
              {divider}
              {listItem(
                'Total Debt Payoff Amount',
                formatCurrency(totalDebtPayoffAmount)
              )}
              {divider}
              {listItem('Annual Gross Rent', formatCurrency(annualGrossRent))}
              {divider}
              {listItem('PITIA', formatCurrency(pitia))}
              {divider}
              {listItem('In place PITIA DSCR', formatDigit(pitiaDSCR, 3))}
              {divider}
              {listItem('Exit DSCR', formatDigit(pitiaExitDSCR, 3))}
            </List>
          </Box>
        </Paper>
      </Grid>
      <Grid item xs={12} md={6}>
        <Paper title="INSURANCE" className={classes.paper}>
          <Box>
            <List>
              {listItem('Replacement Cost', replacementCost)}
              {divider}
              {listItem('Source', source)}
              {divider}
              {listItem(
                'Is there a property in a high hazard flood zone?',
                <BooleanChip value={calculatedLoanData.floodZone === 'Yes' ? true : false} />
              )}
            </List>
          </Box>
        </Paper>
        <LoanSummaryBrokerSection loanData={data} />
      </Grid>
      {globalStore.userFeatures?.addFacoOriginators && (
        <Grid item xs={12} md={6}>
          <Paper title="ORIGINATORS" className={classes.paper}>
            <Grid item>
              <Box>
                <List>
                  {listItem(
                    lenderName,
                    'Percentage: ' +
                    (flag ? 100 - percentageLo : 100) +
                    '%',
                    <Box flexGrow={1}>
                      <SubdirectoryArrowRight fontSize="small" />
                    </Box>
                  )}
                  {divider}
                  {lenderOriginatorNames.map((lender, i) => {
                    return (
                      <div key={lender}>
                        {listItem(
                          lender.lenderName,
                          'Percentage: ' + lender.percentage + '%',
                          <Box flexGrow={1}>
                            <SubdirectoryArrowRight fontSize="small" />
                          </Box>
                        )}
                        {divider}
                      </div>
                    );
                  })}
                  <br />
                  <Box mr={2} display="flex" justifyContent="flex-end">
                    <SubmitButton
                      hidden={false}
                      disabled={!preAgreed}
                      size="small"
                      variant="contained"
                      color="secondary"
                      testId="editLenderOriginator"
                      onCancelClick={() => {
                        loanStore.originatorStore.selectOriginatorsStore.reset();
                        loanStore.setExistingOriginatorsPercentage();
                      }}
                      onClick={() => loanStore.saveLenderOriginator()}
                      submitDialogProps={{
                        title: 'Edit Lender Originators',
                        body:
                          (loanStore.setExistingOriginatorsPercentage(),
                            (
                              <LoanParticipants store={loanStore.selectOriginatorsStore} />
                            )),
                        okButtonText: 'Submit',
                      }}
                    >
                      Edit
                    </SubmitButton>
                  </Box>
                </List>
              </Box>
            </Grid>
          </Paper>
        </Grid>
      )}
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Grid container spacing={2}>
            <TestDataAttribute id="entity">
              <Grid item xs={12} lg={6}>
                <EntityCard title="ENTITY" entity={borrowerEntity} />
              </Grid>
            </TestDataAttribute>
            <Grid item xs={12} lg={6}>
              <Paper title="BORROWERS" style={{ boxShadow: 'none' }}>
                <Box>
                  <List>
                    {borrowerNames.map((name, i) => {
                      return (
                        <div key={name}>
                          {listItem(null, name, '', 'borrower')}
                          {divider}
                        </div>
                      );
                    })}
                    <br />
                    <Grid container justifyContent="flex-end" spacing={2}>
                      <Grid item>
                        <Button
                          size="small"
                          variant="contained"
                          color="secondary"
                          onClick={() =>
                            push(
                              loanRoutesConfig.loans(loanId.toString()).children
                                .borrowerEntity.url
                            )
                          }
                          testId="moreBorrowerInformation"
                        >
                          More information
                        </Button>
                      </Grid>
                      <Grid item>
                        {loanStore.allowEditBorrowers &&
                          <Box mr={2}>
                            <SubmitButton
                              size="small"
                              variant="contained"
                              color="secondary"
                              testId="editBorrowers"
                              onCancelClick={() => {
                                loanStore.selectBorrowersStore.reset();
                                loanStore.setExistingBorrowers();
                              }}
                              onClick={() => loanStore.saveBorrowers()}
                              submitDialogProps={{
                                title: 'Edit Borrowers',
                                body: <EditBorrowersForm store={loanStore} />,
                                okButtonText: 'Submit',
                              }}
                            >
                              Edit
                            </SubmitButton>
                          </Box>
                        }
                      </Grid>
                    </Grid>
                  </List>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12} lg={12}>
        <Paper title="PROPERTIES" className={classes.paper}>
          <Layout>
            <TestDataAttribute id="property">
              <StabilizedBridgePropertiesGrid />
            </TestDataAttribute>
          </Layout>
        </Paper>
      </Grid>
      <StandardDialog
        open={openCreateAppraisalModal}
        title="Create Appraisal Order"
        maxWidth="md"
        handleClose={() => setOpenCreateAppraisalModal(false)}
        dialogContent={
          <CreateAppraisalForm
            onConfirm={(gfdPayment: boolean) => {
              setOpenCreateAppraisalModal(false);
              loanStore.submitLoanAppraisalOrder(loanId, gfdPayment);
            }}
            onClose={() => setOpenCreateAppraisalModal(false)}
          />
        }
      />
    </Grid>
  );
};
