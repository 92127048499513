import { Grid } from '@material-ui/core';
import { yesNoOptions } from '@roc/feature-utils';
import { Button, RadioField } from '@roc/ui';
import { useDocumentStore } from '@roc/feature-documents';
import { observer } from 'mobx-react';
import React from 'react';

export type FloodZoneFormProps = {
  onClose: () => void;
  onConfirm: () => void;
};

export const FloodZoneForm = observer((props: FloodZoneFormProps) => {
  const { onClose, onConfirm } = props;
  const {
    documentStore: { documentFormStore },
  } = useDocumentStore();
  const { insuranceApprovalStore } = documentFormStore;
  const {
    canSubmitInsurance,
    floodZoneFormStore,
    canApprove,
  } = insuranceApprovalStore;
  const { floodZoneCheck, floodZoneCheckDisabled } = floodZoneFormStore;

  const handleFloodZoneCheckChange = value => {
    floodZoneFormStore.setFloodZoneCheck(value);
  };

  return (
    <Grid container spacing={5}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <RadioField
              standaloneLabel
              label="Is there a property in a high hazard flood zone?"
              name="floodZoneCheck"
              value={floodZoneCheck}
              options={yesNoOptions}
              onChange={handleFloodZoneCheckChange}
              fullWidth
              row
              testId="floodZoneCheck"
              disabled={!canApprove || floodZoneCheckDisabled}
            />
          </Grid>
        </Grid>
      </Grid>
      {canSubmitInsurance && (
        <Grid item xs={12}>
          <Grid container spacing={1} justifyContent="flex-end">
            <Grid item>
              <Button
                onClick={onClose}
                variant="outlined"
                color="primary"
                testId="close"
              >
                Close
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={onConfirm}
                variant="contained"
                color="primary"
                testId="save"
                disabled={!canApprove || floodZoneCheckDisabled}
              >
                Confirm Status Selection
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
});

export default FloodZoneForm;
