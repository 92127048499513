import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';

import { useParams } from 'react-router';
import { useStore } from '../../hooks';
import { ScopeOfWorkV2Form, decodeParams } from '@roc/feature-sow-v2';
import { ScopeOfWorkV2FormParams } from '@roc/feature-sow-shared/types';

export type EncodedParams = {
  encodedParams: string;
};

export const ScopeOfWorkV2 = observer(() => {
  const { scopeOfWorkV2Store } = useStore();

  const { encodedParams } = useParams<EncodedParams>();
  const [queryParams] = useState<ScopeOfWorkV2FormParams>(
    decodeParams(encodedParams)
  );

  useEffect(() => {
    if (queryParams) {
      scopeOfWorkV2Store.reset();
      scopeOfWorkV2Store.setParams(queryParams);
    }
  }, [scopeOfWorkV2Store, queryParams]);

  useEffect(() => {
    scrollTo(0, 0);
  }, [scopeOfWorkV2Store.activeStep]);

  return <ScopeOfWorkV2Form store={scopeOfWorkV2Store} />;
});

export default ScopeOfWorkV2;
