import { Chip, Grid, Link, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Button, FileUploadModal, PDFPreview } from '@roc/ui';
import { TrackRecordTable } from '../trackRecordTable';
import { useState } from 'react';

const useStyles = makeStyles((theme: Theme) => {
  return {
    actionButton: {
      marginLeft: '1rem',
    },
  };
});

export const TrackRecordEntities = observer(({ store }) => {
  const classes = useStyles();

  const [uploadDocument, setUploadDocument] = useState(false);
  const [currentRowId, setCurrentRowId] = useState(null);
  const [currentRowType, setCurrentRowType] = useState(null);

  const trackRecordEntitiesHeaders = [
    {
      name: 'Name',
      width: '50%'
    },
    {
      name: 'State',
      width: '42%'
    },
  ];

  const openUploadDocument = async (id, type) => {
    setCurrentRowId(id);
    setCurrentRowType(type);
    setUploadDocument(true);
  }

  const handleUploadDocument = async (id, files, type) => {
    const taskEntityBody = {
      sectionProcess: 'TRACK_RECORD_REVIEW',
      objectType: 'BORROWER_ENTITY_BORROWER',
      taskTemplateType: 'BORROWER_ENTITY_BORROWER',
      objectId: id,
    };

    store.createTaskWithFile(taskEntityBody, files[0]);
  }

  const previewDocument = (id, name, fileExtension) => {
    store.downloadTaskFile(id, name, fileExtension);
  }

  const trackRecordEntitiesRows = store.entities?.reduce((rows, entity, currentIndex) => {
    rows.push({
      id: entity.borrowerEntityBorrowerId,
      object: entity,
      type: 'entity',
      cells: [
        entity.entityName,
        entity.entityState,
      ],
      expandableContent: (
        <Grid container>
          <Grid item xs={12} style={{ marginBottom: '1rem' }}>
            <Typography variant="subtitle1">
              <strong>Documents</strong>
            </Typography>
            <Grid style={{ display: 'flex', flexDirection: "column" }}>
              {store.currentTasks && store.currentTasks.length > 0 ?
                store.currentTasks.map(task => {
                  if (task.originalFileName) {
                    return (
                      <Link key={task.taskId} onClick={() => previewDocument(task.taskId, task.originalFileName, task.fileExtension)}>{task.originalFileName}</Link>
                    )
                  }
                })
                : 'No Records'
              }
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            container
            justifyContent="flex-end"
            style={{ display: 'flex', marginBottom: 10 }}
          >
            {entity.entityVerified === 'No' && entity.isEntityPresentInLoan !== 'true' ? <Button
              variant="outlined"
              color="primary"
              onClick={() => store.handleRemoveTrackRecordEntity(entity)}
              testId='removeEntity'
              className={classes.actionButton}
              size='small'
            >
              Remove Entity
            </Button> : ''}
            <Button
              variant="outlined"
              color="primary"
              onClick={() => store.handleEditExistingTrackRecordEntity(entity, currentIndex)}
              testId='editEntity'
              className={classes.actionButton}
              size='small'
            >
              Edit Entity
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => openUploadDocument(entity.borrowerEntityBorrowerId, 'entity')}
              testId='uploadDocument'
              className={classes.actionButton}
              size='small'
            >
              Upload Document
            </Button>
          </Grid>
        </Grid>
      ),
    });
    return rows;
  }, []);

  return (
    <Grid container direction='row' spacing={2}>
      <Grid item xs={12} >
        <TrackRecordTable
          isExpandable={true}
          store={store}
          headers={trackRecordEntitiesHeaders}
          rows={trackRecordEntitiesRows ?? []}
          enablePagination={store.entities.length > 5}
        />
      </Grid>
      <FileUploadModal
        open={uploadDocument}
        title={`Upload Document`}
        onClose={() => setUploadDocument(false)}
        onUpload={(files) => handleUploadDocument(currentRowId, files, currentRowType)}
      />
      <PDFPreview
        open={store.isPdfPreviewModalOpen}
        title={store.pdfTitle}
        data={store.pdfData}
        headers={{}}
        fileName={store.pdfTitle}
        onClose={() => {
          store.pdfTitle = null;
          store.pdfData = null;
          store.isPdfPreviewModalOpen = false;
        }}
      />
    </Grid>
  );
});