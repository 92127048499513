import { Box } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { useBorrowerPortalMediaQueries, useSearchBarStore } from '@roc/feature-app-core';
import { Button, TextField } from '@roc/ui';
import React from 'react';
import { useHistory } from 'react-router';
import { Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Autocomplete } from '@material-ui/lab';
import { debounce } from '@roc/feature-utils';
import { observer } from 'mobx-react';
import { useEffect, useMemo } from 'react';
import { useRoutes, useStore } from '@roc/client-portal-shared/hooks';
import { useNewLeadStore } from '../../hooks/useNewLeadStore';

const useStyles = makeStyles(theme =>
  createStyles({
    //Search option
    loanId: {
      fontWeight: 'bold',
    },
    lenderName: {
      color: '#5f6368',
      fontSize: '12px',
    },
    dealName: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  })
);

enum SearchType {
  LOANS = 'LOANS',
  CRM = 'CRM',
}

export const GlobalSearchDrawer = () => {
  const [searchBy, setSearchBy] = React.useState(SearchType.CRM);
  const { isSmallScreen } = useBorrowerPortalMediaQueries();

  const handleChange = (event, newSearchBy) => {
    setSearchBy(newSearchBy);
  };

  return (
    <div>
      <Box px={isSmallScreen ? 1 : 8} py={4} maxWidth={600} margin={'auto'} textAlign={'center'}>
        <Box mb={4}>
          <Box mr={1} pb={1}>
            <strong>Search By</strong>
          </Box>
          <Box component={'span'}>
            <ToggleButtonGroup
              size="medium"
              value={searchBy}
              exclusive
              onChange={handleChange}
            >
              <ToggleButton color="secondary" value={SearchType.CRM}>
                CRM
              </ToggleButton>
              <ToggleButton color="secondary" value={SearchType.LOANS}>
                Loans
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
        </Box>
        <Box display={'flex'}>
          {searchBy === SearchType.LOANS ? (
            <Box flexGrow={1}>
              <SearchBar />
            </Box>
          ) : (
            <LeadsSearchBar />
          )}
        </Box>
      </Box>
    </div>
  );
};

const SearchBar = observer(() => {
  const classes = useStyles();
  const { searchBarStore } = useSearchBarStore();
  const { push } = useHistory();
  const { loanRoutesConfig, newLeadRoutesConfig } = useRoutes();
  useEffect(() => {
    searchBarStore.reset();
  }, []);

  const search = useMemo(
    () =>
      debounce(value => {
        if (value.length >= 3) {
          searchBarStore.searchLoans(value);
        } else {
          searchBarStore.reset();
        }
      }, 500),
    []
  );

  const onSelect = async selectedOption => {
    if (selectedOption?.loanId) {
      const loanDashboardUrl = loanRoutesConfig.loans(selectedOption.loanId).url;
      searchBarStore.setShowSearch(false);
      push(loanDashboardUrl);
    }
  };

  return (
    <Autocomplete
      fullWidth
      id="menu-appbar"
      options={searchBarStore.searchResults}
      componentName="menu-appbar"
      renderInput={options => (
        <TextField
          testId="search"
          {...options}
          autoFocus
          fullWidth
          InputProps={{
            ...options.InputProps,
            endAdornment: <></>,
          }}
          helperText="Performs a 'contains' search"
        />
      )}
      renderOption={option => <SearchOption option={option} />}
      blurOnSelect={true}
      getOptionLabel={(option: any) => option.loanId.toString()}
      loading={searchBarStore.loading}
      filterOptions={options => options}
      autoSelect={false}
      onChange={(e, value) => {
        onSelect(value);
      }}
      onInputChange={(e, value) => search(value)}
    />
  );
});

const SearchOption = ({ option }) => {
  const classes = useStyles();
  return (
    <Box overflow={'hidden'} width={'100%'} p={1}>
      <Box flex={1}>
        <Typography className={classes.dealName}>
          <b>{option.loanId}</b> - {option.dealName}
        </Typography>
      </Box>
      <Typography className={classes.lenderName}>{option.lender}</Typography>
    </Box>
  );
};

const LeadsSearchBar = observer(() => {

  const { newLeadStore } = useNewLeadStore();
  const { globalStore } = useStore();
  const { possibleMatchesConfig, newLeadRoutesConfig } = useRoutes();
  const { userDetailStore } = newLeadStore;
  const { form } = userDetailStore;
  const { userDetails } = form.fields;
  const { push } = useHistory();
  const { searchBarStore } = useSearchBarStore();

  const setDetails = (userDetails) => {
    newLeadStore.emailMatch = true;
    newLeadStore.emailMatchMsj = '';
    userDetailStore.onFieldChange('userDetails', userDetails);
  }

  const searchDetails = async () => {
    const userInput = userDetails.value.trim();
    if (userInput === '' || userInput.length < 3) {
      newLeadStore.setRenderPossibleMatches(false);
      globalStore.notificationStore.showWarningNotification({
        message: 'Please provide at least 3 characters to search',
      });
      return;
    }

    cleanDetails();

    if (containsOnlyDigits(userInput)) {
      const cleanNumber = getCleanNumber(userInput);
      if (cleanNumber.length !== 10) {
        newLeadStore.setRenderPossibleMatches(false);
        globalStore.notificationStore.showWarningNotification({
          message: 'If you are searching for a phone number, please provide 10 digits',
        });
        return;
      }
      userDetailStore.onFieldChange('phoneNumber', cleanNumber);
    } else if (userInput.includes('@') && isEmailValidFormat(userInput)) {
      userDetailStore.onFieldChange('email', userInput);
    } else {
      const splittedText = userInput.split(/\s+/);
      userDetailStore.onFieldChange('firstName', splittedText[0]);
      userDetailStore.onFieldChange('lastName', splittedText[1]);
    }
    await newLeadStore.searchUserDetails();
  };

  function getCleanNumber(str) {
    const cleanNumber = str.replace(/[\s\+\(\)-]/g, '');
    return cleanNumber;
  }

  function containsOnlyDigits(str) {
    return /^[\d\s\(\)-]+$/.test(str);
  }

  function isEmailValidFormat(input) {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(input);
  }

  const cleanDetails = () => {
    userDetailStore.onFieldChange('firstName', '');
    userDetailStore.onFieldChange('lastName', '');
    userDetailStore.onFieldChange('email', '');
    userDetailStore.onFieldChange('phoneNumber', '');
  };


  const handleKeyPress = async (event) => {
    if (event.key === 'Enter') {
      onSearch();
    }
  };

  const onSearch = async () => {
    newLeadStore.setGlobalSearch(true);
    await searchDetails();
    console.log(newLeadStore.gridStore);
    if (newLeadStore.renderPossibleMatches) {
      searchBarStore.setShowSearch(false);
      const possibleMatchesUrl = possibleMatchesConfig.possibleMatches.url;
      push(possibleMatchesUrl);
    }else{
      newLeadStore.setGlobalSearch(false);
      newLeadStore.setNoLeadsFound(true);
      newLeadStore.setActiveStep(1);
      newLeadStore.userDetailStore.setDisabled(false);
      searchBarStore.setShowSearch(false);
      push(newLeadRoutesConfig.newLeadsHome.url);
    }
  }

  return (
    <Box p={0} display={'flex'} flexGrow={1}>
      <Box flexGrow={1} pr={4}>
        <TextField
          testId="search"
          autoFocus
          fullWidth
          InputProps={{
            endAdornment: <></>,
          }}
          helperText="Performs a 'contains' search"
          onChange={e => setDetails(e.target.value)}
          onKeyDown={handleKeyPress}
        />
      </Box>
      <Box>
        <Button variant="contained" color="primary" testId="search" onClick={onSearch}>
          Search
        </Button>
      </Box>
    </Box>
  )
});
