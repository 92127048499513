import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useBaseStore } from '@roc/feature-app-core';
import { Button, ConfirmationButton, TextField } from '@roc/ui';
import { observer } from 'mobx-react';
import { useDocumentStore } from '../../../hooks/useDocumentStore';
import { useEffect } from 'react';

export type TamariskPaymentProps = {
  borrowersEmail: string;
  tamariskURL: string;
  entityId: string;
  type: string;
  paymentStatus?: boolean;
  isInternal?: boolean;
  handleClose?: () => void;
};

const useStyles = makeStyles({
  instruction: {
    fontWeight: 600,
  },
  underline: {
    fontWeight: 600,
    textDecorationLine: 'underline',
  },
});

export const TamariskPayment = observer((props: TamariskPaymentProps) => {
  const { borrowersEmail, tamariskURL, entityId, type, paymentStatus, isInternal } = props;
  const {
    documentStore: { documentFormStore },
  } = useDocumentStore();
  const {
    globalStore
  } = useBaseStore();
  const { tamariskPaymentStore, loanPaymentsStore } = documentFormStore;

  const classes = useStyles();

  useEffect(() => {
    tamariskPaymentStore.setIsInternal(isInternal);
  }, [])

  const handleConfirmDeposit = () => {
    tamariskPaymentStore.requestAppraisalPayment(entityId, type);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(tamariskURL);
    if (isInternal) {
      window.parent.postMessage(tamariskURL, '*');
    }
    globalStore.notificationStore.showSuccessNotification({
      message: 'Link was copied to clipboard!',
    });
  };

  const handleDownloadDocument = () => {
    loanPaymentsStore.downloadSignedDocument(entityId, type);
  };

  const renderPaymentPending = () => {
    return (
      <Grid container justifyContent="center">
        {(
          <Grid
            alignItems="center"
            container
            item
            justifyContent="center"
            xs={12}
          >
            <Grid item container xs={11} spacing={2}>
              <Grid item>
                <Typography className={classes.instruction}>
                  Link for the borrower to review and make the appraisal payment
                </Typography>
              </Grid>
              <Grid item container alignItems="center" spacing={2}>
                <Grid item xs={12} sm>
                  <TextField
                    testId="tamariskPaymentUrl"
                    variant="outlined"
                    label="Deposit URL"
                    value={tamariskURL}
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleCopy}
                    testId="copyTamariskPaymentUrlButton"
                    fullWidth
                  >
                    COPY LINK
                  </Button>
                </Grid>
              </Grid>
              <Grid item container spacing={2}>
                <Grid item>
                  <Typography className={classes.instruction}>
                    {`Or you can send an email to the borrower @
                    `}<span className={classes.underline}>{borrowersEmail}</span>
                    {` with the payment details`}
                  </Typography>
                </Grid>
                <Grid item container justifyContent="center">
                  <ConfirmationButton
                    variant="contained"
                    onClick={handleConfirmDeposit}
                    color="primary"
                    testId="sendTamariskPaymentButton"
                    confirmDialogProps={{
                      title: 'Confirmation',
                      body: 'Email will be sent out. Please confirm',
                    }}
                  >
                    Email Link to Borrower
                  </ConfirmationButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  };

  const renderPaymentDone = () => {
    return (
      <Grid item container justifyContent="center" spacing={2}>
        <Grid item>
          <Typography className={classes.instruction}>
            Click on the button to download the signed document
          </Typography>
        </Grid>
        <Grid item container justifyContent="center">
          <Button
            variant="contained"
            onClick={handleDownloadDocument}
            color="primary"
            testId="downloadSignedDocumentButton"
          >
            Download signed document
          </Button>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid container>
      {paymentStatus ? renderPaymentDone() : renderPaymentPending()}
    </Grid>
  );
});

export default TamariskPayment;
