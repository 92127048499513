import { Accordion, AccordionDetails, AccordionSummary, Grid, Theme, Typography, makeStyles } from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { yesNoRadioOptions } from "@roc/feature-sow-shared/constants/scopeOfWorkForm";
import { MultiSelectField, RadioField, SelectField, TextField } from "@roc/ui";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import { useState } from "react";

const useStyles = makeStyles((theme: Theme)=>({
  title: {
    fontSize: '20px',
    paddingLeft: theme.spacing(1),
    marginBottom: '0px'
  },
  item: {
    marginBottom: '10px',
  }
}))

export const ConstructionDataSummary = observer(({store})=>{
  const classes = useStyles();
  const [ownTheProperty, setOwnTheProperty] = useState('Yes');
  const options = [];

  const fields = store.form.fields;

  const handleChange = (field, value) =>
    store.onFieldChange(field, value === 'true');

  const drawingOptions = [
    {value:'Minimal Set',label:'Minimal Set'},
    {value:'Full Set',label:'Full Set'}
  ]

  const dataAvailabilityOptions = [
    {value:'Digital SOW',label:'Digital SOW'},
    {value:'Appraisal',label:'Appraisal'},
    {value:'Portal Data',label:'Portal Data'}
  ]

  const selectedValues = toJS(fields.dataAvailabilityAssessment.value);

  return (
    <>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMore/>}>
          <Typography align="left" className={classes.title}>
            Construction Data Summary
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={4} className={classes.item}>
              <RadioField
                label="Digital Scope of Work Available"
                options={yesNoRadioOptions}
                fullWidth
                name="sowAvailable"
                value={fields.sowAvailable.value}
                onChange={(value) => handleChange('sowAvailable', value)}
                testId="sowAvailable"
                row
              />
            </Grid>
            <Grid item xs={4} className={classes.item}>
              <SelectField
                label="Drawings"
                name="Drawings"
                options={drawingOptions}
                variant="outlined"
                value={fields.drawings.value}
                onChange={value => store.onFieldChange('drawings', value)}
                testId="Drawings"
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <MultiSelectField
                label="Data Availability Assesment"
                name="dataAvailabilityAssessment"
                options={dataAvailabilityOptions}
                getOptionSelected={(option, selectedValue) => {
                  return option.value == selectedValue.value;
                }}
                value={selectedValues ? selectedValues : []}
                onChange={(e, value) =>
                  store.onFieldChange('dataAvailabilityAssessment', value)
                }
                renderInput={params => (
                  <TextField
                    testId="dataAvailabilityAssessment"
                    {...params}
                    variant="outlined"
                  />
                )}
                fullWidth
                testId="dataAvailabilityAssessment"
              />
            </Grid>
            <Grid item xs={4} className={classes.item}>
              <RadioField
                label="Appraisal Available"
                options={yesNoRadioOptions}
                fullWidth
                name="appraisalAvailable"
                value={fields.appraisalAvailable.value}
                onChange={(value) => handleChange('appraisalAvailable', value)}
                testId="appraisalAvailable"
                row
              />
            </Grid>
            <Grid item xs={8}>
              <TextField
                type="text-area"
                value={fields.comments.value}
                variant="outlined"
                label="Reviewer Comments"
                fullWidth
                multiline={true}
                minRows={1}
                margin="normal"
                testId="description"
                onChange={e => store.onFieldChange('comments', e.target.value)}
              ></TextField>
            </Grid>
            <Grid item xs={4} className={classes.item}>
              <RadioField
                label="Permits Available"
                options={yesNoRadioOptions}
                fullWidth
                name="permitsAvailable"
                value={fields.permitsAvailable.value}
                onChange={(value) => handleChange('permitsAvailable', value)}
                testId="permitsAvailable"
                row
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  )
})