import { UserFeatures } from '@roc/feature-app-core';
import { LoanSubType } from '@roc/feature-utils';

const isSinglePropertySubType = loanSubtype => {
  return (
    loanSubtype === LoanSubType.SINGLE_PROPERTY ||
    loanSubtype === LoanSubType.SINGLE_PROPERTY_MULTIFAMILY ||
    loanSubtype === LoanSubType.SINGLE_PROPERTY_MIXED_USE
  );
};

const isBridgePropertySubType = loanSubtype => {
  return (
    loanSubtype === LoanSubType.FIX_AND_FLIP ||
    loanSubtype === LoanSubType.FIX_AND_FLIP_PRO ||
    loanSubtype === LoanSubType.STABILIZED_BRIDGE ||
    loanSubtype === LoanSubType.GROUND_UP
  );
};

export const canSubmitAppraisalWithTamarisk = (
  subType: LoanSubType,
  userFeatures: UserFeatures
) => {
  const isLenderOriginator = userFeatures.isLenderOriginator;
  const subtypeAllowed = isBridgePropertySubType(subType) || isSinglePropertySubType(subType);

  return subtypeAllowed && !isLenderOriginator && !userFeatures.isBroker;
};
