import { action, computed, flow, makeObservable, observable } from 'mobx';
import { GlobalStore } from '../../../stores';
import { FormStore } from '@roc/feature-app-core';
import { LenderFees } from '../types/companyProfileTypes';
import { CompanyProfileService } from '../services/CompanyProfileService';
import { isBlank, isEmptyString } from '@roc/feature-utils';
import { ExternalLenderStore } from './externalLenderStore';
import { LenderFeesStore } from './lenderFeesStore';

const PROCESSING_FEE = 'Processing Fee';
const ADMIN_FEE = 'Admin Fee';
const UNDERWRITING_FEE = 'Underwriting Fee';
const COMMITMENT_FEE = 'Commitment Fee';

interface DefaultFee {
  isPlaceholder: boolean;
  isCustomFee: boolean;
  tpoFeeLabel: string;
  tpoFeeAmount: number;
  rocFeeLabel: string;
  rocFeeAmount: number;
}

export class DefaultFeesStore {
  private globalStore: GlobalStore;
  companyProfileService: CompanyProfileService;
  salesforceLenderStore;
  lenderFeesStore: LenderFeesStore;

  isEditing = false;
  defaultFees: DefaultFee[];

  constructor(globalStore, lenderStore, lenderFeesFormStore) {
    this.globalStore = globalStore;
    this.salesforceLenderStore = lenderStore;
    this.lenderFeesStore = lenderFeesFormStore;
    this.companyProfileService = new CompanyProfileService();

    makeObservable(this, {
      isEditing: observable,
      defaultFees: observable,
      edit: action,
      cancel: action,
      onFeeChange: action,
      onCustomFeeNameChange: action,
      saveDefaultFees: flow,
      fetchDefaultFees: flow,
      validateFields: flow,
      totalOriginatorFees: computed,
      totalRocFees: computed,
    });
  }

  edit() {
    this.isEditing = true;
  }

  cancel() {
    this.isEditing = false;
    this.fetchDefaultFees();
  }

  *fetchDefaultFees() {
    try {
      this.defaultFees = null;
      const response = yield this.companyProfileService.getDefaultFeesTable(this.globalStore.selectedCompanyId);
      const rows = JSON.parse(response.data.data).rows;
      this.defaultFees = rows;
      this.addCustomFeePlaceholder();

      this.lenderFeesStore.loadLenderFees();
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error fetching the default fees',
      });
    }
  }

  *validateFields() { }

  onFeeChange(fee, newValue) {
    fee.tpoFeeAmount = newValue ? parseFloat(newValue as any) : newValue == "" ? 0 : newValue;
  }

  addCustomFeePlaceholder() {
    this.defaultFees = this.defaultFees.concat({
      isPlaceholder: true,
      isCustomFee: true,
      tpoFeeLabel: '',
      tpoFeeAmount: null,
      rocFeeLabel: '',
      rocFeeAmount: null,
    });
  }

  onCustomFeeNameChange(fee, newName) {
    fee.tpoFeeLabel = newName;
    if (fee.isPlaceholder) {
      fee.isPlaceholder = undefined;
      this.addCustomFeePlaceholder();
    }
    if (isBlank(fee.tpoFeeLabel)) {
      this.defaultFees = this.defaultFees.filter(item => item != fee);
    }
  }

  *saveDefaultFees() {
    try {
      this.validateFields();
      this.isEditing = false;

      const defaultFees = this.defaultFees.filter(
        customFee => !customFee.isPlaceholder
      );
      this.companyProfileService.updateDefaultFees(defaultFees);

      this.getSfLenderFees();
      yield this.salesforceLenderStore.updateSalesforceLenderFees(
        this.lenderFeesStore.lenderFees
      );

      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Fees have been updated successfully.',
      });
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while saving company fees',
      });
    }
  }

  get totalOriginatorFees() {
    return this.defaultFees.reduce((total, fee) => total + fee.tpoFeeAmount, 0);
  }

  get totalRocFees() {
    return this.defaultFees.reduce((total, fee) => total + fee.rocFeeAmount, 0);
  }

  getSfLenderFees() {
    this.defaultFees.forEach((fee) => {
      if (fee.tpoFeeLabel == PROCESSING_FEE)
        this.lenderFeesStore.lenderFees.processingFees = fee.tpoFeeAmount || 0;
      else if (fee.tpoFeeLabel == ADMIN_FEE)
        this.lenderFeesStore.lenderFees.adminFees = fee.tpoFeeAmount || 0;
      else if (fee.tpoFeeLabel == UNDERWRITING_FEE)
        this.lenderFeesStore.lenderFees.underWritingFees = fee.tpoFeeAmount || 0;
      else if (fee.tpoFeeLabel == COMMITMENT_FEE)
        this.lenderFeesStore.lenderFees.commitmentFees = fee.tpoFeeAmount || 0;
    });
  }
}
