import { GlobalStore } from '@roc/feature-app-core';
import { FormStore } from '@roc/feature-app-core';
import { Broker } from './../types/brokerTypes';

export const form = {
  fields: {
    brokerId: { value: null },
    companyId: { value: null },
    contactId: { value: null },
    firstName: {
      value: '',
      error: null,
      rule: 'required',
      attribute: 'first name',
    },
    lastName: {
      value: '',
      error: null,
      rule: 'required',
      attribute: 'last name',
    },
    emailAddress: {
      value: '',
      error: null,
      rule: 'required|email',
    },
    cellPhone: {
      value: '',
      error: null,
      rule: 'phone',
      attribute: 'phone number',
    },
    entityName: {
      value: '',
      error: null,
      rule: [{ required_if: ['isReferrer', false] }],
      message: 'Company name is required'
    },
    entityTaxNumber: {
      value: '',
      error: null,
      rule: 'regex:/^\\d{2}-?\\d{7}$/|alpha_dash',
      attribute: 'entity tax number',
    },
    isReferrer: {
      value: false,
      error: null,
      rule: 'required',
    }
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export class BrokerFormStore extends FormStore {
  constructor(globalStore: GlobalStore) {
    super(form, globalStore);
  }

  setBroker(broker: Broker) {
    this.reset();
    this.loadForm(broker);
  }

  getBroker(): Broker {
    return this.getFormValues();
  }

}
