import { GridRowSelectedEvent } from '@roc/ui';
import { ApiResponse, GlobalStore } from '@roc/feature-app-core';
import { action, makeObservable, observable } from 'mobx';
import { GridStore } from '@roc/feature-app-core';
import { OneToolService } from '../../services/oneToolService';

export class QuotesDashboardGridStore extends GridStore {
  private globalStore: GlobalStore;
  private oneToolService: OneToolService;

  constructor(globalStore: GlobalStore) {
    super(() => this.fetchQuotesGrid());
    this.globalStore = globalStore;
    this.oneToolService = new OneToolService();

    makeObservable(this, {});
  }

  private async fetchQuotesGrid() {
    try {
      const response = await this.oneToolService.getMyQuotesGrid(
        this.gridData.meta.pageNumber,
        this.gridData.meta.pageSize,
        this.gridData.meta.sortDir,
        this.gridData.meta.sortBy,
        this.gridData.meta.filters
      );
      return response;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: `Error while fetching the quotes`,
      });
    }
  }
}
