import { action, computed, flow, makeObservable, observable } from 'mobx';
import { GlobalStore } from '@roc/feature-app-core';
import { DocumentStore } from '@roc/feature-documents';
import { LoanStore } from '@roc/feature-loans';
import { Document } from '@roc/feature-types';
import { DocumentName } from '../../../constants';
import { TitleAgentService } from '../../../services/documentForms/titleAgentService';
import { shouldBeAbleToSubmitSpecificForm } from '../../../utils/documentForms';
import { LoanService } from '@roc/feature-loans';
import { AllowedWimba } from '@roc/feature-utils';
import { TitleAgentWimbaFormStore } from './titleAgentWimbaFormStore';
import { TitleAgentOtherFormStore } from './titleAgentOtherFormStore';

export class TitleAgentStore {
  loanStore: LoanStore;
  documentStore: DocumentStore;
  globalStore: GlobalStore;
  titleAgentWimbaFormStore: TitleAgentWimbaFormStore;
  titleAgentOtherFormStore: TitleAgentOtherFormStore;

  titleAgentService: TitleAgentService;

  isWimba: boolean = false;
  wimbaInfo: any;
  titleCompany: any;
  showWimbaOption: boolean = false;
  wimbaStates: any[];
  loanService: LoanService;

  constructor(
    loanStore: LoanStore,
    documentStore: DocumentStore,
    globalStore: GlobalStore
  ) {
    this.loanStore = loanStore;
    this.documentStore = documentStore;
    this.globalStore = globalStore;
    this.titleAgentService = new TitleAgentService();
    this.loanService = new LoanService();
    this.titleAgentWimbaFormStore = new TitleAgentWimbaFormStore(globalStore, loanStore, documentStore);
    this.titleAgentOtherFormStore = new TitleAgentOtherFormStore(globalStore, loanStore, documentStore);
    makeObservable(this, {
      canEdit: computed,
      canSubmit: computed,
      initForm: flow,
      submit: flow,
      setIsWimba: flow,
      fetchWimbaInfo: flow,
      getWimbaStates: flow,
      wimbaStates: observable,
      showWimba: action,
      showWimbaOption: observable,
      isWimba: observable,
      wimbaInfo: observable,
      reset: action,
    });
  }

  reset() {
    this.titleAgentOtherFormStore.reset();
    this.titleAgentWimbaFormStore.reset();
  }

  *fetchWimbaInfo() {
    if (!this.wimbaInfo) {
      try {
        const response = yield this.titleAgentService.getWimbaTitleCompany();
        const wimbaInfo = JSON.parse(response.data.data);
        this.wimbaInfo = wimbaInfo;
        this.wimbaInfo.contactName = wimbaInfo.contacts[0]?.fullName;
      } catch (error) {
        this.globalStore.notificationStore.showErrorNotification({
          message: 'Error while loading Wimba information',
        });
      }
    }
  }

  *initForm(loanId: string) {
    yield this.fetchWimbaInfo();
    yield this.getWimbaStates();
    this.showWimbaOption = this.showWimba();
    this.titleCompany = this.loanStore.loanDetails?.titleCompany;
    this.titleCompany.contactName = this.loanStore.loanDetails?.titleCompany.contacts[0]?.fullName;
    const wimbaCompanyId = this.wimbaInfo?.titleAgencyCompanyId;
    const titleCompanyId = this.titleCompany?.companyId;
    const isWimba = wimbaCompanyId && wimbaCompanyId === titleCompanyId;
    yield this.setIsWimba(isWimba);
  }

  showWimba = () => {
    return (
      this.loanStore?.loanDetails?.properties?.some(p =>
        this.wimbaStates.includes(p.state)
      )
    );
  };

  *submit(document: Document, loanId: string) {
    if (this.isWimba) {
      this.titleAgentWimbaFormStore.submitData(document, loanId, this.wimbaInfo);
    } else {
      this.titleAgentOtherFormStore.submitData(document, loanId, this.wimbaInfo?.titleAgencyCompanyId);
    }
  }

  private getTitleCompany() {
    const titleCompany = this.loanStore.loanDetails?.titleCompany;
    const wimbaCompanyId = this.wimbaInfo?.titleAgencyCompanyId;
    const titleCompanyId = titleCompany?.companyId;

    if (this.isWimba) {
      return this.wimbaInfo;
    } else if (wimbaCompanyId && wimbaCompanyId !== titleCompanyId) {
      return titleCompany;
    } else {
      // Create a new company if the current title company is Wimba
      return null;
    }
  }

  *setIsWimba(isTitleCompanyWimba: boolean) {
    this.isWimba = isTitleCompanyWimba;
    this.isWimba ? this.titleAgentWimbaFormStore.reset() : this.titleAgentOtherFormStore.reset();

    const company = this.getTitleCompany();

    let values = { ...company };
    // Convert null to empty string to prevent form errors
    for (let key in values) {
      values[key] = values[key] ?? '';
    }

    this.isWimba ? this.titleAgentWimbaFormStore.loadForm(values) : this.titleAgentOtherFormStore.loadForm(values);
  }

  populateForm = () => { };

  get canEdit() {
    const entitlements = this.globalStore.userFeatures.closingEntitlements;
    return shouldBeAbleToSubmitSpecificForm(
      entitlements?.isSubmissionAllowed,
      entitlements?.isSubmissionNotAllowedOnly,
      entitlements?.isSubmissionAllowedOnly,
      DocumentName.TITLE_AGENT
    );
  }

  get canSubmit() {
    const formIsValid = this.isWimba ? this.titleAgentWimbaFormStore.form.meta.isValid : this.titleAgentOtherFormStore.form.meta.isValid;
    return this.canEdit && this.wimbaInfo && formIsValid;
  }

  *getWimbaStates() {
    try {
      const response = yield this.loanService.getWimbaStates();
      if (response && response.data && response.data.data) {
        this.wimbaStates = Array.isArray(response.data.data) ? response.data.data : JSON.parse(response.data.data)
      }
      if (this.wimbaStates.length == 0) {
        this.wimbaStates = AllowedWimba;
      }
    } catch (error) {
      this.wimbaStates = AllowedWimba;
    }
  }
}

export default TitleAgentStore;
