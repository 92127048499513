import React, { ReactNode } from 'react';
import { Grid } from '@material-ui/core';
import { Add, Search } from '@material-ui/icons';
import { Button, ButtonProps } from '@roc/ui';

export type AddOrSelectProps = {
  entity: string;
  entities: any[];
  renderEntity: (entity: any, index: number) => ReactNode;
  showSelect?: boolean;
  selectButtonProps?: ButtonProps;
  showAdd?: boolean;
  addButtonProps?: ButtonProps;
};

export const AddOrSelect = (props: AddOrSelectProps) => {
  const {
    entity,
    entities,
    renderEntity,
    showSelect = true,
    selectButtonProps,
    showAdd = true,
    addButtonProps,
  } = props;

  return (
    <Grid container spacing={2}>
      <Grid item container spacing={1}>
        {showSelect && (
          <Grid item>
            <Button
              size="small"
              variant="contained"
              color="secondary"
              startIcon={<Search />}
              testId="selectButton"
              {...selectButtonProps}
            >
              {`CHOOSE EXISTING ${entity.toUpperCase()}`}
            </Button>
          </Grid>
        )}
        {showAdd && (
          <Grid item>
            <Button
              size="small"
              variant="contained"
              color="secondary"
              startIcon={<Add />}
              testId="addButton"
              {...addButtonProps}
            >
              {`ADD NEW ${entity.toUpperCase()}`}
            </Button>
          </Grid>
        )}
      </Grid>
      {renderEntity && (
        <Grid item container spacing={2}>
          {entities.map(renderEntity)}
        </Grid>
      )}
    </Grid>
  );
};

export default AddOrSelect;
