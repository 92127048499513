import {
  Box,
  Typography,
  Grid,
} from '@material-ui/core';
import { observer } from 'mobx-react';
import { CollectCreditReportWithMessageWithCustomOptions, PersonalGuarantor } from '@roc/ui/formComponents';
import { Borrower } from '../types';
import { isNil, prequalificationStatusProperties } from '@roc/feature-utils';
import QualifyBorrowerRequestFormStore from '../stores/qualifyBorrowerRequestFormStore';
import BorrowerCreditBackgroundCheckDisclaimer from './borrowerCreditBackgroundCheckDisclaimer';
import { Alert } from '@material-ui/lab';

interface BorrowerFormProps {
  store: QualifyBorrowerRequestFormStore;
  borrower?: Borrower;
  hideCreditForm?: boolean;
}

export const QualifyBorrowerForm = observer(({ store, borrower, hideCreditForm = false }: BorrowerFormProps) => {

  const getForm = () => {
    return (
      <>
        {
          hideCreditForm && (
            <Grid item xs={12}>
              <PersonalGuarantor store={store} label={`Will this borrower be a personal guarantor?`} />
            </Grid>
          )
        }
        <CollectCreditReportWithMessageWithCustomOptions
          store={store}
          hideDetails={true}
          label={`Please choose one of the following:`}
        />
        {
          <Box pt={2} pb={2}>
            <BorrowerCreditBackgroundCheckDisclaimer store={store} />
          </Box>
        }
      </>
    );
  }

  return (
    <Grid xs={12}>
      {(!borrower?.preQualificationStatus || borrower?.preQualificationStatus === prequalificationStatusProperties.PENDING.name) ? (
        <>
          {(isNil(borrower?.sendIdVerification) || borrower?.sendIdVerification) && borrower?.preQualificationInProgress ? (
            <Box pb={4}>
              <Alert severity="info" >
                <Typography>
                  We are waiting on borrower <strong>{`${borrower?.firstName} ${borrower?.lastName} (${borrower?.emailAddress})`} to provide data.</strong>
                </Typography>
              </Alert>
            </Box>
          ) : (
            getForm()
          )}
        </>
      ) : null}
      {(borrower?.preQualificationStatus === prequalificationStatusProperties.ACCEPTED.name) ? (
        <>
          {(isNil(borrower?.runCreditOnCreation) || borrower?.runCreditOnCreation) && borrower?.personalGuarantor ? (
            <Box pb={4}>
              <Alert severity="info" >
                <Typography>
                  We already have the credit information of borrower <strong>{`${borrower?.firstName} ${borrower?.lastName} (${borrower?.emailAddress})`}</strong>.
                </Typography>
              </Alert>
            </Box>
          ) : (
            (isNil(borrower?.runCreditOnCreation) || !borrower?.runCreditOnCreation) && borrower?.personalGuarantor ? (
              getForm()
            ) : (
              <Box pb={4}>
                <Alert severity="info" >
                  <Typography>
                    No further information needed for borrower <strong>{`${borrower?.firstName} ${borrower?.lastName} (${borrower?.emailAddress})`}</strong>.
                  </Typography>
                </Alert>
              </Box>
            )
          )}
        </>
      ) : null}
    </Grid>
  );
}
);

export default QualifyBorrowerForm;
