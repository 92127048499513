import { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { Badge, Tooltip, createStyles, Fab, withStyles } from '@material-ui/core';
import { Forum } from '@material-ui/icons';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { PORTAL_COLORS } from '@roc/feature-utils';
import { ClosingAttorneyLoanActions } from '../../../../feature-attorney-loans/src/loanDetails/closingAttorneyLoanActions';
import { ConversationsFAB, FilterType, LoanCommunicationStore } from '@roc/feature-communication';
import { useLoanActionsStore } from '@roc/feature-documents';
import { useRouteMatch } from 'react-router';
import { InsuranceReviewerLoanActions } from 'libs/feature-insurance-reviewer-loans/src/insuranceReviewerLoans/insuranceReviewerLoanActions';
import { PayoffActionsButton } from 'libs/feature-loans/src/loans/loanDetails/payoffs/components/payoffActionsButton';

const useStyles = makeStyles(() => ({
  spacer: {
    marginTop: '100px',
    '@media (min-width:1500px)': {
      display: 'none'
    }
  },
  visible: {},
  hidden: {}
}));

interface LoanDetailsFABProps {
  loanId: number;
  isBroker: boolean;
  showConversations: boolean;
  loanCommunicationStore: LoanCommunicationStore;
  onOpenConversations: () => void;
}

export const LoanDetailsFAB = observer(({
  loanId,
  isBroker,
  showConversations,
  loanCommunicationStore,
  onOpenConversations
}: LoanDetailsFABProps) => {
  const classes = useStyles();
  const { loanActionsStore } = useLoanActionsStore();
  const isAttorneyPortal = useRouteMatch('/attorney-portal');
  const isServicerPortal = useRouteMatch('/servicer-portal');
  const isInsuranceReviewerPortal = useRouteMatch('/insurance-reviewer-portal');
  const [conversationsLoaded, setConversationsLoaded] = useState(false);
  const showActions = useMemo(() => !!loanActionsStore?.showActions, [loanActionsStore]);

  function loadConversations() {
    loanCommunicationStore.reset();
    loanCommunicationStore.setFilter({ type: FilterType.LOAN_ID, value: loanId });
    loanCommunicationStore.loadMyConversations(1, 100, (totalCount) => {
      if (totalCount > 0) {
        setConversationsLoaded(true);
      }
    }, () => null);
  }

  useEffect(() => {
    if (isBroker) {
      loadConversations();
    } else if (showConversations) {
      setConversationsLoaded(true);
    }
  }, [isBroker, showConversations, loanId]);

  return (
    <>
      {(!!isAttorneyPortal || !!isServicerPortal || !!isInsuranceReviewerPortal) && showActions && (<div className={classes.spacer} />)}
      {showConversations && conversationsLoaded && (
        <ConversationsFAB
          loanCommunicationStore={loanCommunicationStore}
          onOpenConversations={onOpenConversations}
          showConversations={showConversations}
        />
      )}
      {!!isAttorneyPortal && <ClosingAttorneyLoanActions version="v2" />}
      {!!isServicerPortal && <PayoffActionsButton version="v2" />}
      {!!isInsuranceReviewerPortal && <InsuranceReviewerLoanActions version="v2" />}
    </>
  );
});