import { FormStore } from '@roc/feature-app-core';
import { action, makeObservable, flow, observable, computed } from 'mobx';
import { SinglePropertyStore } from './singlePropertyStore';
import * as constants from '../common/pricerSummaryHelper';
import { PropertyTerm } from '@roc/feature-types';
import { format, parse } from 'date-fns';
import {
  CONDOMINIUM,
  DUPLEX,
  isNil,
  MIXED_USE,
  MULTIFAMILY_5_PLUS,
  notLicensedSfr,
  PLANNEDUNIT,
  PROPERTY_TYPES_RUSHED_APPRAISAL,
  QUADRUPLEX,
  REFINANCE,
  SINGLE_FAMILY,
  statePropertyTypeRestriction,
  TOWNHOME,
  TRIPLEX,
} from '@roc/feature-utils';
import { GlobalStore } from '@roc/feature-app-core';
import { parseAddress } from '@roc/feature-utils';
import { mapLoanPurpose } from '../common/pricerSummaryHelper';
import { MSAService } from '../../services/msaService';

const propertyForm = {
  fields: {
    rentalPricerId: {
      value: '',
      error: null,
      rule: '',
    },
    propertyId: {
      value: '',
      error: null,
      rule: '',
    },
    pricerType: {
      value: '',
      error: null,
      rule: '',
    },
    address: {
      value: '',
      error: null,
      rule: 'required',
      nextStep: 'required',
      message: 'Required',
    },
    aptNumber: {
      value: '',
      error: null,
    },
    __isAddressInLicensedState__: {
      value: true,
      error: null,
      rule: 'accepted',
      message: notLicensedSfr.message,
    },
    __isAddressInCorrectFormat__: {
      value: true,
      error: null,
      rule: 'accepted',
      message: 'Invalid Address Format',
    },
    streetNumber: {
      value: '',
      error: null,
      rule: '',
    },
    streetName: {
      value: '',
      error: null,
      rule: 'required',
    },
    city: {
      value: '',
      error: null,
      rule: 'required',
      nextStep: 'required',
    },
    state: {
      value: '',
      error: null,
      rule: 'required',
      nextStep: 'required',
    },
    zipCode: {
      value: '',
      error: null,
      rule: 'required',
    },
    latitude: {
      value: 0,
      error: null,
      rule: '',
    },
    longitude: {
      value: 0,
      error: null,
      rule: '',
    },
    propertyType: {
      value: '',
      error: null,
      rule: 'required',
      nextStep: 'required',
      message: 'Required',
    },
    loanPurpose: {
      value: '',
      error: null,
      rule: 'required',
      nextStep: 'required',
      message: 'Required',
    },
    acquisitionDate: {
      value: null,
      error: null,
      rule: 'required',
      message: 'Required',
    },
    acquisitionPrice: {
      value: '',
      error: null,
      rule: 'required',
      nextStep: 'required',
      message: 'Required',
    },
    capitalImprovements: {
      value: '',
      error: null,
      rule: 'required',
      message: 'Required',
    },
    anyDebt: {
      value: '',
      error: null,
      rule: 'required',
      message: 'Required',
    },
    totalDebtPayoff: {
      value: '',
      error: null,
      rule: 'required',
      message: 'Required',
    },
    units: {
      value: '',
      error: null,
      rule: 'required',
      message: 'Required',
      nextStep: 'requiredForMF',
    },
    unitsOccupied: {
      value: '',
      error: null,
      rule: '',
      message: '',
    },
    unitsOccupiedHave12MonthsLease: {
      value: '',
      error: null,
      rule: '',
      message: '',
    },
    propertyValuation: {
      value: '',
      error: null,
      rule: 'required',
      nextStep: 'required',
      message: 'Required',
    },
    monthlyGrossRent: {
      value: '',
      error: null,
      rule: 'required',
      nextStep: 'required',
      message: 'Required',
    },
    annualTaxes: {
      value: '',
      error: null,
      rule: 'required',
      message: 'Required',
      nextStep: 'required',
    },
    annualInsurance: {
      value: '',
      error: null,
      rule: 'required',
      nextStep: 'required',
      message: 'Required',
    },
    annualHOA: {
      value: '',
      error: null,
      rule: 'required',
      nextStep: 'required',
      message: 'Required',
    },
    totalAnnualUtilities: {
      value: '',
      error: null,
      rule: [
        {
          required_if_one_of: [
            'propertyType',
            CONDOMINIUM,
            MIXED_USE,
            MULTIFAMILY_5_PLUS,
            TOWNHOME,
            PLANNEDUNIT,
          ],
        },
      ],
      nextStep: 'requiredForOthersThanOneToFour',
      message: 'Required',
    },
    totalAnnualRepairsMaintenance: {
      value: '',
      error: null,
      rule: [
        {
          required_if_one_of: [
            'propertyType',
            CONDOMINIUM,
            MIXED_USE,
            MULTIFAMILY_5_PLUS,
            TOWNHOME,
            PLANNEDUNIT,
          ],
        },
      ],
      nextStep: 'requiredForOthersThanOneToFour',
      message: 'Required',
    },
    totalAnnualPropertyManagementFees: {
      value: '',
      error: null,
      nextStep: 'requiredForOthersThanOneToFour',
      rule: [
        {
          required_if_one_of: [
            'propertyType',
            CONDOMINIUM,
            MIXED_USE,
            MULTIFAMILY_5_PLUS,
            TOWNHOME,
            PLANNEDUNIT,
          ],
        },
      ],
      message: 'Required',
    },
    generalAdministrative: {
      value: 0,
      error: null,
      rule: 'required',
      message: 'Required',
      nextStep: 'requiredForMF',
    },
    payroll: {
      value: 0,
      error: null,
      rule: 'required',
      message: 'Required',
      nextStep: 'requiredForMF',
    },
    marketing: {
      value: 0,
      error: null,
      rule: 'required',
      message: 'Required',
      nextStep: 'requiredForMF',
    },
    replacementReserves: {
      value: '',
      error: null,
      rule: 'required',
      message: 'Required',
      nextStep: 'requiredForMF',
    },
    residentialUnits: {
      value: '',
      error: null,
      rule: 'required',
      message: 'Required',
      nextStep: 'requiredForMU',
    },
    commercialUnits: {
      value: '',
      error: null,
      rule: 'required',
      message: 'Required',
      nextStep: 'requiredForMU',
    },
    residentialUnitsSqFtArea: {
      value: '',
      error: null,
      rule: 'required',
      message: 'Required',
      nextStep: 'requiredForMU',
    },
    commercialUnitsSqFtArea: {
      value: '',
      error: null,
      rule: 'required',
      message: 'Required',
      nextStep: 'requiredForMU',
    },
    residentialIncome: {
      value: '',
      error: null,
      rule: 'required',
      message: 'Required',
      nextStep: 'requiredForMU',
    },
    commercialIncome: {
      value: '',
      error: null,
      rule: 'required',
      message: 'Required',
      nextStep: 'requiredForMU',
    },
    commercialUnitsCurrentOnRent: {
      value: '',
      error: null,
      rule: 'required',
      message: 'Required',
      nextStep: 'requiredForMU',
    },
    targetLTV: {
      value: '',
      error: null,
      nextStep: 'required',
      message: 'required',
      rule: 'required',
    },
    totalAnnualNOI: {
      value: '',
      error: null,
      rule: 'required',
    },
    totalAnnualIncome: {
      value: '',
      error: null,
      rule: 'required',
    },
    totalAnnualExpenses: {
      value: '',
      error: null,
      rule: 'required',
    },
    propertyCounty: {
      value: '',
      error: null,
      rule: 'required',
    },
    borrowerId: {
      value: null,
      error: null,
      rule: '',
    },
    borrowerExp: {
      value: '',
      error: null,
      rule: 'required',
      nextStep: 'required',
      message: 'Required',
    },
    ficoProvidedAtOrigination: {
      value: 660,
      error: null,
      rule: 'required',
    },
    bankruptcy: {
      value: 'N',
      error: null,
      rule: '',
    },
    bankruptcyDate: {
      value: null,
      error: null,
      rule: { required_if: ['bankruptcy', 'Y'] },
    },
    foreclosure: {
      value: 'N',
      error: null,
      rule: '',
    },
    foreclosureDate: {
      value: null,
      error: null,
      rule: { required_if: ['foreclosure', 'Y'] },
    },
    citizenshipStatus: {
      value: 'US Citizen',
      error: null,
      rule: '',
    },
    submitRushedAppraisal: {
      value: false,
      error: null,
      rule: 'required',
    },
    isPropertyLeased: {
      value: 'Y',
      error: null,
      rule: '',
    },
    section8: {
      value: null,
      error: null,
      rule: 'required',
      nextStep: 'required',
      message: 'Required',
    },
    rural: {
      value: null,
      error: null,
      rule: '',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export class PricerStore extends FormStore {
  private globalStore: GlobalStore;
  private singlePropertyStore: SinglePropertyStore;
  isPauseMultifamilySubmissionsOpen: boolean;
  private msaService = new MSAService();
  public isPropertyMSARural: boolean;
  constructor(globalStore, singlePropertyStore) {
    super({ ...propertyForm }, globalStore);
    this.globalStore = globalStore;
    this.singlePropertyStore = singlePropertyStore;
    makeObservable(this, {
      getTotalExpenses: action,
      getTotalAnnualNOI: action,
      handleLoanPurposeChange: action,
      handlePricerType: action,
      handleAnyDebtChange: action,
      handleLeaseChange: action,
      handleIncomeChange: action,
      handleExpenseChange: action,
      getTotalIncome: action,
      handlePropertyTypeChange: action,
      defaultUnitsFromPropertyType: action,
      checkIfRequiredFieldsEmpty: action,
      checkIfRequiredPropertyFieldsEmpty: action,
      checkIfFieldIsEmpty: action,
      checkForPricerStepErrors: action,
      getPricerStepErrors: action,
      checkForPropertyStepErrors: action,
      handlePropertyRefinanceValidations: action,
      getAnyDebt: action,
      getSunkCost: action,
      getProperty: action,
      counties: computed,
      pricerBorrower: computed,
      isPauseMultifamilySubmissionsOpen: observable,
      setIsPauseMultifamilySubmissionsOpen: action,
      checkIfPropertyIsRural: flow,
      isPropertyMSARural: observable
    });
  }

  get isSingleProperty() {
    return true;
  }

  get counties() {
    const { allCounties } = this.singlePropertyStore;
    const states = this.form.fields.state.value
      ? [this.form.fields.state.value]
      : [];
    let selectedStateCounties = [];
    if (states && states.length > 0) {
      selectedStateCounties = states.reduce(
        (c, s) => [...c, ...(allCounties[s] ?? [])],
        []
      );
    }

    const counties = [];
    if (selectedStateCounties && selectedStateCounties.length > 0) {
      selectedStateCounties.sort().map(county => {
        const countyParts = county.split(",");
        counties.push({ value: countyParts[0].trim(), label: county });
      });
    }
    return counties;
  }

  *checkIfPropertyIsRural(address: string) {
    this.msaService.checkIfPropertyIsRural(address)
      .then(response => this.isPropertyMSARural = response.data.data);
  }

  onPropertyStateChanged(value) { }

  getTotalAnnualNOI = () => {
    const { totalAnnualIncome, totalAnnualExpenses } = this.form.fields;
    const totalIncome = isNaN(Number(totalAnnualIncome.value))
      ? 0
      : Number(totalAnnualIncome.value);
    const totalExpenses = isNaN(Number(totalAnnualExpenses.value))
      ? 0
      : Number(totalAnnualExpenses.value);
    const totalAnnualNOI = totalIncome - totalExpenses;
    this.onFieldChange('totalAnnualNOI', totalAnnualNOI);
  };

  handleLoanPurposeChange = (name, value) => {
    this.onFieldChange(name, value);

    if (this.form.fields.loanPurpose.value === constants.PURCHASE) {
      this.form.fields.acquisitionDate.value = null;
      this.form.fields.capitalImprovements.value = '';
      this.form.fields.anyDebt.value = '';
      this.form.fields.totalDebtPayoff.value = '';
    }
  };
  handlePricerType = (value) => {
    this.form.fields.pricerType.value = value;
  };

  handleAnyDebtChange = (name, value) => {
    this.onFieldChange(name, value);

    if (this.form.fields.anyDebt.value === constants.NO) {
      this.form.fields.totalDebtPayoff.value = '';
    }
  };

  handleLeaseChange = (name, value) => {
    this.onFieldChange(name, value);
  }

  handleIncomeChange = (name, value) => {
    this.onFieldChange(name, value);
    this.getTotalIncome();
    this.getTotalAnnualNOI();
  };

  handleExpenseChange = (name, value) => {
    this.onFieldChange(name, value);
    this.getTotalExpenses();
    this.getTotalAnnualNOI();
  };

  getTotalIncome = () => {
    const rent = isNaN(Number(this.form.fields.monthlyGrossRent.value))
      ? 0
      : Number(this.form.fields.monthlyGrossRent.value);
    const totalAnnualIncome = rent * 12;
    this.onFieldChange('totalAnnualIncome', totalAnnualIncome);
  };

  getTotalExpenses = () => {
    const {
      annualTaxes,
      annualInsurance,
      annualHOA,
      totalAnnualUtilities,
      totalAnnualRepairsMaintenance,
      totalAnnualPropertyManagementFees,
      generalAdministrative,
      payroll,
      marketing,
      replacementReserves,
    } = this.form.fields;
    const taxes = isNaN(Number(annualTaxes.value))
      ? 0
      : Number(annualTaxes.value);
    const insurance = isNaN(Number(annualInsurance.value))
      ? 0
      : Number(annualInsurance.value);
    const hoa = isNaN(Number(annualHOA.value)) ? 0 : Number(annualHOA.value);
    const utilities = isNaN(Number(totalAnnualUtilities.value))
      ? 0
      : Number(totalAnnualUtilities.value);
    const repairsMaintenance = isNaN(
      Number(totalAnnualRepairsMaintenance.value)
    )
      ? 0
      : Number(totalAnnualRepairsMaintenance.value);
    const propertyManagementFees = isNaN(
      Number(totalAnnualPropertyManagementFees.value)
    )
      ? 0
      : Number(totalAnnualPropertyManagementFees.value);
    const generalAdministrativeCal = isNaN(Number(generalAdministrative.value))
      ? 0
      : Number(generalAdministrative.value);
    const payrollCal = isNaN(Number(payroll.value)) ? 0 : Number(payroll.value);
    const marketingCal = isNaN(Number(marketing.value))
      ? 0
      : Number(marketing.value);
    const replacementReservesCal = isNaN(Number(replacementReserves.value))
      ? 0
      : Number(replacementReserves.value);

    const totalAnnualExpenses =
      taxes +
      insurance +
      hoa +
      utilities +
      repairsMaintenance +
      propertyManagementFees +
      generalAdministrativeCal +
      payrollCal +
      marketingCal +
      replacementReservesCal;

    this.onFieldChange('totalAnnualExpenses', totalAnnualExpenses);
  };

  getAnyDebt() {
    return this.form.fields.anyDebt.value === 'Y';
  }

  getSunkCost() {
    const { loanPurpose, capitalImprovements } = this.form.fields;

    return loanPurpose.value === constants.PURCHASE
      ? 0
      : capitalImprovements.value;
  }

  getUnitsOccupiedHave12MonthsLease() {
    return this.form.fields.unitsOccupiedHave12MonthsLease.value === 'Y';
  }

  getAcquisitionDate() {
    const { acquisitionDate } = this.form.fields;
    return acquisitionDate.value;
  }

  getLoanPurpose() {
    const { loanPurpose, anyDebt } = this.form.fields;
    const { form } = this.singlePropertyStore.pricerSummaryStore;
    const { seekingCashOut } = form.fields;
    const loanPurposeValue = mapLoanPurpose(loanPurpose.value, seekingCashOut.value, anyDebt.value);
    return constants.loanPurposeDic[loanPurposeValue];
  }

  getProperty(): PropertyTerm {
    const data = this.getFormValues();

    return {
      propertyId: data['propertyId'],
      rentalPricerId: data['rentalPricerId'],
      pricerType: data['pricerType'],
      address: data['address'],
      aptNumber: data['aptNumber'],
      streetName: data['streetName'],
      streetNumber: data['streetNumber'],
      city: data['city'],
      state: data['state'],
      county: data['propertyCounty'],
      zipCode: data['zipCode'],
      latitude: data['latitude'],
      longitude: data['longitude'],
      propertyType: data['propertyType'],
      loanPurpose: this.getLoanPurpose(),
      acquisitionDate: this.getAcquisitionDate(),
      acquisitionPrice: data['acquisitionPrice'],
      capitalImprovements: data['capitalImprovements'],
      anyDebt: this.getAnyDebt(),
      totalDebtPayoff: data['totalDebtPayoff'],
      units: data['units'],
      unitsOccupied: data['unitsOccupied'],
      unitsOccupiedHave12MonthsLease: this.getUnitsOccupiedHave12MonthsLease(),
      propertyValuation: data['propertyValuation'],
      monthlyGrossRent: data['monthlyGrossRent'],
      annualTaxes: data['annualTaxes'],
      annualInsurance: data['annualInsurance'],
      annualHOA: data['annualHOA'],
      totalAnnualUtilities: data['totalAnnualUtilities'],
      totalAnnualRepairsMaintenance: data['totalAnnualRepairsMaintenance'],
      totalAnnualPropertyManagementFees:
        data['totalAnnualPropertyManagementFees'],
      generalAdministrative: data['generalAdministrative'],
      payroll: data['payroll'],
      marketing: data['marketing'],
      replacementReserves: data['replacementReserves'],
      residentialUnits: data['residentialUnits'],
      commercialUnits: data['commercialUnits'],
      residentialUnitsSqFtArea: data['residentialUnitsSqFtArea'],
      commercialUnitsSqFtArea: data['commercialUnitsSqFtArea'],
      commercialUnitsCurrentOnRent: data['commercialUnitsCurrentOnRent'],
      residentialIncome: data['residentialIncome'],
      commercialIncome: data['commercialIncome'],
      targetLTV: data['targetLTV'],
      totalAnnualNOI: data['totalAnnualNOI'],
      totalAnnualIncome: data['totalAnnualIncome'],
      totalAnnualExpenses: data['totalAnnualExpenses'],
      selectedStateCounties: data['selectedStateCounties'],
      propertyCounties: [data['propertyCounty']],
      propertyCountiesSelectedValues: [],
      sunkCost: this.getSunkCost(),
      loanRecourse: 'Full Recourse',
      submitRushedAppraisal: data['submitRushedAppraisal'],
      rural: data['rural'] === null ? null : data['rural'] == 'Y',
      section8: data['section8'] == 'Y'
    };
  }

  private saveAddressField(address: string, addressDetails, geometryDetails) {
    const parsedAddress = parseAddress(
      address,
      addressDetails,
      geometryDetails
    );

    const streetAddress = parsedAddress.street_number === null ? parsedAddress.street : parsedAddress.street_number + " " + parsedAddress.street;
    this.onFieldChange('address', streetAddress);
    this.onFieldChange('streetNumber', parsedAddress.street_number);
    this.onFieldChange('streetName', parsedAddress.street);
    this.onFieldChange('city', parsedAddress.city);
    this.onFieldChange('state', parsedAddress.state);
    this.onFieldChange('propertyCounty', parsedAddress.county);
    this.onFieldChange('zipCode', parsedAddress.zip);
    this.onFieldChange('latitude', parsedAddress.latitude);
    this.onFieldChange('longitude', parsedAddress.longitude);
    this.onFieldChange('aptNumber', parsedAddress.aptNumber);

    const addressInLicensedState = !notLicensedSfr.states.includes(
      parsedAddress.state
    );
    this.onFieldChange('__isAddressInLicensedState__', addressInLicensedState);

    const addressInCorrectFormat = true;
    this.onFieldChange('__isAddressInCorrectFormat__', addressInCorrectFormat);

    this.singlePropertyStore.loanDetailsStore.setDefaultPreferredTitle();
  }

  handlePropertyTypeChange = propertyType => {
    if (propertyType === MULTIFAMILY_5_PLUS || propertyType === MIXED_USE) {
      this.setIsPauseMultifamilySubmissionsOpen(true);
      this.onFieldChange('propertyType', '');
    }
    else {
      this.defaultUnitsFromPropertyType(propertyType);

      if (!PROPERTY_TYPES_RUSHED_APPRAISAL.includes(propertyType)) {
        this.onFieldChange('submitRushedAppraisal', false);
      }
    }
  };

  defaultUnitsFromPropertyType = value => {
    this.onFieldChange('residentialUnits', '');
    this.onFieldChange('commercialUnits', '');
    switch (value) {
      case 'Single Family':
      case 'Townhome':
      case 'Planned Unit Development':
      case 'Condominium':
        this.onFieldChange('units', 1);
        break;
      case 'Duplex':
        this.onFieldChange('units', 2);
        break;
      case 'Triplex':
        this.onFieldChange('units', 3);
        break;
      case 'Quadruplex':
        this.onFieldChange('units', 4);
        break;
      case MULTIFAMILY_5_PLUS:
        this.onFieldChange('units', 5);
        break;
      case MIXED_USE:
        this.onFieldChange('units', '');
        this.onFieldChange('residentialUnits', 1);
        this.onFieldChange('commercialUnits', 1);
        break;
      default:
        this.onFieldChange('units', '');
        break;
    }
  };

  checkIfRequiredFieldsEmpty = () => {
    let requiredFieldEmpty = false;
    const fields = this.form.fields;

    const isOneToFourProperty =
      fields.propertyType.value === SINGLE_FAMILY ||
      fields.propertyType.value === DUPLEX ||
      fields.propertyType.value === TRIPLEX ||
      fields.propertyType.value === QUADRUPLEX

    for (let property in fields) {
      if (
        fields[property].nextStep === 'required' &&
        !fields[property].value &&
        this.checkIfFieldIsEmpty(fields, property)
      ) {
        requiredFieldEmpty = true;
      }
      if (
        this.form.fields.propertyType.value === MULTIFAMILY_5_PLUS &&
        fields[property].nextStep === 'requiredForMF' &&
        !fields[property].value &&
        this.checkIfFieldIsEmpty(fields, property)
      ) {
        requiredFieldEmpty = true;
      }
      if (
        this.form.fields.propertyType.value === MIXED_USE &&
        fields[property].nextStep === 'requiredForMU' &&
        !fields[property].value &&
        this.checkIfFieldIsEmpty(fields, property)
      ) {
        requiredFieldEmpty = true;
      }
      if (
        !isOneToFourProperty &&
        fields[property].nextStep === 'requiredForOthersThanOneToFour' &&
        !fields[property].value &&
        this.checkIfFieldIsEmpty(fields, property)
      ) {
        requiredFieldEmpty = true;
      }

    }
    return requiredFieldEmpty;
  };

  checkIfRequiredPropertyFieldsEmpty = () => {
    let requiredFieldEmpty = false;
    const fields = this.form.fields;
    for (let property in fields) {
      if (
        fields[property].nextStep === 'requiredForProperty' &&
        !fields[property].value
      ) {
        requiredFieldEmpty = this.checkIfFieldIsEmpty(fields, property);
      }
    }
    return requiredFieldEmpty;
  };

  checkIfFieldIsEmpty(fields, property) {
    let requiredFieldEmpty = false;
    if (
      typeof fields[property].value === 'number' &&
      fields[property].value === 0
    ) {
      return requiredFieldEmpty;
    } else {
      this.onFieldChange(property, '');
      requiredFieldEmpty = true;
      return requiredFieldEmpty;
    }
  }

  getPricerStepErrors() {
    const fields = this.form.fields;
    let errors = {} as any;
    if (
      notLicensedSfr.states.includes(this.getProperty()?.state?.toUpperCase())
    ) {
      const errorMsg = notLicensedSfr.message;
      this.form.fields.address.error = errorMsg;
      this.singlePropertyStore.stepError = errorMsg;
      return true;
    }
    if (fields.__isAddressInCorrectFormat__.value !== true) {
      this.form.fields.address.error = 'Invalid Address Format';
      this.singlePropertyStore.stepError = 'Invalid Address Format';
    }
    if (
      fields.propertyType.value === MULTIFAMILY_5_PLUS &&
      fields.units.value < 5
    ) {
      errors.units = 'Number of Units should be equal or higher than 5';
    }
    if (fields.propertyType.value === MIXED_USE) {
      if (fields.residentialUnits.value < 1) {
        errors.residentialUnits =
          'Number of Residential units should be greater than 1';
      }
      if (fields.commercialUnits.value < 1) {
        errors.commercialUnits =
          'Number of Commercial units should be greater than 1';
      }
      if (Number(fields.residentialUnitsSqFtArea.value) <= 0) {
        errors.residentialUnitsSqFtArea =
          'Residential Units sq Ft Area should be greater than 0';
      }
      if (Number(fields.commercialUnitsSqFtArea.value) <= 0) {
        errors.commercialUnitsSqFtArea =
          'Commercial Units sq Ft Area should be greater than 0';
      }
      if (
        Number(fields.commercialUnitsSqFtArea.value) >
        Number(fields.residentialUnitsSqFtArea.value)
      ) {
        errors.residentialUnitsSqFtArea =
          'Commercial Units sq Ft Area should be less than Residential Units sq Ft Area';
      }
      if (Number(fields.residentialIncome.value) <= 0) {
        errors.residentialIncome =
          'Residential income should be greater than 0';
      }
      if (Number(fields.commercialIncome.value) <= 0) {
        errors.commercialIncome =
          'Commercial income should be greater than 0';
      }
    } else {
      if (Number(fields.units.value) < 1) {
        errors.residentialUnitsSqFtArea =
          'Number of units should be greater than or equal to 1';
      }
    }
    if (fields.propertyValuation.value <= 0) {
      errors.propertyValuation =
        'Estimated As Is Value should be greater than 0';
    }
    if (fields.monthlyGrossRent.value <= 0) {
      errors.monthlyGrossRent = 'Gross Monthly Rent should be greater than 0';
    }
    if (fields.totalAnnualNOI.value < 0) {
      errors.totalAnnualNOI = 'Property has negative income';
    }
    if (fields.acquisitionPrice.value <= 0) {
      errors.acquisitionPrice = 'Purchase Price should be greater than 0';
    }
    if (isNil(fields.section8.value)) {
      errors.section8 = 'Required';
    }
    if (fields.targetLTV.value <= 0) {
      errors.targetLTV = 'Target LTV should be greater than 0';
    }
    if (fields.targetLTV.value > 80) {
      errors.targetLTV = 'Maximum target LTV is 80%';
    }
    if (fields.loanPurpose.value === REFINANCE) {
      this.handlePropertyRefinanceValidations();
    }
    if (fields.ficoProvidedAtOrigination.value < 660) {
      errors.ficoProvidedAtOrigination =
        'Sorry we cannot underwrite loans where the borrowers have less than 660 mid-score FICO';
    }
    if (
      fields.bankruptcy.value === 'Y' &&
      !this.singlePropertyStore.isValidBankruptcyDate(
        fields.bankruptcyDate.value
      )
    ) {
      errors.bankruptcyDate = 'Bankruptcy Date must be no less than 3 Years';
    }
    if (
      fields.foreclosure.value === 'Y' &&
      !this.singlePropertyStore.isValidForeclosureDate(
        fields.foreclosureDate.value
      )
    ) {
      errors.foreclosureDate = 'Foreclosure Date must be no less than 3 Years';
    }
    if(statePropertyTypeRestriction.states.includes(this.getProperty()?.state?.toUpperCase()) && statePropertyTypeRestriction.propertyTypes.includes(fields.propertyType.value)) {
      const errorMsg = statePropertyTypeRestriction.message;
      this.form.fields.propertyType.error = errorMsg;
      this.singlePropertyStore.stepError = errorMsg;
    }

    return errors;
  }

  checkForPricerStepErrors() {
    const fields = this.form.fields;

    const errors = this.getPricerStepErrors();
    for (let name in errors) {
      this.singlePropertyStore.stepError = errors[name];
      if (fields[name]) {
        fields[name].error = errors[name];
      }
    }

    if (this.singlePropertyStore.stepError) {
      return true;
    }
  }

  checkForPropertyStepErrors() {
    if (
      notLicensedSfr.states.includes(this.getProperty()?.state?.toUpperCase())
    ) {
      const errorMsg = notLicensedSfr.message;
      this.form.fields.address.error = errorMsg;
      this.singlePropertyStore.stepError = errorMsg;
      return true;
    }
    if (
      this.form.fields.unitsOccupied.value >
      this.singlePropertyStore.getNumberOfUnits()
    ) {
      const errorMsg = 'Can not be higher than Number of Units';
      this.form.fields.unitsOccupied.error = errorMsg;
      this.singlePropertyStore.stepError = errorMsg;
      return true;
    }
  }

  handlePropertyRefinanceValidations = () => {
    const fields = this.form.fields;

    let errorMsg = '';
    const {
      acquisitionDate,
      capitalImprovements,
      anyDebt,
      totalDebtPayoff,
    } = this.form.fields;
    if (!acquisitionDate.value) {
      errorMsg = 'Purchase date cannot be blank';
      fields.acquisitionDate.error = errorMsg;
      this.singlePropertyStore.stepError = errorMsg;
      return true;
    }
    if (capitalImprovements.value === '') {
      errorMsg = 'Completed Renovations cannot be blank';
      fields.capitalImprovements.error = errorMsg;
      this.singlePropertyStore.stepError = errorMsg;
      return true;
    }
    if (capitalImprovements.value !== '') {
      if (anyDebt.value === '') {
        errorMsg = 'Current Debt On Property cannot be blank';
        fields.anyDebt.error = errorMsg;
        this.singlePropertyStore.stepError = errorMsg;
        return true;
      }
      if (anyDebt.value === 'Y') {
        if (totalDebtPayoff.value === '') {
          errorMsg = 'Current Debt Amount on the property cannot be blank';
          fields.totalDebtPayoff.error = errorMsg;
          this.singlePropertyStore.stepError = errorMsg;
          return true;
        }
      }
    }
  };

  get pricerBorrower() {
    return {
      borrowerExp: this.form.fields.borrowerExp.value,
      ficoProvidedAtOrigination: this.form.fields.ficoProvidedAtOrigination
        .value,
      bankruptcy: this.form.fields.bankruptcy.value,
      foreclosure: this.form.fields.foreclosure.value,
      citizenshipStatus: this.form.fields.citizenshipStatus.value,
    };
  }

  get foreignNational() {
    return this.form.fields.citizenshipStatus.value;
  }

  get borrowerExp() {
    return this.form.fields.borrowerExp.value;
  }

  get ficoProvidedAtOrigination() {
    return this.form.fields.ficoProvidedAtOrigination.value;
  }

  get totalMixUseEstimatedAsIsValue() {
    return null;
  }

  get residentialUnits() {
    return this.form.fields.residentialUnits.value ?? 0;
  }

  get commercialUnits() {
    return this.form.fields.commercialUnits.value ?? 0;
  }

  setIsPauseMultifamilySubmissionsOpen(open: boolean) {
    this.isPauseMultifamilySubmissionsOpen = open;
  }

}
export default PricerStore;
