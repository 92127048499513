import { GlobalStore } from "@roc/feature-app-core";
import { makeObservable } from "mobx";
import { DenialOfCreditFormStore } from "./denialOfCreditFormStore";

export class DenialOfCreditRequestStore {
  form:any;
  private globalStore: GlobalStore;
  denialOfCreditFormStore: DenialOfCreditFormStore;
  constructor(globalStore: GlobalStore){
    this.form = {
      fields: {
        firstName: {
          value: '',
          error: null,
          rule: 'required',
          attribute: 'first name',
        },
        lastName: {
          value: '',
          error: null,
          rule: 'required',
          attribute: 'last name',
        },
        phoneNumber:{
          value: '',
          error: null,
          rule: 'required',
          attribute: 'phone number',
        },
        borrowingEntityName: {
          value: '',
          error: null,
          rule: 'required',
          attribute: 'borrowing entity name',
        },
        loanId: {
          value: '',
          error: null,
          rule: '',
          attribute: 'loan id',
        },
        streetNumber: {
          value: '',
          error: null,
          rule: 'required',
          attribute: 'street number',
        },
        street: {
          value: '',
          error: null,
          rule: 'required',
          attribute: 'street',
        },
        city: {
          value: '',
          error: null,
          rule: 'required',
          attribute: 'city',
        },
        zipCode: {
          value: '',
          error: null,
          rule: 'required',
          attribute: 'zip code',
        },
        email: {
          value: '',
          error: null,
          rule: 'required|email',
          attribute: 'email',
        },
        rationale: {
          value: '',
          error: null,
          rule: 'required',
          attribute: 'rationale name',
        },
      },
      meta: {
        isValid: false,
        error: null,
      },
    };
    this.globalStore = globalStore;
    this.denialOfCreditFormStore = new DenialOfCreditFormStore(globalStore, this.form);

    makeObservable(this,{});
  }


  reset(){
    this.denialOfCreditFormStore.reset();
  }
}