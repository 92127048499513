import { observer } from 'mobx-react';
import { PreflightStep } from '../../../utils/constants';
import { BrokerInformationAccordion } from '../../summary/components/brokerInformation/brokerInformationAccordion';
import { BrokerInformationEmptyCard } from '../../summary/components/brokerInformation/brokerInformationEmptyCard';
import { BrokerInformationForm } from '../../summary/components/brokerInformation/brokerInformationForm';
import { PrefligthCard } from './preflightCard';

export const PrefligthBrokerCard = observer(({ store }) => {
  const { brokerFormStore } = store.brokerStore;
  const hasBroker = brokerFormStore.form.fields.hasBroker.value;

  const step = PreflightStep.BROKER;
  const onDeleteClick = () => {};

  return (
    <PrefligthCard
      store={store}
      step={step}
      onEdit={() => store.preflightStore.goToStep(step)}
      onReset={() => {
        store.brokerStore.brokerFormStore.resetBrokerInfo();
      }}
      onSave={() => {
        store.preflightStore.goToNextStep();
      }}
      // onskip={() => {
      //   store.brokerstore.sethasbroker(false);
      //   store.preflightstore.gotonextstep();
      // }}
      FormComponent={() => (
        <BrokerInformationForm
          store={store.brokerStore}
          onDeleteClick={onDeleteClick}
          showActions={false}
        />
      )}
      AccordionComponent={props => (
        <>
          {hasBroker && (
            <BrokerInformationAccordion
              {...props}
              store={store.brokerStore.brokerFormStore}
              showActions={true}
            />
          )}
          {!hasBroker && (
            <BrokerInformationEmptyCard
              onAddBroker={() => {
                store.brokerStore.setHasBroker(true);
                store.preflightStore.goToStep(step);
              }}
            />
          )}
        </>
      )}
    />
  );
});
