import { Layout, createAutocompleteAddressField } from '@roc/client-portal-shared/components';
import { observer } from 'mobx-react';
import { Paper } from '@roc/ui';
import { Box, Grid, InputAdornment, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { Search, WarningOutlined } from '@material-ui/icons';
import { PropertiesGridView } from '../gridViewScreen/propertiesGridView';
import { useStore } from '@roc/client-portal-shared/hooks';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontSize: '48px'
    },
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      padding: '80px',
      textAlign: 'justify'
    },
    paper: {
      height: '100%'
    },
    box: {
      width: '60%',
      flexDirection: 'column'
    },
    body: {
      marginBottom: '30px'
    },
    warningBox: {
      backgroundColor: '#FFF4E5',
      borderRadius: '14px',
      border: '1px solid #EF6C00',
      width: '60%',
      flexDirection: 'column',
      marginTop: '30px',
      padding: '5px'
    },
    content: {
      padding: theme.spacing(2),
      paddingTop: theme.spacing(1),
    },
    titleWarning: {
      color: '#663C00'
    },
    icon: {
      color: theme.palette.warning.light,
    },
    gridBody: {
      padding: '0px 8px'
    },
    borderText: {
      border: '1px solid #EF6C00'
    }
  })
);

export const SearchPropertyScreen = observer(
  () => {
    const { compToolStore } = useStore();

    const { searchPropertyFormStore } = compToolStore;
    const classes = useStyles();

    const handleAddressChange = event => {
      const { value, fullInfo } = event?.target || {};
      const { address_components, geometry } = fullInfo || {};
      searchPropertyFormStore.saveAddressField(
        value,
        address_components,
        geometry
      );
      compToolStore.setShowWarningMessage(false);
      if (address_components && geometry) {
        compToolStore.reset();
        compToolStore.fetchCompToolProperties(1, true);
      }
    };

    return (
      <>
        {compToolStore.compResponse ? (
          <PropertiesGridView />
        ) : (
          <Layout>
            <Paper className={classes.paper}>
              <Box className={classes.container}>
                <Box padding="10px">
                  <Typography className={classes.title}>
                    Comparable Properties Analytics
                  </Typography>
                </Box>
                <Box className={classes.box}>
                  <Typography variant="subtitle1" className={classes.body}>
                    One way to evaluate whether your home has been assessed fairly, or uniformly, is to compare its assessment to the assessment of other comparable homes.
                    <br />  <br />
                    To use the Comp Tool, type the property address of the subject property to see comparable properties.
                  </Typography>
                  {createAutocompleteAddressField({
                    fieldName: 'address',
                    testId: 'address',
                    store: searchPropertyFormStore,
                    onChange: handleAddressChange,
                    showToolTip: false,
                    standaloneLabel: false,
                    InputProps: ({
                      endAdornment: (
                        <InputAdornment position="end" >
                          <Search />
                        </InputAdornment>
                      ),
                      classes: {
                        notchedOutline: compToolStore.showWarningMessage ? classes.borderText : ''
                      }
                    }),

                    compTool: true
                  })}
                </Box>
                {compToolStore.showWarningMessage &&
                  <Box className={classes.warningBox}>

                    <Grid container className={classes.content} spacing={2}>
                      <Grid item >
                        <WarningOutlined className={classes.icon} />
                      </Grid>
                      <Grid item xs={11}>
                        <Typography gutterBottom className={classes.titleWarning}>
                          <strong>
                            Property Information Not Found
                          </strong>
                          <br />
                        </Typography>

                      </Grid>
                      <Grid item xs={12} className={classes.gridBody}>
                        <Typography gutterBottom>
                          We apologize, but we could not find any information for this property. Our team has been notified and will work on retrieving data for this address soon. Please try again with a different address.

                        </Typography>
                      </Grid>
                    </Grid >
                  </Box>
                }
              </Box>
            </Paper>
          </Layout>
        )}
      </>
    );
  }
);
