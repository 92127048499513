import {
  Grid,
  Typography,
  Link
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { useLoanStore } from '@roc/feature-loans-shared';
import { Paper } from '@roc/ui';

const useStyles = makeStyles(theme => ({
  container: {
    padding: 32,
    display: 'flex',
    justifyContent: 'center'
  },
}));

interface PayoffContactInformationProps { }

export const PayoffContactInformationDetails = () => {
  const { loanStore } = useLoanStore();
  const showFayServicingContact = ['Fay Servicing'].includes(loanStore.loanDetails.servicer);
  const showPlanetHomeLendingContact = ['Planet Home Lending'].includes(loanStore.loanDetails.servicer);
  const showPlanetHomePortfolioContact = ['Planet Portfolio'].includes(loanStore.loanDetails.servicer);

  return (
    <Typography variant="h6">
      To request payoff, please reach out to&nbsp;
      {showFayServicingContact && (<Link href={`mailto:fayservicing@loanservicerllc.com`}>{'fayservicing@loanservicerllc.com'}</Link>)}
      {showPlanetHomeLendingContact &&
        (<Link href={`mailto:planethomelending@loanservicerllc.com`}>{'planethomelending@loanservicerllc.com'}</Link>)}
      {showPlanetHomePortfolioContact &&
        (<Link href={`commercialloanServicing-dg@planetloanservicing.com`}>{'commercialloanServicing-dg@planetloanservicing.com'}</Link>)}
    </Typography>
  );
};

export const PayoffContactInformation = (props: PayoffContactInformationProps) => {
  const classes = useStyles();
  return (
    <Grid item xs={12} md={12}>
      <Paper className={classes.container}>
        <PayoffContactInformationDetails />
      </Paper>
    </Grid>
  );
};
