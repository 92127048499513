import { CdaAndAppraisalOrderDetailsService } from '../../services/documentForms/cdaAndAppraisalOrderDetailsService';
import { action, computed, flow, makeObservable, observable } from 'mobx';
import { ApiResponse, GlobalStore, GridStore } from '@roc/feature-app-core';
import { DocumentStore } from '@roc/feature-documents';
import { LoanStore } from '@roc/feature-loans';
import { DocumentService } from '../../services/documentService';
import { Document } from '@roc/feature-types';
import { ARR, CDA, LoanSubType, downloadDocument, isNil } from '@roc/feature-utils';

export class CdaAndAppraisalOrderDetailsStore {
  private documentStore: DocumentStore;
  private globalStore: GlobalStore;
  private cdaAndAppraisalOrderDetailsService: CdaAndAppraisalOrderDetailsService;
  private documentService: DocumentService;
  loanId: string;
  public loanStore: LoanStore;
  public fastTrackFees: any = {};
  public loanFastTrackInfo: any = {};
  public cdaOrderGridStore: GridStore;
  public arrOrderGridStore: GridStore;
  public isAllAppraisalDocumentAccepted: boolean;
  public variationAbove10: boolean;
  public isInternal: boolean;
  appraisalDetailsGridStore: GridStore;
  appraiserDetailsGridStore: GridStore;
  public loanCdaPaymentUrl: string;
  public cdaLoading: boolean;
  public propertiesWithDeviation: any[];
  public radioValue: string = 'cda';
  public secondRadioValue: boolean = true;
  public isCdaRushed: boolean = false;

  constructor(
    loanStore: LoanStore,
    documentStore: DocumentStore,
    globalStore: GlobalStore
  ) {
    this.loanStore = loanStore;
    this.documentStore = documentStore;
    this.globalStore = globalStore;
    this.cdaAndAppraisalOrderDetailsService = new CdaAndAppraisalOrderDetailsService();
    this.documentService = new DocumentService();
    this.isInternal = false;
    this.cdaOrderGridStore = new GridStore(
      () => this.fetchCdaArrOrderGridStore('CDA'),
      () => this.processPropertyCDAs()
    );
    this.arrOrderGridStore = new GridStore(
      () => this.fetchCdaArrOrderGridStore('ARR'),
      () => this.processPropertyARRs()
    );
    this.appraisalDetailsGridStore = new GridStore(
      () => this.fetchPropertyAppraisals(),
      () => this.processPropertyAppraisals()
    );
    this.appraiserDetailsGridStore = new GridStore(
      () => this.setPropertyAppraiserDetails(),
    );
    this.loanCdaPaymentUrl = null;
    this.cdaLoading = false;
    makeObservable(this, {
      acceptDocument: flow,
      updatePaymentOptions: flow,
      downloadCdaSignedDocument: flow,
      downloadTamariskSignedDocument: flow,
      updateLoanFastrackInfo: flow,
      reset: action,
      isBridgeSubType: computed,
      loadFastTrackFees: flow,
      loadFastTrackInfo: flow,
      requestCdaPayment: flow,
      updateAppraisalAndCdaCheck: flow,
      isAllAppraisalDocumentAccepted: observable,
      isInternal: observable,
      setIsInternal: action,
      borrowersEmail: computed,
      setLoanId: action,
      loanCdaPaymentUrl: observable,
      setLoanCdaPaymentUrl: action,
      getCdaPaymentUrl: flow,
      cdaLoading: observable,
      setCdaLoading: action,
      variationAbove10: observable,
      getPropertiesWithDeviation: flow,
      propertiesWithDeviation: observable,
      radioValue: observable,
      setRadioValue: action,
      secondRadioValue: observable,
      setSecondRadioValue: action,
      isCdaRushed: observable,
      setIsCdaRushed: action,
      fetchDesktopAppraisalPreference: flow
    });
  }

  private async fetchCdaArrOrderGridStore(type) {
    try {
      const response: ApiResponse = await this.cdaAndAppraisalOrderDetailsService.getCdaArrOrderGrid(this.isInternal, this.loanStore.loanDetails.loanId, type);
      const newResponse = {
        ...response,
        data: {
          ...response.data,
          data: {
            rows: this.isInternal ? response.data : JSON.parse(response.data?.data),
          },
        },
      };
      return newResponse;
    } catch (error) {
      console.log(error);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching ARR order details.',
      });
    }
  }
  setLoanId(loanId) {
    this.loanId = loanId;
  }

  private async fetchPropertyAppraisals() {
    try {
      const response: ApiResponse = await this.cdaAndAppraisalOrderDetailsService.getPropertyAppraisal(
        this.isInternal,
        this.loanId,
        'APPRAISAL'
      );
      const newResponse = {
        ...response,
        data: {
          ...response.data,
          data: {
            rows: this.isInternal ? response.data : JSON.parse(response.data?.data),
          },
        },
      };
      return newResponse;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching property appraisal details.',
      });
    }
  }

  private async processPropertyAppraisals() {
    this.appraisalDetailsGridStore.gridData.data.rows = this.appraisalDetailsGridStore.gridData.data.rows.map(
      x => this.processPropertyAppraisal(x)
    );
  }

  private processPropertyAppraisal(row) {
    const appraisalOrderJson = JSON.parse(
      row.propertyAppraisalOrderJson || null
    );

    return {
      ...row,
      orderId: appraisalOrderJson?.orderId,
      propertyAddress: appraisalOrderJson?.property?.address,
      additionalAppraisalDetailsJson: row.additionalAppraisalDetailsJson,
    };
  }

  private async processPropertyCDAs() {
    this.cdaOrderGridStore.gridData.data.rows = this.cdaOrderGridStore.gridData.data.rows.map(
      x => this.processPropertyCDA(x)
    );
  }

  private async processPropertyARRs() {
    this.arrOrderGridStore.gridData.data.rows = this.arrOrderGridStore.gridData.data.rows.map(
      x => this.processPropertyCDA(x)
    );
  }


  private processPropertyCDA(row) {
    const cdaOrderJson = JSON.parse(
      row.propertyAppraisalOrderJson || null
    );

    return {
      ...row,
      estimatedDeliveryDate: cdaOrderJson?.estimatedDeliveryDate,
      orderId: cdaOrderJson?.orderId ?? row.orderId,
      propertyAddress: cdaOrderJson?.propertyJson?.address,
      additionalAppraisalDetailsJson: row.additionalAppraisalDetailsJson,

    };
  }

  private async setPropertyAppraiserDetails() {
    try {
      const rows = this.appraisalDetailsGridStore.gridData.data.rows
        .filter(x => x.additionalAppraisalDetailsJson)
        .map(x => {
          const appraiserDetailsJson = JSON.parse(x.additionalAppraisalDetailsJson);
          return {
            ...appraiserDetailsJson?.appraiserDetails,
            orderId: x?.orderId,
          };
        });
      const response: ApiResponse = {
        data: {
          data: {
            rows,
          },
        },
        headers: null,
      };
      return response;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching appraiser details.',
      });
    }
  }

  isBorrowerPayingGfd = () => {
    return !this.isBridgeSubType && (this.globalStore.userFeatures.tamariskGfd)
  }

  closeDialog = () => {
    this.documentStore.documentFormStore.setCurrentForm(null);
  }

  *acceptDocument(document: Document) {
    try {
      const loanDocument = {
        ...document,
      };
      const paymentOptions = {
        loanId: document.loanId,
        gfdPayment: false,
        tamariskPayment: false
      };
      this.updatePaymentOptions(paymentOptions);
      yield this.documentService.updateDocument(loanDocument);
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Document was accepted',
      });
      this.documentStore.fetchDocuments(this.loanStore.loanDetails.loanId);
    } catch (err) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while changing document status',
      });
    }
  }

  *acceptFastrackDocument(document: Document) {
    try {
      const loanDocument = {
        ...document,
      };
      // const fastTrackOptions = {
      //   loanId: document.loanId,
      //   insurance: false,
      //   insuranceReview: false,
      //   titleSearch: false,
      // };
      // this.updateLoanFastrackInfo(fastTrackOptions);
      yield this.documentService.updateDocument(loanDocument);
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Document was accepted',
      });
      this.documentStore.fetchDocuments(this.loanStore.loanDetails.loanId);
    } catch (err) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while changing document status',
      });
    }
  }

  *updatePaymentOptions(paymentOptions: any) {
    try {
      yield this.cdaAndAppraisalOrderDetailsService.updateLoanPaymentFlowData(paymentOptions);
      yield this.loanStore.fetchLoanDetails(this.loanStore.loanDetails.loanId);
    }
    catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while saving the modal state',
      });
    }
    this.closeCurrentModal();
  }


  *updateAppraisalAndCdaCheck(value, type, isCdaRushed) {
    try {
      const response = yield this.cdaAndAppraisalOrderDetailsService.updateAppraisalAndCdaCheck(this.isInternal, this.loanStore.loanDetails.loanId, value, type, isCdaRushed);
      if (this.isInternal) {
        return response.data?.cardknoxUrl;
      }
      return JSON.parse(response?.data?.data || '{}').cardknoxUrl;
    }
    catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while confirming payment options',
      });
      return false;
    }
  }

  *updateLoanFastrackInfo(paymentOptions: any) {
    try {
      yield this.cdaAndAppraisalOrderDetailsService.updateLoanFastrackInfo(paymentOptions);
      yield this.loanStore.fetchLoanDetails(this.loanStore.loanDetails.loanId);
    }
    catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while saving the modal state',
      });
    }
    this.closeCurrentModal();
  }

  *downloadTamariskSignedDocument(entityId: string, entityType: string) {
    try {
      const response = yield this.cdaAndAppraisalOrderDetailsService.downloadSignedDocument(this.isInternal, entityId, entityType);
      downloadDocument(
        response?.data,
        response?.headers,
        'download',
        'signed_document.pdf'
      );
    } catch (err) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while downloading signed document.',
      });
    }
  }
  *downloadCdaSignedDocument(entityId: string, entityType: string) {
    try {
      const response = yield this.cdaAndAppraisalOrderDetailsService.downloadSignedDocument(this.isInternal, entityId, entityType);
      downloadDocument(
        response?.data,
        response?.headers,
        'download',
        'signed_document.pdf'
      );
    } catch (err) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while downloading signed document.',
      });
    }
  }

  closeCurrentModal() {
    return this.documentStore.documentFormStore.setCurrentForm(null);
  }

  reset() {
    this.isAllAppraisalDocumentAccepted = false;
  }

  get isBridgeSubType() {
    const { loanSubType } = this.loanStore.loanDetails;
    return (
      loanSubType === LoanSubType.FIX_AND_FLIP || loanSubType === LoanSubType.FIX_AND_FLIP_PRO || loanSubType === LoanSubType.STABILIZED_BRIDGE || loanSubType === LoanSubType.GROUND_UP
    );
  }

  *loadFastTrackFees() {
    try {
      const response = yield this.cdaAndAppraisalOrderDetailsService.getLoanFastTrackFees();

      this.fastTrackFees = response.data.data;
    } catch (err) {
    }
  }

  *loadFastTrackInfo(loanId) {
    try {
      const response = yield this.cdaAndAppraisalOrderDetailsService.getLoanFastTrackInfo(loanId);

      this.loanFastTrackInfo = response.data.data;
    } catch (err) {
    }
  }
  get borrowersEmail() {
    return this.loanStore.loanDetails.borrowers
      .filter(
        borrower =>
          borrower.emailAddress != null &&
          borrower.emailAddress != '' &&
          borrower.emailAddress != undefined
      )
      .map(b => b.emailAddress)
      .join('; ');
  }

  *requestCdaPayment(loanId, type) {
    try {
      yield this.cdaAndAppraisalOrderDetailsService.requestCdaPayment(this.isInternal, loanId, type);
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Payment email successfully sent',
      });
    } catch (err) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error on sending payment email',
      });
    }
  }

  setIsInternal(isInternal: boolean) {
    this.isInternal = isInternal;
  }

  setLoanCdaPaymentUrl(url?: string) {
    this.loanCdaPaymentUrl = null;
  }

  *getCdaPaymentUrl(loanId) {
    try {
      this.cdaLoading = true;
      const response = yield this.cdaAndAppraisalOrderDetailsService.getCdaPaymentUrl(this.isInternal, loanId);

      if (this.isInternal) {
        this.loanCdaPaymentUrl = response.data?.url;
      } else {
        this.loanCdaPaymentUrl = response.data?.data?.url;
      }
      this.cdaLoading = false;
    } catch (e) {
      this.cdaLoading = false;
    }
  }

  setCdaLoading(value) {
    this.cdaLoading = value;
  }

  *getPropertiesWithDeviation(loanId: number) {
    const response = yield this.cdaAndAppraisalOrderDetailsService.getPropertiesWithDeviation(this.isInternal, loanId);
    this.propertiesWithDeviation = response.data.data;
  }

  setRadioValue(value: string) {
    this.radioValue = value;
  }

  setSecondRadioValue(value: boolean) {
    this.secondRadioValue = value;
  }

  setIsCdaRushed(value: boolean) {
    this.isCdaRushed = value;
  }

  *fetchDesktopAppraisalPreference() {
    const lenderId = this.isInternal ? this.loanStore.loanDetails?.lenderId : this.globalStore.lenderInfo?.lenderId;
    const arrEnabled = this.loanStore.loanDetails?.arrEnabled;
    const cdaEnabled = this.loanStore.loanDetails?.cdaEnabled;
    if (cdaEnabled && !arrEnabled) {
      this.radioValue = CDA;
    }
    else if (!cdaEnabled && arrEnabled) {
      this.radioValue = ARR;
    }
    else {
      const response = yield this.cdaAndAppraisalOrderDetailsService.getDesktopAppraisalPreference(this.isInternal, lenderId);
      this.radioValue = JSON.parse(response).data?.data?.toLowerCase();
    }
  }

}

export default CdaAndAppraisalOrderDetailsStore;
