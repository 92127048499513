import { Button, PrintableAreaRef, StandardDialog } from '@roc/ui';
import { observer } from 'mobx-react';
import { useRef } from 'react';
import { QuoteTypeStore } from '../../../stores/quoteTypeStore';
import { DownloadableQuote } from './downloadableQuote';

export type DownloadQuoteDialogProps = {
  open?: boolean;
  onClose?: () => void;
  store?: QuoteTypeStore;
};

export const DownloadQuoteDialog = observer(
  ({ store, open, onClose }: DownloadQuoteDialogProps) => {
    const ref = useRef<PrintableAreaRef>(null);

    const downloadQuote = () => {
      const property:any = store.propertiesStore.getProperties()?.[0];
      const pdfName = `${property.streetNumber} ${property.streetName} Quote.pdf`;
      ref.current.downloadAsPdf(pdfName);
    };

    const dialogActions = (
      <>
        <Button
          testId="cancel-download-quote"
          color="primary"
          variant="outlined"
          style={{ marginRight: '8px' }}
          onClick={onClose}
        >
          CANCEL
        </Button>
        <Button
          testId="download-quote"
          color="primary"
          variant="contained"
          onClick={downloadQuote}
        >
          DOWNLOAD
        </Button>
      </>
    );

    return (
      <StandardDialog
        open={open}
        title="Download Quote"
        maxWidth="md"
        handleClose={onClose}
        dialogActions={dialogActions}
        dialogContent={<DownloadableQuote store={store} ref={ref} />}
      />
    );
  }
);
