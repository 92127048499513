import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { RadioField } from '@roc/ui';
import { ScopeOfWorkV2Option } from '@roc/feature-sow-shared/stores/v2';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    marginBottom: theme.spacing(3),
    fontSize: 26,
  },
}));

export type ScopeOfWorkV2OptionsProps = {
  option: ScopeOfWorkV2Option;
  optionsToSelect: any;
  onOptionChange: (option: ScopeOfWorkV2Option) => void;
};

export const ScopeOfWorkV2Options = (props: ScopeOfWorkV2OptionsProps) => {
  const { option, optionsToSelect, onOptionChange } = props;

  const classes = useStyles();

  return (
    <Grid>
      <Typography className={classes.title} color="primary">
        Scope of Work
      </Typography>
      <Grid item xs={12}>
        <RadioField
          standaloneLabel
          label="Property’s Scope of Work"
          name="selectedScopeOfWorkLinkOption"
          value={option}
          options={optionsToSelect}
          onChange={onOptionChange}
          fullWidth
          row
          testId="selectedScopeOfWorkLinkOption"
        />
      </Grid>
    </Grid>
  );
};

export default ScopeOfWorkV2Options;
