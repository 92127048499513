import { Box } from '@material-ui/core';
import { useDocumentStore } from '@roc/feature-documents';
import { Document } from '@roc/feature-types';
import { StandardDialog } from '@roc/ui';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { LOAN_FASTRACK } from './constants/loanFastrackPayments';
import { LoanFastrackPaymentsBody } from './loanFastrackPaymentModule';
import { DecodedData } from './loanFastrackPaymentsUtils';

export type LoanFastTrackPaymentDialogProps = {
  loanId?: string;
  open?: boolean;
  onClose?: () => void;
  document: Document;
};

export const BorrowerLoanFastTrackPaymentDialog = observer(
  (props: LoanFastTrackPaymentDialogProps) => {
    const { loanId, open, onClose, document } = props;
    const [entityData, setEntityData] = useState<DecodedData>();
    const { documentStore } = useDocumentStore();
    const { documentFormStore } = documentStore;
    const { loanFastrackPaymentStore } = documentFormStore;
    const { loanFastTrackInfo } = loanFastrackPaymentStore;

    useEffect(() => {
      if (open && loanId) {
        loanFastrackPaymentStore.loadFastTrackInfo(loanId);
      }
      return () => {
        loanFastrackPaymentStore.reset();
      }
    }, [loanId, open]);

    useEffect(() => {
      if (loanFastTrackInfo?.randomLoanId) {
        setEntityData({
          entityId: loanFastTrackInfo?.randomLoanId + '',
          tamariskFlowType: LOAN_FASTRACK,
        });
      } else {
        setEntityData(undefined);
      }
    }, [loanFastTrackInfo]);

    return (
      entityData ?
        <StandardDialog
          open={open}
          title="Loan Fast Track Payment"
          maxWidth="lg"
          handleClose={onClose}
          removeContentBox
          dialogContent={<Box width={"100%"}><LoanFastrackPaymentsBody entityData={entityData} /></Box>}
        /> : null
    );
  }
);
