import { Route } from 'react-router';
import { Layout, Page } from '@roc/feature-app-core';
import { LenderDetails } from '@roc/client-portal-shared/modules/lenderDetails';

export const getLenderDetailsInternalRoutesConfig = (
  basePath: string,
  baseUrl: string
) => {
  return {
    lenderDetails: {
      path: `${basePath}/lender-details`,
      url: `${baseUrl}/lender-details`,
      documentTitle: 'Lender Details',
    },
  };
};

export const getLenderDetailsInternalRoutes = (
  basePath: string,
  baseUrl: string
) => {
  const config = getLenderDetailsInternalRoutesConfig(basePath, baseUrl);

  return {
    lenderDetails: (
      <Route exact path={config.lenderDetails.path}>
        <Page routeDefinition={config.lenderDetails}>
          <LenderDetails />
        </Page>
      </Route>
    )
  };
};
