import { makeObservable, observable, flow, action, computed } from 'mobx';
import { GlobalStore } from '@roc/feature-app-core';
import { FileUpload } from '../types/resolutionForm';
import { DocumentStore } from '@roc/feature-documents';
import { Document } from '@roc/feature-types';
import { downloadDocument, GENERIC_ERROR_MESSAGE, UNDERWRITING } from '@roc/feature-utils';
import { isFileAlreadyUploaded } from 'libs/feature-documents/src/documents/utils/documents';
import { CreditReport } from '../types/creditReport';
import { CreditReportService } from '../services/creditReportService';

export class CreditReportStore {
  globalStore: GlobalStore;
  creditReport: CreditReport;
  private creditReportService: CreditReportService;
  internal: boolean;
  documentStore: DocumentStore;
  document: Document;
  ssnAtLoad: boolean;
  fileIdRequired: boolean;

  constructor(globalStore: GlobalStore, documentStore: DocumentStore) {
    this.globalStore = globalStore;
    this.documentStore = documentStore;
    this.creditReportService = new CreditReportService();
    this.internal = false;
    this.creditReport = this.initializeCreditReport();
    this.ssnAtLoad = false;
    this.fileIdRequired = false;


    makeObservable(this, {
      creditReport: observable,
      internal: observable,
      document: observable,
      ssnAtLoad: observable,
      fileIdRequired: observable,
      uploadDocument: flow,
      downloadDocument: flow,
      downloadDocumentInternal: flow,
      setInternal: action,
      loadCreditReport: flow,
      reissueCreditReport: flow,
      isFileIdRequired: flow,
      showErrorMessage: action,
    });
  };

  setCreditFileId(fileId: String) {
    this.creditReport.fileId = Number(fileId);
  }

  setBorrowerSsn(ssn: string) {
    this.creditReport.ssn = ssn;
  }

  setInternal(internal: boolean) {
    this.internal = internal;
  }

  *loadCreditReport(documentId) {
    try {
      const response = yield this.creditReportService.getCheckCreditReport(documentId, this.internal);
      let data = response.data;
      if (!this.internal) {
        data = JSON.parse(response.data?.data || '{}');
      }
      this.document = JSON.parse(data.document);
      this.creditReport.fileId = data.fileId;
      this.creditReport.ssn = data.ssn;
      this.ssnAtLoad = !!data.ssn;
      this.creditReport.errorResponseMessage = data.errorResponseMessage
    } catch (error) {
      console.log(error);
    }
  }

  *isFileIdRequired(key) {
    try {
      const response = yield this.creditReportService.isFileIdRequired(key, this.internal);
      console.log('response: ', response);
      this.fileIdRequired = response.data.flag;
    } catch (error) {
      console.log(error);
    }
  }

  *reissueCreditReport(onSuccess) {
    try {
      const response = yield this.creditReportService.getReissueCreditReport(this.document.loanDocumentId, this.creditReport.fileId, this.internal, this.creditReport.ssn);

      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Success',
      });
      onSuccess();
    } catch {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error saving data',
      });
    }
  }

  *downloadDocument() {
    if (this.internal) {
      this.downloadDocumentInternal();
    } else {
      yield this.documentStore.downloadDocument(this.documentStore.currentDocument);
    }
  }

  *downloadDocumentInternal() {
    try {
      const response = yield this.creditReportService.downloadDocumentInternal(this.document);
      downloadDocument(
        response?.data,
        response?.headers,
        'download',
        this.document.originalFileName
      );
    } catch (err) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE
      });
    }
  };

  get canDownloadFile() {
    if (this.internal) {
      return isFileAlreadyUploaded(this.document);
    } else {
      return this.documentStore.currentDocument?.canDownloadFile;
    }
  };

  *uploadDocument(fileUpload: FileUpload) {
    if (fileUpload.file) {
      try {
        const blob = fileUpload.file.slice(0, fileUpload.file.size, fileUpload.file.type);
        const newFile = new File([blob], fileUpload.name, { type: fileUpload.file.type });
        const formData = new FormData();
        formData.append('file', newFile);
        yield this.creditReportService.uploadFile(this.document.loanDocumentId, formData, this.internal);
      } catch {
        this.globalStore.notificationStore.showErrorNotification({
          message: 'Error uploading file',
        });
      }
    }
  }

  showErrorMessage(message: string) {
    this.globalStore.notificationStore.showErrorNotification({
      message,
    });
  }

  initializeCreditReport() {
    return {
      fileId: null,
      loanId: null,
      borrowerId: null,
      objectType: null,
      collateralId: null,
      documentId: null,
      comment: null,
      itemSection: null,
      itemName: null,
      itemDescription: null,
      status: null,
      ssn: null,
      errorResponseMessage: null
    }
  }
}