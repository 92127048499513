import { makeObservable, observable } from 'mobx';
import { LenderFeesStore } from './lenderFeesStore';
import { MailingListsStore } from './mailingListsStore';
import { SubscriptionDetailsStore } from './subscriptionDetailsStore';
import { LoanFundingPreferenceStore } from './loanFundinPreferenceStore';
import { DefaultFeesStore } from './defaultFeesStore';
import { LoanEconomicsStore } from './loanEconomicsStore';
import { ApprovalFlowStepperStore } from './approvalFlowStepperStore';
import { LenderDetailsStore } from '../../lenderDetails/stores/lenderDetailsStore';
import { ManageUsersStore } from '@roc/feature-manage-users';
import { ExternalLenderStore } from './externalLenderStore';
import { BaseLenderStore } from './baseLenderStore';
import { LenderBankInformationStore } from 'libs/feature-backoffice-users/src/backOfficeUsers/stores/lenderBankInformationStore';


export class CompanyProfileStore {
  selectedTabIndex = 0;
  displayCompanyProfile = false;

  salesforceLenderStore;
  mailingListsStore: MailingListsStore;
  lenderFeesFormStore: LenderFeesStore;
  defaultFeesStore: DefaultFeesStore;
  subscriptionDetailsStore: SubscriptionDetailsStore;
  loanFundingPreferenceStore: LoanFundingPreferenceStore;
  lenderDetailsStore: LenderDetailsStore;
  loanEconomicsStore: LoanEconomicsStore;
  approvalFlowStepperStore: ApprovalFlowStepperStore;
  lenderBankInformationStore: LenderBankInformationStore;

  constructor(globalStore, salesforceLenderStore) {
    this.salesforceLenderStore = salesforceLenderStore;

    this.mailingListsStore = new MailingListsStore(
      globalStore,
      salesforceLenderStore
    );
    this.lenderFeesFormStore = new LenderFeesStore(
      globalStore,
      salesforceLenderStore
    );
    this.subscriptionDetailsStore = new SubscriptionDetailsStore(
      globalStore,
      salesforceLenderStore
    );

    this.lenderDetailsStore = new LenderDetailsStore(
      globalStore,
      salesforceLenderStore
    );

    this.loanEconomicsStore = new LoanEconomicsStore(
      globalStore,
      salesforceLenderStore
    )

    this.loanFundingPreferenceStore = new LoanFundingPreferenceStore(
      globalStore
    );

    this.approvalFlowStepperStore = new ApprovalFlowStepperStore(
      globalStore,
      salesforceLenderStore
    );

    this.defaultFeesStore = new DefaultFeesStore(globalStore, salesforceLenderStore, this.lenderFeesFormStore);

    //this.manageUsersStore = new ManageUsersStore(globalStore);

    this.lenderBankInformationStore = new LenderBankInformationStore(globalStore);

    makeObservable(this, {
      displayCompanyProfile: observable,
      selectedTabIndex: observable,
    });
  }

  setSelectedTabIndex(selectedTabIndex) {
    this.selectedTabIndex = selectedTabIndex;
  }

  setDisplayCompanyProfile(displayCompanyProfile) {
    this.displayCompanyProfile = displayCompanyProfile;
  }

  getIndexFromTab(tabs, selectedTab) {
    if (selectedTab === 'b') {
      selectedTab = 'BANK INFORMATION';
      const tab = tabs.findIndex(tab => tab.label === selectedTab);
      this.setSelectedTabIndex(tab);
    }

  }
}
