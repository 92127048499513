import { EditableDialog } from '@roc/ui';
import { observer } from 'mobx-react';
import { useBrokersStore } from '../../hooks/useBrokersStore';
import { LoanBrokerForm } from '../../brokerForm/loanBrokerForm';

export const EditBrokerDialog = observer(() => {
  const { brokersStore } = useBrokersStore();
  const { selectBrokersStore, manageBrokerFormStore } = brokersStore;
  const { editDialogState } = selectBrokersStore;

  const confirm = () => selectBrokersStore.confirmEditBrokerDialog();
  const close = () => selectBrokersStore.closeEditBrokerDialog();
  return (
    <EditableDialog
      open={editDialogState !== null}
      dialogState={editDialogState}
      title="Broker"
      handleClose={close}
      dialogContent={<LoanBrokerForm />}
      handleAdd={confirm}
      handleEdit={confirm}
    />
  );
});

export default EditBrokerDialog;
