import { Page } from '@roc/feature-app-core';
import { Route } from 'react-router';
import { TitleAgentHome } from '../titleAgent/titleAgentHome';

export const getDashboardRoutesConfig = (basePath: string, baseUrl: string) => {
  return {
    dashboard: {
      path: `${basePath}/dashboard`,
      url: `${baseUrl}/dashboard`,
      documentTitle: 'Dashboard',
    },
  };
};

export const getDashboardRoutes = (basePath: string, baseUrl: string) => {
  const config = getDashboardRoutesConfig(basePath, baseUrl);
  console.log(config);
  return {
    dashboard: (
      <Route exact path={config.dashboard.path}>
        <Page routeDefinition={config.dashboard}>
          <TitleAgentHome />
        </Page>
      </Route>
    ),
  };
};
