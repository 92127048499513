import React, { useEffect } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';

import {
  RadioField,
  SelectField,
  yesNoOptions,
  TextField,
  CurrencyField,
  NumberFormat,
} from '@roc/ui';
import { observer } from 'mobx-react';
import {
  DUPLEX,
  QUADRUPLEX,
  SINGLE_FAMILY,
  TRIPLEX,
  isNotBlank,
} from '@roc/feature-utils';
import { SinglePropertyPropertiesStore } from '../../stores/singleProperty/singlePropertyPropertiesStore';
import { MIXED_USE, MULTIFAMILY_5_PLUS, REFINANCE } from '@roc/feature-utils';
import {
  AnnualAdministrativeExpense,
  AnnualHoa,
  AnnualInsurance,
  AnnualMarketingExpense,
  AnnualPayrollExpense,
  AnnualPropertyManagementFees,
  AnnualRepairMaintenanceExpenses,
  AnnualReplacementReserve,
  AnnualTaxes,
  AnnualUtilityExpenses,
  AsIsValue,
  CommercialUnits,
  CommercialUnitsArea,
  CommercialUnitsCurrentOnRent,
  County,
  GrossMonthlyRent,
  PropertyType,
  PropertyAddress,
  ResidentialUnits,
  ResidentialUnitsArea,
  Units,
  BorrowerInformationFields,
  PricerLoanPurpose,
  PricerPurchasePrice,
  PricerPurchaseDate,
  PricerCapitalImprovements,
  PricerCurrentDebtOnProperty,
  PricerCurrentDebtAmount,
  PricerAnnualNoi,
  PricerAnnualExpenses,
  PricerAnnualIncome,
  PricerTargetLtv,
  ResidentialIncome,
  CommercialIncome,
  PropertyLeased,
} from './components/rentalPricerFields';
import { PricerFieldsConfig } from './components/rentalPricerFieldsContext';
import { useBaseStore, useUserStore } from '@roc/feature-app-core';
import { CashOut, PauseMultifamilySubmissions } from '@roc/feature-loans';
import { Leases } from 'libs/feature-loans/src/rentalPricer/components/rentalPricerFields';

interface LoanPricerProps {
  store: SinglePropertyPropertiesStore;
}

export const SinglePropertyRentalPropertyInformation = observer(
  ({ store }: LoanPricerProps) => {
    const { pricerStore } = store;
    const { form } = pricerStore;
    const { userStore } = useUserStore();
    const { globalStore } = useBaseStore();
    const fields = form.fields;

    const isNotMixedUse =
      fields.propertyType.value && form.fields.propertyType.value !== MIXED_USE;

    const isRefinance =
      form.fields.loanPurpose.value &&
      form.fields.loanPurpose.value === REFINANCE;

    useEffect(() => {
      store.fetchStateCounties();
    }, []);

    return (
      <>
        <PauseMultifamilySubmissions
          open={store.pricerStore.isPauseMultifamilySubmissionsOpen}
          handleClose={() =>
            store.pricerStore.setIsPauseMultifamilySubmissionsOpen(false)
          }
        />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h4">Property Information</Typography>
            <br />
          </Grid>
        </Grid>
        <br />
        <Grid item container spacing={2} xs={12} md={12} lg={6}>
          <PricerFieldsConfig xs={12}>
            <PropertyAddress store={store} />
            <PropertyType store={store} />
            {form.fields.address.value && <County store={store} />}
            {isNotMixedUse && <Units store={store} />}
            <PricerLoanPurpose store={store} />
            {form.fields.loanPurpose.value && !isRefinance && (
              <PropertyLeased store={pricerStore} />
            )}
            <PricerPurchasePrice store={store} />
            {isRefinance && (
              <>
                <PricerPurchaseDate store={store} />
                <PricerCapitalImprovements store={store} />
                <PricerCurrentDebtOnProperty store={store} />
                {form.fields.anyDebt.value === 'Y' && (
                  <PricerCurrentDebtAmount store={store} />
                )}
                {/* {form.fields.anyDebt.value === 'Y' &&
                form.fields.totalDebtPayoff.value >= 0 && (
                  <Grid item xs={12}>
                    <CashOut
                      store={pricerSummaryStore}
                    />
                  </Grid>
                )} */}
              </>
            )}
          </PricerFieldsConfig>
          <Box sx={{ px: '8px', pt: '16px'}}>
            <Leases store={store.pricerStore}/>
          </Box>
        </Grid>
        <br />
        <MixedUse store={store} />
        <br />
        <PropertyInformation store={store} />
        <br />
      </>
    );
  }
);

const MixedUse = observer(({ store }) => {
  const { form } = store.pricerStore;
  return (
    <>
      {form.fields.propertyType.value &&
        form.fields.propertyType.value === MIXED_USE && (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6">
                  Provide Mixed use Property Information
                </Typography>
                <br />
              </Grid>
            </Grid>
            <Grid container spacing={4}>
              <ResidentialUnits store={store} />
              <CommercialUnits store={store} />
              <ResidentialUnitsArea store={store} />
              <CommercialUnitsArea store={store} />
              <ResidentialIncome store={store} />
              <CommercialIncome store={store} />
              <CommercialUnitsCurrentOnRent store={store} />
            </Grid>
          </>
        )}
    </>
  );
});

const PropertyInformation = observer(
  ({ store }: { store: SinglePropertyPropertiesStore }) => {
    const { form } = store.pricerStore;

    const isOneToFourProperty =
      form.fields.propertyType.value &&
      (form.fields.propertyType.value === SINGLE_FAMILY ||
        form.fields.propertyType.value === DUPLEX ||
        form.fields.propertyType.value === TRIPLEX ||
        form.fields.propertyType.value === QUADRUPLEX);

    return (
      <Grid container direction="row">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6">Provide Property Information</Typography>
            <br />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={4}>
            <AsIsValue store={store} />
            <GrossMonthlyRent store={store} />
            <AnnualTaxes store={store} />
            <AnnualInsurance store={store} />
            <AnnualHoa store={store} />
            {!isOneToFourProperty && (
              <>
                <AnnualUtilityExpenses store={store} />
                <AnnualRepairMaintenanceExpenses store={store} />
                <AnnualPropertyManagementFees store={store} />
              </>
            )}
            {form.fields.propertyType.value &&
              form.fields.propertyType.value === MULTIFAMILY_5_PLUS && (
                <>
                  <AnnualAdministrativeExpense store={store} />
                  <AnnualPayrollExpense store={store} />
                  <AnnualMarketingExpense store={store} />
                  <AnnualReplacementReserve store={store} />
                </>
              )}
          </Grid>

          <br />

          <Grid
            container
            spacing={4}
            style={{
              width: '100%',
              border: 'solid 3px',
              margin: '32px 0px 32px 0px',
              borderColor: '#CECECE',
              borderRadius: '8px',
              padding: '3vw 5vw',
            }}
          >
            <PricerAnnualIncome store={store} />
            <PricerAnnualExpenses store={store} />
            <PricerAnnualNoi store={store} />
          </Grid>
        </Grid>
      </Grid>
    );
  }
);

const BorrowerInformation = observer(
  ({ store }: { store: SinglePropertyPropertiesStore }) => {
    const { pricerStore } = store;
    const { form } = pricerStore;

    return (
      <Grid container direction="row">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6">Provide Borrower Information</Typography>
            <br />
          </Grid>
        </Grid>
        <Grid item container spacing={4} xs={12} md={8} lg={4}>
          <PricerFieldsConfig xs={12}>
            <BorrowerInformationFields store={store} />
          </PricerFieldsConfig>
        </Grid>
      </Grid>
    );
  }
);
