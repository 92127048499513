import { Box, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import { useACHFormStore } from '../hooks/useACHStore';
import { lighten, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => {
  return {
    border: {
      border: '1px solid #C4C4C4',
      borderRadius: theme.spacing(1),
      padding: theme.spacing(6),
    },
    separator: {
      border: 0,
      borderBottom: '1px solid #C4C4C4',
    },
    title: {
      ...theme.typography.h4,
      fontSize: 32,
    },
    fieldLabel: {
      color: '#AAA',
      fontSize: 12,
    },
    fieldValue: {
      fontSize: 18,
      fontWeight: 'bold',
      maxHeight: '8em',
      overflowY: 'auto'
    },
  };
});

export const LoanDetailsCard = observer(() => {
  const classes = useStyles();
  const { achFormStore } = useACHFormStore();
  const { achData } = achFormStore;

  return (
    <Box className={classes.border}>
      <Typography className={classes.title}>Loan Details</Typography>
      <Box py={2}>
        <hr className={classes.separator} />
      </Box>
      <Box pb={2} display={'flex'}>
        <Box flex={1}>
          <Typography className={classes.fieldLabel}>
            {achData?.borrowerNames?.length > 1 ? 'Borrowers' : 'Borrower'}:
          </Typography>
          <Typography className={classes.fieldValue}>
            {achData?.borrowerNames?.map(borrowerName => (
              <div>{borrowerName}</div>
            ))}
          </Typography>
        </Box>
        <Box flex={1}>
          <Typography className={classes.fieldLabel}>Loan Id:</Typography>
          <Typography className={classes.fieldValue}>
            {achData?.loanId}
          </Typography>
        </Box>
      </Box>
      <Box>
        <Typography className={classes.fieldLabel}>
          Property{' '}
          {achData?.propertyAddresses?.length > 1 ? 'Addresses' : 'Address'}:
        </Typography>
        <Typography className={classes.fieldValue}>
          {achData?.propertyAddresses?.map(address => (
            <div>{address}</div>
          ))}
        </Typography>
      </Box>
    </Box>
  );
});
