import { compareDesc } from "date-fns";
import { makeObservable, override } from "mobx";
import { ConversationsType } from "../types/communicationTypes";
import { MyCommunicationStore } from "./myCommunicationsStore";

export class MentionsMyCommunicationsStore extends MyCommunicationStore {

  constructor(globalStore, userStore, webSocketStore, communicationService, isInternal = false, showMessagePreview = false) {
    super(globalStore, userStore, webSocketStore, communicationService, isInternal, showMessagePreview);
    makeObservable(this, {
      _sortConversationsByLastMessageDate: override,
    });
  }

  _sortConversationsByLastMessageDate(conversationsObj: ConversationsType) {
    this._sortConversationsByLastMentionedDate(conversationsObj);
  }

  private _sortConversationsByLastMentionedDate(conversationsObj: ConversationsType) {
    this.conversations = Object.keys(conversationsObj)
      .map(cSid => {
        const lastMentionedDate = this.conversationLastMentionedDate[cSid] ? new Date(this.conversationLastMentionedDate[cSid]) : undefined;
        const lastMessageDate = conversationsObj[cSid].lastMessage?.dateCreated;
        return {
          cSid,
          conversation: conversationsObj[cSid],
          conversationLastMentionedDate: lastMentionedDate ? lastMentionedDate : lastMessageDate
        }
      })
      .sort((a, b) => compareDesc(a.conversationLastMentionedDate, b.conversationLastMentionedDate))
      .reduce((obj, { cSid, conversation }) => {
        obj[cSid] = conversation;
        return obj;
      }, {});
  }

}