import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { Box, Grid } from '@material-ui/core';
import { useStore } from '@roc/client-portal-shared/hooks';
import { isNotBlank } from '@roc/client-portal-shared/utils';
import { CurrencyField, FieldLabel, Slider } from '@roc/ui';
import { observer } from 'mobx-react';
import { useEffect } from 'react';

import {
  createAutocompleteAddressField,
  createRadioField,
  createTextField,
  createCurrencyField,
  createSelectField,
  createDateField,
  createSliderField,
  createPercentageField,
} from '../../../components/formComponents/common/componentBuilder';
import {
  creditOptions,
  termMonthsOptions,
  tooltips,
  yesNoOptions,
} from '../utils/constants';

const useStyles = makeStyles(theme => ({
  slider: {
    marginTop: '2.5em',
  },
}));

const percentageMarks = [
  { value: 0, label: '0%' },
  { value: 100, label: '100%' },
];

const experienceMarks = [
  { label: '1', value: 1 },
  { label: '5', value: 5 },
];

const PercentageSlider = ({
  store,
  fieldName,
  label,
  onChange,
  tooltip,
  testId,
}) => {
  const classes = useStyles();
  return (
    <>
      <FieldLabel tooltip={tooltip}>{label}</FieldLabel>
      <Box
        minHeight={100}
        paddingLeft={2}
        paddingRight={2}
        display="flex"
        flexDirection="column"
        justifyContent="flex-end"
      >
        <Slider
          testId={testId}
          value={store.form.fields[fieldName].value}
          className={classes.slider}
          onChange={onChange}
          valueLabelDisplay={'on'}
          valueLabelFormat={value => <div>{value}%</div>}
          min={0}
          max={100}
          step={1}
          marks={percentageMarks}
        />
      </Box>
    </>
  );
};

const BorrowersAndLoanTerms = observer(() => {
  const classes = useStyles();
  const { fixFlipSizerStore } = useStore();
  const { detailsStore } = fixFlipSizerStore;
  const store = detailsStore.borrowersAndLoanTermsForm;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        {createSelectField({
          store,
          testId: 'credit',
          fieldName: 'credit',
          label: 'Credit',
          options: creditOptions,
          tooltip: tooltips.credit,
        })}
      </Grid>
      <Grid item xs={12}>
        {createCurrencyField({
          store,
          testId: 'netWorth',
          fieldName: 'netWorth',
          label: 'Net Worth',
          tooltip: tooltips.netWorth,
        })}
      </Grid>
      <Grid item xs={12}>
        {createSliderField({
          store,
          fieldName: 'experienceScore',
          label: 'Experience Score',
          step: 1,
          min: 1,
          max: 5,
          className: classes.slider,
          marks: experienceMarks,
          valueLabelDisplay: 'on',
          tooltip: tooltips.experienceScore,
          testId: 'experienceScoreSlider',
        })}
      </Grid>
      <Grid item xs={12}>
        {createTextField({
          store,
          testId: 'experienceDescription',
          fieldName: 'experienceDescription',
          label: 'Experience Description',
          multiline: true,
          tooltip: tooltips.experienceDescription,
        })}
      </Grid>
      <Grid item xs={12}>
        <PercentageSlider
          testId="initialLoanAmountSlider"
          store={store}
          fieldName="initialLoanAmount"
          label="Initial Loan Amount (% Of Cost)"
          tooltip={tooltips.initialLoanAmount}
          onChange={(e, value) => {
            store.onFieldChange('initialLoanAmount', value);
            detailsStore.calculateInitialLoanAmountCurrency();
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <CurrencyField
          testId={'initialLoanAmountCurrency'}
          variant={'outlined'}
          label={'Initial Loan Amount'}
          standaloneLabel
          value={store.form.fields.initialLoanAmountCurrency.value}
          onChange={(name, value) => {
            store.onFieldChange('initialLoanAmountCurrency', value);
            detailsStore.calculateInitialLoanAmountPercentage();
          }}
          error={isNotBlank(store.form.fields.initialLoanAmountCurrency.error)}
          errorText={store.form.fields.initialLoanAmountCurrency.error}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <PercentageSlider
          testId="constructionHoldbackSlider"
          store={store}
          fieldName="constructionHoldback"
          label="Construction Holdback (%)"
          tooltip={tooltips.constructionHoldback}
          onChange={(e, value) => {
            store.onFieldChange('constructionHoldback', value);
            detailsStore.calculateConstructionHoldbackCurrency();
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <CurrencyField
          testId={'constructionHoldbackCurrency'}
          variant={'outlined'}
          label={'Construction Holdback'}
          tooltip={tooltips.constructionHoldbackLimit}
          standaloneLabel
          value={store.form.fields.constructionHoldbackCurrency.value}
          onChange={(name, value) => {
            store.onFieldChange('constructionHoldbackCurrency', value);
            detailsStore.calculateConstructionHoldbackPercentage();
          }}
          error={isNotBlank(store.form.fields.initialLoanAmountCurrency.error)}
          errorText={store.form.fields.initialLoanAmountCurrency.error}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        {createPercentageField({
          store,
          testId: 'interestRate',
          fieldName: 'interestRate',
          label: 'Interest Rate',
          tooltip: tooltips.interestRate,
        })}
      </Grid>
      <Grid item xs={12}>
        {createPercentageField({
          store,
          testId: 'pointsIn',
          fieldName: 'pointsIn',
          label: 'Points In',
          tooltip: tooltips.pointsIn,
        })}
      </Grid>
      <Grid item xs={12}>
        {createPercentageField({
          store,
          testId: 'pointsOut',
          fieldName: 'pointsOut',
          label: 'Points Out',
          tooltip: tooltips.pointsOut,
        })}
      </Grid>
      <Grid item xs={12}>
        {createRadioField({
          store,
          testId: 'termInMonths',
          fieldName: 'termInMonths',
          label: 'Term In Months',
          options: termMonthsOptions,
          row: true,
          tooltip: tooltips.termInMonths,
        })}
      </Grid>
      <Grid item xs={12}>
        {createTextField({
          store,
          type: 'number',
          testId: 'interestReserveMonths',
          fieldName: 'interestReserveMonths',
          label: 'Interest Reserve In Months',
          tooltip: tooltips.interestReserveMonths,
        })}
      </Grid>
      <Grid item xs={12}>
        {createRadioField({
          store,
          testId: 'pledgeOfShares',
          fieldName: 'pledgeOfShares',
          label: 'Pledge Of Shares',
          options: yesNoOptions,
          row: true,
          tooltip: tooltips.pledgeOfShares,
        })}
      </Grid>
      <Grid item xs={12}></Grid>
    </Grid>
  );
});

export default BorrowersAndLoanTerms;
