import { Service } from '@roc/feature-app-core';

const url = {
  GET_LEASE_INFORMATION: '/api/v1//document/formDocument/leases',
  UPDATE_LEASE_INFORMATION: '/api/v1//document/formDocument/leases',
};

export class LeaseInformationService extends Service {

  getLeaseInformation = (documentId) => {
    return this.get(`${url.GET_LEASE_INFORMATION}/${documentId}`);
  };

  updateLeaseInformation = (data) => {
    return this.put(url.UPDATE_LEASE_INFORMATION, data);
  };
}