export const propertyOwnership = {
  PURCHASED: 'Purchase',
  OWNED_BY_BORROWER: 'Owned By Borrower',
};

export const acquisitionOptions = [
  {
    label: 'Purchase',
    value: propertyOwnership.PURCHASED,
  },
  {
    label: 'Owned by Borrower',
    value: propertyOwnership.OWNED_BY_BORROWER,
  },
];

export const proposalStatus = {
  'NEW': 'New',
  'PENDING': 'Pending',
  'DRAFT': 'Draft',
  'COMPLETED': 'Completed',
  'AGREED': 'Agreed'
};

export const renovationConstructionOptions = [
  {
    label: 'Light And Cosmetic',
    value: 'lightAndCosmetic',
  },
  {
    label: 'Moderate',
    value: 'moderate',
  },
  {
    label: 'Heavy',
    value: 'heavy',
  },
  {
    label: 'New Construction On Empty Lot',
    value: 'newConstructionEmptyLot',
  },
  {
    label: 'Tear Down And New Construction',
    value: 'tearDownAndNewConstruction',
  },
];

export const addingSquareFootageOptions = [
  {
    label: 'No',
    value: 'no',
  },
  {
    label: 'Vertical Expansion',
    value: 'verticalExpansion',
  },
  {
    label: 'Horizontal Expansion',
    value: 'horizontalExpansion',
  },
  {
    label: 'Vertical Expansion + Horizontal Expansion',
    value: 'verticalPlusHorizontalExpansion',
  },
];

export const yesNoOptions = [
  {
    label: 'Yes',
    value: true,
  },
  {
    label: 'No',
    value: false,
  },
];

export const creditOptions = [
  {
    label: '300 - 619 Bad',
    value: 'bad',
  },
  {
    label: '620 - 669 Fair',
    value: 'fair',
  },
  {
    label: '670 - 719 Decent',
    value: 'decent',
  },
  {
    label: '720 - 799 Very Good',
    value: 'veryGood',
  },
  {
    label: '800 - 850 Excellent',
    value: 'excellent',
  },
];

export const termMonthsOptions = [
  {
    label: '12 months',
    value: '12',
  },
  {
    label: '18 months',
    value: '18',
  },
];

export const experienceScoreValues = [
  'No experience',
  'Relative newbie',
  'Moderate Experience',
  'Very Experienced',
  'Top Tier',
];

export const timeToFlipValues = [1, 0.9, 0.8, 0.7, 0.6];

export const rateOptions = [
  {
    value: 10.25,
    label: '10.25%',
  },
  {
    value: 10.5,
    label: '10.5%',
  },
  {
    value: 10.75,
    label: '10.75%',
  },
  {
    value: 10.99,
    label: '10.99%',
  },
  {
    value: 11.0,
    label: '11%',
  },
  {
    value: 11.25,
    label: '11.25%',
  },
  {
    value: 11.5,
    label: '11.5%',
  },
  {
    value: 11.75,
    label: '11.75%',
  },
  {
    value: 11.99,
    label: '11.99%',
  },
  {
    value: 12.0,
    label: '12%',
  },
  {
    value: 12.25,
    label: '12.25%',
  },
  {
    value: 12.5,
    label: '12.5%',
  },
  {
    value: 12.75,
    label: '12.75%',
  },
  {
    value: 12.99,
    label: '12.99%',
  },
  {
    value: 13.0,
    label: '13%',
  },
  {
    value: 13.25,
    label: '13.25%',
  },
  {
    value: 13.5,
    label: '13.5%',
  },
  {
    value: 13.75,
    label: '13.75%',
  },
  {
    value: 13.99,
    label: '13.99%',
  },
  {
    value: 14.0,
    label: '14%',
  },
  {
    value: 14.25,
    label: '14.25%',
  },
  {
    value: 14.5,
    label: '14.5%',
  },
  {
    value: 14.75,
    label: '14.75%',
  },
  {
    value: 14.99,
    label: '14.99%',
  },
  {
    value: 15.0,
    label: '15%',
  },
  {
    value: 15.25,
    label: '15.25%',
  },
  {
    value: 15.5,
    label: '15.5%',
  },
  {
    value: 15.75,
    label: '15.75%',
  },
  {
    value: 15.99,
    label: '15.99%',
  },
  {
    value: 16.0,
    label: '16%',
  },
];


export const tooltips = {
  completedCapex:
    "Completed Renovations is the amount of work that the borrower has already completed on the property.",
  address:
    'Please enter the property address. Be sure to include the Street, Apt # (if applicable), City, State, and ZIP Code.',
  acquisition:
    'Will the borrower be buying this property (Purchase) or does the borrower already own the property?',
  purchasePrice:
    'How much was or will the property be purchased for? The purchase price should be NET of any seller credits. Do not include any sort of closing costs in the purchase price.',
  rehabToCompleteProject:
    'Please enter the cost to complete the project. You can include all hard construction costs + reasonable soft costs (architect fees, permit fees, etc). Do not include loan closing costs, interest payments, property taxes, insurance, and/or staging fees.',
  purchaseDate: 'Choose the date of purchase.',
  rehabToDate:
    'Please enter the costs spent so far. You can include all hard construction costs + reasonable soft costs (architect fees, permit fees, etc). DO NOT include loan closing costs, interest payments, property taxes, insurance, and/or staging fees.',
  loanOutstanding: 'Is there currently a loan oustanding against the property?',
  currentLender:
    'If a bank, let us know. If a private lender, thats also interesting. Let us know the type of lender and the name of the lender.',
  everBeenInDefault:
    'Is the loan or has the loan ever been in default? If the loan is past maturity date and the lender has defaulted the borrower, choose the appropriate option. If the loan has matured but the lender has not defaulted the borrower, choose that option.',
  principalBalance:
    'What is the Principal Balance oustanding ONLY? DO NOT include any other fees, default interest, late fees, recording fees, etc. ',
  sumOfLoanFees:
    'Please enter the sum of all additional fees on the payoff. DO NOT include the principal balance. DO include late fees, default interest, recording fees, etc.',
  asIsValue:
    'What is the current As-is value of the property? If you do not know, you can leave this blank.',
  afterRepairValue:
    'What is the value of the property when renovation is complete?',
  projectDescription:
    'Please enter a description of the deal that captures its essence. Sample - "Large highly profitable luxury LA fix/flip to seasoned developer that I have worked with in the past at Civic". If this is a condo conversion, you can enter that here.',
  renovationConstruction:
    'Please describe the scope of the project that will be undertaken with our loan. If the project started out as ground-up construction, but we are making a loan mid-construction - then use this to describe the work that remains.',
  addingSquareFootage:
    'Will the borrower be adding square footage? If yes, please choose the appropriate options.',
  credit:
    'Please choose the credit score range of the mid-FICO score of the borrower that will Personally Guarantee the loan and has the highest credit on the loan.',
  netWorth:
    'Please enter the sum of the net worth of all borrowers that will be personally guaranteeing the loan. Do not include the net worth of borrowers that will not personally guarantee the loan. ',
  experienceScore:
    '1 - No Experience, 2 - Relative Newbie, 3 - Moderate Experience, 4 - Very Experienced, 5 - Top Tier',
  experienceDescription:
    'Having prior experience with deals similar to this deal is key. Try to frame the description in this context. Sample - "Seasoned builder that has done 5 ground up construction deals similar to this one in the last 24 months".',
  initialLoanAmount:
    'Please enter the initial loan to current cost ratio between 0-90% for Purchases. If the borrower already owns the property, the ratio can be higher than 90% (in the case of a cashout).',
  constructionHoldback:
    'Please enter the advance relative to the construction budget to complete the project between 0-100%.',
  constructionHoldbackLimit:
    'Construction Holdback must be equal or less than construction budget',
  interestRate: 'Please enter the rate between 6%-15%.',
  pointsIn:
    'These are origination fees charged at closing of the loan and range from 0-4%.',
  pointsOut:
    'These are origination fees charged at payoff of the loan and range from 0-4%.',
  termInMonths:
    'Please enter the desired loan term. Note that 99% of our bridge loans are 12 months in duration. We are able to extend for up to 50% of the original term. We typically do not default paying borrowers even if past maturity.',
  interestReserveMonths:
    'The number of months of payments that the borrower will pre-pay at the closing of the loan. We currently require at least 1 month prepaid.',
  pledgeOfShares: [
    'Post Covid, borrowers have typically agreed to a pledge of shares 95%+ of the time. Read the comment below which describes what a pledge of shares is.',
    '',
    'Author:',
    'Going back to the caveman era private loans against real estate have been secured by mortgages, and the remedy in the event of default has been foreclosure.  The problem with foreclosures is that you may have to wait years for a judgment and perhaps longer depending on the jurisdiction and whether its contested.',
    'Other remedies have their flaws.  Personal guaranties are often useless because your borrower’s are often in trouble because they are overextended, requiring you to get on line with every other creditor.   Forbearance Agreements only seem to delay the inevitable.   Deed in Lieu can be effective but may not be viable if liens spring up on the collateral.  Both the Forbearance and Deed in Lieu scenarios require the cooperation of the Borrower who may not give it or may be long gone.  What is needed therefore is another form of security taken at the closing which could accelerate Lender’s taking control of the asset due to a defaulted loan.  Hence the membership pledge. ',
    'In the membership pledge, each member of the LLC borrower pledges his/her/its membership interests (or, in the case of the corporation borrower, its shares) to Lender. While the loan is performing, the member(s) retain the right to own and manage the entity. Upon default, Lender can elect to exercise the pledge by giving notice to pledgor and Borrower, and step into the shoes of the member(s) to own and manage the entity. In such capacity, Lender can have the Borrower finish the construction on the property, or whatever needs to be done, market and sell the property at fair market value to pay back the loan to Lender. To the extent there are proceeds realized beyond the indebtedness owing to Lender (including expenses incurred flowing from the exercise of the pledge), those would be retained by Borrower and distributed to the former member(s) as per the entity’s governing documents.',
  ].join('\n'),
};
