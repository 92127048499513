import { format } from "date-fns";
import { formatCurrency } from "../../utils/loanUtils/loanUtils";

export const ADD_PROPERTY = 'Add New Property';
export const EDIT_PROPERTY = 'Edit Property';

export function getStreetNumber(purchaseAddress) {
  var splitAddress = purchaseAddress.split(" ");
  if (splitAddress.length > 0) return splitAddress[0];
  else return "";
}

export function getStreetName(purchaseAddress) {
  var splitAddress = purchaseAddress.split(",");
  if (splitAddress.length < 1) return "";
  var index = splitAddress[0].indexOf(" ");
  if (index < 0 || index >= splitAddress[0].length) return splitAddress[0];
  return splitAddress[0].substring(index).trim();
}


export const usdAmount: any = params => {
  const value = params.value ?? 0;
  return formatCurrency(Number(value));
};

export const formatDate = date => {
  if (date == null || date == '') return '';
  return format(new Date(date), 'MM/dd/yyyy');
};
