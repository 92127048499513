import React, { useEffect } from 'react';
import { Grid, Paper, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import { formatCurrency } from '../../utils/loanUtils/loanUtils';
import BorrowerTrackRecord from './borrowerTrackRecord';
import NewBorrowerTrackRecord from './newBorrowerTrackRecordCard';
import { useUserStore } from '@roc/feature-app-core';

const useStyles = makeStyles(theme =>
  createStyles({
    paper: {
      borderRadius: theme.spacing(2),
      boxShadow:
        'rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px',
      marginRight: theme.spacing(2),
      backgroundColor: theme.palette.background.default,
      paddingLeft: '20px',
      paddingRight: '20px'
    },
    textField: {
      backgroundColor: 'white',
      shrink: true,
      "& input.Mui-disabled": {
        color: "black"
      }
    },
    table: {
      marginTop: '1.5em',
      marginBottom: '2em'
    },
    tab: {
      marginBottom: theme.spacing(2),
    },
  })
);

const boolToStr = (bool) => {
  return bool ? 'Yes' : 'No';
}

export const NewBorrowerBaseballCard = observer(({ store, onClose = undefined, hideTitle = false }) => {
  const { userStore } = useUserStore();
  const { currentBorrower, baseballCardDetails } = store;
  const classes = useStyles();

  useEffect(() => {
    store.fetchBaseballCardData(currentBorrower);
  }, []);

  return (
    <NewBorrowerTrackRecord store={store} isInternal={userStore.isNportalUser} onClose={onClose} hideTitle={hideTitle} />

  );

});

export default NewBorrowerBaseballCard;
