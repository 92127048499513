import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Line } from "react-chartjs-2";
import { Typography, Paper, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

const useStyles = makeStyles({
  accordionSummary: {
    backgroundColor: '#D9DADC',
    '& .MuiAccordionSummary-content': {
      margin: '10px 0px',
    },
  },
  noMarginBottom: {
    marginBottom: '0px',
  },
  accordionDetails: {
    padding: '0px',
  }
});

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const LineChart = ({ dataSet, title, dataLabel }) => {
  const labels = dataSet?.[0] ? dataSet[0] : [];

  // Example dataset values
  const dataset1 = dataSet?.[1] ? dataSet[1] : [];
  const dataset2 = dataSet?.[2] ? dataSet[2] : [];
  const classes = useStyles();
  // Data for the chart
  const data = {
    labels: labels,
    datasets: [
      {
        label: dataLabel[0],
        data: dataset1,
        borderColor: "rgba(75, 192, 192, 1)",
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        tension: 0.4, // For smooth curves
      },
      {
        label: dataLabel[1],
        data: dataset2,
        borderColor: "rgba(255, 99, 132, 1)",
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        tension: 0.4, // For smooth curves
      },
    ],
  };

  // Chart options
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const, // Ensure "top" is treated as a literal type
      },
      title: {
        display: true,
        text: "$ Amount vs Months",
      },
    },
    scales: {
      x: {
        ticks: {
          maxRotation: 45,
          minRotation: 0,
          callback: function (value, index, values) {
            const totalLabels = values.length;
            if (totalLabels <= 12) {
              return this.getLabelForValue(value);
            } else if (totalLabels <= 24) {
              return index % 2 === 0 ? this.getLabelForValue(value) : null;
            } else {
              return index % 3 === 0 ? this.getLabelForValue(value) : null;
            }
          },
        },
      },
      y: {
        beginAtZero: true, // Start y-axis at 0
        ticks: {
          callback: (value) => `$${value.toLocaleString()}`, // Add $ prefix
        },
      },
    },
  };

  return (
    <Paper elevation={0} variant='outlined'>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          className={classes.accordionSummary}
        >
          <Typography gutterBottom variant="h5" component="h2" className={classes.noMarginBottom}>
            {title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <Line data={data} options={options} />
        </AccordionDetails>
      </Accordion>
    </Paper>
  );
};

export default LineChart;