import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { CheckCircle } from '@material-ui/icons';
import { useLoanStore } from '@roc/feature-loans';
import { LoanSummaryBrokerContacts } from '@roc/feature-loans-shared';
import { isFundedLoan } from '@roc/feature-utils';
import { InformationButton } from '@roc/ui';
import { observer } from 'mobx-react';
import { CustomCard, CustomCardDividers, CustomCardRow } from '../customCard';
import {
  LoanDetailsCustomCard,
  LoanDetailsInfoItem,
} from '../loanDetailsCustomCard';

import { Divider, List, ListItem, ListItemText } from '@material-ui/core';
import { darken } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { SubdirectoryArrowRight } from '@material-ui/icons';
import { Layout, useUserStore } from '@roc/feature-app-core';
import {
  US_TREASURY_10_YR_DESC,
  US_TREASURY_10_YR_INDEX,
  US_TREASURY_5_YR_DESC,
  US_TREASURY_5_YR_INDEX,
} from '@roc/feature-loans';
import {
  formatDate,
  isFundedOrAgreedLoan,
  isNil,
  sanitizeTestId,
} from '@roc/feature-utils';
import {
  BooleanChip,
  Paper,
  StatusChip,
  StatusType,
  TestDataAttribute,
} from '@roc/ui';
import { formatCurrency } from '@roc/ui/utils';

export const GeneralStatsCardTerm = observer(() => {
  const { loanStore } = useLoanStore();
  const { loanDetails } = loanStore;

  const {
    status,
    propertyCount,
    mostRecentValuation,
    totalCostBasis,
    totalDebtPayoffAmount,
    annualGrossRent,
    pitia,
    noi,
    netCashFlow,
    netDSCR,
    pitiaDSCR,
  } = loanDetails ?? {};

  return (
    <CustomCard title="General Stats">
      <Box>
        <List>
          {listItem('Property Count', propertyCount)}
          {divider}
          {listItem(
            'Most Recent Valuation',
            formatCurrency(mostRecentValuation)
          )}
          {divider}
          {listItem('Total Cost Basis', formatCurrency(totalCostBasis))}
          {divider}
          {listItem(
            'Total Debt Payoff Amount',
            formatCurrency(totalDebtPayoffAmount)
          )}
          {divider}
          {listItem('Annual Gross Rent', formatCurrency(annualGrossRent))}
          {divider}
          {listItem('PITIA', formatCurrency(pitia))}
          {divider}
          {listItem('NOI', formatCurrency(noi))}
          {divider}
          {listItem('Net Cash Flow', formatCurrency(netCashFlow))}
          {divider}
          {listItem('NCF DSCR', formatDigit(netDSCR, 3))}
          {divider}
          {listItem('PITIA DSCR', formatDigit(pitiaDSCR, 3))}
        </List>
      </Box>
    </CustomCard>
  );
});

const listItem = (label, value, icon = null, customTestId = null) => {
  const testId = sanitizeTestId(customTestId || label);
  return (
    <ListItem>
      <ListItemText>
        <Box display="flex">
          {icon && <Box ml={1}>{icon}</Box>}
          <Box flexGrow={1} mr={1}>
            {label}
          </Box>
          <TestDataAttribute id={testId}>
            <Box textAlign="right">{value}</Box>
          </TestDataAttribute>
        </Box>
      </ListItemText>
    </ListItem>
  );
};

const divider = <Divider variant="middle" component="li" />;

const formatPercentage = value =>
  `${value != null ? Number(value).toFixed(2) : ''}%`;

const formatDigit = (value, digits) => value?.toFixed(3, digits);

const getSwapIndexLabel = index => {
  if (index === US_TREASURY_5_YR_INDEX) return US_TREASURY_5_YR_DESC;
  else if (index === US_TREASURY_10_YR_INDEX) return US_TREASURY_10_YR_DESC;
};
