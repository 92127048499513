import { Page } from '@roc/client-portal-shared/components';
import { Route } from 'react-router';
import { LenderExtensionsDashboard } from '../lenderExtensionsDashboard/lenderExtensionsDashboard';
export const getLenderExtensionsRoutesConfig = (
  basePath: string,
  baseUrl: string
) => {
  return {
    extensions: {
      path: `${basePath}/extensions`,
      url: `${baseUrl}/extensions`,
      documentTitle: 'View Extensions',
    },
  };
};

export const getLenderExtensionsRoutes = (basePath: string, baseUrl: string) => {
  const config = getLenderExtensionsRoutesConfig(basePath, baseUrl);

  return {
    extensions: (
      <Route exact path={config.extensions.path}>
        <Page routeDefinition={config.extensions}>
          <LenderExtensionsDashboard />
        </Page>
      </Route>
    ),
  };
};