import { ConfirmDialog } from '@roc/ui';
import { observer } from 'mobx-react';
import { useLoanActionsStore } from '../hooks/useLoanActionsStore';

export const RejectInsuranceModal = observer(({ open, handleClose }) => {
  const { loanActionsStore } = useLoanActionsStore();

  const confirm = () => {
    loanActionsStore.rejectInsurance();
    handleClose();
  };

  return (
    <ConfirmDialog
      open={open}
      confirmButtonText={'Yes'}
      handleClose={handleClose}
      handleCancel={handleClose}
      handleConfirm={confirm}
      title={'Reject Insurance'}
      body={`Are you sure you want to reject the Insurance Approval document?`}
    />
  );
});
