import { GlobalStore, ApiResponse } from '@roc/feature-app-core';
import { action, makeObservable, observable, flow, computed } from 'mobx';
import { OneToolService } from '../../services/oneToolService';
import { ClosingFormStore } from './closingFormStore';
import { QuoteTypeStore } from '../quoteTypeStore';
import { format, isValid } from 'date-fns';
import { ELMSURE, isNotBlank, parseDate } from '@roc/feature-utils';
import { InsuranceFormStore } from './insuranceFormStore';
import { TitleFormStore } from './titleFormStore';

export class ClosingStore {
  private globalStore: GlobalStore;
  private oneToolService: OneToolService;
  public closingFormStore: ClosingFormStore;
  public insuranceFormStore: InsuranceFormStore;
  public titleFormStore: TitleFormStore;
  public attorneys: any[] = [];
  quoteTypeStore: QuoteTypeStore;

  constructor(globalStore, quoteTypeStore) {
    this.globalStore = globalStore;
    this.oneToolService = new OneToolService();
    this.closingFormStore = new ClosingFormStore(globalStore);
    this.insuranceFormStore = new InsuranceFormStore(globalStore);
    this.titleFormStore = new TitleFormStore(globalStore);
    this.quoteTypeStore = quoteTypeStore;
    makeObservable(this, {
      reset: action,
      closingFormStore: observable,
      getAttorneys: flow,
      getClosingData: action,
      validateClosingData: action,
      setDefaultPreferredClosingAttorney: action,
      attorneys: observable,
      attorneyOptions: computed,
    });
  }

  reset() {
    this.attorneys = [];
    this.closingFormStore.reset();
    this.insuranceFormStore.reset();
    this.titleFormStore.reset();
  }

  loadClosingData(quoteData) {
    const { closingData } = quoteData;
    if (closingData) {
      const form = this.closingFormStore;
      form.loadForm({
        ...closingData,
        preferredInsurance: isNotBlank(closingData.preferredInsurance) ? closingData.preferredInsurance : ELMSURE,
        requestedClosingDate: parseDate(closingData.requestedClosingDate),
        originatorId: quoteData?.originatorId,
      });
      form.setEditMode(false);
      this.insuranceFormStore.loadForm(closingData?.otherInsuranceInfo || {});
      this.titleFormStore.loadForm(closingData?.otherTitleInfo || {});
      return form;
    }
  }

  getClosingData() {
    const values = this.closingFormStore.getFormValues();
    const otherTitelInfo = this.titleFormStore.getFormValues();
    return {
      ...values,
      preferredTitle:
        values?.preferredTitle === 'None' ? '' : values?.preferredTitle,
      requestedClosingDate: isValid(values?.requestedClosingDate)
        ? format(values.requestedClosingDate, 'MM/dd/yyyy')
        : null,
      otherInsuranceInfo: this.insuranceFormStore.getFormValues(),
      otherTitleInfo: otherTitelInfo.name ? otherTitelInfo : {},
      submitAppraisalWithTamarisk: this.globalStore.userFeatures.tamariskAppraisal ? true : values.submitAppraisalWithTamarisk,
      originatorId: this.getLenderOriginatorId(values.originatorId),
    };
  }

  getLenderOriginatorId(selectedLenderOriginatorId) {
    return this.quoteTypeStore.userStore.isSeniorOriginator ? this.quoteTypeStore.userStore.userInformation?.userId : selectedLenderOriginatorId;
  }

  fetchAttorneysByState(states, allowedLoanType) {
    this.getAttorneys(states, allowedLoanType);
  }

  *getAttorneys(states, allowedLoanType) {
    try {
      const economicsStore = this.quoteTypeStore.loanEconomicsStore;
      const response: ApiResponse = yield this.oneToolService.getLenderAttorneysByState(
        (states || []).join(','),
        this.globalStore.userFeatures?.internalClosingTeamOptionEnabled,
        economicsStore.enableManualQuote ?
          economicsStore.manualExceptionQuoteFormStore.calculatedFields.totalLoanAmount :
          economicsStore.instaLeverageQuoteFormStore.calculatedFields.totalLoanAmount
      );
      const attorneys = JSON.parse(response.data?.data);

      this.attorneys = attorneys?.filter(x => (x.externalAllowed && x.allowedLoanTypes.includes(allowedLoanType)))
        .sort((a1, a2) => a1.investorAttorneyId - a2.investorAttorneyId);

      this.setDefaultPreferredClosingAttorney();
    } catch (error) {
      console.log({ error });
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while getting attorneys.',
      });
    }
  }

  validateClosingData() {
    this.closingFormStore.runFormValidationWithMessage();
    return this.closingFormStore.form.meta.isValid;
  }

  setDefaultPreferredClosingAttorney() {
    const currentAttorney = this.closingFormStore.form.fields
      .preferredClosingAttorney.value;
    if (
      isNotBlank(currentAttorney) && !this.isClosingAttorneyPresent(currentAttorney)
    ) {
      const newAttorney = this.isClosingAttorneyPresent(
        'Hartmann Doherty Rosa Berman & Bulbulia (HDRBB)'
      )
        ? 'Hartmann Doherty Rosa Berman & Bulbulia (HDRBB)'
        : 'LaRocca Hornik Rosen & Greenberg LLP';

      this.closingFormStore.onFieldChange(
        'preferredClosingAttorney',
        newAttorney
      );
    }
  }

  private isClosingAttorneyPresent(preferredClosingAttorney) {
    return this.attorneys.some(
      attorney => attorney?.company?.name === preferredClosingAttorney
    );
  }

  get attorneyOptions() {
    return this.attorneys.map(attorney => ({
      id: attorney.investorAttorneyId,
      value: attorney.company.name,
      label: attorney.company.name,
    }));
  }

  get canSubmitAppraisalWithTamarisk() {
    return !this.globalStore.userFeatures.isLenderOriginator &&
      !this.globalStore.userFeatures.isBroker &&
      !this.globalStore.userFeatures.tamariskAppraisal
  };

}
