import {
  Step,
  StepContent, StepLabel, Stepper,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { useAppraisalsStore } from '@roc/feature-appraisals';
import { Button, DialogState } from '@roc/ui';
import { FieldContainer } from '@roc/ui/formComponents';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { BrokerForm } from '../brokerForm/brokerForm';
import { useBrokersStore } from '../hooks/useBrokersStore';
import { Broker } from '../types/brokerTypes';
import { BrokerFees } from './brokerFees';
import { BrokerVendorClosingSettings } from './brokerVendorClosingSettings';
import { titleOptions,titleOptionsAllowedWimba, WIMBA } from '@roc/feature-utils';

function getAddSteps() {
  return [
    'Broker information',
    'Default Fees',
    'Vendor & Closing Settings'
  ];
}

function getViewEditSteps() {
  return [
    'Broker information',
    'Default Fees',
    'Vendor & Closing Settings'
  ];
}

function getAddStepContent(step: number, canEditBroker: boolean, brokers: any) {
  switch (step) {
    case 0:
      return <BrokerForm readOnly={!canEditBroker} brokers={brokers} />;
    case 1:
      return <BrokerFees />;
    case 2:
      return <BrokerVendorClosingSettings preferredTitleOptions={titleOptions}/>
    default:
      return 'Unknown step';
  }
}

function getViewEditStepContent(step: number, canEditBroker: boolean, brokers: any, showWimbaOption: boolean) {
  switch (step) {
    case 0:
      return <BrokerForm readOnly={!canEditBroker} brokers={brokers} />;
    case 1:
      return <BrokerFees />;
    case 2:
      return <BrokerVendorClosingSettings preferredTitleOptions={showWimbaOption ? titleOptionsAllowedWimba : titleOptions}/>
    default:
      return 'Unknown step';
  }
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    button: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    actionsContainer: {
      marginBottom: theme.spacing(2),
    },
    resetContainer: {
      padding: theme.spacing(3),
    },
  })
);

export const AddBrokerSteps = observer(
  () => {
    const { brokersStore } = useBrokersStore();
    const {
      selectBrokersStore,
      manageBrokersStore,
      brokersGridStore,
      brokerFeesStore,
      manageBrokerFormStore,
      brokerFormStore
    } = brokersStore;
    const { editDialogState } = selectBrokersStore;
    const brokers: Broker[] = brokersGridStore.gridData.data.rows;
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const steps =
      editDialogState == DialogState.ADD ? getAddSteps() : getViewEditSteps();
    useEffect(() => {
      handleActiveStep();
    }, []);

    const handleNext = () => {
      if (activeStep === steps.length - 1) {
        if (editDialogState == DialogState.ADD) {
          manageBrokersStore.saveBroker();
          selectBrokersStore.closeEditBrokerDialog();
        } else {
          manageBrokersStore.saveBroker();
          selectBrokersStore.closeEditBrokerDialog();
        }
      } else {
        manageBrokerFormStore.runFormValidationWithMessage();
        if (manageBrokerFormStore.form.meta.isValid) {
          setActiveStep(prevActiveStep => prevActiveStep + 1);
        }
      }

    };

    const handleBack = () => {
      setActiveStep(prevActiveStep => prevActiveStep - 1);
    };

    const handleActiveStep = () => {
      if (selectBrokersStore.openEditBrokerInfoStep) {
        setActiveStep(0);
      } else if (selectBrokersStore.openEditFeesInfoStep) {
        setActiveStep(1);
      } else if (selectBrokersStore.openEditVendorSettings) {
        setActiveStep(2);
      } else {
        setActiveStep(0);
      }
    }

    const shouldShowWimbaOptions = brokersStore.manageBrokersStore.brokerVendorClosingSettings?.preferredTitleCompany === WIMBA;

    return (
      <div className={classes.root}>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
              <StepContent TransitionProps={{ unmountOnExit: false }}>
                {editDialogState == DialogState.ADD
                  ? getAddStepContent(index, true, brokers)
                  : getViewEditStepContent(index, true, brokers, shouldShowWimbaOptions)}
                <div className={classes.actionsContainer}>
                  <FieldContainer>
                    <>
                      <Button
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        className={classes.button}
                        testId={`back-${activeStep + 1}`}
                      >
                        Back
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        className={classes.button}
                        testId={activeStep === steps.length - 1 ? `finish` : `next-${activeStep + 1}`}
                      >
                        {activeStep === steps.length - 1
                          ? editDialogState == DialogState.ADD
                            ? 'Finish & Add Broker'
                            : 'Save'
                          : 'Next'}
                      </Button>
                    </>
                  </FieldContainer>
                </div>
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </div>
    );
  }
);
