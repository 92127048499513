import { Typography } from "@material-ui/core";
import { Layout } from "@roc/feature-app-core";
import { ConstructionFeasibilityToDo } from "@roc/feature-construction-feasibility";
import { observer } from "mobx-react";
import { useParams } from "react-router";

export type EncodedParams = {
  encodedParams: string;
}

export type CMTReportFormParams = {
  loanId?: any;
  propertyId?: any;
  underwritingReview?: boolean;
}

export const decodeParams = (encodedParams: string) => {
  const decodedParams = window.atob(encodedParams.replace('-', '/'));
  const response: CMTReportFormParams = {};
  const params = decodedParams.split(';');
  params.forEach(param => {
    const [key, value] = param.split(':');
    response[key] = value;
  })
  return {
    loanId: response.loanId,
    propertyId: response.propertyId,
    underwritingReview: response.underwritingReview,
  }
}

export const CMTReportInternal = observer(() => {
  const { encodedParams } = useParams<EncodedParams>();
  const { loanId, propertyId, underwritingReview } = decodeParams(encodedParams);

  const handleCloseButton = () => {
    window.parent.postMessage('close-cmt-modal', '*');
  }

  return (
    <>
      <Layout>
        <Typography align="left" style={{ fontSize: '28px', paddingLeft: '10px', marginBottom: '5px' }}>
          Construction Feasibility Review Report
        </Typography>
        <ConstructionFeasibilityToDo
          loanId={loanId}
          propertyId={propertyId}
          onClose={handleCloseButton}
          document={null}
          underwritingReview={underwritingReview}
        />
      </Layout>
    </>
  )
})