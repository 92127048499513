import React, { useMemo } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, CurrencyField } from '@roc/ui';
import { observer } from 'mobx-react';
import {
  CellPhone,
  Address,
  EmailAddress,
} from '@roc/ui/formComponents';
import { InsuranceFormStore } from '../../../../../stores/documentForms/insuranceProvider/insuranceFormStore';
import { isNotBlank, isNil } from '@roc/feature-utils';

export type InsuranceFormProps = {
  title?: string;
  readOnly?: boolean;
  store: InsuranceFormStore;
};

const useStyles = makeStyles({
  title: {
    fontWeight: 'bold',
  },
});

export const InsuranceForm = observer((props: InsuranceFormProps) => {
  const { title = 'Please provide Insurance Details', readOnly, store } = props;

  const {
    form: { fields },
  } = store;

  const classes = useStyles();

  const getIsAddressDisabled = () => {
    const address = store.company?.address
      ? store.company.address.replace(' ', '')
      : '';
    return address.length > 0;
  };

  const isAddressDisabled = useMemo(getIsAddressDisabled, [
    store.company?.address,
  ]);

  return (
    <Grid container spacing={2} direction="column">
      <Grid item>
        <Typography className={classes.title}>{title}</Typography>
      </Grid>
      <Grid item container spacing={2}>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Name"
            standaloneLabel
            value={fields.name.value}
            error={isNotBlank(fields.name.error)}
            helperText={fields.name.error}
            onChange={e => store.onFieldChange('name', e.target.value)}
            testId="name"
            variant="outlined"
            disabled={isNotBlank(store.company?.name) || readOnly}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Contact Name"
            standaloneLabel
            value={fields.contactName.value}
            error={isNotBlank(fields.contactName.error)}
            helperText={fields.contactName.error}
            onChange={e => store.onFieldChange('contactName', e.target.value)}
            testId="contactName"
            variant="outlined"
            disabled={isNotBlank(store.company?.contacts?.[0]?.fullName) || readOnly}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <CellPhone
            store={store}
            disabled={isNotBlank(store.company?.phone) || readOnly}
          />
        </Grid>
      </Grid>
      <Grid item container spacing={2}>
        <Grid item xs={12} sm={4}>
          <Address
            store={store}
            disabled={isNotBlank(isAddressDisabled) || readOnly}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <EmailAddress
            store={store}
            required={false}
            disabled={isNotBlank(store.company?.email) || readOnly}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <CurrencyField
            label="Current Insurance Premium"
            standaloneLabel
            variant="outlined"
            value={fields.cost.value}
            error={isNotBlank(fields.cost.error)}
            errorText={fields.cost.error}
            onChange={(name, value) =>
              store.onFieldChange('cost', Number(value))
            }
            fullWidth
            testId="netWorth"
            disabled={!!store.cost || readOnly}
          />
        </Grid>
      </Grid>
    </Grid>
  );
});

export default InsuranceForm;
