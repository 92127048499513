import { Page } from '@roc/feature-app-core';
import { Route } from 'react-router';
import { SendCorporateEmails } from '../components/sendCorporateEmails';


export const getCorporateEmailsRoutesConfig = (basePath: string, baseUrl: string) => {
  return {
    emails: {
      path: `${basePath}/corporate-emails`,
      url: `${baseUrl}/corporate-emails`,
      documentTitle: 'Corporate Emails',
    }
  };
}

export const getCorporateEmailsRoutes = (basePath: string, baseUrl: string) => {
  const config = getCorporateEmailsRoutesConfig(basePath, baseUrl);
  return {
    emails: (
      <Route exact path={config.emails.path}>
        <Page routeDefinition={config.emails}>
          <SendCorporateEmails />
        </Page>
      </Route>
    )
  };
}