import { Box, Grid, ListItem, ListItemText, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { useStore } from '@roc/client-portal-shared/hooks';
import { Button, Paper } from '@roc/ui';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { createPercentageField, createSelectField, createNumberFormatField } from '../../../components/formComponents/common/componentBuilder';
import { loanEconomicsTier, pointSplitSchemes } from '@roc/feature-utils';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(2),
  },
}));

export const LoanEconomics = observer(({ companyProfileStore }) => {
  const formStore = companyProfileStore.loanEconomicsStore;
  const isEditing = formStore.isEditing;

  const classes = useStyles();

  useEffect(() => {
    formStore.loadLenderDetails();
  }, []);

  return (
    <Paper className={classes.paper}>
      <Box p={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} container justifyContent="flex-end">
            {isEditing ? (
              <>
                <Button
                  style={{ marginRight: '8px' }}
                  onClick={() => formStore.cancel()}
                  color="primary"
                  variant="outlined"
                  testId="cancel"
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => formStore.saveLenderDetails()}
                  color="primary"
                  variant="contained"
                  testId="save"
                >
                  Apply Changes
                </Button>
              </>
            ) : (
              <Button
                color="primary"
                variant="contained"
                onClick={() => formStore.edit()}
                testId="save"
              >
                Edit
              </Button>
            )}
          </Grid>
          <Grid item xs={12} md={12}>
            <Typography variant="h6">Bridge Loan Economics</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            {createSelectField({
              testId: 'pointSplitSchemes',
              label: 'Points Split Schemes',
              fieldName: 'pointSplitSchemes',
              store: formStore,
              options: pointSplitSchemes,
              disabled: !isEditing,
            })}
          </Grid>
          <Grid item xs={12} md={12}>
            <Box pt={2} >
              <Typography variant="h6">Rental Loan Economics</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            {createPercentageField({
              testId: 'lenderPointsShare',
              label: 'Lender Points Share',
              fieldName: 'lenderPointsShare',
              store: formStore,
              disabled: true,
            })}
          </Grid>
          <Grid item xs={12} md={6}>
            {createPercentageField({
              testId: 'yieldSpreadMultiplier',
              label: 'Yield Spread Multiplier',
              fieldName: 'yieldSpreadMultiplier',
              store: formStore,
              disabled: true,
            })}
          </Grid>
          <Grid item xs={12} md={6}>
            {createSelectField({
              testId: 'tier',
              label: 'Tier',
              fieldName: 'tier',
              store: formStore,
              options: loanEconomicsTier,
              disabled: true,
            })}
          </Grid>
          <Grid item xs={12} md={6}>
            {createNumberFormatField({
              testId: 'yspNormalizationFactor',
              label: 'YSP Normalization Factor',
              fieldName: 'yspNormalizationFactor',
              store: formStore,
              disabled: true,
            })}
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
});
