import React from 'react';
import { Grid, Typography, makeStyles, Box } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import { PropertyFormStore } from '../../../stores/properties/propertyFormStore';
import { notLicensedinState, requiredLicenseStates } from '@roc/feature-utils';
import { observer } from 'mobx-react-lite';
import { Error } from '@material-ui/icons';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    backgroundColor: '#FFF4E5',
    borderRadius: '8px',
    padding: '14px 16px',
    alignItems: 'center',
  },
  icon: {
    color: '#EF6C00',
    marginRight: '12px',
  },
  title: {
    fontWeight: 'bold',
    color: '#663C00',
  },
  description: {
    color: '#2e6b31',
  },
}));

export const LeverageExceptionAlert = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <WarningIcon className={classes.icon} />
      <Box>
        <Typography className={classes.title} variant="body1">
          Your Leverage needs to be submitted to underwriting.
        </Typography>
        <Typography className={classes.description} variant="body2">
          Manual underwriter review is needed, you will receive a quote in loan chat.
        </Typography>
      </Box>
    </Box>
  );
};
