import {
  Avatar,
  Badge,
  Box,
  IconButton,

  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  MenuList,
  Typography,

} from '@material-ui/core';
import { lighten } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { SendOutlined } from '@material-ui/icons';
import { Paper, TextField } from '@roc/ui';
import { Layout } from '@roc/client-portal-shared/components';
import React from 'react';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(0),
  },
  avatarRoot: {
    fontSize: '.75rem',
  },
  noBorder: {
    border: 'none',
  },
}));

export default function LoansList() {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <MenuList>
      <MenuItem
        style={{ background: lighten(theme.palette.primary.main, 0.9) }}
      >
        <ListItemAvatar>
          <Avatar className={classes.avatarRoot}>FF</Avatar>
        </ListItemAvatar>
        <ListItemText
          primary="[18456] - Young Campbell Glover - 5329 West Girard Avenue (PA)"
          secondary="Jan 9, 2014"
          style={{ whiteSpace: 'normal', fontSize: '.75rem' }}
          primaryTypographyProps={{
            variant: 'h6',
          }}
        />
        <ListItemSecondaryAction style={{ paddingRight: '5px' }}>
          <Badge badgeContent={4} color="error"></Badge>
        </ListItemSecondaryAction>
      </MenuItem>
      <MenuItem>
        <ListItemAvatar>
          <Avatar className={classes.avatarRoot}>FF</Avatar>
        </ListItemAvatar>
        <ListItemText
          primary="[19119] - 857 S 14 (NJ)/97 Aldine (NJ)"
          secondary="Jan 7, 2014"
          style={{ whiteSpace: 'normal', fontSize: '.75rem' }}
          primaryTypographyProps={{
            variant: 'h6',
          }}
        />
        <ListItemSecondaryAction style={{ paddingRight: '5px' }}>
          <Badge badgeContent={2} color="error"></Badge>
        </ListItemSecondaryAction>
      </MenuItem>
      <MenuItem>
        <ListItemAvatar>
          <Avatar className={classes.avatarRoot}>SFR</Avatar>
        </ListItemAvatar>
        <ListItemText
          primary="	Duran - 1001 Dolbeer Street (ID)"
          secondary="July 20, 2014"
          style={{ whiteSpace: 'normal', fontSize: '.75rem' }}
          primaryTypographyProps={{
            variant: 'h6',
          }}
        />
        <ListItemSecondaryAction style={{ paddingRight: '5px' }}>
          <Badge badgeContent={13} color="error"></Badge>
        </ListItemSecondaryAction>
      </MenuItem>
    </MenuList>
  );
}

export const Messages = () => {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <Layout title="Unread Messages">
      <Paper className={classes.paper}>
        <Box height="800px" display="flex" flexDirection="row">
          <Box py={4} flex="0 0 300px" borderRight="1px solid #eee">
            <LoansList />
          </Box>
          <Box display="flex" flexDirection="column" flexGrow="1">
            <Box p={4} borderBottom="1px solid #eee">
              <Typography variant="h4">
                [18456] - Young Campbell Glover - 5329 West Girard Avenue (PA)
              </Typography>
            </Box>
            <Box
              p={4}
              borderBottom="1px solid #eee"
              flexGrow="1"
              overflow="auto"
              style={{ background: '#f8f8f8' }}
            >
              If you're looking for random paragraphs, you've come to the right
              place. When a random word or a random sentence isn't quite enough,
              the next logical step is to find a random paragraph. We created
              the Random Paragraph Generator with you in mind. The process is
              quite simple. Choose the number of random paragraphs you'd like to
              see and click the button. Your chosen number of paragraphs will
              instantly appear. While it may not be obvious to everyone, there
              are a number of reasons creating random paragraphs can be useful.
              A few examples of how some people use this generator are listed in
              the following paragraphs. Creative Writing Generating random
              paragraphs can be an excellent way for writers to get their
              creative flow going at the beginning of the day. The writer has no
              idea what topic the random paragraph will be about when it
              appears. This forces the writer to use creativity to complete one
              of three common writing challenges. The writer can use the
              paragraph as the first one of a short story and build upon it. A
              second option is to use the random paragraph somewhere in a short
              story they create. The third option is to have the random
              paragraph be the ending paragraph in a short story. No matter
              which of these challenges is undertaken, the writer is forced to
              use creativity to incorporate the paragraph into their writing.
              Tackle Writers' Block A random paragraph can also be an excellent
              way for a writer to tackle writers' block. Writing block can often
              happen due to being stuck with a current project that the writer
              is trying to complete. By inserting a completely random paragraph
              from which to begin, it can take down some of the issues that may
              have been causing the writers' block in the first place. Beginning
              Writing Routine Another productive way to use this tool to begin a
              daily writing routine. One way is to generate a random paragraph
              with the intention to try to rewrite it while still keeping the
              original meaning. The purpose here is to just get the writing
              started so that when the writer goes onto their day's writing
              projects, words are already flowing from their fingers. Writing
              Challenge Another writing challenge can be to take the individual
              sentences in the random paragraph and incorporate a single
              sentence from that into a new paragraph to create a short story.
              Unlike the random sentence generator, the sentences from the
              random paragraph will have some connection to one another so it
              will be a bit different. You also won't know exactly how many
              sentences will appear in the random paragraph.
            </Box>
            <Box
              borderBottom="1px solid #eee"
              display="flex"
              flexDirection="row"
            >
              <Box flexGrow="1">
                <TextField
                  testId="chatInput"
                  variant="outlined"
                  placeholder="Message"
                  multiline
                  minRows={1}
                  maxRows={5}
                  fullWidth
                  inputProps={{
                    maxLength: 2000,
                    disableUnderline: true,
                    classes: { notchedOutline: classes.noBorder },
                  }}
                />
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
              >
                <IconButton aria-label="send message" component="span">
                  <SendOutlined />
                </IconButton>
              </Box>
            </Box>
          </Box>
        </Box>
      </Paper>
    </Layout>
  );
};
