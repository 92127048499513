import { useBaseStore, useUserStore } from '@roc/feature-app-core';
import { useAppraisalReviewStore } from '@roc/feature-appraisals';
import { Document } from '@roc/feature-types';
import { StandardDialog } from '@roc/ui';
import { AppraisalReviewReportForm, EditableToogleButton } from 'libs/feature-appraisals/src/appraisals/appraisalReview/appraisalReviewForm';
import { ManualOrAutomatedChip } from 'libs/feature-appraisals/src/appraisals/appraisalReview/manualOrAutomatedChip';
import { observer } from 'mobx-react';

type Props = {
  open: boolean;
  onClose: () => void;
  document: Document;
};

export const AppraisalReviewReportDialog = observer(
  ({ open, onClose, document }: Props) => {
    const { globalStore } = useBaseStore();
    const { userStore } = useUserStore();

    if (!globalStore.isInternalPortal) {
      return null;
    }

    const { appraisalReviewStore } = useAppraisalReviewStore();

    const dialogContent = (
      <AppraisalReviewReportForm
        store={appraisalReviewStore}
        loanId={document?.loanId}
        propertyId={document?.collateralId}
        onClose={onClose}
        hideTitle={true}
        successCallback={() => {
          globalStore.notificationStore.showSuccessNotification({
            message: 'Data saved successfully.',
          });
          onClose();
        }}
      />
    );

    return (
      <StandardDialog
        maxWidth={'lg'}
        open={open}
        title={
          <>
            Appraisal Review Report{' '}
            <ManualOrAutomatedChip
              status={appraisalReviewStore.manualAutomatedStatus}
            />
            {userStore.canEditAppraisalReportConfig(appraisalReviewStore.reportVersion) && (
              <EditableToogleButton
                checked={appraisalReviewStore.editEnabled}
                label="Editable"
                onClick={() => { appraisalReviewStore.updateReportEditableFlag(document?.collateralId) }}
              />
            )}
          </>
        }
        handleClose={onClose}
        dialogContent={dialogContent}
        removeContentBox
      />
    );
  }
);
