import { action, computed, flow, makeObservable, observable } from 'mobx';
import { GlobalStore, GridStore } from '../../../stores';
import { RingCentralService } from '../services/ringCentralService';
import { SendBulkSMSFormStore } from 'apps/crm-portal/src/app/modules/createList/stores/SendBulkSMSFormStore';
import { ApiResponse } from '@roc/feature-app-core';
import {
  BORROWER_OBJECT_TYPE,
  BROKER_OBJECT_TYPE,
  LEAD_OBJECT_TYPE,
  MIXED_OBJECT_TYPE,
  TPO_OBJECT_TYPE,
} from 'libs/feature-backoffice-users/src/backOfficeUsers/components/leads/constants/constants';
import { replacePlaceholders } from 'libs/feature-sendgrid/src/utils/sendgridUtils';
import { GENERIC_ERROR_MESSAGE } from '@roc/feature-utils';
import { normalizeHTMLTags } from '@roc/feature-utils';

export class RingCentralStore {
  private globalStore: GlobalStore;
  public templatesGrid: GridStore;
  public password: string;
  public userName: string;
  public extension: string;
  ringCentralService: RingCentralService;
  public message: string;
  public label: string;
  public templateType: string;
  public templateSubject: string;
  public bombBombVideoLink: string;
  public imageInsertionOption: string = 'noImage';
  public phoneNumbers: string[];
  public templates: Array<any> = [];
  public templateId: string;
  public templateLabel: string;
  public templateValue: string;
  public sendToAll: boolean;
  public recordType: string;
  public listId: string;
  public openCreateTemplateModal: boolean;
  sendBulkSMSFormStore: SendBulkSMSFormStore;
  public leads: any[];
  public borrowers: any[];
  public companyContacts: any[];
  public mixedRecords: any[];
  public ringCentralCreds: any;
  public phoneBurnerCreds: any;
  public smsCount: number;
  public templateAttachments: any[];
  public templateInsertions: any[];
  public mockTemplateInsertions: string;
  public previewHtml: string;
  public smsSentSuccessfuly: boolean;
  public sendToOptions: Array<{ label: string; value: string }>;

  constructor(globalStore) {
    this.globalStore = globalStore;
    this.ringCentralService = new RingCentralService();
    this.sendBulkSMSFormStore = new SendBulkSMSFormStore(globalStore);
    this.templatesGrid = new GridStore(() => this.getAllTemplatesByUser());
    this.templateId = '';
    this.templateLabel = '';
    this.templateSubject = '';
    this.templateType = '';
    this.leads = [];
    this.borrowers = [];
    this.companyContacts = [];
    this.mixedRecords = [];
    this.templateAttachments = [];
    this.templateInsertions = [];
    this.smsSentSuccessfuly = false;
    this.sendToOptions = [];
    this.sendToOptions = [
      { label: 'Primary phone', value: 'primary' },
      { label: 'Secondary phone', value: 'secondary' },
      { label: 'Additional phone 1', value: 'additional1' },
      { label: 'Additional phone 2', value: 'additional2' },
      { label: 'Additional phone 3', value: 'additional3' },
      { label: 'Additional phone 4', value: 'additional4' },
      { label: 'Additional phone 5', value: 'additional5' },
    ];

    makeObservable(this, {
      password: observable,
      userName: observable,
      extension: observable,
      message: observable,
      label: observable,
      templateType: observable,
      templateSubject: observable,
      phoneNumbers: observable,
      templates: observable,
      templateLabel: observable,
      templateValue: observable,
      templateId: observable,
      sendToAll: observable,
      recordType: observable,
      listId: observable,
      openCreateTemplateModal: observable,
      previewHtml: observable,
      setPassword: flow,
      setExtension: flow,
      setUserName: flow,
      insertTemplate: flow,
      getAllTemplates: flow,
      sendSMS: flow,
      setMessage: flow,
      setRecord: action,
      removeLead: flow,
      setRecordsFromGrid: action,
      setMixedRecordsFromGrid: action,
      resetLists: action,
      setTemplateLabel: flow,
      setTemplateValue: action,
      setTemplateSubject: action,
      setTemplateType: action,
      setTemplateId: action,
      setLabel: action,
      updateTemplate: flow,
      deleteTemplate: flow,
      retriveTokenWithOAuthToken: flow,
      handleTokenCallback: flow,
      setSendToAll: flow,
      setRecordType: flow,
      setListId: flow,
      getRecordList: action,
      templatesGrid: observable,
      leads: observable,
      borrowers: observable,
      companyContacts: observable,
      smsCount: observable,
      getSmsCount: flow,
      updatePreview: flow,
      ringCentralCreds: observable,
      phoneBurnerCreds: observable,
      templateAttachments: observable,
      setTemplateAttachments: action,
      smsSentSuccessfuly: observable,
      initSentToOptions: observable,
      sendToSelf: flow,
      bombBombVideoLink: observable,
      setBombBombVideoLinkValue: action,
      getTemplateValue: computed,
      imageInsertionOption: observable,
      templateInsertions: observable,
      setImageInsertions: action,
      mockTemplateInsertions: observable,
      setImageOption: action
    });
  }

  *initSentToOptions(type) {
    switch (type) {
      case TPO_OBJECT_TYPE:
      case BROKER_OBJECT_TYPE:
      case BORROWER_OBJECT_TYPE:
        this.sendToOptions = [
          { label: 'All', value: 'all' },
          { label: 'Primary phone', value: 'primary' },
          { label: 'Secondary phone', value: 'secondary' },
        ];
        break;
      default:
        this.sendToOptions = [
          { label: 'All', value: 'all' },
          { label: 'Primary phone', value: 'primary' },
          { label: 'Secondary phone', value: 'secondary' },
          { label: 'Additional phone 1', value: 'additional1' },
          { label: 'Additional phone 2', value: 'additional2' },
          { label: 'Additional phone 3', value: 'additional3' },
          { label: 'Additional phone 4', value: 'additional4' },
          { label: 'Additional phone 5', value: 'additional5' },
        ];
    }
  }

  async getAllTemplatesByUser() {
    try {
      const response: ApiResponse = await this.ringCentralService.getAllTemplatesByUser(
        this.templatesGrid.gridData.meta.pageNumber,
        this.templatesGrid.gridData.meta.pageSize,
        this.templatesGrid.gridData.meta.sortBy,
        this.templatesGrid.gridData.meta.sortDir,
        this.templatesGrid.gridData.meta.filters,
        this.templatesGrid.gridData.meta.dropdownFilters
      );
      return response;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'An error occurred while fetching list of lenders',
      });
    }
  }

  setPassword(value) {
    this.password = value;
  }

  setUserName(value) {
    this.userName = value;
  }

  setExtension(value) {
    this.extension = value;
  }

  *insertTemplate(attachments: File[]) {
    try {
      const form = new FormData();
      if (attachments.length == 0 && this.templateInsertions.length == 0) {
        form.append(
          'bulkSMSTemplate',
          JSON.stringify({
            label: this.label,
            value: this.templateValue,
            type: this.templateType,
            subject: this.templateSubject,
            templateAttachments: this.templateAttachments,
            bombBombVideoLink: this.bombBombVideoLink
          })
        );
      }
      if (attachments.length > 0) {
        attachments.forEach(file => {
          form.append('file', file);
          form.append(
            'bulkSMSTemplate',
            JSON.stringify({
              label: this.label,
              value: this.templateValue,
              type: this.templateType,
              subject: this.templateSubject,
              templateAttachments: this.templateAttachments,
              bombBombVideoLink: this.bombBombVideoLink
            })
          );
        });
      } if (this.templateInsertions.length > 0) {
        this.templateInsertions.forEach(fileUpload => {
          form.append('imageInsertions', fileUpload.file);
          form.append(
            'bulkSMSTemplate',
            JSON.stringify({
              templateId: this.templateId,
              label: this.label,
              value: this.templateValue,
              type: this.templateType,
              subject: this.templateSubject,
              templateAttachments: this.templateAttachments,
            })
          );

        });
      }
      yield this.ringCentralService.insertTemplate(form);
      this.templatesGrid.fetchGridData();
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Template added succesfully',
      });
    } catch (error) {
      console.log(error);
    }
  }

  *deleteTemplate(templateId) {
    try {
      yield this.ringCentralService.deleteTemplate(templateId);
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Template deleted succesfully',
      });
      this.templatesGrid.fetchGridData();
    } catch (error) {
      console.log(error);
    }
  }

  *updateTemplate(attachments: File[]) {
    try {
      const form = new FormData();
      if (attachments.length == 0 && this.templateInsertions.length == 0) {
        form.append(
          'bulkSMSTemplate',
          JSON.stringify({
            templateId: this.templateId,
            label: this.label,
            value: this.templateValue,
            subject: this.templateSubject,
            type: this.templateType,
            templateAttachments: this.templateAttachments,
            bombBombVideoLink: this.bombBombVideoLink,
            imageInsertions: this.mockTemplateInsertions
          })
        );
      }
      if (attachments.length > 0) {
        attachments.forEach(file => {
          form.append('file', file);
          form.append(
            'bulkSMSTemplate',
            JSON.stringify({
              templateId: this.templateId,
              label: this.label,
              value: this.templateValue,
              type: this.templateType,
              subject: this.templateSubject,
              templateAttachments: this.templateAttachments,
              bombBombVideoLink: this.bombBombVideoLink,
              imageInsertions: this.mockTemplateInsertions
            })
          );
        });
      } if (this.templateInsertions.length > 0) {
        this.templateInsertions.forEach(fileUpload => {
          form.append('imageInsertions', fileUpload.file);
          form.append(
            'bulkSMSTemplate',
            JSON.stringify({
              templateId: this.templateId,
              label: this.label,
              value: this.templateValue,
              type: this.templateType,
              subject: this.templateSubject,
              templateAttachments: this.templateAttachments,
              imageInsertions: this.mockTemplateInsertions
            })
          );
        });
      }
      yield this.ringCentralService.updateTemplate(form)
      this.templatesGrid.fetchGridData();
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Template updated succesfully',
      });
    } catch (error) {
      console.log(error);
    }
  }

  *getAllTemplates() {
    try {
      const response = yield this.ringCentralService.getAllTemplates();
      this.templates = response.data.filter(element => element.type === 'text');
      return this.templates;
    } catch (error) {
      console.log(error);
    }
  }

  *sendSMS(dashboardType, sendTo, fromGrid, businessUnit: string) {
    try {
      if (sendTo[0].value == 'all') {
        sendTo = this.sendToOptions.filter(t => t.value != 'all');
      }
      const sendToValues = sendTo.map(option => option.value);
      const sendSMSRequest = {
        records: this.getRecordList(dashboardType),
        message: this.sendBulkSMSFormStore.form.fields.message.value,
        recordType: this.recordType,
        sendToAll: this.sendToAll,
        listId: this.listId,
        sendTo: sendToValues,
        businessUnit: businessUnit,
      };

      const response = yield this.ringCentralService.sendSMS(sendSMSRequest);

      if (response.data?.success) {
        this.globalStore.notificationStore.showSuccessNotification({
          message: response.data.message,
        });
        if (fromGrid) {
          this.smsSentSuccessfuly = true;
        }
      } else {
        this.globalStore.notificationStore.showErrorNotification({
          message: response.data.message,
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  *sendToSelf() {
    const response = yield this.ringCentralService.sendSMSToSelf({
      message: this.sendBulkSMSFormStore.form.fields.message.value,
    });
    try {
      if (response.data?.success) {
        this.globalStore.notificationStore.showSuccessNotification({
          message: response.data.message,
        });
        this.smsSentSuccessfuly = true;
      } else {
        this.globalStore.notificationStore.showErrorNotification({
          message: response.data.message,
        });
      }
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: response.data.message,
      });
    }
  }

  getRecordList(dashboardType) {
    if (dashboardType === MIXED_OBJECT_TYPE) {
      return this.mixedRecords;
    }
    if (dashboardType === BORROWER_OBJECT_TYPE) {
      return this.borrowers;
    } else if (
      dashboardType === TPO_OBJECT_TYPE ||
      dashboardType === BROKER_OBJECT_TYPE
    ) {
      return this.companyContacts;
    } else {
      return this.leads;
    }
  }

  *getSmsCount() {
    const resp = yield this.ringCentralService.getSmsCount();
    this.smsCount = resp.data;
  }

  async getCreds() {
    const response = await this.ringCentralService.getCreds();
    this.ringCentralCreds = response?.data;
    this.phoneBurnerCreds =
      response?.data?.phoneBurnerCreds?.clientCredentialsMap;
  }

  setMessage(value) {
    this.message = value;
  }

  setRecord(phoneNumber, data, selected, dashboardType) {
    const id = this.getId(data, dashboardType);
    if (dashboardType === BORROWER_OBJECT_TYPE) {
      this.addBorrower(phoneNumber, id, selected);
    } else if (
      dashboardType === TPO_OBJECT_TYPE ||
      dashboardType === BROKER_OBJECT_TYPE
    ) {
      this.addCompanyContact(phoneNumber, id, selected, dashboardType);
    } else {
      this.addLead(phoneNumber, id, selected);
    }
  }
  addStandaloneRecord(id: number, data: any, type: string) {
    if (type === BORROWER_OBJECT_TYPE) {
      this.addBorrower(data, id, true);
    } else if (type === TPO_OBJECT_TYPE || type === BROKER_OBJECT_TYPE) {
      this.addCompanyContact(data, id, true, type);
    } else {
      this.addLead(data, id, true);
    }
  }

  addBorrower(data, id, selected) {
    if (!selected) {
      this.removeBorrower(data?.phoneNumber);
    } else {
      this.borrowers.push({
        id,
        phoneNumber: data?.phone,
        secondaryPhoneNumber: data?.secondaryPhoneNumber,
        doNotSms: data?.doNotSms,
        brokered: data?.brokered,
        type: BORROWER_OBJECT_TYPE,
      });
    }
  }

  addCompanyContact(data, id, selected, type) {
    if (!selected) {
      this.removeCompanyContact(data?.phoneNumber);
    } else {
      this.companyContacts.push({
        id,
        phoneNumber: data?.phone,
        secondaryPhoneNumber: data?.secondaryPhoneNumber,
        doNotSms: data?.doNotSms,
        brokered: data?.brokered,
        type,
      });
    }
  }

  addLead(data, id, selected) {
    if (!selected) {
      this.removeLead(data?.phoneNumber);
    } else {
      this.leads.push({
        id,
        phoneNumber: data?.phone,
        secondaryPhoneNumber: data?.secondaryPhoneNumber,
        type: LEAD_OBJECT_TYPE,
        doNotSms: data?.doNotSms,
        brokered: data?.brokered,
      });
    }
  }

  isPhoneNumberEmpty(phoneNumber) {
    return !phoneNumber?.toString()?.replace(' ', '');
  }

  resetLists() {
    this.leads = [];
    this.borrowers = [];
    this.companyContacts = [];
  }

  removeLead(value) {
    const indices = this.leads.reduce((acc, lead, index) => {
      if (lead.phoneNumber === value) {
        acc.push(index);
      }
      return acc;
    }, []);

    indices.forEach(index => {
      this.leads.splice(index, 1);
    });
  }

  removeBorrower(value) {
    const indices = this.borrowers.reduce((acc, borrower, index) => {
      if (borrower.phoneNumber === value) {
        acc.push(index);
      }
      return acc;
    }, []);

    indices.forEach(index => {
      this.borrowers.splice(index, 1);
    });
  }

  removeCompanyContact(value) {
    const indices = this.leads.reduce((acc, companyContact, index) => {
      if (companyContact.phoneNumber === value) {
        acc.push(index);
      }
      return acc;
    }, []);

    indices.forEach(index => {
      this.companyContacts.splice(index, 1);
    });
  }

  setRecordsFromGrid(selectedRecords, dashboardType) {
    if (dashboardType === BORROWER_OBJECT_TYPE) {
      this.borrowers = selectedRecords
        .filter(
          borrower => !!borrower && !this.isPhoneNumberEmpty(borrower.phone)
        )
        .map(borrower => {
          return {
            id: borrower.contactId,
            borrowerId: borrower.borrowerId,
            phoneNumber: borrower.phone,
            secondaryPhoneNumber: borrower.secondaryPhoneNumber,
            type: BORROWER_OBJECT_TYPE,
            doNotSms: borrower?.doNotSms,
            brokered: borrower?.brokered,
          };
        });
    } else if (
      dashboardType === TPO_OBJECT_TYPE ||
      dashboardType === BROKER_OBJECT_TYPE
    ) {
      this.companyContacts = selectedRecords
        .filter(
          companyContact =>
            !!companyContact && !this.isPhoneNumberEmpty(companyContact.phone)
        )
        .map(companyContact => {
          return {
            id: companyContact.contactId,
            companyContactId: companyContact.companyContactId,
            phoneNumber: companyContact.phone,
            secondaryPhoneNumber: companyContact.secondaryPhone,
            type: dashboardType,
            doNotSms: companyContact?.doNotSms,
            brokered: companyContact?.brokered,
          };
        });
    } else {
      this.leads = selectedRecords
        .filter(lead => !!lead && !this.isPhoneNumberEmpty(lead.phone))
        .map(lead => {
          return {
            id: lead.leadId,
            phoneNumber: lead.phone,
            secondaryPhoneNumber: lead.secondaryPhone,
            type: LEAD_OBJECT_TYPE,
            doNotSms: lead?.doNotSms,
            brokered: lead?.brokered,
          };
        });
    }
  }

  setMixedRecordsFromGrid(selectedRecords, recordType) {
    this.mixedRecords = [];
    const uniqueIds = new Set();

    selectedRecords.forEach(record => {
      if (!!record && !this.isPhoneNumberEmpty(record.phone)) {
        const recordId = record.contactId || record.leadId;

        if (!uniqueIds.has(recordId)) {
          uniqueIds.add(recordId);

          if (record.borrowerId) {
            this.mixedRecords.push({
              id: record.contactId,
              borrowerId: record.borrowerId,
              phoneNumber: record.phone,
              secondaryPhoneNumber: record.secondaryPhone,
              type: BORROWER_OBJECT_TYPE,
              doNotSms: record?.doNotSms,
              brokered: record?.brokered,
            });
          } else if (record.companyContactId) {
            this.mixedRecords.push({
              id: record.contactId,
              companyContactId: record.companyContactId,
              phoneNumber: record.phone,
              secondaryPhoneNumber: record.secondaryPhone,
              type: recordType,
              doNotSms: record?.doNotSms,
              brokered: record?.brokered,
            });
          } else {
            this.mixedRecords.push({
              id: record.leadId || record.contactId,
              phoneNumber: record.phone,
              secondaryPhoneNumber: record.secondaryPhone,
              type: LEAD_OBJECT_TYPE,
              doNotSms: record?.doNotSms,
              brokered: record?.brokered,
            });
          }
        }
      }
    });

    console.log('mixed records in ringCentralStore:');
    console.log(this.mixedRecords);
  }

  getId(record, dashboardType) {
    return dashboardType === BORROWER_OBJECT_TYPE ||
      dashboardType === TPO_OBJECT_TYPE ||
      dashboardType === BROKER_OBJECT_TYPE
      ? record.contactId
      : record.leadId;
  }

  setTemplateLabel(value) {
    this.templateLabel = value;
  }

  setTemplateValue(value: string) {
    this.templateValue =
      this.templateType === 'email' ? normalizeHTMLTags(value) : value;
  }

  setBombBombVideoLinkValue(value: string) {
    this.bombBombVideoLink = value;
  }

  setTemplateType(value) {
    this.templateType = value;
    if (value == 'text') {
      this.templateSubject = '';
    }
  }

  setTemplateAttachments(value: any[]) {
    this.templateAttachments = value;
  }

  setImageInsertions(value: string) {
    this.mockTemplateInsertions = value;
  }

  setTemplateSubject(value) {
    this.templateSubject = value;
  }

  setTemplateId(value) {
    this.templateId = value;
  }

  setLabel(value) {
    this.label = value;
  }

  setSendToAll(value) {
    this.sendToAll = value;
  }

  setRecordType(value) {
    this.recordType = value;
  }

  setListId(value) {
    this.listId = value;
  }

  *retriveTokenWithOAuthToken(userId, host) {
    yield this.ringCentralService.retrieveTokenWithOAuthCode(
      userId,
      host,
      this.ringCentralCreds?.ringCentralCreds?.clientId
    );
  }

  *handleTokenCallback(code, state) {
    yield this.ringCentralService.handleTokenCallback(code, state);
  }

  setOpenCreateTemplateModal(value) {
    this.openCreateTemplateModal = value;
  }

  reset() {
    this.resetContent();
    this.resetLists();
    this.listId = '';
  }

  resetContent() {
    this.sendBulkSMSFormStore.reset();
    this.message = '';
    this.label = '';
    this.templateValue = '';
    this.templateLabel = '';
    this.templateSubject = '';
    this.templateId = '';
    this.templateType = '';
    this.bombBombVideoLink = '';
    this.templateInsertions = [];
  }

  *updatePreview(content: string, lead?) {
    try {
      this.previewHtml = '';
      if (content) {
        this.previewHtml = replacePlaceholders(content, {
          firstname: lead?.firstName,
          lastname: lead?.lastName,
          fullname: `${lead?.firstName} ${lead?.lastName}`,
        });
      }
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE,
      });
    }
  }

  get getTemplateValue() {
    return this.bombBombVideoLink?.includes('</a>') ? this.templateValue?.concat(`<div style="margin:20px 0">${this.bombBombVideoLink}</div>`) : this.templateValue;
  }

  setImageOption() {
    const mockImagesArray = this.mockTemplateInsertions ? this.mockTemplateInsertions.split(',') : [];
    if (mockImagesArray?.length == 1) this.imageInsertionOption = 'oneImage';
    else if (mockImagesArray?.length > 1) this.imageInsertionOption = 'twoImagesSideBySide';
  }
}
