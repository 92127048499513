import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { useLoanStore } from '@roc/feature-loans-shared';
import { observer } from 'mobx-react';
import { ReviseScopeOfWorkShared } from './reviseScopeOfWorkShared';

const useStyles = makeStyles(theme => ({
  container: {
    padding: 32,
  },
  dealName: {
    display: 'flex',
    justifyContent: 'center',
  },
  title: {
    paddingBottom: 24,
  },
}));

export const ReviseScopeOfWork = observer(({ loanId }: { loanId: string }) => {
  const { loanStore } = useLoanStore();
  const classes = useStyles();

  return (
    <>
      <Grid container direction="column" className={classes.container}>
        <Grid item className={classes.dealName}>
          <Typography variant="h6" color="textSecondary">
            {loanStore?.loanDetails.dealName}
          </Typography>
        </Grid>
        <Grid item className={classes.title}>
          <Typography variant="h4">REVISE SCOPE OF WORK</Typography>
        </Grid>
        <ReviseScopeOfWorkShared loanId={loanId} />
      </Grid>
    </>
  );
});
