import React from 'react';
import { Grid, Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import { SinglePropertyStore, RentalPortfolioStore } from './../stores';
import { SelectBorrowers, SelectBorrowerMode, useBorrowersRoutes } from '@roc/feature-borrowers';
import BorrowerFormTerm from './borrowerFormTerm';
import { useBaseStore } from '@roc/feature-app-core';
import { LoanSubType, LoanType } from '@roc/feature-utils';
import { ErrorsAlert, WarningsAlert } from './alerts';

const useStyles = makeStyles(theme =>
  createStyles({
    fieldSpacing: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    button: {
      marginRight: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    rowSpacing: {
      marginBottom: theme.spacing(2),
    },
    cardSpacing: {
      paddingRight: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  })
);

interface BorrowerInformationProps {
  store: SinglePropertyStore | RentalPortfolioStore;
}

const BorrowerInformation = ({ store }: BorrowerInformationProps) => {
  const { globalStore } = useBaseStore();
  const { isNewEntity, borrowersErrors, borrowersWarnings } = store;
  const { borrowersRoutesConfig } = useBorrowersRoutes();

  let modes = [SelectBorrowerMode.EXISTING];
  if (globalStore.userFeatures?.addNewBorrowerDuringSubmission) {
    modes = [...modes, SelectBorrowerMode.NEW];
  }

  const validateBorrowers = () => {
    store.checkBorrowersErrors();
    store.checkBorrowersWarnings();
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4">Borrower information</Typography>
          <br />
          <Typography variant="body1">
            {isNewEntity
              ? 'Please add the borrowers of this loan. '
              : 'Please review the borrowers of this loan. '}
            <>
              To add a new borrower, please navigate to
              <Link
                href={borrowersRoutesConfig.borrowers.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span> View Borrowers.</span>
              </Link>
            </>
          </Typography>
        </Grid>
        <ErrorsAlert errors={borrowersErrors} />
        <WarningsAlert warnings={borrowersWarnings} />
      </Grid>

      <br />

      <Grid container spacing={2}>
        <SelectBorrowers
          multiple={true}
          modes={modes}
          store={store.selectBorrowersStore}
          borrowerForm={(borrowerFormStore, currentBorrower) => (
            <BorrowerFormTerm
              store={borrowerFormStore}
              currentBorrower={currentBorrower}
              termStore={store}
            />
          )}
          validateBorrowers={validateBorrowers}
          showAccordion={true}
          borrowerCardDisplayFields={{
            firstName: true,
            lastName: true,
            cellPhone: true,
            emailAddress: true,
            pctOwnership: true,
            ficoProvidedAtOrigination: true,
            citizenshipStatus: true,
            roleInTransaction: true,
            borrowerExp: true,
          }}
          loanType={LoanType.RESIDENTIAL_TERM}
        />
      </Grid>
    </>
  );
};

export default observer(BorrowerInformation);
