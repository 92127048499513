import { action, flow, makeObservable, observable } from 'mobx';
import { ApiResponse, GlobalStore } from '@roc/feature-app-core';
import { CreditBackgroundType, downloadDocument, isEmptyObject, isNil, prequalificationStatusProperties, segmentAnalytics } from '@roc/feature-utils';
import BorrowerSetupFormStore from './borrowerSetupFormStore';
import { Borrower } from '@roc/feature-types';
import { BorrowerDetailsVerificationService, CreditBackgroundService } from '@roc/feature-credit-background-check';
import { BorrowersService } from 'libs/feature-borrowers/src/borrowers/services/borrowersService';
import { BorrowerSetupService } from '../../services/borrowerSetup/borrowerSetupService';

export class BorrowerSetupStore {

  private globalStore: GlobalStore;
  currentBorrower: Borrower;
  isTokenValid: boolean;
  token: string;
  borrowerDetailsVerificationUrl: string;
  borrowerDetailsManualVerificationUrl: string;
  unsubscribeLink: string;
  currentRequestId: number;
  lineItemDefaultStep: string;
  loanType: string;
  borrowerSetupFormStore: BorrowerSetupFormStore;
  private borrowerSetupService: BorrowerSetupService;
  private borrowerDetailsVerificationService: BorrowerDetailsVerificationService;
  private creditBackgroundService: CreditBackgroundService;
  private borrowersService: BorrowersService;

  constructor(
    globalStore: GlobalStore
  ) {
    this.globalStore = globalStore;
    this.setDefaults();
    this.borrowerSetupFormStore = new BorrowerSetupFormStore(globalStore);
    this.borrowerSetupService = new BorrowerSetupService();
    this.borrowerDetailsVerificationService = new BorrowerDetailsVerificationService();
    this.creditBackgroundService = new CreditBackgroundService();
    this.borrowersService = new BorrowersService();

    makeObservable(this, {
      currentBorrower: observable,
      isTokenValid: observable,
      token: observable,
      borrowerDetailsVerificationUrl: observable,
      borrowerDetailsManualVerificationUrl: observable,
      unsubscribeLink: observable,
      lineItemDefaultStep: observable,
      setDefaults: action,
      reset: action,
      init: flow,
      fetchBorrowerDetailsVerificationByBorrowerId: flow,
      getBorrowerDetailsVerificationLink: flow,
      getUnsubscribeLink: flow,
      sendIdVerificationEmailReminder: flow,
      saveBorrowerSelection: flow,
      submitCreditBackgroundRequest: flow,
      saveBorrower: flow,
      downloadReports: flow,
      savePgForLoanBorrower: flow,
    });
  }

  setDefaults() {
    this.currentBorrower = null;
    this.isTokenValid = false;
    this.token = null;
    this.borrowerDetailsVerificationUrl = null;
    this.borrowerDetailsManualVerificationUrl = null;
    this.unsubscribeLink = '';
    this.currentRequestId = null;
    this.lineItemDefaultStep = '';
  }

  reset() {
    this.setDefaults();
    this.borrowerSetupFormStore.reset();
  }

  *init(borrower, personalGuarantor, loanType) {
    this.reset();
    try {
      this.loanType = loanType;
      const response = yield this.borrowerSetupService.getBorrowerPrescreenInfoByBorrowerId(borrower.borrowerId, personalGuarantor, loanType);

      const borrowerData = response?.data?.data;
      let defaultCreditBackgroundCheck = !this.globalStore?.lenderInfo?.creditProvidedByTpo;
      this.currentBorrower = { ...borrowerData, creditBackgroundCheck: borrowerData.sendIdVerification ?? defaultCreditBackgroundCheck, personalGuarantor };
    } catch (error) {
      console.log(error);
      this.currentBorrower = borrower;
    }
    this.borrowerSetupFormStore.loadForm(this.currentBorrower);
    yield this.fetchBorrowerDetailsVerificationByBorrowerId(borrower?.borrowerId);
  }

  *fetchBorrowerDetailsVerificationByBorrowerId(borrowerId) {
    try {
      let response;
      if (this.borrowerSetupFormStore.form.fields.creditBackgroundCheck.value) {
        response = yield this.borrowerDetailsVerificationService.getLoanApplicationBorrowerDetailsFormInfoByBorrowerId(borrowerId, this.currentBorrower?.personalGuarantor);
      }
      else {
        response = yield this.borrowerDetailsVerificationService.getFormInformationByBorrowerId(borrowerId, this.currentBorrower?.personalGuarantor);
      }
      if (!isEmptyObject(response?.data?.data) || (this.currentBorrower?.sendIdVerification && this.currentBorrower?.preQualificationStatus === prequalificationStatusProperties.ACCEPTED.name)) {
        this.isTokenValid = response?.data?.data?.isTokenValid;
        this.token = response?.data?.data?.savedForm?.requestToken;
        const creditBackgroundRequestId = response?.data?.data?.savedForm?.creditBackgroundRequestId;
        if (!isNil(creditBackgroundRequestId)) {
          this.currentRequestId = creditBackgroundRequestId;
          yield this.getBorrowerDetailsVerificationLink(creditBackgroundRequestId);
          yield this.getUnsubscribeLink(response?.data?.data?.savedForm?.requestToken);
        }
      }
      else {
        this.lineItemDefaultStep = 'Borrower Setup';
      }
    } catch (error) {
      console.log(error)
      this.globalStore.notificationStore.showErrorNotification({
        message: 'We had an issue while retrieving the borrower form details.',
      });
    }
  }

  *getBorrowerDetailsVerificationLink(requestId) {
    try {
      const { creditBackgroundCheck } = this.borrowerSetupFormStore.getFormValues();

      const response = yield this.borrowerSetupService.getBorrowerSetupFormLink(requestId);
      const link = response?.data?.data;
      if (creditBackgroundCheck) {
        this.borrowerDetailsVerificationUrl = link;
        this.borrowerDetailsManualVerificationUrl = null;
      }
      else {
        this.borrowerDetailsManualVerificationUrl = link;
        this.borrowerDetailsVerificationUrl = null;
      }
    }
    catch (error) {
      console.log(error)
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while getting form link.',
      });
    }
  }

  *getUnsubscribeLink(requestToken: string) {
    try {
      const response = yield this.borrowerSetupService.getUnsubscribeLink(this.globalStore.lenderInfo?.lenderId, requestToken);
      this.unsubscribeLink = response?.data?.data;
    }
    catch (error) {
      console.log(error)
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while getting unsubscribe link.',
      });
    }
  }

  *sendIdVerificationEmailReminder(email) {
    try {
      const data = {
        formLink: this.borrowerDetailsVerificationUrl,
        email: email,
        portalId: this.globalStore.lenderInfo.portalId,
        lenderName: this.globalStore.lenderInfo.companyName,
        unsubscribedLink: this.unsubscribeLink,
      }
      yield this.borrowerSetupService.sendIdVerificationEmailReminder([data]);
    }
    catch (error) {
      console.log(error)
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while sending id verification email',
      });
    }
  }

  *saveBorrowerSelection(callback?: () => void) {
    try {
      const { borrowerId } = this.borrowerSetupFormStore.getFormValues();
      yield this.submitCreditBackgroundRequest();
      yield this.saveBorrower();
      yield this.fetchBorrowerDetailsVerificationByBorrowerId(borrowerId);
      if (callback) {
        callback();
      }
    } catch (error) {
      console.log(error);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while saving details.',
      });
    }
  }

  *submitCreditBackgroundRequest() {
    try {
      const { personalGuarantor, creditBackgroundCheck } = this.borrowerSetupFormStore.getFormValues();

      const type = creditBackgroundCheck ? CreditBackgroundType.ID_VERIFICATION : CreditBackgroundType.BG_ID_VERIFICATION_WITHOUT_NOTIFICATION;
      const response: ApiResponse = yield this.creditBackgroundService.submitCreditBackgroundRequest({
        email: this.currentBorrower?.emailAddress,
        runCredit: creditBackgroundCheck ? personalGuarantor : false,
        runBackground: true,
        type,
        isConfirmed: true,
        isSendCAFConfirmed: true,
        isDuplicateConfirmed: true,
        isBorrower: true,
        borrowerId: this.currentBorrower?.borrowerId,
      });

      return response;
    }
    catch (error) {
      console.log(error);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while submiting request.',
      });
    }
  }

  *saveBorrower() {
    try {
      const { borrowerId, creditBackgroundCheck } = this.borrowerSetupFormStore.getFormValues();
      yield this.borrowersService.saveBorrower({
        ...this.currentBorrower,
        borrowerId,
        creditBackgroundCheck
      })
        .then((data) => {
          if (data?.data?.data) {
            this.currentBorrower = { ...this.currentBorrower, ...data?.data?.data };
          }
          this.globalStore.notificationStore.showSuccessNotification({
            message: 'Details saved successfully.',
          });
        });
    } catch (error) {
      console.log(error);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while saving borrower.',
      });
    }
  }

  *downloadReports() {
    try {
      const response: ApiResponse = yield this.creditBackgroundService.downloadReports(this.currentRequestId);
      downloadDocument(response?.data, response?.headers, 'download');
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while downloading reports.',
      });
    }
  }

  *savePgForLoanBorrower(loanId, borrowerEntityId, callback?: () => void) {
    try {
      const { borrowerId, personalGuarantor } = this.borrowerSetupFormStore.getFormValues();
      if (isNil(loanId)) {
        const borrowerResponse = yield this.borrowersService.saveBorrower(this.currentBorrower);
        this.init(this.currentBorrower, personalGuarantor, this.loanType);
      }
      else {
        const loanBorrowerResponse = yield this.borrowersService.savePgToLoanBorrower({
          loanId,
          borrowerId,
          borrowerEntityId,
          personalGuarantor
        });
      }
      if (callback) {
        callback();
      }
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Details saved successfully.',
      });
    } catch (error) {
      console.log(error);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while saving details.',
      });
    }
  }

}

export default BorrowerSetupStore;