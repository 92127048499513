import { Service } from '../../../services';

export class FindLendersService extends Service {
  protected url = {
    FIND_LENDERS: '/salesforce-ui/api/v1/salesforce/lender/findLenders',
  };
  findLenders(lat: number, lng: number, state: string, radius: number) {
    return this.get(this.url.FIND_LENDERS, {
      lat,
      lng,
      state,
      distanceKM: radius,
    });
  }
}
