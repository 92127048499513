import { Service } from "@roc/feature-app-core";

export const url = {
  GET_FILTER_CRITERIA_OPTIONS: '/api/v1/lender/dashboardBuilder/getFilterCriteriaOptions',
  SAVE_DASHBOARD_LIST: '/api/v1/lender/dashboardBuilder/saveDashboardList',
  GET_FILTER_FIELDS: '/api/v1/lender/customDashboards/getFilterFields',
  GET_CUSTOM_DASHBOARD_DATA_BY_ID: '/api/v1/lender/customDashboards/getCustomDashboardDataById',
  SAVE_CUSTOM_DASHBOARD: '/api/v1/lender/customDashboards/saveCustomDashboard'

};

export class CreateListService extends Service {

  getFilterCriteriaOptions() {
    return this.get(url.GET_FILTER_CRITERIA_OPTIONS);
  }

  submitNewList(body) {
    return this.post(url.SAVE_DASHBOARD_LIST, body, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  getFilterFields(objectType){
    return this.get(url.GET_FILTER_FIELDS, { objectType:objectType.toLowerCase() })
  }

  getCustomDashboardDataById(id){
    return this.get(url.GET_CUSTOM_DASHBOARD_DATA_BY_ID, { id })
  }

  saveCustomDashboard(data, ids){
    return this.post(`${url.SAVE_CUSTOM_DASHBOARD}?ids=${ids}`, data)
  }

}