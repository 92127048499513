import { FormStore, GlobalStore, GridStore } from '@roc/feature-app-core';
import { action, flow, makeObservable, observable } from 'mobx';
import { ApiResponse } from '@roc/feature-app-core';
import {
  isNotBlank
} from '@roc/client-portal-shared/utils';
import { LenderBankInformationService } from '../services/lenderBankInformationService';
import { VerifyBankInformationDocusignFormStore } from './verifyBankInformationDocusignFormStore';

enum Steps {
  BANK_INFORMATION = 0,
  DOCUSIGN = 1,
  THANKYOU = 2,
}

export class LenderBankInformationStore {
  globalStore: GlobalStore;
  lenderBankInformationService: LenderBankInformationService;
  verifyBankInformationDocusignFormStore: VerifyBankInformationDocusignFormStore;
  pendingApprovalLenderBankInfoGridStore: GridStore;
  pendingDocusignLenderBankInfoGridStore: GridStore;
  companies = [];
  pendingApprovalBankRequests = [];
  rejectedRequests = [];
  pendingDocusignRequests = [];
  activeStep: number;
  loading: boolean;
  public docusignUrl: string;
  public bankInformationRequested: boolean;


  constructor(globalStore) {
    this.globalStore = globalStore;
    this.lenderBankInformationService = new LenderBankInformationService();
    this.verifyBankInformationDocusignFormStore = new VerifyBankInformationDocusignFormStore(globalStore);

    this.pendingApprovalLenderBankInfoGridStore = new GridStore(() =>
      this.fetchPendingApprovalLenderBankInformations()
    );

    this.pendingDocusignLenderBankInfoGridStore = new GridStore(() =>
      this.fetchPendingDocusignLenderBankInformations()
    );

    makeObservable(this, {
      submitVerifyBankInfo: flow,
      moveToStep: observable,
      activeStep: observable,
      loading: observable,
      goNextStep: flow,
      goPrevStep: flow,
      approveBankInformation: flow,
      approveBankInformationForCRM: flow,
      verifyBankInformation: flow,
      rejectBankInformation: action,
      getUserCompanies: action,
      verifyExistingCompanyEmail: action,
      getCompanyEmail: action,
      companies: observable,
      pendingApprovalBankRequests: observable,
      rejectedRequests: observable,
      pendingDocusignRequests: observable,
      setDefaults: action,
      checkRequest: action,
      getCurrentCompanyEmail: action,
      docusignUrl: observable,
      confirmDocumentSigned: flow,
      bankInformationRequested: observable
    });
    this.activeStep = 0;
    this.docusignUrl = ''
    this.bankInformationRequested = false;
  }

  *goPrevStep() {
    this.activeStep--;
  }

  *goNextStep() {
    this.activeStep++;
  }

  public *moveToStep(step) {
    this.activeStep = step;
  }

  async fetchPendingApprovalLenderBankInformations() {
    try {
      const response: ApiResponse = await this.lenderBankInformationService.getPendingApprovalLenderBankInformation(
        this.pendingApprovalLenderBankInfoGridStore.gridData.meta.pageNumber,
        this.pendingApprovalLenderBankInfoGridStore.gridData.meta.pageSize,
        this.pendingApprovalLenderBankInfoGridStore.gridData.meta.sortDir,
        this.pendingApprovalLenderBankInfoGridStore.gridData.meta.sortBy,
        this.pendingApprovalLenderBankInfoGridStore.gridData.meta.filters,
        this.pendingApprovalLenderBankInfoGridStore.gridData.meta.dropdownFilters
      );
      this.pendingApprovalBankRequests = response.data.data.rows;

      return response;
    } catch (error) {
      console.log('error ', error)
    }
  }

  async fetchPendingDocusignLenderBankInformations() {
    try {
      const response: ApiResponse = await this.lenderBankInformationService.getPendingDocusignLenderBankInformation(
        this.pendingDocusignLenderBankInfoGridStore.gridData.meta.pageNumber,
        this.pendingDocusignLenderBankInfoGridStore.gridData.meta.pageSize,
        this.pendingDocusignLenderBankInfoGridStore.gridData.meta.sortDir,
        this.pendingDocusignLenderBankInfoGridStore.gridData.meta.sortBy,
        this.pendingDocusignLenderBankInfoGridStore.gridData.meta.filters,
        this.pendingDocusignLenderBankInfoGridStore.gridData.meta.dropdownFilters
      );
      this.pendingDocusignRequests = response.data.data.rows;

      return response;
    } catch (error) {
      console.log('error ', error)
    }
  }

  getUserCompanies() {
    this.companies = this.globalStore.userCompanies.map(company => {
      return { label: company.lenderName, value: company.companyId }
    })
  }

  verifyExistingCompanyEmail(value) {
    const company = this.globalStore.userCompanies.find(company => company.companyId === value);
    return company.email ? true : false;
  }

  async getCurrentCompanyEmail(companyId: number): Promise<string> {
    const response: ApiResponse = await this.lenderBankInformationService.getCurrentCompanyEmail(companyId);
    return response.data.data;
  }

  getCompanyEmail(value) {
    const company = this.globalStore.userCompanies.find(company => company.companyId === value);
    return company.email;
  }

  *confirmDocumentSigned() {
    this.activeStep = Steps.THANKYOU;
  }

  setDefaults() {
    this.verifyBankInformationDocusignFormStore.reset();
    this.loading = false;
    this.activeStep = 0;
    this.verifyBankInformationDocusignFormStore.onChange('accountName', '');
    this.verifyBankInformationDocusignFormStore.onChange('routingNumber', '');
    this.verifyBankInformationDocusignFormStore.onChange('accountNumber', '');
    this.verifyBankInformationDocusignFormStore.onChange('bankName', '');
    this.verifyBankInformationDocusignFormStore.onChange('fullName', '');
    this.verifyBankInformationDocusignFormStore.onChange('phoneNumber', '');
    this.verifyBankInformationDocusignFormStore.onChange('emailAddress', '');
    this.verifyBankInformationDocusignFormStore.onChange('streetName', '');
    this.verifyBankInformationDocusignFormStore.onChange('city', '');
    this.verifyBankInformationDocusignFormStore.onChange('zipCode', '');
    this.verifyBankInformationDocusignFormStore.onChange('state', '');
  }

  checkRequest(value) {
    return isNotBlank(value);
  }

  *approveBankInformation(lenderId, type) {
    const response = yield this.lenderBankInformationService.approveEntityBankInformation(lenderId, type);
    this.pendingApprovalLenderBankInfoGridStore.resetAndFetchGridData()
  }

  *approveBankInformationForCRM(lenderId) {
    const response = yield this.lenderBankInformationService.approveBankInformation(lenderId);
    if (response.data.status === 'OK') {
      this.globalStore.notificationStore.showSuccessNotification({
        message: "Bank verification message request was approved"
      });
      this.bankInformationRequested = true;
    } else {
      this.globalStore.notificationStore.showErrorNotification({
        message: "Bank verification message request was not approved due to an error"
      });
    }
  }

  *verifyBankInformation(companyId: number, shouldResetGridData?: boolean) {
    try {
      const response = yield this.lenderBankInformationService.verifyBankInformation(companyId);
      if (response.data.status === 'OK') {
        this.globalStore.notificationStore.showSuccessNotification({
          message: "Bank verification message request was sent successfully"
        });
        this.bankInformationRequested = true;
      } else {
        this.globalStore.notificationStore.showErrorNotification({
          message: "Bank verification message request was not sent due to an error"
        });
      }

      if (shouldResetGridData !== false) {
        this.pendingDocusignLenderBankInfoGridStore.resetAndFetchGridData();
      }
    } catch (error) {
      console.error(error)
    }

  }

  *rejectBankInformation(lenderId, reason) {
    const response = yield this.lenderBankInformationService.rejectBankInformation(lenderId, reason);
    // this.globalStore.notificationStore.showSuccessNotification({
    //   message: "The bank verification was rejected"
    // });
  }

  filterDataByStatus(data, status) {
    return data.filter(item => item.status === status);
  }

  *submitVerifyBankInfo() {
    const request = this.createVerifyBankInfoRequest();
    this.lenderBankInformationService
      .submitDocusign(request)
      .then(response => (this.docusignUrl = response.data.viewUrl))
      .catch(error =>
        this.globalStore.notificationStore.showErrorNotification({
          message: error.error.response.data.error.message,
        })
      );
  }

  createVerifyBankInfoRequest() {
    const values = this.verifyBankInformationDocusignFormStore.getFormValues();
    const lenderBankInfoRequest = {
      accountName: values.accountName,
      routingNumber: values.routingNumber,
      accountNumber: values.accountNumber,
      bankName: values.bankName,
      fullName: values.fullName,
      phoneNumber: values.phoneNumber,
      emailAddress: values.emailAddress,
      streetName: values.streetName,
      city: values.city,
      zipCode: values.zipCode,
      state: values.state,
      companyId: values.companyId,
    }
    return lenderBankInfoRequest;
  }

}
