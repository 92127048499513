import { Service } from '@roc/feature-app-core';
import { BaseBorrowerLoanSubmissionService } from './baseBorrowerLoanSubmissionService';

const url = {
  BORROWER_ENTITIES: '/api/v1/loan/borrowerEntity/getAllEntities',
  SUBMIT_LOAN_APPLICATION:
    '/api/v1/loan/loanApplications/submitLoanApplication',
  GET_CURRENT_USER_BORROWER:
    '/api/v1/loan/loanApplications/getCurrentUserBorrower',
  SUBMIT_PUBLIC_LOAN_APPLICATION:
    '/api/v1/loan/public/loanApplications/submitPublicLoanApplication',
  SEND_ERROR_EMAIL: '/api/v1/user/public/sendErrorEmail',
  SUBMIT_TERM_LOAN_APPLICATION:
    '/api/v1/loan/loanApplications/submitTermLoanApplication',
  SUBMIT_PUBLIC_TERM_LOAN_APPLICATION:
    '/api/v1/loan/public/loanApplications/submitPublicTermLoanApplication',
};

export class BorrowerLoanSubmissionService extends BaseBorrowerLoanSubmissionService {
  getAllEntities(
    filters?: string,
    page?: number,
    pageSize?: number,
    sortDir?: string,
    sortBy?: string
  ) {
    return this.get(url.BORROWER_ENTITIES, {
      page,
      pageSize,
      sortDir,
      sortBy,
      filters,
    });
  }

  submitLoanApplication(data) {
    return this.post(url.SUBMIT_LOAN_APPLICATION, data);
  }

  submitPublicLoaApplication(data, host, username) {
    return this.post(
      `${url.SUBMIT_PUBLIC_LOAN_APPLICATION}?host=${host}&username=${username}`,
      data
    );
  }

  getCurrentUserBorrower() {
    return this.get(url.GET_CURRENT_USER_BORROWER);
  }

  sendErrorEmail(
    subject: string,
    error: string,
    details: string,
    portalId: string
  ) {
    const body = {
      subject,
      body: `BrowserInfo: ${navigator.userAgent} Error message: ${error} \n Details: ${details}`,
    };
    return this.post(`${url.SEND_ERROR_EMAIL}?portalId=${portalId}`, body);
  }

  submitTermLoanApplication(data) {
    return this.post(url.SUBMIT_TERM_LOAN_APPLICATION, data);
  }

  submitPublicTermLoaApplication(data, host, username) {
    return this.post(
      `${url.SUBMIT_PUBLIC_TERM_LOAN_APPLICATION}?host=${host}&username=${username}`,
      data
    );
  }
}
