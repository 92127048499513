import { formatPercentage } from '@roc/ui/utils';
import { observer } from 'mobx-react';
import { LoanDetailsCardProps } from '../../summary/summaryStep';
import { LoanDetailsCard } from './loanDetailsCard';

export const FixFlipLoanDetailsCard = observer(
  ({ store }: LoanDetailsCardProps) => {
    const leverage = store.loanEconomicsStore.getLoanEconomics();
    const calculatedFields = store.loanEconomicsStore.calculatedFields;

    const ltcPurchasePricePercentage = formatPercentage(leverage.loanToCost);
    const ltcConstructionBudgetHoldbackPercentage = formatPercentage(
      leverage.constructionReserve
    );
    const ARLTV = formatPercentage(calculatedFields.afterRepairLTV);

    return (
      <LoanDetailsCard
        store={store}
        keyRatios={`${ltcPurchasePricePercentage} / ${ltcConstructionBudgetHoldbackPercentage} / ${ARLTV}`}
        keyRatiosTooltip={
          'LTC Purchase Price / LTC Construction Budget (holdback) / ARLTV'
        }
      />
    );
  }
);
