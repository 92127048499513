import { Box, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { Close, Delete, FileCopyOutlined } from '@material-ui/icons';
import { formatBytes } from '@roc/feature-utils';
import { IconButton } from '@roc/ui';
import { observer } from 'mobx-react';

interface Props {
  file: File;
  onRemove: () => void;
}

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
    backgroundColor: theme.palette.grey[50],
    border: '1px solid',
    borderColor: theme.palette.grey[300],
    borderRadius: '4px',
    cursor: 'default',
    position: 'relative',
  },
  fileInfo: {
    flexBasis: '500px',
  },
  fileName: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    minWidth: '100px',
    maxWidth: '200px',
  },
  fileSize: {
    color: theme.palette.grey[500],
    fontSize: theme.typography.fontSize * 0.8,
  },
  fileIcon: {
    color: theme.palette.primary.light,
    fontSize: '32px',
    marginRight: theme.spacing(1),
  },
  closeButton: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: '12px',
    position: 'absolute',
    padding: 0,
    width: '22px',
    height: '22px',
    top: '-12px',
    right: '-12px',
    overflow: 'hidden',
  },
  closeIcon: {
    fontSize: '14px',
  },
}));

export const EditorAttachment = observer(({ file, onRemove }: Props) => {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <FileCopyOutlined className={classes.fileIcon} />
      <Box className={classes.fileInfo}>
        <Typography className={classes.fileName}>{file.name}</Typography>
        <Typography className={classes.fileSize}>{formatBytes(file.size)}</Typography>
      </Box>
      {onRemove && <IconButton
        testId="close"
        onClick={onRemove}
        className={classes.closeButton}
      >
        <Close className={classes.closeIcon} />
      </IconButton>}
    </Box>
  );
});
