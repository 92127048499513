import React from 'react';
import { Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import {
  SummaryTable,
  SummaryRow,
} from '@roc/ui';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  bold: {
    fontWeight: 600,
  },
  negative: {
    color: theme.palette.error.main,
  },
}));

type CostsTableRow = SummaryRow & {
  isNegative?: boolean;
  isTotal?: boolean;
};

type CostsTableProps = {
  title: string;
  data: CostsTableRow[];
};

export const CostsTable = observer((props: CostsTableProps) => {
  const { title, data } = props;
  const classes = useStyles();

  const renderLabel = (row: CostsTableRow) => {
    return (
      <Typography
        component="span"
        className={row.isTotal ? classes.bold : null}
      >
        {row.label}
      </Typography>
    );
  };

  const renderValue = (row: CostsTableRow) => {
    return (
      <Typography
        component="span"
        className={clsx([
          row.isTotal ? classes.bold : null,
          row.isNegative ? classes.negative : null,
        ])}
      >
        {row.value}
      </Typography>
    );
  };

  return (
    <SummaryTable
      title={title}
      data={data}
      renderLabel={renderLabel}
      renderValue={renderValue}
    />
  );
});

export default CostsTable;
