import { Grid } from '@material-ui/core';
import {
  createCurrencyField,
  createPercentageField,
  createSelectField,
  createTextField,
  createRadioField,
} from '@roc/client-portal-shared/components';
import { observer } from 'mobx-react';
import React from 'react';
import { multifamilyLiquidityCalculatorStore } from '../stores/multifamilyLiquidityCalculatorStore';
multifamilyLiquidityCalculatorStore

interface LiquidityCalcFieldProps {
  store: multifamilyLiquidityCalculatorStore;
  tooltip: string;
}

const field = FieldComponent => {
  const WrappedFieldComponent = observer(FieldComponent);
  return props => (
    <Grid item xs={4} md={props.md}>
      <WrappedFieldComponent {...props} />
    </Grid>
  );
};

export const LoanId = field(({ store }: LiquidityCalcFieldProps) => {
  return createTextField({
    store,
    label: 'Loan Id',
    testId: 'loanId',
    fieldName: 'loanId',
  });
});

export const PurchasePrice = field(({ store }: LiquidityCalcFieldProps) => {
  return createCurrencyField({
    store,
    label: 'Purchase Price',
    testId: 'purchasePrice',
    fieldName: 'purchasePrice',
  });
});

export const RenovationAmount = field(({ store }: LiquidityCalcFieldProps) => {
  return createCurrencyField({
    store,
    label: 'Renovation Amount',
    testId: 'renovationAmount',
    fieldName: 'renovationAmount',
  });
});

export const Rate = field(({ store }: LiquidityCalcFieldProps) => {
  return createPercentageField({
    store,
    label: 'Rate',
    testId: 'rate',
    fieldName: 'rate',
    decimalScale: 2,
  });
});

export const InPlaceMonthlyIncome = field(({ store }: LiquidityCalcFieldProps) => {
  return createCurrencyField({
    store,
    label: 'In-place Monthly Income',
    testId: 'inPlaceMonthlyIncome',
    fieldName: 'inPlaceMonthlyIncome',
  });
});

export const InPlaceMonthlyExpenses = field(({ store }: LiquidityCalcFieldProps) => {
  return createCurrencyField({
    store,
    label: 'In-place Monthly Expenses',
    testId: 'inPlaceMonthlyExpenses',
    fieldName: 'inPlaceMonthlyExpenses',
  });
});

export const VerifiedLiquidity = field(({ store }: LiquidityCalcFieldProps) => {
  return createCurrencyField({
    store,
    label: 'Verified Liquidity',
    testId: 'verifiedLiquidity',
    fieldName: 'verifiedLiquidity',
  });
});