import { useEffect, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { Paper } from '@roc/ui';
import { observer } from 'mobx-react';
import { useParams } from 'react-router';
import { Layout, StepperComponent } from '@roc/client-portal-shared/components';

import { DecodedData, decodeData } from './tamariskPaymentsUtils';

import TamariskPaymentInformation from './components/tamariskPaymentInformation';
import TamariskPayment from './components/tamariskPayment';
import { TamariskDocusign } from './components/tamariskDocusign';
import { useTamariskPaymentsStore } from './hooks/useTamariskPaymentsStore';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(3, 0, 4),
      borderRadius: theme.spacing(2),
      boxShadow:
        'rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px',
    },
    stepper: {
      maxWidth: 900,
      margin: 'auto',
      [theme.breakpoints.down('xs')]: {
        paddingLeft: theme.spacing(.5),
        paddingRight: theme.spacing(.5),
      },
    },
    stepContentWrapper: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      [theme.breakpoints.down('xs')]: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
      },
    },
  })
);

export type EncodedParams = {
  encodedParams: string;
};

export const TamariskPayments = observer(({ entityData }: {
  entityData: DecodedData
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const [disableStepperNavigation, setDisableStepperNavigation] = useState(
    false
  );
  const { tamariskPaymentsStore } = useTamariskPaymentsStore();
  const { docusignStatus } = tamariskPaymentsStore;
  const { entityId, tamariskFlowType } = entityData || {};
  const classes = useStyles();

  const loanTermsSteps = [
    {
      title: 'Appraisal Information',
      icon: null,
      onBack: () => { },
      onNext: () => { },
      allowBack: false,
      allowNext: true,
    },
    {
      title: 'Acknowledgment',
      icon: null,
      onBack: () => { },
      onNext: () => { },
      allowBack: false,
      allowNext: true,
    },
    {
      title: 'Payment',
      icon: null,
      onBack: () => { },
      onNext: () => { },
      allowBack: false,
      allowNext: true,
    },
  ];

  const handleBackStep = () => setActiveStep(activeStep - 1);

  const handleNextStep = () => setActiveStep(activeStep + 1);

  const handleStep = (index: number) => setActiveStep(index);

  useEffect(() => {
    return () => {
      tamariskPaymentsStore.reset();
      tamariskPaymentsStore.tamariskDocumentStore.reset();
    };
  }, []);

  useEffect(() => {
    if (entityId) {
      tamariskPaymentsStore.fetchDocusignAndPaymentStatus(entityId, tamariskFlowType);
    }
  }, [entityId]);

  useEffect(() => {
    setDisableStepperNavigation(!docusignStatus);
  }, [docusignStatus]);

  if (entityId) {
    return (
      <Layout>
        <Box my={3}>
          <Typography variant="h3" gutterBottom>
            {'Appraisal Payment'}
          </Typography>
        </Box>
        <Paper className={classes.paper}>
          <StepperComponent
            activeStep={activeStep}
            disableNavigation={disableStepperNavigation}
            handleStep={handleStep}
            steps={loanTermsSteps}
            className={classes.stepper}
          />
          <Box mt={3} className={classes.stepContentWrapper}>
            {activeStep === 0 && (
              <TamariskPaymentInformation
                handleNextStep={handleNextStep}
                entityId={entityId}
                tamariskFlowType={tamariskFlowType}
              />
            )}
            {activeStep === 1 && (
              <TamariskDocusign
                handleBackStep={handleBackStep}
                handleNextStep={handleNextStep}
                entityId={entityId}
                tamariskFlowType={tamariskFlowType}
              />
            )}
            {activeStep === 2 && (
              <TamariskPayment
                handleDisableStepperNavigation={setDisableStepperNavigation}
                entityData={entityData}
              />
            )}
          </Box>
        </Paper>
      </Layout>
    );
  } else {
    return null;
  }
});
