
import { observer } from "mobx-react";
import { Button, StandardDialog, SelectField, createPercentageField } from '@roc/ui';
import { useEffect } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { Checkbox, FormControlLabel, Grid, TextField, Typography } from '@material-ui/core';
import { useNewLeadStore } from "../../hooks/useNewLeadStore";
import { LenderRMUsers } from "libs/client-portal-shared/src/app/modules/companyProfile/lenderRelationshipManagers/lendersRMUsers";
import { useStore } from "@roc/client-portal-shared/hooks";


export const ChangeRelationshipManagerModal = observer(({ open, handleClose, salesforceLeadStore }) => {

  const { newLeadStore } = useNewLeadStore();
  const { lenderDetailsStore, companyProfileStore, salesforceLenderStore } = useStore();
  const { lenderDetailsFormStore } = lenderDetailsStore;
  const { leadsDashboardStore } = salesforceLeadStore;
  const { leadsDashboardGridStore } = leadsDashboardStore;

  useEffect(() => {
    fetchData();
  }, [open]);


  const fetchData = async () => {
    salesforceLenderStore.getRelationshipManagersUsers();
    await salesforceLeadStore.getAllOwners();
  };

  const handleSaveClick = async () => {
    await lenderDetailsStore.updateRMLenderInformation();
    leadsDashboardGridStore.resetAndFetchGridData();
    handleClose();
  };

  const handleRelationshipManagerChange = (field, value) => {
    lenderDetailsFormStore.onFieldChange(field, value)
  }

  return (
    <StandardDialog
      open={open}
      title="Change Relationship Manager"
      maxWidth="lg"
      handleClose={handleClose}
      dialogContent={
        <>
          <Grid spacing={2} style={{ display: 'flex', flexDirection: 'row', padding: '5px' }}>
            <Grid item xs={5} style={{ margin: '7px' }}>
              <LenderRMUsers
                label={"Primary Relationship Manager"}
                value={lenderDetailsFormStore.form.fields.primaryRelationshipManager?.value}
                onChange={(value) => handleRelationshipManagerChange('primaryRelationshipManager', value)}
                disabled={false}
                companyProfileStore={companyProfileStore}
              />
            </Grid>
            <br />
            <Grid item xs={5} style={{ margin: '7px' }}>
              {createPercentageField({
                testId: "primaryCommission",
                label: "Primary Commission %",
                fieldName: "primaryCommission",
                store: lenderDetailsFormStore,
                disabled: false,
              })}
            </Grid>
          </Grid>
          <Grid spacing={2} style={{ display: 'flex', flexDirection: 'row' }}>
            <Grid item xs={5} style={{ margin: '7px' }}>
              <LenderRMUsers
                label={"Secondary Relationship Manager"}
                value={lenderDetailsFormStore.form.fields.secondaryRelationshipManager?.value}
                onChange={(value) => handleRelationshipManagerChange('secondaryRelationshipManager', value)}
                disabled={false}
                companyProfileStore={companyProfileStore}
              />
            </Grid>
            <br />
            <Grid item xs={5} style={{ margin: '7px' }}>
              {createPercentageField({
                testId: "secondaryCommission",
                label: "Secondary Commission %",
                fieldName: "secondaryCommission",
                store: lenderDetailsFormStore,
                disabled: false,
              })}
            </Grid>
          </Grid>
        </>
      }
      dialogActions={
        <>
          <Button
            style={{ marginRight: '8px' }}
            onClick={handleClose}
            color="primary"
            variant="outlined"
            testId="cancel"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSaveClick}
            testId="next"
          >
            Save
          </Button>
        </>
      }
    />
  );
});
