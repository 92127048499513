import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import {
  AssignmentTurnedIn, Business, LocalOffer,
  Description, Person,
  Room
} from '@material-ui/icons';
import { PortfolioLoanSuccess } from '@roc/feature-loans';
import { Layout, useBaseStore, useUserStore } from '@roc/feature-app-core';
import { Paper, StandardDialog, StepsContainer } from '@roc/ui';
import { observer } from 'mobx-react';
import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router';
import BorrowerInformationTerm from '../components/borrowerInformationTerm';
import { BrokerInformation } from '../components/brokerInformation';
import EntityInformation from '../components/entityInformation';
import LoanDetailsTerm from '../components/loanDetailsTerm';
import LoanSummary from '../components/loanSummary';
import { useLoanSubmissionRoutes } from '../hooks/useLoanSubmissionRoutes';
import { useRentalPortfolioStore } from '../hooks/useRentalPortfolioStore';
import LoanPricerRentalPortfolio from './components/loanPricerRentalPortfolio';
import { PropertyInformationPortfolio } from './components/propertyInformationPortfolio';
import { useRoutes } from '@roc/client-portal-shared/hooks';
import { DRAFT_LOAN_TYPE_LOAN, REJECTED_STATE, REQUEST_LOAN_TYPE_LOAN } from '@roc/feature-utils';
import { RejectRequestForm } from '../components/rejectRequestForm';
import LoanRequestSuccess from '../../loans/components/loanSuccess/loanRequestSuccess';
import { insertIf } from '@roc/client-portal-shared/utils';
import { LoanSubmissionStep } from '../utils/constants';
import { LoanDocuments } from '../components/loanDocuments';
import PricerSummaryTerm_new from '../components/pricerSummaryTerm_new';
import SelectPromotionGrid from '../components/selectPromotionGrid';

const useStyles = makeStyles(theme =>
  createStyles({
    paper: {
      padding: theme.spacing(4),
    },
  })
);

export const RentalPortfolio = observer(() => {
  const { rentalPortfolioStore } = useRentalPortfolioStore();
  const { pricerStore, pricerSummaryStore } = rentalPortfolioStore
  const classes = useStyles();
  const { push } = useHistory();
  const { loanSubmissionRoutesConfig } = useLoanSubmissionRoutes();
  const { globalStore } = useBaseStore();
  const { userStore } = useUserStore();
  const { dashboardRoutesConfig } = useRoutes();
  const [openRejectReason, setOpenRejectReason] = useState(false);
  const allowLoanPricing = userStore.allowLoanPricing;
  const showBrokerInformation = !globalStore.userFeatures.isBroker;
  const showDocuments = globalStore.userFeatures.uploadDuringSubmissionEnabled;
  const isLenderOriginator = globalStore.userFeatures.isLenderOriginator;
  const { marketingPromotionsStore } = rentalPortfolioStore;
  const initialRuralValue = useRef(pricerStore.form.fields.rural.value);

  useEffect(() => {
    marketingPromotionsStore.fetchGridData();
  }, []);

  useEffect(() => {
    rentalPortfolioStore.loadStore();
  }, [rentalPortfolioStore]);

  useEffect(() => {
    if (initialRuralValue.current != pricerStore.form.fields.rural.value) {
      pricerSummaryStore.resetStore();
    }
  }, [pricerStore.form.fields.rural.value]);

  const onSubmit = () => {
    const isAllStepsValid = rentalPortfolioStore.allErrors.length === 0;
    if (rentalPortfolioStore.allErrors.length > 0) {
      globalStore.notificationStore.showErrorNotification({
        message: 'Please correct the errors listed above',
      });
    }

    if (isAllStepsValid) {
      rentalPortfolioStore.onLoanSubmit();
    }
  };

  const onSave = (showSuccessNotification = true) => {
    return rentalPortfolioStore.onLoanSave(DRAFT_LOAN_TYPE_LOAN, showSuccessNotification);
  };

  const onReprice = () => {
    rentalPortfolioStore.onReprice();
  };


  const onSaveRequest = (showSuccessNotification = true) => {
    return rentalPortfolioStore.onLoanSave(REQUEST_LOAN_TYPE_LOAN, showSuccessNotification);
    //return rentalPortfolioStore.onLoanApplicationSave();
  };

  const saveDraftLoan = (showSuccessNotification = true) => {
    return rentalPortfolioStore.isLoanRequest ? onSaveRequest(showSuccessNotification) : onSave(showSuccessNotification)
  }

  const onRejectRequest = () => {
    setOpenRejectReason(true);
  }

  const rejectRequest = () => {
    rentalPortfolioStore.draftLoanInfo.status = REJECTED_STATE
    rentalPortfolioStore.onLoanSave(REQUEST_LOAN_TYPE_LOAN);
    //rentalPortfolioStore.rejectLoanApplication();
    push(
      dashboardRoutesConfig.dashboard.url
    )
  }

  const goNextStep = () => {
    const currentStep = loanSubmissionSteps[rentalPortfolioStore.activeStep].type;
    rentalPortfolioStore.goNextStep(currentStep);
  }

  const goPrevStep = () => {
    const currentStep = loanSubmissionSteps[rentalPortfolioStore.activeStep].type;
    rentalPortfolioStore.goPrevStep(currentStep);
  }

  const moveToStep = (stepIndex) => {
    const currentStep = loanSubmissionSteps[rentalPortfolioStore.activeStep].type;
    rentalPortfolioStore.moveToStep(stepIndex, currentStep);
  }

  const setPromotion = (promotionId: number) => {
    rentalPortfolioStore.loanDetailsStore.onFieldChange('marketingPromotionId', promotionId);
  }

  const loanSubmissionSteps = [
    {
      title: 'Loan Pricer',
      icon: <Room color="primary" fontSize="large" />,
      type: LoanSubmissionStep.LOAN_PRICER,
      onBack: () => push(loanSubmissionRoutesConfig.submitloan.url),
      onNext: () => goNextStep(),
      onSave: () => saveDraftLoan(),
      onReject: () => onRejectRequest(),
      allowBack: true,
      allowNext: true,
      allowSave: true,
      allowReprice: false,
      allowReject: rentalPortfolioStore.isLoanRequest,
    },
    ...insertIf(
      allowLoanPricing || isLenderOriginator,
      [
        {
          title: 'Loan Pricer Summary',
          icon: <Description color="primary" fontSize="large" />,
          type: LoanSubmissionStep.LOAN_PRICER_SUMMARY,
          onBack: () => goPrevStep(),
          onNext: () => goNextStep(),
          onSave: () => saveDraftLoan(),
          onReject: () => onRejectRequest(),
          allowBack: true,
          allowNext: false,
          allowSave: true,
          allowReprice: false,
          allowReject: rentalPortfolioStore.isLoanRequest,
        },
      ]
    ),
    ...insertIf(showBrokerInformation, [{
      title: 'Broker Information',
      icon: <Person color="primary" fontSize="large" />,
      type: LoanSubmissionStep.BROKER_INFORMATION,
      onBack: () => goPrevStep(),
      onNext: () => goNextStep(),
      onSave: () => saveDraftLoan(),
      onReject: () => onRejectRequest(),
      allowBack: true,
      allowNext: true,
      allowSave: true,
      allowReject: rentalPortfolioStore.isLoanRequest,
    }]),
    {
      title: 'Entity Information',
      icon: <Business color="primary" fontSize="large" />,
      type: LoanSubmissionStep.ENTITY_INFORMATION,
      onBack: () => goPrevStep(),
      onNext: () => goNextStep(),
      onSave: () => saveDraftLoan(),
      onReject: () => onRejectRequest(),
      onReprice: () => onReprice(),
      allowBack: true,
      allowNext: true,
      allowSave: true,
      allowReprice: true,
      allowReject: rentalPortfolioStore.isLoanRequest,
    },
    {
      title: 'Borrower Information',
      icon: <Person color="primary" fontSize="large" />,
      type: LoanSubmissionStep.BORROWER_INFORMATION,
      onBack: () => goPrevStep(),
      onNext: () => goNextStep(),
      onSave: () => saveDraftLoan(),
      onReject: () => onRejectRequest(),
      onReprice: () => onReprice(),
      allowBack: true,
      allowNext: true,
      allowSave: true,
      allowReprice: true,
      allowReject: rentalPortfolioStore.isLoanRequest,
    },
    {
      title: 'Property Information',
      icon: <Business color="primary" fontSize="large" />,
      type: LoanSubmissionStep.PROPERTY_INFORMATION,
      onBack: () => goPrevStep(),
      onNext: () => goNextStep(),
      onSave: () => saveDraftLoan(),
      onReject: () => onRejectRequest(),
      onReprice: () => onReprice(),
      allowBack: true,
      allowNext: true,
      allowSave: true,
      allowReprice: true,
      allowReject: rentalPortfolioStore.isLoanRequest,
    },
    {
      title: 'Loan Details',
      icon: <Business color="primary" fontSize="large" />,
      type: LoanSubmissionStep.LOAN_DETAILS,
      onBack: () => goPrevStep(),
      onNext: () => {
        goNextStep()
      },
      onSave: () => saveDraftLoan(),
      onReject: () => onRejectRequest(),
      onReprice: () => onReprice(),
      allowBack: true,
      allowNext: true,
      allowSave: true,
      allowReprice: true,
      allowReject: rentalPortfolioStore.isLoanRequest,
    },
    ...insertIf(globalStore.userFeatures.uploadDuringSubmissionEnabled, [{
      title: 'Documents',
      icon: <Description color="primary" fontSize="large" />,
      type: LoanSubmissionStep.LOAN_DOCUMENTS,
      onBack: () => {
        goPrevStep();
      },
      onNext: () => {
        goNextStep();
      },
      onReject: () => onRejectRequest(),
      onSave: () => saveDraftLoan(),
      allowBack: true,
      allowNext: true,
      allowSave: true,
      allowReject: rentalPortfolioStore.isLoanRequest,
    }]),
    ...insertIf(marketingPromotionsStore.showPromotions, [{
      title: 'Promotions',
      icon: <LocalOffer color="primary" fontSize="large" />,
      type: LoanSubmissionStep.PROMOTIONS,
      onBack: () => {
        goPrevStep();
      },
      onNext: () => {
        goNextStep();
      },
      onReject: () => onRejectRequest(),
      onSave: () => saveDraftLoan(),
      allowBack: true,
      allowNext: true,
      allowSave: true,
      allowReject: rentalPortfolioStore.isLoanRequest,
    }]),
    {
      title: 'Summary',
      icon: <AssignmentTurnedIn color="primary" fontSize="large" />,
      type: LoanSubmissionStep.SUMMARY,
      onBack: () => goPrevStep(),
      onNext: () => userStore?.allowLoanPricing ? onSubmit() : onSaveRequest(),
      onSave: () => saveDraftLoan(),
      onReject: () => onRejectRequest(),
      onReprice: () => onReprice(),
      allowBack: true,
      allowNext: true,
      allowSave: true,
      allowReprice: true,
      allowReject: rentalPortfolioStore.isLoanRequest,
      disableNextButton: rentalPortfolioStore.disableSubmitButton,
    },
  ];

  return (
    <Layout title="Submit Portfolio Rental Loan">
      <Paper className={classes.paper}>
        {rentalPortfolioStore.saved ? (
          <>
            <PortfolioLoanSuccess
              loanId={rentalPortfolioStore.savedLoanId}
              showTermsheetSection={true}
              showRentalsEmail={true}
              showDocsAndNewLoanButtons
              onStartAnotherLoan={() => {
                rentalPortfolioStore.reset();
                push(
                  loanSubmissionRoutesConfig.submitloan.children.rentalPortfolio
                    .url
                );
              }}
            />
          </>
        ) : rentalPortfolioStore.requestSaved ? (
          <LoanRequestSuccess
            loanRequestId={rentalPortfolioStore?.draftLoanInfo?.draftLoanId}//loanRequestId={rentalPortfolioStore?.savedLoanId}
            onStartAnotherLoan={() => {
              rentalPortfolioStore.reset();
              push(loanSubmissionRoutesConfig.submitloan.children.rentalPortfolio.url);
            }}
          />
        ) : (
          <>
            <StepsContainer
              activeStep={rentalPortfolioStore.activeStep}
              handleStep={moveToStep}
              steps={loanSubmissionSteps}
            >
              {[
                <LoanPricerRentalPortfolio />,
                // ...insertIf(allowLoanPricing, [<PricerSummaryTerm store={rentalPortfolioStore} />]),
                ...insertIf(allowLoanPricing || isLenderOriginator, [<PricerSummaryTerm_new store={rentalPortfolioStore} onNext={() => goNextStep()} />]),
                ...insertIf(showBrokerInformation, [<BrokerInformation />]),
                <EntityInformation store={rentalPortfolioStore} />,
                <BorrowerInformationTerm store={rentalPortfolioStore} />,
                <PropertyInformationPortfolio store={rentalPortfolioStore} />,
                <LoanDetailsTerm store={rentalPortfolioStore} />,
                ...insertIf(showDocuments, [<LoanDocuments store={rentalPortfolioStore} saveDraftLoan={() => saveDraftLoan(false)} />]),
                ...insertIf(marketingPromotionsStore.showPromotions, [<SelectPromotionGrid store={marketingPromotionsStore.marketingPromotionsGridStore} promotionData={{ setPromotion: setPromotion, promotionId: rentalPortfolioStore.loanDetailsStore.getValue('marketingPromotionId') }} />]),
                <LoanSummary store={rentalPortfolioStore} />
              ]}
            </StepsContainer>
          </>
        )}
      </Paper>
      <StandardDialog
        open={openRejectReason}
        title="Reject Request Reason"
        maxWidth="sm"
        handleClose={() => setOpenRejectReason(false)}
        dialogContent={
          <RejectRequestForm onConfirm={() => rejectRequest()} onClose={() => setOpenRejectReason(false)} draftLoan={rentalPortfolioStore.draftLoanInfo} />
        }
      />
    </Layout>
  );
});
