import { Accordion, AccordionDetails, AccordionSummary, Grid, Theme, Typography, makeStyles } from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { yesNoRadioOptions } from "@roc/feature-sow-shared/constants/scopeOfWorkV2Form";
import { RadioField, SelectField, createSelectField } from "@roc/ui";
import { observer } from "mobx-react";
import { useState } from "react";
import { LoanSubType } from '@roc/feature-utils';

const useStyles = makeStyles((theme: Theme)=>({
  title: {
    fontSize: '20px',
    paddingLeft: theme.spacing(1),
    marginBottom: '0px'
  },
  item: {
    marginBottom: '10px',
  }
}))

const proposedStructuresOptions = [
  {value:'Single Family',label:'Single Family'},
  {value:'Two Family',label:'Two Family'},
  {value:'Three Family',label:'Three Family'},
  {value:'Four Family',label:'Four Family'}
];

const dealTypeOptions = [
  {value:'Ground Up',label:'Ground Up'},
  {value:'Enlargement',label:'Enlargement'},
  {value:'Development',label:'Development'},
  {value:'Fix & Flip',label:'Fix & Flip'},
];

const loanTypeOptions = [
  {
    value: LoanSubType.FIX_AND_FLIP,
    label: 'Fix & Flip',
  },
  {
    value: LoanSubType.GROUND_UP,
    label: 'Ground Up',
  },
];

const basementOptions = [
  {value:'Finish',label:'Finish'},
  {value:'Unfinish',label:'Unfinish'}
];

const qualityOptions = [
  {value:'Economy',label:'Economy'},
  {value:'Standard',label:'Standard'},
  {value:'Above Average',label:'Above Average'},
  {value:'Custom',label:'Custom'},
  {value:'Premium',label:'Premium'}
];

export const GeneralProjectInformation = observer(({store})=>{
  const classes = useStyles();
  const [ownTheProperty, setOwnTheProperty] = useState('Yes');
  const options = [];
  const handleChange = (field, value) =>
    store.onFieldChange(field, value === 'true');

  const fields = store.form.fields;

  return (
    <>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMore/>}>
          <Typography align="left" className={classes.title}>
            General Project Information
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={4} className={classes.item}>
              <RadioField
                label="Does the borrower already own the property"
                options={yesNoRadioOptions}
                fullWidth
                value={fields.ownProperty.value}
                onChange={(value) => handleChange('ownProperty', value)}
                testId="ownProperty"
                row
              />
            </Grid>
            <Grid item xs={4} className={classes.item}>
              <SelectField
                label="UW Deal Type"
                name="uwDealType"
                options={loanTypeOptions}
                variant="outlined"
                value={fields.uwDealType.value}
                onChange={value => store.onFieldChange('uwDealType', value)}
                testId="uwDealType"
                fullWidth
                disabled
              />
            </Grid>
            <Grid item xs={4} className={classes.item}>
              <SelectField
                label="Deal Type"
                name="dealType"
                options={dealTypeOptions}
                variant="outlined"
                value={fields.dealType.value}
                onChange={value => store.onFieldChange('dealType', value)}
                testId="dealType"
                fullWidth
              />
            </Grid>
            <Grid item xs={4} className={classes.item}>
              <RadioField
                label="Does the loan have Completed Renovations?"
                options={yesNoRadioOptions}
                fullWidth
                value={fields.completedRenovations.value}
                onChange={(value) => handleChange('completedRenovations', value)}
                testId="completedRenovations"
                row
              />
            </Grid>
            <Grid item xs={4} className={classes.item}>
              <SelectField
                label="Exterior Finish Quality"
                name="quality"
                options={qualityOptions}
                variant="outlined"
                value={fields.exteriorFinishQuality.value}
                onChange={value => store.onFieldChange('exteriorFinishQuality', value)}
                testId="quality"
                fullWidth
              />
            </Grid>
            <Grid item xs={4} className={classes.item}>
              <SelectField
                label="Basement"
                name="basement"
                options={basementOptions}
                variant="outlined"
                value={fields.basement.value}
                onChange={value => store.onFieldChange('basement', value)}
                testId="basement"
                fullWidth
              />
            </Grid>
            <Grid item xs={4} className={classes.item}>
            </Grid>
            <Grid item xs={4} className={classes.item}>
              <SelectField
                label="Interior Finish Quality"
                name="iquality"
                options={qualityOptions}
                variant="outlined"
                value={fields.interiorFinishQuality.value}
                onChange={value => store.onFieldChange('interiorFinishQuality', value)}
                testId="iquality"
                fullWidth
              />
            </Grid>
            <Grid item xs={4} className={classes.item}>
              <SelectField
                label="Proposed structures"
                name="newProposedStructure"
                options={proposedStructuresOptions}
                variant="outlined"
                value={fields.newProposedStructure.value}
                onChange={value => store.onFieldChange('newProposedStructure', value)}
                testId="iquality"
                fullWidth
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  )
})