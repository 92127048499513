import React, { useState } from 'react';
import {
  Box,
  Checkbox,

  FormControlLabel,
  Grid,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import { ScopeOfWorkV2FormBaseStore } from '@roc/feature-sow-shared/stores/v2';
import { ConfirmDialog, Tooltip } from '@roc/ui';
import { CategoryDB } from '../../../../../types';

const useStyles = makeStyles(theme =>
  createStyles({
    label: {
      fontWeight: 'bold',
    },
    tooltipText: {
      cursor: 'pointer',
      textDecoration: 'underline',
      color: theme.palette.primary.dark,
      fontSize: theme.typography.fontSize * 0.8,
      fontWeight: 'bold',
    },
    categoryDescription: {
      marginLeft: '30px',
    },
  })
);

interface CategoriesProps {
  store: ScopeOfWorkV2FormBaseStore;
}

const Categories = observer((props: CategoriesProps) => {
  const classes = useStyles();
  const [categoryToRemove, setCategoryToRemove] = useState<CategoryDB>();
  const { store } = props;
  const { categoryStore } = store;
  const toggleCategory = ({ checked, category, containsData }) => {
    if (checked && containsData) {
      setCategoryToRemove(category);
    } else if (checked && !containsData) {
      categoryStore.removeCategory(category);
    } else {
      categoryStore.selectCategory(category);
    }
  };

  const renderCategoryItems = column => (
    <Grid item container xs={12} sm={6} spacing={2}>
      {categoryStore.selectCategoryOptions
        .filter(x => x.column === column)
        .map(({ checked, category, containsData }) => (
          <Grid
            item
            xs={12}
            style={category.name === 'LTC Catchup' ? { display: 'none' } : {}}
          >
            <FormControlLabel
              classes={{ label: classes.label }}
              control={
                <Checkbox
                  checked={checked}
                  onChange={() =>
                    toggleCategory({ checked, category, containsData })
                  }
                  color="secondary"
                  disabled={store.isDisabled}
                />
              }
              label={category.name}
            />

            {category.additionalDescription ? (
              <Typography
                variant="body2"
                className={classes.categoryDescription}
              >
                <>
                  {`${category.description} `}
                  <Tooltip
                    title={`${category.additionalDescription}.`}
                    arrow
                    placement={'top'}
                  >
                    <Box component="span" className={classes.tooltipText}>
                      and more
                    </Box>
                  </Tooltip>
                  <Box component="span">.</Box>
                </>
              </Typography>
            ) : (
              <Typography
                variant="body2"
                className={classes.categoryDescription}
              >{`${category.description}.`}</Typography>
            )}
          </Grid>
        ))}
    </Grid>
  );

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4">Categories</Typography>
          <br />
          <Typography variant="body2">
            Please select the Categories of work
          </Typography>
          <br />
          <Grid item container spacing={2}>
            {renderCategoryItems(0)}
            {renderCategoryItems(1)}
          </Grid>
        </Grid>
      </Grid>
      <ConfirmDialog
        open={!!categoryToRemove}
        title={`Remove ${categoryToRemove?.name ?? ''}`}
        body={"This will delete all of the information entered for this Category. Are you sure you want to remove it?"}
        handleCancel={() => setCategoryToRemove(null)}
        handleClose={() => setCategoryToRemove(null)}
        handleConfirm={() => {
          categoryStore.removeCategory(categoryToRemove);
          setCategoryToRemove(null);
        }}
        confirmButtonText={'Yes, Remove'}
      />
    </>
  );
});

export default Categories;
