import { computed, flow, makeObservable, observable, action } from 'mobx';
import { GlobalStore } from '@roc/feature-app-core';
import { DocumentStore } from '@roc/feature-documents';
import { LoanStore } from '@roc/feature-loans';
import { FormStore } from '@roc/feature-app-core';
import { Document } from '@roc/feature-types';
import { DocumentName, UNDERWRITING, GENERIC_ERROR_MESSAGE, downloadDocument, CLOSING } from '@roc/feature-utils';
import { LoanClosingConditionService } from '../services/documentForms/loanClosingConditionServicie';
import { FileUpload } from '../types/documentForms/loanClosingCondition';
import { isFileAlreadyUploaded } from '../utils/documents';
const form = {
  fields: {
    conditionNotes: {
      value: '',
      error: null,
      rule: '',
    },
    status: {
      value: false,
      error: null,
      rule: '',
    },
  },
  meta: {
    isValid: true,
    error: null,
  },
};

export class LoanClosingConditionStore extends FormStore {
  loanStore: LoanStore;
  documentStore: DocumentStore;
  globalStore: GlobalStore;
  closingConditionData;
  conditionNotes: string;
  conditionStatus: boolean;
  loanClosingConditionService: LoanClosingConditionService;
  internal: boolean;
  document: Document;
  isConditionSatisfied: boolean;
  documentStatus: boolean;
  closingConversationUrl: string;
  committeeClearance: boolean;

  constructor(
    loanStore: LoanStore,
    documentStore: DocumentStore,
    globalStore: GlobalStore
  ) {
    super(form, globalStore);
    this.loanStore = loanStore;
    this.documentStore = documentStore;
    this.globalStore = globalStore;
    this.loanClosingConditionService = new LoanClosingConditionService();
    this.conditionStatus = false;
    this.isConditionSatisfied = false;
    this.documentStatus = false;
    this.committeeClearance = false;
    makeObservable(this, {
      document: observable,
      downloadDocument: flow,
      downloadDocumentInternal: flow,
      canDownloadFile: computed,
      closingConditionData: observable,
      conditionStatus: observable,
      conditionNotes: observable,
      fetchClosingConditionByDocumentId: flow,
      setConditionNotes: action,
      setConditionStatus: action,
      saveLoanClosingCondition: flow,
      uploadDocuments: flow,
      isConditionSatisfied: observable,
      setDisableButtonByStatus: action,
      documentStatus: observable,
      closingConversationUrl: observable,
      changeClosingConditionLevel: flow,
      committeeClearance: observable
    });
  }
  *fetchClosingConditionByDocumentId(documentId) {
    try {
      const response = yield this.loanClosingConditionService.getLoanClosingConditionByDocumentId(documentId, this.internal);
      this.closingConditionData = response.data;
      this.setConditionNotes(this.closingConditionData?.conditionNotes)
      this.setConditionStatus(this.closingConditionData?.status)
      this.setDisableButtonByStatus(this.closingConditionData?.documentStatus, this.closingConditionData?.status);
      this.setCommitteeClearance(this.closingConditionData);
      if (!this.internal) this.getClosingConversationURL(this.closingConditionData?.loanId);
    } catch (err) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching closing condition',
      });
    }
  }

  *saveLoanClosingCondition(onSuccess, action) {
    try {
      yield this.loanClosingConditionService.saveLoanClosingCondition({
        loanClosingConditionId: this.closingConditionData?.loanClosingConditionId,
        loanId: this.closingConditionData?.loanId,
        documentId: this.closingConditionData?.documentId,
        conditionName: this.closingConditionData?.conditionName,
        conditionDescription: this.closingConditionData?.conditionDescription,
        conditionNotes: this.conditionNotes,
        status: this.conditionStatus,
        level: this.closingConditionData?.level
      }, this.internal, action);
      this.globalStore.notificationStore.showSuccessNotification({
        message: `Closing condition details saved successfully.`,
      });
      onSuccess();
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while saving closing condition details',
      });
    }
  }

  *uploadDocuments(fileUpload: FileUpload[], documentId, callback: () => void) {
    if (fileUpload.length > 0) {
      const calls = [];
      try {
        fileUpload.forEach(file => {
          const blob = file.file.slice(0, file.file.size, file.file.type);
          const multipartFile = new File([blob], file.name, { type: file.file.type });
          const formData = new FormData();
          formData.append('file', multipartFile);
          calls.push(this.loanClosingConditionService.uploadFile(this.internal, documentId, formData));
        });
        Promise.all(calls).then(response => {
          if (!this.internal) {
            this.documentStore.fetchDocuments(this.closingConditionData?.loanId);
            this.globalStore.notificationStore.showSuccessNotification({
              message: 'File uploaded',
            })
          }
          callback();
        });
      } catch {
        this.globalStore.notificationStore.showErrorNotification({
          message: 'Error uploading file',
        });
      }
    }
  }

  *downloadDocument() {
    if (this.internal) {
      this.downloadDocumentInternal();
    } else {
      yield this.documentStore.downloadDocument(this.documentStore.currentDocument);
    }
  }

  *downloadDocumentInternal() {
    try {
      const response = yield this.loanClosingConditionService.downloadDocumentInternal(this.documentStore.currentDocument);
      downloadDocument(
        response?.data,
        response?.headers,
        'download',
        this.documentStore.currentDocument.originalFileName
      );
    } catch (err) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE
      });
    }
  };

  get canDownloadFile() {
    if (this.internal) {
      return isFileAlreadyUploaded(this.documentStore.currentDocument);
    } else {
      return this.documentStore.currentDocument?.canDownloadFile;
    }
  };
  setInternal(isInternal: boolean) {
    this.internal = isInternal;
  }
  setConditionNotes(notes: string) {
    this.conditionNotes = notes;
  }

  setConditionStatus(status: boolean) {
    this.conditionStatus = status;
  }

  setDisableButtonByStatus(documentStatus: string, isSatisfied: boolean) {
    this.isConditionSatisfied = isSatisfied;
    this.documentStatus = documentStatus === 'Accepted';
  }

  setCommitteeClearance(closingConditionData: any) {
    this.committeeClearance = closingConditionData?.level == 'Level 2';
  }

  getClosingConversationURL(loanId) {
    this.loanClosingConditionService.getClosingConversationURL(loanId)
      .then(response => this.closingConversationUrl = response.data.data)
      .catch(error => console.log(error));
  }

  *changeClosingConditionLevel(committeeClearance: boolean, successCallback: () => void) {
    this.committeeClearance = committeeClearance;
    this.loanClosingConditionService.changeClosingConditionLevel(this.closingConditionData?.documentId, committeeClearance)
      .then(() => successCallback())
      .catch(() => {
        this.globalStore.notificationStore.showErrorNotification({
          message: GENERIC_ERROR_MESSAGE
        });
      })
  }
}

export default LoanClosingConditionStore;