import { Page } from '@roc/feature-app-core';
import { Route } from 'react-router';
import { AppraisalReview } from '../appraisalReview/appraisalReview';
import { useAppraisalReviewStore } from '../hooks';


export const getAppraisalReviewReportRoutesConfig = (
  basePath: string, 
  baseUrl: string,
) => {
  return {
    appraisalReviewReport: {
      path: `${basePath}/appraisal-review-report`,
      url: `${baseUrl}/appraisal-review-report`,
      documentTitle: 'appraisal-review-report',
    },
  };
};

export const getAppraisalReviewReportRoutes = (
  basePath: string,
  baseUrl: string,
  loanId: any,
  propertyId: any,
) => {
  const config = getAppraisalReviewReportRoutesConfig(basePath, baseUrl);
  const { appraisalReviewStore } = useAppraisalReviewStore();

  return {
    appraisalReviewReport: (
      <Route exact path={config.appraisalReviewReport.path}>
        <Page routeDefinition={config.appraisalReviewReport}>
          <AppraisalReview
            loanId={loanId}
            propertyId={propertyId}
            store={appraisalReviewStore}
          />
        </Page>
      </Route>
    ),
  };
}