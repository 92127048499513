import { useEffect, useState } from 'react';
import {
  Typography,
  Grid,
  Box,
  Button
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { CloudUpload, Close } from '@material-ui/icons';
import { useDropzone } from 'react-dropzone';
import { FileUpload } from '@roc/ui';
import CloudCircleIcon from '@material-ui/icons/CloudCircle';
import { ProspectPropertyFormStore } from '../stores/prospectPropertyFormStore';


const useStyles = makeStyles(() => ({
  button: {
    minWidth: '10vw',
  },
  removeButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    cursor: 'pointer'
  },
  top: {
    display: 'flex',
    padding: '10px',
    borderTop: '1px solid black',
    borderLeft: '1px solid black',
    borderRight: '1px solid black',
    borderTopLeftRadius: '1rem',
    borderTopRightRadius: '1rem',
  },
  dragArea: {
    border: '3px dotted #BEBEBE',
    width: '100%',
    minHeight: '20vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: 20,
    marginBottom: 10,
    borderRadius: 5,
    cursor: 'pointer',
  },
  icon: {
    minWidth: '20px',
    minHeight: '20px',
    width: '3vw',
    height: '3vw',
  },
  fileContainer: {
    border: '1px solid grey',
    backgroundColor: '#EAF4FA',
    padding: '10px 20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '10px 0',
    borderRadius: 5,
  },
  fileName: {
    marginLeft: 5,
    marginRight: 2,
    flexGrow: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

interface ImageUploadProps {
  store: ProspectPropertyFormStore;
}

export const ImageUploadPreview = (props: ImageUploadProps) => {
  const [render, setRender] = useState(false);

  const onDrop = (acceptedFiles: File[]) => {
    const newItems = acceptedFiles.map(file => ({
      name: file.name,
      file: file,
    }));
    props.store.images = [...props.store.images, ...newItems];
    setRender(!render);
  };

  const onRemove = (index: number) => {
    const newFiles = [...props.store.images];
    props.store.images.splice(index, 1);
    setRender(!render);
    return newFiles;
  };

  const classes = useStyles();
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: true,
    maxFiles: 3,
    accept: ['.png', '.jpeg', '.jpg', '.webp']
  });
  return (
    <Grid>
      <Box className={classes.top}>
        <CloudCircleIcon fontSize='large' style={{ color: '#5A5A5A' }} />
        <Typography style={{ fontSize: '25px' }}>Image Upload</Typography>
      </Box>
      <Box {...getRootProps()} className={classes.dragArea}>
        <input {...getInputProps()} />
        <CloudUpload className={classes.icon} />
        <>
          <p>Drag and drop here or Browse</p>
        </>
      </Box>
      <Grid style={{ display: 'flex', width: '100%', flexWrap: 'wrap' }}>
        {props.store.images.map((item, i) => {
          return (
            <>
              <Box width={'25%'} position={'relative'} padding={'2px'}>
                <Close className={classes.removeButton} onClick={() => onRemove(i)} />
                <img src={URL.createObjectURL(item.file)} width={'100%'} />
              </Box>
            </>
          )
        })}
      </Grid>
    </Grid>
  )
}