import { Box, Typography } from "@material-ui/core";
import { IconButton, Tooltip } from "@roc/ui";
import { OpenInNew } from '@material-ui/icons';

export const ForeclosureRedirectLink = ({ loanId }) => {

  return (
    <Box display='flex' alignItems='center'>
      <IconButton
        testId="loan-breakdown-icon-btn"
        onClick={e => {
          open('/nportal/#/loan_information/' + loanId);
          e.preventDefault();
        }}>
        <Tooltip
          title={
            <Typography variant="subtitle1">
              Open Loan
            </Typography>
          }
          placement="left"
          arrow
        >
          <OpenInNew color="primary" />
        </Tooltip>
      </IconButton>
    </Box >
  )
}