import { Box, Container, Grid, Paper, TextField, Typography } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { observer } from "mobx-react"
import { useState } from "react";
import { ConfirmationButton, MultiSelectField, SelectField } from "@roc/ui";
import { useSendEmailsStore } from "../../emails";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textBold: {
      fontWeight: 700,
    },
    table: {
      '& table tr:last-child td': {
        borderBottom: 'none',
      },
    },
    paper: {
      padding: theme.spacing(4),
      minHeight: "600px",
      boxShadow:
        'rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px',

    },
    tableRow: {
      minHeigh: '12vh',
    },
    sendButton: {
      marginTop: '250px',

    },
  })
);

const audienceTypes = [
  {
    value: 'CorporateBorrowerLead',
    label: 'Corporate Borrower Lead',
  },
  {
    value: 'CorporateTPOLead',
    label: 'Corporate TPO Lead',
  },
  {
    value: 'CorporateLenderPrincipalContact',
    label: 'Corporate Lender Principal Contact',
  },
  {
    value: 'CorporateBorrowerContact',
    label: 'Corporate Borrower Contact',
  },
];

const brandOptions = [
  {
    value: 'roc',
    label: 'Roc',
  },
  {
    value: 'faco',
    label: 'Faco',
  },
  {
    value: 'civic',
    label: 'Civic',
  }
];

export const SendCorporateEmails = observer(() => {
  const { sendEmailsStore } = useSendEmailsStore();
  const [selectedBrand, setSelectedBrand] = useState('');
  const [selectedAudiences, setSelectedAudiences] = useState([]);
  const { paper } = useStyles();
  const classes = useStyles();

  const handleAudienceChange = (e, value) => {
    setSelectedAudiences(value);
  };

  const handleBrandChange = (value) => {
    if (value === 'none') {
      setSelectedBrand('');
    } else {
      setSelectedBrand(value);
    }

  };

  return (
    <>
      <Container maxWidth={'md'}>
        <Grid item xs={12} md={12}>
          <Box pt={2} pb={2}>
            <Typography variant="h4">
              Send corporate emails
            </Typography>
          </Box>
        </Grid>
        <Paper className={paper} >
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <Box pt={2}>
                <Typography>
                  Please select a brand and select one or multiple audiences (to whom the email will be sent).
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} spacing={4}>
              <SelectField
                testId="selectedBrand"
                options={brandOptions}
                label={'Choose brand'}
                value={selectedBrand}
                onChange={(value) => {
                  handleBrandChange(value);

                }}
                fullWidth
                variant={'outlined'}
              />
            </Grid>
            <Grid item xs={12} spacing={4}>
              <MultiSelectField
                name={'selectedAudience'}
                options={audienceTypes}
                getOptionSelected={(option, selectedValue) => {
                  return option.value == selectedValue.value;
                }}
                value={selectedAudiences}
                onChange={handleAudienceChange}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label="Select audience" />
                )}
                fullWidth
                required
                testId="selectedAudiences"
              />
            </Grid>
            <Grid item xs={9}></Grid>
            <Grid item xs={3} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
              <ConfirmationButton
                disabled={!(selectedBrand && selectedAudiences.length != 0)}
                variant='outlined'
                color='primary'
                onClick={() => {
                  sendEmailsStore.sendCorporateEmails(selectedAudiences, selectedBrand)
                }}
                testId="send-emails"
                confirmDialogProps={{
                  title: 'Send corporate emails',
                  body: `Are you sure you want to send emails to selected audience?`,
                }}
                fullWidth
                className={classes.sendButton}
              >Send</ConfirmationButton>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </>
  );
});
