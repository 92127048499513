import { Box, Drawer, Fab, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { Menu } from '@material-ui/icons';

const drawerWidth = 300;

const useStyles = makeStyles((theme: Theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    zIndex: theme.zIndex.appBar - 1,
  },
}));

export const DrawerMenu = ({ open, onOpen, onClose, children }) => {
  const classes = useStyles();

  return (
    <>
      <Hidden mdUp implementation="css">
        <Fab
          size="small"
          color="primary"
          aria-label="menu"
          onClick={onOpen}
          style={{ position: 'absolute', margin: '8px 0px 0px 8px' }}
        >
          <Menu />
        </Fab>
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="temporary"
          anchor={'left'}
          open={open}
          onClose={onClose}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {children}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          className={classes.drawer}
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
        >
          {children}
        </Drawer>
      </Hidden>
    </>
  );
};
