import { ApiResponse, GlobalStore, GridStore } from '@roc/feature-app-core';
import { MyFundedLoansForDrawsGridStore } from '@roc/feature-borrower-draws';
import { LoanBaseStore } from '@roc/feature-loans-shared';
import { FundedLoansForPayoffGridStore } from '@roc/feature-servicer-loans';
import { GENERIC_ERROR_MESSAGE, getPageFromObject } from '@roc/feature-utils';
import { action, flow, makeObservable, observable, override } from 'mobx';
import { BorrowerLoanDetailsService } from '../modules/borrowerLoanDetails/services/borrowerLoanDetailsService';
import { BorrowerLoansGridStore } from '../modules/borrowerLoans/stores/borrowerLoansGridStore';
import { FundedLoansForExtensionsGridStore } from '@roc/feature-extensions';
import { BorrowerLoanTasksStore } from './borrowerLoanTasksStore';
import { BorrowerPipelineLoansGridStore } from '../modules/borrowerLoans/stores/borrowerPipelineLoansGridStore';
import { BorrowerLoansService } from '../modules/borrowerLoans';
import { BorrowerLoansSummary, BorrowerLoansTodosSummary } from '@roc/feature-types';
import { BorrowerLoanApplicationsGridStore } from '../modules/borrowerLoans/stores/borrowerLoanApplicationsGridStore';

export class BorrowerLoansStore extends LoanBaseStore {
  fundedLoansForPayoffGridStore: GridStore;
  fundedLoansForExtensionsGridStore: GridStore;
  borrowerLoansGridStore: BorrowerLoansGridStore;
  borrowerPipelineLoansGridStore: BorrowerPipelineLoansGridStore;
  borrowerLoanApplicationsGridStore: BorrowerLoanApplicationsGridStore;
  private borrowerLoanDetailsService: BorrowerLoanDetailsService;
  borrowerLoanTasksStore: BorrowerLoanTasksStore;
  borrowerLoanService: BorrowerLoansService;
  isLoanDetailsSuccess: boolean;

  myFundedLoansForDrawsGridStore: GridStore;
  propertiesGridStore: GridStore;

  loanDetails;
  borrowerLoansSummary: BorrowerLoansSummary;
  borrowerLoansTodosSummaries: BorrowerLoansTodosSummary[];

  constructor(globalStore: GlobalStore) {
    super(globalStore);
    this.borrowerLoanService = new BorrowerLoansService();
    this.borrowerLoanDetailsService = new BorrowerLoanDetailsService();
    this.borrowerLoansGridStore = new BorrowerLoansGridStore(globalStore);
    this.borrowerPipelineLoansGridStore = new BorrowerPipelineLoansGridStore(globalStore);
    this.borrowerLoanApplicationsGridStore = new BorrowerLoanApplicationsGridStore(globalStore);
    this.myFundedLoansForDrawsGridStore = new MyFundedLoansForDrawsGridStore(
      globalStore
    );
    this.fundedLoansForPayoffGridStore = new FundedLoansForPayoffGridStore(
      globalStore
    );
    this.fundedLoansForExtensionsGridStore = new FundedLoansForExtensionsGridStore(
      globalStore
    );
    this.propertiesGridStore = new GridStore(() =>
      this.fetchPropertiesGridData()
    );
    this.borrowerLoanTasksStore = new BorrowerLoanTasksStore(globalStore);

    makeObservable(this, {
      loanDetails: override,
      borrowerLoansSummary: observable,
      borrowerLoansTodosSummaries: observable,
      isLoanDetailsSuccess: observable,
      fetchLoanDetails: flow,
      fetchLoansSummary: flow,
      fetchLoansTodosSummary: action,
      updateLoanTodosSummary: action,
      setLoanDetailsSuccess: action,
    });
  }

  *fetchLoanDetails(loanId) {
    try {
      this.loanDetails = null;
      const response = yield this.borrowerLoanDetailsService.getBorrowerLoan(
        loanId
      );
      this.loanDetails = response.data.data;
      this.propertiesGridStore.resetAndFetchGridData();
      this.setLoanDetailsSuccess(true);
    } catch (e) {
      this.setLoanDetailsSuccess(false);
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE
      });
    }
  }

  *fetchLoansSummary() {
    try {
      const response = yield this.borrowerLoanService.getBorrowerLoansSummary();
      this.borrowerLoansSummary = response.data.data;
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE
      });
    }
  }

  async fetchLoansTodosSummary() {
    try {
      const response = await this.borrowerLoanService.getBorrowerLoansTodosSummary();
      this.borrowerLoansTodosSummaries = response.data?.data ?? [];
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE
      });
    }
  }

  async updateLoanTodosSummary(loanId: number, underwritingPendingTodosCount: number) {
    const updated: BorrowerLoansTodosSummary[] = [];
    this.borrowerLoansTodosSummaries.forEach((x) => {
      if (x.loanId == loanId) {
        x.underwritingPendingTodosCount = underwritingPendingTodosCount;
      }
      updated.push(x);
    });
    this.borrowerLoansTodosSummaries = updated;
  }

  private async fetchPropertiesGridData(): Promise<ApiResponse> {
    const properties = this.loanDetails?.properties || [];
    const { meta } = this.propertiesGridStore.gridData;
    const data = getPageFromObject(properties, meta);
    return Promise.resolve(new ApiResponse({ data }, null));
  }

  setLoanDetailsSuccess(value: boolean) {
    this.isLoanDetailsSuccess = value;
  }
}
