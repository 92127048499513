import { Box, Grid } from '@material-ui/core';
import { observer } from 'mobx-react';
import { EntityCard } from '../cards/entityCard';
import { TermPropertiesGridCard } from '../cards/propertiesGridCard';
import { LoanDetailsPaper } from '../loanDetailsPaper';
import { LoanStatusStepper } from '../loanDetailsStepper';
import { LoanApplicationDetailsCard } from '../bridge/loanApplicationDetailsCard';

export const LoanApplicationSummaryTerm = observer(() => {

  return (
    <>
      <Grid item xs={12}>
        <LoanDetailsPaper>
          <LoanStatusStepper />
        </LoanDetailsPaper>
      </Grid>
      <Grid item xs={12}>
        <LoanApplicationDetailsCard />
      </Grid>
      <Grid item xs={12}>
        <EntityCard />
      </Grid>
      <Grid item xs={12}>
        <TermPropertiesGridCard />
      </Grid>
    </>
  );
});
