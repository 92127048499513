import React, { useEffect, useState } from 'react';
import { Grid, Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { CloudDownload } from '@material-ui/icons';

import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';

import {
  SelectField,
  Button,
  LayoutCssClasses,
  Card,
  yesNoOptions,
  Slider,
  Tooltip,
} from '@roc/ui';
import { Paper } from '@material-ui/core';
import { formatCurrency } from '@roc/ui/utils';
import { observer } from 'mobx-react';
import { SinglePropertyStore, RentalPortfolioStore, TermStore } from './../stores';
import {
  insertIf,
  loanTermRateTermMapping,
  PPP_5_4_3_2_1,
  sfrLoanTermTypes,
} from '@roc/feature-utils';
import { CashOut } from './formComponents';
import {
  amortizationTypeTooltip,
  amortizationOptions,
} from '../../rentalPricer/utils/constants';

import { AvailableLoanRatesTable, LoanRatesWarnings, RateAndRateLockTerms, TargetLtvErrors } from '../../rentalPricer/pricerSummary/components/availableLoanRatesTable';
import { ProgramOptionsTable } from '../../rentalPricer/pricerSummary/components/programOptionsTable';
import { useBaseStore, useHeaderHeight, useUserStore } from '@roc/feature-app-core';
import { DSCR_EXPANDED } from '../stores/common/pricerSummaryHelper';
import { loanSubtypeOptions } from 'libs/client-portal-shared/src/app/modules/dscrCalculator/utils/dscrCalculatorConstants';
import { getLoanSubtype } from 'libs/client-portal-shared/src/app/modules/dscrCalculator/utils/dscrCalculatorUtils';
import { DownloadableLoanRatesDialog } from '../../rentalPricer/pricerSummary/components/downloadableLoanRatesDialog';
import { ComparePricingOptions } from '../../rentalPricer/pricerSummary/components/comparePricingOptions';
import { LoanRatesTable } from '../../rentalPricer/pricerSummary/components/loanRatesTable';
import { DownloadableQuotesDialog } from '../../rentalPricer/pricerSummary/components/downloadableQuoteDialog';
import { PricingEconomics } from '../../rentalPricer/pricerSummary/components/pricingEconomics';
import { Alert } from '@material-ui/lab';
import { PricerEconomicsSummaryTable } from '../../rentalPricer/pricerSummary/components/pricerEconomicsSummaryTable';

interface PricerSummaryTermProps {
  store: SinglePropertyStore | RentalPortfolioStore;
}

const useStyles = makeStyles(theme =>
  createStyles({
    table: {
      minWidth: 650,
      minHeight: 325,
    },
    slider: {
      marginTop: '2.5em',
      marginLeft: '2em',
      width: '85%',
    },
    errorContainer: {
      color: theme.palette.error.main,
    },
    selectedRate: {
      color: theme.palette.primary.main,
    },
  })
);

const listItem = (label, value, value2 = null) => {
  return (
    <ListItem>
      <ListItemText>
        <Box display="flex">
          <Box flexGrow={1}>{label}</Box>
          <span />
          {!value2 && (
            <Typography variant="body1">
              <strong>{value}</strong>
            </Typography>
          )}
          {value2 && (
            <Grid spacing={2} style={{ textAlign: 'right' }}>
              <Typography variant="body1">
                <strong>{value}</strong>
              </Typography>
              <Typography variant="body1">
                <strong>{value2}</strong>
              </Typography>
            </Grid>
          )}
        </Box>
      </ListItemText>
    </ListItem>
  );
};

const divider = <Divider variant="middle" component="li" />;

const LoanRatesErrors = observer(({ store }) => {
  const classes = useStyles();
  const { serverExceptions } = store.pricerSummaryStore;

  return (
    <Grid className={classes.errorContainer}>
      <Typography variant="body1">
        <strong>
          Currently Rates are not avaliable for the given loan scenario, Please
          proceed and Submit Loan Application. Our underwriting team will get
          back to you.
        </strong>
      </Typography>
      <br />
      <Typography variant="body1">
        {serverExceptions &&
          serverExceptions.length > 0 &&
          serverExceptions.map(exception => (
            <li key={exception}>
              <strong>{exception}</strong>
            </li>
          ))}
      </Typography>
    </Grid>
  );
});

export const RateProgramType = observer(({ store }) => {
  const classes = useStyles();
  const { pricerLoanTerms, form } = store.pricerSummaryStore;
  const { userStore } = useUserStore();
  const allowLoanPricing = userStore.allowLoanPricing;

  const handleChangeYourLoanTerm = (name, value) => {
    store.pricerSummaryStore.onFieldChange(name, value);
    store.pricerSummaryStore.setMinsAndMaxs();
    store.pricerSummaryStore.fetchRatesAndLoanTerms();
    store.pricerSummaryStore.setErrorMessages();
  };

  const setRateTypeOptionsBasedOnProgramType = () => {
    if (form.fields.programType.value === DSCR_EXPANDED) {
      return store.pricerSummaryStore.rateTypeOptions;
    }
    else {
      return store.pricerSummaryStore.rateTypeOptionsStandard;
    }
  }

  return (
    <Grid container
      xs={12}
      md={12}
      lg={12} direction="row">
      <Grid item xs={12} md={6} lg={6} style={{ paddingRight: '10px' }}>
        <SelectField
          disabled={false}
          standaloneLabel
          label={'Rate Type'}
          name={'rateType'}
          value={form.fields.rateType.value}
          options={setRateTypeOptionsBasedOnProgramType()}
          onChange={value => handleChangeYourLoanTerm('rateType', value)}
          variant="outlined"
          fullWidth
          testId="rateType"
        />
      </Grid>

      <Grid item xs={12} md={6} lg={6} style={{ paddingLeft: '10px' }}>
        <SelectField
          disabled={false}
          standaloneLabel
          label={'Program Type'}
          name={'programType'}
          value={form.fields.programType.value}
          options={store.pricerSummaryStore.programTypeOptions}
          onChange={value => handleChangeYourLoanTerm('programType', value)}
          variant="outlined"
          fullWidth
          testId="programType"
        />
      </Grid>

      <br />
    </Grid>
  );
})

const LoanTermsResult = observer(({ store }: { store: TermStore }) => {
  const classes = useStyles();
  const { pricerLoanTerms, form } = store.pricerSummaryStore;
  const { userStore } = useUserStore();
  const allowLoanPricing = userStore.allowLoanPricing;
  const { dscrCalculatorStore } = store;
  return (
    <Grid
      container
      xs={12}
      md={6}
      lg={6}
      style={{
        width: '100%',
        border: 'solid 3px',
        margin: '32px 0px 32px 0px',
        borderColor: '#f3bc14',
        borderRadius: '8px',
        padding: '15px',
      }}
    >
      <Paper variant="elevation" elevation={0} style={{ width: '100%' }}>
        <Typography variant="h6">
          <strong>Loan Pricer Summary Results</strong>
        </Typography>
        <br />
        <List>
          {listItem('Loan Amount', `${formatCurrency(pricerLoanTerms.amount)}`)}
          {divider}
          {listItem(
            'Spread Charged To borrower',
            `${pricerLoanTerms.spreadRate}%`
          )}
          {divider}
          {listItem(
            'Monthly Payment Including Expenses',
            `${formatCurrency(pricerLoanTerms.monthlyPayment, 3)}`
          )}
          {divider}
          {allowLoanPricing &&
            listItem(
              'Yield Spread Premium',
              `${form.fields.oneTimeYieldSpreadPremium.value}% | ${formatCurrency(
                pricerLoanTerms.oneTimeYieldSpreadPremiumAmount
              )}`
            )}
          {divider}
          {(dscrCalculatorStore.calculatedValues?.fullyAmortizingDscrInIOPeriod) ?
            <>
              {listItem(
                'Fully Amortizing PITIA DSCR',
                dscrCalculatorStore.calculatedValues?.fullyAmortizingDscrInIOPeriod)}
            </>
            :
            <>
              {listItem(
                'NCF Fully Amortizing DSCR',
                dscrCalculatorStore.calculatedValues?.ncfFullyAmortizingDscr
              )}
            </>
          }
        </List>
      </Paper>
    </Grid>
  );
});

const PricerSummaryTerm = ({ store }: PricerSummaryTermProps) => {
  const classes = useStyles();

  const { pricerSummaryStore, pricerStore, globalStore } = store;
  const {
    form,
    overRideRate,
    currentMinLtv,
    currentMaxLtv,
    rateTermLTV,
    showRateNotFoundErrorMsg,
    loanStandardRatesRows
  } = pricerSummaryStore;
  const { maxYsp } = pricerSummaryStore.pricerLoanTerms;
  const { isSingleProperty } = pricerStore;
  const { anyDebt, totalDebtPayoff } = pricerStore.form.fields;
  const { userStore } = useUserStore();
  const allowLoanPricing = userStore.allowLoanPricing;
  const [showLoanRatesDialog, setShowLoanRatesDialog] = useState(false);
  const { pricerFeesEnabled } = globalStore.userFeatures;
  const headerHeight = useHeaderHeight();
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));

  async function fetchDSCRRates() {
    await pricerSummaryStore.fetchDSCRRates();
  }

  useEffect(() => {
    fetchDSCRRates();
  }, [pricerSummaryStore.rateForDSCR])

  useEffect(() => {
    pricerSummaryStore.setPrePaymentPenaltyToDefault();
    pricerSummaryStore.fetchRatesAndLoanTerms();
  }, []);

  const handleOnChangeAmortization = value => {
    pricerSummaryStore.onFieldChange('amortization', value);
    pricerSummaryStore.onFieldChange('oneTimeYieldSpreadPremium', 0);
    pricerSummaryStore.setMaxYsp(value);
    pricerSummaryStore.fetchRatesAndLoanTerms();
    pricerSummaryStore.setErrorMessages();
    pricerSummaryStore.unselectQuoteOption();
  };

  const handleChangePrePaymentPenalty = value => {
    pricerSummaryStore.onFieldChange('prePaymentPenalty', value);
    pricerSummaryStore.onFieldChange('oneTimeYieldSpreadPremium', 0);
    pricerSummaryStore.fetchRatesAndLoanTerms();
    pricerSummaryStore.setErrorMessages();
    pricerSummaryStore.unselectQuoteOption();
  };

  const handleOnChangeYsp = value => {
    pricerSummaryStore.onFieldChange('oneTimeYieldSpreadPremium', value);
    pricerSummaryStore.fetchRatesAndLoanTerms();
    pricerSummaryStore.setErrorMessages();
    pricerSummaryStore.unselectQuoteOption();
  };

  const handleChangeLoanTerm = value => {
    pricerSummaryStore.setLoanTerm(value);
    pricerSummaryStore.onFieldChange(
      'rateType',
      loanTermRateTermMapping[value].default
    );
    pricerSummaryStore.onFieldChange('prePaymentPenalty', PPP_5_4_3_2_1);
    pricerSummaryStore.onFieldChange('oneTimeYieldSpreadPremium', 0);
    pricerSummaryStore.fetchRatesAndLoanTerms();
    pricerSummaryStore.setErrorMessages();
    pricerSummaryStore.unselectQuoteOption();
  };

  const handleChangeCashOut = () => {
    pricerSummaryStore.onFieldChange('oneTimeYieldSpreadPremium', 0);
    pricerSummaryStore.fetchRatesAndLoanTerms();
    pricerSummaryStore.setErrorMessages();
  };

  const handleChangeYourLoanTerm = (name, value) => {
    pricerSummaryStore.onFieldChange(name, value);
    pricerSummaryStore.setMinsAndMaxs();
    pricerSummaryStore.fetchRatesAndLoanTerms();
    pricerSummaryStore.setErrorMessages();
    pricerSummaryStore.unselectQuoteOption();
  };

  const handleChangeRateBuydown = value => {
    pricerSummaryStore.onFieldChange('rateBuydown', value);
    pricerSummaryStore.fetchRatesAndLoanTerms();
    pricerSummaryStore.setErrorMessages();
    pricerSummaryStore.unselectQuoteOption();
  };

  const buildLoanToValueMarks = () => {
    return [
      {
        value: currentMinLtv,
        label: `${currentMinLtv}%`,
      },
      ...insertIf(currentMinLtv !== currentMaxLtv, [
        {
          value: currentMaxLtv,
          label: `${currentMaxLtv}%`,
        }
      ]),
    ];
  };

  const buildYieldSpreadPremiumMarks = () => {
    return [
      {
        value: 0,
        label: '0%',
      },
      {
        value: maxYsp,
        label: `${maxYsp}%`,
      },
    ];
  };

  const buildBuydownOptions = () => {
    let buydownList = [];
    const maxValue = ((pricerSummaryStore.maxAllowedBuydown > 0) ? pricerSummaryStore.maxAllowedBuydown * 100 : pricerSummaryStore.maxAllowedBuydownStandard * 100);
    const maxValueRatio = pricerSummaryStore.buydownRatio ? pricerSummaryStore.buydownRatio : pricerSummaryStore.buydownRatioStandard;
    for (
      let buydown = 0;
      buydown <= maxValue;
      buydown++
    ) {
      let buydownPoints = (
        buydown /
        maxValueRatio /
        100
      ).toFixed(3);
      let buydownDecimal = buydown / 100;
      buydownList.push({
        value: buydownDecimal,
        label: `${buydownDecimal}% (${buydownPoints} pts)`,
      });
    }
    return buydownList;
  };

  const showRatesTable = (!showRateNotFoundErrorMsg && !overRideRate) ||
    loanStandardRatesRows;

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} style={{
        textAlign: 'center',
        marginBottom: 32
      }}>
        <Typography variant="h4">Loan Pricer Summary</Typography>
        <Typography variant="body1">
          Please choose the loan terms. They will affect the loan rates below
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <Grid container spacing={2} style={{
          maxWidth: 500,
          margin: '0 auto',
          padding: '0 16px',
        }}>
          <Grid item xs={12}>
            <Typography color="secondary" variant="h6">
              Loan Terms
            </Typography>
          </Grid>
          {anyDebt.value === yesNoOptions[0].value &&
            totalDebtPayoff.value >= 0 && (
              <Grid item xs={12}>
                <CashOut
                  store={pricerSummaryStore}
                  onChange={handleChangeCashOut}
                />
              </Grid>
            )}
          {!isSingleProperty && (
            <>
              <Grid item xs={12}>
                <SelectField
                  disabled={false}
                  standaloneLabel
                  label={'Loan Term'}
                  name={'loanTerm'}
                  value={form.fields.loanTerm.value}
                  options={sfrLoanTermTypes}
                  onChange={value => handleChangeLoanTerm(value)}
                  variant="outlined"
                  fullWidth
                  testId="loanTerm"
                />
              </Grid>
            </>
          )}

          <Grid item xs={12}>
            <SelectField
              disabled={false}
              standaloneLabel
              label={'Amortization Type'}
              name={'amortization'}
              tooltip={amortizationTypeTooltip}
              value={form.fields.amortization.value}
              options={amortizationOptions}
              onChange={value => handleOnChangeAmortization(value)}
              variant="outlined"
              fullWidth
              testId="amortization"
            />
          </Grid>

          <Grid item xs={12}>
            <SelectField
              disabled={false}
              standaloneLabel
              label={'Pre-Payment Penalty'}
              name={'prePaymentPenalty'}
              value={form.fields.prePaymentPenalty.value}
              options={pricerSummaryStore.prepaymentPenaltyOptions}
              onChange={value => handleChangePrePaymentPenalty(value)}
              variant="outlined"
              fullWidth
              testId="prePaymentPenalty"
            />
          </Grid>
          {!pricerFeesEnabled && allowLoanPricing && (
            <Grid item xs={12}>
              <SelectField
                disabled={false}
                standaloneLabel
                label={'Rate Buydown'}
                name={'rateBuydown'}
                value={form.fields.rateBuydown.value}
                options={buildBuydownOptions()}
                onChange={value => handleChangeRateBuydown(value)}
                variant="outlined"
                fullWidth
                testId="rateBuydown"
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <Slider
              testId="loanToValueSlider"
              standaloneLabel
              label="Loan To Value"
              disabled={false}
              onChangeCommitted={(event, value) =>
                handleChangeYourLoanTerm('loanToValue', value)
              }
              className={classes.slider}
              value={form.fields.loanToValue.value}
              valueLabelDisplay="on"
              marks={buildLoanToValueMarks()}
              step={5}
              min={currentMinLtv}
              max={currentMaxLtv}
            />
          </Grid>

          {!showRateNotFoundErrorMsg && allowLoanPricing && (
            <Grid item xs={12}>
              <Slider
                testId="oneTimeYieldSpreadPremiumSlider"
                standaloneLabel
                label="One Time Yield Spread Premium"
                disabled={false}
                onChangeCommitted={(event, newValue) => {
                  if (
                    form.fields.oneTimeYieldSpreadPremium.value !== newValue
                  ) {
                    handleOnChangeYsp(newValue);
                  }
                }}
                className={classes.slider}
                value={form.fields.oneTimeYieldSpreadPremium.value}
                valueLabelDisplay="on"
                marks={buildYieldSpreadPremiumMarks()}
                step={0.05}
                min={0}
                max={maxYsp}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <Box>
              <Alert severity="info">
                Rates displayed are indicative gross rates based on today's index value and spread. The spread over index is locked at the time of submission and gross rates are subject to change based on the index value
              </Alert>
            </Box>
          </Grid>
          {pricerFeesEnabled && allowLoanPricing && (
            <>
              <Grid item xs={12}>
                <div style={{
                  marginTop: 16,
                }}>
                  <Typography color="secondary" variant="h6" display="inline">
                    Points & Fees
                  </Typography>
                  <Typography variant="body1">
                    Quick Tip: To get lower rates for your deal, you can enter rate buydown points and fees in the Rate Buydown column. Increasing buydown fees will lower rates until you hit the rate floor. Add-on Points and Fees, on the other hand, do not have an impact on rates.
                  </Typography>
                  {((pricerSummaryStore?.lenderFeesFormStore?.totalRocFees ?? 0) > (pricerSummaryStore?.targetRateBuydown ?? 0)) && (
                    <>
                      <br />
                      <Alert severity="warning">
                        Heads up! You exceeded the total rate buydown available. Any additional rate buydown fees and points added will not lower rates.
                      </Alert>
                    </>
                  )}
                  <br />
                  <Alert severity={((pricerSummaryStore?.lenderFeesFormStore?.totalRocFees ?? 0) > (pricerSummaryStore?.targetRateBuydown ?? 0)) ? "warning" : "info"} variant="outlined" icon={false}>
                    <strong>{`Rate Buydown Needed for the Lowest Rates: ${formatCurrency(pricerSummaryStore?.targetRateBuydown ?? 0)}`}</strong>
                    <br />
                    <strong>{`Total Rate Buydown Fees and Points Entered: ${formatCurrency(pricerSummaryStore?.lenderFeesFormStore?.totalRocFees ?? 0)}`}</strong>
                  </Alert>
                  <br />
                </div>
              </Grid>
              <Grid item xs={12}>
                <PricingEconomics store={store} />
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
      {
        isLargeScreen && <Box width={0}>
          <Divider orientation="vertical" />
        </Box>
      }
      <Grid item xs={12} md={6} style={{
        margin: '0 auto'
      }}>
        <Grid container style={{
          position: 'sticky',
          top: headerHeight,
          maxWidth: 600,
          margin: '0 auto',
          padding: '0 16px 8px 16px',

        }}>
          <Grid container spacing={2} style={{
            padding: '0px 0px 16px 8px',
            margin: '16px -8px 0px -8px',
            background: '#eee',
            borderRadius: 8,
            border: '2px solid #ddd',
          }}>
            {showRatesTable ? (
              <>
                <Grid item xs={12}>
                  <Typography color="secondary" variant="h6">
                    Loan Rates
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <LoanRatesTable store={store} />
                  <TargetLtvErrors store={store} />
                </Grid>
                {/* <Grid item xs={12}>
                  <AvailableLoanRatesTable store={store} />
                </Grid> */}
              </>
            ) : (
              <Grid item xs={12} md={7}>
                <LoanRatesErrors store={store} />
              </Grid>
            )}
            {!!showRatesTable && (
              <>
                <Grid item xs={12}>
                  <Typography color="secondary" variant="h6" style={{
                    marginTop: 16,
                  }}>
                    Program Options
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <ProgramOptionsTable />
                </Grid>
                <Grid item xs={12}>
                  <RateAndRateLockTerms store={store} />
                  <LoanRatesWarnings store={store} />
                </Grid>
              </>
            )}
          </Grid>
          <Grid container spacing={2} style={{
            padding: '0px 0px 16px 8px',
            margin: '16px -8px 0px -8px',
            background: '#fff',
          }}>
            <Grid item xs={12}>
              <PricerEconomicsSummaryTable store={store} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} style={{
        textAlign: 'center',
        marginTop: 48
      }}>
        <Typography color="secondary" variant="h6" style={{
          paddingBottom: 8,
          fontSize: 32,
        }}>
          Compare Pricing Options
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          size="small"
          disabled={!store.pricerSummaryStore.pricingOptions.length}
          onClick={() => setShowLoanRatesDialog(true)}
          testId="downloadLoanRates"
          startIcon={<CloudDownload />}
        >
          DOWNLOAD QUOTE
        </Button>
      </Grid>
      <Grid item xs={12} style={{
        overflow: 'auto'
      }}>
        <ComparePricingOptions store={store} rateBuydownOptions={buildBuydownOptions()} pricerFeesEnabled={pricerFeesEnabled} />
      </Grid>
      {rateTermLTV > 0 && (
        <Grid item xs={12}>
          <Paper>
            <List>
              <ListItem>
                <ListItemText>
                  <Box display="flex">
                    <Box flexGrow={1}>
                      <strong>Estimated LTV Needed</strong>
                    </Box>
                    <Box>
                      <strong>{rateTermLTV.toFixed(2)}</strong>
                    </Box>
                  </Box>
                </ListItemText>
              </ListItem>
            </List>
          </Paper>
        </Grid>
      )}
      <DownloadableQuotesDialog store={store} open={showLoanRatesDialog} rateBuydownOptions={buildBuydownOptions()} onClose={() => setShowLoanRatesDialog(false)} />
      {/* <DownloadableLoanRatesDialog store={store} open={showLoanRatesDialog} onClose={() => setShowLoanRatesDialog(false)} /> */}
    </Grid>
  );
};

export default observer(PricerSummaryTerm);
