import { flow, makeObservable } from 'mobx';
import { GlobalStore } from '@roc/feature-app-core';
import { DocumentStore } from '@roc/feature-documents';
import { LoanStore } from '@roc/feature-loans';
import { FormStore } from '@roc/feature-app-core';
import { ExpectedClosingDateService } from '../../services/documentForms/expectedClosingDateService';
import { format, isValid } from 'date-fns';
import { shouldBeAbleToSubmitSpecificForm } from '../../utils/documentForms';
import { DocumentName } from '../../constants';
import { formatDate } from '@roc/feature-utils';

export const expectedClosingDateForm = {
  fields: {
    date: {
      value: null,
      error: null,
      rule: 'required',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export class ExpectedClosingDateStore extends FormStore {
  private documentStore: DocumentStore;
  globalStore: GlobalStore;
  loanStore: LoanStore;
  private expectedClosingDateService: ExpectedClosingDateService;

  constructor(
    documentStore: DocumentStore,
    loanStore: LoanStore,
    globalStore: GlobalStore
  ) {
    super({ ...expectedClosingDateForm }, globalStore);
    this.loanStore = loanStore;
    this.documentStore = documentStore;
    this.globalStore = globalStore;
    this.expectedClosingDateService = new ExpectedClosingDateService();

    makeObservable(this, {
      submitexpectedClosingDateState: flow,
      initialize: flow,
    });
  }

  *initialize() {
    this.reset();
    this.loadForm({
      date: this.loanStore.loanDetails.expectedClosingDate ?? null,
    });
  }

  get canEdit() {
    const entitlements = this.globalStore.userFeatures.closingEntitlements;
    return shouldBeAbleToSubmitSpecificForm(
      entitlements?.isSubmissionAllowed,
      entitlements?.isSubmissionNotAllowedOnly,
      entitlements?.isSubmissionAllowedOnly,
      DocumentName.EXPECTED_CLOSING_DATE
    );
  }

  shouldBeAbleToSubmitExpectedClosingDate() {
    return this.canEdit;
  }

  *submitexpectedClosingDateState() {
    try {
      const values = this.getFormValues();
      yield this.expectedClosingDateService.submitExpectedClosingDate(
        this.loanStore.loanDetails.loanId,
        values.date ? formatDate(values.date, 'yyyy-MM-dd') : null
      );
      console.log(this.loanStore.loanDetails.expectedClosingDate);
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Request made successful.',
      });
      this.documentStore.documentFormStore.closeCurrentForm();
      yield this.loanStore.refreshLoanDetails();
      yield this.documentStore.refetchDocuments();
    } catch (e) {
      console.log(e);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'An error occurred while submitting the form.',
      });
      this.documentStore.documentFormStore.closeCurrentForm();
    }
  }

  closeModal() {
    return this.documentStore.documentFormStore.setCurrentForm(null);
  }
}

export default ExpectedClosingDateStore;
