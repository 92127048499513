import { Service } from '@roc/feature-app-core';

const url = {
  GET_IDENTIFICATION_TYPE_DATA:
    '/nportal/rest/priv/identificationTypeData/getIdentificationTypeData',
  SAVE_IDENTIFICATION_TYPE_DATA: '/nportal/rest/priv/identificationTypeData/',
};

export class PersonalIdentificationService extends Service {
  getIdentificationTypeData(loanId, borrowerId) {
    return this.get(url.GET_IDENTIFICATION_TYPE_DATA, { loanId, borrowerId });
  }

  saveIdentificationTypeData(identificationTypeData) {
    return this.put(url.SAVE_IDENTIFICATION_TYPE_DATA, identificationTypeData);
  }
}
