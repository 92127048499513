import { Component, IHeaderParams } from 'ag-grid-community';

export class CheckboxHeaderComponent implements Partial<Component> {
  private eGui: HTMLElement | undefined;
  private wrapper: HTMLDivElement;
  private input: HTMLInputElement;
  private params: IHeaderParams;

  init(params: IHeaderParams) {
    (window as any)._x = this;
    this.params = params;

    this.eGui = document.createElement('div');
    this.eGui.innerHTML = `
      <div ref="eWrapper" class="ag-wrapper ag-input-wrapper ag-checkbox-input-wrapper" role="presentation">
          <input ref="eInput" class="ag-input-field-input ag-checkbox-input" type="checkbox" tabindex="-1">
      </div>
    `;
    this.wrapper = this.eGui.querySelector('.ag-checkbox-input-wrapper')
    this.input = this.eGui.querySelector('input')

    this.eGui.addEventListener('change', () => {
      this.setInputChecked(this.input.checked);
      this.setRowsSelectedState(this.input.checked)
    })

    this.params.api.addEventListener('rowSelected', ()=>{
      this.updateCheckboxValue();
    })
    this.params.api.addEventListener('rowValueChanged', ()=>{
      this.updateCheckboxValue();
    })
  }

  getGui() {
    return this.eGui;
  }

  refresh(params: IHeaderParams): boolean {
    return false;
  }

  updateCheckboxValue(){
    const allNodes = this.params.api.getRenderedNodes()
    const allNodesSelected = allNodes.every(node => node.isSelected())
    this.setInputChecked(allNodesSelected);
  }

  setInputChecked(checked){
    this.input.checked = checked;
    if(checked){
      this.wrapper.classList.add('ag-checked')
    } else {
      this.wrapper.classList.remove('ag-checked')
    }
  }


  setRowsSelectedState(selected){
    this.params.api.forEachNode(node => {
      if(node.data){
        node.setSelected(selected)
      }
    })
  }
}
