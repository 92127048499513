import { action, computed, flow, makeObservable, observable } from 'mobx';
import {
  GENERIC_ERROR_MESSAGE,
  isEmptyObject,
  isNumber,
} from '@roc/feature-utils';
import { GlobalStore } from '@roc/feature-app-core';
import { BorrowerEntityInformationSteps } from '../utils/constants';
import { BorrowerEntityFormStore } from './borrowerEntityFormStore';
import { BaseBorrowerInformationStore } from './baseBorrowerInformationStore';
import { NewLoanApplicationService } from 'libs/feature-borrower-loan-submission/src/modules/newLoanApplication/services/newLoanApplicationService';
import { mapEntityResponse } from '../utils/borrowerLoanSubmissionUtils';

export class BorrowerEntityInformationStore {
  public globalStore: GlobalStore;
  public mainEntity: any;
  public borrowerEntityInformationActiveStep: BorrowerEntityInformationSteps;
  public editBorrowerActiveIndex: number;
  public borrowerEntityFormStore: BorrowerEntityFormStore;
  public borrowerEntities: any[];

  private currentBorrowerId: number;
  private borrowerInformationStore: BaseBorrowerInformationStore;
  private newLoanApplicationService: NewLoanApplicationService;

  constructor(
    globalStore: GlobalStore,
    borrowerInformationStore: BaseBorrowerInformationStore
  ) {
    this.globalStore = globalStore;
    this.borrowerInformationStore = borrowerInformationStore;
    this.newLoanApplicationService = new NewLoanApplicationService();
    this.borrowerEntityFormStore = new BorrowerEntityFormStore(globalStore);
    this.setDefaults();

    makeObservable(this, {
      mainEntity: observable,
      borrowerEntityInformationActiveStep: observable,
      editBorrowerActiveIndex: observable,
      setDefaults: action,
      reset: action,
      borrowerEntitySubLabels: computed,
      handleBorrowerEntitySubLabelClick: action,
      borrowerEntities: observable,
      fetchBorrowerAndEntityData: flow,
      fetchBorrowerEntities: flow,
      fetchCurrentUserBorrower: flow,
      setSelectedBorrowerEntity: action,
      borrowerEntityOptions: computed,
    });
  }

  setDefaults() {
    this.mainEntity = {};
    this.borrowerEntityInformationActiveStep =
      BorrowerEntityInformationSteps.ENTITY_INFORMATION;
    this.editBorrowerActiveIndex = null;
  }

  reset() {
    this.setDefaults();
  }

  get borrowerEntitySubLabels() {
    if (!isEmptyObject(this.mainEntity)) {
      return [`${this.mainEntity.name}`];
    }
    return [];
  }

  handleBorrowerEntitySubLabelClick = index => {
    if (index === 0) {
      this.borrowerEntityInformationActiveStep =
        BorrowerEntityInformationSteps.EDIT_ENTITY_INFORMATION;
    } else {
      this.editBorrowerActiveIndex = index - 1;
      this.borrowerEntityInformationActiveStep =
        BorrowerEntityInformationSteps.EDIT_OWNERSHIP_INFORMATION;
    }
  };

  *fetchBorrowerAndEntityData() {
    try {
      if (this.globalStore.isBorrowerPortal) {
        yield this.fetchBorrowerEntities();
        yield this.fetchCurrentUserBorrower();
      }
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE,
      });
    }
  }

  *fetchBorrowerEntities() {
    const response = yield this.newLoanApplicationService.getAllEntities();
    this.borrowerEntities = mapEntityResponse(response.data.data.rows);
  }

  *fetchCurrentUserBorrower() {
    const response = yield this.newLoanApplicationService.getCurrentUserBorrower();
    const userBorrower = response.data.data ?? {};
    this.currentBorrowerId = userBorrower.borrowerId;
    const mainBorrower = {
      ...userBorrower,
      confirmEmailAddress: userBorrower?.emailAddress,
    };
    this.borrowerInformationStore.setBorrowers(
      mainBorrower,
      this.borrowerInformationStore.coBorrowers
    );
  }

  setSelectedBorrowerEntity(borrowerEntityId) {
    const selected = this.borrowerEntities.find(
      item => item.borrowerEntity.borrowerEntityId === borrowerEntityId
    );

    const borrowerEntity = selected?.borrowerEntity ?? {};
    const borrowers = selected?.borrowers ?? [];

    const mainBorrower = borrowers.find(
      borrower => borrower.borrowerId === this.currentBorrowerId
    );

    const coBorrowers = borrowers.filter(
      borrower => borrower.borrowerId !== this.currentBorrowerId
    );

    this.borrowerEntityFormStore.reset();
    this.borrowerInformationStore.reset();

    this.borrowerInformationStore.setBorrowers(mainBorrower ?? {}, coBorrowers);

    this.mainEntity = borrowerEntity;
    this.borrowerEntityFormStore.loadForm({
      borrowerEntityOption: {
        label: borrowerEntity.name,
        value: borrowerEntity.borrowerEntityId,
      },
      ...borrowerEntity,
    });
  }

  get borrowerEntityOptions() {
    return (this.borrowerEntities || [])
      .map(item => item.borrowerEntity)
      .map(entity => ({
        label: entity.name,
        value: entity.borrowerEntityId ?? 'temp',
      }));
  }
}
