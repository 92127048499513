import { useMediaQuery } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { formatDateIgnoreTimezone, numberWithCommas } from '@roc/feature-utils';
import { observer } from 'mobx-react';
import React from 'react';
import { WIRE_SENT_DRAW_RESOLVED } from '../../constants/draws';
import { Draw } from '../../types/draws';

const useTableStyles = makeStyles({
  table: {
    '& .MuiTableCell-root': {
      padding: '12px 16px',
    },
  },
});

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

type RowProps = {
  title: string,
  amount: string,
  allowExpand?: boolean,
  previousDraws?: Draw[],
  color?: string,
}

function Row(props: { row: RowProps }) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        {/* <TableCell style={{
          paddingLeft: 0,
          paddingRight: 0,
          textAlign: 'center',
        }}>
          {
            row.allowExpand && <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          }
        </TableCell> */}
        <TableCell component="th" scope="row" align="center" style={{
          paddingLeft: 0,
          paddingRight: 0
        }}>
          {
            row.color &&
            <Box display={'flex'} justifyContent={'center'}>
              <Box display={'inline-block'} height={20} width={20} bgcolor={row.color} />
            </Box>
          }
        </TableCell>
        <TableCell component="th" scope="row">
          {row.title}
        </TableCell>
        <TableCell align="right">
          <Typography variant="h6">
            {row.amount}
          </Typography>
        </TableCell>
      </TableRow>
      {
        row.allowExpand &&
        <TableRow>
          <TableCell style={{ padding: 0 }} colSpan={4}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Draw #</TableCell>
                      <TableCell>Wire Sent Date</TableCell>
                      <TableCell align="right">Wire Amount</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      row.previousDraws?.length == 0 &&
                      <TableRow>
                        <TableCell component="th" scope="row" colSpan={3}>
                          No Records.
                        </TableCell>
                      </TableRow>
                    }
                    {row.previousDraws?.map((row) => {
                      if (row.stage != WIRE_SENT_DRAW_RESOLVED) {
                        return null;
                      }
                      let date;
                      try {
                        date = formatDateIgnoreTimezone(row.wireSentDate);
                      } catch (e) {
                        date = row.wireSentDate;
                      }
                      return (
                        <TableRow key={row.drawNumber}>
                          <TableCell component="th" scope="row">
                            Draw {row.drawNumber}
                          </TableCell>
                          <TableCell>{date}</TableCell>
                          <TableCell align="right">${numberWithCommas(row.wireAmount ?? 0)}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      }
    </React.Fragment>
  );
}

export const LoanValuesTable = observer(({
  totalConstructionHoldback,
  constructionHoldbackReimbursed,
  constructionHoldbackReimbursedColor,
  constructionHoldbackRemaining,
  constructionHoldbackRemainingColor,
  draws
}: {
  totalConstructionHoldback: number,
  constructionHoldbackReimbursed: number,
  constructionHoldbackReimbursedColor: string,
  constructionHoldbackRemaining: number,
  constructionHoldbackRemainingColor: string,
  draws: Draw[]
}) => {
  const classes = useTableStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.sm));

  return (
    <TableContainer component={Paper} style={{
      border: '1px solid #eee'
    }}>
      <Table className={classes.table} size={isSmallScreen ? "small" : "medium"}>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            {/* <TableCell></TableCell> */}
            <TableCell>Detail</TableCell>
            <TableCell align="right">Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <Row key={'totalConstructionHoldback'} row={{
            title: 'Total Construction Holdback',
            amount: `$${numberWithCommas(totalConstructionHoldback ?? 0)}`
          }} />
          <Row key={'constructionHoldbackReimbursed'} row={{
            title: 'Construction Holdback Reimbursed to Date',
            amount: `$${numberWithCommas(constructionHoldbackReimbursed ?? 0)}`,
            color: constructionHoldbackReimbursedColor,
            allowExpand: false,
            previousDraws: draws,
          }} />
          <Row key={'constructionHoldbackRemaining'} row={{
            title: 'Construction Holdback Remaining',
            amount: `$${numberWithCommas(constructionHoldbackRemaining ?? 0)}`,
            color: constructionHoldbackRemainingColor,
          }} />
        </TableBody>
      </Table>
    </TableContainer>
  );
});
