import { observer } from 'mobx-react-lite';
import { ScopeOfWorkV2FormBaseStore } from '@roc/feature-sow-shared/stores/v2';
import {
  Grid, Button, Theme, Chip
} from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { NO_ACTION, SUBMITTED_FOR_REVIEW, CLARIFICATION_REQUESTED, SUBMITTED_FOR_REVIEW_MANUAL } from '@roc/feature-sow-shared/constants/scopeOfWorkForm';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      width: '100%'
    },
    statusChip: {
      width: '100%',
      marginTop: '3px',
    },
    reviewButton: {
      width: '100%',
      backgroundColor: '#FFC107',
      color: '#FFF',
      '&:hover': {
        background: '#B78103',
      },
    }
  })
);

const reviewStatusMapping = {
  [SUBMITTED_FOR_REVIEW_MANUAL]: SUBMITTED_FOR_REVIEW,
  [SUBMITTED_FOR_REVIEW]: SUBMITTED_FOR_REVIEW,
  [CLARIFICATION_REQUESTED]: CLARIFICATION_REQUESTED,
  [NO_ACTION]: NO_ACTION
};

interface Props {
  store: ScopeOfWorkV2FormBaseStore;
}

export const InternalReviewButtons = observer((props: Props) => {
  const classes = useStyles();
  const { store } = props;

  const updateRowsToNeedsAttentionStatus = (status: string) => {
    store.categoryStore.updateReviewStatusSelectedItems("Needs Attention");
    store.handleMarkAsInReview(status, null);
  }

  const updateRowsToApprovedStatus = () => {
    store.categoryStore.updateReviewStatusSelectedItems("Ok");
    if (!store.categoryStore.countItemsByReviewStatus("Needs Attention")) {
      store.handleMarkAsApproved();
    }
  }

  return (
    <>
      {((store.params?.isReviewAllowed || store.params?.isApprovalAllowed) && store?.activeStep === 3) &&
        <Grid item container xs={12} md={4} justifyContent='center'>
          <Chip
            className={classes.statusChip}
            label={reviewStatusMapping[store.params?.reviewStatus] ?? NO_ACTION}
            variant="outlined" />
        </Grid>
      }
      {(store?.activeStep === 3 && store.params.isReviewAllowed &&
        (store.params?.reviewStatus === NO_ACTION || store.params?.reviewStatus === CLARIFICATION_REQUESTED)) &&
        <Grid item container xs={12} md={4} justifyContent='center'>
          <Button
            variant="contained"
            className={classes.reviewButton}
            onClick={() => store.setOpenSubmitReviewDialog(true)}
          >
            Submit for Review
          </Button>
        </Grid>
      }
    </>
  );
});
