import { observer } from 'mobx-react';
import { BorrowerFormBridge } from './borrowerFormBridge';
import { BorrowerFormRental } from './borrowerFormRental';

export const BorrowerForm = observer(
  ({ store, mainBorrower = false, rental = false, readOnly = false }) => {
    if (rental) {
      return <BorrowerFormRental store={store} mainBorrower={mainBorrower} readOnly={readOnly} />;
    } else {
      return <BorrowerFormBridge store={store} readOnly={readOnly} />;
    }
  }
);
