import { Service } from '@roc/feature-app-core';
import { isLocalHost } from '@roc/feature-utils';
import { _getBaseStoreInstance } from 'libs/feature-app-core/src/stores/_baseStore';

const url = {
  GET_PORTAL_CONFIGURATION_BY_HOST:
    '/api/v1/loan/public/portalconfiguration/getPortalConfigurationByHost?host=',
  GET_PORTAL_CONFIGURATION_BY_ID:
    '/api/v1/loan/public/portalconfiguration/getPortalConfiguration?portalId=',
  SAVE_CHANGES: '/api/v1/loan/portalconfiguration/saveChanges',
  DISCARD_CHANGES: '/api/v1/loan/portalconfiguration/discardChanges?portalId=',
  PUBLISH_CHANGES: '/api/v1/loan/portalconfiguration/publishChanges?portalId=',
  UPLOAD_DOCUMENT: '/api/v1/document/upload',
  DOWNLOAD_DOCUMENT: '/api/v1/document/public/download/',
  SAVE_SUBDOMAIN:
    '/api/v1/loan/portalconfiguration/saveSubdomainAndMigrateUsers',
  HAS_WHITE_LABELED_CONFIG:
    '/api/v1/loan/portalconfiguration/hasWhiteLabledPortalConfig?companyId=',
};

export class PortalConfigurationService extends Service {
  getPortalConfigurationByHost(host) {
    return this.get(url.GET_PORTAL_CONFIGURATION_BY_HOST + host);
  }
  getPortalConfigurationById(portalId) {
    return this.get(url.GET_PORTAL_CONFIGURATION_BY_ID + portalId);
  }
  hasWhiteLabeledConfig(companyId) {
    return this.get(url.HAS_WHITE_LABELED_CONFIG + companyId);
  }
  saveChanges(data) {
    return this.post(url.SAVE_CHANGES, data);
  }
  saveSubDomain(data) {
    return this.post(url.SAVE_SUBDOMAIN, data);
  }
  discardChanges(portalId) {
    return this.post(url.DISCARD_CHANGES + portalId, {});
  }
  publishChanges(portalId) {
    return this.post(url.PUBLISH_CHANGES + portalId, {});
  }
  uploadDocument(file: File) {
    const formData = new FormData();
    formData.append(
      'documentMetadata',
      JSON.stringify({
        documentCollection: 'STATIC_IMAGES',
      })
    );
    formData.append('document', file);
    return this.post(url.UPLOAD_DOCUMENT, formData);
  }
  downloadDocument(id, lastUpdatedDate) {
    return this.get(
      url.DOWNLOAD_DOCUMENT + btoa(id),
      {
        lastUpdatedDate,
      },
      { responseType: 'blob' }
    );
  }
}
