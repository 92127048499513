import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { Layout } from '@roc/feature-app-core';
import { Tabs } from '@roc/ui';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { Videos } from './components';
import { useWhatsNewStore } from './hooks/useWhatsNewStore';

const useStyles = makeStyles((theme: Theme) => ({
  tabs: {
    marginBottom: theme.spacing(2),
  },
}));

export const WhatsNew = observer(() => {
  const { whatsNewStore } = useWhatsNewStore();
  const { showVideos, currentTab, tabs } = whatsNewStore;

  const classes = useStyles();

  const onTabChange = (e, index) => whatsNewStore.changeTab(index);

  useEffect(() => {
    whatsNewStore.reset();
    whatsNewStore.getVideos();
  }, [whatsNewStore]);

  return (
    <Layout title="What's New" maxWidth={'md'}>
      {showVideos && (
        <>
          <Tabs
            className={classes.tabs}
            tabs={tabs}
            selectedTab={currentTab}
            onTabChange={onTabChange}
          />
          <Videos />
        </>
      )}
    </Layout>
  );
});
