import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiSvgIcon-root': {
      color: theme.palette.secondary.main,
      backgroundColor: theme.palette.common.white,
    },
  },
  active: {
    '& .MuiSvgIcon-root': {
      color: theme.palette.primary.main,
    },
  },
  disabled: {
    '& .MuiSvgIcon-root': {
      color: 'gray',
    },
  },
  completed: {
    '& .MuiSvgIcon-root': {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.common.white,
    },
  },
}));

export interface StepIconProps {
  active: boolean;
  completed: boolean;
  children: JSX.Element;
  disabled: boolean;
}

export const StepIcon: React.FC<StepIconProps> = ({ active, completed, children, disabled }) => {
  const classes = useStyles();

  return (
    <div
      className={`${classes.root} ${active && classes.active} ${completed && classes.completed
        } ${disabled && classes.disabled}`}
    >
      {children}
    </div>
  );
};
