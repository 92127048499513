import { flow, makeObservable, observable } from 'mobx';
import { DashboardService } from '../services/dashboardService';
import { ApiResponse } from '@roc/feature-app-core';

interface DashboardData {
  loansStatistics: any;
  tasks: any;
}

export class DashboardStore {
  private dashboardService: DashboardService;

  dashboardData: DashboardData;

  constructor() {
    this.dashboardService = new DashboardService();
    makeObservable(this, {
      dashboardData: observable,
      getAccountDashboard: flow,
    });
  }

  *getAccountDashboard() {
    try {
      const response: ApiResponse = yield this.dashboardService.getAccountDashboard();
      this.dashboardData = response.data;
    } catch (error) {
      // do nothing
    }
  }
}

export default DashboardStore;
