import { Box, Grid, Typography } from '@material-ui/core';
import CloudUpload from '@material-ui/icons/CloudUpload';
import { formatCurrency, formatPercentage } from '@roc/ui/utils';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { DscrCalculatorStore } from '../stores/dscrCalculatorStore';
import {
  CalculateButton,
  CalculatorLayout,
  LoanAutocomplete,
  PaperList,
  PaperListItem,
  PaperListTitle,
} from './dscrCalculatorComponents';
import {
  AnnualHOAFees,
  AnnualInsurance,
  AnnualGrossRent,
  AnnualTaxes,
  EstimatedAsIsValue,
  FullyAmortizingPeriodMonths,
  InPlaceMonthlyRent,
  InterestOnlyPeriodMonths,
  LoanAmount,
  LoanSubtype,
  MonthlyMarketRent,
  NoteInterestRate,
  RequestedLTV,
} from './dscrCalculatorFields';

interface DscrCalcSectionProps {
  store: DscrCalculatorStore;
}

export const DscrCalculator1To4Units = ({ store }: DscrCalcSectionProps) => {
  return (
    <CalculatorLayout
      left={
        <>
          <Grid item xs={12}>
            <LoanAutocomplete store={store} />
          </Grid>
          <CalculatorInputs store={store} />
          <CalculateButton onClick={() => store.fetchCalculatedValues()} />
        </>
      }
      right={<CalculatedDSCRValues store={store} />}
    />
  );
};

const CalculatorInputs = observer(({ store }: DscrCalcSectionProps) => {
  return (
    <>
      <Grid item xs={12}>
        <Typography variant={'h5'}>Loan Values</Typography>
      </Grid>
      <LoanSubtype store={store} />
      <EstimatedAsIsValue store={store} />
      <RequestedLTV store={store} />
      <NoteInterestRate store={store} />
      <InterestOnlyPeriodMonths store={store} />
      <FullyAmortizingPeriodMonths store={store} />
      <LoanAmount store={store} />

      <Grid item xs={12}>
        <Typography variant={'h5'}>Income</Typography>
      </Grid>
      <InPlaceMonthlyRent store={store} />
      <MonthlyMarketRent store={store} />
      <AnnualGrossRent store={store} />

      <Grid item xs={12}>
        <Typography variant={'h5'}>Expenses</Typography>
      </Grid>
      <AnnualTaxes store={store} />
      <AnnualInsurance store={store} />
      <AnnualHOAFees store={store} />
    </>
  );
});

const CalculatedDSCRValues = observer(({ store }: DscrCalcSectionProps) => {
  const values = store.calculatedValues;
  const { fields } = store.form;
  const formatCurr = value =>
    value || value === 0 ? formatCurrency(value) : '';
  return (
    <>
      <Grid item xs={12}>
        <PaperList>
          <PaperListTitle text="Income" />
          <PaperListItem
            label="Gross Annual Income"
            value={formatCurr(fields.grossAnnualIncome.value || 0)}
          />
        </PaperList>
      </Grid>
      <Grid item xs={12}>
        <PaperList>
          <PaperListTitle text="Expenses" />
          <PaperListItem
            label="Total Operating Expenses"
            value={formatCurr(store.operatingExpenses)}
          />
        </PaperList>
      </Grid>
      <Grid item xs={12}>
        <PaperList>
          <PaperListTitle text="Economics" />
          <PaperListItem
            label="IO Period Debt Service"
            value={formatCurr(values?.debtServiceInIOPeriod)}
          />
          <PaperListItem
            label="IO Period PITIA DSCR"
            value={values?.pitiaDscrInIOPeriod ?? ''}
          />
          <PaperListItem
            label="Fully Amortizing Debt Service"
            value={formatCurr(values?.fullyAmortizingDebtService)}
          />
          <PaperListItem
            label="Fully Amortizing PITIA DSCR"
            value={values?.fullyAmortizingDscrInIOPeriod ?? ''}
          />
        </PaperList>
      </Grid>
    </>
  );
});
