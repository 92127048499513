import { FormStore, GridStore } from '@roc/feature-app-core';
import { action, flow, makeObservable, observable } from 'mobx';
import { ProspectPropertyService } from '../services/prospectPpropertyService';
import { ProspectPropertiesBaseStore } from './prospectPropertiesBaseStore';

export class ProspectPropertiesStore extends ProspectPropertiesBaseStore {
  prospectPropertyService: ProspectPropertyService;

  constructor(globalStore) {
    super(globalStore);
    this.prospectPropertyService = new ProspectPropertyService();
    this.availablePropertiesGridStore = new GridStore(
      () => this.fetchAvailableProspectProperties(),
      null,
      50
    );
    this.myPropertiesGridStore = new GridStore(
      () => this.fetchMyProperties(),
      null,
      50
    );

    this.isInternal = false;

    makeObservable(this, {
      isInternal: observable,
      fetchProspectProperty: flow,
    });
  }

  fetchAvailableProspectProperties = async () => {
    try {
      this.isLoadingProperties = true;
      const response = await this.prospectPropertyService.getAvailableProspectPropertiesGrid(
        this.availablePropertiesGridStore.gridData.meta.pageNumber,
        this.availablePropertiesGridStore.gridData.meta.pageSize,
        this.availablePropertiesGridStore.gridData.meta.sortDir,
        this.availablePropertiesGridStore.gridData.meta.sortBy,
        this.availablePropertiesGridStore.gridData.meta.filters
      );
      this.propertiesForCardView = response.data.data.rows;
      this.isLoadingProperties = false;
      return response;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: `Error while retrieving the prospect properties list`,
      });
    }
  };

  fetchMyProperties = async () => {
    try {
      const response = await this.prospectPropertyService.getMyProspectPropertiesGrid(
        this.myPropertiesGridStore.gridData.meta.pageNumber,
        this.myPropertiesGridStore.gridData.meta.pageSize,
        this.myPropertiesGridStore.gridData.meta.sortDir,
        this.myPropertiesGridStore.gridData.meta.sortBy,
        this.myPropertiesGridStore.gridData.meta.filters
      );
      this.propertiesForCardView = response.data.data.rows;
      return response;
    } catch (error) {
      console.log(error)
      this.globalStore.notificationStore.showErrorNotification({
        message: `Error while retrieving the prospect properties list`,
      });
    }
  };

  *fetchProspectProperty(prospectPropertyId, propertyType) {
    try {
      const response = yield this.prospectPropertyService.getProspectProperty(
        prospectPropertyId, propertyType
      );
      this.selectedProperty = response.data.data;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: `Error while retrieving the prospect property`,
      });
    }
  }
}
