import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { TextField, CurrencyField, PercentageField, DateField } from '@roc/ui';
import { observer } from 'mobx-react';
import { FundingTemplateStore } from '../../../../../stores/documentForms/fundingTemplateStore';
import { YES, NO } from '@roc/feature-utils';
import { format, isValid } from 'date-fns';

export type FundingTemplateDetailsFormProps = {
  store: FundingTemplateStore;
  estimatedFundingTemplate?: boolean;
};

export const FundingTemplateDetailsForm = observer(
  (props: FundingTemplateDetailsFormProps) => {
    const { store, estimatedFundingTemplate = false } = props;
    const { fundingTemplate, loanStore } = store;

    const { loanDetails } = loanStore;
    const manageDate = (date) => {
      let formattedDate = null;
      if (isValid(date)) {
        formattedDate = format(date, 'MM/dd/yyyy');
      }
      fundingTemplate.loanFundingDate = formattedDate;
    };


    return (
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            {estimatedFundingTemplate &&
              <>
                <Grid item xs={12}>
                  <Typography variant='h4'>
                    Estimated Funding Template Numbers
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='h5'>
                    [{loanDetails.loanId}] - {loanDetails.dealName}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography>
                    This is only an estimation of the numbers in the Funding Template and will not schedule the loan for closing.
                  </Typography>
                </Grid>
              </>
            }
            <Grid item xs={12} md={4}>
              <Grid container spacing={2} direction="row">
                {estimatedFundingTemplate ?
                  <Grid item xs={12}>
                    <DateField
                      testId="dob"
                      required
                      standaloneLabel
                      inputVariant="outlined"
                      format="MM/dd/yyyy"
                      label="Loan Estimated Funding Date"
                      value={fundingTemplate?.loanFundingDate}
                      onChange={(date) => {
                        manageDate(date);
                        store.getEstimatedFundingTemplate(fundingTemplate?.loanFundingDate)
                      }}
                      fullWidth
                    />
                  </Grid>
                  :
                  <Grid item xs={12}>
                    <TextField
                      label="Loan Funding Date"
                      standaloneLabel
                      value={fundingTemplate?.loanFundingDate}
                      variant="outlined"
                      testId="loanFundingDate"
                      fullWidth
                      disabled
                    />
                  </Grid>

                }
                <Grid item xs={12}>
                  <CurrencyField
                    label="Loan Amount"
                    standaloneLabel
                    value={fundingTemplate?.loanAmount}
                    variant="outlined"
                    testId="loanAmount"
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item xs={12}>
                  <CurrencyField
                    label="Deferred Maintenance Holdback"
                    standaloneLabel
                    value={fundingTemplate?.deferredMaintenanceHoldback}
                    variant="outlined"
                    testId="deferredMaintenanceHoldback"
                    fullWidth
                    disabled
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
              <Grid container spacing={2} direction="row">
                <Grid item xs={12}>
                  <PercentageField
                    label="Interest Rate"
                    standaloneLabel
                    value={fundingTemplate?.interestRate}
                    variant="outlined"
                    testId="interestRate"
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Interest Method"
                    standaloneLabel
                    value={fundingTemplate?.interestMethod}
                    variant="outlined"
                    testId="interestMethod"
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Collect Short Interest at Closing?"
                    standaloneLabel
                    value={
                      fundingTemplate?.collectShortInterestAtClosing ? YES : NO
                    }
                    variant="outlined"
                    testId="collectShortInterestAtClosing"
                    fullWidth
                    disabled
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
              <Grid container spacing={2} direction="row">
                <Grid item xs={12}>
                  <PercentageField
                    label="Total Origination Fee"
                    standaloneLabel
                    value={fundingTemplate?.totalOriginationFee}
                    variant="outlined"
                    testId="totalOriginationFee"
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item xs={12}>
                  <PercentageField
                    label="Capital Provider Origination Fee"
                    standaloneLabel
                    value={fundingTemplate?.rocOriginationFee}
                    variant="outlined"
                    testId="rocOriginationFee"
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item xs={12}>
                  <PercentageField
                    label="Lender Origination Fee"
                    standaloneLabel
                    value={fundingTemplate?.lenderOriginationFee}
                    variant="outlined"
                    testId="lenderOriginationFee"
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item xs={12}>
                  <PercentageField
                    label="On Hud Broker Fee"
                    standaloneLabel
                    value={fundingTemplate?.brokerOriginationFee}
                    variant="outlined"
                    testId="brokerOriginationFee"
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item xs={12}>
                  <PercentageField
                    label="Off Hud Broker Fee"
                    standaloneLabel
                    value={fundingTemplate?.referralFeePct}
                    variant="outlined"
                    testId="brokerOriginationFee"
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item xs={12}>
                  <PercentageField
                    label="Lender Yield Spread Premium"
                    standaloneLabel
                    value={fundingTemplate?.lenderYieldSpreadPremium}
                    variant="outlined"
                    testId="lenderYieldSpreadPremium"
                    fullWidth
                    disabled
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
);

export default FundingTemplateDetailsForm;
