import React from 'react';
import {
  Box,

  Grid,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { useAppRoutes } from '../../hooks';
import { useAppConfigurations } from '@roc/client-portal-shared/hooks';
import { useHistory } from 'react-router';
import { LoginLayout } from './components/loginLayout';
import { PasswordChangeType } from '../../types';
import { Button } from '@roc/ui';
import { LoginLogoImg } from '@roc/feature-app-core';

const useStyles = makeStyles(theme =>
  createStyles({
    gridContainer: {
      padding: theme.spacing(4),
      maxWidth: 500,
      margin: 'auto',
    },
    button: {
      width: 200,
    },
  })
);

interface SuccessCreateOrResetPasswordProps {
  passwordChangeType: PasswordChangeType;
}

export const SuccessCreateOrResetPassword = ({
  passwordChangeType,
}: SuccessCreateOrResetPasswordProps) => {
  const { siteName, data } = useAppConfigurations();
  const { publicRoutes } = useAppRoutes();
  const { push } = useHistory();
  const classes = useStyles();

  return (
    <LoginLayout>
      <Grid
        container
        spacing={2}
        justifyContent="center"
        className={classes.gridContainer}
      >
        <Grid item xs={12}>
          <LoginLogoImg />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4" align="center">
            Password{' '}
            {passwordChangeType == PasswordChangeType.CREATE
              ? 'created'
              : 'updated'}{' '}
            successfully!
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <br />
          <Typography variant="body1" align="center">
            {`Please continue to login to view your ${siteName} account.`}
          </Typography>
        </Grid>
        {data.phoneNumber && (
          <>
            <Grid item xs={12}>
              <Typography variant="body1" align="center">
                If you need further assistance, please contact us at:
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" align="center">
                {data.phoneNumber}
              </Typography>
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <br />
          <Box textAlign="center">
            <Button
              variant="contained"
              color="primary"
              type="submit"
              className={classes.button}
              onClick={() => push({
                pathname: publicRoutes.login.url,
                search: window.location.search
              })}
              testId="submit"
            >
              <span>LOG IN</span>
            </Button>
          </Box>
        </Grid>
      </Grid>
    </LoginLayout>
  );
};
