import { Service } from '@roc/feature-app-core';

const url = {
  BROKERS: '/api/v1/loan/brokers/getMyExistingBrokersGrid',
  BROKER_CONTACTS: '/api/v1/loan/brokers/getMyExistingBrokerContactsGrid',
  BROKER_CONTACTS_FOR_BROKER: '/api/v1/loan/brokers/getMyExistingBrokerContactsGridForBroker',
  GET_BROKER_BY_USER_ID: '/api/v1/loan/brokers/getBrokerByUserId',
  SAVE_BROKER: '/api/v1/loan/brokers/saveBroker',
  CREATE_BROKER_USER: '/api/v1/user/createBrokerUser',
  RESEND_INVITE: '/api/v1/user/resendCreatePasswordInvite',
  DEACTIVATE_USER: '/api/v1/user/deactivateUser',
  GET_BROKER_LOGIN_LINK: '/api/v1/user/getPasswordLinkForUser',
  GET_DEFAUL_BROKER_ECONOMICS_TABLE: '/api/v1/loan/brokers/getDefaulBrokerEconomicsTable',
  SAVE_BROKER_ECONOMICS: '/api/v1/loan/brokers/saveBrokerEconomics',
  GET_ATTORNEY_FOR_BROKERS: '/api/v1/loan/getAttorneysForBrokers',
  GET_BROKER_VENDOR_CLOSING_SETTINGS_BY_BROKER_ID: '/api/v1/loan/brokers/getBrokerVendorClosingSettingsByBrokerId'
};

export class BrokerService extends Service {
  async getAllBrokers(
    page: number,
    pageSize: number,
    sortDir: string,
    sortBy: string,
    filters: string
  ) {
    const response = await this.get(
      url.BROKERS,
      {
        page,
        pageSize,
        sortDir,
        sortBy,
        filters,
      },
      {
        disableGlobalLoading: true,
      }
    );

    const {
      data: { data },
    } = response;

    const rows = data.rows.map(b => ({
      userId: b.userId,
      brokerId: b.brokerId,
      firstName: b.firstName,
      lastName: b.lastName,
      name: b.name,
      companyName: b.entityName,
      entityName: b.entityName,
      entityTaxNumber: b.entityTaxNumber,
      einNumber: b.entityTaxNumber,
      phone: b.phone,
      email: b.email,
      lenderId: b.lenderId,
      companyId: b.companyId,
      contactId: b.contactId,
      createdBy: b.createdBy,
      createdDate: b.createdDate,
      lastUpdatedBy: b.lastUpdatedBy,
      lastUpdatedDate: b.lastUpdatedDate,
      portalStatus: b.userPortalStatus
    }));

    response.data.data.rows = rows;
    return response;
  }

  async getAllBrokerContacts(
    page: number,
    pageSize: number,
    sortDir: string,
    sortBy: string,
    filters: string
  ) {
    const response = await this.get(
      url.BROKER_CONTACTS,
      {
        page,
        pageSize,
        sortDir,
        sortBy,
        filters,
      },
      {
        disableGlobalLoading: true,
      }
    );

    const {
      data: { data },
    } = response;

    const rows = data.rows.map(b => ({
      userId: b.userId,
      brokerId: b.brokerId,
      firstName: b.firstName,
      lastName: b.lastName,
      name: b.name,
      companyName: b.companyName,
      entityTaxNumber: b.einNumber,
      einNumber: b.einNumber,
      phone: b.phone,
      email: b.email,
      lenderId: b.lenderId,
      companyId: b.companyId,
      contactId: b.contactId,
      portalStatus: b.userPortalStatus
    }));

    response.data.data.rows = rows;
    return response;
  }

  async getBrokerContactsForBroker(
    page: number,
    pageSize: number,
    sortDir: string,
    sortBy: string,
    filters: string,
    brokerId: number
  ) {
    console.log(brokerId);
    const response = await this.get(
      url.BROKER_CONTACTS_FOR_BROKER,
      {
        page,
        pageSize,
        sortDir,
        sortBy,
        filters,
        brokerId
      },
      {
        disableGlobalLoading: true,
      }
    );

    const {
      data: { data },
    } = response;

    const rows = data.rows.map(b => ({
      userId: b.userId,
      brokerId: b.brokerId,
      firstName: b.firstName,
      lastName: b.lastName,
      name: b.name,
      companyName: b.companyName,
      entityTaxNumber: b.einNumber,
      einNumber: b.einNumber,
      phone: b.phone,
      email: b.email,
      lenderId: b.lenderId,
      companyId: b.companyId,
      contactId: b.contactId,
      portalStatus: b.userPortalStatus
    }));

    response.data.data.rows = rows;
    return response;
  }

  getBrokerByUserId(userId: number) {
    return this.get(url.GET_BROKER_BY_USER_ID, { userId: userId });
  }

  saveBroker(broker) {
    return this.put(url.SAVE_BROKER, broker)
  }

  createBrokerUser(brokerUser) {
    return this.post(url.CREATE_BROKER_USER, brokerUser);
  }

  resendInvite(userId: number) {
    return this.put(`${url.RESEND_INVITE}?userId=${userId}`, {});
  }

  deactivateUser(deactivateUserRequest) {
    return this.post(url.DEACTIVATE_USER, deactivateUserRequest);
  }

  getBrokerLoginLink(userId: number) {
    return this.get(`${url.GET_BROKER_LOGIN_LINK}?userId=${userId}`, {});
  }

  getDefaulBrokerEconomicsTable(lenderId, brokerId) {
    return this.get(url.GET_DEFAUL_BROKER_ECONOMICS_TABLE, {lenderId, brokerId});
  }

  saveBrokerEconomics(data){
    return this.post(url.SAVE_BROKER_ECONOMICS, data);
  }
  getAttorneysForBrokers() {
    return this.get(url.GET_ATTORNEY_FOR_BROKERS);
  }

  getBrokerVendorClosingSettingsByBrokerId(brokerId, lenderId) {
    return this.get(url.GET_BROKER_VENDOR_CLOSING_SETTINGS_BY_BROKER_ID, { lenderId, brokerId });
  }
}
