import React, { useEffect, useState } from 'react';
import { Grid, ListItemIcon, List, ListItem } from '@material-ui/core';
import { observer } from 'mobx-react';
import { FirstName, LastName, EmailAddress, CellPhone, createPercentageField, createRadioField } from '@roc/ui/formComponents';
import { Button, MultiSelectField, SelectField, TextField } from '@roc/ui';
import { isNotBlank, lenderCompanyrolesOptions, lenderRoleOptions, LENDER_OWNER_COMPANY_ROLE, roleOptions, Roles } from '@roc/feature-utils';
import { yesNoOptions } from 'libs/client-portal-shared/src/app/modules/fixFlipSizer/utils/constants';
import { Warning } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { UserPreferencesOptions } from './userPreferencesOptions';
import { User } from '@twilio/conversations';
import { ManageUsersStore } from '../stores/manageUsersStore';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { LenderUserDocumentsForm } from './lenderUserDocuments';
import { useStore } from '@roc/client-portal-shared/hooks';
import { useLenderUserDocumentStore } from '../hooks/useLenderUserDocumentStore';




const useWarningStyles = makeStyles(() => ({
  textContainer: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#fdefbf',
    padding: '4px',
  },
  warningIcon: {
    color: '#F2BC16',
    fontSize: '20px',
  },
  text: {
    paddingLeft: '8px',
    fontSize: '14px',
  },
  warningColor: {
    color: '#F2BC16',
  },
}));



export const UserDetailsForm = observer(({ store, disabled = true, user }) => {
  const { userId } = user;
  const classes = useWarningStyles();
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <FirstName store={store} disabled={disabled} />
      </Grid>
      <Grid item xs={6}>
        <LastName store={store} disabled={disabled} />
      </Grid>
      <Grid item xs={6}>
        <EmailAddress store={store} required={true} disabled={true} />
      </Grid>
      <Grid item xs={6}>
        <CellPhone store={store} required={true} disabled={disabled} />
      </Grid>
      <Grid item xs={6}>
        <Role store={store} options={lenderRoleOptions} required={true} disabled={disabled} />
      </Grid>
      <Grid item xs={6}>
        <ChooseRelationships store={store} userId={userId} disabled={disabled} />
      </Grid>
      <Grid item xs={6}>
        <CompanyRole store={store} options={lenderCompanyrolesOptions} required={true} disabled={disabled} />
      </Grid>

      {store.form.fields.roleInEntityCurrent.value === LENDER_OWNER_COMPANY_ROLE && (<Grid item xs={6}>
        <OwnershipPercentage store={store} disabled={disabled} />
      </Grid>)}
      <UserPreferencesOptions store={store} />
      {store.form.fields.roleInEntityCurrent.value === LENDER_OWNER_COMPANY_ROLE && store.form.fields.ownershipPercentage.value > 0 && (
        <Grid item xs={12}>
          <div className={classes.warningColor}>
            *Adding a new owner will trigger a background check*
          </div>
        </Grid>
      )}
    </Grid >
  );
});

export const ChooseRelationships = observer(({ store, userId = null, disabled = false }) => {
  const role = store.form.fields.role.value;

  useEffect(() => {
    if (Roles.LENDER == role || Roles.ORIGINATOR == role) {
      if (userId) {
        store.fetchLenderOriginatorRelationships(userId);
      } else {
        store.fetchProcessorsAndOriginators();
      }
    }
  }, [userId, role]);

  return (
    <>
      {
        Roles.ORIGINATOR == role && (
          <Processors store={store} required={true} disabled={disabled} />
        )
      }
      {
        Roles.LENDER == role && (
          <Originators store={store} required={true} disabled={disabled} />
        )
      }
    </>
  );
});


const getRoleValue = (role) => {
  if (role === 'Processor') {
    return 'lender';
  } else if (role === 'Originator') {
    return 'lender_originator';
  } else if (role === 'Admin') {
    return 'lender_full_access_admin';
  } else if (role === 'Lead') {
    return 'lender_full_access';
  } else if (role === "Broker") {
    return "broker";
  }
  return role;
}

export const Role = observer(
  ({
    store,
    disabled,
    required,
    options,
    onChange,
  }: {
    store: any;
    disabled?: boolean;
    required?: boolean;
    options?: Array<any>;
    onChange?: (field, value) => void
  }) => {
    const formFields = store.form.fields;
    formFields.role.value = getRoleValue(formFields.role.value);
    const handleChange = (value) => {
      store.onFieldChange('role', value)
      if (onChange) onChange('role', value);
      if (value != roleOptions.ORIGINATOR) {
        store.onFieldChange('allowLoanPricing', true);
      }
    }

    return (
      <SelectField
        disabled={disabled}
        required={required}
        standaloneLabel
        label="Portal Role"
        value={formFields.role.value}
        options={options}
        onChange={handleChange}
        error={isNotBlank(formFields.role.error)}
        errorText={formFields.role.error}
        variant="outlined"
        fullWidth
        testId="role"
      />
    );
  }
);

export const CompanyRole = observer(
  ({
    store,
    disabled,
    required,
    options,
    onChange,
  }: {
    store: any;
    disabled?: boolean;
    required?: boolean;
    options?: Array<any>;
    onChange?: (field, value) => void,
  }) => {
    const formFields = store.form.fields;

    const handleChange = (value) => {
      store.onFieldChange('roleInEntityCurrent', value)
      store.onFieldChange('ownershipPercentage', 0)
      if (onChange) onChange('roleInEntityCurrent', value);
    }

    return (
      <SelectField
        disabled={disabled}
        required={required}
        standaloneLabel
        label={'Role in Entity'}
        value={formFields.roleInEntityCurrent?.value}
        options={options}
        onChange={handleChange}
        error={isNotBlank(formFields.roleInEntityCurrent?.error)}
        errorText={formFields.roleInEntityCurrent?.error}
        variant="outlined"
        fullWidth
        testId="roleInEntityCurrent"
      />
    );
  }
);

export const OwnershipPercentage = observer(({
  store, disabled
}: {
  store: any;
  disabled?: boolean;
  required?: boolean;
  options?: Array<any>;
  onChange?: (field, value) => void,
}) => {

  return createPercentageField({
    testId: 'ownershipPercentage',
    fieldName: 'ownershipPercentage',
    label: 'Ownership Percentage',
    disabled,
    store,
  })
});

export const AllowLoanPricingForUsers = observer(
  ({
    store
  }: {
    store: any;
  }) => {

    return createRadioField({
      store,
      testId: 'allowLoanPricing',
      fieldName: 'allowLoanPricing',
      label: '',
      options: yesNoOptions,
      row: true,
    });
  });

export const Originators = observer(
  ({
    store,
    disabled,
    required,
  }: {
    store: ManageUsersStore;
    disabled?: boolean;
    required?: boolean;
  }) => {
    const formFields = store.form.fields;
    const selectedIds = formFields.originatorIds.value;
    const selectedOptions = store.originatorOptions.filter(option => selectedIds.includes(option.value))
    const onChange = (e, newSelectedOptions) => {
      const newSelectedIds = newSelectedOptions.map(option => option.value)
      store.onFieldChange('originatorIds', newSelectedIds)
    }

    return (
      <MultiSelectField
        standaloneLabel
        disabled={disabled}
        label={'Choose originator(s) for this processor'}
        name={'originatorIds'}
        options={store.originatorOptions}
        getOptionSelected={(option, selectedValue) => {
          return option.value == selectedValue.value;
        }}
        value={selectedOptions}
        onChange={onChange}
        renderInput={params => (
          <TextField
            helperText={formFields.originatorIds.error}
            error={isNotBlank(formFields.originatorIds.error)}
            testId="originatorIds"
            {...params}
            variant="outlined"
          />
        )}
        fullWidth
        testId="originatorIds"
      />
    );
  });

export const Processors = observer(
  ({
    store,
    disabled,
    required,
  }: {
    store: ManageUsersStore;
    disabled?: boolean;
    required?: boolean;
  }) => {
    const formFields = store.form.fields;
    const selectedIds = formFields.processorIds.value;
    const selectedOptions = store.processorOptions.filter(option => selectedIds.includes(option.value))
    const onChange = (e, newSelectedOptions) => {
      const newSelectedIds = newSelectedOptions.map(option => option.value)
      store.onFieldChange('processorIds', newSelectedIds)
    }

    return (
      <MultiSelectField
        standaloneLabel
        disabled={disabled}
        label={'Choose processor(s) for this originator'}
        name={'processorIds'}
        options={store.processorOptions}
        getOptionSelected={(option, selectedValue) => {
          return option.value == selectedValue.value;
        }}
        value={selectedOptions}
        onChange={onChange}
        renderInput={params => (
          <TextField
            helperText={formFields.processorIds.error}
            error={isNotBlank(formFields.processorIds.error)}
            testId="processorIds"
            {...params}
            variant="outlined"
          />
        )}
        fullWidth
        testId="processorIds"
      />
    );
  });

type UserDetailsPropType = {
  store: ManageUsersStore,
  user: any
  currentRole: string
}
const UserDetails = observer(({ store, user, currentRole }: UserDetailsPropType) => {
  const [isEditing, setIsEditing] = useState(false);
  const { lenderUserDocumentStore } = store;

  useEffect(() => {
    store.editUserFromGrid(user);
    lenderUserDocumentStore.loadData(user.firstName, user.lastName);
  }, [lenderUserDocumentStore, store, user])

  const updateUser = () => {
    setIsEditing(false);
    store.updateLenderUser(
      store.form.fields.userId.value,
      currentRole,
      store.form.fields.roleInEntityCurrent.value,
      user,
      store.form.fields.role.value);
  }
  return (
    <div>
      <Grid container spacing={4}>
        <Grid item xs={12} container justifyContent="flex-end">
          {isEditing ? (
            <>
              <Button
                style={{ marginRight: '8px' }}
                onClick={() => setIsEditing(false)}
                color="primary"
                variant="outlined"
                testId="cancel"
              >
                Cancel
              </Button>
              <Button
                onClick={updateUser}
                color="primary"
                variant="contained"
                testId="save"
              >
                Apply Changes
              </Button>
            </>
          ) : (
            <Button
              variant="contained"
              onClick={() => {
                setIsEditing(true);
              }}
              color="primary"
              testId="edit_user"
            >
              Edit
            </Button>
          )}

        </Grid>
        <Grid item xs={12}>
          <UserDetailsForm store={store} disabled={!isEditing} user={user}></UserDetailsForm>
        </Grid>
        <Grid item xs={12}>
          <LenderUserDocumentsForm lenderUserDocumentStore={lenderUserDocumentStore} />
        </Grid>

      </Grid>

    </div>
  )
})

export default UserDetails
