import { Layout, Page } from '../../../components';
import { Route } from 'react-router';
import { useStore } from '@roc/client-portal-shared/hooks';
import { DscrCalculator } from '../dscrCalculator';

export const getDscrCalculatorRoutesConfig = (
  basePath: string,
  baseUrl: string
) => {
  return {
    dscrCalculator: {
      path: `${basePath}/dscr-calculator`,
      url: `${baseUrl}/dscr-calculator`,
      documentTitle: 'DSCR Calculator',
    },
  };
};

export const getDscrCalculatorRoutes = (basePath: string, baseUrl: string) => {
  const config = getDscrCalculatorRoutesConfig(basePath, baseUrl);
  const { globalStore, dscrCalculatorStore } = useStore();

  return {
    dscrCalculator: (
      <Route exact path={config.dscrCalculator.path}>
        <Page routeDefinition={config.dscrCalculator}>
          <DscrCalculator dscrCalculatorStore={dscrCalculatorStore} />
        </Page>
      </Route>
    ),
  };
};
