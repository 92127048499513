import {
  Box,
  Button,
  Grid,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Add, CheckCircle } from '@material-ui/icons';
import { groupBy } from '@roc/feature-utils';
import { Tooltip } from '@roc/ui';
import { observer } from 'mobx-react';
import React, { Fragment, useState } from 'react';
import { FROM_46_TO_50 } from '../../utils/constants';
import { allLtvBandsInDisplayOrder, swapIndexLabels } from '../utils/constants';

const useStyles = makeStyles(theme =>
  createStyles({
    tableContainer: {
      borderRadius: theme.spacing(1),
      border: '1px solid #333333',
      background: '#FFF',
    },
    table: {
      '& td': {
        textAlign: 'center',
        padding: theme.spacing(1),
      },
    },
    errorContainer: {
      color: theme.palette.error.main,
    },
    gray: {
      color: 'white',
      backgroundColor: '#333333',
    },
    lightGray: {
      color: 'white',
      backgroundColor: '#444444',
    },
    rateCell: {
      display: 'inline-flex',
      flexDirection: 'column',
      justifyContent: 'center',
      position: 'relative',
      padding: theme.spacing(1.5),
    },
    lowestRate: {
      textAlign: 'center',
      fontSize: '0.8em',
      color: theme.palette.success.main,
      lineHeight: '12px',
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
    },
  })
);

export const LoanRatesTable = observer(({ store }) => {
  const classes = useStyles();
  const [menuProps, setMenuProps] = useState(null);
  const { globalStore, pricerSummaryStore } = store;
  const {
    rateTypeColumns,
    loanRatesRows,
    loanStandardRatesRows,
    form,
    currentInvestorLtvBands,
  } = store.pricerSummaryStore;
  const { rateType, loanToValue, programType, amortization } = form.fields;

  const getSwapIndexLabel = index => {
    return swapIndexLabels[index];
  };

  const ltvBandsToDisplay = allLtvBandsInDisplayOrder.filter(ltvBand =>
    currentInvestorLtvBands.includes(ltvBand)
  );

  const programTypes = [
    {
      name: loanRatesRows[0]?.programType || loanRatesRows[0]?.[FROM_46_TO_50]?.programType,
      rows: loanRatesRows
    },
    {
      name: loanStandardRatesRows[0]?.programType || loanStandardRatesRows[0]?.[FROM_46_TO_50]?.programType,
      rows: loanStandardRatesRows,
    },
  ];

  const rateTypeColumnsToDisplay = rateTypeColumns.filter(
    column =>
      currentInvestorLtvBands.includes(column.value) &&
      column.label != 'Loan To Value'
  );

  const addToQuote = () => {
    const rateRow = menuProps.selectedRateRow;
    const ltvBand = menuProps.selectedLtvBand;
    pricerSummaryStore.addToQuote(rateRow, ltvBand);
    setMenuProps(null);
  };

  return (
    rateTypeColumnsToDisplay?.length == 0 ? null :
      <>
        <TableContainer className={classes.tableContainer}>
          <Table className={classes.table} aria-label="avilable loan rates">
            <TableHead>
              <TableRow>
                <TableCell colSpan={3} style={{ textAlign: 'center' }}>
                  Loan To Value
                </TableCell>
                {rateTypeColumnsToDisplay.map(column => (
                  <TableCell key={column.label} style={{ textAlign: 'center' }}>
                    <Typography variant="body2">{column.label}</Typography>
                  </TableCell>
                ))}
              </TableRow>
              <TableRow>
                <TableCell colSpan={2} className={classes.gray} />
                <TableCell colSpan={1} className={classes.gray} style={{ textAlign: 'center' }}>
                  Rate Type
                </TableCell>
                {rateTypeColumnsToDisplay.map(
                  column =>
                    currentInvestorLtvBands.includes(column.value) && (
                      <TableCell className={classes.gray} style={{ textAlign: 'center' }}>
                        Today's Gross Rate
                      </TableCell>
                    )
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {programTypes.map(program => {
                const swapIndexLabels = program.rows.map(row =>
                  getSwapIndexLabel(row[FROM_46_TO_50].swapIndex)
                );
                return (
                  <Fragment key={program.name}>
                    {program.rows.map((row, i) => {

                      const swapIndexLabel = swapIndexLabels[i];
                      const swapIndexGroupFirstRow = swapIndexLabels.indexOf(
                        swapIndexLabel
                      );
                      const swapIndexGroupLastRow = swapIndexLabels.lastIndexOf(
                        swapIndexLabel
                      );
                      return (
                        <TableRow key={row.rateType}>
                          {i === 0 && (
                            <TableCell
                              className={classes.gray}
                              rowSpan={program.rows.length}
                              style={{
                                textAlign: 'center',
                                writingMode: 'vertical-rl',
                                transform: 'rotate(180deg)',
                              }}
                            >
                              <Typography variant="body2">
                                <strong>{program.name}</strong>
                              </Typography>
                            </TableCell>
                          )}
                          {i === swapIndexGroupFirstRow && (
                            <TableCell
                              className={classes.lightGray}
                              rowSpan={
                                swapIndexGroupLastRow - swapIndexGroupFirstRow + 1
                              }
                              style={{
                                textAlign: 'center',
                                writingMode: 'vertical-rl',
                                transform: 'rotate(180deg)',
                              }}
                            >
                              {swapIndexLabel}
                            </TableCell>
                          )}
                          <TableCell style={{ textAlign: 'center' }}>
                            <Typography variant="body2">
                              {row.rateType}
                            </Typography>
                          </TableCell>
                          {ltvBandsToDisplay.map(ltvBand => (
                            <TableCell style={{ textAlign: 'center' }}>
                              <SpreadOverIndexAndGrossRateCell
                                store={store}
                                rateRow={row}
                                ltvBand={ltvBand}
                                onClick={e =>
                                  setMenuProps({
                                    anchorEl: e.currentTarget,
                                    selectedRateRow: row,
                                    selectedLtvBand: ltvBand,
                                  })
                                }
                              />
                            </TableCell>
                          ))}
                        </TableRow>
                      );
                    })}
                  </Fragment>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Menu
          id="menu-appbar"
          keepMounted
          getContentAnchorEl={null}
          anchorEl={menuProps?.anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={!!menuProps}
          onClose={() => setMenuProps(null)}
        >
          <MenuItem onClick={addToQuote}>
            <ListItemIcon>
              <Add />
            </ListItemIcon>
            <ListItemText>Add To Quote</ListItemText>
          </MenuItem>
        </Menu>
      </>
  );
});

const SpreadOverIndexAndGrossRateCell = ({
  store,
  rateRow,
  ltvBand,
  onClick,
}) => {
  const classes = useStyles();
  const rateOption = rateRow[ltvBand];
  const { spreadRate, swapRate, warningMessage, errorMessage } =
    rateOption || {};

  const { form, lowestLtvRates } = store.pricerSummaryStore;

  const formatRate = rate => {
    return rate ? (
      <>
        {rate.toFixed(4)}%
        {!!warningMessage && (
          <strong
            style={{ fontSize: '20px' }}
            className={classes.errorContainer}
          >
            *
          </strong>
        )}
      </>
    ) : (
      'N/A'
    );
  };

  const tooltip = !rateOption
    ? 'Ltv band/ Rate Term Type not supported'
    : (errorMessage || []).join('\n');

  const amortization = form.fields.amortization.value;
  const lowestLtvRate = lowestLtvRates[ltvBand]?.[amortization];
  const isLowestRate =
    rateOption?.spreadRate === lowestLtvRate?.spreadRate &&
    rateOption?.swapRate === lowestLtvRate?.swapRate;

  const swapDisplayValue = formatRate(spreadRate);
  const spreadDisplayValue = formatRate(spreadRate + swapRate);

  const hasErrors = !rateOption?.swapIndex || errorMessage?.length > 0;

  return (
    <Tooltip title={tooltip}>
      <Button onClick={hasErrors ? undefined : onClick} className={classes.rateCell}>
        {isLowestRate && <Box className={classes.lowestRate}>LOWEST</Box>}
        <Box display="flex" alignItems={'center'}>
          <Box flex={1} pl={1} textAlign="left">
            <Typography variant="body2">
              {rateOption.swapIndex ? spreadDisplayValue : 'N/A'}
            </Typography>
          </Box>
        </Box>
      </Button>
    </Tooltip>
  );
};