import { Service } from '@roc/client-portal-shared/services';

const url = {
  REFERRAL_FEE_LINK: `/nportal/rest/priv/loan/getReferralFeeLink`,
  GET_BANK_INFO: `/nportal/rest/priv/bankinfo`,
  APPROVE_BANK_INFO: `/nportal/rest/priv/bankinfo/approve`,
};

export class ReferralFeeInternalService extends Service {
  getReferralFeeLink(loanId: string) {
    return this.get(`${url.REFERRAL_FEE_LINK}?loanId=${loanId}`);
  }

  getBankInfo(entityId: string) {
    return this.get(`${url.GET_BANK_INFO}?entityId=${entityId}&entityType=BrokerEntity`);
  }

  approveBankInformation(entityId: string) {
    return this.get(`${url.APPROVE_BANK_INFO}?entityId=${entityId}&entityType=BrokerEntity`);
  }
}
