import { Service } from '@roc/feature-app-core';

const url = {
  UPLOAD_DRAFT_LOAN_TASK_FILE: '/api/v1/tasks/uploadDraftLoanTaskFile',
  GET_DRAFT_LOAN_TASKS: '/api/v1/tasks/getDraftLoanTasks',
  DOWNLOAD_TASK_FILE: '/api/v1/tasks/downloadTaskFile',
};
export class DraftLoanTasksService extends Service {
  getDraftLoanTasks(draftLoanId) {
    return this.get(url.GET_DRAFT_LOAN_TASKS, { draftLoanId });
  }

  uploadDraftLoanTaskFile(task: any, file: File) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('task', new Blob([JSON.stringify(task)], {
      type: 'application/json',
    }));
    return this.post(url.UPLOAD_DRAFT_LOAN_TASK_FILE, formData, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    });
  }

  downloadTaskFile(taskId) {
    return this.get(
      url.DOWNLOAD_TASK_FILE,
      { taskId },
      { responseType: 'arraybuffer' }
    );
  }
}
