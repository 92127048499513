import { FormStore, GlobalStore } from '@roc/feature-app-core';
import { GENERIC_ERROR_MESSAGE } from '@roc/feature-utils';
import { DialogState } from '@roc/ui';
import { flow, makeObservable, observable, override } from 'mobx';
import { LeadService } from '../../services/leadService';
import { LeadDetailsStore } from './leadDetailsStore';
import { LEAD_OBJECT_TYPE } from '../../components/leads/constants/constants';

const form = {
  fields: {
    subject: {
      value: '',
      error: null,
      rule: 'required',
    },
    body: {
      value: '',
      error: null,
      rule: '',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export class EditLeadNoteStore extends FormStore {
  private globalStore: GlobalStore;
  protected leadDetailsStore: LeadDetailsStore;
  protected leadService: LeadService;

  dialogState: DialogState;
  leadNote: any;

  constructor(globalStore: GlobalStore, leadDetailsStore: LeadDetailsStore) {
    super(form, globalStore);
    this.globalStore = globalStore;
    this.leadDetailsStore = leadDetailsStore;
    this.leadService = new LeadService();

    makeObservable(this, {
      dialogState: observable,
      leadNote: observable,
      reset: override,
      openAddLeadNote: flow,
      openAddLeadNoteFromDashboard: flow,
      openEditLeadNote: flow,
      closeLeadNoteDialog: flow,
      saveLeadNote: flow,
      removeLeadNote: flow,
    });
  }

  reset() {
    super.reset();
    this.leadNote = null;
  }

  *openEditLeadNote(leadNote) {
    this.reset();
    this.leadNote = leadNote;
    this.loadForm(leadNote);
    this.dialogState = DialogState.EDIT;
  }

  *openAddLeadNote() {
    this.reset();
    this.initializeLeadNote(this.leadDetailsStore.objectId, this.leadDetailsStore.type);
    this.dialogState = DialogState.ADD;
  }

  *openAddLeadNoteFromDashboard(objectId: number, type: string) {
    this.reset();
    this.initializeLeadNote(objectId, type);
    this.dialogState = DialogState.ADD;
  }

  *closeLeadNoteDialog() {
    this.dialogState = null;
  }

  *saveLeadNote(businessUnit: string) {
    try {
      if (!this.runFormValidationWithMessage()) {
        return;
      }
      const values = this.getFormValues();
      const leadNote = {
        ...this.leadNote,
        subject: values.subject,
        body: values.body,
      };
      yield this.leadService.saveLeadNote(leadNote);
      yield this.closeLeadNoteDialog();
      if (this.leadDetailsStore.objectId) {
        yield this.leadDetailsStore.fetchLeadNotes(businessUnit);
      }
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Note saved successfully!',
      });
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE,
      });
    }
  }

  *removeLeadNote(leadNote, businessUnit: string) {
    try {
      yield this.leadService.deleteLeadNote(leadNote.leadNoteId);
      yield this.closeLeadNoteDialog();
      yield this.leadDetailsStore.fetchLeadNotes(businessUnit);
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Note removed successfully!',
      });
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE,
      });
    }
  }

  private initializeLeadNote(objectId: number, type: string) {
    this.leadNote = {
      leadId: type === LEAD_OBJECT_TYPE ? objectId : null,
      contactId: type !== LEAD_OBJECT_TYPE ? objectId : null,
    };
  }
}
