import { useState, useEffect } from 'react';
import {
  Box,

  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { darken } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { Layout } from '@roc/client-portal-shared/components';
import {
  Button,
  Paper,
  SubmitButton,
  TestDataAttribute,
  StandardDialog,
} from '@roc/ui';
import { formatCurrency } from '@roc/ui/utils';
import { useHistory } from 'react-router';
import {
  EntityCard,
  StatusChip,
  StatusType,
} from '@roc/client-portal-shared/components';
import {
  EditBorrowersForm,
  CreateAppraisalForm,
  TermPropertiesGrid,
  useLoanStore,
  US_TREASURY_5_YR_INDEX,
  US_TREASURY_5_YR_DESC,
  US_TREASURY_10_YR_INDEX,
  US_TREASURY_10_YR_DESC,
} from '@roc/feature-loans';
import { SubdirectoryArrowRight } from '@material-ui/icons';
import { useRoutes } from '@roc/client-portal-shared/hooks';
import {
  formatDate,
  isNil,
  sanitizeTestId,
  isFundedOrAgreedLoan,
  isFundedLoan,
  isPreagreedLoanStatus,
  getUniqueId,
  LoanStatus,
  LoanSubType
} from '@roc/client-portal-shared/utils';
import { BooleanChip } from './components/loanSummaryComponents';
import { useBaseStore, useUserStore } from '@roc/feature-app-core';
import { LoanParticipants } from 'libs/feature-loans/src/loanSubmission/components/loanParticipants';
import { EditLoanButton, LoanStatusChangeReasonBanner, LoanSummaryBrokerSection, LoanSummaryLoanParticipantsSection } from '@roc/feature-loans-shared';
import { LoanPrescreenBanner } from '@roc/feature-loans-shared';
import { EditSubmissionButton } from '@roc/feature-edit-loans';
import { BorrowerLiquidityCard } from 'libs/feature-loan-details/src/loanDetails/components/cards/borrowerLiquidityCard';

const useStyles = makeStyles(theme => ({
  paper: {
    marginBottom: theme.spacing(2),
  },
  card: {
    background: theme.palette.background.default,
    borderColor: darken(theme.palette.background.default, 0.1),
  },
}));

const listItem = (label, value, icon = null, customTestId = null) => {
  const testId = sanitizeTestId(customTestId || label);
  return (
    <ListItem>
      <ListItemText>
        <Box display="flex">
          {icon && <Box ml={1}>{icon}</Box>}
          <Box flexGrow={1} mr={1}>
            {label}
          </Box>
          <TestDataAttribute id={testId}>
            <Box textAlign="right">{value}</Box>
          </TestDataAttribute>
        </Box>
      </ListItemText>
    </ListItem>
  );
};

const divider = <Divider variant="middle" component="li" />;

const formatPercentage = value =>
  `${value != null ? Number(value).toFixed(2) : ''}%`;

const formatDigit = (value, digits) => value?.toFixed(3, digits);

const getSwapIndexLabel = index => {
  if (index === US_TREASURY_5_YR_INDEX) return US_TREASURY_5_YR_DESC;
  else if (index === US_TREASURY_10_YR_INDEX) return US_TREASURY_10_YR_DESC;
}

export const LoanSummaryTerm = ({ data }) => {
  const classes = useStyles();
  const {
    loanId,
    amount,
    initialLoanAmount,
    status,
    loanType,
    loanSubType,
    borrowerNames,
    borrowerEntity,
    attorneyName,
    minNumber,
    interestReserve,
    brokerPointsIn,
    prepayPenaltyMonths,
    rocRate,
    rocPointsIn,
    pledgeOfShares,
    loanPurpose,
    interestReserveMonths,
    amortization,
    recourseStructure,
    rate,
    duration,
    aggregateLoanToAsIs,
    aggregateLoanToCurrentCost,
    totalOriginationFee,
    borrowerCashAtClose,
    interestOnlyMonths,
    interestPayment,
    rateType,
    originalAmortizationTerms,
    prepaymentDescription,
    originationSource,
    sfrLenderPointsIn,
    totalUnderwritingFee,
    rocUnderwritingFees,
    brokerLenderUnderwritingFees,
    lenderPremium,
    propertyCount,
    mostRecentValuation,
    totalCostBasis,
    totalDebtPayoffAmount,
    annualGrossRent,
    pitia,
    noi,
    netCashFlow,
    netDSCR,
    pitiaDSCR,
    loanPricingData = {},
    servicer,
    lenderOriginatorNames,
    lenderName,
    preAgreed,
    loanComparisonValues,
    calculatedLoanData,
    servicerId,
    loanLenderDetails,
    loanRetailFees,
    processingFee,
    rocLegalReviewFee,
    rocAdminFee,
    rocCommitmentFee,
    rocBuydownFee,
    fullySubmitted,
    freeAppraisalPromotion,
    firstServicerPaymentDueDate,
  } = data;

  const { push } = useHistory();

  const { loanRoutesConfig } = useRoutes();
  const { globalStore } = useBaseStore();
  const { loanStore } = useLoanStore();
  const [openCreateAppraisalModal, setOpenCreateAppraisalModal] = useState(
    false
  );

  const renderHeading = value => {
    return (
      <Typography
        variant="body1"
      >
        <strong>{value}</strong>
      </Typography>
    );
  };

  const isCreateAppraisalOrderAllowed = () =>
    !!loanStore.loanDetails?.enableAppraisalCreation && !loanStore.loanDetails?.submitAppraisalWithTamarisk && !loanStore.loanDetails?.arrEnabled;

  useEffect(() => {
    loanStore.setPropertiesData(data.properties);
    loanStore.propertiesGridStore.fetchGridData();
  }, []);

  const hasPricingData = !!loanPricingData.pricingDate;
  const isFundedOrPaidoffOrAgreedLoan = isFundedOrAgreedLoan(status);
  const showLoanExpirationDates = !isFundedLoan(status);

  let flag = false;
  let percentageLo;
  lenderOriginatorNames.map((lender, i) => { percentageLo = lender.percentage, flag = true });
  const { userStore } = useUserStore();
  const allowLoanPricing = userStore.allowLoanPricing;
  const isInternal = globalStore.lenderInfo.isInternal;

  const isLoanSubmissionEditable = loanStore.loanDetails?.status === LoanStatus.PENDING_SUBMISSION || loanStore.loanDetails?.status === LoanStatus.PENDING

  return (
    <Grid container spacing={2}>
      <LoanPrescreenBanner loanStore={loanStore} reloadLoan={() => loanStore.refreshLoanDetails()} />
      <LoanStatusChangeReasonBanner loan={data} />
      <Grid item container justifyContent="flex-end" spacing={1}>
        {isCreateAppraisalOrderAllowed() && (
          <Grid item>
            <Box display="flex" justifyContent="flex-end">
              <Button
                variant="contained"
                size="small"
                onClick={() => setOpenCreateAppraisalModal(true)}
                color="primary"
                testId="create_appraisal_order"
              >
                Create Appraisal Order
              </Button>
            </Box>
          </Grid>
        )}
        {allowLoanPricing && fullySubmitted && isLoanSubmissionEditable && loanSubType == LoanSubType.SINGLE_PROPERTY &&
          <Grid item>
            <EditSubmissionButton loanStore={loanStore} loanId={loanId} />
          </Grid>
        }
      </Grid>
      <Grid item xs={12} md={6}>
        <Paper title="LOAN TERMS" className={classes.paper}>
          {userStore.isBackOfficeUser && isPreagreedLoanStatus(status) &&
            <EditLoanButton loanStore={loanStore} data={data} />
          }
          <List>
            {listItem('Loan Type', loanType)}
            {divider}
            {listItem('Loan SubType', loanSubType)}
            {divider}
            {fullySubmitted && listItem(
              'Loan Status',
              <StatusChip
                statusType={StatusType.LOAN}
                label={status}
                variant="filled"
              />
            )}
            {divider}
            {listItem('Loan Amount', formatCurrency(amount))}
            {divider}
            {listItem(
              'Initial Loan Amount',
              <Typography
                variant="body2"
                color="textSecondary"
                component="p"
              >
                {formatCurrency(initialLoanAmount)}
              </Typography>,
              <Box flexGrow={1}>
                <SubdirectoryArrowRight fontSize="small" />
              </Box>
            )}
            {divider}
            {listItem('Loan Purpose', loanPurpose)}
            {divider}
            {listItem(
              'Borrower Cash Out at Close',
              formatCurrency(borrowerCashAtClose ?? 0)
            )}
            {divider}
            {listItem(
              'Monthly P&I Payment',
              formatCurrency(interestPayment ?? 0)
            )}
            {divider}
            {listItem(
              'P&I Reserves at close (Months)',
              interestReserveMonths
            )}
            {divider}
            {listItem('P&I Reserves at close', interestReserve)}
            {divider}
            {listItem('Attorney', attorneyName)}
            {isFundedOrPaidoffOrAgreedLoan &&
              divider}
            {isFundedOrPaidoffOrAgreedLoan &&
              listItem('Servicer', servicer)}
            {isFundedOrPaidoffOrAgreedLoan && listItem(
              'Servicer Id',
              <Typography variant="body2" color="textSecondary" component="p">
                {servicerId}
              </Typography>,
              <Box flexGrow={1}>
                <SubdirectoryArrowRight fontSize="small" />
              </Box>
            )
            }
            {isFundedOrPaidoffOrAgreedLoan && listItem(
              'First Payment Date',
              <Typography variant="body2" color="textSecondary" component="p">
                {firstServicerPaymentDueDate}
              </Typography>,
              <Box flexGrow={1}>
                <SubdirectoryArrowRight fontSize="small" />
              </Box>
            )
            }
            {isFundedOrPaidoffOrAgreedLoan && divider}
            {listItem('MERS MIN Number', minNumber)}
            {divider}
            {listItem('Loan Rate-Type', rateType)}
            {divider}
            {listItem('Amortization', amortization)}
            {divider}
            {listItem('Int Only Periods(Months)', interestOnlyMonths)}
            {divider}
            {listItem('Loan Guaranty', recourseStructure)}
            {divider}
            {listItem(
              'Pledge of Shares',
              <BooleanChip value={pledgeOfShares} />
            )}
            {divider}
            {allowLoanPricing && listItem('Note Interest Rate', formatPercentage(rate ?? 0))}
            {allowLoanPricing && divider}
            {allowLoanPricing && listItem(
              'Capital Provider Interest Rate',
              formatPercentage(rocRate ?? 0)
            )}
            {allowLoanPricing && divider}
            {listItem('Original Loan Terms (Months)', duration)}
            {divider}
            {listItem(
              'Orig. Amortization Terms (Months)',
              originalAmortizationTerms
            )}
            {divider}
            {listItem(
              'Prepayment Penalty Period (Months)',
              prepayPenaltyMonths
            )}
            {divider}
            {listItem('Prepayment Description', prepaymentDescription)}
            {listItem(
              'Loan LTV at Origination',
              formatPercentage(aggregateLoanToAsIs ?? 0)
            )}
            {divider}
            {listItem(
              'Loan LTC at Origination',
              formatPercentage(aggregateLoanToCurrentCost ?? 0)
            )}
            {divider}
            {listItem('Origination Source', originationSource)}
            {allowLoanPricing && divider}
            {allowLoanPricing &&
              listItem(
                'Total Origination Fee',
                formatPercentage(parseFloat(totalOriginationFee || 0) + parseFloat(loanRetailFees?.retailPoints || 0))
              )}
            {allowLoanPricing && divider}
            {allowLoanPricing && listItem(
              'Capital Provider Origination Fee',
              formatPercentage(parseFloat(rocPointsIn || 0) + parseFloat(loanRetailFees?.retailPoints || 0))
            )}
            {allowLoanPricing && divider}
            {allowLoanPricing && listItem(
              'Broker Origination Fee',
              formatPercentage(brokerPointsIn ?? 0)
            )}
            {allowLoanPricing && divider}
            {allowLoanPricing && listItem(
              'Lender Origination Fee',
              formatPercentage(sfrLenderPointsIn ?? 0)
            )}
            {allowLoanPricing && divider}
            {allowLoanPricing && listItem(
              'Total Underwriting Fee',
              formatCurrency(parseFloat(totalUnderwritingFee || 0) + parseFloat(loanRetailFees?.retailUnderwritingFee || 0))
            )}
            {allowLoanPricing && divider}
            {allowLoanPricing && listItem(
              'Capital Provider Underwriting Fee',
              formatCurrency(parseFloat(rocUnderwritingFees || 0) + parseFloat(loanRetailFees?.retailUnderwritingFee || 0))
            )}
            {allowLoanPricing && divider}
            {allowLoanPricing && listItem(
              'Broker/Lender Underwriting Fee',
              formatCurrency(brokerLenderUnderwritingFees ?? 0)
            )}
            {allowLoanPricing && divider}
            {allowLoanPricing && listItem('Lender Premium', formatPercentage(lenderPremium))}
            {allowLoanPricing && divider}
          </List>
        </Paper>
        <Paper>
          <BorrowerLiquidityCard/>
        </Paper>
      </Grid>
      <Grid item xs={12} md={6}>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={12}>
            <LoanSummaryLoanParticipantsSection loanData={data} loanStore={loanStore} disableEditButton={globalStore.userFeatures?.isLenderOriginator} />
          </Grid>
          <Grid item xs={12}>
            <Paper title="GENERAL STATS" className={classes.paper}>
              <Box>
                <List>
                  {listItem('Property Count', propertyCount)}
                  {divider}
                  {listItem(
                    'Most Recent Valuation',
                    formatCurrency(mostRecentValuation)
                  )}
                  {divider}
                  {listItem('Total Cost Basis', formatCurrency(totalCostBasis))}
                  {divider}
                  {listItem(
                    'Total Debt Payoff Amount',
                    formatCurrency(totalDebtPayoffAmount)
                  )}
                  {divider}
                  {listItem('Annual Gross Rent', formatCurrency(annualGrossRent))}
                  {divider}
                  {listItem('PITIA', formatCurrency(pitia))}
                  {divider}
                  {listItem('NOI', formatCurrency(noi))}
                  {divider}
                  {listItem('Net Cash Flow', formatCurrency(netCashFlow))}
                  {divider}
                  {listItem('NCF DSCR', formatDigit(netDSCR, 3))}
                  {divider}
                  {listItem('PITIA DSCR', formatDigit(pitiaDSCR, 3))}
                </List>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <LoanSummaryBrokerSection loanData={data} />
          </Grid>
          {showLoanExpirationDates ?
            <Grid item xs={12}>
              <Paper title="KEY DATES" className={classes.paper}>
                <Box>
                  <List>
                    {listItem('COGS Expiration Date', loanStore?.cogsExpirationDate)}
                    {divider}
                    {listItem('Credit Expiration Date', loanStore?.creditExpirationDate)}
                    {divider}
                    {listItem('Appraisal Expiration Date', loanStore?.appraisalExpirationDate)}
                  </List>
                </Box>
              </Paper>
            </Grid>
            : <Grid item xs={12}>
              <Paper title="KEY DATES" className={classes.paper}>
                <Box>
                  <List>
                    {listItem('Next Due Date', loanStore?.loanDetails.calculatedLoanData.nextDueDate)}
                  </List>
                </Box>
              </Paper>
            </Grid>}
          {globalStore.userFeatures?.addFacoOriginators && (
            <Grid item xs={12}>
              <Paper title="ORIGINATORS" className={classes.paper}>
                <Grid item>
                  <Box>
                    <List>
                      {listItem(
                        lenderName,
                        'Percentage: ' +
                        (flag ? 100 - percentageLo : 100) +
                        '%',
                        <Box flexGrow={1}>
                          <SubdirectoryArrowRight fontSize="small" />
                        </Box>
                      )}
                      {divider}
                      {lenderOriginatorNames.map((lender, i) => {
                        return (
                          <div key={lender}>
                            {listItem(
                              lender.lenderName,
                              'Percentage: ' + lender.percentage + '%',
                              <Box flexGrow={1}>
                                <SubdirectoryArrowRight fontSize="small" />
                              </Box>
                            )}
                            {divider}
                          </div>
                        );
                      })}
                      <br />
                      <Box mr={2} display="flex" justifyContent="flex-end">
                        <SubmitButton
                          hidden={false}
                          disabled={!preAgreed}
                          size="small"
                          variant="contained"
                          color="secondary"
                          testId="editLenderOriginator"
                          onCancelClick={() => {
                            loanStore.originatorStore.selectOriginatorsStore.reset();
                            loanStore.setExistingOriginatorsPercentage();
                          }}
                          onClick={() => loanStore.saveLenderOriginator()}
                          submitDialogProps={{
                            title: 'Edit Lender Originators',
                            body:
                              (loanStore.setExistingOriginatorsPercentage(),
                                (
                                  <LoanParticipants store={loanStore.selectOriginatorsStore} />
                                )),
                            okButtonText: 'Submit',
                          }}
                        >
                          Edit
                        </SubmitButton>
                      </Box>
                    </List>
                  </Box>
                </Grid>
              </Paper>
            </Grid>
          )}
        </Grid>
      </Grid>
      {allowLoanPricing && hasPricingData && (
        <Grid item xs={12}>
          <Paper
            title="PRICING AND RATE LOCK INFORMATION"
            className={classes.paper}
          >
            <Grid container spacing={4}>
              <Grid item xs={12} md={4}>
                <List>
                  {!isNil(loanPricingData.programType) && listItem(
                    'Program Type',
                    loanPricingData.programType
                  )}
                  {!isNil(loanPricingData.programType) && divider}
                  {listItem(
                    'Spread on Pricing Date',
                    !isNil(loanPricingData.spreadRate) &&
                    formatPercentage(loanPricingData.spreadRate)
                  )}
                  {divider}
                  {listItem(
                    'Indicative Gross Rate On Pricing Date',
                    !isNil(loanPricingData.grossRateOnPricingDate) &&
                    formatPercentage(loanPricingData.grossRateOnPricingDate)
                  )}
                  {divider}
                  {listItem(
                    'Pricing Date',
                    !isNil(loanPricingData.pricingDate) &&
                    formatDate(loanPricingData.pricingDate, 'MMMM d, yyyy')
                  )}
                  {divider}
                  {listItem(
                    'Pricing Index',
                    !isNil(loanPricingData.swapIndex) &&
                    `${getSwapIndexLabel(loanPricingData.swapIndex)}`
                  )}
                  {divider}
                  {listItem(
                    'Index Value On Pricing Date',
                    !isNil(loanPricingData.swapRateAtPricing) &&
                    formatPercentage(loanPricingData.swapRateAtPricing)
                  )}
                  {divider}
                  {listItem(
                    'Index Rate Floor',
                    !isNil(loanPricingData.swapRateAtOrigination) &&
                    formatPercentage(loanPricingData.swapRateAtOrigination)
                  )}
                  {divider}
                  {listItem(
                    'Current Index Value For Loan',
                    !isNil(loanPricingData.swapRate) &&
                    formatPercentage(loanPricingData.swapRate)
                  )}
                  {divider}
                  {listItem(
                    'Today’s Indicative Gross Rate',
                    loanPricingData?.useSpread
                      ? formatPercentage(
                        (
                          parseFloat(loanPricingData.swapRate) +
                          parseFloat(loanPricingData.spreadRate)
                        ).toFixed(4)
                      )
                      : null
                  )}
                </List>
              </Grid>
              <Grid item xs={12} md={4}>
                <List>
                  {listItem(
                    'Borrower Spread Lock',
                    !isNil(loanPricingData.borrowerSpreadLock) && (
                      <BooleanChip value={loanPricingData.borrowerSpreadLock} />
                    )
                  )}
                  {divider}
                  {listItem(
                    'Borrower Gross Rate Lock Expiry Date',
                    !isNil(loanPricingData.borrowerGrossRateLockExpiryDate) &&
                    formatDate(
                      loanPricingData.borrowerGrossRateLockExpiryDate,
                      'MMMM d, yyyy'
                    )
                  )}
                  {divider}
                  {listItem(
                    'Borrower Gross Rate Lock',
                    isNil(loanPricingData.borrowerGrossRateLock) && (
                      <BooleanChip
                        value={loanPricingData.borrowerGrossRateLock}
                      />
                    )
                  )}
                  {divider}
                  {listItem(
                    'Borrower Spread Lock Expiry Date',
                    !isNil(loanPricingData.borrowerSpreadLockExpiryDate) &&
                    formatDate(
                      loanPricingData.borrowerSpreadLockExpiryDate,
                      'MMMM d, yyyy'
                    )
                  )}
                </List>
              </Grid>
              <Grid item xs={12} md={4}>
                <List>
                  {listItem(
                    'Borrower Rate Lock Extension 1 Start Date',
                    !isNil(loanPricingData.firstPaidBorrowerGrossRateLockStartDate) &&
                    formatDate(
                      loanPricingData.firstPaidBorrowerGrossRateLockStartDate,
                      'MMMM d, yyyy'
                    )
                  )}
                  {divider}
                  {listItem(
                    'Borrower Rate Lock Extension 1 Expiry Date',
                    !isNil(loanPricingData.firstPaidBorrowerGrossRateLockExpiryDate) &&
                    formatDate(
                      loanPricingData.firstPaidBorrowerGrossRateLockExpiryDate,
                      'MMMM d, yyyy'
                    )
                  )}
                  {divider}
                  {listItem(
                    'Borrower Rate Lock Extension 2 Start Date',
                    !isNil(loanPricingData.secondPaidBorrowerGrossRateLockStartDate) &&
                    formatDate(
                      loanPricingData.secondPaidBorrowerGrossRateLockStartDate,
                      'MMMM d, yyyy'
                    )
                  )}
                  {divider}
                  {listItem(
                    'Borrower Rate Lock Extension 2 Expiry Date',
                    !isNil(loanPricingData.secondPaidBorrowerGrossRateLockExpiryDate) &&
                    formatDate(
                      loanPricingData.secondPaidBorrowerGrossRateLockExpiryDate,
                      'MMMM d, yyyy'
                    )
                  )}
                </List>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      )}
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
              <TestDataAttribute id="entity">
                <EntityCard title="ENTITY" entity={borrowerEntity} />
              </TestDataAttribute>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Paper title="BORROWERS" style={{ boxShadow: 'none' }}>
                <Box>
                  <List>
                    {borrowerNames.map((name, i) => {
                      return (
                        <div key={name}>
                          {listItem(null, name, '', 'borrower')}
                          {divider}
                        </div>
                      );
                    })}
                    <br />
                    <Grid container justifyContent="flex-end" spacing={2}>
                      <Grid item>
                        <Button
                          size="small"
                          variant="contained"
                          color="secondary"
                          onClick={() =>
                            push(
                              loanRoutesConfig.loans(loanId.toString()).children
                                .borrowerEntity.url
                            )
                          }
                          testId="moreBorrowerInformation"
                        >
                          More information
                        </Button>
                      </Grid>
                      <Grid item>
                        {loanStore.allowEditBorrowers &&
                          <Box mr={2}>
                            <SubmitButton
                              size="small"
                              variant="contained"
                              color="secondary"
                              testId="editBorrowers"
                              onCancelClick={() => {
                                loanStore.selectBorrowersStore.reset();
                                loanStore.setExistingBorrowers();
                              }}
                              onClick={() => loanStore.saveBorrowers()}
                              submitDialogProps={{
                                title: 'Edit Borrowers',
                                body: <EditBorrowersForm store={loanStore} />,
                                okButtonText: 'Submit',
                              }}
                            >
                              Edit
                            </SubmitButton>
                          </Box>
                        }
                      </Grid>
                    </Grid>
                  </List>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper title="PROPERTIES" className={classes.paper}>
          <Layout>
            <TestDataAttribute id="property">
              <TermPropertiesGrid />
            </TestDataAttribute>
          </Layout>
        </Paper>
      </Grid>
      {allowLoanPricing &&
        <Grid item xs={12}>
          <Paper title="POINTS & FEES" className={classes.paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell align='right'>
                    {renderHeading('Rate Buydown')}
                  </TableCell>
                  <TableCell align='right'>
                    {isInternal ? renderHeading('Add-On') : renderHeading('Your Fees')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>{renderHeading('Points')}</TableCell>
                  <TestDataAttribute id='roc_points_in'>
                    <TableCell align='right'>{formatPercentage(rocPointsIn ?? 0)}</TableCell>
                  </TestDataAttribute>
                  {isInternal ? (
                    <TestDataAttribute id='retail_points'>
                      <TableCell align='right'>{formatPercentage(loanRetailFees?.retailPoints || 0)}</TableCell>
                    </TestDataAttribute>
                  ) : (
                    <TestDataAttribute id='lender_points'>
                      <TableCell align='right'>{formatPercentage(sfrLenderPointsIn || 0)}</TableCell>
                    </TestDataAttribute>
                  )}
                </TableRow>
                <TableRow>
                  <TableCell>{renderHeading('Processing Fee')}</TableCell>
                  <TestDataAttribute id='roc_processing'>
                    <TableCell align='right'>{formatCurrency(processingFee ?? 0)}</TableCell>
                  </TestDataAttribute>
                  {isInternal ? (
                    <TestDataAttribute id='retail_processing'>
                      <TableCell align='right'>{formatCurrency(loanRetailFees?.retailProcessingFee || 0)}</TableCell>
                    </TestDataAttribute>
                  ) : (
                    <TestDataAttribute id='lender_processing'>
                      <TableCell align='right'>{formatCurrency(loanLenderDetails.processingFee || 0)}</TableCell>
                    </TestDataAttribute>
                  )}
                </TableRow>
                <TableRow>
                  <TableCell>{renderHeading('Admin Fee')}</TableCell>
                  <TestDataAttribute id='roc_admin'>
                    <TableCell align='right'>{formatCurrency(rocAdminFee ?? 0)}</TableCell>
                  </TestDataAttribute>

                  {isInternal ? (
                    <TestDataAttribute id='retail_admin'>
                      <TableCell align='right'>{formatCurrency(loanRetailFees?.retailAdminFee || 0)}</TableCell>
                    </TestDataAttribute>
                  ) : (
                    <TestDataAttribute id='lender_admin'>
                      <TableCell align='right'>{formatCurrency(loanLenderDetails.adminFee || 0)}</TableCell>
                    </TestDataAttribute>
                  )}
                </TableRow>
                <TableRow>
                  <TableCell>{renderHeading('Underwriting Fee')}</TableCell>
                  <TestDataAttribute id='roc_underwriting'>
                    <TableCell align='right'>{formatCurrency(rocUnderwritingFees ?? 0)}</TableCell>
                  </TestDataAttribute>
                  {isInternal ? (
                    <TestDataAttribute id='retail_underwriting'>
                      <TableCell align='right'>{formatCurrency(loanRetailFees?.retailUnderwritingFee || 0)}</TableCell>
                    </TestDataAttribute>
                  ) : (
                    <TestDataAttribute id='lender_underwriting'>
                      <TableCell align='right'>{formatCurrency(loanLenderDetails.underwritingFee || 0)}</TableCell>
                    </TestDataAttribute>
                  )}
                </TableRow>
                <TableRow>
                  <TableCell>{renderHeading('Commitment Fee')}</TableCell>
                  <TestDataAttribute id='roc_commitment'>
                    <TableCell align='right'>{formatCurrency(rocCommitmentFee ?? 0)}</TableCell>
                  </TestDataAttribute>
                  {isInternal ? (
                    <TestDataAttribute id='retail_commitment'>
                      <TableCell align='right'>{formatCurrency(loanRetailFees?.retailCommitmentFee || 0)}</TableCell>
                    </TestDataAttribute>
                  ) : (
                    <TestDataAttribute id='lender_commitment'>
                      <TableCell align='right'>{formatCurrency(loanLenderDetails.commitmentFee || 0)}</TableCell>
                    </TestDataAttribute>
                  )}
                </TableRow>
                <TableRow>
                  <TableCell>{renderHeading('Buydown Fee')}</TableCell>
                  <TestDataAttribute id='roc_buydown'>
                    <TableCell align='right'>{formatCurrency(rocBuydownFee ?? 0)}</TableCell>
                  </TestDataAttribute>
                  {isInternal ? (
                    <TestDataAttribute id='retail_buydown'>
                      <TableCell align='right'>{formatCurrency(loanRetailFees?.retailBuydownFee || 0)}</TableCell>
                    </TestDataAttribute>
                  ) : (
                    <TestDataAttribute id='lender_buydown'>
                      <TableCell align='right'>{formatCurrency(loanLenderDetails?.lenderBuydownFee || 0)}</TableCell>
                    </TestDataAttribute>
                  )}
                </TableRow>
              </TableBody>
            </Table>
          </Paper>
        </Grid>
      }
      <Grid item xs={12}>
        <Paper
          title='SUBMISSION VALUES VS. UNDERWRITING VALUES'
          className={classes.paper}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell align='right'>
                  {renderHeading('Submission')}
                </TableCell>
                <TableCell align='right'>
                  {renderHeading('Underwriting')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{renderHeading('Borrower FICO ')}</TableCell>
                <TestDataAttribute id='submitted_borrower_fico'>
                  <TableCell align='right'>{loanComparisonValues.submittedBorrowerFICO ? loanComparisonValues.submittedBorrowerFICO : "-"}</TableCell>
                </TestDataAttribute>
                <TestDataAttribute id='underwriting_borrower_fico'>
                  <TableCell align='right'>{calculatedLoanData.maxBorrowerFICOScore ? calculatedLoanData.maxBorrowerFICOScore : "-"}</TableCell>
                </TestDataAttribute>
              </TableRow>
              <TableRow>
                <TableCell>{renderHeading('Purchase price')}</TableCell>
                <TestDataAttribute id='submitted_purchase_price'>
                  <TableCell align='right'>{loanComparisonValues.submittedPurchasePrice ? formatCurrency(loanComparisonValues.submittedPurchasePrice) : "-"}</TableCell>
                </TestDataAttribute>
                <TestDataAttribute id='underwriting_purchase_price'>
                  <TableCell align='right'>{formatCurrency(calculatedLoanData.aggregateAcquisitionPrice)}</TableCell>
                </TestDataAttribute>
              </TableRow>
              <TableRow>
                <TableCell>{renderHeading('As-is value')}</TableCell>
                <TestDataAttribute id='submitted_as_is_value'>
                  <TableCell align='right'>{loanComparisonValues.submittedAsIsValue ? formatCurrency(loanComparisonValues.submittedAsIsValue) : "-"}</TableCell>
                </TestDataAttribute>
                <TestDataAttribute id='underwriting_as_is_value'>
                  <TableCell align='right'>{formatCurrency(calculatedLoanData.aggregateAppraisedAsIsValue)}</TableCell>
                </TestDataAttribute>
              </TableRow>
              <TableRow>
                <TableCell>{renderHeading('Monthly Gross Rent')}</TableCell>
                <TestDataAttribute id='submitted_monthly_gross_rent'>
                  <TableCell align='right'>{loanComparisonValues.submittedMonthlyGrossRent ? formatCurrency(loanComparisonValues.submittedMonthlyGrossRent) : "-"}</TableCell>
                </TestDataAttribute>
                <TestDataAttribute id='underwriting_monthly_gross_rent'>
                  <TableCell align='right'>{formatCurrency(calculatedLoanData.aggregateMonthlyGrossRent)}</TableCell>
                </TestDataAttribute>
              </TableRow>
              <TableRow>
                <TableCell>{renderHeading('Monthly Market Rent')}</TableCell>
                <TestDataAttribute id='submitted_monthly_market_rent'>
                  <TableCell align='right'>{loanComparisonValues.submittedMonthlyMarketRent ? formatCurrency(loanComparisonValues.submittedMonthlyMarketRent) : "-"}</TableCell>
                </TestDataAttribute>
                <TestDataAttribute id='underwriting_monthly_market_rent'>
                  <TableCell align='right'>{formatCurrency(calculatedLoanData.aggregateMonthlyMarketRent)}</TableCell>
                </TestDataAttribute>
              </TableRow>
              <TableRow>
                <TableCell>{renderHeading('Annual Taxes')}</TableCell>
                <TestDataAttribute id='submitted_annual_taxes'>
                  <TableCell align='right'>{loanComparisonValues.submittedAnnualTaxes ? formatCurrency(loanComparisonValues.submittedAnnualTaxes) : "-"}</TableCell>
                </TestDataAttribute>
                <TestDataAttribute id='underwriting_annual_taxes'>
                  <TableCell align='right'>{formatCurrency(calculatedLoanData.aggregateAnnualTaxes)}</TableCell>
                </TestDataAttribute>
              </TableRow>
              <TableRow>
                <TableCell>{renderHeading('Annual Insurance')}</TableCell>
                <TestDataAttribute id='submitted_annual_insurance'>
                  <TableCell align='right'>{loanComparisonValues.submittedAnnualInsurance ? formatCurrency(loanComparisonValues.submittedAnnualInsurance) : "-"}</TableCell>
                </TestDataAttribute>
                <TestDataAttribute id='underwriting_annual_insurance'>
                  <TableCell align='right'>{formatCurrency(calculatedLoanData.aggregateAnnualInsurance)}</TableCell>
                </TestDataAttribute>
              </TableRow>
              <TableRow>
                <TableCell>{renderHeading('Annual HOA Fees ')}</TableCell>
                <TestDataAttribute id='submitted_annual_hoa_fees'>
                  <TableCell align='right'>{loanComparisonValues.submittedHOAFees ? formatCurrency(loanComparisonValues.submittedHOAFees) : "-"}</TableCell>
                </TestDataAttribute>
                <TestDataAttribute id='underwriting_annual_hoa_fees'>
                  <TableCell align='right'>{formatCurrency(calculatedLoanData.aggregateAnnualHOAFees)}</TableCell>
                </TestDataAttribute>
              </TableRow>
            </TableBody>
          </Table>
        </Paper>
      </Grid>
      <StandardDialog
        open={openCreateAppraisalModal}
        title="Create Appraisal Order"
        maxWidth="md"
        handleClose={() => setOpenCreateAppraisalModal(false)}
        dialogContent={
          <CreateAppraisalForm
            onConfirm={(gfdPayment: boolean) => {
              setOpenCreateAppraisalModal(false);
              loanStore.submitLoanAppraisalOrder(loanId, gfdPayment);
            }}
            onClose={() => setOpenCreateAppraisalModal(false)}
          />
        }
      />
    </Grid>
  );
};
