import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { format, isValid } from 'date-fns';
import { createDateField, createNumberFormatField, createPhoneField, createSelectField, createTextField, DateField, TextField } from '@roc/ui';
import { citizenshipStatusOptions } from '@roc/feature-utils';
import { Address } from './address';

const useStyles = makeStyles(() => {
  return {
    container: {
      display: 'flex',
      position: 'relative',
      maxWidth: 1300,
      margin: 'auto',
    },
  };
});

export const PersonalInformation = observer(({ store }) => {
  const classes = useStyles();
  return (
    <Grid container spacing={2} className={classes.container}>
      <PersonalDetails store={store.personalInformationFormStore} disableNameFields={store.disableNameFields} showDob={store.showDob} showSsn={store.showSsn} />
      <Grid item xs={12} />
      <Address store={store.personalInformationFormStore} />
    </Grid>
  );
});

export const PersonalDetails = observer(({ store, disableNameFields, showDob = true, showSsn = true }) => {

  const manageDate = (fieldName, date) => {
    let formattedDate = null;
    if (isValid(date)) {
      formattedDate = format(date, 'MM/dd/yyyy');
    }
    store.onFieldChange(fieldName, formattedDate);
  };

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h5">Personal Details</Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        {createTextField({
          label: 'First Name',
          fieldName: 'firstName',
          testId: 'firstName',
          store: store,
          disabled: disableNameFields,
        })}
      </Grid>
      <Grid item xs={12} sm={6}>
        {createTextField({
          label: 'Middle Name',
          fieldName: 'middleName',
          testId: 'middleName',
          store: store,
          disabled: disableNameFields,
        })}
      </Grid>
      <Grid item xs={12} sm={6}>
        {createTextField({
          label: 'Last Name',
          fieldName: 'lastName',
          testId: 'lastName',
          store: store,
          disabled: disableNameFields,
        })}
      </Grid>
      <Grid item xs={12} sm={6}>
        {createPhoneField({
          label: 'Phone Number',
          fieldName: 'phoneNumber',
          testId: 'phoneNumber',
          store: store,
        })}
      </Grid>
      {showDob ?
        <Grid item xs={12} sm={6}>
          {createDateField({
            label: 'Date of Birth',
            fieldName: 'dateOfBirth',
            testId: 'dateOfBirth',
            format: 'MM/dd/yyyy',
            store: store,
            disableFuture: true,
            onChange: manageDate,
          })}
        </Grid>
        :
        <Grid item xs={12} sm={6}>
          <DateField
            label='Date of Birth'
            standaloneLabel
            inputVariant="outlined"
            placeholder='**********'
            value={null}
            onChange={() => { }}
            required
            format="MM/dd/yyyy"
            testId="dateOfBirth"
            disabled={true}
            fullWidth
          />
        </Grid>
      }
      <Grid item xs={12} sm={6}>
        {createSelectField({
          store: store,
          testId: 'citizenshipStatus',
          fieldName: 'citizenshipStatus',
          label: 'Citizenship Status',
          options: citizenshipStatusOptions,
          placeholder: 'Make Selection',
        })}
      </Grid>
      {showSsn ?
        <Grid item xs={12} sm={6}>
          {createNumberFormatField({
            label: 'Social Security Number',
            fieldName: 'ssn',
            format: "###-##-####",
            testId: 'ssn',
            store: store,
          })}
        </Grid>
        :
        <Grid item xs={12} sm={6}>
          <TextField
            label='Social Security Number'
            standaloneLabel
            type="text"
            variant="outlined"
            value={'***********'}
            required
            testId="ssn"
            disabled={true}
            fullWidth
          />
        </Grid>
      }
    </>
  )
});