import { GlobalStore } from '@roc/client-portal-shared/stores';
import { FormStore, UserStore } from '@roc/feature-app-core';
import { LoanParticipantsStore } from '@roc/feature-loan-participants';
import { makeObservable, flow, observable, computed, action } from 'mobx';
import { FixFlipSizerService } from '../services/fixFlipSizerService';
import { propertyOwnership } from '../utils/constants';
import {
  buildFormDataFromSizer,
  buildSizerFromFormValues,
} from '../utils/sizerHelper';

const requiredIfPurchased = [
  { required_if: ['acquisition', propertyOwnership.PURCHASED] },
];
const requiredIfOwned = [
  { required_if: ['acquisition', propertyOwnership.OWNED_BY_BORROWER] },
];

const REQUIRED_MESSAGE = 'This field is required';

//Step 1: Property and Project
const propertyAndProjectForm = {
  fields: {
    address: {
      value: '',
      error: null,
      rule: '',
    },
    acquisition: {
      value: '',
      error: null,
      rule: 'required',
      message: REQUIRED_MESSAGE,
    },
    purchasePrice: {
      value: '',
      error: null,
      rule: 'required',
      message: REQUIRED_MESSAGE,
    },
    rehabToCompleteProject: {
      value: '',
      error: null,
      rule: 'required',
      message: REQUIRED_MESSAGE,
    },
    purchaseDate: {
      value: '',
      error: null,
      rule: '',
    },
    rehabToDate: {
      value: '',
      error: null,
      rule: requiredIfOwned,
      message: REQUIRED_MESSAGE,
    },
    loanOutstanding: {
      value: '',
      error: null,
      rule: '',
    },
    currentLender: {
      value: '',
      error: null,
      rule: '',
    },
    everBeenInDefault: {
      value: '',
      error: null,
      rule: '',
    },
    principalBalance: {
      value: '',
      error: null,
      rule: '',
    },
    sumOfLoanFees: {
      value: '',
      error: null,
      rule: '',
    },
    asIsValue: {
      value: '',
      error: null,
      rule: '',
    },
    afterRepairValue: {
      value: '',
      error: null,
      rule: 'required',
      message: REQUIRED_MESSAGE,
    },
    projectDescription: {
      value: '',
      error: null,
      rule: '',
    },
    renovationConstruction: {
      value: '',
      error: null,
      rule: '',
    },
    addingSquareFootage: {
      value: '',
      error: null,
      rule: '',
    },
    lenderOriginatorId: {
      value: null,
      error: null,
      rule: '',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

//Step 2: Borrowers & Loan Terms
const borrowersAndLoanTermsForm = {
  fields: {
    credit: {
      value: '',
      error: null,
      rule: 'required',
      message: REQUIRED_MESSAGE,
    },
    netWorth: {
      value: '',
      error: null,
      rule: 'required',
      message: REQUIRED_MESSAGE,
    },
    experienceScore: {
      value: 0,
      error: null,
      rule: '',
    },
    experienceDescription: {
      value: '',
      error: null,
      rule: '',
    },
    initialLoanAmount: {
      value: 0,
      error: null,
      rule: '',
    },
    initialLoanAmountCurrency: {
      value: '',
      error: null,
      rule: '',
    },
    constructionHoldback: {
      value: 0,
      error: null,
      rule: '',
    },
    constructionHoldbackCurrency: {
      value: '',
      error: null,
      rule: '',
    },
    interestRate: {
      value: '',
      error: null,
      rule: 'required',
      message: REQUIRED_MESSAGE,
    },
    pointsIn: {
      value: '',
      error: null,
      rule: 'required',
      message: REQUIRED_MESSAGE,
    },
    pointsOut: {
      value: '',
      error: null,
      rule: 'required',
      message: REQUIRED_MESSAGE,
    },
    termInMonths: {
      value: '',
      error: null,
      rule: '',
    },
    interestReserveMonths: {
      value: '',
      error: null,
      rule: 'required',
      message: REQUIRED_MESSAGE,
    },
    pledgeOfShares: {
      value: '',
      error: null,
      rule: '',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

//Step 3: Borrowers & Loan Terms
const summaryTaxesAndFeesForm = {
  fields: {
    mortgageTaxPercent: {
      value: 0,
      error: null,
      rule: 'required',
      message: REQUIRED_MESSAGE,
    },
    transferTaxPercent: {
      value: 1,
      error: null,
      rule: 'required',
      message: REQUIRED_MESSAGE,
    },
    mansionTaxPercent: {
      value: 0,
      error: null,
      rule: 'required',
      message: REQUIRED_MESSAGE,
    },
    titleInsurancePercent: {
      value: 1,
      error: null,
      rule: 'required',
      message: REQUIRED_MESSAGE,
    },
    brokerFeePercent: {
      value: 4,
      error: null,
      rule: 'required',
      message: REQUIRED_MESSAGE,
    },
    propertyTaxAndMaintenancePercent: {
      value: 1,
      error: null,
      rule: 'required',
      message: REQUIRED_MESSAGE,
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

class FixFlipSizerDetailsStore {
  sizerId: string;

  globalStore: GlobalStore;
  userStore: UserStore;
  sizerService: FixFlipSizerService;

  propertyAndProjectForm: FormStore;
  borrowersAndLoanTermsForm: FormStore;
  summaryTaxesAndFeesForm: FormStore;
  loanParticipantsStore: LoanParticipantsStore;

  sizer: any;

  activeStep: number = 0;
  showSuccess: boolean;

  constructor(globalStore, userStore) {
    this.globalStore = globalStore;
    this.userStore = userStore;
    this.sizerService = new FixFlipSizerService();
    this.propertyAndProjectForm = new FormStore(
      propertyAndProjectForm,
      globalStore
    );
    this.borrowersAndLoanTermsForm = new FormStore(
      borrowersAndLoanTermsForm,
      globalStore
    );
    this.summaryTaxesAndFeesForm = new FormStore(
      summaryTaxesAndFeesForm,
      globalStore
    );
    this.loanParticipantsStore = new LoanParticipantsStore(globalStore);
    makeObservable(this, {
      sizerId: observable,
      sizer: observable,
      activeStep: observable,
      showSuccess: observable,
      goNextStep: action,
      goPrevStep: action,
      moveToStep: action,
      calculateSizer: action,
      calculateInitialLoanAmountCurrency: action,
      calculateInitialLoanAmountPercentage: action,
      calculateConstructionHoldbackCurrency: action,
      calculateConstructionHoldbackPercentage: action,
      initForm: flow,
      saveSizer: flow,
      sendEmail: flow,
      loadParticipants: flow,
    });
  }

  *initForm(sizerId: string) {
    this.resetStore();
    yield this.loadParticipants(sizerId);
    if (sizerId) {
      try {
        const response = yield this.sizerService.getSizerById(sizerId);
        const sizer = response.data.data;

        const {
          propertyAndProjectFormData,
          borrowersAndLoanTermsFormData,
          summaryTaxesAndFeesFormData,
        } = buildFormDataFromSizer(sizer);

        this.sizerId = sizerId;
        this.sizer = sizer;

        this.propertyAndProjectForm.loadForm(propertyAndProjectFormData);
        this.borrowersAndLoanTermsForm.loadForm(borrowersAndLoanTermsFormData);
        this.summaryTaxesAndFeesForm.loadForm(summaryTaxesAndFeesFormData);
      } catch (error) {
        this.globalStore.notificationStore.showErrorNotification({
          message: `Error while retrieving the fix & flip sizer data`,
        });
      }
    }
  }

  *loadParticipants(sizerId) {
    yield this.loanParticipantsStore.fetchLoanParticipants();
    if (!sizerId) {
      if (this.globalStore.userFeatures?.isLenderOriginator) {
        this.propertyAndProjectForm.onFieldChange(
          'lenderOriginatorId',
          this.userStore.userInformation.userId
        );
      }
    }
  }

  *saveSizer() {
    try {
      if (this.areFormsValid()) {
        const response = yield this.sizerService.saveSizer({
          ...this.sizer,
          sizerRequestId: this.sizerId,
        });
        if (!this.sizerId) {
          const newSizerRequestId = response.data.data.draftLoanId;
          this.sizerId = newSizerRequestId;
          this.showSuccess = true;
        } else {
          this.globalStore.notificationStore.showSuccessNotification({
            message: `Fix & flip sizer submitted successfully`,
          });
        }
      }
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: `Error while submitting fix & flip sizer`,
      });
    }
  }

  closeSuccessDialog() {
    this.showSuccess = false;
  }

  goNextStep() {
    this.moveToStep(this.activeStep + 1);
  }

  goPrevStep() {
    this.moveToStep(this.activeStep - 1);
  }

  calculateSizer() {
    this.sizer = buildSizerFromFormValues(this.sizerId, this.getFormsValues());
  }

  moveToStep(nextStep: number) {
    if (this.activeStep == 0) {
      this.propertyAndProjectForm.runFormValidationWithMessage();
      if (!this.propertyAndProjectForm.form.meta.isValid) {
        return;
      }
    }
    if (this.activeStep == 1) {
      this.borrowersAndLoanTermsForm.runFormValidationWithMessage();
      if (!this.borrowersAndLoanTermsForm.form.meta.isValid) {
        return;
      }
    }

    if (nextStep == 2) {
      this.calculateSizer();
    }

    this.activeStep = nextStep;
  }

  calculateInitialLoanAmountCurrency() {
    const { initialLoanAmount } = this.borrowersAndLoanTermsForm.form.fields;

    const purchasePrice = this.calculatePurchasePrice();
    const value = purchasePrice * (initialLoanAmount.value / 100);

    this.borrowersAndLoanTermsForm.onFieldChange(
      'initialLoanAmountCurrency',
      value
    );
  }

  calculateInitialLoanAmountPercentage() {
    const {
      initialLoanAmountCurrency,
    } = this.borrowersAndLoanTermsForm.form.fields;

    const purchasePrice = this.calculatePurchasePrice();
    const percentage = (initialLoanAmountCurrency.value * 100) / purchasePrice;

    this.borrowersAndLoanTermsForm.onFieldChange(
      'initialLoanAmount',
      Math.round(percentage)
    );
  }

  calculateConstructionHoldbackCurrency() {
    const { constructionHoldback } = this.borrowersAndLoanTermsForm.form.fields;

    const renovationBudget = this.calculateTotalRenovationBudget();
    const value = renovationBudget * (constructionHoldback.value / 100);

    this.borrowersAndLoanTermsForm.onFieldChange(
      'constructionHoldbackCurrency',
      value
    );
  }

  calculateConstructionHoldbackPercentage() {
    const {
      constructionHoldbackCurrency,
    } = this.borrowersAndLoanTermsForm.form.fields;

    const renovationBudget = this.calculateTotalRenovationBudget();
    const percentage =
      (constructionHoldbackCurrency.value * 100) / renovationBudget;

    this.borrowersAndLoanTermsForm.onFieldChange(
      'constructionHoldback',
      Math.round(percentage)
    );
  }

  *sendEmail(pdfBlob: Blob) {
    try {
      if (this.areFormsValid()) {
        const { address } = this.propertyAndProjectForm.form.fields;
        const formData = new FormData();
        formData.append('file', pdfBlob);
        formData.append('propertyAddress', address.value);
        yield this.sizerService.emailSizer(formData);
        this.globalStore.notificationStore.showSuccessNotification({
          message: `Email sent successfully`,
        });
      }
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: `Error while sending email`,
      });
    }
  }

  private calculatePurchasePrice = () => {
    const {
      acquisition,
      purchasePrice,
      rehabToDate,
    } = this.propertyAndProjectForm.form.fields;
    return acquisition.value === propertyOwnership.PURCHASED
      ? purchasePrice.value
      : purchasePrice.value + rehabToDate.value;
  };

  private calculateTotalRenovationBudget = () => {
    const { rehabToCompleteProject } = this.propertyAndProjectForm.form.fields;
    return rehabToCompleteProject.value;
  };

  private getFormsValues() {
    return {
      ...this.borrowersAndLoanTermsForm.getFormValues(),
      ...this.propertyAndProjectForm.getFormValues(),
      ...this.summaryTaxesAndFeesForm.getFormValues(),
    };
  }

  private resetStore() {
    this.propertyAndProjectForm.reset();
    this.borrowersAndLoanTermsForm.reset();
    this.summaryTaxesAndFeesForm.reset();
    this.activeStep = 0;
    this.sizer = null;
    this.sizerId = null;
  }

  private areFormsValid() {
    this.propertyAndProjectForm.runFormValidationWithMessage();
    this.borrowersAndLoanTermsForm.runFormValidationWithMessage();
    this.summaryTaxesAndFeesForm.runFormValidationWithMessage();

    return (
      this.propertyAndProjectForm.form.meta.isValid &&
      this.borrowersAndLoanTermsForm.form.meta.isValid &&
      this.summaryTaxesAndFeesForm.form.meta.isValid
    );
  }
}

export default FixFlipSizerDetailsStore;
