import { addQueryParam } from "@roc/client-portal-shared/utils";
import { ScopeOfWorkV2FormService } from "@roc/feature-sow-shared/services";

const baseUrl = '/nportal/rest/pub/draws';
const exportBaseUrl = '/nportal/rest/pub/scopeOfWork';

const url = {
  SUBMIT_SCOPE_OF_WORK: `${baseUrl}/submitSowRevision`,
  GET_SCOPE_OF_WORK_CATEGORIES: `${baseUrl}/getScopeOfWorkCategories`,
  GET_SCOPE_OF_WORK_REVISION: `${baseUrl}/getSowRevisionById`,
  EXPORT_SCOPE_OF_WORK_REVISION: `${exportBaseUrl}/exportScopeOfWorkExternalV2`,
  GET_SPLITTED_CATEGORIES: `${exportBaseUrl}/getScopeOfWorkSplitedCategoriesRevision`,
  GET_SOW_ERROR_MESSAGES: `${exportBaseUrl}/getSowSubmissionErrors`
};

export class ScopeOfWorkRevisionFormService extends ScopeOfWorkV2FormService {
  constructor() {
    super();
    this.url = url;
  }

  getSplitedCategories(drawId: string) {
    return this.get(`${url.GET_SPLITTED_CATEGORIES}?drawId=${drawId}`);
  }

  getScopeOfWorkById(drawId: string) {
    const params = addQueryParam('', 'drawId', drawId);
    return this.get(
      `${this.url.GET_SCOPE_OF_WORK_REVISION}${params ? `?${params}` : ''}`
    );
  }

  submitScopeOfWork(drawId: string, username: string, data: any) {
    let params = addQueryParam('', 'drawId', drawId);
    params = addQueryParam(params, 'username', username);
    return this.post(
      `${this.url.SUBMIT_SCOPE_OF_WORK}?${params}`,
      data,
      {},
    );
  }

  exportScopeOfWork(data: any) {
    return this.post(
      `${url.EXPORT_SCOPE_OF_WORK_REVISION}`,
      data,
      null,
      {
        responseType: 'arraybuffer',
      }
    );
  }

  getSowSubmissionErrors(loanId: string, propertyId: any, data: any) {
    return this.post(`${url.GET_SOW_ERROR_MESSAGES}?loanId=${loanId}&propertyId=${propertyId}`, data)
  }

}