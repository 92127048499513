import { Page } from '@roc/feature-app-core';
import { Redirect, Route } from 'react-router';
import { BrowseAppraisals } from '../browseAppraisals';
import { FindAppraiser } from '../findAppraiser';
import { SubmitAppraisal } from '../submitAppraisal';
import { useSubmitAppraisalStore } from '../hooks';

const APPRAISALS_BASE_PATH = 'appraisals';
const CONVERSATION_ID_PATH = ':conversationId';

export const getAppraisalsRoutesConfig = (
  basePath: string,
  baseUrl: string
) => {
  return {
    appraisals: {
      path: `${basePath}/${APPRAISALS_BASE_PATH}`,
      url: `${baseUrl}/${APPRAISALS_BASE_PATH}`,
      documentTitle: 'Appraisals',
      children: {
        browseAppraisals: {
          path: `${basePath}/${APPRAISALS_BASE_PATH}/browse`,
          url: `${baseUrl}/${APPRAISALS_BASE_PATH}/browse`,
          documentTitle: 'View Appraisals',
        },
        conversation: (conversationId?: string) => ({
          path: `${basePath}/${APPRAISALS_BASE_PATH}/conversations/${CONVERSATION_ID_PATH}`,
          url: `${baseUrl}/${APPRAISALS_BASE_PATH}/conversations/${conversationId}`,
          documentTitle: `Appraisal Conversation - ${conversationId}`,
        }),
        findAppraiser: {
          path: `${basePath}/${APPRAISALS_BASE_PATH}/find-appraiser`,
          url: `${baseUrl}/${APPRAISALS_BASE_PATH}/find-appraiser`,
          documentTitle: 'Find Appraiser',
        },
        submitAppraisal: {
          path: `${basePath}/${APPRAISALS_BASE_PATH}/submit`,
          url: `${baseUrl}/${APPRAISALS_BASE_PATH}/submit`,
          documentTitle: 'Order Appraisal',
        },
      },
    },
  };
};

export const getAppraisalsRoutes = (basePath: string, baseUrl: string) => {
  const config = getAppraisalsRoutesConfig(basePath, baseUrl);
  const { submitAppraisalStore } = useSubmitAppraisalStore();

  return {
    ...{
      submitAppraisal: (
        <Route exact path={config.appraisals.children.submitAppraisal.path}>
          <Page routeDefinition={config.appraisals.children.submitAppraisal}>
            <SubmitAppraisal store={submitAppraisalStore} />
          </Page>
        </Route>
      ),
      browseAppraisals: (
        <Route
          exact
          path={[
            config.appraisals.children.browseAppraisals.path,
            config.appraisals.children.conversation().path,
          ]}
        >
          <Page routeDefinition={config.appraisals.children.browseAppraisals}>
            <BrowseAppraisals />
          </Page>
        </Route>
      ),
      appraisals: (
        <Route
          path={config.appraisals.path}
          render={() => {
            return (
              <Redirect to={config.appraisals.children.browseAppraisals.url} />
            );
          }}
        />
      ),
    },
    findAppraiser: (
      <Route exact path={config.appraisals.children.findAppraiser.path}>
        <Page routeDefinition={config.appraisals.children.findAppraiser}>
          <FindAppraiser />
        </Page>
      </Route>
    ),
  };
};
