export const groundUpLoanTermValues = {
  values: [
    {
      fieldname: "Lender",
      mapvalue: "lenderName",
      specialtype: "customlink",
    },
    {
      fieldname: "Status",
      mapvalue: "status",
      specialtype: "",
    },
    {
      fieldname: "Interest Rate",
      mapvalue: "rate",
      specialtype: "percentage",
    },
    {
      fieldname: "Full Princ Interest",
      mapvalue: "fullPrincipalInterest",
      specialtype: "icon",
    },
    {
      fieldname: "Roc Int Rate",
      mapvalue: "rocRate",
      specialtype: "percentage",
    },
    {
      fieldname: "Appraisal Reviewed",
      mapvalue: "appraisalReviewed",
      htmlfield: "checkbox",
      specialtype: "icon",
    },
    {
      fieldname: "Loan Amount:",
      mapvalue: "amount",
      specialtype: "currency",
    },
    {
      fieldname: "Loan SubType:",
      mapvalue: "loanSubtype",
      specialtype: "",
    },
    {
      fieldname: "Points (I|O)",
      mapvalue: "points",
      specialtype: "",
      titlevalue: "pointsAmounts",
    },
    {
      fieldname: "Int. Reserve (Months)",
      mapvalue: "interestReserveMonths",
      specialtype: "float",
    },
    {
      fieldname: "Roc Points (I|O)",
      mapvalue: "rocPoints",
      specialtype: "",
    },
    {
      fieldname: "Credit Reviewed",
      mapvalue: "creditReviewed",
      htmlfield: "checkbox",
      specialtype: "icon",
    },
    {
      fieldname: "Initial Loan Amount:",
      mapvalue: "initialLoanAmount",
      specialtype: "currency",
    },
    {
      fieldname: "Initial LTC",
      mapvalue: "aggregateLoanToCurrentCostInitial",
      specialtype: "percentage",
    },
    {
      fieldname: "Broker Points (I|O)",
      mapvalue: "brokerPoints",
      specialtype: "",
      titlevalue: "brokerPointsAmounts",
    },
    {
      fieldname: "Interest Reserve",
      mapvalue: "interestReserve",
      specialtype: "currency",
    },
    {
      fieldname: "Sub ordination",
      mapvalue: "origLenderSubordination",
      specialtype: "percentage",
    },
    {
      fieldname: "Background Reviewed",
      mapvalue: "backgroundReviewed",
      htmlfield: "checkbox",
      specialtype: "icon",
    },
    {
      fieldname: "LTC Loan Holdback",
      mapvalue: "ltcLoanHoldback",
      specialtype: "currency",
    },
    {
      fieldname: "LTC Holdback %",
      mapvalue: "ltcHoldbackPercentage",
      specialtype: "percentage",
    },
    {
      fieldname: "Duration (months)",
      mapvalue: "duration",
      specialtype: "numeric",
    },
    {
      fieldname: "Pledge Of Shares",
      mapvalue: "pledgeOfShares",
      specialtype: "icon",
    },
    {
      fieldname: "Buyback Skim Reduction",
      mapvalue: "buybackSkimReduction",
      specialtype: "",
    },
    {
      fieldname: "Attorney Name",
      mapvalue: "lenderAttorneySelectedName",
      specialtype: "",
    },
    {
      fieldname: "Construction Holdback:",
      mapvalue: "constructionHoldback",
      specialtype: "currency",
    },
    {
      fieldname: "Constr.Hold back Rate:",
      mapvalue: "constructionHoldbackToRenovationBudgetPercent",
      specialtype: "percentage",
    },
    {
      fieldname: "Total LTFC",
      mapvalue: "aggregateLoanToFutureCost",
      specialtype: "percentage",
    },
    {
      fieldname: "Partners Approval Required:",
      mapvalue: "partnersApprovalRequired",
      specialtype: "icon",
    },
    {
      fieldname: "Attorney Opinion Letter ",
      mapvalue: "attorneyOpinionLetter",
      specialtype: "",
    },
    {
      fieldname: "Attorney Email",
      mapvalue: "attorneyEmail",
      specialtype: "",
    },
    {
      fieldname: "Pre Penalty (Months)",
      mapvalue: "prepayPenaltyMonths",
      specialtype: "numeric",
    },
    {
      fieldname: "Funding Entity",
      mapvalue: "fundingPreference",
      specialtype: "",
    },
    {
      fieldname: "Mers",
      mapvalue: "customFields.mersProcess",
      specialtype: "",
    },
  ]
};

export const groundUpCollateralValues = {
  values: [
    {
      fieldname: "Address",
      mapvalue: "address",
      specialtype: "bigvalue",
    },
    {
      fieldname: "",
      mapvalue: "",
      specialtype: "customlink",
    },
    {
      fieldname: "As-is Value",
      mapvalue: "asIsWithMedian",
      specialtype: "customlink",
    },
    {
      fieldname: "AC",
      mapvalue: "approved",
      specialtype: "icon",
    },
    {
      fieldname: "Occup",
      mapvalue: "occupancy",
      specialtype: "",
    },
    {
      fieldname: "Acquisition",
      mapvalue: "propertyOwnership",
      specialtype: "",
    },
    {
      fieldname: "Acquisition Price",
      mapvalue: "purchasePrice",
      specialtype: "currency",
    },
    {
      fieldname: "Reno Budget",
      mapvalue: "renovationBudget",
      specialtype: "currency",
    },
    {
      fieldname: "ARV",
      mapvalue: "arvWithMedian",
      specialtype: "customlink",
    },
    {
      fieldname: "Min Release $",
      mapvalue: "releaseAmount",
      specialtype: "currency",
    },
    {
      fieldname: "Monthly Rent",
      mapvalue: "monthlyRent",
      specialtype: "currency",
    },
    {
      fieldname: "Exit Strategy",
      mapvalue: "exitStrategy",
      specialtype: "",
    },
    {
      fieldname: "Acquisition Date",
      mapvalue: "purchaseDate",
    },
    {
      fieldname: "LTC Holdback",
      mapvalue: "ltcHoldback",
      specialtype: "currency",
    },
    {
      fieldname: "Zillow",
      mapvalue: "zestimate",
      specialtype: "currency",
    },
    {
      fieldname: "Full Net Proceeds",
      mapvalue: "fullNetProceeds",
      specialtype: "",
    },
    {
      fieldname: "Ins Exp Date",
      mapvalue: "insuranceExpiryDate",
      specialtype: "",
    },
    {
      fieldname: "Purchase Type",
      mapvalue: "propertySourcing",
      specialtype: "",
    },
    {
      fieldname: "RefiAmount",
      mapvalue: "refiAmountDisplay",
      specialtype: "currency",
    },
    {
      fieldname: "Completed Renovations",
      mapvalue: "sunkCost",
      specialtype: "currency",
    },
    {
      fieldname: "Seller's Credit Amount",
      mapvalue: "sellerCreditAmount",
      specialtype: "currency",
    },
    {
      fieldname: "Release Comment",
      mapvalue: "releaseComment",
      specialtype: "",
    },
    {
      fieldname: "Value %",
      mapvalue: "arvValuePercentage",
      specialtype: "",
    },
    {
      fieldname: "Wholesaler Purchase",
      mapvalue: "wholesalerPurchase",
      specialtype: "",
    },
    {
      fieldname: "WholeSale$",
      mapvalue: "wholesaleAmount",
      specialtype: "currency",
    },
    {
      fieldname: "Total Reno Budget",
      mapvalue: "totalRenovationBudget",
      specialtype: "currency",
    },
    {
      fieldname: " ",
      mapvalue: "collateralspace10",
      specialtype: "blankspace",
    },
    {
      fieldname: "Lien Pos",
      mapvalue: "lienPosition",
      specialtype: "numeric",
    },
    {
      fieldname: "Sales in Zip (730 days) 2",
      mapvalue: "salesInZipDays",
      specialtype: "",
      style: "",
    },
    {
      fieldname: "Arms Length",
      mapvalue: "armsLength",
    },
    {
      fieldname: "WholeSale%",
      mapvalue: "wholesalePercentage",
      specialtype: "percentage",
    },
    {
      fieldname: "Describe Renovation",
      mapvalue: "describeRenovation",
      specialtype: "",
    },
    {
      fieldname: " ",
      mapvalue: "collateralspace11",
      specialtype: "blankspace",
    },
    {
      fieldname: "Sr Amt",
      mapvalue: "lienAmount",
      specialtype: "currency",
    },
    {
      fieldname: "Rural Census",
      mapvalue: "ruralProperty",
      specialtype: "",
    },
    {
      fieldname: "Arms Comment",
      mapvalue: "armsLengthComment",
      specialtype: "",
    },
  ]
};