import { Page } from '@roc/feature-app-core';
import { Route } from 'react-router';
import { CreditReview } from '../creditReview/creditReview';

export const getCreditReviewRoutesConfig = (basePath: string, baseUrl: string) => {
  return {
    credit: {
      path: `${basePath}/credit-review`,
      url: `${baseUrl}/credit-review`,
      documentTitle: 'Credit',
    },
  };
}

export const getCreditReviewRoutes = (basePath: string, baseUrl: string, borrowerId: string, loanId: string) => {
  const config = getCreditReviewRoutesConfig(basePath, baseUrl);
  return {
    credit: (
      <Route exact path={config.credit.path}>
        <Page routeDefinition={config.credit}>
          <CreditReview borrowerId={borrowerId} loanId={loanId} isInternal={isInternal(baseUrl)} />
        </Page>
      </Route>
    ),
  };
};

const isInternal = (baseUrl: string) => {
  return baseUrl.includes('internal');
}
