import { observer } from 'mobx-react';
import { REFINANCE } from '@roc/feature-utils';
import { propertySourceValues } from '../../../utils/constants';
import {
  DetailsTable,
  DetailsTableField,
} from '../../../components/detailsTable';
import { formatCurrency, getCompletePropertyAddress } from '@roc/ui/utils';
import { formatDate, insertIf, LoanSubType } from '@roc/feature-utils';
import {
  getValueOrDefaultDash,
  getBooleanDisplayValue,
} from '../../../utils/utils';
import { PropertyFormStore } from '../../../stores/properties/propertyFormStore';


export const PropertyDetailsTable = observer(
  ({ store }: { store: PropertyFormStore }) => {

    const propertyDetails: DetailsTableField[] = getPropertyDetails(store);

    return <DetailsTable fields={propertyDetails} />;
  }
);

export const getPropertyDetails = (store: PropertyFormStore) => {
  switch (store.loanSubType) {
    case LoanSubType.FIX_AND_FLIP:
      return getFixAndFlipPropertyDetails(store);
    case LoanSubType.GROUND_UP:
      return getGroundUpPropertyDetails(store);
    case LoanSubType.STABILIZED_BRIDGE:
      return getStabilizedBridgePropertyDetails(store);
    default: {
      return [];
    }
  }
}

const getFixAndFlipPropertyDetails = (store) => {
  const property = store.getFormValues();

  let completeAddress = getCompletePropertyAddress(property);
  if (
    completeAddress === '' ||
    completeAddress.replace(/,/g, '').trim() === ''
  ) {
    completeAddress = '-';
  }
  const isPropertyOwnedByBorrower = property.loanPurpose === REFINANCE;
  const propertyDetails: DetailsTableField[] = [
    { label: 'Property Address', value: completeAddress, colSpan: 2 },
    {
      label: 'Property Type',
      value: getValueOrDefaultDash(property.propertyType),
      colSpan: 1,
    },
    {
      label: 'Is the property currently owned by the borrower(s)?',
      value: getBooleanDisplayValue(isPropertyOwnedByBorrower),
      colSpan: 1,
    },
    ...insertIf(isPropertyOwnedByBorrower, [
      {
        label: 'When did you purchase this property?',
        value: getValueOrDefaultDash(
          formatDate(property.purchaseDate, 'MMMM d, yyyy')
        ),
        colSpan: 1,
      },
    ]),
    ...insertIf(isPropertyOwnedByBorrower, [
      {
        label: 'Is there an outstanding loan on the property?',
        value: getBooleanDisplayValue(property.outstandingLoanOnTheProperty),
        colSpan: 1,
      },
    ]),
    ...insertIf(
      isPropertyOwnedByBorrower && property.outstandingLoanOnTheProperty,
      [
        {
          label: 'What is the payoff amount?',
          value: formatCurrency(property.payoffAmount),
          colSpan: 1,
        },
      ]
    ),
    ...insertIf(
      isPropertyOwnedByBorrower && property.outstandingLoanOnTheProperty,
      [
        {
          label: 'Has the loan ever been in default?',
          value: getBooleanDisplayValue(property.loanEverBeenInDefault),
          colSpan: 1,
        },
      ]
    ),

    ...insertIf(!isPropertyOwnedByBorrower, [
      {
        label: 'How is the property being sourced?',
        value: getValueOrDefaultDash(property.propertySourcing),
        colSpan: 1,
      },
    ]),
    ...insertIf(
      !isPropertyOwnedByBorrower &&
      property.propertySourcing === propertySourceValues.other,
      [
        {
          label:
            'Please specify the property sourcing',
          value: getValueOrDefaultDash(property.propertySourcingExplanation),
          colSpan: 1,
        },
      ]
    ),
    ...insertIf(!isPropertyOwnedByBorrower, [
      {
        label: 'Does the borrower know the owner of record?',
        value: getBooleanDisplayValue(property.borrowerKnowOwnerOfRecord),
        colSpan: 1,
      },
    ]),
    ...insertIf(!isPropertyOwnedByBorrower && property.borrowerKnowOwnerOfRecord, [
      {
        label: 'Please Specify',
        value: getValueOrDefaultDash(property.armsLengthComment),
        colSpan: 1,
      },
    ]),
    ...insertIf(!isPropertyOwnedByBorrower, [
      {
        label: 'Is there seller credit?',
        value: getBooleanDisplayValue(property.sellerCredit),
        colSpan: 1,
      },
    ]),
    ...insertIf(!isPropertyOwnedByBorrower && property.sellerCredit, [
      {
        label: 'How much is the seller credit?',
        value: formatCurrency(property.sellerCreditAmount),
        colSpan: 1,
      },
    ]),
  ];

  checkAndCorrectColSpanForFields(propertyDetails);

  return propertyDetails;
}

const getGroundUpPropertyDetails = (store) => {
  const property = store.getFormValues();

  let completeAddress = getCompletePropertyAddress(property);
  if (
    completeAddress === '' ||
    completeAddress.replace(/,/g, '').trim() === ''
  ) {
    completeAddress = '-';
  }
  const isPropertyOwnedByBorrower = property.loanPurpose === REFINANCE;
  const propertyDetails: DetailsTableField[] = [
    { label: 'Property Address', value: completeAddress, colSpan: 2 },
    {
      label: 'Property Type',
      value: getValueOrDefaultDash(property.propertyType),
      colSpan: 1,
    },
    {
      label: 'Is the property currently owned by the borrower(s)?',
      value: getBooleanDisplayValue(isPropertyOwnedByBorrower),
      colSpan: 1,
    },
    ...insertIf(isPropertyOwnedByBorrower, [
      {
        label: 'When did you purchase this property?',
        value: getValueOrDefaultDash(
          formatDate(property.purchaseDate, 'MMMM d, yyyy')
        ),
        colSpan: 1,
      },
    ]),
    ...insertIf(isPropertyOwnedByBorrower, [
      {
        label: 'Is there an outstanding loan on the property?',
        value: getBooleanDisplayValue(property.outstandingLoanOnTheProperty),
        colSpan: 1,
      },
    ]),
    ...insertIf(
      isPropertyOwnedByBorrower && property.outstandingLoanOnTheProperty,
      [
        {
          label: 'What is the payoff amount?',
          value: formatCurrency(property.payoffAmount),
          colSpan: 1,
        },
      ]
    ),
    ...insertIf(
      isPropertyOwnedByBorrower && property.outstandingLoanOnTheProperty,
      [
        {
          label: 'Has the loan ever been in default?',
          value: getBooleanDisplayValue(property.loanEverBeenInDefault),
          colSpan: 1,
        },
      ]
    ),
    ...insertIf(!isPropertyOwnedByBorrower, [
      {
        label: 'How is the property being sourced?',
        value: getValueOrDefaultDash(property.propertySourcing),
        colSpan: 1,
      },
    ]),
    ...insertIf(
      !isPropertyOwnedByBorrower &&
      property.propertySourcing === propertySourceValues.other,
      [
        {
          label:
            'Please specify the property sourcing',
          value: getValueOrDefaultDash(property.propertySourcingExplanation),
          colSpan: 1,
        },
      ]
    ),
    ...insertIf(!isPropertyOwnedByBorrower, [
      {
        label: 'Does the borrower know the owner of record?',
        value: getBooleanDisplayValue(property.borrowerKnowOwnerOfRecord),
        colSpan: 1,
      },
    ]),
    ...insertIf(!isPropertyOwnedByBorrower && property.borrowerKnowOwnerOfRecord, [
      {
        label: 'Please Specify',
        value: getValueOrDefaultDash(property.armsLengthComment),
        colSpan: 1,
      },
    ]),
    ...insertIf(!isPropertyOwnedByBorrower, [
      {
        label: 'Will the property be subdivided into lots?',
        value: getBooleanDisplayValue(property.subdividedIntoLots),
        colSpan: 1,
      },
    ]),
    ...insertIf(!isPropertyOwnedByBorrower && property.subdividedIntoLots, [
      {
        label: 'Number of Lots',
        value: getValueOrDefaultDash(property.numberOfLots),
        colSpan: 1,
      },
    ]),
    ...insertIf(!isPropertyOwnedByBorrower, [
      {
        label: 'Is there seller credit?',
        value: getBooleanDisplayValue(property.sellerCredit),
        colSpan: 1,
      },
    ]),
    ...insertIf(!isPropertyOwnedByBorrower && property.sellerCredit, [
      {
        label: 'How much is the seller credit?',
        value: formatCurrency(property.sellerCreditAmount),
        colSpan: 1,
      },
    ]),
    {
      label: 'Are all permits in place?',
      value: getBooleanDisplayValue(property.permitsInPlace),
      colSpan: 1,
    },
  ];

  checkAndCorrectColSpanForFields(propertyDetails);

  return propertyDetails;
}

const getStabilizedBridgePropertyDetails = (store) => {
  const property = store.getFormValues();

  let completeAddress = getCompletePropertyAddress(property);
  if (
    completeAddress === '' ||
    completeAddress.replace(/,/g, '').trim() === ''
  ) {
    completeAddress = '-';
  }
  const isPropertyOwnedByBorrower = property.loanPurpose === REFINANCE;

  const propertyDetails: DetailsTableField[] = [
    { label: 'Property Address', value: completeAddress, colSpan: 2 },
    {
      label: 'Property Type',
      value: getValueOrDefaultDash(property.propertyType),
      colSpan: 1,
    },
    {
      label: 'Is the property currently owned by the borrower(s)?',
      value: getBooleanDisplayValue(isPropertyOwnedByBorrower),
      colSpan: 1,
    },
    ...insertIf(isPropertyOwnedByBorrower, [
      {
        label: 'When did you purchase this property?',
        value: getValueOrDefaultDash(
          formatDate(property.purchaseDate, 'MMMM d, yyyy')
        ),
        colSpan: 1,
      },
    ]),
    ...insertIf(isPropertyOwnedByBorrower, [
      {
        label: 'Is there an outstanding loan on the property?',
        value: getBooleanDisplayValue(property.outstandingLoanOnTheProperty),
        colSpan: 1,
      },
    ]),
    ...insertIf(
      isPropertyOwnedByBorrower && property.outstandingLoanOnTheProperty,
      [
        {
          label: 'What is the payoff amount?',
          value: formatCurrency(property.payoffAmount),
          colSpan: 1,
        },
      ]
    ),
    ...insertIf(
      isPropertyOwnedByBorrower && property.outstandingLoanOnTheProperty,
      [
        {
          label: 'Has the loan ever been in default?',
          value: getBooleanDisplayValue(property.loanEverBeenInDefault),
          colSpan: 1,
        },
      ]
    ),

    ...insertIf(!isPropertyOwnedByBorrower, [
      {
        label: 'How is the property being sourced?',
        value: getValueOrDefaultDash(property.propertySourcing),
        colSpan: 1,
      },
    ]),
    ...insertIf(
      !isPropertyOwnedByBorrower &&
      property.propertySourcing === propertySourceValues.other,
      [
        {
          label:
            'Please specify the property sourcing',
          value: getValueOrDefaultDash(property.propertySourcingExplanation),
          colSpan: 1,
        },
      ]
    ),
    ...insertIf(!isPropertyOwnedByBorrower, [
      {
        label: 'Is there seller credit?',
        value: getBooleanDisplayValue(property.sellerCredit),
        colSpan: 1,
      },
    ]),
    ...insertIf(!isPropertyOwnedByBorrower && property.sellerCredit, [
      {
        label: 'How much is the seller credit?',
        value: formatCurrency(property.sellerCreditAmount),
        colSpan: 1,
      },
    ]),
    ...insertIf(!isPropertyOwnedByBorrower, [
      {
        label: 'Does the borrower know the owner of record?',
        value: getBooleanDisplayValue(property.borrowerKnowOwnerOfRecord),
        colSpan: 1,
      },
    ]),
    ...insertIf(!isPropertyOwnedByBorrower && property.borrowerKnowOwnerOfRecord, [
      {
        label: 'Please Specify',
        value: getValueOrDefaultDash(property.armsLengthComment),
        colSpan: 1,
      },
    ]),
    {
      label: 'Has the property been recently renovated?',
      value: getBooleanDisplayValue(property.hasBeenRecentlyRenovated),
      colSpan: 1,
    },
    {
      label: 'Is the property currently occupied?',
      value: getBooleanDisplayValue(property.currentlyOccupied),
      colSpan: 1,
    },
    {
      label: 'Is the property soon to be listed for sale?',
      value: getBooleanDisplayValue(property.soonToBeListedForSale),
      colSpan: 1,
    },
  ];

  checkAndCorrectColSpanForFields(propertyDetails);
  return propertyDetails;
}

/**
 * Function that adds colspan to last row in case it will not complete the row, if
 * this is not done it could make the last field to not appear in the property details table
 *
 * @param propertyDetails
 */
const checkAndCorrectColSpanForFields = (propertyDetails) => {
  const totalColSpan = propertyDetails.reduce((sum, obj) => sum + (obj.colSpan || 0), 0);
  if (totalColSpan > 0 && totalColSpan % 2 !== 0) {
    propertyDetails[propertyDetails.length - 1].colSpan = 2;
  }
}
