import { makeObservable } from 'mobx';
import { GlobalStore } from 'libs/feature-app-core/src/stores/index';
import FormStore from 'libs/feature-app-core/src/stores/formStore';
import { Originator } from '../types/originatorTypes';

export const form = {
  fields: {
    lenderId: { value: null },
    lenderName: {
      value: '',
      error: null,
      rule: '',
      attribute: 'lender name',
    },
    companyName: {
      value: '',
      error: null,
      rule: '',
      attribute: 'company name',
    },
    percentage: {
      value: 0,
      error: null,
      rule: '',
    }
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export class OriginatorFormStore extends FormStore {
  constructor(globalStore: GlobalStore) {
    super(form, globalStore);
    makeObservable(this, {});
  }

  setOriginator(originator: Originator) {
    this.reset();
    this.loadForm(originator);
  }

  getOriginator(): Originator {
    return this.getFormValues();
  }

  getOriginators(): any {
    return this.getFormValues();
  }
}
