import { FormStore, GlobalStore } from "@roc/feature-app-core";
import { float } from "html2canvas/dist/types/css/property-descriptors/float";
import { makeObservable, observable } from "mobx";
import { DenialOfCredit } from "../types/denialOfCreditTypes";

export class DenialOfCreditFormStore extends FormStore{
  loanId: string;
  constructor(globalStore: GlobalStore, form){
    super(form, globalStore);
    makeObservable(this, {
      loanId : observable,

    });

  }

  setDenialOfCredit(denialOfCredit: DenialOfCredit){
    this.reset();
    this.loadForm(denialOfCredit);
  }

  getDenialOfCredit(): DenialOfCredit{
    return this.getFormValues();
  }
}