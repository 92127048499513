import { Grid, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import { RadioField, trueFalseOptions } from '@roc/ui';
import { EntityForm } from './entityForm';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: '34px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '123.5%',
    letterSpacing: '0.25px',
  },
  body: {
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '175%',
    letterSpacing: '0.15px',
  },
  entityInfoTitle: {
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '166%',
    letterSpacing: '0.4px',
  },
  gridItem: {
    marginLeft: theme.spacing(4),
  },
}));

export const EditBorrowerEntityInformation = observer(({ store }) => {
  const classes = useStyles();
  const handleHasEntityInfoChange = value => {
    const newValue = value === 'true';
    store.borrowerEntityFormStore.setHasEntityInfo(newValue);
  };
  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h4" className={classes.title}>
          Entity Information*
        </Typography>
        <Typography variant="subtitle1" color="textSecondary">
          *This is an optional step.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5" className={classes.body}>
          If available, please provide entity information.
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.gridItem}>
        <Typography color="textSecondary" className={classes.entityInfoTitle}>
          Do you have the Entity Information?
        </Typography>
        <RadioField
          key={'hasEntityInfo'}
          standaloneLabel
          value={store.borrowerEntityFormStore.hasEntityInfo}
          name={'hasEntityInfo'}
          options={trueFalseOptions}
          onChange={handleHasEntityInfoChange}
          fullWidth
          row
          testId="hasEntityInfo"
        />
        {store.borrowerEntityFormStore.hasEntityInfo && (
          <EntityForm
            store={store.borrowerEntityFormStore}
            borrowerEntityInformationStore={store}
          />
        )}
      </Grid>
    </>
  );
});
