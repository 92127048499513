import { FormStore } from '@roc/feature-app-core';
import { action, makeObservable, observable } from 'mobx';
import { Property } from '@roc/feature-types';
import { GlobalStore } from '@roc/feature-app-core';
import { MIXED_USE, MULTIFAMILY_5_PLUS } from '@roc/feature-utils';

const propertyForm = {
  fields: {
    propertyType: {
      value: '',
      error: null,
      rule: 'required',
      message: 'Required',
    },
    numberOfProperties: {
      value: null,
      error: null,
      rule: 'required',
      message: 'Required',
    },
    estimatedAsIsValue: {
      value: null,
      error: null,
      rule: 'required',
      message: 'Required',
    },
    mfProperties: {
      value: [],
      error: null,
      rule: '',
    },
    muProperties: {
      value: [],
      error: null,
      rule: '',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export class PropertyTypesStore extends FormStore {
  private globalStore: GlobalStore;
  propertyId: string;
  constructor(globalStore) {
    super({ ...propertyForm }, globalStore);
    this.globalStore = globalStore;
    makeObservable(this, {
      getProperty: action,
      propertyId: observable,
      setPropertyId: action,
      loadProperty: action,
      areValidPropertyDetails: action,
    });
  }

  setPropertyId(propertyId) {
    this.propertyId = propertyId;
  }

  getProperty(): Property {
    const data = this.getFormValues();

    return {
      propertyId: this.propertyId,
      propertyType: data['propertyType'],
      numberOfProperties: data['numberOfProperties'],
      estimatedAsIsValue: data['estimatedAsIsValue'],
      mfProperties: data['mfProperties'],
      muProperties: data['muProperties'],
    };
  }

  loadProperty(property) {
    this.loadForm(property);
  }

  areValidPropertyDetails() {
    let isValid = true;
    let error = null;
    this.runFormValidationWithMessage();
    const { propertyType } = this.form.fields;
    if (this.form.meta.isValid) {
      if (
        propertyType.value === MULTIFAMILY_5_PLUS &&
        !this.isValidMFProperty
      ) {
        isValid = false;
        error = 'Required. Units must be greater than 5';
      } else if (propertyType.value === MIXED_USE && !this.isValidMUProperty) {
        isValid = false;
        error =
          'Required. Units must be greater than 1. Residential Units Sq Ft should be greater than Commercial Units Sq Ft';
      }
      if (error) {
        this.globalStore.notificationStore.showErrorNotification({
          message: error,
        });
      }
    } else {
      isValid = false;
    }
    return isValid;
  }

  get isValidMFProperty() {
    const { mfProperties } = this.form.fields;
    return mfProperties.value.every(
      mfProperty => mfProperty.numberOfUnits >= 5
    );
  }

  get isValidMUProperty() {
    const { muProperties } = this.form.fields;
    return muProperties.value.every(
      muProperty =>
        muProperty.residentialUnits >= 1 &&
        muProperty.commercialUnits >= 1 &&
        muProperty.residentialUnitsSqFtArea &&
        muProperty.residentialUnitsSqFtArea > 0 &&
        muProperty.commercialUnitsSqFtArea &&
        muProperty.commercialUnitsSqFtArea > 0 &&
        muProperty.residentialUnitsSqFtArea >=
        muProperty.commercialUnitsSqFtArea &&
        muProperty.residentialIncome &&
        muProperty.residentialIncome > 0 &&
        muProperty.commercialIncome &&
        muProperty.commercialIncome > 0 &&
        muProperty.commercialUnitsCurrentOnRent !== null
    );
  }
}
export default PropertyTypesStore;
