import { Box, Grid, Paper, Typography, lighten, useTheme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Layout, useHeaderHeight } from '@roc/feature-app-core';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useParams } from 'react-router';
import { QuoteGeneratorInputs } from './components/quoteGeneratorInputs';
import { QuoteGeneratorOutputs } from './components/quoteGeneratorOutputs';
import { useQuoteGeneratorStore } from './hooks/useQuoteGeneratorStore';

const useStyles = makeStyles(theme =>
  createStyles({
    paper: {
      padding: theme.spacing(4),
    },
    calculatedValuesBorder: {
      border: `2px solid ${theme.palette.primary.dark}`,
      borderRadius: '4px',
      marginTop: 14,
    },
  })
);

export const QuoteDetails = observer(() => {
  const { quoteId } = useParams<{ quoteId: string }>();
  const { quoteGeneratorStore } = useQuoteGeneratorStore();
  const { quoteGeneratorDetailsStore } = quoteGeneratorStore;
  const classes = useStyles();
  const theme = useTheme();
  const headerHeight = useHeaderHeight();

  useEffect(() => {
    quoteGeneratorDetailsStore.resetStore();
    if (quoteId) {
      quoteGeneratorDetailsStore.fetchQuote(quoteId);
    }
  }, []);

  return (
    <Layout title="Bridge Quote Generator">
      <Paper className={classes.paper}>
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <QuoteGeneratorInputs />
          </Grid>
          <Grid item xs={6}>
            <Box className={classes.calculatedValuesBorder} position={'sticky'} top={headerHeight} bgcolor={lighten(theme.palette.primary.main, .95)}>
              <Box bgcolor={theme.palette.primary.dark} color={'white'} p={1.5} textAlign={'center'}>
                <Typography variant="h5">Quote</Typography>
              </Box>
              <Box p={2}>
                <QuoteGeneratorOutputs />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Layout>
  );
});
