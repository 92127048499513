import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useLoanStore } from '@roc/feature-loans';
import { TestDataAttribute } from '@roc/ui';
import { formatCurrency } from '@roc/ui/utils';
import { observer } from 'mobx-react';
import { CustomCard } from '../customCard';
import { SubmissionVsUnderwritingBaseStore } from '../common/store/submissionVsUnderwritingBaseStore';
import { useEffect } from 'react';
import { formatDate, isNil, LoanSubType } from '@roc/feature-utils';
import { useLoanSummaryStore } from '@roc/feature-loan-summary';

const useStyles = makeStyles((theme: Theme) => ({
  differentValues: {
    fontWeight: 'bold',
    fontSize: '1rem',
  }
}));

const renderHeading = value => {
  return (
    <Typography variant="body1">
      <strong>{value}</strong>
    </Typography>
  );
};

const formatLtvRate = (ltv, rate) => {
  let response = '';

  if (ltv) {
    response = `${ltv}% LTV / ${rate ? `${rate}%` : '-'}`;
  } else if (rate) {
    response = `- / ${rate}%`
  }

  return response;
}

interface SubmissionVsUnderwritingFormProps {
  store: SubmissionVsUnderwritingBaseStore;
  loanId?: Number;
  isInternal: Boolean;
  loanDetails: any;
}

export const SubmissionVsUnderwritingValuesCardTerm = observer(({ store, isInternal, loanId, loanDetails }: SubmissionVsUnderwritingFormProps) => {
  const { loanSummaryStore } = useLoanSummaryStore();

  useEffect(() => {
    if (isInternal) {
      store.getSubmissionVsUnderwriting(loanId, loanDetails);
    } else {
      store.getSubmissionVsUnderwriting(loanId, loanDetails)
    }
  }, []);

  const classes = useStyles();

  const calculatedLoanData = store?.calculatedLoanData;
  const loanComparisonValues = store?.loanComparisonValues;
  const primaryProperty = store?.primaryProperty ?? loanSummaryStore?.loanSummary?.primaryProperty;
  const loanSubtype = store?.loanSubtype ?? loanSummaryStore?.loanSummary?.loanSubtype;

  let style = isInternal ? { width: '100%' } : {};

  const getClassNameIfDifferentValues = (value1, value2) => {
    if (value1 && value2 && value1 !== value2) {
      return classes.differentValues;
    }

    return '';
  }

  return (
    <CustomCard title="Submission Values VS. Underwriting Values" paperStyle={style}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell align="right">{renderHeading('Submission')}</TableCell>
            <TableCell align="right">{renderHeading('Underwriting')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>{renderHeading('Borrower FICO ')}</TableCell>
            <TestDataAttribute id="submitted_borrower_fico">
              <TableCell align="right" className={getClassNameIfDifferentValues(loanComparisonValues?.submittedBorrowerFICO, calculatedLoanData?.maxBorrowerFICOScore)}>
                {loanComparisonValues?.submittedBorrowerFICO
                  ? loanComparisonValues?.submittedBorrowerFICO
                  : '-'}
              </TableCell>
            </TestDataAttribute>
            <TestDataAttribute id="underwriting_borrower_fico">
              <TableCell align="right" className={getClassNameIfDifferentValues(loanComparisonValues?.submittedBorrowerFICO, calculatedLoanData?.maxBorrowerFICOScore)}>
                {calculatedLoanData?.maxBorrowerFICOScore
                  ? calculatedLoanData?.maxBorrowerFICOScore
                  : '-'}
              </TableCell>
            </TestDataAttribute>
          </TableRow>
          <TableRow>
            <TableCell>{renderHeading('Loan Amount')}</TableCell>
            <TestDataAttribute id="submitted_loan_amount">
              <TableCell align="right" className={getClassNameIfDifferentValues(loanComparisonValues?.submittedLoanAmount, store?.amount)}>
                {loanComparisonValues?.submittedLoanAmount
                  ? formatCurrency(loanComparisonValues?.submittedLoanAmount)
                  : '-'}
              </TableCell>
            </TestDataAttribute>
            <TestDataAttribute id="underwriting_loan_amount">
              <TableCell align="right" className={getClassNameIfDifferentValues(loanComparisonValues?.submittedLoanAmount, store?.amount)}>
                {store?.amount
                  ? formatCurrency(store?.amount)
                  : '-'}
              </TableCell>
            </TestDataAttribute>
          </TableRow>
          <TableRow>
            <TableCell>{renderHeading('Purchase price')}</TableCell>
            <TestDataAttribute id="submitted_purchase_price">
              <TableCell align="right" className={getClassNameIfDifferentValues(loanComparisonValues?.submittedPurchasePrice, calculatedLoanData?.aggregateAcquisitionPrice)}>
                {loanComparisonValues?.submittedPurchasePrice
                  ? formatCurrency(loanComparisonValues?.submittedPurchasePrice)
                  : '-'}
              </TableCell>
            </TestDataAttribute>
            <TestDataAttribute id="underwriting_purchase_price">
              <TableCell align="right" className={getClassNameIfDifferentValues(loanComparisonValues?.submittedPurchasePrice, calculatedLoanData?.aggregateAcquisitionPrice)}>
                {formatCurrency(calculatedLoanData?.aggregateAcquisitionPrice)}
              </TableCell>
            </TestDataAttribute>
          </TableRow>
          <TableRow>
            <TableCell>{renderHeading('As-is value')}</TableCell>
            <TestDataAttribute id="submitted_as_is_value">
              <TableCell align="right" className={getClassNameIfDifferentValues(loanComparisonValues?.submittedAsIsValue, calculatedLoanData?.aggregateAppraisedAsIsValue)}>
                {loanComparisonValues?.submittedAsIsValue
                  ? formatCurrency(loanComparisonValues?.submittedAsIsValue)
                  : '-'}
              </TableCell>
            </TestDataAttribute>
            <TestDataAttribute id="underwriting_as_is_value">
              <TableCell align="right" className={getClassNameIfDifferentValues(loanComparisonValues?.submittedAsIsValue, calculatedLoanData?.aggregateAppraisedAsIsValue)}>
                {formatCurrency(calculatedLoanData?.aggregateAppraisedAsIsValue)}
              </TableCell>
            </TestDataAttribute>
          </TableRow>
          <TableRow>
            <TableCell>{renderHeading('Monthly Gross Rent')}</TableCell>
            <TestDataAttribute id="submitted_monthly_gross_rent">
              <TableCell align="right" className={getClassNameIfDifferentValues(loanComparisonValues?.submittedMonthlyGrossRent, calculatedLoanData?.aggregateMonthlyGrossRent)}>
                {loanComparisonValues?.submittedMonthlyGrossRent
                  ? formatCurrency(
                    loanComparisonValues?.submittedMonthlyGrossRent
                  )
                  : '-'}
              </TableCell>
            </TestDataAttribute>
            <TestDataAttribute id="underwriting_monthly_gross_rent">
              <TableCell align="right" className={getClassNameIfDifferentValues(loanComparisonValues?.submittedMonthlyGrossRent, calculatedLoanData?.aggregateMonthlyGrossRent)}>
                {formatCurrency(calculatedLoanData?.aggregateMonthlyGrossRent)}
              </TableCell>
            </TestDataAttribute>
          </TableRow>
          <TableRow>
            <TableCell>{renderHeading('Monthly Market Rent')}</TableCell>
            <TestDataAttribute id="submitted_monthly_market_rent">
              <TableCell align="right" className={getClassNameIfDifferentValues(loanComparisonValues?.submittedMonthlyMarketRent, calculatedLoanData?.aggregateMonthlyMarketRent)}>
                {loanComparisonValues?.submittedMonthlyMarketRent
                  ? formatCurrency(
                    loanComparisonValues?.submittedMonthlyMarketRent
                  )
                  : '-'}
              </TableCell>
            </TestDataAttribute>
            <TestDataAttribute id="underwriting_monthly_market_rent">
              <TableCell align="right" className={getClassNameIfDifferentValues(loanComparisonValues?.submittedMonthlyMarketRent, calculatedLoanData?.aggregateMonthlyMarketRent)}>
                {formatCurrency(calculatedLoanData?.aggregateMonthlyMarketRent)}
              </TableCell>
            </TestDataAttribute>
          </TableRow>
          <TableRow>
            <TableCell>{renderHeading('Annual Taxes')}</TableCell>
            <TestDataAttribute id="submitted_annual_taxes">
              <TableCell align="right" className={getClassNameIfDifferentValues(loanComparisonValues?.submittedAnnualTaxes, calculatedLoanData?.aggregateAnnualTaxes)}>
                {loanComparisonValues?.submittedAnnualTaxes
                  ? formatCurrency(loanComparisonValues?.submittedAnnualTaxes)
                  : '-'}
              </TableCell>
            </TestDataAttribute>
            <TestDataAttribute id="underwriting_annual_taxes">
              <TableCell align="right" className={getClassNameIfDifferentValues(loanComparisonValues?.submittedAnnualTaxes, calculatedLoanData?.aggregateAnnualTaxes)}>
                {formatCurrency(calculatedLoanData?.aggregateAnnualTaxes)}
              </TableCell>
            </TestDataAttribute>
          </TableRow>
          <TableRow>
            <TableCell>{renderHeading('Annual Insurance')}</TableCell>
            <TestDataAttribute id="submitted_annual_insurance">
              <TableCell align="right" className={getClassNameIfDifferentValues(loanComparisonValues?.submittedAnnualInsurance, calculatedLoanData?.aggregateAnnualInsurance)}>
                {loanComparisonValues?.submittedAnnualInsurance
                  ? formatCurrency(
                    loanComparisonValues?.submittedAnnualInsurance
                  )
                  : '-'}
              </TableCell>
            </TestDataAttribute>
            <TestDataAttribute id="underwriting_annual_insurance">
              <TableCell align="right" className={getClassNameIfDifferentValues(loanComparisonValues?.submittedAnnualInsurance, calculatedLoanData?.aggregateAnnualInsurance)}>
                {formatCurrency(calculatedLoanData?.aggregateAnnualInsurance)}
              </TableCell>
            </TestDataAttribute>
          </TableRow>
          <TableRow>
            <TableCell>{renderHeading('Annual HOA Fees ')}</TableCell>
            <TestDataAttribute id="submitted_annual_hoa_fees">
              <TableCell align="right" className={getClassNameIfDifferentValues(loanComparisonValues?.submittedHOAFees, calculatedLoanData?.aggregateAnnualHOAFees)}>
                {loanComparisonValues?.submittedHOAFees
                  ? formatCurrency(loanComparisonValues?.submittedHOAFees)
                  : '-'}
              </TableCell>
            </TestDataAttribute>
            <TestDataAttribute id="underwriting_annual_hoa_fees">
              <TableCell align="right" className={getClassNameIfDifferentValues(loanComparisonValues?.submittedHOAFees, calculatedLoanData?.aggregateAnnualHOAFees)}>
                {formatCurrency(calculatedLoanData?.aggregateAnnualHOAFees)}
              </TableCell>
            </TestDataAttribute>
          </TableRow>
          <TableRow>
            <TableCell>{renderHeading('LTV / Interest Rate')}</TableCell>
            <TestDataAttribute id="submitted_loan_amount">
              <TableCell align="right" className={getClassNameIfDifferentValues(formatLtvRate(loanComparisonValues?.submittedLoanLTV, loanComparisonValues?.submittedRate), formatLtvRate(store?.aggregateLoanToAsIs, store?.rate))}>
                {formatLtvRate(loanComparisonValues?.submittedLoanLTV, loanComparisonValues?.submittedRate) || '-'}
              </TableCell>
            </TestDataAttribute>
            <TestDataAttribute id="underwriting_loan_amount">
              <TableCell align="right" className={getClassNameIfDifferentValues(formatLtvRate(loanComparisonValues?.submittedLoanLTV, loanComparisonValues?.submittedRate), formatLtvRate(store?.aggregateLoanToAsIs, store?.rate))}>
                {formatLtvRate(store?.aggregateLoanToAsIs, store?.rate) || '-'}
              </TableCell>
            </TestDataAttribute>
          </TableRow>
          {loanSubtype === LoanSubType.SINGLE_PROPERTY &&
            <>
              <TableRow>
                <TableCell>{renderHeading('Section 8')}</TableCell>
                <TestDataAttribute id="section_8">
                  <TableCell align="right" className={getClassNameIfDifferentValues(store?.loanComparisonValues?.section8, primaryProperty?.nsfrLoanPropertyFields?.section8)}>
                    {store?.loanComparisonValues?.section8 === null ? '-' : store?.loanComparisonValues?.section8 ? 'Yes' : 'No'}
                  </TableCell>
                </TestDataAttribute>
                <TestDataAttribute id="section_8">
                  <TableCell align="right" className={getClassNameIfDifferentValues(store?.loanComparisonValues?.section8, primaryProperty?.nsfrLoanPropertyFields?.section8)}>
                    {primaryProperty?.nsfrLoanPropertyFields?.section8 === null ? '-' : primaryProperty?.nsfrLoanPropertyFields?.section8 ? 'Yes' : 'No'}
                  </TableCell>
                </TestDataAttribute>
              </TableRow>
              <TableRow>
                <TableCell>{renderHeading('Rural')}</TableCell>
                <TestDataAttribute id="rural">
                  <TableCell align="right" className={getClassNameIfDifferentValues(store?.loanComparisonValues?.rural, primaryProperty?.rural)}>
                    {store?.loanComparisonValues?.rural === null ? '-' : store?.loanComparisonValues?.rural ? 'Yes' : 'No'}
                  </TableCell>
                </TestDataAttribute>
                <TestDataAttribute id="rural">
                  <TableCell align="right" className={getClassNameIfDifferentValues(primaryProperty?.rural, primaryProperty?.rural)}>
                    {primaryProperty?.rural === null ? '-' : primaryProperty?.rural ? 'Yes' : 'No'}
                  </TableCell>
                </TestDataAttribute>
              </TableRow>
              <TableRow>
                <TableCell>{renderHeading('Purchase Date')}</TableCell>
                <TestDataAttribute id="purchaseDate">
                  <TableCell align="right" className={getClassNameIfDifferentValues(store?.loanComparisonValues?.purchaseDate, primaryProperty?.nsfrLoanPropertyFields.acquisitionDate)}>
                    {store?.loanComparisonValues?.purchaseDate === null ? '-' : formatDate(store?.loanComparisonValues?.purchaseDate, 'MMMM d, yyyy')}
                  </TableCell>
                </TestDataAttribute>
                <TestDataAttribute id="purchaseDate">
                  <TableCell align="right" className={getClassNameIfDifferentValues(primaryProperty?.nsfrLoanPropertyFields.acquisitionDate, store?.loanComparisonValues?.purchaseDate)}>
                    {primaryProperty?.nsfrLoanPropertyFields?.acquisitionDate === null ? '-' : formatDate(primaryProperty?.nsfrLoanPropertyFields?.acquisitionDate, 'MMMM d, yyyy')}
                  </TableCell>
                </TestDataAttribute>
              </TableRow>
            </>
          }
        </TableBody>
      </Table>
    </CustomCard>
  );
});
