import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import { SelectOriginators } from 'libs/feature-originator/src/originators/selectOriginators/selectOriginators';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries

export const LoanParticipants = observer(props => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography>
            Please choose an additional originator.
          </Typography>
        </Grid>
      </Grid>
      <br />
      <Grid container spacing={2}>
        <SelectOriginators store={props.store} />
      </Grid>
    </>
  );
});
