import { DenialOfCreditService } from '../services/denialOfCreditService';
import { observable, makeObservable, flow } from 'mobx';
import { GlobalStore } from '@roc/client-portal-shared/stores';
import { DenialOfCreditRequestStore } from '../modules/denialOfCredit/denialOfCreditForm/stores/denialOfCreditRequestStore';
import { DenialOfCredit } from '../modules/denialOfCredit/denialOfCreditForm/types/denialOfCreditTypes';
import { DenialOfCreditFormStore } from '../modules/denialOfCredit/denialOfCreditForm/stores/denialOfCreditFormStore';


export class DenialOfCreditStore {
  private globalStore: GlobalStore;
  denialOfCreditRequestStore: DenialOfCreditRequestStore;
  denialOfCreditService: DenialOfCreditService;
  isDeclined: boolean;
  requestSubmitted: boolean;
  success: boolean;
  denialExist: boolean;

  constructor(globalStore: GlobalStore) {
    this.denialOfCreditService = new DenialOfCreditService();
    this.denialOfCreditRequestStore = new DenialOfCreditRequestStore(globalStore);
    this.isDeclined = null;

    makeObservable(this, {
      isDeclined: observable,
      getDenialOfCredit: flow,
      submitDenialOfCredit: flow,
      requestSubmitted: observable,
      success: observable,
      denialExist: observable,
    });
  }
  *submitDenialOfCredit(store: DenialOfCreditFormStore){
    try {
      store.runFormValidationWithMessage();
      if (store.form.meta.isValid){
        const {
          data: response,
        } = yield this.denialOfCreditService.submitDenialOfCredit(store.getDenialOfCredit());
        this.requestSubmitted = true;
        if (response === true){
          this.success = true;
        }
      }
    }catch (e){
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while submiting denial of credit'
      })
    }
  }

  *getDenialOfCredit(loanId: string) {
    try {
      const {
        data: response,
      } = yield this.denialOfCreditService.sendDenialOfCredit(
        loanId,
      );
      this.isDeclined = response;
      if (this.isDeclined){
        const{
          data: response,
        } = yield this.denialOfCreditService.checkDenialOfCreditExist(loanId);
        this.denialExist = response;
      }
    }
    catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while sending request.',
      });
    }
  }

}

export default DenialOfCreditStore;
