import { makeObservable, observable, override } from 'mobx';
import { UserService } from '../../../../../../feature-app-core/src/services/userService';
import { ClientBridgePricerService } from '../services/clientBridgePricerService';
import { BridgePricerFormValues } from '../types/bridgePricerTypes';
import { BridgePricerBaseStore } from './bridgePricerBaseStore';
import { LoaSubtypeOptions } from '../utils/bridgePricerConstants';

export class ClientBridgePricerStore extends BridgePricerBaseStore {
  private clientBridgePricerService: ClientBridgePricerService;
  private user: UserService;
  public borrowerPointsOptions: any = [];

  constructor(globalStore) {
    super(globalStore);
    this.clientBridgePricerService = new ClientBridgePricerService();
    this.isClientPortal = true;
    this.user = new UserService();
    this.chartData = [
      {
        'borrowerRate': '0',
        'yourYieldSpread': 0.0,
      }
    ];

    makeObservable(this, {
      getValues: override,
      borrowerPointsOptions: observable
    });
  }

  async *getValues(data: BridgePricerFormValues) {
    const userInfo = yield this.user.getUserInformation()
      .then(result => result.data.data.user);
    const formValues = this.getFormValues();
    let extraPayload = {};
    if (formValues.loanSubtype === LoaSubtypeOptions.GROUNDUP && this.globalStore.lenderInfo?.isInternal) {
      extraPayload = {
        rateAdjustmentsRequestFields: {
          experience: formValues.experienceScore,
          fico: formValues.estimatedFicoScore,
          transactionType: formValues.loanPurpose,
          completedRenovations: formValues.completedCapitalImprovements,
          leverage: formValues.loanToPurchase,
          loanTerm: formValues.loanTermsMonths,
          totalRenoBudget: formValues.renoBudget,
        },
        internalLender: this.globalStore.lenderInfo?.isInternal
      }
    }
    const response = yield this.clientBridgePricerService.getValues
      ({ ...data, userId: userInfo.userId, ...extraPayload });
    return response.data;
  }

  *getAllLenderIdNames() {

  }

  *getRateFloor(data) {
    const userInfo = yield this.user.getUserInformation()
      .then(result => result.data.data.user);

    const formValues = this.getFormValues();
    let extraPayload = {};
    if (formValues.loanSubtype === LoaSubtypeOptions.GROUNDUP && this.globalStore.lenderInfo?.isInternal) {
      extraPayload = {
        rateAdjustmentsRequestFields: {
          experience: formValues.experienceScore,
          fico: formValues.estimatedFicoScore,
          transactionType: formValues.loanPurpose,
          completedRenovations: formValues.completedCapitalImprovements,
          leverage: formValues.loanToPurchase,
          loanTerm: formValues.loanTermsMonths,
          totalRenoBudget: formValues.renoBudget,
        },
        internalLender: this.globalStore.lenderInfo?.isInternal
      }
    }
    const response = yield this.clientBridgePricerService.getRateFloor({
      ...data,
      userId: userInfo.userId,
      ...extraPayload,
    });
    if (!this.form.fields['borrowerRate'].value) {
      this.onFieldChange('borrowerRate', response.data);
    }
    return response;
  }

  async *getBorrowerPointsOptions(lenderId, loanTerm, submissionDate) {
    const response = yield this.clientBridgePricerService.getBorrowerPointsOptions(lenderId, loanTerm, submissionDate).then(r => r.data);
    this.borrowerPointsOptions = response;
    if (response && (response?.length || 0) > 0 &&
      (!this.form.fields['borrowerPoints'].value || this.form.fields['borrowerPoints'].value < Number(response[0].value))) {
      this.onFieldChange('borrowerPoints', Number(response[0].value));
    }
    return response;
  }

  *getLoanValues(loanId: string): BridgePricerFormValues {
    const response = yield this.clientBridgePricerService.getLoanValues(loanId);
    return response.data.data;
  }

  *getAutocompleteOptions(searchTerm: string) {
    const filters = { dealName: searchTerm };
    const response = yield this.clientBridgePricerService.getBridgeDealNames(
      1,
      25,
      null,
      null,
      filters
    );
    const rows = response.data.data.rows;
    return rows.map(row => ({
      label: row.dealName,
      value: row.dealid,
    }));
  }
}
