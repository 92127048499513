import * as Validator from 'validatorjs';

export const errorMessages = {
  required: 'The :attribute is required.',
  phone: 'Invalid phone number.',
};

const isPhoneNumberValid = value =>
  value.replace(/[^\d]/g, '').match(/^\d{10}$/);
Validator.register('phone', isPhoneNumberValid, errorMessages.phone);
