import { observer } from 'mobx-react';
import React, { ReactNode, useState } from 'react';
import { createStyles } from '@material-ui/core/styles';
import {
  AgGridColumnProps, DataGrid, SelectFilterComponent,
  SelectFloatingFilterComponent, StatusChip, StatusType, ConfirmationMenuItem, MenuItem, InformationDialog, InformationMenuItem,
} from '@roc/ui';
import { userPortalStatusProperties, lenderRoleOptions, Roles, lenderCompanyrolesOptions } from '@roc/feature-utils';
import { IconButton, Menu, ListItemIcon, ListItemText } from '@material-ui/core';
import { MoreVert, Edit, Close, Send, FileCopy, Refresh } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import UserDetails from './userDetails';
import { ManageUsersStore } from '../stores/manageUsersStore';


const noOpComparator = () => 0;

const { NO_ACCOUNT, INVITATION_EMAIL_SENT, ACCOUNT_CREATED, DEACTIVATED_ACCOUNT } = userPortalStatusProperties;

const getRoleValue = (role) => {
  if (role === 'Processor') {
    return 'lender';
  } else if (role === 'Originator') {
    return 'lender_originator';
  } else if (role === 'Admin') {
    return 'lender_full_access_admin';
  } else if (role === 'Lead') {
    return 'lender_full_access';
  } else if (role === "Broker") {
    return "broker";
  }
}

const roleMap = {
  'lender': [
    {
      label: 'Admin',
      value: 'lender_full_access_admin',
    },
    {
      label: 'Processor',
      value: 'lender',
    },
  ],
  'lender_originator': [
    {
      label: 'Admin',
      value: 'lender_full_access_admin',
    },
    {
      label: 'Originator',
      value: 'lender_originator',
    },
  ],
};

const getValuesToDisplayRole = (role) => {
  return roleMap[role] || [];
};

const columns: AgGridColumnProps[] = [
  {
    field: 'name',
    headerName: 'Name',
    minWidth: 80,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'userName',
    headerName: 'User Name',
    minWidth: 140,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'role',
    headerName: 'Role',
    minWidth: 100,
    cellRenderer: 'roleCellRenderer',
    comparator: noOpComparator,
    filter: 'selectFilter',
    floatingFilter: true,
    floatingFilterComponent: 'selectFloatingFilter',
    floatingFilterComponentParams: {
      suppressFilterButton: true,
      options: lenderRoleOptions.map(option => ({ value: option.label, label: option.label })),
    },
  },
  {
    field: 'roleInEntityCurrent',
    headerName: 'Role in Entity',
    minWidth: 110,
    comparator: noOpComparator,
    filter: 'selectFilter',
    floatingFilter: true,
    floatingFilterComponent: 'selectFloatingFilter',
    floatingFilterComponentParams: {
      suppressFilterButton: true,
      options: lenderCompanyrolesOptions.map(option => ({ value: option.label, label: option.label })),
    },
  },
  {
    field: 'ownershipPercentage',
    headerName: 'Ownership Percentage',
    minWidth: 110,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'userPortalStatus',
    headerName: 'Lender Portal Status',
    minWidth: 160,
    cellRenderer: 'portalStatusCellRenderer',
    comparator: noOpComparator,
    filter: 'selectFilter',
    floatingFilter: true,
    floatingFilterComponent: 'selectFloatingFilter',
    floatingFilterComponentParams: {
      suppressFilterButton: true,
      options: [
        { label: NO_ACCOUNT.name, value: NO_ACCOUNT.value },
        { label: INVITATION_EMAIL_SENT.name, value: INVITATION_EMAIL_SENT.value },
        { label: ACCOUNT_CREATED.name, value: ACCOUNT_CREATED.value },
        { label: DEACTIVATED_ACCOUNT.name, value: DEACTIVATED_ACCOUNT.value },
      ],
    },
  },
  {
    field: 'userNameAction',
    headerName: '',
    minWidth: 25,
    maxWidth: 80,
    cellRenderer: 'actionCellRenderer',
    comparator: noOpComparator,
  },
]

const roleNames = {
  ['Lender']: 'Processor',
  ['Lender Originator']: 'Originator',
  ['Lender Admin']: 'Admin',
  ['Lead']: 'Lead'
}

const useWarningStyles = makeStyles(() => ({
  textContainer: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#fdefbf',
    padding: '4px',
  },
  warningIcon: {
    color: '#F2BC16',
    fontSize: '20px',
  },
  text: {
    paddingLeft: '8px',
    fontSize: '14px',
  },
  warningColor: {
    color: '#F2BC16',
  },
}));

export const ManageUsersGrid = observer(({ toolbar, manageUsersStore }: { toolbar: ReactNode; manageUsersStore: ManageUsersStore }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const [userId, setUserId] = useState();
  const [name, setName] = useState('');
  const [userName, setUserName] = useState('');
  const [role, setRole] = useState('');
  const [roleInEntityCurrent, setRoleInEntityCurrent] = useState('');
  const [ownershipPercentage, setOwnershipPercentage] = useState(0);
  const [userPortalStatus, setUserPortalStatus] = useState('');
  const [user, setUser] = useState({});
  const onSortChanged = params => {
    const sortModel = params.api.getSortModel();
    manageUsersStore.gridStore.setSortModel(sortModel);
  };

  const onFilterChanged = params => {
    const filterModel = params.api.getFilterModel();
    manageUsersStore.gridStore.setFilterModel(filterModel);
  };

  const frameworkComponents = {
    selectFilter: SelectFilterComponent,
    selectFloatingFilter: SelectFloatingFilterComponent,
    portalStatusCellRenderer: ({ value }) =>
      value ? (
        <StatusChip statusType={StatusType.USER_PORTAL_STATUS} label={value} size="small" />
      ) : null,
    actionCellRenderer: params => {
      return (
        <IconButton onClick={e => openMenu(e, params.node.data)}>
          <MoreVert color="primary" />
        </IconButton>
      );
    },
    roleCellRenderer: params => {
      return <>{roleNames[params.value] ?? params.value}</>
    }
  };

  const openMenu = (event: React.MouseEvent<HTMLElement>, record) => {
    const { userId, name, role, userName, roleInEntityCurrent, ownershipPercentage, userPortalStatus } = record;
    const ownershipPercentageNumericValue = ownershipPercentage ? (typeof ownershipPercentage === 'string' ? parseFloat(ownershipPercentage.replace("%", "")) : ownershipPercentage) : 0;
    record['ownershipPercentage'] = ownershipPercentageNumericValue;
    setUserId(userId);
    setName(name);
    setUserName(userName);
    setRole(getRoleValue(role));
    setUser(record);
    setAnchorEl(event.currentTarget);
    setRoleInEntityCurrent(roleInEntityCurrent);
    setOwnershipPercentage(ownershipPercentageNumericValue);
    setUserPortalStatus(userPortalStatus);

    manageUsersStore.form.fields.ownershipPercentage.value = ownershipPercentageNumericValue;
    manageUsersStore.form.fields.roleInEntityCurrent.value = roleInEntityCurrent;
    manageUsersStore.form.fields.role.value = getRoleValue(role);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setUserId(null);
    setName('');
    setUserName('');
    setRole('');
    setUser({});
    setRoleInEntityCurrent('');
    setUserPortalStatus('');
  };

  const renderEditUser = () => {
    return role === Roles.LENDER || role === Roles.ORIGINATOR || role === Roles.ADMIN || role === Roles.LENDER_LEAD;
  };

  const classes = useWarningStyles();
  return (
    <>
      <DataGrid
        toolbar={toolbar}
        columns={columns}
        rows={manageUsersStore.gridStore.gridData.data.rows}
        frameworkComponents={frameworkComponents}
        onSortChanged={onSortChanged}
        onFilterChanged={onFilterChanged}
        isLoading={manageUsersStore.gridStore.isLoading}
        showFilters={manageUsersStore.gridStore.showFilters}
        sortModel={manageUsersStore.gridStore.sortModel}
        filterModel={manageUsersStore.gridStore.filterModel}
        paginationData={manageUsersStore.gridStore.gridData.meta}
        setPageNumber={manageUsersStore.gridStore.setPageNumber}
        rowHeight={60}
        domLayout="autoHeight"
      />
      <Menu
        id="menu-appbar"
        getContentAnchorEl={null}
        anchorEl={anchorEl}
        keepMounted
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left', }}
        transformOrigin={{ vertical: 'top', horizontal: 'left', }}
        open={menuOpen}
        onClose={handleMenuClose}
      >
        <ConfirmationMenuItem
          testId={`resetPassword`}
          onClick={() => {
            manageUsersStore.resetPassword(userName);
            handleMenuClose();
          }}
          confirmDialogProps={{
            body: `Are you sure want to reset the password for ${name}?`,
          }}
        >
          <ListItemIcon>
            <Send fontSize="small" />
          </ListItemIcon>
          <ListItemText>Reset Password</ListItemText>
        </ConfirmationMenuItem>
        <ConfirmationMenuItem
          testId={`disableUser`}
          onClick={() => {
            manageUsersStore.deactivateUser(userId)
            handleMenuClose();
          }}
          confirmDialogProps={{
            body: `Are you sure want to disable ${name}?`,
          }}
        >
          <ListItemIcon>
            <Close fontSize="small" />
          </ListItemIcon>
          <ListItemText>Disable User</ListItemText>
        </ConfirmationMenuItem>
        {userPortalStatus === DEACTIVATED_ACCOUNT.value ? (
          <ConfirmationMenuItem
            testId={`disableUser`}
            onClick={() => {
              manageUsersStore.reactivateUser(userId);
              handleMenuClose();
            }}
            confirmDialogProps={{
              body: `Are you sure you want to reactivate ${name}?`,
            }}
          >
            <ListItemIcon>
              <Refresh fontSize="small" />
            </ListItemIcon>
            <ListItemText>Reactivate User</ListItemText>
          </ConfirmationMenuItem>
        ) : null}
        {userPortalStatus === INVITATION_EMAIL_SENT.value ? (
          <MenuItem
            testId={`copyEmailSetupLink`}
            onClick={() => {
              manageUsersStore.copySetupEmailLink(userId);
              handleMenuClose();
            }}>
            <ListItemIcon>
              <FileCopy fontSize="small" />
            </ListItemIcon>
            <ListItemText>Copy Setup Email Link</ListItemText>
          </MenuItem>
        ) : null}
        {renderEditUser() ? (
          <InformationMenuItem
            onClick={() => { }}
            testId={'View User Details'}
            informationDialogProps={
              {
                title: `User - ${name}`,
                body: (<UserDetails currentRole={role} store={manageUsersStore} user={user}></UserDetails>)
              }
            }>
            <ListItemIcon>
              <Edit fontSize="small" />
            </ListItemIcon>
            <ListItemText>
              View/Edit User
            </ListItemText>
          </InformationMenuItem>
        ) : null}
        {
          manageUsersStore.isOwner() &&
          <ConfirmationMenuItem
            testId={`requestBackground`}
            onClick={() => {
              manageUsersStore.requestBackgroundForUser(userId)
              handleMenuClose();
            }}
            confirmDialogProps={{
              body: `Are you sure want to request a background check for ${name}?`,
            }}
          >
            <ListItemIcon>
              <FileCopy fontSize="small" />
            </ListItemIcon>
            <ListItemText>Request Background</ListItemText>
          </ConfirmationMenuItem>
        }
      </Menu>
    </>
  );
});