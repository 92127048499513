import { Page } from '@roc/feature-app-core';
import { Route } from 'react-router';
import { RevenueRecognitionDashboard } from '../components/revenueRecognitionDashboard';

export const getRevenueRecognitionRoutesConfig = (
  basePath: string,
  baseUrl: string,
) => {
  return {
    revenueRecognitionDashboard: {
      path: `${basePath}/revenue_recognition/revenue-recognition-dashboard`,
      url: `${baseUrl}/revenue_recognition/revenue-recognition-dashboard`,
      documentTitle: 'Revenue Recognition Dashboard',
    },
  };
};

export const getRevenueRecognitionRoutes = (basePath: string, baseUrl: string) => {
  const config = getRevenueRecognitionRoutesConfig(basePath, baseUrl);

  return {
    revenueRecognitionDashboard: (
      <Route exact path={config.revenueRecognitionDashboard.path}>
        <Page routeDefinition={config.revenueRecognitionDashboard}>
          <RevenueRecognitionDashboard />
        </Page>
      </Route>
    ),
  };
};
