import { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Layout, useBaseStore } from '@roc/feature-app-core';
import { useStore } from '@roc/client-portal-shared/hooks';
import { TitleForeclosureLoansGrid } from './components/titleForeclosureLoansGrid'
import { TitleAgentTabs } from './components/titleAgentTabs'

const TABS = [
  {
    label: 'FORECLOSURE'
  }
];

export const LoansHome = observer(() => {
  const { loanStore } = useStore();
  const { globalStore } = useBaseStore();

  useEffect(() => {
    const isComingBackFromLoanDetails = globalStore.lastHistoryAction === 'POP';
    if (!isComingBackFromLoanDetails) {
      loanStore.setSelectedTabIndex(0);
    }
  }, []);

  return (
    <Layout>
      <TitleAgentTabs tabs={TABS} />
      {loanStore.selectedTabIndex === 0 && <TitleForeclosureLoansGrid />}
    </Layout>
  );
});
