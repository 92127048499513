
import { StandardDialog } from '@roc/ui';
import { ReferralFeeLink } from '@roc/feature-referral';
import { useStore } from '@roc/client-portal-shared/hooks';

export const ReferrerInformationDialog = (props) => {
  const { open, onClose, loanId, brokerId } = props;
  const { referralFeeStore } = useStore();
  return (
    <StandardDialog
      open={open}
      title="Referral Information"
      maxWidth="md"
      handleClose={onClose}
      dialogContent={<ReferralFeeLink loanId={loanId} referralFeeStore={referralFeeStore} brokerId={brokerId} onClose={onClose} />}
    />
  );
};

export default ReferrerInformationDialog;
