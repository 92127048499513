import { Container, Grid, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import React from 'react';
import { useAppConfigurations } from '@roc/client-portal-shared/hooks';

const useStyles = makeStyles(theme =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      minHeight: '100%',
      padding: theme.spacing(0),
    },
    gridContainer: {
      display: 'flex',
      flexGrow: 1,
      backgroundColor: theme.palette.common.white,
      height: '100%',
    },
    imageHolder: {
      height: '100%',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    },
    formContainer: {
      padding: theme.spacing(3, 2),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
  })
);

export const LoginLayout = ({ children }) => {
  const classes = useStyles();
  const { loginBanner } = useAppConfigurations();

  return (
    <Container maxWidth="xl" className={classes.container}>
      <Grid
        container
        justifyContent="space-around"
        className={classes.gridContainer}
      >
        <Grid item xs={12} md={6} className={classes.formContainer}>
          {children}
        </Grid>
        {loginBanner && (
          <Hidden smDown>
            <Grid item md={6}>
              <div
                className={classes.imageHolder}
                style={{
                  backgroundImage: `url(${loginBanner})`,
                }}
              ></div>
            </Grid>
          </Hidden>
        )}
      </Grid>
    </Container>
  );
};
