import { Box } from '@material-ui/core';
import { Document } from '@roc/feature-types';
import { StandardDialog } from '@roc/ui';
import { observer } from 'mobx-react';
import { APPRAISAL_PAYMENT_LOAN } from './constants/tamariskPayments';
import { TamariskAppraisalOrderPaymentContainer } from './tamariskAppraisalOrderPaymentContainer';
import { useLoanStore } from '@roc/feature-loans';
import { AppraisalOrderDetailsDashboards } from 'libs/feature-documents/src/documents/components/documentForms/appraisalOrderAndCDADetails/appraisalOrderDetailsTab/AppraisalOrderDetailsDashboards';

export type TamariskAppraisalOrderPaymentContainerProps = {
  loanId?: string;
  open?: boolean;
  onClose?: () => void;
  document: Document;
};

export const TamariskAppraisalOrderPaymentContainerDialog = observer(
  (props: TamariskAppraisalOrderPaymentContainerProps) => {
    const { loanStore } = useLoanStore();
    const { loanId, open, onClose, document } = props;
    const loan = loanStore.loanDetails;
    const isTamariskPaymentDone =
      loan?.loanDepositData &&
      loan?.loanDepositData?.depositReceived &&
      (loan.loanDepositData.depositType === 'Appraisal_Standalone' ||
        loan.loanDepositData.depositType === 'Appraisal_Loan' ||
        loan.loanDepositData.depositType === 'ARR_CDA_Loan' ||
        loan.loanDepositData.depositType === 'CDA_Loan');

    return (
      <StandardDialog
        open={open}
        title="Appraisal Details"
        maxWidth="lg"
        handleClose={onClose}
        removeContentBox
        dialogContent={
          <Box width={'100%'}>
            {isTamariskPaymentDone ? (
              <AppraisalOrderDetailsDashboards
                loanId={loanId}
                isInternal={false}
              />
            ) : (
              <TamariskAppraisalOrderPaymentContainer
                entityData={{
                  entityId: loanId,
                  tamariskFlowType: APPRAISAL_PAYMENT_LOAN,
                }}
              />
            )}
          </Box>
        }
      />
    );
  }
);
