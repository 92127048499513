import React, { Fragment, useEffect, useState } from 'react';
import {
  Dialog, DialogActions, DialogContent, DialogTitle,
  Grid, IconButton, Tab, Table, TableBody, TableCell, TableHead, TableRow,
  Typography
} from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import { Button, TextField } from '@roc/ui';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import {
  BorrowerInfoCardInternalStore
} from 'apps/roc-internal-portal/src/app/modules/borrowerInfoCard/stores/borrowerInfoCardInternalStore';
import { BorrowerBaseballCardStore } from '@roc/feature-borrowers';
import { CheckBox, Close, CloudDownload, IndeterminateCheckBox } from '@material-ui/icons';
import BackgroundReviewForm from '../../../../../feature-borrower-review/src/backgroundReview/backgroundReviewForm';
import { useBackgroundReviewStore, useCreditReviewStore } from '@roc/feature-borrower-review';
import CreditReviewForm from '../../../../../feature-borrower-review/src/creditReview/creditReviewForm';
import { useResolutionStore } from '../../../../../feature-borrower-review/src/hooks/useResolutionStore';
import { DocumentStatus } from '@roc/feature-documents';

const useStyles = makeStyles(theme =>
  createStyles({
    header: {
      height: '50px',
      alignItems: 'center',
      display: 'flex',
      marginBottom: '2rem'
    },
    verificationStatusCell: {
      textAlign: 'right'
    },
    closeButtonRejectDialog: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    nonEditableInput: {
      padding: '2% 2% 0 2%',
      backgroundColor: 'transparent',
      border: 'none',
      outline: 'none'
    },
    editableInput: {
      padding: '2% 2% 0 2%',
      backgroundColor: 'transparent',
      border: 'solid lightgrey 1px',
      outline: 'none',
      margin: '20px',
      marginBottom: 0
    },
    actionButton: {
      marginRight: '1rem',
      height: '40px',
      fontSize: '12px',
      padding: '5px'
    },
    experienceComments: {
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      border: 'solid lightgrey 1px',
      '& textarea': {
        resize: 'none',
        width: '-webkit-fill-available'
      },
      fontFamily: 'Manrope, sans- serif'
    },
    paper: {
      borderRadius: theme.spacing(2),
      boxShadow:
        'rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px',
      marginRight: theme.spacing(2),
      backgroundColor: theme.palette.background.default
    },
    link: {
      cursor: 'pointer',
      textDecoration: 'none',
      transition: 'color 0.3s ease'
    },
    textField: {
      backgroundColor: 'white',
      shrink: true,
      '& input.Mui-disabled': {
        color: 'black'
      }
    },
    table: {
      minWidth: 750,
      width: '100%',
      '& td': {
        height: '40px',
        padding: 0,
        paddingTop: '8px',
        paddingBottom: '8px',
        border: '1px solid #e0e0e0'
      },
      '& th:first-child, td[rowSpan], $addNewItemRow td': {
        paddingLeft: theme.spacing(2),
        paddingRight: '3px'
      },
      '& table tr:last-child td, & table td[rowSpan]': {
        borderBottom: 'none'
      }
    },
    headerCell: {
      height: '41px',
      fontWeight: 'bold',
      color: '#757575',
      padding: 0,
      backgroundColor: '#F0FAFF',
      textAlign: 'center',
      border: '1px solid #e0e0e0'
    },
    titleCell: {
      height: '46px',
      padding: 0,
      overflow: 'hidden',
      backgroundColor: '#E2F6FF'
    },
    descriptionCell: {
      whiteSpace: 'pre-wrap'
    },
    editableRow: {
      position: 'relative',
      cursor: 'pointer',
      textAlign: 'center',
      '& td': {
        textAlign: 'center'
      }
    },
    fixedWidthText: {
      fontSize: '14px',
      display: 'flex',
      alignItems: 'center',
      textAlign: 'left',
      paddingLeft: '10px',
      width: '100%'
    },
    fixedWidthTextDescription: {
      fontSize: '14px',
      display: 'flex',
      alignItems: 'center',
      textAlign: 'left',
      paddingLeft: '10px',
      width: '100%',
      maxHeight: '100px',
      overflowY: 'scroll',
    },
    fixedWidthTextComment: {
      fontSize: '14px',
      display: 'flex',
      textAlign: 'left',
      paddingLeft: '10px',
      width: '100%',
      overflowWrap: 'break-word',
      maxHeight: '100px',
      overflowY: 'scroll',
    },

    tab: {
      marginBottom: theme.spacing(2)
    },
    buttonsGrid: {
      display: 'flex',
      alignItems: 'center',
      alignContent: 'center',
      paddingTop: '10px'
    },
    closeButton: {
      display: 'flex',
      alignItems: 'center',
      alignContent: 'center'
    },
    addressWrapper: {
      '& .MuiInputBase-root.Mui-disabled': {
        backgroundColor: theme.palette.grey[50]
      },
      padding: '16px'
    },
    addressWrapperTab: {
      '& .MuiInputBase-root.Mui-disabled': {
        backgroundColor: theme.palette.grey[50]
      },
      paddingLeft: '16px',
      paddingRight: '16px',
      paddingBottom: '26px'
    }
  })
);


interface Props {
  store: BorrowerInfoCardInternalStore | BorrowerBaseballCardStore;
  isInternal?: boolean;
}

export const BorrowerUWReviewCard = observer(({ store, isInternal = true }: Props) => {
  const { entityStore, borrowerReviewResolutions } = store;
  const classes = useStyles();
  const { tab } = classes;
  const { backgroundReviewStore } = useBackgroundReviewStore();
  const { backgroundItemStore } = backgroundReviewStore;
  const { creditReviewStore } = useCreditReviewStore();
  const { creditItemStore } = creditReviewStore;
  const { resolutionStore } = useResolutionStore();
  const [rejectMessageDialog, setRejectMessageDialog] = useState<boolean>(false);
  const [rejectMessage, setRejectMessage] = useState<string>('');
  const [rejectItem, setRejectItem] = useState<any>(null);

  const refreshBorrowerReviewResolutions = async () => {
    store.borrowerReviewResolutions = [];
    await store.getReviewResolutions(store.currentBorrower, store.reviewSection);
  };

  useEffect(() => {
    if (store.selectedTab === 0) {
      refreshBorrowerReviewResolutions();
    } else if (store.selectedTab === 1) {

    }
  }, [store.selectedTab]);

  useEffect(() => {
    resolutionStore.setInternal(isInternal);
    if (store.reviewSection === 'Background') {
      backgroundItemStore.currentBorrower = store.currentBorrower;
      backgroundItemStore.loanId = store.loanId;
      backgroundItemStore.isInternal = isInternal;
      backgroundItemStore.initializeService();
    } else {
      creditItemStore.currentBorrower = store.currentBorrower;
      creditItemStore.loanId = store.loanId;
      creditItemStore.isInternal = isInternal;
      creditItemStore.initializeService();
    }
  }, [isInternal]);


  const onTabSelect = (e: any, index: number): void => {
    store.setSelectedTab(index);
  };

  const handleDocumentDownload = (item: any) => {
    resolutionStore.setResolution(item);
    resolutionStore.downloadDocumentByDropboxPath();
  };

  const handleApprove = async (item: any) => {
    await resolutionStore.updateResolutionStatus(buildUpdateRequest(item, DocumentStatus.ACCEPTED, null));
    await refreshBorrowerReviewResolutions();
  };

  const handleReject = (item: any) => {
    setRejectMessageDialog(true);
    setRejectItem(item);
  };

  const closeRejectMessageDialog = () => {
    setRejectMessageDialog(false);
    setRejectItem(null);
    setRejectMessage('');
  };

  const saveRejectMessageDialog = async () => {
    await resolutionStore.updateResolutionStatus(buildUpdateRequest(rejectItem, DocumentStatus.MORE_INFO_NEEDED, rejectMessage));
    setRejectMessageDialog(false);
    setRejectItem(null);
    setRejectMessage('');
    await refreshBorrowerReviewResolutions();
  };

  const buildUpdateRequest = (item: any, status: string, message: string) => {
    return {
      loanId: store.loanId,
      resolutionId: item.resolutionId,
      status: status,
      message: message
    };
  };

  const handleCloseButton = () => {
    window.parent.postMessage('close-modal', '*');
  };

  const handleSavedSuccess = () => {
    window.parent.postMessage('saved-success', '*');
  };

  const resolutionsHeaders = [
    {
      name: 'Item Name',
      width: '15%'
    },
    {
      name: 'Item Description',
      width: '15%'
    },
    {
      name: 'LOE Comment',
      width: '30%'
    },
    {
      name: 'LOE Document',
      width: '5%'
    },
    {
      name: 'Actions',
      width: '15%'
    }
  ];
  const resolutionsRows = borrowerReviewResolutions ? borrowerReviewResolutions : [];

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid item xs={12} style={{ marginLeft: '8px', marginBottom: '20px' }}>
          <Grid item xs={12} className={classes.header}>
            <Typography variant={'h4'}>
              {store && store.baseballCardDetails
                ? `${store.baseballCardDetails.firstName} ${store.baseballCardDetails.lastName} - ${store.reviewSection} Review`
                : 'Loading...'}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            style={{ borderBottom: 'solid grey 1px', marginBottom: '3rem' }}
          >
            <Tabs
              value={store.selectedTab}
              onChange={onTabSelect}
              indicatorColor='primary'
              textColor='primary'
            >
              <Tab label='Resolutions - LOE' />
              <Tab label='Review Report' />
            </Tabs>
          </Grid>
        </Grid>
        {store.selectedTab === 0 && (
          <Grid item xs={12} style={{ marginLeft: '8px', marginBottom: '20px' }}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  {resolutionsHeaders.map(({ name, ...props }) => (
                    <TableCell key={name} className={classes.headerCell} style={{ paddingLeft: '8px' }}>
                      {name}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody className={classes.editableRow}>
                {resolutionsRows.length === 0 ?
                  (
                    <TableRow>
                      <TableCell colSpan={resolutionsHeaders.length} align="center">
                        No Resolutions/LOE's found
                      </TableCell>
                    </TableRow>
                  ) :
                  (resolutionsRows.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell width={resolutionsHeaders[0].width}>
                      <span className={classes.fixedWidthText}>{item.itemName}</span>
                    </TableCell>
                    <TableCell width={resolutionsHeaders[1].width}>
                        <span className={classes.fixedWidthTextDescription}>
                        {item.itemDescription.split('\n').map((str, i) => <Fragment key={i}>{str}<br /></Fragment>)}
                      </span>
                    </TableCell>
                    <TableCell width={resolutionsHeaders[2].width}>
                      <p className={classes.fixedWidthTextComment}>{item.comment}</p>
                    </TableCell>
                    <TableCell width={resolutionsHeaders[3].width}>
                      <Button
                        variant='contained'
                        color='primary'
                        startIcon={<CloudDownload />}
                        testId={'download'}
                        disabled={!item.dropboxPath}
                        onClick={() => handleDocumentDownload(item)}
                      >
                        {'Download'}
                      </Button>
                    </TableCell>
                    <TableCell width={resolutionsHeaders[4].width}>
                      <Button
                        variant='contained'
                        color='secondary'
                        startIcon={<CheckBox fontSize='small' />}
                        testId={'approve'}
                        style={{
                          backgroundColor: 'green',
                          filter: item.status === 'More Info Needed' ? 'opacity(50%)' : 'opacity(100%)'
                        }}
                        onClick={() => handleApprove(item)}
                      >
                        {'Approve'}
                      </Button>
                      <Button
                        variant='contained'
                        color='secondary'
                        startIcon={<IndeterminateCheckBox fontSize='small' />}
                        testId={'reject'}
                        style={{
                          backgroundColor: 'red',
                          marginLeft: '5px',
                          filter: item.status === 'Accepted' ? 'opacity(50%)' : 'opacity(100%)'
                        }}
                        onClick={() => handleReject(item)}
                      >
                        {'Reject'}
                      </Button>
                    </TableCell>
                  </TableRow>
                )))}
              </TableBody>
            </Table>
          </Grid>
        )}
        {store.selectedTab === 1 && store.reviewSection === 'Background' && (
          <BackgroundReviewForm store={backgroundItemStore} showButtons={false} />
        )}
        {store.selectedTab === 1 && store.reviewSection === 'Credit' && (
          <CreditReviewForm store={creditItemStore} showButtons={false} />
        )}
        <Dialog
          open={rejectMessageDialog}
          onClose={closeRejectMessageDialog}
          fullWidth
          maxWidth='md'
        >
          <DialogTitle>
            Reject Reason
            <IconButton
              aria-label='close'
              className={classes.closeButtonRejectDialog}
              onClick={closeRejectMessageDialog}
            >
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <TextField
              type='text-area'
              variant='outlined'
              label='Message'
              value={rejectMessage}
              onChange={e => setRejectMessage(e.target.value)}
              fullWidth
              multiline={true}
              minRows={4}
              required
              margin='normal'
              testId='rejectMessage'
            />
            <span>
              *Message will be sent to Underwriting chat room
            </span>
          </DialogContent>
          <DialogActions>
            <Button onClick={saveRejectMessageDialog} color='default' disabled={!rejectMessage.trim()} variant='outlined' testId={'OkMessage'}>
              Reject
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </Grid>
  );
});

export default BorrowerUWReviewCard;
