import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import { UrlParameters, WireAuthorizationState } from '../../types';
import { Layout } from '@roc/feature-app-core';
import { Paper, VerticalBarLoader } from '@roc/ui';
import { Theme, createStyles } from '@material-ui/core/styles';

import { useEffect } from 'react';
import { useStore } from '../../hooks';
import { makeStyles } from '@material-ui/core/styles';
import { WireAuthorizationV1 } from './wireAuthorizationV1';
import { WireAuthorizationV2 } from './wireAuthorizationV2';
import { Box, Typography } from '@material-ui/core';

import { IdentityVerificationVersion } from '../../types/index';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(3, 0, 4),
      minHeight: 900
    },
  })
);


export const WireAuthorization = observer(() => {
  const classes = useStyles();
  const { wireAuthorizationStore } = useStore();
  const { uuid } = useParams<UrlParameters>();

  useEffect(() => {
    wireAuthorizationStore.setUuid(uuid);
    wireAuthorizationStore.getWireAuthInfo();
  }, [wireAuthorizationStore, uuid]);


  if (wireAuthorizationStore.wireAuthorizationState === WireAuthorizationState.LOADING) {
    return (
      <Layout maxWidth="lg">
        <Box my={3}>
          <Typography variant="h3" gutterBottom>
            {'Wire Authorization'}
          </Typography>
        </Box>
        {wireAuthorizationStore.loading && <VerticalBarLoader />}
        <Paper className={classes.paper}>
          <Box mt={4} mx={'auto'} textAlign="center">
            <br />
            <Typography>
              Loading necessary information...
            </Typography>
            <br />
          </Box></Paper>
      </Layout>
    );
  }

  return (<>
    {wireAuthorizationStore.identityVerificationVersion === IdentityVerificationVersion.TRULIO && <WireAuthorizationV1 />}
    {wireAuthorizationStore.identityVerificationVersion === IdentityVerificationVersion.AU10TIX && <WireAuthorizationV2 />}
  </>);
});