import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { Tooltip, TooltipProps } from '../tooltip/tooltip';
import { isNil } from '@roc/feature-utils';

export interface CopyTextProps {
  tooltipText?: string;
  tooltipSuccessText?: string;
  tooltipProps?: TooltipProps;
  stopPropogation?: boolean;
  children?: React.ReactNode;
  valueToCopy?: any;
  propStyles?: any;
  callBackFunction?: () => void
}

const useStyles = makeStyles((theme: Theme) => ({
  copyText: {
    cursor: 'pointer',
    position: 'relative',
    padding: '0 4px',
    '&:hover': {
      background: theme.palette.grey[200],
      border: `1px solid ${theme.palette.grey[200]}`,
      borderLeftWidth: 0,
      borderRightWidth: 0,
      borderRadius: 16,
      '&::after': {
        display: 'inherit',
      },
    },
    '&::after': {
      display: 'none',
      content: '""',
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
    },
  },
}));

const getNodeText = node => {
  if (['string', 'number'].includes(typeof node)) return node;
  if (node instanceof Array) return node.map(getNodeText).join('');
  if (typeof node === 'object' && node) return getNodeText(node.props.children);
  return node;
}

export const CopyText = ({
  tooltipText = "Copy to clipboard",
  tooltipSuccessText = "Copied!",
  tooltipProps,
  stopPropogation = true,
  children,
  valueToCopy,
  propStyles,
  callBackFunction,
}: CopyTextProps) => {
  const [copyTooltipText, setCopyTooltipText] = useState<string>('');
  const classes = isNil(propStyles) ? useStyles() : propStyles;
  const getValue = valueToCopy ? () => valueToCopy : () => getNodeText(children);
  return (
    <Tooltip title={copyTooltipText} arrow placement="top" {...tooltipProps}>
      <span className={classes.copyText} onMouseEnter={() => setCopyTooltipText(tooltipText)}
        onClick={(e) => {
          if (stopPropogation) {
            e.stopPropagation();
          }
          navigator.clipboard.writeText(getValue());
          setCopyTooltipText(tooltipSuccessText);
          callBackFunction()
        }}>
        {children}
      </span>
    </Tooltip>
  );
}