import React from 'react';

import './feature-liquidity-report.module.scss';

/* eslint-disable-next-line */
export interface FeatureLiquidityReportProps {}

export function FeatureLiquidityReport(props: FeatureLiquidityReportProps) {
  return (
    <div>
      <h1>Welcome to feature-liquidity-report!</h1>
    </div>
  );
}

export default FeatureLiquidityReport;
