import { Chip } from '@material-ui/core';
import { green, yellow } from '@material-ui/core/colors';

export const InterestAllocationStatusChip = ({ value }) => {
  const color = value ? green : yellow;
  return (
    <Chip
      label={value ? 'Approved' : 'In Review'}
      style={{
        fontWeight: 0,
        backgroundColor: color[200],
        color: color[900],
      }}
    />
  );
};
