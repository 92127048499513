import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { Paper } from '@roc/ui';
import { observer } from 'mobx-react';
import { IVideo } from '../types';
import { ExpandableCard } from '@roc/ui';
import { Video } from './video';
import { useWhatsNewStore } from '../hooks/useWhatsNewStore';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    padding: theme.spacing(3),
  },
}));

export const Videos = observer(() => {
  const { whatsNewStore } = useWhatsNewStore();

  const { currentTabVideos, currentVideo } = whatsNewStore;

  const classes = useStyles();

  const setCurrentVideo = (index: number) => {
    whatsNewStore.setCurrentVideo(index);
  };

  const renderVideo = (video: IVideo, index: number) => (
    <ExpandableCard
      key={video.displayFileName}
      title={video.displayFileName}
      expanded={currentVideo === index}
      onExpand={() => setCurrentVideo(index)}
    >
      <Grid container direction="column" spacing={2}>
        <Video video={video} />
      </Grid>
    </ExpandableCard>
  );

  return (
    <Paper className={classes.paper}>
      <Grid container direction="column" spacing={2}>
        {currentTabVideos.map(renderVideo)}
      </Grid>
    </Paper>
  );
});

export default Videos;
