import { Box, Typography } from "@material-ui/core";
import { IconButton, Tooltip } from "@roc/ui";
import { FileCopy } from '@material-ui/icons';

export const ForeclosureBreakdown = ({ handleModalOpen }) => {

  return (
    <Box display='flex' alignItems='center'>
      <IconButton
        testId="loan-breakdown-icon-btn"
        onClick={handleModalOpen}>
        <Tooltip
          title={
            <Typography variant="subtitle1">
              Request Loan Breakdown
            </Typography>
          }
          placement="left"
          arrow
        >
          <FileCopy color="primary" />
        </Tooltip>
      </IconButton>
    </Box >
  )
}