import { Box, Table, TableBody, TableCell, TableHead, TableRow, Theme, Typography, makeStyles } from '@material-ui/core';
import { alpha } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import { BackgroundItemStore } from '../stores/backgroundItemStore';
import { TextField } from '@roc/ui';

const useStyles = makeStyles((theme: Theme) => ({
  tableContainer: {
    borderRadius: '10px',
    border: '1px #e0e0e0 solid',
    overflow: 'hidden',
  },
  table: {
    minWidth: 750,
    width: '100%',
    '& td': {
      height: '40px',
      padding: 0,
      paddingTop: '8px',
      paddingBottom: '8px',
      border: '1px solid #e0e0e0',
    },
    '& th:first-child, td[rowSpan], $addNewItemRow td': {
      paddingLeft: theme.spacing(2),
      paddingRight: '3px',
    },
    '& table tr:last-child td, & table td[rowSpan]': {
      borderBottom: 'none',
    },
  },
  editableRow: {
    position: 'relative',
    cursor: 'pointer',
    textAlign: 'center',
    '& td': {
      textAlign: 'center'
    },
  },
  titleCell: {
    height: '46px',
    padding: 0,
    overflow: 'hidden',
    backgroundColor: '#E2F6FF'
  },
  inputBase: {
    width: '100%',
    '& input': {
      backgroundColor: '#fdefbf',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      fontSize: 14,
      color: 'black',
      fontWeight: theme.typography.fontWeightBold,
      padding: '8px 4px',
      cursor: 'pointer',
      '&:focus': {
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  title: {
    color: '#757575',
    fontSize: 20
  },
  addNewItemRow: {
    '&:hover': {
      backgroundColor: '#fff',
    },
  },
}));

interface CreditCommentProps {
  store: BackgroundItemStore;
}

const CreditComment = observer(({ store }: CreditCommentProps) => {
  const classes = useStyles();

  return (
    <Box style={{ paddingTop: '15px' }}>
      <div className={classes.tableContainer}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell colSpan={1} className={classes.titleCell}>
                <Typography className={classes.title}>Comments</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={classes.editableRow}>
            <TableRow>
              <TableCell style={{ paddingRight: '10px', paddingLeft: '10px' }}>
                <TextField
                  type="text-area"
                  variant="outlined"
                  value={store.creditComment || ''}
                  onChange={(e) => store.setCreditComment(e.target.value)}
                  fullWidth
                  multiline={true}
                  minRows={4}
                  margin="normal"
                  testId="shareUrl"
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    </Box>
  );
});

export default CreditComment;