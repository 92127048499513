import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import { Box, Button, Grid, List, ListItem, ListItemText, Toolbar } from '@material-ui/core';
import google_logo from './../assets/Google_logo.png';
import zillow_logo from './../assets/zillow_logo.png';
import redfin_logo from './../assets/redfin_logo.jpg';
import { OpenInNew } from '@material-ui/icons';
import { observer } from 'mobx-react';
import { ValuationReviewReportStore } from '../stores/valuationReviewReportStore';
import { useState } from 'react';
import { ScopeOfWorkDialog } from './scopeOfWorkDialog';

const useStyles = makeStyles({
  root: {
    height: '100%'
  },
});

export type Props = {
  store: ValuationReviewReportStore;
  propertyId: number,
  loanId: number
}

export const QuickLinks = observer((props: Props) => {
  const classes = useStyles();
  const { store, propertyId, loanId } = props;
  const [openSow, setOpenSow] = useState<boolean>(false);

  const openGoogleLink = () => {
    const url = store.valuationReviewInfo?.propertyInformation?.googleLink.split("|")[0]
    window.open(url)
  }

  const openZillowLink = () => {
    const url = store.valuationReviewInfo?.propertyInformation?.zillowLink;
    window.open(url);
  }

  const downloadAppraisalReport = () => {
    store.downloadAppraisalReport(propertyId);
  }

  const downloadLiquidityReport = () => {
    store.getLiquidityDocument(propertyId, loanId);
  }

  const openScopeOfWork = () => {
    setOpenSow(true);
  }

  return (
    <Card className={classes.root} elevation={0} variant='outlined'>
      <Toolbar style={{
        minHeight: 52,
        padding: '8px 16px',
      }}>
        <Typography variant="h6" component="div">
          Quick Links
        </Typography>
      </Toolbar>
      <CardContent style={{
        padding: 16
      }}>
        <Box>
          <Button variant='outlined' size='small' endIcon={<OpenInNew />} onClick={() => openGoogleLink()} style={{
            marginRight: 16,
            marginBottom: 16,
          }}>
            <img src={google_logo} style={{ height: 20, width: 'auto' }}></img>
          </Button>
          <Button variant='outlined' size='small' endIcon={<OpenInNew />} onClick={() => openZillowLink()} style={{
            marginRight: 16,
            marginBottom: 16,
          }}>
            <img src={zillow_logo} style={{ height: 20, width: 'auto' }}></img>
          </Button>
          {/* <Button variant='outlined' size='small' endIcon={<OpenInNew />} style={{
            marginRight: 16,
            marginBottom: 16,
          }}>
            <img src={redfin_logo} style={{ height: 20, width: 'auto' }}></img>
          </Button> */}
          {/* <Button variant='outlined' size='small'
          onClick={() => openScopeOfWork()}
          style={{
            marginRight: 16,
            marginBottom: 16,
          }}>
            Scope of Work
          </Button> */}
          <Button variant='outlined' size='small'
          onClick={() => downloadAppraisalReport()}
          style={{
            marginRight: 16,
            marginBottom: 16,
          }}>
            Appraisal Report
          </Button>
          <Button variant='outlined' size='small'
          onClick={() => downloadLiquidityReport()}
          style={{
            marginRight: 16,
            marginBottom: 16,
          }}>
            Liquidity Report
          </Button>
          {/* <Button variant='outlined' size='small' style={{
            marginRight: 16,
            marginBottom: 16,
          }}>
            Comp Tool
          </Button> */}
        </Box>
      </CardContent>
      <ScopeOfWorkDialog open={openSow} onClose={() => setOpenSow(false)}/>
    </Card>
  );
})
