import { Document } from '@roc/feature-types';
import { Button, PrintableAreaRef, StandardDialog } from '@roc/ui';
import GetAppIcon from '@material-ui/icons/GetApp';
import { useEffect, useRef } from 'react';
import PrintIcon from '@material-ui/icons/Print';
import { QUOTE_LOANS, TermSheetHtml } from '@roc/feature-documents';
import { InternalPortalModal } from '../../components/internalPortalModal';
import { useRocInternalPortalStore } from '../../hooks';
import { observer } from 'mobx-react';
import { useOneToolStore } from 'libs/feature-one-tool/src/quote/hooks/useOneToolStore';
import { DownloadableQuote } from 'libs/feature-one-tool/src/quote/steps/summary/components/downloadableQuote';

const DOCUMENT_NAME = 'Term Sheet.pdf';

export const TermSheetInternalDialog = observer(() => {
  const termSheetRef = useRef<PrintableAreaRef>(null);
  const { termSheetInternalStore } = useRocInternalPortalStore();
  const { termSheetData } = termSheetInternalStore;

  const { oneToolStore } = useOneToolStore();
  const { groundUpQuoteStore, fixFlipQuoteStore, stabilizedBridgeQuoteStore } = oneToolStore;
  const loanProgramMapping = {
    'Fix and Flip (1-4)': fixFlipQuoteStore,
    'Stabilized Bridge': stabilizedBridgeQuoteStore,
    'Groundup (1-4)': groundUpQuoteStore,
  };

  if (QUOTE_LOANS.includes(termSheetInternalStore.termSheetData?.loanProgram)) {
    const store = loanProgramMapping[termSheetInternalStore.termSheetData?.loanProgram];
    if (store) {
      store.loadTermSheetData(termSheetInternalStore?.termSheetData);
    }
  }

  useEffect(() => {
    termSheetInternalStore.fetchTermSheetData();
  }, []);

  const downloadTermSheet = () => {
    termSheetRef.current.downloadAsPdf(DOCUMENT_NAME);
  };

  const printTermSheet = async () => {
    const url = await termSheetRef.current.getPdfBlobUrl();
    const printWindow = window.open(url.toString(), '_blank');
    printWindow?.print();
  };

  const dialogActions = (
    <>
      <Button
        style={{ marginRight: '8px' }}
        testId="print-pdf"
        color="default"
        variant="contained"
        onClick={printTermSheet}
        startIcon={<PrintIcon />}
      >
        Print
      </Button>
      <Button
        testId="download-pdf"
        color="primary"
        variant="contained"
        startIcon={<GetAppIcon />}
        onClick={downloadTermSheet}
      >
        Download
      </Button>
    </>
  );

  return (
    <InternalPortalModal
      title="Term Sheet"
      maxWidth="md"
      dialogActions={dialogActions}
      dialogContent={
        termSheetData ? (
          QUOTE_LOANS.includes(termSheetData?.loanProgram) ?
            <DownloadableQuote store={loanProgramMapping[termSheetData?.loanProgram]} ref={termSheetRef} isQuote={false} /> :
            <TermSheetHtml ref={termSheetRef} data={termSheetData} />
        ) : null
      }
    />
  );
});