import { InfiniteScrollGrid } from '@roc/feature-app-core';
import { formatDate } from '@roc/feature-utils';
import {
  AgGridColumnProps,
  DataGrid,
  SelectFilterComponent,
  SelectFloatingFilterComponent,
} from '@roc/ui';
import { formatCurrency, formatPercentage } from '@roc/ui/utils';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useLoanSummaryStore } from '../../hooks/useLoanSummaryStore';
import { makeStyles } from '@material-ui/core/styles';

const noOpComparator = () => 0;

const useStyles = makeStyles(() => ({
  container: {
    height: 435,
  },
}));

const columns: AgGridColumnProps[] = [
  {
    field: 'actionDate',
    headerName: 'Action Date',
    minWidth: 130,
    cellRenderer: 'dateCellRenderer',
    comparator: noOpComparator,
    filter: 'agDateColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'purchaseDate',
    headerName: 'Purchase Date',
    minWidth: 130,
    cellRenderer: 'dateCellRenderer',
    comparator: noOpComparator,
    filter: 'agDateColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'sellDate',
    headerName: 'Sell Date',
    minWidth: 130,
    cellRenderer: 'dateCellRenderer',
    comparator: noOpComparator,
    filter: 'agDateColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'recordingDate',
    headerName: 'Recording Date',
    minWidth: 130,
    cellRenderer: 'dateCellRenderer',
    comparator: noOpComparator,
    filter: 'agDateColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'description',
    headerName: 'Description',
    minWidth: 200,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'propertyType',
    headerName: 'Property Type',
    minWidth: 200,
    filter: 'agTextColumnFilter',
    comparator: noOpComparator,
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'seller',
    headerName: 'Seller',
    minWidth: 200,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'buyer',
    headerName: 'Buyer',
    minWidth: 200,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'lender',
    headerName: 'Lender',
    minWidth: 200,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'loanAmount',
    headerName: 'Loan Amount',
    minWidth: 120,
    cellRenderer: 'amountRenderer',
    comparator: noOpComparator,
  },
  {
    field: 'sellerPrice',
    headerName: 'Seller Price',
    minWidth: 120,
    cellRenderer: 'amountRenderer',
    comparator: noOpComparator,
  },
  {
    field: 'interestRate',
    headerName: 'Interest Rate',
    minWidth: 120,
    cellRenderer: 'interestRateRenderer',
    comparator: noOpComparator,
  },
];

export const TitleHistoryGrid = observer(() => {
  const { loanSummaryStore } = useLoanSummaryStore();
  const { loanSummaryModalStore } = loanSummaryStore;
  const classes = useStyles();

  useEffect(() => {
    loanSummaryModalStore.titleHistoryGridStore.resetAndFetchGridData();
    loanSummaryModalStore.titleHistoryGridStore.showFilters = false;
  }, [loanSummaryModalStore]);


  const frameworkComponents = {
    selectFilter: SelectFilterComponent,
    selectFloatingFilter: SelectFloatingFilterComponent,
    dateCellRenderer: ({ value }) => {
      return value ? formatDate(value, 'MM/dd/yyyy') : '';
    },
    amountRenderer: ({ value }) => {
      return value ? formatCurrency(value) : '';
    },
    interestRateRenderer: ({ value }) => {
      return value && value > 0 ? formatPercentage(value) : '';
    },
  };

  const onSortChanged = params => {
    const sortModel = params.api.getSortModel();
    loanSummaryModalStore.titleHistoryGridStore.setSortModel(sortModel);
  };

  return (
    <InfiniteScrollGrid
      columns={columns}
      frameworkComponents={frameworkComponents}
      store={loanSummaryModalStore.titleHistoryGridStore}
      className={classes.container}
      onSortChanged={onSortChanged}
    />
  );
});
