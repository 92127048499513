import { Button, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import { PaperList, PaperListItem, PaperListTitle, PricerLayout, LoanAutocomplete, YieldSpreadPremium } from './internalPricerComponents';
import { AmortizationType, AsIsValue, BorrowerExperience, County, CreditScore, CurrentBuydown, DebtYield, DSCR, ForeignNational, GeoTier, LoanPurpose, LoanSubtype, PrepaymentPenalty, PropertyType, RateType, Recourse, RequestedLTV, State, TargetPrice, TotalOriginationPoints, TotalUnderwritingAndProcessingFees } from './internalPricerFields';
import { ClientInternalPricerStore } from '../stores/clientInternalPricerStore';
import { formatCurrency } from '@roc/ui/utils';
import { useEffect } from 'react';
import { US_TREASURY_5_YR_INDEX } from '@roc/feature-loans';

interface PricerCalcSectionProps {
  store: ClientInternalPricerStore;
}

export const ClientInternalPricerSFR = observer(({ store }: PricerCalcSectionProps) => {
  useEffect(() => {
    store.resetYieldSpread();
  }, [store.maxYsp]);

  return (
    <PricerLayout
      left={
        <>
          <Grid item xs={12}>
            <LoanAutocomplete store={store} />
          </Grid>
          <LoanValuesSection store={store} />
          <Grid item xs={12} container justifyContent="center">
            <Button color="primary" variant="contained" size="large" fullWidth
              onClick={() => {
                store.resetYieldSpread();
                store.fetchRatesDictionary();
              }} >
              CALCULATE
            </Button>
          </Grid>
        </>
      }
      right={<CalculatedValues store={store} />}
    />
  )
});

const CalculatedValues = observer(({
  store,
}: PricerCalcSectionProps) => {
  const { calculatedBestLtv, isYspOverMax, loanAmount, maxYsp, yieldSpreadPremium, pointSplit, underwritingProcessingFees, pricerFeesEnabled } = store;
  console.log("Rendering.");
  return (
    <>
      <Grid item xs={12}>
        <Typography variant={'h4'}>Loan Pricing</Typography>
      </Grid>
      <Grid item xs={12} style={{ marginLeft: '12px' }}>
        {maxYsp != null && maxYsp != undefined && <YieldSpreadPremium store={store} />}
      </Grid>
      <Grid item xs={12}>
        <PaperList>
          <PaperListTitle text="Economic Values" />
          <PaperListItem label="Loan Amount" value={loanAmount ? formatCurrency(loanAmount) : '-'} />
          <PaperListItem label="Spread Over Index" value={calculatedBestLtv ? calculatedBestLtv.spreadRate : '-'} />
          <PaperListItem label={calculatedBestLtv && calculatedBestLtv.swapIndex ? (calculatedBestLtv.swapIndex == US_TREASURY_5_YR_INDEX ? "Today's 5 Yr US Tsry Index"
            : "Today's 10 Yr US Tsry Index") : "Today's Index Value"} value={calculatedBestLtv ? calculatedBestLtv.swapRate : '-'} />
          <PaperListItem label="Today's Gross Rate" value={calculatedBestLtv && calculatedBestLtv.grossRate ? calculatedBestLtv.grossRate : '-'} />
          <PaperListItem label="Monthly P&I Payment" value={calculatedBestLtv && calculatedBestLtv.monthlyPayment ? !isNaN(calculatedBestLtv.monthlyPayment) ? formatCurrency(calculatedBestLtv.monthlyPayment) : '-' : '-'} />
          <PaperListItem label="Buydown Fee" value={calculatedBestLtv ? calculatedBestLtv.buydownFee : '-'} />
          {isYspOverMax && <Typography color="error" variant="body1">Requested YSP is not available</Typography>}
        </PaperList>
      </Grid>
      {pricerFeesEnabled &&
        <Grid item xs={12}>
          <PaperList>
            <PaperListTitle text="Originator Economics" />
            <PaperListItem label="Yield Spread Premium" value={yieldSpreadPremium ? formatCurrency(yieldSpreadPremium) : '$0'} />
            <PaperListItem label="Point Split" value={pointSplit ? formatCurrency(pointSplit) : '$0'} />
            <PaperListItem label="Underwriting & Processing Fees" value={underwritingProcessingFees ? formatCurrency(underwritingProcessingFees) : '$0'} />
          </PaperList>
        </Grid>
      }
    </>
  );
});

export const LoanValuesSection = observer(({
  store,
}: PricerCalcSectionProps) => {
  const { pricerFeesEnabled } = store;

  return (
    <Grid container spacing={2} >
      <Grid item xs={12}>
        <Typography variant={'h5'}>Loan Values</Typography>
      </Grid>
      <LoanSubtype store={store} />
      <RateType store={store} />
      <RequestedLTV store={store} />
      <LoanPurpose store={store} />
      <AmortizationType store={store} />
      <PropertyType store={store} />
      <AsIsValue store={store} />
      <DSCR store={store} />
      <PrepaymentPenalty store={store} />
      <CreditScore store={store} />
      <BorrowerExperience store={store} />
      <ForeignNational store={store} />
      <State store={store} />
      <County store={store} />
      <CurrentBuydown store={store} />
      {pricerFeesEnabled &&
        <>
          <TotalOriginationPoints store={store} />
          <TotalUnderwritingAndProcessingFees store={store} />
        </>
      }
    </Grid>
  );
});