import { FormStore, GlobalStore } from "@roc/feature-app-core";

const form = {
  fields: {
    appraisalReviewDate: {
      value: null,
      error: null,
      rule: '',
    },
    address: {
      value: '',
      error: null,
      rule: '',
    },
    grossLeasableRentableArea: {
      value: '',
      error: null,
      rule: '',
    },
    bedrooms: {
      value: '',
      error: null,
      rule: '',
    },
    bathrooms: {
      value: '',
      error: null,
      rule: '',
    },
    annualTaxesPerAppraisal: {
      value: '',
      error: null,
      rule: '',
    },
    appraisalAnnualHoaFees: {
      value: '',
      error: null,
      rule: '',
    },
    attachmentType: {
      value: '',
      error: null,
      rule: '',
    },
    appraisalFormUsed: {
      value: '',
      error: null,
      rule: '',
    },
    appraisalValue1: {
      value: '',
      error: null,
      rule: '',
    },
    appraisalDate1: {
      value: '',
      error: null,
      rule: '',
    },
    appraiserFirmId: {
      value: '',
      error: null,
      rule: '',
    },
    appraiserId: {
      value: '',
      error: null,
      rule: '',
    },
    appraisalAMC: {
      value: '',
      error: null,
      rule: '',
    },
    cdaValue: {
      value: '',
      error: null,
      rule: '',
    },
    cdaDate: {
      value: null,
      error: null,
      rule: '',
    },
    appraisalRent: {
      value: '',
      error: null,
      rule: '',
    },
    replacementCost: {
      value: '',
      error: null,
      rule: '',
    },
    ownerOfRecord: {
      value: '',
      error: null,
      rule: '',
    },
    appraisalValue2: {
      value: '',
      error: null,
      rule: '',
    },
    appraisalDate2: {
      value: null,
      error: null,
      rule: '',
    },
    design: {
      value: '',
      error: null,
      rule: '',
    },
    appraisalPropertyAddress: {
      value: '',
      error: null,
      rule: '',
    },
    borrowerName: {
      value: '',
      error: null,
      rule: '',
    },
    stories: {
      value: '',
      error: null,
      rule: '',
    },
    foundation: {
      value: '',
      error: null,
      rule: '',
    },
    exteriorWalls: {
      value: '',
      error: null,
      rule: '',
    },
    useCode: {
      value: '',
      error: null,
      rule: '',
    },
    neighborhoodType: {
      value: '',
      error: null,
      rule: '',
    },
    county: {
      value: '',
      error: null,
      rule: '',
    },
    legalDescription: {
      value: '',
      error: null,
      rule: '',
    },
    section: {
      value: '',
      error: null,
      rule: '',
    },
    block: {
      value: '',
      error: null,
      rule: '',
    },
    lot: {
      value: '',
      error: null,
      rule: '',
    },
    parcel: {
      value: '',
      error: null,
      rule: '',
    },
    appraisalFloodZone: {
      value: '',
      error: null,
      rule: '',
    },
    location: {
      value: '',
      error: null,
      rule: '',
    },
    yearBuilt: {
      value: '',
      error: null,
      rule: '',
    },
    propertyCondition: {
      value: '',
      error: null,
      rule: '',
    },
    lotSizeSqFt: {
      value: '',
      error: null,
      rule: '',
    },
    monthlyMarketRent: {
      value: '',
      error: null,
      rule: '',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export class AppraisalReviewTermSummaryFormStore extends FormStore {
  constructor(globalStore: GlobalStore) {
    super(form, globalStore);
  }

  public setFormValues(propertyInformation: any) {
    Object.entries(propertyInformation).forEach((entry) => {
      if (this.form.fields[entry[0]] && entry[1]) this.form.fields[entry[0]].value = entry[1];
    });

    Object.entries(propertyInformation?.nsfrLoanPropertyFields).forEach((entry) => {
      if (this.form.fields[entry[0]] && entry[1]) this.form.fields[entry[0]].value = entry[1];
    });
  }

  public getSaveObject() {
    const formInfo = this.getFormValues();
    return {
      propertyCondition: formInfo.propertyCondition,
      location: formInfo.location,
      neighborhood: formInfo.neighborhoodType,
      appraisalAMC: formInfo.appraisalAMC,
      appraisalFormUsed: formInfo.appraisalFormUsed,
      asIsValue: formInfo.appraisalValue1,
      appraiserFirm: formInfo.appraiserFirmId,
      appraiserName: formInfo.appraiserId,
      effectiveAppraisalDate: formInfo.appraisalDate1,
      marketRent: formInfo.monthlyMarketRent,
      replacementCost: formInfo.replacementCost,
      grossLeasableAndRentableArea: formInfo.grossLeasableRentableArea,
      appraisalFloodZone: formInfo.appraisalFloodZone,
      reviewDate: formInfo.appraisalReviewDate,
      propertyAddress: formInfo.address,
      useCode: formInfo.useCode,
      appraisalPropertyAddress: formInfo.appraisalPropertyAddress,
      arrCdaValue: formInfo.cdaValue,
      arrCdaEffectiveDate: formInfo.cdaDate,
      stories: formInfo.stories,
      foundation: formInfo.foundation,
      exteriorWalls: formInfo.exteriorWalls,
      county: formInfo.county,
      legalDescription: formInfo.legalDescription,
      section: formInfo.section,
      block: formInfo.block,
      lot: formInfo.lot,
      parcel: formInfo.parcel,
      yearBuilt: formInfo.yearBuilt,
      lotSizeSqFt: formInfo.lotSizeSqFt,
      bedrooms: formInfo.bedrooms,
      bathrooms: formInfo.bathrooms,
      annualTaxesPerAppraisal: formInfo.annualTaxesPerAppraisal,
      appraisalAnnualHoaFees: formInfo.appraisalAnnualHoaFees,
      attachmentType: formInfo.attachmentType,
      ownerOfRecord: formInfo.ownerOfRecord,
      appraisalValue2: formInfo.appraisalValue2,
      appraisalDate2: formInfo.appraisalDate2,
      design: formInfo.design,
      borrowerName: formInfo.borrowerName,
    }
  }
}