import { Service } from '@roc/feature-app-core';

const url = {
  GET_FEMA_PROPERTY_INFO: '/nportal/rest/priv/fema/getFemaPropertyInfo',
  CHANGE_FEMA_DOCUMENT_STATUS: '/nportal/rest/priv/fema/changeFemaDocumentsStatus',
};

export class PostDisasterInspectionService extends Service {

  getFemaPropertyInfo = (loanId, documentId) => {
    return this.get(url.GET_FEMA_PROPERTY_INFO, { loanId, documentId });
  };

  changeFemaDocumentsStatus = (loanId, propertyId) => {
    return this.get(url.CHANGE_FEMA_DOCUMENT_STATUS, { loanId, propertyId });
  };
}