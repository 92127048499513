import { Service } from '@roc/feature-app-core';
const url = {
  GET_TODAY_CLOSING_LOANS_DATA_GRID: '/api/v1/loan/closingTracker/getTodayClosingLoansDataGrids',
  GET_TOMORROW_EXPECTED_CLOSING_LOANS_DATA_GRID: '/api/v1/loan/closingTracker/getTomorrowExpectedClosingLoansDataGrids',
  GET_MONTHLY_CLOSING_LOANS_DATA_GRID: '/api/v1/loan/closingTracker/getMonthlyClosingLoansDataGrids',
};
export class ClosingTrackerService extends Service {
  getTodayClosingLoansDataGrids() {
    return this.get(
      url.GET_TODAY_CLOSING_LOANS_DATA_GRID,
      {},
      {
        disableGlobalLoading: true,
      }
    );
  }

  getTomorrowExpectedClosingLoansDataGrids() {
    return this.get(
      url.GET_TOMORROW_EXPECTED_CLOSING_LOANS_DATA_GRID,
      {},
      {
        disableGlobalLoading: true,
      }
    );
  }

  getMonthlyClosingLoansDataGrids() {
    return this.get(
      url.GET_MONTHLY_CLOSING_LOANS_DATA_GRID,
      {},
      {
        disableGlobalLoading: true,
      }
    );
  }

}