import { useBaseStore } from '@roc/feature-app-core';
import {
  getBorrowerLoanApplicationsRoutes,
  getBorrowerLoanApplicationsRoutesConfig,
} from '../routes/borrowerLoanApplicationsRoutes';

export const useBorrowerLoanApplicationsRoutes = () => {
  const { globalStore } = useBaseStore();
  const { routeBasePath, routeBaseUrl } = globalStore;
  return {
    borrowerLoanApplicationRoutes: getBorrowerLoanApplicationsRoutes(routeBasePath, routeBaseUrl),
    borrowerLoanApplicationRoutesConfig: getBorrowerLoanApplicationsRoutesConfig(
      routeBasePath,
      routeBaseUrl
    ),
  };
};
