import { Box } from '@material-ui/core';
import { observer } from 'mobx-react';
import { useEffect, useRef } from 'react';
import { GeneralTasksComponent } from './components/generalTasksComponent';
import { useGeneralTasksStore } from './hooks/useGeneralTasksStore';
import { GeneralTasksTab } from './types/generalTasksTypes';

export const MyGeneralTasksGmailPopup = observer(() => {
  const { generalTasksStore } = useGeneralTasksStore();
  const handleMessageEventRef = useRef(null);

  useEffect(() => {
    generalTasksStore.setTab(GeneralTasksTab.MY_TASKS);
    generalTasksStore.resetAndFetchOptions().then(() => {
      const messageToGmail = {
        type: 'rocPortalLoaded',
        data: 'Roc Portal is ready to receive messages!'
      };
      const WHITELISTED_HOST_TO_SEND_MESSAGE = 'https://mail.google.com';
      console.log('Roc360: Task Manager Iframe:', 'Message sent to:', WHITELISTED_HOST_TO_SEND_MESSAGE, ', type:', messageToGmail.type);
      parent.postMessage(messageToGmail, WHITELISTED_HOST_TO_SEND_MESSAGE);

      handleMessageEventRef.current = (event) => {
        if (event.origin == 'https://mail.google.com') {
          console.log('Roc360: Task Manager Iframe:', 'Message received from:', event.origin, ', type:', event.data.type);
          const messageData = event.data?.data;
          const loggedInUserEmail: string = messageData?.loggedInUserEmail;
          const loggedInUserEmailParts = loggedInUserEmail?.split('@');
          const getDescription = () => {
            return (`<p><strong>From: </strong>${messageData?.from?.address}</p>\n
            <p><strong>To: </strong>${messageData?.to?.map(to => to.address).join(", ")}</p>\n
            <p><strong>Subject: </strong>${messageData?.subject}</p>
            `)
          };
          const description = getDescription();
          const assignee = generalTasksStore.assigneeOptions?.find(
            assignee => assignee?.emailAddress?.toUpperCase().includes(loggedInUserEmailParts[0]?.toUpperCase())
          );
          generalTasksStore.editTaskStore.openAddTask(undefined, {}, () => {
            generalTasksStore.editTaskStore.loadForm({
              title: messageData?.subject,
              description,
              assigneeId: assignee?.userId,
              // taskType: ''
            });
          });
        }
      }
      window.addEventListener('message', handleMessageEventRef.current);
    });

    return () => {
      window.removeEventListener('message', handleMessageEventRef.current);
    }
  }, []);

  return (
    <Box px={0.25} py={2} width="100%" bgcolor={'#fff'}>
      <GeneralTasksComponent />
    </Box>
  );
});
