import { observer } from 'mobx-react';
import { CreditInformation } from '../creditInformation';
import { ExpandMore } from '@material-ui/icons';
import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Chip, Grid } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { colorByStatus } from 'libs/feature-loan-details/src/loanDetails/components/todoStatusChip';
import { Button } from '@roc/ui';
import { TodoStatus } from 'libs/feature-loan-details/src/loanDetails/utils/loanDetailsTypes';
import { PublicCreditInformation } from '../public/publicCreditInformation';
import { useEffect } from 'react';
import { IdVerificationSummary } from './idVerificationSummary';
import { PersonalInformationWithIdVerification } from './personalInformationWithIdVerification';

const useStyles = makeStyles<Theme, { status }>((theme: Theme) => ({
  chipColor: props => ({
    color: 'white',
    borderColor: colorByStatus[props.status],
    backgroundColor: colorByStatus[props.status],
  }),
}));

export const StatusChip = observer((props) => {

  const { status } = props;
  const classes = useStyles({ status });

  return (
    <Chip
      label={status}
      className={classes.chipColor}
      size='small'
    />
  );
});

export const BorrowerSetupSectionsWithIdVerification = observer((props) => {

  const { store, handleClose } = props;
  const { isBorrowerUser, currentBorrower, personalGuarantor } = store;
  const elegibleForRepull = currentBorrower && currentBorrower?.areCreditScoresFrozen && currentBorrower?.cafSignedDate;

  useEffect(() => {
    store.setIsCreditInformationExpanded(elegibleForRepull);
  }, [elegibleForRepull]);

  const getCreditContent = () => {
    return isBorrowerUser && personalGuarantor
      ? <PublicCreditInformation store={store} handleClose={handleClose} />
      : <CreditInformation store={store} />
  };

  return (
    <Grid item xs={12} >
      <Accordion
        expanded={store.isIDInformationExpanded}
        onChange={(event: React.SyntheticEvent, expanded: boolean) => store.setIsIDInformationExpanded(expanded)}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Grid container justifyContent='space-between'>
            <Grid item>
              ID Information
            </Grid>
            <Grid item>
              <StatusChip
                status={store.borrowerIdentificationDocumentData?.status}
              />
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <IdVerificationSummary store={store} />
        </AccordionDetails>
        <AccordionActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => store.saveIdInformation()}
            testId='saveIdInformation'
            disabled={store.borrowerIdentificationDocumentData?.status == TodoStatus.ACCEPTED}
          >
            Save
          </Button>
        </AccordionActions>
      </Accordion>
      <Accordion
        expanded={store.isPersonalInformationExpanded}
        onChange={(event: React.SyntheticEvent, expanded: boolean) => store.setIsPersonalInformationExpanded(expanded)}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Grid container justifyContent='space-between'>
            <Grid item>
              Personal Information
            </Grid>
            <Grid item>
              <StatusChip
                status={store.personalInformationStatus}
              />
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <PersonalInformationWithIdVerification store={store} />
        </AccordionDetails>
        <AccordionActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => store.saveSomePersonalFinancialInformation()}
            testId='saveSomePersonalFinancialInformation'
            disabled={store.sendIdVerification}
          >
            Save
          </Button>
        </AccordionActions>
      </Accordion>
      <Accordion
        expanded={store.isCreditInformationExpanded}
        onChange={(event: React.SyntheticEvent, expanded: boolean) => store.setIsCreditInformationExpanded(expanded)}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Grid container justifyContent='space-between'>
            <Grid item>
              Credit Information
            </Grid>
            <Grid item>
              <StatusChip
                status={store.creditInformationStatus}
              />
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          {store.isCreditInformationExpanded && getCreditContent()}
        </AccordionDetails>
        <AccordionActions>
          {!isBorrowerUser && !elegibleForRepull &&
            <Button
              variant="contained"
              color="primary"
              onClick={() => store.saveCreditInformation()}
              testId='saveCreditInformation'
              disabled={store.sendIdVerification || store.creditInformationStatus == TodoStatus.NOT_APPLICABLE}
            >
              Save
            </Button>
          }
        </AccordionActions >
      </Accordion >
    </Grid >
  )
});