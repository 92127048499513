import { FormStore, GlobalStore } from "@roc/feature-app-core";
import { DialogState } from "@roc/ui";
import { action, makeObservable, observable } from "mobx";

const form = {
  fields: {
    name: {
      value: null,
      error: '',
      rule: 'required',
      message: 'This field is required',
    },
    nameSearch: {
      value: '',
      error: '',
      rule: '',
    },
    comments: {
      value: null,
      error: '',
      rule: '',
      message: 'This field is required',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export class AppraisalReviewRevisionItemModalStore extends FormStore {
  globalStore: GlobalStore;
  dialogState: DialogState;

  constructor(globalStore) {
    super(form, globalStore);
    this.globalStore = globalStore;
    makeObservable(this, {
      dialogState: observable,
      handleAdd: action,
      closeDialog: action,
    });
  }

  handleAdd() {
    this.dialogState = DialogState.ADD;
    this.reset();
  }

  openDialog = (mode: DialogState) => {
    this.reset();
  };

  closeDialog() {
    this.dialogState = null;
  }
}
