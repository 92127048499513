
import { Grid } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { Paper } from "@roc/ui";
import { observer } from "mobx-react";
import PreferredProcessContent from "./preferredProcessContent";

const useStyles = makeStyles(theme => ({
  paper: {
    padding: 24,
  },
  container: {
    padding: 16,
  }
}));

const PreferredProcessCard = observer(()=>{
  const classes = useStyles();

  return (
    <Grid item md={6} xs={12}>
      <Paper className={classes.paper}>
        <PreferredProcessContent classes={classes.container}/>
      </Paper>
    </Grid>
  )
});

export default PreferredProcessCard;