import { Box, Grid, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { FabActionsButton, FabActionsMenuItem, ProgressBarComponent, StandardDialog } from '@roc/ui';
import {
  Documents,
  useDocumentStore,
} from '@roc/feature-documents';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useBaseStore } from '@roc/feature-app-core';
import { useExtensionRequestStore } from '../../../../../feature-loans/src/loans/loanDetails/modules/loanRequests/hooks/useExtensionRequestStore';
import { useLoanStore } from '@roc/feature-loans';
import { getBorrowerPortalFullScreenDialogStyles } from '@roc/feature-borrower-portal-core';
import { useLoanRoutes } from '@roc/feature-loans-routes-config';
import { useHistory, useLocation } from 'react-router';

export const ExtensionDetailsModal = observer(({ loanId, extensionId }: { loanId: string; extensionId: string }) => {
  const { loanStore } = useLoanStore();
  const { documentStore } = useDocumentStore();
  const [open, setOpen] = useState(false);
  const { push, replace, goBack } = useHistory();
  const location = useLocation();
  const { loanRoutesConfig } = useLoanRoutes();
  const classes = getBorrowerPortalFullScreenDialogStyles();
  const {
    extensionRequestStore
  } = useExtensionRequestStore();

  useEffect(() => {
    setOpen(extensionId ? true : false);
  }, [extensionId]);

  const handleClose = () => {
    setOpen(false);
    /*  if (location.key !== 'default') {
       goBack();
     } else { */
    replace(loanRoutesConfig.loans(loanId).children.extensions.url);
    /* } */
  }

  return (
    <StandardDialog
      className={classes.dialogRoot}
      open={open}
      removeContentBox
      title={
        <Box>
          <Typography variant="h5" gutterBottom>
            {`Loan# ${loanId} - Extension# ${extensionRequestStore?.extensionByNumber?.extensionNumber ?? ''}`}
            {
              loanStore?.loanDetails?.dealName &&
              <Typography variant="body1">
                {loanStore?.loanDetails?.dealName}
              </Typography>
            }
          </Typography>
        </Box>
      }
      fullScreen
      handleClose={handleClose}
      dialogContent={
        <ExtensionDetailsV2 loanId={loanId} extensionId={extensionId} />
      }
    />
  )
});

const ExtensionDetailsV2 = observer(
  ({ loanId, extensionId }: { loanId: string; extensionId: string }) => {
    const { globalStore } = useBaseStore();
    const {
      extensionRequestStore
    } = useExtensionRequestStore();
    const { documentStore } = useDocumentStore();
    const { loanStore } = useLoanStore();

    useEffect(() => {
      extensionRequestStore.fetchExtensionById(extensionId);
      return () => {
        extensionRequestStore.resetExtensionByNumber();
      };
    }, []);

    useEffect(() => {
      if (extensionRequestStore.extensionByNumber) {
        documentStore.fetchExtensionDocuments(loanId, extensionId);
      }
    }, [extensionRequestStore.extensionByNumber]);

    if (!extensionRequestStore.extensionByNumber || !documentStore.extensionDocuments) {
      return <></>;
    }

    return (
      <Box width={'100%'}>
        <Grid item xs={12}>
          <ProgressBarComponent
            progressArray={["Extension Initiated", "Insurance under review", "Insurance Review Completed",
              "Extension In Process", "Extension Completed"]}
            active={extensionRequestStore.extensionByNumber.status}
          />
        </Grid>
        <Grid item container spacing={2}>
          {documentStore.documentSections.map((element, index) => {
            if (element.loanProcess === "Extensions") {
              return (
                <Grid key={element.sectionName} item xs={12}>
                  <Documents
                    section={element.sectionName}
                    loanProcess={"Extensions"}
                    loanId={loanId}
                    extensionNumber={extensionId}
                    loanStore={loanStore}
                  />
                </Grid>
              );
            }
          })}
        </Grid>
      </Box>
    );
  }
);
