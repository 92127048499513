import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import {
  AuthorizedSignatory,
  BkFCwithinFourYears,
  BorrowerEstimatedExperience,
  CellPhone,
  CitizenshipStatus,
  EmailAddressDuplicated,
  FirstName,
  LastName,
  OwnershipNotKnown,
  PercentOwnership,
  PersonalGuarantor,
  QualifyingFicoScoreDropdown,
  createSliderField,
} from '@roc/ui/formComponents';
import { FormStore, useBaseStore } from '@roc/feature-app-core';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { SelectBorrowersStore } from '@roc/feature-borrowers';
import { FixFlipStore, MultiFamilyStore } from '../stores';
import { LoanSubType, estimatedFFExperienceOptions, estimatedGUExperienceOptions, prequalificationStatusProperties, isFixAndFlip } from '@roc/feature-utils';
import { Borrower } from '@roc/feature-types';

interface BorrowerFormProps {
  store: FormStore;
  selectBorrowersStore: SelectBorrowersStore;
  fixFlipStore: FixFlipStore | MultiFamilyStore;
  currentBorrower?: Borrower;
}

const useStyles = makeStyles(theme =>
  createStyles({
    slider: {
      marginTop: '2.5em',
      marginLeft: '2em',
      width: '85%',
    },
    paragraph: {
      marginLeft: '2em',
    },
    textItalic: {
      fontStyle: 'italic',
    },
  })
);

export const BorrowerFormFixFlip = observer(
  ({ store, selectBorrowersStore, fixFlipStore, currentBorrower }: BorrowerFormProps) => {
    const classes = useStyles();
    const { globalStore } = useBaseStore();

    currentBorrower = currentBorrower ?? selectBorrowersStore.currentBorrower;

    //TODO: how to remove this dependency here
    const checkBorrowerExistingInFunded =
      currentBorrower?.portalProjectsCompleted > 0 ||
      currentBorrower?.portalProjectsActive > 0;
    const disableEdit =
      !globalStore.userFeatures?.addNewBorrowerDuringSubmission ||
      checkBorrowerExistingInFunded;
    const ownershipNotKnown = store.form.fields.ownershipNotKnown.value;

    const validExistingEmail = email => {
      return (
        globalStore.userFeatures?.allowBorrowerDuplicateEmail ||
        !selectBorrowersStore.borrowers
          .filter(borrower => borrower.tempId != currentBorrower?.tempId)
          .some(borrower => borrower.emailAddress === email)
      );
    };

    const getExperience = () => {
      if ((fixFlipStore.loanSubtype === LoanSubType.GROUND_UP && fixFlipStore.isInternal)
        || isFixAndFlip(fixFlipStore.loanSubtype)) {
        if (!currentBorrower?.experience) {
          const options = isFixAndFlip(fixFlipStore.loanSubtype) ? estimatedFFExperienceOptions : estimatedGUExperienceOptions;
          return (
            <Grid item xs={12}>
              <BorrowerEstimatedExperience store={store} disabledEdit={fixFlipStore.isDealRoom} options={options} />
            </Grid>
          );
        }
        return null;
      }
      return null
    };

    const getQualifyingScore = () => {
      if ((fixFlipStore.loanSubtype === LoanSubType.GROUND_UP && fixFlipStore.isInternal)
        || isFixAndFlip(fixFlipStore.loanSubtype)) {
        const min = fixFlipStore.loanSubtype === LoanSubType.GROUND_UP ? 680 : 350;
        return (
          <Grid item xs={12} style={{ display: !currentBorrower?.medianScore ? '' : 'none' }}>
            {createSliderField({
              store,
              label: "Estimated FICO Score*",
              fieldName: 'ficoProvidedAtOrigination',
              testId: 'ficoProvidedAtOrigination',
              className: classes.slider,
              valueLabelDisplay: 'on',
              min,
              max: 800,
              step: 10,
              marks: [
                {
                  value: min,
                  label: `${min}`,
                },
                {
                  value: 800,
                  label: '800',
                },
              ],
            })}
          </Grid>
        )
      }
    };

    const getBKorFCField = () => {
      if (isFixAndFlip(fixFlipStore.loanSubtype) && (!currentBorrower?.bankruptcy || !currentBorrower?.foreclosure)) {
        return (
          <Grid item xs={12}>
            <BkFCwithinFourYears store={store} />
          </Grid>
        )
      }
      return null;
    }

    return (
      <Grid container justifyContent={'center'} spacing={2}>
        <Grid item xs={12}>
          <FirstName store={store} disabled={checkBorrowerExistingInFunded || fixFlipStore.isDealRoom} />
        </Grid>
        <Grid item xs={12}>
          <LastName store={store} disabled={checkBorrowerExistingInFunded || fixFlipStore.isDealRoom} />
        </Grid>
        <Grid item xs={12}>
          <EmailAddressDuplicated
            store={store}
            disabled={disableEdit && !!currentBorrower?.emailAddress}
            onChanged={(name, value) =>
              store.onFieldChange(
                'emailAddressDuplicated',
                validExistingEmail(value)
              )
            }
          />
        </Grid>
        <Grid item xs={12}>
          <CellPhone store={store} disabled={disableEdit} />
        </Grid>
        <Grid item xs={12}>
          <CitizenshipStatus store={store} />
        </Grid>
        <Grid item xs={12}>
          <AuthorizedSignatory store={store} />
        </Grid>
        {getExperience()}
        {getQualifyingScore()}
        {getBKorFCField()}
        <Grid item xs={12}>
          <PercentOwnership store={store} disableEdit={ownershipNotKnown} />
        </Grid>
        <Grid item xs={12} justifyContent="flex-end">
          <OwnershipNotKnown store={store} />
        </Grid>
        <Grid item xs={12}>
          <PersonalGuarantor store={store} />
        </Grid>
      </Grid>
    );
  }
);
