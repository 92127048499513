import { Box, Typography } from '@material-ui/core';
import React from 'react';

import internalServerErrorImage1 from './../../../assets/internal-server-error.svg';
import internalServerErrorImage2 from './../../../assets/500.svg';

export const InternalServerError = () => {
  return (
    <Box display="flex" flexDirection="column" height="100%" alignItems="center" justifyContent="center" py={8}>
      <Box display="flex" alignItems="center" justifyContent="center" mb={8}>
        <Typography variant="h2" align='center'>
          500: Internal Server Error
        </Typography>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="center">
        <img
          alt="Internal Server Error"
          src={internalServerErrorImage1}
          style={{
            maxWidth: 800,
            width: '100%',
          }}
        />
      </Box>
    </Box>
  );
};

export const InternalServerError2 = ({ style = {} }) => {
  return (
    <Box display="flex" justifyContent="center" height="100%" bgcolor={"#f7f7f7"} style={style}>
      <Box display="flex" flexDirection="column" height="100%" alignItems="center" justifyContent="center" maxWidth={800}>
        <Box display="flex" alignItems="center" justifyContent="center" mb={4}>
          <img
            alt="Internal Server Error"
            src={internalServerErrorImage2}
            style={{
              width: '80%',
            }}
          />
        </Box>
        <Box display="flex" alignItems="center" justifyContent="center" mb={4}>
          <Typography variant="h3" align='center'>
            There was an error, please try again later
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="center">
          <Typography variant="h5" align='center'>
            The server encountered an internal error and was not able to complete your request
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
