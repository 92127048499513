import React, { useState } from 'react';
import {
  InputBase,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { alpha } from '@material-ui/core/styles';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import { CategoryDB } from '../../../types';
import { ScopeOfWorkV2FormBaseStore } from '@roc/feature-sow-shared/stores/v2';
import { formatCurrency } from '@roc/ui/utils';
import { observer } from 'mobx-react';

const filter = createFilterOptions();

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    minWidth: 750,
    border: '2px #e0e0e0 solid',
    '& td': {
      height: '40px',
      padding: 0,
      paddingTop: '8px',
      paddingBottom: '8px',
    },
    '& th:first-child, td[rowSpan], $addNewItemRow td': {
      paddingLeft: theme.spacing(2),
      paddingRight: '3px',
    },
    '& table tr:last-child td, & table td[rowSpan]': {
      borderBottom: 'none',
    },
  },
  headerCell: {
    height: '41px',
    fontWeight: 'bold',
    marginRight: '3px',
    padding: 0,
  },
  titleCell: {
    height: '46px',
    padding: 0,
    borderBottom: '2px #e0e0e0 solid',
  },
  descriptionCell: {
    whiteSpace: 'pre-wrap',
  },
  inputBase: {
    width: '100%',
    '& input': {
      backgroundColor: '#fdefbf',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      fontSize: 14,
      color: 'black',
      fontWeight: theme.typography.fontWeightBold,
      padding: '8px 4px',
      cursor: 'pointer',
      '&:focus': {
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  autocompletePaper: {
    marginLeft: 0,
    marginRight: 0,
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    fontSize: 13,
  },
  autocompleteOption: {
    minHeight: 'auto',
    alignItems: 'flex-start',
    padding: 8,
    '&[data-focus="true"]': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  horizontalScroll: {
    [theme.breakpoints.down('md')]: {
      overflowX: 'scroll',
    },
  },
  clickHereToEdit: {
    color: theme.palette.grey[400],
    fontSize: theme.typography.fontSize * 0.8,
  },
  clickHereToEditHidden: {
    visibility: 'hidden',
  },
  title: {
    color: '#333333',
    fontSize: 20,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    paddingRight: '10px',
  },
  div: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

interface ITableCard {
  store: ScopeOfWorkV2FormBaseStore;
  title: string | JSX.Element;
  rows: Array<any>;
  columns: Array<any>;
  category: CategoryDB;
  isPdf?: boolean;
  className?: any;
}

export const SOWTableCardPdf = observer(
  ({
    store,
    columns,
    title,
    rows,
    className = '',
  }: ITableCard) => {
    const classes = useStyles();

    const getRemaining = (row: any) => {
      return row.capex ? row.cost - ((row.capex * row.cost) / 100) : row.cost;
    };

    const getCapex = (lineitem: any) => {
      if (lineitem.capex && lineitem.capex > 0) {
        return `${lineitem.capex}% | ${formatCurrency((lineitem.capex * lineitem.cost) / 100)}`;
      } else {
        return '0% | $0';
      }
    };

    const getRowSpan = (lineItems) => {
      let rowSpanNeeded = lineItems.length;
      lineItems.map((lineItem) => {
        if(lineItem.description){
          rowSpanNeeded ++;
        }
      })
      return rowSpanNeeded;
    }

    return (
      <div className={classes.horizontalScroll + ' ' + className}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell colSpan={columns.length} className={classes.titleCell}>
                <div className={classes.div}>
                  <Typography className={classes.title}>{`${title}`}</Typography>
                </div>
              </TableCell>
            </TableRow>
            <TableRow>
              {columns.map(({ name, ...props }) => (
                <TableCell key={name} className={classes.headerCell} {...props} style={{ paddingLeft: '8px' }}>
                  {name}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {rows.map((row, index) => {
            const areItems = row.itemized.length > 0 ? true : false;
            const lineItems = row.itemized.length > 0 ? row.itemized : [
            {
              cost: row.general.cost,
              description: row.general.description,
              reviewStatus: row.general.reviewStatus,
              comments: row.general.comments,
              capex: row.general.capex ? row.general.capex : 0,
              }
            ]
            return (
              <TableBody key={`${row?.value}_${index}`}>
                {lineItems.map((lineitem, idx) => (
                  <React.Fragment key={lineitem.name + ' ' + index}>
                    <TableRow
                      style={{ backgroundColor: store.getSubCategoryStyle(row.categoryId, row.name) }}>
                      {idx === 0 && (
                        <TableCell
                          width={columns[0]?.width}
                          rowSpan={getRowSpan(lineItems)}
                          style={{ backgroundColor: store.getSubCategoryStyle(row.categoryId, row.name) }}
                        >
                          {row.name}
                        </TableCell>
                      )}
                      <TableCell width={columns[1]?.width} style={{ backgroundColor: store.getItemStyle(row.categoryId, row.name, lineitem.name), paddingLeft: '8px' }}>
                        {lineitem.name}
                      </TableCell>
                      {lineitem.cost ? (
                        <>
                          <TableCell width={columns[2]?.width} style={{ backgroundColor: store.getItemStyle(row.categoryId, row.name, lineitem.name) }} >
                            {formatCurrency(lineitem.cost)}
                          </TableCell>
                          <TableCell align="center" width={columns[3]?.width} style={{ backgroundColor: store.getItemStyle(row.categoryId, row.name, lineitem.name) }} >
                            {getCapex(lineitem)}
                          </TableCell>
                          <TableCell align="center" width={columns[4]?.width} style={{ backgroundColor: store.getItemStyle(row.categoryId, row.name, lineitem.name) }} >
                            {formatCurrency(getRemaining(lineitem))}
                          </TableCell>
                        </>
                      ) : (
                        <TableCell
                          className={`${classes.clickHereToEdit} ${store.isDisabled && classes.clickHereToEditHidden}`}
                          colSpan={5}
                          width={'58.5%'}
                          align="center"
                        >
                          CLICK HERE TO EDIT
                        </TableCell>
                      )}
                    </TableRow>
                    {lineitem.description && (
                      <TableRow>
                        <TableCell
                          colSpan={12}
                          className={classes.descriptionCell}
                          style={{ textAlign: 'left', borderTop: 0, paddingLeft: '10px' }}
                          align="left"
                        >
                          <strong>Description: </strong>
                          <div key={index} style={{paddingRight: 5}}>{lineitem.description}</div>
                        </TableCell>
                      </TableRow>
                    )}
                  </React.Fragment>
                ))}
              </TableBody>
            );
          })}
        </Table>
      </div>
    );
  }
);
