import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useBaseStore } from '@roc/feature-app-core';
import { useDocumentStore } from '@roc/feature-documents';
import { Button, ConfirmationButton, TextField } from '@roc/ui';

const useStyles = makeStyles({
  container: {
    paddingTop: 16,
  },
  instruction: {
    fontWeight: 600,
  },
  underline: {
    fontWeight: 600,
    textDecorationLine: 'underline',
  },
});

export const CDAPayment = ({ url, borrowersEmail, loanId, isInternal, cdaEnabled }) => {

  const classes = useStyles();
  const { globalStore } = useBaseStore();
  const {
    documentStore: { documentFormStore },
  } = useDocumentStore();
  const { cdaAndAppraisalOrderDetailsStore } = documentFormStore;

  const handleCopy = () => {
    navigator.clipboard.writeText(url);
    if (isInternal) {
      window.parent.postMessage(url, '*');
    }
    globalStore.notificationStore.showSuccessNotification({
      message: 'Link was copied to clipboard!',
    });
  };

  const handleClose = () => {
    // TODO: On Save
  };

  const handleConfirmDeposit = () => {
    cdaAndAppraisalOrderDetailsStore.requestCdaPayment(loanId, cdaEnabled? "CDA_Loan": "ARR_CDA_Loan");
  };

  return (
    <>
      {url !== '' || url !== null ? (<Grid item container xs={11} spacing={2}>
        <Grid item>
          <Typography className={classes.instruction}>
            Link for the borrower to review and make the payment
          </Typography>
        </Grid>
        <Grid item container alignItems="center" spacing={2}>
          <Grid item xs={12} sm>
            <TextField
              testId="CDAPaymentUrl"
              variant="outlined"
              label="Deposit URL"
              value={url}
              fullWidth
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleCopy}
              testId="copyCDAPaymentUrlButton"
              fullWidth
            >
              COPY LINK
            </Button>
          </Grid>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item>
            <Typography className={classes.instruction}>
              {`Or you can send an email to the borrower @
                    `}<span className={classes.underline}>{borrowersEmail}</span>
              {` with the payment details`}
            </Typography>
          </Grid>
          <Grid item container justifyContent="center">
            <ConfirmationButton
              variant="contained"
              onClick={handleConfirmDeposit}
              color="primary"
              testId="sendCDAPaymentButton"
              confirmDialogProps={{
                title: 'Confirmation',
                body: 'Email will be sent out. Please confirm',
              }}
            >
              Email Link to Borrower
            </ConfirmationButton>
          </Grid>
        </Grid>
      </Grid>) : null}
    </>
  )
}