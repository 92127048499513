import React from 'react';
import {
  Box,

  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,

  Typography,
  useMediaQuery,

} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { Button } from '@roc/ui';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    title: {
      paddingRight: theme.spacing(6),
    },
    dialogContent: {
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(1, 3, 3),
    },
    confirmationMessage: {
      margin: '10px'
    }
  })
);

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  onConfirm: () => void;
}

const ResetSowModal: React.FC<Props> = props => {
  const { isOpen, handleClose, onConfirm } = props;

  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Dialog
      disableEscapeKeyDown
      fullScreen={fullScreen}
      fullWidth
      maxWidth="sm"
      open={isOpen}
      scroll="paper"
      onClose={handleClose}
      aria-labelledby="reset-sow-dialog"
    >
      <DialogTitle disableTypography>
        <Typography className={classes.title} variant="h4">
          Reset Scope Of Work
        </Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent className={classes.dialogContent}>
        <Grid container justifyContent="flex-start" spacing={3}>
          <Typography className={classes.confirmationMessage}>This will delete all of the information entered in this scope of work. This action cannot be reverted.</Typography>
          <Typography className={classes.confirmationMessage}>Are you sure you want to reset it?</Typography>
        </Grid>
        <Box mt={3}>
          <Grid container justifyContent="flex-end" spacing={2}>
            <Grid item xs={12} sm={4}>
              <Button
                color="primary"
                onClick={handleClose}
                variant="outlined"
                fullWidth
                testId="send"
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Button
                color="primary"
                onClick={onConfirm}
                variant="contained"
                fullWidth
                testId="send"
              >
                Yes, Reset
              </Button>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ResetSowModal;
