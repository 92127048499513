import { useLoanStore } from '@roc/feature-loans';
import { ConfirmDialog, TextField } from '@roc/ui';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useLoanActionsStore } from '../hooks/useLoanActionsStore';

export const UpdateFedexTrackingNumberModal = observer(
  ({ open, handleClose }) => {
    const { loanActionsStore } = useLoanActionsStore();
    const { loanStore } = useLoanStore();
    const [trackingNumber, setTrackingNumber] = useState<string>();

    useEffect(() => {
      if (open) {
        const fedexTrackingNumber =
          loanStore.loanDetails?.loanClosingData?.fedexTrackingNumber;
        setTrackingNumber(fedexTrackingNumber);
      }
    }, [open]);

    const confirm = () => {
      loanActionsStore.updateFedexTrackingNumber(trackingNumber);
      handleClose();
    };

    return (
      <ConfirmDialog
        open={open}
        confirmButtonText={'Yes'}
        handleClose={handleClose}
        handleCancel={handleClose}
        handleConfirm={confirm}
        title={'Update Fedex Tracking Number'}
        body={
          <TextField
            testId="trackingNumber"
            value={trackingNumber}
            fullWidth
            label={'Fedex Tracking Number'}
            variant="outlined"
            onChange={e => setTrackingNumber(e.target.value)}
          />
        }
      />
    );
  }
);
/*
    [TOOLTIP_ACTIONS_TYPES.UPDATE_FEDEX_TRACKING_NUMBER]: {
      icon: <CheckCircleIcon />,
      name: 'Update Fedex Tracking Number',
      onClick: () => {
        openButtonModal({
          title: 'Update Fedex Tracking Number',
          action: {
            name: 'Submit',
            onClick: (data) => {
              documentStore.updateFedexTrackingNumber(loanId, data.inputValue, handleClose);
            },
            color: 'primary',
            variant: 'contained'
          },
          modalContent: {
            text: 'Fedex Tracking Number'
          },
          modalType: 'input',
          inputValue: loanClosingData.fedexTrackingNumber
        })
      }
    },
    */
