import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Chip, Tab, Tabs, Toolbar } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import CardMedia from '@material-ui/core/CardMedia';
import { useState } from 'react';
import { formatDate, isNil } from '@roc/feature-utils';
import { observer } from 'mobx-react';

const useStyles = makeStyles({
  root: {
  },
  carousel: {
    height: '100%',
    background: '#eee',
    '& .CarouselItem': {
      display: 'flex',
      height: '100%',
      alignItems: 'center',
    }
  },
  tabRoot: {
    background: '#555',
    '& *': {
      color: 'white',
    },
  }
});
const DocumentTabs = observer(({ store }) => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };
  return (
    <>
      <Tabs
        value={value}
        TabIndicatorProps={{
          style: {
            backgroundColor: '#fff',
            marginBottom: 2,
          }
        }}
        textColor="inherit"
        onChange={handleChange}
        className={classes.tabRoot}
      >
        {store.frontId &&
          <Tab label="Front" style={{
            minWidth: 80
          }} />
        }
        {store.backId &&
          <Tab label="Back" style={{
            minWidth: 80
          }} />
        }
        {store.selfie &&
          <Tab label="Selfie" style={{
            minWidth: 80
          }} />
        }
      </Tabs>
      <Box m={1}>
        {store.frontId &&
          value == 0 &&
          <div>
            <img src={store.frontId?.imgSrc} width={'100%'} height={'auto'} />
          </div>
        }
        {store.backId &&
          value == 1 &&
          <div>
            <img src={store.backId?.imgSrc} width={'100%'} height={'auto'} />
          </div>
        }
        {((!store.backId && store.selfie && value == 1 || store.backId && store.selfie && value == 2)) &&
          <div>
            <img src={store.selfie?.imgSrc} width={'100%'} height={'auto'} />
          </div>
        }
      </Box>
    </>
  )
})

export const IdVerificationDetails = observer(({ store }) => {
  const classes = useStyles();
  const identity = store.borrowerIdvResultsResponse?.au10TixIdvResultsResponse?.parsedResults?.sessionResult?.identity;
  const processingResult = store.borrowerIdvResultsResponse?.au10TixIdvResultsResponse?.parsedResults?.verificationResults?.idv?.payload?.ProcessingReport?.ProcessingResult;

  return (
    <Card className={classes.root} elevation={0} variant='outlined'>
      <Toolbar style={{
        minHeight: 52,
        padding: '8px 16px',
      }}>
        <Typography variant="h6" component="div">
          ID Verification Details
        </Typography>
      </Toolbar>
      <Grid container>
        <Grid item xs={12} md={6} style={{
          padding: 16
        }}>
          <CardMedia style={{ height: '100%', border: '1px solid #555' }}>
            <DocumentTabs store={store} />
          </CardMedia>
        </Grid>
        <Grid item xs={12} md={6}>
          <CardContent style={{
            padding: 16
          }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography gutterBottom variant="body2" color="textSecondary" component="p">
                  First Name
                </Typography>
                <Typography variant="body1" component="p" style={{
                  fontWeight: 'bold'
                }}>
                  {identity?.firstName ?? '-'}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography gutterBottom variant="body2" color="textSecondary" component="p">
                  Middle Name
                </Typography>
                <Typography variant="body1" component="p" style={{
                  fontWeight: 'bold'
                }}>
                  {identity?.middleNames ?? '-'}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography gutterBottom variant="body2" color="textSecondary" component="p">
                  Last Name
                </Typography>
                <Typography variant="body1" component="p" style={{
                  fontWeight: 'bold'
                }}>
                  {identity?.lastName ?? '-'}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography gutterBottom variant="body2" color="textSecondary" component="p">
                  Gender
                </Typography>
                <Typography variant="body1" component="p" style={{
                  fontWeight: 'bold'
                }}>
                  {identity?.gender ?? '-'}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography gutterBottom variant="body2" color="textSecondary" component="p">
                  Date of Birth
                </Typography>
                <Typography variant="body1" component="p" style={{
                  fontWeight: 'bold'
                }}>
                  {!isNil(identity?.dateOfBirth) ? formatDate(identity?.dateOfBirth, 'MM/dd/yyyy') : '-'}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography gutterBottom variant="body2" color="textSecondary" component="p">
                  Document Type
                </Typography>
                <Typography variant="body1" component="p" style={{
                  fontWeight: 'bold'
                }}>
                  {identity?.idType ?? '-'}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography gutterBottom variant="body2" color="textSecondary" component="p">
                  Document Number
                </Typography>
                <Typography variant="body1" component="p" style={{
                  fontWeight: 'bold'
                }}>
                  {identity?.idNumber ?? '-'}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography gutterBottom variant="body2" color="textSecondary" component="p">
                  Date of Expiry
                </Typography>
                <Typography variant="body1" component="p" style={{
                  fontWeight: 'bold'
                }}>
                  {!isNil(processingResult?.DocumentData2?.DateOfExpiry?.value) ? formatDate(processingResult?.DocumentData2?.DateOfExpiry?.value, 'MM/dd/yyyy') : '-'}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography gutterBottom variant="body2" color="textSecondary" component="p">
                  Date of Issue
                </Typography>
                <Typography variant="body1" component="p" style={{
                  fontWeight: 'bold'
                }}>
                  {!isNil(processingResult?.DocumentData2?.DateOfIssue?.value) ? formatDate(processingResult?.DocumentData2?.DateOfIssue?.value, 'MM/dd/yyyy') : '-'}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography gutterBottom variant="body2" color="textSecondary" component="p">
                  Is ID Expired?
                </Typography>
                <Typography variant="body1" component="p" style={{
                  fontWeight: 'bold'
                }}>
                  {isNil(processingResult?.IsDocumentExpired) || processingResult?.IsDocumentExpired ?
                    <Chip label="YES" style={{
                      fontSize: 12,
                      padding: 4,
                      height: 'auto',
                      background: 'lightgreen',
                      color: 'green',
                      fontWeight: 'bold',
                    }} />
                    :
                    <Chip label="NO" style={{
                      fontSize: 12,
                      padding: 4,
                      height: 'auto',
                      background: '#ffdad7',
                      color: 'red',
                      fontWeight: 'bold',
                    }} />
                  }
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography gutterBottom variant="body2" color="textSecondary" component="p">
                  Address
                </Typography>
                <Typography variant="body1" component="p" style={{
                  fontWeight: 'bold'
                }}>
                  {identity?.addressLine1 ?? '-'}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Grid>
      </Grid>
    </Card>
  );
});