import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Box, Grid, lighten, makeStyles, Theme, Typography, useTheme } from '@material-ui/core';
import { LoginLogoImg, Page } from '@roc/feature-app-core';
import { Paper, PaperBorderType, PreviewBackdrop } from '@roc/ui';
import clsx from 'clsx';
import { format } from 'date-fns';
import jsPDF from 'jspdf';
import { useEffect, useRef, useState } from 'react';
import { useDocumentLibraryRoutes } from './../../hooks/useDocumentLibraryRoutes';

const useStyles = makeStyles((theme: Theme) => ({
  documentContainer: {
    position: 'relative',
    height: '100%',
    padding: '16px',
    background: '#FFF',
    fontSize: 16,
    '& table': {
      width: '100%',
      textAlign: 'center',
    },
    '& ul,ol': {
      marginLeft: theme.spacing(3),
      marginBottom: theme.spacing(0.5),
    },
    '& h6': {
      fontSize: 16,
      padding: 4,
      fontWeight: 'bold',
    },
  },
  documentCard: {
    borderRadius: 0,
    marginBottom: theme.spacing(1),
    borderColor: '#aaa',
  },
  documentCardTitle: {
    justifyContent: 'center',
    textAlign: 'center',
    background: lighten(theme.palette.primary.main, 0.5),
    fontWeight: 'bold',
    padding: theme.spacing(0.5),
  },
  maxLtvsCard: {
    '& table th': {
      background: lighten(theme.palette.primary.main, 0.8),
      padding: '4px',
    },
  },
  maxLtvsCardBody: {
    padding: 0,
  },
  borrowerGuarantorChildCardTitle: {
    background: lighten(theme.palette.primary.main, 0.8),
    backgroundImage: 'none',
  },
  containerDocLibrary: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const usePrintModeStyles = makeStyles((theme: Theme) => ({
  documentContainer: {
    fontSize: 8,
    '& ul,ol': {
      marginLeft: theme.spacing(2),
    },
    '& h6': {
      fontSize: 12,
    },
  },
}));

const DocumentCard = ({
  title,
  data,
  paperBorder = PaperBorderType.SOLID_BORDER,
  paperClassName = '',
  paperTitleClassName = '',
  paperBodyClassName = '',
  paperStyle = {},
}) => {
  const classes = useStyles();
  return (
    <Paper
      title={title}
      titleClass={clsx(classes.documentCardTitle, paperTitleClassName)}
      titleVariant={'h6'}
      borderType={paperBorder}
      className={clsx(classes.documentCard, paperClassName)}
      style={paperStyle}>
      <Box p={0.5} className={paperBodyClassName}>
        {documentToReactComponents(data)}
      </Box>
    </Paper>
  )
}

export const AttorneyDirectoryHtml = ({
  contentId,
  data,
  preview = false,
  printMode = false,
  setContainerRef,
}: {
  contentId: string,
  data: any,
  preview?: boolean,
  printMode?: boolean,
  setContainerRef: any,
}) => {
  const theme = useTheme();
  const { documentLibraryRoutesConfig } = useDocumentLibraryRoutes();
  const classes = useStyles();
  const printModeClasses = usePrintModeStyles();
  const containerRef = useRef(null);

  const [printModeContainerStyle, setPrintModeContainerStyle] = useState<any>();
  const [containerStyle, setContainerStyle] = useState<any>();

  useEffect(() => {
    const doc = new jsPDF("p", "mm", "a4");
    let width: any = doc.internal.pageSize.getWidth();
    let height: any = doc.internal.pageSize.getHeight();
    width = `${Math.floor(width)}mm`;
    height = `${Math.floor(height)}mm`;
    setPrintModeContainerStyle({
      margin: 'auto',
      minWidth: width,
      maxWidth: width,
      height: height
    });
    setContainerStyle({
      margin: 'auto',
      maxWidth: theme.breakpoints.values.lg,
    });
  }, []);

  useEffect(() => {
    setContainerRef(containerRef);
  }, [containerRef])

  if (!data) {
    return null;
  }

  return (
    <div ref={containerRef} style={printMode ? printModeContainerStyle : containerStyle}>
      <Page routeDefinition={documentLibraryRoutesConfig.documentLibraryDocument(contentId)}>
        <Box className={clsx(classes.documentContainer, printMode ? printModeClasses.documentContainer : undefined)}>
          {preview && <PreviewBackdrop />}
          <LoginLogoImg imgStyle={{ maxWidth: 200 }} />
          <Box my={2} textAlign='center'>
            <Typography variant={'h5'}>
              <strong>{data.documentTitle}</strong>
            </Typography>
          </Box>
          <Box mb={2} display={'flex'} justifyContent={'space-around'}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <DocumentCard title={data.privateLenderLawCardTitle} data={data.privateLenderLawCardData} />
                <DocumentCard title={data.geraciLawFirmCardTitle} data={data.geraciLawFirmCardData} />
                <DocumentCard title={data.mangelliBilottiPllcCardTitle} data={data.mangelliBilottiPllcCardData} />
                <DocumentCard title={data.hdrbbCardTitle} data={data.hdrbbCardData} />
                <DocumentCard title={data.castleLawCardTitle} data={data.castleLawCardData} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <DocumentCard title={data.lawOfficesOfLawrenceAndelsmanCardTitle} data={data.lawOfficesOfLawrenceAndelsmanCardData} />
                <DocumentCard title={data.deutscheSchneiderLlpCardTitle} data={data.deutscheSchneiderLlpCardData} />
                <DocumentCard title={data.kaplanKaplanDitrapaniLlpCardTitle} data={data.kaplanKaplanDitrapaniLlpCardData} />
                <DocumentCard title={data.schwartzSladkusReichLlpCardTitle} data={data.schwartzSladkusReichLlpCardData} />
              </Grid>
            </Grid>

          </Box>
          <Box m={1} position={'absolute'} right={0} bottom={0} textAlign='right' color={'#ccc'}>
            <Typography variant={'body2'}>
              {`Last Updated: ${format(new Date(data.lastUpdated), 'MM-dd-yyyy')}`}
            </Typography>
          </Box>
        </Box>
      </Page>
    </div>
  );
}