import { Box } from '@material-ui/core';
import React from 'react';

import comingSoonImage from './../../assets/coming-soon.png';

export const ComingSoon = () => {
  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <img
        alt="Coming Soon"
        src={comingSoonImage}
        style={{
          maxWidth: 400,
          width: '100%',
        }}
      />
    </Box>
  );
};
