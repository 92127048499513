import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import { Paper, PaperTitle, SelectField } from '@roc/ui';
import { noOpComparator, filterProps } from '@roc/ui/utils';
import { Grid } from '@material-ui/core';
import { InfiniteScrollGrid } from '@roc/feature-app-core';

const useStyles = makeStyles(theme => ({
  paper: {
    margin: theme.spacing(1),
  },
  miniGrid: {
    minHeight: '424px',
    padding: '16px'
  },
  select: {
    paddingTop: '13px'
  },
  dropdown: {
    marginLeft: '18px'
  }
}));

export const dashboardColumns = [
  {
    field: 'rmName',
    headerName: 'Originator',
    maxWidth: 700,
    comparator: noOpComparator,
    ...filterProps(),
  },
  {
    field: 'count',
    headerName: 'Count',
    maxWidth: 150,
    comparator: noOpComparator,
    ...filterProps(),
  },
];

export const intervalOptions = [
  { label: 'Last 24 Hours', value: 'Last 24 Hours' },
  { label: 'Last 7 days', value: 'Last 7 days' },
  { label: 'Last Month', value: 'Last Month' },
  { label: 'Last 3 Months', value: 'Last 3 Months' },
  { label: 'This Year', value: 'This Year' },
]

export const StatsGrid = observer((props) => {
  const classes = useStyles();
  const { store } = props;

  const handlerDropdownChange = (value) => {
    store.setDateInterval(value);
  }

  const getTitleComponent = () => {
    return (
      <Grid container justifyContent='space-between'>
          <Grid item md={10} >
            <PaperTitle title={props.title} titleVariant='h5' />
          </Grid>
          <Grid item md={2} className={classes.select} alignContent='flex-end' alignItems='flex-end'>
            <SelectField
              className={classes.dropdown}
              options={intervalOptions}
              testId={'intervalOptions'}
              value={store.dateInterval}
              onChange={value => handlerDropdownChange(value)}
            />
          </Grid>
      </Grid>
    );
  }

  return (
    <Grid item  sm={12} xs={12} md={6} xl={6}>
      <Paper
        className={classes.paper}
        titleComponent={getTitleComponent()}>
        <Grid container className={classes.miniGrid}>
          <InfiniteScrollGrid
            key={store.dateInterval}
            columns={dashboardColumns}
            store={store.statsGrid}
          />
        </Grid>
      </Paper>
    </Grid>

  );
});