
import { observer } from "mobx-react";
import { Button, StandardDialog } from '@roc/ui';
import { useEffect } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { TextField, Typography, Grid } from '@material-ui/core';
import { useStore } from "@roc/client-portal-shared/hooks";
import { makeStyles } from '@material-ui/core/styles';
import { BaseLeadStore } from "libs/client-portal-shared/src/app/modules/companyProfile/stores/baseLeadStore/BaseLeadStore";

const useStyles = makeStyles(() => ({
  input: {
    '& input': {
      height: '30px'
    }
  },
}));

export const AddBrokerRelation = observer(({ open, handleClose, salesforceLeadStore }:
  { open: boolean, handleClose: () => void, salesforceLeadStore: BaseLeadStore }) => {
  const { lenderDetailsStore, newLeadStore } = useStore();
  const classes = useStyles();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    await salesforceLeadStore.getAllOwners();
  };

  const handleSaveClick = async () => {
    lenderDetailsStore.addLenderBrokerRelation(handleClose);
    if (salesforceLeadStore.isFromPossibleMatches) {
      newLeadStore.gridStore.fetchGridData();
    }
  };

  const allOwners = salesforceLeadStore?.rocOwners.concat(salesforceLeadStore.facoOwners, salesforceLeadStore.civicOwners);

  return (
    <StandardDialog
      open={open}
      title={'Add Broker Relation'}
      maxWidth="sm"
      handleClose={handleClose}
      dialogContent={
        <>
          <Typography variant="h6" gutterBottom style={{ marginRight: '15px' }}>
            {'Select New Lender Owner'}
          </Typography>
          <Grid>
            <Autocomplete
              id="leads-dashboard-autocomplete"
              selectOnFocus
              handleHomeEndKeys
              options={allOwners}
              value={(allOwners || []).find(o => o.value === salesforceLeadStore.newLeadOwner)}
              inputValue={salesforceLeadStore?.searchOwnerText}
              size="small"
              blurOnSelect={true}
              onInputChange={(e, value) => (salesforceLeadStore.searchOwnerText = value)}
              renderInput={params => <TextField variant={'outlined'} {...params} className={classes.input} />}
              getOptionLabel={option => option?.label ?? ''}
              getOptionSelected={(option, selected) => option?.value === selected?.value}
              onChange={(event, option) => lenderDetailsStore.selectedBrokerOwner = option.value}
              fullWidth
            />
          </Grid>
          <br />
        </>
      }
      dialogActions={
        <>
          <Button
            style={{ marginRight: '8px' }}
            onClick={handleClose}
            color="primary"
            variant="outlined"
            testId="cancel"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSaveClick}
            testId="next"
          >
            Add Relation
          </Button>
        </>
      }
    />
  );
});
