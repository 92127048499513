import {
  Box,
  Grid, IconButton, InputAdornment,
  makeStyles,
  Theme,
  useTheme
} from '@material-ui/core';
import { observer } from 'mobx-react';
import { Search, Close } from '@material-ui/icons';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Page, useBaseStore, useUserStore } from '@roc/feature-app-core';
import { useDocumentLibraryRoutes } from '@roc/feature-document-library-contentful';
import { AlertDialog, TextField } from '@roc/ui';
import { DocumentList } from '../../components/documentList';

const useStyles = makeStyles((theme: Theme) => ({
  documentContainer: {
    position: 'relative',
    height: '100%',
    padding: '16px',
    background: '#FFF',
    fontSize: 16
  },
  title: {
    minWidth: 0,
    justifyContent: 'center',
    textAlign: 'justify',
    color: theme.palette.primary.main
  },
  videoIframe: {
    border: 0,
    height: '504px',
    width: '100%',
    [theme.breakpoints.down(975)]: {
      height: '2100px'
    }
  },
  videoIcon: {
    color: theme.palette.grey[500]
  },
  searchIcon: {
    color: theme.palette.text.secondary
  }
}));

interface TutorialVideosProps {
  contentId: string,
  data: any,
  preview?: boolean,
  printMode?: boolean,
  setContainerRef: any,
  allowPrint?: boolean
}

export const TutorialVideosHtml = observer(({ contentId, data, preview = false, printMode = false,  setContainerRef, allowPrint = false }: TutorialVideosProps) => {
  const theme = useTheme();
  const { documentLibraryRoutesConfig } = useDocumentLibraryRoutes();
  const classes = useStyles();
  const containerRef = useRef(null);
  const [containerStyle, setContainerStyle] = useState<any>();
  const [openVideoDialog, setOpenVideoDialog] = useState(false);
  const [videoToShowData, setVideoToShowData] = useState(null);
  const [searchText, setSearchText] = useState('');
  const { globalStore } = useBaseStore();
  const { userStore } = useUserStore();
  const { userInfo } = userStore;

  useEffect(() => {
    setContainerStyle({
      margin: 'auto',
      maxWidth: theme.breakpoints.values.lg
    });
  }, []);

  useEffect(() => {
    setContainerRef(containerRef);
  }, [containerRef]);

  if (!data) {
    return null;
  }

  const videoTagsContains = (videoItem: any) => {
    return videoItem?.tags?.some(tag => tag.toLowerCase().includes(searchText.trim().toLowerCase()));
  };

  const videosToShow = useMemo(() => {
    let videosToShow = data?.contentJson
      .filter(video =>
        (globalStore.isInternalPortal) ||
        (!video.internalOnly && video.roles && Array.isArray(video.roles) && video.roles.includes(userInfo.role))
      );

    if (searchText && searchText.trim()) {
      videosToShow = videosToShow.filter(video => video.name.toLowerCase().includes(searchText.trim().toLowerCase()) || videoTagsContains(video));
    }
    videosToShow = videosToShow.sort((a: any, b: any) => a.name.localeCompare(b.name));
    return videosToShow;
  }, [searchText]);

  const searchHasText = useMemo(() => {
    return searchText && searchText.trim().length > 0;
  }, [searchText]);

  const handleClick = (videoItem: any) => {
    setOpenVideoDialog(!openVideoDialog);
    setVideoToShowData(videoItem);
  };

  const handleClose = () => {
    setOpenVideoDialog(!openVideoDialog);
    setVideoToShowData(null);
  };

  const handleCopyLink = (url: string) => {
    (window as any).navigator.clipboard.writeText(url);
    globalStore.notificationStore.showSuccessNotification({
      message: 'Link was copied to clipboard!'
    });
  };

  return (
    <div ref={containerRef}>
      <Page routeDefinition={documentLibraryRoutesConfig.documentLibraryDocument(contentId)}>
        <Box className={classes.documentContainer}>
          {openVideoDialog && <AlertDialog
            open={openVideoDialog}
            okButtonText={'Copy link'}
            handleOk={() => handleCopyLink(videoToShowData.url)}
            title={videoToShowData.name}
            body={
              <Grid>
                <iframe src={`${videoToShowData.url}?watermark=0&color=&sharing=0&title=0`}
                        className={classes.videoIframe} title={videoToShowData.name} allowFullScreen></iframe>
              </Grid>
            }
            handleClose={handleClose}
          />}
          <Box mb={1}>
            <Grid container spacing={3} justifyContent='flex-end'>
              <Grid item lg={4} sm={4}>
                <TextField
                  testId='searchTextField'
                  label='Search'
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  style={{
                    width: '100%'
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <Search className={classes.searchIcon} />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      searchHasText && <InputAdornment position='end'>
                        <IconButton
                          size='small'
                          color='primary'
                          onClick={() => setSearchText('')}
                        >
                          <Close />
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>
            </Grid>
          </Box>
          <Box mt={2}>
            <DocumentList
              items={videosToShow ?? []}
              onDocumentItemClick={handleClick}
            />
          </Box>
        </Box>
      </Page>
    </div>
  );
});
