import { Service } from '@roc/feature-app-core';

export const url = {
  GET_HISTORY_BY_RECORD_ID: '/api/v1/lender/recordHistory/getHistoryByRecordId',

};

export class RecordHistoryService extends Service {


  getHistoryRecordById(pageNumber: number, pageSize: number, sortBy: string, sortDir: string, filters: string, dropdownFilters: string, recordId: number, type: string) {
    return this.get(url.GET_HISTORY_BY_RECORD_ID, { pageNumber, pageSize, sortBy, sortDir, filters, dropdownFilters, recordId, type },
      { disableGlobalLoading: true })
  }


}
