import { useTheme } from "@material-ui/core/styles"

export const ClosingDate = ({ value }) => {
  const theme = useTheme();
  return (
    <span style={{
      color: theme.palette.primary.dark
    }}>
      {value}
    </span>
  )
}