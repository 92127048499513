import Box from '@material-ui/core/Box';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DeleteIcon from '@material-ui/icons/Delete';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Theme } from '@material-ui/core/styles/createTheme';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { withTestDataAttribute } from '../testDataAttribute';

const useStyle = makeStyles((theme: Theme) => ({
  icon: {
    padding: theme.spacing(0),
  },
  label: {
    marginTop: theme.spacing(0.25),
  },
}));

export type FileInputProps = {
  label?: string;
  values?: Array<any>;
  onChange?: (files: any) => void;
  multiple?: boolean;
  required?: boolean;
  accept?: string;
  showValues?: boolean;
  allowFileDeletion?: boolean;
  testId: string;
};

export const FileInput = withTestDataAttribute((props: FileInputProps) => {
  const {
    label,
    values,
    showValues,
    required,
    onChange,
    allowFileDeletion,
    ...inputProps
  } = props;
  const classes = useStyle();

  const onFileChange = event => {
    event.stopPropagation();
    event.preventDefault();

    const newFiles = [...event.target.files];
    if (newFiles.length > 0) onChange(newFiles);
  };

  const onFileDelete = (index: number) => {
    const newFiles = [...values];
    newFiles.splice(index, 1);
    onChange(newFiles);
  };

  return (
    <>
      <input
        id="upload-file"
        type="file"
        onChange={onFileChange}
        {...inputProps}
        hidden
      />
      <label htmlFor="upload-file">
        <Grid container direction="row" spacing={1}>
          <Grid item>
            <IconButton
              color="primary"
              component="span"
              className={classes.icon}
            >
              <CloudUploadIcon />
            </IconButton>
          </Grid>
          <Grid item>
            {label && (
              <Typography className={classes.label}>
                {label}
                {required && ' *'}
              </Typography>
            )}
          </Grid>
        </Grid>
      </label>
      {showValues && values && values.length > 0 && (
        <List>
          {values.map((file, index) => (
            <div key={file.name}>
              <Divider component="li" />
              <ListItem>
                <ListItemText>
                  <Box display="flex">
                    <Box flexGrow={1}>{file.name}</Box>
                    {allowFileDeletion && (
                      <Box>
                        <IconButton
                          color="primary"
                          component="span"
                          className={classes.icon}
                          onClick={() => onFileDelete(index)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    )}
                  </Box>
                </ListItemText>
              </ListItem>
            </div>
          ))}
        </List>
      )}
    </>
  );
});
