import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useRocInternalPortalStore } from '../../../hooks';
import { useParams } from 'react-router-dom';
import { ScopeOfWorkV2FormExternalDialog } from '@roc/feature-sow-v2';

export const ScopeOfWorkRevisionInternal = observer(() => {
  const { scopeOfWorkReviewInternalStore } = useRocInternalPortalStore();
  const { drawId } = useParams<{ drawId: string }>();
  const {
    scopeOfWorkV2FormDialogOpen,
    scopeOfWorkV2FormStore,
  } = scopeOfWorkReviewInternalStore;

  const closeScopeOfWorkV2FormDialog = () => {
    scopeOfWorkReviewInternalStore.setScopeOfWorkV2FormDialogOpen(false);
    window.parent.postMessage('sow-review-close-modal-message', '*');
  };

  useEffect(() => {
    return () => {
      scopeOfWorkReviewInternalStore.reset();
    };
  }, [scopeOfWorkReviewInternalStore]);
  useEffect(() => {
    scopeOfWorkReviewInternalStore.drawId = drawId;
    scopeOfWorkReviewInternalStore.fetchSOWRevision(drawId);
    return () => {
      scopeOfWorkReviewInternalStore.reset();
    };
  }, []);

  return (
    <ScopeOfWorkV2FormExternalDialog
      open={scopeOfWorkV2FormDialogOpen}
      onClose={closeScopeOfWorkV2FormDialog}
      store={scopeOfWorkV2FormStore}
      title={'Scope Of Work Revision'}
    />
  );
});

export default ScopeOfWorkRevisionInternal;
