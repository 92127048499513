import { ValuationReviewReportBaseService } from "libs/feature-valuation-review/src/services/valuationReviewReportService";

const url = {
  GET_VALUATION_INFO: "/nportal/rest/priv/valuationReview/getValuationReviewByLoanAndPropertyId",
  GET_PROPERTY_ACTIVITY: "/nportal/rest/priv/property/getPropertyActivityByPropertyId",
  DOWNLOAD_APPRAISAL_REPORT: "/nportal/rest/priv/appraisalReview/downloadAppraisalReport",
  GET_LIQUIDITY_DOCUMENT: "/nportal/rest/priv/appraisalReview/getLiquidityDocument",
  SAVE_VALUATION_DETAILS: "/nportal/rest/priv/valuationReview/saveValuationDetails"
}

export class ValuationReviewReportInternalService extends ValuationReviewReportBaseService {

  getValuationReviewInformationByLoanIdAndPropertyId(loanId: number, propertyId: number) {
    return this.get(`${url.GET_VALUATION_INFO}?propertyId=${propertyId}&loanId=${loanId}`)
  }

  getPropertyActivityByPropertyId(propertyId: number) {
    return this.get(`${url.GET_PROPERTY_ACTIVITY}`,
      { propertyId },
      { disableGlobalLoading: true }
    );
  }

  downloadAppraisalReport(propertyId: number) {
    return this.post(`${url.DOWNLOAD_APPRAISAL_REPORT}?propertyId=${propertyId}`, null)
  }

  getLiquidityDocument(propertyId: number, loanId: number) {
    console.log(propertyId, ' ', loanId)
    return this.get(`${url.GET_LIQUIDITY_DOCUMENT}?propertyId=${propertyId}&loanId=${loanId}`)
  }

  saveValuationDetails(data: any) {
    return this.post(`${url.SAVE_VALUATION_DETAILS}`, data)
  }

}