import { makeStyles } from '@material-ui/core/styles';
import { Layout } from '@roc/feature-app-core';
import { observer } from 'mobx-react-lite';
import { useHistory, useParams } from 'react-router';
import { useRoutes } from '../../hooks/useRoutes';
import { LeadDetailsComponent } from './leadDetailsComponent';
export { leadSourceOptions } from '../leads/constants/constants';
import { ExternalLeadStore } from 'libs/client-portal-shared/src/app/modules/companyProfile/stores/externalLeadStore';
import { useEffect, useState } from 'react';
import { useLeadsStore } from '../../hooks/useLeadsStore';
import { useNewLeadStore } from '../../hooks/useNewLeadStore';

const useStyles = makeStyles(theme => ({
  heading: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  button: {
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(2),
    maxWidth: '250px',
  },
}));
interface ILeadDetails {
  salesforceLeadStore: ExternalLeadStore;
}

export const LeadDetails = observer(({ salesforceLeadStore }: ILeadDetails) => {
  const { push, goBack } = useHistory();
  const { leadsDashboardRoutesConfig, possibleMatchesConfig, crmTaskDashboardRoutesConfig } = useRoutes();
  const { leadsStore } = useLeadsStore();
  const classes = useStyles();
  const { leadId } = useParams<{ leadId: string }>();
  const [openModal, setOpenModal] = useState(false);
  const { newLeadStore } = useNewLeadStore();

  useEffect(() => {
    leadsStore.leadDetailsStore.reset();
  }, [leadId]);

  const saveLead = () => {
    const isFormValid = salesforceLeadStore.editLeadDetailsFormStore.runFormValidationWithMessage();
    if (isFormValid) {
      salesforceLeadStore.editLeadDetails(leadId);
    }
  };

  const goToLeadsDashboard = () => {
    push(leadsDashboardRoutesConfig.leadsDashboard.url);
  };

  return (
    <Layout
      title="Lead Details"
      onBack={() => push(newLeadStore.globalSearch ? possibleMatchesConfig.possibleMatches.url : newLeadStore.crmTaskDashboard ? crmTaskDashboardRoutesConfig.crmTaskDashboard.url : leadsDashboardRoutesConfig.leadsDashboard.url)}
      maxWidth="xl"
    >
      <LeadDetailsComponent
        readOnly={false}
        leadId={leadId}
        salesforceLeadStore={salesforceLeadStore}
        onClose={goToLeadsDashboard}
        onSave={saveLead}
      />
    </Layout>
  );
});
