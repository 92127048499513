import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    docuSignIframe: {
      border: 0,
      height: 'calc(100vh - 170px)',
      width: '100%',
      [theme.breakpoints.down(960)]: {
        height: 'calc(100vh - 110px)',
      },
    },
  })
);

export const PublicCreditInformation = observer(({ store, handleClose = null }) => {

  const classes = useStyles();
  const docusignUrl = store?.embeddedDocusignLink;

  useEffect(() => {
    window.addEventListener('message', handleWindowMessage);
    return () => window.removeEventListener('message', handleWindowMessage);
  }, []);

  const handleWindowMessage = (event: MessageEvent) => {
    switch (event.data) {
      case 'borrower-details-verification-docusign-done':
        store?.confirmDocusign(handleClose);
        break;
    }
  };

  return docusignUrl ? (
    <iframe
      className={classes.docuSignIframe}
      onLoad={() => { }}
      src={docusignUrl}
    />
  ) : null;
});