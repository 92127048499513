import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { useDocumentStore } from '../../../hooks/useDocumentStore';
import { ASSIGNMENT_AND_CONVEYANCE_ENTITY_TYPE } from '../../../stores/documentForms/docusignStore';
import { DocusignDialog } from '../common/docusignDialog';

interface Props {
  onClose: () => void;
  open: boolean;
}

const AssignmentAndConveyanceDialog = observer((props: Props) => {
  const { open, onClose } = props;
  const { documentStore } = useDocumentStore();
  const { docusignStore } = documentStore.documentFormStore;
  const { iframeUrl, isStatusAccepted, errorMessage } = docusignStore;

  useEffect(() => {
    if (open) {
      docusignStore.initializeDialog(ASSIGNMENT_AND_CONVEYANCE_ENTITY_TYPE);
    }
  }, [open]);

  return (
    <DocusignDialog
      title={'Assignment and Conveyance'}
      open={open}
      onClose={onClose}
      isStatusAccepted={isStatusAccepted}
      iframeUrl={iframeUrl}
      errorMessage={errorMessage}
    />
  );
});

export default AssignmentAndConveyanceDialog;
