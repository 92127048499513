import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Box, Toolbar, Typography } from '@material-ui/core';
import { formatCurrency, formatPercentage } from '@roc/ui/utils';
import { observer } from 'mobx-react';
import { ValuationReviewReportStore } from '../stores/valuationReviewReportStore';

const useStyles = makeStyles({
  table: {
    minWidth: 400,
    '& .MuiTableRow-head th': {
      fontWeight: 'bold',
    },
  },
  tableContainer: {
    maxHeight: 200,
  },
});

function createData(name: string, subject: number, zillow: number, zillowVariance: number) {
  return { name, subject, zillow, zillowVariance };
}

const getRowsInfo = (marketSalesData: any) => {
  const rows = [];
  rows.push(createData('Median Sale Price', marketSalesData?.medianSubjectSalesPrice, marketSalesData?.medianZillowSalesPrice, marketSalesData?.varianceMedianZillowPrice));
  rows.push(createData('Median Sale Price /sqft', marketSalesData?.medianSubjectSalesPriceBySqFt, marketSalesData?.medianZillowSalesPriceBySqFt, marketSalesData?.varianceMedianZillowPriceBySqFt));
  return rows;
}

export type Props = {
  store: ValuationReviewReportStore;
}

export const MarketSaleDataComparisonTable = observer((props: Props) => {
  const classes = useStyles();
  const { store } = props;
  const { valuationReviewInfo } = store;
  const { marketSalesData } = valuationReviewInfo;
  const rows = getRowsInfo(marketSalesData)
  return (
    <Paper elevation={0} variant='outlined'>
      <Toolbar style={{
        minHeight: 52,
        padding: '8px 16px',
      }}>
        <Typography variant="h6" component="div">
          Market Sales Data Comparison
        </Typography>
      </Toolbar>
      <TableContainer className={classes.tableContainer}>
        <Table className={classes.table} size='small' stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell align="center">Subject</TableCell>
              <TableCell align="center">Zillow</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, i) => (
              <TableRow key={i}>
                <TableCell variant='head' component="th" scope="row" align="left">
                  <strong>{row.name}</strong>
                </TableCell>
                <TableCell component="th" scope="row" align="center" valign='top'>
                  <Box>{formatCurrency(row.subject)}</Box>
                </TableCell>
                <TableCell component="th" scope="row" align="center">
                  <Box>{formatCurrency(row.zillow)}</Box>
                  <Box mt={0.5}>
                    <Typography component={'span'} color={'textSecondary'} variant='body2'>
                      Variance:
                    </Typography>
                    {' '}
                    <strong>{formatPercentage(row.zillowVariance)}</strong>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
)