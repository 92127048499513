import { Grid, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { observer } from "mobx-react";
import { StepsContainer } from "../../../components/stepsContainer/stepsContainer";
import { MersDetailsForm } from "./mersDetailsForm";
import { useEffect, useState } from "react";
import { useStore } from '@roc/client-portal-shared/hooks';

import { DocumentSigning } from "./documentSigning";
import { DocusignStatus, FormStatus } from "../stores/mersOnBoardingStore";
import { MersOnboardingWelcome } from "./mersOnBoardingWelcome";
import { MersOnboardingThankYou } from "./mersOnBoardingThankYou";
import { MersOnboardingDocusignError } from "./mersOnboardingDocusignError";

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    padding: theme.spacing(3, 0, 4),
    borderRadius: theme.spacing(2),
  },
  stepper: {
    maxWidth: 800,
    margin: 'auto',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5),
    },
  },
}))

export const MersOnboarding = observer(({ onClose, companyProfileStore }) => {
  const { globalStore } = useStore();
  const { loanFundingPreferenceStore } = companyProfileStore;
  const { mersOnboardingStore } = loanFundingPreferenceStore;

  const [showWelcome, setShowWelcome] = useState(true);
  const { activeStep } = mersOnboardingStore;
  const finished = mersOnboardingStore.formInfo?.status === FormStatus.FINISHED;
  const [documentSigned, setDocumentSigned] = useState(DocusignStatus.PENDING);

  useEffect(() => {
    window.addEventListener('message', handleWindowMessage);
    return () => window.removeEventListener('message', handleWindowMessage);
  }, []);

  const handleWindowMessage = (event: MessageEvent) => {
    switch (event.data) {
      case 'mers-onboarding-docusign-done':
        loanFundingPreferenceStore.updateLabel();
        setDocumentSigned(DocusignStatus.SUCCESS);
        break;
      case 'mers-onboarding-docusign-incomplete':
        setDocumentSigned(DocusignStatus.FAILED);
        break;
    }
    mersOnboardingStore.setDefaults();
  };

  const classes = useStyles();
  const steps = [
    {
      title: 'Lender Details',
      icon: null,
      onBack: () => {
        mersOnboardingStore.goPrevStep();
      },
      onNext: async () => {
        mersOnboardingStore.runFormValidation();
        if (mersOnboardingStore.form.meta.isValid) {
          if (activeStep !== 1) {
            mersOnboardingStore.goNextStep();
          }
        }
        else if (!mersOnboardingStore.form.fields.mersTraining.value) {
          globalStore.notificationStore.showErrorNotification({ message: 'MERs training completion is required.', });
        } else {
          globalStore.notificationStore.showErrorNotification({ message: 'Please fix the validation errors.', });
        }
      },
      allowBack: false,
      allowNext: true,
    },
    {
      title: 'Electronic Signature',
      icon: null,
      onBack: () => {
        mersOnboardingStore.goPrevStep();
      },
      onNext: async () => { },
      allowBack: finished ? false : true,
      allowNext: false,
    }
  ];

  return (
    <Grid container>
      {showWelcome && !finished ? (
        <MersOnboardingWelcome
          disableStart={false}
          onStart={() => setShowWelcome(false)}
        />
      ) : (
        <Grid container>
          {documentSigned === DocusignStatus.SUCCESS ? <MersOnboardingThankYou onFinish={onClose} />
            : documentSigned === DocusignStatus.FAILED ? <MersOnboardingDocusignError onFinish={onClose} />
              : <StepsContainer
                activeStep={activeStep}
                disableNavigation={true}
                steps={steps}
                stepperClassName={classes.stepper}
              >
                {activeStep === 0 && (
                  <MersDetailsForm store={mersOnboardingStore} />
                )}
                {activeStep === 1 && (
                  <DocumentSigning companyProfileStore={companyProfileStore} />
                )}
              </StepsContainer>
          }
        </Grid>
      )
      }
    </Grid>
  )
})