import { Service } from '@roc/feature-app-core';
import { BorrowerDetailsVerificationService } from './borrowerDetailsVerificationService';

const url = {
  SAVE_BORROWER_DETAILS:
    '/api/v1/loan/borrowerDetailsVerification/saveBorrowerDetailsForm',
  SAVE_TRACK_RECORD_PROPERTY:
    '/api/v1/loan/borrowerDetailsVerification/addTrackRecord',
  EDIT_TRACK_RECORD_PROPERTY:
    '/api/v1/loan/borrowerDetailsVerification/editTrackRecord',
  SAVE_TRACK_RECORD_ENTITY:
    '/api/v1/loan/borrowerDetailsVerification/saveBorrowerEntityBorrower',
  EDIT_TRACK_RECORD_ENTITY:
    '/api/v1/loan/borrowerDetailsVerification/editBorrowerEntity',
  REMOVE_TRACK_RECORD_ENTITY:
    '/api/v1/loan/borrowerDetailsVerification/removeBorrowerEntityBorrower',

  SAVE_BORROWER_GC_BROKER_DATA:
    '/api/v1/loan/borrowerDetailsVerification/saveBorrowerGCAndBrokerData',

  GET_TASKS: '/api/v1/tasks/getTasks',
  CREATE_TASK: '/api/v1/tasks/createTasks',
  UPLOAD_TASK_FILE: '/api/v1/tasks/uploadTaskFile',
  DOWNLOAD_TASK_FILE: '/api/v1/tasks/downloadTaskFile',
};

export class PrivateBorrowerDetailsVerificationService extends BorrowerDetailsVerificationService {
  saveBorrowerDetails(borrowerDetails, driversLicense: File) {
    const borrowerDetailsJson = new Blob([JSON.stringify(borrowerDetails)], {
      type: 'application/json',
    });

    const formData = new FormData();
    formData.append('borrowerDetailsForm', borrowerDetailsJson);
    formData.append('driversLicense', driversLicense);

    return this.post(url.SAVE_BORROWER_DETAILS, formData);
  }

  saveTrackRecordProperty(data: any) {
    return this.post(url.SAVE_TRACK_RECORD_PROPERTY, data);
  }

  editTrackRecordProperty(data: any) {
    return this.post(url.EDIT_TRACK_RECORD_PROPERTY, data);
  }

  saveTrackRecordEntity(data: any, id: number) {
    return this.post(`${url.SAVE_TRACK_RECORD_ENTITY}?borrowerId=${id}`, data);
  }

  editTrackRecordEntity(data: any, id: number) {
    return this.post(
      `${url.EDIT_TRACK_RECORD_ENTITY}?borrowerEntityId=${id}`,
      data
    );
  }

  removeTrackRecordEntity(id: number) {
    return this.delete(
      `${url.REMOVE_TRACK_RECORD_ENTITY}?borrowerEntityBorrowerId=${id}`
    );
  }

  saveBorrowerGCAndBrokerData(data: any, token: string) {
    return this.post(
      `${url.SAVE_BORROWER_GC_BROKER_DATA}?token=${token}`,
      data
    );
  }

  getTasks(body) {
    return this.post(url.GET_TASKS, body);
  }

  createTask(body: any) {
    return this.post(url.CREATE_TASK, body);
  }

  uploadTaskFile(body: any) {
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    return this.post(url.UPLOAD_TASK_FILE, body, config);
  }

  downloadTaskFile(taskId) {
    return this.get(url.DOWNLOAD_TASK_FILE + '?taskId=' + taskId, null, {
      responseType: 'arraybuffer',
    }).then(response => new Blob([response.data]));
  }
}
