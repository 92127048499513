export const amortizationTypeTooltip =
  'Partial Interest Only loans have an initial\nIO period, followed, by a fully amortizing\nperiod until maturity. The initial IO period\ngenerally depends on the Rate Type.\n\n5/6 ARM = 5 years\n7/6 ARM = 7 years\n10/6 ARM & 30 YR FRM = 10 years';

export const amortizationOptions = [
  { label: 'Fully Amortizing', value: 'fullyAmortizing' },
  { label: 'Partial Interest Only ', value: 'partialInterest' },
];

export const ltvMapper = {
  '5': '0-5',
  '10': '6-10',
  '15': '11-15',
  '20': '16-20',
  '25': '21-25',
  '30': '26-30',
  '35': '31-35',
  '40': '36-40',
  '45': '41-45',
  '50': '46-50',
  '55': '50-55',
  '60': '55-60',
  '65': '61-65',
  '70': '66-70',
  '75': '71-75',
  '80': '76-80',
  '85': '81-85',
};

export const FROM_0_TO_5 = '0-5';
export const FROM_6_TO_10 = '6-10';
export const FROM_11_TO_15 = '11-15';
export const FROM_16_TO_20 = '16-20';
export const FROM_21_TO_25 = '21-25';
export const FROM_26_TO_30 = '26-30';
export const FROM_31_TO_35 = '31-35';
export const FROM_36_TO_40 = '36-40';
export const FROM_41_TO_45 = '41-45';
export const FROM_46_TO_50 = '46-50';
export const FROM_50_TO_55 = '50-55';
export const FROM_55_TO_60 = '55-60';
export const FROM_61_TO_65 = '61-65';
export const FROM_66_TO_70 = '66-70';
export const FROM_71_TO_75 = '71-75';
export const FROM_76_TO_80 = '76-80';
export const FROM_81_TO_85 = '81-85';

export const citizenshipStatusOptions = [
  { label: 'US Citizen', value: 'US Citizen' },
  { label: 'US Permanent Resident(Green Card Holder)', value: 'US Permanent Resident(Green Card Holder)' },
  { label: 'US Resident with Valid Visa', value: 'US Resident with Valid Visa' },
  { label: 'Foreign National', value: 'Foreign National' },
];

export const citizenshipStatusOptionsRental = [
  { label: 'US Citizen', value: 'US Citizen' },
  { label: 'US Permanent Resident(Green Card Holder)', value: 'US Permanent Resident(Green Card Holder)' },
  { label: 'Foreign National', value: 'Foreign National' },
];

export const idTypeOptions = [
  { label: "US Driver's License / Government ID", value: "US Driver's License / Government ID" },
  { label: "US Passport", value: "US Passport" },
  { label: "Green Card", value: "Green Card" },
  { label: "Visa", value: "Visa" },
  { label: "Non-US Passport", value: "Non-US Passport" }
]

export const US_TREASURY_5_YR_DESC = "5 Yr US Tsry";
export const US_TREASURY_5_YR_INDEX = 5001;
export const US_TREASURY_10_YR_DESC = "10 Yr US Tsry";
export const US_TREASURY_10_YR_INDEX = 5002;
