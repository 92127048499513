import { useBaseStore } from '@roc/feature-app-core';
import { useLoanStore } from '@roc/feature-loans';
import { LoanStatus } from '@roc/feature-utils';
import { DialogState, EditableDialog, SubmitButton } from '@roc/ui';
import { PricingModelTable } from 'libs/feature-loans/src/loanSubmission/multifamilyBridge/components/pricingModelTable';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { CustomCard, CustomCardRow } from '../../customCard';

export const PricingModelCardMultifamily = observer(() => {
  const { loanStore } = useLoanStore();
  const { loanDetails } = loanStore;

  return (
    <CustomCard title="Pricing Model">
      <PricingModelTable data={loanDetails?.pricingModel} />
    </CustomCard>
  );
});
