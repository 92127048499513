import { StandardDialog } from '@roc/ui';
import { useDocumentStore } from '@roc/feature-documents';
import { DocumentStatus } from '../../../../constants';
import { Document } from '@roc/feature-types';
import { AppraisalOrderDetails } from './appraisalOrderDetails';
import { useStore } from '@roc/client-portal-shared/hooks';
import { useEffect } from 'react';

export type AppraisalOrderDetailsDialogProps = {
  open?: boolean;
  onClose?: () => void;
  loan: any;
  document: Document;
};

export const AppraisalOrderDetailsWithARRDialog = (
  props: AppraisalOrderDetailsDialogProps
) => {
  const { open, onClose, loan, document } = props;
  const { globalStore } = useStore();
  const { documentStore } = useDocumentStore();
  const { documentFormStore } = documentStore;
  const { cdaAndAppraisalOrderDetailsStore } = documentFormStore;

  useEffect(() => {
    cdaAndAppraisalOrderDetailsStore.setIsInternal(
      globalStore.isInternalPortal
    );
  }, []);

  const onClick = () => {
    document.status = DocumentStatus.ACCEPTED;
    cdaAndAppraisalOrderDetailsStore.acceptDocument(document);
  };

  return (
    <StandardDialog
      open={open}
      title="Appraisal Order Details"
      maxWidth="md"
      handleClose={onClose}
      dialogContent={
        <AppraisalOrderDetails
          loan={loan}
          document={document}
          onSubmitLoanPayments={onClose}
          onClick={onClick}
          isTamariskLender={globalStore.userFeatures.tamariskAppraisal}
        />
      }
    />
  );
};
