// ----------------------------------------------------------------------

export default function Tooltip(theme) {
  return {
    MuiTooltip: {
      tooltip: {
        backgroundColor: theme.palette.grey[800],
      },
      arrow: {
        color: theme.palette.grey[800],
      },
    },
  };
}
