import { InputAdornment, InputLabelProps, TextField } from '@material-ui/core';
import React from 'react';
import { FieldLabel } from '../fieldLabel/fieldLabel';
import { NumberFormat } from '../numberFormat/numberFormat';

export interface PercentageFieldProps {
  label?: string;
  name?: string;
  value?: number | string;
  onChange?: (name: string, value: number | string) => void;
  onBlur?: () => void;
  disabled?: boolean;
  required?: boolean;
  error?: boolean;
  errorText?: string;
  fullWidth?: boolean;
  margin?: string;
  variant?: string;
  inputClassname?: string;
  standaloneLabel?: boolean;
  testId: string;
  decimalScale?: number;
  tooltip?: string;
  limitedPercentage?: boolean;
  allowNegative?: boolean;
  inputLabelProps?: InputLabelProps;
  style?: any;
  onKeyDown?: any;
  min?: number,
  max?: number
}

const isRangedPercentage = (values, min, max) => {
  const { floatValue } = values;
  return floatValue >= min && floatValue <= max || floatValue === undefined;
}

const _PercentageField: React.FC<PercentageFieldProps> = ({
  label,
  name,
  value,
  onChange,
  onBlur,
  disabled,
  required,
  error,
  errorText,
  fullWidth,
  margin,
  variant,
  inputClassname,
  decimalScale,
  testId,
  limitedPercentage,
  allowNegative = false,
  inputLabelProps,
  style = {},
  onKeyDown,
  min = 0,
  max = 100
}) => {
  return (
    <NumberFormat
      isAllowed={(values) => limitedPercentage ? isRangedPercentage(values, min, max) : true}
      testId={testId}
      label={label}
      name={name}
      value={value}
      onValueChange={values => {
        onChange &&
          onChange(name, values.floatValue ? values.floatValue : values.value);
      }}
      onBlur={onBlur}
      displayType="input"
      mask=""
      allowNegative={allowNegative}
      customInput={TextField}
      InputProps={{
        endAdornment: <InputAdornment position="end">%</InputAdornment>,
      }}
      InputLabelProps={inputLabelProps}
      decimalScale={decimalScale}
      disabled={disabled}
      required={required}
      error={error}
      helperText={errorText}
      fullWidth={fullWidth}
      margin={margin}
      variant={variant}
      className={inputClassname}
      style={style}
      onKeyDown={onKeyDown}
    />
  );
};

export const PercentageField = (props: PercentageFieldProps) => {
  const { standaloneLabel, tooltip, ...muiComponentProps } = props;
  return (
    <>
      {standaloneLabel && muiComponentProps.label ? (
        <FieldLabel tooltip={tooltip}>
          {muiComponentProps.label}
          {muiComponentProps.required && ' *'}
        </FieldLabel>
      ) : null}
      <_PercentageField
        {...muiComponentProps}
        label={
          standaloneLabel
            ? undefined
            : props.required
              ? muiComponentProps.label + ' *'
              : muiComponentProps.label
        }
      />
    </>
  );
};
