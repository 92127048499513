import { Box, Divider, Grid, List, ListItem, ListItemText, Typography, makeStyles } from "@material-ui/core";
import { Layout } from "@roc/feature-app-core";
import { Button, Card, ConfirmationButton, Paper, TestDataAttribute } from "@roc/ui";
import { observer } from "mobx-react";
import { DrawDqGrid } from "./drawDqGrid";
import { formatCurrency } from "@roc/ui/utils";
import { useEffect, useState } from "react";
import { DrawDqDataBaseStore } from "../stores";
import { useParams } from "react-router";
import { sanitizeTestId } from '@roc/feature-utils';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
}))

const usdAmount = value => {
  return (value || value === 0) && formatCurrency(Number(value));
};

export const listItem = (label, value) => {
  const testId = sanitizeTestId(label);
  return (
    <ListItem>
      <ListItemText>
        <Box display="flex">
          <Box flexGrow={1}>{label}</Box>
          <TestDataAttribute id={testId}>
            <Box>{value}</Box>
          </TestDataAttribute>
        </Box>
      </ListItemText>
    </ListItem>
  );
};

export interface DrawDqDataProps {
  store: DrawDqDataBaseStore;
}

export const DrawDqData = observer((props: DrawDqDataProps) => {
  const classes = useStyles();
  const { store } = props;
  const { drawId } = useParams<{ drawId: string }>();
  const [confirmationModalOpen, setConfirmationModaOpen] = useState(false);

  useEffect(() => {
    store.drawId = drawId;
  }, [store, drawId])

  const saveData = () => {
    store.saveData(drawId);
  }

  const handleCloseConfirmModal = () => {
    setConfirmationModaOpen(false);
  }

  const handleConfirmationModal = () => {
    saveData();
    handleCloseConfirmModal();
  }

  const handleCancel = () => {
    window.parent.postMessage('draw-dq-data-cancel', '*');
  }

  return (
    <Layout title="Delinquent Data" maxWidth={'xl'}>
      <Paper className={classes.paper}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DrawDqGrid />
          </Grid>
          <Grid container item spacing={2}>
            <Grid item xs={12}>
              <Card title="Summary">
                <Grid item xs={12} md={6}>
                  <List>
                    {listItem('Total Draw Funds', usdAmount(store.summaryInformation?.totalFunds))}
                    <Divider variant="middle" component="li" />
                    {listItem('Draw Funds Applied', usdAmount(store.summaryInformation?.fundsApplied))}
                    <Divider variant="middle" component="li" />
                    {listItem('Remaining Draw Funds Available', usdAmount(store.summaryInformation?.fundsAvailable))}
                    <Divider variant="middle" component="li" />
                  </List>
                </Grid>
              </Card>
            </Grid>
          </Grid>
          <Grid container item spacing={2} justifyContent="flex-end">
            <Button style={{ marginRight: '8px' }} testId="cancel" variant="outlined" color="primary" onClick={handleCancel}>
              Cancel
            </Button>

            <ConfirmationButton
              variant="contained"
              onClick={handleConfirmationModal}
              color="primary"
              testId="confirmApply"
              confirmDialogProps={{
                title: 'Confirmation',
                body: 'Are you sure you want to apply these changes?',
              }}
              disabled={!store.isSaveEnabled}
            >
              Apply
            </ConfirmationButton>
          </Grid>
        </Grid>
      </Paper>
    </Layout>
  )
})