import React, { useEffect, useState } from 'react';
import {
  Grid,
  Typography,
  Button,
  Link,
  Box,
  makeStyles,
  createStyles,
  Theme,
  Divider,
} from '@material-ui/core';
import { observer } from 'mobx-react';
import BorrowerInformation from '../components/borrowerInformation';
import QuickChecks from '../components/quickChecks';
import { BackgroundItemStore } from '../stores/backgroundItemStore';
import LetterOfExplanation from '../components/letterOfExplanation';
import CriminalRecord from '../components/criminalRecord';
import ArrestRecord from '../components/arrestRecord';
import BankruptcyRecord from '../components/bankruptcyRecord';
import ForeclosureRecord from '../components/foreclosureRecord';
import OtherSSNRecord from '../components/otherSSN';
import GlobalSanctionRecord from '../components/globalSanctionRecord';
import LienJudgmentRecord from '../components/lienJudgment';
import LawsuitRecord from '../components/lawsuitRecord';
import DocketRecord from '../components/docketRecord';
import QuickAnalysisFlags from '../components/quickAnalysisFlag';
import { ConfirmDialog, ConfirmationButton, StandardDialog, IconButton } from '@roc/ui';
import { BORROWER_REVIEW_TYPES } from '../components/utils/backgroundUtils';
import { CloudDownload, LinkOutlined } from '@material-ui/icons';
import BorrowerExperienceData from '../components/borrowerExperienceData';
import BackgroundComment from '../components/bacgroundComment';
import { ManualOrAutomatedChip } from '../components/manualOrAutomatedChip';
import { ReviewType } from '../types/reviewType';
import { BackgroundItemExternalStore } from '../stores';
import { BackgroundItemBaseStore } from '../stores/backgroundItemBaseStore';
import AuditLogs from '../components/utils/auditLogs';
import BorrowerRecords from '../components/borrowerRecords';
import SSNAddressFraudRecord from '../components/ssnAddressFraudRecord';
import ExcludedPartyRecord from '../components/excludedPartyRecord';
import OtherNamesRecord from '../components/otherNamesForSSNRecord';
import { ResolutionInfo } from '../components/utils/resolutionInfo';
import { Resolution } from '../types/resolution';
import { useResolutionStore } from '../hooks/useResolutionStore';
import EscalatedKeywords from '../components/escalatedKeywords';
import SaveButtonWithPermissions from 'libs/ui/src/lib/components/shared/save-button-with-permissions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    radioFieldContainer: {
      '& .MuiFormLabel-root': {
        fontSize: '13px',
      },
    },
    addressWrapper: {
      '& .MuiInputBase-root.Mui-disabled': {
        backgroundColor: theme.palette.grey[50],
      },
      padding: '16px',
    },
    addressGrid: {
      paddingTop: '12px',
    },
    buttonsGrid: {
      display: 'flex',
      alignItems: 'center',
    },
    gridContainer: {
      padding: theme.spacing(0, 2)
    },
    link: {
      color: '#2199E0',
      cursor: 'pointer',
      display: 'flex'
    }
  })
);

interface Props {
  hideTitle?: boolean;
  store: BackgroundItemBaseStore;
  onClose?: () => void;
  showEmptySections?: boolean;
  showBorrowerInformation?: boolean;
  showBorrowerExperience?: boolean;
  showResolutions?: boolean;
  showButtons?: boolean;
}

export const BackgroundReviewForm = observer((props: Props) => {
  const {
    store,
    hideTitle = false,
    showEmptySections = true,
    showBorrowerExperience = true,
    showBorrowerInformation = true,
    showResolutions = true,
    showButtons = true,
  } = props;
  const classes = useStyles();
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [confirmCompleteReviewModalOpen, setConfirmCompleteReviewModalOpen] = useState(false);
  const { backgroundSection } = store.backgroundSectionDetails;
  const { resolutionStore } = useResolutionStore();

  useEffect(() => {
    const fetchData = async () => {
      store.getBackgroundReviewData(
        BORROWER_REVIEW_TYPES.BORROWER_BACKGROUND_REVIEW
      );
    };
    fetchData();
  }, []);

  useEffect(() => {
    resolutionStore.setInternal(store.isInternal);
  }, [store.isInternal]);

  const handleCloseConfirmModal = () => {
    setConfirmModalOpen(false);
  };

  const handleCloseConfirmCompleteReviewModal = () => {
    setConfirmCompleteReviewModalOpen(false);
  }

  const handleConfirmAction = () => {
    store.generateResolutions(BORROWER_REVIEW_TYPES.BORROWER_BACKGROUND_REVIEW);
    handleCloseConfirmModal();
  };

  const handleConfirmationCompleteReviewAction = () => {
    store.submitReview(BORROWER_REVIEW_TYPES.BORROWER_BACKGROUND_REVIEW, handleCloseButton);
    handleCloseConfirmCompleteReviewModal();
  }

  const handleCloseButton = () => {
    if (store.isInternal) {
      window.parent.postMessage('close-modal', '*');
    }
    if (props.onClose) {
      props.onClose();
    }
  };

  const openLegacyForm = () => {
    if (store.showLegacyFormLink) {
      window.parent.postMessage('open-legacy-form', '*');
    }
    if (store.showLegacyFormLinkFromBorrowerForInternal) {
      const url = `/nportal/#/loan_information/${store.loanId}?openLegacyBorrowerBackgroundReview=true&borrowerId=${store.currentBorrower}`
      window.open(url, "_blank", "PopUp" + Math.random() +
        ",scrollbars=1,menubar=0,resizable=1,width=" +
        window.outerWidth +
        ",height=" +
        window.outerHeight
      );
    }
  }

  const handleOpenResolutionInfo = (data: any) => {
    resolutionStore.handleResolutionInfo(data);
  }

  const handleCloseResolutionInfo = () => {
    resolutionStore.setResolution(null);
    resolutionStore.setResolutionInfoOpen(false);
  }

  return (
    <>
      <Box mt={3} width={'100%'}>
        <ConfirmDialog
          open={confirmModalOpen}
          confirmButtonText={'Yes'}
          handleClose={handleCloseConfirmModal}
          handleCancel={handleCloseConfirmModal}
          handleConfirm={handleConfirmAction}
          title={'Request Resolution'}
          body={`Are you sure you want to request the resolutions?`}
        />
        <StandardDialog
          open={resolutionStore.resolutionInfoOpen}
          title={"Resolution"}
          maxWidth="sm"
          handleClose={handleCloseResolutionInfo}
          dialogContent={
            <Grid container>
              <ResolutionInfo resolution={resolutionStore.resolution} resolutionStore={resolutionStore} showComment />
            </Grid>
          }
        />
        {
          !hideTitle &&
          <Box mb={2} display={'flex'} alignItems={'center'}>
            <Typography variant="h4">Background Review Report</Typography>
            {store.isInternal &&
              <ManualOrAutomatedChip automated={store.borrowerJson?.automated} />
            }
          </Box>
        }
        <Grid container spacing={2} className={classes.gridContainer}>
          {(store.showLegacyFormLink || store.showLegacyFormLinkFromBorrowerForInternal) && (
            <Grid item xs={12}>
              <Box display="flex" justifyContent='flex-end'>
                {store.isInternal && <Link onClick={openLegacyForm} className={classes.link}><LinkOutlined />&nbsp;Open Legacy Form</Link>}
              </Box>
            </Grid>
          )}
        </Grid>
        <Grid className={classes.addressGrid}>
          {showBorrowerInformation && (
            <Grid className={classes.addressWrapper}>
              <BorrowerInformation store={store} type={ReviewType.BACKGROUND} />
            </Grid>
          )}
          <Grid className={classes.addressWrapper}>
            <BorrowerRecords store={store} />
          </Grid>
          {(showEmptySections || store?.quickChecks?.length > 0) && (
            <Grid className={classes.addressWrapper}>
              <QuickChecks store={store} openResolutionInfo={handleOpenResolutionInfo} />
            </Grid>
          )}
          {(showEmptySections || store?.quickAnalysisFlags?.length > 0) && (
            <Grid className={classes.addressWrapper}>
              <QuickAnalysisFlags store={store} />
            </Grid>
          )}
          {(showEmptySections || store?.escalatedKeywordFlags?.length > 0) && (
            <Grid className={classes.addressWrapper}>
              <EscalatedKeywords store={store} openResolutionInfo={handleOpenResolutionInfo} />
            </Grid>
          )}
          {(showEmptySections || backgroundSection?.criminalRecord?.length > 0) && (
            <Grid className={classes.addressWrapper}>
              <CriminalRecord store={store} openResolutionInfo={handleOpenResolutionInfo} />
            </Grid>
          )}
          {(showEmptySections || backgroundSection?.arrestRecord?.length > 0) && (
            <Grid className={classes.addressWrapper}>
              <ArrestRecord store={store} openResolutionInfo={handleOpenResolutionInfo} />
            </Grid>
          )}
          {(showEmptySections || backgroundSection?.bankruptcyRecord?.length > 0) && (
            <Grid className={classes.addressWrapper}>
              <BankruptcyRecord store={store} openResolutionInfo={handleOpenResolutionInfo} />
            </Grid>
          )}
          {(showEmptySections || backgroundSection?.foreclosureRecord?.length > 0) && (
            <Grid className={classes.addressWrapper}>
              <ForeclosureRecord store={store} openResolutionInfo={handleOpenResolutionInfo} />
            </Grid>
          )}
          {(showEmptySections || backgroundSection?.otherSSNAssociated?.length > 0) && (
            <Grid className={classes.addressWrapper}>
              <OtherSSNRecord store={store} openResolutionInfo={handleOpenResolutionInfo} />
            </Grid>
          )}
          {(showEmptySections || backgroundSection?.ssnAddressFraudRecord?.length > 0) && (
            <Grid className={classes.addressWrapper}>
              <SSNAddressFraudRecord store={store} openResolutionInfo={handleOpenResolutionInfo} />
            </Grid>
          )}
          {(showEmptySections || backgroundSection?.excludedPartyRecord?.length > 0) && (
            <Grid className={classes.addressWrapper}>
              <ExcludedPartyRecord store={store} openResolutionInfo={handleOpenResolutionInfo} />
            </Grid>
          )}
          {(showEmptySections || backgroundSection?.otherNamesRecord?.length > 0) && (
            <Grid className={classes.addressWrapper}>
              <OtherNamesRecord store={store} openResolutionInfo={handleOpenResolutionInfo} />
            </Grid>
          )}
          {(showEmptySections || backgroundSection?.globalSanction?.length > 0) && (
            <Grid className={classes.addressWrapper}>
              <GlobalSanctionRecord store={store} openResolutionInfo={handleOpenResolutionInfo} />
            </Grid>
          )}
          {(showEmptySections || backgroundSection?.lienJudgmentRecord?.length > 0) && (
            <Grid className={classes.addressWrapper}>
              <LienJudgmentRecord store={store} openResolutionInfo={handleOpenResolutionInfo} />
            </Grid>
          )}
          {(showEmptySections || backgroundSection?.lawsuitRecord?.length > 0) && (
            <Grid className={classes.addressWrapper}>
              <LawsuitRecord store={store} openResolutionInfo={handleOpenResolutionInfo} />
            </Grid>
          )}
          {(showEmptySections || backgroundSection?.docketRecord?.length > 0) && (
            <Grid className={classes.addressWrapper}>
              <DocketRecord store={store} openResolutionInfo={handleOpenResolutionInfo} />
            </Grid>
          )}
          {store.isInternal &&
            <Grid className={classes.addressWrapper}>
              <BackgroundComment store={store} />
            </Grid>
          }
          {showResolutions && (
            <Grid className={classes.addressWrapper}>
              <LetterOfExplanation store={store} />
            </Grid>
          )}
          {store.isInternal &&
            <Grid className={classes.addressWrapper}>
              <AuditLogs store={store} />
            </Grid>
          }
          {showButtons && (
            <Grid container spacing={2} className={classes.buttonsGrid} direction="column" alignItems="center" justifyContent="center">
              {store.lockedData || !store.isInternal || store.readOnly ? (
                <Grid item xs={12} sm={2}>
                  <Button
                    onClick={handleCloseButton}
                    style={{
                      color: '#2199E0',
                      borderColor: '#2199E0',
                      backgroundColor: '#E2F6FF',
                    }}
                    variant="outlined"
                    color="primary"
                    fullWidth
                  >
                    CANCEL
                  </Button>
                </Grid>
              ) : (
                <>
                  <Grid item xs={12} sm={2}>
                    <Button
                      onClick={handleCloseButton}
                      style={{
                        color: '#2199E0',
                        borderColor: '#2199E0',
                        backgroundColor: '#E2F6FF',
                      }}
                      variant="outlined"
                      color="primary"
                      fullWidth
                    >
                      CANCEL
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <SaveButtonWithPermissions
                      onClick={() => store.saveData(BORROWER_REVIEW_TYPES.BORROWER_BACKGROUND_REVIEW, handleCloseButton)}
                      style={{
                        color: '#2199E0',
                        borderColor: '#2199E0',
                        backgroundColor: '#E2F6FF',
                      }}
                      variant="outlined"
                      color="primary"
                      fullWidth
                      label="SAVE"
                      loanId={store.loanId}
                    />
                  </Grid>

                  <Grid item xs={12} sm={2}>
                    <ConfirmationButton
                      variant="contained"
                      onClick={handleConfirmationCompleteReviewAction}
                      color="primary"
                      testId="confirmReview"
                      confirmDialogProps={{
                        title: 'Complete Review',
                        body: 'Are you sure you want to mark this review as completed?',
                      }}
                    >
                      REVIEW COMPLETE
                    </ConfirmationButton>
                  </Grid>
                  <Grid onClick={() => setConfirmModalOpen(true)} item xs={12} sm={2}>
                    <Button
                      style={{ backgroundColor: '#2199E0', color: '#FFFFFF' }}
                      type="submit"
                      variant="contained"
                      color="primary"
                      fullWidth
                    >
                      REQUEST RESOLUTIONS
                    </Button>
                  </Grid>
                </>
              )}
            </Grid>
          )}
        </Grid>
      </Box>
    </>
  );
});

export default BackgroundReviewForm;
