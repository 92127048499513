import {
  Container,

  Grid,

  Typography,
} from '@material-ui/core';
import { lighten } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { SinglePropertyStore, RentalPortfolioStore } from './../../loanSubmission';
import { Button, Paper } from '@roc/ui';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { SectionName } from '../components/rentalPricerComponents';
import { LoanTermFields } from '../components/rentalPricerFields';
import { PricerFieldsConfig } from '../components/rentalPricerFieldsContext';
import { useRentalPricerStore } from '../hooks/useRentalPricerStore';
import {
  AvailableLoanRatesTable,
} from './components/availableLoanRatesTable';
import { LoanValuesTable } from './components/loanValuesTable';

interface PricerSummaryTermProps {
  store: SinglePropertyStore | RentalPortfolioStore;
}

const useStyles = makeStyles(theme =>
  createStyles({
    pricerSummaryContainer: {
      padding: theme.spacing(4),
      margin: 'auto',
      marginTop: theme.spacing(4),
      borderRadius: 16,
      border: '1px solid',
      borderColor: lighten(theme.palette.primary.main, 0.5),
      background: lighten(theme.palette.primary.main, 0.85),
    },
    errorContainer: {
      color: theme.palette.error.main,
    },
  })
);

const LoanRatesErrors = observer(({ store }) => {
  const classes = useStyles();
  const { serverExceptions } = store.pricerSummaryStore;

  return (
    <Grid className={classes.errorContainer}>
      <Typography variant="body1">
        <strong>
          Currently Rates are not avaliable for the given loan scenario.
        </strong>
      </Typography>
      <br />
      <Typography variant="body1">
        {serverExceptions &&
          serverExceptions.length > 0 &&
          serverExceptions.map(exception => (
            <li key={exception}>
              <strong>{exception}</strong>
            </li>
          ))}
      </Typography>
    </Grid>
  );
});

const Title = ({ title }) => {
  return (
    <Box pb={2}>
      <Typography variant="h4">{title}</Typography>
    </Box>
  );
};

const PricerSummaryTerm = () => {
  const { rentalPricerStore } = useRentalPricerStore();
  const { rentalPricerDetailsStore } = rentalPricerStore;
  const classes = useStyles();

  const store = rentalPricerDetailsStore.termStore;
  const { fields } = store.pricerStore.form;
  const { pricerSummaryStore, pricerStore } = store;
  const {
    overRideRate,
    rateTermLTV,
    showRateNotFoundErrorMsg,
  } = pricerSummaryStore;

  const isLoanRatesAvailable = !showRateNotFoundErrorMsg && !overRideRate;

  return (
    <>
      <Container maxWidth="md">
        <Grid container spacing={2}>
          <SectionName name="Loan Terms" />
          <LoanTermFields store={store} />
        </Grid>
      </Container>
      <Box className={classes.pricerSummaryContainer}>
        <Title title="Loan Pricer Summary" />
        <PricerFieldsConfig xs={12} sm={12} md={12}>
          <Grid container spacing={2}>
            {!showRateNotFoundErrorMsg && !overRideRate ? (
              <>
                <SectionName name="Available Loan Rates" />
                <Grid item xs={12}>
                  <Typography>
                    Please fill in the required information to see the available
                    rates.
                  </Typography>
                </Grid>
                {isLoanRatesAvailable && (
                  <>
                    <Grid container item xs={12} justifyContent="flex-end">
                      <Grid container item xs={12} md={4}>
                        <Button
                          variant="outlined"
                          color="secondary"
                          fullWidth
                          onClick={() => pricerSummaryStore.downloadLoanRates()}
                          testId="downloadLoanRates"
                        >
                          DOWNLOAD LOAN RATES
                        </Button>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <AvailableLoanRatesTable store={store} />
                    </Grid>
                    <Grid container spacing={2} justifyContent={'center'} style={{ padding: '20px' }}>
                      <Grid item>
                        <Typography variant="body1">
                          <strong>Please refer to Rental Loan Rates <a style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => pricerSummaryStore.downloadLoanFaqs()}>FAQs</a> for more information</strong>
                        </Typography>
                      </Grid>
                    </Grid>
                  </>
                )}
                <SectionName name="Loan Values" />
                <Grid item xs={12}>
                  <LoanValuesTable store={store} />
                </Grid>
                {rateTermLTV > 0 && (
                  <Grid item xs={12}>
                    <Paper>
                      <List>
                        <ListItem>
                          <ListItemText>
                            <Box display="flex">
                              <Box flexGrow={1}>
                                <strong>Estimated LTV Needed</strong>
                              </Box>
                              <Box>
                                <strong>{rateTermLTV.toFixed(2)}</strong>
                              </Box>
                            </Box>
                          </ListItemText>
                        </ListItem>
                      </List>
                    </Paper>
                  </Grid>
                )}
              </>
            ) : (
              <Grid item xs={12}>
                <LoanRatesErrors store={store} />
              </Grid>
            )}
            {!showRateNotFoundErrorMsg && !overRideRate && <></>}
          </Grid>
        </PricerFieldsConfig>
      </Box>
    </>
  );
};

export default observer(PricerSummaryTerm);
