import { useStore } from '@roc/client-portal-shared/hooks';
import { Layout } from '@roc/client-portal-shared/components';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { CreditBackgroundRequestsGrid } from './components/creditBackgroundRequestsGrid';

export const CreditBackgroundRequests = observer(() => {
  const { creditBackgroundStore } = useStore();

  useEffect(() => {
    creditBackgroundStore.gridStore.resetAndFetchGridData();
  }, []);

  return (
    <Layout title="Credit/Background Requests">
      <CreditBackgroundRequestsGrid />
    </Layout>
  );
});
