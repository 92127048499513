import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import {
  Button,
  ConfirmDialog,
  createCheckboxField,
  createSelectField,
  createTextField,
  FileUpload,
  FileUploadModal,
  StandardDialog,
} from '@roc/ui';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useLoanActionsStore } from '../hooks/useLoanActionsStore';

const USED_PORTAL_WITHOUT_ISSUES = 'Used Portal fully without issues';

const uploadGeneratedDocsAnswerOptions = [
  {
    label: 'Used Portal fully without issues',
    value: USED_PORTAL_WITHOUT_ISSUES,
  },
  {
    label: 'Used portal but had to fix things',
    value: 'Used portal but had to fix things',
  },
  {
    label: 'Used my own docs',
    value: 'Used my own docs',
  },
];

const useStyles = makeStyles(theme => ({
  container: {
    paddingBottom: theme.spacing(4),
  },
}));

export const UploadClosingDocumentsModal = observer(({ open, handleClose }) => {
  const classes = useStyles();
  const [items, setItems] = useState([]);
  const { loanActionsStore } = useLoanActionsStore();
  const { uploadClosingDocStore } = loanActionsStore;
  const { uploadGeneratedDocsAnswer, uploadGeneratedDocsComment } = uploadClosingDocStore.form.fields;

  useEffect(() => {
    return () => uploadClosingDocStore.reset();
  }, []);

  const confirm = (uploads: FileUpload[]) => {
    uploadClosingDocStore.uploadClosingDocuments(uploads);
  };

  return (
    <FileUploadModal
      title={'Upload Closing Documents'}
      open={open}
      onClose={handleClose}
      onUpload={confirm}
      disabled={uploadGeneratedDocsAnswer.value == USED_PORTAL_WITHOUT_ISSUES ? !uploadGeneratedDocsAnswer.value : !uploadGeneratedDocsAnswer.value || !uploadGeneratedDocsComment.value}
      multiple
      top={
        <Grid container spacing={2} className={classes.container}>
          <Grid item xs={12}>
            <Typography>
              <b>
                This will add a new document category on the "Closing Documents"
                section
              </b>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {createCheckboxField({
              store: uploadClosingDocStore,
              fieldName: 'removePreviousClosingDocs',
              label:
                'Remove previous uploaded documents on the selected section',
            })}
          </Grid>
          <Grid item xs={12}>
            {createSelectField({
              store: uploadClosingDocStore,
              testId: 'uploadGeneratedDocsAnswer',
              fieldName: 'uploadGeneratedDocsAnswer',
              label: 'Are you uploading the portal generated documents?',
              options: uploadGeneratedDocsAnswerOptions,
            })}
          </Grid>
          {uploadGeneratedDocsAnswer.value != USED_PORTAL_WITHOUT_ISSUES && (
            <Grid item xs={12}>
              {createTextField({
                store: uploadClosingDocStore,
                testId: 'uploadGeneratedDocsComment',
                fieldName: 'uploadGeneratedDocsComment',
                label:
                  'What stopped you from fully using the portal documents?',
                multiline: true,
              })}
            </Grid>
          )}
        </Grid>
      }
    />
  );
});
