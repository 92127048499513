import { Service } from './service';

const url = {
  SEARCH_LOANS: '/api/v1/loan/searchLoans',
};

export class SearchBarService extends Service {
  searchLoans(searchTerm: string) {
    return this.get(
      url.SEARCH_LOANS,
      {
        keyword: searchTerm,
        start: 1,
        count: 10,
      },
      {
        disableGlobalLoading: true,
      }
    );
  }
}
