import {
  Button,
  createTextField,
  PrintableAreaRef,
  StandardDialog,
} from '@roc/ui';
import { observer } from 'mobx-react';
import { BorrowerEmailFormStore } from '../../../stores/summary/borrowerEmailFormStore';
import { Grid, Typography } from '@material-ui/core';
import { useEffect, useRef } from 'react';
import { DownloadableQuote } from './downloadableQuote';
import { QuoteTypeStore } from '../../../stores/quoteTypeStore';
import { useBaseStore } from '@roc/feature-app-core';

type Props = {
  store?: QuoteTypeStore;
};

export const LoanCommentsDialog = observer(({ store }: Props) => {
  const { summaryStore } = store;
  const { loanCommentsFormStore } = summaryStore;
  const isLeverageException = store.loanEconomicsStore.enableManualQuote;
  const open = loanCommentsFormStore.showLoanCommentsDialog;

  const dialogActions = (
    <>
      <Button
        testId="cancel-email-borrower"
        color="primary"
        variant="outlined"
        style={{ marginRight: '8px' }}
        onClick={() => loanCommentsFormStore.closeDialog()}
      >
        CANCEL
      </Button>
      <Button
        testId="preview-email-borrower"
        color="primary"
        variant="contained"
        onClick={() => loanCommentsFormStore.saveComments()}
      >
        SAVE & CONTINUE
      </Button>
    </>
  );

  const title = isLeverageException
    ? 'Manual Quote Information'
    : 'Additional Comments - OPTIONAL';

  return (
    <StandardDialog
      open={open}
      title={title}
      maxWidth="md"
      handleClose={() => loanCommentsFormStore.closeDialog()}
      dialogActions={dialogActions}
      dialogContent={
        <Grid container spacing={2}>
          {!isLeverageException && (
            <>
              <Grid item xs={12}>
                <Typography>
                  What do you want to tell us about this project or borrower?
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {createTextField({
                  store: loanCommentsFormStore,
                  label: '',
                  testId: 'loanComments',
                  fieldName: 'loanComments',
                  variant: 'outlined',
                  multiline: true,
                  minRows: 10,
                })}
              </Grid>
            </>
          )}
          {isLeverageException && (
            <>
              <Grid item xs={12}>
                <Typography>
                  Please be sure to provide any and all additional information to support credit decision including how you found this borrower, how they sourced the property, or any other things that are relevant to the deal.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {createTextField({
                  store: loanCommentsFormStore,
                  label: 'Reasoning',
                  testId: 'leverageExceptionComments',
                  fieldName: 'leverageExceptionComments',
                  variant: 'outlined',
                  standaloneLabel: false,
                  multiline: true,
                  minRows: 10,
                })}
              </Grid>
            </>
          )}
        </Grid>
      }
    />
  );
});
