import {
  FROM_0_TO_5,
  FROM_11_TO_15,
  FROM_16_TO_20,
  FROM_21_TO_25,
  FROM_26_TO_30,
  FROM_31_TO_35,
  FROM_36_TO_40,
  FROM_41_TO_45,
  FROM_46_TO_50,
  FROM_50_TO_55,
  FROM_55_TO_60,
  FROM_61_TO_65,
  FROM_66_TO_70,
  FROM_6_TO_10,
  FROM_71_TO_75,
  FROM_76_TO_80,
  ltvMapper,
  US_TREASURY_10_YR_DESC,
  US_TREASURY_10_YR_INDEX,
  US_TREASURY_5_YR_DESC,
  US_TREASURY_5_YR_INDEX,
} from '../../utils/constants';

export const allLtvBandsInDisplayOrder = [
  FROM_0_TO_5,
  FROM_6_TO_10,
  FROM_11_TO_15,
  FROM_16_TO_20,
  FROM_21_TO_25,
  FROM_26_TO_30,
  FROM_31_TO_35,
  FROM_36_TO_40,
  FROM_41_TO_45,
  FROM_46_TO_50,
  FROM_50_TO_55,
  FROM_55_TO_60,
  FROM_61_TO_65,
  FROM_66_TO_70,
  FROM_71_TO_75,
  FROM_76_TO_80,
];

export const swapIndexLabels = {
  [US_TREASURY_5_YR_INDEX]: US_TREASURY_5_YR_DESC,
  [US_TREASURY_10_YR_INDEX]: US_TREASURY_10_YR_DESC,
  5: US_TREASURY_5_YR_DESC,
  10: US_TREASURY_10_YR_DESC,
};

export const postLoanApprovalRateLockLabels = {
  ['DSCR Expanded']: '30 Calendar Days',
  ['DSCR Standard']: '14 Calendar Days',
};
