import { ApiResponse, FormStore, GlobalStore } from "@roc/feature-app-core";
import { CompanyProfileService } from "../services/CompanyProfileService";
import { action, computed, flow, makeObservable, observable } from "mobx";
import { MersOnboardingStore } from "./mersOnBoardingStore";

const loanFundingPreferenceForm = {
  fields: {
    fundingPreference: {
      value: '',
      error: null,
      rule: '',
    },
    agreementDate: {
      value: null,
      error: null,
      rule: '',
    }
  },
  meta: {
    isValid: false,
    error: null,
  },
}

class LoanFundingPreferenceFormStore extends FormStore {
  globalStore: GlobalStore;
  constructor(globalStore) {
    super({ ...loanFundingPreferenceForm }, globalStore);
  }
}
export const MERS_TPO_DB = 'SPL with MERS as nominee';
export const MERS_TPO = 'TPO with MERS as nominee';
export const LOAN_FUNDER = 'Loan Funder LLC';
export const LOAN_FUNDER_WITH_MERS = 'Loan Funder LLC with MERS as nominee';
export const DBA = 'DBA';

export class LoanFundingPreferenceStore {
  private globalStore: GlobalStore;
  companyProfileService: CompanyProfileService;
  loanFundingPreferenceFormStore: LoanFundingPreferenceFormStore;
  public fundingPreferenceLabel: string;
  mersOnboardingStore: MersOnboardingStore;
  agreementDate?: Date;

  constructor(globalStore) {
    this.globalStore = globalStore;
    this.loanFundingPreferenceFormStore = new LoanFundingPreferenceFormStore(globalStore);
    this.companyProfileService = new CompanyProfileService();
    this.mersOnboardingStore = new MersOnboardingStore(globalStore);
    this.agreementDate = null;
    makeObservable(this, {
      getLenderFundingPreference: flow,
      fundingPreferenceLabel: observable,
      saveChanges: flow,
      updateFundingPreference: flow,
      isMersSelected: computed,
      isMersSaved: computed,
      updateLabel: flow,
      agreementDate: observable,
      setAgreementDate: action,
      showAgreementDateMessage: action,
      resetAgreementDate: action
    })
  }
  get isMersSelected() {
    return this.loanFundingPreferenceFormStore.form.fields.fundingPreference.value === MERS_TPO;
  }

  get isMersSaved() {
    return this.fundingPreferenceLabel === MERS_TPO;
  }

  *getLenderFundingPreference() {
    const response: ApiResponse = yield this.companyProfileService.getFundingPreference(this.globalStore.selectedCompanyId);
    this.setFundingPreference(response.data.data);
  }

  *updateFundingPreference(json: any) {
    try {
      const response: ApiResponse = yield this.companyProfileService.updateLenderFundingPreference(json, this.globalStore.selectedCompanyId);
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Funding Preference saved succesfully',
      });
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: error.error.response.data.error.message
      });
    }

  }

  private setFundingPreference(value: string) {
    const fundingPreference = value === MERS_TPO_DB ? MERS_TPO : value;
    this.fundingPreferenceLabel = fundingPreference;
    this.loanFundingPreferenceFormStore.loadForm({
      fundingPreference: fundingPreference,
    });
  }

  setAgreementDate(value: string) {
    this.loanFundingPreferenceFormStore.onFieldChange('agreementDate', value);
  }

  resetAgreementDate(value: string) {
    this.loanFundingPreferenceFormStore.onFieldChange('agreementDate', null);
  }

  showAgreementDateMessage() {
    this.globalStore.notificationStore.showErrorNotification({
      message: 'Please select an agreement date in order to save the funding preference',
    });
  }

  *saveChanges() {
    this.fundingPreferenceLabel = this.loanFundingPreferenceFormStore.form.fields.fundingPreference.value;
    const agreementDate = this.loanFundingPreferenceFormStore.form.fields.agreementDate.value;

    const json = {
      fundingPreference: this.loanFundingPreferenceFormStore.form.fields.fundingPreference.value,
      ...(agreementDate !== null && { mersAgreementDate: agreementDate })
    };

    yield this.updateFundingPreference(json);
  }


  updateLabel() {
    this.fundingPreferenceLabel = this.loanFundingPreferenceFormStore.form.fields.fundingPreference.value;
  }

}