import { FormStore } from '@roc/feature-app-core';
import { makeObservable, computed, action, observable, flow } from 'mobx';
import { AllowedWimba, ELMSURE, OTHER, TERM, WIMBA, isNil, isNotBlank } from '@roc/feature-utils';
import { LoanDetails } from '@roc/feature-types';
import { TermStore } from './termStore';
import { AttorneysStore } from './attorneysStore';
import { LoanService } from '../../../loans/services/loanService';
import format from 'date-fns/format';
import { GlobalStore } from '@roc/feature-app-core';
import { LoanOriginatorStore } from './loanOriginatorStore';
import CompanyFormStore from './companyFormStore';
import { propertyStates } from '@roc/feature-utils';
import { TitleCompanyFormStore } from './titleCompanyFormStore';
import { ApiResponse } from '@roc/client-portal-shared/services';
import { FileUpload } from '@roc/ui';
import { getDealName } from '../../utils/dealNameUtils';
import { LoanParticipantsStore } from '@roc/feature-loan-participants';
import PricerStore from '../singleProperty/pricerStore';

const loanDetails = {
  fields: {
    lenderPoints: {
      value: '',
      error: null,
      rule: 'required',
      message: 'Required',
    },
    lenderUnderwritingFee: {
      value: 0,
      error: null,
      rule: '',
    },
    brokerPointsIn: {
      value: '',
      error: null,
      rule: '',
      message: '',
    },
    brokerPointsRequired: {
      value: 'N',
      error: null,
      rule: '',
    },
    preferredClosingAttorney: {
      value: 'lawyer.hdrbb@roccapital.com',
      error: null,
      rule: 'required',
    },
    preferredInsurance: {
      value: '',
      error: null,
      rule: '',
    },
    preferredTitle: {
      value: '',
      error: null,
      rule: '',
    },
    requestedClosingDate: {
      value: null,
      error: null,
      rule: '',
    },
    referralFee: {
      value: 0,
      error: null,
      rule: 'required|numeric|min:0|max:1',
      message:
        'The referral fee field is required and cannot exceed 1% of the total loan amount',
    },
    submitAppraisalWithTamarisk: {
      value: 'Y',
      error: null,
      rule: '',
    },
    gfdPayment: {
      value: 'N',
      error: null,
      rule: '',
    },
    fastTrack: {
      value: 'N',
      error: null,
      rule: 'required',
    },
    fastTrackOptions: {
      value: { insuranceReview: false, titleSearch: false, insurance: false, rushedAppraisal: false, titleAttorneySearch: false },
      error: null,
      rule: ''
    },
    loanOriginator: {
      value: null,
      error: null,
      rule: '',
    },
    wimbaAcknowledged: {
      value: null,
      error: null,
      rule: '',
    },
    freeAppraisalPromotion: {
      value: 'N',
      error: null,
      rule: '',
    },
    referralFeesAmount: {
      value: 0,
      error: null,
      rule: '',
    },
    referralFeesFormat: {
      value: 'percentage',
      error: null,
      rule: '',
    },
    projectDescription: {
      value: '',
      error: null,
      rule: '',
    },
    marketingPromotionId: {
      value: null,
      error: null,
      rule: '',
    }
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export class LoanDetailsStore extends FormStore {
  private globalStore: GlobalStore;
  private termStore: TermStore;
  private attorneysStore: AttorneysStore;
  private otherTitleDetails = {};
  otherTitleFormStore: CompanyFormStore;
  public loanOriginatorStore: LoanOriginatorStore;
  public loanParticipantsStore: LoanParticipantsStore;
  public sfrCalculatedLenderPointsIn: number;
  public rocPointsIn: number;
  public lenderAttorneysOptionList: any[];
  public wimbaStates: any[];
  public loanService: LoanService;
  public draftLoanId: string;
  public fastTrackFees = {};
  public fastTrackPaymentUrl: string;
  public licenseUpload: FileUpload[];
  public preferredAttorneyForBroker: string;

  constructor(globalStore, termStore) {
    super({ ...loanDetails }, globalStore);
    this.globalStore = globalStore;
    this.termStore = termStore;
    this.attorneysStore = new AttorneysStore(this.globalStore);
    this.loanOriginatorStore = new LoanOriginatorStore(this.globalStore);
    this.loanParticipantsStore = new LoanParticipantsStore(this.globalStore);
    this.otherTitleFormStore = new TitleCompanyFormStore(this.globalStore);
    this.loanService = new LoanService();
    this.setDefaults();
    makeObservable(this, {
      draftLoanId: observable,
      sfrCalculatedLenderPointsIn: observable,
      lenderAttorneysOptionList: observable,
      wimbaStates: observable,
      rocPointsIn: observable,
      handleLenderPoints: action,
      showWimba: action,
      checkIfRequiredFieldsEmpty: action,
      isWimba: action,
      onChange: action,
      resetStore: action,
      initForm: action,
      getLenderAttorneys: flow,
      getWimbaStates: flow,
      uploadInsuranceDocument: flow,
      fetchLoanId: flow,
      updateLoanFastrackInfo: flow,
      requestFastTrackPaymentEmail: flow,
      loadFastTrackFees: flow,
      getLoanFastTrackPaymentUrl: flow,
      canSubmitAppraisalWithTamarisk: computed,
      licenseUpload: observable,
      setLicenseUpload: action,
      changePointsPercentage: action,
      calculatePointsAmount: action,
      changePointsAmount: action,
      calculatePointsPercentage: action
    });
  }

  private setDefaults() {
    this.sfrCalculatedLenderPointsIn = 0;
    this.rocPointsIn = 0;
    this.otherTitleDetails = {};
    this.lenderAttorneysOptionList = [];
    this.wimbaStates = AllowedWimba;
    this.setDefaultFastTrackValues();
    this.licenseUpload = [];
  }

  private setDefaultFastTrackValues() {
    this.fastTrackFees = {};
    this.fastTrackPaymentUrl = '';
    this.draftLoanId = '';
  }

  resetStore() {
    this.reset();
    this.setDefaultFastTrackValues();
    this.form = { ...loanDetails };
    this.otherTitleFormStore.reset();
    this.setDefaults();
    this.onChange(
      'submitAppraisalWithTamarisk', this.globalStore.lenderInfo?.tamariskDefaultOption ? 'Y' : 'N'
    );
    this.onChange(
      'loanOriginator',
      this.globalStore.userFeatures.isLenderOriginator
        ? this.termStore.userStore.userInformation.userId
        : null
    );
    this.onChange(
      'preferredInsurance',
      this.termStore.userStore.allowLoanPricing ? ELMSURE : OTHER
    );
  }

  initForm(data) {
    this.loadForm({
      ...data,
    });
    this.rocPointsIn = data.rocPointsIn;
    this.sfrCalculatedLenderPointsIn = data.sfrLenderPointsIn;
  }

  showWimba = () => {
    const { allowLoanPricing } = this.termStore.userStore;
    const { state, propertyStates: propertyState } = this.termStore.pricerStore.form.fields;
    const loanState = state?.value || propertyStates.find(s => s.value === propertyState?.value)?.id;

    return (
      allowLoanPricing && loanState && this.isStateAllowedWimba(loanState) && this.getLoanDetails().preferredTitleCompany === WIMBA
    );
  };

  setDefaultPreferredTitle = () => {
    if (!this.showWimba()) {
      this.onChange('preferredTitle', '');
    } else if (!this.form.fields.preferredTitle?.value) {
      this.onChange('preferredTitle', '');
    }
  };

  isWimba() {
    return this.form.fields.preferredTitle?.value === WIMBA;
  }
  *getLenderAttorneys() {
    const { amount } = this.termStore.pricerSummaryStore.pricerLoanTerms;
    const { propertyStates: propertyState } = this.termStore.pricerStore.form.fields;
    const isBroker = this.globalStore.userFeatures.isBroker;
    if (isBroker) {
      const response = yield this.termStore.brokersStore.manageBrokersStore.getBrokerVendorClosingSettingsByBrokerId(this.termStore.brokersStore.selectBrokersStore.brokers[0].brokerId);
      this.preferredAttorneyForBroker = response.preferredClosingAttorney;
    }
    const { state } = this.termStore.pricerStore.getProperty();
    yield this.attorneysStore.getLenderAttorneys(TERM, this.obtainState(state, propertyStates), amount);
    if (isBroker) {
      this.setDefaultPreferredClosingAttorneyForBroker(this.attorneysStore.lenderAttorneysOptionList, this.preferredAttorneyForBroker);
    } else {
      this.setDefaultPreferredClosingAttorney(this.attorneysStore.lenderAttorneysOptionList)
    }
    this.lenderAttorneysOptionList = this.attorneysStore.lenderAttorneysOptionList;
  }

  setDefaultPreferredClosingAttorney(attorneys) {
    if (isNotBlank(this.form.fields.preferredClosingAttorney.value) && !this.isClosingAttorneyPresentOnList(attorneys, this.form.fields.preferredClosingAttorney.value)) {
      this.isClosingAttorneyPresentOnList(attorneys, 'lawyer.hdrbb@roccapital.com') ?
        this.onFieldChange('preferredClosingAttorney', 'lawyer.hdrbb@roccapital.com') :
        this.onFieldChange('preferredClosingAttorney', 'lawyer.privatelenderlaw@roccapital.com');
    }
  }

  isClosingAttorneyPresentOnList(attorneys, preferredClosingAttorney) {
    return attorneys.some(attorney => attorney.value === preferredClosingAttorney);
  }

  setDefaultPreferredClosingAttorneyForBroker(attorneys, preferredClosingAttorney) {
    if (isNotBlank(this.form.fields.preferredClosingAttorney.value)) {
      console.log(this.isClosingAttorneyPresentOnList(attorneys, preferredClosingAttorney))
      this.isClosingAttorneyPresentOnList(attorneys, preferredClosingAttorney) ?
        this.onFieldChange('preferredClosingAttorney', preferredClosingAttorney) :
        this.onFieldChange('preferredClosingAttorney', 'lawyer.hdrbb@roccapital.com');

    }
  }

  *getWimbaStates() {
    try {
      const response = yield this.loanService.getWimbaStates();
      if (response && response.data && response.data.data) {
        this.wimbaStates = Array.isArray(response.data.data) ? response.data.data : JSON.parse(response.data.data)
      }
      if (this.wimbaStates.length == 0) {
        this.wimbaStates = AllowedWimba;
      }
    } catch (error) {
      console.log(error);
      this.wimbaStates = AllowedWimba;
    }
  }

  obtainState = (state, propertyStates) => {
    if (state === 'DC') {
      return 'DC'
    } else {
      const foundStateObject = propertyStates.find((s) => s.id === state || s.value === state);
      return foundStateObject ? foundStateObject.id : null;
    }
  }

  private isStateAllowedWimba = state => {
    return this.wimbaStates.includes(state);
  };

  handleLenderPoints = value => {
    if (!isNaN(value)) {
      const lenderPointsShare = this.globalStore.lenderInfo?.lenderPointsShare;
      const rocBasePointsPercent = this.globalStore.lenderInfo?.rocBasePoints;
      const rocBasePoints = Number(rocBasePointsPercent) * Number(value);
      const rocPointsShare = 1 - Number(lenderPointsShare);
      const rocPointsIn =
        Number(rocBasePoints) +
        (Number(value) - Number(rocBasePoints)) * rocPointsShare;
      this.sfrCalculatedLenderPointsIn = Number(value) - Number(rocPointsIn);
      this.rocPointsIn = rocPointsIn;
    }
  };

  checkIfRequiredFieldsEmpty = () => {
    let requiredFieldEmpty = false;
    const fields = this.form.fields;
    for (let loanDetail in fields) {
      if (
        fields[loanDetail].rule === 'required' &&
        fields[loanDetail].value === ''
      ) {
        this.onFieldChange(loanDetail, '');
        requiredFieldEmpty = true;
      }
    }
    return requiredFieldEmpty;
  };

  checkForStepErrors = () => {
    const lenderPointsShare = this.globalStore.lenderInfo?.lenderPointsShare;
    const { minRocPoints } = this.termStore.pricerSummaryStore.pricerLoanTerms;
    const allowLoanPricing = this.termStore.userStore.allowLoanPricing;
    const rocPointsIn = this.termStore.loanDetails.rocPointsIn;
    const isInternal = this.globalStore.lenderInfo?.isInternal;
    if (!allowLoanPricing) {
      return false;
    } else {
      if (!this.globalStore.userFeatures?.pricerFeesEnabled && !isInternal && lenderPointsShare < 1 && rocPointsIn < minRocPoints) {
        const errorMsg = `Origination Fees should be greater than ${minRocPoints}%.Please contact your relationship manager for alternative pricing`;
        this.termStore.stepError = errorMsg;
        return true;
      }

      if (this.checkPoints()) {
        return true;
      }

      const pricerFeesEnabled = this.globalStore.userFeatures?.pricerFeesEnabled;
      if (!pricerFeesEnabled) {
        if (this.getLoanDetails().lenderPoints < 1) {
          const errorMsg = `Lender Points should be greater than or equal to 1`;
          this.termStore.stepError = errorMsg;
          return true;
        }
      }
      const allowLenderUnderwritingFee = this.globalStore.userFeatures?.allowLenderUnderwritingFee;
      if (allowLenderUnderwritingFee && this.checkLenderUnderwritingFeeValidations()) {
        return true;
      }

      if ((!this.otherTitleFormStore.form.meta.isValid && !isNil(this.termStore.editLoanJson.loan)) && this.getLoanDetails().preferredTitleCompany === 'Other') {
        const errorMsg = `Please fill out all the info for Preferred Title Company.`;
        this.termStore.stepError = errorMsg;
        return true;
      }

      return false;
    }
  };

  checkLenderUnderwritingFeeValidations() {
    const { amount } = this.termStore.pricerSummaryStore.pricerLoanTerms;
    const lenderUnderwritingFee = this.form.fields.lenderUnderwritingFee.value;
    const isInternal = this.globalStore.lenderInfo?.isInternal;
    if (this.globalStore.userFeatures?.pricerFeesEnabled && isInternal) return false;

    if (amount < 500000 && lenderUnderwritingFee < 595) {
      this.termStore.stepError = 'Lender Underwriting Fee for this loan must be greater than or equal to $595.';
      return true;
    }
    if (amount >= 500000 && amount < 1000000 && lenderUnderwritingFee < 895) {
      this.termStore.stepError = 'Lender Underwriting Fee for this loan must be greater than or equal to $895.';
      return true;
    }
    if (amount >= 1000000 && amount < 2000000 && lenderUnderwritingFee < 1295) {
      this.termStore.stepError = 'Lender Underwriting Fee for this loan must be greater than or equal to $1,295.';
      return true;
    }
    if (amount >= 2000000 && amount < 3000000 && lenderUnderwritingFee < 1995) {
      this.termStore.stepError = 'Lender Underwriting Fee for this loan must be greater than or equal to $1,995.';
      return true;
    }
    if (amount >= 3000000 && amount < 4000000 && lenderUnderwritingFee < 3495) {
      this.termStore.stepError = 'Lender Underwriting Fee for this loan must be greater than or equal to $3,495.';
      return true;
    }
    if (amount >= 4000000 && amount < 5000000 && lenderUnderwritingFee < 4495) {
      this.termStore.stepError = 'Lender Underwriting Fee for this loan must be greater than or equal to $4,495.';
      return true;
    }
    return false;
  }

  checkPoints() {
    const lenderPoints = this.getLoanDetails().lenderPoints;
    const brokerPoints = this.getLoanDetails().brokerPointsIn;

    if (brokerPoints > lenderPoints && !this.globalStore.userFeatures?.pricerFeesEnabled) {
      this.termStore.stepError = 'Broker points cannot exceed total lender points.';
      return true;
    }
    return false;
  }

  getPreferredAttorney() {
    return this.lenderAttorneysOptionList.find(
      attorney =>
        attorney.value === this.form.fields.preferredClosingAttorney.value
    );
  }

  getRequestedClosingDate() {
    return (
      this.form.fields.requestedClosingDate.value &&
      this.form.fields.requestedClosingDate.value
    );
  }

  getSubmitAppraisalWithTamarisk() {
    return this.form.fields.submitAppraisalWithTamarisk.value === 'Y';
  }

  getGfdPayment() {
    return this.form.fields.gfdPayment.value === 'Y';
  }

  getFastrack() {
    return this.form.fields.fastTrack.value === 'Y';
  }

  getFastrackOptions() {
    return this.form.fields.fastTrackOptions.value;
  }

  getFreeAprraisalPromotion() {
    return this.form.fields.freeAppraisalPromotion.value === 'Y';
  }

  getLoanDetails(): LoanDetails {
    const data = this.getFormValues();
    const pricerFeesEnabled = this.globalStore.userFeatures?.pricerFeesEnabled;
    const { originationPointsTpo, originationPointsRoc } = this.termStore.pricerSummaryStore.lenderFeesFormStore.getFormValues();
    this.rocPointsIn = pricerFeesEnabled ? originationPointsRoc : this.rocPointsIn;
    this.sfrCalculatedLenderPointsIn = pricerFeesEnabled ? originationPointsTpo : this.sfrCalculatedLenderPointsIn;

    return {
      rocPointsIn: this.rocPointsIn,
      sfrCalculatedLenderPointsIn: this.sfrCalculatedLenderPointsIn,
      lenderPoints: data['lenderPoints'],
      brokerPointsIn: data['brokerPointsIn'],
      lenderUnderwritingFee: data['lenderUnderwritingFee'],
      attorneyEmail: data['preferredClosingAttorney'],
      attorneyName: this.getPreferredAttorney()?.label,
      preferredInsurance: data['preferredInsurance'],
      preferredTitleCompany: data['preferredTitle'],
      requestedClosingDate: this.getRequestedClosingDate(),
      referralFee: data['referralFee'],
      submitAppraisalWithTamarisk: this.getSubmitAppraisalWithTamarisk(),
      loanOriginator: this.form.fields.loanOriginator.value,
      gfdPayment: this.getGfdPayment(),
      fastTrack: this.getFastrack(),
      fastTrackOptions: this.getFastrackOptions(),
      otherTitleDetails: this.otherTitleDetails,
      freeAppraisalPromotion: this.getFreeAprraisalPromotion(),
      marketingPromotionId: data['marketingPromotionId']
    };
  }

  setOtherTitleCompany() {
    const values = this.otherTitleFormStore.getFormValues();
    this.otherTitleDetails = {
      name: values.name,
      contactName: values.contactName,
      phone: values.cellPhone,
      address: values.address,
      email: values.emailAddress,
      state: values.state,
      streetName: values.streetName,
      streetNumber: values.streetNumber,
      zipCode: values.zipcode,
      city: values.city,
    };
    this.onChange('preferredTitle', this.form.fields.preferredTitle.value);
  }

  onChange = (field, value) => {
    this.onFieldChange(field, value);
  };

  *uploadInsuranceDocument(fileUpload: FileUpload) {
    try {
      const file = fileUpload.file;
      const blob = file.slice(0, file.size, file.type);
      const newFile = new File([blob], fileUpload.name, { type: file.type });
      const apiResponse: ApiResponse = yield this.loanService.uploadInsuranceDocument(
        newFile
      );
      //const documentId = apiResponse.data.data.id; -- use this id
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'File uploaded successfully',
      });
    } catch (error) {
      this.globalStore.notificationStore.showSuccessNotification({
        //message: 'Error uploading file ',
        message: 'File uploaded successfully',
      });
    }
  }
  *fetchLoanId() {
    try {
      if (!this.draftLoanId) {
        const apiResponse: ApiResponse = yield this.loanService.fetchDraftLoanId();
        this.draftLoanId = apiResponse.data.data;
        yield this.getLoanFastTrackPaymentUrl();
      }
    } catch (error) {
      // do nothing
    }
  }

  *updateLoanFastrackInfo(loanId) {
    try {
      const { borrowersRows, property } = this.termStore;
      const data = {
        loanId: loanId,
        randomLoanId: this.draftLoanId,
        ...this.getFormValues().fastTrackOptions,
        properties: 1,
        propertiesAddresses: property.address,
        dealName: getDealName(borrowersRows, [property])
      }
      yield this.loanService.updateLoanFastrackInfo(data);
    }
    catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while saving the modal state',
      });
    }
  }

  *requestFastTrackPaymentEmail(fastTrackInfo: any) {
    try {
      yield this.loanService.requestFastTrackPaymentEmail(fastTrackInfo);
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Email sent successfully',
      });
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while sending the email',
      });
    }
  }

  *loadFastTrackFees() {
    try {
      const response = yield this.loanService.getLoanFastTrackFees();
      this.fastTrackFees = response.data.data;
    } catch (err) {
    }
  }

  *getLoanFastTrackPaymentUrl() {
    try {
      const response = yield this.loanService.getLoanFastTrackPaymentUrl(this.draftLoanId);

      this.fastTrackPaymentUrl = response.data.data.url;
    } catch (err) {
    }
  }

  get canSubmitAppraisalWithTamarisk() {
    return this.termStore.canSubmitAppraisalWithTamarisk;
  }

  setRocUnderwritingFees(value) {
    this.form.fields.lenderUnderwritingFee.value = value;
  }

  setLicenseUpload(items: FileUpload[]) {
    this.licenseUpload = items;
  }

  changePointsPercentage(key, value, keyAmount, loanAmount) {
    this.onChange(key, value);
    const amount = this.calculatePointsAmount(value, loanAmount);
    this.onChange(keyAmount, amount);
  }

  calculatePointsAmount(percentage, loanAmount) {
    return loanAmount ? (loanAmount / 100) * percentage : 0;
  }

  changePointsAmount(key, value, keyPercentage, loanAmount) {
    this.onChange(key, value);
    const percentage = this.calculatePointsPercentage(value, loanAmount);
    this.onChange(keyPercentage, percentage);
  }

  calculatePointsPercentage(amount, loanAmount) {
    return loanAmount ? (amount / loanAmount) * 100 : 0;
  }

}
export default LoanDetailsStore;
