import React from 'react';
import {
  Box,
  List,
  ListItem as MuiListItem,
  ListItemText,
  Divider,
} from '@material-ui/core';
import { Paper } from '@roc/ui';
import { observer } from 'mobx-react';

const ListItem = props => {
  const { label, value, icon = null } = props;
  return (
    <MuiListItem>
      <ListItemText>
        <Box display="flex">
          {icon && <Box ml={1}>{icon}</Box>}
          <Box flexGrow={1}>{label}</Box>
          <Box>{value}</Box>
        </Box>
      </ListItemText>
    </MuiListItem>
  );
};

export type SummaryRow = {
  icon?: any;
  label: string;
  value: string | number;
};

type SummaryTableProps = {
  title: string;
  data: SummaryRow[];
  renderLabel?: (row) => JSX.Element;
  renderValue?: (row) => JSX.Element;
};

export const SummaryTable = observer((props: SummaryTableProps) => {
  const { title, data, renderLabel, renderValue } = props;

  const renderListItem = (row, index) => {
    return (
      <Box key={index}>
        <ListItem
          key={index}
          icon={row.icon}
          label={renderLabel ? renderLabel(row) : row.label}
          value={renderValue ? renderValue(row) : row.value}
        />
        {index !== data.length - 1 && (
          <Divider variant="middle" component="li" />
        )}
      </Box>
    );
  };

  return (
    <Paper title={title}>
      <Box>
        <List>{data.map(renderListItem)}</List>
      </Box>
    </Paper>
  );
});

export default SummaryTable;
