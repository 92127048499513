import React from 'react';
import placeHolderImage from "../../images/Placeholder.svg"
import { Button } from '@roc/ui';
import { ViewModuleOutlined } from '@material-ui/icons';
import { makeStyles, Theme, Grid, createStyles } from '@material-ui/core';
import { useStore } from '@roc/client-portal-shared/hooks';



const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    allPhotosButton: {
      position: 'absolute',
      top: '70%',
      left: '30%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: 'white',
      width: '170px',
      '&:hover': {
        backgroundColor: '#ffffff',
      },
    }

  })
);

export interface ImagesGridProps {
  images: any[];
  imageStyles?: any;
  verticalImages?: boolean;
  showAllPhotosButton?: boolean;
  clickablePhotos?: boolean;
}

export const ImagesGrid: React.FC<ImagesGridProps> = ({
  images,
  imageStyles,
  verticalImages = false,
  showAllPhotosButton = true,
  clickablePhotos = false,
}) => {
  const { compToolStore } = useStore();
  const { compToolPhotosStore } = compToolStore;

  const classes = useStyles();

  const onError = (event) => {
    event.target.src = placeHolderImage;
  };

  const openIndividualPhotoDialog = (event, url) => {
    if (clickablePhotos && url !== placeHolderImage) {
      event.stopPropagation();
      compToolPhotosStore.setSelectedPhoto(url);
      compToolPhotosStore.setOpenIndividualPhoto(true);
    }
  }
  
  return (
    <Grid container spacing={1}>
      {images.map((url, index) => (
        <Grid item xs={6} sm={images.length <= 4 || verticalImages ? 6 : 4} md={images.length <= 4 || verticalImages ? 6 : 4} key={index}>

          {index === images.length - 1 ? (
            <>
              <div style={{
                position: 'relative',
                width: '100%',
                maxWidth: '400px',
              }}>
                <img
                  src={url}
                  loading="lazy"
                  className={imageStyles}
                  onError={onError}
                  onClick={(event) => openIndividualPhotoDialog(event, url)}
                />
                {showAllPhotosButton &&
                  <Button
                    variant="outlined"
                    color="primary"
                    testId="allPhotos"
                    className={classes.allPhotosButton}
                    onClick={() => {
                      compToolStore.setOpenCarouelPhotos(true)
                    }}
                    startIcon={<ViewModuleOutlined />}
                  >
                    ALL PHOTOS
                  </Button>
                }
              </div>
            </>) : (
            <img
              src={url}
              loading="lazy"
              className={imageStyles}
              onError={onError}
              onClick={(event) => openIndividualPhotoDialog(event, url)}
            />
          )}

        </Grid>
      ))}
    </Grid>
  );
};
