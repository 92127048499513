import {
  Box,
  Grid,
  ListItem,
  ListItemText,
  Divider,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { Paper } from '@roc/ui';
import { formatCurrency } from '@roc/ui/utils';
import { observer } from 'mobx-react';

const useStyles = makeStyles(theme => ({
  paper: {
    border: '1px solid',
    borderColor: theme.palette.grey['200'],
  },
  divider: {
    listStyle: 'none',
  },
}));

export const SectionName = ({ name }) => {
  return (
    <Grid item xs={12}>
      <Box pt={1}>
        <Typography variant="h6">{name}</Typography>
      </Box>
    </Grid>
  );
};

export const PricerPaper = props => {
  const classes = useStyles();
  return <Paper className={classes.paper} {...props} />;
};

export const PaperList = ({
  title,
  items,
}: {
  title?: string;
  items: Array<{ label: string; value: string; value2?: string }>;
}) => {
  const classes = useStyles();
  return (
    <PricerPaper>
      {
        title && <Box pl={2}>
          <SectionName name={title} />
        </Box>
      }
      {items.map((item, i) => (
        <>
          {i > 0 && (
            <Divider
              key={`divider-${item.label}`}
              variant="middle"
              component="li"
              className={classes.divider}
            />
          )}
          <ListItem key={item.label}>
            <ListItemText>
              <Box display="flex">
                <Box flexGrow={1}>{item.label}</Box>
                <span />
                {!item.value2 && (
                  <Typography variant="body1">
                    <strong>{item.value}</strong>
                  </Typography>
                )}
                {item.value2 && (
                  <Grid spacing={2} style={{ textAlign: 'right' }}>
                    <Typography variant="body1">
                      <strong>{item.value}</strong>
                    </Typography>
                    <Typography variant="body1">
                      <strong>{item.value2}</strong>
                    </Typography>
                  </Grid>
                )}
              </Box>
            </ListItemText>
          </ListItem>
        </>
      ))}
    </PricerPaper>
  );
};
