import { ApiResponse } from '@roc/client-portal-shared/services';
import { observable, flow, makeObservable } from 'mobx';
import { GlobalStore, FormStore } from '@roc/feature-app-core';
import { capitalize, downloadDocument, formatDate, isEmptyObject, isNil, NO, parseAddress, YES } from '@roc/feature-utils';
import { BorrowerDetailsVerificationService } from '@roc/feature-credit-background-check';
import { FormInfo, FormStatus, IdVerificationForm } from '../../borrowers';
import { BorrowerTrackRecordVerificationStore } from './borrowerTrackRecordVerificationStore';

const detailsVerificationForm = {
  fields: {
    firstName: {
      value: '',
      error: null,
      rule: 'required',
      message: 'The first name field is required.',
    },
    middleName: {
      value: '',
      error: null,
      rule: '',
      message: '',
    },
    lastName: {
      value: '',
      error: null,
      rule: 'required',
      message: 'The last name field is required.',
    },
    phoneNumber: {
      value: '',
      error: null,
      rule: 'required|regex:/^\\d{3}-?\\d{3}-?\\d{4}$/|alpha_dash',
      message: 'phone must be a number with the format xxx-xxx-xxxx',
    },
    dateOfBirth: {
      type: 'DATE',
      value: null,
      error: null,
      rule: 'required',
      format: 'MM/dd/yyyy',
      message: 'Date of birth is required.',
    },
    idType: {
      value: 'US Driver\'s License / Government ID',
      error: null,
      rule: 'required',
      message: 'The last ID Type field is required.',
    },
    ssn: {
      value: '',
      error: null,
      message: 'SSN must be a number with the format xxx-xx-xxxx',
      rule: [
        {
          required_if_one_of: [
            'citizenshipStatus',
            'US Citizen',
            'US Permanent Resident(Green Card Holder)',
            'US Resident with Valid Visa',
          ],
        },
        { regex: '/^\\d{3}-?\\d{2}-?\\d{4}$/' },
      ],
    },
    address: {
      value: '',
      error: null,
      message: 'Address Line 1 is required',
      rule: 'required',
    },
    __isAddressInCorrectFormat__: {
      value: true,
      error: null,
      rule: 'accepted',
      message: 'Invalid Address Format',
    },
    streetNumber: {
      value: '',
      error: null,
      rule: '',
    },
    streetName: {
      value: '',
      error: null,
      rule: '',
    },
    aptNumber: {
      value: '',
      error: null,
      rule: '',
    },
    city: {
      value: '',
      error: null,
      rule: 'required',
      message: 'The city field is required.',
    },
    state: {
      value: '',
      error: null,
      rule: 'required',
      message: 'The state field is required.',
    },
    zipCode: {
      value: '',
      error: null,
      message: 'Zip code must be 5 digits',
      rule: 'required|regex:/^\\d{5}$/|alpha_dash',
    },
    citizenshipStatus: {
      value: 'US Citizen',
      error: null,
      rule: 'required',
    },
    passportNumber: {
      value: '',
      error: null,
      rule: [{ required_if: ['driversLicense', NO] }],
      message: 'The passport number field is required',
    },
    passportExpiration: {
      type: 'DATE',
      value: null,
      error: null,
      format: 'MM/dd/yyyy',
      rule: [{ required_if: ['driversLicense', NO] }],
      message: 'The passport expiration date field is required',
    },
    assets: {
      value: '',
      error: null,
      rule: 'required',
      message: 'The assets field is required.',
    },
    liabilities: {
      value: '',
      error: null,
      rule: 'required',
      message: 'The liabilities field is required.',
    },
    netWorth: {
      value: '',
      error: null,
      rule: 'required',
      message: 'The net worth field is required.',
    },
    experience: {
      value: null,
      error: null,
      rule: '',
      message: 'The transactions field is required.',
    },
    driversLicense: {
      value: YES,
      rule: 'required',
      message: 'This field is required',
    },
    driversLicenseState: {
      value: null,
      rule: [{ required_if: ['driversLicense', YES] }],
      message: 'This field is required',
    },
    driversLicenseNumber: {
      value: null,
      rule: [{ required_if: ['driversLicense', YES] }],
      message: 'This field is required',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};
export class BorrowerDetailsVerificationStore extends FormStore {
  private globalStore: GlobalStore;
  private borrowerDetailsVerificationService: BorrowerDetailsVerificationService;
  borrowerTrackRecordVerificationStore: BorrowerTrackRecordVerificationStore;

  activeStep: number;
  loading: boolean;

  token: string;
  formInfo: FormInfo;
  driversLicense: File | null;
  uwMatrixEnabled: boolean;
  disableNameFields: boolean;

  constructor(globalStore) {
    super({ ...detailsVerificationForm }, globalStore);
    this.globalStore = globalStore;
    this.borrowerDetailsVerificationService = new BorrowerDetailsVerificationService();
    this.borrowerTrackRecordVerificationStore = new BorrowerTrackRecordVerificationStore(this.globalStore, this.borrowerDetailsVerificationService);

    makeObservable(this, {
      activeStep: observable,
      loading: observable,
      token: observable,
      formInfo: observable,
      driversLicense: observable,
      confirmDocusign: flow,
      fetchFormInformation: flow,
      fetchPublicFormInformation: flow,
      resetStore: flow,
      goNextStep: flow,
      goPrevStep: flow,
      saveBorrowerDetails: flow,
      unsubscribedFromDailyReminders: flow,
      downloanDriversLicense: flow,
      isFeatureEnabledForBorrowerDetailsVerification: flow,
      uwMatrixEnabled: observable,
      fetchFormInformationByBorrowerIdForLoanApplications: flow,
    });
    this.setDefaults();
  }
  private setDefaults() {
    this.loading = false;
    this.activeStep = 0;
    this.token = null;
    this.formInfo = null;
    this.driversLicense = null;
    this.uwMatrixEnabled = false;
    this.disableNameFields = true;
  }

  *resetStore() {
    super.reset();
    this.setDefaults();
  }

  *goPrevStep() {
    this.activeStep--;
  }

  *goNextStep() {
    this.activeStep++;
  }

  *fetchFormInformation(token) {
    try {
      this.token = token;
      const response = yield this.borrowerDetailsVerificationService.getFormInformation(
        token
      );
      this.formInfo = response.data.data;
      if (FormStatus.FINISHED === this.formInfo.status) {
        this.activeStep = this.getCurrentActiveStep();
      }
      if (Object.keys(this.formInfo?.savedForm).length !== 0) {
        this.loadBorrowerForm(this.formInfo?.savedForm);
      }
      if (this.form.fields.firstName.value == "" || this.form.fields.lastName.value == "") {
        this.disableNameFields = false;
      }
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'We had an issue while retrieving your form details.',
      });
    }
  }

  *fetchPublicFormInformation(token) {
    try {
      this.token = token;
      const response = yield this.borrowerDetailsVerificationService.getPublicFormInformation(
        token
      );
      this.formInfo = response.data.data;
      if (FormStatus.FINISHED === this.formInfo.status) {
        this.activeStep = this.getCurrentActiveStep();
      }
      if (Object.keys(this.formInfo?.savedForm).length !== 0) {
        this.loadBorrowerForm(this.formInfo?.savedForm);
      }
      if (this.form.fields.firstName.value == "" || this.form.fields.lastName.value == "") {
        this.disableNameFields = false;
      }
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'We had an issue while retrieving your form details.',
      });
    }
  }

  setDriversLicense(file: File) {
    this.driversLicense = file;
  }

  *saveBorrowerDetails() {
    try {
      this.loading = true;
      const values = this.getFormValues();
      const token = this.token;
      yield this.borrowerDetailsVerificationService.saveBorrowerDetails(
        {
          ...values,
          firstName: capitalize(values.firstName),
          lastName: capitalize(values.lastName),
          token,
          dateOfBirth: formatDate(values.dateOfBirth, 'MM/dd/yyyy'),
          passportExpiration: formatDate(
            values.passportExpiration,
            'MM/dd/yyyy'
          ),
          experience: values.experience,
        },
        this.driversLicense
      );
      yield this.fetchPublicFormInformation(this.token);
    } catch (error) {
      console.log(error);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error occurred while saving the contact information',
      });
    } finally {
      this.loading = false;
    }
  }

  *confirmDocusign() {
    try {
      this.formInfo.status = FormStatus.FINISHED;
      const response: ApiResponse = yield this.borrowerDetailsVerificationService.confirmDocusign(
        this.token
      );
      this.activeStep = this.getCurrentActiveStep();
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while completing docusign',
      });
    }
  }

  saveAddressField(address: string, addressDetails, geometryDetails) {
    if (!isNil(addressDetails) && !isNil(geometryDetails)) {
      const parsedAddress = parseAddress(
        address,
        addressDetails,
        geometryDetails
      );
      const streetAddress = parsedAddress.street_number === null ? parsedAddress.street : parsedAddress.street_number + " " + parsedAddress.street;
      this.updateField('address', streetAddress);
      this.updateField('streetNumber', parsedAddress.street_number);
      this.updateField('streetName', parsedAddress.street);
      this.updateField('city', parsedAddress.city);
      this.updateField('state', parsedAddress.state);
      this.updateField('zipCode', parsedAddress.zip);
      this.updateField('aptNumber', parsedAddress.aptNumber);
    } else {
      this.onFieldChange('address', address);
      this.onFieldChange('streetName', address);
    }
  }

  updateField(fieldName, parsedValue) {
    const value = isNil(parsedValue) ? '' : parsedValue;
    this.onFieldChange(fieldName, value);
  }


  *unsubscribedFromDailyReminders(token) {
    try {
      const response: ApiResponse = yield this.borrowerDetailsVerificationService.unsubscribedFromDailyReminders(
        token
      );
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Successfully unsubscribed',
      });
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'We had an issue while unsubscribing from reminders',
      });
    }
  }

  loadBorrowerForm(borrowerVerificationForm: IdVerificationForm) {
    this.loadForm({
      ...borrowerVerificationForm,
      driversLicenseNumber: borrowerVerificationForm.driversLicenseNumber ?? '',
    });
  }

  *downloanDriversLicense() {
    try {
      const response: ApiResponse = yield this.borrowerDetailsVerificationService.downloadDriversLicense(
        this.formInfo.savedForm?.creditBackgroundRequestId
      );
      downloadDocument(response?.data, response?.headers, 'download');
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while downloading driver license.',
      });
    }
  }

  *isFeatureEnabledForBorrowerDetailsVerification(featureName) {
    try {
      if (this.globalStore?.userFeatures) {
        this.uwMatrixEnabled = this.globalStore?.userFeatures.uwMatrixEnabled;
      }
      else {
        const response = yield this.borrowerDetailsVerificationService.isFeatureEnabledForBorrowerDetailsVerification(
          this.token, featureName
        );
        this.uwMatrixEnabled = response.data.data;
        if (FormStatus.FINISHED === this.formInfo?.status) {
          this.activeStep = this.getCurrentActiveStep();
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  getCurrentActiveStep = () => {
    if (this.uwMatrixEnabled) {
      if (this.formInfo?.runCredit && this.formInfo?.showDocusign) {
        return 2;
      }
    }
    return 1;
  }

  *fetchFormInformationByBorrowerIdForLoanApplications(borrowerId: number, isPersonalGuarantor: boolean) {
    try {
      const response = yield this.borrowerDetailsVerificationService.getLoanApplicationBorrowerDetailsFormInfoByBorrowerId(borrowerId, isPersonalGuarantor);
      this.formInfo = response.data.data;
      this.token = this.formInfo?.savedForm?.requestToken;

      if (FormStatus.FINISHED === this.formInfo?.status) {
        this.activeStep = this.getCurrentActiveStep();
      }
      if (!isEmptyObject(this.formInfo?.savedForm || {})) {
        this.loadBorrowerForm(this.formInfo?.savedForm);
      }
      if (this.form.fields.firstName.value == "" || this.form.fields.lastName.value == "") {
        this.disableNameFields = false;
      }
    } catch (e) {
      console.log(e);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while getting form information',
      });
    }
  }

}
