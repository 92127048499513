import React, { MouseEvent, ReactNode, useEffect } from 'react';
import { Button, StandardDialog, trueFalseOptions } from '@roc/ui';
import { observer } from 'mobx-react';
import { BorrowersStore, QualifyBorrowerStore } from '../stores';
import { Grid } from '@material-ui/core';
import { Borrower } from '../types';
import { isNil, LoanType } from '@roc/feature-utils';
import { BorrowerWithPreQualificationStepsForm } from './borrowerWithPreQualificationStepsForm';

export type BorrowerWithPreQualificationDialogProps = {
  open: boolean;
  handleClose: () => void;
  handleSaveBorrower: (onSuccess?: () => void) => void;
  handlePreQualifyBorrower: () => void;
  store: QualifyBorrowerStore;
  borrower?: Borrower;
  personalInformation: ReactNode;
  title: string;
  initialStep: number;
  borrowersStore: BorrowersStore;
};

export const BorrowerWithPreQualificationDialog = observer((props: BorrowerWithPreQualificationDialogProps) => {
  const {
    open,
    handleClose,
    store,
    borrower,
    personalInformation,
    handleSaveBorrower,
    title,
    initialStep,
    borrowersStore,
    handlePreQualifyBorrower,
  } = props;

  const { borrowerSetupStore } = borrowersStore;

  useEffect(() => {
    store.reset();
    if (borrower) {
      store.setInitialValues(borrower);
      if (!isNaN(Number(borrower.borrowerId))) {
        borrowerSetupStore.init(borrower, !isNil(borrower?.personalGuarantor) ? borrower?.personalGuarantor : borrower?.runCreditOnCreation, LoanType.RESIDENTIAL_BRIDGE);
      }
    }
  }, [borrower]);

  return (
    <StandardDialog
      maxWidth={'md'}
      open={open}
      title={title}
      handleClose={handleClose}
      dialogContent={<BorrowerWithPreQualificationStepsForm
        handlePreQualifyBorrower={handlePreQualifyBorrower}
        store={store}
        borrower={borrower}
        personalInformation={personalInformation}
        handleSaveBorrower={handleSaveBorrower}
        initialStep={initialStep}
        handleClose={handleClose}
        borrowersStore={borrowersStore}
      />}
    />
  );
});

export default BorrowerWithPreQualificationDialog;
