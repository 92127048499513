const LOAN_FUNDER_LLC = 'Loan Funder LLC';
const LOAN_FUNDER_LLC_WITH_MERS = 'Loan Funder LLC with MERS as nominee';
const SPL_WITH_MERS = 'SPL with MERS as nominee';
const DBA = 'DBA';

const CAPITAL_PROVIDER = 'Capital Provider';
const CAPITAL_PROVIDER_WITH_MERS = 'Capital Provider with MERS as nominee';
const YOU_WITH_MERS = 'You with MERS as nominee';

export const mapFundingEntity = fundingPreference => {
  if (fundingPreference === LOAN_FUNDER_LLC) {
    return CAPITAL_PROVIDER;
  } else if (fundingPreference === LOAN_FUNDER_LLC_WITH_MERS) {
    return CAPITAL_PROVIDER_WITH_MERS;
  } else if (fundingPreference === SPL_WITH_MERS) {
    return YOU_WITH_MERS;
  } else if (fundingPreference === DBA) {
    return DBA;
  } else {
    return '';
  }
};
