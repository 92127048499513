export const roomsOptions = [
  {
    label: 'Any',
    value: null,
  },
  {
    label: 1,
    value: 1,
  },
  {
    label: 2,
    value: 2,
  },
  {
    label: 3,
    value: 3,
  },
  {
    label: 4,
    value: 4,
  },
  {
    label: '5+',
    value: '5+',
  }
];

export const listingOptions = [
  {
    label: 'Active',
    value: ['True'],
  },
  {
    label: 'Sold',
    value: ['False'],
  }
];

export const yesOrNoOptions = [
  {
    label: 'Yes',
    value: ['True'],
  },
  {
    label: 'No',
    value: ['False'],
  }
];

export const allPhotosOptions = [
  {
    label: 'All Properties',
    value: ['True', 'False'],
  },
  {
    label: 'Properties Only w/ Photos',
    value: ['True'],
  }
];


export const compsRecencyOptions = [
  {
    label: '6 Months',
    value: 6,
  },
  {
    label: '12 Months',
    value: 12,
  },
  {
    label: '18 Months',
    value: 18,
  },
  {
    label: '24 Months',
    value: 24,
  },
  {
    label: '36 Months',
    value: 36,
  }
];

export const conditionDetailsOptions = [
  {
    label: 'Very Bad',
    value: ['Very bad'],
  },
  {
    label: 'Bad',
    value: ['Bad'],
  },
  {
    label: 'Moderate',
    value: ['Moderate'],
  },
  {
    label: 'Good',
    value: ['Good'],
  },
  {
    label: 'Very Good',
    value: ['Very good'],
  },
];

export const confidenceConditionOptions = [
  {
    label: 'Not Confident',
    value: ['Can try', 'Impossible to understand'],
  },
  {
    label: 'Confident',
    value: ['Probable', 'Definitely'],
  }
];