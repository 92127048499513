import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@material-ui/core';
import { FileUploadModal, FileUpload, createCurrencyField, TextField } from '@roc/ui';
import { ForeclosureInvoiceFormStore } from '../stores/foreclosureInvoiceFormStore';
import { isNotBlank } from '@roc/client-portal-shared/utils';

interface IForeclosureInvoiceModalProps {
  loanId: string;
  open: boolean;
  store: ForeclosureInvoiceFormStore;
  typeOfFee: string;
  onClose: () => void;
  onUpload: () => void;
}

export const ForeclosureInvoiceModal = ({ loanId, open, store, typeOfFee, onClose, onUpload }: IForeclosureInvoiceModalProps) => {
  const [invoiceNumber, setInvoiceNumber] = useState('');
  const [billingAmount, setBillingAmount] = useState('');

  const _onUpload = async (uploads: FileUpload[]) => {
    await store.uploadForeclosureInvoice(loanId, uploads[0], typeOfFee);
    onUpload();
  };

  useEffect(() => {
    store.reset();
  }, [open]);

  const onFieldChange = (fieldName: string, value: string) => {
    store.onFieldChange(fieldName, value);
    switch (fieldName) {
      case 'invoice':
        setInvoiceNumber(value);
        break;
      case 'fee':
        setBillingAmount(value);
        break;
      default:
        break;
    };
  };

  return (
    <FileUploadModal
      title="Foreclosure Invoice"
      open={open}
      top={
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                testId="invoice-number"
                variant="outlined"
                standaloneLabel
                label="Invoice #"
                value={store.form.fields.invoice.value}
                onChange={e => onFieldChange('invoice', e.target.value)}
                required={true}
                helperText={
                  isNotBlank(store.form.fields.invoice.error) ?
                    store.form.fields.invoice.message :
                    ''
                }
                error={isNotBlank(store.form.fields.invoice.error)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              {createCurrencyField({
                store,
                testId: 'fee',
                fieldName: 'fee',
                label: 'Billing Amount',
                groupSeparator: ',',
                onChange: onFieldChange
              })}
            </Grid>
          </Grid>
        </Box>
      }
      multiple={false}
      disabled={!(store.form.fields.invoice?.value && store.form.fields.fee?.value)}
      onClose={onClose}
      onUpload={_onUpload}
    />
  );
};
