import { Box, Grid, IconButton, Typography } from '@material-ui/core';
import { ChevronLeft } from '@material-ui/icons';
import {
  DrawRequest as _DrawRequest,
  DrawRequestShared,
  DrawSuccess,
} from '@roc/feature-draws';
import { useLoanStore } from '@roc/feature-loans';
import { useLoanRoutes } from '@roc/feature-loans-routes-config';
import { Button } from '@roc/ui';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { useHistory } from 'react-router';
import { LoanDetailsPaper } from '../components/loanDetailsPaper';

export const DrawRequest = observer(() => {
  const { loanStore } = useLoanStore();
  const { loanRoutesConfig } = useLoanRoutes();
  const { push } = useHistory();
  const { loanDetails } = loanStore;
  const loanId = loanDetails?.loanId;

  const [showSuccessScreen, setShowSuccessScreen] = useState(false);

  if (showSuccessScreen) {
    return (
      <LoanDetailsPaper>
        <DrawSuccess />;
      </LoanDetailsPaper>
    );
  }

  return (
    <>
      <Grid item xs={12}>
        <Box display="flex" alignItems="center">
          <IconButton
            aria-label="back"
            color="secondary"
            onClick={() =>
              push(loanRoutesConfig.loans(loanId).children.draws.url)
            }
          >
            <ChevronLeft fontSize="large" />
          </IconButton>
          <Typography variant="h5">DRAW REQUEST</Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <DrawRequestShared setShowSuccessScreen={setShowSuccessScreen} />
      </Grid>
    </>
  );
});
