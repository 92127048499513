import { Box, makeStyles, Theme } from '@material-ui/core';
import { Home } from '@material-ui/icons';
import { Layout } from '@roc/feature-app-core';
import { BreadCrumbItem } from '@roc/feature-types';
import { BreadcrumbsNew, PDFPreview, StandardDialog, VerticalBarLoader } from '@roc/ui';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { DocumentList } from './components/documentList';
import { HtmlDocument } from './components/htmlDocument';
import { DocumentLibraryStore } from './store/documentLibraryStore';
import axios from 'axios';

const useStyles = makeStyles((theme: Theme) => ({
  breadCrumbContainer: {
    padding: 16,
    border: '1px solid #eee',
    borderRadius: 8,
    background: '#fff',
  },
  dialogRoot: {
    height: '100%',
  },
}));

export const DocumentLibraryHome = observer(({
  documentLibraryStoreNew, isInternal = false, nportalInternal }: { documentLibraryStoreNew: DocumentLibraryStore, isInternal?: boolean, nportalInternal?: string }) => {

  const [breadCrumbItems, setBreadCrumbItems] = useState<BreadCrumbItem[]>([]);
  const [openDownloanPrintModal, setOpenDownloanPrintModal] = useState(false);
  const [isHtmlDocument, setIsHtmlDocument] = useState(false);
  const [pdfLoading, setPdfLoading] = useState(false);
  const [result, setResult] = useState<any>();
  const [pdfData, setPdfData] = useState<ArrayBuffer | null>(null);
  const [pdfTitle, setPdfTitle] = useState<string | null>(null);

  const classes = useStyles();

  useEffect(() => {
    if (nportalInternal === 'true') {
      documentLibraryStoreNew.loadNportalInternalData(false);
    } else if (isInternal) {
      documentLibraryStoreNew.loadData(false)
    } else {
      documentLibraryStoreNew.loadDataResources(false);
    }
  }, []);

  useEffect(() => {
    setIsHtmlDocument(documentLibraryStoreNew?.currentData?.fileType == 'html');
  }, [documentLibraryStoreNew?.currentData]);

  useEffect(() => {
    const _breadCrumbItems: BreadCrumbItem[] = [];
    if (documentLibraryStoreNew.currentData?.parent?.length > 0) {
      const arr = documentLibraryStoreNew.currentData.parent;
      arr.forEach(item => {
        if (item == "Home") {
          _breadCrumbItems.push({
            link: true,
            title: "Home",
            icon: <Home />,
          })
        } else {
          _breadCrumbItems.push({
            link: true,
            title: item,
          });
        }
      });
      _breadCrumbItems.push({
        link: false,
        title: documentLibraryStoreNew.currentData.name,
      });
    } else {
      _breadCrumbItems.push({
        link: true,
        title: "Home",
        icon: <Home />,
      });
    }
    setBreadCrumbItems(_breadCrumbItems);
  }, [documentLibraryStoreNew.currentData]);


  const onDocumentItemClick = (item) => {
    if (item.folder) {
      documentLibraryStoreNew.setCurrentData([...item.parent, item.name]);
    } else if (item.fileType == 'html') {
      setIsHtmlDocument(true);
      documentLibraryStoreNew.setCurrentData([...item.parent, item.name]);
    } else if (item.fileType == 'pdf') {
      setPdfData(null);
      setPdfTitle(null);
      setOpenDownloanPrintModal(true);
      setPdfLoading(true);

      documentLibraryStoreNew.downloadDocumentAsBlob(item.path, nportalInternal)
        .then(result => {
          axios.get(result.blobUrl.toString(), { responseType: 'arraybuffer' })
            .then(response => {
              setPdfData(response.data);
              setPdfTitle(result.title);
              setPdfLoading(false);
            })
            .catch(error => {
              console.error('Error fetching PDF data:', error);
            });
        });

    } else {
      documentLibraryStoreNew.downloadDocument(item.path, nportalInternal);
    }
  };

  if (!documentLibraryStoreNew.currentData) {
    return null;
  }

  return (
    <Layout title="Document Library" maxWidth={'lg'}>
      <BreadcrumbsNew
        className={classes.breadCrumbContainer}
        breadCrumbItems={breadCrumbItems}
        breadCrumbItemClick={(i) => {
          const route = [];
          for (let j = 0; j <= i; j++) {
            route.push(breadCrumbItems[j].title);
          }
          setIsHtmlDocument(false);
          documentLibraryStoreNew.setCurrentData(route);
        }}
      />

      <Box mt={2}>
        {
          isHtmlDocument ?
            <HtmlDocument contentId={documentLibraryStoreNew.currentData?.contentId}
              documentLibraryStoreNew={documentLibraryStoreNew}
            /> :
            <DocumentList
              items={documentLibraryStoreNew.currentData?.items ?? []}
              onDocumentItemClick={onDocumentItemClick}
            />
        }
      </Box>
      <PDFPreview
        open={openDownloanPrintModal}
        title={pdfTitle}
        data={pdfData} // pass the ArrayBuffer
        headers={{}} // you need to determine what headers to pass
        fileName={pdfTitle}
        onClose={() => {
          setResult({});
          setOpenDownloanPrintModal(false);
        }}
      />


    </Layout>
  );
});
