import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { FormControl, FormHelperText, Grid, InputAdornment, Typography } from '@material-ui/core';
import { isNotBlank, LoanSubType, lenderUnderwritingFeeTooltip, multifamilyLoanSubtypes, isNil } from '@roc/feature-utils';
import { Add } from '@material-ui/icons';
import CompanyForm from './companyForm';
import { Card, NumberFormat, TextField, CurrencyField, SubmitButton, FileUpload, FileUploadArea, createTextField } from '@roc/ui';
import { formatCurrency } from '@roc/ui/utils';
import { observer } from 'mobx-react';
import { RentalPortfolioStore, SinglePropertyStore } from './../stores';
import {
  GfdPayment,
  LoanOriginator,
  PreferredClosingAttorney,
  PreferredInsurance,
  PreferredTitleCompany,
  RequestedClosingDate,
  SubmitAppraisalWithTamarisk,
  FastTrackLoan,
  FacoParticipants,
  LenderParticipants
} from './formComponents';
import { useBaseStore, useUserStore } from '@roc/feature-app-core';
import { useEffect } from 'react';
import { useState } from 'react';
import { useStore } from '@roc/client-portal-shared/hooks';
import { getMultipartFile } from '../utils/fileUtils';
import { requiredLicenseStates, Roles } from '@roc/feature-utils';
import { StateLicenseUpload } from './formComponents';
import { normalizeState } from '@roc/feature-utils';
import { nonReferralFeeStates } from '@roc/feature-utils';

const useStyles = makeStyles(theme =>
  createStyles({
    button: {
      marginRight: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  })
);
interface LoanDetailsProps {
  store: SinglePropertyStore | RentalPortfolioStore;
}
export const ELMSURE = 'Elmsure';
const OTHER = 'Other';
const insuranceOptions = [
  { value: ELMSURE, label: ELMSURE },
  { value: OTHER, label: OTHER },
];
const WIMBA = 'Wimba';
const titleOptionsAllowedWimba = [
  { value: WIMBA, label: WIMBA },
  { value: OTHER, label: OTHER },
];
const isSinglePropertySubType = loanSubtype => {
  return (
    loanSubtype === LoanSubType.SINGLE_PROPERTY ||
    loanSubtype === LoanSubType.SINGLE_PROPERTY_MULTIFAMILY ||
    loanSubtype === LoanSubType.SINGLE_PROPERTY_MIXED_USE
  );
};
function LoanDetailsTerm({ store }: LoanDetailsProps) {
  const classes = useStyles();
  const {
    pricerSummaryStore,
    loanDetailsStore,
    loanSubtype,
    globalStore,
    brokersStore
  } = store;
  const {
    spreadRate,
    amount,
    amortizationLabel,
  } = pricerSummaryStore.getPricerLoanTerms();
  const {
    oneTimeYieldSpreadPremium,
    rateType,
    loanToValue,
  } = pricerSummaryStore.form.fields;
  const { lenderFeesFormStore } = pricerSummaryStore;
  const { isFacoOriginator } = loanDetailsStore.loanParticipantsStore;
  const { userStore } = useUserStore();
  const allowLoanPricing = userStore.allowLoanPricing;
  const { form } = store.loanDetailsStore;
  const { pricerFeesEnabled } = globalStore.userFeatures;
  if (!allowLoanPricing) {
    form.fields.brokerPointsIn.value = 0;
    form.fields.lenderPoints.value = 0;
  }
  const isInternal = globalStore.lenderInfo?.isInternal;
  if (isInternal) {
    form.fields.lenderPoints.value = 0;
    lenderFeesFormStore.form.fields.originationPointsTpo.value = 0;
  }
  const { lenderDetailsStore } = useStore();
  const [uploads, setUploads] = useState<FileUpload[]>([]);

  useEffect(() => {
    loanDetailsStore.getLenderAttorneys();
    setUploads(loanDetailsStore.licenseUpload);
  }, []);

  useEffect(() => {
    if (uploads.length > 0 && uploads !== loanDetailsStore.licenseUpload) {
      loanDetailsStore.setLicenseUpload(uploads);
      lenderDetailsStore.lenderDocumentsStore.uploadDocument(`State License - ${normalizeState(store.property.state)}`, getMultipartFile(uploads[0]));
    }
  }, [uploads]);

  const isMultifamilySubType = multifamilyLoanSubtypes.includes(store.getLoanSubtypeBasedOnPropertyTypes());
  const isBrokerUser = userStore.userInformation?.roles?.includes(Roles.BROKER);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4">Loan Details</Typography>
          <br />
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card title="Loan Details">
              <Grid item container direction="row" spacing={2}>
                <Grid item xs={6} md={3}>
                  <Typography>
                    <b>Requested Loan Amount</b>
                  </Typography>
                </Grid>
                <Grid item xs={6} md={3}>
                  <Typography>{formatCurrency(amount)}</Typography>
                </Grid>
                <Grid item xs={6} md={3}>
                  <Typography>
                    <b>Requested Loan To Value</b>
                  </Typography>
                </Grid>
                <Grid item xs={6} md={3}>
                  <Typography>{`${loanToValue.value}%`}</Typography>
                </Grid>
                <Grid item xs={6} md={3}>
                  <Typography>
                    <b>Rate Type</b>
                  </Typography>
                </Grid>
                <Grid item xs={6} md={3}>
                  <Typography>{rateType.value}</Typography>
                </Grid>
                <Grid item xs={6} md={3}>
                  <Typography>
                    <b>Amortization</b>
                  </Typography>
                </Grid>
                <Grid item xs={6} md={3}>
                  <Typography>{amortizationLabel}</Typography>
                </Grid>
                {allowLoanPricing && <>
                  <Grid item xs={6} md={3}>
                    <Typography>
                      <b>Spread Rate charged to Borrower</b>
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <Typography>{`${spreadRate}%`}</Typography>
                  </Grid>
                </>
                }
                {allowLoanPricing &&
                  <>
                    <Grid item xs={6} md={3}>
                      <Typography>
                        <b>One time yield spread Premium</b>
                      </Typography>
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <Typography>{`${oneTimeYieldSpreadPremium.value}%`}</Typography>
                    </Grid>
                  </>
                }
                {pricerFeesEnabled && (
                  <>
                    <Grid item xs={6} md={3}>
                      <Typography>
                        <b>Total Underwriting & Processing Fees</b>
                      </Typography>
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <Typography>{`${formatCurrency(lenderFeesFormStore.totalFees)}`}</Typography>
                    </Grid>
                  </>
                )}
              </Grid>
            </Card>
          </Grid>
          <Grid xs={12}>
            {isBrokerUser ? (
              <Grid>
                <Grid item xs={12}>
                  <BrokerPoints store={store} />
                </Grid>
              </Grid>
            ) : (
              allowLoanPricing &&
              <Grid>
                {!pricerFeesEnabled && (
                  <Grid item xs={12}>
                    <LenderPoints store={store} />
                  </Grid>
                )}
                {globalStore.userFeatures?.allowLenderUnderwritingFee && !pricerFeesEnabled &&
                  <Grid item xs={12}>
                    <LenderUnderwritingFee store={store} />
                  </Grid>
                }
                <Grid item xs={12}>
                  <PreferredClosingAttorney store={loanDetailsStore} />
                </Grid>
                {userStore.allowLoanPricing && (
                  <Grid item xs={12}>
                    <PreferredInsurance store={loanDetailsStore} />
                  </Grid>
                )}
                {userStore.allowLoanPricing && (
                  <Grid item xs={12}>
                    <PreferredTitleCompany store={loanDetailsStore} />
                  </Grid>
                )}
                {loanDetailsStore.form.fields.preferredTitle.value === OTHER && (
                  <Grid item xs={12}>
                    <SubmitButton
                      className={classes.button}
                      variant="contained"
                      color="secondary"
                      startIcon={<Add />}
                      testId="addOtherTitle"
                      onClick={() => loanDetailsStore.setOtherTitleCompany()}
                      submitDialogProps={{
                        title: 'Title',
                        body: (
                          <CompanyForm
                            store={loanDetailsStore.otherTitleFormStore}
                          />
                        ),
                        okButtonText: 'Confirm',
                        submitValidation: () => {
                          loanDetailsStore.otherTitleFormStore.runFormValidation();
                          return loanDetailsStore.otherTitleFormStore.form.meta
                            .isValid;
                        },
                      }}
                    >
                      ADD INFO
                    </SubmitButton>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <RequestedClosingDate store={loanDetailsStore} />
                </Grid>
              </Grid>
            )}
            {isFacoOriginator ? (
              <FacoParticipants xs={12} store={loanDetailsStore} />
            ) : (
              <LenderParticipants
                xs={12}
                store={loanDetailsStore}
                originatorFieldName="loanOriginator"
              />
            )}
            {allowLoanPricing &&
              <Grid>
                {(isSinglePropertySubType(loanSubtype) &&
                  !globalStore.userFeatures?.tamariskAppraisal) && (
                    <>
                      <Grid item xs={12}>
                        <SubmitAppraisalWithTamarisk store={loanDetailsStore} />
                      </Grid>
                      {loanDetailsStore.form.fields.submitAppraisalWithTamarisk
                        .value === 'Y' &&
                        globalStore.userFeatures?.tamariskGfd && (
                          <Grid item xs={12}>
                            <GfdPayment store={loanDetailsStore} />
                          </Grid>
                        )}
                    </>
                  )}
                {(globalStore.userFeatures.fastTrack && isNil(store?.editLoanJson?.loanId) &&
                  isSinglePropertySubType(loanSubtype) &&
                  (loanDetailsStore.form.fields.preferredTitle.value ||
                    loanDetailsStore.form.fields.preferredInsurance.value)) && (
                    <Grid item xs={12}>
                      <FastTrackLoan
                        store={loanDetailsStore}
                        sendPaymentEmail={() => store.sendFastTrackPayment()}
                        propertiesQuantity={store.properties.length || 1}
                      />
                    </Grid>
                  )}
              </Grid>
            }
            <Grid container spacing={10}>
              <Grid item xs={12} md={5}>
                {globalStore.lenderInfo?.showTPOLicenseValidation && requiredLicenseStates.includes(normalizeState(store.property.state)) &&
                  !globalStore.lenderInfo?.stateLicenseList?.includes(normalizeState(store.property.state)) && (
                    <StateLicenseUpload store={loanDetailsStore} setUploads={setUploads} property={store.property} />
                  )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <br />
      <br />
    </>
  );
}
export const GridContainer = ({ children }) => {
  return (
    <Grid container spacing={4} style={{ margin: '3px 10px' }}>
      <Grid item xs={12} sm={12} md={6}>
        {children}
      </Grid>
    </Grid>
  );
};
const LenderPoints = observer(({ store }) => {
  const { form } = store.loanDetailsStore;
  const handleLenderPointsChange = (name, value) => {
    const lenderPointValue = value ? Number(value) : value;
    store.loanDetailsStore.onFieldChange(name, lenderPointValue);
    store.loanDetailsStore.handleLenderPoints(lenderPointValue);
  };
  return (
    <FormControl fullWidth error={form.fields.lenderPoints.error}>
      <NumberFormat
        customInput={TextField}
        required={true}
        variant="outlined"
        label="Lender Points"
        testId="lenderPoints"
        value={form.fields.lenderPoints.value}
        allowNegative={false}
        onChange={e => handleLenderPointsChange('lenderPoints', e.target.value)}
        decimalScale={2}
        InputProps={{
          endAdornment: <InputAdornment position="end">%</InputAdornment>,
        }}
        error={isNotBlank(form.fields.lenderPoints.error)}
        standaloneLabel
        fullWidth
      />
      {form.fields.lenderPoints.error && (
        <FormHelperText>{form.fields.lenderPoints.error}</FormHelperText>
      )}
    </FormControl>
  );
});
export const BrokerPoints = observer(({ store }) => {
  const { form } = store.loanDetailsStore;
  return (
    <NumberFormat
      customInput={TextField}
      required={false}
      variant="outlined"
      label="Broker Points"
      testId="brokerPointsIn"
      allowNegative={false}
      value={form.fields.brokerPointsIn.value}
      onChange={e =>
        store.loanDetailsStore.onFieldChange('brokerPointsIn', e.target.value)
      }
      decimalScale={2}
      InputProps={{
        endAdornment: <InputAdornment position="end">%</InputAdornment>,
      }}
      helperText={form.fields.brokerPointsIn.error}
      error={isNotBlank(form.fields.brokerPointsIn.error)}
      standaloneLabel
      fullWidth
    />
  );
});
const LenderUnderwritingFee = observer(({ store }) => {
  const { form } = store.loanDetailsStore;
  return (
    <CurrencyField
      standaloneLabel
      label="Lender Underwriting Fee"
      value={form.fields.lenderUnderwritingFee.value}
      errorText={form.fields.lenderUnderwritingFee.error}
      error={isNotBlank(form.fields.lenderUnderwritingFee.error)}
      onChange={(name, value) => store.loanDetailsStore.onFieldChange('lenderUnderwritingFee', Number(value))}
      tooltip={lenderUnderwritingFeeTooltip}
      variant="outlined"
      fullWidth
      required
      testId="lenderUnderwritingFee"
    />
  );
});
export default observer(LoanDetailsTerm);