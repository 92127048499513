import {
  Badge,
  Box,
  Container,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu
} from '@material-ui/core';
import { lighten, makeStyles, withStyles } from '@material-ui/core/styles';
import { MoreVert, NavigateNext } from '@material-ui/icons';
import {
  InfiniteScrollGrid
} from '@roc/feature-app-core';
import { PORTAL_COLORS } from '@roc/feature-utils';
import {
  LinkColumn, SelectFilterComponent,
  SelectFloatingFilterComponent, MenuItem,
  TextColumn,
  TextColumnWithOnClick
} from '@roc/ui';
import { observer } from 'mobx-react';
import React, { useState, useEffect } from 'react';
import { useLoanRoutes } from '@roc/feature-loans-routes-config';
import { useLoanStore } from '../hooks/useLoanStore';
import { SingleTaskView } from '@roc/feature-general-tasks';
import { Alert } from '@material-ui/lab';

const StyledBadge = withStyles(theme => ({
  badge: {
    right: -12,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
    fontSize: '11px',
    backgroundColor: PORTAL_COLORS.unreadMessages,
    color: 'white',
    fontWeight: 700,
    userSelect: 'none',
  },
}))(Badge);

const useStyles = makeStyles((theme) => ({
  redRow: {
    '& .ag-cell': {
      backgroundColor: lighten(theme.palette.error.main, 0.85)
    }
  }
}))

export const MyAppraisalToDosGrid = observer(({ columns }) => {
  const { loanStore } = useLoanStore();
  const [anchorEl, setAnchorEl] = useState<HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const [currentRow, setCurrentRow] = useState(null);
  const [taskId, setTaskId] = useState(null);

  const frameworkComponents = {
    selectFilter: SelectFilterComponent,
    selectFloatingFilter: SelectFloatingFilterComponent,
    loanIdCellRenderer: params => {
      return (
        <StyledBadge>
          {
            TextColumnWithOnClick({
              ...params,
              onClick: () => setTaskId(params?.node?.data?.taskId)
            })
          }
        </StyledBadge>
      )
    },
    actionCellRenderer: params => {
      return (
        <IconButton onClick={e => openMenu(e, params.node.data)}>
          <MoreVert color="primary" />
        </IconButton>
      );
    },
  };

  const openMenu = (event: React.MouseEvent<HTMLElement>, record) => {
    setCurrentRow(record);
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setCurrentRow(null);
  };

  const handleCloseTaskModal = () => {
    setTaskId(null);
    handleMenuClose();
    loanStore.myAppraisalToDosGridStore.resetAndFetchGridData();
  }

  return (
    <>
      <Box>
        <Alert severity="info">
          Click on the loan ID or the three dots under 'Action' to open the task. From the Task Interface, you can update the task status to 'Pending on Borrower' or move it back to the 'Capital Provider'.
        </Alert>
      </Box>
      <InfiniteScrollGrid
        columns={columns}
        frameworkComponents={frameworkComponents}
        store={loanStore.myAppraisalToDosGridStore}
      />
      <Menu
        id="menu-appbar"
        getContentAnchorEl={null}
        anchorEl={anchorEl}
        keepMounted
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={menuOpen}
        onClose={handleMenuClose}
      >
        <MenuItem
          testId='task-item'
          onClick={() => {
            setTaskId(currentRow?.taskId);
          }}>
          <ListItemIcon>
            <NavigateNext />
          </ListItemIcon>
          <ListItemText>Task</ListItemText>
        </MenuItem>
      </Menu>
      <SingleTaskView taskId={taskId} onClose={handleCloseTaskModal} />
    </>
  );
});
