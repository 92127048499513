import {
  Button,
  createTextField,
  PrintableAreaRef,
  StandardDialog,
} from '@roc/ui';
import { observer } from 'mobx-react';
import { BorrowerEmailFormStore } from '../../../stores/summary/borrowerEmailFormStore';
import { Grid, Typography } from '@material-ui/core';
import { useEffect, useRef } from 'react';
import { DownloadableQuote } from './downloadableQuote';
import { QuoteTypeStore } from '../../../stores/quoteTypeStore';
import { useBaseStore } from '@roc/feature-app-core';

interface BorrowerEmailFormProps {
  store: QuoteTypeStore;
}

const BorrowerEmailForm = observer(
  ({ store }: { store: BorrowerEmailFormStore }) => {
    const { globalStore } = useBaseStore();
    const fromEmail =
      globalStore.portalConfiguration?.portalConfiguration?.generalConfiguration
        ?.fromEmail;
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="body1" align="left">
            Enter the information below to send this quote to your borrower. It
            will be sent from {fromEmail}, and you'll be able to preview it
            before sending.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          {createTextField({
            label: 'Your Email (Required)',
            fieldName: 'yourEmail',
            testId: 'yourEmail',
            store,
            standaloneLabel: false,
            disabled: true,
          })}
        </Grid>
        <Grid item xs={12} sm={6}>
          {createTextField({
            label: 'Borrower’s Email (Required)',
            fieldName: 'borrowerEmail',
            testId: 'borrowerEmail',
            store,
            standaloneLabel: false,
          })}
        </Grid>
        <Grid item xs={12}>
          {createTextField({
            label: 'Email Subject',
            fieldName: 'emailSubject',
            testId: 'emailSubject',
            store,
            standaloneLabel: false,
          })}
        </Grid>
        <Grid item xs={12}>
          {createTextField({
            label: 'Email Content',
            fieldName: 'emailContent',
            testId: 'emailContent',
            store,
            standaloneLabel: false,
            multiline: true,
            minRows: 11,
          })}
        </Grid>
      </Grid>
    );
  }
);

export type BorrowerEmailDialogProps = {
  open?: boolean;
  onClose?: () => void;
  store?: QuoteTypeStore;
};

export const BorrowerEmailDialog = observer(
  ({ store, open, onClose }: BorrowerEmailDialogProps) => {
    const ref = useRef<PrintableAreaRef>(null);
    const { summaryStore } = store;
    const { borrowerEmailFormStore } = summaryStore;

    useEffect(() => {
      if (open) {
        borrowerEmailFormStore.loadDefaultValues();
      }
    }, [open]);

    const sendEmail = async () => {
      const url = await ref.current.getPdfBlobUrl();
      const response = await fetch(url.toString());
      const blob = await response.blob();
      const file = new File([blob], 'quote.pdf');

      await borrowerEmailFormStore.sendEmail(file);
      onClose();
    };

    const dialogActions = (
      <>
        <Button
          testId="cancel-email-borrower"
          color="primary"
          variant="outlined"
          style={{ marginRight: '8px' }}
          onClick={onClose}
        >
          CANCEL
        </Button>
        {/* <Button
          testId="preview-email-borrower"
          color="primary"
          variant="contained"
          disabled={true}
        >
          PREVIEW
        </Button> */}
        <Button
          testId="preview-email-borrower"
          color="primary"
          variant="contained"
          onClick={sendEmail}
        >
          SEND
        </Button>
      </>
    );

    return (
      <StandardDialog
        open={open}
        title="Email Quote to Borrower"
        maxWidth="md"
        handleClose={onClose}
        dialogActions={dialogActions}
        dialogContent={
          <>
            <BorrowerEmailForm store={borrowerEmailFormStore} />
            <div style={{ height: 0, overflow: 'hidden' }}>
              <div style={{ width: 100 }}>
                <DownloadableQuote store={store} ref={ref} />
              </div>
            </div>
          </>
        }
      />
    );
  }
);
