import React from 'react';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@material-ui/core';
import { Button } from '@roc/ui';
import { observer } from 'mobx-react';
import { ScopeOfWorkV2FormBaseStore } from '@roc/feature-sow-shared/stores/v2';

type SubcategoriesGeneralDetailsModalProps = {
  store: ScopeOfWorkV2FormBaseStore;
};

const ConfirmPopup = observer((props: SubcategoriesGeneralDetailsModalProps) => {
  const { store } = props;

  const handleClose = () => store.confirmPopupStore.closeModal();

  const handleSubmit = () =>
    store.confirmPopupStore.handleSubmit(store.confirmPopupStore.dataToHandleOnSubmit);

  return (
    <Dialog
      disableEscapeKeyDown
      fullWidth
      maxWidth="sm"
      open={store.confirmPopupStore.isOpen}
      scroll="paper"
      onClose={handleClose}
      aria-labelledby="confirm-dialog"
    >
      <DialogTitle id="alert-dialog-title">{store.confirmPopupStore.titleText}</DialogTitle>
      <DialogContent>
        <Typography variant="body1">{store.confirmPopupStore.confirmText}</Typography>
      </DialogContent>
      <DialogActions>
        <Box m={1.5} width="100%">
          <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={6} sm={3}>
              <Button
                color="primary"
                onClick={handleClose}
                variant="outlined"
                fullWidth
                testId="no"
              >
                {store.confirmPopupStore.cancelButtonText}
              </Button>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Button
                color="primary"
                onClick={handleSubmit}
                variant="contained"
                fullWidth
                testId="yes"
              >
                {store.confirmPopupStore.submitButtonText}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </DialogActions>
    </Dialog>
  );
});

export default ConfirmPopup;
