import {GlobalStore,GridStore,UserStore} from '@roc/feature-app-core';
import {makeObservable,flow,observable,action} from 'mobx';
import {ProposalsService} from '../services/proposalsService';
import {FormStore} from '@roc/feature-app-core';
import {ProposalsDetailsStore} from './proposalsDetailsStore';
import {proposalStatus} from './../utils/constants';
import {DealRoomTopicCommunicationStore,CommunicationService} from '@roc/feature-communication';

const proposalTopicForm={
  fields: {
    type: {
      value: '',
      error: null,
      rule: 'required',
    },
    topic: {
      value: '',
      error: null,
      rule: 'required',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};




export class ProposalTopicsStore {
  globalStore: GlobalStore;
  proposalsService: ProposalsService;
  proposalTopicForm: FormStore;
  dealRoomTopicCommunicationStore: DealRoomTopicCommunicationStore;
  userStore: UserStore;
  proposalTopicId: Number;
  proposalTopic: any;
  constructor(globalStore,userStore) {
    this.globalStore=globalStore;
    this.proposalsService=new ProposalsService();
    this.proposalTopicForm=new FormStore(proposalTopicForm,globalStore);
    this.userStore=userStore;
    this.dealRoomTopicCommunicationStore=new DealRoomTopicCommunicationStore(this.globalStore,this.userStore,new CommunicationService());
    makeObservable(this,{
      proposalTopic: observable,
      proposalTopicId: observable,
      saveProposalTopic: flow,
    });
  }

  setProposalTopicId(proposalTopicId) {
    this.proposalTopicId = proposalTopicId;
  }

  resetForm () {
    this.proposalTopicForm.reset();
    this.proposalTopicId = null;
  }

  *saveProposalTopic() {
    try {
      if(this.isProposalTopicFormValid()) {
        const propStatus=proposalStatus.NEW;
        const body=this.proposalTopicForm.getFormValues();
        const response=yield this.proposalsService.saveProposalTopic({
          ...body,
          proposalStatus: propStatus,
        });
        this.proposalTopic=response.data?.data;
        this.setProposalTopicId(response.data?.data?.proposalTopicId);
        this.dealRoomTopicCommunicationStore.setCurrentConversationId(this.proposalTopic?.conversationSid);
        this.dealRoomTopicCommunicationStore.setModalOpen(true);
        this.globalStore.notificationStore.showSuccessNotification({
          message: `Proposal saved successfully`,
        });
      }
    } catch(error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: `Error while submitting Proposal`,
      });
    }
  }

  private isProposalTopicFormValid() {
    this.proposalTopicForm.runFormValidationWithMessage();
    return this.proposalTopicForm.form.meta.isValid;
  }

}