import { Container, Grid } from '@material-ui/core';
import {
  LoanPurpose,
  LoanRecourse,
  PurchasePrice
} from '@roc/feature-loans';
import { MIXED_USE, REFINANCE } from '@roc/feature-utils';
import { observer } from 'mobx-react';
import React from 'react';
import { SectionName } from '../components/rentalPricerComponents';
import {
  CommercialIncome,
  CommercialUnits,
  CommercialUnitsArea,
  CommercialUnitsCurrentOnRent,
  County,
  LeasesRental,
  PricerCapitalImprovements,
  PricerCurrentDebtAmount,
  PricerCurrentDebtOnProperty,
  PricerLoanPurpose,
  PricerPurchaseDate,
  PricerPurchasePrice,
  PropertyAddress,
  PropertyCounties,
  PropertyState,
  PropertyType,
  ResidentialIncome,
  ResidentialUnits,
  ResidentialUnitsArea,
  Units
} from '../components/rentalPricerFields';
import { GridContainer } from '../components/rentalPricerFieldsContext';
import { useRentalPricerStore } from '../hooks/useRentalPricerStore';
import { PropertyTypesGrid } from './components/propertyTypesGrid';

export const PropertyInformationStep = observer(() => {
  const { rentalPricerStore } = useRentalPricerStore();
  const { rentalPricerDetailsStore} = rentalPricerStore;
  const store = rentalPricerDetailsStore.termStore;
  const { fields } = store.pricerStore.form;
  const { isSingleProperty } = rentalPricerDetailsStore;

  const isMixedUse = fields.propertyType?.value === MIXED_USE;
  const isRefinance = fields.loanPurpose?.value === REFINANCE;

  const showCounty = fields?.address?.value;

  return (
    <Container maxWidth="md">
      <Grid container spacing={2}>
        <SectionName name="Property Information" />
        {isSingleProperty ? (
          <SinglePropertyFields
            store={store}
            showCounty={showCounty}
            isMixedUse={isMixedUse}
            isRefinance={isRefinance}
          />
        ) : (
          <PortfolioFields store={store} isRefinance={isRefinance} />
        )}
      </Grid>
    </Container>
  );
});

const SinglePropertyFields = ({
  store,
  showCounty,
  isMixedUse,
  isRefinance,
}) => {
  return (
    <>
      <PropertyAddress store={store} />
      <PropertyType store={store} />
      {showCounty && <County store={store} />}
      {!isMixedUse && <Units store={store} />}
      <PricerLoanPurpose store={store} />
      <PricerPurchasePrice store={store} />
      <LeasesRental store={store} />
      {isRefinance && (
        <>
          <PricerPurchaseDate store={store} />
          <PricerCapitalImprovements store={store} />
          <PricerCurrentDebtOnProperty store={store} />
          <PricerCurrentDebtAmount store={store} />
        </>
      )}
      {isMixedUse && (
        <>
          <SectionName name="Mixed use Property Information" />
          <ResidentialUnits store={store} />
          <CommercialUnits store={store} />
          <ResidentialUnitsArea store={store} />
          <CommercialUnitsArea store={store} />
          <ResidentialIncome store={store} />
          <CommercialIncome store={store} />
          <CommercialUnitsCurrentOnRent store={store} />
        </>
      )}
    </>
  );
};

const PortfolioFields = ({ store, isRefinance }) => {
  return (
    <>
      <PropertyState />
      <PropertyCounties />
      <PropertyTypesGrid />

      <GridContainer>
        <LoanPurpose store={store.pricerStore} />
      </GridContainer>
      <GridContainer>
        <PurchasePrice
          store={store.pricerStore}
          label={'Total Purchase Price'}
        />
      </GridContainer>
      <LeasesRental store={store} />
      {isRefinance && (
        <>
          <PricerPurchaseDate store={store} />
          <PricerCapitalImprovements store={store} />
          <PricerCurrentDebtOnProperty store={store} />
          <PricerCurrentDebtAmount store={store} />
        </>
      )}
      <GridContainer>
        <LoanRecourse store={store.pricerStore} />
      </GridContainer>
    </>
  );
};
