import { Box } from '@material-ui/core';
import { Button, DialogState, EditableDialog, FilterButton, Toolbar } from '@roc/ui';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useStore } from 'libs/client-portal-shared/src/app/hooks/index'
import { LendersWithNoRmGrid } from './lendersWithNoRmGrid';

export const LendersWithNoRmDashboard = observer(() => {

  const { salesforceLenderStore } = useStore();

  useEffect(() => {
    salesforceLenderStore.lenderWithNoRmGrid.resetAndFetchGridData();
  }, [salesforceLenderStore]);

  const handleToggleFilter = () => {
    salesforceLenderStore.lenderWithNoRmGrid.toggleFilters();
  };

  const handleClearFilter = () => {
    salesforceLenderStore.lenderWithNoRmGrid.resetFilters();
  };

  return (
    <LendersWithNoRmGrid
      toolbar={
        <Toolbar>
          <Box mr={2}>
            <FilterButton
              onToggleFilters={handleToggleFilter}
              onClearFilters={handleClearFilter}
            />
          </Box>
        </Toolbar>
      }
    />
  );
});