import React, { useState } from 'react';
import {
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import {
  DataGrid as DrawGrid,
  AgGridColumnProps,
  FieldLabel,
  LinkColumn,
  MenuItem,
} from '@roc/ui';
import { formatCurrency } from '@roc/ui/utils';
import { useEffect } from 'react';
import { observer } from 'mobx-react';
import { formatDate } from '@roc/feature-utils';
import LoanValuesChart from './components/loanValuesChart';
import LoanKeyDates from '../common/loanKeyDates';
import { Cancel, MoreVert } from '@material-ui/icons';
import { DRAW_REQUEST_STAGE, DRAW_INIT_STAGE } from '../constants/draws';
import { ConfirmDialog, Paper } from '@roc/ui';
import { useLoanRoutes } from '@roc/feature-loans-routes-config';
import { useDrawsStore } from '../hooks';
import { useLoanStore } from '@roc/feature-loans-shared';

const noOpComparator = () => 0;

const columns: AgGridColumnProps[] = [
  {
    field: 'drawNumber',
    headerName: 'Draw #',
    minWidth: 100,
    cellRenderer: 'drawNumberCellRenderer',
    comparator: noOpComparator,
    sortable: false,
  },
  {
    field: 'stage',
    headerName: 'Status',
    minWidth: 100,
    comparator: noOpComparator,
    sortable: false,
  },
  {
    field: 'creationDate',
    headerName: 'Draw Submission Date',
    minWidth: 100,
    cellRenderer: 'dateCellRenderer',
    comparator: noOpComparator,
    sortable: false,
  },
  {
    field: 'amount',
    headerName: 'Amount Requested',
    minWidth: 100,
    cellRenderer: 'amountRenderer',
    comparator: noOpComparator,
    sortable: false,

  },
  {
    field: 'wireSentDate',
    headerName: 'Wire Sent Date',
    minWidth: 100,
    cellRenderer: 'wireSentDateRenderer',
    comparator: noOpComparator,
    sortable: false,
  },
  {
    field: 'wireAmount',
    headerName: 'Wire Amount',
    minWidth: 100,
    cellRenderer: 'wireAmountRenderer',
    comparator: noOpComparator,
    sortable: false,
  },
  {
    field: 'actions',
    headerName: 'Actions',
    maxWidth: 100,
    cellRenderer: 'actionsCellRenderer',
    comparator: noOpComparator,
    sortable: false,
  },
];

const useStyles = makeStyles(theme => ({
  paper: {
    padding: 24,
  },
  gridContainer: {
    padding: 8,
  },
}));

export const LoanDrawsDashboardShared = observer(
  ({ loanId }: { loanId: string }) => {
    const {
      drawStore,
      drawStore: { drawList },
    } = useDrawsStore();
    const {
      loanStore: { loanDetails },
    } = useLoanStore();
    const { loanRoutesConfig } = useLoanRoutes();
    const [anchorEl, setAnchorEl] = useState<HTMLElement>(null);
    const classes = useStyles();
    const menuOpen = Boolean(anchorEl);
    const [drawId, setDrawId] = useState(null);
    const [isConfirmOpen, setIsConfirmOpen] = useState(false);

    useEffect(() => {
      drawStore.fetchDraws(loanId);
      return () => {
        drawStore.resetDraws();
      };
    }, []);

    const frameworkComponents = {
      dateCellRenderer: ({ value }) => {
        return value ? formatDate(value) : '';
      },
      drawNumberCellRenderer: params => {
        const drawId = params.node.data?.drawId;
        const { onClick } = params;
        const value = `Draw ${params.value}`;
        return LinkColumn({
          value,
          onClick,
          url: loanRoutesConfig
            .loans(params.node.data?.loanId)
            .children.drawDetails(drawId).url,
        });
      },
      amountRenderer: ({ value }) => {
        return value ? formatCurrency(value) : '';
      },
      wireAmountRenderer: ({ value }) => {
        return value && value > 0 ? formatCurrency(value) : '';
      },
      wireSentDateRenderer: params => {
        return (params.node.data?.wireAmount > 0 ? formatDate(params.node.data?.wireSentDate) : '');
      },
      actionsCellRenderer: params => {
        if (params.node.data?.stage !== DRAW_REQUEST_STAGE && params.node.data?.stage !== DRAW_INIT_STAGE) {
          return (
            <IconButton disabled>
              <MoreVert color="disabled" />
            </IconButton>
          );
        } else {
          return (
            <IconButton onClick={e => openMenu(e, params.node.data.drawId)}>
              <MoreVert color="primary" />
            </IconButton>
          );
        }
      },
    };

    const openMenu = (event: React.MouseEvent<HTMLElement>, record) => {
      setDrawId(record);
      setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
      setDrawId(null);
      setAnchorEl(null);
    };

    const cancelRequest = () => {
      drawStore.cancelDrawRequest(drawId, loanId);
      setIsConfirmOpen(false);
      handleMenuClose();
    };
    return (
      <>
        <Grid item container direction="row" spacing={3} xs={12} md={12} style={{
          margin: 0
        }}>
          <LoanValuesChart
            chRemaining={
              loanDetails?.calculatedLoanData?.constructionHoldbackRemaining
                ? loanDetails?.calculatedLoanData?.constructionHoldbackRemaining
                : 0
            }
            chReimbursedToDate={
              loanDetails?.calculatedLoanData?.constructionHoldbackFunded
                ? loanDetails?.calculatedLoanData?.constructionHoldbackFunded
                : 0
            }
          />
          <LoanKeyDates />
        </Grid>
        <Grid item xs={12} md={12} className={classes.gridContainer}>
          <Paper className={classes.paper}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <FieldLabel
                  tooltip={
                    'Click on the Draw # to see more details and to upload the documents that will allow you to complete your draw request.'
                  }
                >
                  DRAWS
                </FieldLabel>
              </Grid>
              <Grid item xs={12} md={12}>
                <DrawGrid
                  columns={columns}
                  rows={drawList}
                  frameworkComponents={frameworkComponents}
                  domLayout="autoHeight"
                />
                <Menu
                  id="menu-appbar"
                  getContentAnchorEl={null}
                  anchorEl={anchorEl}
                  keepMounted
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  open={menuOpen}
                  onClose={handleMenuClose}
                >
                  <div>
                    <MenuItem
                      testId={`cancel-draw-request-${drawId}`}
                      onClick={() => setIsConfirmOpen(true)}
                    >
                      <ListItemIcon>
                        <Cancel fontSize="small" />
                      </ListItemIcon>
                      <ListItemText>Cancel Request</ListItemText>
                    </MenuItem>
                  </div>
                </Menu>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <ConfirmDialog
          open={isConfirmOpen}
          title={`Cancel Draw Request`}
          body={`Are you sure you want to cancel the draw request?\n\nThis Operation cannot be reverted.`}
          handleCancel={() => setIsConfirmOpen(false)}
          handleClose={() => setIsConfirmOpen(false)}
          handleConfirm={cancelRequest}
          confirmButtonText={'Confirm'}
        />
      </>
    );
  }
);
