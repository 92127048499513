
const prefix = 'client.portal.'

export enum LocalStorageKeys {
  userName = 'userName',
  selectedCompanyId = 'selectedCompanyId',
  portalContextPath = 'portalContextPath',
  refreshTokenExpiresAt = 'refreshTokenExpiresAt',
  fcmToken = 'fcmToken',
  darkMode = 'darkMode',
  loanChat_portalConfigurationHost = 'loanChat_portalConfigurationHost',
  portalId = 'portalId',
}

const setValue = (key: LocalStorageKeys, value: any) => {
  window.localStorage.setItem(`${prefix}${key}`, value);
};
const getValue = (key: LocalStorageKeys) => {
  return window.localStorage.getItem(`${prefix}${key}`);
};
const removeValue = (key: LocalStorageKeys) => {
  return window.localStorage.removeItem(`${prefix}${key}`);
};

export const appLocalStorage = {
  setValue,
  getValue,
  removeValue
};
