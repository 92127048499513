import { ApiResponse, GlobalStore } from '@roc/feature-app-core';
import { action, makeObservable, observable } from 'mobx';
import { GridStore } from '@roc/feature-app-core';
import { BrokerService } from '../services/brokerService';
import { Broker } from '../types/brokerTypes';
import { BrokersStore } from './brokersStore';

export class BrokerContactsSummaryGridStore extends GridStore {
  private globalStore: GlobalStore;
  brokerService: BrokerService;
  brokerId: number;

  selected: Broker[];

  constructor(brokersStore: BrokersStore, globalStore: GlobalStore) {
    super(() => this.fetchBrokerContacts());
    this.globalStore = globalStore;
    this.brokerService = brokersStore.brokerService;

    makeObservable(this, {
      brokerId: observable,
      setBrokerId: action
    });
  }

  setBrokerId(brokerId: number) {
    this.brokerId = brokerId;
  }

  async fetchBrokerContacts() {
    try {
      const response: ApiResponse = await this.brokerService.getBrokerContactsForBroker(
        this.gridData.meta.pageNumber,
        this.gridData.meta.pageSize,
        this.gridData.meta.sortDir,
        this.gridData.meta.sortBy,
        this.gridData.meta.filters,
        this.brokerId
      );
      return response;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching broker contacts',
      });
    }
  }
}
