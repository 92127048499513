import { Grid } from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { useBrokersStore } from '../hooks/useBrokersStore';
import {
  EmailAddress,
  FirstName,
  LastName,
  EntityName,
  EntityTaxNumber,
  PhoneNumber
} from './components/brokerFormFields';
import { useStore } from '@roc/client-portal-shared/hooks';

export const LoanBrokerForm = observer(() => {
  const { brokersStore } = useBrokersStore();
  const { globalStore } = useStore();
  const { brokerFormStore: store } = brokersStore;

  return (
    <Grid container spacing={2}>
      <EntityName store={store} />
      <EntityTaxNumber store={store} />
      <FirstName store={store} />
      <LastName store={store} />
      <EmailAddress store={store} />
      <PhoneNumber store={store} />
    </Grid>
  );
});
