import { Grid } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { StandardDialog } from '@roc/ui';
import { observer } from 'mobx-react';
import React from 'react';
import { useDocumentStore } from '../../../hooks/useDocumentStore';

export const BorrowerEntityWarnings = observer(props => {
  const { documentStore } = useDocumentStore();
  const { documentFormStore } = documentStore;
  const { borrowerEntityStore } = documentFormStore;

  const open =
    borrowerEntityStore.showCertificateOfGoodStandingWarning ||
    borrowerEntityStore.showForeignEntityStatusWarning;

  const dialogContent = (
    <Grid container spacing={4}>
      {borrowerEntityStore.showCertificateOfGoodStandingWarning && (
        <Grid item xs={12}>
          <Alert severity="warning">
            <AlertTitle>Warning</AlertTitle>
            Certificate of Good Standing is needed within 90 days of closing.
            These can be ordered on the applicable county/state website
          </Alert>
        </Grid>
      )}
      {borrowerEntityStore.showForeignEntityStatusWarning && (
        <Grid item xs={12}>
          <Alert severity="warning">
            <AlertTitle>Warning</AlertTitle>
            The borrower must obtain a foreign entity status in the state of the
            collateral. These can be ordered on the applicable county/state
            website
          </Alert>
        </Grid>
      )}
    </Grid>
  );

  return (
    <StandardDialog
      open={open}
      title={'Warnings'}
      maxWidth="sm"
      handleClose={() => borrowerEntityStore.hideWarnings()}
      dialogContent={dialogContent}
    />
  );
});

export default BorrowerEntityWarnings;
