import React from 'react';
import { Grid } from '@material-ui/core';
import { Button } from '@roc/ui';
import { observer } from 'mobx-react';
import { AppraisalDetailsForm, BorrowerForm, PropertyForm } from './components';

export const SubmitAppraisalForm = observer(({ store }) => {
  const { isValidPropertyType } = store;

  const createOrder = () => {
    store.submitAppraisal();
  };

  return (
    <Grid container>
      <Grid container item>
        <Grid item container xs={12} spacing={4}>
          <PropertyForm submitAppraisalStore={store} />
          {isValidPropertyType && (
            <>
              <AppraisalDetailsForm submitAppraisalStore={store} />
              <BorrowerForm submitAppraisalStore={store} />
            </>
          )}
          <Grid item container justifyContent="flex-end">
            <Button
              variant="contained"
              type="submit"
              color="primary"
              onClick={createOrder}
              testId="createOrder"
            >
              Create Order
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
});
