import { Box } from '@material-ui/core';
import { observer } from 'mobx-react';
import { useNewLoanApplicationStore } from '../hooks/useNewLoanApplicationStore';
import { LoanApplicationStep } from '../utils/loanApplicationConstants';
import {
  LoanStep,
  LoanStepButton
} from './loanApplicationComponents';
import { PropertiesCard } from './propertiesCard';
import { PropertyInformationModal } from './propertyInformationModal';

export const PropertyInformationStep = observer(() => {
  const { newLoanApplicationStore } = useNewLoanApplicationStore();
  const { propertyStore } = newLoanApplicationStore;
  const { properties } = propertyStore;

  return (
    <LoanStep
      percentageComplete={10}
      title={'Property Information'}
      subtitle={
        'Please enter the property information for this loan application.'
      }
      disableContinue={properties.length === 0}
      onBack={() =>
        newLoanApplicationStore.goToStep(LoanApplicationStep.ENTITY_INFORMATION)
      }
      onContinue={() =>
        newLoanApplicationStore.goToStep(LoanApplicationStep.SUMMARY)
      }
    >
        <Box py={2}>
          <PropertiesCard />
        </Box>
      <PropertyInformationModal />
    </LoanStep>
  );
});
