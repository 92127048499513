export const PAYOFF_REQUESTED_STAGE = 'Payoff Requested';
export const FORWARDED_STAGE = 'Forwarded to Servicer';
export const APPROVAL_PENDING_STAGE = 'Approval & Forwarding';
export const APPROVED_STAGE = 'Approved';
export const RETRACTED_STAGE = 'Retracted';
export const PAYOFF_PROCESS = 'Payoffs';

export const servicerFeesOptions = [
  { label: 'Satisfaction Of Mortgage Fee', value: 'Satisfaction Of Mortgage Fee' },
  { label: 'Forwarding fee', value: 'Forwarding fee' },
  { label: 'Recording Fee', value: 'Recording Fee' },
  { label: 'Recording Fee - Building', value: 'Recording Fee - Building' },
  { label: 'Assignment of Rents & Leases', value: 'Assignment of Rents & Leases' },
  { label: 'Assignment of Contracts', value: 'Assignment of Contracts' },
  { label: 'UCC Fees', value: 'UCC Fees' },
  { label: 'Affordable Housing Docs', value: 'Affordable Housing Docs' },
  { label: 'Building Loan Agreement', value: 'Building Loan Agreement' },
  { label: 'VOM Fees', value: 'VOM Fees' },
  { label: 'Wire Fees', value: 'Wire Fees' },
  { label: 'NSF Charges', value: 'NSF Charges' },
  { label: 'ACH Fee', value: 'ACH Fee' },
  { label: 'Uncleared Loan Payment', value: 'Uncleared Loan Payment' },
  { label: 'Payoff Demand Fee / Rush fee', value: 'Payoff Demand Fee / Rush fee' },
  { label: 'Borrower Paid Already', value: 'Borrower Paid Already' },
  { label: 'Other fees', value: 'Other fees' },
];