import { Grid, Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useStore } from '@roc/client-portal-shared/hooks';
import { observer } from 'mobx-react';

const useStyles = makeStyles((theme: Theme) => {
  console.log({ theme });
  return {
    paper: {
      margin: theme.spacing(4),
      overflow: 'hidden',
    },
    message: {
      padding: theme.spacing(8, 6),
      textAlign: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
    },
    hello: {
      ...theme.typography.h4,
    },
    text: {
      padding: theme.spacing(8),
      [theme.breakpoints.down('md')]:{
        padding: theme.spacing(4, 0)
      }
    },
  };
});

export const UnsubscribedFromReminders = observer(() => {
  const { globalStore } = useStore();
  const classes = useStyles();
  return (
    <Paper className={classes.paper}>
      <Grid container>
        <Grid item md={12} lg={12} className={classes.message}>
          <Typography className={classes.hello}>
            Hello! <br />
            You have been successfully unsubscribed from these reminders.
          </Typography>
           <Typography className={classes.text}>
            You can now close this window.<br />
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
});
