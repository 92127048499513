import { Box, TextField, Theme, Typography, Tooltip, Button } from "@material-ui/core";
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Autocomplete } from "@material-ui/lab";
import { useState } from "react";
import { useParams } from "react-router";
import { useForeclosureSummaryStore } from "../../hooks/useForeclosureSummaryStore";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    defaultLabel: {
      fontFamily: 'Oswald, sans-serif;',
    },
    boldLabel: {
      fontFamily: 'Oswald, sans-serif;',
      fontWeight: 'bold'
    },
    reasonCodeChip: {
      borderRadius: 100,
      paddingInline: 8,
      paddingBlock: 18
    },
    reasonCodeBtn: {
      borderRadius: 100,
      minWidth: 150
    },
    reasonCodeBox: {
      marginRight: 36,
      columnGap: 12,
      paddingLeft: 8,
    },
    tooltip: {
      backgroundColor: theme.palette.primary.light,
      padding: theme.spacing(1)
    },
  }),
);

export const FORECLOSURE_REASONS = {
  BRIDGE: [
    "Overestimated ARV",
    "Liquidity",
    "Scope of Work",
    "Permit Delays",
    "Over Levered",
    "Personal",
    "Macro Factors",
    "HPA Trends",
    "Natural Disaster",
    "Partner Dispute",
    "Legal Issues",
    "Squatters",
    "Inability to Refinance"
  ],
  RENTAL: [
    "Loss of Rental Income",
    "Inability to Rent",
    "Overestimated Rent",
    "Increase in Property Costs",
    "Over Levered",
    "Personal",
    "Macro Factors",
    "Natural Disaster",
    "Partner Dispute",
    "Legal Issues",
    "Squatters",
    "Inability to Refinance",
  ]
}

const getForeclosureReasonOptions = (loanType) => {
  return loanType === 'Residential Bridge' ?
    FORECLOSURE_REASONS.BRIDGE :
    FORECLOSURE_REASONS.RENTAL
}

export const UpdateForeclosureReasonPopup = ({ value, onChange, loanType, onClose }) => {

  const { loanId } = useParams<{ loanId: string }>();
  const { foreclosureSummaryStore } = useForeclosureSummaryStore();

  const [inputValue, setInputValue] = useState(null);

  const removeAddPrefix = (input: string) => {
    return input.replace(/^Add\s+/i, '');
  }
  const onInputChange = (_, newInputValue) => {
    setInputValue(newInputValue);
  }

  const onForeclosureReasonUpdate = () => {
    foreclosureSummaryStore.saveForeclosureReason(
      {
        loanId: Number(loanId),
        delinquencyStatusComment: value.join(',')
      }
    )
    onClose();
  }

  const filterOptions = (options, { inputValue }) => {
    const filtered = options.filter(option =>
      option.toLowerCase().includes(inputValue.toLowerCase())
    );
    if (inputValue !== '') {
      filtered.push(`Add ${inputValue}`);
    }

    return filtered;
  }

  return (
    <Box display='flex' flexDirection='column' gridGap='12px'>
      <Autocomplete
        multiple={true}
        filterSelectedOptions
        disableCloseOnSelect
        limitTags={2}
        value={value}
        filterOptions={filterOptions}
        onChange={(event, newValue: string[]) => {
          onChange(event, newValue.map((reason: string) => removeAddPrefix(reason)))
        }}
        inputValue={inputValue}
        onInputChange={onInputChange}
        options={getForeclosureReasonOptions(loanType)}
        renderInput={(params) => <TextField variant="outlined" {...params} />}
      />
      <Button
        color='primary'
        variant='contained'
        disabled={!value.length}
        onClick={onForeclosureReasonUpdate}
      >
        Update
      </Button>
    </Box>
  );
}

export const ForeclosureReasonStatus = ({ foreclosureReason, handleReasonCodesModalOpen }) => {

  const classes = useStyles()
  const reasons = foreclosureReason?.split(",") || []

  return (
    <Box display='flex' alignItems='center' className={classes.reasonCodeBox}>
      <Typography variant='h6' className={classes.boldLabel}>Foreclosure Reason:</Typography>
      <Tooltip
        title={reasons.map(reason => <Typography variant="subtitle1">{reason}</Typography>)}
        placement="bottom"
        disableHoverListener={!!!reasons[1]}
      >
        <Button
          variant="contained"
          size="small"
          color="primary"
          onClick={handleReasonCodesModalOpen}
          className={classes.reasonCodeBtn}
        >
          <Typography variant='button' className={classes.defaultLabel}>
            {reasons[0] || 'Not Provided'}
          </Typography>
          {!!reasons[1] &&
            <Typography variant='button' className={classes.defaultLabel}>
              {`, +${reasons.length - 1}`}
            </Typography>
          }
        </Button>

      </Tooltip>
    </Box>
  )
}