export enum BrokerFeeType {
  CURRENCY,
  PERCENTAGE,
}

export const brokerFeeNames = [
  {
    label: 'Yield Spread Premium',
    attribute: 'yieldSpreadPremium',
    type: BrokerFeeType.PERCENTAGE,
    disableForBridge: true,
    valueTerm: 0.25
  },
  {
    label: 'Rate Floor',
    attribute: 'rateFloor',
    type: BrokerFeeType.PERCENTAGE,
    disableForTerm: true,
    valueBridge: 10.24
  },
  {
    label: 'Origination Points',
    attribute: 'originationPoints',
    type: BrokerFeeType.PERCENTAGE,
    valueBridge: 2,
    valueTerm: 2
  },
  {
    label: 'Processing Fee',
    attribute: 'processingFee',
    type: BrokerFeeType.CURRENCY,
    valueBridge: 0,
    valueTerm: 0
  },
  {
    label: 'Admin Fee', attribute: 'adminFee', type: BrokerFeeType.CURRENCY, valueBridge: 0,
    valueTerm: 0
  },
  {
    label: 'Underwriting Fee',
    attribute: 'underwritingFee',
    type: BrokerFeeType.CURRENCY,
    valueBridge: 0,
    valueTerm: 0
  },
  {
    label: 'Commitment Fee',
    attribute: 'commitmentFee',
    type: BrokerFeeType.CURRENCY,
    valueBridge: 0,
    valueTerm: 0
  },
];
