import { Button } from '@roc/ui';
import { observer } from 'mobx-react';
import { routeByLoanSubType, useLoanStore, useLoanSubmissionRoutes } from '@roc/feature-loans';
import { DraftLoan } from '@roc/feature-types';
import { useHistory } from 'react-router';
import { LoanSubType } from '@roc/feature-utils';

export const LoanApplicationDetailsCardActionsButton = observer(() => {
  const { loanStore } = useLoanStore();
  const { loanDetails } = loanStore;
  const loanId = loanDetails?.loanId;
  const { push } = useHistory();
  const { loanSubmissionRoutesConfig } = useLoanSubmissionRoutes();

  const mapDraftLoan = async () => {
    loanStore.loanDetails = {
      ...loanDetails,
      loanApplicationId: Number(loanId)
    }
  }

  return (
    <Button
      size="small"
      variant="contained"
      color="primary"
      onClick={() => {
        mapDraftLoan()
        loanStore.continueDraftLoan(loanStore.loanDetails as DraftLoan);
        push(
          loanSubmissionRoutesConfig.submitloan.children[
            routeByLoanSubType[loanDetails?.loanSubType]
          ].url
        );
      }}
      testId={`continue-loan-application-${loanId}`}
    >
      Continue Submission
    </Button >
  );
});
