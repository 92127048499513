import { Box, Typography } from "@material-ui/core";
import { Card } from "@roc/ui";
import { makeStyles } from '@material-ui/core/styles';
import { IconButton } from '@roc/ui';
import { Edit, Delete } from '@material-ui/icons';
import { DateFormat, formatDate } from '@roc/client-portal-shared/utils';

const useStyles = makeStyles(theme => ({
  noteCard: {
    borderRadius: 0,
    boxShadow: '0px 2px 3px #EEE'
  },
  noteHeader: {
    padding: '8px 16px',
    paddingBottom: '2px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    backgroundColor: '#edebeb',
  },
  noteTime: {
    display: 'block',
    textAlign: 'right',
  }
}));

export const Note = ({ title, onEdit, onDelete, children, note }) => {
  const classes = useStyles();
  const action = (
    <>
      <IconButton testId="edit" onClick={onEdit}>
        <Edit />
      </IconButton>
      <IconButton testId="delete" onClick={onDelete}>
        <Delete />
      </IconButton>
    </>
  );

  return (
    <Card
      title={title}
      className={classes.noteCard}
      titleTypographyProps={{ variant: 'h6' }}
      cardHeaderProps={{
        action,
        className: classes.noteHeader,
      }}
      cardContentProps={{ style: { padding: '8px 16px' } }}
    >
      {children}
      {(note.createdDate || note.lastUpdatedDate) ? (
        <Box pt={2}>
          <Typography variant="subtitle2" className={classes.noteTime}>
            {
              <>
                Last updated by {note.lastUpdatedBy ? note.lastUpdatedBy : note.createdBy} at {formatDate(note.lastUpdatedDate ? note.lastUpdatedDate : note.createdDate, DateFormat.MMDDYYYY)}
              </>
            }
          </Typography>
        </Box>
      ) : null}
    </Card>
  );
}