import { Service } from "@roc/feature-app-core";

const url = {
  GET_EMBEDED_URL: '/api/v1/user/getEmbedUrl?menuId=',
};

export class SigmaDashboardsService extends Service {
  getEmbededUrl(menuId: string) {
    return this.get(`${url.GET_EMBEDED_URL}${menuId}`);
  }
}