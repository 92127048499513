import React, { useState } from 'react';
import {
  Grid,
  Typography,

  Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import ForeclosureLoansForPayoffRequestGrid from './foreclosureLoansForPayoffRequestGrid';

const useStyles = makeStyles((theme) =>
  createStyles({
    buttonContainer: {
      marginTop: 32,
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
    },
    gridContainer: {
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
      height: '50vh',
      overflow: 'hidden',
    },
  })
);

export const ForeclosureLoansForPayoffRequestForm = ({ onClose, onSubmit }) => {
  const classes = useStyles();
  const [selectedLoan, setSelectedLoan] = useState(null);

  return (
    <Grid container spacing={2} className={classes.container}>
      <Grid item>
        <Typography variant="body2">
          Please select a foreclosure loan for the Payoff Request.
        </Typography>
      </Grid>
      <Grid item className={classes.gridContainer}>
        <ForeclosureLoansForPayoffRequestGrid onSelected={(loan) => setSelectedLoan(loan)} />
      </Grid>
      <Grid item xs={12} className={classes.buttonContainer}>
        <Grid container justifyContent="center" spacing={2}>
          <Grid item xs={6} md={2}>
            <Button
              onClick={onClose}
              color="primary"
              size="large"
              variant="outlined"
              fullWidth
            >
              CANCEL
            </Button>
          </Grid>
          <Grid item xs={6} md={2}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              onClick={() => onSubmit(selectedLoan)}
              disabled={!selectedLoan}
            >
              SUBMIT
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
};

export default ForeclosureLoansForPayoffRequestForm;