export const ACCEPTED = 'Accepted';
export const BEING_REVIEWED = 'Being Reviewed';
export const NOT_APPLICABLE = 'Not Applicable';

export const DISABLE_STATUS_LIST = [ACCEPTED, BEING_REVIEWED, NOT_APPLICABLE];

export const yesNoRadioOptions = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

export const propertyTypes = [
  { label: 'Single Family', value: 'Single Family' },
  { label: 'Duplex', value: 'Duplex' },
  { label: 'Triplex', value: 'Triplex' },
  { label: 'Quadruplex', value: 'Quadruplex' },
  { label: 'Condo', value: 'Condominium' },
];

export const renovationDescriptions = [
  { value: 'Cleanout', label: 'Cleanout' },
  { value: 'Light and cosmetic', label: 'Light and cosmetic' },
  { value: 'Moderate rehab', label: 'Moderate rehab' },
  { value: 'Gut rehab', label: 'Gut rehab' },
  { value: 'Adding dormers', label: 'Adding Dormers' },
  { value: 'Horizontal Enlargement', label: 'Horizontal Enlargement' },
  {
    value: 'Vertical enlargement',
    label: 'Vertical enlargement (adding a floor)',
  },
  { value: 'New Construction', label: 'New Construction' },
  {
    value: 'Tear down and rebuild',
    label: 'Tear down and rebuild (new construction)',
  },
  { value: 'Vacant Lot', label: 'Vacant Lot (new construction)' },
  { value: 'Condo conversion', label: 'Condo conversion' },
  { value: 'Structural Improvements', label: 'Structural Improvements' },
  { value: 'No Renovation planned', label: 'No Renovation planned' },
];

export const defaultFieldValue = {
  amount: null,
  description: null,
  dollarCompletion: null,
  dollarRemaining: null,
  howMany: null,
  percentageCompletion: null,
};

export const scopeOfWorkV2Description = {
  'Soft Costs': 'Permits, Approvals.',
  Demolition: 'Demolition, Dumpster, Trash Removal, Halmat',
  Substructure:
    'All Below-Grade Construction Including Footings, Piles, Foundations, Slabs, Underpinning & Related Activities.',
  'Exterior/Shell':
    'All Above Grade Superstructure including Framing of Exterior Walls, Lowest Floor, Roofs, Decks, Soffits, Insulation, Weather Barriers, Wall and Ceiling Interior Finishes, Windows, Doors, Vents, Gutters, Etc.',
  Interiors:
    'Interior Partition Construction Including Framing, Wallboard, Interior Doors, Finishes including Wood, Tiles, Terrazzo, Carpet, Plaster, Plant, Bathroom Accessories, Fireplaces, Closet Interiors.',
  Services:
    'Plumbing, Mechanical, Electrical, Conveying, Fire Protection, Boilers, HW Heaters, Furnaces, Pumps, Compressor, Fan Coils, Etc.',
  'Appliances, Cabinetry & Millwork, Countertops':
    'Appliances, Cabinetry & Millwork, Countertops',
  'Special Construction': 'Swimming Pools, Spas',
  'Site Work':
    'Grading, Earthwork, Tree/Vegetation Removal, Replacement, Driveways, Fences, Wells & Septic.',
};

export enum CALLBACK_TYPE {
  GET_SHARE_URL,
  GET,
  SAVE,
  SUBMIT,
}
