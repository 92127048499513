import { Divider, Grid, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Button, FileUploadArea, createSelectField, createTextField } from '@roc/ui';
import { stateOptions } from 'libs/client-portal-shared/src/app/modules/bridgePricer/utils/bridgePricerConstants';

const useStyles = makeStyles((theme: Theme) => {
  return {
    button: {
      marginRight: theme.spacing(1)
    }
  };
});

export const TrackRecordEntityForm = observer(({ store }) => {
  const classes = useStyles();
  const formStore = store.trackRecordEntityFormStore;

  return (
    <Grid container direction='row' spacing={2}>
      <Grid item xs={12}>
        <Typography variant='h5'>{`Entity #${store.currentEntityIndex}`}</Typography>
      </Grid>
      <Grid item xs={12} container direction='row' spacing={1}>
        <Grid item xs={9}>
          {createTextField({
            store: formStore,
            testId: 'entityName',
            fieldName: 'entityName',
            label: 'Entity Name',
          })}
        </Grid>
        <Grid item xs={3}>
          {createSelectField({
            store: formStore,
            testId: 'entityState',
            fieldName: 'entityState',
            label: 'Entity State',
            options: stateOptions,
          })}
        </Grid>
        <Grid item xs={12}>
          <FileUploadArea
            items={
              store.currentDocumentsToUpload
            }
            setItems={(updateFunc) => {
              const updatedItems = updateFunc(store.currentDocumentsToUpload);
              store.currentDocumentsToUpload = updatedItems;
            }}
            multiple
          />
        </Grid>
      </Grid>
      <Grid container justifyContent='flex-end'>
        <Button
          variant='outlined'
          color='primary'
          onClick={() => { store.resetStore() }}
          testId='removeProperty'
          className={classes.button}
        >
          Cancel
        </Button>
        <Button
          variant='outlined'
          color='primary'
          onClick={() => { store.removeTrackRecordEntity() }}
          testId='removeEntity'
          className={classes.button}
        >
          Remove
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => { store.saveTrackRecordEntity() }}
          testId='saveEntity'
          className={classes.button}
        >
          Save
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Divider variant="fullWidth" />
      </Grid>
    </Grid>
  );
});