import { Grid } from "@material-ui/core";
import { observer } from "mobx-react";
import { CDAPayment } from "./CDAPayment";
import { ConfirmCloseButtons } from "./confirmCloseComponent";


export const CdaArrPaymentLink = observer(({ url, borrowersEmail, loanId, cdaEnabled, isInternal }) => {
  return (
    <>
      <Grid container xs={12} justifyContent="center">
        <CDAPayment url={url} borrowersEmail={borrowersEmail} loanId={loanId} isInternal={isInternal} cdaEnabled={cdaEnabled} />
      </Grid>
    </>
  )
});