import { Box } from '@material-ui/core';
import {
  Button,
  DialogState,
  EditableDialog,
  StandardDialog,
  Tabs,
} from '@roc/ui';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { BrokerForm } from '../brokerForm/brokerForm';
import { useBrokersStore } from '../hooks/useBrokersStore';
import { Broker } from '../types/brokerTypes';
import { BrokerFees } from './brokerFees';

const TABS = [
  {
    label: 'Broker Information',
  },
  {
    label: 'Default Fees',
  },
];

export const BrokerDialog = observer(({ canEditBroker, canEditFees }) => {
  const { brokersStore } = useBrokersStore();
  const {
    selectBrokersStore,
    manageBrokersStore,
    brokersGridStore,
    brokerFeesStore,
  } = brokersStore;
  const { editDialogState } = selectBrokersStore;
  const open = editDialogState !== null;
  const brokers: Broker[] = brokersGridStore.gridData.data.rows;
  const showDefaultFees = editDialogState == DialogState.EDIT;
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    setTabIndex(0);
  }, [open]);

  const close = () => {
    selectBrokersStore.closeEditBrokerDialog();
  };

  const dialogContent = showDefaultFees ? (
    <>
      <Tabs
        tabs={TABS}
        selectedTab={tabIndex}
        onTabChange={(_, i) => setTabIndex(i)}
      />
      <Box pt={2}>
        {tabIndex === 0 && <BrokerForm readOnly={!canEditBroker} brokers={brokers} />}
        {tabIndex === 1 && <BrokerFees />}
      </Box>
    </>
  ) : (
    <BrokerForm brokers={brokers} />
  );

  return (
    <StandardDialog
      open={editDialogState !== null}
      title={editDialogState == DialogState.ADD ? `Add Broker` : `Edit Broker`}
      handleClose={close}
      dialogContent={dialogContent}
      dialogActions={
        <>
          <Button
            style={{ marginRight: '8px' }}
            onClick={close}
            color="primary"
            variant="outlined"
            testId="cancel"
          >
            Cancel
          </Button>
          {tabIndex == 0 && (
            <Button
              onClick={() => manageBrokersStore.saveBroker()}
              color="primary"
              variant="contained"
              testId="save"
              disabled={!canEditBroker}
            >
              {editDialogState == DialogState.ADD ? 'Add' : 'Apply Changes'}
            </Button>
          )}

          {tabIndex == 1 && (
            <Button
              onClick={() => brokerFeesStore.saveBrokerFees()}
              color="primary"
              variant="contained"
              testId="save"
              disabled={!canEditFees}
            >
              {'Save Fees'}
            </Button>
          )}
        </>
      }
    />
  );
});
