import { StandardDialog } from '@roc/ui';
import { observer } from 'mobx-react';
import { InsuranceApproval } from './insuranceApproval';
import { Document } from '@roc/feature-types';
import { makeStyles } from '@material-ui/core/styles';

export type InsuranceApprovalDialogProps = {
  open?: boolean;
  onClose?: () => void;
  document: Document;
};

const useStyles = makeStyles(theme => ({
  dialogPadding: {
    '& .MuiDialogContent-root': {
      padding: '0 24px',
    },
    '& .MuiDialogTitle-root': {
      paddingBottom: 0
    }
  }
}));

export const InsuranceApprovalDialog = observer(
  (props: InsuranceApprovalDialogProps) => {
    const { open, onClose } = props;
    const classes = useStyles();
    return (
      <StandardDialog
        className={classes.dialogPadding}
        open={open}
        title="Insurance Summary"
        maxWidth="lg"
        handleClose={onClose}
        dialogContent={<InsuranceApproval />}
      />
    );
  }
);

export default InsuranceApprovalDialog;
