import { action, flow, makeObservable, observable } from 'mobx';
import { PortalConfiguration } from '@roc/feature-types';
import { GlobalStore, ApiResponse, PortalConfigurationService } from "@roc/feature-app-core";
import { LeadDetailsStore } from "../leadDetails/leadDetailsStore";
import { SendEmailsStore } from "@roc/feature-sendgrid";
import { NewLeadService } from '@roc/feature-new-lead';
import { CreateListStore } from 'apps/crm-portal/src/app/modules/createList/stores/createListStore';
import { EditLeadDetailsFormStore } from 'libs/client-portal-shared/src/app/modules/companyProfile/stores/editLeadDetailsFormStore'; //TODO: fix import
import { RingCentralStore } from 'libs/client-portal-shared/src/app/modules/companyProfile/stores/ringCentralStore'; //TODO: fix import

export class ContactDetailsStore {
  private globalStore: GlobalStore;
  public editLeadDetailsFormStore: EditLeadDetailsFormStore;
  public leadDetailsStore: LeadDetailsStore;
  public sendEmailsStore: SendEmailsStore;
  public ringCentralStore: RingCentralStore;
  public createListStore: CreateListStore;
  protected newLeadService: NewLeadService;
  protected portalConfigurationService: PortalConfigurationService;

  public contactDetails: any;
  public companyContactDetails: any;

  constructor(globalStore: GlobalStore) {
    this.globalStore = globalStore;
    this.leadDetailsStore = new LeadDetailsStore(this.globalStore);
    this.editLeadDetailsFormStore = new EditLeadDetailsFormStore(this.globalStore);
    this.sendEmailsStore = new SendEmailsStore(this.globalStore);
    this.ringCentralStore = new RingCentralStore(this.globalStore);
    this.createListStore = new CreateListStore(this.globalStore);
    this.newLeadService = new NewLeadService();
    this.portalConfigurationService = new PortalConfigurationService();

    this.setDefaults();
    makeObservable(this, {
      contactDetails: observable,
      companyContactDetails: observable,
      getContactDetails: flow,
      getCompanyContactDetails: flow,
      editContactDetails: flow,
      getHostByPortaId: flow,
      getLeadForEmailPreview: action,
    });
  };

  private setDefaults() {
    this.contactDetails = null;
  };

  private handlePhoneNumber(phone) {
    if (phone) {
      const numericPhoneNumber = phone.replace(/\D/g, '');
      let phoneNumber = parseInt(numericPhoneNumber);
      return phoneNumber;
    }
    return null;
  };

  private fillLeadDetailsForm() {
    this.editLeadDetailsFormStore.onFieldChange(
      'firstName',
      this.contactDetails.firstName
    );
    this.editLeadDetailsFormStore.onFieldChange(
      'lastName',
      this.contactDetails.lastName
    );
    this.editLeadDetailsFormStore.onFieldChange(
      'phoneNumber',
      this.handlePhoneNumber(this.contactDetails.phoneNumber)
    );
    this.editLeadDetailsFormStore.onFieldChange(
      'email',
      this.contactDetails.email
    );
    this.editLeadDetailsFormStore.onFieldChange(
      'doNotCall',
      this.contactDetails.doNotCall === true
    );
    this.editLeadDetailsFormStore.onFieldChange(
      'doNotSendEmail',
      this.contactDetails.doNotSendEmail === true
    );
    this.editLeadDetailsFormStore.onFieldChange(
      'doNotSms',
      this.contactDetails.doNotSms === true
    );
    this.editLeadDetailsFormStore.onFieldChange(
      'doNotMail',
      this.contactDetails.doNotMail === true
    );
    this.editLeadDetailsFormStore.onFieldChange(
      'secondaryPhone',
      this.contactDetails.secondaryPhone
    );
    this.editLeadDetailsFormStore.onFieldChange(
      'secondaryEmail',
      this.contactDetails.secondaryEmail
    );
    this.editLeadDetailsFormStore.onFieldChange(
      'brokered',
      this.contactDetails.brokered === true
    );
    //Placeholders
    this.editLeadDetailsFormStore.onFieldChange(
      'leadSource',
      'placeholder'
    );
    this.editLeadDetailsFormStore.onFieldChange(
      'recordType',
      'placeholder'
    );
    this.editLeadDetailsFormStore.onFieldChange(
      'status',
      'placeholder'
    );
    this.editLeadDetailsFormStore.onFieldChange(
      'keyContact',
      this.contactDetails.keyContact === true
    );
  };

  getLeadForEmailPreview() {
    return this.contactDetails;
  }

  *getContactDetails(contactId: string, companyContactId: number) {
    try {
      const response: ApiResponse = yield this.newLeadService.getLeadContactDetails(
        contactId, companyContactId
      );
      this.contactDetails = response.data?.data;
      this.fillLeadDetailsForm();
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'An error occurred while fetching contact details.',
      });
    }
  };

  *editContactDetails(contactId: string) {
    try {
      const requestBody = {
        id: contactId,
        firstName: this.editLeadDetailsFormStore.form.fields.firstName.value,
        lastName: this.editLeadDetailsFormStore.form.fields.lastName.value,
        phoneNumber: this.editLeadDetailsFormStore.form.fields.phoneNumber.value,
        email: this.editLeadDetailsFormStore.form.fields.email.value,
        secondaryPhone: this.editLeadDetailsFormStore.form.fields.secondaryPhone.value,
        secondaryEmail: this.editLeadDetailsFormStore.form.fields.secondaryEmail.value,
        doNotCall: this.editLeadDetailsFormStore.form.fields.doNotCall.value,
        doNotSendEmail: this.editLeadDetailsFormStore.form.fields.doNotSendEmail.value,
        doNotSms: this.editLeadDetailsFormStore.form.fields.doNotSms.value,
        doNotMail: this.editLeadDetailsFormStore.form.fields.doNotMail.value,
        brokered: this.editLeadDetailsFormStore.form.fields.brokered.value,
        keyContact: this.editLeadDetailsFormStore.form.fields.keyContact.value,
        companyContactId: this.companyContactDetails?.companyContactId,
      };
      yield this.newLeadService.updateLeadContactDetails(requestBody);
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Updated contact details successfully',
      });
    } catch (error) {
      console.log(error);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'An error occurred while updating contact details',
      });
    }
  };

  *getCompanyContactDetails(companyContactId: number | string) {
    try {
      const response: ApiResponse = yield this.newLeadService.getCompaniesContacts(
        companyContactId
      );
      this.companyContactDetails = response.data?.data;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'An error occurred while fetching company contact details.',
      });
    }
  };

  *getHostByPortaId(portalId: string | number) {
    const apiResponse: ApiResponse = yield this.portalConfigurationService.getPortalConfigurationById(portalId);
    const portalConfigurationResponse: PortalConfiguration = apiResponse.data.data;
    return portalConfigurationResponse.host;
  }

  reset() {
    this.editLeadDetailsFormStore.reset();
    this.contactDetails = null;
    this.companyContactDetails = null;
    this.leadDetailsStore.reset();
  }

  getCurrentContactDetails() {
    return this.companyContactDetails ?? this.contactDetails;
  }
}