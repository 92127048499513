import { action, makeObservable, observable } from 'mobx';
import { OptionsObject, SnackbarMessage } from 'notistack';

export enum NotificationType {
  default = 'default',
  success = 'success',
  error = 'error',
  warning = 'warning',
  info = 'info',
}
interface Notifications {
  message: SnackbarMessage;
  options?: OptionsObject;
}

export class NotificationStore {
  notifications: Notifications[];
  dismissAllNotificationsFlag: boolean;

  constructor() {
    this.notifications = [];
    makeObservable(this, {
      notifications: observable,
      dismissAllNotificationsFlag: observable,
      hideNotification: action,
      setDismissAllNotificationsFlag: action,
      clearAllNotifications: action,
      showDefaultNotification: action,
      showSuccessNotification: action,
      showErrorNotification: action,
      showWarningNotification: action,
      showInfoNotification: action,
    });
  }

  setDismissAllNotificationsFlag(flag: boolean) {
    this.dismissAllNotificationsFlag = flag;
  }

  private enqueueSnackbar({ message, options }: Notifications) {
    this.notifications.push({
      options: {
        key: new Date().getTime() + Math.random(),
        ...options
      },
      message: message,
    });
  }

  hideNotification(key: number | string) {
    this.notifications = this.notifications.filter(
      notification => notification.options.key !== key
    );
  }

  clearAllNotifications() {
    this.notifications = [];
    this.setDismissAllNotificationsFlag(true);
    return this;
  }

  showDefaultNotification({ message, options }: Notifications) {
    this.enqueueSnackbar({
      message,
      options: {
        variant: NotificationType.default,
        ...options
      }
    });
  }

  showSuccessNotification({ message, options }: Notifications) {
    this.enqueueSnackbar({
      message,
      options: {
        variant: NotificationType.success,
        ...options
      }
    });
  }

  showErrorNotification({ message, options }: Notifications) {
    this.enqueueSnackbar({
      message,
      options: {
        variant: NotificationType.error,
        ...options
      }
    });
  }

  showWarningNotification({ message, options }: Notifications) {
    this.enqueueSnackbar({
      message,
      options: {
        variant: NotificationType.warning,
        ...options
      }
    });
  }

  showInfoNotification({ message, options }: Notifications) {
    this.enqueueSnackbar({
      message,
      options: {
        variant: NotificationType.info,
        ...options
      }
    });
  }
}
