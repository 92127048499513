import { Box, Typography } from "@material-ui/core";
import { NotificationsActive } from "@material-ui/icons";
import { Button, DateField, IconButton, Tooltip } from "@roc/ui";
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useState } from "react";
import { useParams } from "react-router";
import { useForeclosureSummaryStore } from "../../hooks/useForeclosureSummaryStore";
import { format, isValid } from "date-fns";

const useStyles = makeStyles(() =>
  createStyles({
    defaultLabel: {
      fontFamily: 'Oswald, sans-serif;',
    },
    boldLabel: {
      fontFamily: 'Oswald, sans-serif;',
      fontWeight: 'bold'
    },
  }),
);

export const PickRemindMeDate = ({ currentFollowUpDate, onClose }) => {

  const { loanId } = useParams<{ loanId: string }>();
  const { foreclosureSummaryStore } = useForeclosureSummaryStore();
  const [dateValue, setDateValue] = useState(null);

  const onDateChange = (newValue) => {
    setDateValue(newValue)
  }

  const onForeclosureRemindMeDatePicked = () => {
    foreclosureSummaryStore.saveForeclosureFollowUp(
      {
        loanId: Number(loanId),
        followUpDate: dateValue
      }
    )
    onClose();
  }

  const manageDate = (value) => {
    const formattedDate = isValid(value) ? format(value, 'MM/dd/yyyy') : null;
    onDateChange(formattedDate);
  };

  return (
    <Box display='flex' flexDirection='column' gridGap='12px'>
      <Typography variant="subtitle1">
        {`Current Follow Up Date: ${currentFollowUpDate || "N/A"}`}
      </Typography>
      <DateField
        inputVariant="outlined"
        format="MM/dd/yyyy"
        testId='pick-remind-me-date-input'
        value={dateValue}
        onChange={value => manageDate(value)}
        disablePast={true}
        fullWidth
      />
      <Button
        testId='pick-remind-me-date-btn'
        color='primary'
        variant='contained'
        onClick={onForeclosureRemindMeDatePicked}
        disabled={!dateValue}
      >
        Submit
      </Button>
    </Box>
  );
}

export const ForeclosureRemindMe = ({ remindMeDate, handleRemindMeModalOpen }) => {

  return (
    <Box display='flex' alignItems='center'>
      <IconButton testId="remind-me-icon-btn" onClick={handleRemindMeModalOpen}>
        <Tooltip
          title={
            <Typography variant="subtitle1">
              {!!remindMeDate ? remindMeDate : "N/A"}
            </Typography>
          }
          placement="left"
          arrow
        >
          <NotificationsActive color="primary" />
        </Tooltip>
      </IconButton>
    </Box >
  )
}