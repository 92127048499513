import { Grid, Typography } from "@material-ui/core";
import { isNotBlank } from "@roc/feature-utils";
import { TextField } from "@roc/ui";
import { observer } from "mobx-react";
import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme =>
  createStyles({
    input: {
      margin: 8,
    }
  })
);

export const ResidentialUnits = observer(
  ({ store, multiFamilyStore }: { store: any; multiFamilyStore: any; }) => {
    const formFields = store.form.fields;
    const classes = useStyles();
    return (
      <div className={classes.input}>
        <TextField
          type="number"
          testId="residentialUnits"
          variant="outlined"
          standaloneLabel
          label="Residential Units"
          value={formFields.residentialUnits.value}
          onChange={e => store.onFieldChange('residentialUnits', e.target.value)}
          error={isNotBlank(formFields.residentialUnits.error)}
          helperText={formFields.residentialUnits.error}
          fullWidth
          required
        />
      </div>
    );
  }
);
export const CommercialUnits = observer(
  ({ store, multiFamilyStore }: { store: any; multiFamilyStore: any; }) => {
    const formFields = store.form.fields;
    const classes = useStyles();
    return (
      <div className={classes.input}>
        <TextField
          type="number"
          testId="commercialUnits"
          variant="outlined"
          standaloneLabel
          label="Commercial Units"
          value={formFields.commercialUnits.value}
          onChange={e => store.onFieldChange('commercialUnits', e.target.value)}
          error={isNotBlank(formFields.commercialUnits.error)}
          helperText={formFields.commercialUnits.error}
          fullWidth
          required
        />
      </div>
    );
  }
);
export const ResidentialUnitsArea = observer(
  ({ store, multiFamilyStore }: { store: any; multiFamilyStore: any; }) => {
    const formFields = store.form.fields;
    const classes = useStyles();
    return (
      <div className={classes.input}>
        <TextField
          type="number"
          testId="residentialUnitsSqFtArea"
          variant="outlined"
          standaloneLabel
          label="Residential Units Area"
          value={formFields.residentialUnitsSqFtArea.value}
          onChange={e => store.onFieldChange('residentialUnitsSqFtArea', e.target.value)}
          error={isNotBlank(formFields.residentialUnitsSqFtArea.error)}
          helperText={formFields.residentialUnitsSqFtArea.error}
          fullWidth
          required
        />
      </div>
    );
  }
);
export const CommercialUnitsArea = observer(
  ({ store, multiFamilyStore }: { store: any; multiFamilyStore: any; }) => {
    const formFields = store.form.fields;
    const classes = useStyles();
    return (
      <div className={classes.input}>
        <TextField
          type="number"
          testId="commercialUnitsSqFtArea"
          variant="outlined"
          standaloneLabel
          label="Commercial Units Area"
          value={formFields.commercialUnitsSqFtArea.value}
          onChange={e => store.onFieldChange('commercialUnitsSqFtArea', e.target.value)}
          error={isNotBlank(formFields.commercialUnitsSqFtArea.error)}
          helperText={formFields.commercialUnitsSqFtArea.error}
          fullWidth
          required
        />
      </div>
    );
  }
);
export const ResidentialIncome = observer(
  ({ store, multiFamilyStore }: { store: any; multiFamilyStore: any; }) => {
    const formFields = store.form.fields;
    const classes = useStyles();
    return (
      <div className={classes.input}>
        <TextField
          type="number"
          testId="residentialIncome"
          variant="outlined"
          standaloneLabel
          label="Residential Income"
          value={formFields.residentialIncome.value}
          onChange={e => store.onFieldChange('residentialIncome', e.target.value)}
          error={isNotBlank(formFields.residentialIncome.error)}
          helperText={formFields.residentialIncome.error}
          fullWidth
          required
        />
      </div>
    );
  }
);
export const CommercialIncome = observer(
  ({ store, multiFamilyStore }: { store: any; multiFamilyStore: any; }) => {
    const formFields = store.form.fields;
    const classes = useStyles();
    return (
      <div className={classes.input}>
        <TextField
          type="number"
          testId="commercialIncome"
          variant="outlined"
          standaloneLabel
          label="Commercial Income"
          value={formFields.commercialIncome.value}
          onChange={e => store.onFieldChange('commercialIncome', e.target.value)}
          error={isNotBlank(formFields.commercialIncome.error)}
          helperText={formFields.commercialIncome.error}
          fullWidth
          required
        />
      </div>
    );
  }
);

export const MixedUsePropertyInformation = observer(({ store, multiFamilyStore }) => {
  return (<Grid container>
    <Grid item sm={12} xl={12}>
      <Typography variant="body2">
        Provide Mixed use Property Information
      </Typography>
    </Grid>
    <Grid item sm={12} xl={4}>
      <ResidentialUnits store={store} multiFamilyStore={multiFamilyStore} />
    </Grid>
    <Grid item sm={12} xl={4}>
      <CommercialUnits store={store} multiFamilyStore={multiFamilyStore} />
    </Grid>
    <Grid item sm={12} xl={4}>
      <ResidentialUnitsArea store={store} multiFamilyStore={multiFamilyStore} />
    </Grid>
    <Grid item sm={12} xl={4}>
      <CommercialUnitsArea store={store} multiFamilyStore={multiFamilyStore} />
    </Grid>
    <Grid item sm={12} xl={4}>
      <ResidentialIncome store={store} multiFamilyStore={multiFamilyStore} />
    </Grid>
    <Grid item sm={12} xl={4}>
      <CommercialIncome store={store} multiFamilyStore={multiFamilyStore} />
    </Grid>
  </Grid>)
});
