import { Grid } from '@material-ui/core';
import {
  createCurrencyField,
  createSelectField,
  createTextField,
  DialogState,
  EditableDialog,
  createDateField
} from '@roc/ui';
import InsuranceApprovalStore from 'libs/feature-documents/src/documents/stores/documentForms/insuranceApproval/insuranceApprovalStore';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { NewQuoteFormStore } from '../../../../../../stores/documentForms/insuranceApproval/newQuoteFormStore';
import { quoteTypeOptions } from '../../../constants';

interface Props {
  insuranceApprovalStore: InsuranceApprovalStore;
}

export const NewQuoteDialog = observer(({ insuranceApprovalStore }: Props) => {
  const { insuranceApprovalFormStore } = insuranceApprovalStore;
  const store = insuranceApprovalFormStore.newQuoteFormStore;

  useEffect(() => {
    store.updateCalculatedFields();
  }, [
    store.form.fields.quoteType.value,
    store.form.fields.cost.value,
    store.form.fields.paidAlreadyAmount.value,
  ]);

  const getQuoteTypeOptions = () => {
    if (insuranceApprovalStore.loanStore.loanDetails.insuranceReviewerId == 4) {
      return [...quoteTypeOptions,
      {
        label: 'Property Only',
        value: 'Property Only',
      },
      {
        label: 'Liability Only',
        value: 'Liability Only',
      }];
    }
    return quoteTypeOptions;
  }

  return (
    <EditableDialog
      open={[DialogState.ADD, DialogState.EDIT].includes(store.dialogState)}
      title="Quote"
      dialogState={store.dialogState}
      handleClose={() => store.closeDialog()}
      handleAdd={() => store.updateInsuranceDetails()}
      handleEdit={() => store.updateInsuranceDetails()}
      dialogContent={
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {createSelectField({
              store,
              fieldName: 'quoteType',
              testId: 'quoteType',
              label: 'Quote Type',
              options: getQuoteTypeOptions(),
            })}
          </Grid>
          <Grid item xs={12}>
            {createCurrencyField({
              store,
              fieldName: 'cost',
              testId: 'cost',
              label: 'Cost',
            })}
          </Grid>
          <Grid item xs={12}>
            {createCurrencyField({
              store,
              fieldName: 'paidAlreadyAmount',
              testId: 'paidAlreadyAmount',
              label: 'Paid Already Amount',
            })}
          </Grid>
          <Grid item xs={12}>
            {createCurrencyField({
              store,
              fieldName: 'remainingAmount',
              testId: 'remainingAmount',
              label: 'Remaining Amount',
              disabled: true,
            })}
          </Grid>
          <Grid item xs={12}>
            {createTextField({
              store,
              fieldName: 'paymentType',
              testId: 'paymentType',
              label: 'Payment Type',
              disabled: true,
            })}
          </Grid>
          <Grid item xs={12}>
            {createDateField({
              store,
              fieldName: 'policyExpirationDate',
              testId: 'policyExpirationDate',
              label: 'Policy Expiration Date',
              format: 'MM/dd/yyyy',
              disablePast: true,
            })}
          </Grid>
        </Grid>
      }
    />
  );
});
