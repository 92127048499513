import { Grid } from '@material-ui/core';
import { Document } from '@roc/feature-types';
import { Button, StandardDialog } from '@roc/ui';
import { observer } from 'mobx-react';
import React from 'react';
import { useDocumentStore } from '../../../hooks/useDocumentStore';
import { InsuranceProvider } from './insuranceProvider';

export type InsuranceProviderDialogProps = {
  open?: boolean;
  onClose?: () => void;
  document: Document;
};

export const InsuranceProviderDialog = observer(
  (props: InsuranceProviderDialogProps) => {
    const { open, onClose, document } = props;

    const {
      documentStore: {
        documentFormStore: { insuranceProviderStore },
      },
    } = useDocumentStore();
    const { canSubmit } = insuranceProviderStore;

    const onSubmit = () => {
      insuranceProviderStore.submitInsuranceForm(document);
      onClose();
    };

    return (
      <StandardDialog
        open={open}
        title="Insurance"
        maxWidth="lg"
        handleClose={onClose}
        dialogContent={<InsuranceProvider />}
        dialogActions={
          <Grid container spacing={1} justifyContent="flex-end">
            <Grid item>
              <Button
                onClick={onClose}
                color="primary"
                variant="outlined"
                testId="cancel"
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={onSubmit}
                color="primary"
                variant="contained"
                testId="confirm"
                disabled={!canSubmit}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        }
      />
    );
  }
);

export default InsuranceProviderDialog;
