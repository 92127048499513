import { Box, Typography } from '@material-ui/core';
import { FastForward } from '@material-ui/icons';
export const FastForwardIcon = ({ value }) => {
  return (
    <Box pt={1} display="flex" justifyContent="center" color="#12ad2b">
      <Typography>
        <span style={{ fontWeight: 'bolder', color: '#12ad2b' }}>Yes  </span>
      </Typography>
      <FastForward
        style={{ color: '#12ad2b' }}
      />
    </Box>
  );
};
