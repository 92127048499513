import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Box, Toolbar, Typography } from '@material-ui/core';
import { formatCurrency } from '@roc/ui/utils';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    '& .MuiTableRow-head th': {
      fontWeight: 'bold',
    },
  },
  tableContainer: {
    maxHeight: 200,
  },
});

function createData(date: string, description: string, sellerName: string, buyerName: string, lenderName: string, price: number, loanAmount: number) {
  return { date, description, sellerName, buyerName, lenderName, price, loanAmount };
}

const rows = [
  createData('02/13/23', 'Lorem Ipsum', 'Seller Name', 'Buyer Name', 'Lender Name', 230000, 50000),
  createData('02/13/22', 'Lorem Ipsum', 'Seller Name', 'Buyer Name', 'Lender Name', 230000, 50000),
  createData('02/13/21', 'Lorem Ipsum', 'Seller Name', 'Buyer Name', 'Lender Name', 230000, 50000),
  createData('02/13/20', 'Lorem Ipsum', 'Seller Name', 'Buyer Name', 'Lender Name', 230000, 50000),
  createData('02/13/19', 'Lorem Ipsum', 'Seller Name', 'Buyer Name', 'Lender Name', 230000, 50000),
  createData('02/13/18', 'Lorem Ipsum', 'Seller Name', 'Buyer Name', 'Lender Name', 230000, 50000),
  createData('02/13/17', 'Lorem Ipsum', 'Seller Name', 'Buyer Name', 'Lender Name', 230000, 50000),
  createData('02/13/16', 'Lorem Ipsum', 'Seller Name', 'Buyer Name', 'Lender Name', 230000, 50000),
];

export const TitleHistoryTable = () => {
  const classes = useStyles();

  return (
    <Paper elevation={0} variant='outlined'>
      <Toolbar style={{
        minHeight: 52,
        padding: '8px 16px',
      }}>
        <Typography variant="h6" component="div">
          Title History
        </Typography>
      </Toolbar>
      <TableContainer className={classes.tableContainer}>
        <Table className={classes.table} size='small' stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell align="center">Date</TableCell>
              <TableCell align="center">Description</TableCell>
              <TableCell align="center">Seller</TableCell>
              <TableCell align="center">Buyer</TableCell>
              <TableCell align="center">Lender</TableCell>
              <TableCell align="center">Price</TableCell>
              <TableCell align="center">Loan Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, i) => (
              <TableRow hover key={i}>
                <TableCell component="th" scope="row" align="center">
                  {i + 1}
                </TableCell>
                <TableCell component="th" scope="row" align="center">
                  {row.date}
                </TableCell>
                <TableCell component="th" scope="row" align="center">
                  {row.description}
                </TableCell>
                <TableCell component="th" scope="row" align="center">
                  {row.sellerName}
                </TableCell>
                <TableCell component="th" scope="row" align="center">
                  {row.buyerName}
                </TableCell>
                <TableCell component="th" scope="row" align="center">
                  {row.lenderName}
                </TableCell>
                <TableCell component="th" scope="row" align="center">
                  {formatCurrency(row.price)}
                </TableCell>
                <TableCell component="th" scope="row" align="center">
                  {formatCurrency(row.loanAmount)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box p={2} bgcolor={'#fff5e7'} borderTop={'1px solid #eee'}>
        <Typography style={{
          fontWeight: 'bold'
        }}>
          Warnings
        </Typography>
        <ol style={{
          marginLeft: 16
        }}>
          <li>Price Run up (Property is sold for less than 80% subject purchase price within 3 years)</li>
          <li>Previous Private Lender flag</li>
          <li>Previous Roc loan</li>
        </ol>
      </Box>
    </Paper>
  );
}
