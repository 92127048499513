import { action, makeObservable, observable } from 'mobx';
import { GlobalStore } from '@roc/feature-app-core';
import { FormStore } from '@roc/feature-app-core';
import { Broker } from './../types/brokerTypes';
import CompanyFormStore from 'libs/feature-loans/src/loanSubmission/stores/common/companyFormStore';
import { InsuranceCompanyFormStore } from 'libs/feature-loans/src/loanSubmission/stores/common/insuranceCompanyFormStore';

export const form = {
  fields: {
    brokerVendorClosingSettingsId: { value: null },
    preferredClosingAttorney: {
      value: '',
      error: null,
      rule: 'required',
    },
    preferredInsuranceCompany: {
      value: '',
      error: null,
      rule: 'required',
    },
    preferredTitleCompany: {
      value: '',
      error: null,
      rule: 'required',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export class BrokerVendorClosingSettingsFormStore extends FormStore {
  isEdit: boolean;
  isDisabled: boolean;

  constructor(globalStore: GlobalStore) {
    super(form, globalStore);
    this.isEdit = false;
    this.isDisabled = false;
    makeObservable(this, {
      setIsEdit: action,
      setIsDisabled: action,
      isEdit: observable,
      isDisabled: observable,
    });
  }

  setBroker(broker: Broker) {
    this.reset();
    this.loadForm(broker);
  }

  getBrokerVendorClosingSettings() {
    return this.getFormValues();
  }

  setIsEdit(value: boolean) {
    this.isEdit = value;
  }

  setIsDisabled(value: boolean) {
    this.isDisabled = value;
  }
}
