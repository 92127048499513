import { GlobalStore, UserStore } from '@roc/feature-app-core';
import { FixFlipStore } from '@roc/feature-loans';
import { LoanSubType } from '@roc/feature-utils';
import { GroundUpLoanEconomicsStore } from './loanEconomics/groundUpLoanEconomicsStore';
import { GroundUpPropertiesStore } from './properties/groundUpPropertiesStore';
import { QuoteTypeStore } from './quoteTypeStore';

export class GroundUpQuoteStore extends QuoteTypeStore {
  constructor(globalStore: GlobalStore, userStore: UserStore) {
    super(globalStore, userStore);
    this.loanSubtype = LoanSubType.GROUND_UP;
    this.propertiesStore = new GroundUpPropertiesStore(this.globalStore, this.loanSubtype);
    this.loanEconomicsStore = new GroundUpLoanEconomicsStore(
      this.globalStore,
      this
    );
  }
}
