import {
  GeneralTasksFilters,
  GeneralTaskType,
  Task,
  TaskPriority,
  TaskScope,
  TaskStatus,
} from '../types/generalTasksTypes';

export const generalTaskStatusOptions = [
  { label: 'Open', value: TaskStatus.OPEN },
  { label: 'In Progress', value: TaskStatus.IN_PROGRESS },
  { label: 'Done', value: TaskStatus.DONE },
  { label: 'Rejected', value: TaskStatus.REJECTED },
];

export const generalTaskPriorityOptions = [
  { label: 'Low', value: TaskPriority.LOW },
  { label: 'Medium', value: TaskPriority.MEDIUM },
  { label: 'High', value: TaskPriority.HIGH },
  { label: 'Critical', value: TaskPriority.CRITICAL },
  { label: 'Very Critical', value: TaskPriority.VERY_CRITICAL },
];

export const moveToRocCategories = [
  "APPRAISAL_REPORT_REVIEW",
  "CMT_REVIEW",
  "APPRAISAL_DOCUMENT_COLLECTION",
  "APPRAISAL_TAMARISK_REVIEW"
];

export const hasOpenSubtasksOptions = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

export const taskTypeOptions = [
  { label: 'Credit Review', value: GeneralTaskType.CREDIT_REVIEW },
  { label: 'Background Review', value: GeneralTaskType.BACKGROUND_REVIEW },
  { label: 'Track Record Review', value: GeneralTaskType.TRACK_RECORD_REVIEW },
  { label: 'Appraisal Report & Related', value: GeneralTaskType.APPRAISAL_REPORT_AND_RELATED },
  { label: 'Appraisal Risk Review', value: GeneralTaskType.APPRAISAL_RISK_REVIEW },
  { label: 'Collateral Docs', value: GeneralTaskType.COLLATERAL_DOCS },
  { label: 'SOW Review', value: GeneralTaskType.SOW_REVIEW },
  { label: 'Entity Section', value: GeneralTaskType.ENTITY_SECTION },
  { label: 'Feedback', value: GeneralTaskType.FEEDBACK },
  { label: 'Tech Support', value: GeneralTaskType.TECH_SUPPORT },
  { label: 'External Tech Support', value: GeneralTaskType.EXTERNAL_TECH_SUPPORT },
  // Manual Tasks
  { label: 'Closing Assistance', value: GeneralTaskType.CLOSING_ASSISTANCE },
  { label: 'Payoff Request', value: GeneralTaskType.PAYOFF_REQUEST },
  { label: 'Draw Request', value: GeneralTaskType.DRAW_REQUEST },
  { label: 'Loan Extensions', value: GeneralTaskType.LOAN_EXTENSIONS },
  { label: 'Others / Adhoc', value: GeneralTaskType.OTHERS },
  { label: 'Closing Conditions', value: GeneralTaskType.CLOSING_CONDITIONS },
  { label: 'Loan Clarifications', value: GeneralTaskType.LOAN_CLARIFICATIONS },
  { label: 'Additional Requirement', value: GeneralTaskType.ADDITIONAL_REQUIREMENT },
  { label: 'Insurance', value: GeneralTaskType.INSURANCE },
  { label: 'Appraisal Report Review', value: GeneralTaskType.APPRAISAL_REPORT_REVIEW },
  { label: 'CMT Review', value: GeneralTaskType.CMT_REVIEW },
  { label: 'Appraisal Document Collection', value: GeneralTaskType.APPRAISAL_DOCUMENT_COLLECTION },
  // Legacy Tasks
  { label: 'Appraisal Review - Legacy', value: GeneralTaskType.APPRAISAL_REVIEW, legacy: true },
  { label: 'Subject Property - Legacy', value: GeneralTaskType.SUBJECT_PROPERTY, legacy: true },
  { label: 'Track Record - Legacy', value: GeneralTaskType.TRACK_RECORD, legacy: true },
  // SOS Escalation
  { label: 'Escalation', value: GeneralTaskType.ESCALATION },
  //Servicing
  { label: 'Servicing', value: GeneralTaskType.SERVICING },
];

export const reportedByMeOptions = [
  { label: 'Reported by Me', value: TaskScope.REPORTED_BY_ME },
  { label: 'Assigned to Me', value: TaskScope.ASSIGNED_TO_ME },
  { label: 'Watched by Me', value: TaskScope.WATCHED_BY_ME }
];

export const appraisalTaskTypes = [
  GeneralTaskType.APPRAISAL_REPORT_REVIEW,
  GeneralTaskType.CMT_REVIEW,
  GeneralTaskType.APPRAISAL_DOCUMENT_COLLECTION
];
