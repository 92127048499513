import { Grid } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { BorrowingEntityName, FirstName, LastName, LoanId, Rationale, Street, StreetNumber, City, ZipCode, Email, PhoneNumber } from "./components/denialOfCreditFormFields";
import { useDenialOfCreditStore } from "./hooks/useDenialOfCreditStore";

export const DenialOfCreditForm = observer(()=> {
  const {denialOfCreditRequestStore} = useDenialOfCreditStore();
  const {denialOfCreditFormStore: store} = denialOfCreditRequestStore;

  return (
    <Grid container spacing={5}>
      <Grid item xs={6}>
        <FirstName store={store}/>
      </Grid>
      <Grid item xs={6}>
        <LastName store={store}/>
      </Grid>
      <Grid item xs={6}>
        <BorrowingEntityName store={store}/>
      </Grid>
      <Grid item xs={6}>
        <LoanId store={store}/>
      </Grid>
      <Grid item xs={6}>
        <StreetNumber store={store}/>
      </Grid>
      <Grid item xs={6}>
        <Street store={store}/>
      </Grid>
      <Grid item xs={6}>
        <City store={store}/>
      </Grid>
      <Grid item xs={6}>
        <ZipCode store={store}/>
      </Grid>
      <Grid item xs={6}>
        <Email store={store}/>
      </Grid>
      <Grid item xs={6}>
        <PhoneNumber store={store}/>
      </Grid>
      <Grid item xs={12}>
        <Rationale store={store}/>
      </Grid>
    </Grid>
  );
})