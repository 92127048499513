import {
  Box,

  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { darken } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { SubdirectoryArrowRight } from '@material-ui/icons';
import { Layout, useUserStore } from '@roc/feature-app-core';
import { US_TREASURY_10_YR_DESC, US_TREASURY_10_YR_INDEX, US_TREASURY_5_YR_DESC, US_TREASURY_5_YR_INDEX } from '@roc/feature-loans';
import {
  formatDate,
  isFundedOrAgreedLoan,
  isNil,
  sanitizeTestId,
} from '@roc/feature-utils';
import {
  BooleanChip,
  Paper,
  StatusChip,
  StatusType,
  TestDataAttribute,
} from '@roc/ui';
import { formatCurrency } from '@roc/ui/utils';

const useStyles = makeStyles(theme => ({
  paper: {
    marginBottom: theme.spacing(2),
  },
  card: {
    background: theme.palette.background.default,
    borderColor: darken(theme.palette.background.default, 0.1),
  },
}));

const listItem = (label, value, icon = null, customTestId = null) => {
  const testId = sanitizeTestId(customTestId || label);
  return (
    <ListItem>
      <ListItemText>
        <Box display="flex">
          {icon && <Box ml={1}>{icon}</Box>}
          <Box flexGrow={1} mr={1}>
            {label}
          </Box>
          <TestDataAttribute id={testId}>
            <Box textAlign="right">{value}</Box>
          </TestDataAttribute>
        </Box>
      </ListItemText>
    </ListItem>
  );
};

const divider = <Divider variant="middle" component="li" />;

const formatPercentage = value =>
  `${value != null ? Number(value).toFixed(2) : ''}%`;

const formatDigit = (value, digits) => value?.toFixed(3, digits);

const getSwapIndexLabel = index => {
  if (index === US_TREASURY_5_YR_INDEX) return US_TREASURY_5_YR_DESC;
  else if (index === US_TREASURY_10_YR_INDEX) return US_TREASURY_10_YR_DESC;
}

export const TermLoanTermsSection = ({ loanData }) => {
  const classes = useStyles();
  const { userStore } = useUserStore();
  const {
    amount,
    initialLoanAmount,
    status,
    loanType,
    loanSubType,
    attorneyName,
    minNumber,
    interestReserve,
    brokerPointsIn,
    prepayPenaltyMonths,
    rocRate,
    rocPointsIn,
    pledgeOfShares,
    loanPurpose,
    interestReserveMonths,
    amortization,
    recourseStructure,
    rate,
    duration,
    aggregateLoanToAsIs,
    aggregateLoanToCurrentCost,
    totalOriginationFee,
    borrowerCashAtClose,
    interestOnlyMonths,
    interestPayment,
    rateType,
    originalAmortizationTerms,
    prepaymentDescription,
    originationSource,
    sfrLenderPointsIn,
    totalUnderwritingFee,
    rocUnderwritingFees,
    brokerLenderUnderwritingFees,
    lenderPremium,
    servicer,
    freeAppraisalPromotion,
  } = loanData;

  const isFundedOrPaidoffOrAgreedLoan = isFundedOrAgreedLoan(status);

  const allowLoanPricing = userStore.allowLoanPricing;
  return (
    <Paper title="LOAN TERMS" className={classes.paper}>
      <Grid container direction="row" spacing={4}>
        <Grid item xs={12} md={6} lg={4}>
          <List>
            {listItem('Loan Type', loanType)}
            {divider}
            {listItem('Loan SubType', loanSubType)}
            {divider}
            {listItem(
              'Loan Status',
              <StatusChip
                statusType={StatusType.LOAN}
                label={status}
                variant="filled"
              />
            )}
            {divider}
            {listItem('Loan Amount', formatCurrency(amount))}
            {divider}
            {listItem(
              'Initial Loan Amount',
              <Typography variant="body2" color="textSecondary" component="p">
                {formatCurrency(initialLoanAmount)}
              </Typography>,
              <Box flexGrow={1}>
                <SubdirectoryArrowRight fontSize="small" />
              </Box>
            )}
            {divider}
            {listItem('Loan Purpose', loanPurpose)}
            {divider}
            {listItem(
              'Borrower Cash Out at Close',
              formatCurrency(borrowerCashAtClose ?? 0)
            )}
            {divider}
            {listItem(
              'Monthly P&I Payment',
              formatCurrency(interestPayment ?? 0)
            )}
            {divider}
            {listItem('P&I Reserves at close (Months)', interestReserveMonths)}
            {divider}
            {listItem('P&I Reserves at close', interestReserve)}
            {divider}
            {listItem('Attorney', attorneyName)}
            {isFundedOrPaidoffOrAgreedLoan && divider}
            {isFundedOrPaidoffOrAgreedLoan && listItem('Servicer', servicer)}
          </List>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <List>
            {listItem('MERS MIN Number', minNumber)}
            {divider}
            {listItem('Loan Rate-Type', rateType)}
            {divider}
            {listItem('Amortization', amortization)}
            {divider}
            {listItem('Int Only Periods(Months)', interestOnlyMonths)}
            {divider}
            {listItem('Loan Guaranty', recourseStructure)}
            {divider}
            {listItem(
              'Pledge of Shares',
              <BooleanChip value={pledgeOfShares} />
            )}
            {divider}
            {listItem('Note Interest Rate', formatPercentage(rate ?? 0))}
            {divider}
            {listItem(
              'Capital Provider Interest Rate',
              formatPercentage(rocRate ?? 0)
            )}
            {divider}
            {listItem('Original Loan Terms (Months)', duration)}
            {divider}
            {listItem(
              'Orig. Amortization Terms (Months)',
              originalAmortizationTerms
            )}
            {divider}
            {listItem(
              'Prepayment Penalty Period (Months)',
              prepayPenaltyMonths
            )}
            {divider}
            {listItem('Prepayment Description', prepaymentDescription)}
          </List>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <List>
            {listItem(
              'Loan LTV at Origination',
              formatPercentage(aggregateLoanToAsIs ?? 0)
            )}
            {divider}
            {listItem(
              'Loan LTC at Origination',
              formatPercentage(aggregateLoanToCurrentCost ?? 0)
            )}
            {divider}
            {listItem('Origination Source', originationSource)}
            {allowLoanPricing && divider}
            {allowLoanPricing &&
              listItem(
                'Total Origination Fee',
                formatPercentage(totalOriginationFee ?? 0)
              )}
            {allowLoanPricing && divider}
            {allowLoanPricing &&
              listItem(
                'Capital Provider Origination Fee',
                formatPercentage(rocPointsIn ?? 0)
              )}
            {allowLoanPricing && divider}
            {allowLoanPricing &&
              listItem(
                'Broker Origination Fee',
                formatPercentage(brokerPointsIn ?? 0)
              )}
            {allowLoanPricing && divider}
            {allowLoanPricing &&
              listItem(
                'Lender Origination Fee',
                formatPercentage(sfrLenderPointsIn ?? 0)
              )}
            {allowLoanPricing && divider}
            {allowLoanPricing &&
              listItem(
                'Total Underwriting Fee',
                formatCurrency(totalUnderwritingFee ?? 0)
              )}
            {allowLoanPricing && divider}
            {allowLoanPricing &&
              listItem(
                'Capital Provider Underwriting Fee',
                formatCurrency(rocUnderwritingFees ?? 0)
              )}
            {allowLoanPricing && divider}
            {allowLoanPricing &&
              listItem(
                'Broker/Lender Underwriting Fee',
                formatCurrency(brokerLenderUnderwritingFees ?? 0)
              )}
            {allowLoanPricing && divider}
            {allowLoanPricing &&
              listItem('Lender Premium', formatPercentage(lenderPremium))}
          </List>
        </Grid>
      </Grid>
    </Paper>
  );
};

export const TermGeneralStatsSection = ({ loanData }) => {
  const classes = useStyles();
  const {
    status,
    propertyCount,
    mostRecentValuation,
    totalCostBasis,
    totalDebtPayoffAmount,
    annualGrossRent,
    pitia,
    noi,
    netCashFlow,
    netDSCR,
    pitiaDSCR,
  } = loanData;

  return (
    <Paper title="GENERAL STATS" className={classes.paper}>
      <Box>
        <List>
          {listItem('Property Count', propertyCount)}
          {divider}
          {listItem(
            'Most Recent Valuation',
            formatCurrency(mostRecentValuation)
          )}
          {divider}
          {listItem('Total Cost Basis', formatCurrency(totalCostBasis))}
          {divider}
          {listItem(
            'Total Debt Payoff Amount',
            formatCurrency(totalDebtPayoffAmount)
          )}
          {divider}
          {listItem('Annual Gross Rent', formatCurrency(annualGrossRent))}
          {divider}
          {listItem('PITIA', formatCurrency(pitia))}
          {divider}
          {listItem('NOI', formatCurrency(noi))}
          {divider}
          {listItem('Net Cash Flow', formatCurrency(netCashFlow))}
          {divider}
          {listItem('NCF DSCR', formatDigit(netDSCR, 3))}
          {divider}
          {listItem('PITIA DSCR', formatDigit(pitiaDSCR, 3))}
        </List>
      </Box>
    </Paper>
  );
};

export const TermKeyDates = ({ loanData }) => {
  const classes = useStyles();
  return (
    <Grid item xs={12}>
      <Paper title="KEY DATES" className={classes.paper}>
        <Box>
          <List>
            {listItem('Exp. Closing Date', loanData?.expectedClosingDate)}
            {divider}
            {listItem('Closing Date', loanData?.closingDate)}
          </List>
        </Box>
      </Paper>
    </Grid>)
}

export const TermPricingAndRateLockInfoSection = ({ loanData }) => {
  const classes = useStyles();
  const { loanPricingData = {} } = loanData;

  return (
    <Paper title="PRICING AND RATE LOCK INFORMATION" className={classes.paper}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={4}>
          <List>
            {listItem(
              'Spread on Pricing Date',
              !isNil(loanPricingData.spreadRate) &&
              formatPercentage(loanPricingData.spreadRate)
            )}
            {divider}
            {listItem(
              'Indicative Gross Rate On Pricing Date',
              !isNil(loanPricingData.grossRateOnPricingDate) &&
              formatPercentage(loanPricingData.grossRateOnPricingDate)
            )}
            {divider}
            {listItem(
              'Pricing Date',
              !isNil(loanPricingData.pricingDate) &&
              formatDate(loanPricingData.pricingDate, 'MMMM d, yyyy')
            )}
            {divider}
            {listItem(
              'Pricing Index',
              !isNil(loanPricingData.swapIndex) &&
              `${getSwapIndexLabel(loanPricingData.swapIndex)}`
            )}
          </List>
        </Grid>
        <Grid item xs={12} md={4}>
          <List>
            {listItem(
              'Index Value On Pricing Date',
              !isNil(loanPricingData.swapRateAtPricing) &&
              formatPercentage(loanPricingData.swapRateAtPricing)
            )}
            {divider}
            {listItem(
              'Index Rate Floor',
              !isNil(loanPricingData.swapRateAtOrigination) &&
              formatPercentage(loanPricingData.swapRateAtOrigination)
            )}
            {divider}
            {listItem(
              'Current Index Value For Loan',
              !isNil(loanPricingData.swapRate) &&
              formatPercentage(loanPricingData.swapRate)
            )}
            {divider}
            {listItem(
              'Today’s Indicative Gross Rate',
              loanPricingData?.useSpread
                ? formatPercentage(
                  (
                    parseFloat(loanPricingData.swapRate) +
                    parseFloat(loanPricingData.spreadRate)
                  ).toFixed(4)
                )
                : null
            )}
          </List>
        </Grid>
        <Grid item xs={12} md={4}>
          <List>
            {listItem(
              'Borrower Spread Lock',
              !isNil(loanPricingData.borrowerSpreadLock) && (
                <BooleanChip value={loanPricingData.borrowerSpreadLock} />
              )
            )}
            {divider}
            {listItem(
              'Borrower Gross Rate Lock Expiry Date',
              !isNil(loanPricingData.borrowerGrossRateLockExpiryDate) &&
              formatDate(
                loanPricingData.borrowerGrossRateLockExpiryDate,
                'MMMM d, yyyy'
              )
            )}
            {divider}
            {listItem(
              'Borrower Gross Rate Lock',
              isNil(loanPricingData.borrowerGrossRateLock) && (
                <BooleanChip value={loanPricingData.borrowerGrossRateLock} />
              )
            )}
            {divider}
            {listItem(
              'Borrower Spread Lock Expiry Date',
              !isNil(loanPricingData.borrowerSpreadLockExpiryDate) &&
              formatDate(
                loanPricingData.borrowerSpreadLockExpiryDate,
                'MMMM d, yyyy'
              )
            )}
          </List>
        </Grid>
      </Grid>
    </Paper>
  );
};

export const TermPropertiesSection = ({ children, loanData }) => {
  const classes = useStyles();
  return (
    <Paper title="PROPERTIES" className={classes.paper}>
      <Layout>
        <TestDataAttribute id="property">
          {children}
        </TestDataAttribute>
      </Layout>
    </Paper>
  );
};
