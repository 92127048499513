import { useBaseStore } from '@roc/feature-app-core';
import { getProspectPropertiesRoutes, getProspectPropertiesRoutesConfig } from './../routes/prospectPropertiesRoutes';

export const useProspectPropertiesRoutes = () => {
  const { globalStore } = useBaseStore();
  const { routeBasePath, routeBaseUrl } = globalStore;

  return {
    prospectPropertiesRoutes: getProspectPropertiesRoutes(routeBasePath, routeBaseUrl),
    prospectPropertiesRoutesConfig: getProspectPropertiesRoutesConfig(routeBasePath, routeBaseUrl),
  };
};
