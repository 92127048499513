import { LoanSubType } from '@roc/feature-utils';
import { BrokersStore } from '@roc/feature-brokers';
import { TermStore } from '../common/termStore';
import PricerStore from './pricerStore';

export class SinglePropertyStore extends TermStore {
  constructor(globalStore, userStore, brokerStore: BrokersStore) {
    super(globalStore, userStore, brokerStore);
    this.pricerStore = new PricerStore(globalStore, this);
    this.loanSubtype = LoanSubType.SINGLE_PROPERTY;
  }
}
