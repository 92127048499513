import { GlobalStore } from "@roc/client-portal-shared/stores";
import { ScopeOfWorkV2Store } from "@roc/feature-sow-shared/stores/v2";
import { flow, makeObservable } from "mobx";
import { ScopeOfWorkRevisionService } from "../services";
import { ScopeOfWorkRevisionFormStore } from "./scopeOfWorkRevisionFormStore";

export class ScopeOfWorkRevisionStore extends ScopeOfWorkV2Store {
  protected scopeOfWorkService: ScopeOfWorkRevisionService;

  constructor(globalStore: GlobalStore) {
    super(globalStore);
    this.scopeOfWorkService = new ScopeOfWorkRevisionService();
    super.scopeOfWorkService = this.scopeOfWorkService;
    this.scopeOfWorkV2FormStore = new ScopeOfWorkRevisionFormStore(globalStore);
    makeObservable(this, {
      fetchSOWRevision: flow,
    })
  }

  *fetchSOWRevision(drawId, username) {
    const { data: response } = yield this.scopeOfWorkService.getSOWRevision(
      drawId
    );
    const sowRevision = JSON.parse(response.data).data;
    const scopeOfWork = JSON.parse(sowRevision.dataContent);
    const params = {
      scopeOfWorkId: scopeOfWork.scopeOfWorkId,
      isInternal: false,
      version: scopeOfWork.version,
      drawId: drawId,
      isRevisionSOW: true,
      username: username
    };
    this.scopeOfWorkV2FormStore.setParams(params);
  }
}