import { Grid, Paper, Tooltip } from '@material-ui/core';
import { formatDate } from '@roc/feature-utils';
import { formatCurrency } from '@roc/ui/utils';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useEffect, useState } from 'react';
import { useGeneralTasksStore } from '../hooks/useGeneralTasksStore';
import { observer } from 'mobx-react';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    // backgroundColor: 'white',
    padding: theme.spacing(2),
  },
  tooltip: {
    // backgroundColor: 'transparent',
    fontSize: theme.typography.pxToRem(14),
    maxWidth: '500px',
  },
  container: {
    display: 'inline-flex',
    lineHeight: 'initial',
  },
}));

export const GeneralTaskLoanTooltip = observer(({ loanId, children }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const { generalTasksStore } = useGeneralTasksStore();
  const { loanInformation } = generalTasksStore;
  const data = loanInformation[loanId];

  const handleOpen = async () => {
    generalTasksStore.fetchLoanInformation([loanId]);
    setOpen(true);
  };

  return (
    <Tooltip
      classes={{
        tooltip: classes.tooltip,
      }}
      open={open && !!data}
      onOpen={handleOpen}
      onClose={() => setOpen(false)}
      arrow
      title={
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <b>Deal Name:</b> {data?.dealName}
          </Grid>
          <Grid item xs={12}>
            <b>Loan Subtype:</b> {data?.loanSubtype}
          </Grid>
          <Grid item xs={12}>
            <b>Lender Name:</b> {data?.lenderName}
          </Grid>
          <Grid item xs={12}>
            <b>Loan Amount:</b>{' '}
            {data?.loanAmount ? formatCurrency(data?.loanAmount) : null}
          </Grid>
          <Grid item xs={12}>
            <b>Submission Date:</b> {formatDate(data?.submissionDate)}
          </Grid>
        </Grid>
      }
    >
      <div className={classes.container}>{children}</div>
    </Tooltip>
  );
});
