import { FormStore, GlobalStore } from '@roc/feature-app-core';
import { LoanService, LoanStore } from '@roc/feature-loans';
import { CLOSING } from '@roc/feature-utils';
import { FileUpload } from '@roc/ui';
import { flow, makeObservable, observable } from 'mobx';
import { DocumentSectionNames, DocumentStore } from '../../documents';
import { LoanActionsService } from '../services/loanActionsService';
import { LoanActionsStore } from './loanActionsStore';

const REQUIRED_FIELD = 'This field is required.';

const form = {
  fields: {
    removePreviousSignedClosingDocs: {
      value: '',
      error: null,
      rule: 'required',
      message: REQUIRED_FIELD,
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export class UploadSignedClosingDocumentsStore extends FormStore {
  private globalStore: GlobalStore;
  private loanStore: LoanStore;
  private documentStore: DocumentStore;
  private loanActionsStore: LoanActionsStore;

  private loanActionsService: LoanActionsService;

  constructor(
    globalStore: GlobalStore,
    loanStore: LoanStore,
    documentStore: DocumentStore,
    loanActionsStore: LoanActionsStore
  ) {
    super(form, globalStore);
    this.globalStore = globalStore;
    this.loanStore = loanStore;
    this.documentStore = documentStore;
    this.loanActionsStore = loanActionsStore;

    this.loanActionsService = new LoanActionsService();

    makeObservable(this, {
      uploadFiles: flow,
      uploadSignedClosingDocuments: flow,
    });
  }

  *uploadFiles(fileUploads: FileUpload[]) {
    const { loanId } = this.loanStore.loanDetails;
    const section = this.documentStore.documentSections.find(
      s => s.sectionName == DocumentSectionNames.SIGNED_CLOSING_DOCUMENTS
    );
    yield this.documentStore.uploadSectionDocuments(
      fileUploads,
      loanId,
      section.sectionId,
      section.sectionName,
      CLOSING
    );
  }

  *uploadSignedClosingDocuments(fileUploads: FileUpload[]) {
    try {
      const { loanId } = this.loanStore.loanDetails;
      const answers = this.getFormValues();
      if (answers.removePreviousSignedClosingDocs) {
        yield this.loanActionsService.removePreviousFromSignedClosingDocuments(
          loanId
        );
      }
      yield this.uploadFiles(fileUploads);
      yield this.loanActionsService.markClosingStagePendingApproval(loanId);
      this.loanActionsStore.currentAction = null;
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Files uploaded successfully',
      });
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while making request',
      });
    }
  }
}
