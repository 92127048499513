import { Box } from '@material-ui/core';
import { Tooltip } from '@roc/ui';
import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  task: {
    border: '1px solid #808080',
    width: '20px',
    height: '20px',
    lineHeight: '18px',
    textAlign: 'center',
    fontWeight: 'bold',
    borderRadius: '3px',
    fontSize: '12px',
    color: '#808080',
  },
  subtask: {
    border: '1px solid ' + theme.palette.primary.main,
    width: '20px',
    height: '20px',
    lineHeight: '18px',
    textAlign: 'center',
    fontWeight: 'bold',
    borderRadius: '3px',
    fontSize: '12px',
    color: theme.palette.primary.main,
  },
}));

export const SubtaskIcon = ({ isSubtask }) => {
  const classes = useStyles();
  return (
    <Tooltip title={isSubtask ? 'Subtask' : 'Task'}>
      <Box className={isSubtask ? classes.task : classes.subtask}>
        {isSubtask ? 'ST' : 'T'}
      </Box>
    </Tooltip>
  );
};
