import { Box, Grid } from '@material-ui/core';
import { observer } from 'mobx-react';
import { useGeneralTasksStore } from '../../../hooks/useGeneralTasksStore';
import { FilterType, MyChatContainer, SelectConversation, useLoanCommunicationStore } from '@roc/feature-communication';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        containerWrapperInternal: {
        overflow: 'auto',
        flexGrow: 1,
        },
        container: {
        width: '100%',
        },
        warningButton: {
        color: '#ff0000',
        borderColor: '#ff0000',
        marginRight: '8px',
        },
    }),
);
  
export const CommentsTab = observer(() => {
    const { generalTasksStore } = useGeneralTasksStore();
    const { editTaskStore } = generalTasksStore;
    const { loanCommunicationStore } = useLoanCommunicationStore();
    const classes = useStyles();
  
    return (
      <>
        {editTaskStore?.hasConversation ? (
          <Grid item xs={12} md={12}>
            <Box display="flex" height={'70vh'} >
              <MyChatContainer
                id={Number(editTaskStore?.task?.taskDetails?.taskId)}
                communicationStore={loanCommunicationStore}
                allowNewConversations={false}
                allowJoinConversation={true}
                showEmailAddress={false}
                showSnippet={false}
                className={classes.containerWrapperInternal}
                allowRemoveParticipants={true}
                allowManageParticipants={true}
                filterType={FilterType.TASK_ID}
                allowAddInternalParticipants={true}
                onConversationSelect={conversationId => {
                  loanCommunicationStore?.setCurrentConversationId(conversationId)
                }
                }
              />
              <SelectConversation communicationStore={loanCommunicationStore} />
            </Box>
          </Grid>
        ) : (
          <Grid item xs={12} md={12}>
            <Box display="flex"
              justifyContent="flex-start"
              alignItems="flex-start"
              height="50vh"
              textAlign="left"
              padding={2}
            >
            </Box>
          </Grid>
        )}
      </>
    );
});
  