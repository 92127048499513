import { FormStore } from '@roc/feature-app-core';
import { GlobalStore } from '@roc/feature-app-core';
import { borrowerDefaults } from '@roc/feature-utils';
import { action, makeObservable } from 'mobx';
import { DialogState } from '@roc/ui';

const borrowerRoleName = 'borrower';

const borrowerForm = {
  fields: {
    borrowerId: {
      value: null,
      error: null,
      rule: '',
    },
    firstName: {
      value: '',
      error: null,
      rule: 'required',
    },
    lastName: {
      value: '',
      error: null,
      rule: 'required',
    },
    emailAddress: {
      value: '',
      error: null,
      rule: 'required|email',
    },
    emailAddressDuplicated: {
      value: true,
      error: null,
      rule: [{ required_unless: ['emailAddress', ''] }, 'accepted'],
      message: 'This email is already selected for other borrower.',
    },
    cellPhone: {
      value: '',
      error: null,
      rule: '',
    },
    roleInTransaction: {
      value: null,
      error: null,
      rule: '',
    },
    personalGuarantor: {
      value: borrowerDefaults.personalGuarantor,
      error: null,
      rule: 'required',
    },
    pctOwnership: {
      value: 0,
      error: null,
      rule: 'required|between:0,100',
    },
    borrowerExp: {
      value: '',
      error: null,
      rule: '',
    },
    ficoProvidedAtOrigination: {
      value: 680,
      error: null,
      rule: '',
    },
    authSignatory: {
      value: false,
      error: null,
      rule: '',
    },
    creditBackgroundCheck: {
      value: false,
      error: null,
      rule: '',
    },
    hasSubstantialChanges: {
      value: false,
      error: null,
      rule: '',
    },
    substantialChangesDescription: {
      value: '',
      error: null,
      rule: '',
    },
    ownershipNotKnown: {
      value: false,
      error: null,
      rule: '',
    },
    citizenshipStatus: {
      value: '',
      error: null,
      rule: '',
    },
    leadSource: {
      value: '',
      error: null,
      rule: '',
    },
    leadSourceGroup: {
      value: '',
      error: null,
      rule: '',
    },
    leadSfId: {
      value: '',
      error: null,
      rule: '',
    },
    leadId: {
      value: null,
      error: null,
      rule: '',
    },
    experience: {
      value: 1,
      error: null,
      rule: '',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};
export class BorrowerFormStore extends FormStore {
  private globalStore: GlobalStore;
  constructor(globalStore) {
    super({ ...borrowerForm }, globalStore);
    this.globalStore = globalStore;
    makeObservable(this, {
      initFormValues: action,
    });
  }

  isUserBorrower(user) {
    return user.role.toLowerCase() === borrowerRoleName;
  }

  initFormValues(dialogState: DialogState, pricerFields) {
    if (dialogState === DialogState.ADD) {
      this.onFieldChange(
        'citizenshipStatus',
        pricerFields.citizenshipStatus.value
      );
    }

    this.onFieldChange('borrowerExp', pricerFields.borrowerExp.value);
    this.onFieldChange('ficoProvidedAtOrigination', pricerFields.ficoProvidedAtOrigination.value);
  }
}

export default BorrowerFormStore;
