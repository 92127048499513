import { Grid } from '@material-ui/core';
import { Button, StandardDialog } from '@roc/ui';
import { observer } from 'mobx-react';
import React from 'react';
import { useBrokersStore } from '../../hooks/useBrokersStore';
import { SelectBrokerGrid } from './selectBrokersGrid';

export const SelectBrokersDialog = observer(() => {
  const { brokersStore } = useBrokersStore();
  const { selectBrokersStore } = brokersStore;
  const { selectDialogState, allowMultiple } = selectBrokersStore;

  const cancel = () => selectBrokersStore.closeSelectBrokersDialog();
  const confirm = () => selectBrokersStore.confirmSelectedBrokers();

  return (
    <StandardDialog
      open={selectDialogState !== null}
      title={`Select Broker${allowMultiple ? 's' : ''}`}
      dialogContent={open ? <SelectBrokerGrid /> : null}
      dialogActions={
        <Grid container spacing={1} justifyContent="flex-end">
          <Grid item>
            <Button
              onClick={cancel}
              color="primary"
              variant="outlined"
              testId="cancel"
            >
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={confirm}
              color="primary"
              variant="contained"
              testId="confirm"
            >
              Confirm
            </Button>
          </Grid>
        </Grid>
      }
      handleClose={cancel}
    />
  );
});

export default SelectBrokersDialog;
