import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useLoanRoutes } from '@roc/feature-loans-routes-config';
import { StyledBadgeFunded } from '@roc/feature-loans';
import {
  DataGrid,
  FilterButton,
  GridColumnProps,
  LinkColumn,
  SelectFilterComponent,
  SelectFloatingFilterComponent,
  Tabs,
} from '@roc/ui';
import {
  useLoanStore,
} from '@roc/feature-loans';
import { filterProps, formatCurrency, noOpComparator } from '@roc/ui/utils';
import { observer } from 'mobx-react';
import { useEffect } from 'react';

const useStyles = makeStyles(theme => ({
  layoutBody: {
    display: 'block',
    overflow: 'auto',
  },
}));

export const columns: GridColumnProps[] = [
  {
    field: 'dealid',
    headerName: 'Loan Id',
    minWidth: 150,
    cellRenderer: 'loanIdCellRenderer',
    comparator: noOpComparator,
    ...filterProps(),
    pinned: 'left',
    lockPinned: true,
  },
  {
    field: 'dealName',
    headerName: 'Deal Name',
    minWidth: 300,
    cellRenderer: 'dealNameCellRenderer',
    comparator: noOpComparator,
    ...filterProps(),
  },
  {
    field: 'loanSubtype',
    headerName: 'Loan Type',
    minWidth: 150,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'lenderName',
    headerName: 'Lender Name',
    minWidth: 150,
    comparator: noOpComparator,
    ...filterProps(),
  },
  {
    field: 'goodThroughDate',
    headerName: 'Good Through Date',
    minWidth: 150,
    comparator: noOpComparator,
    filter: 'agDateColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'requestedDate',
    headerName: 'Requested Date',
    minWidth: 150,
    comparator: noOpComparator,
    filter: 'agDateColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
];

export const LoansGrid = observer(({ gridStore, tabs }) => {
  const classes = useStyles();
  const { loanRoutesConfig } = useLoanRoutes();
  const { loanStore } = useLoanStore();

  useEffect(() => {
    gridStore.fetchGridData();
  }, [gridStore]);

  const frameworkComponents = {
    selectFilter: SelectFilterComponent,
    selectFloatingFilter: SelectFloatingFilterComponent,
    loanIdCellRenderer: params => (
      <StyledBadgeFunded badgeContent={params.node.data?.unreadMessages}>
        {LinkColumn({
          ...params,
          url: loanRoutesConfig.loans(params.value).children.payoffRequest.url,
        })}
      </StyledBadgeFunded>
    ),
    dealNameCellRenderer: params => {
      const loanId = params.node.data?.dealid || params.node.data?.loanId;
      return LinkColumn({
        ...params,
        url: loanRoutesConfig.loans(loanId).children.payoffRequest.url,
      });
    },
    currencyCellRenderer: ({ value }) => {
      return value ? formatCurrency(Number(value)) : null;
    },
  };

  const onSortChanged = params => {
    const sortModel = params.api.getSortModel();
    gridStore.setSortModel(sortModel);
  };

  const onFilterChanged = params => {
    const filterModel = params.api.getFilterModel();
    gridStore.setFilterModel(filterModel);
  };

  const onTabChange = (e, index) => {
    loanStore.setSelectedTabIndex(index);
  };

  const toolbar = (
    <Tabs
      tabs={tabs}
      selectedTab={loanStore.selectedTabIndex}
      onTabChange={onTabChange}
      right={
        <Box mr={2}>
          <FilterButton
            onToggleFilters={() => gridStore.toggleFilters()}
            onClearFilters={() => gridStore.resetFilters()}
          />
        </Box>
      } />
  );

  return (
    <DataGrid
      columns={columns}
      rows={gridStore.gridData.data.rows}
      frameworkComponents={frameworkComponents}
      toolbar={toolbar}
      isLoading={gridStore.isLoading}
      sortModel={gridStore.sortModel}
      filterModel={gridStore.filterModel}
      showFilters={gridStore.showFilters}
      paginationData={gridStore.gridData.meta}
      setPageNumber={gridStore.setPageNumber}
      onFilterChanged={onFilterChanged}
      onSortChanged={onSortChanged}
    />
  );
});
