import { Page } from '@roc/client-portal-shared/components';
import { Route } from 'react-router';
import { PayoffsDashboard } from '../payoffsDashboard/payoffsDashboard';
export const getPayoffsRoutesConfig = (
  basePath: string,
  baseUrl: string
) => {
  return {
    payoffs: {
      path: `${basePath}/payoffs`,
      url: `${baseUrl}/payoffs`,
      documentTitle: 'View Payoffs',
    },
  };
};

export const getPayoffsRoutes = (basePath: string, baseUrl: string) => {
  const config = getPayoffsRoutesConfig(basePath, baseUrl);

  return {
    payoffs: (
      <Route exact path={config.payoffs.path}>
        <Page routeDefinition={config.payoffs}>
          <PayoffsDashboard />
        </Page>
      </Route>
    ),
  };
};