import React, { useEffect, useState } from 'react';
import {
  Typography,
  Divider,
  Box,
  Grid,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import moment from 'moment';
import { Button, TextField, DateField } from '@roc/ui';
import { BackgroundItemStore } from '../stores/backgroundItemStore';

interface NewLateHistoryItemFormProps {
  store: BackgroundItemStore;
  handleClose: () => void;
  subtitle: string;
  index?: number;
  record?: string;
}

const NewLateHistoryItemForm = ({ store, handleClose, subtitle, index, record }: NewLateHistoryItemFormProps) => {
  const [count, setCount] = useState(0);
  const [itemName, setItemName] = useState('');
  const [mostRecent, setMostRecent] = useState<any>('');
  const [mostAged, setMostAged] = useState<any>('');
  const [accountType, setAccountType] = useState('');
  const [isItemFlagged, setIsItemFlagged] = useState('no');

  useEffect(() => {
    if (index >= 0) {
      if (record) {
        setItemName(store.backgroundSectionDetails.backgroundSection[record][index].item);
        setCount(store.backgroundSectionDetails.backgroundSection[record][index].count);
        setMostRecent(store.backgroundSectionDetails.backgroundSection[record][index].mostRecentDate);
        setMostAged(store.backgroundSectionDetails.backgroundSection[record][index].mostAgedDate);
        setAccountType(store.backgroundSectionDetails.backgroundSection[record][index].accountType);
        setIsItemFlagged(store.backgroundSectionDetails.backgroundSection[record][index].loefFlag ? 'yes' : 'no');
      }
    }
  }, [])

  const handleSubmit = (e: React.FormEvent, record?: string) => {
    e.preventDefault();

    if (index >= 0) {
      if (record) {
        store.editLHRecordItem(itemName, count, mostRecent, mostAged, accountType, setFlagValue(isItemFlagged), record, index);
      }
    }
    else {
      if (record) {
        store.addLHRecordItem(itemName, count, mostRecent, mostAged, accountType, setFlagValue(isItemFlagged), record)
      }
    }
    handleClose();
  };

  const setFlagValue = (isItemFlagged: string) => {
    if (isItemFlagged === 'yes') {
      return true
    } else return null;
  }

  const handleCancel = (e: React.FormEvent) => {
    handleClose();
  };

  return (
    <form onSubmit={(e) => { handleSubmit(e, record) }}>
      <Typography variant='h5'>Review Item</Typography>
      <Divider />
      <Typography variant='h6'>{subtitle}</Typography>
      <TextField
        type="text"
        variant="outlined"
        label="Item Name"
        value={itemName}
        onChange={(e) => setItemName(e.target.value)}
        fullWidth
        margin="normal"
        required
        testId="shareUrl"
      />
      <TextField
        type="number"
        variant="outlined"
        label="Count"
        value={count}
        onChange={(e) => setCount(Number(e.target.value))}
        fullWidth
        margin="normal"
        required
        testId="shareUrl"
      />
      <DateField
        label="Most Recent Date"
        inputVariant="outlined"
        value={mostRecent && moment(mostRecent).isValid() ? new Date(mostRecent) : null}
        onChange={(date) => {
          setMostRecent(date)
        }}
        required
        testId="mostRecent"
        format="MM/dd/yyyy"
        fullWidth={true}
        margin="normal"
        InputLabelProps={{ shrink: true }}
      />

      <DateField
        label="Most Aged Date"
        inputVariant="outlined"
        value={mostAged && moment(mostAged).isValid() ? new Date(mostAged) : null}
        onChange={(date) => {
          setMostAged(date)
        }}
        required
        testId="mostAged"
        format="MM/dd/yyyy"
        fullWidth={true}
        margin="normal"
        InputLabelProps={{ shrink: true }}
      />
      <TextField
        type="string"
        variant="outlined"
        label="Account Type"
        value={accountType}
        onChange={(e) => setAccountType(e.target.value)}
        fullWidth
        margin="normal"
        required
        testId="shareUrl"
      />
      <FormLabel component="legend">Is Item Flagged?</FormLabel>
      <RadioGroup
        row
        value={isItemFlagged}
        onChange={(e) => setIsItemFlagged(e.target.value)}
      >
        <FormControlLabel value="yes" control={<Radio />} label="Yes" />
        <FormControlLabel value="no" control={<Radio />} label="No" />
      </RadioGroup>
      <Box marginTop={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Button onClick={handleCancel} variant="outlined" color="primary" testId="cancel" fullWidth>
              Cancel
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button type="submit" variant="contained" color="primary" testId="save" fullWidth>
              Save
            </Button>
          </Grid>
        </Grid>
      </Box>
    </form>
  );
};

export default NewLateHistoryItemForm;