import { Box, Grid } from '@material-ui/core';
import { observer } from 'mobx-react';
import { TitleDivider } from '../../../components/titleDivider';
import { BorrowerFormStore } from '../../../stores/borrowers/borrowerFormStore';
import {
  Assets,
  BorrowerAddress,
  BorrowerEmail,
  CitizenshipStatus,
  CreditPull,
  DateOfBirth,
  FicoScore,
  FirstNameAutocompleteField,
  IsRealEstateBrokerAgent,
  IsTheBorrowerAGeneralContractor,
  LastName,
  Liabilities,
  NetWorth,
  NumberOfVerifiedInvestmentProperties,
  OwnershipPercentage,
  PersonalGuarantor,
  PhoneNumber,
  SocialSecurityNumber,
  Xactus360Upload,
} from '../../borrowerInformation/components/borrowerInformationFields';
import { FormHeader } from '../../common/formHeader';
import { PermIdentityOutlined } from '@material-ui/icons';
import { useEffect } from 'react';

export const PrefligthBorrowerForm = observer(
  ({
    store,
    showPersonalGuarantorField,
  }: {
    store: BorrowerFormStore;
    showPersonalGuarantorField: boolean;
  }) => {
    const fundedBorrower = store.form.fields.projectsCompleted.value;

    const firstName = store.form.fields.firstName.value;

    useEffect(() => {
      if (firstName) {
        store.findBorrowersByNameDebounced(firstName);
      }
    }, [firstName]);

    return (
      <Grid container spacing={2}>
        <FormHeader icon={PermIdentityOutlined} title="Borrower" />
        <Grid item xs={12}>
          <Box px={12}>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6}>
                <FirstNameAutocompleteField store={store} disabled={true} required />
              </Grid>
              <Grid item xs={12} sm={6}>
                <LastName store={store} disabled={true} />
              </Grid>
              <Grid item xs={12}>
                <BorrowerEmail store={store} disabled={true} required />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FicoScore store={store} disabled={!Boolean(store?.selectedRecord['ficoScore']) ? false : fundedBorrower} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <NumberOfVerifiedInvestmentProperties store={store} disabled={!Boolean(store?.selectedRecord['numberOfVerifiedInvestmentProperties']) ? false : fundedBorrower} />
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box px={2}>
            <TitleDivider title="Additional Options" />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box px={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <CreditPull store={store} creditStatus={store.creditStatus} />
              </Grid>
              {showPersonalGuarantorField && (
                <Grid item xs={12}>
                  <PersonalGuarantor store={store} />
                </Grid>
              )}
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box px={2}>
            <TitleDivider title="Ownership" />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box px={12}>
            <OwnershipPercentage store={store} />
          </Box>
        </Grid>
      </Grid>
    );
  }
);
