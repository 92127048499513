import { ConfirmDialog } from '@roc/ui';
import { observer } from 'mobx-react';
import { useLoanActionsStore } from '../hooks/useLoanActionsStore';

export const MarkTitleCompanyContactedModal = observer(
  ({ open, handleClose }) => {
    const { loanActionsStore } = useLoanActionsStore();

    const confirm = () => {
      loanActionsStore.markTitleCompanyContacted();
      handleClose();
    };

    return (
      <ConfirmDialog
        open={open}
        confirmButtonText={'Yes'}
        handleClose={handleClose}
        handleCancel={handleClose}
        handleConfirm={confirm}
        title={'Confirm title company contacted'}
        body={`Are you sure you want to confirm that the title company has been contacted?`}
      />
    );
  }
);
