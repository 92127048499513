import { lighten, makeStyles, Theme } from '@material-ui/core/styles';
import { LoginLogoImg } from '@roc/feature-app-core';
import { PrintableArea } from '@roc/ui';
import { observer } from 'mobx-react';
import { forwardRef } from 'react';
import { formatCurrency, formatPercentage } from '@roc/ui/utils';
import { Divider } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  page: {
    color: theme.palette.text.primary,
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'verdana, arial, sans-serif',
    lineHeight: '1',
    fontSize: '9.5pt',
    position: 'relative',

    ['& p']: {
      margin: '1em 0',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  table: {
    borderCollapse: 'collapse',
    ['& td']: {
      borderCollapse: 'collapse',
      padding: '5pt',
      border: '0.25pt solid ' + lighten(theme.palette.primary.dark, 0.5),
      width: '25%',
    },
    ['& th']: {
      color: 'black',
      textAlign: 'left',
      padding: '12px 0px',
    },
    ['& td:first-child']: {
      borderLeft: 0,
    },
    ['& td:last-child']: {
      borderRight: 0,
    },
  },
  tableInvis: {
    borderCollapse: 'collapse',
    ['& td']: {
      borderCollapse: 'collapse',
      padding: '5pt',
      border: 'none',
      width: '25%',
    },
    ['& th']: {
      color: 'black',
      textAlign: 'left',
      padding: '12px 0px',
    },
  },
  thickRow: {
    borderTop: '2pt solid ' + lighten(theme.palette.primary.dark, 0.3),
  },
  header: {
    paddingBottom: '0.1in',
  },
  logoContainer: {
    margin: 0,
  },
  headerLine: {
    display: 'flex',
    borderBottom: '4px solid ' + theme.palette.primary.main,
  },
  footer: {
    display: 'flex',
    flexDirection: 'column',
    justfyContent: 'flex-end',
    paddingTop: '0.2in',
  },
  title: {
    fontSize: '18pt',
    fontWeight: 'bold',
    paddingBottom: '2px',
  },
  subtitle: {
    fontSize: '18pt',
    paddingBottom: '12px',
  },
  bold: {
    fontWeight: 'bold',
  },
  sectionTitle: {
    paddingTop: '6px',
    paddingBottom: '6px',
  },
  darkBackground: {
    backgroundColor: theme.palette.primary.light,
    fontWeight: 'bold',
    textAlign: 'right',
    color: 'black',
  },
  note: {
    fontSize: '6pt',
    color: 'rgb(102 102 102)',
  },
  section: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    padding: '4pt 0',
  },
  showOnPrint: {
    display: 'none',
  },
  print: {
    //A4 size page
    width: '8.3in',
    height: '11.7in',

    //Add paddings
    padding: '0 0.65in',
    ['& $header']: {
      paddingTop: '0.6in',
    },

    ['& $footer']: {
      paddingBottom: '0.6in',
    },

    //Display hidden elements
    ['& $showOnPrint']: {
      display: 'block',
    },
  },
}));

export const TermSheetHtml = observer(
  forwardRef<any, any>(({ data, isQuote }, ref) => {
    const classes = useStyles();
    const propertyPages = [];
    for (let currentPage = 0; currentPage < data.properties.length; currentPage += 3) {
      const nextPage = currentPage + 4 > data.properties.length ? data.properties.length : currentPage + 3;
      propertyPages.push(data.properties.slice(currentPage, nextPage));
    }
    return (
      <PrintableArea printClass={classes.print} ref={ref}>
        <div className={classes.page}>
          <Header isQuote={isQuote} />
          <Content>
            {!isQuote && (
              <>
                <p>
                  Thank you for considering {data.lenderName}! This letter is to
                  inform you that the Borrower{data.borrowers.length > 1 ? 's, ' : ', '}
                  {data.borrowers.map((borrower, index) => (
                    <>
                      {borrower.fullName}{index < data.borrowers.length - 1 ? ', ' : ''}
                    </>
                  ))}, qualifies for bridge financing with {data.lenderName} for the {data.properties.length > 1 ? 'properties' : 'property'} located at:
                </p>
                <p>
                  {data.properties.map((property, index) => (
                    <>
                      {(index < 5) && (
                        <><b>{property?.address}</b>{index < data.properties.length - 1 ? (<br />) : ''}</>
                      )}
                    </>
                  ))}
                  <br />
                  {data.properties.length > 5 && (
                    <>
                      ...and {data.properties.length - 5} other {data.properties.length - 5 > 1 ? 'properties' : 'property'}
                    </>
                  )}
                </p>
                <p>
                  In this letter, you'll find a summary of the information shared
                  with us. Please note that final terms are subject to change and
                  will be determined by Underwriting and Credit Committee upon
                  reviewing a full due diligence package. This letter does not
                  represent an obligation to lend.
                </p>
                <p>We look forward to working with you!</p>
              </>
            )}
            {isQuote && (
              <p>
                Re: {data.properties.map((property, index) => (<b>{property.address}{index < data.properties.length - 1 ? ', ' : ''}</b>))}
                <p>
                  Thank you for considering {data.lenderName}! After reviewing the
                  information provided, below is a summary of our proposed terms.
                  Contact us with any questions, and we look forward to working
                  with you!
                </p>
              </p>
            )}
            <br />
            <Divider />
            <br />
            <InformationSharedWithUs data={data} isQuote={isQuote} />
            <br />
            <ConditionalTerms data={data} isQuote={isQuote} />
          </Content>
          <Footer className={classes.showOnPrint} />
        </div>
        <div className={classes.page}>
          <Header className={classes.showOnPrint} />
          <br />
          <Content>
            <InformationSharedWithUsBorrower data={data} isQuote={isQuote} />
            <br />
          </Content>
          <Footer className={classes.showOnPrint} />
        </div>
        {propertyPages.map(propertyList => (
          <div className={classes.page}>
            <Header className={classes.showOnPrint} />
            <br />
            <Content>
              <InformationSharedWithUsProperty data={data} isQuote={isQuote} propertyList={propertyList} />
              <br />
            </Content>
            <Footer className={classes.showOnPrint} />
          </div>
        ))}
        <div className={classes.page}>
          <Header className={classes.showOnPrint} />
          <Content>
            <div className={classes.section}>Estimated Closing Costs</div>
            <p>
              All fees and terms presented are estimates and subject to change
              based on final loan underwriting.
            </p>
            <table className={classes.table}>
              <tr>
                <th colSpan={2}>Closing Expenses</th>
                <th colSpan={2}>Definitions</th>
              </tr>
              <tr className={classes.thickRow}>
                <td rowSpan={2}>Purchase Price or Payoff Amount</td>
                <td>Acquisition Price</td>
                <td>{data.acquisitionPrice}</td>
                <td></td>
              </tr>
              <tr>
                <td>Existing Debt Payoff </td>
                <td>{data.existingDebtPayoff}</td>
                <td></td>
              </tr>
              <tr>
                <td rowSpan={1}>Reserves & Escrows</td>
                <td>Interest Reserve</td>
                <td>{data.interestReserve}</td>
                <td>
                  {data.interestReserveMonths ? `${data.interestReserveMonths} months of prepaid Interest` : ''}
                </td>
              </tr>
              <tr>
                <td rowSpan={3}>Lender Fees</td>
                <td>Origination Fees</td>
                <td>{data.lenderOriginationFees}</td>
                <td></td>
              </tr>
              <tr>
                <td>Underwriting Fee</td>
                <td>{data.underwritingFee}</td>
                <td></td>
              </tr>
              <tr>
                <td>Processing Fee</td>
                <td>{data.processingFee}</td>
                <td></td>
              </tr>
              <tr>
                <td rowSpan={4}>Third-Party Fees</td>
                <td>Broker Origination Fees</td>
                {isQuote ? (
                  <td>{`${!data.brokerPointsAtOrigination || data.amount === 0 ? 'N/A' :
                    formatCurrency(Number(data.amount) * Number(data.brokerPointsAtOrigination) / 100)}
                    (${!data.brokerPointsAtOrigination || data.brokerPointsAtOrigination === 0 ? 'N/A' : formatPercentage(data.brokerPointsAtOrigination)})`}</td>
                ) : (
                  <td>{`${data.brokerOriginationFees} (${!data.brokerOriginationFeesPercentage || data.brokerOriginationFeesPercentage === '0.00%' ? 'N/A' : data.brokerOriginationFeesPercentage})`}</td>
                )}
                <td></td>
              </tr>
              <tr>
                <td>Servicing Setup Fee</td>
                <td>{data.servicingSetupFee}</td>
                <td></td>
              </tr>
              <tr>
                <td>Legal Review Fee</td>
                <td>{data.legalReviewFee}</td>
                <td>Attorney document preparation & review</td>
              </tr>
              {data.thirdPartyEstimatedClosingCost &&
                <>
                  <tr>
                    <td>Third-Party Closing Costs</td>
                    <td>{data.thirdPartyEstimatedClosingCost}</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td colSpan={2}>Estimated Borrower Cash at Close</td>
                    <td colSpan={2}>{data.estimatedBorrowerCashAtClose}</td>
                  </tr>
                </>
              }
            </table>
          </Content>
          <Footer />
        </div>
      </PrintableArea>
    );
  })
);

const InformationSharedWithUs = ({ data, isQuote = false }) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.section}>Loan Information Shared With Us:</div>
      <table className={classes.tableInvis}>
        <tr>
          <td><strong>Loan Program:</strong> {data.loanProgram}</td>
          <td><strong>Loan Purpose:</strong> {data.loanPurpose}</td>
        </tr>
      </table>
      <br />
    </>
  );
};

const InformationSharedWithUsProperty = ({ data, isQuote = false, propertyList }) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.section}>Property Information Shared With Us:</div>
      {propertyList?.map(property => (
        <>
          <div className={classes.sectionTitle}><strong>Property Address:</strong> {property.address}</div>
          <table className={classes.table}>
            <tr>
              <td className={classes.bold}>Property Type:</td>
              <td>{property.propertyType}</td>
              <td className={classes.bold}># of Units:</td>
              <td>{property.units}</td>
            </tr>
            <tr>
              <td className={classes.bold}>Purchase Date:</td>
              <td>{property.purchaseDate}</td>
              <td className={classes.bold}>Purchase Price:</td>
              <td>{property.purchasePrice}</td>
            </tr>
            <tr>
              <td className={classes.bold}>Total Renovation Budget:</td>
              <td>{property.renovationBudget}</td>
              <td className={classes.bold}>Estimated As-Is Value*:</td>
              <td>{property.asIsValue}</td>
            </tr>
            <tr>
              <td className={classes.bold}>Completed Reno To-Date:</td>
              <td>{property.completedRenoToDate}</td>
              <td className={classes.bold}>Estimated ARV*:</td>
              <td>{property.afterRepairValue}</td>
            </tr>
            <tr>
              <td className={classes.bold}>Exit Strategy:</td>
              <td>{property.exitStrategy}</td>
              <td className={classes.bold}>Estimated Payoff Amount:</td>
              <td>{property.existingDebtPayoff}</td>
            </tr>
          </table>
          <br />
        </>
      ))}
      <div className={classes.note} style={{ paddingTop: '2pt' }}>
        * Final As-Is and After-Repair values will be determined by third-party
        valuation reports satisfactory to Lender
      </div>
    </>

  );
};

const InformationSharedWithUsBorrower = ({ data, isQuote = false }) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.section}>Borrower Information Shared With Us:</div>
      <div className={classes.sectionTitle}><strong>Entity Name:</strong> {data.borrowerEntity}</div>
      {data.borrowers?.map(borrower => (
        <>
          <div className={classes.sectionTitle}><strong>Borrower:</strong> {borrower.fullName}</div>
          <table className={classes.table}>
            {isQuote ? (
              <tr>
                <td className={classes.bold}>Estimated FICO Score:</td>
                <td>{borrower.ficoScore}</td>
                <td className={classes.bold}>Experience Score:</td>
                <td>{borrower.experienceScore}</td>
              </tr>
            ) : (
              <>
                <tr>
                  <td className={classes.bold}>% of Ownership of Entity:</td>
                  <td>{borrower.percentageOfOwnership}</td>
                  <td className={classes.bold}>Experience Score:</td>
                  <td>{borrower.experienceScore}</td>
                </tr>
                <tr>
                  <td className={classes.bold}>Citizenship Status:</td>
                  <td>{borrower.citizenshipStatus}</td>
                  <td className={classes.bold}>Estimated FICO Score:</td>
                  <td>{borrower.ficoScore}</td>
                </tr>
              </>
            )}
          </table>
          <br />
        </>
      ))}
    </>
  );
};

const ConditionalTerms = ({ data, isQuote = false }) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.section}>The Conditional Terms We Can Offer:</div>
      <table className={classes.table}>
        <tr className={classes.thickRow}>
          <td className={classes.bold}>Loan Amount:</td>
          <td>
            {data.totalLoanAmount}
          </td>
          <td className={classes.bold}>Initial Loan Amount:</td>
          <td>{data.initialLoanAmount}({data.initialLTC})</td>
        </tr>
        <tr>
          <td className={classes.bold}>Construction Holdback:</td>
          <td>
            {data.constructionHoldback} (
            {data.constructionHoldbackToRenovationBudgetPercentage})
          </td>
        </tr>
        {isQuote ? (
          <>
            <tr>
              <td className={classes.bold}>Loan Term (Months):</td>
              <td>{data.duration}</td>
              <td className={classes.bold}>Interest Rate:</td>
              <td>{formatPercentage(data.interestRate)}</td>
            </tr>
            <tr>
              <td className={classes.bold}>Interest Reserve:</td>
              <td>
                {data.interestReserve}
                {data.interestReserveMonths ? ` (${data.interestReserveMonths} Months)` : ''}
              </td>
            </tr>
          </>
        ) : (
          <>
            <tr>
              <td className={classes.bold}>Loan Term (Months):</td>
              <td>{data.duration}</td>
              <td className={classes.bold}>Pledge of Shares:</td>
              <td>{data.pledgeOfShares}</td>
            </tr>
            <tr>
              <td className={classes.bold}>Interest Rate:</td>
              <td>{data.interestRate}</td>
              <td className={classes.bold}>{`${data.interestReserveFinanced ? 'Financed Interest Reserve:' : 'Standard Interest Reserve:'}`}</td>
              <td>{`${data.interestReserve} (${data.interestReserveMonths} Months)`}</td>
            </tr>
          </>
        )}
        <tr>
          <td className={classes.bold}>Lender Origination Fees:</td>
          <td>{data.lenderOriginationFees + " (" + data.lenderOriginationFeesPercentage + ")"}</td>
          <td className={classes.bold}>Interest Payment Type:</td>
          <td>{data.interestMethod}</td>
        </tr>
        <tr>
          <td className={classes.bold}>Processing Fee:</td>
          <td>{data.processingFee}</td>
          <td className={classes.bold}>Underwriting Fee:</td>
          <td>{data.underwritingFee}</td>
        </tr>
        <tr>
          <td className={classes.bold}>Broker Name:</td>
          <td>{data.brokerName}</td>
          <td className={classes.bold}>Broker Fees:</td>
          {isQuote ? (
            <td>{data.brokerPointsAtOrigination === 0 ? 'N/A' : formatPercentage(data.brokerPointsAtOrigination)}</td>
          ) : (
            <td>{data.brokerOriginationFeesPercentage === '0.00%' ? 'N/A' : data.brokerOriginationFeesPercentage}</td>
          )}
        </tr>
      </table>
    </>
  );
};

const Header = ({ className = '', isQuote = false }) => {
  const classes = useStyles();
  return (
    <div className={`${classes.header} ${className}`}>
      <div className={classes.headerLine}>
        <div style={{ flex: 1 }}>
          {isQuote ? (
            <div className={classes.title}>Conditional Quote</div>
          ) : (
            <div className={classes.title}>Conditional Term Sheet</div>
          )}
          <div className={classes.subtitle}>Bridge Loan Program</div>
        </div>
        <div>
          <LoginLogoImg
            containerClassName={classes.logoContainer}
            imgStyle={{ height: '85px' }}
          />
        </div>
      </div>
    </div>
  );
};

const Footer = ({ className = '' }) => {
  const classes = useStyles();
  return (
    <div className={`${classes.footer} ${className}`}>
      <p className={classes.note}>
        Please note this letter of interest serves to outline the terms of the
        proposed financing of the referenced transaction.This letter is merely a
        general proposal, and is neither a binding offer, nor a contract.
        Borrower understands that no such offer will be forthcoming prior to
        completion of appropriate due diligence and underwriting performed
        and/or contracted by Lender. This proposal does not create any legally
        binding obligations on any party hereto. All properties are subject to
        satisfactory Lender due diligence underwriting including: satisfactory
        appraisal, satisfactory credit review of the borrowing entity and Key
        Principals, and satisfactory review of the property’s market and
        submarket.
      </p>
    </div>
  );
};
const Content = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.content}>{children}</div>;
};
