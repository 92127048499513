import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useStore } from '@roc/client-portal-shared/hooks';
import { Paper } from '@roc/ui';
import { Box, Chip, Typography } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';
import { PORTAL_COLORS } from '@roc/client-portal-shared/utils';
import { useLoanStore } from '@roc/feature-loans';
import { useUserStore } from '@roc/feature-app-core';

const PIPELINE_TAB = 0;

export const UnreadMessages = observer(() => {
  const { loanStore } = useLoanStore();
  const { userStore } = useUserStore();
  const { numberUnreadMessages, selectedTabIndex } = loanStore;
  const showMessage =
    numberUnreadMessages > 0 && selectedTabIndex === PIPELINE_TAB;
  const allowLoanPricing = userStore.allowLoanPricing;

  useEffect(() => {
    loanStore.fetchTotalUnreadMessages();
  }, []);

  return (
    <div>
      {(showMessage && allowLoanPricing) && (
        <Paper>
          <Box p={2}>
            <Typography style={{ display: 'flex', alignItems: 'center' }}>
              <InfoOutlined
                style={{ color: PORTAL_COLORS.unreadMessages, marginRight: 8 }}
              />
              You have{' '}
              <Chip
                size="small"
                style={{
                  margin: '0 4px',
                  color: 'white',
                  backgroundColor: PORTAL_COLORS.unreadMessages,
                }}
                label={numberUnreadMessages}
                variant="outlined"
              />{' '}
              unread {numberUnreadMessages == 1 ? 'message' : 'messages'},
              please review below
            </Typography>
          </Box>
        </Paper>
      )}
    </div>
  );
});
