import { Layout } from '@roc/feature-app-core';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useParams } from 'react-router';
import { UnsubscribedFromReminders } from './components/unsubscribedFromReminders';
import { useStore } from '@roc/client-portal-shared/hooks';

export const BorrowerDetailsVerificationReminderEmail = observer(() => {
  const { borrowerDetailsVerificationStore } = useStore();
  const { uuid } = useParams<{ uuid: string }>();
  useEffect(() => {
    borrowerDetailsVerificationStore.unsubscribedFromDailyReminders(uuid);
  }, []);


  return (
    <Layout maxWidth={'lg'}>
      <UnsubscribedFromReminders />
    </Layout>
  );
});
