import { Box, Typography } from '@material-ui/core';
import { lighten, makeStyles, Theme } from '@material-ui/core/styles';
import { LoginLogoImg } from '@roc/feature-app-core';
import { LoanSubType, formatDate } from '@roc/feature-utils';
import { PrintableArea } from '@roc/ui';
import {
  formatCurrency as _formatCurrency,
  formatPercentage as _formatPercentage,
  getCompletePropertyAddress,
} from '@roc/ui/utils';
import clsx from 'clsx';
import { mapLtvToValue } from 'libs/feature-loans/src/loanSubmission/stores/common/pricerSummaryStore';
import { observer } from 'mobx-react';
import { forwardRef } from 'react';
import { amortizationOptions } from '../../utils/constants';
import { postLoanApprovalRateLockLabels, swapIndexLabels } from '../utils/constants';
import { DEFAULT_FEE_CORELOGIX, DEFAULT_FEE_LEGAL_REVIEW } from 'libs/feature-loans/src/loanSubmission/utils/constants';

const formatCurrency = value => {
  return value ? _formatCurrency(value) : 'N/A';
};

const formatPercentage = value => {
  return value ? _formatPercentage(value, 4) : 'N/A';
}

const useStyles = makeStyles((theme: Theme) => ({
  page: {
    color: theme.palette.text.primary,
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'verdana, arial, sans-serif',
    lineHeight: '1.2',
    fontSize: '9.5pt',
    position: 'relative',

    ['& p']: {
      margin: '1em 0',
    },
    ['& li']: {
      margin: '0.5em 0',
      marginLeft: '2em',
    },
    ['& sup']: {
      fontSize: '80%',
      verticalAlign: 'super',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  table: {
    borderCollapse: 'collapse',
    ['& td']: {
      borderCollapse: 'collapse',
      padding: '5pt',
      border: '0.25pt solid ' + lighten(theme.palette.primary.dark, 0.5),
    },
    ['& th']: {
      color: 'black',
      textAlign: 'center',
      padding: '12px 0px',
    },
    ['& td:first-child']: {
      borderLeft: 0,
    },
    ['& td:last-child']: {
      borderRight: 0,
    },
  },
  thickRow: {
    borderTop: '2pt solid ' + lighten(theme.palette.primary.dark, 0.3),
  },
  header: {
    paddingBottom: '0.2in',
  },
  logoContainer: {
    margin: 0,
  },
  headerLine: {
    borderBottom: '4px solid ' + theme.palette.primary.main,
  },
  footer: {
    display: 'flex',
    flexDirection: 'column',
    justfyContent: 'flex-end',
    paddingTop: '0.2in',
  },
  title: {
    fontSize: '18pt',
    fontWeight: 'bold',
    paddingBottom: '2px',
  },
  subtitle: {
    fontSize: '18pt',
    paddingBottom: '12px',
  },
  bold: {
    fontWeight: 'bold',
  },
  center: {
    textAlign: 'center',
  },
  darkBackground: {
    backgroundColor: theme.palette.primary.light,
    fontWeight: 'bold',
    textAlign: 'right',
    color: 'black',
  },
  note: {
    fontSize: '6pt',
    color: 'rgb(102 102 102)',
  },
  section: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    padding: '4pt 0',
  },
  showOnPrint: {
    display: 'none',
  },
  quotesTable: {
    ['& tr td']: {
      textAlign: 'center',
    },
    ['& tr td:first-child']: {
      fontWeight: 'bold',
      textAlign: 'left',
    },
  },
  informationSharedWithUsTable: {
    ['& td']: {
      width: '25%',
    },
  },
  warnings: {
    color: theme.palette.error.main,
  },
  print: {
    //A4 size page
    width: '8.3in',
    height: '11.7in',

    //Add paddings
    padding: '0 0.65in',
    ['& $header']: {
      paddingTop: '0.6in',
    },

    ['& $footer']: {
      paddingBottom: '0.6in',
    },

    //Display hidden elements
    ['& $showOnPrint']: {
      display: 'block',
    },
  },
}));

export const DownloadableQuoteHtml = observer(
  forwardRef<any, any>(
    ({ data, store, pricingOptions, rateBuydownOptions }, ref) => {
      const classes = useStyles();

      const lenderName = store.globalStore?.lenderInfo?.lenderTradeName;

      const fields = store.pricerStore.getFormValues();

      const propertyAddress = getCompletePropertyAddress(fields);

      return (
        <PrintableArea printClass={classes.print} ref={ref}>
          <div className={classes.page}>
            <Header />
            <Content>
              <div className={classes.headerLine}>
                <div className={classes.title}>Conditional Quote</div>
                <div className={classes.subtitle}>Rental Loan Program</div>
              </div>
              <p>
                <b>{fields.propertyStates ? `Re: ${data.numberOfProperties} Property Portfolio in ${fields.propertyStates}` : `Re: ${propertyAddress}`}</b>
              </p>
              <p>
                Thank you for considering {lenderName}! After reviewing the
                information provided, below is a summary of our proposed terms.
                Contact us with any questions, and we look forward to working
                with you!
              </p>
              <InformationSharedWithUs data={data} store={store} />
              <br />
            </Content>
            <Footer className={classes.showOnPrint} />
          </div>
          <div className={classes.page}>
            <Header className={classes.showOnPrint} />
            <Content>
              <TheConditionalTermsWeCanOffer
                store={store}
                data={data}
                pricingOptions={pricingOptions}
                rateBuydownOptions={rateBuydownOptions}
              />
              <br />
              <br />
              <IndexAndRateLockInformation data={data} />
              <br />
            </Content>
            <Footer />
          </div>
        </PrintableArea>
      );
    }
  )
);

const InformationSharedWithUs = ({ data, store }) => {
  const classes = useStyles();
  const fields = store.pricerStore.getFormValues();

  const propertyAddress = getCompletePropertyAddress(fields);

  return (
    <>
      <div className={classes.section}>Information Shared With Us:</div>
      <table
        className={clsx(classes.table, classes.informationSharedWithUsTable)}
      >
        <tr>
          <td className={classes.bold}>Property Address:</td>
          <td colSpan={3}>{data.loanSubtype === 'Single Property Rental' ? `${propertyAddress}` : `${data.numberOfProperties} Property Portfolio in ${fields.propertyStates}`}</td>
        </tr>
        <tr>
          <td className={classes.bold}>Estimated As-Is Value*:</td>
          <td>{formatCurrency(fields.propertyValuation || fields.totalEstimatedAsIsValue)}</td>
          <td className={classes.bold}>Property County:</td>
          <td>{data.propertyCounties?.map(countie => countie.replace(/, \w{2}$/, "")).join(", ")}</td>
        </tr>
        <tr>
          <td className={classes.bold}>Property Type:</td>
          <td>{Array.from(new Set(data.propertyTypes)).join(', ')}</td>
          <td className={classes.bold}>Loan Purpose:</td>
          <td>{fields.loanPurpose}</td>
        </tr>
        <tr>
          <td className={classes.bold}>Acquisition Date:</td>
          <td>{formatDate(fields.acquisitionDate, 'MMMM d, yyyy')}</td>
          <td className={classes.bold}>Total Acquisition Cost:</td>
          <td>{formatCurrency(fields.acquisitionPrice)}</td>
        </tr>
        <tr>
          <td className={classes.bold}>Completed Renovations:</td>
          <td>{fields.capitalImprovements}</td>
          <td className={classes.bold}>Monthly Expenses:</td>
          <td>
            {fields.totalAnnualExpenses
              ? formatCurrency(fields.totalAnnualExpenses / 12)
              : 'N/A'}
          </td>
        </tr>
        <tr>
          <td className={classes.bold}>Monthly Rent*:</td>
          <td>{formatCurrency(fields.monthlyGrossRent || fields.totalGrossMonthlyRent)}</td>
          <td className={classes.bold}>Borrower’s Experience:</td>
          <td>{fields.borrowerExp}</td>
        </tr>
        <tr>
          <td className={classes.bold}>Estimated Credit Score:</td>
          <td>{fields.ficoProvidedAtOrigination}</td>
          <td className={classes.bold}>Citizenship Status:</td>
          <td>{fields.citizenshipStatus}</td>
        </tr>
      </table>
      <div className={classes.note}>
        * Final As-Is and market rent values will be determined by third-party
        valuation reports satisfactory to Lender
      </div>
    </>
  );
};

const TheConditionalTermsWeCanOffer = ({
  data,
  store,
  pricingOptions,
  rateBuydownOptions,
}) => {
  const classes = useStyles();
  const { pricerSummaryStore, loanDetailsStore, globalStore } = store;
  const isEstimatedClosingCostEnabled = globalStore.userFeatures.isEstimatedClosingCostEnabled;

  const loanDetails = loanDetailsStore.getLoanDetails();

  const getThirdPartyClosingCostFromLtv = option => {
    return data.values.arm5['ltv'.concat(mapLtvToValue(option.ltvBand).toString())]?.thirdPartyClosingCost ?? 0;
  };

  const getSpreadOverIndex = option => {
    const { spreadRate } = option.rateRow?.[option.ltvBand] || {};
    return spreadRate ? `${spreadRate.toFixed(4)}%` : '';
  };

  const getTodayGrossRate = option => {
    const { spreadRate, swapRate } = option.rateRow?.[option.ltvBand] || {};
    return spreadRate && swapRate
      ? `${(spreadRate + swapRate).toFixed(4)}%`
      : '';
  };

  const getBuydownPoints = (buydown) => {
    const maxValueRatio = pricerSummaryStore.buydownRatio ? pricerSummaryStore.buydownRatio : pricerSummaryStore.buydownRatioStandard;
    return (buydown / maxValueRatio).toFixed(3)
  }

  function getTotalBuydownPoints(option) {
    const totalPoints = option.totalBuydownFees / option.pricerLoanTerms.amount * 100;
    return totalPoints.toFixed(3);
  }

  function arePricingOptionsEmpty(pricingOptions, field) {
    for (let option of pricingOptions) {
      if (option[field] && option[field] > 0) {
        return false;
      }
    };
    return true;
  }

  return (
    <>
      <div className={classes.section}>Compare Pricing Options:</div>
      <table className={clsx(classes.table, classes.quotesTable)}>
        <tr>
          <td style={{ width: '25%' }} />
          {pricingOptions.map((option, i) => (
            <td style={{ width: 75 / pricingOptions.length + '%' }}>
              <b>Option {i + 1}</b>
            </td>
          ))}
        </tr>
        <tr>
          <td>LTV</td>
          {pricingOptions.map((option, i) => (
            <td>
              <Box>{mapLtvToValue(option.ltvBand)}%</Box>
            </td>
          ))}
        </tr>
        <tr>
          <td>Loan Amount</td>
          {pricingOptions.map((option, i) => (
            <td>
              <Box>{formatCurrency(option.pricerLoanTerms.amount)}</Box>
            </td>
          ))}
        </tr>
        <tr>
          <td>Amortization</td>
          {pricingOptions.map((option, i) => (
            <td>
              <Box>
                {
                  amortizationOptions.find(
                    opt => opt.value == option.formValues.amortization
                  )?.label
                }
              </Box>
            </td>
          ))}
        </tr>
        <tr>
          <td>Pre-Payment Penalty</td>
          {pricingOptions.map((option, i) => (
            <td>
              <Box>
                {
                  (pricerSummaryStore.prepaymentPenaltyOptions || []).find(
                    opt => opt.value == option.formValues.prePaymentPenalty
                  )?.label
                }
              </Box>
            </td>
          ))}
        </tr>
        <tr>
          <td>Rate Type</td>
          {pricingOptions.map((option, i) => (
            <td>
              <Box>{option.rateRow.rateType}</Box>
            </td>
          ))}
        </tr>
        {/*
        <tr>
          <td>Rate Buydown Fee</td>
          {pricingOptions.map((option, i) => (
            <td>
              <Box>{getBuydownPoints(option.formValues.rateBuydown)}%</Box>
            </td>
          ))}
        </tr>
          */}
        <tr>
          <td>Index</td>
          {pricingOptions.map((option, i) => (
            <td>
              <Box>
                {swapIndexLabels[option.rateRow[option.ltvBand].swapIndex]}
              </Box>
            </td>
          ))}
        </tr>
        <tr>
          <td>Spread Over Index</td>
          {pricingOptions.map((option, i) => (
            <td>
              <Box>{getSpreadOverIndex(option)}</Box>
            </td>
          ))}
        </tr>
        <tr>
          <td>Today's Gross Rate</td>
          {pricingOptions.map((option, i) => (
            <td>
              <Box>{getTodayGrossRate(option)}</Box>
            </td>
          ))}
        </tr>
        <tr>
          <td>Monthly Mortgage Payment (Principal and Interest Payments)</td>
          {pricingOptions.map((option, i) => (
            <td>
              <Box>{formatCurrency(option.pricerLoanTerms.monthlyPaymentWithoutExpenses)}</Box>
            </td>
          ))}
        </tr>
        <tr>
          <td>Post Loan Approval Rate Lock</td>
          {pricingOptions.map((option, i) => (
            <td>
              <Box>{postLoanApprovalRateLockLabels[option.programType]}</Box>
            </td>
          ))}
        </tr>
        {loanDetails.brokerPointsIn != null && loanDetails.brokerPointsIn != '' && loanDetails.brokerPointsIn !== 0 && (
          <tr>
            <td>Broker Fee</td>
            {pricingOptions.map((option, i) => (
              <td>
                <Box>
                  {formatPercentage(loanDetails.brokerPointsIn)}
                </Box>
              </td>
            ))}
          </tr>
        )}
        <tr>
          <td>Corelogix Fee</td>
          {pricingOptions.map((option, i) => (
            <td>
              <Box>
                {formatCurrency(DEFAULT_FEE_CORELOGIX)}
              </Box>
            </td>
          ))}
        </tr>
        {store.globalStore.userFeatures.pricerFeesEnabled && (
          <>
            <tr>
              <td>Origination Points</td>
              {pricingOptions.map((option, i) => (
                <td>
                  <Box>{formatPercentage(option.totalOriginationPoints)}</Box>
                </td>
              ))}
            </tr>
            {!arePricingOptionsEmpty(pricingOptions, 'totalProcessingFees') && (
              <tr>
                <td>Processing Fees</td>
                {pricingOptions.map((option, i) => (
                  <td>
                    <Box>
                      {formatCurrency(option.totalProcessingFees)}
                    </Box>
                  </td>
                ))}
              </tr>
            )}
            {!arePricingOptionsEmpty(pricingOptions, 'totalAdminFees') && (
              <tr>
                <td>Admin Fees</td>
                {pricingOptions.map((option, i) => (
                  <td>
                    <Box>
                      {formatCurrency(option.totalAdminFees)}
                    </Box>
                  </td>
                ))}
              </tr>
            )}
            {!arePricingOptionsEmpty(pricingOptions, 'totalUnderwritingFees') && (
              <tr>
                <td>Underwriting Fees</td>
                {pricingOptions.map((option, i) => (
                  <td>
                    <Box>
                      {formatCurrency(option.totalUnderwritingFees)}
                    </Box>
                  </td>
                ))}
              </tr>
            )}
            {!arePricingOptionsEmpty(pricingOptions, 'totalCommitmentFees') && (
              <tr>
                <td>Commitment Fees</td>
                {pricingOptions.map((option, i) => (
                  <td>
                    <Box>
                      {formatCurrency(option.totalCommitmentFees)}
                    </Box>
                  </td>
                ))}
              </tr>
            )}
            {!arePricingOptionsEmpty(pricingOptions, 'totalBuydownFees') && (
              <tr>
                <td>Buydown Points</td>
                {pricingOptions.map((option, i) => (
                  <td>
                    <Box>
                      {formatPercentage(getTotalBuydownPoints(option))}
                    </Box>
                  </td>
                ))}
              </tr>
            )}
          </>
        )}
        {isEstimatedClosingCostEnabled &&
          <tr>
            <td>Estimated Borrower Cash at Close:</td>
            {pricingOptions.map((option, i) => (
              <td>
                <Box>
                  {formatCurrency(
                    ((option.pricerLoanTerms.loanPurpose.value === 'Refinance' ? option.pricerLoanTerms.totalDebtPayoff.value ?? 0 : option.pricerLoanTerms.acquisitionPrice.value ?? 0) +
                      (getThirdPartyClosingCostFromLtv(option))) -
                    option.pricerLoanTerms.amount ?? 0
                  )}
                </Box>
              </td>
            ))}
          </tr>
        }
      </table>
    </>
  );
};

const IndexAndRateLockInformation = ({ data }) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.section}>Index and Rate Lock Information:</div>
      <table className={classes.table}>
        <tr className={classes.thickRow}>
          <td style={{ width: '25%' }}>
            <b>USD 3M Libor US Treasury Rates as of {data.todayDate}</b>
          </td>
          <td>
            5 Yr US Treasury: <b>{data.swapRateMap.fiveYrTsrySwap}%</b>
            <br />
            10 Yr US Treasury: <b>{data.swapRateMap.tenYrTsrySwap}%</b>
          </td>
        </tr>
        <tr>
          <td colSpan={2}>
            <b>Please refer to Rental Loan Rates FAQs for more information</b>
            <ul>
              <li>
                Indicative Gross Rates are calculated as the sum of (1) value of
                the Index today + (2) Spread over Index.
                <b>
                  <u>
                    They represent today's rates, based on the information
                    submitted at pricing.
                  </u>
                </b>
              </li>
              <li>
                Spread over Index will remain locked for a period of 30 days
                from loan submission date, provided{' '}
                <b>
                  <u>
                    the information submitted at pricing is verified during
                    document collection and underwriting.
                  </u>
                </b>
              </li>
              <li>
                Gross rates are determined at the time of credit approval,
                calculated as the sum of the (1) value of the Index as of the
                date of credit approval, and (2) Spread over Index.
              </li>
              <li>
                Spread over Index will remain unchanged from loan submission if
                (1) rate setting parameters for the loan do not change from
                submission and (2) credit approval occurs within 30 days of loan
                submission
              </li>
            </ul>
          </td>
        </tr>
      </table>
    </>
  );
};

const Header = ({ className = '', isQuote = false }) => {
  const classes = useStyles();
  return (
    <div className={`${classes.header} ${className}`}>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <LoginLogoImg
          containerClassName={classes.logoContainer}
          imgStyle={{ height: '0.8in' }}
        />
      </div>
    </div>
  );
};

const Footer = ({ className = '' }) => {
  const classes = useStyles();
  return (
    <div className={`${classes.footer} ${className}`}>
      <p className={classes.note}>
        Please note this letter of interest serves to outline the terms of the
        proposed financing of the referenced transaction.This letter is merely a
        general proposal, and is neither a binding offer, nor a contract.
        Borrower understands that no such offer will be forthcoming prior to
        completion of appropriate due diligence and underwriting performed
        and/or contracted by Lender. This proposal does not create any legally
        binding obligations on any party hereto. All properties are subject to
        satisfactory Lender due diligence underwriting including: satisfactory
        appraisal, satisfactory credit review of the borrowing entity and Key
        Principals, and satisfactory review of the property’s market and
        submarket.
      </p>
    </div>
  );
};
const Content = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.content}>{children}</div>;
};
