import { useEffect } from "react";
export const ReferralFeeDocusignCallback = () => {
  useEffect(() => {
    const { search } = location || {};
    if (search) {
      const { origin } = window.parent.location;
      const parsed = search.replace('?', '').split('=');
      if (parsed[1] === 'signing_complete') {
        window.parent.postMessage('referral-docusign-success', origin);
      } else {
        window.parent.postMessage('referral-docusign-fail', origin);
      }
    }
  }, []);
  return (<></>)
}