import {
  Box,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import {
  Layout,
  Page,
  useBaseStore,
  useMiscellaneousRoutes,
} from '@roc/feature-app-core';
import {
  ConversationsDialog,
  FilterType,
  LoanCommunicationStore,
  SelectConversation,
  useCommunicationRoutesConfig,
  useLoanCommunicationStore,
} from '@roc/feature-communication';
import { useLoanStore } from '@roc/feature-loans';
import { useLoanRoutes } from '@roc/feature-loans-routes-config';
import {
  CLOSING,
  getDocumentSection,
  getDrawId,
  insertIf,
  SERVICING,
  UNDERWRITING,
} from '@roc/feature-utils';
import { CopyText, NotFound, TooltipOnOverflow } from '@roc/ui';
import { observer } from 'mobx-react';
import { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
} from 'react-router';
import { LoanDetailsTabs } from './components/loanDetailsTabs';
import { useLoanFlags } from './hooks/useLoanFlags';
import { ClosingTab } from './tabs/closingTab';
import { CrmLoanSummaryTab } from './tabs/crmLoanSummaryTab';
import { DecisionCommentsTab } from './tabs/decisionCommentsTab';
import { DrawsTab } from './tabs/drawsTab';
import { ExtensionsTab } from './tabs/extensionsTab';
import { LoanSummaryTab } from './tabs/loanSummaryTab';
import { PayoffsTab } from './tabs/payoffsTab';
import { UnderwritingTab } from './tabs/underwritingTab';
import { LoanDetailsFAB } from './components/LoanDetailsFAB';
import { LoanForeclosureRequest } from 'libs/feature-loans/src/loans/loanDetails/foreclosure/components/loanForeclosureRequest';
import { DrawRequest } from './components/drawRequest';
import { ReviseScopeOfWork } from './components/reviseScopeOfWork';
import { DrawDetails } from './components/drawDetails';
import { LoanTitleAgentDetails } from '@roc/feature-title-agent';
import { LoanContacts } from 'libs/feature-loans/src/loans/loanDetails/modules/loanContacts/loanContacts';
import { PostClosingTab } from './tabs/postClosingTab';

const useStyles = makeStyles((theme: Theme) => ({
  loanDetailsContainer: {
    width: 'calc(100vw - 20px)',
    margin: 'auto'
  },
  paper: {
    boxShadow: '0px 4px 10px rgba(0,0,0,0.16)',
    overflow: 'hidden',

    '&.MuiPaper-rounded': {
      borderRadius: theme.spacing(2),
    },
  },
  tabs: {
    border: '0 !important',
    padding: theme.spacing(0, 2),
  },
  noBorder: {
    border: '0 !important',
  },
  loanTitle: {
    textWrap: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: '75vw',
    marginBottom: '10px'
  }
}));

export const LoanDetailsComponent = observer(
  ({ renderDrawDetails, renderExtensionDetails, renderPayoffScreen, renderLoanClosingCondition, renderRepeatPropertyWarning, onOpenAddTask }) => {
    const classes = useStyles();
    const { allowLoanPricing, showDelinquency, showPostClosing } = useLoanFlags();
    const { loanRoutesConfig } = useLoanRoutes();
    const { communicationRoutesConfig } = useCommunicationRoutesConfig();
    const { miscellaneousRoutes } = useMiscellaneousRoutes();
    const { loanStore } = useLoanStore();
    const { globalStore } = useBaseStore();
    const { showDecisionCommentsTab, showCrmLoanSummaryView } = useLoanFlags();
    const { loanDetails } = loanStore;
    const { loanId } = useParams<{ loanId: string }>();
    const { loanCommunicationStore } = useLoanCommunicationStore();
    const { pathname, state } = useLocation<{ from?: string }>();
    const documentSection = getDocumentSection(pathname);
    const history = useHistory();
    const drawId = getDrawId(pathname);
    const loanConditionsToRender = renderLoanClosingCondition();
    const repeatedPropertyWarning = renderRepeatPropertyWarning();

    const [showConversations, setShowConversations] = useState(false);


    useEffect(() => {
      const loadConversations = () => {
        loanCommunicationStore.reset();
        loanCommunicationStore.setFilter({ type: FilterType.LOAN_ID, value: loanId });
        loanCommunicationStore.loadMyConversations(1, 100, (totalCount) => {
          if (totalCount > 0) {
            setShowConversations(true);
          }
        }, () => null);
      };

      if (globalStore.userFeatures?.isBroker) {
        loadConversations();
      } else if (allowLoanPricing) {
        setShowConversations(true);
      }
    }, []);

    const routes = useMemo(
      () => [
        {
          routeDefinition: loanRoutesConfig.loans(loanId).children.dashboard,
          component: (
            <Dashboard showCrmLoanSummaryView={showCrmLoanSummaryView} />
          ),
        },
        {
          routeDefinition: loanRoutesConfig.loans(loanId).children.underwriting,
          component: <UnderwritingTab />,
        },
        {
          routeDefinition: loanRoutesConfig.loans(loanId).children.closing,
          component: <ClosingTab />,
        },
        {
          routeDefinition: loanRoutesConfig.loans(loanId).children.draws,
          component: <DrawsTab />,
        },
        {
          routeDefinition: loanRoutesConfig.loans(loanId).children.extensions,
          component: <ExtensionsTab />,
        },
        {
          routeDefinition: loanRoutesConfig.loans(loanId).children.payoffs,
          component: <PayoffsTab renderPayoffScreen={renderPayoffScreen} />,
        },
        {
          routeDefinition: loanRoutesConfig.loans(loanId).children.drawDetails(drawId),
          component: <DrawDetails />
        },
        {
          routeDefinition: loanRoutesConfig.loans(loanId).children.reviseScopeOfWork,
          component: <ReviseScopeOfWork />
        },
        {
          routeDefinition: loanRoutesConfig.loans(loanId).children.drawRequest,
          component: <DrawRequest />
        },
        {
          routeDefinition: loanRoutesConfig.loans(loanId).children.loanContacts,
          component: (
            <Box marginX={'8px'} width={'100%'}> <LoanContacts /> </Box>
          ),
        },
        ...insertIf(showDecisionCommentsTab, [
          {
            routeDefinition: loanRoutesConfig.loans(loanId).children
              .decisionTab,
            component: <DecisionCommentsTab />,
          },
        ]),
        ...insertIf(showDelinquency, [
          {
            routeDefinition: loanRoutesConfig.loans(loanId).children
              .foreclosureRequest,
            component: <LoanForeclosureRequest loanId={loanId} />,
          },
          {
            routeDefinition: loanRoutesConfig.loans(loanId).children
              .foreclosureInvoice,
            component: <LoanTitleAgentDetails loanId={loanId} />,
          },
        ]),
        ...insertIf(showPostClosing, [
          {
            routeDefinition: loanRoutesConfig.loans(loanId).children.postClosing,
            component: <PostClosingTab />,
          }
        ]),
      ]
      , [loanId, showCrmLoanSummaryView, showDecisionCommentsTab, renderPayoffScreen, loanRoutesConfig, showDelinquency, showPostClosing]);

    function handleOpenConversationsDialog() {
      history.push(communicationRoutesConfig.conversations(loanId).url, { from: pathname });
    }

    function handleCloseConversationsDialog() {
      history.push(state?.from || loanRoutesConfig.loans(loanId).children.dashboard.url);
      loanCommunicationStore.fetchUnreadMessagesCount(loanId);
    }

    function handleConversationSelect(conversationId: string) {
      const path = communicationRoutesConfig.conversations(loanId).children.conversation(conversationId).url;
      try {
        history.replace(path, state);
      } catch (e) {
        history.push(path, { from: loanRoutesConfig.loans(loanId).children.dashboard.url });
      }
    }

    useEffect(() => {
      loanCommunicationStore.fetchUnreadMessagesCount(loanId);
    }, [loanId, loanCommunicationStore]);

    const loanTitle = `[${loanId}] - ${loanDetails?.dealName}`

    return (
      <Layout maxWidth="lg" containerClass={classes.loanDetailsContainer}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box display="flex">
              <TooltipOnOverflow title={loanTitle}>
                <Typography variant="h4" className={classes.loanTitle}>
                  [<CopyText>{loanId}</CopyText>]&nbsp;-&nbsp;{loanDetails?.dealName}
                </Typography>
              </TooltipOnOverflow>
            </Box>
          </Grid>
          {loanConditionsToRender != null &&
            <Grid className='' item xs={12}>
              {loanConditionsToRender}
            </Grid>
          }
          {repeatedPropertyWarning != null &&
            <Grid className='' item xs={12}>
              {repeatedPropertyWarning}
            </Grid>
          }

          <Grid item xs={12}>
            <Paper square className={classes.paper}>
              <LoanDetailsTabs />
            </Paper>
          </Grid>

          <Switch>
            <Route
              exact
              path={loanRoutesConfig.loans(loanId).children.pendingDocuments.path}
              render={() => <Redirect to={loanRoutesConfig.loans(loanId).children.underwriting.url} />}
            />
            <Route
              exact
              path={loanRoutesConfig.loans(loanId).children.loanSummary.path}
              render={() => <Redirect to={loanRoutesConfig.loans(loanId).children.dashboard.url} />}
            />
            <Route
              exact
              path={loanRoutesConfig.loans(loanId).children.commissions.path}
              render={() => <Redirect to={loanRoutesConfig.loans(loanId).children.dashboard.url} />}
            />
            <Route
              exact
              path={loanRoutesConfig.loans(loanId).children.documents(loanId, UNDERWRITING, documentSection).url}
              render={() => <Redirect to={loanRoutesConfig.loans(loanId).children.underwriting.url} />}
            />
            <Route
              exact
              path={loanRoutesConfig.loans(loanId).children.documents(loanId, CLOSING, 'overview').url}
              render={() => <Redirect to={loanRoutesConfig.loans(loanId).children.closing.url} />}
            />
            <Route
              exact
              path={loanRoutesConfig.loans(loanId).children.documents(loanId, CLOSING, documentSection).url}
              render={() => <Redirect to={loanRoutesConfig.loans(loanId).children.closing.url} />}
            />
            <Route
              exact
              path={loanRoutesConfig.loans(loanId).children.estimatedClosingCosts.path}
              render={() => <Redirect to={loanRoutesConfig.loans(loanId).children.dashboard.url} />}
            />
            <Route
              exact
              path={loanRoutesConfig.loans(loanId).children.documents(loanId, SERVICING, documentSection).url}
              render={() => <Redirect to={loanRoutesConfig.loans(loanId).children.postClosing.url} />}
            />
            <Route
              exact
              path={loanRoutesConfig.loans(loanId).children.properties.path}
              render={() => <Redirect to={loanRoutesConfig.loans(loanId).children.dashboard.url} />}
            />
            <Route
              exact
              path={loanRoutesConfig.loans(loanId).children.borrowerEntity.path}
              render={() => <Redirect to={loanRoutesConfig.loans(loanId).children.dashboard.url} />}
            />
            <Route
              exact
              path={loanRoutesConfig.loans(loanId).children.payoffRequest.path}
              render={() => <Redirect to={loanRoutesConfig.loans(loanId).children.payoffs.url} />}
            />
            <Route
              exact
              path={`${loanRoutesConfig.loans(loanId).path}/`}
              render={() => (
                <Redirect to={loanRoutesConfig.loans(loanId).children.dashboard.url} />
              )}
            />
            {routes.map(route => (
              <Route exact path={route.routeDefinition.path}>
                <Page routeDefinition={route.routeDefinition}>
                  {route.component}
                </Page>
              </Route>
            ))}
            <Route path={communicationRoutesConfig.conversations(loanId).path}>
              <Page routeDefinition={communicationRoutesConfig.conversations(loanId)}>
                <Dashboard showCrmLoanSummaryView={showCrmLoanSummaryView}>
                  <ConversationsDialog
                    open={true}
                    loanId={Number(loanId)}
                    onClose={handleCloseConversationsDialog}
                    communicationStore={loanCommunicationStore}
                    onConversationSelect={handleConversationSelect}
                    routePath={communicationRoutesConfig.conversations(loanId).children.conversation().path}
                    onOpenAddTask={onOpenAddTask}
                  />
                </Dashboard>
              </Page>
            </Route>

            <Box width={'100%'}>
              {renderExtensionDetails()}
            </Box>
            {miscellaneousRoutes.notFound}
            <Route>
              <NotFound />
            </Route>
          </Switch>
        </Grid>
        <LoanDetailsFAB
          loanId={Number(loanId)}
          isBroker={!!globalStore.userFeatures?.isBroker}
          showConversations={allowLoanPricing}
          loanCommunicationStore={loanCommunicationStore}
          onOpenConversations={handleOpenConversationsDialog} />
      </Layout>
    );
  }
);

function Dashboard({ showCrmLoanSummaryView, children }: PropsWithChildren<{ showCrmLoanSummaryView: boolean }>) {
  const component = useMemo(() => showCrmLoanSummaryView ? <CrmLoanSummaryTab /> : <LoanSummaryTab />, [showCrmLoanSummaryView]);

  return <>
    {component}
    {children}
  </>;
}
