import { Box, Grid } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Button, ConfirmDialog, StandardDialog } from '@roc/ui';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useLoanActionsStore } from '../hooks/useLoanActionsStore';

export const UploadDocsToSnapdocsModal = observer(({ open, handleClose }) => {
  const { loanActionsStore } = useLoanActionsStore();
  const { snapdocsStore } = loanActionsStore;
  const { checkResult } = snapdocsStore;

  const confirm = () => {
    snapdocsStore.uploadDocsToSnapdocs();
  };

  useEffect(() => {
      if (!open) {
       snapdocsStore.resetStore();
      }
  }, [open]);

  return (
      <StandardDialog
        open={open}
        handleClose={handleClose}
        title={'Upload Docs to Snapdocs'}
        maxWidth={'sm'}
        dialogContent={
          <Grid container spacing={2}>
            {checkResult?.success === false ? (
              <Grid item xs={12}>
                <Alert severity="error">
                  <b>Following is required to upload documents for the closing on Snapdocs:</b>
                  {checkResult.message.map(err => (
                    <Box> - {err}</Box>
                  ))}
                </Alert>
              </Grid>
            ) :
            (<Grid item xs={12}>
              Are you sure you want to upload documents for Closing on Snapdocs ?
            </Grid>)
            }
          </Grid>
        }
        dialogActions={
          <>
            <Button
              style={{ marginRight: '8px' }}
              onClick={handleClose}
              color="primary"
              variant="outlined"
              testId="cancel"
            >
              Cancel
            </Button>
            <Button
              disabled={checkResult?.success === false}
              onClick={confirm}
              color="primary"
              variant="contained"
              testId="confirm"
            >
              Yes
            </Button>
          </>
        }
      />
    );
});