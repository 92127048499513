import {
  AgGridColumnProps,
  DataGrid,
  LinkColumn,
  SelectFilterComponent,
  SelectFloatingFilterComponent,
  StatusChip,
  StatusType,
} from '@roc/ui';
import { observer } from 'mobx-react';
import { formatDate } from '@roc/feature-utils';
import { InfiniteScrollGrid } from '@roc/feature-app-core';
import { useLoanRoutes } from '@roc/feature-loans-routes-config';
import { useExtensionRequestStore } from '../loanDetails/modules/loanRequests/hooks/useExtensionRequestStore';
import { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

const noOpComparator = () => 0;

const columns: AgGridColumnProps[] = [
  {
    field: 'extensionNumber',
    headerName: 'Extension #',
    minWidth: 175,
    cellRenderer: 'extensionNumberCellRenderer',
    comparator: noOpComparator,
    pinned: 'left',
    lockPinned: true,
    sortable: false,
  },
  {
    field: 'loanId',
    headerName: 'Loan Id',
    minWidth: 100,
    cellRenderer: 'loanIdCellRenderer',
    filter: 'agTextColumnFilter',
    comparator: noOpComparator,
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'status',
    headerName: 'Status',
    minWidth: 230,
    maxWidth: 350,
    comparator: noOpComparator,
    filter: 'selectFilter',
    cellRenderer: 'statusCellRenderer',
    floatingFilter: true,
    floatingFilterComponent: 'selectFloatingFilter',
    floatingFilterComponentParams: {
      suppressFilterButton: true,
      options: [
        { label: 'Extension Initiated', value: 'Extension Initiated' },
        {
          label: 'Insurance under review',
          value: 'Insurance under review',
        },
        { label: 'Insurance Review Completed', value: 'Insurance Review Completed' },
        { label: 'Extension In Process', value: 'Extension In Process' },
        { label: 'Extension Completed', value: 'Extension Completed' },
      ],
    },
  },
  {
    field: 'requestDate',
    headerName: 'Request Date',
    minWidth: 100,
    filter: 'agTextColumnFilter',
    comparator: noOpComparator,
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'newMaturityDate',
    headerName: 'New Expiration Date',
    minWidth: 100,
    cellRenderer: 'extensionExpirationDateCellRenderer',
    comparator: noOpComparator,
  },
];

export const MyExtensionsGrid = observer(props => {
  const { extensionRequestStore } = useExtensionRequestStore();
  const { loanRoutesConfig } = useLoanRoutes();

  return (
    <InfiniteScrollGrid
      toolbar={props.toolbar}
      columns={columns}
      frameworkComponents={getFrameworkComponents(loanRoutesConfig)}
      store={extensionRequestStore.myExtensionsGridStore}
    />
  );
});

const getFrameworkComponents = (loanRoutesConfig) => {
  return {
    selectFilter: SelectFilterComponent,
    selectFloatingFilter: SelectFloatingFilterComponent,
    dateCellRenderer: ({ value }) => {
      return value ? formatDate(value, 'MM/dd/yyyy') : '';
    },
    extensionNumberCellRenderer: params => {
      if (!params.value) {
        return '';
      }
      const extensionId = params.node.data?.loanExtensionId;
      const loanId = params.node.data?.loanId;
      const { onClick } = params;
      const value = `${loanId} - Extension ${params.value}`;
      return LinkColumn({
        value,
        onClick,
        url: loanRoutesConfig.loans(loanId).children.extensionDetails(extensionId).url,
      });
    },
    loanIdCellRenderer: params => {
      if (!params.value) {
        return '';
      }
      const { onClick } = params;
      return LinkColumn({
        value: params.value,
        onClick,
        url: loanRoutesConfig.loans(params.value).children.extensions.url,
      });
    },
    extensionDurationCellRenderer: ({ value }) => {
      return `${value} months`;
    },
    statusCellRenderer: ({ value }) =>
      value ? (
        <StatusChip
          statusType={StatusType.EXTENSIONS}
          label={value}
          size='small'
          variant={(value == 'Extension Completed' || value == 'Extension In Process') ? 'filled' : 'outlined'} />
      ) : null,
  }
};

export const MyExtensionsGridPaginated = observer(props => {
  const { extensionRequestStore } = useExtensionRequestStore();
  const { myExtensionsGridStore } = extensionRequestStore;
  const { loanRoutesConfig } = useLoanRoutes();

  useEffect(() => {
    myExtensionsGridStore.resetAndFetchGridData();
  }, []);

  const onSortChanged = params => {
    const sortModel = params.api.getSortModel();
    myExtensionsGridStore.setSortModel(sortModel, false);
  };

  const onFilterChanged = params => {
    const filterModel = params.api.getFilterModel();
    myExtensionsGridStore.setFilterModel(filterModel, false);
  };

  return (
    <DataGrid
      toolbar={props.toolbar}
      columns={columns}
      rows={myExtensionsGridStore.gridData.data.rows}
      frameworkComponents={getFrameworkComponents(loanRoutesConfig)}
      onSortChanged={onSortChanged}
      onFilterChanged={onFilterChanged}
      isLoading={myExtensionsGridStore.isLoading}
      showFilters={myExtensionsGridStore.showFilters}
      sortModel={myExtensionsGridStore.sortModel}
      filterModel={myExtensionsGridStore.filterModel}
      paginationData={myExtensionsGridStore.gridData.meta}
      setPageNumber={myExtensionsGridStore.setPageNumber}
      domLayout="autoHeight"
      isError={myExtensionsGridStore.isError}
    />
  );
});

export const RecentExtensionsGrid = observer(({
  onDataLoad
}: {
  onDataLoad?: (rows: any[], totalCount: number) => void
}) => {
  const { extensionRequestStore } = useExtensionRequestStore();
  const { myExtensionsGridStore } = extensionRequestStore;
  const { loanRoutesConfig } = useLoanRoutes();
  const [dataRows, setDataRows] = useState([]);

  useEffect(() => {
    myExtensionsGridStore.resetAndFetchGridData();
  }, []);

  useEffect(() => {
    if (myExtensionsGridStore.gridData?.data?.rows?.length) {
      const slicedData = myExtensionsGridStore.gridData?.data?.rows.slice(0, 5);
      setDataRows(slicedData);
      onDataLoad && onDataLoad(slicedData, myExtensionsGridStore.gridData?.data?.rows?.length);
    } else {
      setDataRows([]);
      onDataLoad && onDataLoad([], 0)
    }
  }, [myExtensionsGridStore.gridData.data.rows]);

  const recentExtensionsGridColumns = columns.filter((col) => {
    return col.field != 'loanId'
  }).map((col) => {
    return {
      ...col,
      sortable: false,
      floatingFilter: false,
      lockPinned: false,
      pinned: false,
    }
  });

  return (
    <>
      {
        myExtensionsGridStore.isLoading &&
        <Box m={2}>
          Loading ...
        </Box>
      }
      {
        !myExtensionsGridStore.isLoading && dataRows?.length == 0 &&
        <Box m={2}>
          <Alert severity="info">
            You haven't requested an extension for any of your loans recently.
          </Alert>
        </Box>
      }
      {
        !myExtensionsGridStore.isLoading && dataRows?.length > 0 &&
        <DataGrid
          columns={recentExtensionsGridColumns}
          rows={dataRows}
          frameworkComponents={getFrameworkComponents(loanRoutesConfig)}
          isLoading={myExtensionsGridStore.isLoading}
          domLayout="autoHeight"
          isError={myExtensionsGridStore.isError}
        />
      }
    </>
  );
});

export default MyExtensionsGrid;
