import { Page } from '@roc/feature-app-core';
import { Route } from 'react-router';
import { LenderProfile } from '../components/lenderProfile';

export const getLenderProfileRoutesConfig = (basePath: string, baseUrl: string) => {
  return {
    lenderProfile: {
      path: `${basePath}/profile`,
      url: `${baseUrl}/profile`,
      documentTitle: 'Profile',
    }
  };
}

export const getLenderProfileRoutes = (basePath: string, baseUrl: string) => {
  const config = getLenderProfileRoutesConfig(basePath, baseUrl);
  return {
    lenderProfile: (
      <Route exact path={config.lenderProfile.path}>
        <Page routeDefinition={config.lenderProfile}>
          <LenderProfile />
        </Page>
      </Route>
    )
  };
}