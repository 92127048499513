import { Route } from 'react-router';
import { useStore } from '@roc/client-portal-shared/hooks';
import { useRocInternalPortalStore } from '../../../hooks';
import { DscrCalculator } from '@roc/client-portal-shared/modules/dscrCalculator';
import { Page } from '@roc/feature-app-core';

export const getDscrCalculatorInternalRoutesConfig = (
  basePath: string,
  baseUrl: string
) => {
  return {
    dscrCalculator: {
      path: `${basePath}/dscr-calculator`,
      url: `${baseUrl}/dscr-calculator`,
      documentTitle: 'DSCR Calculator',
    },
  };
};

export const getDscrCalculatorInternalRoutes = (
  basePath: string,
  baseUrl: string
) => {
  const config = getDscrCalculatorInternalRoutesConfig(basePath, baseUrl);
  const { globalStore } = useStore();
  const { dscrCalculatorInternalStore } = useRocInternalPortalStore();

  return {
    dscrCalculator: (
      <Route exact path={config.dscrCalculator.path}>
        <Page routeDefinition={config.dscrCalculator}>
          <DscrCalculator dscrCalculatorStore={dscrCalculatorInternalStore} />
        </Page>
      </Route>
    ),
  };
};
