import { useBaseStore } from '@roc/feature-app-core';
import { Document } from '@roc/feature-types';
import { observer } from 'mobx-react';
import { TermSheetDialogExternal } from './termSheetDialogExternal';
import { TermSheetDialogInternal } from './termSheetDialogInternal';

export type TermSheetDialogProps = {
  open?: boolean;
  onClose?: () => void;
  loan: any;
  document: Document;
};

export const TermSheetDialog = observer((props: TermSheetDialogProps) => {
  const { globalStore } = useBaseStore();
  if (globalStore.isInternalPortal) {
    return <TermSheetDialogInternal {...props} />;
  } else {
    return <TermSheetDialogExternal {...props} />;
  }
});

export default TermSheetDialog;
