import { Service } from 'libs/feature-app-core/src/services/service';

const url = {
  ORIGINATORS: '/api/v1/loan/lenderOriginator/getFacoOriginators',
};

export class OriginatorService extends Service {
  constructor() {
    super();
  }
  async getAllOriginators(
    page: number,
    pageSize: number,
    sortDir: string,
    sortBy: string,
    filters: string,
    userId: number
  ) {
    const response = await this.get(
      url.ORIGINATORS,
      {
        page,
        pageSize,
        sortDir,
        sortBy,
        filters,
        userId,
      },
      {
        disableGlobalLoading: true,
      }
    );

    const {
      data: { data },
    } = response;


    const rows = data.rows.map(b => ({
      lenderName: b.lenderName,
      companyName: b.companyName,
      lenderId: b.lenderId,
    }))

    response.data.data.rows = rows;
    return response;
  }
}