import { GridColumnProps } from "@roc/ui";

export const salesColumns: GridColumnProps[] = [
  {
    field: 'address',
    headerName: 'Address',
    minWidth: 300,
    cellRenderer: 'addressCellRenderer'
  },
  {
    field: 'price',
    headerName: 'Price',
    minWidth: 70,
    cellRenderer: 'moneyAbbreviatedRenderer'
  },
  {
    field: 'days_on_market',
    headerName: 'DOM',
    maxWidth: 75,
    cellRenderer: 'valueArrowCellRenderer'
  },
  {
    field: 'condition_tag',
    headerName: 'Condition',
    minWidth: 70,
    cellRenderer: 'valueCellRenderer'
  },
  {
    field: 'mi_distance',
    headerName: 'Distance',
    minWidth: 70,
    cellRenderer: 'distanceCellRenderer'
  },
  {
    field: 'year_built',
    headerName: 'Yr. Built',
    minWidth: 70,
    cellRenderer: 'valueArrowCellRenderer'
  },
  {
    field: 'rooms',
    headerName: 'Rooms',
    cellRenderer: 'valueArrowCellRenderer',
    maxWidth: 80,
  },
  {
    field: 'bedrooms',
    headerName: 'Beds',
    maxWidth: 75,
    cellRenderer: 'valueArrowCellRenderer',
  },
  {
    field: 'full_bathrooms',
    headerName: 'Baths',
    maxWidth: 75,
    cellRenderer: 'valueArrowCellRenderer',
  },
  {
    field: 'half_bathrooms',
    headerName: 'Half Bath',
    minWidth: 70,
    cellRenderer: 'valueArrowCellRenderer',
  },
  {
    field: 'area',
    headerName: 'Sq. Ft.',
    minWidth: 100,
    cellRenderer: 'valueArrowCellRenderer',
  },
  {
    field: 'lot_size',
    headerName: 'Lot Size',
    minWidth: 70,
    cellRenderer: 'valueArrowCellRenderer',
  },
  {
    field: 'sqft_price',
    headerName: 'Price/Sq.Ft',
    minWidth: 100,
    cellRenderer: 'moneyArrowRenderer'
  },
  {
    field: 'taxes',
    headerName: 'Taxes / Yr.',
    minWidth: 100,
    cellRenderer: 'moneyRenderer',
  },
];


export const rentColumns: GridColumnProps[] = [
  {
    field: 'address',
    headerName: 'Address',
    minWidth: 300,
    cellRenderer: 'addressCellRenderer'
  },
  {
    field: 'rent',
    headerName: 'Rent',
    minWidth: 70,
    cellRenderer: 'rentAbbreviatedRenderer'
  },
  {
    field: 'condition_tag',
    headerName: 'Condition',
    minWidth: 70,
    cellRenderer: 'valueCellRenderer'
  },
  {
    field: 'days_on_market',
    headerName: 'DOM',
    maxWidth: 75,
    cellRenderer: 'valueArrowCellRenderer'
  },
  {
    field: 'mi_distance',
    headerName: 'Distance',
    minWidth: 70,
    cellRenderer: 'distanceCellRenderer'
  },
  {
    field: 'year_built',
    headerName: 'Yr. Built',
    minWidth: 70,
    cellRenderer: 'valueArrowCellRenderer'
  },
  {
    field: 'rooms',
    headerName: 'Rooms',
    cellRenderer: 'valueArrowCellRenderer',
    maxWidth: 80,
  },
  {
    field: 'bedrooms',
    headerName: 'Beds',
    maxWidth: 75,
    cellRenderer: 'valueArrowCellRenderer',
  },
  {
    field: 'full_bathrooms',
    headerName: 'Baths',
    maxWidth: 75,
    cellRenderer: 'valueArrowCellRenderer',
  },
  {
    field: 'half_bathrooms',
    headerName: 'Half Bath',
    minWidth: 70,
    cellRenderer: 'valueArrowCellRenderer',
  },
  {
    field: 'area',
    headerName: 'Sq. Ft.',
    minWidth: 100,
    cellRenderer: 'valueArrowCellRenderer',
  },
  {
    field: 'lot_size',
    headerName: 'Lot Size',
    minWidth: 70,
    cellRenderer: 'valueArrowCellRenderer',
  },
  {
    field: 'sqft_rent',
    headerName: 'Rent/Sq.Ft',
    minWidth: 100,
    cellRenderer: 'moneyArrowRenderer'
  },
  {
    field: 'taxes',
    headerName: 'Taxes / Yr.',
    minWidth: 100,
    cellRenderer: 'moneyRenderer',
  },
];
