import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { formatPhoneNumber } from '@roc/ui/utils';
import { observer } from 'mobx-react';
import { GridStore } from '@roc/feature-app-core';
import {
  DataGrid as Grid,
  GridColumnProps,
  SelectMode,
  GridRowSelectedEvent,
  SelectFilterComponent,
  SelectFloatingFilterComponent,
} from '@roc/ui';

const noOpComparator = () => 0;

export type SelectBorrowersGridProps = {
  multiple?: boolean;
  onRowSelected: (e: GridRowSelectedEvent) => void;
  store: GridStore;
  toolbar?: React.ReactNode;
};

const useStyles = makeStyles(() => ({
  container: {
    height: 435,
  },
}));

export const SelectBorrowersGrid = observer(
  (props: SelectBorrowersGridProps) => {
    const { multiple, onRowSelected, store, toolbar } = props;
    const classes = useStyles();

    const columns: GridColumnProps[] = [
      {
        field: 'firstName',
        headerName: 'First Name',
        minWidth: 100,
        comparator: noOpComparator,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
        },
      },
      {
        field: 'lastName',
        headerName: 'Last Name',
        minWidth: 100,
        comparator: noOpComparator,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
        },
      },
      {
        field: 'emailAddress',
        headerName: 'Email',
        minWidth: 250,
        comparator: noOpComparator,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
        },
      },
      {
        field: 'cellPhone',
        headerName: 'Cell Phone',
        minWidth: 150,
        cellRenderer: 'phoneCellRenderer',
        comparator: noOpComparator,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
        },
      },
    ];

    const frameworkComponents = {
      selectFilter: SelectFilterComponent,
      selectFloatingFilter: SelectFloatingFilterComponent,
      phoneCellRenderer: ({ value }) => formatPhoneNumber(value),
    };

    const onFilterChanged = params => {
      const filterModel = params.api.getFilterModel();
      store.setFilterModel(filterModel);
    };

    const onSortChanged = params => {
      const sortModel = params.api.getSortModel();
      store.setSortModel(sortModel);
    };

    return (
      <>
        <Grid
          className={classes.container}
          selectMode={multiple ? SelectMode.MULTIPLE : SelectMode.SINGLE}
          onRowSelected={onRowSelected}
          columns={columns}
          rows={store.gridData.data.rows}
          frameworkComponents={frameworkComponents}
          isLoading={store.isLoading}
          domLayout="normal"
          onFilterChanged={onFilterChanged}
          showFilters={store.showFilters}
          onSortChanged={onSortChanged}
          sortModel={store.sortModel}
          filterModel={store.filterModel}
          paginationData={store.gridData.meta}
          setPageNumber={store.setPageNumber}
          toolbar={toolbar}
        />
      </>
    );
  }
);

export default SelectBorrowersGrid;
