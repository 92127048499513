import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { createStyles, lighten, makeStyles } from '@material-ui/core/styles';
import {
  Add,
  Close,
  Delete,
  Edit,
  KeyboardArrowDown,
  KeyboardArrowUp,
  Remove,
  Save,
} from '@material-ui/icons';
import {
  FormStore,
  SinglePropertyStore,
} from '@roc/client-portal-shared/stores';
import { ThemeProvider } from '@roc/feature-app-core';
import { isBlank, isNil } from '@roc/feature-utils';
import {
  Button,
  createCurrencyField,
  CurrencyField,
  CurrencyFieldProps,
  PercentageField,
  TextField,
} from '@roc/ui';
import { formatCurrency, formatPercentage } from '@roc/ui/utils';
import clsx from 'clsx';
import Typography from 'libs/feature-borrower-portal-theme/src/theme/overrides/Typography';
import { TermStore } from 'libs/feature-loans/src/loanSubmission';
import { feeRows } from 'libs/feature-loans/src/loanSubmission/stores/common/lenderFeesFormStore';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';

const useStyles = makeStyles(theme =>
  createStyles({
    tableContainer: {
      width: 'fit-content',
      border: '1px solid #e0e0e0',
      borderRadius: theme.spacing(1),
    },
    table: {
      '& td': {
        width: '150px',
        padding: theme.spacing(1.5),
        textAlign: 'right',
      },
      //First column: collapse button
      '& tr td:nth-child(1)': {
        width: '0px',
        paddingRight: 0,
      },
      //Second column: fee name
      '& tr td:nth-child(2)': {
        width: 'auto',
        textAlign: 'left',
      },
      // Third column: charged to borrower
      //'& tr td:nth-child(3)': {
      //  width: 'auto',
      //  backgroundColor: lighten(theme.palette.primary.main, 0.9),
      //},
      //'& tr td:last-child': {
      //  paddingRight: theme.spacing(2),
      //},
    },
    tableInternal: {
      '& td': {
        width: '150px',
        padding: theme.spacing(1.5),
        textAlign: 'right',
      },
      //First column: collapse button
      '& tr td:nth-child(1)': {
        width: '0px',
        paddingRight: 0,
      },
      //Second column: fee name
      '& tr td:nth-child(2)': {
        width: 'auto',
        textAlign: 'left',
      },
      // Third column: charged to borrower
      // '& tr td:nth-child(3)': {
      //   width: 'auto',
      // },
    },
    row: {
      borderTop: '1px solid #BEBEBE',
    },
    bold: {
      fontWeight: 'bold',
    },
    fieldCell: {
      padding: theme.spacing(0, 1),
    },
    field: {
      '& input': {
        padding: theme.spacing(1),
      },
    },
    totalRow: {
      backgroundColor: '#FAFAFA',
      '& td:nth-child(2)': {
        textAlign: 'right',
      },
    },
    removeButton: {
      color: theme.palette.error.main,
    },
    headerRow: {
      '& td': {
        backgroundColor: '#333333 !important',
        color: 'white',
      },
    },
    alignLeft: {
      textAlign: 'left',
    },
    alignRight: {
      textAlight: 'right !important',
    },
    rateFieldInput: {
      '& input': {
        padding: theme.spacing(1),
      },
    },
  })
);

const retailHiddenFieldsList = ['rocAppraisalFee', 'retailAppraisalFee', 'rocLegalReviewFee', 'retailLegalReviewFee',
  'rocValuationReviewFee', 'retailValuationReviewFee', 'rocAdminFee', 'retailAdminFee', 'rocCustodianFee', 'retailCustodianFee',
  'rocBuydownFee', 'retailBuydownFee'];

const wholesaleHiddenFieldsList = ['lenderBuydownFee', 'rocBuydownFee'];

export const PricingEconomics = observer(({ store }: { store: TermStore }) => {
  const classes = useStyles();
  const [feesOpen, setFeesOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const { pricerSummaryStore, loanDetailsStore, globalStore } = store;
  const { lenderFeesFormStore } = pricerSummaryStore;
  const {
    customFees,
    defaultFees,
    totalLenderFees,
    totalRocFees,
    totalRetailFees,
  } = lenderFeesFormStore;
  const { fields } = lenderFeesFormStore.form;
  const isInternal = globalStore.lenderInfo?.isInternal || globalStore.lenderInfo?.rocOwnedLender;

  const fetchRates = () => {
    loanDetailsStore.setRocUnderwritingFees(fields.underwritingFee.value || 0);
    pricerSummaryStore.fetchRatesAndLoanTerms();
    pricerSummaryStore.setErrorMessages();
  };

  const onSave = () => {
    setIsEditing(false);
    fetchRates();
    pricerSummaryStore.unselectQuoteOption();
  }

  const isHiddenFieldRetail = (fieldName) => {
    return retailHiddenFieldsList.includes(fieldName);
  }

  const isHiddenFieldWholesale = (fieldName) => {
    return wholesaleHiddenFieldsList.includes(fieldName);
  }

  const loanAmount = pricerSummaryStore.pricerLoanTerms?.amount;
  const maxYsp = pricerSummaryStore.pricerLoanTerms?.maxYsp;
  const maxBuydownRatio = pricerSummaryStore.buydownRatio
    ? pricerSummaryStore.buydownRatio
    : pricerSummaryStore.buydownRatioStandard;

  useEffect(() => {
    fields.rocBuydownFee.value = fields.buydownPointsRoc.value * (loanAmount ?? 0) / 100;
  }, [fields.buydownPointsRoc.value]);

  useEffect(() => {
    fields.lenderBuydownFee.value = fields.buydownPointsTpo.value * (loanAmount ?? 0) / 100;
  }, [fields.buydownPointsTpo.value]);

  useEffect(() => {
    fields.retailBuydownFee.value = fields.buydownPointsRetail.value * (loanAmount ?? 0) / 100;
  }, [fields.buydownPointsRetail.value]);

  return (
    <Box
      width="fit-content"
      display="flex"
      flexDirection="column"
      alignItems="flex-end"
    >
      <TableContainer className={classes.tableContainer}>
        <Table className={isInternal ? classes.tableInternal : classes.table} aria-label="Pricing Economics">
          <TableRow>
            <TableCell colSpan={!isInternal ? 5 : 4}>
              <Box pr={2}>
                {isEditing ? (
                  <Button
                    testId="save"
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={onSave}
                  >
                    <Save /> Save
                  </Button>
                ) : (
                  <Button
                    testId="edit"
                    size="small"
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      setIsEditing(true);
                      setFeesOpen(true);
                    }}
                  >
                    <Edit /> Edit
                  </Button>
                )}
              </Box>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell />
            <TableCell />
            <TableCell>
              <strong>Rate Buydown Fees</strong>
            </TableCell>
            <TableCell>
              <strong>Add-on Fees</strong>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell />
            <TableCell className={classes.bold}>Origination Points</TableCell>
            <TableCell>
              <RatePercentageField
                store={lenderFeesFormStore}
                name={'originationPointsRoc'}
                isEditing={isEditing}
              />
            </TableCell>
            {!isInternal && (
              <>
                <TableCell>
                  <RatePercentageField
                    store={lenderFeesFormStore}
                    name={'originationPointsTpo'}
                    isEditing={isEditing}
                  />
                </TableCell>
              </>
            )}
            {isInternal && (
              <>
                <TableCell>
                  <RatePercentageField
                    store={lenderFeesFormStore}
                    name={'originationPointsRetail'}
                    isEditing={isEditing}
                  />
                </TableCell>
              </>
            )}
          </TableRow>
          <TableRow>
            <TableCell />
            <TableCell className={classes.bold}>Buydown Points</TableCell>
            <TableCell>
              <RatePercentageField
                store={lenderFeesFormStore}
                name={'buydownPointsRoc'}
                isEditing={isEditing}
              />
            </TableCell>
            {!isInternal && (
              <>
                <TableCell>
                  <RatePercentageField
                    store={lenderFeesFormStore}
                    name={'buydownPointsTpo'}
                    isEditing={isEditing}
                  />
                </TableCell>
              </>
            )}
            {isInternal && (
              <>
                <TableCell>
                  <RatePercentageField
                    store={lenderFeesFormStore}
                    name={'buydownPointsRetail'}
                    isEditing={isEditing}
                  />
                </TableCell>
              </>
            )}
          </TableRow>
          <TableRow>
            <TableCell>
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setFeesOpen(!feesOpen)}
              >
                {feesOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
              </IconButton>
            </TableCell>
            <TableCell className={classes.bold}>
              Total Underwriting & Processing Fees
            </TableCell>
            <TableCell />
            <TableCell />
          </TableRow>
          {feesOpen && (
            <>
              {feeRows.map(fee => (
                <TableRow key={fee.feeName}>
                  {((!isInternal && !isHiddenFieldWholesale(fee.rocFieldName)) || (isInternal && !isHiddenFieldRetail(fee.rocFieldName))) && (
                    <>
                      <TableCell />
                      <TableCell style={{ paddingLeft: '64px' }}>
                        {fee.feeName}
                      </TableCell>
                      <TableCell className={classes.fieldCell}>
                        <RateCurrencyField
                          store={lenderFeesFormStore}
                          name={fee.rocFieldName}
                          isEditing={isEditing}
                        />
                      </TableCell>
                    </>
                  )}
                  {(!isInternal && !isHiddenFieldWholesale(fee.tpoFieldName)) && (
                    <>
                      <TableCell className={classes.fieldCell}>
                        <RateCurrencyField
                          store={lenderFeesFormStore}
                          name={fee.tpoFieldName}
                          isEditing={isEditing}
                        />
                      </TableCell>
                    </>
                  )}
                  {(isInternal && !isHiddenFieldRetail(fee.retailFieldName)) && (
                    <>
                      <TableCell className={classes.fieldCell}>
                        <RateCurrencyField
                          store={lenderFeesFormStore}
                          name={fee.retailFieldName}
                          isEditing={isEditing}
                        />
                      </TableCell>
                    </>
                  )}
                </TableRow>
              ))}

              {(isEditing
                ? lenderFeesFormStore.customFeesWithPlaceholder
                : lenderFeesFormStore.customFees
              ).map((customFee, i) => (
                <TableRow key={i}>
                  <TableCell />
                  <TableCell style={{ paddingLeft: '64px' }}>
                    <CustomFeeNameField
                      isEditing={isEditing}
                      name="customFeeName"
                      value={customFee.feeName}
                      onChange={e => {
                        customFee.feeName = e.target.value;
                        if (customFee.isPlaceholder) {
                          lenderFeesFormStore.addCustomFee(customFee);
                        }
                        lenderFeesFormStore.removeEmptyFees();
                      }}
                    />
                  </TableCell>
                  <TableCell className={classes.fieldCell}>
                    <CustomFeeField
                      isEditing={isEditing}
                      name="customFeeRoc"
                      disabled={!customFee.feeName}
                      value={customFee.rocValue}
                      onChange={(_, newValue) =>
                      (customFee.rocValue = newValue
                        ? parseFloat(newValue)
                        : null)
                      }
                    />
                  </TableCell>
                  {!isInternal && (
                    <>
                      <TableCell className={classes.fieldCell}>
                        <CustomFeeField
                          isEditing={isEditing}
                          name="customFeeTpo"
                          disabled={!customFee.feeName}
                          value={customFee.tpoValue}
                          onChange={(_, newValue) =>
                          (customFee.tpoValue = newValue
                            ? parseFloat(newValue)
                            : null)
                          }
                        />
                      </TableCell>
                    </>
                  )}
                  {isInternal && (
                    <>
                      <TableCell className={classes.fieldCell}>
                        <CustomFeeField
                          isEditing={isEditing}
                          name="customFeeRetail"
                          disabled={!customFee.feeName}
                          value={customFee.retailValue}
                          onChange={(_, newValue) =>
                          (customFee.retailValue = newValue
                            ? parseFloat(newValue)
                            : null)
                          }
                        />
                      </TableCell>
                    </>
                  )}
                </TableRow>
              ))}
            </>
          )}
          <TableRow className={classes.totalRow}>
            <TableCell />
            <TableCell
              className={clsx(classes.bold, classes.alignRight)}
              style={{ textAlign: 'right' }}
            >
              Total Fees & Points
            </TableCell>
            <TableCell>{formatCurrency(totalRocFees)}</TableCell>
            {!isInternal && (
              <>
                <TableCell>{formatCurrency(totalLenderFees)}</TableCell>
              </>
            )}
            {isInternal && (
              <>
                <TableCell>{formatCurrency(totalRetailFees)}</TableCell>
              </>
            )}
          </TableRow>
          {/*<TableRow className={classes.totalRow}>
            <TableCell />
            <TableCell
              className={clsx(classes.bold, classes.alignRight)}
              style={{ textAlign: 'right' }}
            >
              $ Amount
            </TableCell>
            <TableCell>{formatCurrency(totalRocFees)}</TableCell>
            {!isInternal && (
              <>
                <TableCell>{formatCurrency(totalLenderFees)}</TableCell>
              </>
            )}
            {isInternal && (
              <>
                <TableCell>{formatCurrency(totalRetailFees)}</TableCell>
              </>
            )}
          </TableRow>
          <TableRow className={classes.totalRow}>
            <TableCell />
            <TableCell
              className={clsx(classes.bold, classes.alignRight)}
              style={{ textAlign: 'right' }}
            >
              % of Loan Amount:
            </TableCell>
            <TableCell>
              {formatPercentage((totalRocFees / loanAmount) * 100)}
            </TableCell>
            {!isInternal && (
              <>
                <TableCell>
                  {formatPercentage((totalLenderFees / loanAmount) * 100)}
                </TableCell>
              </>
            )}
            {isInternal && (
              <>
                <TableCell>
                  {formatPercentage((totalRetailFees / loanAmount) * 100)}
                </TableCell>
              </>
            )}
            </TableRow>*/}
        </Table>
      </TableContainer>
    </Box>
  );
});

interface RateFieldProps {
  isEditing: boolean;
  name: string;
  store: FormStore;
}

const RateCurrencyField = observer(
  ({ isEditing, store, name }: RateFieldProps) => {
    const value = store.form.fields[name].value;
    return (
      <CustomFeeField
        isEditing={isEditing}
        name={name}
        value={value}
        onChange={(_, newValue) => {
          store.onFieldChange(
            name,
            newValue ? parseFloat(newValue as string) : ''
          );
        }}
      />
    );
  }
);

const RatePercentageField = observer(
  ({ isEditing, store, name }: RateFieldProps) => {
    const classes = useStyles();
    const value = store.form.fields[name].value;

    return (
      <CustomPercentageField
        isEditing={isEditing}
        name={name}
        value={value}
        onChange={(_, newValue) => {
          store.onFieldChange(
            name,
            newValue ? parseFloat(newValue as string) : ''
          );
        }}
      />
    );
  }
);

const CustomFeeNameField = observer(({ isEditing, name, value, onChange }) => {
  const classes = useStyles();

  return isEditing ? (
    <TextField
      className={classes.rateFieldInput}
      variant={'outlined'}
      testId={name}
      value={isNil(value) ? '' : value}
      onChange={onChange}
      placeholder={'+ Add custom fee'}
    />
  ) : (
    <>{value ?? ''}</>
  );
});

const CustomFeeField = observer(
  ({ isEditing, name, value, disabled = false, onChange }) => {
    const classes = useStyles();

    return isEditing ? (
      <CurrencyField
        inputClassname={classes.rateFieldInput}
        disabled={disabled}
        variant={'outlined'}
        testId={name}
        value={isNil(value) ? '' : value}
        onChange={onChange}
      />
    ) : (
      <>{isNil(value) ? '' : formatCurrency(value)}</>
    );
  }
);

const CustomPercentageField = observer(
  ({
    isEditing,
    name,
    value,
    disabled = false,
    onChange,
    helperText = null,
  }) => {
    const classes = useStyles();

    return isEditing ? (
      <PercentageField
        inputClassname={classes.rateFieldInput}
        disabled={disabled}
        variant={'outlined'}
        testId={name}
        value={isNil(value) ? '' : value}
        onChange={onChange}
        errorText={helperText}
      />
    ) : (
      <>{isNil(value) ? '' : formatPercentage(value)}</>
    );
  }
);
