import { action, flow, makeObservable, observable } from 'mobx';
import { FormStore, GridStore } from '@roc/feature-app-core';
import { LoanRequestsService } from './../services/loanRequestsService';
import { GlobalStore } from '@roc/feature-app-core';
import { LoanStore } from './../../../../stores/loanStore';
import { Extensions } from './../types/Extensions';
import { ApiResponse } from '../../../../../../../feature-app-core/src/services/service';
import * as Validator from 'validatorjs';
import { GENERIC_ERROR_MESSAGE, errorMessages } from '@roc/feature-utils';
import { da } from 'date-fns/locale';
import { format, differenceInDays, differenceInMonths, add } from 'date-fns';
import { FileUpload } from '@roc/ui';
import { LoanService } from 'libs/feature-loans/src/loans/services/loanService';
const form = {
  fields: {
    extensionDuration: {
      value: '',
      error: null,
      rule: 'required',
    },
    preferredInsurance: {
      value: '',
      error: null,
      rule: '',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export class ExtensionRequestStore extends FormStore {
  globalStore: GlobalStore;
  private loanRequestsService: LoanRequestsService;
  private loanStore: LoanStore;
  public saved: boolean;
  public extension: string;
  public insurance: string;
  extensionList: Extensions[] = [];
  currentExtension: Extensions;
  extensionByNumber: Extensions;
  disabledCreateExtesion: boolean;
  currentForm: string;
  private rulesExtensions;
  private attributesExtensions;
  private docName: string;
  myExtensionsGridStore: GridStore;
  private loanService: LoanService;

  constructor(globalStore, loanStore) {
    super({ ...form }, globalStore);
    this.globalStore = globalStore;
    this.loanRequestsService = new LoanRequestsService();
    this.loanStore = loanStore;
    this.saved = false;
    this.extension = "";
    this.insurance = "";
    this.docName = "";
    this.disabledCreateExtesion = false;
    this.loanService = new LoanService();
    this.myExtensionsGridStore = new GridStore(
      () => this.fetchMyExtensions(),
      null,
      50,
    );
    makeObservable(this, {
      onFormSubmit: flow,
      approveInsurance: flow,
      saved: observable,
      setExtension: observable,
      setSaved: action,
      fetchExtensionsByLoanId: flow,
      fetchExtensionById: flow,
      extensionList: observable,
      currentExtension: observable,
      extensionByNumber: observable,
      currentForm: observable,
      // onFieldChange: action,
    });
  }
  private async fetchMyExtensions() {
    const gridFilters = this.myExtensionsGridStore.gridData.meta.filters ?? {};
    const filters = {
      ...gridFilters,
      ...this.myExtensionsGridStore.gridData.meta.dropdownFilters
    };
    try {
      const response: ApiResponse = await this.loanService.getMyExtensions(
        this.myExtensionsGridStore.gridData.meta.pageNumber,
        this.myExtensionsGridStore.gridData.meta.pageSize,
        this.myExtensionsGridStore.gridData.meta.sortDir,
        this.myExtensionsGridStore.gridData.meta.sortBy,
        filters,
      );
      return response;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE
      });
    }
  }
  getMonthDifference = (startDate, endDate) => {
    return (
      endDate.getMonth() -
      startDate.getMonth() +
      12 * (endDate.getFullYear() - startDate.getFullYear())
    );
  }
  differenceInDay(startDate, endDate) {
    const diffInDays = differenceInDays(new Date(endDate), new Date(startDate));
    return diffInDays;
  }
  differenceInMonths(startDate, endDate) {
    const diffInMonths = differenceInMonths(new Date(endDate), new Date(startDate));
    return diffInMonths;
  }
  private createFormValidatorExtensions = () => {
    const values = this.getFlattenedValues('value');
    const validator = new Validator(values, this.rulesExtensions, errorMessages);
    validator.setAttributeNames(this.attributesExtensions);
    return validator;
  };

  onFieldChanged = (field, value) => {
    this.form.fields[field].value = value;
    const validator = this.createFormValidatorExtensions();
    if (validator.errors.has(field) && this.form.fields[field].message) {
      this.form.fields[field].error = this.form.fields[field].message;
    } else {
      this.form.fields[field].error = validator.errors.first(field);
    }
  };
  setCurrentForm(form: string) {
    this.currentForm = form;
  }
  closeCurrentForm() {
    this.setCurrentForm(null);
  }
  *fetchExtensionById(extensionId: string) {
    try {
      const response: ApiResponse = yield this.loanRequestsService.getExtensionById(extensionId);
      this.extensionByNumber = response.data.data ? response.data.data : [];
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching extension by number.',
      });
    }
  }
  *fetchCurrentExtension() {
    try {
      const response: ApiResponse = yield this.loanRequestsService.getCurrentExtensionByLoanId(this.loanStore.loanDetails.loanId);
      const { data } = response;
      console.log(data)
      this.currentExtension = data.data.rows ? data.data.rows : [];
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching current extension.',
      });
    }
  }
  *fetchExtensionsByLoanId(loanId, callback?: (success: boolean) => void) {
    try {
      const response: ApiResponse = yield this.loanRequestsService.getExtensionsByLoanId(loanId);
      const currentExtension: ApiResponse = yield this.loanRequestsService.getCurrentExtensionByLoanId(loanId);
      const { data } = response;
      this.currentExtension = currentExtension.data.data;
      this.disabledCreateExtesion = !(this.currentExtension == null || this.currentExtension.status === "Extension Completed");
      this.extensionList = data.data.rows ? data.data.rows : [];
      const size = this.extensionList.map((value) => value).length;
      if (size === 0) {
        this.extension = "6";
      } else {
        this.extension = "3";
      }
      callback && callback(true);
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching extensions.',
      });
      callback && callback(false);
    }
  }
  resetExtensionByNumber() {
    this.extensionByNumber = null;
  }
  setSaved(saved) {
    this.saved = saved;
  }

  setExtension(extension) {
    this.extension = extension;
  }
  uploadDocument(
    fileUpload: FileUpload,
    loanId: number,
    onSuccess?: () => void,

  ) {
    try {
      const file = fileUpload.file;
      const blob = file.slice(0, file.size, file.type);
      const newFile = new File([blob], fileUpload.name, { type: file.type });
      const formData = new FormData();
      formData.append('file', newFile);
      this.docName = fileUpload.name;
      this.loanRequestsService.uploadDocument(
        formData,
        loanId
      );
      this.globalStore.notificationStore.showSuccessNotification({
        message: `File uploaded successfully.`,
      });

      if (!onSuccess) return;
      onSuccess();
    } catch (err) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while uploading document.',
      });
    }
  }
  getNewMaturityDate() {
    let newMaturityDate = new Date();
    const monthsToAdd = this.form.fields.extensionDuration.value;

    if (this.loanStore.loanDetails.extendedMaturityDate !== undefined) {
      newMaturityDate = add(new Date(this.loanStore.loanDetails.extendedMaturityDate), {
        months: monthsToAdd,
      });
    } else {
      newMaturityDate = add(new Date(this.loanStore.loanDetails.maturityDate), {
        months: monthsToAdd,
      });
    }
    return newMaturityDate;
  }
  checkWarningsForDashboard(nextDueDate, extendedMaturityDate) {
    const currentDate = new Date();
    if ((this.differenceInMonths(nextDueDate, currentDate)) > 0) {
      return true;
    }
    if ((this.differenceInMonths(extendedMaturityDate, currentDate)) > 0) {
      return true;
    }
    return false;
  }
  checkWarnings(extensionWarnings, nextDueDate, currentDate, extendedMaturityDate) {
    if ((this.getMonthDifference(nextDueDate, currentDate)) > 1) {
      extensionWarnings = "Loan is Delinquent-Payment Due";
    }
    if ((this.getMonthDifference(extendedMaturityDate, currentDate)) > 0 && extensionWarnings === "") {
      extensionWarnings = "Number of Extensions Expired";
    } else if ((this.getMonthDifference(extendedMaturityDate, currentDate)) > 0 && extensionWarnings !== "") {
      extensionWarnings = extensionWarnings.concat(" - Number of Extensions Expired");
    }
    return extensionWarnings;
  }
  checkInsurance() {
    return this.form.fields.preferredInsurance.value || 'Other';
  }
  *approveInsurance(loanDocumentId) {
    try {
      yield this.loanRequestsService.requestExtension({
        loanId: this.extensionByNumber.loanId,
        loanExtensionId: this.extensionByNumber.loanExtensionId,
        dealName: this.extensionByNumber.dealName,
        requestDate: this.extensionByNumber.requestDate,
        executionDate: this.extensionByNumber.executionDate,
        newMaturityDate: this.extensionByNumber.newMaturityDate,
        status: "Insurance Review Completed",
        extensionInsurance: this.extensionByNumber.extensionInsurance,
        extensionDuration: this.extensionByNumber.extensionDuration,
        extensionNumber: this.extensionByNumber.extensionNumber,
        insuranceReviewerId: null
      });
      yield this.loanRequestsService.approveInsurance({
        insuranceExtensionDocumentId: loanDocumentId
      });
      this.globalStore.notificationStore.showSuccessNotification({
        message: `Insurance approved successfully.`,
      });
      this.reset();
      this.saved = true;
      window.location.reload();
    } catch (error) {
      this.globalStore.notificationStore.showSuccessNotification({
        message: `Error while submitting insurance approval`,
      });
    }

  }
  *onFormSubmit() {
    try {
      this.saved = false;
      const { extensionDuration } = this.form.fields;
      const date = new Date();
      const dd = date.getDate();
      const mm = date.getMonth() + 1;
      const yyyy = date.getFullYear();
      const currentDate = new Date(mm + "/" + dd + "/" + yyyy);
      const requestDate = new Date(currentDate);
      const executionDate = new Date(currentDate);
      const extendedMaturityDate = new Date(this.loanStore.loanDetails.extendedMaturityDate);
      const newMaturityDate = this.getNewMaturityDate();

      const nextDueDate = new Date(this.loanStore.loanDetails.calculatedLoanData.nextDueDate);
      let extensionWarnings = "";
      const insurance = this.checkInsurance();
      extensionWarnings = this.checkWarnings(extensionWarnings, nextDueDate, currentDate, extendedMaturityDate);
      console.log(this.docName);
      yield this.loanRequestsService.requestExtension({
        loanId: this.loanStore.loanDetails.loanId,
        dealName: this.loanStore.loanDetails.dealName,
        // requestDate: format(requestDate, 'MM/dd/yyyy'),
        // executionDate: format(executionDate, 'MM/dd/yyyy'),
        newMaturityDate: format(newMaturityDate, 'MM/dd/yyyy'),
        // status,
        extensionWarnings: extensionWarnings,
        extensionInsurance: insurance,
        extensionDuration: extensionDuration.value,
        insuranceDocName: this.docName
      });
      this.fetchExtensionsByLoanId(this.loanStore.loanDetails.loanId);
      if (insurance === 'ElmSure') {
        yield this.loanRequestsService.createElmsureTask({
          loanId: this.loanStore.loanDetails.loanId,
          percentCompleted: this.loanStore.loanDetails.calculatedLoanData.fundedPercent,
          amountDrawBeenTaken: this.loanStore.loanDetails.calculatedLoanData.constructionHoldbackFunded
        });
      }
      this.globalStore.notificationStore.showSuccessNotification({
        message: `Your request for extension has been submitted successfully.`,
      });
      this.loanStore.fetchLoanDetails(this.loanStore.loanDetails.loanId);
      this.reset();
      this.saved = true;
      this.docName = "";
    } catch (error) {
      this.globalStore.notificationStore.showSuccessNotification({
        message: `Error while submitting extension`,
      });
    }
  }
}

export default ExtensionRequestStore;
