import { observer } from 'mobx-react';
import { useStore } from '@roc/client-portal-shared/hooks';
import { Button, Card } from '@roc/ui';
import { makeStyles } from '@material-ui/core/styles';
import { useEffect, useState } from 'react';
import { CompanyDetailsForm } from './companyDetails';
import { DocumentsForm } from 'libs/client-portal-shared/src/app/modules/lenderDetails/components/documents';
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Grid,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  card: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  saveNonFundedLoansButton: {
    padding: '15px'
  }
}));

export const EntityInformation = observer(({ companyProfileStore }) => {
  const { lenderDetailsStore } = companyProfileStore;
  const [editEntityInformation, setEditEntityInformation] = useState(false);
  const [confirmationStep, setConfirmationStep] = useState(null);
  const classes = useStyles();
  const [confirmNonFundedLoans, setConfirmNonFundedLoans] = useState(false);

  useEffect(() => {
    lenderDetailsStore.initializeForm();
  }, []);

  const saveEntityInformation = () => {
    const rmOrCommissionChanged = lenderDetailsStore.validateRMorPercentageChangedForCommissions();
    if (rmOrCommissionChanged) {
      setConfirmationStep('confirm');
    } else {
      lenderDetailsStore.updateEntityInformation();
    }
  };

  const handleStepConfirmation = () => {
    if (confirmNonFundedLoans) {
      if (lenderDetailsStore.validateRMorPercentageChangedForCommissions()) {
        lenderDetailsStore.updateCommissions();
      }
      setConfirmNonFundedLoans(false);
    }
    lenderDetailsStore.updateEntityInformation();
    setConfirmationStep(null);
  };

  const handleCloseConfirmation = () => {
    setConfirmationStep(null);
  };

  const getEditButton = () => {
    return editEntityInformation
      ? undefined
      : () => setEditEntityInformation(true);
  };

  const handleCheckboxChange = (event) => {
    setConfirmNonFundedLoans(event.target.checked);
  };

  return (
    <Card
      title="ENTITY INFORMATION"
      className={classes.card}
      onEdit={getEditButton()}
    >
      <CompanyDetailsForm
        disabled={!editEntityInformation}
        companyProfileStore={companyProfileStore}
      />
      <Dialog
        open={confirmationStep !== null}
        onClose={handleCloseConfirmation}
      >
        <DialogTitle>
          Account Ownership Percentage Change
        </DialogTitle>
        <DialogContent>
          <div>
            <Grid>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={confirmNonFundedLoans}
                    onChange={handleCheckboxChange}
                    color="primary"
                  />
                }
                label="Apply this change to non-funded Loans in the pipeline"
              />
            </Grid>
          </div>
        </DialogContent>

        <DialogActions className={classes.saveNonFundedLoansButton}>
          <Button
            onClick={handleStepConfirmation}
            color="primary"
            variant="contained"
            testId="yes"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {editEntityInformation && (
        <Box m={2} textAlign="right">
          <Button
            style={{ marginRight: 16 }}
            variant="outlined"
            onClick={() => {
              setEditEntityInformation(false);
            }}
            color="default"
            testId="cancel_changes"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              saveEntityInformation();
              setEditEntityInformation(false);
            }}
            color="primary"
            testId="save_changes"
          >
            Save
          </Button>
        </Box>
      )}
      <DocumentsForm companyProfileStore={companyProfileStore} />
    </Card>
  );
});
