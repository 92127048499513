import { Grid } from '@material-ui/core';
import { TermPropertiesGrid, useLoanStore } from '@roc/feature-loans';
import {
  LoanSummaryBorrowerEntitySection,
  LoanSummaryLoanParticipantsSection,
  TermGeneralStatsSection,
  TermLoanTermsSection,
  TermPricingAndRateLockInfoSection,
  TermPropertiesSection,
} from '@roc/feature-loans-shared';
import { useEffect } from 'react';

export const LoanSummaryTerm = ({ data }) => {
  const { loanPricingData = {} } = data;
  const { loanStore } = useLoanStore();

  useEffect(() => {
    loanStore.setPropertiesData(data.properties);
    loanStore.propertiesGridStore.fetchGridData();
  }, []);

  const hasPricingData = !!loanPricingData.pricingDate;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TermLoanTermsSection loanData={data} />
      </Grid>
      <Grid item xs={12} md={6}>
        <TermGeneralStatsSection loanData={data} />
      </Grid>
      {hasPricingData && (
        <Grid item xs={12}>
          <TermPricingAndRateLockInfoSection loanData={data} />
        </Grid>
      )}
      <Grid item xs={12} md={6}>
        <LoanSummaryLoanParticipantsSection loanData={data} loanStore={loanStore} disableEditButton={true} />
      </Grid>
      <Grid item xs={12}>
        <LoanSummaryBorrowerEntitySection loanData={data} />
      </Grid>
      <Grid item xs={12}>
        <TermPropertiesSection loanData={data}>
          <TermPropertiesGrid />
        </TermPropertiesSection>
      </Grid>
    </Grid>
  );
};
