import { Box, Divider, Grid, List, ListItem, Tooltip, Typography } from '@material-ui/core';
import { lighten, makeStyles } from '@material-ui/core/styles';
import { ChatRounded, DescriptionRounded } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { useBorrowerPortalMediaQueries } from '@roc/feature-app-core';
import {
  CopyText,
  IconButton,
  PaginationBar,
  StatusChip,
  StatusType
} from '@roc/ui';
import { formatCurrency } from '@roc/ui/utils';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useBorrowerLoansRoutes } from '../../hooks/useBorrowerLoansRoutes';
import { useBorrowerLoansStore } from '../../hooks/useBorrowerLoansStore';
import { useCommunicationRoutesConfig } from '@roc/feature-communication';

const useStyles = makeStyles(theme => ({
  paginationBar: {
    border: 'none',
    borderTop: '1px solid #eee',
    padding: 16,
  },
  list: {

  },
  listItem: {
    padding: '24px 12px 24px 24px',
    cursor: 'pointer',
    '&:hover': {
      background: lighten(theme.palette.primary.main, 0.9)
    }
  },
  dateHolder: {
    textAlign: 'left',
    [theme.breakpoints.up('lg')]: {
      textAlign: 'right',
    },
  }
}));

export const BorrowerLoansList = observer(() => {
  const { borrowerLoansStore } = useBorrowerLoansStore();
  const { borrowerLoansGridStore } = borrowerLoansStore;
  const gridStore = borrowerLoansGridStore;
  const { borrowerLoanRoutesConfig } = useBorrowerLoansRoutes();
  const classes = useStyles();
  const { push } = useHistory();
  const { isLargeScreen, isSmallScreen } = useBorrowerPortalMediaQueries();
  const { communicationRoutesConfig } = useCommunicationRoutesConfig();

  useEffect(() => {
    borrowerLoansGridStore.resetAndFetchGridData();
  }, []);

  const openLoanPage = loanId => {
    const url = borrowerLoanRoutesConfig.loans(loanId).children.dashboard.url;
    return push(url);
  };

  const createListitem = (x, i, classes, isLastRow) => {
    const classNames = [classes.listItem];
    return (
      <>
        <ListItem key={x.dealid} className={clsx(classNames)} onClick={() => openLoanPage(x.dealid)}>
          <Grid container style={{
            position: 'relative',
            paddingRight: isLargeScreen ? 100 : 48
          }}>
            <Grid item xs={12}>
              <Box mb={0.5} display={'flex'} alignItems={'center'}>
                <Grid container justifyContent='space-around'>
                  <Grid item xs={12}>
                    <Typography variant='body2' color='textSecondary' component={'span'}>
                      Loan# <CopyText>{x.dealid}</CopyText>
                    </Typography>
                    <Typography variant='body2' color='textSecondary' component={'span'} style={{ padding: '0 8px' }}>
                      &#8226;
                    </Typography>
                    <Typography variant='body2' color='textSecondary' component={'span'}>
                      {x.loanSubType}
                    </Typography>
                    {
                      isLargeScreen &&
                      <Typography variant='body2' color='textSecondary' component={'span'} style={{ padding: '0 8px' }}>
                        &#8226;
                      </Typography>
                    }
                    <Box display={isLargeScreen ? 'inline-block' : 'block'}>
                      <Typography variant='body2' color='textSecondary' component={'span'}>
                        <span>Submission Date: </span>
                        <span>{x.submissionDate}</span>
                      </Typography>
                      {
                        !isSmallScreen &&
                        <Typography variant='body2' color='textSecondary' component={'span'} style={{ padding: '0 8px' }}>
                          &#8226;
                        </Typography>
                      }
                      <Box display={!isSmallScreen ? 'inline-block' : 'block'}>
                        <Typography variant='body2' color='textSecondary' component={'span'}>
                          <span>Closing Date: </span>
                          <span>{x.closingDate}</span>
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box mb={0.5}>
                <Box>
                  <Typography component={'span'} style={{
                    fontSize: 15,
                    width: '100%',
                    display: '-webkit-box',
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden'
                  }}>
                    <strong>{x.dealName}</strong>
                  </Typography>
                </Box>
                <Box position={'absolute'} right={0} top={0} display={'flex'} height={'100%'} alignItems={'center'}>
                  <Box>
                    <Tooltip title="View Messages">
                      <Box display={isLargeScreen ? 'inline-block' : 'block'}>
                        <IconButton testId='viewMessages' color="primary" onClick={(e) => {
                          e.stopPropagation();
                          push(communicationRoutesConfig.conversations(x.dealid).url);
                        }}>
                          <ChatRounded />
                        </IconButton>
                      </Box>
                    </Tooltip>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box display={'flex'} alignItems={'center'}>
                <Typography variant='h6' color='textSecondary' component={'span'}>
                  {formatCurrency(Number(x.amount))}
                </Typography>
                <Box component={'span'} px={1}></Box>
                <Box component={'span'}>
                  <StatusChip statusType={StatusType.LOAN} label={x.status} size="small" />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </ListItem>
        {
          isLastRow ? null : <Divider />
        }
      </>
    )
  }

  return (
    <>
      <List className={classes.list}>
        {
          gridStore.isLoading ?
            <ListItem className={classes.listItem}>
              <Box width={'100%'}>
                <Skeleton width="45%" />
                <Skeleton width="60%" />
                <Skeleton width="20%" />
              </Box>
            </ListItem> :
            (
              gridStore.isError ?
                <Box p={3} textAlign={'center'}>
                  <Typography variant='body2' color='error'>
                    Oops, Something went wrong. Please try again later.
                  </Typography>
                </Box> :
                gridStore.gridData?.data?.rows?.map((x, i) => {
                  const isLastRow = (i == gridStore.gridData.data.rows.length - 1);
                  return (createListitem(x, i, classes, isLastRow))
                })
            )
        }
      </List>
      {
        !gridStore.isLoading && !gridStore.isError &&
        <PaginationBar
          {...gridStore.gridData.meta}
          className={classes.paginationBar}
          onChange={(e, v) => gridStore.setPageNumber(v)}
        />
      }
    </>
  );
});
