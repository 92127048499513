import { StandardDialog } from '@roc/ui';
import { observer } from 'mobx-react';
import { Borrower } from '../types';
import { AddBorrowerWithPrequalificationForm } from './addBorrowerWithPrequalificationForm';
import { useEffect } from 'react';
import { isNil } from '@roc/feature-utils';
import { useStore } from '@roc/client-portal-shared/hooks';

export type AddBorrowerWithPrequalificationDialogProps = {
  open: boolean;
  handleClose: () => void;
  handleSaveBorrower: (onSuccess?: () => void) => void;
  borrower?: Borrower;
  title: string;
  borrowerSetupStore: any;
  loanType: string;
  loanId?: number;
  borrowerEntityId?: number;
  refreshLoan?: () => void;
};

export const AddBorrowerWithPrequalificationDialog = observer((props: AddBorrowerWithPrequalificationDialogProps) => {
  const { open, handleClose, borrower, handleSaveBorrower, title, borrowerSetupStore, loanType, loanId, borrowerEntityId, refreshLoan } = props;

  const { tpoAlreadyHaveInformationStore } = useStore();

  useEffect(() => {
    if (borrower) {
      borrowerSetupStore.init(borrower, !isNil(borrower?.personalGuarantor) ? borrower?.personalGuarantor : borrower?.runCreditOnCreation, loanType);
    }
  }, [borrower])

  return (
    <StandardDialog
      maxWidth={'lg'}
      open={open}
      title={title}
      handleClose={handleClose}
      dialogContent={
        <AddBorrowerWithPrequalificationForm
          borrower={borrower}
          handleSaveBorrower={handleSaveBorrower}
          handleClose={handleClose}
          borrowerSetupStore={borrowerSetupStore}
          loanId={loanId}
          borrowerEntityId={borrowerEntityId}
          refreshLoan={refreshLoan}
          tpoAlreadyHaveInformationStore={tpoAlreadyHaveInformationStore}
        />}
    />
  );
});

export default AddBorrowerWithPrequalificationDialog;
