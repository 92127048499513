import { Box, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Layout, Page } from '@roc/feature-app-core';
import { Route } from 'react-router';
import { EmbeddedFrameBoundaries } from '@roc/ui';

import { DelinquencyBpoDashboards } from 'libs/feature-documents/src/documents/components/documentForms/delinquencyBPO/DelinquencyBpoDashboards'

export const getDelinquencyBpoRoutesConfig = (
  basePath: string,
  baseUrl: string,
  loanId: any,
) => {
  return {
    delinquencyBpo: {
      path: `${basePath}/delinquency-bpo`,
      url: `${baseUrl}/delinquency-bpo`,
      documentTitle: 'delinquency-bpo',
    },
  };
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    overflow: 'hidden',
    backgroundColor: 'white',
    margin: '0px 0px',
    padding: theme.spacing(0)
  },
}));

export const getDelinquencyBpoRoutes = (
  basePath: string,
  baseUrl: string,
  loanId: any,
  isTamariskLender: any
) => {
  const config = getDelinquencyBpoRoutesConfig(basePath, baseUrl, loanId);
  const classes = useStyles();

  return {
    delinquencyBpo: (
      <Route exact path={config.delinquencyBpo.path}>
        <Page routeDefinition={config.delinquencyBpo}>
          <Box pl={3} pr={3} style={{ background: 'white', width: '100%' }}>
            <Layout containerClass={classes.container}>
              <EmbeddedFrameBoundaries>
                <DelinquencyBpoDashboards loanId={loanId} isInternal={true} isTamariskLender={false} />
              </EmbeddedFrameBoundaries>
            </Layout>
          </Box>
        </Page>
      </Route>
    ),
  };
}