import { action, makeObservable, observable, override } from 'mobx';
import { MultifamilyLiquidityCalculatorService } from '../services/multifamilyLiquidityCalculatorService';
import { LiquidityCalculatorFormValues } from '../types/multifamilyLiquidityCalculatorTypes';
import { MultifamilyLiquidityCalculatorBaseStore } from './multifamilyLiquidityCalculatorBaseStore';

export class multifamilyLiquidityCalculatorStore extends MultifamilyLiquidityCalculatorBaseStore{
  private MultifamilyLiquidityCalculatorService: MultifamilyLiquidityCalculatorService;

  constructor(globalStore) {
    super(globalStore);
    this.MultifamilyLiquidityCalculatorService = new MultifamilyLiquidityCalculatorService();

    makeObservable(this, {
      getCalculatedLiquidity: override,
      getLoanValues: override,
      getAutocompleteOptions: override,
    });
  }

  *getCalculatedLiquidity(data) {
    const response = yield this.MultifamilyLiquidityCalculatorService.getLiquidity(data);
    return response?.data;
  }

  *getLoanValues(loanId: string): LiquidityCalculatorFormValues {
    const response = yield this.MultifamilyLiquidityCalculatorService.getLoanValues(loanId);
    return response.data.data;
  }

  *getAutocompleteOptions(searchTerm: string) {
    const filters = { dealName: searchTerm };
    const response = yield this.MultifamilyLiquidityCalculatorService.getMultifamilyDealNames(
      1,
      25,
      null,
      null,
      filters
    );
    const rows = response.data.data.rows;
    return rows.map(row => ({
      label: row.dealName,
      value: row.dealid,
    }));
  }
}
