import { Service } from '@roc/feature-app-core';

const url = {
  OPT_OUT: '/nportal/rest/pub/prospectProperty/optOutFromNotifications',
};

export class ManageNotificationsService extends Service {

  optOutFromNotifications(params: string) {
    return this.get(url.OPT_OUT + '?param=' + params);
  }
}