import { Box, Grid, Link, Typography } from '@material-ui/core';
import { lighten } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows';
import PhoneIcon from '@material-ui/icons/Phone';
import { Layout, useAppConfigurations } from '@roc/feature-app-core';
import { Paper } from '@roc/ui';
import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useFixFlipStore } from './hooks/useFixFlipStore';
import { useLoanSubmissionRoutes } from './hooks/useLoanSubmissionRoutes';
import { useMultifamilyStore } from './hooks/useMultiFamilyStore';
import { useRentalPortfolioStore } from './hooks/useRentalPortfolioStore';
import { useSinglePropertyStore } from './hooks/useSinglePropertyStore';
import { useStabilizedBridgeStore } from './hooks/useStabilizedBridgeStore';
import PauseMultifamilySubmissions from './components/pausedMultifamilySubmissions';
import { useBaseStore } from '@roc/feature-app-core';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(4),
  },
  loanTypePaper: {
    padding: theme.spacing(4),
    border: `1px solid ${theme.palette.primary.light}`,
    '&:hover': {
      background: lighten(theme.palette.primary.main, 0.9),
    },
  },
  loanTypePaperStbBridge: {
    padding: theme.spacing(4),
    border: `1px solid ${theme.palette.primary.light}`,
    '&:hover': {
      background: lighten(theme.palette.primary.main, 0.9),
    },
    paddingBottom: '2.8em'
  },
  icon: {
    width: 20,
    height: 20,
  },
}));

export const NewLoanSelection = () => {
  const classes = useStyles();
  const { siteName } = useAppConfigurations();
  const { loanSubmissionRoutesConfig } = useLoanSubmissionRoutes();
  const {
    fixFlipStore,
  } = useFixFlipStore();
  const {
    multiFamilyStore,
  } = useMultifamilyStore();
  const {
    singlePropertyStore,
  } = useSinglePropertyStore();
  const {
    rentalPortfolioStore,
  } = useRentalPortfolioStore();
  const {
    stabilizedBridgeStore,
  } = useStabilizedBridgeStore();
  const { globalStore } = useBaseStore();
  const [isPauseMultifamilySubmissionsOpen, setIsPauseMultifamilySubmissionsOpen] = useState(false);

  return (
    <Layout maxWidth={'md'}>
      <Paper className={classes.paper}>
        <Box mb={4}>
          <Typography align="center" variant="h3">
            Submit a Loan
          </Typography>
        </Box>
        <Box mb={4} style={{ display: 'none' }}>
          <Typography align="center" variant="body1">
            Welcome to {siteName}, we're very glad to assist you through this
            process.
          </Typography>
          <Typography align="center" variant="body1">
            If you have any questions, we're open to help you at any point.
          </Typography>
        </Box>
        <Box mb={4}>
        </Box>
        <Box my={4} mt={8}>
          <Typography align="center" variant="h4" color="primary">
            BRIDGE LOANS
          </Typography>
        </Box>
        <Box mx={'auto'} mb={4} maxWidth={500}>
          <Grid container direction="row" justifyContent="center" spacing={4}>
            <Grid item xs={12} md={6}>
              <RouterLink
                to={loanSubmissionRoutesConfig.submitloan.children.fixFlip.url}
                onClick={() => fixFlipStore.reset()}
              >
                <Paper className={classes.loanTypePaper}>
                  <Typography align="center" variant="h6" gutterBottom>
                    FIX & FLIP
                  </Typography>
                  <Typography
                    align="center"
                    variant="body2"
                    color="textSecondary"
                  >
                    Residential (1-4 Units)
                  </Typography>
                </Paper>
              </RouterLink>
            </Grid>
            <Grid item xs={12} md={6}>
              <RouterLink
                to={loanSubmissionRoutesConfig.submitloan.children.groundUp.url}
                onClick={() => fixFlipStore.reset()}
              >
                <Paper className={classes.loanTypePaper}>
                  <Typography align="center" variant="h6" gutterBottom>
                    GROUND UP
                  </Typography>
                  <Typography
                    align="center"
                    variant="body2"
                    color="textSecondary"
                  >
                    Residential (1-4 Units)
                  </Typography>
                </Paper>
              </RouterLink>
            </Grid>
            <Grid item xs={12} md={6}>
              <RouterLink
                to={
                  loanSubmissionRoutesConfig.submitloan.url
                }
                onClick={() => setIsPauseMultifamilySubmissionsOpen(true)}
              >
                <Paper className={classes.loanTypePaper}>
                  <Typography align="center" variant="h6" gutterBottom>
                    MULTIFAMILY BRIDGE
                  </Typography>
                  <Typography
                    align="center"
                    variant="body2"
                    color="textSecondary"
                  >
                    5+ Unit Multifamily
                  </Typography>
                </Paper>
              </RouterLink>
            </Grid>
            <PauseMultifamilySubmissions open={isPauseMultifamilySubmissionsOpen} handleClose={() => setIsPauseMultifamilySubmissionsOpen(false)}/>
            <Grid item xs={12} md={6}>
              <RouterLink
                to={
                  loanSubmissionRoutesConfig.submitloan.children
                    .stabilizedBridge.url
                }
                onClick={() => stabilizedBridgeStore.reset()}
              >
                <Paper className={classes.loanTypePaperStbBridge}>
                  <Typography align="center" variant="h6" gutterBottom>
                    STABILIZED BRIDGE
                  </Typography>
                  <Typography
                    align="center"
                    variant="body2"
                    color="textSecondary"
                  >
                    Single Family / 2-4 Unit /  Warrantable Condos

                  </Typography>
                </Paper>
              </RouterLink>
            </Grid>
            {globalStore.lenderInfo?.showFixFlipProSubtype && (
              <Grid item xs={12} md={6}>
                <RouterLink
                  to={loanSubmissionRoutesConfig.submitloan.children.fixFlipPro.url}
                  onClick={() => fixFlipStore.reset()}
                >
                  <Paper className={classes.loanTypePaper}>
                    <Typography align="center" variant="h6" gutterBottom>
                      FIX & FLIP PRO
                    </Typography>
                    <Typography
                      align="center"
                      variant="body2"
                      color="textSecondary"
                    >
                      Residential (1-4 Units)
                    </Typography>
                  </Paper>
                </RouterLink>
              </Grid>)
            }
          </Grid>
        </Box>

        <Box my={4} mt={8}>
          <Typography align="center" variant="h4" color="primary">
            TERM LOANS
          </Typography>
        </Box>
        <Box mx={'auto'} mb={4} maxWidth={500}>
          <Grid container direction="row" justifyContent="center" spacing={4}>
            <Grid item xs={12} md={6}>
              <RouterLink
                to={
                  loanSubmissionRoutesConfig.submitloan.children.singleProperty
                    .url
                }
                onClick={() => singlePropertyStore.reset()}
              >
                <Paper className={classes.loanTypePaper}>
                  <Typography align="center" variant="h6" gutterBottom>
                    SINGLE PROPERTY
                  </Typography>
                  <Typography
                    align="center"
                    variant="body2"
                    color="textSecondary"
                  >
                    Single Family / 2-4 Unit / Townhomes / PUD / Warrantable
                    Condos / 5+ Unit Multifamily / Mixed Use
                  </Typography>
                </Paper>
              </RouterLink>
            </Grid>
            <Grid item xs={12} md={6}>
              <RouterLink
                to={
                  loanSubmissionRoutesConfig.submitloan.children.rentalPortfolio
                    .url
                }
                onClick={() => rentalPortfolioStore.reset()}
              >
                <Paper className={classes.loanTypePaper}>
                  <Typography align="center" variant="h6" gutterBottom>
                    RENTAL PORTFOLIOS
                  </Typography>
                  <Typography
                    align="center"
                    variant="body2"
                    color="textSecondary"
                  >
                    Single Family / 2-4 Unit / Townhomes / PUD / Warrantable
                    Condos / 5+ Unit Multifamily / Mixed Use
                  </Typography>
                </Paper>
              </RouterLink>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Layout>
  );
};
