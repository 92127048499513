import {
  Box,
  Grid,
  Typography,
} from '@material-ui/core';
import { Add, Delete, Edit } from '@material-ui/icons';
import { DateFormat, formatDate, parseDate } from '@roc/feature-utils';
import {
  ActionsColumn,
  AgGridColumnProps,
  AutocompleteField,
  Button,
  createCurrencyField,
  createDateField,
  DataGrid,
  FieldLabel,
  StandardDialog,
  Toolbar,
} from '@roc/ui';
import { formatCurrency } from '@roc/ui/utils';
import { observer } from 'mobx-react';
import { useForeclosureSummaryStore } from '../../hooks/useForeclosureSummaryStore';
import { DetailsCard } from './detailsCard';
import moment from 'moment';
import { foreclosureStages } from '../../utils/constans';


export const RealEstateOwnedDetailsCard = observer(() => {
  const { foreclosureSummaryStore } = useForeclosureSummaryStore();
  const { realEstateOwnedDetailsStore } = foreclosureSummaryStore;

  const data = foreclosureSummaryStore?.foreclosureSummary?.reoDetails;

  return (
    <>
      <DetailsCard
        title={'Real Estate Owned Details'}
        onEdit={e => {
          e.stopPropagation();
          realEstateOwnedDetailsStore.openOwnedDetailsDialog(data);
        }}
        data={[
          {
            name: 'Deed Recorded',
            value: data?.deedRecordedDate
              ? formatDate(data?.deedRecordedDate, DateFormat.MMDDYYYY)
              : 'N/A',
          },
          {
            name: 'Property Listed',
            value: data?.propertyListedDate
              ? formatDate(data?.propertyListedDate, DateFormat.MMDDYYYY)
              : 'N/A',
          },
          {
            name: 'Contract Price',
            value: data?.contractPrice
              ? formatCurrency(data?.contractPrice)
              : 'N/A',
          },
          {
            name: 'Broker',
            value: data?.broker
              ? data?.broker
              : 'N/A',
          },
          {
            name: '',
            value: (
              <>
                <Typography variant="body2" color="textSecondary" component="p">
                  Listed Prices
                </Typography>
                {data?.propertyListing.map(item => (
                  <Box px={0.5}>
                    <Typography
                      style={{
                        fontSize: '14px',
                        fontWeight: 'bold',
                        display: 'inline-flex',
                        justifyContent: 'center',
                      }}
                    >
                      <Box pr={1} flex={1} textAlign="right">
                        {item.date
                          ? formatDate(item.date, DateFormat.MMDDYYYY)
                          : 'N/A'}
                      </Box>
                      <Box pl={1} flex={1}>
                        {item.price ? formatCurrency(item.price) : 'N/A'}
                      </Box>
                    </Typography>
                  </Box>
                ))}
              </>
            ),
          },
        ]}
        columnsNum={1}
        expanded={foreclosureSummaryStore?.foreclosureSummary?.foreclosureStage == foreclosureStages.REO}
      />
      <EditRealEstateOwnedDetailsModal />
      <EditListedPricesDialog />
    </>
  );
});

const EditRealEstateOwnedDetailsModal = observer(() => {
  const { foreclosureSummaryStore } = useForeclosureSummaryStore();
  const { realEstateOwnedDetailsStore } = foreclosureSummaryStore;
  const store = realEstateOwnedDetailsStore.ownedDetailsFormStore;
  const brokerListOptions = foreclosureSummaryStore?.foreclosureSummary?.reoBrokers?.map(value => ({
    label: value.brokerName,
    value: value.reoBrokerId
  }));
  const handleCancel = () => {
    realEstateOwnedDetailsStore.closeOwnedDetailsDialog();
  };

  const handleSave = async () => {
    await realEstateOwnedDetailsStore.saveOwnedDetails(foreclosureSummaryStore?.foreclosureSummary?.loanId);
    foreclosureSummaryStore.getSummary(foreclosureSummaryStore?.foreclosureSummary?.loanId);
  };

  const onDateChange = (fieldName, value) => {
    store.onFieldChange(fieldName, moment(value).format("MM/DD/YYYY"));
  }

  const dialogContent = (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {createDateField({
          store,
          testId: 'deedRecordedDate',
          fieldName: 'deedRecordedDate',
          label: 'Deed Recorded',
          onChange: onDateChange,
        })}
      </Grid>
      <Grid item xs={12}>
        {createDateField({
          store,
          testId: 'propertyListedDate',
          fieldName: 'propertyListedDate',
          label: 'Property Listed',
          onChange: onDateChange,
        })}
      </Grid>
      <Grid item xs={12}>
        {createCurrencyField({
          store,
          testId: 'contractPrice',
          fieldName: 'contractPrice',
          label: 'Contract Price',
        })}
      </Grid>
      <Grid item xs={12}>
        <FieldLabel >
          Broker
        </FieldLabel>
        <AutocompleteField
          label=""
          placeHolder=''
          options={brokerListOptions}
          value={store.form.fields?.broker?.value ? store.form.fields?.broker?.value : ''}
          onChange={(newValue) => {
            store.onFieldChange('broker', newValue);
          }}
          onSelect={(option: any) => {
            store.onFieldChange('broker', option.label);
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Box mt={2}>
          <Typography variant="h6">Listed Prices</Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <ListedPricesGrid />
      </Grid>
    </Grid>
  );

  return (
    <StandardDialog
      title={'Real Estate Owned Details'}
      open={realEstateOwnedDetailsStore.isOwnedDetailsDialogOpen}
      handleClose={handleCancel}
      maxWidth="md"
      dialogContent={dialogContent}
      removeContentBox
      dialogActions={
        <>
          <Button
            style={{ marginRight: '8px' }}
            onClick={handleCancel}
            color="primary"
            variant="outlined"
            testId="cancel"
          >
            Cancel
          </Button>
          <Button
            onClick={handleSave}
            color="primary"
            variant="contained"
            testId="save"
          >
            Save
          </Button>
        </>
      }
    />
  );
});

const EditListedPricesDialog = observer(() => {
  const { foreclosureSummaryStore } = useForeclosureSummaryStore();
  const { realEstateOwnedDetailsStore } = foreclosureSummaryStore;
  const store = realEstateOwnedDetailsStore.listedPricesFormStore;

  const onDateChange = (fieldName, value) => {
    store.onFieldChange(fieldName, moment(value).format("MM/DD/YYYY"));
  }

  const handleCancel = () => {
    realEstateOwnedDetailsStore.closeListedPricesDialog();
  };

  const handleSave = () => {
    realEstateOwnedDetailsStore.saveListedPrice();
  };

  const dialogContent = (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {createDateField({
          store,
          testId: 'date',
          fieldName: 'date',
          label: 'Date',
          onChange: onDateChange,
        })}
      </Grid>
      <Grid item xs={12}>
        {createCurrencyField({
          store,
          testId: 'price',
          fieldName: 'price',
          label: 'Price',
        })}
      </Grid>
    </Grid>
  );

  return (
    <StandardDialog
      title={'Real Estate Owned Details'}
      open={realEstateOwnedDetailsStore.isListedPricesDialogOpen}
      handleClose={handleCancel}
      maxWidth="md"
      dialogContent={dialogContent}
      removeContentBox
      dialogActions={
        <>
          <Button
            style={{ marginRight: '8px' }}
            onClick={handleCancel}
            color="primary"
            variant="outlined"
            testId="cancel"
          >
            Cancel
          </Button>
          <Button
            onClick={handleSave}
            color="primary"
            variant="contained"
            testId="save"
          >
            Save
          </Button>
        </>
      }
    />
  );
});

const ListedPricesGrid = observer(() => {
  const { foreclosureSummaryStore } = useForeclosureSummaryStore();
  const { realEstateOwnedDetailsStore } = foreclosureSummaryStore;
  const listedPrices =
    realEstateOwnedDetailsStore.ownedDetailsFormStore.form.fields.propertyListing
      .value;
  const rows = [...listedPrices].sort(
    (a, b) => parseDate(a.date).getTime() - parseDate(b.date).getTime()
  );

  const listedPriceColumns: AgGridColumnProps[] = [
    {
      field: 'date',
      headerName: 'Date',
      cellRenderer: 'dateCellRenderer',
    },
    {
      field: 'price',
      headerName: 'Price',
      cellRenderer: 'priceCellRenderer',
    },
    {
      field: 'id',
      headerName: '',
      cellRenderer: 'actionsCellRenderer',
      maxWidth: 140,
    },
  ];

  const toolbar = (
    <Toolbar>
      <Button
        variant="contained"
        size="small"
        onClick={() => realEstateOwnedDetailsStore.openListedPricesDialog()}
        color="primary"
        testId="add_new_borrower"
      >
        <Add scale="small" />
        &nbsp;Add
      </Button>
    </Toolbar>
  );

  const frameworkComponents = {
    dateCellRenderer({ value }) {
      return value ? moment(value).format("MM/DD/YYYY") : '';
    },
    priceCellRenderer({ value }) {
      return value ? formatCurrency(value) : '';
    },
    actionsCellRenderer(params) {
      const row = params.node.data;
      const actions = [
        {
          icon: <Edit color="primary" scale="small" />,
          onClick: () =>
            realEstateOwnedDetailsStore.openListedPricesDialog(row),
        },
        {
          icon: <Delete color="error" scale="small" />,
          onClick: () => realEstateOwnedDetailsStore.deleteListedPrice(row),
        },
      ];
      return ActionsColumn({ ...params, actions });
    },
  };

  return (
    <DataGrid
      domLayout="autoHeight"
      toolbar={toolbar}
      columns={listedPriceColumns}
      frameworkComponents={frameworkComponents}
      rows={rows}
    />
  );
});
