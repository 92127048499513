import { Page, useBaseStore } from '@roc/feature-app-core';
import { Route } from 'react-router';
import { BorrowerDetailedScreen } from '../components/borrowerDetails/borrowerDetailedScreen';

const BACK_OFFICE_BASE_PATH = 'backoffice';

export const getBorrowerDetailsRoutesConfig = (basePath: string, baseUrl: string) => {
  return {
    borrowerDetails: (borrowerId?: string) => ({
      path: `${basePath}/${BACK_OFFICE_BASE_PATH}/borrower-details/:borrowerId`,
      url: `${baseUrl}/${BACK_OFFICE_BASE_PATH}/borrower-details/${borrowerId}`,
      documentTitle: `Borrower Details`,
    })
  }
};

export const getBorrowerDetailsRoutes = (basePath: string, baseUrl: string) => {
  const config = getBorrowerDetailsRoutesConfig(basePath, baseUrl);
  return {
    borrowerDetails: (
      <Route exact path={config.borrowerDetails().path}>
        <Page routeDefinition={config.borrowerDetails()}>
          <BorrowerDetailedScreen />
        </Page>
      </Route>
    )
  };
};

export const useBorrowerDetailsRoutes = () => {
  const { globalStore } = useBaseStore();
  const { routeBasePath, routeBaseUrl } = globalStore;
  return {
    borrowerDetailsRoutes: getBorrowerDetailsRoutes(routeBasePath, routeBaseUrl),
    borrowerDetailsRoutesConfig: getBorrowerDetailsRoutesConfig(
      routeBasePath,
      routeBaseUrl
    ),
  };
};