import { makeStyles } from '@material-ui/core/styles';
import {
  DataGrid as Grid, FilterButton, GridColumnProps, SelectFilterComponent,
  SelectFloatingFilterComponent, SelectMode, Toolbar
} from '@roc/ui';
import { formatPhoneNumber } from '@roc/ui/utils';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { useBrokersStore } from '../../hooks/useBrokersStore';

const noOpComparator = () => 0;

const columns: GridColumnProps[] = [
  {
    field: 'entityName',
    headerName: 'Company Name',
    minWidth: 100,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'entityTaxNumber',
    headerName: 'Company Ein Number',
    minWidth: 250,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
];

const useStyles = makeStyles(() => ({
  container: {
    height: 435,
  },
}));

export const SelectBrokerGrid = observer(() => {
  const classes = useStyles();
  const { brokersStore } = useBrokersStore();
  const { brokersGridStore: gridStore } = brokersStore;

  useEffect(() => {
    gridStore.fetchGridData();
  }, []);

  const frameworkComponents = {
    selectFilter: SelectFilterComponent,
    selectFloatingFilter: SelectFloatingFilterComponent,
    phoneCellRenderer: ({ value }) => formatPhoneNumber(value),
  };

  const onFilterChanged = params => {
    const filterModel = params.api.getFilterModel();
    gridStore.setFilterModel(filterModel);
  };

  const toolbar = (
    <Toolbar>
      <FilterButton
        onClearFilters={() => gridStore.resetFilters()}
        onToggleFilters={() => gridStore.toggleFilters()}
      />
    </Toolbar>
  );

  console.log(gridStore.gridData.data.rows);
  return (
    <Grid
      className={classes.container}
      selectMode={SelectMode.SINGLE}
      onRowSelected={e => gridStore.onRowSelected(e)}
      columns={columns}
      rows={gridStore.gridData.data.rows}
      frameworkComponents={frameworkComponents}
      isLoading={gridStore.isLoading}
      domLayout="normal"
      onFilterChanged={onFilterChanged}
      showFilters={gridStore.showFilters}
      sortModel={gridStore.sortModel}
      filterModel={gridStore.filterModel}
      paginationData={gridStore.gridData.meta}
      setPageNumber={gridStore.setPageNumber}
      toolbar={toolbar}
    />
  );
});

export default SelectBrokerGrid;