import React, { ReactNode } from 'react';
import {
  Grid,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';

export interface ExpandableCardProps {
  title: string;
  children: ReactNode;
  onExpand?: () => void;
  expanded: boolean;
}

export const ExpandableCard = (props: ExpandableCardProps) => {
  const { title, children, onExpand, expanded } = props;

  return (
    <Grid item xs={12}>
      <Accordion
        expanded={expanded}
        variant="outlined"
        TransitionProps={{ unmountOnExit: true }}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          onClick={onExpand}
        >
          <Typography variant="button">{title}</Typography>
        </AccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
      </Accordion>
    </Grid>
  );
};
