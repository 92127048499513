import { action, makeObservable } from 'mobx';
import { GlobalStore } from '@roc/feature-app-core';
import { FormStore } from '@roc/feature-app-core';

const form = {
  fields: {
    status: {
      value: '',
      error: null,
      rule: 'required',
      message: 'Loan status is a required field',
    },
    reasonForStatus: {
      value: '',
      error: null,
      rule: [{ required_if_one_of: ['status', 'RETRACTED', 'ON_HOLD'] }],
      message: 'Reason for status is a required field',
    },
    statusChangeComment: {
      value: '',
      error: null,
      rule: [{ required_if: ['__isOtherReasonForStatus__', true] }],
      message: 'Further explanation is a required field',
    },
    __isOtherReasonForStatus__: {
      value: null,
      error: null,
      rule: '',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export class ChangeStatusStore extends FormStore {
  globalStore: GlobalStore;

  constructor(globalStore) {
    super({ ...form }, globalStore);

    makeObservable(this, {
      handleReasonForStatusChange: action,
    });
  }

  handleReasonForStatusChange(value) {
    this.onFieldChange('reasonForStatus', value);
    const isOtherOptionSelected = value?.toLowerCase().includes('other');
    this.onFieldChange('__isOtherReasonForStatus__', isOtherOptionSelected);
  }
}

export default ChangeStatusStore;
