import { Button, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { ExperienceLevel, Layout, QualifyingFicoScore } from '@roc/client-portal-shared/components';
import { useStore } from '@roc/client-portal-shared/hooks';
import { Paper } from '@roc/ui';
import { observer } from 'mobx-react';
import React, { useEffect, useMemo, useState } from 'react';
import { BridgePricer as BridgePricerInternal } from './components/bridgePricer';
import { ClientBridgePricer } from './components/clientBridgePricer';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  tabs: {},
  calculatedValuesBorder: {
    border: '1px solid #BEBEBE',
    borderRadius: '15px',
    padding: 16,
  },
}));

export const BridgePricer = observer(({ bridgePricerStore }) => {
  const classes = useStyles();
  return (
    <Layout title="Bridge Loan Pricer" >
      <Paper className={classes.paper}>
        {!bridgePricerStore.isClientPortal && (
          <BridgePricerInternal store={bridgePricerStore} />
        )}
        {bridgePricerStore.isClientPortal && (
          <ClientBridgePricer store={bridgePricerStore} />
        )}
      </Paper>
    </Layout>
  );
}
);