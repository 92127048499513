import { computed, flow, makeObservable, observable } from 'mobx';
import { GlobalStore, GridStore } from '../../../stores';
import { FormStore } from '@roc/feature-app-core';
import { CompanyProfileService } from '../../companyProfile/services/CompanyProfileService';
import { LenderContactDialogStore } from './lenderContactDialogStore';
import { LenderContact } from '../../companyProfile/types/companyProfileTypes';

export class LenderContactsStore {
  private globalStore: GlobalStore;
  private companyProfileService: CompanyProfileService;

  lenderContactDialogStore: LenderContactDialogStore;
  lenderPrincipalsGridStore: GridStore;

  isReady = false;

  constructor(globalStore) {
    this.globalStore = globalStore;

    this.companyProfileService = new CompanyProfileService();
    this.lenderPrincipalsGridStore = new GridStore(() =>
      this.fetchLenderPrincipals()
    );
    this.lenderContactDialogStore = new LenderContactDialogStore(globalStore);

    makeObservable(this, {
      isReady: observable,
      totalOwnership: computed,
      loadData: flow,
      submitAddContact: flow,
      submitEditContact: flow,
      deleteContact: flow,
    });
  }

  private async fetchLenderPrincipals() {
    const response = await this.companyProfileService.getMyLenderPrincipals();
    this.isReady = true;
    return {
      data: {
        data: {
          rows: response.data?.data,
          totalCount: response.data?.data?.length,
        },
      },
      headers: null,
    };
  }

  get totalOwnership() {
    return (this.lenderPrincipalsGridStore.gridData.data.rows ?? [])
      .map(item => item.Ownership__c ?? 0)
      .reduce((p, n) => p + n, 0);
  }

  *loadData() {
    yield this.lenderPrincipalsGridStore.fetchGridData();
  }

  *submitAddContact() {
    try {
      this.lenderContactDialogStore.runFormValidationWithMessage();
      if (this.lenderContactDialogStore.form.meta.isValid) {
        this.lenderContactDialogStore.closeDialog();
        const contact: LenderContact = this.lenderContactDialogStore.getFormValues();
        const response = yield this.companyProfileService.createLenderContact(contact);
        const data = response.data.data;
        if (typeof data === 'string' && data.startsWith('Error')) {
          this.globalStore.notificationStore.showErrorNotification({
            message: data,
          });
        } else {
          this.lenderPrincipalsGridStore.resetAndFetchGridData();
          this.globalStore.notificationStore.showSuccessNotification({
            message: 'Contact added successfully.',
          });
        }
      }
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while adding a new contact.',
      });
    }
  }

  *submitEditContact() {
    try {
      this.lenderContactDialogStore.runFormValidationWithMessage();
      if (this.lenderContactDialogStore.form.meta.isValid) {
        this.lenderContactDialogStore.closeDialog();
        const contact: LenderContact = this.lenderContactDialogStore.getUpdatedContact();

        yield this.companyProfileService.updateLenderContact(
          contact.Id,
          contact
        );
        this.lenderPrincipalsGridStore.resetAndFetchGridData();
        this.globalStore.notificationStore.showSuccessNotification({
          message: 'Contact updated successfully.',
        });
      }
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while updating a contact.',
      });
    }
  }

  *deleteContact(contact: LenderContact) {
    try {
      yield this.companyProfileService.removeLenderContact(contact.Id);
      this.lenderPrincipalsGridStore.resetAndFetchGridData();
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Contact removed successfully.',
      });
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while removing a contact.',
      });
    }
  }
}
