import React, { useState, useEffect } from 'react';
import {
  Grid,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { SelectField } from '@roc/ui';
import { useLoanStore } from '@roc/feature-loans-shared';
import { useDrawsStore } from '../../hooks';
import { observer } from 'mobx-react';

const useStyles = makeStyles(() => ({
  container: {
    marginBottom: '24px'
  },
  dropDownSection: {
    marginTop: 8,
    display: 'flex',
    flexDirection: 'column',
  },
  selectContainer: {
    marginTop: 8,
  },
}));

interface PropertyValuesNewProps {
}

export const PropertyValuesNew = observer((props: PropertyValuesNewProps) => {
  const {
    loanStore: { loanDetails },
  } = useLoanStore();
  const { drawRequestStore } = useDrawsStore();
  const classes = useStyles();
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    if (loanDetails?.properties?.length) {
      setOptions(loanDetails.properties.map(property => {
        return { label: property.address, value: property }
      }));
    }
  }, [loanDetails])

  useEffect(() => {
    if (selectedOption) {
      drawRequestStore.property = selectedOption;
    } else {
      drawRequestStore.property = null;
    }
  }, [selectedOption, drawRequestStore.property]);

  const onePropertyLoan = () => {
    drawRequestStore.property = options[0].value;
    return null;
  }
  return (
    <>
      {
        options.length === 1 ? onePropertyLoan() : options.length === 0 ? null : (
          <Grid item container xs={12} md={12} className={classes.container}>
            <Grid container>
              <Grid item xs={12} md={12}>
                <Typography variant="h6">PROPERTY</Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <Grid container direction="column">
                  <Grid item>
                    <Typography variant="caption" color="textSecondary">
                      The property you select will get the Draw Request started.
                    </Typography>
                  </Grid>
                  <Grid item className={classes.dropDownSection}>
                    <p>Select A Property for the Draw</p>
                    <div className={classes.selectContainer}>
                      <SelectField
                        label="Property"
                        name="property"
                        value={selectedOption || ''}
                        options={options}
                        onChange={value => setSelectedOption(value)}
                        variant="outlined"
                        fullWidth
                        shrink
                        testId="useCode"
                      />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )
      }
    </>
  );
});

export default PropertyValuesNew;
