import React, { forwardRef, useRef, useImperativeHandle } from 'react';
import { Grid, GridProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

export type ScreenshotGridProps = GridProps & {
  fileName?: string;
};

const useStyles = makeStyles({
  container: {
    '&.screenshot-el *': {
      boxShadow: 'none',
    },
  },
});

const afterConvertToCanvas = (fileName, canvas, domEl) => {
  domEl.classList.remove('screenshot-el');

  const pdf = new jsPDF(
    domEl.offsetWidth < domEl.offsetHeight ? 'p' : 'l',
    'pt',
    [domEl.offsetWidth, domEl.offsetHeight]
  );
  const {
    internal: { pageSize },
  } = pdf;

  pdf.addImage(
    canvas.toDataURL('image/jpeg'),
    'JPEG',
    0,
    0,
    pageSize.getWidth(),
    pageSize.getHeight()
  );
  pdf.save(fileName);
};

export const ScreenshotGrid = forwardRef((props: ScreenshotGridProps, ref) => {
  const { fileName = 'file.pdf', ...rest } = props;

  const classes = useStyles();

  const containerRef = useRef(null);

  const downloadPdf = async () => {
    containerRef.current.classList.add('screenshot-el');
    const canvas = await html2canvas(containerRef.current, {
      scrollX: 0,
      scrollY: 0,
      useCORS: true,
    });
    afterConvertToCanvas(fileName, canvas, containerRef.current);
  };

  useImperativeHandle(ref, () => ({
    download() {
      return downloadPdf();
    },
  }));

  return <Grid className={classes.container} ref={containerRef} {...rest} />;
});

export default ScreenshotGrid;
