import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { Paper } from '@roc/ui';
import { Layout } from '@roc/feature-app-core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(3, 0, 4),
      borderRadius: theme.spacing(2),
      boxShadow:
        'rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px',
    },
  })
);

export const LoanFastrackPaymentComplete: React.FC = () => {
  const classes = useStyles();

  return (
    <Layout>
      <Box mb={2} mt={3}>
        <Typography variant="h3" gutterBottom>
          Payment Completed
        </Typography>
      </Box>
      <Paper className={classes.paper}>
        <Box mt={3} px={4}>
          <Grid container direction="column" justifyContent="center">
            <Box textAlign="center">
              <Typography variant="h4">
                Your payment for fast tracking the loan was successfully
                processed!
              </Typography>
            </Box>
            <Box mt={6} textAlign="center">
              <Typography variant="body1">
                Thank you for completing the payment for fast tracking the loan.
              </Typography>
            </Box>
            <Box mt={2} textAlign="center">
              <Typography variant="body1">
                If you need further assistance, please contact us at:
              </Typography>
            </Box>
            <Box mt={2} textAlign="center">
              <Typography variant="h6">+1 212-607-8333</Typography>
            </Box>
          </Grid>
        </Box>
      </Paper>
    </Layout>
  );
};

export default LoanFastrackPaymentComplete;
