import { Property } from '@roc/feature-types';
import { getUniqueId } from '@roc/feature-utils';
import { GlobalStore, FormStore } from '@roc/feature-app-core';
import { makeObservable, flow, observable, action } from 'mobx';
import { parseAddress } from '@roc/feature-utils';

const REQUIRED_MESSAGE = 'This field is required';

const propertyForm = {
  fields: {
    address: {
      value: '',
      error: null,
      rule: 'required',
      message: REQUIRED_MESSAGE,
    },
    __isAddressInCorrectFormat__: {
      value: true,
      error: null,
      rule: 'accepted',
      message: 'Invalid Address Format',
    },
    streetNumber: {
      value: '',
      error: null,
      rule: '',
    },
    streetName: {
      value: '',
      error: null,
      rule: '',
    },
    city: {
      value: '',
      error: null,
      rule: '',
    },
    state: {
      value: '',
      error: null,
      rule: '',
    },
    zipCode: {
      value: '',
      error: null,
      rule: '',
    },
    latitude: {
      value: 0,
      error: null,
      rule: '',
    },
    longitude: {
      value: 0,
      error: null,
      rule: '',
    },
    acquisition: {
      value: 'Purchase',
      error: null,
      rule: 'required',
      message: REQUIRED_MESSAGE,
    },
    acquisitionPrice: {
      value: '',
      error: null,
      rule: 'required',
      message: REQUIRED_MESSAGE,
    },
    acquisitionDate: {
      value: '',
      error: null,
      rule: '',
    },
    exitStrategy: {
      value: '',
      error: null,
      rule: 'required',
      message: REQUIRED_MESSAGE,
    },
    renovationBudget: {
      value: '',
      error: null,
      rule: 'required',
      message: REQUIRED_MESSAGE,
    },
    describeRenovation: {
      value: '',
      error: null,
      rule: 'required',
    },
    asIsValue: {
      value: '',
      error: null,
      rule: '',
    },
    afterRepairValue: {
      value: '',
      error: null,
      rule: 'required',
    },
    propertyDescription: {
      value: '',
      error: null,
      rule: 'required',
      message: REQUIRED_MESSAGE,
    },
    addingSquareFootage: {
      value: '',
      error: null,
      rule: 'required',
      message: REQUIRED_MESSAGE,
    },
    wholesaleAmount: {
      value: '',
      error: null,
      rule: '',
    },
    wholesalerPurchase: {
      value: '',
      error: null,
      rule: '',
    },
    propertySourcingExplanation: {
      value: '',
      error: null,
      rule: '',
    },
    propertySourcing: {
      value: '',
      error: null,
      rule: 'required_if:acquisition,Purchase',
      message: REQUIRED_MESSAGE,
    },
    payoffAmount: {
      value: '',
      error: null,
      rule: '',
    },
    marketRent: {
      value: '',
      error: null,
      rule: '',
    },
    inPlaceRent: {
      value: '',
      error: null,
      rule: '',
    },
    sunkCost: {
      value: '',
      error: null,
      rule: '',
    },
    useCode: {
      value: '',
      error: null,
      rule: '',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

class PropertyFormStore extends FormStore {
  private globalStore: GlobalStore;
  constructor(globalStore){
    super({...propertyForm}, globalStore);
    this.globalStore = globalStore;

  }

  private saveAddressField(address: string, addressDetails, geometryDetails) {
    const parsedAddress = parseAddress(
      address,
      addressDetails,
      geometryDetails
    );
    this.onFieldChange('address', address);
    this.onFieldChange('streetNumber', parsedAddress.street_number);
    this.onFieldChange('streetName', parsedAddress.street);
    this.onFieldChange('city', parsedAddress.city);
    this.onFieldChange('state', parsedAddress.state);
    this.onFieldChange('zipCode', parsedAddress.zip);
    this.onFieldChange('latitude', parsedAddress.latitude);
    this.onFieldChange('longitude', parsedAddress.longitude);

    const addressInCorrectFormat = parsedAddress.street_number !== null && parsedAddress.street !== null && parsedAddress.city !== null && parsedAddress.state !== null && parsedAddress.zip !== null;
    this.onFieldChange('__isAddressInCorrectFormat__', addressInCorrectFormat);
  }
}

export class PropertyStore {
  propertyId: string;
  globalStore: GlobalStore;
  propertyForm: PropertyFormStore;

  constructor(globalStore) {
    this.globalStore = globalStore;
    this.propertyForm = new PropertyFormStore(this.globalStore);
    makeObservable(this, {
      propertyId: observable,
      setPropertyId: action,
      reset: action,
      loadProperty: action,
      getProperty: action,
    });
  }

  loadProperty(property: Property, runValidations: boolean = true) {
    this.setPropertyId(property.propertyId);
    this.propertyForm.loadForm(property);
    if (runValidations) {
      this.runAllFormValidations();
    }
  }

  runAllFormValidations() {
    this.propertyForm.runFormValidation();
  }

  setPropertyId(propertyId) {
    this.propertyId = propertyId;
  }

  getProperty() {
    return {
      propertyId: this.propertyId ?? getUniqueId(),
      ...this.propertyForm.getFormValues(),
    };
  }

  reset() {
    this.propertyForm.reset();
    this.propertyId = null;
  }
}

export default PropertyStore;
