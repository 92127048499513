import { BodyLayout, Navigation } from "@roc/feature-app-core";
import { useLenderPortalStore } from "apps/lender-portal/src/app/hooks/useLenderPortalStore";
import { observer } from "mobx-react";
import React from "react";
import { Redirect, Route, Switch } from "react-router";
import { useRoutes as useLenderRoutes } from '../hooks/useRoutes';
import { useNavigation } from "../hooks/useNavigation";
import { useRoutes } from "@roc/client-portal-shared/hooks";
import { DealRoomPopup } from "@roc/feature-proposals";
import { GlobalSearchDrawer } from "./globalSearchDrawer/globalSearchDrawer";
import { useRoutes as useCrmRoutes } from "apps/crm-portal/src/app/hooks";

export const BackOfficeUserAuthenticatedView = observer(() => {
  const { globalStore } = useLenderPortalStore();

  if (!globalStore.userFeatures) {
    console.error(`userFeatures is missing`);
    return null;
  }

  const {
    leadActivityMetricsRoutes,
    loanVolumeRoutes
  } = useCrmRoutes();
  const {
    miscellaneousRoutes,
    proposalsRoutes,
    crmTaskDashboardRoutes,
    delinquentLoansDashboardRoutes
  } = useRoutes();
  const {
    loanRoutes,
    loanRoutesConfig,
    lenderRoutes,
    companyProfileRoutes,
    lenderBankInformationRoutes,
    createListRoutes,
    marketingSetupRoutes,
    corporateEmailsRoutes,
    emailsRoutes,
    emailSettingsRoutes,
    leadListViewsRoutes,
    newLeadRoutes,
    lenderDetailsRoutes,
    closingTrackerRoutes,
    lenderWithNoRmRoutes,
    sigmaDashboardsRoutes,
    tpoLeadsDashboardRoutes,
    borrowerLeadsDashboardRoutes,
    leadDetailsRoutes,
    importLeadsRoutes,
    leadsDashboardRoutes,
    manageTemplatesRoutes,
    loanVolumeReportRoutes,
    possibleMatchesRoutes,
    borrowerDetailsRoutes,
    tpoContactDetailsRoutes,
    compToolRoutes,
  } = useLenderRoutes();


  const routes = [
    miscellaneousRoutes.commingSoon,
    miscellaneousRoutes.notFound,
    loanRoutes.loansHome,
    loanRoutes.loanDetails,
    lenderRoutes.lendersHome,
    companyProfileRoutes.companyProfile,
    lenderBankInformationRoutes.lenderBankInformationHome,
    createListRoutes.createList,
    leadListViewsRoutes.listViewsHome,
    newLeadRoutes.newLeadsHome,
    proposalsRoutes.proposalsHome,
    proposalsRoutes.proposalsBackofficeHome,
    proposalsRoutes.submitProposal,
    proposalsRoutes.proposalDetails,
    proposalsRoutes.viewProposal,
    proposalsRoutes.documentUpload,
    lenderDetailsRoutes.lenderDetails,
    closingTrackerRoutes.closingTrackerHome,
    lenderWithNoRmRoutes.lendersWithNoRmHome,
    sigmaDashboardsRoutes.sigmaDashboards,
    tpoLeadsDashboardRoutes.leadsDashboard,
    borrowerLeadsDashboardRoutes.leadsDashboard,
    leadDetailsRoutes.leadDetails,
    importLeadsRoutes.importLeadsHome,
    manageTemplatesRoutes.manageTemplatesHome,
    leadsDashboardRoutes.leadsDashboard,
    loanVolumeReportRoutes.loanVolumeReport,
    possibleMatchesRoutes.possibleMatches,
    borrowerDetailsRoutes.borrowerDetails,
    tpoContactDetailsRoutes.tpoContactDetails,
    compToolRoutes.compTool,
    crmTaskDashboardRoutes.crmTaskDashboard,
    delinquentLoansDashboardRoutes.delinquenctLoansDashboard,
    emailSettingsRoutes.emailSettings,
    marketingSetupRoutes.marketingSetup,
    emailsRoutes.emails,
    corporateEmailsRoutes.emails,
    leadActivityMetricsRoutes.leadActivityMetrics,
    loanVolumeRoutes.loanVolume
  ];

  const navigationProps = useNavigation();

  const getRedirectToUrl = () => {
    const { redirectPath, ...rest } = globalStore.queryParams;
    globalStore.setQueryParams(rest);
    return redirectPath ? redirectPath : loanRoutesConfig.loansHome.url;
  };

  return (
    <>
      <Navigation {...navigationProps} showSupportIcon={false} showCompanyDropdown={false} searchDrawer={<GlobalSearchDrawer />} />

      <BodyLayout>
        <Switch>
          {React.Children.toArray(routes.map(x => x))}
          <Route
            exact
            path={'/'}
            render={() => {
              return <Redirect to={getRedirectToUrl()} />;
            }}
          />
          ,
          <Route
            exact
            path={globalStore.routeBasePath}
            render={() => {
              return <Redirect to={getRedirectToUrl()} />;
            }}
          />
          ,
        </Switch>
      </BodyLayout>

      <DealRoomPopup />
    </>
  );
});