import { GlobalStore } from "@roc/feature-app-core";
import { action, flow, makeObservable } from "mobx";
import { TitleAgentService } from "../../../services/documentForms/titleAgentService";
import { DocumentStatus } from "../../../constants";
import { Document } from '@roc/feature-types';
import { TitleAgentFormBaseStore } from "./titleAgentFormBaseStore";
import { LoanStore } from "@roc/feature-loans";
import { DocumentStore } from "@roc/feature-documents";

const form = {
  fields: {
    name: {
      value: '',
      error: null,
      rule: '',
    },
    contactName: {
      value: '',
      error: null,
      rule: '',
    },
    phone: {
      value: '',
      error: null,
      rule: 'regex:/^\\d{3}-?\\d{3}-?\\d{4}$/|alpha_dash',
      message: 'Phone must be a number with the format xxx-xxx-xxxx',
    },
    address: {
      value: '',
      error: null,
      rule: '',
    },
    email: {
      value: '',
      error: null,
      rule: 'email',
    },
    state: {
      value: '',
      error: null,
      rule: '',
    },
    streetName: {
      value: '',
      error: null,
      rule: '',
    },
    streetNumber: {
      value: '',
      error: null,
      rule: '',
    },
    zipCode: {
      value: '',
      error: null,
      rule: '',
    },
    city: {
      value: '',
      error: null,
      rule: '',
    },
  },
  meta: {
    isValid: true,
    error: null,
  },
};

export class TitleAgentWimbaFormStore extends TitleAgentFormBaseStore {
  constructor(
    globalStore: GlobalStore,
    loanStore: LoanStore,
    documentStore: DocumentStore,
  ) {
    super(form, globalStore, loanStore, documentStore);
    this.titleAgentService = new TitleAgentService();
    makeObservable(this, {
      submitData: flow,
    })
  }

  *submitData(document: Document, loanId: string, wimbaInfo: any) {
    this.runFormValidationWithMessage();
    if (this.form.meta.isValid) {
      try {
        const { phone, ...formValues } = this.getFormValues();

        const loanDocument = {
          ...document,
          status: DocumentStatus.ACCEPTED,
        };

        const company = wimbaInfo;

        this.submit(loanDocument, loanId, company, formValues, phone);
      } catch (error) {
        this.globalStore.notificationStore.showErrorNotification({
          message: 'Error while submitting title agent form',
        });
      }
    }
  }
}