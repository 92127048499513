import { Button, Box, Typography, IconButton } from "@material-ui/core";
import { lighten, useTheme } from '@material-ui/core/styles';
import { Unarchive, HelpOutline } from "@material-ui/icons";
import { InputToolbox } from "@chatscope/chat-ui-kit-react";
import { observer } from "mobx-react-lite";
import { ChatRoomType } from "../types/communicationTypes";
import { ConfirmationButton } from "@roc/ui";
import { CommunicationStore } from "../stores";

export const ArchivedChatInfoBox = observer(({ communicationStore }:
  {
    as: any,
    communicationStore: CommunicationStore,
  }) => {

  const theme = useTheme();

  const getChatRoomType = () => {
    return communicationStore.conversationChatRoomType[communicationStore.currentConversationSid];
  };

  let message;

  switch (getChatRoomType()) {
    case ChatRoomType.ADHOC:
      message = (
        <Typography variant="body2" color="textSecondary" align="center" style={{ marginTop: theme.spacing(1) }}>
          This chat room has been closed due to 10 or more days of inactivity
        </Typography>
      );
      break;
    case ChatRoomType.PAYOFF:
    case ChatRoomType.EXTENSIONS:
    case ChatRoomType.DRAWS:
    case ChatRoomType.INITIAL_PAYMENTS:
      message = (
        <Typography variant="body2" color="textSecondary" align="center" style={{ marginBottom: theme.spacing(1) }}>
          This chat room has been closed, you may reopen at any time if needed
        </Typography>
      );
      break;
    case ChatRoomType.UNDERWRITING:
    case ChatRoomType.CLOSING:
    case ChatRoomType.INSURANCE:
    case ChatRoomType.TAMARISK_APPRAISAL:
    case ChatRoomType.DEAL_ROOM:
    case ChatRoomType.INTERNAL_ROOM:
    case ChatRoomType.NEW_LOAN_REQUEST:
      message = (
        <Typography variant="body2" color="textSecondary" align="center" style={{ marginBottom: theme.spacing(1) }}>
          This chat room has been closed, you may reopen at any time if needed
        </Typography>
      );
      break;
    default:
      message = null;
  }

  return (
    <InputToolbox style={{
      justifyContent: "center",
      padding: 0,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    }}>
      <Box p={1} borderTop={'1px solid #eee'} width="100%" bgcolor={lighten(theme.palette.primary.main, 0.9)}
        display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        {message}
        <ConfirmationButton
          color="primary"
          variant="contained"
          startIcon={<Unarchive />}
          onClick={() => {
            communicationStore.unarchiveConversation();
          }}
          testId="unarchive_conversation"
          confirmDialogProps={{
            title: 'Reopen Conversation',
            body: 'Are you sure you want to reopen this conversation?',
            confirmButtonText: 'Yes',
          }}
          size="small"
        >
          Reopen Room
        </ConfirmationButton>
      </Box>
    </InputToolbox>
  )
});
