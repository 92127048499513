import React, { useState } from 'react';
import { ComparableProperty } from '../../types/propertyTypes';
import { Card, CardContent, CardHeader, CardMedia, Checkbox, Grid, Theme, Typography, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { formatCurrency } from '@roc/ui/utils';
import { Button } from '@roc/ui';
import { Clear, CompareArrowsOutlined } from '@material-ui/icons';
import cardHolder from '../../images/cardImageHolder.png'
import { observer } from 'mobx-react';
import placeHolderImage from "../../images/Placeholder.svg"
import { useStore } from '@roc/client-portal-shared/hooks';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      border: `1px solid #D9DADC`,
      width: '100%',
      height: '230px',
      margin: '10px 50px 10px 50px',
      [theme.breakpoints.down("xs")]: {
        width: '80%'
      }
    },
    imageHolder: {
      height: '100%'
    },
    cardBody: {
      fontSize: '18px',
      fontWeight: 'bold'
    },
    cardMedia: {
      height: '120px',
      margin: '0px 10px 0px 10px'
    },
    container: {
      alignContent: 'center',
      textAlign: 'center',
      padding: '0px 30px'
    },
    button: {
      padding: '10px',
      margin: '20px',
      textAlign: 'center'
    },
    buttonOutlined: {
      backgroundColor: '#ffffff',
      padding: '10px',
      margin: '20px',
      textAlign: 'center',
      '&:hover': {
        backgroundColor: '#ffffff',
      },
    },
    cardHeader: {
      padding: '6px'
    },
    closeButton: {
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  })
);

export interface CompareCardProps {
  properties: ComparableProperty[];
}

export const CompareCard: React.FC<CompareCardProps> = observer(({
  properties }) => {

  const { compToolStore } = useStore();

  const getPropertyImage = (property) => {
    const image = property.data.images.find(
      image => image.recommended_preview
    )
      ? property.data.images.find(
        image => image.recommended_preview
      ).url
      : property.data.images[0]?.url

    return image || placeHolderImage
  }

  const [imageLoaded, setImageLoaded] = useState(false);

  const onError = (event) => {
    if (!imageLoaded && event.target.src !== placeHolderImage) {
      event.target.src = placeHolderImage;
    }
  };

  const onLoad = () => {
    setImageLoaded(true);
  };


  const classes = useStyles();
  return (
    <>
      {
        Array.from({ length: 3 }).map((_, index) => (
          <Grid item xs={6} sm={3} key={index} style={{ padding: '0px 30px' }}>
            {properties && properties?.length > index ? (
              <Card className={classes.card}>
                <CardHeader
                  title={compToolStore.rentalComps ? `${formatCurrency(properties[index]?.data?.rent, 0)}/mo` : formatCurrency(properties[index]?.data?.price, 0)}
                  titleTypographyProps={{
                    style: { fontSize: '20px', fontWeight: 'bold' }
                  }}
                  className={classes.cardHeader}
                  action={(
                    <IconButton aria-label="close" className={classes.closeButton}
                      onClick={() => compToolStore.removePropertyFromCompareTool(properties[index]?.property_id)}>
                      <Clear />
                    </IconButton>
                  )}
                />
                <CardMedia
                  className={classes.cardMedia}
                  image={getPropertyImage(properties[index])}
                  onError={onError}
                  onLoad={onLoad}
                />
                <CardContent>
                  <Typography gutterBottom className={classes.cardBody}>
                    {properties[index].data?.address?.split(',')[0]?.trim()}
                  </Typography>
                </CardContent>
              </Card>
            ) : (
              <Card className={classes.card}>
                <CardMedia
                  className={classes.imageHolder}
                  image={
                    cardHolder
                  }
                />
              </Card>
            )}
          </Grid>
        ))
      }
      <Grid item xs={6} sm={3} className={classes.container}>
        <Grid container spacing={1}>
          <Grid item xs={12} lg={12}>
            <Button
              variant="contained"
              color="primary"
              testId="compareProperties"
              className={classes.button}
              onClick={() => {
                compToolStore.setCompareScreen(true)
                compToolStore.setOpenCompareGadget(false)
              }}
              startIcon={<CompareArrowsOutlined />}
            >
              COMPARE
            </Button>
          </Grid>
          <Grid item xs={12} lg={12}>
            <Button
              variant="outlined"
              color="primary"
              testId="clearProperties"
              className={classes.buttonOutlined}
              onClick={() =>
                compToolStore.clearSelectedCompareProperties()
              }
              startIcon={<Clear />}
            >
              CLEAR
            </Button>
          </Grid>
        </Grid>

      </Grid>
    </>
  );
});
