import { FormStore } from '@roc/feature-app-core';
import { GlobalStore } from '@roc/feature-app-core';
import {
  generateUUID,
  parseAddress,
  REFINANCE,
  PURCHASE,
} from '@roc/feature-utils';
import { PropertySource, propertySourceValues } from '../../utils/constants';
import { PropertiesStore } from './propertiesStore';
import { PropertyFormStore } from './propertyFormStore';

const REQUIRED_MESSAGE = 'This field is required';

const propertyInformationForm = {
  fields: {
    address: {
      value: '',
      error: null,
      rule: 'required',
      message: 'The address is required.',
    },
    __isAddressInCorrectFormat__: {
      value: true,
      error: null,
      rule: 'accepted',
      message: 'Invalid Address Format.',
    },
    streetNumber: {
      value: '',
      error: null,
      rule: '',
    },
    streetName: {
      value: '',
      error: null,
      rule: 'required',
      message: 'The Street Name is required.',
    },
    city: {
      value: '',
      error: null,
      rule: 'required',
      message: 'The City is required.',
    },
    state: {
      value: '',
      error: null,
      rule: 'required',
      message: 'The State is required.',
    },
    zipCode: {
      value: '',
      error: null,
      rule: 'required',
      message: 'The Zip Code is required.',
    },
    latitude: {
      value: 0,
      error: null,
      rule: '',
    },
    longitude: {
      value: 0,
      error: null,
      rule: '',
    },
    aptNumber: {
      value: '',
      error: null,
      rule: '',
    },
    propertyType: {
      value: '',
      error: null,
      rule: 'required',
      message: 'The property type is required.',
    },
    loanPurpose: {
      value: '',
      error: null,
      rule: 'required',
      message: REQUIRED_MESSAGE,
    },
    purchaseDate: {
      type: 'DATE',
      value: null,
      error: null,
      rule: [
        {
          required_if_one_of: ['loanPurpose', REFINANCE],
        },
      ],
      format: 'MM/dd/yyyy',
      message: 'REFINANCE Date is required.',
    },
    propertySourcing: {
      value: '',
      error: null,
      rule: [
        {
          required_if_one_of: ['loanPurpose', PURCHASE],
        },
      ],
      message: REQUIRED_MESSAGE,
    },
    sellerCredit: {
      value: '',
      error: null,
      rule: [
        {
          required_if_one_of: ['loanPurpose', PURCHASE],
        },
      ],
      message: REQUIRED_MESSAGE,
    },
    sellerCreditAmount: {
      value: '',
      error: null,
      rule: [
        {
          required_if_one_of: ['sellerCredit', true],
        },
      ],
      message: 'The Seller Credit Amount is required.',
    },
    borrowerKnowOwnerOfRecord: {
      value: '',
      error: null,
      rule: [
        {
          required_if_one_of: ['loanPurpose', PURCHASE],
        },
      ],
      message: REQUIRED_MESSAGE,
    },
    armsLengthComment: {
      value: '',
      error: null,
      rule: [
        {
          required_if: ['borrowerKnowOwnerOfRecord', true],
        },
      ],
      message: REQUIRED_MESSAGE,
    },
    outstandingLoanOnTheProperty: {
      value: '',
      error: null,
      rule: [
        {
          required_if_one_of: ['loanPurpose', REFINANCE],
        },
      ],
      message: REQUIRED_MESSAGE,
    },
    payoffAmount: {
      value: '',
      error: null,
      rule: [
        {
          required_if_one_of: ['outstandingLoanOnTheProperty', true],
        },
      ],
      message: REQUIRED_MESSAGE,
    },
    loanEverBeenInDefault: {
      value: '',
      error: null,
      rule: [
        {
          required_if_one_of: ['outstandingLoanOnTheProperty', true],
        },
      ],
      message: REQUIRED_MESSAGE,
    },
    //GU
    permitsInPlace: {
      value: false,
      error: null,
      rule: 'required',
      message: REQUIRED_MESSAGE,
    },
    //GU
    ltcCatchupDraw: {
      value: false,
      error: null,
      rule: 'required',
      message: REQUIRED_MESSAGE,
    },
    //GU
    subdividedIntoLots: {
      value: '',
      error: null,
      rule: [
        {
          required_if_one_of: ['loanPurpose', PURCHASE],
        },
      ],
      message: REQUIRED_MESSAGE,
    },
    //GU
    numberOfLots: {
      value: '',
      error: null,
      rule: [
        {
          required_if_one_of: ['subdividedIntoLots', true],
        },
      ],
      message: REQUIRED_MESSAGE,
    },
    purchasePrice: {
      value: '',
      error: null,
      rule: 'required',
      message: REQUIRED_MESSAGE,
    },
    currentMarketValue: {
      value: '',
      error: null,
      rule: [{ required_if_one_of: ['loanPurpose', REFINANCE] }],
      message: REQUIRED_MESSAGE,
    },
    constructionRenoSpendToDate: {
      value: '',
      error: null,
      rule: [
        {
          required_if_one_of: ['loanPurpose', REFINANCE],
        },
      ],
      message: REQUIRED_MESSAGE,
    },
    renovationBudget: {
      value: '',
      error: null,
      rule: 'required',
      message: REQUIRED_MESSAGE,
    },
    marketValueAfterCompletion: {
      value: '',
      error: null,
      rule: 'required',
      message: 'The Market Value After Completion is required.',
    },
    wholesalerPropertyContractAmount: {
      value: '',
      error: null,
      rule: [
        {
          required_if_one_of: [
            'propertySourcing',
            propertySourceValues.wholesaler,
          ],
        },
      ],
      message: REQUIRED_MESSAGE,
    },
    propertySourcingExplanation: {
      value: '',
      error: null,
      rule: [
        { required_if_one_of: ['propertySourcing', PropertySource.OTHER] },
      ],
      message: REQUIRED_MESSAGE,
    },
    units: {
      value: '',
    },
    //Supplemental Information
    condoConversion: {
      value: '',
      error: null,
      rule: [{ required_if_true: 'isPreflight' }],
      message: REQUIRED_MESSAGE,
    },
    fireDamage: {
      value: '',
      error: null,
      rule: [{ required_if_true: 'isPreflight' }],
      message: REQUIRED_MESSAGE,
    },
    squareFootageAdded: {
      value: '',
      error: null,
      rule: [{ required_if_true: 'isPreflight' }],
      message: REQUIRED_MESSAGE,
    },
    hasNewAddress: {
      value: '',
      error: null,
      rule: '',
      message: REQUIRED_MESSAGE,
    },
    exitStrategy: {
      value: '',
      error: null,
      rule: [{ required_if_true: 'isPreflight' }],
      message: REQUIRED_MESSAGE,
    },
    newAddress: {
      value: null,
      error: null,
      rule: [{ required_if_true: ['isPreflight', 'hasNewAddress'] }],
      message: REQUIRED_MESSAGE,
    },
    monthlyMarketRentProvidedAtOrigination: {
      value: '',
      error: null,
      attribute: 'Monthly Market Rent',
    },
    annualHOAProvidedAtOrigination: {
      value: '',
      error: null,
      attribute: 'Annual HOA Dues',
    },
    annualTaxesProvidedAtOrigination: {
      value: '',
      error: null,
      attribute: 'Annual Taxes',
    },
    annualInsuranceProvidedAtOrigination: {
      value: '',
      error: null,
      attribute: 'Annual Insurance',
    },
    //Flags
    isAppraisalAvailable: { value: false },
    isPreflight: { value: false },
  },
  meta: {
    key: null,
    editMode: false,
    isValid: false,
    error: null,
  },
};

export class GroundUpPropertiesStore extends PropertiesStore {
  constructor(globalStore, loanSubType) {
    super(globalStore, loanSubType);
  }

  createPropertyForm() {
    return new PropertyFormStore(propertyInformationForm, this.globalStore, this.loanSubType);
  }
}
