import { Box } from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import {
  Grid,
  GridColumnProps,
  Layout,
  LinkColumn,
} from '@roc/client-portal-shared/components';
import { useRoutes, useStore } from '@roc/client-portal-shared/hooks';
import { formatDate } from '@roc/client-portal-shared/utils';
import { Button, IconButton } from '@roc/ui';
import { formatCurrency } from '@roc/ui/utils';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { FilterButton } from '../../components/grid/filterButton';
import { Toolbar } from '../../components/toolbar/toolbar';

const filter = {
  filter: 'agTextColumnFilter',
  floatingFilter: true,
  floatingFilterComponentParams: {
    suppressFilterButton: true,
  },
};

const columns: GridColumnProps[] = [
  {
    field: 'sizerRequestId',
    headerName: 'Number',
    maxWidth: 120,
    cellRenderer: 'addressCellRenderer',
    ...filter,
  },
  {
    field: 'propertyAddress',
    headerName: 'Property Address',
    minWidth: 225,
    cellRenderer: 'addressCellRenderer',
    ...filter,
  },
  {
    field: 'acquisition',
    headerName: 'Property Origin',
    minWidth: 225,
    ...filter,
  },
  {
    field: 'purchasePrice',
    headerName: 'Purchase Price',
    minWidth: 225,
    cellRenderer: 'currencyCellRenderer',
    ...filter,
  },
  {
    ...filter,
    field: 'creation',
    headerName: 'Creation',
    minWidth: 225,
    cellRenderer: 'dateCellRenderer',
    filter: 'agDateColumnFilter',
  },
  {
    ...filter,
    field: 'lastUpdate',
    headerName: 'Last Update',
    minWidth: 225,
    filter: 'agDateColumnFilter',
  },
  {
    field: 'sizerRequestId',
    headerName: 'Actions',
    minWidth: 225,
    cellRenderer: 'actionCellRenderer',
  },
];

export const FixFlipSizer = observer(() => {
  const history = useHistory();
  const { fixFlipSizerRoutesConfig } = useRoutes();
  const { fixFlipSizerStore } = useStore();
  const { gridStore } = fixFlipSizerStore;

  const editSizer = sizerId => {
    const url = fixFlipSizerRoutesConfig.sizerDetails(sizerId).url;
    history.push(url);
  };

  const createSizer = () => {
    const url = fixFlipSizerRoutesConfig.submitSizer.url;
    history.push(url);
  };

  useEffect(() => {
    gridStore.resetAndFetchGridData();
  }, []);

  const toolbar = (
    <Toolbar>
      <Box mr={2}>
        <FilterButton
          onToggleFilters={() => gridStore.toggleFilters()}
          onClearFilters={() => gridStore.resetFilters()}
        />
      </Box>
      <Box>
        <Button
          variant="contained"
          size="small"
          onClick={() => createSizer()}
          color="primary"
          testId="add-new-sizer"
        >
          Add New Sizer
        </Button>
      </Box>
    </Toolbar>
  );

  const frameworkComponents = {
    addressCellRenderer(params) {
      const sizerId = params.node.data?.sizerRequestId;
      return LinkColumn({
        ...params,
        url: fixFlipSizerRoutesConfig.sizerDetails(sizerId).url,
      });
    },
    currencyCellRenderer(params) {
      return formatCurrency(params.value);
    },
    dateCellRenderer(params) {
      return params.value
        ? formatDate(params.value, 'MM/dd/yyyy hh:mm:ss')
        : null;
    },
    actionCellRenderer(params) {
      const sizerId = params.node.data?.sizerRequestId;
      return (
        <>
          <IconButton
            testId={`edit-sizer-${sizerId}`}
            onClick={() => {
              editSizer(sizerId);
            }}
          >
            <Edit color="primary" />
          </IconButton>
          <IconButton
            testId={`delete-sizer-${sizerId}`}
            onClick={() => {
              fixFlipSizerStore.deleteSizer(sizerId);
            }}
          >
            <Delete color="primary" />
          </IconButton>
        </>
      );
    },
  };

  const onFilterChanged = params => {
    const filterModel = params.api.getFilterModel();
    gridStore.setFilterModel(filterModel);
  };

  const onSortChanged = params => {
    const sortModel = params.api.getSortModel();
    gridStore.setSortModel(sortModel);
  };

  return (
    <Layout title="Fix & Flip Sizer">
      <Grid
        columns={columns}
        rows={gridStore.gridData.data.rows}
        frameworkComponents={frameworkComponents}
        toolbar={toolbar}
        onSortChanged={onSortChanged}
        onFilterChanged={onFilterChanged}
        isLoading={gridStore.isLoading}
        showFilters={gridStore.showFilters}
        sortModel={gridStore.sortModel}
        filterModel={gridStore.filterModel}
        paginationData={gridStore.gridData.meta}
        setPageNumber={gridStore.setPageNumber}
      />
    </Layout>
  );
});

export default FixFlipSizer;
