import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router';
import { Button, StandardDialog } from '@roc/ui';
import { observer } from 'mobx-react';
import LoanValues from '../common/loanValues';
import PointOfContactCard from '../common/pointOfContactCard';
import PropertyValues from './components/propertyValues';
import { useLoanRoutes } from '@roc/feature-loans-routes-config';
import { useLoanStore } from '@roc/feature-loans';
import { useDrawsStore } from '../hooks';
import { DrawRequestFormShared } from '../drawRequestForm/drawRequestFormShared';
import PreferredProcessCard from '../common/preferredProcessCard';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: 24,
  },
  buttonContainer: {
    marginTop: 36,
  },
}));

export const DrawRequestShared = observer(({ setShowSuccessScreen }) => {
  const {
    loanStore: { loanDetails },
  } = useLoanStore();
  const { drawRequestStore } = useDrawsStore();
  const { pointOfContactFormStore } = drawRequestStore.pointOfContactStore;
  const classes = useStyles();
  const { loanRoutesConfig } = useLoanRoutes();
  const { push } = useHistory();
  const [
    openDrawRequestFormModal,
    setOpenDrawRequestFormModal,
  ] = useState<boolean>(false);

  const handleStartDrawRequest = async () => {
    drawRequestStore.successLoad = false;
    pointOfContactFormStore.runFormValidation();
    if (pointOfContactFormStore.form.meta.isValid) {
      await drawRequestStore.submitRequest();
      if (drawRequestStore.successLoad) {
        if (drawRequestStore.isSowAvailable) {
          setOpenDrawRequestFormModal(true);
        } else {
          setShowSuccessScreen(true);
        }
      }
    }
  };

  const handleSubmit = () => {
    drawRequestStore.submitForm(drawRequestStore.drawStore.currentDraw.drawId);
    setOpenDrawRequestFormModal(false);
    push(
      loanRoutesConfig
        .loans(loanDetails.loanId)
        .children.drawDetails(
          drawRequestStore.drawStore.currentDraw.drawId.toString()
        ).url
    );
  };

  const handleClose = () => {
    setOpenDrawRequestFormModal(false);
    push(
      loanRoutesConfig
        .loans(loanDetails.loanId)
        .children.drawDetails(
          drawRequestStore.drawStore.currentDraw.drawId.toString()
        ).url
    );
  };

  const handleSave = () => {
    drawRequestStore.saveForm(drawRequestStore.drawStore.currentDraw.drawId);
    setOpenDrawRequestFormModal(false);
    push(
      loanRoutesConfig
        .loans(loanDetails.loanId)
        .children.drawDetails(
          drawRequestStore.drawStore.currentDraw.drawId.toString()
        ).url
    );
  };

  return (
    <>
      <Grid item container direction="row" spacing={3}>
        <PropertyValues />
        <PointOfContactCard />
        <LoanValues />
        <PreferredProcessCard/>
      </Grid>
      <Grid
        container
        item
        justifyContent="center"
        className={classes.buttonContainer}
      >
        <Grid item xs={12} md={3}>
          <Button
            variant="contained"
            size="small"
            onClick={handleStartDrawRequest}
            color="primary"
            testId="start_draw_request"
            fullWidth={true}
            disabled={
              !drawRequestStore.property?.propertyId
            }
          >
            START DRAW REQUEST
          </Button>
        </Grid>
      </Grid>
      <StandardDialog
        open={openDrawRequestFormModal}
        title="Draw Request Form"
        maxWidth="xl"
        handleClose={handleClose}
        dialogContent={
          <DrawRequestFormShared
            onSubmit={handleSubmit}
            onSave={handleSave}
            store={drawRequestStore}
          />
        }
      />
    </>
  );
});
