import { Grid, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import { AppraisalDetailsGrid } from './components/appraisalDetailsGrid';
import { AppraiserDetailsGrid } from './components/appraiserDetailsGrid';
import { GetApp } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { Button, ScreenshotGrid } from '@roc/ui';
import { useBaseStore } from '@roc/feature-app-core';
import { useDocumentStore } from '@roc/feature-documents';
import { useEffect } from 'react';

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(1),
  },
  downloadContainer: {
    display: 'flex',
    flexDirection: 'row',
    cursor: 'pointer',
    marginLeft: '17px',
  },
  icon: {
    fontSize: '24px',
    marginLeft: 4,
  },
  closeButtonContainer: {
    paddingTop: 16,
  }
}));

export const AppraisalOrderDetailsDashboards = observer(({ loanId, isInternal }) => {

  const classes = useStyles();
  const { globalStore } = useBaseStore();
  const { documentStore } = useDocumentStore();
  const { documentFormStore } = documentStore;
  const { cdaAndAppraisalOrderDetailsStore } = documentFormStore;

  useEffect(() => {
    if (isInternal)
      window.parent.postMessage('appraisal-dashboard', '*');
  }, [])

  const handleDownloadClick = () => {
    cdaAndAppraisalOrderDetailsStore.downloadTamariskSignedDocument(loanId, 'Appraisal_Loan');
  };

  const handleClose = () => {
    if (isInternal) {
      window.parent.postMessage('close-modal', '*');
      window.parent.postMessage('clear-style', '*');
    } else {
      documentStore.documentFormStore.setCurrentForm(null);
    }
  };

  return (
    <Grid container className={classes.container} spacing={1} justifyContent="center">
      <Grid item container direction="row" justifyContent="space-between" xs={11}>
        <Grid item>
          <Typography variant="h5" gutterBottom>Appraisal Details</Typography>
        </Grid>
        <Grid item
          className={classes.downloadContainer}
          onClick={handleDownloadClick}
          data-html2canvas-ignore>
          <Typography variant="body2" gutterBottom>Download Signed document</Typography>
          <GetApp className={classes.icon} />
        </Grid>
      </Grid>
      <Grid item xs={11}>
        <AppraisalDetailsGrid
          loanId={loanId}
        />
      </Grid>
      <Grid item xs={11} />
      <Grid item xs={11}>
        <Typography variant="h5" gutterBottom>Appraiser Details</Typography>
      </Grid>
      <Grid item xs={11}>
        <AppraiserDetailsGrid />
      </Grid>
      <Grid item container xs={11} justifyContent="flex-end" className={classes.closeButtonContainer}>
        <Grid item>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleClose}
            testId="cancel"
            disabled={globalStore.userFeatures?.isInsuranceReviewer ?? false}
          >
            Close
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
});
