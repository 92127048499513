import {
  Typography,
  Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import {
  Button,
  ConfirmationButton,
  TextField,
} from '@roc/ui';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    instruction: {
      fontWeight: 600,
    },
    underline: {
      fontWeight: 600,
      textDecorationLine: 'underline',
    },
  })
);

export const BorrowerSetupLink = observer(({ store }) => {

  const classes = useStyles();
  const { currentBorrower } = store;

  const handleCopy = () => {
    const url = store.borrowerDetailsVerificationUrl;
    navigator.clipboard.writeText(url);
    store.globalStore.notificationStore.showSuccessNotification({
      message: 'Link was copied to clipboard!',
    });
  };

  const handleConfirm = () => {
    store.sendIdVerificationEmailReminder(currentBorrower?.emailAddress);
  };

  return (
    <>
      {store.borrowerDetailsVerificationUrl &&
        <Grid item xs={12} container justifyContent="center" spacing={2}>
          <Grid
            alignItems="center"
            container
            item
            justifyContent="center"
            xs={12}
          >
            <Grid item container xs={11} spacing={2}>
              <Grid item>
                <Typography className={classes.instruction}>
                  We have already sent the authorization form to the borrower. You can click on "copy link" and send to borrower yourself if you prefer.
                </Typography>
              </Grid>
              <Grid item container alignItems="center" spacing={2}>
                <Grid item xs={12} sm>
                  <TextField
                    testId="idVerificationLink"
                    variant="outlined"
                    value={store.borrowerDetailsVerificationUrl}
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleCopy}
                    testId="copyIdVerificationUrlButton"
                    fullWidth
                  >
                    COPY LINK
                  </Button>
                </Grid>
              </Grid>
              <Grid item container spacing={2}>
                <Grid item>
                  <Typography className={classes.instruction}>
                    {`You can also click "email link to borrower" and we will send the link automatically to the borrower.`}<span className={classes.underline}>{store.currentBorrower?.emailAddress}</span>
                  </Typography>
                </Grid>
                <Grid item container justifyContent="center">
                  <ConfirmationButton
                    variant="contained"
                    onClick={handleConfirm}
                    color="primary"
                    testId="sendIdVerificationButton"
                    confirmDialogProps={{
                      title: 'Confirmation',
                      body: 'Email will be sent out. Please confirm',
                    }}
                  >
                    Email Link to Borrower
                  </ConfirmationButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }
    </>
  );
});