import { ExpandMore } from '@material-ui/icons';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Divider,
} from '@material-ui/core';
import { useState } from 'react';
import { observer } from 'mobx-react';
import { TitleDivider } from '../../../components/titleDivider';
import { FeesDetailsTable } from '../../../components/feesDetailsTable';
import { QuoteTypeStore } from '../../../stores/quoteTypeStore';

interface PointsAndFeesDetailsAccordionProps {
  store: QuoteTypeStore;
}

export const PointsAndFeesDetailsAccordion = observer(
  ({ store }: PointsAndFeesDetailsAccordionProps) => {
    const [expanded, setExpanded] = useState(false);

    return (
      <Accordion
        expanded={expanded}
        onChange={(e, expanded) => setExpanded(expanded)}
        elevation={0}
      >
        <AccordionSummary
          expandIcon={<ExpandMore fontSize="large" />}
          aria-controls={`economics-summary-accordion`}
        >
          <Grid item xs={12}>
            <TitleDivider title="Borrower Cash to Close" />
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <FeesDetailsTable store={store} />
        </AccordionDetails>
      </Accordion>
    );
  }
);
