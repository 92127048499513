import { FormStore, GlobalStore } from '@roc/feature-app-core';
import { DocumentStore } from '@roc/feature-documents';
import { LoanStore } from '@roc/feature-loans';
import { GENERIC_ERROR_MESSAGE } from '@roc/feature-utils';
import { action, flow, makeObservable } from 'mobx';
import { PersonalIdentificationService } from '../../services/documentForms/personalIdentificationService';

const form = {
  fields: {
    idType: {
      value: '',
      error: null,
      rule: '',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export class PersonalIdentificationFormStore extends FormStore {
  private globalStore: GlobalStore;

  private personalIdentificationService: PersonalIdentificationService;

  constructor(globalStore: GlobalStore) {
    super(form, globalStore);
    this.globalStore = globalStore;
    this.personalIdentificationService = new PersonalIdentificationService();

    makeObservable(this, {
      resetStore: action,
      getIdentificationTypeData: flow,
      saveIdentificationTypeData: flow,
    });
  }

  resetStore() {
    this.reset();
  }

  *getIdentificationTypeData(loanId, borrowerId) {
    try {
      const response = yield this.personalIdentificationService.getIdentificationTypeData(
        loanId,
        borrowerId
      );
      const { idType } = response.data[0] ?? {};
      this.loadForm({ idType });
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE,
      });
    }
  }

  *saveIdentificationTypeData(loanId, borrowerId, documentId) {
    try {
      const { idType } = this.getFormValues();
      this.personalIdentificationService.saveIdentificationTypeData({
        borrowerId,
        documentId,
        idType,
        loanId,
      });
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE,
      });
    }
  }
}
