import { FormStore } from '@roc/feature-app-core';
import { action, makeObservable, observable } from 'mobx';

const borrowerSetupOptionsForm = {
  fields: {
    sendIdVerification: {
      value: false,
      error: null,
      rule: 'required',
    },
    runCreditOnCreation: {
      value: '',
      error: null,
      rule: 'required',
      message: 'The guarantor field is required.',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};
export class BorrowerSetupOptionsFormStore extends FormStore {

  oldFormValues: any;

  constructor(globalStore) {
    super({ ...borrowerSetupOptionsForm }, globalStore);

    makeObservable(this, {
      oldFormValues: observable,
      setOldFormValues: action
    });
    this.setDefaults();
  }

  reset() {
    super.reset();
    this.setDefaults();
  }

  setDefaults() {
    this.setOldFormValues(null);
  }

  setOldFormValues(oldFormValues) {
    this.oldFormValues = oldFormValues;
  }

}