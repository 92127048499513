import { Service } from '@roc/feature-app-core';

const url = {
  GET_LOAN_BORROWER: '/nportal/rest/priv/borrower/getLoanBorrowerByLoanIdAndBorrowerId',
  GET_FORM_INFORMATION_BY_BORROWER_ID_FOR_LOAN_APPLICATIONS: '/nportal/rest/priv/borrowerDetailsVerification/getLoanApplicationBorrowerDetailsFormInfoByBorrowerIdInternal',
  GET_FORM_INFORMATION_BY_BORROWER_ID: '/nportal/rest/priv/borrowerDetailsVerification/getBorrowerDetailsFormInfoByBorrowerIdInternal',
  GET_BORROWER_SETUP_FORM_LINK: '/nportal/rest/priv/borrowerDetailsVerification/getBorrowerSetupFormLink',
  GET_UNSUBSCRIBE_LINK: '/nportal/rest/priv/borrowerDetailsVerification/getUnsubscribeLink',
  SEND_ID_VERIFICATION_EMAIL_REMINDER: '/nportal/rest/priv/borrowerDetailsVerification/sendIdVerificationEmailReminderForRequests',
  SAVE_BORROWER: '/nportal/rest/priv/lenderBorrowers/saveBorrowerAndAssociateLenderInternal/v2',
  SUBMIT_CREDIT_BACKGROUND_REQUEST: '/nportal/rest/priv/checkRequest/creditBackground',
  DOWNLOAD_REPORTS: '/nportal/rest/priv/checkRequest/getReportsOfRequest',
  GET_BORROWER_PRE_SCREEN_INFO: '/nportal/rest/priv/loan/getBorrowerPrescreenInfo',
  SAVE_PG_FOR_LOAN_BORROWER: '/nportal/rest/priv/borrower/savePgForLoanBorrower',
};

export class BorrowerSetupInternalService extends Service {

  getLoanBorrower(loanId, borrowerId) {
    return this.get(url.GET_LOAN_BORROWER, { loanId, borrowerId });
  }

  getLoanApplicationBorrowerDetailsFormInfoByBorrowerId(borrowerId, lenderId, isPersonalGuarantor) {
    return this.get(url.GET_FORM_INFORMATION_BY_BORROWER_ID_FOR_LOAN_APPLICATIONS, { borrowerId, lenderId, isPersonalGuarantor });
  }

  getFormInformationByBorrowerId(borrowerId, lenderId, isPersonalGuarantor) {
    return this.get(url.GET_FORM_INFORMATION_BY_BORROWER_ID, { borrowerId, lenderId, isPersonalGuarantor });
  }

  getBorrowerSetupFormLink(requestId: number) {
    return this.get(`${url.GET_BORROWER_SETUP_FORM_LINK}?requestId=${requestId}`, {});
  }

  getUnsubscribeLink(lenderId, requestToken) {
    return this.get(`${url.GET_UNSUBSCRIBE_LINK}`, { lenderId, requestToken });
  }

  sendIdVerificationEmailReminder(data, lenderId) {
    return this.post(`${url.SEND_ID_VERIFICATION_EMAIL_REMINDER}?lenderId=${lenderId}`, data);
  }

  saveBorrower(borrower, lenderId) {
    return this.post(`${url.SAVE_BORROWER}?lenderId=${lenderId}`, borrower);
  }

  submitCreditBackgroundRequest(borrower) {
    return this.post(url.SUBMIT_CREDIT_BACKGROUND_REQUEST, borrower);
  }

  downloadReports(requestId) {
    return this.get(
      url.DOWNLOAD_REPORTS,
      {
        requestId,
      },
      {
        responseType: 'blob',
      }
    );
  }

  getBorrowerPrescreenInfoByBorrowerId(borrowerId: number, personalGuarantor: boolean, loanType: string) {
    return this.get(`${url.GET_BORROWER_PRE_SCREEN_INFO}`, {
      borrowerId,
      personalGuarantor,
      loanType
    });
  }

  savePgToLoanBorrower(params) {
    return this.put(`${url.SAVE_PG_FOR_LOAN_BORROWER}?loanId=${params.loanId}&borrowerId=${params.borrowerId}&borrowerEntityId=${params.borrowerEntityId}&personalGuarantor=${params.personalGuarantor}`, {});
  }

}