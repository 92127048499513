import { Box, useMediaQuery } from '@material-ui/core';
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles';
import { useDocumentStore } from '@roc/feature-documents';
import { LoanApplicationDetailsComponent } from '@roc/feature-loan-details';
import { PDFPreview } from '@roc/ui';
import { observer } from 'mobx-react';
import 'react-perfect-scrollbar/dist/css/styles.css';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
    },
  })
);

export const LoanApplicationDetailsV2 = observer(() => {
  const classes = useStyles();
  const { documentStore } = useDocumentStore();

  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <Box display="flex" className={classes.container}>
      <Box flexGrow={1} pb={isLargeScreen ? 0 : 5}>
        <LoanApplicationDetailsComponent />
      </Box>
      <PDFPreview
        open={documentStore.documentPreview != null}
        title={documentStore.documentPreview?.title}
        data={documentStore.documentPreview?.data}
        headers={documentStore.documentPreview?.headers}
        onClose={() => documentStore.closeDocumentPreview()}
      />
    </Box>
  );
});
