import { GlobalStore } from "@roc/feature-app-core";
import { action, flow, makeObservable, observable } from "mobx";
import { CompToolFeedbackService } from "../services/compToolFeedbackService";

export class CompToolFeedbackStore {
  globalStore: GlobalStore;
  showFeedbackThankYou: boolean;
  showFeedbackComment: boolean;
  feedBackComment: string;
  isFeedbackPositive: boolean;
  compToolFeedbackService: CompToolFeedbackService;

  constructor(globalStore) {
    this.globalStore = globalStore;
    this.compToolFeedbackService = new CompToolFeedbackService();
    this.showFeedbackThankYou = false;
    this.showFeedbackComment = false;

    makeObservable(this, {
      sendCompToolFeedback: flow,
      showFeedbackThankYou: observable,
      setShowFeedbackThankYou: action,
      showFeedbackComment: observable,
      feedBackComment: observable,
      setShowFeedbackComment: action,
      setFeedbackComment: action,
      isFeedbackPositive: observable
    });
  }

  buildFeedbackCompRequest(compRequest, subjectProperty, property) {
    return {
      is_positive: this.isFeedbackPositive,
      message: this.feedBackComment,
      comps_request: compRequest,
      subject_property: subjectProperty,
      comp_property: property
    };
  }

  *sendCompToolFeedback(compRequest, subjectProperty, property) {
    try {
      const feedbackCompRequest = this.buildFeedbackCompRequest(compRequest, subjectProperty, property);

      const response = yield this.compToolFeedbackService.sendPropertyFeedback(feedbackCompRequest);
      if (response == null) {
        this.globalStore.notificationStore.showErrorNotification({
          message: 'Error while sending property feedback',
        });
        return false;
      } else {
        this.setShowFeedbackThankYou(true);
        this.globalStore.notificationStore.showSuccessNotification({
          message: 'Property feedback sent',
        });
      }
    }
    catch (error) {
      console.log(error);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while sending feedback',
      });
      return false;
    }
  }

  setIsFeedbackPositive(isFeedbackPositive: boolean) {
    this.isFeedbackPositive = isFeedbackPositive
  }

  setShowFeedbackComment(showFeedbackComment: boolean) {
    this.showFeedbackComment = showFeedbackComment;
  }

  setFeedbackComment(feedBackComment: string) {
    this.feedBackComment = feedBackComment;
  }

  setShowFeedbackThankYou(showFeedbackThankYou: boolean) {
    this.showFeedbackThankYou = showFeedbackThankYou;
    this.showFeedbackComment = !showFeedbackThankYou;
  }

}
