import React, { useEffect, useRef } from 'react';
import { Grid } from '@material-ui/core';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { StandardDialog, Button } from '@roc/ui';
import { DealEconomics } from './dealEconomics';
import { useDocumentStore } from '../../../hooks/useDocumentStore';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import GetAppIcon from '@material-ui/icons/GetApp';
import html2canvas from 'html2canvas';

import jsPDF from 'jspdf';
import { observer } from 'mobx-react';
import SaveButtonWithPermissions from 'libs/ui/src/lib/components/shared/save-button-with-permissions';

export type DealEconomicsDialogProps = {
  open?: boolean;
  onClose?: () => void;
};

let currentDisabled;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerButton: {
      border: 0,
      color: theme.palette.grey[600],
      '&:hover': {
        border: 0,
      },
      '&.Mui-disabled': {
        border: 0,
      },
    },
    iconButton: {
      color: theme.palette.grey[600],
      marginRight: theme.spacing(1),
      padding: theme.spacing(0.75),
    },
  })
);

export const DealEconomicsDialog = observer((props: DealEconomicsDialogProps) => {
  const { open, onClose } = props;
  const {
    documentStore: { documentFormStore },
  } = useDocumentStore();
  const { dealEconomicsStore } = documentFormStore;
  const { isDownloading } = dealEconomicsStore;

  const dealEconomicsEl = useRef(null);

  const classes = useStyles();

  const { headerButton, iconButton } = classes;

  const onSubmit = () => {
    dealEconomicsStore.calculateDealEconomics();
    if (!onClose || !dealEconomicsStore.form.meta.isValid) return;
    onClose();
  };

  const handleDownloadClick = () => {
    currentDisabled = dealEconomicsStore.isDisabled;
    dealEconomicsStore.setIsDownloading(true);
    dealEconomicsStore.setIsDisabled(false);
  };
  const afterConvertToCanvas = (canvas, domConvertedEl) => {
    domConvertedEl.classList.remove('in-process');

    const pdf = new jsPDF(
      domConvertedEl.offsetWidth < domConvertedEl.offsetHeight ? 'p' : 'l',
      'pt',
      [domConvertedEl.offsetWidth, domConvertedEl.offsetHeight]
    );
    const {
      internal: { pageSize },
    } = pdf;
    const marginLeft = 20;
    const marginTop = 30;

    pdf.addImage(
      canvas.toDataURL('image/jpeg'),
      'JPEG',
      marginLeft,
      marginTop,
      pageSize.getWidth() - marginLeft * 2,
      pageSize.getHeight() - marginTop
    );
    pdf.save('Deal_economics.pdf');
    dealEconomicsStore.setIsDownloading(false);
    dealEconomicsStore.setIsDisabled(currentDisabled);
  };
  useEffect(() => {
    if (isDownloading) {
      const { current } = dealEconomicsEl;

      if (!current) {
        return;
      }

      current.classList.add('in-process');
      setTimeout(
        () =>
          html2canvas(current, { scrollX: 0, scrollY: 0 }).then(canvas => {
            afterConvertToCanvas(canvas, current);
          }),
        0
      );
    }
  }, [isDownloading]);

  return (
    <StandardDialog
      open={open}
      title="Deal Economics"
      maxWidth="lg"
      handleClose={onClose}
      dialogContent={<DealEconomics dealEconomicsEl={dealEconomicsEl} />}
      dialogActions={
        <Grid container spacing={1} justifyContent="flex-end">
          <Grid item>
            <Button
              className={headerButton}
              onClick={handleDownloadClick}
              startIcon={<GetAppIcon />}
              variant="outlined"
              testId="downLoadAsPDF"
            >
              Download As PDF
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={onClose}
              color="primary"
              variant="outlined"
              testId="cancel"
            >
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <SaveButtonWithPermissions
              onClick={onSubmit}
              color="primary"
              variant="contained"
              testId="confirm"
              label="Submit"
              loanId={dealEconomicsStore?.loanStore?.loanDetails?.loanId}
            />
          </Grid>
        </Grid>
      }
    />
  );
});

export default DealEconomicsDialog;
