import { action, flow, makeObservable, observable } from 'mobx';
import { GlobalStore, GridStore } from '@roc/feature-app-core';
import { BackgroundReviewFormStore } from './backgroundReviewFormStore';
import { BackgroundItemStore } from './backgroundItemStore';
import { BackgroundItemExternalStore } from './backgroundItemExternalStore';

export class BackgroundReviewExternalStore {
  globalStore: GlobalStore;
  backgroundReviewFormStore: BackgroundReviewFormStore;
  backgroundItemStore: BackgroundItemExternalStore;

  constructor(globalStore: GlobalStore) {
    this.globalStore = globalStore;
    this.backgroundReviewFormStore = new BackgroundReviewFormStore(
      globalStore
    );
    this.backgroundItemStore = new BackgroundItemExternalStore(
      globalStore,
      this.backgroundReviewFormStore
    );
    makeObservable(this, {

    });
  }
};