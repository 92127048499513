import { downloadDocument } from '@roc/client-portal-shared/utils';
import { action, flow, makeObservable, observable } from 'mobx';
import { GlobalStore, GridStore } from '../../../../feature-app-core/src/stores';
import { UserDocumentsService } from '../services/userDocumentsService';
import { CompanyProfileService } from 'libs/client-portal-shared/src/app/modules/companyProfile/services/CompanyProfileService';
import { FileUpload } from '@roc/ui';

export class LenderUserDocumentsStore {
  protected globalStore: GlobalStore;
  userDocumentsService: UserDocumentsService;
  protected userFolderPath: string;

  documentPreview: {
    title: string;
    data: ArrayBuffer;
    headers: object;
  };

  gridStore: GridStore;

  isReady = false;

  constructor(globalStore) {
    this.globalStore = globalStore;

    this.userDocumentsService = new UserDocumentsService();
    this.gridStore = new GridStore(() => this.fetchDocuments());

    makeObservable(this, {
      isReady: observable,
      documentPreview: observable,
      closeDocumentPreview: action,
      loadData: flow,
      uploadDocument: flow,
      downloadDocument: flow,
      deleteDocument: flow,
      previewDocument: flow,
    });
  }

  private async fetchDocuments() {
    const response = await this.userDocumentsService.getUserDocuments(this.userFolderPath);
    this.isReady = true;
    const docs = response?.data?.data;

    const userDocuments = docs.map(doc => {
      return {
        uploaded: Boolean(doc),
        documentLabel: doc
      }
    })
    return {
      data: {
        data: {
          rows: userDocuments,
          totalCount: userDocuments.length,
        },
      },
      headers: null,
    };
  }

  *loadData(firstName: string, lastName: string) {
    this.userFolderPath = `${firstName}_${lastName}`;
    yield this.gridStore.fetchGridData();
  }

  *uploadDocument(file: FileUpload["file"], fileName: string) {
    const formData = new FormData();
    formData.append('file', file);
    yield this.userDocumentsService.uploadUserDocument(this.userFolderPath, fileName, formData);
    yield this.gridStore.resetAndFetchGridData();
  }

  *deleteDocument(documentLabel: string) {
    const filePath = `${this.userFolderPath}/${documentLabel}`;
    yield this.userDocumentsService.deleteUserDocument(filePath);
    yield this.gridStore.resetAndFetchGridData();
  }

  *downloadDocument(documentId, documentLabel) {
    const filePath = `${this.userFolderPath}/${documentLabel}`;
    const response = yield this.userDocumentsService.downloadUserDocument(
      filePath
    );
    downloadDocument(response.data, response.headers, 'download');
  }

  closeDocumentPreview() {
    this.documentPreview = null;
  }

  *previewDocument(documentLabel) {
    const filePath = `${this.userFolderPath}/${documentLabel}`;
    const response = yield this.userDocumentsService.downloadUserDocument(
      filePath
    );
    this.documentPreview = {
      title: documentLabel,
      data: response.data,
      headers: response.headers,
    };
  }
}
