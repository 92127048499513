import { flow, makeObservable, observable } from 'mobx';
import { DrawRequestService } from '../services/drawRequestService';
import { DEFAULT_DRAW_TYPE } from '../../constants/draws';
import { PointOfContactStore } from '../../pointOfContact/stores/pointOfContactStore';
import { DrawStore } from '../../stores/drawStore';
import { ApiResponse, GlobalStore } from '@roc/feature-app-core';
import { DrawRequestBaseStore } from './drawRequestBaseStore';
import { LoanStore } from '@roc/feature-loans';


export class DrawRequestStoreExternal extends DrawRequestBaseStore{
  pointOfContactStore: PointOfContactStore;
  private drawRequestService: DrawRequestService;
  loanStore: LoanStore;

  successLoad: boolean = false;

  constructor(globalStore: GlobalStore, pointOfcontactStore: PointOfContactStore, drawStore: DrawStore, loanStore: LoanStore) {
    super(globalStore, drawStore)
    this.pointOfContactStore = pointOfcontactStore;
    this.loanStore = loanStore;
    this.drawRequestService = new DrawRequestService();


    makeObservable(this, {
      submitRequest: flow,
      fetchDrawRequestShareUrl: flow,
      successLoad: observable,
      getLoanDetails: flow
    });
  }

  async loadTableData(drawId) {
    try {
      const response: ApiResponse = await this.drawRequestService.retrieveDrawData(
        drawId
      );
      const drawDTO = response?.data?.data;
      const drawRequest = drawDTO.draw.drawRequest;
      this.initializeDrawRequest(drawRequest, drawDTO);
      this.tableData = drawRequest.dataContent;
      this.drawDetailsData = drawDTO;
      this.fetchDrawRequestShareUrl();
    } catch (error) {
      console.error(error);
    }
  }

  *getLoanDetails(loanId) {
    const response: ApiResponse = yield this.loanStore.fetchLoanDetails(loanId);
  }


  *saveForm(drawId: any) {
    const response: ApiResponse = yield this.drawRequestService.saveDrawRequest(
      drawId,
      this.tableData
    );
    this.showResponse(response, 'Draw request information saved successfully', 'Error while saving draw request');
    this.saved = true;
  }

  *submitRequest() {
    try {
      const formValues = this.pointOfContactStore.pointOfContactFormStore.getFormValues();
      if (!this.isInspectionProcessValid(formValues.inspectionProcess)){
        return;
      }
      const drawRequestJsonContent = JSON.stringify({
        inspectionProcess: formValues.inspectionProcess
      });
      const jsonForm = {
        loanId: this.loanStore.loanDetails.loanId,
        contactFirstName: formValues.firstName,
        contactLastName: formValues.lastName,
        contactPhone: formValues.phoneNumber,
        contactEmail: formValues.email,
        contactComment: formValues.comments,
        propertyId: this.property.propertyId,
        drawType: DEFAULT_DRAW_TYPE,
        drawRequestJsonContent: drawRequestJsonContent,
      };
      const response: ApiResponse = yield this.drawRequestService.requestDraw(
        jsonForm
      );
      const requestData = response.data;
      if (requestData.status === 'OK') {
        const drawDTO = response?.data?.data;
        this.drawDetailsData = drawDTO;
        this.isSowAvailable = drawDTO?.draw?.isSowVersionSupported;
        this.drawStore.fetchDrawById(drawDTO.draw.drawId);
        const drawRequestUrlResponse = yield this.drawRequestService.getDrawRequestShareUrl(
          drawDTO.draw.drawId.toString()
        );
        this.url = drawRequestUrlResponse?.data?.data
          ? drawRequestUrlResponse?.data?.data
          : '';
        this.successLoad = true;
        if (this.isSowAvailable) {
          const drawRequest = drawDTO.draw.drawRequest;
          this.initializeDrawRequest(drawRequest, drawDTO);
          this.tableData = drawRequest.dataContent;
          this.saved = false;
        }
        this.globalStore.notificationStore.showSuccessNotification({
          message: 'Draw request was created successfully',
        });
      } else {
        this.globalStore.notificationStore.showErrorNotification({
          message: requestData?.error?.message,
        });
        this.successLoad = false;
      }

    } catch (error) {
      console.error(error);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while submitting draw request.',
      });
      this.successLoad = false;
    }
  }

  *submitForm(drawId: any) {
    const response: ApiResponse = yield this.drawRequestService.submitDrawRequest(
      drawId,
      this.tableData
    );
    this.showResponse(response, 'Draw request information submitted for review successfully', 'Error while submitting draw request')
  }

  async getURL() {
    return this.url;
  }

  async getPropertyPhotosURL() {
    this.propertyPhotosUrl = 'https://vision.app.link/RQryOleLujb';
  }

  *fetchDrawRequestShareUrl() {
    try {
      const response = yield this.drawRequestService.getDrawRequestShareUrl(
       this.drawDetailsData.draw.drawId.toString()
      );
      this.url = response?.data?.data ? response?.data?.data : '';
    } catch (error) {
      console.log('Error while getting share url');
    }
  }

  isInspectionProcessValid(value){
    if(!value){
      this.globalStore.notificationStore.showErrorNotification({message: 'Please select your preferred inspection process'});
      return false;
    }
    return true;
  }
}
