import {
  Avatar,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { LaunchRounded, RoomRounded } from '@material-ui/icons';
import { BorrowerPortalCard } from '@roc/feature-borrower-portal-core';
import { BorrowerPropertiesModal } from './borrowerProperties';
import { useState } from 'react';
import { Dividers } from '../overview/borrowerLoanOverviewComponents';

const useStyles = makeStyles(theme => ({
  height100Percent: {
    height: '100%'
  }
}));

export const PropertiesCard = ({ loanSubtype, properties }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [property, setProperty] = useState<any>();

  return (
    <BorrowerPortalCard cardTitle="Properties" cardRootClassName={classes.height100Percent} noPaddingCardContent>
      <List>
        <Dividers variant="fullWidth" component="li">
          {properties?.length > 0 ? properties?.map(property => (
            <ListItem key={property.address}>
              <ListItemAvatar>
                <Avatar style={{
                  background: theme.palette.primary.main
                }}>
                  <RoomRounded />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={property.address} />
              <ListItemSecondaryAction>
                <IconButton edge="end" onClick={() => {
                  setOpen(true);
                  setProperty(property);
                }}>
                  <LaunchRounded />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          )) : null}
        </Dividers>
      </List>
      <BorrowerPropertiesModal
        open={open}
        loanSubtype={loanSubtype}
        property={property}
        handleClose={() => {
          setOpen(false);
          setProperty(undefined);
        }} />
    </BorrowerPortalCard >
  );
};