import { GlobalStore } from '@roc/feature-app-core';
import { DocumentStore } from './../../documents/stores/documentStore';
import { flow, observable, action, makeObservable, computed } from 'mobx';
import { EditorState } from 'draft-js';
import createImagePlugin from '@draft-js-plugins/image';
import { ApiResponse } from '@roc/feature-app-core';
import { DocumentCommunicationService } from '../services/documentCommunicationService';
import {
  Thread,
  ThreadMeta,
  ThreadType,
} from '@roc/feature-types';
import { convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { CLOSING, DRAWS, UNDERWRITING } from '@roc/feature-utils';

const imagePlugin = createImagePlugin();

const initialThread: Thread = {
  type: null,
  title: '',
  meta: {
    loanId: null,
    drawId: null,
    sectionId: null,
    borrowerId: null,
    collateralId: null,
  },
};

const newComment = {
  commentText: '',
};

export class DocumentCommunicationStore {
  globalStore: GlobalStore;
  documentStore: DocumentStore;
  documentCommunicationService: DocumentCommunicationService;
  isDrawerOpen = false;
  thread: Thread;
  comments: any[];
  attachments: any[];
  editorState: EditorState;
  displaySectionsNumber: number;


  constructor(globalStore, documentStore) {
    this.globalStore = globalStore;
    this.documentStore = documentStore;
    this.documentCommunicationService = new DocumentCommunicationService();
    this.thread = { ...initialThread };
    this.comments = [];
    this.attachments = [];
    this.editorState = EditorState.createEmpty();
    this.displaySectionsNumber = 0

    makeObservable(this, {
      isDrawerOpen: observable,
      comments: observable,
      attachments: observable,
      thread: observable,
      reset: action,
      getDocumentComments: flow,
      getDrawComments: flow,
      saveComment: flow,
      getAllProcessComments: flow,
      sendComment: flow,
      uploadDocumentCommentAttachments: flow,
      editorState: observable,
      setEditorState: action,
      setThreadMeta: action,
      getDisplaySections: action,
      isEditorContentEmpty: computed,
      displaySectionsNumber: observable
    });
  }

  setEditorState = editorState => {
    this.editorState = editorState;
  };

  reset(keepDrawerOpen = false) {
    this.thread = { ...initialThread };
    this.comments = [];
    this.attachments = [];
    this.isDrawerOpen = keepDrawerOpen;
  }

  *getDocumentComments(title: string, meta: ThreadMeta, type = undefined) {
    try {
      this.thread = {
        type,
        title,
        meta: {
          ...initialThread.meta,
          ...meta,
        },
      };
      const response: ApiResponse = yield this.documentCommunicationService.getDocumentComments(
        this.thread.meta
      );
      this.comments = response.data?.data;
      yield this.documentCommunicationService.markDocumentCommentsAsRead(
        this.thread.meta
      );
      this.isDrawerOpen = true;
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching the document comments',
      });
    }
  }

  *getAllProcessComments(process: string) {
    this.reset();
    const { loanId } = this.documentStore.loanStore.loanDetails;
    try {
      this.thread = {
        type: ThreadType.PROCESS,
        title: process,
        meta: { ...initialThread.meta, loanId },
      };
      const response: ApiResponse = yield this.documentCommunicationService.getAllDrawComments(
        process,
        this.thread.meta
      );

      this.comments = response.data?.data;

      yield this.documentCommunicationService.markAllDocumentCommentsAsRead(
        process,
        this.thread.meta
      );
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: `Error while fetching all ${process.toLowerCase()} comments`,
      });
    }
  }

  setThreadMeta(meta: ThreadMeta) {
    this.thread = { ...this.thread, meta };
  }

  *getDrawComments(title: string, meta: ThreadMeta, type = undefined) {
    try {
      this.thread = {
        type,
        title,
        meta: {
          ...initialThread.meta,
          ...meta,
        },
      };
      const response: ApiResponse = yield this.documentCommunicationService.getDrawComments(
        this.thread.meta
      );
      this.comments = response.data?.data;
      yield this.documentCommunicationService.markDocumentCommentsAsRead(
        this.thread.meta
      );
      this.isDrawerOpen = true;
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching the draw comments',
      });
    }
  }

  *saveComment(comment) {
    try {
      const attachmentIds = this.attachments.map(attachment => attachment.id);
      const newComment = {
        ...comment,
        ...this.thread.meta,
        attachmentIds,
      };
      const response: ApiResponse = yield this.documentCommunicationService.saveDocumentComment(
        newComment
      );
      const { drawId, borrowerId, collateralId, sectionId } = this.thread.meta;
      this.comments.push({
        ...response.data?.data,
        drawId,
        borrowerId,
        collateralId,
        sectionId,
      });
      this.editorState = EditorState.createEmpty();
      this.attachments = [];
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while saving a document comment',
      });
    }
  }

  *uploadDocumentCommentAttachments(files) {
    try {
      const newAttachments = [];
      for (let i = 0; i < files.length; i++) {
        const {
          data: response,
        } = yield this.documentCommunicationService.uploadDocumentCommentAttachment(
          files[i]
        );
        newAttachments.push(response.data);
      }
      this.attachments = [...this.attachments, ...newAttachments];

      let newContent = this.editorState;
      for (let i = 0; i < newAttachments.length; i++) {
        if (newAttachments[i]) {
          newContent = imagePlugin.addImage(newContent, newAttachments[i].url);
        }
      }
      this.editorState = newContent;
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while uploading attachments',
      });
    }
  }

  *sendComment() {
    const currentComment = {
      ...newComment,
      commentText: draftToHtml(
        convertToRaw(this.editorState.getCurrentContent())
      ),
    };

    yield this.saveComment(currentComment);
  }

  getDisplaySections(process, drawList = []) {
    if (process === DRAWS) {
      return drawList
        .map(draw => {
          return (this.documentStore.sections.draws ?? []).map(s => ({
            drawId: draw.drawId,
            sectionId: s.sectionId,
            borrowerId: null,
            collateralId: null,
            sectionName: `Draw #${draw.drawNumber} - ${s.sectionName}`,
          }));
        })
        .reduce((a, b) => a.concat(b), []);
    } else {
      this.displaySectionsNumber = this.documentStore.displaySections?.[process].length
      return (this.documentStore.displaySections?.[process] || [])
        .map(s => s.sections)
        .reduce((a, b) => a.concat(b), []);
    }
  }

  get isEditorContentEmpty() {
    const currentContent = this.editorState.getCurrentContent();
    const isEditorEmpty = !currentContent.hasText();
    const currentPlainText = currentContent.getPlainText();
    const lengthOfTrimmedContent = currentPlainText.trim().length;
    const containOnlySpaces = !isEditorEmpty && !lengthOfTrimmedContent;
    return isEditorEmpty || containOnlySpaces;
  }
}

export default DocumentCommunicationStore;
