import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { AllLenders } from "./standAloneAppraisalFieldsInternal";

export const LenderForm = ({ submitAppraisalStore }) => {
  return (
    <Grid item container justifyContent="center" spacing={2}>
      <Grid item xs={12}>
        <Typography gutterBottom variant="h5">
          Lender Information
        </Typography>
        <Typography>
          Please select a lender from the list
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <AllLenders submitAppraisalStore={submitAppraisalStore} />
      </Grid>
    </Grid>
  );
}
