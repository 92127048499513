import { Box, Grid, Theme, Tooltip, Typography } from "@material-ui/core";
import { makeStyles, styled } from "@material-ui/core/styles";
import { observer } from "mobx-react";
import { createCheckboxField, createTextField } from "../../../components/formComponents";
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

const useStyles = makeStyles((theme: Theme) => {
  return {
    container: {
      display: 'flex',
      position: 'relative',
      maxWidth: 1300,
      margin: 'auto',
    },
    iconTooltip: {
      color: theme.palette.primary.main
    },
    titleMargin: {
      marginBottom: '10px'
    }
  };
})

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .MuiTooltip-tooltip`]: {
    backgroundColor: '#FFFFFF',
    color: theme.palette.text.primary,
    maxWidth: 363,
    fontSize: theme.typography.pxToRem(13),
    border: '1px solid #dadde9',
  },
}));

export const MersDetailsForm = observer((props) => {
  const { store } = props;
  const classes = useStyles();

  return (
    <Grid container spacing={3} className={classes.container}>
      <Grid item xs={12}>
        <Typography variant="h4" className={classes.titleMargin}>
          Lender Details
        </Typography>
        <Typography variant="body1">
          Please provide the following information to complete MERs onboarding.
        </Typography>
      </Grid>
      <Grid item xs={12} md={6} lg={6}>
        {createTextField({
          label: 'Signatory First Name',
          fieldName: 'signatoryFirstName',
          testId: 'signatoryFirstName',
          store: store,
        })}
      </Grid>
      <Grid item xs={12} md={6} lg={6}>
        {createTextField({
          label: 'Signatory Last Name',
          fieldName: 'signatoryLastName',
          testId: 'signatoryLastName',
          store: store,
        })}
      </Grid>
      <Grid item xs={12} md={6} lg={6}>
        {createTextField({
          label: 'Signatory Title',
          fieldName: 'signatoryTitle',
          testId: 'signatoryTitle',
          store: store,
        })}
      </Grid>
      <Grid item xs={12}>
        <Grid container direction="row">
          {createCheckboxField({
            store,
            fieldName: 'mersTraining',
            label: "I hereby certify that I have completed MERs training *",
            disabled: false
          })}
          <Box>
            <HtmlTooltip
              interactive
              title={
                <>
                  <Typography color="primary">MERs Training Information</Typography>
                  <br />
                  <p>Please visit MERSinc.org and submit an application. </p>
                  <br />
                  <p>You should be able to apply for MERS via this link:  <a rel="noreferrer" target="_blank" href="https://eapplication.mersinc.org">https://eapplication.mersinc.org</a></p>
                  <br />
                  <p>Once the application is submitted, you will be assigned a MERS representative. Notify the rep that you are seeking a Lite-R membership under the Loan Funder LLC arm.</p>
                  <br />
                  <p>MERS has provided the following support contact:</p>
                  <a rel="noreferrer" target="_blank" href="https://www.mersinc.org/about/sales">https://www.mersinc.org/about/sales</a>
                </>
              }>
              <HelpOutlineIcon className={classes.iconTooltip} />
            </HtmlTooltip>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  )
})