import { Grid, Typography, capitalize } from '@material-ui/core';
import { ComparableProperty } from '../../types/propertyTypes';
import { observer } from 'mobx-react';

export interface IndividualPropertyDetailsProps {
  property: ComparableProperty;
}

export const IndividualPropertyDetails: React.FC<IndividualPropertyDetailsProps> = observer(({
  property
}) => {

  return (
    <>
      <Grid item xs={12} sm={9}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography align="left" style={{ fontSize: '26px', fontWeight: 'bold' }}>
              {property.data.address}
            </Typography>
          </Grid>
          {property.data.school_district &&
            <Grid item xs={12}>
              <Typography align="left" style={{ fontSize: '18px', fontStyle: 'italic', textTransform: 'capitalize' }}>
                {property.data.school_district}
              </Typography>
            </Grid>
          }
          <Grid item xs={12} sm={7} lg={7}>
            <Typography align="left" variant="body1">
              Distance to the Subject: <strong>{property.similarity.mi_distance ? `${property.similarity.mi_distance.toFixed(2)}` : '-'} mi</strong>
            </Typography>
          </Grid>
          {property.data.property_type &&
            <Grid item xs={12} sm={5} lg={5}>
              <Typography align="left" style={{ fontSize: '15px' }}>
                <strong>{`${capitalize(property.data.property_type)}`}</strong>
              </Typography>
            </Grid>
          }
        </Grid>
      </Grid>
    </>
  );
});