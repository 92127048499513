import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Box, lighten, makeStyles, Theme, Typography, useTheme } from '@material-ui/core';
import { LoginLogoImg, Page } from '@roc/feature-app-core';
import { Paper, PaperBorderType, PreviewBackdrop } from '@roc/ui';
import clsx from 'clsx';
import { format } from 'date-fns';
import jsPDF from 'jspdf';
import React, { useEffect, useRef, useState } from 'react';
import { useDocumentLibraryRoutes } from '../../hooks/useDocumentLibraryRoutes';

const useStyles = makeStyles((theme: Theme) => ({
  documentContainer: {
    overflowX: 'auto',
    position: 'relative',
    height: '100%',
    padding: '16px',
    background: '#FFF',
    fontSize: 16,
    '& table': {
      width: '100%',
      textAlign: 'center',
      borderCollapse: "collapse",
    },
    '& table th': {
      padding: '5px',
      background: lighten(theme.palette.primary.main, 0.5),
      border: '0.25px solid black',
    },
    '& table td': {
      padding: '5px',
      borderCollapse: "collapse",
      border: '0.25px solid black',
      textAlign: 'left',
    },
    '& td p': {
      marginLeft: '5px'
    },
    '& ul,ol': {
      marginLeft: theme.spacing(3),
      marginBottom: theme.spacing(0.5),
    },
    '& h6': {
      fontSize: 16,
      padding: 4,
      fontWeight: 'bold',
    },
    '@media (max-width: 600px)': {
      fontSize: '8px',
      padding: 0,
    },
  },
  documentCardTable: {
    '& table th': {
      background: lighten(theme.palette.primary.main, 0.5),
      border: '0.25px solid black',
      padding: "9px",
    }
  },
  documentCard: {
    padding: "0!important"
  },
  documentCardTitle: {
    justifyContent: 'center',
    textAlign: 'center',
    position: "relative",
    marginRight: "4px",
    marginLeft: "4px",
    padding: "4px",
    top: "4px",
    background: lighten(theme.palette.primary.main, 0.5),
    fontWeight: 'bold',
    border: '0.25px solid black'
  }
}));

const usePrintModeStyles = makeStyles((theme: Theme) => ({
  documentContainer: {
    fontSize: 10,
    '& ul,ol': {
      marginLeft: theme.spacing(2),
    },
    '& h6': {
      fontSize: 12,
    },
  },
}));

const DocumentCard = ({
  title = undefined,
  data,
  paperBorder = PaperBorderType.SOLID_BORDER,
  paperClassName = '',
  paperTitleClassName = '',
  paperBodyClassName = '',
  paperStyle = {},
}) => {
  const classes = useStyles();
  return (
    <Paper
      title={title}
      titleClass={clsx(classes.documentCardTitle, paperTitleClassName)}
      titleVariant={'h6'}
      borderType={paperBorder}
      className={clsx(classes.documentCard, paperClassName)}
      style={paperStyle}>
      <Box p={0.5} className={paperBodyClassName}>
        {documentToReactComponents(data)}
      </Box>
    </Paper>
  )
}

export const FixAndFlipProgram = ({
  contentId,
  data,
  preview = false,
  printMode = false,
  setContainerRef,
}: {
  contentId: string,
  data: any,
  preview?: boolean,
  printMode?: boolean,
  setContainerRef: any,
}) => {
  const theme = useTheme();
  const { documentLibraryRoutesConfig } = useDocumentLibraryRoutes();
  const classes = useStyles();
  const printModeClasses = usePrintModeStyles();
  const containerRef = useRef(null);

  const [printModeContainerStyle, setPrintModeContainerStyle] = useState<any>();
  const [containerStyle, setContainerStyle] = useState<any>();

  useEffect(() => {
    const doc = new jsPDF("p", "mm", "a4");
    let width: any = doc.internal.pageSize.getWidth();
    let height: any = doc.internal.pageSize.getHeight();
    width = `${Math.floor(width)}mm`;
    height = `${Math.floor(height)}mm`;
    setPrintModeContainerStyle({
      margin: 'auto',
      minWidth: width,
      maxWidth: width,
      height: height
    });
    setContainerStyle({
      margin: 'auto',
      maxWidth: theme.breakpoints.values.lg,
    });
  }, []);

  useEffect(() => {
    setContainerRef(containerRef);
  }, [containerRef])

  if (!data) {
    return null;
  }

  return (
    <div ref={containerRef} style={printMode ? printModeContainerStyle : containerStyle}>

      <Page routeDefinition={documentLibraryRoutesConfig.documentLibraryDocument(contentId)}>
        <Box className={clsx(classes.documentContainer, printMode ? printModeClasses.documentContainer : undefined)}>
          {preview && <PreviewBackdrop />}
          <LoginLogoImg imgStyle={{ maxWidth: 200 }} />
          <Box my={2} textAlign='center'>
            <Paper
              title={data.title}
              titleClass={classes.documentCardTitle}
              titleVariant={'h4'}
              borderType={PaperBorderType.SOLID_BORDER}
              className={classes.documentCard}
            />
          </Box>
          <Box mb={2} paddingBottom="5%">
            <DocumentCard
              data={data.fixAndFlipProgramGeneral}
              paperBorder={PaperBorderType.NO_BORDER}
            />
          </Box>
          <Box mb={2} paddingBottom="5%">
            <DocumentCard
              data={data.fixAndFlipProgramKeyRatios}
              paperBorder={PaperBorderType.NO_BORDER}
            />
          </Box>
          <Box mb={2} paddingBottom="5%">
            <DocumentCard
              data={data.fixAndFlipProgramBorrowerGuarantor}
              paperBorder={PaperBorderType.NO_BORDER}
            />
          </Box>
          <Box mb={2} paddingBottom="5%">
            <DocumentCard
              data={data.fixAndFlipProgramPropertySourcing}
              paperBorder={PaperBorderType.NO_BORDER}
            />
          </Box>
        </Box>
      </Page>

    </div>
  );
}