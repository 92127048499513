import { Checkbox } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import Edit from '@material-ui/icons/Edit';
import { useStore } from '@roc/client-portal-shared/hooks';
import { Button, IconButton } from '@roc/ui';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { Grid } from '../../../components';
import { Toolbar } from '../../../components/toolbar/toolbar';
import { MailingListSubscription } from '../types/companyProfileTypes';
import { mailingListsColumns } from './mailingListsColumns';

export const MailingListsGrid = observer(() => {
  const { companyProfileStore } = useStore();
  const mailingListsStore = companyProfileStore.mailingListsStore;
  const gridStore = mailingListsStore.mailingListsGridStore;

  const onAddNewMail = () => {
    mailingListsStore.showAddMailDialog();
  };

  const onEditMail = (data: MailingListSubscription) => {
    mailingListsStore.showEditMailDialog(data);
  };

  const onDeleteMail = (data: MailingListSubscription) => {
    mailingListsStore.deleteEmail(data);
  };

  useEffect(() => {
    gridStore.fetchGridData();
  }, []);

  const onSortChanged = params => {
    const sortModel = params.api.getSortModel();
    gridStore.setSortModel(sortModel);
  };

  const frameworkComponents = {
    checkboxCellRenderer(params) {
      return (
        <Checkbox
          checked={params.value}
          onChange={() => void 0}
          disabled
          color="secondary"
        />
      );
    },
    actionCellRenderer(params) {
      const data: MailingListSubscription = params.node.data;
      return (
        <>
          <IconButton
            testId={`edit-mailing-list-subscription-${data?.email}`}
            onClick={() => {
              onEditMail(data);
            }}
          >
            <Edit color="primary" />
          </IconButton>
          <IconButton
            testId={`delete-mailing-list-subscription-${data?.email}`}
            onClick={() => {
              onDeleteMail(data);
            }}
          >
            <Delete color="primary" />
          </IconButton>
        </>
      );
    },
  };

  const toolbar = (
    <Toolbar>
      <Button
        variant="contained"
        size="small"
        onClick={onAddNewMail}
        color="primary"
        testId="add_new_borrower"
      >
        Add new mail
      </Button>
    </Toolbar>
  );

  return (
    <Grid
      columns={mailingListsColumns}
      rows={gridStore.gridData.data.rows}
      frameworkComponents={frameworkComponents}
      onSortChanged={onSortChanged}
      isLoading={gridStore.isLoading}
      toolbar={toolbar}
      sortModel={gridStore.sortModel}
      paginationData={gridStore.gridData.meta}
      setPageNumber={gridStore.setPageNumber}
    />
  );
});
