import { LoanStatus } from '@roc/feature-utils';
import {
  ClosingStage,
  LoanDetailsTab,
  TodoItem,
  TodoStatus,
} from './loanDetailsTypes';

export const statusOptions = [
  {
    label: 'Pending',
    value: TodoStatus.PENDING,
  },
  {
    label: 'Being Reviewed',
    value: TodoStatus.BEING_REVIEWED,
  },
  {
    label: 'Accepted',
    value: TodoStatus.ACCEPTED,
  },
  {
    label: 'More Info Needed',
    value: TodoStatus.MORE_INFO_NEEDED,
  },
  {
    label: 'Not Applicable',
    value: TodoStatus.NOT_APPLICABLE,
  },
];

export const stepsByLoanStatus = {
  [LoanStatus.PENDING]: [
    { title: 'Submission', completed: true },
    { title: 'Pending To-dos', completed: true, active: true },
    { title: 'Underwriting' },
    { title: 'Committee' },
    { title: 'Approved' },
    { title: 'Funded' },
    { title: 'Paid Off' },
  ],
  [LoanStatus.IN_UNDERWRITING]: [
    { title: 'Submission', completed: true },
    { title: 'Pending To-dos', completed: true },
    { title: 'Underwriting', completed: true, active: true },
    { title: 'Committee' },
    { title: 'Approved' },
    { title: 'Funded' },
    { title: 'Paid Off' },
  ],
  [LoanStatus.PENDING_DECISION]: [
    { title: 'Submission', completed: true },
    { title: 'Underwriting', completed: true },
    { title: 'Committee', completed: true, active: true },
    { title: 'Approved' },
    { title: 'Funded' },
    { title: 'Paid Off' },
  ],
  [LoanStatus.AGREED]: [
    { title: 'Submission', completed: true },
    { title: 'Underwriting', completed: true },
    { title: 'Committee', completed: true },
    { title: 'Approved', completed: true },
    { title: 'Funded' },
    { title: 'Paid Off' },
  ],
  [LoanStatus.FUNDED]: [
    { title: 'Submission', completed: true },
    { title: 'Underwriting', completed: true },
    { title: 'Committee', completed: true },
    { title: 'Approved', completed: true },
    { title: 'Funded', completed: true },
    { title: 'Paid Off' },
  ],
  [LoanStatus.PAID_OFF]: [
    { title: 'Submission', completed: true },
    { title: 'Underwriting', completed: true },
    { title: 'Committee', completed: true },
    { title: 'Approved', completed: true },
    { title: 'Funded', completed: true },
    { title: 'Paid Off', completed: true },
  ],
  [LoanStatus.PAID_OFF_BUYOUT]: [
    { title: 'Submission', completed: true },
    { title: 'Underwriting', completed: true },
    { title: 'Committee', completed: true },
    { title: 'Approved', completed: true },
    { title: 'Funded', completed: true },
    { title: 'Paid Off', completed: true },
  ],
  [LoanStatus.DECLINED]: [
    { title: 'Submission', completed: true },
    { title: 'Declined', completed: true, negative: true },
  ],
  [LoanStatus.RETRACTED]: [
    { title: 'Submission', completed: true },
    { title: 'Retracted', completed: true, negative: true },
  ],
  [LoanStatus.ON_HOLD]: [
    { title: 'Submission', completed: true },
    { title: 'On Hold', completed: true, active: true },
    { title: 'Retracted' },
  ],
  [LoanStatus.PENDING_SUBMISSION]: [
    { title: 'Pending Submission', completed: true, warning: true },
    { title: 'Underwriting' },
    { title: 'Committee' },
    { title: 'Approved' },
    { title: 'Funded' },
    { title: 'Paid Off' },
  ],
  [LoanStatus.APPLICATION_SUBMITTED]: [
    { title: 'Application Submitted', completed: true },
    { title: 'Pending To-dos', completed: true, active: true },
    { title: 'Underwriting' },
    { title: 'Committee' },
    { title: 'Approved' },
    { title: 'Funded' },
    { title: 'Paid Off' },
  ],
  [LoanStatus.APPLICATION_REJECTED]: [
    { title: 'Application Submitted', completed: true },
    { title: 'Application Rejected', completed: true, negative: true },
  ],
};

export const stepsByClosingStatus = {
  [ClosingStage.UNDEFINED]: [
    { title: 'Loan Agreed', completed: false },
    { title: 'Title Contacted', completed: false },
    { title: 'Closing Scheduled', completed: false },
    { title: 'Pending Approval', completed: false },
    { title: 'Approved to Fund', completed: false },
    { title: 'Docs Sent for Signing', completed: false },
    { title: 'Wire Requested', completed: false },
    { title: 'Wire Sent', completed: false },
    { title: 'Loan Closed & Funded', completed: false },
  ],
  [ClosingStage.LOAN_AGREED]: [
    { title: 'Loan Agreed', completed: true },
    { title: 'Title Contacted', completed: false },
    { title: 'Closing Scheduled', completed: false },
    { title: 'Pending Approval', completed: false },
    { title: 'Approved to Fund', completed: false },
    { title: 'Docs Sent for Signing', completed: false },
    { title: 'Wire Requested', completed: false },
    { title: 'Wire Sent', completed: false },
    { title: 'Loan Closed & Funded', completed: false },
  ],
  [ClosingStage.TITLE_CONTACTED]: [
    { title: 'Loan Agreed', completed: true },
    { title: 'Title Contacted', completed: true },
    { title: 'Closing Scheduled', completed: false },
    { title: 'Pending Approval', completed: false },
    { title: 'Approved to Fund', completed: false },
    { title: 'Docs Sent for Signing', completed: false },
    { title: 'Wire Requested', completed: false },
    { title: 'Wire Sent', completed: false },
    { title: 'Loan Closed & Funded', completed: false },
  ],
  [ClosingStage.CLOSING_SCHEDULED]: [
    { title: 'Loan Agreed', completed: true },
    { title: 'Title Contacted', completed: true },
    { title: 'Closing Scheduled', completed: true },
    { title: 'Pending Approval', completed: false },
    { title: 'Approved to Fund', completed: false },
    { title: 'Docs Sent for Signing', completed: false },
    { title: 'Wire Requested', completed: false },
    { title: 'Wire Sent', completed: false },
    { title: 'Loan Closed & Funded', completed: false },
  ],
  [ClosingStage.PENDING_APPROVAL]: [
    { title: 'Loan Agreed', completed: true },
    { title: 'Title Contacted', completed: true },
    { title: 'Closing Scheduled', completed: true },
    { title: 'Pending Approval', completed: true },
    { title: 'Approved to Fund', completed: false },
    { title: 'Docs Sent for Signing', completed: false },
    { title: 'Wire Requested', completed: false },
    { title: 'Wire Sent', completed: false },
    { title: 'Loan Closed & Funded', completed: false },
  ],
  [ClosingStage.APPROVED_TO_FUND]: [
    { title: 'Loan Agreed', completed: true },
    { title: 'Title Contacted', completed: true },
    { title: 'Closing Scheduled', completed: true },
    { title: 'Pending Approval', completed: true },
    { title: 'Approved to Fund', completed: true },
    { title: 'Docs Sent for Signing', completed: false },
    { title: 'Wire Requested', completed: false },
    { title: 'Wire Sent', completed: false },
    { title: 'Loan Closed & Funded', completed: false },
  ],
  [ClosingStage.DOCS_SENT_FOR_SIGNING]: [
    { title: 'Loan Agreed', completed: true },
    { title: 'Title Contacted', completed: true },
    { title: 'Closing Scheduled', completed: true },
    { title: 'Pending Approval', completed: true },
    { title: 'Approved to Fund', completed: true },
    { title: 'Docs Sent for Signing', completed: true },
    { title: 'Wire Requested', completed: false },
    { title: 'Wire Sent', completed: false },
    { title: 'Loan Closed & Funded', completed: false },
  ],
  [ClosingStage.WIRE_REQUESTED]: [
    { title: 'Loan Agreed', completed: true },
    { title: 'Title Contacted', completed: true },
    { title: 'Closing Scheduled', completed: true },
    { title: 'Pending Approval', completed: true },
    { title: 'Approved to Fund', completed: true },
    { title: 'Docs Sent for Signing', completed: true },
    { title: 'Wire Requested', completed: true },
    { title: 'Wire Sent', completed: false },
    { title: 'Loan Closed & Funded', completed: false },
  ],
  [ClosingStage.WIRE_SENT]: [
    { title: 'Loan Agreed', completed: true },
    { title: 'Title Contacted', completed: true },
    { title: 'Closing Scheduled', completed: true },
    { title: 'Pending Approval', completed: true },
    { title: 'Approved to Fund', completed: true },
    { title: 'Docs Sent for Signing', completed: true },
    { title: 'Wire Requested', completed: true },
    { title: 'Wire Sent', completed: true },
    { title: 'Loan Closed & Funded', completed: false },
  ],
  [ClosingStage.LOAN_CLOSED_AND_FUNDED]: [
    { title: 'Loan Agreed', completed: true },
    { title: 'Title Contacted', completed: true },
    { title: 'Closing Scheduled', completed: true },
    { title: 'Pending Approval', completed: true },
    { title: 'Approved to Fund', completed: true },
    { title: 'Docs Sent for Signing', completed: true },
    { title: 'Wire Requested', completed: true },
    { title: 'Wire Sent', completed: true },
    { title: 'Loan Closed & Funded', completed: true },
  ],
};

enum DocumentStatus {
  PENDING = 'Pending',
  BEING_REVIEWED = 'Being Reviewed',
  MORE_INFO_NEEDED = 'More Info Needed',
  ACCEPTED = 'Accepted',
  NOT_APPLICABLE = 'Not Applicable',
  REPORTS_DELIVERED = 'Report(s) Delivered',
  CANCELED = 'Canceled',
}

export const todoStatusMapping: Record<Exclude<TodoStatus, TodoStatus.ALL>, DocumentStatus> = {
  [TodoStatus.ACCEPTED]: DocumentStatus.ACCEPTED,
  [TodoStatus.PENDING]: DocumentStatus.PENDING,
  [TodoStatus.MORE_INFO_NEEDED]: DocumentStatus.MORE_INFO_NEEDED,
  [TodoStatus.NOT_APPLICABLE]: DocumentStatus.NOT_APPLICABLE,
  [TodoStatus.BEING_REVIEWED]: DocumentStatus.BEING_REVIEWED,
  [TodoStatus.REPORTS_DELIVERED]: DocumentStatus.REPORTS_DELIVERED,
  [TodoStatus.CANCELED]: DocumentStatus.CANCELED,
};

export const StatusFilterType = {
  IN_REVIEW_OR_PENDING: [
    TodoStatus.BEING_REVIEWED,
    TodoStatus.PENDING,
    TodoStatus.MORE_INFO_NEEDED,
  ].join(','),
};
