import { ApiResponse, EnvironmentStore, GlobalStore } from '@roc/feature-app-core';
import {
  PortalConfiguration
} from '@roc/feature-types';
import { selectedCompanyStorage, isElectronApp, isElectronAppLocalHost, isLocalHost, isMobileApp, isMobileAppLocalHost, isNil } from '@roc/feature-utils';
import { flow, makeObservable, observable } from 'mobx';
import { PortalConfigurationService } from '../services/portalConfigurationService';

export class WhiteLabelStore {
  private globalStore: GlobalStore;
  portalConfigurationService: PortalConfigurationService;
  showErrorPage: boolean;
  environmentStore: EnvironmentStore;

  constructor(globalStore, environmentStore) {
    this.portalConfigurationService = new PortalConfigurationService();
    this.globalStore = globalStore;
    this.environmentStore = environmentStore;
    makeObservable(this, {
      showErrorPage: observable,
      fetchCorrectPortalConfigurationByHost: flow,
      fetchCorrectPortalConfigurationByPortalId: flow,
      fetchPortalConfiguration: flow,
      getHostByPortaId: flow,
    });
  }

  *fetchCorrectPortalConfigurationByHost(host: string) {
    try {
      const apiResponse: ApiResponse = yield this.portalConfigurationService.getPortalConfigurationByHost(host);
      const portalConfigurationResponse: PortalConfiguration = apiResponse.data.data;
      this.globalStore.setPortalConfiguration(portalConfigurationResponse);
    } catch (e) {
      console.error(e);
      throw new Error(e);
    }
  }

  *fetchCorrectPortalConfigurationByPortalId(portalId) {
    try {
      const apiResponse: ApiResponse = yield this.portalConfigurationService.getPortalConfigurationById(portalId);
      const portalConfigurationResponse: PortalConfiguration = apiResponse.data.data;
      this.globalStore.setPortalConfiguration(portalConfigurationResponse);
    } catch (e) {
      console.error(e);
      throw new Error(e);
    }
  }

  *getHostByPortaId(portalId) {
    const apiResponse: ApiResponse = yield this.portalConfigurationService.getPortalConfigurationById(portalId);
    const portalConfigurationResponse: PortalConfiguration = apiResponse.data.data;
    return portalConfigurationResponse.host;
  }

  *fetchPortalConfiguration(portalId = null) {
    try {
      if (portalId) {
        yield this.fetchCorrectPortalConfigurationByPortalId(portalId);
        return;
      }

      const localStoragePortalId = selectedCompanyStorage.getSelectedCompanyPortalIdFromStorage();
      if ((this.globalStore.isLenderPortal || this.globalStore.isCrmPortal) && localStoragePortalId) {
        yield this.fetchCorrectPortalConfigurationByPortalId(localStoragePortalId);
        return;
      }

      let host;
      if (isMobileApp()) {
        if (isMobileAppLocalHost()) {
          host = this.environmentStore.environmentVariables?.whitelabelHost;
        } else {
          host = (window as any).MOBILE_UI_GLOBAL_DATA.whitelabelHost;
        }
      }
      else if (isElectronApp()) {
        if (isElectronAppLocalHost()) {
          host = this.environmentStore.environmentVariables?.whitelabelHost;
        } else {
          host = (window as any).CHAT_UI_GLOBAL_DATA.whitelabelHost;
        }
      }
      else if (isLocalHost()) {
        host = this.environmentStore.environmentVariables?.whitelabelHost;
      }
      host = host ?? window.location.origin;

      yield this.fetchCorrectPortalConfigurationByHost(host);
    } catch (e) {
      this.showErrorPage = true;
    }
  }

}
