import { Grid } from '@material-ui/core';
import {
  createNumberFormatField,
  createSelectField,
} from '@roc/client-portal-shared/components';
import { Slider, createSliderField, createTextField, trueFalseOptions } from '@roc/ui';
import { observer } from 'mobx-react';
import React from 'react';
import { propertyTypes, yesNoOptions, citizenshipStatusOptions, stateMapping, MULTIFAMILY_5_PLUS } from '@roc/client-portal-shared/utils';
import { amortizationOptions } from '@roc/feature-loans';
import { InternalPricerStore } from '../stores/internalPricerStore';
import { prepaymentPenaltyOptions, rateTypeOptions, upbOptions, borrowerExpOptions, geoTierOptions, loanSubtypeOptions, debtYieldOptions, dscrOptions, requestedLTVOptions, creditScoreOptions, loanPurposeOptions, targetPriceOptions, foreignNationalOptions, recourseOptions, multifamilyUpbOptions } from '../utils/internalPricerConstants';

interface PricerFieldProps {
  store: InternalPricerStore;
  helperText: string;
}

const field = FieldComponent => {
  const WrappedFieldComponent = observer(FieldComponent);
  return props => (
    <Grid item xs={props.xs ?? 12} md={props.md ?? 6}>
      <WrappedFieldComponent {...props} />
    </Grid>
  );
};

const marks = [
  {
    value: 0,
    label: '0%',
  },
  {
    value: 6,
    label: '6%',
  },
]

export const RateType = field(({ store }: PricerFieldProps) => {
  return createSelectField({
    store,
    label: 'Rate Type',
    testId: 'rateType',
    fieldName: 'rateType',
    options: rateTypeOptions
  });
});

export const CreditScore = field(({ store }: PricerFieldProps) => {
  return createSelectField({
    store,
    label: 'Qualifying FICO Score',
    testId: 'creditScore',
    fieldName: 'creditScore',
    options: creditScoreOptions
  });
});

export const RequestedLTV = field(({ store }: PricerFieldProps) => {
  return createSelectField({
    store,
    label: 'Requested LTV',
    testId: 'requestedLtv',
    fieldName: 'requestedLtv',
    options: requestedLTVOptions
  });
});

export const LoanPurpose = field(({ store }: PricerFieldProps) => {
  return createSelectField({
    store,
    label: 'Loan Purpose',
    testId: 'loanPurpose',
    fieldName: 'loanPurpose',
    options: loanPurposeOptions
  });
});

export const AmortizationType = field(({ store }: PricerFieldProps) => {
  return createSelectField({
    store,
    label: 'Amortization Type',
    testId: 'amortizationType',
    fieldName: 'amortizationType',
    options: amortizationOptions
  });
}
);

export const PropertyType = field(({ store }: PricerFieldProps) => {
  return createSelectField({
    store,
    label: 'Property Type',
    testId: 'propertyType',
    fieldName: 'propertyType',
    options: propertyTypes
  });
}
);

export const AsIsValue = field(({ store }: PricerFieldProps) => {
  return createNumberFormatField({
    store,
    label: 'As Is Value',
    testId: 'asIsValue',
    fieldName: 'asIsValue',
    allowNegative: false,
  });
});

export const DSCR = field(({ store }: PricerFieldProps) => {
  return createSelectField({
    store,
    label: 'DSCR',
    testId: 'dscr',
    fieldName: 'dscr',
    options: dscrOptions
  });
});

export const PrepaymentPenalty = field(({ store }: PricerFieldProps) => {
  return createSelectField({
    store,
    label: 'Prepayment Penalty',
    testId: 'prePaymentPenalty',
    fieldName: 'prePaymentPenalty',
    options: prepaymentPenaltyOptions
  });
});

export const ForeignNational = field(({ store }: PricerFieldProps) => {
  return createSelectField({
    store,
    label: 'Is Borrower a Foreign National?',
    testId: 'foreignNationalFlag',
    fieldName: 'foreignNationalFlag',
    options: foreignNationalOptions
  });
});

export const BorrowerExperience = field(({ store }: PricerFieldProps) => {
  return createSelectField({
    store,
    label: 'Borrower\'s Experience Level',
    testId: 'borrowerExp',
    fieldName: 'borrowerExp',
    options: borrowerExpOptions
  });
});

export const GeoTier = field(({ store }: PricerFieldProps) => {
  return createSelectField({
    store,
    label: 'Geo Tier',
    testId: 'geoTier',
    fieldName: 'geoTier',
    options: geoTierOptions
  });
});

export const State = field(({ store }: PricerFieldProps) => {
  return createSelectField({
    store,
    label: 'Property State',
    testId: 'state',
    fieldName: 'state',
    options: stateMapping
  });
});

export const County = field(({ store }: PricerFieldProps) => {
  return createSelectField({
    store,
    label: 'Property County',
    testId: 'county',
    fieldName: 'county',
    options: store.counties
  });
});

export const LoanSubtype = field(({ store }: PricerFieldProps) => {
  return createSelectField({
    store,
    label: 'Loan Subtype',
    testId: 'loanSubtype',
    fieldName: 'loanSubtype',
    options: loanSubtypeOptions
  });
});

export const DebtYield = field(({ store }: PricerFieldProps) => {
  return createSelectField({
    store,
    label: 'Debt Yield',
    testId: 'debtYield',
    fieldName: 'debtYield',
    options: debtYieldOptions
  });
});

export const Recourse = field(({ store }: PricerFieldProps) => {
  return createSelectField({
    store,
    label: 'Recourse',
    testId: 'fullRecourse',
    fieldName: 'fullRecourse',
    options: recourseOptions
  });
});

export const TargetPrice = field(({ store }: PricerFieldProps) => {
  return createSelectField({
    store,
    label: 'Target Price',
    testId: 'targetPrice',
    fieldName: 'targetPrice',
    options: targetPriceOptions
  });
});

export const NumberOfProperties = field(({ store }: PricerFieldProps) => {
  return createNumberFormatField({
    store,
    label: 'Number of Properties',
    testId: 'numberOfProperties',
    fieldName: 'numberOfProperties',
    allowNegative: false
  })
});

export const CurrentBuydown = field(({ store }: PricerFieldProps) => {
  return createSelectField({
    store,
    label: 'Rate Buydown (bps)',
    testId: 'currentBuydown',
    fieldName: 'currentBuydown',
    options: store.buydownList
  })
});

export const LenderName = field(({ store }: PricerFieldProps) => {
  return createSelectField({
    store,
    label: 'Lender Name',
    testId: 'lenderId',
    fieldName: 'lenderId',
    options: store.lenderNames,
  });
});

export const TotalOriginationPoints = field(({ store }: PricerFieldProps) => {
  return createSliderField({
    store,
    label: 'Total Origination Points',
    testId: 'totalOriginationPoints',
    fieldName: 'totalOriginationPoints',
    step: 0.1,
    min: 0,
    max: 6,
    valueLabelDisplay: 'on',
    marks: marks,
  });
});

export const TotalUnderwritingAndProcessingFees = field(({ store }: PricerFieldProps) => {
  return createNumberFormatField({
    store,
    label: 'Total Underwriting & Processing Fees',
    testId: 'totalFees',
    fieldName: 'totalFees',
    allowNegative: false,
  });
});

export const BrokerName = field(({ store }: PricerFieldProps) => {
  return createTextField({
    store,
    label: 'Broker Name',
    testId: 'brokerName',
    fieldName: 'brokerName',
  });
});

export const BrokerFees = field(({ store }: PricerFieldProps) => {
  return createNumberFormatField({
    store,
    label: 'Broker Fees',
    testId: 'brokerFees',
    fieldName: 'brokerFees',
    allowNegative: false,
  });
});
