import { FormStore } from '@roc/feature-app-core';
import { action, makeObservable, observable, flow, computed } from 'mobx';
import { GlobalStore } from '@roc/feature-app-core';
import { AttorneysStore } from '../common/attorneysStore';
import CompanyFormStore from '../common/companyFormStore';
import { LoanService } from '../../../loans/services/loanService';
import { BRIDGE, LoanSubType, AllowedWimba, WIMBA, ELMSURE, OTHER, isNotBlank } from '@roc/feature-utils';
import { LoanOriginatorStore } from '../common/loanOriginatorStore';
import { InsuranceCompanyFormStore } from '../common/insuranceCompanyFormStore';
import { TitleCompanyFormStore } from '../common/titleCompanyFormStore';
import { ApiResponse } from '@roc/client-portal-shared/services';
import { FileUpload } from '@roc/ui';
import { getDealName } from '../../utils/dealNameUtils';
import { LoanParticipantsStore } from '@roc/feature-loan-participants';
import { BrokersStore } from '@roc/feature-brokers';
import { format, differenceInMonths } from "date-fns";

const loanInformationForm = {
  fields: {
    initialLoanAmount: {
      value: 0,
      error: null,
      rule: 'required',
    },
    initialLoanToPurchase: {
      value: 70,
      error: null,
      rule: 'required',
    },
    constructionHoldback: {
      value: 0,
      error: null,
      rule: 'required',
    },
    constructionHoldbackPercentage: {
      value: 100,
      error: null,
      rule: 'required',
    },
    exceptionLeverage: {
      value: false,
      error: null,
      rule: '',
    },
    exceptionLeverageReason: {
      value: '',
      error: null,
      rule: '',
    },
    exceptionLeverageReasonUnconfirmed: {
      value: '',
      error: null,
      rule: '',
    },
    exceptionInitialLoanAmount: {
      value: 0,
      error: null,
      rule: '',
    },
    exceptionInitialLoanToPurchase: {
      value: 70,
      error: null,
      rule: '',
    },
    exceptionConstructionHoldback: {
      value: 0,
      error: null,
      rule: '',
    },
    exceptionConstructionHoldbackPercentage: {
      value: 100,
      error: null,
      rule: '',
    },
    exceptionAmount: {
      value: 0,
      error: null,
      rule: '',
    },
    rate: {
      value: 12.0,
      error: null,
      rule: 'required',
    },
    pointsFormat: {
      value: 'percentage',
      error: null,
      rule: 'required',
    },
    points: {
      value: 2,
      error: null,
      rule: 'required|numeric|min:0|max:6',
      message:
        'The points field is required. Percentage must be between 0 and 6',
    },
    pointsNumber: {
      value: 0,
      error: null,
      rule: 'required|numeric',
    },
    brokerPointsFormat: {
      value: 'percentage',
      error: null,
      rule: 'required',
    },
    brokerPoints: {
      value: 0.0,
      error: null,
      rule: 'required|numeric|min:0|max:6',
      message:
        'The broker points field is required. Percentage must be between 0 and 6',
    },
    brokerPointsNumber: {
      value: 0,
      error: null,
      rule: 'required|numeric',
    },
    referralFee: {
      value: 0.0,
      error: null,
      rule: 'required|numeric|min:0|max:1',
      message:
        'The referral fee field is required and cannot exceed 1% of the total loan amount',
    },
    lenderUnderwritingFee: {
      value: 0,
      error: null,
      rule: 'required',
    },
    lenderProcessingFee: {
      value: 0,
      error: null,
      rule: '',
    },
    externalLenderUnderwritingFee: {
      value: 0,
      error: null,
      rule: '',
    },
    externalLenderProcessingFee: {
      value: 0,
      error: null,
      rule: '',
    },
    rocPointsIn: {
      value: null,
      error: null,
      rule: '',
    },
    interestAccrualMethod: {
      value: 'As disbursed',
      error: null,
      rule: 'required',
    },
    interestReserveMonths: {
      value: '1',
      error: null,
      rule: 'required',
    },
    duration: {
      value: '12',
      error: null,
      rule: 'required',
    },
    participation: {
      value: 'No Participation',
      error: null,
      rule: 'required',
    },
    origLenderSubordination: {
      value: 0.0,
      error: null,
      rule: 'required',
    },
    preferredClosingAttorney: {
      value: 'lawyer.hdrbb@roccapital.com',
      error: null,
      rule: 'required',
    },
    preferredInsurance: {
      value: '',
      error: null,
      rule: 'required',
    },
    interestReserveFinanced: {
      value: 'N',
      error: null,
      rule: 'required',
    },
    ltcCatchupDraw: {
      value: 'N',
      error: null,
      rule: 'required',
    },
    ltcCatchupDrawAmount: {
      value: 0,
      error: null,
      rule: '',
    },
    loanOriginator: {
      value: null,
      error: null,
      rule: '',
    },
    submitAppraisalWithTamarisk: {
      value: 'Y',
      error: null,
      rule: 'required',
    },
    condoConversion: {
      value: 'N',
      error: null,
      rule: ''
    },
    requestedClosingDate: {
      value: null,
      error: null,
      rule: '',
    },
    amount: {
      value: 0,
      error: null,
      rule: '',
    },
    preferredTitle: {
      value: '',
      error: null,
      rule: '',
    },
    fastTrack: {
      value: 'N',
      error: null,
      rule: 'required',
    },
    fastTrackOptions: {
      value: { insuranceReview: false, titleSearch: false, insurance: false, rushedAppraisal: false, titleAttorneySearch: false },
      error: null,
      rule: ''
    },
    wimbaAcknowledged: {
      value: null,
      error: null,
      rule: '',
    },
    freeAppraisalPromotion: {
      value: 'N',
      error: null,
      rule: '',
    },
    marketingPromotionId: {
      value: null,
      error: null,
      rule: '',
    },
    projectDescription: {
      value: '',
      error: null,
      rule: '',
    },
    referralFeesFormat: {
      value: 'percentage',
      error: null,
      rule: 'required',
    },
    referralFeesAmount: {
      value: 0,
      error: null,
      rule: 'required|numeric',
      message: 'The referral fee field is required',
    },
  },
  meta: {
    isValid: false,

    error: null,
  },
};

export class LoanInformationStore extends FormStore {
  private globalStore: GlobalStore;
  private fixFlipStore: any;
  private attorneysStore: AttorneysStore;
  private totalPurchasePrice = 0;
  private totalRenovationBudget = 0;
  private ltcCatchupDrawAmount = 0;
  private otherInsuranceDetails = {};
  private otherTitleDetails = {};
  otherInsuranceFormStore: CompanyFormStore;
  otherTitleFormStore: CompanyFormStore;
  loanOriginatorStore: LoanOriginatorStore;
  loanParticipantsStore: LoanParticipantsStore;
  public lenderAttorneysOptionList: any[];
  public wimbaStates: any[];
  public loanService: LoanService;
  public draftLoanId: string;
  public fastTrackFees = {};
  public fastTrackPaymentUrl: string;
  public licenseUpload: FileUpload[];
  public preferredAttorneyForBroker: string;

  constructor(globalStore, store) {
    super({ ...loanInformationForm }, globalStore);
    this.globalStore = globalStore;
    this.fixFlipStore = store;
    this.attorneysStore = new AttorneysStore(this.globalStore);
    this.loanOriginatorStore = new LoanOriginatorStore(this.globalStore);
    this.otherInsuranceFormStore = new InsuranceCompanyFormStore(this.globalStore);
    this.otherTitleFormStore = new TitleCompanyFormStore(this.globalStore);
    this.loanParticipantsStore = new LoanParticipantsStore(this.globalStore);
    this.loanService = new LoanService();
    this.setDefaults();
    makeObservable(this, {
      draftLoanId: observable,
      lenderAttorneysOptionList: observable,
      wimbaStates: observable,
      resetLoanInformation: action,
      makeInitialCalculations: action,
      changeInitialLoanAmount: action,
      changeInitialLoanToPurchase: action,
      changeExceptionInitialLoanAmount: action,
      changeExceptionInitialLoanToPurchase: action,
      changeConstructionHoldback: action,
      changeConstructionHoldbackPercentage: action,
      changeExceptionConstructionHoldback: action,
      changeExceptionConstructionHoldbackPercentage: action,
      initializeExceptionValues: action,
      changePointsPercentage: action,
      changePointsAmount: action,
      onChange: action,
      getPreferredAttorney: action,
      setOtherInsuranceCompany: action,
      initForm: action,
      getLenderAttorneys: flow,
      uploadInsuranceDocument: flow,
      fetchLoanId: flow,
      updateLoanFastrackInfo: flow,
      getWimbaStates: flow,
      requestFastTrackPaymentEmail: flow,
      loadFastTrackFees: flow,
      getLoanFastTrackPaymentUrl: flow,
      canSubmitAppraisalWithTamarisk: computed,
      getTotalRenovationBudget: action,
      getTotalPurchasePrice: action,
      licenseUpload: observable,
      setLicenseUpload: action,
      preferredAttorneyForBroker: observable,
      getBrokerVendorClosingSettings: flow,
      requestExceptionLeverage: action,
      resetBrokerPointsBasedOnHud: action,
    });
  }

  setDefaults() {
    this.otherInsuranceDetails = {};
    this.otherTitleDetails = {};
    this.lenderAttorneysOptionList = [];
    this.fastTrackFees = {};
    this.fastTrackPaymentUrl = '';
    this.draftLoanId = '';
    this.licenseUpload = [];
  }
  *getLenderAttorneys() {
    const isBroker = this.globalStore.userFeatures.isBroker;
    const isFlixAndFlipPro = this.fixFlipStore.loanSubtype === LoanSubType.FIX_AND_FLIP_PRO;
    yield this.attorneysStore.getLenderAttorneys(BRIDGE, this.getStatesFromProperties(this.fixFlipStore.properties), this.form?.fields?.amount?.value, isFlixAndFlipPro);
    if (isBroker) {
      this.setDefaultPreferredClosingAttorneyForBroker(this.attorneysStore.lenderAttorneysOptionList, this.fixFlipStore.brokersStore.manageBrokersStore.brokerVendorClosingSettings?.preferredClosingAttorney);
    } else {
      this.setDefaultPreferredClosingAttorney(this.attorneysStore.lenderAttorneysOptionList)
    }
    console.log(this.form.fields.preferredClosingAttorney.value);
    this.lenderAttorneysOptionList = this.attorneysStore.lenderAttorneysOptionList;

    if (isFlixAndFlipPro) {
      const filteredAttorneys = this.lenderAttorneysOptionList.filter(att => att.label === 'Internal');

      if (filteredAttorneys && filteredAttorneys.length > 0) {
        this.form.fields.preferredClosingAttorney.value = filteredAttorneys[0].value;
      }

    }

  }

  *getBrokerVendorClosingSettings() {
    const isBroker = this.globalStore.userFeatures.isBroker;
    if (isBroker) {
      yield this.fixFlipStore.brokersStore.manageBrokersStore.getBrokerVendorClosingSettingsByBrokerId(this.fixFlipStore.brokersStore.selectBrokersStore.brokers[0].brokerId);
    }
  }

  setDefaultPreferredClosingAttorney(attorneys) {
    if (isNotBlank(this.form.fields.preferredClosingAttorney.value) && !this.isClosingAttorneyPresentOnList(attorneys, this.form.fields.preferredClosingAttorney.value)) {
      this.isClosingAttorneyPresentOnList(attorneys, 'lawyer.hdrbb@roccapital.com') ?
        this.onFieldChange('preferredClosingAttorney', 'lawyer.hdrbb@roccapital.com') :
        this.onFieldChange('preferredClosingAttorney', 'lawyer.privatelenderlaw@roccapital.com');
    }
  }

  isClosingAttorneyPresentOnList(attorneys, preferredClosingAttorney) {
    return attorneys.some(attorney => attorney.value === preferredClosingAttorney);
  }

  setDefaultPreferredClosingAttorneyForBroker(attorneys, preferredClosingAttorney) {
    if (isNotBlank(this.form.fields.preferredClosingAttorney.value)) {
      console.log(this.isClosingAttorneyPresentOnList(attorneys, preferredClosingAttorney))
      this.isClosingAttorneyPresentOnList(attorneys, preferredClosingAttorney) ?
        this.onFieldChange('preferredClosingAttorney', preferredClosingAttorney) :
        this.onFieldChange('preferredClosingAttorney', 'lawyer.hdrbb@roccapital.com');
    }
  }

  *getWimbaStates() {
    try {
      const response = yield this.loanService.getWimbaStates();
      if (response && response.data && response.data.data) {
        this.wimbaStates = Array.isArray(response.data.data) ? response.data.data : JSON.parse(response.data.data)
      }
      if (this.wimbaStates.length == 0) {
        this.wimbaStates = AllowedWimba;
      }
    } catch (error) {
      console.log(error);
      this.wimbaStates = AllowedWimba;
    }
  }

  getStatesFromProperties(properties) {
    const states = properties
      .filter(property => property.state)
      .map(property => property.state);

    return states.join(',');
  }

  getLoanOriginators() {
    this.loanOriginatorStore.fetchOriginators();
  }
  resetLoanInformation() {
    this.reset();
    this.setDefaults();
    this.form = { ...loanInformationForm };
    this.otherInsuranceFormStore.reset();
    this.otherTitleFormStore.reset();
    const rocPointsIn = this.fixFlipStore.isMultifamilyBridge ? 1.0 : null;
    this.onChange('rocPointsIn', rocPointsIn);
    this.onChange(
      'submitAppraisalWithTamarisk', this.globalStore.lenderInfo?.tamariskDefaultOption ? 'Y' : 'N'
    );
    this.onChange(
      'loanOriginator',
      this.globalStore.userFeatures.isLenderOriginator
        ? this.fixFlipStore.userStore.userInformation.userId
        : null
    );
    this.onChange(
      'preferredInsurance',
      this.fixFlipStore.userStore.allowLoanPricing ? ELMSURE : OTHER
    );
  }

  initForm(data) {
    this.loadForm({
      ...data,
      submitAppraisalWithTamarisk: data.submitAppraisalWithTamarisk ? 'Y' : 'N',
    });
  }

  makeInitialCalculations() {
    this.calculateTotalPurchasePrice();
    this.calculateInitialLoanAmount();
    this.calculateConstructionHoldback();
    this.calculateTotalLoanAmount();
  }

  changeInitialLoanAmount(key, value) {
    this.onFieldChange(key, value);
    this.calculateInitialLoanToPurchase();
    this.calculateTotalLoanAmount();
    this.updateInterestAccrualMethod();
    this.onChange(key, value);
  }

  changeExceptionInitialLoanAmount(key, value) {
    this.onFieldChange(key, value);
    this.calculateExceptionInitialLoanToPurchase();
    this.calculateExceptionTotalLoanAmount();
    this.onChange(key, value);
  }

  private calculateExceptionInitialLoanToPurchase() {
    const initialLoanAmount = this.form.fields.exceptionInitialLoanAmount.value;
    const totalPurchase = this.totalPurchasePrice || 0;
    let exceptionInitialLoanToPurchase = 0;
    if (totalPurchase > 0 && initialLoanAmount > 0) {
      exceptionInitialLoanToPurchase = (initialLoanAmount * 100) / totalPurchase;
    }
    this.form.fields.exceptionInitialLoanToPurchase.value = Number(exceptionInitialLoanToPurchase.toFixed(2));
  }

  groundUpInitialCalculations(response){
    const lowerAmount = response?.maxARLTVAmount >= response?.maxLTFCAmount ? response?.maxLTFCAmount : response?.maxARLTVAmount;
    if(this.form.fields.ltcCatchupDraw.value == 'Y'){
      this.ltcCatchupDrawAmount = response?.maxLtcCatchup * 0.01 * this.fixFlipStore.properties[0]?.purchasePrice || 0;
      this.form.fields.ltcCatchupDrawAmount.value = this.ltcCatchupDrawAmount;
    }
    if(this.form.fields.ltcCatchupDraw.value == 'N'){
      this.ltcCatchupDrawAmount = 0;
      this.form.fields.ltcCatchupDrawAmount.value = this.ltcCatchupDrawAmount;
    }
    if(this.fixFlipStore.properties[0].permitsInPlace && this.form.fields.ltcCatchupDraw.value == 'Y'){
      this.ltcCatchupDrawAmount = 0;
      this.form.fields.ltcCatchupDrawAmount.value = this.ltcCatchupDrawAmount;
    }
    if(this.form.fields.initialLoanAmount.value + this.form.fields.constructionHoldback.value + this.ltcCatchupDrawAmount >= lowerAmount){
      this.globalStore.notificationStore.showErrorNotification({
        message: 'The leverage you have selected breaches the max LTFC or LTARV, please try again.',
      });
      this.form.fields.initialLoanAmount.value = lowerAmount - this.form.fields.constructionHoldback.value - this.ltcCatchupDrawAmount;
    }
  }
  private calculateExceptionTotalLoanAmount() {
    this.form.fields.exceptionAmount.value =
      this.form.fields.exceptionInitialLoanAmount.value +
      this.form.fields.exceptionConstructionHoldback.value;
  }

  changeInitialLoanToPurchase(key, value) {
    this.onFieldChange(key, value);
    this.calculateInitialLoanAmount();
    this.calculateTotalLoanAmount();
    this.updateInterestAccrualMethod();
    this.getLenderAttorneys();
    this.onChange(key, value);
  }

  changeExceptionInitialLoanToPurchase(key, value) {
    this.onFieldChange(key, value);
    this.calculateExceptionInitialLoanAmount();
    this.calculateExceptionTotalLoanAmount();
    this.onChange(key, value);
  }

  private calculateExceptionInitialLoanAmount() {
    const initialPercentage = this.form.fields.exceptionInitialLoanToPurchase.value;
    const totalPurchase = this.totalPurchasePrice || 0;
    let exceptionInitialLoanAmount = 0;
    if (totalPurchase > 0 && initialPercentage > 0) {
      exceptionInitialLoanAmount = totalPurchase * (initialPercentage / 100);
    }
    this.form.fields.exceptionInitialLoanAmount.value = Math.round(exceptionInitialLoanAmount);
  }

  changeConstructionHoldback(key, value) {
    this.onFieldChange(key, value);
    this.calculateConstHoldbackPercentage();
    this.calculateTotalLoanAmount();
    this.updateInterestAccrualMethod();
    this.onChange(key, value);
  }

  changeExceptionConstructionHoldback(key, value) {
    this.onFieldChange(key, value);
    this.calculateExceptionConstHoldbackPercentage();
    this.calculateExceptionTotalLoanAmount();
    this.onChange(key, value);
  }

  private calculateExceptionConstHoldbackPercentage() {
    const constructionHoldback = this.form.fields.exceptionConstructionHoldback.value;
    let constructionHoldbackPercentage = 0;
    const totalRenovation = this.getTotalRenovationBudget() || 0;
    if (totalRenovation > 0 && constructionHoldback > 0) {
      constructionHoldbackPercentage =
        (constructionHoldback * 100) / totalRenovation;
    }
    this.form.fields.exceptionConstructionHoldbackPercentage.value = Number(constructionHoldbackPercentage.toFixed(2));
  }

  changeConstructionHoldbackPercentage(key, value) {
    this.onFieldChange(key, value);
    this.calculateConstructionHoldback();
    this.calculateTotalLoanAmount();
    this.updateInterestAccrualMethod();
    this.getLenderAttorneys();
    this.onChange(key, value);
  }

  changeExceptionConstructionHoldbackPercentage(key, value) {
    this.onFieldChange(key, value);
    this.calculateExceptionConstructionHoldback();
    this.calculateExceptionTotalLoanAmount();
    this.onChange(key, value);
  }

  private calculateExceptionConstructionHoldback() {
    const totalRenovation = this.getTotalRenovationBudget() || 0;
    let constructionHoldbackPercentage = 0;
    if (
      totalRenovation > 0 &&
      this.form.fields.exceptionConstructionHoldbackPercentage.value > 0
    ) {
      constructionHoldbackPercentage =
        totalRenovation *
        (this.form.fields.exceptionConstructionHoldbackPercentage.value / 100);
    }
    this.form.fields.exceptionConstructionHoldback.value = constructionHoldbackPercentage;
  }

  initializeExceptionValues() {
    if (!this.form.fields.exceptionLeverage.value) {
      this.form.fields.exceptionInitialLoanAmount.value = this.form.fields.initialLoanAmount.value;
      this.form.fields.exceptionInitialLoanToPurchase.value = this.form.fields.initialLoanToPurchase.value;
      this.form.fields.exceptionConstructionHoldback.value = this.form.fields.constructionHoldback.value;
      this.form.fields.exceptionConstructionHoldbackPercentage.value = this.form.fields.constructionHoldbackPercentage.value;
      this.form.fields.exceptionAmount.value = this.form.fields.amount.value;
      this.form.fields.exceptionLeverageReasonUnconfirmed.value = this.form.fields.exceptionLeverageReason.value;
    }
    this.form.fields.exceptionLeverageReasonUnconfirmed.error = '';
  }

  requestExceptionLeverage() {
    this.form.fields.exceptionLeverage.value = true;
    this.form.fields.exceptionLeverageReason.value = this.form.fields.exceptionLeverageReasonUnconfirmed.value;
    this.globalStore.notificationStore.showInfoNotification({
      message: 'Exception requested.',
    });
  }

  changePointsPercentage(key, value, keyAmount) {
    this.onChange(key, value);
    const amount = this.calculatePointsAmount(value);
    this.onChange(keyAmount, amount);
  }

  changePointsAmount(key, value, keyPercentage) {
    this.onChange(key, value);
    const percentage = this.calculatePointsPercentage(value);
    this.onChange(keyPercentage, percentage);
  }

  onChange = (field, value) => {
    this.onFieldChange(field, value);
    const formValues = this.getFormValues();
    this.fixFlipStore.setLoanTerms({
      ...formValues,
      participation: formValues['participation'],
      lenderParticipation: this.mapLenderParticipation(
        formValues['participation']
      ),
      sellAs: this.mapSellAs(formValues['participation']),
      submitAppraisalWithTamarisk:
        formValues['submitAppraisalWithTamarisk'] === 'Y',
      condoConversion:
        formValues['condoConversion'] === 'Y',
      interestAccrualMethod: formValues['interestAccrualMethod'],
      fullPrincipalInterest:
        formValues['interestAccrualMethod'] === 'Full Boat',
      otherInsuranceCompany: {
        ...this.otherInsuranceDetails,
      },
      otherTitleCompany: {
        ...this.otherTitleDetails,
      },
    });
  };

  private calculatePointsAmount(percentage) {
    const loanAmount = this.form.fields.amount.value;
    return loanAmount ? (loanAmount / 100) * percentage : 0;
  }

  private calculatePointsPercentage(amount) {
    const loanAmount = this.form.fields.amount.value;
    return loanAmount ? (amount / loanAmount) * 100 : 0;
  }

  private updateInterestAccrualMethod() {
    const loanAmount = this.form.fields.amount.value;
    this.form.fields.interestAccrualMethod.value = loanAmount > 100000 ? 'As disbursed' : 'Full Boat';
  }

  private calculateInitialLoanAmount() {
    const initialPercentage = this.form.fields.initialLoanToPurchase.value;
    const totalPurchase = this.totalPurchasePrice || 0;
    let initialLoanAmount = 0;
    if (totalPurchase > 0 && initialPercentage > 0) {
      initialLoanAmount = totalPurchase * (initialPercentage / 100);
    }
    this.form.fields.initialLoanAmount.value = Math.round(initialLoanAmount);
  }

  private isPrincipalInterest(){
    const MAX_LOAN_AMOUNT_FOR_PRINCIPAL_INTEREST = 120000;
    const loanAmount = this.form.fields.amount.value;
    return loanAmount < MAX_LOAN_AMOUNT_FOR_PRINCIPAL_INTEREST;
  }

  private calculateMonthsBetween(purchaseDate, submissionDate){
    if(purchaseDate){
      const startDate = new Date(purchaseDate);
      const endDate = new Date(submissionDate);
      const monthsDifference = differenceInMonths(startDate, endDate);
      return Math.abs(monthsDifference);
    }
    else{
      return 12
    }
  }

  calculateInterest(){
    let calculatedLoanAmount = this.form.fields.amount.value;
    const purchaseDate = this.fixFlipStore.properties[0]?.purchaseDate;
    const submissionDate = format(new Date(), "MM/dd/yyyy");
    const MONTHS_DIVIDER = 1/12;
    const isFullPrincipalInterest = this.isPrincipalInterest();
    const timeToFlip = this.calculateMonthsBetween(purchaseDate, submissionDate) + 12
    if(!isFullPrincipalInterest){
      calculatedLoanAmount = this.form.fields.initialLoanAmount.value + (this.form.fields.constructionHoldback.value * 0.66);
    }
    const interest = calculatedLoanAmount * (this.form.fields.rate.value/100) * MONTHS_DIVIDER * timeToFlip;
    return interest;
  }

  private calculateInitialLoanToPurchase() {
    const initialLoanAmount = this.form.fields.initialLoanAmount.value;
    const totalPurchase = this.totalPurchasePrice || 0;
    let initialLoanToPurchase = 0;
    if (totalPurchase > 0 && initialLoanAmount > 0) {
      initialLoanToPurchase = (initialLoanAmount * 100) / totalPurchase;
    }
    this.form.fields.initialLoanToPurchase.value = Number(initialLoanToPurchase.toFixed(2));
  }

  private calculateConstructionHoldback() {
    const totalRenovation = this.getTotalRenovationBudget() || 0;
    let constructionHoldbackPercentage = 0;
    if (
      totalRenovation > 0 &&
      this.form.fields.constructionHoldbackPercentage.value > 0
    ) {
      constructionHoldbackPercentage =
        totalRenovation *
        (this.form.fields.constructionHoldbackPercentage.value / 100);
    }
    this.form.fields.constructionHoldback.value = constructionHoldbackPercentage;
  }

  private calculateConstHoldbackPercentage() {
    const constructionHoldback = this.form.fields.constructionHoldback.value;
    let constructionHoldbackPercentage = 0;
    const totalRenovation = this.getTotalRenovationBudget() || 0;
    if (totalRenovation > 0 && constructionHoldback > 0) {
      constructionHoldbackPercentage =
        (constructionHoldback * 100) / totalRenovation;
    }
    this.form.fields.constructionHoldbackPercentage.value = Number(constructionHoldbackPercentage.toFixed(2));
  }

  private calculateTotalRenovationBudget() {
    let total = 0;
    let renoBudget = 0;
    const properties = this.fixFlipStore.properties;
    if (properties.length > 0) {
      properties.forEach(value => {
        if (value.propertyOwnership === 'Purchase') {
          renoBudget = isNaN(Number(value.totalRenovationBudget))
            ? 0
            : Number(value.totalRenovationBudget);

          total += renoBudget;
        } else {
          renoBudget = isNaN(Number(value.totalRenovationBudget))
            ? 0
            : Number(value.totalRenovationBudget) - Number(value.sunkCost);

          total += renoBudget;
        }
      });
    }
    this.totalRenovationBudget = total;
  }

  public getTotalRenovationBudget() {
    this.calculateTotalRenovationBudget();
    return this.totalRenovationBudget;
  }

  public getTotalPurchasePrice() {
    this.calculateTotalPurchasePrice();
    return this.totalPurchasePrice;
  }

  calculateTotalPurchasePrice() {
    const properties = this.fixFlipStore.properties;
    let total = 0;
    let purchasePrice = 0;
    let renoRemaining = 0;
    if (properties.length > 0) {
      properties.forEach(value => {
        if (value.propertyOwnership === 'Purchase') {
          purchasePrice = isNaN(Number(value.purchasePrice))
            ? 0
            : Number(value.purchasePrice);

          total += purchasePrice;
        } else {
          purchasePrice = isNaN(Number(value.purchasePrice))
            ? 0
            : Number(value.purchasePrice);

          renoRemaining = isNaN(Number(value.sunkCost))
            ? 0
            : Number(value.sunkCost);

          total += purchasePrice + renoRemaining;
        }
      });
    }
    this.totalPurchasePrice = total;
    return total;
  }

  private calculateTotalLoanAmount() {
    this.form.fields.amount.value =
      this.form.fields.initialLoanAmount.value +
      this.form.fields.constructionHoldback.value;
  }

  private mapLenderParticipation(participation) {
    if (participation === 'Through life of loan') {
      return 'Participate through life of loan';
    } else if (participation === 'With subsequent buy-out') {
      return 'Participate with subsequent buy-out';
    } else {
      return 'Broker Loan';
    }
  }

  private mapSellAs(participation) {
    if (participation == 'Through life of loan') {
      return 'A-Note';
    } else {
      return 'Whole Loan';
    }
  }

  getPreferredAttorney() {
    return this.lenderAttorneysOptionList.find(
      attorney =>
        attorney.value === this.form.fields.preferredClosingAttorney.value
    );
  }

  setOtherInsuranceCompany() {
    const values = this.otherInsuranceFormStore.getFormValues();
    this.otherInsuranceDetails = {
      name: values.name,
      contactName: values.contactName,
      phone: values.cellPhone,
      address: values.address,
      email: values.emailAddress,
      state: values.state,
      streetName: values.streetName,
      streetNumber: values.streetNumber,
      zipCode: values.zipcode,
      city: values.city,
    };
    this.onChange(
      'preferredInsurance',
      this.form.fields.preferredInsurance.value
    );
  }

  setOtherTitleCompany() {
    const values = this.otherTitleFormStore.getFormValues();
    this.otherTitleDetails = {
      name: values.name,
      contactName: values.contactName,
      phone: values.cellPhone,
      address: values.address,
      email: values.emailAddress,
      state: values.state,
      streetName: values.streetName,
      streetNumber: values.streetNumber,
      zipCode: values.zipcode,
      city: values.city,
    };
    this.onChange('preferredTitle', this.form.fields.preferredTitle.value);
  }

  showWimba = () => {
    const { allowLoanPricing } = this.fixFlipStore.userStore;
    return (
      allowLoanPricing &&
      this.fixFlipStore.properties.some(p => this.wimbaStates?.includes(p.state)) &&
      this.fixFlipStore.loanTerms.preferredTitle === WIMBA
    );
  };

  setDefaultPreferredTitle = () => {
    if (!this.showWimba()) {
      this.onChange('preferredTitle', '');
    } else if (!this.fixFlipStore.loanTerms.preferredTitle) {
      this.onChange('preferredTitle', '');
    }
  };

  *uploadInsuranceDocument(fileUpload: FileUpload) {
    try {
      const file = fileUpload.file;
      const blob = file.slice(0, file.size, file.type);
      const newFile = new File([blob], fileUpload.name, { type: file.type });
      const apiResponse: ApiResponse = yield this.loanService.uploadInsuranceDocument(
        newFile
      );
      //const documentId = apiResponse.data.data.id; -- use this id
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'File uploaded successfully',
      });
    } catch (error) {
      this.globalStore.notificationStore.showSuccessNotification({
        //message: 'Error uploading file ',
        message: 'File uploaded successfully',
      });
    }
  }
  *fetchLoanId() {
    try {
      if (!this.draftLoanId) {
        const apiResponse: ApiResponse = yield this.loanService.fetchDraftLoanId();

        this.draftLoanId = apiResponse.data.data;
        yield this.getLoanFastTrackPaymentUrl();
      }
    } catch (error) {
      // do nothing
    }
  }

  *updateLoanFastrackInfo(loanId) {
    try {
      const { borrowersRows, properties } = this.fixFlipStore;
      const data = {
        loanId: loanId,
        randomLoanId: this.draftLoanId,
        ...this.getFormValues().fastTrackOptions,
        properties: properties.length,
        propertiesAddresses: properties.map(property => property.address).join(', '),
        dealName: getDealName(borrowersRows, properties)
      }
      yield this.loanService.updateLoanFastrackInfo(data);
    }

    catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while saving the modal state',
      });
    }
  }

  *requestFastTrackPaymentEmail(fastTrackInfo: any) {
    try {
      yield this.loanService.requestFastTrackPaymentEmail(fastTrackInfo);
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Email sent successfully',
      });
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while sending the email',
      });
    }
  }

  *loadFastTrackFees() {
    try {
      const response = yield this.loanService.getLoanFastTrackFees();

      this.fastTrackFees = response.data.data;
    } catch (err) {
    }
  }

  *getLoanFastTrackPaymentUrl() {
    try {
      const response = yield this.loanService.getLoanFastTrackPaymentUrl(this.draftLoanId);

      this.fastTrackPaymentUrl = response.data.data.url;
    } catch (err) {
    }
  }

  get canSubmitAppraisalWithTamarisk() {
    return this.fixFlipStore.canSubmitAppraisalWithTamarisk;
  }

  setLicenseUpload(items: FileUpload[]) {
    this.licenseUpload = items;
  }
  setFixFlipProDefaults() {
    this.form.fields.rate.value = 9.75;
    this.form.fields.points.value = 0;
  }

  resetBrokerPointsBasedOnHud(onTheHud: boolean) {
    if (onTheHud) {
      this.form.fields.referralFee.value = 0.0;
    } else {
      this.form.fields.brokerPoints.value = 0.0;
    }
  }
}



export default LoanInformationStore;
