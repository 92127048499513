import { Service } from '@roc/feature-app-core';

const url = {
  PAYOFF: '/api/v1/loan/requestPayoff',
  REQUEST_PAYOFF: '/api/v1/loan/requestPayoffV2',
  REQUEST_PAYOFF_V3: '/api/v1/loan/requestPayoffV3',
  GET_ACTIVE_PAYOFF: '/api/v1/loan/getActivePayoffByLoanId',
  SAVE_SERVICER_FEES: '/api/v1/loan/saveServicerFees',
  REQUEST_PAYOFF_ASSISTANCE: '/api/v1/loan/requestPayoffAssistance',
  ADD_SERVICER_ACCOUNTING_TEAM: '/api/v1/loan/addServicerAccountingTeam',
  GET_DEFAULT_ALLOCATION_DASHBOARD: '/api/v1/loan/getDefaultAllocationDashboard',
  GET_FORECLOSURE_INTEREST_ALLOCATION_DASHBOARD: '/api/v1/loan/getForeclosureInterestAllocation',
  EXTENSION: '/api/v1/loan/submitLoanExtension',
  EXTENSIONS_BYLOANID: '/api/v1/loan/getLoanExtensionsByLoanId',
  DOCUMENT_UPLOAD: '/api/v1/loan/uploadInsuranceDocument',
  CURRENT_EXTENSION: '/api/v1/loan/getCurrentExtensionForLoan',
  ELMSURE_TASKS: '/api/v1/loan/createExtensionTaskInElmsure',
  GET_PAYOFFS: '/api/v1/loan/getPayoffsByLoanId',
  EXTENSION_BY_ID: '/api/v1/loan/getLoanExtensionById',
  APPROVE_INSURANCE: '/api/v1/loan/approveInsuranceDoc',
  RETRACT_PAYOFF: '/api/v1/loan/retractPayoffExternal',
  UPDATE_LOAN_PAYOFF_DETAILS: '/api/v1/loan/updateLoanPayoffDetails',
  SUBMIT_PAYOFF_FORECLOSURE_LOAN: '/api/v1/loan/submitPayoffForclosureLoan'
};

export class LoanRequestsService extends Service {
  requestPayoff(body: any) {
    return this.post(url.PAYOFF, body);
  }

  requestPayoffV2(goodThroughDate: string, requestedDate: String, comments: string, rush: boolean, loanId: number) {
    const endpointUrl = `${url.REQUEST_PAYOFF}?goodThroughDate=${goodThroughDate}&requestedDate=${requestedDate}&comments=${comments}&rush=${rush}&loanId=${loanId}`;
    return this.put(endpointUrl, {});
  }

  requestPayoffV3(goodThroughDate: string, requestedDate: string, comments: string, rush: boolean, requesterEmail: String, loanId: number) {
    const endpointUrl = `${url.REQUEST_PAYOFF_V3}?goodThroughDate=${goodThroughDate}&requestedDate=${requestedDate}&comments=${comments}&rush=${rush}&requesterEmail=${requesterEmail}&loanId=${loanId}`;
    return this.put(endpointUrl, {});
  }

  getActivePayoffByLoanId(loanId: string) {
    return this.get(`${url.GET_ACTIVE_PAYOFF}/${loanId}`);
  }

  saveServicerFees(loanId: string, servicerFees: string) {
    return this.post(`${url.SAVE_SERVICER_FEES}`, { loanId, servicerFeesJson: servicerFees });
  }

  getPayOffsByLoanId(loanId: string) {
    return this.get(`${url.GET_PAYOFFS}/${loanId}`);
  }

  retractPayoff(loanId: string, comments: string) {
    return this.put(`${url.RETRACT_PAYOFF}?loanId=${loanId}&comments=${comments}`, null);
  }

  updateLoanPayoffDetails(data: any) {
    return this.post(
      url.UPDATE_LOAN_PAYOFF_DETAILS,
      data
    );
  }

  submitForeclosurePayoff(data: any) {
    return this.post(
      url.SUBMIT_PAYOFF_FORECLOSURE_LOAN,
      data
    );
  }

  requestExtension(body: any) {
    return this.post(url.EXTENSION, body);
  }

  getExtensionsByLoanId(loanId) {
    return this.get(`${url.EXTENSIONS_BYLOANID}?loanId=${loanId}&count=50&start=1`);
  }

  getCurrentExtensionByLoanId(loanId) {
    return this.get(`${url.CURRENT_EXTENSION}?loanId=${loanId}`);
  }

  uploadDocument(file, loanId: number) {
    return this.post(`${url.DOCUMENT_UPLOAD}?loanId=${loanId}`, file);
  }

  createElmsureTask(body: any) {
    return this.post(url.ELMSURE_TASKS, body);
  }

  getExtensionById(loanExtensionId: string) {
    return this.get(`${url.EXTENSION_BY_ID}?loanExtensionId=${loanExtensionId}`);
  }

  approveInsurance(body: any) {
    return this.post(url.APPROVE_INSURANCE, body);
  }

  requestPayoffAssistance(loanId: string) {
    return this.get(`${url.REQUEST_PAYOFF_ASSISTANCE}?loanId=${loanId}`);
  }

  addServicerAccountingTeam(loanId: string) {
    return this.get(`${url.ADD_SERVICER_ACCOUNTING_TEAM}?loanId=${loanId}`);
  }

  getDefaultAllocationDashboard(loanId: string) {
    return this.get(`${url.GET_DEFAULT_ALLOCATION_DASHBOARD}?loanId=${loanId}`);
  }

  getForeclosureInterestAllocationDashboard(loanId: string) {
    return this.get(`${url.GET_FORECLOSURE_INTEREST_ALLOCATION_DASHBOARD}?loanId=${loanId}`);
  }
}
