import {
  TextField, Tooltip
} from '@material-ui/core';
import {
  createStyles, makeStyles
} from '@material-ui/core/styles';
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useUserStore } from '../../hooks/useUserStore';

interface NavigationCompanyProps {
  redirectRoute: any;
}

const useStyles = makeStyles(theme =>
  createStyles({
    'autoComplete': {
      width: 200,
      [theme.breakpoints.up('xl')]: {
        width: 300,
      },
      marginLeft: 16,
      '& input': {
        fontWeight: 'bold',
      },
      '& input::placeholder': {
        opacity: 1
      },
      '& input:focus::placeholder': {
        opacity: 0.5
      }
    },
    popper: {
      width: '80vw !important',
      maxWidth: '500px !important',
    },
    listbox: {
      '& .MuiAutocomplete-listbox': {
        width: '80vw !important',
        maxWidth: '500px !important',
      },
    },
  })
);

export interface Company {
  label: string;
  value: number;
  company: string;
}

export const NavigationCompany = observer(({ redirectRoute }: NavigationCompanyProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isCompaniesMenuOpen = Boolean(anchorEl);
  const { companyDropdownStore, userStore } = useUserStore();
  const { companies, company } = companyDropdownStore;
  const { push } = useHistory();
  const classes = useStyles();

  useEffect(() => {
    companyDropdownStore.getUserCompanies();
  }, []);

  const handleMenuItemClick = (event, value: Company | null) => {
    setAnchorEl(null);
    if (value) {
      companyDropdownStore.changeCompanyNavigation(value.value);
      companyDropdownStore.company = value;
      push(redirectRoute);
    }
  };

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option: Company) => [option.label, option.value, option.company].join(' '),
  });

  return (
    <Tooltip
      title={
        <h1 style={{ fontSize: 15 }}>
          {company ? `${company.company} (${company.label})` : ''}
        </h1>
      }
      placement="left"
      arrow
    >
      <Autocomplete
        className={classes.autoComplete}
        classes={{
          listbox: classes.listbox,
          popper: classes.popper,
        }}
        id="menu-appbar"
        options={companies}
        size="small"
        disableClearable
        componentName="menu-appbar"
        getOptionLabel={option => `${option.company} (${option.label})`}
        renderInput={params => (
          <TextField
            {...params}
            placeholder={company ? `${company.company} (${company.label})` : ''}
            variant="outlined"
          />
        )}
        blurOnSelect
        onChange={handleMenuItemClick}
        value={company}
        getOptionSelected={(option, value) => option.value === value.value}
        filterOptions={filterOptions}
      />
    </Tooltip>
  );
});
