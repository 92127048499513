import { Page } from '../../../components';
import { Route } from 'react-router';
import { CreditBackgroundRequests } from '../creditBackgroundRequests';
import { SubmitCreditBackground } from '../submitCreditBackground';
import { PlaidLiquidityRequests } from '../plaidLiquidityRequests';
import { PlaidLiquidityVerification } from '../plaidLiquidityVerification';
import { useStore } from '../../../hooks/useStore';

export const getCreditBackgroundRoutesConfig = (
  basePath: string,
  baseUrl: string
) => {
  return {
    creditBackgroundRequests: {
      path: `${basePath}/credit-background-requests`,
      url: `${baseUrl}/credit-background-requests`,
      documentTitle: 'View Credit Background Requests',
    },
    submitCreditBackground: {
      path: `${basePath}/submit-credit-background-request`,
      url: `${baseUrl}/submit-credit-background-request`,
      documentTitle: 'Submit Credit Background Request',
    },
    plaidLiquidityVerification: {
      path: `${basePath}/plaid-liquidity-verification`,
      url: `${baseUrl}/plaid-liquidity-verification`,
      documentTitle: 'Plaid Liquidity Verification',
    },
    browsePlaidLiquidityRequests: {
      path: `${basePath}/plaid-liquidity-request`,
      url: `${baseUrl}/plaid-liquidity-request`,
      documentTitle: 'Browse Plaid Liquidity Requests',
    },
  };
};

export const getCreditBackgroundRoutes = (
  basePath: string,
  baseUrl: string
) => {
  const config = getCreditBackgroundRoutesConfig(basePath, baseUrl);
  const { liquidityVerificationStore } = useStore();
  return {
    creditBackgroundRequests: (
      <Route exact path={config.creditBackgroundRequests.path}>
        <Page routeDefinition={config.creditBackgroundRequests}>
          <CreditBackgroundRequests />
        </Page>
      </Route>
    ),
    submitCreditBackground: (
      <Route exact path={config.submitCreditBackground.path}>
        <Page routeDefinition={config.submitCreditBackground}>
          <SubmitCreditBackground />
        </Page>
      </Route>
    ),
    plaidLiquidityVerification: (
      <Route exact path={config.plaidLiquidityVerification.path}>
        <Page routeDefinition={config.plaidLiquidityVerification}>
          <PlaidLiquidityVerification store={liquidityVerificationStore} />
        </Page>
      </Route>
    ),
    browsePlaidLiquidityRequests: (
      <Route exact path={config.browsePlaidLiquidityRequests.path}>
        <Page routeDefinition={config.browsePlaidLiquidityRequests}>
          <PlaidLiquidityRequests store={liquidityVerificationStore} />
        </Page>
      </Route>
    ),
  };
};
