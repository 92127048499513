import { useEffect } from "react"
import { Grid, Typography, Tooltip } from "@material-ui/core";
import { TextField, Button, ConfirmationButton, NumberFormat } from "@roc/ui";
import { makeStyles } from "@material-ui/core/styles";
import { observer } from "mobx-react";
import { ReferralFeeStore } from "../stores/referralFeeStore";
import { StatusChip, StatusType } from "@roc/ui";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: 'white',
    width: '100%'
  },
  instruction: {
    fontWeight: 600,
    margin: 2
  },
  underline: {
    fontWeight: 600,
    textDecorationLine: 'underline',
  },
  tooltip: {
    whiteSpace: 'pre-line',
    fontSize: theme.typography.pxToRem(14),
    maxWidth: '200px',
  },
}));

type ReferralFeeLinkProps = {
  loanId: string;
  referralFeeStore: ReferralFeeStore;
  brokerId: string;
  onClose: () => void;
}

export const ReferralFeeLink = observer((props: ReferralFeeLinkProps) => {
  const classes = useStyles();

  const handleCopy = () => {
    navigator.clipboard.writeText(props.referralFeeStore.referralLink);
    props.referralFeeStore.globalStore.notificationStore.showSuccessNotification({
      message: 'Link was copied to clipboard!',
    });
  }

  const getBankInfoStatus = () => {
    return !props.referralFeeStore.bankInfo ? 'PENDING' : props.referralFeeStore.bankInfo?.bankInfoStatus == 'Approved' ? 'APPROVED' : 'PENDING_APPROVAL';
  }

  useEffect(() => {
    props.referralFeeStore.getReferralFeeLink(props.loanId);
    props.referralFeeStore.getBankInformation(props.brokerId);
  }, []);

  return (
    <Grid className={classes.root}>
      <Grid container justifyContent="center">
        <Grid
          alignItems="center"
          container
          item
          justifyContent="center"
          xs={12}
        >
          <Grid item container xs={12} spacing={2}>
            <Grid item xs={12}>
              <Typography className={classes.instruction}>
                Link for the referral partner to provide bank information
              </Typography>
              <Grid item lg={12} >
                <Grid item container justifyContent="flex-start" spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      testId="referralLink"
                      variant="outlined"
                      value={props.referralFeeStore.referralLink}
                      fullWidth
                      disabled
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item container justifyContent="flex-start" spacing={2} style={{ padding: '10px 0', }} direction="row-reverse">
                <Grid item xs={4} sm={3}>
                  <Tooltip title="Copy the link and then share it with the referrer manually" arrow placement="left" classes={{ tooltip: classes.tooltip, }}>
                    <div>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => handleCopy()}
                        testId="copyReferralLink"
                        fullWidth
                      >
                        COPY LINK
                      </Button>
                    </div>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <Grid style={{ padding: '8px' }}>
          <Grid
            item
            style={{ padding: '0', marginBottom: 16 }}
            container
          >
            <Grid item xs={6} style={{ display: 'flex', }}>
              <Typography variant="h5" style={{ marginRight: 7 }}>BANK INFORMATION</Typography>
              <StatusChip
                statusType={StatusType.BANK_INFORMATION_STATUS}
                label={getBankInfoStatus()}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                testId="accountName"
                variant="outlined"
                label='Account Name'
                standaloneLabel
                value={props.referralFeeStore.bankInfo?.accountName}
                fullWidth
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                testId="bankName"
                variant="outlined"
                label='Bank Name'
                standaloneLabel
                value={props.referralFeeStore.bankInfo?.bankName}
                fullWidth
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                value={props.referralFeeStore.bankInfo?.routingNumber}
                label='Routing Number'
                standaloneLabel
                testId="routingNumber"
                disabled
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                value={props.referralFeeStore.bankInfo?.accountNumber}
                label='Account Number'
                standaloneLabel
                testId="accountNumber"
                disabled
                fullWidth
                variant="outlined"
              />
            </Grid>
          </Grid>
        </Grid>
        {(props.referralFeeStore.bankInfo && props.referralFeeStore.bankInfo?.bankInfoStatus != 'Approved') && <Grid container item style={{ padding: '8px', }} direction="row-reverse">
          <Grid item container xs={4} sm={3}>
            <ConfirmationButton
              variant="contained"
              onClick={() => props.referralFeeStore.approveBankInformation(props.brokerId, props.onClose)}
              color="primary"
              testId="approveBankInfo"
              confirmDialogProps={{
                title: 'Confirmation',
                body: 'This will approve the refrerrer bank information',
                cancelButtonText: 'Go Back',
                confirmButtonText: 'Ok'
              }}
              fullWidth
            >
              Approve
            </ConfirmationButton>
          </Grid>
        </Grid>}
      </Grid>
    </Grid>);
});