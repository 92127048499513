import { Service } from "@roc/feature-app-core";

const url = {
  USERS_ORIGINATORS: '/api/v1/loan/lenderUsers/getLenderOriginators',
  GET_LOAN_PARTICIPANTS: '/api/v1/loan/lenderUsers/getLoanParticipants',
};

export class LoanParticipantsService extends Service {
  url;

  constructor() {
    super();
    this.url = url
  }

  getLoanOriginators() {
    return this.get(this.url.USERS_ORIGINATORS);
  }

  getLoanParticipants(){
    return this.get(this.url.GET_LOAN_PARTICIPANTS);
  }
}