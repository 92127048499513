import { ExitToApp } from '@material-ui/icons';
import FlipIcon from '@material-ui/icons/Flip';
import { useRoutes } from './useRoutes';
import { useHistory } from 'react-router';
import { useProposalsStore } from '@roc/feature-proposals';
import { useStore } from '@roc/client-portal-shared/hooks';
import { useRoutes as useSharedRoutes } from '@roc/client-portal-shared/hooks';
import { useClosingTrackerRoutes } from '@roc/feature-closing-tracker';
import { insertIf, Roles } from '@roc/feature-utils';
import { useUserStore } from '@roc/feature-app-core';
import { useRoutes as useCrmRoutes } from 'apps/crm-portal/src/app/hooks/useRoutes';

export const useNavigation = () => {

  const {
    marketingSetupRoutesConfig,
    emailRoutesConfig,
    emailSettingsRoutesConfig,
    corporateEmailsRoutesConfig,
  } = useSharedRoutes();

  const {
    loanRoutesConfig,
    lenderRoutesConfig,
    lenderBankInformationConfig,
    createListRoutesConfig,
    lenderWithNoRmRoutesConfig,
    sigmaDashboardsRoutesConfig,
    leadsDashboardRoutesConfig,
    manageTemplatesConfig,
    loanVolumeReportRoutesConfig,
    possibleMatchesConfig,
    leadDetailsRoutesConfig,
    compToolRoutesConfig,
    crmTaskDashboardRoutesConfig,
    delinquentLoansDashboardRoutesConfig
  } = useRoutes();

  const {
    leadActivityMetricsRoutesConfig,
    loanVolumeRoutesConfig
  } = useCrmRoutes();

  const {
    closingTrackerRoutesConfig
  } = useClosingTrackerRoutes();

  const { companyProfileStore, salesforceLenderStore } = useStore();
  const { push } = useHistory();
  const { proposalsStore } = useProposalsStore();
  const { globalStore } = useStore();
  const { userStore } = useUserStore();
  const isBackOfficeOriginator = userStore.userInformation?.roles?.includes(Roles.BACK_OFFICE_ORIGINATOR);
  const isBackOfficeLead = userStore.userInformation?.roles?.includes(Roles.BACK_OFFICE_LEAD);


  const navigationMenuData = [
    ...insertIf(globalStore?.userFeatures?.showLeads, [
      {
        title: 'HOME',
        subMenu: [
          {
            title: 'Lead Activity Metrics',
            route: leadActivityMetricsRoutesConfig.leadActivityMetrics.url,
          },
          {
            title: 'Loan Volume',
            route: loanVolumeRoutesConfig.loanVolume.url,
          }
        ]
      }
    ]),
    {
      title: 'LOANS',
      route: loanRoutesConfig.loansHome.url,
      subMenu: [
        {
          title: 'View Loans',
          route: loanRoutesConfig.loansHome.url,
        },
        {
          title: 'Deal Room',
          onClick: () => {
            proposalsStore.setShowDealRoomPopup(true);
          },
        },
      ],
    },
    {
      title: 'Lenders',
      route: lenderRoutesConfig.lendersHome.url,
      subMenu: [
        {
          title: 'View Lenders',
          route: lenderRoutesConfig.lendersHome.url,
          onClick: () => {
            companyProfileStore.setDisplayCompanyProfile(false);
            push(lenderRoutesConfig.lendersHome.url);
          },
        },
        {
          title: 'Lenders Pending RM Assignment',
          route: lenderWithNoRmRoutesConfig.lendersWithNoRmHome.url,
          onClick: () => {
            companyProfileStore.setDisplayCompanyProfile(false);
            push(lenderWithNoRmRoutesConfig.lendersWithNoRmHome.url);
          },
        },
        // {
        //   title: 'Create Lead List View',
        //   route: createListRoutesConfig.createList.url,
        // },
        // {
        //   title: 'View Lead List Views',
        //   route: leadListViewsRoutesConfig.listViewsHome.url,
        // },
      ],
    },
    ...insertIf(globalStore?.userFeatures?.showLeads, [
      {
        title: 'CRM',
        route: leadsDashboardRoutesConfig.leadsDashboard.url,
        subMenu: [
          {
            title: 'CRM Dashboard',
            route: leadsDashboardRoutesConfig.leadsDashboard.url,
          },
          // {
          //   title: 'TPO Leads Dashboard',
          //   route: tpoLeadsDashboardRoutesConfig.leadsDashboard.url,
          // },
          // {
          //   title: 'Borrower Leads Dashboard',
          //   route: borrowerLeadsDashboardRoutesConfig.leadsDashboard.url,
          // },
          // {
          //   title: 'Import Leads',
          //   route: importLeadsConfig.importLeadsHome.url,
          // },
          // {
          //   title: 'Create Leads',
          //   route: newLeadRoutesConfig.newLeadsHome.url,
          // },

          {
            title: 'Manage Text/Email Templates',
            route: manageTemplatesConfig.manageTemplatesHome.url,
          },
          {
            title: 'My CRM Tasks',
            route: crmTaskDashboardRoutesConfig.crmTaskDashboard.url,
          },
          {
            title: 'Upcoming Maturity and Delinquency',
            route: delinquentLoansDashboardRoutesConfig.delinquenctLoansDashboard.url,
          },
        ],
      }]),
    {
      title: 'LENDER BANK INFORMATION',
      route: lenderBankInformationConfig.lenderBankInformationHome.url,
    },
    {
      title: 'CLOSING TRACKER',
      route: closingTrackerRoutesConfig.closingTrackerHome.url,
    },
    {
      title: 'RESOURCES',
      subMenu: [
        ...insertIf(globalStore.userFeatures?.showSigmaDashboards && globalStore.userFeatures.availableSigmaDashboards.length > 0, [
          {
            title: "Sigma Dashboards",
            route: sigmaDashboardsRoutesConfig.sigmaDashboards.url,
          }
        ]),
        ...insertIf(isBackOfficeOriginator || isBackOfficeLead, [
          {
            title: 'Loan Volume Report',
            route: loanVolumeReportRoutesConfig.loanVolumeReport.url,
          }
        ]),
        {
          title: 'Comp Tool',
          route: compToolRoutesConfig.compTool.url,
        }
      ],


    },
    ...insertIf(globalStore.userFeatures.backOfficeLead, [
      {
        title: 'MARKETING',
        subMenu: [
          {
            title: 'Create List',
            route: createListRoutesConfig.createList.url
          },
          {
            title: 'Email Template Mapping',
            route: marketingSetupRoutesConfig.marketingSetup.url
          },
          {
            title: 'General Email Settings',
            route: emailSettingsRoutesConfig.emailSettings.url
          },
          {
            title: 'Send Emails By Originator',
            route: emailRoutesConfig.emails.url,
          },
          {
            title: 'Send Corporate Emails',
            route: corporateEmailsRoutesConfig.emails.url,
          },
        ]
      }]),
  ];
  const profileMenuData = [
    {
      title: 'Switch to Lender View',
      route: loanRoutesConfig.loansHomeLender.url,
      onClick: () => {
        push(loanRoutesConfig.loansHomeLender.url);
        window.location.href = window.location.href;
      },
      icon: <FlipIcon fontSize="small" />,
    },
    {
      title: 'Logout',
      route: '',
      icon: <ExitToApp fontSize="small" />,
    },
  ];

  const adjustedNavigationMenuData = [...navigationMenuData];

  function filterLendersSubMenu(adjustedNavigationMenuData) {
    const allowRenderLendersWithNoRm = salesforceLenderStore.globalStore.userFeatures.allowRenderLendersWithNoRm;
    const lendersIndex = adjustedNavigationMenuData.findIndex(item => item.title === 'Lenders');

    if (lendersIndex !== -1 && !allowRenderLendersWithNoRm) {
      const subMenu = adjustedNavigationMenuData[lendersIndex].subMenu;
      const newSubMenu = [];

      for (const item of subMenu) {
        if (item.title !== 'Lenders Pending RM Assignment') {
          newSubMenu.push(item);
        }
      }

      adjustedNavigationMenuData[lendersIndex].subMenu = newSubMenu;
    }
  }

  filterLendersSubMenu(adjustedNavigationMenuData);

  const redirectRoute = loanRoutesConfig.loansHome.url;
  const loanRoutes = loanRoutesConfig.loans;
  const possibleMatchesRoutes = possibleMatchesConfig.possibleMatches;
  return {
    navigationMenuData: adjustedNavigationMenuData,
    profileMenuData,
    redirectRoute,
    loanRoutes,
    possibleMatchesRoutes,
  };
};
