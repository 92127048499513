import { GlobalStore, GridStore, UserStore } from '@roc/feature-app-core';
import { makeObservable, flow, observable, action } from 'mobx';
import { ProposalsService } from '../services/proposalsService';
import { ProposalsDetailsStore } from './proposalsDetailsStore';
import { ProposalTopicsStore } from './proposalTopicsStore';

export class ProposalsStore {
  globalStore: GlobalStore;
  proposalsService: ProposalsService;
  detailsStore: ProposalsDetailsStore;
  proposalTopicsStore: ProposalTopicsStore;
  userStore: UserStore;
  proposalsGridStore: GridStore;
  isAbleToAppeal: boolean;
  showDealRoomPopup: boolean;

  constructor(globalStore, userStore) {
    this.globalStore = globalStore;
    this.userStore = userStore;
    this.proposalsService = new ProposalsService();
    this.proposalsGridStore = new GridStore(() => this.globalStore.isBackOfficeView ? this.fetchBackofficeProposals() : this.fetchProposals());
    this.detailsStore = new ProposalsDetailsStore(globalStore, this);
    this.proposalTopicsStore = new ProposalTopicsStore(globalStore,this);
    makeObservable(this, {
      deleteProposal: flow,
      getIsAbleToAppeal: flow,
      isAbleToAppeal: observable,
      showDealRoomPopup: observable,
      setShowDealRoomPopup: action,
      updateProposalStatus: flow,
    });
  }
  setShowDealRoomPopup(flag) {
    this.showDealRoomPopup = flag;
  }
  fetchProposals = async () => {
    try {
      const response = await this.proposalsService.getMyProposalsGrid(
        this.proposalsGridStore.gridData.meta.pageNumber,
        this.proposalsGridStore.gridData.meta.pageSize,
        this.proposalsGridStore.gridData.meta.sortDir,
        this.proposalsGridStore.gridData.meta.sortBy,
        this.proposalsGridStore.gridData.meta.filters
      );
      return response;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: `Error while retrieving the list of existing proposals`,
      });
    }
  };

  fetchBackofficeProposals = async () => {
    try {
      const response = await this.proposalsService.getBackOfficeProposalsGrid(
        this.proposalsGridStore.gridData.meta.pageNumber,
        this.proposalsGridStore.gridData.meta.pageSize,
        this.proposalsGridStore.gridData.meta.sortDir,
        this.proposalsGridStore.gridData.meta.sortBy,
        this.proposalsGridStore.gridData.meta.filters
      );
      return response;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: `Error while retrieving the list of existing proposals`,
      });
    }
  };

  *deleteProposal(proposalId: number) {
    try {
      yield this.proposalsService.removeProposal(proposalId);
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Proposal request removed successfully',
      });
      this.proposalsGridStore.resetAndFetchGridData();
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while removing proposal request',
      });
    }
  }

  *getIsAbleToAppeal() {
    const response = yield this.proposalsService.getIsAbleToAppeal();
    if (response.data.data === false) {
      this.globalStore.notificationStore.showErrorNotification({
        message: `Appeal can only be used twice per month. Maximum of appeals exceeded! `,
      });
    }
    return response.data.data;
  }

  *updateProposalStatus(proposalId: number, status: String) {
    try {
      const updateProposalStatusRequest = {
        status: status,
        proposalId: proposalId,
      };
      yield this.proposalsService.updateProposalStatus(updateProposalStatusRequest);
      this.globalStore.notificationStore.showSuccessNotification({
          message: 'Proposal status has been updated successfully',
      });
      this.proposalsGridStore.resetAndFetchGridData();
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while updating proposal status',
      });
    }
  }

}