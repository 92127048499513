import { useBaseStore } from '@roc/feature-app-core';
import { getBackgroundReviewRoutes } from '../routes/backgroundReviewRoutes';
import { getResolutionRoutes } from '../routes/resolutionRoutes';

export const useBackgroundReviewRoutes = () => {
  const { globalStore } = useBaseStore();
  const { routeBasePath, routeBaseUrl } = globalStore;
  const { borrowerId } = globalStore.queryParams;
  const { loanId, loanDocumentId } = globalStore.queryParams;
  return {
    backgroundReviewRoutes: getBackgroundReviewRoutes(
      routeBasePath,
      routeBaseUrl,
      borrowerId,
      loanId
    ),
    resolutionRoutes: getResolutionRoutes(
      routeBasePath,
      routeBaseUrl,
      loanDocumentId
    ),
  };
};