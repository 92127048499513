import { Service } from '@roc/feature-app-core';

const url = {
  SUBMIT_APPRAISAL: '/api/v1/loan/appraisal/submitAppraisal',
  SUBMIT_LOAN_APPRAISAL: '/api/v1/loan/appraisal/submitLoanAppraisal',
};

export class SubmitAppraisalService extends Service {
  submitAppraisal(form_information: any, file: File) {
    const formData = new FormData();
    formData.append('formInformation', JSON.stringify(form_information));
    formData.append('file', file);
    return this.post(url.SUBMIT_APPRAISAL, formData);
  }

  submitLoanAppraisal(loanId: number, gfdPayment: boolean) {
    const endpointUrl = `${url.SUBMIT_LOAN_APPRAISAL}?loanId=${loanId}&gfdPayment=${gfdPayment}`;
    return this.post(endpointUrl, {});
  }
}
