import { Grid } from '@material-ui/core';
import { useStore } from '@roc/client-portal-shared/hooks';
import { DialogState, EditableDialog, TextField } from '@roc/ui';
import { EmailAddress } from '../components/profileFormComponents';
import { createCheckboxField } from '../../../components/formComponents/common/componentBuilder';
import { observer } from 'mobx-react-lite';

export const AddMailDialog = observer(() => {
  const { companyProfileStore } = useStore();
  const mailingListsStore = companyProfileStore.mailingListsStore;
  const dialogStore = mailingListsStore.editMailingListsDialogStore;
  const isEditing = dialogStore.mode === DialogState.EDIT;

  return (
    <EditableDialog
      title={dialogStore.mode === DialogState.ADD ? 'new mail' : 'mail'}
      open={dialogStore.open}
      handleClose={dialogStore.closeDialog}
      dialogState={dialogStore.mode}
      handleAdd={() => mailingListsStore.submitAddMailDialog()}
      handleEdit={() => mailingListsStore.submitEditMailDialog()}
      dialogContent={
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <EmailAddress
              testId="edit-mailing-list-email-address"
              name="email"
              label="Email Address"
              readonly={isEditing}
              store={dialogStore}
            />
          </Grid>
          <Grid item xs={12}>
            {createCheckboxField({
              label: 'Loan Status Mailing List',
              fieldName: 'loanStatus',
              store: dialogStore,
            })}
          </Grid>
          <Grid item xs={12}>
            {createCheckboxField({
              label: 'Daily Report Mailing List',
              fieldName: 'dailyReport',
              store: dialogStore,
            })}
          </Grid>
          <Grid item xs={12}>
            {createCheckboxField({
              label: 'Extensions Mailing List',
              fieldName: 'extensions',
              store: dialogStore,
            })}
          </Grid>
        </Grid>
      }
    />
  );
});
