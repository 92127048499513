import { observer } from 'mobx-react';
import React, { ReactNode, useEffect, useState } from 'react';
import { AgGridColumnProps, DataGrid } from '@roc/ui';
import { useStore } from '@roc/client-portal-shared/hooks';
import { Layout } from '@roc/feature-app-core';
import { Box, Divider, Grid, List, Typography } from '@material-ui/core';
import { ApiResponse, GridStore } from '@roc/feature-app-core';
import { formatCurrency } from '@roc/ui/utils';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { useLoanSummaryStore } from 'libs/feature-loan-summary/src/loanSummary/hooks/useLoanSummaryStore';



const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grid: {
      marginTop: '1.5em',
    },
  }))

const noOpComparator = () => 0;

const borrowerPreviousPartnersColumns: AgGridColumnProps[] = [
  {
    field: 'borrowerId',
    headerName: 'Borrower Id',
    minWidth: 180,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'fullName',
    headerName: 'Full Name',
    minWidth: 260,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'credit',
    headerName: 'Credit',
    minWidth: 260,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'experience',
    headerName: 'Experience',
    minWidth: 260,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'age',
    headerName: 'Age',
    minWidth: 260,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'netWorth',
    headerName: 'Networth',
    minWidth: 260,
    cellRenderer: 'amountRenderer',
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'broker',
    headerName: 'Broker',
    minWidth: 260,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'bankruptcy',
    headerName: 'Bankruptcy',
    minWidth: 260,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'foreclosure',
    headerName: 'Foreclosure',
    minWidth: 260,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
];

const borrowerHistoryBridgeLoansColumns: AgGridColumnProps[] = [
  {
    field: 'loanId',
    headerName: 'Loan Id',
    minWidth: 180,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'lenderName',
    headerName: 'Lender Name',
    minWidth: 260,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'loanSubType',
    headerName: 'Loan Sub Type',
    minWidth: 260,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'state',
    headerName: 'State',
    minWidth: 260,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'closeDate',
    headerName: 'Close Date',
    minWidth: 260,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'monthsSinceCloseDate',
    headerName: 'Months Since Close Date',
    minWidth: 260,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'payString',
    headerName: 'Pay String',
    minWidth: 260,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'term',
    headerName: 'Term',
    minWidth: 260,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'propertyAddress',
    headerName: 'Property Address',
    minWidth: 260,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'payoffDate',
    headerName: 'Payoff Date',
    minWidth: 260,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'irMonths',
    headerName: 'IR Months',
    minWidth: 260,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'loanAmount',
    headerName: 'Loan Amount',
    minWidth: 260,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    cellRenderer: 'amountRenderer',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'renoBudget',
    headerName: 'Reno Budget',
    minWidth: 260,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    cellRenderer: 'amountRenderer',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'complete',
    headerName: 'Complete',
    minWidth: 260,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
];

const borrowerHistoryRentalLoansColumns: AgGridColumnProps[] = [
  {
    field: 'loanId',
    headerName: 'Loan Id',
    minWidth: 180,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'lenderName',
    headerName: 'Lender Name',
    minWidth: 260,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'loanSubType',
    headerName: 'Loan Sub Type',
    minWidth: 260,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'state',
    headerName: 'State',
    minWidth: 260,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'closeDate',
    headerName: 'Close Date',
    minWidth: 260,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'monthsSinceCloseDate',
    headerName: 'Months Since Close Date',
    minWidth: 260,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'payString',
    headerName: 'Pay String',
    minWidth: 260,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'term',
    headerName: 'Term',
    minWidth: 260,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'propertyAddress',
    headerName: 'Property Address',
    minWidth: 260,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'payoffDate',
    headerName: 'Payoff Date',
    minWidth: 260,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'loanAmount',
    headerName: 'Loan Amount',
    minWidth: 260,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    cellRenderer: 'amountRenderer',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'complete',
    headerName: 'Complete',
    minWidth: 260,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'propertyCount',
    headerName: 'Property Count',
    minWidth: 260,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'dscr',
    headerName: 'DSCR',
    minWidth: 260,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
];

const loanExposureColumns: AgGridColumnProps[] = [
  {
    field: 'status',
    headerName: 'Status',
    minWidth: 150,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'count',
    headerName: 'Count',
    minWidth: 100,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'amount',
    headerName: 'Amount',
    minWidth: 150,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    cellRenderer: 'amountRenderer',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
];

const loanExposureFacoColumns: AgGridColumnProps[] = [
  {
    field: 'status',
    headerName: 'Status',
    minWidth: 150,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'rocCount',
    headerName: 'Roc Count',
    minWidth: 100,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'rocAmount',
    headerName: 'Roc Amount',
    minWidth: 150,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    cellRenderer: 'amountRenderer',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'facoCount',
    headerName: 'FACO Count',
    minWidth: 150,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'facoAmount',
    headerName: 'FACO Amount',
    minWidth: 150,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    cellRenderer: 'amountRenderer',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
];

const frameworkComponents = {
  amountRenderer: ({ value }) => {
    return value ? formatCurrency(value) : '';
  }
};

export const BorrowerHistoryGrid = observer(({ toolbar }: { toolbar: ReactNode }) => {
  const classes = useStyles();
  const { loanSummaryStore } = useLoanSummaryStore();
  const { loanSummaryModalStore } = loanSummaryStore;

  const divider = <Divider variant="fullWidth" />;

  useEffect(() => {
    const resetAndFetchGridDataPromises = [
      loanSummaryModalStore.borrowerHistoryBridgeLoans.resetAndFetchGridData(),
      loanSummaryModalStore.loanExposure.resetAndFetchGridData(),
      loanSummaryModalStore.borrowerHistoryRentalLoans.resetAndFetchGridData(),
      loanSummaryModalStore.borrowerPreviousPartners.resetAndFetchGridData(),
    ];
    Promise.all(resetAndFetchGridDataPromises)
  }, [loanSummaryModalStore.currentBorrower]);


  const onLoanExposureSortChanged = params => {
    const sortModel = params.api.getSortModel();
    loanSummaryModalStore.loanExposure.setSortModel(sortModel);
  };

  const onLoanExposureSortFilterChanged = params => {
    const filterModel = params.api.getFilterModel();
    loanSummaryModalStore.loanExposure.setFilterModel(filterModel);
  };

  const onBorrowerPreviousPartnersSortChanged = params => {
    const sortModel = params.api.getSortModel();
    loanSummaryModalStore.borrowerPreviousPartners.setSortModel(sortModel);
  };

  const onBorrowerPreviousPartnersFilterChanged = params => {
    const filterModel = params.api.getFilterModel();
    loanSummaryModalStore.borrowerPreviousPartners.setFilterModel(filterModel);
  };

  const onBorrowerHistoryBridgeLoansSortChanged = params => {
    const sortModel = params.api.getSortModel();
    loanSummaryModalStore.borrowerHistoryBridgeLoans.setSortModel(sortModel);
  };

  const onBorrowerHistoryBridgeLoansFilterChanged = params => {
    const filterModel = params.api.getFilterModel();
    loanSummaryModalStore.borrowerHistoryBridgeLoans.setFilterModel(filterModel);
  };

  const onBorrowerHistoryRentalLoansSortChanged = params => {
    const sortModel = params.api.getSortModel();
    loanSummaryModalStore.borrowerHistoryRentalLoans.setSortModel(sortModel);
  };

  const onBorrowerHistoryRentalLoansFilterChanged = params => {
    const filterModel = params.api.getFilterModel();
    loanSummaryModalStore.borrowerHistoryRentalLoans.setFilterModel(filterModel);
  };



  return (
    <Layout height={'100%'}>
      <Grid container spacing={2}>
        <Grid item xs={12} className={classes.grid}>
          <Typography variant="h6" gutterBottom>
            Loan Exposure
          </Typography>
          <DataGrid
            frameworkComponents={frameworkComponents}
            suppressRowClickSelection={false}
            columns={loanSummaryModalStore.isFaco ? loanExposureFacoColumns : loanExposureColumns}
            rows={loanSummaryModalStore.loanExposure.gridData.data.rows}
            onSortChanged={onLoanExposureSortChanged}
            onFilterChanged={onLoanExposureSortFilterChanged}
            isLoading={loanSummaryModalStore.loanExposure.isLoading}
            showFilters={loanSummaryModalStore.loanExposure.showFilters}
            sortModel={loanSummaryModalStore.loanExposure.sortModel}
            filterModel={loanSummaryModalStore.loanExposure.filterModel}
            paginationData={loanSummaryModalStore.loanExposure.gridData.meta}
            setPageNumber={loanSummaryModalStore.loanExposure.setPageNumber}
            rowHeight={30}
            domLayout="autoHeight"
          />
        </Grid>
        {divider}
        <br />
        <Grid item xs={12} className={classes.grid}>
          <Typography variant="h6" gutterBottom>
            Borrower Previous Partners
          </Typography>
          <DataGrid
            frameworkComponents={frameworkComponents}
            suppressRowClickSelection={false}
            columns={borrowerPreviousPartnersColumns}
            rows={loanSummaryModalStore.borrowerPreviousPartners.gridData.data.rows}
            onSortChanged={onBorrowerPreviousPartnersSortChanged}
            onFilterChanged={onBorrowerPreviousPartnersFilterChanged}
            isLoading={loanSummaryModalStore.borrowerPreviousPartners.isLoading}
            showFilters={loanSummaryModalStore.borrowerPreviousPartners.showFilters}
            sortModel={loanSummaryModalStore.borrowerPreviousPartners.sortModel}
            filterModel={loanSummaryModalStore.borrowerPreviousPartners.filterModel}
            paginationData={loanSummaryModalStore.borrowerPreviousPartners.gridData.meta}
            setPageNumber={loanSummaryModalStore.borrowerPreviousPartners.setPageNumber}
            rowHeight={30}
            domLayout="autoHeight"
          />
        </Grid>
        {divider}
        <br />
        <Grid item xs={12} className={classes.grid}>
          <Typography variant="h6" gutterBottom>
            Borrower History Bridge Loans
          </Typography>
          <DataGrid
            frameworkComponents={frameworkComponents}
            suppressRowClickSelection={false}
            columns={borrowerHistoryBridgeLoansColumns}
            rows={loanSummaryModalStore.borrowerHistoryBridgeLoans.gridData.data.rows}
            onSortChanged={onBorrowerHistoryBridgeLoansSortChanged}
            onFilterChanged={onBorrowerHistoryBridgeLoansFilterChanged}
            isLoading={loanSummaryModalStore.borrowerHistoryBridgeLoans.isLoading}
            showFilters={loanSummaryModalStore.borrowerHistoryBridgeLoans.showFilters}
            sortModel={loanSummaryModalStore.borrowerHistoryBridgeLoans.sortModel}
            filterModel={loanSummaryModalStore.borrowerHistoryBridgeLoans.filterModel}
            paginationData={loanSummaryModalStore.borrowerHistoryBridgeLoans.gridData.meta}
            setPageNumber={loanSummaryModalStore.borrowerHistoryBridgeLoans.setPageNumber}
            rowHeight={30}
            domLayout="autoHeight"
          />
        </Grid>
        {divider}
        <br />
        <Grid item xs={12} className={classes.grid}>
          <Typography variant="h6" gutterBottom>
            Borrower History Rental Loans
          </Typography>
          <DataGrid
            frameworkComponents={frameworkComponents}
            suppressRowClickSelection={false}
            columns={borrowerHistoryRentalLoansColumns}
            rows={loanSummaryModalStore.borrowerHistoryRentalLoans.gridData.data.rows}
            onSortChanged={onBorrowerHistoryRentalLoansSortChanged}
            onFilterChanged={onBorrowerHistoryRentalLoansFilterChanged}
            isLoading={loanSummaryModalStore.borrowerHistoryRentalLoans.isLoading}
            showFilters={loanSummaryModalStore.borrowerHistoryRentalLoans.showFilters}
            sortModel={loanSummaryModalStore.borrowerHistoryRentalLoans.sortModel}
            filterModel={loanSummaryModalStore.borrowerHistoryRentalLoans.filterModel}
            paginationData={loanSummaryModalStore.borrowerHistoryRentalLoans.gridData.meta}
            setPageNumber={loanSummaryModalStore.borrowerHistoryRentalLoans.setPageNumber}
            rowHeight={30}
            domLayout="autoHeight"
          />
        </Grid>
      </Grid>
    </Layout>
  );
});
