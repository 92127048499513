import { useLoanRoutes } from "@roc/feature-loans-routes-config";
import { StandardDialog } from "@roc/ui";
import { observer } from "mobx-react";
import React from "react";
import { useHistory } from "react-router-dom";
import { useServicerLoansStore } from "../../hooks/useServicerLoansStore";
import { LoansForPayoffRequestForm } from "./loansForPayoffRequestForm";

export const LoansForPayoffRequestModal = observer(() => {
  const { push } = useHistory();
  const { payoffNewRequestStore } = useServicerLoansStore();
  const { loanRoutesConfig } = useLoanRoutes();

  return (
    <StandardDialog
      open={payoffNewRequestStore.showLoansForPayoffRequestModal}
      title="Payoff Request Form"
      maxWidth="md"
      handleClose={() => payoffNewRequestStore.setShowLoansForPayoffRequestModal(false)}
      dialogContent={
        <LoansForPayoffRequestForm
          onSubmit={(loan) => {
            push(loanRoutesConfig.loans(loan).children.payoffRequest.url);
            payoffNewRequestStore.setShowLoansForPayoffRequestModal(false);
          }}
          onClose={() => payoffNewRequestStore.setShowLoansForPayoffRequestModal(false)}
        />
      }
    />
  );
})