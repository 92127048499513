import { Button, Grid } from '@material-ui/core';
import { observer } from 'mobx-react';
import { GridRowSelectedEvent } from '../dataGrid';
import { StandardDialog } from '@roc/ui';
import { SelectBorrowerEntityGrid } from '@roc/feature-borrowers';
import EntityInternalStore from 'apps/roc-internal-portal/src/app/modules/borrowerInfoCard/stores/entityInternalStore';
import { ExternalEntityStore } from 'libs/feature-borrowers/src/borrowers/stores/externalEntityStore';

interface Props {
  borrowerId: string;
  store: EntityInternalStore | ExternalEntityStore;
  open: boolean;
  handleClose?: () => void;
  setModalOpen: (val: boolean) => void
  reloadData: () => void
}

export const LinkEntityDialog = observer((props: Props) => {
  const { borrowerId, store, open, setModalOpen, reloadData, handleClose } = props;

  const onRowSelected = (e: GridRowSelectedEvent) => {
    const { node } = e;
    if (node.data) {
      store.setSelectedEntity(node.data);
    }
  };

  const handleSuccess = () => {
    reloadData();
    setModalOpen(false);
    if (handleClose) {
      handleClose();
    }
  }

  return (
    <Grid container>
      <StandardDialog
        open={open}
        maxWidth="md"
        handleClose={() => {
          handleClose();
          setModalOpen(false)
        }}
        title='Select Entity'
        dialogContent={
          <SelectBorrowerEntityGrid
            multiple={false}
            store={store.existentEntitiesGridStore}
            onRowSelected={onRowSelected}
          />
        }
        dialogActions={
          <>
            <Button
              style={{ marginRight: '8px' }}
              onClick={() => setModalOpen(false)}
              color="primary"
              variant="outlined"
            >
              CANCEL
            </Button>
            <Button
              onClick={() => store.handleLinkEntity(borrowerId, handleSuccess)}
              color="primary"
              variant="outlined"
            >
              CONFIRM
            </Button>
          </>
        }
      />
    </Grid >
  );
});

export default LinkEntityDialog;
