import { Avatar, Box, Grid, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import { PersonOutlineRounded, Business } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { CurrencyField, SelectField, Slider, trueFalseOptions, yesNoOptions } from '@roc/ui';
import { ffExperienceOptions, renoDescriptionOptions } from '@roc/feature-utils';
import { TotalAmounts } from './totalAmounts';
import { FixFlipStore } from '../stores';
import { useEffect } from 'react';

const useStyles = makeStyles(theme =>
  createStyles({
    parentContainer: {
      marginTop: '12px',
    },
    rowContainer: {
      display: 'flex',
      marginTop: '8px',
      alignItems: 'center'
    },
    sectionName: {
      fontSize: '1.2rem',
      marginLeft: '12px'
    },
    slider: {
      marginTop: '2.5em',
      marginLeft: '2em',
      width: '85%',
    },
    grayBackground: {
      backgroundColor: '#E4E8EB'
    },
    labelFieldContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start'
    },
    label: {
      transform: 'translate(14px, -6px) scale(0.75)',
      color: 'rgba(0, 0, 0, 0.54)',
      marginBottom: '-24px',
    },
  })
);

const calculateRenoDescription = (renoBudget, purchasePrice) => {
  if (!renoBudget || !purchasePrice) {
    return 'Light';
  }
  const percent = renoBudget * 100 / purchasePrice;
  if (percent <= 25) {
    return 'Light';
  }
  if (percent <= 50) {
    return 'Moderate';
  }
  if (percent <= 100) {
    return 'Heavy';
  }
  return 'Extensive';
}

const calculateInitialLoanAmount = (purchasePrice, ltc) => {
  return ltc / 100 * purchasePrice;
}

export const LeverageImprovement = observer(({ store }: { store: FixFlipStore }) => {
  const classes = useStyles();
  const { loanInformationStore } = store;

  useEffect(() => {
    store.validateBridgeSubmissionStep('');
  }, []);

  return (
    <Grid>
      <Grid>
        <Typography variant="body1">
          Below is the information you have entered. Leverage is dependant on Experience, FICO Score, and Renovation Size.
        </Typography>
      </Grid>
      {/* Borrowers section here */}
      {store.borrowersRows.length ? (
        <>
          {store.selectBorrowersStore.borrowers.map(borr => (
            <Grid className={classes.parentContainer} container spacing={2}>
              <Grid item xs={12} className={classes.rowContainer}>
                <Avatar className={classes.grayBackground}>
                  <PersonOutlineRounded htmlColor='black' />
                </Avatar>
                <Typography className={classes.sectionName}>{borr.fullName}</Typography>
              </Grid>
              <Grid item xs={6} className={classes.rowContainer}>
                <SelectField
                  disabled={!!borr.experience}
                  label={'Number of Investment Properties'}
                  value={borr.experience || borr.experienceProvidedAtOrigination}
                  options={ffExperienceOptions}
                  onChange={value => {
                    store.selectBorrowersStore.saveBorrowerValues(borr, { ...borr, experienceProvidedAtOrigination: value });
                    store.validateBridgeSubmissionStep('');
                  }}
                  variant={'outlined'}
                  fullWidth
                  testId={'experience'}
                />
              </Grid>
              <Grid item xs={6} className={`${classes.rowContainer} ${classes.labelFieldContainer}`}>
                <Box>
                  <Typography className={classes.label}>FICO Score</Typography>
                </Box>
                <Slider
                  testId={'fico'}
                  label={'FICO Score'}
                  value={borr.medianScore || borr.ficoProvidedAtOrigination}
                  onChangeCommitted={(e, value) => {
                    store.selectBorrowersStore.saveBorrowerValues(borr, { ...borr, ficoProvidedAtOrigination: Number(value) });
                    store.validateBridgeSubmissionStep('');
                  }}
                  valueLabelDisplay={'on'}
                  className={classes.slider}
                  min={350}
                  max={800}
                  step={10}
                  marks={[
                    {
                      value: 350,
                      label: `350`,
                    },
                    {
                      value: 800,
                      label: '800',
                    },
                  ]}
                  disabled={!!borr.medianScore}
                />
              </Grid>
            </Grid>
          ))}
        </>
      ) : (
        <Grid className={classes.parentContainer}>
          <Typography>Please add a borrower.</Typography>
        </Grid>
      )}


      {/* Properties section here */}
      {store.properties.length ? (
        <>{store.properties.map(prop => (
          <Grid className={classes.parentContainer} container spacing={2}>
            <Grid className={classes.rowContainer} item xs={12}>
              <Avatar className={classes.grayBackground}>
                <Business htmlColor='black' />
              </Avatar>
              <Typography className={classes.sectionName}>{prop.address}</Typography>
            </Grid>
            <Grid item xs={6} className={classes.rowContainer}>
              <Grid xs={12}>
                <CurrencyField
                  testId="constructionHoldback"
                  fullWidth
                  label="Renovation Budget"
                  variant="outlined"
                  value={prop.totalRenovationBudget}
                  onChange={(name, value) => {
                    store.updateProperty({ ...prop, totalRenovationBudget: Number(value) });
                  }}
                  decimalScale={0}
                  disabled={false}
                  onBlur={() => {
                    loanInformationStore.makeInitialCalculations();
                    store.validateBridgeSubmissionStep('');
                  }}
                />
              </Grid>
            </Grid>
            <Grid item xs={6} className={classes.rowContainer}>
              <SelectField
                disabled={true}
                label={'Renovation Description'}
                value={calculateRenoDescription(prop.totalRenovationBudget, prop.purchasePrice)}
                options={renoDescriptionOptions}
                onChange={value => { }}
                variant={'outlined'}
                fullWidth
                testId={'renoDescription'}
              />
            </Grid>
            <Grid item xs={6} className={classes.rowContainer}>
              <SelectField
                disabled={false}
                label={'Will the square footage be expanded?'}
                value={!!prop.squareFootageAdded}
                options={trueFalseOptions}
                onChange={value => {
                  store.updateProperty({ ...prop, squareFootageAdded: value });
                  store.validateBridgeSubmissionStep('');
                }}
                variant={'outlined'}
                fullWidth
                testId={'squareFootageAdded'}
              />
            </Grid>
          </Grid>
        ))}</>
      ) : (
        <Grid className={classes.parentContainer}>
          <Typography>Please add a property.</Typography>
        </Grid>
      )}

      {/* Totals */}
      <TotalAmounts
        store={store}
        initialAmount={calculateInitialLoanAmount(store.loanInformationStore.getTotalPurchasePrice(), store.maxLTC)}
        ltc={store.maxLTC}
      />
    </Grid>
  );
});

export default LeverageImprovement;
