import { flow, makeObservable, observable } from 'mobx';
import { ApiResponse } from '@roc/feature-app-core';
import { GlobalStore } from '../../../stores';
import { FormStore } from '@roc/feature-app-core';
import { CompanyProfileService } from '../services/CompanyProfileService';
import {
  GroupEmailSubscription,
  LenderSubscriptionDetails,
  UserNotificationInfo,
} from '../types/companyProfileTypes';
import { ExternalLenderStore } from './externalLenderStore';

const form = {
  fields: {
    subscribeToUnderwritingEmails: {
      value: null,
      error: null,
      rule: '',
    },
    subscribeToInsuranceEmails: {
      value: null,
      error: null,
      rule: '',
    },
    subscribeToClosingEmails: {
      value: null,
      error: null,
      rule: '',
    },
    subscribeToPendingDocumentsEmails: {
      value: null,
      error: null,
      rule: '',
    },
    notifyInsuranceAgent: {
      value: null,
      error: null,
      rule: '',
    },
    notifyBorrowerClosingAgent: {
      value: null,
      error: null,
      rule: '',
    },
    borrowersParticipateInHomeDepotProgram: {
      value: null,
      error: null,
      rule: '',
    },
    sendDrawBoardedNotification: {
      value: null,
      error: null,
      rule: '',
    },
    fullEmailSynchronization: {
      value: null,
      error: null,
      rule: '',
    },
    loanStatusMailingList: {
      value: null,
      error: null,
      rule: '',
    },
    dailyReportMailingList: {
      value: null,
      error: null,
      rule: '',
    },
    extensionsMailingList: {
      value: null,
      error: null,
      rule: '',
    },
    wireConfirmMailingList: {
      value: null,
      error: null,
      rule: '',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export class SubscriptionDetailsStore extends FormStore {
  private globalStore: GlobalStore;
  salesforceLenderStore: ExternalLenderStore;

  private userNotificationInfo: UserNotificationInfo;
  private groupEmailSubscription: GroupEmailSubscription;

  companyProfileService: CompanyProfileService;

  subscriptionDetails: LenderSubscriptionDetails;

  constructor(globalStore, lenderStore) {
    super(form, globalStore);

    this.globalStore = globalStore;
    this.salesforceLenderStore = lenderStore;
    this.companyProfileService = new CompanyProfileService();

    makeObservable(this, {
      subscriptionDetails: observable,
      loadSubscriptionDetails: flow,
      saveSubscriptionDetails: flow,
      fetchSubscriptionDetails: flow,
      updateSalesforceLender: flow,
      updateUserNotificationInfo: flow,
      updateGroupEmailSubscription: flow,
    });
  }

  *loadSubscriptionDetails() {
    try {
      this.subscriptionDetails = yield this.fetchSubscriptionDetails();
      this.loadForm(this.subscriptionDetails);
    } catch (error) {
      console.log(error)
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while loading subscription details',
      });
    }
  }

  *saveSubscriptionDetails() {
    try {
      const values: LenderSubscriptionDetails = this.getFormValues();
      yield this.updateSalesforceLender(values);
      yield this.updateUserNotificationInfo(values);
      yield this.updateGroupEmailSubscription(values);

      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Subscription details successfully changed.',
      });
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while saving subscription details',
      });
    }
  }

  *updateSalesforceLender(values) {
    const lender = this.salesforceLenderStore.salesForceLender;
    lender.notifyInsuranceAgent = values.notifyInsuranceAgent
    lender.notifyBorrowerClosingAgent = values.notifyBorrowerClosingAgent
    lender.marketProducts = values.marketProducts
    lender.sendDrawNotification = values.sendDrawBoardedNotification
    lender.loanStatusEmails = values.loanStatusMailingList
    lender.dailyReportEmails = values.dailyReportMailingList
    lender.extensions = values.extensionsMailingList
    lender.wireConfirmEmails = values.wireConfirmMailingList
    lender.borrowersParticipateHomeDepotProgram = values.borrowersParticipateInHomeDepotProgram

    yield this.salesforceLenderStore.updateLenderFields(lender);
  }

  *updateUserNotificationInfo(values) {
    const newNotificationInfo = {
      ...this.userNotificationInfo,
      emailSyncEnabled: values.fullEmailSynchronization,
    };
    yield this.companyProfileService.updateUserNotificationInfo(
      newNotificationInfo
    );
    this.userNotificationInfo = newNotificationInfo;
  }

  *updateGroupEmailSubscription(values) {
    const newGroupEmailSubscription = {
      ...this.groupEmailSubscription,
      underwriting: values.subscribeToUnderwritingEmails,
      insurance: values.subscribeToInsuranceEmails,
      closing: values.subscribeToClosingEmails,
      pendingDocsEmail: values.subscribeToPendingDocumentsEmails,
    };

    yield this.companyProfileService.updateGroupEmailSubscription(
      newGroupEmailSubscription
    );
    this.groupEmailSubscription = newGroupEmailSubscription;
  }

  *fetchSubscriptionDetails() {
    const lender = yield this.salesforceLenderStore.salesForceLender;

    if (!this.groupEmailSubscription) {
      const groupEmailResponse: ApiResponse = yield this.companyProfileService.getGroupEmailSubscription(this.globalStore.selectedCompanyId);
      this.groupEmailSubscription = groupEmailResponse.data.data;
    }

    if (!this.userNotificationInfo) {
      const userNotificationResponse: ApiResponse = yield this.companyProfileService.getUserNotificationInfo();
      this.userNotificationInfo = userNotificationResponse.data.data;
    }

    const subscriptionDetails: LenderSubscriptionDetails = {
      subscribeToUnderwritingEmails: this.groupEmailSubscription.underwriting,
      subscribeToInsuranceEmails: this.groupEmailSubscription.insurance,
      subscribeToClosingEmails: this.groupEmailSubscription.closing,
      subscribeToPendingDocumentsEmails: this.groupEmailSubscription
        .pendingDocsEmail,
      notifyInsuranceAgent: lender.notifyInsuranceAgent,
      notifyBorrowerClosingAgent: lender.notifyBorrowerClosingAgent,
      borrowersParticipateInHomeDepotProgram: lender?.borrowersParticipateHomeDepotProgram,
      sendDrawBoardedNotification: lender.sendDrawNotification,
      fullEmailSynchronization: this.userNotificationInfo.emailSyncEnabled,
      loanStatusMailingList: lender.loanStatusEmails,
      dailyReportMailingList: lender.dailyReportEmails,
      extensionsMailingList: lender.extensions || '',
      wireConfirmMailingList: lender.wireConfirmEmails,
    };

    return subscriptionDetails;
  }
}
