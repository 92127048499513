import React, { useRef } from 'react';
import { Grid, Box } from '@material-ui/core';
import { Button } from '@roc/ui';
import { ScreenshotGrid } from '@roc/ui';

export type FundingTemplateContainer = {
  children: JSX.Element;
};

export const FundingTemplateContainer = (props: FundingTemplateContainer) => {
  const { children } = props;
  const screenshotContainerRef = useRef(null);

  const downloadFundingTemplate = () => {
    screenshotContainerRef.current.download();
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box display="flex" justifyContent="flex-end">
          <Button
            onClick={downloadFundingTemplate}
            color="secondary"
            variant="contained"
            testId="downloadFundingTemplateButton"
          >
            Download
          </Button>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <ScreenshotGrid
          fileName="Funding_Template.pdf"
          ref={screenshotContainerRef}
          container
          spacing={5}
        >
          <Grid item xs={12}>
            {children}
          </Grid>
        </ScreenshotGrid>
      </Grid>
    </Grid>
  );
};

export default FundingTemplateContainer;
