import { flow, makeObservable, observable } from 'mobx';
import { GlobalStore } from '@roc/feature-app-core';
import { LoanStore } from '@roc/feature-loans';
import { DocusignService } from '../../services/documentForms/docusignService';
import { DocumentStore } from '../documentStore';

export const ASSIGNMENT_AND_CONVEYANCE_ENTITY_TYPE = 'AssignmentAndConveyance';
export const NOTE_ALLONGE_ENTITY_TYPE = 'NoteAllonge';

export class DocusignStore {
  loanStore: LoanStore;
  documentStore: DocumentStore;
  globalStore: GlobalStore;

  noteAllongeService: DocusignService;

  iframeUrl: string;
  isStatusAccepted: boolean;
  errorMessage: string;
  entityType: string;

  constructor(
    loanStore: LoanStore,
    documentStore: DocumentStore,
    globalStore: GlobalStore
  ) {
    this.loanStore = loanStore;
    this.documentStore = documentStore;
    this.globalStore = globalStore;
    this.noteAllongeService = new DocusignService();
    makeObservable(this, {
      iframeUrl: observable,
      entityType: observable,
      isStatusAccepted: observable,
      errorMessage: observable,
      fetchUrlByEntityType: flow,
      fetchDocuSignURL: flow,
      initializeDialog: flow,
      fetchDocumentStatus: flow,
    });
  }

  *initializeDialog(entityType: string) {
    this.resetStore();
    this.entityType = entityType;
    try {
      yield this.fetchDocumentStatus();
      if (!this.isStatusAccepted && !this.errorMessage) {
        yield this.fetchDocuSignURL();
      }
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while loading Note Allonge document',
      });
    }
  }

  private resetStore() {
    this.iframeUrl = null;
    this.isStatusAccepted = null;
    this.errorMessage = null;
  }

  *fetchUrlByEntityType() {
    const { loanId } = this.loanStore.loanDetails;
    switch (this.entityType) {
      case NOTE_ALLONGE_ENTITY_TYPE:
        return yield this.noteAllongeService.getNoteAllongeDocusignURL(loanId);
      case ASSIGNMENT_AND_CONVEYANCE_ENTITY_TYPE:
        return yield this.noteAllongeService.getAssignmentAndConveyanceDocusignURL(
          loanId
        );
    }
  }

  *fetchDocuSignURL() {
    const response = yield this.fetchUrlByEntityType();
    this.iframeUrl = response.data.data;
  }

  *fetchDocumentStatus() {
    const { loanId } = this.loanStore.loanDetails;
    const response = yield this.noteAllongeService.getStatusOfDocusignRequestOfEntityWithId(
      this.entityType,
      loanId
    );
    const data = response.data.data;
    this.isStatusAccepted = data.isStatusAccepted;
    this.errorMessage = data.errorMessage;
  }
}
