import React from 'react';
import Grid from '@material-ui/core/Grid';
import { TextField, SelectField, DateField, NumberFormat } from '@roc/ui';
import { isNotBlank } from '@roc/feature-utils';
import { observer } from 'mobx-react';
import {
  FixFlipStore,
  SinglePropertyStore,
  RentalPortfolioStore,
} from './../stores';
import { PropertyAddress } from '@roc/ui/formComponents';
import { format, isValid } from 'date-fns';

const entityTypes = [
  { value: 'Corporation', label: 'Corporation' },
  { value: 'Limited Liability Company', label: 'Limited Liability Company' },
];

interface EntityFormProps {
  store: FixFlipStore | SinglePropertyStore | RentalPortfolioStore;
}

const EntityForm = (props: EntityFormProps) => {
  const { store } = props;
  const { entityStore } = store;
  const { form } = entityStore;

  const manageDate = (fieldName, date) => {
    let formattedDate = null;
    if (isValid(date)) {
      formattedDate = format(date, 'MM/dd/yyyy');
    }
    entityStore.onFieldChange(fieldName, formattedDate);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          testId="entityName"
          required={true}
          variant="outlined"
          label="Entity Name"
          type="text"
          value={form.fields.name.value}
          onChange={e => entityStore.onFieldChange('name', e.target.value)}
          helperText={form.fields.name.error}
          error={isNotBlank(form.fields.name.error)}
          standaloneLabel
          fullWidth
          disabled={!store.isNewEntity && store.isRawEntityValue('name')}
        />
      </Grid>
      <Grid item xs={12}>
        <SelectField
          required={true}
          variant="outlined"
          label="Entity Type"
          value={form.fields.type.value}
          options={entityTypes}
          onChange={value => entityStore.onFieldChange('type', value)}
          helperText={form.fields.type.error}
          error={isNotBlank(form.fields.type.error)}
          standaloneLabel
          fullWidth
          testId="entityType"
          disabled={!store.isNewEntity && store.isRawEntityValue('type')}
        />
      </Grid>
      <Grid item xs={12}>
        <NumberFormat
          customInput={TextField}
          format="##-#######"
          mask="_"
          required={(form.fields.ein.rule || '').includes('required')}
          variant="outlined"
          label="Entity EIN"
          testId="ein"
          value={form.fields.ein.value}
          onChange={e => entityStore.onFieldChange('ein', e.target.value)}
          helperText={form.fields.ein.error}
          error={isNotBlank(form.fields.ein.error)}
          standaloneLabel
          fullWidth
          disabled={!store.isNewEntity && store.isRawEntityValue('ein')}
        />
      </Grid>
      <Grid item xs={12}>
        <DateField
          testId="operatingAgreementDate"
          inputVariant="outlined"
          standaloneLabel
          format="MM/dd/yyyy"
          label="Date of Incorporation"
          value={form.fields.operatingAgreementDate.value}
          onChange={date => manageDate('operatingAgreementDate', date)}
          disableFuture={true}
          error={isNotBlank(form.fields.operatingAgreementDate.error)}
          helperText={form.fields.operatingAgreementDate.error}
          fullWidth
          disabled={
            !store.isNewEntity &&
            store.isRawEntityValue('operatingAgreementDate')
          }
        />
      </Grid>
      <Grid item xs={12}>
        <PropertyAddress
          store={entityStore}
          label="Entity Address"
          disabled={!store.isNewEntity && store.isRawEntityValue('address')}
        />
      </Grid>
    </Grid>
  );
};

export default observer(EntityForm);
