import { GridStore } from "@roc/feature-app-core";
import { ApiResponse } from "@roc/feature-app-core";
import { TradeService } from "../service/tradeService";
import { GlobalStore } from "@roc/feature-app-core";
import { makeObservable, observable, flow, computed } from "mobx";
import { TradeFormStore } from "./tradeFormStore";
import { Trade, Loan } from "@roc/feature-types";
import { DialogState } from "@roc/ui";
import { CreateTradeStore } from "./createTradeStore";

export class TradeStore {
  public browseTradesGridStore: GridStore;
  public loansInTradeGridStore: GridStore;
  public addLoansToTradeGridStore: GridStore;
  public tradeFormStore: TradeFormStore;
  public createTradeStore: CreateTradeStore;
  public addLoansModal: boolean;
  private tradeService: TradeService;
  private globalStore: GlobalStore;
  public editDialogState: DialogState;
  public selectedTrade: Trade;
  public selectedLoan: Loan;
  public selectedItems: any[];
  public tradeToMove: Trade;

  constructor(globalStore: GlobalStore) {
    this.globalStore = globalStore;
    this.browseTradesGridStore = new GridStore(
      () => this.fetchTrades(),
      null,
      50
    );
    this.loansInTradeGridStore = new GridStore(
      () => this.fetchLoansInTrade(),
      null,
      50
    );
    this.tradeService = new TradeService();
    this.tradeFormStore = new TradeFormStore(globalStore);
    this.createTradeStore = new CreateTradeStore(globalStore);
    this.editDialogState = null;
    this.addLoansModal = false;
    this.initialize();

    makeObservable(this, {
      selectedItems: observable,
      selectedTrade: observable,
      editDialogState: observable,
      fetchTrade: flow,
      isTradeActive: computed,
      tradeEditAllowed: computed,
      addLoansModal: observable,
      tradeToMove: observable,
      selectedLoan: observable,
      rejectLoan: flow,
      retracttLoan: flow,
      generateExecDocs: flow,
      generateShowDocs: flow,
      processTrade: flow,
      documentGenerationAllowed: computed,
    });
  }

  initialize() {
    this.selectedItems = [];
  }

  fetchTrades = async () => {
    this.selectedTrade = null;
    const gridFilters =
      this.browseTradesGridStore.gridData.meta.filters ?? {};
    const filters = {
      ...gridFilters,
    };
    const dropDownFilters =
      this.browseTradesGridStore.gridData.meta.dropdownFilters ?? {};
    try {
      const response: ApiResponse = await this.tradeService.getTrades(
        dropDownFilters,
        filters,
        this.browseTradesGridStore.gridData.meta.pageNumber,
        this.browseTradesGridStore.gridData.meta.pageSize,
        this.browseTradesGridStore.gridData.meta.sortBy,
        this.browseTradesGridStore.gridData.meta.sortDir,
      );
      return response;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching trades.',
      });
    }
  }

  fetchLoansInTrade = async () => {
    const gridFilters =
      this.loansInTradeGridStore.gridData.meta.filters ?? {};
    const filters = {
      ...gridFilters,
    };
    try {
      const response: ApiResponse = await this.tradeService.getLoansInTrade(
        this.selectedTrade.tradeId,
        this.loansInTradeGridStore.gridData.meta.pageNumber,
        this.loansInTradeGridStore.gridData.meta.pageSize,
        this.loansInTradeGridStore.gridData.meta.sortDir,
        this.loansInTradeGridStore.gridData.meta.sortBy,
        filters
      );
      return response;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching loans in trade.',
      });
    }
  }

  *fetchLoansToAdd() {
    const gridFilters =
      this.loansInTradeGridStore.gridData.meta.filters ?? {};
    const filters = {
      ...gridFilters,
    };
    try {
      const response: ApiResponse = yield this.tradeService.getLoansInTrade(
        this.selectedTrade.tradeId,
        this.loansInTradeGridStore.gridData.meta.pageNumber,
        this.loansInTradeGridStore.gridData.meta.pageSize,
        this.loansInTradeGridStore.gridData.meta.sortDir,
        this.loansInTradeGridStore.gridData.meta.sortBy,
        filters
      );
      return response;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching loans.',
      });
    }
  }

  setSelectedItems(data) {
    if (this.selectedItems.find(x => x.tempId === data.tempId)) {
      this.selectedItems.splice(this.selectedItems.indexOf(data), 1);
    } else {
      this.selectedItems.push(data);
    }
  }

  fetchTrade = async (tradeId: any) => {
    try {
      const response: ApiResponse = await this.tradeService.getTradeById(tradeId);
      this.selectedTrade = response.data?.data;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error getting trade.',
      });
    }
  }

  editTrade = () => {
    this.tradeFormStore.setTrade(this.selectedTrade);
    this.editDialogState = DialogState.EDIT;
  }

  closeDialog = () => {
    this.editDialogState = null;
  }

  get isTradeActive() {
    return this.selectedTrade?.status !== "Completed";
  }

  get tradeEditAllowed() {
    return !(this.selectedTrade?.buyerName == 'REIT')
  }

  get documentGenerationAllowed() {
    return false;
  }

  addLoans = () => {
    this.handleAddLoansModal(false);
  }

  handleAddLoansModal = (param: boolean) => {
    this.addLoansModal = param;
    this.initialize();
  }

  selectLoanRow(param) {
    if (this.selectedItems.some(loan => loan.tempId == param.tempId)) {
      this.selectedItems = this.selectedItems.filter(loan => loan.tempId != param.tempId);
      return;
    }
    this.selectedItems.push(param);
  }

  selectTradeRow(param) {
    if (this.tradeToMove?.tradeId == param.tradeId) {
      this.tradeToMove = null;
      return;
    }
    this.tradeToMove = param;
  }

  formatDate(date) {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${month}/${day}/${year}`;
  }

  getFormattedTrade(): Trade {
    const trade = this.tradeFormStore.getTrade();
    trade.amTradeId = this.selectedTrade.amTradeId;
    trade.expectedTradeDate = trade.expectedTradeDate ? this.formatDate(new Date(trade.expectedTradeDate)) : null;
    trade.showDate = trade.showDate ? this.formatDate(new Date(trade.showDate)) : null;
    trade.tradeDate = trade.tradeDate ? this.formatDate(new Date(trade.tradeDate)) : null;
    return trade;
  }

  *processTrade() {
    try {
      yield this.tradeService.processTrade(this.selectedTrade.tradeId);
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Trade was processed successfully.',
      });
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while processing trade information.',
      });
    }
  }

  *generateShowDocs() {
    try {
      yield this.tradeService.generateDocs(this.selectedTrade.tradeId, this.selectedTrade.buyerName, 'tradeShow');
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Trade was processed successfully.',
      });
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while generating show documents.',
      });
    }
  }

  *generateExecDocs() {
    try {
      yield this.tradeService.generateDocs(this.selectedTrade.tradeId, this.selectedTrade.buyerName, 'tradeExec');
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Trade was processed successfully.',
      });
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while generating exec documents.',
      });
    }
  }

  *rejectLoan() {
    try {
      console.log(this.selectedLoan)
      const request = {
        tradeId: this.selectedTrade.tradeId,
        loanId: this.selectedLoan.loanId,
        isRetract: false
      }
      yield this.tradeService.rejectLoan(request);
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Loan was rejected successfully',
      });
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while rejecting loan',
      });
    }
  }

  *retracttLoan() {
    try {
      const request = {
        tradeId: this.selectedTrade.tradeId,
        loanId: this.selectedLoan.loanId,
        isRetract: true
      }
      yield this.tradeService.rejectLoan(request);
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Loan was retracted successfully',
      });
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while retracting loan',
      });
    }
  }

  updateTrade = async () => {
    this.tradeFormStore.runFormValidationWithMessage();
    if (this.tradeFormStore.form.meta.isValid) {
      try {
        const trade = this.getFormattedTrade();
        const response: ApiResponse = await this.tradeService.updateTrade(trade);
        if (response.data.status === 'OK') {
          this.fetchTrade(response.data.data.tradeId);
          this.globalStore.notificationStore.showSuccessNotification({
            message: 'Trade Details saved successfully.',
          });
        }
      } catch (e) {
        this.globalStore.notificationStore.showErrorNotification({
          message: 'Error while saving trade details.',
        });
      }
      this.editDialogState = null;
      this.tradeFormStore.reset();
    }
  }
}