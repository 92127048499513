import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { Button, Grid, Typography } from '@material-ui/core';
import { Layout } from '@roc/client-portal-shared/components';
import { Tabs } from '@roc/ui';
import { useStore } from '@roc/client-portal-shared/hooks';
import { Paper } from '@roc/ui';
import { observer } from 'mobx-react';
import React, { useEffect, useMemo, useState } from 'react';
import { ClientInternalPricerPortfolio } from './components/clientInternalPricerPortfolio';
import { InternalPricerSFR } from './components/internalPricerSFR';
import { ClientInternalPricerSFR } from './components/clientInternalPricerSFR';
import { InternalPricerPortfolio } from './components/internalPricerPortfolio';
import { LoanSubtypeOptions } from './utils/internalPricerConstants';
import { InternalPricerFieldCalculations } from './components/internalPricerFieldCalculations';
import { store } from 'apps/client-portal-public/src/app/stores';
import { InternalPricerBaseStore } from './stores/internalPricerBaseStore';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  tabs: {},
  calculatedValuesBorder: {
    border: '1px solid #BEBEBE',
    borderRadius: '15px',
    padding: 16,
  },
}));

export interface Props {
  showSensitiveInfo?: boolean;
  internalPricerStore: any;
}

export const InternalPricer = observer(({ internalPricerStore, showSensitiveInfo = false }: Props) => {
  const classes = useStyles();
  //const { internalPricerStore } = useStore();
  const { loanSubtype } = internalPricerStore.form.fields;

  useEffect(() => {
    internalPricerStore.setShowSensitiveInfo(showSensitiveInfo);
  }, [internalPricerStore, showSensitiveInfo]);

  useEffect(() => {
    internalPricerStore.fetchDefaultValues();
  }, [loanSubtype.value]);

  const subtype = internalPricerStore.form.fields.loanSubtype.value;

  return (
    <Layout title="Rental Loan Pricer" maxWidth={'xl'}>
      <Paper className={classes.paper}>
        <Grid container spacing={4}>
          {subtype === LoanSubtypeOptions.SINGLE_PROPERTY && !internalPricerStore.isClientPortal && (
            <InternalPricerSFR store={internalPricerStore} />
          )}
          {subtype === LoanSubtypeOptions.PORTFOLIO && !internalPricerStore.isClientPortal && (
            <InternalPricerPortfolio store={internalPricerStore} />
          )}
          {subtype === LoanSubtypeOptions.SINGLE_PROPERTY && internalPricerStore.isClientPortal && (
            <ClientInternalPricerSFR store={internalPricerStore} />
          )}
          {subtype === LoanSubtypeOptions.PORTFOLIO && internalPricerStore.isClientPortal && (
            <ClientInternalPricerPortfolio store={internalPricerStore} />
          )}
        </Grid>
        <InternalPricerFieldCalculations store={internalPricerStore} />
      </Paper>
      <Paper className={classes.paper}>
        The proposed terms and pricing presented herein do not represent a commitment or offer to lend, but rather a summary for discussion purposes of certain selected terms of the proposed financing. This proposal does not create any legally binding obligations on any party hereto.
      </Paper>
    </Layout>

  );
});