import { action, flow, makeObservable, observable } from 'mobx';
import { GlobalStore } from '../../../stores';
import { UserStore } from '@roc/feature-app-core';
import { phoneBurnerService } from '../services/phoneBurnerService';
import { RingCentralStore } from './ringCentralStore';
import {
  BORROWER_OBJECT_TYPE,
  BROKER_OBJECT_TYPE,
  MIXED_OBJECT_TYPE,
  TPO_OBJECT_TYPE,
} from 'libs/feature-backoffice-users/src/backOfficeUsers/components/leads/constants/constants';

export class PhoneBurnerStore {
  private globalStore: GlobalStore;
  private userStore: UserStore;
  private ringCentralStore: RingCentralStore;
  phoneBurnerService: phoneBurnerService;
  public records: any[];
  public phoneBurnerCreds: any;

  constructor(globalStore, userStore, ringCentralStore) {
    this.globalStore = globalStore;
    this.userStore = userStore;
    this.phoneBurnerService = new phoneBurnerService();
    this.ringCentralStore = ringCentralStore;

    makeObservable(this, {
      retriveTokenWithOAuthToken: flow,
      handleTokenCallback: flow,
      startDialSession: flow,
      records: observable,
      phoneBurnerCreds: observable,
      setRecordsFromGrid: action,
    });
  }

  *retriveTokenWithOAuthToken(userId, host) {
    let clientId = this.getClientId(host);
    yield this.phoneBurnerService.retrieveTokenWithOAuthCode(
      userId,
      host,
      clientId
    );
  }

  getClientId(host) {
    const brand = host.includes('faco')
      ? 'faco'
      : host.includes('roc')
      ? 'roc'
      : 'civic';
    return this.ringCentralStore.phoneBurnerCreds[brand]['client_id'];
  }

  *handleTokenCallback(code) {
    let userId = this.userStore.userInformation?.userId;
    console.log('inhandleTokenCallback ' + code + ' ' + userId);
    yield this.phoneBurnerService.handleTokenCallback(code, userId);
  }

  *startDialSession() {
    let response = yield this.phoneBurnerService.startDialSession(this.records);
    if (response.data.data.dialsessions) {
      window.open(response.data.data.dialsessions.redirect_url, '_blank');
    } else {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'There was an error creating the dial session',
      });
    }
  }

  setRecordsFromGrid(selectedRecords, dashboardType) {
    this.records = selectedRecords.map(record => {
      let additionalPhone = [];

      if (record.secondaryPhone !== null) {
        additionalPhone.push({
          number: record.secondaryPhone,
          phone_type: 3,
          phone_label: 'secondary_phone',
        });
      }

      return {
        first_name: record.firstName,
        last_name: record.lastName,
        phone: record.phone,
        additional_phone: additionalPhone,
        email: record.email,
        lead_id: this.getId(record, dashboardType),
        type: dashboardType,
        doNotCall: record?.doNotCall,
        brokered: record?.brokered,
      };
    });
  }

  getId(record, dashboardType) {
    if (dashboardType === MIXED_OBJECT_TYPE) {
      return record.leadId ? record.leadId : record.contactId;
    }
    return dashboardType === BORROWER_OBJECT_TYPE ||
      dashboardType === TPO_OBJECT_TYPE ||
      dashboardType === BROKER_OBJECT_TYPE
      ? record.contactId
      : record.leadId;
  }
}
