import { useLoanStore } from '@roc/feature-loans';
import { LoanType } from '@roc/feature-utils';
import { observer } from 'mobx-react';
import { LoanApplicationSummaryBridge } from '../components/bridge/loanApplicationSummaryBridge';
import { LoanApplicationSummaryTerm } from '../components/term/loanApplicationSummaryTerm';

export const LoanApplicationSummaryTab = observer(() => {
  const { loanStore } = useLoanStore();
  const { loanDetails } = loanStore;

  const loanType: LoanType = loanDetails?.loanType;

  if (loanType === LoanType.RESIDENTIAL_BRIDGE) {
    return (
      <>
        <LoanApplicationSummaryBridge />
      </>
    );
  }
  else if (loanType === LoanType.RESIDENTIAL_TERM) {
    return (
      <>
        <LoanApplicationSummaryTerm />
      </>
    );
  }

  return null;
});
