import { Grid } from '@material-ui/core';
import { createDateField } from '@roc/ui';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { RequestedClosingDateStore } from '../../../stores/documentForms/requestedClosingDateStore';

export type RequestedClosingDateProps = {
  store: RequestedClosingDateStore;
};

export const RequestedClosingDate = observer(
  (props: RequestedClosingDateProps) => {
    const { store } = props;
    useEffect(() => {
      store.initialize();
    }, []);

    return (
      <>
        <Grid container spacing={1} justify="center">
          <Grid item xs={12}>
            {createDateField({
              store,
              testId: 'date',
              fieldName: 'date',
              label: '',
              disabled: !store.shouldBeAbleToSubmitRequestedClosingDate(),
            })}
          </Grid>
        </Grid>
      </>
    );
  }
);

export default RequestedClosingDate;
