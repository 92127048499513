import { useEffect } from 'react';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import { Paper } from '@roc/ui';
import { useParams } from 'react-router';
import { Layout } from '@roc/client-portal-shared/components';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { useStore } from '../../hooks';
import { observer } from 'mobx-react';
import { DenialOfCreditForm } from './denialOfCreditForm/denialOfCreditForm';
import { useDenialOfCreditStore } from './denialOfCreditForm/hooks/useDenialOfCreditStore';
import { DenialOfCreditSucess } from './denialOfCreditSuccess';

export type EncodedParams = {
  encodedParams: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(3, 0, 4),
      borderRadius: theme.spacing(2),
      boxShadow:
        'rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px',
    },
    stepContentWrapper: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      [theme.breakpoints.down('xs')]: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
      },
    },
    buttonContainer: {
      marginTop: 32,
    },
    centerText: {
      textAlign: 'center',
    },
  })
);

const DenialOfCredit = observer(() => {
  const { denialOfCreditStore } = useStore();
  const { denialOfCreditRequestStore } = useDenialOfCreditStore();
  const { denialOfCreditFormStore: store } = denialOfCreditRequestStore;
  const classes = useStyles();
  const { encodedParams } = useParams<EncodedParams>();
  const decodeLoanId = atob(encodedParams);
  store.form.fields.loanId.value = decodeLoanId;

  useEffect(() => {
    denialOfCreditStore.getDenialOfCredit(encodedParams);
  }, [])

  const handleSubmit = () => {
    denialOfCreditStore.submitDenialOfCredit(store);
  }

  return (
    <Layout>
      <Box my={3}>
        <Typography variant="h4" gutterBottom>
          {'Request rationale of credit denial'}
        </Typography>
      </Box>
      {!denialOfCreditStore.denialExist ?
        <>
          {!denialOfCreditStore.requestSubmitted ?
            <>
              {denialOfCreditStore.isDeclined ?
                <Grid container direction="column" alignItems="center" justifyContent="center">
                  <Grid item xs={8}>
                    <Paper className={classes.paper}>
                      <Box mt={3} className={classes.stepContentWrapper}>
                        <DenialOfCreditForm />
                        <Grid item xs={12} className={classes.buttonContainer}>
                          <Grid container justifyContent="flex-end" spacing={2}>
                            <Grid item xs={6} md={2}>
                              <Button
                                variant="contained"
                                color="primary"
                                size="large"
                                fullWidth
                                onClick={handleSubmit}
                              >
                                SUBMIT
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Box>
                    </Paper>
                  </Grid>
                </Grid> :
                <Paper className={classes.paper}>
                  <Box mt={3} className={classes.stepContentWrapper}>
                    <Typography variant="h5" gutterBottom>
                      {denialOfCreditStore.isDeclined == false ? 'Your loan is not declined, due to this we are not able to do your request rationale of credit denial.' : 'Loading...'}
                    </Typography>
                  </Box>
                </Paper>
              }
            </> :
            <Paper className={classes.paper} >
              <Box mt={3} className={classes.stepContentWrapper}>
                {denialOfCreditStore.success ? <DenialOfCreditSucess /> :
                  <Typography variant="h5" gutterBottom>Error ocurred while submitting request rationale of credit denial!</Typography>}
              </Box>
            </Paper>
          }
        </> :
        <Paper className={classes.paper} >
          <Box className={classes.centerText}>
            <Typography variant="h5">
              Rationale of credit denial already requested for this loan!
            </Typography>
          </Box>
          <Box className={classes.centerText}>
            <Typography>Request has been sent to the commitee, we will be in contact with you shortly.</Typography>
          </Box>
        </Paper>
      }
    </Layout>
  )
});

export default DenialOfCredit;