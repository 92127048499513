import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  Address,
  CellPhone,
  createRadioGroupField,
  EmailAddress,
} from '@roc/ui/formComponents';
import { TextField } from '@roc/ui';
import { observer } from 'mobx-react';
import React from 'react';
import { useDocumentStore } from './../../../../hooks/useDocumentStore';

const useStyles = makeStyles(() => {
  return {
    borrowerAttorneyRadio: {
      marginLeft: '8px'
    }
  };
});

export const BorrowerClosingAgentForm = observer(() => {
  const { documentStore } = useDocumentStore();
  const { documentFormStore } = documentStore;
  const { borrowerClosingAgentStore } = documentFormStore;
  const { canEdit } = borrowerClosingAgentStore;
  const formFields = borrowerClosingAgentStore.form.fields;
  const { isApplicable } = formFields;
  const styles = useStyles();

  const onFieldChange = (name, e) => {
    borrowerClosingAgentStore.onFieldChange(name, e.target.value);
  };

  const disabled = !canEdit;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} className={styles.borrowerAttorneyRadio}>
        {createRadioGroupField({
          store: borrowerClosingAgentStore,
          label: 'Does the borrower have an attorney?',
          fieldName: 'isApplicable',
          disabled,
          boolean: true,
        })}
      </Grid>
      {isApplicable.value && (
        <>
          <Grid item xs={6}>
            <TextField
              label="Name"
              standaloneLabel
              value={formFields.name.value}
              variant="outlined"
              testId="name"
              fullWidth
              required
              error={!!formFields.name.error}
              helperText={formFields.name.error}
              onChange={e => onFieldChange('name', e)}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Contact Name"
              standaloneLabel
              value={formFields.contactName.value}
              variant="outlined"
              testId="contactName"
              fullWidth
              error={!!formFields.contactName.error}
              helperText={formFields.contactName.error}
              onChange={e => onFieldChange('contactName', e)}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={6}>
            <CellPhone
              store={borrowerClosingAgentStore}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={6}>
            <Address
              store={borrowerClosingAgentStore}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={6}>
            <EmailAddress
              store={borrowerClosingAgentStore}
              disabled={disabled}
              required={false}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
});

export default BorrowerClosingAgentForm;
