import { Typography } from '@material-ui/core';
import { Layout } from '@roc/client-portal-shared/components';
import { Paper } from '@roc/ui';
import { LoanSubmissionStep } from 'libs/feature-loans/src/loanSubmission/utils/constants';
import { VerticalStepsComponent } from './stepper/verticalStepsComponent';
import { observer } from 'mobx-react';
import { BorrowerInformationStep } from './borrower/borrowerInformationStep';
import { BorrowerEntityInformationStep } from './borrowerEntity/borrowerEntityInformationStep';
import { PropertiesInformationStep } from './properties/propertiesInformationStep';
import {
  BorrowerEntityInformationSteps,
} from '../utils/constants';
import { LoanSubType } from '@roc/feature-utils';
import { LoanSummary } from './loanSummary/loanSummary';
import { SuccessPage } from './successPage';
import { makeStyles } from '@material-ui/core/styles';
import { useBorrowerLoanSubmissionStore } from '../hooks/useBorrowerLoanSubmissionStore';
import { useEffect } from 'react';
import { BridgePropertiesStore } from '../stores/bridge/bridgePropertiesStore';

const useStyles = makeStyles(theme => ({
  paper: {
    borderRadius: theme.spacing(2),
    boxShadow:
      'rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px',
  },
  title: {
    fontSize: '48px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '116.7%',
    padding: theme.spacing(2),
  },
}));

export const BorrowerGroundUpApplication = observer(({ currentRoutes }) => {
  const classes = useStyles();
  const { borrowerLoanSubmissionStore } = useBorrowerLoanSubmissionStore();
  const { fixFlipStore } = borrowerLoanSubmissionStore;
  const {
    borrowerInformationStore,
    borrowerEntityInformationStore,
  } = fixFlipStore;

  const propertiesStore = fixFlipStore.propertiesStore as BridgePropertiesStore;

  useEffect(() => {
    fixFlipStore.resetForNewLoanApplication();
    fixFlipStore.setLoanSubtype(LoanSubType.GROUND_UP);
  }, [])

  const fixFlipLoanApplicationSteps = [
    {
      label: 'Entity Information',
      icon: null,
      type: LoanSubmissionStep.ENTITY_INFORMATION,
      content: (
        <BorrowerEntityInformationStep store={fixFlipStore} />
      ),
      onBack: () => handleBack(),
      onNext: () => handleNext(),
      allowBack:
        borrowerEntityInformationStore.borrowerEntityInformationActiveStep !=
        BorrowerEntityInformationSteps.ENTITY_INFORMATION,
      allowNext: true,
      subLabels: borrowerEntityInformationStore.borrowerEntitySubLabels,
      handleSubLabelClick: index =>
        handleBorrowerEntitySubLabelClick(index),
    },
    {
      label: 'Borrower Information',
      icon: null,
      type: LoanSubmissionStep.BORROWER_INFORMATION,
      content: <BorrowerInformationStep store={fixFlipStore} />,
      onBack: () => handleBack(),
      onNext: () => handleNext(),
      allowBack: true,
      allowNext: true,
      subLabels: borrowerInformationStore.borrowerSubLabels,
      handleSubLabelClick: index =>
        handleBorrowerSubLabelClick(index),
    },
    {
      label: 'Property Information',
      icon: null,
      type: LoanSubmissionStep.PROPERTY_INFORMATION,
      content: (
        <PropertiesInformationStep
          loanSubtype={borrowerLoanSubmissionStore.loanSubtype}
          store={fixFlipStore}
        />
      ),
      onBack: () => handleBack(),
      onNext: () => handleNext(),
      allowBack: true,
      allowNext: propertiesStore.propertiesCount > 0,
      subLabels: propertiesStore.propertiesSubLables,
      handleSubLabelClick: index =>
        handlePropertySubLabelClick(index),
    },
    {
      label: 'Summary',
      icon: null,
      type: LoanSubmissionStep.SUMMARY,
      content: <LoanSummary store={fixFlipStore} />,
      onBack: () => handleBack(),
      onNext: () => handleNext(),
      allowBack: true,
      allowNext: !fixFlipStore.saved,
      nextButtonText: 'Submit',
    },
    {
      icon: null,
      type: LoanSubmissionStep.SUCCESS,
      content: <SuccessPage store={borrowerLoanSubmissionStore} currentRoutes={currentRoutes} />,
      allowBack: false,
      allowNext: false,
    },
  ];

  const handleNext = () => {
    fixFlipStore.handleNext(
      fixFlipLoanApplicationSteps[fixFlipStore.activeStep].type
    );
  };

  const handleBack = () => {
    fixFlipStore.handleBack(
      fixFlipLoanApplicationSteps[fixFlipStore.activeStep].type
    );
  };

  const handleBorrowerEntitySubLabelClick = (index) => {
    borrowerEntityInformationStore.handleBorrowerEntitySubLabelClick(index),
      fixFlipStore.setActiveStep(LoanSubmissionStep.ENTITY_INFORMATION);
  }

  const handleBorrowerSubLabelClick = (index) => {
    borrowerInformationStore.handleBorrowerSubLabelClick(index);
    fixFlipStore.setActiveStep(LoanSubmissionStep.BORROWER_INFORMATION);
  }

  const handlePropertySubLabelClick = (index) => {
    propertiesStore.handlePropertySubLabelClick(index);
    fixFlipStore.setActiveStep(LoanSubmissionStep.PROPERTY_INFORMATION);
  }

  return (
    <Layout>
      <Typography variant="h3" className={classes.title}>
        Apply for Loan: Ground Up
      </Typography>

      {fixFlipLoanApplicationSteps[fixFlipStore.activeStep]
        .type == LoanSubmissionStep.SUCCESS ? (
        <SuccessPage store={fixFlipStore} currentRoutes={currentRoutes} />
      ) : (
        <Paper className={classes.paper}>
          <VerticalStepsComponent
            activeStep={fixFlipStore.activeStep}
            steps={fixFlipLoanApplicationSteps}
          />
        </Paper>
      )}
    </Layout>
  );
});
