import { GlobalStore } from '@roc/feature-app-core';
import { LoanService } from '../../../loans/services/loanService';
import { makeObservable, observable, flow, action } from 'mobx';

export class AttorneysStore {
  globalStore: GlobalStore;
  loanService: LoanService;
  public lenderAttorneysOptionList: any[];
  constructor(globalStore) {
    this.globalStore = globalStore;
    this.loanService = new LoanService();
    this.setDefaults();
    makeObservable(this, {
      lenderAttorneysOptionList: observable,
      getLenderAttorneys: flow,
    });
  }
  private setDefaults() {
    this.lenderAttorneysOptionList = [];
  }
  *getLenderAttorneys(allowedLoanType, states, loanAmount, overrideInternalClosingTeamOption = null) {
    try {
      if (states.length > 0) {
        this.setDefaults();
        const internalClosingTeamOptionEnabled = overrideInternalClosingTeamOption ? overrideInternalClosingTeamOption : this.globalStore.userFeatures?.internalClosingTeamOptionEnabled;

        const response = yield this.loanService.getLenderAttorneysByState(states, internalClosingTeamOptionEnabled, loanAmount);
        const lenderAttorneysList = response.data && JSON.parse(response.data?.data);
        this.lenderAttorneysOptionList.length = 0;
        lenderAttorneysList
          .filter(x => (x.externalAllowed && x.allowedLoanTypes.includes(allowedLoanType)))
          .sort((a1, a2) => a1.investorAttorneyId - a2.investorAttorneyId)
          .forEach((attorney) => {
            const option = { id: '', value: '', label: '' };
            option.id = attorney.investorAttorneyId;
            option.value = attorney.company.email;
            option.label = attorney.company.name;
            this.lenderAttorneysOptionList.push(option);
          });
      }
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while getting Lender attorneys.',
      });
    }
  }


}
export default AttorneysStore;