import { ApiResponse } from "@roc/feature-app-core";
import { Conversation } from "@twilio/conversations";
import { action, makeObservable, observable, override } from "mobx";
import { ConversationData, ConversationGroup, ConversationsGroupType } from "../types/communicationTypes";
import { MyCommunicationStore } from "./myCommunicationsStore";

export class MyCommunicationGroupedStore extends MyCommunicationStore {
  conversationsGroup: ConversationsGroupType = {};

  constructor(globalStore, userStore, webSocketStore, communicationService, isInternal = false, showMessagePreview = false) {
    super(globalStore, userStore, webSocketStore, communicationService, isInternal, showMessagePreview);
    makeObservable(this, {
      conversationsGroup: observable,
      reset: override,
      removeConversation: override,
      loadMyConversationsGrouped: action
    });
  }

  reset(withoutFilter = false) {
    super.reset(withoutFilter);
    this.conversationsGroup = {
      [ConversationGroup.PRIVATE_MESSAGES]: {},
      [ConversationGroup.DIRECT_MESSAGES]: {},
      [ConversationGroup.ROOMS]: {},
      [ConversationGroup.TECH_SUPPORT]: {},
      [ConversationGroup.SALES_SUPPORT]: {},
      [ConversationGroup.CLOSING_SUPPORT]: {},
      [ConversationGroup.TASKS]: {}
    };
  }

  async loadMyConversationsGrouped(
    start: number,
    pageSize: number,
    onSuccess: (totalCount: number) => void,
    onError: () => void = () => { },
    conversationGroup?: ConversationGroup,
    query?: string,
    silentRefreshMyConversations: boolean = false,
  ) {
    try {
      if (typeof query === 'string') {
        super.saveSearchFilter(query, conversationGroup);
      }
      // Get My Conversations Grouped
      let response: ApiResponse = await this.communicationService.getMyConversationsGrouped(
        {
          userId: this.userId,
          start: start,
          count: pageSize,
          conversationGroup: super.searchConversationGroup,
          query: super.searchQuery,
        }
      );
      const conversationsGroupedData: Record<ConversationGroup, any> = response?.data?.data?.groupedResult ?? {};
      const conversationGroupKeys = Object.keys(conversationsGroupedData);
      if (conversationGroupKeys.length == 0) {
        onSuccess(0);
      } else {
        const allConversations = Object.values(conversationsGroupedData)
          .map(group => group.rows)
          .reduce((a, b) => a.concat(b), []);

        this._processConversations(allConversations, null, () => {
          conversationGroupKeys.forEach(conversationGroup => {
            const totalCount: number = conversationsGroupedData[conversationGroup]?.totalCount;
            this.conversationsGroup[conversationGroup].totalCount = totalCount;
            onSuccess(totalCount);
          })
        }, silentRefreshMyConversations)
      }
    } catch (e) {
      if (!silentRefreshMyConversations) {
        this.globalStore.notificationStore.showErrorNotification({
          message: "Error fetching my grouped conversations"
        });
      }
      onError();
    }
  }

  protected async sortConversations(conversations: Conversation[]) {
    super.sortConversations(conversations);
    this._sortGroupedConversations();
  }

  removeConversation(conversationSid: string) {
    const group = this.conversationGroupMap[conversationSid];
    super.removeConversation(conversationSid);
    if (group && this.conversationsGroup[group]?.rows) {
      this.conversationsGroup[group].rows = this.conversationsGroup[group].rows.filter(item => item !== conversationSid);
    }
    if (group && this.conversationsGroup[group]?.totalCount) {
      const totalCount = this.conversationsGroup[group].totalCount;
      this.conversationsGroup[group].totalCount = Math.max(0, totalCount - 1);
    }
  }

  private _sortGroupedConversations() {
    let temp: ConversationsGroupType = {
      [ConversationGroup.PRIVATE_MESSAGES]: {},
      [ConversationGroup.DIRECT_MESSAGES]: {},
      [ConversationGroup.ROOMS]: {},
      [ConversationGroup.TECH_SUPPORT]: {},
      [ConversationGroup.SALES_SUPPORT]: {},
      [ConversationGroup.CLOSING_SUPPORT]: {},
      [ConversationGroup.TASKS]: {}
    };
    Object.keys(this.conversations).forEach(cSid => {
      const group = this.conversationGroupMap[cSid];
      temp[group] = temp[group] ?? {}
      const _rows: string[] = temp[group].rows ?? [];
      _rows.push(cSid);
      temp[group].rows = _rows;
      temp[group].totalCount = this.conversationsGroup[group]?.totalCount ?? _rows.length;
    });
    this.conversationsGroup = temp;
  }

  setLoadingMoreResults(state: boolean) {
    super.setLoadingMoreResults(state);
  }

}