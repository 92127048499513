import { Box, Grid } from '@material-ui/core';
import { observer } from 'mobx-react';
import { EntityCard } from '../cards/entityCard';
import { PropertiesCard } from '../cards/propertiesCard';
import { LoanDetailsPaper } from '../loanDetailsPaper';
import { LoanStatusStepper } from '../loanDetailsStepper';
import { LoanApplicationDetailsCard } from './loanApplicationDetailsCard';

export const LoanApplicationSummaryBridge = observer(() => {
  return (
    <>
      <Grid item xs={12}>
        <LoanDetailsPaper>
          <LoanStatusStepper />
        </LoanDetailsPaper>
      </Grid>
      <Grid item xs={12}>
        <LoanApplicationDetailsCard />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Box height={'100%'} display={'flex'} flexDirection={'column'}>
              <Box pt={2} flexGrow={1}>
                <EntityCard />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box height={'100%'} display={'flex'} flexDirection={'column'}>
              <Box pt={2} flexGrow={1}>
                <PropertiesCard />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
});
