import { action, flow, makeObservable, observable } from 'mobx';
import { ApiResponse, GlobalStore, GridStore, UserStore } from '@roc/feature-app-core';
import { ScopeOfWorkV2Service } from '../../services';

import { isNil } from '@roc/feature-utils';
import ScopeOfWorkV2FormStore from './scopeOfWorkV2Form/scopeOfWorkV2FormStore';
import { ScopeOfWorkV2LinkStore } from './scopeOfWorkV2LinkStore';

export class ScopeOfWorkV2Store {
  globalStore: GlobalStore;
  scopeOfWorkV2FormStore: ScopeOfWorkV2FormStore;
  scopeOfWorkLinkStore: ScopeOfWorkV2LinkStore;
  gridStore: GridStore;
  protected scopeOfWorkService: ScopeOfWorkV2Service;
  public saved: boolean;

  scopeOfWorkV2FormDialogOpen: boolean;
  scopeOfWorkLinkFormDialogOpen: boolean;

  constructor(globalStore: GlobalStore, userStore?: UserStore) {
    this.globalStore = globalStore;
    this.gridStore = new GridStore(() =>
      this.fetchScopesOfWorkData()
    );
    this.scopeOfWorkV2FormStore = new ScopeOfWorkV2FormStore(
      globalStore,
      userStore
    );
    this.scopeOfWorkLinkStore = new ScopeOfWorkV2LinkStore(globalStore, this);
    this.scopeOfWorkService = new ScopeOfWorkV2Service();
    this.scopeOfWorkV2FormDialogOpen = false;
    this.scopeOfWorkLinkFormDialogOpen = false;
    makeObservable(this, {
      setScopeOfWorkV2FormDialogOpen: action,
      scopeOfWorkV2FormDialogOpen: observable,
      scopeOfWorkLinkFormDialogOpen: observable,
      setScopeOfWorkLinkFormDialogOpen: action,
      openScopeOfWork: flow,
      reset: action,
      saved: observable,
      showLinkModal: action
    });
    this.initialize();
  }

  initialize() {
    this.scopeOfWorkV2FormStore.setOnSaveScopeOfWork(this.onSaveScopeOfWork);
    this.scopeOfWorkV2FormStore.setOnSubmitScopeOfWork(
      this.onSubmitScopeOfWork
    );
    this.scopeOfWorkV2FormStore.setOnResetSuccefull(() => this.showLinkModal())
    this.scopeOfWorkLinkStore.setOnSuccessLink((scopeOfWork) => {
      this.openScopeOfWorkFormModal(scopeOfWork);
      this.setScopeOfWorkLinkFormDialogOpen(false);
    })
  }

  openScopeOfWorkFormModal(scopeOfWork) {
    this.setScopeOfWorkLinkFormDialogOpen(true);
    this.openScopeOfWorkForm(scopeOfWork)
  }

  showLinkModal() {
    const sowData = this.scopeOfWorkV2FormStore.scopeOfWork;
    this.scopeOfWorkLinkStore.setValues(+sowData?.loanId, +sowData?.propertyId, this.scopeOfWorkV2FormStore.scopeOfWorkData?.propertyAddress?.address)
    this.setScopeOfWorkV2FormDialogOpen(false);
    this.setScopeOfWorkLinkFormDialogOpen(true);
    this.gridStore.fetchGridData()
  }

  setScopeOfWorkV2FormDialogOpen(scopeOfWorkV2FormDialogOpen: boolean) {
    this.scopeOfWorkV2FormDialogOpen = scopeOfWorkV2FormDialogOpen;
  }

  setScopeOfWorkLinkFormDialogOpen(scopeOfWorkLinkFormDialogOpen: boolean) {
    this.scopeOfWorkLinkFormDialogOpen = scopeOfWorkLinkFormDialogOpen;
  }

  onAutoSave(saved: boolean) {
    if (saved) {
      this.gridStore.fetchGridData();
    }
  }

  onSaveScopeOfWork = (saved: boolean) => {
    this.gridStore.fetchGridData();
    if (saved !== false) return;
    this.scopeOfWorkV2FormDialogOpen = false;
  };

  onSubmitScopeOfWork = (submitted: boolean) => {
    this.gridStore.fetchGridData();
    if (isNil(submitted)) return;
    this.scopeOfWorkV2FormDialogOpen = false;
    this.scopeOfWorkLinkStore.isFormDialogOpen = false;
  };

  loadStore() {
    this.reset();
  }

  reset() {
    this.scopeOfWorkV2FormStore.reset();
    this.scopeOfWorkV2FormDialogOpen = false;
  }

  *openScopeOfWork(document: any) {
    try {
      const { data: response } = yield this.scopeOfWorkService.getScopeOfWork(
        document.loanId,
        document.collateralId
      );
      const scopeOfWork = JSON.parse(response.data);
      this.openScopeOfWorkForm(scopeOfWork);
    } catch (err) {
      this.globalStore.notificationStore.showErrorNotification({
        message:
          'An error occurred while fetching the Scope of Work information',
      });
    }
  }

  openScopeOfWorkForm(scopeOfWork) {
    const params = {
      scopeOfWorkId: scopeOfWork.scopeOfWorkId,
      isInternal: true,
      version: scopeOfWork.version,
    };
    switch (params.version) {
      case 2:
        this.scopeOfWorkV2FormStore.setParams(params);
        this.scopeOfWorkV2FormDialogOpen = true;
        break;
    }
  }

  private async fetchScopesOfWorkData() {
    try {
      const response: ApiResponse = await this.scopeOfWorkService.getScopesOfWork(
        this.gridStore.gridData.meta.pageNumber,
        this.gridStore.gridData.meta.pageSize,
        this.gridStore.gridData.meta.sortDir,
        this.gridStore.gridData.meta.sortBy,
        this.gridStore.gridData.meta.filters,
        this.gridStore.gridData.meta.dropdownFilters
      );
      return response;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'An error occurred while loading the Scopes of Work',
      });
    }
  }
}

export default ScopeOfWorkV2Store;
