import { Grid } from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { useDocumentStore } from '../../../hooks/useDocumentStore';
import BorrowerClosingAgentForm from './components/borrowerClosingAgentForm';

export const BorrowerClosingAgent = observer(() => {
  const { documentStore } = useDocumentStore();
  const { documentFormStore } = documentStore;
  const { borrowerClosingAgentStore } = documentFormStore;

  useEffect(() => {
    borrowerClosingAgentStore.initialize();
  }, []);
  return (
    <Grid container spacing={3}>
      <Grid item>
        <BorrowerClosingAgentForm />
      </Grid>
    </Grid>
  );
});

export default BorrowerClosingAgent;
