import { GlobalStore, GridStore } from "@roc/feature-app-core";
import { GENERIC_ERROR_MESSAGE } from "@roc/feature-utils";
import { flow, makeObservable, observable } from "mobx";
import { LeadService } from "../../services/leadService";
import { EditLeadNoteStore } from "./editLeadNoteStore";
import { EditLeadActivityStore } from "./editLeadActivityStore";
import { EditLeadTaskStore } from "./editLeadTaskStore";
import { RecordHistoryStore } from "./recordHistoryStore";

export class LeadDetailsStore {
  private globalStore: GlobalStore;
  public leadService: LeadService;
  editLeadNoteStore: EditLeadNoteStore;
  editLeadActivityStore: EditLeadActivityStore;
  editLeadTaskStore: EditLeadTaskStore;
  recordHistoryStore: RecordHistoryStore;



  leadNotes = [];
  leadActivities = [];
  leadTasks = [];
  loanVolumeStats = {}
  additionalPhones = [];
  additionalEmails = [];
  objectId = null;
  type = null;

  constructor(globalStore: GlobalStore) {
    this.globalStore = globalStore;
    this.leadService = new LeadService();
    this.editLeadNoteStore = new EditLeadNoteStore(globalStore, this);
    this.editLeadActivityStore = new EditLeadActivityStore(globalStore, this);
    this.editLeadTaskStore = new EditLeadTaskStore(globalStore, this);
    this.recordHistoryStore = new RecordHistoryStore(globalStore, this);
    makeObservable(this, {
      leadNotes: observable,
      leadActivities: observable,
      leadTasks: observable,
      loanVolumeStats: observable,
      additionalEmails: observable,
      additionalPhones: observable,
      objectId: observable,
      type: observable,
      fetchLeadDetails: flow,
      fetchLeadNotes: flow,
      fetchLeadActivities: flow,
      fetchLeadTasks: flow,
      fetchLoanVolumeStats: flow,
      fetchAdditionalContactInfo: flow,
      deleteAdditionalContactData: flow

    })
  }

  reset() {
    this.leadNotes = [];
    this.leadActivities = [];
    this.leadTasks = [];
    this.objectId = null;
    this.type = null;
    this.loanVolumeStats = {};
    this.resetAdditionalData();
  }

  resetAdditionalData() {
    this.additionalEmails = [];
    this.additionalPhones = [];
  }

  initialize(objectId: number, type: string) {
    this.reset();
    this.objectId = objectId;
    this.type = type;
  }

  *fetchLeadDetails(objectId: number, type: string, businessUnit: string) {
    try {
      this.initialize(objectId, type);
      yield this.fetchLeadNotes(businessUnit);
      yield this.fetchLeadActivities(businessUnit);
      yield this.fetchLeadTasks(businessUnit);
      yield this.fetchAdditionalContactInfo();
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE
      })
    }
  }

  *fetchAdditionalContactInfo() {
    try {
      const response = yield this.leadService.getLeadAdditionalContactInfo(this.objectId);
      const additionalContactInfo = response.data.data;
      additionalContactInfo.forEach(contact => {
        if (/@/.test(contact.data)) {
          this.additionalEmails.push(contact.data);
        } else {
          this.additionalPhones.push(contact.data);
        }
      });
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE
      })
    }
  }

  *fetchLeadNotes(businessUnit: string) {
    try {
      const response = yield this.leadService.getLeadNotes(this.objectId, this.type, businessUnit);
      this.leadNotes = response.data.data;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE
      })
    }
  }

  *fetchLeadActivities(businessUnit: string) {
    try {
      const response = yield this.leadService.getLeadActivities(this.objectId, this.type, businessUnit);
      this.leadActivities = response.data.data;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE
      })
    }
  }

  *fetchLeadTasks(businessUnit: string) {
    try {
      const response = yield this.leadService.getLeadTasks(this.objectId, this.type, businessUnit);
      this.leadTasks = response.data.data;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE
      })
    }
  }

  *fetchLoanVolumeStats(id: number, type: string) {
    try {
      const response = yield this.leadService.getLoanVolumeStats(id, type);
      this.loanVolumeStats = response.data.data;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE
      })
    }
  }

  *deleteAdditionalContactData(value, reason) {
    try {
      const body = {
        data: value,
        reason: reason,
        leadId: this.objectId
      };
      yield this.leadService.deleteLeadAdditionalContactInfo(body);
      this.resetAdditionalData();
      this.fetchAdditionalContactInfo();
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE
      })
    }
  }
}