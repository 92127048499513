import { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import { createRadioGroupField } from 'libs/client-portal-shared/src/app/components/formComponents/common/componentBuilder';
import { Card } from '@roc/ui';
import { TamariskPreferencesModal } from './tamariskPreferencesModal';

const useStyles = makeStyles(theme => ({
  card: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));



export const TamariskPreferences = observer(({ companyProfileStore }) => {
  const { lenderDetailsStore } = companyProfileStore;
  const { lenderDetailsFormStore } = lenderDetailsStore;
  const [editTamariskPreferences, setEditTamariskPreferences] = useState(false);
  const classes = useStyles();


  const handleOpenModal = () => {
    lenderDetailsFormStore.loadStepperModalWithValuesFromDB();
    setEditTamariskPreferences(true);
  };

  const isLead = lenderDetailsStore.globalStore.userFeatures.backOfficeLead;
  const cardProps = isLead ? { onEdit: handleOpenModal } : {};

  return (
    <>
      <Card title='TAMARISK PREFERENCES' cardContentProps={{ style: { padding: 0 } }} className={classes.card} {...cardProps}>
        <Box p={4}>
          <Box display="flex" flexDirection="row" flexWrap="wrap" justifyContent="space-between">
            <Box flex={1} mr={2}>
              {createRadioGroupField({
                store: lenderDetailsFormStore,
                fieldName: 'tamariskAppraisal',
                label: 'Order appraisals only via Tamarisk?',
                disabled: true
              })}
            </Box>
            {
              (!lenderDetailsFormStore.form.fields.tamariskAppraisal.value) &&
              <Box flex={1} mr={2}>
                {createRadioGroupField({
                  store: lenderDetailsFormStore,
                  fieldName: 'tamariskDefaultOption',
                  label: 'Default option to order appraisal with Tamarisk?',
                  disabled: true
                })}
              </Box>
            }
            <Box flex={1}>
              {createRadioGroupField({
                store: lenderDetailsFormStore,
                fieldName: 'tamariskSendInvoiceEmail',
                label: 'Can Portal send payment emails to the borrower?',
                disabled: true
              })}
            </Box>
          </Box>
        </Box>
      </Card>
      <TamariskPreferencesModal open={editTamariskPreferences} setEditTamariskPreferences={setEditTamariskPreferences} companyProfileStore={companyProfileStore} />
    </>
  );
});

export default TamariskPreferences;
