import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { Layout } from '@roc/feature-app-core';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useParams } from 'react-router';
import Summary from './components/summary/summary';
import { useProposalsStore } from './hooks/useProposalsStore';

const useStyles = makeStyles(theme =>
  createStyles({
    paper: {
      padding: theme.spacing(4),
    },
  })
);

const ViewProposal = observer(() => {
  const { proposalId, proposalStatus } = useParams<{
    proposalId: string;
    proposalStatus: string;
  }>();
  const { proposalsStore } = useProposalsStore();
  const classes = useStyles();
  const { detailsStore } = proposalsStore;

  useEffect(() => {
    detailsStore.initForm(proposalId, detailsStore.loanSubtype);
  }, []);

  return (
    <Layout title="Deal Room">
      <Paper className={classes.paper}>
        <Summary onlyView showConversation={true} />
      </Paper>
    </Layout>
  );
});

export default ViewProposal;
