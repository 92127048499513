import { ScopeOfWorkV2Service } from "@roc/feature-sow-shared/services";

const revisionBaseUrl = '/nportal/rest/pub/draws';

const url = {
  GET_SCOPE_OF_WORK_REVISION: `${revisionBaseUrl}/getSowRevisionById`,
};

export class ScopeOfWorkRevisionService extends ScopeOfWorkV2Service {
  constructor() {
    super();
    this.url = url;
  }

  getSOWRevision(drawId: number) {
    return this.get(`${url.GET_SCOPE_OF_WORK_REVISION}?drawId=${drawId}`);
  }
}