import { LoanSubtypeOption } from './dscrCalculatorConstants';

export const getLoanSubtype = loan => {
  const TWO_MILLION = 2000000;

  switch (loan.loanSubType) {
    case 'Single Property Rental':
      return LoanSubtypeOption.SINGLE_PROPERTY;
    case 'Single Property Mixed Use':
    case 'Single Property Multifamily Term':
      return LoanSubtypeOption.FIVE_PLUS;
    case 'Multifamily Term Portfolios':
    case 'Rental Portfolios':
    case 'Mixed Use Portfolios':
      if (loan.unitCount > 10 || loan.amount > TWO_MILLION) {
        return LoanSubtypeOption.LARGE_PORTFOLIO;
      } else {
        return LoanSubtypeOption.PORTFOLIO;
      }
    default:
      return null;
  }
};

export const mapLoanToDscrCalculatorFormValues = loan => {
  const requestedLTV =
    loan.estimatedAsIsValue && loan.amount
      ? (loan.amount / loan.estimatedAsIsValue) * 100
      : '';

  return {
    ...loan,
    loanAmount: loan.amount,
    requestedLTV,
    annualHOAFees: loan.annualHoaFees,
    originationFees: loan.originationFeesPct,
  };
};

export const isNumber = value => !isNaN(value);

export const removeEmptyValues = payload => {
  let output = { ...payload };
  Object.keys(output).forEach(key => {
    output[key] = output[key] === '' ? null : output[key];
  });
  return output;
};
