import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  StandardDialog,
  createAutocompleteField,
  createCheckboxField,
  createPhoneFieldClick,
  createSelectField,
  createTextField,
} from '@roc/ui';
import {
  Box,
  ListItem,
  ListItemText,
  Divider,
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  InputAdornment,
  Tooltip,
  Tab,
  Tabs,
  Chip,
  TextField
} from '@material-ui/core';
import { TestDataAttribute, Card } from '@roc/ui';
import { sanitizeTestId } from '@roc/client-portal-shared/utils';
import { useUserStore } from '@roc/feature-app-core';
import {
  TPO_OBJECT_TYPE,
  dataSourceGroupOptions,
  leadExperienceOptions,
  leadProfessionOptions,
  leadSourceOptions,
  leadTypeOptions,
  leadStatusOptions as statusOptions,
  leadSubStatusOptions,
  leadNewSubStatusOptions,
  leadAttemptingToContactSubStatusOptions,
  leadFitSubStatusOptions,
  leadNoFitSubStatusOptions,
} from '../leads/constants/constants';
import { SendBulkEmailsModal } from '../leadsDashboard/sendBulkEmails';
import { SendBulkMessagesModal } from 'apps/crm-portal/src/app/modules/ringCentral/sendBulkMessages';
import { recordTypeLeadOptions } from '../../stores/editLeadsDashboardStore';
import { ContactActivitiesCard } from '../notesAndActivities/contactActivitiesCard';
import { ContactNotesCard } from '../notesAndActivities/contactNotesCard';
import { LEAD_OBJECT_TYPE } from '../leads/constants/constants';
import { ContactTasksCard } from '../notesAndActivities/contactTasksCard';
import { ContactHistoryCard } from '../notesAndActivities/contactHistoryCard';
import { ConvertLeadToLenderModal } from '../leadsDashboard/convertLeadToLenderModal';
import { AdditionalContactsCard } from '../notesAndActivities/additionalContactsCard';
import { HelpOutline } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  button: {
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(2),
    minWidth: '100px',
  },
}));

export const divider = <Divider variant="middle" component="li" />;

export const listItem = (label, value, icon = null, customTestId = null) => {
  const testId = sanitizeTestId(customTestId || label);
  return (
    <ListItem>
      <ListItemText>
        <Box display="flex">
          {icon && <Box ml={1}>{icon}</Box>}
          <Box flexGrow={1}>{label}</Box>
          <TestDataAttribute id={testId}>
            <Box>{value}</Box>
          </TestDataAttribute>
        </Box>
      </ListItemText>
    </ListItem>
  );
};

export const LeadDetailsComponent = observer(
  ({ leadId, readOnly, salesforceLeadStore, onClose = null, onSave = null }) => {
    const { leadsStore, leadDetails, globalStore } = salesforceLeadStore;

    useEffect(() => {
      salesforceLeadStore.gridBusinessUnit = globalStore.lenderInfo?.businessUnit;
    }, []);

    return (
      <>
        {leadDetails?.relatedLeads.length > 0 &&
          <Tabs
            value={salesforceLeadStore.gridBusinessUnit}
            textColor='primary'
            indicatorColor='primary'
            onChange={(event, newValue) => { salesforceLeadStore.gridBusinessUnit = newValue }}
          >
            {leadDetails?.relatedLeads.map((relatedLead: string) => <Tab label={relatedLead} value={relatedLead} />)}
          </Tabs>}
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <ContactInformationCard
              leadId={leadId}
              readOnly={readOnly}
              salesforceLeadStore={salesforceLeadStore}
              onClose={onClose}
              onSave={onSave}
            />
            <Box pt={2}>
              <AdditionalContactsCard leadDetailsStore={leadsStore.leadDetailsStore} />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <ContactNotesCard
              leadDetailsStore={leadsStore.leadDetailsStore}
            />
            <Box pt={2}>
              <ContactActivitiesCard
                leadDetailsStore={leadsStore.leadDetailsStore}
              />
            </Box>
            <Box pt={2}>
              <ContactTasksCard
                leadDetailsStore={leadsStore.leadDetailsStore}
              />
            </Box>
            <Box pt={2}>
              <ContactHistoryCard
                leadDetailsStore={leadsStore.leadDetailsStore}
                type={LEAD_OBJECT_TYPE} />
            </Box>
          </Grid>
        </Grid>
      </>
    );
  }
);

const ContactInformationCard = observer(
  ({ leadId, readOnly, salesforceLeadStore, onClose, onSave }) => {
    const classes = useStyles();
    const formStore = salesforceLeadStore.editLeadDetailsFormStore;
    const {
      userStore: { userInformation },
    } = useUserStore();
    const [openSendBulkMessagesModal, setOpenSendBulkMessagesModal] = useState(
      false
    );
    const [openSendBulkEmailModal, setOpenSendBulkEmailModal] = useState(false);
    const { ringCentralStore, sendEmailsStore } = salesforceLeadStore;
    const { leadsStore, globalStore, leadDetails } = salesforceLeadStore;
    const { leadDetailsStore } = leadsStore;
    const [subStatusOptions, setSubStatusOptions] = useState([]);
    const [leadDetailsRequest, setLeadDetailsRequest] = useState(true);

    const connectedWithRingCentral = salesforceLeadStore.isSalesForce ? salesforceLeadStore.connectedWithRingcentral : userInformation.connectedWithRingcentral;
    const [openModal, setOpenModal] = useState(false);

    useEffect(() => {
      if (salesforceLeadStore.gridBusinessUnit) {
        leadDetailsStore.fetchLeadDetails(leadId, LEAD_OBJECT_TYPE, salesforceLeadStore.gridBusinessUnit);
        fetchData();
      }
      salesforceLeadStore.currentRole = salesforceLeadStore.currentRole || userInformation?.roles[0] || '';
      salesforceLeadStore.emailAddress = salesforceLeadStore.emailAddress || userInformation?.username || '';
    }, [salesforceLeadStore.gridBusinessUnit]);

    useEffect(() => {
      if (leadDetailsRequest && salesforceLeadStore.leadDetails && globalStore.userFeatures.backOfficeLead) {
        salesforceLeadStore.gridBusinessUnit = salesforceLeadStore?.leadDetails?.relatedLeads[0];
        setLeadDetailsRequest(false);
      }
    }, [salesforceLeadStore.leadDetails]);

    useEffect(() => {
      updateSubStatusOptions(formStore.form.fields.status.value);
    }, [formStore.form.fields.status.value]);

    const fetchData = async () => {
      await salesforceLeadStore.getAllOwners();
      await salesforceLeadStore.getLeadDetails(leadId);
      await salesforceLeadStore.getAdditionalContactInfo(leadId);
    };

    const onSendBulkMessages = () => {
      ringCentralStore.resetLists();
      ringCentralStore.addStandaloneRecord(leadId, { phone: formStore.form.fields.phoneNumber.value, secondaryPhoneNumber: formStore.form.fields.secondaryPhone.value }, LEAD_OBJECT_TYPE);
      setOpenSendBulkMessagesModal(true);
    };

    const onSendBulkEmail = () => {
      sendEmailsStore.resetbulkEmails();
      sendEmailsStore.setBulkEmails(
        {
          email: formStore.form.fields.email.value,
          firstName: formStore.form.fields.firstName.value,
          lastName: formStore.form.fields.lastName.value,
          id: leadId,
          type: LEAD_OBJECT_TYPE
        }, true);
      setOpenSendBulkEmailModal(true);
    };

    const closeModal = () => {
      setOpenSendBulkMessagesModal(false);
      setOpenSendBulkEmailModal(false);
      ringCentralStore.setMessage('');
      ringCentralStore.setLabel('');
      ringCentralStore.setTemplateValue('');
      ringCentralStore.setTemplateId('');
      ringCentralStore.setTemplateLabel('');
      ringCentralStore.sendBulkSMSFormStore.onFieldChange('message', '');
      ringCentralStore.resetLists();
    };

    const closeBulkEmailModal = () => {
      setOpenSendBulkEmailModal(false);
      sendEmailsStore.handleModalClose();
    };

    const updateSubStatusOptions = selectedStatus => {
      switch (selectedStatus) {
        case 'New':
          setSubStatusOptions(leadNewSubStatusOptions);
          break;
        case 'Attempting to contact':
          setSubStatusOptions(leadAttemptingToContactSubStatusOptions);
          break;
        case 'Fit':
          setSubStatusOptions(leadFitSubStatusOptions);
          break;
        case 'No Fit':
          setSubStatusOptions(leadNoFitSubStatusOptions);
          break;
        default:
          setSubStatusOptions([]);
          break;
      }
    };

    const handleStatusChange = selectedStatus => {
      formStore.onFieldChange('status', selectedStatus);
      updateSubStatusOptions(selectedStatus);
    };

    const handleOpenModal = () => {
      setOpenModal(true);
    };

    const handleCloseModal = () => {
      setOpenModal(false);
    };

    const convertLeadToLender = async () => {
      await salesforceLeadStore.convertLeadToLender(leadDetailsObject);
      handleCloseModal();
    };

    const leadDetailsObject = {
      leadId: salesforceLeadStore.leadDetails?.id || '',
      company: salesforceLeadStore.leadDetails?.company || '',
      recordType: salesforceLeadStore.leadDetails?.recordType || '',
      fromLeadDetails: true,
    };

    const action = (
      <Box p={1} mb={-10}>
        <Button
          className={classes.button}
          variant="contained"
          size="small"
          color="primary"
          testId="convert_lead"
          onClick={handleOpenModal}
        >
          Convert
        </Button>
        <Button
          className={classes.button}
          variant="contained"
          size="small"
          onClick={() =>
            (window.location.href = `rcapp://r/call?number=${formStore.form.fields.phoneNumber.value}`)
          }
          color="primary"
          testId="call"
          disabled={formStore.form.fields?.doNotCall.value || formStore.form.fields?.brokered.value}
        >
          Call
        </Button>
        <Button
          className={classes.button}
          variant="contained"
          size="small"
          onClick={onSendBulkMessages}
          color="primary"
          testId="send_bulk_messages"
          disabled={formStore.form.fields?.doNotSms.value || formStore.form.fields?.brokered.value}
        >
          SMS
        </Button>
        <Button
          className={classes.button}
          variant="contained"
          size="small"
          onClick={onSendBulkEmail}
          color="primary"
          testId="send_bulk_emails"
          disabled={formStore.form.fields?.doNotSendEmail.value || formStore.form.fields?.brokered.value}
        >
          Email
        </Button>
      </Box>
    );

    return (
      <Card title={'Contact Information'} cardHeaderProps={{ action }}>
        <Box pb={3}>
          <Grid container>
            <Grid item xs={6} container>
              {leadDetails?.leadTags.map(leadTag => {
                return <Chip label={leadTag.tag} style={{ margin: 1 }} color='primary' onDelete={() => salesforceLeadStore.deleteTag(leadTag.leadTagId)} />
              })}
            </Grid>
            <Grid item container xs={6} direction='row-reverse'>
              <Grid item>
                <Button
                  className={classes.button}
                  variant="contained"
                  size="small"
                  onClick={() => salesforceLeadStore.saveTag()}
                  color="primary"
                  testId="addTag"
                >
                  Add Tag
                </Button>
              </Grid>
              <Grid item>
                <TextField value={formStore.form.fields?.leadTag.value}
                  onChange={e => {
                    formStore.onFieldChange('leadTag', e.target.value);
                  }} />
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            {createTextField({
              label: 'First Name',
              fieldName: 'firstName',
              testId: 'firstName',
              store: formStore,
              disabled: readOnly,
            })}
          </Grid>
          <Grid item xs={12} sm={6}>
            {createTextField({
              label: 'Last Name',
              fieldName: 'lastName',
              testId: 'lastName',
              store: formStore,
              disabled: readOnly,
            })}
          </Grid>
          <Grid item xs={12} sm={6}>
            {createPhoneFieldClick({
              label: 'Phone Number',
              fieldName: 'phoneNumber',
              testId: 'phoneNumber',
              store: formStore,
              disabled: readOnly,
            })}
          </Grid>
          <Grid item xs={12} sm={6}>
            {createPhoneFieldClick({
              label: 'Secondary Phone Number',
              fieldName: 'secondaryPhone',
              testId: 'secondaryPhone',
              store: formStore,
              disabled: readOnly,
            })}
          </Grid>
          <Grid item xs={12} sm={6}>
            {createTextField({
              label: 'Email',
              fieldName: 'email',
              testId: 'email',
              type: 'email',
              store: formStore,
              disabled: readOnly,
            })}
          </Grid>
          <Grid item xs={12} sm={6}>
            {createTextField({
              label: 'Secondary Email',
              fieldName: 'secondaryEmail',
              testId: 'secondaryEmail',
              type: 'email',
              store: formStore,
              disabled: readOnly,
            })}
          </Grid>
          <Grid item xs={12} sm={6}>
            {createTextField({
              label: 'Company',
              fieldName: 'company',
              testId: 'company',
              store: formStore,
              disabled: readOnly,
            })}
          </Grid>
          <Grid item xs={12} sm={6}>
            {createAutocompleteField({
              label: 'Lead Source',
              fieldName: 'leadSource',
              store: formStore,
              options: leadSourceOptions,
              onChange: value => {
                formStore.onFieldChange('leadSource', value);
              },
              disabled: readOnly,
            })}
          </Grid>
          <Grid item xs={12} sm={6}>
            {createTextField({
              label: 'Data Source',
              fieldName: 'dataSource',
              testId: 'dataSource',
              store: formStore,
              disabled: readOnly,
              InputProps: {
                endAdornment: (
                  <InputAdornment position="end">
                    <Tooltip
                      title={
                        <span style={{ fontSize: '16px' }}>
                          Please enter the name of the list here. e.g NPLA Conf
                          2024
                        </span>
                      }
                    >
                      <HelpOutline fontSize="medium" color={'primary'} />
                    </Tooltip>
                  </InputAdornment>
                ),
              },
            })}
          </Grid>
          <Grid item xs={12} sm={6}>
            {createSelectField({
              label: 'Data Source Group',
              fieldName: 'dataSourceGroup',
              testId: 'dataSourceGroup',
              store: formStore,
              options: dataSourceGroupOptions,
              disabled: readOnly,
              onChange: value => {
                formStore.onFieldChange('dataSourceGroup', value);
              },
            })}
          </Grid>
          <Grid item xs={12} sm={6}>
            {createSelectField({
              label: 'Status',
              fieldName: 'status',
              testId: 'status',
              store: formStore,
              options: statusOptions,
              placeholder: 'Choose Status',
              disabled: readOnly,
              onChange: value => {
                formStore.onFieldChange('status', value);
                handleStatusChange(value);
              },
            })}
          </Grid>
          <Grid item xs={12} sm={6}>
            {createSelectField({
              label: 'Sub Status',
              fieldName: 'subStatus',
              testId: 'subStatus',
              store: formStore,
              options: subStatusOptions,
              placeholder: 'Choose Sub Status',
              disabled: readOnly,
            })}
          </Grid>
          <Grid item xs={12} sm={6}>
            {createSelectField({
              label: 'Type',
              fieldName: 'type',
              testId: 'type',
              store: formStore,
              options: leadTypeOptions,
              disabled: readOnly,
            })}
          </Grid>
          <Grid item xs={12} sm={6}>
            {createSelectField({
              label: 'Profession',
              fieldName: 'profession',
              testId: 'profession',
              store: formStore,
              options: leadProfessionOptions,
              disabled: readOnly,
            })}
          </Grid>
          <Grid item xs={12} sm={6}>
            {createSelectField({
              label: 'Record Type',
              fieldName: 'recordType',
              testId: 'recordType',
              store: formStore,
              options: recordTypeLeadOptions,
              disabled: readOnly,
            })}
          </Grid>
          <Grid item xs={12} sm={6}>
            {createSelectField({
              label: 'Experience',
              fieldName: 'experience',
              testId: 'experience',
              store: formStore,
              options: leadExperienceOptions,
              disabled: readOnly,
            })}
          </Grid>
          <Grid item xs={12} sm={6}>
            {createTextField({
              label: 'How Did you Hear About Us?',
              fieldName: 'howDidYouHearAboutUs',
              testId: 'howDidYouHearAboutUs',
              store: formStore,
              disabled: readOnly,
            })}
          </Grid>
          {(salesforceLeadStore?.currentRole === 'backOfficeProcessor' ||
            salesforceLeadStore?.currentRole === 'backOfficeLead' ||
            salesforceLeadStore?.currentRole === 'backOfficeOriginator') &&
            <Grid item xs={12} sm={6}>
              {createAutocompleteField({
                label: 'Owner Name',
                fieldName: 'ownerId',
                store: formStore,
                options: salesforceLeadStore.getActiveOwnersByBusinessUnit(),
                placeholder: 'Choose owner',
                disabled: readOnly,
                onChange: value => {
                  formStore.onFieldChange('ownerId', value);
                },
              })}
            </Grid>}
          <Grid item xs={12} sm={6}>
            {createTextField({
              label: 'Promo Code',
              fieldName: 'promoCode',
              testId: 'promoCode',
              store: formStore,
              disabled: readOnly,
            })}
          </Grid>
          <Grid item xs={12} sm={6}>
            {createTextField({
              label: 'Lead Feedback',
              fieldName: 'leadFeedback',
              testId: 'leadFeedback',
              store: formStore,
              disabled: readOnly,
              multiline: true
            })}
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4" style={{ fontSize: 20 }}>
              Do Not Disturb
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            {createCheckboxField({
              label: 'Do not Call',
              fieldName: 'doNotCall',
              store: formStore,
              disabled: readOnly,
            })}
          </Grid>
          <Grid item xs={12} sm={6}>
            {createCheckboxField({
              label: 'Do not Email',
              fieldName: 'doNotSendEmail',
              store: formStore,
              disabled: readOnly,
            })}
          </Grid>
          <Grid item xs={12} sm={6}>
            {createCheckboxField({
              label: 'Do not SMS',
              fieldName: 'doNotSms',
              store: formStore,
              disabled: readOnly,
            })}
          </Grid>
          <Grid item xs={12} sm={6}>
            {createCheckboxField({
              label: 'Do not Mail',
              fieldName: 'doNotMail',
              store: formStore,
              disabled: readOnly,
            })}
          </Grid>
          <Grid item xs={12} sm={6}>
            {createCheckboxField({
              label: 'Brokered',
              fieldName: 'brokered',
              store: formStore,
              disabled: readOnly,
            })}
          </Grid>
          <StandardDialog
            open={openSendBulkMessagesModal}
            title={connectedWithRingCentral ? ' ' : 'Send SMS'}
            maxWidth="lg"
            handleClose={closeModal}
            dialogContent={
              <SendBulkMessagesModal
                handleClose={closeModal}
                salesforceLeadStore={salesforceLeadStore}
                type={LEAD_OBJECT_TYPE}
              />
            }
          />
          <StandardDialog
            open={openSendBulkEmailModal}
            title={'Send Bulk Emails'}
            maxWidth="xl"
            handleClose={closeBulkEmailModal}
            dialogContent={
              <SendBulkEmailsModal
                handleClose={closeBulkEmailModal}
                salesforceLeadStore={salesforceLeadStore}
                type={LEAD_OBJECT_TYPE}
              />
            }
          />
          <Dialog open={openModal} onClose={handleCloseModal}>
            <DialogTitle>Convert Lead to Lender</DialogTitle>
            <DialogContent>
              <ConvertLeadToLenderModal record={leadDetailsObject} />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleCloseModal}
                color="primary"
                testId="cancel"
              >
                Cancel
              </Button>
              <Button
                onClick={convertLeadToLender}
                color="primary"
                testId="cancel"
              >
                Submit
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
        {onClose && onSave && (
          <Box pt={4} display="flex" justifyContent="flex-end">
            <Button
              style={{ marginRight: '8px' }}
              onClick={onClose}
              color="primary"
              variant="outlined"
              testId="cancel"
            >
              Cancel
            </Button>
            <Button
              onClick={onSave}
              color="primary"
              variant="contained"
              testId="save"
            >
              Apply Changes
            </Button>
          </Box>
        )}
      </Card>
    );
  }
);
