import React from 'react';
import { Grid } from '@material-ui/core';
import { observer } from 'mobx-react';
import { EmailAddress } from '@roc/ui/formComponents';
import { FormStore } from '@roc/feature-app-core';

interface ResendInsuranceQuoteFormProps {
  store: FormStore;
}

export const ResendInsuranceQuoteForm = observer(
  (props: ResendInsuranceQuoteFormProps) => {
    const { store } = props;

    return (
      <Grid>
        <EmailAddress store={store} />
      </Grid>
    );
  }
);
