import {
  Box,
  ButtonGroup,
  Tooltip
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { FileCopyOutlined, Reply } from '@material-ui/icons';
import { IconButton } from '@roc/ui';
import { Message } from '@twilio/conversations';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import { ReactNode } from 'react';

const useStyles = makeStyles(theme => ({
  hoverZone: {
    padding: 8,
  },
  hoverMenu: {
    display: 'none',
    justifyContent: 'flex-end',
    [`$hoverZone:hover &`]: {
      display: 'flex',
    },
  },
  hoverMenuContent: {
    zIndex: 1,
    marginTop: '-24px',
    border: '1px solid #ccc',
    position: 'absolute',
    background: 'white',
    boxShadow: '0 0 0 1px var(--saf-0),0 1px 3px 0 #00000014',
    borderRadius: '5px',
  },
  firstMessage: {
    marginTop: '-24px',
  },
  button: {
    height: '32px',
    padding: theme.spacing(0, 1),
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.text.primary,
    margin: 'auto'
  },
  icon: {
    fontSize: '15px',
  },
  text: {
    fontSize: '12px',
    fontWeight: 'bold',
    paddingLeft: theme.spacing(0.5),
  },
  buttonGroup: {
    minWidth: 'auto',
  },
}));

interface MessageHoverMenuProps {
  firstMessage: boolean;
  message: Message;
  children: ReactNode;
  onReplyClick: () => void;
}

export const MessageHoverMenu = observer((props: MessageHoverMenuProps) => {
  const { children, message, firstMessage, onReplyClick } = props;
  const classes = useStyles();

  const onCopy = () => {
    const messageEl: HTMLElement = document.getElementById(`${message.sid}`);
    const shadowRoot: ShadowRoot = messageEl?.shadowRoot;

    const el = shadowRoot ? shadowRoot : messageEl;
    const innerText = shadowRoot ? (shadowRoot.querySelector('.root') as any).innerText : messageEl.innerText;

    const data = [new (window as any).ClipboardItem({
      ['text/html']: new Blob([el.innerHTML], { type: 'text/html' }),
      ['text/plain']: new Blob([innerText], { type: 'text/plain' })
    })];
    (window as any).navigator.clipboard.write(data);
  };

  return (
    <Box className={classes.hoverZone}>
      <Box className={classes.hoverMenu}>
        <Box className={clsx(classes.hoverMenuContent, firstMessage && classes.firstMessage)}>
          <ButtonGroup color="default" classes={{
            grouped: classes.buttonGroup,
          }}>
            <Tooltip title="Reply" placement="top" arrow>
              <div>
                <IconButton
                  testId='replyButton'
                  className={classes.button}
                  onClick={onReplyClick}
                  size={'small'}
                >
                  <Reply className={classes.icon} />
                </IconButton>
              </div>
            </Tooltip>
            <Tooltip title="Copy" placement="top" arrow>
              <div>
                <IconButton
                  testId='copyButton'
                  className={classes.button}
                  onClick={onCopy}
                  size={'small'}
                >
                  <FileCopyOutlined className={classes.icon} />
                </IconButton>
              </div>
            </Tooltip>
          </ButtonGroup>
        </Box>
      </Box>
      {children}
    </Box>
  );
});
