import { Box } from '@material-ui/core';
import { StepperComponent } from '@roc/ui';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useLoanStore } from '@roc/feature-loans';
import { LoanStatus } from '@roc/feature-utils';
import { Cancel, CancelOutlined, CheckCircle, CheckRounded, Close, ErrorOutline, RadioButtonChecked } from '@material-ui/icons';
import {
  stepsByClosingStatus,
  stepsByLoanStatus,
} from '../utils/loanDetailsConstants';
import { ClosingStage } from '../utils/loanDetailsTypes';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => ({
  stepper: {
    padding: theme.spacing(2.5),
    margin: 'auto',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5),
    },

    '& .MuiStepIcon-root': {
      width: '32px',
      height: '32px',
    },
    '& .MuiStepIcon-text': {
      // Hide numbers inside
      fill: 'transparent',
    },
    '& .MuiStepConnector-alternativeLabel': {
      top: '15px',
    },
    '& .MuiStepConnector-active .MuiStepConnector-lineHorizontal, & .MuiStepConnector-completed .MuiStepConnector-lineHorizontal': {
      borderColor: theme.palette.primary.main,
    },
    '& .MuiStepConnector-lineHorizontal': {
      borderTopWidth: '3px',
      margin: '0 -4px',
    },
    '& .MuiStepLabel-labelContainer': {
      '& .MuiStepLabel-label': {
        marginTop: '6px',
      },
      '& span': {
        color: '#303030 !important',
        fontSize: '14px !important',
        fontWeight: 'normal !important',
      },
    },
  },
  statusStepIcon: {
    width: '32px',
    height: '32px',
    color: theme.palette.primary.main,
    '& svg': {
      backgroundColor: 'transparent !important',
      fontSize: '38px',
      margin: '-3px -4px',
    },
  },
  pendingSubmissionStepIcon: {
    '& svg': {
      color: `#83000c !important`,
    },
  },
  negativeStepIcon: {
    '& svg': {
      color: '#83000c !important',
    },
  },
  activeStepIcon: {
    '& svg': {
      color: `${theme.palette.primary.main} !important`,
    },
  },
  completedStepIcon: {
    '& svg': {
      color: `${theme.palette.primary.main} !important`,
    },
  },
}));

export const LoanDetailsStepper = observer(
  ({ stepsByStatus, currentStatus }) => {
    const classes = useStyles();

    const steps = stepsByStatus?.[currentStatus] ?? [];

    const getIcon = (step) => {
      if (step.warning) {
        return <StatusIcon icon={<ErrorOutline />} className={clsx(classes.statusStepIcon, classes.pendingSubmissionStepIcon)} />
      }
      else if (step.negative) {
        return <StatusIcon icon={<CancelOutlined />} className={clsx(classes.statusStepIcon, classes.negativeStepIcon)} />
      }
      else if (step.active) {
        return <StatusIcon icon={<RadioButtonChecked />} className={clsx(classes.statusStepIcon, classes.activeStepIcon)} />
      }
      else if (step.completed) {
        return <StatusIcon icon={<CheckCircle />} className={clsx(classes.statusStepIcon, classes.completedStepIcon)} />
      }
      return null;
    }

    return (
      <>
        <StepperComponent
          activeStep={steps.findLastIndex(s => s.completed)}
          disableNavigation={true}
          handleStep={() => { }}
          steps={steps.map(step => ({
            ...step,
            icon: getIcon(step),
            onBack: () => { },
            onNext: () => { },
            allowBack: false,
            allowNext: true,
          }))}
          className={classes.stepper}
          alternativeLabel
        />
      </>
    );
  }
);

const StatusIcon = ({
  icon,
  className
}) => {
  const classes = useStyles();
  return (
    <Box className={className}>
      {icon}
    </Box>
  );
};

export const LoanStatusStepper = observer(() => {
  const { loanStore } = useLoanStore();
  const { loanDetails } = loanStore;

  return (
    <LoanDetailsStepper
      stepsByStatus={stepsByLoanStatus}
      currentStatus={loanDetails?.status}
    />
  );
});

export const ClosingStatusStepper = observer(() => {
  const { loanStore } = useLoanStore();

  const closingStage: string =
    (loanStore.closingRequirements?.loanClosingStage as any) ??
    ClosingStage.UNDEFINED;

  return (
    <LoanDetailsStepper
      stepsByStatus={stepsByClosingStatus}
      currentStatus={closingStage?.trim()}
    />
  );
});
