import {
  Grid,
  List,
  ListItem,
  ListItemText,
  Divider,
  Button,
} from '@material-ui/core';
import { observer } from 'mobx-react';
import { useProposalsStore } from '../../hooks/useProposalsStore';
import { Paper, TestDataAttribute, ScreenshotGrid } from '@roc/ui';
import { Box } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { useEffect, useRef } from 'react';
import { formatCurrency } from '@roc/ui/utils';
import { sanitizeTestId } from '@roc/feature-utils';
import GetAppIcon from '@material-ui/icons/GetApp';

const testId = sanitizeTestId;
const listItem = (label, value, component?, divider: boolean = true) => {
  return (
    <>
      <ListItem>
        <ListItemText>
          <Box display="flex">
            <Box flexGrow={1}>{label}</Box>
            <span />
            <TestDataAttribute id={testId(label)}>
              <Box>{component || value}</Box>
            </TestDataAttribute>
          </Box>
        </ListItemText>
      </ListItem>
    </>
  );
};

const divider = <Divider variant="middle" />;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      margin: theme.spacing(3),
      marginLeft: theme.spacing(0),
      borderRadius: theme.spacing(2),
      boxShadow:
        'rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px',
      padding: '16px',
    },
    label: {
      paddingLeft: '10px',
    },
    summaryPaper: {
      width: '50%',
    },
    headerButton: {
      border: 0,
      color: theme.palette.grey[600],
      '&:hover': {
        border: 0,
      },
      '&.Mui-disabled': {
        border: 0,
      },
    },
  })
);

const DealSummaryTab = observer(() => {
  const classes = useStyles();
  const { proposalsStore } = useProposalsStore();
  const { proposal } = proposalsStore.detailsStore;
  const screenshotContainerRef = useRef(null);

  useEffect(() => { }, [proposal]);

  return (
    <ScreenshotGrid fileName={`DealEconomics.pdf`} ref={screenshotContainerRef}>
      <Box display="flex" justifyContent="flex-end">
        <Button
          data-html2canvas-ignore
          className={classes.headerButton}
          startIcon={<GetAppIcon />}
          variant="outlined"
          onClick={() => screenshotContainerRef.current.download()}
        >
          Capture Deal Economics as PDF
        </Button>
      </Box>
      <Grid container>
        <Grid item xs={6}>
          <Paper title="Acquisition Costs" className={classes.paper}>
            <List>
              {listItem(
                'Purchase Price',
                formatCurrency(
                  proposal?.calculatedLoanData?.dealEconomics.purchasePrice
                )
              )}
              {divider}
              {listItem(
                'Legal fees',
                `${!proposal?.calculatedLoanData?.dealEconomics?.legalFees
                  ? '--'
                  : formatCurrency(
                    proposal?.calculatedLoanData?.dealEconomics?.legalFees
                  )
                }`
              )}
              {divider}
              {listItem(
                'Mortgage Recording Tax',
                `${proposal?.calculatedLoanData?.dealEconomics
                  ?.mortgageRecordingTax
                  ? '--'
                  : formatCurrency(
                    proposal?.calculatedLoanData?.dealEconomics
                      ?.mortgageRecordingTax
                  )
                }`
              )}
              {divider}
              {listItem(
                'Filing Fees',
                `${!proposal?.calculatedLoanData?.dealEconomics?.filingFees
                  ? '--'
                  : formatCurrency(
                    proposal?.calculatedLoanData?.dealEconomics?.filingFees
                  )
                }`
              )}
              {divider}
              {listItem(
                'Appraisal',
                `${!proposal?.calculatedLoanData?.dealEconomics?.appraisal
                  ? '--'
                  : formatCurrency(
                    proposal?.calculatedLoanData?.dealEconomics?.appraisal
                  )
                }`
              )}
              {divider}
              {listItem(
                'PL Legal Fee',
                `${!proposal?.calculatedLoanData?.dealEconomics?.pllegalFee
                  ? '--'
                  : formatCurrency(
                    proposal?.calculatedLoanData?.dealEconomics?.pllegalFee
                  )
                }`
              )}
              {divider}
              {listItem(
                'Title Insurance',
                `${!proposal?.calculatedLoanData?.dealEconomics?.titleInsurance
                  ? '--'
                  : formatCurrency(
                    proposal?.calculatedLoanData?.dealEconomics
                      ?.titleInsurance
                  )
                }`
              )}
              {divider}
              {listItem(
                'Origination Points',
                `${!proposal?.calculatedLoanData?.dealEconomics
                  ?.originationPoints
                  ? '--'
                  : formatCurrency(
                    proposal?.calculatedLoanData?.dealEconomics
                      ?.originationPoints
                  )
                }`
              )}
            </List>
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper title="Total Cost" className={classes.paper}>
            <List>
              {listItem(
                'Purchase Price',
                `${!proposal?.calculatedLoanData?.dealEconomics?.purchasePrice
                  ? '--'
                  : formatCurrency(
                    proposal?.calculatedLoanData?.dealEconomics
                      ?.purchasePrice
                  )
                }`
              )}
              {divider}
              {listItem(
                'Renovation',
                `${!proposal?.calculatedLoanData?.dealEconomics?.renovationBudget
                  ? '--'
                  : formatCurrency(
                    proposal?.calculatedLoanData?.dealEconomics
                      ?.renovationBudget
                  )
                }`
              )}
              {divider}
              {listItem(
                'Interest & Origination Points',
                `${!proposal?.calculatedLoanData?.dealEconomics
                  ?.interestAndOriginationPoints
                  ? '--'
                  : formatCurrency(
                    proposal?.calculatedLoanData?.dealEconomics
                      ?.interestAndOriginationPoints
                  )
                }`
              )}
              {divider}
              {listItem(
                'Property Tax & Maintenance',
                `${!proposal?.calculatedLoanData?.dealEconomics
                  ?.propertyTaxAndMaintenanceAmount
                  ? '--'
                  : formatCurrency(
                    proposal?.calculatedLoanData?.dealEconomics
                      ?.propertyTaxAndMaintenanceAmount
                  )
                }`
              )}
              {divider}
              {listItem(
                'Taxes and Other Costs',
                `${!proposal?.calculatedLoanData?.dealEconomics
                  ?.taxesAndOtherCosts
                  ? '--'
                  : formatCurrency(
                    proposal?.calculatedLoanData?.dealEconomics
                      ?.taxesAndOtherCosts
                  )
                }`
              )}
              {divider}
              {listItem(
                'Total',
                `${!proposal?.calculatedLoanData?.dealEconomics?.totalCosts
                  ? '--'
                  : formatCurrency(
                    proposal?.calculatedLoanData?.dealEconomics?.totalCosts
                  )
                }`
              )}
            </List>
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper
            title="Sale Costs (Paid from Proceeds of Sale)"
            className={classes.paper}
          >
            <List>
              {listItem(
                'Broker Fee',
                `${!proposal?.calculatedLoanData?.dealEconomics?.brokerFee
                  ? '--'
                  : formatCurrency(
                    proposal?.calculatedLoanData?.dealEconomics?.brokerFee
                  )
                }`
              )}
              {divider}
              {listItem(
                'Transfer Tax',
                `${!proposal?.calculatedLoanData?.dealEconomics?.transferTax
                  ? '--'
                  : formatCurrency(
                    proposal?.calculatedLoanData?.dealEconomics?.transferTax
                  )
                }`
              )}
              {divider}
              {listItem(
                'Mansion Tax',
                `${proposal?.calculatedLoanData?.dealEconomics?.mansionTax
                  ? '--'
                  : formatCurrency(
                    proposal?.calculatedLoanData?.dealEconomics?.mansionTax
                  )
                }`
              )}
              {divider}
              {listItem(
                'Legal',
                `${!proposal?.calculatedLoanData?.dealEconomics?.legalFees
                  ? '--'
                  : formatCurrency(
                    proposal?.calculatedLoanData?.dealEconomics?.legalFees
                  )
                }`
              )}
              {divider}
              {listItem(
                'Filing Fees',
                `${!proposal?.calculatedLoanData?.dealEconomics?.filingFees
                  ? '--'
                  : formatCurrency(
                    proposal?.calculatedLoanData?.dealEconomics?.filingFees
                  )
                }`
              )}
              {divider}
              {listItem(
                'total',
                `${!proposal?.calculatedLoanData?.dealEconomics?.totalSaleCosts
                  ? '--'
                  : formatCurrency(
                    proposal?.calculatedLoanData?.dealEconomics
                      ?.totalSaleCosts
                  )
                }`
              )}
            </List>
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper title="Rent Economics" className={classes.paper}>
            <List>
              {listItem(
                'Appaiser Opex',
                `${!proposal?.calculatedLoanData?.dealEconomics
                  ?.appraiserOperationalExpense
                  ? '--'
                  : proposal?.calculatedLoanData?.dealEconomics
                    ?.appraiserOperationalExpense
                } %`
              )}
              {divider}
              {listItem(
                'Monthly Payment',
                `${!proposal?.calculatedLoanData?.dealEconomics?.monthlyPayment
                  ? '--'
                  : formatCurrency(
                    proposal?.calculatedLoanData?.dealEconomics
                      ?.monthlyPayment
                  )
                }`
              )}
              {divider}
              {listItem(
                'DSCR',
                `${!proposal?.calculatedLoanData?.dealEconomics?.dscr
                  ? '--'
                  : proposal?.calculatedLoanData?.dealEconomics?.dscr

                }`
              )}
            </List>
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper title="Iterim Expenses" className={classes.paper}>
            <List>
              {listItem(
                'Renovation',
                `${!proposal?.calculatedLoanData?.dealEconomics?.renovationBudget
                  ? '--'
                  : formatCurrency(
                    proposal?.calculatedLoanData?.dealEconomics
                      ?.renovationBudget
                  )
                }`
              )}
              {divider}
              {listItem(
                'Interest',
                `${!proposal?.calculatedLoanData?.dealEconomics?.interestAmount
                  ? '--'
                  : formatCurrency(
                    proposal?.calculatedLoanData?.dealEconomics
                      ?.interestAmount
                  )
                }`
              )}
              {divider}
              {listItem(
                'Property Tax & Maintenance	',
                `${!proposal?.calculatedLoanData?.dealEconomics
                  ?.propertyTaxAndMaintenanceAmount
                  ? '--'
                  : formatCurrency(
                    proposal?.calculatedLoanData?.dealEconomics
                      ?.propertyTaxAndMaintenanceAmount
                  )
                }`
              )}
              {divider}
              {listItem(
                'Total',
                `${!proposal?.calculatedLoanData?.dealEconomics
                  ?.totalInterimExpenses
                  ? '--'
                  : formatCurrency(
                    proposal?.calculatedLoanData?.dealEconomics
                      ?.totalInterimExpenses
                  )
                }`
              )}
            </List>
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper title="Flip Economics" className={classes.paper}>
            <List>
              {listItem(
                'Total Profit on Flip',
                formatCurrency(
                  proposal?.calculatedLoanData?.dealEconomics.totalProfitOnFlip
                )
              )}
              {divider}
              {listItem(
                'Borrower Cash In Deal At Purchase',
                `${!proposal?.calculatedLoanData?.dealEconomics
                  ?.borrowerCashInDealAtPurchase
                  ? '--'
                  : formatCurrency(
                    proposal?.calculatedLoanData?.dealEconomics
                      ?.borrowerCashInDealAtPurchase
                  )
                }`
              )}
              {divider}
              {listItem(
                'Borrower Cash in Deal (Months)',
                `${!proposal?.calculatedLoanData?.dealEconomics
                  ?.borrowerCashInDeal
                  ? '--'
                  : formatCurrency(
                    proposal?.calculatedLoanData?.dealEconomics
                      ?.borrowerCashInDeal
                  )
                }`
              )}
              {divider}
              {listItem(
                'Borrower ROI on Cash in Deal (Months)',
                `${!proposal?.calculatedLoanData?.dealEconomics
                  ?.borrowerROIOnCashInDeal
                  ? '--'
                  : proposal?.calculatedLoanData?.dealEconomics
                    ?.borrowerROIOnCashInDeal
                } %`
              )}
            </List>
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper title="Borrower Profit/Loss" className={classes.paper}>
            <List>
              {listItem(
                'Sales Price',
                formatCurrency(
                  proposal?.calculatedLoanData?.dealEconomics.salesPrice
                )
              )}
              {divider}
              {listItem(
                'Costs',
                `${!proposal?.calculatedLoanData?.dealEconomics?.totalCosts
                  ? '--'
                  : formatCurrency(
                    proposal?.calculatedLoanData?.dealEconomics?.totalCosts
                  )
                }`
              )}
              {divider}
              {listItem(
                'Total',
                `${!proposal?.calculatedLoanData?.dealEconomics
                  ?.borrowerProfitOrLoss
                  ? '--'
                  : formatCurrency(
                    proposal?.calculatedLoanData?.dealEconomics
                      ?.borrowerProfitOrLoss
                  )
                }`
              )}
            </List>
          </Paper>
        </Grid>
      </Grid>
    </ScreenshotGrid>
  );
});

export default DealSummaryTab;
