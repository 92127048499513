import React from 'react';

import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Entity } from '@roc/feature-types';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { Paper, TestDataAttribute } from '@roc/ui';
import { sanitizeTestId } from '@roc/feature-utils';

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  paperNoShadow: {
    boxShadow: 'none',
  },
}));

const listItem = (label, value) => {
  const testId = sanitizeTestId(label);
  return (
    <ListItem>
      <ListItemText>
        <Box display="flex">
          <Box flexGrow={1}>{label}</Box>
          <span />
          <TestDataAttribute id={testId}>
            <Box>{value}</Box>
          </TestDataAttribute>
        </Box>
      </ListItemText>
    </ListItem>
  );
};

const divider = <Divider variant="middle" component="li" />;

interface EntityCardProps {
  entity: Entity;
  onEdit?: (value: string) => void;
  onRemovew?: (value: string) => void;
  title?: string;
  shadow?: boolean;
}

export const EntityCard: React.FC<EntityCardProps> = ({
  entity,
  title,
  shadow,
}) => {
  const classes = useStyles();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box>
          <Paper title={title} className={!shadow ? classes.paperNoShadow : ''}>
            <List>
              {listItem('Entity Name', entity.company?.name)}
              {divider}
              {listItem('Entity EIN', entity.ein || entity.company?.einNumber)}
              {divider}
              {listItem('Entity Type', entity.type || entity.company?.entityType)}
            </List>
          </Paper>
        </Box>
      </Grid>
    </Grid>
  );
};
