import { FormStore } from '@roc/feature-app-core';
import { GlobalStore } from '@roc/feature-app-core';

const form = {
  fields: {
    settlementDate: {
      value: null,
      error: null,
      rule: '',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export class ForeclosureDetailsFormStore extends FormStore {
  globalStore: GlobalStore;
  constructor(globalStore: GlobalStore) {
    super({ ...form }, globalStore);
    this.globalStore = globalStore;
  }

  reset = () => {
    super.reset();
  };
}