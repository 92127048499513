import { useCallback, useEffect, useRef, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Tooltip, TooltipProps } from './tooltip';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => ({
  span: {
    overflow: 'hidden',
  },
}));

interface TooltipOnOverflowProps extends TooltipProps {
  spanClass?: string;
}

export const TooltipOnOverflow = (props: TooltipOnOverflowProps) => {
  const classes = useStyles();
  const { title, children, spanClass, ...muiTooltipProps } = props;
  const ref = useRef<HTMLElement>();
  const [overflow, setOverflow] = useState(false);

  const checkOverflow = useCallback(() => {
    const el = ref.current.children?.[0];
    setOverflow(el && el?.scrollWidth > el?.clientWidth);
  }, []);

  useEffect(() => {
    checkOverflow();
  }, [ref.current, title, children]);

  useEffect(() => {
    addEventListener('resize', checkOverflow);
    return () => removeEventListener('resize', checkOverflow);
  }, []);

  return (
    <span className={clsx(classes.span, spanClass)} ref={ref}>
      {overflow ? (
        <Tooltip title={overflow ? title : undefined} {...muiTooltipProps}>
          {children}
        </Tooltip>
      ) : (
        children
      )}
    </span>
  );
};
