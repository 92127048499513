import { useContext } from 'react';
import { StoreContext } from '@roc/feature-app-core';
import { servicerLoansDashboardStore } from '../stores/servicerLoansDashboardStore';
import { PayoffNewRequestStore } from '../stores/payoffNewRequestStore';
import { FundedForeclosureLoansForPayoffGridStore } from '../stores/fundedForeclosureLoansForPayoffGridStore';

export const useServicerLoansStore = () =>
  useContext<{
    servicerLoansDashboardStore: servicerLoansDashboardStore;
    payoffNewRequestStore: PayoffNewRequestStore;
    fundedForeclosureLoansForPayoffGridStore: FundedForeclosureLoansForPayoffGridStore;
  }>(StoreContext);
