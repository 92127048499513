import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

export const useBorrowerPortalMediaQueries = () => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md')); // >960px
  const isMediumScreen = useMediaQuery(theme.breakpoints.only('sm')); // >600px <960px
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs')); // <600px

  return {
    isLargeScreen,
    isMediumScreen,
    isSmallScreen
  };

}