import { Service } from './service';

const url = {
  /*USER_PROFILE: '/nportal/rest/priv/getUserProfile',*/
  GET_DEPLOY_ENVIRONMENT: '/api/v1/user/public/getDeployEnvironment',
  USER_INFORMATION: '/api/v1/user/getUser',
  ENTITIES:
    '/nportal/rest/priv/borrowerEntities/getMyBorrowerEntitiesForBorrower',
  BORROWERS: '/nportal/rest/priv/borrowerEntities/getMyBorrowersForBorrower',
  USER_INFO_ENTITLEMENTS: '/api/v1/user/getUserInfoAndEntitlements',
  SAVE_USER_PREFERENCE: '/api/v1/user/saveUserPreference',
  SWITCH_USER_PRIMARY_COMPANY: '/api/v1/user/switchUserPrimaryCompany',
  SEND_ERROR_EMAIL: '/api/v1/user/sendErrorEmail',
  UPDATE_ALLOWED_CHAT_ROOM_TYPES: '/api/v1/user/updateAllowedChatRoomTypes',
  UPDATE_SELECTED_COMPANIES_NOTIFICATIONS: '/api/v1/user/updateSelectedCompaniesForNotifications',
  SUBMIT_USER_RATING: '/api/v1/user/ratings',
  FETCH_RATINGS_BY_CHAT_ROOM_AND_USER: '/api/v1/user/ratings/getByChatRoomIdAndUserId',
  GET_LENDER_DROPDOWN_OPTIONS_FOR_BACKOFFICE: '/api/v1/user/getLenderDropdownOptionsForBackoffice',
  GET_USER_INFO: '/api/v1/user/getUserInfo',
};

export class UserService extends Service {
  /*getUserProfile() {
    return this.get(url.USER_PROFILE);
  }*/
  getDeployEnvironment() {
    return this.get(url.GET_DEPLOY_ENVIRONMENT);
  }
  getUserInformation() {
    return this.get(url.USER_INFORMATION);
  }
  getEntities() {
    return this.get(url.ENTITIES);
  }
  getBorrowers() {
    return this.get(url.BORROWERS);
  }
  getUserInfoAndEntitlements() {
    return this.get(url.USER_INFO_ENTITLEMENTS);
  }
  saveUserPreference(attributes) {
    return this.patch(url.SAVE_USER_PREFERENCE, {
      attributes,
    });
  }

  updateAllowedChatRoomTypes(userId, allowedChatRoomTypes) {
    return this.post(url.UPDATE_ALLOWED_CHAT_ROOM_TYPES, {
      userId,
      allowedChatRoomTypes,
    });
  }

  updateSelectedCompaniesForNotifications(selectedCompanies: number[]) {
    return this.post(url.UPDATE_SELECTED_COMPANIES_NOTIFICATIONS, selectedCompanies);
  }

  sendErrorEmail(subject: string, body: string) {
    return this.post(url.SEND_ERROR_EMAIL, {
      subject,
      body,
    });
  }

  switchPrimaryCompany(body) {
    return this.post(url.SWITCH_USER_PRIMARY_COMPANY, body);
  }

  submitUserRating(userRatingRequest) {
    return this.post(
      url.SUBMIT_USER_RATING,
      userRatingRequest,
      {},
      {
        disableGlobalLoading: true
      }
    );
  }

  fetchRatingsByChatRoomIdAndUserId(chatRoomId, userId) {
    return this.get(
      url.FETCH_RATINGS_BY_CHAT_ROOM_AND_USER,
      {
        chatRoomId,
        userId,
      },
      {
        disableGlobalLoading: true,
      });
  }

  getLenderDropdownOptionsForBackoffice() {
    return this.get(url.GET_LENDER_DROPDOWN_OPTIONS_FOR_BACKOFFICE);
  }

  getUserInfo() {
    return this.get(url.GET_USER_INFO);
  }
}
