import { Box, Grid } from '@material-ui/core';
import { useLoanStore } from '@roc/feature-loans';
import { observer } from 'mobx-react';
import { useLoanFlags } from '../../hooks/useLoanFlags';
import { BrokerCard } from '../cards/brokerCard';
import { CommissionsCard } from '../cards/commissionsCard';
import { EntityCard } from '../cards/entityCard';
import { InsuranceCard } from '../cards/insuranceCard';
import { KeyDatesCardTerm } from '../cards/keyDatesCard';
import { LoanContactsCard } from '../cards/loanContactsCard';
import { LoanSummaryTodosCard } from '../cards/loanSummaryTodosCard';
import { OriginatorsCard } from '../cards/originatorsCard';
import { TermPropertiesGridCard } from '../cards/propertiesGridCard';
import { TermsCard } from '../cards/termsCard';
import { LoanDetailsPaper } from '../loanDetailsPaper';
import { LoanStatusStepper } from '../loanDetailsStepper';
import { GeneralStatsCardTerm } from './generalStatsCardTerm';
import { LoanDetailsCardTerm } from './loanDetailsCardTerm';
import { LoanTermsCardTerm } from './loanTermsCardTerm';
import { PointsAndFeesCardTerm } from './pointsAndFeesCardTerm';
import { SubmissionVsUnderwritingValuesCardTerm } from './submissionVsUnderwritingValuesCardTerm';
import { PricingAndRateCard } from '../cards/pricingAndRateCard';
import { EstimatedClosingCosts } from '../cards/estimatedClosingCosts';
import { useBaseStore } from '@roc/feature-app-core';
import { useSubmissionVsUnderwritingStore } from '../../hooks/useSubmissionVsUnderwritingStore';
import { BorrowerLiquidityCard } from '../cards/borrowerLiquidityCard';
import { LoanSubType } from '@roc/feature-utils';

export const LoanSummaryTerm = observer(() => {
  const {
    showOriginatorsCard,
    showCommissionsCard,
    allowLoanPricing,
  } = useLoanFlags();
  const { globalStore } = useBaseStore();
  const { submissionVsUnderwritingStore } = useSubmissionVsUnderwritingStore();

  const isEstimatedClosingCostEnabled = globalStore.userFeatures.isEstimatedClosingCostEnabled;
  const { loanStore } = useLoanStore();
  const { loanDetails } = loanStore;
  const showBorrowerLiquidityCard = loanDetails.loanSubType === LoanSubType.SINGLE_PROPERTY;

  return (
    <>
      <Grid item xs={12}>
        <LoanContactsCard />
      </Grid>
      <Grid item xs={12}>
        <LoanDetailsPaper>
          <LoanStatusStepper />
        </LoanDetailsPaper>
      </Grid>
      <Grid item xs={12}>
        <LoanDetailsCardTerm />
      </Grid>
      <Grid item xs={12}>
        <LoanSummaryTodosCard />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Box height={'100%'} display={'flex'} flexDirection={'column'}>
              <Grid item xs={12}>
                <GeneralStatsCardTerm />
              </Grid>
              <Box pt={2} flexGrow={1}>
                <EntityCard />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box height={'100%'} display={'flex'} flexDirection={'column'}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <KeyDatesCardTerm />
                </Grid>
                {showOriginatorsCard && (
                  <Grid item xs={12}>
                    <OriginatorsCard />
                  </Grid>
                )}
              </Grid>
              <Box pt={2} flexGrow={1}>
                <BrokerCard />
              </Box>
              {showBorrowerLiquidityCard && (
                <Box pt={2}>
                  <BorrowerLiquidityCard />
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <PricingAndRateCard />
      </Grid>
      <Grid item xs={12}>
        <TermPropertiesGridCard />
      </Grid>
      <Grid item xs={12}>
        <LoanTermsCardTerm />
      </Grid>
      {allowLoanPricing && (
        <Grid item xs={12}>
          <PointsAndFeesCardTerm />
        </Grid>
      )}
      <Grid item xs={12}>
        <SubmissionVsUnderwritingValuesCardTerm isInternal={false} loanId={null} store={submissionVsUnderwritingStore} loanDetails={loanDetails} />
      </Grid>
      {showCommissionsCard && (
        <Grid item xs={12}>
          <CommissionsCard />
        </Grid>
      )}
      {isEstimatedClosingCostEnabled && (
        <Grid item xs={12}>
          <EstimatedClosingCosts />
        </Grid>
      )}
    </>
  );
});
