import { PlaidService } from "../services/plaidService";
import { GridStore, ApiResponse } from '@roc/feature-app-core';
import { GlobalStore } from "@roc/feature-app-core";
import { makeObservable, observable, flow } from "mobx";

export class PlaidStore {
  globalStore: GlobalStore;
  plaidService: PlaidService;
  plaidGrid: GridStore;
  linkToken: string;
  borrower: string;
  requestSent: boolean;
  registrationConfirmModal: boolean;
  unlinkModalOpen: boolean;
  selectedAccountId: string;
  isPublic: boolean;
  isDisabledLinked: boolean;
  constructor(globalStore) {
    this.globalStore = globalStore;
    this.isPublic = false;
    this.isDisabledLinked = false;
    this.plaidService = new PlaidService();
    this.plaidGrid = new GridStore(
      () => this.fetchPlaidGrid(),
      null,
      4,
    );

    makeObservable(this, {
      linkToken: observable,
      requestSent: observable,
      unlinkModalOpen: observable,
      fetchLinkToken: flow,
      registrationConfirmModal: observable
    });
  }

  fetchLinkToken = async () => {
    try {
      const response = await this.plaidService.getLinkToken()
      this.linkToken = response.data.link_token;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: `Error while retrieving link token`,
      });
    }
  };

  exchangeToken = async (publicToken) => {
    try {
      const response : ApiResponse = await this.plaidService.getAccessToken(publicToken);

      this.globalStore.notificationStore.showSuccessNotification({
          message: `Accounts Linked Successfully`,
        });

      return response;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: `Error while exchanging token`,
      });
    }
  }

  setBorrower = async (encodedParams) => {
    this.borrower = encodedParams;
  }

  fetchPlaidGrid = async () => {
    try {
      const response = await this.plaidService.getPlaidGrid(
        this.plaidGrid.gridData.meta.pageNumber,
        this.plaidGrid.gridData.meta.pageSize,
        this.plaidGrid.gridData.meta.sortDir,
        this.plaidGrid.gridData.meta.sortBy,
        this.plaidGrid.gridData.meta.filters
      );
      return response;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching plaid accounts',
      });
    }
  }
  unlinkAccount = () => {
    this.unlinkModalOpen = false;
    this.plaidService.unlinkAccount(this.selectedAccountId)
      .then(response => { this.plaidGrid.resetAndFetchGridData() })
      .then(response => {
        this.globalStore.notificationStore.showSuccessNotification({
          message: `Accounts Unlinked Successfully`,
        })
      })
      .catch(error => {
        this.globalStore.notificationStore.showErrorNotification({
          message: `Error while unlinking account`,
        });
      });
  }

  setUnlinkModalOpen = (param) => {
    this.unlinkModalOpen = param;
  }

  setAccountId = (accountId) => {
    this.selectedAccountId = accountId;
  }
}
