import { Page } from '../../../components';
import { Route } from 'react-router';
import { LenderDetails } from '../lenderDetails';

export const getLenderDetailsRoutesConfig = (
  basePath: string,
  baseUrl: string
) => {
  return {
    lenderDetails: {
      path: `${basePath}/lender-details`,
      url: `${baseUrl}/lender-details`,
      documentTitle: 'Lender Details',
    },
  };
};

export const getLenderDetailsRoutes = (basePath: string, baseUrl: string) => {
  const config = getLenderDetailsRoutesConfig(basePath, baseUrl);
  return {
    lenderDetails: (
      <Route exact path={config.lenderDetails.path}>
        <Page routeDefinition={config.lenderDetails}>
          <LenderDetails />
        </Page>
      </Route>
    ),
  };
};
