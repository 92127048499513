import { Page } from '@roc/feature-app-core';
import { Route } from 'react-router';
import { RentalPortfolio } from '../rentalPortfolio';
import { SingleProperty } from '../singleProperty';
import { FixFlip, GroundUp } from '../fixflipGroundUp';
import { FixFlipPro } from '../fixflipGroundUp/fixFlipPro';
import { NewLoanSelection } from '../newLoanSelection';
import { MultifamilyBridge } from '../multifamilyBridge/multifamilyBridge';
import { LoanSubType } from '@roc/feature-utils';
import { StabilizedBridge } from '../stabilizedBridge/stabilizedBridge';
import { ProductSelector } from 'libs/feature-one-tool/src/quote/productSelector';

const SUBMITLOAN_BASE_PATH = 'submitloan';

export const routeByLoanSubType = {
  [LoanSubType.FIX_AND_FLIP]: 'fixFlip',
  [LoanSubType.FIX_AND_FLIP_PRO]: 'fixFlipPro',
  [LoanSubType.GROUND_UP]: 'groundUp',
  [LoanSubType.MULTIFAMILY_BRIDGE_5PLUS]: 'multifamilyBridge',
  [LoanSubType.SINGLE_PROPERTY]: 'singleProperty',
  [LoanSubType.SINGLE_PROPERTY_MULTIFAMILY]: 'singleProperty',
  [LoanSubType.SINGLE_PROPERTY_MIXED_USE]: 'singleProperty',
  [LoanSubType.RENTAL_PORTFOLIO]: 'rentalPortfolio',
  [LoanSubType.MULTIFAMILY_TERM_PORTFOLIO]: 'rentalPortfolio',
  [LoanSubType.MIXED_USE_PORTFOLIO]: 'rentalPortfolio',
  [LoanSubType.STABILIZED_BRIDGE]: 'stabilizedBridge',
};

export const getLoanSubmissionRoutesConfig = (
  basePath: string,
  baseUrl: string
) => {
  return {
    submitloan: {
      path: `${basePath}/${SUBMITLOAN_BASE_PATH}`,
      url: `${baseUrl}/${SUBMITLOAN_BASE_PATH}`,
      documentTitle: 'New Loan Selection',
      children: {
        fixFlip: {
          path: `${basePath}/${SUBMITLOAN_BASE_PATH}/fix-flip`,
          url: `${baseUrl}/${SUBMITLOAN_BASE_PATH}/fix-flip`,
          documentTitle: 'New Fix & Flip Loan ',
        },
        groundUp: {
          path: `${basePath}/${SUBMITLOAN_BASE_PATH}/ground-up`,
          url: `${baseUrl}/${SUBMITLOAN_BASE_PATH}/ground-up`,
          documentTitle: 'New Ground Up Loan',
        },
        multifamilyBridge: {
          path: `${basePath}/${SUBMITLOAN_BASE_PATH}/multifamily-bridge`,
          url: `${baseUrl}/${SUBMITLOAN_BASE_PATH}/multifamily-bridge`,
          documentTitle: 'New Multifamily Bridge Loan',
        },
        singleProperty: {
          path: `${basePath}/${SUBMITLOAN_BASE_PATH}/single-property`,
          url: `${baseUrl}/${SUBMITLOAN_BASE_PATH}/single-property`,
          documentTitle: 'New Single Property Loan',
        },
        rentalPortfolio: {
          path: `${basePath}/${SUBMITLOAN_BASE_PATH}/rental-portfolio`,
          url: `${baseUrl}/${SUBMITLOAN_BASE_PATH}/rental-portfolio`,
          documentTitle: 'New Portfolio Rental Loan',
        },
        stabilizedBridge: {
          path: `${basePath}/${SUBMITLOAN_BASE_PATH}/stabilized-bridge`,
          url: `${baseUrl}/${SUBMITLOAN_BASE_PATH}/stabilized-bridge`,
          documentTitle: 'New Stabilized Bridge Loan',
        },
        fixFlipPro: {
          path: `${basePath}/${SUBMITLOAN_BASE_PATH}/fix-flip-pro`,
          url: `${baseUrl}/${SUBMITLOAN_BASE_PATH}/fix-flip-pro`,
          documentTitle: 'New Fix & Flip Pro Loan',
        },
      },
    },
  };
};

export const getLoanSubmissionRoutes = (basePath: string, baseUrl: string, allowLoanPricing: boolean) => {
  const config = getLoanSubmissionRoutesConfig(basePath, baseUrl);
  return {
    submitloan: (
      <Route exact path={config.submitloan.path}>
        <Page routeDefinition={config.submitloan}>
          {!allowLoanPricing ?
            <NewLoanSelection />
            :
            <ProductSelector />
          }
        </Page>
      </Route>
    ),
    fixFlip: (
      <Route exact path={config.submitloan.children.fixFlip.path}>
        <Page routeDefinition={config.submitloan.children.fixFlip}>
          <FixFlip />
        </Page>
      </Route>
    ),
    groundUp: (
      <Route exact path={config.submitloan.children.groundUp.path}>
        <Page routeDefinition={config.submitloan.children.groundUp}>
          <GroundUp />
        </Page>
      </Route>
    ),
    multifamilyBridge: (
      <Route exact path={config.submitloan.children.multifamilyBridge.path}>
        <Page routeDefinition={config.submitloan.children.multifamilyBridge}>
          <MultifamilyBridge />
        </Page>
      </Route>
    ),
    singleProperty: (
      <Route exact path={config.submitloan.children.singleProperty.path}>
        <Page routeDefinition={config.submitloan.children.singleProperty}>
          <SingleProperty />
        </Page>
      </Route>
    ),
    rentalPortfolio: (
      <Route exact path={config.submitloan.children.rentalPortfolio.path}>
        <Page routeDefinition={config.submitloan.children.rentalPortfolio}>
          <RentalPortfolio />
        </Page>
      </Route>
    ),
    stabilizedBridge: (
      <Route exact path={config.submitloan.children.stabilizedBridge.path}>
        <Page routeDefinition={config.submitloan.children.stabilizedBridge}>
          <StabilizedBridge />
        </Page>
      </Route>
    ),
    fixFlipPro: (
      <Route exact path={config.submitloan.children.fixFlipPro.path}>
        <Page routeDefinition={config.submitloan.children.fixFlipPro}>
          <FixFlipPro />
        </Page>
      </Route>
    ),
  };
};
