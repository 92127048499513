import { Box, Divider, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { isNotBlank } from '@roc/feature-utils';
import { Button, PercentageField } from '@roc/ui';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useNewLoanApplicationStore } from '../hooks/useNewLoanApplicationStore';
import { BorrowerForm } from './borrowerForm';
import {
  LoanApplicationAccordion,
  TotalEntityOwnershipWarning,
} from './loanApplicationComponents';

const useStyles = makeStyles(theme => ({
  percentageOwnership: {
    maxWidth: '140px',
    textWrap: 'nowrap',
    '& input': {
      textAlign: 'center',
    },
  },
  totalPercentageOwnership: {
    color: theme.palette.text.primary,
    '& .MuiInputBase-root': {
      backgroundColor: '#F0F0F0',
    },
    '& .MuiInputBase-root.Mui-error': {
      borderColor: theme.palette.error.main,
      backgroundColor: 'rgb(255, 236, 236)',
    },
  },
}));

export const MultipleBorrowersForm = observer(() => {
  const { newLoanApplicationStore } = useNewLoanApplicationStore();
  const { borrowerStore } = newLoanApplicationStore;
  const formStores = borrowerStore.editBorrowerForms;
  const classes = useStyles();

  const [accordionIndex, setAccordionIndex] = useState(0);

  const toggleAccordion = i => {
    setAccordionIndex(i === accordionIndex ? null : i);
  };

  useEffect(() => {
    borrowerStore.openEditBorrowers();
  }, []);

  const totalOwnership = formStores
    .map(store => parseInt(store.form.fields.pctOwnership.value || '0'))
    .reduce((total, pctOwnership) => total + pctOwnership, 0);

  return (
    <Grid container spacing={2}>
      {formStores.map((store, i) => (
        <>
          <Grid
            item
            xs={12}
          >
            <BorrowerAccordionItem
              store={store}
              expanded={accordionIndex === i}
              onChange={() => toggleAccordion(i)}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider style={{ height: '1px', backgroundColor: '#B9B9B9' }} />
          </Grid>
        </>
      ))}
      {totalOwnership != 100 && (
        <Grid item xs={12}>
          <TotalEntityOwnershipWarning />
        </Grid>
      )}
      <Grid item xs={12}>
        <TotalOwnershipField
          totalOwnership={totalOwnership}
          error={totalOwnership != 100}
        />
      </Grid>
    </Grid>
  );
});

const BorrowerAccordionItem = observer(({ store, expanded, onChange }) => {
  const classes = useStyles();
  const { newLoanApplicationStore } = useNewLoanApplicationStore();
  const { borrowerStore } = newLoanApplicationStore;
  const userBorrowerId = borrowerStore.userBorrower?.borrowerId;
  const showRemoveBorrower =
    userBorrowerId !== store.form.fields.borrowerId.value;

  return (
    <LoanApplicationAccordion
      expanded={expanded}
      onChange={onChange}
      overflowExpandedBackground
      summary={
        <Box
          flex="1"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <span>
            {store.form.fields.firstName.value}{' '}
            {store.form.fields.lastName.value}
          </span>
          <OwnershipField store={store} />
        </Box>
      }
    >
      <Box>
        <BorrowerForm store={store} />
        {showRemoveBorrower && (
          <Box py={2} display="flex" justifyContent="flex-end">
            <Button
              onClick={() => borrowerStore.removeCoBorrower(store)}
              color="primary"
              variant="outlined"
              testId="cancel"
            >
              REMOVE CO-BORROWER
            </Button>
          </Box>
        )}
      </Box>
    </LoanApplicationAccordion>
  );
});

const OwnershipField = ({ store }) => {
  const classes = useStyles();

  return (
    <Box
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <PercentageField
        label={'Ownership Percentage'}
        value={store.form.fields.pctOwnership.value || ''}
        testId={'pctOwnership'}
        onChange={(name, value) => store.onFieldChange('pctOwnership', value)}
        error={isNotBlank(store.form.fields.pctOwnership.error)}
        errorText={store.form.fields.pctOwnership.error}
        inputClassname={classes.percentageOwnership}
        limitedPercentage={true}
        allowNegative={false}
        inputLabelProps={{
          shrink: true,
        }}
      />
    </Box>
  );
};

export const SingleBorrowerForm = observer(() => {
  const { newLoanApplicationStore } = useNewLoanApplicationStore();
  const { borrowerStore } = newLoanApplicationStore;

  return (
    <BorrowerForm store={borrowerStore.editBorrowerForms?.[0]} />
  );
});

export const AddBorrowerForm = observer(() => {
  const { newLoanApplicationStore } = useNewLoanApplicationStore();
  const { borrowerStore } = newLoanApplicationStore;
  const { pctOwnership } = borrowerStore.addBorrowerForm.form.fields;
  const totalOwnership =
    parseInt(pctOwnership.value || '0') + borrowerStore.totalOwnership;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <BorrowerForm
          store={borrowerStore.addBorrowerForm}
          showOnershipPercentage
        />
      </Grid>
      <Grid item xs={12}>
        <Divider style={{ height: '1px', backgroundColor: '#B9B9B9' }} />
      </Grid>
      <Grid item xs={12}>
        <TotalOwnershipField totalOwnership={totalOwnership} error={false} />
      </Grid>
    </Grid>
  );
});

const TotalOwnershipField = ({ totalOwnership, error }) => {
  const classes = useStyles();

  return (
    <PercentageField
      label={'Total Entity Ownership Between Borrowers'}
      value={totalOwnership}
      testId={'pctOwnership'}
      variant="outlined"
      disabled
      fullWidth
      inputClassname={classes.totalPercentageOwnership}
      error={error}
    />
  );
};
