import { Theme, makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { DashboardRounded } from '@material-ui/icons';
import { useBaseStore } from '@roc/feature-app-core';
import { useBorrowerLoanApplicationsRoutes } from '@roc/feature-borrower-loans';
import { BorrowerPortalCard } from '@roc/feature-borrower-portal-core';
import { BorrowerDocumentForms, useDocumentStore } from '@roc/feature-documents';
import {
  TitleWithDetailedTodoCount,
  TodoCardCompponent,
  TodoItem,
  TodoStatus,
} from '@roc/feature-loan-details';
import { useLoanRoutes } from '@roc/feature-loans-routes-config';
import { LoanProcess } from '@roc/feature-utils';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { StatusFilterField } from 'libs/feature-loan-details/src/loanDetails/components/common/statusFilterField';
import { PDFPreview } from '@roc/ui';
import { isPendingTodoStatus } from 'libs/feature-loan-details/src/loanDetails/utils/todoUtils';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles((theme: Theme) => ({
  cardContentClassName: {
    padding: '16px 0 8px 0 !important',
  },
}));

export const BorrowerTodos = observer(({ loanId, isLoanApplication = false }) => {
  const { globalStore } = useBaseStore();
  const { breadCrumbsStore } = globalStore;
  const { loanRoutesConfig } = useLoanRoutes();
  const { borrowerLoanApplicationRoutesConfig } = useBorrowerLoanApplicationsRoutes();
  const { documentStore } = useDocumentStore();
  const { cardContentClassName } = useStyles();
  const currentForm = documentStore.documentFormStore.currentForm;

  useEffect(() => {
    documentStore.loanTodosStore.fetchTodos(loanId, LoanProcess.UNDERWRITING);
  }, [loanId]);

  const todos = documentStore.loanTodosStore.todosByLoanProcess[LoanProcess.UNDERWRITING] ?? [];
  const pendingTodos = todos.filter(t => isPendingTodoStatus(t.status));
  const finishedTodos = todos.filter(t => !isPendingTodoStatus(t.status));
  const pendingOnlyTodos = todos.filter(t => [TodoStatus.PENDING].includes(t.status));
  const moreInfoNeededTodos = todos.filter(t => [TodoStatus.MORE_INFO_NEEDED].includes(t.status));
  const acceptedTodos = todos.filter(t => [TodoStatus.ACCEPTED].includes(t.status));
  const inReviewTodos = todos.filter(t => [TodoStatus.BEING_REVIEWED].includes(t.status));
  const notApplicableTodos = todos.filter(t => [TodoStatus.NOT_APPLICABLE].includes(t.status));

  useEffect(() => {
    if (!currentForm && todos.length > 0) {
      documentStore.loanTodosStore.fetchTodos(loanId, LoanProcess.UNDERWRITING);
    }
  }, [currentForm]);

  useEffect(() => {
    breadCrumbsStore.setBreadCrumbItems([
      {
        link: true,
        title: 'Dashboard',
        url: globalStore.routeBaseUrl,
        icon: <DashboardRounded />,
      },
      {
        link: true,
        title: `Loan ${isLoanApplication ? 'Application' : ''}# ${loanId}`,
        url: isLoanApplication ? borrowerLoanApplicationRoutesConfig.loans(loanId).url : loanRoutesConfig.loans(loanId).url,
      },
      {
        link: false,
        title: 'To-dos',
      },
    ]);
  }, []);

  return (
    <Box>
      <BorrowerPortalCard
        cardTitle={<TitleWithDetailedTodoCount
          title='Pending To-dos'
          pendingCount={pendingOnlyTodos.length}
          moreInfoNeededCount={moreInfoNeededTodos.length} />}
        cardContentClassName={cardContentClassName}
        cardRootStyle={{
          borderColor: '#555'
        }}
      >
        <TodoCardCompponent
          loanProcess={LoanProcess.UNDERWRITING}
          todos={pendingTodos}
          allowStatusChange={false}
          showFileName
          isLoading={documentStore.loanTodosStore.loadingTodos}
          noRecordsMessageComponent={
            <Alert severity="success">
              You don't have any pending to-dos.
            </Alert>
          }
        />
      </BorrowerPortalCard>
      <BorrowerPortalCard
        cardTitle={<TitleWithDetailedTodoCount
          title='Completed To-dos'
          inReviewCount={inReviewTodos.length}
          acceptedCount={acceptedTodos.length}
          notApplicableCount={notApplicableTodos.length} />}
        cardContentClassName={cardContentClassName}
        cardRootStyle={{
          marginTop: 32,
          borderColor: '#555'
        }}
      >
        <TodoCardCompponent
          loanProcess={LoanProcess.UNDERWRITING}
          todos={finishedTodos}
          allowStatusChange={false}
          showFileName
          isLoading={documentStore.loanTodosStore.loadingTodos}
        />
      </BorrowerPortalCard>
      <BorrowerDocumentForms loanId={loanId} />
      <PDFPreview
        open={documentStore.documentPreview != null}
        title={documentStore.documentPreview?.title}
        data={documentStore.documentPreview?.data}
        headers={documentStore.documentPreview?.headers}
        onClose={() => documentStore.closeDocumentPreview()}
      />
    </Box>
  );
});
