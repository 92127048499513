import { useState, useEffect } from 'react';
import {
  Box,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { darken } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Card,
  Paper,
  SubmitButton,
  StandardDialog,
  TestDataAttribute,
} from '@roc/ui';
import { formatCurrency, showPercentage } from '@roc/ui/utils';
import { useHistory } from 'react-router';
import {
  EntityCard,
  StatusChip,
  StatusType,
  PropertyMap,
} from '@roc/client-portal-shared/components';
import { SubdirectoryArrowRight } from '@material-ui/icons';
import { useRoutes } from '@roc/client-portal-shared/hooks';
import {
  EditBorrowersForm,
  CreateAppraisalForm,
  useLoanStore,
} from '@roc/feature-loans';
import { sanitizeTestId, isFundedOrAgreedLoan, isFundedLoan, isPreagreedLoanStatus, isNil } from '@roc/client-portal-shared/utils';
import { BooleanChip } from './components/loanSummaryComponents';
import { useBaseStore, useUserStore } from '@roc/feature-app-core';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import { PricingModelTable } from 'libs/feature-loans/src/loanSubmission/multifamilyBridge/components/pricingModelTable';
import { LoanParticipants } from 'libs/feature-loans/src/loanSubmission/components/loanParticipants';
import { mapFundingEntity } from './components/loanSummaryHelper';
import { EditLoanButton, LoanStatusChangeReasonBanner, LoanSummaryBrokerSection, LoanSummaryLoanParticipantsSection } from '@roc/feature-loans-shared';

const useStyles = makeStyles(theme => ({
  paper: {
    marginBottom: theme.spacing(2),
  },
  container: {
    padding: "15px"
  },
  card: {
    background: theme.palette.background.default,
    borderColor: darken(theme.palette.background.default, 0.1),
  },
  tableBorder: {
    borderStyle: "none"
  }
}));

const renderHeading = value => {
  return (
    <Typography
      variant="body1"
    >
      <strong>{value}</strong>
    </Typography>
  );
};

const listItem = (label, value, icon = null, customTestId = null) => {
  const testId = sanitizeTestId(customTestId || label);
  return (
    <ListItem>
      <ListItemText>
        <Box display="flex">
          {icon && <Box ml={1}>{icon}</Box>}
          <Box flexGrow={1}>{label}</Box>
          <TestDataAttribute id={testId}>
            <Box>{value}</Box>
          </TestDataAttribute>
        </Box>
      </ListItemText>
    </ListItem>
  );
};

const divider = <Divider variant="middle" component="li" />;

export const LoanSummaryBridgeMultifamily = ({ data }) => {
  const classes = useStyles();
  const {
    loanId,
    amount,
    initialLoanAmount,
    constructionHoldback,
    rate,
    status,
    loanType,
    loanSubType,
    borrowerNames,
    properties,
    borrowerEntity,
    submissionDate,
    expectedClosingDate,
    agreedDate,
    closingDate,
    payoffDate,
    maturityDate,
    attorneyName,
    aggregateLoanToCurrentCostInitial,
    constructionHoldbackToRenovationBudgetPercent,
    minNumber,
    aggregateLoanToFutureCost,
    fullPrincipalInterest,
    pointsIn,
    interestReserve,
    brokerPointsIn,
    prepayPenaltyMonths,
    fullPrincipalInterestTime,
    replacementCost,
    source,
    highHazardFloodZone,
    rocRate,
    rocPointsIn,
    rocPointsOut,
    origLenderSubordination,
    buybackSkimReduction,
    sellAs,
    fundingPreference,
    pledgeOfShares,
    servicer,
    servicerId,
    calculatedLoanData,
    pricingModel,
    lenderOriginatorNames,
    preAgreed,
    lenderName,
    underwritingFee,
    freeAppraisalPromotion,
    firstServicerPaymentDueDate,
    referralFeePct
  } = data;
  const [ratios, setRatios] = useState({
    settlementCosts: 4 * 0.01 * calculatedLoanData.aggregatePurchasePrice,
    totalUsesOfCapital: 0,
    purchasePriceOverUses: 0,
    totalRenovationBudgetOverUses: 0,
    interestReserveOverUses: 0,
    settlementCostsOverUses: 0,
    cashAtPurchase: 0,
    initialLoanAmountOverSources: 0,
    totalHoldbackAmountOverSources: 0,
    cashAtPurchaseOverSources: 0,
    totalSourcesOfCapital: 0
  });

  const lenderOriginatorPercentage = lenderOriginatorNames?.map(x => x.percentage).reduce((a, b) => a + b, 0);
  const lenderCommissions = [...lenderOriginatorNames];
  lenderCommissions.splice(0, 0, {
    lenderName,
    percentage: lenderOriginatorPercentage ? 100 - lenderOriginatorPercentage : 100
  });

  useEffect(() => {
    setValues(data);
  }, []);


  const setValues = (data) => {
    const totalUsesOfCapital = Number(calculatedLoanData.aggregatePurchasePrice) + Number(calculatedLoanData.aggregateRenovationBudget) + Number(interestReserve) + Number(ratios.settlementCosts);
    const cashAtPurchase = totalUsesOfCapital - calculatedLoanData.aggregateRenovationBudget - initialLoanAmount;
    const totalSourcesOfCapital = Number(initialLoanAmount) + Number(calculatedLoanData.aggregateRenovationBudget) + Number(cashAtPurchase);
    const settlementCosts = 4 * 0.01 * calculatedLoanData.aggregatePurchasePrice;
    const ratiosPct = {
      totalUsesOfCapital: totalUsesOfCapital,
      purchasePriceOverUses: Math.round((calculatedLoanData.aggregatePurchasePrice / totalUsesOfCapital) * 100),
      totalRenovationBudgetOverUses: Math.round((calculatedLoanData.aggregateRenovationBudget / totalUsesOfCapital) * 100),
      interestReserveOverUses: Math.round((interestReserve / totalUsesOfCapital) * 100),
      settlementCostsOverUses: Math.round((settlementCosts / totalUsesOfCapital) * 100),
      cashAtPurchase: cashAtPurchase,
      initialLoanAmountOverSources: Math.round((initialLoanAmount / totalSourcesOfCapital) * 100),
      totalHoldbackAmountOverSources: Math.round((calculatedLoanData.aggregateRenovationBudget / totalSourcesOfCapital) * 100),
      cashAtPurchaseOverSources: Math.round((cashAtPurchase / totalSourcesOfCapital) * 100),
      totalSourcesOfCapital: totalSourcesOfCapital
    }
    setRatios({ ...ratios, ...ratiosPct });
  }

  const { push } = useHistory();

  const { loanRoutesConfig } = useRoutes();
  const { globalStore } = useBaseStore();
  const { loanStore } = useLoanStore();

  const [openCreateAppraisalModal, setOpenCreateAppraisalModal] = useState(
    false
  );

  const isCreateAppraisalOrderAllowed = () =>
    !!loanStore.loanDetails?.enableAppraisalCreation && !loanStore.loanDetails?.submitAppraisalWithTamarisk && !loanStore.loanDetails?.arrEnabled;

  const isFundedOrPaidoffOrAgreedLoan = isFundedOrAgreedLoan(status);
  const showLoanExpirationDates = !isFundedLoan(status);
  const { userStore } = useUserStore();
  const allowLoanPricing = userStore.allowLoanPricing;

  return (
    <Grid container spacing={2}>
      <LoanStatusChangeReasonBanner loan={data} />
      {isCreateAppraisalOrderAllowed() && (
        <Grid item xs={12}>
          <Box display="flex" justifyContent="flex-end">
            <Button
              variant="contained"
              size="small"
              onClick={() => setOpenCreateAppraisalModal(true)}
              color="primary"
              testId="create_appraisal_order"
            >
              Create Appraisal Order
            </Button>
          </Box>
        </Grid>
      )}
      <Grid item xs={12} lg={6}>
        <Paper title="LOAN TERMS" className={classes.paper}>
          {userStore.isBackOfficeUser && isPreagreedLoanStatus(status) &&
            <EditLoanButton loanStore={loanStore} data={data} />
          }
          <Box>
            <List>
              {listItem('Loan Type', loanType)}
              {divider}
              {listItem('Loan SubType', loanSubType)}
              {divider}
              {listItem(
                'Loan Status',
                <StatusChip
                  statusType={StatusType.LOAN}
                  label={status}
                  variant="filled"
                />
              )}
              {divider}
              {listItem('Loan Amount', formatCurrency(amount))}
              {listItem(
                'Initial Loan Amount',
                <Typography variant="body2" color="textSecondary" component="p">
                  {formatCurrency(initialLoanAmount)}
                </Typography>,
                <Box flexGrow={1}>
                  <SubdirectoryArrowRight fontSize="small" />
                </Box>
              )}
              {listItem(
                'Construction Holdback',
                <Typography variant="body2" color="textSecondary" component="p">
                  {formatCurrency(constructionHoldback)}
                </Typography>,
                <Box flexGrow={1}>
                  <SubdirectoryArrowRight fontSize="small" />
                </Box>
              )}
              {divider}
              {listItem('Initial LTC', `${aggregateLoanToCurrentCostInitial}%`)}
              {divider}
              {listItem(
                'Construction Holdback Rate',
                `${constructionHoldbackToRenovationBudgetPercent}%`
              )}
              {divider}
              {listItem('Attorney', attorneyName)}
              {divider}
              {isFundedOrPaidoffOrAgreedLoan &&
                listItem('Servicer', servicer)}
              {isFundedOrPaidoffOrAgreedLoan && listItem(
                'Servicer Id',
                <Typography variant="body2" color="textSecondary" component="p">
                  {servicerId}
                </Typography>,
                <Box flexGrow={1}>
                  <SubdirectoryArrowRight fontSize="small" />
                </Box>
              )
              }
              {isFundedOrPaidoffOrAgreedLoan && listItem(
                'First Payment Date',
                <Typography variant="body2" color="textSecondary" component="p">
                  {firstServicerPaymentDueDate}
                </Typography>,
                <Box flexGrow={1}>
                  <SubdirectoryArrowRight fontSize="small" />
                </Box>
              )
              }
              {isFundedOrPaidoffOrAgreedLoan && divider}
              {listItem('Total LTFC', `${aggregateLoanToFutureCost}%`)}
              {divider}
              {listItem('MERS MIN Number', minNumber)}
              {divider}
              {listItem('Free Appraisal Promotion',
                !isNil(freeAppraisalPromotion) && (
                  <BooleanChip value={freeAppraisalPromotion} />
                ))}
              {divider}
              {listItem(
                'Full Princ Interest',
                <BooleanChip value={fullPrincipalInterest} />
              )}
              {divider}
              {allowLoanPricing && listItem('Interest Rate', `${rate}%`)}
              {allowLoanPricing && divider}
              {listItem('FPI Time', fullPrincipalInterestTime)}
              {divider}
              {allowLoanPricing && listItem('Points', pointsIn)}
              {allowLoanPricing && divider}
              {allowLoanPricing &&
                (referralFeePct && referralFeePct > 0
                  ? listItem('Off Hud Broker Fee', referralFeePct)
                  : listItem('On Hud Broker Fee', brokerPointsIn)
                )
              }
              {allowLoanPricing && divider}
              {listItem('Interest Reserve', interestReserve)}
              {divider}
              {listItem('Pre Penalty (Months)', prepayPenaltyMonths)}
            </List>
          </Box>
        </Paper>
        <Paper title="INSURANCE" className={classes.paper}>
          <Box>
            <List>
              {listItem('Replacement Cost', replacementCost)}
              {divider}
              {listItem('Source', source)}
              {divider}
              {listItem(
                'Is there a property in a high hazard flood zone?',
                <BooleanChip value={calculatedLoanData.floodZone === 'Yes' ? true : false} />
              )}
            </List>
          </Box>
        </Paper>
      </Grid>
      <Grid item xs={12} md={6}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <LoanSummaryLoanParticipantsSection loanData={data} loanStore={loanStore} disableEditButton={globalStore.userFeatures?.isLenderOriginator} />
          </Grid>
          <Grid item xs={12}>
            <Paper title="TERMS" className={classes.paper}>
              <Box>
                <List>
                  {allowLoanPricing &&
                    listItem('Capital Provider Int Rate', `${rocRate}%`)
                  }
                  {allowLoanPricing && divider}
                  {allowLoanPricing &&
                    listItem(
                      'Capital Provider Points (I|O)',
                      rocPointsIn || rocPointsOut
                        ? `${rocPointsIn ?? ''} | ${rocPointsOut ?? ''}`
                        : null
                    )}
                  {allowLoanPricing && divider}
                  {allowLoanPricing && listItem(
                    'Capital Provider Underwriting Fee',
                    formatCurrency(underwritingFee ?? 0)
                  )}
                  {allowLoanPricing && divider}
                  {listItem('Subordination', `${origLenderSubordination}%`)}
                  {divider}
                  {allowLoanPricing &&
                    listItem('Buy. Skim Red.', buybackSkimReduction)
                  }
                  {allowLoanPricing && divider}
                  {allowLoanPricing &&
                    listItem('Participation', sellAs)
                  }
                  {allowLoanPricing && divider}
                  {listItem('Funding Entity', mapFundingEntity(fundingPreference))}
                  {divider}
                  {listItem(
                    'Pledge Of Shares',
                    <BooleanChip value={pledgeOfShares} />
                  )}
                </List>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={6}>
        <Paper title="KEY DATES" className={classes.paper}>
          <Box>
            <List>
              {listItem('Submission', submissionDate)}
              {divider}
              {listItem('Exp. Closing', expectedClosingDate)}
              {divider}
              {listItem('Agreed Date', agreedDate)}
              {divider}
              {listItem('Closing Date', closingDate)}
              {divider}
              {listItem('PayOff Date', payoffDate)}
              {divider}
              {listItem('Maturity', maturityDate)}
              {showLoanExpirationDates && (
                <>
                  {divider}
                  {listItem('COGS Expiration Date', loanStore?.cogsExpirationDate)}
                  {divider}
                  {listItem('Credit Expiration Date', loanStore?.creditExpirationDate)}
                  {divider}
                  {listItem('Appraisal Expiration Date', loanStore?.appraisalExpirationDate)}
                </>
              )}
              {listItem('Next Due Date', calculatedLoanData.nextDueDate)}
            </List>
          </Box>
        </Paper>
      </Grid>
      <Grid item xs={12} md={6}>
        <LoanSummaryBrokerSection loanData={data} />
        {globalStore.userFeatures?.addFacoOriginators && (
          <Paper title="ORIGINATORS" className={classes.paper}>
            <Grid item>
              <Box>
                <List>
                  {lenderCommissions.map((lender, i) => {
                    return (
                      <div key={lender}>
                        {listItem(
                          lender.lenderName,
                          'Percentage: ' + lender.percentage + '%',
                          <Box flexGrow={1}>
                            <SubdirectoryArrowRight fontSize="small" />
                          </Box>
                        )}
                        {divider}
                      </div>
                    );
                  })}
                  <br />
                  <Box mr={2} display="flex" justifyContent="flex-end">
                    <SubmitButton
                      hidden={false}
                      disabled={!preAgreed}
                      size="small"
                      variant="contained"
                      color="secondary"
                      testId="editLenderOriginator"
                      onCancelClick={() => {
                        loanStore.originatorStore.selectOriginatorsStore.reset();
                        loanStore.setExistingOriginatorsPercentage();
                      }}
                      onClick={() => loanStore.saveLenderOriginator()}
                      submitDialogProps={{
                        title: 'Edit Lender Originators',
                        body:
                          (loanStore.setExistingOriginatorsPercentage(),
                            (
                              <LoanParticipants store={loanStore.selectOriginatorsStore} />
                            )),
                        okButtonText: 'Submit',
                      }}
                    >
                      Edit
                    </SubmitButton>
                  </Box>
                </List>
              </Box>
            </Grid>
          </Paper>
        )}
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Grid container spacing={2}>
            <TestDataAttribute id="entity">
              <Grid item xs={12} lg={6}>
                <EntityCard title="ENTITY" entity={borrowerEntity} />
              </Grid>
            </TestDataAttribute>
            <Grid item xs={12} lg={6}>
              <Paper title="BORROWERS" style={{ boxShadow: 'none' }}>
                <Box>
                  <List>
                    {borrowerNames.map((name, i) => {
                      return (
                        <div key={name}>
                          {listItem(null, name, '', 'borrower')}
                          {divider}
                        </div>
                      );
                    })}
                    <br />
                    <Grid container justifyContent="flex-end" spacing={2}>
                      <Grid item>
                        <Button
                          size="small"
                          variant="contained"
                          color="secondary"
                          onClick={() =>
                            push(
                              loanRoutesConfig.loans(loanId.toString()).children
                                .borrowerEntity.url
                            )
                          }
                          testId="moreBorrowerInformation"
                        >
                          More information
                        </Button>
                      </Grid>
                      <Grid item>
                        {loanStore.allowEditBorrowers &&
                          <Box mr={2}>
                            <SubmitButton
                              size="small"
                              variant="contained"
                              color="secondary"
                              testId="editBorrowers"
                              onCancelClick={() => {
                                loanStore.selectBorrowersStore.reset();
                                loanStore.setExistingBorrowers();
                              }}
                              onClick={() => loanStore.saveBorrowers()}
                              submitDialogProps={{
                                title: 'Edit Borrowers',
                                body: <EditBorrowersForm store={loanStore} />,
                                okButtonText: 'Submit',
                              }}
                            >
                              Edit
                            </SubmitButton>
                          </Box>
                        }
                      </Grid>
                    </Grid>
                  </List>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12} lg={12}>
        <Paper title="PROPERTIES" className={classes.paper}>
          <List>
            <ListItem>
              <Grid container direction="row" spacing={2}>
                {properties?.map((property, i) => {
                  return (
                    <Grid item xs={12} md={6} lg={4} key={property.address}>
                      <TestDataAttribute id="property">
                        <Card
                          color="secondary"
                          title={property.address}
                          value={property}
                          className={classes.card}
                        >
                          <PropertyMap
                            latitude={property.latitude}
                            longitude={property.longitude}
                            address={property.address}
                          />
                        </Card>
                      </TestDataAttribute>
                    </Grid>
                  );
                })}
              </Grid>
            </ListItem>
            <Box mr={2} mb={2} display="flex" justifyContent="flex-end">
              <Button
                size="small"
                variant="contained"
                color="secondary"
                onClick={() =>
                  push(
                    loanRoutesConfig.loans(loanId.toString()).children
                      .properties.url
                  )
                }
                testId="morePropertiesInformation"
              >
                More information
              </Button>
            </Box>
          </List>
        </Paper>
      </Grid>
      <Grid item xs={12} lg={6}>
        <Paper title="SOURCES" className={classes.paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell align='right'>{renderHeading('Absolute')}</TableCell>
                <TableCell align='right'>{renderHeading('Percentage')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{renderHeading('Capital Provider Initial Loan')}</TableCell>
                <TestDataAttribute id='roc_initial_loan'>
                  <TableCell align='right'>{formatCurrency(initialLoanAmount, 0)}</TableCell>
                </TestDataAttribute>
                <TestDataAttribute id='roc_initial_loan_percentage'>
                  <TableCell align='right'>{showPercentage(ratios.initialLoanAmountOverSources)}</TableCell>
                </TestDataAttribute>
              </TableRow>
              <TableRow>
                <TableCell>{renderHeading('Capital Provider Holdbacks')}</TableCell>
                <TestDataAttribute id='roc_holdbacks'>
                  <TableCell align='right'>{formatCurrency(calculatedLoanData.aggregateRenovationBudget, 0)}</TableCell>
                </TestDataAttribute>
                <TestDataAttribute id='roc_holdbacks_percentage'>
                  <TableCell align='right'>{showPercentage(ratios.totalHoldbackAmountOverSources)}</TableCell>
                </TestDataAttribute>
              </TableRow>
              <TableRow>
                <TableCell>{renderHeading('Equity')}</TableCell>
                <TestDataAttribute id='equity'>
                  <TableCell align='right'>{formatCurrency(ratios.cashAtPurchase, 0)}</TableCell>
                </TestDataAttribute>
                <TestDataAttribute id='equity_percentage'>
                  <TableCell align='right'>{showPercentage(ratios.cashAtPurchaseOverSources)}</TableCell>
                </TestDataAttribute>
              </TableRow>
              <TableRow>
                <TableCell>{renderHeading('Total Sources')}</TableCell>
                <TestDataAttribute id='total_sources'>
                  <TableCell align='right'>{formatCurrency(ratios.totalSourcesOfCapital, 0)}</TableCell>
                </TestDataAttribute>
                <TestDataAttribute id=''>
                  <TableCell></TableCell>
                </TestDataAttribute>
              </TableRow>
            </TableBody>
          </Table>
        </Paper>
      </Grid>
      <Grid item xs={12} lg={6}>
        <Paper title="USES" className={classes.paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell align='right'>{renderHeading('Absolute')}</TableCell>
                <TableCell align='right'>{renderHeading('Percentage')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{renderHeading('Purchase Price')}</TableCell>
                <TestDataAttribute id='purchase_price'>
                  <TableCell align='right'>{formatCurrency(calculatedLoanData.aggregatePurchasePrice, 0)}</TableCell>
                </TestDataAttribute>
                <TestDataAttribute id='purchase_price_percentage'>
                  <TableCell align='right'>{showPercentage(ratios.purchasePriceOverUses)}</TableCell>
                </TestDataAttribute>
              </TableRow>
              <TableRow>
                <TableCell>{renderHeading('Total Renovation Budget')}</TableCell>
                <TestDataAttribute id='total_renovation_budget'>
                  <TableCell align='right'>{formatCurrency(calculatedLoanData.aggregateRenovationBudget, 0)}</TableCell>
                </TestDataAttribute>
                <TestDataAttribute id='total_renovation_budget_percentage'>
                  <TableCell align='right'>{showPercentage(ratios.totalRenovationBudgetOverUses)}</TableCell>
                </TestDataAttribute>
              </TableRow>
              <TableRow>
                <TableCell>{renderHeading('Interest Reserve')}</TableCell>
                <TestDataAttribute id='interest_reserve'>
                  <TableCell align='right'>{formatCurrency(interestReserve, 0)}</TableCell>
                </TestDataAttribute>
                <TestDataAttribute id='interest_reserve_percentage'>
                  <TableCell align='right'>{showPercentage(ratios.interestReserveOverUses)}</TableCell>
                </TestDataAttribute>
              </TableRow>
              <TableRow>
                <TableCell>{renderHeading('Settlement Costs')}</TableCell>
                <TestDataAttribute id='settlement_costs'>
                  <TableCell align='right'>{formatCurrency(ratios.settlementCosts, 0)}</TableCell>
                </TestDataAttribute>
                <TestDataAttribute id='settlement_costs_percentage'>
                  <TableCell align='right'>{showPercentage(ratios.settlementCostsOverUses)}</TableCell>
                </TestDataAttribute>
              </TableRow>
              <TableRow>
                <TableCell>{renderHeading('Total Uses')}</TableCell>
                <TestDataAttribute id='total_uses'>
                  <TableCell align='right'>{formatCurrency(ratios.totalUsesOfCapital, 0)}</TableCell>
                </TestDataAttribute>
                <TestDataAttribute id=''>
                  <TableCell></TableCell>
                </TestDataAttribute>
              </TableRow>
            </TableBody>
          </Table>
        </Paper>
      </Grid>
      <Grid item xs={12} lg={12}>
        {pricingModel ?
          <Grid item xs={12} lg={12}>
            <Paper title="PRICING MODEL" className={classes.paper}>
              <PricingModelTable data={data.pricingModel} />
            </Paper>
          </Grid> : null}
      </Grid>
      <StandardDialog
        open={openCreateAppraisalModal}
        title="Create Appraisal Order"
        maxWidth="md"
        handleClose={() => setOpenCreateAppraisalModal(false)}
        dialogContent={
          <CreateAppraisalForm
            onConfirm={(gfdPayment: boolean) => {
              setOpenCreateAppraisalModal(false);
              loanStore.submitLoanAppraisalOrder(loanId, gfdPayment);
            }}
            onClose={() => setOpenCreateAppraisalModal(false)}
          />
        }
      />
    </Grid>
  );
};
