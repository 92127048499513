import { Service } from './../../../services';

const url = {
  LOGOUT: '/api/v1/oauth2/logout',
};

export class LogoutService extends Service {
  logout() {
    return this.post(url.LOGOUT, {});
  }
}
