import { ApiResponse, GlobalStore, GridStore } from '@roc/feature-app-core';
import { makeObservable } from 'mobx';
import { ServicerLoanService } from '../services/servicerLoanService';

export class servicerLoansDashboardStore {
  private globalStore: GlobalStore;
  private servicerLoanService: ServicerLoanService;

  myNewRequestLoansGridStore: GridStore;
  rocLoansGridStore: GridStore;
  myRevisionLoansGridStore: GridStore;
  myApprovedLoansGridStore: GridStore;
  myRetractedExpiredLoansGridStore: GridStore;
  myForeclosureLoansGridStore: GridStore;
  constructor(globalStore: GlobalStore) {
    this.globalStore = globalStore;
    this.servicerLoanService = new ServicerLoanService();

    this.myNewRequestLoansGridStore = new GridStore(
      () => this.fetchMyNewRequestLoans(),
      null,
      50
    );
    this.rocLoansGridStore = new GridStore(
      () => this.fetchRocLoans(),
      null,
      50
    );
    this.myRevisionLoansGridStore = new GridStore(
      () => this.fetchMyRevisionLoans(),
      null,
      50
    );
    this.myApprovedLoansGridStore = new GridStore(
      () => this.fetchMyApprovedLoans(),
      null,
      50
    );
    this.myRetractedExpiredLoansGridStore = new GridStore(
      () => this.fetchMyRetractedExpiredkLoans(),
      null,
      50
    );
    this.myForeclosureLoansGridStore = new GridStore(
      () => this.fetchMyForeclosureLoans(),
      null,
      50
    )
    makeObservable(this, {});
  }

  private async fetchMyNewRequestLoans() {
    try {

      const filters = {
        ...this.myNewRequestLoansGridStore.gridData.meta.filters,
      };
      const response: ApiResponse = await this.servicerLoanService.getPayoffLoans(
        'Forwarded to Servicer',
        this.myNewRequestLoansGridStore.gridData.meta.pageNumber,
        this.myNewRequestLoansGridStore.gridData.meta.pageSize,
        this.myNewRequestLoansGridStore.gridData.meta.sortDir,
        this.myNewRequestLoansGridStore.gridData.meta.sortBy,
        filters,
        this.myNewRequestLoansGridStore.gridData.meta.dropdownFilters
      );
      return {
        ...response,
        data: {
          ...response.data,
          data: JSON.parse(response.data?.data || '{}')
        }
      };
    } catch (error) {
      console.log('error', error)
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching my new requests.',
      });
    }
  }

  private async fetchRocLoans() {
    try {

      const filters = {
        ...this.rocLoansGridStore.gridData.meta.filters,
      };
      const response: ApiResponse = await this.servicerLoanService.getPayoffLoans(
        'Approval and Forwarding Pending',
        this.rocLoansGridStore.gridData.meta.pageNumber,
        this.rocLoansGridStore.gridData.meta.pageSize,
        this.rocLoansGridStore.gridData.meta.sortDir,
        this.rocLoansGridStore.gridData.meta.sortBy,
        filters,
        this.rocLoansGridStore.gridData.meta.dropdownFilters
      );
      return {
        ...response,
        data: {
          ...response.data,
          data: JSON.parse(response.data?.data || '{}')
        }
      };
    } catch (error) {
      console.log('error', error)
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching my funded loans.',
      });
    }
  }

  private async fetchMyRevisionLoans() {
    try {
      const filters = {
        ...this.myRevisionLoansGridStore.gridData.meta.filters,
      };
      const response: ApiResponse = await this.servicerLoanService.getPayoffLoans(
        'In Revision',
        this.myRevisionLoansGridStore.gridData.meta.pageNumber,
        this.myRevisionLoansGridStore.gridData.meta.pageSize,
        this.myRevisionLoansGridStore.gridData.meta.sortDir,
        this.myRevisionLoansGridStore.gridData.meta.sortBy,
        filters,
        this.myRevisionLoansGridStore.gridData.meta.dropdownFilters
      );
      return {
        ...response,
        data: {
          ...response.data,
          data: JSON.parse(response.data?.data || '{}')
        }
      };
    } catch (error) {
      console.log('error', error)
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching my priority list loans.',
      });
    }
  }

  private async fetchMyApprovedLoans() {
    try {
      const filters = {
        ...this.myApprovedLoansGridStore.gridData.meta.filters,
      };
      console.log('')
      const response: ApiResponse = await this.servicerLoanService.getPayoffLoans(
        'Approved',
        this.myApprovedLoansGridStore.gridData.meta.pageNumber,
        this.myApprovedLoansGridStore.gridData.meta.pageSize,
        this.myApprovedLoansGridStore.gridData.meta.sortDir,
        this.myApprovedLoansGridStore.gridData.meta.sortBy,
        filters,
        this.myApprovedLoansGridStore.gridData.meta.dropdownFilters
      );
      return {
        ...response,
        data: {
          ...response.data,
          data: JSON.parse(response.data?.data || '{}')
        }
      };
    } catch (error) {
      console.log('error', error)
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching my priority list loans.',
      });
    }
  }

  private async fetchMyRetractedExpiredkLoans() {
    try {
      const filters = {
        ...this.myRetractedExpiredLoansGridStore.gridData.meta.filters,
      };
      const response: ApiResponse = await this.servicerLoanService.getPayoffLoans(
        'Retracted,Expired',
        this.myRetractedExpiredLoansGridStore.gridData.meta.pageNumber,
        this.myRetractedExpiredLoansGridStore.gridData.meta.pageSize,
        this.myRetractedExpiredLoansGridStore.gridData.meta.sortDir,
        this.myRetractedExpiredLoansGridStore.gridData.meta.sortBy,
        filters,
        this.myRetractedExpiredLoansGridStore.gridData.meta.dropdownFilters
      );
      return {
        ...response,
        data: {
          ...response.data,
          data: JSON.parse(response.data?.data || '{}')
        }
      };
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching my fast track loans.',
      });
    }
  }


  private async fetchMyForeclosureLoans() {
    try {
      const filters = {
        ...this.myForeclosureLoansGridStore.gridData.meta.filters,
      };
      console.log('')
      const response: ApiResponse = await this.servicerLoanService.getPayoffLoans(
        'In Foreclosure',
        this.myForeclosureLoansGridStore.gridData.meta.pageNumber,
        this.myForeclosureLoansGridStore.gridData.meta.pageSize,
        this.myForeclosureLoansGridStore.gridData.meta.sortDir,
        this.myForeclosureLoansGridStore.gridData.meta.sortBy,
        filters,
        this.myForeclosureLoansGridStore.gridData.meta.dropdownFilters
      );
      return {
        ...response,
        data: {
          ...response.data,
          data: JSON.parse(response.data?.data || '{}')
        }
      };
    } catch (error) {
      console.log('error', error)
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching my foreclosure list loans.',
      });
    }
  }
}
