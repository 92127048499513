import {
  FormControl,
  Grid,
  InputLabel,
  Paper,
  Typography,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useDocumentStore } from '@roc/feature-documents';
import { Document, LoanDocument } from '@roc/feature-types';
import { LoanProcess, uniq } from '@roc/feature-utils';
import {
  Button,
  DialogState,
  EditableDialog,
  FieldLabel,
  SelectField,
  StandardDialog,
  TextField,
} from '@roc/ui';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import isValidPath from 'is-valid-path';

const LoanLevelPaths = [
  'Application/',
  'Contract/',
  'Valuation/',
  'Appraisal/',
  'Environmental/',
  'Property Income/',
  'Property Expenses/',
  'Scope Of Work/',
  'ClosingDocs/',
  'Final/',
];

const getDefaultPath = (loanId, doc) => {
  let defaultPath = `${loanId}/`;
  if (doc) {
    if (doc.borrowerId) {
      defaultPath = `${doc.borrowerId}/`;
    } else if (doc.borrowerEntityId) {
      defaultPath = `${doc.borrowerEntityId}/`;
    } else if (doc.loanId) {
      defaultPath = `${doc.loanId}/`;
    }
  }

  return defaultPath;
};

export const MoveDocumentModal = observer(
  ({
    open,
    onClose,
    document,
    loanId,
    loanProcess,
  }: {
    open: boolean;
    onClose: () => void;
    document: Document;
    loanId: number;
    loanProcess: LoanProcess;
  }) => {
    const { documentStore } = useDocumentStore();
    const { loanTodosStore } = documentStore;

    const [customDropboxPath, setCustomDropboxPath] = useState('');
    const [initialCustomDropboxPath, setInitialCustomDropboxPath] = useState('');
    const [selectedSection, setSelectedSection] = useState(null);
    const [customPathError, setCustomPathError] = useState(false);

    const documents = (loanTodosStore.todosByLoanProcess?.[loanProcess] || []).map(
      todo => todo.document
    );
    const sections = uniq(documents.map(doc => doc.sectionName)) as string[];
    const referenceDocument = documents.find(
      doc => doc.sectionName === selectedSection
    );
    const defaultPath = getDefaultPath(loanId, referenceDocument);

    const updateDefaultValues = async () => {
      setSelectedSection(document.sectionName);
      const defaultPathOnInit = getDefaultPath(loanId, document);
      
      const customDropboxPath = document.customDropboxPath
          ? document.customDropboxPath.replace(defaultPathOnInit, '')
          : '';

      setCustomDropboxPath(customDropboxPath);
      setInitialCustomDropboxPath(customDropboxPath);
    };

    useEffect(() => {
      if (open) {
        updateDefaultValues();
      }
    }, [open]);

    const handleSave = async () => {
      const finalDropboxPath = customDropboxPath
        ? `${defaultPath}${customDropboxPath}`
        : null;

      await loanTodosStore.moveDocument(
        document,
        referenceDocument,
        finalDropboxPath
      );
      loanTodosStore.fetchTodos(loanId, loanProcess);

      onClose();
    };

    const handleOnAutocompleteChange = (event, newValue) => {
      const invalidPath =
        !isValidPath(newValue) ||
        (newValue && (newValue.includes('//') || newValue[0] === '/'));
      setCustomPathError(invalidPath);
      setCustomDropboxPath(newValue);
    };

    const dialogContent = (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" component="h6" gutterBottom>
            Move a file from one one section to another of this loan's &nbsp;
            {loanProcess.toLowerCase()} process.
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <TextField
            type={'text'}
            testId={'new-document-name'}
            variant={'outlined'}
            label={'Document Name'}
            standaloneLabel
            value={document.documentName}
            onChange={e => {}}
            fullWidth
            disabled
          />
        </Grid>

        <Grid item xs={12}>
          <SelectField
            label="Section You Are Moving The File To"
            value={selectedSection}
            options={sections.map(value => ({ label: value, value }))}
            testId={'section'}
            variant="outlined"
            standaloneLabel
            fullWidth
            onChange={value => setSelectedSection(value)}
          />
        </Grid>

        <Grid item xs={12}>
          <CustomDropboxPath
            errorMessage="Invalid path detected please check for invalid characters"
            label="Custom Dropbox Path"
            onInputChange={(event, newValue) =>
              handleOnAutocompleteChange(event, newValue)
            }
            options={LoanLevelPaths}
            showError={customPathError}
            startAdornment={defaultPath}
            value={customDropboxPath}
            defaultValue={initialCustomDropboxPath}
          />
        </Grid>
      </Grid>
    );

    return (
      <StandardDialog
        open={open}
        title={'Move Document'}
        handleClose={onClose}
        dialogContent={dialogContent}
        maxWidth={'sm'}
        removeContentBox
        dialogActions={
          <>
            <Button
              style={{ marginRight: '8px' }}
              onClick={onClose}
              color="primary"
              variant="outlined"
              testId="cancel"
            >
              Close
            </Button>
            <Button
              onClick={handleSave}
              color="primary"
              variant="contained"
              testId="save"
              disabled={!referenceDocument}
            >
              Save
            </Button>
          </>
        }
      />
    );
  }
);

const CustomDropboxPath = ({
  classes = undefined,
  disabled = undefined,
  errorMessage = undefined,
  label = undefined,
  onInputChange = undefined,
  options = undefined,
  showError = undefined,
  startAdornment = undefined,
  value = undefined,
  defaultValue = undefined
}) => {
  return (
    <>
      <FieldLabel>{label}</FieldLabel>
      <FormControl fullWidth>
        <Autocomplete
          key={defaultValue}
          defaultValue={defaultValue}
          id="value"
          options={options}
          disabled={disabled}
          PaperComponent={({ children }) => (
            <Paper style={{ fontSize: 14 }}>{children}</Paper>
          )}
          inputValue={value}
          onInputChange={onInputChange}
          freeSolo={true}
          renderInput={params => (
            <TextField
              {...params}
              testId="custom-dropbox-path"
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                startAdornment: startAdornment,
              }}
              helperText={showError ? errorMessage : undefined}
              error={showError}
            />
          )}
        />
      </FormControl>
    </>
  );
};
