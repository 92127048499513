import { Route, useLocation } from 'react-router';
import { Page } from '@roc/feature-app-core';
import { SearchPropertyScreen, CollateralCompScreen } from '@roc/feature-comp-tool';
import { useStore } from '@roc/client-portal-shared/hooks';
import { usePageTrackingInternal } from 'libs/feature-app-core/src/hooks/usePageTrackingInternal';
import { environment } from 'apps/roc-internal-portal/src/environments/environment';

const ADDRESS_BASE_PATH = ':address';

export const getCompToolRoutesInternalConfig = (
  basePath: string,
  baseUrl: string
) => {
  return {
    compTool: {
      path: `${basePath}/comp-tool`,
      url: `${baseUrl}/comp-tool`,
      documentTitle: 'Comp Tool',
    },
    compToolCollateral: (address?: string) => ({
      path: `${basePath}/comp-tool/${ADDRESS_BASE_PATH}`,
      url: `${baseUrl}/comp-tool/${address}`,
      documentTitle: 'Comp Tool Collateral',
    })
  };
}

export const getCompToolInternalRoutes = (basePath: string, baseUrl: string) => {
  const config = getCompToolRoutesInternalConfig(basePath, baseUrl);
  const { compToolStore } = useStore();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const userName = params.get('userName');
  usePageTrackingInternal(environment.gtmTrackingId);
  
  if (userName) {
    compToolStore.setInternalUsername(userName)
  }
  return {
    compTool: (
      <Route exact path={config.compTool.path}>
        <Page routeDefinition={config.compTool}>
          <SearchPropertyScreen />
        </Page>
      </Route>
    ),
    compToolCollateral: (
      <Route exact path={config.compToolCollateral().path}>
        <Page routeDefinition={config.compToolCollateral()}>
          <CollateralCompScreen />
        </Page>
      </Route>
    )
  };
};
