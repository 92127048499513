import { GlobalStore, UserStore } from '@roc/feature-app-core';
import { StabilizedBridgeStore } from '@roc/feature-loans';
import { LoanSubType } from '@roc/feature-utils';
import { StabilizedBridgeLoanEconomicsStore } from './loanEconomics/stabilizedBridgeLoanEconomicsStore';
import { StabilizedBridgePropertiesStore } from './properties/stabilizedBridgePropertiesStore';
import { QuoteTypeStore } from './quoteTypeStore';

export class StabilizedBridgeQuoteStore extends QuoteTypeStore {
  constructor(globalStore: GlobalStore, userStore: UserStore) {
    super(globalStore, userStore);
    this.loanSubtype = LoanSubType.STABILIZED_BRIDGE;
    this.propertiesStore = new StabilizedBridgePropertiesStore(
      this.globalStore, this.loanSubtype
    );
    this.loanEconomicsStore = new StabilizedBridgeLoanEconomicsStore(
      this.globalStore,
      this
    );
  }
}
