import React, { useEffect } from 'react';
import { darken } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { observer } from 'mobx-react';
import { Paper } from '@roc/ui';
import { Card, GoogleMap, TestDataAttribute } from '@roc/ui';
import { formatCurrency, formatPercentage } from '@roc/ui/utils';
import { Alert, AlertTitle } from '@material-ui/lab';
import { green, red } from '@material-ui/core/colors';
import { Chip } from '@material-ui/core';
import { Roles, WIMBA } from '@roc/feature-utils';
import WimbaModal from '../../components/wimbaModal';
import { useMultifamilyStore } from '../../hooks/useMultiFamilyStore';
import { useBaseStore, useUserStore } from '@roc/feature-app-core';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { LoanSubmissionStep } from '../../utils/constants';
import { useLoanCommissionStore } from 'libs/feature-loan-summary/src/loanSummary/hooks/useCommissionStore';

export interface PropertyFormProps {
  property: any;
}

const BooleanChip = ({ value }) => {
  const color = value ? green : red;
  return (
    <Chip
      label={value ? 'Yes' : 'No'}
      style={{
        fontWeight: 0,
        backgroundColor: color[200],
        color: color[900],
      }}
    />
  );
};

const renderHeading = value => {
  return (
    <Typography
      variant="body1"
    >
      <strong>{value}</strong>
    </Typography>
  );
};

const useStyles = makeStyles(theme =>
  createStyles({
    paper: {
      marginBottom: theme.spacing(2),
    },
    card: {
      background: theme.palette.background.default,
      borderColor: darken(theme.palette.background.default, 0.1),
    },
    mapStyles: {
      position: `relative`,
      width: `100%`,
      height: `150px`,
    },
    cardSpacing: {
      paddingRight: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    tableBorders: {
      borderBottom: "none"
    }

  })
);

const usdAmount = value => {
  return value && formatCurrency(Number(value));
};

const percentage = (value, decimals = 2) => {
  return value && `${Number(value).toFixed(2)}%`;
};

const Summary = observer(() => {
  const classes = useStyles();
  const { multiFamilyStore } = useMultifamilyStore();
  const {
    entity,
    selectBorrowersStore,
    properties,
    loanTerms,
    allErrors,
    allWarnings,
    loanInformationStore,
    allOutOfBoxWarnings,
    goPrevStep,
  } = multiFamilyStore;
  const { borrowers } = selectBorrowersStore;

  const { lenderAttorneysOptionList } = loanInformationStore;

  const preferredClosingAttorneyName = lenderAttorneysOptionList?.find(
    o => o.value == loanTerms.preferredClosingAttorney
  )?.label;

  const closeModal = () => {
    if (!loanTerms.wimbaAcknowledged) {
      goPrevStep(LoanSubmissionStep.SUMMARY);
    }
  };

  const confirmModal = () => {
    loanInformationStore.onChange('wimbaAcknowledged', true);
  };

  const isOffHudBroker = () =>{
    return loanTerms?.referralFee && loanTerms?.referralFee > 0 ? true : false;
  }

  useEffect(() => {
    multiFamilyStore.validateOutOfBoxLoan();
    multiFamilyStore.validateBorrowerErrors();
    multiFamilyStore.validateEntityErrors();
  }, [multiFamilyStore])

  const { globalStore } = useBaseStore();
  const { userStore } = useUserStore();
  const { loanCommissionStore } = useLoanCommissionStore();

  const allowLoanPricing = userStore.allowLoanPricing;
  const isInternal = globalStore.lenderInfo?.isInternal;
  const isBackOfficeProcessor = userStore.userInformation?.roles?.includes(Roles.BACK_OFFICE_PROCESSOR);
  const isBackOfficeUser = userStore.isBackOfficeUser;

  return (
    <>

      {
        allErrors.length > 0 && (
          <>
            <Grid xs={12}>
              <Alert severity="error">
                <AlertTitle>ERRORS</AlertTitle>
                {allErrors.map(error => (
                  <Grid>{error}</Grid>
                ))}
              </Alert>
            </Grid>
            <br />
          </>
        )
      }

      {
        allWarnings.length > 0 && (
          <>
            <Grid xs={12}>
              <Alert severity="warning">
                <AlertTitle>WARNINGS</AlertTitle>
                {allWarnings.map(warning => (
                  <Grid>{warning}</Grid>
                ))}
              </Alert>
            </Grid>
            <br />
          </>
        )
      }

      {
        allOutOfBoxWarnings.length > 0 && (
          <>
            <Grid xs={12}>
              <Alert severity="info">
                <AlertTitle>WARNINGS FOR UNDERWRITERS REVIEW</AlertTitle>
                {allOutOfBoxWarnings.map((warning, idx) => (
                  <Grid key={idx}>{warning}</Grid>
                ))}
              </Alert>
            </Grid>
            <br />
            <br />
          </>
        )
      }

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4">Summary</Typography>
          <br />
          <Typography variant="body1">
            Here we will list the entity information, participants and
            properties and loan terms you specified.
          </Typography>
        </Grid>
      </Grid>

      <br />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography color="primary" variant="h6">
            ENTITY
          </Typography>
          <Grid item xs={12} md={6} lg={4} className={classes.cardSpacing}>
            <TestDataAttribute id='entity'>
              <Card
                color="secondary"
                title={entity.name}
                value={entity}
                className={classes.card}
              >
                <div>
                  Ein:&nbsp;
                  <strong>{entity.ein}</strong>
                  <br />
                  Type:&nbsp;
                  <strong>{entity.type}</strong>
                </div>
              </Card>
            </TestDataAttribute>
          </Grid>
        </Grid>
        <br />
        <br />

        <Grid item xs={12}>
          <Typography color="primary" variant="h6">
            BORROWERS
          </Typography>
          {borrowers.length > 0 && (
            <Grid container spacing={2}>
              {borrowers.map(borrower => (
                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={4}
                  className={classes.cardSpacing}
                  key={borrower.borrowerId}
                >
                  <TestDataAttribute id='borrower'>
                    <Card
                      color="secondary"
                      title={`${borrower.firstName} ${borrower.lastName}`}
                      value={borrower}
                      className={classes.card}
                    >
                      <div>
                        Phone:&nbsp;
                        <strong>{borrower.cellPhone}</strong>
                        <br />
                        Email:&nbsp;
                        <strong>{borrower.emailAddress}</strong>
                      </div>
                    </Card>
                  </TestDataAttribute>
                </Grid>
              ))}
            </Grid>
          )}
        </Grid>
      </Grid>

      <br />
      <br />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography color="primary" variant="h6">
            PROPERTIES
          </Typography>
        </Grid>
        {properties.map(location => {
          return (
            <Grid
              item
              xs={12}
              md={6}
              lg={4}
              className={classes.cardSpacing}
              key={location.address}
            >
              <Card
                color="secondary"
                title={location.address}
                value={location}
                className={classes.card}
              >
                {location.latitude && location.longitude ? (
                  <GoogleMap
                    mapStyles={classes.mapStyles}
                    latitude={location.latitude}
                    longitude={location.longitude}
                  />
                ) : null}
              </Card>
            </Grid>
          );
        })}
      </Grid>

      <br />
      <br />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card title="Loan Details">
            <Grid item container direction="row" spacing={2}>
              <Grid item xs={6} md={3}>
                <Typography>
                  <b>Initial Loan Amount</b>
                </Typography>
              </Grid>
              <Grid item xs={6} md={3}>
                <TestDataAttribute id='initial_loan_amount'>
                  <Typography>
                    {usdAmount(loanTerms.initialLoanAmount)}
                  </Typography>
                </TestDataAttribute>
              </Grid>
              <Grid item xs={6} md={3}>
                <Typography>
                  <b>Construction Holdback</b>
                </Typography>
              </Grid>
              <Grid item xs={6} md={3}>
                <TestDataAttribute id='construction_holdback'>
                  <Typography>
                    {usdAmount(loanTerms.constructionHoldback)}
                  </Typography>
                </TestDataAttribute>
              </Grid>
              <Grid item xs={6} md={3}>
                <Typography>
                  <b>Total Loan Amount</b>
                </Typography>
              </Grid>
              <Grid item xs={6} md={3}>
                <TestDataAttribute id='total_loan_amount'>
                  <Typography>
                    {usdAmount(loanTerms.amount)}
                  </Typography>
                </TestDataAttribute>
              </Grid>
              {allowLoanPricing &&
                <>
                  <Grid item xs={6} md={3}>
                    <Typography>
                      <b>Interest Rate</b>
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <TestDataAttribute id='interest_rate'>
                      <Typography>
                        {percentage(loanTerms.rate)}
                      </Typography>
                    </TestDataAttribute>
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <Typography>
                      <b>{isInternal ? 'Capital Provider Points' : 'Points'}</b>
                    </Typography>
                  </Grid><Grid item xs={6} md={3}>
                    <TestDataAttribute id='points'>
                      <Typography>
                        {percentage(loanTerms.points)}
                      </Typography>
                    </TestDataAttribute>
                  </Grid>
                  {isInternal ? (
                    isOffHudBroker() ? (
                      <>
                        <Grid item xs={6} md={3}>
                          <Typography>
                            <b>Off Hud Broker Points</b>
                          </Typography>
                        </Grid>
                        <Grid item xs={6} md={3}>
                          <TestDataAttribute id="broker_points">
                            <Typography>
                              {percentage(loanTerms.referralFee)}
                            </Typography>
                          </TestDataAttribute>
                        </Grid>
                      </>
                    ) : (
                      <>
                        <Grid item xs={6} md={3}>
                          <Typography>
                            <b>On Hud Broker Points</b>
                          </Typography>
                        </Grid>
                        <Grid item xs={6} md={3}>
                          <TestDataAttribute id="broker_points">
                            <Typography>
                              {percentage(loanTerms.brokerPoints)}
                            </Typography>
                          </TestDataAttribute>
                        </Grid>
                      </>
                    )
                  ) : (
                    <>
                      <Grid item xs={6} md={3}>
                        <Typography>
                          <b>Broker Points</b>
                        </Typography>
                      </Grid>
                      <Grid item xs={6} md={3}>
                        <TestDataAttribute id="broker_points">
                          <Typography>
                            {percentage(loanTerms.brokerPoints)}
                          </Typography>
                        </TestDataAttribute>
                      </Grid>
                    </>
                   )}
                  </>
              }
              <Grid item xs={6} md={3}>
                <Typography>
                  <b>Term (Duration)</b>
                </Typography>
              </Grid>
              <Grid item xs={6} md={3}>
                <TestDataAttribute id='term_duration'>
                  <Typography>
                    {loanTerms.duration && `${loanTerms.duration} months`}
                  </Typography>
                </TestDataAttribute>
              </Grid>
              <Grid item xs={6} md={3}>
                <Typography>
                  <b>Interest Accrual method</b>
                </Typography>
              </Grid>
              <Grid item xs={6} md={3}>
                <TestDataAttribute id='interest_accrual_method'>
                  <Typography>{loanTerms.interestAccrualMethod}</Typography>
                </TestDataAttribute>
              </Grid>
              <Grid item xs={6} md={3}>
                <Typography>
                  <b>Interest Reserve</b>
                </Typography>
              </Grid>
              <Grid item xs={6} md={3}>
                <TestDataAttribute id='interest_reserve'>
                  <Typography>
                    {loanTerms.interestReserveMonths &&
                      `${loanTerms.interestReserveMonths} months`}
                  </Typography>
                </TestDataAttribute>
              </Grid>
              {allowLoanPricing &&
                <>
                  <Grid item xs={6} md={3}>
                    <Typography>
                      <b>Preferred Closing Attorney</b>
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <TestDataAttribute id="preferred_closing_attorney">
                      <Typography>{preferredClosingAttorneyName}</Typography>
                    </TestDataAttribute>
                  </Grid>
                </>
              }
            </Grid>
            {isBackOfficeUser && !isBackOfficeProcessor && isInternal && (
              <>
                <br />
                <Grid item container direction="row" spacing={2}>
                  <Grid item xs={6} md={3}>
                    <Typography>
                      <b>Your Commission %</b>
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <TestDataAttribute id="commission_percent">
                      <Typography>{formatPercentage(loanCommissionStore.commissionData?.commissionPercentage ?? 0, 4)}</Typography>
                    </TestDataAttribute>
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <Typography>
                      <b>Your Commission Amount</b>
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <TestDataAttribute id="commission_amount">
                      <Typography>{formatCurrency(loanCommissionStore.commissionData?.commissionAmount ?? 0)}</Typography>
                    </TestDataAttribute>
                  </Grid>
                </Grid>
              </>
            )}
          </Card>
        </Grid>
      </Grid>



      <WimbaModal
        open={loanTerms.preferredTitle === WIMBA && !loanTerms.wimbaAcknowledged}
        handleClose={closeModal}
        handleConfirm={confirmModal}
      />
    </>
  );
});

export default Summary;
