import { Grid, Typography } from "@material-ui/core";
import { observer } from "mobx-react";
import { makeStyles } from '@material-ui/core/styles';
import { CdaArrRadioButtonsSubmission } from "./CdaArrRadioButtonsSubmission";
import { CdaArrPaymentLink } from "./CDAARRPaymentLink";
import { CDAOrderGrid } from "./components/CDAOrderGrid";
import { useEffect, useState } from "react";
import { useDocumentStore } from "libs/feature-documents/src/documents/hooks/useDocumentStore";
import { ConfirmCloseButtons } from "./confirmCloseComponent";

const useStyles = makeStyles({
  container: {
    padding: 16
  },
  confirmButton: {
    marginRight: 10
  }
});

export const SecondCdaArrOrder = observer(({ isCda, isArr, onConfirm, url, borrowerEmail, loanId, isInternal, isArrPaymentDone, isCdaPaymentDone }) => {
  const classes = useStyles();
  const missingSubmissionOrderType = isCda ? 'ARR' : 'CDA';
  const { documentStore } = useDocumentStore();
  const { documentFormStore } = documentStore;
  const { cdaAndAppraisalOrderDetailsStore } = documentFormStore;
  const { propertiesWithDeviation } = cdaAndAppraisalOrderDetailsStore;


  const getMissingPaymentDoneType = () => {
    if (isCdaPaymentDone && isArrPaymentDone) {
      return "PAID";
    } else if (isCdaPaymentDone && !isArrPaymentDone) {
      return "ARR";
    } else if (!isCdaPaymentDone && isArrPaymentDone) {
      return "CDA";
    }
  }
  const missingPaymentOrderType = getMissingPaymentDoneType();

  const radioButtons = !(isCda && isArr);
  const paymentLink = (isCda && isArr && missingPaymentOrderType !== 'PAID');
  const grid = missingPaymentOrderType === 'PAID';

  const formatAddress = (property) => (
    [
      property.streetNumber,
      property.streetName,
      property.city,
      property.state,
      property.zipCode,
    ].join(', ')
  )

  return (
    <>
      <Grid container className={classes.container} xs={11}>

        {/* RADIO BUTTONS */}
        {radioButtons && <Grid item container xs={12} spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5" gutterBottom>
              {missingSubmissionOrderType} Order Submission
            </Typography>
            <Typography > The {missingSubmissionOrderType} can be ordered for the following properties:</Typography>
            <ul style={{ marginLeft: '50px' }}>
              {propertiesWithDeviation?.map(property => {
                return (<li>  {formatAddress(property)} </li>)
              })}
            </ul>
          </Grid>
          <Grid item xs={12}>
            <CdaArrRadioButtonsSubmission
              secondOrder={true}
              isCdaOrdered={isCda}
            />
          </Grid>
        </Grid>}

        {/* PAYMENT LINK */}
        {paymentLink && <Grid item container xs={12} spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5" gutterBottom> {missingPaymentOrderType} Order Payment Link</Typography>
          </Grid>
          <CdaArrPaymentLink
            url={url}
            borrowersEmail={borrowerEmail}
            loanId={loanId}
            cdaEnabled={isCda}
            isInternal={isInternal}
          />
        </Grid>}

        {/* GRID */}
        {grid && <Grid item container xs={12} spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5" gutterBottom> ARR Order</Typography>
          </Grid>
          <Grid item xs={12}>
            <CDAOrderGrid isCda={false} />
          </Grid>
        </Grid>}
      </Grid>

      <ConfirmCloseButtons isInternal={isInternal} hasConfirmButton={radioButtons} onConfirm={onConfirm} secondOrder={true} />
    </>

  )
});