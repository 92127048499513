import { Service } from '@roc/feature-app-core';

const url = {
  GET_ADVOCATE_BROKER_DETAILS:
    '/api/v1/document/formDocument/getAdvocateBrokerDetails',
  SAVE_INSURANCE_PROVIDER_DETAILS:
    '/api/v1/document/formDocument/saveInsuranceProviderDetails',
};

export class InsuranceProviderService extends Service {
  getAdvocateBrokerDetails(loanId: string) {
    return this.get(url.GET_ADVOCATE_BROKER_DETAILS, {
      loanId,
    });
  }
  saveInsuranceProviderDetails = details => {
    return this.put(url.SAVE_INSURANCE_PROVIDER_DETAILS, details);
  };
}
