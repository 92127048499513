import { FormStore, GlobalStore } from "@roc/feature-app-core";
import { action, flow, makeObservable, observable } from "mobx";
import { CmtInternalService } from "../../services/documentForms/cmtInternalService";
import { CMTReportDetails } from "@roc/feature-construction-feasibility";
import { ComparablePropertyImage } from "@roc/feature-comp-tool";

const form = {
  fields: {
    address: {
      value: '',
      error: null,
      rule: '',
    },
    totalRenoBudget: {
      value: '',
      error: null,
      rule: '',
    },
    completedRenoBudget: {
      value: '',
      error: null,
      rule: '',
    },
    bedrooms: {
      value: '',
      error: null,
      rule: '',
    },
    fullBathrooms: {
      value: '',
      error: null,
      rule: '',
    },
    halfBathrooms: {
      value: '',
      error: null,
      rule: '',
    },
    area: {
      value: '',
      error: null,
      rule: '',
    },
    lotSize: {
      value: '',
      error: null,
      rule: '',
    },
    rehabType: {
      value: '',
      error: null,
      rule: '',
    },
    currentStructure: {
      value: '',
      error: null,
      rule: '',
    },
    proposedStructure: {
      value: '',
      error: null,
      rule: '',
    },
    yrBuilt: {
      value: '',
      error: null,
      rule: '',
    },
    proposedSqFt: {
      value: '',
      error: null,
      rule: '',
    },
    valuation: {
      value: '',
      error: null,
      rule: '',
    },
    scopeofWorkAssessment: {
      value: '',
      error: null,
      rule: '',
    },
    dataQualityAssessmnet: {
      value: '',
      error: null,
      rule: '',
    },
    ownProperty: {
      value: '',
      error: null,
      rule: '',
    },
    completedRenovations: {
      value: '',
      error: null,
      rule: '',
    },
    dealType: {
      value: '',
      error: null,
      rule: '',
    },
    basement: {
      value: '',
      error: null,
      rule: '',
    },
    exteriorFinishQuality: {
      value: '',
      error: null,
      rule: '',
    },
    interiorFinishQuality: {
      value: '',
      error: null,
      rule: '',
    },
    sowAvailable: {
      value: '',
      error: null,
      rule: '',
    },
    appraisalAvailable: {
      value: '',
      error: null,
      rule: '',
    },
    permitsAvailable: {
      value: '',
      error: null,
      rule: '',
    },
    drawings: {
      value: '',
      error: null,
      rule: '',
    },
    dataAvailabilityAssessment: {
      value: '',
      error: null,
      rule: '',
    },
    comments: {
      value: '',
      error: null,
      rule: '',
    },
    newProposedStructure: {
      value: '',
      error: null,
      rule: '',
    },
    uwDealType: {
      value: '',
      error: null,
      rule: '',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};
export class CmtFormStore extends FormStore {
  private globalStore: GlobalStore;
  private cmtInternalService: CmtInternalService;
  cmtReportDetails: CMTReportDetails;
  images: ComparablePropertyImage[];

  constructor(globalStore: GlobalStore) {
    super(form, globalStore);
    this.globalStore = globalStore;
    this.cmtInternalService = new CmtInternalService();

    makeObservable(this, {
      cmtReportDetails: observable,
      resetStore: action,
      getCMTData: flow,
      saveCMTData: flow,
      requestRevision: flow,
      revisionComplete: flow,
      revisionCompleteWithComments: flow,
      updateSowUWStatus: flow,
      images: observable,
    })
  }

  resetStore() {
    this.reset();
    this.cmtReportDetails = null;
  }

  *getCMTData(propertyId, loanId) {
    try {
      const response = yield this.cmtInternalService.getCMTReport(loanId, propertyId);
      this.cmtReportDetails = response.data;
      this.images = this.cmtReportDetails.images ? JSON.parse(this.cmtReportDetails.images) : [];

      this.loadForm({ ...this.cmtReportDetails, dataAvailabilityAssessment: this.cmtReportDetails.dataAvailabilityAssessment ? this.cmtReportDetails.dataAvailabilityAssessment.split(',').map(item => { return { label: item, value: item } }) : [] });
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({ message: 'Error while loading report details' });
    }
  }

  *saveCMTData(loanId, propertyId) {
    try {
      const body = {
        ...this.cmtReportDetails,
        ...this.getFormValues(),
        dataAvailabilityAssessment: this.form.fields.dataAvailabilityAssessment.value.map(object => object.value).join(','),
      };

      yield this.cmtInternalService.saveCMTReport(loanId, propertyId, body);

      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Changes have been submitted successfully.',
      });
    } catch (error) {
      console.log(error);
      this.globalStore.notificationStore.showErrorNotification({ message: 'Error while saving report data' });
    }
  }

  *requestRevision(propertyId, loanId) {
    try {
      yield this.cmtInternalService.requestRevision(propertyId, loanId);
    } catch (error) {
      console.log(error);
      this.globalStore.notificationStore.showErrorNotification({ message: 'Error while requesting revision' });
    }
  }

  *revisionComplete(propertyId, loanId) {
    try {
      yield this.cmtInternalService.revisionComplete(propertyId, loanId);
    } catch (error) {
      console.log(error);
      this.globalStore.notificationStore.showErrorNotification({ message: 'Error while moving to revision complete' });
    }
  }

  *updateSowUWStatus(propertyId, loanId, status, message, onSuccess) {
    try {
      yield this.cmtInternalService.updateSowUWStatus(propertyId, loanId, status, message);
      this.globalStore.notificationStore.showSuccessNotification({ message: 'Data updated successfully.' })
      onSuccess();
    } catch (error) {
      console.log(error);
      this.globalStore.notificationStore.showErrorNotification({ message: 'Error while updating sow uw status' });
    }
  }

  *revisionCompleteWithComments(propertyId, loanId) {
    try {
      yield this.cmtInternalService.revisionCompleteWithComments(propertyId, loanId);
    } catch (error) {
      console.log(error);
      this.globalStore.notificationStore.showErrorNotification({ message: 'Error while moving to revision complete' });
    }
  }

}