import { Box, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";
import { lighten, createStyles, makeStyles } from '@material-ui/core/styles';
import { MAX_LOAN_AMOUNT_EXPANDED, BORROWER_CITIZENSHIP_REQUIREMENTS, CLOSINGS, DSCR_1_10, DSCR_1_20, DSCR_EXPANDED, DSCR_STANDARD, DSCR_EXPANDED_MIN_FICO, DSCR_STANDARD_MIN_FICO, FIVE_YEAR_SWAP, FIVE_YEAR_US_TREASURY, INDEX, LIMITED_YSP_AVAILABLE, MAX_LOAN_AMOUNT, MERS_UNAVAILABLE, MIN_DSCR, MIN_FICO, NO_FOREIGN_NATIONALS_ALLOWED, PRICING, PRODUCT_BOX, MAX_LOAN_AMOUNT_STANDARD, RATE_BUY_DOWN_OPTIONS, RATE_BUY_DOWN_OPTION_AVAILABLE, RATE_BUY_DOWN_OPTION_NOT_AVAILABLE, RATE_LOCK_TERMS, SEVEN_DAYS_FREE_GROSS_RATE, THIRTY_DAYS_FREE_GROSS_RATE, US_NON_PERMANENT, WHITELABELED_CLOSINGS_AVAILABLE_VIA_MERS, WHITELABELING, YIELD_SPREAD_PREMIUM, YSP_AVAILABLE, ELIGIBLE_STATES, ELIGIBLE_STATES_DSCR_EXPANDED, ELIGIBLE_STATES_DSCR_STANDARD, RENTAL_INCOME_DOCUMENTATION, PROOF_OF_RECEIPT_RECOMMENDED, PROOF_OF_RECEIPT_REQUIRED, TEN_YEAR_SWAP, FOURTEEN_DAYS_FREE_GROSS_RATE } from "libs/feature-loans/src/loanSubmission/stores/common/pricerSummaryHelper";
import { ExpandableLoanRatesTable } from "./expandableLoanRatesTable";

const useStyles = makeStyles(theme =>
  createStyles({
    table: {},
    row: {
      borderTop: '1px solid #BEBEBE',
    },
    cell: {
      border: 0,
      padding: theme.spacing(1),
      lineHeight: '1em',
      textAlign: 'center',
    },
    headerText: {
      fontWeight: 'bold',
      whiteSpace: 'pre',
      padding: theme.spacing(0.5),
    },
    headerTextSelected: {
      borderRadius: 4,
      backgroundColor: lighten(theme.palette.primary.main, 0.7),
    },
    cellText: {
      padding: theme.spacing(0.5),
    },
    cellTextSelected: {
      fontWeight: 'bold',
      borderRadius: 4,
      backgroundColor: lighten(theme.palette.primary.main, 0.3),
    },

    errorContainer: {
      color: theme.palette.error.main,
    },
    selectedRate: {
      color: theme.palette.primary.main,
    },
    paper: {
      width: '100%',
      backgroundColor: '#F6F6F6',
      padding: '10px 50px 10px 50px',
      margin: '10px 10px 20px'
    },
    list: {
      display: "flex",
      flexDirection: "row",
      listStyle: 'none',
    },
    listItem: {
      margin: '15px 25px 0px 5px'
    }
  })
);

export const ProgramOptionsTable = () => {
  const classes = useStyles();
  return (
    <ExpandableLoanRatesTable title="Program Options">
      <Box>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="Program Options">
            <TableHead>
              <TableRow>
                <TableCell colSpan={5}>{''}</TableCell>
                <TableCell colSpan={4}><strong>{DSCR_EXPANDED}</strong></TableCell>
                <TableCell colSpan={4}><strong>{DSCR_STANDARD}</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell colSpan={2} rowSpan={4}><strong>{PRICING}</strong></TableCell>
                <TableCell colSpan={3} rowSpan={1}>{INDEX}</TableCell>
                <TableCell colSpan={4} rowSpan={1}>{FIVE_YEAR_SWAP}<br />{TEN_YEAR_SWAP}</TableCell>
                <TableCell colSpan={4} rowSpan={1}>{FIVE_YEAR_US_TREASURY}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={3} rowSpan={1}>{RATE_BUY_DOWN_OPTIONS}</TableCell>
                <TableCell colSpan={4} rowSpan={1}>{RATE_BUY_DOWN_OPTION_AVAILABLE}</TableCell>
                <TableCell colSpan={4} rowSpan={1}>{RATE_BUY_DOWN_OPTION_NOT_AVAILABLE}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={3} rowSpan={1}>{YIELD_SPREAD_PREMIUM}</TableCell>
                <TableCell colSpan={4} rowSpan={1}>{YSP_AVAILABLE}</TableCell>
                <TableCell colSpan={4} rowSpan={1}>{YSP_AVAILABLE}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={3} rowSpan={1}>{RATE_LOCK_TERMS}</TableCell>
                <TableCell colSpan={4} rowSpan={1}>{THIRTY_DAYS_FREE_GROSS_RATE}</TableCell>
                <TableCell colSpan={4} rowSpan={1}>{FOURTEEN_DAYS_FREE_GROSS_RATE}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell colSpan={2} rowSpan={6}><strong>{PRODUCT_BOX}</strong></TableCell>
                <TableCell colSpan={3} rowSpan={1}>{MIN_FICO}</TableCell>
                <TableCell colSpan={4} rowSpan={1}>{DSCR_EXPANDED_MIN_FICO}</TableCell>
                <TableCell colSpan={4} rowSpan={1}>{DSCR_STANDARD_MIN_FICO}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={3} rowSpan={1}>{MAX_LOAN_AMOUNT}</TableCell>
                <TableCell colSpan={4} rowSpan={1}>{MAX_LOAN_AMOUNT_EXPANDED}</TableCell>
                <TableCell colSpan={4} rowSpan={1}>{MAX_LOAN_AMOUNT_STANDARD}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={3} rowSpan={1}>{MIN_DSCR}</TableCell>
                <TableCell colSpan={4} rowSpan={1}>{DSCR_1_10}</TableCell>
                <TableCell colSpan={4} rowSpan={1}>{DSCR_1_10}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={3} rowSpan={1}>{BORROWER_CITIZENSHIP_REQUIREMENTS}</TableCell>
                <TableCell colSpan={4} rowSpan={1}>{US_NON_PERMANENT}</TableCell>
                <TableCell colSpan={4} rowSpan={1}>{NO_FOREIGN_NATIONALS_ALLOWED}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={3} rowSpan={1}>{ELIGIBLE_STATES}</TableCell>
                <TableCell colSpan={4} rowSpan={1}>{ELIGIBLE_STATES_DSCR_EXPANDED}</TableCell>
                <TableCell colSpan={4} rowSpan={1}>{ELIGIBLE_STATES_DSCR_STANDARD}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={3} rowSpan={1}>{RENTAL_INCOME_DOCUMENTATION}</TableCell>
                <TableCell colSpan={4} rowSpan={1}>{PROOF_OF_RECEIPT_RECOMMENDED}</TableCell>
                <TableCell colSpan={4} rowSpan={1}>{PROOF_OF_RECEIPT_REQUIRED}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell colSpan={2} rowSpan={1}><strong>{CLOSINGS}</strong></TableCell>
                <TableCell colSpan={3} rowSpan={1}>{WHITELABELING}</TableCell>
                <TableCell colSpan={4} rowSpan={1}>{WHITELABELED_CLOSINGS_AVAILABLE_VIA_MERS}</TableCell>
                <TableCell colSpan={4} rowSpan={1}>{MERS_UNAVAILABLE}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Grid item>
          <Typography variant="caption">*Rate lock periods presented are calendar days</Typography>
          <br />
          <Typography variant="caption">**Collecting proof of rental income allows flexibility to switch between programs</Typography>
        </Grid>
      </Box>
    </ExpandableLoanRatesTable>
  );
};
