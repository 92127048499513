import { Page } from '../../../components';
import { Route } from 'react-router';
import { Messages } from '../messages';

export const getMessagesRoutesConfig = (basePath: string, baseUrl: string) => {
  return {
    messages: {
      path: `${basePath}/messages`,
      url: `${baseUrl}/messages`,
      documentTitle: 'Messages',
    },
  };
};

export const getMessagesRoutes = (basePath: string, baseUrl: string) => {
  const config = getMessagesRoutesConfig(basePath, baseUrl);
  return {
    messages: (
      <Route exact path={config.messages.path}>
        <Page routeDefinition={config.messages}>
          <Messages />
        </Page>
      </Route>
    ),
  };
};
