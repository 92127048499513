import { Service } from '@roc/feature-app-core';
import { NewLeadBaseService } from './newLeadBaseService';

export const url = {
  GET_LEAD_METADATA: '/api/v1/lender/lead/getLeadMetaData',
  GET_ALL_TPO_NAMES: '/api/v1/lender/getAllTPONames',
  SUBMIT_NEW_LEAD: '/api/v1/lender/lead/submitNewLead',
  POSSIBLE_MATCHES_BY_EMAIL: '/api/v1/lender/lead/getPossibleMatchesByEmail',
  POSSIBLE_MATCHES_BY_DOMAIN: '/api/v1/lender/lead/getPossibleMatchesByDomain',
  POSSIBLE_MATCHES_BY_PHONE: '/api/v1/lender/lead/getPossibleMatchesByPhone',
  POSSIBLE_MATCHES_BY_NAME: '/api/v1/lender/lead/getPossibleMatchesByName',
  GET_BUSINESS_UNIT: '/api/v1/lender/lead/getBusinessUnit',
  ASSIGN_NEW_OWNER: '/api/v1/lender/lead/assignNewOwner',
  UPLOAD_LEADS: '/api/v1/lender/lead/uploadLeads',
  DOWNLOAD_LEADS_TEMPLATE: '/api/v1/lender/lead/downloadTemplate',
  GET_ALL_OWNERS_BY_PROCESSOR: '/api/v1/lender/lead/getOriginatorsFromProcessor',
  SAVE_LEAD_SEARCH_HISTORY: '/v1/loan/leads/saveLeadSearchHistorySearch',
  VALIDATE_LEAD_TO_SUBMIT_AND_CONVERT: '/api/v1/lender/lead/validateLeadToSubmitAndConvert'
};

export class NewLeadService extends NewLeadBaseService {
  getCompanyNames() {
    return this.get(url.GET_ALL_TPO_NAMES);
  }

  getLeadMetadata(leadType: string) {
    return this.get(url.GET_LEAD_METADATA);
  }

  getBusinessUnit() {
    return this.get(url.GET_BUSINESS_UNIT);
  }

  submitNewLead(userDetails: any) {
    return this.post(url.SUBMIT_NEW_LEAD, userDetails);
  }

  possibleMatchesByEmail(
    email: string,
    page: number,
    pageSize: number,
    sortDir: string,
    sortBy: string,
    filters: string
  ) {
    return this.get(url.POSSIBLE_MATCHES_BY_EMAIL, {
      email,
      page,
      pageSize,
      sortDir,
      sortBy,
      filters,
    });
  }

  possibleMatchesByDomain(
    domain: string,
    page: number,
    pageSize: number,
    sortDir: string,
    sortBy: string,
    filters: string
  ) {
    return this.get(url.POSSIBLE_MATCHES_BY_DOMAIN, {
      domain,
      page,
      pageSize,
      sortDir,
      sortBy,
      filters,
    });
  }

  possibleMatchesByPhone(
    phone: string,
    page: number,
    pageSize: number,
    sortDir: string,
    sortBy: string,
    filters: string
  ) {
    return this.get(url.POSSIBLE_MATCHES_BY_PHONE, {
      phone,
      page,
      pageSize,
      sortDir,
      sortBy,
      filters,
    });
  }

  possibleMatchesByName(
    name: string,
    page: number,
    pageSize: number,
    sortDir: string,
    sortBy: string,
    filters: string
  ) {
    return this.get(url.POSSIBLE_MATCHES_BY_NAME, {
      name,
      page,
      pageSize,
      sortDir,
      sortBy,
      filters,
    });
  }

  assignOwner(recordId: string, businessUnit: string, objectType: string) {
    return this.get(url.ASSIGN_NEW_OWNER, {
      recordId,
      businessUnit,
      objectType,
    });
  }

  uploadLeads(formData, userName) {
    return this.post(url.UPLOAD_LEADS + '?userName=' + userName, formData);
  }

  downloadLeadsTemplate() {
    return this.get(
      url.DOWNLOAD_LEADS_TEMPLATE,
      {},
      {
        responseType: 'arraybuffer',
      }
    );
  }

  getAllOwnersByProcessor(userId) {
    return this.get(url.GET_ALL_OWNERS_BY_PROCESSOR, { userId })
  }

  saveLeadSearchHistory(searchDetails: string) {
    return this.get(url.SAVE_LEAD_SEARCH_HISTORY, {
      searchDetails
    })
  }

  validateLeadToConvert(userDetails: any) {
    return this.post(url.VALIDATE_LEAD_TO_SUBMIT_AND_CONVERT, userDetails);
  }
}
