import { insertIf, userPortalStatusProperties } from '@roc/feature-utils';
import { AgGridColumnProps, AlertDialog, ConfirmationMenuItem, DataGrid, DialogState, IconButton, SelectFilterComponent, SelectFloatingFilterComponent, StatusChip, StatusType } from '@roc/ui';
import { observer } from 'mobx-react';
import React, { ReactNode, useEffect, useState } from 'react';
import { useBrokersStore } from '../hooks/useBrokersStore';
import { formatPhoneNumber } from '@roc/ui/utils';
import { Box, ListItemIcon, ListItemText, Menu, Typography } from '@material-ui/core';
import { Add, Close, Edit, Link, MoreVert, Send,AttachMoney,Settings } from '@material-ui/icons';
import { useBaseStore, useUserStore } from '@roc/feature-app-core';
import { MenuItem } from '@roc/ui';

const noOpComparator = () => 0;

const { NO_ACCOUNT, INVITATION_EMAIL_SENT, ACCOUNT_CREATED, DEACTIVATED_ACCOUNT } = userPortalStatusProperties;


export const ManageBrokersGrid = observer(({ toolbar, showLenderName = false, showPortalStatus = false, showManageBrokersMenu = false }: { toolbar: ReactNode, showLenderName?: boolean, showPortalStatus?: boolean, showManageBrokersMenu?: boolean }) => {

  const { globalStore } = useBaseStore();

  const columns: AgGridColumnProps[] = [
    {
      field: 'name',
      headerName: 'Full Name',
      minWidth: 100,
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      cellRenderer: 'brokerNameRenderer',
      floatingFilter: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    ...insertIf(showLenderName, [{
      field: 'lenderName',
      headerName: 'Lender Name',
      minWidth: 250,
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    }]),
    {
      field: 'email',
      headerName: 'Email',
      minWidth: 250,
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    {
      field: 'phone',
      headerName: 'Cell Phone',
      minWidth: 150,
      cellRenderer: 'phoneCellRenderer',
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    {
      field: 'companyName',
      headerName: 'Company Name',
      minWidth: 100,
      comparator: noOpComparator,
      cellRenderer: 'companyNameRenderer',
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    ...insertIf(showPortalStatus, [{
      field: 'portalStatus',
      headerName: 'Broker Portal Status',
      minWidth: 300,
      cellRenderer: 'portalStatusCellRenderer',
      comparator: noOpComparator,
      filter: 'selectFilter',
      floatingFilter: true,
      floatingFilterComponent: 'selectFloatingFilter',
      floatingFilterComponentParams: {
        suppressFilterButton: true,
        options: [
          { label: NO_ACCOUNT.name, value: NO_ACCOUNT.value },
          { label: INVITATION_EMAIL_SENT.name, value: INVITATION_EMAIL_SENT.value },
          { label: ACCOUNT_CREATED.name, value: ACCOUNT_CREATED.value },
          { label: DEACTIVATED_ACCOUNT.name, value: DEACTIVATED_ACCOUNT.value },
        ],
      },
    }]),
    {
      field: 'action',
      headerName: 'Action',
      cellRenderer: 'actionCellRenderer',
      comparator: noOpComparator,
      minWidth: 90,
      width: 90,
      pinned: 'right',
      lockPinned: true
    }
  ]

  const { brokersStore } = useBrokersStore();
  const { userStore } = useUserStore();
  const { selectBrokersStore, brokerContactsGridStore, manageBrokersStore,manageBrokerFormStore } = brokersStore;
  const { globalStore: { portalConfiguration } } = useBaseStore();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [userId, setUserId] = useState('');
  const [contactId, setContactId] = useState('');
  const [brokerEmail, setBrokerEmail] = useState('');
  const [showCreateAccount, setShowCreateAccount] = useState(false);
  const [showDeactivateAccount, setShowDeactivateAccount] = useState(false);
  const [showResendInvite, setShowResendInvite] = useState(false);
  const menuOpen = Boolean(anchorEl);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    brokerContactsGridStore.fetchGridData();
  }, []);

  const onSortChanged = params => {
    const sortModel = params.api.getSortModel();
    brokerContactsGridStore.setSortModel(sortModel);
  };

  const onFilterChanged = params => {
    const filterModel = params.api.getFilterModel();
    brokerContactsGridStore.setFilterModel(filterModel);
  };

  const getBrokerLoginLink = () => {
    const response = Promise.resolve(manageBrokersStore.getBrokerLoginLink(userId));
    response.then((value) => {
      navigator.clipboard.writeText(value.toString());
    });
  }

  const frameworkComponents = {
    selectFilter: SelectFilterComponent,
    selectFloatingFilter: SelectFloatingFilterComponent,
    phoneCellRenderer: ({ value }) => formatPhoneNumber(value),
    portalStatusCellRenderer: ({ value }) => {
      return value ? (
        <StatusChip statusType={StatusType.USER_PORTAL_STATUS} label={value} size="small" />
      ) : null
    },
    actionCellRenderer: params => {
      return (
        <Box>
          {showManageBrokersMenu && (
            <IconButton
              testId={'manage-brokers'}
              onClick={e => openMenu(e, params.node.data)}>
              <MoreVert color="primary" />
            </IconButton>
          )}
        </Box>
      );
    },
  };

  const openMenu = (event: React.MouseEvent<HTMLElement>, record) => {
    const { portalStatus, email, contactId, userId } = record;
    setContactId(contactId);
    setBrokerEmail(email);
    setUserId(userId);
    setShowCreateAccount(portalStatus === NO_ACCOUNT.value);
    setShowDeactivateAccount(portalStatus === ACCOUNT_CREATED.value);
    setShowResendInvite(portalStatus === INVITATION_EMAIL_SENT.value);
    setAnchorEl(event.currentTarget);
    brokersStore.manageBrokerFormStore.setBroker(record);
    manageBrokerFormStore.setIsEdit(true);
    manageBrokerFormStore.setIsDisabled(true);
    brokersStore.brokerVendorClosingSettingsFormStore.reset();
    brokersStore.otherInsuranceFormStore.reset();
    brokersStore.otherTitleFormStore.reset();
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleClose = () => {
    manageBrokersStore.setRequestSuccess(false);
  };


  return (
    <>
      <DataGrid
        toolbar={toolbar}
        columns={columns}
        rows={brokerContactsGridStore.gridData.data.rows}
        frameworkComponents={frameworkComponents}
        onSortChanged={onSortChanged}
        onFilterChanged={onFilterChanged}
        isLoading={brokerContactsGridStore.isLoading}
        showFilters={brokerContactsGridStore.showFilters}
        sortModel={brokerContactsGridStore.sortModel}
        filterModel={brokerContactsGridStore.filterModel}
        paginationData={brokerContactsGridStore.gridData.meta}
        setPageNumber={brokerContactsGridStore.setPageNumber}
      />
      <Menu
        id="menu-appbar"
        getContentAnchorEl={null}
        anchorEl={anchorEl}
        keepMounted
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left', }}
        transformOrigin={{ vertical: 'top', horizontal: 'left', }}
        open={menuOpen}
        onClose={handleMenuClose}
      >
        {showCreateAccount && (
          <ConfirmationMenuItem
            testId={`createAccount`}
            onClick={() => {
              manageBrokersStore.createBrokerUserAccount(contactId, portalConfiguration.id);
              handleMenuClose();
            }}
            confirmDialogProps={{
              body: `Are you sure want to create a broker portal account for ${brokerEmail}?`,
            }}
          >
            <ListItemIcon>
              <Add fontSize="small" />
            </ListItemIcon>
            <ListItemText>Create Account</ListItemText>
          </ConfirmationMenuItem>
        )}
        {showDeactivateAccount && (
          <ConfirmationMenuItem
            testId={`deactivateAccount`}
            onClick={() => {
              manageBrokersStore.deactivateUser(userId, userStore.userInformation?.username);
              handleMenuClose();
            }}
            confirmDialogProps={{
              body: `Are you sure want to deactivate ${brokerEmail}?`,
            }}
          >
            <ListItemIcon>
              <Close fontSize="small" />
            </ListItemIcon>
            <ListItemText>Deactivate Account</ListItemText>
          </ConfirmationMenuItem>
        )}
        {showResendInvite && (
          <ConfirmationMenuItem
            testId={`resendInvite`}
            onClick={() => {
              manageBrokersStore.resendInvite(userId);
              handleMenuClose();
            }}
            confirmDialogProps={{
              body: `Are you sure want to resend the invite for ${brokerEmail}?`,
            }}
          >
            <ListItemIcon>
              <Send fontSize="small" />
            </ListItemIcon>
            <ListItemText>Resend Invite</ListItemText>
          </ConfirmationMenuItem>
        )}
        {showResendInvite && (
          <MenuItem
            testId={`getBrokerLoginLink`}
            onClick={() => { getBrokerLoginLink() }}
          >
            <ListItemIcon>
              <Link fontSize="small" />
            </ListItemIcon>
            <ListItemText>Copy Invitation Link</ListItemText>
          </MenuItem>
        )}
        <MenuItem
          testId={`editBrokerInfo`}
          onClick={() => {
            selectBrokersStore.editDialogState = DialogState.EDIT
            selectBrokersStore.openEditBrokerInfoStep = true;
            selectBrokersStore.openEditFeesInfoStep = false;
            selectBrokersStore.openEditVendorSettings = false;
            handleMenuClose();
          }}
        >
          <ListItemIcon>
            <Edit fontSize="small" />
          </ListItemIcon>
          <ListItemText>Edit Broker Information</ListItemText>
        </MenuItem>

        <MenuItem
          testId={`editBrokerFees`}
          onClick={() => {
            selectBrokersStore.editDialogState = DialogState.EDIT
            selectBrokersStore.openEditBrokerInfoStep = false;
            selectBrokersStore.openEditBrokerInfoStep = true;
            selectBrokersStore.openEditVendorSettings = false;
            handleMenuClose();
          }}
        >
          <ListItemIcon>
            <AttachMoney fontSize="small" />
          </ListItemIcon>
          <ListItemText>Edit Broker Fees</ListItemText>
        </MenuItem>
        <MenuItem
          testId={`editBrokerVendorSetting`}
          onClick={() => {
            selectBrokersStore.editDialogState = DialogState.EDIT
            selectBrokersStore.openEditBrokerInfoStep = false;
            selectBrokersStore.openEditFeesInfoStep = false;
            selectBrokersStore.openEditVendorSettings = true;
            handleMenuClose();
          }}
        >
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          <ListItemText>Edit Broker Settings</ListItemText>
        </MenuItem>
      </Menu>
      <AlertDialog
        open={manageBrokersStore.requestSuccess}
        body={
          <>
            <Typography>
              An invitation has been sent to <strong> {brokerEmail} </strong> to create the account.
            </Typography>
          </>
        }
        handleClose={handleClose}
      />
    </>
  )
});