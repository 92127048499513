import { Service } from '@roc/feature-app-core';

const url = {
  GET_VIDEOS: '/api/v1/loan/whatsNew/getVideos',
  GET_VIDEO_URL: '/api/v1/loan/whatsNew/getVideoUrl',
};

export class WhatsNewService extends Service {
  getVideos() {
    return this.get(url.GET_VIDEOS);
  }
  getVideoUrl(video: string) {
    return this.get(url.GET_VIDEO_URL, {
      video,
    });
  }
}
