import { FormStore } from '@roc/feature-app-core';
import { GlobalStore } from '@roc/feature-app-core';
import { makeObservable } from 'mobx';

const borrowerSetupForm = {
  fields: {
    borrowerId: {
      value: null,
      error: null,
      rule: '',
    },
    personalGuarantor: {
      value: null,
      error: null,
      rule: '',
    },
    creditBackgroundCheck: {
      value: null,
      error: null,
      rule: 'required',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export class BorrowerSetupFormStore extends FormStore {
  private globalStore: GlobalStore;

  constructor(globalStore) {
    super({ ...borrowerSetupForm }, globalStore);
    this.globalStore = globalStore;

    makeObservable(this, {
    })
  }

}

export default BorrowerSetupFormStore;