import {
  Grid,
  Paper
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { Layout } from '@roc/feature-app-core';
import { PropertyLayout } from './propertySummary';
import { observer } from 'mobx-react';
import { forwardRef, useImperativeHandle, useRef } from 'react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

const useStyles = makeStyles(theme => ({
  hiddenContainer: {
    height: 0,
    overflow: 'hidden',
  },
  paper: {
    marginBottom: theme.spacing(2),
    alignContent: 'center',
    margin: 'auto',
    width: '85%',
    borderRadius: 0
  },
}));

export interface DownloadablePropertytRef {
  downloadAsPdf: (property) => void;
  getPdfAsBlob: () => Promise<Blob>;
}

const DownloadableProperty = observer(
  forwardRef((props, ref) => {
    const classes = useStyles();

    const prospectPropertyEl = useRef();

    const getCanvasFromElement = (element: HTMLElement) => {
      return html2canvas(element, {
        scrollX: 0,
        scrollY: 0,
        useCORS: true
      });
    };

    const generatePdf = async (): Promise<jsPDF> => {
      const section1Element: HTMLElement = prospectPropertyEl.current;

      const [canvas1] = await Promise.all([
        getCanvasFromElement(section1Element)
      ]);

      const jspdf = new jsPDF(section1Element.offsetWidth < section1Element.offsetHeight ? 'p' : 'l', 'pt', [
        section1Element.offsetWidth,
        section1Element.offsetHeight,
      ]);

      const size1 = jspdf.internal.pageSize;
      const url1 = canvas1.toDataURL('image/png');
      jspdf.addImage(url1, 'PNG', 0, 0, size1.getWidth(), size1.getHeight(), undefined, 'FAST');
      return jspdf;
    };

    const downloadAsPdf = async (property) => {
      const jspdf = await generatePdf();
      jspdf.save(`Prospect_Property_${property.address}, ${property.city}, ${property.state} ${property.zipCode}.pdf`);
    };

    const getPdfAsBlob = async () => {
      const jspdf = await generatePdf();
      return new File([jspdf.output('blob')], `Prospect_Property.pdf`, {
        type: 'application/pdf',
      });
    };

    useImperativeHandle(ref, () => ({
      downloadAsPdf: (propertyAddress) => { downloadAsPdf(propertyAddress) },
      getPdfAsBlob,
    }));

    return (
      <div className={classes.hiddenContainer}>
        <div ref={prospectPropertyEl}>
          <Layout>
            <Grid xs={12}>
              <Paper className={classes.paper} square>
                <PropertyLayout store={props} handleDownloadClick={() => { }} isDownload />
              </Paper>
            </Grid >
          </Layout>
        </div>
      </div>
    );
  })
);

export default DownloadableProperty;