import { Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { TableCard } from '@roc/ui';
import { Document } from '@roc/feature-types';
import { formatDate } from '@roc/feature-utils';
import { observer } from 'mobx-react';
import { useCallback, useEffect, useMemo } from 'react';
import { useDocumentStore } from '../../hooks/useDocumentStore';

const columns = [
  {
    name: 'File Name',
    width: '50%',
  },
  {
    name: 'Upload Date',
    width: '50%',
  },
];

const getRows = (documents: any[], onDocumentClick) => {
  if (documents) {
    return documents.map(doc => [
      {
        value: doc.fileName,
        component: () => (
          <Link component="button" onClick={() => onDocumentClick(doc)}>
            {doc.fileName}
          </Link>
        ),
      },
      {
        value: formatDate(doc.createdDate),
      },
    ]);
  }
  return [];
};

const useStyles = makeStyles(() => ({
  table: {
    minWidth: 'auto',
  },
}));

type DocumentHistory = {
  document: Document;
};

export const DocumentHistory = observer(({ document }: DocumentHistory) => {
  const classes = useStyles();
  const { documentStore } = useDocumentStore();

  useEffect(() => {
    if (document) {
      documentStore.getDocumentHistory(document);
    }
    return () => {
      documentStore.resetDocumentHistory();
    };
  }, [documentStore, document]);

  const onDocumentClick = useCallback(
    (doc: any) => {
      documentStore.downloadHistoryDocument(doc);
    },
    [documentStore]
  );

  const rows = useMemo(
    () => getRows(documentStore.documentHistory, onDocumentClick),
    [documentStore.documentHistory, onDocumentClick]
  );

  return (
    <TableCard
      title={'File History'}
      optionsTitle=""
      options={[]}
      helperText=""
      rows={rows}
      columns={columns}
      classNames={{ table: classes.table }}
      showHeader={false}
    />
  );
});

export default DocumentHistory;
