import React, { MouseEvent, ReactNode, useEffect, useState } from 'react';
import { Button, ConfirmDialog, SelectField, trueFalseOptions } from '@roc/ui';
import { observer } from 'mobx-react';
import { Checkbox, FormControl, Grid, InputLabel, Paper, Select, Step, StepContent, StepLabel, Stepper, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tabs, Typography } from '@material-ui/core';
import { Borrower } from '../../types';
import { isNil } from '@roc/feature-utils';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { FieldContainer } from '@roc/ui/formComponents';
import PersonalInfoTab from './personalInfoTab';
import { CreditInfoTab } from './creditInfoTab';
import { BackgroundInfoTab } from './backgroundInfoTab';
import { TrackRecordInfoTab } from './trackRecordInfoTab';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rootContainer: {
      paddingTop: '24px'
    },
  })
);

export type BorrowerTabsProps = {
  borrower?: Borrower;
};

export const BorrowerTabs = observer((props: BorrowerTabsProps) => {
  const { borrower } = props;
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    //
  }, [borrower]);

  const onTabSelect = (e: any, index: number): void => {
    setSelectedTab(index);
  };

  return (
    <Grid container className={classes.rootContainer}>
      <Grid container>
        <Grid item xs={12}>

          <Tabs
            value={selectedTab}
            onChange={onTabSelect}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label="Personal Info" />
            <Tab label="Credit Info" />
            <Tab label="Background Info" />
            <Tab label="Track Record" />
          </Tabs>
        </Grid>
        <Grid item xs={12}>
          {selectedTab === 0 && <PersonalInfoTab borrower={borrower} />}
          {selectedTab === 1 && <CreditInfoTab borrower={borrower} /> }
          {selectedTab === 2 && <BackgroundInfoTab borrower={borrower} /> }
          {selectedTab === 3 && <TrackRecordInfoTab borrower={borrower} /> }
        </Grid>
      </Grid>
    </Grid>
  );
});

export default BorrowerTabs;
