import { formatCurrency } from '@roc/ui/utils';
import { isStabilizedBridge } from '@roc/feature-utils';
import { insertIf, PURCHASE, REFINANCE } from '@roc/feature-utils';


export const calculateThirdPartyCosts = (loanAmount) => {
  if (!loanAmount || loanAmount < 0) return 0;

  const THRESHOLDS = [200000, 500000];
  const RATES = [0.04, 0.03, 0.02];

  if (loanAmount <= THRESHOLDS[0]) {
    return loanAmount * RATES[0];
  } else if (loanAmount <= THRESHOLDS[1]) {
    return loanAmount * RATES[1];
  } else {
    return loanAmount * RATES[2];
  }
};

export const generateLoanDetailsTableData = (outputs, thirdPartyCosts = 0, loanPurpose, loanSubType) => {
  return {
    categories: [
      {
        name: 'Purchase Price or Payoff Amount',
        fees: [
          {
            name: isStabilizedBridge(loanSubType) ? 'Loan Amount' : 'Initial Advance',
            amount: formatCurrency(outputs?.initialLoanAmount),
          },
          ...insertIf(PURCHASE === loanPurpose,
            [
              {
                name: 'Acquisition Price',
                amount: formatCurrency(outputs?.purchasePrice),
              },
            ]
          ),
          ...insertIf(REFINANCE === loanPurpose,
            [
              {
                name: 'Existing Debt Payoff',
                amount: formatCurrency(outputs?.existingDebtPayoff),
              },
            ]
          ),
        ],
        total: formatCurrency(outputs?.totalPurchasePriceOrPayoffAmount),
      },
      {
        name: 'Reserves & Escrows',
        fees: [
          {
            name: `Interest Reserve (${outputs?.interestReserveFinanced ? 'Financed' : 'Minimum prepaid'})`,
            amount: formatCurrency(outputs?.interestReserve),
          },
        ],
        total: formatCurrency(outputs?.interestReserve),
      },
      {
        name: 'Lender Fees',
        fees: (outputs?.lenderFees ?? []).map(fee => ({
          name: fee.feeName,
          amount: formatCurrency(fee.feeAmount),
        })),
        total: formatCurrency(outputs?.totalLenderFees),
      },
      {
        name: 'Estimated Third Party Fees',
        fees: (outputs?.thirdPartyFees ?? []).map(fee => ({
          name: fee.feeName,
          amount: formatCurrency(fee.feeAmount),
        })),
        total: formatCurrency(outputs?.totalThirdPartyFees),
      },
    ],
    total: formatCurrency(
      outputs?.estimatedBorrowerCashToClose
    ),
  };
};
