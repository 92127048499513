import { Page } from '@roc/feature-app-core';
import { Route } from 'react-router';
import { BridgeProfitAndDscrCalculatorContainer } from '../components';

export const getBridgeProfitAndDscrCalculatorRoutesConfig = (
  basePath: string,
  baseUrl: string
) => {
  return {
    bridgeProfitAndDscrCalculator: {
      path: `${basePath}/bridge-profit-and-dscr-calculator`,
      url: `${baseUrl}/bridge-profit-and-dscr-calculator`,
      documentTitle: 'Bridge Profit & DSCR Calculator',
    },
  };
};

export const getBridgeProfitAndDscrCalculatorRoutes = (basePath: string, baseUrl: string) => {
  const config = getBridgeProfitAndDscrCalculatorRoutesConfig(basePath, baseUrl);
  return {
    bridgeProfitAndDscrCalculator: (
      <Route exact path={config.bridgeProfitAndDscrCalculator.path}>
        <Page routeDefinition={config.bridgeProfitAndDscrCalculator}>
          <BridgeProfitAndDscrCalculatorContainer />
        </Page>
      </Route>
    ),
  };
};
