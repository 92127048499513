import { Grid, Select, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import {
  createNumberFormatField,
  createPercentageField,
  createSelectField,
  createTextField,
} from '@roc/client-portal-shared/components';
import { SelectField, createSliderField, createSliderInputField, trueFalseOptions } from '@roc/ui';
import { observer } from 'mobx-react';
import React from 'react';
import { BridgePricerStore } from '../stores/bridgePricerStore';
import { borrowerRateOptions, loanSubtypeOptions, stateOptions, borrowerPointOptions, allLendersOptions } from '../utils/bridgePricerConstants';
import {
  DateField,
} from '@roc/ui';
import { isNotBlank, YES, NO, formatDate } from '@roc/feature-utils';
import axios from 'axios';
import { store } from 'apps/roc-internal-portal/src/app/stores';
import { BridgePricerService } from '../services/bridgePricerService';
import { ClientBridgePricerStore } from '../stores/clientBridgePricerStore';
import { makeStyles } from '@material-ui/core/styles';

interface PricerFieldProps {
  store: ClientBridgePricerStore;
  helperText: string;
}

const useStyles = makeStyles(theme => ({
  slider: {
    marginTop: '2.5em',
    marginLeft: '2em',
    width: '85%',
  }
}));

const onChangedDate = async (store) => {
  try {
    const response = await store.getRateFloor(
      {
        "submissionDate": store.form.fields["submissionDate"].value,
        "lenderName": store.form.fields["lenderName"].value,
        "propertyState": store.form.fields["propertyState"].value,
        "loanSubtype": store.form.fields["loanSubtype"].value
      });
    store.onFieldChange("floorRate", response.data);
    store.filterBorowerRateOptions(borrowerRateOptions, response.data);
    store.getBorrowerPointsOptions(
      store.form.fields["lenderId"].value,
      store.form.fields["loanTermsMonths"].value,
      formatDate(store.form.fields["submissionDate"].value, 'MM/dd/yyyy')
    );
  } catch (err) {

  }
}

const validateDate = (store) => {
  const date = store.form.fields["submissionDate"].value;
  return date && date.getFullYear();
}

const field = FieldComponent => {
  const WrappedFieldComponent = observer(FieldComponent);
  return props => (
    <Grid item xs={props.xs ?? 12} md={props.md ?? 6}>
      <WrappedFieldComponent {...props} />
    </Grid>
  );
};

export const PropertyState = field(({ store }: PricerFieldProps) => {
  return createCustomSelectField({
    store,
    label: 'Property State',
    testId: 'propertyState',
    fieldName: 'propertyState',
    options: stateOptions,
  });
});

export const LoanSubType = field(({ store }: PricerFieldProps) => {
  return createCustomSelectField({
    store,
    label: 'Loan Sub Type',
    testId: 'loanSubtype',
    fieldName: 'loanSubtype',
    options: loanSubtypeOptions
  })
});

export const SubmissionDate = field(({ store }: PricerFieldProps) => {
  return createDateField({
    store,
    label: 'Submission Date',
    testId: 'submissionDate',
    fieldName: 'submissionDate',
  })
});

export const FloorRate = field(({ store }: PricerFieldProps) => {
  return createPercentageField({
    store,
    label: 'Floor Rate',
    testId: 'floorRate',
    fieldName: 'floorRate',
    disabled: true,
  })
});

export const BorrowerRate = field(({ store }: PricerFieldProps) => {
  const classes = useStyles();

  const options = store.borrowerRateOptions ? store.borrowerRateOptions : borrowerRateOptions;
  let min = 0;
  let max = 0;

  if (options.length > 0) {
    min = Number(options[0].value);
    max = Number(options[options.length - 1].value);
  }

  return createSliderInputField({
    store,
    label: "Borrower Rate",
    fieldName: 'borrowerRate',
    testId: 'borrowerRate',
    className: classes.slider,
    valueLabelDisplay: 'on',
    min,
    max,
    step: 0.01,
    marks: [
      {
        value: min,
        label: min + '%',
      },
      {
        value: max,
        label: max + '%',
      },
    ]
  });
});

export const BorrowerPoints = field(({ store }: PricerFieldProps) => {
  const classes = useStyles();
  const options = store.borrowerPointsOptions ? store.borrowerPointsOptions : borrowerPointOptions;
  let min = 0;
  let max = 0;

  if (options.length > 0) {
    min = Number(options[0].value);
    max = Number(options[options.length - 1].value);
  }

  return createSliderInputField({
    store,
    label: "Borrower Points",
    fieldName: 'borrowerPoints',
    testId: 'borrowerPoints',
    className: classes.slider,
    valueLabelDisplay: 'on',
    min,
    max,
    step: 0.25,
    marks: min > 0 ? [
      {
        value: min,
        label: min + '%',
      },
      {
        value: max,
        label: max + '%',
      },
    ] : false,
  })
});

export const RocRate = field(({ store }: PricerFieldProps) => {
  return createPercentageField({
    store,
    label: 'Capital Provider Rate',
    testId: 'rocRate',
    fieldName: 'rocRate',
    disabled: true,
  })
});

export const RocPoints = field(({ store }: PricerFieldProps) => {
  return createPercentageField({
    store,
    label: 'Capital Provider Points',
    testId: 'rocPoints',
    fieldName: 'rocPoints',
    disabled: true,
  })
});

export const YieldSpread = field(({ store }: PricerFieldProps) => {
  return createPercentageField({
    store,
    label: 'Your Yield Spread',
    testId: 'yourYieldSpread',
    fieldName: 'yourYieldSpread',
    disabled: true,
  })
});

export const YourPoints = field(({ store }: PricerFieldProps) => {
  return createPercentageField({
    store,
    label: 'Your Points',
    testId: 'yourPoints',
    fieldName: 'yourPoints',
    disabled: true,
  })
});

// export const AllLenders = field(({ store }: PricerFieldProps) => {
//   const allLender: lenderType[] = store.allLenderOptions;
//   return (
//     <Autocomplete
//       id='lenderName'
//       options={allLender}
//       componentName="lenderName"
//       renderInput={(options) => <TextField {...options} label="Lenders" />}
//       getOptionLabel={(option) => option.label}
//       onInputChange={(name, value) => store.onFieldChange("lenderName", value)}
//     />
//   );
// });

interface SelectFieldProps {
  options?: Array<{
    label: string;
    value: any;
  }>;
  variant?: 'outlined' | 'standard';
  type?: 'text' | 'number';
  label: string;
  subLabel?: string;
  fieldName: string;
  testId: string;
  store: any;
  disabled?: boolean;
  tooltip?: string;
  placeholder?: string;
}

interface lenderType {
  label: string;
  value: string;
};

interface DateFieldProps {
  store: any;
  testId: string;
  fieldName: string;
  label: string;
  disabled?: boolean;
  format?: string;
  standaloneLabel?: boolean;
  tooltip?: string;
  disableFuture?: boolean;
};

export const createDateField = ({
  testId,
  disabled,
  fieldName,
  format = 'MM/dd/yyyy',
  label,
  store,
  standaloneLabel = true,
  disableFuture,
  tooltip
}: DateFieldProps) => {
  const formFields = store.form.fields;
  const field = formFields[fieldName];
  const required = field.rule.indexOf('required') > -1;
  return (
    <DateField
      testId={testId}
      disabled={disabled}
      inputVariant="outlined"
      required={required}
      format={format}
      label={label}
      value={field.value}
      onChange={date => {
        store.onFieldChange(fieldName, date);
        validateDate(store) && onChangedDate(store)
      }}
      disableFuture={disableFuture}
      error={isNotBlank(field.error)}
      helperText={field.error}
      standaloneLabel={standaloneLabel}
      // tooltip={tooltip}
      fullWidth
    />
  );
};

export const createCustomSelectField = ({
  options,
  variant = 'outlined',
  label,
  fieldName,
  testId,
  store,
  disabled,
  tooltip,
  placeholder,
}: SelectFieldProps) => {
  const formFields = store.form.fields;
  const field = formFields[fieldName];
  const required = field.rule.indexOf('required') > -1;

  return (
    <SelectField
      disabled={disabled}
      standaloneLabel
      label={label}
      value={field.value}
      required={required}
      options={options}
      onChange={value => {
        store.onFieldChange(fieldName, value)
        onChangedDate(store);
      }}
      error={isNotBlank(field.error)}
      errorText={field.error}
      variant={variant}
      fullWidth
      testId={testId}
      tooltip={tooltip}
      placeholder={placeholder}
    />
  );
};