import {
  Tooltip as MuiTooltip,
  TooltipProps as MuiTooltipProps,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { Info } from '@material-ui/icons';
import { ReactElement } from 'react';

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    tooltip: {
      whiteSpace: 'pre-line',
      fontSize: theme.typography.pxToRem(14),
      maxWidth: '75vw',
    },
    infoIcon: {
      height: '12px',
      width: '12px',
      marginBottom: theme.spacing(0.5),
      marginRight: theme.spacing(0.5),
    },
    cursorPointer: {
      cursor: 'pointer',
    },
    textWithTooltip: {
      textDecorationLine: 'underline',
      textDecorationStyle: 'dotted',
    },
  })
);

export interface InfoTooltipProps extends Omit<MuiTooltipProps, 'children'> {
  children?: ReactElement;
  placement?:
  | 'right'
  | 'left'
  | 'top'
  | 'bottom'
  | 'bottom-end';
  text?: string;
}

export const InfoTooltip = (props: InfoTooltipProps) => {
  const { children, placement, text, ...muiTooltipProps } = props;
  const classes = useStyle();

  return (
    <MuiTooltip
      placement={placement || "right"}
      classes={{ tooltip: classes.tooltip }}
      {...muiTooltipProps}
    >
      {children ?? (
        <span className={classes.cursorPointer}>
          {text && <span> <span className={classes.textWithTooltip}>{text}</span></span>}
          <Info className={classes.infoIcon} color="primary" />
        </span>
      )}
    </MuiTooltip>
  );
};
