import { Service } from "@roc/feature-app-core";

const url = {
  GET_ALL_USER_BACK_UPS: '/api/v1/user/getAllProcessorUserBackUps',
  SAVE_USER_BACK_UP: '/api/v1/user/saveProcessorUserBackup',
  DELETE_USER_BACK_UP: '/api/v1/user/deleteProcessorUserBackup',
}

export class ManageUsersBackUpsService extends Service {

  url;

  constructor() {
    super();
    this.url = url;
  }
  getAllUserBackUps(pageNumber: number, pageSize: number, sortBy: string, sortDir: string, filters: string, dropdownFilters: string, companyId) {
    return this.get(this.url.GET_ALL_USER_BACK_UPS,
      { pageNumber, pageSize, sortBy, sortDir, filters, dropdownFilters },
      { disableGlobalLoading: true }
    );
  }

  saveUserBackUp(userId: number, userBackupId: number, backupId: number) {
    return this.put(this.url.SAVE_USER_BACK_UP, {
      userId,
      userBackupId,
      backupId
    });
  }

  deleteUserBackUp(userBackupId: number) {
    return this.delete(this.url.DELETE_USER_BACK_UP, {
      userBackupId,
    });
  }

}