import { Grid } from "@material-ui/core";
import { Tabs } from "@roc/ui";
import { ProfitAndDscrCalculatorTab } from "./tabs/profitAndDscrCalculatorTab";
import { DraftsTab } from "./tabs/draftsTab";
import { observer } from "mobx-react";

const TABS = [
  {
    label: 'PROFIT & DSCR CALCULATOR',
  },
  {
    label: 'DRAFT',
  },
];



export const BridgeProfitAndDscrCalculator = observer(({ store }) => {

  const onTabChange = (e, index) => {
    store.setSelectedTabIndex(index);
  };

  const renderTabContent = selectedTabIndex => {
    switch (selectedTabIndex) {
      case 0:
        return <ProfitAndDscrCalculatorTab store={store} />
      case 1:
        return <DraftsTab />;
      default:
        console.error('Invalid tab index');
        break;
    }
  };
  return (
    <Grid
      container
      direction="column"
      spacing={2}
      style={{ display: 'flex', flexGrow: 1, flexDirection: 'column' }}
    >
      {/*<Tabs
        tabs={TABS}
        selectedTab={store.selectedTabIndex}
        onTabChange={onTabChange}
      />*/}
      {/*renderTabContent(store.selectedTabIndex)*/}
      {renderTabContent(0)}
    </Grid>
  )
});