import {
  TextField as AutoCompleteTextField,
  Box,
  Checkbox,
  Chip,
  ListSubheader,
  useMediaQuery
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import { Participant } from '@twilio/conversations';
import React, { useMemo, useState } from 'react';
import { ParticipantAttribute, UserOption } from '../types/communicationTypes';
import { DisplayNameWithOnlineStatus } from '@roc/feature-app-core';
import { ListChildComponentProps, VariableSizeList } from 'react-window';
import { CheckBoxOutlineBlank } from '@material-ui/icons';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const useStyles = makeStyles(theme => ({
  listbox: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
}));

const filterOptions = createFilterOptions({
  matchFrom: 'any',
  stringify: (option: UserOption) => [option.firstName, option.lastName, option.emailAddress].join(' '),
});

const filterExistingParticipants = (options: UserOption[], existingParticipants: Participant[]) => {
  const existingUserIds = [];
  existingParticipants.forEach(p => {
    const { userId } = p.attributes as ParticipantAttribute;
    existingUserIds.push(userId)
  })

  const filteredOptions: UserOption[] = [];
  options.forEach(option => {
    if (!existingUserIds.includes(option.userId)) {
      filteredOptions.push(option);
    }
  })
  return filteredOptions;
}

export const SelectUserAutocomplete = ({
  options,
  multiple,
  label = 'Select Participant',
  showEmailAddress,
  existingParticipants = [],
  onSelectExistingUser,
  allowCreateNewuser,
  onSelectCreateNewUser,
}: {
  options: UserOption[];
  multiple: boolean;
  label?: string;
  existingParticipants?: Participant[];
  showEmailAddress: boolean;
  allowCreateNewuser: boolean;
  onSelectExistingUser: (x: UserOption | UserOption[]) => void;
  onSelectCreateNewUser?: (x: UserOption) => void;
}) => {
  const theme = useTheme();
  const [inputValue, setInputValue] = useState('');
  const filteredOptions = filterExistingParticipants(
    options,
    existingParticipants
  );
  return (
    <Autocomplete
      id="selectUserSearch"
      multiple={multiple}
      options={filteredOptions as UserOption[]}
      filterOptions={filterOptions}
      getOptionLabel={option => option.firstName}
      getOptionSelected={(option, value) => option.userId === value.userId}
      renderInput={params => (
        <AutoCompleteTextField
          {...params}
          label={label}
          variant="outlined"
          placeholder="Search..."
        />
      )}
      renderOption={option => (
        <React.Fragment>
          <div>
            <Box display={'flex'} alignItems={'center'}>
              <DisplayNameWithOnlineStatus
                userId={option.userId}
                firstName={option.firstName}
                lastName={option.lastName}
                isActive={option.active} />
              {
                option.roleDisplayName != null &&
                <Chip variant="default"
                  size="small"
                  label={option.roleDisplayName}
                  style={{
                    height: 18,
                    marginLeft: '10px',
                    backgroundColor: option.roleDisplayColor,
                    color: option.roleDisplayColor ? theme.palette.getContrastText(option.roleDisplayColor) : 'black',
                    fontWeight: 'bold'
                  }}
                />
              }
            </Box>
            {
              showEmailAddress ?
                <div>
                  <span>{option.emailAddress}</span>
                </div> : null
            }
          </div>
        </React.Fragment>
      )}
      onChange={(event, newValue) => {
        if (multiple && newValue && Array.isArray(newValue) && newValue?.length > 0) {
          const usersValue = newValue as UserOption[]
          setInputValue(usersValue[0]?.emailAddress);
          onSelectExistingUser(newValue as UserOption[]);
        }
        else if (newValue) {
          const userValue = newValue as UserOption;
          setInputValue(userValue?.emailAddress);
          onSelectExistingUser(newValue as UserOption);
        } else {
          setInputValue('');
        }
      }}
      onKeyUp={(ev: any) => setInputValue(ev.target?.value)}
      noOptionsText={
        allowCreateNewuser ? <>
          <p
            style={{ color: '#999', cursor: 'pointer' }}
            onMouseDown={() =>
              onSelectCreateNewUser({
                firstName: '',
                lastName: '',
                emailAddress: inputValue,
                userId: null,
                role: '',
                roleDisplayName: '',
                roleDisplayColor: '',
                active: true
              })
            }
          >
            <i>No matches found.</i>
          </p>
          <p
            style={{ color: '#777', cursor: 'pointer' }}
            onMouseDown={() =>
              onSelectCreateNewUser({
                firstName: '',
                lastName: '',
                emailAddress: inputValue,
                userId: null,
                role: '',
                roleDisplayName: '',
                roleDisplayColor: '',
                active: true
              })
            }
          >
            {`Create a new portal user account for "${inputValue}"`}
          </p>
        </> :
          <>
            <p style={{ color: '#999' }}>
              <i>No records found.</i>
            </p>
          </>
      }
    />
  );
};


const LISTBOX_PADDING = 8; // px

function renderRow(props: ListChildComponentProps) {
  const { data, index, style } = props;
  return React.cloneElement(data[index], {
    style: {
      ...style,
      top: (style.top as number) + LISTBOX_PADDING,
    },
  });
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data: any) {
  const ref = React.useRef<VariableSizeList>(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

const getListBoxComponent = (itemSize: number) => {
  return (
    React.forwardRef<HTMLDivElement>(function ListboxComponent(props, ref) {
      const { children, ...other } = props;
      const itemData = React.Children.toArray(children);
      const theme = useTheme();
      const smUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
      const itemCount = itemData.length;

      const getChildSize = (child: React.ReactNode) => {
        if (React.isValidElement(child) && child.type === ListSubheader) {
          return 48;
        }
        return itemSize;
      };

      const getHeight = () => {
        if (itemCount > 8) {
          return 8 * itemSize;
        }
        return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
      };

      const gridRef = useResetCache(itemCount);

      return (
        <div ref={ref}>
          <OuterElementContext.Provider value={other}>
            <VariableSizeList
              itemData={itemData}
              height={getHeight() + 2 * LISTBOX_PADDING}
              width="100%"
              ref={gridRef}
              outerElementType={OuterElementType}
              innerElementType="ul"
              itemSize={(index) => getChildSize(itemData[index])}
              overscanCount={5}
              itemCount={itemCount}
            >
              {renderRow}
            </VariableSizeList>
          </OuterElementContext.Provider>
        </div>
      );
    })
  )
}

export const SelectUserAutocompleteVirtualized = ({
  options,
  multiple,
  label = 'Select Participant',
  showEmailAddress,
  existingParticipants = [],
  onSelectExistingUser,
  allowCreateNewuser = false,
  onSelectCreateNewUser,
}: {
  options: UserOption[];
  multiple: boolean;
  label?: string;
  existingParticipants?: Participant[];
  showEmailAddress: boolean;
  allowCreateNewuser?: boolean;
  onSelectExistingUser: (x: UserOption | UserOption[]) => void;
  onSelectCreateNewUser?: (x: UserOption) => void;
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [inputValue, setInputValue] = useState('');
  const filteredOptions = filterExistingParticipants(
    options,
    existingParticipants
  );
  const _ListboxComponent = useMemo(() => {
    return getListBoxComponent(showEmailAddress ? 75 : 48);
  }, []);
  return (
    <Autocomplete
      id="selectUserSearch"
      multiple={multiple}
      disableListWrap
      disableCloseOnSelect
      classes={classes}
      ListboxComponent={_ListboxComponent as React.ComponentType<React.HTMLAttributes<HTMLElement>>}
      options={filteredOptions as UserOption[]}
      filterOptions={filterOptions}
      getOptionSelected={(option, value) => option.userId === value.userId}
      renderInput={params => (
        <AutoCompleteTextField
          {...params}
          label={label}
          variant="outlined"
          placeholder="Search..."
        />
      )}
      renderOption={(option, { selected }) => (
        <React.Fragment>
          <div>
            <Box display={'flex'} alignItems={'center'}>
              <Checkbox
                icon={<CheckBoxOutlineBlank fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              <DisplayNameWithOnlineStatus
                userId={option.userId}
                firstName={option.firstName}
                lastName={option.lastName}
                isActive={option.active} />
              {
                option.roleDisplayName != null &&
                <Chip variant="default"
                  size="small"
                  label={option.roleDisplayName}
                  style={{
                    height: 18,
                    marginLeft: '10px',
                    backgroundColor: option.roleDisplayColor,
                    color: option.roleDisplayColor ? theme.palette.getContrastText(option.roleDisplayColor) : 'black',
                    fontWeight: 'bold'
                  }}
                />
              }
            </Box>
            {
              showEmailAddress ?
                <Box pl={6}>
                  <span>{option.emailAddress}</span>
                </Box> : null
            }
          </div>
        </React.Fragment>
      )}
      onChange={(event, newValue) => {
        if (multiple && newValue && Array.isArray(newValue) && newValue?.length > 0) {
          const usersValue = newValue as UserOption[]
          setInputValue(usersValue[0]?.emailAddress);
          onSelectExistingUser(newValue as UserOption[]);
        }
        else if (newValue) {
          const userValue = newValue as UserOption;
          setInputValue(userValue?.emailAddress);
          onSelectExistingUser(newValue as UserOption);
        } else {
          setInputValue('');
        }
      }}
      onKeyUp={(ev: any) => setInputValue(ev.target?.value)}
      getOptionLabel={option => option.firstName}
      noOptionsText={
        allowCreateNewuser ? <>
          <p
            style={{ color: '#999', cursor: 'pointer' }}
            onMouseDown={() =>
              onSelectCreateNewUser({
                firstName: '',
                lastName: '',
                emailAddress: inputValue,
                userId: null,
                role: '',
                roleDisplayName: '',
                roleDisplayColor: '',
                active: true
              })
            }
          >
            <i>No matches found.</i>
          </p>
          <p
            style={{ color: '#777', cursor: 'pointer' }}
            onMouseDown={() =>
              onSelectCreateNewUser({
                firstName: '',
                lastName: '',
                emailAddress: inputValue,
                userId: null,
                role: '',
                roleDisplayName: '',
                roleDisplayColor: '',
                active: true
              })
            }
          >
            {`Create a new portal user account for "${inputValue}"`}
          </p>
        </> :
          <>
            <p style={{ color: '#999' }}>
              <i>No records found.</i>
            </p>
          </>
      }
    />
  );
}
