import { makeObservable, override } from 'mobx';
import { StabilizedBridgeAnalyzerService } from '../services/stabilizedBridgeAnalyzerService';
import { StabilizedBridgeAnalyzerFormValues } from '../types/stabilizedBridgeAnalyzerTypes';
import { StabilizedBridgeAnalyzerBaseStore } from './stabilizedBridgeAnalyzerBaseStore';

export class StabilizedBridgeAnalyzerStore extends StabilizedBridgeAnalyzerBaseStore {
  private stabilizedBridgeAnalyzerService: StabilizedBridgeAnalyzerService;

  constructor(globalStore) {
    super(globalStore);
    this.stabilizedBridgeAnalyzerService = new StabilizedBridgeAnalyzerService();

    makeObservable(this, {
      getStabilizedBridgeAnalyzerCalculatedData: override,
      getLoanValues: override,
      getAutocompleteOptions: override,
    });
  }

  *getStabilizedBridgeAnalyzerCalculatedData(data) {
    const response = yield this.stabilizedBridgeAnalyzerService.getStabilizedBridgeAnalyzer(data);
    return response?.data;
  }

  *getLoanValues(loanId: string): StabilizedBridgeAnalyzerFormValues {
    const response = yield this.stabilizedBridgeAnalyzerService.getLoanValues(loanId);
    return response.data.data;
  }

  *getAutocompleteOptions(searchTerm: string) {
    const filters = { dealName: searchTerm };
    const response = yield this.stabilizedBridgeAnalyzerService.getStabilizedDealNames(
      1,
      25,
      null,
      null,
      filters
    );
    const rows = response.data.data.rows;
    return rows.map(row => ({
      label: row.dealName,
      value: row.dealid,
    }));
  }
}
