import { Paper, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ItemDisplay } from '../../../components/itemDisplay';
import { LoanDetailsAccordion } from './loanDetailsAccordion';
import { formatCurrency, formatPercentage } from '@roc/ui/utils';
import { observer } from 'mobx-react';
import { QuoteTypeStore } from '../../../stores/quoteTypeStore';

const useStyles = makeStyles(theme => ({
  paper: {
    border: `1px solid lightgray`,
    borderRadius: '16px !important',
    padding: theme.spacing(4),
  },
}));

interface LoanDetailsCardProps {
  store: QuoteTypeStore;
  keyRatios: string;
  keyRatiosTooltip: string;
}

export const LoanDetailsCard = observer(
  ({ store, keyRatios, keyRatiosTooltip }: LoanDetailsCardProps) => {
    const classes = useStyles();
    const leverage = store.loanEconomicsStore.getLoanEconomics();
    const calculatedFields = store.loanEconomicsStore.calculatedFields;

    const ARLTV = formatPercentage(calculatedFields.afterRepairLTV);

    const color = store.loanEconomicsStore.enableManualQuote
      ? '#FC5E5E'
      : '#34A853';

    return (
      <Paper className={classes.paper} elevation={0}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid
              container
              spacing={2}
              justifyContent="space-between"
              style={{ paddingInline: '240px' }}
            >
              <Grid item>
                <ItemDisplay
                  label="Total Loan Amount"
                  value={formatCurrency(calculatedFields.totalLoanAmount)}
                  valueTextColor={color}
                />
              </Grid>
              <Grid item>
                <ItemDisplay
                  label="Key Ratios"
                  value={keyRatios}
                  valueTextColor={color}
                  tooltip={keyRatiosTooltip}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <LoanDetailsAccordion store={store} />
          </Grid>
        </Grid>
      </Paper>
    );
  }
);
