import { observer } from 'mobx-react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { LenderBankInformationDetailsForm } from './LenderBankInformationDetailsForm';


const useStyles = makeStyles((theme: Theme) => {
  return {
    container: {
      display: 'flex',
      position: 'relative',
      maxWidth: 1300,
      margin: 'auto',
    }
  };
});

export const LenderBankInformationVerificationForm = observer(({ store }) => {
  const classes = useStyles();
  return (
    <Grid container spacing={2} className={classes.container}>
      <LenderBankInformationDetailsForm store={store} />
    </Grid>
  );
});