import { observer } from 'mobx-react';
import { darken, makeStyles } from '@material-ui/core/styles';
import { Box, Divider, Grid, List, ListItem, ListItemText, Tooltip } from '@material-ui/core';
import { Paper } from '@roc/ui';
import { formatCurrency } from '@roc/ui/utils';
import { Layout } from '@roc/feature-app-core';
import { useLoanStore } from '@roc/feature-loans';
import { useEffect } from 'react';
import { Help } from '@material-ui/icons';
import { LoanType } from '@roc/feature-utils';

const useStyles = makeStyles(theme => ({
  paper: {
    marginBottom: theme.spacing(2),
  },
  card: {
    background: theme.palette.background.default,
    borderColor: darken(theme.palette.background.default, 0.1),
  },
  headingBox: {
    width: '60vw',
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      width: '80vw',
      marginLeft: 60,
      marginTop: theme.spacing(1),
    },
  },
  heading: {
    color: '#4B89DA',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: 24,
  },
  tooltip: {
    whiteSpace: 'pre-line',
    fontSize: theme.typography.pxToRem(14),
    maxWidth: 'none',
  },
  helpIcon: {
    height: '18px',
    width: '18px',
    marginLeft: theme.spacing(0.5),
  },
  tooltipStyle: {
    '& li': {
      marginLeft: '25px',
    },
  },
}));

const listItem = (label, value, bold) => {
  return (
    <ListItem>
      <ListItemText>
        <Box display="flex">
          <Box flexGrow={1}>{label}</Box>
          {bold && <Box fontWeight={'bold'}>{value}</Box>}
          {!bold && <Box>{value}</Box>}        </Box>
      </ListItemText>
    </ListItem>
  );
};

const listItemTooltip = (label, value, tooltip, bold) => {
  const classes = useStyles();

  return (
    <ListItem>
      <ListItemText>
        <Box display="flex">
          <Box>{label}</Box>
          <Tooltip
            placement="left"
            title={
              <div
                className={classes.tooltipStyle}
                dangerouslySetInnerHTML={{
                  __html: tooltip,
                }}
              />
            }
            classes={{
              tooltip: classes.tooltip,
            }}
          >
            <Help className={classes.helpIcon} color="primary" />
          </Tooltip>
          <Box flexGrow={1} />
          {bold && <Box fontWeight={'bold'}>{value}</Box>}
          {!bold && <Box>{value}</Box>}
        </Box>
      </ListItemText>
    </ListItem>
  );
}

const divider = <Divider variant="middle" component="li" />;

export const EstimatedClosingCosts = observer(props => {
  const { loanStore } = useLoanStore();
  const { loanDetails } = loanStore;
  const classes = useStyles();
  const isBridgeLoan = loanDetails.loanType === LoanType.RESIDENTIAL_BRIDGE;

  useEffect(() => {
    switch (loanDetails.loanType) {
      case LoanType.RESIDENTIAL_BRIDGE:
        loanStore.fetchBridgeLoanClosingCosts(loanDetails.loanId);
        break;
      case LoanType.RESIDENTIAL_TERM:
        loanStore.fetchRentalLoanClosingCosts(loanDetails.loanId);
        break;
    }
  }, [loanStore, loanDetails.loanId]);

  return (
    <Layout
      headingClass={classes.heading}
      headingBoxClass={classes.headingBox}
      title="Estimated Closing Costs"
    >
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          <Paper title="CLOSING EXPENSES" className={classes.paper}>
            <Box>
              <List>
                {listItem('Acquisition Price', formatCurrency(loanStore.estimatedClosingCosts?.acquisitionPrice), false)}
                {divider}
                {listItem('Existing Debt Payoff', formatCurrency(loanStore.estimatedClosingCosts?.existingDebtPayoff), false)}
                {divider}
                {listItem('Lender Origination Fees', formatCurrency(loanStore.estimatedClosingCosts?.lenderOriginationFees), false)}
                {divider}
                {listItem('Broker Origination Fees', formatCurrency(loanStore.estimatedClosingCosts?.brokerOriginationFees), false)}
                {divider}
                {listItem('Underwriting Fee', formatCurrency(loanStore.estimatedClosingCosts?.underwritingFee), false)}
                {divider}
                {listItem('Processing Fee', formatCurrency(loanStore.estimatedClosingCosts?.processingFee), false)}
                {divider}
                {listItem('Servicing Setup Fee', formatCurrency(loanStore.estimatedClosingCosts?.servicingSetupFee), false)}
                {divider}
                {listItem('Legal Review Fee', formatCurrency(loanStore.estimatedClosingCosts?.legalReviewFee), false)}
                {divider}
                {!isBridgeLoan && (
                  <>
                    {listItem('P&I Reserves at Close', formatCurrency(loanStore.estimatedClosingCosts?.interestReserve || 0), false)}
                    {divider}
                    {listItem('Tax Escrow at Close', formatCurrency(loanStore.estimatedClosingCosts?.taxEscrowAtClose || 0), false)}
                    {divider}
                    {listItem('Insurance Escrow at Close', formatCurrency(loanStore.estimatedClosingCosts?.insuranceEscrowAtClose || 0), false)}
                    {divider}
                  </>
                )}
                {listItem('Third Party Closing Costs', formatCurrency(loanStore.estimatedClosingCosts?.thirdPartyEstimatedClosingCost), false)}
                {divider}
                {isBridgeLoan && listItem('Interest Reserve', formatCurrency(loanStore.estimatedClosingCosts?.interestReserve), false)}
                {divider}
                {listItem('Total',formatCurrency(loanStore.estimatedClosingCosts?.closingExpenseTotal), true)}
                {divider}
                {listItemTooltip('Initial Loan Amount', formatCurrency(loanStore.estimatedClosingCosts?.initialLoanAmount), 'Amount released at closing, not including construction holdback', false)}
                {divider}
                {listItemTooltip('Estimated Cash to Borrower at Closing', formatCurrency(loanStore.estimatedClosingCosts?.borrowerCashAtClose), 'Total closing expenses - Initial loan amount', true)}
              </List>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Layout >
  );
});