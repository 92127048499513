import { Alert } from '@material-ui/lab';
import { observer } from 'mobx-react';
import React, { useEffect, useRef } from 'react';
import { useStore } from '../../../hooks';
import { Typography } from '@material-ui/core';

const IframeHolder = ({ id, src, width, height, store }) => {
  const container = useRef();
  useEffect(() => {
    const frm = document.createElement('iframe');
    frm.src = src;
    frm.width = width;
    frm.height = height;
    frm.setAttribute('style', 'border: none;');
    container.current;
    document.getElementById(id).appendChild(frm);
    frm.onload = () => {
      store.setLoading(false);
    };
  }, []);

  return (
    <div id={id} style={{ minHeight: 'calc(100vh - 360px)', height: '100%' }} />
  );
};

export const DocumentSigning = observer(({ companyProfileStore }) => {
  const { loanFundingPreferenceStore } = companyProfileStore;
  const { mersOnboardingStore } = loanFundingPreferenceStore;

  useEffect(() => {
    mersOnboardingStore.setLoading(true);
    mersOnboardingStore.submitFormToDocusign();
  }, []);

  if (mersOnboardingStore.docusignError) {
    mersOnboardingStore.setLoading(false);
    return <Alert severity="error">Error loading document signing view</Alert>;
  }

  return (
    <>
      <Typography variant="h4">
        Electronic Signature
      </Typography>
      {mersOnboardingStore.docusignUrl ? (
        <IframeHolder
          id="mers-onboarding-form"
          src={mersOnboardingStore.docusignUrl}
          width="100%"
          height="100%"
          store={mersOnboardingStore}
        />
      ) : (
        null
      )}
    </>
  );
});
