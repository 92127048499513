import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import {
  Grid,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { Paper } from '@roc/ui';
import { useDrawsStore } from '../hooks';
import { useLoanStore } from '@roc/feature-loans';
import EditPointOfContactForm from './editPointOfContactForm';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: 24,
  },
  container: {
    padding: 16,
  },
  editButton: {
    cursor: 'pointer',
  }
}));

const PointOfContactCard = observer(() => {
  const classes = useStyles();
  const { pointOfContactStore } = useDrawsStore();
  const { loanStore } = useLoanStore();

  useEffect(() => {
    return () => pointOfContactStore.resetStore();
  }, [])

  useEffect(() => {
    pointOfContactStore.getPointOfContact(loanStore.loanDetails.loanId);
  }, [loanStore.loanDetails.loanId])

  return (
    <Grid item md={6} xs={12}>
      <Paper className={classes.paper}>
        <Grid container direction="column" item className={classes.container}>
          <Grid item container direction='row' justifyContent='space-between'>
            <Grid item>
              <Typography variant="h6">
                POINT OF CONTACT
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Typography variant="caption" color="textSecondary">
              This person will provide the authenticated photographs of the property or coordinate with the inspection agent.
            </Typography>
          </Grid>
        </Grid>
        <Grid container direction="column">
          <EditPointOfContactForm />
        </Grid>
      </Paper>
    </Grid>)
});

export default PointOfContactCard;