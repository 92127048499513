import {
  AppBar,
  Badge,
  Box,
  ButtonGroup,
  Divider,
  Drawer,
  FormControl,
  Hidden,
  IconButton, InputAdornment, InputLabel, Link, MenuItem, NativeSelect, Select, Toolbar, Typography
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { ArrowBack, ArrowForward, Close, DuoRounded, Face, ForumOutlined, MenuOpenOutlined, Search } from '@material-ui/icons';
import { useAppConfigurations, useBaseStore, useHeaderHeight, useUserStore, ImpersonateHeader, useEnvironmentStore, useSearchBarStore } from '@roc/feature-app-core';
import { isElectronApp } from '@roc/feature-utils';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { NavigationCompany } from './navigationCompany';
import { MobileNavigationMenu, NavigationMenu } from './navigationMenu';
import { ProfileMenu } from './profileMenu';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import { TechSupportChatContainer } from '../../../../feature-communication/src/communication/techSupportChatContainer';

import { SearchBar, SearchIcon } from './searchBar';
import { Button, ConditionalWrapper, Paper, TextField, Tooltip } from '@roc/ui';
import { useLogoutStore } from '@roc/feature-app-core';
import { useBackwardForwardNavigation } from '../../hooks/useBackwardForwardNavigation';
import clsx from 'clsx';

const drawerWidth = 250;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    imageHolder: {
      height: (props: any) => props.headerHeight,
    },
    img: {
      display: 'block',
      padding: (props: any) => props.slimNavigation ? 6 : 10,
      maxHeight: (props: any) => props.headerHeight - 2,
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      height: (props: any) => props.headerHeight,
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      justifyContent: 'flex-end',
    },
    spacer: {
      height: (props: any) => props.headerHeight,
    },
    videoRoomIconHolder: {
      '& .MuiBadge-badge': {
        top: 4
      },
      '& *': {
        color: 'white !important'
      },
    },
    videoRoomIconButton: {
      padding: 4,
      transition: 'background 0.3s ease-out',
      background: 'linear-gradient(to right, #4347b1, #36c3e7)',
      '& *': {
        color: 'white !important'
      },
      '&:hover': {
        backgroundPosition: 32,
      }
    },
    videoRoomButton: {
      width: 160,
      textTransform: 'capitalize',
      letterSpacing: 'inherit',
      transition: 'background 0.3s ease-out',
      background: 'linear-gradient(to right, #4347b1, #36c3e7)',
      '& *': {
        color: 'white'
      },
      '&:hover': {
        backgroundPosition: 160,
      }
    },
    buttonGroup: {
      justifyContent: 'right',
      width: '100%',
      maxWidth: 200,
    },
    buttonDisabled: {
      '& *': {
        color: '#c5c5c5 !important'
      }
    },
    closeSearchButton: {
      padding: theme.spacing(0.5),
    },
    searchBar: {
      width: 300,
      marginLeft: 16,
      border: 0,
      '& input, & svg path': {
        color: theme.palette.text.primary
      },
      '& fieldset': {
        boxShadow: theme.shadows[0],
        borderColor: '#EFEFEF',
      },
    },
    crmPortalStyles: {
      '& .main-navigation-menu button *': {
        fontSize: 12,
      },
      '& .main-navigation-menu button .MuiButton-endIcon': {
        margin: 0,
      }
    }
  })
);

export interface NavigationProps {
  overrideAppLogo?: string;
  appBarClassName?: string;
  navigationMenuData?: any;
  profileMenuData?: any;
  redirectRoute?: any;
  loanRoutes?: any;
  possibleMatchesRoutes?: any;
  searchBar?: any;
  searchDrawer?: any;
  logoAsLink?: boolean;
  onLoanChatMeetClick?: () => void;
  showSupportIcon?: boolean;
  showCompanyDropdown?: boolean;
}

export const Navigation = observer(({
  overrideAppLogo,
  appBarClassName,
  navigationMenuData = [],
  profileMenuData = [],
  redirectRoute = '',
  loanRoutes = null,
  possibleMatchesRoutes = null,
  searchBar,
  searchDrawer,
  logoAsLink = true,
  onLoanChatMeetClick,
  showSupportIcon = true,
  showCompanyDropdown = true
}: NavigationProps) => {
  const { globalStore } = useBaseStore();
  const { userStore } = useUserStore();
  const headerHeight = useHeaderHeight();
  const classes = useStyles({
    searchBar,
    headerHeight,
    slimNavigation: globalStore.slimNavigation
  });
  const { appLogo } = useAppConfigurations();
  const [open, setOpen] = React.useState(false);
  const { logoutStore } = useLogoutStore();
  const { environmentStore } = useEnvironmentStore();
  const location = useLocation();
  const { canGoBack, canGoForward, goBack, goForward } = useBackwardForwardNavigation();
  const queryParams = new URLSearchParams(location.search);
  const logoLinkType = queryParams.get('logoLinkType') || 'ROUTER_LINK';
  const { searchBarStore } = useSearchBarStore();
  const [isCrmPortal, setIsCrmPortal] = useState<boolean>(false);

  useEffect(() => {
    if (searchDrawer) {
      setIsCrmPortal(true);
    }
  }, [searchDrawer])

  const handleLogoClick = () => {
    // TODO
  };

  const toggleDrawer = event => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setOpen(false);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={clsx(classes.root, isCrmPortal ? classes.crmPortalStyles : "")}>
      <EnvironmentText />
      <ImpersonateHeader show={globalStore?.impersonatedUser} onExit={() => { logoutStore.logout() }} />
      <AppBar color="inherit" position="fixed" className={appBarClassName}>
        {
          isCrmPortal &&
          <Box position={'absolute'} width={'100%'} height={'auto'}>
            <Box display={'flex'}>
              <Button
                testId='SearchButton'
                variant='outlined'
                startIcon={<Search />}
                title='Search CRM (or) Loans'
                onClick={() => searchBarStore.setShowSearch(true)}
                style={{
                  height: 26,
                  margin: '12px auto',
                  width: '50%',
                  zIndex: 1,
                  maxWidth: '50%',
                  justifyContent: 'flex-start',
                  textTransform: 'unset',
                  fontSize: 12,
                  fontWeight: 'normal',
                }}
              >
                Search CRM (or) Loans ...
              </Button>
            </Box>
          </Box>
        }
        <Toolbar style={{
          minHeight: 'auto',
          overflow: 'auto'
        }}>
          <Hidden mdUp implementation="css">
            {
              navigationMenuData.length ? <IconButton
                edge="start"
                className={classes.menuButton}
                style={{ transform: 'rotate(180deg)' }}
                color="primary"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
              >
                <MenuOpenOutlined />
              </IconButton> : null
            }
          </Hidden>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            className={classes.imageHolder}
          >
            {(overrideAppLogo || appLogo) && (
              <ConditionalWrapper
                condition={logoAsLink}
                wrapper={(children) => {
                  return (
                    logoLinkType === 'HTML_LINK' ?
                      <a href={'/'}>
                        {children}
                      </a>
                      :
                      <RouterLink to={globalStore.routeBaseUrl}>
                        {children}
                      </RouterLink>
                  )
                }}>
                <img
                  className={classes.img}
                  alt="App Logo"
                  src={overrideAppLogo ? overrideAppLogo : appLogo}
                  onClick={() => handleLogoClick()}
                />
              </ConditionalWrapper>
            )}
          </Box>
          <Box display="flex" flexDirection="row" maxWidth="100%" overflow="auto" flexGrow={1} style={{
            paddingTop: isCrmPortal ? 42 : 0
          }} className='main-navigation-menu'>
            <Box flexGrow="1">
              <Hidden smDown implementation="css">
                <NavigationMenu data={navigationMenuData} isCrmPortal={isCrmPortal} />
              </Hidden>
            </Box>
          </Box>
          {showSupportIcon && userStore.allowLoanPricing && !isElectronApp() &&
            <Box display="flex" alignItems="center" justifyContent="flex-end" minWidth={80} style={{
              paddingTop: isCrmPortal ? 34 : 0
            }}>
              <TechSupportChatContainer />
            </Box>
          }
          {
            globalStore.isLoanChatApp &&
            <ButtonGroup variant='outlined' size="large" color="primary" className={classes.buttonGroup}>
              <Tooltip title="Back in history" placement='bottom' arrow>
                <IconButton
                  onClick={() => {
                    goBack();
                  }}
                  className={!canGoBack ? classes.buttonDisabled : ''}
                  disabled={!canGoBack}>
                  <ArrowBack />
                </IconButton>
              </Tooltip>
              <Tooltip title="Forward in history" placement='bottom' arrow>
                <IconButton
                  onClick={() => {
                    goForward();
                  }}
                  className={!canGoForward ? classes.buttonDisabled : ''}
                  disabled={!canGoForward}>
                  <ArrowForward />
                </IconButton>
              </Tooltip>
            </ButtonGroup>
          }
          {
            searchBar ?
              <Box display="flex" flexDirection="row" maxWidth="100%" width={"100%"} justifyContent="center">
                {
                  searchBar
                }
              </Box> : null
          }
          {
            searchDrawer ? (
              <>
                <Drawer
                  anchor={'top'}
                  open={searchBarStore.showSearch}
                  onClose={() => searchBarStore.setShowSearch(false)}
                >
                  {searchDrawer}
                </Drawer>
                <Box display="flex" flexDirection="row" maxWidth="100%" alignItems="center" justifyContent="flex-end" marginRight="15px" style={{
                  paddingTop: isCrmPortal ? 42 : 0
                }}>
                  {/* <SearchIcon onClick={() => searchBarStore.setShowSearch(true)} /> */}

                </Box>
              </>
            ) : null
          }
          {
            showCompanyDropdown && globalStore.userFeatures?.allowLoanSearch && !isElectronApp() ?
              <Box display="flex" flexDirection="row" maxWidth="100%" alignItems="center" justifyContent="flex-end" style={{
                paddingTop: isCrmPortal ? 34 : 0
              }}>
                <>
                  {!searchDrawer && searchBarStore.showSearch ?
                    <SearchBar loanRoutes={loanRoutes} onClose={() => searchBarStore.setShowSearch(false)} />
                    : !searchDrawer ?
                      <SearchIcon onClick={() => searchBarStore.setShowSearch(true)} />
                      : null
                  }
                  {globalStore.userFeatures?.showMultiCompanyDropdown ?
                    <NavigationCompany redirectRoute={redirectRoute} /> : null
                  }
                </>
              </Box> : null
          }

          {/* {
            isElectronApp() && environmentStore?.environmentVariables?.features?.loanChatMeet ?
              <Box display="flex" alignItems="center" justifyContent="flex-end" mx={1} className={classes.videoRoomIconHolder}>
                <ConditionalWrapper
                  condition={new Date("2023-08-31") >= new Date()}
                  wrapper={children => {
                    return (
                      <Badge
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        badgeContent={'New'}
                        color={'error'}
                      >
                        {children}
                      </Badge>
                    )
                  }}>
                  <Hidden mdUp implementation="css">
                    <IconButton
                      className={classes.videoRoomIconButton}
                      onClick={onLoanChatMeetClick}>
                      <DuoRounded />
                    </IconButton>
                  </Hidden>
                  <Hidden smDown implementation="css">
                    <Button testId='loanChatMeet'
                      variant='contained'
                      startIcon={<DuoRounded />}
                      className={classes.videoRoomButton}
                      onClick={onLoanChatMeetClick}>
                      LoanChat Meet
                    </Button>
                  </Hidden>
                </ConditionalWrapper>
              </Box> : null
          } */}
          {
            profileMenuData.length ?
              <Box display="flex" alignItems="center" justifyContent="flex-end" minWidth={80} style={{
                paddingTop: isCrmPortal ? 34 : 0
              }}>
                <IconButton
                  aria-label="show unread messages"
                  color="inherit"
                  style={{ display: 'none' }}
                >
                  <Badge badgeContent={10} color="error">
                    <ForumOutlined />
                  </Badge>
                </IconButton>
                <ProfileMenu data={profileMenuData} />
              </Box> : null
          }
        </Toolbar>
      </AppBar>
      <Hidden mdUp implementation="css">
        <Drawer
          className={classes.drawer}
          anchor="left"
          open={open}
          onClose={toggleDrawer}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader}>
            <IconButton onClick={handleDrawerClose}>
              <MenuOpenOutlined />
            </IconButton>
          </div>
          <Divider />
          <MobileNavigationMenu
            data={navigationMenuData}
            onMenuItemClick={() => setOpen(false)}
          />
        </Drawer>
      </Hidden>
      <div className={classes.spacer} />
    </div>
  );
});


const useEnvironmentTextStyles = makeStyles((theme: Theme) =>
  createStyles({
    environmentText: {
      position: 'fixed',
      WebkitTransform: 'rotate(315deg)',
      MozTransform: 'rotate(315deg)',
      OTransform: 'rotate(315deg)',
      zIndex: 20004,
      color: 'white',
      top: -10,
      left: -34,
      width: 86,
      fontSize: 10,
      textAlign: 'center',
      fontWeight: 'bold',
      paddingTop: 20,
      letterSpacing: 1,
      fontFamily: 'sans-serif'
    }
  })
);

const EnvironmentText = observer(() => {
  const classes = useEnvironmentTextStyles();
  const { userStore } = useUserStore();
  useEffect(() => {
    userStore.getDeployEnvironment();
  }, []);
  const getColor = () => {
    switch (userStore.deployEnvironment) {
      case 'QA':
        return '#2196f3';
      case 'STG':
        return 'green';
      default:
        return '#535353';
    }
  }

  const show = userStore.deployEnvironment == 'LOCAL'
    || userStore.deployEnvironment == 'DEV'
    || userStore.deployEnvironment == 'QA'
    || userStore.deployEnvironment == 'STG';

  return (
    show ? <Box className={classes.environmentText} bgcolor={getColor()}>
      {userStore.deployEnvironment}
    </Box> : null
  )
});

export default Navigation;
