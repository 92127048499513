import { Grid, makeStyles } from "@material-ui/core";
import { isNil } from "@roc/feature-utils";
import { DataGrid, Paper, VerticalBarLoader } from "@roc/ui";
import { observer } from "mobx-react";
import { useEffect } from "react";


const useStyles = makeStyles(theme => ({
  paper: {
    margin: theme.spacing(2),
    size: '5px',
    '& h5': {
      fontSize: '1.2rem',
    },
    borderRadius: '1px'
  },
  containerSmall: {
    maxHeight: '220px',
    overflow: 'auto'
  },
  containerLarge: {
    maxHeight: '900px',
    overflow: 'auto'
  }

}));

export const TodaysClosingDataGrids = observer(({ store }) => {
  const classes = useStyles();
  const today = new Date();
  const completeDate = today.toDateString();

  useEffect(() => {
    store.fetchTodaysClosingData();
  }, []);
  const { todaysClosingColumns, loading } = store;

  const renderTable = (title, data, tableColumns, smallGrid = false, pinnedRow = []) => {
    return (
      <>
        <>
          <Grid item xs={6}>
            <Paper title={title} className={classes.paper}>
              <Grid container className={smallGrid ? classes.containerSmall : classes.containerLarge}>
                <DataGrid
                  columns={tableColumns}
                  rows={isNil(data) ? [] : data.rows}
                  domLayout="autoHeight"
                  pinnedBottomRowData={pinnedRow}
                />
              </Grid>
            </Paper>
          </Grid>
        </>
      </>
    );
  };

  return (
    <>
      {store.todaysClosingData.length == 0 || loading ? (
        <>
          <VerticalBarLoader />
        </>
      ) : (
        <Grid container spacing={2}>
          {
            renderTable(store.todaysClosingData.totalClosed.title, store.todaysClosingData.totalClosed, todaysClosingColumns.totalClosed, true)}
          {
            renderTable(store.todaysClosingData.netFunded.title, store.todaysClosingData.netFunded, todaysClosingColumns.netFunded, true)}
          {
            renderTable(store.todaysClosingData.fundedLoans.title, store.todaysClosingData.fundedLoans, todaysClosingColumns.fundedLoans, false, store.totalFundedLoans)}
          {
            renderTable(store.todaysClosingData.waitingLoans.title, store.todaysClosingData.waitingLoans, todaysClosingColumns.waitingAdjournedLoans, false, store.totalWaitingLoans)}
          {
            renderTable(store.todaysClosingData.nonProjectedFundedLoans.title, store.todaysClosingData.nonProjectedFundedLoans, todaysClosingColumns.nonProjectedFundedLoans, false, store.totalNonProjectedFundedLoans)}
          {
            renderTable(store.todaysClosingData.retractedLoans.title, store.todaysClosingData.retractedLoans, todaysClosingColumns.retractedLoans, false, store.totalRetractedLoans)}
        </Grid>
      )}
    </>
  );
});