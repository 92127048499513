import React from 'react';
import {
  Card as MuiCard,
  CardContent,
  CardHeader,
  IconButton,
  TypographyProps,
  CardProps,
  CardActions,
} from '@material-ui/core';

export interface PanelProps<
  TitleTypographyComponent extends React.ElementType = 'span'
  > extends CardProps {
  children?: React.ReactNode;
  titleTypographyProps?: TypographyProps<
    TitleTypographyComponent,
    { component?: TitleTypographyComponent }
  >;
}

export const Panel = ({
  title,
  titleTypographyProps,
  children,
  ...rest
}: PanelProps) => {
  return (
    <MuiCard variant="outlined" {...rest}>
      <CardHeader title={title} titleTypographyProps={titleTypographyProps} />
      {children && <CardContent>{children}</CardContent>}
      <CardActions>&nbsp;</CardActions>
    </MuiCard>
  );
};
