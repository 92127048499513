import { observable, makeObservable, action, flow } from 'mobx';
import { GlobalStore } from '@roc/feature-app-core';
import { PersonalInformationFormStore } from './personalInformationFormStore';
import { IdInformationStore } from './idInformationStore';
import { FinancialInformationFormStore } from './financialInformationFormStore';
import { formatDate } from '@roc/feature-utils';
import { differenceInYears, parse, startOfToday } from 'date-fns';
import { FormInfo } from '../../borrowers/types';
import { ContactDetailsFormStore } from './contactDetailsFormStore';

export interface IBorrowerSetupToDoBaseStore {

  globalStore: GlobalStore;
  personalInformationFormStore: PersonalInformationFormStore;
  idInformationStore: IdInformationStore;
  financialInformationFormStore: FinancialInformationFormStore;

  activeStep: number;
  currentBorrowerId: number;
  currentBorrower: any;
  borrowerDetailsVerification: FormInfo;
  showDob: boolean;
  showSsn: boolean;
  disableNameFields: boolean;
  embeddedDocusignLink: string;
  borrowerIdvRequestResponse: any;

  setDefaults(): void;
  reset(): void;
  goNextStep(): void;
  goPrevStep(): void;
  loadStores(): void;

  getBorrowerPrescreenInfo(borrowerId: number, loan?: any): void;
  getBorrowerDetailsVerification(token?: string): void;
  generateAu10tixIdVerification(borrowerId: number): void;
  isInvalidDateOfBirthDate(dateOfBirth): boolean;
  isInvalidSSN(ssn: string): boolean;
  savePersonalInformation(): void;
  saveIdInformation(): void;
  saveFinancialInformation(): void;
  saveSomePersonalFinancialInformation(): void;
  saveCreditInformation(): void;
  confirmDocusign(): void;
  saveBorrowerIdentityVerificationId(): void;
}

export abstract class BorrowerSetupToDoBaseStore implements IBorrowerSetupToDoBaseStore {

  globalStore: GlobalStore;
  personalInformationFormStore: PersonalInformationFormStore;
  idInformationStore: IdInformationStore;
  financialInformationFormStore: FinancialInformationFormStore;
  contactDetailsFormStore: ContactDetailsFormStore;

  activeStep: number;
  currentBorrowerId: number;
  currentBorrower: any;
  borrowerDetailsVerification: FormInfo;
  showDob: boolean;
  showSsn: boolean;
  disableNameFields: boolean;
  embeddedDocusignLink: string;
  borrowerIdvRequestResponse: any;

  constructor(globalStore) {
    this.globalStore = globalStore;
    this.personalInformationFormStore = new PersonalInformationFormStore(globalStore);
    this.idInformationStore = new IdInformationStore(globalStore);
    this.financialInformationFormStore = new FinancialInformationFormStore(globalStore);
    this.contactDetailsFormStore = new ContactDetailsFormStore(globalStore);

    makeObservable(this, {
      activeStep: observable,
      currentBorrowerId: observable,
      currentBorrower: observable,
      borrowerDetailsVerification: observable,
      showDob: observable,
      showSsn: observable,
      disableNameFields: observable,
      embeddedDocusignLink: observable,
      borrowerIdvRequestResponse: observable,
      reset: action,
      goNextStep: action,
      goPrevStep: action,
    });
    this.setDefaults();
  }

  setDefaults() {
    this.activeStep = 0;
    this.currentBorrowerId = null;
    this.currentBorrower = {};
    this.borrowerDetailsVerification = null;
    this.showDob = true;
    this.showSsn = true;
    this.disableNameFields = true;
    this.embeddedDocusignLink = '';
    this.borrowerIdvRequestResponse = null;
  }

  reset() {
    this.personalInformationFormStore.reset();
    this.idInformationStore.reset();
    this.financialInformationFormStore.reset();
    this.setDefaults();
  }

  goPrevStep() {
    this.activeStep--;
  }

  goNextStep() {
    this.activeStep++;
  }

  abstract loadStores(data?: any): void;
  abstract getBorrowerPrescreenInfo(borrowerId: number, loan?: any): void;
  abstract getBorrowerDetailsVerification(token?: string): void;
  abstract generateAu10tixIdVerification(borrowerId: number): void;

  isInvalidDateOfBirthDate = (dateOfBirth) => {
    const date = formatDate(dateOfBirth, 'MM/dd/yyyy')
    return (
      dateOfBirth &&
      differenceInYears(startOfToday(), parse(date, 'MM/dd/yyyy', new Date())) < 18
    );
  }

  isInvalidSSN = (ssn: string) => {
    return this.borrowerDetailsVerification?.invalidSSN?.includes(ssn);
  }

  abstract savePersonalInformation(): void;
  abstract saveIdInformation(): void;
  abstract saveFinancialInformation(): void;
  abstract saveSomePersonalFinancialInformation(): void;
  abstract saveCreditInformation(): void;
  abstract confirmDocusign(): void;
  abstract saveBorrowerIdentityVerificationId(): void;

}