import { Box, Divider, Grid } from '@material-ui/core';
import { Button } from '@roc/ui';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { useNewLoanApplicationStore } from '../hooks/useNewLoanApplicationStore';
import { PropertyFormStore } from '../store/propertyFormStore';
import { LoanApplicationAccordion } from './loanApplicationComponents';
import { PropertyForm } from './propertyForm';

export const EditPropertiesForm = observer(() => {
  const { newLoanApplicationStore } = useNewLoanApplicationStore();
  const { propertyStore } = newLoanApplicationStore;

  const [accordionIndex, setAccordionIndex] = useState(0);

  const toggleAccordion = i => {
    setAccordionIndex(i === accordionIndex ? null : i);
  };

  return (
    <Grid container spacing={2}>
      {propertyStore.editPropertiesForms.map((store, i) => (
        <>
          <Grid item xs={12}>
            <PropertyAccordionItem
              store={store}
              expanded={accordionIndex === i}
              onChange={() => toggleAccordion(i)}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider style={{ height: '1px', backgroundColor: '#B9B9B9' }} />
          </Grid>
        </>
      ))}
    </Grid>
  );
});

export const AddPropertyForm = observer(() => {
  const { newLoanApplicationStore } = useNewLoanApplicationStore();
  const { propertyStore } = newLoanApplicationStore;

  return <PropertyForm store={propertyStore.addPropertyForm} />;
});

const PropertyAccordionItem = observer(({ store, expanded, onChange }) => {
  const { newLoanApplicationStore } = useNewLoanApplicationStore();
  const { propertyStore } = newLoanApplicationStore;
  const { propertyInformationStore } = store as PropertyFormStore;
  const address = propertyInformationStore.form.fields.address.value;

  return (
    <LoanApplicationAccordion
      expanded={expanded}
      onChange={onChange}
      summary={address}
      overflowExpandedBackground
    >
      <Box>
        <PropertyForm store={store} />
        <Box py={2} display="flex" justifyContent="flex-end">
          <Button
            onClick={() => propertyStore.removeProperty(store)}
            color="primary"
            variant="outlined"
            testId="remove-property"
          >
            REMOVE PROPERTY
          </Button>
        </Box>
      </Box>
    </LoanApplicationAccordion>
  );
});
