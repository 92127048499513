import { Tooltip } from "@material-ui/core";
import { Theme, makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    margin: '1px 4px 1px 1px',
    padding: '0px 5px',
    border: '2px solid white',
    borderRadius: 24,
    outline: '1px solid red',
    background: 'red',
    color: 'white',
    display: 'inline-flex',
    fontWeight: 'bold',
    fontSize: 12,
  }
}));

export const BorrowerIndicatorIcon = ({
  className,
  showTooltip = true,
  tooltipText = 'This room has a borrower in it.',
}: {
  showTooltip?: boolean
  tooltipText?: string
  className?: string
}) => {
  const classes = useStyles();
  const result = (
    <span className={clsx(classes.icon, className ? className : '')}>B</span>
  );
  return (
    <>
      {
        showTooltip ?
          <Tooltip title={tooltipText}>
            {result}
          </Tooltip> : result
      }
    </>
  )
}