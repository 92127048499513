import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, Typography } from '@material-ui/core';
import { format, isValid } from 'date-fns';
import { createDateField, createNumberFormatField, createPhoneField, createSelectField, createTextField, Tooltip } from '@roc/ui';
import { citizenshipStatusOptions } from '@roc/feature-utils';
import { Address } from '../address';
import { Help } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles(() => {
  return {
    container: {
      display: 'flex',
      position: 'relative',
      maxWidth: 1300,
      margin: 'auto',
    },
  };
});

export const PublicPersonalInformation = observer(({ store }) => {

  const classes = useStyles();

  return (
    <Grid container spacing={2} className={classes.container} alignItems='center'>
      <PublicPersonalDetails store={store.personalInformationFormStore} disableNameFields={store.disableNameFields} />
      <Grid item xs={12} />
      <Address store={store.personalInformationFormStore} />
    </Grid>
  );
});

const usePersonalDetailsStyles = makeStyles(() => {
  return {
    icon: {
      fontSize: '18px',
      marginLeft: '5px',
      marginBottom: '2px'
    },
    infoBox: {
      width: '100%',
      flexDirection: 'column',
    }
  };
});

export const PublicPersonalDetails = observer(({ store, disableNameFields }) => {

  const classes = usePersonalDetailsStyles();

  const manageDate = (fieldName, date) => {
    let formattedDate = null;
    if (isValid(date)) {
      formattedDate = format(date, 'MM/dd/yyyy');
    }
    store.onFieldChange(fieldName, formattedDate);
  };

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h5">
          Personal Details
          <Tooltip title="This should be your full legal name as it appears on your government issued ID.">
            <Help className={classes.icon} fontSize="small" />
          </Tooltip>
        </Typography>
      </Grid>
      {disableNameFields &&
        <Grid item xs={12}>
          <Box className={classes.infoBox}>
            <Alert severity="info">
              Please note you cannot change the first name and last name. If you feel the first name and last name is wrong, please reach out to your sales representative and he will work to change the name. Please note that you should fill out the information related to the person listed here only
            </Alert>
          </Box>
        </Grid>
      }
      <Grid item xs={12} sm={6}>
        {createTextField({
          label: 'First Name',
          fieldName: 'firstName',
          testId: 'firstName',
          store: store,
          disabled: disableNameFields,
        })}
      </Grid>
      <Grid item xs={12} sm={6}>
        {createTextField({
          label: 'Middle Name',
          fieldName: 'middleName',
          testId: 'middleName',
          store: store,
          disabled: disableNameFields,
        })}
      </Grid>
      <Grid item xs={12} sm={6}>
        {createTextField({
          label: 'Last Name',
          fieldName: 'lastName',
          testId: 'lastName',
          store: store,
          disabled: disableNameFields,
        })}
      </Grid>
      <Grid item xs={12} sm={6}>
        {createPhoneField({
          label: 'Phone Number',
          fieldName: 'phoneNumber',
          testId: 'phoneNumber',
          store: store,
        })}
      </Grid>
      <Grid item xs={12} sm={6}>
        {createDateField({
          label: 'Date of Birth',
          fieldName: 'dateOfBirth',
          testId: 'dateOfBirth',
          format: 'MM/dd/yyyy',
          store: store,
          disableFuture: true,
          onChange: manageDate,
        })}
      </Grid>
      <Grid item xs={12} sm={6}>
        {createSelectField({
          store: store,
          testId: 'citizenshipStatus',
          fieldName: 'citizenshipStatus',
          label: 'Citizenship Status',
          options: citizenshipStatusOptions,
          placeholder: 'Make Selection',
        })}
      </Grid>
      <Grid item xs={12} sm={6}>
        {createNumberFormatField({
          label: 'Social Security Number',
          fieldName: 'ssn',
          format: "###-##-####",
          testId: 'ssn',
          store: store,
        })}
      </Grid>
    </>
  )
});