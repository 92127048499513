import { observer } from "mobx-react";
import { useUserProfileStore } from "../hooks/useUserProfileStore";
import { Button, Card } from '@roc/ui';
import { Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { createPhoneField, createTextField, createNumberFormatField, createStreetAddressField } from '@roc/ui/componentBuilder';
import { useBaseStore } from '@roc/feature-app-core';
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    height: "100%"
  },
}));

export const BasicDetailsCardContent = observer(() => {
  const { userProfileStore } = useUserProfileStore();
  const { globalStore } = useBaseStore();
  const isEditing = userProfileStore.isEditing;
  const canEditBasicDetails = globalStore.userFeatures?.isBorrower ? () => false : () => userProfileStore.edit();

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Alert severity="info">
            Please reach out to the lender to get the information updated.
          </Alert>
        </Grid>
        <Grid item xs={12} md={6}>
          {createTextField({
            testId: 'firstName',
            label: 'First Name',
            fieldName: 'firstName',
            store: userProfileStore,
            disabled: !isEditing,
          })}
        </Grid>
        <Grid item xs={12} md={6}>
          {createTextField({
            testId: 'lastName',
            label: 'Last Name',
            fieldName: 'lastName',
            store: userProfileStore,
            disabled: !isEditing,
          })}
        </Grid>
        <Grid item xs={12} md={6}>
          {createTextField({
            testId: 'email',
            label: 'Email',
            fieldName: 'emailAddress',
            store: userProfileStore,
            disabled: !isEditing,
          })}
        </Grid>
        <Grid item xs={12} md={6}>
          {createPhoneField({
            testId: 'cellPhone',
            label: 'Phone Number',
            fieldName: 'cellPhone',
            format: '(###) ###-####',
            store: userProfileStore,
            disabled: !isEditing,
          })}
        </Grid>
        <Grid item xs={12} md={6}>
          {createStreetAddressField({
            testId: 'address',
            label: 'Address',
            fieldName: 'address',
            store: userProfileStore,
            disabled: !isEditing,
          })}
        </Grid>
        <Grid item xs={12} md={6}>
          {createTextField({
            testId: 'aptNumber',
            label: 'Apt/Unit Number',
            fieldName: 'aptNumber',
            store: userProfileStore,
            disabled: !isEditing,
          })}
        </Grid>
        <Grid item xs={12} md={6}>
          {createTextField({
            testId: 'city',
            label: 'City',
            fieldName: 'city',
            store: userProfileStore,
            disabled: !isEditing,
          })}
        </Grid>
        <Grid item xs={12} md={6}>
          {createTextField({
            testId: 'state',
            label: 'State',
            fieldName: 'state',
            store: userProfileStore,
            disabled: !isEditing,
          })}
        </Grid>
        <Grid item xs={12} md={6}>
          {createNumberFormatField({
            testId: 'zipCode',
            label: 'Zip/Code',
            fieldName: 'zipCode',
            store: userProfileStore,
            disabled: !isEditing,
          })}
        </Grid>
        {isEditing && <Grid item xs={12} container justifyContent="flex-end">
          <>
            <Button
              style={{ marginRight: '8px' }}
              onClick={() => userProfileStore.cancel()}
              color="primary"
              variant="outlined"
              testId="cancel"
            >
              Cancel
            </Button>
            <Button
              onClick={() => userProfileStore.saveBasicUserDetails()}
              color="primary"
              variant="contained"
              testId="save"
            >
              Save
            </Button>
          </>
        </Grid>}
      </Grid>
    </Box>
  );
});

export const BasicDetailsCard = observer(({
  title = 'BASIC DETAILS'
}) => {
  const classes = useStyles();
  return (
    <Card title={title} className={classes.paper}>
      <BasicDetailsCardContent />
    </Card>
  );
});
