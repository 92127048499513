import { Layout } from '@roc/client-portal-shared/components';
import { observer } from 'mobx-react';
import { Paper, VerticalBarLoader } from '@roc/ui';
import { Box, Grid, Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { PropertiesCheckboxes } from './propertiesCheckboxes';
import { SubjectProperty } from '../subjectProperty/subjectProperty';
import { CompHeader } from '../header/compHeader';
import IndividualPropertyDialog from '../individualProperty/individualPropertyDialog';
import { FiltersDialog } from '../filters/filtersDialog';
import { CompListGrid } from '../listViewScreen/compListGrid';
import { CompareGadget } from '../compareScreen/compareGadget';
import AllPhotosDialog from '../allPhotos/allPhotosDialog';
import { CompareScreen } from '../compareScreen/compareScreen';
import { useStore } from '@roc/client-portal-shared/hooks';
import IndividualPhotoDialog from '../allPhotos/individualPhotoDialog';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      padding: '16px',
      textAlign: 'justify'
    },
    paper: {
      height: '100%'
    }
  })
);

export const PropertiesGridView = observer(
  () => {
    const { compToolStore } = useStore();
    const { compToolPhotosStore } = compToolStore;
    const classes = useStyles();
    const { loading } = compToolStore;

    const getBackButtonLabel = () => {
      if (!compToolStore.openCarouselPhotos && !compToolStore.openPropertyDialog) {
        return 'Back to main';
      } else if (compToolStore.openPropertyDialog && !compToolStore.openCarouselPhotos) {
        return 'Back to property';
      }
    
      return undefined;
    };

    return (
      <>
        <Layout>
          <Paper className={classes.paper}>
            {loading ? (
              <>
                <VerticalBarLoader />
              </>
            ) : compToolStore.showCompareScreen ? (
              <>
                <Box className={classes.container}>
                  <Grid container spacing={4}>
                    <CompareScreen />
                  </Grid>
                </Box>
              </>
            ) : compToolStore.showGridView ?
              (
                <>
                  <Box className={classes.container}>
                    <Grid container spacing={4}>
                      <CompHeader />
                      <Grid item xs={12} >
                        <SubjectProperty />
                      </Grid>
                      <Grid item xs={12} >
                        <PropertiesCheckboxes />
                      </Grid>
                    </Grid>
                  </Box>
                </>
              ) : (
                <>
                  <Box className={classes.container}>
                    <Grid container spacing={4}>
                      <CompHeader />
                      <Grid item xs={12} >
                        <SubjectProperty />
                      </Grid>
                      <Grid item xs={12} >
                        <CompListGrid />
                      </Grid>
                    </Grid>
                  </Box>
                </>
              )}
            <IndividualPropertyDialog property={compToolStore.selectedProperty} open={compToolStore.openPropertyDialog} onClose={() => {
              compToolStore.setOpenPropertyDialog(false)
              compToolStore.editSubjectPropertyFormStore.setAreEditFieldsOpened(false)
            }} />
            <FiltersDialog open={compToolStore.openFiltersModal} onClose={() => compToolStore.setOpenFiltersModal(false)} />
            <AllPhotosDialog property={compToolStore.selectedProperty} open={compToolStore.openCarouselPhotos} onClose={() => compToolStore.setOpenCarouelPhotos(false)} />
            <IndividualPhotoDialog closeButtonLabel={getBackButtonLabel()} open={compToolPhotosStore.openIndividualPhoto} onClose={() => compToolPhotosStore.setOpenIndividualPhoto(false)} compToolPhotosStore={compToolPhotosStore} />

          </Paper>
          <CompareGadget />
        </Layout>

      </>
    );
  }
);
