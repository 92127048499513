import { GridRowSelectedEvent } from '@roc/client-portal-shared/components';
import { ApiResponse } from '@roc/client-portal-shared/services';
import { GlobalStore, UserStore } from 'libs/feature-app-core/src/stores/index';
import { action, makeObservable, observable } from 'mobx';
import { userInfo } from 'os';
import { GridStore } from 'libs/feature-app-core/src/stores/gridStore';
import { OriginatorService } from '../services/originatorService';
import { Originator } from '../types/originatorTypes';
import { OriginatorsStore } from './originatorStore';

export class OriginatorsGridStore extends GridStore {
  private globalStore: GlobalStore;
  originatorService: OriginatorService;
  userStore: UserStore

  selected: Originator[];

  constructor(originatorsStore: OriginatorsStore, globalStore: GlobalStore, userStore: UserStore) {
    super(() => this.fetchOriginators());
    this.globalStore = globalStore;
    this.originatorService = originatorsStore.originatorService;
    this.userStore = userStore

    makeObservable(this, {
      selected: observable,
      onRowSelected: action,
      resetSelected: action,
    });
  }

  private async fetchOriginators() {
    try {
      const response: ApiResponse = await this.originatorService.getAllOriginators(
        this.gridData.meta.pageNumber,
        this.gridData.meta.pageSize,
        this.gridData.meta.sortDir,
        this.gridData.meta.sortBy,
        this.gridData.meta.filters,
        this.userStore.userInformation.userId
      );
      return response;
    } catch (error) {
      console.log(error);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching originators',
      });
    }
  }

  onRowSelected = (e: GridRowSelectedEvent) => {
    const { node } = e;
    const originator = node.data as Originator;
    if (node.isSelected()) {
      originator.percentage = 0;
      this.selected = this.selected.concat(node.data);
    } else {
      this.selected = this.selected.filter(l => l.lenderId === originator.lenderId);
    }
  };

  resetSelected() {
    this.selected = [];
  }
}
