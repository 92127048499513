import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useStore } from '../../hooks';
import { useParams } from 'react-router-dom';
import { ScopeOfWorkV2Form } from '@roc/feature-sow-v2';
import { decodeParams } from '../drawRequest/drawRequestUtils';

type EncodedParams = {
  encodedParams: string;
};

export const ScopeOfWorkRevision = observer(() => {
  const { encodedParams } = useParams<EncodedParams>();
  const [queryParams] = useState(decodeParams(encodedParams));

  const { scopeOfWorkRevisionStore } = useStore();
  const { scopeOfWorkV2FormStore } = scopeOfWorkRevisionStore;

  useEffect(() => {
    if (queryParams?.drawId) {
      scopeOfWorkRevisionStore.fetchSOWRevision(queryParams?.drawId, queryParams?.username);
    }
    return () => {
      scopeOfWorkRevisionStore.reset();
    };
  }, []);

  return (
    <ScopeOfWorkV2Form
      store={scopeOfWorkV2FormStore}
    />
  );
});

export default ScopeOfWorkRevision;