import { Box, ClickAwayListener, Portal, Tooltip, TooltipProps, Typography, useMediaQuery } from '@material-ui/core';
import { createStyles, darken, lighten, makeStyles, Theme, useTheme, withStyles } from '@material-ui/core/styles';
import { Close, DragIndicator } from '@material-ui/icons';
import { numberWithCommas, roundToTwoDigits } from '@roc/feature-utils';
import { Button, ConditionalWrapper, IconButton } from '@roc/ui';
import clsx from 'clsx';
import React, { useEffect, useState } from "react";
import { HandleProps, SegmentProps, useRanger } from "react-ranger";


export enum SegmentType {
  PREVIOUSLY_DISBURSED = 'Construction Holdback Reimbursed to Date',
  REMAINING = 'Construction Holdback Remaining'
}

export enum RangerMode {
}

export const BarColors = {
  previouslyDisbursed: '#4CAF50',//'#00d5c0',
  remaining: '#bbb'
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    track: {
      display: 'inline-block',
      height: 16,
      width: '90%',
      margin: '40px 32px',
      marginTop: 0,
      border: '1px solid #d7d7d7',
    },
    tick: {
      '&::before': {
        content: '""',
        position: "absolute",
        top: 6,
        left: 0,
        background: "rgba(0, 0, 0, 0.2)",
        height: 4,
        width: 2,
        transform: "translate(-50%, 0.7rem)",
      }
    },
    tickLabel: {
      position: "absolute",
      fontSize: 12,
      color: "#777777",
      top: 0,
      transform: "translate(-50%, 1.2rem)",
      whiteSpace: "nowrap",
    },
    tickLabelAmount: {
      top: 12,
    },
    segmentPreviouslyDisbursed: {
      background: BarColors.previouslyDisbursed,
      backgroundImage: `linear-gradient(${BarColors.previouslyDisbursed} 10%, ${lighten(BarColors.previouslyDisbursed, .5)} 50%, ${BarColors.previouslyDisbursed} 100%)`,
      height: "100%",
      cursor: "pointer",
      '&:hover': {
        border: `2px solid ${darken(BarColors.previouslyDisbursed, .05)}`
      }
    },
    segmentRemaining: {
      background: BarColors.remaining,
      backgroundImage: `linear-gradient(${BarColors.remaining} 10%, ${lighten(BarColors.remaining, .5)} 50%, ${BarColors.remaining} 100%)`,
      height: "100%",
      cursor: "pointer",
      '&:hover': {
        border: `2px solid ${darken(BarColors.remaining, .05)}`
      }
    },
    handleButton: {
      minWidth: 'auto',
      padding: 4,
      borderRadius: 16,
      color: '#fff',
      display: 'flex',
      background: 'rgb(0 0 0 / 60%)',
      '&:hover': {
        background: 'rgb(0 0 0 / 60%)',
      }
    },
  })
);

const tooltipPopperCommons = {
  zIndex: 1
}

const tooltipCommons = {
  color: 'black',
  maxWidth: '100%',
  fontSize: 14,
  top: 6,
  marginTop: 2,
  marginBottom: 10,
  "user-select": "text",
}

const TooltipPreviouslyDisbursed = withStyles((theme: Theme) => ({
  popper: {
    ...tooltipPopperCommons,
  },
  tooltip: {
    ...tooltipCommons,
    backgroundColor: lighten(BarColors.previouslyDisbursed, .8),
    border: `1px solid ${BarColors.previouslyDisbursed}`,
    '& .MuiTooltip-arrow': {
      color: BarColors.previouslyDisbursed,
    },
  }
}))(Tooltip);

const TooltipRemaining = withStyles((theme: Theme) => ({
  popper: {
    ...tooltipPopperCommons,
  },
  tooltip: {
    ...tooltipCommons,
    backgroundColor: lighten(BarColors.remaining, .8),
    border: `1px solid ${BarColors.remaining}`,
    '& .MuiTooltip-arrow': {
      color: BarColors.remaining,
    },
  }
}))(Tooltip);

const Segment = ({ showTooltipAlways, className, segmentType, amount, percent, getSegmentProps, SegmentTooltip, onMouseOver, onMouseLeave }: {
  showTooltipAlways?: boolean,
  className?: string,
  segmentType?: SegmentType,
  amount?: number,
  percent?: number,
  getSegmentProps: <T>(props?: T) => T & SegmentProps,
  SegmentTooltip?: any,
  onMouseOver?: React.MouseEventHandler,
  onMouseLeave?: React.MouseEventHandler,
}) => {

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.sm));

  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  let tooltipProps: Partial<TooltipProps>;
  if (showTooltipAlways && !isSmallScreen) {
    tooltipProps = {
      open: true,
    };
  } else {
    tooltipProps = {
      open: showTooltip,
      disableFocusListener: true,
      disableHoverListener: true,
      disableTouchListener: true,
    };
  }

  const toggleTooltip = () => {
    setShowTooltip((prev) => !prev);
  }

  return (
    <ClickAwayListener onClickAway={() => setShowTooltip(false)}>
      <div>
        <ConditionalWrapper
          condition={SegmentTooltip != undefined}
          wrapper={children => {
            return (
              <SegmentTooltip
                PopperProps={{
                  disablePortal: true,
                }}
                {...tooltipProps}
                placement={"top"}
                arrow={true}
                interactive
                title={
                  <React.Fragment>
                    <Box>
                      {/* <Box textAlign={'right'}>
                        <IconButton testId={`close-popup`} size="small"
                          onClick={() => setShowTooltip(false)}>
                          <Close style={{
                            fontSize: 16
                          }} />
                        </IconButton>
                      </Box> */}
                      <Box>
                        <Box textAlign={'center'}>
                          <Typography color="inherit" style={{
                            fontSize: 16
                          }}>{segmentType}</Typography>
                          <Typography color="inherit" style={{
                            fontSize: 16
                          }}>
                            <b>{`$${numberWithCommas(amount)}`}</b>
                            <Box component={'span'} display={'inline'} color={'#a8a8a8'} mx={.5}>&#x2022;</Box>
                            <b>{`${percent}%`}</b>
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </React.Fragment>
                }
              >
                {children}
              </SegmentTooltip>
            )
          }}>
          <div className={className} onClick={() => toggleTooltip()} {...getSegmentProps()} />
        </ConditionalWrapper>
      </div>
    </ClickAwayListener>
  );
};

export const DrawsDashboardRanger = ({
  totalConstructionHoldback,
  constructionHoldbackReimbursed,
  constructionHoldbackRemaining
}: {
  totalConstructionHoldback: number,
  constructionHoldbackReimbursed: number,
  constructionHoldbackRemaining: number,
}) => {
  const classes = useStyles();

  const [constructionHoldbackReimbursed_amount, setConstructionHoldbackReimbursed_amount] = useState(0);
  const [constructionHoldbackReimbursed_percent, setConstructionHoldbackReimbursed_percent] = useState(0);

  const [constructionHoldbackRemaining_amount, setConstructionHoldbackRemaining_amount] = useState(0);
  const [constructionHoldbackRemaining_percent, setConstructionHoldbackRemaining_percent] = useState(0);

  const [coordinates, setCoordinates] = React.useState([]);

  useEffect(() => {

    const _ch_reimbursed_amount = (constructionHoldbackReimbursed ? constructionHoldbackReimbursed : 0);
    let _ch_reimbursed_percent = (_ch_reimbursed_amount * 100) / totalConstructionHoldback;
    _ch_reimbursed_percent = roundToTwoDigits(_ch_reimbursed_percent);
    setConstructionHoldbackReimbursed_amount(_ch_reimbursed_amount);
    setConstructionHoldbackReimbursed_percent(_ch_reimbursed_percent);

    const _ch_remaining_amount = (constructionHoldbackRemaining ? constructionHoldbackRemaining : 0);
    let _ch_remaining_percent = (_ch_remaining_amount * 100) / totalConstructionHoldback;
    _ch_remaining_percent = roundToTwoDigits(_ch_remaining_percent);
    setConstructionHoldbackRemaining_amount(_ch_remaining_amount);
    setConstructionHoldbackRemaining_percent(_ch_remaining_percent);

    setCoordinates([_ch_reimbursed_percent, (_ch_reimbursed_percent + _ch_remaining_percent)]);
  }, []);


  const { getTrackProps, ticks, segments, handles } = useRanger({
    min: 0,
    max: 100,
    stepSize: 5,
    values: coordinates,
    onChange: (newValues) => {
    },
    onDrag: (newValues) => {
    }
  });

  if (!ticks?.length || !segments?.length || !handles?.length) {
    return null;
  }

  return (
    <div className={classes.track} {...getTrackProps()}>

      {/* Ticks */}
      {ticks.map(({ value, getTickProps }, index) => {
        if (index == 0 || index == (ticks.length - 1)) {
          return (<div key={`tick-key-${index}`} className={classes.tick} {...getTickProps()}>
            <div className={classes.tickLabel}>
              {`${value}%`}
            </div>
            <div className={clsx(classes.tickLabel, classes.tickLabelAmount)}>
              {`$${numberWithCommas(value * (totalConstructionHoldback / 100))}`}
            </div>
          </div>)
        }
        return null;
      })}

      {/* Segments */}
      <Segment
        className={classes.segmentPreviouslyDisbursed}
        segmentType={SegmentType.PREVIOUSLY_DISBURSED}
        amount={constructionHoldbackReimbursed_amount}
        percent={constructionHoldbackReimbursed_percent}
        getSegmentProps={segments[0].getSegmentProps}
        SegmentTooltip={TooltipPreviouslyDisbursed}
        showTooltipAlways={constructionHoldbackReimbursed_amount > 0 ? true : false}
      />
      <Segment
        className={classes.segmentRemaining}
        segmentType={SegmentType.REMAINING}
        amount={constructionHoldbackRemaining_amount}
        percent={constructionHoldbackRemaining_percent}
        getSegmentProps={segments[1].getSegmentProps}
        SegmentTooltip={TooltipRemaining}
        showTooltipAlways={constructionHoldbackRemaining_amount > 0 ? true : false}
      />
    </div>
  );
};