import { GlobalStore } from '@roc/feature-app-core';
import { isNil, parseDate } from '@roc/feature-utils';
import { makeObservable, flow, action, observable, computed, override } from 'mobx';
import { PlaidService } from '../../../../apps/client-portal-public/src/app/services';
import { PrivatePlaidService } from '../../../../apps/client-portal-public/src/app/services/privatePlaidService';
import { ACHFormSteps } from '../utils/constants';
import { BankInformationFormStore } from './bankInformationFormStore';

enum Steps {
  BANK_INFORMATION = 0,
  DOCUSIGN = 1,
  SUMMARY = 2,
}

export abstract class AchFormBaseStore {
  public globalStore: GlobalStore;
  protected plaidService: PlaidService;

  bankInformationFormStore: BankInformationFormStore;

  public activeStep: number;
  public finalVerificationStep: number;
  public linkToken: string;
  public achData: any;
  public docusignUrl: string;
  public achUrl: string;
  public selectedBorrower: any;
  public borrowerOptions: any;
  public currentAuthSignatoryBorrower: any;

  abstract getACHData(encodedInput: string);

  constructor(globalStore: GlobalStore) {
    this.globalStore = globalStore;
    this.activeStep = 0;
    this.bankInformationFormStore = new BankInformationFormStore(globalStore);
    this.selectedBorrower = null;
    this.borrowerOptions = [];
    this.currentAuthSignatoryBorrower = null;
    makeObservable(this, {
      moveToStep: observable,
      activeStep: observable,
      reset: action,
      getACHData: flow,
      fetchACHData: flow,
      updateACHData: flow,
      setWithdrawalDay: action,
      linkToken: observable,
      achData: observable,
      docusignUrl: observable,
      submitFormToDocusign: flow,
      getACHLink: flow,
      achUrl: observable,
      sendACHEmail: flow,
      finalVerificationStep: observable,
      moveToDocusignStep: flow,
      confirmDocumentSigned: flow,
      selectedBorrower: observable,
      setSelectedBorrower: action,
      borrowerOptions: observable,
      generateBorrowerOptions: action,
      getLoanACHData: flow,
      disableStartACHForm: computed,
      updateSelectedBorrower: flow,
      currentAuthSignatoryBorrower: observable,
      setCurrentAuthSignatoryBorrower: action,
    });
  }

  public reset(){
    this.activeStep = 0;
    this.borrowerOptions = [];
    this.selectedBorrower = null;
    this.currentAuthSignatoryBorrower = null;
  }

  public *fetchACHData(encodedInput) {
    try {
      const response = yield this.getACHData(encodedInput);
      this.achData = response.data;
      this.setVerificationStep();
      this.setFinalVerificationStep();
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: `Error while retrieving ACH Data`,
      });
    }
  }

  public storePlaidACHInformation = async (request: any) => {
    this.plaidService
      .storePlaidACHInformation(request)
      .then(response => {
        this.fetchACHData(request?.loanId);
      })
      .catch(error =>
        this.globalStore.notificationStore.showErrorNotification({
          message: `Error storing ACH information`,
        })
      );
  };

  public *moveToStep(step) {
    this.activeStep = step;
  }

  public setWithdrawalDay(param) {
    this.achData.withdrawalDay = param;
  }

  public *updateACHData() {
    if (this.achData?.withdrawalDay) {
      this.plaidService
        .updateACHData(this.achData)
        .then(response =>
          this.fetchACHData(
            window.location.href.substring(
              window.location.href.lastIndexOf('/') + 1
            )
          )
        )
        .catch(error =>
          this.globalStore.notificationStore.showErrorNotification({
            message: `An error has occurred saving the withdrawal date`,
          })
        );
      return;
    }
    this.globalStore.notificationStore.showErrorNotification({
      message: `A withdrawal day is required`,
    });
  }

  public *submitFormToDocusign() {
    this.plaidService
      .submitDocusign(this.achData.loanId)
      .then(response => (this.docusignUrl = response.data.viewUrl))
      .catch(error =>
        this.globalStore.notificationStore.showErrorNotification({
          message: error.error.response.data.error.message,
        })
      );
  }

  public *getACHLink(loanId: any) {
    this.plaidService
      .getACHLink(loanId, this.selectedBorrower)
      .then(response => (this.achUrl = response.data.data))
      .catch(error =>
        this.globalStore.notificationStore.showErrorNotification({
          message: `An error has occurred getting the ACH link`,
        })
      );
  }

  public *sendACHEmail(loanId: any) {
    this.plaidService
      .sendACHEmail(loanId, this.selectedBorrower)
      .then(response =>
        this.globalStore.notificationStore.showSuccessNotification({
          message: `Email has been sent to the borrower`,
        })
      )
      .catch(error => {
        this.globalStore.notificationStore.showErrorNotification({
          message: error.error.response.data.error.message,
        });
      });
  }

  public setVerificationStep() {
    switch (this.achData.verificationStep) {
      // case ACHFormSteps.SIGNATURE: this.activeStep = 1; break;
      case ACHFormSteps.SIGNED:
        this.activeStep = 2;
        break;
      default:
        this.activeStep = 0;
    }
  }

  private setFinalVerificationStep() {
    if (
      this.achData?.verificationStatus == 'pending_manual_verification' &&
      this.achData?.microdepositPosted
    ) {
      this.finalVerificationStep = 2;
    } else {
      this.finalVerificationStep = 1;
    }
  }

  *moveToDocusignStep() {
    try {
      this.bankInformationFormStore.runFormValidationWithMessage();
      if (this.bankInformationFormStore.form.meta.isValid) {
        const values = this.bankInformationFormStore.getFormValues();

        yield this.plaidService.sentBofaValidation(
          values.accountNumber.replace(/[^0-9]/, '')
          ,values.routingNumber.replace(/[^0-9]/, '')
          ,this.achData.loanId);

        yield this.plaidService.saveBankInformation({
          ...values,
          loanId: this.achData.loanId,
          routingNumber: values.routingNumber.replace(/[^0-9]/, ''),
          accountNumber: values.accountNumber.replace(/[^0-9]/, ''),
          withdrawalDay: values.withdrawalDay,
        });
        this.activeStep = Steps.DOCUSIGN;
      }
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while saving the bank information',
      });
    }
  }

  *confirmDocumentSigned() {
    this.activeStep = Steps.SUMMARY;
  }

  public setSelectedBorrower(borrower) {
    this.selectedBorrower = borrower;
  }

  public setCurrentAuthSignatoryBorrower(currentBorrower) {
    this.currentAuthSignatoryBorrower = currentBorrower;
  }

  public *getLoanACHData(loanId: any, loan) {
    const borrowerId = this.generateBorrowerOptions(loan);
    this.plaidService
      .getLoanACHData(loanId)
      .then(response =>
        this.setSelectedBorrower(
          isNil(borrowerId) ? response.data.data?.borrowerId : borrowerId
        )
      )
      .catch(error =>
        this.globalStore.notificationStore.showErrorNotification({
          message: `An error has occurred getting the ACH data`,
        })
      );
  }

  public generateBorrowerOptions(loan: any) {
    const options = loan.borrowers?.filter(borrower => borrower.authSignatory);
    if (options.length === 1) {
      this.setCurrentAuthSignatoryBorrower(options[0]);
      this.borrowerOptions = options;
      return options[0].borrowerId;
    } else if (options.length > 1) {
      const borrowers = [
        { label: 'Please select a borrower', value: null, id: null },
      ];
      options?.forEach(element => {
        borrowers.push({
          label: element.fullName,
          value: element.borrowerId,
          id: element.borrowerId,
        });
      });
      this.borrowerOptions = borrowers;
    }
    return null;
  }

  *updateSelectedBorrower(borrowerId) {
    try {
      const response = yield this.plaidService.updateSelectedBorrower(
        this.achData.loanId,
        borrowerId
      );
      this.achData = response.data.data;
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: `An error has occurred while updating the ACH data`,
      });
    }
  }

  get disableStartACHForm() {
    return !this.achData || !this.achData?.borrowerId;
  }
}

export class AchFormStore extends AchFormBaseStore {
  *getACHData(encodedInput) {
    return this.plaidService.getACHData(encodedInput);
  }

  constructor(globalStore: GlobalStore) {
    super(globalStore);
    this.plaidService  = new PlaidService();
    makeObservable(this, {
      getACHData: override,
    });
  }
}

export class BorrowerAchFormStore extends AchFormBaseStore {
  *getACHData(encodedInput) {
    return this.plaidService.getACHDataForBorrowerPortal(encodedInput);
  }

  constructor(globalStore: GlobalStore) {
    super(globalStore);
    this.plaidService = new PrivatePlaidService();
    makeObservable(this, {
      getACHData: override,
    });
  }
}