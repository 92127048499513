import { Box, Grid, Tooltip } from "@material-ui/core"
import { LoanDetailsInfoItem } from "./loanDetailsCustomCard"
import { useEffect, useState } from "react";


export const LoandetailsBorrowerEntityBorrowers = ({ loanDetails }: { loanDetails: any }) => {
  const entity = loanDetails?.borrowerEntity;
  const borrowers = loanDetails?.borrowers;

  const [orderedBorrowers, setOrderedBorrowers] = useState<any[]>();

  useEffect(() => {
    if (borrowers?.length) {
      const _ordered = [...borrowers].sort((a, b) => {
        if (a.personalGuarantor) {
          return 1;
        } else {
          return 0;
        }
      });
      setOrderedBorrowers(_ordered.splice(0, 4));
    }
  }, [borrowers]);

  return (
    <LoanDetailsInfoItem
      value={entity?.company?.name}
      labelComponent={
        <Box width={'100%'} fontSize={14}>
          <Grid container spacing={0}>
            {orderedBorrowers?.map((borrower, i) => {
              if (!borrower) {
                return <></>;
              }
              const shortName = `${borrower.firstName} ${borrower.lastName?.charAt(0)}`;
              const fullName = `${borrower.firstName} ${borrower.lastName}`;
              const borrowerExperience = borrower.experience ? borrower.experience : '-';
              const pgText: any = borrower.personalGuarantor ? 'PG' : 'Non PG';
              const pgTextTooltip: any = borrower.personalGuarantor ? 'Personal Guarantor' : 'Non Personal Guarantor';
              const creditScore: any = borrower.medianScore ? borrower.medianScore : '-';
              const isFirstRecord = (i == 0);
              const isLastRecord = (i == orderedBorrowers.length - 1);
              return (
                <Grid item xs={3} key={borrower.borrowerId}>
                  <Box width={'100%'}
                    pl={isFirstRecord ? 0 : 2}
                    pr={isLastRecord ? 0 : 1}
                    borderRight={isLastRecord ? 'none' : '1px solid #bbb'}>
                    <Box component={'div'} textOverflow={'ellipsis'} overflow={'hidden'} whiteSpace={'nowrap'}>
                      <Tooltip title={`Full Name: ${fullName}`}>
                        <span>
                          {shortName}
                        </span>
                      </Tooltip>
                    </Box>
                    <Box component={'div'}>
                      <Box component={'span'} pr={1} borderRight={'1px solid #bbb'}>
                        <Tooltip title={`${pgTextTooltip}`}>
                          <span style={{
                            borderBottom: '1px dashed #bbb'
                          }}>
                            {
                              pgText
                            }
                          </span>
                        </Tooltip>
                      </Box>
                      <Box component={'span'} px={1} borderRight={'1px solid #bbb'}>
                        <Tooltip title={`Experience: ${borrowerExperience}`}>
                          <span style={{
                            borderBottom: '1px dashed #bbb'
                          }}>
                            {
                              borrowerExperience
                            }
                          </span>
                        </Tooltip>
                      </Box>
                      <Box component={'span'} pl={1}>
                        <Tooltip title={`Credit Score: ${creditScore}`}>
                          <span style={{
                            borderBottom: '1px dashed #bbb'
                          }}>
                            {
                              creditScore
                            }
                          </span>
                        </Tooltip>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              )
            })}
          </Grid>
          {
            (borrowers?.length > orderedBorrowers?.length) &&
            <Box mt={1}>
              (and {borrowers.length - orderedBorrowers.length} more)
            </Box>
          }
        </Box>
      }
    />
  )

}