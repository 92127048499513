import { Box } from '@material-ui/core';
import { Button, DialogState, EditableDialog, FilterButton, Toolbar } from '@roc/ui';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { ManageUsersBackUpsGrid } from './components/manageUsersBackUpsGrid';
import { useManageUsersBackUpStore } from './hooks/useManagerUsersBackUpStore';

export const ManageUsersBackUps = observer(({ manageUsersBackUpStore }) => {


  useEffect(() => {
    manageUsersBackUpStore.gridStore.resetAndFetchGridData();
  }, [manageUsersBackUpStore]);

  const handleToggleFilter = () => {
    manageUsersBackUpStore.gridStore.toggleFilters();
  };

  const handleClearFilter = () => {
    manageUsersBackUpStore.gridStore.resetFilters();
  };

  return (
    <>
      <ManageUsersBackUpsGrid
        toolbar={
          <Toolbar>
            <Box mr={2}>
              <FilterButton
                onToggleFilters={handleToggleFilter}
                onClearFilters={handleClearFilter}
              />
            </Box>
          </Toolbar>
        }
        manageUsersBackUpStore={manageUsersBackUpStore}
      />
    </>
  );
});