import { Service } from '@roc/feature-app-core';

const url = {
  DOCUMENT_DETIALS: '/nportal/rest/priv/loanDocs/getLoanDocumentById',
  DOWNLOAD_DOCUMENT_INTERNAL: '/nportal/rest/priv/loanDocs/downloadLoanDocument',
};

export class BorrowerTrackRecordService extends Service {

  getDocumentDetails(loanDocumentId: number) {
    return this.get(url.DOCUMENT_DETIALS, {
      loanDocumentId
    });
  }

  downloadDocumentInternal(document) {
    return this.post(url.DOWNLOAD_DOCUMENT_INTERNAL, document, null, {
      responseType: 'arraybuffer',
    });
  };
}
