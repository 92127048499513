import { Service } from '@roc/feature-app-core';
import { addQueryParam } from '@roc/feature-utils';

const url = {
  DRAW_REQUEST: '/api/v1/draws/requestDraw',
  GET_DRAW_REQUEST: '/api/v1/draws/getRequestDraw',
  SUBMIT_DRAW_REQUEST: '/api/v1/draws/submitDrawRequest',
  SAVE_DRAW_REQUEST: '/api/v1/draws/saveDrawRequest',
  GET_DRAW_REQUEST_SHARE_URL: '/api/v1/draws/getDrawRequestShareUrl',
};

export class DrawRequestService extends Service {
  requestDraw(body: any) {
    return this.post(`${url.DRAW_REQUEST}`, body);
  }
  retrieveDrawData(drawId: number) {
    return this.get(`${url.GET_DRAW_REQUEST}?drawId=${drawId}`); // TODO: set url
  }

  submitDrawRequest(drawid: number, body: any) {
    return this.post(`${url.SUBMIT_DRAW_REQUEST}?drawId=${drawid}`, body);
  }

  saveDrawRequest(drawid: number, body: any) {
    return this.post(`${url.SAVE_DRAW_REQUEST}?drawId=${drawid}`, body);
  }

  getDrawRequestShareUrl(drawid: string) {
    const params = addQueryParam('', 'drawId', drawid);
    return this.get(
      `${url.GET_DRAW_REQUEST_SHARE_URL}${params ? `?${params}` : ''}`
    );
  }
}
