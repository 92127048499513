import { Page } from '@roc/feature-app-core';
import { Route } from 'react-router';
import { useRocInternalPortalStore } from '../../../hooks';
import { AppraisalReview, UwAppraisalReview } from '@roc/feature-appraisals';

export const getAppraisalReviewReportInternalRoutesConfig = (
  basePath: string,
  baseUrl: string,
) => {
  return {
    appraisalReviewReport: {
      path: `${basePath}/appraisal-review-report`,
      url: `${baseUrl}/appraisal-review-report`,
      documentTitle: 'appraisal-review-report',
    },
    uwAppraisalReview: {
      path: `${basePath}/uw-appraisal-review`,
      url: `${baseUrl}/uw-appraisal-review`,
      documentTitle: 'uw-appraisal-review',
    },
  };
};

export const getAppraisalReviewReportInternalRoutes = (
  basePath: string,
  baseUrl: string,
  loanId: any,
  propertyId: any,
) => {
  const config = getAppraisalReviewReportInternalRoutesConfig(basePath, baseUrl);
  const { appraisalReviewStore } = useRocInternalPortalStore();

  return {
    appraisalReviewReport: (
      <Route exact path={config.appraisalReviewReport.path}>
        <Page routeDefinition={config.appraisalReviewReport}>
          <AppraisalReview
            loanId={loanId}
            propertyId={propertyId}
            store={appraisalReviewStore}
          />
        </Page>
      </Route>
    ),
    uwAppraisalReview: (
      <Route exact path={config.uwAppraisalReview.path}>
        <Page routeDefinition={config.uwAppraisalReview}>
          <UwAppraisalReview
            loanId={loanId}
            propertyId={propertyId}
            store={appraisalReviewStore}
          />
        </Page>
      </Route>
    ),
  };
}