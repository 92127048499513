import { FormStore } from '@roc/feature-app-core';
import { GlobalStore } from '@roc/feature-app-core';
import { citizenshipStatusOptions } from '@roc/feature-utils';
import { borrowerDefaults } from '@roc/feature-utils';

const borrowerForm = {
  fields: {
    borrowerId: {
      value: null,
      error: null,
      rule: '',
    },
    firstName: {
      value: '',
      error: null,
      rule: 'required',
    },
    lastName: {
      value: '',
      error: null,
      rule: 'required',
    },
    emailAddress: {
      value: '',
      error: null,
      rule: 'required|email',
    },
    emailAddressDuplicated: {
      value: true,
      error: null,
      rule: [{ required_unless: ['emailAddress', ''] }, 'accepted'],
      message: 'This email is already selected for other borrower.',
    },
    cellPhone: {
      value: '',
      error: null,
      rule: '',
    },
    roleInTransaction: {
      value: null,
      error: null,
      rule: '',
    },
    personalGuarantor: {
      value: borrowerDefaults.personalGuarantor,
      error: null,
      rule: 'required',
    },
    pctOwnership: {
      value: 0,
      error: null,
      rule: 'required|between:0,100',
    },
    ficoProvidedAtOrigination: {
      value: 680,
      error: null,
      rule: 'required',
    },
    bankruptcy: {
      value: 'N',
      error: null,
      rule: '',
    },
    foreclosure: {
      value: 'N',
      error: null,
      rule: '',
    },
    authSignatory: {
      value: false,
      error: null,
      rule: '',
    },
    experience: {
      value: 1,
      error: null,
      rule: '',
    },
    creditBackgroundCheck: {
      value: false,
      error: null,
      rule: '',
    },
    hasSubstantialChanges: {
      value: false,
      error: null,
      rule: '',
    },
    substantialChangesDescription: {
      value: '',
      error: null,
      rule: '',
    },
    ownershipNotKnown: {
      value: false,
      error: null,
      rule: '',
    },
    citizenshipStatus: {
      value: citizenshipStatusOptions[0].value,
      error: null,
      rule: '',
    },
    leadSource: {
      value: '',
      error: null,
      rule: '',
    },
    leadSourceGroup: {
      value: '',
      error: null,
      rule: '',
    },
    leadSfId: {
      value: '',
      error: null,
      rule: '',
    },
    leadId: {
      value: null,
      error: null,
      rule: '',
    },
    experienceProvidedAtOrigination: {
      value: '',
      error: null,
      rule: '',
    },
    bankruptcyOrForeclosureProvidedAtOrigination: {
      value: false,
      error: null,
      rule: '',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export class BorrowerFormStore extends FormStore {
  private globalStore: GlobalStore;

  constructor(globalStore) {
    super({ ...borrowerForm }, globalStore);
    this.globalStore = globalStore;
  }
}

export default BorrowerFormStore;
