import { flow, makeObservable, action, observable, computed } from 'mobx';
import { LoanStore } from '@roc/feature-loans';
import { ApiResponse, GlobalStore } from '@roc/feature-app-core';
import { roundHalf } from '@roc/feature-utils';

export class PayoffNewRequestStore {
  globalStore: GlobalStore;
  private loanStore: LoanStore;
  public saved: boolean;

  tableData: any;

  url: string;

  property: any;

  showLoansForPayoffRequestModal: boolean;

  constructor(globalStore, loanStore) {
    this.globalStore = globalStore;
    this.loanStore = loanStore;
    makeObservable(this, {
      tableData: observable,
      property: observable,
      url: observable,
      showLoansForPayoffRequestModal: observable,
      setShowLoansForPayoffRequestModal: action
    })
  }

  reset() {
    this.tableData = null;
    this.url = '';
    this.property = null;
  }

  setShowLoansForPayoffRequestModal(flag) {
    this.showLoansForPayoffRequestModal = flag;
  }
}