import { Grid } from '@material-ui/core';
import { Document } from '@roc/feature-types';
import { Button, StandardDialog } from '@roc/ui';
import React from 'react';
import { useDocumentStore } from '../../../hooks/useDocumentStore';
import { BorrowerClosingAgent } from './borrowerClosingAgent';

export type BorrowerClosingAgentDialogProps = {
  open?: boolean;
  onClose?: () => void;
  document: Document;
};

export const BorrowerClosingAgentDialog = (
  props: BorrowerClosingAgentDialogProps
) => {
  const { open, onClose, document } = props;

  const { documentStore } = useDocumentStore();
  const { documentFormStore } = documentStore;
  const { borrowerClosingAgentStore } = documentFormStore;
  const { canEdit } = borrowerClosingAgentStore;

  const onSubmit = () => {
    borrowerClosingAgentStore.submitBorrowerAgent(document);
  };

  return (
    <StandardDialog
      open={open}
      title="Borrower Attorney"
      maxWidth="lg"
      handleClose={onClose}
      dialogContent={<BorrowerClosingAgent />}
      dialogActions={
        <Grid container spacing={1} justifyContent="flex-end">
          <Grid item>
            <Button
              onClick={onClose}
              color="primary"
              variant="outlined"
              testId="cancel"
            >
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={onSubmit}
              color="primary"
              variant="contained"
              testId="confirm"
              disabled={!canEdit}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      }
    />
  );
};

export default BorrowerClosingAgentDialog;
