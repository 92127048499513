import { Service } from "@roc/feature-app-core";
import axios from "axios";


const linkedInCreds = {
  redirect_uri: '/crm-portal/profile',
  response_type: 'code',
}

export const url = {
  GET_CREDS: '/api/v1/vendor/linkedIn/getCredentialsForLinkedIn',
  OAUTH_ENDPOINT: 'https://www.linkedin.com/oauth/v2/authorization',
  OAUTH_CALLBACK: '/api/v1/vendor/linkedIn/linkedInCallbackVerification',
};
export class LinkedInService extends Service {

  getCreds() {
    return this.get(url.GET_CREDS);
  }

  handleCallBack(code, state){
    return this.post(url.OAUTH_CALLBACK + '?code='+code+'&state='+state, null,null)
  }

  retrieveTokenWithOAuthCode(brand, host, clientId){
    const response_type = 'code';
    const state = "LinkedIn"+brand;
    const client_id = clientId;
    const redirect_uri = host + linkedInCreds.redirect_uri;
    const urlString = url.OAUTH_ENDPOINT + '?response_type=' + encodeURIComponent(response_type) + '&client_id=' + encodeURIComponent(client_id) + '&redirect_uri=' +
    encodeURIComponent(redirect_uri) + '&state=' + encodeURIComponent(state) + '&scope=r_marketing_leadgen_automation%20r_ads_leadgen_automation';


    window.location.href = urlString;
  }

}