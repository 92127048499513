import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Layout } from '@roc/feature-app-core';
import { ScopeOfWorkV2FormParams } from '@roc/feature-sow-shared/types';
import { ScopeOfWorkV2FormDialog, ScopeOfWorkV2LinkDialog } from '@roc/feature-sow-v2';
import { useScopeOfWorkV2Store } from '@roc/feature-sow-shared/hooks';
import { ScopesOfWorkGridV2 } from '../scopeOfWorkGridV2';


const defaultV2Params: ScopeOfWorkV2FormParams = {
  isInternal: false,
};

export const ScopeOfWorkV2 = observer(() => {
  const { scopeOfWorkV2Store } = useScopeOfWorkV2Store();
  const {
    scopeOfWorkV2FormDialogOpen,
    scopeOfWorkV2FormStore,
    scopeOfWorkLinkFormDialogOpen,
    scopeOfWorkLinkStore
  } = scopeOfWorkV2Store;

  const closeScopeOfWorkV2FormDialog = () => {
    scopeOfWorkV2Store.setScopeOfWorkV2FormDialogOpen(false);
  };

  const createNewScopeOfWorkV2 = () => {
    openScopeOfWorkLinkFormDialog();
  };

  const openScopeOfWorkV2FormDialog = (
    params: ScopeOfWorkV2FormParams = defaultV2Params
  ) => {
    scopeOfWorkV2FormStore.reset();
    scopeOfWorkV2FormStore.setParams(params);
    scopeOfWorkV2Store.setScopeOfWorkV2FormDialogOpen(true);
  };

  const openScopeOfWorkLinkFormDialog = (
    params: ScopeOfWorkV2FormParams = defaultV2Params
  ) => {
    scopeOfWorkV2FormStore.reset();
    scopeOfWorkV2FormStore.setParams(params);
    scopeOfWorkLinkStore.loanId = null;
    scopeOfWorkV2Store.setScopeOfWorkLinkFormDialogOpen(true);
  };

  const selectScopeOfWorkV2 = (scopeOfWorkId: string) => {
    const params: ScopeOfWorkV2FormParams = {
      ...defaultV2Params,
      scopeOfWorkId,
    };
    openScopeOfWorkV2FormDialog(params);
  };

  useEffect(() => {
    setPublicUrl();
    return () => {
      scopeOfWorkV2Store.reset();
    };
  }, [scopeOfWorkV2Store]);

  const setPublicUrl = async () => {
    const publicUrl = await scopeOfWorkV2FormStore.getPublicScopeOfWorkShareUrl();
    scopeOfWorkV2FormStore.publicScopeOfWorkShareUrl = publicUrl;
  }


  return (
    <Layout title="Scope of Work">
      <ScopesOfWorkGridV2
        onCreateNewScopeOfWork={createNewScopeOfWorkV2}
        onSelectScopeOfWork={selectScopeOfWorkV2}
        store={scopeOfWorkV2Store.gridStore}
        scopeOfWorkV2FormStore={scopeOfWorkV2FormStore}
      />

      <ScopeOfWorkV2FormDialog
        open={scopeOfWorkV2FormDialogOpen}
        onClose={closeScopeOfWorkV2FormDialog}
        store={scopeOfWorkV2FormStore}
      />

      <ScopeOfWorkV2LinkDialog
        open={scopeOfWorkLinkFormDialogOpen}
        onClose={() => scopeOfWorkV2Store.setScopeOfWorkLinkFormDialogOpen(false)}
        loanId={scopeOfWorkLinkStore.loanId}
        propertyId={scopeOfWorkLinkStore.propertyId}
        propertyAddress={scopeOfWorkLinkStore.propertyAddress}
        store={scopeOfWorkLinkStore}
      />
    </Layout>
  );
});

export default ScopeOfWorkV2;
