import { BreadCrumbItem } from '@roc/feature-types';
import { flow, makeObservable, observable } from 'mobx';

export class BreadCrumbsStore {
  breadCrumbItems: BreadCrumbItem[];

  constructor() {
    makeObservable(this, {
      breadCrumbItems: observable,
      setBreadCrumbItems: flow,
    });

    this.setBreadCrumbItems([]);
  }

  *setBreadCrumbItems(breadCrumbItems: BreadCrumbItem[]) {
    this.breadCrumbItems = breadCrumbItems;
  }

};
