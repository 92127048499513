export const quoteTypeOptions = [
  {
    label: 'Commercial/Personal Package',
    value: 'Commercial/Personal Package',
  },
  {
    label: 'Commercial/Personal Package and Flood',
    value: 'Commercial/Personal Package and Flood',
  },
  {
    label: 'Flood',
    value: 'Flood',
  },
];

export enum InsurancePaymentType {
  PAID_ALREADY = 'Paid Already',
  PAY_THROUGH_HUD = 'Pay Through HUD',
}

export enum InsuranceCompanyType {
  ELMSURE = 'Elmsure',
  OTHER = 'Other',
}

export enum InsuranceDocumentType {
  BINDER = 'Binder',
  INVOICE = 'Invoice',
}

export enum QuoteType {
  COMMERCIAL_PACKAGE = 'Commercial/Personal Package',
  FLOOD = 'Flood',
  COMMERCIAL_PACKAGE_AND_FLOOD = 'Commercial/Personal Package and Flood',
  PROPERTY_ONLY = 'Property Only',
  LIABILITY_ONLY = 'Liability Only',
}
