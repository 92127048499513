import { withStyles } from '@material-ui/core/styles';
import { FieldLabel, Slider } from "@roc/ui";
import { observer } from "mobx-react";
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';

const useSliderStyles = makeStyles(theme =>
  createStyles({
    slider: {
      marginTop: '2.5em',
      marginLeft: '2em',
      width: '85%',
    },
    helperText: {
      marginTop: theme.spacing(2),
    },
  })
);

const experienceMarks = [
  {
    value: 1,
    label: 'Newbie',
  },
  {
    value: 5,
    label: 'Seasoned investor',
  },
];

export const BorrowerExperienceRealStateMultifamily = observer(({ store, multiFamilyStore, ...rest }) => {
  const formFields = store.form.fields;
  const classes = useSliderStyles();

  return (
    <>
      <FieldLabel>
        Please rank the borrower's experience with 5+ unit multifamily and/or mixed-use properties
      </FieldLabel>
      <Slider
        testId="experienceSlider"
        key={formFields.borrowerId.value}
        onChangeCommitted={(event, value) => {
          store.onFieldChange('experience', value)
        }
        }
        className={classes.slider}
        value={formFields.experience.value}
        valueLabelDisplay="on"
        valueLabelFormat={value => <div>{formFields.experience.value}</div>}
        marks={experienceMarks}
        step={1}
        min={1}
        max={5}
      />
    </>
  );
});