import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { observer } from "mobx-react";
import { Layout, useBorrowerPortalMediaQueries, useUserStore } from "@roc/feature-app-core";
import { useEffect, useState } from 'react';
import { Box, Grid, Typography, Menu, IconButton, MenuItem, TextField } from '@material-ui/core';
import { Button, ConfirmDialog, MultiSelectField, createSelectField } from '@roc/ui';
import { useStore } from '@roc/client-portal-shared/hooks';
import { alertCircleOutline, send } from 'ionicons/icons';
import { IonIcon } from '@ionic/react';
import { RingCentralAuthCard } from './ringCentralAuthCard';
import { HtmlContainer } from 'libs/feature-backoffice-users/src/backOfficeUsers/components/leadDetails/htmlContainer';
import { templatePlaceHolders } from 'libs/feature-communication/src/communication/utils/constants';
import { Code, SendRounded } from '@material-ui/icons';
import { BORROWER_OBJECT_TYPE, TPO_OBJECT_TYPE } from 'libs/feature-backoffice-users/src/backOfficeUsers/components/leads/constants/constants';



const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(3, 0, 4),
      borderRadius: theme.spacing(2),
      boxShadow:
        'rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px',
      display: 'flex',
      justifyContent: 'center',
    },
    container: {
      display: 'flex',
      justifyContent: 'center',
    },
    modalContainer: {
      width: '100%',
    },
    buttonContainer: {
      paddingTop: theme.spacing(4),
    },
    contentContainer: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    title: {
      paddingLeft: theme.spacing(2),
      display: 'flex',
      justifyContent: 'flex-start',
    },
    attachmentButtonContainer: {
      display: 'flex',
      flexDirection: 'row-reverse',
      height: '30px'
    },
    textMessage: {
      borderRadius: 15,
      borderTopLeftRadius: 0,
      backgroundColor: '#90EE90',
      display: 'inline-block'
    },
  })
);

const currentPath = window.location.pathname;

export const SendBulkMessagesForm = observer(({ handleClose = () => { }, salesforceLeadStore, type, fromGrid }) => {
  const classes = useStyles();
  const { ringCentralStore, createListStore } = salesforceLeadStore;
  const { createListFormStore } = createListStore;
  const { userStore, globalStore } = useStore();
  const { userStore: { userInformation } } = useUserStore();
  const [placeHolderMenu, setPlaceHolderMenu] = useState(false);
  const [anchorPlaceHolderElement, setAnchorPlaceHolderElement] = useState<null | HTMLElement>(null);
  const [sendTo, setSendTo] = useState([]);

  const portalId = salesforceLeadStore.portalId || userStore.userInformation.portalId;
  const userId = salesforceLeadStore.userId || userStore.userInformation.userId;
  const connectedWithRingCentral = salesforceLeadStore.isSalesForce ? salesforceLeadStore.connectedWithRingcentral : userInformation.connectedWithRingcentral;

  const handleMenuOpen = (event) => {
    setAnchorPlaceHolderElement(event.currentTarget);
    setPlaceHolderMenu(true);
  }

  function handleConfirm() {
    const fields = ringCentralStore.sendBulkSMSFormStore.form.fields;
    if (ringCentralStore.getRecordList(type).some(record => ringCentralStore.isPhoneNumberEmpty(record?.phoneNumber))) {
      globalStore.notificationStore.showErrorNotification({ message: 'The lead does not have a valid phone number' });
      handleClose();
    } else if (!(sendTo.length > 0) || !fields.message.value) {
      globalStore.notificationStore.showErrorNotification({
        message: 'Please fill out the form',
      })
    }
    else {
      ringCentralStore.setSendToAll(ringCentralStore.getRecordList(type).length > 0 ? false : true);
      ringCentralStore.setListId(salesforceLeadStore?.option?.value);
      ringCentralStore.sendSMS(type, sendTo, fromGrid, salesforceLeadStore?.gridBusinessUnit);
      ringCentralStore.getSmsCount();
      handleClose();
    }
  }

  const handleConfirmSendToSelf = () => {
    ringCentralStore.sendToSelf();
    handleClose();
  }


  async function handleOnClick() {
    await ringCentralStore.getCreds();
    const host = await salesforceLeadStore.getHostByPortaId(portalId);
    ringCentralStore.retriveTokenWithOAuthToken(userId, host);
  }

  useEffect(() => {
    currentPath.includes("/backoffice") && createListFormStore.onFieldChange('objectType', 'Lead');
    createListStore.getFilterCriteriaOptions();
    ringCentralStore.getSmsCount();
    ringCentralStore.getAllTemplates();
    ringCentralStore.initSentToOptions(type);
  }, []);

  useEffect(() => {
    const lead = salesforceLeadStore.getLeadForEmailPreview();
    ringCentralStore.updatePreview(ringCentralStore.sendBulkSMSFormStore.form.fields.message.value, lead);
  }, [
    ringCentralStore.sendBulkSMSFormStore.form.fields.message.value,
  ]);

  useEffect(() => {
    createListStore.setFilterCriteriaOptions(createListStore.createListFormStore.form.fields.objectType.value)
  }, [createListStore.createListFormStore.form.fields.objectType.value, createListStore.filterCriteriaOptions]);

  const allOption = { label: "All", value: 'all' };

  const handleSendToChange = (_, sendTo) => {
    if (sendTo.some(owner => owner.value === allOption.value)) {
      sendTo = [allOption];
    }
    setSendTo(sendTo);
  };

  const isDisabled = (option) => {
    const selectedValues = sendTo.map(opt => opt.value);
    if (option.value === 'additional2' && !selectedValues.includes('additional1')) return true;
    if (option.value === 'additional3' && !selectedValues.includes('additional2')) return true;
    if (option.value === 'additional4' && !selectedValues.includes('additional3')) return true;
    if (option.value === 'additional5' && !selectedValues.includes('additional4')) return true;
    return false;
  };


  return connectedWithRingCentral ? (
    <Grid container spacing={4}>
      <Grid item style={{ flex: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6">Send to</Typography>
          </Grid>
          <Grid item xs={12}>
            <MultiSelectField
              name={'share-with-owners'}
              options={ringCentralStore.sendToOptions}
              getOptionSelected={(option, selectedValue) => {
                return option.value == selectedValue.value;
              }}
              value={sendTo}
              onChange={handleSendToChange}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="" />
              )}
              fullWidth
              required
              testId="sendTo"
              getOptionDisabled={(option) => isDisabled(option)}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">Template</Typography>
          </Grid>
          <Grid item xs={12}>
            {createSelectField({
              label: '',
              fieldName: 'message',
              testId: 'message',
              options: ringCentralStore.templates,
              store: ringCentralStore.sendBulkSMSFormStore,
            })}
          </Grid>
          <Grid item xs={12}>
            <Grid className={classes.attachmentButtonContainer}>
              <IconButton onClick={handleMenuOpen}>{<Code />}</IconButton>
            </Grid>
            <Menu
              anchorEl={anchorPlaceHolderElement}
              open={placeHolderMenu}
              onClose={() => setPlaceHolderMenu(false)}
            >
              {templatePlaceHolders.map(placeHolder => <MenuItem onClick={() => {
                setPlaceHolderMenu(false);
                ringCentralStore.sendBulkSMSFormStore.onFieldChange('message', `${ringCentralStore.sendBulkSMSFormStore.form.fields.message
                  .value} ${placeHolder}`);
              }}>{placeHolder}</MenuItem>)}
            </Menu>
            <TextField
              //testId="message"
              variant="outlined"
              //standaloneLabel
              placeholder="Message"
              inputProps={{ maxLength: 1000 }}
              value={
                ringCentralStore.sendBulkSMSFormStore.form.fields.message
                  .value
              }
              onChange={value =>
                ringCentralStore.sendBulkSMSFormStore.onFieldChange(
                  'message',
                  value.target.value
                )
              }
              multiline
              fullWidth
              minRows={3}
              maxRows={Infinity}
            //containerStyle={styles.field}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              {'Messages sent today : ' +
                ringCentralStore.smsCount +
                ' out of 1000'}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      {!!ringCentralStore.previewHtml && (
        <Grid item xs={12} md={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">Preview</Typography>
            </Grid>
            <Grid item xs={12}>
              <Box p={2} className={classes.textMessage}>
                {ringCentralStore.previewHtml}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid item xs={12}>
        <Box display="flex" justifyContent="flex-end">
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            onClick={handleConfirmSendToSelf}
            testId="sendToSelf"
            disabled={ringCentralStore.smsCount === 1000}
            style={{ width: '200px', margin: '0 5px' }}
          >
            Send To Myself
          </Button>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleConfirm}
            testId="next"
            disabled={ringCentralStore.smsCount === 1000}
            style={{ width: '200px', margin: '0 5px' }}
          >
            {'Send'}
          </Button>
        </Box>
      </Grid>
    </Grid>
  ) : (
    <Grid item container className={classes.container}>
      <Grid item>
        <IonIcon slot="end" icon={alertCircleOutline} size="large" />
      </Grid>
      <Grid item>
        <Typography variant="h4">
          You are not connected to Ringcentral
        </Typography>
      </Grid>
      <Grid item container className={classes.container}>
        <Box p={3}>
          <Typography variant="body1">
            To send bulk SMS you need to connect your RingCentral account.
          </Typography>
        </Box>
      </Grid>
      <Grid
        item
        container
        justifyContent="center"
        className={classes.buttonContainer}
      >
        <Grid item sm={7} md={5} xs={7}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            testId="next"
            onClick={handleOnClick}
          >
            Connect RingCentral
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
});

export const SendBulkMessagesModal = observer(({ handleClose, salesforceLeadStore, type, fromGrid = false }) => {
  const classes = useStyles();
  return (
    <Grid container justifyContent="center" alignItems='center' className={classes.modalContainer}>
      <SendBulkMessagesForm handleClose={handleClose} salesforceLeadStore={salesforceLeadStore} type={type} fromGrid={fromGrid} />
    </Grid>
  )
});