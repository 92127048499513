import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import {
  Box,
  ButtonGroup,
  ClickAwayListener,
  Grid,
  Grow,
  MenuList,
  Typography,
  MenuItem,
  Popper,
  Divider,
} from '@material-ui/core';
import { useStore } from '@roc/client-portal-shared/hooks';
import { observer } from 'mobx-react';
import { Grid as DataGrid } from '@roc/client-portal-shared/components';
import { Tabs } from '@roc/ui';
import { createAutocompleteAddressField } from '../../../../components/formComponents/common/componentBuilder';
import {
  creditOptions,
  propertyOwnership,
  yesNoOptions,
  addingSquareFootageOptions,
} from '../../utils/constants';
import SummaryPurchaseTab from '../sumary/summaryPurchaseTab';
import SummaryProfitAndDscrTab from '../sumary/summaryProfitAndDscrTab';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import SummaryAlreadyOwn from '../sumary/summaryAlreadyOwn';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { Button, Paper } from '@roc/ui';
import { CloudDownload, MailOutline } from '@material-ui/icons';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

const PDF_FILE_NAME = 'sizer-request.pdf';

const useStyles = makeStyles(theme => ({
  hiddenContainer: {
    height: 0,
    overflow: 'hidden',
  },
  page: {
    padding: '32pt',
  },
}));

export interface DownloadableSummaryRef {
  downloadAsPdf: () => void;
  getPdfAsBlob: () => Promise<Blob>;
}

const DownloadableSummary = observer(
  forwardRef((props, ref) => {
    const classes = useStyles();

    const section1Ref = useRef();
    const section2Ref = useRef();

    const getCanvasFromElement = (element: HTMLElement) => {
      return html2canvas(element, {
        scrollX: 0,
        scrollY: -window.scrollY,
      });
    };

    const generatePdf = async (): Promise<jsPDF> => {
      const section1Element: HTMLElement = section1Ref.current;
      const section2Element: HTMLElement = section2Ref.current;

      const [canvas1, canvas2] = await Promise.all([
        getCanvasFromElement(section1Element),
        getCanvasFromElement(section2Element),
      ]);

      const jspdf = new jsPDF('portrait', 'px', [
        section1Element.offsetWidth,
        section1Element.offsetHeight,
      ]);

      const size1 = jspdf.internal.pageSize;
      const url1 = canvas1.toDataURL('image/jpeg');
      jspdf.addImage(url1, 'JPEG', 0, 0, size1.getWidth(), size1.getHeight());

      jspdf.addPage([
        section2Element.offsetWidth,
        section2Element.offsetHeight,
      ]);

      const size2 = jspdf.internal.pageSize;
      const url2 = canvas2.toDataURL('image/jpeg');
      jspdf.addImage(url2, 'JPEG', 0, 0, size2.getWidth(), size2.getHeight());

      return jspdf;
    };

    const downloadAsPdf = async () => {
      const jspdf = await generatePdf();
      jspdf.save(PDF_FILE_NAME);
    };

    const getPdfAsBlob = async () => {
      const jspdf = await generatePdf();
      return new File([jspdf.output('blob')], PDF_FILE_NAME, {
        type: 'application/pdf',
      });
    };

    useImperativeHandle(ref, () => ({
      downloadAsPdf,
      getPdfAsBlob,
    }));

    const { fixFlipSizerStore } = useStore();
    const sizer = fixFlipSizerStore.detailsStore.sizer;
    const aquisition = sizer.property.propertyOwnership;
    const isPurchase = propertyOwnership.PURCHASED === aquisition;
    const isOwned = propertyOwnership.OWNED_BY_BORROWER === aquisition;

    return (
      <div className={classes.hiddenContainer}>
        <div ref={section1Ref} className={classes.page}>
          <Typography variant={'h2'}>
            {isPurchase ? 'Purchase' : 'Already Own'}
          </Typography>
          <Divider />
          {isPurchase && <SummaryPurchaseTab />}
          {isOwned && <SummaryAlreadyOwn />}
        </div>
        <div ref={section2Ref} className={classes.page}>
          <Typography variant={'h2'}>{'Profit & DSCR'}</Typography>
          <Divider />
          <SummaryProfitAndDscrTab isReadOnly={true} />
        </div>
      </div>
    );
  })
);

export default DownloadableSummary;
