import { Group } from '@material-ui/icons';
import { useBaseStore } from '@roc/feature-app-core';
import { AddOrSelect } from '@roc/ui';
import { observer } from 'mobx-react';
import React from 'react';
import { useBrokersStore } from '../hooks/useBrokersStore';
import { BrokerCard } from './components/brokerCard';
import { EditBrokerDialog } from './components/editBrokerDialog';
import { SelectBrokersDialog } from './components/selectBrokersDialog';

export const SelectBrokers = observer(() => {
  const { brokersStore } = useBrokersStore();
  const { selectBrokersStore } = brokersStore;
  const { brokers } = selectBrokersStore;
  const { globalStore } = useBaseStore();

  return (
    <>
      <AddOrSelect
        entity={'broker'}
        entities={brokers}
        renderEntity={broker => (
          <BrokerCard key={broker.emailAddress} broker={broker} />
        )}
        showSelect={true}
        selectButtonProps={{
          startIcon: <Group />,
          testId: 'selectBrokerButton',
          onClick: () => selectBrokersStore.openSelectBrokersDialog(),
          disabled: !selectBrokersStore.canAddMore || globalStore.userFeatures.isBroker
        }}
        showAdd={true}
        addButtonProps={{
          testId: 'addBrokerButton',
          onClick: () => selectBrokersStore.openAddNewBrokerDialog(),
          disabled: !selectBrokersStore.canAddMore || globalStore.userFeatures.isBroker,
        }}
      />
      <SelectBrokersDialog />
      <EditBrokerDialog />
    </>
  );
});