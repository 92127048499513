import { useStore } from '@roc/client-portal-shared/hooks';
import { Layout } from '@roc/client-portal-shared/components';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { PlaidLiquidityRequestsGrid } from './components/plaidLiquidityRequestsGrid';

export const PlaidLiquidityRequests = observer(({ store }) => {

  useEffect(() => {
    store.liquidityRequestsGridStore.resetAndFetchGridData();
  }, []);

  return (
    <Layout title="Browse Plaid Liquidity Requests">
      <PlaidLiquidityRequestsGrid store={store} />
    </Layout>
  );
});
