import React, { useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { StandardDialog } from '@roc/ui';
import { observer } from 'mobx-react';
import { ShareDownloadButtons } from '../shareDownloadButtons';
import { ScopeOfWorkV2Stepper } from '../../scopeOfWorkV2';
import { ScopeOfWorkV2FormStore } from '@roc/feature-sow-shared/stores/v2';
import { SuccessDialog } from '../../scopeOfWorkV2/components/successDialog';
import { Box, Grid } from '@material-ui/core';
import ScopeOfWorkV2Form from './scopeOfWorkV2Form';
import { useUserStore } from '@roc/feature-app-core';
import { useRocInternalPortalStore } from 'apps/roc-internal-portal/src/app/hooks';
import { useDocumentStore } from '@roc/feature-documents';
import { InternalReviewButtons } from '../internalReviewButtons';
import { SubmitReviewDialog } from '../../submitReviewDialog';

export type ScopeOfWorkV2FormInternalDialogProps = {
  open?: boolean;
  onClose?: () => void;
  store: ScopeOfWorkV2FormStore;
  title?: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  dialogContentRootClass: {
    padding: 0,
    '&:first-child': {
      paddingTop: 0,
    },
  },
  shareDownloadContainer: {
    padding: theme.spacing(1, 3),
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

export const ScopeOfWorkV2FormInternalDialog = observer(
  (props: ScopeOfWorkV2FormInternalDialogProps) => {
    const { open, onClose, store, title } = props;

    const classes = useStyles();

    const {
      scopeOfWorkInternalStore,
      scopeOfWorkV2LinkInternalStore,
    } = useRocInternalPortalStore();
    const { documentStore } = useDocumentStore();

    const { userStore } = useUserStore();

    const username = userStore.userInformation.username;
    const document = documentStore.currentDocument;
    const loanId = document?.loanId;
    const propertyId = document?.collateralId;
    const propertyAddress = document?.collateralAddress;

    useEffect(() => {
      if (open) {
        scopeOfWorkInternalStore.setScopeOfWorkInternalDialogParams(
          loanId,
          propertyId,
          username,
          propertyAddress
        );
        scopeOfWorkV2LinkInternalStore.setValues(
          loanId,
          propertyId,
          propertyAddress
        );
      }
    }, [open]);

    const screenshotContainerRef = useRef(null);
    const dialogRef = useRef<HTMLElement>();
    useEffect(() => {
      if (dialogRef.current) {
        dialogRef.current.scrollTo(0, 0);
      }
    }, [dialogRef.current, store.activeStep]);

    const dialogContent = (
      <Box flexGrow={1}>
        <Grid direction="column">
          <Grid
            container
            item
            alignItems="flex-end"
            justifyContent="flex-end"
            xs={12}
            spacing={3}
          >
            <Grid container item xs={12} sm={6} spacing={1} justifyContent='flex-end'>
              <InternalReviewButtons
                store={scopeOfWorkInternalStore}
              />
            </Grid>
          </Grid>
          <div className={classes.shareDownloadContainer}>
            <ShareDownloadButtons
              store={scopeOfWorkInternalStore}
              screenshotContainerRef={screenshotContainerRef}
            />
          </div>
          <ScopeOfWorkV2Stepper
            store={scopeOfWorkInternalStore}
            screenshotContainerRef={screenshotContainerRef}
          />
          <SubmitReviewDialog store={scopeOfWorkInternalStore} />
        </Grid>
      </Box>
    );

    return (
      <>
        <StandardDialog
          dialogContentRef={dialogRef}
          open={open}
          title={title ? title : 'Scope of Work'}
          maxWidth="xl"
          removeContentBox
          dialogContentclasses={{
            root: classes.dialogContentRootClass,
          }}
          handleClose={onClose}
          dialogContent={dialogContent}
        />
        <SuccessDialog store={scopeOfWorkInternalStore} />
      </>
    );
  }
);
