import { Box } from '@material-ui/core';
import React from 'react';

import notFoundImage from './../../../assets/not-found.png';

export const NotFoundImage = notFoundImage;

export const NotFound = () => {
  return (
    <Box display="flex" alignItems="center" justifyContent="center" width="100%">
      <img
        alt="Not Found"
        src={notFoundImage}
        style={{
          maxWidth: 800,
          width: '100%',
        }}
      />
    </Box>
  );
};
