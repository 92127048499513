import { Grid } from '@material-ui/core';
import {
  createCurrencyField,
  createPercentageField,
  createSelectField,
  createTextField,
  createRadioField,
} from '@roc/client-portal-shared/components';
import { observer } from 'mobx-react';
import React from 'react';
import { LiquidityCalculatorStore } from '../stores/liquidityCalculatorStore';
import { loanPurposeOptions, amortizationOptions, loanSubtypeOptions } from '../utils/liquidityCalculatorConstants';

interface LiquidityCalcFieldProps {
  store: LiquidityCalculatorStore;
  tooltip: string;
}

const radioFieldOptions = [
  {
    label: 'Yes',
    value: true,
  },
  {
    label: 'No',
    value: false,
  },
];


const field = FieldComponent => {
  const WrappedFieldComponent = observer(FieldComponent);
  return props => (
    <Grid item xs={6} md={props.md}>
      <WrappedFieldComponent {...props} />
    </Grid>
  );
};

export const LoanId = field(({ store }: LiquidityCalcFieldProps) => {
  return createTextField({
    store,
    label: 'Loan Id',
    testId: 'loanId',
    fieldName: 'loanId',
  });
});

export const LoanAmount = field(({ store }: LiquidityCalcFieldProps) => {
  return createCurrencyField({
    store,
    label: 'Loan Amount',
    testId: 'loanAmount',
    fieldName: 'loanAmount',
  });
});

export const Rate = field(({ store }: LiquidityCalcFieldProps) => {
  return createPercentageField({
    store,
    label: 'Rate',
    testId: 'rate',
    fieldName: 'rate',
    decimalScale: 2,
  });
});

export const AnnualTaxes = field(({ store }: LiquidityCalcFieldProps) => {
  return createCurrencyField({
    store,
    label: 'Annual Taxes',
    testId: 'annualTaxes',
    fieldName: 'annualTaxes',
  });
});

export const AnnualInsurance = field(({ store }: LiquidityCalcFieldProps) => {
  return createCurrencyField({
    store,
    label: 'Annual Insurance',
    testId: 'annualInsurance',
    fieldName: 'annualInsurance',
  });
});

export const LoanPurpose = field(({ store }: LiquidityCalcFieldProps) => {
  return createSelectField({
    store,
    label: 'Loan Purpose',
    testId: 'loanPurpose',
    fieldName: 'loanPurpose',
    options: loanPurposeOptions,
  });
});

export const BorrowerType = field(({ store }: LiquidityCalcFieldProps) => {
  return createRadioField({
    store,
    testId: 'isBorrowerForeignNational',
    fieldName: 'isBorrowerForeignNational',
    label: 'Is the Borrower a Foreign National?',
    options: radioFieldOptions,
    row: true,
  });
});

export const MultifamilyMixedUse = field(({ store }: LiquidityCalcFieldProps) => {
  return createRadioField({
    store,
    testId: 'isMultifamilyOrMixedUseProperty',
    fieldName: 'isMultifamilyOrMixedUseProperty',
    label: 'Is the property mixed use or multifamily?',
    options: radioFieldOptions,
    row: true,
  });
});

export const AmortizationType = field(({ store }: LiquidityCalcFieldProps) => {
  return createSelectField({
    store,
    label: 'Amortization Type',
    testId: 'amortization',
    fieldName: 'amortization',
    options: amortizationOptions
  });
});

export const LoanSubtype = field(({ store }: LiquidityCalcFieldProps) => {
  return createSelectField({
    store,
    label: 'Loan Subtype',
    testId: 'loanSubType',
    fieldName: 'loanSubType',
    options: loanSubtypeOptions
  });
});

// Purchase
export const PurchasePrice = field(({ store }: LiquidityCalcFieldProps) => {
  return createCurrencyField({
    store,
    label: 'Purchase Price',
    testId: 'purchasePrice',
    fieldName: 'purchasePrice',
  });
});

export const DepositPaid = field(({ store }: LiquidityCalcFieldProps) => {
  return createCurrencyField({
    store,
    label: 'Deposit Paid',
    testId: 'depositPaid',
    fieldName: 'depositPaid',
  });
});

export const OriginationFees = field(({ store }: LiquidityCalcFieldProps) => {
  return createPercentageField({
    store,
    label: 'Origination Fees',
    testId: 'originationFees',
    fieldName: 'originationFees',
    decimalScale: 2,
  });
});

// Rate-Term refi
export const Payoff = field(({ store }: LiquidityCalcFieldProps) => {
  return createCurrencyField({
    store,
    label: 'Payoff',
    testId: 'payoff',
    fieldName: 'payoff',
  });
});

// Calculated
export const DownPayment = field(({ store }: LiquidityCalcFieldProps) => {
  return createCurrencyField({
    store,
    label: 'Down Payment',
    testId: 'downPayment',
    fieldName: 'downPayment',
    disabled: true,
    allowNegative: true,
  });
});

export const OriginationAmount = field(({ store }: LiquidityCalcFieldProps) => {
  return createCurrencyField({
    store,
    label: 'Origination Fee Amount',
    testId: 'originationAmount',
    fieldName: 'originationAmount',
    disabled: true,
  });
});

export const ClosingCosts = field(({ store }: LiquidityCalcFieldProps) => {
  return createCurrencyField({
    store,
    label: 'Estimated Closing Costs',
    testId: 'closingCosts',
    fieldName: 'closingCosts',
    disabled: true,
  });
});
