import { UpdateOutlined, ExpandMore } from '@material-ui/icons';
import { ItemDisplay } from '../../../../components/itemDisplay';
import { TitleDivider } from '../../../../components/titleDivider';
import {
  DetailsTable,
  DetailsTableField,
} from '../../../../components/detailsTable';
import { useState } from 'react';
import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@material-ui/core';
import { observer } from 'mobx-react';
import { ViewControls } from '../../../../components/viewControls';
import { RoundedBackgroundIcon } from '../../../../components/roundedBackgroundIcon';
import { ClosingFormStore } from '../../../../stores/closing/closingFormStore';
import { ClosingForm } from '../closing/closingForm';
import {
  getBooleanDisplayValue,
  getValueOrDefaultDash,
  parseDate,
  formatPercentageValue,
} from 'libs/feature-one-tool/src/quote/utils/utils';
import { ClosingStore } from 'libs/feature-one-tool/src/quote/stores/closing/closingStore';
import { insertIf } from '@roc/feature-utils';

interface ClosingSummaryAccordionProps {
  store: ClosingStore;
  expanded: boolean;
  onExpand: (expanded: boolean) => void;
  onEdit: () => void;
  allowParticipation: boolean;
}

export const ClosingSummaryAccordion = observer(
  ({
    store,
    expanded,
    onExpand,
    onEdit,
    allowParticipation,
  }: ClosingSummaryAccordionProps) => {
    const { closingFormStore } = store;
    const closingFormValues = closingFormStore.getFormValues();
    const closingDetails: DetailsTableField[] = [
      ...insertIf(allowParticipation, [
        {
          label: 'Participation',
          value: getValueOrDefaultDash(closingFormValues.lenderParticipation),
          colSpan: 1,
        },
        {
          label: 'Participation Percentage',
          value: formatPercentageValue(
            closingFormValues.origLenderSubordination
          ),
          colSpan: 1,
        },
      ]),
      {
        label: 'Preferred Closing Attorney',
        value: getValueOrDefaultDash(
          closingFormValues.preferredClosingAttorney
        ),
        colSpan: 1,
      },
      {
        label: 'Date of Closing',
        value: parseDate(closingFormValues.requestedClosingDate),
        colSpan: 1,
      },
      {
        label: 'Preferred Insurance Company',
        value: getValueOrDefaultDash(closingFormValues.preferredInsurance),
        colSpan: 1,
      },
      {
        label: 'Preferred Title Company',
        value: getValueOrDefaultDash(closingFormValues.preferredTitle),
        colSpan: 1,
      },
      {
        label: 'Originator',
        value: '-',
        colSpan: 1,
      },
      {
        label: '',
        value: '',
        colSpan: 1,
      },
    ];
    const flastTrackDetails: DetailsTableField[] = [
      {
        label: 'Do you want to FAST TRACK this loan?',
        value: getBooleanDisplayValue(closingFormValues.fastTrack),
        colSpan: 2,
      },
    ];

    const appraisalData: DetailsTableField[] = [
      {
        label: 'Do you want to order an Appraisal with Tamarisk?',
        value: getBooleanDisplayValue(
          closingFormValues.submitAppraisalWithTamarisk
        ),
        colSpan: 2,
      },
    ];

    return (
      <Accordion
        expanded={expanded}
        onChange={(e, expanded) => onExpand(expanded)}
        elevation={0}
      >
        <AccordionSummary
          expandIcon={<ExpandMore fontSize="large" />}
          aria-controls={`borrower-information-accordion-${1}`}
        >
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xs={6}>
              <Grid
                container
                spacing={2}
                justifyContent="flex-start"
                alignItems="center"
              >
                <Grid item alignItems="center" style={{ height: '100%' }}>
                  <RoundedBackgroundIcon
                    Icon={UpdateOutlined}
                    iconColor="#6D6D6D"
                  />
                </Grid>
                <Grid item>
                  <Typography
                    variant="h5"
                    style={{ fontSize: '20px', fontWeight: 'bold' }}
                  >
                    Closing
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid
                container
                spacing={3}
                justifyContent="flex-end"
                alignItems="center"
              >
                <Grid item>
                  <ItemDisplay
                    label="Requested Closing Date"
                    value={parseDate(closingFormValues.requestedClosingDate)}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            container
            spacing={2}
            alignItems="center"
            style={{ marginLeft: '58px' }}
          >
            <Grid item xs={12}>
              <Grid
                container
                spacing={2}
                justifyContent="flex-end"
                alignItems="center"
              >
                <ViewControls showRemoveButton={false} onEditClick={onEdit} />
              </Grid>
            </Grid>

            <Grid item xs={12} style={{ marginBottom: 10, marginTop: 10 }}>
              <TitleDivider title="Closing Details" />
            </Grid>
            <DetailsTable fields={closingDetails} />
            <Grid item xs={12} style={{ marginBottom: 10, marginTop: 10 }}>
              <TitleDivider title="Fast Track" />
            </Grid>
            <DetailsTable fields={flastTrackDetails} />
            {store.canSubmitAppraisalWithTamarisk && (
              <>
                <Grid item xs={12} style={{ marginBottom: 10, marginTop: 10 }}>
                  <TitleDivider title="Appraisals" />
                </Grid>
                <DetailsTable fields={appraisalData} />
              </>
            )}
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  }
);
