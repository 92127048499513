import {
  Box,
  Tab,
  Tabs,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  PortalCard,
} from '@roc/ui';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { DrawsTab } from './components/drawsTab';
import { ScopeOfWorkTab } from './components/scopeOfWorkTab';

const useStyles = makeStyles(theme => ({
  cardHeader: {
    padding: '8px 0 0 0 !important',
    border: `1px solid #eee`
  },
  cardContent: {
  },
}));

export const LoanDrawsTabs = ({ tabIndex, onTabChange }) => {
  return (
    <Box display="flex" justifyContent="space-between">
      <Tabs
        value={tabIndex}
        indicatorColor="primary"
        textColor="primary"
        onChange={onTabChange}
      >
        <Tab label="Draws" />
        <Tab label="Scope of Work" />
      </Tabs>
    </Box>
  );
}

export const LoanDrawsDashboardSharedNew = observer(
  ({ loanId }: { loanId: string }) => {
    const [tabIndex, setTabIndex] = useState(0);
    const classes = useStyles();

    const handleTabChange = (event, newValue) => {
      setTabIndex(newValue);
    };

    return (
      <PortalCard
        cardTitle={
          <LoanDrawsTabs tabIndex={tabIndex} onTabChange={handleTabChange} />
        }
        cardHeaderClassName={classes.cardHeader}
        cardContentClassName={classes.cardContent}
      >
        {
          tabIndex === 0 &&
          (<>
            <DrawsTab loanId={loanId} />
          </>)
        }
        {
          tabIndex === 1 &&
          <ScopeOfWorkTab loanId={loanId} />
        }
      </PortalCard>
    );
  }
);
