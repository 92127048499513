import { ReactNode, useEffect } from 'react';
import { Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { formatDate } from '@roc/feature-utils';
import { DataGrid, GridColumnProps } from '@roc/ui';
import { formatCurrency } from '@roc/ui/utils';
import { observer } from 'mobx-react';
import { useRevenueRecognitionStore } from '../hooks/useRevenueRecognitionStore';

const useStyles = makeStyles(theme => ({
  gridContainer: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    height: '80vh',
    overflow: 'hidden',
  },
}));


interface RevenueRecognitionGridInterface {
  columns: GridColumnProps[];
  toolbar?: ReactNode;
}

export const RevenueRecognitionGrid = observer(({ columns, toolbar }: RevenueRecognitionGridInterface) => {
  const classes = useStyles();
  const { revenueRecognitionStore } = useRevenueRecognitionStore();
  const { revenueRecognitionGridStore } = revenueRecognitionStore;

  useEffect(() => {
    revenueRecognitionGridStore.resetAndFetchGridData();
  }, [])

  const frameworkComponents = {
    dateCellRenderer: ({ value }) => {
      return value ? formatDate(value, 'MM/dd/yyyy') : '';
    },
    currencyCellRenderer({ value }) {
      return formatCurrency(value);
    },
    booleanCellRenderer({ value }) {
      return value ? 'Yes' : 'No';
    },
  };

  const onSortChanged = params => {
    const sortModel = params.api.getSortModel();
    revenueRecognitionGridStore.setSortModel(sortModel);
  };

  const onFilterChanged = params => {
    const filterModel = params.api.getFilterModel();
    revenueRecognitionGridStore.setFilterModel(filterModel);
  };

  return (
    <Box>
      <Grid item className={classes.gridContainer}>
        <DataGrid
          columns={columns}
          rows={revenueRecognitionGridStore.gridData.data.rows}
          frameworkComponents={frameworkComponents}
          headerCheckboxSelection={true}
          suppressRowClickSelection={true}
          toolbar={toolbar}
          onSortChanged={onSortChanged}
          onFilterChanged={onFilterChanged}
          isLoading={revenueRecognitionGridStore.isLoading}
          showFilters={revenueRecognitionGridStore.showFilters}
          sortModel={revenueRecognitionGridStore.sortModel}
          filterModel={revenueRecognitionGridStore.filterModel}
          paginationData={revenueRecognitionGridStore.gridData.meta}
          setPageNumber={revenueRecognitionGridStore.setPageNumber}
          enablePaginationSelectPage={true}
          key={revenueRecognitionStore.selectedModule.value}
        />
      </Grid>
    </Box>
  );
});
