import {
  LoanSubType,
  MIXED_USE,
  MULTIFAMILY_5_PLUS,
  SINGLE_FAMILY,
  CONDOMINIUM,
} from '@roc/feature-utils';

export const propertyTypesByLoanSubtype = (type: LoanSubType) => {
  switch (type) {
    case LoanSubType.MULTIFAMILY_BRIDGE_5PLUS:
      return [
        { value: MULTIFAMILY_5_PLUS, label: '5+ Unit Multifamily' },
        { value: MIXED_USE, label: MIXED_USE },
      ];
    case LoanSubType.GROUND_UP:
      return [
        { value: SINGLE_FAMILY, label: SINGLE_FAMILY },
        { value: 'Duplex', label: 'Duplex' },
        { value: 'Triplex', label: 'Triplex' },
        { value: 'Quadruplex', label: 'Quadruplex' }
      ];
    case LoanSubType.FIX_AND_FLIP:
    case LoanSubType.FIX_AND_FLIP_PRO:
      return [
        { value: SINGLE_FAMILY, label: SINGLE_FAMILY },
        { value: 'Duplex', label: 'Duplex' },
        { value: 'Triplex', label: 'Triplex' },
        { value: 'Quadruplex', label: 'Quadruplex' },
        { value: CONDOMINIUM, label: 'Warrantable Condominium' }
      ];
    case LoanSubType.STABILIZED_BRIDGE:
    case LoanSubType.SINGLE_PROPERTY:
    case LoanSubType.RENTAL_PORTFOLIO:
      return [
        { value: SINGLE_FAMILY, label: SINGLE_FAMILY },
        { value: 'Duplex', label: 'Duplex' },
        { value: 'Triplex', label: 'Triplex' },
        { value: 'Quadruplex', label: 'Quadruplex' },
        { value: CONDOMINIUM, label: 'Warrantable Condominium' },
        { value: MULTIFAMILY_5_PLUS, label: '5+ Unit Multifamily' },
        { value: MIXED_USE, label: MIXED_USE },
      ];
    default:
      return [
        { value: SINGLE_FAMILY, label: SINGLE_FAMILY },
        { value: 'Duplex', label: 'Duplex' },
        { value: 'Triplex', label: 'Triplex' },
        { value: 'Quadruplex', label: 'Quadruplex' },
        { value: CONDOMINIUM, label: 'Warrantable Condominium' },
        { value: MULTIFAMILY_5_PLUS, label: '5+ Unit Multifamily' },
        { value: MIXED_USE, label: MIXED_USE },
      ];
  }
};
