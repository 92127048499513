import { Service } from '@roc/client-portal-shared/services';

const url = {
  UNSUBSCRIBE: '/api/v1/user/public/unsubscribe',
};

const monthlyUnsubsURL = {
  UNSUBSCRIBEMONTHLY: '/api/v1/user/public/unsubscribeFromMonthlyReminders',
};

export class UnsubscribeService extends Service {
  unsubscribe(token : string) {
    return this.get(`${url.UNSUBSCRIBE}?token=${token}`);
  }

  unsubsMonthlyReminder(userId : string) {
    return this.get(`${monthlyUnsubsURL.UNSUBSCRIBEMONTHLY}?userId=${userId}`);
  }

}
