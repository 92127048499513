export const BORROWER_PORTAL_LITE_PATH = 'lite';
const LOANS_HOME_BASE_PATH = 'loanview';
const LOAN_BASE_PATH = ':loanId';

export const getBorrowerLiteLoanRoutesConfig = (basePath: string, baseUrl: string) => {
  return {
    loanview: (loanViewParams?: string) => ({
      path: `${basePath}/${BORROWER_PORTAL_LITE_PATH}/${LOANS_HOME_BASE_PATH}/${LOAN_BASE_PATH}`,
      url: `${baseUrl}/${BORROWER_PORTAL_LITE_PATH}/${LOANS_HOME_BASE_PATH}/${loanViewParams}`,
      documentTitle: `Loan View`,
      children: {
        dashboard: {
          path: `${basePath}/${BORROWER_PORTAL_LITE_PATH}/${LOANS_HOME_BASE_PATH}/${LOAN_BASE_PATH}/dashboard`,
          url: `${baseUrl}/${BORROWER_PORTAL_LITE_PATH}/${LOANS_HOME_BASE_PATH}/${loanViewParams}/dashboard`,
          documentTitle: `Loan Dashboard`,
        },
        borrowerDocuments: {
          path: `${basePath}/${BORROWER_PORTAL_LITE_PATH}/${LOANS_HOME_BASE_PATH}/${LOAN_BASE_PATH}/documents`,
          url: `${baseUrl}/${BORROWER_PORTAL_LITE_PATH}/${LOANS_HOME_BASE_PATH}/${loanViewParams}/documents`,
          documentTitle: `Documents - ${loanViewParams}`,
        },
        properties: {
          path: `${basePath}/${BORROWER_PORTAL_LITE_PATH}/${LOANS_HOME_BASE_PATH}/${LOAN_BASE_PATH}/properties`,
          url: `${baseUrl}/${BORROWER_PORTAL_LITE_PATH}/${LOANS_HOME_BASE_PATH}/${loanViewParams}/properties`,
          documentTitle: `Loan Properties - ${loanViewParams}`,
        },
      },
    }),
  };
};
