import { observer } from 'mobx-react';
import React, { useEffect } from 'react';

export const MersOnboardingDocusignCallback = observer(() => {
  useEffect(() => {
    const { search } = location || {};
    if (search) {
      const { origin } = window.parent.location;
      const parsed = search.replace('?', '').split('=');
      if (parsed[1] === 'signing_complete') {
        window.parent.postMessage('mers-onboarding-docusign-done', origin);
      } else {
        window.parent.postMessage('mers-onboarding-docusign-incomplete', origin);
      }
    }
  }, []);

  return <></>;
});
