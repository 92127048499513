import { SelectOption } from "@roc/ui";

export enum CounterPartyExpensesTab {
  ALL_PAYABLE = 'ALL_PAYABLE',
  PENDING_PAYABLE = 'PENDING_PAYABLE',
  ALL_RECEIVABLE = 'ALL_RECEIVABLE',
  PENDING_RECEIVABLE = 'PENDING_RECEIVABLE',
  ALL_BILL_RECEIVABLE = 'ALL_BILL_RECEIVABLE',
  BILL_RECEIVABLE = 'BILL_RECEIVABLE',
  PAYABLES_APPROVED = 'APPROVED_PAYABLE',
  ALL_PAYABLES_APPROVED = 'ALL_PAYABLES_APPROVED',
  LOAN_PAYABLE = 'LOAN_PAYABLE',
  LOAN_RECEIVABLE = 'LOAN_RECEIVABLE',
};

export const PAYABLES_TABS = [
  {
    value: CounterPartyExpensesTab.PENDING_PAYABLE,
    label: 'Pending',
  },
  {
    value: CounterPartyExpensesTab.ALL_PAYABLE,
    label: 'All',
  },
];
export const PAYABLES_APPROVED_TABS = [
  {
    value: CounterPartyExpensesTab.PAYABLES_APPROVED,
    label: 'Approved',
  },
  {
    value: CounterPartyExpensesTab.ALL_PAYABLES_APPROVED,
    label: 'All',
  },
];

export const LOAN_TABS = [
  {
    value: CounterPartyExpensesTab.LOAN_PAYABLE,
    label: 'Payables',
  },
  {
    value: CounterPartyExpensesTab.LOAN_RECEIVABLE,
    label: 'Receivables',
  },
];

export interface Entity {
  label: string;
  value: string;
}

export enum ProtectiveAdvanceModalType {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
};

export const BILL_TRANSACTION = 'BILL_TRANSACTION';

export enum PayableReceivablesTypes {
  REJECTED = 'Rejected',
  PAID = 'Paid',
  APPROVED = 'Approved',
  PENDING = 'Pending',
};
export const TYPES_OF_FEE: Array<SelectOption> = [
  { label: 'Appraisal Fee', value: 'Appraisal Fee' },
  { label: 'BPO Fee', value: 'BPO Fee' },
  { label: 'Courier Fee', value: 'Courier Fee' },
  { label: 'Delinquent Property Tax', value: 'Delinquent Property Tax' },
  { label: 'Force Placed Insurance', value: 'Force Placed Insurance' },
  { label: 'Legal Fee', value: 'Legal Fee' },
  { label: 'Miscellaneous Fee', value: 'Miscellaneous Fee' },
  { label: 'Property Preservation Fee', value: 'Property Preservation Fee' },
  { label: 'Real Estate Tax', value: 'Real Estate Tax' },
  { label: 'Recording Fee', value: 'Recording Fee' },
  { label: 'REO Insurance', value: 'REO Insurance' },
  { label: 'Title Fee', value: 'Title Fee' },
  { label: 'Sheriffs Fee', value: 'Sheriffs Fee' },
  { label: 'Town Fee', value: 'Town Fee' },
];
