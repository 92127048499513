// This file can be replaced during build by using the `fileReplacements` array.
// When building for production, this file is replaced with `environment.prod.ts`.

export const environment = {
  production: false,
  whitelabelHost: 'https://stg.privatelenderportal.com',
  jwt: "eyJ6aXAiOiJERUYiLCJhbGciOiJSU0EtT0FFUC0yNTYiLCJlbmMiOiJBMjU2R0NNIiwiY3R5IjoiSldUIiwiaXNzIjoibG9zLWp3dC1tcyIsImp0aSI6IjRTZnFVcS1OM3I5VWZicGxEZnFVcGciLCJraWQiOiJsb3Mtand0LW1zIn0.JWV-eC96EwY6dl6L3y7Pi6El77iFyIdcb2EeeC-tEGrDemtM6RnbcfpM6ZgRq1rYyjuI2fjrpxryI2n0GQM_tmLmzfrS2wBLfpCBpKk1q8VNBqx-_Eqw5vaklwh76cTTxvcVkD6Ag42hKeDftPUCjehSFsaG7PVHzB5NsRk5v-KtuSVgDJX1bSWrDwuvER2VReR0mTuzWXWedJQ1MjAa4YAMTpdGkKmBtxNNLvZxLPLc-5sXU_W5sFvPiD5syUSnFh9lixp7Zw4Dxd-tBDSfT4iyWaDCD_AtlCkCX7JCyR7Niqgq7TWP383DBdRq4qR7Ijcg7JYUSgBH5Eg0WnFW-w.3PuQRMZFFPRPmyjz.VR8Tm1ycU3L0uugSuAoPjVH7IHa_OOeTWeTw7pcJ9xAtSPC1OD2mJbDPsakPTontI5-bZ9x9YcHgn30Id8hCKLKbbhT-CrFF-1Ycv8DIo52tVuWKRREji0cVJBTbBL40zcsREdOXxDbsi6yAoMl7dU-0wwvTbmGPV5f6x0T6DRpQMIZQEI31nsn6ULGbHDffpg8wz1dNbqQwljjmT_Oy59iXKxLQFjDCTxOHCZSWa-1B8ydBvNjcRo4NVqPWoW6gDwIhHTgs1eCGrNm4SNCq_UWoOqvAgxVd9RUyhAcx9DaJ_eaAscS99TW9o-7rV3v4adqTZMg2wXgwGBUcBvUV3n7HNJltAxBYsHpUP7Pd9TJ5qnnS4cCg0A3Dxw9bGHw_Y6SI8vq1nXr5GTQ4Y7EdvIaJFeBPaC2uFbdCyhdrg_7ZgY5pdCyFeGcl3JaVeX17ZQCEjCB6u4wiTWMk6hHCarQ81yCA4rpjrSpxNpJpIqRETztyHrwqwteU1gbxWPSAyu8qtDUyICTGWIaTuOIbWKhCb5WIRNn7ZIlCADKYngN8HpBiaerTbWCU9zS0q75UfZn2Jf3m4oQX1YXJJZe96UixtSv1hYxt-YgY4RB10tw4maVj3Yzptn7TuRUWxNX6TqHtQiu-A7jtAfduSz6a0gzpzt8KBy40gjsieoYCe9wPh13lV31gxbTX-T1NqHwBiyHWCaxxy0EAEIAE91SzXBfwxAl6yUx76mrcWgXylGPmUn38HjME1aj70pJL1udb3NnP4zSE9f3cgWV2d9OG.MkqX60RZh24YwspXd7f8hA",
  gtmTrackingId: '',
  contentfulParams: {
    space: '6euw9nkwzdou',
    accessToken: 'zkkPRcXCv0R3fDb3JW626CvW3ss4a59iPEH8DK-jyR0',
    previewToken: 'Cg-TN9hvknfmhGj7u0SkgXgAPS17WdiCnHxVYtSmZLY',
    environment: 'master',
  },
};
