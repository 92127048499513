import { IconButton, Link } from '@material-ui/core';
import {
  ArrowForward,
  Delete,
  Edit,
  FindInPageOutlined,
  GetApp,
  History,
  Info,
  Launch,
  Publish,
  Search,
} from '@material-ui/icons';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { useBaseStore, useUserStore } from '@roc/feature-app-core';
import {
  DocumentName,
  DocumentStatus,
  DONT_DELETE_EXCLUDE_DOCUMENTS,
  useDocumentStore,
} from '@roc/feature-documents';
import { useLoanStore } from '@roc/feature-loans';
import { Document } from '@roc/feature-types';
import { insertIf } from '@roc/feature-utils';
import {
  AlertDialog,
  DropdownMenu,
  FileUpload,
  FileUploadModal,
} from '@roc/ui';
import { DocumentHistoryDialog } from 'libs/feature-documents/src/documents/components/documentHistory/documentHistoryDialog';
import { isFileAlreadyUploaded } from 'libs/feature-documents/src/documents/utils/documents';
import { observer } from 'mobx-react';
import { useMemo, useRef, useState } from 'react';

import { makeStyles, Theme } from '@material-ui/core/styles';
import { DeleteModal } from '../common/deleteModal';
import { RenameModal } from '../common/renameModal';
import { MoveDocumentModal } from '../common/moveDocumentModal';

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    color: theme.palette.text.primary,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  downloadTodo: {
    color: '#86b8ff',
    margin: theme.spacing(-1.5, 0),
  },
}));

const showOpen = document => {
  return (
    document.isForm &&
    (document.canOpenForm ||
      [DocumentName.DRAW_REPORT, DocumentName.CLOSING_CONDITION_SUFIX].includes(
        document.documentName
      ))
  );
};

const showDownload = document => {
  return document.canDownloadFile;
};

export const TodoActions = ({ document, loanProcess }) => {
  const { documentStore } = useDocumentStore();
  const [showHistory, setShowHistory] = useState(false);
  const [isUploadOpen, setUploadOpen] = useState(false);
  const [showLoanDocumentId, setShowLoanDocumentId] = useState(false);
  const { globalStore } = useBaseStore();
  const { userStore } = useUserStore();
  const { loanStore } = useLoanStore();
  const [isRenameOpen, setIsRenameOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isMoveOpen, setIsMoveOpen] = useState(false);
  const isInternalPortal = globalStore.isInternalPortal;

  const onUpload = async (file, doc) => {
    await documentStore.uploadDocument(
      file,
      doc,
      loanProcess,
      null,
      document.drawId
    );
    documentStore.loanTodosStore.fetchTodos(document?.loanId, loanProcess);
  };

  const onOpenForm = (doc: Document) => {
    documentStore.onOpenForm(doc);
  };

  const onDownload = doc => documentStore.downloadDocument(doc);

  const onViewForm = () => {
    documentStore.openDrawReviewModal = true;
  };

  const onPreview = doc => documentStore.previewDocument(doc);

  const onShareLink = doc => documentStore.onShareLink(doc);

  const onRequestMoreInformation = (doc: Document) => {
    documentStore.requestMoreInformation(doc);
  };

  const downloadBorrowerPastLoanDocuments = doc =>
    documentStore.downloadBorrowerPastLoanDocuments(doc);

  const showRenameAndDelete =
    !!document.dropboxPath && globalStore.isInternalPortal && !DONT_DELETE_EXCLUDE_DOCUMENTS.includes(document.documentName);

  const getDocumentActions = () => {
    const actionList = [];

    if (document.isForm) {
      if (document.documentName === DocumentName.DRAW_REPORT) {
        return [
          {
            icon: Launch,
            name: 'Open',
            action: () => onOpenForm(document),
            disabled: !(document.status === DocumentStatus.ACCEPTED),
          },
        ];
      }
      if (
        document.documentName.includes(DocumentName.CLOSING_CONDITION_SUFIX)
      ) {
        return [
          {
            icon: Launch,
            name: 'Open',
            action: () => onOpenForm(document),
          },
          {
            icon: GetApp,
            name: 'Download',
            action: () => onDownload(document),
            disabled: !document.canDownloadFile,
          },
        ];
      }
      if (document.canOpenForm) {
        actionList.push({
          icon: Launch,
          name: 'Open',
          action: () => onOpenForm(document),
        });
      }
      if (document.canRequestMoreInformation && userStore.isBackOfficeUser) {
        actionList.push({
          icon: FindInPageOutlined,
          name: 'Mark as More Info Needed',
          action: () => onRequestMoreInformation(document),
        });
      }
    } else {
      if (document.canUploadFile) {
        actionList.push({
          icon: Publish,
          name: 'Upload',
          action: () => setUploadOpen(true),
        });
      }
      if (document.canDownloadFile) {
        actionList.push({
          icon: GetApp,
          name: 'Download',
          action: () => onDownload(document),
        });
        actionList.push({
          icon: Search,
          name: 'Preview',
          action: () => onPreview(document),
        });
      }
      if (showRenameAndDelete) {
        actionList.push({
          icon: Edit,
          name: 'Rename',
          action: () => setIsRenameOpen(true),
        });
        actionList.push({
          icon: Delete,
          name: 'Delete',
          action: () => setIsDeleteOpen(true),
        });
        actionList.push({
          icon: ArrowForward,
          name: 'Move',
          action: () => setIsMoveOpen(true),
        });
      }
      if (document.documentName === DocumentName.DRAW_REQUEST_FORM) {
        return [
          {
            icon: Launch,
            name: 'Fill Form',
            action: () => (documentStore.openDrawRequestModal = true),
            disabled: !(
              document.status === DocumentStatus.MORE_INFO_NEEDED ||
              document.status === DocumentStatus.PENDING
            ),
          },
          {
            icon: Link,
            name: 'Share Link',
            action: () => onShareLink(document),
          },
        ];
      }
      if (document.documentName === DocumentName.PROPERTY_PHOTOS) {
        return [
          {
            icon: Link,
            name: 'Share Link',
            action: () => onShareLink(document),
          },
        ];
      }

      if (document.documentName === DocumentName.PLAID_BANKS) {
        return [
          ...insertIf(isFileAlreadyUploaded(document), [
            {
              icon: GetApp,
              name: 'Download Document',
              action: () => onDownload(document),
            },
          ]),
          ...insertIf(globalStore.userFeatures.plaidRegistration, [
            {
              icon: Link,
              name: 'Share Link',
              action: () => onShareLink(document),
            },
          ]),
        ];
      }
      if (
        globalStore.isInternalPortal &&
        document.documentName === DocumentName.BORROWER_PAST_LOAN_DOCUMENTS
      ) {
        actionList.push({
          icon: GetApp,
          name: 'Download Zip',
          action: () => downloadBorrowerPastLoanDocuments(document),
        });
      }
    }
    if (document.canDownloadFile) {
      actionList.push({
        icon: History,
        name: 'History',
        action: () => setShowHistory(true),
      });
    }
    if (globalStore.isInternalPortal) {
      actionList.push({
        icon: Info,
        name: 'View LoanDocumentId',
        action: () => setShowLoanDocumentId(true),
      });
    }
    return actionList;
  };

  const documentActions = useMemo(getDocumentActions, [
    document,
    onUpload,
    onDownload,
    onOpenForm,
  ]);

  return documentActions.length ? (
    <>
      <DropdownMenu options={documentActions}>
        <MoreHorizIcon />
      </DropdownMenu>
      <DocumentHistoryDialog
        open={showHistory}
        onClose={() => setShowHistory(false)}
        document={document}
      />
      <AlertDialog
        open={!!showLoanDocumentId}
        title="Loan Document Id"
        body={document.loanDocumentId}
        handleOk={() => setShowLoanDocumentId(false)}
        handleClose={() => setShowLoanDocumentId(false)}
      />
      <FileUploadModal
        title={`Upload: ${document.documentName}`}
        open={isUploadOpen}
        onClose={() => setUploadOpen(false)}
        onUpload={(uploads: FileUpload[]) =>
          onUpload(uploads[0].file, document)
        }
      />
      <RenameModal
        open={isRenameOpen}
        onClose={() => setIsRenameOpen(false)}
        document={document}
        loanId={document.loanId}
        loanProcess={loanProcess}
      />
      <DeleteModal
        open={isDeleteOpen}
        onClose={() => setIsDeleteOpen(false)}
        document={document}
        loanId={document.loanId}
        loanProcess={loanProcess}
      />
      <MoveDocumentModal
        open={isMoveOpen}
        onClose={() => setIsMoveOpen(false)}
        document={document}
        loanId={document.loanId}
        loanProcess={loanProcess}
      />
    </>
  ) : null;
};

export const TodoTitleLink = observer(({ children, document, loanProcess }) => {
  const { globalStore } = useBaseStore();
  const { documentStore } = useDocumentStore();
  const [isUploadOpen, setUploadOpen] = useState(false);
  const classes = useStyles();
  const loanId = document.loanId;

  const onUpload = async (file, doc) => {
    await documentStore.uploadDocument(
      file,
      doc,
      loanProcess,
      null,
      document.drawId
    );
    documentStore.loanTodosStore.fetchTodos(loanId, loanProcess);
  };

  const onDownload = doc => {
    const isPDF = document.dropboxPath.endsWith('.pdf');
    if (isPDF) {
      documentStore.previewDocument(doc);
    } else {
      documentStore.downloadDocument(document);
    }
  };

  if (showOpen(document)) {
    return (
      <Link
        className={classes.link}
        onClick={() => documentStore.onOpenForm(document)}
      >
        {children}
      </Link>
    );
  } else if (showDownload(document)) {
    return (
      <Link className={classes.link} onClick={() => onDownload(document)}>
        {children}
      </Link>
    );
  } else if (document.canUploadFile && !globalStore.isInternalPortal) {
    return (
      <>
        <Link className={classes.link} onClick={() => setUploadOpen(true)}>
          {children}
        </Link>
        <FileUploadModal
          title={`Upload: ${document.documentName}`}
          open={isUploadOpen}
          onClose={() => setUploadOpen(false)}
          onUpload={(uploads: FileUpload[]) =>
            onUpload(uploads[0].file, document)
          }
        />
      </>
    );
  } else {
    return children;
  }
});

export const DownloadTodoButton = observer(({ document }) => {
  const { documentStore } = useDocumentStore();
  const classes = useStyles();

  const onDownload = () => {
    documentStore.downloadDocument(document);
  };

  if (showDownload(document)) {
    return (
      <IconButton
        onClick={onDownload}
        className={classes.downloadTodo}
        size="small"
      >
        <GetApp />
      </IconButton>
    );
  } else {
    return null;
  }
});
