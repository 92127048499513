import React from 'react';
import { observer } from 'mobx-react';
import {
  Grid,
  GridColumnProps,
} from '../../../../components/grid/grid';
import { useStore } from '../../../../hooks';

const columns: GridColumnProps[] = [
  {
    field: 'lenderName',
    headerName: 'Alias',
    minWidth: 300,
  },
  {
    field: 'companyName',
    headerName: 'Name',
    minWidth: 300,
  },
  {
    field: 'state',
    headerName: 'State',
    minWidth: 100,
  },
  {
    field: 'city',
    headerName: 'City',
    minWidth: 100,
  },
  {
    field: 'zipcode',
    headerName: 'ZipCode',
    minWidth: 100,
  },
];

export const FindLendersGrid = observer(() => {
  const { findLendersStore } = useStore();
  return (
    <Grid
      columns={columns}
      rows={findLendersStore.lenders}
      domLayout="autoHeight"
    />
  );
});
