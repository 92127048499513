import { observer } from 'mobx-react';

import {
  DataGrid, DollarColumn, SelectMode,
} from '@roc/ui';
import { Grid, Theme } from '@material-ui/core';
import { CompGridHeader } from '../header/compGridHeader';
import { CompPagination } from '../paginationBar';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { rentColumns, salesColumns } from './gridsColumns';
import { formatAbbreviatedCurrency } from '../../utils/formatter';
import { ReactComponent as GreenArrow } from '../../images/greenArrow.svg'
import { ReactComponent as RedArrow } from '../../images/redArrow.svg'
import { numberWithCommas } from '@roc/feature-utils';
import { useStore } from '@roc/client-portal-shared/hooks';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pagination: {
      justifyContent: 'flex-end',
    },

  })
);



export const CompListGrid = observer(() => {
  const classes = useStyles();
  const { compToolStore } = useStore();
  const { compResponse, subjectProperty } = compToolStore;

  const getArrowSymbol = (key, data) => {
    if (subjectProperty.data[key] && data && data[key]) {
      if (subjectProperty.data[key] > data[key]) {
        return <RedArrow />;
      } else if (subjectProperty.data[key] < data[key]) {
        return <GreenArrow />;
      }
    }
    return null;
  }

  const properties = compResponse.properties.filter(p => !p.is_subject)

  const rows = properties.map(property => {
    return {
      property_id: property.property_id,
      ...property.data,
      ...property.similarity
    };
  });


  const frameworkComponents = {
    addressCellRenderer(params) {
      const address = params.value;
      return (
        <div
          onClick={() => openIndividualPropertyModal(address)}
          style={{ textDecoration: 'underline', color: '#5c94de', cursor: 'pointer' }}>
          {address}
        </div>
      );
    },
    moneyAbbreviatedRenderer(params) {
      return (
        <div>
          {formatAbbreviatedCurrency(params.value)} {getArrowSymbol(params.column.colId, params.data)}
        </div>
      );
    },
    rentAbbreviatedRenderer(params) {
      const abbreviatedCurrency = formatAbbreviatedCurrency(
        params.value
      )
      const value = abbreviatedCurrency == 'N/A' ? abbreviatedCurrency : `${abbreviatedCurrency}/mo`
      return (
        <div>
          {value} {getArrowSymbol(params.column.colId, params.data)}
        </div>
      );
    },
    moneyRenderer(params) {
      return DollarColumn(
        params.value
      )
    },
    moneyArrowRenderer(params) {
      const value = params.value ? numberWithCommas(params.value) : '-';
      return (
        <div>
          {value} {getArrowSymbol(params.column.colId, params.data)}
        </div>
      );
    },
    distanceCellRenderer(params) {
      return params.value
        ? `${params.value.toFixed(2)} mi`
        : '-';
    },
    valueCellRenderer(params) {
      return params.value
        ? params.value
        : '-';
    },
    valueArrowCellRenderer(params) {
      const value = params.value ? params.value : '-';
      return (
        <div>
          {value} {getArrowSymbol(params.column.colId, params.data)}
        </div>
      );

    }
  };

  const rowSelected = e => {
    const { node } = e;
    const property_id = e.data.property_id
    const property = properties.find(p => p.property_id === property_id)
    if (node.isSelected()) {
      if (compToolStore.selectedCompareProperties.length < 3 && property) {
        compToolStore.addSelectCompareProperties(property)
      }
    } else {
      e.node.setSelected(false)
      compToolStore.selectedCompareProperties = compToolStore.selectedCompareProperties.filter(p => p.property_id !== property.property_id)
    }
  }

  const openIndividualPropertyModal = (address) => {
    const property = compToolStore.getCompResponse().properties.find(p => p.data.address === address);
    compToolStore.setSelectedProperty(property);
    compToolStore.setOpenPropertyDialog(true)

  }

  const gridReady = e => {
    compToolStore.setGridApi(e.api)
  }


  const topRow = compResponse.properties.filter(p => p.is_subject).map(property => {
    return {
      ...property.data,
      ...property.similarity
    };
  });

  return (
    <>
      <Grid container spacing={1}>
        <CompGridHeader />

        <Grid item xs={12} lg={12}>
          <DataGrid
            key={compToolStore.rentalComps ? 'rent' : 'sales'}
            selectMode={SelectMode.MULTIPLE}
            columns={compToolStore.rentalComps ? rentColumns : salesColumns}
            onRowSelected={e => rowSelected(e)}
            rows={rows}
            frameworkComponents={frameworkComponents}
            showFilters={false}
            domLayout="autoHeight"
            gridReady={e => gridReady(e)}
            suppressRowClickSelection={true}
            pinnedTopRowData={topRow}
          />
        </Grid>
        <Grid item xs={12} lg={12} style={{ marginBottom: compToolStore.openCompareGadget ? '250px' : '' }}>
          <CompPagination
            paginationStyles={classes.pagination}
          />
        </Grid>
      </Grid>
    </>
  )

})