import { CardHeader } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import { green, red } from '@material-ui/core/colors';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { DeleteOutline, Edit } from '@material-ui/icons';
import { IconButton, Paper } from '@roc/ui';
import React, { ReactNode } from 'react';

const BooleanChip = ({ value }) => {
  const color = value ? green : red;
  return (
    <Chip
      label={value ? 'Yes' : 'No'}
      style={{
        fontWeight: 0,
        backgroundColor: color[200],
        color: color[900],
      }}
    />
  );
};

const TextChip = ({ value }) => {
  return (
    <Chip
      label={value}
      style={{
        fontWeight: 0,
      }}
    />
  );
};

const useStyles = makeStyles(theme => ({
  paper: {
    margin: theme.spacing(3),
    marginLeft: theme.spacing(0),
    borderRadius: theme.spacing(2),
    boxShadow:
      'rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px',
  },
}));

export interface CardProps {
  testId: string | number;
  title?: string;
  onRemove?: (value: any) => void;
  onEdit?: (value: any) => void;
  largeCard: boolean;
  children: ReactNode;
}

export const CardItem = props => {
  const { label, value, component, divider = true } = props;
  return (
    <>
      {divider && <Divider variant="middle" component="li" />}
      <ListItem>
        <ListItemText>
          <Box display="flex">
            <Box flexGrow={1}>{label}</Box>
            <span />
            <Box>{component || value}</Box>
          </Box>
        </ListItemText>
      </ListItem>
    </>
  );
};

export const EntityCard = (props: CardProps) => {
  const { testId, largeCard, title, onEdit, onRemove } = props;
  const classes = useStyles();

  return (
    <>
      <Grid item xs={12}>
        <Box mt={1}>
          <Paper className={classes.paper}>
            <CardHeader
              title={title}
              action={
                <>
                  {onEdit && (
                    <IconButton testId={`edit-${testId}`} onClick={onEdit}>
                      <Edit color="primary" />
                    </IconButton>
                  )}
                  {onRemove && (
                    <IconButton testId={`remove-${testId}`} onClick={onRemove}>
                      <DeleteOutline color="primary" />
                    </IconButton>
                  )}
                </>
              }
            />
            <Grid container direction="row" spacing={4}>
              <Grid item xs={12} md={largeCard ? 6 : 12}>
                {props.children}
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </Grid>
    </>
  );
};
