import { useLoanStore } from '@roc/feature-loans';
import { observer } from 'mobx-react';
import { CustomCard, CustomCardDividers, CustomCardRow } from '../customCard';
import { Alert } from '@material-ui/lab';
import { formatCurrency } from '@roc/ui/utils';

export const BorrowerLiquidityCard = observer(() => {
  const { loanStore } = useLoanStore();
  const { loanDetails } = loanStore;

  return (
    <CustomCard title="Borrower Liquidity" paperStyle={{
      height: '100%'
    }}>
      <CustomCardDividers>
        <Alert severity="error">Please note that this is only an estimate based on numbers at the time of underwriting, this may update to different numbers once we have a closing date and the final HUD is approved.</Alert>
        <CustomCardRow
          label="Liquidity in Bank Statements"
          value={formatCurrency(loanDetails.liquidityInBankStatements)}
        />
        <CustomCardRow
          label="Total Liquidity Required"
          value={formatCurrency(loanDetails.liquidityNeededForClose)}
        />
      </CustomCardDividers>
    </CustomCard>
  );
});
