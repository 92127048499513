import { FormStore, GlobalStore } from "@roc/feature-app-core";
import { parseAddress } from '@roc/feature-utils';
import { action, makeObservable } from "mobx";

const form = {
  fields: {
    address: {
      value: '',
      error: null,
      rule: '',
    },
    latitude: {
      value: '',
      error: null,
      rule: '',
    },
    longitude: {
      value: '',
      error: null,
      rule: '',
    },
    state: {
      value: '',
      error: null,
      rule: '',
    },
    completeAddress: {
      value: '',
      error: null,
      rule: '',
    }
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export class SearchPropertyFormStore extends FormStore {
  globalStore: GlobalStore;

  constructor(globalStore) {
    super(form, globalStore);
    this.globalStore = globalStore;
    makeObservable(this, {
      saveAddressField: action
    });
  }

  saveAddressField(address: string, addressDetails?: any, geometryDetails?: any, latitude?: number, longitude?: number) {
    const parsedAddress = parseAddress(
      address,
      addressDetails,
      geometryDetails
    );

    const modifiedAddress = address.split(', USA')[0];
    this.onFieldChange('address', modifiedAddress);
    this.onFieldChange('latitude', parsedAddress.latitude ?? latitude);
    this.onFieldChange('longitude', parsedAddress.longitude ?? longitude);
    this.onFieldChange('state', parsedAddress.state);

    if (addressDetails && geometryDetails) {
      this.onFieldChange('completeAddress', modifiedAddress);
    }
  }

}