import { Box, Divider, Grid, List, ListItem, ListItemText, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import {
  CheckBoxOutlineBlank,
  Clear,
  CloudUpload,
  Warning
} from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import { useBaseStore } from '@roc/feature-app-core';
import { getPublicDocumentDownloadUrl, sanitizeTestId } from '@roc/feature-utils';
import { Button, Card, ConfirmationButton, FileUpload, FileUploadModal, IconButton, TestDataAttribute } from '@roc/ui';
import {
  createColorSelectionField,
  createTextField
} from '@roc/ui/componentBuilder';
import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import { useWhiteLabelStore } from './../hooks/useWhiteLabelStore';

export enum UploadType {
  FAV_ICON = 'FAV_ICON',
  APP_LOGO = 'APP_LOGO',
  BANNER_IMAGE = 'BANNER_IMAGE',
}

export enum WhiteLabelState {
  DOMAIN_NOT_SETUP,
  HAS_WHITE_LABEL_CONFIGURATION
}

const getModalTitle = (uploadType: UploadType) => {
  switch (uploadType) {
    case UploadType.FAV_ICON:
      return 'Fav Icon';
    case UploadType.APP_LOGO:
      return 'App Logo';
    case UploadType.BANNER_IMAGE:
      return 'Banner Image';
    default:
      return '';
  }
};

const useStyles = makeStyles(theme => ({
  card: {
    marginBottom: theme.spacing(2),
  },
  confirmationDialogBody: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const useImageBoxStyles = makeStyles(theme => ({
  image: {
    maxWidth: '80%',
    maxHeight: 150,
  },
  imageBox: {
    position: 'relative',
    width: '100%',
    minHeight: 200,
    border: '1px solid #eee',
    textAlign: 'center',
    padding: theme.spacing(2),
  },
  imageHoverBox: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: '#eee',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid black',
  }
}));

const useDialogContentStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    '& .dialog-content-box': {
      display: 'flex',
      alignItems: 'center',
    },
  },
}));

const CustomListItem = ({ label, value, valueComp, icon = null }: {
  label: string,
  value?: any,
  valueComp?: React.ReactNode,
  icon?: any
}) => {
  const testId = sanitizeTestId(label);
  return (
    <ListItem>
      <ListItemText>
        <Box display="flex" alignItems="center">
          {icon && <Box ml={1}>{icon}</Box>}
          <Box flexGrow={1}>{label}</Box>
          {
            valueComp ? valueComp : <TestDataAttribute id={testId}>
              <Box>{value}</Box>
            </TestDataAttribute>
          }
        </Box>
      </ListItemText>
    </ListItem>
  );
};

const Dividers = ({ children, ...props }) => {
  return React.Children.map(children, (child, i) => (
    <>
      {i !== 0 && child && <Divider {...props} />}
      {child}
    </>
  ));
};

const ImageBox = observer(({ url, onEditClick, onClearClick }) => {
  const classes = useImageBoxStyles();
  const [showHoverBox, setShowHoverBox] = useState(false);

  const onMouseEnter = () => {
    setShowHoverBox(true);
  };
  const onMouseLeave = () => {
    setShowHoverBox(false);
  };
  return (
    <Box
      className={classes.imageBox}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}>
      <img
        src={url}
        className={classes.image}
        style={{
          maxWidth: '80%',
          maxHeight: 150,
        }}
      />
      {
        showHoverBox && <Box className={classes.imageHoverBox}>
          <IconButton title="Upload" testId="upload" style={{ marginRight: 16 }} onClick={onEditClick} >
            <CloudUpload />
          </IconButton>
          <IconButton title="clear" testId="clear" onClick={onClearClick} >
            <Clear color="error" />
          </IconButton>
        </Box>
      }
    </Box>
  );
});

const ColorReadonlyField = observer(({ label, fieldName, store, readonly }: {
  label?: string,
  fieldName: string,
  store: any,
  readonly?: boolean,
}) => {
  let color = store.form.fields[fieldName].value;
  color = color ? color : '#FFF';
  const style = {
    background: `${color}`,
    color: `${color}`,
    marginRight: '8px',
    boxShadow: `0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%)`,
  };
  const inputRef = useRef(null);
  return (
    <Grid container spacing={1} alignItems="flex-end">
      <Grid item>
        {readonly ? (
          <CheckBoxOutlineBlank fontSize="medium" style={style} />
        ) : (
          createColorSelectionField({
            fieldName,
            store,
            inputRef,
          })
        )}
      </Grid>
      <Grid item>
        {createTextField({
          variant: 'standard',
          label,
          fieldName,
          testId: fieldName,
          store: store,
          disabled: true,
          onClick: () => {
            if (inputRef.current != null) {
              inputRef.current.querySelector('button').click();
            }
          }
        })}
      </Grid>
    </Grid>
  );
});

export const WhiteLabelConfigs = observer(() => {

  const classes = useStyles();
  const theme = useTheme();
  const [editColors, setEditColors] = useState(false);
  const [editBasicInfo, setEditBasicInfo] = useState(false);
  const dialogContentclasses = useDialogContentStyles();
  const { globalStore } = useBaseStore();
  const { whiteLabelConfigStore } = useWhiteLabelStore();
  const { basicInformationFormStore, colorsFormStore } = whiteLabelConfigStore;
  const isFileUploadOpen = Boolean(whiteLabelConfigStore.uploadType);
  const hasPreviewContent = Boolean(
    globalStore.portalConfiguration.previewPortalConfiguration
  );
  const lastUpdatedDate = globalStore.portalConfiguration.lastUpdatedDate;

  const onUpload = (fileUploads: FileUpload[], uploadType) => {
    whiteLabelConfigStore.uploadDocument(fileUploads[0], uploadType);
  };

  useEffect(() => {
    whiteLabelConfigStore.whiteLabelStore.fetchPortalConfiguration();
    whiteLabelConfigStore.populateForm();
  }, []);

  return (
    <>
      {
        hasPreviewContent ?
          <Box pt={2}>
            <Alert severity="warning">
              <strong>You have some unpublished changes. Click any of the buttons below to take appropriate action.</strong>
              <ul style={{
                margin: '8px 0 8px 18px'
              }}>
                <li>
                  Click "DISCARD CHANGES" to revert back the changes.
                </li>
                <li>
                  Click "SEE PREVIEW" to preview the saved changes.
                </li>
                <li>
                  Click "PUBLISH CHANGES" to apply the changes live.
                </li>
              </ul>
            </Alert>
          </Box> : null
      }
      <Box py={2} pr={0} display="flex" justifyContent="flex-end">
        <Box mr={1}>
          <Button
            variant="outlined"
            onClick={() => {
              whiteLabelConfigStore.discardChanges();
            }}
            color="default"
            testId="discard_changes"
            disabled={!hasPreviewContent}
          >
            Discard Changes
          </Button>
        </Box>
        <Box mr={1}>
          <Button
            variant="contained"
            onClick={() => {
              window.open(
                window.origin +
                globalStore.routeBaseUrl +
                '?previewMode=true'
              );
            }}
            color="primary"
            testId="preview_changes"
            disabled={!hasPreviewContent}
          >
            See Preview
          </Button>
        </Box>
        <Box>
          <ConfirmationButton
            variant="contained"
            onClick={() => {
              whiteLabelConfigStore.publishChanges();
            }}
            style={hasPreviewContent ? {
              backgroundColor: 'red',
              color: 'white',
            } : {}}
            testId="publish_changes"
            disabled={!hasPreviewContent}
            confirmDialogProps={{
              title: 'Publish Changes',
              dialogContentclasses: dialogContentclasses,
              body: (
                <>
                  <Warning
                    color="error"
                    fontSize="large"
                    style={{ marginRight: '16px' }}
                  />
                  Are you sure do you want to publish the changes? <br />{' '}
                  These changes will be applied to the portal immediately.
                </>
              ),
              confirmButtonText: 'Yes',
            }}
          >
            Publish Changes
          </ConfirmationButton>
        </Box>
      </Box>
      <Card title="" cardHeaderProps={{
        style: {
          padding: 0,
        }
      }} cardContentProps={{
        style: {
          padding: 0,
        }
      }} className={classes.card}>
        <List>
          <Dividers variant="middle" component="li">
            <CustomListItem label='Your Portal Address' valueComp={
              <span style={{ color: theme.palette.primary.dark }}>{globalStore.portalConfiguration.host}</span>
            } />
          </Dividers>
        </List>
      </Card>
      <Card title="BASIC INFORMATION" cardContentProps={{
        style: {
          padding: 0,
        }
      }} className={classes.card} onEdit={editBasicInfo ? undefined : () => setEditBasicInfo(true)}>
        <List>
          <Dividers variant="middle" component="li">
            <CustomListItem label='Site Name' valueComp={
              editBasicInfo ? <Box width={300}>
                {createTextField({
                  label: '',
                  fieldName: 'name',
                  testId: 'name',
                  store: basicInformationFormStore,
                })}
              </Box> : basicInformationFormStore.getValue('name')
            } />
            <CustomListItem label='From Email Address' value={basicInformationFormStore.getValue('fromEmail')} />
          </Dividers>
        </List>
        {
          editBasicInfo &&
          <Box m={2} textAlign="right">
            <Button
              style={{ marginRight: 16 }}
              variant="outlined"
              onClick={() => {
                whiteLabelConfigStore.populateForm();
                setEditBasicInfo(false);
              }}
              color="default"
              testId="cancel_changes"
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                whiteLabelConfigStore.saveChanges();
                setEditBasicInfo(false);
              }}
              color="primary"
              testId="save_changes"
            >
              Save
            </Button>
          </Box>
        }
      </Card>
      <Card title="COLOR" cardContentProps={{
        style: {
          padding: 0,
        }
      }} className={classes.card} onEdit={editColors ? undefined : () => setEditColors(true)}>
        <List>
          <Dividers variant="middle" component="li">
            <CustomListItem label='Primary Color' valueComp={
              <Box>
                <ColorReadonlyField
                  readonly={!editColors}
                  fieldName="primaryColor"
                  store={colorsFormStore}
                />
              </Box>
            } />
            <CustomListItem label='Secondary Color' valueComp={
              <Box>
                <ColorReadonlyField
                  readonly={!editColors}
                  fieldName="secondaryColor"
                  store={colorsFormStore}
                />
              </Box>
            } />
            <CustomListItem label='Text Color' valueComp={
              <Box>
                <ColorReadonlyField
                  readonly={!editColors}
                  fieldName="textColor"
                  store={colorsFormStore}
                />
              </Box>
            } />
            <CustomListItem label='Navigation Background Color' valueComp={
              <Box>
                <ColorReadonlyField
                  readonly={!editColors}
                  fieldName="appBarBackgroundColor"
                  store={colorsFormStore}
                />
              </Box>
            } />
            <CustomListItem label='Navigation Text Color' valueComp={
              <Box>
                <ColorReadonlyField
                  readonly={!editColors}
                  fieldName="appBarFontColor"
                  store={colorsFormStore}
                />
              </Box>
            } />
            <CustomListItem label='Logo Background Color' valueComp={
              <Box>
                <ColorReadonlyField
                  readonly={!editColors}
                  fieldName="logoBackgroundColor"
                  store={colorsFormStore}
                />
              </Box>
            } />
          </Dividers>
        </List>
        {
          editColors &&
          <Box m={2} textAlign="right">
            <Button
              style={{ marginRight: 16 }}
              variant="outlined"
              onClick={() => {
                whiteLabelConfigStore.populateForm();
                setEditColors(false);
              }}
              color="default"
              testId="cancel_changes"
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                whiteLabelConfigStore.saveChanges();
                setEditColors(false);
              }}
              color="primary"
              testId="save_changes"
            >
              Save
            </Button>
          </Box>
        }
      </Card>
      <Card title="LOGOS" cardContentProps={{
        style: {
          padding: 0,
        }
      }} className={classes.card}>
        <Grid container item xs={12} spacing={2} justify='space-between' style={{
          margin: 0,
          padding: 16
        }}>
          <Grid item xs={12} md={6}>
            <Box>
              <Typography gutterBottom>Fav Icon</Typography>
            </Box>
            <ImageBox url={getPublicDocumentDownloadUrl(whiteLabelConfigStore.favIconDocumentId, lastUpdatedDate)}
              onEditClick={() => whiteLabelConfigStore.setUploadType(UploadType.FAV_ICON)}
              onClearClick={() => whiteLabelConfigStore.clearDocument(UploadType.FAV_ICON)} />
          </Grid>
          <Grid item xs={12} md={6}>
            <Box>
              <Typography gutterBottom>Logo</Typography>
            </Box>
            <ImageBox url={getPublicDocumentDownloadUrl(whiteLabelConfigStore.appLogoDocumentId, lastUpdatedDate)}
              onEditClick={() => whiteLabelConfigStore.setUploadType(UploadType.APP_LOGO)}
              onClearClick={() => whiteLabelConfigStore.clearDocument(UploadType.APP_LOGO)} />
          </Grid>
        </Grid>
      </Card>
      <FileUploadModal
        title={`Upload ${getModalTitle(whiteLabelConfigStore.uploadType)}`}
        open={isFileUploadOpen}
        multiple={false}
        onClose={() => whiteLabelConfigStore.setUploadType(undefined)}
        onUpload={fileUploads =>
          onUpload(fileUploads, whiteLabelConfigStore.uploadType)
        }
      />
    </>
  );

});
