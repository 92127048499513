import { Grid, IconButton } from "@material-ui/core";
import { ARR, CDA, isNil, yesNoOptionsBoolean } from "@roc/feature-utils";
import { RadioField } from "@roc/ui";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { useDocumentStore } from "libs/feature-documents/src/documents/hooks/useDocumentStore";
import { Button } from "@roc/ui";
import EditIcon from '@material-ui/icons/Edit';

const rushedNormalOptions = [
  { label: 'Normal', value: false },
  { label: 'Rushed', value: true },
];

const CdaArrOptions = [
  { label: 'CDA', value: CDA },
  { label: 'ARR', value: ARR },
];

const CdaArrOrderSubmissions = ({ radioValue, handleChangeRadioValue, label, options, tooltip, disabled }) => {
  return (
    <Grid item xs={12}>
      <RadioField
        standaloneLabel
        tooltip={tooltip}
        label={label}
        name="loanPaymentsDecision"
        value={radioValue}
        options={options}
        onChange={handleChangeRadioValue}
        fullWidth
        row
        testId="loanPaymentsDecision"
        disabled={disabled}
      />
    </Grid>
  )
}

export const CdaArrRadioButtonsSubmission = observer(({
  secondOrder,
  isCdaOrdered,
  editOnButton = false,
  onConfirm = (v1, v2) => { } }
) => {

  const { documentStore } = useDocumentStore();
  const { documentFormStore } = documentStore;
  const { cdaAndAppraisalOrderDetailsStore } = documentFormStore;
  const { secondRadioValue, isCdaRushed } = cdaAndAppraisalOrderDetailsStore;
  const [fieldsDisabled, setFieldsDisabled] = useState(editOnButton);

  useEffect(() => {
    cdaAndAppraisalOrderDetailsStore.fetchDesktopAppraisalPreference();
    if (isCdaOrdered) {
      cdaAndAppraisalOrderDetailsStore.setRadioValue(ARR);
    }
  }, [cdaAndAppraisalOrderDetailsStore])


  const handleChangeRadioValue = (value: string) => {
    cdaAndAppraisalOrderDetailsStore.setRadioValue(value);
  };

  const handleChangeSecondRadioValue = (value: string) => {
    cdaAndAppraisalOrderDetailsStore.setSecondRadioValue(value === 'true');
  };

  const handleChangeCDARushed = (value: string) => {
    cdaAndAppraisalOrderDetailsStore.setIsCdaRushed(value === 'true');
  };

  const showRushedOptions = () => {
    if (secondOrder) {
      return cdaAndAppraisalOrderDetailsStore.radioValue === CDA && secondRadioValue;
    }
    return cdaAndAppraisalOrderDetailsStore.radioValue === CDA
  }

  const handleConfirm = () => {
    setFieldsDisabled(true);
    onConfirm(secondOrder ? secondRadioValue : cdaAndAppraisalOrderDetailsStore.radioValue, isCdaRushed);
  }

  return (
    <Grid container xs={11} spacing={2} style={editOnButton ? ({ border: '1px solid #e1e1e1', marginBottom: '24px', padding: '12px' }) : {}}>
      <Grid item xs={8} container>
        {secondOrder ?
          <CdaArrOrderSubmissions
            radioValue={secondRadioValue}
            handleChangeRadioValue={handleChangeSecondRadioValue}
            label={`Do you want to submit ${isCdaOrdered ? 'an ARR' : 'a CDA'} order?`}
            options={yesNoOptionsBoolean}
            tooltip={"Due to deviation percentage being above the limit, a second order is in place to submit."}
            disabled={fieldsDisabled}
          />
          : <CdaArrOrderSubmissions
            radioValue={cdaAndAppraisalOrderDetailsStore.radioValue}
            handleChangeRadioValue={handleChangeRadioValue}
            label='What kind of order do you want to submit?'
            options={CdaArrOptions}
            tooltip={[
              'The CDA price is $90 and the average turnaround time is 2 business days.',
              'The RUSH CDA price is $120 and the average turnaround time is 1 business day.',
              'The ARR price is $120 and the average turnaround time is 3 business days.',
            ].join('\n')}
            disabled={fieldsDisabled}
          />
        }
        {showRushedOptions() && (
          <Grid item xs={12}>
            <RadioField
              standaloneLabel
              label="What kind of CDA order would you like to submit?"
              name="loanPaymentRushedDecision"
              value={isCdaRushed}
              options={rushedNormalOptions}
              onChange={handleChangeCDARushed}
              fullWidth
              row
              testId="loanPaymentsDecision"
              tooltip={[
                'The CDA price is $90 and the average turnaround time is 2 business days.',
                'The RUSH CDA price is $120 and the average turnaround time is 1 business day.',
              ].join('\n')}
              disabled={fieldsDisabled}
            />
          </Grid>
        )}
      </Grid>
      {editOnButton && (
        <Grid item xs={4} container>
          {fieldsDisabled && (
            <Grid item xs={12} style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-end' }}>
              <IconButton onClick={() => setFieldsDisabled(false)}>
                <EditIcon />
              </IconButton>
            </Grid>
          )}
          {!fieldsDisabled && (
            <Grid item xs={12} style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => { setFieldsDisabled(true) }}
                testId="edit"
                style={{ marginRight: '8px' }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleConfirm}
                testId="edit"
              >
                Confirm
              </Button>
            </Grid>
          )}
        </Grid>
      )}
    </Grid>
  )
})