import { GridColumnProps } from "@roc/ui";
import { ReveneuRecognitionModules } from "../types/types";
import { MONTHLY_TRADE_MODULE_COLUMNS } from "./constants";

export const getRevenueRecognitionColumns = (moduleType: ReveneuRecognitionModules): GridColumnProps[] => {
  switch (moduleType) {
    case ReveneuRecognitionModules.MONTHLY_TRADE_MODULE:
      return MONTHLY_TRADE_MODULE_COLUMNS
    default:
      return null;
  }
}

export const getFirstDayOfCurrentMonth = (): string => {
  const currentDate = new Date();
  const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
  const formattedDate = firstDayOfMonth.toLocaleDateString("en-US", { year: "numeric", month: "2-digit", day: "2-digit" });
  return formattedDate;
}
