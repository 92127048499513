import { Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import React from 'react';

interface GreeterProps {
  firstName: string;
  lastName: string;
  style?: CSSProperties;
}

export const Greeter = (props: GreeterProps) => {
  const {
    firstName,
    lastName,
    style
  } = props;

  const { palette } = useTheme();

  return (
    <Typography variant="h4" gutterBottom style={style}>
      Welcome back, <span style={{ color: palette.primary.main }}>{`${firstName} ${lastName}`}!</span>
    </Typography>
  );
};
