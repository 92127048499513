import { formatCurrency } from '@roc/ui/utils';
import { observer } from 'mobx-react';
import { PaperList } from '../../components/rentalPricerComponents';

export const LoanValuesTable = observer(({ store }) => {
  const { pricerLoanTerms } = store.pricerSummaryStore;
  const showPlaceholder = !store.pricerSummaryStore.isPricerSummaryAvailable;

  return (
    <PaperList
      items={[
        {
          label: 'Loan Amount',
          value: showPlaceholder ? '-' : formatCurrency(pricerLoanTerms.amount),
        },
        {
          label: 'Spread Charged To borrower',
          value: showPlaceholder
            ? '-'
            : `${pricerLoanTerms.spreadRate}%`,
        },
        {
          label: 'Monthly Payment Including Expenses',
          value: showPlaceholder
            ? '-'
            : `${formatCurrency(pricerLoanTerms.monthlyPayment, 3)}`,
        },
      ]}
    />
  );
});
