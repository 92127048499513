
/* const getContextRoutePath = contextPath => {
  return contextPath ? '/:context' : '';
}; */

export const useAppRoutes = () => {
  /* let { contextPath } = useAppConfigurations();
  const contextRoutePath = getContextRoutePath(contextPath);
  contextPath = contextPath ?? ''; */

  const contextPath = '';
  const contextRoutePath = '';

  const publicRoutes = {
    root: {
      path: `${contextRoutePath}`,
      url: `${contextPath}`,
    },
    privacyPoicy: {
      path: `${contextRoutePath}/privacy-policy`,
      url: `${contextPath}/privacy-policy`,
      documentTitle: 'Privacy Policy',
    },
    cookiePoicy: {
      path: `${contextRoutePath}/cookie-policy`,
      url: `${contextPath}/cookie-policy`,
      documentTitle: 'Cookie Policy',
    },
    termsAndConditions: {
      path: `${contextRoutePath}/terms-and-conditions`,
      url: `${contextPath}/terms-and-conditions`,
      documentTitle: 'Terms & Conditions',
    },
    unsubscribe: {
      path: `${contextRoutePath}/unsubscribe/:token`,
      url: `${contextPath}/unsubscribe`,
      documentTitle: 'Unsubscribe',
    },
    unsubscribeFromMonthlyReminders: {
      path: `${contextRoutePath}/unsubscribeFromMonthlyReminders/:userId`,
      url: `${contextPath}/unsubscribeFromMonthlyReminders`,
      documentTitle: 'Unsubscribe From Monthly Reminders',
    },
    login: {
      path: `${contextRoutePath}/login`,
      url: `${contextPath}/login`,
      documentTitle: 'Login',
    },
    twoFactorAuthentication: {
      path: `${contextRoutePath}/2fa`,
      url: `${contextPath}/2fa`,
      documentTitle: 'Two-Factor Authentication',
    },
    logout: {
      path: `${contextRoutePath}/logout`,
      url: `${contextPath}/logout`,
      documentTitle: 'Logout',
    },
    // register: {
    //   path: `${contextRoutePath}/register`,
    //   url: `${contextPath}/register`,
    //   documentTitle: 'Register',
    // },
    successRegistration: {
      path: `${contextRoutePath}/success-registration`,
      url: `${contextPath}/success-registration`,
      documentTitle: 'Registration Successful',
    },
    forgotpassword: {
      path: `${contextRoutePath}/forgotpassword`,
      url: `${contextPath}/forgotpassword`,
      documentTitle: 'Forgot Password',
    },
    updatepassword: {
      path: `${contextRoutePath}/updatepassword/:token`,
      documentTitle: 'Update Password',
    },
    successupdatepassword: {
      path: `${contextRoutePath}/successupdatepassword`,
      url: `${contextPath}/successupdatepassword`,
      documentTitle: 'Update Password Successful',
    },
    createpassword: {
      path: `${contextRoutePath}/createpassword/:token`,
      documentTitle: 'Create Password',
    },
    createpasswordMigratedUser: {
      path: `${contextRoutePath}/createpassword-migrateduser/:token`,
      documentTitle: 'Create Password',
    },
    successcreatepassword: {
      path: `${contextRoutePath}/successcreatepassword`,
      url: `${contextPath}/successcreatepassword`,
      documentTitle: 'Create Password Successful',
    },
    successforgotpasswordemail: {
      path: `${contextRoutePath}/successforgotpasswordemail`,
      url: `${contextPath}/successforgotpasswordemail`,
      documentTitle: 'Forgot Password Email Successful',
    },
    loanTerms: {
      path: `${contextRoutePath}/loan-terms/:encodedParams`,
      url: `${contextPath}/loan-terms/:encodedParams`,
      documentTitle: 'Loan Terms & Payment',
    },
    loanTermsDocusignCallback: {
      path: `${contextRoutePath}/loan-terms-docusign-callback`,
      url: `${contextPath}/loan-terms-docusign-callback`,
      documentTitle: 'Loan Terms & Payment Callback',
    },
    tamariskPayments: {
      path: `${contextRoutePath}/tamarisk-payments/:encodedParams`,
      url: `${contextPath}/tamarisk-payments/:encodedParams`,
    },
    tamariskDocumentDocusignCallback: {
      path: `${contextRoutePath}/tamarisk-document-docusign-callback`,
      url: `${contextPath}/tamarisk-document-docusign-callback`,
    },
    loanFastrackPayments: {
      path: `${contextRoutePath}/loan-fastrack-payments/:encodedParams`,
      url: `${contextPath}/loan-fastrack-payments/:encodedParams`,
    },
    loanFastrackDocumentDocusignCallback: {
      path: `${contextRoutePath}/loan-fast-track-document-docusign-callback`,
      url: `${contextPath}/loan-fast-track-document-docusign-callback`,
    },
    creditAuthorization: {
      path: `${contextRoutePath}/credit-authorization`,
      documentTitle: 'Credit Authorization Form',
    },
    creditAuthorizationDocusignCallback: {
      path: `${contextRoutePath}/credit-authorization-docusign-callback`,
      documentTitle: 'Credit Authorization Form Callback',
    },
    wireAuthorization: {
      path: `${contextRoutePath}/wire-authorization/:uuid`,
      documentTitle: 'Wire Authorization Form',
    },
    mobileTruliooVerification: {
      path: `${contextRoutePath}/mobile-trulioo-verification`,
      documentTitle: 'Trulioo Verfication',
    },
    wireAuthorizationDocusignCallback: {
      path: `${contextRoutePath}/wire-authorization-docusign-callback`,
      documentTitle: 'Wire Authorization Form Callback',
    },
    mersOnboardingDocusignCallback: {
      path: `${contextRoutePath}/mers-onboarding-docusign-callback`,
      documentTitle: 'Mers Onboarding Form Callback'
    },
    scopeOfWorkV2: {
      path: `${contextRoutePath}/scope-of-work-v2/:encodedParams`,
      documentTitle: 'Scope of Work',
    },
    createScopeOfWork: {
      path: `${contextRoutePath}/create-scope-of-work/:encodedParams`,
      documentTitle: 'Create Scope of Work',
    },
    createScopeOfWorkV2: {
      path: `${contextRoutePath}/create-scope-of-work`,
      documentTitle: 'Create Scope of Work',
    },
    drawRequest: {
      path: `${contextRoutePath}/draw-request/:encodedParams`,
      documentTitle: 'Draw Request',
    },
    denialOfCredit: {
      path: `${contextRoutePath}/denial-of-credit/:encodedParams`,
      documentTitle: 'Denial Of Credit',
    },
    plaid: {
      path: `${contextRoutePath}/plaid/:encodedParams`,
      documentTitle: 'Plaid Registration',
    },
    docusignCallback: {
      path: `${contextRoutePath}/docusign/docusign-callback`,
      documentTitle: 'Docusign Callback',
    },
    plaidLiquidityVerification: {
      path: `${contextRoutePath}/liquidity-verification/:id`,
      documentTitle: 'Account Linking Via Plaid',
    },
    scopeOfWorkRevision: {
      path: `${contextRoutePath}/scope-of-work-revision/:encodedParams`,
      documentTitle: 'Scope of Work Revision',
    },
    manageEmailSubscriptions: {
      path: `${contextRoutePath}/manage-subscriptions/:encodedParams`,
      documentTitle: 'Manage Subscriptions',
    },
    borrowerDetailsVerification: {
      path: `${contextRoutePath}/borrower-details-verification/:uuid`,
      documentTitle: 'Borrower Details Verification Form',
    },
    borrowerDetailsVerificationDocusignCallback: {
      path: `${contextRoutePath}/borrower-details-verification-docusign-callback/:uuid`,
      documentTitle: 'Borrower Details Verification Form Callback',
    },
    unsubscribedFromDailyReminders: {
      path: `${contextRoutePath}/unsubscribed-from-daily-reminders/:uuid`,
      documentTitle: 'Unsubscribed From Daily Reminders',
    },
    loanApplication: {
      path: `${contextRoutePath}/loan-application`,
      url: `${contextRoutePath}/loan-application`,
      documentTitle: 'Loan Application',
      children: {
        fixFlip: {
          path: `${contextRoutePath}/loan-application/fix-flip`,
          url: `${contextRoutePath}/loan-application/fix-flip`,
          documentTitle: 'New Fix & Flip Loan',
        },
        // groundUp: {
        //   path: `${contextRoutePath}/loan-application/ground-up`,
        //   url: `${contextRoutePath}/loan-application/ground-up`,
        //   documentTitle: 'New Ground Up Loan ',
        // },
        singleProperty: {
          path: `${contextRoutePath}/loan-application/single-property`,
          url: `${contextRoutePath}/loan-application/single-property`,
          documentTitle: 'New Single Property Loan ',
        },
      }
    },
    applyForALoan: {
      path: `${contextRoutePath}/apply-for-a-loan`,
      url: `${contextRoutePath}/apply-for-a-loan`,
      documentTitle: 'Apply for a Loan',
      children: {
        fixFlip: {
          path: `${contextRoutePath}/apply-for-a-loan/fix-flip`,
          url: `${contextRoutePath}/apply-for-a-loan/fix-flip`,
          documentTitle: 'New Fix & Flip Loan',
        },
        groundUp: {
          path: `${contextRoutePath}/apply-for-a-loan/ground-up`,
          url: `${contextRoutePath}/apply-for-a-loan/ground-up`,
          documentTitle: 'New Ground Up Loan',
        },
        singleProperty: {
          path: `${contextRoutePath}/apply-for-a-loan/single-property`,
          url: `${contextRoutePath}/apply-for-a-loan/single-property`,
          documentTitle: 'New Single Property Loan',
        },
      }
    },
    cdaPayments: {
      path: `${contextRoutePath}/cda-payments/:encodedParams`,
      url: `${contextPath}/cda-payments/:encodedParams`,
    },
    arrPayments: {
      path: `${contextRoutePath}/arr-payments/:encodedParams`,
      url: `${contextPath}/arr-payments/:encodedParams`,
    },
    appraisal1004dPayments: {
      path: `${contextRoutePath}/appraisal-1004d-payments/:encodedParams`,
      url: `${contextPath}/appraisal-1004d-payments/:encodedParams`,
    },
    achFormDocusignCallback: {
      path: `${contextRoutePath}/ach/docusign`,
      documentTitle: 'ACH Form',
    },
    achForm: {
      path: `${contextRoutePath}/ach/:encodedParams`,
      documentTitle: 'ACH Form',
    },
    referralFeeAuthDocusign: {
      path: `${contextRoutePath}/referral-fee/docusign`,
      documentTitle: 'Referral Fee Auth',
    },
    referralFeeAuth: {
      path: `${contextRoutePath}/referral-fee/:encodedParams`,
      documentTitle: 'Referral Fee',
    }
  };

  return {
    contextPath,
    publicRoutes,
  };
};
