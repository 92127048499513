import { Grid } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import { ForeignNationalOptions } from 'libs/client-portal-shared/src/app/modules/internalPricer/utils/internalPricerConstants';
import { isNil } from '@roc/feature-utils';

const useStyles = makeStyles(theme => ({
  alertWrapper: {
    width: '100%',
    marginBottom: '16px'
  },
}));

export const BorrowerSetupErrors = observer((props) => {

  const classes = useStyles();
  const { store } = props;
  const { currentBorrower } = store;

  const foreignNationalPGNoSSN = currentBorrower?.citizenshipStatus === ForeignNationalOptions.FOREIGN_NATIONAL && currentBorrower?.runCreditOnCreation === true && (isNil(currentBorrower?.socialSecurityNumber) || currentBorrower?.socialSecurityNumber === '');

  const newBorrower = (isNil(currentBorrower?.dob) || (currentBorrower?.dob == '')) && (isNil(currentBorrower?.socialSecurityNumber) || (currentBorrower?.socialSecurityNumber == ''));

  const sendIdVerification = !isNil(currentBorrower?.sendIdVerification) ? currentBorrower?.sendIdVerification : currentBorrower?.creditBackgroundCheck;

  const showCreditError = currentBorrower?.creditExpired || currentBorrower?.reissueFailed || currentBorrower?.creditPending || currentBorrower?.areCreditScoresFrozen || foreignNationalPGNoSSN || newBorrower;

  return (
    <>
      {!isNil(currentBorrower) && showCreditError &&
        <Grid item xs={12}>
          <Alert severity="warning" className={classes.alertWrapper}>
            {/* {'We ran into an error while verifying the borrower\'s identity. Please provide a new ID Document to proceed.'} */}
            {currentBorrower?.areCreditScoresFrozen ?
              `We were unable to pull the credit report because two or more credit scores are frozen. ${!currentBorrower?.cafSignedDate ? 'Please provide a new report to proceed.' : ''}`
              : currentBorrower?.reissueFailed ? 'We ran into an error pulling the report, please provide file number and report again to continue.'
                : currentBorrower?.creditExpired ?
                  'The credit report has expired. Please send the authorization form to the borrower so we can pull fresh report or provide your own new report'
                  : foreignNationalPGNoSSN ?
                    'Foreign national with no SSN cannot be a Personal Guarantor. Please go to the first step and unmark this borrower as a Personal Guarantor.'
                    : newBorrower ?
                      sendIdVerification ? 'Please inform the borrower to fill out the form sent, so the reports can be pulled.' :
                        'Please complete the borrower information so we can pull the reports.'
                      : 'Borrower Information is still missing - Please fill out the borrower information or choose the option for us to send an authorization form on your behalf.'
            }
          </Alert>
        </ Grid>
      }
    </>
  );
});