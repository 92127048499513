import { Grid, Typography } from "@material-ui/core";
import { Button, TextField, Tooltip } from "@roc/ui";
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Help } from "@material-ui/icons";
import { useCreateListStore } from "../../hooks/useCreateListStore";
import { observer } from "mobx-react";



const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textAreaContainer: {
      paddingTop: theme.spacing(2),
    },
    tooltipContainer: {
      display: 'flex',
      flexDirection: 'row',
    },
    buttonContainer: {
      paddingTop: theme.spacing(2),
    }
  })
);

export const LogicFilterModalContent = observer(({ onSave }) => {
  const classes = useStyles();
  const { createListStore } = useCreateListStore();
  return (
    <Grid container>
      <Grid item container justifyContent="flex-start" alignItems="center">
        <Grid item className={classes.tooltipContainer}>
          <Typography variant="h6">Add Filter Logic</Typography><Tooltip title={'Edit Filter logic governs how and when filters apply to your list view. Use AND and OR operators to fine-tune your results.'} placement="top" arrow><Help color="secondary" /></Tooltip>
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.textAreaContainer}>
        <TextField
          testId="listName"
          required
          type="text"
          variant="outlined"
          multiline
          minRows={4}
          value={createListStore.filterLogicInputValue}
          onChange={e => createListStore.filterLogicInputValue = e.target.value}
          fullWidth
        />
      </Grid>
      <Grid item container justifyContent="flex-end" className={classes.buttonContainer}>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={onSave}
            testId="hideFilterLogic"
          >Save</Button>
        </Grid>
      </Grid>
    </Grid>
  )
});