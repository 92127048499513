import { Box } from '@material-ui/core';
import { useBaseStore, useMiscellaneousRoutes } from '@roc/feature-app-core';
import { ChatRoomType, FilterType, useCommunicationRoutesConfig, useLoanCommunicationStore } from '@roc/feature-communication';
import { LoanProcess, insertIf } from '@roc/feature-utils';
import { BreadcrumbsNew } from '@roc/ui';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { LoanDetailsHeader } from './components/loanDetailsHeader';
import { LoanDetailsTabs } from './components/loanDetailsTabs';
import { useBorrowerLoanApplicationsStore } from '../../hooks/useBorrowerLoanApplicationsStore';
import { BorrowerLoanApplicationDetailsChildRoutes } from './components/borrowerLoanApplicationDetailsChildRoutes';
import { useBorrowerLoanApplicationsRoutes } from '../../hooks/useBorrowerLoanApplicationsRoutes';
import { BorrowerLoanDetailsFAB } from './components/borrowerLoanDetailsFAB';
import { useDocumentStore } from '@roc/feature-documents';

export const BorrowerLoanApplicationDetailsV2 = observer(() => {
  const { loanId } = useParams<{ loanId: string }>();
  const [selectedTab, setSelectedTab] = useState(0);
  const [doesUserHasAnyConversations, setDoesUserHasAnyConversations] = useState<boolean>(false);
  const { borrowerLoanApplicationsStore } = useBorrowerLoanApplicationsStore();
  const { borrowerLoanApplicationRoutesConfig: borrowerLoanRoutesConfig } = useBorrowerLoanApplicationsRoutes();
  const { communicationRoutesConfig } = useCommunicationRoutesConfig();
  const { loanCommunicationStore } = useLoanCommunicationStore();
  const { miscellaneousRoutesConfig } = useMiscellaneousRoutes();
  const { push } = useHistory();
  const { pathname } = useLocation();
  const {
    loanDetails,
  } = borrowerLoanApplicationsStore;
  const { globalStore } = useBaseStore();
  const { documentStore } = useDocumentStore();
  const { breadCrumbsStore: { breadCrumbItems } } = globalStore;
  const [conversationSidToUse, setConversationSidToUse] = useState<string>();

  const tabs = [
    {
      label: 'Loan Summary',
      url: borrowerLoanRoutesConfig.loans(loanId).children.dashboard.url
    },
    ...insertIf(loanDetails?.toDosFrameworkEnabled, [
      {
        label: 'To-dos',
        url: borrowerLoanRoutesConfig.loans(loanId).children.borrowerTodos.url,
        badgeCount: documentStore.loanTodosStore.pendingTodosCountByLoanProcess[LoanProcess.UNDERWRITING],
      }
    ]),
  ];

  useEffect(() => {
    if (borrowerLoanApplicationsStore.isLoanDetailsSuccess === false) {
      push(miscellaneousRoutesConfig.notFound.url);
      borrowerLoanApplicationsStore.setLoanDetailsSuccess(null);
    }
  }, [borrowerLoanApplicationsStore.isLoanDetailsSuccess]);

  useEffect(() => {
    tabs.forEach((t, i) => {
      if (pathname.indexOf(t.url) !== -1 || t.childRoutes?.some?.(url => pathname.indexOf(url) !== -1)) {
        setSelectedTab(i);
      }
    });
  }, [tabs, pathname]);

  useEffect(() => {
    borrowerLoanApplicationsStore.fetchLoanApplicationDetails(loanId);
    documentStore.loanTodosStore.fetchTodos(Number(loanId), LoanProcess.UNDERWRITING);
  }, [loanId]);

  useEffect(() => {
    if (loanDetails?.loanId) {
      loanCommunicationStore.fetchUserConversations([{
        type: FilterType.LOAN_ID,
        value: loanDetails.loanId,
      }]).then(x => {
        if (x?.totalCount) {
          const y = x.conversationsData?.filter(cData => {
            return cData.chatRoomType == ChatRoomType.NEW_LOAN_REQUEST
          })[0];
          if (y?.conversationSid) {
            setConversationSidToUse(y.conversationSid);
            setDoesUserHasAnyConversations(true);
          }
        }
      });
      loanCommunicationStore.fetchUnreadMessagesCount(loanId);
    }
  }, [loanDetails?.loanId]);

  if (!loanDetails) {
    return null;
  }

  const onTabChange = (e, index) => {
    push(tabs[index].url);
    setSelectedTab(index);
  }

  const handleOpenConversationsDialog = () => {
    if (conversationSidToUse) {
      push(communicationRoutesConfig.loanApplicationConversations(loanId).children.conversation(conversationSidToUse).url, { from: pathname });
    } else {
      push(communicationRoutesConfig.loanApplicationConversations(loanId).url, { from: pathname });
    }
  }

  return (
    <Box my={3}>
      <Box display="flex" flexDirection="column" mb={3}>
        <Box mb={1.5}>
          <BreadcrumbsNew breadCrumbItems={breadCrumbItems} separator={<span>&#187;</span>} />
        </Box>
        <Box mb={2}>
          <LoanDetailsHeader loanDetails={loanDetails} overviewUrl={borrowerLoanRoutesConfig.loans(loanDetails?.loanId).children.dashboard.url} />
        </Box>
        <Box maxWidth={'100%'}>
          <LoanDetailsTabs tabs={tabs} tabIndex={selectedTab} onTabChange={onTabChange} />
        </Box>
      </Box>
      <Box display="flex">
        <Box flexGrow={1}>
          <BorrowerLoanApplicationDetailsChildRoutes isNew={true} />
        </Box>
      </Box>
      <BorrowerLoanDetailsFAB
        showConversations={doesUserHasAnyConversations}
        loanCommunicationStore={loanCommunicationStore}
        onOpenConversations={handleOpenConversationsDialog} />
    </Box>
  );
});
