import { Button, CardMedia, Grid, Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useStore } from '@roc/client-portal-shared/hooks';
import consentImage from '../../assets/consumer_consent_icon.svg';
import { FindInPage } from '@material-ui/icons';
import { orange } from '@material-ui/core/colors';
import { isNil } from '@roc/feature-utils';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    maxWidth: '600px',
    padding: theme.spacing(2),
    textAlign: 'center',
  },
  subtitle: {
    color: 'gray',
    '& a': {
      textDecoration: 'underline',
      color: 'gray',
    },
  },
  title: {
    fontWeight: 'bold',
  },
  button: {
    width: '92%',
    height: '2.8rem'
  },
  img: {
    width: '100%',
    maxHeight: '50%',
    objectFit: 'contain',
  },
  verificationIframe: {
    border: 0,
    height: '650px',
    width: '100%',
  },
}));

export const IdVerification = observer(({ store }) => {

  const classes = useStyles();
  const { globalStore } = useStore();

  const [componentToDisplay, setComponentToDisplay] = useState(isNil(store.borrowerDetailsVerification?.savedForm?.borrowerIdentityVerificationId) ? 'CONSENT' : 'VIEW');

  const portalName = globalStore.portalConfiguration?.portalConfiguration?.generalConfiguration
    ?.name || '';

  const handleContinueClick = () => {
    setComponentToDisplay('VERIFICATION');
  };

  const handleNextClick = () => {
    store.saveBorrowerIdentityVerificationId();
    store.goNextStep();
  };

  const verificationCompleteHandler = useCallback((sessionId) => {
    setComponentToDisplay('VIEW');
  }, [store]);

  return (
    <>
      {componentToDisplay === 'CONSENT' &&
        <Box className={classes.container}>
          <div className={classes.paper} style={{ height: '400px' }}>
            <CardMedia
              className={classes.img}
              component="img"
              alt="Identity verification"
              image={consentImage}
            />
            <Typography variant="h6" className={`${classes.title} title-text`}>
              Let's verify your identity
            </Typography>
            <Typography paragraph className={classes.subtitle}>
              By selecting “Continue” you agree to the {portalName}{' '}
              <Link
                href={`https://${window.location.host}/privacy-policy`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span style={{ textDecoration: 'underline', color: 'gray' }}>
                  End User Privacy Policy
                </span>
              </Link>
            </Typography>
            <Button
              type="button"
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={handleContinueClick}
            >
              Continue
            </Button>
          </div>
        </Box>
      }
      {componentToDisplay === 'VERIFICATION' &&
        <Grid container>
          <Grid item xs={12} container >
            <IdDocumentVerification secureMeLink={store.borrowerIdvRequestResponse.au10TixRequestDetailsResponse?.webUrl} onVerificationComplete={verificationCompleteHandler} />
          </Grid>
        </Grid>
      }
      {componentToDisplay === 'VIEW' && (
        <Box className={classes.container}>
          <div className={classes.paper} style={{ height: '400px' }}>
            <FindInPage style={{ fontSize: 60, color: orange[500] }} />
            <br />
            <Typography variant="h4" color="textPrimary">
              In Review
            </Typography>
            <br />
            <Typography>Your document has been received for verification.</Typography>
            <br />
            <Typography>
              You can proceed to the next steps.
            </Typography>
            <br />
            <Button
              type="button"
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={handleNextClick}
            >
              Next
            </Button>
          </div>
        </Box>
      )
      }
    </>
  )
});

export const IdDocumentVerification = ({ secureMeLink, onVerificationComplete }) => {

  const classes = useStyles();

  useEffect(() => {
    const { hostname, protocol } = new URL(secureMeLink);
    const origin = `${protocol}//${hostname}`;

    function receiveMessage(event) {
      if (event.origin === origin) {
        if (event.data.payload.value === '/success') {
          setTimeout(() => {
            onVerificationComplete(event?.data?.id);
          }, 3000);
        }
      }
    }
    window.addEventListener("message", receiveMessage, false);

    return () => window.removeEventListener("message", receiveMessage);
  }, [onVerificationComplete, secureMeLink]);

  return (
    <iframe allow="geolocation;camera; " id="verificationIframe" title="verificationIframe" className={classes.verificationIframe} src={secureMeLink} />
  )
};