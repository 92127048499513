import { action, flow, makeObservable, observable } from 'mobx';
import { GlobalStore, UserStore, UserService, WhiteLabelStore } from '@roc/feature-app-core';
import { isNil, selectedCompanyStorage } from '@roc/feature-utils';
import { CompanyProfileStore } from 'libs/client-portal-shared/src/app/modules/companyProfile/stores/companyProfileStore';



import { LenderService } from '../services/lenderService';
export class CompanyDropdownStore {
  protected globalStore: GlobalStore;
  protected userStore: UserStore;
  protected userService: UserService;
  protected companyProfileStore: CompanyProfileStore;
  protected lenderService: LenderService;
  protected whiteLabelStore: WhiteLabelStore;

  open = false;
  companies = [];
  company = null;
  companyId = null;
  showWrongCompanyBanner = false;

  constructor(globalStore, userStore, whiteLabelStore, companyProfileStore) {
    this.globalStore = globalStore;
    this.userStore = userStore;
    this.whiteLabelStore = whiteLabelStore;
    this.userService = new UserService();
    this.companyProfileStore = companyProfileStore;

    this.lenderService = new LenderService();
    makeObservable(this, {
      open: observable,
      companies: observable,
      company: observable,
      companyId: observable,
      showWrongCompanyBanner: observable,
      getUserCompanies: flow,
      changeCompany: flow,
      checkCompanyAndRedirectUser: flow,
      changeCompanyNavigation: flow,
      setShowWrongCompanyBanner: action,
    });
  }

  private openDialog = () => {
    this.open = true;
  };

  handleInitialSelectedCompanyId = (companies) => {
    let actualCompanyId = selectedCompanyStorage.getSelectedCompanyIdFromStorage();
    let actualCompany = companies.find(company => company.value == actualCompanyId);
    if (isNil(actualCompany) && companies.length > 0) {
      actualCompanyId = companies[0]?.value;
      selectedCompanyStorage.setSelectedCompanyIdToStorage(actualCompany);
    }
    return actualCompanyId;
  }

  *getUserCompanies() {
    this.openDialog();
    this.companies = this.globalStore.userCompanies.map(company => {
      return { label: company.lenderName, value: company.companyId, company: company.name }
    });
    let actualCompany = this.handleInitialSelectedCompanyId(this.companies);
    this.company = this.companies.find(company => company.value == actualCompany) || null;
    this.companyId = actualCompany;
    this.globalStore.setSelectedCompanyId(Number(actualCompany));
  }

  *changeCompany(value) {
    this.companyId = value;
    this.globalStore.setSelectedCompanyId(this.companyId);
    selectedCompanyStorage.setSelectedCompanyIdToStorage(this.companyId);
    yield this.userStore.getUserInfoAndEntitlements();
    this.companyProfileStore.salesforceLenderStore.salesForceLender = null;
    yield this.userStore.updatePortalConfiguration();
    yield this.companyProfileStore.salesforceLenderStore.fetchSalesforceLender();
  }

  *changeCompanyNavigation(value) {
    this.company = this.companies.find(company => company.value == value)?.label;
    this.companyId = value;
    this.globalStore.setSelectedCompanyId(this.companyId);
    selectedCompanyStorage.setSelectedCompanyIdToStorage(this.companyId);
    yield this.userStore.getUserInfoAndEntitlements();
    this.companyProfileStore.salesforceLenderStore.salesForceLender = null;
    yield this.companyProfileStore.salesforceLenderStore.fetchSalesforceLender();
    yield this.userStore.updatePortalConfiguration();
    window.location.reload();
  }

  *checkCompanyAndRedirectUser() {
    this.open = false;
  }

  setShowWrongCompanyBanner(value) {
    this.showWrongCompanyBanner = value;
  }

}