import { Service } from '@roc/client-portal-shared/services';

const url = {
  GET_TRULIOO_SHORT_CODE: '/api/v1/user/public/getTruliooShortCode',
};

export class WireAuthorizationServiceV2 extends Service {
  getTruliooShortCode() {
    return this.post(`${url.GET_TRULIOO_SHORT_CODE}`,null);
  }
}