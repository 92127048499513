const mapper = {
  'Above Ground Structure': {
    helperText: 'Please provide the cost and details of the following items',
    sections: [
      {
        title: 'Floor Structure',
        tooltip: 'Floors, Ramps, Decks, Slabs, Toppings',
        fields: [
          {
            label: 'Amount',
            name: 'floorStructureAmount',
          },
        ],
      },
      {
        title: 'Roof Structure',
        tooltip: 'Roofs, Sheating',
        fields: [
          {
            label: 'Amount',
            name: 'roofStructureAmount',
          },
        ],
      },
      {
        title: 'Stairs Structure',
        tooltip: 'Stringers, Soffits, Railings',
        fields: [
          {
            label: 'Amount',
            name: 'stairsStructureAmount',
          },
        ],
      },
      {
        title: 'Other',
        tooltip: 'Any other relevant items related to this category',
        fields: [
          {
            label: 'Amount',
            name: 'otherAmount',
          },
        ],
      },
    ],
  },
  'Exterior Walls, Windows, Doors, Awnings, Gates': {
    helperText: 'Please provide the cost and details of the following items',
    sections: [
      {
        title: 'Exterior Walls',
        fields: [
          {
            label: 'Framing + Lintels',
            name: 'framingLintels',
          },
          {
            label: 'CMU',
            name: 'cmu',
          },
          {
            label: 'Siding / Stucco',
            name: 'siding',
          },
          {
            label: 'Weather Proofing',
            name: 'weatherProofing',
          },
          {
            label: 'Insulation',
            name: 'insulation',
          },
          {
            label: 'Wall Board',
            name: 'wallBoard',
          },
          {
            label: 'Painting',
            name: 'painting',
          },
        ],
      },
      {
        title: 'Exterior Windows',
        fields: [
          {
            label: 'Amount',
            name: 'exteriorWindowsAmount',
          },
        ],
      },
      {
        title: 'Exterior Doors And Grilles',
        fields: [
          {
            label: 'Swing',
            name: 'swing',
          },
          {
            label: 'Garage',
            name: 'garage',
          },
        ],
      },
      {
        title: 'Other',
        tooltip: 'Any other relevant items related to this category',
        fields: [
          {
            label: 'Amount',
            name: 'otherAmount',
          },
        ],
      },
    ],
  },
  'Roofing, Vents, Gutters, Skylights, Soffits, Insulation': {
    helperText: 'Please provide the cost and details of the following items',
    sections: [
      {
        title: 'Roofing',
        fields: [
          {
            label: 'Shingles',
            name: 'shingles',
          },
          {
            label: 'Tile',
            name: 'tile',
          },
          {
            label: 'Metal',
            name: 'metal',
          },
          {
            label: 'Membrane',
            name: 'membrane',
          },
          {
            label: 'Built-up',
            name: 'builtUp',
          },
          {
            label: 'Insulation',
            name: 'insulation',
          },
        ],
      },
      {
        title: 'Vents, Gutters, Downspots',
        fields: [
          {
            label: 'Vents',
            name: 'vents',
          },
          {
            label: 'Gutters',
            name: 'gutters',
          },
          {
            label: 'Downspouts',
            name: 'downspouts',
          },
        ],
      },
      {
        title: 'Skylights + Hatches',
        fields: [
          {
            label: 'Skylights',
            name: 'skylights',
          },
          {
            label: 'Hatches',
            name: 'hatches',
          },
        ],
      },
      {
        title: 'Soffits, Ext Ceilings',
        fields: [
          {
            label: 'Soffits',
            name: 'soffits',
          },
          {
            label: 'Ceilings',
            name: 'ceilings',
          },
        ],
      },
      {
        title: 'Other',
        tooltip: 'Any other relevant items related to this category',
        fields: [
          {
            label: 'Amount',
            name: 'otherAmount',
          },
        ],
      },
    ],
  },
};

export default mapper;
