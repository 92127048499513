import {
  MenuItem as MuiMenuItem,
  MenuItemProps as MuiMenuItemProps,
} from '@material-ui/core';
import {
  withAlertDialog,
  withConfirmDialog,
  WithConfirmDialogProps,
  withInformationDialog,
  withSubmitDialog,
} from '../dialog';
import { withTestDataAttribute } from '../testDataAttribute';

export type MenuItemProps = MuiMenuItemProps & {
  testId: string;
  button?: true;
};

export type ConfirmationMenuItemProps = MenuItemProps & WithConfirmDialogProps;

export const MenuItem = withTestDataAttribute<MenuItemProps>(props => {
  return (
    <MuiMenuItem onClick={props.onClick} {...props}>
      {props.children}
    </MuiMenuItem>
  );
});

export const ConfirmationMenuItem = withConfirmDialog<MenuItemProps>(props => {
  return (
    <MenuItem onClick={props.onClick} {...props}>
      {props.children}
    </MenuItem>
  );
});

export const AlertMenuItem = withAlertDialog<MenuItemProps>(props => {
  return (
    <MenuItem onClick={props.onClick} {...props}>
      {props.children}
    </MenuItem>
  );
});

export const SubmitMenuItem = withSubmitDialog<MenuItemProps>(props => {
  return (
    <MenuItem onClick={props.onClick} {...props}>
      {props.children}
    </MenuItem>
  );
});

export const InformationMenuItem = withInformationDialog<MenuItemProps>(props => {
  return (
    <MenuItem onClick={props.onClick} {...props}>
      {props.children}
    </MenuItem>
  );
});