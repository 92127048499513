import { Box } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { FilterButton } from '@roc/ui';
import { useBaseStore, useUserStore } from '@roc/feature-app-core';
import { Tabs } from '@roc/ui';
import { observer } from 'mobx-react';
import { useLoanStore } from '../hooks/useLoanStore';

const useButtonStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginX: theme.spacing(2),
    },
    startIcon: {
      margin: 'auto',
    },
  })
);

export const LoanTabs = observer(({ tabs, showSwitchView, totalLoanRequests = 0, showFilterOption = true, right = null }) => {
  const buttonStyles = useButtonStyles();
  const { globalStore } = useBaseStore();
  const { loanStore } = useLoanStore();
  const { userStore } = useUserStore();

  const getCurrentTabGridStore = () => {
    switch (loanStore.selectedTabIndex) {
      case 0:
        return loanStore.myPipelineLoansGridStore;
      case 1:
        return loanStore.myLoansGridStore;
      case 2:
        return loanStore.myPendingTasksGridStore;
      case 3:
        return loanStore.myAgreedLoansGridStore;
      case 4:
        return userStore.isJrOriginator ? loanStore.myDraftLoansGridStore : loanStore.myLoanQuotesGridStore;
      case 5:
        return loanStore.myRequestLoansGridStore;
      case 6:
        return loanStore.myBackUpLoansGridStore;
    }
  };

  const onTabChange = (e, index) => {
    loanStore.setSelectedTabIndex(index);
  };

  const showFilterButton =
    showFilterOption && (globalStore.isListView || loanStore.selectedTabIndex !== 1);

  const handleFilterButtonClick = () => {
    const store = getCurrentTabGridStore();
    store.toggleFilters();
  };

  const handleClearFilters = () => {
    const store = getCurrentTabGridStore();
    store.resetFilters();
  };

  return (
    <Tabs
      tabs={tabs}
      selectedTab={loanStore.selectedTabIndex}
      onTabChange={onTabChange}
      right={
        <>
          {right}
          <Box py={1} pr={2} display="flex" flexDirection="row">
            {showFilterButton && (
              <Box pr={2}>
                <FilterButton
                  onToggleFilters={handleFilterButtonClick}
                  onClearFilters={handleClearFilters} />
              </Box>
            )}
            {/* {showSwitchView() && (
      <ButtonGroup disableElevation>
        <Button
          variant="contained"
          title="Switch to List View"
          color={globalStore.isListView ? 'primary' : 'inherit'}
          classes={buttonStyles}
          onClick={() =>
            !globalStore.isListView && globalStore.toggleView()
          }
          startIcon={<ListIcon />}
          testId="switchToListView"
        />
        <Button
          variant="contained"
          title="Switch to Cards View"
          color={!globalStore.isListView ? 'primary' : 'inherit'}
          classes={buttonStyles}
          onClick={() =>
            globalStore.isListView && globalStore.toggleView()
          }
          startIcon={<CardIcon />}
          testId="switchToCardsView"
        />
      </ButtonGroup>
    )} */}
          </Box></>
      }
    />
  );
});
