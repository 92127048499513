import { ExpandMore } from '@material-ui/icons';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  makeStyles,
  Paper,
  Grid,
} from '@material-ui/core';
import { useState } from 'react';
import { observer } from 'mobx-react';
import { LeverageMatrixCardProps } from '../leverageStep';
import ExperienceMatrix from './experienceMatrix';
import { TitleDivider } from '../../../components/titleDivider';
const useStyles = makeStyles(theme => ({
  paper: {
    border: `1px solid lightgray`,
    borderRadius: '16px !important',
    padding: theme.spacing(1),
  },
}));




export const FixFlipGULeverageMatrixCard = observer(
  ({ store, data }: LeverageMatrixCardProps) => {
    const [expanded, setExpanded] = useState(false);
    const classes = useStyles();
    const highestExperience = store.borrowersStore.borrowerFormStores.reduce(
      (maxExp, borrower) => {
        const experience =
          borrower.form.fields.numberOfVerifiedInvestmentProperties.value;
        const isPersonalGuarantor =
          borrower.form.fields.personalGuarantor.value;
        return experience > maxExp && isPersonalGuarantor ? experience : maxExp;
      },
      0
    );

    return (
      <Accordion
        expanded={expanded}
        onChange={(e, expanded) => setExpanded(expanded)}
        elevation={0}
      >
        <AccordionSummary
          expandIcon={<ExpandMore fontSize="large" />}
          aria-controls="panel1-content"
          style={{
            padding: 0
          }}
        >
          <TitleDivider title="How did we come up with this Leverage?" />
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography
                variant="h5"
                style={{ marginTop: '1rem', marginBottom: '1rem' }}
              >
                Leverage Matrix -{' '}
                <span style={{ color: '#34A853', fontSize: '14px' }}>
                  (Borrower’s Tier highlighted below.)
                </span>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <ExperienceMatrix data={data} experience={highestExperience} />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  }
);
