import { action, computed, flow, makeObservable, observable } from 'mobx';
import { GlobalStore } from '@roc/client-portal-shared/stores';

export abstract class RocUniversityBaseStore {
  protected globalStore: GlobalStore;

  abstract getRocUniversityURL();
  abstract getLogoLMS();


  logoLMS: string;
  rocUniversityURL: string;
  showAlert: boolean;

  constructor(globalStore) {
    this.globalStore = globalStore;

    makeObservable(this, {
      getRocUniversityURL: flow,
      getLogoLMS: flow,
      rocUniversityURL: observable,
      showAlert: observable,
      logoLMS: observable,
      updateRocUniversityURL: action,
      setShowAlert: action,
      reset: action,
      setLogoLMS: action,
    });
    this.setDefaults();
  }

  private setDefaults() {
    this.rocUniversityURL = null;
    this.showAlert = false;
    this.logoLMS = null;
  }

  reset() {
    this.setDefaults();
  }

  updateRocUniversityURL(url: string) {
    this.setShowAlert(!url);
    this.rocUniversityURL = url;
  }

  setShowAlert(value) {
    this.showAlert = value;
  }

  setLogoLMS(logoUrl: string) {
    this.logoLMS = logoUrl;
  }
}
