import {
  Box,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { Event } from '@material-ui/icons';
import { useLoanStore } from '@roc/feature-loans-shared';
import { Paper } from '@roc/ui';

const useStyles = makeStyles(theme => ({
  container: {
    padding: 32,
  },
}));

const listItem = (label, value, divider: boolean = true) => {
  return (
    (
      <>
        {divider && <Divider variant="middle" component="li" />}
        <ListItem>
          <ListItemText>
            <Box display="flex">
              <Box flexGrow={1}>{label}</Box>
              <span />
              <Box>{value}</Box>
            </Box>
          </ListItemText>
        </ListItem>
      </>
    )
  );
};
interface LoanKeyDatesProps {

}
const LoanKeyDates = (props: LoanKeyDatesProps) => {
  const { loanStore } = useLoanStore();
  const classes = useStyles();
  return (
    <Grid item xs={12} md={6}>
      <Paper title="LOAN KEY DATES">
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Grid container direction="column">
              <List>
                {listItem('Submission', loanStore?.loanDetails.submissionDate, false)
                }
                {listItem('Closing Date', loanStore?.loanDetails.closingDate)
                }
                {listItem('Maturity', loanStore?.loanDetails.maturityDate)
                }
              </List>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>

  );
};


export const LoanKeyDatesNew = (props: LoanKeyDatesProps) => {
  const { loanStore } = useLoanStore();
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <Paper style={{
          background: 'linear-gradient(135deg, rgba(118, 176, 241, 0.2), rgba(32, 101, 209, 0.2)) rgb(255, 255, 255)',
          boxShadow: 'none',
          height: '100%',
          display: 'flex',
          alignItems: 'center'
        }}>
          <Box p={4} width={'100%'} textAlign={'center'}>
            <Box mb={.5}>
              <Event />
            </Box>
            <Typography variant='h4' gutterBottom>
              {loanStore?.loanDetails?.submissionDate ?? '-'}
            </Typography>
            <Typography variant='body2'>
              Loan Submission Date
            </Typography>
          </Box>
        </Paper>
      </Grid>
      <Grid item xs={12} md={4}>
        <Paper style={{
          background: 'linear-gradient(135deg, rgba(97, 243, 243, 0.2), rgba(0, 184, 217, 0.2)) rgb(255, 255, 255)',
          boxShadow: 'none',
          height: '100%',
          display: 'flex',
          alignItems: 'center'
        }}>
          <Box p={4} width={'100%'} textAlign={'center'}>
            <Box mb={.5}>
              <Event />
            </Box>
            <Typography variant='h4' gutterBottom>
              {loanStore?.loanDetails?.closingDate ?? '-'}
            </Typography>
            <Typography variant='body2'>
              Loan Closing Date
            </Typography>
          </Box>
        </Paper>
      </Grid>
      <Grid item xs={12} md={4}>
        <Paper style={{
          background: 'linear-gradient(135deg, rgba(255, 214, 102, 0.2), rgba(255, 171, 0, 0.2)) rgb(255, 255, 255)',
          boxShadow: 'none',
          height: '100%',
          display: 'flex',
          alignItems: 'center'
        }}>
          <Box p={4} width={'100%'} textAlign={'center'}>
            <Box mb={.5}>
              <Event />
            </Box>
            <Typography variant='h4' gutterBottom>
              {loanStore?.loanDetails?.extendedMaturityDate ?? '-'}
            </Typography>
            <Typography variant='body2'>
              Loan Maturity Date
            </Typography>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default LoanKeyDates;