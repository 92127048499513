import { ApiResponse, GlobalStore, GridStore } from "@roc/feature-app-core";
import { NewLeadService } from "@roc/feature-new-lead";
import { GENERIC_ERROR_MESSAGE } from "@roc/feature-utils";
import { flow, makeObservable, observable } from "mobx";
import { LeadsDashboardService } from "../services/leadsDashboardService";
import { LeadsDashboardStore } from "./leadsDashboardStore";
import { BaseLeadStore } from "libs/client-portal-shared/src/app/modules/companyProfile/stores/baseLeadStore/BaseLeadStore";

const PAGE_SIZE = 50;
export class LeadsDashboardGridStore extends GridStore {
  private globalStore: GlobalStore;
  private leadsDashboardStore: LeadsDashboardStore;
  private leadsDashboardService: LeadsDashboardService;
  private newLeadService: NewLeadService;
  private leadStore: BaseLeadStore;

  constructor(globalStore: GlobalStore, leadsDashboardStore, leadStore: BaseLeadStore) {
    super(() => this.fetchRows(), undefined, PAGE_SIZE);
    this.globalStore = globalStore;
    this.leadsDashboardStore = leadsDashboardStore;
    this.leadsDashboardService = new LeadsDashboardService();
    this.newLeadService = new NewLeadService();
    this.leadStore = leadStore;
  }

  fetchRows = async () => {
    try {
      if (this.leadsDashboardStore.isCustomDashboard) {
        return await this.fetchCustomDashboard();
      } else {
        return await this.fetchStandardDashboard()
      }
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE
      });
    }
  };


  fetchStandardDashboard = async () => {
    const { currentDashboard } = this.leadsDashboardStore;
    const response: ApiResponse = await this.newLeadService.getLeadsListById(
      currentDashboard.dashboardListId,
      this.gridData.meta.pageNumber,
      this.gridData.meta.pageSize,
      this.gridData.meta.sortBy,
      this.gridData.meta.sortDir,
      this.gridData.meta.filters,
      this.gridData.meta.dropdownFilters,
      this.leadStore.gridBusinessUnit
    );
    return response;
  }


  fetchCustomDashboard = async () => {
    const { currentDashboard } = this.leadsDashboardStore;

    const dashboardData = {
      ...currentDashboard,
      creationDate: undefined,
      lastUpdatedDate: undefined
    }

    const response = await this.leadsDashboardService.getCustomDashboardDataById(
      dashboardData,
      this.gridData.meta.pageNumber,
      this.gridData.meta.pageSize,
      this.gridData.meta.sortDir,
      this.gridData.meta.sortBy,
      this.gridData.meta.filters,
      this.gridData.meta.dropdownFilters,
      this.leadStore.gridBusinessUnit
    );
    const data = JSON.parse(response.data.data);
    return new ApiResponse({ data }, null);
  }
}