import { Box, Grid, lighten, Link, makeStyles, Theme } from '@material-ui/core';
import { Description, OpenInNew } from '@material-ui/icons';
import ExcelIcon from './../../assets/icon-microsoft-excel.png';
import PdfIcon from './../../assets/icon-pdf.png';
import HTMLIcon from './../../assets/icon-html-5.png';
import HTMLYellowIcon from './../../assets/icon-html-5-yellow.png';
import FileIcon from './../../assets/icon-file.png';
import FolderIcon from './../../assets/icon-folder.png';
import VideoIcon from './../../assets/icon-video.png';

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    cursor: 'pointer',
    display: 'block',
    padding: 8,
    color: theme.palette.grey[900],
    '&:hover': {
      color: theme.palette.primary.dark,
      backgroundColor: lighten(theme.palette.primary.main, .9),
      textDecoration: 'none',
    },
  },
}));

export enum DocumentItemType {
  FOLDER,
  EXCEL,
  PDF,
  HTML,
  VIDEO,
  OTHER,
  LINK
}

const getIcon = (type: DocumentItemType) => {
  let iconImg;
  switch (type) {
    case DocumentItemType.FOLDER:
      iconImg = FolderIcon;
      break;
    case DocumentItemType.EXCEL:
      iconImg = ExcelIcon;
      break;
    case DocumentItemType.PDF:
      iconImg = PdfIcon;
      break;
    case DocumentItemType.HTML:
      iconImg = HTMLYellowIcon;
      break;
    case DocumentItemType.VIDEO:
      iconImg = VideoIcon;
      break;
    case DocumentItemType.LINK:
      return <OpenInNew fontSize='large' />
    default:
      iconImg = FileIcon;
      break;
  }
  return (
    <img
      src={iconImg}
      style={{
        maxWidth: 48,
      }}
    />
  )
};

export const DocumentItem = ({ type = DocumentItemType.OTHER, name, onClick }) => {
  const classes = useStyles();

  return (
    <Link
      variant="button"
      className={classes.link}
      onClick={() => onClick()}
    >
      <Grid item container alignItems="center" style={{
        flexWrap: 'nowrap'
      }}>
        <Grid item>
          <Box p={1} display="flex" alignItems="center">
            {getIcon(type)}
          </Box>
        </Grid>
        <Grid item>
          {name}
        </Grid>
      </Grid>
    </Link>
  );
};
