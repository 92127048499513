import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { observer } from 'mobx-react';
import { Paper } from '@roc/ui';
import { formatCurrency, formatPercentage } from '@roc/ui/utils';
import { useUserStore } from '@roc/feature-app-core';

const renderHeading = value => {
  return (
    <Typography variant="body1">
      <strong>{value}</strong>
    </Typography>
  );
};

const usdAmount: any = value => {
  return value != null && formatCurrency(Number(value));
};

const renderValue = (value, render) => (value != null ? render : '-');

export const LoanEconomicTerm = observer(({ loanDetails }) => {
  const {
    rate,
    brokerPointsIn,
    rocRate,
    rocPointsIn,
    totalOriginationPoints,
    sfrLenderPointsIn,
    totalUnderwritingFees,
    rocUnderwritingFees,
    brokerLenderUnderwritingFees,
    goodFaithDeposit,
    loanDepositData: { depositReceived },
    lenderPremium,
    loanRetailFees,
  } = loanDetails;

  const totalUnderwritingFee = totalUnderwritingFees ?? 0;
  const rocUnderwritingFee = parseFloat(rocUnderwritingFees || 0) + parseFloat(loanRetailFees?.retailUnderwritingFee || 0);
  const totalRocPoints = parseFloat(rocPointsIn || 0) + parseFloat(loanRetailFees?.retailPoints || 0);
  const brokerLenderUnderwritingFee = brokerLenderUnderwritingFees ?? 0;
  const { userStore } = useUserStore();
  const allowLoanPricing = userStore.allowLoanPricing;

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{renderHeading('')}</TableCell>
            <TableCell>{renderHeading('Borrower')}</TableCell>
            {allowLoanPricing ? (
              <><TableCell>{renderHeading('Capital Provider')}</TableCell>
                <TableCell>{renderHeading("You")}</TableCell>
                <TableCell>{renderHeading('Broker')}</TableCell></>
            ) : (
              <><><TableCell>{renderHeading("Lender")}</TableCell>
                <TableCell>{renderHeading('Broker')}</TableCell></>
                <TableCell>{renderHeading('')}</TableCell></>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>{renderHeading('Interest Rate')}</TableCell>
            <TableCell>
              {renderValue(rate, formatPercentage(rate, 3))}
            </TableCell>
            {allowLoanPricing ? (
              <><TableCell>
                {renderValue(rocRate, formatPercentage(rocRate, 3))}
              </TableCell>
                <TableCell>{'N/A'}</TableCell>
                <TableCell>{'N/A'}</TableCell></>
            ) : (<><TableCell>
              {renderValue(rocRate, formatPercentage(rocRate, 3))}
            </TableCell>
              <TableCell>{'N/A'}</TableCell>
              <TableCell></TableCell></>
            )}
          </TableRow>
          <TableRow>
            <TableCell>{renderHeading('Points In')}</TableCell>
            <TableCell>
              {renderValue(
                totalOriginationPoints,
                formatPercentage(totalOriginationPoints, 3)
              )}
            </TableCell>
            {allowLoanPricing ? (
              <><TableCell>
                {renderValue(totalRocPoints, formatPercentage(totalRocPoints, 3))}
              </TableCell>
                <TableCell>
                  {renderValue(
                    sfrLenderPointsIn,
                    formatPercentage(sfrLenderPointsIn, 3)
                  )}
                </TableCell>
                <TableCell>
                  {renderValue(brokerPointsIn, formatPercentage(brokerPointsIn, 3))}
                </TableCell></>
            ) : (
              <><TableCell>
                {renderValue(rocPointsIn + sfrLenderPointsIn, formatPercentage(rocPointsIn + sfrLenderPointsIn, 3))}
              </TableCell>
                <TableCell>
                  {renderValue(brokerPointsIn, formatPercentage(brokerPointsIn, 3))}
                </TableCell>
                <TableCell></TableCell></>
            )}
          </TableRow>
          <TableRow>
            <TableCell>
              {renderHeading('Processing/Underwriting Fees')}
            </TableCell>
            <TableCell>
              {renderValue(
                totalUnderwritingFee,
                usdAmount(totalUnderwritingFee)
              )}
            </TableCell>
            {allowLoanPricing ? (
              <><TableCell>
                {renderValue(rocUnderwritingFee, usdAmount(rocUnderwritingFee))}
              </TableCell><TableCell>
                  {renderValue(
                    brokerLenderUnderwritingFee,
                    usdAmount(brokerLenderUnderwritingFee)
                  )}
                </TableCell><TableCell>{'N/A'}</TableCell></>
            ) : (
              <><TableCell>
                {renderValue(rocUnderwritingFee + brokerLenderUnderwritingFee, usdAmount(rocUnderwritingFee + brokerLenderUnderwritingFee))}
              </TableCell>
                <TableCell>{'N/A'}</TableCell>
                <TableCell></TableCell></>
            )}
          </TableRow>
          <TableRow>
            <TableCell>{renderHeading('Borrower Paid Deposit')}</TableCell>
            <TableCell>
              {depositReceived
                ? renderValue(goodFaithDeposit, usdAmount(goodFaithDeposit))
                : usdAmount(0)}
            </TableCell>
            {allowLoanPricing ? (
              <><TableCell>{'N/A'}</TableCell>
                <TableCell>{'N/A'}</TableCell>
                <TableCell>{'N/A'}</TableCell></>
            ) : (
              <><TableCell>{'N/A'}</TableCell>
                <TableCell>{'N/A'}</TableCell>
                <TableCell></TableCell></>
            )}

          </TableRow>
          <TableRow>
            <TableCell>
              {renderHeading('Premium Payments (at close)')}
            </TableCell>
            <TableCell>{'N/A'}</TableCell>
            {allowLoanPricing ? (
              <><TableCell>{'N/A'}</TableCell><TableCell>
                {renderValue(lenderPremium, formatPercentage(lenderPremium, 2))}
              </TableCell><TableCell>{'N/A'}</TableCell></>
            ) : (
              <><TableCell>
                {renderValue(lenderPremium, formatPercentage(lenderPremium, 2))}
              </TableCell>
                <TableCell>{'N/A'}</TableCell></>
            )}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
});
