import { observer } from "mobx-react";
import { CompanyProfileCRM } from "./companyProfileCRM";
import { useStore } from 'libs/client-portal-shared/src/app/hooks/index';


export const CompanyProfileCRMWrapper = observer(({ maxWidth = false }) => {
  const { companyProfileStore, globalStore, manageUsersStore, manageUsersBackUpStore } = useStore();
  companyProfileStore.salesforceLenderStore.setPortal("External");
  return (<CompanyProfileCRM
    maxWidth={maxWidth}
    companyProfileStore={companyProfileStore}
    globalStore={globalStore}
    manageUsersStore={manageUsersStore}
    manageUsersBackUpStore={manageUsersBackUpStore}
  />);
});