import { Theme, ThemeOptions } from '@material-ui/core/styles';
import { createContext } from 'react';

import { Overrides } from '@material-ui/core/styles/overrides';
import { ThemeConfiguration } from '@roc/feature-types';
import { observer } from 'mobx-react';

export const ThemeConfigurationContext = createContext<ThemeConfiguration | undefined>(null);

export const ThemeCongifurationContextProvider = observer(({ value, children }: {
  value: ThemeConfiguration | undefined,
  children: any
}) => {
  return (
    <ThemeConfigurationContext.Provider value={value}>
      {children}
    </ThemeConfigurationContext.Provider >
  );
});