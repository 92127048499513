import React from 'react';
import { Grid } from '@material-ui/core';
import { StandardDialog, Button } from '@roc/ui';
import { observer } from 'mobx-react';
import { AddressMismatch } from './addressMismatch';

export type AddressMismatchDialogProps = {
  open: boolean;
  propertyAddress: string;
  scopeOfWorkAddress: string;
  onClose: () => void;
  onLink: () => void;
};

export const AddressMismatchDialog = observer(
  (props: AddressMismatchDialogProps) => {
    const {
      open,
      propertyAddress,
      scopeOfWorkAddress,
      onClose,
      onLink,
    } = props;

    return (
      <StandardDialog
        open={open}
        title="Address Mismatch"
        maxWidth="sm"
        handleClose={onClose}
        dialogContent={
          <AddressMismatch
            propertyAddress={propertyAddress}
            scopeOfWorkAddress={scopeOfWorkAddress}
          />
        }
        dialogActions={
          <Grid container spacing={1} justifyContent="flex-end">
            <Grid item>
              <Button
                onClick={onClose}
                color="primary"
                variant="outlined"
                testId="close"
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={onLink}
                color="primary"
                variant="contained"
                testId="updateAndLink"
              >
                Update and link
              </Button>
            </Grid>
          </Grid>
        }
      />
    );
  }
);

export default AddressMismatchDialog;
