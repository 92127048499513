import { Route } from 'react-router';

import { borrowerLoanApplicationsRoutesConfig } from '@roc/feature-loans-routes-config';
import { BorrowerLoanApplicationDetailsV2 } from '../modules/borrowerLoanDetails/borrowerLoanApplicationDetailsV2';


export const getBorrowerLoanApplicationsRoutesConfig = (
  basePath: string,
  baseUrl: string
) => {
  return borrowerLoanApplicationsRoutesConfig(basePath, baseUrl);
};

export const getBorrowerLoanApplicationsRoutes = (basePath: string, baseUrl: string) => {
  const config = getBorrowerLoanApplicationsRoutesConfig(basePath, baseUrl);
  return {
    loanDetails: (
      <Route
        path={config.loans().path}
      >
        <BorrowerLoanApplicationDetailsV2 />
      </Route>
    ),
  };
};
