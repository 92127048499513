import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Button, RadioField, Tabs } from '@roc/ui';
import { useDocumentStore } from '@roc/feature-documents';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { TamariskPayment } from '../../tamariskPayment/tamariskPayment';
import { Document } from '@roc/feature-types';
import { CDADefault } from '../cdaOrderDetailsTab/CDADefault';
import { CDAOrderDetails } from '../cdaOrderDetailsTab/CDAOrderDetails';
import { CDAManualSubmission } from '../cdaOrderDetailsTab/CDAManualSubmission';
import { AppraisalOrderDetailsDashboards } from '../appraisalOrderDetailsTab/AppraisalOrderDetailsDashboards';
import { ARR, CDA, LoanSubType } from '@roc/feature-utils';
import { useStore } from 'apps/client-portal-public/src/app/hooks';
import { AppraisalDetails1004d } from '../../1004dAppraisalDetails/appraisalDetails1004d';

const APPRAISAL_PAYMENT_LOAN = "Appraisal_Loan";

const useStyles = makeStyles({
  instruction: {
    fontWeight: 600,
  },
  paymentDetails: {
    fontWeight: 800,
    marginTop: '20px',
    fontStyle: 'italic',
  },
  tabsContainer: {
    border: 'none !important',
    marginTop: '-35px',
  },
  container: {
    paddingTop: 12
  },
  closeButtonContainer: {
    paddingTop: 16,
  },
  button: {
    width: '100%',
  }
});

export enum LoanType {
  RESIDENTIAL_BRIDGE = 'Residential Bridge',
  RESIDENTIAL_TERM = 'Residential Term',
}

export type AppraisalOrderDetailsProps = {
  loan: any;
  document: Document;
  onUpdate?: () => void;
  onSubmitLoanPayments: () => void;
  onClick?: () => void;
  isInternal?: boolean;
  loanId?: number;
  isTamariskLender?: boolean;
};

const yesNoOptions = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

const TamariskPaymentUrl = (props) => {

  const { loan, isInternal, isLenderWithTamarisk } = props;
  const classes = useStyles();
  const { documentStore } = useDocumentStore();
  const { documentFormStore } = documentStore;
  const { tamariskPaymentStore } = documentFormStore;

  useEffect(() => {
    if (isInternal)
      window.parent.postMessage('link-automated', '*');
  }, []);

  return (
    <Grid container className={classes.container}>
      <Grid item container spacing={2}>
        {!isLenderWithTamarisk &&
          <Grid item xs={11}>
            <TamariskYesNoQuestions
              loan={props.loan}
              document={props.document}
              onUpdate={props.onUpdate}
              onSubmitLoanPayments={props.onSubmitLoanPayments}
              onClick={props.onClick}
              handleConfirm={props.handleConfirm}
              handleClose={props.handleClose}
              isInternal={isInternal}
            />
          </Grid>}
        <Grid item xs={12}>
          <TamariskPayment
            borrowersEmail={tamariskPaymentStore.borrowersEmail}
            tamariskURL={tamariskPaymentStore.tamariskPaymentURL}
            entityId={loan.loanId}
            type={APPRAISAL_PAYMENT_LOAN}
            paymentStatus={false}
            isInternal={isInternal}
          />
        </Grid>
        <Grid item container xs={12} justifyContent='flex-end' className={classes.closeButtonContainer}>
          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              onClick={props.handleClose}
              testId="cancel"
            >
              Close
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
};

const TamariskYesNoQuestions = (props) => {
  const { loan, handleConfirm, isInternal } = props;
  const [tamariskOptionValue, setTamariskOptionValue] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    setTamariskOptionValue(loan.submitAppraisalWithTamarisk);
    if (isInternal)
      window.parent.postMessage('link-manual', '*');
  }, []);

  const setTamariskValue = (value: string) => setTamariskOptionValue(value === 'true');

  return (
    <Grid container justifyContent="center" spacing={1}>
      <Grid item style={{ width: '89%' }}>
        <Typography style={{ fontWeight: 600 }}>Will the borrower be using Tamarisk for Appraisal?</Typography>
        <RadioField
          name="loanPaymentsDecision"
          value={tamariskOptionValue}
          options={yesNoOptions}
          onChange={value => setTamariskValue(value)}
          fullWidth
          row
          testId="loanPaymentsDecision"
        />
      </Grid>
      <Grid item container style={{ width: '89%', paddingBottom: 12 }}>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleConfirm(tamariskOptionValue)}
            testId="confirm"
            disabled={(loan.submitAppraisalWithTamarisk && tamariskOptionValue) || (!loan.submitAppraisalWithTamarisk && !tamariskOptionValue)}
            className={classes.button}
          >
            Confirm
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

const TamariskUploadDocuments = ({ isInternal }) => {
  const classes = useStyles();
  const { documentStore } = useDocumentStore();

  const handleClose = () => {
    if (isInternal) {
      window.parent.postMessage('close-modal', '*');
      window.parent.postMessage('clear-style', '*');
    } else {
      documentStore.documentFormStore.setCurrentForm(null);
    }
  };

  return (<Grid container justifyContent="center" className={classes.container}>
    <Grid item xs={11}>
      <Typography variant="body1">Please upload the Appraisals reports to the corresponding line item in the Subject Property section(s)</Typography>
    </Grid>
    <Grid item container xs={11} justifyContent='flex-end' className={classes.closeButtonContainer}>
      <Grid item>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleClose}
          testId="close"
          className={classes.button}
        >
          Close
        </Button>
      </Grid>
    </Grid>
  </Grid>)
}
export const AppraisalOrderDetails = observer((props: AppraisalOrderDetailsProps) => {

  const classes = useStyles();
  const [showRevisionTab, setShowRevisionTab] = useState(false);
  const { isInternal, loan, isTamariskLender } = props;
  const { globalStore } = useStore();
  const { documentStore } = useDocumentStore();
  const { documentFormStore } = documentStore;
  const { cdaAndAppraisalOrderDetailsStore, appraisalRecertificationOrderDetailsStore } = documentFormStore;
  appraisalRecertificationOrderDetailsStore.setIsInternal(globalStore.isInternalPortal);
  cdaAndAppraisalOrderDetailsStore.setIsInternal(props.isInternal);
  cdaAndAppraisalOrderDetailsStore.loanStore.loanDetails = loan;

  useEffect(() => {
    appraisalRecertificationOrderDetailsStore.isAnyAppraisalRevisionByLoanIdPresent()
      .then((isPresent) => {
        setShowRevisionTab(isPresent);
      })
    cdaAndAppraisalOrderDetailsStore.setLoanCdaPaymentUrl(null);
    cdaAndAppraisalOrderDetailsStore.setCdaLoading(false);
  }, []);
  useEffect(() => {
    if (loan?.loanId && loan?.cdaEnabled && !isCdaPaymentDone) {
      cdaAndAppraisalOrderDetailsStore.getCdaPaymentUrl(loan.loanId);
    }
  }, [loan?.loanId]);

  useEffect(() => {
    if (loan?.cdaEnabled && cdaAndAppraisalOrderDetailsStore.loanCdaPaymentUrl) {
      setPaymentUrl(cdaAndAppraisalOrderDetailsStore.loanCdaPaymentUrl);
    } else if (loan?.arrEnabled && loan?.arrPaymentURL) {
      setPaymentUrl(loan?.arrPaymentURL);
    }
  }, [cdaAndAppraisalOrderDetailsStore.loanCdaPaymentUrl]);

  // Tamarisk flags
  const isTamariskPaymentDone = loan.loanDepositData && loan.loanDepositData.depositReceived && (loan.loanDepositData.depositType === 'Appraisal_Standalone' || loan.loanDepositData.depositType === 'Appraisal_Loan' || loan.loanDepositData.depositType === 'ARR_CDA_Loan' || loan.loanDepositData.depositType === 'CDA_Loan')
  const isTamariskDefault = loan.submitAppraisalWithTamarisk;
  const tamariskPaymentURL = loan.tamariskPaymentURL ?? null;

  // CDA flags
  const cdaArrReady = loan.cdaArrReady ?? false;
  const arrPaymentUrl = loan.arrPaymentURL;
  const [paymentUrl, setPaymentUrl] = useState(loan.arrEnabled ? arrPaymentUrl : null)
  const isCdaPaymentDone = loan.cdaLoanDepositData?.depositReceived;
  const isArrPaymentDone = loan.arrLoanDepositData?.depositReceived;

  const borrowersEmail = cdaAndAppraisalOrderDetailsStore.borrowersEmail;

  const [selectedTab, setSelectedTab] = useState((cdaArrReady && loan.loanType === LoanType.RESIDENTIAL_TERM) ? 1 : 0);
  const [tamariskResponse, setTamariskResponse] = useState(null);

  const onTabChange = (e, index) => setSelectedTab(index);

  const loanSubtype = loan.loanSubtype ? loan.loanSubtype : loan.loanSubType;
  const isBridgeLoan = loan.loanType === LoanType.RESIDENTIAL_BRIDGE ||
    loanSubtype === LoanSubType.SINGLE_PROPERTY_MULTIFAMILY ||
    loanSubtype === LoanSubType.MULTIFAMILY_TERM_PORTFOLIO;
  const TABS = (isBridgeLoan && showRevisionTab) ? [
    {
      label: 'Appraisal Order Details',
    },
    {
      label: 'Revised Appraisal Details',
    },
  ] : isBridgeLoan ? [
    {
      label: 'Appraisal Order Details',
    },
  ] :
    showRevisionTab ?
    [
      {
        label: 'Appraisal Order Details',
      },
      {
        label: 'CDA/ARR Order Details',
      },
      {
        label: 'Revised Appraisal Details',
      },
      ] :
      [
        {
          label: 'Appraisal Order Details',
        },
        {
          label: 'CDA/ARR Order Details',
        }
    ];

  const handleClose = () => {
    if (isInternal) {
      window.parent.postMessage('close-modal', '*');
      window.parent.postMessage('clear-style', '*');
    } else {
      documentStore.documentFormStore.setCurrentForm(null);
    }
  };

  const handleConfirm = async value => {
    await cdaAndAppraisalOrderDetailsStore.updateAppraisalAndCdaCheck(value, 'APPRAISAL', false);
    setTamariskResponse(value);
    cdaAndAppraisalOrderDetailsStore.loanStore.loanDetails.submitAppraisalWithTamarisk = value;
    cdaAndAppraisalOrderDetailsStore.loanStore.fetchLoanDetails(loan.loanId);
  }

  const handleCdaResponse = async (value, cdaRushed) => {
    const response = await cdaAndAppraisalOrderDetailsStore.updateAppraisalAndCdaCheck(false, value.toUpperCase(), cdaRushed);
    if (response) {
      switch (value) {
        case CDA:
          cdaAndAppraisalOrderDetailsStore.loanStore.loanDetails.cdaEnabled = true;
          break;
        case ARR:
          cdaAndAppraisalOrderDetailsStore.loanStore.loanDetails.arrEnabled = true;
          break;
      }
      setPaymentUrl(response);
    }
  }

  const tabContentRenderer = (tab) => {
    if (tab === 0) {
      if (isTamariskPaymentDone) {
        return (
          <AppraisalOrderDetailsDashboards
            loanId={props.loan.loanId}
            isInternal={isInternal}
          />);
      } else if (tamariskResponse === true || (isTamariskDefault && tamariskPaymentURL)) {
        return (<TamariskPaymentUrl
          loan={props.loan}
          document={props.document}
          onUpdate={props.onUpdate}
          onSubmitLoanPayments={props.onSubmitLoanPayments}
          onClick={props.onClick}
          isInternal={cdaAndAppraisalOrderDetailsStore.isInternal}
          handleConfirm={handleConfirm}
          handleClose={handleClose}
          isLenderWithTamarisk={isTamariskLender}
        />);
      } else if (!isTamariskDefault || isTamariskDefault === null || isTamariskDefault === undefined) {
        return (
          <TamariskYesNoQuestions
            loan={props.loan}
            document={props.document}
            onUpdate={props.onUpdate}
            onSubmitLoanPayments={props.onSubmitLoanPayments}
            onClick={props.onClick}
            handleConfirm={handleConfirm}
            isInternal={isInternal}
          />);
      } else if (tamariskResponse === false || isTamariskDefault === false) {
        return (<TamariskUploadDocuments isInternal={isInternal} />);
      }
    } else if (tab === 1) {
      if (showRevisionTab && TABS[tab].label === 'Revised Appraisal Details') {
        return <AppraisalDetails1004d
          loanId={props.loan.loanId}
          isInternal={appraisalRecertificationOrderDetailsStore.isInternal}
          isCda={loan?.cdaEnabled}
          isArr={loan?.arrEnabled}
          onConfirm={handleCdaResponse}
          url={paymentUrl}
          borrowerEmail={borrowersEmail}
          isCdaPaymentDone={isCdaPaymentDone}
          isArrPaymentDone={isArrPaymentDone}
        />;
      }
      else if (!cdaArrReady) {
        return <CDADefault
          isInternal={isInternal}
        />;
      } else if (isCdaPaymentDone || isArrPaymentDone) {
        return <CDAOrderDetails
          loanId={props.loan.loanId}
          isInternal={isInternal}
          isCda={loan?.cdaEnabled}
          isArr={loan?.arrEnabled}
          onConfirm={handleCdaResponse}
          url={paymentUrl}
          borrowerEmail={borrowersEmail}
          isCdaPaymentDone={isCdaPaymentDone}
          isArrPaymentDone={isArrPaymentDone}
        />;
      } else if (!cdaAndAppraisalOrderDetailsStore.cdaLoading) {
        return <CDAManualSubmission
          onConfirm={handleCdaResponse}
          isInternal={isInternal}
          url={paymentUrl}
          borrowersEmail={borrowersEmail}
          loanId={props.loan.loanId}
          cdaEnabled={loan?.cdaEnabled}
        />;
      }
    }
    else if (tab === 2) {
      return <AppraisalDetails1004d
        loanId={props.loan.loanId}
        isInternal={appraisalRecertificationOrderDetailsStore.isInternal}
        isCda={loan?.cdaEnabled}
        isArr={loan?.arrEnabled}
        onConfirm={handleCdaResponse}
        url={paymentUrl}
        borrowerEmail={borrowersEmail}
        isCdaPaymentDone={isCdaPaymentDone}
        isArrPaymentDone={isArrPaymentDone}
      />;
    }
    return null;
  };

  return (
    <>
      <Tabs
        tabs={TABS}
        selectedTab={selectedTab}
        onTabChange={onTabChange}
        className={classes.tabsContainer}
      />
      {tabContentRenderer(selectedTab)}
    </>
  );
});
