import { Box, Grid, ListItem, ListItemText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { useStore } from '@roc/client-portal-shared/hooks';
import { Button, Paper } from '@roc/ui';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { createCurrencyField, createPercentageField } from '../../../components/formComponents/common/componentBuilder';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(2),
  },
}));

export const Economics = observer(({ companyProfileStore }) => {
  const formStore = companyProfileStore.lenderFeesFormStore;
  const lenderFormStore = companyProfileStore.salesforceLenderStore;
  const isEditing = formStore.isEditing;

  const classes = useStyles();

  useEffect(() => {
    formStore.loadLenderFees();
  }, []);

  return (
    <Paper className={classes.paper}>
      {formStore.lenderFees && (
        <Box p={4}>
          <Grid container spacing={4}>
            <Grid item xs={12} container justifyContent="flex-end">
              {isEditing ? (
                <>
                  <Button
                    style={{ marginRight: '8px' }}
                    onClick={() => formStore.cancel()}
                    color="primary"
                    variant="outlined"
                    testId="cancel"
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={() => formStore.saveLenderFees()}
                    color="primary"
                    variant="contained"
                    testId="save"
                  >
                    Apply Changes
                  </Button>
                </>
              ) : (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => formStore.edit()}
                  testId="save"
                >
                  Edit
                </Button>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              {createCurrencyField({
                testId: 'economics-admin-fees',
                label: 'Admin Fees',
                fieldName: 'adminFees',
                store: formStore,
                disabled: !isEditing,
              })}
            </Grid>
            <Grid item xs={12} md={6}>
              {createCurrencyField({
                testId: 'economics-commitment-fees',
                label: 'Commitment Fees',
                fieldName: 'commitmentFees',
                store: formStore,
                disabled: !isEditing,
              })}
            </Grid>
            <Grid item xs={12} md={6}>
              {createCurrencyField({
                testId: 'economics-processing-fees',
                label: 'Processing Fees',
                fieldName: 'processingFees',
                store: formStore,
                disabled: !isEditing,
              })}
            </Grid>
            <Grid item xs={12} md={6}>
              {createCurrencyField({
                testId: 'economics-underwriting-fees',
                label: 'Underwriting Fees',
                fieldName: 'underWritingFees',
                store: formStore,
                disabled: !isEditing,
              })}
            </Grid>
            <Grid item xs={12} md={6}>
              {createPercentageField({
                testId: 'economics-default-term-points',
                label: 'Term Origination Points',
                fieldName: 'defaultTermPoints',
                store: formStore,
                disabled: !isEditing,
              })}
            </Grid>
          </Grid>
        </Box>
      )}
    </Paper>
  );
});
