
import { flow, makeObservable, observable } from 'mobx';
import { ContactInformationService } from '../services/contactInformationService';
import { ApiResponse, GlobalStore } from '@roc/feature-app-core';


export class ContactInformationStore {
  private globalStore: GlobalStore;
  private contactInformationService: ContactInformationService;

  lenderAdminContacts: any[];

  constructor(globalStore: GlobalStore) {

    this.globalStore = globalStore;
    this.contactInformationService = new ContactInformationService();
    this.lenderAdminContacts = [];
    makeObservable(this, {
      getAllLenderAdminUsers: flow,
      lenderAdminContacts: observable,
    });
  }


  *getAllLenderAdminUsers() {
    try {
      const response: ApiResponse = yield this.contactInformationService.getAllLenderAdminUsers();
      console.log(response.data.data)
      this.lenderAdminContacts = [...response.data?.data];
    } catch (error) {
      // do nothing
    }
  }


}

export default ContactInformationStore;
