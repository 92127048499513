import { observer } from 'mobx-react';
import { Button } from '@roc/ui';
import { Edit, Delete } from '@material-ui/icons';
import { Grid, makeStyles } from '@material-ui/core';
import { deleteButtonStyle, beigeButtonColorStyle } from '../utils/constants';

const useStyles = makeStyles(() => ({
  deleteButton: deleteButtonStyle,
  beigeButtonColor: beigeButtonColorStyle,
}));

interface ViewControlsProps {
  onDeleteClick?: () => void;
  onEditClick?: () => void;
  showRemoveButton?: boolean;
  hasCustomClass?: boolean;
  removeButtonDisabled?: boolean;
}

export const ViewControls = observer(
  ({
    onDeleteClick = () => null,
    onEditClick = () => null,
    showRemoveButton = true,
    removeButtonDisabled = false,
    hasCustomClass,
  }: ViewControlsProps) => {
    const classes = useStyles();

    return (
      <>
        <Grid item>
          {showRemoveButton && (
            <Button
              size="medium"
              variant="outlined"
              color="primary"
              startIcon={<Delete />}
              testId="delete-borrower"
              onClick={onDeleteClick}
              className={classes.deleteButton}
              disabled={removeButtonDisabled}
            >
              REMOVE
            </Button>
          )}
        </Grid>
        <Grid item>
          <Button
            size="medium"
            variant="outlined"
            color="primary"
            startIcon={<Edit />}
            className={hasCustomClass ? classes.beigeButtonColor : ''}
            testId="edit-borrower"
            onClick={onEditClick}
          >
            EDIT
          </Button>
        </Grid>
      </>
    );
  }
);
