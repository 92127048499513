import { observer } from 'mobx-react-lite';
import { useStore } from '@roc/client-portal-shared/hooks';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { NotYetContactedLeadsDashboardGrid } from '../leads/listViews/notYetContactedLeadsDashboardGrid';
import { ContactedLeadsDashboardGrid } from '../leads/listViews/contactedLeadsDashboardGrid';
import { Box, Grid, Toolbar } from '@material-ui/core';
import { Button, SelectField, StandardDialog } from '@roc/ui';
import { LeadDashboardGrid } from '../leads/listViews/leadDashboardGrid';
import { CreateListModal } from 'apps/crm-portal/src/app/modules/createList/components/createList';
import { useEffect, useState } from 'react';
import { GenericLeadDashboardGrid } from '../leads/listViews/genericLeadDashboardGrid';
import { DashboardAutocompleteField } from './DashboardsAutocompleteField';
import { useCreateListStore } from 'apps/crm-portal/src/app/modules/createList/hooks/useCreateListStore';
import { SendBulkMessagesModal } from 'apps/crm-portal/src/app/modules/ringCentral/sendBulkMessages';
import { IncomingLeadsDashboardGrid } from '../leads/listViews/incomingLeadsDashboardGrid';


const useStyles = makeStyles((theme: Theme) => ({
  header: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  button: {
    margin: '10px',
    minWidth: '150px',
  },
}));


const DEFAULT_OPTIONS = [
  { label: 'All Borrower Leads', value: '0', custom: true },
  { label: 'Not Yet Contacted Borrower Leads', value: '1', custom: true },
  { label: 'Contacted Borrower Leads', value: '2', custom: true, isDivider: true },
];

export const BorrowerLeadsDashboard = observer(() => {

  const classes = useStyles();

  let options = [...DEFAULT_OPTIONS];
  const { salesforceLeadStore, userStore, ringCentralStore } = useStore();
  const [openCreateListModal, setOpenCreateListModal] = useState(false);
  const { createListStore } = useCreateListStore();
  const { createListFormStore } = createListStore;
  const [openSendBulkMessagesModal, setOpenSendBulkMessagesModal] = useState(false);


  useEffect(() => {
    const fetchData = async () => {
      await addOptions();
    }; fetchData();
    salesforceLeadStore.allBorrowerLeadsGridStore.resetAndFetchGridData();
  }, [salesforceLeadStore])

  useEffect(() => {
    ringCentralStore.getAllTemplates();
  }, [])



  const addOptions = async () => {
    try {
      const response = await salesforceLeadStore.getAllLeadsLists();
      const newLists = response?.data?.data;

      if (Array.isArray(newLists)) {
        newLists.forEach(option => {
          options.push({
            label: option.name,
            value: option.dashboardListId,
            custom: false,
          });
        });
        salesforceLeadStore.options = options;
        salesforceLeadStore.filteredOptions = options;
      }
    } catch (error) {
      console.error('An error occurred while fetching list of all leads:', error);
      salesforceLeadStore.globalStore.notificationStore.showErrorNotification({
        message: 'An error occurred while fetching list of all leads'
      });
    }
  };

  const onCreateList = () => {
    setOpenCreateListModal(true);
  }

  const onSendBulkMessages = () => {
    setOpenSendBulkMessagesModal(true);
    ringCentralStore.setRecordType('borrower');
  }

  const closeModal = () => {
    setOpenCreateListModal(false);
    options = [...DEFAULT_OPTIONS];
    addOptions();
    createListFormStore.reset();
    createListStore.removeAllFilters();
    setOpenSendBulkMessagesModal(false);
    ringCentralStore.setMessage('');
    ringCentralStore.resetLists();
    ringCentralStore.setLabel('');
    ringCentralStore.setTemplateValue('');
    ringCentralStore.setTemplateId('');
    ringCentralStore.setTemplateLabel('');
    ringCentralStore.sendBulkSMSFormStore.onFieldChange('message', '');
  };

  const filterOptions = text => {
    salesforceLeadStore.searchText = text;
    if (text === '') {
      salesforceLeadStore.filteredOptions = salesforceLeadStore.options
    } else {
      salesforceLeadStore.filteredOptions = salesforceLeadStore.options.filter(option => option.label.toUpperCase().includes(text.toUpperCase()));
    }

  }

  const CustomToolbar = observer(() => {
    return (
      <Toolbar>
        <Grid container alignItems="center" direction="row" justifyContent="space-between" className={classes.header} >
          {renderSelectField()}
          <Grid item container xs={3} justify="flex-end" spacing={1}>
            <Grid item>
              <Button className={classes.button} variant="contained" size="small" onClick={onCreateList} color="primary" testId="create_new_list">
                Create new list
              </Button>
              {userStore.userInformation.connectedWithRingcentral && <>
                <Button className={classes.button} variant="contained" size="small" onClick={onSendBulkMessages} color="primary" testId="send_bulk_messages">
                  Bulk SMS
                </Button></>}
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
    );
  });

  const renderGrid = () => {
    switch (salesforceLeadStore.option.value) {
      case '0':
        return <LeadDashboardGrid toolbar={<CustomToolbar />} recordType={'Borrower'} />
      case '1':
        return <NotYetContactedLeadsDashboardGrid toolbar={<CustomToolbar />} recordType={'Borrower'} />;
      case '2':
        return <ContactedLeadsDashboardGrid toolbar={<CustomToolbar />} recordType={'Borrower'} />;
      default:
        return <GenericLeadDashboardGrid toolbar={<CustomToolbar />} recordType={'Borrower'} />;
    }
  };

  const renderSelectField = () => (
    <Grid item xs={3}>
      <DashboardAutocompleteField store={salesforceLeadStore} />
    </Grid>
  );

  return (
    <>
      {salesforceLeadStore.options && renderGrid()}
      <StandardDialog open={openCreateListModal} title="Create List" maxWidth="md" handleClose={closeModal} dialogContent={<CreateListModal handleClose={closeModal} />} />
      {/* <StandardDialog open={openSendBulkMessagesModal} title="Send Bulk Messages" maxWidth="md" handleClose={closeModal} dialogContent={<SendBulkMessagesModal handleClose={closeModal}/>} /> */}
    </>
  );
});