import { FormStore, GlobalStore } from '@roc/feature-app-core';
import {
  DateFormat,
  formatDate,
  GENERIC_ERROR_MESSAGE, parseDate
} from '@roc/feature-utils';
import { DialogState } from '@roc/ui';
import { action, flow, makeObservable, observable, override } from 'mobx';
import { LeadService } from '../../services/leadService';
import { LeadDetailsStore } from './leadDetailsStore';
import { LEAD_OBJECT_TYPE } from '../../components/leads/constants/constants';
import { TaskStatus } from 'libs/feature-general-tasks/src/generalTasks/types/generalTasksTypes';

const form = {
  fields: {
    title: {
      value: '',
      error: null,
      rule: 'required',
    },
    dueDate: {
      value: '',
      error: null,
      rule: 'required',
    },
    status: {
      value: TaskStatus.OPEN,
      error: null,
      rule: 'required',
    },
    description: {
      value: '',
      error: null,
      rule: '',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export class EditLeadTaskStore extends FormStore {
  private globalStore: GlobalStore;
  protected leadDetailsStore: LeadDetailsStore;
  protected leadService: LeadService;

  dialogState: DialogState;
  leadTask: any;
  leadTasksForBulk: any;

  constructor(globalStore: GlobalStore, leadDetailsStore: LeadDetailsStore) {
    super(form, globalStore);
    this.globalStore = globalStore;
    this.leadDetailsStore = leadDetailsStore;
    this.leadService = new LeadService();

    makeObservable(this, {
      dialogState: observable,
      leadTask: observable,
      leadTasksForBulk: observable,
      reset: override,
      openAddLeadTask: flow,
      openAddTaskFromContactDetails: flow,
      openAddLeadTaskFromDashboard: flow,
      openEditLeadTask: flow,
      closeLeadTaskDialog: flow,
      saveLeadTask: flow,
      saveLeadTasks: flow,
      removeLeadTask: flow,
      setRecordsFromGrid: action,
      openAddLeadTasksForBulk: flow,
    });
  }

  reset() {
    super.reset();
    this.leadTask = null;
  }

  *openEditLeadTask(leadTask) {
    this.reset();
    this.leadTask = leadTask;
    this.loadForm({
      ...leadTask,
      activityTime: parseDate(leadTask.sentDate)
    });
    this.dialogState = DialogState.EDIT;
  }

  *openAddLeadTask() {
    this.reset();
    this.initializeLeadTask(this.leadDetailsStore.objectId, this.leadDetailsStore.type);
    this.loadForm({
      activityTime: new Date(),
    });
    this.dialogState = DialogState.ADD;
  }

  *openAddTaskFromContactDetails(borrowerId: number, companyContactId: number) {
    this.reset();
    this.initializeLeadTask(this.leadDetailsStore.objectId, this.leadDetailsStore.type, borrowerId, companyContactId);
    this.loadForm({
      activityTime: new Date(),
    });
    this.dialogState = DialogState.ADD;
  }

  *openAddLeadTaskFromDashboard(objectId: number, type: string) {
    this.reset();
    this.initializeLeadTask(objectId, type);
    this.loadForm({
      activityTime: new Date(),
    });
    this.dialogState = DialogState.ADD;
  }

  *openAddLeadTasksForBulk(type: string) {
    this.reset();
    this.loadForm({
      activityTime: new Date(),
    });
    this.dialogState = DialogState.ADD;
  }


  *closeLeadTaskDialog() {
    this.dialogState = null;
  }

  *saveLeadTask(businessUnit: string) {
    try {
      if (!this.runFormValidationWithMessage()) {
        return;
      }
      if (this.leadTasksForBulk) {
        this.saveLeadTasks(businessUnit);
        return;
      }
      const values = this.getFormValues();
      const leadTask = {
        ...this.leadTask,
        dueDate: formatDate(values.dueDate, DateFormat.MMDDYYYY),
        description: values.description,
        title: values.title,
        status: values.status,
      };

      yield this.leadService.saveLeadTask(leadTask);
      yield this.closeLeadTaskDialog();
      if (this.leadDetailsStore.objectId) {
        yield this.leadDetailsStore.fetchLeadTasks(businessUnit);
      }
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Task saved successfully!',
      });
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE,
      });
    }
  }

  *saveLeadTasks(businessUnit: string) {
    try {
      if (!this.runFormValidationWithMessage()) {
        return;
      }
      const values = this.getFormValues();
      const leadTasks = this.leadTasksForBulk.map((leadTask) => ({
        ...leadTask,
        dueDate: formatDate(values.dueDate, DateFormat.MMDDYYYY),
        description: values.description,
        title: values.title,
        status: values.status,
      }));

      yield this.leadService.saveLeadTasksBulk(leadTasks);
      yield this.closeLeadTaskDialog();
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Tasks saved successfully!',
      });
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE,
      });
    }
  }

  *removeLeadTask(leadActivity, businessUnit: string) {
    try {
      yield this.leadService.deleteLeadActivity(leadActivity.leadActivityId);
      yield this.closeLeadTaskDialog();
      yield this.leadDetailsStore.fetchLeadTasks(businessUnit);
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Activity removed successfully!',
      });
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE,
      });
    }
  }

  private initializeLeadTask(objectId: number, type: string, borrowerId?: number, companyContactId?: number) {
    this.leadTask = {
      leadId: type === LEAD_OBJECT_TYPE ? objectId : null,
      contactId: type !== LEAD_OBJECT_TYPE ? objectId : null,
      borrowerId,
      companyContactId
    };
  }

  setRecordsFromGrid(objectIds: any[], type: string) {
    this.leadTasksForBulk = objectIds.map((objectId, index) => ({
      leadId: type === LEAD_OBJECT_TYPE ? objectId?.leadId : null,
      contactId: type !== LEAD_OBJECT_TYPE ? objectId?.contactId : null,
    }));
  }

}
