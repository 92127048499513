import {
  Badge,
  Box,
  Chip,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { ImportExport, MoreVert, PriorityHigh } from '@material-ui/icons';
import {
  GridStore,
  InfiniteScrollGrid
} from '@roc/feature-app-core';
import { bridgeLoanSubTypes, PORTAL_COLORS } from '@roc/feature-utils';
import {
  ConfirmationMenuItem, LinkColumn, SelectField, SelectFilterComponent,
  SelectFloatingFilterComponent, StatusChip,
  StatusType, SubmitMenuItem, TextColumn, FastForwardIcon, tooltipHeaderTemplate
} from '@roc/ui';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useLoanRoutes } from '@roc/feature-loans-routes-config';
import { useBackOfficeLoansStore } from '../../hooks/useBackOfficeLoansStore';
import { LoanPriority, loanPriorityOptions } from '@roc/feature-loans';
import { ChangeLoanStatusForm } from 'libs/feature-loans/src/loans/components/changeLoanStatusForm';
import { useUserStore } from '@roc/feature-app-core';
import { formatCurrency } from '@roc/ui/utils';
import { GridColumnProps } from '@roc/client-portal-shared/components';
import { tooltips } from '@roc/feature-loans';
import { useLoanStore } from '@roc/feature-loans';
import { useRoutes } from '../../hooks/useRoutes';
import { Utility } from '@roc/feature-utils';
import { useProposalsRoutes } from '@roc/feature-proposals';

const usdAmount: any = params => {
  return params.value && formatCurrency(Number(params.value));
};
const noOpComparator = () => 0;
export const StyledBadge = withStyles(theme => ({
  badge: {
    right: -12,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
    fontSize: '11px',
    backgroundColor: PORTAL_COLORS.unreadMessages,
    color: 'white',
    fontWeight: 700,
    userSelect: 'none',
  },
}))(Badge);
export const getUnreadMessagesCount = unreadMessages => {
  const { userStore } = useUserStore();
  const allowLoanPricing = userStore.allowLoanPricing;
  if (unreadMessages && allowLoanPricing) {
    return unreadMessages > 99 ? '99+' : unreadMessages;
  }
  return undefined;
};


export interface MyLoansBackOfficeGridProps {
  store: GridStore;
  openMenu?: (event: React.MouseEvent<HTMLElement>, record: any) => void;
}

export const MyLoansBackOfficeGrid = observer((props: MyLoansBackOfficeGridProps) => {
  const { loanStore } = useLoanStore();
  const { backOfficeLoansStore } = useBackOfficeLoansStore();
  const { proposalsRoutesConfig } = useProposalsRoutes();
  const { loanRoutesConfig } = useRoutes();
  const [loanId, setLoanId] = useState(0);
  const [anchorEl, setAnchorEl] = useState<HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const [showMarkAsHighPriority, setShowMarkAsHighPriority] = useState(true);
  const [showChangePriority, setShowChangePriority] = useState(false);
  const [
    selectPriorityValue,
    setSelectPriorityValue,
  ] = useState<LoanPriority>();
  const { userStore } = useUserStore();
  const allowLoanPricing = userStore.allowLoanPricing;
  const frameworkComponents = {
    selectFilter: SelectFilterComponent,
    selectFloatingFilter: SelectFloatingFilterComponent,
    loanIdCellRenderer: params =>
      loanStore.isLoanSubtypeSupported(params.node.data?.loanSubType) ? (
        <StyledBadge
          badgeContent={getUnreadMessagesCount(
            params.node.data?.unreadMessages
          )}
        >
          {LinkColumn({
            ...params,
            url: getUrl(params.node.data),
          })}
        </StyledBadge>
      ) : (
        <StyledBadge
          badgeContent={getUnreadMessagesCount(
            params.node.data?.unreadMessages
          )}
        >
          {TextColumn(params)}
        </StyledBadge>
      ),
    pendingDocumentsCellRenderer: ({ value }) => {
      if (!value) {
        return null;
      }
      return (
        <Box pt={1} display="flex" justifyContent="center">
          <Chip
            size="small"
            label={value}
            style={{
              backgroundColor: PORTAL_COLORS.pendingDocuments,
              color: 'white',
              fontWeight: 700,
            }}
          />
        </Box>
      );
    },
    fastTrackCellRenderer: ({ value }) => {
      if (!value) {
        return null;
      }
      return (<FastForwardIcon value={value} />)
    },
    appraisalReportUploadedCellRenderer: ({ value }) => {
      if (!value) {
        return null;
      }
      return (<Chip
        size="small"
        label={value ? "Yes" : "No"}
        style={{
          backgroundColor: '#89C053',
          color: 'white',
          fontWeight: 700,
        }}
      />)
    },
    dealNameCellRenderer: params => {
      const loanId = params.node.data?.dealid;
      return LinkColumn({
        ...params,
        url: getUrl(params.node.data),
      });
    },
    statusCellRenderer: ({ value }) =>
      value ? (
        <StatusChip label={value} size="small" statusType={StatusType.LOAN} />
      ) : null,
    actionCellRenderer: params => {
      return (
        <IconButton onClick={e => openMenu(e, params.node.data)}>
          <MoreVert color="primary" />
        </IconButton>
      );
    },
  };

  const isDealRoom = (data) => {
    return Utility.PROPOSAL_INFORMATION === data?.utility;
  }

  const getUrl = (row) => {
    const loanId = row?.dealid;
    return !isDealRoom(row)
      ? loanRoutesConfig.loans(loanId).children.dashboard.url
      : proposalsRoutesConfig.viewProposal(loanId).url;
  }

  useEffect(() => {
    props.store.resetAndFetchGridData();
  }, [loanStore.selectedTabIndex]);

  const openMenu = (event: React.MouseEvent<HTMLElement>, record) => {
    const {
      dealid,
      priority,
      loanSubType,
      keyDocumentsCompletedForSFRLoans,
    } = record;
    const isBridgeLoan = bridgeLoanSubTypes.includes(loanSubType);
    setLoanId(dealid);
    setSelectPriorityValue(priority);
    if (isBridgeLoan) {
      setShowChangePriority(true);
      setShowMarkAsHighPriority(false);
    } else {
      setShowChangePriority(false);
      setShowMarkAsHighPriority(
        priority?.toLowerCase() === 'low' && keyDocumentsCompletedForSFRLoans
      );
    }
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const checkLoanPricingFlag = (columns) => {
    if (!allowLoanPricing) {
      return columns.filter(c => c.field != "interestEconomics" && c.field != "keyRatios");
    } else {
      return columns;
    }
  }

  const columns: GridColumnProps[] = [
    {
      field: 'dealid',
      headerName: 'Loan Id',
      maxWidth: 110,
      cellRenderer: 'loanIdCellRenderer',
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      pinned: 'left',
      lockPinned: true,
    },
    {
      field: 'underwritingPendingDocuments',
      headerName: 'Underwriting Pending Documents',
      minWidth: 70,
      maxWidth: 320,
      cellRenderer: 'pendingDocumentsCellRenderer',
      comparator: noOpComparator,
    },
    {
      field: 'closingPendingDocuments',
      headerName: 'Closing Pending Documents',
      minWidth: 70,
      maxWidth: 300,
      cellRenderer: 'pendingDocumentsCellRenderer',
      comparator: noOpComparator,
    },
    {
      field: 'dealName',
      headerName: 'Deal Name',
      minWidth: 300,
      cellRenderer: 'dealNameCellRenderer',
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      minWidth: 170,
      cellRenderer: 'statusCellRenderer',
      comparator: noOpComparator,
      filter: 'selectFilter',
      floatingFilter: true,
      floatingFilterComponent: 'selectFloatingFilter',
      floatingFilterComponentParams: {
        suppressFilterButton: true,
        options: [
          { label: 'In Underwriting', value: 'IN_UNDERWRITING' },
          { label: 'Agreed', value: 'AGREED' },
          { label: 'Pending', value: 'PENDING' },
          { label: 'Pending Decision', value: 'PENDING_DECISION' },
          { label: 'Pending Term Sheet', value: 'PENDING_TERM_SHEET' },
          { label: 'Prescreen', value: 'PRESCREEN' },
          { label: 'Term Sheet Out', value: 'TERM_SHEET_OUT' },
          { label: 'Unknown', value: 'UNKNOWN' },
          { label: 'Pre-Submission', value: 'PENDING_SUBMISSION' },
          { label: 'Deal Room', value: 'DEAL_ROOM' },
        ],
      },
    },
    {
      field: 'loanSubType',
      headerName: 'Loan Type',
      minWidth: 200,
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    {
      field: 'amount',
      headerName: 'Amount',
      minWidth: 125,
      valueFormatter: usdAmount,
      comparator: noOpComparator,
    },
    {
      field: 'lender',
      headerName: 'Lender',
      minWidth: 200,
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    {
      field: 'relationshipManager',
      headerName: 'Relationship Manager',
      minWidth: 300,
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    {
      field: 'fastrackPaymentComplete',
      headerName: 'Fast Tracked Loan',
      minWidth: 125,
      cellRenderer: 'fastTrackCellRenderer',
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    {
      field: 'appraisalReviewStatus',
      headerName: 'Appraisal Status',
      minWidth: 170,
      cellRenderer: 'statusCellRenderer',
      comparator: noOpComparator,
      filter: 'selectFilter',
      floatingFilter: true,
      floatingFilterComponent: 'selectFloatingFilter',
      floatingFilterComponentParams: {
        suppressFilterButton: true,
        options: [
          { label: 'Pending', value: 'Pending' },
          { label: 'Ordered', value: 'Ordered' },
          { label: 'In Review', value: 'In Review' },
          { label: 'Revision Requested', value: 'Revision Requested' },
          { label: 'Review Completed', value: 'Review Completed' },
        ]
      },
    },
    {
      field: 'interestEconomics',
      headerName: 'Interest Economics',
      minWidth: 200,
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      headerTooltip: tooltips.interestEconomics,
      headerComponentParams: {
        template: tooltipHeaderTemplate
      },
    },
    {
      field: 'keyRatios',
      headerName: 'Key Ratios',
      minWidth: 200,
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      headerTooltip: tooltips.keyRatios,
      headerComponentParams: {
        template: tooltipHeaderTemplate
      },
    },
    {
      field: 'submittedBy',
      headerName: 'Submitted By',
      minWidth: 300,
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    {
      field: 'coordinator',
      headerName: 'Processor',
      minWidth: 300,
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    {
      field: 'underwriter',
      headerName: 'Underwriter',
      minWidth: 300,
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    {
      field: 'priority',
      headerName: 'Priority',
      minWidth: 100,
      comparator: noOpComparator,
      filter: 'selectFilter',
      floatingFilter: true,
      floatingFilterComponent: 'selectFloatingFilter',
      floatingFilterComponentParams: {
        suppressFilterButton: true,
        options: loanPriorityOptions,
      },
    },
    {
      field: 'lenderOriginatorName',
      headerName: 'Originator',
      minWidth: 300,
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      }
    },
    {
      field: 'submissionDate',
      headerName: 'Submission Date',
      minWidth: 180,
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      }
    },
    {
      headerName: 'Action',
      minWidth: 80,
      cellRenderer: 'actionCellRenderer',
      comparator: noOpComparator,
    },
  ];

  return (
    <>
      <InfiniteScrollGrid
        columns={checkLoanPricingFlag(columns)}
        frameworkComponents={frameworkComponents}
        store={props.store}
      />
      <Menu
        id="menu-appbar"
        getContentAnchorEl={null}
        anchorEl={anchorEl}
        keepMounted
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={menuOpen}
        onClose={handleMenuClose}
      >
        {showChangePriority && (
          <SubmitMenuItem
            testId={`select-priority-${loanId}`}
            onClick={() => {
              if (selectPriorityValue) {
                loanStore.changeLoanPriority(loanId, selectPriorityValue);
                handleMenuClose();
              }
            }}
            submitDialogProps={{
              title: `Loan ${loanId}`,
              body: (
                <SelectField
                  standaloneLabel
                  label={'Please set the urgency of the deal:'}
                  value={selectPriorityValue}
                  required={true}
                  options={loanPriorityOptions}
                  onChange={value => setSelectPriorityValue(value)}
                  variant={'outlined'}
                  fullWidth
                  testId={'select-priority-dropdown'}
                />
              ),
              okButtonText: 'Submit',
              cancelButtonText: 'Close',
            }}
          >
            <ListItemIcon>
              <PriorityHigh fontSize="small" />
            </ListItemIcon>
            <ListItemText>Change Priority</ListItemText>
          </SubmitMenuItem>
        )}
        {showMarkAsHighPriority && (
          <ConfirmationMenuItem
            testId={`mark-as-high-${loanId}`}
            onClick={() => {
              loanStore.changeLoanPriority(loanId, LoanPriority.HIGH);
              handleMenuClose();
            }}
            confirmDialogProps={{
              body: `Are you sure you want to mark loan ${loanId} as high priority?`,
            }}
          >
            <ListItemIcon>
              <PriorityHigh fontSize="small" />
            </ListItemIcon>
            <ListItemText>Mark as High Priority</ListItemText>
          </ConfirmationMenuItem>
        )}
        <SubmitMenuItem
          testId={`change-loan-status-${loanId}`}
          onClick={() => {
            loanStore.submitFormStatus(loanId);
            handleMenuClose();
          }}
          submitDialogProps={{
            title: `Change Status for Loan ${loanId}`,
            body: <ChangeLoanStatusForm loanId={loanId} />,
            okButtonText: 'Submit',
            submitValidation: () => {
              loanStore.loanStatusStore.runFormValidation();
              return loanStore.loanStatusStore.form.meta.isValid;
            },
          }}
        >
          <ListItemIcon>
            <ImportExport fontSize="small" />
          </ListItemIcon>
          <ListItemText>Change Status</ListItemText>
        </SubmitMenuItem>
      </Menu>
    </>
  );
});