import {
  AnchorColumn, DataGrid as Grid,

  GridColumnProps
} from '@roc/ui';
import { observer } from 'mobx-react';
import React from 'react';
import { useFindAppraiserStore } from '../../hooks';

const columns: GridColumnProps[] = [
  {
    field: 'name',
    headerName: 'Appraiser',
    minWidth: 300,
  },
  {
    field: 'email',
    headerName: 'Email',
    minWidth: 300,
    cellRenderer: 'emailCellRenderer',
  },
  {
    field: 'phone',
    headerName: 'Phone',
    minWidth: 150,
    maxWidth: 200,
    cellRenderer: 'phoneCellRenderer',
  },
  {
    field: 'companyName',
    headerName: 'Firm Name',
    minWidth: 300,
  },
  {
    field: 'county',
    headerName: 'County',
    minWidth: 300,
  },
];

export const AppraisersGrid = observer(() => {
  const { findAppraiserStore } = useFindAppraiserStore();

  const formatPhoneNumber = (phoneNumber: string) => {
    if (!phoneNumber) return '';
    return phoneNumber.match(/\d+/g).join('');
  };

  const frameworkComponents = {
    emailCellRenderer: params =>
      AnchorColumn({
        ...params,
        href: `mailto:${params.value}`,
      }),
    phoneCellRenderer: params =>
      AnchorColumn({
        ...params,
        href: `tel:${formatPhoneNumber(params.value)}`,
      }),
  };

  return (
    <Grid
      columns={columns}
      rows={findAppraiserStore.appraisers}
      frameworkComponents={frameworkComponents}
      domLayout="autoHeight"
    />
  );
});
