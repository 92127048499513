import { Grid, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import { Button, DateField } from '@roc/ui';
import { isValid } from 'date-fns';
import { usePayoffRequestStore } from '../hooks/usePayoffRequestStore';
import { useEffect } from 'react';
import { CurrencyField } from '@roc/ui';


const useStyles = makeStyles(() => ({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginLeft: 24,
  },
}));

interface loanPayoffDetailsFormProps {
  onSubmit: () => void;
  onClose: () => void;
}

const LoanPayoffDetailsForm = observer(
  ({ onClose, onSubmit }: loanPayoffDetailsFormProps) => {

    const classes = useStyles();
    const { payoffRequestStore } = usePayoffRequestStore();
    const { loanPayoffForm } = payoffRequestStore;

    const manageDate = date => {
      const selectedDate = isValid(date) ? date : null;
      payoffRequestStore.loanPayoffForm.onFieldChange('expectedPayoffDate', selectedDate);
    };
    const isForeclosurePayoffRequest = payoffRequestStore.isForeclosure;


    useEffect(() => {
      payoffRequestStore.initPayoffDetails();
      return () => {
        loanPayoffForm.reset();
      };
    }, []);


    return (

      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <DateField
            testId="expectedPayoffDate"
            inputVariant="outlined"
            format="MM/dd/yyyy"
            standaloneLabel
            label={isForeclosurePayoffRequest ? "Settlement  Date" : "Payoff Date"}
            value={loanPayoffForm?.form?.fields?.expectedPayoffDate?.value}
            onChange={date => manageDate(date)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <CurrencyField
            testId={'totalInterestReceived'}
            variant={'outlined'}
            label={isForeclosurePayoffRequest ? "Settlement Amount Received" : 'Total Interest Amount Received'}
            standaloneLabel
            value={loanPayoffForm.form.fields?.totalInterestReceived?.value}
            onChange={(name, value) => payoffRequestStore.loanPayoffForm.onFieldChange('totalInterestReceived', value)}
            disabled={false}
            fullWidth
          />
        </Grid>
        {!isForeclosurePayoffRequest && (
          <>
            <Grid item xs={12} sm={12}>
              <CurrencyField
                testId={'totalProceeds'}
                variant={'outlined'}
                label={'Total Proceeds'}
                standaloneLabel
                value={loanPayoffForm.form.fields?.totalProceeds?.value}
                onChange={(name, value) => payoffRequestStore.loanPayoffForm.onFieldChange('totalProceeds', value)}
                disabled={false}
                fullWidth

              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <CurrencyField
                testId={'refundToBorrower'}
                variant={'outlined'}
                label={'Refund To Borrower'}
                standaloneLabel
                value={loanPayoffForm.form.fields?.refundToBorrower?.value}
                onChange={(name, value) => payoffRequestStore.loanPayoffForm.onFieldChange('refundToBorrower', value)}
                disabled={false}
                fullWidth

              />
            </Grid>
          </>
        )}

        <Grid item container className={classes.buttonContainer}>
          <Box mr={2}>
            <Button
              className={classes.button}
              size="small"
              variant="contained"
              color="secondary"
              onClick={onClose}
              testId="cancel"
            >
              CANCEL
            </Button>

            <Button
              disabled={false}
              className={classes.button}
              size="small"
              variant="contained"
              color="primary"
              onClick={onSubmit}
              testId="save"
            >
              SUBMIT
            </Button>
          </Box>
        </Grid>
      </Grid>
    );
  }
);

export default LoanPayoffDetailsForm;
