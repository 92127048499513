import { Box, Grid, Link, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { useAppConfigurations } from '@roc/feature-app-core';
import { isHausUrl, isLocalHost, isPrivateLenderPortalUrl, isFacoUrl, isCivicUrl } from '@roc/feature-utils';
import { observer } from 'mobx-react';
import { useEffect } from 'react';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(1),
    margin: theme.spacing(0.5, 4, 1, 4),
    borderTop: '1px solid #ddd',
    color: '#aaa',
    fontSize: 12,
  },
  containerfullWidth: {
    paddingTop: theme.spacing(1),
    margin: theme.spacing(0.5, 4, 1, 4),
    borderTop: '1px solid #ddd',
    color: '#aaa',
    fontSize: 10,
  },
  copyrightNote: {
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      textAlign: 'left',
    },
  },
  copyrightNoteFullWidth: {
    textAlign: 'center',
  },
  policyLinks: {
    '& a': {
      color: '#aaa',
    },
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      textAlign: 'right',
    },
  },
  policyLinksFullWitdh: {
    '& a': {
      color: '#aaa',
    },
    textAlign: 'center',
  }
}));

const EmbedPolicyLink = ({ text, link }) => {
  return (
    <a href={link}
      className="iubenda-nostyle no-brand iubenda-noiframe iubenda-embed iubenda-noiframe"
      title={text}>
      {text}
    </a>
  )
}

const PolicyLinks_IubendaIframeModal = () => {
  return (
    <>
      <EmbedPolicyLink text="Privacy Policy" link="https://www.iubenda.com/privacy-policy/16493128" />
      <span> | </span>
      <EmbedPolicyLink text="Cookie Policy" link="https://www.iubenda.com/privacy-policy/16493128/cookie-policy" />
      <span> | </span>
      <EmbedPolicyLink text="Terms & Conditions" link="https://www.iubenda.com/terms-and-conditions/16493128" />
    </>
  )
}

const PolicyLinks_SelfHostedUrl = () => {
  return (
    <>
      <a href="/privacy-policy" target="_blank" style={{ margin: '0 8px' }}>
        Privacy Policy
      </a>
      <span> | </span>
      <a href="/cookie-policy" target="_blank" style={{ margin: '0 8px' }}>
        Cookie Policy
      </a>
      <span> | </span>
      <a href="/terms-and-conditions" target="_blank" style={{ margin: '0 8px' }}>
        Terms & Conditions
      </a>
    </>
  )
}

export interface FooterProps {
  isFullwidth?: boolean;
}

export const Footer = observer(({ isFullwidth = false }: FooterProps) => {

  const classes = useStyles();
  const { siteName } = useAppConfigurations();
  const theme = useTheme();
  const isLargerScreen = useMediaQuery(theme.breakpoints.up('md'));

  useEffect(() => {
    const scriptTag = document.createElement('script');
    scriptTag.src = 'https://cdn.iubenda.com/iubenda.js';
    document.body.appendChild(scriptTag);
  }, []);

  return (
    (isLocalHost() || isHausUrl() || isPrivateLenderPortalUrl() || isFacoUrl() || isCivicUrl()) ?
      <Box className={isFullwidth ? classes.containerfullWidth : classes.container}>
        <Grid container justifyContent={isLargerScreen ? 'space-between' : 'space-around'}>
          <Grid item xs={12} md={isFullwidth ? 12 : 6} className={isFullwidth ? classes.copyrightNoteFullWidth : classes.copyrightNote}>
            Copyright &copy; {new Date().getFullYear()}. All Rights Reserved.
          </Grid>
          <Grid item xs={12} md={isFullwidth ? 12 : 6} className={isFullwidth ? classes.policyLinksFullWitdh : classes.policyLinks}>
            <PolicyLinks_SelfHostedUrl />
          </Grid>
        </Grid>
      </Box> : null
  );

});