import { Page } from '@roc/feature-app-core';
import { Route } from 'react-router';
import { Borrowers } from '../borrowers';

export const getBorrowersRoutesConfig = (basePath: string, baseUrl: string) => {
  return {
    borrowers: {
      path: `${basePath}/borrowers`,
      url: `${baseUrl}/borrowers`,
      documentTitle: 'View Borrowers',
    }
  };
};

export const getBorrowersRoutes = (basePath: string, baseUrl: string) => {
  const config = getBorrowersRoutesConfig(basePath, baseUrl);
  return {
    borrowers: (
      <Route exact path={config.borrowers.path}>
        <Page routeDefinition={config.borrowers}>
          <Borrowers />
        </Page>
      </Route>
    )
  };
};
