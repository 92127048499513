import { FormStore } from '@roc/feature-app-core';
import { GlobalStore } from '@roc/feature-app-core';
import { action, makeObservable, observable, override } from 'mobx';
import { OneToolService } from '../../services/oneToolService';
import { formatDate } from '@roc/feature-utils';

const REQUIRED_MESSAGE = 'This field is required.';

const form = {
  fields: {
    name: {
      value: '',
      error: null,
      rule: 'required',
      message: REQUIRED_MESSAGE,
    },
    contactName: {
      value: '',
      error: null,
      rule: 'required',
      message: REQUIRED_MESSAGE,
    },
    cellPhone: {
      value: '',
      error: null,
      rule: 'required|regex:/^\\d{3}-?\\d{3}-?\\d{4}$/|alpha_dash',
      message: 'Phone must be a number with the format xxx-xxx-xxxx.',
    },
    emailAddress: {
      value: '',
      error: null,
      rule: 'required|email',
      attribute: 'email address',
    },
    address: {
      value: null,
      error: null,
      rule: [{ required_if_true: ['isPreflight', 'hasNewAddress'] }],
      message: REQUIRED_MESSAGE,
    },
  },
  meta: {
    key: null,
    editMode: false,
    isValid: false,
    error: null,
  },
};
export class TitleFormStore extends FormStore {
  private globalStore: GlobalStore;

  showTitleDialog: boolean;

  constructor(globalStore) {
    super({ ...form }, globalStore);
    this.globalStore = globalStore;
    makeObservable(this, {
      showTitleDialog: observable,
      reset: override,
      openDialog: action,
      closeDialog: action,
      saveTitle: action,
    });
  }

  reset() {
    super.reset();
    this.showTitleDialog = false;
  }

  openDialog() {
    this.showTitleDialog = true;
  }
  closeDialog() {
    this.showTitleDialog = false;
  }

  saveTitle() {
    if (this.runFormValidationWithMessage()) {
      this.showTitleDialog = false;
    }
  }
}
