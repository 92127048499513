import { Divider, Grid, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import { BorrowerForm } from './borrowerForm';
import { NumberFormat, TextField } from '@roc/ui';
import { PersonIcon } from './personIcon';
import { makeStyles } from '@material-ui/core/styles';
import { BorrowerFormRental } from './borrowerFormRental';
import { useBorrowerLoanSubmissionStore } from '../../hooks/useBorrowerLoanSubmissionStore';

const useStyles = makeStyles(theme => ({
  subTitle: {
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '133.4%',
  },
  body: {
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '175%',
    letterSpacing: '0.15px',
  },
}));

export const PersonalInformation = observer(({ store, rental = false }) => {
  const classes = useStyles();
  const { borrowerLoanSubmissionStore } = useBorrowerLoanSubmissionStore();
  const { isPublic } = borrowerLoanSubmissionStore;

  return (
    <Grid container direction="row" spacing={2}>
      <Grid
        item
        xs={12}
        container
        direction="row"
        spacing={2}
        alignItems="center"
      >
        <Grid item>
          <PersonIcon />
        </Grid>
        <Grid item>
          <Typography variant="h5" className={classes.subTitle}>
            Personal Information
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5" className={classes.body}>
          Please fill out your information.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <BorrowerForm store={store.mainBorrowerFormStore} rental={rental} mainBorrower readOnly={!isPublic} />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <NumberFormat
          customInput={TextField}
          required={true}
          variant="outlined"
          standaloneLabel
          label="How many additional co-borrowers will there be in this loan?"
          testId="coBorrowersCount"
          value={store.coBorrowersCount}
          onChange={e => store.setCoBorrowersCount(e.target.value)}
          fullWidth
        />
      </Grid>
    </Grid>
  );
});
