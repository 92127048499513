import { Box, Grid, Typography } from '@material-ui/core';
import { useUserStore } from '@roc/feature-app-core';
import { Button, StandardDialog } from '@roc/ui';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { CommunicationStore } from '../stores/communicationStore';
import { UserOption } from '../types/communicationTypes';
import { SelectUserAutocompleteVirtualized } from './selectUserAutocomplete';

export const StartPrivateConversationDialog = observer(({ communicationStore, onSuccessfulCreation }: {
  communicationStore: CommunicationStore,
  onSuccessfulCreation?: (conversationSid: string) => void;
}) => {
  const { startPrivateConversationStore } = communicationStore;
  const { userStore } = useUserStore();
  useEffect(() => {
    if (startPrivateConversationStore.open) {
      startPrivateConversationStore.getUsers(userStore.userInformation.companyId ?? userStore?.globalStore?.selectedCompanyId);
    }
  }, [startPrivateConversationStore.open]);
  return (
    <StandardDialog
      maxWidth={'sm'}
      title={'Start private conversation'}
      open={startPrivateConversationStore.open}
      handleClose={() => startPrivateConversationStore.closeDialog()}
      removeContentBox={true}
      dialogContent={
        <Box flexGrow={1} px={0} pt={1} pb={3}>
          <Grid
            container
            spacing={2}>
            <Grid item container xs={12}>
              <Grid item xs={12}>
                <Typography variant={'h6'}>
                  Select User
                </Typography>
              </Grid>
            </Grid>
            <Grid item container xs={12}>
              <Grid item xs={12}>
                <SelectUserAutocompleteVirtualized
                  label={""}
                  options={startPrivateConversationStore.users}
                  multiple={false}
                  showEmailAddress={false}
                  onSelectExistingUser={(userOption: UserOption) => startPrivateConversationStore.setSelectedUser(userOption)}
                />
              </Grid>
            </Grid>
            <Grid item container justifyContent='center'>
              <Grid item style={{
                marginTop: 24
              }}>
                <Button
                  color="primary"
                  onClick={() => startPrivateConversationStore.startPrivateConversation(onSuccessfulCreation)}
                  disabled={!startPrivateConversationStore?.selectedUser}
                  variant="contained"
                  fullWidth
                  testId="startConversation"
                >
                  Start Conversation
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      }
    />
  );
});