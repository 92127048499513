import { ApiResponse } from "@roc/feature-app-core";
import { flow, makeObservable } from "mobx";
import { ChatRoomType, ConversationAttribute, ResetUnreadCountRequest } from "../types/communicationTypes";
import { CommunicationStore } from "./communicationStore";


export class LoanCommunicationStore extends CommunicationStore {

  constructor(globalStore, userStore, communicationService, isInternal = false) {
    super(globalStore, userStore, communicationService, isInternal, false, false, true,
      []);
    this.preserveUnreadCount = true;
    makeObservable(this, {
      fetchUnreadMessagesCount: flow,
      resetUnreadCount: flow,
      updateConversationStarredStatus: flow,
      getParticipantsAllowedByLoanId: flow,
      getParticipantsAllowedByChatRoomId: flow,
      setConversationMessageDefaultTag: flow
    });
  }

  *fetchUnreadMessagesCount(loanId) {
    this.unreadMessagesCount = {};
    const response = yield this.communicationService.getUnreadCountByLoanId(loanId, this.userId);
    const data = response.data.data;
    data.forEach(item => this.unreadMessagesCount[item.conversationSid] = item.unreadCount)
  }

  *resetUnreadCount(loanId: number) {
    try {
      const request: ResetUnreadCountRequest = {
        chatRoomId: this.currentChatRoomId,
        chatRoomType: this.currentChatRoomType,
        userId: this.userId,
        conversationSid: this.currentConversationSid,
        loanId,
      }
      this.communicationService.resetUnreadCount(request);
    } catch (e) {
      // do nothing
    }
  }

  *updateConversationStarredStatus(isStarred: boolean, chatRoomId: number, conversationSid: string, callBack?: () => void) {
    throw Error('Not Implemented!');
  }

  *getParticipantsAllowedByLoanId(loanId: number, disableGlobalLoading = false) {
    try {
      const response: ApiResponse = yield this.communicationService.getParticipantsAllowedByLoanId(loanId, disableGlobalLoading);
      const { data } = response;
      return data.data;
    } catch (error) {
      console.error("Error retrieving Participants")
      return {};
    }
  }

  *getParticipantsAllowedByChatRoomId(id, disableGlobalLoading = false) {
    try {
      const response: ApiResponse = yield this.communicationService.getParticipantsAllowedByChatroomId(id, disableGlobalLoading);
      const { data } = response;
      return data.data;
    } catch (error) {
      console.error("Error retrieving Participants")
      return {};
    }

  }

  *setConversationMessageDefaultTag(conversationAttribute: ConversationAttribute, conversationInfo) {
    if (conversationAttribute?.loanId) {
      const response: ApiResponse = yield this.communicationService.getConversationMessageDefaultTag(conversationAttribute?.loanId, [conversationInfo]);
      if (response?.data?.data) {
        const result = response?.data?.data.result;
        [conversationInfo].forEach(c => {
          if (result[c.conversationSid]) {
            this.conversationMessageDefaultTag[c.conversationSid] = result[c.conversationSid];
          }
        });
      }
    }
  }

}