import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { MyAppraisalTasksBackOfficeGrid } from './myAppraisalTasksBackOfficeGrid';
import { MyGeneralTasks } from '@roc/feature-general-tasks';
import { BackOfficeLoansStore } from '../../stores/backOfficeLoansStore';
import { MY_TASKS_TAB_ACTION_OPTIONS } from './myTasksTabActions';


export interface MyTasksBackOfficeGridProps {
  store: BackOfficeLoansStore;
}


export const MyTasksBackOfficeGrid = observer(({ store }: MyTasksBackOfficeGridProps) => {
  const { appraisalTasksBackOfficeLoansGridStore, tabActionSelection } = store;
  return <>
    {MY_TASKS_TAB_ACTION_OPTIONS[0].value === tabActionSelection && (
      <MyAppraisalTasksBackOfficeGrid store={appraisalTasksBackOfficeLoansGridStore} />
    )}
    {MY_TASKS_TAB_ACTION_OPTIONS[1].value === tabActionSelection && (
      <MyGeneralTasks />
    )}
  </>;
});
