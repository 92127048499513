import { observer } from "mobx-react";
import { Grid } from "@material-ui/core";
import { createAutocompleteAddressField, createPhoneField, createTextField, TextField } from "@roc/ui";
import { TitleAgentOtherFormStore } from "../../../stores/documentForms/titleAgent/titleAgentOtherFormStore";
import { useLoanStore } from "@roc/feature-loans";

interface TitleAgentProps {
  store: TitleAgentOtherFormStore;
  disabled: boolean;
}

const TitleAgentOtherForm = observer((props: TitleAgentProps) => {
  const { store, disabled } = props;
  const { loanStore } = useLoanStore();

  const titleContacted = loanStore.loanDetails?.loanClosingData?.titleContactedDate;

  const handleAddressChange = event => {
    const { value, fullInfo } = event?.target || {};
    const { address_components, geometry } = fullInfo || {};
    store.saveAddressField(value, address_components, geometry);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={4}>
        {createTextField({
          store,
          testId: 'name',
          fieldName: 'name',
          label: 'Name',
          disabled,
        })}
      </Grid>
      <Grid item xs={12} md={4}>
        {createTextField({
          store,
          testId: 'contactName',
          fieldName: 'contactName',
          label: 'Contact Name',
          disabled,
        })}
      </Grid>
      <Grid item xs={12} md={4}>
        {createPhoneField({
          store,
          testId: 'phone',
          fieldName: 'phone',
          label: 'Cellphone Number',
          disabled,
        })}
      </Grid>
      <Grid item xs={12} md={8}>
        {createAutocompleteAddressField({
          store,
          testId: 'address',
          fieldName: 'address',
          label: 'Address',
          disabled,
          onChange: handleAddressChange,
        })}
      </Grid>
      <Grid item xs={12} md={4}>
        {createTextField({
          store,
          testId: 'email',
          fieldName: 'email',
          type: 'email',
          label: 'Email Address',
          disabled,
        })}
      </Grid>
      {titleContacted &&
        <Grid item xs={4} md={4}>
          <TextField
            standaloneLabel={true}
            label="Contacted Date"
            type="text"
            variant="outlined"
            value={titleContacted}
            fullWidth
            testId="titleContactedDate"
            contentEditable={false}
          />
        </Grid>
      }
    </Grid>
  )
})

export default TitleAgentOtherForm;