import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Chip, Grid, Link, Typography } from '@material-ui/core';
import { format, isValid } from 'date-fns';
import { Button, createDateField, createNumberFormatField, createPhoneField, createSelectField, createTextField, Tooltip } from '@roc/ui';
import { citizenshipStatusOptions } from '@roc/feature-utils';
import { Address } from '../address';
import { Help, VerifiedUser } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import { FinanciallInformation } from '../financialInformation';

const useStyles = makeStyles(() => {
  return {
    container: {
      display: 'flex',
      position: 'relative',
      maxWidth: 1300,
      margin: 'auto',
    },
  };
});

export const PublicPersonalInformationWithIdVerification = observer(({ store, showContactDetailsVerification = false }) => {

  const classes = useStyles();

  return (
    <Grid container spacing={2} className={classes.container} alignItems='center'>
      <PublicPersonalDetails store={store.personalInformationFormStore} disableNameFields={store.disableNameFields} />
      <Grid item xs={12} />
      <Address store={store.personalInformationFormStore} />
      <Grid item xs={12} />
      <FinanciallInformation store={store} />
      <Grid item xs={12} />
      {/* <PublicContactDetails store={store.contactDetailsFormStore} showContactDetailsVerification={showContactDetailsVerification} /> */}
    </Grid>
  );
});

const usePersonalDetailsStyles = makeStyles(() => {
  return {
    icon: {
      fontSize: '18px',
      marginLeft: '5px',
      marginBottom: '2px'
    },
    infoBox: {
      width: '100%',
      flexDirection: 'column',
    }
  };
});

export const PublicPersonalDetails = observer(({ store, disableNameFields }) => {

  const classes = usePersonalDetailsStyles();

  const manageDate = (fieldName, date) => {
    let formattedDate = null;
    if (isValid(date)) {
      formattedDate = format(date, 'MM/dd/yyyy');
    }
    store.onFieldChange(fieldName, formattedDate);
  };

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h5">
          Personal Details
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        {createPhoneField({
          label: 'Phone Number',
          fieldName: 'phoneNumber',
          testId: 'phoneNumber',
          store: store,
        })}
      </Grid>
      <Grid item xs={12} sm={6}>
        {createDateField({
          label: 'Date of Birth',
          fieldName: 'dateOfBirth',
          testId: 'dateOfBirth',
          format: 'MM/dd/yyyy',
          store: store,
          disableFuture: true,
          onChange: manageDate,
        })}
      </Grid>
      <Grid item xs={12} sm={6}>
        {createSelectField({
          store: store,
          testId: 'citizenshipStatus',
          fieldName: 'citizenshipStatus',
          label: 'Citizenship Status',
          options: citizenshipStatusOptions,
          placeholder: 'Make Selection',
        })}
      </Grid>
      <Grid item xs={12} sm={6}>
        {createNumberFormatField({
          label: 'Social Security Number',
          fieldName: 'ssn',
          format: "###-##-####",
          testId: 'ssn',
          store: store,
        })}
      </Grid>
    </>
  )
});

const useContactDetailsStyles = makeStyles((theme) => {
  return {
    label: {
      paddingRight: '8px',
    },
    verifiedIcon: {
      color: theme.palette.success.dark,
    },
    verifiedChip: {
      borderColor: theme.palette.success.dark,
      color: theme.palette.success.dark,
    },
    notVerifiedChip: {
      borderColor: theme.palette.error.dark,
      color: theme.palette.error.dark,
    },
    editLabelText: {
      paddingRight: '8px',
      textDecoration: 'underline',
      cursor: 'pointer',
    },
    editLabelLink: {
      paddingRight: '8px'
    },
    valueText: {
      fontWeight: 'bold',
    },
    input: {
      padding: '8px'
    },
  };
});

export const PublicContactDetails = observer(({ store, showContactDetailsVerification }) => {

  const classes = useContactDetailsStyles();

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h5">Contact Details</Typography>
      </Grid>
      <Grid item xs={12} sm={12} container>
        <Grid item>
          <Typography className={classes.label}>Email Address</Typography>
        </Grid>
        {showContactDetailsVerification &&
          <>
            <Typography className={classes.editLabelText}>
              {store?.allowEmailAddressChange ? (
                <>
                  <Link onClick={() => { store.handleCancelEmailAddress() }} className={classes.editLabelLink}>Cancel</Link>
                  <Link onClick={() => { store.handleSaveEmailAddress() }}>Save</Link>
                </>
              ) : (
                <Link onClick={() => { store.handleEditEmailAddress() }}>Edit</Link>
              )}
            </Typography>
            <Grid item className={classes.label}>
              {store.form.fields.emailAddressVerified.value === true ?
                <Chip icon={<VerifiedUser className={classes.verifiedIcon} />} size='small' label='Verified' variant='outlined' className={classes.verifiedChip} />
                :
                <Chip size='small' label='Not Verified' variant='outlined' className={classes.notVerifiedChip} />
              }
            </Grid>
          </>
        }
      </Grid>
      <Grid item xs={12} sm={6}>
        {store?.allowEmailAddressChange ?
          createTextField({
            label: '',
            fieldName: 'emailAddress',
            testId: 'emailAddress',
            store: store,
          })
          :
          createTextField({
            label: '',
            fieldName: 'maskedEmailAddress',
            testId: 'maskedEmailAddress',
            store: store,
            disabled: true,
          })
        }
      </Grid>
      {showContactDetailsVerification && store.form.fields.emailAddressVerified.value === false && store.form.fields.verifyEmailAddress.value === false && !store?.allowEmailAddressChange ?
        <Grid item xs={12} sm={6} container>
          <Grid item xs={12} sm={4}>
            <Button
              variant='contained'
              color='primary'
              fullWidth
              testId='verifyEmailAddress'
              onClick={() => { store.verifyEmailAddress() }}
            >
              Verify
            </Button>
          </Grid>
        </Grid>
        :
        <Grid item xs={12} sm={6} />
      }
      {store.form.fields.emailAddressVerified.value === false && store.form.fields.verifyEmailAddress.value === true &&
        <>
          <Grid item xs={12} sm={2}>
            {createNumberFormatField({
              label: '',
              fieldName: 'emailAddressVerificationCode',
              testId: 'emailAddressVerificationCode',
              store: store,
              format: '#-#-#-#-#-#',
            })}
          </Grid>
          <Grid item xs={12} sm={10} container >
            <Grid item xs={12} sm={2}>
              <Button
                variant='contained'
                color='primary'
                fullWidth
                testId='verifyEmailAddressOtp'
                onClick={() => { store.verifyEmailAddressOtp() }}
              >
                Verify OTP
              </Button>
            </Grid>
          </Grid>
        </>
      }
      <Grid item xs={12} sm={12} container>
        <Grid item>
          <Typography className={classes.label}>Phone Number</Typography>
        </Grid>
        {showContactDetailsVerification &&
          <>
            <Typography className={classes.editLabelText}>
              {store?.allowPhoneNumberChange ? (
                <>
                  <Link onClick={() => { store.handleCancelPhoneNumber() }} className={classes.editLabelLink}>Cancel</Link>
                  <Link onClick={() => { store.handleSavePhoneNumber() }}>Save</Link>
                </>
              ) : (
                <Link onClick={() => { store.handleEditPhoneNumber() }}>Edit</Link>
              )}
            </Typography>
            <Grid item className={classes.label}>
              {store.form.fields.phoneNumberVerified.value === true ?
                <Chip icon={<VerifiedUser className={classes.verifiedIcon} />} size='small' label='Verified' variant='outlined' className={classes.verifiedChip} />
                :
                <Chip size='small' label='Not Verified' variant='outlined' className={classes.notVerifiedChip} />
              }
            </Grid>
          </>
        }
      </Grid>
      <Grid item xs={12} sm={6}>
        {store?.allowPhoneNumberChange ?
          createPhoneField({
            label: '',
            fieldName: 'phoneNumber',
            testId: 'phoneNumber',
            store: store,
          })
          :
          createTextField({
            label: '',
            fieldName: 'maskedPhoneNumber',
            testId: 'maskedPhoneNumber',
            store: store,
            disabled: true,
          })
        }
      </Grid>
      {showContactDetailsVerification && store.form.fields.phoneNumberVerified.value === false && store.form.fields.verifyPhoneNumber.value === false && !store?.allowPhoneNumberChange ?
        <Grid item xs={12} sm={6} container>
          <Grid item xs={12} sm={4}>
            <Button
              variant='contained'
              color='primary'
              fullWidth
              testId='verifyPhoneNumber'
              onClick={() => { store.verifyPhoneNumber() }}
            >
              Verify
            </Button>
          </Grid>
        </Grid>
        :
        <Grid item xs={12} sm={6} />
      }
      {store.form.fields.phoneNumberVerified.value === false && store.form.fields.verifyPhoneNumber.value === true &&
        <>
          <Grid item xs={12} sm={2}>
            {createNumberFormatField({
              label: '',
              fieldName: 'phoneNumberVerificationCode',
              testId: 'phoneNumberVerificationCode',
              store: store,
              format: '#-#-#-#-#-#',
            })}
          </Grid>
          <Grid item xs={12} sm={10} container>
            <Grid item xs={12} sm={2}>
              <Button
                variant='contained'
                color='primary'
                fullWidth
                testId='verifyPhoneNumberOtp'
                onClick={() => { store.verifyPhoneNumberOtp() }}
              >
                Verify OTP
              </Button>
            </Grid>
          </Grid>
        </>
      }
    </>
  )
});