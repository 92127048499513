import { observer } from 'mobx-react';

import {
  Button
} from '@roc/ui';
import { ButtonGroup, darken, Grid, lighten, Typography } from '@material-ui/core';
import { FormatListBulletedOutlined, MapOutlined, TuneOutlined } from '@material-ui/icons';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { useStore } from '@roc/client-portal-shared/hooks';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontSize: '28px',
    },
    subtitle: {
      fontSize: '18px'
    },
    button: {
      textAlign: 'end',
      padding: '10px',
      margin: '10px'
    },
    activeButton: {
      backgroundColor: lighten(theme.palette.primary.main, 0.90),
      '&:hover': {
        backgroundColor: lighten(theme.palette.primary.main, 0.95),
      },
      textAlign: 'end',
      fontSize: 'small',
      padding: '10px',
    },
    notActiveButton: {
      textAlign: 'end',
      padding: '10px',
      fontSize: 'small',
    },
    buttonGroup: {
      margin: '10px'
    }

  })
);

export const CompGridHeader = observer(() => {
  const classes = useStyles();
  const { compToolStore } = useStore();


  const { compResponse } = compToolStore;

  const { properties, pages } = compResponse

  const size = (properties?.length - 1) * pages;

  return (
    <>
      <Grid item xs={12} sm={6} lg={6}>
        <Grid container spacing={2}>
          <Grid item sm={12} lg={12}>
            <Typography align="left" className={classes.title} gutterBottom>
              Comparable Properties
            </Typography>
          </Grid>
          <Grid item lg={12} sm={12}>
            <Typography className={classes.subtitle}>
              {`Select up to 3 target properties from ${size} to compare.`}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} sm={6} lg={6}>
        <Grid container spacing={1}>
          <Grid item xs={12} lg={12} className={classes.button}>
            <>
              <ButtonGroup variant="outlined" aria-label="Basic button group" className={classes.buttonGroup}>
                <Button
                  key={'salesComps'}
                  variant="outlined"
                  color="primary"
                  testId={'salesComps'}
                  className={compToolStore.rentalComps ? classes.notActiveButton : classes.activeButton}
                  onClick={() => compToolStore.setRentalComps(false)}
                >
                  SALES COMPS
                </Button>
                <Button
                  key={'rentComps'}
                  color="primary"
                  variant="outlined"
                  testId={'rentComps'}
                  className={compToolStore.rentalComps ? classes.activeButton : classes.notActiveButton}
                  onClick={() => compToolStore.setRentalComps(true)}
                >
                  RENT COMPS
                </Button>
              </ButtonGroup>
              <Button
                variant="outlined"
                color="primary"
                testId="compFilters"
                className={classes.button}
                onClick={() => compToolStore.setOpenFiltersModal(true)}
                startIcon={<TuneOutlined />}
              >
                FILTERS
              </Button>
              {
                compToolStore.showGridView ? (
                  <Button
                    variant="outlined"
                    color="primary"
                    testId="listView"
                    className={classes.button}
                    onClick={() => compToolStore.setShowGridView(false)}
                    startIcon={<FormatListBulletedOutlined />}
                  >
                    LIST VIEW
                  </Button>
                ) : (
                  <Button
                    variant="outlined"
                    color="primary"
                    testId="listView"
                    className={classes.button}
                    onClick={() => compToolStore.setShowGridView(true)}
                  >
                    <MapOutlined />
                    MAP VIEW
                  </Button>
                )
              }
            </>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
});