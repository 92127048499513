import { useParams } from "react-router";
import { useStore } from "../../hooks";
import { observer } from "mobx-react";
import { useEffect } from "react";
import { ArrPayments, EncodedParams } from "./arrPaymentModule";
import { decodeData } from "./arrPaymentsUtils";
import CancelledOrderLink from "./cancelledOrderLink";

export const ArrOrderPaymentContainer = observer(() => {
  const { cdaPaymentsStore } = useStore();
  const { cdaDocumentStore } = cdaPaymentsStore;

  const { encodedParams } = useParams<EncodedParams>();
  const { entityId, tamariskFlowType } = decodeData(encodedParams)

  useEffect(() => {
    cdaDocumentStore.checkIfValidLink(entityId, tamariskFlowType)
  }, [entityId, tamariskFlowType])

  if (typeof cdaDocumentStore.isLinkValid == 'undefined') {
    return <></>
  }
  return cdaDocumentStore.isLinkValid ? <ArrPayments /> : <CancelledOrderLink />;
})
