import { BorrowerBaseService } from './borrowerBaseService';

const url = {
  GET_BORROWER_DATA_COLLECTION: '/nportal/rest/priv/BorrowerDataCollection/getBorrowerDataCollection',
  SAVE_BORROWER_DATA_COLLECTION: '/nportal/rest/priv/BorrowerDataCollection/saveBorrowerDataCollection',
  SAVE_BORROWER_DATA_COLLECTION_AND_RESOLUTIONS: '/nportal/rest/priv/BorrowerDataCollection/saveDataCollectionAndRequestLoe',
  SUBMIT_REVIEW: '/nportal/rest/priv/BorrowerDataCollection/markReviewCompleted',
  GET_BORROWER_DATA_COLLECTION_HISTORY: '/nportal/rest/priv/BorrowerDataCollection/getDataCollectionUpdateHistoryByType',
  GET_BORROWER_LOE: '/nportal/rest/priv/BorrowerDataCollection/getBorrowerLOE',
  SAVE_AUTOMATED_EVENTS: '/nportal/rest/priv/BorrowerDataCollection/saveAutomatedEvents',
};
const type = {
  BORROWER_BACKGROUND_REVIEW: 'Borrower Background Review',
}

export class BorrowerInternalService extends BorrowerBaseService {
  saveBorrowerDataCollection(borrowerId: number, loanId: number, data: any, reviewType = type.BORROWER_BACKGROUND_REVIEW) {
    return this.post(`${url.SAVE_BORROWER_DATA_COLLECTION}?borrowerId=${borrowerId}&loanId=${loanId}&type=${reviewType}`, data);
  }
  saveBorrowerDataCollectionAndCreateResolutions(borrowerId: number, loanId: number, data: any, reviewType = type.BORROWER_BACKGROUND_REVIEW) {
    return this.post(`${url.SAVE_BORROWER_DATA_COLLECTION_AND_RESOLUTIONS}?borrowerId=${borrowerId}&loanId=${loanId}&type=${reviewType}`, data);
  }
  getBorrowerDataCollection(borrowerId: number, loanId?: any, reviewType = type.BORROWER_BACKGROUND_REVIEW) {
    const baseUrl = `${url.GET_BORROWER_DATA_COLLECTION}?borrowerId=${borrowerId}&type=${reviewType}`
    return loanId ? this.get(`${baseUrl}&loanId=${loanId}`) : this.get(baseUrl);
  }
  makeReviewCompleted(loanId: number, borrowerId: number, documentName: string) {
    return this.post(`${url.SUBMIT_REVIEW}?loanId=${loanId}&borrowerId=${borrowerId}&documentName=${documentName}`, {});
  }
  getDataCollectionUpdateHistoryByType(borrowerId: number, reviewType = type.BORROWER_BACKGROUND_REVIEW) {
    return this.get(`${url.GET_BORROWER_DATA_COLLECTION_HISTORY}?borrowerId=${borrowerId}&type=${reviewType}`);
  }
  getBorrowerLOE(borrowerId: number, section: string, lineItem: any) {
    return this.put(`${url.GET_BORROWER_LOE}?borrowerId=${borrowerId}&section=${section}`, lineItem);
  }
  saveAutomatedEvents(data: any) {
    return this.post(`${url.SAVE_AUTOMATED_EVENTS}`, data);
  }
}