import { Box, Typography, Button, Grid } from "@material-ui/core";
import { observer } from "mobx-react";
import { useACHFormStore } from "../hooks/useACHStore";
import React from "react";
import { usePlaidLink } from "react-plaid-link";
import { LoanInfo } from "./loanInfo";
import { ReportProblemOutlined } from "@material-ui/icons";

export interface PlaidProps {
  linkToken: string;
}

export const PlaidVerification = observer((props: PlaidProps) => {
  const { achFormStore } = useACHFormStore();

  const { open, ready } = usePlaidLink({
    token: props.linkToken!,
    onSuccess: React.useCallback((public_token, metadata) => {
      achFormStore.storePlaidACHInformation({
        publicToken: public_token,
        loanId: window.location.href.substring(window.location.href.lastIndexOf('/') + 1),
        accounts: metadata.accounts,
        instantVerification: !metadata.accounts[0].verification_status
      });
    }, [])
  });

  return (
    <>
      {!achFormStore.achData?.verificationStep &&
        <Box width={'100%'} display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'space-evenly'} minHeight={400}>
          <LoanInfo achData={achFormStore.achData} />
          <Box display={'flex'} justifyContent={'center'} alignItems={'center'} width={'50%'}>
            <Typography style={{ textAlign: 'center' }} variant="h6">We need your consent to enable automatic monthly payments and verify your bank account information.</Typography>
          </Box>
          <Box display={'flex'} justifyContent={'center'}>
            <Button color={'primary'} disabled={!ready} variant={'contained'} onClick={() => open()}>Get Started</Button>
          </Box>
        </Box>}
      {achFormStore.achData?.microdepositPosted &&
        <Box width={'100%'} display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'space-evenly'} minHeight={400}>
          <LoanInfo achData={achFormStore.achData} />
          <Box display={'flex'} justifyContent={'center'} alignItems={'center'} width={'70%'}>
            <ReportProblemOutlined style={{ fontSize: '80px', color: '#FF7A00' }} />
            <Box pl={2}>
              <Typography style={{ fontWeight: 'bold' }} variant="h6">Your verification remains incomplete.</Typography>
              <Typography variant="h6">Please enter the 3 digit code in the description of the micro deposit transaction</Typography>
            </Box>
          </Box>
          <Box display={'flex'} justifyContent={'center'}>
            <Button color={'primary'} disabled={!ready} variant={'contained'} onClick={() => open()}>Verify Account</Button>
          </Box>
        </Box>}
    </>
  );
});