import React, { ReactNode } from 'react';
import {
  Dialog,
  DialogActions as MuiDialogActions,
  DialogContent,
  DialogTitle as MuiDialogTitle,
  Typography,
  IconButton,
  Box,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { WithStyles } from '@material-ui/core/styles';
import { Close } from '@material-ui/icons';
import { observer } from 'mobx-react';
import { Button } from '@roc/ui';
import PropertyTypesForm from './propertyTypesForm';
import { RentalPortfolioStore } from './../../stores';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';

export enum PropertyTypesModalState {
  ADD,
  EDIT,
}

interface PropertyTypesModalProps {
  modalState: PropertyTypesModalState;
  open: boolean;
  handleClose: Function;
  handleEdit: Function;
  handleAdd: Function;
  store: RentalPortfolioStore;
}

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const styles = (theme: Theme) =>
  createStyles({
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography {...other}>
      <Typography variant="h4">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <Close />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
}))(MuiDialogActions);

export const PropertyTypesModal = observer(({
  modalState,
  open,
  handleClose,
  handleEdit,
  handleAdd,
  store,
}: PropertyTypesModalProps) => {
  const { form } = store.propertyTypesStore;

  const handleDialogClose = e => {
    handleClose(e);
  };

  return (
    <div>
      <Dialog
        disableEscapeKeyDown
        disableBackdropClick
        open={open}
        onClose={handleDialogClose}
        fullWidth={true}
        maxWidth={'lg'}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle
          id="form-dialog-title"
          onClose={() => handleDialogClose(null)}
        >
          {modalState == PropertyTypesModalState.ADD
            ? 'Add a Property Type to the Portfolio'
            : 'Edit Property Type added to the Portfolio'}
        </DialogTitle>
        <DialogContent>
          <Box m={4}>
            <PropertyTypesForm store={store} />
          </Box>
        </DialogContent>
        <DialogActions disableSpacing>
          <Button onClick={handleDialogClose} color="primary" testId="cancel">
            Cancel
          </Button>
          <Button
            disabled={!form.meta.isValid}
            onClick={e => {
              {
                modalState == PropertyTypesModalState.ADD
                  ? handleAdd()
                  : handleEdit();
              }
            }}
            color="primary"
            variant="contained"
            testId="save"
          >
            {modalState == PropertyTypesModalState.ADD
              ? 'Add'
              : 'Apply Changes'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
});

export default PropertyTypesModal;
