import { flow, makeObservable, observable } from 'mobx';
import { SearchBarService } from '../services/searchBarService';
import { CompanyDropdownStore } from './companyDropdownStore';
import { GlobalStore } from './globalStore';

const form = {
  fields: {
    username: {
      value: '',
      error: null,
      rule: 'required',
    },
    password: {
      value: '',
      error: null,
      rule: 'required',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export interface SearchResult {
  loanId: number;
  lenderCompanyId: number;
  dealName: string;
  lender: string;
}

export class SearchBarStore {
  private globalStore: GlobalStore;
  private companyDropdownStore: CompanyDropdownStore;
  private searchBarService: SearchBarService;
  public showSearch: boolean;

  loading = false;
  searchResults: SearchResult[] = [];

  constructor(globalStore, companyDropdownStore) {
    this.globalStore = globalStore;
    this.companyDropdownStore = companyDropdownStore;
    this.searchBarService = new SearchBarService();
    makeObservable(this, {
      loading: observable,
      searchResults: observable,
      searchLoans: flow,
      setShowSearch: flow,
      showSearch: observable,
    });
  }

  reset() {
    this.searchResults = [];
  }

  *searchLoans(term) {
    try {
      this.loading = true;
      const response = yield this.searchBarService.searchLoans(term);
      this.loading = false;
      this.searchResults = response.data.data.rows;
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while retrieving the search results',
      });
    }
  }

  setShowSearch(value) {
    this.showSearch = value;
  }
}
