import { Badge, Box, Chip, IconButton } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { MoreVert, PlayArrow } from '@material-ui/icons';
import {
  BooleanChip,
  GridColumnProps,
  LinkColumn,
  SelectFilterComponent,
  SelectFloatingFilterComponent,
  StatusChip,
  StatusType,
  FastForwardIcon,
} from '@roc/ui';
import { observer } from 'mobx-react';
import React from 'react';

import { GridStore, InfiniteScrollGrid } from '@roc/feature-app-core';
import { useLoanRoutes } from '@roc/feature-loans-routes-config';
import { NO, PORTAL_COLORS, YES } from '@roc/feature-utils';
import { formatCurrency } from '@roc/ui/utils';

const StyledBadgeFunded = withStyles(theme => ({
  badge: {
    right: -12,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
    fontSize: '11px',
    backgroundColor: PORTAL_COLORS.unreadMessages,
    color: 'white',
    fontWeight: 700,
    userSelect: 'none',
  },
}))(Badge);

export const getUnreadMessagesCountFunded = unreadMessages => {
  if (unreadMessages) {
    return unreadMessages > 99 ? '99+' : unreadMessages;
  }
  return undefined;
};

export interface LoanGridProps {
  store: GridStore;
  columns: GridColumnProps[];
  openMenu?: (event: React.MouseEvent<HTMLElement>, record: any) => void;
}

export const LoanGrid = observer((props: LoanGridProps) => {
  const { loanRoutesConfig } = useLoanRoutes();

  const frameworkComponents = {
    selectFilter: SelectFilterComponent,
    selectFloatingFilter: SelectFloatingFilterComponent,
    loanIdCellRenderer: params => (
      <StyledBadgeFunded
        badgeContent={getUnreadMessagesCountFunded(
          params.node.data?.unreadMessages
        )}
      >
        {LinkColumn({
          ...params,
          url: loanRoutesConfig.loans(params.value).children.dashboard.url,
        })}
      </StyledBadgeFunded>
    ),
    fastTrackCellRenderer: ({ value }) => {
      if (!value) {
        return null;
      }
      return (<FastForwardIcon value={value} />)
    },

    pendingDocumentsCellRenderer: ({ value }) => {
      if (!value) {
        return null;
      }
      return (
        <Box pt={1} display="flex" justifyContent="center">
          <Chip
            size="small"
            label={value}
            style={{
              backgroundColor: PORTAL_COLORS.pendingDocuments,
              color: 'white',
              fontWeight: 700,
            }}
          />
        </Box>
      );
    },
    currencyCellRenderer: ({ value }) => {
      return value ? formatCurrency(Number(value)) : null;
    },
    yesNoCellRenderer: ({ value }) => {
      return [YES, NO].includes(value) ? (
        <BooleanChip value={value == YES} />
      ) : null;
    },
    dealNameCellRenderer: params => {
      const loanId = params.node.data?.dealid || params.node.data?.loanId;
      return LinkColumn({
        ...params,
        url: loanRoutesConfig.loans(loanId).children.dashboard.url,
      });
    },
    statusCellRenderer: ({ value }) =>
      value ? (
        <StatusChip label={value} size="small" statusType={StatusType.LOAN} />
      ) : null,

    actionCellRenderer: params => {
      return (
        <IconButton onClick={e => props?.openMenu(e, params.node.data)}>
          <MoreVert color="primary" />
        </IconButton>
      );
    },
  };

  return (
    <InfiniteScrollGrid
      columns={props.columns}
      frameworkComponents={frameworkComponents}
      store={props.store}
    />
  );
});
