import { makeStyles } from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core';

import { useMemo } from 'react';

const useStyles = makeStyles(theme => ({
  headerCell: {
    fontWeight: 'bold',
    color: '#6D6D6D',
    padding: '0.5rem',
    backgroundColor: '#E4E8EB',
    textAlign: 'center',
    border: '1px solid #ddd',
  },
  valueCell: {
    border: '1px solid #ddd',
    width: '50%',
    padding: '0.5rem',
    backgroundColor: '#FCFCFC',
  },
}));

export interface DetailsTableField {
  label: string;
  value: any;
  colSpan: 1 | 2;
}
interface DetailsTableCell {
  key: string;
  value: string;
  colSpan: 1 | 2;
}

interface DetailsTableRow {
  key: string;
  isLabel: boolean;
  cells: DetailsTableCell[];
}

function convertFieldsToRows(fields: DetailsTableField[]): DetailsTableRow[] {
  const rows: DetailsTableRow[] = [];

  for (let i = 0; i < fields.length; i++) {
    const currentField = fields[i];

    // Create label row
    if (currentField.colSpan === 2) {
      rows.push({
        key: `header-row-${currentField.label}`,
        isLabel: true,
        cells: [
          {
            key: `header-cell-${currentField.label}`,
            value: currentField.label,
            colSpan: 2,
          },
        ],
      });
      rows.push({
        key: `value-row-${currentField.label}`,
        isLabel: false,
        cells: [
          {
            key: `value-cell-${currentField.label}`,
            value: currentField.value,
            colSpan: 2,
          },
        ],
      });
    } else {
      // Handle the case for colSpan 1 where two labels are combined
      const nextField = fields[i + 1];
      if (nextField && nextField.colSpan === 1) {
        // Label row
        rows.push({
          key: `header-row-${currentField.label}-${nextField.label}`,
          isLabel: true,
          cells: [
            {
              key: `header-cell-${currentField.label}`,
              value: currentField.label,
              colSpan: 1,
            },
            {
              key: `header-cell-${nextField.label}`,
              value: nextField.label,
              colSpan: 1,
            },
          ],
        });
        // Value row
        rows.push({
          key: `value-row-${currentField.label}-${nextField.label}`,
          isLabel: false,
          cells: [
            {
              key: `value-cell-${currentField.label}`,
              value: currentField.value,
              colSpan: 1,
            },
            {
              key: `value-cell-${nextField.label}`,
              value: nextField.value,
              colSpan: 1,
            },
          ],
        });
        i++; // Skip next field as it has been handled
      }
    }
  }

  return rows;
}

interface DetailsTableProps {
  fields: DetailsTableField[];
}

export const DetailsTable = ({ fields }: DetailsTableProps) => {
  const classes = useStyles();

  const rows = useMemo(() => convertFieldsToRows(fields), [fields]);

  return (
    <TableContainer>
      <Table>
        <TableBody>
          {rows.map(row => (
            <TableRow key={row.key}>
              {row.cells.map(cell => (
                <TableCell
                  key={cell.key}
                  component="th"
                  scope="row"
                  align="center"
                  colSpan={cell.colSpan}
                  className={
                    row.isLabel ? classes.headerCell : classes.valueCell
                  }
                >
                  {cell.value}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
