import { Box, Grid } from '@material-ui/core';
import { Button } from '@roc/ui';
import {
  VerticalStepper,
  VerticalStepperProps,
  VerticalStepObj,
} from './verticalStepper';
import { useBorrowerPortalMediaQueries } from '@roc/feature-app-core';
import { HorizontalStepper } from './horizontalStepper';

export const VerticalStepsComponent: React.FC<VerticalStepperProps> = ({
  activeStep,
  steps,
}) => {
  const { isLargeScreen } = useBorrowerPortalMediaQueries();
  return isLargeScreen ? (
    <BigScreenStepper activeStep={activeStep} steps={steps} />
  ) : (
    <SmallScreenStepper activeStep={activeStep} steps={steps} />
  );
};

const SmallScreenStepper: React.FC<VerticalStepperProps> = ({
  activeStep,
  steps,
}) => {
  return (
    <Box>
      <HorizontalStepper activeStep={activeStep} steps={steps} />
      <Box px={2} py={4}>
        {steps[activeStep]?.content}
        <Box pt={4}>
          <StepButtons currentStep={steps[activeStep]} />
        </Box>
      </Box>
    </Box>
  );
};

const BigScreenStepper: React.FC<VerticalStepperProps> = ({
  activeStep,
  steps,
}) => {
  return (
    <Grid container direction="row" spacing={2}>
      <Grid item xs={12} sm={4} md={3} lg={3} xl={3}>
        <VerticalStepper activeStep={activeStep} steps={steps} />
      </Grid>
      <Grid item xs={12} sm={8} md={9} lg={9} xl={9}>
        <Grid container spacing={2} justifyContent="center" direction="row">
          <Grid item xs={12} sm={10} md={10} lg={10} xl={10}>
            <Box pr={2} py={6}>
              {steps[activeStep]?.content}
              <Box pt={4}>
                <StepButtons currentStep={steps[activeStep]} />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const StepButtons = ({ currentStep }: { currentStep: VerticalStepObj }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Button
          variant="outlined"
          color="primary"
          disabled={!currentStep?.allowBack}
          onClick={currentStep?.onBack}
          testId="back"
          fullWidth
        >
          {currentStep?.backButtonText ?? 'Back'}
        </Button>
      </Grid>
      <Grid item xs={6}>
        <Button
          variant="contained"
          color="primary"
          disabled={!currentStep?.allowNext}
          onClick={currentStep?.onNext}
          testId="continue"
          fullWidth
        >
          {currentStep?.nextButtonText ?? 'Next'}
        </Button>
      </Grid>
    </Grid>
  );
};
