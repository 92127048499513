import { Document } from '@roc/feature-types';
import { DialogState, StandardDialog } from '@roc/ui';
import { useDocumentStore } from '@roc/feature-documents';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { BorrowerBaseballCardBaseStore } from 'libs/feature-borrowers/src/borrowers/stores/borrowerBaseballCardBaseStore';
import { observer } from 'mobx-react';
import PropertyInformationForm from './propertyInformationForm';
import { ADD_PROPERTY, EDIT_PROPERTY } from './utils';
import { isNil } from '@roc/feature-utils';

export type PropertyInformationDialogProps = {
  loan?: any;
  store: BorrowerBaseballCardBaseStore;
  isVerified?: boolean;
  recordSourceType?: string;
};

const useStyles = makeStyles(() =>
  createStyles({
    typography: {
      marginTop: 0,
    },
    dialogContent: {
      padding: 0,
    },
  })
);

export const PropertyInformationDialog = observer(
  (props: PropertyInformationDialogProps) => {
    const { store, recordSourceType } = props;
    const classes = useStyles();
    const {
      propertyInformationDialogState,
      selectedVerifiedTrackRecordsId,
      selectedTrackRecordIsVerified,
      doPropertyLookup,
      selectedVerifiedTrackRecordsVerificationSource,
      selectedVerifiedTrackRecordsLinks
    } = store;
    const title =
      propertyInformationDialogState == DialogState.ADD
        ? ADD_PROPERTY
        : EDIT_PROPERTY;

    return (
      <StandardDialog
        open={!isNil(propertyInformationDialogState)}
        maxWidth={doPropertyLookup ? 'xl' : 'md'}
        handleClose={() => store.closePropertyInformationDialog()}
        className={classes.dialogContent}
        dialogContent={
          <div className={classes.dialogContent}>
            <Grid>
              <Typography variant="h4">{title}</Typography>
              <PropertyInformationForm
                store={store}
                verifiedTrackRecordsId={selectedVerifiedTrackRecordsId}
                title={title}
                disabled={selectedTrackRecordIsVerified}
                isVerified={selectedTrackRecordIsVerified}
                recordSourceType={recordSourceType}
                links={selectedVerifiedTrackRecordsLinks}
                verificationSource={selectedVerifiedTrackRecordsVerificationSource}
                doPropertyLookup={doPropertyLookup}
              />
            </Grid>
          </div>
        }
      />
    );
  }
);

export default PropertyInformationDialog;
