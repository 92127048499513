import { Service } from './../../../services';

const baseUrl = '/api/v1/stabilizedBridgeAnalyzer';

const url = {
  GET_STABILIZED_BRIDGE_ANALYZER: '/api/v1/validation/stabilizedBridgeAnalyzer/getStabilizedBridgeAnalyzer',
  LOAN_VALUES: '/api/v1/loan/getStabilizedBridgeLoanCalcDetail',
  STABILIZED_DEAL_NAMES: '/api/v1/loan/getStabilizedBridgeDealNames',
};

export class StabilizedBridgeAnalyzerService extends Service {

  getStabilizedBridgeAnalyzer(request) {
    return this.post(url.GET_STABILIZED_BRIDGE_ANALYZER, request);
  }

  getStabilizedDealNames(
    page: number,
    pageSize: number,
    sortDir: string,
    sortBy: string,
    filters: object
  ) {
    return this.get(url.STABILIZED_DEAL_NAMES, {
      page,
      pageSize,
      sortDir,
      sortBy,
      filters,
    });
  }

  getLoanValues(loanId) {
    return this.get(`${url.LOAN_VALUES}?id=${loanId}`);
  }

}
