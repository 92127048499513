// fix n flip, stabilized,
export const loanTermColsBridge = {
  values: [
    {
      fieldname: "Lender",
      mapvalue: "lenderName",
      specialtype: "customlink",
    },
    {
      fieldname: "Status",
      mapvalue: "status",
      specialtype: "",
    },
    {
      fieldname: "Interest Rate",
      mapvalue: "rate",
      specialtype: "percentage",
    },
    {
      fieldname: "Full Princ Interest",
      mapvalue: "fullPrincipalInterest",
      specialtype: "icon",
    },
    {
      fieldname: "Roc Int Rate",
      mapvalue: "rocRate",
      specialtype: "percentage",
    },
    {
      fieldname: "Appraisal Reviewed",
      mapvalue: "appraisalReviewed",
      htmlfield: "checkbox",
      specialtype: "icon",
    },
    {
      fieldname: "Loan Amount",
      mapvalue: "amount",
      specialtype: "currency",
    },
    {
      fieldname: "Loan SubType",
      mapvalue: "loanSubtype",
      specialtype: "",
    },
    {
      fieldname: "Points (I|O)",
      mapvalue: "points",
      specialtype: "",
      titlevalue: "pointsAmounts",
    },
    {
      fieldname: "Int. Reserve (Months)",
      mapvalue: "interestReserveMonths",
      specialtype: "float",
    },

    {
      fieldname: "Roc Points (I|O)",
      mapvalue: "rocPoints",
      specialtype: "",
    },
    {
      fieldname: "Credit Reviewed",
      mapvalue: "creditReviewed",
      htmlfield: "checkbox",
      specialtype: "icon",
    },
    {
      fieldname: "Initial Loan Amount:",
      mapvalue: "initialLoanAmount",
      specialtype: "currency",
    },
    {
      fieldname: "Construction Holdback",
      mapvalue: "constructionHoldback",
      specialtype: "currency",
    },
    {
      fieldname: "Broker Points (I|O)",
      mapvalue: "brokerPoints",
      specialtype: "",
    },
    {
      fieldname: "Interest Reserve",
      mapvalue: "interestReserve",
      specialtype: "currency",
    },
    {
      fieldname: "Sub ordination",
      mapvalue: "origLenderSubordination",
      specialtype: "percentage",
    },
    {
      fieldname: "Background Reviewed",
      mapvalue: "backgroundReviewed",
      htmlfield: "checkbox",
      specialtype: "icon",
    },
    {
      fieldname: "Pre Penalty (Months)",
      mapvalue: "prepayPenaltyMonths",
      specialtype: "numeric",
    },
    {
      fieldname: "Construction Holdback Rate",
      mapvalue: "constructionHoldbackToRenovationBudgetPercent",
      specialtype: "percentage",
    },
    {
      fieldname: "Duration (months)",
      mapvalue: "duration",
      specialtype: "numeric",
    },
    {
      fieldname: "Pledge Of Shares",
      mapvalue: "pledgeOfShares",
      specialtype: "icon",
    },
    {
      fieldname: "Buyback Skim Reduction",
      mapvalue: "buybackSkimReduction",
      specialtype: "",
    },
    {
      fieldname: "Attorney Name",
      mapvalue: "lenderAttorneySelectedName",
      specialtype: "",
    },
    {
      fieldname: "Initial LTC",
      mapvalue: "aggregateLoanToCurrentCostInitial",
      specialtype: "percentage",
    },
    {
      fieldname: "Mers",
      mapvalue: "customFields.mersProcess",
      specialtype: "",
    },
    {
      fieldname: "Partners Approval Required",
      mapvalue: "partnersApprovalRequired",
      specialtype: "icon",
    },
    {
      fieldname: "Attorney Opinion Letter",
      mapvalue: "attorneyOpinionLetter",
      specialtype: "",
    },
    {
      fieldname: "Total LTFC",
      mapvalue: "aggregateLoanToFutureCost",
      specialtype: "percentage",
    },
    {
      fieldname: "Attorney Email",
      mapvalue: "attorneyEmail",
      specialtype: "",
    },
    {
      fieldname: "Funding Entity",
      mapvalue: "fundingPreference",
      specialtype: "",
    },
    {
      fieldname: "",
      mapvalue: "space",
      specialtype: "",
    },
    {
      fieldname: "Condo Conversion",
      mapvalue: "condoConversion",
      specialtype: "",
    },

  ]
}

export const guarantorValuesBridge = {
  values: [
    {
      fieldname: "Name",
      mapvalue: "fullName",
      specialtype: "customlink",
    },
    {
      fieldname: "Personal Guarantor",
      mapvalue: "personalGuarantor",
      specialtype: "icon",
    },
    {
      fieldname: "FICO",
      mapvalue: "medianScore",
      specialtype: "customlink",
    },
    {
      fieldname: "Exp",
      mapvalue: "experience",
      specialtype: "customlink",
    },
    {
      fieldname: "Ownership",
      mapvalue: "pctOwnership",
      specialtype: "percentage",

    },
    {
      fieldname: "Paid Off",
      mapvalue: "portalProjectsCompleted",
      specialtype: "customlink",
    },
    {
      fieldname: "Age",
      mapvalue: "age",
      specialtype: "numeric",
    },
    {
      fieldname: "Borrower Status",
      mapvalue: "status",
      specialtype: "",
    },
    {
      fieldname: "BK",
      mapvalue: "bankruptcyStr",
      specialtype: "",
    },
    {
      fieldname: "GC",
      mapvalue: "generalContractor",
      specialtype: "",
    },
    {
      fieldname: "Citizenship",
      mapvalue: "citizenshipStatus",
      specialtype: "",
    },
    {
      fieldname: "Funded",
      mapvalue: "portalProjectsActive",
      specialtype: "customlink",
    },
    {
      fieldname: "Borrower State",
      mapvalue: "state",
      specialtype: "",
    },
    {
      fieldname: "Networth",
      mapvalue: "netWorth",
      specialtype: "currency",
    },
    {
      fieldname: "FC",
      mapvalue: "foreclosureStr",
      specialtype: "",
    },
    {
      fieldname: "Broker",
      mapvalue: "broker",
      specialtype: "",
    },
    {
      fieldname: "Delinq. 60+",
      mapvalue: "currentDelinquent60Str",
      specialtype: "",
    },
    {
      fieldname: "Agreed",
      mapvalue: "portalProjectsAgreed",
      specialtype: "customlink",
    },

  ]
};

export const borrowerEntityColsBridge = {
  values: [
    {
      fieldname: "Entity name",
      mapvalue: "name",
      specialtype: "customlink",
      warningMapValue: 'flaggedBorrower'
    },
    {
      fieldname: "Entity Type",
      mapvalue: "entityType",
      specialtype: "icon",
    },
    {
      fieldname: "Entity EIN",
      mapvalue: "einNumber",
      specialtype: "numeric",
    },
  ]
};

export const collateralValuesColsBridge = {
  values: [
    {
      fieldname: "Address",
      mapvalue: "address",
      specialtype: "bigvalue",
    },
    {
      fieldname: "",
      mapvalue: "",
      specialtype: "customlink",
    },
    {
      fieldname: "As-is Value",
      mapvalue: "asIsWithMedian",
      specialtype: "customlink",
    },
    {
      fieldname: "AC",
      mapvalue: "approved",
      specialtype: "icon",
    },
    {
      fieldname: "Occup",
      mapvalue: "occupancy",
      specialtype: "",
    },
    {
      fieldname: "Acquisition",
      mapvalue: "propertyOwnership",
      specialtype: "",
    },
    {
      fieldname: "Acquisition Price",
      mapvalue: "purchasePrice",
      specialtype: "currency",
    },
    {
      fieldname: "Reno Budget",
      mapvalue: "renovationBudget",
      specialtype: "currency",
    },
    {
      fieldname: "ARV",
      mapvalue: "arvWithMedian",
      specialtype: "customlink",
    },
    {
      fieldname: "Min Release $",
      mapvalue: "releaseAmount",
      specialtype: "currency",
    },
    {
      fieldname: "Monthly Rent",
      mapvalue: "monthlyRent",
      specialtype: "currency",
    },
    {
      fieldname: "Exit Strategy",
      mapvalue: "exitStrategy",
      specialtype: "",
    },
    {
      fieldname: "Acquisition Date",
      mapvalue: "purchaseDate",
      specialtype: "",
    },
    {
      fieldname: "Completed Renovations",
      mapvalue: "sunkCost",
      specialtype: "currency",
    },
    {
      fieldname: "Zillow",
      mapvalue: "zestimate",
      specialtype: "currency",
    },
    {
      fieldname: "Full Net Proceeds",
      mapvalue: "fullNetProceeds",
      specialtype: "",
    },
    {
      fieldname: "Ins Exp Date",
      mapvalue: "insuranceExpiryDate",
      specialtype: "",
    },
    {
      fieldname: "Purchase Type",
      mapvalue: "propertySourcing",
      specialtype: "",
    },
    {
      fieldname: "RefiAmount",
      mapvalue: "refiAmountDisplay",
      specialtype: "currency",
    },
    {
      fieldname: "Total Reno Budget",
      mapvalue: "totalRenovationBudget",
      specialtype: "currency",
    },
    {
      fieldname: "Seller's Credit Amount",
      mapvalue: "sellerCreditAmount",
      specialtype: "currency",
    },
    {
      fieldname: "Release Comment",
      mapvalue: "releaseComment",
      specialtype: "",
    },
    {
      fieldname: "Value %",
      mapvalue: "arvValuePercentage",
      specialtype: "",
    },
    {
      fieldname: "Wholesaler Purchase",
      mapvalue: "wholesalerPurchase",
      specialtype: "",
    },
    {
      fieldname: "WholeSale$",
      mapvalue: "wholesaleAmount",
      specialtype: "currency",
    },
    {
      fieldname: "Describe Renovation",
      mapvalue: "describeRenovation",
      specialtype: "",
    },
    {
      fieldname: "",
      mapvalue: "collateralspace10",
      specialtype: "blankspace",
    },
    {
      fieldname: "Lien Pos",
      mapvalue: "lienPosition",
      specialtype: "numeric",
    },
    {
      fieldname: "Sales in Zip (730 days) 1",
      mapvalue: "salesInZipDays",
      specialtype: "",
    },
    {
      fieldname: "Arms Length",
      mapvalue: "armsLength",
      specialtype: "",
    },
    {
      fieldname: "WholeSale%",
      mapvalue: "wholesalePercentage",
      specialtype: "percentage",
    },
    {
      fieldname: "",
      mapvalue: "collateralspace11",
      specialtype: "blankspace",
    },
    {
      fieldname: "",
      mapvalue: "collateralspace11",
      specialtype: "blankspace",
    },
    {
      fieldname: "Sr Amt",
      mapvalue: "lienAmount",
      specialtype: "currency",
    },
    {
      fieldname: "Rural Census",
      mapvalue: "ruralProperty",
      specialtype: "",
    },
    {
      fieldname: "Arms Comment",
      mapvalue: "armsLengthComment",
      specialtype: "",
    },
  ]
};

export const dealEconomicsColsBridge = {
  values: [
    {
      fieldname: "Set. Costs:",
      mapvalue: "settlementCosts",
      specialtype: "currency",
    },
    {
      fieldname: "Proj Set. Costs:",
      mapvalue: "projectedSettlementCosts",
      specialtype: "currency",
    },
    {
      fieldname: "Set. Costs Est:",
      mapvalue: "settlementCostsEst",
      specialtype: "",
    },
    {
      fieldname: "Cash at Purch.:",
      mapvalue: "cashAtPurchase",
      specialtype: "currency",
    },
    {
      fieldname: "Est Cash at Purch.:",
      mapvalue: "cashAtPurchaseEst",
      specialtype: "currency",
    },
    {
      fieldname: "Proj Cash in Deal:",
      mapvalue: "projectedCashInDeal",
      specialtype: "currency",
    },
    {
      fieldname: "Cash in Deal Est:",
      mapvalue: "projectedCashInDealEst",
      specialtype: "currency",
    },
    {
      fieldname: "Proj Profit:",
      mapvalue: "projectedProfit",
      specialtype: "negativeCurrency",
    },
    {
      fieldname: "Profit Est:",
      mapvalue: "projectedProfitEst",
      specialtype: "currency",
    },
    {
      fieldname: "Proj Roi",
      mapvalue: "projectedRoi",
      specialtype: "percentage",
    },
    {
      fieldname: "App. Exit Strat.",
      mapvalue: "approvedExitStrategy",
      specialtype: "icon",
    },
    {
      fieldname: "Borrower Down Pmt",
      mapvalue: "borrowerDownPayment",
      specialtype: "currency",
    },
    {
      fieldname: "Borrower Down %",
      mapvalue: "borrowerDownPaymentPct",
      specialtype: "percentage",
    },
    {
      fieldname: "Down Pmt + x",
      mapvalue: "borrowerDownPaymentPlusFees",
      specialtype: "currency",
    },
    {
      fieldname: "Borrower Down + x %",
      mapvalue: "borrowerDownPaymentPlusFeesPct",
      specialtype: "percentage",
    },
    {
      fieldname: "In Place DSCR",
      mapvalue: "inPlaceDscr",
      specialtype: "",
    },
    {
      fieldname: "Projected DSCR",
      mapvalue: "projectedDscr",
      specialtype: "",
    },
    {
      fieldname: "Overwritten Projected DSCR",
      mapvalue: "overwrittenProjectedDscr",
      specialtype: "span",
    }
  ]
};
