import { StandardDialog } from '@roc/ui';
import { useDocumentStore } from '@roc/feature-documents';
import { DocumentStatus } from '../../../../constants';
import { Document } from '@roc/feature-types';
import { LoanPayments } from './loanPayments';

export type LoanPaymentsDialogProps = {
  open?: boolean;
  onClose?: () => void;
  loan: any;
  document: Document;
};

export const LoanPaymentsDialog = (props: LoanPaymentsDialogProps) => {
  const { open, onClose, loan, document } = props;

  const { documentStore } = useDocumentStore();
  const { documentFormStore } = documentStore;
  const { loanPaymentsStore } = documentFormStore;

  const onClick = () => {
    document.status = DocumentStatus.ACCEPTED;
    loanPaymentsStore.acceptDocument(document);
  };

  return (
    <StandardDialog
      open={open}
      title="Due Diligence Payment"
      maxWidth="md"
      handleClose={onClose}
      dialogContent={
        <LoanPayments
          loan={loan}
          document={document}
          onSubmitLoanPayments={onClose}
          onClick={onClick}
        />
      }
    />
  );
};

export default LoanPaymentsDialog;
