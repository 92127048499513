import { observer } from "mobx-react";
import { lighten, makeStyles, Theme, Typography, Grid, Divider, capitalize } from '@material-ui/core';
import { Paper, Button } from '@roc/ui';
import { ViewModuleOutlined, Edit } from "@material-ui/icons";
import { ImagesGrid } from "./imagesGrid";
import { PropertyDetail } from "../propertyDetails";
import { formatCurrency } from "@roc/ui/utils";
import placeHolderImage from "../../images/Placeholder.svg"
import { PriceField } from "./priceField";
import { segmentAnalytics, SegmentTrackerEventCompTool } from "@roc/feature-utils";
import { useStore } from "@roc/client-portal-shared/hooks";


const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    border: `1px solid ${theme.palette.primary.light}`,
    background: lighten(theme.palette.primary.main, 0.92),
    width: '100%',
    padding: theme.spacing(2),
  },
  title: {
    fontSize: '28px',
    paddingLeft: theme.spacing(1),
    marginBottom: '0px'
  },
  image: {
    width: '100%',
    height: '335px',
    padding: '10px',
    paddingRight: '4px',
    objectFit: 'cover',
  },
  imagesItem: {
    alignContent: 'center'
  },
  addressInfo: {
    marginTop: '10px',
  },
  imagesGrid: {
    width: '100%',
    height: '150px',
    padding: '0px 2px 0px 0px',
    objectFit: 'cover',
  },
  priceField: {
    backgroundColor: '#285391',
    color: '#ffffff',
    borderRadius: '10px',
    marginLeft: '10px'
  },
  allPhotosButton: {
    backgroundColor: 'white',
    width: '170px',
    '&:hover': {
      backgroundColor: '#ffffff',
    }
  },
  editButton: {
    backgroundColor: 'white',
    width: '120px',
    '&:hover': {
      backgroundColor: '#ffffff',
    }
  }
}));



export const SubjectProperty = observer(
  () => {
    const { compToolStore } = useStore();
    const { compToolPhotosStore } = compToolStore;
    const classes = useStyles();
    const { subjectProperty, editSubjectPropertyFormStore, userStore, isInternal, internalUsername } = compToolStore;
    const { data } = subjectProperty;
    const imagesArray = Array(6).fill(placeHolderImage);

    const image = data.images.find(image => image.recommended_preview) ? data.images.find(image => image.recommended_preview).url : data.images[0]?.url

    const imagesGrid = data.images.length > 0 
      ? [...data.images.map(image => image.url), ...imagesArray].slice(0, 6) // Fill remaining with placeholders
      : imagesArray;

    const openDialog = (property) => {
      compToolStore.setSelectedProperty(property);
      compToolStore.setOpenPropertyDialog(true)
      segmentAnalytics.trackEvent({
        name: SegmentTrackerEventCompTool.COMP_TOOL_SUBJECT_PROPERTY_CLICKED,
        userName: isInternal ? internalUsername : userStore.userInformation?.username,
        subjectAddress: subjectProperty.data.address,
      });
    }

    const onError = (event) => {
      event.target.src = placeHolderImage;
    };

    const openIndividualPhoto = (image) => {
      compToolPhotosStore.setSelectedPhoto(image);
      compToolPhotosStore.setOpenIndividualPhoto(true);
    }

    return (<>
      <Paper className={classes.paper} onClick={() => openDialog(subjectProperty)}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography align="left" className={classes.title}>
              Subject Property
            </Typography>
          </Grid>

          <Grid item xs={12} md={6} lg={3} className={classes.imagesItem}>
            <img
              src={image || placeHolderImage}
              loading="lazy"
              className={classes.image}
              onError={onError}
              onClick={(event) => {
                event.stopPropagation();
                openIndividualPhoto(image);
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3} className={classes.imagesItem}>
            <ImagesGrid images={imagesGrid} imageStyles={classes.imagesGrid} showAllPhotosButton={false} clickablePhotos={true}/>
          </Grid>

          <Divider orientation="vertical" variant="middle" flexItem style={{ margin: '20px -1px 25px -1px' }} />

          <Grid item xs={12} lg={6} className={classes.addressInfo}>
            <Grid container spacing={1} justifyContent="space-between">
              <Grid item xs={12} style={{ paddingLeft: '10px', paddingBottom: '0px' }}>
                <Typography align="left" variant="h5" >
                  {data.address}
                </Typography>
              </Grid>
              {data.school_district &&
                <Grid item xs={12} style={{ paddingLeft: '10px', paddingTop: '0px', paddingBottom: '10px' }}>
                  <Typography align="left" style={{ fontSize: '18px', fontStyle: 'italic', textTransform: 'capitalize' }}>
                    {capitalize(data.school_district)}
                  </Typography>
                </Grid>
              }

              <Grid item md={5} sm={6} xs={12} style={{ alignContent: 'center' }} className={classes.priceField}>
                <PriceField property={subjectProperty} />
              </Grid>

              <PropertyDetail label="Rm" value={data.rooms} lg={2} md={3} xs={3} variant={"h3"} />
              <PropertyDetail label="Bd" value={data.bedrooms} lg={1} md={3} xs={3} variant={"h3"} />
              <PropertyDetail label="Ba" value={data.full_bathrooms} lg={2} md={3} xs={3} variant={"h3"} />
              <PropertyDetail label="HBa" value={data.half_bathrooms} lg={1} md={3} xs={3} variant={"h3"} />


              <Grid item lg={12} xs={12} style={{ marginTop: '10px', marginBottom: '20px' }}>
                <Divider variant="middle" />
              </Grid>
              <PropertyDetail label="Condition" value={data.condition_tag} lg={2} md={2} xs={4} variant={"h5"} />
              <PropertyDetail label="Lot Size" value={data.lot_size} lg={2} md={2} xs={4} variant={"h5"} />
              <PropertyDetail label="Taxes/Yr." value={formatCurrency(data.taxes, 0)} lg={2} md={2} xs={4} variant={"h5"} />
              <PropertyDetail label="Yr. Built" value={data.year_built} lg={2} md={2} xs={4} variant={"h5"} />
              {compToolStore.rentalComps ? (
                <PropertyDetail label="Rent/Sq.Ft" value={formatCurrency(data.sqft_rent, 0)} lg={2} md={2} xs={4} variant={"h5"} />
              ) : (
                <PropertyDetail label="Price/Sq.Ft" value={formatCurrency(data.sqft_price, 0)} lg={2} md={2} xs={4} variant={"h5"} />
              )}

              <PropertyDetail label="Sq.Ft" value={data.area} lg={2} md={2} xs={4} variant={"h5"} />
            </Grid>

            <Grid container spacing={1} justifyContent="space-between" style={{ marginTop: '20px' }}>
              <Grid item sm={6} xs={12} style={{ paddingLeft: '10px' }}>
                <Button
                  variant="outlined"
                  color="primary"
                  testId="allPhotos"
                  className={classes.allPhotosButton}
                  onClick={() => {
                    compToolStore.setOpenCarouelPhotos(true)
                  }}
                  startIcon={<ViewModuleOutlined />}
                >
                  ALL PHOTOS
                </Button>
              </Grid>
              <Grid item sm={6} xs={12} style={{ textAlign: 'right' }}>
                <Button
                  variant="outlined"
                  color="primary"
                  testId="testEditSubjectProperty"
                  className={classes.editButton}
                  onClick={() => {
                    editSubjectPropertyFormStore.setAreEditFieldsOpened(true)
                    openDialog(subjectProperty)
                  }}
                  startIcon={<Edit />}
                >
                  EDIT
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </>
    );
  }
);