import React from 'react';
import Chip from '@material-ui/core/Chip';
import { alpha, darken, makeStyles } from '@material-ui/core/styles';
import {
  docStatusProperties,
  statusProperties,
  creditBackgroundProperties,
  pendingDocumentsProperties,
  userPortalStatusProperties,
  liquidityVerificationProperties,
  loanRequestStatus,
  tradeStatusProperties,
  proposalStatusProperties,
  prescreenStatusProperties,
  insuranceStatusProperties,
  generalTasksStatusProperties,
  drawsStatusProperties,
  extensionsStatusProperties,
  payoffsStatusProperties,
  bankInStatusProperties,
  priorityProperties,
} from '@roc/feature-utils';
import clsx from 'clsx';

const useStyles = ({ color }) =>
  makeStyles(() => ({
    chipRoot: {
      display: 'inline-flex'
    },
    outlined: {
      borderWidth: 1,
      borderStyle: 'solid',
      backgroundColor: 'white',
      color: darken(color, 0.2),
      borderColor: color,
    },
    filled: {
      borderWidth: 0,
      backgroundColor: alpha(color, 0.2),
      color: darken(color, 0.5),
      borderColor: darken(color, 0.5),
    },
  }));

interface IStatusChip {
  statusType: StatusType;
  label: string;
  labelType?: string;
  [prop: string]: any;
}

export enum StatusType {
  DOCUMENT,
  LOAN,
  CREDIT_BACKGROUND,
  LIQUIDITY_VERIFICATION,
  PENDING_DOCUMENTS,
  USER_PORTAL_STATUS,
  LOAN_REQUEST,
  TRADE,
  PROPOSAL,
  PRESCREEN,
  INSURANCE_STATUS,
  GENERAL_TASK_STATUS,
  DRAWS,
  EXTENSIONS,
  PAYOFFS,
  BANK_INFORMATION_STATUS,
  PRIORITY
}

export const StatusChip = ({
  statusType,
  variant = 'outlined',
  label,
  labelType,
  ...props
}: IStatusChip) => {
  let color: string;
  let chosenProperties: any;

  switch (statusType) {
    case StatusType.CREDIT_BACKGROUND:
      chosenProperties = creditBackgroundProperties;
      color =
        creditBackgroundProperties[label]?.color ??
        creditBackgroundProperties.UNDEFINED.color;
      break;
    case StatusType.LIQUIDITY_VERIFICATION:
      chosenProperties = liquidityVerificationProperties;
      color =
        liquidityVerificationProperties[label]?.color ??
        liquidityVerificationProperties.UNDEFINED.color;
      break;
    case StatusType.DOCUMENT:
      chosenProperties = docStatusProperties;
      color =
        docStatusProperties[label]?.color ??
        docStatusProperties.UNDEFINED.color;
      break;
    case StatusType.LOAN:
      chosenProperties = statusProperties;
      color =
        statusProperties[label]?.color ?? statusProperties.UNKNOWN.color;
      break;
    case StatusType.PENDING_DOCUMENTS:
      chosenProperties = pendingDocumentsProperties;
      color =
        pendingDocumentsProperties[labelType]?.color ??
        statusProperties.UNKNOWN.color;
      break;
    case StatusType.USER_PORTAL_STATUS:
      chosenProperties = userPortalStatusProperties;
      color = userPortalStatusProperties[label]?.color ?? statusProperties.UNKNOWN.color;
      break;
    case StatusType.LOAN_REQUEST:
      chosenProperties = loanRequestStatus;
      color =
        loanRequestStatus[label]?.color ??
        loanRequestStatus.UNDEFINED.color;
      break;
    case StatusType.TRADE:
      chosenProperties = tradeStatusProperties;
      color = tradeStatusProperties[label]?.color ??
        tradeStatusProperties.Undefined.color
      break;
    case StatusType.PROPOSAL:
      chosenProperties = proposalStatusProperties;
      color = proposalStatusProperties[label]?.color ?? proposalStatusProperties.Undefined.color;
    case StatusType.PRESCREEN:
      chosenProperties = prescreenStatusProperties;
      color =
        prescreenStatusProperties[label]?.color ?? statusProperties.UNKNOWN.color;
      break;
    case StatusType.INSURANCE_STATUS:
      chosenProperties = insuranceStatusProperties;
      color =
        insuranceStatusProperties[label]?.color ?? statusProperties.UNKNOWN.color;
      break;
    case StatusType.GENERAL_TASK_STATUS:
      chosenProperties = generalTasksStatusProperties;
      color = generalTasksStatusProperties[label]?.color ?? statusProperties.UNKNOWN.color;
      break;
    case StatusType.DRAWS:
      chosenProperties = drawsStatusProperties;
      color = drawsStatusProperties[label]?.color ?? statusProperties.UNKNOWN.color;
      break;
    case StatusType.EXTENSIONS:
      chosenProperties = extensionsStatusProperties;
      color = extensionsStatusProperties[label]?.color ?? statusProperties.UNKNOWN.color;
      break;
    case StatusType.PAYOFFS:
      chosenProperties = payoffsStatusProperties;
      color = payoffsStatusProperties[label]?.color ?? statusProperties.UNKNOWN.color;
    case StatusType.BANK_INFORMATION_STATUS:
      chosenProperties = bankInStatusProperties;
      color = bankInStatusProperties[label]?.color ?? statusProperties.UNKNOWN.color;
      break;
    case StatusType.PRIORITY:
      chosenProperties = priorityProperties;
      color = priorityProperties[label]?.color ?? statusProperties.UNKNOWN.color;
      break;
  }

  const classes = useStyles({ color })();
  return (
    <Chip
      {...props}
      label={
        <span title={chosenProperties[label]?.name ?? label}>
          {chosenProperties[label]?.name ?? label}
        </span>
      }
      className={clsx(classes.chipRoot, classes[variant])}
    />
  );
};
