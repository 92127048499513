import {
  Box,
  Grid,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import { ScopeOfWorkV2FormBaseStore } from '@roc/feature-sow-shared/stores/v2';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fieldSpace2: {
      [theme.breakpoints.down('xs')]: {
        flexBasis: '66%',
        maxWidth: '66%',
      },
    },
  })
);

interface Props {
  screenshotContainerRef: any;
  store: ScopeOfWorkV2FormBaseStore;
}

let currentDisabled;

const PublicScopeOfWorkHeaderSuccess = observer((props: Props) => {
  const classes = useStyles();

  return (
    <Box my={3} ml={2} mr={3}>
      <Grid container justifyContent={'space-between'} spacing={3}>
        <Grid className={classes.fieldSpace2} item xs={12} sm={6}>
          <Typography variant="h3">Scope of Work</Typography>
        </Grid>
        <Grid
          container
          item
          alignItems="flex-end"
          justifyContent="flex-end"
          xs={12}
          sm={6}
          spacing={3}
        >
        </Grid>
      </Grid>
    </Box>
  );
});

export default PublicScopeOfWorkHeaderSuccess;
