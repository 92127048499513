import { Grid } from '@material-ui/core';
import { observer } from 'mobx-react';
import {
  PropertySource,
  ExitStrategy,
  WholesalerPropertyPaid,
  PropertySourceOther, WholesalerPropertySource,
  WholesalerPropertySourceOther,
  PropertyAddress
} from '@roc/ui/formComponents';
import {
  createRadioField,
  createTextField,
  createCurrencyField,
  createSelectField,
  createDateField,
} from '@roc/ui/componentBuilder';;
import { Button } from '@material-ui/core';
import {
  acquisitionOptions,
  propertyOwnership,
  yesNoOptions,
  tooltips,
} from '../../utils/constants';
import { useProposalsStore } from '../../hooks/useProposalsStore';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { PropertyType } from '@roc/ui/formComponents';
import { LoanSubType, propertyTypesByLoanSubtype } from '@roc/feature-utils';


const useStyles = makeStyles(theme =>
  createStyles({
    container: {
      padding: '25px',
    },
  })
);


const PropertyAndProject = observer((props) => {
  const { proposalsStore } = useProposalsStore();
  const { detailsStore } = proposalsStore;
  const { handleSave } = props;
  const { propertyStore } = detailsStore;
  const store = propertyStore.propertyForm;
  const classes = useStyles();

  const fields = store.form.fields;
  const isWholesalerProperty =
    fields.propertySourcing.value === 'Wholesaler';
  const isPropertySourceOther =
    fields.propertySourcing.value === 'Other';
  const isWholesalerPropertySourceOther =
    fields.wholesalerPurchase.value === 'Other';

  const aquisition = fields.acquisition.value;
  const isPurchase = propertyOwnership.PURCHASED === aquisition;
  const isOwned = propertyOwnership.OWNED_BY_BORROWER === aquisition;
  const propertyTypeOptions = propertyTypesByLoanSubtype(null);

  const fieldsValidationsBasedOnAcquisition = (fieldName: string) => {
    if (store.form.fields.acquisition.value === 'Owned By Borrower'
      && (!store.form.fields[fieldName].value || store.form.fields[fieldName].value === '')) {
      detailsStore.setCustomError(fieldName, 'This field is required.');
      return false;
    }
    detailsStore.setCustomError(fieldName, '');
    return true;
  };

  const runCustomValidations = () => {
    const isValid = fieldsValidationsBasedOnAcquisition('acquisitionDate')
      && fieldsValidationsBasedOnAcquisition('asIsValue');
    if (!isValid) {
      detailsStore.triggerValidationMessage();
    }
    return isValid;
  };

  const onSubmit = () => {
    store.runFormValidationWithMessage();
    if (runCustomValidations() && store.form.meta.isValid) {
      handleSave();
    }
  };

  const checkPropertyOwnership = (store) => {
    if (store.form.fields.acquisition.value === 'Owned By Borrower') {
      store.form.fields.asIsValue.rule = 'required';
    } else {
      store.form.fields.asIsValue.rule = '';
    }
  }

  return (
    <Grid container spacing={3} className={classes.container} >
      <Grid item xs={12}>
        <PropertyAddress
          store={store}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <PropertyType
          store={store}
          propertyTypeOptions={propertyTypeOptions}
          disabled={false}
        />
      </Grid>
      <Grid item xs={12}>
        {createRadioField({
          store,
          testId: 'acquisition',
          fieldName: 'acquisition',
          label:
            'Is this a purchase or does the borrower already own the property?',
          options: acquisitionOptions,
          row: true,
          tooltip: tooltips.acquisition,
        })}
      </Grid>
      {isPurchase && (
        <>
          <Grid item xs={12}>
            {createCurrencyField({
              store,
              testId: 'acquisitionPrice',
              fieldName: 'acquisitionPrice',
              label: 'Acquisition Price',
              tooltip: tooltips.purchasePrice,
            })}
          </Grid>
          <Grid item xs={12}>
            {checkPropertyOwnership(store)}
            {createCurrencyField({
              store,
              testId: 'asIsValue',
              fieldName: 'asIsValue',
              label: 'As-Is Value (If Applicable)',
              tooltip: tooltips.asIsValue,
            })}
          </Grid>
          <Grid item xs={12}>
            <PropertySource
              store={store}
            />
          </Grid>
          {isPropertySourceOther && (
            <Grid item xs={12}>
              <PropertySourceOther
                store={store}
              />
            </Grid>
          )}
          {isWholesalerProperty && (
            <Grid item xs={12}>
              <WholesalerPropertyPaid
                store={store}
              />
            </Grid>
          )}
          {isWholesalerProperty && (
            <Grid item xs={12}>
              <WholesalerPropertySource
                store={store}
              />
            </Grid>
          )}
          {isWholesalerProperty && isWholesalerPropertySourceOther && (
            <Grid item xs={12}>
              <WholesalerPropertySourceOther
                store={store}
              />
            </Grid>
          )}
        </>
      )}
      {isOwned && (
        <>
          <Grid item xs={12}>
            {createCurrencyField({
              store,
              testId: 'acquisitionPrice',
              fieldName: 'acquisitionPrice',
              label: 'Acquisition Price',
              tooltip: tooltips.purchasePrice,
            })}
          </Grid>
          <Grid item xs={12}>
            {createDateField({
              store,
              testId: 'acquisitionDate',
              fieldName: 'acquisitionDate',
              label: 'Acquisition Date',
              tooltip: tooltips.purchaseDate,
              isRequired: true,
            })}
          </Grid>
          <Grid item xs={12}>
            {checkPropertyOwnership(store)}
            {createCurrencyField({
              store,
              testId: 'asIsValue',
              fieldName: 'asIsValue',
              label: 'As-Is Value',
              tooltip: tooltips.asIsValue,
            })}
          </Grid>
          <Grid item xs={12}>
            {createCurrencyField({
              store,
              testId: 'payoffId',
              fieldName: 'payoffAmount',
              label: 'Payoff Amount',
            })}
          </Grid>
          <Grid item xs={12}>
            {createCurrencyField({
              store,
              testId: 'sunkCost',
              fieldName: 'sunkCost',
              label: 'What is the Completed Renovations?',
              tooltip: tooltips.completedCapex,
            })}
          </Grid>
        </>
      )}
      {(isPurchase || isOwned) && (
        <>
          <Grid item xs={12}>
            {createCurrencyField({
              store,
              testId: 'afterRepairValue',
              fieldName: 'afterRepairValue',
              label: 'After Repair Value',
              tooltip: tooltips.afterRepairValue,
            })}
          </Grid>
          <Grid item xs={12}>
            {createTextField({
              store,
              testId: 'propertyDescription',
              fieldName: 'propertyDescription',
              label: "Property Description (Please describe the property and it's planned renovation, current status, and any applicable zoning changes, etc...)",
              multiline: true,
              inputProps: { maxLength: 500, rows: 3 }
            })}
          </Grid>
          <Grid item xs={12}>
            {createCurrencyField({
              store,
              testId: 'renovationBudget',
              fieldName: 'renovationBudget',
              label: 'What is the Renovation Budget?',
            })}
          </Grid>
          <Grid item xs={12}>
            {createSelectField({
              store,
              testId: 'describeRenovation',
              fieldName: 'describeRenovation',
              label: 'What is the Renovation/Construction Description?',
              options: detailsStore.renovationDescriptionOptions,
              tooltip: tooltips.renovationConstruction,
            })}
          </Grid>
          <Grid item xs={12}>
            <ExitStrategy store={store} />
          </Grid>
          <Grid item xs={12}>
            {createSelectField({
              store,
              testId: 'addingSquareFootage',
              fieldName: 'addingSquareFootage',
              label: 'Adding Square Footage?',
              options: yesNoOptions,
              tooltip: tooltips.addingSquareFootage,
            })}
          </Grid>
          <Grid item xs={12}>
            {createCurrencyField({
              store,
              testId: 'inPlaceRent',
              fieldName: 'inPlaceRent',
              label: 'In-Place Rent',
            })}
          </Grid>
          <Grid item xs={12}>
            {createCurrencyField({
              store,
              testId: 'MarketRent',
              fieldName: 'marketRent',
              label: 'Market Rent',
            })}
          </Grid>
          <Grid item xs={12}>
            <Button
              color="primary"
              onClick={onSubmit}>
              Save Property
            </Button>
          </Grid>
        </>
      )}
    </Grid>
  );
});

export default PropertyAndProject;
