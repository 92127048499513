import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { Layout } from '@roc/client-portal-shared/components';
import { observer } from 'mobx-react';
import { Paper } from '@roc/ui';
import { useEffect, useMemo } from 'react';
import {
  PaperList,
  PaperListItem,
  PaperListItemError,
  PaperListTitle,
} from './components/stabilizedBridgeAnalyzerComponents';
import {
  AnnualInsurance,
  AnnualTaxes,
  LoanPurpose,
  LoanPropertyType,
  VacantProperty,
  EstimatedAsIsValue,
  GrossMonthlyRent,
  MonthlyMarketRent,
  AnnualHOADues,
  ExitRate,
  PurchasePrice,
  PurchaseDate,
  LoanRate,
  CapitalImprovements,
  QualifyingFicoScore,
} from './components/stabilizedBridgeAnalyzerFields';
import { LoanPurposeOption } from './utils/stabilizedBridgeAnalyzerConstants';
import { AutocompleteField } from '../dscrCalculator/components/autocompleteField';
import { debounce } from '@roc/client-portal-shared/utils';
import { formatCurrency, formatPercentage } from '@roc/ui/utils';
import { StabilizedBridgeAnalyzerBaseStore } from './stores/stabilizedBridgeAnalyzerBaseStore';
import { CalculateButton } from '../dscrCalculator/components/dscrCalculatorComponents';

interface StabilizedBridgeAnalyzerSectionProps {
  store: StabilizedBridgeAnalyzerBaseStore;
}

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  tabs: {},
  calculatedValuesBorder: {
    border: '1px solid #BEBEBE',
    borderRadius: '15px',
    padding: 16,
  },
}));

export const StabilizedBridgeAnalyzer = observer(
  (props: StabilizedBridgeAnalyzerSectionProps) => {

    useEffect(() => {
      const actualDate = new Date();
      props.store.onFieldChange('purchaseDate', actualDate);
    }, []);

    const classes = useStyles();
    const stabilizedBridgeAnalyzerStore = props.store;
    const formStore = stabilizedBridgeAnalyzerStore;

    const calculateStabillizedBridgeAnalyzerData = () => {
      stabilizedBridgeAnalyzerStore.fetchStabillizedBridgeAnalyzerData();
    };

    const fetchOptions = useMemo(
      () => debounce(searchText => formStore.fetchOptions(searchText), 500),
      [formStore]
    );

    return (
      <Layout title="Stabilized Bridge Analyzer" maxWidth={'xl'}>
        <Paper className={classes.paper}>
          <Grid container spacing={4}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Grid container xs={12} spacing={2}>
                  <Grid item xs={12}>
                    <AutocompleteField
                      value={formStore.loanSearchText}
                      options={formStore.loanSearchOptions}
                      onChange={text => {
                        formStore.loanSearchText = text;
                        fetchOptions(formStore.loanSearchText);
                      }}
                      onSelect={opt => {
                        formStore.loanSearchText = opt.label;
                        formStore.fetchLoanValues(opt.value);
                      }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <LoanValuesSection store={formStore} />
                  </Grid>

                  <Grid item xs={12}>
                    <TransactionValuesSection store={formStore} />
                  </Grid>

                  <Grid item xs={12} container justifyContent="center">
                    <CalculateButton onClick={calculateStabillizedBridgeAnalyzerData} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid
                  container
                  spacing={2}
                  className={classes.calculatedValuesBorder}
                >
                  <Grid item xs={12}>
                    <Typography variant={'h4'}>
                      Calculated Stabilized Bridge Analyzer Values
                    </Typography>
                  </Grid>
                  <CalculatedValues store={formStore} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        <Paper className={classes.paper}>
          The proposed terms and pricing presented herein do not represent a commitment or offer to lend, but rather a summary for discussion purposes of certain selected terms of the proposed financing. This proposal does not create any legally binding obligations on any party hereto.
        </Paper>
      </Layout>
    );
  }
);

const CalculatedValues = observer(({ store }: StabilizedBridgeAnalyzerSectionProps) => {
  const { calculatedValues } = store;

  const formatCurr = value =>
    value || value === 0 ? formatCurrency(value) : '';

  const formatPercent = value =>
    value || value === 0 ? formatPercentage(value) : '';

  return (
    <>
      <Grid item xs={12}>
        <PaperList>
          <PaperListTitle text="Stabilized Bridge Analyzer Totals" />
          {calculatedValues?.maximumAmount > 75000 &&
            <PaperListItem
              label="Maximum Loan Amount"
              value={
                calculatedValues
                  ? formatCurr(calculatedValues.maximumAmount)
                  : '-'
              }
            />}
          {calculatedValues?.maximumAmount < 75000 &&
            <PaperListItemError
              label="Maximum Loan Amount"
              value={
                calculatedValues
                  ? formatCurr(calculatedValues.maximumAmount)
                  : '-'
              }
            />}
          <PaperListItem
            label="LTV"
            value={
              calculatedValues
                ? formatPercent(calculatedValues.ltv)
                : '-'
            }
          />
          <PaperListItem
            label="LTC"
            value={
              calculatedValues
                ? formatPercent(calculatedValues.ltc)
                : '-'
            }
          />
          <PaperListItem
            label="In Place DSCR"
            value={
              calculatedValues
                ? calculatedValues.inPlaceDSCR
                : '-'
            }
          />
          <PaperListItem
            label="Exit DSCR"
            value={
              calculatedValues
                ? calculatedValues.exitDSCR
                : '-'
            }
          />
        </PaperList>
      </Grid>
    </>
  );
});

export const LoanValuesSection = ({ store }: StabilizedBridgeAnalyzerSectionProps) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant={'h5'}>Loan Values</Typography>
      </Grid>
      <LoanPurpose store={store} />
      <VacantProperty store={store} />
      <LoanRate store={store} />
      <ExitRate store={store} />
      <GrossMonthlyRent store={store} />
      <MonthlyMarketRent store={store} />
      <LoanPropertyType store={store} />
      <AnnualTaxes store={store} />
      <EstimatedAsIsValue store={store} />
      <AnnualHOADues store={store} />
      <QualifyingFicoScore store={store} />
      <AnnualInsurance store={store} />
    </Grid>
  );
};

export const TransactionValuesSection = observer(
  ({ store }: StabilizedBridgeAnalyzerSectionProps) => {
    const loanPurpose = store.form.fields.loanPurpose.value;

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant={'h5'}>Transaction Values</Typography>
        </Grid>

        {loanPurpose === LoanPurposeOption.PURCHASE && (
          <>
            <PurchasePrice store={store} />
          </>
        )}
        {loanPurpose === LoanPurposeOption.REFINANCE && (
          <>
            <PurchasePrice store={store} />
            <PurchaseDate store={store} />
            <CapitalImprovements store={store} />
          </>
        )}
      </Grid>
    );
  }
);
