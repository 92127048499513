import {
  Box,
  Grid,
  IconButton,

  Typography,
} from '@material-ui/core';
import { lighten } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { Layout, useAppConfigurations } from '@roc/feature-app-core';
import { Paper } from '@roc/ui';
import React from 'react';
import { LoanSubType } from '@roc/feature-utils';
import { observer } from 'mobx-react';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(4),
  },
  loanTypePaper: {
    padding: theme.spacing(4),
    border: `1px solid ${theme.palette.primary.light}`,
    '&:hover': {
      background: lighten(theme.palette.primary.main, 0.9),
    },
  },
  loanTypePaperStbBridge: {
    padding: theme.spacing(4),
    border: `1px solid ${theme.palette.primary.light}`,
    '&:hover': {
      background: lighten(theme.palette.primary.main, 0.9),
    },
    paddingBottom: '2.8em',
  },
  icon: {
    width: 20,
    height: 20,
  },
  iconButton: {
    padding: 0,
    minHeight: 0,
    minWidth: 0,
  },
}));

export const LoanTypeSelection = observer(({ store }) => {
  const classes = useStyles();
  const { handleSelectLoanSubType } = store;

  return (
    <Layout maxWidth={'md'}>
      <Paper className={classes.paper}>
        <Box mb={4}>
          <Typography align="center" variant="h3">
            Order Appraisal
          </Typography>
        </Box>
        <Box my={4} mt={8}>
          <Typography align="center" variant="h4" color="primary">
            BRIDGE LOANS
          </Typography>
        </Box>
        <Box mx={'auto'} mb={4} maxWidth={500}>
          <Grid container direction="row" justifyContent="center" spacing={4}>
            <Grid item xs={12} md={6}>
              <IconButton
                className={classes.iconButton}
                onClick={() => {
                  handleSelectLoanSubType(LoanSubType.FIX_AND_FLIP);
                }}
              >
                <Paper className={classes.loanTypePaper}>
                  <Typography align="center" variant="h6" gutterBottom>
                    FIX & FLIP
                  </Typography>
                  <Typography
                    align="center"
                    variant="body2"
                    color="textSecondary"
                  >
                    Residential (1-4 Units)
                  </Typography>
                </Paper>
              </IconButton>
            </Grid>
            <Grid item xs={12} md={6}>
              <IconButton
                className={classes.iconButton}
                onClick={() => {
                  handleSelectLoanSubType(LoanSubType.GROUND_UP);
                }}
              >
                <Paper className={classes.loanTypePaper}>
                  <Typography align="center" variant="h6" gutterBottom>
                    GROUND UP
                  </Typography>
                  <Typography
                    align="center"
                    variant="body2"
                    color="textSecondary"
                  >
                    Residential (1-4 Units)
                  </Typography>
                </Paper>
              </IconButton>
            </Grid>
            <Grid item xs={12} md={6}>
              <IconButton
                className={classes.iconButton}
                onClick={() => {
                  handleSelectLoanSubType(LoanSubType.MULTIFAMILY_BRIDGE_5PLUS);
                }}
              >
                <Paper className={classes.loanTypePaper}>
                  <Typography align="center" variant="h6" gutterBottom>
                    MULTIFAMILY BRIDGE
                  </Typography>
                  <Typography
                    align="center"
                    variant="body2"
                    color="textSecondary"
                  >
                    5+ Unit Multifamily
                  </Typography>
                </Paper>
              </IconButton>
            </Grid>
            <Grid item xs={12} md={6}>
              <IconButton
                className={classes.iconButton}
                onClick={() => {
                  handleSelectLoanSubType(LoanSubType.STABILIZED_BRIDGE);
                }}
              >
                <Paper className={classes.loanTypePaperStbBridge}>
                  <Typography align="center" variant="h6" gutterBottom>
                    STABILIZED BRIDGE
                  </Typography>
                  <Typography
                    align="center"
                    variant="body2"
                    color="textSecondary"
                  >
                    Single Family / 2-4 Unit / Warrantable Condos
                  </Typography>
                </Paper>
              </IconButton>
            </Grid>
          </Grid>
        </Box>

        <Box my={4} mt={8}>
          <Typography align="center" variant="h4" color="primary">
            TERM LOANS
          </Typography>
        </Box>
        <Box mx={'auto'} mb={4} maxWidth={500}>
          <Grid container direction="row" justifyContent="center" spacing={4}>
            <Grid item xs={12} md={6}>
              <IconButton
                className={classes.iconButton}
                onClick={() => {
                  handleSelectLoanSubType(LoanSubType.SINGLE_PROPERTY);
                }}
              >
                <Paper className={classes.loanTypePaper}>
                  <Typography align="center" variant="h6" gutterBottom>
                    SINGLE PROPERTY
                  </Typography>
                  <Typography
                    align="center"
                    variant="body2"
                    color="textSecondary"
                  >
                    Single Family / 2-4 Unit / Townhomes / PUD / Warrantable
                    Condos / 5+ Unit Multifamily / Mixed Use
                  </Typography>
                </Paper>
              </IconButton>
            </Grid>
            {/* <Grid item xs={12} md={6}>
              <IconButton
                className={classes.iconButton}
                onClick={() => {
                  handleSelectLoanSubType(LoanSubType.RENTAL_PORTFOLIO);
                }}
              >
                <Paper className={classes.loanTypePaper}>
                  <Typography align="center" variant="h6" gutterBottom>
                    RENTAL PORTFOLIOS
                  </Typography>
                  <Typography
                    align="center"
                    variant="body2"
                    color="textSecondary"
                  >
                    Single Family / 2-4 Unit / Townhomes / PUD / Warrantable
                    Condos / 5+ Unit Multifamily / Mixed Use
                  </Typography>
                </Paper>
              </IconButton>
            </Grid> */}
          </Grid>
        </Box>
      </Paper>
    </Layout>
  );
});
