import { useParams } from "react-router";
import { useStore } from "../../hooks";
import { observer } from "mobx-react";
import { useEffect } from "react";
import { Appraisal1004dPayments, EncodedParams } from "./appraisal1004dPaymentModule";
import { decodeData } from "./arrPaymentsUtils";
import CancelledOrderLink from "./cancelledOrderLink";

export const Appraisal1004dPaymentContainer = observer(() => {
  const { appraisal1004dPaymentStore } = useStore();
  const { appraisal1004dDocumentStore } = appraisal1004dPaymentStore;

  const { encodedParams } = useParams<EncodedParams>();
  const { entityId, tamariskFlowType } = decodeData(encodedParams)

  useEffect(() => {
    appraisal1004dDocumentStore.checkIfValidLink(entityId, tamariskFlowType)
  }, [entityId, tamariskFlowType])

  if (typeof appraisal1004dDocumentStore.isLinkValid == 'undefined') {
    return <></>
  }
  return appraisal1004dDocumentStore.isLinkValid ? <Appraisal1004dPayments /> : <CancelledOrderLink />;
})
