import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import { Box, Grid } from '@material-ui/core';
import { Button } from '@roc/ui';

const useStyles = makeStyles({
  root: {
  },
});

export const AppraiserCard = () => {
  const classes = useStyles();

  return (
    <Card className={classes.root} elevation={0} variant='outlined'>
      <Box display={'flex'} flexDirection={'row'}>
        <Box flexGrow={1}>
          <CardContent style={{
            padding: 16
          }}>
            <Box>
              <Grid container>
                <Grid item xs={6}>
                  <Typography variant="body2" color="textSecondary" component="p">
                    Appraiser
                  </Typography>
                  <Typography variant="h5" component="h2">
                    Kenneth J. Hartman
                  </Typography>
                </Grid>
                <Grid item xs={6} style={{
                  textAlign: 'right'
                }}>
                  <Button testId='appraisalReport' size="small"
                    color="default"
                    variant="outlined"
                    onClick={() => { }}>
                    Appraisal Report
                  </Button>
                </Grid>
              </Grid>

              <Box width={'100%'} height={'1px'} bgcolor={'#eee'} my={1}></Box>

              <Grid container style={{
                textAlign: 'center'
              }} spacing={1}>
                <Grid item xs={2}>
                  <Typography variant="body1" component="p" style={{
                    fontWeight: 'bold'
                  }}>
                    ARV
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p">
                    Order Type
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="body1" component="p" style={{
                    fontWeight: 'bold'
                  }}>
                    NY, CA, MI
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p">
                    State Valued
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="body1" component="p" style={{
                    fontWeight: 'bold'
                  }}>
                    10.2
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p">
                    Value/Sale Price Ratio
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="body1" component="p" style={{
                    fontWeight: 'bold'
                  }}>
                    7
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p">
                    Files
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="body1" component="p" style={{
                    fontWeight: 'bold'
                  }}>
                    14
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p">
                    DQ Loans
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="body1" component="p" style={{
                    fontWeight: 'bold'
                  }}>
                    5
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p">
                    Paid Off Loans
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </CardContent>
        </Box>
      </Box>
    </Card>
  );
}
