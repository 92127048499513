import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import { makeStyles, lighten } from '@material-ui/core/styles';
import { CheckCircle, Delete } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import { mapLtvToValue } from '../../../loanSubmission/stores/common/pricerSummaryStore';
import { observer } from 'mobx-react';
import { formatCurrency, formatPercentage } from '@roc/ui/utils';
import clsx from 'clsx';
import { amortizationOptions } from '../../utils/constants';
import { prePaymentPenaltyOptionsSingleProperty } from '@roc/feature-utils';
import { postLoanApprovalRateLockLabels, swapIndexLabels } from '../utils/constants';

const DARK_BORDER = '1px solid #333333';

const useStyles = makeStyles(theme => ({
  table: {
    width: 'auto',
    borderCollapse: 'separate',
    position: 'relative',
    '& td': {
      textAlign: 'center',
      fontWeight: 'bold',
      width: '200px',
      padding: theme.spacing(1, 4),
      borderLeft: '1px solid rgba(224, 224, 224, 1)',
      borderBottom: '1px solid rgba(224, 224, 224, 1)',
    },
    '& td:first-child': {
      width: '300px',
      fontWeight: 'normal',
      textAlign: 'left',
      padding: theme.spacing(1),
    },
  },
  tableOuterBorder: {
    '& td:first-child': {
      borderLeft: DARK_BORDER,
    },
    '& td:last-child': {
      borderRight: DARK_BORDER,
    },
    '& tr:first-child': {
      '& td': {
        borderTop: DARK_BORDER,
      },
      '& td:first-child': {
        borderTopLeftRadius: theme.spacing(1),
      },
      '& td:last-child': {
        borderTopRightRadius: theme.spacing(1),
      },
    },
    '& tr:nth-last-child(2)': {
      '& td:first-child': {
        borderBottom: DARK_BORDER,
        borderBottomLeftRadius: theme.spacing(1),
      },
    },
    '& tr:last-child': {
      '& td': {
        borderBottom: DARK_BORDER,
      },
      '& td:nth-child(2)': {
        borderBottomLeftRadius: theme.spacing(1),
        borderLeft: DARK_BORDER,
      },
      '& td:last-child': {
        borderBottomRightRadius: theme.spacing(1),
      },
    },
  },
  errorContainer: {
    color: theme.palette.error.main,
  },
  gray: {
    color: 'white',
    backgroundColor: '#333333',
  },
  lightGray: {
    color: 'white',
    backgroundColor: '#444444',
  },
  rateCell: {
    display: 'inline-flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'relative',
    padding: theme.spacing(1),
    paddingLeft: '26px',
    marginRight: '26px',
  },
  rateCellSelected: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    marginRight: '4px',
    border: '1px solid',
    borderColor: theme.palette.success.main,
    color: theme.palette.success.main,
    borderRadius: theme.spacing(1),
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  },
  lowestRate: {
    textAlign: 'center',
    fontSize: '0.8em',
    color: theme.palette.success.main,
    lineHeight: '12px',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
  },
  selectedOptionBorder: {
    border: '4px solid ' + theme.palette.success.main,
    position: 'absolute',
    top: 0,
    bottom: 0,
    marginLeft: '-1px',
    width: '200px',
    borderRadius: theme.spacing(1),
    pointerEvents: 'none'
  },
  selectedOptionCell: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.common.white,
    paddingLeft: '0 !important',
    paddingRight: '0 !important'
  },
}));



export const ComparePricingOptions = observer(({ store, rateBuydownOptions = [], pricerFeesEnabled }) => {
  const classes = useStyles();
  const { pricerSummaryStore } = store;

  const selectedOptions = pricerSummaryStore.pricingOptions;

  if (!pricerSummaryStore.pricingOptions?.length) {
    return (
      <Box maxWidth={500} margin={'auto'}>
        <Alert severity="info">You can compare up to 3 pricing options side by side!<br />
          Simply select each option you're interested in the rate table and it'll automatically populate the comparison chart and downloadable quote sheet</Alert>
      </Box>
    );
  }

  const isSelected = (option) => {
    return option.key === pricerSummaryStore.selectedPricingOption?.key
  }

  const getSpreadOverIndex = (option) => {
    const { spreadRate } = option.rateRow?.[option.ltvBand] || {};
    return spreadRate ? `${spreadRate.toFixed(4)}%` : '';

  }

  const getTodayGrossRate = (option) => {
    const { spreadRate, swapRate } = option.rateRow?.[option.ltvBand] || {};
    return spreadRate && swapRate ? `${(spreadRate + swapRate).toFixed(4)}%` : '';
  }

  return (
    <Table
      className={clsx(classes.table, classes.tableOuterBorder)}
      aria-label="avilable loan rates"
      style={{
        margin: 'auto'
      }}
    >
      <TableRow>
        <TableCell />
        {selectedOptions.map((option, i) => (
          <TableCell>
            <Box>Option {i + 1}</Box>
            <Box>
              <Button
                size="small"
                color="primary"
                onClick={() => pricerSummaryStore.removeFromQuote(option)}
              >
                <Delete /> Remove
              </Button>
            </Box>
          </TableCell>
        ))}
      </TableRow>
      <TableRow>
        <TableCell>LTV</TableCell>
        {selectedOptions.map((option, i) => (
          <TableCell>
            <Box>{mapLtvToValue(option.ltvBand)}%</Box>
          </TableCell>
        ))}
      </TableRow>
      <TableRow>
        <TableCell>Loan Amount</TableCell>
        {selectedOptions.map((option, i) => (
          <TableCell>
            <Box>{formatCurrency(option.pricerLoanTerms.amount)}</Box>
          </TableCell>
        ))}
      </TableRow>
      <TableRow>
        <TableCell>Amortization</TableCell>
        {selectedOptions.map((option, i) => (
          <TableCell>
            <Box>{amortizationOptions.find(opt => opt.value == option.formValues.amortization)?.label}</Box>
          </TableCell>
        ))}
      </TableRow>
      <TableRow>
        <TableCell>Pre-Payment Penalty</TableCell>
        {selectedOptions.map((option, i) => (
          <TableCell>
            <Box>{(pricerSummaryStore.prepaymentPenaltyOptions || []).find(opt => opt.value == option.formValues.prePaymentPenalty)?.label}</Box>
          </TableCell>
        ))}
      </TableRow>
      <TableRow>
        <TableCell>Rate Type</TableCell>
        {selectedOptions.map((option, i) => (
          <TableCell>
            <Box>{option.rateRow.rateType}</Box>
          </TableCell>
        ))}
      </TableRow>
      <TableRow>
        <TableCell>Rate Buydown Fee</TableCell>
        {selectedOptions.map((option, i) => (
          <TableCell>
            <Box>{rateBuydownOptions.find(opt => opt.value == option.formValues.rateBuydown)?.label}</Box>
          </TableCell>
        ))}
      </TableRow>
      <TableRow>
        <TableCell>Index</TableCell>
        {selectedOptions.map((option, i) => (
          <TableCell>
            <Box>{swapIndexLabels[option.rateRow[option.ltvBand].swapIndex]}</Box>
          </TableCell>
        ))}
      </TableRow>
      <TableRow>
        <TableCell>Spread Over Index</TableCell>
        {selectedOptions.map((option, i) => (
          <TableCell>
            <Box>{getSpreadOverIndex(option)}</Box>
          </TableCell>
        ))}
      </TableRow>
      <TableRow>
        <TableCell>Today's Gross Rate</TableCell>
        {selectedOptions.map((option, i) => (
          <TableCell>
            <Box>{getTodayGrossRate(option)}</Box>
          </TableCell>
        ))}
      </TableRow>
      <TableRow>
        <TableCell>Monthly Mortgage Payment</TableCell>
        {selectedOptions.map((option, i) => (
          <TableCell>
            <Box>{formatCurrency(option.pricerLoanTerms.monthlyPaymentWithoutExpenses)}</Box>
          </TableCell>
        ))}
      </TableRow>
      <TableRow>
        <TableCell>Post Loan Approval Rate Lock</TableCell>
        {selectedOptions.map((option, i) => (
          <TableCell>
            <Box>{postLoanApprovalRateLockLabels[option.programType]}</Box>
          </TableCell>
        ))}
      </TableRow>
      {/*<TableRow>
        <TableCell>Estimated Cash to/from Borrower at</TableCell>
        {selectedOptions.map((option, i) => (
          <TableCell>
            <Box>
              {formatCurrency(
                ((option.pricerLoanTerms.loanPurpose.value === 'Refinance' ? option.pricerLoanTerms.totalDebtPayoff.value ?? 0 : option.pricerLoanTerms.acquisitionPrice.value ?? 0) +
                  ((option.pricerLoanTerms.amount ?? 0) * 0.03)) -
                option.pricerLoanTerms.amount ?? 0
              )}
            </Box>
          </TableCell>
        ))}
              </TableRow>*/}
      {pricerFeesEnabled &&
        <>
          <TableRow>
            <TableCell>Origination Points</TableCell>
            {selectedOptions.map((option, i) => (
              <TableCell>
                <Box>{formatPercentage(option.totalOriginationPoints)}</Box>
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell>Total Underwriting and Processing Fees</TableCell>
            {selectedOptions.map((option, i) => (
              <TableCell>
                <Box>{formatCurrency(option.feeSubtotalWithoutPoints)}</Box>
              </TableCell>
            ))}
          </TableRow>
        </>
      }
      <TableRow>
        <TableCell style={{ border: 0 }} />
        {selectedOptions.map((option, i) => (
          <>
            {isSelected(option) ? (
              <TableCell
                className={clsx(
                  option.key ===
                  pricerSummaryStore.selectedPricingOption?.key &&
                  classes.selectedOptionCell
                )}
              >
                <div className={classes.selectedOptionBorder}></div>
                <Box display='flex' alignItems="center" justifyContent="center">
                  <CheckCircle />&nbsp;
                  <Typography>
                    Selected
                  </Typography>
                </Box>
              </TableCell>
            ) : (
              <TableCell>
                <Box>
                  <Button
                    variant="outlined"
                    onClick={() => pricerSummaryStore.selectQuoteOption(option)}
                  >
                    Select
                  </Button>
                </Box>
              </TableCell>
            )}
          </>
        ))}
      </TableRow>
    </Table>
  );
});
