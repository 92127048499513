import { Avatar, Box, Grid, Tooltip, Typography } from '@material-ui/core';
import { Theme, makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import { getNameInCamelCase, Roles } from '@roc/feature-utils';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import { useUserStore, useWebSocketStore } from '../../hooks';
import { Chat, Headset, Person, OpenInNew, Add } from '@material-ui/icons';
import { Button, ConditionalWrapper } from '@roc/ui';

const useStyles = makeStyles(theme => ({
  statusIndicator: {
    width: 14,
    height: 14,
    marginRight: 6,
    borderRadius: 4,
  },
  onlineStatusIndicator: {
    background: 'radial-gradient(circle, #0ce90c, #009500)'
  },
  offlineStatusIndicator: {
    background: 'radial-gradient(circle, #fff, #bbb)'
  },
}));

const DisplayNameTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: '#fff',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: theme.typography.pxToRem(18),
    fontWeight: 700,
    border: '1px solid #dadde9',
  },
}))(Tooltip);

const DisplayNameTooltipContent = observer(({ displayName, userId, isUserActive, allowHuddle, onUserNewConversation, onHuddle, calendlyLink, roleName, isInternalUser, onOpenAddTask }) => {
  const { userStore } = useUserStore();
  const isMessageSenderBackOfficeOrInternal = isInternalUser || [Roles.BACK_OFFICE_LEAD, Roles.BACK_OFFICE_ORIGINATOR, Roles.BACK_OFFICE_PROCESSOR].includes(roleName);
  const isLoggedInUserInternalOrBackOffice = userStore.isBackOfficeUser || userStore.isNportalUser;
 
  return (
    <Grid container>
      <Grid item xs={12}>
        <Box display={'flex'} p={2} alignItems={'center'}>
          <Box>
            <Avatar variant="square">
              <Person fontSize={'large'} style={{ color: '#333' }} />
            </Avatar>
          </Box>
          <Box flexGrow={1} pl={1}>{displayName}</Box>
        </Box>
      </Grid>
      {!isUserActive ? <></> :
        <>
          <Grid item xs={12}>
            <Box borderBottom={'1px solid #eee'}></Box>
          </Grid>
          <Grid item xs={12}>
            <Box display={'flex'} p={2} alignItems={'center'} flexWrap="wrap">
              {calendlyLink && (
                <Box mr={1} mb={1}>
                  <Button
                    color='primary'
                    variant='outlined'
                    testId='calendly-link'
                    size="medium"
                    endIcon={<OpenInNew style={{ color: '#2196f3' }} />}
                    onClick={() => {
                      if ((window as any).require) {
                        const { shell } = (window as any).require('electron');
                        shell?.openExternal(calendlyLink);
                      } else {
                        window.open(calendlyLink);
                      }
                    }}
                  >
                    Calendly Link
                  </Button>
                </Box>
              )}
              {onUserNewConversation && userId && (
                <Box mr={1} mb={1}>
                  <Button
                    color='primary'
                    variant='outlined'
                    testId='new-conversation'
                    size="medium"
                    onClick={() => {
                      onUserNewConversation(userId);
                    }}
                    startIcon={<Chat fontSize="medium" />}
                  >
                    New Conversation
                  </Button>
                </Box>
              )}
              {allowHuddle && onHuddle && (
                <Box mb={1}>
                  <Button
                    color='primary'
                    variant='outlined'
                    testId='huddle'
                    size="medium"
                    onClick={() => {
                      onHuddle([userId]);
                    }}
                    startIcon={<Headset fontSize="medium" />}
                  >
                    Huddle
                  </Button>
                </Box>
              )}
              {(onOpenAddTask && isMessageSenderBackOfficeOrInternal && isLoggedInUserInternalOrBackOffice) && (
                <Box mb={1}>
                  <Button
                    color='primary'
                    variant='outlined'
                    testId='createTask'
                    size="medium"
                    onClick={() => onOpenAddTask(userId)}
                    startIcon={<Add fontSize="medium" />}
                  >
                    Create Task
                  </Button>
                </Box>
              )}
            </Box>
          </Grid>
        </>
      }
    </Grid>
  );
});


export const DisplayName = ({ displayName, firstName, lastName, isUserActive }) => {
  const nameToDisplay = displayName || getNameInCamelCase(firstName, lastName);
  const theme = useTheme();
  const color = !isUserActive ? theme.palette.error.main : undefined;
  return (
    <>
      <Box style={{ color }}>
        {nameToDisplay}
      </Box>
      {(!isUserActive) &&
        <Typography style={{ color: theme.palette.error.main, marginLeft: '10px' }}>{'[Deactivated]'}</Typography>
      }
    </>
  );
}

const StatusIndicator = ({ isOnline }) => {
  const classes = useStyles();
  return (
    <Tooltip title={isOnline ? 'Online' : 'Offline'} placement='top'>
      <Box className={clsx(classes.statusIndicator, isOnline ? classes.onlineStatusIndicator : classes.offlineStatusIndicator)}></Box>
    </Tooltip>
  );
}

export const DisplayNameWithOnlineStatus = observer(({ userId,
  displayName = undefined,
  firstName = undefined,
  lastName = undefined,
  isActive = undefined,
  allowHuddle = false,
  onHuddle = undefined,
  onUserNewConversation = undefined,
  onOpenAddTask = undefined,
  showHoverPopup = false,
  participantCalendlyLink = undefined,
  roleName = undefined,
  isInternalUser = false,
}: {
  userId: number;
  displayName?: string;
  firstName?: string;
  lastName?: string;
  isActive: boolean;
  allowHuddle?: boolean;
  onHuddle?: (inviteUserIds: number[]) => void;
  onUserNewConversation?: (inviteUserId: number) => void;
  onOpenAddTask?: (userId: number) => void;
  showHoverPopup?: boolean;
  participantCalendlyLink?: string,
  roleName?: string,
  isInternalUser?: boolean,
}) => {
  const { webSocketStore } = useWebSocketStore();
  const onlineUsers = webSocketStore?.onlineUsers;
  const isOnline = (onlineUsers && userId) ? onlineUsers[userId] : false;
  
  return (
    <>
      {
        onlineUsers == undefined ? null :
          <Box display="flex">
            {<StatusIndicator isOnline={isOnline} />}
          </Box>
      }
      <ConditionalWrapper condition={showHoverPopup} wrapper={(children) => {
        return (<DisplayNameTooltip
          interactive
          placement='top'
          title={<DisplayNameTooltipContent
            displayName={displayName}
            userId={userId}
            allowHuddle={allowHuddle}
            onUserNewConversation={onUserNewConversation}
            onHuddle={onHuddle}
            onOpenAddTask={onOpenAddTask}
            isUserActive={isActive}
            calendlyLink={participantCalendlyLink}
            roleName={roleName}
            isInternalUser={isInternalUser}
          />}>
          <div style={{
            cursor: 'pointer'
          }}>
            {children}
          </div>
        </DisplayNameTooltip>)
      }}>
        <DisplayName
          displayName={displayName}
          firstName={firstName}
          lastName={lastName}
          isUserActive={isActive} />
      </ConditionalWrapper>
    </>
  )
});