
import { observer } from "mobx-react";
import { Button, StandardDialog, SelectField } from '@roc/ui';
import { leadStatusOptions } from '../leads/constants/constants';


export const BulkLeadChangeStatus = observer(({ open, handleClose, salesforceLeadStore }) => {

  const handleSaveClick = async () => {
    await salesforceLeadStore.bulkChangeStatus();
    salesforceLeadStore.resetLeadsIds();
    salesforceLeadStore.resetBorrowerIds();
    salesforceLeadStore.resetCompanyContactIds();
    handleClose();
  };

  return (
    <StandardDialog
      open={open}
      title="Change Bulk Lead Status"
      maxWidth="lg"
      handleClose={handleClose}
      dialogContent={
        <>
          <SelectField
            standaloneLabel
            label={'Select the new status for leads'}
            name={'status'}
            value={salesforceLeadStore.newLeadStatus}
            errorText={null}
            options={leadStatusOptions}
            onChange={value => salesforceLeadStore.setLeadStatus(value)}
            variant="outlined"
            fullWidth
            shrink
            required
            testId="leadStatus"
          />
        </>
      }
      dialogActions={
        <>
          <Button
            style={{ marginRight: '8px' }}
            onClick={handleClose}
            color="primary"
            variant="outlined"
            testId="cancel"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSaveClick}
            testId="next"
          >
            Change Status
          </Button>
        </>
      }
    />
  );
});
