import { Grid } from '@material-ui/core';
import { useStore } from '@roc/client-portal-shared/hooks';
import { formatDate } from '@roc/client-portal-shared/utils';
import {
  Currency,
  Label,
  LabeledText,
  Percentage,
  Section,
  Value,
} from '../../components/fixFlipSizerSummaryComponents';
import {
  addingSquareFootageOptions,
  creditOptions,
  experienceScoreValues,
  renovationConstructionOptions,
  yesNoOptions,
} from '../../utils/constants';
import { findOptionLabel } from '../../utils/sizerHelper';

const SummaryAlreadyOwn = props => {
  const { fixFlipSizerStore } = useStore();
  const sizer = fixFlipSizerStore.detailsStore.sizer;

  const outputData = sizer.outputData;
  const data = sizer;

  return (
    <Grid container spacing={2}>
      <Section label="Property and Project" />
      <LabeledText label="Address" value={data.address} testId="address"/>
      <LabeledText label="Project Desc" value={data.projectDescription} testId="projectDescription"/>
      <LabeledText
        label="Renovation/Construction Desc"
        value={findOptionLabel(
          renovationConstructionOptions,
          sizer.property.describeRenovation
        )}
        testId="renovation"
      />
      <LabeledText
        label="Adding Square Footage?"
        value={findOptionLabel(
          addingSquareFootageOptions,
          data.addingSquareFootage
        )}
        testId="adding-square-footage"
      />
      <Section label="Loan Terms" />
      <LabeledText label="Loan Amount" value={outputData.loanAmount} currency testId="loanAmount"/>
      <LabeledText label="Interest Rate" value={data.interestRate} percentage testId="interestRate"/>

      <Label label="Points (In|Out)" xs={12} md={3} />
      <Percentage value={sizer.pointsIn} xs={6} md={3} testId="pointsIn"/>
      <Percentage value={sizer.pointsOut} xs={6} md={6} testId="pointsOut"/>

      <LabeledText label="Term (Months)" value={sizer.termInMonths} testId="termInMonths"/>

      <Label label="Interest Reserve (months|$)" xs={12} md={3} />
      <Value value={sizer.interestReserveMonths} xs={6} md={3} testId="interestReserveMonths" />
      <Currency value={outputData.interestReserveAmount} xs={6} md={6} testId="interestReserveAmount" />

      <LabeledText
        label="Pledge of Shares"
        value={findOptionLabel(yesNoOptions, data.pledgeOfShares)}
        testId="pledgeOfShares"
      />

      <Section label="Guarantors" />

      <LabeledText
        label="Credit"
        value={findOptionLabel(creditOptions, sizer.credit)}
        testId="credit"
      />
      <LabeledText label="Net Worth" value={sizer.borrower.netWorth} currency testId="netWorth" />
      <Label label="Experience Score" xs={12} md={3} />
      <Value value={sizer.borrower.experience} xs={6} md={3} testId="experience" />
      <Value
        value={experienceScoreValues?.[sizer.borrower.experience]}
        xs={6}
        md={6}
        testId="experienceScore"
      />
      <LabeledText
        label="Experience Description"
        value={sizer.borrower.experienceComments}
        testId="experienceComments"
      />

      <Section label="LTC/LTV" />

      <Grid item container spacing={1}>
        <Grid item xs={4} />
        <Grid item container xs={8}>
          <Label label="Loan" xs={3} />
          <Grid item container xs={7}>
            <Grid item xs={6} />
            <Label label="Cost" />
          </Grid>
          <Label label="LTC" />
        </Grid>
      </Grid>

      <Grid item container spacing={1}>
        <Label label="Initial Loan" xs={4} />
        <Grid item container xs={8}>
          <Currency value={outputData.initialLoanLoan} xs={3} testId="initialLoanLoan" />
          <Grid item container xs={7}>
            <Label label="Purchase" xs={6} />
            <Currency value={sizer.property.purchasePrice} testId="purchasePrice" />
          </Grid>
          <Percentage value={outputData.initialLoanLtc * 100} decimals={1} testId="initialLoanLtc"/>
        </Grid>
      </Grid>

      <Grid item container spacing={1}>
        <Grid item xs={4} />
        <Grid item container xs={8}>
          <Grid item xs={3} />
          <Grid item container xs={7}>
            <Label label="Purchase Date" xs={6} />
            <Value
              value={
                sizer.property.purchaseDate &&
                formatDate(sizer.property.purchaseDate, 'MMM - yyyy')
              }
              testId="purchaseDate"
            />
          </Grid>
          <Grid item />
        </Grid>
      </Grid>
      <Grid item container spacing={1}>
        <Grid item xs={4} />

        <Grid item container xs={8}>
          <Grid item xs={3} />
          <Grid item container xs={7}>
            <Label label="Rehab Spent" xs={6} />
            <Currency value={data.rehabToDate} testId="rehabToDate"/>
          </Grid>
          <Grid item />
        </Grid>
      </Grid>
      <Grid item container spacing={1}>
        <Grid item xs={4} />

        <Grid item container xs={8}>
          <Grid item xs={3} />
          <Grid item container xs={7}>
            <Label label="Cost to Date" xs={6} />
            <Currency value={outputData.purchasePrice} testId="purchasePriceLtc"/>
          </Grid>
          <Grid item />
        </Grid>
      </Grid>

      <Grid item container spacing={1}>
        <Label label="Construction Holdback" xs={4} />
        <Grid item container xs={8}>
          <Currency value={outputData.constructionHoldbackLoan} xs={3} testId="constructionHoldbackLoan" />
          <Grid item container xs={7}>
            <Grid item xs={6} />
            <Currency value={outputData.constructionHoldbackCost} testId="constructionHoldbackCost" />
          </Grid>
          <Percentage
            value={outputData.constructionHoldbackLtc * 100}
            decimals={1}
            testId="constructionHoldbackLtc"
          />
        </Grid>
      </Grid>

      <Grid item container spacing={1}>
        <Label label="-" xs={4} />
        <Grid item container xs={8}>
          <Currency value={outputData.totalLoan} xs={3} testId="totalLoan" />
          <Grid item container xs={7}>
            <Grid item xs={6} />
            <Currency value={outputData.totalCost} testId="totalCost" />
          </Grid>
          <Percentage value={outputData.totalLtc * 100} decimals={1} testId="totalLtc" />
        </Grid>
      </Grid>

      <Grid item container spacing={1} />

      <Grid item container spacing={1}>
        <Grid item xs={4} />
        <Grid item container xs={8}>
          <Label label="Value" xs={3} />
          <Label label="LTV" xs={6} />
        </Grid>
      </Grid>
      <Grid item container spacing={1}>
        <Label label="As-Is Value" xs={4} />
        <Grid item container xs={8}>
          <Currency value={sizer.property.asIsValue} xs={3} testId="asIsValue" />
          <Percentage value={outputData.asIsLtv * 100} decimals={1} testId="asIsLtv" />
        </Grid>
      </Grid>
      <Grid item container spacing={1}>
        <Label label="After Repair Value" xs={4} />
        <Grid item container xs={8}>
          <Currency value={sizer.property.afterRepairValue} xs={3} testId="afterRepairValue" />
          <Percentage value={outputData.afterRepairLtv * 100} decimals={1} testId="afterRepairLtv" />
        </Grid>
      </Grid>
      <Section label="Loan That Will be Refinanced" />

      <LabeledText label="Current Lender" value={data.currentLender || 'N/A'} testId="currentLender" />
      <LabeledText
        label="Unpaid Principal Balance"
        value={data.principalBalance || 0}
        currency
        testId="principalBalance"
      />
      <LabeledText
        label="Has Loan Ever Been In Default?"
        value={findOptionLabel(yesNoOptions, data.everBeenInDefault)}
        testId="everBeenInDefault"
      />
      <LabeledText
        label="Default + Late Interest + Fees"
        value={sizer.sumOfLoanFees || 0}
        currency
        testId="sumOfLoanFees"
      />
      <Section label="Exit Strategy" />

      <LabeledText
        label="Profit Projection"
        value={outputData.borrowerProfitLoss}
        currency
        testId="borrowerProfitLoss"
      />
      <LabeledText label="DSCR" value="-" testId="dscr" />
    </Grid>
  );
};

export default SummaryAlreadyOwn;
