import { Grid } from '@material-ui/core';
import { useStore } from '@roc/client-portal-shared/hooks';
import { originatorSubLabel } from '@roc/feature-utils';
import { observer } from 'mobx-react';

import {
  createAutocompleteAddressField,
  createRadioField,
  createTextField,
  createCurrencyField,
  createSelectField,
  createDateField,
} from '../../../components/formComponents/common/componentBuilder';
import {
  acquisitionOptions,
  propertyOwnership,
  yesNoOptions,
  renovationConstructionOptions,
  addingSquareFootageOptions,
  tooltips,
} from '../utils/constants';

const PropertyAndProject = observer(() => {
  const { fixFlipSizerStore } = useStore();
  const { detailsStore } = fixFlipSizerStore;
  const store = detailsStore.propertyAndProjectForm;

  const handleAddressChange = event => {};

  const aquisition = store.form.fields.acquisition.value;
  const isPurchase = propertyOwnership.PURCHASED === aquisition;
  const isOwned = propertyOwnership.OWNED_BY_BORROWER === aquisition;
  const {allowOriginatorSelection} = detailsStore.loanParticipantsStore;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        {createAutocompleteAddressField({
          store,
          testId: 'adress',
          fieldName: 'address',
          label: 'Property Address',
          tooltip: tooltips.address,
          onChange: handleAddressChange,
        })}
      </Grid>
      <Grid item xs={12}>
        {createRadioField({
          store,
          testId: 'acquisition',
          fieldName: 'acquisition',
          label:
            'Is This A Purchase Or Does The Borrower Already Own The Property?',
          options: acquisitionOptions,
          row: true,
          tooltip: tooltips.acquisition,
        })}
      </Grid>
      {isPurchase && (
        <>
          <Grid item xs={12}>
            {createCurrencyField({
              store,
              testId: 'purchasePrice',
              fieldName: 'purchasePrice',
              label: 'Purchase Price',
              tooltip: tooltips.purchasePrice,
            })}
          </Grid>
          <Grid item xs={12}>
            {createCurrencyField({
              store,
              testId: 'rehabToCompleteProject',
              fieldName: 'rehabToCompleteProject',
              label: 'Rehab To Complete Project',
              tooltip: tooltips.rehabToCompleteProject,
            })}
          </Grid>
          <Grid item xs={12}>
            {createCurrencyField({
              store,
              testId: 'asIsValue',
              fieldName: 'asIsValue',
              label: 'As-Is Value (If Applicable)',
              tooltip: tooltips.asIsValue,
            })}
          </Grid>
        </>
      )}
      {isOwned && (
        <>
          <Grid item xs={12}>
            {createCurrencyField({
              store,
              testId: 'purchasePrice',
              fieldName: 'purchasePrice',
              label: 'Purchase Price',
              tooltip: tooltips.purchasePrice,
            })}
          </Grid>
          <Grid item xs={12}>
            {createDateField({
              store,
              testId: 'purchaseDate',
              fieldName: 'purchaseDate',
              label: 'Purchase Date',
              tooltip: tooltips.purchaseDate,
            })}
          </Grid>
          <Grid item xs={12}>
            {createCurrencyField({
              store,
              testId: 'rehabToDate',
              fieldName: 'rehabToDate',
              label: 'Rehab To Date',
              tooltip: tooltips.rehabToDate,
            })}
          </Grid>
          <Grid item xs={12}>
            {createCurrencyField({
              store,
              testId: 'rehabToCompleteProject',
              fieldName: 'rehabToCompleteProject',
              label: 'Rehab To Complete Project',
              tooltip: tooltips.rehabToCompleteProject,
            })}
          </Grid>
          <Grid item xs={12}>
            {createRadioField({
              store,
              testId: 'loanOutstanding',
              fieldName: 'loanOutstanding',
              label:
                'Does The Borrower Currently Have A Loan Outstanding On The Property?',
              options: yesNoOptions,
              row: true,
              tooltip: tooltips.loanOutstanding,
            })}
          </Grid>
          <Grid item xs={12}>
            {createTextField({
              store,
              testId: 'currentLender',
              fieldName: 'currentLender',
              label: 'Who Is The Current Lender?',
              tooltip: tooltips.currentLender,
            })}
          </Grid>
          <Grid item xs={12}>
            {createRadioField({
              store,
              testId: 'everBeenInDefault',
              fieldName: 'everBeenInDefault',
              label: 'Has The Loan Ever Been In Default?',
              options: yesNoOptions,
              row: true,
              tooltip: tooltips.everBeenInDefault,
            })}
          </Grid>
          <Grid item xs={12}>
            {createCurrencyField({
              store,
              testId: 'principalBalance',
              fieldName: 'principalBalance',
              label: 'What Is The Principal Balance Of The Loan?',
              tooltip: tooltips.principalBalance,
            })}
          </Grid>
          <Grid item xs={12}>
            {createCurrencyField({
              store,
              testId: 'sumOfLoanFees',
              fieldName: 'sumOfLoanFees',
              label:
                'What Is The Sum Of The Default Interest, Late Fees, Other Fees On The Loan?',
              tooltip: tooltips.sumOfLoanFees,
            })}
          </Grid>
          <Grid item xs={12}>
            {createCurrencyField({
              store,
              testId: 'asIsValue',
              fieldName: 'asIsValue',
              label: 'As-Is Value (If Applicable)',
              tooltip: tooltips.asIsValue,
            })}
          </Grid>
        </>
      )}
      {(isPurchase || isOwned) && (
        <>
          <Grid item xs={12}>
            {createCurrencyField({
              store,
              testId: 'afterRepairValue',
              fieldName: 'afterRepairValue',
              label: 'After Repair Value',
              tooltip: tooltips.afterRepairValue,
            })}
          </Grid>
          <Grid item xs={12}>
            {createTextField({
              store,
              testId: 'projectDescription',
              fieldName: 'projectDescription',
              label: 'Project Description',
              multiline: true,
              tooltip: tooltips.projectDescription,
            })}
          </Grid>
          <Grid item xs={12}>
            {createSelectField({
              store,
              testId: 'renovationConstruction',
              fieldName: 'renovationConstruction',
              label: 'Renovation/Construction Description',
              options: renovationConstructionOptions,
              tooltip: tooltips.renovationConstruction,
            })}
          </Grid>
          <Grid item xs={12}>
            {createSelectField({
              store,
              testId: 'addingSquareFootage',
              fieldName: 'addingSquareFootage',
              label: 'Adding Square Footage?',
              options: addingSquareFootageOptions,
              tooltip: tooltips.addingSquareFootage,
            })}
          </Grid>
          {allowOriginatorSelection && (
            <Grid item xs={12}>
              {createSelectField({
                store,
                testId: 'lenderOriginatorId',
                fieldName: 'lenderOriginatorId',
                label: 'Originator',
                subLabel: originatorSubLabel,
                options: detailsStore.loanParticipantsStore.originatorOptions
              })}
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
});

export default PropertyAndProject;
