import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Box, Grid, lighten, makeStyles, Theme, Typography, useTheme } from '@material-ui/core';
import { LoginLogoImg, Page } from '@roc/feature-app-core';
import { Paper, PaperBorderType, PreviewBackdrop } from '@roc/ui';
import clsx from 'clsx';
import jsPDF from 'jspdf';
import { useEffect, useRef, useState } from 'react';
import { useDocumentLibraryRoutes } from './../../hooks/useDocumentLibraryRoutes';
const useStyles = makeStyles((theme: Theme) => ({
  documentContainer: {
    paddingLeft: '32px',
    paddingRight: '32px',
    position: 'relative',
    background: '#FFF',
    fontSize: 16,
    overFlowX: 'auto',
    '& table': {
      minHeight: '40px',
      width: '100%',
      textAlign: 'left',
      borderCollapse: "collapse",
    },
    '& table th': {
      textAlign: 'center',
      fontWeight: 'bold',
      background: lighten(theme.palette.primary.main, 0.5),
      padding: '4px',
      border: '0.25px solid black',
    },
    '& table td': {
      borderBottom: '0.25px solid black',
      borderRight: '0.25px solid black',
      borderLeft: '0.25px solid black',
    },
    '& table td p': {
      marginLeft: '5px'
    },
    '& table td:nth-child(1)': {
      border: '0.25px solid black',
      textAlign: 'center',
      width: '150px',
      background: lighten(theme.palette.primary.main, 0.8)
    },
    '& ul,ol': {
      marginLeft: theme.spacing(3),
      marginBottom: theme.spacing(0.5),
    },
    '& h6': {
      fontSize: 16,
      padding: 4,
      fontWeight: 'bold',
    },
    '& hr': {
      marginBottom: '10px'
    },
    '@media (max-width: 800px)': {
      fontSize: 8,
      padding: 0,
    },

  },
  documentCard: {
    minWidth: '650px',
    borderRadius: 0,
    borderColor: '#aaa',
  },
  cardContent: {
    minWidth: 0,
    justifyContent: 'center',
    textAlign: 'justify',
    '& div > h3': {
      color: theme.palette.primary.main,
    },
    '& div > p': {
      margin: '20px',
    },
  },
  title: {
    minWidth: 0,
    textAlign: 'center',
    margin: '45px',
    color: theme.palette.primary.main,
  },
  documentCardTitle: {
    justifyContent: 'center',
    textAlign: 'center',
    background: lighten(theme.palette.primary.main, 0.5),
    fontWeight: 'bold',
    padding: theme.spacing(0.5),
  },
  img: {
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'block',
    padding: 10,
    maxWidth: 200,
    maxHeight: 150,
    minHeight: 150,
    textAlign: 'center',
  },
}));
const usePrintModeStyles = makeStyles((theme: Theme) => ({
  documentContainer: {
    fontSize: 12,
    padding: 0,
    '& table th': {
      fontSize: 12
    },
    '& table td': {
      fontSize: 12
    },
    '& ul,ol': {
      marginLeft: theme.spacing(2),
    },
    '& h6': {
      fontSize: 12,
    },
  },
}));
const DocumentCard = ({
  title = undefined,
  data,
  paperBorder = PaperBorderType.SOLID_BORDER,
  paperClassName = '',
  paperTitleClassName = '',
  paperBodyClassName = '',
  paperStyle = {},
}) => {
  const classes = useStyles();
  return (
    <Paper
      title={title}
      titleClass={clsx(classes.documentCardTitle, paperTitleClassName)}
      titleVariant={'h6'}
      borderType={paperBorder}
      className={clsx(classes.documentCard, paperClassName)}
      style={paperStyle}>
      <Box p={0.5} className={paperBodyClassName}>
        {documentToReactComponents(data)}
      </Box>
    </Paper>
  )
}
export const InitialScopeOfWorkHtml = ({
  contentId,
  data,
  preview = false,
  printMode = false,
  setContainerRef,
}: {
  contentId: string,
  data: any,
  preview?: boolean,
  printMode?: boolean,
  setContainerRef: any,
}) => {
  const theme = useTheme();
  const { documentLibraryRoutesConfig } = useDocumentLibraryRoutes();
  const classes = useStyles();
  const containerRef = useRef(null);
  const [printModeContainerStyle, setPrintModeContainerStyle] = useState<any>();
  const [containerStyle, setContainerStyle] = useState<any>();
  const printModeClasses = usePrintModeStyles();
  useEffect(() => {
    const doc = new jsPDF("p", "mm", "a4");
    let width: any = doc.internal.pageSize.getWidth();
    let height: any = doc.internal.pageSize.getHeight();
    width = `${Math.floor(width)}mm`;
    height = `${Math.floor(height)}mm`;
    setPrintModeContainerStyle({
      margin: 'auto',
      minWidth: width,
      maxWidth: width,
      height: height
    });
    setContainerStyle({
      margin: 'auto',
      maxWidth: theme.breakpoints.values.lg,
    });
  }, []);
  useEffect(() => {
    setContainerRef(containerRef);
  }, [containerRef])
  if (!data) {
    return null;
  }


  return (
    <div ref={containerRef} style={printMode ? printModeContainerStyle : containerStyle}>
      <Page routeDefinition={documentLibraryRoutesConfig.documentLibraryDocument(contentId)}>
        <Box padding={'16px'} className={clsx(classes.documentContainer, printMode ? printModeClasses.documentContainer : undefined)}>
          {preview && <PreviewBackdrop />}
          <LoginLogoImg imgStyle={{ maxWidth: 200 }} />
          <Box my={2} textAlign='center'>
            <Typography variant={'h5'}>
              <p style={{ color: 'grey' }}>{data.documentTitle}</p>
            </Typography>
            <Typography variant={'h4'}>
              <strong>{data.residentialFixAndFlipTitle}</strong>
            </Typography>
          </Box>
          <Box mb={2} paddingLeft={'5%'} paddingRight={'5%'} paddingBottom="2%" overflow={'auto'} >
            <Grid container >
              <Grid item xs={12}>
                <DocumentCard
                  data={data.introductionCard}
                  paperClassName={classes.cardContent}
                  paperBorder={PaperBorderType.NO_BORDER}
                />
              </Grid>
            </Grid>
          </Box>
          <Box mb={2} paddingLeft={'5%'} paddingRight={'5%'} paddingBottom="5%" overflow={'auto'} >
            <Grid container >
              <Grid item xs={12}>
                <DocumentCard
                  data={data.whatMakesAGoodScopeOfWorkTitle}
                  paperClassName={classes.cardContent}
                  paperBorder={PaperBorderType.NO_BORDER}
                />
              </Grid>
              <Grid item xs={3}>
                <img src={data.umbrellaImage.fields.file.url} className={classes.img} />
              </Grid>
              <Grid item xs={9}>
                <DocumentCard
                  data={data.comprehensivenessDescriptionCard}
                  paperClassName={classes.cardContent}
                  paperBorder={PaperBorderType.NO_BORDER}
                />
              </Grid>
              <Grid item xs={3}>
                <img src={data.searchImage.fields.file.url} className={classes.img} />
              </Grid>
              <Grid item xs={9}>
                <DocumentCard
                  data={data.clarityDescriptionCard}
                  paperClassName={classes.cardContent}
                  paperBorder={PaperBorderType.NO_BORDER}
                />
              </Grid>
              <Grid item xs={3}>
                <img src={data.documentImage.fields.file.url} className={classes.img} />
              </Grid>
              <Grid item xs={9}>
                <DocumentCard
                  data={data.detailedDescriptionCard}
                  paperClassName={classes.cardContent}
                  paperBorder={PaperBorderType.NO_BORDER}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Page>

      <Page routeDefinition={documentLibraryRoutesConfig.documentLibraryDocument(contentId)}>
        <Box padding={'16px'} className={clsx(classes.documentContainer, printMode ? printModeClasses.documentContainer : undefined)}>
          {preview && <PreviewBackdrop />}
          <LoginLogoImg imgStyle={{ maxWidth: 200 }} />
          <Box mb={2} paddingLeft={'5%'} paddingRight={'5%'} paddingBottom="5%" overflow={'auto'} >
            <Grid container >
              <Grid item xs={3}>
                <img src={data.balancedImage.fields.file.url} className={classes.img} />
              </Grid>
              <Grid item xs={9}>
                <DocumentCard
                  data={data.balancedDescriptionCard}
                  paperClassName={classes.cardContent}
                  paperBorder={PaperBorderType.NO_BORDER}
                />
              </Grid>
              <Grid item xs={3}>
                <img src={data.warningImage.fields.file.url} className={classes.img} />
              </Grid>
              <Grid item xs={9}>
                <DocumentCard
                  data={data.contingencyDescriptionCard}
                  paperClassName={classes.cardContent}
                  paperBorder={PaperBorderType.NO_BORDER}
                />
              </Grid>
            </Grid>
          </Box>
          <Box mb={2} paddingLeft={'5%'} paddingRight={'5%'} paddingBottom="2%" overflow={'auto'} >
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <DocumentCard
                  data={data.guidelinesInformationCard}
                  paperClassName={classes.cardContent}
                  paperBorder={PaperBorderType.NO_BORDER}
                />
              </Grid>
              <Grid item xs={12}>
                <DocumentCard
                  data={data.generalScopeOfWorkGuidelines}
                  paperClassName={classes.cardContent}
                  paperBorder={PaperBorderType.NO_BORDER}
                />
              </Grid>
              <Grid item xs={6}>
                <DocumentCard
                  title={data.valuationLimitsTitle}
                  data={data.valuationLimitsCard}
                  paperClassName={classes.cardContent}
                  paperBorder={PaperBorderType.NO_BORDER}
                />
              </Grid>
              <Grid item xs={6}>
                <DocumentCard
                  title={data.scopeComprehensivenessTitle}
                  data={data.scopeComprehensivenessCard}
                  paperClassName={classes.cardContent}
                  paperBorder={PaperBorderType.NO_BORDER}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Page>



      <Page routeDefinition={documentLibraryRoutesConfig.documentLibraryDocument(contentId)}>
        <Box padding={'16px'} className={clsx(classes.documentContainer, printMode ? printModeClasses.documentContainer : undefined)}>
          {preview && <PreviewBackdrop />}
          <LoginLogoImg imgStyle={{ maxWidth: 200 }} />
          <Box mb={2} paddingLeft={'5%'} paddingRight={'5%'} paddingBottom="2%" overflow={'auto'} >
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <DocumentCard
                  title={data.scopeCompletenessTitle}
                  data={data.scopeCompletenessCard}
                  paperClassName={classes.cardContent}
                  paperBorder={PaperBorderType.NO_BORDER}
                />
              </Grid>
              <Grid item xs={6}>
                <DocumentCard
                  title={data.scopeDesignationTitle}
                  data={data.scopeDesignationCard}
                  paperClassName={classes.cardContent}
                  paperBorder={PaperBorderType.NO_BORDER}
                />
              </Grid>
              <Grid item xs={12}>
                <DocumentCard
                  title={data.scopeCompletenessTitle}
                  data={data.scopeCompletenessLargeCard}
                  paperClassName={classes.cardContent}
                  paperBorder={PaperBorderType.NO_BORDER}
                />
              </Grid>
            </Grid>
          </Box>
          <Box mb={2} paddingLeft={'5%'} paddingRight={'5%'} paddingBottom="2%" overflow={'auto'} >
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <DocumentCard
                  data={data.termsAndTheirMeaningsTitle}
                  paperClassName={classes.cardContent}
                  paperBorder={PaperBorderType.NO_BORDER}
                />
              </Grid>
              <Grid item xs={3}>
                <Typography className={classes.title} variant={'h6'}>
                  {data.demolitionTitle}
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <DocumentCard
                  data={data.demolitionDescriptionCard}
                  paperClassName={classes.cardContent}
                  paperBorder={PaperBorderType.NO_BORDER}
                />
              </Grid>
              <Grid item xs={3}>
                <Typography className={classes.title} variant={'h6'}>
                  {data.hvacTitle}
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <DocumentCard
                  data={data.hvacDescriptionCard}
                  paperClassName={classes.cardContent}
                  paperBorder={PaperBorderType.NO_BORDER}
                />
              </Grid>
              <Grid item xs={3}>
                <Typography className={classes.title} variant={'h6'}>
                  {data.electricTitle}
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <DocumentCard
                  data={data.electricDescriptionCard}
                  paperClassName={classes.cardContent}
                  paperBorder={PaperBorderType.NO_BORDER}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Page>

      <Page routeDefinition={documentLibraryRoutesConfig.documentLibraryDocument(contentId)}>
        <Box padding={'16px'} className={clsx(classes.documentContainer, printMode ? printModeClasses.documentContainer : undefined)}>
          {preview && <PreviewBackdrop />}
          <LoginLogoImg imgStyle={{ maxWidth: 200 }} />
          <Box mb={2} paddingLeft={'5%'} paddingRight={'5%'} paddingBottom="2%" overflow={'auto'} >
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <Typography className={classes.title} variant={'h6'}>
                  {data.plumbingTitle}
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <DocumentCard
                  data={data.plumbingDescriptionCard}
                  paperClassName={classes.cardContent}
                  paperBorder={PaperBorderType.NO_BORDER}
                />
              </Grid>
              <Grid item xs={3}>
                <Typography className={classes.title} variant={'h6'}>
                  {data.roofTitle}
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <DocumentCard
                  data={data.roofDescriptionCard}
                  paperClassName={classes.cardContent}
                  paperBorder={PaperBorderType.NO_BORDER}
                />
              </Grid>
              <Grid item xs={3}>
                <Typography className={classes.title} variant={'h6'}>
                  {data.paintTitle}
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <DocumentCard
                  data={data.paintDescriptionCard}
                  paperClassName={classes.cardContent}
                  paperBorder={PaperBorderType.NO_BORDER}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Page>
    </div>
  );
}