import { ApiResponse, GlobalStore, GridStore } from '@roc/feature-app-core';
import { LoanStatus } from '@roc/feature-utils';
import { ServicerLoanService } from '../services/servicerLoanService';

const PAGE_SIZE = 20;

const fundedStatusList = [
  LoanStatus.FUNDED,
  LoanStatus.PAID_OFF,
  LoanStatus.PAID_OFF_BUYOUT,
];

export class FundedLoansForPayoffGridStore extends GridStore {
  globalStore: GlobalStore;
  servicerLoanService: ServicerLoanService;

  constructor(globalStore: GlobalStore) {
    super(() => this.fetchFundedLoansForPayoffRequest(), undefined, PAGE_SIZE);
    this.globalStore = globalStore;
    this.servicerLoanService = new ServicerLoanService();
  }

  fetchFundedLoansForPayoffRequest = async () => {
    try {
      const response = await this.servicerLoanService.getFundedLoansForPayoffRequest(
        this.gridData.meta.pageNumber,
        this.gridData.meta.pageSize,
        this.gridData.meta.sortDir,
        this.gridData.meta.sortBy,
        this.gridData.meta.filters,
        this.gridData.meta.dropdownFilters
      );
      return response;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: `Error while fetching the loans.`,
      });
    }
  }
}