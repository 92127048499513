const mapper = {
  Contingency: {
    helperText: 'Please provide the cost',
    sections: [
      {
        title: 'Contingency',
        fields: [
          {
            label: 'Amount',
            name: 'contingencyAmount',
          },
        ],
      },
      {
        title: 'Other',
        tooltip: 'Any other relevant items related to this category',
        fields: [
          {
            label: 'Amount',
            name: 'otherAmount',
          },
        ],
      },
    ],
  },
};

export default mapper;
