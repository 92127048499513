import {
  Typography,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { ImageCarouselViewer, PaperBorderType, multiPageScreenshotGrid } from '@roc/ui';
import { useParams } from 'react-router';
import { GetApp, Share, Edit, Clear, Remove, ArrowForward } from '@material-ui/icons';
import { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Paper } from '@roc/ui';
import { formatCurrency } from '@roc/ui/utils';
import DrawReviewReportCategoryTable from './drawReviewReportCategoryTable';
import CopyUrlModal from '../../drawRequests/components/copyUrlModal';
import { formatPrecision } from '@roc/feature-utils';
import { DrawReviewReportBaseStore } from '../stores/drawReviewReportBaseStore';

const defaultAmount = '$0.00';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      marginBottom: 8,
    },
    topHeaderSection: {
      marginBottom: 16,
      fontFamily: 'Oswald, sans-serif;',
      fontWeight: 'bold'
    },
    loanValuesItem: {
      paddingRight: 24,
    },
    paper: {
      padding: theme.spacing(3, 0, 4),
      boxShadow: 'none',
    },
    headerGrid: {
      padding: '12px 48px 12px 48px',
    },
    leftHeaderData: {
      display: 'flex',
      flexDirection: 'row',
    },
    drawNumber: {
      paddingLeft: 24,
    },
    title: {
      color: '#333333',
      fontSize: 20,
      fontWeight: 'bold',
      fontFamily: 'Oswald, sans-serif;',
      marginBottom: 10,
      marginTop: 25
    },
    table: {
      minWidth: 750,
      border: '2px #e0e0e0 solid',
      '& td': {
        height: '40px',
        padding: 0,
        paddingTop: '8px',
        paddingBottom: '8px',
      },
      '& td:first-child': {
        height: '40px',
        paddingLeft: '16px',
        paddingTop: '8px',
        paddingBottom: '8px',
      },
      '& th:first-child, td[rowSpan]': {
        paddingLeft: theme.spacing(2),
        paddingRight: '3px',
      },
      '& table tr:last-child td, & table td[rowSpan]': {
        borderBottom: 'none',
      },
    },
    headerCell: {
      height: '41px',
      fontWeight: 'bold',
      padding: '0 4px 0 0',
      fontFamily: 'Oswald, sans-serif;',
    },
    totalRow: {
      backgroundColor: '#FDEFBF',
    },
    shareDownloadContainer: {
      paddingRight: 24,
      display: 'flex',
      justifyContent: 'flex-end',
    },
    buttonContainer: {
      marginTop: 32,
    },
    container: {
      padding: 36,
    },
    downloadContainer: {
      display: 'flex',
      flexDirection: 'row',
      cursor: 'pointer',
      marginLeft: '17px',
    },
    buttonLabel: {
      fontSize: '14px',
      paddingLeft: '5px',
    },
    icon: {
      fontSize: '24px',
    },
    editContainter: {
      display: 'flex',
      flexDirection: 'row',
      cursor: 'pointer',
      marginLeft: '10px',
      marginTop: '8px',
    },
    info: {
      fontFamily: 'Oswald, sans-serif;',
      fontWeight: 'bold',
    },
    infoTotals: {
      fontFamily: 'Oswald, sans-serif;',
      fontWeight: 'bolder',
    },
    projectTotalTitlesColor: {
      backgroundColor: '#f8f6f8',
    },
    fontColor: {
      color: '#6b6a6b',
      marginLeft: '5%',
    },
    calculationCellsSize: {
      width: '37%',
    },
    marginArrow: {
      marginLeft: '32px',
      color: '#aeafb0',
    },
    declineButton: {
      borderColor: '#E2A529',
      borderWidth: '2px',
      backgroundColor: 'white',
    },
    saveButton: {
      backgroundColor: '#FDEFBF',
      borderColor: '#E2A529',
      borderWidth: '2px',
    },
    approveButton: {
      backgroundColor: '#F2BC16',
      borderColor: '#F2BC16',
      borderWidth: '2px',
    },
    totalSymbolsColor: {
      color: '#FDEFBF'
    },
    asteriskAlert: {
      marginTop: '20px'
    },
    titleDrawResults: {
      fontSize: 22,
      fontWeight: 'bold',
      fontFamily: 'Oswald, sans-serif;',
      marginBottom: 20,
      marginTop: 25
    },
    alignRigth: {
      alignContent: 'right'
    },
    hiddenTitle: {
      fontSize: 22,
      fontWeight: 'bold',
      fontFamily: 'Oswald, sans-serif;',
      marginBottom: 20,
      marginTop: 25,
      color: '#ffffff'
    }
  })
);

interface DrawReviewReportSharedProps {
  store: DrawReviewReportBaseStore;
  isInternal: boolean;
}

export const DrawReviewReportShared = observer((props: DrawReviewReportSharedProps) => {
  const { store, isInternal } = props;
  const classes = useStyles();
  const { drawId } = useParams<{ drawId: string }>();
  const [isCopyUrlModalOpen, setIsCopyUrlModalOpen] = useState(false);
  const { inspectionLink } = store;


  const handleShareClick = () => {
    setIsCopyUrlModalOpen(true);
  };

  const handleCopyUrlModalClose = () => setIsCopyUrlModalOpen(false);

  const handleCopyUrl = () => {
    window.parent.postMessage(`copy-inspection-link|${inspectionLink}`, '*');
    handleCopyUrlModalClose();
  };

  const handleApprove = () => {
    // TODO: add submit
    store.approveRequest();
  };

  const handleSave = () => {
    // TODO: add save
    store.saveReview();
  }

  const handleDecline = () => {
    // TODO: add decline
    store.declineRequest();
  };

  const handleDownload = () => {
    const screenshotsIds = ['report', 'draw-results'];
    if (store?.pictures?.length) {
      screenshotsIds.push('images-report');
    }
    multiPageScreenshotGrid('Draw_Review_Report.pdf', screenshotsIds, store.getIgnoredCompletePdfList());
  };

  const handleDownloadForExternalInspections = () => {
    const screenshotsIds = ['report', 'draw-results'];
    if (store?.pictures?.length) {
      screenshotsIds.push('images-report');
    }
    multiPageScreenshotGrid('Draw_Review_For_External_Inspections_Report.pdf', screenshotsIds, store.getIgnoredList());
  };

  const getAmount = (amount: number, isPercentage: boolean) => {
    if (isPercentage) {
      return amount ? `${formatPrecision(amount, 2)}%` : '0.00%';
    } else {
      return amount ? `${formatCurrency(amount)}` : defaultAmount;
    }
  }

  const getItemForDrawResults = (text: string[], amount: number[], isPercentage: boolean, notShowDivider?: boolean) => {
    return (
      <Grid item sm={12}>
        <Grid container direction='row' md={12}>
          <Grid item md={9}>
            <Typography variant='body1'>
              {text[0]} {<span style={{ color: '#6b6a6b' }}>{text.length > 1 ? text[1] : ''}</span>}
            </Typography>
          </Grid>
          <Grid item md={3}>
            <Typography align='right'>
              {amount.length === 1 ? getAmount(amount[0], isPercentage) : `${formatPrecision(amount[0], 0)}% | ${formatCurrency(amount[1], 2)}`}
            </Typography>
          </Grid>
        </Grid>
        {!notShowDivider && <Divider />}
      </Grid>
    )
  }

  const getCalculationItem = (text: string[], amount: number[], description: string, isPercentage: boolean) => {
    return (
      <Grid item sm={12}>
        <Grid container direction='row'>
          <Grid item md={6}>
            {getItemForDrawResults(text, amount, isPercentage, true)}
          </Grid>
          <Grid item md={1} alignContent='center'>
            <ArrowForward className={classes.marginArrow} />
          </Grid>
          <Grid item md={5}>
            <Typography variant='body1'>
              {<span style={{ color: '#6b6a6b' }}> {description} </span>}
            </Typography>
          </Grid>
        </Grid>
        <Divider />
      </Grid>
    )
  }

  const loanTermsColumns = [
    {
      text: ['Completed Renovations', '(As Stated by the Borrower. Work Already Installed & Paid For)'],
      amount: [store.capex.percentage, store.capex.amount],
      isPercentage: false
    },
    {
      text: ['Renovation Budget', '(Work Required to Complete the Project)'],
      amount: [store?.loanValues?.renoBudget],
      isPercentage: false
    },
    {
      text: ['Total Renovation Budget', '(Renovation Budget + Completed Renovations)'],
      amount: [store.totalRenoBudgetWithCapex],
      isPercentage: false
    },
    {
      text: ['Construction Holdback Rate', '(Renovation Budget Reimbursement Rate)'],
      amount: [store?.loanValues?.constructionHoldbackRate],
      isPercentage: true
    },
    {
      text: ['Construction Holdback', '(Amount Agreed to Borrower)'],
      amount: [store.loanValues?.constructionHoldback],
      isPercentage: false
    }
  ]

  const assestmentReviewSideDataColumns = [
    {
      text: ['Verified Completed Renovations'],
      amount: [store.capex.amount],
      isPercentage: false
    },
    {
      text: ['Verified Completed Renovation Budget'],
      amount: [store.verifiedCompletedRenovationBudget],
      isPercentage: false
    }
  ]

  const calculationsColumns = [
    {
      text: ['Total Renovation Budget Completed', '(Verified)'],
      amount: [(store.projectCompletion.percentage * 100), store.projectCompletion.amount],
      description: 'Verified Completed Renovations + Verified Completed Renovation Budget',
      isPercentage: false
    },
    {
      text: ['Verified Completed Renovations'],
      amount: [store.capex.amount],
      description: 'Verified Completed Renovations',
      isPercentage: false
    },
    {
      text: ['Renovation Budget Earned', '(Including This Draw)'],
      amount: [store.workCompletedSinceFunding.amount],
      description: 'Total Renovation Budget Completed (Verified) - Verified Completed Renovations',
      isPercentage: false
    },
    {
      text: ['Construction Holdback Earned'],
      amount: [store.totalProjectWire.amount],
      description: 'Renovation Budget Earned * Construction Holdback Rate / 100',
      isPercentage: false
    },
    {
      text: ['Construction Holdback Previously Disbursed'],
      amount: [store.loanValues?.contructionHoldbackReimbursed],
      description: 'Sum Gross Draw Wires Disbursed',
      isPercentage: false
    },
    {
      text: ['Gross Reimbursement This Draw'],
      amount: [store.grossWire],
      description: 'Construction Holdback Earned - Construction Holdback Previously Disbursed',
      isPercentage: false
    },
    {
      text: ['Fees'],
      amount: [store.fees],
      description: 'Inspection & Processing',
      isPercentage: false
    },
    {
      text: ['Net Wire This Draw'],
      amount: [store.netWire],
      description: 'Gross Reimbursement This Draw - Fees',
      isPercentage: false
    },
    {
      text: ['Construction Holdback Remaining'],
      amount: [store.constructionHoldbackRemainingCalculated],
      description: 'Construction Holdback - Construction Holdback Earned',
      isPercentage: false
    }
  ]
  useEffect(() => {
    store.reset();
    store.loadTableData(drawId);
  }, []);

  return (
    <Grid container spacing={2} className={classes.container}>
      <CopyUrlModal
        handleClose={handleCopyUrlModalClose}
        handleCopy={handleCopyUrl}
        isOpen={isCopyUrlModalOpen}
        url={inspectionLink}
      />
      <Typography variant='h3' className={classes.topHeaderSection}>
        {isInternal ? `Draw Review Report` : `Draw Report`}
      </Typography>
      <Grid item xs={12} className={classes.header}>
        <div className={classes.shareDownloadContainer}>
          {isInternal &&
            <div className={classes.downloadContainer} onClick={handleShareClick}>
              <Share className={classes.icon} />
              <p className={classes.buttonLabel}>INSPECTION LINK</p>
            </div>
          }
          <div className={classes.downloadContainer} onClick={handleDownload}>
            <GetApp className={classes.icon} />
            <p className={classes.buttonLabel}>DOWNLOAD AS PDF</p>
          </div>
          {isInternal &&
            <div className={classes.downloadContainer} onClick={handleDownloadForExternalInspections}>
              <GetApp className={classes.icon} />
              <p className={classes.buttonLabel}>DOWNLOAD PDF FOR EXTERNAL INSPECTIONS</p>
            </div>
          }
        </div>
      </Grid>
      <Grid
        item
        container
        spacing={2}
        id='report'
      >
        <Grid item xs={12} md={12}>
          <Paper className={classes.paper} borderType={PaperBorderType.NO_BORDER}>
            <Grid container spacing={2} className={classes.headerGrid}>
              <Typography variant='h4' className={classes.topHeaderSection}>
                Draw Details
              </Typography>
              <Grid container spacing={4} className={classes.topHeaderSection}>
                <Grid item xs={12} md={3}>
                  <Typography variant='body1'>
                    Project Address
                  </Typography>
                  <Divider />
                  <Typography variant='h6' className={classes.info}>
                    {store?.drawDetails?.projectAddress}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={3} >
                  <Typography variant='body1'>
                    Borrower
                  </Typography>
                  <Divider />
                  <Typography variant='h5' className={classes.info}>
                    {store?.drawDetails?.borrowerName}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Typography variant='body1'>
                    Lender
                  </Typography>
                  <Divider />
                  <Typography variant='h5' className={classes.info}>
                    {store?.drawDetails?.lenderName}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={1}>
                  <Typography variant='body1'>
                    Loan ID
                  </Typography>
                  <Divider />
                  <Typography variant='h5' className={classes.info}>
                    {store?.drawDetails?.loanId}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={2}>
                  <Typography variant='body1'>
                    Draw Number
                  </Typography>
                  <Divider />
                  <Typography variant='h5' className={classes.info}>
                    {`#${store?.drawDetails?.drawNumber}`}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={2} className={classes.headerGrid}>
              <Typography variant='h4' className={classes.topHeaderSection}>
                Disbursment Summary
              </Typography>
              <Grid container spacing={4} className={classes.topHeaderSection}>
                <Grid item xs={12} md={3}>
                  <Typography variant='body1'>
                    Total Renovation Budget Completed
                  </Typography>
                  <Divider />
                  <Typography variant='h5' className={classes.info}>
                    {`${formatPrecision(store?.totalRenoBudgetCompleted, 2)}%`}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Typography variant='body1'>
                    Construction Holdback Disbursed
                  </Typography>
                  <Divider />
                  <Typography variant='h5' className={classes.info}>
                    {store?.constructionHoldbackDisbursedIncludingThisDraw ? formatCurrency(store?.constructionHoldbackDisbursedIncludingThisDraw, 2) : defaultAmount}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Typography variant='body1'>
                    Construction Holdback Remaining
                  </Typography>
                  <Divider />
                  <Typography variant='h5' className={classes.info}>
                    {store?.constructionHoldbackRemainingIncludingThisDraw ? formatCurrency(store?.constructionHoldbackRemainingIncludingThisDraw, 2) : defaultAmount}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Typography variant='body1'>
                    Net Wire This Draw
                  </Typography>
                  <Divider />
                  <Typography variant='h5' className={classes.info}>
                    {`${store.netWire ? formatCurrency(store.netWire, 2) : defaultAmount}`}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} md={12}>
          <Paper className={classes.paper} borderType={PaperBorderType.NO_BORDER}>
            <Grid container spacing={2} className={classes.headerGrid}>
              <Grid container item xs={12} md={12}>
                <Typography variant='h4' className={classes.topHeaderSection}>
                  Draw Review
                </Typography>
                {isInternal &&
                  <div className={classes.editContainter} onClick={() => store.switchEdition()} data-html2canvas-ignore>
                    <Edit className={classes.icon} />
                  </div>
                }
              </Grid>
              {
                store && store.tableData && store.tableData.map(category => {
                  if (category.subCategories.length) {
                    return (<DrawReviewReportCategoryTable
                      category={category}
                      store={store}
                      key={category.name}
                      isInternal={isInternal}
                    />);
                  }
                })
              }
              <Grid item xs={12} md={12}>
                <Table className={classes.table}>
                  <TableHead className={classes.projectTotalTitlesColor}>
                    <TableRow>
                      <TableCell align='center'>
                        {(store.isProjectTotalEqualToReno ? '' : 'TOTAL ') + 'RENOVATION BUDGET'}
                      </TableCell>
                      <TableCell align='center'>
                        COMPLETED RENOVATION BUDGET {<span style={{ fontSize: '20px' }}> &nbsp;* </span>}
                      </TableCell>
                      <TableCell>
                        <Clear fontSize='small' />
                      </TableCell>
                      <TableCell align='center'>
                        CONSTRUCTION HOLDBACK RATE
                      </TableCell>
                      <TableCell align='center'>
                        <span style={{ fontSize: '20px', fontWeight: 'bold' }}> = </span>
                      </TableCell>
                      <TableCell align='center'>
                        CONSTRUCTION HOLDBACK COMPLETED
                      </TableCell>
                      <TableCell>
                        <Remove fontSize='small' />
                      </TableCell>
                      <TableCell align='center'>
                        CONSTRUCTION HOLDBACK PREVIOUSLY DISBURSED
                      </TableCell>
                      <TableCell>
                        <span style={{ fontSize: '20px', fontWeight: 'bold' }}> = </span>
                      </TableCell>
                      <TableCell align='center'>
                        GROSS DISBURSEMENT THIS DRAW
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow className={classes.totalRow}>
                      <TableCell align='center'>
                        <Typography variant='h6' className={classes.infoTotals}>
                          {store?.totalCalculatedRenoBudget ? formatCurrency(store.totalCalculatedRenoBudget, 2) : defaultAmount}
                        </Typography>
                      </TableCell>
                      <TableCell align='center'>
                        <Typography variant='h6' className={classes.infoTotals}>
                          {`${formatPrecision((store?.workCompletedSinceFunding.percentage), 2)}% | ${formatCurrency(store.workCompletedSinceFunding.amount, 2)}`}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Clear className={classes.totalSymbolsColor} />
                      </TableCell>
                      <TableCell align='center'>
                        <Typography variant='h6' className={classes.infoTotals}>
                          {`${store?.loanValues?.constructionHoldbackRate}%`}
                        </Typography>
                      </TableCell>
                      <TableCell align='center'>
                        <span className={classes.totalSymbolsColor}> = </span>
                      </TableCell>
                      <TableCell align='center'>
                        <Typography variant='h6' className={classes.infoTotals}>
                          {`${formatPrecision(store.totalProjectWire.percentage, 2)}% | ${formatCurrency(store.totalProjectWire.amount, 2)}`}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Remove className={classes.totalSymbolsColor} />
                      </TableCell>
                      <TableCell id={'total_disbursement'}>
                        <Typography variant='h6' align='center' className={classes.infoTotals}>
                          {`${formatPrecision(((store.loanValues?.contructionHoldbackReimbursed / store.loanValues.constructionHoldback) * 100), 2)}% | ${formatCurrency(store.loanValues?.contructionHoldbackReimbursed, 2)}`}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <span className={classes.totalSymbolsColor}> = </span>
                      </TableCell>
                      <TableCell id={'total_disbursement'}>
                        <Typography variant='h6' align='center' className={classes.infoTotals}>
                          {`${formatCurrency(store.grossWire, 2)}`}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                {<Grid className={classes.asteriskAlert}><span style={{ fontSize: '20px' }}> *&nbsp;</span> <span> AS ASSESED INCLUDING THIS DRAW </span></Grid>}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        md={12}
        id='draw-results'
      >
        <Paper className={classes.paper} borderType={PaperBorderType.NO_BORDER}>
          <Grid container className={classes.headerGrid} >
            <Typography variant='h4' className={classes.topHeaderSection}>
              Draw Results
            </Typography>
            <Grid container justifyContent='space-between' spacing={2}>
              <Grid item xs={12} md={6}>
                <Typography variant='h5' className={classes.titleDrawResults}>
                  Loan Terms
                </Typography>
                <Grid container spacing={4} >
                  {loanTermsColumns.map(column => {
                    return getItemForDrawResults(column.text, column.amount, column.isPercentage);
                  })}
                </Grid>
              </Grid>
              <Grid item xs={12} md={5}>
                <Typography variant='h5' className={classes.titleDrawResults}>
                  Assestment Review of Side Data
                </Typography>
                <Grid container spacing={4}>
                  {assestmentReviewSideDataColumns.map(column => {
                    return getItemForDrawResults(column.text, column.amount, column.isPercentage);
                  })}
                </Grid>
              </Grid>
            </Grid>
            <Grid container justifyContent='space-between' spacing={2}>
              <Grid item xs={12} md={12}>
                <Typography variant='h5' className={classes.titleDrawResults}>
                  Calculations
                </Typography>
                <Grid container spacing={4} >
                  {calculationsColumns.map(column => {
                    return getCalculationItem(column.text, column.amount, column.description, column.isPercentage);
                  })}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      {isInternal &&
        <Grid item xs={12} className={classes.buttonContainer} data-html2canvas-ignore>
          <Grid container justifyContent='center' spacing={2}>
            <Grid item xs={6} md={2}>
              <Button
                onClick={handleDecline}
                className={classes.declineButton}
                size='large'
                variant='outlined'
                fullWidth
              >
                DECLINE
              </Button>
            </Grid>
            <Grid item xs={6} md={2}>
              <Button
                onClick={handleSave}
                className={classes.saveButton}
                size='large'
                variant='outlined'
                fullWidth
              >
                SAVE
              </Button>
            </Grid>
            <Grid item xs={6} md={2}>
              <Button
                className={classes.approveButton}
                disabled={!store.isFilled}
                variant='contained'
                size='large'
                fullWidth
                onClick={handleApprove}
              >
                APPROVE
              </Button>
            </Grid>
          </Grid>
        </Grid>
      }
      <Grid
        item
        container
        spacing={2}
        id='images-report'
      >
        <Grid item xs={12} md={12}>
          <Paper className={classes.paper} borderType={PaperBorderType.NO_BORDER}>
            <Grid container spacing={2} className={classes.headerGrid} >
              <Grid item xs={12} md={12}>
                <Typography variant='h4' className={classes.topHeaderSection}>PROPERTY IMAGES</Typography>
              </Grid>
              <Grid item container spacing={2} justifyContent='space-between'>
                {
                  store?.pictures?.length ? (
                    store.pictures.map((picture, index) => (
                      <Grid key={index} item xs={12} md={4}>
                        <ImageCarouselViewer
                          key={picture.image}
                          title={picture.title}
                          subTitle={picture.consumer_note}
                          image={picture}
                          showTitle
                          store={store}
                        />
                      </Grid>
                    ))
                  ) : (
                    <Grid item spacing={2}>
                      <Typography variant='body1'>There are no photos.</Typography>
                    </Grid>
                  )
                }
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Grid >
  );
});
