import { ValuationReviewReportStore } from "@roc/feature-valuation-review";
import { LogInternalService } from "../../../services/logInternalService";
import { ValuationReviewReportInternalService } from "../services/valuationReviewInternalService";
import { GlobalStore } from "@roc/feature-app-core";
import { DocumentStore } from "@roc/feature-documents";

export class ValuationReviewInternalStore extends ValuationReviewReportStore{

  constructor(globalStore: GlobalStore) {

    super(globalStore);
    this.valuationReviewReportService = new ValuationReviewReportInternalService();
    this.logService = new LogInternalService();
  }

}