import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { observer } from 'mobx-react';
import { FundingPreferenceForm } from './components/fundingPreferenceForm';
import { useDocumentStore } from '../../../hooks/useDocumentStore';

export const FundingPreference = observer(() => {
  const {
    documentStore: { documentFormStore },
  } = useDocumentStore();
  const { fundingPreferenceStore } = documentFormStore;

  useEffect(() => {
    fundingPreferenceStore.initialize();
  }, [fundingPreferenceStore]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <FundingPreferenceForm store={fundingPreferenceStore} />
      </Grid>
    </Grid>
  );
});

export default FundingPreference;
