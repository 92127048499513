import { Service } from "@roc/feature-app-core";

const url = {
  FETCH_LOAN_INFO:
    '/nportal/rest/pub/payments/getLoanInfoForTamariskPayment?loanId=',
  FETCH_STANDALONE_INFO:
    '/nportal/rest/pub/payments/getStandaloneInfoForTamariskPayment?propertyAppraisalId=',
  FETCH_BORROWER_INFO:
    '/nportal/rest/pub/payments/getCardholderInfo?id=',
  CHECK_LINK:
    '/api/v1/loan/public/checkIfValidLink'
};

export class TamariskPaymentsService extends Service {
  getLoanInfoForTamariskPayment(loanId: string, borrowerName: string) {
    return this.get(`${url.FETCH_LOAN_INFO}${loanId}&borrowerName=${borrowerName}`);
  }

  getStandaloneInfoForTamariskPayment(propertyAppraisalId: string, borrowerName: string) {
    return this.get(`${url.FETCH_STANDALONE_INFO}${propertyAppraisalId}&borrowerName=${borrowerName}`);
  }

  getCardholderInfo(id: string, type: string) {
    return this.get(`${url.FETCH_BORROWER_INFO}${id}&type=${type}`);
  }

  checkIfValidLink(id: string, tamariskFlowType: string) {
    return this.get(`${url.CHECK_LINK}?id=${id}&tamariskFlowType=${tamariskFlowType}`);
  }
}
