import React, { MouseEvent, useEffect } from 'react';
import { StandardDialog } from '@roc/ui';
import { observer } from 'mobx-react';
import { Borrower } from '../types';
import BorrowerPreScreen from './borrowerPreScreen';
import { useBorrowersStore } from '../hooks/useBorrowersStore';

export type BorrowerPreScreenDialogProps = {
  open: boolean;
  handleClose: (e?: MouseEvent) => void;
  borrower?: Borrower;
  companyId: number;
};

export const BorrowerPreScreenDialog = observer((props: BorrowerPreScreenDialogProps) => {
  const { open, handleClose, borrower, companyId } = props;
  const { borrowersStore } = useBorrowersStore();

  useEffect(() => {
    if (open && borrower?.borrowerId) {
      borrowersStore.getExtraInfoBorrower(borrower?.borrowerId);
    }
  }, [borrower?.borrowerId, open]);

  return (
    <StandardDialog
      fullScreen={true}
      open={open}
      title={`Borrower: ${borrower?.firstName || ''} ${borrower?.lastName || ''}`}
      handleClose={handleClose}
      dialogContent={<BorrowerPreScreen
        borrower={borrower}
        borrowersStore={borrowersStore}
        companyId={companyId}
      />}
    />
  );
});

export default BorrowerPreScreenDialog;
