import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { BackOfficeLoansStore } from '../../stores/backOfficeLoansStore';
import { Box, Typography } from '@material-ui/core';
import { SelectField } from '@roc/ui';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      alignItems: 'center',
      marginRight: '8px',
      '& .MuiFormHelperText-root': {
        margin: '0px',
      }
    },
    label: {
      marginRight: '8px',
    },
    select: {
      '& .MuiSelect-root': {
        paddingTop: '6px',
        paddingBottom: '6px',
      },
    },
  })
);

export const MY_TASKS_TAB_ACTION_OPTIONS = [
  { value: '1', label: 'Appraisal Tasks' },
  { value: '2', label: 'All Tasks' },
];

export interface MyTasksTabActionsProps {
  store: BackOfficeLoansStore;
}

export const MyTasksTabActions = observer(({ store }: MyTasksTabActionsProps) => {
  const classes = useStyles();

  useEffect(() => {
    store.setTabActionSelection(MY_TASKS_TAB_ACTION_OPTIONS[0].value)
  }, []);

  return <>
    <Box className={classes.container}>
      <Typography className={classes.label}>
        Tasks:
      </Typography>
      <SelectField
        options={MY_TASKS_TAB_ACTION_OPTIONS}
        testId={'foundation'}
        value={store.tabActionSelection}
        onChange={value => { store.setTabActionSelection(value) }}
        fullWidth
        variant="outlined"
        className={classes.select}
      />
    </Box>
  </>;
});
