import { Document } from '@roc/feature-types';
import { Button, PrintableAreaRef, StandardDialog } from '@roc/ui';
import GetAppIcon from '@material-ui/icons/GetApp';
import { useEffect, useRef } from 'react';
import PrintIcon from '@material-ui/icons/Print';
import { observer } from 'mobx-react';
import { useQuoteGeneratorStore } from '../hooks/useQuoteGeneratorStore';
import { TermSheetHtml } from '@roc/feature-documents';
import { formatDateIgnoreTimezone } from '@roc/feature-utils';
import { formatCurrency, formatPercentage } from '@roc/ui/utils';
import { QuoteGeneratorDetailsStore } from '../stores/quoteGeneratorDetailsStore';
import { useBrokersStore } from '@roc/feature-brokers';

const formatCurrencyValue = value => {
  if (value === null || value === undefined) {
    return '-';
  }
  return formatCurrency(value, 2);
}

const formatPercentageValue = value => {
  if (value === null || value === undefined) {
    return '-';
  }
  return formatPercentage(value, 2);
}

export type TermSheetDialogProps = {
  open?: boolean;
  onClose?: () => void;
  propQuoteGeneratorDetailsStore?: QuoteGeneratorDetailsStore;
};

export const QuoteGeneratorTermSheetDialog = observer((props: TermSheetDialogProps) => {
  const { open, onClose, propQuoteGeneratorDetailsStore } = props;
  const termSheetRef = useRef<PrintableAreaRef>(null);
  const { quoteGeneratorStore } = useQuoteGeneratorStore();
  const quoteGeneratorDetailsStore = propQuoteGeneratorDetailsStore || quoteGeneratorStore.quoteGeneratorDetailsStore;
  const { quoteCalculationDetails: { bridgeProfitAndDscr, calculatedFields, bridgeClosingCostsResponseFields } } = quoteGeneratorDetailsStore;
  const { brokersStore } = useBrokersStore();
  const downloadTermSheet = () => {
    const address = fields.propertyAddress.value;
    const [addressNumber, ...streetNameArray] = address.split(" ");
    const streetName = streetNameArray.join(" ").split(",")[0];
    const pdfName = `${addressNumber} ${streetName} Quote.pdf`;
    termSheetRef.current.downloadAsPdf(pdfName);
  };

  const printTermSheet = async () => {
    const url = await termSheetRef.current.getPdfBlobUrl();
    const printWindow = window.open(url.toString(), '_blank');
    printWindow?.print();
  };

  const fields = quoteGeneratorDetailsStore.form.fields;

  const purchaseDate = typeof fields.purchaseDate.value === 'object' ? formatDateIgnoreTimezone(fields.purchaseDate.value) : fields.purchaseDate.value;

  const termSheetData = {
    loanProgram: fields.loanSubtype.value,
    loanPurpose: fields.loanPurpose.value,
    lenderName: quoteGeneratorDetailsStore.lenderName,
    totalLoanAmount: formatCurrencyValue(fields.totalLoanAmount.value),
    initialLoanAmount: formatCurrencyValue(fields.initialLoanAmount.value),
    initialLTC: formatPercentageValue(fields.initialLTCPercentage.value),
    constructionHoldback: formatCurrencyValue(fields.constructionHoldback.value),
    constructionHoldbackToRenovationBudgetPercentage: formatPercentageValue(fields.constructionHoldbackPercentage.value),
    duration: fields.loanTermsMonths.value,
    interestRate: fields.borrowerRate.value,
    lenderOriginationFees: formatCurrencyValue(calculatedFields.lenderOriginationFees),
    lenderOriginationFeesPercentage: formatPercentageValue(calculatedFields.lenderOriginationFeesPercentage),
    interestMethod: 'Actual/360',
    processingFee: formatCurrencyValue(fields.processingFee.value),
    underwritingFee: formatCurrencyValue(fields.underwritingFee.value),
    brokerOriginationFeesPercentage: formatPercentageValue(fields.brokerFee.value),
    brokerName: fields.brokerName.value === '' ? brokersStore?.brokerFormStore?.form?.fields?.entityName?.value : fields.brokerName.value,
    properties: [
      {
        address: fields.propertyAddress.value,
        propertyType: fields.propertyType.value,
        units: fields.numberOfUnits.value,
        purchaseDate,
        purchasePrice: formatCurrencyValue(fields.acquisitionPrice.value),
        renovationBudget: formatCurrencyValue(fields.renoBudget.value),
        asIsValue: formatCurrencyValue(fields.asIsValue.value),
        completedRenoToDate: formatCurrencyValue(fields.completedCapitalImprovements.value),
        afterRepairValue: formatCurrencyValue(fields.afterRepairValue.value),
        exitStrategy: fields.exitStrategy.value,
        existingDebtPayoff: formatCurrencyValue(fields.payoffAmount.value)
      }
    ],
    borrowers: [
      {
        fullName: `${fields.borrowerFirstName.value} ${fields.borrowerLastName.value}`,
        ficoScore: fields.estimatedFicoScore.value,
        experienceScore: fields.experienceScore.value
      }
    ],
    acquisitionPrice: formatCurrencyValue(bridgeClosingCostsResponseFields.acquisitionPrice),
    interestReserve: formatCurrencyValue(bridgeClosingCostsResponseFields.interestReserve),
    servicingSetupFee: formatCurrencyValue(bridgeClosingCostsResponseFields.servicingSetupFee),
    legalReviewFee: formatCurrencyValue(bridgeClosingCostsResponseFields.legalReviewFee),
    estimatedAtThreePercent: formatCurrencyValue(bridgeClosingCostsResponseFields.estimatedAtThreePercent),
    closingExpenseTotal: formatCurrencyValue(bridgeClosingCostsResponseFields.closingExpenseTotal),
    borrowerCashAtClose: formatCurrencyValue(bridgeClosingCostsResponseFields.borrowerCashAtClose),
    brokerOriginationFees: formatCurrencyValue(bridgeClosingCostsResponseFields.brokerOriginationFees),
    existingDebtPayoff: formatCurrencyValue(bridgeClosingCostsResponseFields.existingDebtPayoff),
    thirdPartyEstimatedClosingCost: bridgeClosingCostsResponseFields.thirdPartyEstimatedClosingCost
      ? formatCurrencyValue(bridgeClosingCostsResponseFields.thirdPartyEstimatedClosingCost)
      : null,
    estimatedBorrowerCashAtClose: formatCurrencyValue(calculatedFields.estimatedBorrowerCashAtClose),
    borrowerEntity: fields.borrowerEntity.value,
    interestReserveMonths: fields.interestReserveMonths.value,
    brokerPointsAtOrigination: fields.brokerPointsAtOrigination.value,
    amount: fields.totalLoanAmount.value,
  };

  const dialogActions = (
    <>
      <Button
        style={{ marginRight: '8px' }}
        testId="print-pdf"
        color="default"
        variant="contained"
        onClick={printTermSheet}
        startIcon={<PrintIcon />}
      >
        Print
      </Button>
      <Button
        testId="download-pdf"
        color="primary"
        variant="contained"
        startIcon={<GetAppIcon />}
        onClick={downloadTermSheet}
      >
        Download
      </Button>
    </>
  );

  return (
    <StandardDialog
      open={open}
      title="Quote Sheet"
      maxWidth="md"
      handleClose={onClose}
      dialogActions={dialogActions}
      dialogContent={
        termSheetData ? (
          <TermSheetHtml ref={termSheetRef} data={termSheetData} isQuote={true} />
        ) : null
      }
    />
  );
});
