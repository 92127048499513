import { Page } from '@roc/feature-app-core';
import { Route } from 'react-router';
import { BackOfficeLoansHome } from 'libs/feature-backoffice-users/src/backOfficeUsers/components/dashboard/backOfficeLoansHome';

export const getBackOfficeDashboardRoutesConfig = (basePath: string, baseUrl: string) => {
  return {
    backOfficeDashboard: {
      path: `${basePath}/backoffice/loans`,
      url: `${baseUrl}/backoffice/loans`,
      documentTitle: 'Loans Home',
    }
  };
}

export const getBackOfficeDashboardRoutes = (basePath: string, baseUrl: string) => {
  const config = getBackOfficeDashboardRoutesConfig(basePath, baseUrl);

  return {
    backOfficeDashboard: (
      <Route exact path={config.backOfficeDashboard.path}>
        <Page routeDefinition={config.backOfficeDashboard}>
          <BackOfficeLoansHome />
        </Page>
      </Route>
    )
  };
}