import { Service } from '@roc/client-portal-shared/services';

const url = {
  RATES_DICTIONARY: '/api/v1/pricer/internalRatesDictionary',
  LOAN_VALUES: '/api/v1/loan/getPricerRentalLoanCalcDetail',
  RENTAL_DEAL_NAMES: '/api/v1/loan/getRentalDealNames',
  DEFAULT_VALUES: '/api/v1/pricer/dscr/getDefaultValues',
  STATE_COUNTIES: '/api/v1/pricer/stateCounties',
  MONTHLY_PAYMENT: '/api/v1/pricer/getMonthlyPaymentWithoutExpense',
  GEO_TIER: '/api/v1/pricer/getMarketTier',
  INVESTOR_BUYDOWNS: '/api/v1/pricer/getAllInvestorBuydowns',
};

export class ClientInternalPricerService extends Service {

  getRatesDictionary(data) {
    return this.post(url.RATES_DICTIONARY, data);
  }

  getLoanValues(loanId, isDraft) {
    return this.get(`${url.LOAN_VALUES}?id=${loanId}&isDraft=${isDraft}`);
  }

  getRentalDealNames(
    page: number,
    pageSize: number,
    sortDir: string,
    sortBy: string,
    filters: object
  ) {
    return this.get(url.RENTAL_DEAL_NAMES, {
      page,
      pageSize,
      sortDir,
      sortBy,
      filters,
    });
  }

  getDefaultValues() {
    return this.get(url.DEFAULT_VALUES);
  }

  getStateCounties() {
    return this.get(url.STATE_COUNTIES);
  }

  getMonthlyPayment(data) {
    return this.post(url.MONTHLY_PAYMENT, data);
  }

  getMarketTier(value) {
    return this.get(`${url.GEO_TIER}?stateCounty=${value}`);
  }

  getInvestorBuydowns() {
    return this.get(url.INVESTOR_BUYDOWNS);
  }
}
