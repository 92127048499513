import { Page } from '@roc/client-portal-shared/components';
import { Route } from 'react-router';
import { BorrowerDrawsDashboard } from '../borrowerDrawsDashboard/borrowerDrawsDashboard';

export const getBorrowerDrawsRoutesConfig = (
  basePath: string,
  baseUrl: string
) => {
  return {
    draws: {
      path: `${basePath}/draws`,
      url: `${baseUrl}/draws`,
      documentTitle: 'Draws',
    },
  };
};

export const getBorrowerDrawsRoutes = (basePath: string, baseUrl: string) => {
  const config = getBorrowerDrawsRoutesConfig(basePath, baseUrl);

  return {
    draws: (
      <Route exact path={config.draws.path}>
        <Page routeDefinition={config.draws}>
          <BorrowerDrawsDashboard />
        </Page>
      </Route>
    ),
  };
};
