import React, { MouseEvent, ReactNode, useEffect, useState } from 'react';
import { AlertMenuItem, Button as RocButton, ConfirmationMenuItem, IconButton, MenuItem, SelectField, StandardDialog, StatusChip, StatusType } from '@roc/ui';
import { observer } from 'mobx-react';
import { Box, Button, Collapse, Grid, ListItemIcon, ListItemText, Menu, Typography } from '@material-ui/core';
import { Borrower } from '../../types';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { NewTableComponent } from '../../components/newTableComponent';
import { Block, CheckCircleOutline, Close, CloudCircle, Error, FormatAlignCenter, Launch, Link, MoreVert } from '@material-ui/icons';
import { BorrowersStore } from '../../stores';
import { BorrowerDetailsFormInternal } from 'libs/feature-borrowers/src/borrowerDetailsVerification/components/borrowerDetailsFormInternal';
import { useCreditBackgroundStore } from '@roc/feature-credit-background-check';
import ResolutionTaskItemDialog from './resolutionTaskItemDialog';
import { useResolutionStore } from 'libs/feature-borrower-review/src/hooks/useResolutionStore';

import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    filterContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    filterItemContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    filterTitle: {
      paddingRight: '8px',
    },
    rootContainer: {
      paddingTop: '32px',
    },
    taskDescription: {
      textDecoration: 'underline',
      cursor: 'pointer',
    }
  })
);

const TASKS_HEADERS = [
  { headerName: 'Group', width: '15%' },
  { headerName: 'Description', width: '60%' },
  { headerName: 'Status', width: '15%' },
  { headerName: 'Action', width: '10%' }
];

export type BorrowerTasksProps = {
  borrower?: Borrower;
  borrowersStore: BorrowersStore;
};

export const BorrowerTasks = observer((props: BorrowerTasksProps) => {
  const { borrower, borrowersStore } = props;
  const { preScreenTasks } = borrowersStore;
  const { creditBackgroundStore } = useCreditBackgroundStore();
  const classes = useStyles();
  const [taskType, setTaskType] = useState('all');
  const [statusFilter, setStatusFilter] = useState('all');

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [currentTask, setCurrentTask] = useState(null);
  const menuOpen = Boolean(anchorEl);
  const [showDownloadReports, setShowDownloadReports] = useState(true);
  const [showMarkAsVoidOption, setShowMarkAsVoidOption] = useState(true);
  const [isIDVerification, setIDVerification] = useState(false);
  const [showOpenForm, setShowOpenForm] = useState(false);
  const [showMarkAsCompleteOption, setShowMarkAsCompleteOption] = useState(true);
  const [isBorrowerVerificationFormOpen, setIsBorrowerVerificationFormOpen] = useState(false);
  const [token, setToken] = useState('');
  const { qualifyBorrowerStore } = borrowersStore;
  const [isResolutionModalOpen, setIsResolutionModalOpen] = useState(false);
  const { resolutionStore } = useResolutionStore();
  const [isTableExpanded, setIsTableExpanded] = useState(true);

  const toggleTable = () => {
    setIsTableExpanded(!isTableExpanded);
  }

  useEffect(() => {
    if (borrower?.borrowerId) {
      borrowersStore.getPreScreenTasks(borrower?.borrowerId, taskType);
      qualifyBorrowerStore.setInitialValues(borrower);
    }
  }, [taskType]);

  const getRows = () => {
    const rows = preScreenTasks.map(task => ({
      id: task.id,
      status: task.status,
      cells: [
        task.group,
        (task.group === 'Borrower Info' ? task.description : (
          <span className={classes.taskDescription} onClick={() => { openResolutionModal(task) }}>{task.description}</span>
        )),
        (<StatusChip
          statusType={task.group === 'Borrower Info' ? StatusType.CREDIT_BACKGROUND : StatusType.DOCUMENT}
          label={task.status}
          variant="filled"
          size="small" />),
        (task.group === 'Borrower Info' ? (<Box>
          <IconButton
            testId={'manage-borrowers'}
            onClick={e => openMenu(e, task)}>
            <MoreVert color="primary" />
          </IconButton>
        </Box>) : null)
      ]
    }));

    return statusFilter === "pending" ?
      rows.filter(row => (row.status || '').toLowerCase() === "pending" || row.status === "MORE_INFO_NEEDED" || row.status === "Waiting Authorization") : rows;
  }

  const openMenu = (event: React.MouseEvent<HTMLElement>, task) => {
    const { group, status, type, requestToken } = task;
    setAnchorEl(event.currentTarget);
    setCurrentTask(task);
    if (group === 'Borrower Info') {
      setShowDownloadReports(status === 'Report(s) Delivered' || status === 'Background Completed');
      setShowMarkAsVoidOption(status === 'Waiting Authorization');
      setShowMarkAsCompleteOption(status === 'Report Request Error');
      setIDVerification(type === 'ID_VERIFICATION' || type === 'BG_ID_VERIFICATION_WITHOUT_NOTIFICATION');
      setShowOpenForm(type === 'BG_ID_VERIFICATION_WITHOUT_NOTIFICATION' && status === 'Pending');
      setToken(requestToken);
    } else {
      setShowDownloadReports(false);
      setShowMarkAsVoidOption(false);
      setShowMarkAsCompleteOption(false);
      setIDVerification(false);
      setShowOpenForm(false);
      setToken('');
    }
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setCurrentTask(null);
  };

  const closeIdVerificationForm = () => {
    setIsBorrowerVerificationFormOpen(false);
  };

  const openResolutionModal = (task) => {
    setIsResolutionModalOpen(true);
    setCurrentTask(task);
  };

  const closeResolutionModal = () => {
    setIsResolutionModalOpen(false);
    setCurrentTask(null);
  };

  return (
    <Grid container className={classes.rootContainer}>
      <ResolutionTaskItemDialog
        open={isResolutionModalOpen}
        task={currentTask}
        onClose={closeResolutionModal}
        store={resolutionStore}
      />
      <Menu
        id="menu-appbar"
        getContentAnchorEl={null}
        anchorEl={anchorEl}
        keepMounted
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left', }}
        transformOrigin={{ vertical: 'top', horizontal: 'left', }}
        open={menuOpen}
        onClose={handleMenuClose}
      >
        {showDownloadReports && (
          <MenuItem
            testId={`download-reports-${currentTask?.id}`}
            onClick={() => {
              creditBackgroundStore.downloadReports(currentTask?.id);
              handleMenuClose();
            }}
          >
            <ListItemIcon>
              <CloudCircle fontSize="small" />
            </ListItemIcon>
            <ListItemText>Download Reports</ListItemText>
          </MenuItem>
        )}
        {showMarkAsVoidOption && (
          <ConfirmationMenuItem
            testId={`mark-as-void-${currentTask?.id}`}
            onClick={() => {
              creditBackgroundStore.markAsVoid(currentTask?.id);
              handleMenuClose();
            }}
            confirmDialogProps={{
              body: 'Are you sure you want to mark the request as void?',
            }}
          >
            <ListItemIcon>
              <Block fontSize="small" />
            </ListItemIcon>
            <ListItemText>Mark as Void</ListItemText>
          </ConfirmationMenuItem>
        )}
        {showMarkAsCompleteOption && (
          <AlertMenuItem
            testId={`view-error-${currentTask?.id}`}
            onClick={handleMenuClose}
            alertDialogProps={{
              title: `Error message of request: ${currentTask?.id}`,
              body:
                currentTask?.errorReasonMessage ||
                `No error message available for this request.`,
            }}
          >
            <ListItemIcon>
              <Error color="error" fontSize="small" />
            </ListItemIcon>
            <ListItemText>View Error</ListItemText>
          </AlertMenuItem>
        )}
        {showMarkAsCompleteOption && (
          <ConfirmationMenuItem
            testId={`mark-as-complete-${currentTask?.id}`}
            onClick={() => {
              creditBackgroundStore.markAsManuallyClosed(currentTask?.id);
              handleMenuClose();
            }}
            confirmDialogProps={{
              body: 'Are you sure you want to mark the request as complete?',
            }}
          >
            <ListItemIcon>
              <CheckCircleOutline fontSize="small" />
            </ListItemIcon>
            <ListItemText>Mark as Complete</ListItemText>
          </ConfirmationMenuItem>
        )}
        {isIDVerification && showMarkAsVoidOption && (
          <MenuItem
            testId={`copy-verification-form-${currentTask?.id}`}
            onClick={() => {
              handleMenuClose();
              borrowersStore.viewLink(borrower);
              setShowOpenForm(false);
            }}
          >
            <ListItemIcon>
              <Link />
            </ListItemIcon>
            <ListItemText>View Link</ListItemText>
          </MenuItem>
        )}
        {isIDVerification && showMarkAsVoidOption && (
          <ConfirmationMenuItem
            testId={`manage-reminders-${currentTask?.id}`}
            onClick={() => {
              creditBackgroundStore.manageDailyReminder(currentTask?.id);
              handleMenuClose();
            }}
            confirmDialogProps={{
              title: `Manage email reminders`,
              body: `Are you sure you want to ${currentTask?.enableDailyReminders === true ? "deactivate " : "activate"} daily reminder email for this borrower?`
            }}
          >
            <ListItemIcon>
              {currentTask?.enableDailyReminders === true ? <Close /> : <CheckCircleOutline />}
            </ListItemIcon>
            <ListItemText>{currentTask?.enableDailyReminders === true ? "Stop reminders" : "Enable reminders"} </ListItemText>
          </ConfirmationMenuItem>
        )}
        {isIDVerification && (showMarkAsCompleteOption || showDownloadReports) && (
          <MenuItem
            testId={`copy-verification-form-${currentTask?.id}`}
            onClick={() => { setIsBorrowerVerificationFormOpen(true); }}
          >
            <ListItemIcon>
              <FormatAlignCenter />
            </ListItemIcon>
            <ListItemText>View Borrower Form</ListItemText>
          </MenuItem>
        )}
        {isIDVerification && showOpenForm && (
          <MenuItem
            testId={`copy-verification-form-${currentTask?.id}`}
            onClick={() => {
              handleMenuClose();
              borrowersStore.viewLink(borrower);
              setShowOpenForm(false);
            }}
          >
            <ListItemIcon>
              <Launch />
            </ListItemIcon>
            <ListItemText>View Link</ListItemText>
          </MenuItem>
        )}
        <StandardDialog
          open={isBorrowerVerificationFormOpen}
          maxWidth="xl"
          onClose={closeIdVerificationForm}
          handleClose={closeIdVerificationForm}
          dialogContent={
            <>
              <BorrowerDetailsFormInternal token={token} isInternal={true} />
              <Box flex="1" display="flex" justifyContent="flex-end">
                <Button variant='outlined' color='primary' onClick={closeIdVerificationForm}>
                  Close
                </Button>
              </Box>
            </>

          }
        />
      </Menu>
      <Grid item xs={12}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <IconButton onClick={toggleTable} testId={'expand-tasks'}>
            {isTableExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
          <Typography variant='h5' className={classes.filterTitle}>Tasks</Typography>
        </div>
        <Collapse in={isTableExpanded} timeout="auto" unmountOnExit>
          <NewTableComponent
            headers={TASKS_HEADERS}
            rows={getRows()}
            store={null}
            enablePagination={true}
            filterComponent={
              <Grid xs={12} className={classes.filterContainer}>
                <Grid className={classes.filterItemContainer}>
                  <Typography variant='subtitle2' style={{ marginRight: '5px' }}><strong>Status: </strong></Typography>
                  <SelectField
                    name={'status'}
                    value={statusFilter}
                    options={[
                      { label: 'All', value: 'all' },
                      { label: 'Pending', value: 'pending' }
                    ]}
                    onChange={setStatusFilter}
                    variant="outlined"
                    testId="commentCategory"
                    style={{ height: '36px' }}
                  />
                </Grid>
              </Grid>
            }
          />

        </Collapse>

      </Grid>
    </Grid>
  );
});

export default BorrowerTasks;
