import { Service } from '@roc/feature-app-core';

const url = {
  UPDATE_BORROWER_AGENT:
    '/api/v1/document/formDocument/updateBorrowerClosingAttorneyForm',
};

export class BorrowerClosingAgentService extends Service {
  requestBorrowerDeposit = (requestBody: any) => {
    return this.put(url.UPDATE_BORROWER_AGENT, requestBody);
  };
}
