import { Grid } from "@material-ui/core";
import { isNotBlank } from "@roc/feature-utils";
import { PercentageField, TextField } from "@roc/ui";
import { observer } from "mobx-react";
import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { FieldContainer } from "@roc/ui/formComponents";

const useStyles = makeStyles(theme =>
  createStyles({
    input: {
      margin: 8,
    }
  })
);

export const UnitCount = observer(
  ({ store, multiFamilyStore }: { store: any; multiFamilyStore: any; }) => {
    const formFields = store.form.fields;
    const classes = useStyles();
    return (
      <TextField
        type="number"
        testId="units"
        variant="outlined"
        standaloneLabel
        label="Unit Count"
        value={formFields.units.value}
        onChange={e => store.onFieldChange('units', e.target.value)}
        error={isNotBlank(formFields.units.error)}
        helperText={formFields.units.error}
        fullWidth
        required
      />
    );
  }
);
export const CurrentOccupancy = observer(
  ({ store, multiFamilyStore }: { store: any; multiFamilyStore: any; }) => {
    const formFields = store.form.fields;
    const classes = useStyles();
    return (
      <PercentageField
        testId="currentOccupancy"
        variant="outlined"
        standaloneLabel
        label="In place of occupancy"
        value={formFields.currentOccupancy.value}
        onChange={(name, value) => store.onFieldChange('currentOccupancy', value)}
        fullWidth
        required
        limitedPercentage
      />
    );
  }
);

export const MultifamilyPropertyInformationForm = observer(({ store, multiFamilyStore }) => {
  return (<Grid container>
    <FieldContainer>
      <Grid item sm={12} xl={12}>
        <UnitCount store={store} multiFamilyStore={multiFamilyStore} />
      </Grid>
    </FieldContainer>
    <FieldContainer>
      <Grid item sm={12} xl={12}>
        <CurrentOccupancy store={store} multiFamilyStore={multiFamilyStore} />
      </Grid>
    </FieldContainer>
  </Grid>)
});
