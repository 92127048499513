import {
  TextField as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
} from '@material-ui/core';
import { FieldLabel } from '../fieldLabel/fieldLabel';
import { withTestDataAttribute } from '../testDataAttribute';

export type TextFieldProps = MuiTextFieldProps & {
  standaloneLabel?: boolean;
  subLabel?: string;
  testId: string;
  tooltip?: string;
};

export const TextField = withTestDataAttribute((props: TextFieldProps) => {
  const { standaloneLabel, subLabel, tooltip, ...muiComponentProps } = props;
  return (
    <>
      {standaloneLabel && muiComponentProps.label ? (
        <FieldLabel tooltip={tooltip}>
          {muiComponentProps.label}
          {props.required && ' *'}
        </FieldLabel>
      ) : null}
      {subLabel && (
        <FieldLabel style={{ fontSize: '.8rem', color: '#aaa' }}>
          {subLabel}
        </FieldLabel>
      )}
      <MuiTextField
        {...muiComponentProps}
        label={standaloneLabel ? undefined : muiComponentProps.label}
      />
    </>
  );
});
