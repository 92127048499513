import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { useBaseStore } from '@roc/feature-app-core';
import { useLoanStore } from '@roc/feature-loans';
import { LoanStatus } from '@roc/feature-utils';
import {
  DialogState,
  EditableDialog,
  SubmitButton,
  TestDataAttribute,
} from '@roc/ui';
import { formatCurrency, showPercentage } from '@roc/ui/utils';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { CustomCard, CustomCardRow } from '../../customCard';

const renderHeading = value => {
  return (
    <Typography variant="body1">
      <strong>{value}</strong>
    </Typography>
  );
};

export const UsesCardMultifamily = observer(() => {
  const { loanStore } = useLoanStore();
  const { loanDetails } = loanStore;

  const calculatedLoanData = loanDetails?.calculatedLoanData;

  const [ratios, setRatios] = useState({
    settlementCosts: 4 * 0.01 * calculatedLoanData.aggregatePurchasePrice,
    totalUsesOfCapital: 0,
    purchasePriceOverUses: 0,
    totalRenovationBudgetOverUses: 0,
    interestReserveOverUses: 0,
    settlementCostsOverUses: 0,
    cashAtPurchase: 0,
    initialLoanAmountOverSources: 0,
    totalHoldbackAmountOverSources: 0,
    cashAtPurchaseOverSources: 0,
    totalSourcesOfCapital: 0,
  });

  const setValues = data => {
    const totalUsesOfCapital =
      Number(calculatedLoanData.aggregatePurchasePrice) +
      Number(calculatedLoanData.aggregateRenovationBudget) +
      Number(loanDetails?.interestReserve) +
      Number(ratios.settlementCosts);
    const cashAtPurchase =
      totalUsesOfCapital -
      calculatedLoanData.aggregateRenovationBudget -
      loanDetails?.initialLoanAmount;
    const totalSourcesOfCapital =
      Number(loanDetails?.initialLoanAmount) +
      Number(calculatedLoanData.aggregateRenovationBudget) +
      Number(cashAtPurchase);
    const settlementCosts =
      4 * 0.01 * calculatedLoanData.aggregatePurchasePrice;
    const ratiosPct = {
      totalUsesOfCapital: totalUsesOfCapital,
      purchasePriceOverUses: Math.round(
        (calculatedLoanData.aggregatePurchasePrice / totalUsesOfCapital) * 100
      ),
      totalRenovationBudgetOverUses: Math.round(
        (calculatedLoanData.aggregateRenovationBudget / totalUsesOfCapital) *
          100
      ),
      interestReserveOverUses: Math.round(
        (loanDetails?.interestReserve / totalUsesOfCapital) * 100
      ),
      settlementCostsOverUses: Math.round(
        (settlementCosts / totalUsesOfCapital) * 100
      ),
      cashAtPurchase: cashAtPurchase,
      initialLoanAmountOverSources: Math.round(
        (loanDetails?.initialLoanAmount / totalSourcesOfCapital) * 100
      ),
      totalHoldbackAmountOverSources: Math.round(
        (calculatedLoanData.aggregateRenovationBudget / totalSourcesOfCapital) *
          100
      ),
      cashAtPurchaseOverSources: Math.round(
        (cashAtPurchase / totalSourcesOfCapital) * 100
      ),
      totalSourcesOfCapital: totalSourcesOfCapital,
    };
    setRatios({ ...ratios, ...ratiosPct });
  };

  useEffect(() => {
    if (loanDetails) {
      setValues(loanDetails);
    }
  }, [loanDetails]);

  return (
    <CustomCard title="Uses">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell align="right">{renderHeading('Absolute')}</TableCell>
            <TableCell align="right">{renderHeading('Percentage')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>{renderHeading('Purchase Price')}</TableCell>
            <TestDataAttribute id="purchase_price">
              <TableCell align="right">
                {formatCurrency(calculatedLoanData.aggregatePurchasePrice, 0)}
              </TableCell>
            </TestDataAttribute>
            <TestDataAttribute id="purchase_price_percentage">
              <TableCell align="right">
                {showPercentage(ratios.purchasePriceOverUses)}
              </TableCell>
            </TestDataAttribute>
          </TableRow>
          <TableRow>
            <TableCell>{renderHeading('Total Renovation Budget')}</TableCell>
            <TestDataAttribute id="total_renovation_budget">
              <TableCell align="right">
                {formatCurrency(
                  calculatedLoanData.aggregateRenovationBudget,
                  0
                )}
              </TableCell>
            </TestDataAttribute>
            <TestDataAttribute id="total_renovation_budget_percentage">
              <TableCell align="right">
                {showPercentage(ratios.totalRenovationBudgetOverUses)}
              </TableCell>
            </TestDataAttribute>
          </TableRow>
          <TableRow>
            <TableCell>{renderHeading('Interest Reserve')}</TableCell>
            <TestDataAttribute id="interest_reserve">
              <TableCell align="right">
                {formatCurrency(loanDetails?.interestReserve, 0)}
              </TableCell>
            </TestDataAttribute>
            <TestDataAttribute id="interest_reserve_percentage">
              <TableCell align="right">
                {showPercentage(ratios.interestReserveOverUses)}
              </TableCell>
            </TestDataAttribute>
          </TableRow>
          <TableRow>
            <TableCell>{renderHeading('Settlement Costs')}</TableCell>
            <TestDataAttribute id="settlement_costs">
              <TableCell align="right">
                {formatCurrency(ratios.settlementCosts, 0)}
              </TableCell>
            </TestDataAttribute>
            <TestDataAttribute id="settlement_costs_percentage">
              <TableCell align="right">
                {showPercentage(ratios.settlementCostsOverUses)}
              </TableCell>
            </TestDataAttribute>
          </TableRow>
          <TableRow>
            <TableCell>{renderHeading('Total Uses')}</TableCell>
            <TestDataAttribute id="total_uses">
              <TableCell align="right">
                {formatCurrency(ratios.totalUsesOfCapital, 0)}
              </TableCell>
            </TestDataAttribute>
            <TestDataAttribute id="">
              <TableCell></TableCell>
            </TestDataAttribute>
          </TableRow>
        </TableBody>
      </Table>
    </CustomCard>
  );
});
