import { Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import { Alert } from '@material-ui/lab';

export const BorrowerCreditBackgroundCheckDisclaimer = observer(({ store }) => {
  const formFields = store.form.fields;

  return (
    <Alert severity="info" >
      <Typography>
        {formFields.creditBackgroundCheck.value
          ? <p> The borrower will receive an Authorization Form at the email address.</p>
          : <p>
            {formFields.personalGuarantor.value
              ? 'Once you have borrowers credit & loan is submitted, please go to Background & Credit Review section and select Borrower Setup.'
              : 'Once the loan is submitted, please go to Background & Credit Review section and select Borrower Setup.'
            }
          </p>
        }
      </Typography>
    </Alert>
  );
});

export default BorrowerCreditBackgroundCheckDisclaimer;