import { Layout } from '@roc/client-portal-shared/components';
import { Greeting } from '@roc/feature-app-core';
import { PortalBannerMessages } from 'libs/client-portal-shared/src/app/modules/dashboard/components/portalBannerMessages';
import { useServicerLoansRoutes } from '../hooks/useServicerLoansRoutes';

export const ServicerHome = () => {
  const {
    servicerLoanRoutesConfig,
  } = useServicerLoansRoutes();

  return (
    <Layout>
      <PortalBannerMessages />
      <Greeting
        text={''}
        buttonLabel="GO TO PAYOFFS"
        buttonLink={servicerLoanRoutesConfig.loansHome.url}
      />
    </Layout>
  );
}