import { useEffect, useMemo, useState } from 'react';
import { Box, Typography, TextField, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Autocomplete, Pagination } from '@material-ui/lab';
import clsx from 'clsx';
import { ChevronLeft, ChevronRight, FirstPage, LastPage } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  paginationBar: {
    background: '#fff',
    border: '1px solid #ddd',
    padding: theme.spacing(2),
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
  },
  displayText: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0,
      paddingBottom: theme.spacing(2),
    },
  },
  pagination: {
    display: 'flex',
    justifyContent: 'center',
  },
  paginationInputText: {
    width: '50px',
    paddingTop: 10
  },
  customPagination: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 2,
  }

}));

export const PaginationBar = ({
  start,
  pageSize,
  pageNumber,
  totalPages,
  totalRecords,
  onChange,
  className,
  enablePaginationSelectPage = false,
}) => {
  const classes = useStyles();
  const _className = clsx([classes.paginationBar, className]);


  const getDisplayText = () => {
    if (!totalRecords) return `Displaying 0 - 0 of 0 records`;
    return `Displaying ${start} - ${Math.min(
      start + pageSize - 1,
      totalRecords
    )} of ${totalRecords} records`;
  };

  const displayText = useMemo(getDisplayText, [start, totalRecords, pageSize]);

  const onChangeInputPage = (inputPage) => {
    if (inputPage !== null && inputPage !== undefined && inputPage <= totalPages) {
      onChange(null, inputPage);
    }
  }



  return (
    <Box className={_className}>
      <Box pr={1} className={classes.displayText}>
        <Typography variant="body2" color="textSecondary">
          {displayText}
        </Typography>
      </Box>
      {enablePaginationSelectPage ?
        <CustomPagination
          pageNumber={pageNumber}
          totalPages={totalPages}
          onChange={onChangeInputPage}
        />
      :
        <Box className={classes.pagination}>
          <Pagination
            shape="rounded"
            color="primary"
            page={pageNumber}
            count={totalPages}
            onChange={onChange}
            showFirstButton
            showLastButton
          />
        </Box>
      }
    </Box>
  );
};

const CustomPagination = ({
  pageNumber,
  totalPages,
  onChange,
})=>{
  const [inputPageNumber, setInputPageNumber] = useState(totalPages>0?pageNumber:'');

  const classes = useStyles();

  useEffect(()=>{
    setInputPageNumber(totalPages>0?pageNumber:'');
  },[totalPages, pageNumber]);

  const handlePageSelection = (value) => {
    setInputPageNumber(value);
    onChange(value);
  }

  const handlePageChange = (value) => {
    handlePageSelection(value);
  }

  return (
    <>
      <Box className={classes.customPagination}>
        <IconButton
          onClick={()=> handlePageChange(1)}
          disabled={totalPages===0||inputPageNumber === 1}>
            <FirstPage/>
        </IconButton>
      </Box>
      <Box className={classes.customPagination}>
        <IconButton
          onClick={()=> {if (inputPageNumber > 1){handlePageChange(inputPageNumber-1)}}}
          disabled={totalPages===0||inputPageNumber === 1}>
            <ChevronLeft/>
        </IconButton>
      </Box>
      <Box>
        <Autocomplete value={inputPageNumber}
          onChange={(event, newValue) => handlePageSelection(newValue)}
          options={totalPages>0?[...Array(totalPages+1).keys()].filter((page) => page !== 0):[]}
          getOptionLabel={(option)=> option.toString()}
          renderInput={(params)=>(
            <TextField
              {...params}
              variant='standard'
            />
          )}
          disabled={totalPages === 0}
        />
      </Box>
      <Box className={classes.customPagination}>
        <IconButton
          onClick={()=> {if (inputPageNumber < totalPages){handlePageChange(inputPageNumber+1)}}}
          disabled={totalPages===0||inputPageNumber === totalPages}>
            <ChevronRight/>
        </IconButton>
      </Box>
      <Box className={classes.customPagination}>
        <IconButton
          onClick={()=> handlePageChange(totalPages)}
          disabled={totalPages===0||inputPageNumber === totalPages}>
            <LastPage/>
        </IconButton>
      </Box>
    </>
  );
};
