import { Service } from '@roc/client-portal-shared/services';

export abstract class BorrowerBaseService extends Service {
  abstract saveBorrowerDataCollection(borrowerId: number, loanId: number, data: any, reviewType?: string);
  abstract saveBorrowerDataCollectionAndCreateResolutions(borrowerId: number, loanId: number, data: any, reviewType?: string);
  abstract getBorrowerDataCollection(borrowerId: number, loanId: any, reviewType?: string);
  abstract makeReviewCompleted(loanId: number, borrowerId: number, documentName: string);
  abstract getDataCollectionUpdateHistoryByType(borrowerId: number, reviewType?: string);
  abstract getBorrowerLOE(borrowerId: number, section: string, lineItem: any);
  abstract saveAutomatedEvents(data: any)
}