import { Page } from '@roc/feature-app-core';
import { Route } from 'react-router';
import { CreateList } from '../components/createList';


export const getCreateListRoutesConfig = (basePath: string, baseUrl: string) => {
  return {
    createList: {
      path: `${basePath}/create-list`,
      url: `${baseUrl}/create-list`,
      documentTitle: 'Create List',
    }
  };
}

export const getCreateListRoutes = (basePath: string, baseUrl: string) => {
  const config = getCreateListRoutesConfig(basePath, baseUrl);
  return {
    createList: (
      <Route exact path={config.createList.path}>
        <Page routeDefinition={config.createList}>
          <CreateList />
        </Page>
      </Route>
    )
  };
}