import { useStore } from '@roc/client-portal-shared/hooks';
import { getLoanRoutes } from '../routes';
import { getLoanRoutesConfig } from '@roc/feature-loans-routes-config';
import { getWhatsNewRoutes, getWhatsNewRoutesConfig } from '@roc/feature-whats-new';
import { getLenderProfileRoutes, getLenderProfileRoutesConfig } from '../modules/lenderProfile/routes/lenderProfileRoutes';
import {
  getProspectPropertiesRoutes,
  getProspectPropertiesRoutesConfig,
} from '@roc/feature-prospect-properties';
import {
  getBridgeProfitAndDscrCalculatorRoutes,
  getBridgeProfitAndDscrCalculatorRoutesConfig,
} from '@roc/feature-bridge-profit-and-dscr-calculator';
import { useAppsRoutes } from '@roc/feature-apps-contentful';
import { useQuoteGeneratorRoutes } from '@roc/feature-quote-generator'
import { getBackOfficeDashboardRoutes, getBackOfficeDashboardRoutesConfig } from '../modules/backOfficeDashboard/routes/backOfficeRoutes';
import { getCalendlyRoutes, getCalendlyRoutesConfig } from '../modules/calendly/calendlyRoutes';

export const useRoutes = () => {
  const { globalStore } = useStore();
  const { routeBasePath, routeBaseUrl } = globalStore;

  const {
    appsRoutes: appsRoutes,
    appsRoutesConfig: appsRoutesConfig
  } = useAppsRoutes();

  const {
    quoteGeneratorRoutes,
    quoteGeneratorRoutesConfig
  } = useQuoteGeneratorRoutes();


  return {
    appsRoutes,
    appsRoutesConfig,
    quoteGeneratorRoutes,
    quoteGeneratorRoutesConfig,
    loanRoutes: getLoanRoutes(routeBasePath, routeBaseUrl),
    loanRoutesConfig: getLoanRoutesConfig(routeBasePath, routeBaseUrl),
    whatsNewRoutes: getWhatsNewRoutes(routeBasePath, routeBaseUrl),
    whatsNewRoutesConfig: getWhatsNewRoutesConfig(routeBasePath, routeBaseUrl),
    lenderProfileRoutes: getLenderProfileRoutes(routeBasePath, routeBaseUrl),
    lenderProfileRoutesConfig: getLenderProfileRoutesConfig(routeBasePath, routeBaseUrl),
    prospectPropertiesRoutes: getProspectPropertiesRoutes(
      routeBasePath,
      routeBaseUrl
    ),
    prospectPropertiesRoutesConfig: getProspectPropertiesRoutesConfig(
      routeBasePath,
      routeBaseUrl
    ),
    bridgeProfitAndDscrCalculatorRoutes: getBridgeProfitAndDscrCalculatorRoutes(
      routeBasePath,
      routeBaseUrl
    ),
    bridgeProfitAndDscrCalculatorRoutesConfig: getBridgeProfitAndDscrCalculatorRoutesConfig(
      routeBasePath,
      routeBaseUrl
    ),
    backOfficeRoutes: getBackOfficeDashboardRoutes(routeBasePath, routeBaseUrl),
    backOfficeRoutesConfig: getBackOfficeDashboardRoutesConfig(routeBasePath, routeBaseUrl),
    calendlyRoutes: getCalendlyRoutes(routeBasePath, routeBaseUrl),
    calendlyRoutesConfig: getCalendlyRoutesConfig(routeBasePath, routeBaseUrl),
  };
};
