import { Layout } from '@roc/feature-app-core';
import { observer } from 'mobx-react-lite';
import { useHistory, useParams } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import { useRoutes } from '../../hooks/useRoutes';
import { ContactDetailsWrapper } from '../contactDetails/contactDetailsWrapper';
import { useEffect, useState } from 'react';
import { useStore } from '@roc/client-portal-shared/hooks';
import { BROKER_OBJECT_TYPE, TPO_OBJECT_TYPE } from '../leads/constants/constants';
import { Tabs } from '@roc/ui';
import { CompanyProfileCRMWrapper } from '../companyProfile/companyProfileCRMWrapper';
import { insertIf } from '@roc/feature-utils';
import { Broker } from '../../utils/leadConstants';


const useStyles = makeStyles(theme => ({
  tabSection: {
    marginBottom: theme.spacing(1),
  },
  paper: {
    width: '100%',
    height: '100%',
  },
}));

export const LenderDetailedScreen = observer(() => {
  const classes = useStyles();
  const { contactDetailsStore, companyDropdownStore, companyProfileStore } = useStore();

  const { push } = useHistory();
  const { leadsDashboardRoutesConfig } = useRoutes();
  const { companyContactId, recordType } = useParams<{ companyContactId: string, recordType: string }>();
  const [contactId, setContactId] = useState(null);
  const [companyId, setCompanyId] = useState(null);

  const [selectedTab, setSelectedTab] = useState(0);
  const isBroker = recordType == Broker;

  const TABS = [
    {
      label: 'CONTACT DETAILS',
    },
    ...insertIf(!isBroker, [{
      label: 'LENDER DETAILS',
    }]),
  ];

  const onTabChange = (e, index) => setSelectedTab(index);

  useEffect(() => {
    initialize();
  }, [companyContactId]);

  const initialize = async () => {
    await contactDetailsStore.getCompanyContactDetails(companyContactId);
    setContactId(contactDetailsStore.companyContactDetails.contactId);
    setCompanyId(contactDetailsStore.companyContactDetails.companyId);
    companyProfileStore.setDisplayCompanyProfile(false);
    companyDropdownStore.changeCompany(contactDetailsStore.companyContactDetails.companyId);
  };

  const goToLeadsDashboard = () => {
    push(leadsDashboardRoutesConfig.leadsDashboard.url);
  };

  return (
    <Layout title="Contact Details" onBack={goToLeadsDashboard} maxWidth="xl">
      <Tabs
        className={classes.tabSection}
        tabs={TABS}
        selectedTab={selectedTab}
        onTabChange={onTabChange}
      />
      {selectedTab === 0 && contactId &&
        <ContactDetailsWrapper
          contactId={contactId}
          companyContactId={parseInt(companyContactId)}
          type={isBroker ? BROKER_OBJECT_TYPE : TPO_OBJECT_TYPE}
          store={contactDetailsStore}
          readOnly={false}
        />
      }
      {selectedTab === 1 && companyId && !isBroker &&
        <CompanyProfileCRMWrapper maxWidth={true} />
      }
    </Layout>
  );
});
