import { observable, action, flow, makeObservable, computed } from 'mobx';
import { GlobalStore, FormStore, ApiResponse } from '@roc/feature-app-core';
import { UserProfileService } from '../services/userProfileService';
import { isEmptyString, isNotBlank } from "@roc/feature-utils";

const basicUserDetailsForm = {
  fields: {
    firstName: {
      value: '',
      error: null,
      rule: '',
    },
    lastName: {
      value: '',
      error: null,
      rule: '',
    },
    emailAddress: {
      value: '',
      error: null,
      rule: 'email',
    },
    cellPhone: {
      value: '',
      error: null,
      rule: 'regex:/^\\(\\d{3}\\)\\s?\\d{3}-?\\d{4}$/',
      message: 'Cellphone must be a number with the format (xxx) xxx-xxxx',
    },
    address: {
      value: '',
      error: null,
      rule: '',
    },
    streetNumber: {
      value: '',
      error: null,
      rule: '',
    },
    streetName: {
      value: '',
      error: null,
      rule: '',
    },
    aptNumber: {
      value: '',
      error: null,
      rule: '',
    },
    city: {
      value: '',
      error: null,
      rule: '',
    },
    state: {
      value: '',
      error: null,
      rule: '',
    },
    zipCode: {
      value: '',
      error: null,
      rule: '',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export class UserProfileStore extends FormStore {
  private globalStore: GlobalStore;
  private userProfileService: UserProfileService;

  isEditing = false;

  constructor(globalStore: GlobalStore) {
    super({ ...basicUserDetailsForm }, globalStore);
    this.globalStore = globalStore;
    this.userProfileService = new UserProfileService();

    makeObservable(this, {
      isEditing: observable,
      getBasicUserDetails: flow,
      saveBasicUserDetails: flow,
      handleStreetAddressChange: action,
    });
  }

  edit() {
    this.isEditing = true;
  }

  cancel() {
    this.isEditing = false;
    this.getBasicUserDetails();
  }

  private getStreetAddress() {
    let address = '';
    if (isNotBlank(this.form.fields.streetNumber.value) && !isEmptyString(this.form.fields.streetNumber.value)) {
      address = this.form.fields.streetNumber.value;
    }
    if (isNotBlank(this.form.fields.streetName.value) && !isEmptyString(this.form.fields.streetName.value)) {
      address = address + ' ' + this.form.fields.streetName.value;
    }
    return address.trim();
  }

  handleStreetAddressChange(fieldName: string, fieldValue: string) {
    this.onFieldChange('streetNumber', '');
    this.onFieldChange('streetName', fieldValue);
    this.onFieldChange('address', fieldValue);
  }

  *getBasicUserDetails() {
    try {
      const response: ApiResponse = yield this.userProfileService.getBasicUserDetails();
      const { data } = response.data;
      this.loadForm(data);
      this.form.fields.address.value = this.getStreetAddress();
    }
    catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while getting basic user details.',
      });
    }
  }

  *saveBasicUserDetails() {
    this.runFormValidationWithMessage();
    if (this.form.meta.isValid) {
      try {
        this.isEditing = false;
        const formData = this.getFormValues();
        yield this.userProfileService.saveBasicUserDetails(formData);
        this.globalStore.notificationStore.showSuccessNotification({
          message: 'Basic details have been updated successfully.',
        });
      }
      catch (error) {
        this.globalStore.notificationStore.showErrorNotification({
          message: 'Error while saving basic user details.',
        });
      }
    }
  }

}
