import { Route } from 'react-router';
import { Page } from '@roc/feature-app-core';
import { CompanyProfileCRMInternalWrapper } from '../companyProfileCRMInternalWrapper';

export const getCompanyProfileCRMInternalRoutesConfig = (
  basePath: string,
  baseUrl: string,
  companyId: string
) => {
  return {
    companyProfileCRM: {
      path: `${basePath}/company-profile`,
      url: `${baseUrl}/company-profile`,
      documentTitle: 'Company Profile',
    },
  };
};

export const getCompanyProfileCRMInternalRoutes = (
  basePath: string,
  baseUrl: string,
  companyId: string
) => {
  const config = getCompanyProfileCRMInternalRoutesConfig(basePath, baseUrl, companyId);

  return {
    companyProfile: (
      <Route exact path={config.companyProfileCRM.path}>
        <Page routeDefinition={config.companyProfileCRM}>
          <CompanyProfileCRMInternalWrapper companyId={companyId} />
        </Page>
      </Route>
    ),
  };
};
