import { Route } from 'react-router';
import { useRocInternalPortalStore } from '../../../hooks';
import { DrawReviewReportShared } from '@roc/feature-draws';
import { Page } from '@roc/feature-app-core';

export const getDrawReviewReportInternalRoutesConfig = (
  basePath: string,
  baseUrl: string
) => {
  return {
    drawReviewReport: {
      path: `${basePath}/draw-review-report/:drawId`,
      url: `${baseUrl}/draw-review-report/:drawId`,
      documentTitle: 'Draw Review Report',
    },
  };
};

export const getDrawReviewReportInternalRoutes = (
  basePath: string,
  baseUrl: string
) => {
  const config = getDrawReviewReportInternalRoutesConfig(basePath, baseUrl);
  const { drawReviewReportInternalStore } = useRocInternalPortalStore();

  return {
    drawReviewReport: (
      <Route exact path={config.drawReviewReport.path}>
        <Page routeDefinition={config.drawReviewReport}>
          <DrawReviewReportShared store={drawReviewReportInternalStore}
            isInternal={true}
          />
        </Page>
      </Route>
    ),
  };
};
