import { ApiResponse, FormStore, GlobalStore } from "@roc/feature-app-core";
import { computed, flow, makeObservable, observable } from "mobx";
import { MersOnboardingService } from "../services/mersOnboardingService";

const lenderDetailsForm = {
  fields: {
    signatoryTitle: {
      value: '',
      error: null,
      rule: 'required',
      message: 'Signatory title is required.',
    },
    signatoryFirstName: {
      value: '',
      error: null,
      rule: 'required',
      message: 'Signatory first name is required.',
    },
    signatoryLastName: {
      value: '',
      error: null,
      rule: 'required',
      message: 'Signatory last name is required.',
    },
    mersTraining: {
      value: null,
      error: null,
      rule: 'required',
      message: 'MERS training completion is required.',
    }
  },
  meta: {
    isValid: false,
    error: null,
  },
}

export interface FormInfo {
  status: FormStatus;
  embeddedDocusignLink: string;
}

export enum FormStatus {
  PENDING = 'PENDING',
  UNSIGNED = 'UNSIGNED',
  FINISHED = 'FINISHED',
}

export enum DocusignStatus {
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  PENDING = 'PENDING',
}

export class MersOnboardingStore extends FormStore {
  private globalStore: GlobalStore;
  mersOnboardingService: MersOnboardingService;

  activeStep = 0;
  loading: boolean = false;

  formInfo: FormInfo;
  docusignError: boolean;
  docusignUrl: string;

  constructor(globalStore) {
    super({ ...lenderDetailsForm }, globalStore);
    this.globalStore = globalStore;
    this.mersOnboardingService = new MersOnboardingService();
    makeObservable(this, {
      activeStep: observable,
      loading: observable,
      formInfo: observable,
      goNextStep: flow,
      goPrevStep: flow,
      setLoading: flow,
      submitFormToDocusign: flow,
      docusignError: observable,
      docusignUrl: observable,
    });
    this.setDefaults();
  }

  setDefaults() {
    this.loading = false;
    this.docusignError = false;
    this.activeStep = 0;
  }

  *goPrevStep() {
    this.activeStep--;
  }

  *goNextStep() {
    this.activeStep++;
  }

  *setLoading(value: boolean) {
    this.loading = value;
  }

  *submitFormToDocusign() {
    try {
      const values = this.getFormValues();

      const request = {
        tabs: [
          {
            type: 'TEXT',
            label: 'signatoryFirstName',
            value: values.signatoryFirstName,
          },
          {
            type: 'TEXT',
            label: 'signatoryLastName',
            value: values.signatoryLastName,
          },
          {
            type: 'TEXT',
            label: 'signatoryTitle',
            value: values.signatoryTitle,
          },
        ],
        returnUrl:
          this.globalStore.routeBaseUrl + '/mers-onboarding-docusign-callback',
      };

      const response: ApiResponse = yield this.mersOnboardingService.submitMersOnboardingFormToDocusign(
        request, this.globalStore.selectedCompanyId
      );
      this.docusignUrl = response.data.data.viewUrl;
    } catch (error) {
      this.docusignError = true;
    }
  }
}