import { Service } from '@roc/feature-app-core';

const url = {
  RATES_DICTIONARY: '/api/v1/pricer/ratesDictionary',
  RATES_YSP_DICTIONARY: '/api/v1/pricer/ratesBasedonYieldSpreadPremium',
  SFR_SIZER_LOAN_TERMS: '/api/v1/pricer/getSfrSizerLoanTerms',
  DOWNLOAD_RATES: '/api/v1/pricer/downloadLoanRates',
  DOWNLOAD_FAQS: '/api/v1/pricer/downloadFAQ',
  GET_LOAN_RATES_PDF_DATA: '/api/v1/pricer/getLoanRatesPdfData',
  GET_DEFAULT_FEES: '/api/v1/pricer/getDefaultFees',
};

const urlV2 = {
  RATES_SCENARIOS: '/api/v1/pricer/ratesDictionaryScenarios',
  RATES_SINGLE_QUOTE: '/api/v1/pricer/ratesSingleScenario',
  ADJUST_RATES_SINGLE_QUOTE: '/api/v1/pricer/adjustRateScenario',
  GET_YIELD_SPREAD_FOR_RATE_CHANGE: '/api/v1/pricer/getYieldSpreadForRateChange',
  YIELD_SPREAD_SCENARIOS: '/api/v1/pricer/yieldSpreadPremiumScenarios',
  GET_RATE_SCENARIO_VALIDATION_ERRORS: '/api/v1/pricer/getRateScenarioValidationErrors',
  GET_MY_LENDER: '/api/v1/loan/companyprofile/myLender',
}

export class LoanSubmissionService extends Service {
  getRates(data) {
    return this.post(url.RATES_DICTIONARY, data);
  }

  getRatesScenarios(data) {
    return this.post(urlV2.RATES_SCENARIOS, data);
  }

  getRatesSingleQuote(data) {
    return this.post(urlV2.RATES_SINGLE_QUOTE, data);
  }

  adjustRatesSingleQuote(data) {
    return this.post(urlV2.ADJUST_RATES_SINGLE_QUOTE, data);
  }

  getYieldSpreadPremiumScenarios(data) {
    return this.post(urlV2.YIELD_SPREAD_SCENARIOS, data);
  }

  calculateYieldSpreadForRateChange(data) {
    return this.post(urlV2.GET_YIELD_SPREAD_FOR_RATE_CHANGE, data);
  }

  getRatesBasedOnYsp(data) {
    return this.post(url.RATES_YSP_DICTIONARY, data);
  }

  postPricerLoanTerms(data) {
    return this.post(url.SFR_SIZER_LOAN_TERMS, data);
  }

  downloadRates(data) {
    return this.post(
      url.DOWNLOAD_RATES,
      data,
      {},
      {
        responseType: 'arraybuffer',
      }
    );
  }

  downloadFaq() {
    return this.get(
      url.DOWNLOAD_FAQS,
      {},
      {
        responseType: 'arraybuffer',
      }
    );
  }

  getLoanRatesPdfData(data) {
    return this.post(url.GET_LOAN_RATES_PDF_DATA, data);
  }

  getDefaultFees(data) {
    return this.post(url.GET_DEFAULT_FEES, data);
  }

  getRateValidationErrors(data) {
    return this.post(urlV2.GET_RATE_SCENARIO_VALIDATION_ERRORS, data);
  }

  getMyLenderFeesInfo() {
    return this.get(urlV2.GET_MY_LENDER);
  }
}
