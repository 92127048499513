import { Box } from '@material-ui/core';
import { Document } from '@roc/feature-types';
import { StandardDialog } from '@roc/ui';
import { observer } from 'mobx-react';
import { ACHFormPageComponent } from './achFormPage';

export type AchConsentFormDialogProps = {
  loanId?: string;
  open?: boolean;
  onClose?: () => void;
  document: Document;
};

export const AchConsentFormDialog = observer(
  (props: AchConsentFormDialogProps) => {
    const { loanId, open, onClose, document } = props;
    return (
      <>
        <StandardDialog
          open={open}
          title="ACH Consent"
          maxWidth="lg"
          handleClose={onClose}
          removeContentBox
          dialogContent={<Box width={"100%"}><ACHFormPageComponent encodedInput={(window as any).btoa(loanId)} showTitle={false} /></Box>}
        />
      </>
    );
  }
);
