import {
  Avatar,
  Box,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@material-ui/core';
import {
  lighten,
  makeStyles
} from '@material-ui/core/styles';
import { AccountCircle, AccountCircleOutlined } from '@material-ui/icons';
import { useLogoutStore } from '@roc/feature-app-core';
import { Button, IconButton } from '@roc/ui';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useUserStore } from '../../hooks/useUserStore';

interface ProfileMenuProps {
  data: any;
}

const useStyles = makeStyles(theme => ({
  profileButton: {
    padding: 0,
    '&:hover': {
      border: `1px solid ${lighten(theme.palette.primary.main, .1)}`
    }
  },
  avatar: {
    color: '#fff',
    backgroundColor: theme.palette.primary.dark,
  }
}));

const UserProfileButton = ({ firstName, lastName, fullName, onClick }) => {
  const classes = useStyles();
  const shortName = `${firstName} ${lastName.charAt(0)}`;
  let displayName = fullName.length > 10 ? shortName : fullName;
  displayName = displayName.length > 10 ? `${displayName.substring(0, 10).trim()}...` : displayName;
  const [avatarLetter, setAvatarLetter] = useState('')

  useEffect(() => {
    if (displayName) {
      setAvatarLetter(displayName ? (displayName as string)?.toUpperCase().charAt(0) : '');
    }
  }, [displayName])

  return (
    <IconButton
      testId='user-account-button'
      onClick={onClick}
      style={{
        padding: '0',
        margin: '0 0 0 16px'
      }}>
      {
        avatarLetter ?
          <Avatar className={classes.avatar}>
            {avatarLetter}
          </Avatar> :
          <AccountCircleOutlined fontSize='large' />
      }
    </IconButton >
  );
};

export const ProfileMenu = observer(({ data }: ProfileMenuProps) => {
  const { logoutStore } = useLogoutStore();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { push } = useHistory();
  const isProfileMenuOpen = Boolean(anchorEl);
  const { userStore: { userInformation } } = useUserStore();

  const openProfileMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = menu => {
    if (menu.title == 'Logout') {
      logoutStore.logout();
      setAnchorEl(null);
    } else if (menu.onClick) {
      menu.onClick();
      if (menu.onClickCloseMenu) {
        setAnchorEl(null);
      }
    } else {
      push(menu.route);
      setAnchorEl(null);
    }
  };

  const handleProfileMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <UserProfileButton
        firstName={userInformation.firstName}
        lastName={userInformation.lastName}
        fullName={userInformation.fullName}
        onClick={openProfileMenu}
      />
      <Menu
        id="menu-appbar"
        getContentAnchorEl={null}
        anchorEl={anchorEl}
        keepMounted
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={isProfileMenuOpen}
        onClose={handleProfileMenuClose}
      >
        {data.map(menu => {
          return (
            <MenuItem
              key={menu.title}
              title={menu.title}
              onClick={() => handleMenuItemClick(menu)}
            >
              {menu.icon && <ListItemIcon>{menu.icon}</ListItemIcon>}
              <ListItemText>
                {
                  menu.component ? menu.component : menu.title
                }
              </ListItemText>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
});
