import {
  Box,
  Checkbox,
  Grid,
  Typography,
  FormControlLabel,
  Link as BorrowersLink
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useRoutes, useStore } from '@roc/client-portal-shared/hooks';
import { isNotBlank, originatorSubLabel } from '@roc/client-portal-shared/utils';
import {
  AlertDialog,
  Button,
  createSelectField,
  Paper,
  TestDataAttribute,
  TextField,
} from '@roc/ui';
import { Layout } from '@roc/client-portal-shared/components';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { SelectBorrowerInput } from '@roc/feature-appraisals';
import { SelectBorrowerMode, useBorrowersRoutes } from '@roc/feature-borrowers';
import { Link } from 'react-router-dom';
import { ProcessFlowCard } from './components/processFlowCard';

const useStyles = makeStyles(theme => ({
  orderFormCard: {
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(0),
    },
  },
  spacedTypography: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  link: {
    color: theme.palette.primary.dark,
  },
}));

const Email = observer(
  ({ store, disabled }: { store: any; disabled?: boolean }) => {
    const formFields = store.form.fields;
    return (
      <TextField
        testId="email"
        disabled={disabled}
        variant="outlined"
        standaloneLabel
        label=""
        value={formFields.email.value}
        onChange={e => store.onFieldChange('email', e.target.value)}
        error={isNotBlank(formFields.email.error)}
        helperText={formFields.email.error}
        fullWidth
        required
      />
    );
  }
);

const RunCredit = observer(
  ({ store, disabled }: { store: any; disabled?: boolean }) => {
    const formFields = store.form.fields;
    const handleChange = e => {
      store.onFieldChange(e.target.name, e.target.checked);
    };
    return (
      <FormControlLabel
        control={
          <Checkbox
            name="runCredit"
            color="primary"
            checked={formFields.runCredit.value}
            disabled={disabled}
            onChange={handleChange}
            style={{ paddingLeft: '49px' }}
          />
        }
        labelPlacement="start"
        label="Run Credit"
      />
    );
  }
);

const RunBackground = observer(
  ({ store, disabled }: { store: any; disabled?: boolean }) => {
    const formFields = store.form.fields;
    const handleChange = e => {
      !disabled && store.onFieldChange(e.target.name, e.target.checked);
    };
    return (
      <FormControlLabel
        control={
          <Checkbox
            name="runBackground"
            color="primary"
            checked={formFields.runBackground.value}
            disabled={disabled}
            onChange={handleChange}
          />
        }
        labelPlacement="start"
        label="Run Background"
      />
    );
  }
);

interface AddBorrowerLabelProps {
  isSubTitle?: boolean;
}
function AddBorrowerLabel ({isSubTitle = false}: AddBorrowerLabelProps) {
  const { borrowersRoutesConfig } = useBorrowersRoutes();

  return (
    <>
      {!isSubTitle && (
        <Typography style={{fontSize:'smaller'}}>
          To add a new borrower.
        </Typography>
      )}
      <Typography style={{fontSize: isSubTitle ? '16px' : 'smaller',  marginBottom: isSubTitle ? 0 : '8px'}}>
        {isSubTitle ? 'To add a new borrower, please navigate to ' : 'Please navigate to '}
        <BorrowersLink
          href={borrowersRoutesConfig.borrowers.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <span>View Borrowers.</span>
        </BorrowersLink>
      </Typography>
    </>
  );
}

const OrderFormCard = observer(() => {
  const classes = useStyles();
  const { creditBackgroundStore, globalStore } = useStore();
  const { loanParticipantsStore } = creditBackgroundStore;
  const { borrowersRoutesConfig } = useBorrowersRoutes();

  useEffect(() => {
    creditBackgroundStore.loadParticipants();
  }, [])

  return (
    <Paper title="Order Form" className={classes.orderFormCard}>
      <Box p={2}>
        <Grid container justifyContent={'center'} spacing={2}>
          <Grid item xs={12}>
            Who will be receiving the authorization form?
          </Grid>
          <Grid item xs={12}>
            {globalStore.userFeatures?.creditBackgroundChooseBorrower ? null : <AddBorrowerLabel /> }
            <SelectBorrowerInput
              store={creditBackgroundStore}
              modes={[SelectBorrowerMode.EXISTING]}
              subTitle={globalStore.userFeatures?.creditBackgroundChooseBorrower ? undefined : <AddBorrowerLabel isSubTitle={true} />}
              borrowerCardGridSize={12}
              borrowerCardDisplayFields={{
                firstName: true,
                lastName: true,
                emailAddress: true,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container alignItems="center">
              <Grid item xs={12} md={12}>
                <RunCredit store={creditBackgroundStore} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container alignItems="center">
              <Grid item xs={12} md={12}>
                <RunBackground disabled store={creditBackgroundStore} />
              </Grid>
            </Grid>
          </Grid>
          {loanParticipantsStore.allowOriginatorSelection && (
            <Grid item xs={12}>
              {createSelectField({
                store: creditBackgroundStore,
                testId: 'originatorId',
                fieldName: 'originatorId',
                label: 'Originator',
                subLabel: originatorSubLabel,
                options: loanParticipantsStore.originatorOptions
              })}
            </Grid>
          )}
          <Grid item xs={12}>
            <Button
              variant="contained"
              size="large"
              onClick={() =>
                creditBackgroundStore.submitCreditBackgroundRequest()
              }
              color="primary"
              testId="submit_credit_background"
            >
              Request Authorization and Generate Reports
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
});

export const SubmitCreditBackground = observer(() => {
  const { creditBackgroundStore } = useStore();
  const { creditBackgroundRoutesConfig } = useRoutes();
  const classes = useStyles();
  useEffect(() => {
    creditBackgroundStore.reset();
  }, []);

  const handleClose = () => {
    creditBackgroundStore.setRequestSuccess(false);
  };
  return (
    <Layout title="Request Credit/Background Check" maxWidth={'md'}>
      <Grid container>
        <Grid item md={6} lg={6} xs={12}>
          <OrderFormCard />
        </Grid>
        <Grid item md={6} lg={6} xs={12}>
          <ProcessFlowCard />
        </Grid>
      </Grid>
      <AlertDialog
        open={creditBackgroundStore.requestSuccess}
        body={
          <>
            {!creditBackgroundStore.isCAFSigned ?
            <Typography>
              We have received your request. Please ask your borrower to check
              and complete the form sent via email. We will then process it
              and send the reports to you.
            </Typography> :
            <Typography>
              We already have an authorization form signed for this borrower.
            </Typography>}
            {creditBackgroundStore.newRequestId && (
              <>
                <Typography className={classes.spacedTypography}>
                  Your request number is{' '}
                  <TestDataAttribute id={'newRequestId'}>
                    <b>{creditBackgroundStore.newRequestId}</b>
                  </TestDataAttribute>
                  .
                </Typography>
                {!creditBackgroundStore.isCAFSigned ?
                <Typography className={classes.spacedTypography}>
                  Click
                  <Link
                    onClick={handleClose}
                    className={classes.link}
                    to={
                      creditBackgroundRoutesConfig.creditBackgroundRequests.url
                    }
                  >
                    {' '}
                    here{' '}
                  </Link>
                  to see the list of credit/background requests.
                </Typography> :
                  <Typography className={classes.spacedTypography}>
                    Please click
                    <Link
                      onClick={handleClose}
                      className={classes.link}
                      to={
                        creditBackgroundRoutesConfig.creditBackgroundRequests.url
                      }
                    >
                      {' '}
                      here{' '}
                    </Link>
                    to see the refreshed soft pull credit report.
                  </Typography>}
              </>
            )}
          </>
        }
        handleClose={handleClose}
      />
    </Layout>
  );
});
