import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { useLoanStore } from '@roc/feature-loans-shared';
import { observer } from 'mobx-react';
import { PayoffDashboard } from './components/payoffDashboard';
import { PayoffDashboardNew } from './components/payoffDashboardNew';
import { PayoffContactInformation } from './components/payoffContactInformation';
import { usePayoffRequestStore } from './hooks/usePayoffRequestStore';
import { useEffect } from 'react';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

const useStyles = makeStyles(theme => ({
  container: {
    padding: 16,
  },
  dealName: {
    display: 'flex',
    justifyContent: 'center',
  },
  title: {
    marginBottom: 20,
  },
}));

export const PayoffRequestV2 = observer(({ loanId, showTitle = true, style }: { loanId: string; showTitle?: boolean; style?: CSSProperties }) => {
  const { loanStore } = useLoanStore();
  const classes = useStyles();
  const { payoffRequestStore } = usePayoffRequestStore();
  const showPayoffForm = ['SLS', 'Keybank'].includes(loanStore.loanDetails.servicer);
  const showPayoffContact = ['Fay Servicing', 'Planet Home Lending', 'Planet Portfolio'].includes(loanStore.loanDetails.servicer);
  const currentPayoff = payoffRequestStore.currentPayoff;

  useEffect(() => {
    payoffRequestStore.setSaved(false);
  }, []);

  return (
    <>
      <Grid container direction="column" className={classes.container} style={style}>
        {
          showTitle &&
          <>
            <Grid item className={classes.dealName}>
              <Typography variant="h6" color="textSecondary">
                {loanStore?.loanDetails.dealName}
              </Typography>
            </Grid>
            <Grid item style={{
              paddingLeft: 12
            }}>
              <Typography variant="h4" className={classes.title}>
                {payoffRequestStore?.isForeclosure || currentPayoff?.foreclosure === true ? 'Foreclosure Payoff Request' : 'Payoff Request'}
              </Typography>
            </Grid>
          </>
        }
        <Grid item>
          {showPayoffForm && (<PayoffDashboard loanId={loanId} />)}
          {showPayoffContact && (<PayoffContactInformation />)}
        </Grid>
      </Grid>
    </>
  );
});

export const PayoffRequestV2New = observer(({ loanId }: { loanId: string }) => {
  const { loanStore } = useLoanStore();
  const classes = useStyles();
  const { payoffRequestStore } = usePayoffRequestStore();
  const showPayoffForm = ['SLS', 'Keybank'].includes(loanStore.loanDetails.servicer);
  const showPayoffContact = ['Fay Servicing', 'Planet Home Lending', 'Planet Portfolio'].includes(loanStore.loanDetails.servicer);

  useEffect(() => {
    payoffRequestStore.setSaved(false);
  }, []);

  return (
    <>
      <Grid container direction="column">
        <Grid item>
          <Typography variant="h4" style={{ paddingBottom: '15px' }} gutterBottom>
            Payoffs
          </Typography>
          {(showPayoffForm) && (<PayoffDashboardNew loanId={loanId} />)}
          {(showPayoffContact) && (<PayoffContactInformation />)}
        </Grid>
      </Grid>
    </>
  );
});
