import { GlobalStore } from '@roc/feature-app-core';
import { computed, flow, makeObservable, observable } from 'mobx';
import { LoanService } from '../../../loans/services/loanService';

interface Option {
  label: string;
  value: string;
}

export class LoanOriginatorStore {
  private globalStore: GlobalStore;
  private loanService: LoanService;

  originators: any[] = [];

  constructor(globalStore: GlobalStore) {
    this.globalStore = globalStore;
    this.loanService = new LoanService();
    makeObservable(this, {
      originators: observable,
      fetchOriginators: flow,
      userCanSelectOriginator: computed,
    });
  }

  get userCanSelectOriginator() {
    return (
      !this.globalStore.userFeatures.isLenderOriginator &&
      this.originators.length > 0
    );
  }

  *fetchOriginators() {
    if (this.originators.length === 0) {
      try {
        const response = yield this.loanService.getOriginators();
        this.originators = response.data.data;
      } catch (e) {
        this.globalStore.notificationStore.showErrorNotification({
          message: 'Error while fetching the loan originators',
        });
      }
    }
  }
}
