import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  StandardDialog,
  createCheckboxField,
  createPhoneFieldClick,
  createTextField,
} from '@roc/ui';
import {
  Box,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core';
import { Card } from '@roc/ui';
import { useUserStore } from '@roc/feature-app-core';
import { SendBulkEmailsModal } from '../leadsDashboard/sendBulkEmails';
import { SendBulkMessagesModal } from 'apps/crm-portal/src/app/modules/ringCentral/sendBulkMessages';
import { ContactActivitiesCard } from '../notesAndActivities/contactActivitiesCard';
import { ContactNotesCard } from '../notesAndActivities/contactNotesCard';
import { ContactTasksCard } from '../notesAndActivities/contactTasksCard';
import { ContactHistoryCard } from '../notesAndActivities/contactHistoryCard';

const useStyles = makeStyles(theme => ({
  button: {
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(2),
    minWidth: '150px',
  },
}));

export const divider = <Divider variant="middle" component="li" />;

export const ContactDetailsComponent = observer(
  ({ contactId, borrowerId, companyContactId, type, readOnly, contactDetailsStore }) => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <ContactInformationCard
            contactId={contactId}
            type={type}
            readOnly={readOnly}
            contactDetailsStore={contactDetailsStore}
            companyContactId={companyContactId}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <ContactNotesCard
            leadDetailsStore={contactDetailsStore.leadDetailsStore}
          />
          <Box pt={2}>
            <ContactActivitiesCard
              leadDetailsStore={contactDetailsStore.leadDetailsStore}
            />
          </Box>
          <Box pt={2}>
            <ContactTasksCard
              leadDetailsStore={contactDetailsStore.leadDetailsStore}
              borrowerId={borrowerId}
              companyContactId={companyContactId}
            />
          </Box>
          <Box pt={2}>
            <ContactHistoryCard
              leadDetailsStore={contactDetailsStore.leadDetailsStore}
              type={type} />
          </Box>
        </Grid>
      </Grid>
    );
  }
);

const ContactInformationCard = observer(
  ({ contactId, type, readOnly, contactDetailsStore, companyContactId }) => {
    const classes = useStyles();
    const formStore = contactDetailsStore.editLeadDetailsFormStore;
    const { userStore: { userInformation } } = useUserStore();
    const [openSendBulkMessagesModal, setOpenSendBulkMessagesModal] = useState(false);
    const [openSendBulkEmailModal, setOpenSendBulkEmailModal] = useState(false);
    const { ringCentralStore, sendEmailsStore } = contactDetailsStore;
    const { leadDetailsStore } = contactDetailsStore;


    const connectedWithRingCentral = userInformation.connectedWithRingcentral;

    useEffect(() => {
      contactDetailsStore.getContactDetails(contactId, companyContactId);
      leadDetailsStore.fetchLeadDetails(contactId, type);
      return () => {
        sendEmailsStore.reset();
        ringCentralStore.reset();
      };
    }, []);

    const saveContactInformation = () => {
      const isFormValid = formStore.runFormValidationWithMessage();
      if (isFormValid) {
        contactDetailsStore.editContactDetails(contactId);
      }
    };

    const onSendBulkMessages = () => {
      ringCentralStore.resetLists();
      ringCentralStore.addStandaloneRecord(contactId, { phone: formStore.form.fields.phoneNumber.value, secondaryPhoneNumber: formStore.form.fields.secondaryPhone.value }, type);
      setOpenSendBulkMessagesModal(true);
    };

    const closeModal = () => {
      setOpenSendBulkMessagesModal(false);
      ringCentralStore.resetContent();
    };

    const onSendBulkEmail = () => {
      sendEmailsStore.resetbulkEmails();
      sendEmailsStore.setBulkEmails({
        email: formStore.form.fields.email.value,
        firstName: formStore.form.fields.firstName.value,
        lastName: formStore.form.fields.lastName.value,
        id: contactId,
        type
      }, true);
      setOpenSendBulkEmailModal(true);
    };

    const closeBulkEmailModal = () => {
      setOpenSendBulkEmailModal(false);
      sendEmailsStore.handleModalClose();
    };

    const action = (
      <Box p={1} mb={-10}>
        <Button
          className={classes.button}
          variant="contained"
          size="small"
          onClick={() =>
            (window.location.href = `rcapp://r/call?number=${formStore.form.fields.phoneNumber.value}`)
          }
          color="primary"
          testId="call"
          disabled={formStore.form.fields?.doNotCall.value || formStore.form.fields?.brokered.value}
        >
          Call
        </Button>
        <Button
          className={classes.button}
          variant="contained"
          size="small"
          onClick={onSendBulkMessages}
          color="primary"
          testId="send_bulk_messages"
          disabled={formStore.form.fields?.doNotSms.value || formStore.form.fields?.brokered.value}
        >
          SMS
        </Button>
        <Button
          className={classes.button}
          variant="contained"
          size="small"
          onClick={onSendBulkEmail}
          color="primary"
          testId="send_bulk_emails"
          disabled={formStore.form.fields?.doNotSendEmail.value || formStore.form.fields?.brokered.value}
        >
          Email
        </Button>
      </Box>
    )

    return (
      <Card title={'Contact Information'} cardHeaderProps={{ action }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            {createTextField({
              label: 'First Name',
              fieldName: 'firstName',
              testId: 'firstName',
              store: formStore,
              disabled: readOnly,
            })}
          </Grid>
          <Grid item xs={12} sm={6}>
            {createTextField({
              label: 'Last Name',
              fieldName: 'lastName',
              testId: 'lastName',
              store: formStore,
              disabled: readOnly,
            })}
          </Grid>
          <Grid item xs={12} sm={6}>
            {createPhoneFieldClick({
              label: 'Phone Number',
              fieldName: 'phoneNumber',
              testId: 'phoneNumber',
              store: formStore,
            })}
          </Grid>
          <Grid item xs={12} sm={6}>
            {createPhoneFieldClick({
              label: 'Secondary Phone Number',
              fieldName: 'secondaryPhone',
              testId: 'secondaryPhone',
              store: formStore,
            })}
          </Grid>
          <Grid item xs={12} sm={6}>
            {createTextField({
              label: 'Email',
              fieldName: 'email',
              testId: 'email',
              type: 'email',
              store: formStore,
              disabled: readOnly,
            })}
          </Grid>
          <Grid item xs={12} sm={6}>
            {createTextField({
              label: 'Secondary Email',
              fieldName: 'secondaryEmail',
              testId: 'secondaryEmail',
              type: 'email',
              store: formStore,
            })}
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4" style={{ fontSize: 20 }}>
              Do Not Disturb
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            {createCheckboxField({
              label: 'Do not Call',
              fieldName: 'doNotCall',
              store: formStore,
            })}
          </Grid>
          <Grid item xs={12} sm={6}>
            {createCheckboxField({
              label: 'Do not Email',
              fieldName: 'doNotSendEmail',
              store: formStore,
            })}
          </Grid>
          <Grid item xs={12} sm={6}>
            {createCheckboxField({
              label: 'Do not SMS',
              fieldName: 'doNotSms',
              store: formStore,
            })}
          </Grid>
          <Grid item xs={12} sm={6}>
            {createCheckboxField({
              label: 'Do not Mail',
              fieldName: 'doNotMail',
              store: formStore,
            })}
          </Grid>
          <Grid item xs={12} sm={6}>
            {createCheckboxField({
              label: 'Brokered',
              fieldName: 'brokered',
              store: formStore,
            })}
          </Grid>
          {contactDetailsStore?.companyContactDetails &&
            <Grid item xs={12} sm={6}>
              {createCheckboxField({
                label: 'Key Contact',
                fieldName: 'keyContact',
                store: formStore,
              })}
            </Grid>
          }
          <StandardDialog
            open={openSendBulkMessagesModal}
            title={
              connectedWithRingCentral
                ? ' '
                : 'Send SMS'
            }
            maxWidth="md"
            handleClose={closeModal}
            dialogContent={<SendBulkMessagesModal handleClose={closeModal} salesforceLeadStore={contactDetailsStore} type={type} />}
          />
          <StandardDialog
            open={openSendBulkEmailModal}
            title={'Send Bulk Emails'}
            maxWidth="xl"
            handleClose={closeBulkEmailModal}
            dialogContent={
              <SendBulkEmailsModal handleClose={closeBulkEmailModal} salesforceLeadStore={contactDetailsStore} type={type} />
            }
          />
        </Grid>
        <Box pt={4} display="flex" justifyContent="flex-end">
          <Button
            onClick={saveContactInformation}
            color="primary"
            variant="contained"
            testId="save"
          >
            Apply Changes
          </Button>
        </Box>
      </Card>
    );
  }
);