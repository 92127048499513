import { Service } from '@roc/feature-app-core';

const url = {
  PROPERTY_APPRAISALS: '/api/v1/document/formDocument/getPropertyAppraisal',
};

export class AppraisalOrderDetailsService extends Service {
  getPropertyAppraisal(loanId: number, propertyId: number) {
    return this.get(url.PROPERTY_APPRAISALS, { loanId, propertyId });
  }

  getPropertyAppraisalById(propertyAppraisalId: number) {
    return this.get(url.PROPERTY_APPRAISALS, { propertyAppraisalId });
  }
}
