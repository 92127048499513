import React, { useEffect, useState } from 'react';
import {
  Typography,
  Divider,
  Box,
  Grid,
} from '@material-ui/core';
import { Button, TextField, CurrencyField } from '@roc/ui';
import { BackgroundItemStore } from '../stores/backgroundItemStore';

interface NewTradelineDataItemFormProps {
  store: BackgroundItemStore;
  handleClose: () => void;
  subtitle: string;
  index?: number;
  record?: string;
}

const NewTradelineDataItemForm = ({ store, handleClose, subtitle, index, record }: NewTradelineDataItemFormProps) => {
  const [itemName, setItemName] = useState('');
  const [outstandingCount, setOutstandingNumber] = useState(0);
  const [balanceAmount, setOutstandingAmount] = useState(0);
  const [paidCount, setPaidNumber] = useState(0);
  const [highCreditAmount, setPaidAmount] = useState(0);
  const [nameEditable, setNameEditable] = useState(true);
  const [pastDue, setPastDue] = useState(0);

  useEffect(() => {
    if (index >= 0) {
      if (record) {
        setItemName(store.backgroundSectionDetails.backgroundSection[record][index].item);
        setOutstandingNumber(store.backgroundSectionDetails.backgroundSection[record][index].outstandingCount);
        setOutstandingAmount(store.backgroundSectionDetails.backgroundSection[record][index].balanceAmount);
        setPaidNumber(store.backgroundSectionDetails.backgroundSection[record][index].paidCount);
        setPaidAmount(store.backgroundSectionDetails.backgroundSection[record][index].highCreditAmount);
        setPastDue(store.backgroundSectionDetails.backgroundSection[record][index].pastDue);
        if (store.backgroundSectionDetails.backgroundSection[record][index].item) {
          setNameEditable(false);
        }
      }
    }
  }, [])

  const handleSubmit = (e: React.FormEvent, record?: string) => {
    e.preventDefault();

    if (index >= 0) {
      if (record) {
        store.editTDRecordItem(itemName, outstandingCount, balanceAmount, paidCount, highCreditAmount, pastDue, record, index);
      }
    }
    else {
      if (record) {
        store.addTDRecordItem(itemName, outstandingCount, balanceAmount, paidCount, highCreditAmount, pastDue, record)
      }
    }
    handleClose();
  };

  const handleCancel = (e: React.FormEvent) => {
    handleClose();
  };

  return (
    <form onSubmit={(e) => { handleSubmit(e, record) }}>
      <Typography variant='h5'>Review Item</Typography>
      <Divider />
      <Typography variant='h6'>{subtitle}</Typography>
      <TextField
        type="text"
        variant="outlined"
        label="Item Name"
        value={itemName}
        onChange={(e) => setItemName(e.target.value)}
        fullWidth
        margin="normal"
        required
        testId="shareUrl"
        disabled={!nameEditable}
      />
      <TextField
        type="number"
        variant="outlined"
        label="Outstanding Number"
        value={outstandingCount}
        onChange={(e) => setOutstandingNumber(Number(e.target.value))}
        fullWidth
        margin="normal"
        required
        testId="shareUrl"
      />
      <CurrencyField
        testId="currency-1"
        variant="outlined"
        label="Outstanding Amount *"
        value={balanceAmount}
        onChange={(name, value) => setOutstandingAmount(Number(value))}
        fullWidth
        groupSeparator=","
        allowNegative={false}
        margin="normal"
      />
      <TextField
        type="number"
        variant="outlined"
        label="Paid Number"
        value={paidCount}
        onChange={(e) => setPaidNumber(Number(e.target.value))}
        fullWidth
        margin="normal"
        required
        testId="shareUrl"
      />
      <CurrencyField
        variant="outlined"
        label="Paid Amount *"
        value={highCreditAmount}
        onChange={(name, value) => setPaidAmount(Number(value))}
        fullWidth
        margin="normal"
        groupSeparator=","
        allowNegative={false}
        testId="shareUrl"
      />
      <CurrencyField
        variant="outlined"
        label="Past Due *"
        value={pastDue}
        onChange={(name, value) => setPastDue(Number(value))}
        fullWidth
        margin="normal"
        groupSeparator=","
        allowNegative={false}
        testId="shareUrl"
      />
      <Box marginTop={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Button onClick={handleCancel} variant="outlined" color="primary" testId="cancel" fullWidth>
              Cancel
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button type="submit" variant="contained" color="primary" testId="save" fullWidth>
              Save
            </Button>
          </Grid>
        </Grid>
      </Box>
    </form>
  );
};

export default NewTradelineDataItemForm;