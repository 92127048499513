import { Service } from '@roc/feature-app-core';

const url = {
  GET_BORROWER_LOAN: '/api/v1/loan/loanApplications/getLoanApplicationById',
  GET_CURRENT_USER_BORROWER: '/api/v1/loan/loanApplications/getCurrentUserBorrower'
};

export class BorrowerLoanApplicationDetailsService extends Service {
  getBorrowerLoanApplication(loanId) {
    return this.get(url.GET_BORROWER_LOAN, { loanId });
  }

  getCurrentUserBorrower() {
    return this.get(url.GET_CURRENT_USER_BORROWER);
  }
}