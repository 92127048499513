import React from "react";
import { Grid, TextField } from '@material-ui/core';
import { Autocomplete } from "@material-ui/lab";
import { observer } from "mobx-react";

interface lenderType {
  label: string;
  value: string;
};

const field = FieldComponent => {
  const WrappedFieldComponent = observer(FieldComponent);
  return props => (
    <Grid item xs={props.xs ?? 12} md={props.md ?? 6}>
      <WrappedFieldComponent {...props} />
    </Grid>
  );
};

const onLenderChanged = async (store) => {
  console.log(store.form.fields["lenderName"].value);  //Populate Borrowers on store based on selected lenderId
}

export const AllLenders = field(({ submitAppraisalStore }: any) => {
  const allLender: lenderType[] = submitAppraisalStore.allLenderOptions;
  return (
    <Autocomplete
      id='lenderName'
      options={allLender}
      componentName="lenderName"
      renderInput={(options) => <TextField {...options} />}
      getOptionLabel={(option) => option.label}
      onInputChange={(label, value) => submitAppraisalStore.onFieldChange("lenderName", value)}
      onChange={() => onLenderChanged(submitAppraisalStore)}
    />
  );
});