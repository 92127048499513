import { observable, flow, makeObservable, action, computed, override } from 'mobx';
import { isBlank, isEmptyObject, isNil, isNotBlank } from '@roc/feature-utils';
import { FormStatus } from '../../borrowers/types';
import { CreditBackgroundCheckStatus } from '@roc/feature-credit-background-check';
import { BorrowerSetupToDoBaseStore } from './borrowerSetupToDoBaseStore';
import { PublicBorrowerSetupToDoService } from '../services/publicBorrowerSetupToDoService';
import { ContactDetailsFormStore } from './contactDetailsFormStore';

export class PublicBorrowerSetupToDoStore extends BorrowerSetupToDoBaseStore {

  publicBorrowerSetupToDoService: PublicBorrowerSetupToDoService;

  requestToken: string;
  showWelcome: boolean;
  loading: boolean;

  constructor(globalStore) {
    super(globalStore);
    this.publicBorrowerSetupToDoService = new PublicBorrowerSetupToDoService();

    makeObservable(this, {
      requestToken: observable,
      showWelcome: observable,
      loading: observable,
      reset: override,
      loadStores: action,
      setShowWelcome: action,
      finished: computed,
      waitingAuthorizationState: computed,
      backgroundCompletedState: computed,
      getBorrowerPrescreenInfo: flow,
      getBorrowerDetailsVerification: flow,
      generateAu10tixIdVerification: flow,
      savePersonalInformation: flow,
      saveIdInformation: flow,
      saveFinancialInformation: flow,
      saveSomePersonalFinancialInformation: flow,
      confirmDocusign: flow,
      saveBorrowerIdentityVerificationId: flow,
    });
    this.setDefaults();
  }

  setDefaults() {
    super.setDefaults();
    this.showWelcome = true;
    this.loading = false;
  }

  reset() {
    super.reset();
    this.setDefaults();
  }

  loadStores() {
    this.showDob = isBlank(this.borrowerDetailsVerification?.savedForm?.dateOfBirth);
    this.showSsn = isBlank(this.borrowerDetailsVerification?.savedForm?.ssn);
    if (isBlank(this.borrowerDetailsVerification?.savedForm?.firstName) || isBlank(this.borrowerDetailsVerification?.savedForm?.lastName)) {
      this.disableNameFields = false;
    }
    const data = {
      ...this.borrowerDetailsVerification?.savedForm
    };
    this.personalInformationFormStore.loadForm(data);
    this.idInformationStore.loadForm(data);
    this.contactDetailsFormStore.setAllowPhoneNumberChange(isBlank(this.borrowerDetailsVerification?.savedForm?.phoneNumber));
    this.contactDetailsFormStore.loadForm({
      ...this.borrowerDetailsVerification,
      ...this.borrowerDetailsVerification?.savedForm,
      maskedEmailAddress: this.contactDetailsFormStore.maskEmailAddress(this.currentBorrower?.emailAddress),
      maskedPhoneNumber: this.contactDetailsFormStore.maskPhoneNumber(this.borrowerDetailsVerification?.savedForm?.phoneNumber),
    });
    this.financialInformationFormStore.loadForm(data);
  }

  setShowWelcome(showWelcome: boolean) {
    this.showWelcome = showWelcome;
  }

  get finished() {
    return this.borrowerDetailsVerification?.status === FormStatus.FINISHED;
  }

  get waitingAuthorizationState() {
    return this.borrowerDetailsVerification?.requestState === CreditBackgroundCheckStatus.WAITING_AUTHORIZATION;
  }

  get backgroundCompletedState() {
    return this.borrowerDetailsVerification?.requestState === CreditBackgroundCheckStatus.BACKGROUND_COMPLETED;
  }

  *getBorrowerPrescreenInfo(borrowerId) {
    try {
      this.currentBorrowerId = borrowerId;
      const response = yield this.publicBorrowerSetupToDoService.getBorrowerPrescreenInfo(this.currentBorrowerId, this.borrowerDetailsVerification?.runCredit);
      this.currentBorrower = response?.data?.data;
    } catch (error) {
      console.log(error);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'We had an issue while retrieving the borrower info.',
      });
    }
  }

  *getBorrowerDetailsVerification(requestToken) {
    try {
      this.requestToken = requestToken;
      const response = yield this.publicBorrowerSetupToDoService.getBorrowerDetailsVerification(requestToken);
      if (!isEmptyObject(response?.data?.data)) {
        this.borrowerDetailsVerification = response?.data?.data;
        if (!isNil(this.borrowerDetailsVerification?.borrowerId)) {
          yield this.getBorrowerPrescreenInfo(this.borrowerDetailsVerification?.borrowerId);
        }
        this.loadStores();
        this.embeddedDocusignLink = this.borrowerDetailsVerification?.embeddedDocusignLink;
      }
    } catch (error) {
      console.log(error)
      this.globalStore.notificationStore.showErrorNotification({
        message: 'We had an issue while retrieving the borrower form details.',
      });
    }
  }

  *generateAu10tixIdVerification() {
    try {
      const response = yield this.publicBorrowerSetupToDoService.generateAu10tixIdVerification(this.currentBorrowerId);
      this.borrowerIdvRequestResponse = response?.data?.data;
    } catch (error) {
      console.log(error);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'We had an issue while generating ID verification.',
      });
    }
  }

  *savePersonalInformation() {
    try {
      if (this.isInvalidDateOfBirthDate(this.personalInformationFormStore.form.fields.dateOfBirth.value)) {
        this.personalInformationFormStore.form.fields.dateOfBirth.error = 'Age must be over 18 years';
        this.globalStore.notificationStore.showWarningNotification({ message: 'Please fix the validation errors.', });
      } else if (this.isInvalidSSN(this.personalInformationFormStore.form.fields.ssn.value)) {
        this.personalInformationFormStore.form.fields.ssn.error = 'Invalid SSN number.';
        this.globalStore.notificationStore.showWarningNotification({ message: 'Please fix the validation errors.', });
      }
      else if (this.personalInformationFormStore.form.fields.__isAddressInCorrectFormat__.value == false) {
        this.personalInformationFormStore.form.fields.address.error = 'Invalid address format';
        this.globalStore.notificationStore.showWarningNotification({ message: 'Please fix the validation errors.', });
      }
      else {
        this.personalInformationFormStore.runFormValidationWithMessage();
        // this.contactDetailsFormStore.runFormValidationWithMessage();
        // if (!this.contactDetailsFormStore.form.fields.emailAddressVerified.value) {
        //   this.contactDetailsFormStore.form.fields.maskedEmailAddress.error = 'Email address must be verified';
        //   this.globalStore.notificationStore.showWarningNotification({ message: 'Email address must be verified.', });
        // }
        // if (!this.contactDetailsFormStore.form.fields.phoneNumberVerified.value) {
        //   this.contactDetailsFormStore.form.fields.maskedPhoneNumber.error = 'Phone number must be verified';
        //   this.globalStore.notificationStore.showWarningNotification({ message: 'Phone number must be verified.', });
        // }
        if (this.personalInformationFormStore.form.meta.isValid) { //&& this.contactDetailsFormStore.form.meta.isValid) {
          const data = {
            ...this.personalInformationFormStore.getFormValues(),
            // ...this.contactDetailsFormStore.getFormValues(),
            token: this.requestToken,
          };
          yield this.publicBorrowerSetupToDoService.savePersonalInformation(data);
          this.goNextStep();
          this.globalStore.notificationStore.showSuccessNotification({
            message: 'Personal Information saved successfully.',
          });
        }
      }
    } catch (error) {
      console.log(error);
      if (isNotBlank(error.message)) {
        this.globalStore.notificationStore.showErrorNotification({ message: error.message });
      }
      else {
        this.globalStore.notificationStore.showErrorNotification({
          message: 'Error while saving Personal Information.',
        });
      }
    }
  }

  *saveIdInformation() {
    try {
      if (isNil(this.idInformationStore.driversLicense)) {
        this.globalStore.notificationStore.showWarningNotification({ message: "Please upload a Document.", });
      }
      this.idInformationStore.runFormValidationWithMessage();
      if (!isNil(this.idInformationStore.driversLicense) && this.idInformationStore.form.meta.isValid) {
        const data = {
          ...this.idInformationStore.getFormValues(),
          token: this.requestToken,
        }
        yield this.publicBorrowerSetupToDoService.saveIdInformation(data, this.idInformationStore.driversLicense);
      }
      this.goNextStep();
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'ID Information saved successfully.',
      });
    } catch (error) {
      console.log(error);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while saving ID Information.',
      });
    }
  }

  *saveFinancialInformation() {
    try {
      this.financialInformationFormStore.runFormValidationWithMessage();
      if (this.financialInformationFormStore.form.meta.isValid) {
        const data = {
          ...this.financialInformationFormStore.getFormValues(),
          token: this.requestToken,
        }
        yield this.publicBorrowerSetupToDoService.saveFinancialInformation(data);
        yield this.getBorrowerDetailsVerification(this.requestToken);
        if (this.currentBorrower?.runCreditOnCreation) {
          this.goNextStep();
        }
        this.globalStore.notificationStore.showSuccessNotification({
          message: 'Financial Information saved successfully.',
        });
      }
    } catch (error) {
      console.log(error);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while saving Financial Information.',
      });
    }
  }


  *saveSomePersonalFinancialInformation() {
    try {
      if (this.isInvalidDateOfBirthDate(this.personalInformationFormStore.form.fields.dateOfBirth.value)) {
        this.personalInformationFormStore.form.fields.dateOfBirth.error = 'Age must be over 18 years';
        this.globalStore.notificationStore.showWarningNotification({ message: 'Please fix the validation errors.', });
      } else if (this.isInvalidSSN(this.personalInformationFormStore.form.fields.ssn.value)) {
        this.personalInformationFormStore.form.fields.ssn.error = 'Invalid SSN number.';
        this.globalStore.notificationStore.showWarningNotification({ message: 'Please fix the validation errors.', });
      }
      else if (isBlank(this.personalInformationFormStore.form.fields.phoneNumber.value)) {
        this.personalInformationFormStore.form.fields.phoneNumber.error = 'Phone number is required.';
        this.globalStore.notificationStore.showWarningNotification({ message: 'Please fix the validation errors.', });
      } else if (this.personalInformationFormStore.form.fields.__isAddressInCorrectFormat__.value == false) {
        this.personalInformationFormStore.form.fields.address.error = 'Invalid address format';
        this.globalStore.notificationStore.showWarningNotification({ message: 'Please fix the validation errors.', });
      }
      else {
        this.personalInformationFormStore.runFormValidationWithMessage();
        this.financialInformationFormStore.runFormValidationWithMessage();
        if (this.personalInformationFormStore.form.meta.isValid && this.financialInformationFormStore.form.meta.isValid) {
          const data = {
            ...this.personalInformationFormStore.getFormValues(),
            ...this.financialInformationFormStore.getFormValues(),
            token: this.requestToken,
          };
          yield this.publicBorrowerSetupToDoService.saveSomePersonalFinancialInformation(data);
          yield this.getBorrowerDetailsVerification(this.requestToken);
          if (this.currentBorrower?.runCreditOnCreation) {
            this.goNextStep();
          }
          this.globalStore.notificationStore.showSuccessNotification({
            message: 'Some Personal Information saved successfully.',
          });
        }
      }
    } catch (error) {
      console.log(error);
      if (isNotBlank(error.message)) {
        this.globalStore.notificationStore.showErrorNotification({ message: error.message });
      }
      else {
        this.globalStore.notificationStore.showErrorNotification({
          message: 'Error while saving some Personal Information.',
        });
      }
    }
  }

  saveCreditInformation() { }

  *confirmDocusign() {
    try {
      yield this.publicBorrowerSetupToDoService.confirmDocusign(this.borrowerDetailsVerification?.savedForm?.requestToken);
      yield this.getBorrowerDetailsVerification(this.requestToken);
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Credit Docusign saved successfully.',
      });
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while saving Credit Docusign.',
      });
    }
  }

  *saveBorrowerIdentityVerificationId() {
    try {
      yield this.publicBorrowerSetupToDoService.saveBorrowerIdentityVerificationId(this.borrowerDetailsVerification?.savedForm?.requestToken, this.borrowerIdvRequestResponse?.borrowerIdentityVerificationId);
      yield this.getBorrowerDetailsVerification(this.requestToken);
    } catch (error) {
      console.log(error);
    }
  }
}