import { FormStore } from '@roc/feature-app-core';
import { GlobalStore } from '@roc/feature-app-core';
import { BorrowerDetailsVerificationService } from '@roc/feature-credit-background-check';
import {
  DateFormat,
  debounceRequest,
  formatDate,
  generateUUID,
  GENERIC_ERROR_MESSAGE,
  isNumber,
  LoanType,
} from '@roc/feature-utils';
import { Option } from '@roc/ui/utils';
import { differenceInDays } from 'date-fns';
import { BorrowerSetupService } from 'libs/feature-borrowers/src/borrowers/services/borrowerSetup/borrowerSetupService';
import { action, computed, flow, makeObservable, observable } from 'mobx';
import { OneToolService } from '../../services/oneToolService';
import {
  CreditPull,
  CreditStatus,
  IDVerificationStatus,
} from '../../utils/constants';

const PRE_SCREEN_STATUS_ACCEPTED = 'Accepted';
const REQUIRED_MESSAGE = 'This field is required';

const borrowerForm = {
  fields: {
    borrowerId: {
      value: '',
      error: null,
      rule: '',
    },
    leadId: {
      value: '',
      error: null,
      rule: '',
    },
    firstName: {
      value: '',
      error: null,
      rule: 'required_if_true:isPreflight',
      message: REQUIRED_MESSAGE,
    },
    lastName: {
      value: '',
      error: null,
      rule: 'required_if_true:isPreflight',
      message: REQUIRED_MESSAGE,
    },
    emailAddress: {
      value: '',
      error: null,
      rule: 'required|email',
      message: 'Email is invalid.',
    },
    ficoScore: {
      value: '',
      error: null,
      rule: 'required|numeric|min:300|max:850',
      message: 'FICO Score must be a number between 300 and 850.',
    },
    numberOfVerifiedInvestmentProperties: {
      value: '',
      error: null,
      rule: 'required|min:1',
      message: 'Number of verified Investment Properties is Required.',
    },
    personalGuarantor: {
      value: true,
      error: null,
      rule: '',
    },
    creditStatus: {
      value: CreditStatus.NO_CREDIT_AND_BACKGROUND,
      error: null,
      rule: '',
    },
    hasIdentification: {
      value: false,
      error: null,
      rule: '',
    },
    percentageOfOwnership: {
      value: 0,
      error: null,
      rule: '',
    },
    creditPull: {
      value: '',
      error: null,
      rule: 'required_if_true:isPreflight',
      message: REQUIRED_MESSAGE,
    },
    citizenshipStatus: {
      value: '',
      error: null,
      rule: '',
      message: REQUIRED_MESSAGE,
    },
    experience: {
      value: null,
      error: null,
      rule: '',
    },
    //Flags
    isPreflight: {
      value: false,
    },
    projectsCompleted: {
      value: false,
    },
    blacklisted: {
      value: false,
    },
    medianScore: {
      value: null
    }
  },
  meta: {
    key: null,
    editMode: false,
    isValid: false,
    showErrors: null,
    error: null,
  },
};

export class BorrowerFormStore extends FormStore {
  private globalStore: GlobalStore;
  private oneToolService: OneToolService;
  private borrowerSetupService: BorrowerSetupService;
  private borrowerDetailsVerificationService: BorrowerDetailsVerificationService;

  public key = generateUUID();

  borrowerOptions: Option[] = [];

  creditStatus: CreditStatus;
  idVerificationStatus: IDVerificationStatus;
  selectedRecord: any = {};

  findBorrowersByNameDebounced = debounceRequest((cancelToken, name) =>
    this.findBorrowersByName(name, cancelToken)
  );

  findBorrowersAndLeadsByNameDebounced = debounceRequest((cancelToken, name) =>
    this.findBorrowersAndLeadsByName(name, cancelToken)
  );

  constructor(globalStore) {
    super({ ...borrowerForm }, globalStore);
    this.globalStore = globalStore;
    this.oneToolService = new OneToolService();

    this.borrowerSetupService = new BorrowerSetupService();
    this.borrowerDetailsVerificationService = new BorrowerDetailsVerificationService();

    this.prepopulateNewBorrower();
    makeObservable(this, {
      selectedRecord: observable,
      borrowerOptions: observable,
      findBorrowersByName: flow,
      findBorrowersAndLeadsByName: flow,
      fetchRecordData: flow,
      fetchBorrowerStatus: flow,
      creditStatus: observable,
      idVerificationStatus: observable,
      fetchBorrowerExtraInfo: flow,
      cannotCreateBorrower: computed,
      isNewBorrower: computed,
    });
  }

  reset() {
    super.reset();
    this.selectedRecord = {};
    this.setEditMode(true);
  }

  prepopulateNewBorrower() {
    this.form.meta.editMode = true;
  }
  setShowErrors(value) {
    this.form.meta.showErrors = value;
  }
  setEditMode(value) {
    this.form.meta.editMode = value;

    if (value) {
      this.setShowErrors(null);
    }
  }

  *findBorrowersByName(name, cancelToken?) {
    try {
      if (name.length >= 3) {
        const response = yield this.oneToolService.findBorrowersByName(
          name,
          cancelToken
        );
        this.borrowerOptions = response.data.data;
      } else {
        this.borrowerOptions = [];
      }
    } catch (e) {
      console.log(e);
      if (!e.isCancel) {
        this.globalStore.notificationStore.showErrorNotification({
          message: GENERIC_ERROR_MESSAGE,
        });
      }
    }
  }

  *findBorrowersAndLeadsByName(name, cancelToken?) {
    try {
      if (name.length >= 3) {
        const response = yield this.oneToolService.findBorrowersAndLeadsByName(
          name,
          cancelToken
        );
        this.borrowerOptions = response.data.data;
      } else {
        this.borrowerOptions = [];
      }
    } catch (e) {
      if (!e.isCancel) {
        this.globalStore.notificationStore.showErrorNotification({
          message: GENERIC_ERROR_MESSAGE,
        });
      }
    }
  }

  *fetchRecordData(recordId) {
    try {
      const recordComposedKey = recordId.split('-');
      const type = recordComposedKey[0];
      const id = recordComposedKey[1];
      const response = yield type === 'lead'
        ? this.oneToolService.fetchLeadData(id, '')
        : this.oneToolService.fetchBorrowerData(id);

      const recordData = response.data;
      const formData = {
        leadId: recordData.leadId,
        borrowerId: recordData.borrowerId,
        firstName: recordData.firstName,
        lastName: recordData.lastName,
        emailAddress: type === 'lead' ? recordData.email : recordData.emailAddress,
        ficoScore: recordData.medianScore ?? recordData.ficoProvidedAtOrigination ?? '',
        numberOfVerifiedInvestmentProperties: recordData.experience === 0 ? '' : recordData.experience,
        projectsCompleted: !!recordData.portalProjectsCompleted || !!recordData.portalProjectsActive,
        percentageOfOwnership: recordData.percentOwnershipCurrent,
        experience: type === 'lead' ? null: recordData.experience,
        citizenshipStatus: recordData.citizenshipStatus,
        blacklisted: recordData.blacklisted,
        medianScore: recordData.medianScore,
      };
      this.loadForm(formData);
      this.selectedRecord = { ...formData };
      yield type === 'borrower' && this.fetchBorrowerStatus(recordId);
    } catch (e) {
      console.log(e);
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE,
      });
    }
  }

  *fetchBorrowerExtraInfo(borrowerId) {
    try {
      const id = this.getIdFromComposedKey(borrowerId);
      const response = yield this.oneToolService.fetchBorrowerData(id);
      const borrower = response.data;
      this.onFieldChange(
        'projectsCompleted',
        !!borrower.portalProjectsCompleted || !!borrower.portalProjectsActive
      );
      this.onFieldChange(
        'creditPull',
        borrower.sendIdVerification
          ? CreditPull.BORROWER_AUTHORIZATION_FORM
          : CreditPull.XACTUS_360
      );
    } catch (e) {
      console.log(e);
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE,
      });
    }
  }

  getIdFromComposedKey(composedKey) {
    if (!isNumber(composedKey) && composedKey.includes('-')) {
      const recordComposedKey = composedKey.split('-');
      return recordComposedKey[1];
    }
    return composedKey;
  }

  *fetchBorrowerStatus(borrowerId) {
    const id = this.getIdFromComposedKey(borrowerId);
    const isPersonalGuarantor = !!this.form.fields.personalGuarantor.value;
    const preScreenResponse = yield this.borrowerSetupService.getBorrowerPrescreenInfoByBorrowerId(
      id,
      isPersonalGuarantor,
      LoanType.RESIDENTIAL_BRIDGE
    );
    const preScreenData = preScreenResponse.data.data;

    this.idVerificationStatus =
      preScreenData?.driverLicenseNumber !== ''
        ? IDVerificationStatus.ID_ON_FILE
        : IDVerificationStatus.NO_ID_ON_FILE;
    this.onFieldChange(
      'hasIdentification',
      preScreenData?.driverLicenseNumber !== ''
    );
    if (preScreenData.preQualificationStatus === PRE_SCREEN_STATUS_ACCEPTED) {
      const creditAge = differenceInDays(
        new Date(),
        formatDate(preScreenData.creditReportDate)
      );
      if (creditAge > 180) {
        this.creditStatus = CreditStatus.CREDIT_OLDER_THAN_180_DAYS;
      } else {
        this.creditStatus = CreditStatus.CREDIT_AND_BACKGROUND_OK;
      }
    } else {
      if (preScreenData.preQualificationInProgress) {
        this.creditStatus = CreditStatus.CREDIT_AND_BACKGROUND_CHECK_SENT;
      } else {
        this.creditStatus = CreditStatus.NO_CREDIT_AND_BACKGROUND;
      }
    }
    this.onFieldChange('creditStatus', this.creditStatus);
  }

  getBorrower() {
    const values = this.getFormValues();
    return {
      ...values,
      uuid: this.key,
      ficoScore: values.ficoScore ? parseInt(values.ficoScore) : null,
      creditBackgroundStatus: false,
      creditPull: values.creditPull ? values.creditPull : null,
    };
  }

  loadBorrower(borrower) {
    this.loadForm(borrower);
    this.key = borrower.uuid;
  }

  validateBorrower(prefight?: boolean) {
    this.onFieldChange('isPreflight', Boolean(prefight));
    this.runFormValidationWithMessage();
    this.setShowErrors(!this.form.meta.isValid);
    return this.form.meta.isValid;
  }

  get cannotCreateBorrower() {
    return !this.globalStore.userFeatures?.addNewBorrower;
  }

  get isNewBorrower() {
    return !this.form.fields.borrowerId.value && !this.form.fields.leadId.value;
  }
}
