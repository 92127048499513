import { Grid } from '@material-ui/core';
import { Button, StandardDialog, Toolbar } from '@roc/ui';
import { observer } from 'mobx-react';
import { useRef, useState } from 'react';
import { ProtectiveAdvanceModal } from './protectiveAdvanceModal';
import { LoanDashboardStore } from '../../stores/loanDashboardStore';
import { Entity, ProtectiveAdvanceModalType } from '../../types/types';

export const SpecialServicingLoanToolbar = observer(({ loanDashboardStore, vendors }: { loanDashboardStore: LoanDashboardStore, vendors: Entity[] }) => {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false)
  const { protectiveAdvanceFormStore } = loanDashboardStore;

  const handleOpen = () => {
    setOpen(true);
    protectiveAdvanceFormStore.setType(ProtectiveAdvanceModalType.CREATE);
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Toolbar>
          <Actions anchorRef={anchorRef} handleOpen={handleOpen} />
        </Toolbar>
      </Grid>
      <ProtectiveAdvanceModal
        open={open}
        loanId={loanDashboardStore.entity}
        store={protectiveAdvanceFormStore}
        vendors={vendors}
        onClose={() => setOpen(false)}
        onUpload={() => {
          loanDashboardStore.counterPartyExpenseGridStore.resetAndFetchGridData();
          setOpen(false)
        }}
      />
    </Grid>
  );
});

const Actions = ({ anchorRef, handleOpen }) => {
  return <div ref={anchorRef} style={{ display: 'inline-block' }}>

    <Button
      id="basic-button"
      aria-controls={open ? 'basic-menu' : undefined}
      aria-haspopup="true"
      aria-expanded={open ? 'true' : undefined}
      onClick={() => { handleOpen() }}
      testId="open_menu"
      variant="contained"
      size="small"
      color="primary"
    >
      Add Protective Advance
    </Button>
  </div>
}
