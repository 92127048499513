import { Grid, Theme, Menu, MenuItem } from "@material-ui/core";
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { useRef, useState, useEffect } from "react";
import { observer } from "mobx-react";
import { AttachFileButton } from "libs/feature-communication/src/communication/components/attachments/attachFileButton";
import { Editor } from 'react-draft-wysiwyg';
import { ContentState, EditorState, convertToRaw, DefaultDraftBlockRenderMap, convertFromHTML } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { EditorAttachment } from 'libs/feature-communication/src/communication/components/attachments/editorAttachment';
import { FormEvent } from "react";
import { Code } from '@material-ui/icons';

const toolbar = {
  options: ['inline', 'blockType', 'list', 'link'],
  inline: {
    inDropdown: false,
    options: ['bold', 'italic', 'underline'],
  },
  list: {
    inDropdown: false,
    options: ['unordered', 'ordered'],
  },
  link: { inDropdown: false, options: ['link'], }
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    richTextWrapper: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%'
    },
    hiddenFileInput: {
      display: 'none'
    },
    attachmentButtonContainer: {
      display: 'flex',
      flexDirection: 'row-reverse',
      height: '30px'
    },
    menuButtonDiv: {
      display: 'flex',
      alignItems: 'center',
      margin: '0 10px'
    },
    menuButton: {
      display: 'flex',
      alignItems: 'center',
      backgroundColor: 'white',
      border: '1px solid #F1F1F1',
      cursor: 'pointer'
    },
    hiddenToolbar: {
      display: 'none',
    },
    richTextStyles: {
      padding: theme.spacing(0, 2),
      width: '100%',
      '& .public-DraftStyleDefault-block': {
        margin: 'auto'
      },
      '& .DraftEditor-editorContainer, .DraftEditor-root, .public-DraftEditor-content': {
        height: 'auto'
      },
    },
    templateInput: {
      boxShadow: 'inset 0 0 6px rgba(219, 219, 219, 0.5)',
      borderBottomRightRadius: '10px',
      borderBottomLeftRadius: '10px'
    },
    richTextContent: {
      height: '425px',
    },
    richTextContentWithAttachments: {
      height: '350px',
    },
    fileContainer: {
      padding: '15px'
    }
  })
);


const RichTextWithAttachments = observer(({
  onFilesChange,
  attachments,
  mockAttachments = [],
  setAttachments,
  setMockAttachments,
  onChange,
  value,
  readOnly = false,
  reloadDependencies = [],
  placeHolders }:
  {
    onFilesChange?: (event: FormEvent<HTMLInputElement>) => void,
    attachments?: File[],
    mockAttachments?: any[],
    setAttachments?: (state: any) => void,
    setMockAttachments?: (value: any) => void,
    onChange: (value: string) => void,
    value: string,
    readOnly?: boolean,
    reloadDependencies?: any[],
    placeHolders?: any[]
  }) => {

  const classes = useStyles();
  const fileInput = useRef<HTMLInputElement>();
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [placeHolderMenu, setPlaceHolderMenu] = useState(false);
  const [anchorPlaceHolderElement, setAnchorPlaceHolderElement] = useState<null | HTMLElement>(null);

  const handleMenuOpen = (event) => {
    setAnchorPlaceHolderElement(event.currentTarget);
    setPlaceHolderMenu(true);
  }

  const getSafeBodyFromHTML = (html: string): Element => {
    return new DOMParser().parseFromString(html, 'text/html').body;
  }

  useEffect(() => {
    if (value) updateEditorState(value);
  }, reloadDependencies);

  const updateEditorState = (value: string) => {
    const blockRenderMap = DefaultDraftBlockRenderMap.set('br', { element: 'br' });
    const blocksFromHTML = convertFromHTML(value, getSafeBodyFromHTML, blockRenderMap);
    const state = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
    setEditorState(EditorState.createWithContent(state));
  }

  const handleEditorStateChange = (newState) => {
    setEditorState(newState);
    onChange(draftToHtml(convertToRaw(newState.getCurrentContent())));
  }

  return (
    <Grid container className={classes.templateInput}>
      <input
        type='file'
        ref={fileInput}
        onChangeCapture={onFilesChange}
        className={classes.hiddenFileInput}
      />
      <Grid item xs={12} className={classes.attachmentButtonContainer}>
        {!readOnly && <AttachFileButton files={attachments} onAttachClick={() => { if (fileInput.current) fileInput.current.click() }} />}
        {!readOnly && placeHolders &&
          <>
            {placeHolders.length > 0 && <div className={classes.menuButtonDiv}><button onClick={handleMenuOpen} className={classes.menuButton}>{<Code />}</button></div>}
            <Menu
              anchorEl={anchorPlaceHolderElement}
              open={placeHolderMenu}
              onClose={() => setPlaceHolderMenu(false)}
            >
              {placeHolders.map(placeHolder => <MenuItem onClick={() => {
                setPlaceHolderMenu(false);
                updateEditorState(`${value} ${placeHolder}`)
                onChange(`${value} ${placeHolder}`);
              }}>{placeHolder}</MenuItem>)}
            </Menu>
          </>}
      </Grid>
      <Editor
        readOnly={readOnly}
        toolbar={toolbar}
        toolbarClassName={readOnly ? classes.hiddenToolbar : null}
        editorState={editorState}
        editorClassName={`${classes.richTextStyles} ${(attachments?.length > 0 || mockAttachments?.length > 0) ? classes.richTextContentWithAttachments : classes.richTextContent}`}
        wrapperClassName={classes.richTextWrapper}
        onEditorStateChange={handleEditorStateChange}
        spellCheck
      />
      {(attachments?.length > 0 || mockAttachments?.length > 0) && <Grid container spacing={2} className={classes.fileContainer}>
        {attachments?.map(file => (
          <Grid item>
            <EditorAttachment
              file={file}
              onRemove={!readOnly ? () => {
                setAttachments(attachments.filter(f => f != file));
              } : null}
            />
          </Grid>
        ))}
        {mockAttachments?.map(attachment => (
          <Grid item>
            <EditorAttachment
              file={attachment}
              onRemove={!readOnly ? () => {
                setMockAttachments(mockAttachments.filter(mockAttachment => mockAttachment.templateAttachmentId != attachment.templateAttachmentId));
              } : null}
            />
          </Grid>
        ))}
      </Grid>}
    </Grid>)
});

export default RichTextWithAttachments;