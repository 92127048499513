import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { CurrencyField, FieldLabel, Slider, SelectField } from '@roc/ui';
import { observer } from 'mobx-react';
import { SelectBorrowerMode } from '@roc/feature-borrowers';
import {
  createTextField,
  createSelectField,
  createPercentageField,
} from '@roc/ui/componentBuilder';
import {
  termMonthsOptions,
  tooltips,
  rateOptions,
} from '../utils/constants'
import { useProposalsStore } from '../hooks/useProposalsStore';
import { isNotBlank, originatorSubLabel } from '@roc/feature-utils';

const useStyles = makeStyles(() => ({
  slider: {
    marginTop: '0px',
    marginBottom: '30px'
  },
}));
const percentageMarks = [
  { value: 0, label: '0%' },
  { value: 100, label: '100%' },
];
const experienceMarks = [
  { label: '1', value: 1 },
  { label: '5', value: 5 },
];
const PercentageSlider = ({
  store,
  fieldName,
  label,
  onChange,
  tooltip,
  testId,
}) => {
  const classes = useStyles();
  return (
    <>
      <FieldLabel tooltip={tooltip}>{label}</FieldLabel>
      <Box
        minHeight={100}
        paddingLeft={2}
        paddingRight={2}
        display="flex"
        flexDirection="column"
        justifyContent="flex-end"
      >
        <Slider
          testId={testId}
          value={store.form.fields[fieldName].value}
          className={classes.slider}
          onChange={onChange}
          valueLabelDisplay={'on'}
          valueLabelFormat={value => <div>{value}%</div>}
          min={0}
          max={100}
          step={1}
          marks={percentageMarks}
        />
      </Box>
    </>
  );
};
const LoanTerms = observer(() => {
  const { proposalsStore } = useProposalsStore();
  const { detailsStore } = proposalsStore;
  const store = detailsStore.loanTermsForm;
  const { loanParticipantsStore } = detailsStore;
  const modes = [SelectBorrowerMode.EXISTING];
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h4">Loan Terms</Typography>
        <br />
      </Grid>
      <Grid item xs={6}>
        <CurrencyField
          testId={'initialLoanAmount'}
          variant={'outlined'}
          label={'Initial Loan Amount'}
          standaloneLabel
          value={store.form.fields.initialLoanAmount.value}
          onChange={(name, value) => {
            store.onFieldChange('initialLoanAmount', Number(value));
            detailsStore.changeInitialLoanAmount();
          }}
          error={isNotBlank(store.form.fields.initialLoanAmount.error)}
          errorText={store.form.fields.initialLoanAmount.error}
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <SelectField
          standaloneLabel
          label={'Interest Rate'}
          name={'rate'}
          value={store.form.fields.rate.value}
          options={rateOptions}
          onChange={value => store.onFieldChange('rate', value)}
          variant="outlined"
          fullWidth
          shrink
          required
          testId="rate"
        />
      </Grid>
      <Grid item xs={6}>
        <PercentageSlider
          testId="initialLoanAmountSlider"
          store={store}
          fieldName="initialLoanAmountPercentage"
          label="Initial Loan Amount (% Of Cost)"
          tooltip=""
          onChange={(e, value) => {
            store.onFieldChange('initialLoanAmountPercentage', value);
            detailsStore.changeInitialLoanToPurchase();
          }}
        />
      </Grid>
      <Grid item xs={6}>
        {createPercentageField({
          store,
          testId: 'pointsIn',
          fieldName: 'pointsIn',
          label: 'Points In',
          tooltip: tooltips.pointsIn,
        })}
      </Grid>
      <Grid item xs={6}>
        <CurrencyField
          testId={'constructionHoldback'}
          variant={'outlined'}
          label={'Construction Holdback'}
          tooltip={tooltips.constructionHoldbackLimit}
          standaloneLabel
          value={store.form.fields.constructionHoldback.value}
          onChange={(name, value) => {
            store.onFieldChange('constructionHoldback', Number(value));
            detailsStore.changeConstructionHoldback();
          }}
          error={isNotBlank(store.form.fields.constructionHoldback.error)}
          errorText={store.form.fields.constructionHoldback.error}
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        {createPercentageField({
          store,
          testId: 'pointsOut',
          fieldName: 'pointsOut',
          label: 'Points Out',
          tooltip: tooltips.pointsOut,
        })}
      </Grid>
      <Grid item xs={6}>
        <PercentageSlider
          testId="constructionHoldbackSlider"
          store={store}
          fieldName="constructionHoldbackPercentage"
          label="Construction Holdback (%)"
          tooltip=''
          onChange={(e, value) => {
            store.onFieldChange('constructionHoldbackPercentage', value);
            detailsStore.changeConstructionHoldbackPercentage();
          }}
        />
      </Grid>
      <Grid item xs={6}>
        {createSelectField({
          store,
          testId: 'duration',
          fieldName: 'duration',
          label: 'Duration',
          options: termMonthsOptions,
          tooltip: tooltips.termInMonths,
        })}
      </Grid>
      <Grid item xs={6}>
        <CurrencyField
          testId={'amount'}
          variant={'outlined'}
          label={'Total Loan Amount'}
          standaloneLabel
          value={store.form.fields.amount?.value}
          disabled={true}
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        {createTextField({
          store,
          testId: 'projectDescription',
          fieldName: 'projectDescription',
          label: 'Project Description',
          multiline: true,
          tooltip: tooltips.projectDescription,
          inputProps: { maxLength: 500, rows: 3 }
        })}
      </Grid>
      {loanParticipantsStore.allowOriginatorSelection && (
        <Grid item xs={6}>
          {createSelectField({
            store,
            testId: 'lenderOriginatorId',
            fieldName: 'lenderOriginatorId',
            label: 'Originator',
            subLabel: originatorSubLabel,
            options: loanParticipantsStore.originatorOptions
          })}
        </Grid>
      )}
    </Grid>
  );
});
export default LoanTerms;