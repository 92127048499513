import { IconButton, Menu, ListItemIcon, ListItemText } from '@material-ui/core';
import { MoreVert, ImportExport, Check } from '@material-ui/icons';
import { InfiniteScrollGrid, useBaseStore } from '@roc/feature-app-core';
import {
  LinkColumn,
  SelectFilterComponent,
  SelectFloatingFilterComponent,
  StatusChip,
  StatusType,
  TextColumn, FastForwardIcon
} from '@roc/ui';
import { observer } from 'mobx-react';
import React, { useState, useEffect } from 'react';
import { useLoanRoutes } from '@roc/feature-loans-routes-config';
import { useLoanStore } from '../hooks/useLoanStore';
import { getUnreadMessagesCount, StyledBadge } from './myPipelineLoansGrid';
import { SubmitMenuItem, SelectField, ConfirmationMenuItem } from '@roc/ui';
import { useInsuranceReviewStore } from '@roc/feature-insurance-review';


export const MyPendingTasksGrid = observer(({ columns }) => {
  const { loanStore } = useLoanStore();
  const { globalStore } = useBaseStore();
  const { loanRoutesConfig } = useLoanRoutes();
  const [loanId, setLoanId] = useState(0);
  const [anchorEl, setAnchorEl] = useState<HTMLElement>(null);
  const [selectAssignee, setSelectAssignee] = useState(null);
  const menuOpen = Boolean(anchorEl);
  const { insuranceReviewers } = loanStore;
  const { insuranceReviewStore } = useInsuranceReviewStore();

  const getInsuranceReviewerOptions = (insuranceReviewers) => {
    return (insuranceReviewers.map(user => {
      return { label: user.contactName, value: user.userName }
    }));
  }

  useEffect(() => {
    loanStore.fetchInternalInsuranceReviewers();
  }, []);

  const handleMenuClose = () => {
    setAnchorEl(null);
    setLoanId(null);
  };

  const openMenu = (event: React.MouseEvent<HTMLElement>, record) => {
    const { insuranceReviewers } = loanStore;
    setLoanId(record.dealid);
    setSelectAssignee(insuranceReviewers.length > 0 ? insuranceReviewers[0].value : null);
    setAnchorEl(event.currentTarget);
  }

  const frameworkComponents = {
    selectFilter: SelectFilterComponent,
    selectFloatingFilter: SelectFloatingFilterComponent,
    loanIdCellRenderer: params =>
      loanStore.isLoanSubtypeSupported(params.node.data?.loanSubType) ? (
        <StyledBadge
          badgeContent={getUnreadMessagesCount(
            params.node.data?.unreadMessages
          )}
        >
          {LinkColumn({
            ...params,
            url: loanRoutesConfig.loans(params.value).children.dashboard.url,
          })}
        </StyledBadge>
      ) : (
        <StyledBadge
          badgeContent={getUnreadMessagesCount(
            params.node.data?.unreadMessages
          )}
        >
          {TextColumn(params)}
        </StyledBadge>
      ),
    dealNameCellRenderer: params => {
      const loanId = params.node.data?.dealid;
      return LinkColumn({
        ...params,
        url: loanRoutesConfig.loans(loanId).children.dashboard.url,
      });
    },
    fastTrackCellRenderer: ({ value }) => {
      if (!value) {
        return null;
      }
      return (<FastForwardIcon value={value} />)
    },
    statusCellRenderer: ({ value }) =>
      value ? (
        <StatusChip
          label={value?.toUpperCase()}
          size="small"
          statusType={StatusType.DOCUMENT}
        />
      ) : null,
    insuranceStatusCellRenderer: ({ value }) =>
      value ? (
        <StatusChip label={value} size="small" statusType={StatusType.INSURANCE_STATUS} />
      ) : null,
    actionCellRenderer: params => {
      return (
        <IconButton onClick={e => openMenu(e, params.node.data)}>
          <MoreVert color="primary" />
        </IconButton>
      );
    },
  };

  return (
    <>
      <InfiniteScrollGrid
        columns={columns}
        frameworkComponents={frameworkComponents}
        store={loanStore.myPendingTasksGridStore}
      />
      <Menu
        id="menu-appbar"
        getContentAnchorEl={null}
        anchorEl={anchorEl}
        keepMounted
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={menuOpen}
        onClose={handleMenuClose}
      >
        {globalStore.userFeatures?.internalInsuranceReviewer && (
          <ConfirmationMenuItem
            testId={`mark-as-high-${loanId}`}
            onClick={() => {
              insuranceReviewStore.removeFromPendingTasks(loanId, () => {
                loanStore.myPendingTasksGridStore.resetAndFetchGridData();
              });
              handleMenuClose();
            }}
            confirmDialogProps={{
              body: `Are you sure you want to remove loan ${loanId} from my pending tasks?`,
            }}
          >
            <ListItemIcon>
              <Check fontSize="small" />
            </ListItemIcon>
            <ListItemText>Remove from My Pending Tasks</ListItemText>
          </ConfirmationMenuItem>)}

        {globalStore.userFeatures?.internalInsuranceReviewerAdmin && (
          <SubmitMenuItem
            testId={`change-assignee-${loanId}`}
            onClick={() => {
              loanStore.changeAssignee(loanId, selectAssignee, () => loanStore.myPendingTasksGridStore.resetAndFetchGridData());
              handleMenuClose();
            }}
            submitDialogProps={{
              title: `Loan ${loanId}`,
              body: (
                <SelectField
                  standaloneLabel
                  label={'Please choose the new insurance reviewer specialist for this loan:'}
                  value={selectAssignee}
                  required
                  options={getInsuranceReviewerOptions(insuranceReviewers)}
                  onChange={value => setSelectAssignee(value)}
                  variant={'outlined'}
                  fullWidth
                  testId={'select-assignee-dropdown'}
                />
              ),
              okButtonText: 'Submit',
              cancelButtonText: 'Close',
            }}
          >
            <ListItemIcon>
              <ImportExport fontSize="small" />
            </ListItemIcon>
            <ListItemText>Change Reviewer Specialist</ListItemText>
          </SubmitMenuItem>)}
      </Menu >
    </>
  );
});
