import { Grid } from '@material-ui/core';
import { Button, TextField } from '@roc/ui';
import { useDocumentStore } from '@roc/feature-documents';
import { observer } from 'mobx-react';
import React from 'react';
import { isNotBlank } from '@roc/feature-utils';
import {
  createPhoneField,
} from '@roc/ui/componentBuilder';

type InsuranceProviderFormProps = {
  disabled?: boolean;
  onClose: () => void;
  onSave: () => void;
};

export const InsuranceProviderForm = observer(
  (props: InsuranceProviderFormProps) => {
    const { disabled, onClose, onSave } = props;

    const {
      documentStore: { documentFormStore },
    } = useDocumentStore();
    const { insuranceApprovalStore } = documentFormStore;
    const {
      canSubmitInsurance,
      insuranceProviderFormStore,
      canApprove,
    } = insuranceApprovalStore;

    const {
      form: { fields },
    } = insuranceProviderFormStore;

    const onFormChange = (name, e) => {
      const value = e.target.value;
      insuranceProviderFormStore.onFieldChange(name, value);
    };

    const saveInsuranceProviderDetails = () => {
      insuranceProviderFormStore.saveInsuranceProviderDetails(onSave);
    };

    return (
      <Grid container spacing={5} direction="row">
        <Grid item xs={12} sm={6}>
          <Grid container spacing={2}>
            <Grid item sm={12}>
              <TextField
                label="Broker/Insurance Provider Name"
                standaloneLabel
                value={fields.name.value}
                error={isNotBlank(fields.name.error)}
                helperText={fields.name.error}
                onChange={e => onFormChange('name', e)}
                testId="name"
                variant="outlined"
                fullWidth
                disabled={!canApprove}
              />
            </Grid>
            <Grid item xs={12}>
              {createPhoneField({
                testId: 'phone',
                label: 'Broker/Insurance Provider Phone',
                fieldName: 'phone',
                store: insuranceProviderFormStore,
                disabled: !canApprove,
              })}
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Broker/Insurance Provider Email"
                standaloneLabel
                value={fields.emailAddress.value}
                error={isNotBlank(fields.emailAddress.error)}
                helperText={fields.emailAddress.error}
                onChange={e => onFormChange('emailAddress', e)}
                testId="emailAddress"
                variant="outlined"
                fullWidth
                disabled={!canApprove}
              />
            </Grid>
          </Grid>
        </Grid>
        {canSubmitInsurance && (
          <Grid item xs={12}>
            <Grid container spacing={1} justifyContent="flex-end">
              <Grid item>
                <Button
                  onClick={onClose}
                  variant="outlined"
                  color="primary"
                  testId="cancel"
                >
                  Close
                </Button>
              </Grid>
              <Grid item>
                <Button
                  onClick={saveInsuranceProviderDetails}
                  variant="contained"
                  color="primary"
                  testId="save"
                  disabled={!canApprove}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  }
);

export default InsuranceProviderForm;
