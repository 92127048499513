import { Grid } from '@material-ui/core';
import {
  createPhoneField,
  createRadioField,
  createTextField,
} from '@roc/ui/componentBuilder';
import { FormStore } from '@roc/feature-app-core';
import { observer } from 'mobx-react';
interface BrokerField {
  store: FormStore;
}

const field = component => {
  const WrappedComponent = observer(component);
  return props => (
    <Grid item xs={12}>
      <WrappedComponent {...props} />
    </Grid>
  );
};

export const EntityName = field(({ store }: BrokerField) => {
  return createTextField({
    store,
    label: 'Company Name',
    fieldName: 'entityName',
    testId: 'entityName',
  });
});

export const EntityTaxNumber = field(({ store }: BrokerField) => {
  return createTextField({
    store,
    label: 'Company EIN Number',
    fieldName: 'entityTaxNumber',
    testId: 'entityTaxNumber'
  });
});

export const FirstName = field(({ store }: BrokerField) => {
  return createTextField({
    store,
    label: 'Contact First Name',
    fieldName: 'firstName',
    testId: 'firstName',
  });
});

export const LastName = field(({ store }: BrokerField) => {
  return createTextField({
    store,
    label: 'Contact Last Name',
    fieldName: 'lastName',
    testId: 'lastName',
  });
});

export const PhoneNumber = field(({ store }: BrokerField) => {
  return createPhoneField({
    store,
    label: 'Contact Phone Number',
    fieldName: 'cellPhone',
    testId: 'cellPhone',
  });
});

export const EmailAddress = field(({ store }: BrokerField) => {
  return createTextField({
    store,
    label: 'Contact Email',
    fieldName: 'emailAddress',
    type: 'email',
    testId: 'emailAddress',
  });
});
