import {
  Box,
  Divider,
  Grid,
  InputAdornment,
  makeStyles,
  SvgIconProps,
  TextField,
  Typography,
} from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import {
  createRadioField,
  createTextField,
} from '../../../../components/temp/componentBuiderTemp';
import {
  brokerTypeOptions,
  createNumberFormatField,
  createPhoneField,
} from '@roc/ui';
import { observer } from 'mobx-react';
import { EditControlsPreflightCards } from '../../../../components/editControls';
import { ItemDisplay } from '../../../../components/itemDisplay';
import { RoundedBackgroundIcon } from '../../../../components/roundedBackgroundIcon';
import { FORM_MAX_WIDTH } from '../../../../utils/constants';
import { PercentagePicker } from '../../../leverage/components/leverageComponents';
import { ReactComponent as HandShakeIcon } from '../../../../../assets/handShakeIcon.svg';
import { useEffect } from 'react';
import { BrokerStore } from 'libs/feature-one-tool/src/quote/stores/broker/brokerStore';
import { useStore } from '@roc/client-portal-shared/hooks';

const useStyles = makeStyles(theme => ({
  infoMessage: {
    fontStyle: 'italic',
    marginTop: '0rem',
    marginLeft: '1rem',
    color: 'gray',
    fontSize: '12px',
  },
  autocomplete: {
    '& .MuiAutocomplete-endAdornment': {
      display: 'none',
    },
    '& .MuiInputBase-root': {
      paddingRight: '10px',
    },
    '& .MuiOutlinedInput-root': {
      paddingRight: '10px',
    },
  },
}));

interface BrokerInformationFormProps {
  store: BrokerStore;
  onDeleteClick: (borrowerKey: string) => void;
  showActions?: boolean;
}

export const BrokerInformationForm = observer(
  ({ store, showActions = true }: BrokerInformationFormProps) => {
    const classes = useStyles();
    const { globalStore } = useStore();

    function saveHandler() {
      const isValid = store.brokerFormStore.runFormValidationWithMessage(
        'Please complete all the required fields'
      );
      if (isValid) {
        store.brokerFormStore.setSkipped(false);
        store.brokerFormStore.setEditMode(false);
      }
    }

    const handleResetBrokerForm = () => {
      store.brokerFormStore.reset();
      store.brokerFormStore.setEditMode(true);
    };

    const handleSkipBrokerForm = () => {
      store.brokerFormStore.reset();
      store.brokerFormStore.setSkipped(true);
      store.brokerFormStore.setEditMode(false);
    };

    return (
      <>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          style={{ marginBottom: '16px' }}
        >
          <Grid item xs={6}>
            <Grid
              container
              spacing={2}
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item alignItems="center" style={{ height: '100%' }}>
                <RoundedBackgroundIcon
                  Icon={(props: SvgIconProps) => <HandShakeIcon />}
                  iconColor="#6D6D6D"
                />
              </Grid>
              <Grid item>
                <ItemDisplay
                  value={'Broker Information'}
                  alignItems="flex-start"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Grid item container spacing={2} style={{ maxWidth: FORM_MAX_WIDTH }}>
            <Grid item xs={12} className={classes.autocomplete}>
              <SearchBrokerAutocompleteField store={store} />
            </Grid>
            <Grid item xs={12}>
              <OrDivider />
            </Grid>
            <Grid item xs={12} sm={6}>
              {createTextField({
                label: 'Company Name',
                fieldName: 'companyName',
                testId: 'companyName',
                store: store.brokerFormStore,
                standaloneLabel: false,
              })}
            </Grid>
            <Grid item xs={12} sm={6}>
              {createNumberFormatField({
                label: 'Company EIN',
                fieldName: 'companyEin',
                testId: 'companyEin',
                store: store.brokerFormStore,
                format: '##-#######',
                standaloneLabel: false,
                isRequired: true
              })}
            </Grid>
            <Grid item xs={12} sm={6}>
              {createTextField({
                label: 'Contact First Name',
                fieldName: 'contactFirstName',
                testId: 'contactFirstName',
                store: store.brokerFormStore,
                standaloneLabel: false,
              })}
            </Grid>
            <Grid item xs={12} sm={6}>
              {createTextField({
                label: 'Contact Last Name',
                fieldName: 'contactLastName',
                testId: 'contactLastName',
                store: store.brokerFormStore,
                standaloneLabel: false,
              })}
            </Grid>
            <Grid item xs={12} sm={6}>
              {createTextField({
                label: 'Contact Email',
                fieldName: 'contactEmail',
                testId: 'contactEmail',
                store: store.brokerFormStore,
                standaloneLabel: false,
              })}
            </Grid>
            <Grid item xs={12} sm={6}>
              {createPhoneField({
                label: 'Contact Phone Number',
                fieldName: 'contactPhoneNumber',
                testId: 'contactPhoneNumber',
                store: store.brokerFormStore,
                standaloneLabel: false,
              })}
            </Grid>

            {showActions && (
              <Grid
                item
                container
                spacing={2}
                justifyContent="center"
                alignItems="center"
                style={{ padding: '1rem' }}
              >
                <EditControlsPreflightCards
                  onSaveClick={saveHandler}
                  onResetClick={handleResetBrokerForm}
                  onSkipClick={handleSkipBrokerForm}
                  disableButtons={!store.brokerFormStore.form.meta.isValid}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </>
    );
  }
);

export const SearchBrokerAutocompleteField = observer(
  ({ store }: { store: BrokerStore }) => {
    const classNames = useStyles();
    useEffect(() => {
      store.getLenderBrokerDropdownData();
    }, []);

    const handleBrokerSelection = broker => {
      store.getBrokerById(broker.value);
    };

    return (
      <Autocomplete
        id="autocomplete-broker"
        disableClearable={true}
        className={classNames.autocomplete}
        options={store.lenderBrokerData}
        getOptionLabel={option => option?.label ?? ''}
        renderInput={params => (
          <TextField
            {...params}
            label="Search broker"
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <InputAdornment position="end">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        )}
        onChange={(event, selectedBroker) =>
          handleBrokerSelection(selectedBroker)
        }
        fullWidth
      />
    );
  }
);

const OrDivider = () => {
  return (
    <Box display="flex" alignItems="center" my={2}>
      <Divider style={{ flex: 1 }} />
      <Typography
        variant="body1"
        style={{
          marginLeft: '6px',
          marginRight: '6px',
          color: 'gray',
          fontWeight: 'bold',
        }}
      >
        or
      </Typography>
      <Divider style={{ flex: 1 }} />
    </Box>
  );
};
