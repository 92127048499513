import { Service } from '@roc/feature-app-core';
import { LedgerNote } from '../types/ledgerNote';
import { LoanDqStageData } from '../types/loanDqStageData';
import { LoanDqReasonData } from '../types/loanDqReasonData';
import { LoanDqFollowUpData } from '../types/loanDqFollowUpData';
import { LoanDqRepurchaseRequestedData } from '../types/loanDqRepurchaseData';

const url = {
  GET_ACTIVE_FORECLOSURE: '/api/v1/loan/getActiveForeclosureByLoanId',
  UPDATE_ACTIVE_FORECLOSURE: '/api/v1/loan/updateActiveForeclosure',
  REQUEST_BREAKDOWN: '/api/v1/loan/requestForeclosureBreakdown',
  ADD_FORECLOSURE_INVOICE: '/api/v1/loan/addForeclosureInvoice',
  GET_INVOICES: '/api/v1/loan/getForeclosureExpensesByLoanId',
  GET_SUMMARY: '/api/v1/loan/getLoanForeclosureSummary',
  FETCH_NOTES: '/api/v1/loan/fetchForeclosureNotes',
  SAVE_NOTE: '/api/v1/loan/saveForeclosureNote',
  DELETE_NOTE: '/api/v1/loan/deleteForeclosureNote',
  SAVE_FORECLOSURE_STAGES: '/api/v1/loan/saveForeclosureStages',
  SAVE_FORECLOSURE_REASON: '/api/v1/loan/saveForeclosureReason',
  SAVE_FORECLOSURE_FOLLOW_UP: '/api/v1/loan/saveForeclosureFollowUp',
  SAVE_FORECLOSURE_REPURCHASE: '/api/v1/loan/saveForeclosureRepurchaseRequested',
  UPLOAD_MILESTIONE_FILE: '/api/v1/loan/specialServicing/uploadMilestoneFile',
  SAVE_REO_DETAILS: '/api/v1/loan/specialServicing/saveReoDetails',
  SAVE_SOLD_AT_DETAILS: '/api/v1/loan/specialServicing/saveSoldAtForeclosureDetails',
};

export class LoanForeclosureService extends Service {

  getActiveForeclosureByLoanId(loanId: string) {
    return this.get(`${url.GET_ACTIVE_FORECLOSURE}?loanId=${loanId}`);
  }

  requestForeclosureBreakdown(loanId: number, formattedDate: string) {
    return this.get(`${url.REQUEST_BREAKDOWN}?loanId=${loanId}&formattedDate=${formattedDate}`);
  }

  updateActiveForeclosure(data) {
    return this.post(`${url.UPDATE_ACTIVE_FORECLOSURE}`, data);
  }

  addForeclosureInvoice(
    loanId: string,
    invoice: string,
    fee: string,
    file: File,
    typeOfFee: string,
  ) {
    const formData = new FormData();
    formData.append('loanId', loanId);
    formData.append('invoice', invoice);
    formData.append('fee', fee);
    formData.append('file', file);
    formData.append('typeOfFee', typeOfFee);
    return this.post(url.ADD_FORECLOSURE_INVOICE, formData);
  };

  getInvoicesByLoanId(loanId: string, typeOfFee: string) {
    return this.get(`${url.GET_INVOICES}?loanId=${loanId}&typeOfFee=${typeOfFee}`);
  };

  getSummary(loanId: string) {
    return this.get(`${url.GET_SUMMARY}?loanId=${loanId}`);
  };

  fetchNotes(loanId: number) {
    return this.get(`${url.FETCH_NOTES}?loanId=${loanId}`);
  };

  saveNote(note: LedgerNote) {
    return this.post(url.SAVE_NOTE, note);
  };

  deleteNote(ledgerNoteId: number) {
    return this.delete(`${url.DELETE_NOTE}?ledgerNoteId=${ledgerNoteId}`);
  };

  saveForeclosureStages(loanId: string, stages: LoanDqStageData) {
    return this.post(`${url.SAVE_FORECLOSURE_STAGES}?loanId=${loanId}`, stages);
  }

  saveForeclosureReason(data: LoanDqReasonData) {
    return this.post(`${url.SAVE_FORECLOSURE_REASON}`, data);
  }

  saveForeclosureFollowUp(data: LoanDqFollowUpData) {
    return this.post(`${url.SAVE_FORECLOSURE_FOLLOW_UP}`, data);
  }

  saveForeclosureRepurchaseRequested(data: LoanDqRepurchaseRequestedData) {
    return this.post(`${url.SAVE_FORECLOSURE_REPURCHASE}`, data);
  }

  uploadMilestoneFile(loanId: string, stageName: string, file: File,) {
    const formData = new FormData();
    formData.append('loanId', loanId);
    formData.append('stage', stageName);
    formData.append('file', file);
    return this.post(url.UPLOAD_MILESTIONE_FILE, formData);
  }

  saveOwnedDetails(data) {
    return this.post(url.SAVE_REO_DETAILS, data);
  };

  saveSoldAtDetails(data) {
    return this.post(url.SAVE_SOLD_AT_DETAILS, data);
  };
}