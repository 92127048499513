import { Box } from '@material-ui/core';
import { Button, DialogState, EditableDialog, FilterButton, Toolbar } from '@roc/ui';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { LendersGrid } from './lendersGrid';
import {useStore} from 'libs/client-portal-shared/src/app/hooks/index'

export const LenderDashboard = observer(() => {

  const { salesforceLenderStore } = useStore();

  useEffect(() => {
    salesforceLenderStore.gridStore.resetAndFetchGridData();
  }, [salesforceLenderStore]);

  const handleToggleFilter = () => {
    salesforceLenderStore.gridStore.toggleFilters();
  };

  const handleClearFilter = () => {
    salesforceLenderStore.gridStore.resetFilters();
  };

  return (
    <>
      <LendersGrid
        toolbar={
          <Toolbar>
            <Box mr={2}>
              <FilterButton
                onToggleFilters={handleToggleFilter}
                onClearFilters={handleClearFilter}
              />
            </Box>
          </Toolbar>
        }
      />
    </>
  );
});