import { ThumbDown, ThumbUp } from '@material-ui/icons';
import { Button, TextField } from '@roc/ui';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { Grid, IconButton, Theme, Typography } from '@material-ui/core';
import { ComparableProperty } from '../../types/propertyTypes';
import { observer } from 'mobx-react';
import { useStore } from '@roc/client-portal-shared/hooks';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridItem: {
      marginBottom: '20px'
    },
    text: {
      fontSize: '16px',
      fontWeight: 800,
      color: '#717580',
      marginBottom: '20px'
    },
    gridContainer: {
      paddingBottom: '20px'
    },
    centerItem: {
      textAlign: 'center',
      alignContent: 'center'
    },
    button: {
      padding: '10px',
      textAlign: 'center',
      alignContent: 'center'
    }
  })
);

export interface PropertyFeedbackProps {
  property: ComparableProperty;
}


export const PropertyFeedback: React.FC<PropertyFeedbackProps> = observer(({
  property,
}) => {
  const { compToolStore } = useStore();
  const classes = useStyles();
  const { compToolFeedbackStore } = compToolStore
  const { showFeedbackThankYou, feedBackComment, showFeedbackComment } = compToolFeedbackStore

  return (
    <>

      {showFeedbackThankYou ? (
        <Grid item lg={12} xs={12} className={classes.gridItem}>
          <Typography align="justify" className={classes.text}>
            Thank you for your feed back.
          </Typography>

        </Grid>
      ) : (
        <>

          <Grid item xs={12} >
            <Typography align="justify" className={classes.text}>
              Is this comp accurate?
              <IconButton onClick={() => { compToolFeedbackStore.setShowFeedbackThankYou(true), compToolFeedbackStore.setIsFeedbackPositive(true), compToolStore.sendCompFeedback(property) }}>
                <ThumbUp style={{ color: '#7F7F7F' }} />
              </IconButton>
              <IconButton onClick={() => { compToolFeedbackStore.setShowFeedbackComment(true), compToolFeedbackStore.setIsFeedbackPositive(false) }}>
                <ThumbDown style={{ color: showFeedbackComment ? '#285391' : '#7F7F7F' }} />
              </IconButton>
            </Typography>
          </Grid>
          {showFeedbackComment &&
            <>
              <Grid container className={classes.gridContainer}>
                <Grid item lg={10} sm={10} xs={12}>
                  <TextField
                    testId="feedbackComment"
                    variant="outlined"
                    label="Please tell us how this comp can be improved?"
                    value={feedBackComment}
                    onChange={(e) => { compToolFeedbackStore.setFeedbackComment(e.target.value) }}
                    style={{
                      width: '100%'
                    }}
                  />
                </Grid>
                <Grid item lg={2} sm={2} xs={12} className={classes.centerItem}>
                  <Button
                    variant="contained"
                    color="primary"
                    testId="sendFeedback"
                    className={classes.button}
                    disabled={feedBackComment == ''}
                    onClick={() => { compToolStore.sendCompFeedback(property) }}
                  >
                    SEND
                  </Button>
                </Grid>
              </Grid>
            </>
          }

        </>
      )}
    </>
  );
});