import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, List, ListItem, ListItemIcon, Theme, Typography } from '@material-ui/core';
import { Warning } from '@material-ui/icons';
import { observer } from 'mobx-react';
import { AllowLoanPricingForUsers } from './userForm';
import { roleOptions } from '@roc/feature-utils';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CancelOutlined from '@material-ui/icons/CancelOutlined';

const useWarningStyles = makeStyles((theme:Theme) => ({
  item:{
    padding: theme.spacing(0)
  },
  success: {
    color: theme.palette.success.main,
  },
  error: {
    color: theme.palette.error.main,
  },
}));

export const UserPreferencesOptions = observer(({ store }) => {
  const classes = useWarningStyles();
  const requirePermissionRoles = [roleOptions.BROKER.value, roleOptions.ORIGINATOR.value]
  const showAllowCommunicationField = requirePermissionRoles.includes(store.form.fields.role.value)
  const requireSubmitPermissionRoles = [roleOptions.ORIGINATOR.value]
  const showAllowSubmissionField = requireSubmitPermissionRoles.includes(store.form.fields.role.value)

  return (
    <>
      {showAllowSubmissionField && (
        <Grid item xs={12}>
          <div>
            Do you want the user to have access to pricing, messaging and direct
            loan submission to back office?
          </div>


          {store.form.fields.allowLoanPricing.value ? (
            <List>
              <ItemCheck checked>
                User will be able to see pricing fields (for eg. points, broker
                points, participation mechanics etc..){' '}
              </ItemCheck>
              <ItemCheck checked>
                User will be added to the conversation rooms as participant and
                will be able to send / receive messages
              </ItemCheck>
              <ItemCheck checked>
                User will be able to directly submit loans to back office
                without inspection
              </ItemCheck>
            </List>
          ) : (
            <List>
              <ItemCheck unchecked>
                User won't be able to see pricing fields (for eg. points, broker
                points, participation mechanics etc..)
              </ItemCheck>
              <ItemCheck unchecked>
                User won't be added to the conversation rooms as participant and
                won't be able to send / receive messages
              </ItemCheck>
              <ItemCheck unchecked>
                Loan submission will have to be approved by admins and
                processors in order to be sent to back office
              </ItemCheck>
            </List>
          )}
          <AllowLoanPricingForUsers store={store} />
        </Grid>
      )}
    </>
  );
});

const ItemCheck = ({ checked = false, unchecked = false, children }) => {
  const classes = useWarningStyles();
  return (
    <ListItem className={classes.item}>
      <ListItemIcon>
        {checked && (
          <CheckCircleOutlineIcon
            className={classes.success}
            fontSize="small"
          />
        )}
        {unchecked && (
          <CancelOutlined
            className={classes.error}
            fontSize="small"
          />
        )}
      </ListItemIcon>
      <Typography
        variant="body2"
        color="textSecondary"
        className={checked ? classes.success : classes.error}
      >
        {children}
      </Typography>
    </ListItem>
  );
};
