import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Box, Grid, lighten, makeStyles, Theme, Typography, useTheme } from '@material-ui/core';
import { LoginLogoImg, Page } from '@roc/feature-app-core';
import { Paper, PaperBorderType, PaperTitle, PreviewBackdrop } from '@roc/ui';
import clsx from 'clsx';
import jsPDF from 'jspdf';
import { useEffect, useRef, useState } from 'react';
import { useDocumentLibraryRoutes } from '../../hooks/useDocumentLibraryRoutes';
import { format } from 'date-fns';

const useStyles = makeStyles((theme: Theme) => ({
  documentContainer: {
    position: 'relative',
    height: '100%',
    padding: '16px 5%',
    background: '#FFF',
    fontSize: 16,
    '& ul,ol': {
      marginLeft: theme.spacing(3),
      marginBottom: theme.spacing(0.5),
    },
    '& table': {
      width: '100%',
      borderCollapse: 'collapse'
    },
    '@media (max-width: 800px)': {
      fontSize: 8,
      padding: 0,
    },
  },
  documentCard: {
    borderRadius: 0,
    marginBottom: theme.spacing(1),
    borderColor: '#aaa',
  },
  documentCardTitle: {
    justifyContent: 'center',
    textAlign: 'center',
    background: lighten(theme.palette.primary.main, 0.5),
    fontWeight: 'bold',
    padding: theme.spacing(0.5),
    '& h6': {
      '@media (max-width: 800px)': {
        fontSize: 10,
      },
    },
  },
  underWritingDocumentsNotesTitle: {
    alignItems: 'center',
    height: '100%',
    paddingTop: '12%',
  },
  underWritingDocumentsHeader: {
    '& table th': {
      padding: '5%',
      background: lighten(theme.palette.primary.main, 0.8),
    },
    marginBottom: 0,
    '& div': {
      padding: 0
    }
  },
  underWritingDocumentsCard: {
    '& table td ': {
      textAlign: 'center',
      width: 200,
      paddingTop: '1%',
      paddingBottom: '1%',
      borderBottom: '1px solid grey',
      '& p': {
        marginLeft: 10
      }
    },

    '& table td:nth-child(1)': {
      textAlign: 'left',
    },
    '& table td:nth-child(4)': {
      textAlign: 'left',
    },
    '& table tr:last-child td': {
      border: 'none'
    }
  },
  closingDocumentsCard: {
    '& h4': {
      textAlign: 'center',
      paddingTop: '2%',
      paddingBottom: '2%'
    },
    '& table th': {
      textAlign: 'center',
      background: lighten(theme.palette.primary.main, 0.5),
      minWidth: 100
    },
    '& table td': {
      paddingTop: '1%',
      paddingBottom: '1%',
      borderBottom: '1px solid grey'
    },
    '& table tr:last-child td': {
      borderBottom: 'none'
    },
    '& table td:nth-child(1)': {
      textAlign: 'center',
    }
  },
  closingDocumentsCard4: {
    '& h3': {
      paddingTop: '1%',
      paddingBottom: '1%'
    },

    '& table': {
      marginTop: '2%'
    },
    '& table td': {
      paddingTop: '0.5%',
      paddingBottom: '0.5%'
    },
  }
}));

const usePrintModeStyles = makeStyles((theme: Theme) => ({
  documentContainer: {
    fontSize: 8,
    '& ul,ol': {
      marginLeft: theme.spacing(2),
    },
    '& h6': {
      fontSize: 10,
    },
  },
}));

const DocumentCard = ({
  title = undefined,
  data,
  paperBorder = PaperBorderType.SOLID_BORDER,
  paperClassName = '',
  paperTitleClassName = '',
  paperBodyClassName = '',
  paperStyle = {},
}) => {
  const classes = useStyles();
  return (
    <Paper
      title={title}
      titleClass={clsx(classes.documentCardTitle, paperTitleClassName)}
      titleVariant={'h6'}
      borderType={paperBorder}
      className={clsx(classes.documentCard, paperClassName)}
      style={paperStyle}>
      <Box p={0.5} className={paperBodyClassName}>
        {documentToReactComponents(data)}
      </Box>
    </Paper>
  )
}

const TableHeader = ({
  rigthTitle,
  paperRightTitleClassName = '',
  title,
  data,
  paperBorder = PaperBorderType.SOLID_BORDER,
  paperClassName = '',
  paperTitleClassName = '',
  paperBodyClassName = '',
  paperStyle = {},
}) => {
  const classes = useStyles();
  return (
    <Grid container >
      <Grid item xs={3}>

      </Grid>
      <Grid item xs={6}>
        <DocumentCard
          title={title}
          data={data}
          paperBodyClassName={paperBodyClassName}
          paperClassName={paperClassName}
          paperStyle={paperStyle}
          paperBorder={PaperBorderType.NO_BORDER}
        />
      </Grid>

      <Grid item xs={3}>
        <PaperTitle
          title={rigthTitle}
          titleClass={clsx(classes.documentCardTitle, paperRightTitleClassName)}
          titleVariant={'h6'}
        />
      </Grid>
    </Grid>
  )
}

export const RentalLoansUWAndClosingChecklistHtml = ({
  contentId,
  data,
  preview = false,
  printMode = false,
  setContainerRef,
}: {
  contentId: string,
  data: any,
  preview?: boolean,
  printMode?: boolean,
  setContainerRef: any,
}) => {
  const theme = useTheme();
  const { documentLibraryRoutesConfig } = useDocumentLibraryRoutes();
  const classes = useStyles();
  const printModeClasses = usePrintModeStyles();
  const containerRef = useRef(null);

  const [printModeContainerStyle, setPrintModeContainerStyle] = useState<any>();
  const [containerStyle, setContainerStyle] = useState<any>();

  useEffect(() => {
    const doc = new jsPDF("p", "mm", "a4");
    let width: any = doc.internal.pageSize.getWidth();
    let height: any = doc.internal.pageSize.getHeight();
    width = `${Math.floor(width)}mm`;
    height = `${Math.floor(height)}mm`;
    setPrintModeContainerStyle({
      margin: 'auto',
      minWidth: width,
      maxWidth: width,
      height: height
    });
    setContainerStyle({
      margin: 'auto',
      maxWidth: theme.breakpoints.values.lg,
    });
  }, []);

  useEffect(() => {
    setContainerRef(containerRef);
  }, [containerRef])

  if (!data) {
    return null;
  }

  return (
    <div ref={containerRef} style={printMode ? printModeContainerStyle : containerStyle}>
      <Page routeDefinition={documentLibraryRoutesConfig.documentLibraryDocument(contentId)}>
        <Box padding={'16px'} className={clsx(classes.documentContainer, printMode ? printModeClasses.documentContainer : undefined)}>
          {preview && <PreviewBackdrop />}
          <LoginLogoImg imgStyle={{ maxWidth: 200 }} />
          <Box my={2} textAlign='center'>

            <Typography style={{ marginTop: '10px', marginBottom: '20px' }} variant={'h5'}>
              <strong>{data.underwritingDocumentsChecklistTitle}</strong>
            </Typography>
          </Box>

          <Box mb={2} paddingBottom="5%" overflow={'auto'} >
            <Grid container >
              <Grid item xs={12} style={{ minWidth: 500 }}>

                <TableHeader
                  rigthTitle={data.notesTitle}
                  paperRightTitleClassName={classes.underWritingDocumentsNotesTitle}
                  paperClassName={classes.underWritingDocumentsHeader}
                  title={data.loanProgramsTitle}
                  data={data.underwritingDocumentsTableHeader}
                  paperBorder={PaperBorderType.NO_BORDER}
                />

                <DocumentCard
                  title={data.underwritingDocumentsGeneralTitle}
                  paperClassName={classes.underWritingDocumentsCard}
                  data={data.underwritingDocumentsGeneralCard}
                  paperBorder={PaperBorderType.NO_BORDER}
                />

                <DocumentCard
                  title={data.underwritingDocumentsGuarantorTitle}
                  paperClassName={classes.underWritingDocumentsCard}
                  data={data.underwritingDocumentsGuarantorCard}
                  paperBorder={PaperBorderType.NO_BORDER}
                />

                <DocumentCard
                  title={data.underwritingDocumentsBorrowingEntityTitle}
                  paperClassName={classes.underWritingDocumentsCard}
                  data={data.underwritingDocumentsBorrowingEntityCard}
                  paperBorder={PaperBorderType.NO_BORDER}
                />

              </Grid>
            </Grid>
          </Box>
        </Box>
      </Page>

      <Page routeDefinition={documentLibraryRoutesConfig.documentLibraryDocument(contentId)}>
        <Box padding={'16px'} className={clsx(classes.documentContainer, printMode ? printModeClasses.documentContainer : undefined)}>
          {preview && <PreviewBackdrop />}
          <LoginLogoImg imgStyle={{ maxWidth: 200 }} />
          <Box my={2} textAlign='center'>



            <Typography style={{ marginTop: '10px', marginBottom: '20px' }} variant={'h5'}>
              <strong>{data.underwritingDocumentsChecklistTitle}</strong>
            </Typography>
          </Box>

          <Box mb={2} paddingBottom="5%" overflow={'auto'} >
            <Grid container >
              <Grid item xs={12} style={{ minWidth: 500 }}>

                <TableHeader
                  rigthTitle={data.notesTitle}
                  paperRightTitleClassName={classes.underWritingDocumentsNotesTitle}
                  paperClassName={classes.underWritingDocumentsHeader}
                  title={data.loanProgramsTitle}
                  data={data.underwritingDocumentsTableHeader}
                  paperBorder={PaperBorderType.NO_BORDER}
                />

                <DocumentCard
                  title={data.underwritingDocumentsSubjectPropertyTitle}
                  paperClassName={classes.underWritingDocumentsCard}
                  data={data.underwritingDocumentsSubjectPropertyCard1}
                  paperBorder={PaperBorderType.NO_BORDER}
                />


              </Grid>
            </Grid>
          </Box>
        </Box>
      </Page>

      <Page routeDefinition={documentLibraryRoutesConfig.documentLibraryDocument(contentId)}>
        <Box padding={'16px'} className={clsx(classes.documentContainer, printMode ? printModeClasses.documentContainer : undefined)}>
          {preview && <PreviewBackdrop />}
          <LoginLogoImg imgStyle={{ maxWidth: 200 }} />
          <Box my={2} textAlign='center'>



            <Typography style={{ marginTop: '10px', marginBottom: '20px' }} variant={'h5'}>
              <strong>{data.underwritingDocumentsChecklistTitle}</strong>
            </Typography>
          </Box>

          <Box mb={2} paddingBottom="5%" overflow={'auto'} >
            <Grid container >
              <Grid item xs={12} style={{ minWidth: 500 }}>

                <TableHeader
                  rigthTitle={data.notesTitle}
                  paperRightTitleClassName={classes.underWritingDocumentsNotesTitle}
                  paperClassName={classes.underWritingDocumentsHeader}
                  title={data.loanProgramsTitle}
                  data={data.underwritingDocumentsTableHeader}
                  paperBorder={PaperBorderType.NO_BORDER}
                />

                <DocumentCard
                  title={data.underwritingDocumentsSubjectPropertyTitle}
                  paperClassName={classes.underWritingDocumentsCard}
                  data={data.underwritingDocumentsSubjectPropertyCard2}
                  paperBorder={PaperBorderType.NO_BORDER}
                />

                <DocumentCard
                  title={data.underwritingDocumentsOtherTitle}
                  paperClassName={classes.underWritingDocumentsCard}
                  data={data.underWritingDocumentsOtherCard}
                  paperBorder={PaperBorderType.NO_BORDER}
                />



              </Grid>
            </Grid>
          </Box>
        </Box>
      </Page>

      <Page routeDefinition={documentLibraryRoutesConfig.documentLibraryDocument(contentId)}>
        <Box padding={'16px'} className={clsx(classes.documentContainer, printMode ? printModeClasses.documentContainer : undefined)}>
          {preview && <PreviewBackdrop />}
          <LoginLogoImg imgStyle={{ maxWidth: 200 }} />
          <Box my={2} textAlign='center'>

            <Typography style={{ marginTop: '10px', marginBottom: '20px' }} variant={'h5'}>
              <strong>{data.closingDocumentsChecklistTitle}</strong>
            </Typography>
          </Box>

          <Box mb={2} paddingBottom="5%" overflow={'auto'} >
            <Grid container >
              <Grid item xs={12} style={{ minWidth: 400 }}>

                <DocumentCard
                  paperClassName={classes.closingDocumentsCard}
                  data={data.closingDocumentsCard1}
                  paperBorder={PaperBorderType.NO_BORDER}
                />

              </Grid>
            </Grid>
          </Box>
        </Box>
      </Page>

      <Page routeDefinition={documentLibraryRoutesConfig.documentLibraryDocument(contentId)}>
        <Box padding={'16px'} className={clsx(classes.documentContainer, printMode ? printModeClasses.documentContainer : undefined)}>
          {preview && <PreviewBackdrop />}
          <LoginLogoImg imgStyle={{ maxWidth: 200 }} />
          <Box my={2} textAlign='center'>

            <Typography style={{ marginTop: '10px', marginBottom: '20px' }} variant={'h5'}>
              <strong>{data.closingDocumentsChecklistTitle}</strong>
            </Typography>
          </Box>

          <Box mb={2} paddingBottom="5%" overflow={'auto'} >
            <Grid container >
              <Grid item xs={12} style={{ minWidth: 400 }}>

                <DocumentCard
                  paperClassName={classes.closingDocumentsCard}
                  data={data.closingDocumentsCard2}
                  paperBorder={PaperBorderType.NO_BORDER}
                />

              </Grid>
            </Grid>
          </Box>
        </Box>
      </Page>

      <Page routeDefinition={documentLibraryRoutesConfig.documentLibraryDocument(contentId)}>
        <Box padding={'16px'} className={clsx(classes.documentContainer, printMode ? printModeClasses.documentContainer : undefined)}>
          {preview && <PreviewBackdrop />}
          <LoginLogoImg imgStyle={{ maxWidth: 200 }} />
          <Box my={2} textAlign='center'>

            <Typography style={{ marginTop: '10px', marginBottom: '20px' }} variant={'h5'}>
              <strong>{data.closingDocumentsChecklistTitle}</strong>
            </Typography>
          </Box>

          <Box mb={2} paddingBottom="5%" overflow={'auto'} >
            <Grid container >
              <Grid item xs={12} style={{ minWidth: 400 }}>

                <DocumentCard
                  paperClassName={classes.closingDocumentsCard}
                  data={data.closingDocumentsCard3}
                  paperBorder={PaperBorderType.NO_BORDER}
                />

              </Grid>
            </Grid>
          </Box>
        </Box>
      </Page>

      <Page routeDefinition={documentLibraryRoutesConfig.documentLibraryDocument(contentId)}>
        <Box padding={'16px'} className={clsx(classes.documentContainer, printMode ? printModeClasses.documentContainer : undefined)}>
          {preview && <PreviewBackdrop />}
          <LoginLogoImg imgStyle={{ maxWidth: 200 }} />
          <Box my={2} textAlign='center'>

            <Typography style={{ marginTop: '10px', marginBottom: '20px' }} variant={'h5'}>
              <strong>{data.closingDocumentsChecklistTitle}</strong>
            </Typography>
          </Box>

          <Box mb={2} paddingBottom="5%" overflow={'auto'} >
            <Grid container >
              <Grid item xs={12} style={{ minWidth: 400 }}>

                <DocumentCard
                  paperClassName={classes.closingDocumentsCard4}
                  data={data.closingDocumentsCard4}
                  paperBorder={PaperBorderType.NO_BORDER}
                />

              </Grid>
            </Grid>
          </Box>
          <Box m={1} position={'absolute'} right={0} bottom={0} textAlign='right' color={'#ccc'}>
            <Typography variant={'body2'}>
              {`Last Updated: ${format(new Date(data.lastUpdatedDate), 'MM-dd-yyyy')}`}
            </Typography>
          </Box>
        </Box>
      </Page>



    </div>
  );
}