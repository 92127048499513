import { observer } from 'mobx-react';
import React, { ReactNode, useEffect, useState } from 'react';
import { AgGridColumnProps, DataGrid } from '@roc/ui';
import { useStore } from '@roc/client-portal-shared/hooks';
import { CompanyProfileCRM } from '../companyProfile/companyProfileCRM';
import { Button, Grid } from '@material-ui/core';
import { Layout } from '@roc/feature-app-core';
import { makeStyles } from '@material-ui/core/styles';

const noOpComparator = () => 0;

const columns: AgGridColumnProps[] = [
  {
    field: 'companyId',
    headerName: 'Company Id',
    minWidth: 180,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    hide: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'lenderName',
    headerName: 'Lender Alias',
    minWidth: 260,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
    cellRenderer: 'lenderNameCellRenderer',
  },
  {
    field: 'lenderId',
    headerName: 'Lender Id',
    minWidth: 180,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    hide: false,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'tradeName',
    headerName: 'Lender Name',
    minWidth: 180,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    hide: false,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'status',
    headerName: 'Status',
    minWidth: 260,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'primaryRelationshipManagerEmail',
    headerName: 'Primary RM Email',
    minWidth: 260,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'secondaryRelationshipManagerEmail',
    headerName: 'Secondary RM Email',
    minWidth: 260,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
];

export const LendersWithNoRmGrid = observer(({ toolbar }: { toolbar: ReactNode }) => {
  const { salesforceLenderStore, companyProfileStore, globalStore, manageUsersStore, companyDropdownStore, manageUsersBackUpStore } = useStore();
  const [companyId, setCompanyId] = useState();
  useEffect(() => {
  }, [companyProfileStore.displayCompanyProfile]);

  const onSortChanged = params => {
    const sortModel = params.api.getSortModel();
    salesforceLenderStore.lenderWithNoRmGrid.setSortModel(sortModel);
  };

  const onFilterChanged = params => {
    const filterModel = params.api.getFilterModel();
    salesforceLenderStore.lenderWithNoRmGrid.setFilterModel(filterModel);
  };

  const lenderNameCellRenderer = params => {
    const lenderName = params.value;
    return (
      <Button onClick={() => handleOnclick(params)}>
        {lenderName}
      </Button>
    );
  }

  const handleOnclick = async (params) => {
    await companyDropdownStore.changeCompany(params.data?.companyId);
    companyProfileStore.setDisplayCompanyProfile(true);
  }

  const frameworkComponents = {
    lenderNameCellRenderer: lenderNameCellRenderer,
  };

  return (
    <Layout>
      {companyProfileStore.displayCompanyProfile ? (
        <>
          <CompanyProfileCRM
            companyProfileStore={companyProfileStore}
            globalStore={globalStore}
            manageUsersStore={manageUsersStore}
            manageUsersBackUpStore={manageUsersBackUpStore}
          />
        </>
      ) : (
        <DataGrid
          suppressRowClickSelection={false}
          frameworkComponents={frameworkComponents}
          toolbar={toolbar}
          columns={columns}
          rows={salesforceLenderStore.lenderWithNoRmGrid.gridData.data.rows}
          onSortChanged={onSortChanged}
          onFilterChanged={onFilterChanged}
          isLoading={salesforceLenderStore.lenderWithNoRmGrid.isLoading}
          showFilters={salesforceLenderStore.lenderWithNoRmGrid.showFilters}
          sortModel={salesforceLenderStore.lenderWithNoRmGrid.sortModel}
          filterModel={salesforceLenderStore.lenderWithNoRmGrid.filterModel}
          paginationData={salesforceLenderStore.lenderWithNoRmGrid.gridData.meta}
          setPageNumber={salesforceLenderStore.lenderWithNoRmGrid.setPageNumber}
          rowHeight={60}
        />
      )}
    </Layout>
  );
});
