import { CreateListStore } from "apps/crm-portal/src/app/modules/createList/stores/createListStore";
import { BaseLeadStore } from "./baseLeadStore/BaseLeadStore";
import { RingCentralStore } from "./ringCentralStore";

export class ExternalLeadStore extends BaseLeadStore {
  public ringCentralStore: RingCentralStore;
  public createListStore: CreateListStore;

  constructor(globalStore, newLeadStore, userStore) {
    super(globalStore, newLeadStore, userStore);
    this.ringCentralStore = new RingCentralStore(this.globalStore);
    this.createListStore = new CreateListStore(this.globalStore);
  }

}