import { Grid, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import { BorrowerEntityOwnershipStep } from './borrowerEntityOwnershipStep';
import { PercentageIcon } from './percentageIcon';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  borroInfoTitle: {
    fontSize: '34px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '123.5%',
    letterSpacing: '0.25px',
  },
  borrowerInfoSubTitle: {
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '133.4%',
  },
  borrowerInfoBody: {
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '175%',
    letterSpacing: '0.15px',
  },
}));

export const BorrowerEntityOwnershipInformation = observer(
  ({ store, hideTitle = false }) => {
    const classes = useStyles();
    return (
      <>
        <Grid container direction="row" spacing={2}>
          {!hideTitle && (
            <Grid item xs={12}>
              <Typography variant="h4" className={classes.borroInfoTitle}>
                Borrower Information
              </Typography>
            </Grid>
          )}
          <Grid
            item
            xs={12}
            container
            direction="row"
            spacing={2}
            alignItems="center"
          >
            <Grid item>
              <PercentageIcon />
            </Grid>
            <Grid item>
              <Typography variant="h5" className={classes.borrowerInfoSubTitle}>
                ENTITY OWNERSHIP
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5" className={classes.borrowerInfoBody}>
              Please assign ownership percentages to all borrowers.
            </Typography>
          </Grid>
        </Grid>
        <BorrowerEntityOwnershipStep store={store} />
      </>
    );
  }
);
