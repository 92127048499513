import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { MonetizationOn, Person, Room, TableChart } from '@material-ui/icons';
import { Layout } from '@roc/feature-app-core';
import { Paper, StepsContainer } from '@roc/ui';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { PricerFieldsConfig } from './components/rentalPricerFieldsContext';
import { useRentalPricerStore } from './hooks/useRentalPricerStore';
import { BorrowerInformationStep } from './pricerSteps/borrowerInformationStep';
import { PortfolioInformationStep } from './pricerSteps/portfolioInformationStep';
import { PropertyInformationStep } from './pricerSteps/propertyInformationStep';
import { PropertyValuationStep } from './pricerSteps/propertyValuationStep';
import RentalPricerSummary from './pricerSummary/rentalPricerSummary';
import {
  BORROWER_INFORMATION,
  PORTFOLIO_INFORMATION,
  PRICER_SUMMARY,
  PROPERTY_INFORMATION,
  PROPERTY_VALUATION
} from './stores/rentalPricerDetailsStore';

const useStyles = makeStyles(theme =>
  createStyles({
    paper: {
      padding: theme.spacing(3, 0, 4),
    },
    stepper: {
      maxWidth: 1200,
      margin: 'auto',
      [theme.breakpoints.down('xs')]: {
        '& .MuiStepLabel-label': {
          display: 'none',
        },
        '& .MuiStepLabel-label.MuiStepLabel-active': {
          display: 'block',
        },
      },
    },
  })
);

const PricerSteps = props => {
  const { isSingleProperty } = props;
  const step = props.steps[props.activeStep];
  if (!step) {
    return null;
  }

  if (isSingleProperty) {
    return (
      <StepsContainer {...props}>
        {step.id === PROPERTY_INFORMATION && <PropertyInformationStep />}
        {step.id === PROPERTY_VALUATION && <PropertyValuationStep />}
        {step.id === BORROWER_INFORMATION && <BorrowerInformationStep />}
        {step.id === PRICER_SUMMARY && <RentalPricerSummary />}
      </StepsContainer>
    );
  } else {
    return (
      <StepsContainer {...props}>
        {step.id === PROPERTY_INFORMATION && <PropertyInformationStep />}
        {step.id === PORTFOLIO_INFORMATION && <PortfolioInformationStep />}
        {step.id === PROPERTY_VALUATION && <PropertyValuationStep />}
        {step.id === BORROWER_INFORMATION && <BorrowerInformationStep />}
        {step.id === PRICER_SUMMARY && <RentalPricerSummary />}
      </StepsContainer>
    );
  }
};

export const RentalPricer = observer(({ isSingleProperty = false}) => {
  const { rentalPricerId } = useParams<{ rentalPricerId: string }>();
  const classes = useStyles();
  const { rentalPricerStore } = useRentalPricerStore();
  const { rentalPricerDetailsStore } = rentalPricerStore;
  const isPortfolio = !isSingleProperty;
  const store = rentalPricerDetailsStore.termStore;


  useEffect(() => {
    rentalPricerDetailsStore.initialize(isSingleProperty);
    if (rentalPricerId) {
      rentalPricerDetailsStore.fetchRentalPricerData(rentalPricerId);
    }
  }, [rentalPricerId]);

  const onNext = () => {
    moveToStep(stepIndex + 1, false);
  };

  const onBack = () => {
    moveToStep(stepIndex - 1, true);
  };

    const onSave = () => {
      const savedRentalPricer = rentalPricerDetailsStore.saveRentalPricer(isSingleProperty);
    };

  const moveToStep = (stepIndex, skipValidation = false) => {
    rentalPricerDetailsStore.moveToStep(steps[stepIndex].id, skipValidation);
  };

  const steps = [
    {
      id: PROPERTY_INFORMATION,
      title: 'Property Information',
      icon: <Room color="primary" fontSize="large" />,
      onBack: null,
      onNext,
      onSave,
      allowBack: false,
      allowNext: true,
      allowSave: true,
      nextButtonText: 'Continue',
      saveButtonText: 'Save Rental Quote',
    },
    isPortfolio && {
      id: PORTFOLIO_INFORMATION,
      title: 'Portfolio Information',
      icon: <MonetizationOn color="primary" fontSize="large" />,
      onBack,
      onNext,
      onSave,
      allowBack: true,
      allowNext: true,
      allowSave: true,
      nextButtonText: 'Continue',
      saveButtonText: 'Save Rental Quote',
    },
    {
      id: PROPERTY_VALUATION,
      title: 'Property Valuation',
      icon: <MonetizationOn color="primary" fontSize="large" />,
      onBack,
      onNext,
      onSave,
      allowBack: true,
      allowNext: true,
      allowSave: true,
      nextButtonText: 'Continue',
      saveButtonText: 'Save Rental Quote',
    },
    {
      id: BORROWER_INFORMATION,
      title: 'Borrower Information',
      icon: <Person color="primary" fontSize="large" />,
      onBack,
      onNext,
      onSave,
      allowBack: true,
      allowNext: true,
      allowSave: true,
      nextButtonText: 'Check Loan Rates',
      saveButtonText: 'Save Rental Quote',
    },
    {
      id: PRICER_SUMMARY,
      title: 'Loan Pricer Summary',
      icon: <TableChart color="primary" fontSize="large" />,
      onBack,
      onSave,
      onNext: null,
      allowBack: true,
      allowNext: false,
      allowSave: true,
      saveButtonText: 'Save Rental Quote',
    },
  ].filter(Boolean);

  const stepIndex = steps.findIndex(
    s => s.id === rentalPricerDetailsStore.currentStep
  );

  return (
    <Layout title="Rental Loan Pricer">
      <Paper className={classes.paper}>
        <PricerFieldsConfig xs={12} sm={12} md={6}>
          <PricerSteps
            isSingleProperty={isSingleProperty}
            activeStep={stepIndex}
            disableNavigation={false}
            steps={steps}
            handleStep={moveToStep}
            stepperClassName={classes.stepper}
          />
        </PricerFieldsConfig>
      </Paper>
    </Layout>
  );
});
