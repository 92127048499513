import { makeObservable, observable, flow, action, computed } from 'mobx';
import { GlobalStore } from '@roc/feature-app-core';
import { ResolutionStore } from 'libs/feature-borrower-review/src/stores/resolutionStore';
import { DocumentStore } from '@roc/feature-documents';
import { Task } from '@roc/feature-types';
import { BorrowerBaseService } from '@roc/feature-borrower-review';
import { BorrowerTaskService } from '../services/borrowerTaskService';
import { FileUpload } from '@roc/ui';
import { UNDERWRITING } from '@roc/feature-utils';


export class BorrowerTaskStore extends ResolutionStore {
  task: Task
  private borrowerTaskService: BorrowerTaskService;

  constructor(globalStore: GlobalStore, documentStore: DocumentStore) {
    super(globalStore, documentStore);
    this.borrowerTaskService = new BorrowerTaskService();

    makeObservable(this, {
      task: observable,
      getTaskByTaskId: flow,
      syncTaskResolution: flow,
      uploadTaskDocument: flow,
    });
  }

  *getTaskByTaskId(taskId: number) {
    try {
      const response = yield this.borrowerTaskService.getTaskById(taskId);
      this.task = response.data.data;
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'There was an error while retrieving task details',
      });
    }
  }

  *syncTaskResolution(taskId: number) {
    try {
      yield this.borrowerTaskService.syncTaskResolution(taskId, this.task.comment);
    } catch {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error saving data',
      });
    }
  }

  setTaskComment(comment: string) {
    this.task.comment = comment;
  }

  *uploadTaskDocument(fileUpload: FileUpload) {
    const task = this.task;
    const blob = fileUpload.file.slice(0, fileUpload.file.size, fileUpload.file.type);
    const newFile = new File([blob], fileUpload.name, { type: fileUpload.file.type });
    const formData = new FormData();
    formData.append('file', newFile);
    formData.append('task', JSON.stringify(task));
    yield this.resolutionService.uploadTaskFile(formData, this.internal);
  }
}