import { AnalyticsBrowser } from '@segment/analytics-next';

interface SegmentEvent {
  name: string;
  userName: string;
  [key: string]: any;
}

interface UserIdentify {
  userId: string,
  name: string,
  userName: string
}


const _SegmentAnalytics = new AnalyticsBrowser();

const initialize = () => {
  try {
    _SegmentAnalytics.load({
      writeKey: process.env.NX_SEGMENT_KEY,
    });
  } catch (e) {
    console.log('An error occurred when trying to initialize segment analytics', e);
  }
}

const identify = (data: UserIdentify) => {
  try {
    _SegmentAnalytics.identify(data.userId, {
      name: data.name,
      email: data.userName
    })
  } catch (e) {
    console.log('An error occurred when trying to initialize segment analytics', e);
  }
}

const trackEvent = (data: SegmentEvent) => {
  try {
    const { name, userName, ...rest } = data;
    _SegmentAnalytics.track(name, {
      userName,
      ...rest,
    });
  } catch (e) {
    console.log('An error occurred when trying to track event with segment analytics', e);
  }
}

const trackPage = () => {
  try {
    _SegmentAnalytics.page();
  } catch (e) {
    console.log('An error occurred when trying to track page with segment analytics', e);
  }
}

export const segmentAnalytics = {
  trackEvent,
  initialize,
  trackPage,
  identify
}