import { flow, makeObservable, override } from 'mobx';
import { GlobalStore } from '@roc/feature-app-core';
import { InternalInsuranceReviewBaseStore } from 'libs/feature-insurance-review/src/insurance-review/stores/internalInsuranceReviewBaseStore';
import { InsuranceReviewService } from '../services/InsuranceReviewService'
import { INSURANCE_REVIEW_EVENT_TYPE, InsuranceReviewEvents } from 'libs/feature-insurance-review/src/insurance-review/types/insuranceReviewTypes';
import { LogInternalService } from '../services/logInternalService';

export class InsuranceReviewStore extends InternalInsuranceReviewBaseStore {
  private insuranceReviewService: InsuranceReviewService;
  constructor(globalStore: GlobalStore) {
    super(globalStore);
    this.isInternal = false;
    this.insuranceReviewService = new InsuranceReviewService();
    this.logService = new LogInternalService();
    makeObservable(this, {
      fetchInsuranceData: flow,
      approveOrRejectFields: flow,
      updateComment: flow,
      saveInsuranceData: flow,
      handleItemChange: flow,
      removeFromPendingTasks: flow,
      moveToPending: flow
    });
  }

  *fetchInsuranceData(loanId: string) {
    try {
      const response = yield this.insuranceReviewService.getInsuranceData(parseInt(loanId));
      this.properties = response.data.data;
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: `An error occurred while retrieving Insurance Data for loan ${loanId}.`,
      });
    }
  }

  *approveOrRejectFields(propertyInsuranceData: any[]) {
    try {
      yield this.insuranceReviewService.updateInsuranceItems(this.properties);
      yield this.insuranceReviewService.approveOrRejectItems(propertyInsuranceData.map(item => item.insuranceReviewDataId).filter(item => this.selectedItems.includes(item)), this.selectedActionApprove, this.loanId);
      this.globalStore.notificationStore.showSuccessNotification({ message: 'Property insurance data updated successfully' });
      this.selectedItems = [];
      this.fetchInsuranceData(this.loanId);
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: `An error occurred while updating Insurance Data for loan ${this.loanId}.`,
      });
    }
  }

  *saveInsuranceData() {
    try {
      yield this.insuranceReviewService.updateInsuranceItems(this.properties);
      this.globalStore.notificationStore.showSuccessNotification({ message: 'Property insurance data updated successfully' });
      this.fetchInsuranceData(this.loanId);
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: `An error occurred while updating Insurance Data for loan ${this.loanId}.`,
      });
    }
  }

  *updateComment(selectedRecord: any) {
    try {
      this.insuranceReviewService.updateInsuranceItem(selectedRecord);
      this.globalStore.notificationStore.showSuccessNotification({ message: 'Property insurance data updated successfully' });
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: `An error occurred while updating Insurance Data for loan ${this.loanId}.`,
      });
    }
  }

  // eslint-disable-next-line require-yield
  *handleItemChange(loanId: string, propertyId: string, itemName: string, section: string, actionPerformed: string) {
    this.logService.logEvent({
      loanId: loanId,
      propertyId: propertyId,
      reviewType: INSURANCE_REVIEW_EVENT_TYPE,
      actionPerformed: actionPerformed,
      sectionName: section,
      itemName: itemName,
    });

  }

  *removeFromPendingTasks(loanId: number, callback = () => { }) {
    yield this.insuranceReviewService.removeFromPendingTasks(loanId)
      .then(() => {
        this.globalStore.notificationStore.showSuccessNotification({ message: 'Loan marked as not pending' });
        callback();
      })
      .catch(error => this.globalStore.notificationStore.showErrorNotification({
        message: `An error occurred while marking the loan as not pending`,
      }));
  }

  *moveToPending(loanId: number, callback = () => { }) {
    yield this.insuranceReviewService.moveToPending(loanId)
      .then(() => {
        this.globalStore.notificationStore.showSuccessNotification({ message: 'Loan marked as pending' });
        callback();
      })
      .catch(error => this.globalStore.notificationStore.showErrorNotification({
        message: `An error occurred while marking the loan as pending`,
      }));
  }
}
