import { Theme } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';

export const appraisalTableStyles = makeStyles((theme: Theme) => ({
  tableContainer: {
    borderRadius: '10px',
    border: '1px #e0e0e0 solid',
    overflow: 'hidden',
  },
  table: {
    width: '100%',
    '& td': {
      height: '40px',
      padding: 0,
      paddingTop: '8px',
      paddingBottom: '8px',
      border: '1px solid #e0e0e0',
      width: '350px',
      maxWidth: '400px'
    },
    '& th:first-child, td[rowSpan], $addNewItemRow td': {
      paddingLeft: theme.spacing(2),
      paddingRight: '3px',
    },
    '& table tr:last-child td, & table td[rowSpan]': {
      borderBottom: 'none',
    },
  },
  titleCell: {
    height: '46px',
    paddingTop: 10,
    paddingLeft: 16,
    overflow: 'hidden',
    backgroundColor: '#E2F6FF',
    color: '#757575',
    fontSize: 20,
    fontWeight: 400
  },
  title: {
    color: '#757575',
    fontSize: 20
  },
  headerCell: {
    height: '30px',
    fontWeight: 'bold',
    color: '#757575',
    padding: 0,
    backgroundColor: '#F0FAFF',
    textAlign: 'center',
    border: '1px solid #e0e0e0',
    paddingLeft: '8px',
  },
  bodyRow: {
    position: 'relative',
    textAlign: 'center',
  },
  fixedWidthText: {
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'left',
    paddingLeft: '10px',
    width: '100%'
  }
}));

export enum AppraisalReviewType {
  BRIDGE,
  TERM,
}
