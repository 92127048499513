import { makeObservable, override } from 'mobx';
import { EscrowDepositCalculatorService } from '../services/escrowDepositCalculatorService';
import { EscrowDepositCalculatorBaseStore } from '@roc/feature-escrow-deposit-calculator';

export class EscrowDepositCalculatorStore extends EscrowDepositCalculatorBaseStore {
  private escrowDepositCalculatorService: EscrowDepositCalculatorService;

  constructor(globalStore) {
    super(globalStore);
    this.escrowDepositCalculatorService = new EscrowDepositCalculatorService();

    makeObservable(this, {
      getAutocompleteOptions: override,
      getPaymentValuesForState: override,
      getLoanValues: override,
      calculateNewNumberOfPayments: override,
      calculateFirstPaymenDuetDate: override
    });
  }

  *getPaymentValuesForState(state) {
    const response = yield this.escrowDepositCalculatorService.getPaymentValuesForState(state);
    if (response.data) {
      this.form.fields.firstPaymentDueDate.value = response.data.nextPaymentDate;
      this.form.fields.numberOfMonthsPaymentCovers.value = response.data.period;

    }
  }

  *getLoanValues(loanId: string) {
    const response = yield this.escrowDepositCalculatorService.getLoanValues(loanId);
    return response.data;
  }

  *updateLoan(loanId: string, taxEscrowMonths: string, loanDocumentId: string, callback?: () => void) {
    try {
      if (!this.checkForValidations()) {
        const response = yield this.escrowDepositCalculatorService.updateLoan(loanId, Number(taxEscrowMonths), loanDocumentId);
        if (response.data.success === false) {
          this.globalStore.notificationStore.showErrorNotification({
            message: response.data.responseMessage,
          });
          window.parent.postMessage(response.data.responseMessage, '*');
        } else {
          this.globalStore.notificationStore.showSuccessNotification({
            message: 'Tax Escrow Months have been updated successfully',
          });
          this.setTaxEscrowMonths(taxEscrowMonths);
          window.parent.postMessage('tax-escrow-months-updated-succefully', '*');
        }
        if (callback) {
          callback();
        }
      }
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'An error occurred while updating the loan.',
      });
      window.parent.postMessage('error-updating-tax-escrow-months', '*');
    }
  }

  *getAutocompleteOptions(searchTerm: string) {
    const filters = { dealName: searchTerm };
    const response = yield this.escrowDepositCalculatorService.getRentalDealNames(
      1,
      25,
      null,
      null,
      filters
    );
    const rows = response.data.rows;
    return rows.map(row => ({
      label: row.dealName,
      value: row.dealid,
    }));
  }

  *calculateNewNumberOfPayments(firstPaymentDueDate, nextTaxPaymentDue) {
    const response = yield this.escrowDepositCalculatorService.calculateNewNumberOfPayments(firstPaymentDueDate, nextTaxPaymentDue);
    return response.data;
  }

  *calculateFirstPaymenDuetDate(expectedClosingDate) {
    const response = yield this.escrowDepositCalculatorService.calculateFirstPaymentDueDate(expectedClosingDate);
    return response.data;
  }

}
