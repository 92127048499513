import { Route } from 'react-router';
import { Page } from '@roc/feature-app-core';
import { ExpectedClosings } from '../expectedClosings';

export const getExpectedClosingsRoutesConfig = (
  basePath: string,
  baseUrl: string,
) => {
  return {
    expectedClosings: {
      path: `${basePath}/expected-closings`,
      url: `${baseUrl}/expected-closings`,
      documentTitle: 'Expected Closings',
    },
  };
};

export const getExpectedClosingsRoutes = (
  basePath: string,
  baseUrl: string,
) => {
  const config = getExpectedClosingsRoutesConfig(basePath, baseUrl);
  return {
    expectedClosings: (
      <Route exact path={config.expectedClosings.path}>
        <Page routeDefinition={config.expectedClosings}>
          <ExpectedClosings />
        </Page>
      </Route>
    ),
  };
};
