import {
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Cancel, MoreVert } from '@material-ui/icons';
import { useLoanRoutes } from '@roc/feature-loans-routes-config';
import { useLoanStore } from '@roc/feature-loans-shared';
import { formatDate } from '@roc/feature-utils';
import {
  AgGridColumnProps,
  ConfirmDialog,
  DataGrid as DrawGrid,
  FieldLabel,
  LinkColumn,
  MenuItem,
  Paper,
  StatusChip,
  StatusType,
} from '@roc/ui';
import { formatCurrency } from '@roc/ui/utils';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { LoanKeyDatesNew } from '../../common/loanKeyDates';
import { DRAW_INIT_STAGE, DRAW_REQUEST_STAGE } from '../../constants/draws';
import { useDrawsStore } from '../../hooks';
import { LoanValuesCard } from './loanValuesCard';

const noOpComparator = () => 0;

const columns: AgGridColumnProps[] = [
  {
    field: 'drawNumber',
    headerName: 'Draw #',
    minWidth: 100,
    maxWidth: 100,
    cellRenderer: 'drawNumberCellRenderer',
    comparator: noOpComparator,
    sortable: false,
  },
  {
    field: 'stage',
    headerName: 'Status',
    minWidth: 220,
    maxWidth: 240,
    comparator: noOpComparator,
    sortable: false,
    cellRenderer: 'statusCellRenderer',
  },
  {
    field: 'creationDate',
    headerName: 'Draw Submission Date',
    minWidth: 100,
    cellRenderer: 'dateCellRenderer',
    comparator: noOpComparator,
    sortable: false,
  },
  {
    field: 'amount',
    headerName: 'Amount Requested',
    minWidth: 100,
    cellRenderer: 'amountRenderer',
    comparator: noOpComparator,
    sortable: false,

  },
  {
    field: 'wireSentDate',
    headerName: 'Wire Sent Date',
    minWidth: 100,
    cellRenderer: 'wireSentDateRenderer',
    comparator: noOpComparator,
    sortable: false,
  },
  {
    field: 'wireAmount',
    headerName: 'Wire Amount',
    minWidth: 100,
    cellRenderer: 'wireAmountRenderer',
    comparator: noOpComparator,
    sortable: false,
  },
  {
    field: 'actions',
    headerName: 'Actions',
    maxWidth: 100,
    cellRenderer: 'actionsCellRenderer',
    comparator: noOpComparator,
    sortable: false,
  },
];

const useStyles = makeStyles(theme => ({
  paper: {
    padding: 24,
  },
  gridContainer: {
    paddingTop: 24,
  },
  cardSeparator: {
    marginTop: 24,
  }
}));

export const LoanDrawsGrid = observer(
  ({ loanId, drawList }) => {
    const {
      drawStore,
    } = useDrawsStore();
    const { loanRoutesConfig } = useLoanRoutes();
    const [anchorEl, setAnchorEl] = useState<HTMLElement>(null);
    const menuOpen = Boolean(anchorEl);
    const [drawId, setDrawId] = useState(null);
    const [isConfirmOpen, setIsConfirmOpen] = useState(false);

    const frameworkComponents = {
      dateCellRenderer: ({ value }) => {
        return value ? formatDate(value) : '';
      },
      drawNumberCellRenderer: params => {
        const drawId = params.node.data?.drawId;
        const { onClick } = params;
        const value = `Draw ${params.value}`;
        return LinkColumn({
          value,
          onClick,
          url: loanRoutesConfig
            .loans(params.node.data?.loanId)
            .children.drawDetails(drawId).url,
        });
      },
      amountRenderer: ({ value }) => {
        return value ? formatCurrency(value) : '';
      },
      wireAmountRenderer: ({ value }) => {
        return value && value > 0 ? formatCurrency(value) : '';
      },
      wireSentDateRenderer: params => {
        return (params.node.data?.wireAmount > 0 ? formatDate(params.node.data?.wireSentDate) : '');
      },
      statusCellRenderer: ({ value }) =>
        value ? (
          <StatusChip statusType={StatusType.DRAWS} label={value} size='small' variant={value == 'Wire Sent/Draw Resolved' ? 'filled' : 'outlined'} />
        ) : null,
      actionsCellRenderer: params => {
        if (params.node.data?.stage !== DRAW_REQUEST_STAGE && params.node.data?.stage !== DRAW_INIT_STAGE) {
          return (
            <IconButton disabled>
              <MoreVert color="disabled" />
            </IconButton>
          );
        } else {
          return (
            <IconButton onClick={e => openMenu(e, params.node.data.drawId)}>
              <MoreVert color="primary" />
            </IconButton>
          );
        }
      },
    };

    const openMenu = (event: React.MouseEvent<HTMLElement>, record) => {
      setDrawId(record);
      setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
      setDrawId(null);
      setAnchorEl(null);
    };

    const cancelRequest = () => {
      drawStore.cancelDrawRequest(drawId, loanId);
      setIsConfirmOpen(false);
      handleMenuClose();
    };

    return (
      drawList?.length ?
        <>
          <DrawGrid
            columns={columns}
            rows={drawList}
            frameworkComponents={frameworkComponents}
            domLayout="autoHeight"
          />
          <Menu
            id="menu-appbar"
            getContentAnchorEl={null}
            anchorEl={anchorEl}
            keepMounted
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            open={menuOpen}
            onClose={handleMenuClose}
          >
            <div>
              <MenuItem
                testId={`cancel-draw-request-${drawId}`}
                onClick={() => setIsConfirmOpen(true)}
              >
                <ListItemIcon>
                  <Cancel fontSize="small" />
                </ListItemIcon>
                <ListItemText>Cancel Request</ListItemText>
              </MenuItem>
            </div>
          </Menu>
          <ConfirmDialog
            open={isConfirmOpen}
            title={`Cancel Draw Request`}
            body={`Are you sure you want to cancel the draw request?\n\nThis Operation cannot be reverted.`}
            handleCancel={() => setIsConfirmOpen(false)}
            handleClose={() => setIsConfirmOpen(false)}
            handleConfirm={cancelRequest}
            confirmButtonText={'Confirm'}
          />
        </> : null
    );
  }
);
