import { DialogContentClassKey } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/styles';
import { Button } from '@roc/ui';
import React, { ReactNode, useState } from 'react';
import { StandardDialog } from './dialog';

export interface ConfirmDialogProps {
  dialogContentclasses?: Partial<ClassNameMap<DialogContentClassKey>>;
  className?: string;
  open: boolean;
  title?: string;
  body: string | ReactNode;
  confirmButtonText?: string;
  cancelButtonText?: string;
  removeContentBox?: boolean;
  handleClose: React.ReactEventHandler<{}>;
  handleCancel: React.MouseEventHandler<HTMLButtonElement>;
  handleConfirm: React.MouseEventHandler<HTMLButtonElement>;
}

export interface InformationDialogProps {
  dialogContentclasses?: Partial<ClassNameMap<DialogContentClassKey>>;
  className?: string;
  open: boolean;
  title?: string;
  body: string | ReactNode;
  closeButtonText?: string;
  handleClose: React.ReactEventHandler<{}>;
}


export const ConfirmDialog = ({
  dialogContentclasses,
  className,
  open,
  title = 'Confirmation',
  body,
  confirmButtonText = 'Confirm',
  cancelButtonText = 'Cancel',
  removeContentBox,
  handleClose,
  handleCancel,
  handleConfirm,
}: ConfirmDialogProps) => {
  return (
    <StandardDialog
      dialogContentclasses={dialogContentclasses}
      className={className}
      open={open}
      title={title}
      maxWidth={'sm'}
      handleClose={handleClose}
      dialogContent={body}
      removeContentBox={removeContentBox}
      dialogActions={
        <>
          <Button
            style={{ marginRight: '8px' }}
            onClick={handleCancel}
            color="primary"
            variant="outlined"
            testId="cancel"
          >
            {cancelButtonText}
          </Button>
          <Button
            onClick={handleConfirm}
            color="primary"
            variant="contained"
            testId="confirm"
          >
            {confirmButtonText}
          </Button>
        </>
      }
    />
  );
};

export interface WithConfirmDialogProps {
  onClick: (event: React.MouseEventHandler<HTMLElement>) => void;
  confirmDialogProps: {
    dialogContentclasses?: Partial<ClassNameMap<DialogContentClassKey>>;
    className?: string;
    title?: string;
    body: string | ReactNode;
    confirmButtonText?: string;
    cancelButtonText?: string;
    removeContentBox?: boolean;
  };
}

export interface WithInformationDialogProps {
  onClick: (event: React.MouseEventHandler<HTMLElement>) => void;
  informationDialogProps: {
    dialogContentclasses?: Partial<ClassNameMap<DialogContentClassKey>>;
    className?: string;
    title?: string;
    body: string | ReactNode;
    closeButtonText?: string;
  };
}

export const withConfirmDialog = <P extends object>(
  Component: React.ComponentType<P>
): React.FC<P & WithConfirmDialogProps> => ({
  onClick,
  confirmDialogProps,
  ...props
}: WithConfirmDialogProps) => {
    const {
      title,
      body,
      confirmButtonText,
      cancelButtonText,
      dialogContentclasses,
      removeContentBox,
    } = confirmDialogProps;
    const [open, setOpen] = useState(false);
    const [targetButtonEvent, setTargetButtonEvent] = useState(null);

    const onTargetButtonClick = (
      event: React.MouseEventHandler<HTMLButtonElement>
    ) => {
      setTargetButtonEvent(event);
      setOpen(true);
    };

    return (
      <>
        <ConfirmDialog
          dialogContentclasses={dialogContentclasses}
          open={open}
          title={title}
          body={body}
          confirmButtonText={confirmButtonText}
          cancelButtonText={cancelButtonText}
          removeContentBox={removeContentBox}
          handleCancel={() => setOpen(false)}
          handleClose={() => setOpen(false)}
          handleConfirm={() => {
            setOpen(false);
            onClick(targetButtonEvent);
          }}
        />
        <Component onClick={onTargetButtonClick} {...(props as P)} />
      </>
    );
  };

export const InformationDialog = ({
  dialogContentclasses,
  className,
  open,
  title = 'Information',
  body,
  closeButtonText = 'Close',
  handleClose
}: InformationDialogProps) => {
  return (
    <StandardDialog
      dialogContentclasses={dialogContentclasses}
      className={className}
      open={open}
      fullWidth
      title={title}
      handleClose={handleClose}
      dialogContent={body}
      dialogActions={
        <>
          <Button
            style={{ marginRight: '8px' }}
            onClick={handleClose}
            color="primary"
            variant="outlined"
            testId="cancel"
          >
            {closeButtonText}
          </Button>
        </>
      }
    />
  );
};

export const withInformationDialog = <P extends object>(
  Component: React.ComponentType<P>
): React.FC<P & WithInformationDialogProps> => ({
  onClick,
  informationDialogProps,
  ...props
}: WithInformationDialogProps) => {
    const {
      title,
      body,
      closeButtonText,
      dialogContentclasses,
    } = informationDialogProps;
    const [open, setOpen] = useState(false);
    const [targetButtonEvent, setTargetButtonEvent] = useState(null);

    const onTargetButtonClick = (
      event: React.MouseEventHandler<HTMLButtonElement>
    ) => {
      setTargetButtonEvent(event);
      setOpen(true);
    };

    return (
      <>
        <InformationDialog
          dialogContentclasses={dialogContentclasses}
          open={open}
          title={title}
          body={body}
          closeButtonText={closeButtonText}
          handleClose={() => setOpen(false)}
        />
        <Component onClick={onTargetButtonClick} {...(props as P)} />
      </>
    );
  };
