import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Grid, Typography, Button, Card, CardContent, Box } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import { UnsubscribeService } from '../../services/unsubscribeService';
import { LoginLogoImg } from '@roc/feature-app-core';
import { CheckCircle } from '@material-ui/icons';
import { green } from '@material-ui/core/colors';

const useStyles = makeStyles((theme: Theme) => ({
  gridContainer: {
    padding: theme.spacing(4),
    width: '100%',
    maxWidth: 650,
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0),
    },
  },
  card: {
    width: '100%',
    maxWidth: 650,
    margin: 'auto',
  },
  message: {
    padding: theme.spacing(4, 2),
    textAlign: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  text: {
    padding: theme.spacing(4),
    lineHeight: '2',
    margin: '20px 0',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(4, 0)
    }
  },
  buttonContainer: {
    marginBottom: '20px',
  }
}));

export const Unsubscribe = observer(() => {
  const classes = useStyles();
  const { token } = useParams<{ token: string }>();
  const [message, setMessage] = useState('Processing your unsubscription request, do not close this window yet...');
  const [requestComplete, setRequestComplete] = useState(false);
  const unsubscribeService = new UnsubscribeService();
  const handleUnsubscribe = async () => {
    try {
      const response = await unsubscribeService.unsubscribe(token);
      setMessage(response.data.message || "You have been successfully unsubscribed from these emails.");
    } catch (error) {
      setMessage("There was an error processing your request. Please try again or contact support.");
    } finally {
      setRequestComplete(true);
    }
  };
  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      className={classes.gridContainer}
    >
      <Grid item md={12} lg={12}>
        <Card className={classes.card}>
          <CardContent className={classes.message}>
            <LoginLogoImg imgStyle={{ maxWidth: 200 }} />
            {!requestComplete ? (
              <>
                <Typography className={classes.text}>
                  You are about to unsubscribe from our emails. <br />
                  Are you sure you want to proceed?
                </Typography>
                <Grid item md={12} lg={12} className={classes.buttonContainer}>
                  <Button variant="contained" color="primary" onClick={handleUnsubscribe}>
                    Unsubscribe
                  </Button>
                </Grid>

              </>
            ) : (message &&
              (
                <>
                  <Grid item md={12} lg={12}>
                    <CheckCircle style={{ fontSize: 60, color: green[500] }} />
                    <br />
                    <Typography variant="h4" color="textPrimary">
                      Success
                    </Typography>
                  </Grid>
                  <Typography className={classes.text}>
                    <>
                      {message}<br />
                      You can now close this window.
                    </>
                  </Typography>
                </>
              )
            )}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
});