import { Box, Grid } from '@material-ui/core';
import { PropertyAddressDetails } from '../../../components/temp/propertyFormComponentsTemp';
import { FormStore } from '@roc/feature-app-core';
import { exitStrategies, isNotBlank, LoanSubType } from '@roc/feature-utils';
import {
  AddressDetailsField,
  FieldLabel,
  NumberFormat,
  RadioField,
  SelectField,
  TextField,
  trueFalseOptions,
} from '@roc/ui';
import {
  createCurrencyField,
  createDateField,
  createRadioField,
  createSelectField,
  createTextField,
} from '../../../components/temp/componentBuiderTemp';
import { observer } from 'mobx-react';
import { PropertyFormStore } from '../../../stores/properties/propertyFormStore';
import {
  propertyOwnershipOptions,
  propertySourceOptions,
  propertyTypeOptions,
  propertyTypeOptionsFnFGU,
  SquareFootageExpansion,
  squareFootageExpansionOptions,
} from '../../../utils/constants';
import { PropertyAddressMessages } from './propertyAddressMessages';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const YesNoField = observer(
  ({
    store,
    fieldName,
    label,
    required
  }: {
    store: FormStore;
    fieldName: string;
    label: string;
    required?: boolean
  }) => {
    const field = store.form.fields[fieldName];
    return (
      <RadioField
        label={label}
        value={field.value}
        options={trueFalseOptions}
        onChange={value => store.onFieldChange(fieldName, value === 'true')}
        fullWidth
        row={true}
        required={required}
        testId={fieldName}
        standaloneLabel={true}
        error={!store.form.meta.isValid && isNotBlank(field.error)}
        errorText={!store.form.meta.isValid && field.error}
      />
    );
  }
);

export const PropertyAddress = observer(
  ({ store }: { store: PropertyFormStore }) => {
    return (
      <>
        <PropertyAddressDetails
          testId={`${store.key}-addressComponent`}
          store={store}
          showMap={false}
          standaloneLabel={false}
          autoFocus
          addMarkForRequiredFields={true}
        />
        <PropertyAddressMessages store={store} />
      </>
    );
  }
);

export const PropertyOwnership = observer(({ store }: { store: FormStore }) => {
  return (
    <>
      {createRadioField({
        label: 'Is the property owned by the borrower(s)?',
        fieldName: 'loanPurpose',
        testId: 'loanPurpose',
        store,
        options: propertyOwnershipOptions,
        row: true,
        standaloneLabel: false,
        addMarkForRequiredFields: true,
        isRequired: true
      })}
    </>
  );
});

export const SubdividedIntoLots = observer(
  ({ store }: { store: FormStore }) => {
    return (
      <>
        {createRadioField({
          label: 'Will the property be subdivided into lots?',
          fieldName: 'subdividedIntoLots',
          testId: 'subdividedIntoLots',
          store,
          options: trueFalseOptions,
          row: true,
          standaloneLabel: false,
          addMarkForRequiredFields: true,
          isRequired: true
        })}
      </>
    );
  }
);

export const NumberOfLots = observer(({ store, ...rest }) => {
  const formFields = store.form.fields;
  return (
    <NumberFormat
      required
      customInput={TextField}
      label="Number of Lots"
      testId="numberOfLots"
      variant="outlined"
      mask="_"
      fullWidth
      value={formFields.numberOfLots.value}
      onChange={e => store.onFieldChange('numberOfLots', e.target.value)}
      error={isNotBlank(formFields.numberOfLots.error)}
      helperText={formFields.numberOfLots.error}
    />
  );
});

export const SellerCredit = observer(({ store }: { store: FormStore }) => {
  return (
    <>
      {createRadioField({
        label: 'Is there seller credit?',
        fieldName: 'sellerCredit',
        testId: 'sellerCredit',
        store,
        options: trueFalseOptions,
        row: true,
        standaloneLabel: false,
        addMarkForRequiredFields: true,
        isRequired: true
      })}
    </>
  );
});

export const SellerCreditAmount = observer(
  ({ store }: { store: FormStore }) => {
    return (
      <>
        {createCurrencyField({
          label: 'How much is the seller credit?',
          fieldName: 'sellerCreditAmount',
          testId: 'sellerCreditAmount',
          store,
          standaloneLabel: false,
          addMarkForRequiredFields: true,
          isRequired: true
        })}
      </>
    );
  }
);

export const ArePermitsInPlace = observer(({ store }: { store: FormStore }) => {
  return (
    <>
      {createRadioField({
        label: 'Are all permits in place?',
        fieldName: 'permitsInPlace',
        testId: 'permitsInPlace',
        store,
        options: trueFalseOptions,
        row: true,
        standaloneLabel: false,
        addMarkForRequiredFields: true,
        isRequired: true
      })}
    </>
  );
});


export const BorrowerKnowOwnerOfRecord = observer(
  ({ store }: { store: FormStore }) => {
    return (
      <>
        {createRadioField({
          label: 'Does the borrower know the owner of record?',
          fieldName: 'borrowerKnowOwnerOfRecord',
          testId: 'borrowerKnowOwnerOfRecord',
          store,
          options: trueFalseOptions,
          row: true,
          standaloneLabel: false,
          addMarkForRequiredFields: true,
          isRequired: true
        })}
      </>
    );
  }
);

export const ArmsLengthComment = observer(
  ({ store }: { store: FormStore }) => {
    return (
      <>
        {createTextField({
          label: 'Please specify',
          fieldName: 'armsLengthComment',
          testId: 'armsLengthComment',
          store,
          standaloneLabel: false,
          addMarkForRequiredFields: true,
          isRequired: true
        })}
      </>
    );
  }
);


export const WholesalerPropertyContractAmount = observer(
  ({ store }: { store: FormStore }) => {
    return (
      <>
        {createCurrencyField({
          label:
            'How much does the wholesaler have the property under contract for?',
          fieldName: 'wholesalerPropertyContractAmount',
          testId: 'wholesalerPropertyContractAmount',
          store,
          standaloneLabel: false,
          addMarkForRequiredFields: false,
        })}
      </>
    );
  }
);

export const PropertySourcingExplanation = observer(
  ({ store }: { store: FormStore }) => {
    return (
      <>
        {createTextField({
          label: 'Please specify the property sourcing',
          fieldName: 'propertySourcingExplanation',
          testId: 'propertySourcingExplanation',
          store,
          standaloneLabel: false,
        })}
      </>
    );
  }
);

export const PropertySourcing = observer(({ store }: { store: FormStore }) => {
  return (
    <>
      {createSelectField({
        store,
        testId: 'propertySourcing',
        fieldName: 'propertySourcing',
        label: 'How is the property being sourced?',
        options: propertySourceOptions,
        standaloneLabel: false,
        addMarkForRequiredFields: true,
        isRequired: true
      })}
    </>
  );
});

export const PurchaseDate = observer(({ store }: { store: FormStore }) => {
  return (
    <>
      {createDateField({
        label: 'When did you purchase this property?',
        fieldName: 'purchaseDate',
        testId: 'purchaseDate',
        store,
        format: 'MM/dd/yyyy',
        disableFuture: true,
        standaloneLabel: false,
        addMarkForRequiredFields: true,
        isRequired: true
      })}
    </>
  );
});

export const OutstandingLoanOnTheProperty = observer(
  ({ store }: { store: FormStore }) => {
    return (
      <>
        {createRadioField({
          label: 'Is there an outstanding loan on the property?',
          fieldName: 'outstandingLoanOnTheProperty',
          testId: 'outstandingLoanOnTheProperty',
          store,
          options: trueFalseOptions,
          row: true,
          standaloneLabel: false,
          addMarkForRequiredFields: true,
          isRequired: true
        })}
      </>
    );
  }
);

export const PayoffAmount = observer(({ store }: { store: FormStore }) => {
  return (
    <>
      {createCurrencyField({
        label: 'What is the payoff amount?',
        fieldName: 'payoffAmount',
        testId: 'payoffAmount',
        store,
        standaloneLabel: false,
        addMarkForRequiredFields: true,
        isRequired: true
      })}
    </>
  );
});

export const LoanEverBeenInDefault = observer(
  ({ store }: { store: FormStore }) => {
    return (
      <>
        {createRadioField({
          label: 'Has the loan ever been in default?',
          fieldName: 'loanEverBeenInDefault',
          testId: 'loanEverBeenInDefault',
          store,
          options: trueFalseOptions,
          row: true,
          standaloneLabel: false,
          addMarkForRequiredFields: true,
          isRequired: true
        })}
      </>
    );
  }
);

export const HasBeenRecentlyRenovated = observer(
  ({ store }: { store: FormStore }) => {
    return (
      <>
        {createRadioField({
          label: 'Has the property been recently renovated?',
          fieldName: 'hasBeenRecentlyRenovated',
          testId: 'hasBeenRecentlyRenovated',
          store,
          options: trueFalseOptions,
          row: true,
          standaloneLabel: false,
          addMarkForRequiredFields: true,
          isRequired: true
        })}
      </>
    );
  }
);

export const SoonToBeListedForSale = observer(
  ({ store }: { store: FormStore }) => {
    return (
      <>
        {createRadioField({
          label: 'Is the property soon to be listed for sale?',
          fieldName: 'soonToBeListedForSale',
          testId: 'soonToBeListedForSale',
          store,
          options: trueFalseOptions,
          row: true,
          standaloneLabel: false,
          addMarkForRequiredFields: true,
          isRequired: true
        })}
      </>
    );
  }
);

export const CurrentlyOccupied = observer(({ store }: { store: FormStore }) => {
  return (
    <>
      {createRadioField({
        label: 'Is the property currently occupied?',
        fieldName: 'currentlyOccupied',
        testId: 'currentlyOccupied',
        store,
        options: trueFalseOptions,
        row: true,
        standaloneLabel: false,
        addMarkForRequiredFields: true,
        isRequired: true
      })}
    </>
  );
});

export const WhatWasThePurchasePrice = observer(
  ({ store }: { store: FormStore }) => {
    return (
      <>
        {createCurrencyField({
          label: 'What was the Purchase Price?',
          fieldName: 'purchasePrice',
          testId: 'purchasePrice',
          store,
          standaloneLabel: false,
          addMarkForRequiredFields: true,
          isRequired: true
        })}
      </>
    );
  }
);

export const PurchasePrice = observer(({ store }: { store: FormStore }) => {
  return (
    <>
      {createCurrencyField({
        label: 'Purchase Price',
        fieldName: 'purchasePrice',
        testId: 'purchasePrice',
        store,
        standaloneLabel: false,
        addMarkForRequiredFields: true,
        isRequired: true
      })}
    </>
  );
});

export const CurrentMarketValue = observer(
  ({ store }: { store: FormStore }) => {
    return (
      <>
        {createCurrencyField({
          label: 'Current Market Value',
          fieldName: 'currentMarketValue',
          testId: 'currentMarketValue',
          store,
          standaloneLabel: false,
          addMarkForRequiredFields: true,
          isRequired: true
        })}
      </>
    );
  }
);

export const MarketValueAfterCompletion = observer(
  ({ store }: { store: FormStore }) => {
    return (
      <>
        {createCurrencyField({
          label: 'Market Value After Completion',
          fieldName: 'marketValueAfterCompletion',
          testId: 'marketValueAfterCompletion',
          store,
          standaloneLabel: false,
          addMarkForRequiredFields: true,
          isRequired: true
        })}
      </>
    );
  }
);

export const ConstructionRenoSpendToDate = observer(
  ({ store }: { store: FormStore }) => {
    return (
      <>
        {createCurrencyField({
          label: 'Construction/Reno Spent to Date',
          fieldName: 'constructionRenoSpendToDate',
          testId: 'constructionRenoSpendToDate',
          store,
          helperText: '*Excluding any property taxes or holding costs.',
          standaloneLabel: false,
          addMarkForRequiredFields: true,
          isRequired: true
        })}
      </>
    );
  }
);

export const ProposedConstructionBudget = observer(
  ({ store }: { store: FormStore }) => {
    return (
      <>
        {createCurrencyField({
          label: 'Proposed Construction Budget',
          fieldName: 'renovationBudget',
          testId: 'renovationBudget',
          store,
          standaloneLabel: false,
          addMarkForRequiredFields: true,
          isRequired: true
        })}
      </>
    );
  }
);

export const ProposedAdditionalConstructionBudget = observer(
  ({ store }: { store: FormStore }) => {
    return (
      <>
        {createCurrencyField({
          label: 'Proposed Additional Construction Budget',
          fieldName: 'renovationBudget',
          testId: 'renovationBudget',
          store,
          standaloneLabel: false,
          addMarkForRequiredFields: true,
          isRequired: true
        })}
      </>
    );
  }
);

export const useSquareFootageStyles = makeStyles(theme => ({
  field: {
    '& .MuiInputBase-input': {
      textAlign: 'left',
    },
  },
}));

export const WillTheSquareFootageBeExpanded = observer(
  ({ store }: { store: FormStore }) => {
    const classes = useSquareFootageStyles();
    return (
      <Box className={clsx(classes.field)}>
        {createSelectField({
          store,
          testId: 'squareFootageExpansion',
          fieldName: 'squareFootageExpansion',
          label: 'Will the square footage be expanded?',
          options: squareFootageExpansionOptions,
          standaloneLabel: false,
          addMarkForRequiredFields: true,
          isRequired: true
        })}
      </Box>
    );
  }
);

export const PropertyType = observer(({ store, options }: { store: FormStore, options: any[] }) => {
  return (
    <>
      {createSelectField({
        store,
        testId: 'propertyType',
        fieldName: 'propertyType',
        label: 'Property Type',
        options: options,
        standaloneLabel: false,
        addMarkForRequiredFields: true,
        isRequired: true
      })}
    </>
  );
});

export const MonthlyMarketRent = observer(({ store }: { store: FormStore }) => {
  return (
    <>
      {createCurrencyField({
        label: 'Monthly Market Rent',
        fieldName: 'monthlyMarketRent',
        testId: 'monthlyMarketRent',
        store,
        standaloneLabel: false,
        addMarkForRequiredFields: true,
        isRequired: true
      })}
    </>
  );
});

export const HoldingCosts = observer(({ store }: { store: FormStore }) => {
  return (
    <>
      {createCurrencyField({
        label: 'Holding Costs',
        fieldName: 'holdingCosts',
        testId: 'holdingCosts',
        store,
        standaloneLabel: false,
        addMarkForRequiredFields: true,
        isRequired: true
      })}
    </>
  );
});

export const AnnualTaxes = observer(({ store }: { store: FormStore }) => {
  return (
    <>
      {createCurrencyField({
        store,
        label: 'Annual Taxes',
        testId: 'annualTaxes',
        fieldName: 'annualTaxes',
        standaloneLabel: false,
        addMarkForRequiredFields: true,
        isRequired: true
      })}
    </>
  );
});

export const AnnualInsurance = observer(({ store }: { store: FormStore }) => {
  return (
    <>
      {createCurrencyField({
        store,
        label: 'Annual Insurance',
        testId: 'annualInsurance',
        fieldName: 'annualInsurance',
        standaloneLabel: false,
        addMarkForRequiredFields: true,
        isRequired: true
      })}
    </>
  );
});

export const IsThePropertyBeingConvertedToCondominiums = observer(
  ({ store }: { store: FormStore }) => {
    const fieldName = 'condoConversion';
    return (
      <YesNoField
        store={store}
        fieldName={fieldName}
        label={'Is the property being converted to condominiums?'}
        required
      />
    );
  }
);

export const HasThePropertySufferedFireDamage = observer(
  ({ store }: { store: FormStore }) => {
    const fieldName = 'fireDamage';
    const field = store.form.fields[fieldName];
    return (
      <YesNoField
        store={store}
        fieldName={fieldName}
        label={'Has the property suffered fire damage?'}
        required
      />
    );
  }
);

export const IsThereSquareFootageBeingAdded = observer(
  ({ store }: { store: FormStore }) => {
    const fieldName = 'squareFootageAdded';
    const field = store.form.fields[fieldName];
    return (
      <YesNoField
        store={store}
        fieldName={fieldName}
        label={
          'Is there square footage being added to the property and/or an ADU being added?'
        }
        required
      />
    );
  }
);

export const IsThereANewAddressForThisProperty = observer(
  ({ store }: { store: FormStore }) => {
    const fieldName = 'hasNewAddress';
    const field = store.form.fields[fieldName];
    return (
      <YesNoField
        store={store}
        fieldName={fieldName}
        label={'Is there a new address for this property?'}
        required
      />
    );
  }
);

export const ExitStrategy = observer(({ store }: { store: FormStore }) => {
  const fieldName = 'exitStrategy';
  const field = store.form.fields[fieldName];
  return (
    <SelectField
      label={'Exit Strategy'}
      variant={'outlined'}
      value={field.value}
      options={exitStrategies}
      onChange={value => store.onFieldChange(fieldName, value)}
      fullWidth
      testId={fieldName}
      standaloneLabel={true}
      error={!store.form.meta.isValid && isNotBlank(field.error)}
      errorText={!store.form.meta.isValid && field.error}
      required
    />
  );
});

export const NewAddressForThisProperty = observer(
  ({ store }: { store: FormStore }) => {
    const fieldName = 'newAddress';
    const field = store.form.fields[fieldName];
    return (
      <>
        <FieldLabel>New address for this property</FieldLabel>
        <AddressDetailsField
          value={field.value}
          onChange={value => store.onFieldChange(fieldName, value)}
          name={fieldName}
          error={!store.form.meta.isValid && isNotBlank(field.error)}
          errorText={!store.form.meta.isValid && field.error}
          standaloneLabel={false}
          required
        />
      </>
    );
  }
);

export const MonthlyMarketRentProvidedAtOriginationValue = observer(
  ({ store }: { store: FormStore }) => {
    return (
      <>
        {createCurrencyField({
          label: 'Monthly Market Rent',
          fieldName: 'monthlyMarketRentProvidedAtOrigination',
          testId: 'monthlyMarketRentProvidedAtOrigination',
          store,
          standaloneLabel: true,
          addMarkForRequiredFields: false,
        })}
      </>
    );
  }
);

export const AnnualHOAProvidedAtOriginationValue = observer(
  ({ store }: { store: FormStore }) => {
    return (
      <>
        {createCurrencyField({
          label: 'Annual HOA Dues',
          fieldName: 'annualHOAProvidedAtOrigination',
          testId: 'annualHOAProvidedAtOrigination',
          store,
          standaloneLabel: true,
          addMarkForRequiredFields: false,
        })}
      </>
    );
  }
);

export const AnnualTaxesProvidedAtOriginationValue = observer(
  ({ store }: { store: FormStore }) => {
    return (
      <>
        {createCurrencyField({
          label: 'Annual Taxes',
          fieldName: 'annualTaxesProvidedAtOrigination',
          testId: 'annualTaxesProvidedAtOrigination',
          store,
          standaloneLabel: true,
          addMarkForRequiredFields: false,
        })}
      </>
    );
  }
);

export const AnnualInsuranceProvidedAtOriginationValue = observer(
  ({ store }: { store: FormStore }) => {
    return (
      <>
        {createCurrencyField({
          label: 'Annual Insurance',
          fieldName: 'annualInsuranceProvidedAtOrigination',
          testId: 'annualInsuranceProvidedAtOrigination',
          store,
          standaloneLabel: true,
          addMarkForRequiredFields: false,
        })}
      </>
    );
  }
);
