import { observer } from 'mobx-react';
import { AlertDialog } from '@roc/ui';
import { Typography } from '@material-ui/core';

export const PauseMultifamilySubmissions = observer(({ open, handleClose, }) => {

  return (
    <AlertDialog
      open={open}
      body={
        <>
          <Typography>
            We have paused multifamily bridge and term submissions at this time. Contact your account executive or relationship manager for more information.
          </Typography>
        </>
      }
      handleClose={handleClose}
    />
  );
}

);

export default PauseMultifamilySubmissions;