import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { HomeWorkOutlined } from '@material-ui/icons';
import { insertIf, REFINANCE } from '@roc/feature-utils';
import { observer } from 'mobx-react';
import { EditControls } from '../../../components/editControls';
import { RoundedBackgroundIcon } from '../../../components/roundedBackgroundIcon';
import { TitleDivider } from '../../../components/titleDivider';
import { WholesalerAlertMessage } from '../../../components/wholesalerAlertMessage';
import { FORM_MAX_WIDTH, propertySourceValues, propertyTypeOptionsFnFGU } from '../../../utils/constants';
import {
  ArePermitsInPlace,
  ArmsLengthComment,
  BorrowerKnowOwnerOfRecord,
  ConstructionRenoSpendToDate,
  CurrentMarketValue,
  LoanEverBeenInDefault,
  MarketValueAfterCompletion,
  NumberOfLots,
  OutstandingLoanOnTheProperty,
  PayoffAmount,
  PropertyAddress,
  PropertyOwnership,
  PropertySourcing,
  PropertySourcingExplanation,
  PropertyType,
  ProposedAdditionalConstructionBudget,
  ProposedConstructionBudget,
  PurchaseDate,
  PurchasePrice,
  SellerCredit,
  SellerCreditAmount,
  SubdividedIntoLots,
  WhatWasThePurchasePrice,
  WholesalerPropertyContractAmount,
} from '../components/propertyInformationFields';
import {
  PropertyInformationTimeline,
  TimelineIconAdditionalConstructionBudget,
  TimelineIconConstructionBudget,
  TimelineIconMarketValue,
  TimelineIconPurchasePrice,
  TimelineIconRenoSpent,
} from '../components/propertyInformationTimeline';
import { PropertyInformationFormProps } from '../components/propertyInformationCard';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    position: 'relative',
    maxWidth: 1300,
    margin: 'auto',
  },
  activeConnector: {
    height: '50px',
    backgroundColor: theme.palette.primary.main,
    width: '4px',
  },
  inactiveConnector: {
    height: '50px',
    width: '4px',
  },
  timelineDot: {},
}));

export const GroundUpPropertyInformationForm = observer(
  ({
    store,
    onDeleteClick,
    showActions = true,
    removeButtonDisabled = false,
  }: PropertyInformationFormProps) => {
    const classes = useStyles();
    const propertyKey = store.form.meta.key;

    const loanPurpose = store.form.fields.loanPurpose.value;

    const isPurchase = loanPurpose !== REFINANCE;
    const isRefinance = loanPurpose === REFINANCE;

    const propertySourcing = store.form.fields.propertySourcing.value;

    const displayWholesalerAlertMessage =
      isPurchase && propertySourcing === propertySourceValues.wholesaler;

    const showPropertySourcingExplanation =
      isPurchase && propertySourcing === propertySourceValues.other;

    function saveHandler() {
      const isValid = store.validateAndCleanupProperty(
        'Please complete all the required fields'
      );
      if (isValid) {
        store.setEditMode(false);
      }
    }

    return (
      <>
        <Grid
          container
          spacing={2}
          className={classes.container}
          style={{ maxWidth: FORM_MAX_WIDTH, marginBottom: '0.5rem' }}
        >
          <Grid item xs={12}>
            <Grid
              container
              spacing={2}
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item>
                <RoundedBackgroundIcon
                  Icon={HomeWorkOutlined}
                  iconColor="#6D6D6D"
                />
              </Grid>
              <Grid item>
                <Typography variant="h4" style={{ color: '#6D6D6D' }}>
                  Add Property
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            style={{ marginTop: '1rem', marginBottom: '1rem' }}
          >
            <TitleDivider title="Property Details" />
          </Grid>
          <Grid item xs={12}>
            <PropertyAddress store={store} />
          </Grid>
          <Grid item xs={12}>
            <PropertyType store={store} options={propertyTypeOptionsFnFGU} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <PropertyOwnership store={store} />
          </Grid>
          {isPurchase && <PurchaseFields store={store} />}
          {isRefinance && <RefinanceFields store={store} />}
        </Grid>
        <Grid
          container
          spacing={2}
          className={classes.container}
          justifyContent="center"
          style={{ maxWidth: FORM_MAX_WIDTH, paddingInline: 0 }}
        >
          <Grid
            item
            xs={12}
            style={{ marginTop: '1rem', marginBottom: '1rem' }}
          >
            <TitleDivider title="Project Details" />
          </Grid>
          {displayWholesalerAlertMessage && (
            <Grid item xs={12} style={{ paddingInline: '1rem' }}>
              <WholesalerPropertyContractAmount store={store} />
            </Grid>
          )}
          <Grid item xs={12}>
            <PropertyInformationTimeline
              items={[
                {
                  icon: <TimelineIconPurchasePrice />,
                  right: isPurchase ? (
                    <PurchasePrice store={store} />
                  ) : (
                    <WhatWasThePurchasePrice store={store} />
                  ),
                },
                ...insertIf(isPurchase, [
                  {
                    icon: <TimelineIconConstructionBudget />,
                    left: <ProposedConstructionBudget store={store} />,
                  },
                ]),
                ...insertIf(isRefinance, [
                  {
                    icon: <TimelineIconMarketValue />,
                    right: <CurrentMarketValue store={store} />,
                  },
                  {
                    icon: <TimelineIconRenoSpent />,
                    left: <ConstructionRenoSpendToDate store={store} />,
                  },
                  {
                    icon: <TimelineIconAdditionalConstructionBudget />,
                    left: (
                      <ProposedAdditionalConstructionBudget store={store} />
                    ),
                    gray: true,
                  },
                ]),
                {
                  icon: <TimelineIconMarketValue />,
                  right: <MarketValueAfterCompletion store={store} />,
                  gray: true,
                },
              ]}
            />
          </Grid>
        </Grid>
        {showActions && (
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
            style={{ padding: '1rem' }}
          >
            <EditControls
              onDeleteClick={() => onDeleteClick(propertyKey)}
              onSaveClick={saveHandler}
              removeButtonDisabled={removeButtonDisabled}
            />
          </Grid>
        )}
      </>
    );
  }
);

const PurchaseFields = observer(({ store }) => {
  const fields = store.form.fields;
  const sellerCredit = store.form.fields.sellerCredit.value;
  const subdividedIntoLots = !!fields.subdividedIntoLots.value;
  const isPermitsInPlaceNotSelected = !store.form.fields.permitsInPlace.value;
  const displayWholesalerAlertMessage =
    store.form.fields.propertySourcing.value ===
    propertySourceValues.wholesaler;
  const armsLength = fields.borrowerKnowOwnerOfRecord.value;

  const loanPurpose = store.form.fields.loanPurpose.value;

  const isPurchase = loanPurpose !== REFINANCE;
  const propertySourcing = store.form.fields.propertySourcing.value;

  const showPropertySourcingExplanation =
    isPurchase && propertySourcing === propertySourceValues.other;

  return (
    <>
      <Grid item xs={12} sm={6}>
        <PropertySourcing store={store} />
      </Grid>
      {showPropertySourcingExplanation && (
        <Grid item xs={12} style={{ paddingInline: '1rem' }}>
          <PropertySourcingExplanation store={store} />
        </Grid>
      )}
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <BorrowerKnowOwnerOfRecord store={store} />
          </Grid>
          <Grid item xs={12} sm={6}>
            {armsLength && (
              <ArmsLengthComment store={store} />
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6}>
        <SubdividedIntoLots store={store} />
      </Grid>
      <Grid item xs={12} sm={6}>
        {subdividedIntoLots && <NumberOfLots store={store} />}
      </Grid>
      <Grid item xs={12} sm={6}>
        <SellerCredit store={store} />
      </Grid>
      <Grid item xs={12} sm={6}>
        {sellerCredit && <SellerCreditAmount store={store} />}
      </Grid>
      <Grid item xs={12} sm={6}>
        <ArePermitsInPlace store={store} />
      </Grid>
    </>
  );
});

const RefinanceFields = observer(({ store }) => {
  const outstandingLoanOnTheProperty =
    store.form.fields.outstandingLoanOnTheProperty.value;
  const isPermitsInPlaceNotSelected = !store.form.fields.permitsInPlace.value;

  return (
    <>
      <Grid item xs={12} sm={6}>
        <PurchaseDate store={store} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <OutstandingLoanOnTheProperty store={store} />
      </Grid>
      {outstandingLoanOnTheProperty && (
        <Grid item xs={12} sm={6}>
          <PayoffAmount store={store} />
        </Grid>
      )}
      {outstandingLoanOnTheProperty && (
        <Grid item xs={12} sm={6}>
          <LoanEverBeenInDefault store={store} />
        </Grid>
      )}
      <Grid xs={12} />
      <Grid item xs={12} sm={6}>
        <ArePermitsInPlace store={store} />
      </Grid>
    </>
  );
});
