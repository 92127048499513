import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Theme, Divider, Grid, Slider, Typography } from '@material-ui/core';
import { Button, CurrencyField, SelectField, TextField, NumberFormat } from '@roc/ui';
import { ButtonGroupFilter } from './buttonGroupFilter';
import { allPhotosOptions, conditionDetailsOptions, confidenceConditionOptions, listingOptions, roomsOptions, compsRecencyOptions, yesOrNoOptions } from '../../types/filtersOptions';
import { useStore } from '@roc/client-portal-shared/hooks';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    field: {
      width: '70%',
      margin: 'auto'
    },
    inputField: {
      margin: 'auto'
    },
    button: {
      padding: '10px',
      margin: '20px'
    },
    label: {
      alignContent: 'center',
      textAlign: 'center'
    },
    sectionsDivider: {
      marginBottom: '15px'
    },
    itemsDivider: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '3px'
    }
  })
);


export const FiltersContent = observer(() => {
  const { compToolStore } = useStore();

  const { compToolFiltersStore, compResponse } = compToolStore;
  const { propertyTypeOptions } = compToolFiltersStore;
  const classes = useStyles();

  const formFields = compToolFiltersStore.form.fields;


  const [bathsActive, setBathsActive] = useState(compToolFiltersStore.form.fields.FULL_BATHS.max.value);
  const [halfBathsActive, setHalfBathsActive] = useState(compToolFiltersStore.form.fields.HALF_BATHS.max.value);
  const [bedsActive, setBedsActive] = useState(compToolFiltersStore.form.fields.BEDROOMS.max.value);
  const [roomssActive, setRoomssActive] = useState(compToolFiltersStore.form.fields.ROOMS.max.value);
  const [activeProperties, setActiveProperties] = useState(compToolFiltersStore.form.fields.IS_ACTIVE.values.value);
  const [flippedProperties, setFlippedProperties] = useState(compToolFiltersStore.form.fields.WAS_FLIPPED.values.value);
  const [hasPhotosProperties, setHasPhotosProperties] = useState(compToolFiltersStore.form.fields.HAS_PHOTOS.values.value);
  const [conditionDetails, setConditionDetails] = useState(compToolFiltersStore.form.fields.CONDITION_TAG.values.value);
  const [confidenceCondition, setConfidenceCondition] = useState(compToolFiltersStore.form.fields.CONDITION_CONFIDENCE_TAG.values.value);
  const [sameSchoolDistrict, setSameSchoolDistrict] = useState(compToolFiltersStore.form.fields.SCHOOL_DISTRICT.values.value);


  const handleMinMaxButtonClick = (activeButton, value, name, setValue) => {
    setValue(value);
    if (value == '5+') {
      compToolFiltersStore.onFieldsChange(name, 'min', 5);
      compToolFiltersStore.onFieldsChange(name, 'max', 20);
    } else {
      compToolFiltersStore.onFieldsChange(name, 'min', value);
      compToolFiltersStore.onFieldsChange(name, 'max', value);
    }
  };

  const handleSingleSelection = (activeButton, value, name, setValue) => {
    let newValue
    if ((typeof value === 'string' && activeButton == value) || (Array.isArray(value) && activeButton.length === value.length && activeButton.every((v, i) => v == value[i]))) {
      newValue = []
    } else {
      newValue = value
    }
    setValue(newValue);
    compToolFiltersStore.onFieldsChange(name, 'values', newValue);
  }

  const handleMultipleSelection = (activeButton, values, name, setValue) => {
    let newValue = [...activeButton];

    values.forEach(value => {
      if (newValue.includes(value)) {
        newValue = newValue.filter(p => p !== value);
      } else {
        newValue.push(value);
      }
    });
    setValue(newValue);
    compToolFiltersStore.onFieldsChange(name, 'values', newValue);
  };


  const sectionsDivider = <Divider variant='fullWidth' className={classes.sectionsDivider} />
  const itemsDivider = <Divider variant='fullWidth' className={classes.itemsDivider} />


  return (
    <>
      <Grid container spacing={3} direction='row' justifyContent='space-between'>
        <Grid item xs={12} sm={2} className={classes.label}>
          <Typography variant='h6' align='left' >
            Property Type
          </Typography>
        </Grid>
        <Grid item xs={12} sm={10}>
          <SelectField
            options={propertyTypeOptions}
            testId={'propertyType'}
            value={compToolFiltersStore.form.fields.TYPE.values.value}
            onChange={value => compToolFiltersStore.onFieldsChange('TYPE', 'values', value)}
            fullWidth
            variant="outlined"
            placeholder='Make Selection'
          />
        </Grid>


        <Grid item xs={12}>
          {sectionsDivider}
        </Grid>

        <Grid item xs={12}>
          <Typography variant='h6' align='left' >
            School District
          </Typography>
        </Grid>

        <Grid item xs={12} sm={7} style={{ alignContent: 'center' }}>
          <Typography variant='body1' align='left'>
            Show Comps in the same School District as Subject Property
          </Typography>
        </Grid>
        <Grid item xs={12} sm={5} style={{ textAlign: 'left', alignContent: 'left' }}>
          <ButtonGroupFilter options={compToolFiltersStore.schoolDistricts} activeButton={sameSchoolDistrict} setActiveButton={handleSingleSelection} name='SCHOOL_DISTRICT' setValue={setSameSchoolDistrict} />
        </Grid>

        <Grid item xs={12} sm={12}>
          {sectionsDivider}
        </Grid>


        <Grid item xs={12} className={classes.label}>
          <Typography variant='h6' align='left' >
            Property Status
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <ButtonGroupFilter options={listingOptions} activeButton={activeProperties} setActiveButton={handleMultipleSelection} title='Listings' name='IS_ACTIVE' setValue={setActiveProperties} allowMultipleSelection={true} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ButtonGroupFilter options={yesOrNoOptions} activeButton={flippedProperties} setActiveButton={handleMultipleSelection} title='Flip' name='WAS_FLIPPED' setValue={setFlippedProperties} allowMultipleSelection={true} />
        </Grid>


        <Grid item xs={12} sm={12}>
          {sectionsDivider}
        </Grid>


        <Grid item xs={12} sm={6}>
          <Typography variant='h6' align='left' >
            Photos
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant='h6' align='left' >
            Comps Recency
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <ButtonGroupFilter options={allPhotosOptions} activeButton={hasPhotosProperties} setActiveButton={handleSingleSelection} name='HAS_PHOTOS' setValue={setHasPhotosProperties} />
        </Grid>
        <Grid item xs={12} sm={1} style={{ alignContent: 'center' }}>
          <Typography variant='subtitle1' align='left'>
            Last
          </Typography>
        </Grid>
        <Grid item xs={12} sm={5}>
          <SelectField
            name={'compsRecency'}
            value={compToolFiltersStore.form.fields.MONTHS.values.value}
            options={compsRecencyOptions}
            onChange={value => { compToolFiltersStore.onFieldsChange('MONTHS', 'values', value) }}
            variant="outlined"
            testId="termDuration"
            placeholder='Make a Selection'
            fullWidth
          />
        </Grid>


        <Grid item xs={12} sm={12}>
          {sectionsDivider}
        </Grid>


        <Grid item xs={12} sm={12}>
          <Typography variant='h6' align='left' >
            Price Range
          </Typography>
        </Grid>
        <Grid item xs={12} sm={5}>
          <CurrencyField
            testId='priceMin'
            variant="outlined"
            label="Minimum"
            value={compToolFiltersStore.form.fields.PRICE.min.value}
            fullWidth
            onChange={(name, value) => {
              compToolFiltersStore.onFieldsChange('PRICE', 'min', value)
            }}
          />
        </Grid>
        <Grid item xs={12} sm={2} className={classes.label}>
          {itemsDivider}
        </Grid>
        <Grid item xs={12} sm={5}>
          <CurrencyField
            testId='priceMax'
            variant="outlined"
            label="Maximum"
            value={formFields.PRICE.max.value}
            fullWidth
            onChange={(name, value) => {
              compToolFiltersStore.onFieldsChange('PRICE', 'max', value)
            }}
          />
        </Grid>


        <Grid item xs={12} sm={12}>
          {sectionsDivider}
        </Grid>

        {compToolStore.rentalComps &&
          <>
            <Grid item xs={12}>
              <Typography variant='h6' align='left' >
                Rent Range
              </Typography>
            </Grid>
            <Grid item xs={12} sm={5}>
              <CurrencyField
                testId='rentMin'
                variant="outlined"
                label="Minimum"
                value={compToolFiltersStore.form.fields.LEASE_PRICE.min.value}
                fullWidth
                onChange={(name, value) => {
                  compToolFiltersStore.onFieldsChange('LEASE_PRICE', 'min', value)
                }}
              />
            </Grid>
            <Grid item xs={12} sm={2} className={classes.label}>
              {itemsDivider}
            </Grid>
            <Grid item xs={12} sm={5}>
              <CurrencyField
                testId='rentMax'
                variant="outlined"
                label="Maximum"
                value={formFields.LEASE_PRICE.max.value}
                fullWidth
                onChange={(name, value) => {
                  compToolFiltersStore.onFieldsChange('LEASE_PRICE', 'max', value)
                }}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              {sectionsDivider}
            </Grid>
          </>
        }

        <Grid item xs={12} sm={12}>
          <Typography variant='h6' align='left'>
            Rooms and Beds
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <ButtonGroupFilter options={roomsOptions} activeButton={roomssActive} setActiveButton={handleMinMaxButtonClick} title='Rooms' name='ROOMS' setValue={setRoomssActive} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ButtonGroupFilter options={roomsOptions} activeButton={bedsActive} setActiveButton={handleMinMaxButtonClick} title='Beds' name='BEDROOMS' setValue={setBedsActive} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ButtonGroupFilter options={roomsOptions} activeButton={bathsActive} setActiveButton={handleMinMaxButtonClick} title='Baths' name='FULL_BATHS' setValue={setBathsActive} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ButtonGroupFilter options={roomsOptions} activeButton={halfBathsActive} setActiveButton={handleMinMaxButtonClick} title='Half-Baths' name='HALF_BATHS' setValue={setHalfBathsActive} />
        </Grid>


        <Grid item xs={12} sm={12}>
          {sectionsDivider}
        </Grid>

        <Grid item xs={12} sm={12}>
          <Typography variant='h6' align='left'>
            Property Details
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12}>
              <Typography variant='body1' align='left'>
                Square Feet
              </Typography>
            </Grid>
            <Grid container spacing={1} style={{ marginTop: '3px' }}>
              <Grid item xs={12} sm={5}>
                <NumberFormat
                  testId="areaMin"
                  variant="outlined"
                  allowNegative={false}
                  label="Minimum"
                  value={formFields.AREA.min.value}
                  fullWidth
                  customInput={TextField}
                  onChange={e => compToolFiltersStore.onFieldsChange('AREA', 'min', e.target.value)}
                  className={classes.inputField}
                />
              </Grid>
              <Grid item xs={12} sm={2} className={classes.label}>
                {itemsDivider}
              </Grid>
              <Grid item xs={12} sm={5}>
                <NumberFormat
                  testId="areaMax"
                  allowNegative={false}
                  variant="outlined"
                  label="Maximum"
                  value={compToolFiltersStore.form.fields.AREA.max.value}
                  fullWidth
                  customInput={TextField}
                  onChange={e => compToolFiltersStore.onFieldsChange('AREA', 'max', e.target.value)}
                  className={classes.inputField}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Grid container spacing={1} >
            <Grid item xs={12} sm={12}>
              <Typography variant='body1' align='left'>
                Year Built
              </Typography>
            </Grid>
            <Grid container spacing={1} style={{ marginTop: '3px' }}>
              <Grid item xs={12} sm={5}>
                <NumberFormat
                  testId="yearBuiltMin"
                  allowNegative={false}
                  variant="outlined"
                  label="Oldest"
                  value={compToolFiltersStore.form.fields.YEARBUILT.min.value}
                  fullWidth
                  customInput={TextField}
                  onChange={e => compToolFiltersStore.onFieldsChange('YEARBUILT', 'min', e.target.value)}
                  className={classes.inputField}
                />
              </Grid>
              <Grid item xs={12} sm={2} className={classes.label}>
                {itemsDivider}
              </Grid>
              <Grid item xs={12} sm={5}>
                <NumberFormat
                  testId="yearBuiltMax"
                  variant="outlined"
                  allowNegative={false}
                  label="Maximum"
                  value={compToolFiltersStore.form.fields.YEARBUILT.max.value}
                  fullWidth
                  customInput={TextField}
                  onChange={e => compToolFiltersStore.onFieldsChange('YEARBUILT', 'max', e.target.value)}
                  className={classes.inputField}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>


        <Grid item xs={12} sm={6}>
          <Grid container spacing={1} style={{ marginTop: '10px' }}>
            <Grid item xs={12} sm={12}>
              <Typography variant='body1' align='left'>
                Lot Size (acres)
              </Typography>
            </Grid>
            <Grid container spacing={1} style={{ marginTop: '3px' }}>
              <Grid item xs={12} sm={5}>
                <NumberFormat
                  testId="lotSizeMin"
                  variant="outlined"
                  allowNegative={false}
                  label="Minimum"
                  value={formFields.LOTSIZE.min.value}
                  fullWidth
                  customInput={TextField}
                  onChange={e => compToolFiltersStore.onFieldsChange('LOTSIZE', 'min', e.target.value)}
                  className={classes.inputField}
                />
              </Grid>
              <Grid item xs={12} sm={2} className={classes.label}>
                {itemsDivider}
              </Grid>
              <Grid item xs={12} sm={5}>
                <NumberFormat
                  testId="lotSizeMax"
                  allowNegative={false}
                  variant="outlined"
                  label="Maximum"
                  value={formFields.LOTSIZE.max.value}
                  fullWidth
                  customInput={TextField}
                  onChange={e => compToolFiltersStore.onFieldsChange('LOTSIZE', 'max', e.target.value)}
                  className={classes.inputField}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Grid container spacing={1} style={{ marginTop: '10px' }}>
            <Grid item xs={12} sm={12}>
              <Typography variant='body1' align='left'>
                Distance from Subject Property (miles)
              </Typography>
            </Grid>
            <Grid container spacing={1} style={{ marginTop: '3px' }}>
              <Grid item xs={12} sm={5}>
                <NumberFormat
                  testId="distanceMin"
                  variant="outlined"
                  label="Minimum"
                  allowNegative={false}
                  value={formFields.GEO__DIST.min.value}
                  fullWidth
                  customInput={TextField}
                  onChange={e => compToolFiltersStore.onFieldsChange('GEO__DIST', 'min', e.target.value)}
                  className={classes.inputField}
                />
              </Grid>
              <Grid item xs={12} sm={2} className={classes.label}>
                {itemsDivider}
              </Grid>
              <Grid item xs={12} sm={5}>
                <NumberFormat
                  testId="distanceMax"
                  allowNegative={false}
                  variant="outlined"
                  label="Maximum"
                  value={formFields.GEO__DIST.max.value}
                  fullWidth
                  customInput={TextField}
                  onChange={e => compToolFiltersStore.onFieldsChange('GEO__DIST', 'max', e.target.value)}
                  className={classes.inputField}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={12}>
          {sectionsDivider}
        </Grid>

        <Grid item xs={12} className={classes.label}>
          <Typography variant='h6' align='left' >
            Condition Details
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography variant='body1' align='left'>
            Condition
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant='body1' align='left'>
            Confidence Level of Condition Accuracy
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <ButtonGroupFilter options={conditionDetailsOptions} activeButton={conditionDetails} setActiveButton={handleMultipleSelection} name='CONDITION_TAG' setValue={setConditionDetails} allowMultipleSelection={true} />
        </Grid>
        <Grid item xs={12} sm={6} style={{ textAlign: 'left' }}>
          <ButtonGroupFilter options={confidenceConditionOptions} activeButton={confidenceCondition} setActiveButton={handleMultipleSelection} name='CONDITION_CONFIDENCE_TAG' setValue={setConfidenceCondition} allowMultipleSelection={true} />
        </Grid>


        <Grid item xs={12} sm={12}>
          {sectionsDivider}
        </Grid>


        <Grid item xs={12} sm={12}>
          <Typography variant='h6' align='left'>
            Costs
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12}>
              <Typography variant='body1' align='left'>
                Price/Sq.Ft
              </Typography>
            </Grid>
            <Grid container spacing={1} style={{ marginTop: '3px' }}>
              <Grid item xs={12} sm={5}>
                <CurrencyField
                  testId='sqtPriceMin'
                  variant="outlined"
                  label="Minimum"
                  value={formFields.SQFTPRICE.min.value}
                  fullWidth
                  onChange={(name, value) => compToolFiltersStore.onFieldsChange('SQFTPRICE', 'min', value)}
                  inputClassname={classes.inputField}
                />
              </Grid>
              <Grid item xs={12} sm={2} className={classes.label}>
                {itemsDivider}
              </Grid>
              <Grid item xs={12} sm={5}>
                <CurrencyField
                  testId='sqtPriceMax'
                  variant="outlined"
                  label="Maximum"
                  value={formFields.SQFTPRICE.max.value}
                  fullWidth
                  onChange={(name, value) => compToolFiltersStore.onFieldsChange('SQFTPRICE', 'max', value)}
                  inputClassname={classes.inputField}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>


        <Grid item xs={12} sm={6}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12}>
              <Typography variant='body1' align='left'>
                Taxes/Year
              </Typography>
            </Grid>
            <Grid container spacing={1} style={{ marginTop: '3px' }}>
              <Grid item xs={12} sm={5}>
                <CurrencyField
                  testId='taxesMin'
                  variant="outlined"
                  label="Minimum"
                  value={formFields.ASSESSOR_TAX.min.value}
                  fullWidth
                  onChange={(name, value) => compToolFiltersStore.onFieldsChange('ASSESSOR_TAX', 'min', value)}
                  inputClassname={classes.inputField}
                />
              </Grid>
              <Grid item xs={12} sm={2} className={classes.label}>
                {itemsDivider}
              </Grid>
              <Grid item xs={12} sm={5}>
                <CurrencyField
                  testId='taxesMax'
                  variant="outlined"
                  label="Maximum"
                  value={formFields.ASSESSOR_TAX.max.value}
                  fullWidth
                  onChange={(name, value) => compToolFiltersStore.onFieldsChange('ASSESSOR_TAX', 'max', value)}
                  inputClassname={classes.inputField}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>


        <Grid item xs={12} md={12} className={classes.label}>
          <Button
            variant="outlined"
            color="primary"
            testId="clearFilters"
            className={classes.button}
            onClick={() => {
              compToolStore.setFiltersApplied(false)
              compToolFiltersStore.clearFilters()
            }}
          >
            CLEAR FILTERS
          </Button>
          <Button
            variant="contained"
            color="primary"
            testId="applyFilters"
            className={classes.button}
            onClick={() => {
              compToolStore.setFiltersApplied(true)
              compToolStore.fetchCompToolProperties(1, true)
              compToolStore.setOpenFiltersModal(false)
            }}
          >
            APPLY FILTERS
          </Button>
        </Grid>
      </Grid>
    </>
  );
});