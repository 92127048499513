import { Box } from '@material-ui/core';
import { MoreHorizOutlined } from '@material-ui/icons';
import { HtmlContainer } from '@roc/ui';
import { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const ResizeObserver = (window as any).ResizeObserver;

const useStyles = makeStyles(theme => ({}));

export const DescriptionHtmlCell = ({ html }) => {
  const elementRef = useRef<HTMLDivElement>();
  const [showEllipsis, setShowEllipsis] = useState(false);

  const handleResize = () => {
    const htmlContainerEl = elementRef.current.children[0];
    setShowEllipsis(htmlContainerEl.clientHeight > 50);
  };

  useEffect(() => {
    const observer = new ResizeObserver(handleResize);

    if (elementRef.current) {
      observer.observe(elementRef.current);
      handleResize();
    }

    return () => observer.disconnect();
  }, [elementRef.current]);

  const css = `
    .wrapper {
      line-height: 1.1em;
      text-wrap: wrap;
      text-overflow: ellipsis;
    }
    p {
      margin:0.1em 0;
    }
  `;

  const style = {
    inset: 0,
    height: showEllipsis ? 34 : 50,
    alignItems: showEllipsis ? undefined : 'center',
  };

  return (
    <Box height={50} py={0.5} position="relative">
      <Box overflow="hidden" position="absolute" style={style} display="flex">
        <div ref={elementRef}>
          <HtmlContainer
            html={`
              <style>${css}</style>
              <div class="wrapper">${html}</div>
            `}
          />
        </div>
      </Box>
      {showEllipsis && (
        <Box
          position="absolute"
          bottom={0}
          right={0}
          left={0}
          fontSize={30}
          lineHeight={1}
        >
          <MoreHorizOutlined
            style={{ marginBottom: '-5px', fontSize: '12px' }}
          />
        </Box>
      )}
    </Box>
  );
};
