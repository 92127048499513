
import { Grid } from '@material-ui/core';
import { isFixAndFlip, LoanSubType, REFINANCE, renovationDescriptionsByLoanSubtype } from '@roc/feature-utils';

import {
  PurchasePrice,
  AnyDebt,
  DebtAmount,
  PurchaseDate,
  RenovationDescription,
  RefinanceRenovationBudget,
  RefinanceInvestedCapitalImprovements,
  RefinanceRenovationAmountRemaining,
  AsIsValue,
  AfterRepairValue,
  ExitStrategy,
  AdditionalComments,
  FireDamage,
  SquareFootageAdded,
  MonthlyMarketRent,
  AnnualTaxes,
  AnnualInsurance,
  AnnualHoa,
  PermitsInPlace,
  RehabDetails,
} from '@roc/ui/formComponents';
import { observer } from 'mobx-react';
import { useEffect } from 'react';

export const RefinancePurchaseFields = observer(
  ({ propertyStore }) => {
    const { refinance_purchaseInformationStore } = propertyStore;

    const isAnyDebt =
      refinance_purchaseInformationStore.form.fields.anyDebt.value === 'Y';

    return (
      <>
        <Grid item xs={12}>
          <AnyDebt store={refinance_purchaseInformationStore} disabled={false} showError />
        </Grid>
        {isAnyDebt && (
          <Grid item xs={12}>
            <DebtAmount store={refinance_purchaseInformationStore} disabled={false} />
          </Grid>
        )}
        <Grid item xs={12}>
          <PurchaseDate store={refinance_purchaseInformationStore} disabled={false} />
        </Grid>
        <Grid item xs={12}>
          <PurchasePrice store={refinance_purchaseInformationStore} disabled={false} />
        </Grid>
      </>
    );
  })


export const RefinanceRenovationFields = observer(
  ({ propertyStore, loanSubtype }) => {
    const { refinance_renovationDetailsStore } = propertyStore;
    const amountRemaining =
      refinance_renovationDetailsStore.form.fields.renovationRemaining.value > 0;

    const renovationDescriptionOptions = renovationDescriptionsByLoanSubtype(
      loanSubtype
    );
    const showFixFlipProFields = loanSubtype === LoanSubType.FIX_AND_FLIP_PRO;

    useEffect(() => {
      refinance_renovationDetailsStore.onFieldChange('loanSubType', loanSubtype);
    }, [loanSubtype]);

    return (
      <>
        <Grid item xs={12}>
          <RefinanceRenovationBudget
            store={refinance_renovationDetailsStore}
            onChange={() =>
              refinance_renovationDetailsStore.handleRenovationBudgetChange()
            }
            disabled={false}
          />
        </Grid>
        <Grid item xs={12}>
          <RefinanceInvestedCapitalImprovements
            store={refinance_renovationDetailsStore}
            onChange={() =>
              refinance_renovationDetailsStore.handleRenovationSpentToDateChange(
                loanSubtype
              )
            }
            disabled={false}
          />
        </Grid>
        <Grid item xs={12}>
          <RefinanceRenovationAmountRemaining
            store={refinance_renovationDetailsStore}
          />
        </Grid>
        {(amountRemaining && !(LoanSubType.GROUND_UP == loanSubtype)) && (
          <Grid item xs={12}>
            <RenovationDescription
              store={refinance_renovationDetailsStore}
              disabled={false}
              renovationDescriptionOptions={renovationDescriptionOptions}
              showError
            />
          </Grid>
        )}
        {isFixAndFlip(loanSubtype) && (
          <>
            <Grid item xs={12}>
              <FireDamage store={refinance_renovationDetailsStore} />
            </Grid>
            <Grid item xs={12}>
              <SquareFootageAdded store={refinance_renovationDetailsStore} />
            </Grid>
          </>
        )}
        {showFixFlipProFields && (
          <Grid item xs={12}>
            <RehabDetails store={refinance_renovationDetailsStore} postRehabGradeFieldsDisabled={!refinance_renovationDetailsStore.form.fields.squareFootageAdded.value} />
          </Grid>
        )}
        {loanSubtype === LoanSubType.GROUND_UP && (
          <Grid item xs={12}>
            <PermitsInPlace store={refinance_renovationDetailsStore} />
          </Grid>
        )}
      </>
    );
  });

export const RefinanceOtherFields = observer(
  ({ propertyStore }) => {
    const { refinance_renovationDetailsStore, refinance_otherDetailsStore, globalStore } = propertyStore;
    const amountRemaining =
      refinance_renovationDetailsStore.form.fields.renovationRemaining.value >
      0;
    const exitStrategy =
      refinance_otherDetailsStore.form.fields.exitStrategy.value;
    return (
      <>
        <Grid item xs={12}>
          <AsIsValue
            store={refinance_otherDetailsStore}
            disabled={false}
            showError
          />
        </Grid>
        {amountRemaining && (
          <Grid item xs={12}>
            <AfterRepairValue
              store={refinance_otherDetailsStore}
              disabled={false}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <ExitStrategy store={refinance_otherDetailsStore} disabled={false} />
        </Grid>
        {exitStrategy === REFINANCE ? (
          <>
            <Grid item xs={6}>
              <MonthlyMarketRent store={refinance_otherDetailsStore} />
            </Grid>
            <Grid item xs={6}>
              <AnnualTaxes store={refinance_otherDetailsStore} />
            </Grid>
            <Grid item xs={6}>
              <AnnualInsurance store={refinance_otherDetailsStore} />
            </Grid>
            <Grid item xs={6}>
              <AnnualHoa store={refinance_otherDetailsStore} />
            </Grid>
          </>
        ) : null}
      </>
    );
  });