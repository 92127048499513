import { Box, Grid } from '@material-ui/core';
import { FormStore } from '@roc/feature-app-core';
import { estimatedFFExperienceOptions } from '@roc/feature-utils';
import {
  createNumberFormatField,
  createPercentageField, createSelectField, createSliderField, createTextField
} from '@roc/ui/componentBuilder';
import { observer } from 'mobx-react-lite';
import { makeStyles } from '@material-ui/core/styles';
import { FieldLabel } from '@roc/ui';

const useStyles = makeStyles(theme => ({
  slider: {
    paddingTop: '1px',
  },
}));

interface Props {
  store: FormStore;
  showOnershipPercentage?: boolean;
}

export const BorrowerForm = observer((props: Props) => {
  const classes = useStyles();
  const { store, showOnershipPercentage } = props;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {createTextField({
          store,
          testId: 'firstName',
          fieldName: 'firstName',
          label: 'First Name',
        })}
      </Grid>
      <Grid item xs={12}>
        {createTextField({
          store,
          testId: 'lastName',
          fieldName: 'lastName',
          label: 'Last Name',
        })}
      </Grid>
      <Grid item xs={12}>
        {createTextField({
          store,
          testId: 'emailAddress',
          fieldName: 'emailAddress',
          label: 'Email Address',
        })}
      </Grid>
      <Grid item xs={12}>
        {createTextField({
          store,
          testId: 'confirmEmailAddress',
          fieldName: 'confirmEmailAddress',
          label: 'Confirm Email Address',
        })}
      </Grid>

      <Grid item xs={12}>
        {createNumberFormatField({
          store,
          testId: 'cellPhone',
          fieldName: 'cellPhone',
          label: 'Phone Number',
          format: '###-###-####',
        })}
      </Grid>
      {showOnershipPercentage && (
        <Grid item xs={12}>
          {createPercentageField({
            store,
            testId: 'pctOwnership',
            fieldName: 'pctOwnership',
            label: 'Percentage of Ownership',
            limitedPercentage: true,
          })}
        </Grid>
      )}
      <Grid item xs={12}>
        {createSelectField({
          store,
          testId: 'experienceProvidedAtOrigination',
          fieldName: 'experienceProvidedAtOrigination',
          label: 'Estimated Experience',
          options: estimatedFFExperienceOptions,
        })}
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" alignItems="center" style={{ height: '40px', marginBottom: '-16px' }}>
          <FieldLabel>Estimated FICO Score</FieldLabel>
        </Box>
        <Box style={{ paddingLeft: '8px' }}>
          {createSliderField({
            store,
            testId: 'ficoProvidedAtOrigination',
            fieldName: 'ficoProvidedAtOrigination',
            label: '',
            min: 350,
            max: 800,
            step: 10,
            marks: [
              {
                value: 350,
                label: '350',
              },
              {
                value: 800,
                label: '800',
              },
            ],
            valueLabelDisplay: 'on',
            className: classes.slider,
          })}
        </Box>
      </Grid>
    </Grid>
  );
});
