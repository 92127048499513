import { Grid } from '@material-ui/core';
import { Button, StandardDialog, TextField } from '@roc/ui';
import { observer } from 'mobx-react';

interface IMarkAsPaidModalProps {
  payableRefNumber: string;
  showMarkAsPaidModal: boolean;
  setPayableRefNumber: (value) => void;
  handleMarkAsPaidConfirm: () => void;
  closeMarkAsPaidModal: () => void;

}

export const MarkAsPaidModal = observer(({ payableRefNumber, setPayableRefNumber, showMarkAsPaidModal, closeMarkAsPaidModal, handleMarkAsPaidConfirm }: IMarkAsPaidModalProps) => {

  const getContent = () => {
    return (
      <Grid container>
        <Grid item xs={12}>
          <TextField
            testId="markAsPaidTextField"
            label=""
            variant='outlined'
            value={payableRefNumber}
            onChange={(e) => setPayableRefNumber(e.target.value)}
            helperText="Provide a reference number for selected payables"
            style={{
              width: '100%'
            }}
          />
        </Grid>
      </Grid>
    );
  }

  return (
    <StandardDialog
      open={showMarkAsPaidModal}
      title={"Mark as Paid"}
      maxWidth="sm"
      handleClose={closeMarkAsPaidModal}
      dialogContent={
        getContent()
      }
      dialogActions={(
        <>
          <Button
            style={{ marginRight: '8px' }}
            onClick={closeMarkAsPaidModal}
            color="primary"
            variant="outlined"
            testId="cancel"
          >
            Cancel
          </Button>
          <Button
            onClick={handleMarkAsPaidConfirm}
            color="primary"
            variant="contained"
            testId="confirm"
            disabled={!payableRefNumber}
          >
            Confirm
          </Button>
        </>
      )}
    />
  );

});
