import { UserService, WireAuthorizationService, WireAuthorizationServiceV2 } from './../services';
import { ApiResponse } from '@roc/client-portal-shared/services';
import { observable, flow, makeObservable } from 'mobx';
import { WireAuthorizationState, WireAuthInfo } from '../types';
import { DocumentVerificationState } from '@roc/feature-types';
import { GlobalStore, FormStore } from '@roc/feature-app-core';


export class WireAuthorizationStoreV2{
  private globalStore: GlobalStore;
  private wireAuthorizationServiceV2: WireAuthorizationServiceV2;
  shortCode: string;

  constructor(globalStore) {
    this.globalStore = globalStore;
    this.wireAuthorizationServiceV2 = new WireAuthorizationServiceV2();
    makeObservable(this, {
      setShortCode: flow,
      getTruliooShortCode: flow,
    });
    this.setDefaults();
  }

  private setDefaults() {
    this.shortCode = '';
  }

  *getTruliooShortCode() {
    try {
      const response = yield this.wireAuthorizationServiceV2.getTruliooShortCode();
      this.setShortCode(response.data.shortCode);
      return response.data.shortCode;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching trulioo short code',
      });
    }
  }

  *setShortCode(id: string) {
    this.shortCode = id;
  }
}

export default WireAuthorizationStoreV2;
