import { useEffect, useState } from 'react';
import { Box, ListItemIcon, ListItemText, Menu, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Layout } from '@roc/feature-app-core';
import { Tabs, Paper, GridColumnProps } from '@roc/ui';
import { observer } from 'mobx-react';
import { ProtectiveAdvancesGrid } from '../protectiveAdvancesGrid';
import { filterProps, noOpComparator } from '@roc/ui/utils';
import { LOAN_TABS, CounterPartyExpensesTab, ProtectiveAdvanceModalType, PayableReceivablesTypes } from '../../types/types';
import { CloudDownload, Edit, Clear, PlaylistAddCheck } from '@material-ui/icons';
import { useLoanDashboardStore } from '../../hooks/useLoanDashboardStore';
import { useDocumentStore } from '@roc/feature-documents';
import { SpecialServicingLoanToolbar } from './specialServicingLoanToolbar';
import { ProtectiveAdvanceModal } from './protectiveAdvanceModal';
import { insertIf } from '@roc/feature-utils';

const useStyles = makeStyles(() => ({
  container: {
    maxWidth: '1400px',
  },
  paper: {
    boxShadow: 'none !important',
    border: '1px solid #cdcdcd',
    padding: '0px',
    borderRadius: '0 !important',
  },
}));

export const SpecialServicingLoanDashboard = observer(({ loanId }) => {
  const classes = useStyles();
  const [openProtectiveAdvanceModal, setOpenProtectiveAdvanceModal] = useState(false);
  const [actionSelectedRecord, setActionSelectedRecord] = useState(null);

  const { loanDashboardStore } = useLoanDashboardStore();

  const { tab, payablesDashboardStore } = loanDashboardStore;
  const { documentStore } = useDocumentStore();

  useEffect(() => {
    loanDashboardStore.payablesDashboardStore.fetchEntityNames();
    return () => {
      loanDashboardStore.reset();
      loanDashboardStore.setEntity(loanId)
    };
  }, []);

  const handleTabChange = (_, i) => {
    loanDashboardStore.setTab(LOAN_TABS[i].value)
  }

  const actions = (record, handleMenuClose) => {

    return (
      [
        <MenuItem
          disabled={!record.documentId}
          onClick={() => {
            const document = {
              loanDocumentId: record.documentId,
              originalFileName: record.originalFileName,
            };
            documentStore.downloadDocumentOriginalNameById(document);
            handleMenuClose();
          }}
        >
          <ListItemIcon>
            <CloudDownload fontSize="small" />
          </ListItemIcon>
          <ListItemText>Download Invoice</ListItemText>
        </MenuItem>,
        ...insertIf(tab === CounterPartyExpensesTab.LOAN_PAYABLE, [
          <MenuItem
            disabled={record.status !== PayableReceivablesTypes.PENDING}
            onClick={() => {
              setActionSelectedRecord(record);
              setOpenProtectiveAdvanceModal(true);
              handleMenuClose();
              loanDashboardStore.protectiveAdvanceFormStore.setType(ProtectiveAdvanceModalType.EDIT);
            }}
          >
            <ListItemIcon>
              <Edit fontSize="small" />
            </ListItemIcon>
            <ListItemText>Edit</ListItemText>
          </MenuItem>,
          <MenuItem
            disabled={record.status !== PayableReceivablesTypes.PENDING}
            onClick={async () => {
              await payablesDashboardStore.performAction(record.recordId, PayableReceivablesTypes.REJECTED);
              loanDashboardStore.counterPartyExpenseGridStore.resetAndFetchGridData();
              handleMenuClose();
            }}
          >
            <ListItemIcon>
              <Clear fontSize="small" />
            </ListItemIcon>
            <ListItemText>Reject</ListItemText>
          </MenuItem>,
        ]),

      ]


    );
  }

  const COLUMNS: GridColumnProps[] = [
    {
      field: 'entity',
      headerName: tab === CounterPartyExpensesTab.LOAN_PAYABLE ? 'Vendor' : 'Investor',
      minWidth: 125,
      comparator: noOpComparator,
      ...filterProps(),
    },
    {
      field: 'invoice',
      headerName: 'Invoice #',
      minWidth: 125,
      comparator: noOpComparator,
      ...filterProps(),
    },
    {
      field: 'feeType',
      headerName: 'Type',
      minWidth: 125,
      comparator: noOpComparator,
      ...filterProps(),
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 125,
      comparator: noOpComparator,
      ...filterProps(),
    },
    {
      field: 'amount',
      headerName: 'Amount',
      minWidth: 125,
      cellRenderer: 'currencyCellRenderer',
      comparator: noOpComparator,
      ...filterProps(),
    },
    {
      field: 'createdDate',
      headerName: 'Creation Date',
      minWidth: 125,
      cellRenderer: 'dateCellRenderer',
      comparator: noOpComparator,
      ...filterProps(),
    },
    {
      field: 'leadId',
      headerName: 'Action',
      minWidth: 80,
      cellRenderer: 'actionCellRenderer',
      comparator: noOpComparator,
      width: 80,
      pinned: 'right',
      lockPinned: true,
    },
  ];

  return (
    <Layout title={`Loan Protective Advances - Loan ${loanId}`} containerClass={classes.container}>
      <Box pb={2}>
        <Tabs
          selectedTab={LOAN_TABS.findIndex(t => t.value == tab)}
          tabs={Object.values(LOAN_TABS)}
          onTabChange={handleTabChange}
        />
      </Box>
      <Box display="flex" flexDirection="column" height="100%">
        <Box flex={1}>
          <Paper className={classes.paper}>
            <ProtectiveAdvancesGrid
              counterPartyExpenseStore={loanDashboardStore}
              columns={COLUMNS}
              toolbar={tab === CounterPartyExpensesTab.LOAN_PAYABLE &&
                <SpecialServicingLoanToolbar
                  loanDashboardStore={loanDashboardStore}
                  vendors={loanDashboardStore.payablesDashboardStore.entityList}
                />
              }
              selectModeEnabled={false}
              actions={actions}
              loanId={loanId}
            />
          </Paper>
        </Box>
      </Box>
      <ProtectiveAdvanceModal
        record={actionSelectedRecord}
        loanId={loanDashboardStore.entity}
        open={openProtectiveAdvanceModal}
        store={loanDashboardStore.protectiveAdvanceFormStore}
        vendors={loanDashboardStore.payablesDashboardStore.entityList}
        onClose={() => setOpenProtectiveAdvanceModal(false)}
        onUpload={() => {
          loanDashboardStore.counterPartyExpenseGridStore.resetAndFetchGridData();
          setOpenProtectiveAdvanceModal(false)
        }}
      />
    </Layout>
  );
});
