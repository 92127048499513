import { Grid } from '@material-ui/core';
import { observer } from 'mobx-react';
import {
  TextField,
  PhoneField,
} from '@roc/ui';
import { isNotBlank } from '@roc/feature-utils';
import { useDrawsStore } from '../hooks/useDrawsStore';

const EditPointOfContactForm = observer(() => {
  const { pointOfContactStore } = useDrawsStore();
  const formFields = pointOfContactStore.pointOfContactFormStore.form.fields;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <TextField
          testId="firstName"
          required
          standaloneLabel
          variant="outlined"
          label="First Name"
          value={formFields.firstName.value}
          onChange={e => pointOfContactStore.pointOfContactFormStore.onFieldChange('firstName', e.target.value)}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          error={isNotBlank(formFields.firstName.error)}
          helperText={formFields.firstName.error}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          testId="lastName"
          standaloneLabel
          required
          variant="outlined"
          label="Last Name"
          value={formFields.lastName.value}
          onChange={e => pointOfContactStore.pointOfContactFormStore.onFieldChange('lastName', e.target.value)}
          error={isNotBlank(formFields.lastName.error)}
          helperText={formFields.lastName.error}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <PhoneField
          testId="phoneNumber"
          required
          variant="outlined"
          standaloneLabel
          label="Cellphone number"
          value={formFields.phoneNumber.value}
          onChange={(name, value) => pointOfContactStore.pointOfContactFormStore.onFieldChange('phoneNumber', value.match(/\d+/g) ? value.match(/\d+/g).join('') : '')}
          error={isNotBlank(formFields.phoneNumber.error)}
          errorText={formFields.phoneNumber.error}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          testId="email"
          required
          variant="outlined"
          standaloneLabel
          label="Email"
          value={formFields.email.value}
          onChange={e => pointOfContactStore.pointOfContactFormStore.onFieldChange('email', e.target.value)}
          error={isNotBlank(formFields.email.error)}
          helperText={formFields.email.error}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <TextField
          testId="comments"
          variant="outlined"
          label="Comments"
          standaloneLabel
          value={formFields.comments.value}
          onChange={e => pointOfContactStore.pointOfContactFormStore.onFieldChange('comments', e.target.value)}
          error={isNotBlank(formFields.comments.error)}
          helperText={formFields.comments.error}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
    </Grid>)
});

export default EditPointOfContactForm;