import { Chip, Grid, Typography, makeStyles, Box } from "@material-ui/core";
import { Accordion, AccordionDetails, AccordionSummary } from "@material-ui/core";
import { ExpandMore, Done, Cancel } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { ConfirmDialog, StatusType } from "@roc/ui";
import { PropertyTable } from './propertyTable';
import { observer } from "mobx-react";
import { Button } from "@roc/ui";
import { SelectField } from "@roc/ui";
import { useDocumentStore } from "@roc/feature-documents";
import { StatusChip } from "@roc/ui";
import { WarningOutlined } from "@material-ui/icons";
import { useStore } from "@roc/client-portal-shared/hooks";


const useStyles = makeStyles(theme => ({
  label: {
    width: '60%',
  },
  accordion: {
    boxShadow: 'none',
    position: 'relative'
  },
  accordionSummary: {
    zIndex: 9,
    position: 'sticky',
    top: 0,
    padding: 0,
    backgroundColor: 'white',
    '& .MuiAccordionSummary-content': {
      margin: theme.spacing(2),
    }
  },
  accordionDetails: {
    display: 'flex',
    flexDirection: 'column',
  },
  selectedContainer: {
    height: 52,
    backgroundColor: 'rgba(45, 194, 202, 0.08)',
    padding: 8,
    alignItems: 'center',
  },
  approveButton: {
    margin: '8px 8px 8px 0px',
    color: 'white',
    backgroundColor: 'green',
  },
  rejectButton: {
    margin: '8px 8px 8px 0px',
    color: 'white',
    backgroundColor: 'red',
  },
  naButton: {
    margin: '8px 8px 8px 0px',
    color: 'white',
    backgroundColor: 'gray',
    broder: '2px solid black'
  },
  paper: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    backgroundColor: '#FEF0ED',
    color: '#642B20',
  },
  content: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(1),
  },
  icon: {
    color: theme.palette.warning.light,
    padding: '2px'
  },
  warningTitle: {
    display: 'flex',
    alignItems: 'center'
  },
}));

const SelectedOptions = observer(({ numberOfSelected, onApprove, onReject, onDiscard }) => {
  const classes = useStyles();
  const { documentStore } = useDocumentStore();
  const { documentFormStore } = documentStore;
  const { insuranceApprovalStore } = documentFormStore;

  return (
    <Grid container justifyContent="space-between" alignContent="center" className={classes.selectedContainer}>
      <Grid item xs={4}>
        <Typography variant="body1" color="error">{numberOfSelected} selected</Typography>
      </Grid>
      <Grid item container xs={8} justifyContent="flex-end">
        <Grid item >
          <Button
            testId="approve-button"
            className={classes.approveButton}
            variant="contained"
            startIcon={<Done />}
            onClick={onApprove}
          >
            {insuranceApprovalStore.loanStore.loanDetails.preAgreed ? 'Conditional Approval' : 'Final Approval'}
          </Button>
        </Grid>
        <Grid item>
          <Button
            testId="reject-button"
            variant="contained"
            className={classes.rejectButton}
            startIcon={<Cancel />}
            onClick={onReject}
          >
            REJECT
          </Button>
        </Grid>
        <Grid item>
          <Button
            testId="reject-button"
            variant="contained"
            className={classes.naButton}
            onClick={onDiscard}
          >
            DISCARD
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
});

export const PropertyAccordion = observer(({ store, property }) => {
  const classes = useStyles();
  const [isOpenComfirmDialog, setIsOpenComfirmDialog] = useState<boolean>(false);
  const { documentStore } = useStore();
  const { documentFormStore } = documentStore;
  const { insuranceApprovalStore } = documentFormStore;

  const statusOptions = [
    {
      label: 'No Filter',
      value: 'No Filter'
    },
    {
      label: 'Needs Attention',
      value: 'Needs Attention'
    },
    {
      label: 'Approved',
      value: 'Approved'
    },
    {
      label: 'Rejected',
      value: 'Rejected'
    },
    {
      label: 'Not Applicable',
      value: 'Not Applicable'
    }
  ]

  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      if (selectedItems() == 0) setExpanded(newExpanded ? panel : false);
    };

  useEffect(() => {
    setExpanded(property.propertyAddress);
  }, [])

  const selectedItems = () => property.insuranceData.filter(item => store.selectedItems.includes(item.insuranceReviewDataId)).length;

  return (
    <Accordion className={classes.accordion} expanded={expanded === property.propertyAddress} onChange={handleChange(property.propertyAddress)}>
      <AccordionSummary
        className={classes.accordionSummary}
        expandIcon={<ExpandMore />}
      >
        <Grid container spacing={2}>
          {property.finalApprovalErrors?.length > 0 &&
            <Grid item xs={12} className={classes.paper}>
              <Grid item>
                <Typography className={classes.warningTitle}>
                  <WarningOutlined className={classes.icon} />
                  Conditional Approval Errors
                </Typography>
              </Grid>
              <Box pl={3} pt={1}>
                {property.finalApprovalErrors.map(error => {
                  return <Grid item xs={12}><Typography>{`\u2022${error}`}</Typography></Grid>
                })}
              </Box>
            </Grid>
          }
          <Grid item direction="row" alignItems="center" justifyContent="space-between">
            <Grid item >
              <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="h4" style={{ marginRight: '10px' }}>{property.propertyAddress}</Typography>
                {property.status === "Approved" ? (
                  <Chip
                    size="small"
                    label={"Approved"}
                    style={{
                      backgroundColor: '#89C053',
                      color: 'white',
                      fontWeight: 700,
                    }}
                  />) : null}
                {property.status === "In Review" ? (
                  <Chip
                    size="small"
                    label={"In Review"}
                    style={{
                      backgroundColor: '#F5B945',
                      color: 'white',
                      fontWeight: 700,
                    }}
                  />) : null}
                {property.status === "Rejected" ? (
                  <Chip
                    size="small"
                    label={"Rejected"}
                    style={{
                      backgroundColor: '#D6828A',
                      color: 'white',
                      fontWeight: 700,
                    }}
                  />) : null}
              </Grid>
              <Grid>
                {<Grid style={{ display: 'flex', alignItems: 'center' }}>
                  <Typography variant="h6" style={{ marginRight: '10px' }}>Loan Status: </Typography>
                  <StatusChip statusType={StatusType.LOAN} label={store.loanStatus} />
                </Grid>}
              </Grid>
            </Grid>
          </Grid>
          {selectedItems() > 0 && (
            <SelectedOptions numberOfSelected={selectedItems()}
              onApprove={() => {
                setIsOpenComfirmDialog(true);
                store.selectedActionApprove = 'Approved';
              }}
              onReject={() => {
                setIsOpenComfirmDialog(true);
                store.selectedActionApprove = 'Rejected';
              }}
              onDiscard={() => {
                setIsOpenComfirmDialog(true);
                store.selectedActionApprove = 'Not Applicable';
              }} />
          )}
        </Grid>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        <Grid container>
          <Grid item xs={12}>
            <Grid xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="h6" gutterBottom>Review Details</Typography>
              <Box width={'15%'}>
                <SelectField
                  label={'Status'}
                  value={store.statusFilter}
                  options={statusOptions}
                  onChange={value => store.statusFilter = value}
                  testId={'select-status-dropdown'}
                  fullWidth
                />
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <PropertyTable store={store} property={property}/>
          </Grid>
        </Grid>
      </AccordionDetails>
      <ConfirmDialog
        open={isOpenComfirmDialog}
        title="Confirmation"
        handleClose={() => setIsOpenComfirmDialog(false)}
        handleConfirm={() => store.approveOrRejectFields(property.insuranceData).then(response => insuranceApprovalStore.loanStore.fetchLoanDetails(store.loanId)).then(response => setIsOpenComfirmDialog(false))
          .then(response => insuranceApprovalStore.insuranceProviderFormStore.initialize())
        }
        handleCancel={() => setIsOpenComfirmDialog(false)}
        body={<Typography>{store.selectedActionApprove == 'Approved' ? 'Are you sure you want to approve the selected items?' :
          store.selectedActionApprove == 'Rejected' ? 'Are you sure you want to reject the selected items?' :
            'Are you sure you want to discard the selected items?'
        }</Typography>}
      />
    </Accordion>
  )
});
