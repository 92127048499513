import { FormStore, GlobalStore } from '@roc/feature-app-core';
import { generateUUID, GENERIC_ERROR_MESSAGE } from '@roc/feature-utils';
import { action, flow, makeObservable, observable } from 'mobx';
import { LoanForeclosureService } from '../services/loanForeclosureService';

const ownedDetailsForm = {
  fields: {
    deedRecordedDate: {
      value: '',
      error: null,
      rule: '',
    },
    propertyListedDate: {
      value: '',
      error: null,
      rule: '',
    },
    contractPrice: {
      value: '',
      error: null,
      rule: '',
    },
    broker: {
      value: '',
      error: null,
      rule: '',
    },
    propertyListing: {
      value: [],
      error: null,
      rule: '',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

const listedPricesForm = {
  fields: {
    id: {
      value: null,
    },
    date: {
      value: '',
      error: null,
      rule: 'required',
    },
    price: {
      value: '',
      error: null,
      rule: 'required',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export class RealEstateOwnedDetailsStore {
  private globalStore: GlobalStore;

  ownedDetailsFormStore: FormStore;
  listedPricesFormStore: FormStore;

  loanForeclosureService: LoanForeclosureService

  isOwnedDetailsDialogOpen: boolean;
  isListedPricesDialogOpen: boolean;

  constructor(globalStore: GlobalStore, loanForeclosureService: LoanForeclosureService) {
    this.globalStore = globalStore;
    this.ownedDetailsFormStore = new FormStore(ownedDetailsForm, globalStore);
    this.listedPricesFormStore = new FormStore(listedPricesForm, globalStore);
    this.loanForeclosureService = loanForeclosureService;

    makeObservable(this, {
      isOwnedDetailsDialogOpen: observable,
      isListedPricesDialogOpen: observable,
      openOwnedDetailsDialog: action,
      closeOwnedDetailsDialog: action,
      openListedPricesDialog: action,
      closeListedPricesDialog: action,
      reset: action,
      saveOwnedDetails: flow,
    });
  }

  reset() {
    this.ownedDetailsFormStore.reset();
    this.listedPricesFormStore.reset();
  }

  openOwnedDetailsDialog(data) {
    this.reset();
    this.isOwnedDetailsDialogOpen = true;
    this.ownedDetailsFormStore.loadForm(data);
  }

  closeOwnedDetailsDialog() {
    this.isListedPricesDialogOpen = false;
    this.isOwnedDetailsDialogOpen = false;
    this.reset();
  }

  openListedPricesDialog(data?) {
    this.listedPricesFormStore.reset();
    this.isListedPricesDialogOpen = true;
    let values = {
      ...data,
      id: data?.id ?? generateUUID(),
    };
    this.listedPricesFormStore.loadForm(values);
  }

  closeListedPricesDialog() {
    this.isListedPricesDialogOpen = false;
  }

  *saveOwnedDetails(loanId) {
    try {
      const data = {
        loanId,
        ...this.ownedDetailsFormStore.getFormValues(),
      }
      yield this.loanForeclosureService.saveOwnedDetails(data);
      this.closeOwnedDetailsDialog();
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE,
      });
    }
  }

  saveListedPrice() {
    try {
      const values = this.listedPricesFormStore.getFormValues();
      const listedPrices =
        this.ownedDetailsFormStore.form.fields.propertyListing.value ?? [];
      const items = listedPrices.filter(item => item.id != values.id);
      this.ownedDetailsFormStore.onFieldChange('propertyListing', [
        ...items,
        values,
      ]);
      this.closeListedPricesDialog();
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE,
      });
    }
  }

  deleteListedPrice(data?) {
    const listedPrices =
      this.ownedDetailsFormStore.form.fields.propertyListing.value ?? [];
    const items = listedPrices.filter(item => item.id != data.id);
    this.ownedDetailsFormStore.onFieldChange('propertyListing', items);
  }
}
