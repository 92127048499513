import { Box, ListItem, ListItemText, Typography } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { observer } from "mobx-react"
import { useEffect, useState } from "react";
import { Button, Card, TestDataAttribute, Tooltip, createNumberFormatField } from "@roc/ui";
import { sanitizeTestId } from "@roc/feature-utils";
import { useSendgridEmailSettingsStore } from "../hooks/useSendgridEmailSettingsStore";
import { Layout } from "@roc/feature-app-core";
import { InfoOutlined } from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
  card: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));

const CustomListItem = ({ label, value, valueComp, tooltip, tooltipText }: {
  label: string,
  value?: any,
  valueComp?: React.ReactNode,
  icon?: any,
  tooltip?: boolean,
  tooltipText?: string
}) => {
  const testId = sanitizeTestId(label);
  return (
    <ListItem>
      <ListItemText>
        <Box display="flex" alignItems="center">
          <Box display="flex" flexGrow={1}>{label}
            {tooltip && (
              <Tooltip title={tooltipText} placement="top" arrow>
                <Box sx={{ paddingLeft: '10px' }}>
                  <InfoOutlined fontSize="inherit" />
                </Box>
              </Tooltip>
            )
            }

          </Box>
          {
            valueComp ? valueComp : <TestDataAttribute id={testId}>
              <Box>{value}</Box>
            </TestDataAttribute>
          }
        </Box>
      </ListItemText>
    </ListItem>
  );
};


export const UpdateEmailSettingsScreen = observer(() => {
  const { sendgridEmailSettingsStore } = useSendgridEmailSettingsStore();

  const classes = useStyles();
  const [editEmailSettings, setEditEmailSettings] = useState(false);
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  const getEditButton = () => {
    return editEmailSettings ? undefined : () => setEditEmailSettings(true);
  }

  const handleSave = () => {
    sendgridEmailSettingsStore.updateGeneralEmailSettings();
  }

  const fetchData = async () => {
    await sendgridEmailSettingsStore.getGeneralEmailSettings();
    setIsDataLoaded(true);
  };

  useEffect(() => {
    fetchData();
  }, []);


  return (
    <Layout maxWidth={'md'}>
      {isDataLoaded && (
        <Card title='General Email Settings' cardContentProps={{ style: { padding: 0 } }} className={classes.card}
          onEdit={getEditButton()}>
          <Box p={2}>
            <Typography>
              Changes made on this screen will impact all future emails sent to recipients from any owner and audience.
            </Typography>
          </Box>
          <CustomListItem label='Days between repeat emails' tooltip={true} tooltipText={'Prevents email to the recipient from same originator within the selected number of days'} valueComp={
            editEmailSettings ?
              <Box width={300}>
                {createNumberFormatField({
                  store: sendgridEmailSettingsStore,
                  fieldName: 'daysInterval',
                  testId: 'daysInterval',
                  label: '',
                })}
              </Box> : sendgridEmailSettingsStore.form.fields.daysInterval.value + " days"
          }
          />
          {editEmailSettings &&
            <Box m={2} textAlign="right">
              <Button
                style={{ marginRight: 16 }}
                variant="outlined"
                onClick={() => {
                  setEditEmailSettings(false);
                }}
                color="default"
                testId="cancel_changes"
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  handleSave();
                  setEditEmailSettings(false);
                }}
                color="primary"
                testId="save_changes"
              >
                Save
              </Button>
            </Box>
          }
        </Card>
      )}
    </Layout>
  )
});
