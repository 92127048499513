import { Alert } from '@material-ui/lab';
import { observer } from 'mobx-react';
import React, { useEffect, useRef } from 'react';
import { useStore } from '../../../hooks';

const IframeHolder = ({ id, src, width, height, store }) => {
  const container = useRef();
  useEffect(() => {
    const frm = document.createElement('iframe');
    frm.src = src;
    frm.width = width;
    frm.height = height;
    frm.setAttribute('style', 'border: none;');
    container.current;
    document.getElementById(id).appendChild(frm);
    frm.onload = () => {
      store.setLoading(false);
    };
  }, []);

  return (
    <div id={id} style={{ minHeight: 'calc(100vh - 360px)', height: '100%' }} />
  );
};

export const DocumentSigning = observer(() => {
  const { wireAuthorizationStore } = useStore();

  useEffect(() => {
    wireAuthorizationStore.setLoading(true);
    wireAuthorizationStore.submitFormToDocusign();
  }, []);

  if (wireAuthorizationStore.docusignError) {
    wireAuthorizationStore.setLoading(false);
    return <Alert severity="error">Error loading document signing view</Alert>;
  }

  return (
    <>
      {wireAuthorizationStore.docusignUrl ? (
        <IframeHolder
          id="wire-authorization-form"
          src={wireAuthorizationStore.docusignUrl}
          width="100%"
          height="100%"
          store={wireAuthorizationStore}
        />
      ) : (
        null
      )}
    </>
  );
});
