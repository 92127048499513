import React, { Component, useEffect, useState } from 'react';
import {
  Query,
  Builder,
  BasicConfig,
  Utils as QbUtils,
  FieldProps,
} from 'react-awesome-query-builder';
import MaterialWidgets from 'react-awesome-query-builder/lib/components/widgets/material';
import { makeStyles, Theme, lighten, alpha } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import MaterialConfig from 'react-awesome-query-builder/lib/config/material';

const {
  MaterialBooleanWidget,
  MaterialTextWidget,
  MaterialTextAreaWidget,
  MaterialDateWidget,
  MaterialTimeWidget,
  MaterialDateTimeWidget,
  MaterialMultiSelectWidget,
  MaterialSelectWidget,
  MaterialNumberWidget,
  MaterialSliderWidget,
  MaterialRangeWidget,
  MaterialAutocompleteWidget,

  MaterialFieldSelect,
  MaterialFieldAutocomplete,
  MaterialConjs,
  MaterialSwitch,
  MaterialButton,
  MaterialButtonGroup,
  MaterialValueSources,

  MaterialProvider,
  MaterialConfirm,
  MaterialUseConfirm,
} = MaterialWidgets;

import 'react-awesome-query-builder/lib/css/styles.css';
import 'react-awesome-query-builder/lib/css/compact_styles.css';
import clsx from 'clsx';
import { QueryBuilderButton } from './queryBuilderButton';
import { QueryBuilderConjs } from './queryBuilderConjs';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { ThemeProvider } from '@roc/feature-app-core';
import { LEAD_SOURCE_OPTIONS } from '../../../leadDetails/leadSouceOptions';
import { leadStatusOptions, leadSubStatusOptions } from '../../../leads/constants/constants';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';


export interface FieldDefinition {
  fieldDisplayName: string,
  fieldName: string,
  fieldType: string,
  optionValueFields: any,
  optionValueFieldsDisplay: any
}


const useStyles = makeStyles((theme: Theme) => ({
  queryBuilderContainer: {
    ['&[data-advanced=true]']: {},
    ['&[data-advanced=false]']: {
      // Remove group line
      ['& .group-or-rule::before, & .group-or-rule::after']: {
        border: 0,
      },
      // Remove group line
      ['& .group--children']: {
        paddingLeft: '0 !important',
      },
      //Add some spacing between the Add filter button and the first rule
      ['& .group--children .group-or-rule-container:first-child::before']: {
        content: '" "',
        display: 'block',
        height: theme.spacing(1),
      },
      //AND separator
      ['& .group--children .group-or-rule-container:not(:first-child)::before']: {
        content: '"AND"',
        display: 'inline-block',
        marginBottom: theme.spacing(1),
        position: 'relative',
        backgroundColor: 'white',
        border: '1px solid #ddd',
        borderRadius: theme.shape.borderRadius,
        fontWeight: 'bold',
        padding: theme.spacing(0.5, 1),
      },
      // Hide select value source dropdown
      ['& .widget--valuesrc']:{
        display:'none'
      }
    },
    ['&>.group-or-rule-container']: {
      margin: '0 !important',
    },
    ['& .group']: {
      background: alpha('#ddd', 0.1),
      border: '1px solid #ddd',
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(1),
    },
    ['& .rule']: {
      backgroundColor: 'white',
      border: '1px solid rgba(0, 0, 0, 0.23)',
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(1),
    },
    ['& .group--actions']: {
      opacity: '1 !important',
      margin: theme.spacing(-1),
    },
    ['& .group--children']: {
      margin: theme.spacing(-1),
    },
    ['& .group-or-rule-container']: {
      padding: 0,
      margin: theme.spacing(1),
    },
    ['& .rule--header']: {
      opacity: '1 !important',
    },
  },
}));

const defaultConfig = MaterialConfig;




const getFields = (fields: FieldDefinition[]) => {
  const getDropdownOptions = (field) => {
    const values = field.optionValueFields.split(',');
    const labels = field.optionValueFieldsDisplay.split(',');
    return values.map((value, i) => ({ value, title:labels[i ]}))
  }
  const mapField = (field: FieldDefinition) => {
    const label = field.fieldDisplayName

    switch(field.fieldType){
      case 'Dropdown':
        return {
          label,
          type: 'select',
          fieldSettings: {
            showSearch: true,
            listValues:getDropdownOptions(field)
          },
        }
      case 'Numeric':
        return {
          label,
          type: 'number'
        }
      case 'DateSelect':
        return {
          label,
          type: 'date',
          valueSources: ['value'],
        }
      case 'CheckBox':
        return {
          label,
          type: 'select',
          fieldSettings: {
            listValues:[
              { title: 'Yes', value:true },
              { title: 'No', value:false }
            ],
          },
        }
      case 'TextBox':
      default:
        return {
          label,
          type: 'text'
        }
    }
  }

  return fields.reduce((agg, field) => (agg[field.fieldName] = mapField(field), agg),{})
}

const getConfig = (advanced: boolean, fields: FieldDefinition[]): any => ({
  ...defaultConfig, // reuse basic config
  settings: {
    ...defaultConfig.settings,
    renderProvider: ({ children, ...props }) => (
      <MaterialProvider {...props}>
        <ThemeProvider>{children}</ThemeProvider>
      </MaterialProvider>
    ),
    renderField: props =>
      props?.customProps?.showSearch ? (
        <MaterialFieldAutocomplete {...props} />
      ) : (
        <MaterialFieldSelect {...props} />
      ),
    renderButton: props => <QueryBuilderButton {...props} />,
    renderConjs: props => (advanced ? <QueryBuilderConjs {...props} /> : null),
    maxNesting: advanced ? undefined : 1, // If 1 cannot add groups
    canReorder: advanced,
    showNot: advanced,
    addRuleLabel: advanced ? defaultConfig.settings.addRuleLabel : 'Add Filter',
  },
  operators: {
    ...defaultConfig.operators,
    equal: {
      ...defaultConfig.operators.equal,
      label: 'Is',
    },
    not_equal: {
      ...defaultConfig.operators.not_equal,
      label: 'Is not',
    },
    select_equals: {
      ...defaultConfig.operators.select_equals,
      label: 'Is',
    },
    select_not_equals: {
      ...defaultConfig.operators.select_not_equals,
      label: 'Is not',
    },
    less: {
      ...defaultConfig.operators.less,
      label: 'Is less than',
    },
    less_or_equal: {
      ...defaultConfig.operators.less_or_equal,
      label: 'Is less than or equal to',
    },
    greater: {
      ...defaultConfig.operators.greater,
      label: 'Is greater than',
    },
    greater_or_equal: {
      ...defaultConfig.operators.greater_or_equal,
      label: 'Is greater than or equal to',
    },
  },
  widgets: {
    ...defaultConfig.widgets,
    date: {
      ...defaultConfig.widgets.date,
      dateFormat: 'MM/DD/yyyy',
    },
  },
  fields: getFields(fields),
});


// You can load query value from your backend storage (for saving see `Query.onChange()`)
const createEmptyTree = (cfg) => {
  const queryValue: any = {
    id: QbUtils.uuid(),
    type: 'group'
  };
  return QbUtils.checkTree(QbUtils.loadTree(queryValue), cfg);
}

interface QueryBuilderProps {
  advanced: boolean;
  fields: FieldDefinition[];
  query: string;
  onQueryChange: (query: any) => void;
}

export const QueryBuilder = ({ advanced, fields, query, onQueryChange }:QueryBuilderProps) => {
  (window as any)._QbUtils = QbUtils
  const [currentQuery, setCurrentQuery] = useState(null);
  const [tree, setTree] = useState(null);
  const [config, setConfig] = useState(null);
  const classes = useStyles();

  const loadQuery = () => {
    const cfg = getConfig(advanced, fields);
    const immutableTree = query
      ? QbUtils.loadFromJsonLogic(JSON.parse(query), cfg)
      : createEmptyTree(cfg);
    setTree(immutableTree);
    setConfig(cfg);
    setCurrentQuery(query);
  }
  useEffect(() => {
    if(currentQuery && query!=currentQuery){
      loadQuery();
    }
  }, [query]);

  useEffect(() => {
    loadQuery();
  }, [advanced, fields])

  const renderBuilder = props => (
    <div
      className={clsx('query-builder-container', classes.queryBuilderContainer)}
      data-advanced={advanced}
    >
      <Builder {...props} />
    </div>
  );

  const renderResult = () => {
    const immutableTree = tree;
    return (
      <div className="query-builder-result">
        <div>
          Query string:{' '}
          <pre>
            {JSON.stringify(QbUtils.queryString(immutableTree, config))}
          </pre>
        </div>
        <div>
          MongoDb query:{' '}
          <pre>
            {JSON.stringify(QbUtils.mongodbFormat(immutableTree, config))}
          </pre>
        </div>
        <div>
          SQL where:{' '}
          <pre>{JSON.stringify(QbUtils.sqlFormat(immutableTree, config))}</pre>
        </div>
        <div>
          JsonLogic:{' '}
          <pre>
            {JSON.stringify(QbUtils.jsonLogicFormat(immutableTree, config))}
          </pre>
        </div>
      </div>
    );
  };

  const onChange = (immutableTree, config) => {
    // Tip: for better performance you can apply `throttle` - see `examples/demo`
    setTree(immutableTree);
    setConfig(config);

    const jsonLogic = QbUtils.jsonLogicFormat(immutableTree, config);

    //Null if query is not valid
    const query = jsonLogic?.logic ? JSON.stringify(jsonLogic?.logic) : null;

    setCurrentQuery(query);
    onQueryChange(query);
  };

  if (!config || !tree) {
    return null;
  }

  return (
    <div>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Query
          {...config}
          value={tree}
          onChange={onChange}
          renderBuilder={renderBuilder}
        />
      </MuiPickersUtilsProvider>
      {/* {renderResult()} */}
    </div>
  );
};

QueryBuilder.displayName = 'QueryBuilder'