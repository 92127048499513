import { Box } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { observer } from 'mobx-react';
import { BorrowerEntityCard } from './borrowerSection/borrowerEntity/borrowerEntityCard';
import { BorrowersCard } from './borrowerSection/borrower/borrowersCard';
import { LoanTermsCard } from './loanTermsSection/loanTermsCard';
import { useLoanSummaryStore } from '../hooks/useLoanSummaryStore';
import { borrowerEntityColsBridge, loanTermColsBridge } from '../utils/ColumnsBridgeLoanSummary';
import { generalStatsColsStb } from '../utils/ColumnsStbLoanSummary';
import { CollateralsCard } from './collateralsSection/collateralsCard';

export const LoanDetailsOverviewStabilizedBridgeLoans = observer(({ loanDetails }) => {
  const { loanSummaryStore } = useLoanSummaryStore();
  return (
    <>
      <Box m={2}>
        <Grid container>
          <Grid item xs={12}>
            <LoanTermsCard loanDetails={loanDetails} columns={loanSummaryStore.transformData(loanTermColsBridge, 6, loanDetails)} title="Loan Terms" />
          </Grid>
        </Grid>
      </Box>
      <Box m={2}>
        <Grid container>
          <Grid item xs={12}>
            <BorrowerEntityCard columns={loanSummaryStore.transformDataForBorrowerEntity(borrowerEntityColsBridge, 3, loanDetails)} />
          </Grid>
        </Grid>
      </Box>
      <Box m={2}>
        <Grid container>
          <Grid item xs={12}>
            <BorrowersCard loanDetails={loanDetails} />
          </Grid>
        </Grid>
      </Box>
      <Box m={2}>
        <Grid container>
          <Grid item xs={12}>
            <Grid item xs={12}>
              <CollateralsCard loanDetails={loanDetails} />
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box m={2}>
        <Grid container>
          <Grid item xs={12}>
            <LoanTermsCard loanDetails={loanDetails} columns={loanSummaryStore.transformData(generalStatsColsStb, 6, loanDetails)} title="General Stats" />
          </Grid>
        </Grid>
      </Box>
    </>
  );
});