import DateFnsUtils from '@date-io/date-fns';
import { FormControl, FormHelperText } from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
  KeyboardDatePickerProps,
} from '@material-ui/pickers';
import { TextFieldProps } from '@roc/ui';
import 'date-fns';
import React from 'react';
import { FieldLabel } from '../fieldLabel/fieldLabel';
import { withTestDataAttribute } from '../testDataAttribute';

export type DateFieldProps = TextFieldProps &
  KeyboardDatePickerProps & {
    required?: boolean;
    value?: Date | null;
    onChange?: (value: Date | null) => void;
    disableFuture?: boolean;
    inputVariant?: 'standard' | 'outlined' | 'filled';
    inputClassname?: string;
    ariaLabel?: string;
    format: string;
    fullWidth?: boolean;
    disabled?: boolean;
    testId: string;
    errorText?: string;
    tooltip?: string;
  };

const MuiDateField: React.FC<DateFieldProps> = ({
  required,
  label,
  name,
  value,
  onChange,
  disableFuture,
  inputVariant,
  format,
  fullWidth,
  disabled,
  ...props
}) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        required={required}
        id={'date-picker-' + name}
        label={label}
        name={name}
        fullWidth={fullWidth}
        disabled={disabled}
        value={value}
        format={format}
        disableFuture={disableFuture}
        inputVariant={inputVariant}
        onChange={value => onChange && onChange(value)}
        KeyboardButtonProps={{
          'aria-label': 'Enter the Date',
        }}
        {...props}
      />
    </MuiPickersUtilsProvider>
  );
};

export const DateField = withTestDataAttribute((props: DateFieldProps) => {
  const { standaloneLabel, tooltip, ...muiComponentProps } = props;
  return (
    <FormControl
      color={muiComponentProps.color}
      error={muiComponentProps.error}
      fullWidth={muiComponentProps.fullWidth}
      disabled={muiComponentProps.disabled}
      required={muiComponentProps.required}
      size={muiComponentProps.size}
      variant={muiComponentProps.variant}
    >
      {standaloneLabel && muiComponentProps.label ? (
        <FieldLabel tooltip={tooltip}>
          {muiComponentProps.label}
          {muiComponentProps.required && ' *'}
        </FieldLabel>
      ) : null}
      <MuiDateField
        {...muiComponentProps}
        placeholder={muiComponentProps.placeholder ?? 'MM/DD/YYYY'}
        label={standaloneLabel ? undefined : muiComponentProps.label}
        required={muiComponentProps.required}
      />
      <FormHelperText>{muiComponentProps.errorText}</FormHelperText>
    </FormControl>
  );
});
