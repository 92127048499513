import { GlobalStore, GridStore } from '@roc/feature-app-core';
import { flow, makeObservable } from 'mobx';
import { RentalPricerBaseService } from '../services/rentalPricerBaseService';
import { RentalPricerDetailsStore } from './rentalPricerDetailsStore';
import { RentalPricerGridStore } from './rentalPricerGridStore';

export abstract class RentalPricerBaseStore {
  public globalStore: GlobalStore;
  protected rentalPricerService: RentalPricerBaseService;

  rentalPricerDetailsStore: RentalPricerDetailsStore;
  rentalPricerGridStore: RentalPricerGridStore;

  constructor(globalStore: GlobalStore) {
    this.globalStore = globalStore;
    makeObservable(this, {
      deletePricer: flow,
    });
  }

  *deletePricer(rentalPricerId) {
    try {
      yield this.rentalPricerService.deletePricer(rentalPricerId);
      yield this.rentalPricerGridStore.fetchGridData();
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while removing single property',
      });
    }
  }
}
