import React, { useState } from 'react';
import {
  ComposedChart, Bar, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer
} from 'recharts';

interface LoanVolumeChartProps {
  data: any[];
  columns: any[];
  lineData: any[];
}

export const LoanVolumeChart: React.FC<LoanVolumeChartProps> = ({ data, columns, lineData }) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [tooltipDataKey, setTooltipDataKey] = useState('');
  const [maxValue, setMaxValue] = useState(0);
  const [tickCount, setTickCount] = useState(0);

  const tooltipHandler = (key: string) => {
    setIsTooltipOpen(!isTooltipOpen);
    setTooltipDataKey(key);
  };

  const renderFundedLabel = (tickProps: any) => {
    const { x, y } = tickProps;
    return <text x={x} y={y + 10} textAnchor="middle" fontSize={10}>{'Funded'}</text>;
  };

  const renderMonthAxis = (tickProps: any) => {
    const { x, y, payload } = tickProps;
    const { value } = payload;
    return <text x={x} y={y} textAnchor="middle" fontSize={10}>{value}</text>;
  };

  const renderVerticalLabel = (props: any) => {
    const { x, y, height } = props.viewBox;
    const cy = (height / 2) + y;
    return (
      <text x={x} y={cy - 30} transform={`rotate(270 ${x} ${cy})`} textAnchor="middle">
        Loan Amount
      </text>
    );
  };

  const renderCustomTooltip = (props: any) => {
    const { active, payload } = props;
    if (isTooltipOpen && active) {
      const hoverObject = payload.find((element: any) => element.dataKey === tooltipDataKey);
      if (!hoverObject) {
        return null;
      }
      return (
        <div className="bar-custom-tooltip">
          <p style={{ color: hoverObject.fill }}>
            {hoverObject.name}: {hoverObject.value.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
          </p>
        </div>
      );
    }
    return null;
  };

  const renderYLabels = (props: number) => {
    let tick;
    if (props < 1000000) {
      tick = (props / 1000).toLocaleString('en-US', { style: 'currency', currency: 'USD' });
      return tick.slice(0, tick.indexOf('.')) + " th";
    } else if (props >= 1000000 && maxValue <= 20000000) {
      tick = (props / 1000000).toLocaleString('en-US', { style: 'currency', currency: 'USD' });
      return tick.slice(0, tick.indexOf('.') + 2) + " mn";
    } else {
      tick = (props / 1000000).toLocaleString('en-US', { style: 'currency', currency: 'USD' });
      return tick.slice(0, tick.indexOf('.')) + " mn";
    }
  };

  const calculateMax = (maxValue: number) => {
    if (!isFinite(maxValue)) {
      return 0;
    }
    setTickCount(30);
    setMaxValue(maxValue);
    return maxValue;
  };

  return (
    <ResponsiveContainer width={"100%"} height={600}>
      <ComposedChart
        data={data}
        margin={{ top: 30, right: 20, left: 60, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="month" tick={renderFundedLabel} />
        <XAxis dataKey="month" axisLine={false} tickLine={false} tick={renderMonthAxis} xAxisId="monthAxis" />
        <YAxis label={renderVerticalLabel} tickFormatter={renderYLabels} interval={0} width={80} tickCount={tickCount} domain={[0, dataMax => calculateMax(dataMax)]} />
        <YAxis tickFormatter={renderYLabels} interval={0} width={80} tickCount={tickCount} domain={[0, maxValue]} yAxisId="rightAxis" orientation="right" />
        <Tooltip cursor={false} animationDuration={0} content={renderCustomTooltip} labelFormatter={() => "Info"} />
        {columns && columns.length > 0 && columns
          .filter(elem => elem.stackName !== "Ignore")
          .map(bar =>
            <Bar key={bar.key} dataKey={bar.key} stackId={bar.stackName} fill={bar.color} name={bar.name}
              onMouseOver={() => tooltipHandler(bar.key)} onMouseOut={() => tooltipHandler('')} />
          )}
        {lineData.map((item, index) =>
          item.checked ? <Line key={item.dataKey} dataKey={item.dataKey} stroke={item.color} /> : null
        )}
      </ComposedChart>
    </ResponsiveContainer>
  );
};
