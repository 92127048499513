import { observer } from 'mobx-react';
import { ReactNode, useEffect, useState } from 'react';
import { AgGridColumnProps, DataGrid, LinkColumn, Paper, SubmitMenuItem } from '@roc/ui';
import { useStore } from '@roc/client-portal-shared/hooks';
import { Button, IconButton, ListItemIcon, ListItemText, Menu, Typography } from '@material-ui/core';
import { Layout } from '@roc/feature-app-core';
import { ImportExport, MoreVert } from '@material-ui/icons';
import { ConvertLeadToLenderModal } from '../../leadsDashboard/convertLeadToLenderModal';
import { useHistory } from 'react-router';
import { SelectMode } from 'libs/client-portal-shared/src/app/components/grid';
import { useCRMPortalStore } from 'apps/crm-portal/src/app/hooks/useCRMPortalStore';
import { useLeadDetailsRoutes } from '../../../routes/loanRoutes';
import { formatDate } from '@roc/feature-utils';


const noOpComparator = () => 0;

const columns = (recordType): AgGridColumnProps[] => {
  let cols = [
    {
      field: 'id',
      headerName: 'id',
      minWidth: 180,
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      hide: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    {
      field: 'ownerEmailC',
      headerName: 'Owner Email',
      minWidth: 180,
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      hide: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    {
      field: 'firstName',
      headerName: 'First Name',
      minWidth: 260,
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      cellRenderer: 'firstNameCellRenderer',
    },
    {
      field: 'lastName',
      headerName: 'Last Name',
      minWidth: 260,
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      cellRenderer: 'lastNameCellRenderer',
    },
    {
      field: 'company',
      headerName: 'Company',
      minWidth: 260,
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      cellRenderer: 'companyCellRenderer',
    },
    {
      field: 'leadSource',
      headerName: 'Lead Source',
      minWidth: 260,
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      minWidth: 260,
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    {
      field: 'subStatus',
      headerName: 'Lead Sub-Status',
      minWidth: 260,
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    {
      field: 'experienceScore',
      headerName: 'Experience Score',
      minWidth: 260,
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    {
      field: 'lastModifiedDate',
      headerName: 'Last Modified Date',
      minWidth: 260,
      cellRenderer: 'dateCellRenderer',
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    // {
    //   headerName: 'Action',
    //   minWidth: 80,
    //   cellRenderer: 'actionCellRenderer',
    //   comparator: noOpComparator,
    //   width: 80,
    //   pinned: 'right',
    //   lockPinned: true
    // },
  ];
  if (recordType === 'Borrower') {
    console.log(recordType);
    cols = cols.filter(column => column.headerName !== 'Action' && column.headerName !== 'Company');
  }
  return cols;
}

export const GenericLeadDashboardGrid = observer(({ toolbar, recordType }: { toolbar: ReactNode, recordType: String }) => {
  const { salesforceLeadStore, companyProfileStore, companyDropdownStore } = useStore();
  const { push } = useHistory();
  const [anchorEl, setAnchorEl] = useState<HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const [record, setRecord] = useState({});
  const { newLeadStore, ringCentralStore } = useCRMPortalStore();
  const { leadDetailsRoutesConfig } = useLeadDetailsRoutes();
  const { leadsDashboardStore } = salesforceLeadStore;

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    salesforceLeadStore.genericGridStore.resetAndFetchGridData();
  }, [salesforceLeadStore]);

  const onSortChanged = params => {
    const sortModel = params.api.getSortModel();
    salesforceLeadStore.genericGridStore.setSortModel(sortModel);
  };

  const onFilterChanged = params => {
    const filterModel = params.api.getFilterModel();
    salesforceLeadStore.genericGridStore.setFilterModel(filterModel);
  };

  const leadNameCellRenderer = (params, propertyName) => {
    const selectedId = salesforceLeadStore.genericGridStore?.gridData?.data?.rows.find(row => row[propertyName] === params.value)?.leadId;
    const url = leadDetailsRoutesConfig.leadDetails(selectedId).url;
    return LinkColumn({ ...params, url });
  };

  const handleOnclick = async (params) => {
    const selectedId = salesforceLeadStore?.genericGridStore?.gridData?.data?.rows.find(row => row.company === params.value)?.id;
    if (selectedId) {
      push(`/crm-portal/backoffice/lead-details/${selectedId}`)
    }

  }

  const frameworkComponents = {
    firstNameCellRenderer: params => leadNameCellRenderer(params, 'firstName'),
    lastNameCellRenderer: params => leadNameCellRenderer(params, 'lastName'),
    companyCellRenderer: params => leadNameCellRenderer(params, 'company'),
    dateCellRenderer: ({ value }) => formatDate(value) ?? null,
    actionCellRenderer: params => {
      return (
        <IconButton onClick={e => openMenu(e, params.node.data)}>
          <MoreVert color="primary" />
        </IconButton>
      );
    },
  };

  const openMenu = (event: React.MouseEvent<HTMLElement>, record) => {
    setRecord(record)
    setAnchorEl(event.currentTarget);
  };

  function onRowSelected(value) {
    const dashboardType = leadsDashboardStore.currentDashboard?.objectType;
    ringCentralStore.setRecord(value?.data.phone, value?.data, value?.node.selected, dashboardType)
  }

  return (
    <Layout>
      <Paper title={salesforceLeadStore.option?.label}>
        <DataGrid
          selectMode={SelectMode.MULTIPLE}
          onRowSelected={e => onRowSelected(e)}
          suppressRowClickSelection={false}
          frameworkComponents={frameworkComponents}
          columns={columns(recordType)}
          toolbar={toolbar}
          rows={salesforceLeadStore.genericGridStore.gridData.data.rows}
          onSortChanged={onSortChanged}
          onFilterChanged={onFilterChanged}
          isLoading={salesforceLeadStore.genericGridStore.isLoading}
          showFilters={salesforceLeadStore.genericGridStore.showFilters}
          sortModel={salesforceLeadStore.genericGridStore.sortModel}
          filterModel={salesforceLeadStore.genericGridStore.filterModel}
          paginationData={salesforceLeadStore.genericGridStore.gridData.meta}
          setPageNumber={salesforceLeadStore.genericGridStore.setPageNumber}
          rowHeight={45}
          domLayout='autoHeight'
        />
      </Paper>
      <Menu
        id="menu-appbar"
        getContentAnchorEl={null}
        anchorEl={anchorEl}
        keepMounted
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={menuOpen}
        onClose={handleMenuClose}
      >
        <SubmitMenuItem
          testId={`change-loan-status-`}
          onClick={() => {
            salesforceLeadStore.convertLeadToLender(record);
            handleMenuClose();
          }}
          submitDialogProps={{
            title: `Convert Lead to Lender`,
            body: <ConvertLeadToLenderModal record={record} />,
            okButtonText: 'Submit',
            submitValidation: () => {
              salesforceLeadStore.convertLeadToLenderStore.runFormValidation();
              return salesforceLeadStore.convertLeadToLenderStore.form.meta.isValid;
            },
          }}
        >
          <ListItemIcon >
            <ImportExport fontSize="small" />
          </ListItemIcon>
          <ListItemText>Convert Lead</ListItemText>
        </SubmitMenuItem>
      </Menu>
    </Layout>
  );
});
