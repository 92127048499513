import { ReactNode, useEffect, useState } from 'react';
import { Box, Grid, IconButton, Menu } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { formatDate } from '@roc/feature-utils';
import { DataGrid, GridColumnProps, SelectMode } from '@roc/ui';
import { formatCurrency } from '@roc/ui/utils';
import { observer } from 'mobx-react';
import { StatusDropdownGridEditor } from './receivables/statusDropdownGridEditor';
import { MoreVert } from '@material-ui/icons';
import { StatusEditorReceivablesBills } from './receivablesBills/statusEditorReceivablesBills';
import { StatusEditorPayables } from './payables/statusEditorPayables';
import { CommentEditorReceivableBills } from './receivablesBills/commentEditorReceivableBills';

const useStyles = makeStyles(theme => ({
  gridContainer: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    height: '65vh',
    overflow: 'hidden',
  },
}));

interface ProtectiveAdvancesGridInterface {
  counterPartyExpenseStore: any;
  columns: GridColumnProps[];
  selectModeEnabled: boolean;
  toolbar?: ReactNode;
  actions: (record, handleMenuClose) => ReactNode;
  loanId?: any;
}

export const ProtectiveAdvancesGrid = observer(({ counterPartyExpenseStore, columns, selectModeEnabled, toolbar, actions, loanId }: ProtectiveAdvancesGridInterface) => {
  const classes = useStyles();
  const { tab, counterPartyExpenseGridStore } = counterPartyExpenseStore;
  const [anchorEl, setAnchorEl] = useState<HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const [record, setRecord] = useState<any>({});
  const [gridApi, setGridApi] = useState(null);

  useEffect(() => {
    onTabChange();
  }, [tab]);

  useEffect(() => {
    if (selectModeEnabled) {
      counterPartyExpenseStore.clearRecordsIds();
    }
  }, [counterPartyExpenseGridStore.gridData?.meta?.pageNumber])

  useEffect(() => {
    gridApi?.deselectAll();
  }, [counterPartyExpenseStore.entity, counterPartyExpenseGridStore.gridData.data.rows])

  const onGridReady = params => {
    setGridApi(params.api);
  };

  const onTabChange = () => {
    counterPartyExpenseStore.setEntity(loanId || '');
    counterPartyExpenseGridStore.resetAndFetchGridData();

    if (selectModeEnabled) {
      counterPartyExpenseStore.clearRecordsIds();
    }
  };

  const frameworkComponents = {
    dateCellRenderer: ({ value }) => {
      return value ? formatDate(value, 'MM/dd/yyyy') : '';
    },
    currencyCellRenderer({ value }) {
      return formatCurrency(value);
    },
    booleanCellRenderer({ value }) {
      return value ? 'Yes' : 'No';
    },
    actionCellRenderer: params => {
      return (
        <>
          <IconButton onClick={e => {
            openMenu(e, params.node.data)
          }}>
            <MoreVert color="primary" />
          </IconButton>
        </>
      );
    },
    dropdownGridEditor: StatusDropdownGridEditor,
    statusReceivablesBillsEditor: StatusEditorReceivablesBills,
    statusPayablesEditor: StatusEditorPayables,
    commentBillsEditor: CommentEditorReceivableBills,
  };

  const onSortChanged = params => {
    const sortModel = params.api.getSortModel();
    counterPartyExpenseGridStore.setSortModel(sortModel);
  };

  const onFilterChanged = params => {
    const filterModel = params.api.getFilterModel();
    counterPartyExpenseGridStore.setFilterModel(filterModel);
  };

  const openMenu = (event: React.MouseEvent<HTMLElement>, record) => {
    setRecord(record);
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const onRowSelected = (params) => {
    // TODO - WE SHOULD USE A TRANSACTION ID
    counterPartyExpenseStore.setRecordsId(params?.data?.recordId, params?.node.selected);
    counterPartyExpenseStore.setTotalAmount(params?.data?.amount, params?.node.selected);
  };

  return (
    <Box>
      <Grid item className={classes.gridContainer}>
        <DataGrid
          columns={columns}
          rows={counterPartyExpenseGridStore.gridData.data.rows}
          frameworkComponents={frameworkComponents}
          selectMode={selectModeEnabled && SelectMode.MULTIPLE}
          headerCheckboxSelection={true}
          suppressRowClickSelection={true}
          toolbar={toolbar}
          key={tab}
          onSortChanged={onSortChanged}
          onFilterChanged={onFilterChanged}
          isLoading={counterPartyExpenseGridStore.isLoading}
          showFilters={counterPartyExpenseGridStore.showFilters}
          sortModel={counterPartyExpenseGridStore.sortModel}
          filterModel={counterPartyExpenseGridStore.filterModel}
          paginationData={counterPartyExpenseGridStore.gridData.meta}
          setPageNumber={counterPartyExpenseGridStore.setPageNumber}
          onRowSelected={onRowSelected}
          gridReady={onGridReady}
        />
      </Grid>
      <Menu
        id="menu-appbar"
        getContentAnchorEl={null}
        anchorEl={anchorEl}
        keepMounted
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={menuOpen}
        onClose={handleMenuClose}
      >
        {actions(record, handleMenuClose)}
      </Menu>
    </Box>
  );
});
