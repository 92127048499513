import { Box, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Layout } from '@roc/feature-app-core';
import { Paper, StepsContainer, VerticalBarLoader } from '@roc/ui';
import { observer } from 'mobx-react';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { useStore } from '@roc/client-portal-shared/hooks';
import { BorrowerDetailsVerificationForm } from './components/borrowerDetailsVerificationForm';
import { DocumentSigning } from './components/documentSigning';
import { ThankYou } from './components/thankYou';
import { Welcome } from './components/welcome';
import { FormStatus } from '../borrowers';
import { differenceInYears, parse, startOfToday } from 'date-fns';
import { YES, formatDate, insertIf, isNil } from '@roc/feature-utils';
import { BorrowerTrackRecordVerification } from './components/trackRecordVerification/borrowerTrackRecordVerification';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    padding: theme.spacing(3, 0, 4),
    borderRadius: theme.spacing(2),
  },
  stepper: {
    maxWidth: 800,
    margin: 'auto',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5),
    },
  },
  hideSteps: {
    '&.MuiStepper-root': {
      display: 'none'
    }
  }
}));

export const BorrowerDetailsVerification = observer(() => {
  const classes = useStyles();
  const { borrowerDetailsVerificationStore, globalStore } = useStore();
  const { uuid } = useParams<{ uuid: string }>();
  const [showWelcome, setShowWelcome] = useState(true);
  const { activeStep, uwMatrixEnabled } = borrowerDetailsVerificationStore;
  const formInfo = borrowerDetailsVerificationStore.formInfo;
  const finished = borrowerDetailsVerificationStore.formInfo?.status === FormStatus.FINISHED;
  const trackRecordStepCompleted = borrowerDetailsVerificationStore.formInfo?.savedForm?.trackRecordStepCompleted;
  const waitingAuthorizationState = borrowerDetailsVerificationStore.formInfo?.requestState === 'Waiting Authorization' || borrowerDetailsVerificationStore.formInfo?.requestState === 'Background Completed';
  const showDriversLicenseFields = borrowerDetailsVerificationStore.form.fields.driversLicense.value === YES;
  const showExperienceStep = formInfo?.showExperienceStep !== false;

  const isDriverLicenseMissing = useMemo(() => {
    return isNil(borrowerDetailsVerificationStore.driversLicense);
  }, [borrowerDetailsVerificationStore.driversLicense]);

  useEffect(() => {
    const fetchInformation = async () => {
      await borrowerDetailsVerificationStore.fetchPublicFormInformation(uuid);
      await borrowerDetailsVerificationStore.isFeatureEnabledForBorrowerDetailsVerification('uwMatrixEnabled');
    }
    fetchInformation();
  }, []);


  const isInvalidDateOfBirthDate = (dateOfBirth) => {
    const date = formatDate(dateOfBirth, 'MM/dd/yyyy')
    return (
      dateOfBirth &&
      differenceInYears(startOfToday(), parse(date, 'MM/dd/yyyy', new Date())) < 18
    );
  }

  const steps = [
    {
      title: 'Personal Information',
      icon: null,
      onBack: () => {
        borrowerDetailsVerificationStore.goPrevStep();
      },
      onNext: async () => {
        if (borrowerDetailsVerificationStore.form.meta.isValid) {
          if (isInvalidDateOfBirthDate(borrowerDetailsVerificationStore.form.fields.dateOfBirth.value)) {
            globalStore.notificationStore.showErrorNotification({ message: 'Please fix the validation errors.', });
            borrowerDetailsVerificationStore.form.fields.dateOfBirth.error = 'Age must be over 18 years'
          } else if (isDriverLicenseMissing) {
            globalStore.notificationStore.showErrorNotification({ message: "Please upload the driver's license image.", });
          } else {
            await borrowerDetailsVerificationStore.saveBorrowerDetails();
            if (borrowerDetailsVerificationStore.activeStep !== 1) {
              borrowerDetailsVerificationStore.goNextStep();
            }
          }
        } else if (borrowerDetailsVerificationStore.form.fields.__isAddressInCorrectFormat__.value == false) {
          globalStore.notificationStore.showErrorNotification({ message: 'Please fix the validation errors.', });
          borrowerDetailsVerificationStore.form.fields.address.error = 'Invalid address format';
        } else {
          borrowerDetailsVerificationStore.runFormValidationWithMessage();
        }
      },
      allowBack: false,
      allowNext: true,
    },
    ...insertIf(borrowerDetailsVerificationStore.formInfo?.runCredit || !uwMatrixEnabled, [{
      title: `${borrowerDetailsVerificationStore.formInfo?.runCredit === true ? 'Credit Authorization' : 'Finish'}`,
      icon: null,
      onBack: () => {
        borrowerDetailsVerificationStore.goPrevStep();
      },
      allowBack: finished ? false : true,
    }]),
    ...insertIf(uwMatrixEnabled, [{
      title: 'Experience',
      icon: null,
      onBack: () => { },
      onNext: () => { },
      allowBack: false,
      allowNext: false,
    }]
    ),
  ];

  const hideStepper = uwMatrixEnabled && !showExperienceStep;

  const getStepsContent = () => {
    const stepsContent = [];
    if (!uwMatrixEnabled) {
      stepsContent.push(<BorrowerDetailsVerificationForm isInternal={false} store={borrowerDetailsVerificationStore} />)
      if (borrowerDetailsVerificationStore.formInfo?.runCredit && !finished) {
        stepsContent.push(<DocumentSigning store={borrowerDetailsVerificationStore} />);
      }
      else {
        stepsContent.push(<ThankYou />);
      }
    }
    else {
      stepsContent.push(<BorrowerDetailsVerificationForm isInternal={false} store={borrowerDetailsVerificationStore} />)
      if (borrowerDetailsVerificationStore.formInfo?.runCredit) {
        stepsContent.push(<DocumentSigning store={borrowerDetailsVerificationStore} />);
      }
      if (!trackRecordStepCompleted && showExperienceStep) {
        stepsContent.push(<BorrowerTrackRecordVerification token={uuid} store={borrowerDetailsVerificationStore} />);
      }
      else {
        stepsContent.push(<ThankYou />);
      }
    }
    return stepsContent;
  };

  return (
    <Layout maxWidth={'lg'}>
      {showWelcome && !finished ? (
        <Welcome
          disableStart={!waitingAuthorizationState}
          onStart={() => setShowWelcome(false)}
        />
      ) : (
        <>
          <Box my={3}>
            <Typography variant="h3" gutterBottom>
              {'Tell us about yourself'}
            </Typography>
          </Box>

          <Paper className={classes.paper}>
            <StepsContainer
              activeStep={activeStep}
              disableNavigation={true}
              steps={steps}
              stepperClassName={clsx(
                classes.stepper,
                hideStepper && classes.hideSteps
              )}
            >
              {getStepsContent()}
            </StepsContainer>
          </Paper>
        </>
      )}
      {borrowerDetailsVerificationStore.loading && <VerticalBarLoader />}
    </Layout>
  );
});
