
import { observer } from 'mobx-react';
import { useProposalsStore } from '../../hooks';
import { Grid, Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { createSelectField, createTextField } from '@roc/ui';
import { ChatBubbleOutlineOutlined } from '@material-ui/icons';
import { Button } from '@roc/ui';
import { useState } from 'react';



const useStyles = makeStyles(theme => ({
  optionList: {
    padding: '10px 0px 30px 50px',
  },
  modalContainer: {
    display: 'flex',
    flexDirection: 'column',
  }
}));


const TopicModal = observer(({ createProposal, onClose }) => {
  const { proposalsStore } = useProposalsStore();
  const { proposalTopicsStore } = proposalsStore;
  const store = proposalTopicsStore.proposalTopicForm;
  const classes = useStyles();
  const [showCreateChatRoom, setShowCreateChatRoom] = useState(false);
  const [showProposalCreation, setShowProposalCreation] = useState(false);

  const createNewProposal = (val) => {
    if (val === "Fix and Flip (1-4)" || val === "Groundup (1-4)") {
      setShowProposalCreation(true);
      setShowCreateChatRoom(false);
    } else {
      setShowProposalCreation(false);
      setShowCreateChatRoom(true);
    }
    store.onFieldChange("type", val);
  }

  return (
    <>
      <Grid container>
        <div className={classes.modalContainer}>
          <div>
            <Typography>Please choose a category below or create your own question using "Other" from the dropdown. Here are some excellent examples of questions:</Typography>
            <ul className={classes.optionList}>
              <li> Can we provide loans for farmhouses?</li>
              <li> What is the maximum loan term we offer? </li>
              <li> Which loan servicers do we partner with? </li>
              <li> What's the minimum FICO score require for bridge loans? </li>
            </ul>
          </div>
          <div >
            {createSelectField({
              store,
              testId: 'type',
              fieldName: 'type',
              label: 'Please choose one from below',
              placeholder: 'Select from below',
              onChange: (value) => createNewProposal(value),
              options: [{ label: 'Loan Scenario (Fix and Flip; 1-4 Units)', value: 'Fix and Flip (1-4)' },
              { label: 'Loan Scenario (Ground Up; 1-4 Units)', value: 'Groundup (1-4)' },
              { label: 'Loan Structure', value: 'Loan Structure' },
              { label: 'Operational Question', value: 'Operational Question' },
              { label: 'Other', value: 'Other' }],
            })}
          </div>
          {showCreateChatRoom && (
            <div>
              {createTextField({
                store,
                testId: 'topic',
                fieldName: 'topic',
                label: "Kindly input your inquiry",
                multiline: true,
              })}
            </div>
          )}
        </div>
        <Box style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '40px' }}>
          <Button style={{ margin: '10px' }} testId='opctionCancelButton' size='small' variant='outlined' onClick={() => onClose()}>Cancel</Button>
          {showProposalCreation && (
            <Button style={{ margin: '10px' }} testId='submitTopicButton' size='small' onClick={() => createProposal(store.getFormValues().type)} variant='outlined'>Add New Deal</Button>
          )}
          {showCreateChatRoom && (
            <Button style={{ margin: '10px' }} testId='submitTopicButton' size='small' startIcon={<ChatBubbleOutlineOutlined />} onClick={() => proposalTopicsStore.saveProposalTopic()} variant='outlined'>Start Conversation</Button>
          )}
        </Box>
      </Grid>
    </>
  )

});
export default TopicModal;