

import { DrawReviewReportService } from '../services/drawReviewReportService';
import { ApiResponse } from '@roc/feature-app-core';
import { DrawReviewReportBaseStore } from './drawReviewReportBaseStore';

export class DrawReviewReportStore extends DrawReviewReportBaseStore {
  private drawReviewReportService: DrawReviewReportService;


  constructor(globalStore) {
    super(globalStore);

    this.drawReviewReportService = new DrawReviewReportService();
  }

  async loadTableData(drawId) {
    try {
      this.drawId = drawId;
      const response: ApiResponse = await this.drawReviewReportService.getDrawRequest(
        drawId
      );
      const drawDTO = response?.data?.data;
      const draw = drawDTO?.draw;
      const drawRequest = draw.drawRequest;
      this.drawDetails = {
        projectAddress: drawDTO.propertyAddress,
        loanId: draw.loanId,
        drawId: drawId,
        drawNumber: draw.drawNumber,
        borrowerName: drawDTO.borrowerName,
        lenderName: drawDTO.lenderName,
      };
      this.loanValues = {
        projectTotal: drawDTO.totalRenoBudget + drawDTO.sunkCost,
        contructionHoldbackReimbursed: drawDTO.constructionHoldbackReimbursedToDate,
        contructionHoldbackRemaining: drawDTO.constructionHoldbackRemaining,
        constructionHoldback: drawDTO.constructionHoldback,
        renoBudget: drawDTO.totalRenoBudget,
        sunkCost: drawDTO.sunkCost,
        constructionHoldbackRate: drawDTO.constructionHoldbackRate,
        constructionHoldbackFundedPercent: drawDTO.constructionHoldbackFundedPercent

      };
      this.inspectionLink = drawDTO.truepicLink;
      this.initializeDrawRequest(drawRequest, drawDTO);
      this.tableData = drawRequest?.dataContent;
      const imageResponse: ApiResponse = await this.drawReviewReportService.getImages(
        draw.loanId, drawId
      );

      this.pictures = JSON.parse(imageResponse?.data?.data);

    } catch (error) {
      console.log({ error });
    }
  }

  approveRequest() {
    //Not implemented
  }

  saveReview() {
    //Not implemented
  }

  declineRequest() {
    //Not implemented
  }

}
