import { Page } from '@roc/feature-app-core';
import { Route } from 'react-router';
import { ProspectPropertiesDashboard } from '../prospectPropertiesDashboard';
import { PropertyDetailsLayout } from '../components/propertyDetailsLayout';
import { useProspectPropertiesStore } from '../hooks/useProspectPropertiesStore';


const PROPERTY_BASE_PATH = ':propertyId';
const PROPERTY_TYPE_BASE_PATH = ':propertyType';

export const getProspectPropertiesRoutesConfig = (
  basePath: string,
  baseUrl: string
) => {
  return {
    prospectProperties: {
      path: `${basePath}/prospect-properties`,
      url: `${baseUrl}/prospect-properties`,
      documentTitle: 'Prospect Properties',
    },
    propertyDetails: (propertyId?: string, propertyType?: string) => ({
      path: `${basePath}/prospect-properties/${PROPERTY_BASE_PATH}/${PROPERTY_TYPE_BASE_PATH}`,
      url: `${baseUrl}/prospect-properties/${propertyId}/${propertyType}`,
    })
  };
};

export const getProspectPropertiesRoutes = (
  basePath: string,
  baseUrl: string
) => {
  const config = getProspectPropertiesRoutesConfig(basePath, baseUrl);
  const { prospectPropertiesStore } = useProspectPropertiesStore();
  return {
    prospectProperties: (
      <Route exact path={config.prospectProperties.path}>
        <Page routeDefinition={config.prospectProperties}>
          <ProspectPropertiesDashboard store={prospectPropertiesStore} />
        </Page>
      </Route>
    ),
    prospectPropertyDetails: (
      <Route exact path={config.propertyDetails().path}>
        <Page routeDefinition={config.prospectProperties}>
          <PropertyDetailsLayout store={prospectPropertiesStore} />
        </Page>
      </Route>
    )
  };
};
