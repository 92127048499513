import { Grid } from '@material-ui/core';
import { Tabs } from '@roc/ui';
import { useDocumentStore } from '@roc/feature-documents';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { InsuranceForm } from './common/insuranceForm';
import { PropertyForm } from './common/propertyForm';

const TABS = [
  {
    label: 'Collaterals',
  },
  {
    label: 'Current Insurance',
  },
];

export const ElmsureInsurance = observer(() => {
  const [selectedTab, setSelectedTab] = useState(0);

  const {
    documentStore: { documentFormStore },
  } = useDocumentStore();
  const { insuranceProviderStore } = documentFormStore;
  const { currentInsurance, properties } = insuranceProviderStore;

  const onTabChange = (e, index) => setSelectedTab(index);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Tabs tabs={TABS} selectedTab={selectedTab} onTabChange={onTabChange} />
      </Grid>
      <Grid item xs={12}>
        {selectedTab === 0 && (
          <Grid container spacing={2}>
            {properties.map(property => (
              <Grid key={property.address} item xs={12}>
                <PropertyForm
                  readOnly={!insuranceProviderStore.canEdit}
                  store={property}
                />
              </Grid>
            ))}
          </Grid>
        )}
        {selectedTab === 1 && (
          <InsuranceForm
            title="Please provide existing Insurance Details if any"
            readOnly={!insuranceProviderStore.canEdit}
            store={currentInsurance}
          />
        )}
      </Grid>
    </Grid>
  );
});

export default ElmsureInsurance;
