import { Service } from '@roc/feature-app-core';

const url = {
  TRADE_DOCUMENTS: '/api/v1/assetm/trade/getTradeDocs',
  DOWNLOAD_DOCUMENT: '/api/v1/assetm/trade/downloadTradeDocument',
  UPLOAD_DOCUMENT: '/api/v1/assetm/trade/uploadTradeDocument'
};

export class AssetManagementDocumentService extends Service {

  getDocuments(tradeId: string) {
    return this.get(
      `${url.TRADE_DOCUMENTS}/${tradeId}`
    );
  }

  downloadDocument(documentId: any) {
    return this.get(`${url.DOWNLOAD_DOCUMENT}?id=${documentId}`, {}, {
      responseType: 'arraybuffer'
    });
  }

  uploadDocument(documentId: any, data: any) {
    return this.post(`${url.UPLOAD_DOCUMENT}?tradeDocId=${documentId}`, data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    });
  }
}
