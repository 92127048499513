import { Service } from '@roc/feature-app-core';

const url = {
  FIND_APPRAISER: '/api/v1/loan/appraisal/findAppraiser',
};

export class FindAppraiserService extends Service {
  findAppraiser(lat: number, lng: number, state: string) {
    return this.get(url.FIND_APPRAISER, {
      lat,
      lng,
      state,
    });
  }
}
