import { Divider, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { GetApp } from '@material-ui/icons';
import { observer } from 'mobx-react';
import { useDocumentStore } from '@roc/feature-documents';
import { Button } from '@roc/ui';
import { useEffect } from 'react';
import { AppraisalGrid1004d } from './appraisalGrid1004d';

const useStyles = makeStyles({
  container: {
    paddingTop: 16,
    paddingBottom: 16,
    width: '100%'

  },
  downloadContainer: {
    display: 'flex',
    flexDirection: 'row',
    cursor: 'pointer',
    marginLeft: '17px',
  },
  icon: {
    fontSize: '24px',
    marginLeft: 4,
  },
  closeButtonContainer: {
    paddingTop: 16,
  }
});

export const AppraisalDetails1004d = observer(({ loanId, isInternal, isCda, isArr, onConfirm, url, borrowerEmail, isArrPaymentDone, isCdaPaymentDone }) => {

  const classes = useStyles();
  const { documentStore } = useDocumentStore();
  const { documentFormStore } = documentStore;
  const { appraisalRecertificationOrderDetailsStore } = documentFormStore;
  const { propertiesWithDeviation } = appraisalRecertificationOrderDetailsStore;
  appraisalRecertificationOrderDetailsStore.setIsInternal(isInternal);

  useEffect(() => {
    appraisalRecertificationOrderDetailsStore.getPropertiesWithDeviation(loanId);
    if (isInternal)
      window.parent.postMessage('cda-dashboard', '*');
  }, [])

  const handleDownloadClick = () => {
    appraisalRecertificationOrderDetailsStore.downloadTamariskSignedDocument(loanId, isCda ? 'CDA_Loan' : 'ARR_CDA_Loan');
  };

  return (
    <Grid container className={classes.container} justifyContent="center" spacing={3}>
      <Grid item container direction="row" justifyContent="space-between" xs={11}>
        <Grid item>
          <Typography variant="h5" gutterBottom>Revised Orders</Typography>
        </Grid>
      </Grid>
      <Grid item xs={11}>
        <AppraisalGrid1004d isCda={isCdaPaymentDone} />
      </Grid>
      {propertiesWithDeviation?.length > 0 &&
        <>
          <Grid item xs={12}>
            <Divider variant="fullWidth" />
          </Grid>
        </>
      }
    </Grid>
  );
});