import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  toolbarContainer: {
    background: '#fff',
    border: '1px solid #ddd',
    padding: theme.spacing(2),
    alignItems: 'center',
    display: 'flex',
  },
  toolbarAligner: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

export const Toolbar = ({ children, toolbarContainerStyle, left }: {
  children: any,
  toolbarContainerStyle?: any,
  left?: any
}) => {
  const classes = useStyles();
  return (
    <Box className={classes.toolbarContainer} style={toolbarContainerStyle} justifyContent={left ? 'space-between' : 'flex-end'}>
      {left && <Box className={classes.toolbarAligner}>
        {left}
      </Box>}
      <Box pr={1} className={classes.toolbarAligner}>
        {children}
      </Box>
    </Box>
  );
};
