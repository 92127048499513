import { Page } from '@roc/feature-app-core';
import { Route } from 'react-router';
import { CalendlyPage } from './calendlyPage';

export const getCalendlyRoutesConfig = (basePath: string, baseUrl: string) => {
  return {
    calendly: (loanId?: string) => ({
      path: `${basePath}/calendly/:loanId`,
      url: `${baseUrl}/calendly/${loanId}`,
      documentTitle: 'Calendly',
    }
    )
  };
}

export const getCalendlyRoutes = (basePath: string, baseUrl: string) => {
  const config = getCalendlyRoutesConfig(basePath, baseUrl);
  return {
    calendlyPage: (
      <Route exact path={config.calendly().path}>
        <Page routeDefinition={config.calendly()}>
          <CalendlyPage />
        </Page>
      </Route>
    )
  };
}