import { Service } from '@roc/feature-app-core';

const url = {
  ALL_VERIFICATION_REQUESTS:
    '/api/v1/loan/borrowerDetailsVerification/getAllBorrowerDetailsVerificationRequests',
  SUBMIT_VERIFICATION_REQUEST:
    '/api/v1/loan/borrowerDetailsVerification/submitBorrowerDetailsVerificationRequest',

  GET_PUBLIC_FORM_INFORMATION:
    '/api/v1/loan/public/borrowerDetailsVerification/getBorrowerDetailsPublicFormInfo',

  GET_FORM_INFORMATION:
    '/api/v1/loan/borrowerDetailsVerification/getBorrowerDetailsFormInfo',

  SAVE_BORROWER_DETAILS:
    '/api/v1/loan/public/borrowerDetailsVerification/saveBorrowerDetailsForm',

  CREATE_DETAILS_VERIFICATION_DOCUSIGN:
    '/api/v1/loan/public/borrowerDetailsVerification/createBorrowerDetailsVerificationDocusign',

  ////
  GET_DETAILS_VERIFICATION_INFO:
    '/api/v1/loan/public/borrower/borrowerDetailsVerification/getAuthInfo',

  CONFIRM_DOCUSIGN:
    '/api/v1/loan/public/borrowerDetailsVerification/confirmDocusign',

  UNSUBSCRIBED_FROM_DAILY_REMINDERS:
    '/api/v1/loan/public/borrowerDetailsVerification/unsubscribedFromReminders',

  DOWNLOAD_DRIVERS_LICENSE:
    '/api/v1/loan/public/borrowerDetailsVerification/downloadDriversLicense',
  GET_BORROWER_INFO_BY_TOKEN:
    '/api/v1/loan/public/borrowerDetailsVerification/getBorrowerInfoByToken',
  GET_BORROWER_BASEBALL_CARD_DETAILS: '/api/v1/loan/public/borrowerDetailsVerification/getBorrowerBaseballCardViewOnly',
  GET_VERIFIED_TRACK_RECORD_PROPERTIES: '/api/v1/loan/public/borrowerDetailsVerification/getVerifiedPropertiesInTrackRecord',
  GET_UNVERIFIED_TRACK_RECORD_PROPERTIES: '/api/v1/loan/public/borrowerDetailsVerification/getUnverifiedPropertiesInTrackRecord',
  SAVE_TRACK_RECORD_PROPERTY: '/api/v1/loan/public/borrowerDetailsVerification/addTrackRecord',
  EDIT_TRACK_RECORD_PROPERTY: '/api/v1/loan/public/borrowerDetailsVerification/editTrackRecord',
  DELETE_TRACK_RECORD_PROPERTY: '/api/v1/loan/public/borrowerDetailsVerification/deleteTrackRecord',
  SAVE_TRACK_RECORD_ENTITY: '/api/v1/loan/public/borrowerDetailsVerification/saveBorrowerEntityBorrower',
  EDIT_TRACK_RECORD_ENTITY: '/api/v1/loan/public/borrowerDetailsVerification/editBorrowerEntity',
  REMOVE_TRACK_RECORD_ENTITY: '/api/v1/loan/public/borrowerDetailsVerification/removeBorrowerEntityBorrower',
  GET_TASKS: '/api/v1/tasks/public/getTasks',
  CREATE_TASK: '/api/v1/tasks/public/createTasks',
  UPLOAD_TASK_FILE: '/api/v1/tasks/public/uploadTaskFile',
  DOWNLOAD_TASK_FILE: '/api/v1/tasks/public/downloadTaskFile',
  SAVE_BORROWER_GC_BROKER_DATA: '/api/v1/loan/public/borrowerDetailsVerification/saveBorrowerGCAndBrokerData',
  GET_BORROWER_TIER: '/api/v1/loan/public/borrowerDetailsVerification/getBorrowerUnverifiedTierRating',
  IS_FEATURE_ENABLED_FOR_BORROWER_DETAILS_VERIFICATION: '/api/v1/user/public/isFeatureEnabledForBorrowerDetailsVerification',
  GET_FORM_INFORMATION_BY_BORROWER_ID: '/api/v1/loan/borrowerDetailsVerification/getBorrowerDetailsFormInfoByBorrowerId',
  GET_FORM_INFORMATION_BY_BORROWER_ID_FOR_LOAN_APPLICATIONS: '/api/v1/loan/borrowerDetailsVerification/getLoanApplicationBorrowerDetailsFormInfoByBorrowerId',
  PARSE_TRACK_RECORD_TEMPLATE: '/api/v1/loan/public/borrowerDetailsVerification/parseTrackRecordTemplate',
  IS_TRACK_RECORD_TEMPLATE_PARSING_ALLOWED: '/api/v1/user/public/isTrackRecordTemplateParsingAllowed',
};

export class BorrowerDetailsVerificationService extends Service {
  getAllBorrowerDetailsVerificationRequests(
    page: number,
    pageSize: number,
    sortDir: string,
    sortBy: string,
    filters: object,
    dropdownFilters: object
  ) {
    return this.get(
      url.ALL_VERIFICATION_REQUESTS,
      {
        page,
        pageSize,
        sortDir,
        sortBy,
        filters,
        dropdownFilters,
      },
      {
        disableGlobalLoading: true,
      }
    );
  }

  submitBorrowerDetailsVerificationRequest(request) {
    return this.post(url.SUBMIT_VERIFICATION_REQUEST, request);
  }

  getFormInformation(token) {
    return this.get(url.GET_FORM_INFORMATION, { token });
  }

  getPublicFormInformation(token) {
    return this.get(url.GET_PUBLIC_FORM_INFORMATION, { token });
  }

  saveBorrowerDetails(borrowerDetails, driversLicense: File) {
    const borrowerDetailsJson = new Blob([JSON.stringify(borrowerDetails)], {
      type: 'application/json',
    });

    const formData = new FormData();
    formData.append('borrowerDetailsForm', borrowerDetailsJson);
    formData.append('driversLicense', driversLicense);

    return this.post(url.SAVE_BORROWER_DETAILS, formData);
  }

  createBorrowerDetailsVerificationDocusign(token) {
    return this.post(`${url.CREATE_DETAILS_VERIFICATION_DOCUSIGN}`, { token });
  }

  ///////

  getAuthInfo(requestId) {
    return this.get(
      `${url.GET_DETAILS_VERIFICATION_INFO}?requestId=${requestId}`
    );
  }

  confirmDocusign(token) {
    return this.post(`${url.CONFIRM_DOCUSIGN}?token=${token}`, null);
  }

  unsubscribedFromDailyReminders(token) {
    return this.post(
      `${url.UNSUBSCRIBED_FROM_DAILY_REMINDERS}?token=${token}`,
      null
    );
  }

  downloadDriversLicense(requestId) {
    return this.get(
      url.DOWNLOAD_DRIVERS_LICENSE,
      {
        requestId,
      },
      {
        responseType: 'blob',
      }
    );
  }

  getBorrowerInfoByToken(token) {
    return this.get(url.GET_BORROWER_INFO_BY_TOKEN, { token });
  }

  getBorrowerBaseballCardDetails(borrowerId: number, loanId?: number) {
    return this.get(url.GET_BORROWER_BASEBALL_CARD_DETAILS, {
      borrowerId,
      loanId,
    });
  }

  getVerifiedTrackRecordProperties(borrowerId: number) {
    return this.get(url.GET_VERIFIED_TRACK_RECORD_PROPERTIES, { borrowerId });
  }

  getUnverifiedTrackRecordProperties(borrowerId: number) {
    return this.get(url.GET_UNVERIFIED_TRACK_RECORD_PROPERTIES, { borrowerId });
  }

  saveTrackRecordProperty(data: any) {
    return this.post(url.SAVE_TRACK_RECORD_PROPERTY, data);
  }

  editTrackRecordProperty(data: any) {
    return this.post(url.EDIT_TRACK_RECORD_PROPERTY, data);
  }

  deleteTrackRecordProperty(id: number) {
    return this.delete(`${url.DELETE_TRACK_RECORD_PROPERTY}/${id}`);
  }

  saveTrackRecordEntity(data: any, id: number) {
    return this.post(`${url.SAVE_TRACK_RECORD_ENTITY}?borrowerId=${id}`, data);
  }

  editTrackRecordEntity(data: any, id: number) {
    return this.post(
      `${url.EDIT_TRACK_RECORD_ENTITY}?borrowerEntityId=${id}`,
      data
    );
  }

  removeTrackRecordEntity(id: number) {
    return this.delete(
      `${url.REMOVE_TRACK_RECORD_ENTITY}?borrowerEntityBorrowerId=${id}`
    );
  }

  getTasks(body) {
    return this.post(url.GET_TASKS, body);
  }

  createTask(body: any) {
    return this.post(url.CREATE_TASK, body);
  }

  uploadTaskFile(body: any) {
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    return this.post(url.UPLOAD_TASK_FILE, body, config);
  }

  downloadTaskFile(taskId) {
    return this.get(url.DOWNLOAD_TASK_FILE + '?taskId=' + taskId, null, {
      responseType: 'arraybuffer',
    }).then(response => new Blob([response.data]));
  }

  saveBorrowerGCAndBrokerData(data: any, token: string) {
    return this.post(
      `${url.SAVE_BORROWER_GC_BROKER_DATA}?token=${token}`,
      data
    );
  }

  getBorrowerUnverifiedTierRating(token, borrowerId) {
    return this.get(url.GET_BORROWER_TIER, { token, borrowerId });
  }

  isFeatureEnabledForBorrowerDetailsVerification(token, featureName) {
    return this.get(url.IS_FEATURE_ENABLED_FOR_BORROWER_DETAILS_VERIFICATION, { token, featureName });
  }

  getFormInformationByBorrowerId(borrowerId, isPersonalGuarantor) {
    return this.get(url.GET_FORM_INFORMATION_BY_BORROWER_ID, { borrowerId, isPersonalGuarantor });
  }

  getLoanApplicationBorrowerDetailsFormInfoByBorrowerId(borrowerId, isPersonalGuarantor) {
    return this.get(url.GET_FORM_INFORMATION_BY_BORROWER_ID_FOR_LOAN_APPLICATIONS, { borrowerId, isPersonalGuarantor });
  }

  parseTrackRecordTemplate(body: any, borrowerId: number) {
    const parseTrackRecordTemplateUrl = `${url.PARSE_TRACK_RECORD_TEMPLATE}?borrowerId=${borrowerId}`
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    return this.post(parseTrackRecordTemplateUrl, body, config);
  }

  isTrackRecordTemplateParsingAllowed() {
    return this.get(url.IS_TRACK_RECORD_TEMPLATE_PARSING_ALLOWED);
  }

}
