import { observer } from "mobx-react";
import { Grid, makeStyles } from '@material-ui/core';
import PropertyInformation from "../../../../../feature-loans/src/loanSubmission/fixflipGroundUp/propertyInformation";
import { PropertySteps } from "libs/feature-loans/src/loanSubmission/fixflipGroundUp/components/propertySteps";
import { Button, Paper } from "@roc/ui";
import { useHistory } from "react-router";
import { useRoutes } from "@roc/client-portal-shared/hooks";
import { useEffect } from "react";
import { Alert, AlertTitle } from '@material-ui/lab';

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(4),
  },
}));

export const EditLoanProperties = observer(props => {
  const { store, refreshLoan } = props;
  const { editLoansStore } = store;
  const classes = useStyles();
  const { push } = useHistory();
  const { loanRoutesConfig } = useRoutes();

  useEffect(() => {
    editLoansStore.loadStore(store.loanDetails);

    return () => {
      store.reset();
    };
  }, []);

  return (
    <Paper className={classes.container}>
      <PropertyInformation
        store={editLoansStore}
        renderSteps={(modalState, handleAdd, handleEdit, editLoansStore) => {
          return (
            <PropertySteps
              modalState={modalState}
              handleAdd={handleAdd}
              handleEdit={handleEdit}
              store={editLoansStore}
            />
          );
        }}
      />
      {
        editLoansStore.allErrors.length > 0 && (
          <Grid container direction='row' style={{ marginBottom: '8px' }}>
            <Grid item xs={12}>
              <Alert severity="error">
                <AlertTitle>ERRORS</AlertTitle>
                {editLoansStore.allErrors.map(error => (
                  <Grid>{error}</Grid>
                ))}
              </Alert>
            </Grid>
          </Grid>
        )
      }
      <Grid container direction='row' justifyContent='flex-end' spacing={2}>
        <Grid item>
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={() => {
              editLoansStore.updateLoanProperties(() => {
                push(
                  loanRoutesConfig.loans(store.loanDetails?.loanId).children
                    .dashboard.url
                )
                editLoansStore.reset();
                refreshLoan();
              });
            }}
            testId="saveLoanProperties"
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
});