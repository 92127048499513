import { Page } from '@roc/feature-app-core';
import { Route } from 'react-router';
import { useRocInternalPortalStore } from '../../../hooks';
import { SubmitAppraisal } from '@roc/feature-appraisals';

export const getStandAloneAppraisalInternalRoutesConfig = (
  basePath: string,
  baseUrl: string
) => {
  return {
    standAloneAppraisal: {
      path: `${basePath}/standAloneAppraisal`,
      url: `${baseUrl}/standAloneAppraisal`,
      documentTitle: 'Order Appraisal',
    }
  };
};

export const getStandAloneAppraisalInternalRoutes = (
  basePath: string,
  baseUrl: string
) => {
  const config = getStandAloneAppraisalInternalRoutesConfig(basePath, baseUrl);
  const { standAloneAppraisalInternalStore } = useRocInternalPortalStore();
  return {
    standAloneAppraisal: (
      <Route exact path={config.standAloneAppraisal.path}>
        <Page routeDefinition={config.standAloneAppraisal}>
          {/* <SubmitAppraisal store={standAloneAppraisalInternalStore} /> */}
        </Page>
      </Route>
    )

  };
};
