import { FormStore } from '@roc/feature-app-core';
import { GlobalStore } from '@roc/feature-app-core';
import { AppraisalsService } from '@roc/feature-appraisals';
import {
  generateUUID,
  GENERIC_ERROR_MESSAGE,
  LoanSubType,
  notLicensedinState,
  parseAddress,
  PURCHASE,
  REFINANCE,
  statePropertyTypeRestriction,
} from '@roc/feature-utils';
import { flow, makeObservable, observable, override } from 'mobx';
import { propertySourceValues } from '../../utils/constants';
import { mapPropertyFields } from '../../utils/utils';

const addressEquals = (addressA, addressB) => {
  return (
    addressA.streetNumber == addressB.streetNumber &&
    addressA.streetName == addressB.streetName &&
    addressA.city == addressB.city &&
    addressA.state == addressB.state &&
    addressA.zipCode == addressB.zipCode &&
    (addressA.aptNumber ?? '') == (addressB.aptNumber ?? '')
  );
};

export class PropertyFormStore extends FormStore {
  private globalStore: GlobalStore;
  private appraisalService: AppraisalsService;
  public loanSubType: string;

  key = generateUUID();
  isEditing = true;

  constructor(form, globalStore, loanSubType) {
    super(form, globalStore);
    this.globalStore = globalStore;
    this.loanSubType = loanSubType;
    this.appraisalService = new AppraisalsService();
    this.prepopulateNewProperty();

    makeObservable(this, {
      key: observable,
      isEditing: observable,
      findAppraisals: flow,
      reset: override,
    });
  }

  reset() {
    super.reset();
    this.isEditing = true;
    this.form.meta.editMode = true;
    this.form.meta.key = this.key;
  }

  prepopulateNewProperty() {
    this.form.meta.key = generateUUID();
    this.form.meta.editMode = true;
  }
  setEditMode(value) {
    this.form.meta.editMode = value;
    this.isEditing = value;
  }
  saveAddressField(address: string, addressDetails, geometryDetails) {
    const parsedAddress = parseAddress(
      address,
      addressDetails,
      geometryDetails
    );

    const streetAddress =
      parsedAddress.street_number === null
        ? parsedAddress.street
        : parsedAddress.street_number + ' ' + parsedAddress.street;
    this.onFieldChange('address', streetAddress);
    this.onFieldChange('streetNumber', parsedAddress.street_number);
    this.onFieldChange('streetName', parsedAddress.street);
    this.onFieldChange('city', parsedAddress.city);
    this.onFieldChange('state', parsedAddress.state);
    this.onFieldChange('zipCode', parsedAddress.zip);
    this.onFieldChange('latitude', parsedAddress.latitude);
    this.onFieldChange('longitude', parsedAddress.longitude);
    this.onFieldChange('aptNumber', parsedAddress.aptNumber);
    this.findAppraisals(address);
  }

  *findAppraisals(address) {
    try {
      const values = this.getFormValues();
      const propertyAddress = address.replace(/, EUA$/i, '');
      const response = yield this.appraisalService.getStandaloneAppraisals(
        1,
        20,
        null,
        null,
        { propertyAddress },
        {}
      );
      const appraisals = response.data.data.rows;
      const isAppraisalAvailable = appraisals
        .map(row => JSON.parse(row.appraisalData.propertyAppraisalOrderJson))
        .some(appraisalData => addressEquals(appraisalData.property, values));

      this.onFieldChange('isAppraisalAvailable', isAppraisalAvailable);
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE,
      });
    }
  }

  /**
   * Validates the property fields and cleans up unused fields (fields hidden because of other fields values)
   */
  validateAndCleanupProperty(message?: string) {
    this.cleanupUnusedFields();
    const state = this.form.fields.state.value;
    const propertyType = this.form.fields.propertyType.value;
    if (notLicensedinState.states.includes(state)) {
      this.globalStore.notificationStore.showWarningNotification({
        message: notLicensedinState.message,
      });
      return false;
    }
    if(statePropertyTypeRestriction.states.includes(state) && statePropertyTypeRestriction.propertyTypes.includes(propertyType)) {
      this.globalStore.notificationStore.showWarningNotification({
        message: statePropertyTypeRestriction.message,
      });
      return false;
    }

    if (message) {
      this.runFormValidationWithMessage(message);
    } else {
      this.runFormValidation();
    }

    return this.form.meta.isValid;
  }

  loadProperty(property) {
    this.loadForm({
      ...property,
      city: property?.city || '',
      state: property?.state || '',
      zipCode: property?.zipCode || '',
    });
    this.key = property.uuid;
  }

  getProperty() {
    const property = mapPropertyFields(this.getFormValues());
    return {
      ...property,
      uuid: this.key,
    };
  }
  /**
   * Cleans up unused fields (fields hidden because of other fields values)
   */
  cleanupUnusedFields() {
    const property = this.getProperty();
    this.reset();
    this.loadForm(property);
  }

  validateProperty(prefight?: boolean) {
    this.onFieldChange('isPreflight', Boolean(prefight));
    this.runFormValidationWithMessage();
    return this.form.meta.isValid;
  }

  onFieldChange(field, value) {
    super.onFieldChange(field, value);
    if (field == 'loanPurpose') {
      if (value == REFINANCE) {
        super.onFieldChange('propertySourcing', '');
        super.onFieldChange('sellerCredit', '');
        super.onFieldChange('sellerCreditAmount', '');
        super.onFieldChange('borrowerKnowOwnerOfRecord', '');
        super.onFieldChange('armsLengthComment', '');
        super.onFieldChange('wholesalerPropertyContractAmount', '');
        super.onFieldChange('propertySourcingExplanation', '');
        if (this.loanSubType == LoanSubType.GROUND_UP) {
          super.onFieldChange('subdividedIntoLots', '');
          super.onFieldChange('numberOfLots', '');
        }
        else if (this.loanSubType == LoanSubType.STABILIZED_BRIDGE) {
          super.onFieldChange('squareFootageExpansion', '');
        }
      }
      if (value == PURCHASE) {
        super.onFieldChange('purchaseDate', null);
        super.onFieldChange('outstandingLoanOnTheProperty', '');
        super.onFieldChange('payoffAmount', '');
        super.onFieldChange('loanEverBeenInDefault', '');
        super.onFieldChange('constructionRenoSpendToDate', '');
        if (this.loanSubType == LoanSubType.FIX_AND_FLIP) {
          super.onFieldChange('currentMarketValue', '');
        }
        else if (this.loanSubType == LoanSubType.GROUND_UP) {
          super.onFieldChange('currentMarketValue', '');
        }
        else if (this.loanSubType == LoanSubType.STABILIZED_BRIDGE) {
          super.onFieldChange('holdingCosts', '');
        }
      }
    }
  }
}
