import { Box, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import { useParams } from 'react-router';
import { useEffect } from 'react';
import { Layout } from '@roc/feature-app-core';
import { Welcome } from '../../../../../../libs/feature-borrowers/src/borrowerSetupToDo/components/public/welcome';
import { WelcomeWithIdVerification } from '../../../../../../libs/feature-borrowers/src/borrowerSetupToDo/components/withIdVerification/welcomeWithIdVerification';
import { Paper, VerticalBarLoader } from '@roc/ui';
import { makeStyles } from '@material-ui/core/styles';
import { PublicBorrowerSetupSteps } from '../../../../../../libs/feature-borrowers/src/borrowerSetupToDo/components/public/publicBorrowerSetupSteps';
import { PublicBorrowerSetupStepsWithIdVerification } from '../../../../../../libs/feature-borrowers/src/borrowerSetupToDo/components/withIdVerification/publicBorrowerSetupStepsWithIdVerification';
import { useStore } from '../../hooks';
import { ThankYou } from 'libs/feature-borrowers/src/borrowerSetupToDo/components/public/thankYou';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(3, 0, 4),
    borderRadius: theme.spacing(2),
  },
}));

export const PublicBorrowerSetupToDo = observer(() => {

  const classes = useStyles();
  const { publicBorrowerSetupToDoStore } = useStore();
  const { uuid } = useParams<{ uuid: string }>();

  useEffect(() => {
    publicBorrowerSetupToDoStore.reset();
    publicBorrowerSetupToDoStore.getBorrowerDetailsVerification(uuid);
  }, []);

  return (
    <>
      {publicBorrowerSetupToDoStore.borrowerDetailsVerification &&
        <Layout maxWidth={'lg'}>
          {publicBorrowerSetupToDoStore.finished ?
            <ThankYou />
            :
            publicBorrowerSetupToDoStore.showWelcome ?
              <>
                {publicBorrowerSetupToDoStore.borrowerDetailsVerification.borrowerSetupIdVerificationEnabled ?
                  <WelcomeWithIdVerification
                    disableStart={!(publicBorrowerSetupToDoStore.waitingAuthorizationState || publicBorrowerSetupToDoStore.backgroundCompletedState)}
                    onStart={() => publicBorrowerSetupToDoStore.setShowWelcome(false)}
                    borrowerName={`${publicBorrowerSetupToDoStore.personalInformationFormStore.form.fields.firstName.value} ${publicBorrowerSetupToDoStore.personalInformationFormStore.form.fields.lastName.value}`}
                  />
                  :
                  <Welcome
                    disableStart={!(publicBorrowerSetupToDoStore.waitingAuthorizationState || publicBorrowerSetupToDoStore.backgroundCompletedState)}
                    onStart={() => publicBorrowerSetupToDoStore.setShowWelcome(false)}
                  />
                }
              </>
              :
              <>
                <Box my={3}>
                  <Typography variant="h3" gutterBottom>
                    {'Tell us about yourself'}
                  </Typography>
                </Box>

                <Paper className={classes.paper}>
                  {publicBorrowerSetupToDoStore.borrowerDetailsVerification.borrowerSetupIdVerificationEnabled ?
                    <PublicBorrowerSetupStepsWithIdVerification store={publicBorrowerSetupToDoStore} />
                    :
                    <PublicBorrowerSetupSteps store={publicBorrowerSetupToDoStore} />
                  }
                </Paper>
              </>
          }
          {publicBorrowerSetupToDoStore?.loading && <VerticalBarLoader />}
        </Layout>
      }
    </>
  );
});