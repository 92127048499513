import { action, computed, makeObservable, observable } from 'mobx';
import { GlobalStore } from '@roc/feature-app-core';
import { SelectBorrowersDialogStore } from './selectBorrowersDialogStore';
import { BorrowerFormDialogStore } from './borrowerFormDialogStore';
import { BorrowerFormStore } from './borrowerFormStore';
import { Borrower } from '../types';
import { FormStore } from '@roc/feature-app-core';
import {
  prequalificationStatusProperties,
  getUniqueId,
  generateUUID,
} from '@roc/feature-utils';
import { BorrowerAccordionStore } from './borrowerAccordionStore';

export class SelectBorrowersStore {
  private globalStore: GlobalStore;
  selectBorrowersDialogStore: SelectBorrowersDialogStore;
  borrowerFormDialogStore: BorrowerFormDialogStore;
  borrowerAccordionStore: BorrowerAccordionStore;
  allowMultiple: boolean;
  isSelectBorrowersDialogOpen: boolean;
  isBorrowerFormDialogOpen: boolean;
  newBorrowers: Borrower[];
  existingBorrowers: Borrower[];
  currentBorrower: Borrower;
  canEditSelectedBorrowers: boolean;
  canRemoveSelectedBorrowers: boolean;
  private onBorrowersChanged: (borrowers: Borrower[]) => void;
  private processNewSelectedBorrower: (borrower: Borrower) => void;
  isLoanBeingEdit: boolean;

  constructor(
    globalStore: GlobalStore,
    borrowerFormStore?: FormStore,
    canEditSelectedBorrowers?: boolean,
    canRemoveSelectedBorrowers?: boolean,
    onBorrowersChanged?: (borrowers: Borrower[]) => void,
    processNewSelectedBorrower?: (borrower: Borrower) => void,
    createBorrowerForm?: () => BorrowerFormStore
  ) {
    this.onBorrowersChanged = onBorrowersChanged;
    this.processNewSelectedBorrower = processNewSelectedBorrower;
    this.globalStore = globalStore;
    this.selectBorrowersDialogStore = new SelectBorrowersDialogStore(
      this.globalStore,
      this.getExistingBorrowers.bind(this)
    );
    this.borrowerAccordionStore = new BorrowerAccordionStore(
      this.globalStore,
      this,
      createBorrowerForm
    );
    this.borrowerFormDialogStore = borrowerFormStore
      ? new BorrowerFormDialogStore(this.globalStore, borrowerFormStore)
      : null;
    this.isSelectBorrowersDialogOpen = false;
    this.isBorrowerFormDialogOpen = false;
    this.allowMultiple = true;
    this.newBorrowers = [];
    this.existingBorrowers = [];
    this.currentBorrower = null;
    this.canEditSelectedBorrowers = canEditSelectedBorrowers ?? true;
    this.canRemoveSelectedBorrowers = canRemoveSelectedBorrowers ?? true;
    this.isLoanBeingEdit = false;
    makeObservable(this, {
      reset: action,
      setAllowMultiple: action,
      setIsSelectBorrowersDialogOpen: action,
      setIsBorrowerFormDialogOpen: action,
      isSelectBorrowersDialogOpen: observable,
      isBorrowerFormDialogOpen: observable,
      allowMultiple: observable,
      canAddMore: computed,
      editBorrower: action,
      removeBorrower: action,
      saveBorrower: action,
      saveBorrowerValues: action,
      newBorrowers: observable,
      existingBorrowers: observable,
      setExistingBorrowers: action,
      setExistingBorrowersFromExternal: action,
      setNewBorrowersFromExternal: action,
      canEditSelectedBorrowers: observable,
      canRemoveSelectedBorrowers: observable,
      handlePreQualifyBorrower: action,
      currentBorrower: observable,
      setCurrentBorrower: action,
      isLoanBeingEdit: observable,
      setIsLoanBeingEdit: action
    });
  }

  reset() {
    this.isSelectBorrowersDialogOpen = false;
    this.isBorrowerFormDialogOpen = false;
    this.newBorrowers = [];
    this.existingBorrowers = [];
    this.currentBorrower = null;
    this.selectBorrowersDialogStore.reset();
    this.borrowerFormDialogStore?.reset();
    this.borrowerAccordionStore.reset()
    this.isLoanBeingEdit = false;
  }

  setAllowMultiple(allowMultiple: boolean) {
    this.allowMultiple = allowMultiple;
    this.selectBorrowersDialogStore.setAllowMultiple(allowMultiple);
  }

  setIsSelectBorrowersDialogOpen(isOpen: boolean) {
    this.isSelectBorrowersDialogOpen = isOpen;
  }

  setIsBorrowerFormDialogOpen(isOpen: boolean) {
    this.isBorrowerFormDialogOpen = isOpen;
  }

  setIsLoanBeingEdit(isBeingEdit: boolean) {
    this.isLoanBeingEdit = isBeingEdit
  }

  get borrowers(): Borrower[] {
    return [...this.existingBorrowers, ...this.newBorrowers].map(borrower => {
      return {
        ...borrower,
        showDelete: borrower.showDelete ?? this.canRemoveSelectedBorrowers,
        showEdit: borrower.showEdit ?? this.canEditSelectedBorrowers,
      };
    });
  }

  get canAddMore() {
    return this.allowMultiple || this.borrowers.length === 0;
  }

  addNewBorrower() {
    this.currentBorrower = null;
    this.borrowerFormDialogStore?.reset();
    this.setIsBorrowerFormDialogOpen(true);
  }

  editBorrower(borrower: Borrower) {
    this.currentBorrower = borrower;
    this.borrowerFormDialogStore?.reset();
    this.borrowerFormDialogStore?.setBorrower(borrower);
    this.setIsBorrowerFormDialogOpen(true);
  }

  private removeOrReplaceBorrower(borrower: Borrower, replacement?: Borrower) {
    const source = !borrower.borrowerId
      ? this.newBorrowers
      : this.existingBorrowers;
    const index = source.findIndex(
      sourceBorrower => sourceBorrower.tempId === borrower.tempId
    );
    if (replacement) {
      source.splice(index, 1, replacement);
    } else {
      source.splice(index, 1);
    }
  }

  removeBorrower(borrower: Borrower) {
    this.removeOrReplaceBorrower(borrower);
    this.notifyBorrowersChange();
  }

  setExistingBorrowersFromExternal(borrowers: Borrower[]) {
    this.existingBorrowers = [...borrowers.map(b => this.normalize(b))];
  }

  setNewBorrowersFromExternal(borrowers: Borrower[]) {
    this.newBorrowers = [...borrowers.map(b => this.normalize(b))];
  }

  setExistingBorrowers() {
    const allBorrowers = this.selectBorrowersDialogStore.selectedBorrowers.map(
      b => this.normalize(b)
    );

    const newSelectedBorrowers = [];
    const oldBorrowers = [];
    const existingBorrowersIds = this.existingBorrowers.map(
      borrower => borrower.tempId
    );
    allBorrowers.forEach(borrower =>
      existingBorrowersIds.includes(borrower.tempId)
        ? oldBorrowers.push(borrower)
        : newSelectedBorrowers.push(borrower)
    );

    this.existingBorrowers = [
      ...oldBorrowers,
      ...newSelectedBorrowers.map(borrower =>
        this.processNewSelectedBorrower
          ? this.processNewSelectedBorrower(borrower)
          : borrower
      ),
    ];
    this.setIsSelectBorrowersDialogOpen(false);
    this.notifyBorrowersChange();
  }

  addNewBorrowerFromDB(borrower: Borrower) {
    const normalizedBorrower = this.normalize(borrower);

    const filteredExistingBorrowers = this.existingBorrowers.filter(
      currentBorrower => currentBorrower.emailAddress !== borrower.emailAddress
    );

    this.existingBorrowers = [...filteredExistingBorrowers, normalizedBorrower];

    this.notifyBorrowersChange();
  }

  saveBorrower(onSuccess?: () => void) {
    const allPropsBorrower = this.saveBorrowerValues(
      this.currentBorrower,
      this.borrowerFormDialogStore?.getBorrower()
    );
    if (onSuccess) {
      this.currentBorrower = allPropsBorrower;
      onSuccess();
    } else {
      this.setIsBorrowerFormDialogOpen(false);
    }
    this.notifyBorrowersChange();
  }

  saveBorrowerValues(currentBorrower: Borrower, values: Borrower) {
    const borrower = this.normalize(values);
    let allPropsBorrower = borrower;

    if (currentBorrower) {
      // Is edit
      // If there's a borrower id, it's an existing borrower
      allPropsBorrower = Object.assign({}, currentBorrower, borrower);
      this.removeOrReplaceBorrower(currentBorrower, allPropsBorrower);
    } else {
      this.newBorrowers.push(allPropsBorrower);
    }
    return allPropsBorrower;
  }

  private getExistingBorrowers() {
    return this.existingBorrowers;
  }

  private notifyBorrowersChange() {
    if (!this.onBorrowersChanged) return;
    this.onBorrowersChanged(this.borrowers);
  }

  private normalize(borrower) {
    return {
      ...borrower,
      tempId: !borrower.tempId ? getUniqueId() : borrower.tempId,
      loanApplicationUuid: borrower.loanApplicationUuid ?? generateUUID(),
    };
  }

  handlePreQualifyBorrower(borrower, preQualifyFormValues) {
    borrower.creditBackgroundCheck = preQualifyFormValues.creditBackgroundCheck;
    borrower.reissueRequested = !preQualifyFormValues.creditBackgroundCheck;

    this.removeOrReplaceBorrower(borrower, borrower);
  }

  setCurrentBorrower(borrower) {
    this.currentBorrower = borrower;
  }
}

export default SelectBorrowersStore;
