// ----------------------------------------------------------------------

export default function Card(theme) {
  return {
    MuiCard: {
      root: {
        boxShadow: theme.customShadows.card,
        borderRadius: Number(theme.shape.borderRadius) * 2,
        position: 'relative',
        zIndex: 0, // Fix Safari overflow: hidden with border radius
      },
    },
    MuiCardHeader: {
      root: {
        padding: theme.spacing(3, 3, 0),
      },
    },
    MuiCardContent: {
      root: {
        padding: theme.spacing(3),
      },
    },
  };
}
