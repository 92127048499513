import { Box, Divider, Grid, lighten, makeStyles, Theme, Typography, useTheme } from '@material-ui/core';
import { LoginLogoImg, Page } from '@roc/feature-app-core';
import { Paper, PaperBorderType, PreviewBackdrop } from '@roc/ui';
import clsx from 'clsx';
import jsPDF from 'jspdf';
import { useEffect, useRef, useState } from 'react';
import { useDocumentLibraryRoutes } from './../../hooks/useDocumentLibraryRoutes';
const useStyles = makeStyles((theme: Theme) => ({
  documentContainer: {
    position: 'relative',
    height: '100%',
    padding: '16px',
    background: '#FFF',
    fontSize: 16,
    '& table': {
      width: '100%',
      textAlign: 'center',
      borderCollapse: 'collapse',
    },
    '& table td': {
      padding: '2%',
      textAlign: 'center',
      borderBottom: '0.25px solid black',
      color: lighten(theme.palette.primary.main, 0.5),
    },
    '& table tr': {
      color: lighten(theme.palette.primary.main, 0.5),
    },
    '& ul,ol': {
      marginLeft: theme.spacing(3),
      marginBottom: theme.spacing(0.5),
    },
    '& h6': {
      fontSize: 16,
      padding: 4,
      fontWeight: 'bold',
    },
  },
  title: {
    minWidth: 0,
    justifyContent: 'center',
    textAlign: 'justify',
    color: theme.palette.primary.main,
  },
  overlay: {
    padding: '4px',
    fontSize: '35px',
    fontWeight: 'initial',
    position: 'absolute',
    margin: '70px',
    maxWidth: '480px',
    color: '#444444'
  },
  documentCard: {
    borderRadius: 0,
    marginBottom: theme.spacing(1),
    borderColor: '#aaa',
  },
  documentCardTitle: {
    justifyContent: 'center',
    textAlign: 'center',
    background: lighten(theme.palette.primary.main, 0.5),
    fontWeight: 'bold',
    padding: theme.spacing(0.5),
  }
}));
const usePrintModeStyles = makeStyles((theme: Theme) => ({
  documentContainer: {
    fontSize: 12,
    '& ul,ol': {
      marginLeft: theme.spacing(2),
    },
    '& table td': {
      padding: '2%',
    },
    '& h6': {
      fontSize: 14,
    },
  },
}));
const DocumentCard = ({
  title = undefined,
  data,
  paperBorder = PaperBorderType.SOLID_BORDER,
  paperClassName = '',
  paperTitleClassName = '',
  paperBodyClassName = '',
  paperStyle = {},
}) => {
  const classes = useStyles();
  return (
    <Paper
      title={title}
      titleClass={clsx(classes.documentCardTitle, paperTitleClassName)}
      titleVariant={'h6'}
      borderType={paperBorder}
      className={clsx(classes.documentCard, paperClassName)}
      style={paperStyle}>
      <Box p={0.5} className={paperBodyClassName}>
        {data}
      </Box>
    </Paper>
  )
}
export const ScopeOfWorkAndDrawsTeachingVideosHtml = ({
  contentId,
  data,
  preview = false,
  printMode = false,
  setContainerRef,
  allowPrint = false
}: {
  contentId: string,
  data: any,
  preview?: boolean,
  printMode?: boolean,
  setContainerRef: any,
  allowPrint?: boolean
}) => {
  const theme = useTheme();
  const { documentLibraryRoutesConfig } = useDocumentLibraryRoutes();
  const classes = useStyles();
  const printModeClasses = usePrintModeStyles();
  const containerRef = useRef(null);
  const [printModeContainerStyle, setPrintModeContainerStyle] = useState<any>();
  const [containerStyle, setContainerStyle] = useState<any>();

  const [whereToFindSowPlaying, setWhereToFindSowPlaying] = useState(false);
  const [howToReviseSowPlaying, setHowToReviseSowPlaying] = useState(false);
  const [howToInitializeAndShareWithBorrowerPlaying, setHowToInitializeAndShareWithBorrowerPlaying] = useState(false);
  const [howToUsePlaying, setHowToUsePlaying] = useState(false);
  const [howToRequestADrawPlaying, setHowToRequestADrawPlaying] = useState(false);
  const [howToShareTheDrawRequestFormPlaying, setHowToShareTheDrawRequestFormPlaying] = useState(false);

  useEffect(() => {
    const doc = new jsPDF("p", "mm", "a4");
    let width: any = doc.internal.pageSize.getWidth();
    let height: any = doc.internal.pageSize.getHeight();
    width = `${Math.floor(width)}mm`;
    height = `${Math.floor(height)}mm`;
    setPrintModeContainerStyle({
      margin: 'auto',
      minWidth: width,
      maxWidth: width,
      height: height
    });
    setContainerStyle({
      margin: 'auto',
      maxWidth: theme.breakpoints.values.lg,
    });
  }, []);
  useEffect(() => {
    setContainerRef(containerRef);
  }, [containerRef])
  if (!data) {
    return null;
  }

  const toggleVideoPlaying = (videoPlaying, setPlayingState) => {
    if (!videoPlaying) {
      setPlayingState(!videoPlaying)
    }
  };

  return (
    <div ref={containerRef} style={printMode ? printModeContainerStyle : containerStyle}>
      <Page routeDefinition={documentLibraryRoutesConfig.documentLibraryDocument(contentId)}>
        <Box className={clsx(classes.documentContainer, printMode ? printModeClasses.documentContainer : undefined)}>
          {preview && <PreviewBackdrop />}
          <LoginLogoImg imgStyle={{ maxWidth: 200 }} />
          <Box my={2} textAlign='center'>
            <Typography variant={'h5'}>
              <p style={{ color: 'grey' }}>{data.scopeOfWorkAndDrawsTitle}</p>
            </Typography>
            <Typography variant={'h4'}>
              <strong>{data.teachingVideosTitle}</strong>
            </Typography>
            <Divider style={{ marginTop: "1%", marginRight: "5.3%", marginLeft: "5.3%", color: lighten(theme.palette.primary.main, 0.5) }}></Divider>
            <p style={{ fontSize: "12px" }}>{data.accessVideoHeader}</p>
          </Box>
          <Box mb={1} >
            <Grid container spacing={3}>
              <Grid item lg={12} sm={12}>
                <Typography className={classes.title} variant={'h5'}>
                  {data.scopeOfWorkTitle}
                </Typography>
                <Divider style={{ marginTop: "1%", width: "100%", color: lighten(theme.palette.primary.main, 0.5) }}></Divider>
              </Grid>

              <Grid item lg={6} sm={12}>
                <DocumentCard data={<>
                  {!whereToFindSowPlaying &&
                    <Typography variant={'h5'} className={classes.overlay} >
                      {data.whereToFindSowTitle}
                    </Typography>
                  }
                  <video onClick={() => toggleVideoPlaying(whereToFindSowPlaying, setWhereToFindSowPlaying)}
                    controls poster={data.videoThumbnail.fields.file.url} width="100%" height="100%">
                    <source src={data.whereToFindSowLink} type="video/mp4" />
                  </video></>}
                  paperBorder={PaperBorderType.NO_BORDER} />
              </Grid>
              <Grid item lg={6} sm={12}>
                <DocumentCard data={<>
                  {!howToReviseSowPlaying &&
                    <Typography variant={'h5'} className={classes.overlay} >
                      {data.howToReviseSowTitle}
                    </Typography>
                  }
                  <video onClick={() => toggleVideoPlaying(howToReviseSowPlaying, setHowToReviseSowPlaying)} controls poster={data.videoThumbnail.fields.file.url} width="100%" height="100%">
                    <source src={data.howToReviseSowLink} type="video/mp4" />
                  </video></>}
                  paperBorder={PaperBorderType.NO_BORDER} />

              </Grid>
              <Grid item lg={6} sm={12}>
                <DocumentCard data={<>
                  {!howToInitializeAndShareWithBorrowerPlaying &&
                    <Typography variant={'h5'} className={classes.overlay} >
                      {data.howToInitializeAndShareWithBorrowerTitle}
                    </Typography>
                  }
                  <video onClick={() => toggleVideoPlaying(howToInitializeAndShareWithBorrowerPlaying, setHowToInitializeAndShareWithBorrowerPlaying)} controls poster={data.videoThumbnail.fields.file.url} width="100%" height="100%">
                    <source src={data.howToInitializeAndShareWithBorrowerLink} type="video/mp4" />
                  </video></>}
                  paperBorder={PaperBorderType.NO_BORDER} />

              </Grid>
              <Grid item lg={6} sm={12}>
                <DocumentCard data={<>
                  {!howToUsePlaying &&
                    <Typography variant={'h5'} className={classes.overlay} >
                      {data.howToUseTitle}
                    </Typography>
                  }
                  <video onClick={() => toggleVideoPlaying(howToUsePlaying, setHowToUsePlaying)} controls poster={data.videoThumbnail.fields.file.url} width="100%" height="100%">
                    <source src={data.howToUseLink} type="video/mp4" />
                  </video></>}
                  paperBorder={PaperBorderType.NO_BORDER} />

              </Grid>
            </Grid>
          </Box>
          <Box mb={1} >
            <Grid container spacing={3}>
              <Grid item lg={12} sm={12}>
                <Typography className={classes.title} variant={'h5'}>
                  {data.drawTitle}
                </Typography>
                <Divider style={{ marginTop: "1%", width: "100%", color: lighten(theme.palette.primary.main, 0.5) }}></Divider>
              </Grid>

              <Grid item lg={6} sm={12}>
                <DocumentCard data={<>
                  {!howToRequestADrawPlaying &&
                    <Typography className={classes.overlay} variant={'h5'}>
                      {data.howToRequestADrawTitle}
                    </Typography>
                  }
                  <video onClick={() => toggleVideoPlaying(howToRequestADrawPlaying, setHowToRequestADrawPlaying)} controls poster={data.videoThumbnail.fields.file.url} width="100%" height="100%">
                    <source src={data.howToRequestADrawLink} type="video/mp4" />
                  </video></>}
                  paperBorder={PaperBorderType.NO_BORDER} />
              </Grid>
              <Grid item lg={6} sm={12}>
                <DocumentCard data={<>
                  {!howToShareTheDrawRequestFormPlaying &&
                    <Typography variant={'h5'} className={classes.overlay} >
                      {data.howToShareTheDrawRequestFormTitle}
                    </Typography>
                  }
                  <video onClick={() => toggleVideoPlaying(howToShareTheDrawRequestFormPlaying, setHowToShareTheDrawRequestFormPlaying)} controls poster={data.videoThumbnail.fields.file.url} width="100%" height="100%">
                    <source src={data.howToShareTheDrawRequestFormLink} type="video/mp4" />
                  </video></>}
                  paperBorder={PaperBorderType.NO_BORDER} />

              </Grid>
            </Grid>
          </Box>
        </Box>
      </Page>
    </div>
  );
}