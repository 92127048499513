import { useState } from 'react';
import { ExpandMore } from '@material-ui/icons';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core';
import { TitleDivider } from '../../../components/titleDivider';
import { TableHead } from '@material-ui/core';
import { Button } from '@roc/ui';
import { Add } from '@material-ui/icons';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core';

import { QuoteTypeStore } from '../../../stores/quoteTypeStore';
import { FeeItem } from './feeItem';
import { OriginationPointsItem } from './originationPointsItem';
import { useBaseStore } from '@roc/feature-app-core';
import { feeOptions } from '../../../utils/constants';

interface PointsAndFeesAccordionProps {
  store: QuoteTypeStore;
}

const useStyles = makeStyles(() => ({
  noBorders: {
    border: 'none',
  },
  headerCell: {
    color: '#9b9ea3',
    fontWeight: 'bold',
  },
}));

export const PointsAndFeesAccordion = observer(
  ({ store }: PointsAndFeesAccordionProps) => {
    const { loanEconomicsStore } = store;
    const { globalStore } = useBaseStore();
    const [expanded, setExpanded] = useState(true);
    const classes = useStyles();

    function handleDeleteFee(feeKey: string) {
      loanEconomicsStore.removeFee(feeKey);
    }

    return (
      <Accordion
        expanded={expanded}
        onChange={(e, expanded) => setExpanded(expanded)}
        elevation={0}
      >
        <AccordionSummary
          expandIcon={<ExpandMore fontSize="large" />}
          aria-controls="point-fees-accordion"
        >
          <TitleDivider title="Points and Fees" />
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{ width: '20%' }}
                    className={classes.headerCell}
                  >
                    Fee Name
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ width: '20%' }}
                    className={classes.headerCell}
                  ></TableCell>
                  <TableCell
                    align="center"
                    style={{ width: '20%' }}
                    className={classes.headerCell}
                  >
                    Capital Provider
                  </TableCell>
                  {!globalStore?.lenderInfo?.isInternal &&
                    <TableCell
                      align="center"
                      style={{ width: '20%' }}
                      className={classes.headerCell}
                    >
                      {globalStore.lenderInfo.lenderTradeName}
                    </TableCell>
                  }
                  <TableCell
                    style={{ width: '20%' }}
                    className={classes.headerCell}
                  ></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <OriginationPointsItem store={store} />
                {loanEconomicsStore.feeStores.map( (feeStore, index) => (
                  <FeeItem
                    key={feeStore.key}
                    store={feeStore}
                    feeSelectionDisabled = {index < loanEconomicsStore.feeStores.length - 1}
                    onDeleteFee={handleDeleteFee}
                    feeOptions={loanEconomicsStore.getCurrentFeesOptions(feeStore)}
                  />
                ))}
                {
                  (loanEconomicsStore.feeStores.length < feeOptions.length - 1 ) &&
                    (<TableRow>
                      <TableCell
                        style={{ width: '20%' }}
                        className={classes.noBorders}
                      >
                        <Button
                          size="medium"
                          variant="outlined"
                          color="primary"
                          startIcon={<Add />}
                          testId="add-fee"
                          fullWidth
                          onClick={() => loanEconomicsStore.addNewFee()}
                        >
                          ADD FEE
                        </Button>
                      </TableCell>
                  </TableRow> )
                }

              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
    );
  }
);
