import {
  Slider as MuiSlider,
  SliderProps as MuiSliderProps,
  Typography,
} from '@material-ui/core';
import { withTestDataAttribute } from '../testDataAttribute';
import { ReactNode } from 'react';

export type SliderProps = MuiSliderProps & {
  standaloneLabel?: boolean;
  label?: string | ReactNode;
  testId: string;
};

export const Slider = withTestDataAttribute((props: SliderProps) => {
  const { standaloneLabel, label, ...muiComponentProps } = props;
  return (
    <>
      {standaloneLabel && label ? <Typography>{label}</Typography> : null}
      <MuiSlider {...muiComponentProps} />
    </>
  );
});
