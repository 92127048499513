import { action, flow, makeObservable } from "mobx";
import { ScopeOfWorkRevisionFormStore } from ".";
import { ApiResponse, GlobalStore } from '@roc/feature-app-core';
import { ScopeOfWorkRevisionService } from "../services";
import { ScopeOfWorkV2Store } from "@roc/feature-sow-shared/stores/v2";
import { LoanStore } from '@roc/feature-loans';
import { DrawStore } from "@roc/feature-draws";

export class ScopeOfWorkRevisionStore extends ScopeOfWorkV2Store {

  private loanStore: LoanStore;
  protected scopeOfWorkService: ScopeOfWorkRevisionService;
  private drawStore: DrawStore;

  constructor(globalStore: GlobalStore, loanStore: LoanStore, drawStore: DrawStore) {
    super(globalStore);
    this.scopeOfWorkService = new ScopeOfWorkRevisionService();
    super.scopeOfWorkService = this.scopeOfWorkService;
    this.scopeOfWorkV2FormStore = new ScopeOfWorkRevisionFormStore(globalStore, drawStore, loanStore, this.closeDialog);
    this.loanStore = loanStore;
    this.drawStore = drawStore;
    makeObservable(this, {
      submitSOWRevision: flow,
      fetchSOWRevision: flow,
      fetchSOW: flow,
      closeDialog: action,
    })
  }

  *submitSOWRevision(document) {
    try {
      const jsonForm = {
        loanId: this.loanStore.loanDetails.loanId,
        propertyId: document.collateralId,
        drawType: 'regular',
      };
      const response: ApiResponse = yield this.scopeOfWorkService.submitSOWRevision(
        jsonForm
      );
      const responseData = response.data;
      if (responseData.status === 'OK') {
        this.globalStore.notificationStore.showSuccessNotification({
          message: 'SOW Revision request was created successfully',
        });
        this.drawStore.fetchSOWDocuments(jsonForm.loanId);
      } else {
        this.globalStore.notificationStore.showErrorNotification({
          message: responseData?.error?.message,
        });
      }
    } catch (error) {
      console.log({ error });
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while submitting SOW revision request.',
      });
    }
  }

  *fetchSOWRevision(document) {
    const { data: response } = yield this.scopeOfWorkService.getSOWRevision(
      document.drawId
    );
    const scopeOfWork = JSON.parse(response.data.dataContent);
    const params = {
      scopeOfWorkId: scopeOfWork.scopeOfWorkId,
      isInternal: true,
      version: scopeOfWork.version,
      drawId: document.drawId,
      isRevisionSOW: true,
    };
    this.scopeOfWorkV2FormStore.setParams(params);
    this.scopeOfWorkV2FormDialogOpen = true;
  }

  *fetchSOW(document) {
    const { data: response } = yield this.scopeOfWorkService.getScopeOfWork(
      document.loanId,
      document.collateralId
    );
    const scopeOfWork = JSON.parse(response.data);
    const params = {
      scopeOfWorkId: scopeOfWork.scopeOfWorkId,
      isInternal: true,
      version: scopeOfWork.version,
    };
    this.scopeOfWorkV2FormStore.setParams(params);
    this.scopeOfWorkV2FormDialogOpen = true;
  }

  closeDialog = () => {
    this.scopeOfWorkV2FormDialogOpen = false;
  }
}