export const formatNegativeCurrency = (number: number, isNegative: boolean) => {
  const formattedValue = formatCurrency((isNegative ? -1 : 1) * number);
  return !isNegative ? formattedValue : `(${formattedValue})`;
};

export const formatCurrency = (number, digits = 2) =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: digits,
  }).format(number);

export const formatPercentage = (value, digits = 2) =>
  value != null
    ? new Intl.NumberFormat('en-US', {
      style: 'percent',
      maximumFractionDigits: digits || 2,
    }).format(value / 100)
    : '';

export const showPercentage = value => {
  return value && value ? formatPercentage(value, 3) : 'N/A';
};

export const getBoolean = (value: string) => {
  return value === 'Y';
};

export const getValues = (options: any[]) => {
  const values = options ? options.map(option => option.value) : [];
  return values.join(',');
};

export const getRocPointsIn = (loanDetails: any): number => {
  const rocPointsIn = parseFloat(loanDetails?.rocPointsIn ?? '0');
  const retailPoints = parseFloat(loanDetails?.loanRetailFees?.retailPoints ?? '0');
  return rocPointsIn + retailPoints;
}

export const formatNumberWithCommas = (num) => {
  if (num) {
    return num.toLocaleString('en-US');
  }
  return num;
}