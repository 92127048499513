import { useLoanStore } from '@roc/feature-loans';
import { CreateAppraisalForm } from '@roc/feature-loans';
import { green, red } from '@material-ui/core/colors';
import { useState } from 'react';
import { Button, StandardDialog } from '@roc/ui';
import { Box, Grid } from '@material-ui/core';

export const LoanSummaryCreateAppraisalButton = () => {
  const { loanStore } = useLoanStore();

  const isCreateAppraisalOrderAllowed = () =>
    !!loanStore.loanDetails?.enableAppraisalCreation && !loanStore.loanDetails?.submitAppraisalWithTamarisk && !loanStore.loanDetails?.arrEnabled;

  const [openCreateAppraisalModal, setOpenCreateAppraisalModal] = useState(
    false
  );

  const loanId = loanStore.loanDetails?.loanId;

  return (
    <>
      {isCreateAppraisalOrderAllowed() && (
        <Grid item xs={12}>
          <Box display="flex" justifyContent="flex-end">
            <Button
              variant="contained"
              size="small"
              onClick={() => setOpenCreateAppraisalModal(true)}
              color="primary"
              testId="create_appraisal_order"
            >
              Create Appraisal Order
            </Button>
          </Box>
        </Grid>
      )}
      <StandardDialog
        open={openCreateAppraisalModal}
        title="Create Appraisal Order"
        maxWidth="md"
        handleClose={() => setOpenCreateAppraisalModal(false)}
        dialogContent={
          <CreateAppraisalForm
            onConfirm={(gfdPayment: boolean) => {
              setOpenCreateAppraisalModal(false);
              loanStore.submitLoanAppraisalOrder(loanId, gfdPayment);
            }}
            onClose={() => setOpenCreateAppraisalModal(false)}
          />
        }
      />
    </>
  );
};
