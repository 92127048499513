import { makeStyles } from "@material-ui/core/styles";
import { useBaseStore, useUserStore } from "@roc/feature-app-core";
import { observer } from "mobx-react";
import { Card } from '@roc/ui';
import { Box, Grid, Checkbox, FormControlLabel } from '@material-ui/core';
import { TreeItem, TreeView } from "@material-ui/lab";
import { ExpandMore, ChevronRight } from "@material-ui/icons";
import { isNil } from "@roc/feature-utils";
import { useEffect, useState } from 'react';
import { getDefaultAllowedChatroom } from "./defaultChatrooms";

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(2),
    height: "100%"
  },
  conversationMentionsBox: {
    marginLeft: "23px",
  }
}));

export const UserPreferenceCardContent = observer(() => {
  const classes = useStyles();
  const { userStore } = useUserStore();
  const { globalStore } = useBaseStore();
  const [mentionsNotifications, setMentionsNotifications] = useState(false);
  const defaultChatRooms = getDefaultAllowedChatroom(globalStore.userFeatures)

  const saveMessagesNotifications = (allowNotifications) => {

    if (allowNotifications) {
      userStore.saveUserPreference({ allowedChatRoomTypes: defaultChatRooms });
    } else {
      userStore.saveUserPreference({ allowedChatRoomTypes: [] });
    }
  }

  const isTypeChecked = (chatRoomType) => {
    return (userStore.userInformation?.userPreference.allowedChatRoomTypes === null || userStore.userInformation?.userPreference.allowedChatRoomTypes.includes(chatRoomType));
  };

  const checkChatRoomType = (chatRoomType, checked) => {
    const currentTypes = userStore.userInformation.userPreference.allowedChatRoomTypes
      ? userStore.userInformation.userPreference.allowedChatRoomTypes
      : defaultChatRooms;

    const types = checked
      ? [...currentTypes, chatRoomType]
      : currentTypes.filter(t => t !== chatRoomType);

    userStore.updateNotificationsPreferences(types);
  };

  const allConversationsCheck = (
    isNil(userStore.userInformation.userPreference.allowedChatRoomTypes) ||
    (userStore.userInformation.userPreference.allowedChatRoomTypes.length === defaultChatRooms.length &&
      userStore.userInformation.userPreference.allowedChatRoomTypes.every((val, index) => val === defaultChatRooms[index]))
  )

  const handleConversatonMentionsChange = () => {
    const newMentionsNotifications = !mentionsNotifications;
    setMentionsNotifications(newMentionsNotifications);
    userStore.saveUserPreference({
      mentionsNotifications: newMentionsNotifications
    });
  };

  useEffect(() => {
    const mentionsNotifications = userStore.userInformation?.userPreference?.mentionsNotifications === null ? true : userStore.userInformation?.userPreference?.mentionsNotifications;
    if (mentionsNotifications) {
      setMentionsNotifications(true);
    } else {
      setMentionsNotifications(false);
    }
  }, [userStore.userInformation?.userPreference?.mentionsNotifications]);


  return (
    <Box>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} md={12}>
          Select the conversations you want to receive notifications from
        </Grid>
        <Grid item xs={12} md={12}>
          <Grid container direction="column">
            <Grid item>
              <Grid container alignItems="center">
                <Grid item className={classes.conversationMentionsBox}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={mentionsNotifications}
                        onChange={handleConversatonMentionsChange}
                      />
                    }
                    label="Conversation Mentions"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <TreeView
                defaultCollapseIcon={<ExpandMore />}
                defaultExpandIcon={<ChevronRight />}
                defaultExpanded={['enableNotifications']}
              >
                <TreeItem nodeId="enableNotifications"
                  label={
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={allConversationsCheck}
                          onChange={(e) => saveMessagesNotifications(e.target.checked)}
                        />
                      }
                      label="All Conversations"
                    />
                  }>
                  {defaultChatRooms.map(chatRoomType => {
                    const chatRoomName = chatRoomType.toLowerCase().replace(/_/g, ' ');
                    return (
                      <TreeItem nodeId={chatRoomType}
                        label={
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={isTypeChecked(chatRoomType)}
                                onChange={e => checkChatRoomType(chatRoomType, e.target.checked)}
                              />
                            }
                            label={chatRoomName.charAt(0).toUpperCase() + chatRoomName.slice(1)}
                          />
                        } />
                    )
                  })}
                </TreeItem>
              </TreeView>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );

});


export const UserPreferenceCard = observer(({
  title = "NOTIFICATION PREFERENCES"
}) => {
  const classes = useStyles();
  return (
    <>
      <Card title={title} className={classes.paper}>
        <UserPreferenceCardContent />
      </Card>
    </>
  );
});