import { Box, Grid, List } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { BorrowerPortalCard } from '@roc/feature-borrower-portal-core';
import { observer } from 'mobx-react';
import {
  Dividers,
  EntityAndBorrowersCard,
  listItem,
} from './borrowerLoanOverviewComponents';
import {
  LoanDetailsStepper,
  stepsByLoanStatus,
} from '@roc/feature-loan-details';
import { useBorrowerLoanApplicationsStore } from 'libs/feature-borrower-loans/src/hooks/useBorrowerLoanApplicationsStore';
import { format } from 'date-fns';
import { PropertiesCardGrid } from './borrowerLoanOverviewTermLoans';
import { PropertiesCard } from '../properties/propertiesCard';
import { BorrowerTermPropertiesGrid } from './borrowerTermPropertiesGrid';
import { BorrowerLoanApplicationTermPropertiesGrid } from './borrowerLoanApplicationTermPropertiesGrid';

const useStyles = makeStyles(theme => ({
  height100Percent: {
    height: '100%',
  },
}));

export const BorrowerLoanOverviewTermLoanApplications = observer(() => {
  const classes = useStyles();
  const { borrowerLoanApplicationsStore } = useBorrowerLoanApplicationsStore();
  const { loanDetails } = borrowerLoanApplicationsStore;

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <LoanDetailsStepper
            stepsByStatus={stepsByLoanStatus}
            currentStatus={loanDetails?.status}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <KeyInformation loanDetails={loanDetails} />
        </Grid>
        <Grid item xs={12} md={6}>
          <KeyDatesCard loanDetails={loanDetails} />
        </Grid>
      </Grid>
      <Box mt={1}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <EntityAndBorrowersCard
              entity={loanDetails?.loanBorrowerInformation?.borrowerEntity}
              borrowers={
                loanDetails?.loanBorrowerInformation?.loanBorrowers?.rows
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <BorrowerPortalCard
              cardTitle="Properties"
              cardRootClassName={classes.height100Percent}
              noPaddingCardContent
            >
              <BorrowerLoanApplicationTermPropertiesGrid
                gridStore={borrowerLoanApplicationsStore.propertiesGridStore}
              />
            </BorrowerPortalCard>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
});

const KeyInformation = ({ loanDetails }) => {
  const classes = useStyles();
  return (
    <BorrowerPortalCard
      cardTitle="Key Information"
      cardRootClassName={classes.height100Percent}
      noPaddingCardContent
    >
      <List>
        <Dividers variant="fullWidth" component="li">
          {listItem('Loan Type', loanDetails?.loanType)}
          {listItem('Loan Subtype', loanDetails?.loanSubtype)}
        </Dividers>
      </List>
    </BorrowerPortalCard>
  );
};

const KeyDatesCard = ({ loanDetails }) => {
  const classes = useStyles();
  return (
    <BorrowerPortalCard
      cardTitle="Key Dates"
      cardRootClassName={classes.height100Percent}
      noPaddingCardContent
    >
      <List>
        <Dividers variant="fullWidth" component="li">
          {listItem(
            'Submission',
            loanDetails?.creationDate
              ? format(new Date(loanDetails?.creationDate), 'MM/dd/yyyy')
              : '-'
          )}
        </Dividers>
      </List>
    </BorrowerPortalCard>
  );
};
