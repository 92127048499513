import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import {
  AuthorizedSignatory,
  BorrowerExperienceRealState,
  CellPhone,
  EmailAddressDuplicated,
  FirstName,
  LastName,
  OwnershipNotKnown,
  PercentOwnership,
  PersonalGuarantor,
  RoleInTransaction,
} from '@roc/ui/formComponents';
import { FormStore } from '@roc/feature-app-core';
import { useBaseStore } from '@roc/feature-app-core';
import { observer } from 'mobx-react';
import React from 'react';
import { LoanStore } from '../../stores/loanStore';
import { Borrower } from '@roc/feature-types';

interface BorrowerFormProps {
  store: FormStore;
  loanStore: LoanStore;
  currentBorrower?: Borrower;
}

const useStyles = makeStyles(theme =>
  createStyles({
    slider: {
      marginTop: '2.5em',
      marginLeft: '2em',
      width: '85%',
    },
    paragraph: {
      marginLeft: '2em',
    },
    textItalic: {
      fontStyle: 'italic',
    },
  })
);

export const BorrowerFormLoan = observer(
  ({ store, loanStore, currentBorrower }: BorrowerFormProps) => {
    const classes = useStyles();
    const { globalStore } = useBaseStore();

    currentBorrower = currentBorrower ?? loanStore.selectBorrowersStore.currentBorrower;

    const checkBorrowerExistingInFunded =
      currentBorrower?.portalProjectsCompleted > 0 ||
      currentBorrower?.portalProjectsActive > 0;
    const disableEdit =
      !globalStore.userFeatures?.addNewBorrowerDuringSubmission ||
      checkBorrowerExistingInFunded;

    const validExistingEmail = email => {
      return (
        globalStore.userFeatures?.allowBorrowerDuplicateEmail ||
        !loanStore.selectBorrowersStore.borrowers
          .filter(borrower => borrower.tempId != currentBorrower?.tempId)
          .some(borrower => borrower.emailAddress === email)
      );
    };

    return (
      <Grid container justifyContent={'center'} spacing={2}>
        <Grid item xs={12}>
          <FirstName store={store} disabled={disableEdit} />
        </Grid>
        <Grid item xs={12}>
          <LastName store={store} disabled={disableEdit} />
        </Grid>
        <Grid item xs={12}>
          <EmailAddressDuplicated
            store={store}
            disabled={disableEdit && !!currentBorrower.emailAddress}
            onChanged={(name, value) =>
              store.onFieldChange(
                'emailAddressDuplicated',
                validExistingEmail(value)
              )
            }
          />
        </Grid>
        <Grid item xs={12}>
          <CellPhone store={store} disabled={disableEdit} />
        </Grid>
        <Grid item xs={12}>
          <PersonalGuarantor store={store} />
        </Grid>
        <Grid item xs={12}>
          <AuthorizedSignatory store={store} />
        </Grid>
        <Grid item xs={12}>
          <BorrowerExperienceRealState store={store} />
        </Grid>
        <Grid item xs={12}>
          <PercentOwnership store={store} disableEdit={false} />
        </Grid>
        <Grid item xs={12} justifyContent="flex-end">
          <OwnershipNotKnown store={store} />
        </Grid>
      </Grid>
    );
  }
);
