import { action, computed, flow, makeObservable, observable } from 'mobx';
import { GlobalStore } from '@roc/feature-app-core';
import { DocumentStore } from '@roc/feature-documents';
import { LoanStore, canSubmitAppraisalWithTamarisk } from '@roc/feature-loans';
import { LoanFastrackPaymentService } from '../../services/documentForms/loanFastrackPaymentService';
import { ApiResponse } from '@roc/client-portal-shared/services';
import { FileUpload } from '@roc/ui';
import { PROPERTY_TYPES_RUSHED_APPRAISAL } from '@roc/feature-utils';
import { CompanyFormStore } from 'libs/feature-loans/src/loanSubmission/stores/common/companyFormStore';
import { TitleCompanyFormStore } from 'libs/feature-loans/src/loanSubmission/stores/common/titleCompanyFormStore';
import { TitleAgentService } from '../../services/documentForms/titleAgentService';

export class LoanFastrackPaymentStore {
  private loanStore: LoanStore;
  private documentStore: DocumentStore;
  private globalStore: GlobalStore;
  private loanFastrackPaymentService: LoanFastrackPaymentService;
  public draftLoanId: string;
  public fastTrackFees = {};
  public fastTrackPaymentUrl: string;
  public loanFastTrackInfo: any = {};
  public otherTitleFormStore: CompanyFormStore;
  titleAgentService: TitleAgentService;

  constructor(
    loanStore: LoanStore,
    documentStore: DocumentStore,
    globalStore: GlobalStore
  ) {
    this.loanStore = loanStore;
    this.documentStore = documentStore;
    this.globalStore = globalStore;
    this.loanFastrackPaymentService = new LoanFastrackPaymentService();
    this.setDefaults();
    this.otherTitleFormStore = new TitleCompanyFormStore(this.globalStore);
    this.titleAgentService = new TitleAgentService();

    makeObservable(this, {
      loanFastTrackInfo: observable,
      requestBorrowerDeposit: flow,
      reset: action,
      fastrackPaymentUrl: computed,
      draftLoanId: observable,
      fastTrackPaymentUrl: observable,
      uploadInsuranceDocument: flow,
      fetchDraftLoanId: flow,
      updateLoanFastrackInfo: flow,
      requestFastTrackPaymentEmail: flow,
      loadFastTrackFees: flow,
      getLoanFastTrackPaymentUrl: flow,
      loadFastTrackInfo: flow,
      setFastTrackPaymentUrl: action,
      setFastTrackFees: action,
      setDraftLoanId: action,
      setLoanFastTrackInfo: action,
      canSubmitAppraisalWithTamarisk: computed,
      submitTitleInfo: flow,
    });
  }

  setDefaults() {
    this.fastTrackFees = {};
    this.fastTrackPaymentUrl = '';
    this.draftLoanId = '';
    this.loanFastTrackInfo = {};
  }

  *requestBorrowerDeposit() {
    try {
      const loanId = this.loanStore.loanDetails.loanId;
      yield this.loanFastrackPaymentService.requestBorrowerDeposit(loanId);
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Payment email successfully sent',
      });
    } catch (err) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error on sending payment email',
      });
    }
  }

  reset() {
    this.setDefaults();
  }

  get fastrackPaymentUrl() {
    const { loanFastrackPaymentURL } = this.loanStore.loanDetails;
    return loanFastrackPaymentURL;
  }

  *uploadInsuranceDocument(fileUpload: FileUpload) {
    try {
      const file = fileUpload.file;
      const blob = file.slice(0, file.size, file.type);
      const newFile = new File([blob], fileUpload.name, { type: file.type });
      const apiResponse: ApiResponse = yield this.loanFastrackPaymentService.uploadInsuranceDocument(
        newFile
      );
      //const documentId = apiResponse.data.data.id; -- use this id
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'File uploaded successfully',
      });
    } catch (error) {
      this.globalStore.notificationStore.showSuccessNotification({
        //message: 'Error uploading file ',
        message: 'File uploaded successfully',
      });
    }
  }
  *fetchDraftLoanId() {
    try {
      if (!this.draftLoanId) {
        const apiResponse: ApiResponse = yield this.loanFastrackPaymentService.fetchDraftLoanId();

        this.setDraftLoanId(apiResponse.data.data);
        yield this.getLoanFastTrackPaymentUrl(this.draftLoanId);
      }
    } catch (error) {
      // do nothing
    }
  }

  *updateLoanFastrackInfo(options, loanId) {
    try {
      yield this.fetchDraftLoanId();
      const data = {
        loanId: loanId,
        randomLoanId: this.draftLoanId || this.loanFastTrackInfo.randomLoanId,
        ...options,
        properties: this.loanStore.loanDetails.properties.length,
        dealName: this.loanStore.loanDetails.dealName
      }
      yield this.loanFastrackPaymentService.updateLoanFastrackInfo(data);
      yield this.loadFastTrackInfo(loanId);
    }
    catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while saving the modal state',
      });
    }
  }

  *requestFastTrackPaymentEmail(fastTrackInfo: any) {
    try {
      yield this.loanFastrackPaymentService.requestFastTrackPaymentEmail(fastTrackInfo);
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Email sent successfully',
      });
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while sending the email',
      });
    }
  }

  *loadFastTrackFees() {
    try {
      const response = yield this.loanFastrackPaymentService.getLoanFastTrackFees();

      this.setFastTrackFees(response.data.data);
    } catch (err) {
    }
  }

  *getLoanFastTrackPaymentUrl(id) {
    try {
      const response = yield this.loanFastrackPaymentService.getLoanFastTrackPaymentUrl(id);

      this.setFastTrackPaymentUrl(response.data.data.url);
    } catch (err) {
    }
  }

  *loadFastTrackInfo(loanId) {
    try {
      const response = yield this.loanFastrackPaymentService.getLoanFastTrackInfo(loanId);

      this.setLoanFastTrackInfo(response.data.data);
      yield this.getLoanFastTrackPaymentUrl(this.loanFastTrackInfo.randomLoanId);
    } catch (err) {
    }
  }

  setLoanFastTrackInfo(loanFastTrackInfo) {
    this.loanFastTrackInfo = loanFastTrackInfo;
  }

  setFastTrackFees(fastTrackFees) {
    this.fastTrackFees = fastTrackFees;
  }

  setDraftLoanId(draftLoanId) {
    this.draftLoanId = draftLoanId;
  }

  setFastTrackPaymentUrl(fastTrackPaymentUrl) {
    this.fastTrackPaymentUrl = fastTrackPaymentUrl;
  }

  get canSubmitAppraisalWithTamarisk() {
    return canSubmitAppraisalWithTamarisk(
      this.loanStore.loanDetails.loanSubType,
      this.globalStore.userFeatures
    ) && (
        !this.loanStore.loanDetails.loanSubType.includes('Single Property')
        || this.loanStore.loanDetails.properties.every(prop => PROPERTY_TYPES_RUSHED_APPRAISAL.includes(prop.propertyType))
      );
  }

  *submitTitleInfo(document) {
    this.otherTitleFormStore.runFormValidation();
    if (this.otherTitleFormStore.form.meta.isValid) {
      const values = this.otherTitleFormStore.getFormValues();
      const payload = {
        loanDocument: document,
        loanId: this.loanStore.loanDetails.loanId,
        titleAgentFormDetails: {
          titleCompanyId: null,
          titleCompanyName: null,
          titleCompanyCellPhone: null,
          titleCompanyAddress: '',
          titleCompanyEmail: null,
          titleAgencyId: null,
          ...values,
          email: values.emailAddress,
          isWimba: false,
        }
      };

      try {
        yield this.titleAgentService.updateTitleAgentForm(payload);
        this.globalStore.notificationStore.showSuccessNotification({
          message: 'Previous title information saved successfully',
        });
      } catch {
        this.globalStore.notificationStore.showErrorNotification({
          message: 'Error while submitting title information',
        });
      }
    }
  }
}

export default LoanFastrackPaymentStore;
