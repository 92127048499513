import { Service } from "@roc/feature-app-core";

const pbCreds = {
  redirect_uri: '/crm-portal/profile',
  response_type: 'code',
}

export const url = {
  PHONEBURNER_OAUTH: '/api/v1/vendor/public/phoneBurner/phoneBurnerOAuth',
  OAUTH_ENDPOINT: 'https://www.phoneburner.com/oauth/signin',
  START_DIAL_SESSION: '/api/v1/vendor/phoneBurner/startDialSession',
};

declare global {
  interface Window {
    handleOAuthCallback: (returnedCode: string, returnedState: string) => void;
  }
}

export class phoneBurnerService extends Service {

  async retrieveTokenWithOAuthCode(userId, host, clientId) {
    const response_type = 'code';
    const client_id = clientId;
    const redirect_uri = host + pbCreds.redirect_uri;

    const urlString = url.OAUTH_ENDPOINT + '?client_id=' + client_id + '&response_type=' + response_type + '&' + 'owner_type=user&' + 'redirect_uri=' + redirect_uri;

    window.location.href = urlString;
  }

  handleTokenCallback(code, userId) {
    return this.get(url.PHONEBURNER_OAUTH + '?code=' + code + '&userId=' + userId);
  }

  startDialSession(leads) {
    return this.post(url.START_DIAL_SESSION, leads, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }
}
