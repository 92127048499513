import { Grid, Typography } from '@material-ui/core';
import { useBaseStore } from '@roc/feature-app-core';
import { Button, ConfirmationButton, ConfirmDialog } from '@roc/ui';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useDocumentStore } from './../../../../../hooks/useDocumentStore';
import { ElmsureQuotesGrid } from './components/elmsureQuotes/elmsureQuotesGrid';
import { FinalChoiceQuotesGrid } from './components/finalChoiceQuotesGrid';
import { NewQuoteDialog } from './components/newQuoteDialog';
import { OtherQuotesGrid } from './components/otherQuotesGrid';
import { UploadOtherFinalDocumentsGrid } from './components/uploadOtherFinalDocumentsGrid';
import { DateField } from '@roc/ui';

type InsuranceApprovalFormProps = {
  onClose: () => void;
};

export const InsuranceApprovalForm = observer(
  (props: InsuranceApprovalFormProps) => {
    const { onClose } = props;
    const [showConfirmDelete, setShowConfirmDelete] = useState();
    const [showConfirmApprove, setShowConfirmApprove] = useState(false);

    const { globalStore } = useBaseStore();
    const {
      documentStore: { currentDocument, documentFormStore },
    } = useDocumentStore();
    const { insuranceApprovalStore, loanStore } = documentFormStore;
    const {
      canSubmitInsurance,
      insuranceApprovalFormStore,
      canEdit,
      canSubmit,
    } = insuranceApprovalStore;
    const {
      elmsureQuotes,
      otherQuotes,
      finalQuotes,
      newQuoteFormStore,
    } = insuranceApprovalFormStore;
    const {
      loanDetails,
    } = loanStore
    const isTermLoan = loanStore?.loanDetails?.loanType === 'Residential Term';

    const isInternalInsuranceReviewer = insuranceApprovalStore.loanStore.loanDetails.insuranceReviewerId == 4 && globalStore.userFeatures.isInsuranceReviewer;

    const save = () => {
      insuranceApprovalFormStore.saveInsuranceSummaryForm();
    };

    useEffect(() => {
      insuranceApprovalStore.getCoverageStartDate();
    }, [])

    const runValidationAndOpenConfirm = async () => {
      const isValid = await insuranceApprovalStore.validateInsurance();
      if (isValid) {
        setShowConfirmApprove(true);
      }
    };

    const confirmAndApprove = async () => {
      await insuranceApprovalStore.confirmAndApprove();
      setShowConfirmApprove(false);
      documentFormStore.closeCurrentForm();
    };

    const formatDigit = (value, digits) => value?.toFixed(3, digits);

    const formatCurrency = (number, digits = 2) =>
      new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: digits,
      }).format(number);

    const useStyles = makeStyles(() => ({
      helperText: {
        fontSize: 18,
        color: '#d94452',
      },
    }));

    const classes = useStyles();

    return (
      <Grid container spacing={3}>
        {isInternalInsuranceReviewer && <Grid item xs={12}>
          <DateField
            standaloneLabel
            label='Coverage Effective Date'
            format='MM/dd/yyyy'
            testId='coverageDate'
            value={insuranceApprovalStore.coverageEffectiveDate}
            onChange={date => insuranceApprovalStore.updateCoverageStartDate(date)} />
        </Grid>}
        <Grid item xs={12}>
          <ElmsureQuotesGrid quotes={elmsureQuotes} />
        </Grid>
        {insuranceApprovalFormStore?.delayReason &&
          <Grid item xs={12} className={classes.helperText}>
            <Typography>Delay Reason:</Typography>
            {insuranceApprovalFormStore.delayReason}
          </Grid>
        }
        <Grid item xs={12}>
          <OtherQuotesGrid
            quotes={otherQuotes}
            disableActions={!canEdit}
            addQuote={() => newQuoteFormStore.openAdd()}
            editQuote={quote => newQuoteFormStore.openEdit(quote)}
            removeQuote={quote =>
              insuranceApprovalFormStore.deleteInsuranceDetail(quote)
            }
          />
        </Grid>
        <Grid item xs={12}>
          <FinalChoiceQuotesGrid
            insuranceApprovalStore={insuranceApprovalStore}
            disableSelection={!canEdit}
          />
        </Grid>
        {canEdit && (
          <Grid item xs={12}>
            <UploadOtherFinalDocumentsGrid
              insuranceApprovalStore={insuranceApprovalStore}
              disableSelection={!canEdit}
            />
          </Grid>
        )}
        {isTermLoan && canEdit && finalQuotes.length > 0 && (
          <Grid item xs={12}>
            <Typography color="textPrimary">
              *The DSCR value based on the final choice is {formatDigit(loanStore?.loanDetails?.pitiaDSCR, 3)}. (The DSCR should be greater than {loanStore?.loanDetails?.minimumInPlaceDscr}).
              <br />
            </Typography>
            {loanStore?.loanDetails?.maximumInsuranceAllowed > 0 && (
              <Typography color="textPrimary">
                *The maximum insurance amount allowed is {formatCurrency(loanStore?.loanDetails?.maximumInsuranceAllowed)}.
              </Typography>
            )}
            {loanStore?.loanDetails?.maximumInsuranceAllowed < 0 && (
              <Typography color="textPrimary">
                *Please reach out to Underwriter to restructure this loan.
              </Typography>
            )}

          </Grid>
        )}
        {canSubmitInsurance && (
          <Grid item xs={12}>
            <Grid container spacing={1} justifyContent="flex-end">
              <Grid item>
                <Button
                  onClick={onClose}
                  variant="outlined"
                  color="primary"
                  testId="cancel"
                >
                  Close
                </Button>
              </Grid>
              <Grid item>
                <Button
                  onClick={save}
                  variant="contained"
                  color="primary"
                  testId="save"
                  disabled={!canSubmit}
                >
                  Save
                </Button>
              </Grid>
              <Grid item>
                <Button
                  onClick={runValidationAndOpenConfirm}
                  variant="contained"
                  color="primary"
                  testId="save"
                  disabled={!canSubmit}
                >
                  Confirm And Approve
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
        <NewQuoteDialog insuranceApprovalStore={insuranceApprovalStore} />
        <ConfirmDialog
          open={showConfirmApprove}
          handleCancel={() => setShowConfirmApprove(false)}
          handleClose={() => setShowConfirmApprove(false)}
          handleConfirm={() => confirmAndApprove()}
          title="Confirm and approve"
          body="Are you sure you want to approve the insurance summary?"
        />
      </Grid>
    );
  }
);

export default InsuranceApprovalForm;
