import { GlobalStore } from '@roc/feature-app-core';
import { computed, flow, action, makeObservable, observable, override } from 'mobx';
import { DialogState, FileUpload } from '@roc/ui';
import { FormStore } from '@roc/feature-app-core';
import { GridStore } from '@roc/feature-app-core';
import { ApiResponse } from '@roc/feature-app-core';
import { BorrowersService } from './../services/borrowersService';
import { BorrowerBaseballCardBaseStore } from './borrowerBaseballCardBaseStore';
import { ExternalEntityStore } from './externalEntityStore';
import { PropertyFormExternalStore } from './propertyFormExternalStore';
export class BorrowerBaseballCardStore extends BorrowerBaseballCardBaseStore {
  private borrowerService: BorrowersService;
  entityStore: ExternalEntityStore;

  constructor(globalStore, propertyFormStore: PropertyFormExternalStore) {
    super(globalStore, propertyFormStore);
    this.borrowerService = new BorrowersService();
    this.entityStore = new ExternalEntityStore(globalStore, this);
    this.propertyFormStore = propertyFormStore;

    makeObservable(this, {
      getBaseballCardData: override,
      getVerifiedTrackRecord: override,
      getUnverifiedTrackRecord: override,
      getPublicTrackRecord: override,
      downloadTaskFile: flow,
      deleteMultipleTrackRecords: flow,
      deleteTrackRecord: flow,
      createTaskWithFile: flow,
      uploadTaskFile: flow,
      getTasks: flow,
      lookupProperty: flow,
    });
  }

  *getBaseballCardData(borrowerId: number, loanId?: number) {
    const response = yield this.borrowerService.getBaseballCardDetails(
      borrowerId,
      loanId
    );
    return { data: JSON.parse(response?.data?.data || '{}') };
  }

  *getVerifiedTrackRecord(borrowerId: number, loanId: string) {
    const response = yield this.borrowerService.getVerifiedTrackRecord(borrowerId);
    return { data: JSON.parse(response?.data?.data || '[]') };
  }

  *getUnverifiedTrackRecord(borrowerId: number) {
    const response = yield this.borrowerService.getUnverifiedTrackRecord(borrowerId);
    return { data: JSON.parse(response?.data?.data || '[]') };
  }

  *getPublicTrackRecord(borrowerId: number) {
    const response = yield this.borrowerService.getPublicTrackRecord(borrowerId);
    return { data: JSON.parse(response?.data?.data || '[]') };
  }

  *downloadTaskFile(id, name, fileExtension) {
    try {
      const blob = yield this.borrowerService.downloadTaskFile(id);
      const reader = new FileReader();

      reader.readAsArrayBuffer(blob);

      reader.onloadend = () => {
        if (fileExtension === 'pdf') {
          // Preview the PDF
          this.pdfTitle = name;
          this.pdfData = reader.result; // The ArrayBuffer
          this.isPdfPreviewModalOpen = true;
        } else {
          // Download the file if not a PDF
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = name;
          a.click();
          window.URL.revokeObjectURL(url);
        }
      };

    } catch (error) {
      console.error(error);
    }
  }

  *getTasks(id, type) {

    const taskEntityBody = {
      sectionProcess: 'TRACK_RECORD_REVIEW',
      objectType: 'BORROWER_ENTITY_BORROWER',
      taskTemplateType: 'BORROWER_ENTITY_BORROWER',
      objectId: id,
    };

    const taskPropertyBody = {
      sectionProcess: 'TRACK_RECORD_REVIEW',
      objectType: 'PROPERTY_TRACK_RECORD',
      taskTemplateType: 'PROPERTY_TRACK_RECORD',
      objectId: id,
    };
    const taskBody = type === 'entity' ? taskEntityBody : taskPropertyBody;

    const response = yield this.borrowerService.getTasks(taskBody);
    this.currentTasks = response.data.data;
  }

  *createTaskWithFile(id, file, type) {
    try {

      const taskEntityBody = {
        sectionProcess: 'TRACK_RECORD_REVIEW',
        objectType: 'BORROWER_ENTITY_BORROWER',
        taskTemplateType: 'BORROWER_ENTITY_BORROWER',
        objectId: id,
      };

      const taskPropertyBody = {
        sectionProcess: 'TRACK_RECORD_REVIEW',
        objectType: 'PROPERTY_TRACK_RECORD',
        taskTemplateType: 'PROPERTY_TRACK_RECORD',
        objectId: id,
      };

      const taskBody = type === 'entity' ? taskEntityBody : taskPropertyBody;

      const taskResponse = yield this.borrowerService.createTask(taskBody);


      const task = taskResponse.data.data[0];

      yield this.uploadTaskFile(task, file);

      yield this.getTasks(id, type);
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while uploading the file',
      });
    }
  }

  *uploadTaskFile(task, fileUpload: FileUpload) {
    const blob = fileUpload.file.slice(0, fileUpload.file.size, fileUpload.file.type);
    const newFile = new File([blob], fileUpload.name, { type: fileUpload.file.type });
    const formData = new FormData();
    formData.append('file', newFile);
    formData.append('task', JSON.stringify(task));
    yield this.borrowerService.uploadTaskFile(formData);
  }

  lookupProperty(data: any) { }

  verifyTrackRecord() { }

  unverifyTrackRecord() { }

  *deleteTrackRecord(id: number) {
    const response = yield this.borrowerService.deleteTrackRecord(id);
    return response;
  }

  *deleteMultipleTrackRecords(ids: number[]) {
    const response = yield this.borrowerService.deleteMultipleTrackRecords(JSON.stringify(ids));
    return response;
  }
  saveExperienceComment() { }

  savePropertyInformation() { }

  requestTrackRecordVerification() { }

  isRawEntityValue() {
    return false;
  }

  submitReview() { }

  removeEntity() { }

  verifyMultipleTrackRecords() { }

  updateVerifyEntity() { }

  getTrackHistoryAuditLogs() { }

  getResolutionsReviewItems() { }
}
