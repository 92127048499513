import { FormStore } from '@roc/feature-app-core';
import { GlobalStore } from '@roc/feature-app-core';
import { borrowerDefaults } from '@roc/feature-utils';
import { FileUpload } from '@roc/ui';
import { action, makeObservable, observable } from 'mobx';

const borrowerForm = {
  fields: {
    borrowerId: {
      value: null,
      error: null,
      rule: '',
    },
    dobRequired: {
      value: false,
      error: null,
      rule: '',
    },
    ssnRequired: {
      value: false,
      error: null,
      rule: '',
    },
    creditBackgroundCheck: {
      value: true,
      error: null,
      rule: 'required',
    },
    dateOfBirth: {
      value: null,
      error: null,
    },
    ssn: {
      value: null,
      error: null,
    },
    fileId: {
      value: null,
      error: null,
      rule: [{ required_if: ['creditBackgroundCheck', false] }],
    },
    personalGuarantor: {
      value: borrowerDefaults.personalGuarantor,
      error: null,
      rule: '',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export class QualifyBorrowerRequestFormStore extends FormStore {
  private globalStore: GlobalStore;
  uploads: FileUpload[];

  constructor(globalStore) {
    super({ ...borrowerForm }, globalStore);
    this.uploads = [];
    this.globalStore = globalStore;

    makeObservable(this, {
      uploads: observable,
      setUploads: action,
      resetUploads: action,
      initCreditBackgroundCheck: action,
    })
  }

  resetUploads() {
    this.uploads = [];
  }

  setUploads(itemsCall) {
    this.uploads = itemsCall(this.uploads);
  }

  initCreditBackgroundCheck(borrower) {
    const creditProvidedByTpo = this.globalStore.lenderInfo.creditProvidedByTpo;
    let creditBackgroundCheck = borrower?.sendIdVerification ?? !creditProvidedByTpo;
    this.onFieldChange('creditBackgroundCheck', creditBackgroundCheck);
  }
}

export default QualifyBorrowerRequestFormStore;
