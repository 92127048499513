import { lighten, makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import {
  AssignmentTurnedIn,
  Business,
  MonetizationOn,
  Person,
  Room, LocalOffer
} from '@material-ui/icons';
import { BrokerInformation, FixFlipPropertySteps, LoanStore, LoanSubmissionStep, PropertySteps, useFixFlipStore, useLoanStore } from '@roc/feature-loans';
import { Layout, useBaseStore } from '@roc/feature-app-core';
import { insertIf, isNil, LoanSubType } from '@roc/feature-utils';
import { Paper, StepsContainer } from '@roc/ui';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import Summary from 'libs/feature-loans/src/loanSubmission/summary';
import LoanInformation from 'libs/feature-loans/src/loanSubmission/fixflipGroundUp/components/loanInformation';
import BorrowerInformation from 'libs/feature-loans/src/loanSubmission/components/borrowerInformation';
import PropertyInformation from 'libs/feature-loans/src/loanSubmission/fixflipGroundUp/propertyInformation';

import BorrowerEntity from 'libs/feature-loans/src/loanSubmission/components/entityInformation';
import { useRoutes } from '@roc/client-portal-shared/hooks';
import { SelectPromotionGrid } from '@roc/feature-loans';


const useStyles = makeStyles(theme =>
  createStyles({
    container: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    paper: {
      padding: theme.spacing(4),
    },
    termSheetContainer: {
      padding: theme.spacing(2),
      marginBottom: '24px',
      backgroundColor: lighten(theme.palette.primary.main, 0.8),
    },
    termSheetDownload: {
      display: 'flex',
      alignItems: 'center',
    }
  })
);


export const EditFixAndFlip = observer(() => {
  const { globalStore } = useBaseStore();
  const { fixFlipStore } = useFixFlipStore();
  const { loanId } = useParams<{ loanId: string }>();
  const classes = useStyles();
  const { loanRoutesConfig } = useRoutes();
  const { push } = useHistory();
  const showBrokerInformation = !globalStore.userFeatures.isBroker;
  const { marketingPromotionsStore } = fixFlipStore;

  useEffect(() => {
    fixFlipStore.loadStore(loanId);
    fixFlipStore.setLoanType(LoanSubType.FIX_AND_FLIP);
    fixFlipStore.selectBorrowersStore.setAllowMultiple(false);
    marketingPromotionsStore.fetchGridData();
  }, [loanId]);

  const onSubmit = () => {
    const isLoanDetailsValid =
      fixFlipStore.loanInformationStore.form.meta.isValid;
    const isAllStepsValid = fixFlipStore.allErrors.length === 0;
    if (!isLoanDetailsValid) {
      globalStore.notificationStore.showErrorNotification({
        message: 'Please correct the errors in Loan Details',
      });
    } else if (fixFlipStore.allErrors.length > 0) {
      globalStore.notificationStore.showErrorNotification({
        message: 'Please correct the errors listed above',
      });
    }

    const isValid = isAllStepsValid && isLoanDetailsValid;
    if (isValid) {
      fixFlipStore.onEditLoanSubmit(() => push(loanRoutesConfig.loans(fixFlipStore.editLoanJson.loanId).children.dashboard.url))
    }
  };

  const goNextStep = () => {
    const currentStep = loanSubmissionSteps[fixFlipStore.activeStep].type;
    fixFlipStore.goNextStep(currentStep);
  }

  const goPrevStep = () => {
    const currentStep = loanSubmissionSteps[fixFlipStore.activeStep].type;
    fixFlipStore.goPrevStep(currentStep);
  }

  const moveToStep = (stepIndex) => {
    const currentStep = loanSubmissionSteps[fixFlipStore.activeStep].type;
    fixFlipStore.moveToStep(stepIndex, currentStep);
  }

  const setPromotion = (promotionId: number) => {
    fixFlipStore.loanInformationStore.onChange('marketingPromotionId', promotionId);
  }


  const loanSubmissionSteps = [
    ...insertIf(showBrokerInformation, [{
      title: 'Broker Information',
      icon: <Person color="primary" fontSize="large" />,
      type: LoanSubmissionStep.BROKER_INFORMATION,
      onBack: () => {
        push(loanRoutesConfig.loans(fixFlipStore.editLoanJson.loanId).children.dashboard.url);
      },
      onNext: () => goNextStep(),
      allowBack: true,
      allowNext: true,
    }]),
    {
      title: 'Entity Information',
      icon: <Business color="primary" fontSize="large" />,
      type: LoanSubmissionStep.ENTITY_INFORMATION,
      onBack: () => {
        goPrevStep();
      },
      onNext: () => {
        goNextStep();
      },
      allowBack: true,
      allowNext: true,
    },
    {
      title: 'Borrower Information',
      icon: <Person color="primary" fontSize="large" />,
      type: LoanSubmissionStep.BORROWER_INFORMATION,
      onBack: () => goPrevStep(),
      onNext: () => goNextStep(),
      allowBack: true,
      allowNext: true,
    },
    {
      title: 'Property Information',
      icon: <Room color="primary" fontSize="large" />,
      type: LoanSubmissionStep.PROPERTY_INFORMATION,
      onBack: () => goPrevStep(),
      onNext: () => goNextStep(),
      allowBack: true,
      allowNext: true,
    },
    {
      title: 'Loan Details',
      icon: <MonetizationOn color="primary" fontSize="large" />,
      type: LoanSubmissionStep.LOAN_DETAILS,
      onBack: () => goPrevStep(),
      onNext: () => {
        goNextStep()
      },
      allowBack: true,
      allowNext: true,
    },
    ...insertIf(marketingPromotionsStore.showPromotions, [{
      title: 'Promotions',
      icon: <LocalOffer color="primary" fontSize="large" />,
      type: LoanSubmissionStep.PROMOTIONS,
      onBack: () => goPrevStep(),
      onNext: () => {
        goNextStep()
      },
      allowBack: true,
      allowNext: true,
    },]),
    {
      title: 'Summary',
      icon: <AssignmentTurnedIn color="primary" fontSize="large" />,
      type: LoanSubmissionStep.SUMMARY,
      onBack: () => goPrevStep(),
      onNext: () => onSubmit(),
      allowBack: true,
      allowNext: true,
    },
  ];


  return (
    <Layout title={`Edit Fix and Flip Loan #${fixFlipStore.editLoanJson.loanId}`}>
      <Paper className={classes.paper}>
        <>
          <StepsContainer
            disableNavigation={false}
            activeStep={fixFlipStore.activeStep}
            handleStep={moveToStep}
            steps={loanSubmissionSteps}
          >
            {[
              ...insertIf(showBrokerInformation, [<BrokerInformation />]),
              <BorrowerEntity store={fixFlipStore} />,
              <BorrowerInformation store={fixFlipStore} />,
              <PropertyInformation
                store={fixFlipStore}
                renderSteps={(modalState, handleAdd, handleEdit, store) => {
                  return fixFlipStore.canSubmitAppraisalWithTamarisk ? (
                    <FixFlipPropertySteps
                      modalState={modalState}
                      handleAdd={handleAdd}
                      handleEdit={handleEdit}
                    />
                  ) : (
                    <PropertySteps
                      modalState={modalState}
                      handleAdd={handleAdd}
                      handleEdit={handleEdit}
                      store={store}
                    />
                  );
                }}
              />,
              <LoanInformation store={fixFlipStore} />,
              ...insertIf(marketingPromotionsStore.showPromotions, [<SelectPromotionGrid store={marketingPromotionsStore.marketingPromotionsGridStore} promotionData={{ setPromotion: setPromotion, promotionId: fixFlipStore.loanInformationStore.getValue('marketingPromotionId') }} />]),
              <Summary />
            ]}
          </StepsContainer>
        </>
      </Paper>
    </Layout>
  );
});
