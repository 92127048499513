import { Grid, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import { lighten, makeStyles, Theme } from '@material-ui/core/styles';
import { useStore } from '@roc/client-portal-shared/hooks';
import { Button, SelectField } from '@roc/ui';
import { display } from 'html2canvas/dist/types/css/property-descriptors/display';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useACHFormStore } from '../hooks/useACHStore';
import { LoanDetailsCard } from './loanDetailsCard';

const useStyles = makeStyles((theme: Theme) => {
  return {
    rightColumn: {
      padding: theme.spacing(8, 6),
    },
    leftColumn: {
      padding: theme.spacing(8, 6),
    },
    hello: {
      ...theme.typography.h4,
      fontSize: 48,
    },
    text: {
      padding: theme.spacing(4, 0)
    },
    button: {
      fontWeight: 'normal',
      padding: theme.spacing(2, 8)
    },
  };
});

export const Welcome = observer(({ onStart }) => {
  const { achFormStore } = useACHFormStore();
  const { achData } = achFormStore;
  const { globalStore } = useStore();
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item md={12} lg={6} className={classes.leftColumn}>
        <Typography className={classes.hello}>
          Hello {achData?.borrowerFirstName}! <br />
        </Typography>
        <Typography className={classes.text}>
          Start the ACH consent process by clicking below.
          It should take no more than a few minutes to complete.
        </Typography>
        <Button
          testId="start"
          variant="contained"
          color="primary"
          placeholder=''
          className={classes.button}
          disabled={achFormStore.disableStartACHForm}
          onClick={onStart}
        >
          GET STARTED
        </Button>
      </Grid>
      <Grid item xs={12} md={12} lg={6} className={classes.rightColumn}>
        <LoanDetailsCard />
      </Grid>
    </Grid>
  );
});
