import { Grid } from '@material-ui/core';
import { DashboardRounded, Home } from '@material-ui/icons';
import { useBaseStore } from '@roc/feature-app-core';
import { useBorrowerLoansStore } from '@roc/feature-borrower-loans';
import { useDocumentStore } from '@roc/feature-documents';
import {
  DrawDetails,
  DrawDetailsShared,
  useDrawsStore,
} from '@roc/feature-draws';
import { useLoanRoutes } from '@roc/feature-loans-routes-config';
import { LoanDetailsPaperLayout } from '@roc/feature-loans-shared';
import { PDFPreview } from '@roc/ui';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

export const BorrowerDrawDetails = observer(({ loanId, drawId }) => {
  const { loanRoutesConfig } = useLoanRoutes();
  const { push } = useHistory();
  const { drawStore } = useDrawsStore();

  const { drawNumber, propertyAddress } = drawStore.currentDraw || {};

  const { globalStore } = useBaseStore();
  const { borrowerLoansStore } = useBorrowerLoansStore();
  const { documentStore } = useDocumentStore();
  const { breadCrumbsStore } = globalStore;

  useEffect(() => {
    breadCrumbsStore.setBreadCrumbItems([
      {
        link: true,
        title: 'Dashboard',
        url: globalStore.routeBaseUrl,
        icon: <DashboardRounded />,
      },
      {
        link: true,
        title: `Loan# ${loanId}`,
        url: loanRoutesConfig.loans(loanId).url,
      },
      {
        link: true,
        title: 'Draws Dashboard',
        url: loanRoutesConfig.loans(loanId).children.draws.url,
      },
      {
        link: false,
        title: 'Draw ' + (drawNumber ?? ''),
      },
    ]);
  });

  return (
    <LoanDetailsPaperLayout
      title={`DRAW ${drawNumber ?? ''} ${propertyAddress ? '- ' + propertyAddress : ''
        }`}
      onBack={() => push(loanRoutesConfig.loans(loanId).children.draws.url)}
    >
      <Grid container spacing={2}>
        <DrawDetailsShared loanId={loanId} drawId={drawId} adminSpecialist={borrowerLoansStore.loanDetails?.drawAdminSpecialistFullName} reviewSpecialist={borrowerLoansStore.loanDetails?.drawReviewSpecialistFullName} />
      </Grid>
      <PDFPreview
        open={documentStore.documentPreview != null}
        title={documentStore.documentPreview?.title}
        data={documentStore.documentPreview?.data}
        headers={documentStore.documentPreview?.headers}
        onClose={() => documentStore.closeDocumentPreview()}
      />
    </LoanDetailsPaperLayout>
  );
});
