import { Service } from '@roc/feature-app-core';

const url = {
  REQUEST_BORROWER_DEPOSIT:
    '/api/v1/document/formDocument/requestBorrowersDeposit',
  GET_LOAN_FAST_TRACK_FEES: '/api/v1/loan/fastTrackFees',
  GET_LOAN_FAST_TRACK_INFO: '/api/v1/loan/loanFastTrackInfo',
  REQUEST_FAST_TRACK_PAYMENT: '/api/v1/loan/requestFastrackPayment',
  GET_FAST_TRACK_PAYMENT_URL: '/api/v1/loan/loanFastTrackPaymentUrl',
  FETCH_DRAFT_LOAN_ID: '/api/v1/loan/fetchDraftLoanIdForFastrackPayment',
  UPDATE_LOAN_FAST_TRACK_PAYMENTS: '/api/v1/document/formDocument/updateLoanFastrackInfoState',
  UPLOAD_DOCUMENT: '/api/v1/document/upload',
};

export class LoanFastrackPaymentService extends Service {
  requestBorrowerDeposit = (loanId: string) => {
    return this.get(url.REQUEST_BORROWER_DEPOSIT, {
      loanId,
    });
  };

  uploadInsuranceDocument(file: File) {
    const formData = new FormData();
    formData.append(
      'documentMetadata',
      JSON.stringify({
        documentCollection: 'FAST_TRACK_INSURANCE_DOCUMENTS',
      })
    );
    formData.append('document', file);
    return this.post(url.UPLOAD_DOCUMENT, formData);
  }

  fetchDraftLoanId() {
    return this.get(url.FETCH_DRAFT_LOAN_ID);
  }

  updateLoanFastrackInfo(fastTrackOptions: any) {
    return this.put(url.UPDATE_LOAN_FAST_TRACK_PAYMENTS, fastTrackOptions);
  }

  getLoanFastTrackFees() {
    return this.get(url.GET_LOAN_FAST_TRACK_FEES);
  }

  getLoanFastTrackInfo(loanId) {
    return this.get(url.GET_LOAN_FAST_TRACK_INFO, { loanId });
  }

  requestFastTrackPaymentEmail(fastTrackInfo: any) {
    return this.post(url.REQUEST_FAST_TRACK_PAYMENT, fastTrackInfo);
  }

  getLoanFastTrackPaymentUrl(loanId) {
    const encodeParams = (data: string): string => {
      return window.btoa(unescape(encodeURIComponent(data)));
    };

    var string = loanId.toString() + ';Loan_Fastrack';
    var encodedString = encodeParams(string);
    const fastrackUrl =
      `${window.location.origin}/loan-fastrack-payments/` + encodedString;

    return { data: { data: { url: fastrackUrl } } };
  }
}
