import { Route } from 'react-router';
// import { SigmaDashboards } from '../sigmaDashboards';
import { Page } from '@roc/feature-app-core';
import { useStore } from '../../../hooks/useStore';
import { Typography } from '@material-ui/core';
import { LoanVolumeReport } from '../components/loanVolumeReport';

export const getLoanVolumeReportRoutesConfig = (
  basePath: string,
  baseUrl: string
) => {
  return {
    loanVolumeReport: {
      path: `${basePath}/backoffice/loan-volume-report`,
      url: `${baseUrl}/backoffice/loan-volume-report`,
      documentTitle: 'Loan Volumen Report',
    },
  };
};

export const getLoanVolumeReportRoutes = (basePath: string, baseUrl: string) => {
  const config = getLoanVolumeReportRoutesConfig(basePath, baseUrl);
  const { loanVolumeReportStore } = useStore();

  return {
    loanVolumeReport: (
      <Route exact path={config.loanVolumeReport.path}>
        <Page routeDefinition={config.loanVolumeReport}>
          <LoanVolumeReport />
        </Page>
      </Route>
    ),
  };
};
