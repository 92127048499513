import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { CheckCircle } from '@material-ui/icons';
import { useLoanStore } from '@roc/feature-loans';
import { LoanSummaryBrokerContacts } from '@roc/feature-loans-shared';
import { isFundedLoan } from '@roc/feature-utils';
import { Button, InformationButton } from '@roc/ui';
import { observer } from 'mobx-react';
import { CustomCard, CustomCardDividers, CustomCardRow } from '../customCard';
import {
  LoanDetailsCustomCard,
  LoanDetailsInfoItem,
} from '../loanDetailsCustomCard';

import { Divider, List, ListItem, ListItemText } from '@material-ui/core';
import { darken } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { SubdirectoryArrowRight } from '@material-ui/icons';
import { Layout, useUserStore } from '@roc/feature-app-core';
import {
  US_TREASURY_10_YR_DESC,
  US_TREASURY_10_YR_INDEX,
  US_TREASURY_5_YR_DESC,
  US_TREASURY_5_YR_INDEX,
} from '@roc/feature-loans';
import {
  formatDate,
  isFundedOrAgreedLoan,
  isNil,
  sanitizeTestId,
} from '@roc/feature-utils';
import {
  BooleanChip,
  Paper,
  StatusChip,
  StatusType,
  TestDataAttribute,
} from '@roc/ui';
import { formatCurrency } from '@roc/ui/utils';
import { useLoanFlags } from '../../hooks/useLoanFlags';
import { useHistory } from 'react-router';
import { useLoanRoutes } from '@roc/feature-loans-routes-config';

export const LoanTermsCardTerm = observer(() => {
  const { loanStore } = useLoanStore();
  const { userStore } = useUserStore();
  const { loanDetails } = loanStore;
  const { push } = useHistory();
  const { loanRoutesConfig } = useLoanRoutes();

  const {
    loanId,
    amount,
    initialLoanAmount,
    status,
    loanType,
    loanSubType,
    borrowerNames,
    borrowerEntity,
    attorneyName,
    minNumber,
    interestReserve,
    brokerPointsIn,
    prepayPenaltyMonths,
    rocRate,
    rocPointsIn,
    pledgeOfShares,
    loanPurpose,
    interestReserveMonths,
    amortization,
    recourseStructure,
    rate,
    duration,
    aggregateLoanToAsIs,
    aggregateLoanToCurrentCost,
    totalOriginationFee,
    borrowerCashAtClose,
    interestOnlyMonths,
    interestPayment,
    rateType,
    originalAmortizationTerms,
    prepaymentDescription,
    originationSource,
    sfrLenderPointsIn,
    totalUnderwritingFee,
    rocUnderwritingFees,
    brokerLenderUnderwritingFees,
    lenderPremium,
    propertyCount,
    mostRecentValuation,
    totalCostBasis,
    totalDebtPayoffAmount,
    annualGrossRent,
    pitia,
    noi,
    netCashFlow,
    netDSCR,
    pitiaDSCR,
    loanPricingData = {},
    servicer,
    lenderOriginatorNames,
    lenderName,
    preAgreed,
    loanComparisonValues,
    calculatedLoanData,
    servicerId,
    loanLenderDetails,
    loanRetailFees,
    processingFee,
    rocServicingSetupFee,
    rocAppraisalFee,
    rocServicingFee,
    rocValuationReviewFee,
    rocLegalReviewFee,
    rocAdminFee,
    rocCustodianFee,
    rocCommitmentFee,
    rocBuydownFee,
    rocOtherFee,
    rocCustomFees,
    fullySubmitted,
    freeAppraisalPromotion,
    firstServicerPaymentDueDate,
  } = loanDetails ?? {};

  const isFundedOrPaidoffOrAgreedLoan = isFundedOrAgreedLoan(status);

  const allowLoanPricing = userStore.allowLoanPricing;

  return (
    <CustomCard title="Other Loan Terms" cardContentProps={{
      style: {
        padding: 0
      }
    }}>
      <Grid container>
        <Grid item xs={6}>
          <Box>
            <List>
              {listItem('Loan Purpose', loanPurpose)}
              {divider}
              {listItem(
                'Borrower Cash Out at Close',
                formatCurrency(borrowerCashAtClose ?? 0)
              )}
              {divider}
              {listItem(
                'P&I Reserves at close (Months)',
                interestReserveMonths
              )}
              {divider}
              {listItem('Attorney', attorneyName)}
              {divider}
              {isFundedOrPaidoffOrAgreedLoan && listItem('Servicer', servicer)}
              {isFundedOrPaidoffOrAgreedLoan &&
                listItem(
                  'Servicer Id',
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    {servicerId}
                  </Typography>,
                  <Box flexGrow={1}>
                    <SubdirectoryArrowRight fontSize="small" />
                  </Box>
                )}
              {isFundedOrPaidoffOrAgreedLoan &&
                listItem(
                  'First Payment Date',
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    {firstServicerPaymentDueDate}
                  </Typography>,
                  <Box flexGrow={1}>
                    <SubdirectoryArrowRight fontSize="small" />
                  </Box>
                )}
              {isFundedOrPaidoffOrAgreedLoan && divider}
              {listItem('MERS MIN Number', minNumber)}
              {divider}
              {listItem('Amortization', amortization)}
              {divider}
              {listItem('Int Only Periods(Months)', interestOnlyMonths)}
              {divider}
              {listItem('Loan Guaranty', recourseStructure)}
              {divider}
              {allowLoanPricing &&
                listItem(
                  'Capital Provider Interest Rate',
                  formatPercentage(rocRate ?? 0)
                )}
              {allowLoanPricing && divider}
              {listItem('Original Loan Terms (Months)', duration)}
              {divider}
              {listItem(
                'Orig. Amortization Terms (Months)',
                originalAmortizationTerms
              )}
            </List>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box>
            <List>
              {listItem(
                'Prepayment Penalty Period (Months)',
                prepayPenaltyMonths
              )}
              {divider}
              {listItem('Prepayment Description', prepaymentDescription)}
              {divider}
              {listItem(
                'Loan LTV at Origination',
                formatPercentage(aggregateLoanToAsIs ?? 0)
              )}
              {divider}
              {listItem(
                'Loan LTC at Origination',
                formatPercentage(aggregateLoanToCurrentCost ?? 0)
              )}
              {divider}
              {listItem('Origination Source', originationSource)}
              {allowLoanPricing && divider}
              {allowLoanPricing &&
                listItem(
                  'Total Origination Fee',
                  formatPercentage(
                    parseFloat(totalOriginationFee || 0) +
                    parseFloat(loanRetailFees?.retailPoints || 0)
                  )
                )}
              {allowLoanPricing && divider}
              {allowLoanPricing &&
                listItem(
                  'Capital Provider Origination Fee',
                  formatPercentage(
                    parseFloat(rocPointsIn || 0) +
                    parseFloat(loanRetailFees?.retailPoints || 0)
                  )
                )}
              {allowLoanPricing && divider}
              {allowLoanPricing &&
                listItem(
                  'Total Underwriting Fee',
                  formatCurrency(
                    parseFloat(totalUnderwritingFee || 0) +
                    parseFloat(loanRetailFees?.retailUnderwritingFee || 0)
                  )
                )}
              {allowLoanPricing && divider}
              {allowLoanPricing &&
                listItem(
                  'Capital Provider Underwriting Fee',
                  formatCurrency(
                    parseFloat(rocUnderwritingFees || 0) +
                    parseFloat(loanRetailFees?.retailUnderwritingFee || 0)
                  )
                )}
              {allowLoanPricing && divider}
              {allowLoanPricing &&
                listItem(
                  'Broker/Lender Underwriting Fee',
                  formatCurrency(brokerLenderUnderwritingFees ?? 0)
                )}
              {allowLoanPricing && divider}
              {allowLoanPricing &&
                listItem('Lender Premium', formatPercentage(lenderPremium))}
            </List>
          </Box>
        </Grid>
      </Grid>
    </CustomCard>
  );
});

const listItem = (label, value, icon = null, customTestId = null) => {
  const testId = sanitizeTestId(customTestId || label);
  return (
    <ListItem>
      <ListItemText>
        <Box display="flex">
          {icon && <Box ml={1}>{icon}</Box>}
          <Box flexGrow={1} mr={1}>
            {label}
          </Box>
          <TestDataAttribute id={testId}>
            <Box textAlign="right">{value}</Box>
          </TestDataAttribute>
        </Box>
      </ListItemText>
    </ListItem>
  );
};

const divider = <Divider variant="middle" component="li" />;

const formatPercentage = value =>
  `${value != null ? Number(value).toFixed(2) : ''}%`;

const formatDigit = (value, digits) => value?.toFixed(3, digits);

const getSwapIndexLabel = index => {
  if (index === US_TREASURY_5_YR_INDEX) return US_TREASURY_5_YR_DESC;
  else if (index === US_TREASURY_10_YR_INDEX) return US_TREASURY_10_YR_DESC;
};
