import {
  DataGrid as Grid,
  GridColumnProps,
  StatusChip,
  StatusType
} from '@roc/ui';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useDocumentStore } from '@roc/feature-documents';

const noOpComparator = () => 0;

const columns: GridColumnProps[] = [
  {
    field: 'orderId',
    headerName: 'Order Id',
    maxWidth: 350,
    comparator: noOpComparator,
    sortable: false,
  },
  {
    field: 'propertyAddress',
    headerName: 'Property Address',
    minWidth: 300,
    comparator: noOpComparator,
    sortable: false,
  },
  {
    field: 'orderStatus',
    headerName: 'Status',
    maxWidth: 150,
    comparator: noOpComparator,
    cellRenderer: 'statusCellRenderer',
    sortable: false,
  },
  {
    field: 'creationDate',
    headerName: 'Order Creation Date',
    minWidth: 120,
    sortable: false,
    comparator: noOpComparator,
  },
  {
    field: 'paymentStatus',
    headerName: 'Payment Status',
    maxWidth: 120,
    comparator: noOpComparator,
    cellRenderer: 'paymentStatusCellRenderer',
    sortable: false,
  },
   {
    field: 'estimatedDeliveryDate',
    headerName: 'Delivery Date',
    maxWidth: 150,
    comparator: noOpComparator,
    sortable: false,
  },
];

const frameworkComponents = {
  statusCellRenderer: ({ value }) =>
    value ? (
      <StatusChip statusType={StatusType.DOCUMENT} label={value} size="small" />
    ) : null,
  paymentStatusCellRenderer: ({ value }) =>
    value === true ? (
      "Paid"
    ) : "Pending",
};

export const CDAOrderGrid = observer(({ isCda }) => {
  const { documentStore } = useDocumentStore();
  const { documentFormStore } = documentStore;
  const { cdaAndAppraisalOrderDetailsStore } = documentFormStore;
  const { cdaOrderGridStore, arrOrderGridStore } = cdaAndAppraisalOrderDetailsStore;

  useEffect(() => {
    if (isCda) {
      cdaOrderGridStore.fetchGridData();
    } else {
      arrOrderGridStore.fetchGridData();
    }
  }, [cdaAndAppraisalOrderDetailsStore]);

  return (
    <Grid
      columns={columns}
      rows={isCda ? cdaOrderGridStore.gridData.data.rows : arrOrderGridStore.gridData.data.rows}
      frameworkComponents={frameworkComponents}
      isLoading={isCda ? cdaOrderGridStore.isLoading : arrOrderGridStore.isLoading}
      showFilters={isCda ? cdaOrderGridStore.showFilters : arrOrderGridStore.showFilters}
      domLayout="autoHeight"
    />
  );
}
);
