import { configure } from 'mobx';
import { EnvironmentVariables } from './environmentStore';
import { GlobalStore } from './globalStore';
import { _BaseStore } from './_baseStore';
import { WorkerStore } from './workerStore';

export abstract class BaseStore extends _BaseStore {
  globalStore: GlobalStore;
  workerStore: WorkerStore;

  constructor(routeBasePath: string, routeBaseUrl: string, environmentVariables: EnvironmentVariables) {
    super(environmentVariables);
    this.globalStore = new GlobalStore(routeBasePath, routeBaseUrl);
    this.workerStore = new WorkerStore();
  }
}

configure({
  enforceActions: 'never',
});
