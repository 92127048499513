import { FormStore, GlobalStore } from '@roc/feature-app-core';
import { getUniqueId } from '@roc/feature-utils';
import { action, computed, flow, makeObservable, observable } from 'mobx';
import { LoanSubmissionService } from '../../services/loanSubmissionService';
import PricerSummaryStore from './pricerSummaryStore';
import { TermStore } from '../..';
import { DEFAULT_FEE_RETAIL_SERVICING_SETUP } from '../../utils/constants';

const lenderFeesForm = {
  fields: {
    originationPointsTpo: {
      value: '',
      error: null,
      rule: '',
    },
    originationPointsRoc: {
      value: '',
      error: null,
      rule: '',
    },
    originationPointsRetail: {
      value: '',
      error: null,
      rule: '',
    },
    buydownPointsTpo: {
      value: 0,
      error: null,
      rule: '',
    },
    buydownPointsRoc: {
      value: 0,
      error: null,
      rule: '',
    },
    buydownPointsRetail: {
      value: 0,
      error: null,
      rule: '',
    },
    processingFee: {
      value: '',
      error: null,
      rule: '',
    },
    rocProcessingFee: {
      value: '',
      error: null,
      rule: '',
    },
    retailProcessingFee: {
      value: '',
      error: null,
      rule: '',
    },
    adminFee: {
      value: '',
      error: null,
      rule: '',
    },
    rocAdminFee: {
      value: '',
      error: null,
      rule: '',
    },
    retailAdminFee: {
      value: '',
      error: null,
      rule: '',
    },
    underwritingFee: {
      value: '',
      error: null,
      rule: '',
    },
    rocUnderwritingFee: {
      value: '',
      error: null,
      rule: '',
    },
    retailUnderwritingFee: {
      value: '',
      error: null,
      rule: '',
    },
    commitmentFee: {
      value: '',
      error: null,
      rule: '',
    },
    rocCommitmentFee: {
      value: '',
      error: null,
      rule: '',
    },
    retailCommitmentFee: {
      value: '',
      error: null,
      rule: '',
    },
    lenderBuydownFee: {
      value: '',
      error: null,
      rule: '',
    },
    rocBuydownFee: {
      value: '',
      error: null,
      rule: '',
    },
    retailBuydownFee: {
      value: '',
      error: null,
      rule: '',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export const feeRows = [
  {
    feeName: 'Processing Fee',
    tpoFieldName: 'processingFee',
    rocFieldName: 'rocProcessingFee',
    retailFieldName: 'retailProcessingFee',
  },
  {
    feeName: 'Admin Fee',
    tpoFieldName: 'adminFee',
    rocFieldName: 'rocAdminFee',
    retailFieldName: 'retailAdminFee',
  },
  {
    feeName: 'Underwriting Fee',
    tpoFieldName: 'underwritingFee',
    rocFieldName: 'rocUnderwritingFee',
    retailFieldName: 'retailUnderwritingFee',
  },
  {
    feeName: 'Commitment Fee',
    tpoFieldName: 'commitmentFee',
    rocFieldName: 'rocCommitmentFee',
    retailFieldName: 'retailCommitmentFee',
  },
  {
    feeName: 'Buydown Fee',
    tpoFieldName: 'lenderBuydownFee',
    rocFieldName: 'rocBuydownFee',
    retailFieldName: 'retailBuydownFee',
  },
];

const sum = (a, b) => a + b;

export class LenderFeesFormStore extends FormStore {
  private globalStore: GlobalStore;
  private loanSubmissionService: LoanSubmissionService;
  private pricerSummaryStore: PricerSummaryStore;

  defaultFees;
  customFees = [];
  targetMargin;

  constructor(
    globalStore: GlobalStore,
    pricerSummaryStore: PricerSummaryStore
  ) {
    super(lenderFeesForm, globalStore);
    this.globalStore = globalStore;
    this.pricerSummaryStore = pricerSummaryStore;
    this.loanSubmissionService = new LoanSubmissionService();
    makeObservable(this, {
      defaultFees: observable,
      customFees: observable,
      removeEmptyFees: action,
      fetchDefaultFees: flow,
      loanLenderDetails: computed,
      customFeesWithPlaceholder: computed,
      totalLenderFees: computed,
      totalRocFees: computed,
      totalRetailFees: computed,
      underwritingProcessingFees: computed,
      underwritingProcessingFeesPlusRetailFees: computed,
      totalFees: computed,
      targetMargin: observable,
      rocFeeRowTotal: computed,
      rocCustomFeeTotal: computed,
      tpoFeeRowTotal: computed,
      tpoCustomFeeTotal: computed,
      retailFeeRowTotal: computed,
      retailCustomFeeTotal: computed,
      setInitialDefaultFees: action,
    });
  }

  *fetchDefaultFees(data) {
    try {
      if (!this.defaultFees) {
        const response = yield this.loanSubmissionService.getDefaultFees(data);
        this.setInitialDefaultFees(response);
      }
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching the lender fees',
      });
    }
  }

  setInitialDefaultFees(response) {
    this.defaultFees = response.data;
    this.targetMargin = response.data.targetMargin;
    this.loadForm({
      ...this.defaultFees,
      others: this.defaultFees?.lenderOtherFee,
      originationPointsTpo: this.defaultFees?.brokerOriginationPoints ? this.defaultFees.brokerOriginationPoints : this.defaultFees?.originationPointsTpo || 0,
      originationPointsRoc: this.defaultFees?.rocPoints || 0,
      originationPointsRetail: this.defaultFees?.retailPoints || 0,
      retailServicingSetupFee: DEFAULT_FEE_RETAIL_SERVICING_SETUP,
    });
    this.customFees = this.mergeLenderAndRocCustomFees();
  }

  addCustomFee(fee) {
    fee.isPlaceholder = undefined;
    this.customFees = this.customFees.concat(fee);
  }

  removeEmptyFees() {
    this.customFees = this.customFees.filter(fee => fee.feeName);
  }

  initForm(data) {
    const { loanLenderDetails } = data;

    this.loadForm({
      ...loanLenderDetails,
    });
    this.defaultFees = loanLenderDetails;
    this.customFees = this.mergeLenderAndRocCustomFees();
  }

  resetStore() {
    this.reset();
    this.defaultFees = null;
    this.customFees = [];
  }

  private mergeLenderAndRocCustomFees() {
    const lenderCustomFees = this.defaultFees?.lenderCustomFees
      ? JSON.parse(this.defaultFees?.lenderCustomFees)
      : [];
    const rocCustomFees = this.defaultFees?.rocCustomFees
      ? JSON.parse(this.defaultFees?.rocCustomFees)
      : [];
    const retailCustomFees = this.defaultFees?.retailCustomFees
      ? JSON.parse(this.defaultFees?.retailCustomFees)
      : [];

    const uniqueFeeNames = [
      ...new Set([
        ...lenderCustomFees.map(fee => fee.feeName),
        ...rocCustomFees.map(fee => fee.feeName),
        ...retailCustomFees.map(fee => fee.feeName),
      ]),
    ];
    const customFeeRows = uniqueFeeNames.map(feeName => ({
      key: getUniqueId(),
      feeName,
      tpoValue: lenderCustomFees.find(item => item.feeName === feeName)
        ?.tpoValue,
      rocValue: rocCustomFees.find(item => item.feeName === feeName)?.rocValue,
      retailValue: retailCustomFees.find(item => item.feeName === feeName)?.retailValue,
    }));
    return customFeeRows;
  }

  get customFeesWithPlaceholder() {
    return this.customFees.concat({
      isPlaceholder: true,
      feeName: '',
      tpoValue: null,
      rocValue: null,
      retailValue: null,
    });
  }

  get underwritingProcessingFees() {
    const rocFields = feeRows.map(row => row.rocFieldName);
    const formValues = this.getFormValues();

    return [
      ...rocFields.map(fieldName => formValues[fieldName] || 0),
      ...this.customFees.map(customFee => customFee.rocValue || 0),
    ];
  }

  get underwritingProcessingFeesPlusRetailFees() {
    const rocFields = feeRows.map(row => row.rocFieldName);
    const retailFields = feeRows.map(row => row.retailFieldName);
    const formValues = this.getFormValues();
    const loanAmount = this.pricerSummaryStore.pricerLoanTerms?.amount;
    const originationAmount =
      (loanAmount * ((this.form.fields.originationPointsRoc.value ?? 0) + (this.form.fields.originationPointsRetail.value ?? 0))) / 100;
    return [
      ...rocFields.map(fieldName => formValues[fieldName] || 0),
      ...retailFields.map(fieldName => formValues[fieldName] || 0),
      ...this.customFees.map(customFee => customFee.rocValue || 0),
      originationAmount,
    ];
  }

  //roc fees without points
  get rocFeeRowTotal() {
    const rocFees = feeRows
      .map(feeRow => parseFloat(this.form.fields[feeRow.rocFieldName].value || 0))
      .reduce(sum, 0);
    return rocFees + this.rocCustomFeeTotal;
  }

  get rocCustomFeeTotal() {
    const customRocFees = this.customFees
      .map(customFee => parseFloat(customFee.rocValue || 0))
      .reduce(sum, 0);
    return customRocFees;
  }

  //tpo fees without points or ysp
  get tpoFeeRowTotal() {
    const lenderFees = feeRows
      .map(feeRow => parseFloat(this.form.fields[feeRow.tpoFieldName].value || 0))
      .reduce(sum, 0);
    return lenderFees + this.tpoCustomFeeTotal;
  }

  get tpoCustomFeeTotal() {
    const customLenderFees = this.customFees
      .map(customFee => parseFloat(customFee.tpoValue || 0))
      .reduce(sum, 0);
    return customLenderFees;
  }

  //retail fees without points
  get retailFeeRowTotal() {
    const retailFees = feeRows
      .map(feeRow => parseFloat(this.form.fields[feeRow.retailFieldName].value || 0))
      .reduce(sum, 0);
    return retailFees + this.retailCustomFeeTotal;
  }

  get retailCustomFeeTotal() {
    const customRetailFees = this.customFees
      .map(customFee => parseFloat(customFee.retailValue || 0))
      .reduce(sum, 0);
    return customRetailFees;
  }

  get totalFees() {
    return this.underwritingProcessingFeesPlusRetailFees.reduce(
      (total, value) => total + value,
      0
    );
  }

  get loanLenderDetails() {
    const formValues = this.getFormValues();
    const rocCustomFees = this.customFees.filter(
      fee => fee.feeName && fee.rocValue
    );
    const lenderCustomFees = this.customFees.filter(
      fee => fee.feeName && fee.tpoValue
    );
    const retailCustomFees = this.customFees.filter(
      fee => fee.feeName && fee.retailValue
    );
    return {
      ...formValues,
      rocCustomFees: JSON.stringify(rocCustomFees),
      lenderCustomFees: JSON.stringify(lenderCustomFees),
      retailCustomFees: JSON.stringify(retailCustomFees),
    };
  }

  get totalLenderFees() {
    const fields = this.form.fields;
    const oneTimeYSP =
      this.pricerSummaryStore.form.fields.oneTimeYieldSpreadPremium.value || 0;
    const loanAmount = this.pricerSummaryStore.pricerLoanTerms?.amount;

    const originationAmount =
      (loanAmount * (fields.originationPointsTpo.value ?? 0)) / 100;
    const oneTimeYSPAmount = loanAmount * (oneTimeYSP / 100);

    return this.tpoFeeRowTotal + originationAmount + oneTimeYSPAmount;
  }

  get totalRocFees() {
    const fields = this.form.fields;
    const loanAmount = this.pricerSummaryStore.pricerLoanTerms?.amount;

    const originationAmount =
      (loanAmount * (fields.originationPointsRoc.value ?? 0)) / 100;
    const rateBuydownAmount =
      (loanAmount * this.pricerSummaryStore.form.fields.rateBuydown.value) /
      100;

    return this.rocFeeRowTotal + originationAmount + rateBuydownAmount;
  }

  get totalRetailFees() {
    const fields = this.form.fields;
    const loanAmount = this.pricerSummaryStore.pricerLoanTerms?.amount;

    const originationAmount =
      (loanAmount * (fields.originationPointsRetail.value ?? 0)) / 100;
    const rateBuydownAmount =
      (loanAmount * this.pricerSummaryStore.form.fields.rateBuydown.value) /
      100;

    return this.retailFeeRowTotal + originationAmount + rateBuydownAmount;
  }

  get totalOriginationPoints() {
    const formValues = this.getFormValues();
    return [
      formValues.originationPointsTpo,
      formValues.originationPointsRoc,
      formValues.originationPointsRetail,
    ]
      .map(value => parseFloat(value || 0))
      .reduce(sum, 0);
  }

  get totalProcessingFees() {
    const formValues = this.getFormValues();
    return [
      formValues.processingFee,
      formValues.rocProcessingFee,
      formValues.retailProcessingFee,
    ]
      .map(value => parseFloat(value || 0))
      .reduce(sum, 0);
  }

  get totalAdminFees() {
    const formValues = this.getFormValues();
    return [
      formValues.adminFee,
      formValues.rocAdminFee,
      formValues.retailAdminFee,
    ]
      .map(value => parseFloat(value || 0))
      .reduce(sum, 0);
  }

  get totalUnderwritingFees() {
    const formValues = this.getFormValues();
    return [
      formValues.underwritingFee,
      formValues.rocUnderwritingFee,
      formValues.retailUnderwritingFee,
    ]
      .map(value => parseFloat(value || 0))
      .reduce(sum, 0);
  }

  get totalCommitmentFees() {
    const formValues = this.getFormValues();
    return [
      formValues.commitmentFee,
      formValues.rocCommitmentFee,
      formValues.retailCommitmentFee,
    ]
      .map(value => parseFloat(value || 0))
      .reduce(sum, 0);
  }

  get totalBuydownFees() {
    const formValues = this.getFormValues();
    return [
      formValues.lenderBuydownFee,
      formValues.rocBuydownFee,
      formValues.retailBuydownFee,
    ]
      .map(value => parseFloat(value || 0))
      .reduce(sum, 0);
  }

  get totalBuydownPoints() {
    const formValues = this.getFormValues();
    return [
      formValues.buydownPointsTpo,
      formValues.buydownPointsRoc,
      formValues.buydownPointsRetail,
    ]
      .map(value => parseFloat(value || 0))
      .reduce(sum, 0);
  }

  get valuationAndApplicationFees() {
    const formValues = this.getFormValues();
    return [
      formValues.adminFee,
      formValues.rocAdminFee,
      formValues.retailAdminFee,
      formValues.commitmentFee,
      formValues.rocCommitmentFee,
      formValues.retailCommitmentFee,
      formValues.lenderBuydownFee,
      formValues.rocBuydownFee,
      formValues.retailBuydownFee,
      //formValues.applicationFee,
    ]
      .map(value => parseFloat(value || 0))
      .reduce(sum, 0);
  }

  get underwritingAndProcessingFees() {
    const formValues = this.getFormValues();
    return [
      formValues.underwritingFee,
      formValues.rocUnderwritingFee,
      formValues.retailUnderwritingFee,
      formValues.processingFee,
      formValues.rocProcessingFee,
      formValues.retailProcessingFee,
    ]
      .map(value => parseFloat(value || 0))
      .reduce(sum, 0);
  }

  get feeSubtotalWithoutPoints() {
    return this.rocFeeRowTotal + this.tpoFeeRowTotal + this.retailFeeRowTotal;
  }
}
