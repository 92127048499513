import { Stepper, Step, StepLabel } from '@material-ui/core';
import React, { useState, useEffect } from 'react';

export interface ProgressbarComponentProps {
  progressArray: Array<string>;
  active: string;
}

export const ProgressBarComponent: React.FC<ProgressbarComponentProps> = ({
  progressArray,
  active,
}) => {
  const [selectedStep, updateSelectedStep] = useState(0);

  useEffect(() => {
    let index = progressArray.indexOf(active);
    if (index >= 0) {
      updateSelectedStep(index);
    }
  }, [active]);

  return (
    <Stepper activeStep={selectedStep} alternativeLabel>
      {progressArray.map(label => (
        <Step key={label}>
          <StepLabel>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};
