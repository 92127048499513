import { observer } from 'mobx-react';
import { Box, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Layout } from '@roc/feature-app-core';
import { EmbeddedFrameBoundaries } from '@roc/ui';
import { BorrowerSetupToDo } from 'libs/feature-borrowers/src/borrowerSetupToDo/borrowerSetupToDo';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    overflow: 'hidden',
    backgroundColor: 'white',
    margin: '0px 0px',
    padding: '0px 0px'
  },
}));

interface BorrowerSetupToDoInternalProps {
  loanId: number;
  borrowerId: number;
  lenderId: number;
  borrowerEntityId: number;
}

export const BorrowerSetupToDoInternal = observer(({ loanId, borrowerId, lenderId, borrowerEntityId }: BorrowerSetupToDoInternalProps) => {

  const classes = useStyles();

  const loan = {
    loanId,
    lenderId,
    borrowerEntity: {
      borrowerEntityId
    },
  }

  const handleClose = () => {
    window.parent.postMessage('close-modal', '*');
  }

  const refreshLoan = () => {
    window.parent.postMessage('refresh-loan', '*');
  }

  return (
    <Box pl={3} pr={3} style={{ background: 'white', width: '100%' }}>
      <Layout title="Borrower Setup" minHeight={100} containerClass={classes.container}>
        <EmbeddedFrameBoundaries>
          <BorrowerSetupToDo
            borrowerId={borrowerId}
            loan={loan}
            handleClose={handleClose}
            refreshLoan={refreshLoan}
          />
        </EmbeddedFrameBoundaries>
      </Layout>
    </Box>
  );
});

export default BorrowerSetupToDoInternal;