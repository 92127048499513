import { Route } from 'react-router';
import { Page } from '@roc/feature-app-core';
import { useRocInternalPortalStore } from '../../../hooks';
import { StabilizedBridgeAnalyzer } from 'libs/client-portal-shared/src/app/modules/stabilizedBridgeAnalyzer';

export const getStabilizedBridgeAnalyzerInternalRoutesConfig = (
  basePath: string,
  baseUrl: string
) => {
  return {
    stabilizedBridgeAnalyzer: {
      path: `${basePath}/stabilized-bridge-analyzer`,
      url: `${baseUrl}/stabilized-bridge-analyzer`,
      documentTitle: 'Stabilized Bridge Analyzer',
    },
  };
};

export const getStabilizedBridgeAnalyzerInternalRoutes = (
  basePath: string,
  baseUrl: string
) => {
  const config = getStabilizedBridgeAnalyzerInternalRoutesConfig(basePath, baseUrl);
  const { stabilizedBridgeAnalyzerInternalStore } = useRocInternalPortalStore();

  return {
    stabilizedBridgeAnalyzer: (
      <Route exact path={config.stabilizedBridgeAnalyzer.path}>
        <Page routeDefinition={config.stabilizedBridgeAnalyzer}>
          <StabilizedBridgeAnalyzer store={stabilizedBridgeAnalyzerInternalStore} />
        </Page>
      </Route>
    ),
  };
};
