import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import {
  AssignmentTurnedIn,
  Business,
  Description,
  MonetizationOn,
  Person,
  Room
} from '@material-ui/icons';
import { LoanSuccess } from '@roc/feature-loans';
import { Layout, useBaseStore, useUserStore } from '@roc/feature-app-core';
import { DRAFT_LOAN_TYPE_LOAN, insertIf, LoanSubType, REJECTED_STATE, REQUEST_LOAN_TYPE_LOAN } from '@roc/feature-utils';
import { Paper, StandardDialog, StepsContainer } from '@roc/ui';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import MultiFamilyBorrowerInformation from './components/multifamilyBorrowerInformation';
import { BrokerInformation } from '../components/brokerInformation';
import EntityInformation from '../components/entityInformation';
import { useLoanSubmissionRoutes } from '../hooks/useLoanSubmissionRoutes';
import Summary from './components/summary';
import MultiFamilyLoanInformation from './components/multifamilyLoanInformation';
import { PropertySteps } from './components/propertySteps';
import MultiFamilyPropertyInformation from './components/multifamilyPropertyInformation';
import { useMultifamilyStore } from '../hooks/useMultiFamilyStore';
import { useRoutes } from '@roc/client-portal-shared/hooks';
import { RejectRequestForm } from '../components/rejectRequestForm';
import LoanRequestSuccess from '../../loans/components/loanSuccess/loanRequestSuccess';
import { LoanSubmissionStep } from '../utils/constants';
import { LoanDocuments } from '../components/loanDocuments';

const useStyles = makeStyles(theme =>
  createStyles({
    container: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    paper: {
      padding: theme.spacing(4),
    },
  })
);

export const MultifamilyBridge = observer(() => {
  const { globalStore } = useBaseStore();
  const { multiFamilyStore } = useMultifamilyStore();
  const classes = useStyles();
  const { push } = useHistory();
  const { loanSubmissionRoutesConfig } = useLoanSubmissionRoutes();
  const { userStore } = useUserStore();
  const { dashboardRoutesConfig } = useRoutes();
  const [openRejectReason, setOpenRejectReason] = useState(false)
  const showBrokerInformation = !globalStore.userFeatures.isBroker;
  const showDocuments = globalStore.userFeatures.uploadDuringSubmissionEnabled;

  useEffect(() => {
    multiFamilyStore.loadStore();
    multiFamilyStore.setLoanType(LoanSubType.MULTIFAMILY_BRIDGE_5PLUS);
  }, []);

  const onSubmit = () => {
    const isLoanDetailsValid =
      multiFamilyStore.loanInformationStore.form.meta.isValid;
    const isAllStepsValid = multiFamilyStore.allErrors.length === 0;
    if (!isLoanDetailsValid) {
      globalStore.notificationStore.showErrorNotification({
        message: 'Please correct the errors in Loan Details',
      });
    } else if (multiFamilyStore.allErrors.length > 0) {
      globalStore.notificationStore.showErrorNotification({
        message: 'Please correct the errors listed above',
      });
    }

    const isValid = isAllStepsValid && isLoanDetailsValid;
    if (isValid) {
      multiFamilyStore.onLoanSubmit();
    }
  };

  const onSave = (showSuccessNotification = true) => {
    return multiFamilyStore.onLoanSave(DRAFT_LOAN_TYPE_LOAN, showSuccessNotification);
  };

  const onSaveRequest = (showSuccessNotification = true) => {
    return multiFamilyStore.onLoanSave(REQUEST_LOAN_TYPE_LOAN, showSuccessNotification);
  };

  const saveDraftLoan = (showSuccessNotification = true) => {
    return multiFamilyStore.isLoanRequest ? onSaveRequest(showSuccessNotification) : onSave(showSuccessNotification)
  }

  const onRejectRequest = () => {
    setOpenRejectReason(true);
  }

  const rejectRequest = () => {
    multiFamilyStore.draftLoanInfo.status = REJECTED_STATE
    multiFamilyStore.onLoanSave(REQUEST_LOAN_TYPE_LOAN);
    push(
      dashboardRoutesConfig.dashboard.url
    )
  }

  const goNextStep = () => {
    const currentStep = loanSubmissionSteps[multiFamilyStore.activeStep].type;
    multiFamilyStore.goNextStep(currentStep);
  }

  const goPrevStep = () => {
    const currentStep = loanSubmissionSteps[multiFamilyStore.activeStep].type;
    multiFamilyStore.goPrevStep(currentStep);
  }

  const moveToStep = (stepIndex) => {
    const currentStep = loanSubmissionSteps[multiFamilyStore.activeStep].type;
    multiFamilyStore.moveToStep(stepIndex, currentStep);
  }

  const loanSubmissionSteps = [
    ...insertIf(showBrokerInformation, [{
      title: 'Broker Information',
      icon: <Person color="primary" fontSize="large" />,
      type: LoanSubmissionStep.BROKER_INFORMATION,
      onBack: () => {
        push(loanSubmissionRoutesConfig.submitloan.url);
      },
      onNext: () => goNextStep(),
      onSave: () => saveDraftLoan(),
      onReject: () => onRejectRequest(),
      allowBack: true,
      allowNext: true,
      allowSave: true,
      allowReject: multiFamilyStore.isLoanRequest
    }]),
    {
      title: 'Entity Information',
      icon: <Business color="primary" fontSize="large" />,
      type: LoanSubmissionStep.ENTITY_INFORMATION,
      onBack: () => goPrevStep(),
      onNext: () => {
        goNextStep();
      },
      onSave: () => saveDraftLoan(),
      onReject: () => onRejectRequest(),
      allowBack: true,
      allowNext: true,
      allowSave: true,
      allowReject: multiFamilyStore.isLoanRequest,
    },
    {
      title: 'Borrower Information',
      icon: <Person color="primary" fontSize="large" />,
      type: LoanSubmissionStep.BORROWER_INFORMATION,
      onBack: () => goPrevStep(),
      onNext: () => goNextStep(),
      onSave: () => saveDraftLoan(),
      onReject: () => onRejectRequest(),
      allowBack: true,
      allowNext: true,
      allowSave: true,
      allowReject: multiFamilyStore.isLoanRequest,
    },
    {
      title: 'Property Information',
      icon: <Room color="primary" fontSize="large" />,
      type: LoanSubmissionStep.PROPERTY_INFORMATION,
      onBack: () => goPrevStep(),
      onNext: () => goNextStep(),
      onSave: () => saveDraftLoan(),
      onReject: () => onRejectRequest(),
      allowBack: true,
      allowNext: true,
      allowSave: true,
      hidden: false,
      allowReject: multiFamilyStore.isLoanRequest,
    },
    {
      title: 'Loan Details',
      icon: <MonetizationOn color="primary" fontSize="large" />,
      type: LoanSubmissionStep.LOAN_DETAILS,
      onBack: () => goPrevStep(),
      onNext: () => {
        goNextStep()
      },
      onSave: () => saveDraftLoan(),
      onReject: () => onRejectRequest(),
      allowBack: true,
      allowNext: true,
      allowSave: true,
      allowReject: multiFamilyStore.isLoanRequest,
    },
    ...insertIf(globalStore.userFeatures.uploadDuringSubmissionEnabled,[{
      title: 'Documents',
      icon: <Description color="primary" fontSize="large" />,
      type: LoanSubmissionStep.LOAN_DOCUMENTS,
      onBack: () => {
        goPrevStep();
      },
      onNext: () => {
        goNextStep();
      },
      onReject: () => onRejectRequest(),
      onSave: () => saveDraftLoan(),
      allowBack: true,
      allowNext: true,
      allowSave: true,
      allowReject: multiFamilyStore.isLoanRequest,
    }]),
    {
      title: 'Summary',
      icon: <AssignmentTurnedIn color="primary" fontSize="large" />,
      type: LoanSubmissionStep.SUMMARY,
      onBack: () => goPrevStep(),
      onNext: () => userStore?.allowLoanPricing ? onSubmit() : onSaveRequest(),
      onSave: () => saveDraftLoan(),
      onReject: () => onRejectRequest(),
      allowBack: true,
      allowNext: true,
      allowSave: true,
      allowReject: multiFamilyStore.isLoanRequest,
      disableNextButton: multiFamilyStore.disableSubmitButton,
    },
  ];

  return (
    <Layout title="Submit Multifamily Bridge Loan">
      <Paper className={classes.paper}>
        {multiFamilyStore && multiFamilyStore.saved ? (
          <>
            <LoanSuccess
              loanId={multiFamilyStore?.savedLoanId}
              showDocsAndNewLoanButtons
              onStartAnotherLoan={() => {
                multiFamilyStore.reset();
                push(
                  loanSubmissionRoutesConfig.submitloan.children.multifamilyBridge
                    .url
                );
              }}
              hasFastTrack={multiFamilyStore.hasFastTrack}
              isFastTrackPaymentComplete={multiFamilyStore.isFastTrackPaymentComplete}
              fastTrackStore={multiFamilyStore.loanInformationStore}
              sendPaymentEmail={() => multiFamilyStore.sendFastTrackPayment()}
              propertiesQuantity={multiFamilyStore.properties.length}
              fastTrackEnabled={globalStore.userFeatures.fastTrack}
            />
          </>
        ) : multiFamilyStore.requestSaved ? (
          <LoanRequestSuccess
            loanRequestId={multiFamilyStore?.draftLoanInfo?.draftLoanId}
            onStartAnotherLoan={() => {
              multiFamilyStore.reset();
              push(loanSubmissionRoutesConfig.submitloan.children.multifamilyBridge.url);
            }}
          />
        ) : (
          <>
            <StepsContainer
              disableNavigation={false}
              activeStep={multiFamilyStore.activeStep}
              handleStep={moveToStep}
              steps={loanSubmissionSteps}
            >
              {[
                ...insertIf(showBrokerInformation, [<BrokerInformation />]),
                <EntityInformation store={multiFamilyStore} />,
                <MultiFamilyBorrowerInformation store={multiFamilyStore} />,
                <MultiFamilyPropertyInformation
                  renderSteps={(modalState, handleAdd, handleEdit) => (
                    <PropertySteps
                      modalState={modalState}
                      handleAdd={handleAdd}
                      handleEdit={handleEdit}
                    />
                  )}
                />,
                <MultiFamilyLoanInformation />,
                ...insertIf(showDocuments, [<LoanDocuments store={multiFamilyStore} saveDraftLoan={() => saveDraftLoan(false)} />]),
                <Summary />
              ]}
            </StepsContainer>
          </>
        )}
      </Paper>
      <StandardDialog
        open={openRejectReason}
        title="Reject Request Reason"
        maxWidth="sm"
        handleClose={() => setOpenRejectReason(false)}
        dialogContent={
          <RejectRequestForm onConfirm={() => rejectRequest()} onClose={() => setOpenRejectReason(false)} draftLoan={multiFamilyStore.draftLoanInfo} />
        }
      />
    </Layout>
  );
});
