import { GlobalStore, UserStore } from '@roc/feature-app-core';
import { QuoteGeneratorService } from '../services/quoteGeneratorService';
import { QuoteGeneratorBaseStore } from './quoteGeneratorBaseStore';
import { QuoteGeneratorDetailsStore } from './quoteGeneratorDetailsStore';
import { QuoteGeneratorGridStore } from './quoteGeneratorGridStore';

export class QuoteGeneratorStore extends QuoteGeneratorBaseStore {
  constructor(globalStore: GlobalStore, userStore: UserStore) {
    super(globalStore);
    this.quoteGeneratorService = new QuoteGeneratorService();
    this.quoteGeneratorGridStore = new QuoteGeneratorGridStore(
      globalStore,
      this.quoteGeneratorService
    );
    this.quoteGeneratorDetailsStore = new QuoteGeneratorDetailsStore(
      globalStore,
      userStore,
      this.quoteGeneratorService,
      false
    );
  }
}
