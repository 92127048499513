import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import { Box, Button, Grid, List, ListItem, ListItemText, Toolbar } from '@material-ui/core';
import google_logo from './../assets/Google_logo.png';
import zillow_logo from './../assets/zillow_logo.png';
import redfin_logo from './../assets/redfin_logo.jpg';
import { OpenInNew } from '@material-ui/icons';

const useStyles = makeStyles({
  root: {
    height: '100%'
  },
});

export const QuickLinks = () => {
  const classes = useStyles();

  return (
    <Card className={classes.root} elevation={0} variant='outlined'>
      <Toolbar style={{
        minHeight: 52,
        padding: '8px 16px',
      }}>
        <Typography variant="h6" component="div">
          Quick Links
        </Typography>
      </Toolbar>
      <CardContent style={{
        padding: 16
      }}>
        <Box>
          <Button variant='outlined' size='small' endIcon={<OpenInNew />} style={{
            marginRight: 16,
            marginBottom: 16,
          }}>
            <img src={google_logo} style={{ height: 20, width: 'auto' }}></img>
          </Button>
          <Button variant='outlined' size='small' endIcon={<OpenInNew />} style={{
            marginRight: 16,
            marginBottom: 16,
          }}>
            <img src={zillow_logo} style={{ height: 20, width: 'auto' }}></img>
          </Button>
          <Button variant='outlined' size='small' endIcon={<OpenInNew />} style={{
            marginRight: 16,
            marginBottom: 16,
          }}>
            <img src={redfin_logo} style={{ height: 20, width: 'auto' }}></img>
          </Button>
          <Button variant='outlined' size='small' style={{
            marginRight: 16,
            marginBottom: 16,
          }}>
            Scope of Work
          </Button>
          <Button variant='outlined' size='small' style={{
            marginRight: 16,
            marginBottom: 16,
          }}>
            Appraisal Report
          </Button>
          <Button variant='outlined' size='small' style={{
            marginRight: 16,
            marginBottom: 16,
          }}>
            Liquidity Report
          </Button>
          <Button variant='outlined' size='small' style={{
            marginRight: 16,
            marginBottom: 16,
          }}>
            Comp Tool
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
}
