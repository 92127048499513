import { Grid, Typography } from '@material-ui/core';
import { hammer } from 'ionicons/icons';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { EarningsCards } from '../../components/earningsCards';
import { StepTitle } from '../../components/stepTitle';
import { QuoteTypeStore } from '../../stores/quoteTypeStore';
import { LoanDetailsCardProps } from '../summary/summaryStep';
import { EconomicsCard } from './components/economicsCard';
import { useEarningsCardSticky } from '../../hooks/useEarningsCardSticky';
import { useStore } from '@roc/client-portal-shared/hooks';
import { isGroundUp } from '@roc/feature-utils';
import { useUserStore } from '@roc/feature-app-core';

interface EconomicsStepProps {
  store: QuoteTypeStore;
  LoanDetailsCard: React.FC<LoanDetailsCardProps>;
}

export const EconomicsStep = observer(
  ({ store, LoanDetailsCard }: EconomicsStepProps) => {
    const { earningsCardsStickyStyle } = useEarningsCardSticky();
    const { loanEconomicsStore, brokerStore } = store;
    const { loanEconomicsFormStore } = loanEconomicsStore;
    const { brokerFormStore } = brokerStore;
    const { globalStore } = useStore();
    const { userStore } = useUserStore();
    const loanData = store.getQuoteData();

    useEffect(() => {
      if (globalStore.lenderInfo?.isInternal && isGroundUp(loanData?.loanSubtype)) {
        const ltc = loanEconomicsStore.enableManualQuote ?
          loanEconomicsStore.manualExceptionQuoteFormStore?.calculatedFields?.calculatedLTFC :
          loanEconomicsStore.instaLeverageQuoteFormStore?.calculatedFields?.calculatedLTFC;
        const calculatedFields = loanEconomicsStore.caclulateFields(
          store.propertiesStore.getProperties(),
          loanEconomicsStore.instaLeverageQuoteFormStore.getFormValues()
        );
        store.loanEconomicsStore.getRateFloor({
          lenderName: '',
          submissionDate: new Date().toJSON(),
          leverage: ltc,
          purchasePrice: loanData?.properties[0]?.purchasePrice || 0,
          permitsInPlace: hasPermitsInPlace(loanData?.properties),
          constructionCost: loanData?.properties[0]?.renovationBudget || 0,
          interestExpense: calculatedFields?.interestExpense,
          arv: loanData?.properties[0]?.marketValueAfterCompletion || 0,
          propertyState: loanData?.properties[0]?.state,
          enableFinancedIr: isGroundUp(loanData?.loanSubtype),
          loanSubtype: loanData?.loanSubtype,
        });
      } else {
        store.loanEconomicsStore.fetchFloorRate();
      }
      store.loanEconomicsStore.fetchBorrowerPointsOptions();
    }, [
      store.quoteId,
      loanEconomicsFormStore.form.fields.loanTermMonths.value,
    ]);

    const hasPermitsInPlace = properties => {
      return properties.length === 1 && properties[0]?.permitsInPlace;
    };

    const lenderFees = loanEconomicsStore.feeStores.map(formStore => {
      return Object.keys(formStore.form.fields).map(
        field => formStore.form.fields[field].value
      );
    });

    const fields = loanEconomicsFormStore.form.fields;
    const brokertFields = brokerFormStore.form.fields;
    const hash = JSON.stringify(
      Object.keys(fields)
        .filter(
          fieldName =>
            !['capitalProviderPoints', 'lenderPoints'].includes(fieldName)
        )
        .map(fieldName => fields[fieldName].value)
    );
    const brokerHash = JSON.stringify(
      Object.keys(brokertFields).map(
        fieldName => brokertFields[fieldName].value
      )
    );
    useEffect(() => {
      loanEconomicsStore.fetchQuoteOutputsDebounced();
    }, [hash, brokerHash, lenderFees]);

    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <StepTitle
              title={<>Loan Economics</>}
              subtitle={
                <>
                  Set the rate and fees that your borrower(s) will be charged
                  below. As the rate increases, so will your estimated future
                  earnings through monthly yield spread.
                </>
              }
            />
          </Grid>
          {userStore.canSeeEarnings && (
            <Grid item xs={12} style={earningsCardsStickyStyle}>
              <EarningsCards store={store} />
            </Grid>
          )}
          <Grid item xs={12}>
            <LoanDetailsCard store={store} />
          </Grid>
          <Grid item xs={12}>
            <EconomicsCard store={store} />
          </Grid>
        </Grid>
      </>
    );
  }
);
