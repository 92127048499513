export default {
  propertyId: null,
  loanId: null,
  loanPropertyId: null,
  createdBy: null,
  creationDate: null,
  lastUpdatedBy: null,
  lastUpdateDate: null,
  propertyOwnership: null,
  exitStrategy: null,
  purchasePrice: null,
  purchaseDate: null,
  asIsValue: null,
  renovationBudget: null,
  sunkCost: null,
  afterRepairValue: null,
  notes: null,
  lienPosition: null,
  lienAmount: null,
  asIsValueSource: null,
  afterRepairValueSource: null,
  occupancy: '%',
  insuranceExpiryDate: null,
  approved: false,
  refiAmount: null,
  releaseAmount: null,
  released: null,
  asIsValueSourceChecklist: null,
  afterRepairValueSourceChecklist: null,
  asIsValueSourceNotes: null,
  afterRepairValueSourceNotes: null,
  propertySourcing: null,
  wholesaleAmount: null,
  monthlyIncome: null,
  ordering: null,
  projectedMonthlyIncome: null,
  noi: null,
  projectedNoi: null,
  describeRenovation: null,
  appraisalDate: null,
  wholesalerPurchase: null,
  armsLength: null,
  armsLengthComment: null,
  fullNetProceeds: null,
  releaseComment: null,
  inPlaceRevenue: null,
  projectedRevenue: null,
  ncf: null,
  projectedNcf: null,
  landPurchasePrice: null,
  upbSeniorLiens: null,
  projectedStabilizedOccupancy: null,
  totalRenovationBudget: null,
  propertySubType: null,
  primaryCollateral: null,
  index: 0,
  projectedRevenueMf: null,
  projectedRevenueNonMf: null,
  purchaseContractDate: null,
  parentId: null,
  streetNumber: '',
  streetName: '',
  aptNumber: '',
  city: '',
  state: null,
  zipCode: null,
  propertySourcingExplanation: null,
  anyDebt: null,
  rural: null,
  debtAmount: null,
  capitalImprovementsToDate: null,
  mapUrl: null,
  latitude: null,
  longitude: null,
  section: null,
  county: '',
  block: '',
  lot: '',
  parcel: null,
  stateId: null,
  indexNumber: null,
  zestimate: null,
  zestimateLow: null,
  zestimateHigh: null,
  zestimateMedian: null,
  zpid: null,
  useCode: '',
  lotSizeSqFt: null,
  finishedSqFt: null,
  yearBuilt: null,
  propertyType: null,
  bedrooms: 0,
  bathrooms: 0,
  neighborhoodType: null,
  deedTransferDate: null,
  deedTransferPrice: null,
  deedTransferNotes: null,
  propertyDescription: null,
  isJudicial: null,
  uploadId: null,
  appraiserId: null,
  appraiserFirmId: null,
  grossBuildingArea: null,
  grossLeasableRentableArea: null,
  monthlyRent: null,
  stories: null,
  exteriorWalls: null,
  avmValue: null,
  attomId: null,
  propertyReferenceId: -1,
  foundation: null,
  backgroundReportDate: null,
  backgroundReportUrl: null,
  yearRenovated: null,
  mostRecentAppraisedValue: null,
  mostRecentAppraisedDate: null,
  diligenceAppraisalValue: null,
  units: 0,
  residentialUnits: null,
  commercialUnits: null,
  unitType: null,
  grossLeasableRentableAreaMf: null,
  grossLeasableRentableAreaNonMf: null,
  mfUnits: null,
  nonMfUnits: null,
  nonMfUnitType: null,
  propertyConditionReport: null,
  environmentalReport: null,
  expandedProfileJson: null,
  repeatedProperty: null,
  msa: null,
  address: '',
  googleLink: '',
  zillowLink: '',
  delToForeclosureDays: null,
  judicialStatus: null,
  foreclosureDaysPercentile: null,
  additionalComments: null,
  tenants: [],
  remainingRenovationBudget: null,
  asIsComps: [],
  arvComps: [],
  comps: [],
  lenderId: null,
  addressLine1: '',
  streetNameSuffix: '',
  deltaLow: null,
  deltaHigh: null,
  deltaMedian: null,
  sumTotalOfRenoAndSunk: 0,
  nsfrLoanPropertyFields: {
    section8: false,
    acquisitionDate: null,
    acquisitionPrice: 0,
    transactionCosts: 0,
    capitalImprovements: 0,
    rehabCompletionDate: null,
    totalCostBasis: 0,
    borrowerOpinion: 0,
    totalDebtPayoff: 0,
    annualPITIA: 0,
    managerType: '',
    annualNOI: 0,
    annualDebtService: 0,
    netDSCR: 0,
    annualNCF: 0,
    ownerEntityName: '',
    loanPropertyId: null,
  },
  nsfrPropertyAdditionalInformationList: [
    {
      leaseInformationId: null,
      loanPropertyId: null,
      currentlyLeased: false,
      securityDeposit: 0,
      leaseStartDate: null,
      leaseEndDate: null,
      leaseReadyDate: null,
      monthlyGrossRent: 0,
      annualGrossRent: 0,
      otherIncomePetFees: 0,
      annualPropertyManagementFee: 0,
      annualTaxes: 0,
      annualInsurance: 0,
      ownerPaidUtilities: 0,
      annualHOA: 0,
      repairsAndMaintenance: 0,
      turnoverCost: 0,
      vacancy: 0,
      replacementReserve: 0,
      otherOwnerPaidExpenses: 0,
      source: 'borrower',
      createdBy: null,
      creationDate: null,
      lastUpdatedBy: null,
      lastUpdateDate: null,
      propertyValuation: 0,
      collectedRent: null,
      marketRent: null,
      propertyLoanAmount: null,
      releaseAmount: null,
      leaseInformation: '',
    },
  ],
  verificationDataMap: {
    borrower: {
      leaseInformation: '',
      annualGrossRent: 0,
      propertyValuation: 0,
    },
    underwriter: {
      leaseInformation: '',
      annualGrossRent: 0,
      propertyValuation: 0,
    },
  },
  arvWithMedian: '',
  asIsWithMedian: '',
  rocValue: null,
  avmValuePercentageDifference: null,
  appraisalPercentageDifference: null,
};
