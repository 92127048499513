import React, { useEffect, useState } from 'react';
import { Box, Checkbox, FormControlLabel, Grid } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Button } from '@roc/ui';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router';
import { useNewLeadStore } from '../../hooks/useNewLeadStore';
import { useRoutes } from '../../hooks/useRoutes';
import { PossibleMatches } from './possibleMatches';
import { Summary } from './summary';
import { useStore } from '@roc/client-portal-shared/hooks';
import { Borrower, Broker, TPO } from '../../utils/leadConstants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    btnFilled: {
      backgroundColor: '#1565c0',
      color: 'white',
      '&:hover': {
        backgroundColor: '#2196f3',
        boxShadow: 'none',
      },
    },
    btnOutlined: {
      color: '#1565c0',
    },
  })
);

export const Submit = observer(() => {
  const classes = useStyles();
  const { companyProfileStore, companyDropdownStore } = useStore();
  const { newLeadStore } = useNewLeadStore();
  const { userDetailStore } = newLeadStore;
  const { leadId, isInternal, leadType, companyId, companyContactId } = newLeadStore;
  const { push } = useHistory();
  const { leadDetailsRoutesConfig, lenderRoutesConfig, tpoContactDetailsRoutesConfig } = useRoutes();

  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = event => {
    setIsChecked(event.target.checked);
  };

  const handleSubmit = () => {
    newLeadStore.submitNewLead(isChecked);
  };

  const handleNext = () => {
    newLeadStore.goToSummary();
  };

  const handlePreviousStep = () => {
    userDetailStore.disabled = false;
    newLeadStore.notLoad = true;
    newLeadStore.goPrevStep();
  };

  const handleCreateAnotherLead = () => {
    newLeadStore.setDefaults();
  };

  useEffect(() => {
    if (leadId && !isInternal) {
      push(leadDetailsRoutesConfig.leadDetails(leadId).url);
      newLeadStore.setDefaults();
    }
  }, [leadId]);

  useEffect(() => {
    if (companyContactId && !isInternal) {
      push(tpoContactDetailsRoutesConfig.tpoContactDetails(companyContactId, leadType).url);
      newLeadStore.setDefaults();
    }
  }, [companyContactId]);

  useEffect(() => {
    if (companyId && leadType == TPO && !isInternal) {
      companyDropdownStore.changeCompany(companyId);
      companyProfileStore.setDisplayCompanyProfile(true);
      push(lenderRoutesConfig.lendersHome.url);
      newLeadStore.setDefaults();
    }
  }, [companyId]);

  return (
    !newLeadStore.notLoad && (
      <>
        {newLeadStore.findMatch ? <PossibleMatches /> : <Summary />}
        <Grid item xs={12}>
          {leadType !== Borrower && (
            <Box display="flex" pt={3}>
              <Grid item md={12} xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                      color="primary"
                    />
                  }
                  label={`Convert this lead to a new ${leadType == TPO ? 'TPO' : 'Broker'} account`}
                />
              </Grid>
            </Box>
          )}
          <Box display="flex" pt={8}>
            <Grid container spacing={2} justifyContent="flex-end">
              <Grid item xs={6} md={3}>
                <Button
                  variant="outlined"
                  className={classes.btnOutlined}
                  onClick={handlePreviousStep}
                  fullWidth
                  testId="back"
                >
                  Back
                </Button>
              </Grid>
              {newLeadStore.showSubmit && !newLeadStore.showViewDetails && (
                <Grid item xs={6} md={3}>
                  <Button
                    variant="outlined"
                    className={classes.btnFilled}
                    onClick={handleSubmit}
                    testId="submit"
                    fullWidth
                  >
                    Submit
                  </Button>
                </Grid>
              )}
              {!newLeadStore.dissableSubmit && newLeadStore.findMatch && (
                <Grid item xs={6} md={3}>
                  <Button
                    variant="outlined"
                    className={classes.btnFilled}
                    onClick={handleNext}
                    testId="submit"
                    fullWidth
                  >
                    Next
                  </Button>
                </Grid>
              )}
              {isInternal && leadId && (
                <Grid item xs={6} md={4}>
                  <Button
                    variant="outlined"
                    className={classes.btnFilled}
                    onClick={handleCreateAnotherLead}
                    testId="submit"
                    fullWidth
                  >
                    Create Another Lead
                  </Button>
                </Grid>
              )}
            </Grid>
          </Box>
        </Grid>
      </>
    )
  );
});
