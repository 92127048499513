import { Box, Grid, Link, Typography } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useBorrowerPortalMediaQueries } from '@roc/feature-app-core';
import {
  useBorrowerLoanApplicationsRoutes,
  useBorrowerLoansRoutes
} from '@roc/feature-borrower-loans';
import { Button } from '@roc/ui';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router';
import { thankYouStepBg } from '../assets';
import { useNewLoanApplicationStore } from '../hooks/useNewLoanApplicationStore';

const useStyles = makeStyles(theme => ({}));

export const ThankYouStep = observer(() => {
  const { newLoanApplicationStore } = useNewLoanApplicationStore();
  const { borrowerLoanRoutesConfig } = useBorrowerLoansRoutes();
  const { push } = useHistory();
  const { newLoanId } = newLoanApplicationStore;
  const theme = useTheme();
  const {
    borrowerLoanApplicationRoutesConfig,
  } = useBorrowerLoanApplicationsRoutes();
  const { isLargeScreen, isSmallScreen } = useBorrowerPortalMediaQueries();

  return (
    <Grid container>
      <Grid xs={12} md={12}>
        <Box
          height="100%"
          maxWidth="412px"
          margin="0 auto"
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
          textAlign={'center'}
        >
          <Box p={2}>
            <Typography style={{ fontSize: '48px' }}>Thank You!</Typography>
          </Box>
          <Box p={2}>
            <Typography style={{ fontSize: '20px' }}>
              Your loan application was successfully submitted.
            </Typography>
          </Box>
          <Box p={2}>
            <Typography style={{ fontSize: '20px' }}>
              Your loan application request Id is{' '}
              <Link
                style={{ color: theme.palette.primary.main, cursor: 'pointer' }}
                onClick={() =>
                  push(
                    borrowerLoanApplicationRoutesConfig.loans('' + newLoanId)
                      .url
                  )
                }
              >
                {newLoanId || ''}
              </Link>
            </Typography>
          </Box>
          <Box p={2}>
            <Button
              testId="go-home"
              variant="outlined"
              color="primary"
              fullWidth
              onClick={() => push(borrowerLoanRoutesConfig.loansHome.url)}
            >
              BACK TO HOME
            </Button>
          </Box>
          <Box p={2}>
            <Button
              testId="start-another-loan"
              variant="contained"
              color="primary"
              fullWidth
              onClick={() =>
                newLoanApplicationStore.openNewLoanApplicationModal()
              }
            >
              START ANOTHER LOAN
            </Button>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
});
