
import { Box, Grid, Paper, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { ProgressBarComponent, StandardDialog } from '@roc/ui';
import {
  Documents,
  useDocumentCommunicationStore,
  useDocumentStore
} from '@roc/feature-documents';
import { DocumentForms } from '@roc/feature-documents';
import CopyUrlModal from '../drawRequests/components/copyUrlModal';
import {
  SOW_REVISION_STAGE,
  DRAWS_PROCESS,
  ON_HOLD,
} from '../constants/draws';
import { useDrawsStore } from '../hooks';
import { Alert } from '@material-ui/lab';
import { DrawReviewReportShared } from '../drawReviewReport/components/drawReviewReportShared';
import { DrawRequestFormShared } from '../drawRequestForm/drawRequestFormShared';
import { useLoanStore } from '@roc/feature-loans';

export const DrawDetailsShared = observer(
  ({ loanId, drawId, adminSpecialist, reviewSpecialist }:
    { loanId: string; drawId: string; adminSpecialist: string; reviewSpecialist: string; }) => {
    const { drawStore, drawRequestStore, drawReviewReportStore } = useDrawsStore();
    const { documentStore } = useDocumentStore();
    const { loanStore } = useLoanStore();
    const [isCopyUrlModalOpen, setIsCopyUrlModalOpen] = useState(false);
    const [isCopyUrlForPropertyPhotosModalOpen, setIsCopyUrlForPropertyPhotosModalOpen] = useState(false);

    // Communication
    const { documentCommunicationStore } = useDocumentCommunicationStore();
    const allowCommunication = documentStore.isCommunicationAllowed(DRAWS_PROCESS.toLowerCase());
    const twilioCommunicationEnabled = drawStore.currentDraw?.twilioCommunicationEnabled;

    const handleSubmit = async () => {
      // TODO: add Submit API call
      await drawRequestStore.submitForm(drawId);
      drawStore.fetchDrawById(drawId);
      documentStore.openDrawRequestModal = false;
      documentStore.fetchDrawDocuments(loanId, drawId);
    };

    const handleSave = () => {
      // TODO: add save API call
      drawRequestStore.saveForm(drawId);
      documentStore.openDrawRequestModal = false;
    };

    useEffect(() => {
      drawStore.fetchDrawById(drawId);
      return () => {
        drawStore.resetCurrentDraw();
        documentStore.resetDrawDocuments();
        documentStore.resetDrawComments();
      };
    }, []);

    useEffect(() => {
      if (drawStore.currentDraw) {
        if (allowCommunication && !twilioCommunicationEnabled) {
          documentStore.fetchUnreadDrawComments(loanId, drawId);
        }
        documentStore.fetchDrawDocuments(loanId, drawId);
      }
    }, [drawStore.currentDraw]);

    useEffect(() => {
      if (documentStore.openShareLinkModal) {
        drawRequestStore.getURL();
        setIsCopyUrlModalOpen(true);
      }
    }, [documentStore.openShareLinkModal]);

    useEffect(() => {
      if (documentStore.openSharePropertyPicturesLinkModal) {
        setIsCopyUrlForPropertyPhotosModalOpen(true);
      }
    }, [documentStore.openSharePropertyPicturesLinkModal]);

    useEffect(() => {
      if (drawStore.currentDraw && !documentCommunicationStore?.isDrawerOpen) {
        documentStore.fetchUnreadDrawComments(loanId, drawId);
      }
    }, [documentCommunicationStore?.isDrawerOpen]);

    if (!drawStore.currentDraw || !documentStore.drawDocuments) {
      return <></>;
    }

    const handleCopyUrlModalClose = () => {
      documentStore.openShareLinkModal = false;
      setIsCopyUrlModalOpen(false);
    };

    const handleCopyUrl = () => {
      navigator.clipboard.writeText(drawRequestStore.url);
      handleCopyUrlModalClose();
    };

    const handleCopyPropertyPhotosUrlModalClose = () => {
      documentStore.openSharePropertyPicturesLinkModal = false;
      setIsCopyUrlForPropertyPhotosModalOpen(false);
    };

    const handleCopyPropertyPhotosUrl = () => {
      navigator.clipboard.writeText(drawStore.propertyPhotosUrl);
      handleCopyPropertyPhotosUrlModalClose();
    };

    const formatName = (name) => {
      var split = name.split(" ");
      var nameFormatted = split[0] + ' ' + split[1].charAt(0).toUpperCase() + '.';
      return nameFormatted;
    }

    return (
      <>
        {drawStore.currentDraw.stage === ON_HOLD &&
          <Grid item xs={12}>
            <Alert severity='warning'>Draw Request is currently On Hold</Alert>
          </Grid>
        }
        <Grid item xs={12}>
          <Paper>
            <Box p={2}>
              <Typography component="span" style={{ display: 'inline-block', marginRight: 100 }}>
                <Typography component="span" color="textSecondary">
                  {'Admin Specialist: '}
                </Typography>
                <strong>
                  {adminSpecialist ? formatName(adminSpecialist) : ''}
                </strong>
              </Typography>
              <Typography component="span" style={{ display: 'inline-block' }}>
                <Typography component="span" color="textSecondary">
                  {'Review Specialist: '}
                </Typography>
                <strong>
                  {reviewSpecialist ? formatName(reviewSpecialist) : ''}
                </strong>
              </Typography>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          {drawStore.currentDraw.stage !== SOW_REVISION_STAGE &&
            drawStore.currentDraw.stage !== ON_HOLD &&
            drawStore.currentDraw.stageArray?.length > 0 && (
              <ProgressBarComponent
                progressArray={drawStore.currentDraw.stageArray}
                active={drawStore.currentDraw.stage}
              />
            )}
        </Grid>
        <Grid item container spacing={2}>
          {documentStore.documentSections.map((element, index) => {
            if (element.loanProcess === DRAWS_PROCESS) {
              return (
                <Grid key={element.sectionName} item xs={12}>
                  <Documents
                    section={element.sectionName}
                    loanProcess={'draws'}
                    loanId={loanId}
                    drawId={drawId}
                    extensionNumber={null}
                    loanStore={loanStore}
                  />
                </Grid>
              );
            }
          })}
          <DocumentForms loanId={loanId} />
        </Grid>
        <StandardDialog
          open={documentStore.openDrawRequestModal}
          title="Draw Request Form"
          maxWidth="xl"
          handleClose={() => (documentStore.openDrawRequestModal = false)}
          dialogContent={
            <DrawRequestFormShared
              onSubmit={handleSubmit}
              onSave={handleSave}
              store={drawRequestStore}
              drawId={drawId}
            />
          }
        />

        <StandardDialog
          open={documentStore.openDrawReviewModal}
          maxWidth="xl"
          handleClose={() => (documentStore.openDrawReviewModal = false)}
          dialogContent={
            <DrawReviewReportShared
              store={drawReviewReportStore}
              isInternal={false}
            />
          }
        />

        <CopyUrlModal
          handleClose={handleCopyUrlModalClose}
          handleCopy={handleCopyUrl}
          isOpen={isCopyUrlModalOpen}
          url={drawRequestStore.url}
        />
        <CopyUrlModal
          handleClose={handleCopyPropertyPhotosUrlModalClose}
          handleCopy={handleCopyPropertyPhotosUrl}
          isOpen={isCopyUrlForPropertyPhotosModalOpen}
          url={drawStore.propertyPhotosUrl}
        />
      </>
    );
  }
);

export default DrawDetailsShared;
