import { Box, Tooltip } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Delete, Edit, Person, Warning } from '@material-ui/icons';
import { Button, IconButton } from '@roc/ui';
import { observer } from 'mobx-react';
import {
  PublicLoanCardDividers,
  PublicLoanCardField,
  PublicLoanCard,
} from './loanSummaryComponents';

export const BorrowersCard = observer(({ store }) => {
  const theme = useTheme();
  const { borrowerInformationStore, borrowerEntityInformationStore } = store;
  const { mainBorrowerFormStore, coBorrowersFormStores } = borrowerInformationStore;
  const { borrowerEntityFormStore } = borrowerEntityInformationStore;

  return (
    <PublicLoanCard
      icon={<Person />}
      title={'Borrowers'}
    // error={showTotalOwnershipWarning}
    >
      <PublicLoanCardDividers px={2} py={2}>
        <PublicLoanCardField
          label={
            <>
              {mainBorrowerFormStore.form.fields.firstName.value} {mainBorrowerFormStore.form.fields.lastName.value}
            </>
          }
          value={
            <Box display={'flex'} alignItems={'center'}>
              {borrowerEntityFormStore.hasEntityInfo && <>{mainBorrowerFormStore.form.fields.pctOwnership.value || '0'}% Ownership</>}
            </Box>
          }
        />
        {coBorrowersFormStores.map((borrower, index) => (
          <PublicLoanCardField
            label={
              <>
                {borrower.form.fields.firstName.value} {borrower.form.fields.lastName.value}
              </>
            }
            value={
              <Box display={'flex'} alignItems={'center'}>
                {borrowerEntityFormStore.hasEntityInfo && coBorrowersFormStores.length > 0 && (
                  <>{borrower.form.fields.pctOwnership.value || '0'}% Ownership</>
                )}
              </Box>
            }
          />
        ))}
      </PublicLoanCardDividers>
    </PublicLoanCard>
  );
});
