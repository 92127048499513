import { GlobalStore } from '@roc/feature-app-core';
import { flow, makeObservable, observable } from 'mobx';
import { BrokerService } from '../services/brokerService';
import { BrokerFormStore } from './brokerFormStore';
import { BrokersGridStore } from './brokersGridStore';
import { ManageBrokersStore } from './manageBrokersStore';
import { SelectBrokersStore } from './selectBrokersStore';
import { ManageBrokerFormStore } from './manageBrokerFormStore';
import { BrokerContactsGridStore } from './brokerContactsGridStore';
import { BrokerContactsSummaryGridStore } from './brokerContactsSummaryGridStore';
import { BrokerFeesStore } from './brokerFeesStore';
import { BrokerVendorClosingSettingsFormStore } from './brokerVendorClosingSettingsFormStore';
import CompanyFormStore from 'libs/feature-loans/src/loanSubmission/stores/common/companyFormStore';
import { InsuranceCompanyFormStore } from 'libs/feature-loans/src/loanSubmission/stores/common/insuranceCompanyFormStore';
import { TitleCompanyFormStore } from 'libs/feature-loans/src/loanSubmission/stores/common/titleCompanyFormStore';

export class BaseBrokersStore {
  private globalStore: GlobalStore;
  brokerService: BrokerService;
  selectBrokersStore: SelectBrokersStore;
  brokersGridStore: BrokersGridStore;
  brokerFormStore: BrokerFormStore;
  brokerFeesStore: BrokerFeesStore;

  manageBrokersStore: ManageBrokersStore;
  manageBrokerFormStore: ManageBrokerFormStore;
  brokerContactsGridStore: BrokerContactsGridStore;
  brokerContactsSummaryGridStore: BrokerContactsSummaryGridStore;
  brokerVendorClosingSettingsFormStore: BrokerVendorClosingSettingsFormStore;
  otherInsuranceFormStore: CompanyFormStore;
  otherTitleFormStore: CompanyFormStore;
  onTheHud: boolean;
  isTermLoan: boolean;


  constructor(globalStore: GlobalStore, brokerService: BrokerService) {
    this.globalStore = globalStore;
    this.brokerService = brokerService;
    this.brokersGridStore = new BrokersGridStore(this, globalStore);
    this.brokerFormStore = new BrokerFormStore(globalStore);
    this.selectBrokersStore = new SelectBrokersStore(this, globalStore);
    this.manageBrokersStore = new ManageBrokersStore(this, globalStore);
    this.manageBrokerFormStore = new ManageBrokerFormStore(globalStore);
    this.brokerContactsGridStore = new BrokerContactsGridStore(this, globalStore);
    this.brokerContactsSummaryGridStore = new BrokerContactsSummaryGridStore(this, globalStore);
    this.brokerFeesStore = new BrokerFeesStore(this, globalStore);
    this.brokerVendorClosingSettingsFormStore = new BrokerVendorClosingSettingsFormStore(this.globalStore)
    this.otherInsuranceFormStore = new InsuranceCompanyFormStore(this.globalStore);
    this.otherTitleFormStore = new TitleCompanyFormStore(this.globalStore);
    this.onTheHud = true;
    this.isTermLoan = false;
    makeObservable(this, {
      onTheHud: observable,
      setOnTheHud: flow,
      isTermLoan: observable,
      setIsTermLoan: flow,
    });
  }

  reset() {
    this.brokerFormStore.reset();
    this.manageBrokerFormStore.reset();
    this.brokersGridStore.resetSelected();
    this.selectBrokersStore.reset();
    this.brokerContactsGridStore.resetSelected();
    this.brokerVendorClosingSettingsFormStore.reset();
    this.onTheHud = true;
    this.isTermLoan = false;
  }

  setOnTheHud(value) {
    this.onTheHud = value;
  }

  setIsTermLoan(value) {
    this.isTermLoan = value;
  }
}
