import { Box, Typography } from '@material-ui/core';
import React from 'react';
import CropOriginalIcon from '@material-ui/icons/CropOriginal';

export const NoImageAvailable = () => {
  return (
    <Box display="flex" alignItems="center" justifyContent="center" flexDirection={'column'} width={'100%'} height={'100%'} style={{ backgroundColor: '#EBECF0' }}>
      <Typography style={{ fontSize: '1.5vw', color: '#949494' }}>
        Image Not Available
      </Typography>
      <CropOriginalIcon style={{ width: '15%', height: '15%', color: '#949494' }} />
    </Box>
  );
};
