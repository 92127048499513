import { useEffect, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Layout } from '@roc/feature-app-core';
import { Tabs, Paper, GridColumnProps } from '@roc/ui';
import { observer } from 'mobx-react';
import { ProtectiveAdvancesGrid } from '../protectiveAdvancesGrid';
import { CounterPartyExpensesTab } from '../../types/types';
import { useReceivablesDashboardStore } from '../../hooks/useReceivablesDasboardStore';
import { formatCurrency, filterProps, noOpComparator } from '@roc/ui/utils';
import { ReceivablesToolbar } from '../receivables/receivablesToolbar';
import { Box, Grid, IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@material-ui/core';
import { CloudDownload } from '@material-ui/icons';
import { useDocumentStore } from '@roc/feature-documents';
import { insertIf } from '@roc/feature-utils';

const TABS = [
  {
    value: CounterPartyExpensesTab.BILL_RECEIVABLE,
    label: 'Billed',
  },
  {
    value: CounterPartyExpensesTab.ALL_BILL_RECEIVABLE,
    label: 'All',
  },
];


const useStyles = makeStyles((theme: Theme) => ({
  container: {
    //Same as nportal dashboard
    maxWidth: '1400px',
  },
  paper: {
    boxShadow: 'none !important',
    border: '1px solid #cdcdcd',
    padding: '0px',
    borderRadius: '0 !important',
  },
}));


export const ReceivablesBillsDashboard = observer(() => {
  const classes = useStyles();
  const { receivablesBillsStore } = useReceivablesDashboardStore();
  const { tab } = receivablesBillsStore;
  const { documentStore } = useDocumentStore();

  useEffect(() => {
    receivablesBillsStore.fetchEntityNames();
    return () => {
      receivablesBillsStore.reset();
    };
  }, []);

  const RECEIVABLES_BILLS_COLUMNS: GridColumnProps[] = [
    {
      field: 'billId',
      headerName: 'Bill Id',
      width: 90,
      comparator: noOpComparator,
      ...filterProps(),
    },
    {
      field: 'entity',
      headerName: 'Investor',
      minWidth: 125,
      comparator: noOpComparator,
      ...filterProps(),
    },
    ...insertIf(tab === CounterPartyExpensesTab.BILL_RECEIVABLE, [{
      field: 'status',
      headerName: 'Status',
      width: 125,
      comparator: noOpComparator,
      ...filterProps(),
      editable: true,
      cellEditor: 'statusReceivablesBillsEditor',
    },
    ]),
    ...insertIf(tab === CounterPartyExpensesTab.ALL_BILL_RECEIVABLE, [{
      field: 'status',
      headerName: 'Status',
      width: 125,
      comparator: noOpComparator,
      ...filterProps(),
    },
    ]),
    {
      field: 'amount',
      headerName: 'Total Bill Amount',
      minWidth: 125,
      cellRenderer: 'currencyCellRenderer',
      comparator: noOpComparator,
      ...filterProps(),
    },
    {
      field: 'createdDate',
      headerName: 'Creation Date',
      minWidth: 125,
      cellRenderer: 'dateCellRenderer',
      comparator: noOpComparator,
      ...filterProps(),
    },
    ...insertIf(tab === CounterPartyExpensesTab.ALL_BILL_RECEIVABLE, [
      {
        field: 'paidDate',
        headerName: 'Bill Payment Date',
        minWidth: 125,
        cellRenderer: 'dateCellRenderer',
        comparator: noOpComparator,
        ...filterProps(),
      },
    ]),
    {
      field: 'comments',
      headerName: 'Comments',
      minWidth: 125,
      comparator: noOpComparator,
      ...filterProps(),
      editable: true,
      cellEditor: 'commentBillsEditor',
    },
    {
      field: 'leadId',
      headerName: 'Action',
      minWidth: 80,
      cellRenderer: 'actionCellRenderer',
      comparator: noOpComparator,
      width: 80,
      pinned: 'right',
      lockPinned: true,
    },
  ];

  const actions = (record, handleMenuClose) => {

    return (
      [
        <MenuItem
          onClick={() => {
            receivablesBillsStore.downloadBillDocuments(record.recordId);
            handleMenuClose();
          }}
        >
          <ListItemIcon>
            <CloudDownload fontSize="small" />
          </ListItemIcon>
          <ListItemText>Download Invoices Files</ListItemText>
        </MenuItem>,
        <MenuItem
          onClick={() => {
            receivablesBillsStore.exportBillInvoicesData(record.recordId);
            handleMenuClose();
          }}
        >
          <ListItemIcon>
            <CloudDownload fontSize="small" />
          </ListItemIcon>
          <ListItemText>Download Invoices Report</ListItemText>
        </MenuItem>
      ]
    );
  }


  return (
    <Layout title="Receivables - Bills" containerClass={classes.container}>
      <Box pb={2}>
        <Tabs
          selectedTab={TABS.findIndex(t => t.value == tab)}
          tabs={Object.values(TABS)}
          onTabChange={(e, i) => receivablesBillsStore.setTab(TABS[i].value)}
        />
      </Box>
      <Box display="flex" flexDirection="column" height="100%">
        <Box flex={1}>
          <Paper className={classes.paper}>
            <ProtectiveAdvancesGrid
              counterPartyExpenseStore={receivablesBillsStore}
              columns={RECEIVABLES_BILLS_COLUMNS}
              selectModeEnabled={false}
              toolbar={
                <ReceivablesToolbar
                  receivablesDashboardStore={receivablesBillsStore}
                />}
              actions={actions}
            />
          </Paper>
        </Box>
      </Box>
    </Layout>
  );
});
