import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Theme, Typography, makeStyles } from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { Paper, SelectField } from "@roc/ui";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { RadialBar, RadialBarChart, ResponsiveContainer } from "recharts";

const useStyles = makeStyles((theme: Theme)=>({
  title: {
    fontSize: '20px',
    paddingLeft: theme.spacing(1),
    marginBottom: '0px'
  },
  select: {
    padding: theme.spacing(2)
  }
}))

const options = [
  {value:"LOW",label:"LOW"},
  {value:"MODERATE",label:"MODERATE"},
  {value:"FAIR",label:"FAIR"},
  {value:"HIGH",label:"HIGH"}
]

const sowOptions = [
  {value:"MUST BE AMENDED",label:"MUST BE AMENDED"},
  {value:"MISSING ITEMS",label:"MISSING ITEMS"},
  {value:"DETAILED & BALANCE",label:"DETAILED & BALANCE"}
]

export const ConstructionFeasibilityReview = observer(({store})=>{
  const classes = useStyles();

  return (
    <>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMore/>}>
          <Typography align="left" className={classes.title}>
            Construction Feasibility Review
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <ChartCard title={"Valuation"} options={options} store={store} fieldName={"valuation"}/>
            </Grid>
            <Grid item xs={4}>
              <ChartCard title={"Scope of Work Assessment"} options={sowOptions} store={store} fieldName={"scopeofWorkAssessment"}/>
            </Grid>
            <Grid item xs={4}>
              <ChartCard title={"Data Quality Assessment"} options={options} store={store} fieldName={"dataQualityAssessmnet"}/>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  )
})

const ChartCard = observer(({title, options, store, fieldName})=>{

  const classes = useStyles();

  const [status, setStatus] = useState('');
  useEffect(()=>{
    setStatus(store?.form?.fields[fieldName]?.value || options[0]?.value)
  },[store?.form?.fields[fieldName]?.value]);

  const handleChange = value => {
    setStatus(value);
    store.onFieldChange(fieldName, value);
  };

  const getData = () => {
    switch (status) {
      case "DETAILED & BALANCE":
        return { value: 100, fill: '#00C49F'};
      case "MISSING ITEMS":
        return { value: 70, fill: '#FFBB28'};
      case "MUST BE AMENDED":
        return { value: 30, fill: '#FF8042'};
      case 'HIGH':
        return { value: 100, fill: '#00C49F'};
      case 'FAIR':
        return { value: 75, fill: '#FFBB28'};
      case 'MODERATE':
        return { value: 50, fill: '#FF8042'};
      case 'LOW':
        return { value: 25, fill: '#C80303'};
      default:
        return { value: 0, fill: '#CCC'};
    }
  }

  const data = [
    {name: 'Background', value:100, fill: '#FFFFFF'},
    {name: 'Gauge', ...getData()}];

  return (
    <Paper>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography align="center">
            {title}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <ResponsiveContainer width="100%" height={200}>
            <RadialBarChart
              cx="50%"
              cy="50%"
              innerRadius="70%"
              outerRadius="90%"
              barSize={40}
              data={data}
              startAngle={210}
              endAngle={-30}
            >
              <RadialBar
                background
                dataKey="value"
                cornerRadius={20}
              />
              <text
                x="50%"
                y={status.split(' ').length === 1 ? "50%": "40%"}
                textAnchor="middle"
                dominantBaseline="middle"
                style={{fontSize: '16px', fontWeight: 'bold'}}
              >
                {status.split(' ').map((word, index)=>(
                  <tspan x="50%" dy={index === 0 ? 0:"1.2em"} key={index}>
                    {word}
                  </tspan>
                ))}
              </text>
            </RadialBarChart>
          </ResponsiveContainer>
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center" mb={2} className={classes.select}>
            <SelectField
              name="status"
              options={options}
              variant="outlined"
              value={status}
              onChange={handleChange}
              testId="status"
              fullWidth
            />
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
})