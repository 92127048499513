import React, { useEffect } from 'react';

import { Grid, InputAdornment, TextField, Typography } from '@material-ui/core';

import { useHistory } from 'react-router-dom';
import MailIcon from '@material-ui/icons/Mail';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { LoginLayout } from './components/loginLayout';
import { useAppRoutes } from '../../hooks/useAppRoutes';
import { useStore } from '../../hooks';
import { isNotBlank } from '@roc/client-portal-shared/utils';
import { observer } from 'mobx-react';
import { Button } from '@roc/ui';
import { LoginLogoImg } from '@roc/feature-app-core';

const useStyles = makeStyles(theme =>
  createStyles({
    gridContainer: {
      padding: theme.spacing(4),
      maxWidth: 500,
      margin: 'auto',
    },
    linkBox: {
      marginTop: theme.spacing(10),
      alignItems: 'center',
      align: 'center',
      textAlign: 'center',
    },
    link: {
      cursor: 'Pointer',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    helperText: {
      position: 'relative',
      marginTop: theme.spacing(2),
      textAlign: 'center',
    },
    loginButton: {
      marginTop: theme.spacing(2),
      color: 'white',
      backgroundColor: theme.palette.primary.main,
    },
  })
);

export const ForgotPassword = observer(() => {
  const classes = useStyles();
  const history = useHistory();
  const { publicRoutes } = useAppRoutes();
  const { forgotPasswordStore } = useStore();
  const { form } = forgotPasswordStore;

  useEffect(() => {
    forgotPasswordStore.setSuccessUrl(
      publicRoutes.successforgotpasswordemail.url
    );
  }, []);

  const handleHasAccount = () => {
    history.push(publicRoutes.login.url);
  };
  const handleFormSubmit = () => {
    form.meta.isValid && forgotPasswordStore.onFormSubmit();
  };

  const keyPress = e => {
    if (e.key === 'Enter') {
      handleFormSubmit();
    }
  };

  return (
    <LoginLayout>
      <Grid container justifyContent="center" className={classes.gridContainer}>
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={12}>
            <LoginLogoImg />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" align="center">
              Enter your email and we'll send you a password reset link.
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.linkBox}>
            <TextField
              fullWidth
              variant="outlined"
              label="Email"
              type="email"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MailIcon />
                  </InputAdornment>
                ),
              }}
              value={form.fields.email.value}
              onChange={e =>
                forgotPasswordStore.onFieldChange('email', e.target.value)
              }
              onKeyDown={keyPress}
              helperText={form.fields.email.error}
              error={isNotBlank(form.fields.email.error)}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              fullWidth
              className={classes.loginButton}
              variant="contained"
              type="submit"
              disabled={!form.meta.isValid}
              onClick={() => forgotPasswordStore.onFormSubmit()}
              testId="submit"
            >
              <span>SEND REQUEST</span>
            </Button>
            <Typography
              className={`${classes.link} ${classes.helperText}`}
              onClick={handleHasAccount}
              color="textSecondary"
              variant="body2"
            >
              Already have an account?
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </LoginLayout>
  );
});