import { flow, makeObservable, observable, override, toJS } from 'mobx';
import { InternalPricerService } from '../services/internalPricerService';
import { InternalPricerFormValues } from '../types/internalPricerTypes';
import { mapInvestorToName, mapLtvDecimalToRange } from '../utils/internalPricerUtils';
import { InternalPricerBaseStore } from './internalPricerBaseStore';

export class InternalPricerStore extends InternalPricerBaseStore {
  private internalPricerService: InternalPricerService;
  public lenderNames: { label: string; value: string }[] = [];

  constructor(globalStore) {
    super(globalStore);
    this.internalPricerService = new InternalPricerService();
    this.isClientPortal = false;

    makeObservable(this, {
      getRatesDictionary: override,
      getLoanValues: override,
      getAutocompleteOptions: override,
      getDefaultValues: override,
      getMonthlyPayment: override,
      getPricerValues: override,
      getMarketTier: override,
      getLenderNames: override,
      lenderNames: observable,
    });
  }

  *getRatesDictionary(data: InternalPricerFormValues) {
    const response = yield this.internalPricerService.getRatesDictionary(data);
    return response.data;
  }

  *getLoanValues(loanId: string, isDraft: boolean): InternalPricerFormValues {
    const response = yield this.internalPricerService.getLoanValues(loanId, isDraft);
    return response.data;
  }

  *getAutocompleteOptions(searchTerm: string) {
    const filters = {
      dealName: searchTerm,
    };
    const response = yield this.internalPricerService.getRentalDealNames(
      1,
      25,
      null,
      null,
      filters
    );
    const rows = response.data.rows;
    this.loanSearchOptions = rows.map(row => ({
      label: row.dealName,
      value: row.dealid ? row.dealid : 'DRAFT' + row.draftid,
    }));
  }

  *getDefaultValues() {
    const response = yield this.internalPricerService.getDefaultValues();
    return response.data.data;
  }

  *getStateCounties() {
    const response = yield this.internalPricerService.getStateCounties();
    return response.data;
  }

  *getMonthlyPayment(data: any) {
    const response = yield this.internalPricerService.getMonthlyPayment(data);
    return response.data;
  }

  *getPricerValues(formValues) {
    let ratesArray = [];
    let allInvestorBuydowns = yield this.internalPricerService.getInvestorBuydowns();
    this.calculatedValues = [];

    ratesArray = this.investors[formValues["rateType"]][formValues["amortizationType"]];
    //this.maxYsp = this.investors.maxYspBasedonAmortizationType[formValues["amortizationType"]];
    for (let investor of ratesArray) {
      let investorName = "";
      if (investor && investor.investorId) investorName = mapInvestorToName(investor.investorId);
      const ltvRange = mapLtvDecimalToRange(formValues["requestedLtv"]);
      if (investor) {
        if (investor.baseRates[ltvRange].spreadRate && investor.baseRates[ltvRange].swapRate) {
          const rate = investor.baseRates[ltvRange].swapRate + investor.baseRates[ltvRange].spreadRate;
          const payment = yield this.fetchMonthlyPayment(rate);
          const buydownRatio = parseFloat(this.getBuydown(allInvestorBuydowns, investor.investorId));
          this.calculatedValues?.push(
            {
              investorName: investorName,
              spreadRate: investor.baseRates[ltvRange].spreadRate + '%',
              swapRate: investor.baseRates[ltvRange].swapRate + '%',
              swapIndex: investor.baseRates[ltvRange].swapIndex,
              grossRate: (parseFloat(investor.baseRates[ltvRange].spreadRate) + parseFloat(investor.baseRates[ltvRange].swapRate)).toFixed(4) + '%',
              monthlyPayment: payment,
              buydownFee: buydownRatio != 0 ? (parseFloat(formValues["currentBuydown"]) / buydownRatio).toFixed(4) + '%' : 0,
            }
          )
        }
        else {
          this.calculatedValues?.push(
            {
              investorName: investorName,
              spreadRate: investor.baseRates[ltvRange].errorMessage,
              swapRate: investor.baseRates[ltvRange].errorMessage,
              swapIndex: investor.baseRates[ltvRange].swapIndex,
              grossRate: '-',
              monthlyPayment: '',
              buydownFee: 0
            }
          )
        }
      }
    }

  }

  *getMarketTier(stateCounty) {
    const response = yield this.internalPricerService.getMarketTier(stateCounty);
    return response;
  }

  getBuydown(investorBuydowns, investor) {
    const investorId = investor + '.0';
    let investorBuydown = investorBuydowns?.data[investorId];

    for (let rateTypeBuydown of investorBuydown) {
      if (rateTypeBuydown.rateTerm == this.form.fields.rateType.value) {
        return rateTypeBuydown.buydownRatio;
      }
    }
    return 0;
  }

  async *getLenderNames() {
    const response = yield this.internalPricerService.getAllLenderNames();
    this.lenderNames = toJS(response.data);
  }
}