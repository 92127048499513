const mapper = {
  'Tree/Vegetation Clearing/Demo/Relocate, Earthwork, Grading, Soil Remediation': {
    helperText: 'Please provide the cost and details of the following items',
    sections: [
      {
        title: 'Site Cleaning',
        tooltip: 'To collect liquids.',
        fields: [
          {
            label: 'Amount',
            name: 'siteCleaningAmount',
          },
        ],
      },
      {
        title: 'Site Elements Demolition',
        tooltip: 'Include pit covers, anchor bolts.',
        fields: [
          {
            label: 'Amount',
            name: 'siteElementsDemolitionAmount',
          },
        ],
      },
      {
        title: 'Site Elements Relocation',
        tooltip: 'Include pit covers, anchor bolts.',
        fields: [
          {
            label: 'Amount',
            name: 'siteElementsRelocationAmount',
          },
        ],
      },
      {
        title: 'Site Remediation',
        tooltip: 'To collect liquids.',
        fields: [
          {
            label: 'Amount',
            name: 'siteRemediationAmount',
          },
        ],
      },
      {
        title: 'Site Earthwork',
        tooltip: 'To collect liquids.',
        fields: [
          {
            label: 'Amount',
            name: 'siteEarthworkAmount',
          },
        ],
      },
      {
        title: 'Other',
        tooltip: 'Any other relevant items related to this category',
        fields: [
          {
            label: 'Amount',
            name: 'otherAmount',
          },
        ],
      },
    ],
  },
  'Gravel Driveway, Fences, Gates, Planters, Lighting': {
    helperText: 'Please provide the cost and details of the following items',
    sections: [
      {
        title: 'Roadways',
        tooltip: 'To collect liquids.',
        fields: [
          {
            label: 'Amount',
            name: 'roadwaysAmount',
          },
        ],
      },
      {
        title: 'Site Development',
        tooltip: 'To collect liquids.',
        fields: [
          {
            label: 'Amount',
            name: 'siteDevelopmentAmount',
          },
        ],
      },
      {
        title: 'Landscaping',
        tooltip: 'To collect liquids.',
        fields: [
          {
            label: 'Amount',
            name: 'landscapingAmount',
          },
        ],
      },
      {
        title: 'Other',
        tooltip: 'Any other relevant items related to this category',
        fields: [
          {
            label: 'Amount',
            name: 'otherAmount',
          },
        ],
      },
    ],
  },
  'Wells, Septic': {
    helperText: 'Please provide the cost and details of the following items',
    sections: [
      {
        title: 'Water Utilities',
        tooltip: 'To collect liquids.',
        fields: [
          {
            label: 'Amount',
            name: 'waterUtilitiesAmount',
          },
        ],
      },
      {
        title: 'Sanitary Sewage Utilities',
        tooltip: 'To collect liquids.',
        fields: [
          {
            label: 'Amount',
            name: 'sanitarySewageUtilitiesAmount',
          },
        ],
      },
      {
        title: 'Storm Drainage Utilities',
        tooltip: 'To collect liquids.',
        fields: [
          {
            label: 'Amount',
            name: 'stormDrainageUtilitiesAmount',
          },
        ],
      },
      {
        title: 'Other',
        tooltip: 'Any other relevant items related to this category',
        fields: [
          {
            label: 'Amount',
            name: 'otherAmount',
          },
        ],
      },
    ],
  },
};

export default mapper;
