import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Layout } from '@roc/feature-app-core';
import { ScopeOfWorkV2FormDialog } from '@roc/feature-sow-v2';
import { useDrawsStore } from '../../hooks';


//TODO DRAWS Remove whole file
export const ScopeOfWorkRevision = observer(() => {
  const { scopeOfWorkRevisionStore } = useDrawsStore();

  const {
    scopeOfWorkV2FormDialogOpen,
    scopeOfWorkV2FormStore,
  } = scopeOfWorkRevisionStore;

  const closeScopeOfWorkV2FormDialog = () => {
    scopeOfWorkRevisionStore.setScopeOfWorkV2FormDialogOpen(false);
  };

  useEffect(() => {
    return () => {
      scopeOfWorkRevisionStore.reset();
    };
  }, [scopeOfWorkRevisionStore]);

  return (
    <Layout title="Scope of Work">
      <ScopeOfWorkV2FormDialog
        open={scopeOfWorkV2FormDialogOpen}
        onClose={closeScopeOfWorkV2FormDialog}
        store={scopeOfWorkV2FormStore}
      />
    </Layout>
  );
});

export default ScopeOfWorkRevision;
