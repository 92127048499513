import { makeObservable } from "mobx";
import { FormStore } from "@roc/feature-app-core";
import { GlobalStore } from "@roc/feature-app-core";

const form = {
  fields: {
    listName: {
      value: '',
      error: null,
      rule: 'required',
      message: 'This value is required',
    },
    objectType: {
      value: '',
      error: null,
      rule: 'required',
      message: 'This value is required',
    },
    recordType: {
      value: '',
      error: null,
      rule: 'required',
      message: 'This value is required',
    },
    brand: {
      value: '',
      error: null,
      rule: '',
      message: 'This value is required',
    }
  },
  meta: {
    isValid: false,
    error: null,
  },
};
export class CreateListFormStore extends FormStore {
  constructor(globalStore: GlobalStore) {
    super(form, globalStore);
    makeObservable(this, {
    });
  }
  onFieldChange(field, value) {
    if (field === 'objectType' && this.form.fields.objectType.value !== value) {
      this.form.fields.recordType.value = null;
    }
    super.onFieldChange(field, value);
  }
}
