import { Grid, Typography } from "@material-ui/core";
import { observer } from "mobx-react";
import { useEffect } from "react";
import TpoCreditInformation from "../tpoBorrowerDetailsVerification/tpoCreditInformation";
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Address, Identification, NetworthInformation, PhoneNumber } from "./tpoAlreadyHaveInformationHelper";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    alertWrapper: {
      width: '100%',
      marginBottom: '16px'
    },
    errorColor: {
      color: '#EB5463'
    },
  })
);

export const TpoAlreadyHaveInformationStep = observer((props) => {
  const { token, showCredit, creditReportFileId, currentBorrower, tpoAlreadyHaveInformationStore } = props;
  const classes = useStyles();

  useEffect(() => {
    const fetchInformation = async () => {
      if (token) {
        tpoAlreadyHaveInformationStore.resetStore();
        await tpoAlreadyHaveInformationStore.fetchFormInformation(token);
        await tpoAlreadyHaveInformationStore.loadDriversLicense();
      }
    };
    fetchInformation();
  }, [token]);

  useEffect(() => {
    tpoAlreadyHaveInformationStore.setFileNumber(creditReportFileId ?? '');
  }, [creditReportFileId]);

  const showCreditError = tpoAlreadyHaveInformationStore.basicInfoIsPresent && (currentBorrower?.creditExpired || currentBorrower?.reissueFailed || currentBorrower?.creditPending)
  return (
    <Grid container spacing={2}>
      <Identification store={tpoAlreadyHaveInformationStore} showDob={tpoAlreadyHaveInformationStore.showDob} showSsn={tpoAlreadyHaveInformationStore.showSsn} />
      <PhoneNumber store={tpoAlreadyHaveInformationStore} />
      <Grid item xs={12} />
      <Address store={tpoAlreadyHaveInformationStore} />
      <NetworthInformation store={tpoAlreadyHaveInformationStore} />
      {showCredit &&
        <>
          <Grid item xs={12}>
            <Typography variant="h5" className={showCreditError ? classes.errorColor : ''}>Credit Information</Typography>
          </Grid>
          <Grid item xs={12}>
            <TpoCreditInformation store={tpoAlreadyHaveInformationStore} disabled={false} showError={showCreditError} />
          </Grid>
        </>
      }
    </Grid>
  );
});

export default TpoAlreadyHaveInformationStep;