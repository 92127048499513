import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import React from 'react';

const useStyles = makeStyles(theme => ({
  disabled: {
    pointerEvents: 'none',
    backgroundColor: '#F1F2F3',
    '& .MuiPaper-root': {
      backgroundColor: 'transparent',
    },
  },
  paper: {
    border: `1px solid lightgray`,
    borderRadius: '16px !important',
    padding: theme.spacing(4),
  },
}));

export const OneToolCard = ({ children, disabled }) => {
  const classes = useStyles();

  return (
    <OneToolCardBorder className={clsx(disabled && classes.disabled)}>
      {children}
    </OneToolCardBorder>
  );
};

export const OneToolCardBorder = ({ children, className = undefined }) => {
  const classes = useStyles();

  return (
    <Paper className={clsx(classes.paper, className)} elevation={0}>
      {children}
    </Paper>
  );
};
