import { Service } from '@roc/feature-app-core';

const url = {
  EXPECTED_CLOSING_DATE:
    '/api/v1/document/formDocument/updateRequestedClosingDate',
};

export class RequestedClosingDateService extends Service {
  submitRequestedClosingDate(loanId: string, requestedClosingDate: string) {
    return this.put(
      url.EXPECTED_CLOSING_DATE +
      '?loanID=' +
      loanId +
      (requestedClosingDate
        ? `&requestedClosingDate=${requestedClosingDate}`
        : ``),
      {}
    );
  }
}
