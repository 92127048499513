import { flow, makeObservable } from 'mobx';
import { GlobalStore } from '@roc/feature-app-core';
import { DocumentStore } from '@roc/feature-documents';
import { LoanStore } from '@roc/feature-loans';
import { FormStore } from '@roc/feature-app-core';
import { RequestedClosingDateService } from '../../services/documentForms/requestedClosingDateService';
import { format, isValid, parse } from 'date-fns';
import { DocumentName } from '../../constants';
import { shouldBeAbleToSubmitSpecificForm } from '../../utils/documentForms';
import { formatDate } from '@roc/feature-utils';

export const requestedClosingDateForm = {
  fields: {
    date: {
      value: null,
      error: null,
      rule: 'required',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export class RequestedClosingDateStore extends FormStore {
  private documentStore: DocumentStore;
  globalStore: GlobalStore;
  loanStore: LoanStore;
  private requestedClosingDateService: RequestedClosingDateService;

  constructor(
    documentStore: DocumentStore,
    loanStore: LoanStore,
    globalStore: GlobalStore
  ) {
    super({ ...requestedClosingDateForm }, globalStore);
    this.loanStore = loanStore;
    this.documentStore = documentStore;
    this.globalStore = globalStore;
    this.requestedClosingDateService = new RequestedClosingDateService();

    makeObservable(this, {
      submitrequestedClosingDateState: flow,
      initialize: flow,
    });
  }

  *initialize() {
    this.reset();
    this.loadForm({
      date: this.loanStore.loanDetails.requestedClosingDate ?? null,
    });
  }

  get canEdit() {
    const entitlements = this.globalStore.userFeatures.closingEntitlements;
    return shouldBeAbleToSubmitSpecificForm(
      entitlements?.isSubmissionAllowed,
      entitlements?.isSubmissionNotAllowedOnly,
      entitlements?.isSubmissionAllowedOnly,
      DocumentName.REQUESTED_CLOSING_DATE
    );
  }
  shouldBeAbleToSubmitRequestedClosingDate() {
    return this.canEdit;
  }

  *submitrequestedClosingDateState() {
    try {
      const values = this.getFormValues();
      console.log(values);
      yield this.requestedClosingDateService.submitRequestedClosingDate(
        this.loanStore.loanDetails.loanId,
        values.date ? formatDate(values.date, 'yyyy-MM-dd') : null
      );
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Request made successful.',
      });
      this.documentStore.documentFormStore.closeCurrentForm();
      yield this.loanStore.fetchLoanDetails(this.loanStore.loanDetails.loanId);
      yield this.documentStore.fetchDocuments(this.loanStore.loanDetails.loanId);
    } catch (e) {
      console.log(e);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'An error occurred while submitting the form.',
      });
      this.documentStore.documentFormStore.closeCurrentForm();
    }
  }

  closeModal() {
    return this.documentStore.documentFormStore.setCurrentForm(null);
  }
}

export default RequestedClosingDateStore;
