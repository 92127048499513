import { Service } from '@roc/feature-app-core';

const url = {
  GET_ENTITY_BANK_INFO: '/api/v1/document/formDocument/getTitleCompanyBankInfo',
  GET_ENTITY_BANK_INFO_FOR_LENDER: '/api/v1/document/formDocument/getTitleCompanyBankInfoForLender',
  UPDATE_ENTITY_BANK_INFO:
    '/api/v1/document/formDocument/updateTitleCompanyBankInfo',
  UPDATE_ENTITY_BANK_INFO_LENDER_ENTITY:
    '/api/v1/document/formDocument/updateTitleCompanyBankInfoLenderEntity',
};

export class TitleCompanyBankInformationService extends Service {
  url;
  constructor() {
    super();
    this.url = url;
  }
  getTitleCompanyBankInfo(loanId: string) {
    return this.get(this.url.GET_ENTITY_BANK_INFO, { loanId });
  }
  getTitleCompanyBankInfoForLender(companyId: string) {
    return this.get(this.url.GET_ENTITY_BANK_INFO_FOR_LENDER, { companyId });
  }
  updateTitleCompanyBankInfo(loanId, payload) {
    return this.post(
      `${this.url.UPDATE_ENTITY_BANK_INFO}?loanId=${loanId}`,
      payload
    );
  }
  updateTitleCompanyBankInfoLenderEntity(loanId, payload) {
    return this.post(
      `${this.url.UPDATE_ENTITY_BANK_INFO_LENDER_ENTITY}?companyId=${loanId}`,
      payload
    );
  }
}
