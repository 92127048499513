import {
  Box,
  Collapse,
  IconButton,

} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { Alert } from '@material-ui/lab';
import { Layout } from '@roc/client-portal-shared/components';
import { useEffect, useState } from 'react';

import { Greeting } from '@roc/feature-app-core';
import { useTechSupportCommunicationStore } from 'libs/feature-communication/src/communication/hooks/useTechSupportCommunicationStore';
import { TechSupportConversation } from '@roc/feature-communication';
import { PortalBannerMessages } from 'libs/client-portal-shared/src/app/modules/dashboard/components/portalBannerMessages';
import { CurrentNotification } from 'libs/client-portal-shared/src/app/modules/dashboard/components/currentNotification';
import { useRoutes } from '@roc/client-portal-shared/hooks';
import { useCRMPortalStore } from '../../hooks';
import { SendEmailsByOwner } from '@roc/feature-sendgrid';
import { BackOfficeHomePage } from '../backofficeHomePage/backofficeHomePage';
import { useLoanRoutes } from '@roc/feature-loans-routes-config';

const useStyles = makeStyles(theme => ({
  alert: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.warning.main,
  },
}));

const UserAlert = () => {
  const [open, setOpen] = useState(true);
  const { palette } = useTheme();
  const classes = useStyles();
  return (
    <Collapse in={open}>
      <Box mb={3}>
        <Alert
          className={classes.alert}
          severity="warning"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          You have some pending tasks, be sure to check them out in the section
          below!
        </Alert>
      </Box>
    </Collapse>
  );
};

export const Dashboard = () => {
  const { loanRoutesConfig } = useLoanRoutes();
  const params = new URLSearchParams(location.search);
  const conversationSid = params.get('openConversation')

  if (conversationSid) {
    const { techSupportCommunicationStore } = useTechSupportCommunicationStore();
    techSupportCommunicationStore.setCurrentConversationId(conversationSid)
    techSupportCommunicationStore.setModalOpen(true)

  }

  return (
    <>
      <Layout>
        <PortalBannerMessages />
        <CurrentNotification />
        <Greeting
          text={
            'Be sure to check out any pending documents on your loans. ' +
            'If you upload them, it will help speed up the loan process.'
          }
          buttonLabel="GO TO LOANS"
          buttonLink={loanRoutesConfig.loansHome.url}
        />
      </Layout>
      {conversationSid &&
        <TechSupportConversation conversationSid={conversationSid} />
      }
    </>
  );
};
