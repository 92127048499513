import { Paper, Typography } from '@material-ui/core';
import { Layout } from '@roc/feature-app-core';
import { observer } from 'mobx-react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useEffect } from 'react';
import { NewLeadComponent } from './newLeadComponent';
import { Alert, AlertTitle } from '@material-ui/lab';
import { useNewLeadStore } from '../../hooks/useNewLeadStore';
import { NewLeadStore } from '../../stores/newLeadStore';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    padding: theme.spacing(4),
  },
  alerts: {
    fontSize: '1rem',
    paddingLeft: '50px'
  },
}));

export const NewLeadCreationHome = observer(() => {
  const styles = useStyles();
  const { newLeadStore } = useNewLeadStore();

  useEffect(() => {
    newLeadStore.fetchAllOwners();
    if (!newLeadStore.noLeadsFound) {
      newLeadStore.setDefaults();
    }
  }, []);

  const isLeadsTableVisible = (newLeadStore.gridStore.gridData.data.rows.length > 0) && (newLeadStore.activeStep === 0)

  return (
    <Layout title="New Lead" maxWidth={isLeadsTableVisible ? false : "md"}>
      {(newLeadStore.noLeadsFound && newLeadStore.activeStep == 1) &&
        <>
          <Alert severity="info" className={styles.alerts}>
            <AlertTitle><b>No leads found with these details</b></AlertTitle>
            Please ensure all required fields are filled out. <br />
          </Alert>
          <br />
        </>
      }
      <Paper className={styles.paper}>
        <NewLeadComponent />
      </Paper>
    </Layout>
  );
});
