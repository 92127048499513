import { Service } from '@roc/feature-app-core';

const url = {
  GET_MY_PROPOSALS_GRID: '/api/v1/loan/proposals/getMyProposalsGrid',
  GET_BACKOFFICE_PROPOSALS_GRID: '/api/v1/loan/proposals/getBackofficeProposalsGrid',
  GET_PROPOSAL_BY_ID: '/api/v1/loan/proposals/getProposalById',
  SAVE_PROPOSAL: '/api/v1/loan/proposals/saveProposal',
  DELETE_PROPOSAL: '/api/v1/loan/proposals/removeProposal',
  GET_CALCULATED_VALUES: '/api/v1/loan/proposals/getProposalCalculatedValues',
  SUBMIT_PROPOSAL: '/api/v1/loan/proposals/submitProposal',
  UPDATE_PROPOSAL_STATUS: '/api/v1/loan/proposals/updateProposalStatus',
  IS_ABLE_TO_APPEAL: '/api/v1/loan/proposals/isAbleToAppeal',
  SAVE_PROPOSAL_TOPIC: '/api/v1/loan/proposalTopic/saveProposalTopic',
  GET_PROPOSAL_TOPIC_BY_ID: '/api/v1/loan/proposalTopic/getProposalTopicById'
};

export class ProposalsService extends Service {
  getMyProposalsGrid(
    page: number,
    pageSize: number,
    sortDir: string,
    sortBy: string,
    filters: string
  ) {
    return this.get(
      url.GET_MY_PROPOSALS_GRID,
      {
        page,
        pageSize,
        sortDir,
        sortBy,
        filters,
      },
      {
        disableGlobalLoading: true,
      }
    );
  }

  getBackOfficeProposalsGrid(
    page: number,
    pageSize: number,
    sortDir: string,
    sortBy: string,
    filters: string
  ) {
    return this.get(
      url.GET_BACKOFFICE_PROPOSALS_GRID,
      {
        page,
        pageSize,
        sortDir,
        sortBy,
        filters,
      },
      {
        disableGlobalLoading: true,
      }
    );
  }

  getCalculatedValues(data) {
    return this.post(url.GET_CALCULATED_VALUES, data);
  }

  getProposalById(proposalId) {
    return this.get(`${url.GET_PROPOSAL_BY_ID}?proposalId=${proposalId}`);
  }

  getProposalTopicById(proposalTopicId){
      return this.get(`${url.GET_PROPOSAL_TOPIC_BY_ID}?proposalTopicId=${proposalTopicId}`);
  }

  saveProposal(data) {
    return this.post(url.SAVE_PROPOSAL, data);
  }

  saveProposalTopic(data) {
    return this.post(url.SAVE_PROPOSAL_TOPIC, data);
  }

  submitProposal(data) {
    return this.post(url.SUBMIT_PROPOSAL, data)
  }

  removeProposal(proposalId) {
    return this.delete(url.DELETE_PROPOSAL, { proposalId });
  }

  updateProposalStatus = updateProposalStatusRequest => {
    return this.put(url.UPDATE_PROPOSAL_STATUS, updateProposalStatusRequest);
  };

  getIsAbleToAppeal() {
    return this.get(url.IS_ABLE_TO_APPEAL);
  }

}
