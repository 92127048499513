import { Box, Typography } from '@material-ui/core';
import { ClosingDate } from './closingDate';

export const ExpectedClosingsCardTitle = ({ title }) => {
  try {
    const [titleWithoutDate, date] = title.split(/for (?!the)|for the/i);

    return (
      <Box>
        <Typography variant="h5" style={{ fontSize: '24px' }}>
          {titleWithoutDate}
        </Typography>
        {date && (
          <Typography variant="h6" style={{ fontSize: '16px' }}>
            <ClosingDate value={date} />
          </Typography>
        )}
      </Box>
    );
  } catch (e) {
    return <Typography variant="h5"> title </Typography>;
  }
};
