import { Business } from '@material-ui/icons';
import { Button } from '@roc/ui';
import { observer } from 'mobx-react';
import { useNewLoanApplicationStore } from '../hooks/useNewLoanApplicationStore';
import {
  LoanApplicationCard,
  LoanApplicationCardActions,
  LoanApplicationCardDividers,
  LoanApplicationCardField
} from './loanApplicationComponents';

export const EntityCard = observer(({ editable = true }) => {
  const { newLoanApplicationStore } = useNewLoanApplicationStore();
  const { borrowerEntityFormStore, borrowerStore } = newLoanApplicationStore;
  const { selectedBorrowerEntity } = borrowerEntityFormStore;

  return (
    <LoanApplicationCard icon={<Business />} title={'Entity'}>
      <LoanApplicationCardDividers px={2} py={2}>
        <LoanApplicationCardField
          label="Entity Name"
          value={selectedBorrowerEntity?.name || '-'}
        />
        <LoanApplicationCardField
          label="Entity EIN"
          value={selectedBorrowerEntity?.ein || '-'}
        />
        <LoanApplicationCardField
          label="Entity Type"
          value={selectedBorrowerEntity?.type || '-'}
        />
        {editable && (
          <LoanApplicationCardActions>
            <Button
              color="primary"
              testId={'editEntity'}
              variant="outlined"
              onClick={() => borrowerEntityFormStore.openEditEntity()}
            >
              EDIT ENTITY
            </Button>
          </LoanApplicationCardActions>
        )}
      </LoanApplicationCardDividers>
    </LoanApplicationCard>
  );
});
