import { action, observable, flow, makeObservable } from 'mobx';
import { GlobalStore } from "@roc/feature-app-core";
import { PayablesDashboardStore } from "./payablesDashboardStore";
import { CounterPartyExpensesTab } from "../types/types";

export class PayablesApprovedStore extends PayablesDashboardStore {
  constructor(globalStore: GlobalStore) {
    super(globalStore);
    this.tab = CounterPartyExpensesTab.PAYABLES_APPROVED;
    makeObservable(this, {
      resetApproved: action,
      markAsPaid: flow,
      markMultipleAsPaid: flow,
    });
  }

  resetApproved() {
    this.tab = CounterPartyExpensesTab.PAYABLES_APPROVED;
    this.counterPartyExpenseGridStore.reset();
    this.recordsIds = [];
  };


  *markAsPaid(id: number, reference: string) {
    const idList: number[] = [id];
    try {
      const response = yield this.service.markPayableAsPaid(idList, reference);
      if (response.data.status === 'OK') {
        this.globalStore.notificationStore.showSuccessNotification({
          message: 'Payable marked as paid successfully.'
        });
      }
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while marking payable as paid.',
      });
    }
  }

  *markMultipleAsPaid(reference: string) {
    try {
      const response = yield this.service.markPayableAsPaid(this.recordsIds, reference);
      if (response.data.status === 'OK') {
        this.globalStore.notificationStore.showSuccessNotification({
          message: 'Payables marked as paid successfully.'
        });
      }
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while marking payables as paid.',
      });
    }
  }
}