import { capitalize } from "@material-ui/core";
import { FormStore, GlobalStore } from "@roc/feature-app-core";
import { action, flow, makeObservable, observable, override } from "mobx";
import { CompToolService } from "../services/compToolService";
import { isNumber } from "@roc/feature-utils";

const form = {
  fields: {
    TYPE: {
      values: {
        value: '',
        error: null,
        rule: '',
      }
    },
    ROOMS: {
      min: {
        value: null,
        error: null,
        rule: '',
      },
      max: {
        value: null,
        error: null,
        rule: '',
      }
    },
    BEDROOMS: {
      min: {
        value: null,
        error: null,
        rule: '',
      },
      max: {
        value: null,
        error: null,
        rule: '',
      }
    },
    FULL_BATHS: {
      min: {
        value: null,
        error: null,
        rule: '',
      },
      max: {
        value: null,
        error: null,
        rule: '',
      }
    },
    HALF_BATHS: {
      min: {
        value: null,
        error: null,
        rule: '',
      },
      max: {
        value: null,
        error: null,
        rule: '',
      }
    },
    PRICE: {
      min: {
        value: '',
        error: null,
        rule: '',
      },
      max: {
        value: '',
        error: null,
        rule: '',
      }
    },
    LEASE_PRICE: {
      min: {
        value: '',
        error: null,
        rule: '',
      },
      max: {
        value: '',
        error: null,
        rule: '',
      }
    },
    AREA: {
      min: {
        value: '',
        error: null,
        rule: '',
      },
      max: {
        value: '',
        error: null,
        rule: '',
      },
    },
    YEARBUILT: {
      min: {
        value: '',
        error: null,
        rule: '',
      },
      max: {
        value: '',
        error: null,
        rule: '',
      }
    },
    LOTSIZE: {
      min: {
        value: '',
        error: null,
        rule: '',
      },
      max: {
        value: '',
        error: null,
        rule: '',
      }
    },
    GEO__DIST: {
      min: {
        value: 0,
        error: null,
        rule: '',
      },
      max: {
        value: 1,
        error: null,
        rule: '',
      }
    },
    SQFTPRICE: {
      min: {
        value: '',
        error: null,
        rule: '',
      },
      max: {
        value: '',
        error: null,
        rule: '',
      }
    },
    ASSESSOR_TAX: {
      min: {
        value: '',
        error: null,
        rule: '',
      },
      max: {
        value: '',
        error: null,
        rule: '',
      }
    },
    IS_ACTIVE: {
      values: {
        value: [],
        error: null,
        rule: '',
      }
    },
    WAS_FLIPPED: {
      values: {
        value: [],
        error: null,
        rule: '',
      }
    },
    HAS_PHOTOS: {
      values: {
        value: ['True', 'False'],
        error: null,
        rule: '',
      }
    },
    MONTHS: {
      values: {
        value: '',
        error: null,
        rule: '',
      }
    },
    CONDITION_TAG: {
      values: {
        value: ['Very bad', 'Bad', 'Moderate', 'Good', 'Very good'],
        error: null,
        rule: '',
      }
    },
    CONDITION_CONFIDENCE_TAG: {
      values: {
        value: ['Can try', 'Impossible to understand', 'Probable', 'Definitely'],
        error: null,
        rule: '',
      }
    },
    SCHOOL_DISTRICT: {
      values: {
        value: null,
        error: null,
        rule: '',
      }
    }
  },
  meta: {
    isValid: false,
    error: null,
  },
};

const unloadedValues = [
  'ROOMS', 'BEDROOMS', 'FULL_BATHS', 'HALF_BATHS',
  'TYPE', 'HAS_PHOTOS', 'MONTHS', 'CONDITION_TAG',
  'CONDITION_CONFIDENCE_TAG', 'SCHOOL_DISTRICT', 'GEO__DIST'
];

const arrayValues = [
  'IS_ACTIVE', 'WAS_FLIPPED', 'CONDITION_TAG',
  'HAS_PHOTOS', 'CONDITION_CONFIDENCE_TAG', 'SCHOOL_DISTRICT'
];

const nonArrayValues = ['TYPE'];

export class CompToolFiltersStore extends FormStore {
  globalStore: GlobalStore;
  compToolService: CompToolService;
  propertyTypeOptions: any[];
  schoolDistricts: any[];

  constructor(globalStore) {
    super(form, globalStore);
    this.globalStore = globalStore;
    this.compToolService = new CompToolService();
    this.schoolDistricts = [
      {
        label: 'Yes',
        value: [''],
      },
      {
        label: 'No',
        value: '',
      }
    ];


    makeObservable(this, {
      loadValues: flow,
      onFieldsChange: action,
      propertyTypeOptions: observable,
      clearFilters: action,
      getMultiFormValues: action,
      getFiltersRequest: action,
      getFormValue: action,
      getMinMaxValue: action,
      schoolDistricts: observable,
    });
  }

  getMultiFormValues: any = () => {
    const data = {};
    for (const key in this.form.fields) {
      const field = this.form.fields[key];
      if (arrayValues.includes(key)) {
        const value = field.values.value;
        if (isNumber(value) || (Array.isArray(value) && value.length > 0) || (typeof value === 'string' && value)) {
          data[key] = {
            include_missing: true,
            values: value
          };
        }
      }

      if (nonArrayValues.includes(key) && field.values.value) {
        data[key] = {
          include_missing: true,
          values: [field.values.value]
        };
      }

      if (field.min && field.min.value) {
        data[key] = {
          ...data[key],
          min: field.min.value
        };
      }

      if (field.max && field.max.value) {
        data[key] = {
          ...data[key],
          max: field.max.value
        };
      }
    }
    return data;
  };

  onFieldsChange(key, field, value) {
    if (typeof value === 'string' && !isNaN(value as any) && value.trim() !== '') {
      value = value.includes('.') ? parseFloat(value) : parseInt(value, 10);
    }

    this.form.fields[key][field].value = value;
  }

  *loadValues(data) {
    if (data.TYPE) {
      this.propertyTypeOptions = data.TYPE.values.map(type => {
        return {
          label: capitalize(type),
          value: type
        }
      })
    }

    if (data.SCHOOL_DISTRICT && data.SCHOOL_DISTRICT.values.length > 0) {
      this.schoolDistricts = this.schoolDistricts.map(item => {
        if (item.label === 'Yes') {
          return {
            ...item,
            value: data.SCHOOL_DISTRICT.values
          };
        }
        return item;
      });
    }

    Object.keys(data).map(key => {
      if (!unloadedValues.includes(key) && data[key] && this.form.fields[key]) {
        const field = this.form.fields[key];
        if (field.min) {
          field.min.value = data[key].min
        }
        if (field.max) {
          field.max.value = data[key].max
        }
        if (field.values) {
          field.values.value = data[key].values
        }
      }
    });
  }

  getFiltersRequest() {
    const {
      TYPE,
      ROOMS,
      BEDROOMS,
      FULL_BATHS,
      HALF_BATHS,
      PRICE,
      LEASE_PRICE,
      AREA,
      YEARBUILT,
      LOTSIZE,
      GEO__DIST,
      SQFTPRICE,
      ASSESSOR_TAX,
      IS_ACTIVE,
      WAS_FLIPPED,
      HAS_PHOTOS,
      CONDITION_TAG,
      CONDITION_CONFIDENCE_TAG,
      SCHOOL_DISTRICT
    } = this.getMultiFormValues();

    const filtersFields =
    {
      fields: {
        TYPE,
        ROOMS,
        BEDROOMS,
        FULL_BATHS,
        HALF_BATHS,
        PRICE,
        LEASE_PRICE,
        AREA,
        YEARBUILT,
        LOTSIZE,
        SQFTPRICE,
        ASSESSOR_TAX,
        IS_ACTIVE,
        WAS_FLIPPED,
        HAS_PHOTOS,
        CONDITION_TAG,
        CONDITION_CONFIDENCE_TAG,
        SCHOOL_DISTRICT,
        ...(GEO__DIST.max <= 1 && { GEO__DIST })
      }
    }
    return filtersFields;
  }

  getFormValue(key) {
    const field = this.form.fields[key];
    return field.values.value;
  }

  getMinMaxValue(key, subKey) {
    const field = this.form.fields[key];
    return field[subKey];
  }

  clearFilters() {
    Object.keys(this.form.fields).map(key => {

      const field = this.form.fields[key];
      if (field.min) {
        field.min.value = '';
      }
      if (field.max) {
        field.max.value = '';
      }
      if (key === 'TYPE' && field.values?.value) {
        field.values.value = '';
      }
    });
  }


}