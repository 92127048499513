import { observer } from 'mobx-react';
import { useForeclosureSummaryStore } from '../../hooks/useForeclosureSummaryStore';
import { DetailsCard } from './detailsCard';

export const BorrowerProfile = observer(() => {
  const { foreclosureSummaryStore } = useForeclosureSummaryStore();
  const { foreclosureSummary } = foreclosureSummaryStore;
  const { borrowerBO } = foreclosureSummary;

  const repeatedBorrower = () => {
    return (borrowerBO?.portalProjectsActive + borrowerBO?.portalProjectsCompleted) > 1;
  }
  return (
    <>
      <DetailsCard
        title={'Borrower Profile'}
        data={[
          {
            name: 'FICO',
            value: borrowerBO?.medianScore ? borrowerBO?.medianScore : 'N/A',
          },
          {
            name: 'Repeat borrower',
            value: repeatedBorrower() ? 'Yes' : 'No',
          },
          {
            name: 'Experience score',
            value: borrowerBO?.experience ? borrowerBO?.experience : 'N/A',
          },
        ]}
        columnsNum={1}
      />
    </>
  );
});