import { Service } from '@roc/feature-app-core';

const url = {
  GET_DOCUMENTS: '/api/v1/loan/documentLibrary/getDocuments',
  DOWNLOAD_DOCUMENT: '/api/v1/loan/documentLibrary/downloadDocument',
  DOWNLOAD_PUBLIC_DOCUMENT: '/api/v1/loan/public/documentLibrary/downloadPublicDocument',
};

export class DocumentLibraryService extends Service {
  getDocuments() {
    return this.get(url.GET_DOCUMENTS);
  }
  downloadDocument(document: string) {
    return this.get(
      url.DOWNLOAD_DOCUMENT,
      {
        document,
      },
      {
        responseType: 'arraybuffer',
      }
    );
  }

  downloadPublicDocument(document: string) {
    return this.get(
      url.DOWNLOAD_PUBLIC_DOCUMENT,
      {
        document,
      },
      {
        responseType: 'arraybuffer',
      }
    );
  }
}
