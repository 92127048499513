import { Grid, Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { lighten, makeStyles, Theme } from '@material-ui/core/styles';
import { isNotBlank } from '@roc/feature-utils';
import { Button } from '@roc/ui';
import { observer } from 'mobx-react';

const useStyles = makeStyles((theme: Theme) => {
  return {
    paper: {
      margin: theme.spacing(4),
      overflow: 'hidden',
      minHeight: '75%',
    },
    leftColumn: {
      fontFamily: theme.typography.body2.fontFamily,
      backgroundColor: lighten(theme.palette.primary.main, 0.1),
      padding: theme.spacing(8, 6),
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    rightColumn: {
      padding: theme.spacing(8, 6),
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
    },
    title: {
      ...theme.typography.h1,
      paddingBottom: theme.spacing(2),
      fontSize: '4rem',
      color: theme.palette.getContrastText(theme.palette.primary.main),
    },
    text: {
      paddingBottom: theme.spacing(8),
      [theme.breakpoints.down('md')]: {
        paddingBottom: theme.spacing(4, 0)
      }
    },
    button: {
      width: '160px',
    },
    wrapper: {
      height: '100%',
      display: 'flex',
    },
    textWidth: {
      width: '90%'
    }
  };
});

export const WelcomeWithIdVerification = observer(({ disableStart, onStart, borrowerName = '' }) => {
  const classes = useStyles();
  return (
    <Paper className={classes.paper}>
      <Grid container className={classes.wrapper}>
        <Grid item xs={12} md={12} lg={4} className={classes.leftColumn}>
          <Typography className={classes.title}>Welcome!</Typography>
        </Grid>
        <Grid item md={12} lg={8} className={classes.rightColumn}>
          <Grid item container direction='row' justifyContent='center' alignItems='center'>
            <Grid item container direction='row' spacing={2} className={classes.textWidth}>
              {isNotBlank(borrowerName) &&
                <Grid item>
                  <Typography variant='h6'>
                    Hi {borrowerName},
                  </Typography>
                </Grid>
              }
              <Grid item>
                <Typography className={classes.text}>
                  Please provide us with some basic information to facilitate your loan request.
                </Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Button
                testId="start"
                variant="contained"
                color="primary"
                className={classes.button}
                disabled={disableStart}
                onClick={onStart}
              >
                START
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
});
