import { action, computed, flow, makeObservable, observable } from 'mobx';
import { GlobalStore } from '@roc/feature-app-core';
import { DocumentStore } from '@roc/feature-documents';
import { LoanStore } from '@roc/feature-loans';
import { DocumentStatus } from '../../constants';
import { TermSheetService } from '../../services/documentForms/termSheetService';
import { Document } from '@roc/feature-types';
import { LoanType, LoanSubType, GENERIC_ERROR_MESSAGE } from '@roc/feature-utils';

export class TermSheetStore {
  private loanStore: LoanStore;
  private documentStore: DocumentStore;
  private globalStore: GlobalStore;
  private termSheetService: TermSheetService;
  loanId: string;
  portalId: number;

  termSheetData;

  constructor(
    loanStore: LoanStore,
    documentStore: DocumentStore,
    globalStore: GlobalStore
  ) {
    this.loanStore = loanStore;
    this.documentStore = documentStore;
    this.globalStore = globalStore;
    this.termSheetService = new TermSheetService();
    makeObservable(this, {
      termSheetData: observable,
      portalId: observable,
      updateTermSheetModalState: flow,
      downloadTermSheet: flow,
      submitTermSheet: flow,
      requestBorrowerTermSheet: flow,
      reset: action,
      isTermSheetAvailable: computed,
      isSubmissionAllowed: computed,
      tapeMatchesWithLoanInformation: computed,
      isSingleProperty: computed,
      isTermLoan: computed,
      isPortfolioLoan: computed,
      isPortfolioTapeMatchesWithLoanInformation: computed,
      isValidTermSheetLoan: computed,
      showAcceptTermSheet: computed,
      canAcceptTermSheet: computed,
      validationErrorsOnBehalfOfBorrowerFlow: computed,
      hasValidationErrorsOnBehalfOfBorrowerFlow: computed,
      validationErrorsForNonGFDFlow: computed,
      hasValidationErrorsForNonGFDFlow: computed,
      fetchTermSheetData: flow,
      fetchTermSheetDataForBridgeSubmission: flow,
      fetchRentalTermSheetData: flow,
      fetchRentalTermSheetDataForTermSubmission: flow,
      fetchPortalId: flow,
    });
  }

  *updateTermSheetModalState(
    borrowerPayingGfd: boolean,
    termSheetOnBehalfOfTheBorrower: boolean
  ) {
    try {
      const loan = this.loanStore.loanDetails;
      const { loanId, loanDepositData } = loan;
      loanDepositData.borrowerPayingGfd = borrowerPayingGfd;
      loanDepositData.termSheetOnBehalfOfTheBorrower = termSheetOnBehalfOfTheBorrower;
      yield this.termSheetService.updateTermSheetModalState(
        loanId,
        borrowerPayingGfd,
        termSheetOnBehalfOfTheBorrower
      );
    } catch (err) {
      console.log('err', err);
    }
  }

  *downloadTermSheet(document: Document) {
    try {
      yield this.documentStore.downloadDocument(document);
    } catch (err) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while downloading document.',
      });
    }
  }

  *downloadRateLockAgreement(document: Document) {
    try {
      yield this.documentStore.downloadDocument(document);
    } catch (err) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while downloading document.',
      });
    }
  }

  *submitTermSheet(document: Document) {
    try {
      const updatingDocument = { ...document };
      updatingDocument.status = DocumentStatus.ACCEPTED;
      yield this.termSheetService.updateLoanDocumentTermSheet(updatingDocument);
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Term Sheet Accepted',
      });
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error submitting the Term Sheet',
      });
    }
  }

  *requestBorrowerTermSheet() {
    try {
      const loanId = this.loanStore.loanDetails.loanId;
      yield this.termSheetService.requestBorrowerTermSheet(loanId);
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Email has been sent',
      });
    } catch (err) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error on sending term sheet email',
      });
    }
  }

  *fetchTermSheetData(loanId) {
    try {
      const response = yield this.termSheetService.getTermSheetFields(loanId);
      this.termSheetData = response.data.data;
    } catch (err) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching term sheet',
      });
    }
  }

  *fetchTermSheetDataForBridgeSubmission(loanJson) {
    try {
      const response = yield this.termSheetService.getTermSheetFieldsBridgeSubmission(
        loanJson
      );
      this.termSheetData = response.data.data;
    } catch (err) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching term sheet',
      });
    }
  }

  *fetchRentalTermSheetData(loanId) {
    try {
      const response = yield this.termSheetService.getTermSheetFields(loanId);
      this.termSheetData = response.data.data;
    } catch (err) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching term sheet',
      });
    }
  }

  *fetchRentalTermSheetDataForTermSubmission(loanJson) {
    try {
      const response = yield this.termSheetService.getTermSheetFieldsBridgeSubmission(
        loanJson
      );
      this.termSheetData = response.data.data;
    } catch (err) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching term sheet',
      });
    }
  }

  *fetchPortalId(lenderId) {
    try {
      const response = yield this.termSheetService.getLenderById(lenderId);
      this.portalId = response.data.portalId;
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE,
      });
    }
  }

  reset() {
    // No reset logic required yet.
  }

  get isTermSheetAvailable() {
    const { termSheetValidationErrors } = this.loanStore.loanDetails;
    return (
      Array.isArray(termSheetValidationErrors) &&
      termSheetValidationErrors.length === 0
    );
  }

  get isSubmissionAllowed() {
    return this.globalStore.userFeatures?.underwritingEntitlements
      ?.isSubmissionAllowed;
  }

  get tapeMatchesWithLoanInformation() {
    const loan = this.loanStore.loanDetails;
    const { properties, sfrLoanFields } = loan;
    return (
      properties &&
      properties.totalCount > 0 &&
      (sfrLoanFields.loanInformationMatchesClientTape ||
        (!sfrLoanFields.loanInformationMatchesClientTape &&
          sfrLoanFields.exceptionStatus === 'Exception corrected'))
    );
  }

  get isSingleProperty() {
    const { loanSubType } = this.loanStore.loanDetails;
    return (
      loanSubType === LoanSubType.SINGLE_PROPERTY ||
      loanSubType === LoanSubType.SINGLE_PROPERTY_MULTIFAMILY ||
      loanSubType === LoanSubType.SINGLE_PROPERTY_MIXED_USE
    );
  }

  get isTermLoan() {
    const { loanType } = this.loanStore.loanDetails;
    return loanType === LoanType.RESIDENTIAL_TERM;
  }

  get isPortfolioLoan() {
    return !this.isSingleProperty;
  }

  get isPortfolioTapeMatchesWithLoanInformation() {
    return this.isPortfolioLoan && !!this.tapeMatchesWithLoanInformation;
  }

  get isValidTermSheetLoan() {
    return (
      !this.isTermLoan ||
      this.isSingleProperty ||
      this.isPortfolioTapeMatchesWithLoanInformation
    );
  }

  get showAcceptTermSheet() {
    const {
      termSheetBorrowerValidationErrorsForOnBehalfOfBorrowerFlow,
    } = this.loanStore.loanDetails;
    return (
      !termSheetBorrowerValidationErrorsForOnBehalfOfBorrowerFlow ||
      termSheetBorrowerValidationErrorsForOnBehalfOfBorrowerFlow.length === 0
    );
  }

  get canAcceptTermSheet() {
    return (
      this.isSubmissionAllowed &&
      this.isTermSheetAvailable &&
      this.isValidTermSheetLoan
    );
  }

  get validationErrorsOnBehalfOfBorrowerFlow() {
    const {
      termSheetBorrowerValidationErrorsForOnBehalfOfBorrowerFlow,
    } = this.loanStore.loanDetails;
    return termSheetBorrowerValidationErrorsForOnBehalfOfBorrowerFlow;
  }

  get hasValidationErrorsOnBehalfOfBorrowerFlow() {
    return this.validationErrorsOnBehalfOfBorrowerFlow?.length > 0;
  }

  get validationErrorsForNonGFDFlow() {
    const {
      termSheetBorrowerValidationErrorsForNonGFDFlow,
    } = this.loanStore.loanDetails;
    return termSheetBorrowerValidationErrorsForNonGFDFlow;
  }

  get hasValidationErrorsForNonGFDFlow() {
    return this.validationErrorsForNonGFDFlow?.length > 0;
  }
}

export default TermSheetStore;
