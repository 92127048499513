import { action, flow, makeObservable, observable } from 'mobx';
import { DrawService } from '../services/drawService';
import { GlobalStore, GridStore, ApiResponse } from '@roc/feature-app-core';
import { Draw, DrawDTO } from '../types/draws';
import { GENERIC_ERROR_MESSAGE } from '@roc/feature-utils';

export class DrawStore {
  globalStore: GlobalStore;
  private drawService: DrawService;
  drawList: Draw[] = [];
  currentDraw: Draw = null;
  drawDTO: DrawDTO = null;
  scopeOfWorkList: any[] = [];
  revisionList: any[] = [];
  myDrawsGridStore: GridStore;
  propertyPhotosUrl: string;

  constructor(globalStore) {
    this.globalStore = globalStore;
    this.drawService = new DrawService();
    this.myDrawsGridStore = new GridStore(
      () => this.fetchAllDraws(),
      null,
      50,
    );
    makeObservable(this, {
      fetchDraws: flow,
      fetchDrawById: flow,
      resetDraws: action,
      drawList: observable,
      currentDraw: observable,
      scopeOfWorkList: observable,
      revisionList: observable,
      fetchSOWDocuments: flow,
      cancelRevision: flow,
      cancelDrawRequest: flow,
      propertyPhotosUrl: observable,
    });
  }

  *cancelDrawRequest(drawId: number, loanId: string) {
    try {
      const response: ApiResponse = yield this.drawService.cancelDrawRequest(drawId);
      const data = JSON.parse(response.data?.data);
      if (data.success) {
        this.fetchDraws(loanId);
        this.globalStore.notificationStore.showSuccessNotification({
          message: 'Cancel request was made successfully.',
        });
      }
    } catch (err) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while canceling draw request.',
      });
    }
  }

  *cancelRevision(data: any) {
    try {
      const response: ApiResponse = yield this.drawService.cancelDrawRequest(data.drawId);
      const resposeData = JSON.parse(response.data?.data);
      if (resposeData.success) {
        this.fetchSOWDocuments(data.loanId);
        this.globalStore.notificationStore.showSuccessNotification({
          message: 'The SOW revision was successfully cancelled.',
        });
      }
    } catch (err) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while canceling SOW revision.',
      });
    }
  }

  *fetchSOWDocuments(loanId: string, callback?: (success: boolean) => void) {
    try {
      const response: ApiResponse = yield this.drawService.getSOWDocuments(
        loanId
      );
      const { data } = response;
      const swoData = JSON.parse(data?.data);
      this.scopeOfWorkList = swoData.sowList ? swoData.sowList : [];
      this.revisionList = swoData.revisionList ? swoData.revisionList : [];
      callback && callback(true);
    } catch (err) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while getting SOW related documents.',
      });
      callback && callback(false);
    }
  }

  *fetchDraws(loanId: string) {
    try {
      const response: ApiResponse = yield this.drawService.getDrawsByLoanId(loanId);
      const { data } = response;
      this.drawList = data.data ? data.data : [];
    } catch (err) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while getting draw list.',
      });
    }
  }

  private async fetchAllDraws() {
    const gridFilters = this.myDrawsGridStore.gridData.meta.filters ?? {};
    const filters = {
      ...gridFilters,
      ...this.myDrawsGridStore.gridData.meta.dropdownFilters
    };
    try {
      const response: ApiResponse = await this.drawService.getAllDraws(
        this.myDrawsGridStore.gridData.meta.pageNumber,
        this.myDrawsGridStore.gridData.meta.pageSize,
        this.myDrawsGridStore.gridData.meta.sortDir,
        this.myDrawsGridStore.gridData.meta.sortBy,
        filters,
      );
      return response;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE,
      });
    }
  }

  *fetchDrawById(drawId: string) {
    try {
      const response: ApiResponse = yield this.drawService.getDrawById(drawId);
      const { data } = response;
      this.drawDTO = data.data ? data.data : null;
      this.currentDraw = this.drawDTO?.draw;
      this.propertyPhotosUrl = this.drawDTO?.truepicLink;
    } catch (err) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while getting draw information.',
      });
    }
  }

  resetDraws() {
    this.drawList = [];
  }

  resetCurrentDraw() {
    this.currentDraw = null;
  }

  resetRevisionDocs() {
    this.scopeOfWorkList = [];
    this.revisionList = [];
  }
}
