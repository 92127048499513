import { VerifyTwoFactorLoginPage, useLoginStore } from "@roc/feature-app-core"
import { observer } from "mobx-react"
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useAppRoutes, useStore } from "../../hooks";

export const Login2FA = observer(() => {

  const { loginStore } = useLoginStore();
  const { authenticationStore } = useStore();
  const { publicRoutes } = useAppRoutes();
  const { redirectUrlAfter2FASuccessful } = loginStore;
  const { push } = useHistory();
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (authenticationStore.isAuthenticated == undefined) {
      authenticationStore.checkAuthentiation();
    }
    else if (authenticationStore.isAuthenticated == false) {
      console.log("User is not authenticated, so redirecting to login");
      push(publicRoutes.login.url);
    }
    else if (authenticationStore.isAuthenticated == true && authenticationStore.twoFactorRequired == false) {
      window.location.href = '/';
    }
  }, [authenticationStore.isAuthenticated]);

  useEffect(() => {
    if (authenticationStore.twoFactorRequired) {
      setShow(true);
    }
  }, [authenticationStore.twoFactorRequired])

  const onSuccess = () => {
    window.location.href = redirectUrlAfter2FASuccessful ?? "/";
  };

  return (
    show && <VerifyTwoFactorLoginPage onSuccess={onSuccess} />
  )
})