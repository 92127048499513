import { PaginationBar } from "@roc/ui";
import { observer } from "mobx-react";
import { useStore } from "@roc/client-portal-shared/hooks";




export const CompPagination = observer(
  ({ paginationStyles }: { paginationStyles: any }) => {
    const { compToolStore } = useStore();
    const { compResponse } = compToolStore;

    return (
      <>
        <PaginationBar
          start={1}
          pageSize={compResponse.per_page}
          totalRecords={(compResponse.per_page - 1) * compResponse.pages}
          pageNumber={compResponse.page}
          totalPages={compResponse.pages}
          onChange={(event, value) => { compToolStore.fetchPropertiesWithPagination(value) }}
          className={paginationStyles}
        />
      </>
    );
  }
);