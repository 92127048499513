import { Service } from "@roc/feature-app-core";
import { CMTReportDetails } from "@roc/feature-construction-feasibility";

const url = {
  GET_CMT_REPORT: '/nportal/rest/priv/cmt/getCMTReport',
  SAVE_CMT_REPORT: '/nportal/rest/priv/cmt/saveCMTReport',
  REQUEST_REVISION: '/nportal/rest/priv/cmt/requestRevision',
  REVISION_COMPLETE: '/nportal/rest/priv/cmt/revisionComplete',
  REVISION_COMPLETE_WITH_COMMENTS: '/nportal/rest/priv/cmt/revisionCompleteWithComments',
  UPDATE_SOW_UW_STATUS: '/nportal/rest/priv/cmt/updateSowUWStatus',

};

export class CmtInternalService extends Service {
  url;

  constructor() {
    super();
    this.url = url;
  }

  getCMTReport(loanId, propertyId) {
    return this.get(`${this.url.GET_CMT_REPORT}?propertyId=${propertyId}&loanId=${loanId}`);
  }

  saveCMTReport(loanId: any, propertyId: any, body: CMTReportDetails) {
    return this.put(`${this.url.SAVE_CMT_REPORT}?loanId=${loanId}&propertyId=${propertyId}`, body);
  }

  requestRevision(propertyId, loanId) {
    return this.get(`${this.url.REQUEST_REVISION}?propertyId=${propertyId}&loanId=${loanId}`);
  }

  revisionComplete(propertyId, loanId) {
    return this.get(`${this.url.REVISION_COMPLETE}?propertyId=${propertyId}&loanId=${loanId}`);
  }

  revisionCompleteWithComments(propertyId, loanId) {
    return this.get(`${this.url.REVISION_COMPLETE_WITH_COMMENTS}?propertyId=${propertyId}&loanId=${loanId}`);
  }

  updateSowUWStatus(propertyId, loanId, status, message) {
    return this.post(`${this.url.UPDATE_SOW_UW_STATUS}?propertyId=${propertyId}&loanId=${loanId}&status=${status}`, { message });
  }
}