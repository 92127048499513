import { Service } from '@roc/client-portal-shared/services';

const url = {
  RATES_DICTIONARY: '/nportal/rest/priv/internalPricer/internalRatesDictionary',
  LOAN_VALUES: '/nportal/rest/priv/internalPricer/getPricerRentalLoanCalcDetail',
  RENTAL_DEAL_NAMES: '/nportal/rest/priv/dscr/getRentalDealNames',
  DEFAULT_VALUES: '/nportal/rest/priv/dscr/getDefaultValues',
  STATE_COUNTIES: '/nportal/rest/priv/stateCounties',
  MONTHLY_PAYMENT: '/nportal/rest/priv/internalPricer/getMonthlyPaymentWithoutExpense',
  GEO_TIER: '/nportal/rest/priv/getMarketTier',
  INVESTOR_BUYDOWNS: '/nportal/rest/priv/pricer/getAllInvestorBuydowns',
  ALL_LENDER_ID_NAMES: '/nportal/rest/priv/lender/getAllLenderIdNames',
};

export class InternalPricerService extends Service {

  getRatesDictionary(data) {
    return this.post(url.RATES_DICTIONARY, data);
  }

  getLoanValues(loanId, isDraft) {
    return this.get(`${url.LOAN_VALUES}?id=${loanId}&isDraft=${isDraft}`);
  }

  getRentalDealNames(
    page: number,
    pageSize: number,
    sortDir: string,
    sortBy: string,
    filters: object
  ) {
    return this.get(url.RENTAL_DEAL_NAMES, {
      page,
      pageSize,
      sortDir,
      sortBy,
      filters,
    });
  }

  getDefaultValues() {
    return this.get(url.DEFAULT_VALUES);
  }

  getStateCounties() {
    return this.get(url.STATE_COUNTIES);
  }

  getMonthlyPayment(data) {
    return this.post(url.MONTHLY_PAYMENT, data);
  }

  getMarketTier(value) {
    return this.get(`${url.GEO_TIER}?stateCounty=${value}`);
  }

  getInvestorBuydowns() {
    return this.get(url.INVESTOR_BUYDOWNS);
  }

  getAllLenderNames() {
    return this.get(url.ALL_LENDER_ID_NAMES);
  }
}
