import { Layout } from '@roc/feature-app-core';
import {
  useLoanStore,
  LoanTabs
} from '@roc/feature-loans';
import { observer } from 'mobx-react';
import { TodaysClosingDataGrids } from './todaysClosingDataGrids';
import { useClosingTrackerStore } from '../hooks/useClosingTrackerStore';
import { TomorrowExpectedClosingDataGrids } from './tomorrowExpectedClosingDataGrids';
import { MonthlyClosingDataGrids } from './monthlyClosingDataGrids';

const renderTabContent = (loanStore) => {
  const { closingTrackerStore } = useClosingTrackerStore();
  switch (loanStore.selectedTabIndex) {
    case 0:
      return <TodaysClosingDataGrids store={closingTrackerStore} />;
    case 1:
      return <TomorrowExpectedClosingDataGrids store={closingTrackerStore} />;
    case 2:
      return <MonthlyClosingDataGrids store={closingTrackerStore} />;
    default:
      console.error('Invalid tab index');
      break;
  }
};

export const ClosingTrackerHome = observer(() => {
  const { loanStore } = useLoanStore();
  const { selectedTabIndex } = loanStore;


  const TABS = [
    {
      label: `Today's Closing Data`,
    },
    {
      label: `Next Businness Day's Expected Closing Data`,
    },
    {
      label: 'Monthly Closing Data'
    }
  ]
  const showSwitchView = () => {
    switch (selectedTabIndex) {
      case 1:
        return true;
      default:
        return false;
    }
  };
  return (
    <Layout>
      <LoanTabs tabs={TABS} showSwitchView={showSwitchView} showFilterOption={false} />
      {renderTabContent(loanStore)}
    </Layout>
  );
});