import { useEffect, useState } from 'react';
import {
  Grid,
  Typography,
  Button,
  Box,
  makeStyles,
  createStyles,
  Theme,
  Link,
  Badge,
  Chip,
} from '@material-ui/core';
import { observer } from 'mobx-react';
import BorrowerInformation from '../components/borrowerInformation';
import QuickChecks from '../components/quickChecks';
import { BackgroundItemStore } from '../stores/backgroundItemStore';
import LetterOfExplanation from '../components/letterOfExplanation';
import FicoScoreData from '../components/ficoScoreData';
import TradelineSummary from '../components/tradelineSummary';
import TradelineData from '../components/tradelineData';
import MortgageAccounts from '../components/mortgageAccounts';
import OtherAccounts from '../components/otherAccounts';
import MortgageHistory from '../components/mortgageHistory';
import CollectionAccounts from '../components/collectionAccounts';
import { BORROWER_REVIEW_TYPES } from '../components/utils/backgroundUtils';
import { ConfirmDialog, ConfirmationButton, StandardDialog } from '@roc/ui';
import CreditComment from '../components/creditComment';
import { LinkOutlined } from '@material-ui/icons';
import { ManualOrAutomatedChip } from '../components/manualOrAutomatedChip';
import { PublicRecords } from '../components/publicRecords';
import BorrowerIncomeInfoData from '../components/borrowerPFSInfo';
import ChargeOffAccounts from '../components/chargeOffAccounts';
import { ReviewType } from '../types/reviewType';
import AuditLogs from '../components/utils/auditLogs';
import { useResolutionStore } from '../hooks/useResolutionStore';
import { ResolutionInfo } from '../components/utils/resolutionInfo';
import { useUserStore } from '@roc/feature-app-core';
import { useLoanStore } from '@roc/feature-loans';
import SaveButtonWithPermissions from 'libs/ui/src/lib/components/shared/save-button-with-permissions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    radioFieldContainer: {
      '& .MuiFormLabel-root': {
        fontSize: '13px',
      },
    },
    addressWrapper: {
      '& .MuiInputBase-root.Mui-disabled': {
        backgroundColor: theme.palette.grey[50],
      },
      padding: '16px',
    },
    addressGrid: {
      paddingTop: '12px',
    },
    gridContainer: {
      padding: theme.spacing(0, 2)
    },
    link: {
      color: '#2199E0',
      cursor: 'pointer',
      display: 'flex'
    }
  })
);

interface Props {
  hideTitle?: boolean;
  store: BackgroundItemStore;
  onClose?: () => void;
  showEmptySections?: boolean;
  showBorrowerInformation?: boolean;
  showResolutions?: boolean;
  showButtons?: boolean;
}

export const CreditReviewForm = observer((props: Props) => {
  const {
    store,
    hideTitle = false,
    showEmptySections = true,
    showBorrowerInformation = true,
    showResolutions = true,
    showButtons = true,
  } = props;
  const classes = useStyles();
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [confirmCompleteReviewModalOpen, setConfirmCompleteReviewModalOpen] = useState(false);
  const { backgroundSection } = store.backgroundSectionDetails;
  const { resolutionStore } = useResolutionStore();

  useEffect(() => {
    const fetchData = async () => {
      await store.getBackgroundReviewData(BORROWER_REVIEW_TYPES.BORROWER_CREDIT_REVIEW);
    };
    fetchData();
  }, [])

  useEffect(() => {
    resolutionStore.setInternal(store.isInternal);
  }, [store.isInternal]);


  const handleCloseConfirmCompleteReviewModal = () => {
    setConfirmCompleteReviewModalOpen(false);
  }

  const handleConfirmationCompleteReviewAction = () => {
    store.submitReview(BORROWER_REVIEW_TYPES.BORROWER_CREDIT_REVIEW, handleCloseButton);
    handleCloseConfirmCompleteReviewModal();
  }

  const handleCloseConfirmModal = () => {
    setConfirmModalOpen(false);
  }

  const handleConfirmAction = () => {
    store.generateResolutions(BORROWER_REVIEW_TYPES.BORROWER_CREDIT_REVIEW);
    handleCloseConfirmModal();
  }

  const handleCloseButton = () => {
    if (store.isInternal) {
      window.parent.postMessage('close-modal', '*');
    }
    if (props.onClose) {
      props.onClose();
    }
  }

  const openLegacyForm = () => {
    if (store.showLegacyFormLink) {
      window.parent.postMessage('open-legacy-form', '*');
    }
    if (store.showLegacyFormLinkFromBorrowerForInternal) {
      const url = `/nportal/#/loan_information/${store.loanId}?openLegacyBorrowerCreditReview=true&borrowerId=${store.currentBorrower}`
      window.open(url, "_blank", "PopUp" + Math.random() +
        ",scrollbars=1,menubar=0,resizable=1,width=" +
        window.outerWidth +
        ",height=" +
        window.outerHeight
      );
    }
  }

  const handleOpenResolutionInfo = (data: any) => {
    resolutionStore.handleResolutionInfo(data);
  }

  const handleCloseResolutionInfo = () => {
    resolutionStore.setResolution(null);
    resolutionStore.setResolutionInfoOpen(false);
  }

  return (
    <>
      <Box mt={3} width={'100%'}>
        <ConfirmDialog
          open={confirmModalOpen}
          confirmButtonText={'Yes'}
          handleClose={handleCloseConfirmModal}
          handleCancel={handleCloseConfirmModal}
          handleConfirm={handleConfirmAction}
          title={'Request Resolution'}
          body={`Are you sure you want to request the resolutions?`}
        />
        <StandardDialog
          open={resolutionStore.resolutionInfoOpen}
          title={"Resolution"}
          maxWidth="sm"
          handleClose={handleCloseResolutionInfo}
          dialogContent={
            <Grid container>
              <ResolutionInfo resolution={resolutionStore.resolution} resolutionStore={resolutionStore} showComment />
            </Grid>
          }
        />
        {
          !hideTitle &&
          <Box mb={2} display={'flex'} alignItems={'center'}>
            <Typography variant="h4">Credit Review Report</Typography>
            {store.isInternal &&
              <ManualOrAutomatedChip automated={store.borrowerJson?.automated} />
            }
          </Box>
        }
        <Grid container spacing={2} className={classes.gridContainer}>
          {(store.showLegacyFormLink || store.showLegacyFormLinkFromBorrowerForInternal) && (
            <Grid item xs={12}>
              <Box display="flex" justifyContent='flex-end'>
                {store.isInternal && <Link onClick={openLegacyForm} className={classes.link}><LinkOutlined />&nbsp;Open Legacy Form</Link>}
              </Box>
            </Grid>
          )}
        </Grid>
        <Grid className={classes.addressGrid}>
          {showBorrowerInformation && (
            <Grid className={classes.addressWrapper}>
              <BorrowerInformation store={store} type={ReviewType.CREDIT} />
            </Grid>
          )}
          <Grid className={classes.addressWrapper}>
            <FicoScoreData store={store} />
          </Grid>
          <Grid className={classes.addressWrapper}>
            <BorrowerIncomeInfoData store={store} />
          </Grid>
          {(showEmptySections || store?.quickChecks?.length > 0) && (
            <Grid className={classes.addressWrapper}>
              <QuickChecks store={store} openResolutionInfo={handleOpenResolutionInfo} />
            </Grid>
          )}
          {(showEmptySections || backgroundSection?.tradelineSummary?.length > 0) && (
            <Grid className={classes.addressWrapper}>
              <TradelineSummary store={store} openResolutionInfo={handleOpenResolutionInfo} />
            </Grid>
          )}
          {(showEmptySections || store?.tradelineDataRows?.length > 0) && (
            <Grid className={classes.addressWrapper}>
              <TradelineData store={store} openResolutionInfo={handleOpenResolutionInfo} />
            </Grid>
          )}
          {(showEmptySections || backgroundSection?.mortgageHistory?.length > 0) && (
            <Grid className={classes.addressWrapper}>
              <MortgageHistory store={store} openResolutionInfo={handleOpenResolutionInfo} />
            </Grid>
          )}
          {(showEmptySections || backgroundSection?.mortgageLateTradelines?.length > 0) && (
            <Grid className={classes.addressWrapper}>
              <MortgageAccounts store={store} openResolutionInfo={handleOpenResolutionInfo} />
            </Grid>
          )}
          {(showEmptySections || backgroundSection?.otherLateTradelines?.length > 0) && (
            <Grid className={classes.addressWrapper}>
              <OtherAccounts store={store} openResolutionInfo={handleOpenResolutionInfo} />
            </Grid>
          )}
          {(showEmptySections || backgroundSection?.collectionTradelines?.length > 0) && (
            <Grid className={classes.addressWrapper}>
              <CollectionAccounts hideIfEmpty={!showEmptySections} store={store} openResolutionInfo={handleOpenResolutionInfo} />
            </Grid>
          )}
          {(showEmptySections || backgroundSection?.chargeOffTradelines?.length > 0) && (
            <Grid className={classes.addressWrapper}>
              <ChargeOffAccounts hideIfEmpty={!showEmptySections} store={store} openResolutionInfo={handleOpenResolutionInfo} />
            </Grid>
          )}
          {(showEmptySections || backgroundSection?.publicRecords?.length > 0) && (
            <Grid className={classes.addressWrapper}>
              <PublicRecords hideIfEmpty={!showEmptySections} store={store} openResolutionInfo={handleOpenResolutionInfo} />
            </Grid>
          )}
          {store.isInternal &&
            <Grid className={classes.addressWrapper}>
              <CreditComment store={store} />
            </Grid>
          }
          {showResolutions && (
            <Grid className={classes.addressWrapper}>
              <LetterOfExplanation store={store} />
            </Grid>
          )}
          {store.isInternal &&
            <Grid className={classes.addressWrapper}>
              <AuditLogs store={store} />
            </Grid>
          }
          {showButtons && (
            <Grid container spacing={2} direction='row' justifyContent='center'>
              {store.lockedData || !store.isInternal ? (
                <>
                  <Grid item xs={12} sm={2}>
                    <Button
                      onClick={handleCloseButton}
                      style={{ color: '#2199E0', borderColor: '#2199E0', backgroundColor: '#E2F6FF' }}
                      variant="outlined" color="primary" fullWidth>
                      CANCEL
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <SaveButtonWithPermissions
                      onClick={() => store.saveData(BORROWER_REVIEW_TYPES.BORROWER_CREDIT_REVIEW, handleCloseButton)}
                      style={{ color: '#2199E0', borderColor: '#2199E0', backgroundColor: '#E2F6FF' }}
                      variant="outlined" color="primary" fullWidth
                      loanId={store.loanId}
                      label="SAVE"
                    />
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={12} sm={2}>
                    <Button
                      onClick={handleCloseButton}
                      style={{ color: '#2199E0', borderColor: '#2199E0', backgroundColor: '#E2F6FF' }}
                      variant="outlined" color="primary" fullWidth>
                      CANCEL
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <SaveButtonWithPermissions
                      style={{ color: '#2199E0', borderColor: '#2199E0', backgroundColor: '#E2F6FF' }}
                      variant="outlined" color="primary" fullWidth
                      onClick={() => store.saveData(BORROWER_REVIEW_TYPES.BORROWER_CREDIT_REVIEW, handleCloseButton)}
                      loanId={store.loanId}
                      label="SAVE"
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <ConfirmationButton
                      variant="contained"
                      onClick={handleConfirmationCompleteReviewAction}
                      color="primary"
                      testId="confirmReview"
                      confirmDialogProps={{
                        title: 'Complete Review',
                        body: 'Are you sure you want to mark this review as completed?',
                      }}
                      fullWidth
                    >
                      REVIEW COMPLETE
                    </ConfirmationButton>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <Button onClick={() => setConfirmModalOpen(true)} style={{ backgroundColor: '#2199E0', color: '#FFFFFF' }} type="submit" variant="contained" color="primary" fullWidth>
                      REQUEST RESOLUTIONS
                    </Button>
                  </Grid>
                </>
              )}
            </Grid>
          )}
        </Grid>
      </Box>
    </>
  );
});

export default CreditReviewForm;