import { action, flow, makeObservable, observable } from 'mobx';
import { GlobalStore, GridStore } from '@roc/feature-app-core';
import { BackgroundReviewFormStore } from './backgroundReviewFormStore';
import { BackgroundItemStore } from './backgroundItemStore';
import { BackgroundItemExternalStore } from './backgroundItemExternalStore';

export class CreditReviewExternalStore {
  globalStore: GlobalStore;
  creditReviewFormStore: BackgroundReviewFormStore;
  creditItemStore: BackgroundItemExternalStore;

  constructor(globalStore: GlobalStore) {
    this.globalStore = globalStore;
    this.creditReviewFormStore = new BackgroundReviewFormStore(
      globalStore
    );
    this.creditItemStore = new BackgroundItemExternalStore(
      globalStore,
      this.creditReviewFormStore
    );
    makeObservable(this, {

    });
  }
};