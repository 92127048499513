import {
  Typography,
  Grid,
  TextField,
} from '@material-ui/core';
import {
  Button,
} from '@roc/ui';
import { useState } from 'react';

interface CommentDialogProps {
  onClose: () => void;
  subTitle: string;
  props: any;
  handleRowItemChange: any;
}

const ReviewerCommentDialog = ({ onClose, subTitle, props, handleRowItemChange }: CommentDialogProps) => {
  const {category, row, idx} = props;
  const {reviewerComments} = props.item;
  const [comment, setComment] = useState<string>(reviewerComments? reviewerComments : '');

  const handleSaveOnComment = () =>{
    handleRowItemChange(category.name, row.name, !!row.itemized.length, idx, comment, 'reviewerComments');
    onClose();
  }
  const handleChange = () => (event: React.ChangeEvent<HTMLInputElement>) =>{
    setComment(event.target.value);
  }

  return (
    <Grid container justifyContent="center" spacing={3}>
      <Grid item xs={12}>
        <Typography variant="body2">
          {subTitle}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField variant="outlined" fullWidth onChange={handleChange()} value={comment}>
        </TextField>
      </Grid>
      <Grid item container justifyContent="center">
        <Grid item xs={4}>
          <Button
            variant="contained"
            size="small"
            onClick={() => handleSaveOnComment()}
            color="primary"
            testId="Comment_Cancel"
            fullWidth={true}
          >
            SAVE
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
};
export default ReviewerCommentDialog;