import { useEffect, useRef } from 'react';
import { TodoStatus } from './loanDetailsTypes';

export const isPendingTodoStatus = (status: TodoStatus) => {
  return [TodoStatus.PENDING, TodoStatus.MORE_INFO_NEEDED].includes(status);
};

export const useIframeMessaging = actions => {
  const actionsRef = useRef<Record<string, (params) => void>>();
  const postMessage = (action, params) => {
    const msg = { action, ...params };
    parent.postMessage(msg, '*');
  };

  actionsRef.current = actions;
  useEffect(() => {
    const handleMessage = event => {
      const data = event.data;
      const actions = actionsRef.current;
      actions[event.data?.action]?.(data);
    };
    addEventListener('message', handleMessage, false);
    return () => removeEventListener('message', handleMessage);
  }, []);

  return { postMessage };
};
