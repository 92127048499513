import { useEffect, useRef } from "react";

export const IframeHolder = ({ id, src, width, height }) => {
  const container = useRef();
  useEffect(() => {
    const frm = document.createElement('iframe');
    frm.src = src;
    frm.width = width;
    frm.height = height;
    frm.setAttribute('style', 'border: none;');
    container.current;
    document.getElementById(id).appendChild(frm);
  }, []);

  return (
    <div id={id} style={{ minHeight: 'calc(100vh - 360px)', height: '100%' }} />
  );
};