// @mui
import { ThemeOptions } from '@material-ui/core/styles';
//
import { ThemeConfiguration } from '@roc/feature-types';
import customShadows from './customShadows';
import componentsOverride from './overrides';
import palette from './palette';
import props from './props';
import shadows from './shadows';
import typography from './typography';

export const getThemeConfiguration = (): ThemeConfiguration => {
  const themeOptions: ThemeOptions = {
    palette,
    shape: { borderRadius: 6 },
    // @ts-ignore
    typography,
    // @ts-ignore
    props,
    // @ts-ignore
    shadows: shadows(),
    customShadows: customShadows()
  };

  return {
    themeOptions,
    componentsOverride
  }
};
