import React from 'react';
import { StandardDialog } from '@roc/ui';
import { InsuranceDetails } from './insuranceDetails';

export type InsuranceDetailsDialogProps = {
  open?: boolean;
  onClose?: () => void;
  quote: any;
};

export const InsuranceDetailsDialog = (props: InsuranceDetailsDialogProps) => {
  const { open, onClose, quote } = props;

  return (
    <StandardDialog
      open={open}
      title="Policy Details"
      maxWidth="lg"
      handleClose={onClose}
      dialogContent={<InsuranceDetails quote={quote} />}
    />
  );
};

export default InsuranceDetailsDialog;
