import { GlobalStore } from '@roc/feature-app-core';
import { flow, makeObservable, observable } from 'mobx';
import { ApiResponse } from '@roc/feature-app-core';
import { FindAppraiserService } from '../services/findAppraiserService';

export class FindAppraiserStore {
  globalStore: GlobalStore;
  findAppraiserService: FindAppraiserService;
  appraisers = [];

  constructor(globalStore) {
    this.globalStore = globalStore;
    this.findAppraiserService = new FindAppraiserService();
    makeObservable(this, {
      appraisers: observable,
      findAppraisers: flow,
    });
  }

  *findAppraisers(address) {
    try {
      const { lat, lng, state } = this.parseAddressValues(address);
      const response: ApiResponse = yield this.findAppraiserService.findAppraiser(
        lat,
        lng,
        state
      );
      this.appraisers = response.data?.data;
    } catch (error) {
      // do nothing
    }
  }

  private parseAddressValues(value) {
    const {
      geometry: { location },
      address_components,
    } = value;
    const state = address_components.find(item =>
      item.types.includes('administrative_area_level_1')
    )?.short_name;
    return {
      lat: location.lat(),
      lng: location.lng(),
      state,
    };
  }
}
