import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { useCommunicationRoutesConfig } from './hooks';
import { MyChatContainer, SelectConversation } from './myChatContainer';
import { FilterType } from './types/communicationTypes';
import { useTechSupportCommunicationStore } from './hooks/useTechSupportCommunicationStore';

const useStyles = makeStyles((theme: Theme) => ({
  chatContainer: {
    flex: 1,
  },
}));

interface TechSupportConversationProps {
  showEmailAddress?: boolean;
}

export const TechSupportConversationInternal = observer(
  ({
    showEmailAddress = false,
  }: TechSupportConversationProps) => {
    const classes = useStyles();

    const params = new URLSearchParams(location.search);

    const userId = parseInt(params.get('userId'))

    const { techSupportCommunicationStore } = useTechSupportCommunicationStore();

    const { communicationRoutesConfig } = useCommunicationRoutesConfig();
    const { push } = useHistory();

    return (
      <Switch>
        <Route path={communicationRoutesConfig.techSupportConversations.path}>
          <>
            <Box display="flex" width={'100%'}>
              <MyChatContainer
                id={Number(userId)}
                communicationStore={techSupportCommunicationStore}
                allowNewConversations={false}
                allowJoinConversation={true}
                showEmailAddress={showEmailAddress}
                showSnippet={false}
                allowRemoveParticipants={true}
                allowManageParticipants={true}
                allowAddInternalParticipants={true}
                onConversationSelect={conversationId =>
                  push(
                    communicationRoutesConfig
                      .techSupportConversations
                      .children.conversation(conversationId).url
                  )
                }
                className={classes.chatContainer}
                filterType={FilterType.INTERNAL_SUPPORT}
              />
            </Box>
            <Switch>
              <Route
                exact
                path={
                  communicationRoutesConfig
                    .techSupportConversations
                    .children.conversation().path
                }
              >
                <SelectConversation
                  communicationStore={techSupportCommunicationStore}
                />
              </Route>
            </Switch>
          </>
        </Route>
      </Switch>
    );
  }
);
