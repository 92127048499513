import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { StandardDialog, Button } from '@roc/ui';
import { ExpectedWireReleaseDate } from './expectedWireReleaseDate';
import { ExpectedWireReleaseDateStore } from '../../../stores/documentForms/expectedWireReleaseDateStore';

export type ExpectedWireReleaseDateDialogProps = {
  open?: boolean;
  onClose?: () => void;
  loanId: string;
  store: ExpectedWireReleaseDateStore;
};

export const ExpectedWireReleaseDateDialog = (
  props: ExpectedWireReleaseDateDialogProps
) => {
  const { open, onClose, loanId, store } = props;

  const onSubmit = () => {
    store.submitexpectedWireReleaseDateState();
    if (!onClose) return;
    onClose();
  };

  return (
    <StandardDialog
      open={open}
      title="Expected Wire Release Date"
      maxWidth="xs"
      handleClose={onClose}
      dialogContent={<ExpectedWireReleaseDate store={store} />}
      dialogActions={
        <Grid container spacing={1} justifyContent="flex-end">
          <Grid item>
            <Button
              onClick={onClose}
              color="primary"
              variant="outlined"
              testId="cancel"
            >
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={onSubmit}
              color="primary"
              variant="contained"
              testId="confirm"
              disabled={!store.shouldBeAbleToSubmitExpectedWireReleaseDate()}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      }
    />
  );
};

export default ExpectedWireReleaseDateDialog;
