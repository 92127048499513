import { Route } from 'react-router';
import { useCompToolStore } from '../hooks';
import { Page } from '@roc/feature-app-core';
import { SearchPropertyScreen } from '../components/searchProperty/searchPropertyScreen';
import { PropertiesGridView } from '../components/gridViewScreen/propertiesGridView';

export const getCompToolRoutesConfig = (
  basePath: string,
  baseUrl: string
) => {
  return {
    compTool: {
      path: `${basePath}/backoffice/comp-tool`,
      url: `${baseUrl}/backoffice/comp-tool`,
      documentTitle: 'Comp Tool',
    }
  };
}

export const getCompToolRoutes = (basePath: string, baseUrl: string) => {
  const config = getCompToolRoutesConfig(basePath, baseUrl);
  return {
    compTool: (
      <Route exact path={config.compTool.path}>
        <Page routeDefinition={config.compTool}>
          <SearchPropertyScreen />
        </Page>
      </Route>
    )
  };
};
