import { Box, Card, CardContent, Grid, Typography } from "@material-ui/core";
import { Close, CloudDownload, CloudUpload } from "@material-ui/icons";
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { Button, IconButton } from "@roc/ui";
import { FC, SyntheticEvent } from "react";
import { useDropzone } from "react-dropzone";

const useFileUploadStyles = makeStyles((theme: Theme) => ({
  card: {
    marginTop: theme.spacing(2)
  },
  fileLine: {
    display: 'flex',
    alignItems: 'center'
  },
  dragArea: {
    border: '2px dotted #427ED1',
    width: '100%',
    backgroundColor: '#EAF4FA',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: 20,
    borderRadius: 5,
    cursor: 'pointer',
  },
  icon: {
    minWidth: '20px',
    minHeight: '20px',
    width: '3vw',
    height: '3vw',
    color: '#4B89DA',
  },
  removeButton: {
    marginLeft: theme.spacing(2),
    alignSelf: 'flex-end',
    alignItems: 'flex-end',
    float: 'right',
  },
}));

interface FileUploadDownloadAreaProps {
  file: File | null;
  onChange: (file: File | null) => void;
  onUpload?: (file: File | null) => void;
  onDownload?: () => void;
  disableDownload?: boolean;
  infoMessage?: string;
}

export const FileUploadDownloadArea: FC<FileUploadDownloadAreaProps> = ({ file, onChange, infoMessage, onUpload, onDownload, disableDownload = true }) => {
  function onDrop(newFiles: File[]) {
    onChange(newFiles?.[0]);
    if (onUpload) {
      onUpload(newFiles?.[0]);
    }
  }

  function handleOnRemove(event: SyntheticEvent) {
    event.stopPropagation();
    onChange(null);
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
  });

  const classes = useFileUploadStyles();
  return (
    <Card variant="outlined" className={classes.card}>
      <CardContent >
        <Grid container direction='row' justifyContent='space-between' spacing={2}>
          {infoMessage ? (
            <Grid item >
              <Typography
                component="span"
                variant='subtitle2'
                color="textSecondary"
              >
                {infoMessage}
              </Typography>
            </Grid>
          ) : (
            <Grid item >
              {onDownload &&
                <Typography
                  component="span"
                  variant='subtitle2'
                  color="textSecondary"
                >
                  File is already uploaded
                </Typography>
              }
            </Grid>
          )}
          {onDownload &&
            <Grid item >
              <IconButton
                color="primary"
                testId="download"
                disabled={disableDownload}
                onClick={onDownload}
                size="small"
              >
                <CloudDownload />
              </IconButton>
            </Grid>
          }
        </Grid>
        <Box {...getRootProps()} className={classes.dragArea}>
          <input {...getInputProps()} />
          <CloudUpload className={classes.icon} />
          {file ? (
            <Box className={classes.fileLine}>
              <Typography>{file?.name}</Typography>
              <Button
                testId={`removeFile-${file?.name}`}
                size="small"
                variant="outlined"
                className={classes.removeButton}
                onClick={handleOnRemove}
              >
                REMOVE <Close />
              </Button>
            </Box>
          ) : isDragActive ? (
            <p>Drop the file(s) here...</p>
          ) : (
            <>
              <p>Drag File to Upload</p>
              <p>or Browse file</p>
            </>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};