import { action, computed, flow, makeObservable } from 'mobx';
import { GlobalStore } from '@roc/feature-app-core';
import { DocumentStore } from '@roc/feature-documents';
import { LoanStore } from '@roc/feature-loans';
import { GoodFaithDepositService } from '../../services/documentForms/goodFaithDepositService';

export class GoodFaithDepositStore {
  private loanStore: LoanStore;
  private documentStore: DocumentStore;
  private globalStore: GlobalStore;
  private goodFaithDepositService: GoodFaithDepositService;

  constructor(
    loanStore: LoanStore,
    documentStore: DocumentStore,
    globalStore: GlobalStore
  ) {
    this.loanStore = loanStore;
    this.documentStore = documentStore;
    this.globalStore = globalStore;
    this.goodFaithDepositService = new GoodFaithDepositService();
    makeObservable(this, {
      requestBorrowerDeposit: flow,
      reset: action,
      validationErrors: computed,
      hasValidationErrors: computed,
      depositUrl: computed,
    });
  }

  *requestBorrowerDeposit() {
    try {
      const loanId = this.loanStore.loanDetails.loanId;
      yield this.goodFaithDepositService.requestBorrowerDeposit(loanId);
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Payment email successfully sent',
      });
    } catch (err) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error on sending payment email',
      });
    }
  }

  reset() {
    // No reset logic required yet.
  }

  get validationErrors() {
    const { goodFaithDepositValidationErrors } = this.loanStore.loanDetails;
    return goodFaithDepositValidationErrors;
  }

  get hasValidationErrors() {
    return this.validationErrors?.length > 0;
  }

  get depositUrl() {
    const { depositUrl } = this.loanStore.loanDetails;
    return depositUrl;
  }
}

export default GoodFaithDepositStore;
