import { action, makeObservable, observable, override } from 'mobx';
import { DscrCalculatorService } from '../../dscrCalculator/services/dscrCalculatorService';
import { LoanService } from '@roc/feature-loans';
import { LiquidityCalculatorService } from '../services/liquidityCalculatorService';
import { LiquidityCalculatorFormValues } from '../types/liquidityCalculatorTypes';
import { LoanPurposeOption } from '../utils/liquidityCalculatorConstants';
import { LiquidityCalculatorBaseStore } from './liquidityCalculatorBaseStore';

export class LiquidityCalculatorStore extends LiquidityCalculatorBaseStore {
  private dscrCalculatorService: DscrCalculatorService;
  private liquidityCalculatorService: LiquidityCalculatorService;
  private loanService: LoanService;

  constructor(globalStore) {
    super(globalStore);
    this.dscrCalculatorService = new DscrCalculatorService();
    this.liquidityCalculatorService = new LiquidityCalculatorService();
    this.loanService = new LoanService();

    makeObservable(this, {
      getCalculatedLiquidity: override,
      getLoanValues: override,
      getAutocompleteOptions: override,
    });
  }

  *getCalculatedLiquidity(data) {
    const response = yield this.liquidityCalculatorService.getLiquidity(data);
    return response?.data;
  }

  *getLoanValues(loanId: string, isDraft: boolean): LiquidityCalculatorFormValues {
    const response = yield this.dscrCalculatorService.getLoanValues(loanId, isDraft);
    return response.data.data;
  }

  *getAutocompleteOptions(searchTerm: string) {
    const filters = { dealName: searchTerm };
    const response = yield this.loanService.getRentalDealNames(
      1,
      25,
      null,
      null,
      filters
    );
    const rows = response.data.data.rows;
    return rows.map(row => ({
      label: row.dealName,
      value: row.dealid ? row.dealid : 'DRAFT' + row.draftid,
    }));
  }
}
