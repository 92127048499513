import { HTMLProps, ReactNode, useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import { GridStore } from '@roc/feature-app-core';
import { grid } from 'ionicons/icons';
import { Box, CircularProgress } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  container: {
    overflowY: 'auto',
  },
}));

function isOnBottom(el) {
  return el.scrollHeight - el.scrollTop - el.clientHeight < 1;
}

const InfiniteScrollListComponent = ({
  children,
  onBottomReached,
  ...props
}) => {
  const classes = useStyles();
  const containerRef = useRef();

  const handleScroll = e => {
    if (isOnBottom(containerRef.current)) {
      onBottomReached();
    }
  };

  return (
    <div
      ref={containerRef}
      {...props}
      onScroll={handleScroll}
      className={clsx(props.className, classes.container)}
    >
      {children}
    </div>
  );
};

interface InfiniteScrollListProps extends HTMLProps<HTMLDivElement> {
  gridStore: GridStore;
  renderItem: (row: any) => ReactNode;
  renderEmpty: () => ReactNode;
}

export const InfiniteScrollList = observer(
  ({
    gridStore,
    renderItem,
    renderEmpty,
    ...divProps
  }: InfiniteScrollListProps) => {
    const [isMoreLoading, setIsMoreLoading] = useState(false);

    const totalCount = gridStore.gridData.data.totalCount;
    const rows = gridStore.gridData.data.rows || [];
    const isLoading = gridStore.isLoading || isMoreLoading;

    const fetchMore = async () => {
      const data = await gridStore.getRowsBatch({ startRow: rows.length });
      gridStore.setData(rows.concat(data.rows));
    }

    useEffect(() => {
        gridStore.fetchGridData();
    }, []);

    const handleBottomReached = async () => {
      if(rows.length < totalCount){
        setIsMoreLoading(true);
        await fetchMore();
        setIsMoreLoading(false);
      }
    };



    return (
      <InfiniteScrollListComponent
        {...divProps}
        onBottomReached={handleBottomReached}
      >
        {rows.length > 0 && (
          <>{rows.map(row => renderItem(row))}</>
        )}
        {!isLoading && rows.length === 0 && (
          <>{renderEmpty()}</>
        )}
        {isLoading && (
          <Box textAlign="center">
            <CircularProgress />
          </Box>
        )}
      </InfiniteScrollListComponent>
    );
  }
);
