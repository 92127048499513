import { GlobalStore } from '@roc/client-portal-shared/stores';
import { AnySoaRecord } from 'dns';
import { action, flow, makeObservable, observable } from 'mobx';
import { FormStore } from '@roc/feature-app-core';
import {
  getLoanSubtype,
  mapLoanToDscrCalculatorFormValues,
} from '../../dscrCalculator/utils/dscrCalculatorUtils';
import {
  CalculatedLiquidityValues,
  LiquidityCalculatorFormValues,
} from '../types/liquidityCalculatorTypes';
import { LoanPurposeOption } from '../utils/liquidityCalculatorConstants';
import { mapLoanPurpose } from '../utils/liquidityCalculatorUtils';

const form = {
  fields: {
    loanId: {
      value: '',
      error: null,
      rule: '',
    },
    loanAmount: {
      value: '',
      error: null,
      rule: 'required',
      attribute: 'Loan Amount',
    },
    rate: {
      value: '',
      error: null,
      rule: 'required',
      attribute: 'Rate',
    },
    annualTaxes: {
      value: '',
      error: null,
      rule: '',
    },
    annualInsurance: {
      value: '',
      error: null,
      rule: '',
    },
    loanPurpose: {
      value: LoanPurposeOption.CASHOUT,
      error: null,
      rule: '',
    },
    amortization: {
      value: '',
      error: null,
      rule: '',
    },
    loanSubType: {
      value: '',
      error: null,
      rule: '',
    },
    isBorrowerForeignNational: {
      value: false,
      error: null,
      rule: '',
    },
    isMultifamilyOrMixedUseProperty: {
      value: false,
      error: null,
      rule: '',
    },
    purchasePrice: {
      value: '',
      error: null,
      rule: '',
    },
    depositPaid: {
      value: '',
      error: null,
      rule: '',
    },
    originationFees: {
      value: '',
      error: null,
      rule: '',
    },
    payoff: {
      value: '',
      error: null,
      rule: '',
    },
    downPayment: {
      value: '',
      error: null,
      rule: '',
    },
    originationAmount: {
      value: '',
      error: null,
      rule: '',
    },
    closingCosts: {
      value: '',
      error: null,
      rule: '',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export abstract class LiquidityCalculatorBaseStore extends FormStore {
  protected globalStore: GlobalStore;
  calculatedValues: any;

  abstract getCalculatedLiquidity(data): CalculatedLiquidityValues;
  abstract getLoanValues(loanId: string, isDraft: boolean): LiquidityCalculatorFormValues;
  abstract getAutocompleteOptions(searchTerm: string);

  loanSearchText = '';
  loanSearchOptions: { label: string; value: string }[] = [];

  constructor(globalStore) {
    super(form, globalStore);
    this.globalStore = globalStore;

    makeObservable(this, {
      loanSearchText: observable,
      calculatedValues: observable,
      loanSearchOptions: observable,
      getCalculatedLiquidity: flow,
      getAutocompleteOptions: flow,
      calculateClosingCostValue: action,
      calculateDownPaymentValue: action,
      calculateOriginationAmountValue: action,
      getLoanValues: flow,
      fetchOptions: flow,
      fetchLoanValues: flow,
      fetchCalculatedLiquidity: flow,
      clearCalculatedValues: action,
    });
  }

  *fetchOptions(searchText: string) {
    try {
      if (!searchText) {
        this.loanSearchOptions = [];
        return;
      }
      const options = yield this.getAutocompleteOptions(searchText);
      this.loanSearchOptions = options;
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'An error occurred while loading the list of loans.',
      });
    }
  }

  *fetchLoanValues(loanId: string, isDraft: boolean) {
    try {
      const loanValues = yield this.getLoanValues(loanId, isDraft);
      const values = mapLoanToDscrCalculatorFormValues(loanValues);
      this.loadForm(values);

      const loanPurpose = mapLoanPurpose(loanValues.loanPurpose);
      this.loadForm({ loanPurpose });
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'An error occurred while loading the loan data.',
      });
    }
  }

  *fetchCalculatedLiquidity() {
    try {
      this.runFormValidationWithMessage();
      if (this.form.meta.isValid) {
        const loanPurpose = this.form.fields.loanPurpose.value;
        const loanAmount = this.form.fields.loanAmount.value;
        const rate = this.form.fields.rate.value;
        const annualTaxes = this.form.fields.annualTaxes.value;
        const annualInsurance = this.form.fields.annualInsurance.value;
        const originationFees = this.form.fields.originationFees.value;
        const purchasePrice = this.form.fields.purchasePrice.value;
        const depositPaid = this.form.fields.depositPaid.value;
        const payoff = this.form.fields.payoff.value;
        const amortization = this.form.fields.amortization.value;
        const loanSubType = this.form.fields.loanSubType.value;
        const isBorrowerForeignNational = this.form.fields
          .isBorrowerForeignNational.value;
        const isMultifamilyOrMixedUseProperty = this.form.fields
          .isMultifamilyOrMixedUseProperty.value;

        const data = {
          loanAmount: loanAmount,
          rate: rate,
          annualTaxes: annualTaxes,
          annualInsurance: annualInsurance,
          loanPurpose: loanPurpose,
          amortizationType: amortization,
          loanSubType: loanSubType,
          originationFees: null,
          purchasePrice: null,
          depositPaid: null,
          payoff: null,
          isBorrowerForeignNational: isBorrowerForeignNational,
          isMultifamilyOrMixedUseProperty: isMultifamilyOrMixedUseProperty,
        };

        if (amortization === 'Fully Amortizing') {
          data.amortizationType = 'fullyAmortizing';
        } else {
          data.amortizationType = 'partialInterest';
        }

        this.calculateClosingCostValue();
        this.calculateDownPaymentValue();
        this.calculateOriginationAmountValue();

        if (loanPurpose === LoanPurposeOption.PURCHASE) {
          data.originationFees = originationFees;
          data.purchasePrice = purchasePrice;
          data.depositPaid = depositPaid;
        }

        if (loanPurpose === LoanPurposeOption.RATE_TERM_REFI) {
          data.originationFees = originationFees;
          data.payoff = payoff;
        }

        const response = yield this.getCalculatedLiquidity(data);
        this.calculatedValues = response;
      }
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'An error occurred while loading the calculated values.',
      });
    }
  }

  calculateClosingCostValue() {
    const values = this.getFormValues();

    const calculateValue = baseValue => {
      return baseValue ? parseFloat(baseValue) * 0.05 : '';
    };

    const currencyValues = {
      closingCosts: null,
    };

    if (values.loanPurpose === LoanPurposeOption.PURCHASE) {
      currencyValues.closingCosts = calculateValue(values.loanAmount);
    }

    if (values.loanPurpose === LoanPurposeOption.RATE_TERM_REFI) {
      currencyValues.closingCosts = calculateValue(values.payoff);
    }

    this.loadForm(currencyValues);
  }

  calculateDownPaymentValue() {
    const values = this.getFormValues();

    const calculateValue = (loanAmount, purchasePrice, depositPaid) => {
      return (
        parseFloat(purchasePrice || 0) -
        parseFloat(loanAmount || 0) -
        parseFloat(depositPaid || 0)
      );
    };

    const currencyValues = {
      downPayment: calculateValue(
        values.loanAmount,
        values.purchasePrice,
        values.depositPaid
      ),
    };

    this.loadForm(currencyValues);
  }

  calculateOriginationAmountValue() {
    const values = this.getFormValues();

    const calculateValue = (loanAmount, originationFees) => {
      return (
        (parseFloat(loanAmount || 0) * parseFloat(originationFees || 0)) / 100
      );
    };

    const currencyValues = {
      originationAmount: calculateValue(
        values.loanAmount,
        values.originationFees
      ),
    };

    this.loadForm(currencyValues);
  }

  clearCalculatedValues() {
    this.calculatedValues = null;
  }
}
