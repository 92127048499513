import React from 'react';
import {

  Step,
  StepButton,
  StepConnector,
  StepLabel,
  Stepper,

} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { StepIcon } from './stepIcon';
import { StepTitle } from './stepTitle';
import clsx from 'clsx';

export interface StepperComponentProps {
  disableNavigation?: boolean;
  steps: Array<StepObj>;
  activeStep: number;
  handleStep?: (value: number) => void;
  className?: string;
  alternativeLabel?: boolean;
}

export interface StepObj {
  title: string;
  icon: JSX.Element;
  onBack?: () => void;
  onNext?: () => void;
  onSave?: () => void;
  onReprice?: () => void;
  onReject?: () => void;
  allowBack?: boolean;
  allowNext?: boolean;
  allowSave?: boolean;
  allowReprice?: boolean;
  completed?: boolean;
  nextButtonText?: string;
  saveButtonText?: string;
  allowReject?: boolean;
  disableNextButton?: boolean;
  disabledStep?: boolean;
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    stepper: {
      [theme.breakpoints.down('xs')]: {
        '& .MuiStepLabel-label': {
          display: 'none',
        },
        '& .MuiStepLabel-label.MuiStepLabel-active': {
          display: 'block',
        },
      },
    },
  })
);

export const StepperComponent: React.FC<StepperComponentProps> = ({
  disableNavigation,
  steps,
  activeStep,
  handleStep,
  className,
  alternativeLabel
}) => {
  const classes = useStyle();
  return (
    <Stepper
      activeStep={activeStep}
      connector={<StepConnector />}
      className={clsx([classes.stepper, className])}
      alternativeLabel={alternativeLabel}
    >
      {steps.map((step, index) => {
        return (
          <Step key={`${step.title}-${index}`} completed={step.completed}>
            <StepButton
              disabled={disableNavigation}
              onClick={() => handleStep && handleStep(index)}
            >
              {step.icon ? (
                <StepLabel
                  StepIconComponent={props => (
                    <StepIcon {...props} disabled={step.disabledStep}>{step.icon}</StepIcon>
                  )}
                >
                  <StepTitle active={activeStep == index}>
                    {step.title}
                  </StepTitle>
                </StepLabel>
              ) : (
                <StepLabel>
                  <StepTitle active={activeStep == index}>
                    {step.title}
                  </StepTitle>
                </StepLabel>
              )}
            </StepButton>
          </Step>
        );
      })}
    </Stepper>
  );
};
