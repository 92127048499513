import { useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { Button, TextField } from '@roc/ui';
import { Alert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';

import LoanFastrackDocusignModal from './loanFastrackDocusignModal';
import {
  DOCUSIGN_CALLBACK,
  DOCUSIGN_DONE,
  DOCUSIGN_INCOMPLETE,
  LOAN_FASTRACK,
} from '../constants/loanFastrackPayments';
import { useLoanFastrackPaymentsStore } from '../hooks/useLoanFastrackPaymentsStore';
import { isNotBlank } from '@roc/feature-utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    successAlert: {
      backgroundColor: 'transparent',
      '& .MuiAlert-icon': {
        marginRight: theme.spacing(1),
      },
    },
    borrowerInfoGrid: {
      padding: theme.spacing(4),
    },
  })
);

interface Props {
  handleBackStep: () => void;
  handleNextStep: () => void;
  entityId: string;
  tamariskFlowType: string;
}

const LoanFastrackDocusign = observer((props: Props) => {
  const [docusingError, setDocusingError] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const { loanFastrackPaymentsStore } = useLoanFastrackPaymentsStore();
  const { docusignStatus } = loanFastrackPaymentsStore;
  const { form } = loanFastrackPaymentsStore.loanFastrackDocumentStore;
  const classes = useStyles();

  useEffect(() => {
    window.addEventListener('message', handleWindowMessage);
    return () => window.removeEventListener('message', handleWindowMessage);
  }, []);

  const setDocusignStatus = (value: boolean) =>
    loanFastrackPaymentsStore.setDocusignStatus(value);

  const handleModalClose = () => setModalOpen(false);

  const handleModalOpen = () => {
    setModalOpen(true);
  };


  const handleWindowMessage = (event: MessageEvent) => {
    switch (event.data) {
      case DOCUSIGN_DONE:
        setDocusignStatus(true);
        loanFastrackPaymentsStore.loanFastrackDocumentStore.fetchLoanFastrackInfo(
          props.entityId,
          form.fields.fullName.value
        );
        loanFastrackPaymentsStore.persistDataAfterDocusignComplete(
          props.entityId,
          form.fields.fullName.value,
          form.fields.email.value,
          props.tamariskFlowType
        );
        break;
      case DOCUSIGN_INCOMPLETE:
        setDocusignStatus(false);
        setDocusingError(true);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (modalOpen) {
      setDocusingError(false);
      const request = {
        entityId: props.entityId,
        type: props.tamariskFlowType,
        callbackURL: `${window.location.origin}${DOCUSIGN_CALLBACK}`,
        fullName: form.fields.fullName.value,
        email: form.fields.email.value,
      };
      loanFastrackPaymentsStore.fetchLoanFastrackDocument(request);
    } else {
      loanFastrackPaymentsStore.setTamariskDocumentUrl(null);
    }
  }, [modalOpen]);

  useEffect(() => {
    if (form.fields.fullName.value && docusignStatus) {
      loanFastrackPaymentsStore.loanFastrackDocumentStore.fetchLoanFastrackInfo(
        props.entityId,
        form.fields.fullName.value
      );
    }
  }, [form.fields.fullName.value, docusignStatus]);

  return (
    <>
      <Box>
        <Box mb={2}>
          <Typography variant="h4">Payment Disclosure</Typography>
        </Box>
      </Box>

      <Box mt={3}>
        <Typography variant="body1">
          Please review the Credit Card Payment Authorization Form carefully
          before continuing. Once you have read and agree to the terms, please
          sign and accept it to proceed to the payment for fast tracking your
          loan.
        </Typography>
        <br />
        <br />
        <Typography variant="h6">Card Holder's Information</Typography>
        <br />
        <Grid container direction="row" spacing={3}>
          <Grid item xs={12} md={3} className={classes.borrowerInfoGrid}>
            <TextField
              testId="fullName"
              variant="outlined"
              standaloneLabel
              label="Card Holder's Name"
              placeholder="(as displayed in card)"
              value={form.fields.fullName.value}
              onChange={e =>
                loanFastrackPaymentsStore.loanFastrackDocumentStore.onFieldChange(
                  'fullName',
                  e.target.value
                )
              }
              disabled={docusignStatus}
              fullWidth
              helperText={form.fields.fullName.error}
              error={isNotBlank(form.fields.fullName.error)}
            />
          </Grid>
          <Grid item xs={12} md={3} className={classes.borrowerInfoGrid}>
            <TextField
              testId="email"
              variant="outlined"
              standaloneLabel
              label="Card Holder's Email"
              value={form.fields.email.value}
              onChange={e =>
                loanFastrackPaymentsStore.loanFastrackDocumentStore.onFieldChange(
                  'email',
                  e.target.value
                )
              }
              disabled={docusignStatus}
              fullWidth
              helperText={form.fields.email.error}
              error={isNotBlank(form.fields.email.error)}
            />
          </Grid>
        </Grid>
        <Box mb={1} mt={3}>
          <Grid container>
            <Button
              variant="contained"
              color="primary"
              onClick={handleModalOpen}
              disabled={docusignStatus || !form.meta.isValid}
              size="large"
              testId="acceptTermsAndSign"
            >
              {'Review & Sign'}
            </Button>
            {docusignStatus && (
              <Alert className={classes.successAlert} severity="success">
                Accepted
              </Alert>
            )}
          </Grid>
        </Box>
      </Box>
      <Box mt={16}>
        <Grid container justifyContent="center" spacing={2}>
          <Grid item xs={6} md={3}>
            <Button
              variant="outlined"
              color="primary"
              fullWidth
              onClick={props.handleBackStep}
              testId="back"
            >
              Back
            </Button>
          </Grid>
          <Grid item xs={6} md={3}>
            <Button
              variant="contained"
              color="primary"
              disabled={!docusignStatus}
              fullWidth
              onClick={props.handleNextStep}
              testId="next"
            >
              Next
            </Button>
          </Grid>
        </Grid>
      </Box>

      <LoanFastrackDocusignModal
        onClose={handleModalClose}
        open={modalOpen}
        successPage={docusignStatus || docusingError}
      />
    </>
  );
});

export default LoanFastrackDocusign;
