import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import PaidOffInterestAllocationTable from "./paidOffInterestAllocationTable";
import { Tab, Tabs, Box } from "@material-ui/core";
import { DateField } from "@roc/ui";
import { InterestAllocationInternalStore } from "apps/roc-internal-portal/src/app/modules/InterestAllocation/stores/interestAllocationInternalStore";
import { observer } from "mobx-react";
import { makeStyles } from "@material-ui/core/styles";
import { Card } from "@material-ui/core";
import { formatCurrency } from "@roc/ui/utils";
import { useParams } from "react-router";


const Accordion = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(1, 0, 2, 0),
  },
}))(MuiAccordionDetails);

const useStyles = makeStyles(() => ({
  tabsContainer: {
    display: "flex",
    justifyContent: "flex-start",
    borderBottom: "1px solid #ddd",
    marginBottom: "20px"
  },
  tabRoot: {
    textTransform: "none",
    minWidth: "100px",
  },
  cardStyle: {
    minHeight: "80%",
  },
  typographyStyle: {
    marginLeft: "10px",
  }
}));


interface AccordionData {
  title: string;
  servicerId: string;
  payString: string;
  investor: string;
  tpo: string;
  data: {
    name: string;
    n1: String | undefined;
    n2: String | undefined;
    n3: String | undefined;
    n4: String | undefined;
    n5: String | undefined;
  }[];
}


export type Props = {
  store: InterestAllocationInternalStore;
}

export const PaidOffInterestAllocationAccordion = observer((props: Props) => {
  const [tabIndex, setTabIndex] = useState(0);
  const { date } = useParams<{ date?: string }>();
  const initialPayoffDate = date ? new Date(`${date}T00:00:00`) : new Date();
  const [payoffDate, setPayoffDate] = useState<Date | null>(initialPayoffDate);
  const [loanData, setLoanData] = useState<AccordionData[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { store } = props
  const { defaultInterestAllocation, foreclosureInterestAllocationList } = store;
  const classes = useStyles();

  const handleDateChange = (date: Date | null) => {
    setPayoffDate(date);
  };

  const handleTabChange = (event: React.ChangeEvent<{}>, newIndex: number) => {
    setTabIndex(newIndex);
  };

  useEffect(() => {
    if (payoffDate) {
      console.log("Payoff date param", payoffDate);
      setLoading(true);
      if (tabIndex === 0) {
        store.getPayoffDefaultInterest(payoffDate.toISOString().split("T")[0]);
      } else if (tabIndex === 1) {
        store.getForeclosureDefaultInterest(payoffDate.toISOString().split("T")[0]);
      }
      setLoading(false);
    }
  }, [payoffDate, tabIndex]);


  useEffect(() => {
    if (defaultInterestAllocation) {
      const fetchedData = defaultInterestAllocation.map((item) => {
        return {
          title: `[${item.loanId}] - ${item.dealName}`,
          servicerId: item.servicerId,
          payString: item.paystring,
          investor: item.investorName,
          tpo: item.tpoName,
          data: [
            { name: "Principal", n1: formatCurrency(item.principal), n2: formatCurrency(0), n3: formatCurrency(item.principalFunder), n4: formatCurrency(item.principalTpo), n5: formatCurrency(item.principalInv) },
            { name: "Extensions", n1: formatCurrency(item.extensions), n2: formatCurrency(0), n3: formatCurrency(item.extensions), n4: formatCurrency(0), n5: formatCurrency(0) },
            { name: "Note Rate Interest", n1: formatCurrency(item.interestNoteRate), n2: formatCurrency(item.loanServicerNoteRate), n3: formatCurrency(item.funderNoteRate), n4: formatCurrency(item.tpoNoteRate), n5: formatCurrency(item.investorNoteRate) },
            { name: "Default Rate Interest", n1: formatCurrency(item.interestDefaultRate), n2: formatCurrency(item.loanServicerDefaultRate), n3: formatCurrency(item.funderDefaultRate), n4: formatCurrency(item.tpoDefaultRate), n5: formatCurrency(item.investorDefaultRate) },
            { name: "Total Interest", n1: formatCurrency(item.totalInterest), n2: formatCurrency(item.totalServicerInterest), n3: formatCurrency(item.totalFunderInterest), n4: formatCurrency(item.totalTpoInterest), n5: formatCurrency(item.totalInvestorInterest) },
          ],
        };
      });
      setLoanData(fetchedData);
    }
  }, [defaultInterestAllocation]);

  useEffect(() => {
    if (foreclosureInterestAllocationList) {
      const fetchedData = foreclosureInterestAllocationList.map((item) => {

        return {
          title: `[${item.loanId}] - ${item.dealName}`,
          servicerId: item.servicerId,
          payString: item.paystring,
          investor: item.investorName,
          tpo: item.tpoName,
          data: item.foreclosureInterestAllocation.map(foreclosureItem => ({
            name: foreclosureItem.description,
            n1: formatCurrency(foreclosureItem.amount),
            n2: formatCurrency(foreclosureItem.loanServicer),
            n3: formatCurrency(foreclosureItem.funder),
            n4: formatCurrency(foreclosureItem.tpo),
            n5: formatCurrency(foreclosureItem.investor),
          })),
        };
      });
      setLoanData(fetchedData);
    }
  }, [foreclosureInterestAllocationList]);


  return (
    <Box width={"100%"} paddingLeft={"10%"} paddingRight={"10%"} paddingTop={"35px"}>
      <Typography variant="h4" align="center" style={{ marginBottom: "20px" }}>
        Interest Allocation
      </Typography>
      <Box display="flex" justifyContent="flex-end" width="100%">
        <DateField
          label="Payoff Date"
          value={payoffDate}
          onChange={date => handleDateChange(date)}
          format="MM/dd/yyyy"
          inputVariant="outlined"
          testId=""
          style={{
            marginBottom: "20px",
            width: "200px",
            float: "right",
          }}
        />
      </Box>

      {loading && <Typography align="center">Loading...</Typography>}
      {error && <Typography align="center" color="error">{error}</Typography>}

      {!loading && !error && (
        <>
          <Card className={classes.cardStyle}>
            <Tabs className={classes.tabsContainer} value={tabIndex} onChange={handleTabChange} indicatorColor="primary" >
              <Tab label="Default Allocation" />
              <Tab label="REO Allocation" />
            </Tabs>
            <Box sx={{ padding: 2 }}>
              {loanData.length > 0 ? (
                loanData.map((loan, index) => (
                  <Accordion key={index} square defaultExpanded>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography variant="h6">{loan.title}</Typography>
                    </AccordionSummary>
                    <Typography className={classes.typographyStyle} variant="h6">Servicer Id: {loan.servicerId}</Typography>
                    <Typography className={classes.typographyStyle} variant="h6">Pay String(MBA): {loan.payString}</Typography>
                    <AccordionDetails>
                      <PaidOffInterestAllocationTable loanData={loan} />
                    </AccordionDetails>
                  </Accordion>
                ))
              ) : (
                <Typography align="center">No data available for the selected date.</Typography>
              )}
            </Box>

          </Card>
        </>
      )}
    </Box>
  );
});
