import {
  Box,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import {
  Layout,
  Page,
  useBaseStore,
  useMiscellaneousRoutes,
} from '@roc/feature-app-core';
import {
  ConversationsDialog,
  LoanCommunicationStore,
  useCommunicationRoutesConfig,
  useLoanCommunicationStore,
} from '@roc/feature-communication';
import { CopyText, NotFound, TooltipOnOverflow } from '@roc/ui';
import { observer } from 'mobx-react';
import { PropsWithChildren, useEffect, useMemo } from 'react';
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
} from 'react-router';
import { UnderwritingTab } from './tabs/underwritingTab';
import { LoanDetailsFAB } from './components/LoanDetailsFAB';
import { useLoanStore } from '@roc/feature-loans';
import { LoanApplicationDetailsTabs } from './components/loanApplicationDetailsTabs';
import { LoanApplicationSummaryTab } from './tabs/loanApplicationSummaryTab';
import { useLoanRoutes } from '@roc/feature-loans-routes-config';

const useStyles = makeStyles((theme: Theme) => ({
  loanDetailsContainer: {
    width: 'calc(100vw - 20px)',
    margin: 'auto'
  },
  paper: {
    boxShadow: '0px 4px 10px rgba(0,0,0,0.16)',
    overflow: 'hidden',

    '&.MuiPaper-rounded': {
      borderRadius: theme.spacing(2),
    },
  },
  tabs: {
    border: '0 !important',
    padding: theme.spacing(0, 2),
  },
  dealName: {
    textWrap: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    paddingRight: theme.spacing(2),
    maxWidth: '75vw',
  }
}));

export const LoanApplicationDetailsComponent = observer(
  () => {
    const classes = useStyles();
    const { communicationRoutesConfig } = useCommunicationRoutesConfig();
    const { miscellaneousRoutes } = useMiscellaneousRoutes();
    const { loanStore } = useLoanStore();
    const { globalStore } = useBaseStore();
    const { loanDetails } = loanStore;
    const { loanId } = useParams<{ loanId: string }>();
    const { loanRoutesConfig } = useLoanRoutes();

    const { loanCommunicationStore } = useLoanCommunicationStore();
    const { pathname, state } = useLocation<{ from?: string }>();
    const history = useHistory();

    const routes = useMemo(
      () => [
        {
          routeDefinition: loanRoutesConfig.loanApplications(loanId).children.dashboard,
          component: (
            <Dashboard />
          ),
        },
        {
          routeDefinition: loanRoutesConfig.loanApplications(loanId).children.todos,
          component: <UnderwritingTab />,
        },
      ]
      , [loanId, loanRoutesConfig]);

    function handleOpenConversationsDialog() {
      history.push(communicationRoutesConfig.loanApplicationConversations(loanId).url, { from: pathname });
    }

    function handleCloseConversationsDialog() {
      history.push(state?.from || loanRoutesConfig.loanApplications(loanId).children.dashboard.url);
      loanCommunicationStore.fetchUnreadMessagesCount(loanId);
    }

    function handleConversationSelect(conversationId: string) {
      const path = communicationRoutesConfig.loanApplicationConversations(loanId).children.conversation(conversationId).url;
      try {
        history.replace(path, state);
      } catch (e) {
        history.push(path, { from: loanRoutesConfig.loanApplications(loanId).children.dashboard.url });
      }
    }

    useEffect(() => {
      loanCommunicationStore.fetchUnreadMessagesCount(loanId);
    }, [loanId, loanCommunicationStore]);

    return (
      <Layout maxWidth="lg" containerClass={classes.loanDetailsContainer}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="space-between">
              <Typography variant="h4">
                #<CopyText>{loanId}</CopyText>
              </Typography>
              <TooltipOnOverflow title={loanDetails?.dealName}>
                <Typography variant="h4" className={classes.dealName}>
                  {loanDetails?.dealName}
                </Typography>
              </TooltipOnOverflow>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Paper square className={classes.paper}>
              <LoanApplicationDetailsTabs />
            </Paper>
          </Grid>

          <Switch>
            {routes.map(route => (
              <Route exact path={route.routeDefinition.path}>
                <Page routeDefinition={route.routeDefinition}>
                  {route.component}
                </Page>
              </Route>
            ))}
            <Route path={communicationRoutesConfig.loanApplicationConversations(loanId).path}>
              <Page routeDefinition={communicationRoutesConfig.loanApplicationConversations(loanId)}>
                <Dashboard >
                  <ConversationsDialog
                    open={true}
                    loanId={Number(loanId)}
                    onClose={handleCloseConversationsDialog}
                    communicationStore={loanCommunicationStore}
                    onConversationSelect={handleConversationSelect}
                    routePath={communicationRoutesConfig.loanApplicationConversations(loanId).children.conversation().path}
                  />
                </Dashboard>
              </Page>
            </Route>

            {miscellaneousRoutes.notFound}
            <Route>
              <NotFound />
            </Route>
          </Switch>
        </Grid>
        <LoanDetailsFAB
          loanId={Number(loanId)}
          isBroker={!!globalStore.userFeatures?.isBroker}
          showConversations={true}
          loanCommunicationStore={loanCommunicationStore}
          onOpenConversations={handleOpenConversationsDialog} />
      </Layout>
    );
  }
);

function Dashboard({ children }: PropsWithChildren<{}>) {
  return <>
    <LoanApplicationSummaryTab />
    {children}
  </>;
}