import { Grid, TextField, Typography } from '@material-ui/core';
import { createStyles, darken, makeStyles, Theme } from '@material-ui/core/styles';
import { useStore } from '@roc/client-portal-shared/hooks';
import { isNil } from '@roc/feature-utils';
import {
  Button, ConfirmationButton, createMultiSelectField, createSelectField,
  createTextField, DialogState, MultiSelectField, StandardDialog, Tabs
} from '@roc/ui';
import { observer } from 'mobx-react';
import { useEffect, useMemo, useState } from 'react';
import { HelperText } from 'react-native-paper';
import { ObjectType, objectTypeOptions, recordTypeLeadOptions } from '../../../stores/editLeadsDashboardStore';
import { leadExperienceOptions, leadProfessionOptions, leadSourceOptions, leadStatusOptions, leadSubStatusOptions, leadTypeOptions, sortDirectionOptions, tpoStatusOptions } from '../../leads/constants/constants';
import { QueryBuilder } from './queryBuilder/queryBuilder';
import { stateOptions } from 'libs/client-portal-shared/src/app/modules/bridgePricer/utils/bridgePricerConstants';
import { useUserStore } from '@roc/feature-app-core';

const useStyles = makeStyles((theme: Theme) => ({
  removeButton: {
    backgroundColor: theme.palette.error.dark,
    ['&:hover']: {
      backgroundColor: darken(theme.palette.error.dark, 0.2),
    }
  },
}))

const mapDropdownOptions = (field, baseObject) => {
  const { salesforceLeadStore } = useStore();
  if (field.fieldName === 'leadSource') {
    return {
      ...field,
      optionValueFields: leadSourceOptions.map(o => o.value).join(','),
      optionValueFieldsDisplay: leadSourceOptions.map(o => o.label).join(',')
    }
  }
  if (field.fieldName === 'status' && baseObject == ObjectType.LEAD) {
    return {
      ...field,
      optionValueFields: leadStatusOptions.filter(o => o.label !== "No Fit").map(o => o.value).join(','),
      optionValueFieldsDisplay: leadStatusOptions.filter(o => o.label !== "No Fit").map(o => o.label).join(',')
    };
  }
  if (field.fieldName === 'status' && baseObject == ObjectType.TPO) {
    return {
      ...field,
      optionValueFields: tpoStatusOptions.map(o => o.value).join(','),
      optionValueFieldsDisplay: tpoStatusOptions.map(o => o.label).join(',')
    };
  }
  if (field.fieldName === 'recordType') {
    return {
      ...field,
      optionValueFields: recordTypeLeadOptions.map(o => o.value).join(','),
      optionValueFieldsDisplay: recordTypeLeadOptions.map(o => o.label).join(',')
    }
  }
  if (field.fieldName === 'subStatus') {
    return {
      ...field,
      optionValueFields: leadSubStatusOptions.map(o => o.value).join(','),
      optionValueFieldsDisplay: leadSubStatusOptions.map(o => o.label).join(',')
    }
  }
  if (field.fieldName === 'type') {
    return {
      ...field,
      optionValueFields: leadTypeOptions.map(o => o.value).join(','),
      optionValueFieldsDisplay: leadTypeOptions.map(o => o.label).join(',')
    }
  }
  if (field.fieldName === 'experience') {
    return {
      ...field,
      optionValueFields: leadExperienceOptions.map(o => o.value).join(','),
      optionValueFieldsDisplay: leadExperienceOptions.map(o => o.label).join(',')
    }
  }
  if (field.fieldName === 'profession') {
    return {
      ...field,
      optionValueFields: leadProfessionOptions.map(o => o.value).join(','),
      optionValueFieldsDisplay: leadProfessionOptions.map(o => o.label).join(',')
    }
  }
  if (field.fieldName === 'ownerId') {
    return {
      ...field,
      fieldDisplayName: "Owner Name",
      optionValueFields: salesforceLeadStore.allOwners.map(o => o.value).join(','),
      optionValueFieldsDisplay: salesforceLeadStore.allOwners.map(o => o.label).join(',')
    }
  }
  if (field.fieldName === 'civicOwner') {
    return {
      ...field,
      fieldDisplayName: "Civic Owner",
      optionValueFields: salesforceLeadStore.civicOwners.map(o => o.value).join(','),
      optionValueFieldsDisplay: salesforceLeadStore.civicOwners.map(o => o.label).join(',')
    }
  }
  if (field.fieldName === 'facoOwner') {
    return {
      ...field,
      fieldDisplayName: "Faco Owner",
      optionValueFields: salesforceLeadStore.facoOwners.map(o => o.value).join(','),
      optionValueFieldsDisplay: salesforceLeadStore.facoOwners.map(o => o.label).join(',')
    }
  }
  if (field.fieldName === 'rocOwner') {
    return {
      ...field,
      fieldDisplayName: "Roc Owner",
      optionValueFields: salesforceLeadStore.rocOwners.map(o => o.value).join(','),
      optionValueFieldsDisplay: salesforceLeadStore.rocOwners.map(o => o.label).join(',')
    }
  }

  if (field.fieldName === 'state') {
    return {
      ...field,
      fieldDisplayName: "State",
      optionValueFields: stateOptions.map(o => o.value).join(','),
      optionValueFieldsDisplay: stateOptions.map(o => o.label).join(',')
    }
  }

  if (field.fieldName === 'primaryRmId') {
    return {
      ...field,
      fieldDisplayName: "Primary RM Name",
      optionValueFields: salesforceLeadStore.allOwners.map(o => o.value).join(','),
      optionValueFieldsDisplay: salesforceLeadStore.allOwners.map(o => o.label).join(',')
    }
  }

  if (field.fieldName === 'secondaryRmId') {
    return {
      ...field,
      fieldDisplayName: "Secondary RM Name",
      optionValueFields: salesforceLeadStore.allOwners.map(o => o.value).join(','),
      optionValueFieldsDisplay: salesforceLeadStore.allOwners.map(o => o.label).join(',')
    }
  }

  return field;
}

export const EditLeadsDashboardForm = observer(() => {
  const classes = useStyles();
  const { salesforceLeadStore } = useStore();
  const { editLeadsDashboardStore } = salesforceLeadStore.leadsDashboardStore;
  const { fields, columnOptions } = editLeadsDashboardStore;
  const store = editLeadsDashboardStore;
  const [selectedOwners, setSelectedOwners] = useState([]);
  const { dashboard } = editLeadsDashboardStore;

  const fieldsWithOptions = useMemo(() => {
    return fields.map(field => mapDropdownOptions(field, editLeadsDashboardStore.form.fields.baseObject.value))
  }, [fields]);

  useEffect(() => {
    salesforceLeadStore.leadsDashboardStore.fetchColumns(editLeadsDashboardStore.form.fields.baseObject.value);
    editLeadsDashboardStore.fetchFilterFields();
  }, [editLeadsDashboardStore.form.fields.baseObject.value]);

  useEffect(() => {
    displayShareWithUsers();
  }, [editLeadsDashboardStore.sharedWithOwners]);

  const allOption = { label: "All", value: 'all' };
  const allOwners = [allOption].concat(
    salesforceLeadStore.rocOwners,
    salesforceLeadStore.facoOwners,
    salesforceLeadStore.civicOwners
  );

  const displayShareWithUsers = () => {
    try {
      const matchingOwners = allOwners.filter(owner => editLeadsDashboardStore.sharedWithOwners.includes(owner.value));
      store.onFieldChange('shareWith', matchingOwners);
    } catch (error) {
      console.error("Failed to get users with dashboard:", error);
    }
  }

  const handleOwnersChange = (newOwners, store) => {
    const allOption = { label: "All", value: 'all' };
    if (newOwners.some(owner => owner.value === allOption.value)) {
      newOwners = [allOption];
    }
    store.onFieldChange('shareWith', newOwners);
  };


  return (
    <Grid item container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6">View Details</Typography>
      </Grid>
      <Grid item xs={12}>
        {createTextField({
          store,
          label: 'View Name',
          fieldName: 'name',
          testId: 'name',
        })}
      </Grid>
      <Grid item xs={6}>
        {createSelectField({
          store,
          label: 'Object Type',
          fieldName: 'baseObject',
          testId: 'baseObject',
          options: objectTypeOptions,
        })}
      </Grid>
      <Grid item xs={12}>
        {createMultiSelectField({
          store,
          label: 'Columns',
          fieldName: 'columns',
          testId: 'columns',
          options: columnOptions,
          helperText: 'The columns "First Name" and "Last Name" are always visible'
        })}
      </Grid>
      <Grid item xs={12} md={6}>
        {createSelectField({
          store,
          label: 'Sort By',
          fieldName: 'sortBy',
          testId: 'sortBy',
          options: columnOptions
        })}
      </Grid>
      <Grid item xs={12} md={6}>
        {createSelectField({
          store,
          label: 'Sort Direction',
          fieldName: 'sortDir',
          testId: 'sortDir',
          options: sortDirectionOptions
        })}
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6">Filter Criteria</Typography>
      </Grid>
      <Grid item xs={12}>
        <Tabs
          tabs={[{ label: 'Simple' }, { label: 'Advanced' }]}
          selectedTab={editLeadsDashboardStore.advanced ? 1 : 0}
          onTabChange={(e, index) =>
            editLeadsDashboardStore.setAdvanced(index == 1)
          }
        />
        <QueryBuilder
          advanced={editLeadsDashboardStore.advanced}
          fields={fieldsWithOptions}
          query={editLeadsDashboardStore.query}
          onQueryChange={query => editLeadsDashboardStore.setQuery(query)}
        />
      </Grid>
    </Grid>
  );
});


export const EditLeadDashboardModal = observer(({ onClose }) => {
  const classes = useStyles();
  const { salesforceLeadStore } = useStore();
  const { leadsDashboardStore } = salesforceLeadStore;
  const { editLeadsDashboardStore } = leadsDashboardStore;
  const { dialogState, dashboard } = editLeadsDashboardStore;
  const {
    userStore
  } = useUserStore();

  const handleClose = () => {
    editLeadsDashboardStore.closeDialog();
    onClose();
  };

  const handleSave = async () => {
    editLeadsDashboardStore.saveDashboard(userStore.userInformation?.username, salesforceLeadStore.allOwners);
  };

  const handleRemove = () => {
    editLeadsDashboardStore.deleteDashboard(dashboard);
  };

  return (
    <StandardDialog
      open={!isNil(dialogState)}
      title={DialogState.ADD == dialogState ? 'Create View' : 'Edit View'}
      maxWidth="lg"
      handleClose={handleClose}
      dialogContent={<EditLeadsDashboardForm />}
      dialogActions={
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item xs={2}>
            <Button
              onClick={handleClose}
              color="primary"
              variant="outlined"
              testId="cancel"
              fullWidth
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs={2}>
            <ConfirmationButton
              className={classes.removeButton}
              onClick={handleRemove}
              variant="contained"
              color="primary"
              testId="cancel"
              fullWidth
              confirmDialogProps={{
                body: 'Are you sure you want to delete this dashboard?'
              }}
            >
              Delete View
            </ConfirmationButton>
          </Grid>
          <Grid item xs={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSave}
              testId="next"
              fullWidth
            >
              Save View
            </Button>
          </Grid>
        </Grid>
      }
    />
  );
});
