import {
  Box,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Close, Search } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import { debounce } from '@roc/feature-utils';
import { observer } from 'mobx-react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { useUserStore } from '../../hooks';
import { useSearchBarStore } from '../../hooks/useSearchBarStore';
import { SearchResult } from '../../stores';

const useStyles = makeStyles(theme =>
  createStyles({
    searchBar: {
      width: 300,
      marginLeft: 16,
      border: 0,
      '& input, & svg path': {
        color: theme.palette.text.primary
      },
      '& fieldset': {
        boxShadow: theme.shadows[0],
        borderColor: '#EFEFEF',
      },
    },
    searchButton: {
      borderRadius: 99,
      padding: theme.spacing(1),
    },
    closeSearchButton: {
      padding: theme.spacing(0.5),
    },

    //Search option
    loanId: {
      fontWeight: 'bold',
    },
    lenderName: {
      color: '#5f6368',
      fontSize: '12px'
    },
    dealName: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }
  })
);

export const SearchIcon = ({ onClick }) => {
  const classes = useStyles();
  return (
    <Tooltip title="Search" placement="bottom">
      <Box display="flex" alignItems="center">
        <IconButton
          aria-label="close"
          className={classes.searchButton}
          onClick={onClick}
          style={{ marginLeft: '16px', padding: '0px' }}
        >
          <Search />
        </IconButton>
      </Box>
    </Tooltip>
  );
};

export const SearchBar = observer(({ onClose, loanRoutes }) => {
  const classes = useStyles();
  const { searchBarStore } = useSearchBarStore();
  const { push } = useHistory();

  useEffect(() => {
    searchBarStore.reset();
  }, []);

  const search = useMemo(
    () =>
      debounce(value => {
        if (value.length >= 3) {
          searchBarStore.searchLoans(value);
        } else {
          searchBarStore.reset();
        }
      }, 500),
    []
  );

  const onSelect = async (selectedOption: SearchResult) => {
    if (selectedOption?.loanId) {
      const loanDashboardUrl = loanRoutes(selectedOption.loanId).url;
      push(loanDashboardUrl);
    }
  };

  return (
    <Autocomplete
      className={classes.searchBar}
      size="small"
      id="menu-appbar"
      options={searchBarStore.searchResults}
      componentName="menu-appbar"
      renderInput={options => (
        <Paper elevation={1}>
          <TextField
            {...options}
            autoFocus
            InputProps={{
              ...options.InputProps,
              className: undefined,
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    className={classes.closeSearchButton}
                    size="small"
                    aria-label="close search"
                    onClick={onClose}
                  >
                    <Close />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            placeholder={'Search Loans'}
            variant={'outlined'}
          />
        </Paper>
      )}
      renderOption={option => <SearchOption option={option} />}
      blurOnSelect={true}
      onBlur={() => onClose()}
      getOptionLabel={option => option.loanId.toString()}
      loading={searchBarStore.loading}
      filterOptions={(options) => options}
      autoSelect={false}
      onChange={(e, value) => {
        onSelect(value as SearchResult);
        onClose();
      }}
      onInputChange={(e, value) => search(value)}
    />
  );
});

const SearchOption = ({ option }) => {
  const classes = useStyles();
  return (
    <Box overflow={'hidden'} width={'100%'} p={1}>
      <Box flex={1}>
        <Typography className={classes.dealName}><b>{option.loanId}</b> - {option.dealName}</Typography>
      </Box>
      <Typography className={classes.lenderName}>{option.lender}</Typography>
    </Box>
  );
};
