export interface DecodedData {
  entityId: string;
  tamariskFlowType: string;
}

export const decodeData = (data: string): DecodedData => {
  const decoded = window.atob(data.replace('-', '/')).split(';');

  return {
    entityId: decoded[0],
    tamariskFlowType: decoded[1],
  };
};
