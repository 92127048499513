import { Grid, Popper } from '@material-ui/core';
import { observer } from 'mobx-react';
import {
  createNumberFormatField,
  createTextField,
  createDateField,
  createStreetAddressField,
  createAutocompleteAddressField,
  createSelectField,
  createAutocompleteField,
} from '@roc/ui';
import { borrowerEntityTypeOptions } from '@roc/feature-utils';
import { format, isValid } from 'date-fns';
import { useBaseStore } from '@roc/feature-app-core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  autocompletePopper: {
    '& .MuiAutocomplete-noOptions': {
      display: 'none',
    },
  },
}));

export const EntityForm = observer(
  ({ store, borrowerEntityInformationStore }) => {
    const classes = useStyles();
    const { globalStore } = useBaseStore();
    const handleAddressChange = event => {
      const { value, fullInfo } = event?.target || {};
      const { address_components, geometry } = fullInfo || {};
      store.saveAddressField(value, address_components, geometry);
    };

    const manageDate = (fieldName, date) => {
      let formattedDate = null;
      if (isValid(date)) {
        formattedDate = format(date, 'MM/dd/yyyy');
      }
      store.onFieldChange(fieldName, formattedDate);
    };

    const showEntitySelection = globalStore.isBorrowerPortal;

    return (
      <Grid container direction="row" spacing={2}>
        <Grid item xs={12}>
          {showEntitySelection ? (
            <>
              {createAutocompleteField({
                store,
                fieldName: 'borrowerEntityOption',
                inputFieldName: 'name',
                label: 'Entity Name',
                options: borrowerEntityInformationStore.borrowerEntityOptions,
                clearOnBlur: false,
                PopperComponent: props => (
                  <Popper {...props} className={classes.autocompletePopper} />
                ),
                onChange: option =>
                  borrowerEntityInformationStore.setSelectedBorrowerEntity(
                    option?.value
                  ),
              })}
            </>
          ) : (
            <>
              {createTextField({
                store,
                testId: 'name',
                fieldName: 'name',
                label: 'Entity Name',
              })}
            </>
          )}
        </Grid>
        <Grid item xs={12}>
          {createSelectField({
            testId: 'type',
            label: 'Entity Type',
            fieldName: 'type',
            store,
            options: borrowerEntityTypeOptions,
          })}
        </Grid>
        <Grid item xs={12}>
          {createNumberFormatField({
            store,
            testId: 'ein',
            fieldName: 'ein',
            label: 'Entity EIN',
            format: '##-#######'
          })}
        </Grid>
        <Grid item xs={12}>
          {createDateField({
            label: 'Date of Incorporation',
            fieldName: 'operatingAgreementDate',
            testId: 'operatingAgreementDate',
            format: 'MM/dd/yyyy',
            store: store,
            disableFuture: true,
            onChange: manageDate,
          })}
        </Grid>
        <Grid item xs={12}>
          {createAutocompleteAddressField({
            fieldName: 'address',
            label: 'Entity Addres',
            testId: 'address',
            store: store,
            onChange: handleAddressChange,
          })}
        </Grid>
      </Grid>
    );
  }
);
