import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Box, Grid, lighten, makeStyles, Theme, Typography, useTheme } from '@material-ui/core';
import { LoginLogoImg, Page } from '@roc/feature-app-core';
import { Paper, PaperBorderType, PreviewBackdrop } from '@roc/ui';
import clsx from 'clsx';
import jsPDF from 'jspdf';
import { useEffect, useRef, useState } from 'react';
import { useDocumentLibraryRoutes } from './../../hooks/useDocumentLibraryRoutes';

const useStyles = makeStyles((theme: Theme) => ({
  documentContainer: {
    paddingLeft: '32px',
    paddingRight: '32px',
    position: 'relative',
    background: '#FFF',
    fontSize: 16,
    overFlowX: 'auto',
    '& table': {
      minHeight: '40px',
      width: '100%',
      textAlign: 'left',
      borderCollapse: "collapse",
    },
    '& table th': {
      textAlign: 'center',
      fontWeight: 'bold',
      background: lighten(theme.palette.primary.main, 0.5),
      padding: '4px',
      border: '0.25px solid black',
    },
    '& table td': {
      borderBottom: '0.25px solid black',
      borderRight: '0.25px solid black',
      borderLeft: '0.25px solid black',
    },
    '& table td p': {
      marginLeft: '5px'
    },
    '& table td:nth-child(1)': {
      border: '0.25px solid black',
      textAlign: 'center',
      width: '150px',
      background: lighten(theme.palette.primary.main, 0.8)
    },
    '& ul,ol': {
      marginLeft: theme.spacing(3),
      marginBottom: theme.spacing(0.5),
    },
    '& h6': {
      fontSize: 16,
      padding: 4,
      fontWeight: 'bold',
    },
    '& hr': {
      marginBottom: '10px'
    },
    '@media (max-width: 800px)': {
      fontSize: 8,
      padding: 0,
    },
  },
  documentCard: {
    borderRadius: 0,
    marginBottom: theme.spacing(1),
    borderColor: '#aaa',
  },
  documentCardTitle: {
    justifyContent: 'center',
    textAlign: 'center',
    background: lighten(theme.palette.primary.main, 0.5),
    fontWeight: 'bold',
    padding: theme.spacing(0.5),
  },
}));

const usePrintModeStyles = makeStyles((theme: Theme) => ({
  documentContainer: {
    fontSize: 12,
    padding: 0,
    '& table th': {
      fontSize: 12
    },
    '& table td': {
      fontSize: 12
    },
    '& ul,ol': {
      marginLeft: theme.spacing(2),
    },
    '& h6': {
      fontSize: 12,
    },
  },
}));



const DocumentCard = ({
  title = undefined,
  data,
  paperBorder = PaperBorderType.SOLID_BORDER,
  paperClassName = '',
  paperTitleClassName = '',
  paperBodyClassName = '',
  paperStyle = {},
}) => {
  const classes = useStyles();
  return (
    <Paper
      title={title}
      titleClass={clsx(classes.documentCardTitle, paperTitleClassName)}
      titleVariant={'h6'}
      borderType={paperBorder}
      className={clsx(classes.documentCard, paperClassName)}
      style={paperStyle}>
      <Box className={paperBodyClassName}>
        {documentToReactComponents(data)}
      </Box>
    </Paper>
  )
}

export const AppraisalTransferPolicyHtml = ({
  contentId,
  data,
  preview = false,
  printMode = false,
  setContainerRef,
}: {
  contentId: string,
  data: any,
  preview?: boolean,
  printMode?: boolean,
  setContainerRef: any,
}) => {
  const theme = useTheme();
  const { documentLibraryRoutesConfig } = useDocumentLibraryRoutes();
  const classes = useStyles();
  const containerRef = useRef(null);

  const [printModeContainerStyle, setPrintModeContainerStyle] = useState<any>();
  const [containerStyle, setContainerStyle] = useState<any>();
  const printModeClasses = usePrintModeStyles();

  useEffect(() => {

    const doc = new jsPDF("p", "mm", "a4");
    let width: any = doc.internal.pageSize.getWidth();
    let height: any = doc.internal.pageSize.getHeight();
    width = `${Math.floor(width)}mm`;
    height = `${Math.floor(height)}mm`;
    setPrintModeContainerStyle({
      margin: 'auto',
      minWidth: width,
      maxWidth: width,
      height: height
    });
    setContainerStyle({
      margin: 'auto',
      maxWidth: theme.breakpoints.values.lg,
    });
  }, []);

  useEffect(() => {
    setContainerRef(containerRef);
  }, [containerRef])

  if (!data) {
    return null;
  }

  return (
    <div ref={containerRef} style={printMode ? printModeContainerStyle : containerStyle}>
      <Page routeDefinition={documentLibraryRoutesConfig.documentLibraryDocument(contentId)}>
        <Box padding={'16px'} className={clsx(classes.documentContainer, printMode ? printModeClasses.documentContainer : undefined)}>
          {preview && <PreviewBackdrop />}
          <LoginLogoImg imgStyle={{ maxWidth: 200 }} />
          <Box my={2} textAlign='center'>

            <Typography style={{ marginTop: '10px' }} variant={'h5'}>
              <strong>{data.documentTitle}</strong>
            </Typography>
          </Box>

          <Box mb={2} paddingLeft={'5%'} paddingRight={'5%'} paddingBottom="5%" overflow={'auto'} >
            <Grid container >
              <Grid item xs={12}>

                <DocumentCard
                  data={data.transferCriteriaText}
                  paperBorder={PaperBorderType.NO_BORDER}
                />

                <DocumentCard
                  title={data.transferProcessTitle}
                  data={data.transferProcessText}
                  paperBorder={PaperBorderType.NO_BORDER}
                />

                <DocumentCard
                  title={data.noteTitle}
                  data={data.noteText}
                  paperBorder={PaperBorderType.NO_BORDER}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Page>
    </div>
  );
}
