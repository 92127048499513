import { GridStore } from "@roc/feature-app-core";
import { ApiResponse } from "@roc/feature-app-core";
import { LoanService } from "libs/feature-asset-management-loans/src/services/loanService";
import { GlobalStore } from "@roc/feature-app-core";
import { makeObservable, action, flow, observable } from "mobx";
import { InvestorEntityStore } from './investorEntityStore';
import { downloadDocument } from '@roc/feature-utils';

export class FundingAmountStore {
  public gridStore: GridStore;
  private loanService: LoanService;
  private globalStore: GlobalStore;
  public investorEntityStore: InvestorEntityStore;
  public asOfDate: Date = null;

  constructor(globalStore: GlobalStore) {
    this.globalStore = globalStore;
    this.gridStore = new GridStore(() => this.fetchGridRecords(), null, 50);
    this.investorEntityStore = new InvestorEntityStore(globalStore);
    this.loanService = new LoanService();

    makeObservable(this, {
      reset: action,
      exportFundingAmountFile: flow,
      importFundingAmountFile: flow,
      asOfDate: observable,
      setAsOfDate: action,
      resetAndFetchGridData: flow,
      gridStore: observable
    });
  }

  formatDate(date: Date) {
    if (!date) return null;
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  private fetchGridRecords = async () => {
    if (!this.investorEntityStore.selectedEntity) return;
    const gridFilters = this.gridStore.gridData.meta.filters ?? {};
    const filters = { ...gridFilters };
    try {
      const response: ApiResponse = await this.loanService.getLoanFundingAmountList(
        this.investorEntityStore.selectedEntity,
        this.formatDate(this.asOfDate),
        filters,
        this.gridStore.gridData.meta.pageNumber,
        this.gridStore.gridData.meta.pageSize,
        this.gridStore.gridData.meta.sortBy,
        this.gridStore.gridData.meta.sortDir,
      );
      return response;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching the historic loan funding amount list.',
      });
    }
  }

  *exportFundingAmountFile() {
    try {
      if (!this.investorEntityStore.selectedEntity) return;
      const response = yield this.loanService.exportFundingAmountFile(this.investorEntityStore.selectedEntity, this.formatDate(this.asOfDate));
      downloadDocument(
        response?.data,
        response?.headers,
        'download',
      );
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'File exported successfully.'
      });
    } catch (err) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while exporting file'
      });
    }
  };

  *importFundingAmountFile(data: any) {
    try {
      const formData = new FormData();
      formData.append('file', data);
      yield this.loanService.importFundingAmountFile(formData);
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'File imported successfully.'
      });
      this.setAsOfDate(null);
    } catch (err) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while importing file'
      });
    }
  };

  setAsOfDate(value: Date) {
    this.asOfDate = value;
  };

  reset() {
    this.asOfDate = null;
    this.investorEntityStore.reset();
  };

  *resetAndFetchGridData() {
    this.gridStore = yield new GridStore(() => this.fetchGridRecords(), null, 50);
    yield this.gridStore.reset();
  };

}