import Geocode from 'react-geocode';
const GOOGLE_PLACES_API_KEY = 'AIzaSyBXcirzwwKlWxg16cm4oW6yw-wtQwcdJRo';

export const geocode = async (address: string) => {
  Geocode.setApiKey(GOOGLE_PLACES_API_KEY);

  const response = await Geocode.fromAddress(address);
  const { lat, lng } = response.results[0].geometry.location;
  return { lat, lng };
};
