import { observer } from 'mobx-react';
import { StatsGrid } from './statsGrid';
import { useCRMPortalStore } from '../../hooks';
import { Grid } from '@material-ui/core';


export const BackOfficeHomePage = observer(() => {
  const {ringCentralCallStatsStore, ringCentralTextStatsStore, sendgridEmailStatsStore, phoneBurnerCallStatsStore} = useCRMPortalStore();
  return (
    <Grid container>
      <StatsGrid store={ringCentralCallStatsStore} title='RingCentral Outbound Calls'/>
      <StatsGrid store={ringCentralTextStatsStore} title='Text Messages'/>
      <StatsGrid store={phoneBurnerCallStatsStore} title='PhoneBurner Calls'/>
      <StatsGrid store={sendgridEmailStatsStore} title='Sendgrid Emails'/>
    </Grid>
  );
});
