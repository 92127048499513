import { useStore } from '@roc/client-portal-shared/hooks';
import {
  getDashboardRoutes,
  getDashboardRoutesConfig,
} from '../modules/dashboard/routes/dashboardRoutes';
import { useAppsRoutes } from '@roc/feature-apps-contentful';
import { useQuoteGeneratorRoutes } from '@roc/feature-quote-generator'
import { getBackOfficeDashboardRoutes, getBackOfficeDashboardRoutesConfig } from 'apps/lender-portal/src/app/modules/backOfficeDashboard/routes/backOfficeRoutes';
import {
  getBridgeProfitAndDscrCalculatorRoutes,
  getBridgeProfitAndDscrCalculatorRoutesConfig,
} from '@roc/feature-bridge-profit-and-dscr-calculator';
import {
  getProspectPropertiesRoutes,
  getProspectPropertiesRoutesConfig,
} from '@roc/feature-prospect-properties';
import { getLoanRoutesConfig } from '@roc/feature-loans-routes-config';
import { getWhatsNewRoutes, getWhatsNewRoutesConfig } from '@roc/feature-whats-new';
import { getLenderProfileRoutes, getLenderProfileRoutesConfig } from 'apps/lender-portal/src/app/modules/lenderProfile/routes/lenderProfileRoutes';
import { getLoanRoutes } from 'apps/lender-portal/src/app/routes/loanRoutes';

import { getCorporateEmailsRoutes, getCorporateEmailsRoutesConfig, getEmailSettings, getEmailSettingsRoutesConfig, getEmailsRoutes, getEmailsRoutesConfig, getMarketingSetupRoutes, getMarketingSetupRoutesConfig } from '@roc/feature-sendgrid';
import { getCreateListRoutes, getCreateListRoutesConfig } from '../modules/createList/routes/createListRoutes';
import { getLeadActivityMetricsRoutes, getLeadActivityMetricsRoutesConfig, getLoanVolumeRoutes, getLoanVolumeRoutesConfig } from '../modules/dashboard/routes/metricsRoutes';

export const useRoutes = () => {
  const { globalStore } = useStore();
  const { routeBasePath, routeBaseUrl } = globalStore;

  const {
    appsRoutes: appsRoutes,
    appsRoutesConfig: appsRoutesConfig
  } = useAppsRoutes();

  const {
    quoteGeneratorRoutes,
    quoteGeneratorRoutesConfig
  } = useQuoteGeneratorRoutes();

  return {
    dashboardRoutes: getDashboardRoutes(routeBasePath, routeBaseUrl),
    dashboardRoutesConfig: getDashboardRoutesConfig(
      routeBasePath,
      routeBaseUrl
    ),
    leadActivityMetricsRoutes: getLeadActivityMetricsRoutes(routeBasePath, routeBaseUrl),
    leadActivityMetricsRoutesConfig: getLeadActivityMetricsRoutesConfig(
      routeBasePath,
      routeBaseUrl
    ),
    loanVolumeRoutes: getLoanVolumeRoutes(routeBasePath, routeBaseUrl),
    loanVolumeRoutesConfig: getLoanVolumeRoutesConfig(
      routeBasePath,
      routeBaseUrl
    ),
    emailsRoutes: getEmailsRoutes(routeBasePath, routeBaseUrl),
    emailRoutesConfig: getEmailsRoutesConfig(routeBasePath, routeBaseUrl),
    marketingSetupRoutes: getMarketingSetupRoutes(routeBasePath, routeBaseUrl),
    marketingSetupRoutesConfig: getMarketingSetupRoutesConfig(routeBasePath, routeBaseUrl),
    appsRoutes,
    appsRoutesConfig,
    quoteGeneratorRoutes,
    quoteGeneratorRoutesConfig,
    loanRoutes: getLoanRoutes(routeBasePath, routeBaseUrl),
    loanRoutesConfig: getLoanRoutesConfig(routeBasePath, routeBaseUrl),
    whatsNewRoutes: getWhatsNewRoutes(routeBasePath, routeBaseUrl),
    whatsNewRoutesConfig: getWhatsNewRoutesConfig(routeBasePath, routeBaseUrl),
    lenderProfileRoutes: getLenderProfileRoutes(routeBasePath, routeBaseUrl),
    lenderProfileRoutesConfig: getLenderProfileRoutesConfig(routeBasePath, routeBaseUrl),
    prospectPropertiesRoutes: getProspectPropertiesRoutes(
      routeBasePath,
      routeBaseUrl
    ),
    prospectPropertiesRoutesConfig: getProspectPropertiesRoutesConfig(
      routeBasePath,
      routeBaseUrl
    ),
    bridgeProfitAndDscrCalculatorRoutes: getBridgeProfitAndDscrCalculatorRoutes(
      routeBasePath,
      routeBaseUrl
    ),
    bridgeProfitAndDscrCalculatorRoutesConfig: getBridgeProfitAndDscrCalculatorRoutesConfig(
      routeBasePath,
      routeBaseUrl
    ),
    backOfficeRoutes: getBackOfficeDashboardRoutes(routeBasePath, routeBaseUrl),
    backOfficeRoutesConfig: getBackOfficeDashboardRoutesConfig(routeBasePath, routeBaseUrl),
    createListRoutes: getCreateListRoutes(routeBasePath, routeBaseUrl),
    createListRoutesConfig: getCreateListRoutesConfig(routeBasePath, routeBaseUrl),
    emailSettingsRoutes: getEmailSettings(routeBasePath, routeBaseUrl),
    emailSettingsRoutesConfig: getEmailSettingsRoutesConfig(routeBasePath, routeBaseUrl),
    corporateEmailsRoutes: getCorporateEmailsRoutes(routeBasePath, routeBaseUrl),
    corporateEmailsRoutesConfig: getCorporateEmailsRoutesConfig(routeBasePath, routeBaseUrl),

  }
};