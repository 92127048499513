import { makeObservable } from "mobx";
import { FormStore } from "@roc/feature-app-core";
import { GlobalStore } from "@roc/feature-app-core";

const form = {
  fields: {
    message: {
      value: '',
      error: null,
      rule: '',
      message: '',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};
export class SendBulkSMSFormStore extends FormStore {
  constructor(globalStore: GlobalStore) {
    super(form, globalStore);
    makeObservable(this, {
    });
  }
  onFieldChange(field, value) {
    if (field === 'objectType') {
      this.form.fields.recordType.value = null;
    }
    super.onFieldChange(field, value);
  }
}
