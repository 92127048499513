import { ApiResponse, GlobalStore, GridStore } from '@roc/feature-app-core';
import { BorrowerLoansService } from '../services/borrowerLoansService';
import { GENERIC_ERROR_MESSAGE } from '@roc/feature-utils';

const PAGE_SIZE = 10;
export class BorrowerLoansGridStore extends GridStore {
  globalStore: GlobalStore;
  borrowerLoansService: BorrowerLoansService;

  constructor(globalStore: GlobalStore) {
    super(() => this.fetchBorrowerLoans(), undefined, PAGE_SIZE);
    this.globalStore = globalStore;
    this.borrowerLoansService = new BorrowerLoansService();
  }

  fetchBorrowerLoans = async () => {
    try {
      const response = await this.borrowerLoansService.getBorrowerLoansGrid(
        this.gridData.meta.pageNumber,
        this.gridData.meta.pageSize,
        this.gridData.meta.sortDir,
        this.gridData.meta.sortBy,
        this.gridData.meta.filters,
        this.gridData.meta.dropdownFilters
      );

      response.data?.data.rows.forEach(element => {
        element._amount = element.amount;
        if (element.status !== "PAID_OFF" && element.status !== "FUNDED") {
          element.amount = '-';
        }
      });

      return response;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE
      });
    }
  };
}
