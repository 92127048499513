import { Info, Share } from '@material-ui/icons';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import GetApp from '@material-ui/icons/GetApp';
import { observer } from 'mobx-react-lite';
import { useRef, useState } from 'react';
import { ScopeOfWorkV2FormBaseStore } from '@roc/feature-sow-shared/stores/v2';
import CopyUrlModal from './scopeOfWorkV2Form/components/copyUrlModal/copyUrlModal';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import { Theme } from '@material-ui/core/styles';
import ResetSowModal from './scopeOfWorkV2Form/components/resetModal/resetModal';
import { VerticalBarLoader, multiPageScreenshotGrid } from '@roc/ui';
import GuidelinesModal from './scopeOfWorkV2Form/components/guidelinesModal/guidelinesModal';
import VersionsModal from './scopeOfWorkV2Form/components/versionsModal/versionsModal';
import { Button, Chip, IconButton, Tooltip } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    downloadContainer: {
      display: 'flex',
      flexDirection: 'row',
      cursor: 'pointer',
      marginLeft: '17px',
      alignItems: 'center',
    },
    versionsContainer: {
      display: 'flex',
      flexDirection: 'row',
      marginLeft: '17px',
    },
    shareContainer: {
      display: 'flex',
      flexDirection: 'row',
      cursor: 'pointer',
    },
    buttonLabel: {
      fontSize: '14px',
      paddingLeft: '5px',
    },
    icon: {
      fontSize: '24px',
    },
    button: {
      fontSize: '10px',
      marginLeft: '5px'
    }
  })
);

interface Props {
  store: ScopeOfWorkV2FormBaseStore;
  screenshotContainerRef: any;
}

export const ShareDownloadButtons = observer((props: Props) => {
  const classes = useStyles();
  const { store } = props;
  const [isCopyUrlModalOpen, setIsCopyUrlModalOpen] = useState(false);
  const [isResetModalOpen, setResetModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isGuidelinesModalOpen, setIsGuidelinesModalOpen] = useState(false);
  const [isVersionsModalOpen, setVersionsModalOpen] = useState(false);

  const handleShareClick = () => {
    setIsCopyUrlModalOpen(true);
  };

  const handleResetClick = () => {
    setResetModalOpen(true)
  };

  const handleCopyUrlModalClose = () => setIsCopyUrlModalOpen(false);

  const handleResetSowModalClose = () => setResetModalOpen(false);

  const handleCopyUrl = () => {
    store.handleCopyUrl();
    handleCopyUrlModalClose();
  };

  const resetScopeOfWork = () => {
    store.resetScopeOfWork()
  }

  const handleDownload = async () => {
    setIsLoading(true);
    try {
      store.showScreenshotGrid()
      await multiPageScreenshotGrid('Scope of Work', store.scopeOfWorkScreenshotIds, []);
      store.hideScreenshotGrid()
    } catch (error) {
      console.error('Error generating PDF:', error);
    } finally {
      setIsLoading(false);
    }
  };


  const handleExcelExport = () => {
    store.exportScopeOfWorkData();
  };

  const handleGuidelinesClick = () => {
    setIsGuidelinesModalOpen(true)
  };

  const handleVersionsClick = () => {
    setVersionsModalOpen(true);
  }

  const handleGuidelinesModalClose = () => setIsGuidelinesModalOpen(false);

  const handleVersionsModalClose = () => setVersionsModalOpen(false);

  return (
    <>
      {store.showInternalActions &&
        <>
          <Chip
            label={`Version # ${store?.scopeOfWork?.currentVersion}`}
            variant="outlined" />
          <div className={classes.downloadContainer} onClick={handleVersionsClick}>
            <FormatListNumberedIcon className={classes.icon} />
            <p className={classes.buttonLabel}>SHOW AUDIT LOGS</p>
          </div>
        </>
      }
      <div className={classes.downloadContainer} onClick={handleGuidelinesClick}>
        <Info className={classes.icon} />
        <p className={classes.buttonLabel}>GUIDELINES</p>
      </div>
      {
        store.showResetSow && (
          <div className={classes.downloadContainer} onClick={handleResetClick}>
            <RotateLeftIcon className={classes.icon} />
            <p className={classes.buttonLabel}>RESET</p>
          </div>
        )
      }
      {
        store.showShare && (
          <div className={classes.downloadContainer} onClick={handleShareClick}>
            <Share className={classes.icon} />
            <p className={classes.buttonLabel}>SHARE</p>
          </div>
        )
      }
      {
        store?.activeStep === 3 && (
          <div className={classes.downloadContainer} onClick={handleDownload}>
            <GetApp className={classes.icon} />
            <p className={classes.buttonLabel}>DOWNLOAD AS PDF</p>
          </div>
        )
      }
      {
        store?.activeStep === 3 && (
          <div className={classes.downloadContainer} onClick={handleExcelExport}>
            <GetApp className={classes.icon} />
            <p className={classes.buttonLabel}>DOWNLOAD AS EXCEL</p>
          </div>
        )
      }
      <CopyUrlModal
        handleClose={handleCopyUrlModalClose}
        handleCopy={handleCopyUrl}
        isOpen={isCopyUrlModalOpen}
        url={store.scopeOfWorkShareUrl}
      />
      <ResetSowModal
        handleClose={handleResetSowModalClose}
        isOpen={isResetModalOpen}
        onConfirm={resetScopeOfWork}
      />
      <GuidelinesModal
        handleClose={handleGuidelinesModalClose}
        isOpen={isGuidelinesModalOpen}
      />
      <VersionsModal
        handleClose={handleVersionsModalClose}
        isOpen={isVersionsModalOpen}
        store={store}
      />
      {isLoading && <VerticalBarLoader />}
    </>
  );
});
