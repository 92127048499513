import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';

import { useParams } from 'react-router';
import { ScopeOfWorkV2Form, decodeParams } from '@roc/feature-sow-v2';
import { ScopeOfWorkV2FormParams } from '@roc/feature-sow-shared/types';
import { useRocInternalPortalStore } from '../../../hooks';

export type EncodedParams = {
  encodedParams: string;
};

export const ScopeOfWorkInternal = observer(() => {
  const { scopeOfWorkInternalStore } = useRocInternalPortalStore();

  const { encodedParams } = useParams<EncodedParams>();
  const [queryParams] = useState<ScopeOfWorkV2FormParams>(
    decodeParams(encodedParams)
  );

  useEffect(() => {
    if (queryParams) {
      scopeOfWorkInternalStore.reset();
      scopeOfWorkInternalStore.setParams(queryParams);
    }
  }, [scopeOfWorkInternalStore, queryParams]);

  useEffect(() => {
    scrollTo(0, 0);
  }, [scopeOfWorkInternalStore.activeStep]);

  return <ScopeOfWorkV2Form store={scopeOfWorkInternalStore} />;
});
