import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import React from 'react';
import preview_background from './../../assets/preview_background.jpg';

const useStyles = makeStyles((theme: Theme) => ({
  previewBackdrop: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    zIndex: -1,
    background: `url(${preview_background})`,
    backgroundRepeat: 'repeat',
    top: 0,
    left: 0,
  },
}));

export const PreviewBackdrop = () => {
  const classes = useStyles();
  return (
    <div className={classes.previewBackdrop} />
  )
}