import { Box, Button, Paper, Table, TableBody, TableCell, TableHead, TableRow, Theme, Typography, makeStyles, Modal, Backdrop } from '@material-ui/core';
import { alpha } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import BackgroundReviewFormBaseStore from '../stores/backgroundReviewFormBaseStore';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import react, { useEffect, useState } from 'react';
import { Layout } from '@roc/feature-app-core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import FlagIcon from '@material-ui/icons/Flag';
import FlagOutlinedIcon from '@material-ui/icons/FlagOutlined';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { BackgroundItemStore } from '../stores/backgroundItemStore';
import NewItemForm from './newItemForm';

const useStyles = makeStyles((theme: Theme) => ({
  fixedWidthText: {
    fontSize: '1rem',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'left',
    paddingLeft: '10px',
    width: '100%'
  },
  ssnCell: {
    textAlign: 'center',
    minWidth: '120px',
  },
  tableContainer: {
    borderRadius: '10px',
    border: '1px #e0e0e0 solid',
    overflow: 'hidden',
  },
  table: {
    minWidth: 750,
    width: '100%',
    '& td': {
      height: '40px',
      padding: 0,
      paddingTop: '8px',
      paddingBottom: '8px',
      border: '1px solid #e0e0e0',
    },
    '& th:first-child, td[rowSpan], $addNewItemRow td': {
      paddingLeft: theme.spacing(2),
      paddingRight: '3px',
    },
    '& table tr:last-child td, & table td[rowSpan]': {
      borderBottom: 'none',
    },
  },

  editableRow: {
    position: 'relative',
    cursor: 'pointer',
    textAlign: 'center',
    '& td': {
      textAlign: 'center'
    },
  },

  headerCell: {
    height: '41px',
    fontWeight: 'bold',
    color: '#757575',
    padding: 0,
    backgroundColor: '#F0FAFF',
    textAlign: 'center',
    border: '1px solid #e0e0e0',
  },

  titleCell: {
    height: '46px',
    padding: 0,
    overflow: 'hidden',
    backgroundColor: '#E2F6FF'
  },

  descriptionCell: {
    whiteSpace: 'pre-wrap',
  },

  inputBase: {
    width: '100%',
    '& input': {
      backgroundColor: '#fdefbf',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      fontSize: 14,
      color: 'black',
      fontWeight: theme.typography.fontWeightBold,
      padding: '8px 4px',
      cursor: 'pointer',
      '&:focus': {
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  autocompletePaper: {
    marginLeft: 0,
    marginRight: 0,
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    fontSize: 13,
  },
  autocompleteOption: {
    minHeight: 'auto',
    alignItems: 'flex-start',
    padding: 8,
    '&[data-focus="true"]': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  addNewItemCell: {
    padding: 0,
    height: '40px',
    backgroundColor: '#fdefbf',
  },
  addNewItemRow: {
    '&:hover': {
      backgroundColor: '#fff',
    },
  },
  rowActions: {
    visibility: 'hidden',
    position: 'absolute',
    right: 0,
    top: 0,
    bottom: 0,
    zIndex: 99,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      visibility: 'visible',
    },
  },
  horizontalScroll: {
    [theme.breakpoints.down('md')]: {
      overflowX: 'scroll',
    },
  },
  clickHereToEdit: {
    color: theme.palette.grey[400],
    fontSize: theme.typography.fontSize * 0.8,
  },
  clickHereToEditHidden: {
    visibility: 'hidden',
  },
  title: {
    color: '#757575',
    fontSize: 20
  },
}));

interface LetterOfExplanationProps {
  store: BackgroundItemStore;
}

const LetterOfExplanation = observer(({ store }: LetterOfExplanationProps) => {
  const classes = useStyles();
  const columns = [
    {
      name: 'ITEM SECTION',
      width: '50%',
    },
    {
      name: 'ITEM NAME',
      width: '50%',
    },
  ];

  return (
    <Box style={{ paddingTop: '15px' }}>
      <div className={classes.tableContainer}>
        <Table className={classes.table}>
          <TableHead><TableRow>
            <TableCell colSpan={columns.length} className={classes.titleCell}>
              <Typography className={classes.title}>Resolutions</Typography>
            </TableCell>
          </TableRow>
            <TableRow>
              {columns.map(({ name, ...props }) => (
                <TableCell key={name} className={classes.headerCell} style={{ paddingLeft: '8px' }}>
                  {name}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody className={classes.editableRow}>
            {store.letterOfExplanation?.map((q, index) => {
              return (
                <TableRow key={index}>
                  <TableCell width={columns[0].width}>
                    <span className={classes.fixedWidthText}>{q.itemSection}</span>
                  </TableCell>
                  <TableCell width={columns[0].width}>
                    <span className={classes.fixedWidthText}>{q.itemName}</span>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    </Box>
  );
});

export default LetterOfExplanation;