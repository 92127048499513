import { Service } from "@roc/feature-app-core";
import { EditLoanRequestType } from "@roc/feature-utils";

const url = {
  CHANGE_STATUS_TO_PRE_SUBMISSION: '/api/v1/loan/changeLoanStatus',
  RECALCULATE_LOAN_STATUS: '/api/v1/loan/recalculateLoanPreScreenData',
  UPDATE_LOAN: '/api/v1/loan/updateBridgeLoan',
};

export class EditLoansService extends Service {

  changeLoanStatus(loanId: number, data: any) {
    return this.post(`${url.CHANGE_STATUS_TO_PRE_SUBMISSION}?loanId=${loanId}`, data);
  }

  recalculateLoanStatus(loanId: number) {
    return this.get(url.RECALCULATE_LOAN_STATUS, { loanId });
  }

  updateLoan(requestType: EditLoanRequestType, data: any) {
    return this.post(`${url.UPDATE_LOAN}?requestType=${requestType}`, data);
  }

}