import { FormStore } from '@roc/feature-app-core';
import { NO, YES } from '@roc/feature-utils';
import { action, makeObservable, observable } from 'mobx';

const idInformationForm = {
  fields: {
    idType: {
      value: 'US Driver\'s License / Government ID',
      error: null,
      rule: 'required',
      message: 'The last ID Type field is required.',
    },
    driversLicense: {
      value: YES,
      rule: 'required',
      message: 'This field is required',
    },
    driversLicenseState: {
      value: null,
      rule: [{ required_if: ['driversLicense', YES] }],
      message: 'This field is required',
    },
    driversLicenseNumber: {
      value: null,
      rule: [{ required_if: ['driversLicense', YES] }],
      message: 'This field is required',
    },
    passportNumber: {
      value: '',
      error: null,
      rule: [{ required_if: ['driversLicense', NO] }],
      message: 'The passport number field is required',
    },
    passportExpiration: {
      type: 'DATE',
      value: null,
      error: null,
      format: 'MM/dd/yyyy',
      rule: [{ required_if: ['driversLicense', NO] }],
      message: 'The passport expiration date field is required',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};
export class IdInformationStore extends FormStore {

  driversLicense: File;

  constructor(globalStore) {
    super({ ...idInformationForm }, globalStore);

    makeObservable(this, {
      driversLicense: observable,
      setDriversLicense: action,
    });
    this.setDefaults();
  }

  private setDefaults() {
    this.setDriversLicense(null);
  }

  setDriversLicense(file: File) {
    this.driversLicense = file;
  }

  reset() {
    super.reset();
    this.setDefaults();
  }

}