import {
  getDscrCalculatorInternalRoutes,
  getDscrCalculatorInternalRoutesConfig,
} from '../modules/dscrCalculator/routes/dscrCalculatorInternalRoutes';
import {
  getLiquidityCalculatorInternalRoutes,
  getLiquidityCalculatorInternalRoutesConfig,
} from '../modules/liquidityCalculator/routes/liquidityCalculatorInternalRoutes';
import { useRocInternalPortalStore } from './useRocInternalPortalStore';
import {
  getInternalPricerRoutes,
  getInternalPricerRoutesConfig,
} from '@roc/client-portal-shared/modules/internalPricer';
import {
  getRentalLoanPricerRoutes,
  getRentalLoanPricerRoutesConfig,
} from '../../../../../libs/client-portal-shared/src/app/modules/internalPricer/routes/rentalLoanPricerRoutes';
import {
  getDrawReviewReportInternalRoutesConfig,
  getDrawReviewReportInternalRoutes,
} from '../modules/drawReviewReport/routes/drawReviewReportInternalRoutes';
import {
  getPreviousDrawInformationInternalRoutesConfig,
  getPreviousDrawInformationInternalRoutes,
} from '../modules/previousDrawInformation/routes/previousDrawInformationInternalRoutes';
import {
  getScopeOfWorkRevisionInternalRoutes,
  getScopeOfWorkRevisionInternalRoutesConfig,
} from '../modules/scopeOfWorkRevision/routes/scopeOfWorkRevisionRoutesInternal';
import {
  getBackgroundReviewRoutes,
  getBackgroundReviewRoutesConfig
} from '@roc/feature-borrower-review';
import {
  getRocUniversityInternalRoutesConfig,
  getRocUniversityInternalRoutes,
} from '../modules/rocUniversity/routes/rocUniversityInternalRoutes';
import {
  getProspectPropertiesInternalRoutes,
  getProspectPropertiesInternalRoutesConfig,
} from '../modules/prospectProperty/routes/prospectPropertyInternalRoutes';
import {
  getStabilizedBridgeAnalyzerInternalRoutes,
  getStabilizedBridgeAnalyzerInternalRoutesConfig,
} from '../modules/stabilizedBridgeAnalyzer/routes/stabilizedBridgeAnalyzerInternalRoutes';

import {
  getBridgePricerRoutes,
  getBridgePricerRoutesConfig,
} from '@roc/client-portal-shared/modules/bridgePricer';
import {
  getStandAloneAppraisalInternalRoutes,
  getStandAloneAppraisalInternalRoutesConfig,
} from '../modules/standAloneAppraisal/routes/standAloneAppraisalInternalRoutes';
import {
  getMultifamilyLiquidityCalculatorInternalRoutes,
  getMultifamilyLiquidityCalculatorInternalRoutesConfig
} from '../modules/multifamilyLiquidityCalculator/routes/multifamilyLiquidityCalculatorInternalRoutes';
import { getLiquidityVerificationInternalRoutes, getLiquidityVerificationInternalRoutesConfig } from '../modules/liquidityVerification/routes/liquidityVerificationInternalRoutes';
import { getBorrowerBaseballCardRoutes, getBorrowerBaseballCardRoutesConfig, getBorrowerTrackRecordRoutes, getBorrowerTrackRecordRoutesConfig, getBorrowerUWReviewRoutes, getBorrowerUWReviewRoutesConfig, getBorrowerTrackRecordLineItemRoutesConfig, getBorrowerTrackRecordLineItemRoutes } from '../modules/borrowerInfoCard/routes/borrowerInfoCardInternalRoutes';
import { getResolutionRoutes, getResolutionRoutesConfig } from 'libs/feature-borrower-review/src/routes/resolutionRoutes';
import { getAppraisalReportRoutes, getAppraisalReportRoutesConfig } from 'libs/feature-appraisals/src/appraisals/routes/appraisalReportRoutes';
import { getCreditReportRoutes, getCreditReportRoutesConfig, getCreditReportExampleRoutes, getCreditReportExampleRoutesConfig } from 'libs/feature-borrower-review/src/routes/creditReportRoutes';
import {
  getCreditReviewRoutes,
  getCreditReviewRoutesConfig
} from '@roc/feature-borrower-review';
import { getBridgeProfitAndDscrCalculatorInternalRoutes, getBridgeProfitAndDscrCalculatorInternalRoutesConfig } from '../modules/bridgeProfitAndDscrCalculator/routes/bridgeProfitAndDscrCalculatorInternalRoutes';
import { getBorrowerVerificationRoutes, getBorrowerVerificationRoutesConfig } from '@roc/feature-borrowers';
import { getDocumentLibraryInternalRoutes, getDocumentLibraryInternalRoutesConfig } from '../modules/documentLibraryContentul/routes/documentLibraryInternalRoutes';

import { getEscrowCalculatorInternalRoutes, getEscrowCalculatorInternalRoutesConfig } from '../modules/escrowDepositCalculator/routes/escrowDepositCalculatorRoutes';
import { getDrawDqDataInternalRoutes, getDrawDqDataInternalRoutesConfig } from '../modules/drawDqData/routes/drawDqDataRoutes';
import { getTermSheetInternalRoutes, getTermSheetInternalRoutesConfig } from '../modules/termSheet/routes/termSheetInternalRoutes';
import { useInternalAppsRoutes } from '@roc/feature-apps-contentful';
import { getInsuranceReviewInternalRoutes, getInsuranceReviewInternalRoutesConfig } from '../modules/insuranceReview/routes/insuranceReviewInternalRoutes';
import { getQuoteGeneratorRoutes, getQuoteGeneratorRoutesConfig } from '@roc/feature-quote-generator';
import { getLoanClosingConditionRoutes, getLoanClosingConditionRoutesConfig } from '../modules/loanClosingCondition/routes/loanClosingConditionRoutes';
import { getScopeOfWorkInternalRoutes, getScopeOfWorkInternalRoutesConfig, getScopeOfWorkUwInternalRoutes, getScopeOfWorkUwInternalRoutesConfig } from '../modules/scopeOfWork/routes/scopeOfWorkInternalRoutes';
import { getAppraisalCdaRoutes, getAppraisalCdaRoutesConfig, getDelinquencyBpoRoutes, getDelinquencyBpoRoutesConfig } from '@roc/feature-appraisals';
import { getAppraisalReviewReportInternalRoutes, getAppraisalReviewReportInternalRoutesConfig } from '../modules/appraisalReview/routes/appraisalReviewInternalRoutes';
import { getCompanyProfileCRMInternalRoutes, getCompanyProfileCRMInternalRoutesConfig } from '../modules/companyProfileCRM/routes/companyProfileCRMInternalRoutes';
import { getLenderDetailsInternalRoutes, getLenderDetailsInternalRoutesConfig } from '../modules/companyProfileCRM/routes/lenderDetailsInternalRoutes';
import { getManageBrokersInternalRoutes, getManageBrokersInternalRoutesConfig } from '../modules/manageBrokers/routes/brokersRoutesInternal';
import { getGeneralTasksRoutes, getGeneralTasksRoutesConfig } from '@roc/feature-general-tasks';
import { getSpecialServicingRoutes, getSpecialServicingRoutesConfig } from '@roc/feature-special-servicing';
import { getExpectedClosingsRoutes, getExpectedClosingsRoutesConfig } from '../modules/expectedClosings/routes/expectedClosingsRoutes';
import { getValuationReviewInternalRoutes, getValuationReviewInternalRoutesConfig } from '../modules/valuationReview/routes/valuationReviewInternalRoutes';
import {
  getForeclosureOverviewInternalRoutes,
  getForeclosureOverviewInternalRoutesConfig, getValuationDashboardRoutes, getValuationDashboardRoutesConfig
} from '../modules/foreclosureOverview/routes/foreclosureOverviewInternalRoutes';
import { getCompToolInternalRoutes, getCompToolRoutesInternalConfig } from '../modules/compTool/routes/compToolInternalRoutes';
import { getLoanDetailsRoutes, getLoanDetailsRoutesConfig } from '@roc/feature-loan-details'
import { getReferralFeeLinkRoutesConfig, getReferralFeeLinkRoutes } from '@roc/feature-referral';
import { getBorrowerSetupToDoInternalRoutes, getBorrowerSetupToDoInternalRoutesConfig } from '../modules/borrowerSetupToDo/borrowerSetupToDoInternalRoutes';
import { getSubmissionVsUnderwritingInternalRoutes, getSubmissionVsUnderwritingInternalRoutesConfig } from '../modules/submissionVsUnderwriting/routes/submissionVsUnderwritingRoutes'
import { getLiquidityReportInternalRoutes, getLiquidityReportInternalRoutesConfig } from '../modules/liquidityReport/routes/liquidityReportInternalRoutes'
import { getCMTInternalRoutes, getCMTInternalRoutesConfig } from '../modules/cmtReport/routes/cmtInternalRoutes';
import { getInterestAllocationInternalRoutes, getInterestAllocationInternalRoutesConfig } from '../modules/InterestAllocation/interestAllocationInternalRoutes';
import { getRevenueRecognitionRoutes, getRevenueRecognitionRoutesConfig } from '@roc/feature-revenue-recognition';


export const useRocInternalPortalRoutes = () => {
  const { globalStore } = useRocInternalPortalStore();
  const { routeBasePath, routeBaseUrl } = globalStore;
  const { borrowerId, reviewSection, borrower, companyId } = globalStore.queryParams;
  const { loanId, loanDocumentId, closingConditionAdmin, closingConditionCommittee, loanStatus, isTamariskLender, propertyId, lenderId, loanType, borrowerEntityId, brokerId, attestationType } = globalStore.queryParams;
  const { token } = globalStore.queryParams;
  const { portalId } = globalStore.queryParams;
  const { nportalInternal } = globalStore.queryParams;
  const { readOnly } = globalStore.queryParams;
  const {
    appsRoutes: appsRoutes,
    appsRoutesConfig: appsRoutesConfig
  } = useInternalAppsRoutes();

  return {
    appsRoutes,
    appsRoutesConfig,
    dscrCalculatorRoutes: getDscrCalculatorInternalRoutes(
      routeBasePath,
      routeBaseUrl
    ),
    dscrCalculatorRoutesConfig: getDscrCalculatorInternalRoutesConfig(
      routeBasePath,
      routeBaseUrl
    ),
    liquidityCalculatorRoutes: getLiquidityCalculatorInternalRoutes(
      routeBasePath,
      routeBaseUrl
    ),
    liquidityCalculatorRoutesConfig: getLiquidityCalculatorInternalRoutesConfig(
      routeBasePath,
      routeBaseUrl
    ),
    multifamilyLiquidityCalculatorRoutes: getMultifamilyLiquidityCalculatorInternalRoutes(
      routeBasePath,
      routeBaseUrl
    ),
    multifamilyLiquidityCalculatorRoutesConfig: getMultifamilyLiquidityCalculatorInternalRoutesConfig(
      routeBasePath,
      routeBaseUrl
    ),
    scopeOfWorkRevisionRoutes: getScopeOfWorkRevisionInternalRoutes(
      routeBasePath,
      routeBaseUrl
    ),
    scopeOfWorkRevisionRoutesConfig: getScopeOfWorkRevisionInternalRoutesConfig(
      routeBasePath,
      routeBaseUrl
    ),
    internalPricerRoutes: getInternalPricerRoutes(routeBasePath, routeBaseUrl),
    internalPricerRoutesConfig: getInternalPricerRoutesConfig(
      routeBasePath,
      routeBaseUrl
    ),
    rentalPricerRoutes: getRentalLoanPricerRoutes(routeBasePath, routeBaseUrl),
    rentalPricerRoutesConfig: getRentalLoanPricerRoutesConfig(
      routeBasePath,
      routeBaseUrl
    ),
    rocUniversityInternalRoutes: getRocUniversityInternalRoutes(
      routeBasePath,
      routeBaseUrl
    ),
    rocUniversityInternalRoutesConfig: getRocUniversityInternalRoutesConfig(
      routeBasePath,
      routeBaseUrl
    ),
    prospectPropertiesInternalRoutesConfig: getProspectPropertiesInternalRoutesConfig(
      routeBasePath,
      routeBaseUrl
    ),
    prospectPropertiesInternalRoutes: getProspectPropertiesInternalRoutes(
      routeBasePath,
      routeBaseUrl
    ),
    drawReviewReportRoutes: getDrawReviewReportInternalRoutes(
      routeBasePath,
      routeBaseUrl
    ),
    drawReviewReportRoutesConfig: getDrawReviewReportInternalRoutesConfig(
      routeBasePath,
      routeBaseUrl
    ),
    previousDrawInformationRoutes: getPreviousDrawInformationInternalRoutes(
      routeBasePath,
      routeBaseUrl
    ),
    previousDrawInformationRoutesConfig: getPreviousDrawInformationInternalRoutesConfig(
      routeBasePath,
      routeBaseUrl
    ),
    bridgePricerRoutes: getBridgePricerRoutes(routeBasePath, routeBaseUrl),
    bridgePricerRoutesConfig: getBridgePricerRoutesConfig(
      routeBasePath,
      routeBaseUrl
    ),
    backgroundReviewRoutes: getBackgroundReviewRoutes(routeBasePath, routeBaseUrl, borrower, loanId, readOnly),
    backgroundReviewRoutesConfig: getBackgroundReviewRoutesConfig(
      routeBasePath,
      routeBaseUrl,
      borrower,
      loanId
    ),
    resolutionRoutes: getResolutionRoutes(routeBasePath, routeBaseUrl, loanDocumentId, readOnly),
    resolutionsRoutesConfig: getResolutionRoutesConfig(
      routeBasePath,
      routeBaseUrl,
      loanDocumentId
    ),
    appraisalReportRoutes: getAppraisalReportRoutes(routeBasePath, routeBaseUrl, loanId, loanDocumentId),
    appraisalReportRoutesConfig: getAppraisalReportRoutesConfig(
      routeBasePath,
      routeBaseUrl,
      loanId,
      loanDocumentId
    ),
    appraisalReviewReportRoutes: getAppraisalReviewReportInternalRoutes(routeBasePath, routeBaseUrl, loanId, propertyId),
    appraisalReviewReportRoutesConfig: getAppraisalReviewReportInternalRoutesConfig(
      routeBasePath,
      routeBaseUrl,
    ),
    valuationReviewRoutes: getValuationReviewInternalRoutes(routeBasePath, routeBaseUrl, loanId, propertyId),
    valuationReviewRoutesConfig: getValuationReviewInternalRoutesConfig(
      routeBasePath,
      routeBaseUrl
    ),
    closingConditionRoutes: getLoanClosingConditionRoutes(routeBasePath, routeBaseUrl, loanDocumentId, closingConditionAdmin, closingConditionCommittee),
    closingConditionRoutesConfig: getLoanClosingConditionRoutesConfig(
      routeBasePath,
      routeBaseUrl
    ),
    creditReportRoutes: getCreditReportRoutes(routeBasePath, routeBaseUrl, loanDocumentId),
    creditReportRoutesConfig: getCreditReportRoutesConfig(
      routeBasePath,
      routeBaseUrl,
      loanDocumentId
    ),
    creditReportExampleRoutes: getCreditReportExampleRoutes(routeBasePath, routeBaseUrl),
    getCreditReportExampleRoutesConfig: getCreditReportExampleRoutesConfig(
      routeBasePath,
      routeBaseUrl
    ),
    apprailsaCdaRoutes: getAppraisalCdaRoutes(routeBasePath, routeBaseUrl, loanId, loanDocumentId, isTamariskLender),
    appraisalCdaRoutesConfig: getAppraisalCdaRoutesConfig(
      routeBasePath,
      routeBaseUrl,
      loanId,
      loanDocumentId
    ),
    getDelinquencyBpoRoutes: getDelinquencyBpoRoutes(routeBasePath, routeBaseUrl, loanId, isTamariskLender),
    getDelinquencyBpoRoutesConfig: getDelinquencyBpoRoutesConfig(
      routeBasePath,
      routeBaseUrl,
      loanId,
    ),
    stabilizedBridgeAnalyzerRoutes: getStabilizedBridgeAnalyzerInternalRoutes(
      routeBasePath,
      routeBaseUrl
    ),
    stabilizedBridgeAnalyzerRoutesConfig: getStabilizedBridgeAnalyzerInternalRoutesConfig(
      routeBasePath,
      routeBaseUrl
    ),
    standAloneAppraisalInternalRoutesConfig: getStandAloneAppraisalInternalRoutesConfig(
      routeBasePath,
      routeBaseUrl
    ),
    standAloneAppraisalInternalRoutes: getStandAloneAppraisalInternalRoutes(
      routeBasePath,
      routeBaseUrl
    ),
    liquidityVerificationInternalRoutesConfig: getLiquidityVerificationInternalRoutesConfig(
      routeBasePath,
      routeBaseUrl
    ),
    liquidityVerificationInternalRoutes: getLiquidityVerificationInternalRoutes(
      routeBasePath,
      routeBaseUrl
    ),
    borrowerBaseballCardRoutesConfig: getBorrowerBaseballCardRoutesConfig(
      routeBasePath,
      routeBaseUrl,
      borrowerId
    ),
    borrowerBaseballCardRoutes: getBorrowerBaseballCardRoutes(
      routeBasePath,
      routeBaseUrl,
      borrowerId
    ),
    borrowerTrackRecordRoutesConfig: getBorrowerTrackRecordRoutesConfig(
      routeBasePath,
      routeBaseUrl,
    ),
    borrowerTrackRecordLineItemRoutesConfig: getBorrowerTrackRecordLineItemRoutesConfig(
      routeBasePath,
      routeBaseUrl,
    ),
    borrowerTrackRecordRoutes: getBorrowerTrackRecordRoutes(
      routeBasePath,
      routeBaseUrl,
      borrowerId,
      loanId
    ),
    borrowerTrackRecordLineItemRoutes: getBorrowerTrackRecordLineItemRoutes(
      routeBasePath,
      routeBaseUrl,
      borrowerId,
      loanDocumentId,
    ),
    borrowerUWReviewRoutesConfig: getBorrowerUWReviewRoutesConfig(
      routeBasePath,
      routeBaseUrl,
    ),
    borrowerUWReviewRoutes: getBorrowerUWReviewRoutes(
      routeBasePath,
      routeBaseUrl,
      borrowerId,
      reviewSection,
      loanId
    ),
    creditReviewRoutes: getCreditReviewRoutes(routeBasePath, routeBaseUrl, borrower, loanId),
    creditReviewRoutesConfig: getCreditReviewRoutesConfig(
      routeBasePath,
      routeBaseUrl
    ),
    bridgeProfitAndDscrCalculatorInternalRoutes: getBridgeProfitAndDscrCalculatorInternalRoutes(routeBasePath, routeBaseUrl),
    bridgeProfitAndDscrCalculatorInternalRoutesConfig: getBridgeProfitAndDscrCalculatorInternalRoutesConfig(
      routeBasePath,
      routeBaseUrl
    ),
    borrowerVerificationRoutes: getBorrowerVerificationRoutes(routeBasePath, routeBaseUrl, token),
    borrowerVerificationRoutesConfig: getBorrowerVerificationRoutesConfig(routeBasePath, routeBaseUrl),
    documentLibraryRoutes: getDocumentLibraryInternalRoutes(
      routeBasePath,
      routeBaseUrl,
      portalId,
      nportalInternal
    ),
    documentLibraryRoutesConfig: getDocumentLibraryInternalRoutesConfig(
      routeBasePath,
      routeBaseUrl,
      portalId,
      nportalInternal,
    ),
    escrowDepositInternalRoutes: getEscrowCalculatorInternalRoutes(
      routeBasePath,
      routeBaseUrl,
      loanId,
      loanDocumentId
    ),
    escrowDepositInternalRoutesConfig: getEscrowCalculatorInternalRoutesConfig(
      routeBasePath,
      routeBaseUrl,
      loanId,
      loanDocumentId
    ),
    drawDqDataInternalRoutes: getDrawDqDataInternalRoutes(
      routeBasePath,
      routeBaseUrl
    ),
    drawDqDataInternalRoutesConfig: getDrawDqDataInternalRoutesConfig(
      routeBasePath,
      routeBaseUrl
    ),
    termSheetInternalRoutes: getTermSheetInternalRoutes(routeBasePath, routeBaseUrl),
    termSheetInternalRoutesConfig: getTermSheetInternalRoutesConfig(routeBasePath, routeBaseUrl),
    insuranceReviewRoutes: getInsuranceReviewInternalRoutes(
      routeBasePath,
      routeBaseUrl,
      loanId,
      loanStatus
    ),
    insuranceReviewRoutesConfig: getInsuranceReviewInternalRoutesConfig(
      routeBasePath,
      routeBaseUrl,
      loanId
    ),
    expectedClosingsRoutesConfig: getExpectedClosingsRoutesConfig(routeBasePath, routeBaseUrl),
    expectedClosingsRoutes: getExpectedClosingsRoutes(routeBasePath, routeBaseUrl),
    bridgeQuoteRoutes: getQuoteGeneratorRoutes(routeBasePath, routeBaseUrl),
    bridgeQuoteRoutesConfig: getQuoteGeneratorRoutesConfig(routeBasePath, routeBaseUrl),
    scopeOfWorkRoutes: getScopeOfWorkInternalRoutes(routeBasePath, routeBaseUrl),
    scopeOfWorkRoutesConfig: getScopeOfWorkInternalRoutesConfig(routeBasePath, routeBaseUrl),
    lenderDetailsInternalRoutes: getLenderDetailsInternalRoutes(routeBasePath, routeBaseUrl),
    lenderDetailsInternalRoutesConfig: getLenderDetailsInternalRoutesConfig(routeBasePath, routeBaseUrl),
    companyProfileCRMInternalRoutes: getCompanyProfileCRMInternalRoutes(routeBasePath, routeBaseUrl, companyId),
    companyProfileCRMInternalRoutesConfig: getCompanyProfileCRMInternalRoutesConfig(routeBasePath, routeBaseUrl, companyId),
    manageBrokersRoutes: getManageBrokersInternalRoutes(routeBasePath, routeBaseUrl),
    manageBrokersRoutesConfig: getManageBrokersInternalRoutesConfig(routeBasePath, routeBaseUrl),
    borrowerSetupRoutes: getBorrowerSetupToDoInternalRoutes(routeBasePath, routeBaseUrl, loanId, borrowerId, lenderId, loanType, borrowerEntityId),
    borrowerSetupRoutesConfig: getBorrowerSetupToDoInternalRoutesConfig(routeBasePath, routeBaseUrl),
    generalTasksRoutes: getGeneralTasksRoutes(routeBasePath, routeBaseUrl),
    generalTasksRoutesConfig: getGeneralTasksRoutesConfig(routeBasePath, routeBaseUrl),
    specialServicingRoutes: getSpecialServicingRoutes(routeBasePath, routeBaseUrl, loanId, attestationType),
    specialServicingRoutesConfig: getSpecialServicingRoutesConfig(routeBasePath, routeBaseUrl, loanId, attestationType),
    foreclosureOverviewRoutes: getForeclosureOverviewInternalRoutes(routeBasePath, routeBaseUrl),
    foreclosureOverviewRoutesConfig: getForeclosureOverviewInternalRoutesConfig(routeBasePath, routeBaseUrl),
    loanValuationRoutes: getValuationDashboardRoutes(routeBasePath, routeBaseUrl),
    loanValuationRoutesConfig: getValuationDashboardRoutesConfig(routeBasePath, routeBaseUrl),
    compToolRoutes: getCompToolInternalRoutes(
      routeBasePath,
      routeBaseUrl
    ),
    compToolRoutesConfig: getCompToolRoutesInternalConfig(
      routeBasePath,
      routeBaseUrl
    ),
    loanDetailsRoutes: getLoanDetailsRoutes(routeBasePath, routeBaseUrl),
    loanDetailsRoutesConfig: getLoanDetailsRoutesConfig(routeBasePath, routeBaseUrl),
    referralFeeLinkRoutes: getReferralFeeLinkRoutes(routeBasePath, routeBaseUrl, loanId, brokerId),
    referralFeeLinkRoutesConfig: getReferralFeeLinkRoutesConfig(routeBasePath, routeBaseUrl),
    scopeOfWorkUWRoutes: getScopeOfWorkUwInternalRoutes(routeBasePath, routeBaseUrl),
    scopeOfWorkRoutesUWConfig: getScopeOfWorkUwInternalRoutesConfig(routeBasePath, routeBaseUrl),
    submissionVsUnderwritingRoutes: getSubmissionVsUnderwritingInternalRoutes(routeBasePath, routeBaseUrl, loanId, loanType),
    submissionVsUnderwritingRoutesConfig: getSubmissionVsUnderwritingInternalRoutesConfig(routeBasePath, routeBaseUrl),
    liquidityReportInternalRoutes: getLiquidityReportInternalRoutes(routeBasePath, routeBaseUrl),
    liquidityReportRoutesConfig: getLiquidityReportInternalRoutesConfig(routeBasePath, routeBaseUrl),
    cmtReportRoutes: getCMTInternalRoutes(routeBasePath, routeBaseUrl),
    cmtReportRoutesConfig: getCMTInternalRoutesConfig(routeBasePath, routeBaseUrl),
    interestAllocationRoutesConfig: getInterestAllocationInternalRoutesConfig(routeBasePath, routeBaseUrl),
    interestAllocationRoutes: getInterestAllocationInternalRoutes(routeBasePath, routeBaseUrl),
    revenueRecognitionRoutes: getRevenueRecognitionRoutes(routeBasePath, routeBaseUrl),
    revenueRecognitionRoutesConfig: getRevenueRecognitionRoutesConfig(routeBasePath, routeBaseUrl),
  };
};
