import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography, Paper, TableRow, TableHead, TableContainer,
  TableCell, TableBody, Table, Accordion, AccordionSummary, AccordionDetails, TableFooter
} from '@material-ui/core';
import { observer } from 'mobx-react';
import {  formatCurrency } from '@roc/ui/utils';
import { transformDataV2, getTotalFromSection } from './foreclosureOverviewUtils';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    '& .MuiTableRow-head th': {
      fontWeight: 'bold',
    },
  },
  tableCellBold: {
    fontWeight: 'bold',
    backgroundColor: '#D9DADC',
    fontSize: '0.875rem',
    color: '#222222'
  },
  tableContainer: {
    maxHeight: 800,
  },
  accordionSummary: {
    backgroundColor: '#D9DADC',
    '& .MuiAccordionSummary-content': {
      margin: '10px 0px',
    },
  },
  labelRow: {
    backgroundColor: '#edebeb !important',
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  noMarginBottom: {
    marginBottom: '0px',
  },
  accordionDetails: {
    padding: '0px',
  }
});

interface Props {
  title: string;
  columns: any[];
  data: any;
  total: number
}

export const TotalsTable = observer(({ title, columns, data, total }: Props) => {
  const classes = useStyles();
  const getHeaderTableRow = (columns: any[], key: string) => {
    return (
      <TableRow key={`${key}-head-columns-row`}>
        {columns?.map((column, index) => (
          <TableCell key={`${key}-${column.fieldname}`} align="center">{column.fieldname}</TableCell>
        ))}
      </TableRow>
    );
  };

  const getLabelTableRow = (columns: any[], key: string) => {
    const lastIndex = columns.length - 1;
    return (
      <TableRow key={`${key}-head-label-row`}>
        {columns?.map((column, index) => (
          <TableCell className={classes.labelRow} key={`${key}-${index}-label`} align="center">
            {
              index === 0 ? key : (index === lastIndex) ? `${getTotalFromSection(data[key], column.mapvalue)}` : ""
            }
          </TableCell>
        ))}
      </TableRow>
    );
  };

  const getBodyTableRow = (key: string, data: any) => {
    return (
      <>
        {data[key]?.map((record: any, index: number) => (
          <TableRow hover key={`${key}-${index}-content-row`}>
            {transformDataV2(record, columns).map((item) => (
              <TableCell key={`${key}-${index}-${item.name}`} align="center">{item.value}</TableCell>
            ))}
          </TableRow>
        ))}
      </>
    );
  };

  return (
    <Paper elevation={0} variant='outlined'>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          className={classes.accordionSummary}
        >
          <Typography gutterBottom variant="h5" component="h2" className={classes.noMarginBottom}>
            {title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <TableContainer className={classes.tableContainer}>
            <Table className={classes.table} size='small' stickyHeader>
              {data && Object.keys(data).map((key, index) => (
                <>
                  <TableHead key={`${key}-head-label`}>
                    {getLabelTableRow(columns, key)}
                  </TableHead>
                  <TableHead key={`${key}-head-columns`}>
                    {getHeaderTableRow(columns, key)}
                  </TableHead>
                  <TableBody key={`${key}-body-rows`}>
                    {getBodyTableRow(key, data)}
                  </TableBody>
                </>
              ))}
              <TableFooter>
                <TableRow>
                  <TableCell align="left" colSpan={4} className={`${classes.tableCellBold}`}>
                    Total Protective Advances
                  </TableCell>
                  <TableCell className={`${classes.tableCellBold}`} align="center">
                    {formatCurrency(total, 2)}
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
    </Paper>
  );
});