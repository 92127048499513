import { FormStore } from '@roc/feature-app-core';

const form = {
  fields: {
    interestRate: {
      value: 12,
      error: null,
      rule: '',
    },
    loanTermMonths: {
      value: 12,
      error: null,
      rule: '',
    },
    totalPoints: {
      value: 2,
      error: null,
      rule: '',
    },
    capitalProviderPoints: {
      value: 0,
      error: null,
      rule: '',
    },
    lenderPoints: {
      value: 0,
      error: null,
      rule: '',
    },
    brokerPoints: {
      value: 0,
      error: null,
      rule: '',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export class LoanEconomicsFormStore extends FormStore {
  constructor(globalStore) {
    super(form, globalStore);
  }

  loadFloorRate(floorRate: number) {
    if (this.form.fields.interestRate.value < floorRate) {
      this.onFieldChange('interestRate', floorRate);
    }
  }

  loadPoints(floorPoints: number, maxPoints: number) {
    if (this.form.fields.totalPoints.value < floorPoints) {
      this.onFieldChange('totalPoints', floorPoints);
    }
    if (this.form.fields.totalPoints.value > maxPoints) {
      this.onFieldChange('totalPoints', maxPoints);
    }
  }
}
