import { Box, IconButton, TableHead, Tooltip, Typography } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { Add, Check, Delete, Edit } from '@material-ui/icons';
import { isNil } from '@roc/feature-utils';
import { Button, CurrencyField, PercentageField, SelectField, IconButton as RocIconButton, ConfirmDialog } from '@roc/ui';
import { formatCurrency, formatPercentage } from '@roc/ui/utils';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  table: {
    '& .MuiInputBase-root *': {
      fontSize: 14
    },
    '& .MuiSelect-root *': {
      fontSize: 14
    },
  },
  customFieldInputContainer: {
    minWidth: 220,
    '& .currency-field-container': {
      display: 'inline-block',
      maxWidth: 100,
      '& fieldset': {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
      },
      '& input': {
        padding: '8px 8px 8px 0',
        textAlign: 'left'
      },
    },
    '& .percentage-field-container': {
      display: 'inline-block',
      maxWidth: 80,
      '& fieldset': {
        borderLeftWidth: 0,
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
      },
      '& input': {
        padding: '8px 0 8px 8px',
        textAlign: 'right'
      },
    },
    '& .check-button-container': {
      display: 'inline-block',
      padding: '8px 0 8px 8px'
    },
  },
  selectField: {
    '& .MuiSelect-root': {
      padding: '8px 32px 8px 8px'
    }
  }
});

export function createData(category: string, name: string, amount: number, percent: number) {
  return { category, name, amount, percent };
}

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
      paddingTop: theme.spacing(1),
      minHeight: 42,
    },
    title: {
      flex: '1 1 100%',
    },
    tooltipRoot: {
      width: '150px',
      justifyContent: 'space-between',
    },
    tooltipWrapper: {
      display: 'flex',
    },
    tooltipText: {
      fontSize: '0.8rem',
    },
    separateToRight: {
      marginRight: '8px'
    }
  }),
);

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: 'white',
    color: 'rgba(0, 0, 0, 0.87)',
    border: '1px solid #222222',
    marginBottom: '6px',
  },
}))(Tooltip);

const EnhancedTableToolbar = ({ title, customQuote, showFees = false, pricerSummaryStore, quoteIndex, isInternal }) => {
  const classes = useToolbarStyles();
  const customFieldClasses = useStyles();
  const rateAvailable = !isNil(customQuote.rate);
  const [editFeesMode, setEditFeesMode] = React.useState(false);
  const [isConfirmOpen, setIsConfirmOpen] = React.useState(false);

  const handleConfirm = () => {
    setIsConfirmOpen(false);
    // timeout is needed because if modal closes the input looses the focus.
    setTimeout(() => {
      setEditFeesMode(true);
    }, 100);
  };

  return (
    <Toolbar
      className={clsx(classes.root)}
    >
      <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
        {title}
      </Typography>
      <Box>
        {showFees && rateAvailable && customQuote ? (
          editFeesMode ? (
            <Box>
              <HtmlTooltip
                open={true}
                title={(
                  <Box className={`${classes.tooltipRoot} ${classes.tooltipWrapper}`}>
                    <Box className={`${classes.tooltipWrapper} ${classes.separateToRight}`}>
                      <Typography className={classes.tooltipText} style={{ paddingRight: '2px' }}>Min:</Typography>
                      <Typography className={classes.tooltipText} style={{ color: '#00BCD4' }}>$0</Typography>
                    </Box>
                    <Box className={classes.tooltipWrapper}>
                      <Typography className={classes.tooltipText} style={{ paddingRight: '2px' }}>Max:</Typography>
                      <Typography className={classes.tooltipText} style={{ color: '#00BCD4' }}>
                        {formatCurrency(customQuote.maxPointsAndFees)}
                      </Typography>
                    </Box>
                  </Box>
                )}
                arrow
                placement="top-start"
              ><p></p></HtmlTooltip>
              <Box className={customFieldClasses.customFieldInputContainer}>
                <Box className={'currency-field-container'}>
                  <CurrencyField
                    testId='editFeesInput'
                    value={customQuote.pointsAndFees}
                    variant={'outlined'}
                    onChange={(_, value) => {
                      pricerSummaryStore.handleRateBuydownChange(value, customQuote, quoteIndex, 'amount');
                    }}
                    onBlur={() => {
                      customQuote.pointsAndFeesPercent = Number((customQuote.pointsAndFees * 100 / customQuote.pricerLoanTerms.amount).toFixed(2));
                      customQuote.buydown = customQuote.pointsAndFees;
                      pricerSummaryStore.editCustomQuote(customQuote, quoteIndex, true, false);
                    }}
                  />
                </Box>
                <Box className={'percentage-field-container'}>
                  <PercentageField
                    testId='editFeesByPercentageInput'
                    value={customQuote.pointsAndFeesPercent}
                    variant={'outlined'}
                    onChange={(_, value) => {
                      pricerSummaryStore.handleRateBuydownChange(value, customQuote, quoteIndex, 'percent');
                    }}
                    onBlur={() => {
                      const calculatedFeeAmount = Number((customQuote.pricerLoanTerms.amount * customQuote.pointsAndFeesPercent / 100).toFixed(2));
                      customQuote.pointsAndFees = calculatedFeeAmount;
                      customQuote.buydown = customQuote.pointsAndFees;
                      pricerSummaryStore.editCustomQuote(customQuote, quoteIndex, true, false);
                    }}
                  />
                </Box>
                <Box className={'check-button-container'}>
                  <IconButton color="primary" aria-label="enter" size='small'>
                    <Check fontSize="small" onClick={(event) => {
                      pricerSummaryStore.handleRateBuydownSubmit(quoteIndex);
                      setEditFeesMode(false);
                      customQuote.yieldSpread = 0;
                      pricerSummaryStore.editCustomQuote(customQuote, quoteIndex, true);
                    }} />
                  </IconButton>
                </Box>
              </Box>
            </Box>
          ) : (
            <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Typography>
                <span style={{
                  fontSize: 24,
                  fontWeight: 'bold',
                  margin: '0px 8px'
                }}>{formatCurrency(customQuote.pointsAndFees)}</span>
              </Typography>
              <RocIconButton size='small' testId='reduceRate' >
                <Edit fontSize="small" style={{ color: 'black' }} onClick={(event) => {
                  setIsConfirmOpen(true);
                }} />
              </RocIconButton>
            </Box>
          )
        ) : ''}
      </Box>
      <ConfirmDialog
        open={isConfirmOpen}
        body={`By changing these fees, the rate will be reset to the lowest possible and the ${isInternal ? 'Buydown' : 'Capital Provider'} Points/Fees section will also get reset. Are you sure you want to continue?`}
        handleCancel={() => setIsConfirmOpen(false)}
        handleClose={() => setIsConfirmOpen(false)}
        handleConfirm={handleConfirm}
        confirmButtonText={'Continue'}
        cancelButtonText={'Cancel'}
      />
    </Toolbar>
  );
};

const CustomFeeAndPercentField = observer(
  ({ isEditing, name, amount, percent, disabled = false, onChange, onBlur }) => {
    const classes = useStyles();
    return (
      <Box className={classes.customFieldInputContainer}>
        <Box className="currency-field-container">
          {
            isEditing ? (
              <CurrencyField
                disabled={disabled}
                variant={'outlined'}
                testId={name}
                value={isNil(amount) ? '' : amount}
                onChange={(_, newValue) => onChange('amount', newValue)}
                onBlur={() => onBlur('amount')}
              />
            ) : (
              <>{isNil(amount) ? '' : formatCurrency(amount)}</>
            )
          }
        </Box>
        <Box className="percentage-field-container">
          {
            isEditing ? (
              <PercentageField
                disabled={disabled}
                variant={'outlined'}
                testId={name}
                value={isNil(percent) ? '' : percent}
                onChange={(_, newValue) => onChange('percent', newValue)}
                onBlur={() => onBlur('percent')}
              />
            ) : (
              <>{isNil(percent) ? '' : formatPercentage(percent)}</>
            )
          }
        </Box>
      </Box>
    )
  }
);

export const PointsFeesAllocationTable = observer(({
  customQuote,
  handleAdd,
  handleDelete,
  handleChange,
  handleBlur,
  pricerSummaryStore,
  quoteIndex,
  isInternal,
}) => {
  const classes = useStyles();

  const remaining = customQuote.pointsAndFees - customQuote.totalAllocatedFees;
  const isBroker = pricerSummaryStore.isBroker;

  const feeOptions = [
    { label: 'Admin Fee', value: 'Admin Fee' },
    { label: 'Underwriting Fee', value: 'Underwriting Fee' },
    { label: 'Commitment Fee', value: 'Commitment Fee' },
    { label: 'Buydown Fee', value: 'Buydown Fee' },
    { label: 'Origination Points', value: 'Origination Points' },
    { label: 'Processing Fee', value: 'Processing Fee' },
  ];

  return (
    <Box>
      <Box bgcolor={'#fff'} border={'1px solid #eee'} style={{ marginBottom: '16px' }}>
        <EnhancedTableToolbar title={isInternal || isBroker ? 'Buydown Points/Fees' : 'Capital Provider Points/Fees'}
          customQuote={customQuote}
          showFees={true}
          pricerSummaryStore={pricerSummaryStore}
          quoteIndex={quoteIndex}
          isInternal={isInternal}
        />
        <TableContainer>
          <Table className={classes.table} aria-label="simple table" size="small">
            <TableHead>
              <TableRow>
                <TableCell style={{ width: '45%' }}>Fee Name</TableCell>
                <TableCell align="left" style={{ width: '45%' }}>Fee Amount</TableCell>
                <TableCell style={{ width: '10%' }}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {customQuote.feesRows.map((row, i) => {
                if (row.category !== 'Capital Provider') {
                  return <></>;
                }
                return (
                  <TableRow key={row.rowId}>
                    <TableCell component="th" scope="row" style={{
                      minWidth: 200,
                      width: '45%',
                    }}>
                      <SelectField
                        disabled={false}
                        label={''}
                        value={row.name}
                        fullWidth
                        options={feeOptions}
                        onChange={value => { handleChange(row, 'name', value) }}
                        variant="standard"
                        testId={`feeNameSelect-${i}`}
                        className={classes.selectField}
                      />
                    </TableCell>
                    <TableCell align="left" style={{
                      maxWidth: 200,
                      width: '45%',
                    }}>
                      <CustomFeeAndPercentField
                        isEditing={true}
                        name={`customFeePercentField-${i}`}
                        disabled={false}
                        amount={row.amount}
                        percent={row.percent}
                        onChange={(key, newValue) => { handleChange(row, key, newValue) }}
                        onBlur={(key) => handleBlur(i, key)}
                      />
                    </TableCell>
                    <TableCell align="left" style={{
                      width: '10%',
                    }}>
                      <IconButton aria-label="delete" size='small'>
                        <Delete fontSize="small" onClick={(event) => {
                          handleDelete(i);
                        }} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                )
              })}
              <TableRow>
                <TableCell component="th" scope="row" colSpan={3}>
                  <Button
                    variant="contained"
                    color="default"
                    size="small"
                    onClick={() => handleAdd('Capital Provider')}
                    testId="addFee"
                    startIcon={<Add />}
                    fullWidth
                  >
                    Add Fee
                  </Button>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row" colSpan={2} style={{
                  paddingTop: 16,
                  paddingBottom: 16,
                }}>
                  <div style={{
                    fontWeight: 'bold',
                    textAlign: 'right',
                    paddingBottom: 4,
                  }}>Total allocated fees:</div>
                  <div style={{
                    fontWeight: 'bold',
                    textAlign: 'right'
                  }}>Remaining:</div>
                </TableCell>
                <TableCell>
                  <div style={{
                    fontWeight: 'bold',
                    textAlign: 'right',
                    paddingBottom: 4,
                  }}>{
                      formatCurrency(customQuote.totalAllocatedFees)
                    }</div>
                  <div style={{
                    fontWeight: 'bold',
                    textAlign: 'right',
                    color: (isNil(remaining) || remaining == 0) ? 'inherit' : 'red'
                  }}>{
                      formatCurrency(remaining)
                    }</div>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box bgcolor={'#fff'} border={'1px solid #eee'}>
        <EnhancedTableToolbar
          title={isInternal || isBroker ? 'Non-Buydown Fees' : 'Your Fees'}
          customQuote={customQuote}
          pricerSummaryStore={pricerSummaryStore}
          quoteIndex={quoteIndex}
          isInternal={isInternal}
        />
        <TableContainer>
          <Table className={classes.table} aria-label="simple table" size="small">
            <TableHead>
              <TableRow>
                <TableCell style={{ width: '45%' }}>Fee Name</TableCell>
                <TableCell align="left" style={{ width: '45%' }}>Fee Amount</TableCell>
                <TableCell style={{ width: '10%' }}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {customQuote.feesRows.map((row, i) => {
                if (row.category !== 'Lender') {
                  return <></>;
                }
                return (
                  <TableRow key={row.rowId}>
                    <TableCell component="th" scope="row" style={{
                      minWidth: 200,
                      width: '45%',
                    }}>
                      {isBroker ? (
                        <div>
                          {row.name}
                        </div>
                      ) : (
                        <SelectField
                          disabled={false}
                          label={''}
                          value={row.name}
                          fullWidth
                          options={feeOptions}
                          onChange={value => { handleChange(row, 'name', value) }}
                          variant="standard"
                          testId={`feeNameSelect-${i}`}
                          className={classes.selectField}
                        />
                      )}
                    </TableCell>
                    <TableCell align="left" style={{
                      maxWidth: 200,
                      width: '45%',
                    }}>
                      {isBroker ? (
                        <div>
                          {formatCurrency(row.amount)}
                        </div>
                      ) : (
                        <CustomFeeAndPercentField
                          isEditing={true}
                          name={`customFeePercentField-${i}`}
                          disabled={false}
                          amount={row.amount}
                          percent={row.percent}
                          onChange={(key, newValue) => { handleChange(row, key, newValue) }}
                          onBlur={(key) => handleBlur(i, key)}
                        />
                      )}
                    </TableCell>
                    <TableCell align="left" style={{
                      width: '10%',
                    }}>
                      {!isBroker && (
                        <IconButton aria-label="delete" size='small'>
                          <Delete fontSize="small" onClick={(event) => {
                            handleDelete(i);
                          }} />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                )
              })}
              {!isBroker && (
                <TableRow>
                  <TableCell component="th" scope="row" colSpan={3}>
                    <Button
                      variant="contained"
                      color="default"
                      size="small"
                      onClick={() => handleAdd('Lender')}
                      testId="addFee"
                      startIcon={<Add />}
                      fullWidth
                    >
                      Add Fee
                    </Button>
                  </TableCell>
                </TableRow>
              )}
              <TableRow>
                <TableCell component="th" scope="row" colSpan={2} style={{
                  paddingTop: 16,
                  paddingBottom: 16,
                }}>
                  <div style={{
                    fontWeight: 'bold',
                    textAlign: 'right'
                  }}>Total fees:</div>
                </TableCell>
                <TableCell>
                  <div style={{
                    fontWeight: 'bold',
                    textAlign: 'right',
                  }}>{
                      formatCurrency(customQuote.totalNonRocFees)
                    }</div>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
});
