import { Service } from '@roc/feature-app-core';
import { CONTENTFUL_KEY } from '../utils/utils';

const url = {
  GET_DOCUMENT_LIBRARY_JSON: '/api/v1/vendor/public/contentful/getDocumentLibraryJson',
  DOWNLOAD_DOCUMENT: '/api/v1/loan/documentLibrary/downloadDocumentLibraryDocument',
  GET_DOCUMENT_LIBRARY_JSON_RESOURCES: '/api/v1/vendor/contentful/getDocumentLibraryJsonResources',
  GET_NPORTAL_INTERNAL_DOCUMENT_LIBRARY_JSON: '/api/v1/vendor/contentful/getNportalInternalLibraryJson',
  GET_ENTRY_BY_KEY: '/api/v1/vendor/contentful/getEntryByKey',
};

export class DocumentLibraryService extends Service {
  getDocumentLibraryJson(params) {
    return this.get(url.GET_DOCUMENT_LIBRARY_JSON, params);
  }
  getDocumentLibraryJsonResources(params) {
    return this.get(url.GET_DOCUMENT_LIBRARY_JSON_RESOURCES, params);
  }
  getNportalInternalLibraryJson(params) {
    return this.get(url.GET_NPORTAL_INTERNAL_DOCUMENT_LIBRARY_JSON, params);
  }
  downloadDocument(document: string) {
    return this.get(
      url.DOWNLOAD_DOCUMENT,
      {
        document,
      },
      {
        responseType: 'arraybuffer',
      }
    );
  }

  getEntryByKey(key: CONTENTFUL_KEY) {
    return this.get(url.GET_ENTRY_BY_KEY, { key });
  }

}
