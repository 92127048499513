import { InputAdornment } from '@material-ui/core';
import { alpha, createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import SearchIcon from '@material-ui/icons/Search';
import { IconButton, TextField } from '@roc/ui';
import { useEffect, useState } from 'react';


export const SearchBar = ({ onSearch, value = '', placeholder, startAdornment, clearAfterSearch = false, inputType, classes }: {
  onSearch: (value: string) => void,
  value: string,
  placeholder?: string,
  startAdornment?: React.ReactNode,
  clearAfterSearch?: boolean,
  inputType?: React.InputHTMLAttributes<unknown>['type'],
  classes?: Partial<ClassNameMap<string>>
}) => {
  const [localText, setLocalText] = useState('');

  useEffect(() => {
    setLocalText(value);
  }, [value]);

  const onChange = (e) => {
    setLocalText(e.target.value);
  }

  const keyDownHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onSearch(localText);
      if (clearAfterSearch) {
        setLocalText('');
      }
    }
  };

  return (
    <TextField
      testId='appSearchBar'
      placeholder={placeholder}
      label=""
      variant='outlined'
      classes={classes}
      type={inputType}
      value={localText}
      InputProps={{
        startAdornment,
        endAdornment:
          <InputAdornment position="end">
            <IconButton
              size='small'
              color='primary'
              testId='searchButton'
              onClick={() => { }}
              onMouseDown={() => { }}
            >
              <SearchIcon onClick={() => onSearch(localText)} />
            </IconButton>
          </InputAdornment>
      }}
      onChange={onChange}
      onKeyDown={keyDownHandler}
    />
  )
}