import { action, flow, makeObservable, observable } from 'mobx';
import { ApiResponse } from '@roc/client-portal-shared/services';
import { GlobalStore, FormStore } from '@roc/feature-app-core';
import { DialogState } from '@roc/ui';
import FundingPreferenceStore, {
  AdditionalBorrowerEntityMember,
} from './fundingPreferenceStore';

const REQUIRED_FIELD = 'This field is required.';

const form = {
  fields: {
    fullName: {
      value: '',
      error: null,
      rule: 'required',
      message: REQUIRED_FIELD,
    },
    roleInEntity: {
      value: '',
      error: null,
      rule: 'required',
      message: REQUIRED_FIELD,
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

const defaultNewMember = {
  isLast: false,
  fullName: '',
  roleInEntity: '',
  fullNameActive: true,
  roleInEntityActive: false,
};

export class EditBorrowerEntityMemberStore extends FormStore {
  private globalStore: GlobalStore;
  private fundingPreferenceStore: FundingPreferenceStore;
  private selectedMember: AdditionalBorrowerEntityMember;
  dialogState: DialogState = null;

  constructor(globalStore, fundingPreferenceStore) {
    super(form, globalStore);
    this.globalStore = globalStore;
    this.fundingPreferenceStore = fundingPreferenceStore;

    makeObservable(this, {
      dialogState: observable,
      openAdd: action,
      openEdit: action,
      removeMember: action,
      closeDialog: action,
      resetStore: action,
      submit: action,
    });
  }

  openAdd() {
    this.resetStore();
    this.selectedMember = defaultNewMember;
    this.loadForm(defaultNewMember);
    this.dialogState = DialogState.ADD;
  }

  openEdit(selectedMember: AdditionalBorrowerEntityMember) {
    this.resetStore();
    this.selectedMember = selectedMember;
    this.loadForm(selectedMember);
    this.dialogState = DialogState.EDIT;
  }

  removeMember(member) {
    let members = this.fundingPreferenceStore.additionalBorrowerEntityMembers.concat();
    this.fundingPreferenceStore.additionalBorrowerEntityMembers = members.filter(
      item => item !== member
    );
  }

  closeDialog() {
    this.dialogState = null;
  }

  resetStore() {
    this.selectedMember = null;
    this.reset();
  }

  submit() {
    this.runFormValidationWithMessage();
    if (this.form.meta.isValid) {
      const data = {
        ...this.selectedMember,
        ...this.getFormValues(),
      };

      let members = this.fundingPreferenceStore.additionalBorrowerEntityMembers.concat();

      if (this.dialogState === DialogState.EDIT) {
        const i = members.indexOf(this.selectedMember);
        members[i] = data;
      } else {
        members.push(data);
      }

      this.fundingPreferenceStore.additionalBorrowerEntityMembers = members;

      this.closeDialog();
    }
  }
}
