import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import React, { ReactNode } from 'react';
import { FieldLabel } from '../fieldLabel/fieldLabel';
import { TestDataAttribute } from '../testDataAttribute';

export const CFPBRuralOptions = [
  { label: 'CFPB not rural', value: 'N' },
  { label: 'CFPB rural', value: 'Y' },
];

export const yesNoOptions = [
  { label: 'Yes', value: 'Y' },
  { label: 'No', value: 'N' },
];

export const trueFalseOptions = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

export const brokerTypeOptions = [
  { label: 'On the HUD', value: 'ON_THE_HUD' },
  { label: 'Off the HUD', value: 'OFF_THE_HUD' },
]

export interface RadioFieldProps {
  label?: string | ReactNode;
  name?: string;
  value?: string | boolean | null;
  onChange?: (value: any) => void;
  options?: Array<{ value: any; label: string | React.ReactNode }>;
  disabled?: boolean;
  required?: boolean;
  readOnly?: boolean;
  error?: boolean;
  errorText?: string;
  fullWidth?: boolean;
  row?: boolean;
  standaloneLabel?: boolean;
  needHtml?: boolean;
  testId: string;
  tooltip?: string;
  openToolTip?: any;
  onClick?: any;
  className?: string;
  useCustomTooltip?: boolean;
}

const _RadioField: React.FC<RadioFieldProps> = ({
  label,
  name,
  value,
  onChange,
  options,
  error,
  errorText,
  disabled,
  required,
  fullWidth,
  needHtml,
  row,
  testId,
  className,
}) => {

  const CustomHtmlLabel = ({ htmlContent }) => (
    <span dangerouslySetInnerHTML={{ __html: htmlContent }} />
  );

  return (
    <TestDataAttribute id={`${testId}-radiogroup`}>
      <FormControl
        fullWidth={fullWidth}
        disabled={disabled}
        required={required}
        error={error}
      >
        {label && <FormLabel>{label}</FormLabel>}
        <RadioGroup
          row={row}
          name={name}
          value={value}
          onChange={event => onChange && onChange(event.target.value)}
          className={className}
        >
          {options.map((option, index) => {
            const { label } = option;
            return (
              <TestDataAttribute id={`${testId}-${index}`} key={`option-${option.value}`}>
                {needHtml ?
                  <FormControlLabel
                    value={option.value}
                    label={<CustomHtmlLabel htmlContent={label} />}
                    control={<Radio color="primary" />}
                    className={value == option.value ? 'isSelected' : ''} />
                  :
                  <FormControlLabel
                    value={option.value}
                    label={label}
                    control={<Radio color="primary" />}
                    className={value == option.value ? 'isSelected' : ''} />}

              </TestDataAttribute>
            );
          })}
        </RadioGroup>
        <FormHelperText>{errorText}</FormHelperText>
      </FormControl>
    </TestDataAttribute>
  );
};

export const RadioField = (props: RadioFieldProps) => {
  const { standaloneLabel, openToolTip, tooltip, needHtml, onClick, useCustomTooltip, ...muiComponentProps } = props;
  return (
    <>
      {standaloneLabel && muiComponentProps.label ? (
        <FieldLabel tooltip={tooltip} open={openToolTip} onClick={onClick} useCustomTooltip={useCustomTooltip}>
          {muiComponentProps.label}
          {muiComponentProps.required && ' *'}
        </FieldLabel>
      ) : null}
      <_RadioField
        {...muiComponentProps}
        testId={props.testId}
        needHtml={needHtml}
        label={
          standaloneLabel
            ? undefined
            : muiComponentProps.label
        }
      />
    </>
  );
};
