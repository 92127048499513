import { Page } from '@roc/feature-app-core';
import { Route } from 'react-router';
import BorrowerSetupToDoInternal from './borrowerSetupToDoInternal';

export const getBorrowerSetupToDoInternalRoutesConfig = (
  basePath: string,
  baseUrl: string,
) => {
  return {
    borrowerSetup: {
      path: `${basePath}/borrower-setup`,
      url: `${baseUrl}/borrower-setup`,
      documentTitle: 'Borrower Setup',
    },
  };
};

export const getBorrowerSetupToDoInternalRoutes = (
  basePath: string,
  baseUrl: string,
  loanId: any,
  borrowerId: any,
  lenderId: any,
  loanType: any,
  borrowerEntityId: any,
) => {
  const config = getBorrowerSetupToDoInternalRoutesConfig(basePath, baseUrl);

  return {
    borrowerSetup: (
      <Route exact path={config.borrowerSetup.path}>
        <Page routeDefinition={config.borrowerSetup}>
          <BorrowerSetupToDoInternal
            loanId={loanId}
            borrowerId={borrowerId}
            lenderId={lenderId}
            borrowerEntityId={borrowerEntityId}
          />
        </Page>
      </Route>
    ),
  };
}