import { Service } from '@roc/client-portal-shared/services';

const url = {
  DOWNLOAD_DOCUMENT_INTERNAL: '/nportal/rest/priv/loanDocs/downloadLoanDocument',
  UPLOAD_DOCUMENT_INTERNAL: '/nportal/rest/priv/borrower/uploadCreditReport',
  CHECK_CREDIT_REPORT_INTERNAL: '/nportal/rest/priv/borrower/checkCreditReport',
  REISSUE_CREDIT_REPORT_INTERNAL: '/nportal/rest/priv/borrower/reissueCreditReport',
  FILE_ID_REQUIRED_INTERNAL: '/nportal/rest/priv/flags/getFlagByKey',
  FILE_ID_REQUIRED: '/api/v1/loan/isCreditReportFileIdRequired',
  UPLOAD_DOCUMENT: '/api/v1/loan/uploadCreditReportExternal',
  CHECK_CREDIT_REPORT: '/api/v1/loan/checkCreditReportExternal',
  REISSUE_CREDIT_REPORT: '/api/v1/loan/reissueCreditReportExternal',
};

export class CreditReportService extends Service {

  downloadDocumentInternal(document) {
    return this.post(url.DOWNLOAD_DOCUMENT_INTERNAL, document, null, {
      responseType: 'arraybuffer',
    });
  };

  uploadFile(documentId: number, formData, internal: boolean) {
    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }
    return this.post(`${internal ? url.UPLOAD_DOCUMENT_INTERNAL : url.UPLOAD_DOCUMENT}?loanDocumentId=${documentId}`, formData, config);
  }

  getCheckCreditReport(documentId: number, internal: boolean) {
    return this.get(`${internal ? url.CHECK_CREDIT_REPORT_INTERNAL : url.CHECK_CREDIT_REPORT}?loanDocumentId=${documentId}`);
  }

  getReissueCreditReport(documentId: number, fileIdNumber: number, internal: boolean, ssn: string) {
    return this.get(`${internal ? url.REISSUE_CREDIT_REPORT_INTERNAL : url.REISSUE_CREDIT_REPORT}?loanDocumentId=${documentId}&fileId=${fileIdNumber}${ssn ? `&ssn=${ssn}` : ''}`);
  }

  isFileIdRequired(key: string, internal: boolean) {
    return this.get(`${internal ? url.FILE_ID_REQUIRED_INTERNAL : url.FILE_ID_REQUIRED}?key=${key}`);
  }
}