import React, { useState } from 'react';
import { Grid, Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  AgGridColumnProps,
  Button,
  Paper,
  DataGrid as PayoffGrid, DropdownMenu
} from '@roc/ui';
import { RETRACTED_STAGE, APPROVED_STAGE } from '../constants/payoffs';
import { MoreVert, Replay, CloudDownload } from '@material-ui/icons';
import { useEffect } from 'react';
import { observer } from 'mobx-react';
import { formatDate } from '@roc/feature-utils';
import { usePayoffRequestStore } from '../hooks/usePayoffRequestStore';
import { useLoanStore } from '@roc/feature-loans-shared';
import { useBaseStore } from '@roc/feature-app-core';
import { PayoffRequestDialog } from './payoffRequestDialog';
import { PayoffDetails } from './payoffDetails';
import PayoffLoanInformation from './payoffLoanInformation';
import { PayoffRetractDialog } from './payoffRetractDialog';
import { useDocumentStore } from '@roc/feature-documents';
import { DocumentName } from '@roc/feature-documents';
import { DefaultAllocationDashboard } from './defaultAllocationDashboard';
import { ForeclosureInterestAllocationDashboard } from './foreclosureAllocationDashboard';
import { insertIf } from '@roc/feature-utils';
import { LoanPayoffDetailstDialog } from './loanPayoffDetailsDialog';
import { ServicerFees } from './servicerFees';

const noOpComparator = () => 0;

const useStyles = makeStyles(theme => ({
  paper: {
    padding: 24,
  },
  gridContainer: {
    padding: '24px 8px',
  },
  dealName: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 40,
  },
  alert: {
    textAlign: 'center',
  },
  alertContainer: {
    paddingTop: 20,
  },
}));

export const PayoffDashboard = observer(({ loanId }: { loanId: string }) => {
  const {
    payoffRequestStore,
    payoffRequestStore: { loanPayoffs, currentPayoff, disableCreatePayoff },
  } = usePayoffRequestStore();
  const { documentStore } = useDocumentStore();
  const {
    loanStore: { loanDetails },
  } = useLoanStore();
  const classes = useStyles();
  const [
    isNewPayoffRequestModalOpen,
    setIsNewPayoffRequestModalOpen,
  ] = useState(false);
  const [
    isNewPayoffRetractModalOpen,
    setIsNewPayoffRetractModalOpen,
  ] = useState(false);
  const { globalStore } = useBaseStore();
  useEffect(() => {
    payoffRequestStore.getActivePayoffDataByLoanId(loanId);
    payoffRequestStore.getPayoffsByLoanId(loanId);
  }, [loanId, payoffRequestStore]);
  const isServicer = globalStore?.userFeatures?.isServicer;
  const isClosingAttorney = globalStore?.userFeatures?.isClosingAttorney;
  const [
    isNewForeclosureRequestModalOpen,
    setIsNewForeclosureRequestModalOpen,
  ] = useState(false);
  const showForeclosureAllocation = isServicer && payoffRequestStore?.isForeclosure;
  const showDefaultAllocation = isServicer && !payoffRequestStore?.isForeclosure;



  useEffect(() => {
    if (currentPayoff) {
      payoffRequestStore.getPayoffsByLoanId(loanId);
    }
  }, [currentPayoff, payoffRequestStore, loanId]);


  useEffect(() => {
    return () => {
      payoffRequestStore.setIsForeclosure(false);
    };
  }, []);

  const showActivePayoff = () => {
    if (payoffRequestStore.currentPayoff)
      return <PayoffDetails loanId={loanId} />;
  };

  const downloadFinalPayoffReport = () => {
    const loanDocumentJson = {
      loanId: loanId,
      documentName: DocumentName.FINAL_PAYOFF_REPORT,
    };
    documentStore.downloadDocumentByName(loanDocumentJson);

  };

  const openRetractPayoff = (payoff) => {
    if (payoff.payoffStatus !== RETRACTED_STAGE)
      setIsNewPayoffRetractModalOpen(true);
  };

  const handleCreatePayoffRequest = () => {
    if (!payoffRequestStore.currentPayoff) {
      if (isServicer && payoffRequestStore?.isForeclosure) {
        setIsNewForeclosureRequestModalOpen(true);
      } else {
        setIsNewPayoffRequestModalOpen(true);
      }
    } else {
      globalStore.notificationStore.showErrorNotification({
        message: 'There is an active payoff for this loan.',
      });
    }
  };

  const payoffColumns: AgGridColumnProps[] = [
    {
      field: 'goodThroughDate',
      headerName: 'Good Through Date',
      minWidth: 100,
      cellRenderer: 'dateCellRenderer',
      comparator: noOpComparator,
      sortable: false,
    },
    {
      field: 'payoffStatus',
      headerName: 'Status',
      minWidth: 100,
      comparator: noOpComparator,
      sortable: false,
    },
    {
      field: 'requestedByFullName',
      headerName: 'Requested By',
      minWidth: 100,
      comparator: noOpComparator,
      sortable: false,
    },
    {
      field: 'requestedDate',
      headerName: 'Requested Date',
      minWidth: 100,
      cellRenderer: 'dateCellRenderer',
      comparator: noOpComparator,
      sortable: false,
    },
    {
      field: 'loanPayoffDataId',
      headerName: 'Actions',
      minWidth: 50,
      cellRenderer: 'actionCellRenderer',
    },
  ];

  const frameworkComponents = {
    dateCellRenderer: ({ value }) => {
      return value ? formatDate(value, 'MM/dd/yyyy') : '';
    },
    actionCellRenderer: params => {
      const data = params.node.data;
      return (
        <>
          <DropdownMenu options={getActions(data)}>
            <MoreVert />
          </DropdownMenu>
        </>
      );
    },
  };

  const getActions = (data) => {
    const actionList = [];
    if (data?.payoffStatus !== RETRACTED_STAGE) {
      actionList.push(
        {
          icon: Replay,
          name: 'Reject',
          action: () => openRetractPayoff(data),
        }
      );
      if (data?.payoffStatus === APPROVED_STAGE) {
        actionList.push(
          {
            icon: CloudDownload,
            name: 'Download Final Report',
            action: () => downloadFinalPayoffReport(),
          }
        );
      }
    }
    return actionList;
  };

  return (
    <>
      <Grid direction="row" spacing={3} xs={12} md={12} style={{
        margin: 0
      }}>
        {showActivePayoff()}
        <PayoffLoanInformation />
      </Grid>
      {[
        ...insertIf((isServicer && payoffRequestStore?.currentPayoff !== null), [
          <ServicerFees loanId={loanId} store={payoffRequestStore} />
        ]),
      ]}
      {[
        ...insertIf(showForeclosureAllocation && (payoffRequestStore?.currentPayoff !== null), [
          <ForeclosureInterestAllocationDashboard loanId={loanId} />
        ]),
      ]}
      {[
        ...insertIf(showDefaultAllocation && (payoffRequestStore?.currentPayoff !== null), [
          <DefaultAllocationDashboard loanId={loanId} />
        ]),
      ]}
      <Grid item xs={12} md={12} className={classes.gridContainer}>
        <Paper className={classes.paper}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Typography variant="h5">PAYOFFS</Typography>
                </Grid>
                <Grid item>
                  <Box display="flex" justifyContent="flex-end">
                    <Button
                      testId="createPayoffRequest"
                      variant="contained"
                      size="small"
                      color="primary"
                      disabled={disableCreatePayoff}
                      onClick={handleCreatePayoffRequest}
                    >
                      Create Payoff Request
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <PayoffGrid
              columns={payoffColumns}
              rows={loanPayoffs}
              frameworkComponents={frameworkComponents}
              domLayout="autoHeight"
            />
          </Grid>
        </Paper>
      </Grid>

      <PayoffRequestDialog
        loanId={loanId}
        open={isNewPayoffRequestModalOpen}
        onClose={() => {
          setIsNewPayoffRequestModalOpen(false);
        }}
      />
      <PayoffRetractDialog
        loanId={loanId}
        open={isNewPayoffRetractModalOpen}
        onClose={() => {
          setIsNewPayoffRetractModalOpen(false);
        }}
      />
      <LoanPayoffDetailstDialog loanId={loanId} open={isNewForeclosureRequestModalOpen} onClose={() => {
        setIsNewForeclosureRequestModalOpen(false);
      }} />
    </>
  );
});
