import { makeStyles, Theme } from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import {
  LoanCommunicationStore,
  MyChatContainer,
  SelectConversation
} from '@roc/feature-communication';
import { StandardDialog } from '@roc/ui';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Route, Switch } from 'react-router';

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    '& .MuiDialog-paper': {
      height: 'min(100%,1200px)',
    },
    '& .MuiDialogContent-root': {
      height: '100%',
      padding: 0,
    },
    '& .MuiDialogTitle-root': {
      padding: 0,
      zIndex: 2,
    },

    '& button[aria-label="close"]': {
      top: '1px',
    },
  },
  chatContainer: {
    overflow: 'auto',
    flexGrow: 1,

    '& .cs-conversation-header': {
      paddingRight: '50px',
    },

    '& .cs-main-container': {
      borderColor: 'transparent',
    },
  },
}));

interface ConversationsDialogProps {
  open: boolean;
  loanId: number;
  onClose: () => void;
  communicationStore: LoanCommunicationStore;
  onConversationSelect?: (conversationId: string) => void;
  hideSidebar?: boolean;
  allowManageParticipants?: boolean;
  allowNewConversations?: boolean;
  allowAddParticipants?: boolean;
  allowAddInternalParticipants?: boolean;
  allowRemoveParticipants?: boolean;
  allowJoinConversation?: boolean;
  showSnippet?: boolean;
  showEmailAddress?: boolean;
  dialogClass?: string;
  routePath: string;
  onOpenAddTask?: (userId: number) => void;
}

export const ConversationsDialog = observer(({
  open,
  loanId,
  onClose,
  communicationStore,
  onConversationSelect,
  hideSidebar = false,
  allowManageParticipants = true,
  allowNewConversations = true,
  allowAddParticipants = true,
  allowAddInternalParticipants = true,
  allowRemoveParticipants = true,
  allowJoinConversation = true,
  showSnippet = true,
  showEmailAddress = true,
  dialogClass,
  routePath,
  onOpenAddTask
}: ConversationsDialogProps) => {
  const classes = useStyles();
  const [enableClose, setEnableClose] = useState(false);

  const onCloseModal = () => {
    communicationStore.reset();
    onClose();
  }

  useEffect(() => {
    return () => {
      communicationStore.reset();
    }
  }, [])

  const dialogContent = (
    <>
      <MyChatContainer
        id={loanId}
        communicationStore={communicationStore}
        className={classes.chatContainer}
        hideSidebar={hideSidebar}
        allowManageParticipants={allowManageParticipants}
        allowNewConversations={allowNewConversations}
        allowAddParticipants={allowAddParticipants}
        allowAddInternalParticipants={allowAddInternalParticipants}
        allowRemoveParticipants={allowRemoveParticipants}
        allowJoinConversation={allowJoinConversation}
        showSnippet={showSnippet}
        showEmailAddress={showEmailAddress}
        onConversationSelect={onConversationSelect}
        onUserNewConversation={() => { }}
        onConversationsLoaded={() => {
          setEnableClose(true)
        }}
        onOpenAddTask={onOpenAddTask}
      />
      <Switch>
        <Route path={routePath}>
          <SelectConversation communicationStore={communicationStore} />
        </Route>
      </Switch>
    </>

  );
  return (
    <StandardDialog
      open={open}
      onClose={(e, reason) => {
        if (reason == 'backdropClick') return;
        onCloseModal();
      }}
      handleClose={enableClose ? onCloseModal : null}
      maxWidth={'lg'}
      dialogContent={dialogContent}
      className={clsx(classes.dialog, dialogClass ? dialogClass : '')}
      removeContentBox
      disableEscapeKeyDown={true}
    />
  );
});
