import { GlobalStore, FormStore, UserStore, EnvironmentStore, WhiteLabelStore } from '@roc/feature-app-core';
import {
  PortalConfiguration,
  PortalConfigurationData,
} from '@roc/feature-types';
import { action, computed, flow, makeObservable, observable } from 'mobx';
import { FileUpload } from '@roc/ui';
import { ApiResponse } from '@roc/feature-app-core';
import { UploadType, WhiteLabelState } from '../whiteLabel';
import { PortalConfigurationService } from '../services/portalConfigurationService';
import { isElectronApp, isElectronAppLocalHost, isLocalHost, isMobileApp, isMobileAppLocalHost, isNil } from '@roc/feature-utils';

const basicInformationForm = {
  fields: {
    name: {
      value: '',
      error: null,
      rule: 'required',
    },
    fromEmail: {
      value: '',
      error: null,
      rule: 'required|email',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

const colorsForm = {
  fields: {
    primaryColor: {
      value: '',
      error: null,
      rule: 'required',
    },
    secondaryColor: {
      value: '',
      error: null,
      rule: 'required',
    },
    textColor: {
      value: '',
      error: null,
      rule: 'required',
    },
    appBarBackgroundColor: {
      value: '',
      error: null,
      rule: 'required',
    },
    appBarFontColor: {
      value: '',
      error: null,
      rule: 'required',
    },
    logoBackgroundColor: {
      value: '',
      error: null,
      rule: '',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};


class BasicInformationFormStore extends FormStore {
  globalStore: GlobalStore;
  portalConfigurationService: PortalConfigurationService;

  constructor(globalStore) {
    super({ ...basicInformationForm }, globalStore);
    this.portalConfigurationService = new PortalConfigurationService();
  }
}

class ColorsFormStore extends FormStore {
  globalStore: GlobalStore;
  portalConfigurationService: PortalConfigurationService;

  constructor(globalStore) {
    super({ ...colorsForm }, globalStore);
    this.portalConfigurationService = new PortalConfigurationService();
  }

}

export class WhiteLabelConfigStore {
  private globalStore: GlobalStore;
  private userStore: UserStore;
  whiteLabelStore: WhiteLabelStore;
  basicInformationFormStore: BasicInformationFormStore;
  colorsFormStore: ColorsFormStore;
  portalConfigurationService: PortalConfigurationService;
  whiteLabelState: WhiteLabelState;
  uploadType: UploadType;
  favIconDocumentId: string;
  appLogoDocumentId: string;
  bannerImageDocumentId: string;
  _portalConfigurationData: PortalConfigurationData
  subDomain: string;
  environmentStore: EnvironmentStore;

  constructor(globalStore, userStore, environmentStore, whiteLabelStore) {
    this.portalConfigurationService = new PortalConfigurationService();
    this.globalStore = globalStore;
    this.userStore = userStore;
    this.whiteLabelStore = whiteLabelStore;
    this.environmentStore = environmentStore;
    this.basicInformationFormStore = new BasicInformationFormStore(globalStore);
    this.colorsFormStore = new ColorsFormStore(globalStore);
    makeObservable(this, {
      requestPayload: computed,
      favIconDocumentId: observable,
      appLogoDocumentId: observable,
      bannerImageDocumentId: observable,
      whiteLabelState: observable,
      uploadType: observable,
      _portalConfigurationData: observable,
      subDomain: observable,
      setUploadType: action,
      setFavIconDocumentId: action,
      setAppLogoDocumentId: action,
      setBannerImageDocumentId: action,
      setSubDomain: action,
      hasWhiteLabeledConfig: flow,
      populateForm: action,
      saveSubDomain: flow,
      saveChanges: flow,
      discardChanges: flow,
      publishChanges: flow,
      clearDocument: flow,
      uploadDocument: flow,
    });
  }

  setUploadType(uploadType) {
    this.uploadType = uploadType;
  }

  setFavIconDocumentId(favIconDocumentId) {
    this.favIconDocumentId = favIconDocumentId;
  }

  setAppLogoDocumentId(appLogoDocumentId) {
    this.appLogoDocumentId = appLogoDocumentId;
  }

  setBannerImageDocumentId(bannerImageDocumentId) {
    this.bannerImageDocumentId = bannerImageDocumentId;
  }

  setSubDomain(subDomain) {
    this.subDomain = subDomain;
  }

  private loadPortalConfigurationForm(config: PortalConfigurationData) {
    const { generalConfiguration, uiThemeConfiguration } = config;
    this.basicInformationFormStore.loadForm({
      name: generalConfiguration?.name ?? '',
      fromEmail: generalConfiguration?.fromEmail ?? '',
    });
    this.colorsFormStore.loadForm({
      primaryColor: uiThemeConfiguration?.palette?.primary?.main ?? '',
      secondaryColor: uiThemeConfiguration?.palette?.secondary?.main ?? '',
      textColor: uiThemeConfiguration?.palette?.text?.primary ?? '',
      appBarBackgroundColor:
        uiThemeConfiguration?.themeOverrides?.appBar?.backgroundColor ?? '',
      appBarFontColor:
        uiThemeConfiguration?.themeOverrides?.appBar?.color ?? '',
      logoBackgroundColor:
        uiThemeConfiguration?.themeOverrides?.appBar?.logoBackgroundColor ?? '',
    });
    this.favIconDocumentId = generalConfiguration?.favIconDocumentId;
    this.appLogoDocumentId = generalConfiguration?.appLogoDocumentId;
    this.bannerImageDocumentId = generalConfiguration?.bannerImageDocumentId;
    this._portalConfigurationData = config;
  }

  *hasWhiteLabeledConfig() {
    try {
      const apiResponse: ApiResponse = yield this.portalConfigurationService.hasWhiteLabeledConfig(
        this.userStore.userInformation.companyId ?? this.globalStore?.selectedCompanyId
      );
      const result: boolean =
        apiResponse.data.data;
      this.whiteLabelState = result ? WhiteLabelState.HAS_WHITE_LABEL_CONFIGURATION : WhiteLabelState.DOMAIN_NOT_SETUP;
    } catch (e) {
      console.error("Error in hasWhiteLabeledConfig");
      console.error(e);
    }
  }

  populateForm() {
    const portalConfigurationData: PortalConfigurationData = this.globalStore
      .portalConfiguration.portalConfiguration;
    const previewPortalConfiguration: PortalConfigurationData = this.globalStore
      .portalConfiguration.previewPortalConfiguration;
    this.basicInformationFormStore.reset();
    this.colorsFormStore.reset();
    this.loadPortalConfigurationForm(
      previewPortalConfiguration ?? portalConfigurationData
    );
  }

  *saveSubDomain() {
    try {
      yield this.portalConfigurationService.saveSubDomain({
        companyId: this.userStore.userInformation.companyId ?? this.globalStore?.selectedCompanyId,
        subDomain: this.subDomain,
      });
    } catch (e) {
      let errorMessage = 'Error creating subdomain';
      if (e?.error?.response?.data?.error?.errorCode == 'SUBDOMAIN_RESERVED') {
        errorMessage = e?.error?.response?.data?.error?.message ?? errorMessage;
      }
      this.globalStore.notificationStore.showErrorNotification({
        message: errorMessage,
      });
      throw e;
    }
  }

  *saveChanges() {
    try {
      const apiResponse: ApiResponse = yield this.portalConfigurationService.saveChanges(
        this.requestPayload
      );
      const portalConfigurationResponse: PortalConfiguration =
        apiResponse.data.data;
      this.globalStore.setPortalConfiguration(portalConfigurationResponse);
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Changes saved successfully',
      });
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error saving portal configuration changes',
      });
      throw e;
    }
  }

  *discardChanges() {
    try {
      const { id } = this.globalStore.portalConfiguration;
      const apiResponse: ApiResponse = yield this.portalConfigurationService.discardChanges(
        id
      );
      const portalConfigurationResponse: PortalConfiguration =
        apiResponse.data.data;
      this.globalStore.setPortalConfiguration(portalConfigurationResponse);
      this.populateForm();
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Changes discarded successfully',
      });
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error discarding portal configuration changes',
      });
    }
  }

  *publishChanges() {
    try {
      yield this.saveChanges();
      const { id } = this.globalStore.portalConfiguration;
      yield this.portalConfigurationService.publishChanges(id);
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Changes published successfully',
      });
      window.location.reload();
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error publishing portal configuration changes',
      });
    }
  }

  *uploadDocument(fileUpload: FileUpload, uploadType: UploadType) {
    try {
      const file = fileUpload.file;
      const blob = file.slice(0, file.size, file.type);
      const newFile = new File([blob], fileUpload.name, { type: file.type });
      const apiResponse: ApiResponse = yield this.portalConfigurationService.uploadDocument(
        newFile
      );
      const documentId = apiResponse.data.data.id;
      if (uploadType == UploadType.FAV_ICON) {
        this.setFavIconDocumentId(documentId);
      } else if (uploadType == UploadType.APP_LOGO) {
        this.setAppLogoDocumentId(documentId);
      } else if (uploadType == UploadType.BANNER_IMAGE) {
        this.setBannerImageDocumentId(documentId);
      }
      this.setUploadType(undefined);
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'File uploaded successfully',
      });
      yield this.saveChanges();
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error uploading file ' + uploadType,
      });
    }
  }

  *clearDocument(uploadType: UploadType) {
    try {
      if (uploadType == UploadType.FAV_ICON) {
        this.setFavIconDocumentId('');
      } else if (uploadType == UploadType.APP_LOGO) {
        this.setAppLogoDocumentId('');
      } else if (uploadType == UploadType.BANNER_IMAGE) {
        this.setBannerImageDocumentId('');
      }
      this.setUploadType(undefined);
      yield this.saveChanges();
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error saving changes - ' + uploadType,
      });
    }
  }

  get requestPayload() {
    const { isDefault, host } = this.globalStore.portalConfiguration;
    const { uiThemeConfiguration } = this._portalConfigurationData;
    const {
      name,
      fromEmail,
    } = this.basicInformationFormStore.getFormValues();
    const {
      primaryColor,
      secondaryColor,
      textColor,
      appBarBackgroundColor,
      appBarFontColor,
      logoBackgroundColor,
    } = this.colorsFormStore.getFormValues();

    const payload = {
      companyId: this.userStore.userInformation.companyId ?? this.globalStore?.selectedCompanyId,
      isDefault: isDefault,
      host: host,
      generalConfiguration: {
        name,
        fromEmail,
        gtmTrackingId: '',
        favIconDocumentId: this.favIconDocumentId,
        appLogoDocumentId: this.appLogoDocumentId,
        bannerImageDocumentId: this.bannerImageDocumentId,
        slogan: '',
      },
      uiThemeConfiguration: {
        palette: {
          text: {
            primary: textColor,
          },
          primary: {
            main: primaryColor,
          },
          secondary: {
            main: secondaryColor,
          },
        },
        typography: {
          fontFamily: uiThemeConfiguration?.typography?.fontFamily ?? '',
          h1: {
            fontFamily: uiThemeConfiguration?.typography?.h1?.fontFamily ?? '',
          },
          h2: {
            fontFamily: uiThemeConfiguration?.typography?.h2?.fontFamily ?? '',
          },
          h3: {
            fontFamily: uiThemeConfiguration?.typography?.h3?.fontFamily ?? '',
          },
          h4: {
            fontFamily: uiThemeConfiguration?.typography?.h4?.fontFamily ?? '',
          },
          h5: {
            fontFamily: uiThemeConfiguration?.typography?.h5?.fontFamily ?? '',
          },
          h6: {
            fontFamily: uiThemeConfiguration?.typography?.h6?.fontFamily ?? '',
          },
          button: {
            fontFamily: uiThemeConfiguration?.typography?.button?.fontFamily,
            fontWeight: uiThemeConfiguration?.typography?.button?.fontWeight,
            letterSpacing: uiThemeConfiguration?.typography?.button?.letterSpacing,
          },
        },
        themeOverrides: {
          appBar: {
            backgroundColor: appBarBackgroundColor,
            color: appBarFontColor,
            logoBackgroundColor: logoBackgroundColor,
          },
          button: {
            borderRadius: uiThemeConfiguration?.themeOverrides?.button?.borderRadius,
          },
        },
        fontFamilies: uiThemeConfiguration?.fontFamilies ?? [],
      },
    };
    return payload;
  }

  get host() {
    return `https://${this.subDomain}.privatelenderportal.com`;
  }
}
