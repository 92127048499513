import { Service } from '@roc/feature-app-core';

export const url = {
  REFRESH_TOKEN: '/api/v1/oauth2/token',
  VERIFY_TWO_FACTOR: '/api/v1/oauth2/token/2fa',
};

export class AuthenticationService extends Service {

  refreshToken(disableGlobalLoading = true) {
    return this.post(
      url.REFRESH_TOKEN,
      {
        grant_type: 'refresh_token',
      },
      undefined,
      {
        disableGlobalLoading,
      }
    );
  }

  verifyTwoFactor(code: string) {
    return this.post(
      url.VERIFY_TWO_FACTOR,
      {
        code
      }, {},
      {
        disableGlobalLoading: true
      }
    );
  }
}
