import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useBaseStore } from '@roc/feature-app-core';
import { StandardDialog, StandardDialogProps } from '@roc/ui';
import { useEffect } from 'react';
import { useRocInternalPortalStore } from '../hooks';
const useStyles = makeStyles((theme: Theme) => ({
  '@global': {
    body: {
      backgroundColor: 'transparent !important',
    },
  },
}));
type InternalPortalModalProps = Omit<StandardDialogProps, 'open'>;
export const InternalPortalModal = (props: InternalPortalModalProps) => {
  const { rocInternalPortalModalStore } = useRocInternalPortalStore();
  useStyles();
  useEffect(() => {
    rocInternalPortalModalStore.modalReady();
  }, []);
  return (
    <StandardDialog
      {...props}
      open={true}
      transitionDuration={0}
      handleClose={() => rocInternalPortalModalStore.closeModal()}
    />
  );
};
