import { observer } from 'mobx-react';
import { useEffect } from 'react';

const getOrigin = () => {
  try {
    return window.parent.location.origin;
  } catch (e) {
    return '*';
  }
}

export const PublicBorrowerSetupDocusignCallback = observer(() => {

  useEffect(() => {
    const { search } = location || {};
    if (search) {
      const origin = getOrigin();
      const parsed = search.replace('?', '').split('=');
      if (parsed[1] === 'signing_complete') {
        window.parent.postMessage(
          'borrower-details-verification-docusign-done',
          origin
        );
      } else {
        window.parent.postMessage(
          'borrower-details-verification-docusign-incomplete',
          origin
        );
      }
    }
  }, []);
  return <></>;
});