import { Service } from '@roc/client-portal-shared/services';

const url = {
  NEW_USER_REGISTRATION: '/api/v1/user/public/newUserRegistration',
  GET_REGISTRATION_ENABLED: '/api/v1/user/public/getRegistrationEnabled'
};

export class RegisterService extends Service {
  register(data) {
    const _url = url.NEW_USER_REGISTRATION;
    return this.post(_url, data);
  }

  getRegistrationEnabled() {
    const _url = url.GET_REGISTRATION_ENABLED;
    return this.get(_url);
  }
}
