// ----------------------------------------------------------------------

export default function Table(theme) {
  return {
    MuiTableCell: {
      head: {
        color: '#111',
        backgroundColor: '#fafafa',
      },
    },
  };
}
