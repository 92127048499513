export interface Document {
  borrowerEntityId: any;
  borrowerId: any;
  borrowerName: any;
  collateralAddress: any;
  collateralId: any;
  createdBy: string;
  creationDate: Date;
  customDropboxPath: any;
  displayOrder: number;
  docType: any;
  documentInformation: any;
  documentName: string;
  dropboxPath: any;
  dropboxRev: any;
  keyDocument: boolean;
  lastAcceptedBy: any;
  lastAcceptedDate: any;
  lastUpdateDate: Date;
  lastUpdatedBy: string;
  lastUploadBy: any;
  lastUploadDate: any;
  loanDocumentId: number;
  loanId: number;
  movedToDropbox: boolean;
  multipleUpload: boolean;
  originalFileName: any;
  requiredForLoanApproval: boolean;
  sectionId: number;
  sectionName: string;
  springcmId: any;
  stage: any;
  status: string;
  streetName: any;
  streetNumber: any;
  synchronizedWithBorrowerDocs: boolean;
  drawId: number;
  // Autocalculated properties
  isForm: boolean;
  canOpenForm: boolean;
  canUploadFile: boolean;
  canDownloadFile: boolean;
  documentNameSecondLine: string;
  isDrawRequestForm: boolean;
  canRequestMoreInformation: boolean
}

export interface DocumentSection {
  sectionId: number;
  sectionName: string;
  loanProcess: string;
  displayOrder: number;
  borrowerSection: boolean;
  entitySection: boolean;
  propertySection: boolean;
}

export interface LoanDocument {
  borrowerId: number | null;
  collateralId: number | null;
  customDropboxPath: any;
  documentName: string;
  isMovedToDropbox: boolean;
  loanDocumentId: number | null;
  loanId: number;
  requiredForLoanApproval: boolean;
  sectionId: number;
  drawId: number;
}

interface UnreadDocumentsCommentsBySection {
  [key: string]: number;
}

export interface UnreadDocumentsComments {
  totalUnread: number;
  unreadCountBySection: UnreadDocumentsCommentsBySection;
}

export enum DocumentVerificationState {
  NOT_STARTED,
  VERIFIED,
  IN_REVIEW,
  FAILED,
}