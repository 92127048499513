import { observer } from 'mobx-react';
import { DataGrid as Grid } from '@roc/ui';
import { useBrokersStore } from '@roc/feature-brokers';
import { useEffect } from 'react';

export const BrokerContactsGrid = observer(({ columns, brokerId }) => {
  const { brokersStore } = useBrokersStore();
  const onSortChanged = params => {
    const sortModel = params.api.getSortModel();
    brokersStore.brokerContactsSummaryGridStore.setSortModel(sortModel);
  };
  useEffect(() => {
    brokersStore.brokerContactsSummaryGridStore.setBrokerId(brokerId);
    brokersStore.brokerContactsSummaryGridStore.fetchGridData();
  }, [brokersStore]);

  return (
    <Grid
      columns={columns}
      rows={brokersStore.brokerContactsSummaryGridStore.gridData.data.rows}
      onSortChanged={onSortChanged}
      isLoading={brokersStore.brokerContactsSummaryGridStore.isLoading}
      sortModel={brokersStore.brokerContactsSummaryGridStore.sortModel}
      domLayout="autoHeight"
      paginationData={brokersStore.brokerContactsSummaryGridStore.gridData.meta}
      setPageNumber={brokersStore.brokerContactsSummaryGridStore.setPageNumber}
    />
  );
});
