import { Box, Grid, Modal } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { RotateLeft, RotateRight, ZoomIn, ZoomOut } from "@material-ui/icons";
import { useState } from "react";
import Carousel from 'react-material-ui-carousel';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    color: '#5A5A5A',
  },
  iconsContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  iconContainer: {
    cursor: 'pointer',
    backgroundColor: 'white',
    opacity: '50%',
    width: 24,
    height: 24,
    margin: 4,
  },
}));

export const ImageCarousel = (props) => {
  const classes = useStyles();
  const [rotateDeg, setRotateDeg] = useState<number>(0)
  const rotateString = 'rotate(' + rotateDeg + 'deg)';

  const rotateLeft = () => {
    setRotateDeg(rotateDeg - 90);
  }

  const rotateRight = () => {
    setRotateDeg(rotateDeg + 90);
  }

  const getSelectedImage = () => {
    const selectedImage = props.selectedImage;
    const index = props?.store?.pictures.indexOf(selectedImage);
    return index;
  }

  return (
    <Box style={{
      width: '90vw',
    }}
    >
      <Carousel
        autoPlay={false}
        cycleNavigation={false}
        animation="slide"
        navButtonsAlwaysVisible={true}
        index={getSelectedImage()}
      >
        {props?.store?.pictures?.map((image: any, index: number) => {
          return (
            <div style={{ display: 'flex', justifyContent: 'center' }} key={index}>
              <TransformWrapper initialScale={1}>
                {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                  <Grid>
                    <div className={classes.iconsContainer}>
                      <div className={classes.iconContainer}>
                        <ZoomIn onClick={() => zoomIn()} />
                      </div>
                      <div className={classes.iconContainer}>
                        <ZoomOut onClick={() => zoomOut()} />
                      </div>
                      <div className={classes.iconContainer}>
                        <RotateLeft onClick={() => rotateLeft()} />
                      </div>
                      <div className={classes.iconContainer}>
                        <RotateRight onClick={() => rotateRight()} />
                      </div>
                    </div>
                    <TransformComponent>
                      <img src={image.image}
                        width={1000}
                        style={{ alignItems: 'middle', transform: rotateString }}
                      />
                    </TransformComponent>
                  </Grid>
                )}
              </TransformWrapper>
            </div>
          )
        })}
      </Carousel>
    </Box>
  )

};

export default ImageCarousel;