import { ConfirmDialog } from '@roc/ui';
import { observer } from 'mobx-react';
import { useLoanActionsStore } from '../hooks/useLoanActionsStore';

export const MarkClosingDocumentsSentForSigningModal = observer(
  ({ open, handleClose }) => {
    const { loanActionsStore } = useLoanActionsStore();

    const confirm = () => {
      loanActionsStore.markClosingDocumentsSentForSigning();
      handleClose();
    };

    return (
      <ConfirmDialog
        open={open}
        confirmButtonText={'Yes'}
        handleClose={handleClose}
        handleCancel={handleClose}
        handleConfirm={confirm}
        title={'Confirm closing documents sent for signing'}
        body={`Are you sure you want to confirm that the closing documents have been sent for signing?`}
      />
    );
  }
);
