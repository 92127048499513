import { Grid, Typography } from '@material-ui/core';
import { Button } from '@roc/ui';
import { Add } from '@material-ui/icons';
import { observer } from 'mobx-react';
import { QuoteTypeStore } from '../../stores/quoteTypeStore';
import { BorrowerInformationCard } from './components/borrowerInformationCard';
import { StepTitle } from '../../components/stepTitle';
import { InterestRateSlider } from '../loanEconomics/components/interestRateSlider';

interface BorrowerInformationStepProps {
  store: QuoteTypeStore;
}

export const BorrowerInformationStep = observer(
  ({ store }: BorrowerInformationStepProps) => {
    const { borrowersStore } = store;
    const borrowerFormStores = borrowersStore.borrowerFormStores;

    function handleAddMoreBorrowers() {
      borrowersStore.addNewBorrower();
    }

    function handleDeleteBorrower(borrowerKey) {
      borrowersStore.removeBorrower(borrowerKey);
    }

    const removeButtonDisabled = borrowerFormStores.length <= 1;
    //const displayPGField = borrowerFormStores?.length > 1;
    const displayPGField = true;

    return (
      <>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <StepTitle
              title={<>Borrower Information</>}
              subtitle={
                <>
                  This quote will be generated based on the information you
                  input here. This quote may not be accurate if the borrower's
                  actual credit and/or experience level differs from your
                  inputs. Quotes are non-binding estimates and are always
                  subject to underwriting.
                </>
              }
            />
          </Grid>
          {borrowerFormStores.map(borrowerFormStore => (
            <Grid item xs={12} key={borrowerFormStore.key}>
              <BorrowerInformationCard
                borrowerFormStore={borrowerFormStore}
                onDeleteClick={handleDeleteBorrower}
                removeButtonDisabled={removeButtonDisabled}
                displayPGField={displayPGField}
              />
            </Grid>
          ))}
          <Grid
            item
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <Button
                size="medium"
                variant="outlined"
                color="primary"
                startIcon={<Add />}
                testId="edit-borrower"
                onClick={handleAddMoreBorrowers}
              >
                ADD MORE BORROWERS
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }
);
