import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Layout } from '@roc/client-portal-shared/components';
import { observer } from 'mobx-react';
import { Paper, ConfirmDialog, Button, StandardDialog, DateField } from '@roc/ui';
import { useEffect, useState, useMemo } from 'react';
import {
  PaperList,
  PaperListItem,
} from './escrowDepositCalculatorComponents'
import { AutocompleteField, VerticalBarLoader } from '@roc/ui';
import { debounce } from '@roc/client-portal-shared/utils';
import { EscrowDepositCalculatorBaseStore } from '../stores/escrowDepositCalculatorBaseStore';
import { ExpectedClosingDate, FirstPaymentDueDate, NextPaymentDueDateDateField, NumberOfMonthsPaymentCovers, PaymentsPriorToDue, StateSelectField, TaxEscrowMonthsOverride } from './escrowCalculatorFields';

interface EscrowDepositSectionProps {
  store: EscrowDepositCalculatorBaseStore;
  loanId?: any;
  loanDocumentId?: any;
  refreshAndClose?: () => void;
}

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  tabs: {},
  calculatedValuesBorder: {
    border: '1px solid #BEBEBE',
    borderRadius: '15px',
    padding: 16,
  },
}));



export const EscrowDepositCalculator = observer(
  (props: EscrowDepositSectionProps) => {
    const [isModifyAndUpdate, setIsModifyAndUpdate] = useState(false);
    const [showModifyPopup, setShowModifyPopup] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isCalculated, setIsCalculated] = useState(false);

    const classes = useStyles();
    const escrowDepositCalculatorStore = props.store;
    const formStore = escrowDepositCalculatorStore;
    const loanId = props.loanId;
    const loanDocumentId = props.loanDocumentId;

    const updateLoan = (taxEscrowMonths) => {
      escrowDepositCalculatorStore.updateLoan(
        formStore.form.fields.loanId.value,
        taxEscrowMonths,
        loanDocumentId,
        props.refreshAndClose
      );
    };

    const calculateTaxEscrowMonths = () => {
      setIsLoading(true);

      setTimeout(function () {
        setIsCalculated(true);
        formStore.calculateTaxEscrowMonths();
        setIsLoading(false);

      }, 1000);

    }

    useEffect(() => {
      if (loanId) {
        formStore.fetchLoanValues(loanId);
      }
    }, [loanId, formStore]);

    const fetchOptions = useMemo(
      () => debounce(searchText => formStore.fetchOptions(searchText), 500),
      [formStore]
    );

    return (
      <Layout title="Tax Escrow Deposit Calculator" maxWidth={'xl'}>
        <Paper className={classes.paper}>
          <Grid container spacing={4}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Grid container xs={12} spacing={2}>
                  <Grid item xs={12}>
                    {isLoading && <VerticalBarLoader />}
                    {!loanId && (<AutocompleteField
                      label=""
                      placeHolder="Select Loan Ids or Deal Names"
                      value={formStore.loanSearchText}
                      options={formStore.loanSearchOptions}
                      onChange={text => {
                        formStore.loanSearchText = text;
                        fetchOptions(formStore.loanSearchText);
                      }}
                      onSelect={opt => {
                        formStore.loanSearchText = opt.label;
                        formStore.fetchLoanValues(opt.value);
                      }}
                    />)}
                  </Grid>

                  <Grid item xs={12}>
                    <LoanValuesSection store={formStore} />
                  </Grid>

                  <Grid item xs={12} container justifyContent="center">

                    <Grid item xs={12}>
                      <Button
                        testId="calculate"
                        onClick={calculateTaxEscrowMonths}
                        fullWidth
                        color="primary"
                        variant="contained"
                        size="large"
                      >
                        {isCalculated ? `RECALCULATE` : `CALCULATE`}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid
                  container
                  spacing={2}
                  className={classes.calculatedValuesBorder}
                >
                  <Grid item xs={12}>
                    <Typography variant={'h4'}>
                      Calculated Tax Escrow Values
                    </Typography>
                  </Grid>
                  <CalculatedValues store={formStore} />
                  <Grid item xs={12} style={{ marginTop: '3px' }}></Grid>
                  <Grid item xs={12} container direction={'row'} justifyContent='flex-end' spacing={1}>
                    <Grid item xs={6} style={{ paddingLeft: '15px', paddingRight: '15px' }}>
                      <Button
                        testId="updateLoan"
                        onClick={() => updateLoan(formStore.form.fields.taxEscrowMonths.value)}
                        fullWidth
                        color="secondary"
                        variant="contained"
                        disabled={!isCalculated}
                        size="large"
                      >
                        SAVE CALCULATED ESCROW MONTHS
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        testId="updateLoan"
                        onClick={() => {
                          formStore.setTaxEscrowMonthsOverride(formStore.form.fields.taxEscrowMonths.value);
                          formStore.runFormValidation();
                          setShowModifyPopup(true);
                        }}
                        fullWidth
                        color="secondary"
                        variant="contained"
                        disabled={!isCalculated}
                        size="large"
                      >
                        MODIFY CALCULATED ESCROW MONTHS
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>

              </Grid>
            </Grid>
          </Grid>
          <ConfirmDialog
            open={isModifyAndUpdate}
            title={`Confirmation`}
            body={`Are you sure you want to update the loan?`}
            handleCancel={() => setIsModifyAndUpdate(false)}
            handleClose={() => setIsModifyAndUpdate(false)}
            handleConfirm={() => {
              updateLoan(formStore.form.fields.taxEscrowMonthsOverride.value);
              setIsModifyAndUpdate(false);
            }}
            confirmButtonText={'Yes'}
            cancelButtonText={'No'}
          />


          <StandardDialog
            open={showModifyPopup}
            title="Modify and Update Loan"
            maxWidth="sm"
            handleClose={() => setShowModifyPopup(false)}
            dialogContent={
              <Grid item xs={12}>
                <TaxEscrowMonthsOverride store={escrowDepositCalculatorStore} />
              </Grid>}
            dialogActions={
              <Grid container spacing={1} justifyContent="flex-end">
                <Grid item>
                  <Button
                    onClick={() => setShowModifyPopup(false)}
                    color="primary"
                    variant="outlined"
                    testId="cancel"
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    onClick={() => { setShowModifyPopup(false); setIsModifyAndUpdate(true); }}
                    color="primary"
                    variant="contained"
                    testId="confirm"
                    disabled={!formStore.form.meta.isValid}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            }
          />
        </Paper>
      </Layout>
    );
  }
);

const CalculatedValues = observer(({ store }: EscrowDepositSectionProps) => {
  return (
    <>
      <Grid item xs={12}>
        <PaperList>

          <PaperListItem
            label="Months of Tax to collect at Closing (min 3)"
            value={store.form.fields.taxEscrowMonths.value}
          />

        </PaperList>
      </Grid>
    </>
  );
});


export const LoanValuesSection = ({ store }: EscrowDepositSectionProps) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant={'h5'}>Loan Values</Typography>
      </Grid>
      <StateSelectField store={store} />
      <ExpectedClosingDate store={store} />
      <NextPaymentDueDateDateField store={store} />
      <FirstPaymentDueDate store={store} />
      <NumberOfMonthsPaymentCovers store={store} />
      <PaymentsPriorToDue store={store} />
    </Grid>
  );
};
