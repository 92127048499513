import { Service } from '@roc/client-portal-shared/services';

const url = {
  GET_MY_PROMOTIONS_GRID: `/api/v1/loan/marketing/getMyPromotionsGrid`,
};

export class MarketingService extends Service {

  getMyAplicableMarketingPromotionsGrid(
    page: number,
    pageSize: number,
    sortDir: string,
    sortBy: string,
    filters: string,
    isInternalTPO: boolean
  ) {
    return this.get(
      url.GET_MY_PROMOTIONS_GRID,
      {
        page,
        pageSize,
        sortDir,
        sortBy,
        filters,
        isInternalTPO
      },
    );
  }

}
