import { FormStore, GlobalStore } from '@roc/feature-app-core';
import { parseAddress } from '@roc/feature-utils';
import { action, makeObservable, observable } from 'mobx';

const entityForm = {
  fields: {
    borrowerEntityOption: {
      value: null
    },
    borrowerEntityId: {
      value: '',
      error: null,
      rule: '',
    },
    name: {
      value: '',
      error: null,
      rule: 'required',
    },
    type: {
      value: '',
      error: null,
      rule: 'required',
    },
    ein: {
      value: '',
      error: null,
      rule: 'required|regex:/^\\d{2}-?\\d{7}$/|alpha_dash',
      message: 'EIN must be a number with the format xx-xxxxxxx',
    },
    operatingAgreementDate: {
      value: null,
      error: null,
      rule: '',
    },
    address: {
      value: '',
      error: null,
      rule: '',
    },
    latitude: {
      value: 0,
      error: null,
      rule: '',
      message: '',
    },
    longitude: {
      value: 0,
      error: null,
      rule: '',
      message: '',
    },
    streetNumber: {
      value: '',
      error: null,
      rule: '',
    },
    streetName: {
      value: '',
      error: null,
      rule: '',
    },
    city: {
      value: '',
      error: null,
      rule: '',
    },
    state: {
      value: '',
      error: null,
      rule: '',
    },
    zipcode: {
      value: '',
      error: null,
      rule: '',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export class BorrowerEntityFormStore extends FormStore {
  globalStore: GlobalStore;
  hasEntityInfo: boolean;

  constructor(globalStore: GlobalStore) {
    super({ ...entityForm }, globalStore);
    this.globalStore = globalStore;
    this.setDefaults();
    makeObservable(this, {
      hasEntityInfo: observable,
      setHasEntityInfo: action,
      resetStore: action,
    });
  }

  resetStore() {
    this.reset();
    this.setDefaults();
  }

  private setDefaults() {
    this.hasEntityInfo = false;
  }

  setHasEntityInfo(value) {
    this.hasEntityInfo = value;
  }

  saveAddressField(address: string, addressDetails, geometryDetails) {
    const parsedAddress = parseAddress(
      address,
      addressDetails,
      geometryDetails
    );
    this.onFieldChange('streetNumber', parsedAddress.street_number);
    this.onFieldChange('streetName', parsedAddress.street);
    this.onFieldChange('city', parsedAddress.city);
    this.onFieldChange('state', parsedAddress.state);
    this.onFieldChange('zipcode', parsedAddress.zip);
    this.onFieldChange('address', address);
    this.onFieldChange('latitude', parsedAddress.latitude);
    this.onFieldChange('longitude', parsedAddress.longitude);
  }
}