import {
  AnchorColumn, DataGrid as Grid,
  GridColumnProps
} from '@roc/ui';
import { observer } from 'mobx-react';
import React from 'react';
import { useFindBorrowersStore } from '../../hooks/useFindBorrowersStore';

const columns: GridColumnProps[] = [
  {
    field: 'borrowerId',
    headerName: 'Borrower ID',
    minWidth: 50,
  },
  {
    field: 'firstName',
    headerName: 'First Name',
    minWidth: 175,
  },
  {
    field: 'lastName',
    headerName: 'Last Name',
    minWidth: 175,
  },
  {
    field: 'emailAddress',
    headerName: 'Email',
    minWidth: 300,
    cellRenderer: 'emailCellRenderer',
  },
  {
    field: 'workPhone',
    headerName: 'Phone',
    minWidth: 150,
    maxWidth: 200,
    cellRenderer: 'phoneCellRenderer',
  },
  {
    field: 'state',
    headerName: 'State',
    minWidth: 100,
  },
  {
    field: 'city',
    headerName: 'City',
    minWidth: 100,
  },
  {
    field: 'zipCode',
    headerName: 'ZipCode',
    minWidth: 100,
  },
];

export const FindBorrowersGrid = observer(() => {
  const { findBorrowersStore } = useFindBorrowersStore();

  const formatPhoneNumber = (phoneNumber: string) => {
    if (!phoneNumber) return '';
    return phoneNumber.match(/\d+/g).join('');
  };

  const frameworkComponents = {
    emailCellRenderer: params =>
      AnchorColumn({
        ...params,
        href: `mailto:${params.value}`,
      }),
    phoneCellRenderer: params =>
      AnchorColumn({
        ...params,
        href: `tel:${formatPhoneNumber(params.value)}`,
      }),
  };

  return (
    <Grid
      columns={columns}
      rows={findBorrowersStore.borrowers}
      frameworkComponents={frameworkComponents}
      domLayout="autoHeight"
    />
  );
});
