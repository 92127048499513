import { Box, IconButton, MenuItem, Select } from '@material-ui/core';
import Delete from '@material-ui/icons/Delete';
import { Button } from '@roc/ui';
import { useStore } from 'apps/client-portal-public/src/app/hooks';
import React, { useState } from 'react';


export const DeletableItem = ({ item, itemLabel, onDelete, reasonOptions }) => {
  const [showDeleteOptions, setShowDeleteOptions] = useState(false);
  const [deleteReason, setDeleteReason] = useState('');
  const { globalStore } = useStore();

  const handleDeleteClick = () => {
    setShowDeleteOptions(true);
  };

  const handleReasonChange = (event) => {
    setDeleteReason(event.target.value);
  };

  const handleDeleteConfirm = () => {
    if (deleteReason) {
      onDelete(item, deleteReason);
      setShowDeleteOptions(false);
      setDeleteReason('');
    } else {
      globalStore.notificationStore.showErrorNotification({
        message: 'Please select a reason for deletion',
      });
    }
  };

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between" p={1}
    >
      {
        itemLabel
      }
      {showDeleteOptions ? (
        <Box display="flex" alignItems="center">
          <Select
            value={deleteReason}
            onChange={handleReasonChange}
            displayEmpty
            style={{ marginRight: '20px' }}
          >
            <MenuItem value="" disabled>Select reason</MenuItem>
            {reasonOptions.map((reason) => (
              <MenuItem key={reason.value} value={reason.value}>
                {reason.label}
              </MenuItem>
            ))}
          </Select>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleDeleteConfirm}
            testId="delete"
          >
            Delete
          </Button>
        </Box>
      ) : (
        <IconButton onClick={handleDeleteClick}>
          <Delete />
        </IconButton>
      )}
    </Box>
  );
};
