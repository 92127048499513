import {
  GlobalStore,
} from '@roc/feature-app-core';
import {
  RentalPortfolioStore,
  SinglePropertyStore,
} from '@roc/feature-loans';
import {
  MIXED_USE,
  MULTIFAMILY_5_PLUS,
  REFINANCE,
} from '@roc/feature-utils';
import { action, computed, flow, makeObservable, observable } from 'mobx';
import { RentalPricerBaseService } from '../services/rentalPricerBaseService';

export const PROPERTY_INFORMATION = 'Property Information';
export const PORTFOLIO_INFORMATION = 'Portfolio Information';
export const PROPERTY_VALUATION = 'Property Valuation';
export const BORROWER_INFORMATION = 'Borrower Information';
export const PRICER_SUMMARY = 'Loan Pricer Summary';

export class RentalPricerDetailsStore {
  globalStore: GlobalStore;
  singlePropertyStore: SinglePropertyStore;
  rentalPortfolioStore: RentalPortfolioStore;
  private rentalPricerService: RentalPricerBaseService;
  currentStep: string;
  isSingleProperty: boolean;

  constructor(globalStore: GlobalStore, singlePropertyStore: SinglePropertyStore, rentalPortfolioStore: RentalPortfolioStore,rentalPricerService: RentalPricerBaseService) {

    this.globalStore = globalStore;
    this.singlePropertyStore = singlePropertyStore;
    this.rentalPortfolioStore = rentalPortfolioStore;
    this.rentalPricerService = rentalPricerService;

    makeObservable(this, {
      currentStep: observable,
      isSingleProperty: observable,
      termStore: computed,
      moveToStep: action,
      validateStep: action,
      runPricerStoreValidation: action,
      checkRates: flow,
      saveRentalPricer: flow,
      fieldHasError: action,
      fetchRentalPricerData: flow,
    });
  }

  initialize(isSingleProperty) {
    this.termStore.reset();
    this.isSingleProperty = isSingleProperty;
    this.currentStep = PROPERTY_INFORMATION;
    this.termStore.pricerSummaryStore.resetStore(true);
    this.termStore.pricerSummaryStore.setPrePaymentPenaltyToDefault();
    this.termStore.fetchStateCounties();
  }

  get termStore() {
    return this.isSingleProperty
      ? this.singlePropertyStore
      : this.rentalPortfolioStore;
  }

  getCurrentStepFieldNames() {
    const pricerStore = this.termStore.pricerStore;
    const fields = pricerStore.form.fields;
    const isMixedUse = fields?.propertyType?.value === MIXED_USE;
    const isRefinance = fields?.loanPurpose?.value === REFINANCE;
    const isMultifamily5Plus =
      fields?.propertyType?.value === MULTIFAMILY_5_PLUS;
    const isBankruptcyYes = fields.bankruptcy.value === 'Y';
    const isForeclosureYes = fields.foreclosure.value === 'Y';
    const hasMultiFamily =
      !this.isSingleProperty &&
      this.rentalPortfolioStore.getPropertyTypes().includes(MULTIFAMILY_5_PLUS);

    switch (this.currentStep) {
      case PROPERTY_INFORMATION:
        return this.isSingleProperty
          ? [
            'address',
            'propertyType',
            'propertyCounty',
            ...(!isMixedUse ? ['units'] : []),
            'loanPurpose',
            'acquisitionPrice',
            'section8',
            ...(isRefinance
              ? [
                'acquisitionDate',
                'capitalImprovements',
                'anyDebt',
                'totalDebtPayoff',
              ]
              : []),
            ...(isMixedUse
              ? [
                'residentialUnits',
                'commercialUnits',
                'residentialUnitsSqFtArea',
                'commercialUnitsSqFtArea',
                'commercialUnitsCurrentOnRent',
                'residentialIncome',
                'commercialIncome',
              ]
              : []),
          ]
          : [
            'propertyStates',
            'propertyCounties',
            'totalNumberOfProperties', //Calclulated
            'loanPurpose',
            'acquisitionPrice',
            'section8',
            ...(isRefinance
              ? [
                'acquisitionDate',
                'capitalImprovements',
                'anyDebt',
                'totalDebtPayoff',
              ]
              : []),
            'loanRecourse',
          ];
      case PORTFOLIO_INFORMATION:
        return [
          'targetLTV',
          'minimumAsIsValue',
          'maximumAsIsValue',
          'totalEstimatedAsIsValue',
          'propertyManagement',
        ];
      case PROPERTY_VALUATION:
        return this.isSingleProperty
          ? [
            'propertyValuation',
            'monthlyGrossRent',
            'annualTaxes',
            'annualInsurance',
            'annualHOA',
            'totalAnnualUtilities',
            'totalAnnualRepairsMaintenance',
            'totalAnnualPropertyManagementFees',
            ...(isMultifamily5Plus
              ? [
                'generalAdministrative',
                'payroll',
                'marketing',
                'replacementReserves',
              ]
              : []),
            'targetLTV',
            'totalAnnualNOI', //Calculated
          ]
          : [
            'monthlyGrossRent',
            'totalGrossAnnualTaxes',
            'totalGrossAnnualInsurance',
            'totalAnnualHOADues',
            'totalAnnualUtilities',
            'totalAnnualRepairsMaintenance',
            'totalAnnualPropertyManagementFees',
            ...(hasMultiFamily
              ? [
                'generalAdministrative',
                'payroll',
                'marketing',
                'replacementReserves',
              ]
              : []),
            'totalAnnualNOI', //Calculated
          ];
      case BORROWER_INFORMATION:
        return [
          'borrowerExp',
          'citizenshipStatus',
          'ficoProvidedAtOrigination',
          'bankruptcy',
          ...(isBankruptcyYes ? ['bankruptcyDate'] : []),
          'foreclosure',
          ...(isForeclosureYes ? ['foreclosureDate'] : []),
        ];
      case PRICER_SUMMARY:
        return [];
    }
  }

  fieldHasError(fieldName) {
    const pricerStore = this.termStore.pricerStore;
    const fields = pricerStore.form.fields;

    pricerStore.onFieldChange(fieldName, fields[fieldName].value);
    return !!fields[fieldName]?.error;
  }

  validateStep() {
    const pricerStore = this.termStore.pricerStore;
    const currentStepFields = this.getCurrentStepFieldNames();
    const form = pricerStore.form;
    const fields = form.fields;

    const validationFailed = currentStepFields
      .filter(
        name => name !== 'totalAnnualNOI' && name !== 'totalNumberOfProperties'
      )
      .map(name => fields[name] && this.fieldHasError(name))
      .some(hasError => hasError == true);

    if (validationFailed) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Please fix the validation errors.',
      });
      return false;
    }

    const errors = pricerStore.getPricerStepErrors();
    let errorMessage;
    for (let fieldName of currentStepFields) {
      errorMessage = errorMessage || errors[fieldName];
      if (fields[fieldName]) {
        fields[fieldName].error = errors[fieldName];
      }
    }
    if (errorMessage) {
      this.globalStore.notificationStore.showErrorNotification({
        message: errorMessage,
      });
      return false;
    }

    return true;
  }

  runPricerStoreValidation() {
    const pricerStore = this.termStore.pricerStore;
    let errorMessage;
    if (pricerStore.checkIfRequiredFieldsEmpty()) {
      errorMessage = 'Please fill all the required fields';
    } else if (pricerStore.checkForPricerStepErrors()) {
      errorMessage = this.termStore.stepError;
    }

    if (errorMessage) {
      this.globalStore.notificationStore.showErrorNotification({
        message: errorMessage,
      });
      return false;
    } else {
      return true;
    }
  }

  moveToStep(step, skipValidation = false) {
    if (step === PRICER_SUMMARY) {
      if (this.validateStep() && this.runPricerStoreValidation()) {
        this.checkRates();
        this.currentStep = step;
      }
    } else {
      if (skipValidation || this.validateStep()) {
        this.currentStep = step;
      }
    }
  }

  resetPricerErrors() {
    const values = this.termStore.pricerStore.getFormValues();
    this.termStore.pricerStore.reset();
    this.termStore.pricerStore.loadForm(values);
  }

  *checkRates() {
    const pricerSummaryStore = this.termStore.pricerSummaryStore;
    pricerSummaryStore.setPrePaymentPenaltyToDefault();
    pricerSummaryStore.fetchRatesAndLoanTerms();
  }

  *saveRentalPricer(isSingleProperty) {
    this.termStore.pricerStore.handlePricerType(isSingleProperty?'singleProperty':'Portfolio');
    const formValues = this.termStore.pricerStore.getFormValues();
    const rentalPricerData = {
      ...formValues,
      section8: formValues.section8 == 'Y',
    }
    try {
      if((isSingleProperty && rentalPricerData.address) || (!isSingleProperty && rentalPricerData.propertyStates)){
        if(!isSingleProperty && !rentalPricerData.properties)
          rentalPricerData.properties = []
        const response = yield this.rentalPricerService.saveRentalPricer(rentalPricerData);
        this.globalStore.notificationStore.showSuccessNotification({
          message: `Rental quote saved successfully`,
        });
        const data = response?.data?.data;
        const rentalPricer = {
          ...data,
          section8: data.section8 == true ? 'Y' : data.section8 == false ? 'N' : data.section8,
        }
        rentalPricer.pricerType === 'singleProperty' ? this.singlePropertyStore.pricerStore.loadForm(rentalPricer) : this.rentalPortfolioStore.pricerStore.loadForm(rentalPricer);
        return response;
      }else{
        this.globalStore.notificationStore.showErrorNotification({
        message: `To be able to save you need to add an Address or State`,
      });
      }
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: `Error while saving the rental quote`,
      });
    }
  }

  *fetchRentalPricerData(rentalPricerId){
    try {
      const response = yield this.rentalPricerService.getRentalPricerById(rentalPricerId);
      const data = response?.data?.data;
      const rentalPricer = {
        ...data,
        section8: data.section8 == true ? 'Y' : data.section8 == false ? 'N' : data.section8,
      }
      rentalPricer.pricerType === 'singleProperty' ? this.singlePropertyStore.pricerStore.loadForm(rentalPricer) : this.rentalPortfolioStore.pricerStore.loadForm(rentalPricer);
      const properties = response?.data?.data?.properties;
      if(properties)
        this.rentalPortfolioStore.setProperties(properties)
      else
        this.rentalPortfolioStore.setProperties([])
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: `Error while saving the rental quote`,
      });
    }
  }
}
