import { Grid, Menu, MenuItem, ListItemText, ListItemIcon } from '@material-ui/core';
import { ArrowDropDown, Clear, PlaylistAddCheck } from '@material-ui/icons';
import { Button, StandardDialog, TextField, Toolbar, ConfirmationMenuItem } from '@roc/ui';
import { observer } from 'mobx-react';
import { useRef, useState } from 'react';
import { ProtectiveAdvancesSearchBar } from '../protectiveAdvancesSearchBar';
import { CounterPartyExpensesTab, PayableReceivablesTypes } from '../../types/types';
import { MarkAsPaidModal } from './markAsPaidModal';
import { useBaseStore } from '@roc/feature-app-core';

export const PayablesToolbar = observer(({ counterPartyExpenseStore }) => {
  const anchorRef = useRef(null);
  const [showBulkActionsMenu, setShowBulkActionsMenu] = useState(false);
  const [payableRefNumber, setPayableRefNumber] = useState<string>('');
  const { tab, entity } = counterPartyExpenseStore;
  const [showMarkAsPaidModal, setShowMarkAsPaidModal] = useState(false);
  const { globalStore } = useBaseStore();
  const closeMenu = () => {
    setShowBulkActionsMenu(false);
  };

  const closeMarkAsPaidModal = () => {
    setPayableRefNumber('');
    setShowMarkAsPaidModal(false);
  };

  const openMarkAsPaidModal = () => {
    if (counterPartyExpenseStore.recordsIds && counterPartyExpenseStore.recordsIds.length > 0) {
      setShowMarkAsPaidModal(true);
    } else {
      globalStore.notificationStore.showErrorNotification({
        message: 'Please select an item from the table.',
      });
    }

  }

  const handleMarkAsPaidConfirm = async () => {
    await counterPartyExpenseStore.markMultipleAsPaid(payableRefNumber);
    counterPartyExpenseStore.counterPartyExpenseGridStore.resetAndFetchGridData();
    closeMarkAsPaidModal();
  }

  const performAction = async (status) => {
    if (counterPartyExpenseStore.recordsIds && counterPartyExpenseStore.recordsIds.length > 0) {
      await counterPartyExpenseStore.performActions(status);
      counterPartyExpenseStore.counterPartyExpenseGridStore.resetAndFetchGridData();
    } else {
      globalStore.notificationStore.showErrorNotification({
        message: 'Please select an item from the table.',
      });
    }
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Toolbar left={
          <ProtectiveAdvancesSearchBar
            counterPartyExpenseStore={counterPartyExpenseStore}
            label={"Vendor"}
          />
        }>
          {
            {
              [CounterPartyExpensesTab.PENDING_PAYABLE]:
                <PendingTabActions
                  {...{
                    anchorRef,
                    setShowBulkActionsMenu,
                    showBulkActionsMenu,
                    closeMenu,
                    openMarkAsPaidModal,
                    entity,
                    performAction,
                    tab
                  }}
                />,
              [CounterPartyExpensesTab.PAYABLES_APPROVED]:
                <ApprovedTabActions
                  {...{
                    anchorRef,
                    setShowBulkActionsMenu,
                    showBulkActionsMenu,
                    closeMenu,
                    openMarkAsPaidModal,
                    entity,
                  }}
                />,
              [CounterPartyExpensesTab.ALL_PAYABLE]:
                <AllTabActions store={counterPartyExpenseStore} />,
              [CounterPartyExpensesTab.ALL_PAYABLES_APPROVED]:
                <AllTabActions store={counterPartyExpenseStore} />
            }[tab]
          }
        </Toolbar>
      </Grid>
      <MarkAsPaidModal
        payableRefNumber={payableRefNumber}
        setPayableRefNumber={setPayableRefNumber}
        showMarkAsPaidModal={showMarkAsPaidModal}
        closeMarkAsPaidModal={closeMarkAsPaidModal}
        handleMarkAsPaidConfirm={handleMarkAsPaidConfirm}
      />
    </Grid>
  );
});

const PendingTabActions = ({ anchorRef, setShowBulkActionsMenu, showBulkActionsMenu, closeMenu, openMarkAsPaidModal, entity, performAction, tab }) => {
  return <div ref={anchorRef} style={{ display: 'inline-block' }}>
    <Button
      id="basic-button"
      aria-controls={open ? 'basic-menu' : undefined}
      aria-haspopup="true"
      aria-expanded={open ? 'true' : undefined}
      onClick={() => { setShowBulkActionsMenu(true) }}
      testId="open_menu"
      variant="contained"
      size="small"
      color="primary"
      disabled={!entity}
    >
      Bulk Actions <ArrowDropDown style={{ marginRight: '-6px' }} />
    </Button>
    <Menu
      id="basic-menu"
      anchorEl={anchorRef.current}
      open={showBulkActionsMenu}
      onClose={closeMenu}
      keepMounted={true}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      getContentAnchorEl={null}
    >
      <ConfirmationMenuItem
        testId={`approve-payable`}
        onClick={() => { performAction(PayableReceivablesTypes.APPROVED) }}
        confirmDialogProps={{
          body: `Are you sure you want to Approve the selected records?`,
        }}
      >
        <ListItemIcon>
          <PlaylistAddCheck fontSize="small" />
        </ListItemIcon>
        <ListItemText onClick={closeMenu}>Approve</ListItemText>
      </ConfirmationMenuItem>
      <ConfirmationMenuItem
        testId={`reject-payable`}
        onClick={() => { performAction(PayableReceivablesTypes.REJECTED) }}
        confirmDialogProps={{
          body: `Are you sure you want to Reject the selected records?`,
        }}
      >
        <ListItemIcon>
          <Clear fontSize="small" />
        </ListItemIcon>
        <ListItemText onClick={closeMenu}>Reject</ListItemText>
      </ConfirmationMenuItem>
    </Menu>
  </div>
}

const ApprovedTabActions = ({ anchorRef, setShowBulkActionsMenu, showBulkActionsMenu, closeMenu, openMarkAsPaidModal, entity }) => {
  return <div ref={anchorRef} style={{ display: 'inline-block' }}>
    <Button
      id="basic-button"
      aria-controls={open ? 'basic-menu' : undefined}
      aria-haspopup="true"
      aria-expanded={open ? 'true' : undefined}
      onClick={() => { setShowBulkActionsMenu(true) }}
      testId="open_menu"
      variant="contained"
      size="small"
      color="primary"
      disabled={!entity}
    >
      Bulk Actions <ArrowDropDown style={{ marginRight: '-6px' }} />
    </Button>
    <Menu
      id="basic-menu"
      anchorEl={anchorRef.current}
      open={showBulkActionsMenu}
      onClose={closeMenu}
      keepMounted={true}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      getContentAnchorEl={null}
    >
      <MenuItem onClick={openMarkAsPaidModal}>
        <ListItemIcon>
          <PlaylistAddCheck fontSize="small" />
        </ListItemIcon>
        <ListItemText onClick={closeMenu}>Mark as Paid</ListItemText>
      </MenuItem>
    </Menu>
  </div>
}

const AllTabActions = ({ store }) => {
  return <Button
    id="basic-button"
    aria-controls={open ? 'basic-menu' : undefined}
    aria-haspopup="true"
    aria-expanded={open ? 'true' : undefined}
    onClick={() => store.exportServicingDashboardData()}
    testId="open_menu"
    variant="contained"
    size="small"
    color="primary"
  >
    Export as Excel
  </Button>
}