import { UserService, WireAuthorizationService } from './../services';
import { ApiResponse } from '@roc/client-portal-shared/services';
import { observable, flow, makeObservable } from 'mobx';
import { WireAuthorizationState, WireAuthInfo, IdentityVerificationVersion } from '../types';
import { DocumentVerificationState } from '@roc/feature-types';
import { GlobalStore, FormStore } from '@roc/feature-app-core';

const wireAuthorizationForm = {
  fields: {
    accountName: {
      value: '',
      error: null,
      rule: 'required',
      message: 'The Account Name field is required.',
    },
    routingNumber: {
      value: '',
      error: null,
      rule: 'required|regex:/^\\d{9}$/|alpha_dash',
      message: 'Routing Number must be 9 digits',
    },
    accountNumber: {
      value: '',
      error: null,
      rule: 'required',
      message: 'The Account Number field is required.',
    },
    bankName: {
      value: '',
      error: null,
      rule: 'required',
      message: 'The Bank Name field is required.',
    },
    streetName: {
      value: '',
      error: null,
      rule: 'required',
      message: 'The Street Name field is required.',
    },
    city: {
      value: '',
      error: null,
      rule: 'required',
      message: 'The City field is required.',
    },
    zipCode: {
      value: '',
      error: null,
      message: 'Zip Code must be 5 digits',
      rule: 'required|regex:/^\\d{5}$/|alpha_dash',
    },
    state: {
      value: '',
      error: null,
      rule: 'required',
      message: 'The State field is required.',
    },
    fccAccountName: {
      value: '',
      error: null,
      rule: '',
    },
    fccAccountNumber: {
      value: '',
      error: null,
      rule: '',
    },
    fccRoutingNumber: {
      value: '',
      error: null,
      rule: 'regex:/^\\d{9}$/|alpha_dash',
      message: 'Routing Number must be 9 digits',
    },
    fccBankName: {
      value: '',
      error: null,
      rule: '',
    },
    fccStreetName: {
      value: '',
      error: null,
      rule: '',
    },
    fccCity: {
      value: '',
      error: null,
      rule: '',
    },
    fccZipCode: {
      value: '',
      error: null,
      rule: '',
    },
    fccState: {
      value: '',
      error: null,
      rule: '',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export class WireAuthorizationStore extends FormStore {
  private globalStore: GlobalStore;
  private userService: UserService;
  private wireAuthorizationService: WireAuthorizationService;
  wireAuthorizationState: WireAuthorizationState;
  documentVerificationState: DocumentVerificationState;
  experienceTransactionId: string;
  uuid: string;
  docusignUrl: string;
  docusignError: boolean;
  activeStep: number;
  accessTokenURL: string;
  secureMeLink: string;
  publicKey: string;
  loading: boolean;
  identityVerificationCompleted: boolean;
  identityVerificationVersion: number;

  constructor(globalStore) {
    super({ ...wireAuthorizationForm }, globalStore);
    this.globalStore = globalStore;
    this.userService = new UserService();
    this.wireAuthorizationService = new WireAuthorizationService();
    makeObservable(this, {
      wireAuthorizationState: observable,
      documentVerificationState: observable,
      setWireAuthorizationState: flow,
      setIdentityVerificationCompleted: flow,
      setDocumentVerificationState: flow,
      setExperienceId: flow,
      setUuid: flow,
      docusignUrl: observable,
      docusignError: observable,
      activeStep: observable,
      accessTokenURL: observable,
      secureMeLink: observable,
      identityVerificationCompleted: observable,
      identityVerificationVersion: observable,
      publicKey: observable,
      resetStore: flow,
      goNextStep: flow,
      goPrevStep: flow,
      submitFormToDocusign: flow,
      getWireAuthInfo: flow,
      confirmDocusign: flow,
      identityVerification: flow,
      loading: observable,
      setLoading: flow,
      consentRequest: flow,
    });
    this.setDefaults();
  }

  private setDefaults() {
    this.activeStep = 0;
    this.wireAuthorizationState = WireAuthorizationState.LOADING;
    this.documentVerificationState = DocumentVerificationState.NOT_STARTED;
    this.experienceTransactionId = '';
    this.uuid = '';
    this.docusignError = false;
    this.accessTokenURL = '';
    this.publicKey = '';
    this.loading = false;
    this.identityVerificationCompleted = false;
  }

  *resetStore() {
    super.reset();
    this.setDefaults();
  }

  *goPrevStep() {
    this.activeStep--;
  }

  *goNextStep() {
    this.activeStep++;
  }

  *submitFormToDocusign() {
    try {
      const values = this.getFormValues();
      const request = {
        tabs: [
          {
            type: 'TEXT',
            label: 'accountName',
            value:
              values.accountName,
          },
          {
            type: 'TEXT',
            label: 'routingNumber',
            value:
              values.routingNumber,
          },
          {
            type: 'TEXT',
            label: 'accountNumber',
            value: values.accountNumber,
          },
          {
            type: 'TEXT',
            label: 'bankName',
            value: values.bankName,
          },
          {
            type: 'TEXT',
            label: 'streetName',
            value: values.streetName,
          },
          {
            type: 'TEXT',
            label: 'city',
            value: values.city,
          },
          {
            type: 'ZIP',
            label: 'zipcode',
            value: values.zipCode,
          },
          {
            type: 'TEXT',
            label: 'state',
            value: values.state,
          },
          {
            type: 'TEXT',
            label: 'ffcAccountName',
            value:
              values.fccAccountName,
          },
          {
            type: 'TEXT',
            label: 'ffcRoutingNumber',
            value:
              values.fccRoutingNumber,
          },
          {
            type: 'TEXT',
            label: 'ffcAccountNumber',
            value: values.fccAccountNumber,
          },
          {
            type: 'TEXT',
            label: 'ffcBankName',
            value: values.fccBankName,
          },
          {
            type: 'TEXT',
            label: 'ffcStreetName',
            value: values.fccStreetName,
          },
          {
            type: 'TEXT',
            label: 'ffcCity',
            value: values.fccCity,
          },
          {
            type: 'ZIP',
            label: 'ffcZipCode',
            value: values.fccZipCode,
          },
          {
            type: 'TEXT',
            label: 'ffcState',
            value: values.fccState,
          },
        ],
        returnUrl:
          '/wire-authorization-docusign-callback',
      };

      const response: ApiResponse = yield this.wireAuthorizationService.submitWireAuthorizationFormToDocusign(
        request,
        this.uuid,
      );
      this.docusignUrl = response.data.data.viewUrl;
    } catch (error) {
      this.docusignError = true;
    }
  }

  *identityVerification() {
    try {
      this.setLoading(true);

      switch (this.identityVerificationVersion) {
        case IdentityVerificationVersion.TRULIO: {
          yield this.userService.identityVerification(
            this.uuid,
            this.experienceTransactionId
          );
          break;
        }
        case IdentityVerificationVersion.AU10TIX: {
          yield this.wireAuthorizationService.associateVerification(
            this.uuid,
            this.experienceTransactionId
          );
          break;
        }
        default: {
          throw new Error("Unsupported verification version");
        }
      }
      this.goNextStep();
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error occurred during identity verification',
      });
    }
    this.setLoading(false);
  }

  *getWireAuthInfo() {
    try {
      const response: ApiResponse = yield this.wireAuthorizationService.getWireAuthInfo(
        this.uuid
      );
      const wireAuthInfo: WireAuthInfo = response.data.data;
      this.identityVerificationVersion = wireAuthInfo.identityVerificationVersion;
      if (wireAuthInfo.docusignCompleted === true) {
        this.setWireAuthorizationState(WireAuthorizationState.DONE);
      } else {
        this.accessTokenURL = wireAuthInfo.accessTokenURL;
        this.secureMeLink = wireAuthInfo.secureMeLink;
        this.publicKey = wireAuthInfo.publicKey;
        this.setWireAuthorizationState(WireAuthorizationState.IN_PROGRESS);
      }
      this.setIdentityVerificationCompleted(wireAuthInfo.identityVerificationCompleted)
      if (wireAuthInfo.identityVerificationCompleted === true) {
        this.setDocumentVerificationState(DocumentVerificationState.IN_REVIEW);
      }
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching wire authorization information',
      });
    }
  }

  *confirmDocusign() {
    try {
      yield this.wireAuthorizationService.confirmDocusign(
        this.uuid
      );
      this.setWireAuthorizationState(WireAuthorizationState.DONE);
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error occurred during docusign confirmation',
      });
    }
  }

  *setWireAuthorizationState(state: WireAuthorizationState) {
    this.wireAuthorizationState = state;
  }
  *setDocumentVerificationState(state: DocumentVerificationState) {
    this.documentVerificationState = state;
  }
  *setExperienceId(experienceId: string) {
    this.experienceTransactionId = experienceId;
  }
  *setUuid(id: string) {
    this.uuid = id;
  }
  *setLoading(value: boolean) {
    this.loading = value;
  }
  *setIdentityVerificationCompleted(identityVerificationCompleted: boolean) {
    this.identityVerificationCompleted = identityVerificationCompleted;
  }

  *consentRequest() {
    try {
      yield this.wireAuthorizationService.consentRequest(
        this.uuid
      );
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error occurred saving consent request',
      });
    }
  }
}

export default WireAuthorizationStore;
