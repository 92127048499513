import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { useLoanStore } from '@roc/feature-loans-shared';
import { observer } from 'mobx-react';
import { LoanDrawsDashboardShared } from './loanDrawsDashboardShared';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

const useStyles = makeStyles(theme => ({
  container: {
    padding: 16,
  },
  dealName: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

export const LoanDrawsDashboard = observer(({ loanId, showTitle = true, style }: { loanId: string; showTitle?: boolean; style?: CSSProperties }) => {
  const { loanStore } = useLoanStore();
  const classes = useStyles();

  return (
    <>
      <Grid container direction="column" className={classes.container} style={style}>
        {
          showTitle &&
          <>
            <Grid item className={classes.dealName}>
              <Typography variant="h6" color="textSecondary">
                {loanStore?.loanDetails.dealName}
              </Typography>
            </Grid>
            <Grid item style={{
              paddingLeft: 12
            }}>
              <Typography variant="h4">DRAWS DASHBOARD</Typography>
            </Grid>
          </>
        }
        <LoanDrawsDashboardShared loanId={loanId} />
      </Grid>
    </>
  );
});
