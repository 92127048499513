import { ApiResponse, GlobalStore, LogBaseService } from '@roc/feature-app-core';
import { action, computed, flow, makeObservable, observable } from 'mobx';
import { PropertyDataCollectionService } from '@roc/feature-properties';
import { SummaryData } from '../appraisalReview/appraisalReviewForm';
import { summaryDataRows } from '../appraisalReview/appraisalReviewConstants';
import { APPRAISAL_REPORT_VERSION_3, LoanSubType, downloadDocument, getUniqueId } from '@roc/feature-utils';
import { AppraisalReviewRevisionItemModalStore } from './appraisalReviewRevisionItemModalStore';
import { AppraisalReviewBaseService } from '../services/appraisalReviewBaseService';
import { AppraisalEvents, APPRAISAL_REVIEW_EVENT_TYPE } from '../types/appraisalType';
import { AppraisalReviewTermSummaryFormStore } from './appraisalReviewTermFormStore';
import { AppraisalReviewType } from '../utils/utils';
import { MANUAL_EDIT_APPRAISAL_REVIEW } from '../appraisalReview/constants';

const CUSTOM_REVISIONS_SECTION = 'CUSTOMREVISIONS';
const HIDDEN_SECTIONS = [CUSTOM_REVISIONS_SECTION];

export class AppraisalReviewStore {
  globalStore: GlobalStore;
  appraisalReviewRevisionItemModalStore: AppraisalReviewRevisionItemModalStore;
  public appraisalReviewTermSummaryFormStore: AppraisalReviewTermSummaryFormStore;
  propertyDataCollectionService: PropertyDataCollectionService;
  logService: LogBaseService;
  protected appraisalReviewService: AppraisalReviewBaseService;
  propertyInformation: any;
  summaryData: SummaryData[];
  summaryDataFirstCol: SummaryData[];
  summaryDataSecondCol: SummaryData[];
  automatedReviewSections: any[];
  manualAutomatedStatus: any;
  dynamicDropdownOptions: any;
  currentFirmId: string;
  currentFirmLabel: string;
  dropDownSelectionCounter: number;
  reviewType: AppraisalReviewType;
  editEnabled: boolean;
  automatedReviewPhotosSections: any[];
  successCallback: () => void;
  eventList: any[];
  appraisalReviewItemNames: any[];
  reportVersion: number;
  showAllItems: boolean;

  constructor(globalStore) {
    this.globalStore = globalStore;
    this.appraisalReviewRevisionItemModalStore = new AppraisalReviewRevisionItemModalStore(globalStore);
    this.appraisalReviewTermSummaryFormStore = new AppraisalReviewTermSummaryFormStore(globalStore);
    this.propertyDataCollectionService = new PropertyDataCollectionService();
    this.setDefaults();
    makeObservable(this, {
      propertyInformation: observable,
      automatedReviewSections: observable,
      manualAutomatedStatus: observable,
      summaryData: observable,
      summaryDataFirstCol: observable,
      summaryDataSecondCol: observable,
      successCallback: observable,
      getPropertyInformmation: flow,
      getAutomatedReviewItems: flow,
      saveData: flow,
      saveDataAndClose: flow,
      approveReview: flow,
      requestAppraisal1004D: flow,
      requestRevision: flow,
      downloadRevisionList: flow,
      addNewRevisionListItem: action,
      revisions: computed,
      automatedReviewSectionsToDisplay: computed,
      handleItemChange: action,
      onChangeSummaryDataFirstColumn: action,
      onChangeSummaryDataSecondColumn: action,
      dynamicDropdownOptions: observable,
      getDynamicDropdownOptions: action,
      getAppraiserNames: action,
      handleAppraiserFirmSelected: action,
      handleAppraiserFirmChange: action,
      currentFirmId: observable,
      currentFirmLabel: observable,
      dropDownSelectionCounter: observable,
      reviewType: observable,
      getAutomatedManualStatus: flow,
      automatedReviewPhotosSections: observable,
      getAutomatedAppraisalReviewPhotos: flow,
      setSuccessCallback: action,
      editEnabled: observable,
      getReportEditableFlag: flow,
      updateReportEditableFlag: flow,
      appraisalReviewItemNames: observable,
      getAppraisalReviewItemNames: flow,
      reportVersion: observable,
      getAppraisalReviewVersion: flow,
      addNewRevisionListItemV3: action,
      showAllItems: observable,
      setShowAllItems: action,
      updateUwAppraisalReviewStatus: flow,
      showWarningNotification: action,
    });
  }

  setDefaults() {
    this.automatedReviewSections = [];
    this.dropDownSelectionCounter = 0;
    this.currentFirmLabel = "";
    this.manualAutomatedStatus = MANUAL_EDIT_APPRAISAL_REVIEW;
    this.automatedReviewPhotosSections = [];
    this.eventList = [];
    this.editEnabled = false;
    this.appraisalReviewItemNames = [];
    this.reportVersion = 0;
    this.showAllItems = false;
  }

  buildSummaryDataFields() {
    if (!this.propertyInformation) {
      return;
    }

    if (this.reviewType === AppraisalReviewType.BRIDGE) {
      this.summaryData = summaryDataRows.map(row => (
        {
          mapping: row.mapValue,
          mappingToSave: row.mapValueToSave,
          title: row.title,
          value: (this.propertyInformation)[row.mapValue],
          options: row.options,
          type: row.type,
          disabled: row.disabled || this.manualAutomatedStatus === MANUAL_EDIT_APPRAISAL_REVIEW,
          getOptions: row.getOptions,
        }
      ))
      const maxIndexLeftColumn = 19;
      this.summaryDataFirstCol = this.summaryData.slice(0, maxIndexLeftColumn);
      this.summaryDataSecondCol = this.summaryData.slice(maxIndexLeftColumn, this.summaryData.length);
    } else {
      this.appraisalReviewTermSummaryFormStore.setFormValues(this.propertyInformation);
    }
  }

  *getPropertyInformmation(propertyId: number, loanId: number) {
    try {
      const response: ApiResponse = yield this.appraisalReviewService.getLoanDetails(loanId);
      this.setPropertyInformation(propertyId, response?.data);
      this.setReviewType(response?.data);
      const appraiserFirmsResponse: ApiResponse = yield this.appraisalReviewService.getAllAppraiserFirmNames();
      this.setAppraiserFirmsInformation(appraiserFirmsResponse?.data);
      this.buildSummaryDataFields();
    } catch (error) {
      console.log('Error occurred while fetching property data', error);
    }
  }

  setPropertyInformation(propertyId: number, data: any) {
    this.propertyInformation = data?.propertiesMap?.rows?.find(property => property.propertyId === +propertyId);
    if (!this.propertyInformation) {
      this.globalStore.notificationStore.showWarningNotification({
        message:
          'An error occurred when loading the property information',
      });
    }
  }

  setAppraiserFirmsInformation(data: any) {
    const appraiserFirms = [];
    const appraiserNames = [];

    data?.forEach(item => {
      appraiserFirms.push({
        value: item.firmId?.toString(),
        label: item.firmName
      });
      item.AppraisersList.forEach(appraiser => {
        appraiserNames.push({
          firmId: item.firmId?.toString(),
          value: appraiser.AppraiserId?.toString(),
          label: appraiser.AppraiserName
        });
      });
    });

    this.dynamicDropdownOptions = {
      ...this.dynamicDropdownOptions,
      appraiserFirmId: appraiserFirms,
      appraiserId: appraiserNames
    };

    this.currentFirmId = this.propertyInformation.appraiserFirmId?.toString();
    this.currentFirmLabel = appraiserFirms.find(firm => firm.value === this.currentFirmId)?.label;

  }

  *getAutomatedReviewItems(propertyId: number, loanId: number) {
    try {
      const response: ApiResponse = yield this.appraisalReviewService.getAutomatedAppraisalReviewReport(propertyId, loanId);
      this.automatedReviewSections = this.normalizeAutomatedReviewItems(response?.data);
      this.getAppraisalReviewVersion(propertyId, loanId);
      this.getAutomatedManualStatus(propertyId);
      this.getReportEditableFlag(propertyId);
    } catch (error) {
      console.log('Error occurred while getting the data', error);
    }
  }

  *getAutomatedManualStatus(propertyId: number) {
    try {
      const response: ApiResponse = yield this.appraisalReviewService.getAutomatedManualFlag(propertyId);
      console.log(response)
      this.manualAutomatedStatus = response?.data?.reponseMessage;
    } catch (error) {
      console.log("Error trying to get Automated Manual Status ", error)
    }
  }

  *getReportEditableFlag(propertyId: number) {
    try {
      const response: ApiResponse = yield this.appraisalReviewService.getReportEditableFlag(propertyId);
      this.editEnabled = response?.data;
    } catch (error) {
      this.editEnabled = false;
    }
  }

  *getAppraisalReviewVersion(propertyId: number, loanId: number) {
    try {
      const response: ApiResponse = yield this.appraisalReviewService.getAppraisalReviewVersion(propertyId, loanId);
      this.reportVersion = response?.data || 0;
    } catch (error) {
      this.reportVersion = 0;
    }
  }

  *getAppraisalReviewItemNames() {
    try {
      const response: ApiResponse = yield this.appraisalReviewService.getAppraisalReviewItemNames();
      this.appraisalReviewItemNames = response?.data;
    } catch (error) {
      console.error('Error on getAppraisalReviewItemNames', error);
    }
  }

  *updateReportEditableFlag(propertyId: number) {
    try {
      this.editEnabled = !this.editEnabled;
      const response = yield this.appraisalReviewService.updateReportEditableFlag(propertyId, this.editEnabled);
    } catch (e) {
      console.error('Error on updateReportEditableFlag', e);
      this.editEnabled = !this.editEnabled;
    }
  }

  *updateUwAppraisalReviewStatus(loanId: number, propertyId: number, status: string, onSuccess: () => void) {
    try {
      const response = yield this.appraisalReviewService.updateUwAppraisalReviewStatus(loanId, propertyId, status);
      onSuccess();
    } catch (e) {
      console.error('Error on updateUwAppraisalReviewStatus', e);
    }
  }

  normalizeAutomatedReviewItems(data) {
    return data?.map(section => ({
      ...section,
      items: section.items?.map(item => (
        { ...item, uniqueId: getUniqueId() }
      ))
    }));
  }

  *saveDataAndClose(propertyId: number, loanId: number) {
    try {
      yield this.saveData(propertyId, loanId);
      window.parent.postMessage('saved-success', '*');
      this.successCallback?.();
    } catch (error) {
      console.log('Error occurred while saving the data', error);
    }
  }

  *saveData(propertyId: number, loanId: number) {
    try {
      const data = this.parseDataToSave();
      yield this.logService.logEventsAppraisalReview(this.eventList, data, propertyId, loanId);
      yield this.appraisalReviewService.saveReview(propertyId, loanId, data);
    } catch (error) {
      console.log('Error occurred while saving the data', error);
    }
  }

  parseDataToSave() {
    const reviewSections = this.automatedReviewSections
      .map(section => ({
        ...section,
        items: section.items?.map(item => ({
          itemDisplayName: item.itemDisplayName,
          review: item.review,
          needsRevisionFlag: item.needsRevisionFlag,
          data: item.data,
          reviewerComment: item.reviewerComment,
          sectionId: item.sectionId,
          revisionRequired: item.revisionRequired,
          needsRevisionFlagRequired: item.needsRevisionFlagRequired,
          customData: item.customData,
          sendForRevision: item.sendForRevision,
        }))
      }));
    return {
      summaryData: this.parseSummaryDataToSave(),
      sections: reviewSections
    };
  }

  parseSummaryDataToSave() {
    if (this.reviewType === AppraisalReviewType.TERM) {
      return this.appraisalReviewTermSummaryFormStore.getSaveObject();
    }

    let summaryData: any = {};
    [...this.summaryDataFirstCol, ...this.summaryDataSecondCol]
      .forEach(item => {
        summaryData = {
          ...summaryData,
          [item.mappingToSave ?? item.mapping]: item.value
        }
      });
    return {
      ...summaryData,
      reviewDate: summaryData.appraisalReviewDate,
      appraiserFirm: summaryData.appraiserFirm ? Number(summaryData.appraiserFirm) : summaryData.appraiserFirm,
      appraiserName: summaryData.appraiserName ? Number(summaryData.appraiserName) : summaryData.appraiserName,
    };
  }

  *approveReview(propertyId: number, loanId: number) {
    try {
      yield this.saveData(propertyId, loanId);
      yield this.appraisalReviewService.approveReport(propertyId, loanId);

      window.parent.postMessage('saved-success', '*');
      this.successCallback?.();
    } catch (error) {
      console.log('Error occurred while submitting the review', error);
    }
  }

  *requestAppraisal1004D(propertyId: number, loanId: number) {
    try {
      yield this.appraisalReviewService.requestAppraisal1004D(propertyId, loanId);

      window.parent.postMessage('saved-success', '*');
      this.successCallback?.();
    } catch (error) {
      console.log('Error occurred while submitting the review', error);
    }
  }

  *requestRevision(propertyId: number, loanId: number, ownerValue: string) {
    try {
      yield this.saveData(propertyId, loanId);
      const comments = this.revisions
        .filter(rev => rev.sendForRevision)
        .map(rev => rev.reviewerComment || rev.itemDisplayName);
      const response = yield this.appraisalReviewService.requestRevision({ propertyId, loanId, ownerValue, comments });
      if (response.data.success) {
        window.parent.postMessage('saved-success', '*');
        this.successCallback?.();
      } else {
        this.globalStore.notificationStore.showErrorNotification({
          message: response.data.responseMessage,
        });
      }
    } catch (error) {
      console.log('Error occurred while submitting the review', error);
    }
  }

  *downloadRevisionList(propertyId: number) {
    try {
      const response = yield this.appraisalReviewService.downloadRevisionListPdf(
        propertyId, this.revisions
      );
      downloadDocument(response?.data, response?.headers, 'download');
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while downloading document.',
      });
    }
  }

  *getAllAppraiserFirmNames() {
    try {
      const response = yield this.appraisalReviewService.getAllAppraiserFirmNames();
      return response?.data;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching list of appraiser firms.',
      });
    }
  }

  handleItemChange(sectionName, index, uniqueId, mapping, value, itemName) {
    let newValue = value;
    switch (mapping?.toLowerCase()) {
      case 'review':
        newValue = value?.toLowerCase() === 'true';
        this.eventList.push({
          propertyId: this.propertyInformation?.propertyId,
          reviewType: APPRAISAL_REVIEW_EVENT_TYPE,
          actionPerformed: newValue ? AppraisalEvents.ITEM_REVIEW_FLAGGED_EVENT : AppraisalEvents.ITEM_REVIEW_UNFLAGGED_EVENT,
          sectionName: sectionName,
          itemName: itemName,
        })
        break;
      case 'needsrevisionflag':
        newValue = !value;
        this.eventList.push({
          propertyId: this.propertyInformation?.propertyId,
          reviewType: APPRAISAL_REVIEW_EVENT_TYPE,
          actionPerformed: newValue ? AppraisalEvents.ITEM_FLAGGED_EVENT : AppraisalEvents.ITEM_UNFLAGGED_EVENT,
          sectionName: sectionName,
          itemName: itemName,
        })
        break;
      case 'reviewercomment':
        newValue = value;
        break;
    }

    this.automatedReviewSections.map(section => (section.items
      .filter(item => item.uniqueId === uniqueId)
      .map(item => item[mapping] = newValue)
    ));
  }

  addNewRevisionListItem() {
    if (this.reportVersion === APPRAISAL_REPORT_VERSION_3) {
      this.addNewRevisionListItemV3();
    } else {
      const formValues = this.appraisalReviewRevisionItemModalStore.getFormValues();
      const section = this.automatedReviewSections.find(section => CUSTOM_REVISIONS_SECTION === section.sectionName);
      this.automatedReviewSections = [
        ...this.automatedReviewSections.filter(s => CUSTOM_REVISIONS_SECTION !== s.sectionName),
        {
          sectionName: CUSTOM_REVISIONS_SECTION,
          items: [
            ...section?.items ?? [],
            {
              uniqueId: getUniqueId(),
              itemDisplayName: formValues.name,
              reviewerComment: formValues.comments,
              needsRevisionFlag: true,
            }
          ]
        },
      ];
      this.appraisalReviewRevisionItemModalStore.closeDialog();
    }
  }

  addNewRevisionListItemV3() {
    const formValues = this.appraisalReviewRevisionItemModalStore.getFormValues();

    this.automatedReviewSections.forEach(section => {
      const itemIndex = section.items.findIndex(item => item.itemDisplayName === formValues.name);

      if (itemIndex >= 0) {
        const item = section.items[itemIndex];
        item.needsRevisionFlag = true;
        item.reviewerComment = item.revisionText && item.revisionText !== 'NA' ? item.revisionText : null;
      }

    });

    this.appraisalReviewRevisionItemModalStore.closeDialog();
  }

  onChangeSummaryDataFirstColumn(name, value) {
    this.summaryDataFirstCol
      .filter(item => item.mapping === name)
      .forEach(item => item.value = value)
  }

  onChangeSummaryDataSecondColumn(name, value) {
    this.summaryDataSecondCol
      .filter(item => item.mapping === name)
      .forEach(item => item.value = value)
  }

  getDynamicDropdownOptions(mapping) {
    return this.dynamicDropdownOptions[mapping];
  }

  getAppraiserNames(mapping) {
    return this.dynamicDropdownOptions[mapping].filter(option => option.firmId === this.currentFirmId);
  }

  handleAppraiserFirmChange(value) {
    this.currentFirmId = "";
    this.currentFirmLabel = value;
    this.dropDownSelectionCounter += 1;

  }

  handleAppraiserFirmSelected(mapping, item) {
    this.currentFirmId = item.value;
    this.currentFirmLabel = item.label;
    this.onChangeSummaryDataSecondColumn(mapping, item.value);
    this.dropDownSelectionCounter += 1;
    this.appraisalReviewTermSummaryFormStore.onFieldChange('appraiserFirmId', item.value);
  }

  get revisions() {
    const flaggedAsNeedsRevision = [];
    this.automatedReviewSections.map((section) => (
      section.items
        .filter(item => item.needsRevisionFlag)
        .map(item => flaggedAsNeedsRevision.push(item))
    ));
    return [...flaggedAsNeedsRevision];
  }

  get automatedReviewSectionsToDisplay() {
    return this.automatedReviewSections
      .map(section => {
        const items = section.items
          .map(item => ({ ...item, displayComment: item.reviewerComment }));

        return ({
          ...section,
          items,
        });
      })
      .filter(section => !HIDDEN_SECTIONS.includes(section.sectionName));
  }

  get uwAutomatedReviewSectionsToDisplay() {
    const sections = this.automatedReviewSections
      .map(section => {
        const items = section.items
          .filter(item => item.review === false) // we need false values excluding nulls
          .map(item => ({ ...item, displayComment: item.reviewerComment }));

        return ({
          ...section,
          items,
        });
      })
      .filter(section => !HIDDEN_SECTIONS.includes(section.sectionName) && section.items.length > 0);

    return sections;
  }

  private setReviewType(loanDetails: any) {
    this.reviewType = [LoanSubType.FIX_AND_FLIP, LoanSubType.FIX_AND_FLIP_PRO, LoanSubType.GROUND_UP].includes(loanDetails.loanSubtype)
      ? AppraisalReviewType.BRIDGE
      : AppraisalReviewType.TERM;
  }

  *getAutomatedAppraisalReviewPhotos(propertyId: number, loanId: number) {
    try {
      const response: ApiResponse = yield this.appraisalReviewService.getAutomatedAppraisalReviewPhotos(propertyId, loanId);
      this.automatedReviewPhotosSections = response?.data;
    } catch (error) {
      console.log('Error occurred while getting the data', error);
    }
  }

  setSuccessCallback(successCallback) {
    this.successCallback = successCallback;
  }

  setShowAllItems(value: boolean) {
    this.showAllItems = value;
  }

  showWarningNotification(message: string) {
    this.globalStore.notificationStore.showWarningNotification({
      message,
    });
  }
}
