import { Box, Button, Grid, Typography } from '@material-ui/core';
import { DashboardRounded, Info, InfoOutlined } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import { useBaseStore } from '@roc/feature-app-core';
import { BorrowerPortalCard } from '@roc/feature-borrower-portal-core';
import {
  LoanDrawsGrid,
  LoanValuesChartColor,
  LoanValuesChartOnly,
  LoanValuesTable,
  SOWGrid,
  SOWRevisionGrid,
  useDrawsStore
} from '@roc/feature-draws';
import { useLoanStore } from '@roc/feature-loans';
import { useLoanRoutes } from '@roc/feature-loans-routes-config';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useScopeOfWorkV2Store } from '@roc/feature-sow-shared/hooks';
import { useHistory } from 'react-router';
import { ScopeOfWorkV2FormDialog } from '@roc/feature-sow-v2';


export const BorrowerLoanDrawsDashboardV2 = observer(({ loanId }) => {
  const { globalStore } = useBaseStore();
  const { breadCrumbsStore } = globalStore;
  const { loanRoutesConfig } = useLoanRoutes();
  const {
    loanStore: { loanDetails },
  } = useLoanStore();
  const chRemaining = loanDetails?.calculatedLoanData?.constructionHoldbackRemaining;
  const chReimbursedToDate = loanDetails?.calculatedLoanData?.constructionHoldbackFunded;
  const {
    drawStore,
    drawStore: { drawList, scopeOfWorkList, revisionList },
    scopeOfWorkRevisionStore,
  } = useDrawsStore();
  const { scopeOfWorkV2Store } = useScopeOfWorkV2Store();
  const [loading, setLoading] = useState(true);
  const { push } = useHistory();
  const { scopeOfWorkV2FormStore } = scopeOfWorkV2Store;


  useEffect(() => {
    drawStore.fetchDraws(loanId);
    return () => {
      drawStore.resetDraws();
    };
  }, []);

  useEffect(() => {
    drawStore.fetchSOWDocuments(loanId, () => {
      setLoading(false);
    });
    return () => {
      drawStore.resetRevisionDocs();
      scopeOfWorkV2Store.reset();
      scopeOfWorkRevisionStore.reset();
    };
  }, []);

  useEffect(() => {
    breadCrumbsStore.setBreadCrumbItems([
      {
        link: true,
        title: 'Dashboard',
        url: globalStore.routeBaseUrl,
        icon: <DashboardRounded />,
      },
      {
        link: true,
        title: `Loan# ${loanId}`,
        url: loanRoutesConfig.loans(loanId).url,
      },
      {
        link: false,
        title: 'Draws',
      },
    ]);
  });

  return (
    <>
      <Box>
        <BorrowerPortalCard cardTitle={'Scope of Work'} noPaddingCardContent cardHeaderStyle={{
          paddingBottom: 16
        }}>
          {
            !loading && scopeOfWorkList?.length == 0 &&
            <Box mx={2}>
              <Alert severity="info">
                There is no approved Scope of Work for this loan.
              </Alert>
            </Box>
          }
          {
            !loading && scopeOfWorkList?.length > 0 &&
            <SOWGrid scopeOfWorkList={scopeOfWorkList} />
          }
          {
            !loading &&
            <Box mt={3} mx={2}>
              <Typography variant='h6'>
                Scope of Work Revisions
              </Typography>
            </Box>
          }
          {
            !loading && revisionList?.length == 0 &&
            <Box my={2} mx={2}>
              <Alert severity="info">
                There is no Scope of Work Revision for this loan.
              </Alert>
            </Box>
          }
          {
            !loading && revisionList?.length > 0 &&
            <Box mt={2}>
              <SOWRevisionGrid loanId={loanId} revisionList={revisionList} />
            </Box>
          }
        </BorrowerPortalCard>
      </Box>
      <Box mt={3}>
        <BorrowerPortalCard
          cardTitle={'Draws'}
          noPaddingCardContent
          cardHeaderAction={
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                push(loanRoutesConfig.loans(loanId).children.drawRequest.url)
              }
            >
              Request a Draw
            </Button>
          }
        >
          <Box my={3} mx={2}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <LoanValuesTable
                  totalConstructionHoldback={chRemaining + chReimbursedToDate}
                  constructionHoldbackReimbursed={chReimbursedToDate}
                  constructionHoldbackReimbursedColor={LoanValuesChartColor.chReimbursedToDate}
                  constructionHoldbackRemaining={chRemaining}
                  constructionHoldbackRemainingColor={LoanValuesChartColor.chRemaining}
                  draws={drawList} />
              </Grid>
              <Grid item xs={12} md={6}>
                <Box width={280} display={'flex'} alignItems={'center'} margin={'auto'}>
                  <LoanValuesChartOnly
                    chRemaining={chRemaining ? chRemaining : 0}
                    chReimbursedToDate={chReimbursedToDate ? chReimbursedToDate : 0}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box mt={4}>
            <Box m={1}>
              <Alert severity="info">
                Click on the Draw # to see more details and to upload the documents that will allow you to complete your draw request.
              </Alert>
            </Box>
            <LoanDrawsGrid loanId={loanId} drawList={drawList} />
          </Box>
        </BorrowerPortalCard>
      </Box>
      <ScopeOfWorkV2FormDialog
        title={"Scope Of Work Revision"}
        open={scopeOfWorkRevisionStore.scopeOfWorkV2FormDialogOpen}
        onClose={() => scopeOfWorkRevisionStore.setScopeOfWorkV2FormDialogOpen(false)}
        store={scopeOfWorkRevisionStore.scopeOfWorkV2FormStore}
      />
      <ScopeOfWorkV2FormDialog
        open={scopeOfWorkV2Store.scopeOfWorkV2FormDialogOpen}
        onClose={() => scopeOfWorkV2Store.setScopeOfWorkV2FormDialogOpen(false)}
        store={scopeOfWorkV2FormStore}
      />
    </>
  );
});
