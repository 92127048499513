import { makeObservable, flow, observable, computed, action } from 'mobx';
import { GlobalStore } from '@roc/feature-app-core';
import { ApiResponse } from '@roc/feature-app-core';
import { DocumentLibraryService } from '../services/documentLibraryService';
import { Tab } from '@roc/ui';
import { Document } from '../types';
import { downloadDocument } from '@roc/feature-utils';

export class DocumentLibraryStore {
  globalStore: GlobalStore;
  documentLibraryService: DocumentLibraryService;
  selectedTab: number;
  paths: string[];
  documents: Document[];

  constructor(globalStore) {
    this.globalStore = globalStore;
    this.documentLibraryService = new DocumentLibraryService();
    this.selectedTab = 0;
    this.documents = [];
    this.paths = [];
    makeObservable(this, {
      getDocuments: flow,
      downloadDocument: flow,
      downloadPublicDocument: flow,
      changeTab: action,
      goToFolder: action,
      moveToFolderInPath: action,
      selectedTab: observable,
      documents: observable,
      paths: observable,
      tabs: computed,
      files: computed,
      showBreadcrumbs: computed,
    });
  }

  reset() {
    this.selectedTab = 0;
    this.documents = [];
    this.paths = [];
  }

  *getDocuments() {
    try {
      const response: ApiResponse = yield this.documentLibraryService.getDocuments();
      this.documents = response.data?.data;
      this.initializePaths();
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while getting documents.',
      });
    }
  }

  goToFolder(folder: string) {
    this.paths.push(folder);
  }

  moveToFolderInPath(index: number) {
    this.paths = this.paths.slice(0, index + 1);
  }

  *downloadDocument(document: string) {
    try {
      const response: ApiResponse = yield this.documentLibraryService.downloadDocument(
        document
      );
      downloadDocument(response?.data, response?.headers, 'download');
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while downloading document.',
      });
    }
  }

  *downloadPublicDocument(document: string) {
    try {
      const response: ApiResponse = yield this.documentLibraryService.downloadPublicDocument(
        document
      );
      downloadDocument(response?.data, response?.headers, 'download');
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while downloading public document.',
      });
    }
  }

  get tabs() {
    return this.documents
      .filter(document => document.folder)
      .map(
        (document): Tab => ({
          label: document.displayFileName,
        })
      );
  }

  changeTab(selectedTab: number) {
    this.selectedTab = selectedTab;
    this.initializePaths();
  }

  private initializePaths() {
    this.paths = [this.documents[this.selectedTab].displayFileName];
  }

  get showDocuments() {
    return this.tabs && this.tabs.length > 1;
  }

  get files(): Document[] {
    let documents = this.documents;
    for (let i = 0; i < this.paths.length; i++) {
      const document = documents.find(
        document => document.displayFileName === this.paths[i]
      );
      documents = document?.files || [];
    }
    return documents;
  }

  get showBreadcrumbs() {
    return this.paths && this.paths.length > 1;
  }
}

export default DocumentLibraryStore;
