import { GlobalStore } from '@roc/feature-app-core';
import { BackgroundReviewFormBaseStore } from './backgroundReviewFormBaseStore';
import { BackgroundItemBaseStore } from './backgroundItemBaseStore';
import { BorrowerBaseService } from '../services/borrowerBaseService';

import { ARREST_RECORD, BANKRUPTCY_RECORD, BORROWER_REVIEW_TYPES, COLLECTION_ACCOUNTS_RECORD, CRIMINAL_RECORD, DOCKET_RECORD, FORECLOSURE_RECORD, GLOBAL_SANCTION_RECORD, LAWSUIT_RECORD, LIEN_JUDGEMENT_RECORD, MORTGAGE_ACCOUNTS_RECORD, OTHER_ACCOUNTS_RECORD, OTHER_SSN_RECORD, PUBLIC_RECORD, QUICK_ANALYSIS_FLAGS, QUICK_CHECKS, TRADELINE_DATA_RECORD, TRADELINE_SUMMARY_RECORD, CHARGE_OFF_ACCOUNTS_RECORD, SSN_ADDRESS_FRAUD_RECORD, EXCLUDED_PARTY_RECORD, OTHER_NAMES_FOR_SSN_RECORD } from '../components/utils/backgroundUtils';
import { MORTGAGE_HISTORY_RECORD } from '../components/utils/backgroundUtils';
import { flow, makeObservable, override } from 'mobx';

export class BackgroundItemExternalStore extends BackgroundItemBaseStore {

  constructor(
    globalStore: GlobalStore,
    backgroundReviewFormBaseStore: BackgroundReviewFormBaseStore
  ) {
    super(globalStore, backgroundReviewFormBaseStore);
    makeObservable(this, {
      getBackgroundReviewData: override,
    });
  }

  *getBackgroundReviewData(reviewType?: string) {
    this.initData();
    this.quickChecks = [];
    this.letterOfExplanation = [];
    this.quickAnalysisFlagsCounter = 0;
    this.escalatedKeywordCounter = 0;
    const response = yield this.borrowerService.getBorrowerDataCollection(Number(this.currentBorrower), this.loanId, reviewType);
    let borrowerData = JSON.parse(response.data.data);
    borrowerData = borrowerData.data;
    this.borrowerName = borrowerData.borrowerName;
    this.borrowerJson = borrowerData;
    this.backgroundDate = borrowerData.backgroundDate;
    this.creditDate = borrowerData.creditDate;
    this.birthdayDate = borrowerData.birthdayDate || borrowerData.birthdate;
    this.ssn = borrowerData.ssn;
    this.equifax = borrowerData.equifax;
    this.transunion = borrowerData.transunion;
    this.experian = borrowerData.experian;
    this.creditComment = borrowerData.creditComment;
    this.assets = borrowerData.assets;
    this.liabilities = borrowerData.liabilities;
    this.netWorth = borrowerData.netWorth;
    this.annualIncome = borrowerData.annualIncome;
    this.experience = borrowerData.experience;
    this.foreclosureDate = borrowerData.foreclosureDate;
    this.bankruptcyDate = borrowerData.bankruptcyDate;
    this.criminalRecord = borrowerData.criminalRecord;
    this.financialCrimes = borrowerData.financialCrimes;
    this.rentalExperience = borrowerData.rentalExperience;
    this.rentalMfExperience = borrowerData.rentalMfExperience;
    this.totalPropertiesOwned = borrowerData.totalPropertiesOwned;
    this.backgroundComments = borrowerData.comment;
    this.lockedData = borrowerData.lockedData;
    this.quickChecks = borrowerData.quickChecks || [];
    this.backgroundSectionDetails.backgroundSection.arrestRecord =
      borrowerData.backgroundSectionDetails?.backgroundSection[this.itemsMap[ARREST_RECORD].name] || [];
    this.backgroundSectionDetails.backgroundSection.bankruptcyRecord =
      borrowerData.backgroundSectionDetails?.backgroundSection[this.itemsMap[BANKRUPTCY_RECORD].name] || [];
    this.backgroundSectionDetails.backgroundSection.criminalRecord =
      borrowerData.backgroundSectionDetails?.backgroundSection[this.itemsMap[CRIMINAL_RECORD].name] || [];
    this.backgroundSectionDetails.backgroundSection.docketRecord =
      borrowerData.backgroundSectionDetails?.backgroundSection[this.itemsMap[DOCKET_RECORD].name] || [];
    this.backgroundSectionDetails.backgroundSection.foreclosureRecord =
      borrowerData.backgroundSectionDetails?.backgroundSection[this.itemsMap[FORECLOSURE_RECORD].name] || [];
    this.backgroundSectionDetails.backgroundSection.globalSanction =
      borrowerData.backgroundSectionDetails?.backgroundSection[this.itemsMap[GLOBAL_SANCTION_RECORD].name] || [];
    this.backgroundSectionDetails.backgroundSection.lawsuitRecord =
      borrowerData.backgroundSectionDetails?.backgroundSection[this.itemsMap[LAWSUIT_RECORD].name] || [];
    this.backgroundSectionDetails.backgroundSection.lienJudgmentRecord =
      borrowerData.backgroundSectionDetails?.backgroundSection[this.itemsMap[LIEN_JUDGEMENT_RECORD].name] || [];
    this.backgroundSectionDetails.backgroundSection.otherSSNAssociated =
      borrowerData.backgroundSectionDetails?.backgroundSection[this.itemsMap[OTHER_SSN_RECORD].name] || [];
    this.backgroundSectionDetails.backgroundSection.ssnAddressFraudRecord =
      borrowerData.backgroundSectionDetails?.backgroundSection[this.itemsMap[SSN_ADDRESS_FRAUD_RECORD].name] || [];
    this.backgroundSectionDetails.backgroundSection.excludedPartyRecord =
      borrowerData.backgroundSectionDetails?.backgroundSection[this.itemsMap[EXCLUDED_PARTY_RECORD].name] || [];
    this.backgroundSectionDetails.backgroundSection.otherNamesRecord =
      borrowerData.backgroundSectionDetails?.backgroundSection[this.itemsMap[OTHER_NAMES_FOR_SSN_RECORD].name] || [];
    this.quickAnalysisFlags = borrowerData.quickAnalysisFlags ? borrowerData.quickAnalysisFlags : [];
    this.escalatedKeywordFlags = borrowerData.escalatedKeywordFlags || [];

    // Credit
    this.backgroundSectionDetails.backgroundSection.tradelineSummary = borrowerData[TRADELINE_SUMMARY_RECORD] || [];
    this.backgroundSectionDetails.backgroundSection.tradelinesData = borrowerData[TRADELINE_DATA_RECORD] || [];
    this.backgroundSectionDetails.backgroundSection.mortgageHistory = borrowerData[MORTGAGE_HISTORY_RECORD] || [];
    this.backgroundSectionDetails.backgroundSection.otherLateTradelines = borrowerData[OTHER_ACCOUNTS_RECORD] || [];
    this.backgroundSectionDetails.backgroundSection.mortgageLateTradelines = borrowerData[MORTGAGE_ACCOUNTS_RECORD] || [];
    this.backgroundSectionDetails.backgroundSection.collectionTradelines = borrowerData[COLLECTION_ACCOUNTS_RECORD] || [];
    this.backgroundSectionDetails.backgroundSection.chargeOffTradelines = borrowerData[CHARGE_OFF_ACCOUNTS_RECORD] || [];
    this.backgroundSectionDetails.backgroundSection.publicRecords = borrowerData[PUBLIC_RECORD] || [];

    if (reviewType === BORROWER_REVIEW_TYPES.BORROWER_CREDIT_REVIEW) {
      this.letterOfExplanation = borrowerData.letterOfExplanationList || [];
    } else {
      this.validateLetterOfExplanation();
    }

  };

}