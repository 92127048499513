export const downloadDocument = (
  bytes,
  headers,
  action = '',
  filename = ''
) => {
  if (filename) {
    headers['content-disposition'] = filename;
  }
  const file = parseDocumentBuffer(bytes, headers, action);

  if (isIE()) {
    executeIEDownload(file, action);
  } else {
    executeDownload(file, action);
  }
};

export const getDocumentAsBlob = (
  bytes,
  headers,
  blobContentType,
) => {
  const name = headers['content-disposition'].replace(
    /^attachment; filename="(.+)"$/,
    '$1'
  );
  const blob = new Blob([bytes], { type: blobContentType });
  return {
    blob,
    name
  };
};

const executeDownload = (file, action) => {
  const link = document.createElement('a');

  document.body.appendChild(link);

  if (action === 'download') {
    link.setAttribute('download', file.name);
  } else {
    link.setAttribute('target', '_blank');
  }

  link.setAttribute('href', URL.createObjectURL(file.blob));
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(file.blob);
};

const executeIEDownload = (file, action) => {
  if (action === 'download') {
    navigator.msSaveOrOpenBlob(file.blob, file.name);
  } else {
    navigator.msSaveOrOpenBlob(file.blob);
  }
};

const isIE = () => {
  return typeof navigator !== 'undefined' && navigator.msSaveOrOpenBlob;
};

const parseDocumentBuffer = (bytes, headers, action) => {
  const fileType =
    action === 'download'
      ? 'application/octet-stream'
      : headers['content-type'];
  const blob = new Blob([bytes], { type: fileType });
  let name;

  if (headers['content-disposition']) {
    name = headers['content-disposition'].replace(
      /^attachment; filename="(.+)"$/,
      '$1'
    );
  } else {
    if (headers['content-type'].indexOf('pdf') !== -1) {
      name = 'unknow_filename.pdf';
    } else if (headers['content-type'].indexOf('excel') !== -1) {
      name = 'unknow_filename.xlsx';
    } else {
      console.error('Unable to find file name and extension');
    }
  }
  return { blob, name };
};

export const blobToBase64 = (blob: Blob): Promise<string> => {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const dataURL = reader.result as string;
      const [, base64] = dataURL.split(',');
      resolve(base64);
    };
    reader.readAsDataURL(blob);
  });
};
