import { Route } from 'react-router';
import { Page } from '@roc/feature-app-core';
import { useRocInternalPortalStore } from '../../../hooks';
import { LiquidityReport } from '@roc/feature-liquidity-report';

export const getLiquidityReportInternalRoutesConfig = (
  basePath: string,
  baseUrl: string
) => {
  return {
    liquidityReport: {
      path: `${basePath}/liquidity-report`,
      url: `${baseUrl}/liquidity-report`,
      documentTitle: 'Liquidity report'
    }
  }
};

export const getLiquidityReportInternalRoutes = (basePath: string, baseUrl: string) => {
  const config = getLiquidityReportInternalRoutesConfig(basePath, baseUrl);
  const { liquidityReportInternalStore } = useRocInternalPortalStore();

  return {
    liquidityReport: (
      <Route exact path={config.liquidityReport.path}>
        <Page routeDefinition={config.liquidityReport}>
          <LiquidityReport store={liquidityReportInternalStore} />
        </Page>
      </Route>
    )
  }
}
