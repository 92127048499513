import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useStore } from '@roc/client-portal-shared/hooks';
import { TestDataAttribute } from '@roc/ui';
import { formatCurrency } from '@roc/ui/utils';
import { observer } from 'mobx-react';
import { CustomCard } from '../customCard';
import { SubmissionVsUnderwritingBaseStore } from '../common/store/submissionVsUnderwritingBaseStore';
import { useEffect } from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  differentValues: {
    fontWeight: 'bold',
    fontSize: '1rem',
  }
}));

const renderHeading = value => {
  return (
    <Typography variant="body1">
      <strong>{value}</strong>
    </Typography>
  );
};

interface SubmissionVsUnderwritingFormProps {
  store: SubmissionVsUnderwritingBaseStore;
  loanId: Number;
  isInternal: Boolean;
  loanDetails: {};
}


export const SubmissionVsUnderwritingValuesCardBridge = observer(({ store, loanId, isInternal, loanDetails }: SubmissionVsUnderwritingFormProps) => {


  useEffect(() => {
    if (isInternal) {
      store.getSubmissionVsUnderwriting(loanId, null);
    } else {
      store.getSubmissionVsUnderwriting(loanId, loanDetails);
    }
  }, []);

  const classes = useStyles();
  let style = isInternal ? { width: '100%', borderRadius: '0' } : {};

  const calculatedLoanData = store?.calculatedLoanData;
  const loanComparisonValues = store?.loanComparisonValues;

  const getClassNameIfDifferentValues = (value1, value2) => {
    if (value1 && value2 && value1 !== value2) {
      return classes.differentValues;
    }

    return '';
  }

  return (
    <CustomCard title="Submission Values VS. Underwriting Values" paperStyle={style}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell align="right">{renderHeading('Submission')}</TableCell>
            <TableCell align="right">{renderHeading('Underwriting')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>{renderHeading('Borrower FICO ')}</TableCell>
            <TestDataAttribute id="submitted_borrower_fico">
              <TableCell align="right" className={getClassNameIfDifferentValues(loanComparisonValues?.submittedBorrowerFICO, calculatedLoanData?.maxBorrowerFICOScore)}>
                {loanComparisonValues?.submittedBorrowerFICO
                  ? loanComparisonValues?.submittedBorrowerFICO
                  : '-'}
              </TableCell>
            </TestDataAttribute>
            <TestDataAttribute id="underwriting_borrower_fico">
              <TableCell align="right" className={getClassNameIfDifferentValues(loanComparisonValues?.submittedBorrowerFICO, calculatedLoanData?.maxBorrowerFICOScore)}>
                {calculatedLoanData?.maxBorrowerFICOScore
                  ? calculatedLoanData?.maxBorrowerFICOScore
                  : '-'}
              </TableCell>
            </TestDataAttribute>
          </TableRow>
          <TableRow>
            <TableCell>{renderHeading('Experience Score ')}</TableCell>
            <TestDataAttribute id="submitted_experience_score">
              <TableCell align="right" className={getClassNameIfDifferentValues(loanComparisonValues?.submittedBorrowerExperience, calculatedLoanData?.maxExperienceScore)}>
                {loanComparisonValues?.submittedBorrowerExperience
                  ? loanComparisonValues?.submittedBorrowerExperience
                  : '-'}
              </TableCell>
            </TestDataAttribute>
            <TestDataAttribute id="underwriting_experience_score">
              <TableCell align="right" className={getClassNameIfDifferentValues(loanComparisonValues?.submittedBorrowerExperience, calculatedLoanData?.maxExperienceScore)}>
                {calculatedLoanData?.maxExperienceScore
                  ? calculatedLoanData?.maxExperienceScore
                  : '-'}
              </TableCell>
            </TestDataAttribute>
          </TableRow>
          <TableRow>
            <TableCell>{renderHeading('Loan Amount')}</TableCell>
            <TestDataAttribute id="submitted_loan_amount">
              <TableCell align="right" className={getClassNameIfDifferentValues(loanComparisonValues?.submittedLoanAmount, store?.amount)}>
                {loanComparisonValues?.submittedLoanAmount
                  ? formatCurrency(loanComparisonValues?.submittedLoanAmount)
                  : '-'}
              </TableCell>
            </TestDataAttribute>
            <TestDataAttribute id="underwriting_loan_amount">
              <TableCell align="right" className={getClassNameIfDifferentValues(loanComparisonValues?.submittedLoanAmount, store?.amount)}>
                {store?.amount
                  ? formatCurrency(store?.amount)
                  : '-'}
              </TableCell>
            </TestDataAttribute>
          </TableRow>
          <TableRow>
            <TableCell>{renderHeading('Purchase price')}</TableCell>
            <TestDataAttribute id="submitted_purchase_price">
              <TableCell align="right" className={getClassNameIfDifferentValues(loanComparisonValues?.submittedPurchasePrice, calculatedLoanData?.aggregatePurchasePrice)}>
                {loanComparisonValues?.submittedPurchasePrice
                  ? formatCurrency(loanComparisonValues?.submittedPurchasePrice)
                  : '-'}
              </TableCell>
            </TestDataAttribute>
            <TestDataAttribute id="underwriting_purchase_price">
              <TableCell align="right" className={getClassNameIfDifferentValues(loanComparisonValues?.submittedPurchasePrice, calculatedLoanData?.aggregatePurchasePrice)}>
                {calculatedLoanData?.aggregatePurchasePrice ? formatCurrency(calculatedLoanData?.aggregatePurchasePrice) : '-'}
              </TableCell>
            </TestDataAttribute>
          </TableRow>
          <TableRow>
            <TableCell>{renderHeading('Renovation Budget')}</TableCell>
            <TestDataAttribute id="submitted_reno_budget">
              <TableCell align="right" className={getClassNameIfDifferentValues(loanComparisonValues?.submittedConstructionHoldback, calculatedLoanData?.aggregateRenovationBudget)}>
                {loanComparisonValues?.submittedConstructionHoldback
                  ? formatCurrency(loanComparisonValues?.submittedConstructionHoldback)
                  : '-'}
              </TableCell>
            </TestDataAttribute>
            <TestDataAttribute id="aggregate_reno_budget">
              <TableCell align="right" className={getClassNameIfDifferentValues(loanComparisonValues?.submittedConstructionHoldback, calculatedLoanData?.aggregateRenovationBudget)}>
                {calculatedLoanData?.aggregateRenovationBudget ? formatCurrency(calculatedLoanData?.aggregateRenovationBudget) : '-'}
              </TableCell>
            </TestDataAttribute>
          </TableRow>
          <TableRow>
            <TableCell>{renderHeading('As-is value')}</TableCell>
            <TestDataAttribute id="submitted_as_is_value">
              <TableCell align="right" className={getClassNameIfDifferentValues(loanComparisonValues?.submittedAsIsValue, calculatedLoanData?.aggregateAsIsValue)}>
                {loanComparisonValues?.submittedAsIsValue
                  ? formatCurrency(loanComparisonValues?.submittedAsIsValue)
                  : '-'}
              </TableCell>
            </TestDataAttribute>
            <TestDataAttribute id="underwriting_as_is_value">
              <TableCell align="right" className={getClassNameIfDifferentValues(loanComparisonValues?.submittedAsIsValue, calculatedLoanData?.aggregateAsIsValue)}>
                {calculatedLoanData?.aggregateAsIsValue ? formatCurrency(calculatedLoanData?.aggregateAsIsValue) : '-'}
              </TableCell>
            </TestDataAttribute>
          </TableRow>
          <TableRow>
            <TableCell>{renderHeading('ARV')}</TableCell>
            <TestDataAttribute id="submitted_after_repair_value">
              <TableCell align="right" className={getClassNameIfDifferentValues(loanComparisonValues?.submittedLoanARV, calculatedLoanData?.aggregateAfterRepairValue)}>
                {loanComparisonValues?.submittedLoanARV
                  ? formatCurrency(loanComparisonValues?.submittedLoanARV)
                  : '-'}
              </TableCell>
            </TestDataAttribute>
            <TestDataAttribute id="underwriting_after_repair_value">
              <TableCell align="right" className={getClassNameIfDifferentValues(loanComparisonValues?.submittedLoanARV, calculatedLoanData?.aggregateAfterRepairValue)}>
                {calculatedLoanData?.aggregateAfterRepairValue ? formatCurrency(calculatedLoanData?.aggregateAfterRepairValue) : '-'}
              </TableCell>
            </TestDataAttribute>
          </TableRow>
          <TableRow>
            <TableCell>{renderHeading('Sourcing')}</TableCell>
            <TestDataAttribute id="submitted_sourcing">
              <TableCell align="right" className={getClassNameIfDifferentValues(loanComparisonValues?.submittedSourcing, calculatedLoanData?.sourcing)}>
                {loanComparisonValues?.submittedSourcing
                  ? loanComparisonValues?.submittedSourcing
                  : '-'}
              </TableCell>
            </TestDataAttribute>
            <TestDataAttribute id="sourcing">
              <TableCell align="right" className={getClassNameIfDifferentValues(loanComparisonValues?.submittedSourcing, calculatedLoanData?.sourcing)}>
                {calculatedLoanData?.sourcing || '-'}
              </TableCell>
            </TestDataAttribute>
          </TableRow>
          <TableRow>
            <TableCell>{renderHeading('Wholesaler Fee')}</TableCell>
            <TestDataAttribute id="submitted_wholesaler_fee">
              <TableCell align="right" className={getClassNameIfDifferentValues(loanComparisonValues?.submittedWholesalerFee, calculatedLoanData?.aggregateWholesalerFee)}>
                {loanComparisonValues?.submittedWholesalerFee
                  ? formatCurrency(loanComparisonValues?.submittedWholesalerFee)
                  : '-'}
              </TableCell>
            </TestDataAttribute>
            <TestDataAttribute id="underwriting_wholesaler_fee">
              <TableCell align="right" className={getClassNameIfDifferentValues(loanComparisonValues?.submittedWholesalerFee, calculatedLoanData?.aggregateWholesalerFee)}>
                {calculatedLoanData?.aggregateWholesalerFee ? formatCurrency(calculatedLoanData?.aggregateWholesalerFee) : '-'}
              </TableCell>
            </TestDataAttribute>
          </TableRow>
          <TableRow>
            <TableCell>{renderHeading('Seller Credit')}</TableCell>
            <TestDataAttribute id="submitted_seller_credit">
              <TableCell align="right" className={getClassNameIfDifferentValues(loanComparisonValues?.submittedSellerCreditAmount, calculatedLoanData?.sellerCreditAmount)}>
                {loanComparisonValues?.submittedSellerCreditAmount
                  ? formatCurrency(loanComparisonValues?.submittedSellerCreditAmount)
                  : '-'}
              </TableCell>
            </TestDataAttribute>
            <TestDataAttribute id="underwriting_seller_credit">
              <TableCell align="right" className={getClassNameIfDifferentValues(loanComparisonValues?.submittedSellerCreditAmount, calculatedLoanData?.sellerCreditAmount)}>
                {calculatedLoanData?.sellerCreditAmount ? formatCurrency(calculatedLoanData?.sellerCreditAmount) : '-'}
              </TableCell>
            </TestDataAttribute>
          </TableRow>
        </TableBody>
      </Table>
    </CustomCard>
  );
});
