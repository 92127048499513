import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { formatCurrency } from '@roc/ui/utils';
import { observer } from 'mobx-react';
import React from 'react';
import {
  PaperList,
  PricerPaper,
} from '../../components/rentalPricerComponents';

export const NetOperatingIncome = observer(({ store }) => {
  const { form } = store.pricerStore;
  const {
    totalAnnualIncome,
    totalAnnualExpenses,
    totalAnnualNOI,
  } = form.fields;
  return (
    <PaperList
      items={[
        {
          label: 'Total Annual Income',
          value: formatCurrency(totalAnnualIncome.value),
        },
        {
          label: 'Annual Expenses',
          value: formatCurrency(totalAnnualExpenses.value),
        },
        {
          label: 'Annual NOI',
          value: formatCurrency(totalAnnualNOI.value),
        },
      ]}
    />
  );
});
