import { Grid } from '@material-ui/core';
import { Layout } from '@roc/feature-app-core';
import { useAppraisalsStore } from '@roc/feature-appraisals';
import { isNotBlank } from '@roc/feature-utils';
import {
  GridRowSelectedEvent,
  RadioField,
  SelectMode,
  trueFalseOptions
} from '@roc/ui';
import { StandaloneAppraisalsGrid } from '@roc/feature-appraisals';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';

export const ChooseExistingPropertyAppraisal = observer(
  ({ store, onRowSelected, ...rest }) => {
    const formFields = store.form.fields;
    const { appraisalsStore } = useAppraisalsStore();

    useEffect(() => {
      appraisalsStore.standaloneAppraisalsGridStore.resetAndFetchGridData();
    }, [appraisalsStore]);

    const handleRowSelected = (e: GridRowSelectedEvent) => {
      const { node } = e;

      if (node.isSelected()) {
        store.onFieldChange('orderId', node.data.orderId);
      } else {
        store.onFieldChange('orderId', null);
      }

      onRowSelected(e);
    };

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <RadioField
            standaloneLabel
            label="Do you want to choose existing property Appraisal?"
            value={formFields.chooseExistingAppraisal.value}
            errorText={formFields.chooseExistingAppraisal.error}
            error={isNotBlank(formFields.chooseExistingAppraisal.error)}
            name={'chooseExistingAppraisal'}
            options={trueFalseOptions}
            onChange={value =>
              store.onFieldChange('chooseExistingAppraisal', value === 'true')
            }
            fullWidth
            required
            row
            testId="chooseExistingAppraisal"
            {...rest}
          />
        </Grid>
        {formFields.chooseExistingAppraisal.value && (
          <Grid item xs={12}>
            <Layout>
              <StandaloneAppraisalsGrid
                onOpenCommunication={() => { }}
                selectMode={SelectMode.SINGLE}
                onRowSelected={handleRowSelected}
                showActions={false}
                showFilterButton={true}
                domLayout="autoHeight"
              />
            </Layout>
          </Grid>
        )}
      </Grid>
    );
  }
);