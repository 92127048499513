import { action, computed, flow, makeObservable, observable } from 'mobx';
import { GlobalStore } from '@roc/feature-app-core';
import { DocumentStore } from '@roc/feature-documents';
import { LoanStore } from '@roc/feature-loans';
import { FormStore } from '@roc/feature-app-core';
import { FundingPreferenceService } from '../../../services/documentForms/fundingPreferenceService';
import {
  DocumentName,
  isEmptyString,
  noneSelected,
  stateMapping,
} from '@roc/feature-utils';
import { EditBorrowerEntityMemberStore } from './editBorrowerEntityMemberStore';
import { EditBorrowerStore } from './editBorrowerStore';
import { shouldBeAbleToSubmitSpecificForm } from '../../../utils/documentForms';

const fundingPreferenceForm = {
  fields: {
    fundingPreference: {
      value: '',
      error: null,
      rule: '',
    },
    interestStartMechanism: {
      value: '',
      error: null,
      rule: '',
    },
    attorneyFee: {
      value: '',
      error: null,
      rule: '',
    },
    rocLegalReviewFee: {
      value: '',
      error: null,
      rule: '',
    },
    defaultRate: {
      value: '',
      error: null,
      rule: '',
    },
    trustee: {
      value: '',
      error: null,
      rule: '',
    },
    trusteeAddress: {
      value: '',
      error: null,
      rule: '',
    },
    onlineClosingFeeRequired: {
      value: '',
      error: null,
      rule: '',
    },
    addWitnessLines: {
      value: '',
      error: null,
      rule: '',
    },
    primaryPropertyState: {
      value: '',
      error: null,
      rule: '',
    },
    isCema: {
      value: '',
      error: null,
      rule: '',
    },
    newMoneyAmount: {
      value: '',
      error: null,
      rule: [{ required_if: ['isCema', true] }],
      message: 'This field is required.',
    },
    shouldHaveBuildingDocs: {
      value: '',
      error: null,
      rule: '',
    },
    lenderName: {
      value: '',
      error: null,
      rule: '',
    },
    operatingState: {
      value: 'NONE',
      error: null,
      rule: '',
    },
    fedexFee: {
      value: '',
      error: null,
      rule: '',
    },
    numberOfEntities: {
      value: '',
      error: null,
      rule: '',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};
const SELENE = 'Selene';

export interface AdditionalBorrowerEntityMember {
  fullName: string;
  roleInEntity: string;
}

export class FundingPreferenceStore extends FormStore {
  private documentStore: DocumentStore;
  private globalStore: GlobalStore;
  loanStore: LoanStore;
  loanId: string;
  fundingPreferenceService: FundingPreferenceService;
  editBorrowerEntityMembersStore: EditBorrowerEntityMemberStore;
  editBorrowerStore: EditBorrowerStore;
  additionalBorrowerEntityMembers: AdditionalBorrowerEntityMember[];
  borrowers: any[];
  servicer: string;

  constructor(
    loanStore: LoanStore,
    documentStore: DocumentStore,
    globalStore: GlobalStore
  ) {
    super({ ...fundingPreferenceForm }, globalStore);
    this.documentStore = documentStore;
    this.globalStore = globalStore;
    this.loanStore = loanStore;
    this.fundingPreferenceService = new FundingPreferenceService();
    this.editBorrowerEntityMembersStore = new EditBorrowerEntityMemberStore(
      globalStore,
      this
    );
    this.editBorrowerStore = new EditBorrowerStore(globalStore, this);
    makeObservable(this, {
      additionalBorrowerEntityMembers: observable,
      borrowers: observable,
      submitFundingEntity: flow,
      loanId: observable,
      canEdit: computed,
      setLoanId: action,
      setLenderOperatingState: flow,
    });
  }

  initialize() {
    const { loanDetails } = this.loanStore;
    const { loanClosingData } = loanDetails;
    this.resetStore();

    this.additionalBorrowerEntityMembers = loanDetails.borrowerEntity
      ?.additionalBorrowerEntityMembers
      ? JSON.parse(loanDetails.borrowerEntity?.additionalBorrowerEntityMembers)
      : [];
    this.servicer = loanDetails.servicer;
    this.borrowers = [...(loanDetails.borrowers || [])];
    this.loadForm({
      fundingPreference: loanDetails.fundingPreference,
      interestStartMechanism: loanClosingData?.interestStartMechanism,
      attorneyFee: loanClosingData?.attorneyFee,
      rocLegalReviewFee: loanDetails.rocLegalReviewFee,
      defaultRate: loanDetails.defaultRate,
      trustee: loanDetails.loanClosingData?.trustee,
      trusteeAddress: loanDetails.loanClosingData?.trusteeAddress,
      numberOfEntities: loanClosingData?.numberOfEntities,
      onlineClosingFeeRequired: loanClosingData?.onlineClosingFeeRequired,
      addWitnessLines: loanClosingData?.addWitnessLines,
      primaryPropertyState: loanDetails.primaryPropertyState,
      isCema: loanClosingData?.cema,
      newMoneyAmount: loanClosingData?.newMoneyAmount,
      shouldHaveBuildingDocs: loanClosingData?.shouldHaveBuildingDocs,
      lenderName: loanDetails.lenderName,
      fedexFee: loanClosingData?.fedexFee,
    });
    this.setLenderOperatingState();
  }

  *setLenderOperatingState() {
    try {
      const response = yield this.fundingPreferenceService.getLenderOperatingState(
        this.loanStore.loanDetails.lenderId
      );
      const operatingState = stateMapping.find(
        item => item.value === response.data.data
      )
        ? response.data.data
        : noneSelected.value;
      this.form.fields.operatingState.value = operatingState;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'An error occurred while getting lender operating state',
      });
    }
  }

  setLoanId(loanId: string) {
    this.loanId = loanId;
  }

  updateBorrowersField = ({ data, rowIndex, colDef }) => {
    this.form.fields.borrowers.value[rowIndex][colDef.field] =
      data[colDef.field];
  };

  *submitFundingEntity() {
    try {
      this.runFormValidationWithMessage();
      if (this.form.meta.isValid) {
        const data = this.getPayload();
        yield this.fundingPreferenceService.updateFundingPreference(
          this.loanId,
          data
        );
        yield this.documentStore.documentFormStore.closeCurrentForm();
        yield this.loanStore.refreshLoanDetails();
        yield this.documentStore.refetchDocuments();
        this.globalStore.notificationStore.showSuccessNotification({
          message: 'Funding preference data saved succesfully',
        });
      }
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'An error occurred while saving funding preference data',
      });
    }
  }

  private getPayload() {
    const formValues = this.getFormValues();
    const borrowers = this.borrowers;
    const additionalBorrowerEntityMembers = this
      .additionalBorrowerEntityMembers;

    return {
      ...formValues,
      data: {
        borrowers: borrowers.map(borrower => ({
          borrowerId: borrower.borrowerId,
          firstName: borrower.firstName,
          lastName: borrower.lastName,
          middleName: borrower.middleName,
          suffix: borrower.suffix,
          title: borrower.roleInEntity,
        })),
        json: JSON.stringify(additionalBorrowerEntityMembers),
        operatingState: formValues.operatingState,
      },
    };
  }

  resetStore() {
    this.reset();
    this.borrowers = [];
    this.additionalBorrowerEntityMembers = [];
  }

  get canEdit() {
    return this.globalStore.userFeatures.isClosingAttorney;
  }

  get canAttorneysEditFundingPreference() {
    return this.globalStore.userFeatures.isClosingAttorney && this.servicer !== SELENE;
  }
}

export default FundingPreferenceStore;
