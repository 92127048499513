import { Box } from '@material-ui/core';
import { Edit, Lock, LockOpen } from '@material-ui/icons';
import { useBaseStore } from '@roc/feature-app-core';
import { insertIf } from '@roc/feature-utils';
import { ColDef, DataGrid, IconButton } from '@roc/ui';
import { observer } from 'mobx-react';
import EditBorrowerDialog from './editBorrowerDialog';

const isLocked = borrower => {
  return (
    borrower?.portalProjectsActive > 0 || borrower?.portalProjectsCompleted > 0
  );
};

export const BorrowersTab = observer(({ store }) => {
  const { globalStore } = useBaseStore();

  const columns: ColDef[] = [
    {
      field: 'locked',
      headerName: '',
      maxWidth: 80,
      cellRenderer: 'lockedCellRenderer',
    },
    {
      field: 'roleInEntity',
      headerName: 'Title',
      minWidth: 200,
    },
    {
      field: 'firstName',
      headerName: 'First Name',
      minWidth: 200,
    },
    {
      field: 'middleName',
      headerName: 'Middle Name',
      minWidth: 200,
    },
    {
      field: 'lastName',
      headerName: 'Last Name',
      minWidth: 200,
    },
    {
      field: 'suffix',
      headerName: 'Suffix',
      minWidth: 200,
    },
    ...insertIf(store.canEdit, [
      {
        field: 'actions',
        headerName: '',
        cellRenderer: 'actionsCellRenderer',
        maxWidth: 120,
      },
    ]),
  ];

  const edit = row => {
    store.editBorrowerStore.openEdit(row);
  };

  return (
    <>
      <DataGrid
        columns={columns}
        rows={store.borrowers}
        domLayout="autoHeight"
        frameworkComponents={{
          lockedCellRenderer: params => {
            const borrower = params.node.data;
            return (
              <Box
                display={'flex'}
                alignItems="center"
                justifyContent={'center'}
              >
                {isLocked(borrower) ? (
                  <Lock color="error" />
                ) : (
                  <LockOpen color="primary" />
                )}
              </Box>
            );
          },
          actionsCellRenderer: params => {
            const borrower = params.node.data;
            return (
              <Box pr={1}>
                <IconButton
                  testId="edit"
                  onClick={() => edit(borrower)}
                  disabled={isLocked(borrower)}
                >
                  <Edit color={isLocked(borrower) ? 'disabled' : 'primary'} />
                </IconButton>
              </Box>
            );
          },
        }}
      />
      <span style={{ color: 'red' }}>
        * Borrower name cannot be changed as there is already a funded loan. Please reach out to the closing team if you have any questions.
      </span>
      <EditBorrowerDialog fundingPreferenceStore={store} />
    </>
  );
});
