import { Service } from '@roc/client-portal-shared/services';

const url = {
  CREDIT_AUTHORIZATION: '/api/v1/docusign/creditAuthorization',
  CREDIT_AUTHORIZATION_SAMPLE: '/api/v1/docusign/creditAuthorizationSample',
  CREATE_IDENTITY_VERIFICATION: '/api/v1/user/public/identityVerification/create',
};

export class UserService extends Service {
  submitCreditAuthorizationFormToDocusign(request) {
    //return this.get(url.CREDIT_AUTHORIZATION_SAMPLE);
    return this.post(url.CREDIT_AUTHORIZATION, request);
  }

  identityVerification(requestId, experienceTransactionId) {
    return this.post(`${url.CREATE_IDENTITY_VERIFICATION}`, {
      requestId,
      experienceTransactionId
    });
  }
}
