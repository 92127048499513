import { Chip } from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';

export const BooleanChip = ({ value }) => {
  const color = value ? green : red;
  return (
    <Chip
      label={value ? 'Yes' : 'No'}
      style={{
        fontWeight: 0,
        backgroundColor: color[200],
        color: color[900],
      }}
    />
  );
};
