import { Card, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { EarningsCard, EarningsCardSymbol } from './earningsCard';
import { observer } from 'mobx-react';
import { QuoteTypeStore } from '../stores/quoteTypeStore';
import { useBaseStore } from '@roc/feature-app-core';

interface EarningsCardsProps {
  store: QuoteTypeStore;
}

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#F6EEFF',
    paddingTop: theme.spacing(1),
    textAlign: 'center',
    height: '100%',
    borderRadius: 0,
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
  },
  title: {
    fontWeight: 'bold',
    fontSize: '26px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    textAlign: 'center',
  },
}));

export const EarningsCards = observer(({ store }: EarningsCardsProps) => {
  const classes = useStyles();
  const { globalStore } = useBaseStore();
  const { loanEconomicsStore } = store;
  const lenderTradeName = globalStore.lenderInfo?.lenderTradeName;
  const outputs = loanEconomicsStore.outputs;

  return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Card className={`${classes.root}`} elevation={0}>
            <Typography variant="subtitle1" className={classes.title}>
              {lenderTradeName} Earnings
            </Typography>
          </Card>
        </Grid>
      </Grid>
      <Grid container spacing={0}>
        <Grid item xs={3}>
          <EarningsCard
            title={`At Closing`}
            earnings={outputs?.lenderMakesNow}
            percentage={outputs?.totalLenderPoints}
            percentageDescription="of the Total Loan"
            position="left"
            tooltip="The combination of origination points and fees paid out when the loan closes."
          />
        </Grid>
        <Grid item xs={1}>
          <EarningsCardSymbol text='+' />
        </Grid>
        <Grid item xs={3}>
          <EarningsCard
            title="Future Estimate"
            earnings={outputs?.lenderFutureEarnings}
            percentage={outputs?.lenderYieldSpread}
            percentageDescription="Yield Spread"
            position="center"
            tooltip="This amount is an estimate. Your earnings will vary monthly based on how much of the construction holdback your borrower draws."
          />
        </Grid>
        <Grid item xs={1}>
          <EarningsCardSymbol text='=' />
        </Grid>
        <Grid item xs={4}>
          <EarningsCard
            title={`Potential Total`}
            earnings={outputs?.lenderTotalEarnings}
            position="right"
          />
        </Grid>
      </Grid>
    </>
  );
});
