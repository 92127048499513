import { Service } from '@roc/feature-app-core';

const url = {
  NOTE_ALLONGE: '/api/v1/loan/splsigning/noteAllonge',
  ASSIGNMENT_AND_CONVEYANCE: '/api/v1/loan/splsigning/assignmentAndConveyance',
  GET_STATUS_BY_TYPE_AND_ID: '/api/v1/loan/splsigning/getStatusByTypeAndId',
};

export class DocusignService extends Service {
  getNoteAllongeDocusignURL = loanId => {
    return this.post(`${url.NOTE_ALLONGE}?loanId=${loanId}`, {});
  };

  getAssignmentAndConveyanceDocusignURL = loanId => {
    return this.post(`${url.ASSIGNMENT_AND_CONVEYANCE}?loanId=${loanId}`, {});
  };

  getStatusOfDocusignRequestOfEntityWithId = (entityType, loanId) => {
    return this.get(
      `${url.GET_STATUS_BY_TYPE_AND_ID}?entityType=${entityType}&loanId=${loanId}`
    );
  };
}
