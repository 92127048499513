import { makeObservable } from 'mobx';
import { GlobalStore } from '@roc/feature-app-core';
import { FormStore } from '@roc/feature-app-core';
import { action } from 'mobx';

const form = {
  fields: {
    reasonForRemoval: {
      value: '',
      error: null,
      rule: 'required',
      message: 'A reason for removal is required',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export class RemoveLeadFromQueueStore extends FormStore {
  globalStore: GlobalStore;
  constructor(globalStore) {
    super({ ...form }, globalStore);

    makeObservable(this, {
      onChange: action,
    });
  }

  onChange = (field, value) => {
    this.onFieldChange(field, value);
  };

}
export default RemoveLeadFromQueueStore;