import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { ChevronLeft } from '@material-ui/icons';
import { Paper } from '@roc/ui';
import clsx from 'clsx';
import React, { ReactNode } from 'react';

export interface LayoutProps {
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
  heading?: string;
  headingClass?: string;
  headingBoxClass?: string;
  title?: string | ReactNode;
  titleBoxClass?: string;
  subtitle?: string;
  subtitleBoxClass?: string;
  footer?: string;
  footerBoxClass?: string;
  children: ReactNode | ReactNode[];
  bodyContentBoxClass?: string;
  onBack?: onBackFunc;
  height?: number | string;
  useCalculatedHeight?: boolean;
  minHeight?: number | string;
  wrapWithPaper?: boolean,
  paperClass?: string,
  paperTitleComponent?: React.ReactNode,
  containerClass?: string,
}

interface onBackFunc {
  (): void;
}

const useStyles = makeStyles(theme => ({
  container: {
    minWidth: 300,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0),
    },
  },
  containerBox: {
    height: '100%',
    padding: theme.spacing(0.25, 0),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0.5),
    },
  },
  heading: {
    textAlign: 'left',
  }
}));

export const Layout = ({
  maxWidth = 'xl',
  heading,
  headingClass,
  headingBoxClass,
  onBack,
  title,
  titleBoxClass,
  subtitle,
  subtitleBoxClass,
  footer,
  footerBoxClass,
  children,
  bodyContentBoxClass,
  height,
  minHeight = 600,
  wrapWithPaper = false,
  paperClass,
  paperTitleComponent,
  containerClass,
}: LayoutProps) => {
  const { spacing } = useTheme();
  const classes = useStyles();

  const getHeaderStyles = () => ({
    minHeight,
    height,
  });

  const layoutBody = (
    <Box
      display="flex"
      flexDirection="column"
      className={classes.containerBox}
    >
      {heading && (
        <Box mt={4} className={headingBoxClass}>
          <Typography variant="h4" className={clsx([classes.heading, headingClass])} title={heading}>
            {heading}
          </Typography>
        </Box>
      )}
      {title && (
        <Box mt={3} className={titleBoxClass}>
          {onBack && (
            <Box style={{ float: 'left', marginTop: spacing(-1) }}>
              <IconButton
                aria-label="back"
                color="secondary"
                onClick={onBack}
              >
                <ChevronLeft fontSize="large" />
              </IconButton>
            </Box>
          )}
          <Box>
            <Typography variant="h4" gutterBottom>
              {title}
            </Typography>
          </Box>
        </Box>
      )}
      {subtitle && (
        <Box className={subtitleBoxClass}>
          <Typography variant="body1">{subtitle}</Typography>
        </Box>
      )}
      <Box my={3} display="flex" flexGrow="1" flexDirection="column" className={bodyContentBoxClass}>
        {React.Children.toArray(
          Array.isArray(children) ? children.map(c => c) : children
        )}
      </Box>
      {footer && (
        <Box mb={3} className={footerBoxClass}>
          <Typography variant="h5" gutterBottom>
            {footer}
          </Typography>
        </Box>
      )}
    </Box>
  );

  return (
    <Container maxWidth={maxWidth} className={clsx([classes.container, containerClass])} style={getHeaderStyles()}>
      {
        wrapWithPaper ?
          <Paper className={paperClass} titleComponent={paperTitleComponent}>{layoutBody}</Paper> :
          layoutBody
      }
    </Container>
  );
};

export default Layout;
