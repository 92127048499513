import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import {
  Box,
  Typography,
  Grid,
  Divider,
  Theme,
  Paper,
} from '@material-ui/core';
import { LocalPhone, Mail } from '@material-ui/icons';
import {
  Layout,
  useAppConfigurations,
  useBaseStore,
} from '@roc/feature-app-core';
import { Link, useHistory } from 'react-router-dom';
import {
  CLOSING,
  UNDERWRITING,
  yesNoOptions,
  YES,
  NO,
} from '@roc/client-portal-shared/utils';
import { Button, InfoTooltip, RadioField, TestDataAttribute } from '@roc/ui';
import { observer } from 'mobx-react';
import { FileUploadModal } from '@roc/ui';
import { useLoanRoutes } from '@roc/feature-loans-routes-config';
import { useRoutes } from '@roc/client-portal-shared/hooks';
import { FastTrackModal } from '@roc/feature-loans';
import { QuoteTypeStore } from '../../stores/quoteTypeStore';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontSize: '34px',
    },
    paper: {
      overflow: 'hidden',
    },
    stepperWrapper: {
      flex: 1,
      padding: theme.spacing(3.5, 3.5),
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(1, 1),
      },
    },
    fieldSpacing: {
      padding: theme.spacing(2),
    },
    rowSpacing: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(2),
    },
    centerText: {
      textAlign: 'center',
    },
    spacedTypography: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    attention: {
      fontWeight: 'bold',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    link: {
      color: theme.palette.primary.dark,
    },
    contactLine: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },
    contactIcon: {
      marginRight: '8px',
      fontSize: '1.40rem',
    },
    largeButton: {
      border: '2px solid #F2BC16',
      cursor: 'pointer',
      minHeight: '160px',
      '&:hover': {
        backgroundColor: '#fdefbf',
        transition: theme.transitions.create(
          ['background-color', 'transform'],
          { duration: theme.transitions.duration.standard }
        ),
      },
    },
    buttonTitle: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: '16px',
    },
    buttonContent: {
      padding: '8px',
    },
    buttonIcon: {
      backgroundColor: '#F2BC16',
      color: '#fff',
      fontSize: '48px',
    },
    buttonContainer: {
      padding: '5px',
      backgroundColor: '#F2BC16',
      borderRadius: '6px',
      height: '60px',
      width: '60px',
    },
    buttonTitleText: {
      marginLeft: '20px',
    },
    buttonsSection: {
      marginBottom: '32px',
    },
    loanIdSection: {
      marginTop: '32px',
      marginBottom: '40px',
    },
    loanIdTitle: {
      fontWeight: 'bold',
    },
    loanGrid: {
      marginLeft: '20px',
    },
    loanDivider: {
      marginTop: '4px',
      marginBottom: '6px',
    },
  })
);

export interface LoanSuccessProps {
  loanId: number;
  showTermsheetSection?: boolean;
  showRentalsEmail?: boolean;
  showDocsAndNewLoanButtons?: boolean;
  hasFastTrack?: boolean;
  preQualifiedLoan?: boolean;
  areBorrowersPreQualified?: boolean;
}

const ContactUs = observer(({ showRentalsEmail }) => {
  const classes = useStyles();
  const { data } = useAppConfigurations();

  return (
    <>
      {(data.phoneNumber || data.rentalsEmail) && (
        <Box className={classes.centerText}>
          <Typography className={classes.spacedTypography} variant="body1">
            If you need further assistance, please contact us at:
          </Typography>
          <Typography className={classes.spacedTypography} variant="h6">
            <div className={classes.contactLine}>
              <LocalPhone className={classes.contactIcon} />
              {data.phoneNumber}
            </div>
          </Typography>
          {showRentalsEmail && (
            <Typography className={classes.spacedTypography} variant="h6">
              <div className={classes.contactLine}>
                <Mail className={classes.contactIcon} />
                {data.rentalsEmail}
              </div>
            </Typography>
          )}
        </Box>
      )}
    </>
  );
});

export const LoanSuccessStep = observer(
  ({ store }: { store: QuoteTypeStore }) => {
    const classes = useStyles();
    return (
      <Layout maxWidth={'lg'}>
        <Paper className={classes.paper} elevation={0}>
          <Box display="flex">
            <Box className={classes.stepperWrapper}>
              <LoanSuccess
                loanId={store.submittedLoan?.loanId}
                showDocsAndNewLoanButtons
                areBorrowersPreQualified={store.areBorrowersPreQualified}
              />
            </Box>
          </Box>
        </Paper>
      </Layout>
    );
  }
);

export function LoanSuccess(props: LoanSuccessProps) {
  const {
    loanId,
    showTermsheetSection = false,
    showRentalsEmail = false,
    showDocsAndNewLoanButtons = false,
    hasFastTrack = false,
    areBorrowersPreQualified = true,
  } = props;
  const classes = useStyles();
  const history = useHistory();
  const { dashboardRoutesConfig } = useRoutes();
  const { loanRoutesConfig } = useLoanRoutes();
  const { globalStore } = useBaseStore();
  const submittedLoanRoutes = loanRoutesConfig.loans(loanId?.toString())
    .children;
  const loanDashboardURL = submittedLoanRoutes.underwriting.url;
  const closingURL = submittedLoanRoutes.closing.url;
  const loanRelatedDocumentsURL = submittedLoanRoutes.underwriting.url;
  const dashboardURL = dashboardRoutesConfig.dashboard.url;
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [isFastTrack, setIsFastTrack] = useState<boolean>(hasFastTrack);

  return (
    <Grid container justifyContent="space-around">
      <Grid item xs={12}>
        <Box className={classes.centerText}>
          <Typography variant="h5">
            {areBorrowersPreQualified
              ? 'Your Loan Application Was Successfully Submitted!'
              : 'Thank you!'}
          </Typography>
        </Box>
        <Box className={classes.centerText}>
          {areBorrowersPreQualified ? (
            <Typography className={classes.spacedTypography} variant="body1">
              Thank you for submitting your loan application.
            </Typography>
          ) : (
            <Typography variant="h6">
              Your loan will be automatically assigned to our underwriting team
              once borrower credit and background are received.
            </Typography>
          )}
          {loanId && (
            <>
              <Typography className={classes.spacedTypography} variant="body1">
                Your loan number is
                <TestDataAttribute id="loanIdLink">
                  <Link className={classes.link} to={loanDashboardURL}>
                    {' '}
                    {loanId}
                  </Link>
                </TestDataAttribute>
                .
              </Typography>
              <Box pt={2} pb={2}>
                <Divider />
                {showTermsheetSection && (
                  <Typography className={classes.attention} variant="body1">
                    Click on
                    <TestDataAttribute id="relatedDocumentsLink">
                      <Link
                        className={classes.link}
                        to={loanRelatedDocumentsURL}
                      >
                        {' '}
                        Link{' '}
                      </Link>
                    </TestDataAttribute>
                    to download Term Sheet from <b>Loan Related Documents </b>
                    Section, review and Accept.
                  </Typography>
                )}
                <Typography className={classes.attention} variant="body1">
                  Please Upload All required Underwriting Data and Documents in
                  the
                  <TestDataAttribute id="underwritingSectionLink">
                    <Link className={classes.link} to={loanDashboardURL}>
                      {' '}
                      Underwriting Section{' '}
                    </Link>
                  </TestDataAttribute>
                  for this Loan.
                </Typography>
                {areBorrowersPreQualified && (
                  <Typography className={classes.attention} variant="body1">
                    Both options are now open for your convenience, to help you
                    get an expedited loan closing.
                  </Typography>
                )}
                <Divider />
              </Box>
            </>
          )}
        </Box>
      </Grid>
      {showDocsAndNewLoanButtons && (
        <Grid
          container
          item
          xs={12}
          direction="row"
          justifyContent="center"
          spacing={2}
        >
          {loanId && (
            <Grid item xs={10} md={3}>
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                onClick={() => history.push(loanDashboardURL)}
                testId="uploadDocumentsButton"
              >
                COMPLETE TO-DOS
              </Button>
            </Grid>
          )}
          <Grid item xs={12} />
          <Grid item xs={10} md={3}>
            <Button
              variant="outlined"
              color="secondary"
              fullWidth
              onClick={() => history.push(dashboardURL)}
              testId="returnToHomeButton"
            >
              RETURN TO HOME
            </Button>
          </Grid>
        </Grid>
      )}
      <Grid item xs={12}>
        <ContactUs showRentalsEmail={showRentalsEmail} />
      </Grid>
    </Grid>
  );
}
export default LoanSuccess;
