import { useStore } from '@roc/client-portal-shared/hooks';
import {
  getLenderBankInfoRoutesConfig, getLenderBankInformationRoutes, getLoanRoutes, getLoanRoutesConfig, getCompanyProfileRoutes, getCompanyProfileRoutesConfig, getLenderRoutes, getLenderRoutesConfig, getCreateListRoutes, getCreateListRoutesConfig, getLeadListViewsRoutes, getLeadViewsRoutesConfig, getNewLeadRoutes, getNewLeadRoutesConfig, getLenderDetailsRoutesConfig, getLenderDetailsRoutes, getLenderWitNoRMRoutesConfig, getLenderWitnNoRmRoutes, getTPOLeadsDashboardRoutes, getTPOLeadsDashboardRoutesConfig,
  getLeadDetailsRoutes, getLeadDetailsRoutesConfig, getImportLeadsConfig, getImportLeadsRoutes, getBorrowerLeadsDashboardRoutesConfig, getBorrowerLeadsDashboardRoutes, getManageTemplatesConfig, getManageTemplatesRoutes, getLeadsDashboardRoutes, getLeadsDashboardRoutesConfig, getPossibleMatchesConfig, gettPossibleMatchesRoutes,
  getCRMTaskRoutes, getCRMTaskRoutesConfig, getDelinquentLoansRoutes, getDelinquentLoansRoutesConfig
} from '../routes/loanRoutes';
import { getBorrowerDetailsRoutes, getBorrowerDetailsRoutesConfig } from '../routes/borrowerDetailsRoutes';
import { getTPOContactDetailsRoutes, getTPOContactDetailsRoutesConfig } from '../routes/tpoContactDetailsRoutes';
import { getLoanRoutesConfig as loanDetailsRoutes } from '@roc/feature-loans-routes-config';
import { useClosingTrackerRoutes } from '@roc/feature-closing-tracker';
import { getSigmaDashboardsRoutes, getSigmaDashboardsRoutesConfig } from '@roc/client-portal-shared/modules/sigmaDashboards';

import { getLoanVolumeReportRoutes, getLoanVolumeReportRoutesConfig } from '@roc/client-portal-shared/modules/loanVolumeReport';
import { getCompToolRoutes, getCompToolRoutesConfig } from '@roc/feature-comp-tool';
import { getEmailsRoutes, getEmailsRoutesConfig, getEmailSettings, getEmailSettingsRoutesConfig, getMarketingSetupRoutes, getMarketingSetupRoutesConfig, getCorporateEmailsRoutes, getCorporateEmailsRoutesConfig } from '@roc/feature-sendgrid';

export const useRoutes = () => {
  const { globalStore } = useStore();
  const { routeBasePath, routeBaseUrl } = globalStore;

  const {
    closingTrackerRoutes,
    closingTrackerRoutesConfig
  } = useClosingTrackerRoutes();

  return {
    loanRoutes: getLoanRoutes(routeBasePath, routeBaseUrl),
    loanRoutesConfig: getLoanRoutesConfig(routeBasePath, routeBaseUrl),
    loanDetailsRoutes: loanDetailsRoutes(routeBasePath, routeBaseUrl),
    lenderRoutes: getLenderRoutes(routeBasePath, routeBaseUrl),
    lenderRoutesConfig: getLenderRoutesConfig(routeBasePath, routeBaseUrl),
    companyProfileRoutes: getCompanyProfileRoutes(routeBasePath, routeBaseUrl),
    companyProfileRoutesConfig: getCompanyProfileRoutesConfig(routeBasePath, routeBaseUrl),
    lenderBankInformationRoutes: getLenderBankInformationRoutes(routeBasePath, routeBaseUrl),
    lenderBankInformationConfig: getLenderBankInfoRoutesConfig(routeBasePath, routeBaseUrl),
    emailsRoutes: getEmailsRoutes(routeBasePath, routeBaseUrl),
    emailRoutesConfig: getEmailsRoutesConfig(routeBasePath, routeBaseUrl),
    corporateEmailsRoutes: getCorporateEmailsRoutes(routeBasePath, routeBaseUrl),
    corporateEmailsRoutesConfig: getCorporateEmailsRoutesConfig(routeBasePath, routeBaseUrl),
    emailSettingsRoutes: getEmailSettings(routeBasePath, routeBaseUrl),
    emailSettingsRoutesConfig: getEmailSettingsRoutesConfig(routeBasePath, routeBaseUrl),
    marketingSetupRoutes: getMarketingSetupRoutes(routeBasePath, routeBaseUrl),
    marketingSetupRoutesConfig: getMarketingSetupRoutesConfig(routeBasePath, routeBaseUrl),
    createListRoutes: getCreateListRoutes(routeBasePath, routeBaseUrl),
    createListRoutesConfig: getCreateListRoutesConfig(routeBasePath, routeBaseUrl),
    leadListViewsRoutes: getLeadListViewsRoutes(routeBasePath, routeBaseUrl),
    leadListViewsRoutesConfig: getLeadViewsRoutesConfig(routeBasePath, routeBaseUrl),
    newLeadRoutes: getNewLeadRoutes(routeBasePath, routeBaseUrl),
    newLeadRoutesConfig: getNewLeadRoutesConfig(routeBasePath, routeBaseUrl),
    lenderDetailsRoutes: getLenderDetailsRoutes(routeBasePath, routeBaseUrl),
    lenderDetailsBORoutesConfig: getLenderDetailsRoutesConfig(routeBasePath, routeBaseUrl),
    closingTrackerRoutes,
    closingTrackerRoutesConfig,
    lenderWithNoRmRoutesConfig: getLenderWitNoRMRoutesConfig(routeBasePath, routeBaseUrl),
    lenderWithNoRmRoutes: getLenderWitnNoRmRoutes(routeBasePath, routeBaseUrl),
    sigmaDashboardsRoutes: getSigmaDashboardsRoutes(routeBasePath, routeBaseUrl),
    sigmaDashboardsRoutesConfig: getSigmaDashboardsRoutesConfig(
      routeBasePath,
      routeBaseUrl
    ),
    leadsDashboardRoutesConfig: getLeadsDashboardRoutesConfig(routeBasePath, routeBaseUrl),
    leadsDashboardRoutes: getLeadsDashboardRoutes(routeBasePath, routeBaseUrl),
    tpoLeadsDashboardRoutesConfig: getTPOLeadsDashboardRoutesConfig(routeBasePath, routeBaseUrl),
    tpoLeadsDashboardRoutes: getTPOLeadsDashboardRoutes(routeBasePath, routeBaseUrl),
    borrowerLeadsDashboardRoutesConfig: getBorrowerLeadsDashboardRoutesConfig(routeBasePath, routeBaseUrl),
    borrowerLeadsDashboardRoutes: getBorrowerLeadsDashboardRoutes(routeBasePath, routeBaseUrl),
    leadDetailsRoutesConfig: getLeadDetailsRoutesConfig(routeBasePath, routeBaseUrl),
    leadDetailsRoutes: getLeadDetailsRoutes(routeBasePath, routeBaseUrl),
    importLeadsRoutes: getImportLeadsRoutes(routeBasePath, routeBaseUrl),
    importLeadsConfig: getImportLeadsConfig(routeBasePath, routeBaseUrl),
    manageTemplatesRoutes: getManageTemplatesRoutes(routeBasePath, routeBaseUrl),
    manageTemplatesConfig: getManageTemplatesConfig(routeBasePath, routeBaseUrl),
    loanVolumeReportRoutes: getLoanVolumeReportRoutes(routeBasePath, routeBaseUrl),
    loanVolumeReportRoutesConfig: getLoanVolumeReportRoutesConfig(routeBasePath, routeBaseUrl),
    possibleMatchesRoutes: gettPossibleMatchesRoutes(routeBasePath, routeBaseUrl),
    possibleMatchesConfig: getPossibleMatchesConfig(routeBasePath, routeBaseUrl),
    borrowerDetailsRoutesConfig: getBorrowerDetailsRoutesConfig(routeBasePath, routeBaseUrl),
    borrowerDetailsRoutes: getBorrowerDetailsRoutes(routeBasePath, routeBaseUrl),
    tpoContactDetailsRoutesConfig: getTPOContactDetailsRoutesConfig(routeBasePath, routeBaseUrl),
    tpoContactDetailsRoutes: getTPOContactDetailsRoutes(routeBasePath, routeBaseUrl),
    compToolRoutes: getCompToolRoutes(routeBasePath, routeBaseUrl),
    compToolRoutesConfig: getCompToolRoutesConfig(
      routeBasePath,
      routeBaseUrl
    ),
    crmTaskDashboardRoutesConfig: getCRMTaskRoutesConfig(routeBasePath, routeBaseUrl),
    crmTaskDashboardRoutes: getCRMTaskRoutes(routeBasePath, routeBaseUrl),
    delinquentLoansDashboardRoutesConfig: getDelinquentLoansRoutesConfig(routeBasePath, routeBaseUrl),
    delinquentLoansDashboardRoutes: getDelinquentLoansRoutes(routeBasePath, routeBaseUrl)
  };
};
