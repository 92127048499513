import {
  ConfirmationButton,
  StandardDialog,
  Button,
  TextField,
  createTextField,
  FieldLabel,
  AddressDetailsField,
  createNumberFormatField,
} from '@roc/ui';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { isNil, isNotBlank } from '@roc/feature-utils';
import { QuoteTypeStore } from 'libs/feature-one-tool/src/quote/stores/quoteTypeStore';
import { Grid } from '@material-ui/core';
import { InsuranceFormStore } from 'libs/feature-one-tool/src/quote/stores/closing/insuranceFormStore';
import { FormStore } from '@roc/feature-app-core';

interface Props {
  store: QuoteTypeStore;
}

export const OtherInsuranceDialog = observer(({ store }: Props) => {
  const { closingStore } = store;
  const { insuranceFormStore } = closingStore;

  return (
    <StandardDialog
      open={insuranceFormStore.showInsuranceDialog}
      title={`Insurance`}
      maxWidth="md"
      handleClose={() => insuranceFormStore.closeDialog()}
      dialogContent={<OtherInsuranceForm store={insuranceFormStore} />}
      dialogActions={
        <>
          <Button
            style={{ marginRight: '8px' }}
            onClick={() => insuranceFormStore.closeDialog()}
            color="primary"
            variant="outlined"
            testId="cancel"
          >
            Cancel
          </Button>
          <Button
            onClick={() => insuranceFormStore.saveInsurance()}
            color="primary"
            variant="contained"
            testId="save"
          >
            Confirm
          </Button>
        </>
      }
    />
  );
});

const OtherInsuranceForm = observer(
  ({ store }: { store: InsuranceFormStore }) => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {createTextField({
            store: store,
            label: 'Name',
            fieldName: 'name',
            testId: 'name',
          })}
        </Grid>
        <Grid item xs={12}>
          {createTextField({
            store: store,
            label: 'Contact Name',
            fieldName: 'contactName',
            testId: 'contactName',
          })}
        </Grid>
        <Grid item xs={12}>
          {createNumberFormatField({
            store,
            testId: 'cellPhone',
            fieldName: 'cellPhone',
            label: 'Phone Number',
            format: '###-###-####',
          })}
        </Grid>
        <Grid item xs={12}>
          <Address store={store} />
        </Grid>
        <Grid item xs={12}>
          {createTextField({
            store: store,
            label: 'Email Address',
            fieldName: 'emailAddress',
            testId: 'emailAddress',
          })}
        </Grid>
      </Grid>
    );
  }
);

const Address = observer(({ store }: { store: FormStore }) => {
  const fieldName = 'address';
  const field = store.form.fields[fieldName];
  return (
    <>
      <FieldLabel>Address</FieldLabel>
      <AddressDetailsField
        value={field.value}
        onChange={value => store.onFieldChange(fieldName, value)}
        name={fieldName}
        error={!store.form.meta.isValid && isNotBlank(field.error)}
        errorText={!store.form.meta.isValid && field.error}
        standaloneLabel={false}
      />
    </>
  );
});
