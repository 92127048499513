export const replacePlaceholders = (
  html: string,
  params: Record<string, string>
): string => {
  return html.replace(/{{?([A-z0-9]+?)}?}/g, placeholder => {
    const paramName = placeholder.replace(/[^A-z0-9]/g, '').toLowerCase();
    const value = params[paramName] ?? '';
    return value;
  });
};
