
import { observer } from "mobx-react";
import { Button, StandardDialog, SelectField, MultiSelectField } from '@roc/ui';
import { useEffect, useState } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { Checkbox, FormControlLabel, Grid, TextField, Typography } from '@material-ui/core';
import { useNewLeadStore } from "../../hooks/useNewLeadStore";
import { FALSE } from "@roc/feature-utils";
import { codeWorking } from "ionicons/icons";


export const ShareDashboardView = observer(({ open, handleClose, salesforceLeadStore, dashboard }) => {
  const { leadsDashboardStore } = salesforceLeadStore;
  const { editLeadsDashboardStore } = leadsDashboardStore;
  const { newLeadStore } = useNewLeadStore();
  const store = editLeadsDashboardStore;

  const handleOwnersChange = (newOwners, store) => {
    const allOption = { label: "All", value: 'all' };
    if (newOwners.some(owner => owner.value === allOption.value)) {
      newOwners = [allOption];
    }
    store.onFieldChange('shareWith', newOwners);
  };

  useEffect(() => {
    fetchData();
  }, [])

  useEffect(() => {
    if (dashboard?.filters) {
      editLeadsDashboardStore.getUsersWithDashboard(
        dashboard
      );
    }
  }, [dashboard])

  useEffect(() => {
    displayShareWithUsers();
  }, [editLeadsDashboardStore.sharedWithOwners]);


  const displayShareWithUsers = () => {
    try {
      const matchingOwners = allOwners.filter(owner => editLeadsDashboardStore.sharedWithOwners.includes(owner.value));
      store.onFieldChange('shareWith', matchingOwners);
    } catch (error) {
      console.error("Failed to get users with dashboard:", error);
    }
  }


  const fetchData = async () => {
    await salesforceLeadStore.getAllOwners();
  };

  const handleSaveClick = async () => {
    let owners = store.form.fields.shareWith.value;
    if (store.form.fields.shareWith.value[0].value == 'all') {
      owners = [
        ...salesforceLeadStore.rocOwners,
        ...salesforceLeadStore.facoOwners,
        ...salesforceLeadStore.civicOwners
      ];
    }
    await editLeadsDashboardStore.shareDashboard(leadsDashboardStore.currentDashboard, owners);
    handleClose();
  };

  const allOption = { label: "All", value: 'all' };
  const allOwners = [allOption].concat(
    salesforceLeadStore.rocOwners,
    salesforceLeadStore.facoOwners,
    salesforceLeadStore.civicOwners
  );

  return (
    <StandardDialog
      open={open}
      title={"Share View"}
      maxWidth="lg"
      handleClose={handleClose}
      dialogContent={
        <>
          <Typography variant="h6" gutterBottom style={{ marginRight: '15px' }}>
            {'Select the people you want to share this view with'}
          </Typography>
          <Grid item xs={12}>
            <MultiSelectField
              name={'share-with-owners'}
              options={allOwners}
              getOptionSelected={(option, selectedValue) => {
                return option.value == selectedValue.value;
              }}
              value={
                store.form.fields.shareWith.value?.length
                  ? store.form.fields.shareWith.value
                  : []
              }
              onChange={(e, value) => {
                handleOwnersChange(value, store)
              }}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="Select owner(s)" />
              )}
              fullWidth
              required
              testId="selectedOwners"
            />
          </Grid>
        </>
      }
      dialogActions={
        <>
          <Button
            style={{ marginRight: '8px' }}
            onClick={handleClose}
            color="primary"
            variant="outlined"
            testId="cancel"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSaveClick}
            testId="next"
          >
            Share
          </Button>
        </>
      }
    />
  );
});
