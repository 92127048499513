import { observer } from "mobx-react";
import { ValuationReviewReportStore } from "../stores/valuationReviewReportStore";
import { StandardDialog } from "@roc/ui";
import { makeStyles } from '@material-ui/core/styles';
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import { CellType, PropertyDataInfo, propertyInfoFields } from "./valuationUtils";
import { formatCurrency } from "@roc/ui/utils";
import { formatDate } from "@roc/feature-utils";

const useStyles = makeStyles({
  dialogContentclasses: {
    padding: '8px 0'
  },
  tableContainer: {
    maxHeight: 400,
    '& .MuiTableRow-head th': {
      fontWeight: 'bold',
    },
    '& .MuiTableRow-head.subject-property-row th': {
      fontWeight: 'normal',
      background: '#555',
      color: 'white',
    },
  },
  table: {
    minWidth: 650,
    '& .MuiTableCell-head': {
      background: '#f7f7f7'
    },
    '& td': {
      lineHeight: '20px',
      whiteSpace: 'nowrap',
    },
    '& .address': {
      position: 'relative',
      whiteSpace: 'normal',
      padding: '0 0 0 16px',
      '& .data-container': {
        overflow: 'auto',
        height: 84,
        padding: '6px 16px 6px 0',
        display: 'flex',
        alignItems: 'center',
      }
    }
  },
  innerTable: {
    minWidth: 150,
  },
});

export type Props = {
  store: ValuationReviewReportStore,
  open: boolean,
  onClose: any
}

export const PropertyDataComparisonDialog = observer((props: Props) => {
  const { store, open, onClose } = props;

  const classes = useStyles();

  return(
    <StandardDialog
      open={open}
      maxWidth="lg"
      title={'Property Data Comparison'}
      handleClose={onClose}
      removeContentBox
      dialogContentclasses={{
        root: classes.dialogContentclasses
      }}
      dialogContent={(
        <Box width={'100%'}>
          <PropertyDataComparisonTable store={store}/>
        </Box>
      )}
    />
  )
})

const DataType = ({field}) => {
  switch(field.type){
    case CellType.DOLLAR_AMOUNT:
      return field?.value ? formatCurrency(field?.value, 2) : '-';
    case CellType.NUMERIC:
      return field?.value ? field?.value?.toLocaleString() : '-';
    case CellType.DATE:
      return field?.value ? formatDate(field?.value, 'MM/dd/yyyy') : '-';
    case CellType.TEXT:
    default:
      return field?.value ? field?.value : '-';
  }
}

const DataCell = ({ field, isFieldNames }: {
  field?: any,
  isFieldNames?: boolean
}) => {
  return (
    <TableRow>
      <TableCell variant={isFieldNames ? 'head' : 'body'}  align={isFieldNames ? 'left' : 'center'} >
        <Box className='data-container'>
          {isFieldNames ? (field ? field : <>&nbsp;</>) : <DataType field={field}/>}
        </Box>
      </TableCell>
    </TableRow>

  )
}
const getData  = (data) => {
  console.log(data, ' DATA')
  let dataSource = [];
  PropertyDataInfo.map((field) =>{
    let fieldData =
    {
      name: field.name,
      mapping: field.mapping,
      type: field.type,
      value: null
    };
    switch(field.mapping){
      case 'beds':
        fieldData.value = data?.beds;
        break;
      case 'baths':
        fieldData.value = data?.baths;
        break;
      case 'landArea':
        fieldData.value = data?.lotSize;
        break;
      case 'gla':
        fieldData.value = data?.gla;
        break;
      case 'propertyType':
        fieldData.value = data?.propertyType;
        break;
      case 'zoning':
        fieldData.value = data?.zoning;
        break;
      case 'lastSoldDate':
        fieldData.value = data?.lastSoldDate;
        break;
      case 'lastSoldPrice':
        fieldData.value = data?.asIsValue;
        break;
    }
    dataSource.push(fieldData);
    })
    return dataSource;
  }

const PropertyDataComparisonTable = observer(({store}) => {
  const classes = useStyles();


  const { valuationReviewInfo } = store;
  const { propertyInformationDataSources }  = valuationReviewInfo;
  const publicRecords = propertyInformationDataSources?.publicRecords;
  const subject = propertyInformationDataSources?.subject;
  const mls = propertyInformationDataSources?.mls;

  const publicRecordsData = getData(publicRecords);
  const subjectData = getData(subject);
  const mlsData = getData(mls);

  const allDataSources = [subjectData, publicRecordsData, mlsData];

  return(
    <Box style={{padding: '30px'}}>
      <Paper elevation={0} variant='outlined'>
      <TableContainer className={classes.tableContainer}>
        <Table className={classes.table} size='small'>
          <TableHead>
            <TableRow>
              <TableCell variant='head'></TableCell>
              <TableCell variant='head' align="center" style={{
                borderLeft: '1px solid #bbb'
              }}><strong>Subject/Appraisal</strong></TableCell>
              <TableCell variant='head' align="center" style={{
                borderLeft: '1px solid #bbb'
              }}><strong>Public Records</strong></TableCell>
              <TableCell variant='head' align="center" style={{
                borderLeft: '1px solid #bbb'
              }}><strong>MLS</strong></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell padding={'none'} style={{
                    borderLeft: '1px solid #bbb',
                    borderBottom: 'none'
                  }}>
                    <TableContainer>
                      <Table className={classes.innerTable} size="small">
                        <TableBody>
                          {propertyInfoFields.map(field => {
                            return <DataCell field={field} isFieldNames={true}/>
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </TableCell>
              {allDataSources.map(dataSource => {
                return(
                  <TableCell padding={'none'} style={{
                    borderLeft: '1px solid #bbb',
                    borderBottom: 'none'
                  }}>
                    <TableContainer>
                      <Table className={classes.innerTable} size="small">
                        <TableBody>
                          {dataSource.map(field => {
                            return <DataCell field={field}/>
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </TableCell>
                )
              })}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
    </Box>
  )
})
