import { Service } from '@roc/client-portal-shared/services';

const url = {
  VALUES: '/api/v1/pricer/bridgePricer/calculateValues',
  FLOOR_RATE: '/api/v1/pricer/bridgePricer/getRateFloorBySubmissionDate',
  BORROWER_POINTS_OPTION: '/api/v1/pricer/bridgePricer/getBorrowerPointsValues',
  BRIDGE_DEAL_NAMES: '/api/v1/loan/getBridgeDealNames',
  LOAN_VALUES: '/api/v1/loan/getBridgeLoanDetail',
};

export class ClientBridgePricerService extends Service {
  getValues(data) {
    return this.post(url.VALUES, data);
  }

  getRateFloor(data) {
    return this.post(url.FLOOR_RATE, data);
  }

  getBorrowerPointsOptions(lenderId, loanTerm, submissionDate) {
    return this.get(url.BORROWER_POINTS_OPTION, { lenderId, loanTerm, submissionDate });
  }

  getBridgeDealNames(
    page: number,
    pageSize: number,
    sortDir: string,
    sortBy: string,
    filters: object
  ) {
    return this.get(url.BRIDGE_DEAL_NAMES, {
      page,
      pageSize,
      sortDir,
      sortBy,
      filters,
    });
  }

  getLoanValues(loanId) {
    return this.get(`${url.LOAN_VALUES}?id=${loanId}`);
  }
}