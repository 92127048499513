import { Box, Typography, FormHelperText, Grid } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { isNotBlank, statesAbbreviation, statesNames } from '@roc/feature-utils';
import {
  AutocompleteAddressField,
  CurrencyField,
  DateField,
  FieldLabel, GoogleMap, MultiSelectField,
  RadioField,
  SelectField,
  TextField,
  trueFalseOptions, yesNoOptions, NumberFormat,
  createSelectField, createTextField,
  createNumberFormatFieldWithAdornment
} from '@roc/ui';
import { formatCurrency } from '@roc/ui/utils';
import { format, isValid } from 'date-fns';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { isNil } from '@roc/feature-utils';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';


const propertySources = [
  { value: 'Short Sale', label: 'Short Sale' },
  { value: 'REO', label: 'REO' },
  { value: 'Wholesaler', label: 'Wholesaler' },
  { value: 'Foreclosure Auction', label: 'Foreclosure Auction' },
  { value: "Sheriff's Sale", label: "Sheriff's Sale" },
  { value: 'Estate Sale', label: 'Estate Sale' },
  { value: 'Online Auction', label: 'Online Auction' },
  { value: 'Bankruptcy Sale', label: 'Bankruptcy Sale' },
  { value: 'MLS', label: 'MLS' },
  { value: 'Pre-Foreclosure', label: 'Pre-Foreclosure' },
  { value: 'Entity to Entity', label: 'Entity to Entity' },
  { value: 'Inheritance', label: 'Inheritance' },
  { value: 'Private Sale', label: 'Private Sale' },
  { value: 'Other', label: 'Other' },
];

const wholesalerPropertySources = [
  { value: 'Short Sale', label: 'Short Sale' },
  { value: 'REO', label: 'REO' },
  { value: 'Foreclosure Auction', label: 'Foreclosure Auction' },
  { value: "Sheriff's Sale", label: "Sheriff's Sale" },
  { value: 'Estate Sale', label: 'Estate Sale' },
  { value: 'Online Auction', label: 'Online Auction' },
  { value: 'Bankruptcy Sale', label: 'Bankruptcy Sale' },
  { value: 'MLS', label: 'MLS' },
  { value: 'Pre-Foreclosure', label: 'Pre-Foreclosure' },
  { value: 'Entity to Entity', label: 'Entity to Entity' },
  { value: 'Inheritance', label: 'Inheritance' },
  { value: 'Private Sale', label: 'Private Sale' },
  { value: 'Other', label: 'Other' },
];

const wholesalerRelationships = [
  { value: 'Relative', label: 'Relative' },
  { value: 'Non-Relative', label: 'Non-Relative' },
];

const propertyOwnerships = [
  { label: 'Purchase', value: 'Purchase' },
  { label: 'Already Owns the Property', value: 'Owned By Borrower' },
];

const exitStrategies = [
  { value: 'Refinance', label: 'Refinance' },
  { value: 'Sale', label: 'Sale' },
];

const useAddressStyles = makeStyles((theme: Theme) => ({
  autocompleteContainer: {
    marginBottom: theme.spacing(2),
  },
  mapStyles: {
    position: 'relative',
    width: '100%',
    height: '150px',
  },
}));

const stateOptions = statesAbbreviation.map((abbreviation, i) => ({
  value: abbreviation,
  label: statesNames[i],
}));

const addressLine2Tooltip =
  'Address Line 2 can be used for additional information\nsuch as apartment number, suite number, unit number\nor others details that can help in identifying the\nexact location of the address.';

const STREET_NUMBER_REGEX = /^(\d+)\s(.*)$/;

export const PropertyAddressDetails = observer(({ store, disabled = false, showMap = true, required = true }: { store: any, disabled?: boolean, showMap?: boolean, required?: boolean }) => {
  const classes = useAddressStyles();

  const formFields = store.form.fields;

  const handleAddressChange = event => {
    const { value, fullInfo } = event?.target || {};
    const { address_components, geometry } = fullInfo || {};
    if (!isNil(address_components) && !isNil(geometry)) {
      store.saveAddressField(value, address_components, geometry);
    } else {
      const address = event.target.value;
      store.onFieldChange('address', address);
      if (STREET_NUMBER_REGEX.test(address)) {
        const [, streetNumber, streetName] = address.match(STREET_NUMBER_REGEX);
        store.onFieldChange('streetName', streetName);
        store.onFieldChange('streetNumber', streetNumber);
      } else {
        store.onFieldChange('streetName', address);
        store.onFieldChange('streetNumber', '');
      }
    }
  };

  const showGoogleMap = showMap && Boolean(formFields?.latitude?.value && formFields?.longitude?.value);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <AutocompleteAddressField
          label="Address Line 1"
          disabled={disabled}
          required={required}
          name="address"
          value={formFields.address.value}
          error={isNotBlank(formFields.address.error) || isNotBlank(formFields.__isAddressInCorrectFormat__.error)}
          errorText={formFields.address.error || formFields.__isAddressInCorrectFormat__.error}
          onChange={handleAddressChange}
          fullWidth
          testId="address"
          standaloneLabel={true}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        {createTextField({
          label: 'Address Line 2',
          fieldName: 'aptNumber',
          testId: 'aptNumber',
          store: store,
          disabled,
          tooltip: addressLine2Tooltip
        })}
      </Grid>
      <Grid item xs={12} sm={4}>
        {createTextField({
          label: 'City',
          fieldName: 'city',
          testId: 'city',
          store: store,
          disabled
        })}
      </Grid>
      <Grid item xs={12} sm={4}>
        {createSelectField({
          store: store,
          testId: 'state',
          fieldName: 'state',
          label: 'State',
          options: stateOptions,
          disabled
        })}
      </Grid>
      <Grid item xs={12} sm={4}>
        {createTextField({
          label: 'Zip Code',
          fieldName: 'zipCode',
          testId: 'zipCode',
          store: store,
          disabled
        })}
      </Grid>
      <Grid item xs={12}>
        {showGoogleMap &&
          <GoogleMap
            mapStyles={classes.mapStyles}
            latitude={formFields.latitude.value}
            longitude={formFields.longitude.value}
          />
        }
      </Grid>
    </Grid>
  )
});

export const PropertyAddress = observer(({ store, showGoogleMap = true, ...rest }) => {
  const classes = useAddressStyles();
  const formFields = store.form.fields;

  const handleAddressChange = (event, name) => {
    const { value, fullInfo } = event?.target || {};
    const { address_components, geometry } = fullInfo || {};
    store.saveAddressField(value, address_components, geometry);
  };
  return (
    <>
      <Box className={classes.autocompleteContainer}>
        <AutocompleteAddressField
          value={formFields.address.value}
          name="address"
          label="Enter property location"
          error={isNotBlank(formFields.address.error) || isNotBlank(formFields.__isAddressInCorrectFormat__.error)}
          errorText={formFields.address.error || formFields.__isAddressInCorrectFormat__.error}
          onChange={event => handleAddressChange(event, 'address')}
          fullWidth
          testId="address"
          {...rest}
        />
      </Box>
      {showGoogleMap && formFields.latitude.value && formFields.longitude.value ? (
        <GoogleMap
          mapStyles={classes.mapStyles}
          latitude={formFields.latitude.value}
          longitude={formFields.longitude.value}
        />
      ) : null}
    </>
  );
});

export const ParcelNumber = observer(({ store, ...rest }) => {
  const formFields = store.form.fields;
  return (
    <TextField
      testId="parcel"
      variant="outlined"
      standaloneLabel
      label="Parcel Number"
      value={formFields.parcel.value}
      fullWidth
      InputLabelProps={{
        shrink: true,
      }}
      onChange={e => store.onFieldChange('parcel', e.target.value)}
      {...rest}
    />
  );
});

export const MultipleLots = observer(({ store, loanSubType, disabled, ...rest }) => {
  const formFields = store.form.fields;
  const valueWhenDisabled = formFields.numberOfLots.value === null || formFields.numberOfLots.value === 0 ? false : true;
  const fieldValue = disabled ? valueWhenDisabled : formFields.multipleLots.value;

  return (
    <RadioField
      standaloneLabel
      label={'Are you dividing this property into multiple lots?'}
      disabled={disabled}
      value={fieldValue}
      errorText={formFields.multipleLots.error}
      error={isNotBlank(formFields.multipleLots.error)}
      name={'multipleLots'}
      options={trueFalseOptions}
      onChange={value => store.onFieldChange('multipleLots', value == 'true')}
      required
      row
      testId="multipleLots"
      {...rest}
    />
  );
});

export const IsSellerCredit = observer(({ store, disabled, ...rest }) => {
  const formFields = store.form.fields;

  return (
    <RadioField
      standaloneLabel
      label={'Is there a seller credit on this property?'}
      disabled={disabled}
      value={formFields.sellerCredit.value}
      errorText={formFields.sellerCredit.error}
      error={isNotBlank(formFields.sellerCredit.error)}
      name={'sellerCredit'}
      options={trueFalseOptions}
      onChange={value => store.onFieldChange('sellerCredit', value == 'true')}
      required
      row
      testId="sellerCredit"
      {...rest}
    />
  );
});

export const SellerCreditAmount = observer(({ store, ...rest }) => {
  const formFields = store.form.fields;
  return (
    <CurrencyField
      variant="outlined"
      standaloneLabel
      label="How much is the seller credit?"
      tooltip='It will be netted from the purchase price'
      value={formFields.sellerCreditAmount.value}
      errorText={formFields.sellerCreditAmount.error}
      error={isNotBlank(formFields.sellerCreditAmount.error)}
      onChange={(name, value) =>
        store.onFieldChange('sellerCreditAmount', Number(value))
      }
      fullWidth
      required
      testId="sellerCreditAmount"
      decimalScale={0}
      {...rest}
    />
  );
});

export const IsOriginalAddress = observer(({ store, loanSubType, ...rest }) => {
  const formFields = store.form.fields;
  return (
    <RadioField
      standaloneLabel
      label={'Is this the original address (the lot was not previously subdivided)?'}
      value={formFields.isOriginalAddress.value}
      errorText={formFields.isOriginalAddress.error}
      error={isNotBlank(formFields.isOriginalAddress.error)}
      name={'isOriginalAddress'}
      options={trueFalseOptions}
      onChange={value => {
        store.onFieldChange('isOriginalAddress', value == 'true');
      }}
      required
      row
      testId="isOriginalAddress"
      {...rest}
    />
  );
});

export const OriginalAddress = observer(({ store, ...rest }) => {
  const classes = useAddressStyles();
  const formFields = store.form.fields;

  const handleAddressChange = (event) => {
    const { value } = event?.target || {};
    store.onFieldChange('originalAddress', value);
  };

  return (
    <>
      <Box className={classes.autocompleteContainer}>
        <AutocompleteAddressField
          value={formFields.originalAddress.value}
          name="originalAddress"
          label="What was the original address?"
          error={isNotBlank(formFields.originalAddress.error)}
          errorText={formFields.originalAddress.error}
          onChange={event => handleAddressChange(event)}
          fullWidth
          testId="originalAddress"
          {...rest}
        />
      </Box>
    </>
  );
});

export const PermitsInPlace = observer(({ store, ...rest }) => {
  const formFields = store.form.fields;
  return (
    <RadioField
      standaloneLabel
      label={'Are permits received for the proposed project?'}
      value={formFields.permitsInPlace.value}
      errorText={formFields.permitsInPlace.error}
      error={isNotBlank(formFields.permitsInPlace.error)}
      name={'permitsInPlace'}
      options={trueFalseOptions}
      onChange={value => {
        store.onFieldChange('permitsInPlace', value == 'true');
      }}
      required
      row
      testId="permitsInPlace"
      {...rest}
    />
  );
});

export const NumberOfLots = observer(({ store, ...rest }) => {
  const formFields = store.form.fields;
  return (
    <NumberFormat
      customInput={TextField}
      required
      label="Number of Lots"
      testId="numberOfLots"
      value={formFields.numberOfLots.value}
      onChange={e =>
        store.onFieldChange('numberOfLots', e.target.value)
      }
      error={isNotBlank(formFields.numberOfLots.error)}
      helperText={formFields.numberOfLots.error}
    />
  );
});

export const PropertyType = observer(
  ({ store, propertyTypeOptions, ...rest }) => {
    const formFields = store.form.fields;

    return (
      <SelectField
        standaloneLabel
        label={'Select the Property Type'}
        name={'useCode'}
        value={formFields.useCode.value}
        errorText={formFields.useCode.error}
        error={isNotBlank(formFields.useCode.error)}
        options={propertyTypeOptions}
        onChange={value => store.onFieldChange('useCode', value)}
        variant="outlined"
        fullWidth
        shrink
        required
        testId="propertyType"
        {...rest}
      />
    );
  }
);


export const PotentialExitStrategy = observer(
  ({ store, exitOptions, ...rest }) => {
    const formFields = store.form.fields;

    return (
      <SelectField
        standaloneLabel
        label={'Select a Potential Exit Strategy'}
        name={'potentialExitStrategy'}
        value={formFields.potentialExitStrategy.value}
        errorText={formFields.potentialExitStrategy.error}
        error={isNotBlank(formFields.potentialExitStrategy.error)}
        options={exitOptions}
        onChange={value => store.onFieldChange('potentialExitStrategy', value)}
        variant="outlined"
        fullWidth
        shrink
        required
        testId="potentialExitStrategy"
        {...rest}
      />
    );
  }
);

export const Unit = observer(({ store, ...rest }) => {
  const formFields = store.form.fields;
  return (
    <TextField
      testId="aptNumber"
      variant="outlined"
      standaloneLabel
      label="Unit #"
      value={formFields.aptNumber.value}
      fullWidth
      InputLabelProps={{
        shrink: true,
      }}
      onChange={e => store.onFieldChange('aptNumber', e.target.value)}
      {...rest}
    />
  );
});

export const PropertyOwnership = observer(
  ({ store, onChange = null, loanSubType = null, ...rest }) => {
    const formFields = store.form.fields;

    const handleChange = value => {
      store.onFieldChange('propertyOwnership', value);
      onChange && onChange('propertyOwnership', value);
    };

    return (
      <RadioField
        standaloneLabel
        label="Purchase or Refinance"
        value={formFields.propertyOwnership.value}
        errorText={formFields.propertyOwnership.error}
        error={isNotBlank(formFields.propertyOwnership.error)}
        name={'propertyOwnership'}
        options={propertyOwnerships}
        onChange={handleChange}
        fullWidth
        required
        row
        testId="propertyOwnership"
        {...rest}
      />
    );
  }
);

export const PurchasePrice = observer(({ store, ...rest }) => {
  const formFields = store.form.fields;
  return (
    <CurrencyField
      variant="outlined"
      standaloneLabel
      label="What was the Purchase Price of the Property?"
      value={formFields.purchasePrice.value}
      errorText={formFields.purchasePrice.error}
      error={isNotBlank(formFields.purchasePrice.error)}
      onChange={(name, value) =>
        store.onFieldChange('purchasePrice', Number(value))
      }
      fullWidth
      required
      testId="purchasePrice"
      decimalScale={0}
      {...rest}
    />
  );
});


export const SellingPrice = observer(({ store, ...rest }) => {
  const formFields = store.form.fields;
  return (
    <CurrencyField
      variant="outlined"
      standaloneLabel
      label="Set Your Price"
      value={formFields.sellingPrice.value}
      errorText={formFields.sellingPrice.error}
      error={isNotBlank(formFields.sellingPrice.error)}
      onChange={(name, value) =>
        store.onFieldChange('sellingPrice', Number(value))
      }
      fullWidth
      required
      testId="sellingPrice"
      {...rest}
    />
  );
});

export const PayoffAmount = observer(({ store, ...rest }) => {
  const formFields = store.form.fields;
  return (
    <CurrencyField
      variant="outlined"
      standaloneLabel
      label="What is the Payoff Amount of the Property?"
      value={formFields.payoffAmount.value}
      errorText={formFields.payoffAmount.error}
      error={isNotBlank(formFields.payoffAmount.error)}
      onChange={(name, value) =>
        store.onFieldChange('payoffAmount', Number(value))
      }
      fullWidth
      required
      testId="payoffAmount"
      {...rest}
    />
  );
})

export const Bedrooms = observer(({ store, ...rest }) => {
  const formFields = store.form.fields;
  return (
    <TextField
      type='number'
      testId="bedrooms"
      variant="outlined"
      standaloneLabel
      inputProps={{ min: 0 }}
      label="Bedrooms"
      value={formFields.bedrooms.value}
      onChange={e => store.onFieldChange('bedrooms', e.target.value)}
      fullWidth
      {...rest}
    />
  );
})

export const SquareFootage = observer(({ store, ...rest }) => {
  const formFields = store.form.fields;
  return (
    <TextField
      type='number'
      testId="squareFootage"
      variant="outlined"
      standaloneLabel
      inputProps={{ min: 0 }}
      label="Est. Sq. Ft"
      value={formFields.squareFootage.value}
      onChange={e => store.onFieldChange('squareFootage', e.target.value)}
      fullWidth
      {...rest}
    />
  );
})

export const Bathrooms = observer(({ store, ...rest }) => {
  const formFields = store.form.fields;
  return (
    <TextField
      type='number'
      testId="bathrooms"
      variant="outlined"
      standaloneLabel
      inputProps={{ min: 0 }}
      label="Bathrooms"
      value={formFields.bathrooms.value}
      onChange={e => store.onFieldChange('bathrooms', e.target.value)}
      fullWidth
      {...rest}
    />
  );
})

export const ArmsLength = observer(({ store, ...rest }) => {
  const formFields = store.form.fields;
  return (
    <RadioField
      standaloneLabel
      label="Is the transaction arm’s length where buyer and seller are trying to get the best terms for their respective sides?"
      value={formFields.armsLength.value}
      errorText={formFields.armsLength.error}
      error={isNotBlank(formFields.armsLength.error)}
      name={'armsLength'}
      options={yesNoOptions}
      onChange={value => store.onFieldChange('armsLength', value)}
      fullWidth
      row
      required
      testId="armsLength"
      {...rest}
    />
  );
});


export const NeedsRepairs = observer(({ store, ...rest }) => {
  const formFields = store.form.fields;
  return (
    <RadioField
      standaloneLabel
      label="Needs Repairs?"
      value={formFields.needsRepairs.value}
      errorText={formFields.needsRepairs.error}
      error={isNotBlank(formFields.needsRepairs.error)}
      name={'needsRepairs'}
      options={yesNoOptions}
      onChange={value => store.onFieldChange('needsRepairs', value)}
      fullWidth
      row
      required
      testId="needsRepairs"
      {...rest}
    />
  );
});

export const FinishedBasement = observer(({ store, ...rest }) => {
  const formFields = store.form.fields;
  return (
    <TextField
      testId="finishedBasement"
      variant="outlined"
      standaloneLabel
      label="Finished Basement/Attic"
      value={formFields.finishedBasement.value}
      onChange={e => store.onFieldChange('finishedBasement', e.target.value)}
      fullWidth
      {...rest}
    />
  );
});

export const FlipAssumptions = observer(({ store, ...rest }) => {
  const formFields = store.form.fields;
  return (
    <TextField
      testId="flipAssumptions"
      variant="outlined"
      standaloneLabel
      label="Flip Assumptions"
      value={formFields.flipAssumptions.value}
      onChange={e => store.onFieldChange('flipAssumptions', e.target.value)}
      fullWidth
      {...rest}
    />
  );
});

export const RentalAssumptions = observer(({ store, ...rest }) => {
  const formFields = store.form.fields;
  return (
    <TextField
      testId="rentalAssumptions"
      variant="outlined"
      standaloneLabel
      label="Rental Assumptions"
      value={formFields.rentalAssumptions.value}
      onChange={e => store.onFieldChange('rentalAssumptions', e.target.value)}
      fullWidth
      {...rest}
    />
  );
});

export const ArmsLengthDescribe = observer(({ store, showError = false, ...rest }) => {
  const formFields = store.form.fields;
  return (
    <TextField
      testId="armsLengthComment"
      variant="outlined"
      standaloneLabel
      label="Please describe"
      value={formFields.armsLengthComment.value}
      onChange={e => store.onFieldChange('armsLengthComment', e.target.value)}
      error={showError ? isNotBlank(formFields.armsLengthComment.error) : undefined}
      helperText={showError ? formFields.armsLengthComment.error : undefined}
      fullWidth
      {...rest}
    />
  );
});

export const PropertySource = observer(
  ({ store, onChange = null, ...rest }) => {
    const formFields = store.form.fields;

    const handleChange = value => {
      store.onFieldChange('propertySourcing', value);
      onChange && onChange('propertySourcing', value);
    };

    return (
      <SelectField
        standaloneLabel
        label={'How is the property being sourced?'}
        name={'propertySourcing'}
        options={propertySources}
        variant="outlined"
        value={formFields.propertySourcing.value}
        errorText={formFields.propertySourcing.error}
        error={isNotBlank(formFields.propertySourcing.error)}
        onChange={handleChange}
        fullWidth
        required
        testId="propertySourcing"
        {...rest}
      />
    );
  }
);

export const PropertySourceOther = observer(({ store, ...rest }) => {
  const formFields = store.form.fields;
  return (
    <TextField
      testId="propertySourcingExplanation"
      variant="outlined"
      standaloneLabel
      label="Please specify the property sourcing"
      value={formFields.propertySourcingExplanation.value}
      onChange={e =>
        store.onFieldChange('propertySourcingExplanation', e.target.value)
      }
      fullWidth
      {...rest}
    />
  );
});

export const WholesalerPropertyPaid = observer(({ store, ...rest }) => {
  const formFields = store.form.fields;
  return (
    <CurrencyField
      variant="outlined"
      standaloneLabel
      label="How much does the wholesaler have the property under contract for?"
      value={formFields.wholesaleAmount.value}
      errorText={formFields.wholesaleAmount.error}
      error={isNotBlank(formFields.wholesaleAmount.error)}
      onChange={(name, value) =>
        store.onFieldChange('wholesaleAmount', Number(value))
      }
      fullWidth
      required
      testId="wholesaleAmount"
      {...rest}
    />
  );
});

export const WholesalerPropertySource = observer(({ store, ...rest }) => {
  const formFields = store.form.fields;
  return (
    <SelectField
      standaloneLabel
      label={'How did the wholesaler source the property?'}
      name={'wholesalerPurchase'}
      options={wholesalerPropertySources}
      variant="outlined"
      value={formFields.wholesalerPurchase.value}
      onChange={value => store.onFieldChange('wholesalerPurchase', value)}
      fullWidth
      required
      testId="wholesalerPurchase"
      {...rest}
    />
  );
});

export const WholesalerRelationship = observer(({ store, ...rest }) => {
  const formFields = store.form.fields;
  return (
    <SelectField
      standaloneLabel
      label={'What is your relationship with the wholesaler?'}
      name={'wholesalerRelationship'}
      options={wholesalerRelationships}
      variant="outlined"
      value={formFields.wholesalerRelationship.value}
      onChange={value => store.onFieldChange('wholesalerRelationship', value)}
      fullWidth
      testId="wholesalerRelationship"
      {...rest}
    />
  );
});

export const WholesalerPropertySourceOther = observer(({ store, ...rest }) => {
  const formFields = store.form.fields;
  return (
    <TextField
      testId="wholesalerPropertySourcingExplanation"
      variant="outlined"
      standaloneLabel
      label="Please specify the property sourcing"
      value={formFields.propertySourcingExplanation.value}
      onChange={e =>
        store.onFieldChange('propertySourcingExplanation', e.target.value)
      }
      fullWidth
      required
      {...rest}
    />
  );
});

export const RenovationBudget = observer(
  ({ store, onChange = null, ...rest }) => {
    const formFields = store.form.fields;

    const handleChange = (name, value) => {
      const newValue = Number(value);
      store.onFieldChange('totalRenovationBudget', newValue);
      onChange && onChange('totalRenovationBudget', newValue);
    };

    return (
      <CurrencyField
        variant="outlined"
        standaloneLabel
        label={'What is the renovation budget?'}
        value={formFields.totalRenovationBudget.value}
        errorText={formFields.totalRenovationBudget.error}
        error={isNotBlank(formFields.totalRenovationBudget.error)}
        onChange={handleChange}
        fullWidth
        required
        testId="totalRenovationBudget"
        decimalScale={0}
        {...rest}
      />
    );
  }
);

export const RenovationDescription = observer(
  ({ store, renovationDescriptionOptions, showError = false, ...rest }) => {
    const formFields = store.form.fields;
    const selectedValues = toJS(formFields.describeRenovation.value);
    return (
      <>
        <MultiSelectField
          standaloneLabel
          label={'Please describe the renovation'}
          name={'describeRenovation'}
          options={renovationDescriptionOptions}
          getOptionSelected={(option, selectedValue) => {
            return option.value == selectedValue.value;
          }}
          value={selectedValues ? selectedValues : []}
          onChange={(e, value) =>
            store.onFieldChange('describeRenovation', value)
          }
          renderInput={params => (
            <TextField
              testId="describeRenovation"
              {...params}
              variant="outlined"
            />
          )}
          fullWidth
          required
          testId="describeRenovation"
          {...rest}
        />
        {showError && isNotBlank(formFields.describeRenovation.error) && (
          <FormHelperText error>{formFields.describeRenovation.error}</FormHelperText>
        )}
      </>
    );
  }
);

export const CondoConversion = observer(({ store }) => {
  const formFields = store.form.fields;
  return (
    <RadioField
      standaloneLabel
      label="Is the property being converted to Condominiums?"
      value={formFields.condoConversion.value}
      errorText={formFields.condoConversion.error}
      error={isNotBlank(formFields.condoConversion.error)}
      name={'condoConversion'}
      options={yesNoOptions}
      onChange={value => store.onChange('condoConversion', value)}
      fullWidth
      row
      required
      testId="condoConversion"
    />
  );
});

export const FireDamage = observer(({ store }) => {
  const formFields = store.form.fields;

  return (
    <RadioField
      standaloneLabel
      label="Has the property suffered fire damage?"
      value={formFields.fireDamage.value}
      errorText={formFields.fireDamage.error}
      error={isNotBlank(formFields.fireDamage.error)}
      name={'fireDamage'}
      options={trueFalseOptions}
      onChange={value => store.onFieldChange('fireDamage', value == 'true')}
      fullWidth
      row
      required
      testId="fireDamage"
    />
  );
});

export const SquareFootageAdded = observer(({ store }) => {
  const formFields = store.form.fields;

  return (
    <RadioField
      standaloneLabel
      label="Is there square footage being added to the property and/or an ADU being added?"
      value={formFields.squareFootageAdded.value}
      errorText={formFields.squareFootageAdded.error}
      error={isNotBlank(formFields.squareFootageAdded.error)}
      name={'squareFootageAdded'}
      options={trueFalseOptions}
      onChange={value => store.onFieldChange('squareFootageAdded', value == 'true')}
      fullWidth
      row
      required
      testId="squareFootageAdded"
    />
  );
});

export const AnyDebt = observer(({ store, showError = false, ...rest }) => {
  const formFields = store.form.fields;
  return (
    <RadioField
      standaloneLabel
      label="Is there any debt?"
      value={formFields.anyDebt.value}
      name={'anyDebt'}
      options={yesNoOptions}
      onChange={value => store.onFieldChange('anyDebt', value)}
      fullWidth
      row
      required
      testId="anyDebt"
      errorText={showError ? formFields.anyDebt.error : undefined}
      error={showError ? isNotBlank(formFields.anyDebt.error) : undefined}
      {...rest}
    />
  );
});

export const DebtAmount = observer(({ store, ...rest }) => {
  const formFields = store.form.fields;
  return (
    <CurrencyField
      variant="outlined"
      standaloneLabel
      label="How much Debt is there?"
      value={formFields.refiAmount.value}
      onChange={(name, value) =>
        store.onFieldChange('refiAmount', Number(value))
      }
      fullWidth
      testId="refiAmount"
      {...rest}
    />
  );
});

export const PurchaseDate = observer(({ store, ...rest }) => {
  const formFields = store.form.fields;

  const manageDate = date => {
    let formattedDate = null;
    if (isValid(date)) {
      formattedDate = format(date, 'MM/dd/yyyy');
    }
    store.onFieldChange('purchaseDate', formattedDate);
  };

  return (
    <DateField
      inputVariant="outlined"
      testId="purchaseDate"
      format="MM/dd/yyyy"
      standaloneLabel
      label="When did you purchase the property?"
      value={formFields.purchaseDate.value}
      error={isNotBlank(formFields.purchaseDate.error)}
      helperText={formFields.purchaseDate.error}
      onChange={date => manageDate(date)}
      disableFuture={true}
      fullWidth
      required
      {...rest}
    />
  );
});

export const AvailableUntil = observer(({ store, ...rest }) => {
  const formFields = store.form.fields;

  const manageDate = date => {
    let formattedDate = null;
    if (isValid(date)) {
      formattedDate = format(date, 'MM/dd/yyyy');
    }
    store.onFieldChange('availableUntil', formattedDate);
  };

  return (
    <DateField
      inputVariant="outlined"
      testId="availableUntil"
      format="MM/dd/yyyy"
      standaloneLabel
      label="Available Until"
      value={formFields.availableUntil.value}
      error={isNotBlank(formFields.availableUntil.error)}
      helperText={formFields.availableUntil.error}
      onChange={date => manageDate(date)}
      disableFuture={false}
      disablePast={true}
      fullWidth
      required
      {...rest}
    />
  );
});


export const RefinanceRenovationBudget = observer(
  ({ store, onChange = null, ...rest }) => {
    const formFields = store.form.fields;

    const handleChange = (name, value) => {
      const newValue = Number(value);
      store.onFieldChange('totalRenovationBudget', newValue);
      onChange && onChange('totalRenovationBudget', newValue);
    };

    return (
      <CurrencyField
        variant="outlined"
        standaloneLabel
        label="What is the renovation budget including renovation capital that has already been invested plus the amount required to complete the project?"
        value={formFields.totalRenovationBudget.value}
        errorText={formFields.totalRenovationBudget.error}
        error={isNotBlank(formFields.totalRenovationBudget.error)}
        onChange={handleChange}
        fullWidth
        testId="totalRenovationBudget"
        {...rest}
      />
    );
  }
);

export const RefinanceInvestedCapitalImprovements = observer(
  ({ store, onChange = null, ...rest }) => {
    const formFields = store.form.fields;

    const handleChange = (name, value) => {
      const newValue = Number(value);
      store.onFieldChange('sunkCost', newValue);
      onChange && onChange('sunkCost', newValue);
    };

    return (
      <CurrencyField
        variant="outlined"
        standaloneLabel
        label="How much has been invested in Completed Renovations to date?"
        value={formFields.sunkCost.value}
        onChange={handleChange}
        fullWidth
        testId="sunkCost"
        {...rest}
      />
    );
  }
);

export const RefinanceRenovationAmountRemaining = observer(({ store }) => {
  // TODO - do we need to send renovationAmountRemaining to api
  const formFields = store.form.fields;
  return (
    <>
      <FieldLabel>Renovation Amount Remaining</FieldLabel>
      <Typography color="primary">
        <strong>
          {formatCurrency(Math.max(0, formFields.renovationRemaining.value))}
        </strong>
      </Typography>
    </>
  );
});

export const EstimatedAsIsValue = observer(({ store, ...rest }) => {
  const formFields = store.form.fields;
  return (
    <CurrencyField
      variant="outlined"
      standaloneLabel
      label="Estimated As Is Value"
      value={formFields.estimatedAsIsValue.value}
      onChange={(name, value) =>
        store.onFieldChange('estimatedAsIsValue', Number(value))
      }
      fullWidth
      testId="estimatedAsIsValue"
      {...rest}
    />
  )
});

export const AsIsValue = observer(({ store, showError = false, ...rest }) => {
  const formFields = store.form.fields;
  return (
    <CurrencyField
      variant="outlined"
      standaloneLabel
      label="What is the As-Is Value?"
      value={formFields.asIsValue.value}
      onChange={(name, value) =>
        store.onFieldChange('asIsValue', Number(value))
      }
      fullWidth
      testId="asIsValue"
      error={showError ? isNotBlank(formFields.asIsValue.error) : undefined}
      errorText={showError ? formFields.asIsValue.error : undefined}
      {...rest}
    />
  );
});

export const AfterRepairValue = observer(({ store, ...rest }) => {
  const formFields = store.form.fields;
  return (
    <CurrencyField
      variant="outlined"
      standaloneLabel
      label="What is the After repair value?"
      value={formFields.afterRepairValue.value}
      errorText={formFields.afterRepairValue.error}
      error={isNotBlank(formFields.afterRepairValue.error)}
      onChange={(name, value) =>
        store.onFieldChange('afterRepairValue', Number(value))
      }
      fullWidth
      required
      testId="afterRepairValue"
      decimalScale={0}
      {...rest}
    />
  );
});

export const ExitStrategy = observer(({ store, ...rest }) => {
  const formFields = store.form.fields;
  return (
    <SelectField
      standaloneLabel
      label={'Exit Strategy'}
      name={'exitStrategy'}
      options={exitStrategies}
      variant="outlined"
      value={formFields.exitStrategy.value}
      errorText={formFields.exitStrategy.error}
      error={isNotBlank(formFields.exitStrategy.error)}
      onChange={value => store.onFieldChange('exitStrategy', value)}
      fullWidth
      required
      testId="exitStrategy"
      {...rest}
    />
  );
});

export const AdditionalComments = observer(({ store, ...rest }) => {
  const formFields = store.form.fields;
  return (
    <TextField
      testId="additionalComments"
      variant="outlined"
      multiline
      standaloneLabel
      label="Additional Comments"
      value={formFields.additionalComments.value}
      onChange={e => store.onFieldChange('additionalComments', e.target.value)}
      fullWidth
      inputProps={{ maxLength: 1000 }}
      minRows={3}
      maxRows={Infinity}
      {...rest}
    />
  );
});

export const AdditionalCommentsMF = observer(({ store, ...rest }) => {
  const formFields = store.form.fields;
  return (
    <TextField
      testId="additionalComments"
      variant="outlined"
      multiline
      standaloneLabel
      label="Please outline the background / story for this opportunity (what is it about this deal that you like)"
      value={formFields.additionalComments.value}
      onChange={e => store.onFieldChange('additionalComments', e.target.value)}
      fullWidth
      inputProps={{ maxLength: 500 }}
      minRows={3}
      maxRows={Infinity}
      {...rest}
    />
  );
});

export const RedFlagComments = observer(({ store, ...rest }) => {
  const formFields = store.form.fields;
  return (
    <TextField
      testId="redFlagComments"
      variant="outlined"
      multiline
      standaloneLabel
      label="Can you outline all of the red flags or concerns that we should be aware of? (What should we know at the beginning of this process that may have an impact on how we look at the deal?)"
      value={formFields.redFlagComments.value}
      onChange={e => store.onFieldChange('redFlagComments', e.target.value)}
      fullWidth
      inputProps={{ maxLength: 500 }}
      minRows={3}
      maxRows={Infinity}
      {...rest}
    />
  );
});


export const SubmitRushAppraisal = observer(
  ({ store, onChange = null, ...rest }) => {
    const formFields = store.form.fields;

    return (
      <>
        <RadioField
          standaloneLabel
          label="Do you want to submit a rushed appraisal?"
          value={formFields.submitRushedAppraisal.value}
          errorText={formFields.submitRushedAppraisal.error}
          error={isNotBlank(formFields.submitRushedAppraisal.error)}
          name={'submitRushedAppraisal'}
          options={trueFalseOptions}
          onChange={value =>
            store.onFieldChange('submitRushedAppraisal', value === 'true')
          }
          fullWidth
          required
          row
          testId="submitRushedAppraisal"
          {...rest}
        />
        {formFields.submitRushedAppraisal.value && (
          <Typography color="primary">
            Rushed Appraisal cost extra $600 and appraisal report will be
            delivered within 3 business days, subject to access to the property.
          </Typography>
        )}
      </>
    );
  }
);

export const MonthlyMarketRent = observer(({ store }) => {
  const { form } = store;
  return (
    <CurrencyField
      variant="outlined"
      standaloneLabel
      label="Monthly Market Rent"
      value={form.fields.monthlyMarketRentProvidedAtOrigination.value}
      onChange={(name, value) =>
        store.onFieldChange(
          'monthlyMarketRentProvidedAtOrigination',
          Number(value)
        )
      }
      fullWidth
      testId="monthlyMarketRentProvidedAtOrigination"
      required
      error={isNotBlank(
        form.fields.monthlyMarketRentProvidedAtOrigination.error
      )}
      errorText={form.fields.monthlyMarketRentProvidedAtOrigination.error}
    />
  );
});

export const AnnualTaxes = observer(({ store }) => {
  const { form } = store;

  return (
    <CurrencyField
      variant="outlined"
      standaloneLabel
      label="Annual Taxes"
      value={form.fields.annualTaxesProvidedAtOrigination.value}
      onChange={(name, value) =>
        store.onFieldChange('annualTaxesProvidedAtOrigination', Number(value))
      }
      fullWidth
      testId="annualTaxesProvidedAtOrigination"
      required
      error={isNotBlank(form.fields.annualTaxesProvidedAtOrigination.error)}
      errorText={form.fields.annualTaxesProvidedAtOrigination.error}
    />
  );
});

export const AnnualInsurance = observer(({ store }) => {
  const { form } = store;
  return (
    <CurrencyField
      variant="outlined"
      standaloneLabel
      label="Annual Insurance"
      value={form.fields.annualInsuranceProvidedAtOrigination.value}
      onChange={(name, value) =>
        store.onFieldChange(
          'annualInsuranceProvidedAtOrigination',
          Number(value)
        )
      }
      fullWidth
      testId="annualInsuranceProvidedAtOrigination"
      required
      error={isNotBlank(form.fields.annualInsuranceProvidedAtOrigination.error)}
      errorText={form.fields.annualInsuranceProvidedAtOrigination.error}
    />
  );
});

export const AnnualHoa = observer(({ store }) => {
  const { form } = store;
  return (
    <CurrencyField
      variant="outlined"
      standaloneLabel
      label="Annual HOA Dues"
      value={form.fields.annualHOAProvidedAtOrigination.value}
      onChange={(name, value) =>
        store.onFieldChange('annualHOAProvidedAtOrigination', Number(value))
      }
      fullWidth
      testId="annualHOAProvidedAtOrigination"
      required
      error={isNotBlank(form.fields.annualHOAProvidedAtOrigination.error)}
      errorText={form.fields.annualHOAProvidedAtOrigination.error}
    />
  );
});

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.grey[200],
    fontWeight: "bold",
    textAlign: "center",
    border: "1px solid lightgray",
    width: "33%"
  },
  body: {
    fontSize: 14,
    border: "1px solid lightgray",
    padding: "4px",
    "&:last-child": {
      paddingRight: "4px",
    },
    width: "33%"
  },
}))(TableCell);

interface RehabDetailsProps {
  store: any;
  postRehabGradeFieldsDisabled?: boolean;
}

export const RehabDetails = observer(({ store, postRehabGradeFieldsDisabled = false }: RehabDetailsProps) => {
  return (
    (
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell>Pre-Rehab</StyledTableCell>
              <StyledTableCell>Post-Rehab</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <StyledTableCell style={{ paddingInline: "2rem" }}>
                Bedroom count
              </StyledTableCell>
              <StyledTableCell align="center">
                {createNumberFormatFieldWithAdornment({
                  store,
                  testId: 'preRehabBedroomsCount',
                  fieldName: 'preRehabBedroomsCount',
                  label: '',
                  allowNegative: false,
                  thousandSeparator: true,
                  decimalScale: 1,
                })}
              </StyledTableCell>
              <StyledTableCell align="center">
                {createNumberFormatFieldWithAdornment({
                  store,
                  testId: 'postRehabBedroomsCount',
                  fieldName: 'postRehabBedroomsCount',
                  label: '',
                  allowNegative: false,
                  thousandSeparator: true,
                  decimalScale: 1,
                })}
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell style={{ paddingInline: "2rem" }}>
                Bathroom count
              </StyledTableCell>
              <StyledTableCell align="center">
                {createNumberFormatFieldWithAdornment({
                  store,
                  testId: 'preRehabBathroomsCount',
                  fieldName: 'preRehabBathroomsCount',
                  label: '',
                  allowNegative: false,
                  thousandSeparator: true,
                  decimalScale: 1,
                })}
              </StyledTableCell>
              <StyledTableCell align="center">
                {createNumberFormatFieldWithAdornment({
                  store,
                  testId: 'postRehabBathroomsCount',
                  fieldName: 'postRehabBathroomsCount',
                  label: '',
                  allowNegative: false,
                  thousandSeparator: true,
                  decimalScale: 1,
                })}
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell style={{ paddingInline: "2rem" }}>
                Approximate sq. ft. above grade
              </StyledTableCell>
              <StyledTableCell align="center">
                {createNumberFormatFieldWithAdornment({
                  store,
                  testId: 'preRehabAproxSquareFootageAboveGrade',
                  fieldName: 'preRehabAproxSquareFootageAboveGrade',
                  label: '',
                  allowNegative: false,
                  thousandSeparator: true,
                  adornment: "sq. ft.",
                  decimalScale: 0,
                })}
              </StyledTableCell>
              <StyledTableCell align="center">
                {postRehabGradeFieldsDisabled ? 'N/A' : createNumberFormatFieldWithAdornment({
                  store,
                  testId: 'postRehabAproxSquareFootageAboveGrade',
                  fieldName: 'postRehabAproxSquareFootageAboveGrade',
                  label: '',
                  allowNegative: false,
                  thousandSeparator: true,
                  adornment: "sq. ft.",
                  decimalScale: 0,
                })}
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell style={{ paddingInline: "2rem" }}>
                Approximate sq. ft. below grade
              </StyledTableCell>
              <StyledTableCell align="center">
                {createNumberFormatFieldWithAdornment({
                  store,
                  testId: 'preRehabAproxSquareFootageBelowGrade',
                  fieldName: 'preRehabAproxSquareFootageBelowGrade',
                  label: '',
                  allowNegative: false,
                  thousandSeparator: true,
                  adornment: "sq. ft.",
                  decimalScale: 0,
                })}
              </StyledTableCell>
              <StyledTableCell align="center">
                {postRehabGradeFieldsDisabled ? 'N/A' : createNumberFormatFieldWithAdornment({
                  store,
                  testId: 'postRehabAproxSquareFootageBelowGrade',
                  fieldName: 'postRehabAproxSquareFootageBelowGrade',
                  label: '',
                  allowNegative: false,
                  thousandSeparator: true,
                  adornment: "sq. ft.",
                  decimalScale: 0,
                })}
              </StyledTableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    )

  );
});