import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import {
  StandardDialog,
  Button
} from '@roc/ui';
import EditPointOfContactForm from './editPointOfContactForm';
import { useDrawsStore } from '../hooks';
import { useLoanStore } from '@roc/feature-loans';
import { Grid, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

interface PointOfContactDialogProps {
  open: boolean;
  onClose: () => void;
}

const useStyles = makeStyles(() => ({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginLeft: 24,
    marginTop: 24
  },
}));

export const PointOfContactDialog = observer(({ open, onClose }: PointOfContactDialogProps) => {
  const classes = useStyles();
  const { pointOfContactStore, drawStore } = useDrawsStore();
  const { pointOfContactFormStore } = pointOfContactStore;
  const { loanStore } = useLoanStore();

  const handleConfirm = () => {
    pointOfContactFormStore.runFormValidation();
    if (pointOfContactFormStore.form.meta.isValid) {
      pointOfContactStore.savePointOfContact(drawStore.currentDraw.drawId, pointOfContactFormStore.getFormValues());
      onClose();
    }
  };

  useEffect(() => {
    return () => pointOfContactStore.resetStore();
  }, [])

  useEffect(() => {
    if (open) {
      pointOfContactStore.getPointOfContact(loanStore.loanDetails.loanId);
    }
  }, [open])

  return (
    <StandardDialog
      open={open}
      title="Point of Contact"
      maxWidth="sm"
      handleClose={onClose}
      dialogContent={
        <>
          <EditPointOfContactForm />
          <Grid item container className={classes.buttonContainer}>
            <Box mr={2}>
              <Button
                className={classes.button}
                size="small"
                variant="contained"
                color="secondary"
                onClick={onClose}
                testId="cancel"
              >
                CANCEL
              </Button>
              <Button
                className={classes.button}
                size="small"
                variant="contained"
                color="primary"
                onClick={handleConfirm}
                testId="save"
              >
                SAVE
              </Button>
            </Box>
          </Grid>
        </>
      }
    />)
});
