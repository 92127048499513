import { Document } from '@roc/feature-types';

export enum LoanDetailsTab {
  LOAN_SUMMARY = 'LOAN_SUMMARY',
  UNDERWRITING = 'UNDERWRITING',
  CLOSING = 'CLOSING',
  DRAWS = 'DRAWS',
  EXTENSIONS = 'EXTENSIONS',
  PAYOFFS = 'PAYOFFS',
  DECISION_COMMENTS = 'DECISION_COMMENTS',
  FORECLOSURE_REQUEST = 'FORECLOSURE_REQUEST',
  FORECLOSURE_INVOICE = 'FORECLOSURE_REQUEST',
  CONTACTS = 'CONTACTS',
  POST_CLOSING = 'POST_CLOSING'
}

export enum TodoStatus {
  PENDING = 'Pending',
  BEING_REVIEWED = 'Being Reviewed',
  MORE_INFO_NEEDED = 'More Info Needed',
  ACCEPTED = 'Accepted',
  NOT_APPLICABLE = 'Not Applicable',
  REPORTS_DELIVERED = 'Report(s) Delivered',
  CANCELED = 'Canceled',
  ALL = 'All',
}

export enum ClosingStage {
  UNDEFINED = 'UNDEFINED',
  LOAN_AGREED = 'Loan Agreed',
  TITLE_CONTACTED = 'Title Contacted',
  CLOSING_SCHEDULED = 'Closing Scheduled',
  PENDING_APPROVAL = 'Pending Approval',
  APPROVED_TO_FUND = 'Approved to Fund',
  DOCS_SENT_FOR_SIGNING = 'Docs Sent for Signing',
  WIRE_REQUESTED = 'Wire Requested',
  WIRE_SENT = 'Wire Sent',
  LOAN_CLOSED_AND_FUNDED = 'Loan Closed & Funded',
}

export interface TodoItem {
  todoId: number;
  title: string;
  status: TodoStatus;
  lastUpdateDate: string;
  category: string;
  document?: Document;
}
