import {
  Box
} from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { useBaseStore } from '@roc/feature-app-core';
import { useCommunicationRoutesConfig, useLoanCommunicationStore } from '@roc/feature-communication';
import { useDocumentStore } from '@roc/feature-documents';
import { useLoanRoutes } from '@roc/feature-loans-routes-config';
import { insertIf } from '@roc/feature-utils';
import { BreadcrumbsNew, Paper } from '@roc/ui';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useBorrowerLoansStore } from '../../hooks/useBorrowerLoansStore';
import { BorrowerLoanDetailsChildRoutes } from './components/borrowerLoanDetailsChildRoutes';
import { LoanDetailsHeader } from './components/loanDetailsHeader';
import { LoanDetailsTabs } from './components/loanDetailsTabs';
import { useBorrowerLoansRoutes } from '../../hooks/useBorrowerLoansRoutes';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2, 0),
    },
  },
  breadCrumb: {
    '& *': {
      fontSize: '.875rem'
    }
  }
}));

const BreadCrumbSeparator = () => {
  return <span>&#8226;</span>
}

export const BorrowerLoanDetailsNew = observer(() => {
  const classes = useStyles();
  const { loanId } = useParams<{ loanId: string }>();
  const [selectedTab, setSelectedTab] = useState(0);
  const { borrowerLoansStore } = useBorrowerLoansStore();
  const { borrowerLoanRoutesConfig } = useBorrowerLoansRoutes();
  const { communicationRoutesConfig } = useCommunicationRoutesConfig();
  const { documentStore } = useDocumentStore();
  const { loanCommunicationStore } = useLoanCommunicationStore();
  const { push } = useHistory();
  const { pathname } = useLocation();
  const {
    loanDetails,
  } = borrowerLoansStore;
  const { globalStore } = useBaseStore();
  const { breadCrumbsStore: { breadCrumbItems } } = globalStore;

  const isBridge = (loanDetails?.loanType === 'Residential Bridge');
  const isServicerPayoffApplicable = ['SLS', 'Keybank', 'Fay Servicing', 'Planet Home Lending', 'Planet Portfolio'].includes(loanDetails?.servicer);
  const shouldShowServicing = documentStore.showServicing && !globalStore.userFeatures.isClosingAttorney && !globalStore.userFeatures.isClosingReviewer && globalStore.userFeatures.isBorrower;

  const tabs = [
    {
      label: 'Loan Summary',
      url: borrowerLoanRoutesConfig.loans(loanId).children.dashboard.url
    },
    /* {
      label: 'Documents',
      url: borrowerLoanRoutesConfig.loans(loanId).children.borrowerDocuments.url
    }, */
    ...insertIf(loanDetails?.showDraws, [{
      label: 'Draws',
      url: borrowerLoanRoutesConfig.loans(loanId).children.draws.url
    }]),
    ...insertIf(shouldShowServicing && loanDetails.showExtensions && isBridge, [{
      label: 'Extensions',
      url: borrowerLoanRoutesConfig.loans(loanId).children.extensions.url
    }]),
    ...insertIf(shouldShowServicing && loanDetails.showPayoffs && isServicerPayoffApplicable, [{
      label: 'Payoffs',
      url: borrowerLoanRoutesConfig.loans(loanId).children.payoffRequest.url
    }]),
    {
      label: 'Conversations',
      badgeCount: loanCommunicationStore.totalUnreadMessagesCount,
      url: communicationRoutesConfig.conversations(loanId).url
    },
  ];

  useEffect(() => {
    tabs.forEach((t, i) => {
      if (pathname.indexOf(t.url) !== -1) {
        setSelectedTab(i);
      }
    });
  }, [tabs, pathname]);

  useEffect(() => {
    borrowerLoansStore.fetchLoanDetails(loanId);
  }, [loanId]);

  useEffect(() => {
    documentStore.fetchSections();
  }, []);

  if (!loanDetails) {
    return null;
  }

  const onTabChange = (e, index) => {
    push(tabs[index].url);
    setSelectedTab(index);
  }

  return (
    <Box className={classes.container}>
      <Box display="flex" flexDirection="column" mb={3}>
        <Box mb={2}>
          <BreadcrumbsNew className={classes.breadCrumb} breadCrumbItems={breadCrumbItems} separator={<BreadCrumbSeparator />} />
        </Box>
        <Paper style={{ padding: '24px 24px 0 24px' }}>
          <Box display="flex" alignItems="center" flexDirection="column">
            <Box width={'100%'} display="flex" flexDirection="column" overflow="hidden" textAlign={'center'}>
              <Box mb={3}>
                <LoanDetailsHeader loanDetails={loanDetails} overviewUrl={borrowerLoanRoutesConfig.loans(loanDetails?.loanId).children.dashboard.url} />
              </Box>
            </Box>
            <Box maxWidth={'100%'}>
              <LoanDetailsTabs tabs={tabs} tabIndex={selectedTab} onTabChange={onTabChange} />
            </Box>
          </Box>
        </Paper>
      </Box>
      <Box display="flex">
        <Box flexGrow={1}>
          <BorrowerLoanDetailsChildRoutes isNew={true} />
        </Box>
      </Box>
    </Box>
  );
});
