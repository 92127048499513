import { Service } from "@roc/feature-app-core";

const baseUrl = {
  CALCULATE: '/api/v1/pricer/bridgePricer/calculateBridgeProfitAndDscr',
  LOAN_VALUES: '/api/v1/pricer/bridgePricer/getBridgeProfitAndDscrByLoanId',
  GET_BRIDGE_DEAL_NAMES: '/api/v1/loan/getBridgeDealNames',
  GET_EXIT_RATE: '/api/v1/pricer/bridgePricer/getExitRate',
};

export class BridgeProfitAndDscrCalculatorService extends Service {

  protected url: any;

  constructor() {
    super();
    this.url = baseUrl;
  }

  calculate(request) {
    return this.post(this.url.CALCULATE, request);
  }

  getExitRate() {
    return this.get(this.url.GET_EXIT_RATE);
  }

  getBridgeDealNames(
    page: number,
    pageSize: number,
    sortDir: string,
    sortBy: string,
    filters: object
  ) {
    return this.get(this.url.GET_BRIDGE_DEAL_NAMES, {
      page,
      pageSize,
      sortDir,
      sortBy,
      filters,
    });
  }

  getLoanValues(loanId) {
    return this.get(`${this.url.LOAN_VALUES}?loanId=${loanId}`);
  }

}
