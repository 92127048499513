import { ApiResponse } from "@roc/feature-app-core";
import { flow, makeObservable } from "mobx";
import { ConversationAttribute, ResetUnreadCountRequest } from "../types/communicationTypes";
import { CommunicationStore } from "./communicationStore";


export class AppraisalCommunicationStore extends CommunicationStore {

  constructor(globalStore, userStore, communicationService, isInternal = false) {
    super(globalStore, userStore, communicationService, isInternal);
    makeObservable(this, {
      resetUnreadCount: flow,
      updateConversationStarredStatus: flow,
    });
  }

  *resetUnreadCount(appraisalId: number) {
    try {
      const request: ResetUnreadCountRequest = {
        chatRoomId: this.currentChatRoomId,
        chatRoomType: this.currentChatRoomType,
        userId: this.userId,
        conversationSid: this.currentConversationSid,
        appraisalId,
      }
      this.communicationService.resetUnreadCount(request);
    } catch (e) {
      // do nothing
    }
  }

  *updateConversationStarredStatus(isStarred: boolean, chatRoomId: number, conversationSid: string, callBack?: () => void) {
    throw Error('Not Implemented!');
  }

  *getParticipantsAllowedByLoanId(id, disableGlobalLoading = false) {
    throw new Error("getParticipantsAllowedByLoanId not implemented for Appraisals");
  }

  *getParticipantsAllowedByChatRoomId(id, disableGlobalLoading = false) {
    throw new Error("getParticipantsAllowedByChatRoomId not implemented for Appraisals");
  }

  *setConversationMessageDefaultTag(conversationAttribute: ConversationAttribute, conversationInfo) {
    throw new Error("setConversationMessageDefaultTag not implemented for Appraisals");
  }

}