import { computed, flow, makeObservable, observable } from 'mobx';
import { GlobalStore } from '@roc/feature-app-core';
import { LoanStore } from '@roc/feature-loans';
import { FormStore } from '@roc/feature-app-core';
import { Document } from '@roc/feature-types';
import { DocumentStatus } from '../../constants';
import { BorrowerSurveyService } from '../../services/documentForms/borrowerSurveyService';
import { DocumentStore } from '../documentStore';

const form = {
  fields: {
    firstName: {
      value: '',
      error: null,
      rule: '',
    },
    lastName: {
      value: '',
      error: null,
      rule: '',
    },
    encounters: {
      value: '',
      error: null,
      rule: '',
    },
    realEstateExperience: {
      value: '',
      error: null,
      rule: '',
    },
    payHabits: {
      value: '',
      error: null,
      rule: '',
    },
    meetingInfo: {
      value: '',
      error: null,
      rule: '',
    },
    yearsOfKnowing: {
      value: '',
      error: null,
      rule: '',
    },
    rentalPropertiesOwned: {
      value: '',
      error: null,
      rule: '',
    },
    honestyAndTransparancyLevel: {
      value: 5,
      error: null,
      rule: '',
    },
    financialControlLevel: {
      value: 5,
      error: null,
      rule: '',
    },
    organizationAndRecordKeepingLevel: {
      value: 5,
      error: null,
      rule: '',
    },
    likeabilityLevel: {
      value: 5,
      error: null,
      rule: '',
    },
    experienceLevel: {
      value: 5,
      error: null,
      rule: '',
    },
    loansDone: {
      value: '',
      error: null,
      rule: '',
    },
    flipsCompletedInLifetime: {
      value: '',
      error: null,
      rule: '',
    },
    flipsCompletedInLastYear: {
      value: '',
      error: null,
      rule: '',
    },
    flippingBusinessStyle: {
      value: '',
      error: null,
      rule: '',
    },
    realEstateLicense: {
      value: '',
      error: null,
      rule: '',
    },
    relavantInfo: {
      value: '',
      error: null,
      rule: '',
    },
  },
  meta: {
    isValid: true,
    error: null,
  },
};

export class BorrowerSurveyStore extends FormStore {
  loanStore: LoanStore;
  documentStore: DocumentStore;
  globalStore: GlobalStore;
  borrowerSurveyService: BorrowerSurveyService;
  surveyDetail: { content: string };

  constructor(
    loanStore: LoanStore,
    documentStore: DocumentStore,
    globalStore: GlobalStore
  ) {
    super(form, globalStore);
    this.loanStore = loanStore;
    this.documentStore = documentStore;
    this.globalStore = globalStore;
    this.borrowerSurveyService = new BorrowerSurveyService();
    makeObservable(this, {
      canEdit: computed,
      canSubmit: computed,
      initForm: flow,
      submit: flow,
    });
  }

  *initForm(borrowerId: string) {
    const response = yield this.borrowerSurveyService.getBorrowerSurvey(
      borrowerId
    );
    const surveyDetail = JSON.parse(response.data.data);
    const surveyContent = JSON.parse(surveyDetail.content);
    const fields = surveyContent.borrowerSurvey;
    const fieldValues = {};
    for (let field of fields) {
      if (field.fieldType === 'SliderRange') {
        fieldValues[field.fieldName] = parseInt(field.fieldValue ? field.fieldValue : 0);
      } else {
        fieldValues[field.fieldName] = field.fieldValue;
      }
    }

    this.surveyDetail = surveyDetail;
    this.loadForm(fieldValues);
  }

  private buildRequestPayload(document: Document) {
    const formValues = this.getFormValues();
    const surveyContent = JSON.parse(this.surveyDetail.content);
    const fields = surveyContent.borrowerSurvey;
    for (let field of fields) {
      field.fieldValue = formValues[field.fieldName];
    }

    return {
      loanDocument: {
        ...document,
        status: DocumentStatus.BEING_REVIEWED,
      },
      borrowerSurveyDetail: {
        ...this.surveyDetail,
        content: JSON.stringify(surveyContent),
      },
    };
  }

  *submit(document: Document) {
    this.runFormValidationWithMessage();
    if (this.canSubmit && this.form.meta.isValid) {
      try {
        const payload = this.buildRequestPayload(document);
        yield this.borrowerSurveyService.updateLoanDocumentBorrowerSurvey(
          payload
        );
        this.globalStore.notificationStore.showSuccessNotification({
          message: 'Borrower survey questionnaire saved successfully',
        });
      } catch (error) {
        this.globalStore.notificationStore.showErrorNotification({
          message: 'Error while submitting borrower survey questionnaire',
        });
      }
    }
  }

  get canEdit() {
    const entitlements = this.globalStore.userFeatures.underwritingEntitlements;
    return entitlements?.isSubmissionAllowed;
  }

  get canSubmit() {
    return this.canEdit && this.form.meta.isValid;
  }
}

export default BorrowerSurveyStore;
