import { observer } from 'mobx-react';
import { useNewLeadStore } from '../../hooks/useNewLeadStore';
import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router';
import { useRoutes } from '../../hooks/useRoutes';
import { useStore } from '@roc/client-portal-shared/hooks';
import { PossibleMatches } from './possibleMatches';

export const PossibleMatchesContainer = observer(() => {
  const { newLeadStore } = useNewLeadStore();
  const { userDetailStore } = newLeadStore;
  const { form } = userDetailStore;
  const { push } = useHistory();
  const { leadsDashboardRoutesConfig } = useRoutes();


  useEffect(() => {
    if (!newLeadStore.globalSearch) {
      push(leadsDashboardRoutesConfig.leadsDashboard.url);
    }
  }, []);


  return (
      <PossibleMatches/>
    )
});
