import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDocumentStore } from '@roc/feature-documents';
import { ConfirmCloseButtons } from './confirmCloseComponent';
import { useEffect } from 'react';

const useStyles = makeStyles({
  container: {
    paddingTop: 16,
  },
});

export const CDADefault = ({ isInternal }) => {

  useEffect(() => {
    if (isInternal)
      window.parent.postMessage('cda-default', '*');
  }, [])



  const classes = useStyles();
  return (<Grid container justifyContent="center" className={classes.container}>
    <Grid item xs={11}>
      <Typography align='center' style={{ fontWeight: 'bold' }}>CDA/ARR Order details will be available once all Appraisals are available and approved by the reviewer</Typography>
    </Grid>
    <Grid item xs={11}>
      <ConfirmCloseButtons isInternal={isInternal} hasConfirmButton={false}/>
    </Grid>
  </Grid>
  )
}