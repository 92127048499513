import {
  Avatar,
  Box,
  Collapse,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { sanitizeTestId } from '@roc/feature-utils';
import {
  BorrowerCard,
  Paper,
  TestDataAttribute
} from '@roc/ui';
import React from 'react';
import { LoanDetailsHeader } from '../components/loanDetailsHeader';
import { BorrowerPortalCard } from '@roc/feature-borrower-portal-core';
import { AccountCircleRounded, ExpandLess, ExpandMore, Person, PersonRounded } from '@material-ui/icons';
import { formatPercentage } from '@roc/ui/utils';

const useStyles = makeStyles(theme => ({
  paper: {
    marginBottom: theme.spacing(2),
  },
  showPropertiesLink: {
    color: theme.palette.primary.main,
    textDecoration: 'underline',
  },
  height100Percent: {
    height: '100%'
  },
}));

export const formatDigit = (value, digits) => value?.toFixed(3, digits);

export const listItem = (label, value, icon = null) => {
  const testId = sanitizeTestId(label);
  return (
    <ListItem>
      <ListItemText>
        <Box display="flex">
          {icon && <Box ml={1}>{icon}</Box>}
          <Box flexGrow={1}>{label}</Box>
          <TestDataAttribute id={testId}>
            <Box textAlign={'right'}>{value}</Box>
          </TestDataAttribute>
        </Box>
      </ListItemText>
    </ListItem>
  );
};

export const SectionHeader = ({ children }) => {
  return (
    <>
      <Grid item xs={12}>
        <Box py={1}>
          <Divider light />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box pt={2}>
          <Typography variant="h5">{children}</Typography>
        </Box>
      </Grid>
    </>
  );
};

export const Dividers = ({ children, ...props }) => {
  return React.Children.map(children, (child, i) => (
    <>
      {i !== 0 && child && <Divider {...props} />}
      {child}
    </>
  ));
};

export const EntityAndBorrowersCard = ({ entity, borrowers }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const theme = useTheme();

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <BorrowerPortalCard cardTitle="Entity" cardRootClassName={classes.height100Percent} noPaddingCardContent>
      <List>
        <Dividers variant="fullWidth" component="li">
          {listItem('Entity Name', entity?.company?.name)}
          {listItem('Entity EIN', entity?.ein)}
          {listItem('Entity Type', entity?.type)}
          {
            borrowers?.length > 0 && (
              <ListItem button onClick={handleClick}>
                <ListItemText primary={
                  <Typography variant="h6">
                    Borrowers
                  </Typography>
                } />
                {open ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
            )}
        </Dividers>
        {borrowers?.length > 0 && (
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <Dividers variant="inset" component="li">
                {borrowers.map((borrower, index) => (
                  <ListItem key={borrower?.borrowerId}>
                    <ListItemAvatar>
                      <Avatar style={{
                        background: theme.palette.primary.main
                      }}>
                        <PersonRounded />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={`${borrower?.firstName} ${borrower?.lastName}`}
                      secondary={
                        <>
                          <div>
                            {borrower?.emailAddress}
                          </div>
                          {
                            borrower?.pctOwnership &&
                            <div>
                              {`Ownership: ${formatPercentage(borrower.pctOwnership, 0)}`}
                            </div>
                          }
                        </>
                      } />
                  </ListItem>
                ))}
              </Dividers>
            </List>
          </Collapse>
        )}
      </List>
    </BorrowerPortalCard>
  );
};

export const EntityInformationSection = ({ loanDetails }) => {
  const classes = useStyles();
  const entity = loanDetails?.borrowerEntity;

  return (
    <>
      <SectionHeader>Entity</SectionHeader>
      <Grid item xs={12} md={6}>
        <Paper className={classes.paper}>
          <List>
            <Dividers variant="middle" component="li">
              {listItem('Entity Name', entity?.company?.name)}
              {listItem('Entity EIN', entity?.ein)}
              {listItem('Entity Type', entity?.type)}
            </Dividers>
          </List>
        </Paper>
      </Grid>
    </>
  );
};

export const ServicerInformationSection = ({ loanDetails }) => {
  const classes = useStyles();

  return (
    <>
      <SectionHeader>Servicer</SectionHeader>
      <Grid item xs={12} md={6}>
        <Paper className={classes.paper}>
          <List>
            <Dividers variant="middle" component="li">
              {listItem('Servicer Name', loanDetails?.servicer)}
              {listItem('Servicer Id', loanDetails?.servicerId)}
            </Dividers>
          </List>
        </Paper>
      </Grid>
    </>
  );
};

export const BorrowersSection = ({ loanDetails }) => {
  const classes = useStyles();
  const borrowers = loanDetails?.borrowers;

  return (
    <>
      {borrowers?.length > 0 && (
        <>
          <SectionHeader>Borrowers</SectionHeader>
          {borrowers.map((borrower, index) => (
            <Grid item xs={12} lg={6} key={borrower.borrowerId}>
              <Box mt={-1} pb={2}>
                <BorrowerCard
                  className={classes.paper}
                  displayFields={{
                    firstName: true,
                    lastName: true,
                    emailAddress: true,
                    pctOwnership: true,
                  }}
                  title={`Borrower #${index + 1}`}
                  borrower={borrower}
                  largeCard={false}
                />
              </Box>
            </Grid>
          ))}
        </>
      )}
    </>
  );
};
