import { GlobalStore } from '@roc/feature-app-core';
import { action, flow, makeObservable, observable } from 'mobx';
import { OneToolService } from '../../services/oneToolService';
import { BrokerFormStore } from './brokerFormStore';
import { GENERIC_ERROR_MESSAGE } from '@roc/feature-utils';

export class BrokerStore {
  private globalStore: GlobalStore;
  private oneToolService: OneToolService;
  public brokerFormStore: BrokerFormStore;
  public lenderBrokerData: any[];

  constructor(globalStore) {
    this.globalStore = globalStore;
    this.oneToolService = new OneToolService();
    this.brokerFormStore = new BrokerFormStore(globalStore);
    this.lenderBrokerData = [];

    makeObservable(this, {
      reset: action,
      getLenderBrokerDropdownData: flow,
      lenderBrokerData: observable,
      getBrokerById: flow,
      validateBroker_quote: action,
      validateBroker_preflight: action,
      loadBroker: action,
      getBroker: action,
    });
  }

  *getLenderBrokerDropdownData() {
    try {
      const response = yield this.oneToolService.getLenderBrokerData();
      this.lenderBrokerData = response.data.data;
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE,
      });
    }
  }

  *getBrokerById(brokerId: number) {
    try {
      const response = yield this.oneToolService.getBrokerById(brokerId);
      this.loadBrokerFromSelection(response.data.data, this.brokerFormStore.getFormValues());
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE,
      });
    }
  }

  loadBrokerFromSelection(broker, brokerFormValues) {
    this.loadBroker({
      ...broker ?? {},
      brokerType: brokerFormValues.brokerType || 'ON_THE_HUD',
      brokerPoints: brokerFormValues?.brokerPoints ?? '',
    })
  }

  loadBroker(broker) {
    this.brokerFormStore.loadForm({
      brokerId: broker?.brokerId ?? '',
      brokerType: broker?.brokerType ?? '',
      brokerPoints: broker?.brokerPoints ?? '',
      companyName: broker?.companyName ?? '',
      companyEin: broker?.companyEin ?? '',
      contactFirstName: broker?.contactFirstName ?? '',
      contactLastName: broker?.contactLastName ?? '',
      contactEmail: broker?.contactEmail ?? '',
      contactPhoneNumber: broker?.contactPhoneNumber ?? '',
      hasBroker: broker?.hasBroker ?? true,
    });
  }

  reset() {
    this.brokerFormStore.reset();
  }

  getBroker() {
    const values = this.brokerFormStore.getFormValues();
    return {
      ...values,
      brokerType: values.brokerType ? values.brokerType : null,
    };
  }

  setHasBroker(value) {
    this.brokerFormStore.reset();
    this.brokerFormStore.onFieldChange('hasBroker', value);
  }

  validateBroker_quote() {
    this.brokerFormStore.onFieldChange('isPreflight', Boolean(false));
    this.brokerFormStore.onFieldChange('isQuote', Boolean(true));
    this.brokerFormStore.runFormValidationWithMessage();
    return this.brokerFormStore.form.meta.isValid;
  }

  validateBroker_preflight() {
    this.brokerFormStore.onFieldChange('isPreflight', Boolean(true));
    this.brokerFormStore.onFieldChange('isQuote', Boolean(false));
    if (this.getBroker().hasBroker) {
      this.brokerFormStore.runFormValidationWithMessage();
      return this.brokerFormStore.form.meta.isValid;
    } else {
      return true;
    }
  }

}
