import { Grid } from '@material-ui/core';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { BorrowerEntityInformationSteps } from '../../utils/constants';
import { BorrowerEntityInformation } from './borrowerEntityInformation';
import { BorrowerEntityOwnershipInformation } from './borrowerEntityOwnershipInformation';
import { EditBorrowerEntityInformation } from './editBorrowerEntityInformation';

export const BorrowerEntityInformationStep = observer(({ store }) => {
  const { borrowerEntityInformationStore } = store;

  useEffect(() => {
    borrowerEntityInformationStore.fetchBorrowerAndEntityData();
  }, []);

  return (
    <Grid container direction="row" spacing={2}>
      {borrowerEntityInformationStore.borrowerEntityInformationActiveStep ===
        BorrowerEntityInformationSteps.ENTITY_INFORMATION && (
        <BorrowerEntityInformation store={borrowerEntityInformationStore} />
      )}
      {borrowerEntityInformationStore.borrowerEntityInformationActiveStep ===
        BorrowerEntityInformationSteps.ENTITY_OWNERSHIP && (
        <BorrowerEntityOwnershipInformation store={store} />
      )}
      {borrowerEntityInformationStore.borrowerEntityInformationActiveStep ===
        BorrowerEntityInformationSteps.EDIT_ENTITY_INFORMATION && (
        <EditBorrowerEntityInformation store={borrowerEntityInformationStore} />
      )}
    </Grid>
  );
});
