import { Button } from '@roc/ui';
import React, { useState } from 'react';
import { PopupModal } from 'react-calendly';

interface CalendlyWidgetProps {
  url: string;
  prefill: any;
}

const CalendlyWidget: React.FC<CalendlyWidgetProps> = ({
  url,
  prefill,
}) => {
  const [isCalendlyModalOpen, setIsCalendlyModalOpen] = useState<boolean>(false);

  return (
    <div>
      <Button
        hidden={true}
        size="small"
        variant="contained"
        color="secondary"
        testId="scheduleCalendlyCall"
        onClick={() => setIsCalendlyModalOpen(true)}
      >
        Schedule a Call
      </Button>
      <PopupModal
        url={`https://calendly.com/${url}`}
        prefill={prefill}
        onModalClose={() => setIsCalendlyModalOpen(false)}
        open={isCalendlyModalOpen}
        /*
         * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
         * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
         */
        rootElement={document.getElementById("root")}
      />
    </div>
  );
};

export default CalendlyWidget;
