import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import { useAppraisalCommunicationStore } from './hooks';
import { MyChatContainer, SelectConversation } from './myChatContainer';

const useStyles = makeStyles((theme: Theme) => ({
  chatContainer: {
    flex: 1,
  },
}));

interface AppraisalConversationProps {
  showEmailAddress?: boolean;
}

export const AppraisalConversation = observer(
  ({ showEmailAddress = false }: AppraisalConversationProps) => {
    const classes = useStyles();
    const { appraisalCommunicationStore } = useAppraisalCommunicationStore();
    const { appraisalId } = useParams<{ appraisalId: string }>();

    return (
      <Box display="flex" width={'100vw'} height={'100vh'}>
        <MyChatContainer
          id={Number(appraisalId)}
          communicationStore={appraisalCommunicationStore}
          allowNewConversations={false}
          allowJoinConversation={true}
          showEmailAddress={showEmailAddress}
          showSnippet={false}
          onConversationSelect={conversationId =>
            appraisalCommunicationStore.setCurrentConversationId(conversationId)
          }
          className={classes.chatContainer}
        />
        <SelectConversation communicationStore={appraisalCommunicationStore} />
      </Box>
    );
  }
);
