import { Service } from '@roc/client-portal-shared/services';

const baseUrl = '/api/v1/insurance/insurance-review';

const url = {
  GET_INSURANCE_DATA_BY_LOAN_ID: `${baseUrl}/getInsuranceReviewDataByLoanId`,
  APPROVE_OR_REJECT_ITEMS: `${baseUrl}/overrideActions`,
  UPDATE_INSURANCE_ITEM: `${baseUrl}/saveLineItem`,
  UPDATE_INSURANCE_ITEMS: `${baseUrl}/saveLineItems`,
  REMOVE_FROM_PENDING_TASKS: `${baseUrl}/removeFromPendingTasks`,
  MOVE_TO_PENDING_TASKS: `${baseUrl}/moveToPending`,
};

export class InsuranceReviewService extends Service {

  getInsuranceData(loanId: number) {
    return this.get(`${url.GET_INSURANCE_DATA_BY_LOAN_ID}?loanId=${loanId}`);
  }
  approveOrRejectItems(insuranceDataItems: any[], override: boolean, loanId: any) {
    return this.post(`${url.APPROVE_OR_REJECT_ITEMS}?override=${override}&loanId=${loanId}`, insuranceDataItems);
  }

  updateInsuranceItems(propertyInsuranceData: any[]) {
    return this.post(url.UPDATE_INSURANCE_ITEMS, propertyInsuranceData);
  }

  updateInsuranceItem(insuranceDataItem: any) {
    return this.post(`${url.UPDATE_INSURANCE_ITEM}`, insuranceDataItem);
  }

  removeFromPendingTasks(loanId: number) {
    return this.get(`${url.REMOVE_FROM_PENDING_TASKS}?loanId=${loanId}`);
  }

  moveToPending(loanId: number) {
    return this.get(`${url.MOVE_TO_PENDING_TASKS}?loanId=${loanId}`);
  }

}
