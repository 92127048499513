import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Box, Toolbar, Typography } from '@material-ui/core';
import { formatCurrency, formatPercentage } from '@roc/ui/utils';

const useStyles = makeStyles({
  table: {
    minWidth: 400,
    '& .MuiTableRow-head th': {
      fontWeight: 'bold',
    },
  },
  tableContainer: {
    maxHeight: 200,
  },
});

function createData(name: string, subject: number, dataScience: number, dataScienceVariance: number, zillow: number, zillowVariance: number, redfin: number, redfinVariance: number) {
  return { name, subject, dataScience, dataScienceVariance, zillow, zillowVariance, redfin, redfinVariance };
}

const rows = [
  createData('Median Sale Price', 200000, 220000, 10, 220000, 10, 240000, 20),
  createData('Median Sale Price /sqft', 120, 120, 0, 110, -10, 130, 8),
];


export const MarketSaleDataComparisonTable = () => {
  const classes = useStyles();

  return (
    <Paper elevation={0} variant='outlined'>
      <Toolbar style={{
        minHeight: 52,
        padding: '8px 16px',
      }}>
        <Typography variant="h6" component="div">
          Market Sales Data Comparison
        </Typography>
      </Toolbar>
      <TableContainer className={classes.tableContainer}>
        <Table className={classes.table} size='small' stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell align="center">Subject</TableCell>
              <TableCell align="center">Roc (DataScience)</TableCell>
              <TableCell align="center">Zillow</TableCell>
              <TableCell align="center">Redfin</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, i) => (
              <TableRow key={i}>
                <TableCell variant='head' component="th" scope="row" align="left">
                  <strong>{row.name}</strong>
                </TableCell>
                <TableCell component="th" scope="row" align="center" valign='top'>
                  <Box>{formatCurrency(row.subject)}</Box>
                </TableCell>
                <TableCell component="th" scope="row" align="center">
                  <Box>{formatCurrency(row.dataScience)}</Box>
                  <Box mt={0.5}>
                    <Typography component={'span'} color={'textSecondary'} variant='body2'>
                      Variance:
                    </Typography>
                    {' '}
                    <strong>{formatPercentage(row.dataScienceVariance)}</strong>
                  </Box>
                </TableCell>
                <TableCell component="th" scope="row" align="center">
                  <Box>{formatCurrency(row.zillow)}</Box>
                  <Box mt={0.5}>
                    <Typography component={'span'} color={'textSecondary'} variant='body2'>
                      Variance:
                    </Typography>
                    {' '}
                    <strong>{formatPercentage(row.zillowVariance)}</strong>
                  </Box>
                </TableCell>
                <TableCell component="th" scope="row" align="center">
                  <Box>{formatCurrency(row.redfin)}</Box>
                  <Box mt={0.5}>
                    <Typography component={'span'} color={'textSecondary'} variant='body2'>
                      Variance:
                    </Typography>
                    {' '}
                    <strong>{formatPercentage(row.redfinVariance)}</strong>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
