import { ApiResponse, GlobalStore } from '@roc/feature-app-core';
import { configure, flow, makeObservable, action, observable } from 'mobx';

import { DocumentLibraryService } from '../services/documentLibraryService';
import { downloadDocument, getDocumentAsBlob } from '@roc/feature-utils';
import { DocumentLibraryContentfulStore } from './documentLibraryContentfulStore';
import { CONTENTFUL_KEY } from '../utils/utils';

export class DocumentLibraryStore {
  globalStore: GlobalStore;
  documentLibraryService: DocumentLibraryService;
  contentfulStore: DocumentLibraryContentfulStore;
  bestPractices: any;

  data: any;
  currentData: any;

  constructor(globalStore, environmentStore) {
    this.globalStore = globalStore;
    this.documentLibraryService = new DocumentLibraryService();
    this.contentfulStore = new DocumentLibraryContentfulStore(this.globalStore, environmentStore);
    makeObservable(this, {
      loadData: action,
      setParent: flow,
      setCurrentDataByContentId: action,
      setCurrentData: action,
      downloadDocument: flow,
      downloadDocumentAsBlob: action,
      data: observable,
      currentData: observable,
      getEntryByKey: flow,
      bestPractices: observable
    });
  }

  async loadData(isPreview) {
    if (this.data) return; //simply return if the data is already loaded
    try {
      const response: ApiResponse = await this.documentLibraryService.getDocumentLibraryJson({
        isPreview: isPreview ? true : false
      });
      this.data = response.data.data;
      this.setParent(this.data);
      this.setCurrentData([]);
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error fetching document library json.',
      });
    }
  }

  async loadDataResources(isPreview) {
    if (this.data) return; //simply return if the data is already loaded
    try {
      const response: ApiResponse = await this.documentLibraryService.getDocumentLibraryJsonResources({
        isPreview: isPreview ? true : false
      });

      const data = response.data.data;
      if (this.globalStore.isBrokerPortal) {
        data.items = data.items.filter(item => item.name !== "Videos");
      }
      this.data = data;

      this.setParent(this.data);
      this.setCurrentData([]);
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error fetching document library json.',
      });
    }
  }

  async loadNportalInternalData(isPreview) {
    if (this.data) return; //simply return if the data is already loaded
    try {
      const response: ApiResponse = await this.documentLibraryService.getNportalInternalLibraryJson({
        isPreview: isPreview ? true : false
      });
      this.data = response.data.data;
      this.setParent(this.data);
      this.setCurrentData([]);
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error fetching document library json.',
      });
    }
  }

  setParent(data) {
    if (!data.parent) {
      data.parent = [];
    }
    if (data?.items) {
      let items = data.items as Array<any>;
      for (let i = 0; i < items.length; i++) {
        let _parent: Array<any> = data.items[i].parent ?? [];
        _parent.push(...data.parent);
        if (data.name) {
          _parent.push(data.name);
        }
        data.items[i].parent = _parent;
        this.setParent(data.items[i]);
      }
    }
  }

  setCurrentDataByContentId(contentId) {
    const result = this._findObjectByContentId(this.data, contentId);
    if (result) {
      this.currentData = result;
    } else {
      this.currentData = this.data;
    }
  }

  private _findObjectByContentId(data, contentId: string) {
    for (let i = 0; i < data.items?.length; i++) {
      if (data.items[i].contentId == contentId) {
        return data.items[i];
      }
      else if (data.items[i].folder) {
        const _result = this._findObjectByContentId(data.items[i], contentId);
        if (_result) {
          return _result;
        }
      }
    }
    return undefined;
  }

  setCurrentData(name: Array<any>) {
    if (name.length) {
      name.shift(); // this is to remove "Home" while finding the child object
    }
    if (name.length > 0) {
      const result = this._findObject(this.data, name);
      if (result) {
        this.currentData = result;
      } else {
        this.currentData = this.data;
      }
    }
    else {
      this.currentData = this.data;
    }
  }

  private _findObject(data, name: Array<any>) {
    if (data?.items) {
      const _name = [...name];
      const firstElement = _name.shift();
      let items = data.items as Array<any>;
      for (let i = 0; i < items.length; i++) {
        if (data.items[i].name == firstElement) {
          if (_name.length > 0) {
            return this._findObject(data.items[i], _name);
          }
          else {
            return data.items[i];
          }
        }
      }
    }
    console.error("Error in the _findObject", data, name);
    console.error("Could not find folder named '" + name.shift() + "' in child items.", data.items);
    return undefined;
  }

  *downloadDocument(document: string, nportalInternal: string) {
    try {
      const response: ApiResponse = yield this.documentLibraryService.downloadDocument(
        document
      );
      downloadDocument(response?.data, response?.headers, 'download');
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Downloaded Successfully!'
      });
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while downloading document.',
      });
    }
  }

  async downloadDocumentAsBlob(document: string, nportalInternal: string) {
    try {
      const response: ApiResponse = await this.documentLibraryService.downloadDocument(
        document
      );
      const result = getDocumentAsBlob(response?.data, response?.headers, 'application/pdf');
      return {
        title: result.name,
        blobUrl: URL.createObjectURL(result.blob),
      };
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while downloading document.',
      });
    }
  }

  *getEntryByKey(key: CONTENTFUL_KEY) {
    try {
      const keyResponse: ApiResponse = yield this.documentLibraryService.getEntryByKey(key);
      const response = yield this.contentfulStore.getClient().getEntry(keyResponse.data.data);
      switch (key) {
        case CONTENTFUL_KEY.BEST_PRACTICES: {
          this.bestPractices = response.fields;
        }
      }
    } catch (error) {
      console.log(error);
    }
  }



};

configure({
  enforceActions: 'never',
});
