import { Box } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { observer } from 'mobx-react';
import { LoanTermsCard } from './loanTermsSection/loanTermsCard';
import { BorrowerEntityCard } from './borrowerSection/borrowerEntity/borrowerEntityCard';
import { BorrowersCard } from './borrowerSection/borrower/borrowersCard';
import { LoanSubType } from '@roc/feature-utils';
import { useLoanSummaryStore } from '../hooks/useLoanSummaryStore';
import { borrowerEntityColsBridge, dealEconomicsColsBridge, loanTermColsBridge } from '../utils/ColumnsBridgeLoanSummary';
import { CollateralsCard } from './collateralsSection/collateralsCard';
import { groundUpLoanTermValues } from '../utils/ColumnsGroundUpLoanSummary';


export const LoanDetailsOverviewBridgeLoans = observer(({ loanDetails }) => {

  const { loanSummaryStore } = useLoanSummaryStore();

  const getLoanTermColumns = () => {
    const loanSubtype = loanDetails?.loanSubtype;
    switch (loanSubtype) {
      case LoanSubType.GROUND_UP:
        return loanSummaryStore.transformData(groundUpLoanTermValues, 6, loanDetails);
      case LoanSubType.FIX_AND_FLIP:
      case LoanSubType.FIX_AND_FLIP_PRO:
        return loanSummaryStore.transformData(loanTermColsBridge, 6, loanDetails);
      default:
        return loanSummaryStore.transformData(loanTermColsBridge, 6, loanDetails);
    }
  };

  const loanTermcolumns = getLoanTermColumns();

  return (
    <>
      <Box m={2}>
        <Grid container>
          <Grid item xs={12}>
            <LoanTermsCard loanDetails={loanDetails} columns={loanTermcolumns} title="Loan Terms" />
          </Grid>
        </Grid>
      </Box>
      <Box m={2}>
        <Grid container>
          <Grid item xs={12}>
            <BorrowerEntityCard columns={loanSummaryStore.transformDataForBorrowerEntity(borrowerEntityColsBridge, 3, loanDetails)} />
          </Grid>
        </Grid>
      </Box>
      <Box m={2}>
        <Grid container>
          <Grid item xs={12}>
            <BorrowersCard loanDetails={loanDetails} />
          </Grid>
        </Grid>
      </Box>
      <Box m={2}>
        <Grid container>
          <Grid item xs={12}>
            <CollateralsCard loanDetails={loanDetails} />
          </Grid>
        </Grid>
      </Box>
      <Box m={2}>
        <Grid container>
          <Grid item xs={12}>
            <LoanTermsCard loanDetails={loanDetails} columns={loanSummaryStore.transformData(dealEconomicsColsBridge, 6, loanDetails)} title="Deal Economics" />
          </Grid>
        </Grid>
      </Box>
    </>
  );
});