import { useEffect, useState } from 'react';
import { observer } from "mobx-react";
import { Tabs, VerticalBarLoader } from '@roc/ui';
import { Layout } from '@roc/feature-app-core';
import { General, Security, ContactInformation, Notifications } from '@roc/feature-user-profile';
import { insertIf } from '@roc/client-portal-shared/utils';
import { useLenderPortalStore } from '../../../hooks';
import { PhoneBurnerStore } from 'libs/client-portal-shared/src/app/modules/companyProfile/stores/phoneBurnerStore';



export const LenderProfile = observer(() => {
  const { userProfileStore, globalStore, userStore, ringCentralStore, phoneBurnerStore, linkedInStore } = useLenderPortalStore();
  const [selectedTab, setSelectedTab] = useState(0);
  const [showLoadingMask, setShowLoadingMask] = useState(false);

  useEffect(() => {
    if (selectedTab === 0) {
      userProfileStore.getBasicUserDetails();
    }
  }, [selectedTab, userProfileStore]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (userStore.isMarketingUser) {
          await linkedInStore.getCreds();
        } else {
          await ringCentralStore.getCreds();
        }
      } catch (error) {
        console.error('Error fetching creds:', error);
      }
    };
    fetchData();
  }, []);



  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const code = queryParams.get('code');
    const state = queryParams.get('state');

    if (code) {
      try {
        setShowLoadingMask(true);
        if (state?.includes('LinkedIn')) {
          linkedInStore.handleTokenCallback(code, state);
          globalStore.notificationStore.showSuccessNotification({
            message: 'LinkedIn successfully connected ',
          });
        }
        else if (state) {
          ringCentralStore.handleTokenCallback(code, state);
          globalStore.notificationStore.showSuccessNotification({
            message: 'RingCentral successfully connected ',
          });
        }
        else {
          phoneBurnerStore.handleTokenCallback(code);
          globalStore.notificationStore.showSuccessNotification({
            message: 'PhoneBurner successfully connected ',
          });
        }
        userStore.getUserInfoAndEntitlements();

      } catch (ex) {
        globalStore.notificationStore.showErrorNotification({
          message: 'Authentication Failed',
        });
        console.log(ex);
      } finally {
        setShowLoadingMask(false);
      }
    }
  }, []);

  const onTabChange = (e, index) => setSelectedTab(index);

  const TABS = [
    {
      label: 'GENERAL',
    },
    ...insertIf(userStore.isBackOfficeUser, [
      {
        label: 'NOTIFICATIONS',
      },
    ]),
    {
      label: 'SECURITY',
    },
    ...insertIf(userStore.isBackOfficeUser, [
      {
        label: 'CONTACT INFORMATION',
      },
    ]),
  ];

  const GENERAL_TAB_INDEX = 0;
  const NOTIFICATIONS_TAB_INDEX = userStore.isBackOfficeUser ? 1 : -1;
  const SECURITY_TAB_INDEX = userStore.isBackOfficeUser ? 2 : 1;
  const CONTACT_INFORMATION_TAB_INDEX = userStore.isBackOfficeUser ? 3 : -1;

  return (
    <Layout title="MY PROFILE" maxWidth={'lg'}>
      {showLoadingMask && <VerticalBarLoader />}
      <Tabs
        tabs={TABS}
        selectedTab={selectedTab}
        onTabChange={onTabChange}
      />
      {selectedTab === GENERAL_TAB_INDEX && <General />}
      {selectedTab === NOTIFICATIONS_TAB_INDEX && <Notifications />}
      {selectedTab === SECURITY_TAB_INDEX && <Security />}
      {selectedTab === CONTACT_INFORMATION_TAB_INDEX && <ContactInformation />}
    </Layout>
  );

});
