import { useBaseStore } from '@roc/feature-app-core';
import {
  getBorrowerDrawsRoutes,
  getBorrowerDrawsRoutesConfig,
} from '../routes/borrowerDrawsRoutes';

export const useBorrowerDrawsRoutes = () => {
  const { globalStore } = useBaseStore();
  const { routeBasePath, routeBaseUrl } = globalStore;
  return {
    borrowerDrawsRoutes: getBorrowerDrawsRoutes(routeBasePath, routeBaseUrl),
    borrowerDrawsRoutesConfig: getBorrowerDrawsRoutesConfig(
      routeBasePath,
      routeBaseUrl
    ),
  };
};
