import { makeObservable, observable, flow, action, computed } from 'mobx';
import { GlobalStore } from '@roc/feature-app-core';
import { Resolution } from '../types/resolution';
import { ResolutionService } from '../services/resolutionService';
import { FileUpload } from '../types/resolutionForm';
import { DocumentStore } from '@roc/feature-documents';
import { Document } from '@roc/feature-types';
import { BORROWER_BACKGROUND_REVIEW_TYPE, downloadDocument, TASK_BACKGROUND_TEMPLATE_ID, TASK_TRACK_RECORD_TEMPLATE_ID, TOOLTIP_BACKGROUND_REVIEW_MESSAGE, TOOLTIP_CREDIT_REVIEW_MESSAGE, TOOLTIP_TRACK_RECORD_MESSAGE, TRACK_RECORD_REVIEW_TYPE, UNDERWRITING } from '@roc/feature-utils';
import { isFileAlreadyUploaded } from 'libs/feature-documents/src/documents/utils/documents';

export class ResolutionStore {
  globalStore: GlobalStore;
  resolution: Resolution;
  protected resolutionService: ResolutionService;
  internal: boolean;
  readOnly: boolean;
  documentStore: DocumentStore;
  document: Document;
  tooltTipTitle: string;
  resolutionInfoOpen: boolean;
  documentId: number;

  constructor(globalStore: GlobalStore, documentStore: DocumentStore) {
    this.globalStore = globalStore;
    this.documentStore = documentStore;
    this.resolutionService = new ResolutionService();
    this.internal = false;
    this.readOnly = false;
    this.resolutionInfoOpen = false;
    this.documentId = null;

    makeObservable(this, {
      resolution: observable,
      documentId: observable,
      tooltTipTitle: observable,
      internal: observable,
      readOnly: observable,
      document: observable,
      resolutionInfoOpen: observable,
      getResolutionByDocumentId: flow,
      saveResolution: flow,
      setInternal: action,
      uploadDocument: flow,
      downloadDocument: flow,
      downloadDocumentByPath: flow,
      canDownloadFile: computed,
      canDownloadFileFromInfoScreen: computed,
      fetchDocumentInternal: flow,
      downloadDocumentInternal: flow,
      downloadDocumentByDropboxPath: flow,
      uploadDocumentInternal: flow,
      setResolutionInfoOpen: action,
      handleResolutionInfo: flow,
      setResolution: action,
      updateResolutionStatus: flow,
      setDocumentId: action,
    });
  }

  getToolTipMessageByResolutionObjectType() {
    switch (this.resolution?.objectType) {
      case TRACK_RECORD_REVIEW_TYPE:
        return TOOLTIP_TRACK_RECORD_MESSAGE;
      case BORROWER_BACKGROUND_REVIEW_TYPE:
        return TOOLTIP_BACKGROUND_REVIEW_MESSAGE;
      default:
        return TOOLTIP_CREDIT_REVIEW_MESSAGE;
    }
  }


  getToolTipMessageByTaskTemplateId(task) {
    switch (task?.taskTemplateId) {
      case TASK_TRACK_RECORD_TEMPLATE_ID:
        return TOOLTIP_TRACK_RECORD_MESSAGE;
      case TASK_BACKGROUND_TEMPLATE_ID:
        return TOOLTIP_BACKGROUND_REVIEW_MESSAGE;
      default:
        return TOOLTIP_CREDIT_REVIEW_MESSAGE;
    }
  }

  *getResolutionByDocumentId(documentId: string) {
    try {
      const response = yield this.resolutionService.getResolution(this.internal, documentId);

      if (this.internal) {
        this.resolution = response.data;
        yield this.fetchDocumentInternal();
      } else {
        this.resolution = JSON.parse(response.data.data)
      }
      this.tooltTipTitle = this.getToolTipMessageByResolutionObjectType();
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'There was an error while retrieving resolution details',
      });
    }
  }

  setResolutionComment(comment: string) {
    this.resolution.comment = comment;
  }

  *saveResolution(handleClose?: any) {
    try {
      yield this.resolutionService.saveResolution(this.internal, this.resolution.documentId, this.resolution.comment);
      if (this.internal) {
        window.parent.postMessage('saved-success', '*');
      } else if (handleClose) {
        handleClose();
      } else {
        this.globalStore.notificationStore.showSuccessNotification({
          message: 'Data saved successfully!',
        });
      }
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error saving data',
      });
    }
  }

  *downloadDocument() {
    if (this.internal) {
      this.downloadDocumentInternal();
    } else {
      yield this.documentStore.downloadDocument(this.documentStore.currentDocument);
    }
  }

  *downloadDocumentByPath() {
    this.downloadDocumentByDropboxPath();
  }

  get canDownloadFile() {
    if (this.internal) {
      return isFileAlreadyUploaded(this.document);
    } else {
      return this.documentStore.currentDocument?.canDownloadFile;
    }
  }

  get canDownloadFileFromInfoScreen() {
    return this.resolution && !!this.resolution.dropboxPath;
  }

  *fetchDocumentInternal() {
    const docsResponse = yield this.resolutionService.getLoanDocsInternal(this.resolution.loanId, 'Underwriting');
    const allDocs: Document[] = Object
      .values(docsResponse.data as Record<string, Document[]>)
      .reduce((a, b) => a.concat(b));
    this.document = allDocs.find(doc => doc.loanDocumentId === this.resolution.documentId);
  }

  *downloadDocumentInternal() {
    try {
      const response = yield this.resolutionService.downloadDocumentInternal(this.document);
      downloadDocument(
        response?.data,
        response?.headers,
        'download',
        this.document.originalFileName
      );
    } catch (err) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while downloading document.',
      });
    }
  }

  *downloadDocumentByDropboxPath() {
    try {
      const response = yield this.resolutionService.downloadDocumentByPath(this.resolution.dropboxPath, this.internal);
      downloadDocument(
        response?.data,
        response?.headers,
        'download'
      );
    } catch (err) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while downloading document.',
      });
    }
  }

  *updateResolutionStatus(request: any) {
    try {
      yield this.resolutionService.updateResolutionStatus(request);
    } catch (err) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error updating resolution status.',
      });
    }
  }

  *uploadDocument(fileUpload: FileUpload) {
    if (this.resolution?.objectType == TRACK_RECORD_REVIEW_TYPE) {
      const propertyIdStr = this.resolution?.itemName.match('\\[(.*)\\]'); //getting the trackRecordId which is the number between [ ]
      if (propertyIdStr.length > 1) {
        const propertyId = Number(propertyIdStr[1]);
        const taskPropertyBody = {
          sectionProcess: 'TRACK_RECORD_REVIEW',
          objectType: 'PROPERTY_TRACK_RECORD',
          taskTemplateType: 'PROPERTY_TRACK_RECORD',
          objectId: propertyId,
        };
        const taskResponse = yield this.resolutionService.createTask(taskPropertyBody, this.internal);
        const task = this.internal ? taskResponse.data[0] : taskResponse.data.data[0];
        const blob = fileUpload.file.slice(0, fileUpload.file.size, fileUpload.file.type);
        const newFile = new File([blob], fileUpload.name, { type: fileUpload.file.type });
        const formData = new FormData();
        formData.append('file', newFile);
        formData.append('task', JSON.stringify(task));
        yield this.resolutionService.uploadTaskFile(formData, this.internal);
      }
    }

    if (this.internal) {
      yield this.uploadDocumentInternal(fileUpload);
    } else {
      yield this.documentStore.uploadDocument(fileUpload.file, this.documentStore.currentDocument, UNDERWRITING);
      this.documentStore.documentFormStore.closeCurrentForm();
    }
  }

  *uploadDocumentInternal(fileUpload: FileUpload) {
    if (fileUpload.file) {
      try {
        const blob = fileUpload.file.slice(0, fileUpload.file.size, fileUpload.file.type);
        const newFile = new File([blob], fileUpload.name, { type: fileUpload.file.type });
        const formData = new FormData();
        formData.append('file', newFile);
        yield this.resolutionService.uploadFile(this.internal, this.resolution.documentId, formData);
      } catch {
        this.globalStore.notificationStore.showErrorNotification({
          message: 'Error uploading file',
        });
      }
    }
  }

  setInternal(isInternal: boolean) {
    this.internal = isInternal;
  }

  setReadOnly(readOnly: boolean) {
    this.readOnly = readOnly;
  }

  setResolutionInfoOpen(value: boolean) {
    this.resolutionInfoOpen = value;
  }

  *handleResolutionInfo(data: any) {
    try {
      this.setResolutionInfoOpen(true);
      const response = yield this.resolutionService.getResolutionInfoById(this.internal, data.letterOfExplanation.resolutionId);
      if (this.internal) {
        this.setResolution(response.data);
      } else {
        this.setResolution(JSON.parse(response.data.data));
      }
    } catch {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error getting resolution info.',
      });
    }
  }

  setResolution(value) {
    this.resolution = value;
  }

  setDocumentId(value) {
    this.documentId = value;
  }
};
