import { Service } from "@roc/feature-app-core";

const url = {
  UPDATE_LOAN_SUBMISSION: '/api/v1/loan/updateLoanSubmission',
};

export class EditLoanSubmissionService extends Service {

  updateLoanSubmission(submitLoanRequest: any, loanId: number) {
    return this.post(`${url.UPDATE_LOAN_SUBMISSION}?loanId=${loanId}`, submitLoanRequest);
  }

}
