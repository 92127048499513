import {
  Box,

  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { darken } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { SubdirectoryArrowRight } from '@material-ui/icons';
import { useLoanStore } from '@roc/feature-loans-shared';
import { useLoanRoutes } from '@roc/feature-loans-routes-config';
import { isFundedOrAgreedLoan, isNil, sanitizeTestId } from '@roc/feature-utils';
import {
  BooleanChip,
  Button,
  Card,
  Paper,
  PropertyMap,
  StatusChip,
  StatusType,
  TestDataAttribute,
} from '@roc/ui';
import { formatCurrency } from '@roc/ui/utils';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router';
import { useUserStore } from '@roc/feature-app-core';

const useStyles = makeStyles(theme => ({
  paper: {
    marginBottom: theme.spacing(2),
  },
  card: {
    background: theme.palette.background.default,
    borderColor: darken(theme.palette.background.default, 0.1),
  },
}));

const listItem = (label, value, icon = null, customTestId = null) => {
  const testId = sanitizeTestId(customTestId || label);
  return (
    <ListItem>
      <ListItemText>
        <Box display="flex">
          {icon && <Box ml={1}>{icon}</Box>}
          <Box flexGrow={1}>{label}</Box>
          <TestDataAttribute id={testId}>
            <Box>{value}</Box>
          </TestDataAttribute>
        </Box>
      </ListItemText>
    </ListItem>
  );
};

const divider = <Divider variant="middle" component="li" />;

export const BridgeLoanTermsSection = ({ loanData }) => {
  const classes = useStyles();
  const { userStore } = useUserStore();
  const {
    amount,
    initialLoanAmount,
    constructionHoldback,
    rate,
    status,
    loanType,
    loanSubType,
    attorneyName,
    aggregateLoanToCurrentCostInitial,
    constructionHoldbackToRenovationBudgetPercent,
    minNumber,
    aggregateLoanToFutureCost,
    fullPrincipalInterest,
    pointsIn,
    interestReserve,
    brokerPointsIn,
    prepayPenaltyMonths,
    fullPrincipalInterestTime,
    servicer,
    freeAppraisalPromotion,
  } = loanData;

  const isFundedOrPaidoffOrAgreedLoan = isFundedOrAgreedLoan(status);

  const allowLoanPricing = userStore.allowLoanPricing;
  return (
    <Paper title="LOAN TERMS" className={classes.paper}>
      <Box>
        <List>
          {listItem('Loan Type', loanType)}
          {divider}
          {listItem('Loan SubType', loanSubType)}
          {divider}
          {listItem(
            'Loan Status',
            <StatusChip
              statusType={StatusType.LOAN}
              label={status}
              variant="filled"
            />
          )}
          {divider}
          {listItem('Loan Amount', formatCurrency(amount))}
          {listItem(
            'Initial Loan Amount',
            <Typography variant="body2" color="textSecondary" component="p">
              {formatCurrency(initialLoanAmount)}
            </Typography>,
            <Box flexGrow={1}>
              <SubdirectoryArrowRight fontSize="small" />
            </Box>
          )}
          {listItem(
            'Construction Holdback',
            <Typography variant="body2" color="textSecondary" component="p">
              {formatCurrency(constructionHoldback)}
            </Typography>,
            <Box flexGrow={1}>
              <SubdirectoryArrowRight fontSize="small" />
            </Box>
          )}
          {divider}
          {listItem('Initial LTC', `${aggregateLoanToCurrentCostInitial}%`)}
          {divider}
          {listItem(
            'Construction Holdback Rate',
            `${constructionHoldbackToRenovationBudgetPercent}%`
          )}
          {divider}
          {listItem('Attorney', attorneyName)}
          {divider}
          {isFundedOrPaidoffOrAgreedLoan && listItem('Servicer', servicer)}
          {isFundedOrPaidoffOrAgreedLoan && divider}
          {listItem('Total LTFC', `${aggregateLoanToFutureCost}%`)}
          {divider}
          {listItem('MERS MIN Number', minNumber)}
          {divider}
          {listItem('Free Appraisal Promotion',
            !isNil(freeAppraisalPromotion) && (
              <BooleanChip value={freeAppraisalPromotion} />
            ))}
          {divider}
          {listItem(
            'Full Princ Interest',
            <BooleanChip value={fullPrincipalInterest} />
          )}
          {divider}
          {listItem('Interest Rate', `${rate}%`)}
          {divider}
          {listItem('FPI Time', fullPrincipalInterestTime)}
          {divider}
          {listItem('Points', pointsIn)}
          {allowLoanPricing && divider}
          {allowLoanPricing &&
            listItem('Broker Points', brokerPointsIn)}
          {divider}
          {listItem('Interest Reserve', interestReserve)}
          {divider}
          {listItem('Pre Penalty (Months)', prepayPenaltyMonths)}
        </List>
      </Box>
    </Paper>
  );
};

export const BridgeTermsSection = ({ loanData }) => {
  const classes = useStyles();
  const { userStore } = useUserStore();
  const {
    rocRate,
    rocPointsIn,
    rocPointsOut,
    origLenderSubordination,
    buybackSkimReduction,
    sellAs,
    fundingPreference,
    pledgeOfShares,
  } = loanData;
  const allowLoanPricing = userStore.allowLoanPricing;
  return (
    <Paper title="TERMS" className={classes.paper}>
      <Box>
        <List>
          {allowLoanPricing &&
            listItem('Capital Provider Int Rate', `${rocRate}%`)
          }
          {allowLoanPricing && divider}
          {allowLoanPricing &&
            listItem(
              'Capital Provider Points (I|O)',
              rocPointsIn || rocPointsOut
                ? `${rocPointsIn ?? ''} | ${rocPointsOut ?? ''}`
                : null
            )}
          {allowLoanPricing && divider}
          {listItem('Subordination', `${origLenderSubordination}%`)}
          {allowLoanPricing && divider}
          {allowLoanPricing &&
            listItem('Buy. Skim Red.', buybackSkimReduction)
          }
          {allowLoanPricing && divider}
          {allowLoanPricing &&
            listItem('Participation', sellAs)
          }
          {divider}
          {listItem('Funding Entity', fundingPreference)}
          {divider}
          {listItem('Pledge Of Shares', <BooleanChip value={pledgeOfShares} />)}
        </List>
      </Box>
    </Paper>
  );
};

export const BridgeKeyDatesSection = ({ loanData }) => {
  const classes = useStyles();
  const {
    submissionDate,
    expectedClosingDate,
    agreedDate,
    closingDate,
    payoffDate,
    maturityDate,
  } = loanData;

  return (
    <Paper title="KEY DATES" className={classes.paper}>
      <Box>
        <List>
          {listItem('Submission', submissionDate)}
          {divider}
          {listItem('Exp. Closing', expectedClosingDate)}
          {divider}
          {listItem('Agreed Date', agreedDate)}
          {divider}
          {listItem('Closing Date', closingDate)}
          {divider}
          {listItem('PayOff Date', payoffDate)}
          {divider}
          {listItem('Maturity', maturityDate)}
        </List>
      </Box>
    </Paper>
  );
};

export const BridgeInsuranceSection = ({ loanData }) => {
  const classes = useStyles();
  const { replacementCost, source, calculatedLoanData } = loanData;

  return (
    <Paper title="INSURANCE" className={classes.paper}>
      <Box>
        <List>
          {listItem('Replacement Cost', replacementCost)}
          {divider}
          {listItem('Source', source)}
          {divider}
          {listItem(
            'Is there a property in a high hazard flood zone?',
            <BooleanChip value={calculatedLoanData.floodZone === 'Yes' ? true : false} />
          )}
        </List>
      </Box>
    </Paper>
  );
};

export const BridgePropertiesSection = observer(({ loanData }) => {
  const classes = useStyles();
  const { loanId, properties } = loanData;

  const { loanRoutesConfig } = useLoanRoutes();
  const { loanStore } = useLoanStore();
  const { push } = useHistory();

  return (
    <Paper title="PROPERTIES" className={classes.paper}>
      <List>
        <ListItem>
          <Grid container direction="row" spacing={2}>
            {properties?.map((property, i) => {
              return (
                <Grid item xs={12} md={6} lg={4} key={property.address}>
                  <TestDataAttribute id="property">
                    <Card
                      color="secondary"
                      title={property.address}
                      value={property}
                      className={classes.card}
                    >
                      <PropertyMap
                        latitude={property.latitude}
                        longitude={property.longitude}
                        address={property.address}
                      />
                    </Card>
                  </TestDataAttribute>
                </Grid>
              );
            })}
          </Grid>
        </ListItem>
        <Box mr={2} mb={2} display="flex" justifyContent="flex-end">
          <Button
            size="small"
            variant="contained"
            color="secondary"
            onClick={() =>
              push(
                loanRoutesConfig.loans(loanId.toString()).children.properties
                  .url
              )
            }
            testId="morePropertiesInformation"
          >
            More information
          </Button>
        </Box>
      </List>
    </Paper>
  );
});
