import { Layout } from '@roc/feature-app-core';
import { observer } from 'mobx-react';
import { useBackOfficeLoansStore } from '../../hooks/useBackOfficeLoansStore';
import { MyLoansBackOfficeGridByContact } from '../backOfficeGrid/myLoansBackOfficeGridByContact';

export const BackOfficeLoansByContact = observer(({ lenderId = null, borrowerId = null }) => {
  const { backOfficeLoansStore } = useBackOfficeLoansStore();

  if (lenderId != null) {
    backOfficeLoansStore.lenderIdForLoanVolumeDashboard = lenderId;
  }
  if (borrowerId != null) {
    backOfficeLoansStore.borrowerForLoanVolumeDashboard = borrowerId;
  }

  return (
    <MyLoansBackOfficeGridByContact store={backOfficeLoansStore.pipelineBackOfficeLoansByContactId} />
  );
});