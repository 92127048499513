import { makeStyles } from '@material-ui/core/styles';
import { statusProperties } from '@roc/feature-utils';
import {
  DataGrid,
  GridColumnProps,
  IconFilterButton,
  LinkColumn,
  SelectFilterComponent,
  SelectFloatingFilterComponent,
  StatusChip,
  StatusType,
  Toolbar,
} from '@roc/ui';
import { formatCurrency } from '@roc/ui/utils';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useBorrowerLoansRoutes } from '../../hooks/useBorrowerLoansRoutes';
import { useBorrowerLoansStore } from '../../hooks/useBorrowerLoansStore';

const usdAmount: any = params => {
  return params.value && formatCurrency(Number(params.value));
};

const statusOptions = Object.keys(statusProperties).map(key => ({
  label: statusProperties[key].name,
  value: key,
}));

const filter = {
  filter: 'agTextColumnFilter',
  floatingFilter: true,
  floatingFilterComponentParams: {
    suppressFilterButton: true,
  },
};

const dateFilter = {
  filter: 'agDateColumnFilter',
  floatingFilter: true,
  floatingFilterComponentParams: {
    suppressFilterButton: true,
  },
};

const columns: GridColumnProps[] = [
  {
    field: 'dealid',
    headerName: 'Loan Id',
    maxWidth: 120,
    cellRenderer: 'loanIdCellRenderer',
    ...filter,
  },
  {
    field: 'dealName',
    headerName: 'Deal Name',
    minWidth: 350,
    cellRenderer: 'dealNameCellRenderer',
    ...filter,
  },
  {
    field: 'status',
    headerName: 'Status',
    minWidth: 120,
    cellRenderer: 'statusCellRenderer',
    filter: 'selectFilter',
    floatingFilter: true,
    floatingFilterComponent: 'selectFloatingFilter',
    floatingFilterComponentParams: {
      suppressFilterButton: true,
      options: statusOptions,
    },
  },
  {
    field: 'loanSubType',
    headerName: 'Loan Type',
    cellRenderer: 'loanSubTypeCellRenderer',
    minWidth: 300,
    ...filter,
  },
  {
    field: 'amount',
    headerName: 'Amount',
    minWidth: 130,
    valueFormatter: usdAmount,
  },
  {
    field: 'submissionDate',
    headerName: 'Submission Date',
    minWidth: 185,
    filter: 'agDateColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'closingDate',
    headerName: 'Closing Date',
    minWidth: 185,
    ...dateFilter,
  },
];

const useStyles = makeStyles(theme => ({
  paginationBar: {
    border: 'none',
    borderTop: '1px solid #eee',
    padding: 8,
    background: '#fafafa',
  },
}));

export const BorrowerLoansDashboard = observer(() => {
  const { borrowerLoansStore } = useBorrowerLoansStore();
  const { borrowerLoansGridStore } = borrowerLoansStore;
  const gridStore = borrowerLoansGridStore;
  const { borrowerLoanRoutesConfig } = useBorrowerLoansRoutes();
  const classes = useStyles();

  useEffect(() => {
    borrowerLoansGridStore.resetAndFetchGridData();
  }, []);

  const loanSubTypeCellRenderer = params => {
    const loanSubType = params.node?.data?.loanSubType;
    return (
      <>
        <span>{loanSubType}</span>
      </>
    );
  };

  const loanDetailsLinkCellRenderer = params => {
    const loanId = params.node?.data?.dealid;
    const url = borrowerLoanRoutesConfig.loans(loanId).children.dashboard.url;
    return LinkColumn({ ...params, url });
  };

  const frameworkComponents = {
    selectFilter: SelectFilterComponent,
    selectFloatingFilter: SelectFloatingFilterComponent,
    loanIdCellRenderer: loanDetailsLinkCellRenderer,
    dealNameCellRenderer: loanDetailsLinkCellRenderer,
    loanSubTypeCellRenderer: loanSubTypeCellRenderer,
    statusCellRenderer: ({ value }) =>
      value ? (
        <StatusChip statusType={StatusType.LOAN} label={value} size="small" />
      ) : null,
  };

  const toolbar = (
    <Toolbar
      toolbarContainerStyle={{
        border: 0,
        padding: 0,
      }}
    >
      <IconFilterButton
        onToggleFilters={() => borrowerLoansGridStore.toggleFilters()}
        onClearFilters={() => borrowerLoansGridStore.resetFilters()}
        showFilters={borrowerLoansGridStore.showFilters}
      />
    </Toolbar>
  );

  const onFilterChanged = params => {
    const filterModel = params.api.getFilterModel();
    gridStore.setFilterModel(filterModel);
  };

  const onSortChanged = params => {
    const sortModel = params.api.getSortModel();
    gridStore.setSortModel(sortModel);
  };

  return (
    <DataGrid
      columns={columns}
      rows={gridStore.gridData.data.rows}
      frameworkComponents={frameworkComponents}
      toolbar={toolbar}
      onSortChanged={onSortChanged}
      onFilterChanged={onFilterChanged}
      isLoading={gridStore.isLoading}
      showFilters={gridStore.showFilters}
      sortModel={gridStore.sortModel}
      filterModel={gridStore.filterModel}
      paginationData={gridStore.gridData.meta}
      paginationBarClassName={classes.paginationBar}
      setPageNumber={gridStore.setPageNumber}
      domLayout="autoHeight"
    />
  );
});
