import { Box, Grid, Paper, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Layout, LoginLogoImg } from '@roc/feature-app-core';
import { StepsContainer } from '@roc/ui';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useACHFormStore } from '../hooks/useACHStore';
import { ACHFormSteps } from '../utils/constants';
import { BankInformation } from './bankInformation';
import { DocusignACHIframe } from './docusignACHIframe';
import { SummaryStep } from './summaryStep';
import { Welcome } from './welcome';
import { useParams } from 'react-router';

const useStyles = makeStyles(theme =>
  createStyles({
    stepper: {
      [theme.breakpoints.down('xs')]: {
        paddingLeft: theme.spacing(0.5),
        paddingRight: theme.spacing(0.5),
      },
      maxWidth: 1000,
      margin: 'auto',
      '& .MuiStepLabel-completed': {
        color: theme.palette.primary.main,
      },
    },
    box: {
      [theme.breakpoints.up('xs')]: {
        width: 930,
      },
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        justifyContent: 'center',
      },
    },
    paper: {
      marginTop: theme.spacing(2),
      padding: theme.spacing(2)
    }
  })
);

export const ACHFormPage = observer(() => {
  const [encodedInput, setEncodedInput] = useState<string>();

  useEffect(() => {
    setEncodedInput(window.location.href.substring(window.location.href.lastIndexOf('/') + 1));
  }, []);

  return (
    <Layout maxWidth={'lg'}>
      <Box style={{ display: 'flex', justifyContent: 'center' }}>
        <LoginLogoImg imgStyle={{ width: '150px' }} />
      </Box>
      {!!encodedInput && <ACHFormPageComponent encodedInput={encodedInput} />}
    </Layout>
  );
});

export const ACHFormPageComponent = observer(({
  encodedInput,
  showTitle = true
}: {
  encodedInput: string;
  showTitle?: boolean;
}) => {
  const [showWelcome, setShowWelcome] = useState(true);
  const [showThankYou, setShowThankYou] = useState(false);
  const { achFormStore } = useACHFormStore();
  const { achData } = achFormStore;

  useEffect(() => {
    if (achData) {
      setShowWelcome(achData.verificationStep !== ACHFormSteps.SIGNED);
      // setShowThankYou(achData.verificationStep === ACHFormSteps.SIGNED);
    }
  }, [achData]);

  useEffect(() => {
    achFormStore.fetchACHData(encodedInput);
  }, []);

  const classes = useStyles();

  const steps = [
    {
      title: 'Bank Information',
      icon: null,
      completed: achFormStore.activeStep > 0,
    },
    {
      title: 'DocuSign Confirmation',
      icon: null,
      completed: achFormStore.activeStep > 1,
    },
    {
      title: 'Summary',
      icon: null,
      allowBack: true,
    },
  ];

  return (
    <>
      {showWelcome ? (
        <Welcome
          onStart={() => setShowWelcome(false)}
        />
      ) : showThankYou ? (
        <></>
      ) : achData ? (
        <>
          {
            showTitle &&
            <Box display={'flex'} justifyContent={'center'}>
              <Typography variant="h3">ACH Consent</Typography>
            </Box>
          }
          {achFormStore.achData && (
            <Paper className={classes.paper}>
              <StepsContainer
                disableNavigation={true}
                activeStep={achFormStore.activeStep}
                handleStep={step => achFormStore.moveToStep(step)}
                steps={steps}
                stepperClassName={classes.stepper}
              >
                <BankInformation />
                <DocusignACHIframe />
                <SummaryStep />
              </StepsContainer>
            </Paper>
          )}
        </>
      ) : null}
    </>
  );
});
