import {
  Box,

  Paper,

  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import React, { useEffect, useRef } from 'react';
import { Layout } from '@roc/feature-app-core';
import { ScopeOfWorkV2Stepper } from '../../scopeOfWorkV2/scopeOfWorkV2Stepper';
import ScopeOfWorkV2Header from './components/scopeOfWorkV2Header';
import { ScopeOfWorkV2FormBaseStore } from '@roc/feature-sow-shared/stores/v2';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(4),
    },
  },
}));

export type ScopeOfWorkV2FormProps = {
  store: ScopeOfWorkV2FormBaseStore;
};

export const ScopeOfWorkV2Form = observer((props: ScopeOfWorkV2FormProps) => {
  const { store } = props;
  const classes = useStyles();
  const screenshotContainerRef = useRef(null);

  return (
    <>
      <Layout>
        <ScopeOfWorkV2Header
          store={store}
          screenshotContainerRef={screenshotContainerRef}
        />
        <Paper className={classes.paper}>
          <ScopeOfWorkV2Stepper
            store={store}
            screenshotContainerRef={screenshotContainerRef}
          />
        </Paper>
      </Layout>
    </>
  );
});

export default ScopeOfWorkV2Form;
