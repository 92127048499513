import { Service } from '@roc/client-portal-shared/services';

const url = {
  FLOOR_RATE: '/api/v1/pricer/bridgePricer/getRateFloorBySubmissionDateWithDataResults',
  PRESCREENING_VALIDATIONS: '/api/v1/validation/validatePrescreenBridgeLoan',
  GET_BORROWER_POINTS_OPTIONS: '/api/v1/pricer/bridgePricer/getBorrowerPointsValues',
}

export class FixFlipGroundUpService extends Service {
  getRateFloor(data) {
    return this.post(url.FLOOR_RATE, data);
  }

  runPrescreeningValidations(data) {
    return this.post(url.PRESCREENING_VALIDATIONS, data);
  }

  calculateBorrowerPointsOptions(lenderId, loanTerm, submissionDate) {
    return this.get(url.GET_BORROWER_POINTS_OPTIONS, { lenderId, loanTerm, submissionDate });
  }
}