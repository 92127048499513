import { Box, Grid, Typography, Button, Link } from '@material-ui/core';
import { FileUpload, TextField, FileUploadArea, FieldLabel } from '@roc/ui';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useLoanClosingConditionStore } from '../../../hooks/useLoanClosingConditionStore';
import { CLOSING } from '@roc/feature-utils';
import { RadioField } from '@roc/ui';
import { trueFalseOptions } from '@roc/ui';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: '3px',
    minWidth: '100px'
  }
}));

export const LoanClosingCondition = observer(
  ({ handleClose, loanDocumentId, closingConditionAdmin, closingConditionCommittee, internal }) => {
    const { loanClosingConditionStore } = useLoanClosingConditionStore();
    const classes = useStyles();
    const [uploads, setUploads] = useState<FileUpload[]>([]);

    const handleCloseButton = () => {
      if (loanClosingConditionStore.internal) {
        window.parent.postMessage('close-closing-condition-modal', '*');
      } else {
        handleClose();
      }
    }

    const onSave = async (action?: string) => {
      await loanClosingConditionStore.saveLoanClosingCondition(handleCloseAfterSave, action);
      handleCloseAfterSave();
      await loanClosingConditionStore.documentStore.fetchProcessDocuments(loanClosingConditionStore.closingConditionData?.loanId, CLOSING)
    };

    useEffect(() => {
      loanClosingConditionStore.setInternal(internal);
      if (loanDocumentId) {
        loanClosingConditionStore.setConditionNotes('');
        loanClosingConditionStore.fetchClosingConditionByDocumentId(loanDocumentId);
      }
    }, [loanDocumentId]);

    useEffect(() => {
      if (uploads[0]) {
        loanClosingConditionStore.uploadDocuments(uploads, loanDocumentId, handleCloseAfterSave);
      }
    }, [uploads]);


    const handleCloseAfterSave = () => {
      if (loanClosingConditionStore.internal) {
        window.parent.postMessage('close-closing-condition-modal-save', '*');
      } else {
        handleClose();
      }
    }

    const handleLevelChangeInternal = () => {
      window.parent.postMessage('closing-condition-level-change', '*');
    }

    const handleConditionNotesChange = (e) => {
      loanClosingConditionStore.setConditionNotes(e.target.value);
    };

    return (
      <Grid style={{ background: 'white', width: '100%' }}>
        <Grid item xs={12}>
          <Typography variant='h4'>{`Closing Condition - ${loanClosingConditionStore.closingConditionData?.conditionName}`}</Typography>
          <Typography
            variant='subtitle1'
            style={{ fontWeight: 'bold' }}>
            <div dangerouslySetInnerHTML={{ __html: loanClosingConditionStore.closingConditionData?.conditionDescription }} />
          </Typography>
        </Grid>
        {closingConditionCommittee == 'true' &&
          <Grid item xs={12} style={{ paddingTop: '20px' }}>
            <RadioField
              standaloneLabel
              label={'Is Committee Clearance Required?'}
              value={loanClosingConditionStore.committeeClearance}
              name={'clearance'}
              options={trueFalseOptions}
              onChange={value => {
                loanClosingConditionStore.changeClosingConditionLevel(value == 'true', handleLevelChangeInternal);
              }}
              required
              row
              testId="clearance"
            />
          </Grid>}
        <Grid item xs={12}>
          <Box display="flex" alignItems="center" >
            <FieldLabel>
              {loanClosingConditionStore.internal ? 'Please add a note to the LoanChat conversation below' : 'Notes'}
            </FieldLabel>
          </Box>
          <TextField
            type="text-area"
            variant="outlined"
            label=""
            name='conditionNotes'
            value={loanClosingConditionStore?.conditionNotes}
            onChange={handleConditionNotesChange}
            fullWidth
            multiline={true}
            minRows={4}
            margin="normal"
            testId="conditionNotes"
            style={{ marginTop: '6px' }}
            disabled={!(closingConditionAdmin == 'true' || closingConditionCommittee == 'true')}
          />
        </Grid>
        {!loanClosingConditionStore.internal && loanClosingConditionStore.closingConversationUrl && <Grid item xs={12}>
          <Box display="flex">
            <Typography style={{ fontWeight: 'bold' }}>Please contact the closing team if you have any questions</Typography>
            <Link href={loanClosingConditionStore.closingConversationUrl} style={{ fontWeight: 'bold' }}>{`\xa0here`}</Link>
          </Box>
        </Grid>}
        <Grid item xs={12} style={{ marginTop: '12px' }}>
          <Box display="flex" alignItems="center" >
            <FieldLabel>
              Please upload the updated document(s) needed to finalize the closing process
            </FieldLabel>
          </Box>
          <FileUploadArea
            items={uploads}
            setItems={(items) => setUploads(items)} multiple />
        </Grid>
        <Box display={'flex'} flexDirection={'column'}>
          {loanClosingConditionStore.internal && <Box display={'flex'} justifyContent={'flex-end'}>
            {loanClosingConditionStore.closingConditionData?.canBeRemoved &&
              <Button
                onClick={() => onSave('clear')}
                variant="contained"
                color="secondary"
                className={classes.button}>
                Mark as Invalid
              </Button>}
            {(closingConditionAdmin == 'true' || closingConditionCommittee == 'true') &&
              <Button
                onClick={() => onSave('reject')}
                variant="contained"
                color="secondary"
                className={classes.button}
                disabled={!loanClosingConditionStore?.conditionNotes}>
                Reject
              </Button>}
            {((closingConditionAdmin == 'true' && loanClosingConditionStore.closingConditionData?.level == 'Level 1') || loanClosingConditionStore.closingConditionData?.canBeRemoved) &&
              <Button
                onClick={() => onSave('approve')}
                variant="contained"
                color="primary"
                className={classes.button}
                disabled={loanClosingConditionStore.documentStatus}>
                Approve
              </Button>}
          </Box>}
          <Box display={'flex'} justifyContent={'flex-end'} alignItems={'flex-end'} paddingTop={'25px'}>
            <Button
              onClick={() => handleCloseButton()}
              variant="outlined"
              color="primary"
              className={classes.button}>
              Close
            </Button>
          </Box>
        </Box>
      </Grid>
    );
  }
);

export default LoanClosingCondition;
