import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { observer } from "mobx-react";
import { Layout } from "@roc/feature-app-core";
import { useEffect, useState, useRef } from 'react';
import { Box, Grid, Paper, Typography, IconButton, Menu, MenuItem } from '@material-ui/core';
import { Button, RadioField, TextField, SelectField } from '@roc/ui';
import { useCreateListStore } from '../createList/hooks/useCreateListStore';
import { useStore } from '@roc/client-portal-shared/hooks';
import { MAX_FILE_SIZE, templatePlaceHolders } from 'libs/feature-communication/src/communication/utils/constants';
import RichTextWithAttachments from 'libs/feature-backoffice-users/src/backOfficeUsers/utils/richTextWithAttachments';
import { Code } from '@material-ui/icons';
import { HtmlContainer } from '@roc/ui';
import { imageInsertionOptions } from '@roc/feature-utils';
import { FileUploadArea } from '@roc/ui';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(3, 0, 4),
      borderRadius: theme.spacing(2),
      boxShadow:
        'rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px',
      display: 'flex',
      justifyContent: 'center',
    },
    container: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%'
    },
    modalContainer: {
      position: 'relative'
    },
    title: {
      display: 'flex',
      justifyContent: 'flex-start',
    },
    buttonContainer: {
      paddingTop: theme.spacing(1),
      margin: theme.spacing(1, 0, 1),
    },
    contentContainer: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(2),
      maxWidth: '1000px',
      [theme.breakpoints.down('xs')]: {
        maxWidth: '400px',
      },
    },
    attachmentButtonContainer: {
      display: 'flex',
      flexDirection: 'row-reverse',
      height: '30px'
    },
    textMessage: {
      borderRadius: 15,
      borderTopLeftRadius: 0,
      backgroundColor: '#90EE90',
      display: 'inline-block',
      padding: '10px'
    },
    previewBox: {
      [theme.breakpoints.up('sm')]: {
        width: '100%'
      },
      [theme.breakpoints.up('lg')]: {
        width: '75%',
      },
      paddingBottom: '20px'
    },
    divider: {
      position: 'absolute',
      border: '1px solid grey',
      height: '95%',
      [theme.breakpoints.up('sm')]: {
        left: '50%',
      },
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    }
  })
);

const currentPath = window.location.pathname;


export const CreateTemplateForm = observer(({ handleClose = () => { } }) => {
  const classes = useStyles();
  const { createListStore } = useCreateListStore();
  const { createListFormStore } = createListStore;
  const { ringCentralStore, salesforceLeadStore } = useStore();
  const { sendEmailsStore } = salesforceLeadStore;
  const [attachments, setAttachments] = useState<File[]>([]);
  const [mockAttachments, setMockAttachments] = useState<any[]>([]);
  const [placeHolderMenu, setPlaceHolderMenu] = useState(false);
  const [anchorPlaceHolderElement, setAnchorPlaceHolderElement] = useState<null | HTMLElement>(null);
  const [insertions, setInsertions] = useState<any[]>([]);

  const handleMenuOpen = (event) => {
    setAnchorPlaceHolderElement(event.currentTarget);
    setPlaceHolderMenu(true);
  }

  const { globalStore } = useStore();

  function handleSaveClick() {
    if (!ringCentralStore.label || !ringCentralStore.templateValue.replace('<p>', '').replace('</p>', '') || !ringCentralStore.templateType || (ringCentralStore.templateType == 'email' && !ringCentralStore.templateSubject)) {
      globalStore.notificationStore.showErrorNotification({
        message: 'All fields are required',
      });
    } else if (duplicateAttachmentName()) {
      globalStore.notificationStore.showErrorNotification({
        message: 'Duplicate attachment file names',
      });
    } else if ((ringCentralStore.templateInsertions.length != 1 && ringCentralStore.imageInsertionOption == 'oneImage') ||
      (ringCentralStore.templateInsertions.length != 2 && ringCentralStore.imageInsertionOption == 'twoImagesSideBySide')) {
      globalStore.notificationStore.showErrorNotification({
        message: 'Add the required images to the template',
      });
    } else {
      ringCentralStore.insertTemplate(attachments);
      handleClose();
    }
  }

  const duplicateAttachmentName = () => {
    const fileNames = [...attachments.map(attachment => attachment.name), ...mockAttachments.map(mockAttachment => mockAttachment.name)];
    return new Set(fileNames).size != fileNames.length;
  }

  function handleUpdateClick() {
    if (!ringCentralStore.label || !ringCentralStore.templateValue.replace('<p>', '').replace('</p>', '') || !ringCentralStore.templateType || (ringCentralStore.templateType == 'email' && !ringCentralStore.templateSubject)) {
      globalStore.notificationStore.showErrorNotification({
        message: 'All fields are required',
      });
    } else if (duplicateAttachmentName()) {
      globalStore.notificationStore.showErrorNotification({
        message: 'Duplicate attachment file names',
      });
    } else if (((ringCentralStore.templateInsertions.length != 1 && ringCentralStore.imageInsertionOption == 'oneImage') ||
      (ringCentralStore.templateInsertions.length != 2 && ringCentralStore.imageInsertionOption == 'twoImagesSideBySide')) && !ringCentralStore.templateId) {
      globalStore.notificationStore.showErrorNotification({
        message: 'Add the required images to the template',
      });
    } else {
      ringCentralStore.updateTemplate(attachments);
      handleClose();
    }
  }

  function handleDeleteClick() {
    ringCentralStore.deleteTemplate(ringCentralStore.templateId);
    handleClose();
  }

  const onFilesChange = (event): void => {
    const { files } = event.target as HTMLInputElement;
    if (files?.length) {
      addFiles(Array.from(files));
    }
  };

  const addFiles = (newFiles: File[]) => {
    const validFiles = newFiles.filter(
      ({ size }) => size < MAX_FILE_SIZE + 1
    );
    setAttachments(currentFiles => [...currentFiles, ...validFiles]);
  }

  useEffect(() => {
    setMockAttachments(ringCentralStore.templateAttachments);
    sendEmailsStore.setSelectedTemplateOption({ imageInsertions: ringCentralStore.mockTemplateInsertions });
    ringCentralStore.setImageOption();
    currentPath.includes("/backoffice") && createListFormStore.onFieldChange('objectType', 'Lead');
    createListStore.getFilterCriteriaOptions();
  }, []);

  useEffect(() => {
    ringCentralStore.templateAttachments = mockAttachments;
  }, [mockAttachments]);

  useEffect(() => {
    ringCentralStore.templateInsertions = insertions;
  }, [insertions]);

  useEffect(() => {
    if (insertions.length > 1 && ringCentralStore.imageInsertionOption == 'oneImage') {
      setInsertions(prev => prev.slice(0, -1));
    }

    if (ringCentralStore.imageInsertionOption != 'noImage') {
      ringCentralStore.bombBombVideoLink = null;
    }

    if (ringCentralStore.imageInsertionOption == 'noImage') {
      setInsertions([]);
    }

  }, [ringCentralStore.imageInsertionOption]);

  const onRichTextValueChange = (value: string) => {
    ringCentralStore.setTemplateValue(value);
  }

  const templateOptions = [
    {
      label: 'Text',
      value: 'text',
    },
    {
      label: 'Email',
      value: 'email',
    },
  ];

  useEffect(() => {
    createListStore.setFilterCriteriaOptions(createListStore.createListFormStore.form.fields.objectType.value)
  }, [createListStore.createListFormStore.form.fields.objectType.value, createListStore.filterCriteriaOptions]);

  return (
    <Grid item style={{ flex: 1 }} spacing={2} xs={12} md={6}>
      <Grid item container className={classes.title}>
        <Grid item>
          <Typography variant="h6">Template Name</Typography>
        </Grid>
        <Grid item container className={classes.contentContainer}>
          <Grid item xs={11}>
            <TextField
              testId='label'
              variant="outlined"
              standaloneLabel
              inputProps={{ maxLength: 1000 }}
              value={ringCentralStore.label}
              onChange={value =>
                ringCentralStore.setLabel(value.target.value)
              }
              fullWidth
              minRows={1}
              maxRows={1}
            //containerStyle={styles.field}
            />
          </Grid>
        </Grid>
        <Grid item>
          <Typography variant="h6">Template Type</Typography>
        </Grid>
        <Grid item container className={classes.contentContainer}>
          <Grid item xs={11}>
            <RadioField
              testId="templateType"
              options={templateOptions}
              name={'templateType'}
              onChange={value =>
                ringCentralStore.setTemplateType(value)
              }
              value={ringCentralStore.templateType}
              fullWidth
              row
              disabled={!!ringCentralStore.templateId}
            />
            {/* {createRadioField({
              store: ringCentralStore,
              testId: 'templateType',
              fieldName: 'templateType',
              label: 'Template Type',
              options: templateOptions,
              row: true,

            })} */}
          </Grid>
        </Grid>
        {ringCentralStore.templateType === 'email' && (
          <>
            <Grid item container className={classes.contentContainer}>
              <Grid item xs={11}>
                <SelectField
                  testId='value'
                  variant="outlined"
                  standaloneLabel
                  options={imageInsertionOptions}
                  value={ringCentralStore.imageInsertionOption}
                  onChange={value => { ringCentralStore.imageInsertionOption = value }}
                  fullWidth
                  disabled={!!ringCentralStore.templateId}
                />
              </Grid>
            </Grid>
            {(['oneImage', 'twoImagesSideBySide'].includes(ringCentralStore.imageInsertionOption) && !ringCentralStore.templateId) &&
              <Grid item container className={classes.contentContainer}>
                <Grid item xs={11}>
                  <FileUploadArea items={insertions} setItems={items => setInsertions(items)} fileTypes={['png', 'jpg', 'jpeg', 'webp']}
                    multiple={'twoImagesSideBySide' == ringCentralStore.imageInsertionOption} maxFiles={2} />
                </Grid>
              </Grid>}
            {ringCentralStore.imageInsertionOption == 'noImage' && <> <Grid item>
              <Typography variant="h6">BombBomb Video HTML</Typography>
            </Grid>
              <Grid item container className={classes.contentContainer}>
                <Grid item xs={11}>
                  <TextField
                    testId='value'
                    variant="outlined"
                    standaloneLabel
                    inputProps={{ maxLength: 1000 }}
                    value={ringCentralStore.bombBombVideoLink}
                    error={ringCentralStore.bombBombVideoLink && !ringCentralStore.bombBombVideoLink?.includes('</a>')}
                    onChange={value =>
                      ringCentralStore.setBombBombVideoLinkValue(value.target.value)
                    }
                    fullWidth
                  />
                </Grid>
              </Grid> </>}
            <Grid item>
              <Typography variant="h6">Template Subject</Typography>
            </Grid>
            <Grid item container className={classes.contentContainer}>
              <Grid item xs={11}>
                <TextField
                  testId='value'
                  variant="outlined"
                  standaloneLabel
                  inputProps={{ maxLength: 1000 }}
                  value={ringCentralStore.templateSubject}
                  onChange={value =>
                    ringCentralStore.setTemplateSubject(value.target.value)
                  }
                  fullWidth
                />
              </Grid>
            </Grid>
          </>
        )
        }
        <Grid item>
          <Typography variant="h6">Template Value</Typography>
        </Grid>
        <Grid item container className={classes.contentContainer}>
          <Grid container item xs={11}>
            {ringCentralStore.templateType == 'email' ?
              <RichTextWithAttachments
                onFilesChange={onFilesChange}
                attachments={attachments}
                mockAttachments={mockAttachments}
                setMockAttachments={setMockAttachments}
                setAttachments={setAttachments}
                value={ringCentralStore.templateValue}
                onChange={onRichTextValueChange}
                placeHolders={templatePlaceHolders}
              />
              : <Grid xs={12}>
                <Grid className={classes.attachmentButtonContainer}>
                  <IconButton onClick={handleMenuOpen}>{<Code />}</IconButton>
                </Grid>
                <Menu
                  anchorEl={anchorPlaceHolderElement}
                  open={placeHolderMenu}
                  onClose={() => setPlaceHolderMenu(false)}
                >
                  {templatePlaceHolders.map(placeHolder => <MenuItem onClick={() => {
                    setPlaceHolderMenu(false);
                    ringCentralStore.setTemplateValue(`${ringCentralStore.templateValue} ${placeHolder}`);
                  }}>{placeHolder}</MenuItem>)}
                </Menu>
                <TextField
                  testId='value'
                  variant="outlined"
                  standaloneLabel
                  inputProps={{ maxLength: 1000 }}
                  value={ringCentralStore.templateValue}
                  onChange={value =>
                    ringCentralStore.setTemplateValue(value.target.value)
                  }
                  multiline
                  fullWidth
                  minRows={3}
                  maxRows={Infinity}
                />
              </Grid>
            }
          </Grid>
        </Grid>
      </Grid>
      <Grid item container justifyContent='center' className={classes.buttonContainer}>
        {ringCentralStore.templateId === '' &&
          <Grid item sm={4} md={4} xs={12} style={{ marginBottom: 16 }}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleSaveClick}
              testId="next"
            >
              Save
            </Button>
          </Grid>}
        {ringCentralStore.templateId !== '' && <Grid item sm={4} md={4} xs={12} style={{ marginBottom: 16, marginRight: 8 }}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleUpdateClick}
            testId="update"
            style={{ marginBottom: 16 }}
          >
            Update
          </Button>
        </Grid>}
        {ringCentralStore.templateId !== '' && <Grid item sm={4} md={4} xs={12} style={{ marginBottom: 16, marginRight: 8 }}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleDeleteClick}
            testId="delete"
            style={{ marginBottom: 16 }}
          >
            Delete
          </Button>
        </Grid>}
      </Grid>
    </Grid>
  )
});


export const CreateTemplate = observer(() => {
  const classes = useStyles();

  return (
    <Layout>
      <Box my={3}>
        <Typography variant="h3" gutterBottom>
          {'Create List'}
        </Typography>
      </Box>
      <Grid container justifyContent="center">
        <Grid item sm={12} md={8}>
          <Paper className={classes.paper}>
            <Grid container justifyContent="center" alignItems='center' className={classes.container}>
              <CreateTemplateForm />
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Layout>
  )
});

export const CreateTemplateModal = observer(({ handleClose }) => {
  const classes = useStyles();
  const { ringCentralStore, salesforceLeadStore, userStore } = useStore();
  const { sendEmailsStore } = salesforceLeadStore;

  useEffect(() => {
    if (ringCentralStore.templateType === 'email') {
      sendEmailsStore.updatePreview(userStore.userInfo.username, ringCentralStore.getTemplateValue, null, ringCentralStore.templateInsertions);
    } else {
      ringCentralStore.updatePreview(ringCentralStore.templateValue);
    }
  }, [ringCentralStore.templateType, ringCentralStore.templateValue, ringCentralStore.bombBombVideoLink, ringCentralStore.templateInsertions]);

  return (
    <Grid container justifyContent='flex-start' className={classes.modalContainer}>
      <CreateTemplateForm handleClose={handleClose} />
      {ringCentralStore.templateType === 'email' && (
        <>
          <div className={classes.divider}></div>
          <Grid item xs={12} md={6} className={classes.container}>
            <Box className={classes.previewBox}>
              <Box>
                <HtmlContainer html={sendEmailsStore.previewHtml} />
              </Box>
            </Box>
          </Grid>
        </>
      )}
      {ringCentralStore.templateType === 'text' &&
        <Grid item xs={12} md={6}>
          <Box className={classes.textMessage}>
            {ringCentralStore.previewHtml}
          </Box>
        </Grid>
      }
    </Grid>
  )
});