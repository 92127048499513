import { Route } from 'react-router';
import { Page, useBaseStore } from '@roc/feature-app-core';
import { ScopeOfWorkV2 } from '../components';

export const getScopeOfWorkRoutesConfigV2 = (
  basePath: string,
  baseUrl: string
) => {
  return {
    scopeOfWork: {
      path: `${basePath}/scope-of-work`,
      url: `${baseUrl}/scope-of-work`,
      documentTitle: 'Scope of Work',
    },
  };
};

export const getScopeOfWorkRoutesV2 = (basePath: string, baseUrl: string) => {
  const config = getScopeOfWorkRoutesConfigV2(basePath, baseUrl);
  const { globalStore } = useBaseStore();

  return {
    ...(globalStore.userFeatures?.underwritingEntitlements?.isScopeOfWorkFormEnabled
      ? {
        scopeOfWork: (
          <Route exact path={config.scopeOfWork.path}>
            <Page routeDefinition={config.scopeOfWork}>
              <ScopeOfWorkV2 />
            </Page>
          </Route>
        ),
      }
      : {}),
  };
};
