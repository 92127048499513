import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import {
  Grid,
  Typography,
} from '@material-ui/core';
import { useDrawsStore } from '../hooks';
import { useLoanStore } from '@roc/feature-loans';
import EditPointOfContactForm from './editPointOfContactForm';

const PointOfContactNew = observer(() => {
  const { pointOfContactStore } = useDrawsStore();
  const { loanStore } = useLoanStore();

  useEffect(() => {
    return () => pointOfContactStore.resetStore();
  }, [])

  useEffect(() => {
    pointOfContactStore.getPointOfContact(loanStore.loanDetails.loanId);
  }, [loanStore.loanDetails.loanId])

  return (
    <Grid item container xs={12} md={12}>
      <Grid container direction="column" item>
        <Grid item container direction='row' justifyContent='space-between'>
          <Grid item>
            <Typography variant="h6">
              POINT OF CONTACT
            </Typography>
          </Grid>
        </Grid>
        <Grid item>
          <Typography variant="caption" color="textSecondary">
            This person will provide the authenticated photographs of the property or coordinate with the inspection agent.
          </Typography>
        </Grid>
      </Grid>
      <Grid container direction="column">
        <EditPointOfContactForm />
      </Grid>
    </Grid>
  )
});

export default PointOfContactNew;
