import { GlobalStore } from '@roc/client-portal-shared/stores';
import { action, computed, flow, makeObservable, observable } from 'mobx';
import { FormStore } from '@roc/feature-app-core';
import {
  CalculatedDscrValues,
  DscrCalculatorFormValues,
} from '../types/dscrCalculatorTypes';
import { LoanSubtypeOption } from '../utils/dscrCalculatorConstants';
import {
  isNumber,
  getLoanSubtype,
  mapLoanToDscrCalculatorFormValues,
  removeEmptyValues,
} from '../utils/dscrCalculatorUtils';
const { SINGLE_PROPERTY, FIVE_PLUS, PORTFOLIO } = LoanSubtypeOption;

const REQUIRED_5_PLUS_OR_PORFOLIO = [
  {
    required_if_one_of: [
      'loanSubtype',
      LoanSubtypeOption.FIVE_PLUS,
      LoanSubtypeOption.PORTFOLIO,
      LoanSubtypeOption.LARGE_PORTFOLIO,
    ],
  },
];

const form = {
  fields: {
    loanSubtype: {
      value: LoanSubtypeOption.SINGLE_PROPERTY,
      error: null,
      rule: '',
    },

    estimatedAsIsValue: {
      value: '',
      error: null,
      rule: 'required',
      attribute: 'estimated as-is value',
    },

    requestedLTV: {
      value: '',
      error: null,
      rule: 'required|numeric',
      attribute: 'requested LTV',
    },

    rate: {
      value: '',
      error: null,
      rule: 'required',
      attribute: 'note interest rate',
    },

    interestOnlyPeriod: {
      value: '',
      error: null,
      rule: 'required',
      attribute: 'interest only period',
    },

    fullyAmortizingPeriod: {
      value: '',
      error: null,
      rule: REQUIRED_5_PLUS_OR_PORFOLIO,
      attribute: 'fully amortizing period',
      message: 'The fully amortizing period is required.',
    },

    loanAmount: {
      value: '',
      error: null,
      rule: '',
    },

    inPlaceMonthlyRent: {
      value: '',
      error: null,
      rule: '',
    },

    monthlyMarketRent: {
      value: '',
      error: null,
      rule: 'required|numeric|min:1',
      attribute: 'montlhy market rent',
    },

    annualQualifyingRent: {
      value: '',
      error: null,
      rule: '',
    },

    vacancy: {
      value: '',
      error: null,
      rule: '',
    },

    vacancyPercentOfGPR: {
      value: '',
      error: null,
      rule: '',
    },

    concession: {
      value: '',
      error: null,
      rule: '',
    },

    concessionPercentOfGPR: {
      value: '',
      error: null,
      rule: '',
    },

    creditLoss: {
      value: '',
      error: null,
      rule: '',
    },

    creditLossPercentOfGPR: {
      value: '',
      error: null,
      rule: '',
    },

    grossAnnualIncome: {
      value: '',
      error: null,
      rule: '',
    },

    grossPotentialRent: {
      value: '',
      error: null,
      rule: '',
    },

    annualTaxes: {
      value: '',
      error: null,
      rule: '',
    },

    annualInsurance: {
      value: '',
      error: null,
      rule: '',
    },

    annualHOAFees: {
      value: '',
      error: null,
      rule: '',
    },

    unitCount: {
      value: '',
      error: null,
      rule: [
        {
          required_if_one_of: [
            'loanSubtype',
            LoanSubtypeOption.FIVE_PLUS,
          ],
        },
      ],
      message: 'The unit count is required.',
    },

    propertyManagementFee: {
      value: '',
      error: null,
      rule: '',
    },

    propertyManagementFeePercentOfGPR: {
      value: '',
      error: null,
      rule: '',
    },

    generalAndAdministrativeFee: {
      value: '',
      error: null,
      rule: '',
    },

    generalAndAdministrativeFeePercentOfGPR: {
      value: '',
      error: null,
      rule: '',
    },

    repairsAndMaintenance: {
      value: '',
      error: null,
      rule: '',
    },

    repairsAndMaintenancePercentOfGPR: {
      value: '',
      error: null,
      rule: '',
    },

    utilities: {
      value: '',
      error: null,
      rule: '',
    },

    utilitiesPercentOfGPR: {
      value: '',
      error: null,
      rule: '',
    },

    payroll: {
      value: '',
      error: null,
      rule: '',
    },

    payrollPercentOfGPR: {
      value: '',
      error: null,
      rule: '',
    },

    //PORTFOLIO
    turnoverCosts: {
      value: '',
      error: null,
      rule: '',
    },

    //PORTFOLIO
    leasingAndMarketing: {
      value: '',
      error: null,
      rule: '',
    },

    //PORTFOLIO
    miscellaneous: {
      value: '',
      error: null,
      rule: '',
    },

    totalOperatingExpensePercentOfGPR: {
      value: '',
      error: null,
      rule: '',
    },

    replacementReserves: {
      value: '',
      error: null,
      rule: '',
    },

    replacementReservesPercentOfGPR: {
      value: '',
      error: null,
      rule: '',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

const sum = (...numbers) =>
  numbers.reduce((total, n) => total + (parseFloat(n) || 0), 0);

export abstract class DscrCalculatorBaseStore extends FormStore {
  private globalStore: GlobalStore;

  abstract getDefaultValues(): DscrCalculatorFormValues;
  abstract getLoanValues(loanId: string, isDraft: boolean): DscrCalculatorFormValues;
  abstract getCalculatedValues(data: DscrCalculatorFormValues);
  abstract getAutocompleteOptions(searchTerm: string);

  loanSearchText: string = '';
  loanSearchOptions: { label: string; value: string }[] = [];

  defaultValues: any;
  calculatedValues: CalculatedDscrValues;
  operatingExpenses: number;

  constructor(globalStore) {
    super(form, globalStore);
    this.globalStore = globalStore;

    makeObservable(this, {
      getDefaultValues: flow,
      getLoanValues: flow,
      getCalculatedValues: flow,
      getAutocompleteOptions: flow,
      loanSearchText: observable,
      loanSearchOptions: observable,
      calculatedValues: observable,
      defaultValues: observable,
      fetchDefaultValues: flow,
      fetchLoanValues: flow,
      fetchCalculatedValues: flow,
      fetchOptions: flow,
      calculateLoanAmount: action,
      calculateFullyAmortizingPeriod: action,
      calculateDefaultsBasedOnGPR: action,
      isLargePortfolio: computed,
      loanAmount: computed,
      grossPotentialRent: computed,
      operatingIncome: computed,
      operatingExpenses: observable,
    });
  }

  resetStore({ keepSearchText = false }) {
    if (!keepSearchText) {
      this.loanSearchText = '';
      this.loanSearchOptions = [];
    }
    this.calculatedValues = null;

    this.reset();
  }

  *fetchOptions(searchText: string) {
    try {
      if (!searchText) {
        this.loanSearchOptions = [];
        return;
      }
      return this.getAutocompleteOptions(searchText);
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'An error occurred while loading the list of loans.',
      });
    }
  }

  *fetchDefaultValues() {
    try {
      if (!this.defaultValues) {
        this.defaultValues = yield this.getDefaultValues();
      }
      const loanSubtype = this.form.fields.loanSubtype.value;
      const defaults = { ...this.defaultValues[loanSubtype], loanSubtype };
      this.resetStore({ keepSearchText: true });
      this.loadForm(defaults);
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'An error occurred while loading the default values.',
      });
    }
  }

  *fetchLoanValues(loanId: string, isDraft: boolean) {
    try {
      const loanValues = yield this.getLoanValues(loanId, isDraft);
      const values = mapLoanToDscrCalculatorFormValues(loanValues);
      const loanSubtype = getLoanSubtype(loanValues);

      if (loanSubtype) {
        this.loadForm({ loanSubtype });
        yield this.fetchDefaultValues();
        this.loadForm(values);
      } else {
        this.globalStore.notificationStore.showErrorNotification({
          message: `${loanValues.loanSubType} loans are not supported yet`,
        });
      }
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'An error occurred while loading the loan data.',
      });
    }
  }

  *fetchCalculatedValues() {
    try {
      this.getOperatingExpenses();
      this.runFormValidationWithMessage();
      if (this.form.meta.isValid) {
        const data = removeEmptyValues(this.getFormValues());
        this.calculatedValues = yield this.getCalculatedValues(data);
      }
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'An error occurred while loading the calculated values.',
      });
    }
  }

  get isLargePortfolio() {
    const { unitCount } = this.form.fields;
    //TODO: What's UPB in -> Large Portfolios (>10 properties OR UPB >$2MM)
    return unitCount.value > 10;
  }

  get loanAmount() {
    const values = this.getFormValues();
    return values.estimatedAsIsValue && values.requestedLTV
      ? values.estimatedAsIsValue * (values.requestedLTV / 100)
      : undefined;
  }

  get grossPotentialRent() {
    const { fields } = this.form;
    const inPlaceMonthlyRent = fields.inPlaceMonthlyRent.value;
    const monthlyMarketRent = fields.monthlyMarketRent.value;
    return !isNumber(monthlyMarketRent)
      ? undefined
      : inPlaceMonthlyRent > 0
        ? Math.min(inPlaceMonthlyRent, monthlyMarketRent) * 12
        : monthlyMarketRent * 12 * 0.9;
  }

  get operatingIncome() {
    const values = this.getFormValues();
    const gpr = this.grossPotentialRent;

    return gpr - sum(values.vacancy, values.concession, values.creditLoss);
  }

  getOperatingExpenses() {
    const values = this.getFormValues();
    this.operatingExpenses = sum(
      values.propertyManagementFee,
      values.annualTaxes,
      values.annualInsurance,
      values.generalAndAdministrativeFee,
      values.annualHOAFees,
      values.repairsAndMaintenance,
      values.turnoverCosts,
      values.utilities,
      values.payroll,
      values.leasingAndMarketing,
      values.miscellaneous
    );
  }

  calculateLoanAmount() {
    const { estimatedAsIsValue, requestedLTV } = this.getFormValues();
    if (isNumber(estimatedAsIsValue) && isNumber(requestedLTV)) {
      const value = (estimatedAsIsValue * requestedLTV) / 100;
      this.onFieldChange('loanAmount', value);
    }
  }

  calculateFullyAmortizingPeriod() {
    const { interestOnlyPeriod, loanSubtype } = this.getFormValues();

    if (isNumber(interestOnlyPeriod)) {
      const value = 360 - interestOnlyPeriod;
      this.onFieldChange('fullyAmortizingPeriod', value);
    }
  }

  calculateGrossAnnualIncome() {
    if (isNumber(this.operatingIncome)) {
      this.onFieldChange('grossAnnualIncome', this.operatingIncome);
    }
  }

  calculateDefaultsBasedOnGPR() {
    const { loanSubtype } = this.getFormValues();
    const defaults = this.defaultValues?.[loanSubtype];
    const gpr = this.grossPotentialRent;
    if (isNumber(gpr) && defaults) {
      this.loadForm({
        annualQualifyingRent: gpr,
        grossPotentialRent: gpr,
        vacancy: gpr * (defaults.vacancyPercentOfGPR / 100),
        concession: gpr * (defaults.concessionPercentOfGPR / 100),
        creditLoss: gpr * (defaults.creditLossPercentOfGPR / 100),
        propertyManagementFee:
          gpr * (defaults.propertyManagementFeePercentOfGPR / 100),
        leasingAndMarketing:
          gpr * (defaults.leasingAndMarketingPercentOfGPR / 100),
      });
    }
  }

  calculateDefaultsBasedOnUnitCount() {
    const { loanSubtype, unitCount } = this.getFormValues();
    const defaults = this.defaultValues?.[loanSubtype];
    if (isNumber(unitCount) && defaults) {
      this.loadForm({
        generalAndAdministrativeFee:
          unitCount * defaults.generalAndAdministrativeFeePerUnit,
        repairsAndMaintenance:
          unitCount * defaults.repairsAndMaintenancePerUnit,
        turnoverCosts: unitCount * defaults.turnoverCostsPerUnit,
        utilities: unitCount * defaults.utilitiesPerUnit,
        payroll: unitCount * defaults.payrollPerUnit,
        replacementReserves: unitCount * defaults.replacementReservesPerUnit,
      });
    }
  }
}
