import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { Layout } from '@roc/client-portal-shared/components';
import { Paper } from '@roc/ui';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { DscrCalculator1To4Units } from './components/dscrCalculator1To4Unit';
import { DscrCalculator5PlusUnits } from './components/dscrCalculator5PlusUnits';
import { DscrCalculatorFieldCalculations } from './components/dscrCalculatorFieldCalculations';
import { DscrCalculatorLargePortfolio } from './components/dscrCalculatorLargePortfolio';
import { DscrCalculatorPortfolio } from './components/dscrCalculatorPortfolio';
import { LoanSubtypeOption } from './utils/dscrCalculatorConstants';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  tabs: {},
}));

export const DscrCalculator = observer(({ dscrCalculatorStore }) => {
  const classes = useStyles();
  const { loanSubtype } = dscrCalculatorStore.form.fields;

  useEffect(() => {
    dscrCalculatorStore.fetchDefaultValues();
  }, [loanSubtype.value]);

  const subtype = dscrCalculatorStore.form.fields.loanSubtype.value;

  return (
    <Layout title="DSCR Calculator" maxWidth={'xl'}>
      <Paper className={classes.paper}>
        <Grid container spacing={4}>
          {subtype === LoanSubtypeOption.SINGLE_PROPERTY && (
            <DscrCalculator1To4Units store={dscrCalculatorStore} />
          )}
          {subtype === LoanSubtypeOption.FIVE_PLUS && (
            <DscrCalculator5PlusUnits store={dscrCalculatorStore} />
          )}
          {subtype === LoanSubtypeOption.PORTFOLIO && (
            <DscrCalculatorPortfolio store={dscrCalculatorStore} />
          )}
          {subtype === LoanSubtypeOption.LARGE_PORTFOLIO && (
            <DscrCalculatorLargePortfolio store={dscrCalculatorStore} />
          )}
        </Grid>
        <DscrCalculatorFieldCalculations store={dscrCalculatorStore} />
      </Paper>
    </Layout>
  );
});
