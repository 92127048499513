import { RentalPricerBaseService } from './rentalPricerBaseService';

const url = {
  GET_PRICER_GRID: '/api/v1/loan/rentalPricer/getRentalPricerGrid',
  GET_RENTAL_PRICER_BY_ID: '/api/v1/loan/rentalPricer/getRentalPricerById',
  SAVE_RENTAL_PRICER: '/api/v1/loan/rentalPricer/saveRentalPricer',
  DELETE_PRICER: '/api/v1/loan/rentalPricer/removePricer',
};

export class RentalPricerService extends RentalPricerBaseService {
  getRentalPricerGrid(
    page: number,
    pageSize: number,
    sortDir: string,
    sortBy: string,
    filters: string
  ): Promise<any> {
    return this.get(
      url.GET_PRICER_GRID,
      {
        page,
        pageSize,
        sortDir,
        sortBy,
        filters,
      },
      {
        disableGlobalLoading: true,
      }
    );

    // const rows = [{ quoteId: 1 }, { quoteId: 2 }, { quoteId: 3 }];
    // return Promise.resolve({ data: { data: { rows } } });
  }

  getRentalPricerById(rentalPricerId: number) {
    return this.get(url.GET_RENTAL_PRICER_BY_ID, { rentalPricerId });
  }

  saveRentalPricer(rentalPricerData: any) {
    return this.post(url.SAVE_RENTAL_PRICER, rentalPricerData);
  }

  deletePricer(rentalPricerId: number) {
    return this.delete(url.DELETE_PRICER, { rentalPricerId });
  }

}
