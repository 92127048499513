import { action, flow, makeObservable, observable } from 'mobx';
import { GlobalStore, GridStore } from '@roc/feature-app-core';
import { BackgroundReviewFormStore } from '@roc/feature-borrower-review';

export class BackgroundReviewStore {
  globalStore: GlobalStore;
  backgroundReviewFormStore: BackgroundReviewFormStore;

  constructor(globalStore: GlobalStore) {
    this.globalStore = globalStore;
    this.backgroundReviewFormStore = new BackgroundReviewFormStore(
      globalStore
    );
    makeObservable(this, {

    });
  }
};