import { ApiResponse, GlobalStore, GridStore } from "@roc/feature-app-core";
import { LoanService } from "@roc/feature-loans";
import { action, makeObservable, observable } from "mobx";

export class LoanSummaryModalStore {
  private loanService: LoanService;
  private globalStore: GlobalStore;
  titleHistoryGridStore: GridStore;
  titleHistoryOpen: boolean;
  borrowerHistoryOpen: boolean;
  currentEntity: any;
  modalColumnMap: any;
  currentBorrower: any;
  experienceCommentsOpen: boolean;
  creditCommentsOpen: boolean;
  public loanExposure: GridStore;
  public borrowerPreviousPartners: GridStore;
  public borrowerHistoryBridgeLoans: GridStore;
  public borrowerHistoryRentalLoans: GridStore;
  public isFaco: boolean;

  constructor(globalStore: GlobalStore, loanService: LoanService) {
    this.loanService = loanService;
    this.globalStore = globalStore;
    this.titleHistoryGridStore = new GridStore(() => this.fetchTitleHistory(), null, 50);
    this.loanExposure = new GridStore(() => this.getLoanExposure());
    this.borrowerPreviousPartners = new GridStore(() => this.getBorrowerPreviousPartners());
    this.borrowerHistoryBridgeLoans = new GridStore(() => this.getBorrowerHistoryBridgeLoans());
    this.borrowerHistoryRentalLoans = new GridStore(() => this.getBorrowerHistoryRentalLoans());

    makeObservable(this, {
      titleHistoryOpen: observable,
      isFaco: observable,
      borrowerHistoryOpen: observable,
      experienceCommentsOpen: observable,
      creditCommentsOpen: observable,
      openTitleHistoryModal: action,
      closeTitleHistoryModal: action,
    });
    this.setDefaults();
  }

  private setDefaults() {
    this.titleHistoryOpen = false;
    this.borrowerHistoryOpen = false;
    this.experienceCommentsOpen = false;
    this.creditCommentsOpen = false;
    this.currentEntity = {};
    this.currentBorrower = null;
    this.modalColumnMap = {
      "address": (data) => this.openTitleHistoryModal(data),
      "portalProjectsCompleted": (data) => this.openBorrowerHistoryModal(data),
      "portalProjectsAgreed": (data) => this.openBorrowerHistoryModal(data),
      "portalProjectsActive": (data) => this.openBorrowerHistoryModal(data),
      "experience": (data) => this.openExperienceCommentsModal(data),
      "medianScore": (data) => this.openCreditCommentsModal(data),
    };
  }

  openTitleHistoryModal(property) {
    this.titleHistoryOpen = true;
    this.currentEntity = property;
  }

  closeTitleHistoryModal() {
    this.titleHistoryOpen = false;
    this.currentEntity = {};
  }

  openBorrowerHistoryModal(borrower) {
    this.borrowerHistoryOpen = true;
    this.currentBorrower = borrower;
  }

  closeBorrowerHistoryModal() {
    this.borrowerHistoryOpen = false;
    this.currentBorrower = null;
  }

  openExperienceCommentsModal(borrower) {
    this.experienceCommentsOpen = true;
    this.currentBorrower = borrower;
  }

  closeExperienceCommentsModal() {
    this.experienceCommentsOpen = false;
    this.currentBorrower = null;
  }

  openCreditCommentsModal(borrower) {
    this.creditCommentsOpen = true;
    this.currentBorrower = borrower;
  }

  closeCreditCommentsModal() {
    this.creditCommentsOpen = false;
    this.currentBorrower = null;
  }

  fetchTitleHistory = async () => {
    try {
      const response: ApiResponse = await this.loanService.getTitleHistory(
        this.currentEntity?.propertyReferenceId,
        this.titleHistoryGridStore.gridData.meta.pageNumber,
        this.titleHistoryGridStore.gridData.meta.pageSize,
        this.titleHistoryGridStore.gridData.meta.sortDir,
        this.titleHistoryGridStore.gridData.meta.sortBy,
        this.titleHistoryGridStore.gridData.meta.filters,
      );
      return response;
    } catch (error) {
      console.log('Error while fetching the title history', error);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching the title history.',
      });
    }
  }

  async getLoanExposure() {
    try {
      if (this.currentBorrower) {
        const response: ApiResponse = await this.loanService.getLoanExposure(
          this.loanExposure.gridData.meta.pageNumber,
          this.loanExposure.gridData.meta.pageSize,
          this.loanExposure.gridData.meta.sortBy,
          this.loanExposure.gridData.meta.sortDir,
          this.loanExposure.gridData.meta.filters,
          this.loanExposure.gridData.meta.dropdownFilters,
          this.currentBorrower?.borrowerId,
        );
        const rows = response.data.data.rows;
        this.isFaco = rows.some(function (currentRow) {
          return currentRow.faco === true;
        });
        return response;
      }
    }
    catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'An error occurred while fetching LoanExposure'
      });
    }
  }

  async getBorrowerPreviousPartners() {
    try {
      if (this.currentBorrower) {
        const response: ApiResponse = await this.loanService.getBorrowerPreviousPartners(
          this.borrowerPreviousPartners.gridData.meta.pageNumber,
          this.borrowerPreviousPartners.gridData.meta.pageSize,
          this.borrowerPreviousPartners.gridData.meta.sortBy,
          this.borrowerPreviousPartners.gridData.meta.sortDir,
          this.borrowerPreviousPartners.gridData.meta.filters,
          this.borrowerPreviousPartners.gridData.meta.dropdownFilters,
          this.currentBorrower?.borrowerId,
        );
        return response;
      }
    }
    catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'An error occurred while fetching borrowerPreviousPartners'
      });
    }
  }

  async getBorrowerHistoryBridgeLoans() {
    try {
      if (this.currentBorrower) {
        const response: ApiResponse = await this.loanService.getBorrowerHistoryBridgeLoans(
          this.borrowerHistoryBridgeLoans.gridData.meta.pageNumber,
          this.borrowerHistoryBridgeLoans.gridData.meta.pageSize,
          this.borrowerHistoryBridgeLoans.gridData.meta.sortBy,
          this.borrowerHistoryBridgeLoans.gridData.meta.sortDir,
          this.borrowerHistoryBridgeLoans.gridData.meta.filters,
          this.borrowerHistoryBridgeLoans.gridData.meta.dropdownFilters,
          this.currentBorrower?.borrowerId,
        );
        return response;
      }
    }
    catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'An error occurred while fetching BorrowerHistoryBridgeLoans'
      });
    }
  }

  async getBorrowerHistoryRentalLoans() {
    try {
      if (this.currentBorrower) {
        const response: ApiResponse = await this.loanService.getBorrowerHistoryRentalLoans(
          this.borrowerHistoryRentalLoans.gridData.meta.pageNumber,
          this.borrowerHistoryRentalLoans.gridData.meta.pageSize,
          this.borrowerHistoryRentalLoans.gridData.meta.sortBy,
          this.borrowerHistoryRentalLoans.gridData.meta.sortDir,
          this.borrowerHistoryRentalLoans.gridData.meta.filters,
          this.borrowerHistoryRentalLoans.gridData.meta.dropdownFilters,
          this.currentBorrower?.borrowerId,
        );
        return response;
      }
    }
    catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'An error occurred while fetching borrowerHistoryRentalLoans'
      });
    }
  }
}