import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@material-ui/core';
import {
  Check,
  Close,
  Refresh,
  MoreVert,
} from '@material-ui/icons';
import {
  Toolbar,
} from '@roc/client-portal-shared/components';
import { formatDate } from '@roc/client-portal-shared/utils';
import {
  AgGridColumnProps, StatusChip, StatusType,
} from '@roc/ui';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import {
  FilterButton,
  SelectFilterComponent,
  SelectFloatingFilterComponent,
} from '../../../components/grid';
import { Grid } from '../../../components/grid/grid';

const noOpComparator = () => 0;


const columns: AgGridColumnProps[] = [
  {
    field: 'borrowerName',
    headerName: 'Borrower Name',
    minWidth: 250,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'email',
    headerName: 'Borrower Email',
    minWidth: 250,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'status',
    headerName: 'Status',
    minWidth: 150,
    cellRenderer: 'statusCellRenderer',
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'requestedDate',
    headerName: 'Requested Date',
    minWidth: 100,
    cellRenderer: 'dateCellRenderer',
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'liquidityDate',
    headerName: 'Last liquidity date',
    minWidth: 100,
    cellRenderer: 'dateCellRenderer',
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: '',
    headerName: 'Action',
    maxWidth: 100,
    cellRenderer: 'actionCellRenderer',
    comparator: noOpComparator,
    width: 80,
    pinned: 'right',
    lockPinned: true
  },
];


export const PlaidLiquidityRequestsGrid = observer(({ store }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const [requestId, setRequestId] = useState('');
  const [record, setRecord] = useState(null);

  const openMenu = (event: React.MouseEvent<HTMLElement>, record) => {
    const { requestId } = record;
    setRequestId(requestId);
    setRecord(record);
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const frameworkComponents = {
    selectFilter: SelectFilterComponent,
    selectFloatingFilter: SelectFloatingFilterComponent,
    dateCellRenderer: ({ value }) => formatDate(value, 'MM/dd/yyyy') ?? null,
    checkCellRenderer: ({ value }) => {
      return value ? (
        <Check style={{ color: 'green', verticalAlign: 'middle' }} />
      ) : (
        <Close style={{ color: 'red', verticalAlign: 'middle' }} />
      );
    },
    statusCellRenderer: ({ value }) =>
      value ? (
        <StatusChip
          statusType={StatusType.LIQUIDITY_VERIFICATION}
          label={value}
          size="small"
        />
      ) : null,
    actionCellRenderer: params => {
      return (
        <IconButton onClick={e => openMenu(e, params.node.data)}>
          <MoreVert color={params.node?.data?.status === 'Request Initiated' ? "disabled" : "primary"} />
        </IconButton>
      );
    },
  };

  const onSortChanged = params => {
    const sortModel = params.api.getSortModel();
    store.liquidityRequestsGridStore.setSortModel(sortModel);
  };

  const onFilterChanged = params => {
    const filterModel = params.api.getFilterModel();
    store.liquidityRequestsGridStore.setFilterModel(filterModel);
  };

  const toolbar = (
    <Toolbar>
      <FilterButton
        onToggleFilters={() => store.liquidityRequestsGridStore.toggleFilters()}
        onClearFilters={() => store.liquidityRequestsGridStore.resetFilters()}
      />
    </Toolbar>
  );

  return (
    <>
      <Grid
        columns={columns}
        rows={store.liquidityRequestsGridStore.gridData.data.rows}
        frameworkComponents={frameworkComponents}
        toolbar={toolbar}
        onSortChanged={onSortChanged}
        isLoading={store.liquidityRequestsGridStore.isLoading}
        sortModel={store.liquidityRequestsGridStore.sortModel}
        showFilters={store.liquidityRequestsGridStore.showFilters}
        filterModel={store.liquidityRequestsGridStore.filterModel}
        onFilterChanged={onFilterChanged}
        paginationData={store.liquidityRequestsGridStore.gridData.meta}
        setPageNumber={store.liquidityRequestsGridStore.setPageNumber}
      />
      {record?.status === 'Request Initiated' && (
        <Menu
          id="menu-appbar"
          getContentAnchorEl={null}
          anchorEl={anchorEl}
          keepMounted
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          open={menuOpen}
          onClose={handleMenuClose}
        >
          <MenuItem
            onClick={() => {
              store.updateRequest(record);
              handleMenuClose();
            }}
          >
            <ListItemIcon>
              <Refresh fontSize="small" />
            </ListItemIcon>
            <ListItemText>Resend Request</ListItemText>
          </MenuItem>
        </Menu>
      )}
      {record?.status === 'Request Completed' && (
        <Menu
          id="menu-appbar"
          getContentAnchorEl={null}
          anchorEl={anchorEl}
          keepMounted
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          open={menuOpen}
          onClose={handleMenuClose}
        >
          <MenuItem
            onClick={() => {
              store.regenerateReport(requestId);
              handleMenuClose();
            }}
          >
            <ListItemIcon>
              <Refresh fontSize="small" />
            </ListItemIcon>
            <ListItemText>Regenerate Report</ListItemText>
          </MenuItem>
        </Menu>
      )}
    </>
  );
});
