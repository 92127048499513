import { Button } from '@roc/ui';
import React, { ReactNode } from 'react';
import { DialogState, StandardDialog, StandardDialogProps } from './dialog';

interface EditableDialogProps extends StandardDialogProps {
  open: boolean;
  title: string;
  addDialogTitlePrefix?: string;
  editDialogTitlePrefix?: string;
  addButtonText?: string;
  editButtonText?: string;
  dialogContent: ReactNode;
  dialogState: DialogState;
  handleClose: React.MouseEventHandler<HTMLButtonElement>;
  handleAdd?: React.MouseEventHandler<HTMLButtonElement>;
  handleEdit?: React.MouseEventHandler<HTMLButtonElement>;
  disabledButton?: boolean;
  additionalActions?: ReactNode[];
  showSaveChangesButton?: boolean;
  showCancelButton?: boolean;
}

export const EditableDialog = ({
  open,
  title,
  addDialogTitlePrefix = 'Add',
  editDialogTitlePrefix = 'Edit',
  handleClose,
  dialogContent,
  dialogState,
  handleAdd = () => { },
  handleEdit = () => { },
  disabledButton = false,
  addButtonText = 'Add',
  editButtonText = 'Apply Changes',
  additionalActions,
  showSaveChangesButton = true,
  showCancelButton = true,
  ...props
}: EditableDialogProps) => {
  return (
    <StandardDialog
      open={open}
      title={
        dialogState == DialogState.ADD
          ? `${addDialogTitlePrefix} ${title}`
          : `${editDialogTitlePrefix} ${title}`
      }
      handleClose={handleClose}
      dialogContent={dialogContent}
      dialogActions={
        <>
          {showCancelButton && 
            <Button
              style={{ marginRight: '8px' }}
              onClick={handleClose}
              color="primary"
              variant="outlined"
              testId="cancel"
            >
              Cancel
            </Button>
          }
          {additionalActions && additionalActions.map((action, index) => (
            <span key={index}>
              {action}
            </span>
          ))}
          {showSaveChangesButton && 
            <Button
              onClick={e => {
                {
                  dialogState == DialogState.ADD ? handleAdd(e) : handleEdit(e);
                }
              }}
              color="primary"
              variant="contained"
              testId="save"
              disabled={disabledButton}
            >
              {dialogState == DialogState.ADD ? addButtonText : editButtonText}
            </Button>
          } 
        </>
      }
      {...props}
    />
  );
};
