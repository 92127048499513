import React, { useState } from 'react';
import {
  Dialog,
  DialogActions as MuiDialogActions,
  DialogContent,
  DialogTitle as MuiDialogTitle,
  Typography,
  IconButton,
  Checkbox,
  FormControlLabel,
  Box
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { WithStyles } from '@material-ui/core/styles';
import { Close } from '@material-ui/icons';
import { observer } from 'mobx-react';
import { Button } from '@roc/ui';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const styles = (theme: Theme) =>
  createStyles({
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography {...other}>
      <Typography variant="h4">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <Close />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
}))(MuiDialogActions);

const ResubmitAsStabilizedModal = observer(
  ({ open, handleClose }) => {

    const handleDialogClose = e => {
      handleClose(e);
    };

    return (
      <div>
        <Dialog
          disableEscapeKeyDown
          disableBackdropClick
          open={open}
          onClose={handleDialogClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle
            id="form-dialog-title"
            onClose={() => handleDialogClose(null)}
          >
            Resubmit Loan Warning
          </DialogTitle>
          <DialogContent>

            <Typography variant="body1">
              Please resubmit this deal as a Stabilized Bridge loan, the program designed for bridge loans with no renovations needed
            </Typography>

          </DialogContent>
          <DialogActions disableSpacing>
            <Button onClick={handleDialogClose} color="primary" testId="cancel">
              Go Back
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
);

export default ResubmitAsStabilizedModal;
