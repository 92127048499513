import { observer } from 'mobx-react';
import {
  Paper
} from '@roc/ui';
import { Grid, Snackbar } from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { CompareCard } from './compareCard';
import { useEffect } from 'react';
import { useStore } from '@roc/client-portal-shared/hooks';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      border: `1px solid ${theme.palette.primary.light}`,
      background: '#28292D',
      width: '100%',
      padding: '20px',
    },
    snackbar: {
      bottom: '0px',
      width: '100%',
      [theme.breakpoints.down('xs')]: {
        left: 0,
        right: 0,
      },
      zIndex: 'auto'
    },
  })
);


export const CompareGadget = observer(() => {
  const classes = useStyles();
  const { compToolStore } = useStore();

  const { openCompareGadget, selectedCompareProperties, showGridView } = compToolStore;

  return (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={openCompareGadget}
        key='compareGadget'
        className={classes.snackbar}
      >
        <Paper className={classes.paper}>
          <Grid container>
            <CompareCard properties={selectedCompareProperties} />
          </Grid>
        </Paper>
      </Snackbar>
    </>
  );
});