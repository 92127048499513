import { Card, CardContent, CardHeader, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { useDropzone } from 'react-dropzone';
import { Box } from '@material-ui/core';
import { Close, CloudDownload, CloudUpload } from '@material-ui/icons';
import { FC, SyntheticEvent, useEffect, useMemo } from 'react';
import { Button, createDateField, createSelectField, createTextField, IconButton } from '@roc/ui';
import Grid from '@material-ui/core/Grid';
import { idTypeOptions } from '@roc/feature-loans';
import { NO, YES, borrowerStatesAbbreviation, borrowerStatesNames, isNotBlank } from '@roc/feature-utils';
import { observer } from 'mobx-react';
import { format, isValid } from 'date-fns';

const useFileUploadStyles = makeStyles((theme: Theme) => ({
  card: {
    marginTop: theme.spacing(2)
  },
  cardContent: {
    paddingTop: 0
  },
  fileLine: {
    display: 'flex',
    alignItems: 'center'
  },
  dragArea: {
    border: '2px dotted #427ED1',
    width: '100%',
    backgroundColor: '#EAF4FA',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: 20,
    borderRadius: 5,
    cursor: 'pointer',
  },
  icon: {
    minWidth: '20px',
    minHeight: '20px',
    width: '3vw',
    height: '3vw',
    color: '#4B89DA',
  },
  fileContainer: {
    border: '1px solid grey',
    backgroundColor: '#EAF4FA',
    padding: '10px 20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '10px 0',
    borderRadius: 5,
  },

  fileName: {
    marginLeft: 5,
    marginRight: 2,
    flexGrow: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  removeButton: {
    marginLeft: theme.spacing(2),
    alignSelf: 'flex-end',
    alignItems: 'flex-end',
    float: 'right',
  },
}));

const stateOptions = borrowerStatesAbbreviation.map((abbreviation, i) => ({
  value: abbreviation,
  label: borrowerStatesNames[i],
}));

export const IdInformation = observer(({ store }) => {

  const { idInformationStore } = store;

  const showPassportFields = useMemo(() => {
    return idInformationStore.form.fields.idType.value !== 'US Driver\'s License / Government ID';
  }, [idInformationStore.form.fields.idType.value]);

  const uploadImageTitle = useMemo(() => {
    if (showPassportFields) return "Document Upload - Document ID Image*";
    return "Document Upload - Driver's License Image*";
  }, [showPassportFields]);

  useEffect(() => {
    idInformationStore.onFieldChange('driversLicense', showPassportFields ? NO : YES);
  }, [showPassportFields]);

  const manageDate = (fieldName, date) => {
    let formattedDate = null;
    if (isValid(date)) {
      formattedDate = format(date, 'MM/dd/yyyy');
    }
    idInformationStore.onFieldChange(fieldName, formattedDate);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5">Identification</Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        {createSelectField({
          store: idInformationStore,
          testId: 'idType',
          fieldName: 'idType',
          label: 'ID Type',
          options: idTypeOptions,
          placeholder: 'Make Selection',
        })}
      </Grid>
      {showPassportFields ? (
        <>
          <Grid item xs={12} sm={3}>
            {createTextField({
              store: idInformationStore,
              testId: 'passportNumber',
              fieldName: 'passportNumber',
              label: 'Passport Number',
            })}
          </Grid>
          <Grid item xs={12} sm={3}>
            {createDateField({
              store: idInformationStore,
              label: 'Passport Expiration',
              fieldName: 'passportExpiration',
              testId: 'passportExpiration',
              format: 'MM/dd/yyyy',
              onChange: manageDate,
            })}
          </Grid>
        </>
      ) : (
        <>
          <Grid item xs={12} sm={3}>
            {createSelectField({
              store: idInformationStore,
              testId: 'driversLicenseState',
              fieldName: 'driversLicenseState',
              label: "Driver's License State",
              options: stateOptions,
            })}
          </Grid>
          <Grid item xs={12} sm={3}>
            {createTextField({
              store: idInformationStore,
              testId: 'driversLicenseNumber',
              fieldName: 'driversLicenseNumber',
              label: "Driver's License Number",
            })}
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        <DriversLicenseUpload
          title={uploadImageTitle}
          file={idInformationStore.driversLicense}
          onChange={file =>
            idInformationStore.setDriversLicense(file)
          }
          onDownload={isNotBlank(store.borrowerDetailsVerification?.savedForm?.driversLicenseUploadPath) ? () => store.getIdFile() : undefined}
        />
      </Grid>
    </Grid>
  )
});

interface DriversLicenseUploadProps {
  title?: string,
  file: File | null;
  onChange: (file: File | null) => void;
  onDownload: () => void;
}

export const DriversLicenseUpload: FC<DriversLicenseUploadProps> = ({ title = 'Document Upload - Passport Image*', file, onChange, onDownload }) => {
  function onDrop(newFiles: File[]) {
    onChange(newFiles?.[0]);
  }

  function handleOnRemove(event: SyntheticEvent) {
    event.stopPropagation();
    onChange(null)
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
  });

  const classes = useFileUploadStyles();
  return (
    <Card variant="outlined" className={classes.card}>
      <CardHeader title={title}
        action={onDownload &&
          <IconButton
            color="primary"
            testId="download"
            onClick={onDownload}
            size="small"
          >
            <CloudDownload />
          </IconButton>
        } />
      <CardContent className={classes.cardContent}>
        <Box {...getRootProps()} className={classes.dragArea}>
          <input {...getInputProps()} />
          <CloudUpload className={classes.icon} />
          {file ? (
            <Box className={classes.fileLine}>
              <Typography>{file?.name}</Typography>
              <Button
                testId={`removeFile-${file?.name}`}
                size="small"
                variant="outlined"
                className={classes.removeButton}
                onClick={handleOnRemove}
              >
                REMOVE <Close />
              </Button>
            </Box>
          ) : isDragActive ? (
            <p>Drop the file(s) here...</p>
          ) : (
            <>
              <p>Drag File to Upload</p>
              <p>or Browse file</p>
            </>
          )}
        </Box>
        <Box></Box>
      </CardContent>
    </Card>
  );
};
