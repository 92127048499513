import {

  Tooltip as MuiTooltip,
  TooltipProps as MuiTooltipProps,

  SvgIconProps,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { Help } from '@material-ui/icons';
import { ReactElement, ReactNode } from 'react';

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    tooltip: {
      whiteSpace: 'pre-line',
      fontSize: theme.typography.pxToRem(14),
      maxWidth: '75vw',
    },
    helpIcon: {
      height: '18px',
      width: '18px',
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.5),
    },
  })
);

export interface IconProps extends SvgIconProps { }

export interface TooltipProps extends Omit<MuiTooltipProps, 'children'> {
  children?: ReactElement;
  placement?:
  | 'right'
  | 'left'
  | 'top'
  | 'bottom'
  | 'bottom-end';
}

export const Tooltip = (props: TooltipProps) => {
  const { children, placement, ...muiTooltipProps } = props;
  const classes = useStyle();

  return (
    <MuiTooltip
      placement={placement || "right"}
      classes={{ tooltip: classes.tooltip }}
      {...muiTooltipProps}
    >
      {children ?? <Help className={classes.helpIcon} color="primary" />}
    </MuiTooltip>
  );
};
