import { Step, StepContent, StepLabel, Stepper, Button, Box, Typography } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useACHFormStore } from '../hooks/useACHStore';
import { usePlaidLink } from 'react-plaid-link';
import React from 'react';
import { PlaidProps } from './generalInformation';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';

const useStyles = makeStyles(theme => ({
  stepper: {
    '& .MuiStepIcon-completed': {
      color: 'green'
    }
  }
}));

export const BankVerficationStepper = observer((props: PlaidProps) => {
  const { achFormStore } = useACHFormStore();
  const classes = useStyles();

  const { open, ready } = usePlaidLink({
    token: props.linkToken!,
    onSuccess: React.useCallback((public_token, metadata) => {
      const encodedLoanId = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
      achFormStore.storePlaidACHInformation({
        publicToken: public_token,
        loanId: encodedLoanId,
        accounts: metadata.accounts,
        instantVerification: !metadata.accounts[0].verification_status
      }).then(response => achFormStore.fetchACHData(encodedLoanId));
    }, [])
  });

  return (
    <Stepper orientation='vertical' activeStep={achFormStore.finalVerificationStep} className={classes.stepper}>
      <Step>
        <StepLabel>You have given your bank account information</StepLabel>
      </Step>
      <Step>
        <StepLabel>{achFormStore.finalVerificationStep == 1 ? 'We are in the process of posting a micro deposit into your account' : 'We have posted a micro deposit into your account'}</StepLabel>
        <StepContent TransitionProps={{ unmountOnExit: false }}>
        </StepContent>
      </Step>
      <Step>
        <StepLabel>{achFormStore.finalVerificationStep == 1 ? 'Complete the verification process. You will receive an email when this is ready' : 'Complete the verification process'}</StepLabel>
        <StepContent TransitionProps={{ unmountOnExit: false }}>
          <Box display={'flex'} justifyContent={'flex-start'}>
            <Box display={'flex'} justifyContent={'center'} alignItems={'center'} flexDirection={'column'} p={6}>
              <Typography variant="h5">Please verify your deposit information</Typography>
              <Button color={'primary'} size='small' style={{ marginTop: '20px' }} disabled={!ready} variant={'contained'} onClick={() => open()}>Verify Account</Button>
            </Box>
          </Box>
        </StepContent>
      </Step>
    </Stepper>)
});