import { observer } from 'mobx-react';
import { CustomCard } from '../customCard';
import { TestDataAttribute } from '@roc/ui';
import { Box, Divider, Grid, List, ListItem, ListItemText } from '@material-ui/core';
import { formatDate, isNil, sanitizeTestId } from '@roc/feature-utils';
import { BooleanChip } from '@roc/ui';
import {
  US_TREASURY_10_YR_DESC,
  US_TREASURY_10_YR_INDEX,
  US_TREASURY_5_YR_DESC,
  US_TREASURY_5_YR_INDEX,
  useLoanStore
} from '@roc/feature-loans';
import { useUserStore } from '@roc/feature-app-core';

function PricingAndRateDivider() {
  return <Divider variant="middle" component="li" />;
}

const listItem = (label: string, value: unknown, icon = null, customTestId = null) => {
  const testId = sanitizeTestId(customTestId || label);
  return (
    <ListItem>
      <ListItemText>
        <Box display="flex">
          {icon && <Box ml={1}>{icon}</Box>}
          <Box flexGrow={1} mr={1}>
            {label}
          </Box>
          <TestDataAttribute id={testId}>
            <Box textAlign="right">{value}</Box>
          </TestDataAttribute>
        </Box>
      </ListItemText>
    </ListItem>
  );
};

const formatPercentage = (value: string | null) => `${value !== null ? Number(value).toFixed(2) : ''}%`;

const getSwapIndexLabel = (index: number) => {
  if (index === US_TREASURY_5_YR_INDEX) return US_TREASURY_5_YR_DESC;
  else if (index === US_TREASURY_10_YR_INDEX) return US_TREASURY_10_YR_DESC;
};

type LoanPricingData = Record<any, any>;
interface PricingAndRateCardListProps {
  loanPricingData: LoanPricingData;
}

function PricingList({ loanPricingData }: PricingAndRateCardListProps) {
  return (
    <List>
      {!isNil(loanPricingData.programType) && listItem(
        'Program Type',
        loanPricingData.programType
      )}
      {!isNil(loanPricingData.programType) && <PricingAndRateDivider />}
      {listItem(
        'Spread on Pricing Date',
        !isNil(loanPricingData.spreadRate) &&
        formatPercentage(loanPricingData.spreadRate)
      )}
      <PricingAndRateDivider />
      {listItem(
        'Indicative Gross Rate On Pricing Date',
        !isNil(loanPricingData.grossRateOnPricingDate) &&
        formatPercentage(loanPricingData.grossRateOnPricingDate)
      )}
      <PricingAndRateDivider />
      {listItem(
        'Pricing Date',
        !isNil(loanPricingData.pricingDate) &&
        formatDate(loanPricingData.pricingDate, 'MMMM d, yyyy')
      )}
      <PricingAndRateDivider />
      {listItem(
        'Pricing Index',
        !isNil(loanPricingData.swapIndex) &&
        `${getSwapIndexLabel(loanPricingData.swapIndex)}`
      )}
      <PricingAndRateDivider />
      {listItem(
        'Index Value On Pricing Date',
        !isNil(loanPricingData.swapRateAtPricing) &&
        formatPercentage(loanPricingData.swapRateAtPricing)
      )}
      <PricingAndRateDivider />
      {listItem(
        'Index Rate Floor',
        !isNil(loanPricingData.swapRateAtOrigination) &&
        formatPercentage(loanPricingData.swapRateAtOrigination)
      )}
      <PricingAndRateDivider />
      {listItem(
        'Current Index Value For Loan',
        !isNil(loanPricingData.swapRate) &&
        formatPercentage(loanPricingData.swapRate)
      )}
      <PricingAndRateDivider />
      {listItem(
        'Today’s Indicative Gross Rate',
        loanPricingData?.useSpread
          ? formatPercentage(
            (
              parseFloat(loanPricingData.swapRate) +
              parseFloat(loanPricingData.spreadRate)
            ).toFixed(4)
          )
          : null
      )}
    </List>
  );
}

function RightRateList({ loanPricingData }: PricingAndRateCardListProps) {
  return (
    <List>
      {listItem(
        'Borrower Spread Lock',
        !isNil(loanPricingData.borrowerSpreadLock) && (
          <BooleanChip value={loanPricingData.borrowerSpreadLock} />
        )
      )}
      <PricingAndRateDivider />
      {listItem(
        'Borrower Gross Rate Lock Expiry Date',
        !isNil(loanPricingData.borrowerGrossRateLockExpiryDate) &&
        formatDate(
          loanPricingData.borrowerGrossRateLockExpiryDate,
          'MMMM d, yyyy'
        )
      )}
      <PricingAndRateDivider />
      {listItem(
        'Borrower Gross Rate Lock',
        isNil(loanPricingData.borrowerGrossRateLock) && (
          <BooleanChip
            value={loanPricingData.borrowerGrossRateLock}
          />
        )
      )}
      <PricingAndRateDivider />
      {listItem(
        'Borrower Spread Lock Expiry Date',
        !isNil(loanPricingData.borrowerSpreadLockExpiryDate) &&
        formatDate(
          loanPricingData.borrowerSpreadLockExpiryDate,
          'MMMM d, yyyy'
        )
      )}
    </List>
  );
}

function RateList({ loanPricingData }: PricingAndRateCardListProps) {
  return (
    <List>
      {listItem(
        'Borrower Rate Lock Extension 1 Start Date',
        !isNil(loanPricingData.firstPaidBorrowerGrossRateLockStartDate) &&
        formatDate(loanPricingData.firstPaidBorrowerGrossRateLockStartDate, 'MMMM d, yyyy')
      )}
      <PricingAndRateDivider />
      {listItem(
        'Borrower Rate Lock Extension 1 Expiry Date',
        !isNil(loanPricingData.firstPaidBorrowerGrossRateLockExpiryDate) &&
        formatDate(loanPricingData.firstPaidBorrowerGrossRateLockExpiryDate, 'MMMM d, yyyy')
      )}
      <PricingAndRateDivider />
      {listItem(
        'Borrower Rate Lock Extension 2 Start Date',
        !isNil(loanPricingData.secondPaidBorrowerGrossRateLockStartDate) &&
        formatDate(loanPricingData.secondPaidBorrowerGrossRateLockStartDate, 'MMMM d, yyyy')
      )}
      <PricingAndRateDivider />
      {listItem(
        'Borrower Rate Lock Extension 2 Expiry Date',
        !isNil(loanPricingData.secondPaidBorrowerGrossRateLockExpiryDate) &&
        formatDate(loanPricingData.secondPaidBorrowerGrossRateLockExpiryDate, 'MMMM d, yyyy')
      )}
    </List>
  );
}

export const PricingAndRateCard = observer(() => {
  const { userStore } = useUserStore();
  const { loanStore } = useLoanStore();
  const allowLoanPricing = userStore.allowLoanPricing;
  const { loanPricingData = {} } = loanStore.loanDetails;
  const hasPricingData = !!loanPricingData.pricingDate;

  if (allowLoanPricing && hasPricingData) return (
    <CustomCard title="Pricing And Rate Lock Information">
      <Grid container spacing={4}>
        <Grid item xs={12} md={4}>
          <PricingList loanPricingData={loanPricingData} />
        </Grid>
        <Grid item xs={12} md={4}>
          <RightRateList loanPricingData={loanPricingData} />
        </Grid>
        <Grid item xs={12} md={4}>
          <RateList loanPricingData={loanPricingData} />
        </Grid>
      </Grid>
    </CustomCard>
  );
  return null;
});
