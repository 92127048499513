import { Page } from "@roc/feature-app-core";
import { getManageBrokerRoutesConfig } from "@roc/feature-brokers";
import { Route } from "react-router";
import { ManageBrokersInternal } from "../components/manageBrokersInternal";

export const getManageBrokersInternalRoutesConfig = (basePath: string, baseUrl: string) => {
  return getManageBrokerRoutesConfig(basePath, baseUrl);
}

export const getManageBrokersInternalRoutes = (basePath: string, baseUrl: string) => {
  const config = getManageBrokerRoutesConfig(basePath, baseUrl);
  return {
    manageBrokers: (
      <Route exact path={config.manageBrokers.path}>
        <Page routeDefinition={config.manageBrokers}>
          <ManageBrokersInternal/>
        </Page>
      </Route>
    )
  }
}