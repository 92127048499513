import React, { useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';

import {
  RadioField,
  SelectField,
  yesNoOptions,
  TextField,
  CurrencyField,
  NumberFormat,
  GridRowSelectedEvent,
  PreConstruct,
} from '@roc/ui';
import { observer } from 'mobx-react';
import { CONDOMINIUM, isNotBlank } from '@roc/client-portal-shared/utils';
import {
  REFINANCE,
} from '@roc/client-portal-shared/utils';
import {
  AnnualHoa,
  AnnualInsurance,
  AnnualTaxes,
  AsIsValue,
  GrossMonthlyRent,
  PricerLoanPurpose,
  PricerPurchasePrice,
  PricerPurchaseDate,
  PricerCapitalImprovements,
  PricerCurrentDebtOnProperty,
  PricerCurrentDebtAmount,
  MonthlyMarketRent,
  Occupancy,
  PropertyType,
  ExitStrategy,
  TenantInsuranceAlert,
  Address
} from './components/stabilizedBridgeFields';

import {
  PropertyAddressDetails
} from '@roc/ui/formComponents';

import { PricerFieldsConfig } from '../../rentalPricer/components/rentalPricerFieldsContext';
import { StabilizedBridgeStore } from '../stores';
import { ChooseExistingPropertyAppraisal } from '../fixflipGroundUp/components/chooseExistingPropertyAppraisal';
import { ArmsLength, FieldContainer, ArmsLengthDescribe, PropertySource, PropertySourceOther, WholesalerPropertyPaid, WholesalerPropertySource, WholesalerPropertySourceOther, AnyDebt, ColumnContainer, FieldContainerNoSpacing } from '@roc/client-portal-shared/components';
import { useStabilizedBridgeStore } from '../hooks/useStabilizedBridgeStore';
import { propertyTypesByLoanSubtype } from '../utils/propertyTypes';
import { PURCHASE } from '../stores/common/pricerSummaryHelper';
import { useFixFlipStore } from '../hooks/useFixFlipStore';
import { GridContainer } from '../components/formComponents';

export const AppraisalInformation = observer(() => {
  const {
    stabilizedBridgeStore: { pricerStore },
  } = useStabilizedBridgeStore();
  const { form } = pricerStore.appraisalInformationStore;

  useEffect(() => {
    if (!form.fields.chooseExistingAppraisal.value) {
      pricerStore.reset();
      pricerStore.resetStores();
      pricerStore.appraisalInformationStore.onFieldChange(
        'chooseExistingAppraisal',
        false
      );
    }
  }, [form.fields.chooseExistingAppraisal.value]);

  const onRowSelected = (e: GridRowSelectedEvent) => {
    const { node } = e;

    if (node.isSelected()) {
      pricerStore.setAppraisalId(
        node.data?.appraisalData?.propertyAppraisalId
      );
      pricerStore.loadPropertyFromAppraisal(node.data);
    } else {
      pricerStore.reset();
    }
  };

  return (
    <>
      <FieldContainer>
        <ChooseExistingPropertyAppraisal
          store={pricerStore.appraisalInformationStore}
          onRowSelected={onRowSelected}
        />
      </FieldContainer>
    </>
  );
});

export const PropertyDetails = observer(
  ({ store }: { store: StabilizedBridgeStore }) => {
    const { pricerStore } = useStabilizedBridgeStore().stabilizedBridgeStore;

    return (
      <PricerFieldsConfig xs={12}>
        <FieldContainer>
          <PropertyAddressDetails store={pricerStore.propertyDetailsStore} disabled={Boolean(pricerStore.appraisalId)} />
        </FieldContainer>
        <FieldContainer>
          <PropertyType store={pricerStore} />
        </FieldContainer>
        <FieldContainer>
          <PricerPurchasePrice store={pricerStore} />
        </FieldContainer>
        <FieldContainer>
          <PricerLoanPurpose store={pricerStore} />
        </FieldContainer>
      </PricerFieldsConfig>
    );
  }
);

export const PurposeInformation = observer(
  ({ store }: { store: StabilizedBridgeStore }) => {
    const { pricerStore } = store;
    const { form } = pricerStore;

    const fields = form.fields;

    const isRefinance =
      store.pricerStore.propertyDetailsStore.form.fields.loanPurpose.value &&
      store.pricerStore.propertyDetailsStore.form.fields.loanPurpose.value === REFINANCE;

    const isPurchase =
      store.pricerStore.propertyDetailsStore.form.fields.loanPurpose.value &&
      store.pricerStore.propertyDetailsStore.form.fields.loanPurpose.value === PURCHASE;

    return (
      <PricerFieldsConfig xs={12}>
        {isRefinance && (
          <>
            <Refinance_PurchaseInformation />
          </>
        )}
        {isPurchase && (
          <PurchaseInformation />
        )
        }

      </PricerFieldsConfig>
    );
  }
);

export const PurchaseInformation = observer(() => {
  const { pricerStore } = useStabilizedBridgeStore().stabilizedBridgeStore;
  const { purchaseInformationStore, appraisalId } = pricerStore;
  const isWholesalerProperty =
    pricerStore.purchaseInformationStore.form.fields.propertySourcing.value ===
    'Wholesaler';
  const isArmsLengthNo =
    pricerStore.purchaseInformationStore.form.fields.armsLength.value === 'N';
  const isPropertySourceOther =
    pricerStore.purchaseInformationStore.form.fields.propertySourcing.value === 'Other';
  const isWholesalerPropertySourceOther =
    pricerStore.purchaseInformationStore.form.fields.wholesalerPurchase.value === 'Other';
  return (
    <>
      <FieldContainer>
        <ArmsLength store={purchaseInformationStore} disabled={appraisalId} />
      </FieldContainer>
      {isArmsLengthNo && (
        <FieldContainer>
          <ArmsLengthDescribe
            store={purchaseInformationStore}
            disabled={appraisalId}
          />
        </FieldContainer>
      )}
      <FieldContainer>
        <PropertySource
          store={purchaseInformationStore}
          disabled={appraisalId}
        />
      </FieldContainer>
      {isPropertySourceOther && (
        <FieldContainer>
          <PropertySourceOther
            store={purchaseInformationStore}
            disabled={appraisalId}
          />
        </FieldContainer>
      )}
      {isWholesalerProperty && (
        <FieldContainer>
          <WholesalerPropertyPaid
            store={purchaseInformationStore}
            disabled={appraisalId}
          />
        </FieldContainer>
      )}
      {isWholesalerProperty && (
        <FieldContainer>
          <WholesalerPropertySource
            store={purchaseInformationStore}
            disabled={appraisalId}
          />
        </FieldContainer>
      )}
      {isWholesalerProperty && isWholesalerPropertySourceOther && (
        <FieldContainer>
          <WholesalerPropertySourceOther
            store={purchaseInformationStore}
            disabled={appraisalId}
          />
        </FieldContainer>
      )}
    </>
  );
});

export const Refinance_PurchaseInformation = observer(() => {
  const { pricerStore } = useStabilizedBridgeStore().stabilizedBridgeStore;
  const { refinance_purchaseInformationStore, appraisalId } = pricerStore;
  const isAnyDebt =
    refinance_purchaseInformationStore.form.fields.anyDebt.value === 'Y';
  return (
    <>

      <PricerFieldsConfig xs={12}>
        <FieldContainer>
          <PricerPurchaseDate store={refinance_purchaseInformationStore} />
        </FieldContainer>
        <FieldContainer>
          <PricerCapitalImprovements store={refinance_purchaseInformationStore} />
        </FieldContainer>
        <FieldContainer>
          <PricerCurrentDebtOnProperty store={refinance_purchaseInformationStore} />
        </FieldContainer>
        <FieldContainer>
          <PricerCurrentDebtAmount store={refinance_purchaseInformationStore} />
        </FieldContainer>
      </PricerFieldsConfig>
    </>
  );
});

export const PropertyInformation = observer(
  ({ store }: { store: StabilizedBridgeStore }) => {
    const { form } = store.pricerStore;

    return (
      <FieldContainerNoSpacing>
        <ColumnContainer>
          <AsIsValue store={store} />
          <GrossMonthlyRent store={store} />
        </ColumnContainer>
        <ColumnContainer>
          <MonthlyMarketRent store={store} />
          <AnnualTaxes store={store} />
        </ColumnContainer>
        <ColumnContainer>
          <AnnualInsurance store={store} />
          <AnnualHoa store={store} />
        </ColumnContainer>
        <ColumnContainer>
          <ExitStrategy store={store} />
          <Occupancy store={store} />
        </ColumnContainer>
        <ColumnContainer>
          <TenantInsuranceAlert store={store} />
        </ColumnContainer>
      </FieldContainerNoSpacing>
    );
  }
);
