import { ApiResponse, GlobalStore, GridStore } from '@roc/feature-app-core';
import { MyFundedLoansForDrawsGridStore } from '@roc/feature-borrower-draws';
import { LoanBaseStore } from '@roc/feature-loans-shared';
import { FundedLoansForPayoffGridStore } from '@roc/feature-servicer-loans';
import { GENERIC_ERROR_MESSAGE, getPageFromObject } from '@roc/feature-utils';
import { flow, makeObservable, override, observable, action } from 'mobx';
import { BorrowerLoanDetailsService } from '../modules/borrowerLoanDetails/services/borrowerLoanDetailsService';
import { BorrowerLoanApplicationsGridStore } from '../modules/borrowerLoans/stores/borrowerLoanApplicationsGridStore';
import { FundedLoansForExtensionsGridStore } from '@roc/feature-extensions';
import { BorrowerLoanApplicationDetailsService } from '../modules/borrowerLoanDetails/services/borrowerLoanApplicationDetailsService';
import { BorrowerDetailsVerificationStore } from '@roc/feature-borrowers';
import { BorrowerTrackRecordVerificationStore } from 'libs/feature-borrowers/src/borrowerDetailsVerification/stores/borrowerTrackRecordVerificationStore';
import { BorrowerDetailsVerificationService } from '@roc/feature-credit-background-check';

export class BorrowerLoanApplicationsStore extends LoanBaseStore {
  fundedLoansForPayoffGridStore: GridStore;
  fundedLoansForExtensionsGridStore: GridStore;
  borrowerLoanApplicationsGridStore: BorrowerLoanApplicationsGridStore;
  private borrowerLoanApplicationDetailsService: BorrowerLoanApplicationDetailsService;
  borrowerDetailsVerificationStore: BorrowerDetailsVerificationStore;
  borrowerTrackRecordVerificationStore: BorrowerTrackRecordVerificationStore;
  myFundedLoansForDrawsGridStore: GridStore;
  propertiesGridStore: GridStore;

  loanDetails;
  currentBorrowerId: number;
  currentBorrower: any;
  isLoanDetailsSuccess: boolean;

  constructor(globalStore: GlobalStore) {
    super(globalStore);


    this.borrowerLoanApplicationsGridStore = new BorrowerLoanApplicationsGridStore(globalStore);
    this.myFundedLoansForDrawsGridStore = new MyFundedLoansForDrawsGridStore(
      globalStore
    );
    this.fundedLoansForPayoffGridStore = new FundedLoansForPayoffGridStore(
      globalStore
    );
    this.fundedLoansForExtensionsGridStore = new FundedLoansForExtensionsGridStore(
      globalStore
    );
    this.propertiesGridStore = new GridStore(() =>
      this.fetchPropertiesGridData()
    );
    this.borrowerLoanApplicationDetailsService = new BorrowerLoanApplicationDetailsService();
    this.borrowerDetailsVerificationStore = new BorrowerDetailsVerificationStore(globalStore);
    this.borrowerTrackRecordVerificationStore = new BorrowerTrackRecordVerificationStore(globalStore, new BorrowerDetailsVerificationService());

    makeObservable(this, {
      loanDetails: override,
      fetchLoanApplicationDetails: flow,
      currentBorrowerId: observable,
      isLoanDetailsSuccess: observable,
      fetchCurrentUserBorrower: flow,
      findCurrentBorrower: action,
      fetchLoanApplicationDetailsAndCurrentUserBorrower: flow,
      setLoanDetailsSuccess: action,
    });
  }

  *fetchLoanApplicationDetails(loanId) {
    try {
      this.loanDetails = null;
      const response = yield this.borrowerLoanApplicationDetailsService.getBorrowerLoanApplication(
        loanId
      );
      this.loanDetails = response.data.data;
      this.propertiesGridStore.resetAndFetchGridData();
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE
      });
    }
  }

  private async fetchPropertiesGridData(): Promise<ApiResponse> {
    const properties = this.loanDetails?.properties || this.loanDetails?.propertiesMap?.rows || [];
    const { meta } = this.propertiesGridStore.gridData;
    const data = getPageFromObject(properties, meta);
    return Promise.resolve(new ApiResponse({ data }, null));
  }

  *fetchCurrentUserBorrower() {
    try {
      const response = yield this.borrowerLoanApplicationDetailsService.getCurrentUserBorrower();
      this.currentBorrowerId = response.data.data.borrowerId;
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'A issue happened while fetching the current borrower data.'
      })
    }
  }

  findCurrentBorrower() {
    this.currentBorrower = this.loanDetails?.loanBorrowerInformation?.loanBorrowers?.rows.find(row => row.borrowerId == this.currentBorrowerId);
  }

  *fetchLoanApplicationDetailsAndCurrentUserBorrower(loanId) {
    yield this.fetchLoanApplicationDetails(loanId);
    yield this.fetchCurrentUserBorrower();
    this.findCurrentBorrower();
  }

  setLoanDetailsSuccess(value: boolean) {
    this.isLoanDetailsSuccess = value;
  }
}
