import { Service } from './service';

export const url = {
  VALIDATE_HOST_CERTIFICATES:
    '/api/v1/loan/public/portalconfiguration/certificate/valid?host='
};

export class GlobalService extends Service {
  validateHostCertificates(host:string) {
    return this.get(`${url.VALIDATE_HOST_CERTIFICATES}${host}`);
  }
}
