import { Link } from "@material-ui/core";
import { Theme, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    cursor: 'pointer',
    textDecoration: 'none',
    transition: 'color 0.3s ease',
  },
}));

export function createData(
  name: string,
  value: any,
  renderer?: any,
  data?: any,
  onClick?: (value: any) => any
) {
  return { name, value, renderer, data, onClick };
}

export function createHeader(header: string, renderer: (value: any) => any = undefined) {
  return { header, renderer };
}

export function createValue(value: any, renderer: (value: any) => any = undefined) {
  return { value, renderer };
}

export function createName(name: any, renderer: (value: any) => any = undefined) {
  return { name, renderer };
}

export const NoWrapRenderer = (val) => (<span style={{
  whiteSpace: 'nowrap'
}}>{val}</span>);


export const BreakAllRenderer = (val) => (<span style={{
  wordBreak: 'break-all'
}}>{val}</span>);

export const ModalRenderer = (val, onClick, data) => {
  const classes = useStyles();
  return <Link
    style={{ fontWeight: 'bold', textDecoration: 'underline' }}
    className={classes.link}
    onClick={() => onClick(data)}
  >
    {val}
  </Link>
};
