import { GlobalStore } from '@roc/feature-app-core';
import { observable, action, makeObservable } from 'mobx';
import { ScopeOfWorkV2FormBaseStore } from '.';
import {
  CategoryDB,
  SubcategoryDB,
  SubcategoryDetailsDB,
} from '@roc/feature-sow-shared/types';

interface InitOpenData {
  categoryName?: string;
  categoryId?: number;
  subcategoryName?: string;
  cost?: number;
  description?: string;
  capex?: number;
  details?: SubcategoryDetailsDB[];
  isItemized?: boolean;
  hasTooltip?: boolean;
}

export const GENERAL_RADIO_OPTION = 'general';
export const ITEMIZED_RADIO_OPTION = 'itemized';

const OTHER = 'Other';

export class SubcategoryItemizedDetailsStore {
  private globalStore: GlobalStore;
  private sowFormStore: ScopeOfWorkV2FormBaseStore;

  subcategory: SubcategoryDB;

  isOpen: boolean;
  isItemized: boolean;
  categoryName: string;
  subcategoryName: string;
  categoryId: number;
  cost: number;
  capex: number;
  description: string;
  details: SubcategoryDetailsDB[];
  hasTooltip: boolean;
  subCatTooltip: string;

  validationErrors: Record<string, any>;
  option: string;

  constructor(
    globalStore: GlobalStore,
    sowFormStore: ScopeOfWorkV2FormBaseStore
  ) {
    this.globalStore = globalStore;
    this.sowFormStore = sowFormStore;

    this.reset();

    makeObservable(this, {
      subcategory: observable,
      option: observable,
      validationErrors: observable,
      closeModal: action,
      isOpen: observable,
      openModal: action,
      reset: action,
      categoryName: observable,
      categoryId: observable,
      subcategoryName: observable,
      cost: observable,
      description: observable,
      capex: observable,
      details: observable,
      subCatTooltip: observable,
    });
  }

  reset() {
    this.option = GENERAL_RADIO_OPTION;
    this.isOpen = false;
    this.isItemized = false;
    this.categoryName = null;
    this.subcategoryName = null;
    this.categoryId = null;
    this.cost = null;
    this.details = [];
    this.description = null;
    this.capex = null;
    this.validationErrors = {};
    this.hasTooltip = false;
    this.subCatTooltip = "";
  }

  closeModal() {
    this.isOpen = false;
  }

  saveAuditLog(){
    if (this.option === GENERAL_RADIO_OPTION) {

      const logCost = {
        category: this.categoryName,
        subcategory: this.subcategoryName,
        actionPerformed: null,
        columnChanged: "COST",
        previousValue: null,
        newValue: this.cost
      };

      const logCapex = this.capex ?
      {category: this.categoryName,
        subcategory: this.subcategoryName,
        actionPerformed: null,
        columnChanged: "COMPLETED RENOVATIONS",
        previousValue: null,
        newValue: this.capex,
        cost: this.cost
      } : null;

      this.sowFormStore.scopeOfWorkAuditLogs.push(logCost)
      if(logCapex){
        this.sowFormStore.scopeOfWorkAuditLogs.push(logCapex)
      }
    } else {
      this.details.map((detail, _) => {
        if(detail.cost){
          const logCost = {
            category: this.categoryName,
            subcategory: this.subcategoryName + "_" + detail.name,
            actionPerformed: null,
            columnChanged: "COST",
            previousValue: null,
            newValue: detail.cost
          }
          const logCapex = detail.capex ? {
              category: this.categoryName,
              subcategory: this.subcategoryName + "_" + detail.name,
              actionPerformed: null,
              columnChanged: "COMPLETED RENOVATIONS",
              previousValue: null,
              newValue: detail.capex,
              cost: detail.cost
            } : null;
          this.sowFormStore.scopeOfWorkAuditLogs.push(logCost);
          if(logCapex){
            this.sowFormStore.scopeOfWorkAuditLogs.push(logCapex);
          }
        }
      })
    }
  }

  setOption(option) {
    this.validationErrors = {};
    this.option = option;
  }

  openModal(initData: InitOpenData = {}) {
    this.isOpen = true;
    this.isItemized = initData.isItemized;
    this.categoryId = initData.categoryId;
    this.categoryName = initData.categoryName || '';
    this.subcategoryName = initData.subcategoryName || '';
    this.cost = initData.cost ? parseFloat(initData.cost.toString()) : null;
    this.description = initData.description || '';
    this.capex = initData.capex ? parseFloat(initData.capex.toString()) : null;
    this.details = initData.details;
    this.hasTooltip = initData.hasTooltip;
  }

  editSubcategory(
    subcategory: SubcategoryDB,
    categoryMeta: CategoryDB,
    subcategoryMeta: SubcategoryDB
  ) {
    this.reset();
    this.isOpen = true;

    this.subcategory = subcategory;

    this.categoryName = categoryMeta.name;
    this.categoryId = subcategory.categoryId;
    this.subcategoryName = subcategory.name;

    this.isItemized = subcategoryMeta.details.length > 0;

    this.hasTooltip = !(subcategoryMeta?.tooltip?.trim() === "");

    this.subCatTooltip = subcategoryMeta.tooltip;

    const hasSavedItems = subcategory.details.length > 0;

    this.option = hasSavedItems ? ITEMIZED_RADIO_OPTION : GENERAL_RADIO_OPTION;

    this.details = hasSavedItems
      ? subcategory.details.map(d => ({ ...d }))
      : subcategoryMeta.details.map(d => ({ ...d }));

    this.cost = subcategory.cost;
    this.description = subcategory.description;
    this.capex = subcategory.capex;
  }

  addOther() {
    const other: SubcategoryDetailsDB = {
      detailId: null,
      name: 'Other',
      reviewStatus: null,
      comments: "",
    };
    this.details = this.details.concat([other]);
  }

  validateFields() {
    this.validationErrors = {};
    if (this.option === GENERAL_RADIO_OPTION) {
      this.validationErrors['general'] = {
        cost: !this.cost ? 'Required' : null,
        capex: (this.capex > 100) ? 'Completed amount greater than cost' : null
      };
    } else {
      this.details.forEach((detail, i) => {
        const isOther = detail.name === OTHER && !detail.detailId;
        this.validationErrors[i] = {
          cost:
            !detail.cost && detail.description && detail.capex ? 'Required' : null,
          description:
            isOther && detail.cost && !detail.description && detail.capex ? 'Required' : null,
          capex:
            (detail.capex > 100) ? 'Completed amount greater than cost' : null
        };
      });
    }
    return !Object.values(this.validationErrors).some(
      e => e.cost || e.description || e.capex
    );
  }

  handleSave = () => {
    if (this.validateFields()) {
      this.saveChanges();
      this.closeModal();
      this.saveAuditLog();
    } else {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Please fix the validation errors.',
      });
    }
  };

  saveChanges() {
    if (this.option === GENERAL_RADIO_OPTION) {
      this.sowFormStore.categoryStore.updateSubcategory(this.subcategory, {
        cost: this.cost,
        description: this.description,
        capex: this.capex,
        details: [],
      });
    } else {
      const isOtherAndIsEmpty = ({ name, detailId, cost = 0 }) =>
        name === OTHER && !detailId && !cost;

      const details = this.details.filter(d => !isOtherAndIsEmpty(d));

      this.sowFormStore.categoryStore.updateSubcategory(this.subcategory, {
        cost: null,
        description: null,
        capex: null,
        details,
      });
    }
  }
}

export default SubcategoryItemizedDetailsStore;
