import { useBaseStore } from '@roc/feature-app-core';
import { Document } from '@roc/feature-types';
import { ValuationReview } from '@roc/feature-valuation-review';
import { StandardDialog } from '@roc/ui';
import { useRocInternalPortalStore } from 'apps/roc-internal-portal/src/app/hooks';
import { observer } from 'mobx-react';

type InternalValuationReportDialogProps = {
  open: boolean;
  onClose: () => void;
  document: Document;
};

export const InternalValuationReportDialog = observer(
  ({ open, onClose, document }: InternalValuationReportDialogProps) => {
    const { globalStore } = useBaseStore();
    const obj = useRocInternalPortalStore();
    const { valuationReviewReportStore } = obj;

    if (!document) {
      return null;
    }

    const dialogContent = <ValuationReview store={valuationReviewReportStore} loanId={document.loanId} propertyId={document.collateralId} readOnlyMode={!globalStore.isInternalPortal} />

    return (
      <StandardDialog
        maxWidth={globalStore.isInternalPortal ? 'lg' : 'md'}
        open={open}
        title="Internal Valuation Report"
        handleClose={onClose}
        dialogContent={dialogContent}
        removeContentBox
      />
    );
  }
);