import { useBaseStore } from '@roc/feature-app-core';
import { getClosingTrackerRoutes, getClosingTrackerRoutesConfig } from '../routes/closingTrackerRoutes';

export const useClosingTrackerRoutes = () => {
  const { globalStore } = useBaseStore();
  const { routeBasePath, routeBaseUrl } = globalStore;
  return {
    closingTrackerRoutes: getClosingTrackerRoutes(routeBasePath, routeBaseUrl),
    closingTrackerRoutesConfig: getClosingTrackerRoutesConfig(
      routeBasePath,
      routeBaseUrl
    ),
  };
};
