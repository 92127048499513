import { Route } from 'react-router';
import { Page } from '@roc/feature-app-core';
import { ScopeOfWorkInternal } from '../components/scopeOfWorkInternal';
import { ScopeOfWorkInternalLinkCreate } from '../components/scopeOfWorkInternalLinkCreate';

export const getScopeOfWorkInternalRoutesConfig = (
  basePath: string,
  baseUrl: string
) => {
  return {
    scopeOfWork: {
      path: `${basePath}/scope-of-work/:encodedParams`,
      url: `${baseUrl}/scope-of-work/:encodedParams`,
      documentTitle: 'Scope of Work',
    },
  };
};

export const getScopeOfWorkInternalRoutes = (
  basePath: string,
  baseUrl: string
) => {
  const config = getScopeOfWorkInternalRoutesConfig(basePath, baseUrl);

  return {
    scopeOfWork: (
      <Route exact path={config.scopeOfWork.path} >
        <Page routeDefinition={config.scopeOfWork}>
          <ScopeOfWorkInternal />
        </Page>
      </Route>
    ),
  };
};

export const getScopeOfWorkUwInternalRoutesConfig = (
  basePath: string,
  baseUrl: string
) => {
  return {
    scopeOfWork: {
      path: `${basePath}/scope-of-work-uw/:encodedParams`,
      url: `${baseUrl}/scope-of-work-uw/:encodedParams`,
      documentTitle: 'Scope of Work',
    },
  };
};

export const getScopeOfWorkUwInternalRoutes = (
  basePath: string,
  baseUrl: string
) => {
  const config = getScopeOfWorkUwInternalRoutesConfig(basePath, baseUrl);

  return {
    scopeOfWork: (
      <Route exact path={config.scopeOfWork.path} >
        <Page routeDefinition={config.scopeOfWork}>
          <ScopeOfWorkInternalLinkCreate />
        </Page>
      </Route>
    ),
  };
};
