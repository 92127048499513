import {
  Grid,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  Divider,
  Button,
  Link,
  Tabs,
  Tab,
  Modal,
  ImageList,
  ImageListItem,
  Fab,
  Dialog
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { TestDataAttribute } from '@roc/ui';
import GetAppIcon from '@material-ui/icons/GetApp';
import { ArrowBackIos, Cancel, ContactMail, Phone, SubdirectoryArrowRight, MailOutlineOutlined, Height } from '@material-ui/icons';
import { Layout } from '@roc/feature-app-core';
import {
  Paper
} from '@roc/ui';
import { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { useHistory, useParams } from 'react-router-dom';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { formatDateIgnoreTimezone, sanitizeTestId } from '@roc/feature-utils';
import { numberWithCommas, NoImageAvailable } from '@roc/ui';
import { useProspectPropertiesRoutes } from '../hooks/useProspectPropertiesRoutes';
import { ListingCard } from './ListingCard';

import React, { useCallback } from 'react';
import { useHeaderHeight } from '@roc/feature-app-core';
import { useInView } from "react-intersection-observer";
import Carousel from 'react-material-ui-carousel';

const useStyles = makeStyles(theme => ({
  titleBoxClass: {
    paddingTop: '20px'
  },
  imageBox: {
    padding: '0 45px',
    paddingBottom: '5px'
  },
  barPadding: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    padding: '15px 47px',
    alignItems: 'center'
  },
  paper: {
    margin: 'auto',
    borderRadius: 0,
    display: 'flex',
    flexDirection: 'column'
  },
  icon: {
    color: 'grey'
  },
  hiddenContainer: {
    height: 0,
    overflow: 'hidden',
  },
  containerClass: {
    background: '#fff',
    width: '100%',
    marginTop: '-50px',
    '@media screen and (max-width: 500px)': {
      margin: 'auto'
    }
  },
  compImage: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    objectFit: 'cover',
    objectPostion: 'center',
    cursor: 'pointer'
  },
  pdfImage: {
    width: '100%',
    overflow: 'hidden',
    objectFit: 'cover',
    objectPostion: 'center',
  },
  closeButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    cursor: 'pointer'
  },
  responsiveFab: {
    width: '30%',
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'sticky',
    top: 0 | 90,
    zIndex: 11,
    alignSelf: 'flex-end',
    marginRight: '40px',
    '@media screen and (max-width: 500px)': {
      position: 'fixed',
      bottom: '10px',
      right: 0,
      top: 'auto',
      flexDirection: 'column',
      alignItems: 'flex-end',
      margin: 0,
      justifyContent: 'space-between',
      height: '16vh'
    }
  },
  responsiveModal:
  {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '@media screen and (max-width: 500px)': {
      width: '100vw',
      overflowY: 'scroll'
    }
  },
  responsiveGridTwoCardFix:
  {
    '@media screen and (max-width: 1000px)': {
      paddingBottom: '16px'
    }
  }
}));

export const ListingPhotoCarousel = (props) => {

  return (
    <Modal
      open={props?.store.isCarouselPopupOpen}
      onBackdropClick={() => props?.store.setCarouselIsOpen(false)}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%'
      }}
    >
      <Box style={{
        width: '80vw',
      }}>
        <Carousel
          autoPlay={false}
          cycleNavigation={false}
          animation="slide"
          navButtonsAlwaysVisible={true}
        >
          {props?.store?.selectedProperty?.images?.map((image) => {
            return (
              <Box style={{
                height: '80vh',
                backgroundColor: 'black'
              }}>
                <img src={`/api/v1/loan/public/prospectProperties/download?path=${image}`} width={'100%'} height={'100%'} style={{
                  objectFit: 'contain',
                  objectPosition: 'center',
                }} />
              </Box>
            )
          })}
        </Carousel>
      </Box>
    </Modal>
  )
};

const GridImageList = (props) => {
  const classes = useStyles();

  const openCarousel = (param) => {
    props?.store?.setCarouselIsOpen(param)
  }

  switch (props?.imageAmount) {
    case 1:
    case 2: {
      return (
        <Grid container spacing={1} style={{ height: 600, overflow: 'hidden' }}>
          <Grid item xs={12} lg={12}>
            <img src={`/api/v1/loan/public/prospectProperties/download?path=${props?.store.selectedProperty?.images[0]}`} className={props?.isDownload ? classes.pdfImage : classes.compImage} onClick={() => openCarousel(true)} />
          </Grid>
        </Grid>)
    }
    case 3: {
      return (
        <Grid container spacing={1} style={{ height: 600, overflow: 'hidden' }}>
          <Grid item xs={12} lg={9}>
            <img src={`/api/v1/loan/public/prospectProperties/download?path=${props?.store.selectedProperty?.images[0]}`} className={props?.isDownload ? classes.pdfImage : classes.compImage} onClick={() => openCarousel(true)} />
          </Grid>
          <Grid item xs={12} lg={3}>
            <Grid container spacing={1}>
              <Grid item xs={12} style={{ height: 300, overflow: 'hidden' }}>
                <img src={`/api/v1/loan/public/prospectProperties/download?path=${props?.store.selectedProperty?.images[1]}`} className={props?.isDownload ? classes.pdfImage : classes.compImage} onClick={() => openCarousel(true)} />
              </Grid>
              <Grid item xs={12} style={{ height: 300, overflow: 'hidden' }}>
                <img src={`/api/v1/loan/public/prospectProperties/download?path=${props?.store.selectedProperty?.images[2]}`} className={props?.isDownload ? classes.pdfImage : classes.compImage} onClick={() => openCarousel(true)} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>)
    }
    default: {
      return (
        <Grid container spacing={1} style={{ height: 600, overflow: 'hidden' }}>
          <Grid item xs={12} lg={9}>
            <img src={`/api/v1/loan/public/prospectProperties/download?path=${props?.store.selectedProperty?.images[0]}`} className={props?.isDownload ? classes.pdfImage : classes.compImage} onClick={() => openCarousel(true)} />
          </Grid>
          <Grid item xs={12} lg={3}>
            <Grid container spacing={1}>
              <Grid item xs={12} style={{ height: 200, overflow: 'hidden' }}>
                <img src={`/api/v1/loan/public/prospectProperties/download?path=${props?.store.selectedProperty?.images[1]}`} className={props?.isDownload ? classes.pdfImage : classes.compImage} onClick={() => openCarousel(true)} />
              </Grid>
              <Grid item xs={12} style={{ height: 200, overflow: 'hidden' }}>
                <img src={`/api/v1/loan/public/prospectProperties/download?path=${props?.store.selectedProperty?.images[2]}`} className={props?.isDownload ? classes.pdfImage : classes.compImage} onClick={() => openCarousel(true)} />
              </Grid>
              <Grid item xs={12} style={{ height: 200, overflow: 'hidden' }}>
                <img src={`/api/v1/loan/public/prospectProperties/download?path=${props?.store.selectedProperty?.images[3]}`} className={props?.isDownload ? classes.pdfImage : classes.compImage} onClick={() => openCarousel(true)} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )
    }
  }
}


export const ScrollableTabs = (props) => {

  const useCustomRef = (threshold = 1) => {
    const customRef = useRef(null);
    const { ref, inView } = useInView({
      threshold
    });
    const references = useCallback(
      (node) => {
        customRef.current = node;
        ref(node);
      },
      [ref],
    );
    return {
      customRef,
      references,
      inView,
    };
  }

  const scrollBehavior = { behavior: "auto", block: "center", inline: "nearest" };

  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [render, setRender] = useState(false);
  const headerHeight = useHeaderHeight();

  const {
    customRef: propertyDetailsRef,
    inView: propertyDetailsInView,
    references: propertyDetailsRefs,
  } = useCustomRef();
  const {
    customRef: fixFlipRef,
    inView: fixFlipInView,
    references: fixFlipRefs,
  } = useCustomRef();
  const {
    customRef: rentalRef,
    inView: rentalInView,
    references: rentalRefs,
  } = useCustomRef();
  const {
    customRef: comparablesRef,
    inView: comparablesInView,
    references: comparablesRefs,
  } = useCustomRef();

  const handleCompClick = (comp, open) => {
    props?.store?.setComp(comp);
    props?.store?.setCompModalIsOpen(open);
    setRender(!render)
  }

  useEffect(() => {
    if (propertyDetailsInView) {
      setValue(0);
    }
    else if (fixFlipInView && props?.store?.selectedProperty?.fixFlipValuation) {
      setValue(1);
    }
    else if (rentalInView && props?.store?.selectedProperty?.rentalValuation) {
      setValue(2);
    }
    if (comparablesInView && props?.store?.selectedProperty?.comps?.length > 0) {
      setValue(3);
    }
  }, [propertyDetailsInView, fixFlipInView, rentalInView, comparablesInView])

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
    if (newValue == 0) {
      propertyDetailsRef.current.scrollIntoView(scrollBehavior);
    } else if (newValue == 1 && props?.store?.selectedProperty?.fixFlipValuation) {
      fixFlipRef.current.scrollIntoView(scrollBehavior);
    } else if (newValue == 2 && props?.store?.selectedProperty?.rentalValuation) {
      rentalRef.current.scrollIntoView(scrollBehavior);
    } else if (newValue == 3 && props?.store?.selectedProperty?.comps?.length > 0) {
      comparablesRef.current.scrollIntoView(scrollBehavior);
    }
  };
  return (
    <>
      <Layout containerClass={classes.containerClass}>
        <Box display={'flex'} className={classes.containerClass}>
          <Box flex={1} width="100%">
            <Box m={2} pt={2} pb={1} position='sticky' bgcolor={'#fff'} top={headerHeight} zIndex={10}>
              <Tabs value={value} onChange={handleChange} variant="scrollable" scrollButtons="auto">
                <Tab label="Property Details" value={0} />
                {props?.store?.selectedProperty?.fixFlipValuation && <Tab label="Fix & Flip Strategy" value={1} />}
                {props?.store?.selectedProperty?.rentalValuation && <Tab label="Rental Strategy" value={2} />}
                {props?.store?.selectedProperty?.comps?.length > 0 && <Tab label="ARV Comparables" value={3} />}
              </Tabs>
            </Box>
            <Box m={2}>
              <div ref={propertyDetailsRefs}>
                <Grid style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }} >
                  <Grid item xs={12} md={6} className={classes.responsiveGridTwoCardFix}>
                    <Paper title="Property Details" style={{ height: '100%', width: '98%' }} className={classes.responsiveGridTwoCardFix}>
                      <List>
                        {listItem('Property Type', props?.store?.selectedProperty?.propertyType ? props?.store?.selectedProperty?.propertyType : '--')}
                        {divider}
                        {listItem('Bedrooms', props?.store?.selectedProperty?.bedrooms ? props?.store?.selectedProperty?.bedrooms : '--')}
                        {divider}
                        {listItem('Bathrooms', props?.store?.selectedProperty?.bathrooms ? props?.store?.selectedProperty?.bathrooms : '--')}
                        {divider}
                        {listItem('Est. Sq. Ft.', props?.store?.selectedProperty?.squareFootage ? numberWithCommas(props?.store?.selectedProperty?.squareFootage) : '--')}
                        {divider}
                        {listItem('Finished Basement/Attic', props?.store?.selectedProperty?.basementFinished ? props?.store?.selectedProperty?.basementFinished : '--')}
                      </List>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Paper title='Overview' style={{ height: '100%', width: '98%' }}>
                      <List>
                        {listItem('Photo Capture / Inspection Link', props?.store?.selectedProperty?.photoInspectionLink ? props?.store?.selectedProperty?.photoInspectionLink : '--', props?.store?.selectedProperty?.photoInspectionLink)}
                        {divider}
                        {listItem('Available Until', props?.store?.selectedProperty?.availableUntil ? formatDateIgnoreTimezone(props?.store?.selectedProperty?.availableUntil) : '--')}
                        {divider}
                        {listItem('Contact Information', ' ')}
                        {listItem('Contact Name', 'Chad Belcher', null, null, null, <SubdirectoryArrowRight />)}
                        {listItem('Contact Email', 'chad.belcher@really.homes', null, null, null, <SubdirectoryArrowRight />)}
                        {listItem('Contact Phone', '407-951-2423', null, null, null, <SubdirectoryArrowRight />)}
                      </List>
                    </Paper>
                  </Grid>
                </Grid>
              </div>
            </Box>
            {props?.store?.selectedProperty?.fixFlipValuation && <Box m={2}>
              <div ref={fixFlipRefs}>
                <Grid item xs={12} md={6}>
                  <Paper title="Fix & Flip Strategy" style={{ width: '98%' }}>
                    <List>
                      {listItem('Asking Price', `${!props?.store?.selectedProperty?.fixFlipValuation?.askingPrice ? '--' : '$' + numberWithCommas(props?.store?.selectedProperty?.fixFlipValuation?.askingPrice)}`)}
                      {divider}
                      {listItem('Estimated Renovation Budget', `${!props?.store?.selectedProperty?.fixFlipValuation?.estimatedRenoBudget ? '--' : '$' + numberWithCommas(props?.store?.selectedProperty?.fixFlipValuation?.estimatedRenoBudget)}`)}
                      {divider}
                      {listItem('Potential After Repair Value', `${!props?.store?.selectedProperty?.fixFlipValuation?.estimatedArv ? '--' : '$' + numberWithCommas(props?.store?.selectedProperty?.fixFlipValuation?.estimatedArv)}`)}
                      {divider}
                      {listItem('Potential Borrower ROI', `${!props?.store?.selectedProperty?.fixFlipValuation?.potentialBorrowerRoi ? '--' : props?.store?.selectedProperty?.fixFlipValuation?.potentialBorrowerRoi + '%'}`)}
                      {divider}
                      {listItem('Flip Assumptions', props?.store?.selectedProperty?.flipAssumptions ? props?.store?.selectedProperty?.flipAssumptions : '--', null, null, true)}
                    </List>
                  </Paper>
                </Grid>
              </div>
            </Box>}
            {props?.store?.selectedProperty?.rentalValuation && <Box m={2}>
              <div ref={rentalRefs}>
                <Grid item xs={12} md={6}>
                  <Paper title="Rental Strategy" style={{ width: '98%' }}>
                    <List>
                      {listItem('Potential Monthly Rent', `${!props?.store?.selectedProperty?.rentalValuation?.estimatedMonthlyRent ? '--' : '$' + numberWithCommas(props?.store?.selectedProperty?.rentalValuation?.estimatedMonthlyRent)}`)}
                      {divider}
                      {listItem('Potential DSCR', `${!props?.store?.selectedProperty?.rentalValuation?.potentialDscr ? '--' : props?.store?.selectedProperty?.rentalValuation?.potentialDscr}`)}
                      {divider}
                      {listItem('Est. Property Taxes', `${!props?.store?.selectedProperty?.rentalValuation?.annualPropertyTaxes ? '--' : numberWithCommas(props?.store?.selectedProperty?.rentalValuation?.annualPropertyTaxes)}`)}
                      {divider}
                      {listItem('Est. Annual Insurance', `${!props?.store?.selectedProperty?.rentalValuation?.annualInsurance ? '--' : numberWithCommas(props?.store?.selectedProperty?.rentalValuation?.annualInsurance)}`)}
                      {divider}
                      {listItem('HOA', `${!props?.store?.selectedProperty?.rentalValuation?.annualHoa ? '--' : numberWithCommas(props?.store?.selectedProperty?.rentalValuation?.annualHoa)}`)}
                      {divider}
                      {listItem('Tenant Occupied?', `${!props?.store?.selectedProperty?.rentalValuation?.tenantOccupied ? '--' : props?.store?.selectedProperty?.rentalValuation?.tenantOccupied}`)}
                      {divider}
                      {listItem('Rental Assumptions', props?.store?.selectedProperty?.rentalAssumptions ? props?.store?.selectedProperty?.rentalAssumptions : '--', null, null, true)}
                    </List>
                  </Paper>
                </Grid>
              </div>
            </Box>}
            {props?.store?.selectedProperty?.comps?.length > 0 && <Box m={2}>
              <div ref={comparablesRefs}>
                <Paper title="ARV Comparables">
                  <Grid container spacing={2} style={{ padding: '8px 16px' }}>
                    {props.store?.selectedProperty?.comps?.map((comp, index) => {
                      return (
                        <>
                          <Grid item xs={12} md={6} sm={6} lg={4}>
                            <a onClick={() => handleCompClick(comp, true)} >
                              <ListingCard property={comp} isComp={true} />
                            </a>
                          </Grid>
                        </>)
                    })}
                  </Grid>
                </Paper>
              </div>
            </Box>}
          </Box>
        </Box>
      </Layout >
      <Dialog
        open={props?.store?.compModalIsOpen}
        onBackdropClick={() => handleCompClick(null, false)}
        onClose={() => handleCompClick(null, false)}
        className={classes.responsiveModal}
        maxWidth={'md'}
      >
        <Paper style={{ borderRadius: 0, position: 'relative', padding: '15px', width: '100%' }}>
          <Grid style={{ display: 'flex', flexWrap: 'wrap', width: '100%' }} xs={12}>
            <Grid xs={12} md={6}>
              <Typography variant='h6' style={{ fontWeight: 'bold', margin: '20px', textDecoration: 'underline' }}>{props?.store?.selectedComp?.address}</Typography>
              <List>
                {listItem('Property Type', `${!props?.store?.selectedComp?.propertyType ? '--' : props?.store?.selectedComp?.propertyType}`)}
                {divider}
                {listItem('Bedrooms', `${!props?.store?.selectedComp?.bedrooms ? '--' : props?.store?.selectedComp?.bedrooms}`)}
                {divider}
                {listItem('Bathrooms', `${!props?.store?.selectedComp?.bathrooms ? '--' : props?.store?.selectedComp?.bathrooms}`)}
                {divider}
                {listItem('Basement', `${!props?.store?.selectedComp?.basementFinished ? '--' : props?.store?.selectedComp?.basementFinished}`)}
                {divider}
                {listItem('Sq. Ft.', `${!props?.store?.selectedComp?.squareFootage ? '--' : numberWithCommas(props?.store?.selectedComp?.squareFootage)}`)}
                {divider}
                {listItem('Stories', `${!props?.store?.selectedComp?.stories ? '--' : props?.store?.selectedComp?.stories}`)}
                {divider}
                {listItem('Year', `${!props?.store?.selectedComp?.yearBuilt ? '--' : formatDateIgnoreTimezone(props?.store?.selectedComp?.yearBuilt)}`)}
                {divider}
                {listItem('Renovated', `${!props?.store?.selectedComp?.renovated ? '--' : props?.store?.selectedComp?.renovated}`)}
                {divider}
                {listItem('Distance from Subject Property (mi.)', `${!props?.store?.selectedComp?.proximityInMiles ? '--' : numberWithCommas(props?.store?.selectedComp?.proximityInMiles)}`)}
                {divider}
                {listItem('Sold Price', `${!props?.store?.selectedComp?.soldPrice ? '--' : '$' + numberWithCommas(props?.store?.selectedComp?.soldPrice)}`)}
                {divider}
                {listItem('Sold Date', `${!props?.store?.selectedComp?.soldDate ? '--' : formatDateIgnoreTimezone(props?.store?.selectedComp?.soldDate)}`)}
              </List>
            </Grid>
            <Grid xs={12} md={6}>
              {props?.store?.selectedComp?.photoUrl ? <ImageList rowHeight={500} style={{ padding: '10px' }}>
                <ImageListItem cols={2}>
                  <img src={props?.store?.selectedComp?.photoUrl} width='100%' style={{ objectFit: 'contain', objectPosition: 'center' }} />
                </ImageListItem>
              </ImageList> :
                <ImageList rowHeight={500} style={{ padding: '10px' }}>
                  <ImageListItem cols={2}>
                    <NoImageAvailable />
                  </ImageListItem>
                </ImageList>}
            </Grid>
          </Grid>
          <Cancel className={classes.closeButton} onClick={() => handleCompClick(null, false)} />
        </Paper>
      </Dialog>
    </>
  );
};


const divider = <Divider variant="middle" component="li" />;

export const listItem = (label, value, link = null, fileExtension = null, largeText = null, icon = null) => {

  if (link && (!link.includes('https://') || !link.includes('http://'))) {
    link = 'https://' + link;
  }
  const testId = sanitizeTestId(label);
  return (
    <ListItem>
      <ListItemText>
        <Box display="flex">
          {icon && <Box ml={1}>{icon}</Box>}
          <Box flexGrow={1} mr={1}>
            {label}
          </Box>
          <TestDataAttribute id={testId}>
            {!largeText ?
              <Box textAlign={'right'}>{link ? <Link download={fileExtension ? label + '.' + fileExtension : null} href={link} target={!fileExtension && link ? '_blank' : null} color={'primary'} underline={'always'}>External Link</Link> : value ? value : '--'}</Box>
              : <Box textAlign='left' width={'70%'}>{value}</Box>
            }
          </TestDataAttribute>
        </Box>
      </ListItemText>
    </ListItem>
  );
};

export const PropertyLayoutHeader = observer((props) => {
  const classes = useStyles();
  const { prospectPropertiesRoutesConfig } = useProspectPropertiesRoutes();
  const { push } = useHistory();

  return (
    <>
      <Box className={classes.titleBoxClass}>
        {!props?.isDownload && <Box display={'flex'} className={classes.barPadding} alignItems='center'>
          <Button onClick={() => push(prospectPropertiesRoutesConfig.prospectProperties.url)} variant='outlined'>
            <ArrowBackIos fontSize='small' className={classes.icon} />
            Back
          </Button>
          <Button variant='text' onClick={props?.handleDownloadClick} startIcon={<GetAppIcon />}>Download as PDF</Button>
        </Box>}
        <Box className={classes.imageBox}>
          {props?.store.selectedProperty?.images?.length > 0 ? <GridImageList store={props?.store} isDownload={props?.isDownload} imageAmount={props?.store.selectedProperty?.images?.length} /> : <></>}
        </Box>
      </Box>
    </>
  )
})


export const PropertyLayout = observer((props) => {
  const classes = useStyles();

  return (
    <Layout>
      <Grid xs={12}>
        <Paper className={classes.paper} >
          <PropertyLayoutHeader store={props?.store} handleDownloadClick={props?.handleDownloadClick} isDownload={props?.isDownload} />
          <Box className={classes.barPadding}>
            <Box width={'50%'}>
              <TestDataAttribute id={sanitizeTestId('askingprice')}>
                <Typography variant='h4'>{!props?.store?.selectedProperty?.askingPrice ? 'Price Not available' : `$${numberWithCommas(props?.store?.selectedProperty?.askingPrice)}`}</Typography>
              </TestDataAttribute>
              <TestDataAttribute id={sanitizeTestId('streetaddress')}>
                <Typography style={{ fontSize: '15px' }} >{`${props?.store?.selectedProperty?.address}, ${props?.store?.selectedProperty?.city}, ${props?.store?.selectedProperty?.state} ${props?.store?.selectedProperty?.zipCode}`}</Typography>
              </TestDataAttribute>
            </Box>
            <Box sx={{ textAlign: 'right', width: '50%' }}>
              <Typography style={{ color: 'gray' }} variant='subtitle1'>Available Until</Typography>
              <TestDataAttribute id={sanitizeTestId('availableunit')}>
                <Typography style={{ color: 'gray', fontSize: '25px', fontWeight: 'bold' }}>{!props?.store?.selectedProperty?.availableUntil ? 'Not available' : `${formatDateIgnoreTimezone(props?.store?.selectedProperty?.availableUntil)}`}</Typography>
              </TestDataAttribute>
            </Box>
          </Box>
          <Box className={classes.responsiveFab}>
            <Fab color="primary" style={{ margin: '0 5px' }}
              onClick={() => window.open('tel:4079512423')}>
              <Phone />
            </Fab>
            <Fab color="primary" style={{ margin: '0 5px' }}
              onClick={() => window.open('mailto:chad.belcher@really.homes')}>
              <MailOutlineOutlined />
            </Fab>
          </Box>
          <ScrollableTabs store={props?.store} />
        </Paper>
      </Grid >
    </Layout>
  )
});

export const PropertySummary = observer(({ store, isDownload }) => {
  const classes = useStyles();
  const prospectPropertyEl = useRef(null);
  const { propertyId } = useParams<{ propertyId: string }>();
  const { propertyType } = useParams<{ propertyType: string }>();

  useEffect(() => {
    !isDownload && store.fetchProspectProperty(propertyId, propertyType);
  }, []);

  let currentDisabled;

  const handleDownloadClick = () => {
    currentDisabled = store.isDisabled;
    store.setIsDownloading(true);
    store.setIsDisabled(false);
  };

  const afterConvertToCanvas = (canvas, domConvertedEl) => {
    domConvertedEl.classList.remove('in-process');

    const pdf = new jsPDF(
      domConvertedEl.offsetWidth < domConvertedEl.offsetHeight ? 'p' : 'l',
      'pt',
      [domConvertedEl.offsetWidth, domConvertedEl.offsetHeight]
    );
    const {
      internal: { pageSize },
    } = pdf;

    pdf.addImage(
      canvas.toDataURL('image/png'),
      'PNG',
      0,
      0,
      pageSize.getWidth(),
      pageSize.getHeight(),
      undefined,
      'FAST'
    );
    pdf.save(`Prospect_Property-${store?.selectedProperty?.address}, ${store?.selectedProperty?.city}, ${store?.selectedProperty?.state} ${store?.selectedProperty?.zipCode}.pdf`);
    store.setIsDownloading(false);
    store.setIsDisabled(currentDisabled);

  };

  useEffect(() => { }, [store.isCarouselPopupOpen])

  useEffect(() => {
    if (store.isDownloading) {
      const { current } = prospectPropertyEl;
      if (!current) {
        return;
      }

      current.classList.add('in-process');
      setTimeout(
        () =>
          html2canvas(current, { scrollX: 0, scrollY: 0, useCORS: true, allowTaint: false }).then(canvas => {
            afterConvertToCanvas(canvas, current);
          }),
        0
      );
    }
  }, [store.isDownloading]);

  return (
    <>
      <Box display={'block'} width={'100%'} position={'relative'}>
        <Grid xs={12} direction={'column'}>
          <PropertyLayout store={store} handleDownloadClick={handleDownloadClick} />
          <ListingPhotoCarousel store={store} />
        </Grid>
        <div className={classes.hiddenContainer}>
          <div ref={prospectPropertyEl}>
            <Layout>
              <Grid xs={12}>
                <Paper className={classes.paper} square>
                  <PropertyLayout store={store} handleDownloadClick={handleDownloadClick} isDownload />
                </Paper>
              </Grid >
            </Layout>
          </div>
        </div>
      </Box>
    </>
  );
})