import { Box } from '@material-ui/core';
import { Page } from '@roc/feature-app-core';
import { Route } from 'react-router';
import { EmbeddedFrameBoundaries } from '@roc/ui';
import { LoanClosingCondition } from 'libs/feature-documents/src/documents/components/documentForms/loanClosingCondition/loanClosingCondition';

export const getLoanClosingConditionRoutesConfig = (
  basePath: string,
  baseUrl: string,
) => {
  return {
    closingCondition: {
      path: `${basePath}/loan-closing-condition`,
      url: `${baseUrl}/loan-closing-condition`,
      documentTitle: 'Loan Closing Condition'
    },
  };
};

export const getLoanClosingConditionRoutes = (
  basePath: string,
  baseUrl: string,
  loanDocumentId: string,
  closingConditionAdmin: string,
  closingConditionCommittee: string
) => {
  const config = getLoanClosingConditionRoutesConfig(basePath, baseUrl);
  return {
    closingCondition: (
      <Route exact path={config.closingCondition.path}>
        <Page routeDefinition={config.closingCondition}>
          <LoanClosingCondition
            loanDocumentId={loanDocumentId}
            handleClose={() => { }}
            closingConditionAdmin={closingConditionAdmin}
            closingConditionCommittee={closingConditionCommittee}
            internal={true}
          />
        </Page>
      </Route>
    ),
  };
}