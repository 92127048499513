import { Service } from '@roc/feature-app-core';

const url = {
  FIND_BORROWERS: '/salesforce-ui/api/v1/salesforce/borrower/findBorrowers',
};

export class FindBorrowersService extends Service {
  findBorrowers(lat: number, lng: number, state: string, radius: number) {
    return this.get(url.FIND_BORROWERS, {
      lat,
      lng,
      state,
      distanceKM: radius,
    });
  }
}
