import { Box } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { observer } from 'mobx-react';
import { LoanTermsCard } from './loanTermsSection/loanTermsCard';
import { BorrowerEntityCard } from './borrowerSection/borrowerEntity/borrowerEntityCard';
import { BorrowersCard } from './borrowerSection/borrower/borrowersCard';
import { useLoanSummaryStore } from '../hooks/useLoanSummaryStore';
import { generalStatsColsTerm, loanTermsTermColsStb } from '../utils/ColumnsTermLoanSummary';
import { borrowerEntityColsBridge } from '../utils/ColumnsBridgeLoanSummary';
import { generalStatsColsStb } from '../utils/ColumnsStbLoanSummary';
import { CollateralsCard } from './collateralsSection/collateralsCard';


export const LoanDetailsOverviewTermLoans = observer(({ loanDetails }) => {
  const { loanSummaryStore } = useLoanSummaryStore();
  return (
    <>
      <Box m={2}>
        <Grid container>
          <Grid item xs={12}>
            <LoanTermsCard loanDetails={loanDetails} columns={loanSummaryStore.transformData(loanTermsTermColsStb, 6, loanDetails)} title="Loan Terms" />
          </Grid>
        </Grid>
      </Box>

      <Box m={2}>
        <Grid container>
          <Grid item xs={12}>
            <BorrowerEntityCard columns={loanSummaryStore.transformDataForBorrowerEntity(borrowerEntityColsBridge, 3, loanDetails)} />
          </Grid>
        </Grid>
      </Box>
      <Box m={2}>
        <Grid container>
          <Grid item xs={12}>
            <BorrowersCard loanDetails={loanDetails} />
          </Grid>
        </Grid>
      </Box>
      <Box m={2}>
        <Grid container>
          <Grid item xs={12}>
            <Grid item xs={12}>
              <CollateralsCard loanDetails={loanDetails} />
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box m={2}>
        <Grid container>
          <Grid item xs={12}>
            <Grid item xs={12}>
              <LoanTermsCard loanDetails={loanDetails} columns={loanSummaryStore.transformData(generalStatsColsTerm, 3, loanDetails)} title="General Stats" />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
});