import { GlobalStore } from '@roc/client-portal-shared/stores';
import { flow, makeObservable, observable } from 'mobx';
import { ApiResponse } from '../../../services';
import { FindLendersService } from '../services/findLendersService';

export class FindLendersStore {
  globalStore: GlobalStore;
  findLendersService: FindLendersService;
  lenders = [];

  constructor(globalStore) {
    this.globalStore = globalStore;
    this.findLendersService = new FindLendersService();
    makeObservable(this, {
      lenders: observable,
      findLenders: flow,
    });
  }

  *findLenders(address, radius: number, sameState: boolean) {
    try {
      const { lat, lng, state } = this.parseAddressValues(address);
      const response: ApiResponse = yield this.findLendersService.findLenders(
        lat,
        lng,
        sameState ? state : '',
        radius
      );
      this.lenders = response.data;
    } catch (error) {
      console.log({ error });
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Unable to retrieve the data',
      });
    }
  }

  private parseAddressValues(value) {
    const {
      geometry: { location },
      address_components,
    } = value;
    const state = address_components.find(item =>
      item.types.includes('administrative_area_level_1')
    )?.short_name;
    return {
      lat: location.lat(),
      lng: location.lng(),
      state,
    };
  }
}
