const mapper = {
  Foundation: {
    helperText: 'Please provide the cost and details of the following items',
    sections: [
      {
        title: 'Concrete Footings',
        fields: [
          {
            label: 'Amount',
            name: 'concreteFootingsAmount',
          },
        ],
      },
      {
        title: 'Special Foundations',
        tooltip:
          'Piles, Caons, Foundation Walls, Anchors, Underpinning, Raft, Grade Beams',
        fields: [
          {
            label: 'Piles',
            name: 'piles',
          },
          {
            label: 'Casons',
            name: 'casons',
          },
          {
            label: 'Foundation Walls',
            name: 'foundationWalls',
          },
          {
            label: 'Grade Beams',
            name: 'gradeBeams',
          },
          {
            label: 'Raft',
            name: 'raft',
          },
          {
            label: 'Underpinning',
            name: 'underpinning',
          },
        ],
      },
      {
        title: 'Other',
        tooltip: 'Any other relevant items related to this category',
        fields: [
          {
            label: 'Amount',
            name: 'otherAmount',
          },
        ],
      },
    ],
  },
  'Basement/Cellar Wall Work, Interior & Exterior': {
    helperText: 'Please provide the cost and details of the following items',
    sections: [
      {
        title: 'Basement Walls',
        fields: [
          {
            label: 'New CMU, Vapor Barrier, Waterproofing, Interior Finish',
            name: 'interiorFinish',
          },
          {
            label: 'Repair Existing',
            name: 'repairExisting',
          },
          {
            label: 'Finish Exterior w/ Furring, Wallboard, Paint',
            name: 'exteriorFinish',
          },
        ],
      },
      {
        title: 'Other',
        tooltip: 'Any other relevant items related to this category',
        fields: [
          {
            label: 'Amount',
            name: 'otherAmount',
          },
        ],
      },
    ],
  },
  'Slab on Grade': {
    helperText: 'Please provide the cost and details of the following items',
    sections: [
      {
        title: 'Standard Slabs-On-Grade',
        fields: [
          {
            label: 'Amount',
            name: 'standardSlabOnGradeAmount',
          },
        ],
      },
      {
        title: 'Structural Slabs-On-Grade',
        fields: [
          {
            label: 'Amount',
            name: 'structuralSlabOnGradeAmount',
          },
        ],
      },
      {
        title: 'Other',
        tooltip: 'Any other relevant items related to this category',
        fields: [
          {
            label: 'Amount',
            name: 'otherAmount',
          },
        ],
      },
    ],
  },
  'Water & Gas Mitigation': {
    helperText: 'Please provide the cost and details of the following items',
    sections: [
      {
        title: 'Building Subdrainage',
        fields: [
          {
            label: 'Amount',
            name: 'buildingSubdrainageAmount',
          },
        ],
      },
      {
        title: 'Off-Gassing Mitigation',
        fields: [
          {
            label: 'Amount',
            name: 'offGassingMitigationAmount',
          },
        ],
      },
      {
        title: 'Other',
        tooltip: 'Any other relevant items related to this category',
        fields: [
          {
            label: 'Amount',
            name: 'otherAmount',
          },
        ],
      },
    ],
  },
  'Substructure Related Activities': {
    helperText: 'Please provide the cost and details of the following items',
    sections: [
      {
        title: 'Substructure Excavation',
        fields: [
          {
            label: 'Amount',
            name: 'substructureExcavationAmount',
          },
        ],
      },
      {
        title: 'Construction Dewatering',
        fields: [
          {
            label: 'Amount',
            name: 'constructionDewateringAmount',
          },
        ],
      },
      {
        title: 'Excavation Support',
        fields: [
          {
            label: 'Amount',
            name: 'excavationSupportAmount',
          },
        ],
      },
      {
        title: 'Soil Treatment',
        fields: [
          {
            label: 'Amount',
            name: 'soilTreatment',
          },
        ],
      },
      {
        title: 'Other',
        tooltip: 'Any other relevant items related to this category',
        fields: [
          {
            label: 'Amount',
            name: 'otherAmount',
          },
        ],
      },
    ],
  },
};

export default mapper;
