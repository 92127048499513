import { Service } from '@roc/feature-app-core';

const url = {
  GET_BORROWER_LOAN_APPLICATIONS_GRID: '/api/v1/loan/loanApplications/getMyLoanApplicationsGrid',
};

export class BorrowerLoanApplicationsService extends Service {
  getBorrowerLoansGrid(
    page: number,
    pageSize: number,
    sortDir: string,
    sortBy: string,
    filters: string,
    dropdownFilters: string
  ) {
    return this.get(
      url.GET_BORROWER_LOAN_APPLICATIONS_GRID,
      {
        page,
        pageSize,
        sortDir,
        sortBy,
        filters,
        dropdownFilters,
      },
      {
        disableGlobalLoading: true,
      }
    );
  }
}
