import { action, flow, makeObservable, observable } from 'mobx';
import { GlobalStore, ApiResponse, GridStore } from '@roc/feature-app-core';
import { LoanForeclosureService } from '../services/loanForeclosureService';
import { LedgerNoteStore } from './ledgerNoteStore';
import { LoanDqReasonData } from '../types/loanDqReasonData';
import { LoanDqRepurchaseRequestedData } from '../types/loanDqRepurchaseData';
import { LoanDqFollowUpData } from '../types/loanDqFollowUpData';
import { RealEstateOwnedDetailsStore } from './realEstateOwnedDetailsStore';
import { SoldAtForeclosureDetailsStore } from './soldAtForeclosureDetailsStore';
import { LoanValuationFormStore } from './loanValuationFormStore';

export class ForeclosureSummaryStore {
  globalStore: GlobalStore;
  ledgerNoteStore: LedgerNoteStore;
  loanForeclosureService: LoanForeclosureService;
  foreclosureSummary: any;
  realEstateOwnedDetailsStore: RealEstateOwnedDetailsStore;
  soldAtForeclosureDetailsStore: SoldAtForeclosureDetailsStore;
  loanValuationStore: LoanValuationFormStore;

  constructor(globalStore: GlobalStore) {
    this.globalStore = globalStore;
    this.loanForeclosureService = new LoanForeclosureService();
    this.ledgerNoteStore = new LedgerNoteStore(globalStore);
    this.realEstateOwnedDetailsStore = new RealEstateOwnedDetailsStore(globalStore, this.loanForeclosureService);
    this.soldAtForeclosureDetailsStore = new SoldAtForeclosureDetailsStore(globalStore, this.loanForeclosureService);
    this.loanValuationStore = new LoanValuationFormStore(globalStore);
    makeObservable(this, {
      foreclosureSummary: observable,
      getSummary: flow,
      saveForeclosureReason: flow,
      saveForeclosureFollowUp: flow,
      saveForeclosureRepurchaseRequested: flow,
    });
  }

  *getSummary(loanId: string) {
    try {
      const response: ApiResponse = yield this.loanForeclosureService.getSummary(loanId);
      this.foreclosureSummary = response.data;
    } catch (err) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while getting loan foreclosure summary.',
      });
    }
  };

  *saveForeclosureReason(payload: LoanDqReasonData) {
    try {
      yield this.loanForeclosureService.saveForeclosureReason(payload);
      this.foreclosureSummary.foreclosureReason = payload.delinquencyStatusComment;
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Foreclosure Reason updated successfully.',
      });
    } catch (err) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while updating Foreclosure Reason.',
      });
    }
  }

  *saveForeclosureRepurchaseRequested(payload: LoanDqRepurchaseRequestedData) {
    try {
      yield this.loanForeclosureService.saveForeclosureRepurchaseRequested(payload);
      this.foreclosureSummary.repurchaseRequested = payload.repurchaseRequested;
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Foreclosure Repurchase Requested updated successfully.',
      });
    } catch (err) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while updating Foreclosure Repurchase Requested.',
      });
    }
  }

  *saveForeclosureFollowUp(payload: LoanDqFollowUpData) {
    try {
      yield this.loanForeclosureService.saveForeclosureFollowUp(payload);
      this.foreclosureSummary.followUpDate = payload.followUpDate;
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Foreclosure Follow up date updated successfully.',
      });
    } catch (err) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while updating Foreclosure Follow up date.',
      });
    }
  }
}

export default ForeclosureSummaryStore;