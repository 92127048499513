import { StoreContext } from '@roc/feature-app-core';
import { useContext } from 'react';
import { ReceivablesDashboardStore } from '../stores/receivablesDashboardStore';
import { ReceivablesBillsStore } from '../stores/receivablesBillsStore';

export const useReceivablesDashboardStore = () =>
  useContext<{
    receivablesDashboardStore: ReceivablesDashboardStore;
    receivablesBillsStore: ReceivablesBillsStore;
  }>(StoreContext);
