import { ApiResponse, FormStore, GlobalStore, LogBaseService } from "@roc/feature-app-core";
import { PropertyDataCollectionService } from "@roc/feature-properties";
import { flow, makeObservable, observable } from "mobx";
import { ValuationReviewReportBaseService } from "../services/valuationReviewReportService";
import { downloadDocument, parseAddress } from "@roc/feature-utils";

const valuationReviewForm = {
  fields: {
    internalValue: {
      value: '',
      error: null,
      rule: '',
    },
    comments: {
      value: '',
      error: null,
      rule: '',
    },
    address: {
      value: '',
      error: null,
      rule: '',
    },
    __isAddressInCorrectFormat__: {
      value: true,
      error: null,
      rule: 'accepted',
      message: 'Invalid Address Format',
    },
    streetNumber: {
      value: '',
      error: null,
      rule: '',
    },
    streetName: {
      value: '',
      error: null,
      rule: '',
    },
    city: {
      value: '',
      error: null,
      rule: '',
    },
    state: {
      value: '',
      error: null,
      rule: '',
    },
    zipCode: {
      value: '',
      error: null,
      rule: '',
    },
    latitude: {
      value: 0,
      error: null,
      rule: '',
    },
    longitude: {
      value: 0,
      error: null,
      rule: '',
    },
    aptNumber: {
      value: '',
      error: null,
      rule: '',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export class ValuationReviewReportStore extends FormStore {
  globalStore: GlobalStore;
  propertyDataCollectionService: PropertyDataCollectionService;
  logService: LogBaseService;
  protected valuationReviewReportService: ValuationReviewReportBaseService;
  valuationReviewInfo: any;
  totalBaths: number;
  propertyActivityInfo: any;

  constructor(globalStore: GlobalStore) {
    super(valuationReviewForm, globalStore);
    this.globalStore = globalStore;
    this.propertyDataCollectionService = new PropertyDataCollectionService();
    this.setDefaults();

    makeObservable(this, {
      getAppraisalReviewInformation: flow,
      getPopertyActivityList: flow,
      downloadAppraisalReport: flow,
      getLiquidityDocument: flow,
      saveValuationDetails: flow,
      valuationReviewInfo: observable,
      propertyActivityInfo: observable,
    })
  }

  *getAppraisalReviewInformation(loanId: number, propertyId: number) {
    const response: ApiResponse = yield this.valuationReviewReportService.getValuationReviewInformationByLoanIdAndPropertyId(loanId, propertyId);
    this.valuationReviewInfo = response.data;
    const valuationReviewData = this.valuationReviewInfo.valuationReviewData;
    this.form.fields.comments.value = valuationReviewData.comments;
    this.form.fields.internalValue.value = valuationReviewData.internalValue;
    this.form.fields.address.value = valuationReviewData.comparisonAddress.streetNumber === null ? valuationReviewData.comparisonAddress.streetName : valuationReviewData.comparisonAddress.streetNumber + " " + valuationReviewData.comparisonAddress.streetName;
    this.form.fields.streetNumber.value = valuationReviewData.comparisonAddress.streetNumber;
    this.form.fields.streetName.value = valuationReviewData.comparisonAddress.streetName;
    this.form.fields.aptNumber.value = valuationReviewData.comparisonAddress.aptNumber;
    this.form.fields.city.value = valuationReviewData.comparisonAddress.city;
    this.form.fields.state.value = valuationReviewData.comparisonAddress.state;
    this.form.fields.zipCode.value = valuationReviewData.comparisonAddress.zipCode;
    this.form.fields.latitude.value = valuationReviewData.comparisonAddress.latitude;
    this.form.fields.longitude.value = valuationReviewData.comparisonAddress.longitude;
  }

  *getPopertyActivityList(propertyId: number) {
    const response: ApiResponse = yield this.valuationReviewReportService.getPropertyActivityByPropertyId(propertyId);
    this.propertyActivityInfo = response.data;
  }

  *downloadAppraisalReport(propertyId: number) {
    const response: ApiResponse = yield this.valuationReviewReportService.downloadAppraisalReport(propertyId);
    downloadDocument(response?.data, response?.headers, 'download');
  }

  *getLiquidityDocument(propertyId: number, loanId: number) {
    const response: ApiResponse = yield this.valuationReviewReportService.getLiquidityDocument(propertyId, loanId);
    downloadDocument(response?.data, response?.headers, 'download');
  }

  *saveValuationDetails(propertyId: number, loanId: number) {
    try {
      const valuationDetails = this.getFlattenedValues() as any;

      const data = {
        propertyId,
        loanId,
        comments: valuationDetails.comments,
        internalValue: valuationDetails.internalValue,
        comparisonAddress: {
          streetNumber: valuationDetails.streetNumber,
          streetName: valuationDetails.streetName,
          aptNumber: valuationDetails.aptNumber,
          city: valuationDetails.city,
          state: valuationDetails.state,
          zipCode: valuationDetails.zipCode,
          latitude: valuationDetails.latitude,
          longitude: valuationDetails.longitude
        }
      }
      const response: ApiResponse = yield this.valuationReviewReportService.saveValuationDetails(data);
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Valuation Details saved successfully.',
      });
    }
    catch (error) {
      console.log(error);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'We had an issue while saving Valuation Details.',
      });
    }
  }

  setDefaults() {
    this.valuationReviewInfo = {};
    this.totalBaths = 0;
    this.propertyActivityInfo = null;
    this.reset();
  }

  saveAddressField(address: string, addressDetails, geometryDetails) {
    const parsedAddress = parseAddress(
      address,
      addressDetails,
      geometryDetails
    );
    const streetAddress = parsedAddress.street_number === null ? parsedAddress.street : parsedAddress.street_number + " " + parsedAddress.street;
    this.onFieldChange('address', streetAddress);
    this.onFieldChange('streetNumber', parsedAddress.street_number);
    this.onFieldChange('streetName', parsedAddress.street);
    this.onFieldChange('city', parsedAddress.city);
    this.onFieldChange('state', parsedAddress.state);
    this.onFieldChange('zipCode', parsedAddress.zip);
    this.onFieldChange('latitude', parsedAddress.latitude);
    this.onFieldChange('longitude', parsedAddress.longitude);
    this.onFieldChange('aptNumber', parsedAddress.aptNumber);

    const addressInCorrectFormat = true;
    this.onFieldChange('__isAddressInCorrectFormat__', addressInCorrectFormat);
  }
}
