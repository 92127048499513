import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { StandardDialog } from '@roc/ui';
import { usePayoffRequestStore } from '../hooks/usePayoffRequestStore';
import PayoffRetractForm from './payoffRetractForm';

interface PayoffRetractDialogProps {
  open: boolean;
  onClose: () => void;
  loanId: string;
}

export const PayoffRetractDialog = observer(
  ({ open, onClose, loanId }: PayoffRetractDialogProps) => {
    const { payoffRequestStore } = usePayoffRequestStore();
    useEffect(() => {
      return () => payoffRequestStore.reset();
    }, []);

    return (<>
      <StandardDialog
        open={open}
        title="Retract Payoff"
        maxWidth="sm"
        handleClose={onClose}
        dialogContent={
          <PayoffRetractForm loanId={loanId} onClose={onClose} />
        }
      />

    </>
    );
  }
);
