import { Service } from '@roc/feature-app-core';

const url = {
  CHANGE_PASSWORD: '/api/v1/user/changePassword',
};

export class ChangePasswordService extends Service {

  changePassword(changePasswordRequest) {
    return this.post(url.CHANGE_PASSWORD, changePasswordRequest);
  }
}