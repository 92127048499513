
import creditReportExample from './../../../../../libs/ui/src/lib/assets/credit-report-example.png';

export const CreditReportExample = creditReportExample;

export const CreditReportExampleImage = () => {
  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: 'fit-content',
      width: '100%',
    }}>
      <img
        alt="Credit Report Example"
        src={creditReportExample}
        style={{ width: '100%', height: 'auto' }}
      />
    </div>
  );
};
