import { SubmitButton } from '@roc/ui';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { ResendInsuranceQuoteForm } from './resendInsuranceQuoteForm';

export const ResendInsuranceQuote = observer(({ loanStore }) => {
  const { loanId, borrowers } = loanStore.loanDetails;

  useEffect(() => {
    loanStore.resendQuoteStore.setDefaultBorrowerEmailAddress();
  }, [loanStore.resendQuoteStore, borrowers]);

  return (
    <SubmitButton
      size="small"
      variant="contained"
      color="primary"
      testId="resendInsuranceQuote"
      onCancelClick={() => {
        loanStore.resendQuoteStore.reset();
        loanStore.resendQuoteStore.setDefaultBorrowerEmailAddress();
      }}
      onClick={() =>
        loanStore.resendQuote(
          loanId,
          loanStore.resendQuoteStore.form.fields.emailAddress.value
        )
      }
      submitDialogProps={{
        title: 'Confirm borrower email address to resend the quote',
        body: <ResendInsuranceQuoteForm store={loanStore.resendQuoteStore} />,
        okButtonText: 'Submit',
        submitValidation: () => {
          loanStore.resendQuoteStore.runFormValidation();
          return loanStore.resendQuoteStore.form.meta.isValid;
        },
      }}
    >
      Resend Elmsure Quote
    </SubmitButton>
  );
});
