import { StandardDialog, Button } from "@roc/ui";
import { observer } from "mobx-react";
import { useState } from "react";
import { useDrawsStore } from "../hooks";
import { useLoanStore } from "@roc/feature-loans";
import { Box, Grid, Typography } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router";
import { useLoanRoutes } from "@roc/feature-loans-routes-config";
import { DrawRequestFormShared } from "../drawRequestForm/drawRequestFormShared";
import PropertyValuesNew from "./components/propertyValuesNew";
import PointOfContactNew from "../common/PointOfContactNew";
import PreferredProcessContent from "../common/preferredProcessContent";

const useStyles = makeStyles(theme => ({
  modalContainer: {
    margin: '-16px 0px 0px 0px',
  },
  centerText: {
    textAlign: 'center',
  },
  spacedTypography: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  dropDownSection: {
    marginTop: 8,
    display: 'flex',
    flexDirection: 'column',
  },
  selectContainer: {
    marginTop: 8,
  },
  container: {
    marginTop: '25px',
  }
}));

export const DrawRequestNew = observer(() => {
  const [showSuccessScreen, setShowSuccessScreen] = useState<boolean>(false);
  const [showPointOfContactFormModal, setShowPointOfContactModal] = useState<boolean>(false);
  const { pointOfContactStore } = useDrawsStore();
  const { loanStore } = useLoanStore();
  const { loanDetails } = loanStore;
  const classes = useStyles();
  const { drawRequestStore } = useDrawsStore();
  const { pointOfContactFormStore } = pointOfContactStore;
  const { loanRoutesConfig } = useLoanRoutes();
  const { push } = useHistory();

  const [openDrawRequestFormModal, setOpenDrawRequestFormModal] = useState<boolean>(false);

  const handleStartDrawRequest = async () => {
    drawRequestStore.successLoad = false;
    pointOfContactFormStore.runFormValidation();
    if (pointOfContactFormStore.form.meta.isValid) {
      await drawRequestStore.submitRequest();
      if (drawRequestStore.successLoad) {
        if (drawRequestStore.isSowAvailable) {
          setOpenDrawRequestFormModal(true);
        } else {
          setShowSuccessScreen(true);
        }
      }
      setShowPointOfContactModal(false);
    }
  };

  const handleSubmit = () => {
    drawRequestStore.submitForm(drawRequestStore.drawStore.currentDraw.drawId);
    setOpenDrawRequestFormModal(false);
    push(
      loanRoutesConfig
        .loans(loanDetails.loanId)
        .children.drawDetails(
          drawRequestStore.drawStore.currentDraw.drawId.toString()
        ).url
    );
  };

  const handleClose = () => {
    setOpenDrawRequestFormModal(false);
    push(
      loanRoutesConfig
        .loans(loanDetails.loanId)
        .children.drawDetails(
          drawRequestStore.drawStore.currentDraw.drawId.toString()
        ).url
    );
  };

  const handleSave = () => {
    drawRequestStore.saveForm(drawRequestStore.drawStore.currentDraw.drawId);
    setOpenDrawRequestFormModal(false);
    push(
      loanRoutesConfig
        .loans(loanDetails.loanId)
        .children.drawDetails(
          drawRequestStore.drawStore.currentDraw.drawId.toString()
        ).url
    );
  };

  const handleClosePointOfContact = () => {
    setShowPointOfContactModal(false);
  }

  const handleCloseSuccess = () => {
    setShowSuccessScreen(false);
    drawRequestStore.drawStore.fetchDraws(loanDetails.loanId);
  }

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        testId="requestDraw"
        onClick={() => setShowPointOfContactModal(true)}
      >
        Request Draw
      </Button>
      <StandardDialog
        open={showPointOfContactFormModal}
        title="Draw Request"
        maxWidth="md"
        dialogContent={
          <Grid className={classes.modalContainer}>
            <PropertyValuesNew />
            <PointOfContactNew />
            <PreferredProcessContent classes={classes.container}/>
          </Grid>
        }
        dialogActions={
          <Grid container spacing={1} justifyContent="flex-end">
            <Grid item>
              <Button
                onClick={handleClosePointOfContact}
                color="primary"
                variant="outlined"
                testId="cancel"
              >
                CANCEL
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={handleStartDrawRequest}
                color="primary"
                variant="contained"
                testId="confirm"
                disabled={
                  !drawRequestStore.property?.propertyId
                }
              >
                SUBMIT
              </Button>
            </Grid>
          </Grid>
        }
        handleClose={handleClosePointOfContact}
      />
      <StandardDialog
        open={openDrawRequestFormModal}
        title="Draw Request Form"
        maxWidth="xl"
        handleClose={handleClose}
        dialogContent={
          <DrawRequestFormShared
            onSubmit={handleSubmit}
            onSave={handleSave}
            store={drawRequestStore}
          />
        }
      />
      <StandardDialog
        open={showSuccessScreen}
        title="Your Draw Request Was Successfully Submitted!"
        maxWidth="md"
        handleClose={handleCloseSuccess}
        dialogContent={
          <Grid item xs={12}>
            <Box>
              <Typography className={classes.spacedTypography} variant="body1">
                {`Thank you for submitting your Draw Request. Your Draw Number is ${drawRequestStore.drawStore?.currentDraw?.drawNumber}`}
              </Typography>
            </Box>
          </Grid>
        }
        dialogActions={
          <Grid container spacing={1} justifyContent="flex-end">
            <Grid item>
              <Button
                onClick={handleCloseSuccess}
                color="primary"
                variant="outlined"
                testId="cancel"
              >
                CLOSE
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={() => push(loanRoutesConfig
                  .loans(loanDetails.loanId)
                  .children.drawDetails(drawRequestStore.drawStore?.currentDraw?.drawId.toString()).url)}
                color="primary"
                variant="contained"
                testId="confirm"
              >
                UPLOAD DOCUMENTS
              </Button>
            </Grid>
          </Grid>
        }
      />
    </>
  );
});
