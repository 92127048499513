import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import React from 'react';

const useStyles = makeStyles(theme => ({
  ldsFacebook: {
    display: 'inline-block',
    position: 'relative',
    width: '80px',
    height: '80px',
    '& div': {
      display: 'inline-block',
      position: 'absolute',
      left: '8px',
      width: '16px',
      background: `${theme.palette.primary.main}`,
      animation: `$myEffect 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite`,
    },
    '& div:nth-child(1)': {
      left: '8px',
      animationDelay: '-0.24s',
    },
    '& div:nth-child(2)': {
      left: '32px',
      animationDelay: '-0.12s',
    },
    '& div:nth-child(3)': {
      left: '56px',
      animationDelay: '0',
    },
  },
  '@keyframes myEffect': {
    '0%': {
      top: '8px',
      height: '64px',
    },
    '50%, 100%': {
      top: '24px',
      height: '32px',
    },
  },
}));

export const VerticalBarLoader = ({
  position = "fixed"
}) => {
  const classes = useStyles();
  return (
    <Box
      position={position}
      width="100%"
      height="100%"
      top="0"
      left="0"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      zIndex="99999"
      style={{ backgroundColor: 'rgb(255 255 255 / 20%)' }}
    >
      <Box display="flex" justifyContent="center">
        <div className={classes.ldsFacebook}>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </Box>
    </Box>
  );
};
