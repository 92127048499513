import { Route } from 'react-router';
import { useRocInternalPortalStore } from '../../../hooks';
import { Page } from '@roc/client-portal-shared/components';
import { PlaidLiquidityVerification } from 'libs/client-portal-shared/src/app/modules/creditBackground/plaidLiquidityVerification';
import { PlaidLiquidityRequests } from 'libs/client-portal-shared/src/app/modules/creditBackground/plaidLiquidityRequests';

export const getLiquidityVerificationInternalRoutesConfig = (
  basePath: string,
  baseUrl: string
) => {
  return {
    liquidityVerification: {
      path: `${basePath}/liquidity-verification`,
      url: `${baseUrl}/liquidity-verification`,
      documentTitle: 'Liquidity Verification',
    },
    liquidityRequests: {
      path: `${basePath}/liquidity-requests`,
      url: `${baseUrl}/liquidity-requests`,
      documentTitle: 'Browse Plaid Liquidity Requests',
    },
  };
};

export const getLiquidityVerificationInternalRoutes = (
  basePath: string,
  baseUrl: string
) => {
  const config = getLiquidityVerificationInternalRoutesConfig(basePath, baseUrl);
  const { liquidityVerificationInternalStore } = useRocInternalPortalStore();

  return {
    liquidityVerification: (
      <Route exact path={config.liquidityVerification.path}>
        <Page routeDefinition={config.liquidityVerification}>
          <PlaidLiquidityVerification store={liquidityVerificationInternalStore} />
        </Page>
      </Route>
    ),
    liquidityRequests: (
      <Route exact path={config.liquidityRequests.path}>
        <Page routeDefinition={config.liquidityRequests}>
          <PlaidLiquidityRequests store={liquidityVerificationInternalStore} />
        </Page>
      </Route>
    ),
  };
};
