import { FormStore } from '@roc/feature-app-core';

const trackRecordOtherForm = {
  fields: {
    generalContractor: {
      value: null,
      error: null,
      message: '',
      rule: '',
    },
    broker: {
      value: null,
      error: null,
      message: '',
      rule: '',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export class TrackRecordOtherFormStore extends FormStore {

  constructor(globalStore) {
    super({ ...trackRecordOtherForm }, globalStore);
  }

}