import { Box, Grid, Typography } from '@material-ui/core';
import { ArrowForwardIos, CloudDownload, HourglassEmpty } from '@material-ui/icons';
import { Button, CircularLoader } from '@roc/ui';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { QuoteChart } from './quoteChart';
import {
  PaperList,
  PaperListItem,
  PaperListTitle,
} from './quoteGeneratorComponents';
import { QuoteGeneratorTermSheetDialog } from './quoteGeneratorTermSheetDialog';
import { useQuoteGeneratorStore } from '../hooks/useQuoteGeneratorStore';
import { formatCurrency, formatPercentage } from '@roc/ui/utils';
import { LoanSubType, PrequalificationLanes, isNil } from '@roc/feature-utils';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Warning as WarningIcon } from '@material-ui/icons';
import { PaperListItem as ExitStrategyPaperListItem, ExpandablePaperListItem } from 'libs/feature-bridge-profit-and-dscr-calculator/src/components/tabs/summary';
import { useHistory } from 'react-router-dom';
import { useLoanRoutes } from '@roc/feature-loans-routes-config';
import { routeByLoanSubType, useFixFlipStore, useStabilizedBridgeStore, useLoanSubmissionRoutes } from '@roc/feature-loans';

const formatCurrencyValue = value => {
  if (value === null || value === undefined) {
    return '-';
  }
  return formatCurrency(value, 2);
}

const formatPercentageValue = value => {
  if (value === null || value === undefined) {
    return '-';
  }
  return formatPercentage(value, 2);
}

export const QuoteGeneratorOutputs = observer(() => {
  const [showTermSheetDialog, setShowTermSheetDialog] = useState(false);
  const { quoteGeneratorStore } = useQuoteGeneratorStore();
  const { fixFlipStore } = useFixFlipStore();
  const { stabilizedBridgeStore } = useStabilizedBridgeStore();
  const { quoteGeneratorDetailsStore } = quoteGeneratorStore;
  const { quoteCalculationDetails: { bridgeProfitAndDscr, bridgePricerResponseFields }, chartData, quotePrescreenDetails, isPrescreenLoading, calculationStarted } = quoteGeneratorDetailsStore;
  const formFields = quoteGeneratorDetailsStore.form.fields;
  const { isBroker } = !quoteGeneratorDetailsStore.isInternal && quoteGeneratorStore?.globalStore?.userFeatures;
  const { push } = useHistory();
  const { loanRoutesConfig } = useLoanRoutes();
  const { loanSubmissionRoutesConfig } = useLoanSubmissionRoutes();

  const progressQuoteToLoan = () => {
    const loanSubtype = quoteGeneratorDetailsStore.form.fields.loanSubtype.value;
    if (loanSubtype === LoanSubType.FIX_AND_FLIP || loanSubtype === LoanSubType.GROUND_UP) {
      quoteGeneratorDetailsStore.continueQuoteSubmission(fixFlipStore);
    } else if (loanSubtype === LoanSubType.STABILIZED_BRIDGE) {
      quoteGeneratorDetailsStore.continueQuoteSubmission(stabilizedBridgeStore);
    } else {
      return;
    }
    push(
      loanSubmissionRoutesConfig.submitloan.children[
        routeByLoanSubType[loanSubtype]
      ].url
    );
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
      </Grid>
      <Grid item xs={6}>
        <Box pt={2}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            testId="downloadTermSheet"
            onClick={() => setShowTermSheetDialog(true)}
            disabled={!calculationStarted}
          >
            <CloudDownload />&nbsp;
            Download Quote
          </Button>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <PaperList>
          <PaperListTitle text="Loan Terms" />
          <PaperListItem label="Initial Loan Amount" value={formatCurrencyValue(bridgeProfitAndDscr.initialLoanAmount)} />
          <PaperListItem label="Construction Holdback" value={formatCurrencyValue(bridgeProfitAndDscr.constructionHoldback)} />
          <PaperListItem label="Total Loan Amount" value={formatCurrencyValue(bridgeProfitAndDscr.loanAmount)} />
          <PaperListItem label="As-Is Value" value={formatCurrencyValue(formFields.asIsValue.value)} />
          <PaperListItem label="After Repair Value" value={formatCurrencyValue(bridgeProfitAndDscr.salesPrice)} />
          <PaperListItem label="ARLTV" value={formatPercentageValue(bridgeProfitAndDscr.afterRepairLTV)} />
          <PaperListItem label="Total LTC" value={formatPercentageValue(bridgeProfitAndDscr.loanAmountLTC)} />
        </PaperList>
      </Grid>
      {!isBroker &&
        <Grid item xs={12}>
          <PaperList>
            <Box p={1}>
              <QuoteChart chartData={chartData} />
            </Box>
          </PaperList>
        </Grid>
      }
      <Grid item xs={12}>
        <PaperList>
          <PaperListTitle text={isBroker ? "Economics" : "Yield Spread Values"} />
          <PaperListItem label="Capital Provider Rate" value={formatPercentageValue(bridgePricerResponseFields.rocRate)} />
          <PaperListItem label="Capital Provider Points" value={formatPercentageValue(bridgePricerResponseFields.rocPoints)} />
          {!isBroker && <PaperListItem label="Your Yield Spread" value={formatPercentageValue(bridgePricerResponseFields.yourYieldSpread)} />}
          <PaperListItem label={isBroker ? "Broker Points" : "Your Points"} value={formatPercentageValue(bridgePricerResponseFields.yourPoints)} />
        </PaperList>
      </Grid>
      {
        formFields.exitStrategy.value === 'Sale' ? (
          <>
            <Grid item xs={12}>
              <PaperList>
                <PaperListTitle text="Total Project Costs" />
                <ExitStrategyPaperListItem label="Acquisition Price" value={formatCurrencyValue(bridgeProfitAndDscr.acquisitionPrice)} first />
                <ExitStrategyPaperListItem label="Total Renovation Budget" value={formatCurrencyValue(bridgeProfitAndDscr.renovations)} />
                {
                  formFields.loanPurpose.value === 'Refinance' ? (
                    <ExpandablePaperListItem label="Interest & Origination Points" value={formatCurrencyValue(bridgeProfitAndDscr.interestAndOriginationPoints)} >
                      <ExitStrategyPaperListItem label="Interest since purchase date " value={formatCurrencyValue(bridgeProfitAndDscr.interestAndOriginationPointsSincePurchase)} first />
                      <ExitStrategyPaperListItem label="Interest from close of this loan" value={formatCurrencyValue(bridgeProfitAndDscr.interestAndOriginationPointsSinceClose)} first={formFields.loanPurpose.value !== 'Refinance'} />
                    </ExpandablePaperListItem>
                  ) : <ExitStrategyPaperListItem label="Interest & Origination Points" value={formatCurrencyValue(bridgeProfitAndDscr.interestAndOriginationPoints)} />
                }
                <ExitStrategyPaperListItem label="Property Tax & Maintenance" value={formatCurrencyValue(bridgeProfitAndDscr.propertyTaxAndMaintenanceAmount)} />
                <ExpandablePaperListItem label="Taxes and Other Closing Costs" value={formatCurrencyValue(bridgeProfitAndDscr.taxesAndOtherCosts)} >
                  <ExitStrategyPaperListItem label="Legal Fees" value={formatCurrencyValue(bridgeProfitAndDscr.legalFees)} first />
                  <ExitStrategyPaperListItem label="Mortgage Recording Tax" value={formatCurrencyValue(bridgeProfitAndDscr.mortgageRecordingTax)} />
                  <ExitStrategyPaperListItem label="Filling Fees" value={formatCurrencyValue(bridgeProfitAndDscr.filingFees)} />
                  <ExitStrategyPaperListItem label="Appraisal" value={formatCurrencyValue(bridgeProfitAndDscr.appraisal)} />
                  <ExitStrategyPaperListItem label="PL Legal Fee" value={formatCurrencyValue(bridgeProfitAndDscr.pllegalFee)} />
                  <ExitStrategyPaperListItem label="Title Insurance" value={formatCurrencyValue(bridgeProfitAndDscr.titleInsurance)} />
                  <ExitStrategyPaperListItem label="Broker Fee" value={formatCurrencyValue(bridgeProfitAndDscr.brokerFee)} />
                  <ExitStrategyPaperListItem label="Transfer Tax" value={formatCurrencyValue(bridgeProfitAndDscr.transferTax)} />
                  <ExitStrategyPaperListItem label="Mansion Tax" value={formatCurrencyValue(bridgeProfitAndDscr.mansionTax)} />
                  <ExitStrategyPaperListItem label="Recording Fee" value={formatCurrencyValue(bridgeProfitAndDscr.recordingFee)} />
                </ExpandablePaperListItem>
                <ExitStrategyPaperListItem label="Total" value={formatCurrencyValue(quoteGeneratorDetailsStore.totalCosts)} total />
              </PaperList>
            </Grid>
            <Grid item xs={12}>
              <PaperList>
                <PaperListTitle text="Flip Economics" />
                <PaperList>
                  <ExitStrategyPaperListItem label="Sales Price" value={formatCurrencyValue(bridgeProfitAndDscr.salesPrice)} first />
                  <ExitStrategyPaperListItem label="Total Project Costs" value={formatCurrencyValue(quoteGeneratorDetailsStore.totalCosts)} />
                  <ExitStrategyPaperListItem label="Total Profit on Flip" value={formatCurrencyValue(quoteGeneratorDetailsStore.totalProfitOnFlip)} total />
                </PaperList>
                <PaperList>
                  <ExitStrategyPaperListItem label="Borrower Cash in Deal At Purchase" value={formatCurrencyValue(bridgeProfitAndDscr.borrowerCashInDealAtPurchase)} first />
                  <ExitStrategyPaperListItem label="Borrower Cash in Deal (months)" value={formatCurrencyValue(bridgeProfitAndDscr.borrowerCashInDeal)} />
                  <ExitStrategyPaperListItem label="Borrower ROI Cash in Deal in (months)" value={formatPercentageValue(bridgeProfitAndDscr.borrowerROIOnCashInDeal)} total />
                </PaperList>
              </PaperList>
            </Grid>
          </>) : null
      }
      {
        formFields.exitStrategy.value === 'Refinance' ? (
          <Grid item xs={12}>
            <PaperList>
              <PaperListTitle text="Exit DSCR Calculation" />
              <PaperListItem label="Annual PITIA" value={formatCurrencyValue(bridgeProfitAndDscr.annualPITIA)} />
              <PaperListItem label="Monthly Principal & Interest Payment" value={formatCurrencyValue(bridgeProfitAndDscr.monthlyPAndIP)} />
              <PaperListItem label="Exit DSCR" value={bridgeProfitAndDscr.dscr} />
            </PaperList>
          </Grid>
        ) : null
      }
      <Grid item xs={12}>
        <PaperList>
          <Box p={2}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="h5">Pre-qualification</Typography>
              </Grid>
              <Grid item xs={6}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  testId="downloadTermSheet"
                  onClick={() => quoteGeneratorDetailsStore.getQuotePrescreen()}
                  disabled={isPrescreenLoading || !calculationStarted}
                >
                  Check
                </Button>
              </Grid>
              {isPrescreenLoading ? (
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box pt={2}>
                      <CircularLoader position="relative" thickness={5} color="primary" size={36} />
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box pt={2} textAlign="center">
                      <Typography variant="caption">Calculating the pre-qualification status, this may take some time</Typography>
                    </Box>
                  </Grid>
                </Grid>
              ) :
                <Box m={1} width={'100%'}>
                  {!isNil(quotePrescreenDetails?.lane) ? (
                    <Grid item xs={12}>
                      {quotePrescreenDetails.lane === PrequalificationLanes.PRE_QUALIFIED ? (
                        <Alert severity="success">
                          <AlertTitle>
                            <Typography variant="body1">Based on the information provided, your loan application is pre qualified.</Typography>
                          </AlertTitle>
                        </Alert>
                      ) : quotePrescreenDetails.lane === PrequalificationLanes.PRE_REVIEW ? (
                        <Alert
                          severity="warning"
                          style={{
                            backgroundColor: '#f9f9e8',
                            color: '#000',
                          }}
                          icon={<WarningIcon style={{ color: '#ffa726' }} />}
                        >
                          <AlertTitle>Based on the information provided, your loan application needs to be reviewed by a senior underwriter.</AlertTitle>
                        </Alert>
                      ) : (
                        <Alert severity="warning">
                          <AlertTitle>Based on the information provided, your loan application will go to Deal room.</AlertTitle>
                        </Alert>
                      )}
                    </Grid>
                  ) :
                    <Grid item xs={12}>
                      <Alert severity="info">
                        <AlertTitle>
                          <Typography variant="body1">Click 'Check' button to determine your loan application's pre-qualification status</Typography>
                        </AlertTitle>
                      </Alert>
                    </Grid>
                  }
                </Box>
              }
            </Grid>
          </Box>
        </PaperList>
      </Grid>
      {!quoteGeneratorDetailsStore.isInternal &&
        <Grid item xs={12}>
          <Box pt={2}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              testId="continueSubmission"
              onClick={() => progressQuoteToLoan()}
              disabled={!calculationStarted}
              size='large'
            >
              Continue to Submission &nbsp;
              <ArrowForwardIos />
            </Button>
          </Box>
        </Grid>
      }
      <QuoteGeneratorTermSheetDialog
        open={showTermSheetDialog}
        onClose={() => setShowTermSheetDialog(false)}
      />
    </Grid>
  );
});
