import {
  action,
  computed,
  flow,
  makeObservable,
  observable,
  override,
} from 'mobx';
import { GlobalStore } from '@roc/feature-app-core';
import { FormStore } from '@roc/feature-app-core';
import { isNotBlank, parseAddress } from '@roc/feature-utils';

const backgroundReviewFormFields = {
  fields: {
    borrowerId: {
      value: '',
      error: null,
    },
    borrowerName: {
      value: '',
      error: null,
    },
    birthdayDate: {
      value: '',
      error: null,
    },
    ssn: {
      value: '',
      error: null,
    },
    equifaxScore: {
      value: '',
      error: null
    },
    transunionScore: {
      value: '',
      error: null
    },
    experianScore: {
      value: '',
      error: null
    },
    quickChecks: {
      value: [],
      error: null,
    },
    quickAnalysisFlags: {
      value: [],
      error: null,
    },
    backgroundSectionDetails: {
      value: {},
      error: null,
    }
  },
};

export class BackgroundReviewFormBaseStore extends FormStore {
  protected globalStore: GlobalStore;

  constructor(globalStore) {
    super({ ...backgroundReviewFormFields }, globalStore);
    this.globalStore = globalStore;

    makeObservable(this, {

    });
  }

  changeField(field: string, value: any) {
    this.form.fields[field].value = value;
    this.form.fields[field].error = null;
  }
};

export default BackgroundReviewFormBaseStore;