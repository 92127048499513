import { FormStore, GlobalStore } from '@roc/feature-app-core';

const form = {
  fields: {
    bankName: {
      value: '',
      error: null,
      rule: 'required',
      attribute: 'bank name'
    },
    accountType: {
      value: '',
      error: null,
      rule: 'required',
      attribute: 'account type'
    },
    accountName: {
      value: '',
      error: null,
      rule: 'required',
      attribute: 'account name',
    },
    accountNumber: {
      value: '',
      error: null,
      rule: 'required',
      attribute: 'account number',
    },
    reenterAccountNumber: {
      value: '',
      error: null,
      rule: 'required|same:accountNumber',
      message: 'The account number does not match.'
    },
    routingNumber: {
      value: '',
      error: null,
      rule: 'required|regex:/^\\d{9}$/',
      message: 'The routing number must be 9 digits.'
    },
    reenterRoutingNumber: {
      value: '',
      error: null,
      //rule: 'required|regex:/^\\d{3}-?\\d{3}-?\\d{3}$/|same:routingNumber',
      rule: '',
      message: 'The routing number does not match.'
    },
    withdrawalDay: {
      value: 1,
      error: null,
      rule: 'required',
      attribute: 'withdrawal day'
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export class BankInformationFormStore extends FormStore {
  constructor(globalStore: GlobalStore) {
    super(form, globalStore);
  }
}