import { GlobalStore } from '@roc/feature-app-core';
import { LoanService, LoanStore } from '@roc/feature-loans';
import { CLOSING, LoanProcess } from '@roc/feature-utils';
import { action, computed, flow, makeObservable, observable } from 'mobx';
import { DocumentStore } from '../../documents';
import {
  DocumentName,
  DocumentSectionNames,
  INSURANCE_APPROVAL,
} from '../../documents/constants';
import { LoanActionsService } from '../services/loanActionsService';
import { LoanAction } from '../utils/constants';
import { GenerateClosingDocsStore } from './generateClosingDocsStore';
import { RequestClosingWireStore } from './requestClosingWireStore';
import { SnapdocsStore } from './snapdocsStore';
import { UploadClosingDocumentsStore } from './uploadClosingDocumentsStore';
import { UploadSignedClosingDocumentsStore } from './uploadSignedClosingDocumentsStore';

export class LoanActionsStore {
  private globalStore: GlobalStore;
  private loanStore: LoanStore;
  private documentStore: DocumentStore;

  private loanActionsService: LoanActionsService;

  requestClosingWireStore: RequestClosingWireStore;
  generateClosingDocsStore: GenerateClosingDocsStore;
  snapdocsStore: SnapdocsStore;
  uploadClosingDocStore: UploadClosingDocumentsStore;
  uploadSignedClosingDocsStore: UploadSignedClosingDocumentsStore;

  currentAction: LoanAction;
  loanProcess: LoanProcess;

  closingStageActionsInfo;

  constructor(
    globalStore: GlobalStore,
    loanStore: LoanStore,
    documentStore: DocumentStore
  ) {
    this.globalStore = globalStore;
    this.loanStore = loanStore;
    this.documentStore = documentStore;
    this.loanActionsService = new LoanActionsService();

    this.requestClosingWireStore = new RequestClosingWireStore(
      globalStore,
      loanStore
    );

    this.generateClosingDocsStore = new GenerateClosingDocsStore(
      globalStore,
      loanStore
    );

    this.uploadClosingDocStore = new UploadClosingDocumentsStore(
      globalStore,
      loanStore,
      documentStore,
      this
    );

    this.uploadSignedClosingDocsStore = new UploadSignedClosingDocumentsStore(
      globalStore,
      loanStore,
      documentStore,
      this
    );

    this.snapdocsStore = new SnapdocsStore(
      globalStore,
      loanStore,
      this
    );

    makeObservable(this, {
      currentAction: observable,
      loanProcess: observable,
      closingStageActionsInfo: observable,
      setCurrentAction: action,
      showActions: computed,
      refreshLoanDetails: flow,

      approveAdditionalClosingReview: flow,

      openInsuranceApprovalForm: flow,
      rejectInsurance: flow,
      openExpectedClosingDateForm: flow,
      uploadHUD: flow,
      markAsFundedWireCompleted: flow,
      markAsFundedWireCompletedForLoanId: flow,
      approveEntityInformation: flow,
      approveTitleInformation: flow,
      updateFedexTrackingNumber: flow,
      markTitleCompanyContacted: flow,
      markClosingDocumentsSentForSigning: flow,
      markClosingStagePendingApproval: flow,
      checkClosingStageActions: flow,
      openEstimateFundingTemplate: flow
    });
  }

  setCurrentAction(action: LoanAction) {
    this.currentAction = action;
  }

  closeActionsMenu() {
    this.currentAction = null;
  }

  setLoanProcess(loanProcess: LoanProcess) {
    console.log(loanProcess);
    this.loanProcess = loanProcess;
  }

  get showActions() {
    const processEntitlements = this.loanStore.getLoanProcessEntitlements(
      this.loanProcess
    );
    return !!processEntitlements?.showLoanActions;
  }

  *approveAdditionalClosingReview() {
    try {
      const { loanId } = this.loanStore.loanDetails;
      yield this.loanActionsService.approveAdditionalClosingReview(loanId);
      yield this.refreshLoanDetails();
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Additional closing review approved successfully',
      });
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while approving additional closing review',
      });
    }
  }

  *openInsuranceApprovalForm() {
    this.openDocumentForm(CLOSING, DocumentName.INSURANCE_APPROVAL);
  }

  *rejectInsurance() {
    try {
      const { loanId } = this.loanStore.loanDetails;
      yield this.loanActionsService.rejectInsurance(loanId);
      yield this.refreshLoanDetails();
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Success',
      });
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while rejecting the Insurance Approval document',
      });
    }
  }

  *openExpectedClosingDateForm() {
    this.openDocumentForm(CLOSING, DocumentName.EXPECTED_CLOSING_DATE);
  }

  *openEstimateFundingTemplate() {
    this.documentStore.setOpenEstimateFundingTemplate(true);
  }

  *uploadHUD(file) {
    const document = this.findDocumentByName(CLOSING, DocumentName.HUD);
    yield this.documentStore.uploadDocument(file, document, CLOSING);
    yield this.markClosingStagePendingApproval();
    yield this.refreshLoanDetails();
  }

  *markAsFundedWireCompleted() {
    const { loanId } = this.loanStore.loanDetails;
    yield this.markAsFundedWireCompletedForLoanId(loanId);
    yield this.refreshLoanDetails();
  }

  *markAsFundedWireCompletedForLoanId(loanId) {
    try {
      yield this.loanActionsService.markAsFundedWireCompleted(loanId);
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Loan marked as Funded/Completed successfully.',
      });
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while marking loan as Funded/Completed.',
      });
    }
  }

  *updateFedexTrackingNumber(fedexTrackingNumber: string) {
    try {
      const { loanId } = this.loanStore.loanDetails;
      yield this.loanActionsService.updateFedexTrackingNumber(
        loanId,
        fedexTrackingNumber
      );
      yield this.refreshLoanDetails();
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Fedex tracking number updated successfully',
      });
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while updating fedex tracking number',
      });
    }
  }

  *approveEntityInformation() {
    try {
      const { loanId } = this.loanStore.loanDetails;
      yield this.loanActionsService.approveEntityInformation(loanId);
      yield this.refreshLoanDetails();
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Success',
      });
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while making request',
      });
    }
  }

  *approveTitleInformation() {
    try {
      const { loanId } = this.loanStore.loanDetails;
      yield this.loanActionsService.approveTitleInformation(loanId);
      yield this.refreshLoanDetails();
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Success',
      });
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while making request',
      });
    }
  }

  *markTitleCompanyContacted() {
    try {
      const { loanId } = this.loanStore.loanDetails;
      yield this.loanActionsService.markTitleCompanyContacted(loanId);
      yield this.checkClosingStageActions();
      yield this.refreshLoanDetails();
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Success',
      });
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while making request',
      });
    }
  }

  *markClosingDocumentsSentForSigning() {
    try {
      const { loanId } = this.loanStore.loanDetails;
      yield this.loanActionsService.markClosingDocumentsSentForSigning(loanId);
      yield this.checkClosingStageActions();
      yield this.refreshLoanDetails();
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Success',
      });
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while making request',
      });
    }
  }

  *markClosingStagePendingApproval() {
    try {
      const { loanId } = this.loanStore.loanDetails;
      yield this.loanActionsService.markClosingStagePendingApproval(loanId);
      yield this.refreshLoanDetails();
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Success',
      });
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while making request',
      });
    }
  }

  *checkClosingStageActions() {
    try {
      const { loanId } = this.loanStore.loanDetails;
      const response = yield this.loanActionsService.checkClosingStageActions(loanId);
      this.closingStageActionsInfo = response.data.data;
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while making request',
      });
    }
  }



  *refreshLoanDetails() {
    yield this.documentStore.refetchDocuments();
    yield this.loanStore.refreshLoanDetails();
  }

  findDocumentByName(loanProcess, documentName) {
    const processDocuments = this.documentStore.documents[loanProcess];
    const allDocuments = Object.keys(processDocuments).reduce(
      (docs, key) => docs.concat(processDocuments[key]),
      []
    );
    return allDocuments.find(doc => doc.documentName === documentName);
  }

  private openDocumentForm(loanProcess, documentName) {
    const document = this.findDocumentByName(loanProcess, documentName);
    this.documentStore.onOpenForm(document);
  }
}
