import { GlobalStore, GridStore } from '@roc/feature-app-core';
import { action, makeObservable, observable } from 'mobx';
import { ProspectPropertyFormStore } from './prospectPropertyFormStore';
import { DialogState } from '@roc/ui';

export interface ProspectProperty {
  id: string,
  address: string,
  sfID: string,
  sellerStory: string
  askingPrice: number,
  availableUntil: string,
  finishedBasement: string,
  bathrooms: string,
  bedrooms: string,
  halfBath: string,
  city: string,
  photoInspectionLink: string,
  squareFootage: number,
  rentalAssumptions: string,
  potentialExitStrategy: string,
  propertyType: string,
  state: string,
  zipCode: string,
  latitude: number,
  longitude: number,
  images: any[],
  flipAssumptions: string,
  comps: Comp[],
  needsRepair: boolean,
  fixFlipValuation: any,
  rentalValuation: any,
  internalPropertyType: string,
}

export interface Comp {
  compId: string,
  address: string,
  soldPrice: number,
  soldDate: string,
  estimate: number,
  bedrooms: string,
  bathrooms: string,
  halfBath: string,
  squareFootage: number,
  propertyType: string,
  photoUrl: string,
  yearBuilt: string,
  stories: number,
  proximityInMiles: number,
  renovated: string,
  basementFinished: string,
  image: any,
}

export class ProspectPropertiesBaseStore {
  globalStore: GlobalStore;
  prospectPropertiesFormStore: ProspectPropertyFormStore;
  editDialogState: DialogState = null;
  selectedTabIndex = 0;
  isInternal: boolean;
  availablePropertiesGridStore: GridStore;
  myPropertiesGridStore: GridStore;
  selectedProperty: ProspectProperty;
  selectedComp: Comp;
  compModalIsOpen: boolean;
  isDisabled: boolean;
  isDownloading: boolean;
  isLoading: boolean;
  isCarouselPopupOpen: boolean;
  propertiesForCardView: any[];
  isCardView: boolean;
  isAddPropertyModalOpen: boolean;
  isLoadingProperties: boolean;
  constructor(globalStore) {
    this.globalStore = globalStore;
    this.prospectPropertiesFormStore = new ProspectPropertyFormStore(globalStore);
    this.isDisabled = true;
    this.isDownloading = false;
    this.isCarouselPopupOpen = false;
    this.isCardView = true;

    makeObservable(this, {
      selectedTabIndex: observable,
      setSelectedTabIndex: action,
      selectedProperty: observable,
      isDisabled: observable,
      isDownloading: observable,
      setCarouselIsOpen: action,
      isCarouselPopupOpen: observable,
      isCardView: observable,
      isAddPropertyModalOpen: observable,
      setComp: action,
      setCompModalIsOpen: action,
      selectedComp: observable,
      compModalIsOpen: observable,
      propertiesForCardView: observable,
      isLoadingProperties: observable
    });
  }

  onTabChange = (e, index) => {
    this.setSelectedTabIndex(index);
  };

  setSelectedTabIndex(selectedTabIndex) {
    this.selectedTabIndex = selectedTabIndex;
  }

  setIsDisabled(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }

  setIsDownloading(isDownloading: boolean) {
    this.isDownloading = isDownloading;
  }

  setCarouselIsOpen(param) {
    this.isCarouselPopupOpen = param;
  }

  openAddNewProspectPropertyDialog() {
    this.isAddPropertyModalOpen = true;
    this.prospectPropertiesFormStore.reset();
  }
  setCompModalIsOpen(param) {
    console.log('openModal')
    this.compModalIsOpen = param;
  }
  setComp(param) {
    console.log('a comp')
    this.selectedComp = param;
  }
}
