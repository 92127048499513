import { Page } from '../../../components';
import { Route } from 'react-router';
import FixFlipSizerDetails from '../fixFlipSizerDetails';
import FixFlipSizer from '../flixFlipSizer';
import React, { Suspense } from 'react';

export const getFixFlipSizerRoutesConfig = (
  basePath: string,
  baseUrl: string
) => {
  const sizerPath = `${basePath}/fix-and-flip-sizer`;
  const sizerUrl = `${baseUrl}/fix-and-flip-sizer`;
  return {
    sizerHome: {
      path: `${sizerPath}`,
      url: `${sizerUrl}`,
      documentTitle: 'Fix & Flip Sizers',
    },
    submitSizer: {
      path: `${sizerPath}/submit`,
      url: `${sizerUrl}/submit`,
      documentTitle: 'Fix & Flip Sizer',
    },
    sizerDetails: (sizerId?: string) => ({
      path: `${sizerPath}/:sizerId/details`,
      url: `${sizerUrl}/${sizerId}/details`,
      documentTitle: 'Fix & Flip Sizer',
    }),
  };
};

export const getFixFlipSizerRoutes = (basePath: string, baseUrl: string) => {
  const config = getFixFlipSizerRoutesConfig(basePath, baseUrl);
  return {
    sizerHome: (
      <Route exact path={config.sizerHome.path}>
        <Page routeDefinition={config.sizerHome}>
          <FixFlipSizer />
        </Page>
      </Route>
    ),
    submitSizer: (
      <Route exact path={config.submitSizer.path}>
        <Page routeDefinition={config.submitSizer}>
          <FixFlipSizerDetails />
        </Page>
      </Route>
    ),
    sizerDetails: (
      <Route exact path={config.sizerDetails().path}>
        <Page routeDefinition={config.sizerDetails()}>
          <FixFlipSizerDetails />
        </Page>
      </Route>
    ),
  };
};
