import {
  Stepper,
  Step,
  StepLabel,
  StepContent,


} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { Button } from '@roc/ui';
import { FieldContainer } from '@roc/ui/formComponents';
import PropertyStore from '../../stores/multifamily/propertyStore';
import {
  OtherDetails,
  PropertyInformation,
  PurchaseInformation,
  Refinance_OtherDetails,
  Refinance_PurchaseInformation,
  Refinance_RenovationDetails,
  RenovationDetails,
} from './propertyForm';
import { PropertyModalState } from './propertyModal';
import { useAppraisalsStore } from '@roc/feature-appraisals';
import { useMultifamilyStore } from '../../hooks/useMultiFamilyStore';
import { MIXED_USE, MULTIFAMILY_5_PLUS } from '@roc/feature-utils';

function getSteps() {
  return [
    'Property information',
    'Purchase information',
    'Renovation details',
    'Other details',
  ];
}

function getStepContent(step: number, isPurchase: boolean) {
  switch (step) {
    case 0:
      return <PropertyInformation />;
    case 1:
      return isPurchase ? (
        <PurchaseInformation />
      ) : (
        <Refinance_PurchaseInformation />
      );
    case 2:
      return isPurchase ? (
        <RenovationDetails />
      ) : (
        <Refinance_RenovationDetails />
      );
    case 3:
      return isPurchase ? <OtherDetails /> : <Refinance_OtherDetails />;
    default:
      return 'Unknown step';
  }
}

function isFormValid(step: number, isPurchase: boolean, store: PropertyStore) {
  switch (step) {
    case 0:
      return store.propertyInformationStore.form.fields.useCode.value === MIXED_USE ?
        store.mixedUsePropertyInformationStore.form.meta.isValid && store.propertyInformationStore.form.meta.isValid
        : store.propertyInformationStore.form.fields.useCode.value === MULTIFAMILY_5_PLUS ?
          store.multiFamilyPropertyInformationStore.form.meta.isValid && store.propertyInformationStore.form.meta.isValid
          : store.propertyInformationStore.form.meta.isValid;
    case 1:
      return isPurchase ?
        store.propertyInformationStore.form.meta.isValid && store.purchaseInformationStore.form.meta.isValid
        : store.cashOutPropertyInformationStore.form.fields.cashOut.value === 'Y' ?
          store.dscrPropertyInformationStore.form.meta.isValid && store.refinance_purchaseInformationStore.form.meta.isValid
          : store.cashOutPropertyInformationStore.form.fields.cashOut.value === 'N' ?
            store.refinance_purchaseInformationStore.form.meta.isValid
            : false;
    case 2:
      return isPurchase
        ? store.renovationDetailsStore.form.meta.isValid
        : store.refinance_renovationDetailsStore.form.meta.isValid;
    case 3:
      return isPurchase ?
        store.renovationDetailsStore.form.fields.totalRenovationBudget.value > 0 ?
          store.afterRepairValueStore.form.meta.isValid && store.otherDetailsStore.form.meta.isValid
          : store.otherDetailsStore.form.meta.isValid
        : store.refinance_renovationDetailsStore.form.fields.totalRenovationBudget.value > 0 ||
          store.refinance_renovationDetailsStore.form.fields.renovationBudget.value > 0 ?
          store.afterRepairValueRefStore.form.meta.isValid && store.refinance_otherDetailsStore.form.meta.isValid
          : store.refinance_otherDetailsStore.form.meta.isValid;
    default:
      return 'Unknown step';
  }
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    button: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    actionsContainer: {
      marginBottom: theme.spacing(2),
    },
    resetContainer: {
      padding: theme.spacing(3),
    },
  })
);

export const PropertySteps = observer(
  ({ modalState, handleAdd, handleEdit }) => {
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps();
    const {
      appraisalsStore,
    } = useAppraisalsStore();
    const {
      multiFamilyStore: { propertyStore },
    } = useMultifamilyStore();
    const { propertyInformationStore } = propertyStore;
    const isPurchase =
      propertyInformationStore.form.fields.propertyOwnership.value ===
      'Purchase';

    useEffect(() => {
      appraisalsStore.fetchStandaloneAppraisals();
    }, [appraisalsStore]);

    const handleNext = () => {
      if (activeStep === steps.length - 1) {
        modalState == PropertyModalState.ADD ? handleAdd() : handleEdit();
        return;
      }
      setActiveStep(prevActiveStep => prevActiveStep + 1);
    };

    const handleBack = () => {
      setActiveStep(prevActiveStep => prevActiveStep - 1);
    };

    return (
      <div className={classes.root}>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
              <StepContent TransitionProps={{ unmountOnExit: false }}>
                {getStepContent(index, isPurchase)}
                <div className={classes.actionsContainer}>
                  <FieldContainer>
                    <>
                      <Button
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        className={classes.button}
                        testId="back"
                      >
                        Back
                      </Button>
                      <Button
                        variant="contained"
                        disabled={
                          !isFormValid(index, isPurchase, propertyStore)
                        }
                        color="primary"
                        onClick={handleNext}
                        className={classes.button}
                        testId="next"
                      >
                        {activeStep === steps.length - 1
                          ? modalState == PropertyModalState.ADD
                            ? 'Finish & Add Property'
                            : 'Finish'
                          : 'Next'}
                      </Button>
                    </>
                  </FieldContainer>
                </div>
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </div>
    );
  }
);
