import { StepsContainer } from '@roc/ui';
import { observer } from 'mobx-react';
import { PublicCreditInformation } from '../public/publicCreditInformation';
import { useEffect } from 'react';
import { IdVerification } from './idVerification';
import { PersonalInformationWithIdVerification } from './personalInformationWithIdVerification';
import { insertIf } from '@roc/feature-utils';

export const BorrowerSetupStepsWithIdVerification = observer((props) => {

  const { store, handleClose } = props;
  const { activeStep, isBorrowerUser, personalGuarantor } = store;

  useEffect(() => {
    store.generateAu10tixIdVerification();
  }, []);

  const steps = [
    {
      title: 'ID Information',
      icon: null,
      allowBack: false,
      allowNext: false,
    },
    {
      title: 'Personal Information',
      icon: null,
      onBack: () => {
        store.goPrevStep();
      },
      onNext: async () => {
        store.saveSomePersonalFinancialInformation();
      },
      allowBack: true,
      allowNext: true,
    },
    {
      title: 'Credit Information',
      icon: null,
      onBack: () => {
        store.goPrevStep();
      },
      onNext: async () => {
        store.saveCreditInformation(handleClose);
      },
      allowBack: true,
      allowNext: !isBorrowerUser,
      nextButtonText: 'Finish',
    },
  ];

  const getStepsContent = () => {
    return [
      <IdVerification store={store} />,
      <PersonalInformationWithIdVerification store={store} />,
      ...insertIf(personalGuarantor, [
        <PublicCreditInformation store={store} handleClose={handleClose} />
      ]),
    ];
  };

  return (
    <StepsContainer
      activeStep={activeStep}
      disableNavigation={true}
      steps={steps}
    >
      {getStepsContent()}
    </StepsContainer>
  )
});