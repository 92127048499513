import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { observer } from 'mobx-react';
import { QuoteTypeStore } from '../stores/quoteTypeStore';
import { formatCurrency } from '@roc/ui/utils';
import { sum } from '../utils/utils';
import { generateLoanDetailsTableData } from '../steps/summary/utils/summaryUtils';

const useStyles = makeStyles(() => ({
  noBorders: {
    border: 'none',
  },
  darkCell: {
    backgroundColor: '#E4E8EB',
  },
  brightCell: {
    backgroundColor: '#FCFCFC',
  },
  boldCell: {
    fontWeight: 'bold',
    color: 'black',
  },
}));

interface FeesDetailsTableProps {
  store: QuoteTypeStore;
}

export const FeesDetailsTable = observer(({ store }: FeesDetailsTableProps) => {
  const classes = useStyles();

  const property = store.propertiesStore.getProperties()[0];
  const outputs = store.loanEconomicsStore.outputs;

  function getClassNames(categoryIdx) {
    return `${classes.noBorders} ${categoryIdx % 2 === 0 ? classes.darkCell : classes.brightCell
      }`;
  }

  const feesDetails = generateLoanDetailsTableData(outputs, 0, property?.loanPurpose, store.loanSubtype);

  const lastRowBackgroundClass =
    feesDetails.categories.length % 2 === 0
      ? classes.darkCell
      : classes.brightCell;

  return (
    <TableContainer style={{ border: '1px solid #ddd' }}>
      <Table>
        <TableBody>
          {feesDetails.categories.map((category, categoryIdx) =>
            category.fees.map((fee, feeIdx) => (
              <TableRow key={fee.name}>
                <TableCell className={getClassNames(categoryIdx)}>
                  {feeIdx === 0 && category.name}
                </TableCell>
                <TableCell
                  className={`${getClassNames(categoryIdx)} ${classes.boldCell
                    }`}
                >
                  {fee.name}
                </TableCell>
                <TableCell className={getClassNames(categoryIdx)} align="right">
                  {fee.amount}
                </TableCell>
                <TableCell
                  className={`${getClassNames(categoryIdx)} ${classes.boldCell
                    }`}
                  align="right"
                >
                  {feeIdx === category.fees.length - 1 && category.total}
                </TableCell>
              </TableRow>
            ))
          )}
          <TableRow>
            <TableCell className={lastRowBackgroundClass}></TableCell>
            <TableCell className={lastRowBackgroundClass}></TableCell>
            <TableCell
              className={`${lastRowBackgroundClass} ${classes.boldCell}`}
              align="right"
            >
              TOTAL:
            </TableCell>
            <TableCell
              className={`${lastRowBackgroundClass} ${classes.boldCell}`}
              align="right"
            >
              {feesDetails.total}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
});
