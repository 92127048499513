import { Box, Container, Grid } from '@material-ui/core';
import {
  Button,
  createNumberFormatField,
  createRadioField,
  createSelectField,
  createTextField,
} from '@roc/ui';
import { observer } from 'mobx-react';
import { useACHFormStore } from '../hooks/useACHStore';
import { accountTypeOptions, withdrawalDayOptions } from '../utils/constants';
import { WithdrawalDayField } from './withdrawalDayField';
import { lighten, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from 'libs/feature-borrower-portal-theme/src/theme/overrides/Typography';
import { LoanDetailsCard } from './loanDetailsCard';

const useStyles = makeStyles((theme: Theme) => {
  return {
    button: {
      fontWeight: 'normal',
      padding: theme.spacing(2, 8),
    },
  };
});

export const BankInformation = observer(() => {
  const classes = useStyles();
  const { achFormStore } = useACHFormStore();

  return (
    <Container maxWidth={'md'} disableGutters>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Box>
            Once your loan becomes funded, ACH enables easy automated loan
            payments. Setting this up requires certain banking information.
            Please enter the necessary information below. Make sure the
            information is for the account you will use to pay your monthly loan
            payments.
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <BankInformationFields />
        </Grid>
        <Grid item xs={12} md={6}>
          <LoanDetailsCard />
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center">
            <Button
              testId="start"
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={() => achFormStore.moveToDocusignStep()}
            >
              CONTINUE
            </Button>
        </Box>
        </Grid>
      </Grid>
    </Container>
  );
});

const BankInformationFields = observer(() => {
  const classes = useStyles();
  const { achFormStore } = useACHFormStore();
  const { bankInformationFormStore } = achFormStore;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        {createTextField({
          store: bankInformationFormStore,
          testId: 'bankName',
          fieldName: 'bankName',
          label: 'Bank Name',
        })}
      </Grid>
      <Grid item xs={12} md={12}>
        {createTextField({
          store: bankInformationFormStore,
          testId: 'accountName',
          fieldName: 'accountName',
          label: 'Bank Account Name',
        })}
      </Grid>
      <Grid item xs={12} md={12}>
        {createNumberFormatField({
          store: bankInformationFormStore,
          testId: 'routingNumber',
          fieldName: 'routingNumber',
          label: 'Routing Number',
          format: '#########',
        })}
      </Grid>
      <Grid item xs={12} md={12}>
        {createNumberFormatField({
          store: bankInformationFormStore,
          testId: 'accountNumber',
          fieldName: 'accountNumber',
          label: 'Bank Account Number',
        })}
      </Grid>
      <Grid item xs={12} md={12}>
        {createNumberFormatField({
          store: bankInformationFormStore,
          testId: 'reenterAccountNumber',
          fieldName: 'reenterAccountNumber',
          label: 'Confirm Bank Account Number',
        })}
      </Grid>
      <Grid item xs={12} md={12}>
        {createSelectField({
          store: bankInformationFormStore,
          testId: 'withdrawalDay',
          fieldName: 'withdrawalDay',
          label: 'Recurring Monthly Payment Date',
          tooltip:
            'Select the day of the month when your payments will be made - this can be any day from the 1st to the 10th',
          options: withdrawalDayOptions,
        })}
      </Grid>
      <Grid item xs={12} md={12}>
        {createRadioField({
          store: bankInformationFormStore,
          testId: 'accountType',
          fieldName: 'accountType',
          label: 'Account Type',
          row: true,
          options: accountTypeOptions,
        })}
      </Grid>
      <Grid item xs={12}>
        <i>
          Your account will only be debited after your loan has funded, any
          applicable interest reserves have been depleted, and your monthly loan
          payments are due.
        </i>
      </Grid>
    </Grid>
  );
});
