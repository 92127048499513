import { ConfirmationButton, StandardDialog, Button, TextField } from "@roc/ui";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { isNil } from "@roc/feature-utils";

interface Props {
  open: boolean;
  onConfirm: (message?: string) => void;
  onClose: () => void;
  reasonRequired: boolean;
  confirmBtnText: string;
}

export const UwReasonDialog = observer(({ open, onClose, onConfirm, reasonRequired, confirmBtnText }: Props) => {
  const [reason, setReason] = useState('');
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  useEffect(() => {
    if (open) {
      setReason('');
    }
  }, [open]);

  const handleChange = (value: string) => {
    setReason(value);
    if (showErrorMessage) {
      setShowErrorMessage(false);
    }
  }

  const handleConfirm = () => {
    if (reasonRequired && !reason) {
      setShowErrorMessage(true);
    } else {
      onConfirm(reason);
    }
  }

  return (
    <StandardDialog
      open={open}
      title={`Confirm ${confirmBtnText} Action`}
      maxWidth="sm"
      handleClose={onClose}
      dialogContent={
        <>
          <TextField
            type='text'
            testId={'reason'}
            variant='outlined'
            standaloneLabel={false}
            label='Please provide comments'
            value={reason}
            onChange={e => handleChange(e.target.value)}
            error={showErrorMessage}
            helperText={showErrorMessage ? 'The reason is required for rejecting the review.' : null}
            fullWidth
            multiline={true}
            required={reasonRequired}
            minRows={3}
          />
        </>
      }
      dialogActions={
        <>
          <Button
            style={{ marginRight: '8px' }}
            onClick={onClose}
            color="primary"
            variant="outlined"
            testId="cancel"
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirm}
            color="primary"
            variant="contained"
            testId="save"
          >
            {confirmBtnText}
          </Button>
        </>
      }
    />
  )
})