import { computed, flow, action, makeObservable, observable } from 'mobx';
import { GlobalStore } from '@roc/feature-app-core';
import { LoanStore } from '@roc/feature-loans';
import { InsuranceApprovalFormStore } from './insuranceApprovalFormStore';
import { ExceptionsFormStore } from './exceptionsFormStore';
import { FloodZoneFormStore } from './floodZoneFormStore';
import { InsuranceProviderFormStore } from './insuranceProviderFormStore';
import { DocumentStore } from './../../documentStore';
import { isNotBlank, LoanType, NO, YES } from '@roc/feature-utils';
import { InsuranceApprovalService } from '../../../services/documentForms/insuranceApproval/insuranceApprovalService';
import { format, isValid } from 'date-fns';
import { ApiResponse } from '@roc/feature-app-core';
import {
  InsuranceCompanyType,
  InsuranceDocumentType,
} from '../../../components/documentForms/insuranceApproval/constants';
import { DocumentSectionNames, DocumentStatus } from '../../../constants';

export class InsuranceApprovalStore {
  public loanStore: LoanStore;
  private documentStore: DocumentStore;
  private globalStore: GlobalStore;
  private insuranceApprovalService: InsuranceApprovalService;
  insuranceApprovalFormStore: InsuranceApprovalFormStore;

  floodZoneFormStore: FloodZoneFormStore;
  exceptionsFormStore: ExceptionsFormStore;
  insuranceProviderFormStore: InsuranceProviderFormStore;
  floodCertErrorMessage: string;
  coverageEffectiveDate: Date;

  constructor(
    loanStore: LoanStore,
    documentStore: DocumentStore,
    globalStore: GlobalStore
  ) {
    this.loanStore = loanStore;
    this.documentStore = documentStore;
    this.globalStore = globalStore;
    this.insuranceApprovalService = new InsuranceApprovalService();
    this.floodZoneFormStore = new FloodZoneFormStore(loanStore, globalStore);
    this.exceptionsFormStore = new ExceptionsFormStore(loanStore, globalStore);
    this.insuranceProviderFormStore = new InsuranceProviderFormStore(
      loanStore,
      globalStore
    );
    this.insuranceApprovalFormStore = new InsuranceApprovalFormStore(
      loanStore,
      this.insuranceProviderFormStore,
      globalStore,
      documentStore
    );
    this.floodCertErrorMessage = '';
    this.coverageEffectiveDate = null;

    makeObservable(this, {
      canSubmitInsurance: computed,
      canApprove: computed,
      canEdit: computed,
      canSubmit: computed,
      confirmAndApprove: flow,
      validateInsurance: flow,
      validateFloodCerts: flow,
      floodCertErrorMessage: observable,
      updateCoverageStartDate: action,
      coverageEffectiveDate: observable,
      getCoverageStartDate: flow
    });
  }

  initialize() {
    this.reset();
    this.insuranceApprovalFormStore.initialize();
    this.floodZoneFormStore.initialize();
    this.insuranceProviderFormStore.initialize();
  }

  reset() {
    this.insuranceApprovalFormStore.reset();
    this.floodZoneFormStore.reset();
    this.exceptionsFormStore.reset();
    this.insuranceProviderFormStore.reset();
    this.floodCertErrorMessage = '';
    this.coverageEffectiveDate = null;
  }

  *validateFloodCerts() {
    try {
      this.floodCertErrorMessage = '';
      const response = yield this.insuranceApprovalService.validateFloodCertConditions(this.loanStore.loanDetails.loanId);
      if (response?.data?.data?.success === false) {
        this.floodCertErrorMessage = response?.data?.data?.responseMessage;
      }
      return response?.data?.data?.success;
    } catch (e) {
      console.log(e)
      this.globalStore.notificationStore.showErrorNotification({
        message:
          'Sorry, there was some error while checking the flood certs.',
      });
    }
  }

  *validateInsurance() {
    const finalQuotes = this.insuranceApprovalFormStore.finalQuotes;
    const insuranceDocuments = this.documentStore.closingDocuments[
      DocumentSectionNames.INSURANCE
    ];

    const { properties } = this.loanStore.loanDetails;

    const messages = [];

    if (!finalQuotes.some(quote => quote.selectedQuote)) {
      messages.push(
        'Insurance cannot be approved without selecting any quotes. Please try again'
      );
    }

    if (![YES, NO].includes(this.floodZoneFormStore.floodZoneCheck)) {
      messages.push(
        'The Flood Zone status for the property is set to not known. Please click on the second tab (Flood Zone Check) to select the right status.'
      );
    }

    if (!this.coverageEffectiveDate && this.loanStore.loanDetails.insuranceReviewerId == 4) {
      messages.push(
        'Coverage Effective Date is required for insurance approval'
      );
    }

    const inFloodZone = this.floodZoneFormStore.floodZoneCheck === YES;
    const floodException = this.exceptionsFormStore.ignoreFloodCheck;
    const isFloodQuotePresent = finalQuotes.some(quote =>
      quote.quoteType.toLowerCase().includes('flood')
    );
    if (inFloodZone && !isFloodQuotePresent && !floodException) {
      messages.push(
        'The Flood Zone status for the property is set to Yes, hence an Elmsure or Other Flood Quote is required for insurance approval.'
      );
    }

    const areValidFloodCerts = yield this.validateFloodCerts();
    if (!areValidFloodCerts) {
      messages.push(this.floodCertErrorMessage);
    }

    const areBrokerDetailsRequired = this.globalStore.userFeatures
      .closingEntitlements.areBrokerDetailsRequired;
    const hasOtherQuotes = finalQuotes.some(
      quote => quote.insuranceCompany == InsuranceCompanyType.OTHER
    );
    this.insuranceProviderFormStore.runFormValidation();
    const brokerInfoCompleted = this.insuranceProviderFormStore.form.meta
      .isValid;

    if (areBrokerDetailsRequired && hasOtherQuotes && !brokerInfoCompleted) {
      messages.push(
        "When 'Other' quotes are selected, the broker/insurance provider information is required."
      );
    }

    const otherSelectedQuotes = finalQuotes.filter(
      quote => quote.insuranceCompany != InsuranceCompanyType.ELMSURE && quote.selectedQuote
    );

    const binders = this.insuranceApprovalFormStore.uploadedFiles.filter(
      f => f.fileType === InsuranceDocumentType.BINDER
    );
    const invoices = this.insuranceApprovalFormStore.uploadedFiles.filter(
      f => f.fileType === InsuranceDocumentType.INVOICE
    );
    const allDocumentsUploaded = otherSelectedQuotes.every(
      quote =>
        binders.some(b => b.insuranceDetailId === quote.insuranceDetailId) &&
        invoices.some(i => i.insuranceDetailId === quote.insuranceDetailId)
    );

    if (!allDocumentsUploaded) {
      messages.push('Upload all documents for all selected Quotes');
    }

    yield this.insuranceApprovalFormStore.saveInsuranceSummary();
    yield this.loanStore.refreshLoanDetails();
    const { loanType, pitiaDSCR, loanInsurance, minimumInPlaceDscr } = this.loanStore.loanDetails;
    const isTermLoan = loanType === LoanType.RESIDENTIAL_TERM;
    if (isTermLoan && pitiaDSCR <= minimumInPlaceDscr && !loanInsurance?.lowDscrAllowed) {
      messages.push(
        'DSCR value is too low. Please reach to the team to address this issue.'
      );
    }

    if (messages.length > 0) {
      this.globalStore.notificationStore.showErrorNotification({
        message: messages[0],
      });
      return false;
    } else {
      return true;
    }
  }

  *confirmAndApprove() {
    try {
      const {
        name,
        phone,
        emailAddress,
      } = this.insuranceProviderFormStore.getFormValues();

      const insuranceForm = {
        loanId: this.loanStore.loanDetails.loanId,
        coverageEffectiveDate: this.coverageEffectiveDate ? format(this.coverageEffectiveDate, 'MM/dd/yyyy') : null,
        loanDocument: this.documentStore.currentDocument,
        insuranceFormDetails: this.insuranceApprovalFormStore.finalQuotes,
        floodZoneCheck: this.floodZoneFormStore.floodZoneCheck,
        insuranceBrokerName: name,
        insuranceBrokerPhone: phone,
        insuranceBrokerEmail: emailAddress,
        floodExceptionReason: this.exceptionsFormStore.ignoreFloodCheckReason,
      };

      const files = this.insuranceApprovalFormStore.uploadedFiles.map(
        f => f.file
      );
      const response = yield this.insuranceApprovalService.confirmAndApproveInsurance(
        insuranceForm,
        files
      );
      if (response?.data?.data?.success) {
        yield this.loanStore.refreshLoanDetails();
        yield this.documentStore.refetchDocuments();
        this.globalStore.notificationStore.showSuccessNotification({
          message: 'Insurance approved successfully',
        });
      } else {
        this.globalStore.notificationStore.showErrorNotification({
          message:
            response?.data?.data?.responseMessage,
        });
      }

    } catch (e) {
      console.log('e', e);
      this.globalStore.notificationStore.showErrorNotification({
        message:
          'Sorry, there was some error updating the insurance summary form. Please try again.',
      });
    }
  }

  get canApprove() {
    // Only the Insurance Reviewer can edit and approve
    return this.canEdit;
  }

  get canEdit() {
    return (
      this.globalStore.userFeatures?.closingEntitlements
        ?.allowInsuranceApproval &&
      this.documentStore.currentDocument.status !== DocumentStatus.ACCEPTED
    );
  }

  get canSubmit() {
    const hasQuotes = this.insuranceApprovalFormStore.finalQuotes.length > 0;
    return this.canEdit && hasQuotes;
  }

  get canSubmitInsurance() {
    return this.globalStore.userFeatures?.closingEntitlements
      .canSubmitInsurance;
  }

  updateCoverageStartDate(date: Date) {
    this.coverageEffectiveDate = date;
  }

  *getCoverageStartDate() {
    const response: ApiResponse = yield this.insuranceApprovalService.getCoverageStartDate(this.loanStore.loanDetails.loanId);
    this.coverageEffectiveDate = isNotBlank(response.data.data) ? new Date(Date.parse(response.data.data)) : null;
  }

}

export default InsuranceApprovalStore;
