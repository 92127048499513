import { Page } from '@roc/feature-app-core';
import { Route } from 'react-router';
import { useRocInternalPortalStore } from '../../../hooks';
import { SubmissionVsUnderwritingValuesCardBridge } from '@roc/feature-loan-details';
import { SubmissionVsUnderwritingValuesCardTerm } from '@roc/feature-loan-details';

export const getSubmissionVsUnderwritingInternalRoutesConfig = (
  basePath: string,
  baseUrl: string,
) => {
  return {
    submissionVsUnderwriting: {
      path: `${basePath}/submission-vs-underwriting`,
      url: `${baseUrl}/submission-vs-underwriting`,
      documentTitle: 'submission-vs-underwriting',
    },
  };
};

export const getSubmissionVsUnderwritingInternalRoutes = (
  basePath: string,
  baseUrl: string,
  loanId: any,
  loanType: string,
) => {
  const config = getSubmissionVsUnderwritingInternalRoutesConfig(basePath, baseUrl);
  const { submissionVsUnderwritingInternalStore } = useRocInternalPortalStore();
  return {
    submissionVsUnderwriting: (
      <Route exact path={config.submissionVsUnderwriting.path}>
        <Page routeDefinition={config.submissionVsUnderwriting}>
          {loanType === 'Residential Bridge' && <SubmissionVsUnderwritingValuesCardBridge
            store={submissionVsUnderwritingInternalStore}
            loanId={loanId}
            isInternal={true}
            loanDetails={null}
          />
          }
          {loanType === 'Residential Term' && <SubmissionVsUnderwritingValuesCardTerm
            store={submissionVsUnderwritingInternalStore}
            loanId={loanId}
            isInternal={true}
            loanDetails={null}
          />
          }
        </Page>
      </Route>
    ),
  };
}