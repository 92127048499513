import { Service } from '@roc/feature-app-core';

const url = {
  GET_LOAN_CLOSING_CONDITION_BY_DOCUMENT_ID:
    '/api/v1/loan/getClosingConditionByDocumentId',
  GET_LOAN_CLOSING_CONDITION_BY_DOCUMENT_ID_INTERNAL:
    '/nportal/rest/priv/loan/getClosingConditionByDocumentId',
  SAVE_LOAN_CLOSING_CONDITION:
    '/api/v1/loan/saveLoanClosingCondition',
  SAVE_LOAN_CLOSING_CONDITION_INTERNAL:
    '/nportal/rest/priv/loan/saveLoanClosingCondition',
  UPLOAD_DOCUMENT_INTERNAL: '/nportal/rest/priv/loanDocs/uploadChildDocuments',
  UPLOAD_DOCUMENT: '/api/v1/loan/loanDocuments/uploadChildDocuments',
  DOWNLOAD_DOCUMENT_INTERNAL: '/nportal/rest/priv/loanDocs/downloadLoanDocument',
  GET_CLOSING_CONVERSATION_URL: '/api/v1/loan/getClosingConversationUrl',
  CLOSING_CONDITION_LEVEL: '/nportal/rest/priv/loan/changeClosingConditionLevel'
};

export class LoanClosingConditionService extends Service {
  getLoanClosingConditionByDocumentId(documentId, internal: boolean) {
    return this.get(`${internal ? url.GET_LOAN_CLOSING_CONDITION_BY_DOCUMENT_ID_INTERNAL : url.GET_LOAN_CLOSING_CONDITION_BY_DOCUMENT_ID}?documentId=${documentId}`);
  }

  saveLoanClosingCondition(closing_condition_information, internal: boolean, action: string) {
    return this.post(internal ? `${url.SAVE_LOAN_CLOSING_CONDITION_INTERNAL}?action=${action}` : url.SAVE_LOAN_CLOSING_CONDITION, closing_condition_information)
  }

  uploadFile(internal: boolean, documentId: number, formData) {
    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }
    return this.post(internal ? `${url.UPLOAD_DOCUMENT_INTERNAL}?loanDocumentId=${documentId}` : `${url.UPLOAD_DOCUMENT}?loanDocumentId=${documentId}`, formData, config);
  }
  downloadDocumentInternal(document) {
    return this.post(url.DOWNLOAD_DOCUMENT_INTERNAL, document, null, {
      responseType: 'arraybuffer',
    });
  };
  getClosingConversationURL(loanId) {
    return this.get(`${url.GET_CLOSING_CONVERSATION_URL}?loanId=${loanId}`);
  };

  changeClosingConditionLevel(documentId: number, committeeClearance: boolean) {
    return this.get(`${url.CLOSING_CONDITION_LEVEL}?documentId=${documentId}&committeeClearance=${committeeClearance}`);
  };
}
