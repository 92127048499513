import { TextField } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Autocomplete } from "@material-ui/lab";
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { useStore } from '@roc/client-portal-shared/hooks';
import { FieldLabel } from '@roc/ui';


export const LenderRMUsers = observer(({ label, value, onChange, disabled, companyProfileStore }) => {
  const { salesforceLenderStore } = companyProfileStore;
  const { relationshipManagers } = salesforceLenderStore;

  const handleMenuItemClick = value => {
    onChange(value);
  };

  return (
    <>
      <FieldLabel>{label}</FieldLabel>
      <Autocomplete
        id='menu-appbar'
        options={relationshipManagers}
        disableClearable
        componentName="menu-appbar"
        renderInput={
          (options) => <TextField {...options} placeholder={value?.label} variant={'outlined'} />
        }
        blurOnSelect={true}
        getOptionLabel={(option) => option?.label || ''}
        onChange={(event, value) => handleMenuItemClick(value)}
        value={value}
        getOptionSelected={(option, value) => option?.label === value?.label}
        disabled={disabled}
      />
    </>
  );
});
