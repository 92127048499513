import { FormStore, GlobalStore } from '@roc/feature-app-core';
import { Document } from '@roc/feature-types';
import { action, flow, makeObservable, observable } from 'mobx';
import { getDocumentAsBlob } from '@roc/feature-utils';
import { PostDisasterInspectionService } from '../../../services/documentForms/postDisasterInspection/postDisasterInspectionService';

const postDisasterInspectionForm = {
  fields: {
    femaIncidentEndDate: {
      type: 'DATE',
      value: null,
      error: null,
      rule: 'required',
      format: 'MM/dd/yyyy',
      message: 'This field is required',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};
export class PostDisasterInspectionFormStore extends FormStore {

  postDisasterInspectionFile: File;
  document: Document;
  propertyInfo: any;

  globalStore: GlobalStore;
  postDisasterInspectionService: PostDisasterInspectionService;

  constructor(globalStore) {
    super({ ...postDisasterInspectionForm }, globalStore);

    this.globalStore = globalStore;
    this.postDisasterInspectionService = new PostDisasterInspectionService();

    makeObservable(this, {
      postDisasterInspectionFile: observable,
      setPostDisasterInspectionFile: action,
      getFemaPropertyInfo: flow,
      changeFemaDocumentsStatus: flow,
    });
    this.setDefaults();
  }

  private setDefaults() {
    this.setPostDisasterInspectionFile(null);
    this.propertyInfo = {};
  }

  reset() {
    super.reset();
    this.setDefaults();
  }

  setPostDisasterInspectionFile(file: File) {
    this.postDisasterInspectionFile = file;
  }

  *getFemaPropertyInfo(loan, document, downloadExistingFile) {
    try {

      this.reset();
      this.document = document;

      if (downloadExistingFile) {
        const response = yield downloadExistingFile(document);
        const fileResult = getDocumentAsBlob(response?.data, response?.headers, response?.data?.type);
        this.postDisasterInspectionFile = new File([fileResult.blob], fileResult.name);
      }

      const response = yield this.postDisasterInspectionService.getFemaPropertyInfo(loan?.loanId, this.document?.loanDocumentId);
      if (response?.data?.propertyId) {
        this.propertyInfo = response?.data;
      }
      else {
        this.propertyInfo = {
          loanId: loan?.loanId,
          documentId: this.document?.loanDocumentId,
        };
      }
      this.loadForm(this.propertyInfo);
    }
    catch (error) {
      console.log(error);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'There was an error while trying to get the details for this property fema disaster.',
      });
    }
  }

  *changeFemaDocumentsStatus(onClose) {
    try {
      this.runFormValidationWithMessage();
      if (this.form.meta.isValid) {
        yield this.postDisasterInspectionService.changeFemaDocumentsStatus(this.document?.loanId, this.document?.collateralId);
        this.globalStore.notificationStore.showSuccessNotification({
          message: 'Fema Documents accepted.',
        });
        onClose();
      }
    } catch (error) {
      console.log(error);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'There was an error while trying to update the status of the document.',
      });
    }
  }

}