import { configure } from 'mobx';
import { ForgotPasswordStore } from './forgotPasswordStore';
import { CreateOrResetPasswordStore } from './createOrResetPasswordStore';
import { CreditAuthorizationStore } from './creditAuthorizationStore';
import { WireAuthorizationStore } from './wireAuthorizationStore';
import WireAuthorizationStoreV2 from './wireAuthorizationStoreV2';
import { LoanTermsStore } from './loanTermsStore';
import { LoginStore, LogoutStore, UserStore } from '@roc/feature-app-core';
import RegisterStore from './registerStore';
import ScopeOfWorkV2Store from './scopeOfWorkV2Store';
import DenialOfCreditStore from './denialOfCreditStore';
import { DrawRequestStorePublic } from './drawRequestStorePublic';
import { PayoffNewRequestStore } from './payoffNewRequestStore';
import { BackgroundReviewStore } from './backgroundReviewStore';
import { WhiteLabelStore } from '@roc/feature-app-core';
import { BaseStore, EnvironmentVariables } from '@roc/feature-app-core';
import {
  FixFlipStore,
  LoanStore,
  MultiFamilyStore,
  RentalPortfolioStore,
  SinglePropertyStore,
  StabilizedBridgeStore
} from '@roc/feature-loans';
import { DrawStore } from '@roc/feature-draws';
import { BrokersStore } from '@roc/feature-brokers';
import { DocumentLibraryStore } from '@roc/feature-document-library';
import { DenialOfCreditRequestStore } from '../modules/denialOfCredit/denialOfCreditForm/stores/denialOfCreditRequestStore';
import { PublicAuthenticationStore } from './publicAuthenticationStore';
import { PlaidStore } from './plaidStore';
import { ScopeOfWorkRevisionStore } from './scopeOfWorkRevisionStore';
import { ManageNotificationsStore } from '../modules/manageNotifications/stores/manageNotificationsStore';
import { environment } from './../../environments/environment';
import { BorrowerDetailsVerificationStore } from '@roc/feature-borrowers';
import { DocumentLibraryStore as DocumentLibraryStoreNew, DocumentLibraryContentfulStore } from '@roc/feature-document-library-contentful';
import { NewLoanApplicationStore, PublicLoanSubmissionStore } from '@roc/feature-borrower-loan-submission';
import CdaPaymentsStore from './cdaArrPaymentsStore';
import CdaDocumentStore from './cdaArrDocumentStore';
import { AchFormStore } from '@roc/feature-plaid';
import { ReferralAuthStore } from '@roc/feature-referral';
import { PublicBorrowerSetupToDoStore } from 'libs/feature-borrowers/src/borrowerSetupToDo';
import { LoanFastrackDocumentStore, LoanFastrackPaymentsStore } from '@roc/feature-loan-fasttrack';
import { TamariskPaymentsStore, TamariskDocumentStore } from '@roc/feature-tamarisk-payments';
import { SubmissionVsUnderwritingStore } from '@roc/feature-loan-details';
import { PublicScopeOfWorkV2Store } from './publicScopeOfWorkV2Store';
import Appraisal1004dPaymentsStore from './appraisal1004dPaymentsStore';
import Appraisal1004dDocumentStore from './appraisal1004dDocumentStore';

export class Store extends BaseStore {
  loginStore: LoginStore;
  logoutStore: LogoutStore;
  authenticationStore: PublicAuthenticationStore;
  registerStore: RegisterStore;
  loanTermsStore: LoanTermsStore;
  tamariskPaymentsStore: TamariskPaymentsStore;
  loanFastrackPaymentsStore: LoanFastrackPaymentsStore;
  loanFastrackDocumentStore: LoanFastrackDocumentStore;
  forgotPasswordStore: ForgotPasswordStore;
  createOrResetPasswordStore: CreateOrResetPasswordStore;
  creditAuthorizationStore: CreditAuthorizationStore;
  wireAuthorizationStore: WireAuthorizationStore;
  wireAuthorizationStoreV2: WireAuthorizationStoreV2;
  whiteLabelStore: WhiteLabelStore;
  scopeOfWorkV2Store: ScopeOfWorkV2Store;
  publicscopeOfWorkV2Store: PublicScopeOfWorkV2Store;
  tamariskDocumentStore: TamariskDocumentStore;
  cdaDocumentStore: CdaDocumentStore;
  appraisal1004dDocumentStore: Appraisal1004dDocumentStore;
  denialOfCreditStore: DenialOfCreditStore;
  drawRequestStore: DrawRequestStorePublic;
  payoffNewRequestStore: PayoffNewRequestStore;
  backgroundReviewStore: BackgroundReviewStore;
  plaidStore: PlaidStore;
  documentLibraryStore: DocumentLibraryStore;
  userStore: UserStore;
  brokerStore: BrokersStore;
  fixFlipStore: FixFlipStore;
  multiFamilyStore: MultiFamilyStore;
  singlePropertyStore: SinglePropertyStore;
  rentalPortfolioStore: RentalPortfolioStore;
  stabilizedBridgeStore: StabilizedBridgeStore;
  loanStore: LoanStore;
  drawStore: DrawStore;
  denialOfCreditRequestStore: DenialOfCreditRequestStore;
  scopeOfWorkRevisionStore: ScopeOfWorkRevisionStore;
  manageNotificationsStore: ManageNotificationsStore;
  borrowerDetailsVerificationStore: BorrowerDetailsVerificationStore;
  documentLibraryContentfulStore: DocumentLibraryContentfulStore;
  documentLibraryStoreNew: DocumentLibraryStoreNew;
  newLoanApplicationStore: NewLoanApplicationStore;
  cdaPaymentsStore: CdaPaymentsStore;
  appraisal1004dPaymentStore: Appraisal1004dPaymentsStore;
  achFormStore: AchFormStore;
  publicLoanSubmissionStore: PublicLoanSubmissionStore;
  referralAuthStore: ReferralAuthStore;
  publicBorrowerSetupToDoStore: PublicBorrowerSetupToDoStore;
  submissionVsUnderwritingStore: SubmissionVsUnderwritingStore;
  borrowerLoanSubmissionStore: PublicLoanSubmissionStore;

  constructor(environmentVariables: EnvironmentVariables) {
    super('', '', environmentVariables);
    this.globalStore.setShowNavigation(false);
    this.loginStore = new LoginStore(this.globalStore);
    this.logoutStore = new LogoutStore(this.globalStore);
    this.authenticationStore = new PublicAuthenticationStore(this.globalStore, this.environmentStore);
    this.tamariskPaymentsStore = new TamariskPaymentsStore(
      this.globalStore
    );
    this.loanFastrackPaymentsStore = new LoanFastrackPaymentsStore(
      this.globalStore
    );
    this.registerStore = new RegisterStore(this.globalStore);
    this.loanTermsStore = new LoanTermsStore(this.globalStore);
    this.forgotPasswordStore = new ForgotPasswordStore(this.globalStore);
    this.createOrResetPasswordStore = new CreateOrResetPasswordStore(
      this.globalStore
    );
    this.creditAuthorizationStore = new CreditAuthorizationStore(
      this.globalStore
    );
    this.wireAuthorizationStore = new WireAuthorizationStore(this.globalStore);
    this.wireAuthorizationStoreV2 = new WireAuthorizationStoreV2(this.globalStore);
    this.scopeOfWorkV2Store = new ScopeOfWorkV2Store(this.globalStore);
    this.publicscopeOfWorkV2Store = new PublicScopeOfWorkV2Store(this.globalStore);
    this.whiteLabelStore = new WhiteLabelStore(
      this.globalStore,
      this.environmentStore
    );
    this.denialOfCreditStore = new DenialOfCreditStore(this.globalStore);
    this.plaidStore = new PlaidStore(this.globalStore);
    this.userStore = new UserStore(this.globalStore, this.whiteLabelStore);
    this.documentLibraryStore = new DocumentLibraryStore(this.globalStore);
    this.fixFlipStore = new FixFlipStore(
      this.globalStore,
      this.userStore,
      this.brokerStore
    );
    this.multiFamilyStore = new MultiFamilyStore(
      this.globalStore,
      this.userStore,
      this.brokerStore
    );
    this.singlePropertyStore = new SinglePropertyStore(
      this.globalStore,
      this.userStore,
      this.brokerStore
    );
    this.rentalPortfolioStore = new RentalPortfolioStore(
      this.globalStore,
      this.userStore,
      this.documentLibraryStore,
      this.brokerStore
    );
    this.stabilizedBridgeStore = new StabilizedBridgeStore(
      this.globalStore,
      this.userStore,
      this.brokerStore
    );
    this.loanStore = new LoanStore(
      this.globalStore,
      this.documentLibraryStore,
      this.fixFlipStore,
      this.multiFamilyStore,
      this.singlePropertyStore,
      this.rentalPortfolioStore,
      this.stabilizedBridgeStore,
      this.userStore,
      null
    );
    this.drawStore = new DrawStore(this.globalStore);
    this.drawRequestStore = new DrawRequestStorePublic(
      this.globalStore,
      this.drawStore
    );
    this.payoffNewRequestStore = new PayoffNewRequestStore(
      this.globalStore,
      this.loanStore
    );
    this.backgroundReviewStore = new BackgroundReviewStore(
      this.globalStore
    );
    this.denialOfCreditRequestStore = new DenialOfCreditRequestStore(this.globalStore);
    this.scopeOfWorkRevisionStore = new ScopeOfWorkRevisionStore(this.globalStore);
    this.manageNotificationsStore = new ManageNotificationsStore(this.globalStore);
    this.borrowerDetailsVerificationStore = new BorrowerDetailsVerificationStore(this.globalStore);
    this.documentLibraryContentfulStore = new DocumentLibraryContentfulStore(this.globalStore, this.environmentStore);
    this.documentLibraryStoreNew = new DocumentLibraryStoreNew(this.globalStore, this.environmentStore);
    this.newLoanApplicationStore = new NewLoanApplicationStore(this.globalStore, this.userStore);
    this.cdaPaymentsStore = new CdaPaymentsStore(this.cdaDocumentStore);
    this.appraisal1004dPaymentStore = new Appraisal1004dPaymentsStore(this.cdaDocumentStore);
    this.achFormStore = new AchFormStore(this.globalStore);
    this.publicLoanSubmissionStore = new PublicLoanSubmissionStore(this.globalStore);
    this.referralAuthStore = new ReferralAuthStore(this.globalStore);
    this.publicBorrowerSetupToDoStore = new PublicBorrowerSetupToDoStore(this.globalStore);
    this.submissionVsUnderwritingStore = new SubmissionVsUnderwritingStore(this.globalStore, this.loanStore);
    this.borrowerLoanSubmissionStore = new PublicLoanSubmissionStore(
      this.globalStore,
    );
  }
}

export const store = new Store(environment);

configure({
  enforceActions: 'never',
});
