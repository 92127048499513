import { Grid, Box } from "@material-ui/core";
import { Card } from "@roc/ui";
import { observer } from "mobx-react";
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@roc/ui';
import { Add } from '@material-ui/icons';
import { HtmlContainer } from '../leadDetails/htmlContainer';
import { Note } from "./note";
import { EditLeadNoteDialog } from "./editLeadNoteDialog";

const useStyles = makeStyles(theme => ({
  button: {
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(2),
    minWidth: '150px',
  },
  notesScroll: {
    backgroundColor: '#FAFAFA',
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    maxHeight: '800px',
    overflowY: 'auto',
    overflowX: 'hidden',
    boxShadow: '0 0 2px #DDD inset'
  },
}));

export const ContactNotesCard = observer(({ leadDetailsStore }) => {
  const classes = useStyles();
  const { editLeadNoteStore } = leadDetailsStore;

  const action = (
    <Box p={1} mb={-10}>
      <Button
        testId="add-lead-note"
        className={classes.button}
        variant="contained"
        size="small"
        color="primary"
        onClick={() => editLeadNoteStore.openAddLeadNote()}
      >
        <Add /> Add Note
      </Button>
    </Box>
  );

  return (
    <Card
      title="Notes"
      cardHeaderProps={{ action }}
      cardContentProps={{ className: classes.notesScroll }}
    >
      {leadDetailsStore.leadNotes.length > 0 ? (
        <Grid container spacing={2}>
          {leadDetailsStore.leadNotes.map(leadNote => (
            <Grid item xs={12}>
              <Note
                title={leadNote.subject}
                onEdit={() => editLeadNoteStore.openEditLeadNote(leadNote)}
                createdDate={leadNote.createdDate}
                lastUpdatedDate={leadNote.lastUpdatedDate}
                createdBy={leadNote.createdByName}
                lastModifiedBy={leadNote.lastUpdatedByName}
              >
                <HtmlContainer html={leadNote.body} />
              </Note>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Box textAlign="center">No records.</Box>
      )}
      <EditLeadNoteDialog editLeadNoteStore={editLeadNoteStore} />
    </Card>
  );
});
