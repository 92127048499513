import { GlobalStore } from '@roc/feature-app-core';
import { FormStore } from '@roc/feature-app-core'
import { makeObservable, observable } from 'mobx';
import { parseAddress } from "@roc/feature-utils";
import { FileUpload } from "@roc/ui";

export const form = {
  fields: {
    address: {
      value: '',
      error: null,
      rule: 'required',
      attribute: 'address',
    },
    streetName: {
      value: '',
      error: null,
      rule: 'required',
      attribute: 'streetName',
    },
    streetNumber: {
      value: '',
      error: null,
      rule: 'required',
      attribute: 'streetNumber',
    },
    city: {
      value: '',
      error: null,
      rule: 'required',
      attribute: 'city',
    },
    state: {
      value: '',
      error: null,
      rule: 'required',
      attribute: 'state',
    },
    zipCode: {
      value: '',
      error: null,
      rule: 'required',
      attribute: 'zipCode',
    },
    __isAddressInCorrectFormat__: {
      value: true,
      error: null,
      rule: 'accepted',
      message: 'Invalid Address Format',
    },
    latitude: {
      value: 0,
      error: null,
      rule: null,
      attribute: 'latitude',
    },
    longitude: {
      value: 0,
      error: null,
      rule: null,
      attribute: 'longitude',
    },
    propertyType: {
      value: '',
      error: null,
      rule: null,
      attribute: 'propertyType',
    },
    sellingPrice: {
      value: '',
      error: null,
      rule: 'required',
      attribute: 'sellingPrice',
    },
    squareFootage: {
      value: '',
      error: null,
      rule: null,
      attribute: 'squareFootage',
    },
    bedrooms: {
      value: '',
      error: null,
      rule: null,
      attribute: 'bedrooms',
    },
    bathrooms: {
      value: '',
      error: null,
      rule: null,
      attribute: 'bathrooms',
    },
    halfBaths: {
      value: '',
      error: null,
      rule: null,
      attribute: 'halfBaths',
    },
    finishedBasement: {
      value: '',
      error: null,
      rule: null,
      attribute: 'finishedBasement',
    },
    availableUntil: {
      value: '',
      error: null,
      rule: 'required',
      attribute: 'availableUntil',
    },
    needsRepairs: {
      value: '',
      error: null,
      rule: 'required',
      attribute: 'needsRepairs',
    },
    flipAssumptions: {
      value: '',
      error: null,
      rule: null,
      attribute: 'flipAssumptions',
    },
    rentalAssumptions: {
      value: '',
      error: null,
      rule: null,
      attribute: 'rentalAssumptions',
    },
    potentialExitStrategy: {
      value: '',
      error: null,
      rule: null,
      attribute: 'potentialExitStrategy',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export class ProspectPropertyFormStore extends FormStore {
  images: FileUpload[]
  constructor(globalStore: GlobalStore) {
    super(form, globalStore);
    this.images = [];
    makeObservable(this, {
      images: observable
    });
  }

  getProperty() {
    return this.getFormValues();
  }

  saveAddressField(address: string, addressDetails, geometryDetails) {
    const parsedAddress = parseAddress(
      address,
      addressDetails,
      geometryDetails
    );
    this.onFieldChange('address', address);
    this.onFieldChange('streetNumber', parsedAddress.street_number);
    this.onFieldChange('streetName', parsedAddress.street);
    this.onFieldChange('city', parsedAddress.city);
    this.onFieldChange('state', parsedAddress.state);
    this.onFieldChange('zipCode', parsedAddress.zip);
    this.onFieldChange('latitude', parsedAddress.latitude);
    this.onFieldChange('longitude', parsedAddress.longitude);

    const addressInCorrectFormat =
      parsedAddress.street_number !== null &&
      parsedAddress.street !== null &&
      parsedAddress.city !== null &&
      parsedAddress.state !== null &&
      parsedAddress.zip !== null;
    this.onFieldChange('__isAddressInCorrectFormat__', addressInCorrectFormat);
  }
}
