import { Box, Paper, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Layout } from '@roc/feature-app-core';
import { StepsContainer } from '@roc/ui';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useStore } from 'apps/client-portal-public/src/app/hooks';
import { ReferralAuthWelcome } from './welcome';
import { BankInformation } from './bankInformation';
import { ReferralFeeDcousign } from './referralFeeDocusign';
import { ReferralFeeThankYou } from './referralAuthThankYou';

const useStyles = makeStyles(theme =>
  createStyles({
    stepper: {
      [theme.breakpoints.down('xs')]: {
        paddingLeft: theme.spacing(0.5),
        paddingRight: theme.spacing(0.5),
      },
      maxWidth: 1000,
      margin: 'auto',
      '& .MuiStepLabel-completed': {
        color: theme.palette.primary.main,
      },
    },
    box: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100%'
    },
    paper: {
      marginTop: theme.spacing(2),
      padding: theme.spacing(2)
    }
  })
);

export const ReferralAuthPage = observer(() => {
  const { referralAuthStore } = useStore();
  const [showWelcome, setShowWelcome] = useState(true);

  const classes = useStyles();

  useEffect(() => {
    referralAuthStore.getBankInfo();
  }, []);

  const steps = [
    {
      title: 'Bank Information',
      icon: null,
      completed: referralAuthStore.activeStep > 0,
    },
    {
      title: 'DocuSign Confirmation',
      icon: null,
      completed: referralAuthStore.isSigned,
    }
  ];

  return (
    <Layout maxWidth={'lg'}>
      <Box display={'flex'} justifyContent={'center'}>
        <Typography variant="h3">Referral Fee Authorization</Typography>
      </Box>
      {referralAuthStore.requestComplete && <Paper className={classes.paper}>
        <StepsContainer
          disableNavigation={true}
          activeStep={referralAuthStore.activeStep}
          handleStep={step => {
            referralAuthStore.activeStep = step;
          }}
          steps={steps}
          stepperClassName={classes.stepper}
        >
          {showWelcome ? <ReferralAuthWelcome onStart={() => setShowWelcome(false)} /> : <BankInformation />}
          {referralAuthStore.isSigned ? <ReferralFeeThankYou /> : <ReferralFeeDcousign />}
        </StepsContainer>
      </Paper>}
    </Layout>
  );
});
