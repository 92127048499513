// ----------------------------------------------------------------------

export default function Typography(theme) {
  return {
    MuiTypography: {
      paragraph: {
        marginBottom: theme.spacing(2),
      },
      gutterBottom: {
        marginBottom: theme.spacing(1),
      },
    },
  };
}
