import { CurrencyField, TextField } from "@roc/ui";
import { DrawDqDataBaseStore } from "../stores";
import { Grid } from "@material-ui/core";
import { observer } from "mobx-react";

interface EditDqDataModalFormProps {
  store: DrawDqDataBaseStore;
}

export const EditDqDataModalForm = observer((props: EditDqDataModalFormProps) => {
  const { store } = props;
  const { editDqDataStore } = store;
  const { form } = editDqDataStore;

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            type="text"
            value={form.fields.loanId.value}
            variant="outlined"
            standaloneLabel
            label="Loan Id"
            fullWidth
            testId="loanId"
            onChange={e => editDqDataStore.onFieldChange('loanId', e.target.value)}
            disabled={true}
          ></TextField>
        </Grid>
        <Grid item xs={12}>
          <CurrencyField
            variant="outlined"
            standaloneLabel
            label="Amount"
            value={form.fields.dqAmount.value}
            onChange={(name, value) =>
              editDqDataStore.onFieldChange('dqAmount', value)
            }
            fullWidth
            testId="dqAmount"
            errorText={form.fields.dqAmount.error}
          />
        </Grid>
        <Grid item xs={12}>
          <CurrencyField
            variant="outlined"
            standaloneLabel
            label="Applied Amount"
            value={form.fields.appliedDqAmount.value}
            onChange={(name, value) =>
              editDqDataStore.onFieldChange('appliedDqAmount', value)
            }
            fullWidth
            testId="appliedDqAmount"
            required
            errorText={form.fields.appliedDqAmount.error}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            type="text-area"
            value={form.fields.comments.value}
            variant="outlined"
            standaloneLabel
            label="Comments"
            multiline
            minRows={3}
            fullWidth
            testId="comments"
            onChange={e => editDqDataStore.onFieldChange('comments', e.target.value)}
            required
          ></TextField>
        </Grid>
      </Grid>
    </>
  )
})