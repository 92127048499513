import { Service } from '@roc/client-portal-shared/services';

const url = {
  REFERRAL_FEE_LINK: `/api/v1/document/formDocument/getReferralFeeLink`,
  GET_FILTERED_BANK_INFO: `/api/v1/document/public/getBankInformation`,
  GET_BANK_INFO: `/api/v1/document/formDocument/getBankInformation`,
  GET_DOCUSIGN: `/api/v1/lender/public/getReferralDocusign`,
  APPROVE_BANK_INFO: `/api/v1/loan/approveEntityBankInformation`,
};

export class ReferralFeeExternalService extends Service {
  getReferralFeeLink(loanId: string) {
    return this.get(`${url.REFERRAL_FEE_LINK}?loanId=${loanId}`);
  }

  getFilteredBankInfo(entityId: string) {
    return this.get(`${url.GET_FILTERED_BANK_INFO}?entityId=${entityId}&entityType=BrokerEntity`);
  }

  getBankInfo(entityId: string) {
    return this.get(`${url.GET_BANK_INFO}?entityId=${entityId}&entityType=BrokerEntity`);
  }

  getDocusign(bankInfo: { bankName: string, accountName: string, routingNumber: string, accountNumber: string }, requestId: string) {
    return this.post(`${url.GET_DOCUSIGN}?requestId=${requestId}`, bankInfo);
  }

  approveBankInformation(entityId: string) {
    return this.get(`${url.APPROVE_BANK_INFO}?entityId=${entityId}&entityType=BrokerEntity`);
  }
}
