import { Grid } from '@material-ui/core';

import {
  BridgeInsuranceSection,
  BridgeKeyDatesSection,
  BridgeLoanTermsSection,
  BridgePropertiesSection,
  BridgeTermsSection,
  LoanSummaryBorrowerEntitySection,
  LoanSummaryBrokerSection,
  LoanSummaryLoanParticipantsSection,
} from '@roc/feature-loans-shared';

import {
  useLoanStore,
} from '@roc/feature-loans';

export const LoanSummaryBridge = ({ data }) => {
  const { loanStore } = useLoanStore();
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={6}>
        <BridgeLoanTermsSection loanData={data} />
      </Grid>
      <Grid item xs={12} lg={6}>
        <BridgeTermsSection loanData={data} />
        <BridgeKeyDatesSection loanData={data} />
      </Grid>
      <Grid item xs={12} md={6}>
        <LoanSummaryLoanParticipantsSection loanData={data} loanStore={loanStore} disableEditButton={true} />
      </Grid>
      <Grid item xs={12} md={6}>
        <BridgeInsuranceSection loanData={data} />
        <LoanSummaryBrokerSection loanData={data} />
      </Grid>
      <Grid item xs={12}>
        <LoanSummaryBorrowerEntitySection loanData={data} />
      </Grid>
      <Grid item xs={12} lg={12}>
        <BridgePropertiesSection loanData={data} />
      </Grid>
    </Grid>
  );
};
