import { observer } from 'mobx-react';
import { Grid, Typography } from '@material-ui/core';
import { createAutocompleteAddressField, createSelectField, createTextField } from '@roc/ui';
import { borrowerStatesAbbreviation, borrowerStatesNames } from '@roc/feature-utils';

const stateOptions = borrowerStatesAbbreviation.map((abbreviation, i) => ({
  value: abbreviation,
  label: borrowerStatesNames[i],
}));

export const Address = observer(({ store }) => {

  const handleAddressChange = event => {
    const { value, fullInfo } = event?.target || {};
    const { address_components, geometry } = fullInfo || {};
    store.saveAddressField(value, address_components, geometry);
  };

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h5">Address</Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        {createAutocompleteAddressField({
          fieldName: 'address',
          label: 'Address Line 1',
          testId: 'address',
          store: store,
          onChange: handleAddressChange,
          showToolTip: false
        })}
      </Grid>
      <Grid item xs={12} sm={6}>
        {createTextField({
          label: 'Address Line 2',
          fieldName: 'aptNumber',
          testId: 'aptNumber',
          store: store,
        })}
      </Grid>
      <Grid item xs={12} />
      <Grid item xs={12} sm={4}>
        {createSelectField({
          store: store,
          testId: 'state',
          fieldName: 'state',
          label: 'State',
          options: stateOptions,
        })}
      </Grid>
      <Grid item xs={12} sm={4}>
        {createTextField({
          label: 'City',
          fieldName: 'city',
          testId: 'city',
          store: store,
        })}
      </Grid>
      <Grid item xs={12} sm={4}>
        {createTextField({
          label: 'Zip Code',
          fieldName: 'zipCode',
          testId: 'zipCode',
          store: store,
        })}
      </Grid>
    </>
  )
});