import { Box, Grid, Table, TableCell, TableContainer, TableRow, Typography } from "@material-ui/core";
import { createStyles, lighten, makeStyles } from "@material-ui/core/styles";
import { Roles } from "@roc/feature-utils";
import { formatCurrency, formatPercentage } from "@roc/ui/utils";
import { useLoanCommissionStore } from "libs/feature-loan-summary/src/loanSummary/hooks/useCommissionStore";
import { TermStore } from "libs/feature-loans/src/loanSubmission";
import { observer } from "mobx-react";
import { useEffect } from "react";

const useStyles = makeStyles(theme =>
  createStyles({
    tableContainer: {
      width: 'fit-content',
      border: '1px solid #e0e0e0',
      borderRadius: theme.spacing(1),
    },
    tableExternal: {
      '& td': {
        width: '150px',
        padding: theme.spacing(1.5),
        textAlign: 'right',
      },
      '& tr td:last-child': {
        width: 'auto',
        backgroundColor: '#FAFAFA',
      },
    },
    tableInternal: {
      '& td': {
        width: '150px',
        padding: theme.spacing(1.5),
        textAlign: 'right',
      },
    },
    totalRow: {
      backgroundColor: '#FAFAFA',
      '& td:nth-child(2)': {
        textAlign: 'right',
      },
    },
  })
);

export const PricerEconomicsSummaryTable = observer(({ store }: { store: TermStore }) => {
  const classes = useStyles();
  const { loanCommissionStore } = useLoanCommissionStore();
  const { pricerSummaryStore, globalStore, userStore, loanDetails } = store;
  const { lenderFeesFormStore, pricerLoanTerms } = pricerSummaryStore;
  const { oneTimeYieldSpreadPremium } = pricerSummaryStore.form.fields;
  const { originationPointsTpo, originationPointsRoc, originationPointsRetail } = lenderFeesFormStore.form.fields;
  const isInternal = globalStore.lenderInfo?.isInternal;
  const isBackOfficeProcessor = userStore.userInformation?.roles?.includes(Roles.BACK_OFFICE_PROCESSOR);
  const isBackOfficeUser = userStore.isBackOfficeUser;
  const { pricerFeesEnabled } = globalStore.userFeatures;

  useEffect(() => {
    if (pricerSummaryStore?.pricerLoanTerms?.amount) {
      loanCommissionStore?.getTermCommission({
        loanAmount: pricerLoanTerms?.amount,
        rocPoints: originationPointsRetail?.value ?? 0,
        fees: lenderFeesFormStore?.retailFeeRowTotal,
        lenderPremium: oneTimeYieldSpreadPremium?.value ?? 0,
        totalPremium: pricerLoanTerms?.totalPremium ?? 0,
        referralFee: loanDetails?.referralFee,
      });
    }
  }, [oneTimeYieldSpreadPremium.value, pricerLoanTerms.amount, lenderFeesFormStore.rocFeeRowTotal, lenderFeesFormStore?.retailFeeRowTotal,
  originationPointsRoc?.value, originationPointsRetail?.value, oneTimeYieldSpreadPremium?.value, pricerLoanTerms.totalPremium]);

  //const showWholesaleTable = !isInternal && pricerFeesEnabled;
  const showWholesaleTable = false;
  const showCommissionsTable = isBackOfficeUser && !isBackOfficeProcessor && isInternal;

  return (
    <Box
      width="fit-content"
      display="flex"
      flexDirection="column"
      alignItems="flex-end"
    >
      {(showWholesaleTable || showCommissionsTable) && (
        <Typography color="secondary" variant="h6" style={{
          marginTop: 16,
        }}>
          Economics Summary
        </Typography>
      )}
      {/*
      {showWholesaleTable && (
        <Grid item xs={12}>
          <TableContainer className={classes.tableContainer}>
            <Table className={classes.tableExternal} aria-label="Economic Summary">
              <TableRow>
                <TableCell />
                <TableCell>
                  <strong>Your Earnings</strong>
                </TableCell>
                <TableCell>
                  <strong>Rate Buydown</strong> (to Capital Provider)
                </TableCell>
                <TableCell>
                  <strong>Charged to Borrower</strong>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  Yield Spread Premium
                </TableCell>
                <TableCell>
                  {formatPercentage(oneTimeYieldSpreadPremium.value ?? 0)}
                </TableCell>
                <TableCell>
                  -
                </TableCell>
                <TableCell>
                  -
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  Origination Points
                </TableCell>
                <TableCell>
                  {formatPercentage(originationPointsTpo.value ?? 0)}
                </TableCell>
                <TableCell>
                  {formatPercentage(originationPointsRoc.value ?? 0)}
                </TableCell>
                <TableCell>
                  {formatPercentage(
                    parseFloat(originationPointsTpo.value ?? 0) +
                    parseFloat(originationPointsRoc.value ?? 0)
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  Total Fees
                </TableCell>
                <TableCell>
                  {formatCurrency(lenderFeesFormStore.tpoFeeRowTotal ?? 0)}
                </TableCell>
                <TableCell>
                  {formatCurrency(lenderFeesFormStore.rocFeeRowTotal ?? 0)}
                </TableCell>
                <TableCell>
                  {formatCurrency(
                    parseFloat(lenderFeesFormStore.tpoFeeRowTotal ?? 0) +
                    parseFloat(lenderFeesFormStore.rocFeeRowTotal ?? 0)
                  )}
                </TableCell>
              </TableRow>
              <TableRow className={classes.totalRow}>
                <TableCell>
                  <strong>Your Total Earnings</strong>
                </TableCell>
                <TableCell>
                  {formatCurrency(lenderFeesFormStore.totalLenderFees)}
                </TableCell>
                <TableCell>
                  {formatCurrency(lenderFeesFormStore.totalRocFees)}
                </TableCell>
                <TableCell>
                  {formatCurrency(
                    parseFloat(lenderFeesFormStore.totalLenderFees ?? 0) +
                    parseFloat(lenderFeesFormStore.totalRocFees ?? 0)
                  )}
                </TableCell>
              </TableRow>
            </Table>
          </TableContainer>
        </Grid>
      )}
      <br />
                  */}
      {showCommissionsTable && (
        <Grid item xs={12}>
          <TableContainer className={classes.tableContainer}>
            <Table className={classes.tableInternal} aria-label="Economic Summary">
              <TableRow>
                <TableCell />
                <TableCell>
                  <strong>Total Earnings</strong>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  Yield Spread Premium
                </TableCell>
                <TableCell>
                  {formatPercentage(oneTimeYieldSpreadPremium.value ?? 0)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  Add-on Origination Points
                </TableCell>
                <TableCell>
                  {formatPercentage(originationPointsRetail.value ?? 0)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  Rate Buydown Points
                </TableCell>
                <TableCell>
                  {formatPercentage(originationPointsRoc.value ?? 0)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  Add-on Fees
                </TableCell>
                <TableCell>
                  {formatCurrency(lenderFeesFormStore.retailFeeRowTotal ?? 0)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  Rate Buydown Fees
                </TableCell>
                <TableCell>
                  {formatCurrency(lenderFeesFormStore.rocFeeRowTotal ?? 0)}
                </TableCell>
              </TableRow>
              <TableRow className={classes.totalRow}>
                <TableCell>
                  <strong>Your Commission %</strong>
                </TableCell>
                <TableCell>
                  {formatPercentage(
                    loanCommissionStore?.commissionData?.commissionPercentage ?? 0, 4
                  )}
                </TableCell>
              </TableRow>
              <TableRow className={classes.totalRow}>
                <TableCell>
                  <strong>Your Commission Amount</strong>
                </TableCell>
                <TableCell>
                  {formatCurrency(
                    loanCommissionStore?.commissionData?.commissionAmount ?? 0
                  )}
                </TableCell>
              </TableRow>
            </Table>
          </TableContainer>
        </Grid>
      )}
    </Box>
  );
});
