import { Service } from '@roc/feature-app-core';

const url = {
  ALL_CREDIT_REQUESTS:
    '/api/v1/loan/creditbackground/getAllCreditBackgroundRequests',
  ALL_LIQUIDITY_REQUESTS: '/api/v1/loan/plaid/getAllPlaidRequests',

  MARK_VOID: '/api/v1/loan/creditbackground/markAsVoid?requestId=',
  MARK_COMPLETED:
    '/api/v1/loan/creditbackground/markAsManuallyClosed?requestId=',
  DOWNLOAD_REPORTS: '/api/v1/loan/creditbackground/downloadReport',
  SUBMIT_CREDIT_BACKGROUND_REQUEST:
    '/api/v1/loan/creditbackground/submitCreditBackgroundRequest',
  SUBMIT_LIQUIDITY_VERIFICATION_REQUEST:
    '/api/v1/loan/plaid/requestVerification',
  UPDATE_REQUEST: '/api/v1/loan/plaid/resendRequest',
  REGENERATE_REPORT: '/api/v1/loan/plaid/regenerateReport',
  MANAGE_DAILY_REMINDER: '/api/v1/loan/borrowerDetailsVerification/manageDailyReminder?requestId=',
  GET_FORM_LINK: '/api/v1/loan/borrowerDetailsVerification/getFormLink'
};

export class CreditBackgroundService extends Service {
  getAllCreditBackgroundRequests(
    page: number,
    pageSize: number,
    sortDir: string,
    sortBy: string,
    filters: object
  ) {
    return this.get(
      url.ALL_CREDIT_REQUESTS,
      {
        page,
        pageSize,
        sortDir,
        sortBy,
        filters,
      },
      {
        disableGlobalLoading: true,
      }
    );
  }
  getAllLiquidityRequests(
    page: number,
    pageSize: number,
    sortDir: string,
    sortBy: string,
    filters: object
  ) {
    return this.get(
      url.ALL_LIQUIDITY_REQUESTS,
      {
        page,
        pageSize,
        sortDir,
        sortBy,
        filters,
      },
      {
        disableGlobalLoading: true,
      }
    );
  }
  markAsVoid(requestId) {
    return this.post(url.MARK_VOID + requestId, {});
  }
  markAsManuallyClosed(requestId) {
    return this.post(url.MARK_COMPLETED + requestId, {});
  }
  downloadReports(requestId) {
    return this.get(
      url.DOWNLOAD_REPORTS,
      {
        requestId,
      },
      {
        responseType: 'blob',
      }
    );
  }
  submitCreditBackgroundRequest(borrower) {
    return this.post(url.SUBMIT_CREDIT_BACKGROUND_REQUEST, borrower);
  }
  submitLiquidityVerificationRequest(borrower) {
    return this.post(url.SUBMIT_LIQUIDITY_VERIFICATION_REQUEST, borrower);
  }
  updateRequest(requestId) {
    return this.post(url.UPDATE_REQUEST, requestId);
  }

  regenerateReport(requestId) {
    return this.get(`${url.REGENERATE_REPORT}?id=${requestId}`);
  }

  manageDailyReminder(requestId) {
    return this.post(url.MANAGE_DAILY_REMINDER + requestId, {});
  }

  getFormLink(requestId: number) {
    return this.get(`${url.GET_FORM_LINK}?requestId=${requestId}`, {});
  }
}
