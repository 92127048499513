import { GridRowSelectedEvent } from '@roc/ui';
import { ApiResponse, GlobalStore } from '@roc/feature-app-core';
import { action, makeObservable, observable } from 'mobx';
import { GridStore } from '@roc/feature-app-core';
import { RentalPricerBaseService } from '../services/rentalPricerBaseService';

export class RentalPricerGridStore extends GridStore {
  private globalStore: GlobalStore;
  private rentalPricerService: RentalPricerBaseService;

  constructor(
    globalStore: GlobalStore,
    rentalPricerService: RentalPricerBaseService
  ) {
    super(() => this.fetchRentalPricerGrid());
    this.globalStore = globalStore;
    this.rentalPricerService = rentalPricerService;

    makeObservable(this, {});
  }

  private async fetchRentalPricerGrid() {
    try {
      const response = await this.rentalPricerService.getRentalPricerGrid(
        this.gridData.meta.pageNumber,
        this.gridData.meta.pageSize,
        this.gridData.meta.sortDir,
        this.gridData.meta.sortBy,
        this.gridData.meta.filters
      );
      return response;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: `Error while fetching the Rental quotes grid`,
      });
    }
  }
}
