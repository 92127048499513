import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import { downloadDocument } from '@roc/feature-utils';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { Button, StandardDialog } from '@roc/ui';
import { useMemo, useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    document: {
      width: 'fit-content',
      margin: '0 auto',
    },
    controlPanel: {
      left: 0,
      right: 0,
      position: 'absolute',
      bottom: '100px',
      display: 'flex',
      justifyContent: 'center',
    },
    control: {
      zIndex: 11,
      fontSize: '16px',
      color: '#fff',
      backgroundColor: '#3d464d',
      borderRadius: '5px',
      padding: '8px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    icon: {
      margin: '0 4px',
      fontSize: '22px',
      cursor: 'pointer',
    },
    page: {
      margin: theme.spacing(2),
      boxShadow: '2px 2px 4px #888',
      width: 'fit-content',
    },
    dialogPaper: {
      height: '100%'
    },
    dialogContent: {
      margin: theme.spacing(3),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      border: '1px solid #EFEFEF',
      backgroundColor: '#EFEFEF',
    },
    imageContainer: {
      width:'100%',
    }
  })
);

const ControlPanel = props => {
  const classes = useStyles();
  const { scale, setScale } = props;

  const isMinZoom = scale < 0.6;
  const isMaxZoom = scale >= 2.0;

  const zoomOut = () => {
    if (!isMinZoom) setScale(scale - 0.1);
  };

  const zoomIn = () => {
    if (!isMaxZoom) setScale(scale + 0.1);
  };

  return (
    <div className={classes.controlPanel}>
      <div className={classes.control}>
        <ZoomOutIcon onClick={zoomOut} className={classes.icon} />
        <span>{(scale * 100).toFixed()}%</span>
        <ZoomInIcon onClick={zoomIn} className={classes.icon} />
      </div>
    </div>
  );
};

export interface PDFPreviewProps {
  open: boolean;
  title: string;
  data: ArrayBuffer;
  // eslint-disable-next-line @typescript-eslint/ban-types
  headers: object;
  fileName?: string;
  onClose: () => void;
}

export const ImagePreview = (props: PDFPreviewProps) => {
  const { title, data, headers, fileName } = props;
  const classes = useStyles();
  const [scale, setScale] = useState(1.0);

  const imageURL = useMemo(() => URL.createObjectURL(new Blob([data])) ,[data]);

  const dialogContent = (
    <>
      <div className={classes.imageContainer}>
        {data && (
          <img src={imageURL} style={{width:scale*100+'%'}} alt={"Preview"} />
        )}
      </div>
      <ControlPanel scale={scale} setScale={setScale} />
    </>
  );

  const dialogActions = (
    <Button
      testId="download-pdf"
      color="primary"
      variant="contained"
      onClick={() => downloadDocument(props.data, props.headers, 'download', fileName)}
    >
      Download
    </Button>
  );

  return (
    <StandardDialog
      maxWidth={'xl'}
      open={props.open}
      title={props.title}
      removeContentBox={true}
      dialogContentclasses={{
        root: classes.dialogContent,
      }}
      handleClose={props.onClose}
      dialogContent={dialogContent}
      dialogActions={dialogActions}
      classes={{
        'paper': classes.dialogPaper
      }}
    />
  );
};
