import { flow, makeObservable, observable } from 'mobx';
import { ApiResponse } from '@roc/feature-app-core';
import { FormStore } from '@roc/feature-app-core';
import { CompanyProfileService } from '../services/CompanyProfileService';
import { UserNotificationInfo } from '../types/companyProfileTypes';


const form = {
  fields: {
    currentNotification: {
      value: false,
      error: null,
      rule: '',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export class CurrentNotificationStore extends FormStore {

  private userNotificationInfo: UserNotificationInfo;
  private companyProfileService: CompanyProfileService;
  open = false;

  constructor(globalStore) {
    super(form, globalStore);
    this.companyProfileService = new CompanyProfileService();
    makeObservable(this, {
      open: observable,
      getUserNotificationPreferences: flow,
      checkAndSaveUserNotificationPreferences: flow,
    });
  }

  private openDialog = () => {
    this.open = true;
  };

  private closeDialog = () => {
    this.open = false;
  };

  *getUserNotificationPreferences() {
    const userNotificationResponse: ApiResponse = yield this.companyProfileService.getUserNotificationInfo();
    this.userNotificationInfo = userNotificationResponse.data.data;
    if (this.userNotificationInfo.currentNotification) {
      this.openDialog();
    }
  }

  *checkAndSaveUserNotificationPreferences() {
    try {
      const values = this.getFormValues();
      // if the "Do not show checkbox" is checked
      if (values.currentNotification) {
        const newNotificationInfo = {
          ...this.userNotificationInfo,
          currentNotification: false,
        };
        yield this.companyProfileService.updateUserNotificationInfo(
          newNotificationInfo
        );
        this.userNotificationInfo = newNotificationInfo;
      }
      this.closeDialog();
    } catch (error) {
      // do nothing
    }
  }

}
