import { observer } from "mobx-react";
import { makeStyles, Theme, Typography, Grid } from '@material-ui/core';
import { formatDate, formatPrice } from "../../utils/formatter";
import { ComparableProperty } from "../../types";
import { useStore } from "@roc/client-portal-shared/hooks";


const useStyles = makeStyles((theme: Theme) => ({
  priceFormat: {
    fontSize: '52px',
    fontFamily: 'Roboto'
  },
  textPrice: {
    fontWeight: 'bold'
  },
  priceContainer: {
    padding: '2px 5px'
  },
  fields: {
    padding: '0px !important',
  },
}));




export const PriceField = observer(
  ({ property }: { property: ComparableProperty }) => {
    const { compToolStore } = useStore();
    const classes = useStyles();
    const { data } = property;

    return (<>
      <Grid container spacing={1} justifyContent="space-between" className={classes.priceContainer}>
        <Grid item xs={6} className={classes.fields}>
          <Typography align="left" variant="subtitle1" className={classes.textPrice}>
            Latest {compToolStore.rentalComps ? 'Rent' : 'Sale'}
          </Typography>
        </Grid>
        <Grid item xs={6} className={classes.fields}>
          <Typography align="right" variant="subtitle1" className={classes.textPrice}>
            {data.price_cut ? `${data.price_cut.toFixed(2)} %` : '-'} Price Cut
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.fields}>
          <Typography align="left" className={classes.priceFormat}>
            {formatPrice(data.price, data.rent, compToolStore.rentalComps)}
            {compToolStore.rentalComps && <strong><span style={{ fontSize: '18px' }}>/mo</span></strong>}
          </Typography>
        </Grid>
        <Grid item xs={6} className={classes.fields}>
          <Typography align="left" variant="subtitle1" className={classes.textPrice}>
            on {compToolStore.rentalComps ? formatDate(data.rent_date) : formatDate(data.sale_date)}
          </Typography>
        </Grid>
        <Grid item xs={6} className={classes.fields}>
          <Typography align="right" variant="subtitle1" className={classes.textPrice}>
            {data.days_on_market ? data.days_on_market : '-'} DOM
          </Typography>
        </Grid>
      </Grid>
    </>
    );
  }
);