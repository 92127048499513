import React, { ReactNode } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { Add } from '@material-ui/icons';
import { Button, Card, GoogleMap } from '@roc/ui';
import { observer } from 'mobx-react';
import { useMultifamilyStore } from '../../hooks/useMultiFamilyStore';
import PropertyModal, { PropertyModalState } from './propertyModal';

const useStyles = makeStyles(theme =>
  createStyles({
    cardSpacing: {
      paddingRight: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    card: {
      background: theme.palette.background.default,
    },
    mapStyles: {
      position: `relative`,
      width: `100%`,
      height: `150px`,
    },
  })
);

interface PropertyInformationProps {
  renderSteps: ReactNode;
}

const MultiFamilyPropertyInformation = (props: PropertyInformationProps) => {
  const { renderSteps } = props;
  const classes = useStyles();
  const { multiFamilyStore } = useMultifamilyStore();
  const { propertyStore } = multiFamilyStore;

  const [modalState, setModalState] = React.useState<PropertyModalState>(null);

  const closeModal = () => {
    setModalState(null);
    propertyStore.reset();
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4">Property Information</Typography>
          <br />
          <Typography variant="body1">
            Please add a property and tell us how the property was sourced and
            how the loan will be repaid. Remember we do NOT lend on borrower
            occupied properties.
          </Typography>
        </Grid>
      </Grid>

      <br />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="secondary"
            startIcon={<Add />}
            onClick={() => setModalState(PropertyModalState.ADD)}
            testId="add"
          >
            ADD NEW PROPERTY
          </Button>
        </Grid>
      </Grid>

      <br />

      {multiFamilyStore.properties.length > 0 && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container>
              {multiFamilyStore.properties.map(property => (
                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={4}
                  className={classes.cardSpacing}
                  key={property.propertyId}
                >
                  <Card
                    color="secondary"
                    title={property.address}
                    value={property}
                    className={classes.card}
                    onEdit={
                      !property.appraisalId
                        ? property => {
                          setModalState(PropertyModalState.EDIT);
                          multiFamilyStore.propertyStore.loadProperty(property);
                        }
                        : null
                    }
                    onView={
                      property.appraisalId
                        ? property => {
                          setModalState(PropertyModalState.VIEW);
                          multiFamilyStore.propertyStore.loadProperty(property);
                        }
                        : null
                    }
                    onRemove={property => {
                      multiFamilyStore.deleteProperty(property.propertyId);
                    }}
                  >
                    {property.latitude && property.longitude ? (
                      <GoogleMap
                        mapStyles={classes.mapStyles}
                        latitude={property.latitude}
                        longitude={property.longitude}
                      />
                    ) : null}
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      )}

      <PropertyModal
        modalState={modalState}
        open={modalState != null}
        handleClose={closeModal}
        handleAdd={() => {
          multiFamilyStore.addProperty(propertyStore.getProperty());
          propertyStore.reset();
          closeModal();
        }}
        handleEdit={() => {
          multiFamilyStore.updateProperty(propertyStore.getProperty());
          propertyStore.reset();
          closeModal();
        }}
        renderSteps={renderSteps}
      />
    </>
  );
};

export default observer(MultiFamilyPropertyInformation);
