import { FormStore } from '@roc/feature-app-core';
import { GlobalStore } from '@roc/feature-app-core';
import { action, computed, makeObservable, observable } from 'mobx';
import { InstaQuoteRanges } from '../../utils/types';
import { LoanEconomicsStore } from './loanEconomicsStore';

const leverageForm = {
  fields: {
    loanToCost: {
      value: 0,
      error: null,
      rule: '',
    },
    constructionReserve: {
      value: 0,
      error: null,
      rule: '',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

const LEVERAGE_EXCEPTION_RANGES = {
  minLoanToCost: 0,
  maxLoanToCost: 100,
  minConstructionReserve: 0,
  maxConstructionReserve: 100,
};

export class LeverageFormStore extends FormStore {
  private globalStore: GlobalStore;
  private loanEconomicsStore: LoanEconomicsStore;

  instaQuote: boolean;
  propertyFormHash: string;

  constructor(
    globalStore: GlobalStore,
    leverageStore: LoanEconomicsStore,
    instaQuote: boolean
  ) {
    super(leverageForm, globalStore);
    this.globalStore = globalStore;
    this.loanEconomicsStore = leverageStore;
    this.instaQuote = instaQuote;
    this.propertyFormHash = '';

    makeObservable(this, {
      calculatedFields: computed,
      ranges: computed,
      propertyFormHash: observable,
      setPropertyFormHash: action,
    });
  }

  reset() {
    super.reset();
    this.propertyFormHash = '';
  }

  loadRanges(ranges: InstaQuoteRanges) {
    const values = this.getFormValues();

    let loanToCost = values.loanToCost > 0 
      ? values.loanToCost 
      : ranges.defaultLoanToCost;

    loanToCost = Math.max(loanToCost, ranges.minLoanToCost);
    loanToCost = Math.min(loanToCost, ranges.maxLoanToCost);

    let constrReserve = values.constructionReserve > 0
      ? values.constructionReserve
      : ranges.defaultConstructionReserve;
      
    constrReserve = Math.max(constrReserve, ranges.minConstructionReserve);
    constrReserve = Math.min(constrReserve, ranges.maxConstructionReserve);
    constrReserve = Math.min(
      constrReserve,
      this.calculateMaxConstuctionReserve(ranges, loanToCost)
    );

    this.onFieldChange('loanToCost', loanToCost);
    this.onFieldChange('constructionReserve', constrReserve);
  }

  private calculateMaxConstuctionReserve(ranges, loanToCost) {
    const totalAfterRepairValue = this.calculatedFields.totalAfterRepairValue;
    const totalProposedConstructionBudget = this.calculatedFields
      .totalProposedConstructionBudget;
    const maxLoanAmount = totalAfterRepairValue * (ranges.maxARLTV / 100);
    const initialLoanAmount =
      this.calculatedFields.totalPurchasePrice * (loanToCost / 100);
    return Math.floor(
      ((maxLoanAmount - initialLoanAmount) / totalProposedConstructionBudget) *
        100
    );
  }

  get calculatedFields() {
    const properties = this.loanEconomicsStore.quoteTypeStore.propertiesStore.getProperties();
    const loanToCost = this.form.fields.loanToCost.value;
    const constructionReserve = this.form.fields.constructionReserve.value;

    return this.loanEconomicsStore.caclulateFields(properties, {
      loanToCost,
      constructionReserve,
      propertyFormHash: this.propertyFormHash,
    });
  }

  get ranges() {
    return this.instaQuote
      ? this.loanEconomicsStore.instaQuoteRanges
      : LEVERAGE_EXCEPTION_RANGES;
  }

  setPropertyFormHash(propertyFormHash: string) {
    this.propertyFormHash = propertyFormHash;
  }
}
