import { useEffect, useState } from "react";
import { createData, createHeader, createName, createValue } from "../loanColumnsHelper/loanColumnsHelper";
import { useLoanSummaryStore } from "../../hooks/useLoanSummaryStore";
import { formatCurrency, formatPercentage, showPercentage } from "@roc/ui/utils";
import { format } from "path";


export const sourcesColumns = () => {

  const { loanSummaryStore } = useLoanSummaryStore();
  const loanDetails = loanSummaryStore.loanSummary;

  let calculatedLoanData = loanDetails?.calculatedLoanData;
  ;

  const [ratios, setRatios] = useState({
    settlementCosts: 4 * 0.01 * calculatedLoanData.aggregatePurchasePrice,
    totalUsesOfCapital: 0,
    purchasePriceOverUses: 0,
    totalRenovationBudgetOverUses: 0,
    interestReserveOverUses: 0,
    settlementCostsOverUses: 0,
    cashAtPurchase: 0,
    initialLoanAmountOverSources: 0,
    totalHoldbackAmountOverSources: 0,
    cashAtPurchaseOverSources: 0,
    totalSourcesOfCapital: 0
  });

  useEffect(() => {
    setValues(loanDetails);
  }, []);

  const setValues = (data) => {
    const totalUsesOfCapital = Number(calculatedLoanData.aggregatePurchasePrice) + Number(calculatedLoanData.aggregateRenovationBudget) + Number(data?.interestReserve) + Number(ratios.settlementCosts);
    const cashAtPurchase = totalUsesOfCapital - calculatedLoanData.aggregateRenovationBudget - data?.initialLoanAmount;
    const totalSourcesOfCapital = Number(data?.initialLoanAmount) + Number(calculatedLoanData.aggregateRenovationBudget) + Number(cashAtPurchase);
    const settlementCosts = 4 * 0.01 * calculatedLoanData.aggregatePurchasePrice;
    const ratiosPct = {
      totalUsesOfCapital: totalUsesOfCapital,
      purchasePriceOverUses: Math.round((calculatedLoanData.aggregatePurchasePrice / totalUsesOfCapital) * 100),
      totalRenovationBudgetOverUses: Math.round((calculatedLoanData.aggregateRenovationBudget / totalUsesOfCapital) * 100),
      interestReserveOverUses: Math.round((data?.interestReserve / totalUsesOfCapital) * 100),
      settlementCostsOverUses: Math.round((settlementCosts / totalUsesOfCapital) * 100),
      cashAtPurchase: cashAtPurchase,
      initialLoanAmountOverSources: Math.round((data?.initialLoanAmount / totalSourcesOfCapital) * 100),
      totalHoldbackAmountOverSources: Math.round((calculatedLoanData.aggregateRenovationBudget / totalSourcesOfCapital) * 100),
      cashAtPurchaseOverSources: Math.round((cashAtPurchase / totalSourcesOfCapital) * 100),
      totalSourcesOfCapital: totalSourcesOfCapital
    }
    setRatios({ ...ratios, ...ratiosPct });
  }
  const sourcesColumns = {
    sourcesCol1: [
      createHeader('-'),
      createName('Capital Provider Initial Loan'),
      createName('Capital Provider Holdbacks'),
      createName('Equity'),
      createName('Total Sources')
    ],

    sourcesCol2: [
      createHeader('Absolute'),
      createValue(formatCurrency(loanDetails?.initialLoanAmount, 0)),
      createValue(formatCurrency(calculatedLoanData.aggregateRenovationBudget, 0)),
      createValue(formatCurrency(ratios.cashAtPurchase, 0)),
      createValue(formatCurrency(ratios.totalSourcesOfCapital, 0)),

    ],

    sourcesCol3: [
      createHeader('Source'),
      createValue(showPercentage(ratios.initialLoanAmountOverSources)),
      createValue(showPercentage(ratios.totalHoldbackAmountOverSources)),
      createValue(showPercentage(ratios.cashAtPurchaseOverSources))
    ],
  }
  return sourcesColumns;
}



export const UsesColumns = () => {

  const { loanSummaryStore } = useLoanSummaryStore();
  const loanDetails = loanSummaryStore.loanSummary;

  let calculatedLoanData = loanDetails?.calculatedLoanData;
  let interestReserve = loanDetails?.interestReserve;

  const [ratios, setRatios] = useState({
    settlementCosts: 4 * 0.01 * calculatedLoanData.aggregatePurchasePrice,
    totalUsesOfCapital: 0,
    purchasePriceOverUses: 0,
    totalRenovationBudgetOverUses: 0,
    interestReserveOverUses: 0,
    settlementCostsOverUses: 0,
    cashAtPurchase: 0,
    initialLoanAmountOverSources: 0,
    totalHoldbackAmountOverSources: 0,
    cashAtPurchaseOverSources: 0,
    totalSourcesOfCapital: 0
  });

  useEffect(() => {
    setValues(loanDetails);
  }, []);

  const setValues = (data) => {
    const totalUsesOfCapital = Number(calculatedLoanData.aggregatePurchasePrice) + Number(calculatedLoanData.aggregateRenovationBudget) + Number(data?.interestReserve) + Number(ratios.settlementCosts);
    const cashAtPurchase = totalUsesOfCapital - calculatedLoanData.aggregateRenovationBudget - data?.initialLoanAmount;
    const totalSourcesOfCapital = Number(data?.initialLoanAmount) + Number(calculatedLoanData.aggregateRenovationBudget) + Number(cashAtPurchase);
    const settlementCosts = 4 * 0.01 * calculatedLoanData.aggregatePurchasePrice;
    const ratiosPct = {
      totalUsesOfCapital: totalUsesOfCapital,
      purchasePriceOverUses: Math.round((calculatedLoanData.aggregatePurchasePrice / totalUsesOfCapital) * 100),
      totalRenovationBudgetOverUses: Math.round((calculatedLoanData.aggregateRenovationBudget / totalUsesOfCapital) * 100),
      interestReserveOverUses: Math.round((data?.interestReserve / totalUsesOfCapital) * 100),
      settlementCostsOverUses: Math.round((settlementCosts / totalUsesOfCapital) * 100),
      cashAtPurchase: cashAtPurchase,
      initialLoanAmountOverSources: Math.round((data?.initialLoanAmount / totalSourcesOfCapital) * 100),
      totalHoldbackAmountOverSources: Math.round((calculatedLoanData.aggregateRenovationBudget / totalSourcesOfCapital) * 100),
      cashAtPurchaseOverSources: Math.round((cashAtPurchase / totalSourcesOfCapital) * 100),
      totalSourcesOfCapital: totalSourcesOfCapital
    }
    setRatios({ ...ratios, ...ratiosPct });
  }
  const usesColumns = {

    usesCol1: [
      createHeader('-'),
      createName('Purchase Price'),
      createName('Total Renovation Budget'),
      createName('Interest Reserve'),
      createName('Settlement Costs'),
      createName('Total Uses')
    ],

    usesCol2: [
      createHeader('Absolute'),
      createValue(formatCurrency(calculatedLoanData.aggregatePurchasePrice, 0)),
      createValue(formatCurrency(calculatedLoanData.aggregateRenovationBudget, 0)),
      createValue(formatCurrency(interestReserve, 0)),
      createValue(formatCurrency(ratios.settlementCosts, 0)),
      createValue(formatCurrency(ratios.totalUsesOfCapital, 0)),
    ],

    usesCol3: [
      createHeader('Source'),
      createValue(showPercentage(ratios.purchasePriceOverUses)),
      createValue(showPercentage(ratios.totalRenovationBudgetOverUses)),
      createValue(showPercentage(ratios.interestReserveOverUses)),
      createValue(showPercentage(ratios.settlementCostsOverUses))
    ],

  }
  return usesColumns;
}
