import { Grid } from '@material-ui/core';
import { StandardDialog, Button } from '@roc/ui';
import { RequestedClosingDate } from './requestedClosingDate';
import { RequestedClosingDateStore } from '../../../stores/documentForms/requestedClosingDateStore';

export type RequestedClosingDateDialogProps = {
  open?: boolean;
  onClose?: () => void;
  loanId: string;
  store: RequestedClosingDateStore;
};

export const RequestedClosingDateDialog = (
  props: RequestedClosingDateDialogProps
) => {
  const { open, onClose, loanId, store } = props;

  const onSubmit = () => {
    store.submitrequestedClosingDateState();
    if (!onClose) return;
    onClose();
  };

  return (
    <StandardDialog
      open={open}
      title="Requested Closing Date"
      maxWidth="xs"
      handleClose={onClose}
      dialogContent={<RequestedClosingDate store={store} />}
      dialogActions={
        <Grid container spacing={1} justifyContent="flex-end">
          <Grid item>
            <Button
              onClick={onClose}
              color="primary"
              variant="outlined"
              testId="cancel"
            >
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={onSubmit}
              color="primary"
              variant="contained"
              testId="confirm"
              disabled={!store.shouldBeAbleToSubmitRequestedClosingDate()}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      }
    />
  );
};

export default RequestedClosingDateDialog;
