import { Grid, Box } from "@material-ui/core";
import { Card } from "@roc/ui";
import { observer } from "mobx-react";
import { activityTypeOptionsMapping } from "../leads/constants/constants";
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@roc/ui';
import { Add } from '@material-ui/icons';
import { HtmlContainer } from '../leadDetails/htmlContainer';
import { EditLeadActivityDialog } from "./editLeadActivityDialog";
import { Note } from "./note";

const useStyles = makeStyles(theme => ({
  button: {
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(2),
    minWidth: '150px',
  },
  notesScroll: {
    backgroundColor: '#FAFAFA',
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    maxHeight: '800px',
    overflowY: 'auto',
    overflowX: 'hidden',
    boxShadow: '0 0 2px #DDD inset'
  },
}));

export const ContactActivitiesCard = observer(({ leadDetailsStore }) => {
  const classes = useStyles();
  const { editLeadActivityStore } = leadDetailsStore;

  const action = (
    <Box p={1} mb={-10}>
      <Button
        testId="add-lead-activity"
        className={classes.button}
        variant="contained"
        size="small"
        color="primary"
        onClick={() => editLeadActivityStore.openAddLeadActivity()}
      >
        <Add /> Add Activity
      </Button>
    </Box>
  );

  const getActivityType = leadActivity => {
    return activityTypeOptionsMapping.find(
      opt => opt.value === leadActivity.activityType
    )?.label;
  };

  return (
    <Card
      title="Activities"
      cardHeaderProps={{ action }}
      cardContentProps={{ className: classes.notesScroll }}
    >
      {leadDetailsStore.leadActivities.length > 0 ? (
        <Grid container spacing={2}>
          {leadDetailsStore.leadActivities.map(leadActivity => (
            <Grid item xs={12}>
              <Note
                title={`${leadActivity.sentDate} - ${getActivityType(
                  leadActivity
                )}`}
                onEdit={() =>
                  editLeadActivityStore.openEditLeadActivity(leadActivity)
                }
                createdDate={leadActivity.createdDate}
                lastUpdatedDate={leadActivity.lastUpdatedDate}
                createdBy={leadActivity.createdByName}
                lastModifiedBy={leadActivity.lastUpdatedByName}
              >
                <HtmlContainer
                  html={
                    leadActivity.emailContent
                      ? leadActivity.emailContent.substring(0, 300) + '...'
                      : leadActivity.text
                  }
                />
              </Note>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Box textAlign="center">No records.</Box>
      )}
      <EditLeadActivityDialog editLeadActivityStore={editLeadActivityStore} />
    </Card>
  );
});
