import { Page } from '@roc/feature-app-core';
import { Route } from 'react-router';
import { WhatsNew } from '../whatsNew';

export const getWhatsNewRoutesConfig = (basePath: string, baseUrl: string) => {
  return {
    whatsNew: {
      path: `${basePath}/whats-new`,
      url: `${baseUrl}/whats-new`,
      documentTitle: "What's New",
    },
  };
};

export const getWhatsNewRoutes = (basePath: string, baseUrl: string) => {
  const config = getWhatsNewRoutesConfig(basePath, baseUrl);
  return {
    whatsNew: (
      <Route exact path={config.whatsNew.path}>
        <Page routeDefinition={config.whatsNew}>
          <WhatsNew />
        </Page>
      </Route>
    ),
  };
};
