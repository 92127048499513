import { FormStore, GlobalStore } from "@roc/feature-app-core";
import { action, flow, makeObservable, observable } from 'mobx';

const lenderfields = {
  fields: {
    companyId: {
      value: '',
      error: null,
      rule: '',
      message: '',
    },
    accountName: {
      value: '',
      error: null,
      rule: 'required',
      message: 'The account name is required.',
    },
    routingNumber: {
      value: '',
      error: null,
      rule: 'required|regex:/^\\d+$/',
      message: 'The Routing number must be 9 digits long',
    },
    accountNumber: {
      value: '',
      error: null,
      rule: 'required|regex:/^\\d+$/',
      message: 'The account number is required',
    },
    bankName: {
      value: '',
      error: null,
      rule: 'required',
      message: 'The bank name is required.',
    },
    fullName: {
      value: '',
      error: null,
      rule: 'required',
      message: 'The name is required.',
    },
    phoneNumber: {
      value: '',
      error: null,
      rule: 'required|regex:/^\\d{3}-?\\d{3}-?\\d{4}$/|alpha_dash',
      message: 'phone must be a number with the format xxx-xxx-xxxx',
    },
    emailAddress: {
      value: '',
      error: null,
      rule: 'required',
      message: 'The email address is required.',
    },
    streetName: {
      value: '',
      error: null,
      rule: 'required',
      message: 'The street name is required.',
    },
    city: {
      value: '',
      error: null,
      rule: 'required',
      message: 'The city is required.',
    },
    zipCode: {
      value: '',
      error: null,
      rule: 'required',
      message: 'The zip code is required.',
    },
    state: {
      value: '',
      error: null,
      rule: 'required',
      message: 'The state is required.',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
}

export class VerifyBankInformationDocusignFormStore extends FormStore {
  constructor(globalStore: GlobalStore) {
    super(lenderfields, globalStore);

    makeObservable(this, {
      onChange: action,
    });
  }

  onChange = (field, value) => {
    this.onFieldChange(field, value);
  };
}
