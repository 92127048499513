import React from 'react';
import { Link, Step, StepLabel, Stepper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { isNil } from '@roc/feature-utils';

export interface VerticalStepperProps {
  steps: Array<VerticalStepObj>;
  activeStep: number;
}

export interface VerticalStepObj {
  label?: string;
  content: JSX.Element;
  onBack?: () => void;
  onNext?: () => void;
  allowBack?: boolean;
  allowNext?: boolean;
  nextButtonText?: string;
  backButtonText?: string;
  subLabels?: Array<string>;
  handleSubLabelClick?: (number) => void;
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    stepperSection: {
      borderRadius: '16px 0px 0px 16px',
      background: 'rgba(40, 83, 145, 0.10)',
      height: '100%'
    },
    stepper: {
      background: 'transparent',
      padding: theme.spacing(6, 4),
      position: 'sticky',
      top: 0,
    }
  })
);

export const VerticalStepper: React.FC<VerticalStepperProps> = ({
  steps,
  activeStep,
}) => {
  const classes = useStyle();
  return (
    <div className={classes.stepperSection}>
      <Stepper activeStep={activeStep} orientation="vertical"
        className={classes.stepper}>
        {
          steps.filter(step => step.label).map((step) => (
            <Step key={step.label}>
              <StepLabel
                optional={!isNil(step.subLabels) ?
                  step.subLabels.map((subLabel, index) => {
                    return (
                      <div key={subLabel}>
                        <Link variant="caption" onClick={() => step.handleSubLabelClick(index)}>
                          {subLabel}
                        </Link>
                      </div>
                    )
                  })
                  : null
                }
              >
                {step.label}
              </StepLabel>
            </Step>
          ))
        }
      </Stepper>
    </div >
  );
};