import { alpha } from '@material-ui/core/styles';

// ----------------------------------------------------------------------

export default function Backdrop(theme) {
  return {
    MuiBackdrop: {
      root: {
        backgroundColor: alpha(theme.palette.grey[800], 0.8),
      },
      invisible: {
        background: 'transparent',
      },
    },
  };
}
