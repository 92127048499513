import { DocumentName } from './documents';

export const DEAL_ECONOMICS = 'DEAL_ECONOMICS';
export const REQUESTED_CLOSING_DATE = 'Requested Closing Date';
export const REFERRAL_INFORMATION = 'Referral Information';
export const EXPECTED_CLOSING_DATE = 'Expected Closing Date';
export const DUE_DILIGENCE_PAYMENT = 'Due Diligence Payment';
export const LOAN_FAST_TRACK_PAYMENT = 'Loan Fast Track Payment';
export const TERM_SHEET = 'Term Sheet';
export const LOAN_TERMS = 'Loan Terms';
export const BORROWER_SURVEY_QUESTIONNAIRE = 'BORROWER_SURVEY_QUESTIONNAIRE';
export const BORROWER_ENTITY = 'BORROWER_ENTITY';
export const LENDER_FEES = 'LENDER_FEES';
export const TITLE_AGENT = 'TITLE_AGENT';
export const INSURANCE_PROVIDER = 'INSURANCE_PROVIDER';
export const FUNDING_PREFERENCE = 'FUNDING_PREFERENCE';
export const EXPECTED_WIRE_RELEASE_DATE = 'EXPECTED_WIRE_RELEASE_DATE';
export const INSURANCE_APPROVAL = 'INSURANCE_APPROVAL';
export const LOAN_FUNDING_TEMPLATE = 'LOAN_FUNDING_TEMPLATE';
export const BORROWER_CLOSING_AGENT = 'BORROWER_CLOSING_AGENT';
export const SCOPE_OF_WORK_LINK = 'SCOPE_OF_WORK_LINK';
export const SCOPE_OF_WORK_LINK_V2 = 'SCOPE_OF_WORK_LINK_V2';
export const SCOPE_OF_WORK_FORM = 'SCOPE_OF_WORK_FORM';
export const SCOPE_OF_WORK_FORM_V2 = 'SCOPE_OF_WORK_FORM_V2';
export const NOTE_ALLONGE = 'NOTE_ALLONGE';
export const ASSIGNMENT_AND_CONVEYANCE = 'ASSIGNMENT_AND_CONVEYANCE';
export const RATE_LOCK_EXTENSION = 'Rate Lock Extension';
export const POINT_OF_CONTACT = 'POINT_OF_CONTACT';
export const TITLE_COMPANY_BANK_INFORMATION = 'TITLE_COMPANY_BANK_INFORMATION';
export const RESOLUTION_ITEM_FORM = 'RESOLUTION_ITEM_FORM';
export const RESOLUTION_LINE_ITEM_PREFIX = 'Resolution - ';
export const DRAW_REPORT = 'Draw Report';
export const BORROWER_BACKGROUND_REVIEW = 'Borrower Background Review';
export const BORROWER_CREDIT_REVIEW = 'Borrower Credit Review';
export const TRACK_RECORD_REVIEW = 'Borrower Track Record Review';
export const TRACK_RECORD = 'Track Record';
export const CREDIT_REPORT = 'Credit Report';
export const TRIMERGE_CREDIT_REPORT = 'Trimerge Credit Report';
export const CLOSING_CONDITION = 'Closing Condition - ';
export const APPRAISAL_ORDER_DETAILS = 'Appraisal Order Details';
export const APPRAISAL_DETAILS = 'Appraisal Details';
export const ACH_FORM = 'ACH Consent Form';
export const APPRAISAL_REPORT = 'Appraisal Report';
export const APPRAISAL_REPORT_AS_IS_RENT =
  'Appraisal Report (As-Is w/ Rent Schedule)';
export const APPRAISAL_REPORT_AS_IS = 'Appraisal Report (As is)';
export const APPRAISAL_REPORT_ARV = 'Appraisal Report (ARV)';
export const APPRAISAL_RESOLUTIONS = 'Appraisal Resolutions';
export const BORROWER_SETUP = 'Borrower Setup';
export const APPRAISAL_REVIEW_REPORT = 'Appraisal Review Report';
export const BANK_STATEMENT_PREFIX = 'Bank Statement #';
export const LEASE_INFORMATION_UNIT_PREFIX = 'Lease Information Unit';
export const TAX_ESCROW = 'Tax Escrow';
export const POST_DISASTER_INSPECTION = 'Post Disaster Inspection';
export const PERSONAL_IDENTIFICATION = 'Personal Identification';
export const WIMBA_NAME = 'Wimba Title Insurance Agency, LLC';
export const CONSTRUCTION_FEASIBILITY_REVIEW = 'Construction Feasibility Review';
export const INTERNAL_VALUATION_REPORT = 'Internal Valuation Report';

export const DOCUMENT_FORMS = {
  [DocumentName.DEAL_ECONOMICS]: DEAL_ECONOMICS,
  [DocumentName.REQUESTED_CLOSING_DATE]: REQUESTED_CLOSING_DATE,
  [DocumentName.EXPECTED_CLOSING_DATE]: EXPECTED_CLOSING_DATE,
  [DocumentName.DUE_DILIGENCE_PAYMENT]: DUE_DILIGENCE_PAYMENT,
  [DocumentName.APPRAISAL_ORDER_DETAILS]: APPRAISAL_ORDER_DETAILS,
  [DocumentName.APPRAISAL_DETAILS]: APPRAISAL_DETAILS,
  [DocumentName.LOAN_FAST_TRACK_PAYMENT]: LOAN_FAST_TRACK_PAYMENT,
  [DocumentName.TERM_SHEET]: TERM_SHEET,
  [DocumentName.LOAN_TERMS]: TERM_SHEET,
  [DocumentName.BORROWER_SURVEY_QUESTIONNAIRE]: BORROWER_SURVEY_QUESTIONNAIRE,
  [DocumentName.EXPECTED_WIRE_RELEASE_DATE]: EXPECTED_WIRE_RELEASE_DATE,
  [DocumentName.BORROWER_ENTITY]: BORROWER_ENTITY,
  [DocumentName.LENDER_FEES]: LENDER_FEES,
  [DocumentName.TITLE_AGENT]: TITLE_AGENT,
  [DocumentName.INSURANCE_PROVIDER]: INSURANCE_PROVIDER,
  [DocumentName.INSURANCE_APPROVAL]: INSURANCE_APPROVAL,
  [DocumentName.LOAN_FUNDING_TEMPLATE]: LOAN_FUNDING_TEMPLATE,
  [DocumentName.BORROWER_CLOSING_AGENT]: BORROWER_CLOSING_AGENT,
  [DocumentName.SCOPE_OF_WORK]: [
    SCOPE_OF_WORK_LINK,
    SCOPE_OF_WORK_LINK_V2,
    SCOPE_OF_WORK_FORM,
    SCOPE_OF_WORK_FORM_V2,
  ],
  [DocumentName.DIGITAL_SCOPE_OF_WORK]: [
    SCOPE_OF_WORK_LINK,
    SCOPE_OF_WORK_FORM,
  ],
  [DocumentName.NOTE_ALLONGE]: NOTE_ALLONGE,
  [DocumentName.ASSIGNMENT_AND_CONVEYANCE]: ASSIGNMENT_AND_CONVEYANCE,
  [DocumentName.FUNDING_PREFERENCE]: FUNDING_PREFERENCE,
  [DocumentName.RATE_LOCK_EXTENSION]: RATE_LOCK_EXTENSION,
  [DocumentName.POINT_OF_CONTACT]: POINT_OF_CONTACT,
  [DocumentName.TITLE_COMPANY_BANK_INFORMATION]: TITLE_COMPANY_BANK_INFORMATION,
  [DocumentName.DRAW_REPORT]: DRAW_REPORT,
  [DocumentName.BORROWER_BACKGROUND_REVIEW]: BORROWER_BACKGROUND_REVIEW,
  [DocumentName.BORROWER_CREDIT_REVIEW]: BORROWER_CREDIT_REVIEW,
  [DocumentName.TRACK_RECORD_REVIEW]: TRACK_RECORD_REVIEW,
  [DocumentName.TRACK_RECORD]: TRACK_RECORD,
  [DocumentName.ACH_FORM]: ACH_FORM,
  [DocumentName.APPRAISAL_REPORT_ARV]: APPRAISAL_REPORT_ARV,
  [DocumentName.APPRAISAL_REPORT_AS_IS_RENT]: APPRAISAL_REPORT_AS_IS_RENT,
  [DocumentName.APPRAISAL_REPORT_AS_IS]: APPRAISAL_REPORT_AS_IS,
  [DocumentName.APPRAISAL_REPORT]: APPRAISAL_REPORT,
  [DocumentName.APPRAISAL_RESOLUTIONS]: APPRAISAL_RESOLUTIONS,
  [DocumentName.BORROWER_SETUP]: BORROWER_SETUP,
  [DocumentName.APPRAISAL_REVIEW_REPORT]: APPRAISAL_REVIEW_REPORT,
  [DocumentName.REFERRAL_INFORMATION]: REFERRAL_INFORMATION,
  [DocumentName.PERSONAL_IDENTIFICATION]: PERSONAL_IDENTIFICATION,
  [DocumentName.CONSTRUCTION_FEASIBILITY_REVIEW]: CONSTRUCTION_FEASIBILITY_REVIEW,
  [DocumentName.INTERNAL_VALUATION_REPORT]: INTERNAL_VALUATION_REPORT,
};
