import { Box } from '@material-ui/core';
import { ExtensionsDashboard, useLoanStore } from '@roc/feature-loans';
import { observer } from 'mobx-react';

export const ExtensionsTab = observer(() => {
  const { loanStore } = useLoanStore();
  const { loanDetails } = loanStore;
  const loanId = loanDetails?.loanId;

  return (
    <Box width={'100%'}>
      <ExtensionsDashboard loanId={loanId} showTitle={false} style={{
        padding: 0
      }} />
    </Box>
  );
});
