import { Document } from '@roc/feature-types';
import { DocumentsTable } from './components/documentsTable/documentsTable';
import { ThreadMeta, ThreadType } from '@roc/feature-types';
import { FileUpload } from '@roc/ui';
import { useDocumentStore } from './hooks/useDocumentStore';
import { useDocumentCommunicationStore } from '../documentCommunication';
import { useDrawsStore } from '@roc/feature-draws';

type DocumentSection = {
  entityId: string;
  drawId?: string;
  section: any;
  documents: Document[];
  loanProcess: string;
  onDocumentUpload: (file: File, docName: Document) => void;
  onSectionDocumentsUpload: (section, files: FileUpload[]) => void;
  showDocumentStatus?: boolean;
};

export const DocumentSection = ({
  entityId,
  drawId,
  section,
  documents,
  loanProcess,
  onDocumentUpload,
  onSectionDocumentsUpload,
  showDocumentStatus = true,
}: DocumentSection) => {
  const { documentStore } = useDocumentStore();
  const { documentCommunicationStore } = useDocumentCommunicationStore();
  const { drawStore } = useDrawsStore();

  const allowCommunication = documentStore.isCommunicationAllowed(loanProcess);
  const allowUpload = documentStore.isUploadAllowed(section.sectionName, loanProcess);
  const showDownloadAll = documentStore.showDownloadAll(section.sectionName);

  const twilioEnabled = drawId ?
    (drawStore.currentDraw.twilioCommunicationEnabled) :
    (documentStore.loanStore.loanDetails?.twilioCommunicationEnabled);

  const openSectionCommunication = section => {
    const { sectionName, collateralId, borrowerId, sectionId } = section;
    const meta: ThreadMeta = {
      loanId: entityId,
      drawId,
      sectionId,
      borrowerId,
      collateralId,
    };

    if (drawId) {
      documentCommunicationStore.getDrawComments(sectionName, meta);
    } else {
      documentCommunicationStore.getDocumentComments(
        sectionName,
        meta,
        ThreadType.DOCUMENT
      );
    }
  };

  const onOpenForm = (doc: Document) => {
    documentStore.onOpenForm(doc);
  };

  const onSectionUpload = (files: FileUpload[]) => {
    onSectionDocumentsUpload(section, files);
  };

  const onSectionDownload = () => {
    documentStore.downloadAllFromSection(section);
  };

  const onRequestMoreInformation = (doc: Document) => {
    documentStore.requestMoreInformation(doc);
  };

  return (
    <DocumentsTable
      title={section.sectionName}
      documents={documents}
      unreadComments={section.unreadComments}
      allowCommunication={allowCommunication}
      twilioEnabled={twilioEnabled}
      allowUpload={allowUpload}
      allowSectionDownload={showDownloadAll}
      onOpenForm={onOpenForm}
      onSectionUpload={onSectionUpload}
      onSectionDownload={onSectionDownload}
      onDocumentUpload={onDocumentUpload}
      onDownload={doc => documentStore.downloadDocument(doc)}
      onPreview={doc => documentStore.previewDocument(doc)}
      onOpenCommunication={() => openSectionCommunication(section)}
      onFillForm={() => (documentStore.openDrawRequestModal = true)}
      onShareLink={doc => documentStore.onShareLink(doc)}
      onViewForm={() => {
        documentStore.openDrawReviewModal = true;
      }}
      onRequestMoreInformation={onRequestMoreInformation}
      showDocumentStatus={showDocumentStatus}
    />
  );
};

export default DocumentSection;
