import { Grid } from '@material-ui/core';
import { createCurrencyField } from '@roc/ui/componentBuilder';
import { observer } from 'mobx-react-lite';
import LenderFeesStore from '../../../stores/documentForms/lenderFeesStore';

interface LenderFeesProps {
  store: LenderFeesStore;
}

const LenderFees = observer((props: LenderFeesProps) => {
  const { store } = props;
  const disabled = !store.canEdit;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        {createCurrencyField({
          store,
          testId: 'underwritingFee',
          fieldName: 'underwritingFee',
          label: 'Underwriting Fee',
          disabled,
        })}
      </Grid>
      <Grid item xs={12} md={6}>
        {createCurrencyField({
          store,
          testId: 'processingFee',
          fieldName: 'processingFee',
          label: 'Processing Fee',
          disabled,
        })}
      </Grid>
      <Grid item xs={12} md={6}>
        {createCurrencyField({
          store,
          testId: 'adminFee',
          fieldName: 'adminFee',
          label: 'Admin Fee',
          disabled,
        })}
      </Grid>
      <Grid item xs={12} md={6}>
        {createCurrencyField({
          store,
          testId: 'commitmentFee',
          fieldName: 'commitmentFee',
          label: 'Commitment Fee',
          disabled,
        })}
      </Grid>
      <Grid item xs={12} md={6}>
        {createCurrencyField({
          store,
          testId: 'lenderBuydownFee',
          fieldName: 'lenderBuydownFee',
          label: 'Buydown Fee',
          disabled,
        })}
      </Grid>
    </Grid>
  );
});

export default LenderFees;
