import { InputToolbox } from "@chatscope/chat-ui-kit-react";
import { ConfirmationButton } from "@roc/ui";
import { observer } from "mobx-react-lite";
import { Box } from "@material-ui/core";
import { lighten, useTheme } from '@material-ui/core/styles';
import { CommunicationStore } from "../stores";

export const JoinToolBox = observer(({ communicationStore }:
  {
    as: any,
    communicationStore: CommunicationStore,
  }) => {

  const theme = useTheme();
  const {
    currentConversationSid,
  } = communicationStore;
  return (
    <InputToolbox style={{
      justifyContent: "center",
      padding: 0,
    }}>
      <Box p={1} borderTop={'1px solid #eee'} width="100%" textAlign={"center"} bgcolor={lighten(theme.palette.primary.main, 0.9)}>
        <ConfirmationButton
          color="primary"
          variant="contained"
          onClick={() => {
            communicationStore.joinConversationByconversationSid(currentConversationSid, 'You have joined the conversation!');
          }}
          testId="join_conversation"
          confirmDialogProps={{
            title: 'Join Conversation',
            body: 'Are you sure you want to join this conversation as a participant and communicate?',
            confirmButtonText: 'Yes',
          }}
          size="small"
        >
          Join
        </ConfirmationButton>
      </Box>
    </InputToolbox>
  )
});