import { useMemo, useState } from 'react';
import { ExpandMore } from '@material-ui/icons';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core';
import { TitleDivider } from '../../../components/titleDivider';
import { TableHead } from '@material-ui/core';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core';

import { QuoteTypeStore } from '../../../stores/quoteTypeStore';
import { formatPercentage } from '@roc/ui/utils';

interface PricingAdjustmentsAccordionProps {
  store: QuoteTypeStore;
}

const useStyles = makeStyles(theme => ({
  noBorders: {
    border: 'none',
  },
  headerCell: {
    backgroundColor: '#b9bdc4',
    fontWeight: 'bold',
    border: 'none',
    padding: '0.5rem',
    textAlign: 'center',
  },
  subHeaderCell: {
    backgroundColor: '#e4e8eb',
    fontWeight: 'bold',
    border: 'none',
    padding: '0.5rem'
  },
  valueCell: {
    border: 'none',
    backgroundColor: '#FCFCFC',
    padding: '0.5rem',
    borderRight: '1px solid #e8e9eb',
  },
  boldFont: {
    fontWeight: 'bold',
  },
  greenFont: {
    border: 'none',
    backgroundColor: '#FCFCFC',
    color: theme.palette.success.dark,
    fontWeight: 'bold',
    padding: '0.5rem'
  },
  redFont: {
    border: 'none',
    backgroundColor: '#FCFCFC',
    color: theme.palette.error.dark,
    fontWeight: 'bold',
    padding: '0.5rem'
  },
}));

export const PricingAdjustmentsAccordion = observer(
  ({ store }: PricingAdjustmentsAccordionProps) => {
    const [expanded, setExpanded] = useState(true);
    const classes = useStyles();
    const { loanEconomicsStore } = store;

    const rows = useMemo(() => {
      const parsedRows =
        loanEconomicsStore.rateAdjustmentsData?.criteriaRateAdjustmentData?.slice() ||
        [];

      parsedRows.sort((a, b) => a.adjustment - b.adjustment);
      return parsedRows;
    }, [loanEconomicsStore.rateAdjustmentsData]);

    return (
      <Accordion
        expanded={expanded}
        onChange={(e, expanded) => setExpanded(expanded)}
        elevation={0}
      >
        <AccordionSummary expandIcon={<ExpandMore fontSize="large" />}>
          <TitleDivider title="Pricing Adjustments" />
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{ textAlign: 'left', width: '25%' }}
                    className={classes.headerCell}
                  >
                    Criteria
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ width: '50%' }}
                    className={classes.headerCell}
                  >
                    Input
                  </TableCell>
                  <TableCell
                    style={{ width: '25%' }}
                    className={classes.headerCell}
                  >
                    Adjustment
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell
                    component="th"
                    scope="row"
                    align="left"
                    className={classes.subHeaderCell}
                  >
                    Base Rate
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    align="center"
                    className={classes.subHeaderCell}
                  />
                  <TableCell
                    component="th"
                    scope="row"
                    align="center"
                    className={classes.subHeaderCell}
                  >
                    {formatPercentage(loanEconomicsStore.rateAdjustmentsData?.baseRate || 10.99)}
                  </TableCell>
                </TableRow>
                {rows.map(row => (
                  <TableRow>
                    <TableCell
                      component="th"
                      scope="row"
                      align="left"
                      className={classes.valueCell}
                    >
                      {row.criteria}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      align="center"
                      className={classes.valueCell}
                    >
                      {row.input}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      align="center"
                      className={
                        row.adjustment > 0
                          ? classes.redFont
                          : row.adjustment < 0
                          ? classes.greenFont
                          : classes.valueCell
                      }
                    >
                      {typeof row.adjustment === 'number'
                        ? formatPercentage(row.adjustment)
                        : 'N/A'}
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell
                    component="th"
                    scope="row"
                    align="left"
                    className={classes.subHeaderCell}
                  >
                    Adjusted Floor Rate
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    align="center"
                    className={classes.subHeaderCell}
                  />
                  <TableCell
                    component="th"
                    scope="row"
                    align="center"
                    className={classes.subHeaderCell}
                  >
                    {formatPercentage(loanEconomicsStore.floorRate || 10.99)}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
    );
  }
);
