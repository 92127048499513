import { FormStore } from '@roc/feature-app-core';
import { GlobalStore } from '@roc/feature-app-core';
import { action, makeObservable, override } from 'mobx';
import { OneToolService } from '../../services/oneToolService';

const brokerForm = {
  fields: {
    brokerId: {
      value: '',
      error: null,
      rule: '',
    },
    hasBroker: {
      value: null,
      error: null,
      rule: 'required_if_true:isQuote',
      message: '"Is there a broker involved in this loan?" is required',
    },
    brokerType: {
      value: 'ON_THE_HUD',
      error: null,
      rule: '',
      message: '',
    },
    brokerPoints: {
      value: 0,
      error: null,
      rule: [{ required_if_and: ['isQuote', [true], 'hasBroker', [true]] }, 'max:100'],
      message: 'Broker points are required and must not exceed lender points',
    },
    companyName: {
      value: '',
      error: null,
      rule: 'required_if_true:isPreflight',
      message: 'Company Name is required',
    },
    companyEin: {
      value: '',
      error: null,
      rule: 'regex:/^\\d{2}-?\\d{7}$/|alpha_dash',
      message: 'Company EIN must be a number with format xx-xxxxxxx',
    },
    contactFirstName: {
      value: '',
      error: null,
      rule: 'required_if_true:isPreflight',
      message: 'Contact First Name is equired',
    },
    contactLastName: {
      value: '',
      error: null,
      rule: 'required_if_true:isPreflight',
      message: 'Contact Last Name is required',
    },
    contactEmail: {
      value: '',
      error: null,
      rule: 'email|required_if_true:isPreflight',
      message: 'Email is required',
    },
    contactPhoneNumber: {
      value: '',
      error: null,
      rule: '',
    },
    //Flags
    isPreflight: {
      value: false,
    },
    isQuote: {
      value: true,
    },
  },
  meta: {
    key: null,
    editMode: false,
    isValid: false,
    skipped: false,
    error: null,
  },
};

export class BrokerFormStore extends FormStore {
  private globalStore: GlobalStore;
  private oneToolService: OneToolService;

  constructor(globalStore) {
    super({ ...brokerForm }, globalStore);
    this.globalStore = globalStore;
    this.oneToolService = new OneToolService();
    this.init();
    makeObservable(this, {
      resetBrokerInfo: action,
      onFieldChange: override
    });
  }

  resetBrokerInfo() {
    const currentValues = this.getFormValues();
    this.reset();
    this.loadForm({
      hasBroker: currentValues.hasBroker,
      brokerPoints: currentValues.brokerPoints,
      brokerType: currentValues.brokerType || 'ON_THE_HUD',
    });
  }

  init() {
    this.form.meta.skipped = true;
    this.form.meta.editMode = true;
  }

  setEditMode(value) {
    this.form.meta.editMode = value;
  }

  setSkipped(value: boolean) {
    this.form.meta.skipped = value;
  }

  onFieldChange(field: any, value: any): void {
    if (field == 'hasBroker' && !value) {
      super.onFieldChange('brokerPoints', 0);
    }
    super.onFieldChange(field, value);
  }

}
