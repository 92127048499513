import React from 'react';
import { Box, Grid, Link } from '@material-ui/core';
import { Folder, Description } from '@material-ui/icons';
import { Document } from '../types';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';

type DocumentItemProps = {
  document: Document;
  onClick: (document: Document) => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  documentName: {
    color: theme.palette.grey[900],
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.primary.main,
    },
  },
}));

export const DocumentItem = (props: DocumentItemProps) => {
  const { document, onClick } = props;

  const classes = useStyles();

  const Icon = document.folder ? Folder : Description;

  return (
    <Grid item container alignItems="center" spacing={2}>
      <Grid item>
        <Box display="flex" alignItems="center">
          <Icon color="primary" />
        </Box>
      </Grid>
      <Grid item>
        <Link
          variant="button"
          className={classes.documentName}
          onClick={() => onClick(document)}
        >
          {document.displayFileName}
        </Link>
      </Grid>
    </Grid>
  );
};

export default DocumentItem;
