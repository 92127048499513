import React from 'react';
import { Box } from '@material-ui/core';
import { formatNegativeCurrency } from '@roc/ui/utils';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import {
  DataGrid as Grid,
  GridColumnProps,
} from '@roc/ui';
import { FundingTemplateStore } from '../../../../../stores/documentForms/fundingTemplateStore';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';

export type RowCellProps = {
  children?: string | JSX.Element;
  className?: string;
  isLastRow: boolean;
};

const RowCell = (props: RowCellProps) => {
  const { isLastRow, className, ...rest } = props;
  const classes = useStyles();
  return (
    <Box
      className={clsx([isLastRow ? classes.bold : null, className])}
      {...rest}
    />
  );
};

const useCurrencyRowCellStyles = makeStyles((theme: Theme) => ({
  error: {
    color: theme.palette.error.main,
  },
}));

export type CurrenctyRowCellProps = RowCellProps & {
  value: number;
};

const CurrencyRowCell = (props: CurrenctyRowCellProps) => {
  const { value, ...rest } = props;

  const isNegative = value < 0;
  const classes = useCurrencyRowCellStyles();

  const formattedCurrency = formatNegativeCurrency(value, isNegative);

  return (
    <RowCell className={isNegative ? classes.error : null} {...rest}>
      {formattedCurrency}
    </RowCell>
  );
};

const columns: GridColumnProps[] = [
  {
    field: 'type',
    headerName: '',
    width: 240,
    cellRenderer: 'typeCellRenderer',
  },
  {
    field: 'roc',
    headerName: 'Capital Provider',
    cellRenderer: 'currencyCellRenderer',
  },
  {
    field: 'lender',
    headerName: 'Lender',
    cellRenderer: 'currencyCellRenderer',
  },
  {
    field: 'settlementAgent',
    headerName: 'Attorney Settlement Agent',
    cellRenderer: 'currencyCellRenderer',
  },
  {
    field: 'servicer',
    headerName: 'Servicer',
    cellRenderer: 'currencyCellRenderer',
  },
  {
    field: 'brokerShare',
    headerName: 'Broker Share',
    cellRenderer: 'currencyCellRenderer',
  },
];

const useStyles = makeStyles({
  bold: {
    fontWeight: 'bold',
  },
});

export type ParticipantsBreakdownGridProps = {
  store: FundingTemplateStore;
};

export const ParticipantsBreakdownGrid = observer(
  (props: ParticipantsBreakdownGridProps) => {
    const { store } = props;
    const { participantsBreakdown } = store;

    const frameworkComponents = {
      typeCellRenderer: params => {
        return (
          <RowCell isLastRow={params.node.data?.isLastRow}>
            {params.value}
          </RowCell>
        );
      },
      currencyCellRenderer: params => {
        return (
          <CurrencyRowCell
            isLastRow={params.node.data?.isLastRow}
            value={params.value}
          />
        );
      },
    };

    return (
      <Grid
        columns={columns}
        rows={participantsBreakdown}
        frameworkComponents={frameworkComponents}
        domLayout="autoHeight"
      />
    );
  }
);

export default ParticipantsBreakdownGrid;
