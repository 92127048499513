import { Service } from '@roc/client-portal-shared/services';

const url = {
  VALIDATE_TOKEN: '/api/v1/user/public/validateToken',
  RESEND_NEW_LINK: '/api/v1/user/public/resendNewLink',
  UPDATE_PASSWORD: '/api/v1/user/public/updatePassword',
};

export class CreateOrResetPasswordService extends Service {
  validateToken(token: string) {
    return this.get(url.VALIDATE_TOKEN, {
      token,
    });
  }
  resendNewLink(token: string) {
    return this.get(url.RESEND_NEW_LINK, {
      token,
    });
  }
  updatePassword(token: string, password: string) {
    return this.post(url.UPDATE_PASSWORD, {
      token,
      password,
    });
  }
}
