import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';

import { useParams } from 'react-router';
import { ScopeOfWorkV2Form, ScopeOfWorkV2Link, decodeParams } from '@roc/feature-sow-v2';
import { ScopeOfWorkV2FormParams } from '@roc/feature-sow-shared/types';
import { useRocInternalPortalStore } from '../../../hooks';
import { ScopeOfWorkV2Option } from 'libs/feature-sow-shared/src/stores/v2/scopeOfWorkV2LinkBaseStore';
import { Grid, Paper, Typography } from '@material-ui/core';
import { Button, StandardDialog } from '@roc/ui';


export type EncodedParams = {
  encodedParams: string;
};

export const ScopeOfWorkInternalLinkCreate = observer(() => {
  const { scopeOfWorkInternalStore, scopeOfWorkV2LinkInternalStore } = useRocInternalPortalStore();

  const { encodedParams } = useParams<EncodedParams>();
  const [queryParams] = useState<ScopeOfWorkV2FormParams>(
    decodeParams(encodedParams)
  );


  useEffect(() => {
    if (queryParams) {
      scopeOfWorkInternalStore.setRenderScopeOfWorkV2LinkFromUW(true);
      scopeOfWorkInternalStore.reset();
      scopeOfWorkInternalStore.setUsername(queryParams?.username);
      scopeOfWorkInternalStore.setParams(queryParams);
      scopeOfWorkV2LinkInternalStore.setValues(queryParams.loanId , queryParams.propertyId  , queryParams.propertyAddress);
      if(!queryParams.scopeOfWorkId){
        scopeOfWorkInternalStore.setRenderScopeOfWorkV2Link(true);
      }
    }
  }, [scopeOfWorkInternalStore, queryParams]);

  const tryAndLinkScopeOfWork = async () => {
      scopeOfWorkV2LinkInternalStore.setValues(queryParams.loanId, queryParams.propertyId , queryParams.propertyAddress);
      scopeOfWorkV2LinkInternalStore.tryAndLinkScopeOfWork().then(() => {
      queryParams.scopeOfWorkId = scopeOfWorkV2LinkInternalStore.scopeOfWorkId;
      scopeOfWorkInternalStore.setRenderScopeOfWorkV2Link(scopeOfWorkV2LinkInternalStore.renderScopeOfWorkV2Link);
      scopeOfWorkInternalStore.reset();
      scopeOfWorkInternalStore.setParams(queryParams);

    })
    .catch((error) => {
      console.error('Error linking scope of work:', error);
    });
  };

  useEffect(() => {
    scrollTo(0, 0);
  }, [scopeOfWorkInternalStore.activeStep]);

  useEffect(() => {
  }, [scopeOfWorkInternalStore.renderScopeOfWorkV2Link]);

  useEffect(() => {
    if(!scopeOfWorkV2LinkInternalStore.renderScopeOfWorkV2Link){
      if(!queryParams.scopeOfWorkId){
        queryParams.scopeOfWorkId = scopeOfWorkInternalStore?.params?.scopeOfWorkId
        scopeOfWorkInternalStore.setParams(queryParams);
      }
      scopeOfWorkInternalStore.setRenderScopeOfWorkV2Link(scopeOfWorkV2LinkInternalStore.renderScopeOfWorkV2Link);
    }
  }, [scopeOfWorkV2LinkInternalStore.renderScopeOfWorkV2Link]);


    return (
    <Paper style={{ padding: 24, maxWidth: 'lg', margin: 'auto' }}>
      {
        scopeOfWorkInternalStore.renderScopeOfWorkV2Link ? (
          <>
            <Typography variant="h6" gutterBottom>
              Link Scope of Work
            </Typography>
            <ScopeOfWorkV2Link
              loanId={queryParams?.loanId}
              propertyId={queryParams?.propertyId}
              propertyAddress={queryParams?.propertyAddress}
              store={scopeOfWorkV2LinkInternalStore}
              userName={queryParams?.username}
            />
            <Grid container spacing={1} justifyContent="flex-end" style={{ marginTop: 24 }}>
              <Grid item>
                <Button
                  onClick={tryAndLinkScopeOfWork}
                  color="primary"
                  variant="contained"
                  testId="confirm"
                  disabled={!scopeOfWorkV2LinkInternalStore.canSubmit}
                >
                  {scopeOfWorkV2LinkInternalStore.scopeOfWorkOption === ScopeOfWorkV2Option.COPY_SCOPE_OF_WORK
                    ? 'Create copy & Link Scope Of Work'
                    : 'Link & Open Scope Of Work'
                  }
                </Button>
              </Grid>
            </Grid>
          </>
        ) : (
          <ScopeOfWorkV2Form store={scopeOfWorkInternalStore} />
        )
      }
    </Paper>
  );
});
