import { useEffect } from 'react';
import { observer } from 'mobx-react';
import { SelectField, TextField } from '@roc/ui';
import { Typography } from '@material-ui/core';
import { isNotBlank } from '@roc/feature-utils';
import { useLoanStore } from '../hooks/useLoanStore';

const loanStatusesForChange = [
  { label: 'Retracted', value: 'RETRACTED' },
  { label: 'On Hold', value: 'ON_HOLD' },
];

const loanStatusesForChangePending = [
  { label: 'Pending', value: 'PENDING' },
  { label: 'Retracted', value: 'RETRACTED' },
];

export const statusReasonOptions = [
  {
    category: 'Operational Issue',
    value: 'O-Roc not quick enough',
    label: 'Capital provider not quick enough',
  },
  {
    category: 'Operational Issue',
    value: 'O-Appraiser not quick enough',
    label: 'Appraiser not quick enough',
  },
  {
    category: 'Operational Issue',
    value: 'Operational Issue - Other',
    label: 'Operational Issue - Other',
  },
  {
    category: 'Borrower Issue',
    value: 'B-Not enough cash to close',
    label: 'Not enough cash to close',
  },
  { category: 'Borrower Issue', value: 'B-IR too high', label: 'IR too high' },
  {
    category: 'Borrower Issue',
    value: 'B-Protest pledge of shares',
    label: 'Protest pledge of shares',
  },
  {
    category: 'Borrower Issue',
    value: 'B-Protest architect letter',
    label: 'Protest architect letter',
  },
  {
    category: 'Borrower Issue',
    value: 'B-Protest Roc insurance guidelines',
    label: 'Protest capital provider insurance guidelines',
  },
  {
    category: 'Borrower Issue',
    value: 'B-Protest opinion letter',
    label: 'Protest opinion letter',
  },
  {
    category: 'Borrower Issue',
    value: 'B-Closed in cash',
    label: 'Closed in cash',
  },
  {
    category: 'Borrower Issue',
    value: 'B-Went with competitor to SPL',
    label: 'Went with competitor to TPO',
  },
  {
    category: 'Borrower Issue',
    value: 'Borrower Issue - Other',
    label: 'Borrower Issue - Other',
  },
  {
    category: 'TPO Issue',
    value: 'L-Pricing too high',
    label: 'Pricing too high',
  },
  {
    category: 'TPO Issue',
    value: 'L-Went with competitor to Roc',
    label: 'Went with competitor to capital provider',
  },
  {
    category: 'TPO Issue',
    value: 'SPL Issue - Other',
    label: 'TPO Issue - Other',
  },
  { category: 'Seller Issue', value: 'S-The seller backed out of the deal', label: 'The seller backed out of the deal' },
  {
    category: 'Seller Issue',
    value: 'S-Property condition issue',
    label: 'Property condition issue',
  },
  {
    category: 'Seller Issue',
    value: 'Seller Issue - Other',
    label: 'Seller Issue - Other',
  },
  { category: 'Title Issue', value: 'T-Title Issue', label: 'Title Issue' },
  {
    category: 'Title Issue',
    value: 'Title Issue - Other',
    label: 'Title Issue - Other',
  },
];

export const ChangeLoanStatusForm = observer(({ loanId, fromMyLoans = false }) => {
  const { loanStore } = useLoanStore();
  const { loanStatusStore } = loanStore;
  const formFields = loanStatusStore.form.fields;

  useEffect(() => {
    loanStatusStore.reset();
    if (fromMyLoans) {
      loanStatusStore.onFieldChange('status', loanStatusesForChangePending[0].value)
    } else {
      loanStatusStore.onFieldChange('status', loanStatusesForChange[0].value)
    }
  }, []);

  return (
    <>
      <Typography>Please set the new status of the deal</Typography>
      <br />
      <SelectField
        standaloneLabel
        label={'Status'}
        name={'status'}
        value={formFields.status.value}
        errorText={formFields.status.error}
        error={isNotBlank(formFields.status.error)}
        options={fromMyLoans ? loanStatusesForChangePending : loanStatusesForChange}
        onChange={value => loanStatusStore.onFieldChange('status', value)}
        variant="outlined"
        fullWidth
        shrink
        required
        testId="status"
      />
      <br />
      <br />
      {formFields.status.value !== 'PENDING' &&
        <SelectField
          standaloneLabel
          label={'Reason for status'}
          name={'reasonForStatus'}
          value={formFields.reasonForStatus.value}
          errorText={formFields.reasonForStatus.error}
          error={isNotBlank(formFields.reasonForStatus.error)}
          options={statusReasonOptions}
          onChange={value => {
            loanStatusStore.handleReasonForStatusChange(value);
          }}
          variant="outlined"
          fullWidth
          shrink
          required
          testId="reasonForStatus"
          displayCategory
        />
      }
      {formFields.reasonForStatus.value?.toLowerCase().includes('other') && (
        <>
          <br />
          <br />
          <TextField
            standaloneLabel
            testId="statusChangeComment"
            required
            variant="outlined"
            label="Type a further explanation here"
            value={formFields.statusChangeComment.value}
            onChange={e =>
              loanStatusStore.onFieldChange(
                'statusChangeComment',
                e.target.value
              )
            }
            error={isNotBlank(formFields.statusChangeComment.error)}
            helperText={formFields.statusChangeComment.error}
            fullWidth
          />
        </>
      )}
    </>
  );
});
