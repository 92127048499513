import { Box, Divider, Grid, Typography } from '@material-ui/core';
import { Button, IconButton, FileUpload, FileUploadModal, TextField, FileUploadArea } from '@roc/ui';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useDocumentStore } from '../../../hooks/useDocumentStore';
import { CloudDownload } from '@material-ui/icons';
import { useAppraisalReportFormStore } from '../../../hooks/useAppraisalReportFormStore';

export const AppraisalReportForm = observer(
  ({ open, handleClose, loanDocumentId, internal = false }) => {
    const {
      documentStore: { documentFormStore },
    } = useDocumentStore();
    const { appraisalReportFormStore } = useAppraisalReportFormStore();
    const [uploads, setUploads] = useState<FileUpload[]>([]);
    const [isUploadOpen, setUploadOpen] = useState(false);
    const [isUploadXmlOpen, setUploadXmlOpen] = useState(false);
    const [uploadPdf, setUploadPdf] = useState<FileUpload[]>([]);
    const [uploadXml, setUploadXml] = useState<FileUpload[]>([]);

    useEffect(() => {
      if (loanDocumentId) {
        appraisalReportFormStore.setIsInternal(internal);
        if (internal) {
          appraisalReportFormStore.loadReport(loanDocumentId);
        }
      }
    }, [loanDocumentId]);

    const upload = async (fileUploads: FileUpload[]) => {
      await appraisalReportFormStore.uploadDocument(fileUploads, loanDocumentId);
    };

    const uploadXmlFile = async (fileUPloads: FileUpload[]) => {
      await appraisalReportFormStore.uploadAndParseDocument(fileUPloads, loanDocumentId);

    }

    const onSave = async () => {
      if (uploadPdf[0] && uploadXml[0]) {
        await upload(uploadPdf);
        await uploadXmlFile(uploadXml);
        if (handleClose) handleClose();
      }else if(uploadPdf[0] && !uploadXml[0]){
        showErrorAlert('Appraisal Report XML is required');
      }else if(uploadXml[0] && !uploadPdf[0]){
        showErrorAlert('Appraisal Report PDF is required');
      }
      else {
        showErrorAlert('Appraisal Report PDF and Appraisal Report XML are required');
      }
    }

    const showErrorAlert = (message: string) => {
      appraisalReportFormStore.showErrorMessage(message);
    }

    const test =(items: FileUpload[]) => {
      setUploadPdf(items)
    }

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          {appraisalReportFormStore.canDownloadFile && (
            <Box display="flex" justifyContent="flex-end">
              <IconButton
                type="submit"
                color="primary"
                testId="save"
                onClick={() => appraisalReportFormStore.downloadDocument()}
              >
                <CloudDownload />
              </IconButton>
            </Box>
          )}
        </Grid>
        <Grid xs={12}>
          <Typography style={{ display: 'inline-block', paddingLeft: '10px' }}>Upload Appraisal Report - PDF Format</Typography>
        </Grid>
        <Grid item xs={12}>
          <FileUploadArea
            items={uploadPdf}
            setItems={(items) => test(items)}
            multiple={false}
            fileType='pdf'
            />
        </Grid>
        <div></div>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <div><br></br></div>
        <Grid container direction="row" justifyContent="flex-start">
          <Typography style={{ display: 'inline-block', paddingLeft: '10px' }}>Upload Appraisal Report - XML Format</Typography>
        </Grid>
        <Grid item xs={12}>
          <FileUploadArea
            items={uploadXml}
            setItems={(items) => setUploadXml(items)}
            multiple={false}
            fileType='xml'
            />
        </Grid>

        <Grid item xs={12}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            testId="save"
            fullWidth
            onClick={onSave}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    );
  }
);
