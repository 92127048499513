import { ApiResponse, GlobalStore } from '@roc/feature-app-core';
import { flow, makeObservable, observable } from 'mobx';
import { DelinquencyBpoService } from '../services/delinquencyBpoService';

export class InternalDelinquencyBpoStore {
  globalStore: GlobalStore;
  loanDetails: any = undefined;
  loanForeclosureValuations: any = [];
  isLoading: boolean = false;
  delinquencyBpoService: DelinquencyBpoService;

  constructor(globalStore: GlobalStore) {
    this.delinquencyBpoService = new DelinquencyBpoService();
    this.globalStore = globalStore;
    makeObservable(this, {
      loanDetails: observable,
      loanForeclosureValuations: observable,
      isLoading: observable,
      refreshLoanDetails: flow,
      fetchLoanDetails: flow,
      refreshLoanForeclosureValuations: flow,
      fetchLoanForeclosureValuations: flow,
      requestBpoOrder: flow,
      requestForeclosureAppraisalOrder: flow
    });
  }

  *refreshLoanDetails(loanId: number) {
    yield this.fetchLoanDetails(loanId);
  }

  *fetchLoanDetails(loanId, successCallback = () => { }) {
    try {
      const response: ApiResponse = yield this.delinquencyBpoService.getLoanDetails(loanId)
        .then(response => {
          this.loanDetails = response?.data;
        });

      successCallback();

    } catch (error) {
      console.log(error);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching loan details.',
      });
    }
  }

  *refreshLoanForeclosureValuations(loanId: number) {
    yield this.fetchLoanForeclosureValuations(loanId);
  }

  *fetchLoanForeclosureValuations(loanId, successCallback = () => { }) {
    try {
      const response: ApiResponse = yield this.delinquencyBpoService.getLoanForeclosureValuations(loanId)
        .then(response => {
          this.loanForeclosureValuations = JSON.parse(response?.data?.data);
        });

      successCallback();

    } catch (error) {
      console.log(error);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching loan foreclosure valuations.',
      });
    }
  }

  *requestBpoOrder(loanId: string, properties: any) {
    try {
      this.isLoading = true;
      const response: ApiResponse = yield this.delinquencyBpoService.createBpoOrder(loanId, properties);

      this.globalStore.notificationStore.showSuccessNotification({
        message: 'BPO Order created successfully.',
      });
    }
    catch (error) {
      console.log(error);
      this.globalStore.notificationStore.showErrorNotification({
        message: `Error while creating BPO Order for loan: ${loanId}.`,
      });
    }
    this.isLoading = false;
  }

  *requestForeclosureAppraisalOrder(loanId: string, properties: any) {
    try {
      this.isLoading = true;
      const response: ApiResponse = yield this.delinquencyBpoService.createForeclosureAppraisalOrder(loanId, properties);

      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Appraisal Order created successfully.',
      });
    }
    catch (error) {
      console.log(error);
      this.globalStore.notificationStore.showErrorNotification({
        message: `Error while creating Appraisal Order for loan: ${loanId}.`,
      });
    }
    this.isLoading = false;
  }
}