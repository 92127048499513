import { Box, Grid, Typography } from '@material-ui/core';
import { formatDate } from '@roc/feature-utils';
import { Button, DialogState } from '@roc/ui';
import {
  createHtmlField,
  createNumberFormatField,
  createTextField,
} from '@roc/ui/componentBuilder';
import { observer } from 'mobx-react';
import { useGeneralTasksStore } from '../../../hooks/useGeneralTasksStore';
import { RelatedTask } from '../relatedTask';
import { TaskAssigneeField } from '../taskAssigneeField';
import { useStore } from '@roc/client-portal-shared/hooks';

export const AppraisalTaskDetails = observer(() => {
    const { generalTasksStore } = useGeneralTasksStore();
    const { editTaskStore } = generalTasksStore;
    const {
      isReadOnly,
      isSubTask,
      task,
    } = editTaskStore;
    const store = editTaskStore;
    const { globalStore } = useStore();

    return (
      <Grid container spacing={2}>
        {
          isSubTask &&
          <Grid item xs={12}>
            <Box pb={2}>
              <Typography variant="h6">Parent Task</Typography>
            </Box>
            <Box>
              <RelatedTask taskInfo={task?.parentTask} />
            </Box>
            <Box pt={2}>
              <Typography variant="h6">Task Details</Typography>
            </Box>
          </Grid>
        }
        <Grid item xs={12}>
          {createTextField({
            store,
            testId: 'title',
            fieldName: 'title',
            label: 'Title',
            disabled: isReadOnly || !globalStore.isInternalPortal,
          })}
        </Grid>

        <Grid item xs={12}>
          {createHtmlField({
            store,
            testId: 'description',
            fieldName: 'description',
            label: 'Description',
            multiline: true,
            minRows: 5,
            disabled: isReadOnly || !globalStore.isInternalPortal,
          })}
        </Grid>
      </Grid>
    );
});
  
export const AppraisalAttributesPanel = observer(() => {
    const { generalTasksStore } = useGeneralTasksStore();
    const { editTaskStore, assigneeOptions } = generalTasksStore;
    const isLoanView = !!generalTasksStore.loanId;
    const { isReadOnly, task, dialogState } = editTaskStore;
    const store = editTaskStore;
    const assigneeStartDate = task?.assignees?.[0]?.assigneeStartDate;
    const { globalStore } = useStore();
    
    return (
      <Grid container spacing={2}>
        {globalStore.isInternalPortal && (
          <Grid item xs={12}>
            <TaskAssigneeField
              label={'Assignee'}
              value={store.form.fields.assigneeId.value}
              onChange={(e, value) => store.onFieldChange('assigneeId', value)}
              assigneeOptions={assigneeOptions}
              disabled={false}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          {createNumberFormatField({
            store,
            testId: 'loanId',
            fieldName: 'loanId',
            label: 'Loan Id',
            disabled: isReadOnly || isLoanView || !globalStore.isInternalPortal,
          })}
        </Grid>

        {DialogState.EDIT === dialogState && (
          <>
            <Grid item xs={12}>
              <Typography>
                Created: {formatDate(task?.taskDetails?.createdDate)}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                Updated: {formatDate(task?.taskDetails?.lastUpdatedDate)}
              </Typography>
            </Grid>
            {(globalStore.isInternalPortal && assigneeStartDate) && (
              <Grid item xs={12}>
                <Typography>
                  Assigned Date: {formatDate(assigneeStartDate)}
                </Typography>
              </Grid>
            )}
          </>
        )}
      </Grid>
    );
});
  