import { FormControlLabel, Grid, IconButton, List, Radio, RadioGroup, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { Flag, Info } from "@material-ui/icons";
import { yesNoOptionsBoolean } from "@roc/feature-utils";
import { RadioField, StandardDialog } from "@roc/ui";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { MANUAL_EDIT_APPRAISAL_REVIEW, MANUAL_SECTION } from "./constants";
import { useResolutionStore } from "@roc/feature-borrower-review";

const useStyles = makeStyles(theme => ({
  tabs: {
    backgroundColor: '#FFFFF',
    borderTopLeftRadius: 7,
    borderTopRightRadius: 7,
    border: 'solid',
    borderWidth: '0.1px',
    borderColor: '#747575',
    '& .MuiTab-root.Mui-selected': {
      color: '#747575',
      opacity: 1,
      backgroundColor: '#e6f5fe',

    },
    width: '100%'
  },
  indicator: {
    backgroundColor: '#e6f5fe',
  },
  tab: {
    fontSize: '15px',
    fontFamily: 'Open Sans, sans-serif',
    fontWeight: 'bold',
    color: '#000000',
  },
  addressWrapper: {
    '& .MuiInputBase-root.Mui-disabled': {
      backgroundColor: theme.palette.grey[50],
    },
    marginBottom: '25px',
    width: '100%',
  },
  container: {
    padding: '20px 35px'
  },
  accordionSummary: {
    '&$expanded': {
      minHeight: '54px'
    },
    backgroundColor: '#e6f6fe',
    color: '#747575',
    border: '1px solid',
    borderColor: '#d3d3d3',
    borderCollapse: 'separate',
    borderSpacing: '0',
    width: '100%',
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',
    overflow: 'hidden',
  },
  gridHeaderTitle: {
    color: 'black',
    padding: '5px',
    textTransform: 'capitalize',
    fontSize: '20px'
  },
  expanded: {
    '&$expanded': {
      margin: '0 0'
    }
  },
  accordionDetails: {
    padding: '0px 0px 0px',
    margin: '0',
    display: 'flex',
    justifyContent: 'center'
  },
  titleCell: {
    backgroundColor: '#E2F6FF',
    color: '#747575',
    fontWeight: 'bold',
    padding: '10px 5px',
    textAlign: 'left'
  },
  titleRow: {
    height: '3em',
    borderLeft: '1px solid',
    borderLeftColor: '#e6f6fe',
    borderRight: '1px solid',
    borderRightColor: '#e6f6fe'
  },
  headerCell: {
    height: '41px',
    fontWeight: 'bold',
    color: '#757575',
    padding: 0,
    backgroundColor: '#F0FAFF',
    textAlign: 'center',
    border: '1px solid #e0e0e0',
  },
  tableContainer: {
    borderRadius: '10px',
    border: '1px #e0e0e0 solid',
  },
  table: {
    margin: 0,
    padding: 0,
    '& td': {
      border: '1px solid #e0e0e0',
    },
    '& th:first-child, td[rowSpan], $addNewItemRow td': {
      paddingLeft: theme.spacing(2),
      paddingRight: '3px',
    },
    '& table tr:last-child td, & table td[rowSpan]': {
      borderBottom: 'none',
    },
  },
  bodyCellName: {
    border: '1px solid',
    borderColor: '#d3d3d3',
    width: '350px',
    minWidth: '200px',
    padding: '10px'
  },
  bodyCellAutomated: {
    border: '1px solid',
    borderColor: '#d3d3d3',
    minWidth: '200px',
  },
  bodyCellReview: {
    border: '1px solid',
    borderColor: '#d3d3d3',
    width: '180px',
    minWidth: '180px',
  },
  bodyCellData: {
    border: '1px solid',
    borderColor: '#d3d3d3',
    width: '450px',
    minWidth: '200px',
  },
  bodyCellNeedsRevision: {
    width: '175px'
  },
  addressGrid: {
    paddingTop: '12px',
  },
  outlinedButton: {
    fontSize: '16px',
    fontFamily: 'Open Sans,Lato,sans-serif',
    textTransform: 'capitalize',
    width: '250px',
    margin: theme.spacing(1, 0, 1, 0),
    color: '#00B2FF',
    borderColor: '#00B2FF',
    marginTop: '10px',
    marginBottom: '30px'
  },
  button: {
    fontSize: '16px',
    fontFamily: 'Open Sans,Lato,sans-serif',
    textTransform: 'capitalize',
    margin: theme.spacing(1, 0, 1, 0),
    width: '240px',
    color: '#00B2FF',
    backgroundColor: '#E2F6FF',
    borderColor: '#00B2FF'
  },
  flag: {
    fontSize: '25px'
  },
  radioLabel: {
    "& svg": {
      width: "15px",
      height: "15px"
    },
    color: '#828282',
  },
  radioLabelSpan: {
    fontSize: '15px',
    color: '#828282',
    fontWeight: 'lighter'
  },
  radioButton: {
    '&.Mui-checked': {
      color: '#005dc8',
    },
  },
  radioGrid: {
    marginBottom: '25px',
    marginLeft: '10px'
  },
  resize: {
    fontSize: '15px',
    color: '#555555'
  },
  title: {
    color: '#757575',
    fontSize: 20
  },
  fixedWidthText: {
    fontSize: '1rem',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'left',
    paddingLeft: '10px',
    width: '100%'
  },
}));

const RadioControl = observer(({ index, uniqueId, mapping, value, handleChange, revisionRequired, disabled }) => {
  if (revisionRequired) {
    return (
      <RadioField
        disabled={disabled}
        value={value}
        name={mapping}
        options={yesNoOptionsBoolean}
        onChange={(newValue) => handleChange(index, uniqueId, mapping, newValue)}
        row
        testId={mapping}
      />
    )
  } else {
    return <></>
  }
})

const FlagIcon = observer(({ index, uniqueId, mapping, value, handleChange, flagRequired, disabled, resolutionDocumentId, handleOpenResolution }) => {
  const classes = useStyles();
  const color = value && disabled ? '#dea278'
    : value && !disabled ? '#E9843D'
      : !value && disabled ? '#807974'
        : '#757575';

  if (flagRequired) {
    return (
      <>
        <IconButton onClick={() => handleChange(index, uniqueId, mapping, value)} disabled={disabled}>
          <Flag htmlColor={color} className={classes.flag} />
        </IconButton>
        {value && resolutionDocumentId && (
          <IconButton onClick={handleOpenResolution}>
            <Info style={{ color: '#2199E0' }} />
          </IconButton>
        )}
      </>
    )
  } else {
    return <></>
  }
})

const TextBoxControl = observer(({ value, index, mapping, uniqueId, handleChange, disabled }) => {
  const classes = useStyles();
  const [inputValue, setInputValue] = useState(value);
  const [debouncedInputValue, setDebouncedInputValue] = useState(undefined);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
    setDebouncedInputValue(event.target.value);
  };

  useEffect(() => {
    if (typeof debouncedInputValue === 'undefined') return;
    const timeoutId = setTimeout(() => {
      handleChange(index, uniqueId, mapping, inputValue);
    }, 750);
    return () => clearTimeout(timeoutId);
  }, [debouncedInputValue, 750]);

  useEffect(() => {
    if (value !== inputValue) {
      setInputValue(value);
    }
  }, [value]);

  return (
    <TextField
      variant="outlined"
      value={inputValue}
      fullWidth
      InputProps={{
        classes: {
          input: classes.resize,
        },
      }}
      size="small"
      multiline
      onChange={handleInputChange}
      disabled={disabled}
    />
  )
})

const TextControl = observer(({ value }) => {
  const classes = useStyles();
  return <span className={classes.fixedWidthText} style={{ fontSize: '14px' }}>{value}</span>
})

const MismatchTextControl = observer(({ value }) => {
  const classes = useStyles();
  return <span className={classes.fixedWidthText} style={{ fontSize: '14px', color: '#e60e19' }}>{value}</span>
})

const CustomData = observer(({ dataValue, customDataValue }) => {
  if (!customDataValue) {
    return <TextControl value={dataValue} />
  } else {
    return (
      <Typography>
        {customDataValue.map(v => {
          const type = v.type;
          switch (type) {
            case 'MISMATCH':
              return <MismatchTextControl value={v.text} />
            case 'REGULAR_TEXT':
              return <TextControl value={v.text} />
          }
        })}
      </Typography>
    )
  }
})

export const CellType = observer(({ value, mapping, index, uniqueId, handleChange, revisionRequired, needsRevisionFlag, customData, disabled, sectionName, resolutionDocumentId, handleOpenResolution, isManualReport }) => {
  switch (mapping?.toLowerCase()) {
    case 'review':
    case 'radio':
      return <RadioControl
        disabled={sectionName === MANUAL_SECTION ? disabled : true}
        index={index}
        uniqueId={uniqueId}
        mapping={mapping}
        value={value}
        handleChange={handleChange}
        revisionRequired={revisionRequired}

      />
    case 'needsrevisionflag':
    case 'resolutionflag':
      return <FlagIcon
        value={value}
        index={index}
        uniqueId={uniqueId}
        mapping={mapping}
        handleChange={handleChange}
        flagRequired={needsRevisionFlag}
        disabled={disabled}
        resolutionDocumentId={resolutionDocumentId}
        handleOpenResolution={handleOpenResolution}
      />
    case 'reviewercomment':
      return <TextBoxControl
        value={value}
        index={index}
        mapping={mapping}
        uniqueId={uniqueId}
        handleChange={handleChange}
        disabled={isManualReport}
      />
    case 'data':
      return <CustomData
        dataValue={value}
        customDataValue={customData}
      />
    default:
      return <TextControl value={value} />
  }
})

export const AppraisalReviewTable = observer(({ columns, items, sectionName, store }) => {
  const classes = useStyles();
  const disabled = (!store.editEnabled || store.manualAutomatedStatus === MANUAL_EDIT_APPRAISAL_REVIEW);
  const isManualReport = store.manualAutomatedStatus === MANUAL_EDIT_APPRAISAL_REVIEW;
  const { resolutionStore } = useResolutionStore();

  const getClassNameByColumn = (mapping) => {
    switch (mapping) {
      case 'itemDisplayName':
        return classes.bodyCellName;
      case 'review':
        return classes.bodyCellReview;
      case 'data':
        return classes.bodyCellData;
      case 'needsRevisionFlag':
        return classes.bodyCellNeedsRevision;
      default:
        return classes.bodyCellAutomated;
    }
  }

  const handleOpenResolution = (item) => {
    resolutionStore.setResolutionInfoOpen(true);
    resolutionStore.setDocumentId(item.documentId);
  }

  return (
    <TableContainer className={classes.tableContainer}>
      <Table className={classes.table} >
        <TableHead>
          <TableRow>
            <TableCell colSpan={columns.length} className={classes.titleCell}>
              <Typography className={classes.title}>{sectionName}</Typography>
            </TableCell>
          </TableRow>
          <TableRow className={classes.titleRow}>
            {columns.map(c => {
              return (
                <TableCell className={classes.headerCell}> {c.name} </TableCell>
              )
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item, index) => (
            <TableRow>
              {columns.map(c => {
                return (
                  <TableCell
                    className={getClassNameByColumn(c.mapping)}
                    align="center"
                  >
                    <CellType
                      value={item[c.mapping]}
                      mapping={c.mapping}
                      index={index}
                      uniqueId={item.uniqueId}
                      handleChange={(index, uniqueId, mapping, value) => store.handleItemChange(sectionName, index, uniqueId, mapping, value, item.itemDisplayName)}
                      revisionRequired={item.revisionRequired}
                      needsRevisionFlag={item.needsRevisionFlagRequired}
                      customData={item.customData}
                      disabled={disabled}
                      sectionName={sectionName}
                      resolutionDocumentId={item.documentId}
                      handleOpenResolution={() => { handleOpenResolution(item) }}
                      isManualReport={isManualReport}
                    />
                  </TableCell>
                )
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
})