import { useBaseStore } from '@roc/feature-app-core';
import { useLoanStore } from '@roc/feature-loans';
import { LoanStatus } from '@roc/feature-utils';
import { DialogState, EditableDialog, SubmitButton } from '@roc/ui';
import { LoanParticipants } from 'libs/feature-loans/src/loanSubmission/components/loanParticipants';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useLoanFlags } from '../../hooks/useLoanFlags';
import { CustomCard, CustomCardDividers, CustomCardRow } from '../customCard';

export const OriginatorsCard = observer(() => {
  const { loanStore } = useLoanStore();
  const { loanDetails } = loanStore;
  const { globalStore } = useBaseStore();
  const { showEditLenderOriginators } = useLoanFlags();
  const [showEditDialog, setShowEditDialog] = useState(false);

  let flag = false;
  let percentageLo;
  loanDetails?.lenderOriginatorNames?.map?.((lender, i) => {
    (percentageLo = lender.percentage), (flag = true);
  });

  return (
    <CustomCard
      title="Originators"
      onEdit={() => setShowEditDialog(true)}
      editButtonProps={{
        disabled: !showEditLenderOriginators,
      }}
    >
      <CustomCardRow
        label={loanDetails?.lenderName}
        value={`Percentage: ${flag ? 100 - percentageLo : 100}%`}
      />
      {loanDetails?.lenderOriginatorNames?.map?.(lender => (
        <CustomCardDividers>
          <CustomCardRow
            label={lender.lenderName}
            value={`Percentage: ${lender.percentage}%`}
          />
        </CustomCardDividers>
      ))}
      <EditLenderOriginatorsModal
        open={showEditDialog}
        onClose={() => setShowEditDialog(false)}
      />
    </CustomCard>
  );
});

const EditLenderOriginatorsModal = observer(({ open, onClose }) => {
  const { loanStore } = useLoanStore();
  const { loanDetails } = loanStore;

  useEffect(() => {
    if (open) {
      loanStore.setExistingOriginatorsPercentage();
    }
  }, [open]);

  const handleClose = () => {
    loanStore.originatorStore.selectOriginatorsStore.reset();
    loanStore.setExistingOriginatorsPercentage();
    onClose();
  };
  const handleSubmit = () => {
    loanStore.saveLenderOriginator();
    onClose();
  };

  const dialogContent = (
    <>
      <LoanParticipants store={loanStore.selectOriginatorsStore} />
    </>
  );

  return (
    <EditableDialog
      title={'Lender Originators'}
      dialogState={DialogState.EDIT}
      open={open}
      handleClose={handleClose}
      onClose={handleClose}
      dialogContent={dialogContent}
      handleEdit={handleSubmit}
      editButtonText={'Submit'}
    />
  );
});
