import { action, flow, observable, computed, makeObservable } from 'mobx';
import { GlobalStore } from '@roc/feature-app-core';
import { DocumentStore } from '@roc/feature-documents';
import { LoanStore } from '@roc/feature-loans';
import { FundingTemplateService } from '../../services/documentForms/fundingTemplateService';
import { FundingTemplate } from '../../types/documentForms/fundingTemplate';
import { LoanType } from '@roc/feature-utils';

export class FundingTemplateStore {
  public loanStore: LoanStore;
  private documentStore: DocumentStore;
  private globalStore: GlobalStore;
  private fundingTemplateService: FundingTemplateService;
  fundingTemplate: FundingTemplate;
  fundingTemplateLoaded: boolean;

  constructor(
    loanStore: LoanStore,
    documentStore: DocumentStore,
    globalStore: GlobalStore
  ) {
    this.loanStore = loanStore;
    this.documentStore = documentStore;
    this.globalStore = globalStore;
    this.fundingTemplateService = new FundingTemplateService();
    this.fundingTemplate = null;
    this.fundingTemplateLoaded = null;
    makeObservable(this, {
      getFundingTemplate: flow,
      getEstimatedFundingTemplate: flow,
      fundingTemplate: observable,
      participantsBreakdown: computed,
      fundingTemplateLoaded: observable,
      reset: action,
      showBridgeFundingTemplate: computed,
      showTermFundingTemplate: computed,
    });
  }

  initialize() {
    this.fundingTemplate = null;
    this.fundingTemplateLoaded = null;
  }

  *getFundingTemplate() {
    try {
      const { loanId } = this.loanStore.loanDetails;
      const { data } = yield this.fundingTemplateService.getFundingTemplate(
        loanId
      );

      const response = JSON.parse(data.data);

      if (!response.success) {
        throw response.data;
      }

      this.fundingTemplate = response.data;
      this.fundingTemplateLoaded = response.success;
    } catch (err) {
      this.fundingTemplateLoaded = false;
      this.globalStore.notificationStore.showErrorNotification({
        message: 'An error occurred while loading funding template',
      });
    }
  }

  *getEstimatedFundingTemplate(closingDate) {
    try {
      const { loanId } = this.loanStore.loanDetails;
      const { data } = yield this.fundingTemplateService.getEstimatedFundingTemplate(
        loanId,
        closingDate
      );

      const response = JSON.parse(data.data);

      if (!response.success) {
        throw response.data;
      }

      this.fundingTemplate = response.data;
      this.fundingTemplate.loanFundingDate = closingDate
      this.fundingTemplateLoaded = response.success;
    } catch (err) {
      this.fundingTemplateLoaded = false;
      this.globalStore.notificationStore.showErrorNotification({
        message: 'An error occurred while loading the estimated funding template',
      });
    }
  }

  get participantsBreakdown() {
    const breakdown =
      this.fundingTemplate?.fundingSummaryBreakDownByParticipants || [];
    return breakdown.map((row, index) => ({
      ...row,
      isLastRow: index === breakdown.length - 1,
    }));
  }

  reset() {
    this.fundingTemplate = null;
    this.fundingTemplateLoaded = null;
  }

  get showBridgeFundingTemplate() {
    const { loanType } = this.loanStore.loanDetails;
    return loanType === LoanType.RESIDENTIAL_BRIDGE;
  }

  get showTermFundingTemplate() {
    const { loanType } = this.loanStore.loanDetails;
    return loanType === LoanType.RESIDENTIAL_TERM;
  }
}

export default FundingTemplateStore;
