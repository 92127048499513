import { Layout, Page, useBaseStore } from '@roc/feature-app-core';
import { Route } from 'react-router';
import { BackOfficeLoansHome } from '../components/dashboard/backOfficeLoansHome';
import { LoanSummaryBridge } from 'apps/lender-portal/src/app/modules/loans/loanSummaryBridge';
import { LoanSummaryBridgeMultifamily } from 'apps/lender-portal/src/app/modules/loans/loanSummaryBridgeMultifamily';
import { LoanSummaryStabilizedBridge } from 'apps/lender-portal/src/app/modules/loans/loanSummaryStabilizedBridge';
import { LoanSummaryTerm } from 'apps/lender-portal/src/app/modules/loans/loanSummaryTerm';
import { LoanSubType } from '@roc/feature-utils';
import { LoanDetails } from '@roc/feature-loans';
import { LenderDashboard } from '../components/LenderDashboard/lendersDashboard';
import { LenderBankInformationHome } from '../components/LenderBankInformation/LenderBankInformationHome';
import { CreateList } from 'apps/crm-portal/src/app/modules/createList/components/createList';
import { LeadListViewDashBoard } from '../components/leads/listViews/leadListViewDashBoard';
import { NewLeadCreationHome } from '../components/newLead/newLeadCreationHome';
import { LenderDetails } from 'libs/client-portal-shared/src/app/modules/lenderDetails/lenderDetails';
import { LendersWithNoRmDashboard } from '../components/LenderDashboard/lendersWithNoRmDashBoard';
import { ImportLeads } from '../components/importLeads/ImportLeads';
import { CompanyProfileCRMWrapper } from '../components/companyProfile/companyProfileCRMWrapper';
import Typography from 'libs/feature-borrower-portal-theme/src/theme/overrides/Typography';
import { LeadDetails } from '../components/leadDetails/leadDetails';
import { useStore } from '@roc/client-portal-shared/hooks';
import { TpoLeadsDashboard } from '../components/leadsDashboard/tpoLeadsDashboard';
import { BorrowerLeadsDashboard } from '../components/leadsDashboard/borrowerLeadsDashboard';
import { ManageTemplatesDashboard } from '../components/manageTemplates/manageTemplatesDashboard';
import { AllLeadDashboard } from '../components/leadsDashboard/allLeadDashboard';
import { CRMTaskDashboard } from '../components/crmTasks/crmTaskDashboard';
import { MaturityAndDqInformation } from '../components/delinquentLoans/maturityAndDqInformation';
import { PossibleMatchesContainer } from '../components/newLead/possibleMatchesContainer';
import { LoanApplicationDetailsCardActionsButton } from '../../../../feature-loan-details/src/loanDetails/components/loanApplicationDetailsCardActionsButton';

const LOANS_HOME_BASE_PATH = 'loans';
const LENDERS_HOME_BASE_PATH = 'lenders';
const COMPANY_PROFILE_BASE_PATH = 'companyProfile';
const LOAN_BASE_PATH = ':loanId';
const BACK_OFFICE_BASE_PATH = 'backoffice';
const LENDER_BANK_INFO_HOME_BASE_PATH = 'lenderBankInformations';
const CREATE_LEAD_LISTS = 'create-list';
const LEADS_LISTS_VIEWS_HOME_BASE_PATH = 'lead-list-views';
const NEW_LEADS_HOME_BASE_PATH = 'newLead';
const TPO_LEADS_DASHBOARD_BASE_PATH = 'tpo-leads-dashboard';
const BORROWER_LEADS_DASHBOARD_BASE_PATH = 'borrower-leads-dashboard';
const LEADS_DASHBOARD_BASE_PATH = 'dashboard-leads';
const LENDER_DETAILS = 'lender-details';
const LENDERS_NO_RM_HOME_BASE_PATH = 'lenders-with-no-rm';
const IMPORT_LEADS_BASE_PATH = 'importLeads';
const MANAGE_TEMPLATES = 'manageTemplates';
const POSSIBLE_MATCHES = 'possibleMatches';
const CRM_TASKS = 'my-crm-tasks';
const DELINQUENT_LOANS = 'maturity-delinquent-loans';
const LOANS_APPLICATION_HOME_BASE_PATH = 'loan-applications';
const LOAN_APPLICATION_BASE_PATH = ':loanId';



export const getLoanRoutesConfig = (basePath: string, baseUrl: string) => {
  return {
    loansHome: {
      path: `${basePath}/${BACK_OFFICE_BASE_PATH}/${LOANS_HOME_BASE_PATH}`,
      url: `${baseUrl}/${BACK_OFFICE_BASE_PATH}/${LOANS_HOME_BASE_PATH}`,
      documentTitle: 'Loans Home',
    },
    loansHomeLender: {
      path: `${basePath}/${LOANS_HOME_BASE_PATH}`,
      url: `${baseUrl}/${LOANS_HOME_BASE_PATH}`,
      documentTitle: 'Loans Home',
    },
    loans: (loanId?: string) => ({
      path: `${basePath}/${LOANS_HOME_BASE_PATH}/${LOAN_BASE_PATH}`,
      url: `${baseUrl}/${LOANS_HOME_BASE_PATH}/${loanId}`,
      children: {
        dashboard: {
          path: `${basePath}/${LOANS_HOME_BASE_PATH}/${LOAN_BASE_PATH}/dashboard`,
          url: `${baseUrl}/${LOANS_HOME_BASE_PATH}/${loanId}/dashboard`,
          documentTitle: `${loanId} - Loan Dashboard`,
        },
        loanSummary: {
          path: `${basePath}/${LOANS_HOME_BASE_PATH}/${LOAN_BASE_PATH}/loan-summary`,
          url: `${baseUrl}/${LOANS_HOME_BASE_PATH}/${loanId}/loan-summary`,
          documentTitle: `${loanId} - Loan Summary`,
        },
      }
    }),
    loanApplications: (loanApplicationId?: string) => ({
      path: `${basePath}/${LOANS_APPLICATION_HOME_BASE_PATH}/${LOAN_APPLICATION_BASE_PATH}`,
      url: `${baseUrl}/${LOANS_APPLICATION_HOME_BASE_PATH}/${loanApplicationId}`,
      children: {
        dashboard: {
          path: `${basePath}/${LOANS_APPLICATION_HOME_BASE_PATH}/${LOAN_APPLICATION_BASE_PATH}/dashboard`,
          url: `${baseUrl}/${LOANS_APPLICATION_HOME_BASE_PATH}/${loanApplicationId}/dashboard`,
          documentTitle: `${loanApplicationId} - Loan Application Dashboard`,
        },
        todos: {
          path: `${basePath}/${LOANS_APPLICATION_HOME_BASE_PATH}/${LOAN_APPLICATION_BASE_PATH}/todos`,
          url: `${baseUrl}/${LOANS_APPLICATION_HOME_BASE_PATH}/${loanApplicationId}/todos`,
          documentTitle: `Todos - ${loanApplicationId}`,
        },
      },
    }),
  };
};

export const getManageTemplatesConfig = (basePath: string, baseUrl: string) => {
  return {
    manageTemplatesHome: {
      path: `${basePath}/${BACK_OFFICE_BASE_PATH}/${MANAGE_TEMPLATES}`,
      url: `${baseUrl}/${BACK_OFFICE_BASE_PATH}/${MANAGE_TEMPLATES}`,
      documentTitle: 'Manage Text/Email Templates',
    }
  };
};

export const getManageTemplatesRoutes = (basePath: string, baseUrl: string) => {
  const config = getManageTemplatesConfig(basePath, baseUrl);
  return {
    manageTemplatesHome: (
      <Route exact path={config.manageTemplatesHome.path}>
        <Page routeDefinition={config.manageTemplatesHome}>
          <ManageTemplatesDashboard />
        </Page>
      </Route>
    )
  };
};

export const getCRMTaskRoutesConfig = (basePath: string, baseUrl: string) => {
  return {
    crmTaskDashboard: {
      path: `${basePath}/${BACK_OFFICE_BASE_PATH}/${CRM_TASKS}`,
      url: `${baseUrl}/${BACK_OFFICE_BASE_PATH}/${CRM_TASKS}`,
      documentTitle: 'My CRM Tasks',
    }
  };
};

export const getCRMTaskRoutes = (basePath: string, baseUrl: string) => {
  const config = getCRMTaskRoutesConfig(basePath, baseUrl);
  return {
    crmTaskDashboard: (
      <Route exact path={config.crmTaskDashboard.path}>
        <Page routeDefinition={config.crmTaskDashboard}>
          <CRMTaskDashboard />
        </Page>
      </Route>
    )
  };
};

export const getDelinquentLoansRoutesConfig = (basePath: string, baseUrl: string) => {
  return {
    delinquenctLoansDashboard: {
      path: `${basePath}/${BACK_OFFICE_BASE_PATH}/${DELINQUENT_LOANS}`,
      url: `${baseUrl}/${BACK_OFFICE_BASE_PATH}/${DELINQUENT_LOANS}`,
      documentTitle: 'Upcoming Maturity and Delinquency',
    }
  };
};

export const getDelinquentLoansRoutes = (basePath: string, baseUrl: string) => {
  const config = getDelinquentLoansRoutesConfig(basePath, baseUrl);
  return {
    delinquenctLoansDashboard: (
      <Route exact path={config.delinquenctLoansDashboard.path}>
        <Page routeDefinition={config.delinquenctLoansDashboard}>
          <MaturityAndDqInformation />
        </Page>
      </Route>
    )
  };
};


export const getCreateListRoutesConfig = (basePath: string, baseUrl: string) => {
  return {
    createList: {
      path: `${basePath}/${BACK_OFFICE_BASE_PATH}/${CREATE_LEAD_LISTS}`,
      url: `${baseUrl}/${BACK_OFFICE_BASE_PATH}/${CREATE_LEAD_LISTS}`,
      documentTitle: 'Create List',
    }
  };
}

export const getLenderBankInfoRoutesConfig = (basePath: string, baseUrl: string) => {
  return {
    lenderBankInformationHome: {
      path: `${basePath}/${BACK_OFFICE_BASE_PATH}/${LENDER_BANK_INFO_HOME_BASE_PATH}`,
      url: `${baseUrl}/${BACK_OFFICE_BASE_PATH}/${LENDER_BANK_INFO_HOME_BASE_PATH}`,
      documentTitle: 'Lender Bank Informations',
    },
  };
};

export const getLenderRoutesConfig = (basePath: string, baseUrl: string) => {
  return {
    lendersHome: {
      path: `${basePath}/${BACK_OFFICE_BASE_PATH}/${LENDERS_HOME_BASE_PATH}`,
      url: `${baseUrl}/${BACK_OFFICE_BASE_PATH}/${LENDERS_HOME_BASE_PATH}`,
      documentTitle: 'Lenders Home',
    },
  };
};

export const getCompanyProfileRoutesConfig = (basePath: string, baseUrl: string) => {
  return {
    companyProfile: {
      path: `${basePath}/${BACK_OFFICE_BASE_PATH}/${COMPANY_PROFILE_BASE_PATH}`,
      url: `${baseUrl}/${BACK_OFFICE_BASE_PATH}/${COMPANY_PROFILE_BASE_PATH}`,
      documentTitle: 'Company Profile',
    },
  };
};

export const getLeadViewsRoutesConfig = (basePath: string, baseUrl: string) => {
  return {
    listViewsHome: {
      path: `${basePath}/${BACK_OFFICE_BASE_PATH}/${LEADS_LISTS_VIEWS_HOME_BASE_PATH}`,
      url: `${baseUrl}/${BACK_OFFICE_BASE_PATH}/${LEADS_LISTS_VIEWS_HOME_BASE_PATH}`,
      documentTitle: 'Lead List Views',
    },
  };
};

export const getLenderWitNoRMRoutesConfig = (basePath: string, baseUrl: string) => {
  return {
    lendersWithNoRmHome: {
      path: `${basePath}/${BACK_OFFICE_BASE_PATH}/${LENDERS_NO_RM_HOME_BASE_PATH}`,
      url: `${baseUrl}/${BACK_OFFICE_BASE_PATH}/${LENDERS_NO_RM_HOME_BASE_PATH}`,
      documentTitle: 'Lenders With No Rm Home',
    },
  };
};

export enum LoanType {
  RESIDENTIAL_BRIDGE = 'Residential Bridge',
  RESIDENTIAL_TERM = 'Residential Term',
}

export const LoanSummaryBridgeBySubType = ({ data }) => {
  const { loanSubType } = data;
  switch (loanSubType) {
    case LoanSubType.STABILIZED_BRIDGE:
      return <LoanSummaryStabilizedBridge data={data} />;
    case LoanSubType.MULTIFAMILY_BRIDGE_5PLUS:
      return <LoanSummaryBridgeMultifamily data={data} />
    default:
      return <LoanSummaryBridge data={data} />;
  }
};

export const getLenderBankInformationRoutes = (basePath: string, baseUrl: string) => {
  const config = getLenderBankInfoRoutesConfig(basePath, baseUrl);

  return {
    lenderBankInformationHome: (
      <Route exact path={config.lenderBankInformationHome.path}>
        <Page routeDefinition={config.lenderBankInformationHome}>
          <LenderBankInformationHome />
        </Page>
      </Route>
    ),
  };
};

export const LoanSummary = ({ loanDetails }) => {
  const { loanType } = loanDetails;

  switch (loanType) {
    case LoanType.RESIDENTIAL_BRIDGE:
      return <LoanSummaryBridgeBySubType data={loanDetails} />;
    case LoanType.RESIDENTIAL_TERM:
      return <LoanSummaryTerm data={loanDetails} />;
  }
};
export const getLoanRoutes = (basePath: string, baseUrl: string) => {
  const config = getLoanRoutesConfig(basePath, baseUrl);

  return {
    loansHome: (
      <Route exact path={config.loansHome.path}>
        <Page routeDefinition={config.loansHome}>
          <BackOfficeLoansHome />
        </Page>
      </Route>
    ),
    loanDetails: (
      <Route path={config.loans().path}>
        <LoanDetails
          renderLoanSummary={({ loanDetails }) => (
            <LoanSummary loanDetails={loanDetails} />
          )}
        />
      </Route>
    ),
    loanApplications: (
      <Route path={config.loanApplications().path}>
        <LoanApplicationDetailsCardActionsButton />
      </Route>
    )
  };
};

export const getCompanyProfileRoutes = (basePath: string, baseUrl: string) => {
  const config = getCompanyProfileRoutesConfig(basePath, baseUrl);

  return {
    companyProfile: (
      <Route path={config.companyProfile.path}>
        <Page routeDefinition={config.companyProfile}>
          <CompanyProfileCRMWrapper />
        </Page>
      </Route>
    ),
  };
};

export const getLenderRoutes = (basePath: string, baseUrl: string) => {
  const config = getLenderRoutesConfig(basePath, baseUrl);
  return {
    lendersHome: (
      <Route exact path={config.lendersHome.path}>
        <Page routeDefinition={config.lendersHome}>
          <LenderDashboard />
        </Page>
      </Route>
    )
  };
};
export const getNewLeadRoutesConfig = (basePath: string, baseUrl: string) => {
  return {
    newLeadsHome: {
      path: `${basePath}/${BACK_OFFICE_BASE_PATH}/${NEW_LEADS_HOME_BASE_PATH}`,
      url: `${baseUrl}/${BACK_OFFICE_BASE_PATH}/${NEW_LEADS_HOME_BASE_PATH}`,
      documentTitle: 'New Lead',
    }
  };
};

export const getNewLeadRoutes = (basePath: string, baseUrl: string) => {
  const config = getNewLeadRoutesConfig(basePath, baseUrl);
  return {
    newLeadsHome: (
      <Route exact path={config.newLeadsHome.path}>
        <Page routeDefinition={config.newLeadsHome}>
          <NewLeadCreationHome />
        </Page>
      </Route>
    )
  };

};

export const getLeadsDashboardRoutesConfig = (basePath: string, baseUrl: string) => {
  return {
    leadsDashboard: {
      path: `${basePath}/${BACK_OFFICE_BASE_PATH}/${LEADS_DASHBOARD_BASE_PATH}`,
      url: `${baseUrl}/${BACK_OFFICE_BASE_PATH}/${LEADS_DASHBOARD_BASE_PATH}`,
      documentTitle: 'Leads Dashboard',
    }
  };
};

export const getLeadsDashboardRoutes = (basePath: string, baseUrl: string) => {
  const config = getLeadsDashboardRoutesConfig(basePath, baseUrl);
  return {
    leadsDashboard: (
      <Route exact path={config.leadsDashboard.path}>
        <Page routeDefinition={config.leadsDashboard}>
          <AllLeadDashboard />
        </Page>
      </Route>
    )
  };

};


export const getTPOLeadsDashboardRoutesConfig = (basePath: string, baseUrl: string) => {
  return {
    leadsDashboard: {
      path: `${basePath}/${BACK_OFFICE_BASE_PATH}/${TPO_LEADS_DASHBOARD_BASE_PATH}`,
      url: `${baseUrl}/${BACK_OFFICE_BASE_PATH}/${TPO_LEADS_DASHBOARD_BASE_PATH}`,
      documentTitle: 'TPO Leads Dashboard',
    }
  };
};

export const getTPOLeadsDashboardRoutes = (basePath: string, baseUrl: string) => {
  const config = getTPOLeadsDashboardRoutesConfig(basePath, baseUrl);
  return {
    leadsDashboard: (
      <Route exact path={config.leadsDashboard.path}>
        <Page routeDefinition={config.leadsDashboard}>
          <TpoLeadsDashboard />
        </Page>
      </Route>
    )
  };

};


export const getBorrowerLeadsDashboardRoutesConfig = (basePath: string, baseUrl: string) => {
  return {
    leadsDashboard: {
      path: `${basePath}/${BACK_OFFICE_BASE_PATH}/${BORROWER_LEADS_DASHBOARD_BASE_PATH}`,
      url: `${baseUrl}/${BACK_OFFICE_BASE_PATH}/${BORROWER_LEADS_DASHBOARD_BASE_PATH}`,
      documentTitle: 'Borrower Leads Dashboard',
    }
  };
};

export const getBorrowerLeadsDashboardRoutes = (basePath: string, baseUrl: string) => {
  const config = getBorrowerLeadsDashboardRoutesConfig(basePath, baseUrl);
  return {
    leadsDashboard: (
      <Route exact path={config.leadsDashboard.path}>
        <Page routeDefinition={config.leadsDashboard}>
          <BorrowerLeadsDashboard />
        </Page>
      </Route>
    )
  };

};

export const getLeadDetailsRoutesConfig = (basePath?: string, baseUrl?: string) => {
  return {
    leadDetails: (leadId?: string) => ({
      path: `${basePath}/${BACK_OFFICE_BASE_PATH}/lead-details/:leadId`,
      url: `${baseUrl}/${BACK_OFFICE_BASE_PATH}/lead-details/${leadId}`,
      documentTitle: `Lead Details`,
    })
  }
};

export const getLeadDetailsRoutes = (basePath: string, baseUrl: string) => {
  const config = getLeadDetailsRoutesConfig(basePath, baseUrl);
  const { salesforceLeadStore } = useStore();
  return {
    leadDetails: (
      <Route exact path={config.leadDetails().path}>
        <Page routeDefinition={config.leadDetails()}>
          <LeadDetails salesforceLeadStore={salesforceLeadStore} />
        </Page>
      </Route>
    )
  };

};

export const useLeadDetailsRoutes = () => {
  const { globalStore } = useBaseStore();
  const { routeBasePath, routeBaseUrl } = globalStore;
  return {
    leadDetailsRoutes: getLeadDetailsRoutes(routeBasePath, routeBaseUrl),
    leadDetailsRoutesConfig: getLeadDetailsRoutesConfig(
      routeBasePath,
      routeBaseUrl
    ),
  };
};


export const getCreateListRoutes = (basePath: string, baseUrl: string) => {
  const config = getCreateListRoutesConfig(basePath, baseUrl);
  return {
    createList: (
      <Route exact path={config.createList.path}>
        <Page routeDefinition={config.createList}>
          <CreateList />
        </Page>
      </Route>
    )
  };
}

export const getLeadListViewsRoutes = (basePath: string, baseUrl: string) => {
  const config = getLeadViewsRoutesConfig(basePath, baseUrl);
  return {
    listViewsHome: (
      <Route exact path={config.listViewsHome.path}>
        <Page routeDefinition={config.listViewsHome}>
          <LeadListViewDashBoard />
        </Page>
      </Route>
    ),
  };
};

export const getLenderDetailsRoutesConfig = (basePath: string, baseUrl: string) => {
  return {
    lenderDetails: {
      path: `${basePath}/${BACK_OFFICE_BASE_PATH}/${LENDER_DETAILS}`,
      url: `${baseUrl}/${BACK_OFFICE_BASE_PATH}/${LENDER_DETAILS}`,
      documentTitle: 'Lender Details',
    },
  };
};

export const getLenderDetailsRoutes = (basePath: string, baseUrl: string) => {
  const config = getLenderDetailsRoutesConfig(basePath, baseUrl);
  return {
    lenderDetails: (
      <Route exact path={config.lenderDetails.path}>
        <Page routeDefinition={config.lenderDetails}>
          <LenderDetails />
        </Page>
      </Route>
    ),
  };
};

export const getLenderWitnNoRmRoutes = (basePath: string, baseUrl: string) => {
  const config = getLenderWitNoRMRoutesConfig(basePath, baseUrl);
  return {
    lendersWithNoRmHome: (
      <Route exact path={config.lendersWithNoRmHome.path}>
        <Page routeDefinition={config.lendersWithNoRmHome}>
          <LendersWithNoRmDashboard />
        </Page>
      </Route>
    )
  };
};

export const getImportLeadsConfig = (basePath: string, baseUrl: string) => {
  return {
    importLeadsHome: {
      path: `${basePath}/${BACK_OFFICE_BASE_PATH}/${IMPORT_LEADS_BASE_PATH}`,
      url: `${baseUrl}/${BACK_OFFICE_BASE_PATH}/${IMPORT_LEADS_BASE_PATH}`,
      documentTitle: 'Import Leads',
    }
  };
};

export const getImportLeadsRoutes = (basePath: string, baseUrl: string) => {
  const config = getImportLeadsConfig(basePath, baseUrl);
  return {
    importLeadsHome: (
      <Route exact path={config.importLeadsHome.path}>
        <Page routeDefinition={config.importLeadsHome}>
          <ImportLeads />
        </Page>
      </Route>
    )
  };
}


export const getPossibleMatchesConfig = (basePath: string, baseUrl: string) => {
  return {
    possibleMatches: {
      path: `${basePath}/${BACK_OFFICE_BASE_PATH}/${POSSIBLE_MATCHES}`,
      url: `${baseUrl}/${BACK_OFFICE_BASE_PATH}/${POSSIBLE_MATCHES}`,
      documentTitle: 'Possible Matches',
    }
  };
};

export const gettPossibleMatchesRoutes = (basePath: string, baseUrl: string) => {
  const config = getPossibleMatchesConfig(basePath, baseUrl);
  return {
    possibleMatches: (
      <Route exact path={config.possibleMatches.path}>
        <Page routeDefinition={config.possibleMatches}>
          <Layout>
            <PossibleMatchesContainer />
          </Layout>
        </Page>
      </Route>
    )
  };
};
