import { observer } from 'mobx-react';
import {
  StandardDialog,
  TrackRecordLineItem,
} from '@roc/ui';

import { Document } from '@roc/feature-types';
import { useBorrowerTrackRecordStore } from '@roc/feature-borrowers';
import { useEffect } from 'react';
import { UNDERWRITING } from '@roc/feature-utils';


interface TrackRecordLineItemDialogProps {
  open: boolean;
  onClose: () => void;
  loanId: any;
  document: Document;
}

export const TrackRecordLineItemDialog = observer(({ open, onClose, loanId, document }: TrackRecordLineItemDialogProps) => {
  const { borrowerTrackRecordStore } = useBorrowerTrackRecordStore();

  if (!borrowerTrackRecordStore) {
    return <></>;
  }

  useEffect(() => {
    borrowerTrackRecordStore.setDocument(document);
    borrowerTrackRecordStore.internal = false;
  }, [document]);

  return (
    <StandardDialog
      open={open}
      maxWidth="xl"
      handleClose={onClose}
      title={`${borrowerTrackRecordStore.document?.borrowerName} - Track Record`}
      removeContentBox
      dialogContent={
        <TrackRecordLineItem
          store={borrowerTrackRecordStore}
          borrowerId={document?.borrowerId}
          hideTitle={true}
          onClose={() => {
            onClose();
            borrowerTrackRecordStore.documentStore.fetchProcessDocuments(loanId, UNDERWRITING)
          }}
        />
      }
    />)
});