import { action, configure, makeObservable, observable } from 'mobx';
import { _BaseStore } from './_baseStore';

export interface EnvironmentVariables {
  production?: boolean,
  jwt?: string,
  gtmTrackingId?: string,
  whitelabelHost?: string,
  restApiBaseURL?: string,
  isInternalChatString?: string,
  isInternalAppString?: string,
  productName?: string,
  contentfulParams?: {
    space: string,
    accessToken: string,
    previewToken: string,
    environment?: string,
  },
  features?: {
    loanChatMeet?: boolean
    privateChat?: boolean
  }
}

export class EnvironmentStore {
  environmentVariables: EnvironmentVariables;

  constructor(environmentVariables: EnvironmentVariables) {
    this.environmentVariables = environmentVariables;
    makeObservable(this, {
      environmentVariables: observable
    });
  }

}

configure({
  enforceActions: 'never',
});
