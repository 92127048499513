import { Grid } from '@material-ui/core';
import { createTextField, EditableDialog } from '@roc/ui';
import FundingPreferenceStore from 'libs/feature-documents/src/documents/stores/documentForms/fundingPreference/fundingPreferenceStore';
import { observer } from 'mobx-react';
import { useBaseStore } from '@roc/feature-app-core';

interface Props {
  fundingPreferenceStore: FundingPreferenceStore;
}

export const EditAdditionalBorrowerEntityMemberDialog = observer(
  (props: Props) => {
    const { fundingPreferenceStore } = props;
    const { editBorrowerEntityMembersStore } = fundingPreferenceStore;
    const store = editBorrowerEntityMembersStore;
    const { globalStore } = useBaseStore();
    const disabled = !fundingPreferenceStore.canEdit && !globalStore.userFeatures.isClosingReviewer;

    return (
      <EditableDialog
        title={'Additional Member'}
        open={store.dialogState !== null}
        dialogState={store.dialogState}
        handleAdd={() => store.submit()}
        handleEdit={() => store.submit()}
        handleClose={() => store.closeDialog()}
        dialogContent={
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {createTextField({
                store,
                disabled,
                label: 'Full Name',
                fieldName: 'fullName',
                testId: 'fullName',
              })}
            </Grid>
            <Grid item xs={12}>
              {createTextField({
                store,
                disabled,
                label: 'Title',
                fieldName: 'roleInEntity',
                testId: 'roleInEntity',
              })}
            </Grid>
          </Grid>
        }
      />
    );
  }
);
