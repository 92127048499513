import {
  Client,
  Conversation,
  Message,
  Paginator,
  Participant,
} from '@twilio/conversations';

export interface PromiseHolderType<T> {
  promise?: Promise<T>;
  resolve?: (c: T) => void;
  reject?: (reason: String) => void;
}
export type ConversationsType = Record<string, Conversation>; // key: conversationSid value: Twilio Conversation
export type ConversationsGroupData = {
  totalCount?: number;
  rows?: string[]; // conversationSid
};
export type ConversationsGroupType = Record<
  ConversationGroup | string,
  ConversationsGroupData
>; // key: ConversationGroup value: totalCount, rows
export type ConversationsPromiseType = Record<
  string,
  PromiseHolderType<Conversation>
>; // key: conversationSid value: Promise<Twilio Conversation>
export type ConversationTwilioIdentityType = Record<string, string>; // key: conversationSid value: twilioIdentity
export type ConversationChatRoom = Record<string, number>; // key: conversationSid value: chatRoomId
export type ConversationHasBorrower = Record<string, boolean>; // key: conversationSid value: boolean
export type ConversationChatRoomType = Record<string, ChatRoomType>; // key: conversationSid value: ChatRoomType
export type ConversationChatRoomStatus = Record<string, ChatRoomStatus>; // key: conversationSid value: ChatRoomStatys
export type ConversationGroupMap = Record<string, ConversationGroup>; // key: conversationSid value: ConversationGroup
export type ConversationStarred = Record<string, boolean>; // key: conversationSid value: isStarred
export type ConversationMentioned = Record<string, boolean>; // key: conversationSid value: hasMentions
export type ConversationLastMentionedDate = Record<string, string>; // key: conversationSid value: lastMentionedDate
export type ConversationWriteAccess = Record<string, boolean>; // key: conversationSid value: boolean
export type TwilioIdentityClientType = Record<string, Client>; // key: twilioidentity value: Twilio Client
export type MessagesType = Record<string, Message[]>; // key: conversationSid value: Twilio Messages
export type MessagesGroupedByDateType = Record<string, Message[]>; // key: date value: Twilio Messages
export type TwilioReplyMessageType = Record<
  string,
  Record<string, ConversationMessage>
>; // key: conversationSid value: ( key: messageSid value: ConversationMessage)
export type MessagesPaginatorType = Record<string, Paginator<Message>>; // key: conversationSid value: Twilio Paginator Messages
export type ParticipantType = Record<string, Participant[]>; // key: conversationSid value: Twilio Participants
export type UnreadMessagesCountType = Record<string, number>; // key: conversationSid value: Twilio Unread Count
export type TypingDataType = Record<string, string[]>; // key: conversationSid value: Twilio Typing Data
export type ConversationMessageDefaultTag = Record<string, string>; // key: conversationSid value: Message Default Tag Name
export type ConversationTags = Record<string, Set<string>>; // key: conversationSid value: Tag Names
export type RequireParticipant = Record<string, boolean>; // key: conversationSid value: requiredParticipant
export enum FilterType {
  LOAN_ID = 'LOAN_ID',
  STARRED = 'STARRED',
  MENTIONS = 'MENTIONS',
  MENTIONS_UNREAD = 'MENTIONS_UNREAD',
  UNREAD = 'UNREAD',
  SUPPORT = 'SUPPORT',
  INTERNAL_SUPPORT = 'INTERNAL_SUPPORT',
  DEAL_ROOM_TOPIC = 'DEAL_ROOM_TOPIC_ROOM',
  TASK_ID = 'TASK_ID',
}
export interface Filter {
  type: FilterType;
  value?: string | number;
}

export interface ConversationData {
  chatRoomId: number;
  conversationSid: string;
  chatRoomName: string;
  chatRoomType: ChatRoomType;
  twilioIdentity: string;
  isStarred: boolean;
  hasMentions: boolean;
  lastMentionedDate: string;
  conversationGroup: ConversationGroup;
  status: ChatRoomStatus;
  requiredParticipant: boolean;
}

export interface PrivateChatRoomResponse {
  chatRoomId: number;
  conversationSid: string;
  roomKey: string;
  chatRoomName: string;
  chatRoomType: ChatRoomType;
}

export interface ParticipantData {
  participantSid: string;
  userIdentity: number; //this is userId
  twilioIdentity: string;
}

export enum ConversationType {
  MY,
  LOAN,
  APPRAISAL,
  DRAWS,
}

export enum ChatAppNotificationType {
  off = 'off',
  all = 'all',
  mentions = 'mentions',
}

export enum ChatRoomType {
  UNDERWRITING = 'UNDERWRITING',
  CLOSING = 'CLOSING',
  INSURANCE = 'INSURANCE',
  APPRAISAL = 'APPRAISAL',
  ADHOC = 'ADHOC',
  PRIVATE_ROOM = 'PRIVATE_ROOM',
  DRAWS = 'DRAWS',
  EXTENSIONS = 'EXTENSIONS',
  PAYOFF = 'PAYOFF',
  DRAWS_INTERNAL = 'DRAWS_INTERNAL',
  DRAWS_CONSTRUCTION_DATA_REVIEW = 'DRAWS_CONSTRUCTION_DATA_REVIEW',
  DEAL_ROOM = 'DEAL_ROOM',
  TAMARISK_APPRAISAL = 'TAMARISK_APPRAISAL',
  TECH_SUPPORT = 'TECH_SUPPORT',
  INITIAL_PAYMENTS = 'INITIAL_PAYMENTS',
  SALES_SUPPORT = 'SALES_SUPPORT',
  CLOSING_SUPPORT = 'CLOSING_SUPPORT',
  INTERNAL_ROOM = 'INTERNAL_ROOM',
  DEAL_ROOM_TOPIC_ROOM = 'DEAL_ROOM_TOPIC_ROOM',
  NEW_LOAN_REQUEST = 'NEW_LOAN_REQUEST',
  FORECLOSURE = 'FORECLOSURE',
  TASKS = 'TASKS',
  LEAD_NOTIFICATIONS = 'LEAD_NOTIFICATIONS',
  POST_CLOSING_EXTERNAL = 'POST_CLOSING_EXTERNAL',
  TITLE = 'TITLE',
  INTERNAL_VALUATION_REVIEW = 'INTERNAL_VALUATION_REVIEW',
}

export enum ChatRoomStatus {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
}

export enum ConversationGroup {
  ROOMS = 'ROOMS',
  DIRECT_MESSAGES = 'DIRECT_MESSAGES',
  PRIVATE_MESSAGES = 'PRIVATE_MESSAGES',
  TECH_SUPPORT = 'TECH_SUPPORT',
  SALES_SUPPORT = 'SALES_SUPPORT',
  CLOSING_SUPPORT = 'CLOSING_SUPPORT',
  TASKS = 'TASKS',
}

export enum PreferredConversationName {
  TECH_SUPPORT = 'Tech Support / Help',
  SALES_SUPPORT = 'Sales Support',
  CLOSING_SUPPORT = 'Closing Support',
}

export const EMAIL_CHATROOM_TYPES_ALLOWED = [
  ChatRoomType.INSURANCE,
];

export interface UserOption {
  firstName: string;
  lastName: string;
  emailAddress: string;
  userId: number;
  role: string;
  roleDisplayName: string;
  roleDisplayColor: string;
  active: boolean;
}

export interface ParticipantDetails {
  userId: number;
  userName: string;
  role: string;
  roleDisplayName?: string;
  roleDisplayColor: string;
  hasRole: boolean;
  fullName: string;
  isInternal: boolean;
  isRoleInternal?: boolean;
  active: boolean;
}

export interface RoleInfoForAuthor {
  role: string;
  roleColor: string;
  hasRole: boolean;
  isActive: boolean;
}

export interface ConversationAttribute {
  chatRoomType: ChatRoomType;
  loanId: number;
  appraisalId: number;
  companyId: number;
  userId: number;
  proposalTopicId: number;
}

export interface ParticipantAttribute {
  firstName: string;
  lastName: string;
  role: string;
  userId: number;
  isSystemUser: boolean;
  isInternal: boolean;
}

export interface MessageAttribute {
  authorName: string;
  tags?: string[];
  mentions?: string[];
  replyMessageSid?: string;
}

export enum MessageStatus {
  Sending = 'Sending',
  Sent = 'Sent',
  Delivered = 'Delivered',
  Failed = 'Failed',
  None = 'none (incoming)',
  Read = 'Read',
}

export interface MessageDeliveryParticipantData {
  participantSid: string;
  name: string;
  status: MessageStatus;
}

export type MessageDeliveryReceipts = Record<
  MessageStatus,
  MessageDeliveryParticipantData[]
>;

export interface FetchConversationMessageRequest {
  conversationSid: string;
  messageSidList: string[];
}

export interface ConversationMessage {
  conversationSid: string;
  sid: string;
  body: string;
  author: string;
  attributes: MessageAttribute;
}

export interface AddParticipantRequest {
  chatRoomId: number;
  userName?: string;
  userNames?: string[];
  isSystemUser: boolean;
  newUser?: {
    firstName: string;
    lastName: string;
    participantType: string;
  };
}
export interface ResetUnreadCountRequest {
  chatRoomId: number;
  conversationSid: string;
  chatRoomType: ChatRoomType;
  loanId?: number;
  appraisalId?: number;
  userId: number;
  companyId?: number;
  proposalTopicId?: number;
}
export interface UpdateConversationNameRequest {
  chatRoomId: number;
  conversationName: string;
  userId?: number;
  loanId?: number;
}

export interface UpdateConversationStarredStatusRequest {
  chatRoomId: number;
  userId: number;
  isStarred: boolean;
}

export interface MessageTypes {
  supportMessages: ConversationsGroupData;
  showGrouped: boolean;
}
