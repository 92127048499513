import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { StandardDialog } from '@roc/ui';
import { usePayoffRequestStore } from '../hooks/usePayoffRequestStore';
import { useLoanStore } from '@roc/feature-loans';
import PayoffRequestForm from './payoffRequestForm';

interface PayoffRequestDialogProps {
  open: boolean;
  onClose: () => void;
  loanId: string;
}

export const PayoffRequestDialog = observer(
  ({ open, onClose, loanId }: PayoffRequestDialogProps) => {
    const { payoffRequestStore } = usePayoffRequestStore();
    const { loanStore } = useLoanStore();

    const handleConfirm = () => {
      payoffRequestStore.getActivePayoffDataByLoanId(loanId);
      onClose();
    };

    useEffect(() => {
      return () => payoffRequestStore.reset();
    }, []);

    return (
      <StandardDialog
        open={open}
        title="New Payoff Request"
        maxWidth="sm"
        handleClose={onClose}
        dialogContent={
          <PayoffRequestForm onConfirm={handleConfirm} onClose={onClose} />
        }
      />
    );
  }
);
