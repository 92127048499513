import { capitalize } from '@roc/feature-utils';
import { LoanDocument } from '@roc/feature-types';
import { Service } from '@roc/feature-app-core';

const url = {
  DOCUMENTS: '/api/v1/loan/loanDocuments',
  EXTENSION_DOCUMENTS: '/api/v1/loan/loanDocuments/getExtensionDocumentsExternal',
  DOCUMENT_DOWNLOAD: '/api/v1/loan/loanDocuments/downloadLoanDocument',
  DOCUMENT_DOWNLOAD_BY_NAME: '/api/v1/loan/loanDocuments/downloadDocumentByName',
  DOCUMENT_ADD_DOC: '/api/v1/loan/loanDocuments/addLoanDocuments',
  DOCUMENT_SECTION_UPLOAD: '/api/v1/loan/loanDocuments/uploadLoanDocuments',
  DOCUMENT_UPLOAD: '/api/v1/loan/loanDocuments/uploadLoanDocument',
  DOCUMENT_SECTIONS: '/api/v1/loan/loanDocuments/getLoanDocumentSections',
  GET_UNREAD_DOCUMENT_COMMENTS:
    '/api/v1/loan/documentCommunication/getUnreadDocumentComments',
  GET_UNREAD_DRAW_COMMENTS:
    '/api/v1/loan/documentCommunication/getAllUnreadDrawComments',
  DOCUMENT_HISTORY: '/api/v1/loan/loanDocuments/getLoanDocumentHistory',
  DOWNLOAD_DOCUMENT_HISTORY:
    '/api/v1/loan/loanDocuments/downloadLoanDocumentHistory',
  DOCUMENTS_PENDING: '/api/v1/loan/loanDocuments/getPendingLoanDocuments',
  GET_PROPERTY_DOCUMENT: '/api/v1/loan/loanDocuments/getPropertyDocument',
  DOWNLOAD_TERMSHEET: '/api/v1/loan/loanDocuments/downloadTermSheet',
  DOWNLOAD_DEAL_SUMMARY: '/api/v1/loan/loanDocuments/downloadDealSummary',
  UPDATE_LOAN_DOCUMENT: '/api/v1/document/formDocument/updateLoanDocument',
  DOWNLOAD_RATE_LOCK_AGREEMENT:
    '/api/v1/loan/loanDocuments/downloadRateLockAgreement',
  DOWNLOAD_ALL_FROM_SECTION:
    '/api/v1/loan/loanDocuments/downloadAllFromSection',
  DOCUMENTS_ACCEPTED: '/api/v1/loan/loanDocuments/getBorrowerAcceptedLoanDocuments',
  PROPOSAL_DOCUMENTS: '/api/v1/loan/loanDocuments/getProposalDocumentsExternal',
  UPLOAD_PROPOSAL_DOCUMENT: '/api/v1/loan/loanDocuments/uploadProposalDocument',
  TRADE_DOCUMENTS: '/api/v1/assetm/trade/getTradeDocs',
  DOWNLOAD_BORROWER_PAST_DOCUMENTS: '/api/v1/loan/loanDocuments/downloadBorrowerPastLoanDocuments',
  EDIT_LOAN_DOCUMENT: '/nportal/rest/priv/loanDocs/editLoanDocument',
  DELETE_LOAN_DOCUMENT: '/nportal/rest/priv/loanDocs/deleteLoanDocument'
};

export class DocumentService extends Service {
  getDocumentSections() {
    return this.get(url.DOCUMENT_SECTIONS);
  }

  getUnreadDocumentComments(loanId: string, loanProcess: string) {
    return this.get(url.GET_UNREAD_DOCUMENT_COMMENTS, {
      loanId,
      loanProcess: capitalize(loanProcess),
    });
  }

  getUnreadDrawComments(loanId: string, drawId: string) {
    return this.get(
      `${url.GET_UNREAD_DRAW_COMMENTS}?loanId=${loanId}&drawId=${drawId}&loanProcess=Draws`
    );
  }

  getDocuments(loanId: string, loanProcess: string) {
    return this.get(
      `${url.DOCUMENTS}?loanId=${loanId}&loanProcess=${capitalize(loanProcess)}`
    );
  }

  getPendingDocuments(loanId: string, loanProcess: string) {
    return this.get(
      `${url.DOCUMENTS_PENDING}?loanId=${loanId}&loanProcess=${capitalize(
        loanProcess
      )}`
    );
  }

  getPropertyDocument(loanId: number, documentName: string, collateralId: number) {
    return this.get(
      `${url.GET_PROPERTY_DOCUMENT}?loanId=${loanId}&documentName=${documentName}&collateralId=${collateralId}`
    );
  }

  getProposalDocuments(loanId: string) {
    return this.get(`${url.PROPOSAL_DOCUMENTS}?loanId=${loanId}`);
  }

  getAcceptedDocuments(loanId: string, loanProcess: string) {
    return this.get(
      `${url.DOCUMENTS_ACCEPTED}?loanId=${loanId}&loanProcess=${capitalize(
        loanProcess
      )}`
    );
  }

  getDrawDocuments(loanId: string, drawId: string) {
    return this.get(
      `${url.DOCUMENTS}?loanId=${loanId}&loanProcess=Draws&drawId=${drawId}`
    );
  }

  getExtensionDocuments(loanId: string, extensionId: string) {
    return this.get(
      `${url.EXTENSION_DOCUMENTS}?loanId=${loanId}&loanProcess=Extensions&extensionId=${extensionId}`
    );
  }

  addDocument(files: LoanDocument[]) {
    return this.post(url.DOCUMENT_ADD_DOC, files);
  }

  uploadSectionDocument(files) {
    return this.post(url.DOCUMENT_SECTION_UPLOAD, files);
  }

  uploadDocument(file, loanDocId: number) {
    return this.post(`${url.DOCUMENT_UPLOAD}/${loanDocId}`, file);
  }

  uploadProposalDocument(file, loanDocId: number) {
    return this.post(`${url.UPLOAD_PROPOSAL_DOCUMENT}/${loanDocId}`, file);
  }

  downloadDocument(body) {
    return this.post(
      url.DOCUMENT_DOWNLOAD,
      body,
      {},
      { responseType: 'arraybuffer' }
    );
  }

  downloadDocumentByName(body) {
    return this.post(
      url.DOCUMENT_DOWNLOAD_BY_NAME,
      body,
      {},
      { responseType: 'arraybuffer' }
    );
  }

  getDocumentHistory(documentId: number) {
    const endpointUrl = `${url.DOCUMENT_HISTORY}/${documentId}`;
    return this.get(endpointUrl, {});
  }

  downloadDocumentHistory(body) {
    return this.post(
      url.DOWNLOAD_DOCUMENT_HISTORY,
      body,
      {},
      { responseType: 'arraybuffer' }
    );
  }

  downloadTermSheet(loanId: number) {
    return this.get(
      url.DOWNLOAD_TERMSHEET,
      {
        loanId,
      },
      {
        responseType: 'arraybuffer',
      }
    );
  }

  downloadRateLockAgreement(loanId: number) {
    return this.get(
      url.DOWNLOAD_RATE_LOCK_AGREEMENT,
      {
        loanId,
      },
      {
        responseType: 'arraybuffer',
      }
    );
  }

  downloadDealSummary(loanId: number) {
    return this.get(
      url.DOWNLOAD_DEAL_SUMMARY,
      {
        loanId,
      },
      {
        responseType: 'arraybuffer',
      }
    );
  }

  updateDocument = (loanDocument: any) => {
    return this.put(url.UPDATE_LOAN_DOCUMENT, loanDocument);
  };

  downloadAllFromSection(loanId: number, sectionId: number) {
    return this.get(
      url.DOWNLOAD_ALL_FROM_SECTION,
      {
        loanId,
        sectionId,
      },
      {
        responseType: 'arraybuffer',
      }
    );
  }

  downloadBorrowerPastLoanDocuments(borrowerId: number) {
    return this.get(
      url.DOWNLOAD_BORROWER_PAST_DOCUMENTS,
      { borrowerId },
      { responseType: 'arraybuffer' }
    );
  }

  editLoanDocument(loanDocument) {
    return this.put(url.EDIT_LOAN_DOCUMENT, { editedDocument: loanDocument });
  }

  deleteDocument(loanDocument) {
    return this.put(url.DELETE_LOAN_DOCUMENT, loanDocument);
  }
}
