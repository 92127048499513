import {
  Box,
  Grid,
  Table,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CurrencyField, PercentageField, DialogState } from '@roc/ui';
import { formatCurrency } from '@roc/ui/utils';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { brokerFeeNames, BrokerFeeType } from '../constants/brokerConstants';
import { useBrokersStore } from '../hooks/useBrokersStore';
import {
  Email,
  FirstName,
  LastName,
  AutocompleteBrokers,
  CompanyName,
  EinNumber,
  Phone
} from '../brokerForm/components/manageBrokerFormFields';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(2),
  },
  tableContainer: {},
  table: {
    '& td': {
      padding: theme.spacing(2, 4),
    },
    '& tr td:first-child': {
      width: 'auto',
      padding: theme.spacing(0, 4),
    },
  },
  row: {
    borderTop: '1px solid #BEBEBE',
  },
  bold: {
    fontWeight: 'bold',
  },
  fieldCell: {
    padding: theme.spacing(0, 1),
  },
  totalRow: {
    fontWeight: 'bold',
    backgroundColor: '#FAFAFA',
    '& tr td:nth-child(1), & tr td:nth-child(3)': {
      textAlign: 'center',
    },
  },
  headerRow: {
    backgroundColor: '#EEE',
  },
  rateFieldInput: {
    '& input': {
      padding: theme.spacing(1),
    },
  },
}));

export const BrokerFees = observer(() => {
  const classes = useStyles();
  const { brokersStore } = useBrokersStore();
  const { brokerFeesStore } = brokersStore;
  const { brokerFees } = brokerFeesStore;
  const brokerFields = brokersStore.manageBrokerFormStore.form.fields;
  const brokerId = brokerFields.brokerId.value;

  useEffect(() => {
    const fetchData = async () => {
      if (brokerId !== null) {
        await brokerFeesStore.fetchBrokerFees();
      }
    };
    fetchData();
  }, [brokerId]);

  return (
    <Box>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <TableContainer className={classes.tableContainer}>
            <Table className={classes.table} aria-label="Pricing Economics">
              <TableRow className={classes.headerRow}>
                <TableCell />
                <TableCell>
                  <strong>Bridge Amount</strong>
                </TableCell>
                <TableCell>
                  <strong>Term Amount</strong>
                </TableCell>
              </TableRow>
              {brokerFeeNames.map((fee, i) => {
                return (
                  <TableRow key={i}>
                    <TableCell>
                      <>{fee.label}</>
                    </TableCell>
                    <TableCell className={classes.fieldCell}>
                      <FeeField
                        type={fee.type}
                        testId={'bridge' + fee.attribute}
                        value={(brokerFees && brokerFees.bridgeFees) ? brokerFees.bridgeFees[fee?.attribute] : null}
                        disabled={fee.disableForBridge}
                        onChange={(_, val) =>
                          brokerFeesStore.onBridgeFeeChange(
                            fee.attribute,
                            val
                          )
                        }
                      />
                    </TableCell>
                    <TableCell className={classes.fieldCell}>
                      <FeeField
                        type={fee.type}
                        testId={'term' + fee.attribute}
                        value={(brokerFees && brokerFees.termFees) ? brokerFees.termFees[fee?.attribute] : null}
                        disabled={fee.disableForTerm}
                        onChange={(_, val) =>
                          brokerFeesStore.onTermFeeChange(fee.attribute, val)
                        }
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Box>
  );
});

const FeeField = ({ type, disabled, testId, value, onChange }) => {
  const classes = useStyles();

  if (disabled) {
    return <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>- - -</Box>
  }

  if (type === BrokerFeeType.CURRENCY) {
    return (
      <CurrencyField
        inputClassname={classes.rateFieldInput}
        variant={'outlined'}
        testId={testId}
        value={value}
        onChange={onChange}
      />
    );
  }

  if (type === BrokerFeeType.PERCENTAGE) {
    return (
      <PercentageField
        inputClassname={classes.rateFieldInput}
        variant={'outlined'}
        testId={testId}
        value={value}
        onChange={onChange}
      />
    );
  }

  return null;
};
