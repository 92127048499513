import { observer } from 'mobx-react';

import {
  DataGrid as Grid,
} from '@roc/ui';

const filter = {
  filter: 'agTextColumnFilter',
  floatingFilter: true,
  floatingFilterComponentParams: {
    suppressFilterButton: true,
  },
};

export const AvailableListingGrid = observer(({ availablePropertiesGridStore, toolbar, onSortChanged, onFilterChanged, frameworkComponents, columns }) => {

  return (
    <Grid
      columns={columns}
      rows={availablePropertiesGridStore.gridData.data.rows}
      frameworkComponents={frameworkComponents}
      toolbar={toolbar}
      onSortChanged={onSortChanged}
      onFilterChanged={onFilterChanged}
      isLoading={availablePropertiesGridStore.isLoading}
      showFilters={availablePropertiesGridStore.showFilters}
      sortModel={availablePropertiesGridStore.sortModel}
      filterModel={availablePropertiesGridStore.filterModel}
      paginationData={availablePropertiesGridStore.gridData.meta}
      setPageNumber={availablePropertiesGridStore.setPageNumber}
      domLayout="autoHeight"
    />
  )

})