import { Grid, Tooltip } from '@material-ui/core';
import { Help } from '@material-ui/icons';
import { StandardDialog, Button } from '@roc/ui';
import { ResolutionTaskItemForm } from './resolutionTaskItem';
import { TOOLTIP_BACKGROUND_REVIEW_MESSAGE } from '@roc/feature-utils';

export type ResolutionTaskItemDialogProps = {
  open?: boolean;
  onClose?: () => void;
  task?: any;
  store: any
};

export const ResolutionTaskItemDialog = (
  props: ResolutionTaskItemDialogProps
) => {
  const { open, onClose, task, store } = props;
  return (
    <StandardDialog
      open={open}
      title={
        <div>
          Provide Resolution
          <Tooltip
            placement="right"
            title={<p style={{ fontSize: '12px' }}> {store.getToolTipMessageByTaskTemplateId(task)} </p>}
          >
            <Help color="primary" style={{ fontSize: '18px', marginLeft: '3px', marginBottom: '10px' }} />
          </Tooltip>
        </div>}
      maxWidth="sm"
      handleClose={onClose}
      dialogContent={
        <div style={{ marginTop: '-40px' }}>
          <ResolutionTaskItemForm
            onClose={onClose}
            task={task}
            store={{ store }}
          />
        </div>
      }
    />
  );
};

export default ResolutionTaskItemDialog;
