import html2canvas, { Options } from 'html2canvas';
import jsPDF from 'jspdf';
import { RefObject, useRef } from 'react';

export type GeneratePdfOptions = Partial<Options> & {
  singlePage?: boolean
};

const A4 = { width: 446.46, height: 631.4175 };

const getCanvasFromElement = async (
  element: HTMLElement,
  options?: GeneratePdfOptions
) => {
  return html2canvas(element, options);
};

const generateMultiPagePdf = async (element: HTMLElement, options?: GeneratePdfOptions): Promise<jsPDF> => {
  let jspdf = new jsPDF();

  const children = element.children;

  for (let i = 0; i < children.length; i++) {
    const child = children[i] as HTMLElement;

    const canvas = await getCanvasFromElement(child, options);
    const { height, width } = canvas;
    const orientation = height > width ? 'portrait' : 'landscape';
    const ratio = A4.width / canvas.width;

    const canvasWidth = width * ratio;
    const canvasHeight = height * ratio;

    if (i == 0) {
      jspdf = new jsPDF(orientation, 'px', [width * ratio, height * ratio]);
    } else if (i > 0) {
      jspdf.addPage([width * ratio, height * ratio], orientation);
    } 

    jspdf.addImage(canvas.toDataURL('image/jpeg'), 'JPEG', 0, 0, canvasWidth, canvasHeight);
  }

  return jspdf;
};

const generateSinglePagePdf = async (element: HTMLElement, options?: GeneratePdfOptions): Promise<jsPDF> => {
  const canvas = await getCanvasFromElement(element, options);
  const { height, width } = canvas;
  const orientation = height > width ? 'portrait' : 'landscape';

  const ratio = A4.width / canvas.width;
  const jspdf = new jsPDF(orientation, 'px', [width * ratio, height * ratio]);

  const size = jspdf.internal.pageSize;
  const url = canvas.toDataURL('image/jpeg');
  jspdf.addImage(url, 'JPEG', 0, 0, size.getWidth(), size.getHeight());

  return jspdf;
};

export const downloadAsPdf = async (
  element: HTMLElement,
  filename,
  options?: GeneratePdfOptions
) => {
  const jspdf = options.singlePage
    ? await generateSinglePagePdf(element, options)
    : await generateMultiPagePdf(element, options);
  jspdf.save(filename);
};

export const getPdfBlobUrl = async (element: HTMLElement, options?: GeneratePdfOptions) => {
  const jspdf = await generateMultiPagePdf(element, options);
  return jspdf.output('bloburl');
};
