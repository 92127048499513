import { action, computed, makeObservable, observable } from 'mobx';
import { isEmptyObject } from '@roc/feature-utils';
import { FormStore, GlobalStore } from '@roc/feature-app-core';
import { BorrowerInformationSteps } from '../utils/constants';

export abstract class BaseBorrowerInformationStore {
  public globalStore: GlobalStore;
  public mainBorrowerFormStore: FormStore;
  public mainBorrower: any;
  public coBorrowersCount: number;
  public coBorrowersFormStores: Array<FormStore>;
  public coBorrowers: Array<any>;
  public borrowerInformationActiveStep: BorrowerInformationSteps;
  public editBorrowerActiveIndex: number;

  public abstract createBorrowerForm(): FormStore;

  constructor(globalStore: GlobalStore) {
    this.globalStore = globalStore;
    this.mainBorrowerFormStore = this.createBorrowerForm();
    this.setDefaults();

    makeObservable(this, {
      mainBorrower: observable,
      coBorrowersCount: observable,
      coBorrowers: observable,
      borrowerInformationActiveStep: observable,
      editBorrowerActiveIndex: observable,
      setDefaults: action,
      reset: action,
      setCoBorrowersCount: action,
      handleBorrowerSubLabelClick: action,
      borrowerSubLabels: computed,
      totalOwnership: computed,
      isTotalOwnershipExceedingLimit: computed,
      isAnyPctOwnershipNotEmpty: computed,
      borrowers: computed,
      setBorrowers: action,
      updateBorrowersOwnershipFromFormStores: action,
    });
  }

  setDefaults() {
    this.mainBorrower = {};
    this.coBorrowersCount = null;
    this.coBorrowersFormStores = [];
    this.coBorrowers = [];
    this.borrowerInformationActiveStep = BorrowerInformationSteps.PERSONAL_INFORMATION;
    this.editBorrowerActiveIndex = null;
  }

  reset() {
    this.setDefaults();
  }

  setCoBorrowersCount(coBorrowersCount) {
    this.coBorrowersCount = coBorrowersCount != '' ? coBorrowersCount : null;
  }

  get borrowerSubLabels() {
    const coBorrowersSubLabels = this.coBorrowers.filter(borrower => !isEmptyObject(borrower)).map(borrower => `${borrower.firstName} ${borrower.lastName}`);
    if (!isEmptyObject(this.mainBorrower)) {
      return [`${this.mainBorrower.firstName} ${this.mainBorrower.lastName}`, ...coBorrowersSubLabels];
    }
    return [];
  }

  handleBorrowerSubLabelClick = (index) => {
    if (index === 0) {
      this.borrowerInformationActiveStep = BorrowerInformationSteps.EDIT_PERSONAL_INFORMATION;
    }
    else {
      this.editBorrowerActiveIndex = index - 1;
      this.borrowerInformationActiveStep = BorrowerInformationSteps.EDIT_CO_BORROWER_INFORMATION;
    }
  };

  get totalOwnership() {
    const borrowersFields = [
      this.mainBorrowerFormStore.form.fields.pctOwnership,
      ...(this.coBorrowersFormStores || []).map(coBorrowerFormStore =>
        coBorrowerFormStore?.form?.fields?.pctOwnership
      ),
    ];

    const totalOwnershipArray = borrowersFields.map((borrowerFields) => {
      return parseInt(borrowerFields?.value || '0') || 0;
    });

    return totalOwnershipArray.reduce((total, ownership) => total + ownership, 0);
  }

  get isTotalOwnershipExceedingLimit() {
    return this.totalOwnership > 100;
  }

  handleBorrowerPctOwnershipNextStep = () => {
    return this.isAnyPctOwnershipNotEmpty && !this.isTotalOwnershipExceedingLimit;
  }


  get isAnyPctOwnershipNotEmpty() {
    const allBorrowersFields = [
      this.mainBorrowerFormStore.form.fields.pctOwnership,
      ...(this.coBorrowersFormStores || []).map(coBorrowerFormStore =>
        coBorrowerFormStore?.form?.fields?.pctOwnership
      ),
    ];

    return allBorrowersFields.some(borrowerFields => {
      const pctOwnership = borrowerFields?.value;
      return typeof pctOwnership === 'number' && pctOwnership !== 0;
    });
  }

  get borrowers() {
    let borrowers = [this.mainBorrower];
    if (this.coBorrowersCount > 0) {
      borrowers = [...borrowers, ...this.coBorrowers];
    }
    return borrowers;
  }

  updateBorrowersOwnershipFromFormStores(){
    this.mainBorrower.pctOwnership = this.mainBorrowerFormStore.getFormValues().pctOwnership;
    this.coBorrowers.forEach((coBorrower, index) => {
      const formStore = this.coBorrowersFormStores[index];
      coBorrower.pctOwnership = formStore.form.fields.pctOwnership.value;
    })
  }

  
  setBorrowers(mainBorrower, coBorrowers) {
    this.mainBorrowerFormStore.loadForm(mainBorrower);
    this.mainBorrower = mainBorrower;

    this.setCoBorrowersCount(coBorrowers.length);
    coBorrowers.forEach(coBorrower => {
      this.coBorrowers.push(coBorrower)
      const coBorrowerFormStore = this.createBorrowerForm();
      coBorrowerFormStore.loadForm(coBorrower);
      this.coBorrowersFormStores.push(coBorrowerFormStore);
    });
  }
}