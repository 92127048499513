export interface ThreadMeta {
  loanId?: string;
  drawId?: string;
  sectionId?: string;
  borrowerId?: string;
  collateralId?: string;
}

export enum ThreadType {
  PROCESS,
  DOCUMENT,
}

export interface Thread {
  type: ThreadType;
  title: string;
  meta: ThreadMeta;
}
