import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { Paper } from '@roc/ui';
import { Layout } from '@roc/client-portal-shared/components';
import { useAppConfigurations } from '@roc/client-portal-shared/hooks';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(3, 0, 4),
      borderRadius: theme.spacing(2),
      boxShadow:
        'rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px',
    },
  })
);

export const PaymentComplete: React.FC = () => {
  const classes = useStyles();
  const { data } = useAppConfigurations();
  return (
    <Layout>
      <Box mb={2} mt={3}>
        <Typography variant="h3" gutterBottom>
          Payment Completed
        </Typography>
      </Box>
      <Paper className={classes.paper}>
        <Box mt={3} px={4}>
          <Grid container direction="column" justifyContent="center">
            <Box textAlign="center">
              <Typography variant="h4">
                Your Good Faith Deposit Was successfully Processed!
              </Typography>
            </Box>
            <Box mt={6} textAlign="center">
              <Typography variant="body1">
                Thank you for completing the payment for the Good Faith Deposit.
              </Typography>
            </Box>
            {data.phoneNumber && (
              <>
                <Box mt={2} textAlign="center">
                  <Typography variant="body1">
                    If you need further assistance, please contact us at:
                  </Typography>
                </Box>
                <Box mt={2} textAlign="center">
                  <Typography variant="h6">{data.phoneNumber}</Typography>
                </Box>
              </>
            )}
          </Grid>
        </Box>
      </Paper>
    </Layout>
  );
};

export default PaymentComplete;
