import { Typography, Grid, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  buttonContainer: {
    marginTop: 32,
  },
});

export const SubmitDrawRequestForm = ({ onCancel, onSubmit }) => {
  const classes = useStyles();
  return (
    <Grid container justifyContent="center">
      <Grid item>
        <Typography variant="body1">
          You are about to submit the Draw Request Form for review. You will be
          allowed to make further changes after the review is complete.
        </Typography>
      </Grid>
      <Grid
        item
        container
        justifyContent="space-around"
        className={classes.buttonContainer}
      >
        <Grid item xs={4} md={4}>
          <Button
            variant="outlined"
            color="primary"
            size="large"
            fullWidth
            onClick={onCancel}
          >
            CANCEL
          </Button>
        </Grid>
        <Grid item xs={4} md={4}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            fullWidth
            onClick={onSubmit}
          >
            CONTINUE
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
