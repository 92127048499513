

import { makeObservable, action, observable, computed, flow } from 'mobx';
import { roundHalf } from '@roc/feature-utils';

export abstract class DrawReviewReportBaseStore {
  globalStore;

  drawDetails: any;

  loanValues: any = {};

  pictures: any = [];

  tableData: any;

  drawId: number;

  inspectionLink: string;

  editable: boolean;

  ignoreIdsList: any = [];

  ignoreIdsListCompletePdf: any = [];

  constructor(globalStore) {
    this.globalStore = globalStore;
    this.editable = false;
    this.ignoreIdsList = [];
    this.ignoreIdsListCompletePdf = [];
    makeObservable(this, {
      tableData: observable,
      drawDetails: observable,
      loanValues: observable,
      pictures: observable,
      loadTableData: action,
      approveRequest: flow,
      saveReview: flow,
      declineRequest: flow,
      onTableContentChange: flow,
      isFilled: computed,
      totalCalculatedRenoBudget: computed,
      totalPreviouslyDisbursed: computed,
      totalCompleteToDate: computed,
      totalRequestedThisDraw: computed,
      totalDisbursement: computed,
      totalCapex: computed,
      drawId: observable,
      inspectionLink: observable,
      editable: observable,
      switchEdition: action,
      grossWire: computed,
      isProjectTotalEqualToReno: computed,
      capex: computed,
      projectCompletionCapex: computed,
      totalRenoBudgetWithCapex: computed,
      verifiedCompletedRenovationBudget: computed,
      projectCompletion: computed,
      workCompletedSinceFunding: computed,
      totalProjectWire: computed,
      fees: computed,
      netWire: computed,
      constructionHoldbackRemainingCalculated: computed,
      totalRenoBudgetCompleted: computed,
      constructionHoldbackDisbursedIncludingThisDraw: computed,
      constructionHoldbackRemainingIncludingThisDraw: computed
    });
  }

  reset() {
    this.tableData = null;
    this.drawId = null;
    this.editable = false;
    this.ignoreIdsList = [];
    this.ignoreIdsListCompletePdf = [];
  }


  get isFilled() {
    return true;
  }

  get totalCapex() {
    let total = 0;
    if (this.tableData && this.tableData.length) {
      this.tableData.forEach(data => {
        data.subCategories.forEach(category => {
          if (category.itemized.length) {
            category.itemized.forEach(item => {
              if (item.capex) {
                const capexAmount = item.capex * item.cost / 100;
                total += capexAmount;
              }
            });
          } else {
            if (category.general.capex) {
              const capexAmount = category.general.capex * category.general.cost / 100;
              total += capexAmount;
            }
          }
        });
      });
    }
    return {
      percentage: total > 0 ? 100 : 0,
      amount: total
    };
  }

  get totalDisbursement() {
    let total = 0;
    if (this.tableData && this.tableData.length) {
      this.tableData.forEach(data => {
        data.subCategories.forEach(category => {
          if (category.itemized.length) {
            category.itemized.forEach(item => {
              if (item.loanFunderLLCApprovedPercentage) {
                total += roundHalf((item.loanFunderLLCApprovedPercentage / 100) * item.cost) - (item.previouslyDisbursed || 0);
              }
            });
          } else {
            if (category.general.loanFunderLLCApprovedPercentage) {
              total += roundHalf((category.general.loanFunderLLCApprovedPercentage / 100) * category.general.cost)
                - (category.general.previouslyDisbursed || 0);
            }
          }
        });
      });
    }
    return {
      percentage: roundHalf((total / this.totalCalculatedRenoBudget) * 100),
      amount: total
    };
  }

  get totalPreviouslyDisbursed() {
    let total = 0;
    if (this.tableData && this.tableData.length) {
      this.tableData.forEach(data => {
        data.subCategories.forEach(category => {
          if (category?.itemized?.length) {
            category.itemized.forEach(detail => {
              if (detail.previouslyDisbursed) total += detail.previouslyDisbursed;
            });
          } else {
            if (category.general.previouslyDisbursed) total += category.general.previouslyDisbursed;
          }
        });
      });
    }
    return {
      percentage: roundHalf((total / this.totalCalculatedRenoBudget) * 100),
      amount: total,
    };
  }

  get totalRequestedThisDraw() {
    let cost = 0;
    if (this.tableData && this.tableData.length) {
      this.tableData.forEach(data => {
        data.subCategories.forEach(category => {
          if (category.itemized.length) {
            category.itemized.forEach(item => {
              if (item.percentageCompleteToDate) {
                cost += roundHalf((item.percentageCompleteToDate / 100) * item.cost) - (item.previouslyDisbursed || 0);
              }
            });
          } else {
            if (category.general.percentageCompleteToDate) {
              cost += roundHalf((category.general.percentageCompleteToDate / 100) * category.general.cost)
                - (category.general.previouslyDisbursed || 0);
            }
          }
        });
      });
    }
    return {
      percentage: roundHalf((cost / this.totalCalculatedRenoBudget) * 100),
      amount: cost
    };
  }

  get totalCompleteToDate() {
    const completeToDate = this.totalRequestedThisDraw.amount + this.totalPreviouslyDisbursed.amount;
    return {
      amount: completeToDate,
      percentage: roundHalf((completeToDate / this.totalCalculatedRenoBudget) * 100)
    };
  }

  get totalCalculatedRenoBudget() {
    let total = 0;
    if (this.tableData && this.tableData.length) {
      this.tableData.forEach(data => {
        data.subCategories.forEach(category => {
          if (category.itemized.length) {
            category.itemized.forEach(item => {
              if (item.cost) {
                total += item.cost;
              }
            })
          } else {
            if (category.general.cost) total += category.general.cost;
          }
        });
      })
    }
    return total;
  }

  get isProjectTotalEqualToReno() {
    return (Math.round(this.totalCalculatedRenoBudget * 100) === Math.round(this.loanValues.renoBudget * 100));
  }

  get grossWire() {
    if (!this.totalProjectWire.amount) return 0;
    return roundHalf(this.totalProjectWire.amount - this.loanValues.contructionHoldbackReimbursed);
  };

  get capex() {
    const capexCalc: number = this.totalCapex.amount > 0 ? this.totalCapex.amount : this.loanValues.sunkCost;
    return {
      amount: capexCalc,
      percentage: capexCalc ? 100 : 0
    }
  }

  get projectCompletionCapex() {
    const capexCalc: number = this.isProjectTotalEqualToReno ? this.capex.amount : 0;
    return capexCalc;
  }

  get totalRenoBudgetWithCapex() {
    return this.loanValues.renoBudget + this.capex.amount;
  }

  get verifiedCompletedRenovationBudget() {
    return this.totalPreviouslyDisbursed.amount + this.totalDisbursement.amount;
  }

  get projectCompletion() {
    if (!this.totalDisbursement.amount || !this.totalCalculatedRenoBudget) return { amount: 0, percentage: 0 }
    const totalCompletionPercentage = (this.totalPreviouslyDisbursed.amount + this.totalDisbursement.amount + this.projectCompletionCapex) / (this.loanValues.projectTotal);
    const totalCompletionAmount = totalCompletionPercentage * this.loanValues.projectTotal;
    return {
      amount: totalCompletionAmount,
      percentage: totalCompletionPercentage
    }
  }

  get workCompletedSinceFunding() {
    if (!this.projectCompletion.amount) return { amount: 0, percentage: 0 }
    const amount = this.projectCompletion.amount - this.capex.amount;
    return {
      amount: amount,
      percentage: (amount / this.loanValues.renoBudget) * 100
    };
  }

  get totalProjectWire() {
    if (!this.workCompletedSinceFunding) return { amount: 0, percentage: 0 };
    const amount = this.workCompletedSinceFunding.amount * (this.loanValues.constructionHoldbackRate / 100);
    return {
      amount: amount,
      percentage: (amount / this.loanValues.constructionHoldback) * 100
    }
  }

  get fees() {
    return 300;
  }

  get netWire() {
    return this.grossWire - this.fees;
  }

  get constructionHoldbackRemainingCalculated() {
    if (this.grossWire < 0) return this.loanValues.contructionHoldbackRemaining;
    return this.loanValues.contructionHoldbackRemaining - this.grossWire;
  }

  get totalRenoBudgetCompleted() {
    return (this.constructionHoldbackDisbursedIncludingThisDraw / this.loanValues.constructionHoldback) * 100;
  }

  get constructionHoldbackDisbursedIncludingThisDraw() {
    if (this.grossWire < 0) return this.loanValues.contructionHoldbackReimbursed;
    return this.loanValues.contructionHoldbackReimbursed + this.grossWire;
  }

  get constructionHoldbackRemainingIncludingThisDraw() {
    return this.loanValues.constructionHoldback - this.constructionHoldbackDisbursedIncludingThisDraw;
  }


  abstract loadTableData(drawId);

  abstract approveRequest();

  abstract saveReview();

  abstract declineRequest();

  initializeDrawRequest(drawRequest, drawDTO) {
    if (drawRequest?.dataContent != null) {
      drawRequest.dataContent = JSON.parse(drawRequest.dataContent);
      const categoryMap = {};
      for (const category of drawDTO.scopeOfWorkCategories) {
        categoryMap[category.categoryId] = category.name;
      }
      for (const category of drawRequest.dataContent) {
        category.name = categoryMap[category.id];
      }
    }
  }

  onTableContentChange(
    categoryName,
    lineItemName,
    detailIndex,
    value,
    fieldName
  ) {
    const selectedCategory = this.tableData.find(
      elem => elem.name === categoryName
    );
    const selectedSubCategory = selectedCategory.subCategories.find(
      elem => elem.name === lineItemName
    );
    if (detailIndex != null) {
      const selectedDetail = selectedSubCategory.itemized[detailIndex];
      selectedDetail[fieldName] = value;
    } else {
      selectedSubCategory.general[fieldName] = value;
    }
  }

  switchEdition() {
    this.editable = !this.editable;
  }

  addToIgnoredList(id: string) {
    if (!this.ignoreIdsList.includes(id)) {
      this.ignoreIdsList.push(id);
    }
  }

  addToIgnoreListCompletePdf(id: string) {
    if (!this.ignoreIdsListCompletePdf.includes(id)) {
      this.ignoreIdsListCompletePdf.push(id);
    }
  }

  getIgnoredList() {
    return this.ignoreIdsList;
  }

  getIgnoredCompletePdfList() {
    return this.ignoreIdsListCompletePdf;
  }
}
