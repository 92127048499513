import { DialogState } from '@roc/ui';
import { action, makeObservable, observable, values } from 'mobx';
import { FormStore } from '@roc/feature-app-core';
import {
  LenderContact,
  MailingListSubscription,
} from '../../companyProfile/types/companyProfileTypes';

const REQUIRED_MESSAGE = 'This field is required';

const form = {
  fields: {
    FirstName: {
      value: '',
      error: null,
      rule: '',
    },
    LastName: {
      value: '',
      error: null,
      rule: 'required',
      message: REQUIRED_MESSAGE,
    },
    Email: {
      value: '',
      error: null,
      rule: 'required|email',
      message: 'Insert a valid e-mail address.',
    },
    Job_Function__c: {
      value: '',
      error: null,
      rule: '',
    },
    Role__c: {
      value: '',
      error: null,
      rule: '',
    },
    Ownership__c: {
      value: 0,
      error: null,
      rule: 'required_if:Role__c,Owner/Principal',
      message: REQUIRED_MESSAGE,
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export class LenderContactDialogStore extends FormStore {
  private contact: LenderContact;
  mode: DialogState;
  open = false;

  constructor(globalStore) {
    super(form, globalStore);
    makeObservable(this, {
      mode: observable,
      open: observable,
      openAdd: action,
      openEdit: action,
      openDialog: action,
      closeDialog: action,
    });
  }

  openAdd = () => {
    this.openDialog(DialogState.ADD);
  };

  openEdit = (contact: LenderContact) => {
    this.openDialog(DialogState.EDIT);
    this.contact = contact;
    this.loadForm(contact);
  };

  openDialog = (mode: DialogState) => {
    this.reset();
    this.contact = null;
    this.mode = mode;
    this.open = true;
  };

  closeDialog = () => {
    this.open = false;
  };

  getUpdatedContact() {
    const formValues = this.getFormValues();
    return {
      ...this.contact,
      ...formValues,
    };
  }
}
