import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowBack, ChevronLeft } from '@material-ui/icons';
import { Layout } from '@roc/feature-app-core';
import { Button, PaperTitle } from '@roc/ui';
import { observer } from 'mobx-react';
import React from 'react';

const useStyles = makeStyles(theme => ({
  containerClass: {
    padding: 0,
  },
  headingBox: {
    width: '60vw',
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      width: '80vw',
      marginLeft: 60,
      marginTop: theme.spacing(1),
    },
  },
  heading: {
    color: '#4B89DA',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: 24,
  },
  titleBoxClass: {
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
  },
  subtitle: {
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
  },
  paperClass: {
    marginBottom: theme.spacing(2),
  },
  paperTitleClass: {
    paddingBottom: 0,
  },
}));

export const LoanDetailsLayout = observer(props => {
  const classes = useStyles();

  return (
    <Layout
      headingClass={classes.heading}
      headingBoxClass={classes.headingBox}
      titleBoxClass={classes.titleBoxClass}
      subtitleBoxClass={classes.subtitle}
      {...props}
    />
  );
});

export const LoanDetailsPaperLayout = observer(props => {
  const classes = useStyles();
  const { children, title, onBack, ...rest } = props;
  return (
    <Layout
      containerClass={classes.containerClass}
      headingClass={classes.heading}
      headingBoxClass={classes.headingBox}
      titleBoxClass={classes.titleBoxClass}
      subtitleBoxClass={classes.subtitle}
      wrapWithPaper={true}
      paperClass={classes.paperClass}
      paperTitleComponent={
        (
          <Box display="flex">
            {
              onBack ?
                <Button
                  testId="back-button"
                  color="default"
                  onClick={onBack}
                  style={{ margin: '16px 0 0 16px' }}
                  startIcon={<ArrowBack fontSize="large" />}>Back</Button> : null
            }
            <PaperTitle title={title} titleVariant="h4" titleClass={classes.paperTitleClass} />
          </Box>
        )
      }
      {...rest}
    >
      <Box display="flex" p={2}>
        {children}
      </Box>
    </Layout>
  );
});
