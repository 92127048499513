import React from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import { Button, IconButton } from "@roc/ui";
import { Box } from "@material-ui/core";

export const QueryBuilderButton = ({type, label, onClick, readonly, config}) => {
  const typeToOnlyIcon = {
    "delGroup": <DeleteIcon />,
    "delRuleGroup": <DeleteIcon />,
    "delRule": <DeleteIcon />,
    "addRuleGroup": <AddIcon />,
  };
  const typeToIcon = {
    "addRule": <AddIcon />,
    "addGroup": <AddIcon />,
    "addRuleGroupExt": <AddIcon />,
  };
  const typeToColor = {
    "addRule": "primary",
    "addGroup": "primary",
    "delGroup": "primary",
    "delRuleGroup": "primary",
    "delRule": "primary",
  };
  if (typeToOnlyIcon[type])
    return <IconButton testId={null} size="small" disabled={readonly} onClick={onClick} color={typeToColor[type]}>{typeToOnlyIcon[type]}</IconButton>;
  else
    return (
      <Box p={1}>
        <Button testId={null}  variant="contained" size="small" disabled={readonly} onClick={onClick} color={typeToColor[type]} startIcon={typeToIcon[type]}>{label}</Button>
      </Box>
    );
};