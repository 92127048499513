import { GlobalStore } from '@roc/client-portal-shared/stores';
import { AnySoaRecord } from 'dns';
import { action, flow, makeObservable, observable } from 'mobx';
import { FormStore } from '@roc/feature-app-core';
import {
  getLoanSubtype,
  mapLoanToDscrCalculatorFormValues,
} from '../../dscrCalculator/utils/dscrCalculatorUtils';
import {
  CalculatedLiquidityValues,
  LiquidityCalculatorFormValues,
} from '../types/multifamilyLiquidityCalculatorTypes';

const form = {
  fields: {
    loanId: {
      value: '',
      error: null,
      rule: '',
    },
     purchasePrice: {
      value: '',
      error: null,
      rule: 'required',
    },
    renovationAmount: {
      value: '',
      error: null,
      rule: 'required',
    },
    rate: {
      value: '',
      error: null,
      rule: 'required',
    },
    inPlaceMonthlyIncome: {
      value: '',
      error: null,
      rule: 'required',
    },
    inPlaceMonthlyExpenses: {
      value: '',
      error: null,
      rule: 'required',
    },
    verifiedLiquidity: {
      value: '',
      error: null,
      rule: 'required',
    }
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export abstract class MultifamilyLiquidityCalculatorBaseStore extends FormStore {
  protected globalStore: GlobalStore;
  calculatedValues: any;

  abstract getCalculatedLiquidity(data): CalculatedLiquidityValues;
  abstract getLoanValues(loanId: string): LiquidityCalculatorFormValues;
  abstract getAutocompleteOptions(searchTerm: string);

  loanSearchText = '';
  loanSearchOptions: { label: string; value: string }[] = [];

  constructor(globalStore) {
    super(form, globalStore);
    this.globalStore = globalStore;

    makeObservable(this, {
      loanSearchText: observable,
      calculatedValues: observable,
      loanSearchOptions: observable,
      getCalculatedLiquidity: flow,
      getAutocompleteOptions: flow,
      getLoanValues: flow,
      fetchOptions: flow,
      fetchLoanValues: flow,
      fetchCalculatedLiquidity: flow,
      clearCalculatedValues: action,
    });
  }

  *fetchOptions(searchText: string) {
    try {
      if (!searchText) {
        this.loanSearchOptions = [];
        return;
      }
      const options = yield this.getAutocompleteOptions(searchText);
      this.loanSearchOptions = options;
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'An error occurred while loading the list of loans.',
      });
    }
  }

  *fetchLoanValues(loanId: string) {
    try {
      const loanValues = yield this.getLoanValues(loanId);
      const values = mapLoanToDscrCalculatorFormValues(loanValues);
      this.loadForm(values);
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'An error occurred while loading the loan data.',
      });
    }
  }

  *fetchCalculatedLiquidity() {
    try {
      this.runFormValidationWithMessage();
      if (this.form.meta.isValid) {
        const purchasePrice = this.form.fields.purchasePrice.value;
        const renovationAmount = this.form.fields.renovationAmount.value;
        const rate = this.form.fields.rate.value;
        const inPlaceMonthlyExpenses = this.form.fields.inPlaceMonthlyExpenses.value;
        const inPlaceMonthlyIncome =this.form.fields.inPlaceMonthlyIncome.value;
        const verifiedLiquidity = this.form.fields.verifiedLiquidity.value;

        const data = {
          rate: rate,
          inPlaceMonthlyExpenses: inPlaceMonthlyExpenses,
          inPlaceMonthlyIncome: inPlaceMonthlyIncome,
          renovationAmount : renovationAmount,
          purchasePrice : purchasePrice,
          verifiedLiquidity : verifiedLiquidity
        };
        const response = yield this.getCalculatedLiquidity(data);
        this.calculatedValues = response;
      }
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'An error occurred while loading the calculated values.',
      });
    }
  }

  clearCalculatedValues() {
    this.calculatedValues = null;
  }
}
