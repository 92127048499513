import { Box } from "@material-ui/core"
import { Reply } from "@material-ui/icons"

export const ReplyButton = ({
  isCurrentUserMessage,
  onClick
}: {
  isCurrentUserMessage: boolean,
  onClick: () => void
}) => {
  return (
    <Box onClick={onClick}>
      <Box position={'absolute'}
        left={isCurrentUserMessage ? 24 : 0}
        bottom={0}
        pr={1}
        borderRadius={8}
        fontSize={10}
        color={'#777'}
        fontWeight={'bold'}>
        <span>
          <Reply style={{
            fontSize: 10
          }} />
        </span>
        <span style={{
          cursor: 'pointer',
          textDecoration: 'underline'
        }}>
          REPLY
        </span>
      </Box>
    </Box>
  )
}