import { Layout } from "@roc/feature-app-core";
import { BridgeProfitAndDscrCalculator } from "./bridgeProfitAndDscrCalculator";
import { useBridgeProfitAndDscrCalculatorStore } from "../hooks/useBridgeProfitAndDscrCalculatorStore";

export const BridgeProfitAndDscrCalculatorContainer = () => {
  const { bridgeProfitAndDscrCalculatorStore } = useBridgeProfitAndDscrCalculatorStore();
  return (
    <Layout title='Bridge Profit & DSCR Calculator'>
      <BridgeProfitAndDscrCalculator store={bridgeProfitAndDscrCalculatorStore} />
    </Layout>
  );
};
