import { Grid, Typography } from "@material-ui/core"
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { SelectField } from "@roc/ui";
import { useState } from "react";

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: 200,
  },
  title: {
    marginRight: 8,
    marginLeft: 16,
    paddingBottom: 2,
    fontWeight: 'bold',
    fontSize: 12,
  }
}));

export const AddSnippet = ({ messageSnippets, onSelect }) => {
  const classes = useStyles();
  const [selectedSnippet, setSelectedSnippet] = useState('');
  return (
    <div className={classes.container}>
      <Typography variant="body2" className={classes.title}>Snippet:</Typography>
      <SelectField
        value={selectedSnippet}
        options={messageSnippets}
        onChange={value => {
          setSelectedSnippet(value);
          onSelect(value)
        }}
        fullWidth
        testId={"snippets"}
        style={{
          fontSize: 12
        }}
      />
    </div>
  );
}