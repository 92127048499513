import {
  Box,
  Grid,
  IconButton,
  ListItem,
  ListItemText,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { useStore } from '@roc/client-portal-shared/hooks';
import { FormStore, Layout, useUserStore } from '@roc/feature-app-core';
import { isBlank, isNil } from '@roc/feature-utils';
import { Button, CurrencyField, Paper, TextField } from '@roc/ui';
import { formatCurrency } from '@roc/ui/utils';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { BackOfficeLoansHome } from '../../dashboard/backOfficeLoansHome';
import { Link } from 'react-router-dom';
import { useLoanRoutes } from '@roc/feature-loans-routes-config';
import { LeadDetailsStore } from '../../../stores/leadDetails/leadDetailsStore';
import { useLeadsStore } from '../../../hooks/useLeadsStore';
import { BackOfficeLoansByContact } from '../../dashboard/backOfficeLoansByContact';


const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(2),
  },
  tableContainer: {},
  table: {
    '& td': {
      padding: theme.spacing(2, 4),
    },
    '& tr td:nth-child(1), & tr td:nth-child(3)': {
      width: 'auto',
    },
    '& tr td:nth-child(2), & tr td:nth-child(4)': {
      width: 'auto',
      textAlign: 'right',
      padding: theme.spacing(0, 4)
    },
  },
  row: {
    borderTop: '1px solid #BEBEBE',
  },
  bold: {
    fontWeight: 'bold',
  },
  fieldCell: {
    padding: theme.spacing(0, 1),
  },
  totalRow: {
    fontWeight: 'bold',
    backgroundColor: '#FAFAFA',
    '& tr td:nth-child(1), & tr td:nth-child(3)': {
      textAlign: 'center',
    },
  },
  headerRow: {
    backgroundColor: '#EEE',
  },
  rateFieldInput: {
    '& input': {
      padding: theme.spacing(1),
    },
  },
  container: {
    paddingLeft: '0',
    paddingRight: '0'
  }
}));

export const LoanVolume = observer(({ loanVolumeStats, lenderId = null, borrowerId = null }) => {
  const classes = useStyles();
  const { palette } = useTheme();
  const { loanRoutesConfig } = useLoanRoutes();
  const { globalStore } = useStore();

  return (
    <>
      {loanVolumeStats &&
        <Paper className={classes.paper}>
          <Box p={4}>
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <Typography variant="h5">LOAN VOLUME</Typography>
              </Grid>
              <TableContainer className={classes.tableContainer}>
                <Table className={classes.table} aria-label="Loan Volume">
                  <TableRow>
                    <TableCell>
                      LTD Submitted
                    </TableCell>
                    <TableCell>
                      {loanVolumeStats.submittedAmount ? formatCurrency(loanVolumeStats.submittedAmount) : '-'}
                      {loanVolumeStats.submittedLoans ?
                        ` (${loanVolumeStats.submittedLoans} ${loanVolumeStats.submittedLoans === 1 ? 'loan' : 'loans'})`
                        : ''}
                    </TableCell>
                    <TableCell>
                      LTD Funded
                    </TableCell>
                    <TableCell>
                      {loanVolumeStats.fundedLoansAmount ? formatCurrency(loanVolumeStats.fundedLoansAmount) : '-'}
                      {loanVolumeStats.fundedLoans ?
                        ` (${loanVolumeStats.fundedLoans} ${loanVolumeStats.fundedLoans === 1 ? 'loan' : 'loans'})`
                        : ''}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      Last 12 Months Submitted
                    </TableCell>
                    <TableCell>
                      {loanVolumeStats.lastYearSubmittedAmount ? formatCurrency(loanVolumeStats.lastYearSubmittedAmount) : '-'}
                      {loanVolumeStats.lastYearSubmittedLoans ?
                        ` (${loanVolumeStats.lastYearSubmittedLoans} ${loanVolumeStats.lastYearSubmittedLoans === 1 ? 'loan' : 'loans'})`
                        : ''}
                    </TableCell>
                    <TableCell>
                      Last 12 Months Funded
                    </TableCell>
                    <TableCell>
                      {loanVolumeStats.lastYearFundedAmount ? formatCurrency(loanVolumeStats.lastYearFundedAmount) : '-'}
                      {loanVolumeStats.lastYearFundedLoans ?
                        ` (${loanVolumeStats.lastYearFundedLoans} ${loanVolumeStats.lastYearFundedLoans === 1 ? 'loan' : 'loans'})`
                        : ''}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      Last Submitted Loan
                    </TableCell>
                    <TableCell>
                      {loanVolumeStats.latestSubmittedLoanId ? < Link style={{ color: palette.primary.dark }} to={loanRoutesConfig.loans(loanVolumeStats.latestSubmittedLoanId).children.dashboard.url}>
                        <strong>{loanVolumeStats.latestSubmittedLoanId}</strong>
                      </Link> : ''}
                    </TableCell>
                    <TableCell>
                      Last Funded Loan
                    </TableCell>
                    <TableCell>
                      {loanVolumeStats.latestFundedLoanId ? < Link style={{ color: palette.primary.dark }} to={loanRoutesConfig.loans(loanVolumeStats.latestFundedLoanId).children.dashboard.url}>
                        <strong>{loanVolumeStats.latestFundedLoanId}</strong>
                      </Link> : ''}
                    </TableCell>
                  </TableRow>
                </Table>
              </TableContainer>
            </Grid>
          </Box>
        </Paper >
      }
      <Layout containerClass={classes.container}>
        <BackOfficeLoansByContact lenderId={lenderId} borrowerId={borrowerId} />
      </Layout>
    </>
  );
});