import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router';
import { observer } from 'mobx-react';
import { Paper, Button } from '@roc/ui';
import { useAppConfigurations } from '../../modules/portalConfiguration';
import { useUserStore } from '../../hooks/useUserStore';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(4),
  },
}));

interface GreetingProps {
  text: string;
  buttonLabel: string;
  buttonLink: string;
}

export const Greeting = observer((props: GreetingProps) => {
  const classes = useStyles();
  const { push } = useHistory();
  const { userStore } = useUserStore();
  const {
    dashboardImg,
  } = useAppConfigurations();

  const firstName = userStore.userInformation?.firstName ?? '';
  const lastName = userStore.userInformation?.lastName ?? '';

  return (
    <Paper className={classes.paper}>
      <Grid container spacing={4}>
        <Grid item xs={12} lg={4}>
          <Typography variant="h2" gutterBottom>
            Welcome back,
          </Typography>
          <Typography variant="h2" gutterBottom>
            {`${firstName} ${lastName}`}
          </Typography>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Box
            style={{ height: '100%' }}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            textAlign="center"
          >
            <Typography gutterBottom>{props.text}</Typography>
            <Box mt={2}>
              <Button
                variant="contained"
                color="primary"
                size="medium"
                onClick={() => push(props.buttonLink)}
                testId="goToLoans"
              >
                {props.buttonLabel}
              </Button>
            </Box>
          </Box>
        </Grid>
        {dashboardImg && (
          <Grid item xs={12} lg={4} style={{ display: 'flex' }}>
            <div
              style={{
                backgroundImage: `url(${dashboardImg})`,
                width: '277px',
                height: '184px',
                margin: 'auto',
              }}
            ></div>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
});
