import { makeObservable, observable, override, toJS } from 'mobx';
import { UserService } from '../../../../../../feature-app-core/src/services/userService';
import { BridgePricerService } from '../services/bridgePricerService';
import { BridgePricerFormValues } from '../types/bridgePricerTypes';
import { BridgePricerBaseStore } from './bridgePricerBaseStore';
import { LoaSubtypeOptions } from '../utils/bridgePricerConstants';

export class BridgePricerStore extends BridgePricerBaseStore {
  private bridgePricerService: BridgePricerService;
  private user: UserService;
  public allLenderOptions: any = [];
  public borrowerPointsOptions: any = [];

  constructor(globalStore) {
    super(globalStore);
    this.bridgePricerService = new BridgePricerService();
    this.isClientPortal = false;
    this.user = new UserService();
    this.chartData = [
      {
        'borrowerRate': '0',
        'yourYieldSpread': 0.0,
      }
    ];

    makeObservable(this, {
      getValues: override,
      allLenderOptions: observable,
      borrowerPointsOptions: observable,
    });
  }

  *getValues(data: BridgePricerFormValues) {
    const formValues = this.getFormValues();
    let extraPayload = {};
    if (formValues.loanSubtype === LoaSubtypeOptions.GROUNDUP && this.globalStore.lenderInfo?.isInternal) {
      extraPayload = {
        rateAdjustmentsRequestFields: {
          experience: formValues.experienceScore,
          fico: formValues.estimatedFicoScore,
          transactionType: formValues.loanPurpose,
          completedRenovations: formValues.completedCapitalImprovements,
          leverage: formValues.loanToPurchase,
          loanTerm: formValues.loanTermsMonths,
          totalRenoBudget: formValues.renoBudget,
        },
        internalLender: this.globalStore.lenderInfo?.isInternal
      }
    }
    const response = yield this.bridgePricerService.getValues({ ...data, ...extraPayload });
    return response.data;
  }

  async *getAllLenderIdNames() {
    const response = await this.bridgePricerService.getAllLenderIdNames();
    this.allLenderOptions = toJS(response.data);
  }

  *getRateFloor(data) {
    const formValues = this.getFormValues();
    let extraPayload = {};
    if (formValues.loanSubtype === LoaSubtypeOptions.GROUNDUP && this.globalStore.lenderInfo?.isInternal) {
      extraPayload = {
        rateAdjustmentsRequestFields: {
          experience: formValues.experienceScore,
          fico: formValues.estimatedFicoScore,
          transactionType: formValues.loanPurpose,
          completedRenovations: formValues.completedCapitalImprovements,
          leverage: formValues.loanToPurchase,
          loanTerm: formValues.loanTermsMonths,
          totalRenoBudget: formValues.renoBudget,
        },
        internalLender: this.globalStore.lenderInfo?.isInternal
      }
    }
    const response = yield this.bridgePricerService.getRateFloor({
      ...data,
      ...extraPayload,
    });

    if (!this.form.fields['borrowerRate'].value) {
      this.onFieldChange('borrowerRate', response.data);
    }
    return response;
  }

  async *getBorrowerPointsOptions(lenderId, loanTerm, submissionDate) {
    const response = yield this.bridgePricerService.getBorrowerPointsOptions(lenderId, loanTerm, submissionDate).then(r => r.data);
    this.borrowerPointsOptions = response;
    if (response && (response?.length || 0) > 0 &&
      (!this.form.fields['borrowerPoints'].value || this.form.fields['borrowerPoints'].value < Number(response[0].value))) {
      this.onFieldChange('borrowerPoints', Number(response[0].value));
    }
    return response;
  }

  *getLoanValues(loanId: string): BridgePricerFormValues {
    const response = yield this.bridgePricerService.getLoanValues(loanId);
    return response.data;
  }

  *getAutocompleteOptions(searchTerm: string) {
    const filters = { dealName: searchTerm };
    const response = yield this.bridgePricerService.getBridgeDealNames(
      1,
      25,
      null,
      null,
      filters
    );
    const rows = response.data.rows;
    return rows.map(row => ({
      label: row.dealName,
      value: row.dealid,
    }));
  }
}