import { Grid, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import React from 'react';
import { SelectBorrowerInput } from '../components/formComponents';
import { SelectBorrowerMode } from '@roc/feature-borrowers';

export const BorrowerForm = observer(({ submitAppraisalStore }) => {
  return (
    <Grid item container justifyContent="center" spacing={2}>
      <Grid item xs={12}>
        <Typography gutterBottom variant="h5">
          Borrower Information
        </Typography>
        <Typography>
          Please select your borrower from the list below. If you don't see your borrower in the system, you can add them from the Borrowers dashboard
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <SelectBorrowerInput
          store={submitAppraisalStore}
          modes={[SelectBorrowerMode.EXISTING]}
          borrowerCardDisplayFields={{
            firstName: true,
            lastName: true,
            cellPhone: true,
            emailAddress: true,
          }}
        />
      </Grid>
    </Grid>
  );
});
