import { makeStyles, lighten } from "@material-ui/core/styles";

export const getBorrowerPortalTabStyles = makeStyles(theme => ({
  tabRoot: {
    /*background: '#f7f7f7',*/
    background: lighten(theme.palette.primary.main, 0.95),
    borderTopRightRadius: 8,
    borderTopLeftRadius: 8,
    padding: '8px 8px 0 8px',
    '& .MuiTabs-indicator': {
      display: 'none'
    },
    '& .MuiTab-root': {
      margin: '0 1px',
      color: '#4b4b4b',
      /* fontWeight: 'normal', */
      background: '#f1f1f1',
      border: '1px solid #dbdbdb',
      borderTopRightRadius: 8,
      borderTopLeftRadius: 8,
      borderBottom: 0,
    },
    '& .MuiTab-root.Mui-selected': {
      color: theme.palette.primary.main,
      fontWeight: 'bold',
      background: '#fff',
      borderTopRightRadius: 8,
      borderTopLeftRadius: 8,
      borderColor: '#dbdbdb',
      borderBottom: 0,
    }
  },
}));
