export interface Task {
  taskDetails: TaskDetails;
  attributes: Attributes;
  assignees: Assignee[];
  comments?: GeneralTaskComment[];
  attachments?: GeneralTaskAttachment[];
  parentTask?: RelatedTaskInfo;
  subtasks?: RelatedTaskInfo[];
  observers?: number[];
  escalationDetails?: EscalationDetails;
}

interface AudiData {
  createdBy: string;
  createdDate: string;
  lastUpdatedBy: string;
  lastUpdatedDate: string;
}

export interface EscalationDetails extends AudiData {
  taskId: number;
  userId: number;
}

export interface Assignee extends AudiData {
  taskAssigneeId: number;
  taskId: number;
  assigneeId: number;
  assigneeFistName: string;
  assigneeLastName: string;
  assigneeStartDate: string;
  assigneeEndDate: string;
}

export interface Attributes extends AudiData {
  taskId: number;
  loanId: number;
  propertyAppraisalId: number;
  taskType: string;
  borrowerId: number;
  propertyId: number;
}

export interface TaskDetails extends AudiData {
  taskId: number;
  parentTaskId: number;
  title: string;
  description: string;
  status: TaskStatus;
  dueDate: string;
  priority: TaskPriority;
  ownerId: number;
  isSystemTask: boolean;
  ownerFirstName: string;
  ownerLastName: string;
}

export interface GeneralTaskComment {
  authorId: number;
  text: string;
  createdDate: string;
}
export interface GeneralTaskAttachment {
  taskAttachmentId: number;
  taskId: number;
  documentId: number;
  attachmentName: string;
}

export interface RelatedTaskInfo {
  taskId: number;
  title: string;
  status: string;
}

export enum TaskStatus {
  OPEN = 'OPEN',
  IN_PROGRESS = 'IN_PROGRESS',
  DONE = 'DONE',
  REJECTED = 'REJECTED',
  CLOSED = 'CLOSED',
}

export enum TaskPriority {
  HIGH = 'HIGH',
  MEDIUM = 'MEDIUM',
  LOW = 'LOW',
  CRITICAL = 'CRITICAL',
  VERY_CRITICAL = 'VERY_CRITICAL'
}

export interface TaskGridRow {
  lastUpdatedDate: string;
  description: string;
  assignees: string;
  ownerFirstName: null;
  title: string;
  ownerId: number;
  taskId: number;
  parentTaskId: number;
}

export enum GeneralTasksView {
  KANBAN = 'KANBAN',
  LIST = 'LIST',
}

export enum GeneralTasksTab {
  ALL_TASKS = 'ALL_TASKS',
  MY_TASKS = 'MY_TASKS',
}

export enum GeneralTasksFilters {
  STATUS = 'STATUS',
  PRIORITY = 'PRIORITY',
  DUE_DATE = 'DUE_DATE',
  TITLE = 'TITLE',
  ASSIGNEE = 'ASSIGNEE',
  LOAN_ID = 'LOAN_ID',
  OWNER = 'OWNER',
  CREATED_BY = 'CREATED_BY',
  OPEN_SUBTASKS = 'OPEN_SUBTASKS',
  TASK_TYPE = 'TASK_TYPE',
  TASK_SCOPE = 'TASK_SCOPE',
  TASK_ID = 'TASK_ID'
}

export enum GeneralTaskType {
  CREDIT_REVIEW = 'CREDIT_REVIEW',
  BACKGROUND_REVIEW = 'BACKGROUND_REVIEW',
  TRACK_RECORD_REVIEW = 'TRACK_RECORD_REVIEW',
  APPRAISAL_REPORT_AND_RELATED = 'APPRAISAL_REPORT_AND_RELATED',
  APPRAISAL_RISK_REVIEW = 'APPRAISAL_RISK_REVIEW',
  COLLATERAL_DOCS = 'COLLATERAL_DOCS',
  SOW_REVIEW = 'SOW_REVIEW',
  ENTITY_SECTION = 'ENTITY_SECTION',
  APPRAISAL_REVIEW = 'APPRAISAL_REVIEW',

  CLOSING_ASSISTANCE = 'CLOSING_ASSISTANCE',
  PAYOFF_REQUEST = 'PAYOFF_REQUEST',
  DRAW_REQUEST = 'DRAW_REQUEST',
  LOAN_EXTENSIONS = 'LOAN_EXTENSIONS',
  OTHERS = 'OTHERS',
  CLOSING_CONDITIONS = 'CLOSING_CONDITIONS',
  LOAN_CLARIFICATIONS = 'LOAN_CLARIFICATIONS',
  ADDITIONAL_REQUIREMENT = 'ADDITIONAL_REQUIREMENT',

  SUBJECT_PROPERTY = 'SUBJECT_PROPERTY',
  TRACK_RECORD = 'TRACK_RECORD',
  CRM_SALES_REMINDER = 'CRM_SALES_REMINDER',
  FEEDBACK = 'FEEDBACK',
  TECH_SUPPORT = 'TECH_SUPPORT',
  EXTERNAL_TECH_SUPPORT = 'EXTERNAL_TECH_SUPPORT',
  INSURANCE = 'INSURANCE',

  ESCALATION = 'ESCALATION',

  APPRAISAL_REPORT_REVIEW = 'APPRAISAL_REPORT_REVIEW',
  CMT_REVIEW = 'CMT_REVIEW',
  APPRAISAL_DOCUMENT_COLLECTION = 'APPRAISAL_DOCUMENT_COLLECTION',
  SERVICING = 'SERVICING'
}

export enum TaskScope {
  REPORTED_BY_ME = 'REPORTED_BY_ME',
  WATCHED_BY_ME = 'WATCHED_BY_ME',
  ASSIGNED_TO_ME = 'ASSIGNED_TO_ME'
}

export interface PopulateTaskFormField {
  title?: string,
  priority?: string,
  assigneeId?: number,
  taskType?: GeneralTaskType,
}
