import React from 'react';
import { useBaseStore } from '@roc/feature-app-core';

const HEADER_HEIGHT = 75;
const CRM_PORTAL_HEADER_HEIGHT = 100;
const SLIM_HEADER_HEIGHT = 48;

export const useHeaderHeight = () => {
  const { globalStore } = useBaseStore();
  const { showNavigation, slimNavigation, isBorrowerPortal, isCrmPortal } = globalStore;
  if (isBorrowerPortal) {
    return 0;
  }
  if (!showNavigation) {
    return 0;
  }
  return slimNavigation ? SLIM_HEADER_HEIGHT : isCrmPortal ? CRM_PORTAL_HEADER_HEIGHT : HEADER_HEIGHT;
};
