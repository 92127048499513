import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { ExpandMore, Person, Visibility } from '@material-ui/icons';
import { EditBorrowersForm, useLoanStore } from '@roc/feature-loans';
import { StandardDialog, SubmitDialog } from '@roc/ui';
import { formatPercentage } from '@roc/ui/utils';
import BorrowerEntityOverview from 'libs/feature-loans/src/loans/loanDetails/components/borrowerEntityOverview';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { useLoanFlags } from '../../hooks/useLoanFlags';
import { CustomCard, CustomCardDividers, CustomCardRow } from '../customCard';

const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
  borrowerText2: {
    fontSize: '0.875rem',
    color: theme.palette.text.secondary,
  },
  accordion: {
    boxShadow: 'none',
    '& .MuiAccordionDetails-root': {
      padding: 0,
    },
    '& .MuiAccordionSummary-root': {
      padding: 0,
    },
  },
}));

export const EntityCard = observer(() => {
  const { loanStore } = useLoanStore();
  const { loanDetails } = loanStore;
  const classes = useStyles();
  const [expanded, setExpanded] = useState(true);
  const [showEditBorrowersDialog, setShowEditBorrowersDialog] = useState(false);
  const [showMoreInformationDialog, setShowMoreInformationDialog] = useState(
    false
  );
  const { showEditBorrowers } = useLoanFlags();
  const entity = loanDetails?.borrowerEntity;
  return (
    <CustomCard
      paperStyle={{
        height: '100%',
      }}
      title="Entity"
      onEdit={showEditBorrowers ? () => setShowEditBorrowersDialog(true) : null}
    >
      <CustomCardDividers>
        <CustomCardRow label="Entity Name" value={entity.company?.name} />
        <CustomCardRow
          label="Entity EIN"
          value={entity.ein ?? entity?.company?.einNumber}
        />
        <CustomCardRow
          label="Entity Type"
          value={entity.type ?? entity?.company?.entityType}
        />
        <Accordion
          className={classes.accordion}
          expanded={expanded}
          onChange={(e, expanded) => setExpanded(expanded)}
        >
          <AccordionSummary expandIcon={<ExpandMore />} >
            <Box width="100%" display="flex" alignItems="center" justifyContent="space-between">
              <Typography variant="h5">Borrowers </Typography>
              <IconButton onClick={(e) =>  {
                setShowMoreInformationDialog(true)
                e.stopPropagation()
              }}>
                <Visibility />
              </IconButton>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              {loanDetails?.borrowers?.map?.(borrower => (
                <Grid item xs={12}>
                  <Borrower
                    name={`${borrower.firstName} ${borrower.lastName}`}
                    email={borrower.emailAddress}
                    ownership={borrower.pctOwnership}
                  />
                </Grid>
              ))}
            </Grid>
          </AccordionDetails>
        </Accordion>
      </CustomCardDividers>
      <MoreInformationDialog
        open={showMoreInformationDialog}
        onClose={() => setShowMoreInformationDialog(false)}
      />
      <EditBorrowersDialog
        open={showEditBorrowersDialog}
        onClose={() => setShowEditBorrowersDialog(false)}
      />
    </CustomCard>
  );
});

const Borrower = observer(({ name, email, ownership }) => {
  const classes = useStyles();
  return (
    <Box display="flex" alignItems="center">
      <Avatar className={classes.avatar}>
        <Person />
      </Avatar>
      <Box pl={2} flex={1}>
        <Typography>{name}</Typography>
        <Typography className={classes.borrowerText2}>{email}</Typography>
        <Typography className={classes.borrowerText2}>
          Ownership: {formatPercentage(ownership)}
        </Typography>
      </Box>
    </Box>
  );
});

const MoreInformationDialog = observer(({ open, onClose }) => {
  const classes = useStyles();
  const { loanStore } = useLoanStore();
  const { loanDetails } = loanStore;
  const { showEditBorrowers } = useLoanFlags();

  const dialogContent = (
    <BorrowerEntityOverview
      hideEditBorrowers={!showEditBorrowers}
      entity={loanStore.loanDetails.borrowerEntity}
      borrowers={loanStore.loanDetails.borrowers}
    />
  );

  const handleClose = () => {
    loanStore.selectBorrowersStore.reset();
    loanStore.setExistingBorrowers();
    onClose();
  };

  return (
    <StandardDialog
      title="Borrower Entity Overview"
      open={open}
      dialogContent={dialogContent}
      handleClose={handleClose}
      onClose={handleClose}
    />
  );
});
const EditBorrowersDialog = observer(({ open, onClose }) => {
  const classes = useStyles();
  const { loanStore } = useLoanStore();
  const { loanDetails } = loanStore;

  const dialogContent = <EditBorrowersForm store={loanStore} />;

  return (
    <SubmitDialog
      title="Edit Borrowers"
      open={open}
      body={dialogContent}
      handleClose={() => {
        loanStore.selectBorrowersStore.reset();
        loanStore.setExistingBorrowers();
        onClose();
      }}
      handleOk={() => {
        loanStore.saveBorrowers();
        onClose();
      }}
    />
  );
});
