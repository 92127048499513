import {
  FormHelperText,
  Grid,
  GridProps,
  GridSize,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import {
  Bankruptcy,
  BankruptcyDate,
  ExperienceLevel,
  Foreclosure,
  ForeclosureDate,
  QualifyingFicoScore,
} from '@roc/ui/formComponents';
import {
  RentalPortfolioStore,
  SinglePropertyStore,
} from '@roc/feature-loans';
import {
  isNotBlank,
  loanTermRateTermMapping,
  MIXED_USE,
  MULTIFAMILY_5_PLUS,
  notLicensedSfr,
  PPP_5_4_3_2_1,
  propertyStates,
  sfrLoanTermTypes,
  sfrPortfolioPropertyManagement,
} from '@roc/feature-utils';
import { propertyTypesByLoanSubtype } from '../../loanSubmission/utils/propertyTypes';
import {
  CurrencyField,
  MultiSelectField,
  NumberFormat,
  RadioField,
  SelectField,
  Slider,
  TextField,
  yesNoOptions,
} from '@roc/ui';
import { observe } from 'mobx';
import { observer } from 'mobx-react';
import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import {
  AddressNoLicensedText,
  AnnualExpenses,
  AnnualIncome,
  AnnualNoi,
  CapitalImprovements,
  CashOut,
  CurrentDebtAmount,
  CurrentDebtOnProperty,
  LoanPurpose,
  PurchaseDate,
  PurchasePrice,
  TargetLtv,
} from '@roc/feature-loans';
import {
  amortizationOptions,
  amortizationTypeTooltip,
  citizenshipStatusOptionsRental,
} from '../utils/constants';
import { GridContainer } from './rentalPricerFieldsContext';
import { PropertyLeased, useRentalPortfolioStore } from '../../loanSubmission';
import { StabilizedBridgeStore } from '../../loanSubmission/stores/stabilizedBridge/stabilizedBridgeStore';
import { LoanSubType } from '@roc/client-portal-shared/utils';
import { Check } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  slider: {
    marginTop: '2.5em',
    marginLeft: '2em',
    width: '85%',
  },
}));


interface PricerFieldProps {
  store: SinglePropertyStore | RentalPortfolioStore | StabilizedBridgeStore;
}

/*
 * Property Information - Portfolio
 */
export const PropertyCounties = observer(() => {
  const { rentalPortfolioStore } = useRentalPortfolioStore();
  const { pricerStore } = rentalPortfolioStore;
  const { form, counties } = pricerStore;
  const disabled = !form.fields.propertyStates?.value?.length;

  const error = form.fields.propertyCounties.error;

  return (
    <GridContainer>
      <MultiSelectField
        standaloneLabel
        label={'Property Counties (Select All that apply)'}
        name={'propertyCounties'}
        options={counties}
        disabled={disabled}
        getOptionSelected={(option, selectedValue) => {
          return option.value == selectedValue.value;
        }}
        value={
          form.fields.propertyCounties.value
            ? form.fields.propertyCounties.value
            : []
        }
        onChange={(e, value) => {
          pricerStore.onFieldChange('propertyCounties', value);
        }}
        renderInput={params => (
          <TextField
            helperText={error}
            error={isNotBlank(error)}
            testId="propertyCounties"
            {...params}
            variant="outlined"
          />
        )}
        fullWidth
        required
        testId="propertyCounties"
      />
    </GridContainer>
  );
});

export const PropertyState = observer(() => {
  const { rentalPortfolioStore } = useRentalPortfolioStore();
  const { pricerStore } = rentalPortfolioStore;
  const { form } = pricerStore;

  const onPropertyStateChanged = value => {
    pricerStore.onFieldChange('propertyStates', value);
  };

  return (
    <GridContainer>
      <SelectField
        standaloneLabel
        label={'Property State'}
        name={'propertyStates'}
        value={form.fields.propertyStates.value}
        error={isNotBlank(form.fields.propertyStates.error)}
        errorText={form.fields.propertyStates.error}
        options={propertyStates}
        onChange={value => onPropertyStateChanged(value)}
        variant="outlined"
        fullWidth
        shrink
        required
        testId="propertyStates"
      />
      <FormHelperText>{notLicensedSfr.message}</FormHelperText>
    </GridContainer>
  );
});

export const MinimumAsIsValue = observer(() => {
  const { rentalPortfolioStore } = useRentalPortfolioStore();
  const { pricerStore } = rentalPortfolioStore;
  const { form } = pricerStore;
  const store = rentalPortfolioStore;

  const hasMixedUseProperties = false;

  return (
    <GridContainer>
      <CurrencyField
        variant="outlined"
        standaloneLabel
        label="Lowest Property As Is Value in the Portfolio"
        value={form.fields.minimumAsIsValue.value}
        onChange={(name, value) =>
          pricerStore.onFieldChange('minimumAsIsValue', Number(value))
        }
        fullWidth
        testId="minimumAsIsValue"
        required
        error={isNotBlank(form.fields.minimumAsIsValue.error)}
        errorText={form.fields.minimumAsIsValue.error}
      />
    </GridContainer>
  );
});

export const MaximumAsIsValue = observer(() => {
  const { rentalPortfolioStore } = useRentalPortfolioStore();
  const { pricerStore } = rentalPortfolioStore;
  const { form } = pricerStore;
  const store = rentalPortfolioStore;

  const hasMixedUseProperties = false;

  return (
    <GridContainer>
      <CurrencyField
        variant="outlined"
        standaloneLabel
        label="Highest Property As Is Value in the Portfolio"
        value={form.fields.maximumAsIsValue.value}
        onChange={(name, value) =>
          pricerStore.onFieldChange('maximumAsIsValue', Number(value))
        }
        fullWidth
        testId="maximumAsIsValue"
        required
        error={isNotBlank(form.fields.maximumAsIsValue.error)}
        errorText={form.fields.maximumAsIsValue.error}
      />
    </GridContainer>
  );
});

export const TotalEstimatedAsIsValue = observer(() => {
  const { rentalPortfolioStore } = useRentalPortfolioStore();
  const { pricerStore } = rentalPortfolioStore;
  const { form } = pricerStore;
  const store = rentalPortfolioStore;

  const hasMixedUseProperties = false;

  return (
    <GridContainer>
      <CurrencyField
        variant="outlined"
        standaloneLabel
        label="Total Estimated As Is Value"
        value={form.fields.totalEstimatedAsIsValue.value}
        onChange={(name, value) =>
          pricerStore.onFieldChange('totalEstimatedAsIsValue', Number(value))
        }
        fullWidth
        testId="totalEstimatedAsIsValue"
        required
        error={isNotBlank(form.fields.totalEstimatedAsIsValue.error)}
        errorText={form.fields.totalEstimatedAsIsValue.error}
      />
    </GridContainer>
  );
});

export const PropertyManagement = observer(() => {
  const { rentalPortfolioStore } = useRentalPortfolioStore();
  const { pricerStore } = rentalPortfolioStore;
  const { form } = pricerStore;
  const store = rentalPortfolioStore;

  const hasMixedUseProperties = false;

  return (
    <GridContainer>
      <SelectField
        standaloneLabel
        label={'Property Management'}
        name={'propertyManagement'}
        value={form.fields.propertyManagement.value}
        errorText={form.fields.propertyManagement.error}
        error={isNotBlank(form.fields.propertyManagement.error)}
        options={sfrPortfolioPropertyManagement}
        onChange={value =>
          pricerStore.onFieldChange('propertyManagement', value)
        }
        variant="outlined"
        fullWidth
        shrink
        required
        testId="propertyManagement"
      />
    </GridContainer>
  );
});

export const TotalMixUseEstimatedAsIsValue = observer(() => {
  const { rentalPortfolioStore } = useRentalPortfolioStore();
  const { pricerStore } = rentalPortfolioStore;
  const { form } = pricerStore;
  const store = rentalPortfolioStore;

  const hasMixedUseProperties = false;

  return (
    <GridContainer>
      <CurrencyField
        variant="outlined"
        standaloneLabel
        label="Total Value of Mixed Use Properties in Portfolio"
        value={form.fields.totalMixUseEstimatedAsIsValue.value}
        onChange={(name, value) =>
          pricerStore.onFieldChange(
            'totalMixUseEstimatedAsIsValue',
            Number(value)
          )
        }
        fullWidth
        testId="totalMixUseEstimatedAsIsValue"
        required
        error={isNotBlank(form.fields.totalMixUseEstimatedAsIsValue.error)}
        errorText={form.fields.totalMixUseEstimatedAsIsValue.error}
      />
    </GridContainer>
  );
});

export const PortfolioCashFlowInformation = observer(() => {
  const { rentalPortfolioStore } = useRentalPortfolioStore();
  const store = rentalPortfolioStore;
  const { pricerStore, getPropertyTypes } = rentalPortfolioStore;
  const { form } = pricerStore;
  const hasMultiFamily = getPropertyTypes().includes(MULTIFAMILY_5_PLUS);

  return (
    <>
      <GridContainer>
        <CurrencyField
          variant="outlined"
          standaloneLabel
          label="Gross Monthly Rent"
          value={form.fields.totalGrossMonthlyRent.value}
          onChange={(name, value) => {
            pricerStore.handleIncomeChange(
              'totalGrossMonthlyRent',
              Number(value)
            );
          }}
          fullWidth
          testId="totalGrossMonthlyRent"
          required
          error={isNotBlank(form.fields.totalGrossMonthlyRent.error)}
          errorText={form.fields.totalGrossMonthlyRent.error}
        />
      </GridContainer>
      <GridContainer>
        <CurrencyField
          variant="outlined"
          standaloneLabel
          label="Total Gross Annual Taxes"
          value={form.fields.totalGrossAnnualTaxes.value}
          onChange={(name, value) =>
            pricerStore.handleExpenseChange(
              'totalGrossAnnualTaxes',
              Number(value)
            )
          }
          fullWidth
          testId="totalGrossAnnualTaxes"
          required
          error={isNotBlank(form.fields.totalGrossAnnualTaxes.error)}
          errorText={form.fields.totalGrossAnnualTaxes.error}
        />
      </GridContainer>
      <GridContainer>
        <CurrencyField
          variant="outlined"
          standaloneLabel
          label="Total Gross Annual Insurance"
          value={form.fields.totalGrossAnnualInsurance.value}
          onChange={(name, value) =>
            pricerStore.handleExpenseChange(
              'totalGrossAnnualInsurance',
              Number(value)
            )
          }
          fullWidth
          testId="totalGrossAnnualInsurance"
          required
          error={isNotBlank(form.fields.totalGrossAnnualInsurance.error)}
          errorText={form.fields.totalGrossAnnualInsurance.error}
        />
      </GridContainer>
      <GridContainer>
        <CurrencyField
          variant="outlined"
          standaloneLabel
          label="Total Annual HOA Dues"
          value={form.fields.totalAnnualHOADues.value}
          onChange={(name, value) =>
            pricerStore.handleExpenseChange('totalAnnualHOADues', Number(value))
          }
          fullWidth
          testId="totalAnnualHOADues"
          required
          error={isNotBlank(form.fields.totalAnnualHOADues.error)}
          errorText={form.fields.totalAnnualHOADues.error}
        />
      </GridContainer>
      <AnnualUtilityExpenses store={store} />
      <AnnualRepairMaintenanceExpenses store={store} />
      <AnnualPropertyManagementFees store={store} />
      {hasMultiFamily && (
        <>
          <AnnualAdministrativeExpense store={store} />
          <AnnualPayrollExpense store={store} />
          <AnnualMarketingExpense store={store} />
          <AnnualReplacementReserve store={store} />
        </>
      )}
    </>
  );
});

/*
 * Loan Pricer Inputs
 */
export const BorrowerInformationFields = observer((props: PricerFieldProps) => {
  const { store } = props;
  const { pricerStore } = store;
  const { form } = pricerStore;
  return (
    <>
      <GridContainer>
        <ExperienceLevel
          store={store.pricerStore}
          tooltip={'Number of Rental Properties Owned in the Last 24 Months'}
          onChange={() => store.handleBorrowerInformationChange()}
        />
      </GridContainer>
      <GridContainer>
        <CitizenshipStatus store={store} />
      </GridContainer>
      <GridContainer>
        <QualifyingFicoScore
          store={store.pricerStore}
          tooltip={
            'If there are multiple guarantors, please select the lowest mid-score'
          }
          onChange={() => store.handleBorrowerInformationChange()}
        />
      </GridContainer>
      <Grid item xs={12} />
      <GridContainer>
        <Bankruptcy
          store={store.pricerStore}
          onChange={() => store.handleBorrowerInformationChange()}
        />
      </GridContainer>
      {form.fields.bankruptcy.value === 'Y' && (
        <GridContainer>
          <BankruptcyDate
            store={store.pricerStore}
            onChange={() => store.handleBorrowerInformationChange()}
          />
        </GridContainer>
      )}
      <GridContainer>
        <Foreclosure store={store.pricerStore} />
      </GridContainer>
      {form.fields.foreclosure.value === 'Y' && (
        <GridContainer>
          <ForeclosureDate
            store={store.pricerStore}
            onChange={() => store.handleBorrowerInformationChange()}
          />
        </GridContainer>
      )}
    </>
  );
});

export const CitizenshipStatus = observer(({ store }: PricerFieldProps) => {
  const form = store.pricerStore.form;

  return (
    <SelectField
      standaloneLabel
      label={"Borrower's Citizenship Status"}
      name={'citizenshipStatus'}
      value={form.fields.citizenshipStatus.value}
      options={citizenshipStatusOptionsRental}
      onChange={value =>
        store.pricerStore.onFieldChange('citizenshipStatus', value)
      }
      error={isNotBlank(form.fields.citizenshipStatus.error)}
      errorText={form.fields.citizenshipStatus.error}
      variant="outlined"
      fullWidth
      testId="citizenshipStatus"
    />
  );
});

export const PropertyAddress = observer(({ store }: PricerFieldProps) => {
  return (
    <GridContainer>
      <AddressNoLicensedText store={store.pricerStore} />
    </GridContainer>
  );
});

export const PricerLoanPurpose = observer(({ store }: PricerFieldProps) => {
  return (
    <GridContainer>
      <LoanPurpose store={store.pricerStore} />
    </GridContainer>
  );
});

export const PricerPurchasePrice = observer(({ store }: PricerFieldProps) => {
  return (
    <GridContainer>
      <PurchasePrice store={store.pricerStore} />
    </GridContainer>
  );
});

export const PricerPropertyLeased = observer(({ store }: PricerFieldProps) => {
  return (
    <GridContainer>
      <PropertyLeased store={store.pricerStore} />
    </GridContainer>
  );
});

export const PricerPurchaseDate = observer(({ store }: PricerFieldProps) => {
  return (
    <GridContainer>
      <PurchaseDate store={store.pricerStore} />
    </GridContainer>
  );
});

export const PricerCapitalImprovements = observer(
  ({ store }: PricerFieldProps) => {
    return (
      <GridContainer>
        <CapitalImprovements store={store.pricerStore} />
      </GridContainer>
    );
  }
);

export const PricerCurrentDebtOnProperty = observer(
  ({ store }: PricerFieldProps) => {
    return (
      <GridContainer>
        <CurrentDebtOnProperty store={store.pricerStore} />
      </GridContainer>
    );
  }
);

export const PricerCurrentDebtAmount = observer(
  ({ store }: PricerFieldProps) => {
    return (
      <GridContainer>
        <CurrentDebtAmount store={store.pricerStore} />
      </GridContainer>
    );
  }
);

export const PricerAnnualIncome = observer(({ store }: PricerFieldProps) => {
  return <AnnualIncome store={store} />;
});

export const PricerAnnualExpenses = observer(({ store }: PricerFieldProps) => {
  return <AnnualExpenses store={store} />;
});

export const PricerAnnualNoi = observer(({ store }: PricerFieldProps) => {
  return <AnnualNoi store={store} />;
});

export const PricerTargetLtv = observer(({ store }: PricerFieldProps) => {
  return (
    <GridContainer>
      <TargetLtv store={store.pricerStore} />
    </GridContainer>
  );
});

export const LeasesRental = observer(({ store }: PricerFieldProps) => {
  return (
    <GridContainer>
      <Leases store={store.pricerStore} />
    </GridContainer>
  );
});

export const CommercialUnitsCurrentOnRent = observer(({ store }) => {
  const { form } = store.pricerStore;
  return (
    <GridContainer>
      <RadioField
        key={form.fields.commercialUnitsCurrentOnRent.value}
        standaloneLabel
        label="Are commercial units current on rent for the most recent 3 months?"
        value={form.fields.commercialUnitsCurrentOnRent.value}
        name={'commercialUnitsCurrentOnRent'}
        options={yesNoOptions}
        onChange={value =>
          store.pricerStore.onFieldChange('commercialUnitsCurrentOnRent', value)
        }
        fullWidth
        required
        error={isNotBlank(form.fields.commercialUnitsCurrentOnRent.error)}
        errorText={form.fields.commercialUnitsCurrentOnRent.error}
        row
        testId="commercialUnitsCurrentOnRent"
      />
    </GridContainer>
  );
});

export const Units = observer(({ store }) => {
  const { form } = store.pricerStore;
  const disabled =
    form.fields.propertyType.value &&
    form.fields.propertyType.value !== MULTIFAMILY_5_PLUS;
  return (
    <GridContainer>
      <NumberFormat
        customInput={TextField}
        required={true}
        variant="outlined"
        label="Number of units"
        testId="numberOfUnits"
        value={form.fields.units.value}
        onChange={e => store.pricerStore.onFieldChange('units', e.target.value)}
        helperText={form.fields.units.error}
        error={isNotBlank(form.fields.units.error)}
        standaloneLabel
        fullWidth
        disabled={disabled}
      />
    </GridContainer>
  );
});

export const AsIsValue = observer(({ store }) => {
  const { form } = store.pricerStore;
  return (
    <GridContainer>
      <CurrencyField
        variant="outlined"
        standaloneLabel
        label="Estimated As Is Value"
        value={form.fields.propertyValuation.value}
        onChange={(name, value) =>
          store.pricerStore.onFieldChange('propertyValuation', Number(value))
        }
        fullWidth
        testId="propertyValuation"
        required
        error={isNotBlank(form.fields.propertyValuation.error)}
        errorText={form.fields.propertyValuation.error}
      />
    </GridContainer>
  );
});

export const GrossMonthlyRent = observer(({ store }) => {
  const { form } = store.pricerStore;
  return (
    <GridContainer>
      <CurrencyField
        variant="outlined"
        standaloneLabel
        label="Gross Monthly Rent"
        value={form.fields.monthlyGrossRent.value}
        onChange={(name, value) =>
          store.pricerStore.handleIncomeChange(
            'monthlyGrossRent',
            Number(value)
          )
        }
        fullWidth
        testId="monthlyGrossRent"
        required
        error={isNotBlank(form.fields.monthlyGrossRent.error)}
        errorText={form.fields.monthlyGrossRent.error}
      />
    </GridContainer>
  );
});

export const MonthlyMarketRent = observer(({ store }) => {
  const { form } = store.pricerStore;
  return (
    <GridContainer>
      <CurrencyField
        variant="outlined"
        standaloneLabel
        label="Monthly Market Rent"
        value={form.fields.monthlyMarketRent.value}
        onChange={(name, value) =>
          store.pricerStore.handleIncomeChange(
            'monthlyMarketRent',
            Number(value)
          )
        }
        fullWidth
        testId="monthlyMarketRent"
        required
        error={isNotBlank(form.fields.monthlyMarketRent.error)}
        errorText={form.fields.monthlyMarketRent.error}
      />
    </GridContainer>
  );
});

export const AnnualTaxes = observer(({ store }) => {
  const { form } = store.pricerStore;
  return (
    <GridContainer>
      <CurrencyField
        variant="outlined"
        standaloneLabel
        label="Annual Taxes"
        value={form.fields.annualTaxes.value}
        onChange={(name, value) =>
          store.pricerStore.handleExpenseChange('annualTaxes', Number(value))
        }
        fullWidth
        testId="annualTaxes"
        required
        error={isNotBlank(form.fields.annualTaxes.error)}
        errorText={form.fields.annualTaxes.error}
      />
    </GridContainer>
  );
});

export const AnnualInsurance = observer(({ store }) => {
  const { form } = store.pricerStore;
  return (
    <GridContainer>
      <CurrencyField
        variant="outlined"
        standaloneLabel
        label="Annual Insurance"
        value={form.fields.annualInsurance.value}
        onChange={(name, value) =>
          store.pricerStore.handleExpenseChange(
            'annualInsurance',
            Number(value)
          )
        }
        fullWidth
        testId="annualInsurance"
        required
        error={isNotBlank(form.fields.annualInsurance.error)}
        errorText={form.fields.annualInsurance.error}
      />
    </GridContainer>
  );
});

export const AnnualHoa = observer(({ store }) => {
  const { form } = store.pricerStore;
  return (
    <GridContainer>
      <CurrencyField
        variant="outlined"
        standaloneLabel
        label="Annual HOA Dues"
        value={form.fields.annualHOA.value}
        onChange={(name, value) =>
          store.pricerStore.handleExpenseChange('annualHOA', Number(value))
        }
        fullWidth
        testId="annualHOA"
        required
        error={isNotBlank(form.fields.annualHOA.error)}
        errorText={form.fields.annualHOA.error}
      />
    </GridContainer>
  );
});

export const AnnualUtilityExpenses = observer(({ store }) => {
  const { form } = store.pricerStore;
  return (
    <GridContainer>
      <CurrencyField
        variant="outlined"
        standaloneLabel
        label="Annual Utility Expenses"
        value={form.fields.totalAnnualUtilities.value}
        onChange={(name, value) =>
          store.pricerStore.handleExpenseChange(
            'totalAnnualUtilities',
            Number(value)
          )
        }
        fullWidth
        testId="totalAnnualUtilities"
        required
        error={isNotBlank(form.fields.totalAnnualUtilities.error)}
        errorText={form.fields.totalAnnualUtilities.error}
      />
    </GridContainer>
  );
});

export const AnnualRepairMaintenanceExpenses = observer(({ store }) => {
  const { form } = store.pricerStore;
  return (
    <GridContainer>
      <CurrencyField
        variant="outlined"
        standaloneLabel
        label="Annual Repair/Maintenance Expenses"
        value={form.fields.totalAnnualRepairsMaintenance.value}
        onChange={(name, value) =>
          store.pricerStore.handleExpenseChange(
            'totalAnnualRepairsMaintenance',
            Number(value)
          )
        }
        fullWidth
        testId="totalAnnualRepairsMaintenance"
        required
        error={isNotBlank(form.fields.totalAnnualRepairsMaintenance.error)}
        errorText={form.fields.totalAnnualRepairsMaintenance.error}
      />
    </GridContainer>
  );
});

export const AnnualAdministrativeExpense = observer(({ store }) => {
  const { form } = store.pricerStore;
  return (
    <GridContainer>
      <CurrencyField
        variant="outlined"
        standaloneLabel
        label="Annual Administrative Expense"
        value={form.fields.generalAdministrative.value}
        onChange={(name, value) =>
          store.pricerStore.handleExpenseChange(
            'generalAdministrative',
            Number(value)
          )
        }
        fullWidth
        testId="generalAdministrative"
        required
        error={isNotBlank(form.fields.generalAdministrative.error)}
        errorText={form.fields.generalAdministrative.error}
      />
    </GridContainer>
  );
});

export const AnnualPayrollExpense = observer(({ store }) => {
  const { form } = store.pricerStore;
  return (
    <GridContainer>
      <CurrencyField
        variant="outlined"
        standaloneLabel
        label="Annual Payroll Expense"
        value={form.fields.payroll.value}
        onChange={(name, value) =>
          store.pricerStore.handleExpenseChange('payroll', Number(value))
        }
        fullWidth
        testId="payroll"
        required
        error={isNotBlank(form.fields.payroll.error)}
        errorText={form.fields.payroll.error}
      />
    </GridContainer>
  );
});

export const AnnualMarketingExpense = observer(({ store }) => {
  const { form } = store.pricerStore;
  return (
    <GridContainer>
      <CurrencyField
        variant="outlined"
        standaloneLabel
        label="Annual Marketing Expense"
        value={form.fields.marketing.value}
        onChange={(name, value) =>
          store.pricerStore.handleExpenseChange('marketing', Number(value))
        }
        fullWidth
        testId="marketing"
        required
        error={isNotBlank(form.fields.marketing.error)}
        errorText={form.fields.marketing.error}
      />
    </GridContainer>
  );
});

export const AnnualReplacementReserve = observer(({ store }) => {
  const { form } = store.pricerStore;
  return (
    <GridContainer>
      <CurrencyField
        variant="outlined"
        standaloneLabel
        label="Annual Replacement Reserve"
        value={form.fields.replacementReserves.value}
        onChange={(name, value) =>
          store.pricerStore.handleExpenseChange(
            'replacementReserves',
            Number(value)
          )
        }
        fullWidth
        testId="replacementReserves"
        required
        error={isNotBlank(form.fields.replacementReserves.error)}
        errorText={form.fields.replacementReserves.error}
      />
    </GridContainer>
  );
});

export const ResidentialUnits = observer(({ store }) => {
  const { form } = store.pricerStore;
  return (
    <GridContainer>
      <NumberFormat
        customInput={TextField}
        required={true}
        variant="outlined"
        label="Residential Units"
        testId="residentialUnits"
        value={form.fields.residentialUnits.value}
        onChange={e =>
          store.pricerStore.onFieldChange('residentialUnits', e.target.value)
        }
        helperText={form.fields.residentialUnits.error}
        error={isNotBlank(form.fields.residentialUnits.error)}
        standaloneLabel
        fullWidth
      />
    </GridContainer>
  );
});

export const CommercialUnits = observer(({ store }) => {
  const { form } = store.pricerStore;
  return (
    <GridContainer>
      <NumberFormat
        customInput={TextField}
        required={true}
        variant="outlined"
        label="Commercial Units"
        testId="commercialUnits"
        value={form.fields.commercialUnits.value}
        onChange={e =>
          store.pricerStore.onFieldChange('commercialUnits', e.target.value)
        }
        helperText={form.fields.commercialUnits.error}
        error={isNotBlank(form.fields.commercialUnits.error)}
        standaloneLabel
        fullWidth
      />
    </GridContainer>
  );
});

export const ResidentialUnitsArea = observer(({ store }) => {
  const { form } = store.pricerStore;
  return (
    <GridContainer>
      <NumberFormat
        customInput={TextField}
        required={true}
        variant="outlined"
        label="Residential Units Area"
        testId="residentialUnitsSqFtArea"
        value={form.fields.residentialUnitsSqFtArea.value}
        onChange={e =>
          store.pricerStore.onFieldChange(
            'residentialUnitsSqFtArea',
            e.target.value
          )
        }
        helperText={form.fields.residentialUnitsSqFtArea.error}
        error={isNotBlank(form.fields.residentialUnitsSqFtArea.error)}
        standaloneLabel
        fullWidth
      />
    </GridContainer>
  );
});

export const CommercialUnitsArea = observer(({ store }) => {
  const { form } = store.pricerStore;
  return (
    <GridContainer>
      <NumberFormat
        customInput={TextField}
        required={true}
        variant="outlined"
        label="Commercial Units Area"
        testId="commercialUnitsSqFtArea"
        value={form.fields.commercialUnitsSqFtArea.value}
        onChange={e =>
          store.pricerStore.onFieldChange(
            'commercialUnitsSqFtArea',
            e.target.value
          )
        }
        helperText={form.fields.commercialUnitsSqFtArea.error}
        error={isNotBlank(form.fields.commercialUnitsSqFtArea.error)}
        standaloneLabel
        fullWidth
      />
    </GridContainer>
  );
});

export const ResidentialIncome = observer(({ store }) => {
  const { form } = store.pricerStore;
  return (
    <GridContainer>
      <NumberFormat
        customInput={TextField}
        required={true}
        variant="outlined"
        label="Residential Income"
        testId="residentialIncome"
        value={form.fields.residentialIncome.value}
        onChange={e =>
          store.pricerStore.onFieldChange(
            'residentialIncome',
            e.target.value
          )
        }
        helperText={form.fields.residentialIncome.error}
        error={isNotBlank(form.fields.residentialIncome.error)}
        standaloneLabel
        fullWidth
      />
    </GridContainer>
  );
});

export const CommercialIncome = observer(({ store }) => {
  const { form } = store.pricerStore;
  return (
    <GridContainer>
      <NumberFormat
        customInput={TextField}
        required={true}
        variant="outlined"
        label="Commercial Income"
        testId="commercialIncome"
        value={form.fields.commercialIncome.value}
        onChange={e =>
          store.pricerStore.onFieldChange(
            'commercialIncome',
            e.target.value
          )
        }
        helperText={form.fields.commercialIncome.error}
        error={isNotBlank(form.fields.commercialIncome.error)}
        standaloneLabel
        fullWidth
      />
    </GridContainer>
  );
});

export const AnnualPropertyManagementFees = observer(({ store }) => {
  const { form } = store.pricerStore;
  return (
    <GridContainer>
      <CurrencyField
        variant="outlined"
        standaloneLabel
        label="Annual Property Management Fees"
        value={form.fields.totalAnnualPropertyManagementFees.value}
        onChange={(name, value) =>
          store.pricerStore.handleExpenseChange(
            'totalAnnualPropertyManagementFees',
            Number(value)
          )
        }
        fullWidth
        testId="totalAnnualPropertyManagementFees"
        required
        error={isNotBlank(form.fields.totalAnnualPropertyManagementFees.error)}
        errorText={form.fields.totalAnnualPropertyManagementFees.error}
      />
    </GridContainer>
  );
});

export const PropertyType = observer(({ store }) => {
  const { form } = store.pricerStore;

  const handlePropertyTypeChange = (name, value) => {
    store.pricerStore.onFieldChange(name, value);
    if (value) {
      store.pricerStore.handlePropertyTypeChange(value);
    }
  };

  return (
    <GridContainer>
      <SelectField
        standaloneLabel
        label={'Select the Property Type'}
        name={'propertyType'}
        value={form.fields.propertyType.value}
        errorText={form.fields.propertyType.error}
        error={isNotBlank(form.fields.propertyType.error)}
        options={propertyTypesByLoanSubtype(store.loanSubtype)}
        onChange={value => handlePropertyTypeChange('propertyType', value)}
        variant="outlined"
        fullWidth
        shrink
        required
        testId="propertyType"
      />
    </GridContainer>
  );
});

export const County = observer(({ store }) => {
  const { form, counties } = store.pricerStore;

  return (
    <GridContainer>
      <SelectField
        standaloneLabel
        label={'Select Property County'}
        name={'propertyCounty'}
        value={form.fields.propertyCounty.value}
        errorText={form.fields.propertyCounty.error}
        error={isNotBlank(form.fields.propertyCounty.error)}
        options={counties ?? []}
        onChange={value =>
          store.pricerStore.onFieldChange('propertyCounty', value)
        }
        variant="outlined"
        fullWidth
        shrink
        required
        testId="propertyCounty"
      />
    </GridContainer>
  );
});

/*
 * Loan Pricer Summary
 */
export const LoanTermFields = observer(({ store }: PricerFieldProps) => {
  const { pricerStore, pricerSummaryStore } = store;
  const { isSingleProperty } = pricerStore;
  const { overRideRate, showRateNotFoundErrorMsg } = pricerSummaryStore;
  const { maxYsp } = pricerSummaryStore.pricerLoanTerms;
  const { anyDebt, totalDebtPayoff } = pricerStore.form.fields;
  const isLoanRatesAvailable = !showRateNotFoundErrorMsg && !overRideRate;

  const handleChangeCashOut = () => {
    pricerSummaryStore.onFieldChange('oneTimeYieldSpreadPremium', 0);
    pricerSummaryStore.fetchRatesAndLoanTerms();
  };

  return (
    <>
      {anyDebt.value === yesNoOptions[0].value && totalDebtPayoff.value >= 0 && (
        <Grid item xs={12} md={6}>
          <CashOut store={pricerSummaryStore} onChange={handleChangeCashOut} />
        </Grid>
      )}
      {!isSingleProperty && <LoanTerm store={store} />}
      <AmortizationType store={store} />
      <PrepaymentPenalty store={store} />
      {isLoanRatesAvailable && (
        <>
          <RateType store={store} />
          <LoanToValueSlider store={store} />
        </>
      )}
    </>
  );
});

const AmortizationType = observer(({ store }: PricerFieldProps) => {
  const { pricerStore } = store;
  const { pricerSummaryStore } = store;
  const { form } = pricerSummaryStore;

  const handleOnChangeAmortization = value => {
    pricerSummaryStore.onFieldChange('amortization', value);
    pricerSummaryStore.onFieldChange('oneTimeYieldSpreadPremium', 0);
    pricerSummaryStore.setMaxYsp(value);
    pricerSummaryStore.updateRatesTable();
  };

  return (
    <GridContainer>
      <SelectField
        disabled={false}
        standaloneLabel
        label={'Amortization Type'}
        name={'amortization'}
        tooltip={amortizationTypeTooltip}
        value={form.fields.amortization.value}
        options={amortizationOptions}
        onChange={value => handleOnChangeAmortization(value)}
        variant="outlined"
        fullWidth
        testId="amortization"
      />
    </GridContainer>
  );
});

const PrepaymentPenalty = observer(({ store }: PricerFieldProps) => {
  const { pricerSummaryStore } = store;
  const { form } = pricerSummaryStore;

  const handleChangePrePaymentPenalty = value => {
    pricerSummaryStore.onFieldChange('prePaymentPenalty', value);
    pricerSummaryStore.onFieldChange('oneTimeYieldSpreadPremium', 0);
    pricerSummaryStore.fetchRatesAndLoanTerms();
  };

  return (
    <GridContainer>
      <SelectField
        disabled={false}
        standaloneLabel
        label={'Pre-Payment Penalty'}
        name={'prePaymentPenalty'}
        value={form.fields.prePaymentPenalty.value}
        options={pricerSummaryStore.prepaymentPenaltyOptions}
        onChange={value => handleChangePrePaymentPenalty(value)}
        variant="outlined"
        fullWidth
        testId="prePaymentPenalty"
      />
    </GridContainer>
  );
});

const LoanTerm = observer(({ store }: PricerFieldProps) => {
  const { pricerStore } = store;
  const { pricerSummaryStore } = store;
  const { form } = pricerSummaryStore;

  const handleChangeLoanTerm = value => {
    pricerSummaryStore.setLoanTerm(value);
    pricerSummaryStore.onFieldChange(
      'rateType',
      loanTermRateTermMapping[value].default
    );
    pricerSummaryStore.onFieldChange('prePaymentPenalty', PPP_5_4_3_2_1);
    pricerSummaryStore.onFieldChange('oneTimeYieldSpreadPremium', 0);
    pricerSummaryStore.fetchRatesAndLoanTerms();
  };

  return (
    <GridContainer>
      <SelectField
        disabled={false}
        standaloneLabel
        label={'Loan Term'}
        name={'loanTerm'}
        value={form.fields.loanTerm.value}
        options={sfrLoanTermTypes}
        onChange={value => handleChangeLoanTerm(value)}
        variant="outlined"
        fullWidth
        testId="loanTerm"
      />
    </GridContainer>
  );
});

const RateType = observer(({ store }: PricerFieldProps) => {
  const { pricerStore } = store;
  const { pricerSummaryStore } = store;
  const { form } = pricerSummaryStore;

  const handleChangeYourLoanTerm = (name, value) => {
    pricerSummaryStore.onFieldChange(name, value);
    pricerSummaryStore.setMinsAndMaxs();
  };

  return (
    <GridContainer>
      <SelectField
        disabled={false}
        standaloneLabel
        label={'Rate Type'}
        name={'rateType'}
        value={form.fields.rateType.value}
        options={pricerSummaryStore.rateTypeOptions}
        onChange={value => handleChangeYourLoanTerm('rateType', value)}
        variant="outlined"
        fullWidth
        testId="rateType"
      />
    </GridContainer>
  );
});

const LoanToValueSlider = observer(({ store }: PricerFieldProps) => {
  const classes = useStyles();
  const { pricerSummaryStore } = store;
  const { currentMinLtv, currentMaxLtv, form } = pricerSummaryStore;

  const handleChangeYourLoanTerm = (name, value) => {
    pricerSummaryStore.onFieldChange(name, value);
    pricerSummaryStore.setMinsAndMaxs();
  };

  const buildLoanToValueMarks = () => {
    return [
      {
        value: currentMinLtv,
        label: `${currentMinLtv ?? ''}%`,
      },
      {
        value: currentMaxLtv,
        label: `${currentMaxLtv ?? ''}%`,
      },
    ];
  };

  if (!pricerSummaryStore.isPricerSummaryAvailable) {
    return null;
  }

  return (
    <GridContainer>
      <Slider
        testId="loanToValueSlider"
        standaloneLabel
        label="Loan To Value"
        disabled={false}
        onChangeCommitted={(event, value) =>
          handleChangeYourLoanTerm('loanToValue', value)
        }
        className={classes.slider}
        value={form.fields.loanToValue.value}
        valueLabelDisplay="on"
        marks={buildLoanToValueMarks()}
        step={5}
        min={currentMinLtv}
        max={currentMaxLtv}
      />
    </GridContainer>
  );
});

export const Leases = observer(({ store, onChange = null, fieldName = 'section8', ...rest }) => {
  const formFields = store.form.fields;
  const required = formFields[fieldName].rule.indexOf('required') > -1;

  return (
    <>
      <RadioField
        disabled={false}
        standaloneLabel
        label="Are there section 8 leases for this property?"
        value={formFields[fieldName].value}
        name={fieldName}
        options={yesNoOptions}
        onChange={value => store.onFieldChange('section8', value)}
        fullWidth
        row
        testId={fieldName}
        required={required}
        error={isNotBlank(formFields[fieldName].error)}
        errorText={formFields[fieldName].error}
        {...rest}
      />
    </>
  );
});
