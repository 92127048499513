import { Box, Tooltip } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Delete, Edit, Person, Warning } from '@material-ui/icons';
import { Button, IconButton, PropertyMap } from '@roc/ui';
import { observer } from 'mobx-react';
import {
  PublicLoanCardDividers,
  PublicLoanCardField,
  PublicLoanCard,
  PublicLoanAccordion,
  PublicLoanCardActions,
} from './loanSummaryComponents';
import { isNumber } from '@roc/feature-utils';
import { formatCurrency, formatPercentage, getCompletePropertyAddress } from '@roc/ui/utils';
import { useState } from 'react';
import { House } from '@material-ui/icons';
import { PublicLoanProperty } from '../../utils/publicLoanTypes';
import { emptyMapPlaceholder } from 'libs/feature-borrower-loan-submission/src/modules/newLoanApplication/assets';

const _formatCurrency = value =>
  isNumber(value) ? formatCurrency(value) : null;

export const RentalPropertiesCard = observer(({ store }) => {
  const { propertiesStore } = store;
  const properties = propertiesStore.getProperties();
  const [accordionIndex, setAccordionIndex] = useState();

  const toggleAccordion = i => {
    setAccordionIndex(i === accordionIndex ? null : i);
  };

  return (
    <PublicLoanCard icon={<House />} title={'Properties'}>
      <Box pt={2}>
        <PublicLoanCardDividers px={2}>
          {properties.map((property, i) => (
            <Box mx={-2}>
              <PublicLoanAccordion
                expanded={accordionIndex === i}
                onChange={() => toggleAccordion(i)}
                summary={getCompletePropertyAddress(property)}
              >
                <Box flex={1}>
                  <PropertyItem property={property} />
                </Box>
              </PublicLoanAccordion>
            </Box>
          ))}
          {properties.length === 0 && <PropertyPlaceholder />}
        </PublicLoanCardDividers>
      </Box>
    </PublicLoanCard>
  );
});

const PropertyPlaceholder = () => {
  const emptyProperty = {} as PublicLoanProperty;
  return <PropertyItem property={emptyProperty} />;
};

const PropertyItem = observer(({ property }: { property: any }) => {
  return (
    <PublicLoanCardDividers py={2}>
      {property.latitude === undefined ? (
        <img
          alt="placeholder"
          src={emptyMapPlaceholder}
          style={{
            width: '100%',
            height: '200px',
            objectFit: 'cover',
          }}
        />
      ) : (
        <PropertyMap
          latitude={property.latitude}
          longitude={property.longitude}
          address={property.address}
        />
      )}
      {/* <LoanApplicationCardField label="Loan Purpose" value={property.loanPurpose} /> */}
      <PublicLoanCardField
        label="Purchase Price"
        value={
          !property.acquisitionPrice
            ? '-'
            : _formatCurrency(property.acquisitionPrice)
        }
      />
      <PublicLoanCardField
        label="Estimated As Is Value"
        value={
          !property.propertyValuation
            ? '-'
            : _formatCurrency(property.propertyValuation)
        }
      />
      <PublicLoanCardField
        label="Annual Taxes"
        value={
          !property.annualTaxes ? '-' : _formatCurrency(property.annualTaxes)
        }
      />
      <PublicLoanCardField
        label="Annual Insurance"
        value={
          !property.annualInsurance
            ? '-'
            : _formatCurrency(property.annualInsurance)
        }
      />
      <PublicLoanCardField
        label="Annual HOA Dues"
        value={!property.annualHOA ? '-' : _formatCurrency(property.annualHOA)}
      />
    </PublicLoanCardDividers>
  );
});
