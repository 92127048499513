import {
  Box,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { useLoanStore } from '@roc/feature-loans-shared';
import { formatCurrency } from '@roc/ui/utils';
import { Paper } from '@roc/ui';

const useStyles = makeStyles(() => ({
  container: {
    padding: 32,
  },
  dealName: {
    display: 'flex',
    justifyContent: 'center',
  },
  paper: {
    padding: 24,
  },
}));

const listItem = (label, value, divider: boolean = true) => {
  return (
    <>
      {divider && <Divider variant="middle" component="li" />}
      <ListItem>
        <ListItemText>
          <Box display="flex">
            <Box flexGrow={1}>{label}</Box>
            <span />
            <Box>{value}</Box>
          </Box>
        </ListItemText>
      </ListItem>
    </>
  );
};
interface LoanValuesProps { }
const LoanValues = (props: LoanValuesProps) => {
  const {
    loanStore: { loanDetails },
  } = useLoanStore();
  const classes = useStyles();
  return (
    <Grid item xs={12} md={6}>
      <Paper className={classes.paper}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Typography variant="h6">LOAN VALUES</Typography>
          </Grid>
          <Grid item xs={12} md={12}>
            <Grid container direction="column">
              <List>
                {
                  listItem(
                    'Project Total',
                    formatCurrency(loanDetails.amount),
                    false
                  )
                }
                {
                  listItem(
                    'Construction Holdback Reimbursed To Date',
                    formatCurrency(loanDetails.calculatedLoanData.constructionHoldbackFunded)
                  )
                }
                {
                  listItem(
                    'Construction Holdback Remaining',
                    formatCurrency(loanDetails.calculatedLoanData.constructionHoldbackRemaining)
                  )
                }
              </List>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default LoanValues;
