import React, { useEffect, useState } from 'react';
import { Box, Divider, Grid, List, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { Button, Paper, SelectField } from '@roc/ui';
import { observer } from 'mobx-react';
import { Alert, AlertTitle } from '@material-ui/lab';
import { useStore } from '../../hooks';
import CustomListItem from '../loanTerms/components/customListItem';
import { formatCurrency } from '@roc/ui/utils';

const rushedValue = 'Rushed CDA($120, 1-2 business days)';
const normalValue = 'Normal CDA($90, 3-4 business days)';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      margin: '0 auto',
      borderRadius: theme.spacing(2),
      padding: theme.spacing(3, 0),
      boxShadow:
        'rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px',
    },
    textBold: {
      fontWeight: 700,
    },
    textItalic: {
      fontStyle: 'italic',
    },
    totalCost: {
      marginTop: '20Px',
    },
  })
);

interface Props {
  handleNextStep: () => void;
  entityId: string;
  tamariskFlowType: string;
}

export const InformationStep = observer((props: Props) => {
  const { cdaPaymentsStore } = useStore();
  const { paper, textBold } = useStyles();
  const cdaDocumentStore = cdaPaymentsStore.cdaDocumentStore;
  const { loading, form } = cdaDocumentStore;
  const [cdaOption, setCdaOption] = useState(cdaDocumentStore.rushed ? rushedValue: normalValue);
  const cdaOptions = [{ label: normalValue, value: normalValue }, {label: rushedValue, value: rushedValue}];

  const divider = <Divider variant="middle" component="li" />;

  const renderPaymentDetails = () => (
    <Box mt={3}>
      <Box mt={6}>
        <Paper className={paper}>
          <Box mb={1} px={2}>
            <Typography className={textBold} variant="subtitle1">
              Cost Details
            </Typography>
          </Box>
          <List>
            <CustomListItem
              key='price'
              label='Price'
              value={`$${cdaDocumentStore.feeAmount}`}
            />
            <CustomListItem
              key='timeframe'
              label='Delivery Timeframe'
              value={`${cdaDocumentStore.businessDays} business days`}
            />
            {cdaDocumentStore.properties?.map((prop) => (
              <React.Fragment key={prop.description}>
                {(prop.address && prop.description === 'CDA Fee') && (
                  <CustomListItem
                    key={prop.address}
                    label={prop.address + (prop.asIsValue ? ` (As-Is Value ${formatCurrency(prop.asIsValue)})` : '')}
                    value=""
                  />
                )}
                <div style={{ marginLeft: '20px' }}>
                  <CustomListItem
                    key={prop.description}
                    label={`${prop.description} ${prop.alreadyPaid ? '(already paid)' : ''
                      }`}
                    value={`$${prop.appraisalCost}`}
                  />
                </div>
              </React.Fragment>
            ))}
            {divider}
            <CustomListItem
              key="Total"
              label="Total CDA Cost"
              value={`$${cdaDocumentStore.totalAppraisalCost}`}
            />
          </List>
        </Paper>
      </Box>
    </Box>
  );

  const renderErrorMessage = () => {
    return (
      <Grid item xs={12}>
        <Alert severity="error">
          <AlertTitle>Warning</AlertTitle>
          {`${cdaDocumentStore.feeCalcError}`}
        </Alert>
      </Grid>
    )
  }

  const renderLoading = () => {
    return (
      <Grid item xs={12}>
        <Typography variant="subtitle1">
          Loading...
        </Typography>
      </Grid>
    )
  }

  useEffect(() => {
    cdaDocumentStore.getLoanInfoForCDAPaymentLink(
      props.entityId,
      form.fields.fullName.value
    );
  }, []);

  useEffect(() => {
    setCdaOption(cdaDocumentStore.rushed ? 'Rushed CDA($120, 1-2 business days)' : 'Normal CDA($90, 3-4 business days)')
  }, [cdaDocumentStore.rushed])

  const updateValues = (value) => {
    setCdaOption(value);
    const rushed = value === rushedValue ? true : false;
    cdaDocumentStore.updateRushedNormalOption(
      props.entityId,
      form.fields.fullName.value,
      rushed
    )
  }

  return (
    <>
      <Box style={{ maxWidth: 840, margin: 'auto' }}>
        <Box>
          <Grid container justifyContent="flex-start" spacing={2}>
            <Box mb={2}>
              <Typography variant="h4">CDA Order</Typography>
            </Box>
          </Grid>
        </Box>
        <Box>
          <Typography className={textBold}>
            Please select the preferred delivery timeframe for the report
          </Typography>
          <SelectField
            options={cdaOptions}
            testId={'foundation'}
            value={cdaOption}
            onChange={value => updateValues(value)}
            fullWidth
            variant="outlined"
          />
        </Box>
        {
          loading ? renderLoading() : (cdaDocumentStore.feeCalcError ? renderErrorMessage() : renderPaymentDetails())
        }
        <Box mt={16}>
          <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={6} md={3}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={props.handleNextStep}
                testId="next"
                disabled={(cdaDocumentStore.feeCalcError || loading) ? true : false}
              >
                {cdaPaymentsStore.paymentStatus ? 'Next' : 'Next'}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
});
