import { Service } from "@roc/feature-app-core";

const url = {
  GET_COMP_TOOL_PROPERTIES_DATA:
    '/api/v1/property/compTool/getCompToolPropertiesData',
  GET_PROPERTY_BY_ID:
    '/nportal/rest/priv/property/getPropertyById/'
}


export class CompToolService extends Service {

  getCompToolPropertiesData(compToolRequest: any) {
    return this.post(url.GET_COMP_TOOL_PROPERTIES_DATA, compToolRequest);
  }

  getPropertyById(propertyId: number) {
    return this.get(url.GET_PROPERTY_BY_ID + propertyId);
  }

}