import React from 'react';
import {
  Dialog,
  DialogActions as MuiDialogActions,
  DialogContent,
  DialogTitle as MuiDialogTitle,
  Typography,
  IconButton,
  Box,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { WithStyles } from '@material-ui/core/styles';
import EntityForm from './entityForm';
import { Close } from '@material-ui/icons';
import { observer } from 'mobx-react';
import { Button } from '@roc/ui';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';

export enum BorrowerEntityModalState {
  ADD,
  EDIT,
}

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const styles = (theme: Theme) =>
  createStyles({
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography {...other}>
      <Typography variant="h4">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <Close />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
}))(MuiDialogActions);

const EntityModal = observer(
  ({ modalState, open, handleClose, handleAdd, handleEdit, store }) => {
    const { form } = store.entityStore;

    const handleDialogClose = e => {
      handleClose(e);
    };

    const isNewEntity = modalState == BorrowerEntityModalState.ADD;

    return (
      <div>
        <Dialog
          disableEscapeKeyDown
          disableBackdropClick
          open={open}
          onClose={handleDialogClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle
            id="form-dialog-title"
            onClose={() => handleDialogClose(null)}
          >
            {isNewEntity ? 'Add Borrower Entity' : 'Edit Borrower Entity'}
          </DialogTitle>
          <DialogContent>
            <Box m={4}>
              <EntityForm store={store} />
            </Box>
          </DialogContent>
          <DialogActions disableSpacing>
            <Button onClick={handleDialogClose} color="primary" testId="cancel">
              Cancel
            </Button>
            <Button
              disabled={!form.meta.isValid}
              onClick={e => {
                isNewEntity ? handleAdd() : handleEdit();
                handleDialogClose(e);
              }}
              color="primary"
              variant="contained"
              testId="save"
            >
              {isNewEntity ? 'Add' : 'Apply Changes'}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
);

export default EntityModal;
