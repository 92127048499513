import { Page } from '@roc/feature-app-core';
import { Route } from 'react-router';

import { LoanSubType } from '@roc/feature-utils';
import { ProductSelector } from '../productSelector';
import { FixFlipQuote } from '../fixflipQuote';
import { GroundUpQuote } from '../groundUpQuote';
import { StabilizedBridgeQuote } from '../stabilizedBridgeQuote';

const ONE_TOOL = 'one-tool';

export const routeByLoanSubType = {
  [LoanSubType.FIX_AND_FLIP]: 'fixFlip',
  [LoanSubType.FIX_AND_FLIP_PRO]: 'fixFlipPro',
  [LoanSubType.GROUND_UP]: 'groundUp',
  [LoanSubType.MULTIFAMILY_BRIDGE_5PLUS]: 'multifamilyBridge',
  [LoanSubType.SINGLE_PROPERTY]: 'singleProperty',
  [LoanSubType.SINGLE_PROPERTY_MULTIFAMILY]: 'singleProperty',
  [LoanSubType.SINGLE_PROPERTY_MIXED_USE]: 'singleProperty',
  [LoanSubType.RENTAL_PORTFOLIO]: 'rentalPortfolio',
  [LoanSubType.MULTIFAMILY_TERM_PORTFOLIO]: 'rentalPortfolio',
  [LoanSubType.MIXED_USE_PORTFOLIO]: 'rentalPortfolio',
  [LoanSubType.STABILIZED_BRIDGE]: 'stabilizedBridge',
};

export const getOneToolRoutesConfig = (basePath: string, baseUrl: string) => {
  return {
    oneTool: {
      path: `${basePath}/${ONE_TOOL}`,
      url: `${baseUrl}/${ONE_TOOL}`,
      documentTitle: 'Product Selector',
    },
    fixFlip: (quoteId?) => ({
      path: `${basePath}/${ONE_TOOL}/fix-flip/:quoteId?`,
      url: `${baseUrl}/${ONE_TOOL}/fix-flip/${quoteId ?? ''}`,
      documentTitle: 'Fix & Flip Quote',
    }),
    groundUp: (quoteId?) => ({
      path: `${basePath}/${ONE_TOOL}/ground-up/:quoteId?`,
      url: `${baseUrl}/${ONE_TOOL}/ground-up/${quoteId ?? ''}`,
      documentTitle: 'Ground Up Quote ',
    }),
    stabilizedBridge: (quoteId?) => ({
      path: `${basePath}/${ONE_TOOL}/stabilized-bridge/:quoteId?`,
      url: `${baseUrl}/${ONE_TOOL}/stabilized-bridge/${quoteId ?? ''}`,
      documentTitle: 'Stabilized Bridge Quote ',
    }),
  };
};

export const getOneToolRoutes = (basePath: string, baseUrl: string) => {
  const config = getOneToolRoutesConfig(basePath, baseUrl);
  return {
    oneTool: (
      <Route exact path={config.oneTool.path}>
        <Page routeDefinition={config.oneTool}>
          <ProductSelector />
        </Page>
      </Route>
    ),
    fixFlip: (
      <Route exact path={config.fixFlip().path}>
        <Page routeDefinition={config.fixFlip()}>
          <FixFlipQuote />
        </Page>
      </Route>
    ),
    groundUp: (
      <Route exact path={config.groundUp().path}>
        <Page routeDefinition={config.groundUp()}>
          <GroundUpQuote />
        </Page>
      </Route>
    ),
    stabilizedBridge: (
      <Route exact path={config.stabilizedBridge().path}>
        <Page routeDefinition={config.stabilizedBridge()}>
          <StabilizedBridgeQuote />
        </Page>
      </Route>
    ),
  };
};
