import { Divider, Grid } from '@material-ui/core';
import { observer } from 'mobx-react';
import { AppraisalAttributesPanel, AppraisalTaskDetails } from './editAppraisalTaskBody';

export const AppraisalSummaryTab = observer(() => {
    return (
      <>
        <Grid item xs={12} md={12} style={{
          background: '#fff',
        }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={9}>
              <AppraisalTaskDetails />
            </Grid>
            <Divider
              orientation="vertical"
              style={{ width: '1px', marginRight: '-1px', height: 'inherit' }}
            />
            <Grid item xs={12} md={3}>
              <AppraisalAttributesPanel />
            </Grid>
          </Grid>
        </Grid>
      </>
    );
});