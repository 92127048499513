const mapper = {
  Demolition: {
    helperText: 'Please provide the cost and details of the following items',
    sections: [
      {
        title: 'Demolition',
        fields: [
          {
            label: 'Interior',
            name: 'interior',
          },
          {
            label: 'Exterior',
            name: 'exterior',
          },
          {
            label: 'Entire Structure',
            name: 'entireStructure',
          },
        ],
      },
      {
        title: 'Dumpster',
        fields: [
          {
            label: 'Amount',
            name: 'dumpsterAmount',
          },
        ],
      },
      {
        title: 'Trash Removal/Hauling',
        fields: [
          {
            label: 'Amount',
            name: 'trashRemovalAmount',
          },
        ],
      },
      {
        title: 'Hazardous Material Remediation',
        fields: [
          {
            label: 'Amount',
            name: 'hazardousMaterialRemediationAmount',
          },
        ],
      },
      {
        title: 'Other',
        tooltip: 'Any other relevant items related to this category',
        fields: [
          {
            label: 'Amount',
            name: 'otherAmount',
          },
        ],
      },
    ],
  },
};

export default mapper;
