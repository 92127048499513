import { Grid, Box } from '@material-ui/core';
import { TextField, SelectField, FileUpload, FileUploadModal, FileUploadItem, CurrencyField, StandardDialog, Button, FileUploadArea } from '@roc/ui';
import { observer } from 'mobx-react';
import { isNotBlank } from '@roc/client-portal-shared/utils';
import { ProtectiveAdvanceFormStore } from '../../stores/protectiveAdvanceFormStore';
import { makeStyles } from '@material-ui/core/styles';
import { AutocompleteField } from '@roc/ui';
import { useEffect, useState } from 'react';
import { Entity, ProtectiveAdvanceModalType, TYPES_OF_FEE } from '../../types/types';


interface IProtectiveAdvanceModalProps {
  record?: any;
  loanId: string;
  open: boolean;
  store: ProtectiveAdvanceFormStore;
  vendors: Entity[];
  onClose: () => void;
  onUpload: () => void;
}


const useStyles = makeStyles(theme => ({
  entityLabel: {
    display: 'flex',
    alignItems: 'center',
  },
  autoComplete: {
    minWidth: '400px'
  },

  editableInput: {
    backgroundColor: 'transparent',
    border: 'solid lightgrey 1px',
    outline: 'none',
    marginBottom: 0,
    width: '100%',
    borderRadius: 8,
    fontFamily: 'Oswald, sans-serif;',
  },

  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginRight: '8px'
  },
}));

export const ProtectiveAdvanceModal = observer(({ record, loanId, open, store, vendors, onClose, onUpload }: IProtectiveAdvanceModalProps) => {
  const classes = useStyles();
  const [inputValue, setInputValue] = useState('');
  const [uploads, setUploads] = useState<FileUpload[]>([]);

  const _onUpload = async () => {
    await store.uploadProtectiveAdvance(record?.recordId, loanId, uploads[0]);
    store.reset();
    setInputValue('');
    setUploads([]);
    onUpload();
  };

  useEffect(() => {
    if (record && store.type === ProtectiveAdvanceModalType.EDIT) {
      store.onFieldChange('invoice', record.invoice)
      store.onFieldChange('amount', Number(record.amount))
      store.onFieldChange('typeOfFee', record.feeType)
      const vendor: Entity = vendors.find(val => val.value === record.companyId);
      store.onFieldChange('vendor', vendor.value);
      setInputValue(vendor.label);
    } else {
      store.reset();
    }
  }, [store.type, record]);

  const onRemove = (index: number) => {
    setUploads(prevFiles => {
      const newFiles = [...prevFiles];
      newFiles.splice(index, 1);
      return newFiles;
    });
  };

  const onRename = (index: number, newName: string) => {
    setUploads(prevItems => {
      const items = [...prevItems];
      items[index].name = newName;
      return items;
    });
  };

  const getContent = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            testId="invoice"
            variant="outlined"
            standaloneLabel
            label="Invoice #"
            value={store.form.fields.invoice.value}
            onChange={e => store.onFieldChange('invoice', e.target.value)}
            required={true}
            helperText={
              isNotBlank(store.form.fields.invoice.error) ?
                store.form.fields.invoice.message :
                ''
            }
            error={isNotBlank(store.form.fields.invoice.error)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CurrencyField
            testId={'fee-amount-field'}
            variant={'outlined'}
            label={'Amount'}
            standaloneLabel
            value={store.form.fields.amount.value}
            onChange={(name, value) => store.onFieldChange('amount', Number(value))}
            fullWidth
            required={true}
          />

        </Grid>
        <Grid item xs={12} sm={6}>
          Vendor
          <AutocompleteField
            disabled={store.type === ProtectiveAdvanceModalType.EDIT}
            label=""
            placeHolder='Search'
            options={vendors}
            value={inputValue}
            onChange={(newValue) => {
              setInputValue(newValue)
              if (newValue === '') {
                store.onFieldChange('vendor', newValue)
              }
            }}
            onSelect={(option: any) => {
              store.onFieldChange('vendor', option.value)
              setInputValue(option.label);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectField
            disabled={store.type === ProtectiveAdvanceModalType.EDIT}
            testId={'typeOfFee'}
            fullWidth
            standaloneLabel
            variant="outlined"
            label='Type of Fee'
            options={TYPES_OF_FEE}
            value={store.form.fields.typeOfFee.value}
            onChange={value => store.onFieldChange('typeOfFee', value)}
            required={true}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <FileUploadArea
            items={[]}
            setItems={(items) => setUploads(items)}
            multiple={false} />
        </Grid>
        <Grid item xs={12} sm={12}>
          {uploads.map((item, i) => (
            <FileUploadItem
              key={item.file.name}
              name={item.name}
              file={item.file}
              onSaveName={newName => onRename(i, newName)}
              onRemove={() => onRemove(i)}
            />
          ))}
        </Grid>
      </Grid>
    )
  }

  return (
    <StandardDialog
      open={open}
      title={
        store.type === ProtectiveAdvanceModalType.CREATE ? "Add Protective Advance" : "Edit Protective Advance"
      }
      maxWidth="md"
      handleClose={() => {
        onClose();
        setUploads([]);
      }}
      dialogContent={
        getContent()
      }
      dialogActions={(
        <>
          <Box mr={2}>
            <Button
              className={classes.button}
              variant="outlined"
              color="primary"
              onClick={() => {
                onClose();
                setUploads([]);
              }}
              testId="cancel"
            >
              CANCEL
            </Button>
            <Button
              disabled={
                !(store.form.fields.invoice?.value && store.form.fields.amount?.value && store.form.fields.typeOfFee?.value && store.form.fields.vendor?.value)
              }
              variant="contained"
              color="primary"
              onClick={_onUpload}
              testId="save"
            >
              UPLOAD
            </Button>
          </Box>
        </>
      )}
    />
  );
});