import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import {
  Layout, WhiteLabel
} from '@roc/feature-app-core';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { Tabs } from '@roc/ui';
import { Economics } from 'libs/client-portal-shared/src/app/modules/companyProfile/economics/economics';
import { LoanEconomics } from 'libs/client-portal-shared/src/app/modules/companyProfile/economics/loanEconomics';
import { MailingLists } from 'libs/client-portal-shared/src/app/modules/companyProfile/mailingLists/mailingLists';
import { SubscriptionDetailsCRM } from './SubscriptionDetails/subscriptionDetailsCRM';
import { insertIf } from '@roc/feature-utils';
import { Preferences } from 'libs/client-portal-shared/src/app/modules/companyProfile/preferences/preferences';
import { EntityInformation } from '../companyProfile/entityInformation/entityInformation';
import { DefaultFees } from 'libs/client-portal-shared/src/app/modules/companyProfile/defaultFees/defaultFees';
import { ManageUsers, ManageUsersBackUps } from '@roc/feature-manage-users';
import { CompanyBankInformation } from './titleCompanyBankInformation/companyBankInformation';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import { ApprovalFlowStepper } from 'apps/crm-portal/src/app/modules/companyProfile/approvalFlow/approvalFlowStepper';
import { useStore } from '@roc/client-portal-shared/hooks';
import { LoanVolume } from './loanVolume/loanVolume';
import { useLeadsStore } from '../../hooks/useLeadsStore';
import { gl } from 'date-fns/locale';

const MAILING_LISTS_TAB = 'Mailing Lists';
const ECONOMICS_TAB = 'Economics';
const DEFAULT_FEES_TAB = 'Fees';
const SUBSCRIPTION_DETAILS_TAB = 'Subscription Details';
const LOAN_VOLUME_TAB = 'Loan Volume'
const PREFERENCES = 'Preferences';
const USERS = 'USERS';
const USERS_BACKUPS = 'USERS BACKUPS';
const WHITE_LABEL = 'WHITE LABEL';
const ENTITY_INFORMATION = 'Entity Information';
const BANK_INFO_COLLECTION_TAB = 'Bank Information';
const LOAN_ECONOMICS = 'Pricing Economics';
const TAMARISK_PREFERENCES = 'Tamarisk Preferences';



const useStyles = makeStyles(theme =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'row',
    },
    grid: {
      padding: theme.spacing(3),
    },
    paper: {
      padding: theme.spacing(2),
      marginTop: theme.spacing(1),
      borderRadius: theme.spacing(2),
      boxShadow:
        'rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px',
    },
  })
);

export const CompanyProfileCRM = observer(({
  companyProfileStore,
  globalStore,
  manageUsersStore,
  manageUsersBackUpStore,
  role = null,
  isInternal = false,
  maxWidth = false }) => {
  const { userStore, salesforceLenderStore } = useStore();
  const classes = useStyles();
  const tabIndex = companyProfileStore.selectedTabIndex;
  const userRole = role ? role : userStore.userInformation.roles[0];
  const { lenderDetailsStore } = companyProfileStore;
  const { leadsStore } = useLeadsStore();


  useEffect(() => {
    const fetchData = async () => {
      await companyProfileStore.salesforceLenderStore.fetchSalesforceLender(true);
      await salesforceLenderStore.fetchLoanVolumeStats(salesforceLenderStore.salesForceLender.lenderId, "TPO");
      lenderDetailsStore.initializeForm();
    };
    fetchData();
  }, []);

  const onTabChange = (e, index) => {
    companyProfileStore.setSelectedTabIndex(index);
  };

  const handleOnClick = () => {
    companyProfileStore.setDisplayCompanyProfile(false);
    companyProfileStore.salesforceLenderStore.gridStore.refresh();
    userRole === 'backOfficeLead' && companyProfileStore.salesforceLenderStore.lenderWithNoRmGrid.refresh();
  };

  const TABS = [
    {
      label: ENTITY_INFORMATION,
    },
    {
      label: LOAN_VOLUME_TAB
    },
    {
      label: ECONOMICS_TAB,
    },
    {
      label: LOAN_ECONOMICS,
    },
    {
      label: USERS,
    },
    ...insertIf(!isInternal, [{
      label: USERS_BACKUPS,
    }]),
    ...insertIf(globalStore?.userFeatures?.adminPortalConfiguration, [{
      label: WHITE_LABEL,
    }]),
    {
      label: BANK_INFO_COLLECTION_TAB
    },
    {
      label: PREFERENCES,
    },
    {
      label: SUBSCRIPTION_DETAILS_TAB,
    },
    ...insertIf(globalStore?.userFeatures?.showMailingLists, [{
      label: MAILING_LISTS_TAB,
    }]),
  ];

  return (
    <Layout maxWidth={maxWidth ? 'xl' : 'lg'}>
      <div className={classes.container}>
        {companyProfileStore.displayCompanyProfile &&
          <IconButton size='small' onClick={() => handleOnClick()}>
            <ArrowBackIos />
          </IconButton>}
        <h2>Company Profile</h2></div>
      <ApprovalFlowStepper companyProfileStore={companyProfileStore} />
      <Tabs variant={"scrollable"} scrollButtons={"auto"} tabs={TABS} selectedTab={tabIndex} onTabChange={onTabChange} />
      {TABS[tabIndex]?.label === ENTITY_INFORMATION && <EntityInformation companyProfileStore={companyProfileStore} />}
      {TABS[tabIndex]?.label === LOAN_VOLUME_TAB && <LoanVolume loanVolumeStats={salesforceLenderStore.loanVolumeStats} lenderId={salesforceLenderStore.salesForceLender.lenderId} />}
      {TABS[tabIndex]?.label === DEFAULT_FEES_TAB && <DefaultFees companyProfileStore={companyProfileStore} />}
      {TABS[tabIndex]?.label === ECONOMICS_TAB && <Economics companyProfileStore={companyProfileStore} />}
      {TABS[tabIndex]?.label === LOAN_ECONOMICS && <LoanEconomics companyProfileStore={companyProfileStore} />}
      {TABS[tabIndex]?.label === USERS && <ManageUsers manageUsersStore={manageUsersStore} />}
      {TABS[tabIndex]?.label === USERS_BACKUPS && <ManageUsersBackUps manageUsersBackUpStore={manageUsersBackUpStore} />}
      {TABS[tabIndex]?.label === WHITE_LABEL && <WhiteLabel />}
      {TABS[tabIndex]?.label === BANK_INFO_COLLECTION_TAB && <CompanyBankInformation companyProfileStore={companyProfileStore} />}
      {TABS[tabIndex]?.label === PREFERENCES && <Preferences companyProfileStore={companyProfileStore} />}
      {TABS[tabIndex]?.label === SUBSCRIPTION_DETAILS_TAB && <SubscriptionDetailsCRM companyProfileStore={companyProfileStore} />}
      {TABS[tabIndex]?.label === MAILING_LISTS_TAB && <MailingLists />}
    </Layout>
  );
});