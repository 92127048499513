
import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import { Box, IconButton } from "@material-ui/core";
import { useDocumentLibraryStore } from "@roc/feature-document-library-contentful";
import { observer } from "mobx-react";
import { Close } from '@material-ui/icons';
import { CONTENTFUL_KEY } from "@roc/feature-document-library-contentful";

const Accordion = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 40,
    "&$expanded": {
      minHeight: 40,
    },
    "& *": {
      fontWeight: "bold",
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    "& *": {
      fontSize: 14,
    },
  },
}))(MuiAccordionDetails);

export const BestPractices = observer(({ handleClose, ...rest }: { handleClose: () => void }) => {
  const [expanded, setExpanded] = useState<number | false>(0);
  const { documentLibraryStoreNew } = useDocumentLibraryStore();

  useEffect(() => {
    if (!documentLibraryStoreNew.bestPractices) {
      documentLibraryStoreNew.getEntryByKey(CONTENTFUL_KEY.BEST_PRACTICES);
    }
  }, [documentLibraryStoreNew.bestPractices]);

  const handleChange =
    (panel: number) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  return (
    <Box>
      <Box style={{ borderLeft: '1px solid rgba(0, 0, 0, .125)' }} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
        <Box>
          <Typography
            variant="h6"
            style={{
              display: "flex",
              alignItems: "center",
              padding: '10px'
            }}
          >
            <ThumbUpIcon style={{ marginRight: 10 }} /> Communication Best Practices
          </Typography>
        </Box>
        <Box m={'auto 20px'}>
          <IconButton onClick={handleClose} size="small">
            <Close />
          </IconButton>
        </Box>
      </Box>
      {documentLibraryStoreNew?.bestPractices?.contentJson?.map((item, index) => {
        return (<Accordion
          square
          expanded={expanded === index}
          onChange={handleChange(index)}>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <Typography style={{ fontSize: '14px', fontWeight: 'bold' }}>{`${(index + 1)}. ${item.tabTitle}`}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              {item.content}
              <Box ml={2}>
                <ul>
                  {item?.bulletPoints?.map(bulletPoint => {
                    return (
                      <li>
                        <div dangerouslySetInnerHTML={{ __html: bulletPoint.content }} />
                        {<Box ml={2}><ul>
                          {bulletPoint?.bulletPoints?.map(point => <li>{point}</li>)}
                        </ul></Box>}
                      </li>)
                  })}
                </ul>
              </Box>
            </Typography>
          </AccordionDetails>
        </Accordion>);
      })}
    </Box>);
});