import { useEffect } from "react";
import { Button } from "@material-ui/core";
import { POST_ATF_LOAN_STATUS_LIST, POST_ATF_USER_PERMISSION, useUserStore } from "@roc/feature-app-core";
import { useLoanStore } from "@roc/feature-loans";
import { observer } from "mobx-react";


const SaveButtonWithPermissions = observer((props) => {

  const { loanId, label, ...buttonProps } = props;

  const { userStore } = useUserStore();
  const { loanStore } = useLoanStore();
  const permissions = userStore?.userPermissions;
  const loanStatus = loanStore.loanDetails?.status;

  useEffect(() => {
    userStore.getUserPermissions();
    loanStore.fetchLoanDetails(loanId);
  }, []);

  const hasUserPermission = !!permissions?.includes(POST_ATF_USER_PERMISSION);
  const hasValidLoanStatus = !POST_ATF_LOAN_STATUS_LIST.includes(loanStatus);

  return <Button
    {...buttonProps}
    disabled={buttonProps.disabled || (!hasUserPermission && !hasValidLoanStatus)}
  >
    {label}
  </Button>
})

export default SaveButtonWithPermissions;