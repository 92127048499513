export enum ACHFormSteps {
  SIGNATURE = 'signature',
  SIGNED = 'signed',
}

export const accountTypeOptions = [
  { label: 'Checking', value: 'Checking' },
  { label: 'Savings', value: 'Savings' },
];

export const withdrawalDayOptions = [
  { label: '1st day of the Month', value: 1 },
  { label: '2nd day of the Month', value: 2 },
  { label: '3rd day of the Month', value: 3 },
  { label: '4th day of the Month', value: 4 },
  { label: '5th day of the Month', value: 5 },
  { label: '6th day of the Month', value: 6 },
  { label: '7th day of the Month', value: 7 },
  { label: '8th day of the Month', value: 8 },
  { label: '9th day of the Month', value: 9 },
  { label: '10th day of the Month', value: 10 },
];
