import { Service } from '@roc/feature-app-core';

const url = {
  GET_INSURANCE_SUMMARY:
    '/api/v1/document/formDocument/getInsuranceSummaryFormDetails',
  SAVE_INSURANCE_SUMMARY_FORM:
    '/api/v1/document/formDocument/saveInsuranceSummaryForm',
  APPROVE_INSURANCE: '/api/v1/document/formDocument/approveInsurance',
  UPDATE_INSURANCE_SUMMARY:
    '/api/v1/document/formDocument/updateInsuranceSummaryDetails',
  DELETE_INSURANCE_SUMMARY_DETAIL:
    '/api/v1/document/formDocument/deleteInsuranceSummaryDetail',
  GET_DELAY_REASON: '/api/v1/document/formDocument/getDelayReason',
  VALIDATE_FLOOD_CERT_CONDITIONS: '/api/v1/document/formDocument/validateFloodCertConditions',
  GET_COVERAGE_START_DATE: '/api/v1/insurance/insurance-review/getCoverageStartDate',
};

export class InsuranceApprovalService extends Service {
  getInsuranceSummary = (loanId: string) => {
    return this.get(url.GET_INSURANCE_SUMMARY, {
      loanId,
    });
  };

  saveInsuranceSummary = details => {
    return this.put(url.SAVE_INSURANCE_SUMMARY_FORM, details);
  };

  confirmAndApproveInsurance(insuranceForm, files: File[]) {
    const insuranceFormJson = new Blob([JSON.stringify(insuranceForm)], {
      type: 'application/json',
    });

    const formData = new FormData();
    formData.append('insuranceForm', insuranceFormJson);
    files.forEach(f => formData.append('file', f));

    return this.post(url.APPROVE_INSURANCE, formData);
  }

  updateInsuranceDetails = details => {
    return this.put(url.UPDATE_INSURANCE_SUMMARY, details);
  };

  deleteInsuranceSummaryDetail = detail => {
    return this.put(url.DELETE_INSURANCE_SUMMARY_DETAIL, detail);
  };
  getDelayReason = (loanId: string) => {
    return this.get(url.GET_DELAY_REASON, {
      loanId,
    });
  };

  validateFloodCertConditions = (loanId) => {
    return this.get(url.VALIDATE_FLOOD_CERT_CONDITIONS, {
      loanId,
    });
  };

  getCoverageStartDate = (loanId: number) => {
    return this.get(url.GET_COVERAGE_START_DATE, { loanId });
  };
}
