import React from 'react';
import { Box, List, ListItem, ListItemText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    subList: {
      padding: 0,
      '& li': {
        display: 'block',
        padding: '8px 0',
        textAlign: 'right',
        '&:first-child': {
          paddingTop: 0,
        },
        '&:last-child': {
          paddingBottom: 0,
        },
      },
    },
  })
);

interface Props {
  label: string;
  value: string | Array<string>;
}

const CustomListItem: React.FC<Props> = props => {
  const { value } = props;
  const classes = useStyles();

  return (
    <ListItem>
      <ListItemText>
        <Box display="flex">
          <Box flexGrow={1} mr={2}>
            {props.label}
          </Box>
          <Box textAlign="right">
            {Array.isArray(value) ? (
              <List className={classes.subList}>
                {value.map((itemText: string) => (
                  <ListItem key={itemText}>{itemText}</ListItem>
                ))}
              </List>
            ) : (
              value
            )}
          </Box>
        </Box>
      </ListItemText>
    </ListItem>
  );
};

export default CustomListItem;
