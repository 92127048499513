import { useBaseStore } from "@roc/feature-app-core";
import { getLoanApplicationRoutesConfig } from "../routes/loanApplicationRoutes";

export const useNewLoanApplicationRoutes = () => {
  const { globalStore } = useBaseStore();
  const { routeBasePath, routeBaseUrl } = globalStore;
  return {
    loanApplicationRoutesConfig: getLoanApplicationRoutesConfig(
      routeBasePath,
      routeBaseUrl
    ),
  };
};
