import { Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { useState } from 'react';
import OtpInput from 'react-otp-input';

export const TwoFactorOtpInput = ({
  onChange,
  onValidate
}: {
  onChange?: (value) => void
  onValidate?: (value) => Promise<any>
}) => {
  const [value, setValue] = useState('');
  const [verifying, setVerifying] = useState<boolean>(false);
  const theme = useTheme();
  const isXtraSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const handleChange = (newValue) => {
    setValue(newValue);
    onChange && onChange(newValue);
    checkValidation(newValue, () => {
      if (newValue.length == 6) {
        if (onValidate) {
          setVerifying(true);
          onValidate(newValue)
            .then((result) => {
              // do nothing on success
            })
            .catch(() => {
              setVerifying(false);
            })
            .finally(() => {
              setValue('');
              setTimeout(() => {
                document.getElementById('otp-id-1')?.focus();
              }, 100);
            });
        }
      }
    });
  };

  const checkValidation = (value, callback) => {
    if (value.length == 6) {
      callback && callback();
    }
  };

  const inputStyle = {
    width: 48,
    padding: 4,
    fontSize: 32,
    margin: '0 4px',
    borderRadius: 8,
    border: '1px solid #999'
  };

  if (isXtraSmallScreen) {
    inputStyle.width = 32;
    inputStyle.fontSize = 24;
  }

  return (
    <OtpInput
      inputType={'number'}
      value={value}
      onChange={handleChange}
      numInputs={6}
      renderSeparator={<Typography component="span" style={{ color: '#bbb' }}>&#8226;</Typography>}
      containerStyle={{
        justifyContent: 'center'
      }}
      inputStyle={inputStyle}
      renderInput={(props, index) => <input id={`otp-id-${index + 1}`} {...props} disabled={verifying} />}
      shouldAutoFocus
    />
  )
};