import React, { useState } from 'react';
import {
  Dialog,
  DialogActions as MuiDialogActions,
  DialogContent,
  DialogTitle as MuiDialogTitle,
  Typography,
  IconButton,
  Checkbox,
  FormControlLabel,
  Box
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { WithStyles } from '@material-ui/core/styles';
import { Close } from '@material-ui/icons';
import { observer } from 'mobx-react';
import { Button } from '@roc/ui';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const styles = (theme: Theme) =>
  createStyles({
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography {...other}>
      <Typography variant="h4">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <Close />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
}))(MuiDialogActions);

const WimbaModal = observer(
  ({ open, handleClose, handleConfirm }) => {

    const handleDialogClose = e => {
      handleClose(e);
    };

    const [checked, setChecked] = useState(false);

    return (
      <div>
        <Dialog
          disableEscapeKeyDown
          disableBackdropClick
          open={open}
          onClose={handleDialogClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle
            id="form-dialog-title"
            onClose={() => handleDialogClose(null)}
          >
            Wimba Acknowledgement
          </DialogTitle>
          <DialogContent>
            <Box className={`dialog-content-box`} m={4}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={checked}
                    onChange={(e) => setChecked(e.target.checked)}
                  />
                }
                label={"I acknowledge that by selecting Wimba, a title order will be opened and any future cancellations may incur fees."}
              />
            </Box>
          </DialogContent>
          <DialogActions disableSpacing>
            <Button onClick={handleDialogClose} color="primary" testId="cancel">
              Go Back
            </Button>
            <Button
              disabled={!checked}
              onClick={e => {
                handleConfirm();
              }}
              color="primary"
              variant="contained"
              testId="save"
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
);

export default WimbaModal;
