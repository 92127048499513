
import { observer } from "mobx-react";
import { Button, StandardDialog, SelectField } from '@roc/ui';
import { useEffect } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { Checkbox, FormControlLabel, Grid, TextField, Typography } from '@material-ui/core';
import { useNewLeadStore } from "../../hooks/useNewLeadStore";
import { BaseLeadStore } from "libs/client-portal-shared/src/app/modules/companyProfile/stores/baseLeadStore/BaseLeadStore";


export const BulkLeadChangeOwner = observer(({ open, handleClose, salesforceLeadStore, isFromGlobalSearch }: { open, handleClose, salesforceLeadStore: BaseLeadStore, isFromGlobalSearch }) => {
  const { newLeadStore } = useNewLeadStore();
  useEffect(() => {
    fetchData();
  }, [])


  const fetchData = async () => {
    await salesforceLeadStore.getAllOwners();
  };

  const handleSaveClick = async () => {
    if (isFromGlobalSearch) {
      await salesforceLeadStore.assignOwner();
    } else {
      await salesforceLeadStore.bulkChangeOwner();
    }
    salesforceLeadStore.resetLeadsIds();
    if (salesforceLeadStore.isFromPossibleMatches) {
      newLeadStore.gridStore.fetchGridData();
    }
    salesforceLeadStore.setIsFromPossibleMatches(false);
    salesforceLeadStore.setNewLeadOwner(null);
    handleClose();
  };
  const allOwners = salesforceLeadStore.rocActiveOwners.concat(salesforceLeadStore.facoActiveOwners, salesforceLeadStore.civicActiveOwners);

  const handleChangeFundedLoans = e => {
    salesforceLeadStore.setMoveFundedLoans(e.target.checked);
  };

  const handleChangeSendNotification = e => {
    salesforceLeadStore.setSendNotification(e.target.checked);
  };


  const handleChangeNonFundedLoans = e => {
    salesforceLeadStore.setMoveNonFundedLoans(e.target.checked);
  };

  return (
    <StandardDialog
      open={open}
      title={!salesforceLeadStore.isFromPossibleMatches ? "Change Bulk Owner" : "Change Owner"}
      maxWidth="lg"
      handleClose={handleClose}
      dialogContent={
        <>
          <Typography variant="h6" gutterBottom style={{ marginRight: '15px' }}>
            {!salesforceLeadStore.isFromPossibleMatches ? 'Select or Search the new Owner for leads' : 'Select or Search the new Owner for lead'}
          </Typography>
          <Autocomplete
            id="leads-dashboard-autocomplete"
            selectOnFocus
            handleHomeEndKeys
            options={allOwners}
            value={(allOwners || []).find(o => o.value === salesforceLeadStore.newLeadOwner)}
            inputValue={salesforceLeadStore.searchOwnerText}
            size="small"
            blurOnSelect={true}
            onInputChange={(e, value) => (salesforceLeadStore.searchOwnerText = value)}
            renderInput={params => <TextField variant={'outlined'} {...params} />}
            getOptionLabel={option => option?.label ?? ''}
            getOptionSelected={(option, selected) => option?.value === selected?.value}
            onChange={(event, option) => salesforceLeadStore.setNewLeadOwner(option.value)}
            fullWidth
          />
          <br />
          {(salesforceLeadStore.isBorrower) && <>
            <Grid>

              <FormControlLabel
                control={
                  <Checkbox
                    name="moveNonFunded"
                    color="primary"
                    onChange={handleChangeNonFundedLoans}
                  />
                }
                labelPlacement="end"
                label="Move Non Funded Loans"
              />
            </Grid>
            <Grid>
              <FormControlLabel
                control={
                  <Checkbox
                    name="moveFunded"
                    color="primary"
                    disabled={!salesforceLeadStore.moveNonFundedLoans}
                    onChange={handleChangeFundedLoans}
                  />
                }
                labelPlacement="end"
                label="Move Funded Loans"
              />
            </Grid>
          </>
          }
          <Grid>
            <FormControlLabel
              control={
                <Checkbox
                  name="sendNotification"
                  color="primary"
                  onChange={handleChangeSendNotification}
                />
              }
              labelPlacement="end"
              label="Send Notification"
            />
          </Grid>
        </>
      }
      dialogActions={
        <>
          <Button
            style={{ marginRight: '8px' }}
            onClick={handleClose}
            color="primary"
            variant="outlined"
            testId="cancel"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSaveClick}
            testId="next"
          >
            Change Owner
          </Button>
        </>
      }
    />
  );
});
