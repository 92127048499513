import {
  Card,
  CardContent,
  Typography,
  Avatar,
  makeStyles,
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';
import InfoIcon from '@material-ui/icons/Info';
import { darken } from '@material-ui/core/styles';
import {
  creditBackgroundReportsStatuses,
  CreditStatus,
} from '../../../utils/constants';

interface styleProps {
  bgColor: string;
  textColor: string;
}

const useStyles = makeStyles(theme => ({
  card: (props: styleProps) => ({
    display: 'flex',
    alignItems: 'center',
    backgroundColor: props.bgColor,
    borderRadius: '8px',
    padding: '3px',
  }),
  cardContent: () => ({
    padding: '0px 14px 0px 5px !important',
  }),
  icon: (props: styleProps) => ({
    backgroundColor: 'transparent',
    color: props.textColor,
  }),
  text: (props: styleProps) => ({
    color: darken(props.textColor, 0.4),
  }),
}));

interface CreditBackgroundCardProps {
  status: CreditStatus;
}

export const CreditBackgroundCard = ({ status }: CreditBackgroundCardProps) => {
  let icon: any, bgColor: string, textColor: string;
  let message = '';

  switch (status) {
    case CreditStatus.CREDIT_AND_BACKGROUND_OK_XACTUS:
      icon = <CheckCircleIcon />;
      bgColor = '#eef7e6';
      textColor = '#4a7b39';
      message = 'Xactus 360 Credit & Background';
      break;
    case CreditStatus.CREDIT_AND_BACKGROUND_OK:
      icon = <CheckCircleIcon />;
      bgColor = '#eef7e6';
      textColor = '#4a7b39';
      message = 'Credit & Background';
      break;
    case CreditStatus.CREDIT_OLDER_THAN_180_DAYS:
      icon = <WarningIcon />;
      bgColor = '#fff4e5';
      textColor = '#ff9800';
      message = 'Credit is 180+ Days Old';
      break;
    case CreditStatus.CREDIT_AND_BACKGROUND_CHECK_SENT:
      icon = <InfoIcon />;
      bgColor = '#e3f2fd';
      textColor = '#2196f3';
      message = 'Credit & Background Sent';
      break;
    default:
      icon = <ErrorIcon />;
      bgColor = '#fdecea';
      textColor = '#b71c1c';
      message = 'No Credit & Background';
  }

  const classes = useStyles({ bgColor, textColor });

  return (
    <Card className={classes.card} elevation={0}>
      <Avatar className={classes.icon}>{icon}</Avatar>
      <CardContent className={classes.cardContent}>
        <Typography variant="body2" className={classes.text}>
          {message}
        </Typography>
      </CardContent>
    </Card>
  );
};
