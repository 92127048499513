import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { Toolbar } from 'libs/ui/src/lib/components/index';
import { FilterButton } from 'libs/client-portal-shared/src/app/components/grid/filterButton';
import { makeStyles } from '@material-ui/core/styles';
import { formatPhoneNumber } from '@roc/ui/utils';
import { Grid, GridColumnProps, SelectMode } from 'libs/client-portal-shared/src/app/components/grid';
import {
  SelectFilterComponent,
  SelectFloatingFilterComponent,
} from 'libs/client-portal-shared/src/app/components/grid/selectFilter';
import { useStore } from '@roc/client-portal-shared/hooks';
import { OriginatorsStore } from '../../stores/originatorStore';

const noOpComparator = () => 0;

const columns: GridColumnProps[] = [
  {
    field: 'lenderName',
    headerName: 'Name',
    minWidth: 100,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'companyName',
    headerName: 'Company Name',
    minWidth: 100,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
];

const useStyles = makeStyles(() => ({
  container: {
    height: 435,
  },
}));

export type SelectOriginatorGridProps = {
  store: OriginatorsStore;
};

export const SelectOriginatorGrid = observer((props: SelectOriginatorGridProps) => {
  const classes = useStyles();
  const { originatorsGridStore: gridStore } = props.store;

  useEffect(() => {
    gridStore.fetchGridData();
  }, []);

  const frameworkComponents = {
    selectFilter: SelectFilterComponent,
    selectFloatingFilter: SelectFloatingFilterComponent
  };

  const onFilterChanged = params => {
    const filterModel = params.api.getFilterModel();
    gridStore.setFilterModel(filterModel);
  };

  const toolbar = (
    <Toolbar>
      <FilterButton
        onClearFilters={() => gridStore.resetFilters()}
        onToggleFilters={() => gridStore.toggleFilters()}
      />
    </Toolbar>
  );

  return (
    <Grid
      className={classes.container}
      selectMode={SelectMode.MULTIPLE}
      onRowSelected={e => gridStore.onRowSelected(e)}
      columns={columns}
      rows={gridStore.gridData.data.rows}
      frameworkComponents={frameworkComponents}
      isLoading={gridStore.isLoading}
      domLayout="normal"
      onFilterChanged={onFilterChanged}
      showFilters={gridStore.showFilters}
      sortModel={gridStore.sortModel}
      filterModel={gridStore.filterModel}
      paginationData={gridStore.gridData.meta}
      setPageNumber={gridStore.setPageNumber}
      toolbar={toolbar}
    />
  );
});

export default SelectOriginatorGrid;