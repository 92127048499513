import { Grid } from '@material-ui/core';
import { observer } from 'mobx-react';
import { ExpectedWireReleaseDateStore } from '../../../stores/documentForms/expectedWireReleaseDateStore';
import { createDateField } from '@roc/ui';
import { useEffect } from 'react';

export type ExpectedWireReleaseDateProps = {
  store: ExpectedWireReleaseDateStore;
};

export const ExpectedWireReleaseDate = observer(
  (props: ExpectedWireReleaseDateProps) => {
    const { store } = props;

    useEffect(() => {
      store.initialize();
    }, []);

    return (
      <Grid container spacing={1} justify="center">
        <Grid item>
          {createDateField({
            store,
            testId: 'date',
            fieldName: 'date',
            label: '',
            disabled: !store.shouldBeAbleToSubmitExpectedWireReleaseDate(),
          })}
        </Grid>
      </Grid>
    );
  }
);

export default ExpectedWireReleaseDate;
