import { Accordion, AccordionDetails, AccordionSummary, Box, Dialog, DialogContent, DialogTitle, Divider, Grid, IconButton, List, ListItem, Table, TableBody, TableCell, TableHead, TableRow, Typography, useMediaQuery } from "@material-ui/core";
import { useTheme, makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { observer } from "mobx-react";
import CloseIcon from '@material-ui/icons/Close';
import { useState } from "react";
import { ExpandMore } from "@material-ui/icons";
import { ScopeOfWorkV2FormBaseStore } from "@roc/feature-sow-shared/stores/v2";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    title: {
      paddingRight: theme.spacing(6),
      fontSize: 25,
      fontWeight: 'bold',
    },
    titleTable: {
      color: '#333333',
      fontSize: 15,
      fontWeight: 'bold',
      textTransform: 'uppercase',
      paddingRight: '10px'
    },
    table: {
      minWidth: 750,
      border: '2px #e0e0e0 solid',
      '& td': {
        height: '40px',
        padding: 0,
        paddingTop: '8px',
        paddingBottom: '8px',
      },
      '& th:first-child, td[rowSpan], $addNewItemRow td': {
        paddingLeft: theme.spacing(2),
        paddingRight: '3px',
      },
      '& table tr:last-child td, & table td[rowSpan]': {
        borderBottom: 'none',
      },
      marginBottom: '5px'
    },
    accordion: {
      marginBottom: '25px',
    },
    headerCell: {
      height: '41px',
      fontWeight: 'bold',
      marginRight: '3px',
      padding: 0,
    },
    firstVersionContainer: {
      marginLeft: '10px'
    },
  })
);

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  store: ScopeOfWorkV2FormBaseStore;
}

const VersionsModal = observer((props: Props) =>{
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const { isOpen, handleClose, store } = props;
  const currentVersion = store?.scopeOfWork?.currentVersion;
  const classes = useStyles();
  const [expanded, setExpanded] = useState<string | false>('panel'+(currentVersion-1));
  const logs = store.scopeOfWorkAuditLogsMessages;

  const handleExpandAccordion =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  return(
    <Dialog
      disableEscapeKeyDown
      fullScreen={fullScreen}
      fullWidth
      maxWidth="md"
      open={isOpen}
      scroll="paper"
      onClose={handleClose}
      aria-labelledby="versions-sow-dialog"
    >
      <DialogTitle disableTypography>
        <Typography className={classes.title} variant="h3">
          Scope Of Work History
        </Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} className={classes.accordion}>
          <AccordionVersionsModal logs={logs} expanded={expanded} handleExpandAccordion={handleExpandAccordion}/>
        </Grid>
      </DialogContent>
    </Dialog>
  )
});

const FirstVersionModal = observer(({}) => {
  const classes = useStyles();
  return (
    <Grid className={classes.firstVersionContainer}>
      <Typography> Items shown in this form reflect the current changes for this version. </Typography>
    </Grid>
  )
});


const AccordionVersionsModal = observer(({logs, expanded, handleExpandAccordion}) => {
  return(
    <Grid style={{width: '100%'}}>
      {logs.map((version) => {
        return version?.type === 'Updates Messages'
        ? <ExpandableAccordionVersion expanded={expanded} handleExpandAccordion={handleExpandAccordion} versionLogs={version}/>
        : <NonExpandableAccordionVersion versionLogs={version}/>
      })}
    </Grid>
  )
});

const ExpandableAccordionVersion = observer(({expanded, handleExpandAccordion, versionLogs}) => {
  const {  logsMessages, version, submittedBy, submittedDate } = versionLogs;
  const panelName = 'panel'+version;

  return(
    <Accordion expanded={expanded === panelName} onChange={handleExpandAccordion(panelName)} style={{width: '100%', marginBottom: '10px'}}>
        <AccordionSummary
          expandIcon={<ExpandMore/>}
          style={{width: '100%'}}
        >
          <Typography style={{fontWeight: 'bold', color: expanded === 'panel'+version ? '#285391' : 'black'}}>
            Version # {version} was submitted by {submittedBy} on {submittedDate}
          </Typography>
        </AccordionSummary>
        <Divider/>
        <AccordionDetails style={{width:'100%'}}>
          <List style={{width: '100%'}}>
              {logsMessages.length > 0 ? logsMessages.map(message => (
                <Box>
                  <ListItem>
                    <Typography style={{marginBottom: '10px', marginLeft: '15px'}}>
                        <FiberManualRecordIcon style={{fontSize: '10px', marginTop: '2px'}}/> {message}
                    </Typography>
                  </ListItem>
                  <Divider/>
                </Box>
              )) :
              <FirstVersionModal/>}
            </List>
        </AccordionDetails>
      </Accordion>
  )
});

const NonExpandableAccordionVersion = observer(({versionLogs}) => {
  const {  version, submittedBy, submittedDate, type } = versionLogs;

  const getTextByType = () => {
    return type === 'Approved' ? `Version # ${version} was approved by ${submittedBy} on ${submittedDate}`
    : type === 'Sent to review' ? `Revision requested by ${submittedBy} on ${submittedDate}`
    : type === 'SOW Creation' ? `Scope of Work was created by ${submittedBy} on ${submittedDate}.`
    : 'No previous logs are available for this scope of work.';
  }

  return(
    <Accordion style={{width: '100%', marginBottom: '10px'}}>
      <AccordionSummary
        style={{ pointerEvents: 'none' }}
      >
        <Typography style={{fontWeight: 'bold'}}>
          {getTextByType()}
        </Typography>
      </AccordionSummary>
    </Accordion>
  )
})

export default VersionsModal;