import {
  action,
  computed,
  flow,
  makeObservable,
  observable,
  override,
} from 'mobx';
import { FormStore, GlobalStore } from '@roc/feature-app-core';
import { BridgeProfitAndDscrCalculatorService } from '../services';

const bridgeProfitAndDscrCalculatorFormFields = {
  fields: {
    loanPurpose: {
      value: '',
      error: null,
      rule: 'required',
    },
    acquisition: {
      value: '',
      error: null,
      rule: 'required',
    },
    asIsValue: {
      value: '',
      error: null,
      rule: '',
    },
    capitalImprovements: {
      value: '',
      error: null,
      rule: [{ required_if: ['loanPurpose', 'Refi'] }]
    },
    renovationBudget: {
      value: '',
      error: null,
      rule: 'required',
    },
    afterRepairValue: {
      value: '',
      error: null,
      rule: '',
    },
    loanTerm: {
      value: 12,
      error: null,
      rule: 'required',
    },
    initialLoanAmount: {
      value: 0,
      error: null,
      rule: 'required',
    },
    constructionHoldback: {
      value: 0,
      error: null,
      rule: 'required',
    },
    exitStrategy: {
      value: '',
      error: null,
      rule: 'required',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

const saleExitStrategyFormFields = {
  fields: {
    interestRate: {
      value: '',
      error: null,
      rule: 'required',
    },
    points: {
      value: '',
      error: null,
      rule: 'required',
    },
    mortgageTax: {
      value: 0,
      error: null,
      rule: 'required',
    },
    transferTax: {
      value: 1,
      error: null,
      rule: 'required',
    },
    mansionTax: {
      value: 0,
      error: null,
      rule: 'required',
    },
    titleInsurance: {
      value: 1,
      error: null,
      rule: 'required',
    },
    brokerFee: {
      value: '',
      error: null,
      rule: 'required',
    },
    propertyTaxAndMaintenance: {
      value: 1,
      error: null,
      rule: 'required',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

const refinanceExitStrategyFormFields = {
  fields: {
    exitLoanRate: {
      value: '',
      error: null,
      rule: 'required',
    },
    monthlyRent: {
      value: '',
      error: null,
      rule: 'required',
    },
    annualTaxes: {
      value: '',
      error: null,
      rule: 'required',
    },
    annualHOADues: {
      value: '',
      error: null,
      rule: 'required',
    },
    annualInsurance: {
      value: '',
      error: null,
      rule: 'required',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

const refinanceLoanPurposeFormfields = {
  fields: {
    purchaseDate: {
      value: '',
      error: null,
      rule: 'required',
    },
    payoffAmount: {
      value: '',
      error: null,
      rule: 'required',
    },
    loanClosingDate: {
      value: '',
      error: null,
      rule: 'required',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

class SaleExitStrategyFormStore extends FormStore {
  protected globalStore: GlobalStore;
  constructor(globalStore) {
    super({ ...saleExitStrategyFormFields }, globalStore);
    this.globalStore = globalStore;
  }
}

class RefinanceExitStrategyFormStore extends FormStore {
  protected globalStore: GlobalStore;
  constructor(globalStore) {
    super({ ...refinanceExitStrategyFormFields }, globalStore);
    this.globalStore = globalStore;
  }
}
class RefinanceLoanPurposeFormStore extends FormStore {
  protected globalStore: GlobalStore;
  constructor(globalStore) {
    super({ ...refinanceLoanPurposeFormfields }, globalStore);
    this.globalStore = globalStore;
  }
}

export class BridgeProfitAndDscrCalculatorFormStore extends FormStore {
  protected globalStore: GlobalStore;
  loanSearchText: string;
  loanSearchOptions: { label: string; value: string }[];
  protected bridgeProfitAndDscrCalculatorService: BridgeProfitAndDscrCalculatorService;

  saleExitStrategyFormStore: SaleExitStrategyFormStore;
  refinanceExitStrategyFormStore: RefinanceExitStrategyFormStore;
  refinanceLoanPurposeFormStore: RefinanceLoanPurposeFormStore;

  constructor(globalStore) {
    super({ ...bridgeProfitAndDscrCalculatorFormFields }, globalStore);
    this.globalStore = globalStore;
    this.bridgeProfitAndDscrCalculatorService = new BridgeProfitAndDscrCalculatorService();
    this.saleExitStrategyFormStore = new SaleExitStrategyFormStore(this.globalStore);
    this.refinanceExitStrategyFormStore = new RefinanceExitStrategyFormStore(this.globalStore);
    this.refinanceLoanPurposeFormStore = new RefinanceLoanPurposeFormStore(this.globalStore);

    makeObservable(this, {
      reset: override,
      loanSearchText: observable,
      loanSearchOptions: observable,
      fetchOptions: flow,
      fetchLoanValues: flow,
      getAutocompleteOptions: flow,
      exitStrategy: computed,
      loanPurpose: computed,
      getDefaultExitRate: flow,
    });
    this.setDefaults();
  }

  setDefaults() {
    this.loanSearchText = '';
    this.loanSearchOptions = [];
  }
  reset() {
    super.reset();
    this.setDefaults();
  }

  *getAutocompleteOptions(searchTerm: string) {
    const filters = { dealName: searchTerm };
    const response = yield this.bridgeProfitAndDscrCalculatorService.getBridgeDealNames(
      1,
      25,
      null,
      null,
      filters
    );
    const rows = response.data.data ? response.data.data.rows : response.data.rows;
    return rows.map(row => ({
      label: row.dealName,
      value: row.dealid,
    }));
  }

  *fetchOptions(searchText: string) {
    try {
      if (!searchText) {
        this.loanSearchOptions = [];
        return;
      }
      const options = yield this.getAutocompleteOptions(searchText);
      this.loanSearchOptions = options;
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'An error occurred while loading the list of loans.',
      });
    }
  }

  *fetchLoanValues(loanId: string) {
    try {
      const response = yield this.bridgeProfitAndDscrCalculatorService.getLoanValues(loanId);
      const loanValues = response.data;
      this.loadFormFromLoan(loanValues);
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'An error occurred while loading the loan data.',
      });
    }
  }
  get exitStrategy() {
    return this.form.fields.exitStrategy.value ?? '';
  }
  get loanPurpose() {
    return this.form.fields.loanPurpose.value ?? '';
  }

  *getDefaultExitRate() {
    try {
      const response = yield this.bridgeProfitAndDscrCalculatorService.getExitRate();
      const refiFormValues = {
        exitLoanRate: response.data,
      };
      this.refinanceExitStrategyFormStore.loadForm(refiFormValues);
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'An error occurred while loading default data.',
      });
    }

  }

  loadFormFromLoan(loanValues) {
    const formValues = {
      loanPurpose: loanValues.loanPurpose,
      acquisition: loanValues.acquisitionPrice,
      asIsValue: loanValues.value,
      capitalImprovements: loanValues.completedCapitalImprovements,
      renovationBudget: loanValues.renovationBudget,
      afterRepairValue: loanValues.salesPrice,
      loanTerm: loanValues.loanTerm,
      exitStrategy: loanValues.exitStrategy,
      initialLoanAmount: this.calculateInitialLoanAmountPercentageFromLoan(loanValues),
      constructionHoldback: this.calculateConstructionHoldbackPercentageFromLoan(loanValues)
    };

    if (loanValues.loanPurpose === 'Refi') {
      const refiLoanPurposeFormValues = {
        purchaseDate: loanValues.purchaseDate,
        payoffAmount: loanValues.payoffAmount,
        loanClosingDate: loanValues.loanClosingDate
      };

      this.refinanceLoanPurposeFormStore.loadForm(refiLoanPurposeFormValues);
      this.refinanceLoanPurposeFormStore.runFormValidation();
    }

    const saleFormValues = {
      interestRate: loanValues.interestRate,
      points: loanValues.points,
      mortgageTax: loanValues.mortgageTaxPercent,
      transferTax: loanValues.transferTaxPercent,
      mansionTax: loanValues.mansionTaxPercent,
      titleInsurance: loanValues.titleInsurancePercent,
      brokerFee: loanValues.brokerFeePercent,
      propertyTaxAndMaintenance: loanValues.propertyTaxAndMaintenancePercent
    };

    const refiFormValues = {
      exitLoanRate: loanValues.exitLoanRate,
      monthlyRent: loanValues.rent,
      annualInsurance: loanValues.annualInsurance,
      annualTaxes: loanValues.annualTaxes,
      annualHOADues: loanValues.annualHOADues,
    };

    this.loadForm(formValues);
    this.saleExitStrategyFormStore.loadForm(saleFormValues);
    this.refinanceExitStrategyFormStore.loadForm(refiFormValues);

    this.runFormValidation();
    this.saleExitStrategyFormStore.runFormValidation();
    this.refinanceExitStrategyFormStore.runFormValidation();
  }

  calculateInitialLoanAmountPercentageFromLoan(loanValues) {
    let initialLoanAmountPercentage = 0;
    if (loanValues.initialLoanAmount !== null
      && loanValues.purchasePrice !== null
      && loanValues.loanPurpose !== null) {
      const purchasePrice = loanValues.loanPurpose === "Purchase"
        ? loanValues.purchasePrice
        : loanValues.purchasePrice + (loanValues.completedCapitalImprovements ?? 0);
      initialLoanAmountPercentage = (loanValues.initialLoanAmount * 100) / purchasePrice;
    }
    return initialLoanAmountPercentage.toFixed(0);
  }

  calculateConstructionHoldbackPercentageFromLoan(loanValues) {
    let constructionHoldbackPercentage = 0;
    if (loanValues.constructionHoldback !== null
      && loanValues.renovationBudget !== null
      && loanValues.renovationBudget !== 0) {
      constructionHoldbackPercentage = (loanValues.constructionHoldback * 100) / loanValues.renovationBudget;
    }
    return constructionHoldbackPercentage.toFixed(0);
  }

}

export default BridgeProfitAndDscrCalculatorFormStore;
