import { Box, Divider, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { useStore } from '@roc/client-portal-shared/hooks';
import { Button, Paper } from '@roc/ui';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { createCheckboxField,createTextField } from '../../../components/formComponents/common/componentBuilder';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(2),
  },
}));

export const SubscriptionDetails = observer(() => {
  const { companyProfileStore } = useStore();
  const formStore = companyProfileStore.subscriptionDetailsStore;

  const classes = useStyles();

  useEffect(() => {
    formStore.loadSubscriptionDetails();
  }, []);

  return (
    <Paper className={classes.paper}>
      {formStore.subscriptionDetails && (
        <Box p={4}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
                            <Box sx={{ pt: 4 }}>
                <Typography variant="h5">Contact Details</Typography>
              </Box>
              <br />
              <Grid >
                <Grid item xs={12}>
                  {createTextField({
                    label: 'Loan Status Mailing List',
                    fieldName: 'loanStatusMailingList',
                    testId: 'loanStatusMailingList',
                    store: formStore
                  })}
                </Grid>
                <br />
                <Grid item xs={12}>
                  {createTextField({
                    label: 'Daily Report Mailing List',
                    fieldName: 'dailyReportMailingList',
                    testId: 'dailyReportMailingList',
                    store: formStore
                  })}
                </Grid>
                <br />
                <Grid item xs={12}>
                  {createTextField({
                    label: 'Extensions Mailing List',
                    fieldName: 'extensionsMailingList',
                    testId: 'extensionsMailingList',
                    store: formStore
                  })}
                </Grid>
                <br />
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ pt: 4 }}>
                  <Divider />
                </Box>
              </Grid>
              <Box sx={{ pt: 4 }}>
                <Typography variant="h5">Other preferences</Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              {createCheckboxField({
                label:
                  'I would like my borrowers to participate in The Home Depot Program',
                fieldName: 'borrowersParticipateInHomeDepotProgram',
                store: formStore,
              })}
            </Grid>
            <Grid item xs={12}>
              {createCheckboxField({
                label: 'Notify borrowers when a construction draw funding is processed',
                fieldName: 'sendDrawBoardedNotification',
                store: formStore,
              })}
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ pt: 4 }}>
                <Divider />
              </Box>
            </Grid>
            <Grid item xs={12} container justifyContent="flex-end">
              <Button
                color="primary"
                variant="contained"
                onClick={() => formStore.saveSubscriptionDetails()}
                testId="save"
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Box>
      )}
    </Paper>
  );
});
