import Typography from "@material-ui/core/Typography";
import {
  Grid,
  Divider,
} from "@material-ui/core";

interface TitleDividerProps {
  title: string;
}

export const TitleDivider = ({ title }: TitleDividerProps) => {
  return (
    <Grid container alignItems="center" spacing={1}>
      <Grid item>
        <Typography variant="subtitle1" style={{ color: "#5E5E5E" }}>
          {title}
        </Typography>
      </Grid>
      <Grid item xs>
        <Divider />
      </Grid>
    </Grid>);
}