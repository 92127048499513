import { FormStore } from '@roc/feature-app-core';
import { GlobalStore } from '@roc/feature-app-core';
import { makeObservable } from 'mobx';
import { OneToolService } from '../../services/oneToolService';
import { formatDate } from '@roc/feature-utils';
import { LoanOriginatorStore } from './loanOriginatorStore';

const closingForm = {
  fields: {
    fastTrack: {
      value: '',
      error: null,
      rule: 'required',
    },
    preferredClosingAttorney: {
      value: 'Hartmann Doherty Rosa Berman & Bulbulia (HDRBB)',
      error: null,
      rule: 'required',
      message: '',
    },
    preferredInsurance: {
      value: null,
      error: null,
      rule: 'required',
      message: 'required',
    },
    requestedClosingDate: {
      type: 'DATE',
      value: null,
      error: null,
      rule: `required|date|after_or_equal:todayDate`,
      format: 'MM/dd/yyyy',
      message: 'Please select a future date.',
    },
    lenderParticipation: {
      value: 'Broker Loan',
      error: null,
      rule: 'required',
    },
    origLenderSubordination: {
      value: 0.0,
      error: null,
      rule: 'required',
    },
    preferredTitle: {
      value: '',
      error: null,
      rule: 'required',
      message: 'required',
    },
    originatorId: {
      value: null,
      error: null,
      rule: '',
      message: '',
    },
    todayDate: {
      value: formatDate(Date.now(), 'yyyy/MM/dd'),
    },
    submitAppraisalWithTamarisk: {
      value: '',
      error: null,
      rule: 'required',
      message: 'required',
    },
  },
  meta: {
    key: null,
    editMode: false,
    isValid: false,
    error: null,
  },
};
export class ClosingFormStore extends FormStore {
  private globalStore: GlobalStore;
  private oneToolService: OneToolService;
  public loanOriginatorStore: LoanOriginatorStore;

  constructor(globalStore) {
    super({ ...closingForm }, globalStore);
    this.globalStore = globalStore;
    this.oneToolService = new OneToolService();
    this.loanOriginatorStore = new LoanOriginatorStore(globalStore);
    this.init();
    makeObservable(this, {});
  }
  init() {
    this.form.meta.editMode = true;
  }
  setEditMode(value) {
    this.form.meta.editMode = value;
  }
}
