import { observer } from 'mobx-react';
import {
  StandardDialog,
} from '@roc/ui';
import { CreditReviewForm, useCreditReviewExternalStore } from '@roc/feature-borrower-review';
import { Typography } from '@material-ui/core';
import { useBackgroundReviewExternalStore } from 'libs/feature-borrower-review/src/hooks/useBackgroundReviewExternalStore';
import { Document } from '@roc/feature-types';
import { useCreditReviewStore } from 'libs/feature-borrower-review/src/hooks/useCreditReviewStore';
import { useUserStore } from '@roc/feature-app-core';


interface BorrowerCreditReviewDialogProps {
  open: boolean;
  onClose: () => void;
  loanId: any;
  document: Document;
  loan?: any;
}

export const BorrowerCreditReviewDialog = observer(({ open, onClose, loanId, document, loan }: BorrowerCreditReviewDialogProps) => {
  const { userStore } = useUserStore();
  const { creditReviewStore } = useCreditReviewExternalStore();
  const { creditItemStore } = creditReviewStore;

  creditItemStore.currentBorrower = document?.borrowerId;
  creditItemStore.loanId = loanId;
  creditItemStore.isInternal = userStore.isNportalUser;
  creditItemStore.loan = loan;
  creditItemStore.initializeService();

  return (
    <StandardDialog
      open={open}
      maxWidth="md"
      handleClose={onClose}
      title={'Credit Review Report'}
      removeContentBox
      dialogContent={
        <CreditReviewForm store={creditItemStore} onClose={onClose} hideTitle={true}
        />
      }
    />)
});