import React, { useEffect, useState } from 'react';
import { Layout } from '@roc/feature-app-core';
import { observer } from 'mobx-react';
import { ReportsList } from './components/reportsList';
import { Box, IconButton } from '@material-ui/core';
import { useStore } from '@roc/client-portal-shared/hooks';
import { StandardDialog } from '@roc/ui';
import { makeStyles } from '@material-ui/core/styles';
import { SigmaDashboardsBaseStore } from './stores/sigmaDashboardsBaseStore';
import { useHistory, useLocation } from 'react-router';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { segmentAnalytics, SegmentTrackerEvent } from '@roc/feature-utils';

const useStyles = makeStyles(() => ({
  wrapperContent: {
    height: 'calc(100vh - 100px)',
    width: '100%',
  },
  frame: {
    height: '100%',
    width: '100%',
    border: 0,
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export const SigmaDashboards = observer(({ sigmaDashboardsStore }: { sigmaDashboardsStore: SigmaDashboardsBaseStore }) => {
  const { globalStore, userStore } = useStore();
  const location = useLocation();
  const [showReportList, setShowReportList] = useState(true);


  const classes = useStyles();
  const history = useHistory();

  const addParamToUrl = (key, value) => {
    const params = new URLSearchParams(location.search);
    params.set(key, value);
    history.push({ search: params.toString() });
  };

  const onReportItemClick = (item) => {
    sigmaDashboardsStore.getDashboardEmbededUrl(item.menuId);
    addParamToUrl('id', item.menuId);
    segmentAnalytics.trackEvent({
      name: SegmentTrackerEvent.SIGMA_REPORTS,
      userName: userStore?.userInformation?.username,
      location: window.location.href,
    });
    setShowReportList(false);
  };

  const params = new URLSearchParams(location.search);
  const id = params.get("id");

  useEffect(() => {
    if (id) {
      sigmaDashboardsStore.getDashboardEmbededUrl(id);
      setShowReportList(false);
    } else {
      setShowReportList(true);
    }
  }, [id, sigmaDashboardsStore]);

const handleBackClick = () => {
  params.delete('id');
  history.push({ search: params.toString() });
  setShowReportList(true);
};

  return (
    <Layout
      title={
        <div className={classes.titleContainer}>
          {!showReportList && (
            <IconButton onClick={handleBackClick} aria-label="back">
              <ArrowBackIcon />
            </IconButton>
          )}
          Sigma Dashboards
        </div>
      }
      maxWidth={showReportList ? 'lg' : 'xl'}
    >
      {showReportList ? (
        <Box mt={2}>
          <ReportsList
            items={globalStore.userFeatures?.availableSigmaDashboards ?? []}
            onReportItemClick={onReportItemClick}
          />
        </Box>
      ) : (
        <div className={classes.wrapperContent}>
          <iframe className={classes.frame} src={sigmaDashboardsStore.embededUrl}></iframe>
        </div>
      )}
    </Layout>
  );
});
