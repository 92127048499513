import { observer } from 'mobx-react';
import React, { useState, useEffect, useRef } from 'react';
import {
  GridColumnProps,
  SelectFilterComponent,
  SelectFloatingFilterComponent,
  SelectMode,
} from '@roc/ui';
import { InfiniteScrollGrid } from '@roc/feature-app-core';
import { useServicerLoansStore } from "../../hooks/useServicerLoansStore";
import { usePayoffRequestStore } from '@roc/feature-loans';

const noOpComparator = () => 0;

const columns: GridColumnProps[] = [
  {
    field: 'dealid',
    headerName: 'Loan Id',
    maxWidth: 125,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'dealName',
    headerName: 'Deal Name',
    minWidth: 275,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
];

export const ForeclosureLoansForPayoffRequestGrid = observer(({ onSelected }) => {
  const { fundedForeclosureLoansForPayoffGridStore } = useServicerLoansStore();
  const [selectedLoan, setSelectedLoan] = useState(null);
  const syncedLoan = useRef(null);
  const { payoffRequestStore } = usePayoffRequestStore();

  const frameworkComponents = {
    selectFilter: SelectFilterComponent,
    selectFloatingFilter: SelectFloatingFilterComponent,
  };

  useEffect(() => {
    onSelected(selectedLoan);
    payoffRequestStore.setIsForeclosure(true);
  }, [selectedLoan]);

  const onRowSelected = e => {
    const { node } = e;
    if (node.data.dealid !== syncedLoan.current && !node.isSelected()) {
      return;
    } else if (node.isSelected()) {
      syncedLoan.current = node.data.dealid;
      setSelectedLoan(node.data.dealid);
    } else {
      syncedLoan.current = node.data.dealid;
      setSelectedLoan(null);
    }
  };

  return (
    <InfiniteScrollGrid
      columns={columns}
      frameworkComponents={frameworkComponents}
      store={fundedForeclosureLoansForPayoffGridStore}
      onRowSelected={(row: any) => onRowSelected(row)}
      selectMode={SelectMode.SINGLE}
    />
  );
});

export default ForeclosureLoansForPayoffRequestGrid;