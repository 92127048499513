import { Service } from "@roc/feature-app-core";
import axios from "axios";


const rcCreds = {
  redirect_uri: '/crm-portal/profile',
  response_type: 'code',
}

export const url = {
  AUTHENTICATE: '/api/v1/vendor/ringCentral/authenticateWithRingCentral',
  INSERT_TEMPLATE: '/api/v1/vendor/ringCentral/insertTemplate',
  UPDATE_TEMPLATE: '/api/v1/vendor/ringCentral/updateTemplate',
  DELETE_TEMPLATE: '/api/v1/vendor/ringCentral/deleteTemplate',
  GET_ALL_TEMPLATES: '/api/v1/vendor/ringCentral/getAllTemplates',
  SEND_SMS: '/api/v1/vendor/ringCentral/sendSMSRequestsList',
  SEND_SMS_TO_SELF: '/api/v1/vendor/ringCentral/sendSMSToSelf',
  OAUTH_ENDPOINT: 'https://platform.ringcentral.com/restapi/oauth/authorize',
  RINGCENTRAL_OAUTH: '/api/v1/vendor/public/ringCentral/ringCentralOAuth',
  GET_ALL_TEMPLATE_BY_USER: '/api/v1/vendor/ringCentral/getAllTemplatesByUser',
  GET_SMS_COUNT: '/api/v1/lender/lead/getSmsCount',
  GET_CREDS: '/api/v1/vendor/ringCentral/getCredentials',
};

declare global {
  interface Window {
    handleOAuthCallback: (returnedCode: string, returnedState: string) => void;
  }
}

export class RingCentralService extends Service {
  insertTemplate(request) {
    return this.post(url.INSERT_TEMPLATE, request);
  }

  deleteTemplate(templateId) {
    return this.post(url.DELETE_TEMPLATE + '?templateId=' + templateId, null);
  }

  updateTemplate(request) {
    return this.post(url.UPDATE_TEMPLATE, request);
  }

  getAllTemplates() {
    return this.get(url.GET_ALL_TEMPLATES);
  }

  sendSMS(sendSMSRequest) {
    return this.post(url.SEND_SMS, sendSMSRequest);
  }

  sendSMSToSelf(sendSMSRequest) {
    return this.post(url.SEND_SMS_TO_SELF, sendSMSRequest);
  }

  getSmsCount() {
    return this.get(url.GET_SMS_COUNT);
  }

  getCreds() {
    return this.get(url.GET_CREDS);
  }

  async retrieveTokenWithOAuthCode(userId, host, clientId) {
    const response_type = 'code';
    const state = userId;
    const client_id = clientId;
    const redirect_uri = host + rcCreds.redirect_uri;

    const urlString = url.OAUTH_ENDPOINT + '?response_type=' + response_type + '&redirect_uri=' + redirect_uri + '&client_id=' + client_id + '&state=' + state;

    window.location.href = urlString;
  }

  handleTokenCallback(code, state) {
    return this.get(url.RINGCENTRAL_OAUTH + '?code=' + code + '&state=' + state);
  }

  getAllTemplatesByUser(pageNumber: number, pageSize: number, sortBy: string, sortDir: string, filters: string, dropdownFilters: string) {
    return this.get(url.GET_ALL_TEMPLATE_BY_USER,
      { pageNumber, pageSize, sortBy, sortDir, filters, dropdownFilters },
      { disableGlobalLoading: true }
    );
  }

}
