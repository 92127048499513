import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AutocompleteField } from '@roc/ui';
import { observer } from 'mobx-react';
import { useState } from 'react';

const useStyles = makeStyles(theme => ({
  entityLabel: {
    display: 'flex',
    alignItems: 'center',
  },
  autoComplete: {
    minWidth: '400px'
  }
}));

export const ProtectiveAdvancesSearchBar = observer(({ counterPartyExpenseStore, label }) => {
  const classes = useStyles();
  const { entityList, entity } = counterPartyExpenseStore;
  const [inputValue, setInputValue] = useState('');

  return (
    <Grid className={classes.entityLabel}>
      <Typography style={{ paddingRight: '16px' }}>{label}: </Typography>
      <AutocompleteField
        label=""
        placeHolder='Search'
        options={entityList}
        value={inputValue}
        onChange={(newValue) => {
          setInputValue(newValue)
          if (newValue === '') {
            counterPartyExpenseStore.onSelectEntity({
              label: 0,
              value: null
            });
          }
        }}
        onSelect={(option: any) => {
          counterPartyExpenseStore.onSelectEntity(option);
          setInputValue(option.label);
        }}
        className={classes.autoComplete}
      />
    </Grid>
  )
});
