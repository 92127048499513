import { makeStyles, Theme } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import { useEffect, useRef, useState } from 'react';
import { useIframeMessaging } from '../../utils/todoUtils';
const ResizeObserver = (window as any).ResizeObserver;
const MutationObserver = (window as any).MutationObserver;

const useStyles = makeStyles((theme: Theme) => ({
  '@global': {
    body: {
      backgroundColor: 'transparent !important',
      overflow: 'hidden',
    },
    '.MuiDialog-scrollPaper': {
      alignItems: 'flex-start',
    },
  },

  virtualScrollContainer: {
    margin: '0 auto',
    alignSelf: 'flex-start',
    backgroundColor: 'white',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

export const VirtualScroll = observer(({ children }) => {
  const classes = useStyles();
  const containerRef = useRef<HTMLDivElement>();
  const [width, setWidth] = useState();

  const { postMessage } = useIframeMessaging({
    scrollTo: ({ posY }) => scrollTo(0, posY),
    updateContainerWidth: ({ containerWidth }) => setWidth(containerWidth),
  });

  const sendContentHeight = () => {
    // Update Max Iframe Height
    const containerRect = containerRef.current.getBoundingClientRect();
    const height = containerRect.height ?? 9999;
    postMessage('updateContentHeight', { height });
  };

  const updateModalOpen = () => {
    const isModalOpen = !!document.getElementsByClassName('MuiBackdrop-root')
      .length;
    postMessage('updateModalOpen', { isModalOpen });
  };

  useEffect(() => {
    if (containerRef.current) {
      const resizeObserver = new ResizeObserver(entries => sendContentHeight());
      resizeObserver.observe(containerRef.current);
      const mutationObserver = new MutationObserver(() => updateModalOpen());
      mutationObserver.observe(document.body, { childList: true });
      return () => {
        resizeObserver.disconnect();
        mutationObserver.disconnect();
      };
    }
  }, [containerRef.current]);

  return (
    <div
      ref={containerRef}
      className={classes.virtualScrollContainer}
      style={{ width: width ? width + 'px' : '100%' }}
    >
      {children}
    </div>
  );
});
