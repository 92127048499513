import { Layout } from '@roc/client-portal-shared/components';
import { useEffect } from 'react';
import { observer } from 'mobx-react';
import { RocUniversityBaseStore } from './stores/rocUniversityBaseStore';
import { AlertDialog, Button, Paper } from '@roc/ui';
import { Box, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    img: {
      maxWidth: 400,
      width: '50%',
      margin: 30,
    },
    button: {
      margin: 50,
      width: '50%',
      maxWidth: 400,
    },
  })
);

export const RocUniversity = observer(
  ({ rocUniversityStore }: { rocUniversityStore: RocUniversityBaseStore }) => {

    const { rocUniversityURL, showAlert, logoLMS } = rocUniversityStore;
    const classes = useStyles();

    const handleClose = () => {
      rocUniversityStore.reset();
      window.top.history.back();
    };

    const openTab = () => {
      window.open(rocUniversityURL);
    }

    useEffect(() => {
      rocUniversityStore.getRocUniversityURL();
    }, []);

    useEffect(() => {
      rocUniversityStore.getLogoLMS();
      return () => {
        rocUniversityStore.reset();
      };
    }, [logoLMS, rocUniversityStore]);

    return (
      <Layout>
        <Paper>
          <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
            <img alt="Roc University" className={classes.img} src={logoLMS} />
            <Button onClick={openTab} className={classes.button} color="secondary" variant="contained" size="large" testId='rocUniversity'>Click here for Roc University</Button>
          </Box>
        </Paper>
        <AlertDialog
          open={showAlert}
          handleClose={handleClose}
          title="Logging Failure"
          body="You are not registered as a user of Roc University. Please reach out to Roc Capital technology team with the issue."
        />
      </Layout>
    );
  }
);
