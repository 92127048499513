import { Grid, Box, Typography } from "@material-ui/core";
import { Card } from "@roc/ui";
import { observer } from "mobx-react";
import { useLoanSummaryStore } from "../../hooks/useLoanSummaryStore";


export const BorrowerCreditComments = observer(() => {
  const { loanSummaryStore } = useLoanSummaryStore();
  const { loanSummaryModalStore } = loanSummaryStore;

  const creditComments = loanSummaryModalStore?.currentBorrower?.creditComment;

  return (
    <Card
      title="Comments"
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box>
            {creditComments ? (
              creditComments.split(";").map((comment, index) => (
                <Typography key={index} variant="body1">
                  {comment.trim()}
                </Typography>
              ))
            ) : (
              <Typography variant="body1">No comments available</Typography>
            )}
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
});
