import { Box, Typography } from '@material-ui/core';
import { green, orange } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { CheckCircle, FindInPage } from '@material-ui/icons';
import { Layout, StepsContainer } from '@roc/client-portal-shared/components';
import { Paper } from '@roc/ui';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useStore } from '../../hooks';
import { CreditAuthorizationState } from '../../types';
import { DocumentVerificationState } from '@roc/feature-types';
import { CreditAuthorizationForm } from './components/creditAuthorizationForm';
import { DocumentSigning } from './components/documentSigning';
import { DocumentVerification } from '@roc/ui';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(3, 0, 4),
    },
    stepper: {
      maxWidth: 900,
      margin: 'auto',
    },
  })
);

const CreditAuthCompleteView = () => {
  return (
    <Box mt={4} mx={'auto'} textAlign="center">
      <CheckCircle style={{ fontSize: 60, color: green[500] }} />
      <br />
      <Typography variant="h4" color="textPrimary">
        Thank you
      </Typography>
      <br />
      <Typography>
        Your credit authorization has been completed successfully
      </Typography>
      <br />
    </Box>
  );
};

const CreditAuthInReviewView = () => {
  return (
    <Box mt={4} mx={'auto'} textAlign="center">
      <FindInPage style={{ fontSize: 60, color: orange[500] }} />
      <br />
      <Typography variant="h4" color="textPrimary">
        Thank you
      </Typography>
      <br />
      <Typography>
        Your credit authorization is being reviewed! We will send you an email
        regarding the status.
      </Typography>
      <br />
    </Box>
  );
};

export const CreditAuthorization = observer(() => {
  const classes = useStyles();
  const { creditAuthorizationStore } = useStore();
  const {
    creditAuthorizationState,
    documentVerificationState,
  } = creditAuthorizationStore;
  const [
    allowNavigationInDocVerifyStep,
    setAllowNavigationInDocVerifyStep,
  ] = useState(false);
  const [
    allowNavigationInDocSigningStep,
    setAllowNavigationInDocSigningStep,
  ] = useState(false);

  useEffect(() => {
    window.addEventListener('message', handleWindowMessage);
    return () => window.removeEventListener('message', handleWindowMessage);
  }, []);

  const handleWindowMessage = (event: MessageEvent) => {
    console.log(event);
    switch (event.data) {
      case 'credit-authorization-docusign-done':
        creditAuthorizationStore.setCreditAuthorizationState(
          CreditAuthorizationState.DONE
        );
        break;
    }
  };

  const steps = [
    {
      title: 'Document Verification',
      icon: null,
      onBack: null,
      onNext: () => {
        creditAuthorizationStore.goNextStep();
      },
      allowBack: false,
      allowNext: allowNavigationInDocVerifyStep,
    },
    {
      title: 'Personal Information',
      icon: null,
      onBack: () => {
        creditAuthorizationStore.goPrevStep();
      },
      onNext: () => {
        creditAuthorizationStore.goNextStep();
      },
      allowBack: true,
      allowNext: true,
    },
    {
      title: 'Credit Authorization',
      icon: null,
      onBack: () => {
        creditAuthorizationStore.goPrevStep();
      },
      onNext: () => {
        // todo
      },
      allowBack: allowNavigationInDocSigningStep,
      allowNext: false,
    },
  ];

  const renderView = () => {
    switch (creditAuthorizationState) {
      case CreditAuthorizationState.DONE:
        if (documentVerificationState == DocumentVerificationState.IN_REVIEW) {
          return <CreditAuthInReviewView />;
        } else {
          return <CreditAuthCompleteView />;
        }
      default:
        return (
          <StepsContainer
            activeStep={creditAuthorizationStore.activeStep}
            disableNavigation={true}
            steps={steps}
            stepperClassName={classes.stepper}
          >
            {creditAuthorizationStore.activeStep === 0 && (
              <DocumentVerification
                store={creditAuthorizationStore}
                setAllowNavigationInDocVerifyStep={
                  setAllowNavigationInDocVerifyStep
                }
              />
            )}
            {creditAuthorizationStore.activeStep === 1 && (
              <CreditAuthorizationForm />
            )}
            {creditAuthorizationStore.activeStep === 2 && <DocumentSigning />}
          </StepsContainer>
        );
    }
  };

  return (
    <Layout maxWidth="md">
      <Box my={3}>
        <Typography variant="h3" gutterBottom>
          {'CREDIT AUTHORIZATION'}
        </Typography>
      </Box>
      <Paper className={classes.paper}>{renderView()}</Paper>
    </Layout>
  );
});
