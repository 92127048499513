import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Box, Toolbar, Typography } from '@material-ui/core';
import { formatCurrency, formatPercentage } from '@roc/ui/utils';
import { Button } from '@roc/ui';

const useStyles = makeStyles({
  table: {
    minWidth: 300,
    '& .MuiTableRow-head th': {
      fontWeight: 'bold',
    },
  },
  tableContainer: {
    maxHeight: 200,
  },
});

function createData(subjectPercent: number, transPerYear: number, daysOnMarket: number, priceCut: number) {
  return { subjectPercent, transPerYear, daysOnMarket, priceCut };
}

const subjectPropertyData = createData(8, 15, 56, 9000);
const trendData = createData(undefined, 12, 40, undefined);

export const MarketLiquidityDataTable = () => {
  const classes = useStyles();

  return (
    <Paper elevation={0} variant='outlined'>
      <Toolbar style={{
        minHeight: 52,
        padding: '8px 16px',
        display: 'flex',
        justifyContent: 'space-between'
      }}>
        <Typography variant="h6" component="div">
          Market Liquidity Data
        </Typography>
        <Box ml={2}>
          <Button testId='liquidityReport' size="small"
            color="default"
            variant="outlined"
            onClick={() => { }}>
            Liquidity Report
          </Button>
        </Box>
      </Toolbar>
      <TableContainer className={classes.tableContainer}>
        <Table className={classes.table} size='small' stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell align="center">Subject</TableCell>
              <TableCell align="center">Trend</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell variant='head' component="th" scope="row" align="left">
                <strong>Subject %</strong>
              </TableCell>
              <TableCell component="th" scope="row" align="center" valign='top'>
                <Box>{subjectPropertyData.subjectPercent ? formatPercentage(subjectPropertyData.subjectPercent) : '-'}</Box>
              </TableCell>
              <TableCell component="th" scope="row" align="center" valign='top'>
                <Box>{trendData.subjectPercent ? formatPercentage(trendData.subjectPercent) : '-'}</Box>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head' component="th" scope="row" align="left">
                <strong>Transactions /year</strong>
              </TableCell>
              <TableCell component="th" scope="row" align="center" valign='top'>
                <Box>{subjectPropertyData.transPerYear ? formatPercentage(subjectPropertyData.transPerYear) : '-'}</Box>
              </TableCell>
              <TableCell component="th" scope="row" align="center" valign='top'>
                <Box>{trendData.transPerYear ? formatPercentage(trendData.transPerYear) : '-'}</Box>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head' component="th" scope="row" align="left">
                <strong>Days on Market</strong>
              </TableCell>
              <TableCell component="th" scope="row" align="center" valign='top'>
                <Box>{subjectPropertyData.daysOnMarket ? subjectPropertyData.daysOnMarket : '-'}</Box>
              </TableCell>
              <TableCell component="th" scope="row" align="center" valign='top'>
                <Box>{trendData.daysOnMarket ? trendData.daysOnMarket : '-'}</Box>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head' component="th" scope="row" align="left">
                <strong>Price Cut</strong>
              </TableCell>
              <TableCell component="th" scope="row" align="center" valign='top'>
                <Box>{subjectPropertyData.priceCut ? formatCurrency(subjectPropertyData.priceCut) : '-'}</Box>
              </TableCell>
              <TableCell component="th" scope="row" align="center" valign='top'>
                <Box>{trendData.priceCut ? formatCurrency(trendData.priceCut) : '-'}</Box>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
