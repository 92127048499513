import { Grid, IconButton, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Paper } from "@roc/ui";
import { useCreateListStore } from "../../hooks/useCreateListStore";
import { observer } from "mobx-react";
import { Close } from "@material-ui/icons";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2),
      minHeigth: theme.spacing(8),
      cursor: 'pointer',
    },
    closeButton: {
      color: theme.palette.grey[500],
    },
    textContainer: {
      maxWidth: '70%',
    }
  })
);

const ItemText = ({ item }) => {
  const classes = useStyles();

  const renderLabelValue = (labelValue, index) => (
    <span key={index}>
      <b>{labelValue.label}</b>
      {index < item.value.length - 1 && ', '}
    </span>
  );
  if (item.type == 'boolean') {
    return (
      <p>
        <b>{item.field}</b>{'  '}{item.operator}{'  '}
        <span>
          <b>{item.value[0] === true ? 'true' : 'false'}</b>
        </span>
      </p>
    )
  }
  return (
    <p>
      <b>{item.field}</b>{'  '}{item.operator}{'  '}
      {Array.isArray(item.value) && item.value.length > 1
        ? item.value.map(renderLabelValue)
        : (
          <span>
            <b>{Array.isArray(item.value) ? item.value[0] : item.value}</b>
          </span>
        )}
    </p>
  );
};

export const FilterItem = observer(({ index, onEdit }) => {
  const classes = useStyles();
  const { createListStore } = useCreateListStore();
  const currentFilter = createListStore.filters[index];
  return (
    <Paper className={classes.paper} onClick={() => onEdit(index)}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography variant="body2">{`${index + 1}.`}</Typography>
        </Grid>
        <Grid item className={classes.textContainer}>
          <ItemText item={currentFilter} />
        </Grid>
        <Grid item>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={() => createListStore.removeFilterByIndex(index)}
          >
            <Close />
          </IconButton>
        </Grid>
      </Grid>
    </Paper>
  )

});