import { Service } from "@roc/feature-app-core";

const url = {
  SEND_PROPERTY_FEEDBACK:
    '/api/v1/property/compTool/sendPropertyFeedback'
}

export class CompToolFeedbackService extends Service {

  sendPropertyFeedback(compToolFeedbackRequest: any) {
    return this.post(url.SEND_PROPERTY_FEEDBACK, compToolFeedbackRequest);
  }

}