import React from 'react';
import { Grid } from '@material-ui/core';
import { StandardDialog, Button } from '@roc/ui';
import { observer } from 'mobx-react';
import { SelectOriginatorGrid } from './selectOriginatorsGrid';
import SelectOriginatorsStore from '../../stores/selectOriginatorStore';

export type SelectOriginatorsDialogProps = {
  store: SelectOriginatorsStore;
};

export const SelectOriginatorsDialog = observer((props: SelectOriginatorsDialogProps) => {
  const { selectDialogState, allowMultiple } = props.store;

  const cancel = () => props.store.closeSelectOriginatorsDialog();
  const confirm = () => props.store.confirmSelectedOriginators();

  return (
    <StandardDialog
      open={selectDialogState !== null}
      title={`Select Originator${allowMultiple ? 's' : ''}`}
      dialogContent={open ? <SelectOriginatorGrid store={props.store.originatorsStore} /> : null}
      dialogActions={
        <Grid container spacing={1} justifyContent="flex-end">
          <Grid item>
            <Button
              onClick={cancel}
              color="primary"
              variant="outlined"
              testId="cancel"
            >
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={confirm}
              color="primary"
              variant="contained"
              testId="confirm"
            >
              Confirm
            </Button>
          </Grid>
        </Grid>
      }
      handleClose={cancel}
    />
  );
});

export default SelectOriginatorsDialog;
