import { configure } from 'mobx';
import { EnvironmentStore, EnvironmentVariables } from './environmentStore';

let _storeInstance;
export abstract class _BaseStore {
  environmentStore: EnvironmentStore;

  constructor(environmentVariables: EnvironmentVariables) {
    if (_storeInstance) {
      const errorMessage =
        'Serious Bug! Cannot create multiple store instances.';
      throw Error(errorMessage);
    }
    _storeInstance = this;
    this.environmentStore = new EnvironmentStore(environmentVariables);
  }
}

export const _getBaseStoreInstance = (): any => {
  if (!_storeInstance) {
    console.warn('There is no store instance available to return.');
  }
  return _storeInstance;
};

configure({
  enforceActions: 'never',
});
