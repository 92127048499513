import React, { useState } from 'react';
import { Box, Button, Grid, Paper, TextField, Typography } from '@material-ui/core';
import { lighten } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import { Layout, useBaseStore } from '@roc/feature-app-core';
import { DataGrid, GridColumnProps } from '../dataGrid';
import { formatCurrency } from '../../utils/loanUtils/loanUtils';
import BorrowerTrackRecord from './borrowerTrackRecord';
import { Tabs } from '@roc/ui';

const useStyles = makeStyles(theme =>
  createStyles({
    paper: {
      borderRadius: theme.spacing(2),
      boxShadow:
        'rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px',
      marginRight: theme.spacing(2),
      backgroundColor: theme.palette.background.default,
      paddingLeft: '20px',
      paddingRight: '20px'
    },
    textField: {
      backgroundColor: 'white',
      shrink: true,
      "& input.Mui-disabled": {
        color: "black"
      }
    },
    table: {
      marginTop: '1.5em',
      marginBottom: '2em'
    },
    tab: {
      marginBottom: theme.spacing(2),
    },
  })
);

const usdAmount: any = params => {
  const value = params.value ?? 0;
  return formatCurrency(Number(value));
};
const noOpComparator = () => 0;
const borrowingEntitiesCol: GridColumnProps[] = [
  {
    field: 'entityName',
    headerName: 'Entity Name',
    minWidth: 200,
    comparator: noOpComparator,
  },
  {
    field: 'entityVerified',
    headerName: 'Verified',
    minWidth: 200,
    comparator: noOpComparator,
  }
];
const verifiedExperienceCols: GridColumnProps[] = [
  {
    field: 'entityName',
    headerName: 'Entity Name',
    minWidth: 150,
    comparator: noOpComparator,
  },
  {
    field: 'purchaseAddress',
    headerName: 'Property Address',
    minWidth: 200,
    comparator: noOpComparator,
  },
  {
    field: 'purchaseCity',
    headerName: 'City',
    minWidth: 150,
    comparator: noOpComparator,
  },
  {
    field: 'purchaseState',
    headerName: 'State',
    minWidth: 100,
    comparator: noOpComparator,
  },
  {
    field: 'purchaseDate',
    headerName: 'Purchase Date',
    minWidth: 120,
    comparator: noOpComparator,
  },
  {
    field: 'purchasePrice',
    headerName: 'Purchase Amount',
    minWidth: 180,
    valueFormatter: usdAmount,
    comparator: noOpComparator,
  },
  {
    field: 'rehabBudget',
    headerName: 'Rehab Budget',
    minWidth: 150,
    valueFormatter: usdAmount,
    comparator: noOpComparator,
  },
  {
    field: 'mortgageAmount',
    headerName: 'Mortgage Amount',
    minWidth: 180,
    valueFormatter: usdAmount,
    comparator: noOpComparator,
  },
  {
    field: 'saleDate',
    headerName: 'Sale Date',
    minWidth: 120,
    comparator: noOpComparator,
  },
  {
    field: 'salePrice',
    headerName: 'Sale Amount',
    minWidth: 150,
    valueFormatter: usdAmount,
    comparator: noOpComparator,
  },
  {
    field: 'exitStrategy',
    headerName: 'Exit Strategy',
    minWidth: 150,
    comparator: noOpComparator,
  },
  {
    field: 'recordSourceType',
    headerName: 'Source Type',
    minWidth: 150,
    comparator: noOpComparator,
  },
  {
    field: 'actions',
    headerName: '',
    minWidth: 80,
    cellRenderer: 'actionCellRenderer',
    comparator: noOpComparator,
  }
];
const propertiesPurchasedCols: GridColumnProps[] = [
  {
    field: 'entityName',
    headerName: 'Entity Name',
    minWidth: 150,
    comparator: noOpComparator,
  },
  {
    field: 'soldTo',
    headerName: 'Buyer Name',
    minWidth: 150,
    comparator: noOpComparator,
  },
  {
    field: 'purchaseAddress',
    headerName: 'Property Address',
    minWidth: 200,
    comparator: noOpComparator,
  },
  {
    field: 'purchaseCity',
    headerName: 'City',
    minWidth: 150,
    comparator: noOpComparator,
  },
  {
    field: 'purchaseState',
    headerName: 'State',
    minWidth: 100,
    comparator: noOpComparator,
  },
  {
    field: 'purchaseDate',
    headerName: 'Purchase Date',
    minWidth: 150,
    comparator: noOpComparator,
  },
  {
    field: 'purchasePrice',
    headerName: 'Purchase Amount',
    minWidth: 180,
    valueFormatter: usdAmount,
    comparator: noOpComparator,
  },
  {
    field: 'rehabBudget',
    headerName: 'Rehab Budget',
    minWidth: 150,
    valueFormatter: usdAmount,
    comparator: noOpComparator,
  },
  {
    field: 'mortgageAmount',
    headerName: 'Mortgage Amount',
    minWidth: 180,
    valueFormatter: usdAmount,
    comparator: noOpComparator,
  },
  {
    field: 'purchasedFrom',
    headerName: 'Seller Name',
    minWidth: 150,
    comparator: noOpComparator,
  },
  {
    field: 'lenderName',
    headerName: 'Lender Name',
    minWidth: 150,
    comparator: noOpComparator,
  },
  {
    field: 'saleDate',
    headerName: 'Sale Date',
    minWidth: 120,
    comparator: noOpComparator,
  },
  {
    field: 'salePrice',
    headerName: 'Sale Amount',
    minWidth: 150,
    valueFormatter: usdAmount,
    comparator: noOpComparator,
  },
  {
    field: 'recordSourceType',
    headerName: 'Source Type',
    minWidth: 150,
    comparator: noOpComparator,
  },
  {
    field: 'isVerificationRequested',
    headerName: 'Verification Requested',
    minWidth: 200,
    comparator: noOpComparator,
  },
];

const boolToStr = (bool) => {
  return bool ? 'Yes' : 'No';
}

const toUpperCase = (grid) => {
  for (let obj of grid) {
    for (let key in obj) {
      if (typeof obj[key] === 'string') {
        obj[key] = obj[key].toUpperCase();
      }
    }
  }
  return grid;
}

export const BorrowerBaseballCard = observer(({ store }) => {
  const { currentBorrower, baseballCardDetails } = store;
  const [selectedTab, setSelectedTab] = useState(0);
  const classes = useStyles();
  const { tab } = classes;

  const onTabSelect = (e: any, index: number): void => {
    setSelectedTab(index);
  };

  const tabs = [
    {
      label: 'VERIFIED PROPERTIES',
    },
    {
      label: 'UNVERIFIED PROPERTIES',
    },
  ].filter(Boolean);

  return (
    <Grid container>
      <Grid item xs={3} spacing={2}>
        <Paper title='Borrower Summary' className={classes.paper}>
          <Layout>
            <Typography variant="h5" align="center">Borrower Summary</Typography>
            <br />
            <TextField disabled InputLabelProps={{ shrink: true }} className={classes.textField} variant="outlined" label="First Name" value={baseballCardDetails ? baseballCardDetails.firstName : ""} />
            <br />
            <TextField disabled InputLabelProps={{ shrink: true }} className={classes.textField} variant="outlined" label="Last Name" value={baseballCardDetails ? baseballCardDetails.lastName : ""} />
            <br />
            <TextField disabled InputLabelProps={{ shrink: true }} className={classes.textField} variant="outlined" label="Blacklisted Borrower" value={boolToStr(baseballCardDetails ? baseballCardDetails.isBlacklistedBorrower : false)} />
            <br />
            <TextField disabled InputLabelProps={{ shrink: true }} className={classes.textField} variant="outlined" label="Bridge Experience Score" value={baseballCardDetails ? baseballCardDetails.bridgeExp : ""} />
            <br />
            <TextField disabled InputLabelProps={{ shrink: true }} className={classes.textField} variant="outlined" label="Rental Experience Score" value={baseballCardDetails ? baseballCardDetails.rentalExp : ""} />
            <br />
            <TextField disabled InputLabelProps={{ shrink: true }} className={classes.textField} variant="outlined" label="General Contractor" value={boolToStr(baseballCardDetails ? baseballCardDetails.isGeneralContractor : false)} />
            <br />
            <TextField disabled InputLabelProps={{ shrink: true }} className={classes.textField} variant="outlined" label="Broker" value={boolToStr(baseballCardDetails ? baseballCardDetails.isBroker : false)} />
            <br />
            <TextField disabled InputLabelProps={{ shrink: true }} className={classes.textField} variant="outlined" label="Credit Report Date" value={baseballCardDetails ? baseballCardDetails.creditReportDate : ""} />
            <br />
            <TextField disabled InputLabelProps={{ shrink: true }} className={classes.textField} variant="outlined" label="Fico Score" value={baseballCardDetails ? baseballCardDetails.fico : ""} />
            <br />
            <TextField disabled InputLabelProps={{ shrink: true }} className={classes.textField} variant="outlined" label="Liquidity" value={formatCurrency(baseballCardDetails ? baseballCardDetails.liquidity : false)} />
            <br />
            <TextField disabled InputLabelProps={{ shrink: true }} className={classes.textField} variant="outlined" label="Verified Experience Count" value={baseballCardDetails ? baseballCardDetails.verifiedExpCount : ""} />
            <br />
            <TextField disabled InputLabelProps={{ shrink: true }} className={classes.textField} variant="outlined" label="Paid Off Experience Count" value={baseballCardDetails ? baseballCardDetails.paidOffExpCount : ""} />
          </Layout>
        </Paper>
      </Grid>
      <Grid item xs={9}>
        <Typography variant="h5" align="center">Borrower Information</Typography>
        <br />
        <BorrowerTrackRecord store={store} isInternal={false} />
      </Grid>
    </Grid>
  );

});

export default BorrowerBaseballCard;
