import { Box, Grid, Paper, Tooltip } from '@material-ui/core';
import { formatDate } from '@roc/feature-utils';
import { formatCurrency } from '@roc/ui/utils';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { FastForward } from '@material-ui/icons';
import { useGeneralTasksStore } from '../../hooks/useGeneralTasksStore';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {},
  tooltip: {
    // backgroundColor: 'transparent',
    fontSize: theme.typography.pxToRem(14),
    maxWidth: '500px',
  },
  icon: {
    color: theme.palette.primary.dark,
    fontSize: '18px',
    marginLeft: theme.spacing(1),
  },
}));

export const GeneralTaskLoanFasttrackTooltip = observer(({ loanId }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const { generalTasksStore } = useGeneralTasksStore();
  const { loanInformation } = generalTasksStore;
  const data = loanInformation[loanId];

  const handleOpen = async () => {
    generalTasksStore.fetchLoanInformation([loanId]);
    setOpen(true);
  };

  return (
    <>
      {data?.fastTracked && (
        <Tooltip
          classes={{
            tooltip: classes.tooltip,
          }}
          open={open && !!data}
          onOpen={handleOpen}
          onClose={() => setOpen(false)}
          arrow
          title={'This loan is Fast Tracked'}
        >
          <FastForward className={classes.icon} />
        </Tooltip>
      )}
    </>
  );
});
