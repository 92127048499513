import React from 'react';
import {

  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,

  Typography,
  useMediaQuery,

} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { Close } from '@material-ui/icons';
import { observer } from 'mobx-react';
import { useTamariskPaymentsStore } from '../hooks/useTamariskPaymentsStore';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    docuSignIframe: {
      border: 0,
      height: 'calc(100vh - 170px)',
      width: '100%',
      [theme.breakpoints.down(960)]: {
        height: 'calc(100vh - 110px)',
      },
      '&.success-page': {
        height: '280px',
        [theme.breakpoints.down(838)]: {
          height: '490px',
        },
      },
    },
  })
);

interface Props {
  onClose: () => void;
  open: boolean;
  successPage: boolean;
}

const TamariskDocusignModal = observer((props: Props) => {
  const { tamariskPaymentsStore } = useTamariskPaymentsStore();
  const { onClose } = props;
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      disableEscapeKeyDown
      fullScreen={fullScreen}
      fullWidth
      maxWidth="lg"
      open={props.open}
      scroll="paper"
      onClose={onClose}
      aria-labelledby="tamarisk-document-dialog"
    >
      <DialogTitle disableTypography>
        <Typography variant="h4">Property Appraisal Disclosure</Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <iframe
          className={`${classes.docuSignIframe} ${props.successPage ? 'success-page' : ''
            }`}
          onLoad={() => { }}
          src={tamariskPaymentsStore.tamariskDocumentUrl}
        />
      </DialogContent>
    </Dialog>
  );
});

export default TamariskDocusignModal;
