import { Box, Link } from '@material-ui/core';
import { VerifyTwoFactorUI } from '@roc/feature-2fa';
import { LoginLogoImg, RefreshTokenExpired, SessionTimeout, useFavIcon, useFontFamilies, UserContextChecker } from '@roc/feature-app-core';
import { getPortalContextPath } from '@roc/feature-utils';
import { InternalServerError2 } from '@roc/ui';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useAuthenticationStore, useBaseStore, useUserStore } from './../../hooks';
import { useLogoutStore } from './../../modules/logout';
import { VerifyTwoFactorLoginPage } from './verifyTwoFactorLoginPage';

const enum ViewState {
  EMPTY,
  TWO_FACTOR,
  ERROR_PAGE,
  MAIN_CONTENT
}

const MainContent = ({ children }) => {
  return (
    <>
      <SessionTimeout />
      <RefreshTokenExpired />
      <UserContextChecker />
      {
        children
      }
    </>
  )
};

export const JwtAuthenticatedView = observer(({ children }) => {

  const { globalStore } = useBaseStore();
  const { authenticationStore } = useAuthenticationStore();
  const { isAuthenticated, twoFactorRequired } = authenticationStore;
  const { logoutStore } = useLogoutStore();
  const { userStore } = useUserStore();
  const { userInformation, showErrorPage } = userStore;
  const { pathname } = useLocation();
  const [viewState, setViewState] = useState<ViewState>(ViewState.EMPTY);

  useFavIcon();
  useFontFamilies(globalStore.isBorrowerPortal ? ["Lato"] : []);

  useEffect(() => {
    authenticationStore.checkAuthentiation();
  }, []);

  useEffect(() => {
    if (twoFactorRequired) {
      setViewState(ViewState.TWO_FACTOR);
    }
    else if (isAuthenticated == false) {
      setViewState(ViewState.EMPTY);
      logoutStore.logout(pathname);
    }
    else if (isAuthenticated == true) {
      userStore.getUserInfoAndEntitlements();
    }
    else {
      // This is valid inital case
      setViewState(ViewState.EMPTY);
    }
  }, [isAuthenticated, twoFactorRequired]);

  useEffect(() => {
    if (showErrorPage) {
      setViewState(ViewState.ERROR_PAGE);
    }
  }, [showErrorPage]);

  useEffect(() => {
    if (userInformation) {
      // check if the user is trying to access correct authenticated route for the user's role
      // for e.g. if `lender` is trying to go to `borrower-portal` routes, then taken them to their dashboard
      const portalContextPath = getPortalContextPath(userInformation.portalName);
      const basePathFromBrowserUrl = pathname.split('/')[1];
      if (!globalStore.routeBaseUrl.includes(portalContextPath)) {
        setViewState(ViewState.EMPTY);
        setTimeout(() => window.location.href = `/${portalContextPath}`, 0);
      }
      // check if the contextPath from url is correct as configured in routeBaseUrl
      else if (!globalStore.routeBaseUrl.includes(basePathFromBrowserUrl)) {
        setViewState(ViewState.EMPTY);
        setTimeout(() => window.location.href = `/${portalContextPath}`, 0);
      }
      else {
        setViewState(ViewState.MAIN_CONTENT);
      }
    }
  }, [userInformation]);

  const renderView = (children) => {
    switch (viewState) {
      case ViewState.EMPTY:
        return null;

      case ViewState.ERROR_PAGE:
        return <InternalServerError2 />;

      case ViewState.TWO_FACTOR:
        return <VerifyTwoFactorLoginPage />;

      case ViewState.MAIN_CONTENT:
        return (
          <MainContent>
            {children}
          </MainContent>
        );

      default:
        return null;
    }
  };

  return (renderView(children));
});
