import { Grid, Tooltip, Typography } from "@material-ui/core";
import { StandardDialog, TextField, Button, ConfirmDialog, SelectField } from "@roc/ui";
import { useLenderPortalStore } from "apps/lender-portal/src/app/hooks/useLenderPortalStore";
import { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ConfirmationButton } from "@roc/ui";
import { observer } from "mobx-react";
import { isNil } from "@roc/feature-utils";

export type ACHFormProps = {
  open?: boolean;
  onClose?: () => void;
  loan: any;
};

const useStyles = makeStyles(theme => ({
  instruction: {
    fontWeight: 600,
  },
  underline: {
    fontWeight: 600,
    textDecorationLine: 'underline',
  },
  tooltip: {
    whiteSpace: 'pre-line',
    fontSize: theme.typography.pxToRem(14),
    maxWidth: '200px',
  },
}));

export const ACHFormDialog = observer((props: ACHFormProps) => {
  const { achFormStore } = useLenderPortalStore();
  useEffect(() => {
    if (props.open && !isNil(props.loan)) {
      achFormStore.reset();
      achFormStore.getLoanACHData(props.loan.loanId, props.loan);
    }
  }, [props.open, props.loan]);

  useEffect(() => {
    if (!isNil(achFormStore.selectedBorrower)) {
      achFormStore.getACHLink(props.loan.loanId);
    }
  }, [achFormStore.selectedBorrower]);

  const classes = useStyles();

  const handleCopy = () => {
    navigator.clipboard.writeText(achFormStore.achUrl)
    achFormStore.globalStore.notificationStore.showSuccessNotification({
      message: 'Link was copied to clipboard!',
    });
  }

  return (
    <>
      <StandardDialog
        open={props.open}
        title="ACH Consent"
        maxWidth="md"
        handleClose={props.onClose}
        dialogContent={
          <>
            <Grid container justifyContent="center">
              {(
                <Grid
                  alignItems="center"
                  container
                  item
                  justifyContent="center"
                  xs={12}
                >
                  <Grid item container xs={11} spacing={2}>
                    <Grid item container alignItems="center" spacing={2}>
                      <Grid item xs={12} container spacing={2}>
                        <Grid item xs={12}>
                          <Typography>
                            To facilitate a smooth and efficient closing, an authorized signatory borrower must complete the ACH consent
                            process as part of underwriting. This should take your borrower no more than 5 minutes.
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography>
                            The consent process is accessed through a link shared with your borrower. You can copy
                            and send the link to your borrower manually, or you can easily send the link to your
                            borrower in an email via the portal.
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    {achFormStore.borrowerOptions.length === 0 &&
                      < Grid item xs={12}>
                        <Typography color="error">
                          Please make sure there is at least one authorized signatory borrower for this action.
                        </Typography>
                      </Grid>
                    }
                    {achFormStore.borrowerOptions.length > 0 &&
                      <>
                        {achFormStore.borrowerOptions.length === 1 ? (
                          <>
                            <Grid item lg={12} >
                              <TextField
                                testId="selectedBorrower"
                                variant="outlined"
                                standaloneLabel
                                label="Authorized Signatory Borrower"
                                value={achFormStore.currentAuthSignatoryBorrower.fullName}
                                disabled={true}
                                fullWidth
                              />
                            </Grid>
                            <Grid item lg={12} >
                              <TextField
                                testId="selectedBorrower"
                                variant="outlined"
                                standaloneLabel
                                value={achFormStore.currentAuthSignatoryBorrower.emailAddress}
                                disabled={true}
                                fullWidth
                              />
                            </Grid>

                            <Grid item container justifyContent="flex-start" spacing={2}>
                              <Grid item xs={12}>
                                <TextField
                                  testId="achUrl"
                                  variant="outlined"
                                  value={achFormStore.achUrl}
                                  fullWidth
                                  disabled
                                />
                              </Grid>
                            </Grid>
                          </>
                        ) : (
                          <>
                            <Grid item xs={12} sm>
                              <SelectField
                                standaloneLabel
                                name={'selectedBorrower'}
                                value={achFormStore.selectedBorrower}
                                options={achFormStore.borrowerOptions}
                                onChange={value => achFormStore.setSelectedBorrower(value)}
                                variant="outlined"
                                fullWidth
                                shrink
                                required
                                testId="selectedBorrower"
                              />
                            </Grid>
                            {!isNil(achFormStore.selectedBorrower) &&
                              <Grid item container justifyContent="flex-start" spacing={2}>
                                <Grid item xs={12}>
                                  <TextField
                                    testId="achUrl"
                                    variant="outlined"
                                    value={achFormStore.achUrl}
                                    fullWidth
                                    disabled
                                  />
                                </Grid>
                              </Grid>
                            }
                          </>
                        )}
                        <Grid item container justifyContent="flex-start" spacing={2}>
                          <Grid item xs={12}>
                            <Typography className={classes.instruction}>
                              Select Link Delivery Method
                            </Typography>
                          </Grid>
                          <Grid item xs={4} sm={3}>
                            <Tooltip title="Copy the link and then share it with your borrower manually" arrow placement="left" classes={{ tooltip: classes.tooltip, }}>
                              <div>
                                <Button
                                  variant="contained"
                                  color="secondary"
                                  onClick={() => handleCopy()}
                                  testId="copyACHEmail"
                                  fullWidth
                                >
                                  COPY LINK
                                </Button>
                              </div>
                            </Tooltip>
                          </Grid>
                          <Grid item xs={4} sm={3}>
                            <Tooltip title="Automatically email the link to your borrower" arrow placement="right" classes={{ tooltip: classes.tooltip, }}>
                              <div>
                                <ConfirmationButton
                                  variant="contained"
                                  onClick={() => achFormStore.sendACHEmail(props.loan.loanId)}
                                  color="primary"
                                  testId="sendACHEmail"
                                  confirmDialogProps={{
                                    title: 'Confirmation',
                                    body: 'This will send an email containing the link to the ACH consent process to your borrower.',
                                    cancelButtonText: 'Go Back',
                                    confirmButtonText: 'Ok'
                                  }}
                                  fullWidth
                                >
                                  Send Email
                                </ConfirmationButton>
                              </div>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </>
                    }
                    <>

                    </>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </>}
      />
    </>
  );
});