import { TableContainer, Grid, Table, TableCell, TableHead, TableRow, TableBody, Typography } from '@material-ui/core';
import { CurrencyField, DateField, NumberFormat, SelectField, TextField, AutocompleteField } from "@roc/ui";
import { appraisalTableStyles } from '../utils/utils';
import { observer } from 'mobx-react';
import { AppraisalReviewStore } from '../stores';
import { formatDate } from "@roc/feature-utils";
import {
  attachmentTypeOptions, foundationOptions, exteriorWallsOptions, neighborhoodOptions,
  propertyTypeOptions, locationOptions, propertyConditionOptions
}
  from './appraisalReviewConstants';
import { trueFalseOptions } from '@roc/ui';


export const AppraisalReviewTermForm = observer(({ store }: { store: AppraisalReviewStore }) => {
  const classes = appraisalTableStyles();
  const { appraisalReviewTermSummaryFormStore } = store;
  const { appraisalReviewDate, address, grossLeasableRentableArea, bedrooms, bathrooms, annualTaxesPerAppraisal,
    appraisalAnnualHoaFees, attachmentType, appraisalFormUsed, appraisalValue1, appraisalDate1, appraiserId, appraisalAMC, cdaValue,
    cdaDate, appraisalRent, replacementCost, ownerOfRecord, appraisalValue2, appraisalDate2, design, stories, foundation, exteriorWalls,
    useCode, county, legalDescription, section, lot, block, parcel, appraisalFloodZone, location, yearBuilt, propertyCondition, lotSizeSqFt,monthlyMarketRent
  } = appraisalReviewTermSummaryFormStore.form.fields;
  return (
    <Grid container>
      <Grid item xs={12} md={6}>
        <TableContainer>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell className={classes.headerCell}>
                  Item
                </TableCell>
                <TableCell className={classes.headerCell}>
                  Review
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody className={classes.bodyRow}>
              <TableRow>
                <TableCell style={{ padding: '10px' }}>
                  <Typography className={classes.fixedWidthText}>
                    Review Date
                  </Typography>
                </TableCell>
                <TableCell style={{ padding: '10px' }}>
                  <DateField
                    testId={'reviewDate'}
                    inputVariant="outlined"
                    format='MM/dd/yyyy'
                    value={appraisalReviewDate.value}
                    onChange={(value) => appraisalReviewTermSummaryFormStore.onFieldChange('appraisalReviewDate', value ? formatDate(value, 'MM/dd/yyyy') : value)}
                    fullWidth
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ padding: '10px' }}>
                  <Typography className={classes.fixedWidthText}>
                    Property Address
                  </Typography>
                </TableCell>
                <TableCell style={{ padding: '10px' }}>
                  <TextField
                    testId={'address'}
                    value={address.value}
                    fullWidth
                    disabled
                    variant="outlined"
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ padding: '10px' }}>
                  <Typography className={classes.fixedWidthText}>
                    Stories
                  </Typography>
                </TableCell>
                <TableCell style={{ padding: '10px' }}>
                  <TextField
                    testId={'stories'}
                    value={stories.value}
                    type='number'
                    onChange={e => appraisalReviewTermSummaryFormStore.onFieldChange('stories', e.target.value)}
                    fullWidth
                    variant="outlined"
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ padding: '10px' }}>
                  <Typography className={classes.fixedWidthText}>
                    Foundation
                  </Typography>
                </TableCell>
                <TableCell style={{ padding: '10px' }}>
                  <SelectField
                    options={foundationOptions}
                    testId={'foundation'}
                    value={foundation.value}
                    onChange={value => appraisalReviewTermSummaryFormStore.onFieldChange('foundation', value)}
                    fullWidth
                    variant="outlined"
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ padding: '10px' }}>
                  <Typography className={classes.fixedWidthText}>
                    Exterior Walls
                  </Typography>
                </TableCell>
                <TableCell style={{ padding: '10px' }}>
                  <SelectField
                    options={exteriorWallsOptions}
                    testId={'exteriorWalls'}
                    value={exteriorWalls.value}
                    onChange={value => appraisalReviewTermSummaryFormStore.onFieldChange('exteriorWalls', value)}
                    fullWidth
                    variant="outlined"
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ padding: '10px' }}>
                  <Typography className={classes.fixedWidthText}>
                    Use Code
                  </Typography>
                </TableCell>
                <TableCell style={{ padding: '10px' }}>
                  <SelectField
                    options={propertyTypeOptions}
                    testId={'useCode'}
                    value={useCode.value}
                    onChange={value => appraisalReviewTermSummaryFormStore.onFieldChange('useCode', value)}
                    fullWidth
                    variant="outlined"
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ padding: '10px' }}>
                  <Typography className={classes.fixedWidthText}>
                    County
                  </Typography>
                </TableCell>
                <TableCell style={{ padding: '10px' }}>
                  <TextField
                    testId={'county'}
                    value={county.value}
                    fullWidth
                    variant="outlined"
                    onChange={e => appraisalReviewTermSummaryFormStore.onFieldChange('county', e.target.value)}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ padding: '10px' }}>
                  <Typography className={classes.fixedWidthText}>
                    Legal Description
                  </Typography>
                </TableCell>
                <TableCell style={{ padding: '10px' }}>
                  <TextField
                    testId={'legalDescription'}
                    value={legalDescription.value}
                    fullWidth
                    variant="outlined"
                    onChange={e => appraisalReviewTermSummaryFormStore.onFieldChange('legalDescription', e.target.value)}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ padding: '10px' }}>
                  <Typography className={classes.fixedWidthText}>
                    Section
                  </Typography>
                </TableCell>
                <TableCell style={{ padding: '10px' }}>
                  <TextField
                    testId={'legalDescrsectioniption'}
                    value={section.value}
                    fullWidth
                    variant="outlined"
                    onChange={e => appraisalReviewTermSummaryFormStore.onFieldChange('section', e.target.value)}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ padding: '10px' }}>
                  <Typography className={classes.fixedWidthText}>
                    Lot
                  </Typography>
                </TableCell>
                <TableCell style={{ padding: '10px' }}>
                  <TextField
                    testId={'lot'}
                    value={lot.value}
                    fullWidth
                    variant="outlined"
                    onChange={e => appraisalReviewTermSummaryFormStore.onFieldChange('lot', e.target.value)}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ padding: '10px' }}>
                  <Typography className={classes.fixedWidthText}>
                    Block
                  </Typography>
                </TableCell>
                <TableCell style={{ padding: '10px' }}>
                  <TextField
                    testId={'block'}
                    value={block.value}
                    fullWidth
                    variant="outlined"
                    onChange={e => appraisalReviewTermSummaryFormStore.onFieldChange('block', e.target.value)}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ padding: '10px' }}>
                  <Typography className={classes.fixedWidthText}>
                    Parcel
                  </Typography>
                </TableCell>
                <TableCell style={{ padding: '10px' }}>
                  <TextField
                    testId={'parcel'}
                    value={parcel.value}
                    fullWidth
                    variant="outlined"
                    onChange={e => appraisalReviewTermSummaryFormStore.onFieldChange('parcel', e.target.value)}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ padding: '10px' }}>
                  <Typography className={classes.fixedWidthText}>
                    Appraisal Flood Zone
                  </Typography>
                </TableCell>
                <TableCell style={{ padding: '10px' }}>
                  <SelectField
                    options={trueFalseOptions}
                    testId={'appraisalFloodZone'}
                    value={appraisalFloodZone.value}
                    onChange={value => appraisalReviewTermSummaryFormStore.onFieldChange('appraisalFloodZone', value)}
                    fullWidth
                    variant="outlined"
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ padding: '10px' }}>
                  <Typography className={classes.fixedWidthText}>
                    Location
                  </Typography>
                </TableCell>
                <TableCell style={{ padding: '10px' }}>
                  <SelectField
                    options={locationOptions}
                    testId={'location'}
                    value={location.value}
                    onChange={value => appraisalReviewTermSummaryFormStore.onFieldChange('location', value)}
                    fullWidth
                    variant="outlined"
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ padding: '10px' }}>
                  <Typography className={classes.fixedWidthText}>
                    Year Built
                  </Typography>
                </TableCell>
                <TableCell style={{ padding: '10px' }}>
                  <TextField
                    testId={'yearBuilt'}
                    type='number'
                    value={yearBuilt.value}
                    fullWidth
                    variant="outlined"
                    onChange={e => appraisalReviewTermSummaryFormStore.onFieldChange('yearBuilt', e.target.value)}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ padding: '10px' }}>
                  <Typography className={classes.fixedWidthText}>
                    Property Condition
                  </Typography>
                </TableCell>
                <TableCell style={{ padding: '10px' }}>
                  <SelectField
                    options={propertyConditionOptions}
                    testId={'propertyCondition'}
                    value={propertyCondition.value}
                    onChange={value => appraisalReviewTermSummaryFormStore.onFieldChange('propertyCondition', value)}
                    fullWidth
                    variant="outlined"
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ padding: '10px' }}>
                  <Typography className={classes.fixedWidthText}>
                    Lot Size (sqft)
                  </Typography>
                </TableCell>
                <TableCell style={{ padding: '10px' }}>
                  <TextField
                    testId={'lotSizeSqFt'}
                    type='number'
                    value={lotSizeSqFt.value}
                    fullWidth
                    variant="outlined"
                    onChange={e => appraisalReviewTermSummaryFormStore.onFieldChange('lotSizeSqFt', e.target.value)}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ padding: '10px' }}>
                  <Typography className={classes.fixedWidthText}>
                    Gross Building Area
                  </Typography>
                </TableCell>
                <TableCell style={{ padding: '10px' }}>
                  <TextField
                    testId={'grossLeasableRentableArea'}
                    value={grossLeasableRentableArea.value}
                    type='number'
                    onChange={e => appraisalReviewTermSummaryFormStore.onFieldChange('grossLeasableRentableArea', e.target.value)}
                    fullWidth
                    variant="outlined"
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ padding: '10px' }}>
                  <Typography className={classes.fixedWidthText}>
                    Bedrooms
                  </Typography>
                </TableCell>
                <TableCell style={{ padding: '10px' }}>
                  <TextField
                    testId={'bedrooms'}
                    value={bedrooms.value}
                    type='number'
                    onChange={e => appraisalReviewTermSummaryFormStore.onFieldChange('bedrooms', e.target.value)}
                    fullWidth
                    variant="outlined"
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <Grid item xs={12} md={6}>
        <TableContainer>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell className={classes.headerCell}>
                  Item
                </TableCell>
                <TableCell className={classes.headerCell}>
                  Review
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody className={classes.bodyRow}>
              <TableRow>
                <TableCell style={{ padding: '10px' }}>
                  <Typography className={classes.fixedWidthText}>
                    Bathrooms
                  </Typography>
                </TableCell>
                <TableCell style={{ padding: '10px' }}>
                  <TextField
                    testId={'bathrooms'}
                    type='number'
                    value={bathrooms.value}
                    onChange={e => appraisalReviewTermSummaryFormStore.onFieldChange('bathrooms', e.target.value)}
                    fullWidth
                    variant="outlined"
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ padding: '10px' }}>
                  <Typography className={classes.fixedWidthText}>
                    Annual Taxes (per Appraisal)
                  </Typography>
                </TableCell>
                <TableCell style={{ padding: '10px' }}>
                  <CurrencyField
                    testId={'annualTaxesPerAppraisal'}
                    value={annualTaxesPerAppraisal.value}
                    onChange={(name, value) => appraisalReviewTermSummaryFormStore.onFieldChange('annualTaxesPerAppraisal', value)}
                    fullWidth
                    variant="outlined"
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ padding: '10px' }}>
                  <Typography className={classes.fixedWidthText}>
                    Annual HOA Fees
                  </Typography>
                </TableCell>
                <TableCell style={{ padding: '10px' }}>
                  <CurrencyField
                    testId={'appraisalAnnualHoaFees'}
                    value={appraisalAnnualHoaFees.value}
                    onChange={(name, value) => appraisalReviewTermSummaryFormStore.onFieldChange('appraisalAnnualHoaFees', value)}
                    fullWidth
                    variant="outlined"
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ padding: '10px' }}>
                  <Typography className={classes.fixedWidthText}>
                    Attachment Type
                  </Typography>
                </TableCell>
                <TableCell style={{ padding: '10px' }}>
                  <SelectField
                    options={attachmentTypeOptions}
                    testId={'attachmentType'}
                    value={attachmentType.value}
                    onChange={value => appraisalReviewTermSummaryFormStore.onFieldChange('attachmentType', value)}
                    fullWidth
                    variant="outlined"
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ padding: '10px' }}>
                  <Typography className={classes.fixedWidthText}>
                    Appraisal Form Used
                  </Typography>
                </TableCell>
                <TableCell style={{ padding: '10px' }}>
                  <TextField
                    testId={'appraisalFormUsed'}
                    value={appraisalFormUsed.value}
                    onChange={e => appraisalReviewTermSummaryFormStore.onFieldChange('appraisalFormUsed', e.target.value)}
                    fullWidth
                    variant="outlined"
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ padding: '10px' }}>
                  <Typography className={classes.fixedWidthText}>
                    Appraised Value 1
                  </Typography>
                </TableCell>
                <TableCell style={{ padding: '10px' }}>
                  <CurrencyField
                    testId={'appraisalValue1'}
                    value={appraisalValue1.value}
                    onChange={(name, value) => appraisalReviewTermSummaryFormStore.onFieldChange('appraisalValue1', value)}
                    fullWidth
                    variant="outlined"
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ padding: '10px' }}>
                  <Typography className={classes.fixedWidthText}>
                    Effective Appraisal Date
                  </Typography>
                </TableCell>
                <TableCell style={{ padding: '10px' }}>
                  <DateField
                    testId={'appraisalDate1'}
                    inputVariant="outlined"
                    format='MM/dd/yyyy'
                    value={appraisalDate1.value}
                    onChange={(value) => appraisalReviewTermSummaryFormStore.onFieldChange('appraisalDate1', value ? formatDate(value, 'MM/dd/yyyy') : value)}
                    fullWidth
                  />
                </TableCell>
              </TableRow>
              {store.dynamicDropdownOptions &&
                <>
                  <TableRow>
                    <TableCell style={{ padding: '10px' }}>
                      <Typography className={classes.fixedWidthText}>
                        Appraiser Firm
                      </Typography>
                    </TableCell>
                    <TableCell style={{ padding: '10px' }}>
                      <AutocompleteField
                        label=''
                        placeHolder='Select Appraiser Firm'
                        options={store.getDynamicDropdownOptions('appraiserFirmId')}
                        value={store.currentFirmLabel ?? ''}
                        onChange={(newValue) => {
                          store.handleAppraiserFirmChange(newValue);
                        }}
                        onSelect={(option: any) => {
                          store.handleAppraiserFirmSelected('appraiserFirmId', option);
                        }}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ padding: '10px' }}>
                      <Typography className={classes.fixedWidthText}>
                        Appraiser Name
                      </Typography>
                    </TableCell>
                    <TableCell style={{ padding: '10px' }}>
                      <SelectField
                        options={store.getDynamicDropdownOptions('appraiserId')}
                        testId={'appraiserId'}
                        value={appraiserId.value}
                        onChange={value => appraisalReviewTermSummaryFormStore.onFieldChange('appraiserId', value)}
                        fullWidth
                        variant="outlined"
                      />
                    </TableCell>
                  </TableRow>
                </>}
              <TableRow>
                <TableCell style={{ padding: '10px' }}>
                  <Typography className={classes.fixedWidthText}>
                    Appraisal AMC
                  </Typography>
                </TableCell>
                <TableCell style={{ padding: '10px' }}>
                  <TextField
                    testId={'appraisalAMC'}
                    value={appraisalAMC.value}
                    onChange={e => appraisalReviewTermSummaryFormStore.onFieldChange('appraisalAMC', e.target.value)}
                    fullWidth
                    variant="outlined"
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ padding: '10px' }}>
                  <Typography className={classes.fixedWidthText}>
                    ARR/CDA Value
                  </Typography>
                </TableCell>
                <TableCell style={{ padding: '10px' }}>
                  <CurrencyField
                    testId={'cdaValue'}
                    value={cdaValue.value}
                    onChange={(name, value) => appraisalReviewTermSummaryFormStore.onFieldChange('cdaValue', value)}
                    fullWidth
                    variant="outlined"
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ padding: '10px' }}>
                  <Typography className={classes.fixedWidthText}>
                    ARR/CDA Effective Date
                  </Typography>
                </TableCell>
                <TableCell style={{ padding: '10px' }}>
                  <DateField
                    testId={'cdaDate'}
                    inputVariant="outlined"
                    format='MM/dd/yyyy'
                    value={cdaDate.value}
                    onChange={(value) => appraisalReviewTermSummaryFormStore.onFieldChange('cdaDate', value ? formatDate(value, 'MM/dd/yyyy') : value)}
                    fullWidth
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ padding: '10px' }}>
                  <Typography className={classes.fixedWidthText}>
                    Monthly Market Rent
                  </Typography>
                </TableCell>
                <TableCell style={{ padding: '10px' }}>
                  <CurrencyField
                    testId={'monthlyMarketRent'}
                    value={monthlyMarketRent.value}
                    onChange={(name, value) => appraisalReviewTermSummaryFormStore.onFieldChange('monthlyMarketRent', value)}
                    fullWidth
                    variant="outlined"
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ padding: '10px' }}>
                  <Typography className={classes.fixedWidthText}>
                    Replacement Cost
                  </Typography>
                </TableCell>
                <TableCell style={{ padding: '10px' }}>
                  <CurrencyField
                    testId={'replacementCost'}
                    value={replacementCost.value}
                    onChange={(name, value) => appraisalReviewTermSummaryFormStore.onFieldChange('replacementCost', value)}
                    fullWidth
                    variant="outlined"
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ padding: '10px' }}>
                  <Typography className={classes.fixedWidthText}>
                    Owner Record
                  </Typography>
                </TableCell>
                <TableCell style={{ padding: '10px' }}>
                  <TextField
                    testId={'ownerOfRecord'}
                    value={ownerOfRecord.value}
                    onChange={e => appraisalReviewTermSummaryFormStore.onFieldChange('ownerOfRecord', e.target.value)}
                    fullWidth
                    variant="outlined"
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ padding: '10px' }}>
                  <Typography className={classes.fixedWidthText}>
                    Appraised Value 2
                  </Typography>
                </TableCell>
                <TableCell style={{ padding: '10px' }}>
                  <CurrencyField
                    testId={'appraisalValue2'}
                    value={appraisalValue2.value}
                    onChange={(name, value) => appraisalReviewTermSummaryFormStore.onFieldChange('appraisalValue2', value)}
                    fullWidth
                    variant="outlined"
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ padding: '10px' }}>
                  <Typography className={classes.fixedWidthText}>
                    Appraisal Date 2
                  </Typography>
                </TableCell>
                <TableCell style={{ padding: '10px' }}>
                  <DateField
                    testId={'appraisalDate2'}
                    inputVariant="outlined"
                    format='MM/dd/yyyy'
                    value={appraisalDate2.value}
                    onChange={(value) => appraisalReviewTermSummaryFormStore.onFieldChange('appraisalDate2', value ? formatDate(value, 'MM/dd/yyyy') : value)}
                    fullWidth
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ padding: '10px' }}>
                  <Typography className={classes.fixedWidthText}>
                    Property Design
                  </Typography>
                </TableCell>
                <TableCell style={{ padding: '10px' }}>
                  <TextField
                    testId={'design'}
                    value={design.value}
                    onChange={e => appraisalReviewTermSummaryFormStore.onFieldChange('design', e.target.value)}
                    fullWidth
                    variant="outlined"
                    disabled
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
});