import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { Grid, Theme } from '@material-ui/core';
import { ComparableProperty } from '../../types/propertyTypes';
import { ImagesGrid } from '../subjectProperty/imagesGrid';
import placeHolderImage from "../../images/Placeholder.svg"
import { observer } from 'mobx-react';
import { useStore } from '@roc/client-portal-shared/hooks';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    image: {
      width: '100%',
      height: '350px',
      padding: '10px',
      objectFit: 'cover'
    },
    item: {
      marginTop: '10px',
    },
    imagesGrid: {
      width: '100%',
      height: '100px',
      padding: '0px 5px 0px 0px',
      objectFit: 'cover'
    }
  })
);

export interface IndividualPropertyImagesProps {
  property: ComparableProperty;
}


export const IndividualPropertyImages: React.FC<IndividualPropertyImagesProps> = observer(({
  property
}) => {
  const { compToolStore } = useStore();
  const { compToolPhotosStore } = compToolStore;
  const classes = useStyles();
  const imagesArray = Array(6).fill(placeHolderImage);

  const image = property.data.images.find(image => image.recommended_preview) ? property.data.images.find(image => image.recommended_preview).url : property.data.images[0]?.url

  const imagesGrid = property.data.images.length > 0
    ? [...property.data.images.map(image => image.url), ...imagesArray].slice(0, 6)
    : imagesArray;

  const onError = (event) => {
    event.target.src = placeHolderImage;
  };

  const openIndividualPhoto = (image) => {
    compToolPhotosStore.setSelectedPhoto(image);
    compToolPhotosStore.setOpenIndividualPhoto(true);
  }

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={8} >
          <img
            src={image || placeHolderImage}
            onError={onError}
            loading="lazy"
            className={classes.image}
            onClick={(event) => {
              event.stopPropagation();
              openIndividualPhoto(image);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4} className={classes.item}>
          <ImagesGrid verticalImages={true} images={imagesGrid} imageStyles={classes.imagesGrid} clickablePhotos={true}/>
        </Grid>
      </Grid >
    </>
  );
});