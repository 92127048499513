import { Service } from '@roc/client-portal-shared/services';

const url = {
  MERS_ONBOARDING_DOCUSIGN: '/api/v1/loan/mersOnboarding/createMersOnboardongDocusign',
  CONFIRM_DOCUSIGN: '/api/v1/loan/mersOnboarding/confirmDocusign'
};

export class MersOnboardingService extends Service {
  url;
  constructor() {
    super();
    this.url = url;
  }
  submitMersOnboardingFormToDocusign(request, companyId) {
    return this.post(`${this.url.MERS_ONBOARDING_DOCUSIGN}`, request);
  }

  confirmDocusign(signatoryTitle) {
    return this.post(`${this.url.CONFIRM_DOCUSIGN}?signatoryTitle=${signatoryTitle}`, null);
  }

}