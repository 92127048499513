import { observer } from 'mobx-react';
import { useGeneralTasksStore } from '../../hooks/useGeneralTasksStore';
import { EditTaskDialog } from './editTaskDialog';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useQuery } from '@roc/feature-app-core';
import { DialogState } from '@roc/ui';
import { makeStyles } from '@material-ui/core';
import { GeneralTaskType } from '../../types/generalTasksTypes';

const useStyles = makeStyles((theme) => ({
  '@global': {
    body: {
      backgroundColor: 'transparent !important',
    },
  },
}));

const decodeData = (encodedData: string) => {
  if (!encodedData) {
    return null;
  }
  try {
    return atob(decodeURIComponent(encodedData));
  } catch (e) {
    return encodedData;
  }
}

export const EditTaskDialogInternal = observer(() => {
  const { generalTasksStore } = useGeneralTasksStore();
  const { editTaskStore } = generalTasksStore;
  const { taskId } = useParams<{ taskId: string }>();
  const query = useQuery();
  const [dialogState, setDialogState] = useState<DialogState | null>(null);
  useStyles();

  useEffect(() => {
    if (query.get('openModal') === 'true' && dialogState === null) {
      debugger;
      generalTasksStore.fetchUserInfoAndAssigneeOptions().then(() => {
        editTaskStore.openEditTask(taskId);
      });
      setDialogState(DialogState.EDIT);
      window.parent.postMessage({ action: 'modal-ready' }, '*');
    }
  }, [query, taskId, dialogState]);

  useEffect(() => {
    if (dialogState === DialogState.EDIT && editTaskStore.dialogState === null) {
      window.parent.postMessage({ action: 'close-modal' }, '*');
      setDialogState(null);
    }
  }, [editTaskStore.dialogState]);

  return <EditTaskDialog />;
});
