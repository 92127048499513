import { Box, Tooltip } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Delete, Edit, Person, Warning } from '@material-ui/icons';
import { Button, IconButton, PropertyMap } from '@roc/ui';
import { observer } from 'mobx-react';
import {
  PublicLoanCardDividers,
  PublicLoanCardField,
  PublicLoanCard,
  PublicLoanAccordion,
  PublicLoanCardActions,
} from './loanSummaryComponents';
import { isNumber, LoanSubType, PropertyOwnership } from '@roc/feature-utils';
import { formatCurrency } from '@roc/ui/utils';
import { useState } from 'react';
import { House } from '@material-ui/icons';
import { PublicLoanProperty } from '../../utils/publicLoanTypes';
import { emptyMapPlaceholder } from 'libs/feature-borrower-loan-submission/src/modules/newLoanApplication/assets';
import { useBorrowerLoanSubmissionStore } from '../../hooks/useBorrowerLoanSubmissionStore';

const _formatCurrency = value =>
  isNumber(value) ? formatCurrency(value) : null;

export const PropertiesCard = observer(({ store }) => {
  const { propertiesStore } = store;
  const properties = propertiesStore.getProperties();
  const [accordionIndex, setAccordionIndex] = useState();

  const toggleAccordion = i => {
    setAccordionIndex(i === accordionIndex ? null : i);
  };

  return (
    <PublicLoanCard icon={<House />} title={'Properties'}>
      <Box pt={2}>
        <PublicLoanCardDividers px={2}>
          {properties.map((property, i) => (
            <Box mx={-2}>
              <PublicLoanAccordion
                expanded={accordionIndex === i}
                onChange={() => toggleAccordion(i)}
                summary={property.address}
              >
                <Box flex={1}>
                  <PropertyItem property={property} />
                </Box>
              </PublicLoanAccordion>
            </Box>
          ))}
          {properties.length === 0 && <PropertyPlaceholder />}
        </PublicLoanCardDividers>
      </Box>
    </PublicLoanCard>
  );
});

const PropertyPlaceholder = () => {
  const emptyProperty = {} as PublicLoanProperty;
  return <PropertyItem property={emptyProperty} />;
};

const PropertyItem = observer(
  ({ property }: { property: PublicLoanProperty }) => {
    const { borrowerLoanSubmissionStore } = useBorrowerLoanSubmissionStore();
    const { fixFlipStore } = borrowerLoanSubmissionStore;
    const { loanSubtype } = fixFlipStore;
    const isGroundUp = loanSubtype === LoanSubType.GROUND_UP;
    return (
      <PublicLoanCardDividers py={2}>
        {property.latitude === undefined ? (
          <img
            alt="placeholder"
            src={emptyMapPlaceholder}
            style={{
              width: '100%',
              height: '200px',
              objectFit: 'cover',
            }}
          />
        ) : (
          <PropertyMap
            latitude={property.latitude}
            longitude={property.longitude}
            address={property.address}
          />
        )}
        {isGroundUp && (
          <PublicLoanCardField
            label="Parcel Number"
            value={!property.parcel ? '-' : property.parcel}
          />
        )}
        {isGroundUp && property.numberOfLots && (
          <PublicLoanCardField
            label="Number of Lots"
            value={!property.numberOfLots ? '-' : property.numberOfLots}
          />
        )}
        {isGroundUp && property.isOriginalAddress === false &&
          property.propertyOwnership ==
          PropertyOwnership.OWNED_BY_BORROWER && (
            <PublicLoanCardField
              label="Original Address"
              value={
                !property.originalAddress ? '-' : property.originalAddress
              }
            />
          )}
        {/* <LoanApplicationCardField label="Loan Purpose" value={property.loanPurpose} /> */}
        <PublicLoanCardField
          label="Purchase Price"
          value={
            !property.purchasePrice
              ? '-'
              : _formatCurrency(property.purchasePrice)
          }
        />
        <PublicLoanCardField
          label="Favorable ARM"
          value={
            !property.armsLength
              ? '-'
              : property.armsLength === 'Y'
                ? 'Yes'
                : 'No'
          }
        />
        <PublicLoanCardField
          label="Property Source"
          value={!property.propertySourcing ? '-' : property.propertySourcing}
        />
        <PublicLoanCardField
          label="Renovation Budget"
          value={
            !property.totalRenovationBudget
              ? '-'
              : _formatCurrency(property.totalRenovationBudget)
          }
        />
        <PublicLoanCardField
          label="Desired Renovation"
          value={
            (property.describeRenovation || []).length === 0
              ? '-'
              : (property.describeRenovation || []).map(item => item.value).join(', ')
          }
        />
        {/* <LoanApplicationCardField
          label="Being converted to condominiums"
          value={property.}
        /> */}
        <PublicLoanCardField
          label="After Renovation Value"
          value={
            !property.afterRepairValue
              ? '-'
              : _formatCurrency(property.afterRepairValue)
          }
        />
        <PublicLoanCardField
          label="Exit Strategy"
          value={!property.exitStrategy ? '-' : property.exitStrategy}
        />
      </PublicLoanCardDividers>
    );
  }
);
