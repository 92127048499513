import { GlobalStore } from '@roc/feature-app-core';
import { ClosingTrackerService } from '../services/closingTrackerService';
import { action, flow, makeObservable, observable } from 'mobx';
import { GridColumnProps } from '@roc/ui';
import { formatCurrency } from '@roc/ui/utils';
import { isNil } from '@roc/feature-utils';

const usdAmount: any = params => {
  if (!isNil(params.value) && !isNaN(params.value) && params.colDef.field !== 'loanId') {
    return formatCurrency(Number(params.value));
  }
};


export class ClosingTrackerStore {
  private globalStore: GlobalStore;
  private closingTrackerService: ClosingTrackerService;
  loading: boolean;

  todaysClosingData: any = [];
  tomorrowExpectedClosingData: any = [];
  monthlyClosingData: any = [];

  totalFundedLoans: any = [];
  totalWaitingLoans: any = [];
  totalNonProjectedFundedLoans: any = [];
  totalRetractedLoans: any = [];
  totalClosingNumbers: any = [];
  totalLoansForLast15Days: any = [];
  totalLastMonthRetractedLoans: any = [];

  todaysClosingColumns: { [key: string]: GridColumnProps[] } = {};
  tomorrowClosingColumns: { [key: string]: GridColumnProps[] } = {};
  monthlyClosingColumns: { [key: string]: GridColumnProps[] } = {};
  constructor(globalStore: GlobalStore) {
    this.globalStore = globalStore;
    this.closingTrackerService = new ClosingTrackerService();

    makeObservable(this, {
      todaysClosingData: observable,
      tomorrowExpectedClosingData: observable,
      monthlyClosingData: observable,
      fetchTodaysClosingData: flow,
      fetchTomorrowExpectedClosingData: flow,
      fetchMonthlyClosingData: flow,
      setTodaysClosingColumns: flow,
      setTomorrowsClosingColumns: flow,
      setMonthlyClosingColumns: flow,
      todaysClosingColumns: observable,
      tomorrowClosingColumns: observable,
      monthlyClosingColumns: observable,
      loading: observable,
      setLoading: action,
      totalFundedLoans: observable,
      totalWaitingLoans: observable,
      totalNonProjectedFundedLoans: observable,
      totalRetractedLoans: observable,
      totalClosingNumbers: observable,
      totalLoansForLast15Days: observable,
      totalLastMonthRetractedLoans: observable
    });
  }

  setLoading(value: boolean) {
    this.loading = value;
  }


  setTotalRows(data: any, columns: any) {
    return [{
      [columns[0].mapping]: "Totals",
      [columns[3].mapping]: data.total1,
      [columns[4].mapping]: data.total2
    }];
  }

  setTodaysTotalRows() {
    this.totalFundedLoans = this.setTotalRows(this.todaysClosingData.fundedLoans, this.todaysClosingData.fundedLoans.columns);
    this.totalWaitingLoans = this.setTotalRows(this.todaysClosingData.waitingLoans, this.todaysClosingData.waitingLoans.columns);
    this.totalNonProjectedFundedLoans = this.setTotalRows(this.todaysClosingData.nonProjectedFundedLoans, this.todaysClosingData.nonProjectedFundedLoans.columns);
    this.totalRetractedLoans = this.setTotalRows(this.todaysClosingData.retractedLoans, this.todaysClosingData.retractedLoans.columns);
  }

  setTomorrowsTotalRows() {
    this.totalClosingNumbers = this.setTotalRows(this.tomorrowExpectedClosingData.closingTomorrow, this.tomorrowExpectedClosingData.closingTomorrow.columns);
  }

  setMonthlyTotalRows() {
    this.totalLoansForLast15Days = [{ [this.monthlyClosingData.last15DaysGrid.columns[0].mapping]: "Totals", [this.monthlyClosingData.last15DaysGrid.columns[1].mapping]: this.monthlyClosingData.last15DaysGrid.total1, [this.monthlyClosingData.last15DaysGrid.columns[2].mapping]: this.monthlyClosingData.last15DaysGrid.total2, [this.monthlyClosingData.last15DaysGrid.columns[3].mapping]: this.monthlyClosingData.last15DaysGrid.total3 }]
    this.totalLastMonthRetractedLoans = this.setTotalRows(this.monthlyClosingData.lastMonthRetractedLoans, this.monthlyClosingData.lastMonthRetractedLoans.columns);

  }

  *fetchTodaysClosingData() {
    try {
      this.setLoading(true);
      const response = yield this.closingTrackerService.getTodayClosingLoansDataGrids();
      const closingTracker = response.data && JSON.parse(response.data?.data);
      const mapData = [];
      Object.keys(closingTracker).forEach(key => {
        mapData.push({ [key]: closingTracker[key] && JSON.parse(closingTracker[key]) })
      });
      this.todaysClosingData = mapData.reduce((result, item) => {
        const key = Object.keys(item)[0];
        result[key] = item[key];
        return result;
      }, {});
      this.setTodaysClosingColumns();
      this.setTodaysTotalRows();
      this.setLoading(false);
      return this.todaysClosingData;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: `Error while today's closing data.`,
      });
    }
  };

  *fetchTomorrowExpectedClosingData() {
    try {
      this.setLoading(true);
      const response = yield this.closingTrackerService.getTomorrowExpectedClosingLoansDataGrids();
      const tomorrowsData = response.data && JSON.parse(response.data?.data);
      const mapData = [];
      Object.keys(tomorrowsData).forEach(key => {
        mapData.push({ [key]: tomorrowsData[key] && JSON.parse(tomorrowsData[key]) })
      });
      this.tomorrowExpectedClosingData = mapData.reduce((result, item) => {
        const key = Object.keys(item)[0];
        result[key] = item[key];
        return result;
      }, {});
      this.setTomorrowsClosingColumns();
      this.setTomorrowsTotalRows();
      this.setLoading(false);
      return this.tomorrowExpectedClosingData;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: `Error while fetching next business day's expected closing data.`,
      });
    }
  };

  *fetchMonthlyClosingData() {
    try {
      this.setLoading(true);
      const response = yield this.closingTrackerService.getMonthlyClosingLoansDataGrids();
      const closingTracker = response.data && JSON.parse(response.data?.data);
      const mapData = [];
      Object.keys(closingTracker).forEach(key => {
        mapData.push({ [key]: closingTracker[key] && JSON.parse(closingTracker[key]) })
      });
      this.monthlyClosingData = mapData.reduce((result, item) => {
        const key = Object.keys(item)[0];
        result[key] = item[key];
        return result;
      }, {});
      this.setMonthlyClosingColumns();
      this.setMonthlyTotalRows();
      this.setLoading(false);
      return this.monthlyClosingData;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: `Error while monthly closing data.`,
      });
    }
  };

  *setTodaysClosingColumns() {
    this.todaysClosingColumns.totalClosed = this.generateTableColumns(this.todaysClosingData.totalClosed);
    this.todaysClosingColumns.netFunded = this.generateTableColumns(this.todaysClosingData.netFunded);
    this.todaysClosingColumns.fundedLoans = this.generateTableColumns(this.todaysClosingData.fundedLoans);
    this.todaysClosingColumns.waitingAdjournedLoans = this.generateTableColumns(this.todaysClosingData.waitingLoans);
    this.todaysClosingColumns.nonProjectedFundedLoans = this.generateTableColumns(this.todaysClosingData.nonProjectedFundedLoans);
    this.todaysClosingColumns.retractedLoans = this.generateTableColumns(this.todaysClosingData.retractedLoans);
  }

  *setTomorrowsClosingColumns() {
    this.tomorrowClosingColumns.totalClosedForToday = this.generateTableColumns(this.tomorrowExpectedClosingData.totalClosedForToday);
    this.tomorrowClosingColumns.futureFundRequirement = this.generateTableColumns(this.tomorrowExpectedClosingData.futureFundRequirement);
    this.tomorrowClosingColumns.closingTomorrow = this.generateTableColumns(this.tomorrowExpectedClosingData.closingTomorrow);
  }

  *setMonthlyClosingColumns() {
    this.monthlyClosingColumns.last15DaysGrid = this.generateTableColumns(this.monthlyClosingData.last15DaysGrid);
    this.monthlyClosingColumns.lastMonthRetractedLoans = this.generateTableColumns(this.monthlyClosingData.lastMonthRetractedLoans);
    this.monthlyClosingColumns.monthlyLoanStatistics = this.generateTableColumns(this.monthlyClosingData.monthlyLoanStatistics);
    this.monthlyClosingColumns.monthlyStatsiticsForLastMonthGrid = this.generateTableColumns(this.monthlyClosingData.monthlyStatsiticsForLastMonthGrid);
  }


  private generateTableColumns(data: any): GridColumnProps[] {
    const noOpComparator = () => 0;
    return Object.keys(data.columns).map(key => ({
      field: data.columns[key].mapping,
      headerName: data.columns[key].name,
      minWidth: 150,
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      valueFormatter: usdAmount
    }));
  }

}
