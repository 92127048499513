import { action, makeObservable, observable } from 'mobx';
import { GlobalStore } from '@roc/feature-app-core';
import { FormStore } from '@roc/feature-app-core';
import { parseAddress } from '@roc/feature-utils';

const insuranceForm = {
  fields: {
    name: {
      value: '',
      error: null,
      rule: '',
    },
    contactName: {
      value: '',
      error: null,
      rule: '',
    },
    cellPhone: {
      value: '',
      error: null,
      rule: 'regex:/^\\(?\\d{3}\\)?[\\s.-]?\\d{3}[\\s.-]?\\d{4}$/',
      message: 'Cellphone must be a number with the format xxx-xxx-xxxx.',
    },
    emailAddress: {
      value: '',
      error: null,
      rule: 'email',
      message: 'Email address format is invalid. ',
    },
    address: {
      value: '',
      error: null,
      rule: '',
    },
    streetNumber: {
      value: '',
      error: null,
      rule: '',
    },
    streetName: {
      value: '',
      error: null,
      rule: '',
    },
    city: {
      value: '',
      error: null,
      rule: '',
    },
    state: {
      value: '',
      error: null,
      rule: '',
    },
    zipCode: {
      value: '',
      error: null,
      rule: '',
    },
    latitude: {
      value: 0,
      error: null,
      rule: '',
    },
    longitude: {
      value: 0,
      error: null,
      rule: '',
    },
    cost: {
      value: '',
      error: null,
      rule: '',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export class InsuranceFormStore extends FormStore {
  company;
  cost: number;
  constructor(globalStore: GlobalStore) {
    super({ ...insuranceForm }, globalStore);
    makeObservable(this, {
      company: observable,
      cost: observable,
      saveAddressField: action,
    });
  }

  initialize(insuranceCompany) {
    const { company, cost } = insuranceCompany;
    this.company = company;
    this.cost = cost;
    this.loadForm(this.getFormValuesFromCompany(company, cost));
    this.runFormValidation();
  }

  private getFormValuesFromCompany(company, cost: number) {
    const { phone, email, ...rest } = company || {};

    return {
      cellPhone: phone,
      emailAddress: email,
      contactName: company?.contacts?.[0]?.fullName,
      cost,
      ...rest,
    };
  }

  saveAddressField(address: string, addressDetails, geometryDetails) {
    const parsedAddress = parseAddress(
      address,
      addressDetails,
      geometryDetails
    );
    this.onFieldChange('address', address);
    this.onFieldChange('streetNumber', parsedAddress.street_number);
    this.onFieldChange('streetName', parsedAddress.street);
    this.onFieldChange('city', parsedAddress.city);
    this.onFieldChange('state', parsedAddress.state);
    this.onFieldChange('zipCode', parsedAddress.zip);
  }
}

export default InsuranceFormStore;
