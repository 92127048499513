export interface TwoFactorInfoResponse {
  twoFactorStatus: TwoFactorStatusType,
  forceTwoFactorSetup: boolean
}

export enum TwoFactorType {
  LOGIN = 'LOGIN',
  LENDER_ATTORNIES = 'LENDER_ATTORNIES',
  LENDERS = 'LENDERS'
}

export enum TwoFactorStatusType {
  ACTIVE = 'ACTIVE',
  NOT_ACTIVE = 'NOT_ACTIVE',
}