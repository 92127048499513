import { observer } from 'mobx-react';
import React, { ReactNode, useState } from 'react';
import { useManageUsersBackUpStore } from '../hooks/useManagerUsersBackUpStore';
import { AgGridColumnProps, DataGrid, SelectFilterComponent, SelectFloatingFilterComponent, SelectField } from '@roc/ui';
import { ManageUsersBackUpStore } from '../stores/manageUsersBackUpStore';

const noOpComparator = () => 0;


const columns: AgGridColumnProps[] = [
  {
    field: 'processorUsername',
    headerName: 'Processor',
    minWidth: 180,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'backUpUsername',
    headerName: 'Back Up Processor',
    cellRenderer: 'backUpProcessorCellRenderer',
    minWidth: 260,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: false,
  },
]

export const ManageUsersBackUpsGrid = observer(({ toolbar, manageUsersBackUpStore }: { toolbar: ReactNode, manageUsersBackUpStore: ManageUsersBackUpStore }) => {

  const onSortChanged = params => {
    const sortModel = params.api.getSortModel();
    manageUsersBackUpStore.gridStore.setSortModel(sortModel);
  };

  const onFilterChanged = params => {
    const filterModel = params.api.getFilterModel();
    manageUsersBackUpStore.gridStore.setFilterModel(filterModel);
  };

  const frameworkComponents = {
    selectFilter: SelectFilterComponent,
    selectFloatingFilter: SelectFloatingFilterComponent,
    backUpProcessorCellRenderer: params => {

      const initialProcessorOption = manageUsersBackUpStore.processorOptions.find(
        option => option.value === params.node.data?.backupId
      );

      const defaultOption = manageUsersBackUpStore.processorOptions.find(
        option => option.value === 'default'
      );

      const [selectedBackUpProcessor, setSelectedBackUpProcessor] = useState<any>(
        initialProcessorOption?.value || defaultOption.value
      );

      const processorOptions = manageUsersBackUpStore.processorOptions.filter(processor => processor.value !== params.node.data?.userId
        && !manageUsersBackUpStore.gridStore.gridData.data.rows.find(row => row.backupId === processor.value && row.backupId !== params.node.data?.backupId));

      const handleBackUpProcessorChange = async (value) => {
        setSelectedBackUpProcessor(manageUsersBackUpStore.processorOptions.find(option => option.value === value)?.value);
        if (value === 'default') {
          manageUsersBackUpStore.deleteUserBackUp(params.node.data.userBackupId)
        } else {
          manageUsersBackUpStore.saveUserBackUp(params.node.data.userId, params.node.data.userBackupId, value)
        }
      };

      return <>
        {
          <SelectField
            standaloneLabel
            label={''}
            name={'backUpProcessor'}
            value={selectedBackUpProcessor}
            options={processorOptions}
            onChange={handleBackUpProcessorChange}
            variant="outlined"
            fullWidth
            shrink
            style={{
              marginTop: 5,
              height: 30
            }}
            testId="backUpProcessor"
          />
        }
      </>
    }
  };

  return (
    <>
      <DataGrid
        toolbar={toolbar}
        columns={columns}
        rows={manageUsersBackUpStore.gridStore.gridData.data.rows}
        frameworkComponents={frameworkComponents}
        onSortChanged={onSortChanged}
        onFilterChanged={onFilterChanged}
        isLoading={manageUsersBackUpStore.gridStore.isLoading}
        showFilters={manageUsersBackUpStore.gridStore.showFilters}
        sortModel={manageUsersBackUpStore.gridStore.sortModel}
        filterModel={manageUsersBackUpStore.gridStore.filterModel}
        paginationData={manageUsersBackUpStore.gridStore.gridData.meta}
        setPageNumber={manageUsersBackUpStore.gridStore.setPageNumber}
        rowHeight={60}
        domLayout="autoHeight"
      />
    </>
  );
});