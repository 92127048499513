import { ApiResponse, GlobalStore } from "@roc/feature-app-core";
import { LoanService } from "@roc/feature-loans";
import { formatCurrency, formatPercentage } from "@roc/ui/utils";
import { flow, makeObservable, observable } from "mobx";
import { BreakAllRenderer, ModalRenderer, createData, NoWrapRenderer } from "../loanSummaryNportal/loanColumnsHelper/loanColumnsHelper";
import { breakAllRendererFields, noWrapRendererFields } from "../utils/breakAllRendererFields";
import { LoanSummaryModalStore } from "./loanSummaryModalStore";

export class LoanSummaryStore {
  private globalStore: GlobalStore;
  private loanService: LoanService;
  loanSummaryModalStore: LoanSummaryModalStore;
  loanSummary: any = undefined;

  constructor(globalStore: GlobalStore) {
    this.globalStore = globalStore;
    this.loanService = new LoanService();
    this.loanSummaryModalStore = new LoanSummaryModalStore(this.globalStore, this.loanService);

    makeObservable(this, {
      fetchLoanSummary: flow,
      loanSummary: observable,
    });
  }

  *fetchLoanSummary(loanId) {
    try {
      const apiResponse: ApiResponse = yield this.loanService.getLoanSummary(
        loanId
      );
      const response = JSON.parse(apiResponse.data?.data);
      const convertedResponse = this.convertApiResponse(response);
      if (response.loanId === undefined) {
        throw new Error("Don't have access to the loan");
      }
      this.loanSummary = convertedResponse;
    } catch (error) {
      console.log(error);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching loan details.',
      });
    }
  }

  convertApiResponse(response) {
    return {
      ...response,
      appraisalReviewed: response?.customFields?.appraisalReviewed,
      creditReviewed: response?.customFields?.creditReviewed,
      backgroundReviewed: response?.customFields?.backgroundReviewed
    };
  };

  transformData(originalData, numColumns, loanDetails) {
    const result = {};

    for (let i = 1; i <= numColumns; i++) {
      result[`col${i}`] = [];
    }
    originalData.values.forEach((field, index) => {
      const columnNumber = (index % numColumns) + 1;
      const columnName = `col${columnNumber}`;
      let mapValue = loanDetails && loanDetails.hasOwnProperty(field.mapvalue)
        ? loanDetails[field.mapvalue]
        : '';
      if (loanDetails?.loanPricingData) {
        mapValue = loanDetails.loanPricingData.hasOwnProperty(field.mapvalue)
          ? loanDetails.loanPricingData[field.mapvalue]
          : mapValue;
      }
      if (loanDetails?.calculatedLoanData) {
        console.log(loanDetails?.calculatedLoanData);
        mapValue = loanDetails.calculatedLoanData.hasOwnProperty(field.mapvalue)
          ? loanDetails.calculatedLoanData[field.mapvalue]
          : mapValue;
      }
      mapValue = this.getCustomLoanTermFields(field, mapValue, loanDetails);
      const formattedMapValue = this.formatMapValue(field, mapValue);
      const fieldName = field.fieldname;
      let dataObject;
      if (breakAllRendererFields.includes(fieldName)) {
        dataObject = createData(fieldName, formattedMapValue, BreakAllRenderer);
      } else if (noWrapRendererFields.includes(fieldName)) {
        dataObject = createData(fieldName, formattedMapValue, NoWrapRenderer);
      }
      else {
        dataObject = createData(fieldName, formattedMapValue);
      }
      result[columnName].push(dataObject);
    });
    return result;
  }

  transformDataForCollaterals(property, columns, numColumns) {
    const result = {};
    for (let i = 1; i <= numColumns; i++) {
      result[`col${i}`] = [];
    }
    if (Object.keys(columns).length > 0) {
      columns.forEach((field, index) => {
        const columnNumber = (index % numColumns) + 1;
        const columnName = `col${columnNumber}`;
        let mapValue =
          property.hasOwnProperty(field.mapvalue)
            ? property[field.mapvalue]
            : '';
        if (property?.nsfrLoanPropertyFields) {
          mapValue = property.nsfrLoanPropertyFields.hasOwnProperty(field.mapvalue)
            ? property.nsfrLoanPropertyFields[field.mapvalue]
            : mapValue;
        }
        const fieldName = field.fieldname;
        const formattedMapValue = this.formatMapValue(field, mapValue);
        const dataObject = this.loanSummaryModalStore.modalColumnMap[field.mapvalue]
          ? createData(fieldName, formattedMapValue, ModalRenderer, property, this.loanSummaryModalStore.modalColumnMap[field.mapvalue])
          : createData(fieldName, formattedMapValue);
        result[columnName].push(dataObject);
      });
    }
    return result;
  }

  transformDataForBorrowers(borrower, originalData, numColumns) {
    const result = {};
    for (let i = 1; i <= numColumns; i++) {
      result[`col${i}`] = [];
    }
    originalData.values.forEach((field, index) => {
      const columnNumber = (index % numColumns) + 1;
      const columnName = `col${columnNumber}`;
      const mapValue =
        borrower.hasOwnProperty(field.mapvalue)
          ? borrower[field.mapvalue]
          : '';
      const fieldName = field.fieldname;
      const formattedMapValue = this.formatMapValue(field, mapValue);
      const dataObject = this.loanSummaryModalStore.modalColumnMap[field.mapvalue]
        ? createData(fieldName, formattedMapValue, ModalRenderer, borrower, this.loanSummaryModalStore.modalColumnMap[field.mapvalue])
        : createData(fieldName, formattedMapValue);
      result[columnName].push(dataObject);
    });
    return result;
  }

  transformDataForBorrowerEntity(originalData, numColumns, loanDetails) {
    const result = {};

    for (let i = 1; i <= numColumns; i++) {
      result[`borrowerEntityCol${i}`] = [];
    }
    const borrowerEntity = loanDetails?.loanBorrowerInformation?.borrowerEntity?.company;
    originalData.values.forEach((field, index) => {
      const columnNumber = (index % numColumns) + 1;
      const columnName = `borrowerEntityCol${columnNumber}`;
      const mapValue =
        borrowerEntity &&
          borrowerEntity.hasOwnProperty(field.mapvalue)
          ? borrowerEntity[field.mapvalue]
          : '';
      const fieldName = field.fieldname;
      const formattedMapValue = this.formatMapValue(field, mapValue);
      const dataObject = createData(fieldName, formattedMapValue);
      result[columnName].push(dataObject);
    });
    return result;
  }

  formatMapValue = (field, mapValue) => {
    if (field.specialtype === "percentage") {
      return formatPercentage(mapValue, 2);
    } else if (field.specialtype === "currency") {
      return formatCurrency(mapValue, 2);
    } else if (mapValue === 0) {
      return '0'
    }
    else {
      return mapValue;
    }
  }

  getCustomLoanTermFields(field, mapValue, loan) {
    if (field.mapvalue === 'swapIndex') {
      if (mapValue === 5) {
        mapValue = "5-Year Swaps";
      } else if (mapValue === 10) {
        mapValue = "10-Year Swaps";
      } else if (mapValue === 5001) {
        mapValue = "5 Year US Treasury Rate";
      } else if (mapValue === 5002) {
        mapValue = "10 Year US Treasury Rate";
      }
    }
    if (field.mapvalue === 'sfrTotalOriginationFee') {
      mapValue = (parseFloat(loan.rocPointsIn ? loan.rocPointsIn : 0) +
        parseFloat(loan.loanRetailFees?.retailPoints ? loan.loanRetailFees.retailPoints : 0) +
        parseFloat(loan.brokerPointsIn ? loan.brokerPointsIn : 0) +
        parseFloat(loan.sfrLenderPointsIn ? loan.sfrLenderPointsIn : 0)).toFixed(3);
    }
    if (field.mapvalue === 'totalFees') {
      mapValue = (parseFloat(loan.calculatedLoanData.totalRocFees ? loan.calculatedLoanData.totalRocFees : 0) +
        parseFloat(loan.calculatedLoanData.totalLenderFees ? loan.calculatedLoanData.totalLenderFees : 0)).toFixed(3)
    }
    if (field.mapvalue === 'partnersApprovalRequired' && mapValue === null) {
      return false;
    }
    return mapValue;

  }
}
