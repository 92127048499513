import { Service } from '@roc/feature-app-core';

const url = {
  REQUEST_APPRAISAL_PAYMENT:
    '/api/v1/document/formDocument/requestAppraisalPayment',
  INTERNAL_REQUEST_APPRAISAL_PAYMENT: '/nportal/rest/priv/payments/requestAppraisalPaymentExternal'
};

export class TamariskPaymentService extends Service {
  requestAppraisalPayment = (isInternal: boolean, entityId: any, type: string) => {
    return this.get(isInternal ? url.INTERNAL_REQUEST_APPRAISAL_PAYMENT : url.REQUEST_APPRAISAL_PAYMENT, {
      entityId,
      type,
    });
  };
}
