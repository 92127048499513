import {
  Box,
  Grid,
  List,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { BorrowerPortalCard } from '@roc/feature-borrower-portal-core';
import { isFundedLoan } from '@roc/feature-utils';
import {
  StatusChip,
  StatusType,
} from '@roc/ui';
import { formatCurrency, formatPercentage } from '@roc/ui/utils';
import { observer } from 'mobx-react';
import { useBorrowerLoansStore } from '../../../hooks/useBorrowerLoansStore';
import {
  Dividers,
  EntityAndBorrowersCard,
  listItem
} from './borrowerLoanOverviewComponents';
import { LoanDetailsStepper, stepsByLoanStatus } from '@roc/feature-loan-details';
import { PropertiesCard } from '../properties/propertiesCard';

const useStyles = makeStyles(theme => ({
  paper: {
    marginBottom: theme.spacing(2),
  },
  showPropertiesLink: {
    color: theme.palette.primary.main,
    textDecoration: 'underline',
    fontSize: '16px',
  },
  height100Percent: {
    height: '100%'
  }
}));

export const BorrowerLoanOverviewBridgeLoans = observer(() => {
  const { borrowerLoansStore } = useBorrowerLoansStore();
  const { loanDetails } = borrowerLoansStore;
  const isFundedOrPaidoffLoan = isFundedLoan(loanDetails.status);
  const { loanSubtype } = loanDetails;

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <LoanDetailsStepper
            stepsByStatus={stepsByLoanStatus}
            currentStatus={loanDetails?.status} />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          {isFundedOrPaidoffLoan ? (
            <KeyInformationFundedCard loanDetails={loanDetails} />
          ) : (
            <KeyInformationNotFundedCard loanDetails={loanDetails} />
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          <KeyDatesCard loanDetails={loanDetails} />
        </Grid>
      </Grid>
      <Box mt={1}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <EntityAndBorrowersCard entity={loanDetails?.borrowerEntity} borrowers={loanDetails?.borrowers} />
          </Grid>
          <Grid item xs={12} md={6}>
            <PropertiesCard loanSubtype={loanSubtype} properties={loanDetails?.properties} />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
});

const KeyInformationFundedCard = ({ loanDetails }) => {
  const classes = useStyles();
  return (
    <BorrowerPortalCard cardTitle="Key Information" cardRootClassName={classes.height100Percent} noPaddingCardContent>
      <List>
        <Dividers variant="fullWidth" component="li">
          {listItem('Loan Type', loanDetails?.loanType)}
          {listItem('Loan Subtype', loanDetails?.loanSubType)}
          {listItem(
            'Status',
            <StatusChip
              statusType={StatusType.LOAN}
              label={loanDetails?.status}
              variant="filled"
            />
          )}
          {listItem('Loan Amount', formatCurrency(loanDetails?.amount))}
          {listItem(
            'Initial Loan To Cost',
            formatPercentage(loanDetails?.aggregateLoanToCurrentCostInitial)
          )}
          {listItem('Interest Rate', formatPercentage(loanDetails?.rate))}
          {listItem(<>
            <Typography>Servicer</Typography>
            <Typography variant='body2' color='textSecondary'>
              {`Servicer Id: ${loanDetails?.servicerId}`}
            </Typography>
          </>, loanDetails?.servicer)}
        </Dividers>
      </List>
    </BorrowerPortalCard>
  );
};

const KeyInformationNotFundedCard = ({ loanDetails }) => {
  const classes = useStyles();
  return (
    <BorrowerPortalCard cardTitle="Key Information" cardRootClassName={classes.height100Percent} noPaddingCardContent>
      <List>
        <Dividers variant="fullWidth" component="li">
          {listItem('Loan Type', loanDetails?.loanType)}
          {listItem('Loan Subtype', loanDetails?.loanSubType)}
        </Dividers>
      </List>
    </BorrowerPortalCard>
  );
};


const KeyDatesCard = ({ loanDetails }) => {
  const classes = useStyles();
  return (
    <BorrowerPortalCard cardTitle="Key Dates" cardRootClassName={classes.height100Percent} noPaddingCardContent>
      <List>
        <Dividers variant="fullWidth" component="li">
          {listItem('Submission', loanDetails?.submissionDate)}
          {listItem('Exp. Closing', loanDetails?.expectedClosingDate)}
          {listItem('Agreed Date', loanDetails?.agreedDate)}
          {listItem('Closing Date', loanDetails?.closingDate)}
          {listItem('PayOff Date', loanDetails?.payoffDate)}
          {listItem('Maturity', loanDetails?.maturityDate)}
        </Dividers>
      </List>
    </BorrowerPortalCard >
  );
};