import { observer } from "mobx-react";
import { useStore } from "../../../hooks/useStore";
import { Theme, lighten, makeStyles } from "@material-ui/core/styles";
import { commonPalette } from "libs/feature-app-core/src/modules/portalConfiguration/theme/common";
import { Grid, Typography } from "@material-ui/core";
import { Button } from "@roc/ui";

const useStyles = makeStyles((theme: Theme)=>{
  return {
    paper: {
      margin: theme.spacing(4),
      overflow: 'hidden',
    },
    leftColumn: {
      fontFamily: theme.typography.body2.fontFamily,
      backgroundColor: lighten(commonPalette.primary.main, 0.1),
      padding: theme.spacing(8, 6),
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      marginBottom:'30px'
    },
    rightColumn: {
      padding: theme.spacing(8, 6),
      textAlign: 'center',
    },
    title: {
      ...theme.typography.h1,
      paddingBottom: theme.spacing(2),
      fontSize: '4rem',
      color: theme.palette.getContrastText(commonPalette.primary.main),
    },
    subtitle: {
      ...theme.typography.body2,
      fontWeight: 'bold',
      paddingBottom: theme.spacing(4),
      color: theme.palette.getContrastText(commonPalette.primary.main),
    },
    hello: {
      ...theme.typography.h4,
    },
    text: {
      padding: theme.spacing(8),
      [theme.breakpoints.down('md')]:{
        padding: theme.spacing(4, 0)
      }
    },
    button: {
      width: '160px'
    },
  }
})

export const MersOnboardingWelcome = observer(({disableStart, onStart})=> {
  const classes = useStyles();
  return (
    <Grid container>
      <Grid item xs={12} md={12} lg={4} className={classes.leftColumn}>
        <Typography className={classes.title}>Welcome</Typography>
        <Typography className={classes.subtitle}>
          MERs Onboarding Process
        </Typography>
      </Grid>
      <Grid item md={12} lg={8} className={classes.rightColumn}>
        <Typography className={classes.hello}>
          Click below to start the TPO MERs Onboarding Process
        </Typography>
        <Typography className={classes.text}>
          We have simplified the process to just a few steps to allow you to easily update your funding preferences
        </Typography>
        <Button
          testId="start"
          variant="contained"
          color="primary"
          className={classes.button}
          disabled={disableStart}
          onClick={onStart}
        >
          START
        </Button>
      </Grid>
    </Grid>
  )

})