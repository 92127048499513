import { Service } from '@roc/feature-app-core';

const url = {
  VERIFY_MSA_ZIPCODE: '/api/v1/loan/verifyMSAZipcode',
  CHECK_IF_PROPERTY_IS_RURAL: '/api/v1/pricer/isPropertyRuralCheck'
}

export class MSAService extends Service {
  verifyMSAData(zipCode: string) {
    return this.get(url.VERIFY_MSA_ZIPCODE, { zipCode }, { disableGlobalLoading: true });
  }
  checkIfPropertyIsRural(propertyAddress: string) {
    return this.get(url.CHECK_IF_PROPERTY_IS_RURAL, { propertyAddress }, { disableGlobalLoading: true });
  }
}