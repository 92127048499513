import { Badge, IconButton, Tooltip } from '@material-ui/core';
import { observer } from 'mobx-react';
import { useUserStore, useBaseStore } from '@roc/feature-app-core';
import { useEffect } from 'react';
import { useTechSupportCommunicationStore } from './hooks/useTechSupportCommunicationStore';
import { HelpOutline } from '@material-ui/icons';
import { TechSupportConversation } from './techSupportConversation';


export const TechSupportChatContainer = observer(() => {
  const { userStore } = useUserStore();
  const { globalStore } = useBaseStore();
  const { userId, companyId } = userStore?.userInformation;
  const { techSupportCommunicationStore } = useTechSupportCommunicationStore();
  const { totalTechSupportUnreadMessagesCount } = techSupportCommunicationStore;

  useEffect(() => {
    techSupportCommunicationStore.fetchUnreadMessagesCount(userId, companyId);
  }, []);



  return (
    <>
      <Badge color="error" variant="dot" invisible={totalTechSupportUnreadMessagesCount == 0}>
        <Tooltip title="Support" placement="bottom">
          <IconButton
            aria-label="close"
            onClick={() => {
              if (companyId) {
                techSupportCommunicationStore.createTechSupportChat(companyId)
              } else {
                techSupportCommunicationStore.createTechSupportChat(globalStore.selectedCompanyId)
              }
            }}
            style={{ padding: '0px' }}
          >
            <HelpOutline fontSize='medium' color={'primary'} />
          </IconButton>
        </Tooltip>
      </Badge>
      <TechSupportConversation conversationSid={techSupportCommunicationStore.supportConversationSid} />
    </>
  );
}
);
