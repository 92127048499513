import { Box } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';

import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { CopyText, StandardDialog, Toolbar, FilterButton } from '@roc/ui';

import { LoanDetailsOverviewBridgeLoans } from './loanDetailsOverviewBridgeLoans';
import { useLoanSummaryStore } from '../hooks/useLoanSummaryStore';
import { useParams } from 'react-router';
import { LoanSubType, LoanType } from '@roc/feature-utils';
import { LoanDetailsOverviewTermLoans } from './loanDetailsOverviewTermLoans';
import { LoanDetailsOverviewStabilizedBridgeLoans } from './loanDetailsOverviewStabilizedBridgeLoans';
import { LoanDetailsOverviewMultifamilyBridgeLoans } from './loanDetailsOverviewMultifamilyBridgeLoans';
import { LoanDetailsLayout } from '@roc/feature-loans-shared';
import { TitleHistoryGrid } from './titleHistory/titleHistoryGrid';
import { BorrowerHistoryGrid } from '@roc/feature-borrower-history';
import { BorrowerExperienceComments } from './borrowerComments/borrowerExperienceComments';
import { BorrowerCreditComments } from './borrowerComments/borrowerCreditComments';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      background: '#fff'
    },
  }),
);


export const LoanSummaryExtendedBridgeBySubType = ({ data }) => {
  const loanSubtype = data?.loanSubtype;
  switch (loanSubtype) {
    case LoanSubType.STABILIZED_BRIDGE:
      return <LoanDetailsOverviewStabilizedBridgeLoans loanDetails={data} />;
    case LoanSubType.MULTIFAMILY_BRIDGE_5PLUS:
      return <LoanDetailsOverviewMultifamilyBridgeLoans loanDetails={data} />;
    default:
      return <LoanDetailsOverviewBridgeLoans loanDetails={data} />;
  }
};

export const LoanSummaryExtendedByLoanType = ({ loanDetails }) => {
  const loanType = loanDetails?.loanType;
  switch (loanType) {
    case LoanType.RESIDENTIAL_BRIDGE:
      return <LoanSummaryExtendedBridgeBySubType data={loanDetails} />;
    case LoanType.RESIDENTIAL_TERM:
      return <LoanDetailsOverviewTermLoans loanDetails={loanDetails} />;
    default:
      return <LoanDetailsOverviewBridgeLoans loanDetails={loanDetails} />;
  }
};


export const LoanSummaryExtended = observer(() => {
  const classes = useStyles();
  const { loanSummaryStore } = useLoanSummaryStore();
  const { loanId } = useParams<{ loanId: string }>();

  const handleToggleFilter = () => {
    loanSummaryStore.loanSummaryModalStore.borrowerHistoryBridgeLoans.toggleFilters();
    loanSummaryStore.loanSummaryModalStore.borrowerHistoryRentalLoans.toggleFilters();
    loanSummaryStore.loanSummaryModalStore.loanExposure.toggleFilters();
    loanSummaryStore.loanSummaryModalStore.borrowerPreviousPartners.toggleFilters();
  };

  const handleClearFilter = () => {
    loanSummaryStore.loanSummaryModalStore.borrowerHistoryBridgeLoans.resetFilters();
    loanSummaryStore.loanSummaryModalStore.borrowerHistoryRentalLoans.resetFilters();
    loanSummaryStore.loanSummaryModalStore.loanExposure.resetFilters();
    loanSummaryStore.loanSummaryModalStore.borrowerPreviousPartners.resetFilters();
  };


  useEffect(() => {
    loanSummaryStore.fetchLoanSummary(loanId);
  }, [loanSummaryStore, loanId]);

  return (
    <>
      <Box className={classes.root} display={'flex'} flexDirection={'column'}>
        <LoanDetailsLayout height="100%">
          <Box flexGrow={1}>
            <LoanSummaryExtendedByLoanType
              loanDetails={loanSummaryStore?.loanSummary}
            />
          </Box>
        </LoanDetailsLayout>
      </Box>
      <StandardDialog
        title={'Title History'}
        open={loanSummaryStore.loanSummaryModalStore.titleHistoryOpen}
        handleClose={() => loanSummaryStore.loanSummaryModalStore.closeTitleHistoryModal()}
        maxWidth="lg"
        dialogContent={<TitleHistoryGrid />}
      />
      <StandardDialog
        title={'Borrower History'}
        open={loanSummaryStore.loanSummaryModalStore.borrowerHistoryOpen}
        handleClose={() => loanSummaryStore.loanSummaryModalStore.closeBorrowerHistoryModal()}
        maxWidth="lg"
        dialogContent={<BorrowerHistoryGrid
          toolbar={
            <Toolbar>
              <Box mr={2}>
                <FilterButton
                  onToggleFilters={handleToggleFilter}
                  onClearFilters={handleClearFilter}
                />
              </Box>
            </Toolbar>
          }
        />}
      />

      <StandardDialog
        title={'Experience Comments'}
        open={loanSummaryStore.loanSummaryModalStore.experienceCommentsOpen}
        handleClose={() => loanSummaryStore.loanSummaryModalStore.closeExperienceCommentsModal()}
        maxWidth="lg"
        dialogContent={<BorrowerExperienceComments />}
      />

      <StandardDialog
        title={'Credit Comments'}
        open={loanSummaryStore.loanSummaryModalStore.creditCommentsOpen}
        handleClose={() => loanSummaryStore.loanSummaryModalStore.closeCreditCommentsModal()}
        maxWidth="lg"
        dialogContent={<BorrowerCreditComments />}
      />
    </>
  );
});