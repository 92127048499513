import { DeployEnvironment } from '@roc/feature-types';
import { appLocalStorage, selectedCompanyStorage, isNil, LocalStorageKeys, PortalName, Roles, SupportRoles, APPRAISAL_EDIT_CONFIG_PERMISSION_OLD_VERSIONS, APPRAISAL_REPORT_VERSION_3, APPRAISAL_EDIT_CONFIG_PERMISSION_VERSION_3, READ_EARNINGS_PERMISSION_LIST } from '@roc/feature-utils';
import { computed, flow, makeObservable, observable, action } from 'mobx';
import { ApiResponse, UserService } from '../services';
import { GlobalStore } from './globalStore';
import { ChatRoomType } from '@roc/feature-communication';
import { WhiteLabelStore } from '../modules/portalConfiguration';
interface UserPreference {
  darkMode?: boolean;
  allowLoanPricing?: boolean;
  allowedChatRoomTypes?: ChatRoomType[],
  selectedCompaniesBackofficeUsersNotifications?: number[],
  chatAppNotification?: boolean;
  chatAppNotificationType?: string;
  showMentionsOnly?: boolean; // this is now used to filter mentions tab in LoanChat to show only unread conversations
  mentionsNotifications?: boolean;
  defaultCrmDashboard?: number;
}

interface UserInformation {
  userId: number;
  companyId: number | undefined;
  username: string;
  preferredUsername: string;
  migratedUserId: number;
  firstName: string;
  lastName: string;
  fullName: string;
  emailAddress: string;
  fixAndFlipPricerLink: string;
  portalId: string | number;
  portalName: PortalName;
  userPreference: UserPreference;
  contactId: number;
  roles: Array<string>;
  connectedWithRingcentral: boolean;
  connectedWithLinkedInRoc: boolean;
  connectedWithLinkedInCivic: boolean;
  connectedWithLinkedInFaco: boolean;
  isInternal: boolean;
}

export class UserStore {
  globalStore: GlobalStore;
  private whiteLabelStore: WhiteLabelStore;
  private userService: UserService;
  userInfo: any;
  userInformation: UserInformation;
  userPermissions: any;
  deployEnvironment: DeployEnvironment;
  showErrorPage: Boolean;
  ratings: any[];

  constructor(globalStore, whiteLabelStore) {
    this.globalStore = globalStore;
    this.whiteLabelStore = whiteLabelStore;
    this.userService = new UserService();
    makeObservable(this, {
      userInformation: observable,
      userPermissions: observable,
      showErrorPage: observable,
      deployEnvironment: observable,
      ratings: observable,
      getDeployEnvironment: flow,
      submitUserRating: flow,
      fetchAllRatingsByChatRoomIdAndUserId: flow,
      getUserInfoAndEntitlements: flow,
      getUserPermissions: flow,
      getUserInformation: action,
      saveUserPreference: flow,
      updateNotificationsPreferences: flow,
      updateSelectedCompaniesForNotifications: flow,
      sendErrorEmail: flow,
      darkMode: computed,
      allowLoanPricing: computed,
      isNportalUser: computed,
      isTechUser: computed,
      isSalesUser: computed,
      isClosingUser: computed,
      isTamariskUser: computed,
      selectedCompaniesNotifications: computed,
      setInternalUser: action,
      updatePortalConfiguration: flow,
      canEditAppraisalReportConfig: action,
      isDrawUser: computed,
      isUserAuthorizedToMarkAsComplete: computed,
      isJrOriginator: computed,
      canSeeEarnings: computed,
    });
  }

  private setUserInformation(user, fixAndFlipPricerLink, connectedWithRingcentral = false, connectedWithLinkedInRoc = false, connectedWithLinkedInCivic = false, connectedWithLinkedInFaco = false) {
    this.userInformation = {
      userId: user.userId,
      companyId: user.contact.companyId ?? undefined,
      migratedUserId: user.migratedUserId,
      firstName: user.contact.firstName,
      lastName: user.contact.lastName,
      fullName: user.contact.fullName,
      username: user.userName,
      preferredUsername: user.preferredUsername,
      emailAddress: user.contact.emailAddress,
      fixAndFlipPricerLink: fixAndFlipPricerLink,
      portalId: user.portalId,
      portalName: user.portalName,
      userPreference: user.userPreference ?? {},
      contactId: user.contact.contactId,
      roles: user.roles,
      connectedWithRingcentral: connectedWithRingcentral,
      connectedWithLinkedInRoc: connectedWithLinkedInRoc,
      connectedWithLinkedInCivic: connectedWithLinkedInCivic,
      connectedWithLinkedInFaco: connectedWithLinkedInFaco,
      isInternal: user?.roleObjects[0]?.internal
    };
  }

  private setUserPreference(userPreference) {
    this.userInformation.userPreference = Object.assign({}, this.userInformation.userPreference, userPreference)
  }

  private setUserAllowChatRoom(allowedChatRoomTypes) {
    this.userInformation.userPreference.allowedChatRoomTypes = allowedChatRoomTypes;
  }

  private setSelectedCompaniesForNotifications(selectedCompanies: number[]) {
    this.userInformation.userPreference.selectedCompaniesBackofficeUsersNotifications = selectedCompanies;
  }

  *getDeployEnvironment() {
    try {
      const response: ApiResponse = yield this.userService.getDeployEnvironment();
      const data = response.data?.data as DeployEnvironment;
      this.deployEnvironment = data
    } catch (error) {
      // do nothing
    }
  }

  *submitUserRating(userRatingRequest) {
    try {
      yield this.userService.submitUserRating(userRatingRequest);
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Rating saved succesfully',
      });
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while saving rating',
      });
    }
  }

  *fetchAllRatingsByChatRoomIdAndUserId(chatRoomId, userId) {
    const response: ApiResponse = yield this.userService.fetchRatingsByChatRoomIdAndUserId(chatRoomId, userId);
    this.ratings = response.data;
  }

  handleInitialSelectedCompanyId = (backofficeCompanies) => {
    let actualCompanyId = selectedCompanyStorage.getSelectedCompanyIdFromStorage();
    let actualCompany = backofficeCompanies.find(backofficeCompany => backofficeCompany.companyId == actualCompanyId);
    if (isNil(actualCompany) && backofficeCompanies.length > 0) {
      actualCompanyId = backofficeCompanies[0]?.companyId;
      selectedCompanyStorage.setSelectedCompanyIdToStorage(actualCompany);
    }
    return actualCompanyId;
  }

  *getUserPermissions() {
    try {
      const response: ApiResponse = yield this.userService.getUserInfoAndEntitlements();
      this.userPermissions = response.data?.data?.user?.roleObjects?.flatMap(i => i.rolePermissions);
    }
    catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error retrieving user permissions.',
      });
    }
  }

  *getUserInfoAndEntitlements() {
    if (
      this.globalStore.userCompanies.length == 1 && (this.userInformation ||
        this.globalStore.userFeatures ||
        this.globalStore.lenderInfo)
    ) {
      return;
    }

    try {
      const userResponse: ApiResponse = yield this.userService.getUserInfo();
      this.userInfo = userResponse.data?.data;
      let backofficeCompanies = [];
      if (this.userInfo.backOfficeUser) {
        const response: ApiResponse = yield this.userService.getLenderDropdownOptionsForBackoffice();
        backofficeCompanies = response?.data?.data;
        let actualCompany = this.handleInitialSelectedCompanyId(backofficeCompanies);
        this.globalStore.setSelectedCompanyId(Number(actualCompany));
      }
      this.showErrorPage = false;
      const response: ApiResponse = yield this.userService.getUserInfoAndEntitlements();
      const data = response.data?.data;
      const userPortalId = data?.user?.portalId;
      if (userPortalId && userPortalId != this.globalStore.portalConfiguration.id) {
        console.warn(`User portalId: ${userPortalId} doesn't match with current portalId: ${this.globalStore.portalConfiguration.id}`);
      }
      this.setUserInformation(data?.user, data?.fixAndFlipPricerLink, data?.connectedWithRingcentral, data?.connectedWithLinkedInRoc, data?.connectedWithLinkedInCivic, data?.connectedWithLinkedInFaco);
      let userCompanies = data?.userCompanies || [];
      if (this.isBackOfficeUser) {
        userCompanies = backofficeCompanies;
      }
      this.globalStore.setUserCompanies(userCompanies);
      this.globalStore.setUserFeatures(data?.features);
      this.globalStore.setLenderInfo(data?.lender);
      this.globalStore.setBannerMessages(data?.portalBannerMessages, data?.alertMessages)
      this.globalStore.setImpersonatedUser(data?.impersonatedUser);
      appLocalStorage.setValue(LocalStorageKeys.darkMode, this.userInformation?.userPreference?.darkMode ?? false);
      this.updatePortalConfiguration();
    } catch (error) {
      if (error.error.response.status != 401 && error.error.response.status != 403) {
        this.showErrorPage = true;
      }
    }
  }

  async getUserInformation() {
    const userResponse: ApiResponse = await this.userService.getUserInformation();
    return userResponse.data?.data;
  }

  *updatePortalConfiguration() {
    const portalId = this.globalStore.lenderInfo.portalId;
    selectedCompanyStorage.setSelectedCompanyPortalIdToStorage(portalId);
    yield this.whiteLabelStore.fetchPortalConfiguration();
  }

  *saveUserPreference(userPreferenceAttributes: UserPreference) {
    try {
      const response: ApiResponse = yield this.userService.saveUserPreference(userPreferenceAttributes);
      this.setUserPreference(userPreferenceAttributes);
      appLocalStorage.setValue(LocalStorageKeys.darkMode, userPreferenceAttributes?.darkMode ?? false);
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Success!'
      })
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while saving User Preference.',
      });
    }
  }

  *updateNotificationsPreferences(allowedChatRoomTypes: ChatRoomType[]) {
    try {
      yield this.userService.updateAllowedChatRoomTypes(this.userInformation.userId, allowedChatRoomTypes)
      this.setUserAllowChatRoom(allowedChatRoomTypes)
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Success!'
      })
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while updating the allowed chat room types'
      })
    }
  }

  *updateSelectedCompaniesForNotifications(selectedCompanies: number[]) {
    try {
      yield this.userService.updateSelectedCompaniesForNotifications(selectedCompanies)
      this.setSelectedCompaniesForNotifications(selectedCompanies)
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Success!'
      })
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while updating the company notification options'
      })
    }
  }

  *sendErrorEmail(subject: string, error: string, details?: string) {
    const body =
      `BrowserInfo: ${navigator.userAgent} \n<br/>` +
      `User: ${this.userInformation.username} \n<br/>` +
      `Url: ${location.href} \n<br/>` +
      `Error message: ${error} \n<br/>` +
      `Details: ${details}`;

    yield this.userService.sendErrorEmail(subject, body);
  }

  setInternalUser(user, fixAndFlipPricerLink) {
    try {
      this.setUserInformation(user, fixAndFlipPricerLink);
    } catch (error) {
      console.log(error)
    }
  }

  get darkMode() {
    if (this.userInformation?.userPreference?.darkMode == undefined) {
      return null;
    }
    return this.userInformation.userPreference.darkMode;
  }

  get allowLoanPricing() {
    return (isNil(this.userInformation?.userPreference?.allowLoanPricing) || this.userInformation?.userPreference?.allowLoanPricing) && !this.userInformation?.roles?.includes(Roles.BROKER);
  }

  get isNportalUser() {
    return this.userInformation?.portalName == PortalName.N_PORTAL;
  }

  get isBackOfficeUser() {
    return this.userInformation?.roles?.includes(Roles.BACK_OFFICE_PROCESSOR) || this.userInformation?.roles?.includes(Roles.BACK_OFFICE_ORIGINATOR) || this.userInformation?.roles?.includes(Roles.BACK_OFFICE_LEAD);
  }

  get isBackofficeOriginator() {
    return this.userInformation?.roles?.includes(Roles.BACK_OFFICE_ORIGINATOR);
  }

  get isBackofficeProcesor() {
    return this.userInformation?.roles?.includes(Roles.BACK_OFFICE_PROCESSOR);
  }

  get isMarketingUser() {
    return this.userInformation?.roles?.includes(Roles.MARKETING);
  }

  get isTechUser() {
    return this.userInformation?.roles?.includes(SupportRoles.TECH) || this.userInformation?.roles?.includes(SupportRoles.TECH_ADMIN);
  }

  get isSalesUser() {
    return this.userInformation?.roles?.includes(Roles.BACK_OFFICE_ORIGINATOR) || this.userInformation?.roles?.includes(Roles.BACK_OFFICE_PROCESSOR) || this.userInformation?.roles?.includes(Roles.BACK_OFFICE_LEAD);
  }

  get isLenderUser() {
    return this.userInformation?.roles?.includes(Roles.LENDER) || this.userInformation?.roles?.includes(Roles.LENDER_LEAD) || this.userInformation?.roles?.includes(Roles.ADMIN) || this.userInformation?.roles?.includes(Roles.ORIGINATOR);
  }

  get isClosingUser() {
    return this.userInformation?.roles?.includes(SupportRoles.CLOSING_MANAGER) || this.userInformation?.roles?.includes(SupportRoles.CLOSING_SPECIALIST);
  }

  get isTamariskUser() {
    return this.userInformation?.roles?.includes(Roles.APPRAISAL_TAMARISK_LEAD) || this.userInformation?.roles?.includes(Roles.APPRAISAL_TAMARISK_ASSISTANCE) || this.userInformation?.roles?.includes(Roles.APPRAISAL_TAMARISK_SUPPORT);
  }

  get isExternalUser() {
    return this.isLenderUser || this.isSalesUser;
  }

  get isUserAuthorizedToMarkAsComplete() {
    return this.userInformation?.roles?.includes(SupportRoles.TECH) || this.userInformation?.roles?.includes(SupportRoles.TECH_ADMIN);
  }

  get isDrawUser() {
    return this.userInformation.roles?.includes(Roles.DRAW_ADMIN_SPECIALIST) || this.userInformation.roles?.includes(Roles.DRAW_MANAGER) || this.userInformation.roles?.includes(Roles.DRAW_REVIEW_SPECIALIST);
  }

  get isJrOriginator() {
    return this.globalStore.userFeatures.isLenderOriginator && !this.allowLoanPricing;
  }

  get isSeniorOriginator() {
    return this.globalStore.userFeatures.isLenderOriginator && this.allowLoanPricing;
  }

  get selectedCompaniesNotifications() {
    return this.userInformation?.userPreference?.selectedCompaniesBackofficeUsersNotifications;
  }

  canEditAppraisalReportConfig(version: number) {
    let permissionList = APPRAISAL_EDIT_CONFIG_PERMISSION_OLD_VERSIONS;
    if (version === APPRAISAL_REPORT_VERSION_3) {
      permissionList = APPRAISAL_EDIT_CONFIG_PERMISSION_VERSION_3;
    }
    return permissionList.some(role => this.userInformation?.roles?.includes(role));
  }

  get canSeeEarnings() {
    return READ_EARNINGS_PERMISSION_LIST.some(role => this.userInformation?.roles?.includes(role));
  }
}

export default UserStore;
