import {
  ApiResponse,
  GlobalStore,
  GridStore,
  UserStore,
} from '@roc/feature-app-core';
import { action, flow, makeObservable, observable } from 'mobx';
import { NewLeadService } from '../services/newLeadService';
import { Broker, TPO } from '../utils/leadConstants';
import { UserDetailsStore } from './userDetailsStore';
import {
  BusinessUnit,
  downloadDocument,
  Roles,
} from '@roc/client-portal-shared/utils';
import { NewLeadBaseService } from '../services/newLeadBaseService';

interface UserInformation {
  userId: number;
  roles: Roles[];
  userName: string;
}

const GREGG_KENNEDY_USERNAME = 'gregg.kennedy@civicfs.com';

export abstract class NewLeadBaseStore {
  globalStore: GlobalStore;
  userDetailStore: UserDetailsStore;
  gridStore: GridStore;
  newLeadService: NewLeadService;

  public activeStep: number;
  public stepError: string;
  public leadType: string;
  public userDetails: any;
  public findMatch: boolean;
  public emailMatch: boolean;
  public checkingMail: boolean;
  public dissableSubmit: boolean;
  public fullMatch: boolean;
  public submitNew: boolean;
  public notLoad: boolean;
  public showCheckbox: boolean;
  public emailMatchMsj: string;
  public leadId: string;
  public companyId: string;
  public companyContactId: string;
  public searchLimit: boolean;
  public assignLimit: boolean;
  public showSubmit: boolean;
  public showViewDetails: boolean;
  public openViewLeadsModal: boolean;
  public isInternal: boolean = false;
  public globalSearch: boolean;
  public crmTaskDashboard: boolean;
  public showBackButtton: boolean;
  public showLeadsDashboard: boolean;
  public selectedLeadId: string | null = null;
  public globalSearchTextField: string;
  public renderPossibleMatches: boolean;
  public showSearch: boolean;
  public openBulkLeadChangeOwnerModal: boolean;
  public openChangeRmModal: boolean;
  public noLeadsFound: boolean = false;

  abstract getUserInformation(): any;
  abstract getAllActiveOwners(): any[];
  abstract fetchAllOwners();

  constructor(globalStore, newLeadService: NewLeadBaseService) {
    this.globalStore = globalStore;
    this.newLeadService = newLeadService;
    this.userDetailStore = new UserDetailsStore(globalStore, newLeadService);

    this.gridStore = new GridStore(
      () => this.fetchPossibleMatchesByUserDetails(),
      null,
      20
    );
    this.setDefaults();
    makeObservable(this, {
      activeStep: observable,
      stepError: observable,
      leadType: observable,
      findMatch: observable,
      emailMatch: observable,
      checkingMail: observable,
      userDetails: observable,
      dissableSubmit: observable,
      fullMatch: observable,
      showCheckbox: observable,
      setDefaults: action,
      loadData: flow,
      submitNewLead: flow,
      notLoad: observable,
      searchUserDetails: flow,
      searchUserPossibleMatches: flow,
      emailMatchMsj: observable,
      leadId: observable,
      companyId: observable,
      searchLimit: observable,
      assignLimit: observable,
      showSubmit: observable,
      showViewDetails: observable,
      openViewLeadsModal: observable,
      setOpenViewLeadsModal: flow,
      downloadLeadsTemplate: flow,
      uploadLeads: flow,
      globalSearch: observable,
      setGlobalSearch: flow,
      showBackButtton: observable,
      setShowBackButton: flow,
      showLeadsDashboard: observable,
      setShowLeadsDashboard: flow,
      selectedLeadId: observable,
      setSelectedLeadId: flow,
      globalSearchTextField: observable,
      setGlobalSearchTextField: flow,
      renderPossibleMatches: observable,
      setRenderPossibleMatches: flow,
      saveLeadSearchHistory: flow,
      setShowSearch: flow,
      showSearch: observable,
      openBulkLeadChangeOwnerModal: observable,
      setOpenBulkLeadChangeOwnerModal: flow,
      openChangeRmModal: observable,
      setOpenChangeRmModal: flow,
      noLeadsFound: observable,
      crmTaskDashboard: observable,
      fetchAllOwners: flow,
    });
  }

  setDefaults() {
    this.activeStep = 0;
    this.stepError = '';
    this.leadType = '';
    this.findMatch = false;
    this.emailMatch = true;
    this.checkingMail = true;
    this.userDetails = '';
    this.dissableSubmit = true;
    this.notLoad = true;
    this.showCheckbox = false;
    this.emailMatchMsj = '';
    this.leadId = '';
    this.companyContactId = '';
    this.companyId = '';
    this.showSubmit = false;
    this.showViewDetails = false;
  }

  resetValues() {
    this.emailMatch = true;
    this.notLoad = true;
    this.emailMatchMsj = '';
    this.leadId = '';
    this.companyId = '';
    this.findMatch = false;
    this.showCheckbox = false;
    this.userDetailStore.resetValues();
  }

  private checkStepErrors = () => {
    this.stepError = '';

    switch (this.activeStep) {
      case 1: {
        if (!this.checkingMail) {
          if (!this.userDetailStore.runFormValidationWithMessage()) {
            return true;
          }
          if (!this.leadType) {
            this.stepError = 'Please select a Lead Type';
            return true;
          }
          if (!this.userDetailStore.selectedCompany && [Broker, TPO].includes(this.leadType)) {
            this.stepError = 'Please select a company name';
            return true;
          }

          if (this.userDetailStore.form.fields.phoneNumber.error) {
            this.stepError = this.userDetailStore.form.fields.phoneNumber.error;
            return true;
          }
        }
        break;
      }
      default:
        break;
    }

    return false;
  };

  setActiveStep = step => {
    this.activeStep = step;
  };

  setNoLeadsFound = noLeadsFound => {
    this.noLeadsFound = noLeadsFound;
  };

  setLeadType(value) {
    this.leadType = value;
  }

  goPrevStep = () => {
    this.stepError = '';
    this.gridStore.reset();
    this.activeStep--;
  };

  goToSummary = () => {
    this.findMatch = false;
    this.goNextStep();
  };

  goNextStep = () => {
    this.stepError = '';
    if (!this.checkStepErrors()) {
      this.activeStep++;
    }
    if (this.stepError) {
      this.globalStore.notificationStore.showErrorNotification({
        message: this.stepError,
      });
    }
  };

  private async fetchPossibleMatches() {
    try {
      this.getUserDetails();
      const name =
        this.userDetails?.companyName ||
        `${this.userDetails.firstName ? this.userDetails.firstName : ''} ${this.userDetails.lastName ? this.userDetails.lastName : ''
        }`;

      if (this.userDetails.email) {
        return await this.getPossibleMatchesByFunction(this.userDetails.email);
      } else {
        return await this.getPossibleMatchesByFunction(name);
      }
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching possible matches.',
      });
    }
  }

  private async fetchPossibleMatchesByUserDetails() {
    try {
      this.getUserDetails();
      const inputValue = this.userDetails.userDetails.trim();
      const resp = await this.getPossibleMatchesByFunction(inputValue);
      return resp;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching possible matches by user details.',
      });
    }
  }

  private async getPossibleMatchesByFunction(inputValue) {
    if (inputValue.includes('@') && this.isEmailValidFormat(inputValue)) {
      return await this.newLeadService.possibleMatchesByEmail(
        inputValue,
        this.gridStore.gridData.meta.pageNumber,
        this.gridStore.gridData.meta.pageSize,
        this.gridStore.gridData.meta.sortDir,
        this.gridStore.gridData.meta.sortBy,
        this.gridStore.gridData.meta.filters
      );
    } else if (this.isDomainValidFormat(inputValue)) {
      return await this.newLeadService.possibleMatchesByDomain(
        inputValue,
        this.gridStore.gridData.meta.pageNumber,
        this.gridStore.gridData.meta.pageSize,
        this.gridStore.gridData.meta.sortDir,
        this.gridStore.gridData.meta.sortBy,
        this.gridStore.gridData.meta.filters
      );
    } else if (this.containsOnlyDigits(this.getCleanNumber(inputValue))) {
      return await this.newLeadService.possibleMatchesByPhone(
        inputValue,
        this.gridStore.gridData.meta.pageNumber,
        this.gridStore.gridData.meta.pageSize,
        this.gridStore.gridData.meta.sortDir,
        this.gridStore.gridData.meta.sortBy,
        this.gridStore.gridData.meta.filters
      );
    } else {
      return await this.newLeadService.possibleMatchesByName(
        inputValue,
        this.gridStore.gridData.meta.pageNumber,
        this.gridStore.gridData.meta.pageSize,
        this.gridStore.gridData.meta.sortDir,
        this.gridStore.gridData.meta.sortBy,
        this.gridStore.gridData.meta.filters
      );
    }
  }

  getUserDetails = () => {
    this.userDetails = this.userDetailStore.getUserDetails();
  };

  *loadData() {
    this.notLoad = true;
    if (!this.stepError) {
      yield this.gridStore.fetchGridData();
      yield this.getMatch(this.gridStore.gridData.data.rows);
      this.notLoad = false;
    }
  }

  getRecordType() {
    const recordTypes = this.userDetailStore.recordTypes;
    const matchingRecord = Object.entries(recordTypes)
      .map(([id, value]) => ({ id, value }))
      .find(record => {
        return record.value === this.leadType;
      });
    return matchingRecord ? matchingRecord.value : '';
  }

  get newLeadSubmitJson() {
    return {
      firstName: this.userDetails.firstName,
      lastName: this.userDetails.lastName,
      email: this.userDetails.email,
      phoneNumber: this.userDetails.phoneNumber,
      company: this.userDetails.companyName,
      recordType: this.leadType,
      leadSource: this.userDetails.leadSource,
      status: this.userDetails.status,
      subStatus: this.userDetails.subStatus,
      ownerEmail: this.getOwnerEmailFromName(),
    };
  }

  getOwnerEmailFromName() {
    if (this.isGreggKennedy()) {
      return GREGG_KENNEDY_USERNAME;
    }

    const name = this.userDetails.ownerEmail;
    if (this.isBackofficeLead()) {
      const selected = this.getAllActiveOwners().find(record => {
        return record.label == name;
      });
      return selected.userName || selected.email;
    } else if (this.isBackofficeProcessor()) {
      const selected = this.userDetailStore.owners.find(record => {
        return record.value.includes(name) && record.value.includes(name);
      });
      return selected.userName || selected.email;
    }
  }

  *submitNewLead(isChecked) {
    try {
      let data;

      if (!this.findMatch || this.submitNew) {
        data = this.newLeadSubmitJson;
      }

      let response: ApiResponse;
      let successMessage: string;
      if (isChecked) {
        response = yield this.newLeadService.validateLeadToConvert(data);
        successMessage = `New lead created and converted to ${data.recordType} account successfully`;
      } else {
        response = yield this.newLeadService.submitNewLead(data);
        successMessage = 'New lead created successfully';
      }
      this.handleLeadResponse(response, successMessage);
    } catch (error) {
      console.error('Error submitting new lead:', error);
    }
  }

  handleLeadResponse(response: ApiResponse, successMessage: string) {
    if (response.data?.data?.success === false) {
      const errorMessage = response.data?.data?.message;
      this.globalStore.notificationStore.showErrorNotification({
        message: errorMessage,
      });
    } else {
      this.dissableSubmit = true;
      this.showSubmit = false;
      this.showViewDetails = true;
      if (response.data?.data?.companyContactId) {
        this.companyContactId = response.data?.data?.companyContactId;
      }
      if (response.data?.data?.companyId) {
        this.companyId = response.data?.data?.companyId;
      } else {
        this.leadId = response.data?.data?.leadId;
      }
      this.globalStore.notificationStore.showSuccessNotification({
        message: successMessage,
      });
    }
  }

  getMatch(data) {
    this.dissableSubmit = true;
    this.showCheckbox = true;

    if (data.length !== 0) {
      this.findMatch = true;

      const fullMatch = data.filter(match =>
        match.matchType.includes('Full Match')
      );
      const partialMatchDissabling = data.filter(match =>
        this.isPartialMatch(match)
      );

      if (fullMatch.length !== 0 || partialMatchDissabling.length !== 0) {
        this.dissablingMatchFound();
      } else {
        this.submitNew = true;
        this.dissableSubmit = false;
        this.emailMatch = false;
      }
    } else {
      this.findMatch = false;
      this.dissableSubmit = false;
      this.emailMatch = false;
    }
  }

  isPartialMatch(match) {
    const partialMatchTypes = [
      'Name and Phone Or Email',
      'Phone And Email',
      'Email',
      'Company',
    ];

    return partialMatchTypes.some(type => match.matchType.includes(type));
  }

  getPossibleMatches = () => {
    this.searchUserPossibleMatches();
  };

  sendMakeMeOwnerAlert = () => {
    this.globalStore.notificationStore.showErrorNotification({
      message: 'Accounts/Lender Principals cannot have more than one owner',
    });
  };

  dissablingMatchFound() {
    this.showCheckbox = false;
    this.submitNew = false;
    this.dissableSubmit = true;
    this.emailMatch = true;
  }

  *searchUserDetails() {
    try {
      this.dissablingMatchFound();
      if (this.globalSearch) {
        const resp = yield this.fetchPossibleMatchesByUserDetails();
        if (resp.data.data.totalCount > 0) {
          this.gridStore.fetchGridData();
          this.renderPossibleMatches = true;
          return;
        } else {
          this.renderPossibleMatches = false;
          this.handleNoLeadsFound();
          return;
        }
      } else {
        yield this.gridStore.fetchGridData();
      }

      if (this.gridStore.gridData.data.totalCount > 0) {
        this.loadData();
      } else {
        this.handleNoLeadsFound();
      }
    } catch (error) {
      console.error('Error searching user details:', error);
    }
  }

  handleNoLeadsFound() {
    if (!this.globalSearch) {
      this.gridStore = new GridStore(
        () => this.fetchPossibleMatches(),
        null,
        20
      );
      this.emailMatch = false;
      this.userDetailStore.disabled = false;
      this.dissableSubmit = false;
      this.emailMatchMsj =
        'No leads found with this user details, please continue.';
    }
  }

  *searchUserPossibleMatches() {
    try {
      this.dissablingMatchFound();
      this.gridStore = new GridStore(
        () => this.fetchPossibleMatches(),
        null,
        20
      );

      yield this.gridStore.fetchGridData();

      const hasMatches = this.gridStore.gridData.data.totalCount > 0;

      if (hasMatches) {
        this.loadData();
        this.activeStep = 0;
      } else {
        this.handleNoMatchesFound();
      }
    } catch (error) {
      console.error('Error searching possible matches:', error);
    }
  }

  handleNoMatchesFound() {
    this.findMatch = false;
    this.notLoad = false;
    this.showSubmit = true;
    this.showViewDetails = false;
  }

  handleAssignOwnerSuccess(response, recordId) {
    this.dissableSubmit = true;
    this.leadId = recordId;
    this.globalStore.notificationStore.showSuccessNotification({
      message: 'New owner was added successfully',
    });
    this.searchUserDetails();
  }

  handleSingleAssignOwnerSuccess(response, recordId) {
    this.dissableSubmit = true;
    this.leadId = recordId;
    response?.data?.data &&
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'New owner was added successfully',
      });
    this.searchUserDetails();
  }

  *uploadLeads(documentType, file, userName) {
    try {
      const formData = new FormData();
      formData.append('file', file);
      formData.set('fileType', documentType);
      yield this.newLeadService.uploadLeads(formData, userName);
      this.openViewLeadsModal = true;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while uploading leads, please contact the tech team',
      });
      console.log(error);
    }
  }

  *downloadLeadsTemplate() {
    try {
      const response = yield this.newLeadService.downloadLeadsTemplate();
      downloadDocument(
        response.data,
        response.headers,
        'download',
        'leads_template.xlsx'
      );
      console.log(response);
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message:
          'Error while downloading the leads template, please contact the tech team',
      });
      console.log(error);
    }
  }

  setOpenViewLeadsModal(value) {
    this.openViewLeadsModal = value;
  }

  setGlobalSearch(value) {
    this.globalSearch = value;
  }

  setGlobalSearchTextField(value) {
    this.globalSearchTextField = value;
  }

  setShowLeadsDashboard(value) {
    this.showLeadsDashboard = value;
  }

  setShowBackButton(value) {
    this.showBackButtton = value;
  }

  setSelectedLeadId(value) {
    this.selectedLeadId = value;
  }

  setRenderPossibleMatches(value) {
    this.renderPossibleMatches = value;
  }

  setShowSearch(value) {
    this.showSearch = value;
  }

  setOpenBulkLeadChangeOwnerModal(value) {
    this.openBulkLeadChangeOwnerModal = value;
  }

  setOpenChangeRmModal(value) {
    this.openChangeRmModal = value;
  }

  getCleanNumber(str) {
    const cleanNumber = str.replace(/[\s\+\(\)-]/g, '');
    return cleanNumber;
  }

  containsOnlyDigits(str) {
    return /^[\d\s\(\)-]+$/.test(str);
  }

  isEmailValidFormat(input) {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(input);
  }

  isDomainValidFormat(input) {
    const emailRegex = /^@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return (
      emailRegex.test(input) ||
      (input &&
        (input.includes('.com') ||
          input.includes('.net') ||
          input.includes('.co') ||
          input.includes('.es')))
    );
  }

  isGreggKennedy() {
    const username =
      this.getUserInformation().userName ?? this.getUserInformation().username;
    return username?.startsWith(GREGG_KENNEDY_USERNAME);
  }

  isBackofficeProcessor() {
    return this.getUserInformation().roles.includes(
      Roles.BACK_OFFICE_PROCESSOR
    );
  }

  isBackofficeLead() {
    return this.getUserInformation().roles.includes(Roles.BACK_OFFICE_LEAD);
  }

  isBackofficeOriginator() {
    return this.getUserInformation().roles.includes(
      Roles.BACK_OFFICE_ORIGINATOR
    );
  }

  *saveLeadSearchHistory(userDetails: any) {
    try {
      yield this.newLeadService.saveLeadSearchHistory(userDetails);
    } catch (error) {
      console.log(error);
    }
  }
}
