
import { flow, makeObservable, override } from 'mobx';
import { BorrowersService } from '../services/borrowersService';
import { PropertyFormStore } from './propertyFormStore';


export class PropertyFormExternalStore extends PropertyFormStore {
  private borrowersService;

  constructor(globalStore) {
    super(globalStore);
    this.borrowersService = new BorrowersService();

    makeObservable(this, {
      savePropertyInformation: override,
      editPropertyInformation: override,
      lookupProperty: override,
      saveMultiplePropertyInformation: override
    });
  }

  *savePropertyInformation(data: any) {
    const response = yield this.borrowersService.savePropertyInformation(data);
    return response;
  }

  *editPropertyInformation(data: any) {
    const response = yield this.borrowersService.editPropertyInformation(data);
    return response;
  }

  *lookupProperty(data: any) {

  }

  *saveMultiplePropertyInformation(data: any) {

  }
}