import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { Box, Typography, Grid } from "@material-ui/core";
import { Layout } from "@roc/feature-app-core";
import { BankAccounts } from "@roc/feature-user-profile"
import { Paper } from "@roc/ui";
import { useStore } from "../../hooks";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(3, 0, 4),
    },
  })
);

export const PlaidLiquidityVerificationContainer = () => {
  const { plaidStore } = useStore();
  const classes = useStyles();
  return (
    <Layout maxWidth="lg">
      <Box my={3}>
        <Typography variant="h4" gutterBottom>PLAID LIQUIDITY VERIFICATION </Typography>
      </Box>
      <Paper className={classes.paper}>
        <Grid container justifyContent="center">
          <BankAccounts store={plaidStore} />
        </Grid>
      </Paper>
    </Layout>
  );

}