import { GridStore, GlobalStore, ApiResponse } from "@roc/feature-app-core";
import { GENERIC_ERROR_MESSAGE } from "@roc/feature-utils";
import { GeneralTasksService } from "../services/generalTasksService";
import { CRM_SALES_REMINDER } from "libs/feature-backoffice-users/src/backOfficeUsers/components/leads/constants/constants";
import { action, computed, makeObservable, observable } from "mobx";
import { TaskScope } from "../types/generalTasksTypes";

export class CRMTaskStore {
  globalStore: GlobalStore
  crmTaskGridStore: GridStore;
  generalTasksService: GeneralTasksService;
  public recordIds: string[];


  constructor(globalStore: GlobalStore) {
    this.globalStore = globalStore;
    this.crmTaskGridStore = new GridStore(() => this.fetchMyCRMTasks(), null, 50);
    this.generalTasksService = new GeneralTasksService();
    this.recordIds = [];
    makeObservable(this, {
      setRecordIds: action,
      removeRecordsIds: action,
      recordIds: observable,
      // resetLeadsIds: flow,
      selectedMixedRecords: computed,
    });


  }

  fetchMyCRMTasks = async () => {
    try {
      const response: ApiResponse = await this.generalTasksService.getMyCRMTasksGrid(
        null, null,
        this.crmTaskGridStore.gridData.meta.pageNumber,
        this.crmTaskGridStore.gridData.meta.pageSize,
        this.crmTaskGridStore.gridData.meta.sortDir,
        this.crmTaskGridStore.gridData.meta.sortBy,
        this.crmTaskGridStore.gridData.meta.filters,
        this.crmTaskGridStore.gridData.meta.dropdownFilters,
        TaskScope.REPORTED_BY_ME
      );
      return response;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE
      });
    }
  };

  setRecordIds(value, selected) {
    console.log(value);
    if (value != null && !selected) {
      this.removeRecordsIds(value);
    } else if (value != null && !this.recordIds.includes(value)) {
      this.recordIds.push(value);
    }
  }

  removeRecordsIds(value) {
    this.recordIds = this.recordIds.filter(leadId => leadId != value);
  }


  get selectedMixedRecords() {
    return this.crmTaskGridStore.gridData.data.rows.filter(
      row => this.recordIds.includes(row.leadId) || this.recordIds.includes(row.contactId)
    );

  }
}