import React, { useEffect, useState } from 'react';
import {
  Grid,
  InputAdornment,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  Box
} from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import ErrorIcon from '@material-ui/icons/Error'
import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { useAppConfigurations } from '@roc/client-portal-shared/hooks';
import { LoginLayout } from './components/loginLayout';
import { useParams, useHistory } from 'react-router';
import { useStore, useAppRoutes } from '../../hooks';
import { observer } from 'mobx-react';
import { Button, TextField } from '@roc/ui';
import { isNotBlank } from '@roc/client-portal-shared/utils';
import { Alert, AlertTitle } from '@material-ui/lab';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { PasswordChangeType } from '../../types';
import { CheckCircle } from '@material-ui/icons';
import { LoginLogoImg } from '@roc/feature-app-core';
import { CreatePasswordFormProps } from '@roc/feature-types';

const useStyles = makeStyles(theme =>
  createStyles({
    gridContainer: {
      padding: theme.spacing(4),
      maxWidth: 500,
      margin: 'auto',
    },
    topMargin: {
      marginTop: theme.spacing(4),
    },
    submitButton: {
      marginTop: theme.spacing(4),
    },
    noPadding: {
      padding: theme.spacing(0),
    },
    success: {
      color: theme.palette.success.main,
    },
    loginButton: {
      width: 250,
    },
    errorAlert: {
      color: '#cc1414',
      paddingTop: '10px',
      paddingBottom: '10px',
      margin: 'auto'
    },
    successAlert: {
      color: 'green',
      paddingTop: '10px',
      paddingBottom: '10px',
      width: '380px',
      margin: 'auto'
    },
    text: {
      fontWeight: 'bolder',
      paddingLeft: '6px'
    }
  })
);

const passwordRequirementFields = [
  'passwordMin',
  'passwordSpecialOrNumber',
  'passwordLowerUpper',
  'passwordsMatch',
];

interface CreateOrResetPasswordProps {
  passwordChangeType: PasswordChangeType;
}

export const CreatePasswordForm = observer(({
  passwordChangeType = PasswordChangeType.CREATE,
  handleFormSubmit,
  submitButtonText = 'Submit',
  fullWidthButton = true
}: CreatePasswordFormProps) => {

  const classes = useStyles();
  const { siteName } = useAppConfigurations();
  const { createOrResetPasswordStore } = useStore();
  const { form } = createOrResetPasswordStore;
  const [showPassword, setShowPassword] = useState(false);

  const keyPress = e => {
    if (e.key === 'Enter') {
      handleFormSubmit();
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = e => {
    e.preventDefault();
  };

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h6" align="center">
          {
            passwordChangeType == PasswordChangeType.CREATE ||
              passwordChangeType == PasswordChangeType.CREATE_MIGRATED_USER
              ? `To finish setting up your ${siteName} account, it's important to set a secure password.` :
              `Enter a new password for you ${siteName} account.`
          }
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.topMargin}>
        <TextField
          testId="password"
          fullWidth
          label="Password"
          variant="outlined"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LockIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          value={form.fields.password.value}
          onChange={e => {
            createOrResetPasswordStore.onFieldChange(
              'password',
              e.target.value
            );
            passwordRequirementFields.map(field =>
              createOrResetPasswordStore.onFieldChange(
                field,
                e.target.value
              )
            );
          }}
          onKeyDown={keyPress}
          helperText={form.fields.password.error}
          error={isNotBlank(form.fields.password.error)}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          testId="confirmPassword"
          fullWidth
          label="Confirm Password"
          variant="outlined"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LockIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          value={form.fields.confirmPassword.value}
          onChange={e => {
            createOrResetPasswordStore.onFieldChange(
              'confirmPassword',
              e.target.value
            );
            createOrResetPasswordStore.onFieldChange(
              'passwordsMatch',
              e.target.value
            );
          }}
          onKeyDown={keyPress}
          helperText={form.fields.confirmPassword.error}
          error={isNotBlank(form.fields.confirmPassword.error)}
        />
      </Grid>
      <Grid item xs={12}>
        <List component="nav" className={classes.noPadding}>
          {passwordRequirementFields.map((field, index) => {
            const isError =
              !form.fields[field].value.replace(/\s/g, '') ||
              form.fields[field].error;
            return (
              <ListItem
                key={`${field}-${index}`}
                className={classes.noPadding}
              >
                <ListItemIcon>
                  {isError ? (
                    <RadioButtonUncheckedIcon fontSize="small" />
                  ) : (
                    <CheckCircleRoundedIcon
                      className={classes.success}
                      fontSize="small"
                    />
                  )}
                </ListItemIcon>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  className={!isError && classes.success}
                >
                  {form.fields[field].message}
                </Typography>
              </ListItem>
            );
          })}
        </List>
      </Grid>
      <Grid item xs={12} style={{
        textAlign: 'center'
      }}>
        <Button
          fullWidth={fullWidthButton}
          className={classes.submitButton}
          variant="contained"
          color="primary"
          type="submit"
          disabled={!form.meta.isValid}
          onClick={handleFormSubmit}
          testId="submit"
        >
          <span>{submitButtonText}</span>
        </Button>
      </Grid>
    </>
  );
});

export const CreateOrResetPassword = observer(
  ({ passwordChangeType }: CreateOrResetPasswordProps) => {
    const classes = useStyles();
    const { siteName } = useAppConfigurations();
    const { token } = useParams<{ token: string }>();
    const { createOrResetPasswordStore } = useStore();
    const { form, showSuccessResendNewLink, isTokenValid, isTokenNotExpired } = createOrResetPasswordStore;
    const isTokenExpired = !isTokenNotExpired;
    const { publicRoutes } = useAppRoutes();
    const { push } = useHistory();

    useEffect(() => {
      createOrResetPasswordStore.setSuccessUrl(
        publicRoutes.successcreatepassword.url + window.location.search);
      createOrResetPasswordStore.setToken(token);
      createOrResetPasswordStore.validateToken();
    }, []);

    const handleFormSubmit = () => {
      form.meta.isValid && createOrResetPasswordStore.onFormSubmit();
    };

    // wait for validateToken
    if (isTokenValid == undefined) {
      return null;
    }

    return (
      <LoginLayout>
        <Grid
          container
          spacing={2}
          justifyContent="center"
          className={classes.gridContainer}
        >
          <Grid item xs={12}>
            <LoginLogoImg />
          </Grid>
          {
            passwordChangeType == PasswordChangeType.CREATE_MIGRATED_USER && (
              <Grid item xs={12}>
                <Alert severity="warning">
                  <AlertTitle>
                    <Typography variant="h6">Note</Typography>
                  </AlertTitle>
                  <span style={{ fontSize: '1.2em' }}>
                    You have been migrated to this new portal.
                  </span>
                </Alert>
                <br />
              </Grid>
            )
          }
          {
            !isTokenValid && (
              <div>
                <div className={classes.errorAlert}>
                  <Box display="flex" justifyContent="center">
                    <ErrorIcon />
                    <span className={classes.text}>Invalid Token</span>
                  </Box>
                </div>
                <br />
                <Grid item xs={12}>
                  <Box textAlign="center">
                    <span style={{ fontSize: '1.2em' }}>
                      Looks like the token is not valid.
                    </span>
                  </Box>
                  <Box textAlign="center">
                    <span style={{ fontSize: '1.2em' }}>
                      Not to worry, we can send the link again.
                    </span>
                  </Box>
                </Grid>
                <br />
                <br />
                <Grid item xs={12}>
                  <Box textAlign="center">
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      className={classes.loginButton}
                      onClick={() => {
                        push(publicRoutes.forgotpassword.url)
                      }}
                      testId="submit"
                    >
                      <span>RESET PASSWORD</span>
                    </Button>
                  </Box>
                </Grid>
              </div>
            )
          }
          {
            !showSuccessResendNewLink && isTokenValid && isTokenNotExpired && (
              <CreatePasswordForm
                passwordChangeType={passwordChangeType}
                handleFormSubmit={handleFormSubmit}
              />
            )
          }
          {
            !showSuccessResendNewLink && isTokenValid && isTokenExpired && (
              <div>
                <div className={classes.errorAlert}>
                  <Box display="flex" justifyContent="center">
                    <ErrorIcon />
                    <span className={classes.text}>Link Expired</span>
                  </Box>
                </div>
                <br />
                <Grid item xs={12}>
                  <Box textAlign="center">
                    <span style={{ fontSize: '1.2em' }}>
                      Looks like the link has expired.
                    </span>
                  </Box>
                  <Box textAlign="center">
                    <span style={{ fontSize: '1.2em' }}>
                      Not to worry, we can send the link again.
                    </span>
                  </Box>
                </Grid>
                <br />
                <br />
                <Grid item xs={12}>
                  <Box textAlign="center">
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      className={classes.loginButton}
                      onClick={() => {
                        createOrResetPasswordStore.resendNewLink();
                      }}
                      testId="submit"
                    >
                      <span>RESEND NEW {`${PasswordChangeType.CREATE == passwordChangeType ? 'INVITATION' : 'LINK'}`}</span>
                    </Button>
                  </Box>
                </Grid>
              </div>
            )
          }
          {
            showSuccessResendNewLink && (
              <div>
                <div className={classes.successAlert}>
                  <Box display="flex" justifyContent="center" alignItems={"center"}>
                    <CheckCircle />
                    <Typography variant='h6'>
                      <span className={classes.text}>SUCCESS</span>
                    </Typography>
                  </Box>
                </div>
                <br />
                <Grid item xs={12}>
                  <Box textAlign="center">
                    <span style={{ fontSize: '1.2em' }}>
                      Please check your email for a new link to create a new password to your account.
                    </span>
                  </Box>
                </Grid>
                <br />
                <br />
                <Grid item xs={12}>
                  <Box textAlign="center">
                    <span style={{ fontSize: '1.2em' }}>
                      Once done, you will be able to continue.
                    </span>
                  </Box>
                </Grid>
              </div>
            )
          }
        </Grid>
      </LoginLayout>
    );
  }
);
