import { Grid } from '@material-ui/core';
import { observer } from 'mobx-react';
import React from 'react';
import { FundingTemplateContainer } from '../common/fundingTemplateContainer';
import { ParticipantsBreakdownGrid } from '../common/participantsBreakdownGrid';
import { useDocumentStore } from './../../../../../hooks/useDocumentStore';
import { FundingTemplateDetailsForm } from './fundingTemplateDetailsForm';
import { LenderFeesForm } from './lenderFeesForm';

export const FundingTemplate = observer(() => {
  const {
    documentStore: { documentFormStore },
  } = useDocumentStore();
  const { documentStore } = useDocumentStore();
  const { fundingTemplateStore } = documentFormStore;

  return (
    <FundingTemplateContainer>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <FundingTemplateDetailsForm estimatedFundingTemplate={documentStore.openEstimateFundingTemplate} store={fundingTemplateStore} />
        </Grid>
        <Grid item xs={12}>
          <ParticipantsBreakdownGrid store={fundingTemplateStore} />
        </Grid>
        <Grid item xs={12}>
          <LenderFeesForm store={fundingTemplateStore} />
        </Grid>
      </Grid>
    </FundingTemplateContainer>
  );
});

export default FundingTemplate;
