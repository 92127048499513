import { TextField } from '@material-ui/core';
import React from 'react';
import { FieldLabel } from '../fieldLabel/fieldLabel';
import { NumberFormat } from '../numberFormat/numberFormat';

export interface PhoneFieldProps {
  label?: string;
  name?: string;
  value?: number;
  onChange?: (name: string, value: any) => void;
  disabled?: boolean;
  required?: boolean;
  error?: boolean;
  errorText?: string;
  fullWidth?: boolean;
  margin?: string;
  variant?: string;
  inputClassname?: string;
  standaloneLabel?: boolean;
  testId: string;
}

const _PhoneField: React.FC<PhoneFieldProps> = ({
  label,
  name,
  value,
  onChange,
  disabled,
  required,
  error,
  errorText,
  fullWidth,
  margin,
  variant,
  inputClassname,
  testId,
}) => {
  return (
    <NumberFormat
      testId={testId}
      label={label}
      name={name}
      value={value}
      onChange={event => onChange && onChange(name, event.target.value)}
      displayType={'input'}
      format="(###) ###-####"
      mask="_"
      customInput={TextField}
      disabled={disabled}
      required={required}
      error={error}
      helperText={errorText}
      fullWidth={fullWidth}
      margin={margin}
      variant={variant}
      className={inputClassname}
    />
  );
};

export const PhoneField = (props: PhoneFieldProps) => {
  const { standaloneLabel, ...muiComponentProps } = props;
  return (
    <>
      {standaloneLabel && muiComponentProps.label ? (
        <FieldLabel>
          {muiComponentProps.label}
          {muiComponentProps.required && ' *'}
        </FieldLabel>
      ) : null}
      <_PhoneField
        {...muiComponentProps}
        label={
          standaloneLabel
            ? undefined
            : props.required
              ? muiComponentProps.label + ' *'
              : muiComponentProps.label
        }
      />
    </>
  );
};
