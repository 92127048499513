import { makeObservable, action, computed, observable, flow } from 'mobx';
import { GlobalStore } from '@roc/feature-app-core';
import { BorrowerFormStore } from './borrowerFormStore';
import { Borrower } from '../types';
import { DialogState } from '@roc/ui';
import { FormStore } from '@roc/feature-app-core';
import QualifyBorrowerRequestFormStore from './qualifyBorrowerRequestFormStore';
import SelectBorrowersStore from './selectBorrowersStore';
import { BorrowersService } from '../services/borrowersService';

export class BorrowerAccordionStore {
  private globalStore: GlobalStore;
  private selectBorrowersStore: SelectBorrowersStore;
  private createBorrowerForm: () => BorrowerFormStore;
  private prequalifyBorrowerCallback: (
    borrower: Borrower,
    qualifyBorrowerRequestFormStore: QualifyBorrowerRequestFormStore
  ) => void;
  borrowerForms: {
    borrower: Borrower;
    borrowerFormStore: FormStore;
    qualifyBorrowerRequestFormStore: QualifyBorrowerRequestFormStore;
  }[] = [];
  private borrowersService: BorrowersService;

  constructor(
    globalStore: GlobalStore,
    selectBorrowersStore: SelectBorrowersStore,
    createBorrowerForm?: () => BorrowerFormStore
  ) {
    this.globalStore = globalStore;
    this.selectBorrowersStore = selectBorrowersStore;
    this.createBorrowerForm = createBorrowerForm;
    this.borrowersService = new BorrowersService();
    makeObservable(this, {
      borrowerForms: observable,
      reset: action,
      validate: action,
      refreshBorrowers: action,
      createAccordionForm: action,
      setPrequalifyBorrowerCallback: action,
      refreshAccordionBorrowers: action,
      saveBorrowersFromAccordion: action,
      saveBorrower: action,
      loadBorrowerPrescreen: flow,
    });
  }

  reset() {
    this.borrowerForms = [];
  }

  refreshBorrowers(borrowers) {
    this.borrowerForms = borrowers.map(borrower => {
      const existingForm = this.borrowerForms.find(x =>
        this.borrowerEquals(x.borrower, borrower)
      );
      if (existingForm) {
        const updatedBorrower = Object.assign({}, existingForm.borrowerFormStore.getFormValues(), borrower)
        const updatedValues = Object.assign({}, { ...existingForm }, {
          borrower: Object.assign({}, existingForm.borrower, updatedBorrower)
        });

        updatedValues.borrowerFormStore.loadForm(updatedValues.borrower);
        return updatedValues;
      } else {
        return this.createAccordionForm(borrower);
      }
    });
  }

  private borrowerEquals(borrowerA, borrowerB) {
    return (
      borrowerA.tempId === borrowerB.tempId ||
      borrowerA.borrowerId === borrowerB.borrowerId
    );
  }

  createAccordionForm(borrower) {
    const borrowerFormStore = this.createBorrowerForm();
    borrowerFormStore.loadForm({
      ...borrower,
      personalGuarantor: this.selectBorrowersStore.isLoanBeingEdit ? borrower.personalGuarantor : null,
    });
    const qualifyBorrowerRequestFormStore = new QualifyBorrowerRequestFormStore(
      this.globalStore
    );
    qualifyBorrowerRequestFormStore.initCreditBackgroundCheck(borrower);
    return {
      borrower,
      borrowerFormStore,
      qualifyBorrowerRequestFormStore,
    };
  }

  validate(): string[] {
    this.borrowerForms.forEach(item =>
      item.borrowerFormStore.runFormValidation()
    );
    const allFormsAreValid = this.borrowerForms.every(
      item => item.borrowerFormStore.form.meta.isValid
    );
    const errors = [];
    if (!allFormsAreValid) {
      errors.push('Please fix the validation errors.');
    }
    const allPersonalGuarantorFieldsAreValid = this.borrowerForms.every(
      item => !item.borrowerFormStore.form.fields?.personalGuarantor?.error
    );

    if (!allPersonalGuarantorFieldsAreValid) {
      errors.push(
        'Please answer the personal guarantor question for each borrower.'
      );
    }
    return errors;
  }

  setPrequalifyBorrowerCallback(callback) {
    this.prequalifyBorrowerCallback = callback;
  }

  refreshAccordionBorrowers() {
    this.refreshBorrowers(this.selectBorrowersStore.borrowers);
  }

  saveBorrowersFromAccordion() {
    this.borrowerForms.forEach(item => {
      const updatedBorrower = this.saveBorrower(
        item.borrower,
        item.borrowerFormStore
      );
      this.prequalifyBorrowerCallback(
        updatedBorrower,
        item.qualifyBorrowerRequestFormStore
      );
    });
  }

  saveBorrower(borrower: Borrower, borrowerFormStore: BorrowerFormStore) {
    const values = borrowerFormStore.getFormValues();
    values.tempId = borrower.tempId;
    const updatedBorrower = this.selectBorrowersStore.saveBorrowerValues(
      borrower,
      values
    );
    return updatedBorrower;
  }

  *loadBorrowerPrescreen(borrower, personalGuarantor, loanType, borrowerFormStore) {
    try {
      const response = yield this.borrowersService.getBorrowerPrescreenInfoForSubmission({
        borrowerId: borrower.borrowerId,
        personalGuarantor: personalGuarantor,
        loanType: loanType,
      });
      if (response.data?.data) {
        const values = Object.assign({}, borrowerFormStore.getFormValues(), response.data.data);
        values.tempId = borrower.tempId;

        this.selectBorrowersStore.saveBorrowerValues(
          borrower,
          values
        );
        this.borrowerForms = this.borrowerForms.map(borrForm => {
          if (borrForm.borrower.tempId === values.tempId) {
            return Object.assign({}, { ...borrForm }, {
              borrower: Object.assign({}, borrForm.borrower, values)
            });
          }
          return borrForm;
        });
      }


    } catch (err) {
      console.error('Error while trying to load borrower pre screen data', err);
    }
  }
}
