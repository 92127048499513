import {
  Typography,
  Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import {
  Button,
  ConfirmationButton,
  TextField,
} from '@roc/ui';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    instruction: {
      fontWeight: 600,
    },
    flex: {
      display: 'flex'
    },
  })
);

export const BorrowerSetupRepull = observer(({ store }) => {

  const classes = useStyles();

  const handleConfirm = () => {
    store.repullReports();
  };

  return (
    <Grid item xs={12} container justifyContent='center' spacing={2}>
      <Grid item xs={12} justifyContent="center" className={classes.flex}>
        <Typography className={classes.instruction}>
          We were unable to pull the credit report because two or more credit scores are frozen. Please click on the Repull button once you've confirmed the scores have been unfrozen.
        </Typography>
      </Grid>
      {!store.repullInProgress && (
        <Grid item container justifyContent="center">
          <ConfirmationButton
            variant="contained"
            onClick={handleConfirm}
            color="primary"
            testId="sendIdVerificationButton"
            confirmDialogProps={{
              title: 'Confirmation',
              body: <>
                <Typography>Please confirm if you would like us to proceed with pulling the credit report. </Typography>
                <br />
                <Typography style={{ fontSize: '0.8rem' }}>* Please note we allow only 1 pull per day.</Typography>
              </>,
            }}
          >
            Repull the reports
          </ConfirmationButton>
        </Grid>
      )}
    </Grid>
  );
});