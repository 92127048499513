import {
  GENERIC_VALIDATION_NOTIFICATION,
  GlobalStore,
} from '@roc/feature-app-core';
import { DialogState } from '@roc/ui';
import { action, makeObservable, observable } from 'mobx';
import { LoanApplicationProperty } from '../utils/loanApplicationTypes';
import { PropertyFormStore } from './propertyFormStore';

export class PropertiesStore {
  private globalStore: GlobalStore;

  addPropertyForm: PropertyFormStore;
  editPropertiesForms: PropertyFormStore[];

  dialogState: DialogState;
  properties: LoanApplicationProperty[] = [];

  constructor(globalStore) {
    this.globalStore = globalStore;
    this.addPropertyForm = new PropertyFormStore(globalStore);

    makeObservable(this, {
      editPropertiesForms: observable,
      dialogState: observable,
      properties: observable,
      reset: action,
      openAddProperty: action,
      saveAddProperty: action,
      openEditProperties: action,
      saveEditProperties: action,
      closeDialog: action,
      removeProperty: action,
    });
  }

  reset() {
    this.addPropertyForm.reset();
    this.editPropertiesForms = [];
    this.properties = [];
  }

  openAddProperty() {
    this.reset();
    this.dialogState = DialogState.ADD;
  }

  saveAddProperty() {
    if (this.addPropertyForm.runAllFormValidationsWithMessage()) {
      const property = this.addPropertyForm.getProperty();
      this.properties = this.properties.concat([property]);
      this.closeDialog();
    }
  }

  openEditProperties() {
    this.editPropertiesForms = this.properties.map(property => {
      const form = new PropertyFormStore(this.globalStore);
      form.loadProperty(property as any);
      return form;
    });
    this.dialogState = DialogState.EDIT;
  }

  saveEditProperties() {
    if (this.runAllEditFormValidationsWithMessage()){
      this.properties = this.editPropertiesForms.map(s => s.getProperty());
      this.closeDialog();
    }
  }

  closeDialog() {
    this.dialogState = null;
  }

  removeProperty(formStore: PropertyFormStore) {
    this.editPropertiesForms = this.editPropertiesForms.filter(
      x => x != formStore
    );
  }

  private runAllEditFormValidationsWithMessage() {
    const isValid = this.editPropertiesForms
      .map(formStore => formStore.runAllFormValidations())
      .every(result => result === true);
    if (!isValid) {
      this.globalStore.notificationStore.showWarningNotification({
        message: GENERIC_VALIDATION_NOTIFICATION,
      });
    }
    return isValid;
  }
}
