import { GlobalStore } from '@roc/client-portal-shared/stores';
import { computed, flow, makeObservable, observable, toJS } from 'mobx';
import { FormStore } from '@roc/feature-app-core';
import { borrowerRateOptions, LoaSubtypeOptions } from '../utils/bridgePricerConstants';
import { BridgePricerFormValues } from '../types/bridgePricerTypes';

const form = {
  fields: {
    propertyState: {
      value: '',
      error: null,
      rule: 'required',
      attribute: 'property state',
    },

    loanSubtype: {
      value: '',
      error: null,
      rule: 'required',
      attribute: 'loan subtype',
    },

    submissionDate: {
      value: '',
      error: null,
      rule: 'required',
      attribute: 'submission date',
    },

    floorRate: {
      value: '',
      error: null,
      rule: ''
    },

    borrowerRate: {
      value: '',
      error: null,
      rule: 'required',
      attribute: 'borrower rate',
    },

    borrowerPoints: {
      value: '',
      error: null,
      rule: 'required',
      attribute: 'borrower points',
    },

    rocRate: {
      value: '',
      error: null,
      rule: ''
    },

    rocPoints: {
      value: '',
      error: null,
      rule: ''
    },

    yourYieldSpread: {
      value: '',
      error: null,
      rule: ''
    },

    yourPoints: {
      value: '',
      error: null,
      rule: ''
    },
    lenderName: {
      value: '',
      error: null,
      rule: ''
    },
    lenderId: {
      value: '',
      error: null,
      rule: ''
    },
    loanPurpose: {
      value: '',
      error: null,
      rule: '',
    },
    loanTermsMonths: {
      value: '',
      error: null,
      rule: '',
    },
    renoBudget: {
      value: '',
      error: null,
      rule: '',
    },
    completedCapitalImprovements: {
      value: '',
      error: null,
      rule: '',
    },
    estimatedFicoScore: {
      value: 680,
      error: null,
      rule: ''
    },
    experienceScore: {
      value: 1,
      error: null,
      rule: ''
    },
    loanToPurchase: {
      value: 0,
      error: null,
      rule: ''
    },
  },
  meta: {
    isValid: false,
    error: null,
  }
};

export abstract class BridgePricerBaseStore extends FormStore {
  protected globalStore: GlobalStore;
  public isBroker: boolean = false;
  public exceptions: string[];
  public swapRates: any;
  public exceptionCounter: number;
  public chart: any;
  public loadData: boolean = false;
  public penaltyCeiling: any;

  abstract getValues(data: BridgePricerFormValues);
  abstract getAllLenderIdNames();
  abstract getBorrowerPointsOptions(lenderId, loanTerm, submissionDate);
  abstract getLoanValues(loanId: string): BridgePricerFormValues;
  abstract getAutocompleteOptions(searchTerm: string);
  abstract getRateFloor(data: BridgePricerFormValues);

  loanSearchText = '';
  loanSearchOptions: { label: string; value: string }[] = [];

  calculatedValues: any[];
  defaultValues: any;
  isClientPortal: boolean;
  chartData: any[];
  borrowerRateOptions: any[];

  constructor(globalStore) {
    super(form, globalStore);
    this.globalStore = globalStore;

    makeObservable(this, {
      loanSearchText: observable,
      loanSearchOptions: observable,
      calculatedValues: observable,
      defaultValues: observable,
      isClientPortal: observable,
      chartData: observable,
      borrowerRateOptions: observable,
      getValues: flow,
      fetchValues: flow,
      getAllLenderIdNames: flow,
      getBorrowerPointsOptions: flow,
      getAutocompleteOptions: flow,
      getLoanValues: flow,
      fetchOptions: flow,
      fetchLoanValues: flow,
      getRateFloor: flow,
      loadData: observable,
      penaltyCeiling: observable,
      isInternal: computed,
      lenderId: computed,
    });
  }

  get isInternal() {
    return this.globalStore.lenderInfo?.isInternal;
  }

  get lenderId() {
    return this.globalStore.lenderInfo?.lenderId;
  }

  *fetchValues() {
    try {
      this.loadData = true;
      this.runFormValidationWithMessage();
      if (this.form.meta.isValid && (this.form.fields['lenderName'].value || this.isClientPortal)) {
        const response = yield this.getValues(this.getFormValues());
        yield this.validateBorrowerRate(response);
        this.onFieldChange('floorRate', response['floorRate']);
        this.onFieldChange('rocRate', response['rocRate']);
        this.onFieldChange('yourYieldSpread', response['yourYieldSpread'].toFixed(2));
        this.onFieldChange('yourPoints', response['yourPoints'].toFixed(2));
        this.onFieldChange('rocPoints', response['rocPoints'].toFixed(2));
        this.penaltyCeiling = response['penaltyCeiling'];

        this.chartData[0]['borrowerRate'] = response['floorRate'];

        this.chartData = [
          this.chartData[0],
          response['firstCurve'],
          response['secondCurve'],
          response['aboveSecondCurve']
        ];

        this.filterBorowerRateOptions(borrowerRateOptions, response['floorRate']);
      }
      else if (!this.isClientPortal && !this.form.fields['lenderName'].value) {
        this.globalStore.notificationStore.showWarningNotification({
          message: 'Please select a lender.',
        });
      }
    } catch (e) {

    }
  };

  filterBorowerRateOptions(borrowerRateOptions, floorRate) {
    const options = borrowerRateOptions.filter((o) => {
      return parseFloat(o.value) >= parseFloat(floorRate);
    })

    const lowest = options.find((o =>
      parseFloat(o.value) === parseFloat(floorRate)
    ));

    if (!lowest) {
      options.push({
        label: floorRate + "%",
        value: floorRate
      });
    }

    options.sort((a, b) => parseFloat(a.value) - parseFloat(b.value));

    this.borrowerRateOptions = options;
  }

  async validateBorrowerRate(response) {
    const borrowerRate = this.form.fields['borrowerRate'].value;
    if (borrowerRate < response['floorRate']) {
      this.onFieldChange('borrowerRate', response['floorRate']);
      const data = await this.getValues(this.getFormValues());
      response['floorRate'] = data['floorRate'];
      response['rocRate'] = data['rocRate'];
      response['yourYieldSpread'] = data['yourYieldSpread'];
      response['yourPoints'] = data['yourPoints'];
      response['rocPoints'] = data['rocPoints'];
      response['rocPoints'] = data['rocPoints'];
      response['firstCurve'] = data['firstCurve'];
      response['secondCurve'] = response['secondCurve'];
      response['aboveSecondCurve'] = response['aboveSecondCurve'];
    }
  }

  *fetchOptions(searchText: string) {
    try {
      if (!searchText) {
        this.loanSearchOptions = [];
        return;
      }
      const options = yield this.getAutocompleteOptions(searchText);
      this.loanSearchOptions = options;
    } catch (e) {
      console.log(e);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'An error occurred while loading the list of loans.',
      });
    }
  }

  async fetchRateFloorInternal() {
    const data = {
      "submissionDate": this.form.fields['submissionDate'].value,
      "lenderName": this.form.fields["lenderName"].value,
      "propertyState": this.form.fields["propertyState"].value,
      "loanSubtype": this.form.fields["loanSubtype"].value
    };

    const response = await this.getRateFloor(data)
      .then(r => r.data)
      .then(r => this.onFieldChange('floorRate', r))
      .finally(r => this.applyValidations());
  }

  *fetchLoanValues(loanId: string) {
    try {
      const loanValues = yield this.getLoanValues(loanId);
      const date = new Date(loanValues['submissionDate']);
      this.onFieldChange('submissionDate', date);
      this.onFieldChange('loanSubtype', loanValues['loanSubtype']);
      this.onFieldChange('borrowerRate', Number(loanValues['rate']));
      this.onFieldChange('borrowerPoints', Number(loanValues['points']));
      this.onFieldChange('propertyState', loanValues['propertyState']);
      this.onFieldChange('loanTermsMonths', loanValues['duration']);
      this.onFieldChange('lenderName', loanValues['lenderName']);
      this.onFieldChange('renoBudget', loanValues['renoBudget']);
      this.onFieldChange('loanToPurchase', loanValues['initialLTCPercentage']);
      this.onFieldChange('estimatedFicoScore', loanValues['fico']);
      this.onFieldChange('experienceScore', loanValues['borrowerExperience'] && loanValues['borrowerExperience'] > 2 ? loanValues['borrowerExperience'] : '');

      !this.isClientPortal ? this.fetchRateFloorInternal() : this.applyValidations();
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'An error occurred while loading the loan data',
      });
    }

    this.setDefaultValues();
  }

  applyValidations() {
    if (this.form.fields['floorRate'].value > 0 && this.form.fields['borrowerRate'].value < this.form.fields['floorRate'].value) {
      this.globalStore.notificationStore.showInfoNotification({
        message: 'Borrower Rate from loan is less than floor rate',
      });
      this.onFieldChange('borrowerRate', null);
    }

    if (this.form.fields['floorRate'].value.length == 0) {
      this.onFieldChange('borrowerRate', null);
    }

    if (this.form.fields['borrowerPoints'].value == 0) {
      this.globalStore.notificationStore.showInfoNotification({
        message: 'Borrower points from loan are zero.',
      });
      this.onFieldChange('borrowerPoints', null);
    }
  }

  setDefaultValues() {
    this.onFieldChange('rocRate', "");
    this.onFieldChange('yourYieldSpread', "");
    this.onFieldChange('yourPoints', "");
    this.onFieldChange('rocPoints', "");
  }
}