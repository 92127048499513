import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { CheckCircle, NotInterested } from '@material-ui/icons';
import { useLoanStore } from '@roc/feature-loans';
import { formatCurrency, getRocPointsIn } from '@roc/ui/utils';
import { observer } from 'mobx-react';
import {
  LoanDetailsCustomCard,
  LoanDetailsInfoItem,
} from '../loanDetailsCustomCard';
import { useLoanFlags } from '../../hooks/useLoanFlags';
import { LoanDetailsCardActionsButton } from '../loanDetailsCardActionsButton';
import { LoandetailsBorrowerEntityBorrowers } from '../loanDetailsBorrowerEntityBorrowers';
import {
  LoanType,
} from '@roc/feature-utils';
import { useStore } from '@roc/client-portal-shared/hooks';

const TermLoanAmountHighlighter = () => {
  return (
    <Box p={2} mt={-.25} ml={-1.5} bgcolor={'#d7eeff'} borderRadius={16} position={'absolute'} top={0} width={'100%'} height={'100%'}>
    </Box>
  )
}

const formatPoints = (value) => {
  return Number((value || 0).toFixed(4))
}

export const LoanDetailsCardTerm = observer(() => {
  const { allowLoanPricing } = useLoanFlags();
  const { loanStore, globalStore } = useStore();
  const { loanDetails } = loanStore;
  const isInternal = globalStore?.lenderInfo?.isInternal;
  const isOffHudBroker = loanDetails?.referralFeePct && loanDetails?.referralFeePct > 0 ? true : false;


  const noOfUnits = (loanDetails?.properties || []).reduce(
    (total, p) => total + p.units,
    0
  );

  let ltvValue = '-';
  if (loanDetails?.aggregateLoanToAsIs) {
    ltvValue = `${loanDetails?.aggregateLoanToAsIs}%`;
  } else if (loanDetails?.loanType === LoanType.RESIDENTIAL_TERM && loanDetails?.estimatedLTV) {
    ltvValue = `~ ${loanDetails?.estimatedLTV}%`;
  }

  return (
    <LoanDetailsCustomCard contentStyle={{
      minWidth: 1100
    }} cardContentProps={{
      style: {
        overflow: 'auto'
      }
    }}>
      <Grid item xs={12}>
        <Grid container style={{ position: 'relative' }}>
          <Grid item xs={3} style={{ position: 'relative' }}>
            <TermLoanAmountHighlighter />
            <LoanDetailsInfoItem
              boxStyle={{
                position: 'relative'
              }}
              value={formatCurrency(loanDetails?.amount, 0)}
              label={loanDetails?.loanSubType}
              valueStyle={{
                fontSize: 28,
                marginTop: -6,
              }}
              secondValue={<>
                <span>{ltvValue}</span>
                <span style={{
                  color: '#555',
                  fontSize: 14,
                  fontWeight: 'normal'
                }}>&nbsp;{'LTV'}</span>
              </>}
              secondValueStyle={{
                fontSize: 20,
                fontWeight: 'bold',
                marginTop: -6,
              }}
              thirdLabel='Prepayment penalty'
              thirdValue={loanDetails.prepaymentDescription}
              thirdValueStyle={{
                fontSize: 18
              }}
            />
          </Grid>
          {allowLoanPricing &&
            <Grid item xs={2}>
              <LoanDetailsInfoItem
                value={`${formatPoints((loanDetails?.brokerPointsIn ?? 0) +
                  (loanDetails?.sfrLenderPointsIn ?? 0) +
                  (getRocPointsIn(loanDetails) ?? 0) + (loanDetails?.referralFeePct ?? 0))
                  } points`}
                label={
                  <>
                    {isInternal || isOffHudBroker
                      ? (isOffHudBroker
                          ? `${formatPoints(loanDetails?.referralFeePct ?? 0)} Off Hud Broker | `
                          : `${formatPoints(loanDetails?.brokerPointsIn ?? 0)} On Hud Broker | `
                        )
                      : `${formatPoints(loanDetails?.brokerPointsIn ?? 0)} Broker | `}
                    {formatPoints(loanDetails?.sfrLenderPointsIn ?? 0)} Lender <br />
                    {formatPoints(getRocPointsIn(loanDetails) ?? 0)} Capital Provider
                  </>
                }
              />
            </Grid>
          }
          <Grid item xs={2}>
            <LoanDetailsInfoItem
              value={formatCurrency(loanDetails?.interestPayment ?? 0)}
              label="Monthly Payment"
            />
          </Grid>
          <Grid item xs={2}>
            <LoanDetailsInfoItem
              value={noOfUnits + ` Unit${noOfUnits > 1 ? 's' : ''}`}
              label="No. of Units"
            />
          </Grid>
          <Grid item xs={2}>
            <LoanDetailsInfoItem
              value={loanDetails?.pledgeOfShares ? (
                <CheckCircle style={{ color: 'rgb(72, 173, 37)', display: 'flex', margin: '3px 0' }} />
              ) : (
                <NotInterested style={{ color: '#e90000', display: 'flex', margin: '3px 0' }} />
              )}
              label="Pledge of Shares"
            />
          </Grid>
          <Grid item xs={1}>
            <LoanDetailsCardActionsButton />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={3}>
            <LoanDetailsInfoItem
              value={`${loanDetails?.loanPurpose}`}
              label="Loan Purpose"
            />
          </Grid>
          <Grid item xs={2}>
            <LoanDetailsInfoItem
              value={formatCurrency(loanDetails?.pitia ?? 0)}
              label="PITIA"
            />
          </Grid>
          <Grid item xs={2}>
            <LoanDetailsInfoItem
              value={loanDetails?.pitiaDSCR}
              label="PITIA DSCR" />
          </Grid>
          <Grid item xs={2}>
            <LoanDetailsInfoItem
              value={formatCurrency(loanDetails?.interestReserve)}
              label="P&I Reserves"
            />
          </Grid>
          <Grid item xs={2}>
            <LoanDetailsInfoItem
              value={'-'}
              label="Due At Closing" />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container>
          {allowLoanPricing &&
            <Grid item xs={3}>
              <LoanDetailsInfoItem
                value={loanDetails?.rateType}
                label="Fixed Rate" />
            </Grid>
          }
          {allowLoanPricing &&
            <Grid item xs={2}>
              <LoanDetailsInfoItem
                value={`${loanDetails?.rate}%`}
                label="Interest Rate"
              />
            </Grid>
          }
          <Grid item xs={7}>
            <LoandetailsBorrowerEntityBorrowers loanDetails={loanDetails} />
          </Grid>
        </Grid>
      </Grid>
    </LoanDetailsCustomCard>
  );
});
