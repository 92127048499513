import { Page } from '../../../components';
import { Route } from 'react-router';
import { useStore } from '@roc/client-portal-shared/hooks';
import { BridgePricer } from '../bridgePricer';
import { useRocInternalPortalStore } from '../../../../../../../apps/roc-internal-portal/src/app/hooks';

export const getClientBridgePricerRoutesConfig = (
  basePath: String,
  baseUrl: String
) => {
  return {
    bridgePricer: {
      path: `${basePath}/bridge-pricer`,
      url: `${baseUrl}/bridge-pricer`,
      documentTitle: 'Bridge Pricer',
    },
  }
}

export const getClientBridgePricerRoutes = (
  basePath: string,
  baseUrl: string
) => {
  const config = getClientBridgePricerRoutesConfig(basePath, baseUrl);
  const { clientBridgePricerStore } = useStore();

  return {
    bridgePricer: (
      <Route exact path={config.bridgePricer.path}>
        <Page routeDefinition={config.bridgePricer}>
          <BridgePricer bridgePricerStore={clientBridgePricerStore} />
        </Page>
      </Route>
    ),
  };
}