import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { Box, Button, CardMedia, Chip, Dialog, Grid, Paper } from '@material-ui/core';
import { observer } from 'mobx-react';
import { ValuationReviewReportStore } from '../stores/valuationReviewReportStore';
import { formatCurrency } from '@roc/ui/utils';
import { PropertyDataComparisonDialog } from './propertyDataComparisonDialog';
import { useState } from 'react';
import Carousel from 'react-material-ui-carousel';
// import img1 from './../assets/img1.png';
// import img2 from './../assets/img2.png';
// import img3 from './../assets/img3.png';
// import img4 from './../assets/img4.png';
// import img5 from './../assets/img5.png';

function Item(props) {
  return (
    <Box display={'flex'}>
      <img src={props.item} width={'100%'} height={'auto'}></img>
    </Box>
  )
}

export enum LoanType {
  RESIDENTIAL_BRIDGE = 'Residential Bridge',
  RESIDENTIAL_TERM = 'Residential Term',
}

const useStyles = makeStyles({
  root: {
  },
  carousel: {
    height: '100%',
    background: '#eee',
    '& .CarouselItem': {
      display: 'flex',
      height: '100%',
      alignItems: 'center',
    }
  }
});

export type Props = {
  store: ValuationReviewReportStore
}

export const PropertyCard = observer((props: Props) => {
  const classes = useStyles();
  const { store } = props;
  const { valuationReviewInfo } = store;
  const { propertyInformationDataSources, propertyInformation, loanType, publicRecordsAvailable}  = valuationReviewInfo;
  const publicRecords = publicRecordsAvailable ? propertyInformationDataSources?.publicRecords : propertyInformationDataSources?.subject;
  const [openCompareDialog, setOpenCompareDialog] = useState<boolean>();

  // var items = [img1, img2, img3, img4, img5];
  return (
    <Card className={classes.root} elevation={0} variant='outlined'>
      <Box display={'flex'} flexDirection={'row'}>
        {/* <Box width={200}>
          <CardMedia style={{ height: '100%' }}>
            <Carousel
              className={classes.carousel}
              autoPlay={false}
              indicators={false}
              navButtonsAlwaysVisible={true}
              cycleNavigation={true}
              fullHeightHover={true}
              animation={'slide'}
              navButtonsProps={{
                style: {
                  padding: 4,
                  borderRadius: 24,
                  opacity: 0.5,
                }
              }}
            >
              {
                items.map((item, i) => <Item key={i} item={item} />)
              }
            </Carousel>
          </CardMedia>
        </Box> */}
        <Box flexGrow={1}>
          <CardContent style={{
            padding: 16
          }}>
            <Grid container direction='row'>
                <Grid item xs={10}>
                  <Typography gutterBottom variant="h5" component="h2">
                    {propertyInformation?.address}
                    <Chip
                      size="small"
                      style={{marginLeft: '10px', color: '#285391', backgroundColor: 'white', border: '1px solid', borderColor: '#285391'}}
                      label={publicRecordsAvailable? 'Public Records' : 'Subject'}
                    />
                  </Typography>
                </Grid>
                <Grid item xs={2} style={{ textAlign: 'right' }} direction='row' spacing={3}>
                  <Button color="primary" variant='contained' fullWidth size='small' onClick={() => setOpenCompareDialog(true)}>
                      Compare
                    </Button>
                </Grid>
            </Grid>
            <Box>
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant="body1" component="p" style={{
                    fontWeight: 'bold'
                  }}>
                    {propertyInformation?.useCode}
                  </Typography>
                </Grid>
              </Grid>

              <Box width={'100%'} height={'1px'} bgcolor={'#eee'} my={1}></Box>

              <Grid container style={{
                textAlign: 'center'
              }} spacing={1}>
                <Grid item xs={3}>
                  <Typography variant="body1" component="p" style={{
                    fontWeight: 'bold'
                  }}>
                    {publicRecords?.beds ?? '-'}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p">
                    Beds
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body1" component="p" style={{
                    fontWeight: 'bold'
                  }}>
                    {publicRecords?.baths ?? '-'}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p">
                    Baths
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body1" component="p" style={{
                    fontWeight: 'bold'
                  }}>
                    {publicRecords?.yearBuilt ?? '-'}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p">
                    Year Built
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body1" component="p" style={{
                    fontWeight: 'bold'
                  }}>
                    {publicRecords?.gla?.toLocaleString() ?? '-'}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p">
                    GLA
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body1" component="p" style={{
                    fontWeight: 'bold'
                  }}>
                    {publicRecords?.asIsValue ? formatCurrency(publicRecords?.asIsValue, 2) : '-'}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p">
                    {loanType === LoanType.RESIDENTIAL_BRIDGE ? 'Purchase Price' : 'As Is Value'}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body1" component="p" style={{
                    fontWeight: 'bold'
                  }}>
                    {publicRecords?.renovationBudget ? formatCurrency(publicRecords?.renovationBudget, 2) : '-'}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p">
                    Reno Budget
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body1" component="p" style={{
                    fontWeight: 'bold'
                  }}>
                    {publicRecords?.afterRepairValue ? formatCurrency(publicRecords?.afterRepairValue, 2) : '-'}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p">
                    ARV
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body1" component="p" style={{
                    fontWeight: 'bold'
                  }}>
                  {publicRecords?.marketRent ? formatCurrency(publicRecords?.marketRent, 2) : '-'}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p">
                    Market Rent
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </CardContent>
        </Box>
      </Box>
      <PropertyDataComparisonDialog
        store={store}
        open={openCompareDialog}
        onClose={() => setOpenCompareDialog(false)}
      />
    </Card>
  );
}
)