import { Service } from '@roc/client-portal-shared/services';

const url = {
  WIRE_AUTHORIZATION_DOCUSIGN: '/api/v1/loan/public/wireAuthorization/createWireAuthDocusign',
  GET_WIRE_AUTHORIZATION_INFO: '/api/v1/loan/public/wireAuthorization/getWireAuthInfo',
  CONFIRM_DOCUSIGN: '/api/v1/loan/public/wireAuthorization/confirmDocusign',
  CONSENT_REQUEST: '/api/v1/loan/public/wireAuthorization/consent',
  ASSOCIATE_VERIFICATION: '/api/v1/loan/public/wireAuthorization/associate'
};

export class WireAuthorizationService extends Service {
  submitWireAuthorizationFormToDocusign(request, requestId) {
    return this.post(`${url.WIRE_AUTHORIZATION_DOCUSIGN}/${requestId}`, request);
  }

  getWireAuthInfo(requestId) {
    return this.get(`${url.GET_WIRE_AUTHORIZATION_INFO}?requestId=${requestId}`);
  }

  confirmDocusign(requestId) {
    return this.put(`${url.CONFIRM_DOCUSIGN}?requestId=${requestId}`, null);
  }

  consentRequest(requestId) {
    return this.put(`${url.CONSENT_REQUEST}/${requestId}`, null);
  }
  associateVerification(requestId, sessionId) {
    return this.post(url.ASSOCIATE_VERIFICATION, {
      requestId,
      sessionId
    });
  }
}