import { StepsContainer } from '@roc/ui';
import { observer } from 'mobx-react';
import { PublicPersonalInformation } from './publicPersonalInformation';
import { IdInformation } from '../idInformation';
import { PublicCreditInformation } from './publicCreditInformation';
import { FinanciallInformation } from '../financialInformation';
import { insertIf } from '@roc/feature-utils';

export const PublicBorrowerSetupSteps = observer((props) => {

  const { store } = props;
  const { activeStep } = store;

  const steps = [
    {
      title: 'Personal Information',
      icon: null,
      onNext: async () => {
        store.savePersonalInformation();
      },
      allowBack: false,
      allowNext: true,
    },
    {
      title: 'ID Information',
      icon: null,
      onBack: () => {
        store.goPrevStep();
      },
      onNext: async () => {
        store.saveIdInformation();
      },
      allowBack: true,
      allowNext: true,
    },
    {
      title: 'Financial Information',
      icon: null,
      onBack: () => {
        store.goPrevStep();
      },
      onNext: async () => {
        store.saveFinancialInformation();
      },
      allowBack: true,
      allowNext: true,
    },
    ...insertIf(store.currentBorrower?.runCreditOnCreation, [{
      title: 'Credit Information',
      icon: null,
      onBack: () => {
        store.goPrevStep();
      },
      allowBack: true,
      allowNext: false,
    }]
    ),
  ];

  const getStepsContent = () => {
    return [
      <PublicPersonalInformation store={store} />,
      <IdInformation store={store} />,
      <FinanciallInformation store={store} />,
      ...insertIf(store.currentBorrower?.runCreditOnCreation, [
        <PublicCreditInformation store={store} />
      ]),
    ];
  };

  return (
    <StepsContainer
      activeStep={activeStep}
      disableNavigation={true}
      steps={steps}
    >
      {getStepsContent()}
    </StepsContainer>
  )
});