import React, { useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { StandardDialog } from '@roc/ui';
import { observer } from 'mobx-react';
import { ShareDownloadButtons } from '../shareDownloadButtons';
import { ScopeOfWorkV2Stepper } from '../../scopeOfWorkV2';
import { ScopeOfWorkV2FormStore } from '@roc/feature-sow-shared/stores/v2';
import { SuccessDialog } from '../../scopeOfWorkV2/components/successDialog';
import { Box, Grid } from '@material-ui/core';

export type ScopeOfWorkV2FormExternalDialogProps = {
  open?: boolean;
  onClose?: () => void;
  store: ScopeOfWorkV2FormStore;
  title?: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  dialogContentRootClass: {
    padding: 0,
    '&:first-child': {
      paddingTop: 0,
    },
  },
  shareDownloadContainer: {
    padding: theme.spacing(1, 3),
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

export const ScopeOfWorkV2FormExternalDialog = observer(
  (props: ScopeOfWorkV2FormExternalDialogProps) => {
    const { open, onClose, store, title } = props;
    const screenshotContainerRef = useRef(null);

    const classes = useStyles();

    const dialogRef = useRef<HTMLElement>();
    useEffect(() => {
      if (dialogRef.current) {
        dialogRef.current.scrollTo(0, 0);
      }
    }, [dialogRef.current, store.activeStep]);

    const dialogContent = (
      <Box flexGrow={1}>
        <Grid direction="column">
          <div className={classes.shareDownloadContainer}>
            <ShareDownloadButtons
              store={props.store}
              screenshotContainerRef={screenshotContainerRef}
            />
          </div>
          <ScopeOfWorkV2Stepper
            store={store}
            screenshotContainerRef={screenshotContainerRef}
          />
        </Grid>
      </Box>
    );

    return (
      <>
        <StandardDialog
          dialogContentRef={dialogRef}
          open={open}
          title={title ? title : 'Scope of Work'}
          maxWidth="xl"
          removeContentBox
          dialogContentclasses={{
            root: classes.dialogContentRootClass,
          }}
          handleClose={onClose}
          dialogContent={dialogContent}
        />
        <SuccessDialog store={store} />
      </>
    );
  }
);
