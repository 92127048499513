import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { SelectField, TextField } from '@roc/ui';
import { noneSelected, stateMapping } from '@roc/feature-utils';
import { useStore } from '@roc/client-portal-shared/hooks';
import { useBaseStore } from '@roc/feature-app-core';
import FundingPreferenceStore from 'libs/feature-documents/src/documents/stores/documentForms/fundingPreference/fundingPreferenceStore';

const useStyles = makeStyles((theme: Theme) => ({
  formFields: {
    marginTop: '2rem',
  },
}));

const operationStateOptions = [noneSelected].concat(stateMapping);

interface Props {
  store: FundingPreferenceStore;
}

export const OriginatorTab = observer(({ store }: Props) => {
  const classes = useStyles();
  const { globalStore } = useBaseStore();
  const formFields = store.form.fields;
  const { isClosingAttorney } = globalStore.userFeatures;
  return (
    <div>
      <Grid container direction="row" justify="space-around">
        <Grid item xs={7} className={classes.formFields}>
          <TextField
            disabled={true}
            standaloneLabel
            label="Lender Name"
            value={formFields.lenderName.value}
            variant="outlined"
            fullWidth
            testId="lenderName"
          />
        </Grid>
        <Grid item xs={7} className={classes.formFields}>
          <SelectField
            disabled={!store.canEdit}
            standaloneLabel
            label={'Operating State'}
            name={'operatingState'}
            value={formFields.operatingState.value}
            options={operationStateOptions}
            variant="outlined"
            fullWidth
            testId="operatingState"
            onChange={value => store.onFieldChange('operatingState', value)}
          />
        </Grid>
      </Grid>
    </div>
  );
});
