import { Search } from '@material-ui/icons';
import { createAutocompleteAddressField } from '@roc/ui';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { Grid, InputAdornment, Theme, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import { useStore } from '@roc/client-portal-shared/hooks';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontSize: '34px'
    },
    subtitle: {
      fontSize: '14px'
    }
  })
);


export const CompHeader = observer(() => {
  const { compToolStore } = useStore();

  const classes = useStyles();

  const { searchPropertyFormStore, compResponse } = compToolStore;

  const { properties, pages } = compResponse

  const size = (properties?.length - 1) * pages;

  const fields = searchPropertyFormStore.form.fields;

  const handleAddressChange = event => {
    const { value, fullInfo } = event?.target || {};
    const { address_components, geometry } = fullInfo || {};
    searchPropertyFormStore.saveAddressField(value, address_components, geometry);
    if (address_components && geometry) {
      compToolStore.reset();
      compToolStore.fetchCompToolProperties(1, true);
    }
  };

  return (
    <>
      <Grid item xs={12} lg={6}>
        <Typography className={classes.title}>
          Comparable Properties Analytics
        </Typography>
        <Typography className={classes.subtitle}>
          {`${size} Comparable Properties near ${fields.completeAddress.value}.`}
        </Typography>
      </Grid>
      <Grid item xs={12} lg={6}>
        {createAutocompleteAddressField({
          fieldName: 'address',
          testId: 'address',
          store: searchPropertyFormStore,
          onChange: handleAddressChange,
          label: 'Subject Property Address',
          showToolTip: false,
          standaloneLabel: true,
          InputLabelProps: ({ shrink: true }),
          InputProps: ({
            endAdornment: (
              <InputAdornment position="end" >
                <Search />
              </InputAdornment>
            )
          }),
          compTool: true
        })}
      </Grid>
    </>
  );
});