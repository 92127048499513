import {
  Box,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { Business, Event, Update } from '@material-ui/icons';
import { useLoanStore } from '@roc/feature-loans-shared';
import { Paper } from '@roc/ui';
import { useEffect, useState } from 'react';

const useStyles = makeStyles(theme => ({
  container: {
    padding: 32,
  },
}));

const listItem = (label, value, divider: boolean = true) => {
  return (
    (
      <>
        {divider && <Divider variant="middle" component="li" />}
        <ListItem>
          <ListItemText>
            <Box display="flex">
              <Box flexGrow={1}>{label}</Box>
              <span />
              <Box>{value}</Box>
            </Box>
          </ListItemText>
        </ListItem>
      </>
    )
  );
};
interface ExtensionKeyDatesProps {

}
const ExtensionKeyDates = (props: ExtensionKeyDatesProps) => {
  const { loanStore } = useLoanStore();
  const classes = useStyles();
  const [insurace, setInsurance] = useState("");
  useEffect(() => {
    if (loanStore?.loanDetails?.loanInsurance?.insuredThroughElmsure === true || loanStore?.loanDetails?.insuredThroughElmsure === true) {
      setInsurance("ElmSure")
    } else {
      setInsurance("Other")
    }
  }, []);
  return (
    <Grid item xs={12} md={6}>
      <Paper title="LOAN KEY INFORMATION" style={{ height: '240px' }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Grid container direction="column">
              <List>
                {listItem('Insurance Company', insurace, false)
                }
                {listItem('Maturity Date', loanStore?.loanDetails.maturityDate)
                }
                {listItem('Extended Maturity Date', loanStore?.loanDetails.extendedMaturityDate)
                }
              </List>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>

  );
};

export const ExtensionKeyDatesNew = (props: ExtensionKeyDatesProps) => {
  const { loanStore } = useLoanStore();
  const classes = useStyles();
  const [insurace, setInsurance] = useState("");
  useEffect(() => {
    if (loanStore?.loanDetails?.loanInsurance?.insuredThroughElmsure === true || loanStore?.loanDetails?.insuredThroughElmsure === true) {
      setInsurance("ElmSure")
    } else {
      setInsurance("Other")
    }
  }, []);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <Paper style={{
          background: 'linear-gradient(135deg, rgba(118, 176, 241, 0.2), rgba(32, 101, 209, 0.2)) rgb(255, 255, 255)',
          boxShadow: 'none',
          height: '100%',
          display: 'flex',
          alignItems: 'center'
        }}>
          <Box p={4} width={'100%'} textAlign={'center'}>
            <Box mb={.5}>
              <Business />
            </Box>
            <Typography variant='h4' gutterBottom>
              {insurace}
            </Typography>
            <Typography variant='body2'>
              Insurance Company
            </Typography>
          </Box>
        </Paper>
      </Grid>
      <Grid item xs={12} md={4}>
        <Paper style={{
          background: 'linear-gradient(135deg, rgba(97, 243, 243, 0.2), rgba(0, 184, 217, 0.2)) rgb(255, 255, 255)',
          boxShadow: 'none',
          height: '100%',
          display: 'flex',
          alignItems: 'center'
        }}>
          <Box p={4} width={'100%'} textAlign={'center'}>
            <Box mb={.5}>
              <Event />
            </Box>
            <Typography variant='h4' gutterBottom>
              {loanStore?.loanDetails?.maturityDate ?? '-'}
            </Typography>
            <Typography variant='body2'>
              Maturity Date
            </Typography>
          </Box>
        </Paper>
      </Grid>
      <Grid item xs={12} md={4}>
        <Paper style={{
          background: 'linear-gradient(135deg, rgba(255, 214, 102, 0.2), rgba(255, 171, 0, 0.2)) rgb(255, 255, 255)',
          boxShadow: 'none',
          height: '100%',
          display: 'flex',
          alignItems: 'center'
        }}>
          <Box p={4} width={'100%'} textAlign={'center'}>
            <Box mb={.5}>
              <Update />
            </Box>
            <Typography variant='h4' gutterBottom>
              {loanStore?.loanDetails?.extendedMaturityDate ?? '-'}
            </Typography>
            <Typography variant='body2'>
              Extended Maturity Date
            </Typography>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default ExtensionKeyDates;
