import { isNotBlank } from "@roc/feature-utils";
import { RadioField } from "@roc/ui";
import { observer } from "mobx-react";

const options = [
  { label: 'Yes', value: 'Y' },
  { label: 'No', value: 'N' },
];

export const CashOutForm = observer(
  ({ store, onChange = null, ...rest }) => {
    const formFields = store.form.fields;

    const handleChange = value => {
      store.onFieldChange('cashOut', value);
      onChange && onChange('cashOut', value);
    };

    return (
      <RadioField
        standaloneLabel
        label="Are you looking for cash-out?"
        value={formFields.cashOut.value}
        errorText={formFields.cashOut.error}
        error={isNotBlank(formFields.cashOut.error)}
        name={'cashOut'}
        options={options}
        onChange={handleChange}
        fullWidth
        required
        row
        testId="cashOut"
        {...rest}
      />
    );
  }
);