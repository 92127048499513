import { FormStore, GlobalStore } from '@roc/feature-app-core';
import { LoanStore } from '@roc/feature-loans';
import { DialogState } from '@roc/ui';
import { format, differenceInDays, startOfToday } from 'date-fns';
import { action, flow, makeObservable, observable } from 'mobx';
import { LoanSubType } from '@roc/feature-utils';
import {
  InsuranceCompanyType,
  InsurancePaymentType,
  QuoteType,
} from '../../../components/documentForms/insuranceApproval/constants';
import { InsuranceApprovalService } from '../../../services/documentForms/insuranceApproval/insuranceApprovalService';
import InsuranceApprovalFormStore from './insuranceApprovalFormStore';

const form = {
  fields: {
    quoteType: {
      value: '',
      error: null,
      rule: 'required',
    },
    cost: {
      value: '',
      error: null,
      rule: 'required|min:1',
      message: 'Please enter a valid cost',
    },
    paidAlreadyAmount: {
      value: '',
      error: null,
      rule: 'required',
      message: 'Please enter a valid amount',
    },
    remainingAmount: {
      value: '',
      error: null,
      rule: '',
    },
    paymentType: {
      value: '',
      error: null,
      rule: '',
    },
    selectedLoanSubType: {
      value: null,
      error: null,
      rule: '',
    },
    policyExpirationDate: {
      value: '',
      error: null,
      rule: '',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export class NewQuoteFormStore extends FormStore {
  private globalStore: GlobalStore;
  private loanStore: LoanStore;
  private insuranceApprovalFormStore: InsuranceApprovalFormStore;
  private insuranceApprovalService: InsuranceApprovalService;

  dialogState: DialogState;
  currentQuote: any;

  constructor(
    globalStore: GlobalStore,
    loanStore: LoanStore,
    insuranceApprovalFormStore: InsuranceApprovalFormStore
  ) {
    super(form, globalStore);
    this.globalStore = globalStore;
    this.loanStore = loanStore;
    this.insuranceApprovalFormStore = insuranceApprovalFormStore;

    this.insuranceApprovalService = new InsuranceApprovalService();

    makeObservable(this, {
      dialogState: observable,
      currentQuote: observable,
      openAdd: action,
      openEdit: action,
      closeDialog: action,
      updateCalculatedFields: flow,
      validateForm: flow,
      updateInsuranceDetails: flow,
    });
  }

  openAdd() {
    this.currentQuote = null;
    this.reset();
    this.dialogState = DialogState.ADD;
  }

  openEdit(quote) {
    this.currentQuote = quote;
    this.reset();
    this.loadForm(this.currentQuote);
    this.dialogState = DialogState.EDIT;
  }

  closeDialog() {
    this.dialogState = null;
  }

  *updateCalculatedFields() {
    const values = this.getFormValues();
    const cost = parseFloat(values.cost || 0);
    const paidAlreadyAmount = parseFloat(values.paidAlreadyAmount || 0);

    if (paidAlreadyAmount > cost) {
      this.onFieldChange('paidAlreadyAmount', cost);
    }

    const remainingAmount = cost - paidAlreadyAmount;
    this.onFieldChange('remainingAmount', remainingAmount);

    const paymentType =
      remainingAmount === 0
        ? InsurancePaymentType.PAID_ALREADY
        : InsurancePaymentType.PAY_THROUGH_HUD;

    this.onFieldChange('paymentType', paymentType);
  }


  *validateForm() {
    this.runFormValidationWithMessage();
    if (!this.form.meta.isValid) {
      return false;
    }

    const { quoteType } = this.getFormValues();
    const { policyExpirationDate } = this.getFormValues();
    const { loanId } = this.loanStore.loanDetails;
    const { loanSubType } = this.loanStore.loanDetails;
    const { otherQuotes } = this.insuranceApprovalFormStore;

    let allowedTypes = [];
    let quoteTypes = [];

    otherQuotes.map(data => {
      if (this.currentQuote?.insuranceDetailId !== data.insuranceDetailId) {
        quoteTypes.push(data.quoteType);
      }
    });

    if (
      quoteTypes.includes(QuoteType.COMMERCIAL_PACKAGE_AND_FLOOD) ||
      (quoteTypes.includes(QuoteType.COMMERCIAL_PACKAGE) &&
        quoteTypes.includes(QuoteType.FLOOD))
    ) {
      allowedTypes = [QuoteType.PROPERTY_ONLY, QuoteType.LIABILITY_ONLY];
    } else if (quoteTypes.includes(QuoteType.FLOOD)) {
      allowedTypes = [QuoteType.COMMERCIAL_PACKAGE, QuoteType.PROPERTY_ONLY, QuoteType.LIABILITY_ONLY];
    } else if (quoteTypes.includes(QuoteType.COMMERCIAL_PACKAGE)) {
      allowedTypes = [QuoteType.FLOOD, QuoteType.PROPERTY_ONLY, QuoteType.LIABILITY_ONLY];
    } else {
      allowedTypes = [
        QuoteType.COMMERCIAL_PACKAGE,
        QuoteType.COMMERCIAL_PACKAGE_AND_FLOOD,
        QuoteType.FLOOD,
        QuoteType.PROPERTY_ONLY,
        QuoteType.LIABILITY_ONLY
      ];
    }

    if (!allowedTypes.includes(quoteType)) {
      this.globalStore.notificationStore.showWarningNotification({
        message: `Quote type ${quoteType} not supported for the loan ${loanId}`,
      });
      return false;
    }

    const ls = loanSubType === LoanSubType.SINGLE_PROPERTY;
    const qt = quoteType !== QuoteType.FLOOD;
    if (ls && qt) {
      if (policyExpirationDate === null || policyExpirationDate === '') {
        this.globalStore.notificationStore.showWarningNotification({
          message: `Policy Expiration Date is required`,
        });
        return false;
      }
      if (policyExpirationDate !== null &&
        differenceInDays(policyExpirationDate, startOfToday()) < 120) {
        this.globalStore.notificationStore.showWarningNotification({
          message: `Policy Expiration Date must be at least 4 months until expiry`,
        });
        return false;
      }
    }
    return true;
  }

  *updateInsuranceDetails() {
    try {
      const isValid = yield this.validateForm();
      if (isValid) {
        const { loanId } = this.loanStore.loanDetails;
        const formValues = this.getFormValues();

        if (this.dialogState === DialogState.ADD) {
          yield this.insuranceApprovalService.updateInsuranceDetails({
            ...formValues,
            loanId,
            insuranceCompany: InsuranceCompanyType.OTHER,
            selectedQuote: false,
            policyExpirationDate: formValues.policyExpirationDate ? format(formValues.policyExpirationDate, 'MM/dd/yyyy') : null,
          });
        } else {
          yield this.insuranceApprovalService.updateInsuranceDetails({
            ...this.currentQuote,
            ...formValues,
            policyExpirationDate: formValues.policyExpirationDate ? format(formValues.policyExpirationDate, 'MM/dd/yyyy') : null,
          });
        }

        yield this.insuranceApprovalFormStore.getInsuranceSummary();
        this.closeDialog();
        this.globalStore.notificationStore.showSuccessNotification({
          message: 'Quote information saved successfully.',
        });
      }
    } catch (err) {
      this.globalStore.notificationStore.showErrorNotification({
        message:
          'An error occurred while saving the quote information. Please try again.',
      });
    }
  }
}
