import { Grid } from '@material-ui/core';
import { observer } from 'mobx-react';
import { useStore } from '@roc/client-portal-shared/hooks';
import { BorrowerSetupOptions } from './components/borrowerSetupOptions';
import { BorrowerSetupErrors } from './components/borrowerSetupErrors';
import { BorrowerSetupSteps } from './components/borrowerSetupSteps';
import { BorrowerSetupLink } from './components/borrowerSetupLink';
import { BorrowerSetupSections } from './components/borrowerSetupSections';
import { useEffect } from 'react';
import { BorrowerSetupSectionsWithIdVerification } from './components/withIdVerification/borrowerSetupSectionsWithIdVerification';
import { BorrowerSetupStepsWithIdVerification } from './components/withIdVerification/borrowerSetupStepsWithIdVerification';

export type BorrowerSetupToDoProps = {
  borrowerId: number;
  loan?: any;
  handleClose: () => void;
  refreshLoan?: () => void;
};

export const BorrowerSetupToDo = observer((props: BorrowerSetupToDoProps) => {

  const { borrowerId, loan, handleClose, refreshLoan } = props;

  const { borrowerSetupToDoStore } = useStore();
  const { isBorrowerUser, showStepsLayout } = borrowerSetupToDoStore;

  useEffect(() => {
    borrowerSetupToDoStore.reset();
    borrowerSetupToDoStore.loadBorrowerPrescreenInfo(borrowerId, loan);
  }, []);

  return (
    <Grid container spacing={2} style={{
      width: '100%',
      minHeight: '180px'
    }}>
      {!isBorrowerUser &&
        <BorrowerSetupOptions store={borrowerSetupToDoStore} refreshLoan={refreshLoan} />
      }
      {borrowerSetupToDoStore.borrowerDetailsVerification && !isBorrowerUser &&
        <BorrowerSetupErrors store={borrowerSetupToDoStore} />
      }
      {borrowerSetupToDoStore.borrowerDetailsVerification &&
        <>
          {borrowerSetupToDoStore.sendIdVerificationPending && borrowerSetupToDoStore?.setupPending && !isBorrowerUser ?
            <BorrowerSetupLink store={borrowerSetupToDoStore} />
            :
            <>
              {showStepsLayout === true &&
                <>
                  {borrowerSetupToDoStore.borrowerDetailsVerification.borrowerSetupIdVerificationEnabled && borrowerSetupToDoStore.sendIdVerification && isBorrowerUser ?
                    <BorrowerSetupStepsWithIdVerification store={borrowerSetupToDoStore} handleClose={handleClose} />
                    :
                    <BorrowerSetupSteps store={borrowerSetupToDoStore} handleClose={handleClose} />
                  }
                </>
              }
              {showStepsLayout === false &&
                <>
                  {borrowerSetupToDoStore.borrowerDetailsVerification.borrowerSetupIdVerificationEnabled && borrowerSetupToDoStore.sendIdVerification ?
                    <BorrowerSetupSectionsWithIdVerification store={borrowerSetupToDoStore} handleClose={handleClose} />
                    :
                    <BorrowerSetupSections store={borrowerSetupToDoStore} handleClose={handleClose} />
                  }
                </>
              }
            </>
          }
        </>
      }
      <Grid item xs={12} />
    </Grid>
  );
});