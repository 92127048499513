import { Button, StandardDialog } from '@roc/ui';
import { useDocumentStore } from '@roc/feature-documents';
import { Document } from '@roc/feature-types';
import { PersonalIdentificationForm } from './personalIdentificatonForm';
import { useEffect } from 'react';
import { observer } from 'mobx-react';
import SaveButtonWithPermissions from 'libs/ui/src/lib/components/shared/save-button-with-permissions';

type Props = {
  open?: boolean;
  onClose?: () => void;
  loan: any;
  document: Document;
};

export const PersonalIdentificationDialog = observer((props: Props) => {
  const { open, onClose, loan, document } = props;

  const { documentStore } = useDocumentStore();
  const { documentFormStore } = documentStore;
  const { personalIdentificationFormStore } = documentFormStore;

  useEffect(() => {
    if (open) {
      const { loanId, borrowerId } = document;
      personalIdentificationFormStore.reset();
      personalIdentificationFormStore.getIdentificationTypeData(
        loanId,
        borrowerId
      );
    }
  }, [open]);

  const sumbit = async () => {
    const { loanId, borrowerId, loanDocumentId } = document;
    await personalIdentificationFormStore.saveIdentificationTypeData(
      loanId,
      borrowerId,
      loanDocumentId
    );
    onClose();
  };

  return (
    <StandardDialog
      open={open}
      title="Personal Identification"
      maxWidth="sm"
      handleClose={onClose}
      dialogContent={<PersonalIdentificationForm document={document} />}
      dialogActions={
        <>
          <Button
            style={{ marginRight: '8px' }}
            onClick={onClose}
            color="primary"
            variant="contained"
            testId="cancel"
          >
            Cancel
          </Button>
          <SaveButtonWithPermissions
            variant="contained"
            color="primary"
            onClick={sumbit}
            testId="next"
            label="Submit"
            loanId={loan?.loanId}
          />
        </>
      }
    />
  );
});
