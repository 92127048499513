import { Layout } from '@roc/feature-app-core';
import { Tabs } from '@roc/ui';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { DelinquentLoansDashboard } from './delinquentLoansDashboard';
import { MaturityLoansDashboard } from './maturityLoansDashboard';

const TABS = [
  {
    label: 'Delinquent Loans',
  },
  {
    label: 'Maturity Loans',
  },
];


const useStyles = makeStyles((theme: Theme) => ({
  dialogStyle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  buttonStyle: {
    marginRight: '10px',
    height: '40px'
  }
}));

export const MaturityAndDqInformation = observer(() => {
  const [selectedTab, setSelectedTab] = useState(0);
  const classes = useStyles();

  const onTabChange = (e, index) => setSelectedTab(index);

  return (
    <Layout>
      <Tabs
        tabs={TABS}
        selectedTab={selectedTab}
        onTabChange={onTabChange}
      />
      {selectedTab === 0 && (
        <DelinquentLoansDashboard />
      )}
      {selectedTab === 1 && (
        <MaturityLoansDashboard />
      )}

    </Layout>
  );
});