import { StandardDialog } from '@roc/ui';
import { ComparableProperty } from '../../types/propertyTypes';
import { AllPhotosScreen } from './allPhotosScreen';

export type AllPhotosDialogProps = {
  open?: boolean;
  onClose?: () => void;
  property: ComparableProperty;
};

export const AllPhotosDialog = (props: AllPhotosDialogProps) => {
  const { open, onClose, property } = props;

  return (
    <StandardDialog
      open={open}
      maxWidth="md"
      onClose={onClose}
      removeContentBox={true}
      style={{ overflow: 'hidden' }}
      dialogContent={<AllPhotosScreen onClose={onClose} property={property} />}
    />
  );
};

export default AllPhotosDialog;
