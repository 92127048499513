import {
  Badge,
  Box,
  Chip,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu
} from '@material-ui/core';
import { lighten, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import { ImportExport, MoreVert, NavigateNext, PriorityHigh, Visibility, CheckCircleOutline, Check } from '@material-ui/icons';
import {
  InfiniteScrollGrid, useBaseStore
} from '@roc/feature-app-core';
import { bridgeLoanSubTypes, LoanStatus, LoanSubType, PORTAL_COLORS, Roles, Utility } from '@roc/feature-utils';
import {
  ConfirmationMenuItem, LinkColumn, SelectField, SelectFilterComponent,
  SelectFloatingFilterComponent, StatusChip,
  StatusType, SubmitMenuItem, TextColumn, FastForwardIcon, MenuItem
} from '@roc/ui';
import { observer } from 'mobx-react';
import React, { useState, useEffect } from 'react';
import { useLoanRoutes } from '@roc/feature-loans-routes-config';
import { useLoanStore } from '../hooks/useLoanStore';
import { LoanPriority, loanPriorityOptions } from '../utils/loansConstants';
import { ChangeLoanStatusForm } from './changeLoanStatusForm';
import { useUserStore } from '@roc/feature-app-core';
import { useProposalsRoutes } from '@roc/feature-proposals';
import { useHistory } from 'react-router';
import { useFixFlipStore, useLoanSubmissionRoutes } from '../../loanSubmission';
import { proposalStatus as proposalStatusOptions } from 'libs/feature-proposals/src/utils/constants';
import { useInsuranceReviewStore } from '@roc/feature-insurance-review';

export const StyledBadge = withStyles(theme => ({
  badge: {
    right: -12,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
    fontSize: '11px',
    backgroundColor: PORTAL_COLORS.unreadMessages,
    color: 'white',
    fontWeight: 700,
    userSelect: 'none',
  },
}))(Badge);

const useStyles = makeStyles((theme) => ({
  redRow: {
    '& .ag-cell': {
      backgroundColor: lighten(theme.palette.error.main, 0.85)
    }
  }
}))

export const getUnreadMessagesCount = unreadMessages => {
  const { userStore } = useUserStore();
  const allowLoanPricing = userStore.allowLoanPricing;

  if (unreadMessages && allowLoanPricing) {
    return unreadMessages > 99 ? '99+' : unreadMessages;
  }
  return undefined;
};

export const MyPipelineLoansGrid = observer(({ columns }) => {
  const classes = useStyles();
  const { loanStore } = useLoanStore();
  const { loanRoutesConfig } = useLoanRoutes();
  const { insuranceReviewers } = loanStore;
  const { insuranceReviewStore } = useInsuranceReviewStore();
  const { globalStore } = useBaseStore();
  const [loanId, setLoanId] = useState(0);
  const [anchorEl, setAnchorEl] = useState<HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const [showMarkAsHighPriority, setShowMarkAsHighPriority] = useState(true);
  const [showChangePriority, setShowChangePriority] = useState(false);
  const [
    selectPriorityValue,
    setSelectPriorityValue,
  ] = useState<LoanPriority>();
  const { userStore } = useUserStore();
  const allowLoanPricing = userStore.allowLoanPricing;
  const [
    selectAssignee,
    setSelectAssignee,
  ] = useState(null);
  const history = useHistory();
  const { proposalsRoutesConfig } = useProposalsRoutes();
  const { loanSubmissionRoutesConfig } = useLoanSubmissionRoutes();
  const { fixFlipStore } = useFixFlipStore();
  const [showChangeStatus, setShowChangeStatus] = useState(true);
  const [showProposalView, setShowProposalView] = useState(false);
  const [showContinueProposal, setShowContinueProposal] = useState(false);
  const [showMarkProposalAsCompleted, setShowMarkProposalAsCompleted] = useState(false);
  const [currentRow, setCurrentRow] = useState(null);

  useEffect(() => {
    loanStore.fetchInternalInsuranceReviewers();
  }, []);


  const insReview = (insuranceReviewers) => {
    return (insuranceReviewers.map(user => {
      return { label: user.contactName, value: user.userName }
    }));
  }

  const isDealRoom = (data) => {
    return Utility.PROPOSAL_INFORMATION === data?.utility;
  }

  const getUrl = (row) => {
    const loanId = row?.dealid;
    return !isDealRoom(row)
      ? loanRoutesConfig.loans(loanId).children.dashboard.url
      : proposalsRoutesConfig.viewProposal(loanId).url;
  }

  const frameworkComponents = {
    selectFilter: SelectFilterComponent,
    selectFloatingFilter: SelectFloatingFilterComponent,
    loanIdCellRenderer: params =>
      loanStore.isLoanSubtypeSupported(params.node.data?.loanSubType) ? (
        <StyledBadge
          badgeContent={getUnreadMessagesCount(
            params.node.data?.unreadMessages
          )}
        >
          {LinkColumn({
            ...params,
            url: getUrl(params.node.data),
          })}
        </StyledBadge>
      ) : (
        <StyledBadge
          badgeContent={getUnreadMessagesCount(
            params.node.data?.unreadMessages
          )}
        >
          {TextColumn(params)}
        </StyledBadge>
      ),
    pendingDocumentsCellRenderer: ({ value }) => {
      if (!value) {
        return null;
      }
      return (
        <Box pt={1} display="flex" justifyContent="center">
          <Chip
            size="small"
            label={value}
            style={{
              backgroundColor: PORTAL_COLORS.pendingDocuments,
              color: 'white',
              fontWeight: 700,
            }}
          />
        </Box>
      );
    },
    fastTrackCellRenderer: ({ value }) => {
      if (!value) {
        return null;
      }
      return (<FastForwardIcon value={value} />)
    },
    appraisalReportUploadedCellRenderer: ({ value }) => {
      if (!value) {
        return null;
      }
      return (<Chip
        size="small"
        label={value ? "Yes" : "No"}
        style={{
          backgroundColor: '#89C053',
          color: 'white',
          fontWeight: 700,
        }}
      />)
    },
    dealNameCellRenderer: params => {
      return LinkColumn({
        ...params,
        url: getUrl(params.node.data),
      });
    },
    statusCellRenderer: ({ value }) =>
      value ? (
        <StatusChip label={value} size="small" statusType={StatusType.LOAN} />
      ) : null,
    insuranceStatusCellRenderer: ({ value }) =>
      value ? (
        <StatusChip label={value} size="small" statusType={StatusType.INSURANCE_STATUS} />
      ) : null,
    preScreenStatusCellRenderer: ({ value }) =>
      value ? (
        <StatusChip label={value} size="small" statusType={StatusType.PRESCREEN} />
      ) : null,
    actionCellRenderer: params => {
      return (
        <IconButton onClick={e => openMenu(e, params.node.data)}>
          <MoreVert color="primary" />
        </IconButton>
      );
    },
  };

  const openMenu = (event: React.MouseEvent<HTMLElement>, record) => {
    const {
      dealid,
      priority,
      loanSubType,
      keyDocumentsCompletedForSFRLoans,
      proposalStatus
    } = record;

    const { insuranceReviewers } = loanStore;

    const isBridgeLoan = bridgeLoanSubTypes.includes(loanSubType);

    setLoanId(dealid);
    setCurrentRow(record);

    setSelectPriorityValue(priority);
    setSelectAssignee(insuranceReviewers.length > 0 ? insuranceReviewers[0].value : null);
    if (!isDealRoom(record)) {
      setShowProposalView(false);
      setShowContinueProposal(false);
      setShowChangeStatus(true);
      if (isBridgeLoan) {
        setShowChangePriority(true);
        setShowMarkAsHighPriority(false);
      } else {
        setShowChangePriority(false);
        setShowMarkAsHighPriority(
          priority?.toLowerCase() === 'low' && keyDocumentsCompletedForSFRLoans
        );
      }
    }
    else {
      setShowChangePriority(false);
      setShowMarkAsHighPriority(false);
      setShowChangeStatus(false);
      setShowMarkProposalAsCompleted(true);
      switch (proposalStatus) {
        case proposalStatusOptions.NEW:
        case proposalStatusOptions.PENDING:
          setShowProposalView(true);
          setShowContinueProposal(false);
          break;
        case proposalStatusOptions.AGREED:
          setShowProposalView(true);
          setShowContinueProposal(true);
          break;
      }
    }
    if (globalStore.userFeatures?.isInsuranceReviewer) {
      setShowChangePriority(false);
      setShowMarkAsHighPriority(false);
      setShowChangeStatus(false);
    }
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setLoanId(null);
    setCurrentRow(null);
  };

  const checkLoanPricingFlag = (columns) => {
    if (!allowLoanPricing) {
      return columns.filter(c => c.field != "interestEconomics" && c.field != "keyRatios");
    } else {
      return columns;
    }
  }

  const getRowClass = (params) => {
    if (params.node.data?.status === LoanStatus.PRE_SUBMISSION || params.node.data?.status === LoanStatus.PENDING_SUBMISSION) {
      return classes.redRow
    }
  }

  const viewProposal = (id) => {
    const url = proposalsRoutesConfig.viewProposal(id).url;
    history.push(url);
  };

  const navigateToLoanSubmissionFlow = (id) => {
    fixFlipStore.setProposalId(id);

    const loanSubtype = currentRow?.loanSubType;

    if (loanSubtype === LoanSubType.GROUND_UP) {
      fixFlipStore.setLoanType(LoanSubType.GROUND_UP);
      history.push(loanSubmissionRoutesConfig.submitloan.children.groundUp.url);
    } else if (loanSubtype === LoanSubType.FIX_AND_FLIP) {
      fixFlipStore.setLoanType(LoanSubType.FIX_AND_FLIP);
      history.push(loanSubmissionRoutesConfig.submitloan.children.fixFlip.url);
    } else if (loanSubtype === LoanSubType.FIX_AND_FLIP_PRO) {
      fixFlipStore.setLoanType(LoanSubType.FIX_AND_FLIP_PRO);
      history.push(loanSubmissionRoutesConfig.submitloan.children.fixFlipPro.url);
    }
  };

  return (
    <>
      <InfiniteScrollGrid
        columns={checkLoanPricingFlag(columns)}
        frameworkComponents={frameworkComponents}
        store={loanStore.myPipelineLoansGridStore}
        getRowClass={getRowClass}
      />
      <Menu
        id="menu-appbar"
        getContentAnchorEl={null}
        anchorEl={anchorEl}
        keepMounted
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={menuOpen}
        onClose={handleMenuClose}
      >
        {showChangePriority && (
          <SubmitMenuItem
            testId={`select-priority-${loanId}`}
            onClick={() => {
              if (selectPriorityValue) {
                loanStore.changeLoanPriority(loanId, selectPriorityValue);
                handleMenuClose();
              }
            }}
            submitDialogProps={{
              title: `Loan ${loanId}`,
              body: (
                <SelectField
                  standaloneLabel
                  label={'Please set the urgency of the deal:'}
                  value={selectPriorityValue}
                  required={true}
                  options={loanPriorityOptions}
                  onChange={value => setSelectPriorityValue(value)}
                  variant={'outlined'}
                  fullWidth
                  testId={'select-priority-dropdown'}
                />
              ),
              okButtonText: 'Submit',
              cancelButtonText: 'Close',
            }}
          >
            <ListItemIcon>
              <PriorityHigh fontSize="small" />
            </ListItemIcon>
            <ListItemText>Change Priority</ListItemText>
          </SubmitMenuItem>
        )}
        {showMarkAsHighPriority && (
          <ConfirmationMenuItem
            testId={`mark-as-high-${loanId}`}
            onClick={() => {
              loanStore.changeLoanPriority(loanId, LoanPriority.HIGH);
              handleMenuClose();
            }}
            confirmDialogProps={{
              body: `Are you sure you want to mark loan ${loanId} as high priority?`,
            }}
          >
            <ListItemIcon>
              <PriorityHigh fontSize="small" />
            </ListItemIcon>
            <ListItemText>Mark as High Priority</ListItemText>
          </ConfirmationMenuItem>
        )}
        {showChangeStatus &&
          <SubmitMenuItem
            testId={`change-loan-status-${loanId}`}
            onClick={() => {
              loanStore.submitFormStatus(loanId);
              handleMenuClose();
            }}
            submitDialogProps={{
              title: `Change Status for Loan ${loanId}`,
              body: <ChangeLoanStatusForm loanId={loanId} />,
              okButtonText: 'Submit',
              submitValidation: () => {
                loanStore.loanStatusStore.runFormValidation();
                return loanStore.loanStatusStore.form.meta.isValid;
              },
            }}
          >
            <ListItemIcon>
              <ImportExport fontSize="small" />
            </ListItemIcon>
            <ListItemText>Change Status</ListItemText>
          </SubmitMenuItem>
        }

        {globalStore.userFeatures?.internalInsuranceReviewerAdmin && (
          <SubmitMenuItem
            testId={`change-assignee-${loanId}`}
            onClick={() => {
              loanStore.changeAssignee(loanId, selectAssignee, () => loanStore.myPipelineLoansGridStore.resetAndFetchGridData());
              handleMenuClose();
            }}
            submitDialogProps={{
              title: `Loan ${loanId}`,
              body: (
                <SelectField
                  standaloneLabel
                  label={'Please choose the new insurance reviewer specialist for this loan:'}
                  value={selectAssignee}
                  required={true}
                  options={insReview(insuranceReviewers)}
                  onChange={value => setSelectAssignee(value)}
                  variant={'outlined'}
                  fullWidth
                  testId={'select-assignee-dropdown'}
                />
              ),
              okButtonText: 'Submit',
              cancelButtonText: 'Close',
            }}
          >
            <ListItemIcon>
              <ImportExport fontSize="small" />
            </ListItemIcon>
            <ListItemText>Change Reviewer Specialist</ListItemText>
          </SubmitMenuItem>
        )}

        {globalStore.userFeatures?.internalInsuranceReviewer && (
          <ConfirmationMenuItem
            testId={`mark-as-pending-${loanId}`}
            onClick={() => {
              insuranceReviewStore.moveToPending(loanId, () => {
                loanStore.myPipelineLoansGridStore.resetAndFetchGridData();
              });
              handleMenuClose();
            }}
            confirmDialogProps={{
              body: `Are you sure you want to add the loan ${loanId} to my pending tasks?`,
            }}
          >
            <ListItemIcon>
              <Check fontSize="small" />
            </ListItemIcon>
            <ListItemText>Add to My Pending Tasks</ListItemText>
          </ConfirmationMenuItem>
        )}
        {showProposalView &&
          <MenuItem
            testId='view-item'
            onClick={() => {
              viewProposal(loanId);
              handleMenuClose();
            }}
          >
            <ListItemIcon>
              <Visibility />
            </ListItemIcon>
            <ListItemText>View Submission</ListItemText>
          </MenuItem>
        }
        {showContinueProposal &&
          <MenuItem
            testId='continue-item'
            onClick={() => {
              navigateToLoanSubmissionFlow(loanId);
              handleMenuClose();
            }}>
            <ListItemIcon>
              <NavigateNext />
            </ListItemIcon>
            <ListItemText>Continue</ListItemText>
          </MenuItem>
        }
        {showMarkProposalAsCompleted &&
          <MenuItem
            testId='mark-completed-proposal'
            onClick={() => {
              fixFlipStore.markProposalAsCompleted(loanId);
              handleMenuClose();
            }}>
            <ListItemIcon>
              <CheckCircleOutline />
            </ListItemIcon>
            <ListItemText>Mark Proposal as Completed</ListItemText>
          </MenuItem>
        }
      </Menu>
    </>
  );
});
