import { Grid } from '@material-ui/core';
import { observer } from 'mobx-react';
import { ExpectedClosingDateStore } from '../../../stores/documentForms/expectedClosingDateStore';
import { createDateField } from '@roc/ui';
import { useEffect } from 'react';

export type ExpectedClosingDateProps = {
  store: ExpectedClosingDateStore;
};

export const ExpectedClosingDate = observer(
  (props: ExpectedClosingDateProps) => {
    const { store } = props;

    useEffect(() => {
      store.initialize();
    }, []);

    return (
      <>
        <Grid container spacing={1} justify="center">
          <Grid item xs={12}>
            {createDateField({
              store,
              testId: 'date',
              fieldName: 'date',
              label: '',
              disablePast: true,
              disabled: !store.shouldBeAbleToSubmitExpectedClosingDate(),
            })}
          </Grid>
        </Grid>
      </>
    );
  }
);

export default ExpectedClosingDate;
