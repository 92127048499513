import { useBaseStore } from '@roc/feature-app-core';
import { getQuoteGeneratorRoutes, getQuoteGeneratorRoutesConfig } from '../routes/quoteGeneratorRoutes';

export const useQuoteGeneratorRoutes = () => {
  const { globalStore } = useBaseStore();
  const { routeBasePath, routeBaseUrl } = globalStore;

  return {
    quoteGeneratorRoutes: getQuoteGeneratorRoutes(routeBasePath, routeBaseUrl),
    quoteGeneratorRoutesConfig: getQuoteGeneratorRoutesConfig(routeBasePath, routeBaseUrl),
  };
};
