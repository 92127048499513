import { StandardDialog } from '@roc/ui';
import { FiltersContent } from './filtersContent';
import { useEffect } from 'react';
import { useStore } from '@roc/client-portal-shared/hooks';

export type FiltersDialogProps = {
  open?: boolean;
  onClose?: () => void;
};

export const FiltersDialog = (props: FiltersDialogProps) => {
  const { open, onClose } = props;
  const { compToolStore } = useStore();

  const { compResponse } = compToolStore

  useEffect(() => {
    compToolStore.compToolFiltersStore.loadValues(compResponse.filters_options.fields);
  }, []);

  return (
    <StandardDialog
      open={open}
      title="Filters"
      maxWidth="md"
      handleClose={onClose}
      onClose={onClose}
      removeContentBox={true}
      dialogContent={<FiltersContent />}
    />
  );
};
