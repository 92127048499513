import { Grid, Typography } from '@material-ui/core';
import { formatCurrency, formatPercentage } from '@roc/ui/utils';
import { observer } from 'mobx-react';
import { DscrCalculatorStore } from '../stores/dscrCalculatorStore';
import {
  CalculateButton,
  CalculatorLayout,
  LoanAutocomplete,
  PaperList,
  PaperListItem,
  PaperListTitle,
} from './dscrCalculatorComponents';
import {
  AmortizingPeriodMonths,
  Concession,
  CreditLoss,
  EstimatedAsIsValue,
  FullyAmortizingPeriodMonths,
  GeneralAndAdministrativeFee,
  GrossPotentialRent,
  InPlaceMonthlyRent,
  Insurance,
  InterestOnlyPeriodMonths,
  LoanAmount,
  LoanSubtype,
  MonthlyMarketRent,
  NoteInterestRate,
  Payroll,
  PropertyManagementFee,
  RepairsAndMaintenance,
  ReplacementReserves,
  RequestedLTV,
  Taxes,
  UnitCount,
  Utilities,
  Vacancy,
} from './dscrCalculatorFields';

interface DscrCalcSectionProps {
  store: DscrCalculatorStore;
}

export const DscrCalculator5PlusUnits = ({ store }: DscrCalcSectionProps) => {
  return (
    <CalculatorLayout
      left={
        <>
          <Grid item xs={12}>
            <LoanAutocomplete store={store} />
          </Grid>
          <CalculatorInputs store={store} />
          <CalculateButton onClick={() => store.fetchCalculatedValues()} />
        </>
      }
      right={<CalculatedDSCRValues store={store} />}
    />
  );
};

const CalculatorInputs = observer(({ store }: DscrCalcSectionProps) => {
  const {
    vacancyPercentOfGPR,
    propertyManagementFeePercentOfGPR,
  } = store.form.fields;

  const defaults = store.defaultValues?.[store.form.fields.loanSubtype.value];

  return (
    <>
      <Grid item xs={12}>
        <Typography variant={'h5'}>Loan Values</Typography>
      </Grid>
      <LoanSubtype store={store} />
      <EstimatedAsIsValue store={store} />
      <RequestedLTV store={store} />
      <NoteInterestRate store={store} />
      <InterestOnlyPeriodMonths store={store} />
      <AmortizingPeriodMonths store={store} />
      <LoanAmount store={store} />

      <Grid item xs={12}>
        <Typography variant={'h5'}>Income</Typography>
      </Grid>
      <InPlaceMonthlyRent store={store} />
      <MonthlyMarketRent store={store} />
      <GrossPotentialRent store={store} />
      <Vacancy
        store={store}
        helperText={`* Vacancy is greater of in-place and  ${formatPercentage(
          vacancyPercentOfGPR.value
        )}`}
      />
      <Concession store={store} />
      <CreditLoss
        store={store}
        helperText={`* Include tenants under forbearance`}
      />

      <Grid item xs={12}>
        <Typography variant={'h5'}>Expenses</Typography>
      </Grid>
      <UnitCount store={store} />
      <PropertyManagementFee
        store={store}
        helperText={`* Higher of in place or ${formatPercentage(
          propertyManagementFeePercentOfGPR.value
        )}`}
      />
      <Taxes store={store} helperText={`* Based on current tax year`} />
      <Insurance store={store} helperText={`* Based on in-place at closing`} />
      <GeneralAndAdministrativeFee
        store={store}
        helperText={`* Higher of T-12 or $${defaults?.generalAndAdministrativeFeePerUnit}/unit/yr`}
      />
      <RepairsAndMaintenance
        store={store}
        helperText={`* Higher of T-12 or $${defaults?.repairsAndMaintenancePerUnit}/unit/yr`}
      />
      <Utilities
        store={store}
        helperText={`* Higher of T-12 or $${defaults?.utilitiesPerUnit}/unit/yr`}
      />
      <Payroll
        store={store}
        helperText={`* Higher of T-12 or $${defaults?.payrollPerUnit}/unit/yr (if applicable)`}
      />
      <ReplacementReserves
        store={store}
        helperText={`* ${formatCurrency(
          defaults?.replacementReservesPerUnit
        )}/unit/yr`}
      />
    </>
  );
});

const CalculatedDSCRValues = observer(({ store }: DscrCalcSectionProps) => {
  const values = store.calculatedValues;
  const { fields } = store.form;

  const formatCurr = value =>
    value || value === 0 ? formatCurrency(value) : '';
  const formatPerc = value =>
    value || value === 0 ? formatPercentage(value) : '';
  return (
    <>
      <Grid item xs={12}>
        <PaperList>
          <PaperListTitle text="Income" />
          <PaperListItem
            label="Gross Annual Income"
            value={formatCurr(fields.grossAnnualIncome.value || 0)}
          />
        </PaperList>
      </Grid>
      <Grid item xs={12}>
        <PaperList>
          <PaperListTitle text="Expenses" />
          <PaperListItem
            label="Total Operating Expenses"
            value={formatCurr(store.operatingExpenses)}
          />
        </PaperList>
      </Grid>
      <Grid item xs={12}>
        <PaperList>
          <PaperListTitle text="Economics" />
          <PaperListItem
            label="Annual Net Operating Income"
            value={formatCurr(store.operatingIncome - store.operatingExpenses)}
          />
          <PaperListItem
            label="Debt Service in IO Period "
            value={formatCurr(values?.debtServiceInIOPeriod)}
          />
          <PaperListItem
            label="Fully Amortizing Debt Service"
            value={formatCurr(values?.fullyAmortizingDebtService)}
          />
          <PaperListItem
            label="Annual Net Cash Flow"
            value={formatCurr(values?.annualNetCashFlow)}
          />
          <PaperListItem
            label="NCF DSCR in IO Period"
            value={values?.ncfDscrInIOPeriod ?? ''}
          />
          <PaperListItem
            label="NCF Fully Amortizing DSCR"
            value={values?.ncfFullyAmortizingDscr ?? ''}
          />
          <PaperListItem
            label="NCF Debt Yield"
            value={formatPerc(values?.ncfDebtYield)}
          />
        </PaperList>
      </Grid>
    </>
  );
});
