import { createContext } from 'react';
import { ConfigInterface } from './config';

const _TestDataAttributeContext = createContext<ConfigInterface>({});
_TestDataAttributeContext.displayName = 'TestAttributesConfigContext';

export const TestDataAttributeContext = _TestDataAttributeContext;

export const TestDataAttributesConfigProvider =
  _TestDataAttributeContext.Provider;
