import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { CheckCircle } from '@material-ui/icons';
import { useLoanStore } from '@roc/feature-loans';
import { LoanSummaryBrokerContacts } from '@roc/feature-loans-shared';
import { isFundedLoan } from '@roc/feature-utils';
import { Button, InformationButton } from '@roc/ui';
import { observer } from 'mobx-react';
import { CustomCard, CustomCardDividers, CustomCardRow } from '../customCard';
import {
  LoanDetailsCustomCard,
  LoanDetailsInfoItem,
} from '../loanDetailsCustomCard';

import { Divider, List, ListItem, ListItemText } from '@material-ui/core';
import { darken } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { SubdirectoryArrowRight } from '@material-ui/icons';
import { Layout, useUserStore } from '@roc/feature-app-core';
import {
  US_TREASURY_10_YR_DESC,
  US_TREASURY_10_YR_INDEX,
  US_TREASURY_5_YR_DESC,
  US_TREASURY_5_YR_INDEX,
} from '@roc/feature-loans';
import {
  formatDate,
  isFundedOrAgreedLoan,
  isNil,
  sanitizeTestId,
} from '@roc/feature-utils';
import {
  BooleanChip,
  Paper,
  StatusChip,
  StatusType,
  TestDataAttribute,
} from '@roc/ui';
import { formatCurrency } from '@roc/ui/utils';
import { useLoanFlags } from '../../hooks/useLoanFlags';
import { useHistory } from 'react-router';
import { useLoanRoutes } from '@roc/feature-loans-routes-config';

export const LoanTermsCardStabilizedBridge = observer(() => {
  const { loanStore } = useLoanStore();
  const { userStore } = useUserStore();
  const { loanDetails } = loanStore;
  const { push } = useHistory();
  const { loanRoutesConfig } = useLoanRoutes();

  const {
    loanId,
    amount,
    initialLoanAmount,
    constructionHoldback,
    rate,
    status,
    loanType,
    loanSubType,
    borrowerNames,
    properties,
    borrowerEntity,
    submissionDate,
    expectedClosingDate,
    agreedDate,
    closingDate,
    payoffDate,
    maturityDate,
    attorneyName,
    aggregateLoanToCurrentCostInitial,
    constructionHoldbackToRenovationBudgetPercent,
    minNumber,
    aggregateLoanToFutureCost,
    fullPrincipalInterest,
    pointsIn,
    interestReserve,
    brokerPointsIn,
    prepayPenaltyMonths,
    fullPrincipalInterestTime,
    replacementCost,
    source,
    highHazardFloodZone,
    rocRate,
    rocPointsIn,
    rocPointsOut,
    origLenderSubordination,
    buybackSkimReduction,
    sellAs,
    fundingPreference,
    pledgeOfShares,
    servicer,
    lenderOriginatorNames,
    lenderName,
    preAgreed,
    underwritingFee,
    calculatedLoanData,
    servicerId,
    loanPurpose,
    interestReserveMonths,
    fullySubmitted,
    freeAppraisalPromotion,
    firstServicerPaymentDueDate,
  } = loanDetails ?? {};

  const isFundedOrPaidoffOrAgreedLoan = isFundedOrAgreedLoan(status);

  const allowLoanPricing = userStore.allowLoanPricing;

  return (
    <CustomCard title="Other Loan Terms" cardContentProps={{
      style: {
        padding: 0
      }
    }}>
      <Grid container>
        <Grid item xs={6}>
          <Box>
            <List>
              {listItem('Loan Purpose', loanPurpose)}
              {divider}
              {listItem('Initial LTC', `${aggregateLoanToCurrentCostInitial}%`)}
              {divider}
              {listItem('Attorney', attorneyName)}
              {divider}
              {isFundedOrPaidoffOrAgreedLoan && listItem('Servicer', servicer)}
              {isFundedOrPaidoffOrAgreedLoan &&
                listItem(
                  'Servicer Id',
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    {servicerId}
                  </Typography>,
                  <Box flexGrow={1}>
                    <SubdirectoryArrowRight fontSize="small" />
                  </Box>
                )}
              {isFundedOrPaidoffOrAgreedLoan &&
                listItem(
                  'First Payment Date',
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    {firstServicerPaymentDueDate}
                  </Typography>,
                  <Box flexGrow={1}>
                    <SubdirectoryArrowRight fontSize="small" />
                  </Box>
                )}
              {isFundedOrPaidoffOrAgreedLoan && divider}
              {listItem('Total LTFC', `${aggregateLoanToFutureCost}%`)}
              {divider}
              {listItem('MERS MIN Number', minNumber)}
            </List>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box>
            <List>
              {listItem(
                'Full Princ Interest',
                <BooleanChip value={fullPrincipalInterest} />
              )}
              {divider}
              {listItem('FPI Time', fullPrincipalInterestTime)}
              {divider}
              {listItem('Pre Penalty (Months)', prepayPenaltyMonths)}
              {divider}
              {listItem('Free Appraisal Promotion',
                !isNil(freeAppraisalPromotion) && (
                  <BooleanChip value={freeAppraisalPromotion} />
                ))}
            </List>
          </Box>
        </Grid>
      </Grid>
    </CustomCard>
  );
});

const listItem = (label, value, icon = null, customTestId = null) => {
  const testId = sanitizeTestId(customTestId || label);
  return (
    <ListItem>
      <ListItemText>
        <Box display="flex">
          {icon && <Box ml={1}>{icon}</Box>}
          <Box flexGrow={1} mr={1}>
            {label}
          </Box>
          <TestDataAttribute id={testId}>
            <Box textAlign="right">{value}</Box>
          </TestDataAttribute>
        </Box>
      </ListItemText>
    </ListItem>
  );
};

const divider = <Divider variant="middle" component="li" />;

const formatPercentage = value =>
  `${value != null ? Number(value).toFixed(2) : ''}%`;

const formatDigit = (value, digits) => value?.toFixed(3, digits);

const getSwapIndexLabel = index => {
  if (index === US_TREASURY_5_YR_INDEX) return US_TREASURY_5_YR_DESC;
  else if (index === US_TREASURY_10_YR_INDEX) return US_TREASURY_10_YR_DESC;
};
