import { observer } from "mobx-react";
import { Typography } from "@material-ui/core";
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from "@material-ui/core";
import { formatCurrency, formatPercentage } from '@roc/ui/utils';
import { TestDataAttribute } from "@roc/ui";
import { sanitizeTestId } from "@roc/feature-utils";

const renderHeading = value => {
  return (
    <TestDataAttribute id={sanitizeTestId(value)}>
      <Typography
        variant="body1"
      >
        <strong>{value}</strong>
      </Typography>
    </TestDataAttribute>

  );
};

const TableItem = ({ testId, data, className }) => {
  return (
    <TestDataAttribute id={sanitizeTestId(testId)}>
      <TableCell className={className}>{data}</TableCell>
    </TestDataAttribute>
  )
}

const useStyles = makeStyles(theme => ({
  tableBorder: {
    borderStyle: "none"
  }

}));

export const PricingModelTable = observer(
  (props) => {
    const classes = useStyles();
    const { data } = props;

    return (
      <Grid>
        <Grid item xs={12}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{renderHeading('Criteria')}</TableCell>
                <TableCell>{renderHeading('Input')}</TableCell>
                <TableCell >{renderHeading('Adjustment')}</TableCell>
                <TableCell>{renderHeading('Adjustment Description')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableItem
                  testId='credit-score-label'
                  data={'Credit Score'}
                  className={''}
                />
                <TableItem
                  testId='credit-score-input'
                  data={data.creditScore}
                  className={''}
                />
                <TableItem
                  testId='credit-score-adjustment'
                  data={`${data.creditScoreActualAdjustment}%`}
                  className={''}
                />
                <TableItem
                  testId='credit-score-adjustment-description'
                  data={`+${data.creditScoreAdjustment}% if mid-FICO<${data.creditScoreCondition}`}
                  className={''}
                />
              </TableRow>
              <TableRow>
                <TableItem
                  testId='liquidity-label'
                  data={'Liquidity'}
                  className={''}
                />
                <TableItem
                  testId='liquidity-input'
                  data={formatCurrency(data.liquidity, 0)}
                  className={''}
                />
                <TableItem
                  testId='liquidity-adjustment'
                  data={`${data.liquidityActualAdjustment}%`}
                  className={''}
                />
                <TableItem
                  testId='liquidity-adjustment-description'
                  data={`+${data.liquidityAdjustment}% if guarantor post close liquidity<${data.liquidityCondition}% of Total Loan`}
                  className={''}
                />
              </TableRow>
              <TableRow>
                <TableItem
                  testId='total-projects-completed-label'
                  data={'Total Projects Completed'}
                  className={''}
                />
                <TableItem
                  testId='total-projects-completed-input'
                  data={data.totalProjectsCompleted}
                  className={''}
                />
                <TableItem
                  testId='total-projects-completed-adjustment'
                  data={`${data.totalProjectsCompletedActualAdjustment}%`}
                  className={''}
                />
                <TableItem
                  testId='total-projects-completed-adjustment-description'
                  data={`+${data.totalProjectsCompletedAdjustment}% if combined sponsor experience<${data.totalProjectsCompletedCondition} similar MF projects (size, scope, location,etc.)`}
                  className={''}
                />
              </TableRow>
              <TableRow>
                <TableItem
                  testId='tertiary-market-label'
                  data={'Tertiary Market'}
                  className={''}
                />
                <TableItem
                  testId='tertiary-market-input'
                  data={data.tertiaryMarket === true ? 'yes' : 'no'}
                  className={''}
                />
                <TableItem
                  testId='tertiary-market-adjustment'
                  data={`${data.tertiaryMarketActualAdjustment}%`}
                  className={''}
                />
                <TableItem
                  testId='tertiary-market-adjustment-description'
                  data={`+${data.tertiaryMarketAdjustment}% if tertiary submarket`}
                  className={''}
                />
              </TableRow>
              <TableRow>
                <TableItem
                  testId='non-residential-units-label'
                  data={'Non-Residential Units'}
                  className={''}
                />
                <TableItem
                  testId='non-residential-units-input'
                  data={data.nonResidentialUnits === true ? 'yes' : 'no'}
                  className={''}
                />
                <TableItem
                  testId='non-residential-units-adjustment'
                  data={`${data.nonResidentialUnitsActualAdjustment}%`}
                  className={''}
                />
                <TableItem
                  testId='non-residential-units-adjustment-description'
                  data={`+${data.nonResidentialUnitsAdjustment}% if property is mixed-use`}
                  className={''}
                />
              </TableRow>
              <TableRow>
                <TableItem
                  testId='vacant-label'
                  data={'Vacant'}
                  className={''}
                />
                <TableItem
                  testId='vacant-input'
                  data={formatPercentage(data.vacant)}
                  className={''}
                />
                <TableItem
                  testId='vacant-adjustment'
                  data={`${data.vacantActualAdjustment}%`}
                  className={''}
                />
                <TableItem
                  testId='vacant-adjustment-description'
                  data={`+${data.vacantAdjustment}% if property is >=${data.vacantCondition}% of physical vacancy at closing`}
                  className={''}
                />
              </TableRow>
              <TableRow>
                <TableItem
                  testId='loan-to-value-label'
                  data={'Loan to Value'}
                  className={''}
                />
                <TableItem
                  testId='loan-to-value-input'
                  data={formatPercentage(data.loanToValue)}
                  className={''}
                />
                <TableItem
                  testId='loan-to-value-adjustment'
                  data={`${(data.loanToValueActualAdjustment)}%`}
                  className={''}
                />
                <TableItem
                  testId='loan-to-value-adjustment-description'
                  data={`+${data.loanToValueAdjustment}% if ARLTV is higher than ${data.loanToValueCondition}% (does not apply to cash-out refi)`}
                  className={''}
                />
              </TableRow>
              <TableRow>
                <TableItem
                  testId='loan-to-purpose-label'
                  data={'Loan To Purpose'}
                  className={''}
                />
                <TableItem
                  testId='loan-to-purpose-input'
                  data={data.loanToPurpose}
                  className={''}
                />
                <TableItem
                  testId='loan-to-purpose-adjustment'
                  data={`${data.loanToPurposeActualAdjustment}%`}
                  className={''}
                />
                <TableItem
                  testId='loan-to-purpose-adjustment-description'
                  data={`+${data.loanToPurposeAdjustment}% if loan purpose is a cash-out refinance`}
                  className={''}
                />
              </TableRow>
              <TableRow>
                <TableItem
                  testId='total-renovation-budget-label'
                  data={'Total Renovation Budget'}
                  className={''}
                />
                <TableItem
                  testId='total-renovation-budget-input'
                  data={formatCurrency(data.totalRenovationBudget, 0)}
                  className={''}
                />
                <TableItem
                  testId='total-renovation-budget-adjustment'
                  data={`${data.totalRenovationBudgetActualAdjustment}%`}
                  className={''}
                />
                <TableItem
                  testId='total-renovation-budget-adjustment-description'
                  data={`+${data.totalRenovationBudgetAdjustment}% if Completed Renovations budget>${data.totalRenovationBudgetCondition}% of Total Loan`}
                  className={''}
                />
              </TableRow>
              <TableRow>
                <TableItem
                  testId='total-adjustments-label'
                  data={'Total Adjustments'}
                  className={''}
                />
                <TableItem
                  testId='total-adjustments-adjustment'
                  data={`${data.totalAdjustments}%`}
                  className={''}
                />
              </TableRow>
              <TableRow>
                <TableItem
                  testId='total-rate'
                  data={'Total Rate'}
                  className={classes.tableBorder}
                />
                <TableCell className={classes.tableBorder}></TableCell>
                <TableItem
                  testId='total-rate-adjustment'
                  data={`${data.rate}%`}
                  className={classes.tableBorder}
                />
                <TableCell className={classes.tableBorder}></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    );
  }
);