import { observer } from 'mobx-react';

import {
  DataGrid, DollarColumn, GridColumnProps, PaginationBar,
} from '@roc/ui';
import { Grid, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { ComparableProperty } from '../../types/propertyTypes';
import { capitalize } from '@roc/feature-utils';
import { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { useStore } from '@roc/client-portal-shared/hooks';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pagination: {
      justifyContent: 'flex-end',
    },

  })
);

const availableColumns: GridColumnProps[] = [
  {
    field: 'sale_date',
    headerName: 'Date',
    minWidth: 100,
    cellRenderer: 'valueCellRenderer'
  },
  {
    field: 'sale_price',
    headerName: 'Price',
    minWidth: 100,
    cellRenderer: 'moneyRenderer'
  },
  {
    field: 'transaction_type',
    headerName: 'Transaction Type',
    minWidth: 150,
    cellRenderer: 'valueCellRenderer'
  },
  {
    field: 'buyer_name',
    headerName: 'Buyer',
    minWidth: 150,
    cellRenderer: 'valueCellRenderer'
  },
  {
    field: 'seller_name',
    headerName: 'Seller',
    minWidth: 150,
    cellRenderer: 'valueCellRenderer'
  },
  {
    field: 'source',
    headerName: 'Source',
    cellRenderer: 'valueCellRenderer',
    minWidth: 150,
  }
];

const formatDate = (dateStr: string): string => {
  if (!dateStr) {
    const defaultDate = new Date(0);
    return format(defaultDate, 'MM-dd-yyyy');
  } else {
    const [day, month, year] = dateStr.split('-');
    return `${month}-${day}-${year}`;
  }
};

export interface TransactionHistoryProps {
  property: ComparableProperty;
}

export const TransactionHistory: React.FC<TransactionHistoryProps> = observer(({ property }) => {
  const classes = useStyles();
  const { compToolStore } = useStore();
  const [currentPage, setCurrentPage] = useState(1);
  const [sortModel, setSortModel] = useState<{ colId: string, sort: 'asc' | 'desc' } | null>(null);
  const [sortedRows, setSortedRows] = useState([]);

  const pageSize = 8;
  const rows = property.data.transactions_history.transactions;

  const formattedTransactionHistory = rows
    .map(transaction => ({
      ...transaction,
      sale_date: formatDate(transaction.sale_date),
    }))
    .sort((current, next) => {
      const currentDate = new Date(current.sale_date);
      const nextDate = new Date(next.sale_date);
      return nextDate.getTime() - currentDate.getTime();
    });


  useEffect(() => {
    setSortedRows(formattedTransactionHistory)
  }, []);

  useEffect(() => {
    if (sortModel) {
      const sortedRows = [...formattedTransactionHistory].sort((a, b) => {
        const valueA = a[sortModel.colId];
        const valueB = b[sortModel.colId];


        if (sortModel.colId === 'sale_date') {
          const dateA = new Date(valueA);
          const dateB = new Date(valueB);
          return sortModel.sort === 'asc'
            ? dateA.getTime() - dateB.getTime()
            : dateB.getTime() - dateA.getTime();
        } else {
          if (valueA === null || valueA === undefined) return 1;
          if (valueB === null || valueB === undefined) return -1;

          const numA = isNaN(valueA) ? valueA : Number(valueA);
          const numB = isNaN(valueB) ? valueB : Number(valueB);

          if (sortModel.sort === 'asc') {
            if (numA > numB) return 1;
            if (numA < numB) return -1;
            return 0;
          } else {
            if (numA < numB) return 1;
            if (numA > numB) return -1;
            return 0;
          }
        }
      });
      setSortedRows(sortedRows);
    }
  }, [sortModel]);


  const onSortChanged = params => {
    const sortState = params.api.getSortModel();
    if (sortState.length > 0) {
      setSortModel(sortState[0]);
    } else {
      setSortModel(null);
    }
  };

  const frameworkComponents = {
    moneyRenderer(params) {
      return DollarColumn(
        params.value
      )
    },
    valueCellRenderer(params) {
      return params.value
        ? capitalize(params.value)
        : '-';
    }
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Accordion
            expanded={compToolStore.titleTransactionExpanded}
            onChange={(event: React.SyntheticEvent, expanded: boolean) => compToolStore.setTitleTransactionExpanded(expanded)}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Grid container justifyContent='space-between'>
                <Grid item>
                  <Typography variant='h5'>
                    Transaction History
                  </Typography>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <DataGrid
                      columns={availableColumns}
                      rows={sortedRows.slice((currentPage - 1) * pageSize, currentPage * pageSize)}
                      frameworkComponents={frameworkComponents}
                      showFilters={false}
                      domLayout="autoHeight"
                      onSortChanged={onSortChanged}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <PaginationBar
                      start={1}
                      pageSize={pageSize}
                      totalRecords={rows.length}
                      pageNumber={currentPage}
                      totalPages={Math.ceil(rows.length / pageSize)}
                      onChange={(event, value) => setCurrentPage(value)}
                      className={classes.pagination}
                    />
                  </Grid>
                </Grid>
              </>
            </AccordionDetails>
          </Accordion >
        </Grid>
      </Grid>
    </>
  )

})