import { Grid, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useStore } from '@roc/client-portal-shared/hooks';
import { observer } from 'mobx-react';

const useStyles = makeStyles((theme: Theme) => {
  return {
    container: {
      display: 'inline-flex',
      flexDirection: 'column',
      justifyContent: 'center',
      textAlign: 'center',
      position: 'relative',
      top: '50%',
      transform: 'translateY(-50%)',
    },
  };
});

export const ThankYouVerifyBankInfo = observer(() => {
  const { globalStore } = useStore();
  const classes = useStyles();
  return (
    <Grid container spacing={4} className={classes.container}>
      <Grid item xs={12}>
        <Typography variant="h2">
          Thank you for providing your bank information!
          <br />
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography>
          The process has been completed.
        </Typography>
        <Typography>
          Your information was successfully received.
        </Typography>
      </Grid>
    </Grid>
  );
});