import { action, flow, makeObservable } from 'mobx';
import { GlobalStore, GridStore } from '../../../stores';
import { MailingListSubscription } from '../types/companyProfileTypes';
import { EditMailingListsDialogStore } from './editMailingListsDialogStore';
import { ExternalLenderStore } from './externalLenderStore';

export class MailingListsStore {
  private globalStore: GlobalStore;
  salesforceLenderStore;

  editMailingListsDialogStore: EditMailingListsDialogStore;
  mailingListsGridStore: GridStore;

  constructor(globalStore, lenderStore) {
    this.globalStore = globalStore;
    this.salesforceLenderStore = lenderStore;

    this.editMailingListsDialogStore = new EditMailingListsDialogStore(
      globalStore
    );
    this.mailingListsGridStore = new GridStore(this.fetchMailingListsGridData);

    makeObservable(this, {
      showAddMailDialog: action,
      showEditMailDialog: action,
      submitAddMailDialog: flow,
      submitEditMailDialog: flow,
      deleteEmail: flow,
    });
  }

  private fetchMailingListsGridData = async () => {
    //await this.salesforceLenderStore.fetchSalesforceLender(true);
    try {
      const gridMeta = this.mailingListsGridStore.gridData.meta;
      const rows = this.salesforceLenderStore.getMailingLisGridPage(gridMeta);
      return rows;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while loading mailing lists',
      });
    }
  };

  showAddMailDialog = () => {
    this.editMailingListsDialogStore.showAddMail();
  };

  showEditMailDialog = (values: MailingListSubscription) => {
    this.editMailingListsDialogStore.showEditMail(values);
  };

  *submitAddMailDialog() {
    try {
      const mailingListsInfo: MailingListSubscription = this.editMailingListsDialogStore.getFormValues();

      this.editMailingListsDialogStore.runFormValidationWithMessage();
      const isLengthValid = this.salesforceLenderStore.validateMailingListsLength(
        mailingListsInfo
      );
      if (this.editMailingListsDialogStore.form.meta.isValid && isLengthValid) {
        this.editMailingListsDialogStore.closeDialog();

        yield this.salesforceLenderStore.updateMailSubscriptions(
          mailingListsInfo
        );
        this.mailingListsGridStore.resetAndFetchGridData();
        this.globalStore.notificationStore.showSuccessNotification({
          message: `Email subscription categories has been added successfully for ${mailingListsInfo.email}.`,
        });
      }
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while adding email subscription categories',
      });
    }
  }

  *submitEditMailDialog() {
    try {
      const mailingListsInfo: MailingListSubscription = this.editMailingListsDialogStore.getFormValues();
      this.editMailingListsDialogStore.runFormValidationWithMessage();
      const isLengthValid = this.salesforceLenderStore.validateMailingListsLength(
        mailingListsInfo
      );
      if (this.editMailingListsDialogStore.form.meta.isValid && isLengthValid) {
        this.editMailingListsDialogStore.closeDialog();
        yield this.salesforceLenderStore.updateMailSubscriptions(
          mailingListsInfo
        );
        this.mailingListsGridStore.resetAndFetchGridData();
        this.globalStore.notificationStore.showSuccessNotification({
          message: `Email subscription categories has been changed successfully for ${mailingListsInfo.email}.`,
        });
      }
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while editing email subscription categories',
      });
    }
  }

  *deleteEmail(mailingListsInfo: MailingListSubscription) {
    try {
      mailingListsInfo.loanStatus = false;
      mailingListsInfo.dailyReport = false;
      mailingListsInfo.extensions = false;
      mailingListsInfo.participationAgreement = false;
      mailingListsInfo.wireConfirm = false;

      yield this.salesforceLenderStore.updateMailSubscriptions(
        mailingListsInfo
      );

      this.mailingListsGridStore.resetAndFetchGridData();
      this.globalStore.notificationStore.showSuccessNotification({
        message: `User ${mailingListsInfo.email} remove from email subscription.`,
      });
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while removing email subscription categories for user',
      });
    }
  }
}
