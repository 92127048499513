import { LogBaseService } from "@roc/feature-app-core";

const url = {
  LOGIN: '/nportal/rest/priv/logger/logEvent',
};

export class LogInternalService extends LogBaseService {
  async logEvent(event: any) {
    try {
      this.post(url.LOGIN, event, {}, { disableGlobalLoading: true });
    } catch (error) {
      console.log("An error occurred when logging an event", error);
    }
  }

  logEventsAppraisalReview(events: any[], items, propertyId: number, loanId: number) {
    throw Error('Not Implemented');
  }
}