import { GlobalStore, UserStore } from '@roc/feature-app-core';
import { FixFlipStore } from '@roc/feature-loans';
import { LoanSubType } from '@roc/feature-utils';
import { FixFlipLoanEconomicsStore } from './loanEconomics/fixFlipLoanEconomicsStore';
import { FixFlipPropertiesStore } from './properties/fixFlipPropertiesStore';
import { QuoteTypeStore } from './quoteTypeStore';

export class FixFlipQuoteStore extends QuoteTypeStore {
  constructor(globalStore: GlobalStore, userStore: UserStore) {
    super(globalStore, userStore);
    this.loanSubtype = LoanSubType.FIX_AND_FLIP;
    this.propertiesStore = new FixFlipPropertiesStore(globalStore, this.loanSubtype);
    this.loanEconomicsStore = new FixFlipLoanEconomicsStore(
      this.globalStore,
      this
    );
  }
}
