import { makeStyles } from '@material-ui/core/styles';

export enum LoanPurposeOption {
  PURCHASE = 'Purchase',
  REFINANCE = 'Refi',
}

export const loanPurposeOptions = [
  {
    label: 'Purchase',
    value: LoanPurposeOption.PURCHASE,
  },
  {
    label: 'Refi',
    value: LoanPurposeOption.REFINANCE,
  },
];


export const loanPropertyTypes = [
  { label: 'Single Family', value: 'Single Family' },
  { label: 'Duplex', value: 'Duplex' },
  { label: 'Triplex', value: 'Triplex' },
  { label: 'Quadruplex', value: 'Quadruplex' },
  { label: 'Condo', value: 'Condominium' },
];


export const rateOptions = [
  {
    value: 8.0,
    label: '8%',
  },
  {
    value: 8.25,
    label: '8.25%',
  },
  {
    value: 8.5,
    label: '8.50%',
  },
  {
    value: 8.75,
    label: '8.75%',
  },
  {
    value: 9.0,
    label: '9%',
  },
  {
    value: 9.25,
    label: '9.25%',
  },
  {
    value: 9.5,
    label: '9.5%',
  },
  {
    value: 9.75,
    label: '9.75%',
  },
  {
    value: 10.0,
    label: '10%',
  },
  {
    value: 10.25,
    label: '10.25%',
  },
  {
    value: 10.5,
    label: '10.5%',
  },
  {
    value: 10.75,
    label: '10.75%',
  },
  {
    value: 11.0,
    label: '11%',
  },
  {
    value: 11.25,
    label: '11.25%',
  },
  {
    value: 11.5,
    label: '11.5%',
  },
  {
    value: 11.75,
    label: '11.75%',
  },
  {
    value: 12.0,
    label: '12%',
  },
  {
    value: 12.25,
    label: '12.25%',
  },
  {
    value: 12.5,
    label: '12.5%',
  },
  {
    value: 12.75,
    label: '12.75%',
  },
  {
    value: 13.0,
    label: '13%',
  },
  {
    value: 13.25,
    label: '13.25%',
  },
  {
    value: 13.5,
    label: '13.5%',
  },
  {
    value: 13.75,
    label: '13.75%',
  },
  {
    value: 14.0,
    label: '14%',
  },
];


export const exitRateOptions = [
  {
    value: 7.5,
    label: '7.50%',
  },
  {
    value: 7.75,
    label: '7.75%',
  },
  {
    value: 8.0,
    label: '8%',
  },
  {
    value: 8.25,
    label: '8.25%',
  },
  {
    value: 8.5,
    label: '8.50%',
  },
  {
    value: 8.75,
    label: '8.75%',
  },
  {
    value: 9.0,
    label: '9%',
  },
  {
    value: 9.25,
    label: '9.25%',
  },
  {
    value: 9.5,
    label: '9.5%',
  },
  {
    value: 9.75,
    label: '9.75%',
  },
  {
    value: 10.0,
    label: '10%',
  },
];


export const useStyles = makeStyles(theme => ({
  slider: {
    marginTop: '2.5em',
  },
}));
