import {
  Grid,
  Paper,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useScopeOfWorkV2Store } from '@roc/feature-sow-shared/hooks';
import { ScopeOfWorkV2FormDialog } from '@roc/feature-sow-v2';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useDrawsStore } from '../hooks';
import { SOWGrid } from './components/sowGrid';
import { SOWRevisionGrid } from './components/sowRevisionsGrid';

const useStyles = makeStyles(theme => ({
  container: {
    padding: 32,
  },
  dealName: {
    display: 'flex',
    justifyContent: 'center',
  },
  paper: {
    padding: 24,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    marginLeft: '-8px',
  },
  gridContainer: {
    paddingBottom: 24,
  },
}));

export const ReviseScopeOfWorkShared = observer(
  ({ loanId }: { loanId: string }) => {
    const { drawStore, scopeOfWorkRevisionStore } = useDrawsStore();
    const { scopeOfWorkV2Store } = useScopeOfWorkV2Store();
    const classes = useStyles();

    const {
      scopeOfWorkV2FormDialogOpen,
      scopeOfWorkV2FormStore,
    } = scopeOfWorkV2Store;

    const scopeOfWorkRevisionFormDialogOpen = scopeOfWorkRevisionStore.scopeOfWorkV2FormDialogOpen;
    const scopeOfWorkRevisionFormStore = scopeOfWorkRevisionStore.scopeOfWorkV2FormStore;

    const closeScopeOfWorkV2FormDialog = () => {
      scopeOfWorkV2Store.setScopeOfWorkV2FormDialogOpen(false);
    };

    const closeScopeOfWorkRevisionFormDialog = () => {
      scopeOfWorkRevisionStore.setScopeOfWorkV2FormDialogOpen(false);
    };

    useEffect(() => {
      drawStore.fetchSOWDocuments(loanId);
      return () => {
        drawStore.resetRevisionDocs();
        scopeOfWorkV2Store.reset();
        scopeOfWorkRevisionStore.reset();
      };
    }, []);

    if (!drawStore.scopeOfWorkList || !drawStore.revisionList) {
      return <></>;
    };

    return (
      <>
        <Grid item xs={12} md={12} className={classes.gridContainer}>
          <Paper className={classes.paper}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <Typography variant="h5">SCOPE OF WORK</Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography>This is the list of approved SOW for this loan.</Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <SOWGrid scopeOfWorkList={drawStore.scopeOfWorkList} />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} md={12} className={classes.gridContainer}>
          <Paper className={classes.paper}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <Typography variant="h5">SCOPE OF WORK REVISIONS</Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <SOWRevisionGrid loanId={loanId} revisionList={drawStore.revisionList} />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <ScopeOfWorkV2FormDialog
          title={"Scope Of Work Revision"}
          open={scopeOfWorkRevisionFormDialogOpen}
          onClose={closeScopeOfWorkRevisionFormDialog}
          store={scopeOfWorkRevisionFormStore}
        />
        <ScopeOfWorkV2FormDialog
          open={scopeOfWorkV2FormDialogOpen}
          onClose={closeScopeOfWorkV2FormDialog}
          store={scopeOfWorkV2FormStore}
        />
      </>
    );
  }
);