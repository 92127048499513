import { action, observable, makeObservable } from 'mobx';
import { GlobalStore } from '@roc/feature-app-core';
import { LoanStore } from '@roc/feature-loans';

export class FloodZoneFormStore {
  private loanStore: LoanStore;
  private globalStore: GlobalStore;
  floodZoneCheck: string;
  floodZoneCheckDisabled: boolean;

  constructor(loanStore: LoanStore, globalStore: GlobalStore) {
    this.loanStore = loanStore;
    this.globalStore = globalStore;

    this.floodZoneCheck = null;
    this.floodZoneCheckDisabled = null;

    makeObservable(this, {
      setFloodZoneCheck: action,
      floodZoneCheck: observable,
      floodZoneCheckDisabled: observable,
      reset: action,
    });
  }

  initialize() {
    const loanFloodZone = this.loanStore.loanDetails.calculatedLoanData
      .floodZone;
    this.floodZoneCheck = loanFloodZone || '';
    this.floodZoneCheckDisabled =
      !!loanFloodZone && 'Unknown' !== loanFloodZone;
  }

  setFloodZoneCheck(floodZoneCheck: string) {
    this.floodZoneCheck = floodZoneCheck;
  }

  reset() {
    this.floodZoneCheck = null;
    this.floodZoneCheckDisabled = null;
  }
}

export default FloodZoneFormStore;
