import { Box, Grid, IconButton, InputAdornment, makeStyles, Theme, useTheme } from '@material-ui/core';
import { observer } from 'mobx-react';
import { Search, Close } from '@material-ui/icons';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Page, useBaseStore, useUserStore } from '@roc/feature-app-core';
import { useDocumentLibraryRoutes } from '@roc/feature-document-library-contentful';
import { TextField } from '@roc/ui';
import { DocumentList } from '../../components/documentList';

const useStyles = makeStyles((theme: Theme) => ({
  documentContainer: {
    position: 'relative',
    height: '100%',
    padding: '16px',
    background: '#FFF',
    fontSize: 16
  },
  title: {
    minWidth: 0,
    justifyContent: 'center',
    textAlign: 'justify',
    color: theme.palette.primary.main
  },
  searchIcon: {
    color: theme.palette.text.secondary
  }
}));

interface CanvaTemplateProps {
  contentId: string,
  data: any,
  setContainerRef: any,
}

export const CanvaTemplateHtml = observer(({ contentId, data, setContainerRef }: CanvaTemplateProps) => {
  const theme = useTheme();
  const { documentLibraryRoutesConfig } = useDocumentLibraryRoutes();
  const classes = useStyles();
  const containerRef = useRef(null);
  const [searchText, setSearchText] = useState('');
  const { globalStore } = useBaseStore();
  const { userStore } = useUserStore();
  const { userInfo } = userStore;

  useEffect(() => {
    setContainerRef(containerRef);
  }, [containerRef]);

  if (!data) {
    return null;
  }

  const videoTagsContains = (templateItem: any) => {
    return templateItem?.tags?.some(tag => tag.toLowerCase().includes(searchText.trim().toLowerCase()));
  };

  const templatesToShow = useMemo(() => {
    let templatesToShow = data?.contentJson
      .filter(template =>
        (globalStore.isInternalPortal) || !template.roles || (Array.isArray(template.roles) && !template.roles.length) ||
        (!template.internalOnly && Array.isArray(template.roles) && template.roles.includes(userInfo.role))
      );

    if (searchText && searchText.trim()) {
      templatesToShow = templatesToShow.filter(template => template.name.toLowerCase().includes(searchText.trim().toLowerCase()) || videoTagsContains(template));
    }
    templatesToShow = templatesToShow.sort((a: any, b: any) => a.name.localeCompare(b.name));
    return templatesToShow;
  }, [searchText]);

  const searchHasText = useMemo(() => {
    return searchText && searchText.trim().length > 0;
  }, [searchText]);

  const handleClick = (templateItem: any) => {
    window.open(templateItem.url, '_blank', 'noopener,noreferrer');
  };

  return (
    <div ref={containerRef}>
      <Page routeDefinition={documentLibraryRoutesConfig.documentLibraryDocument(contentId)}>
        <Box className={classes.documentContainer}>
          <Box mb={1}>
            <Grid container spacing={3} justifyContent='flex-end'>
              <Grid item lg={4} sm={4}>
                <TextField
                  testId='searchTextField'
                  label='Search'
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  style={{
                    width: '100%'
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <Search className={classes.searchIcon} />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      searchHasText && <InputAdornment position='end'>
                        <IconButton
                          size='small'
                          color='primary'
                          onClick={() => setSearchText('')}
                        >
                          <Close />
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>
            </Grid>
          </Box>
          <Box mt={2}>
            <DocumentList
              items={templatesToShow ?? []}
              onDocumentItemClick={handleClick}
            />
          </Box>
        </Box>
      </Page>
    </div>
  );
});
