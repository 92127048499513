import { downloadDocument } from '@roc/client-portal-shared/utils';
import { action, flow, makeObservable, observable } from 'mobx';
import { GlobalStore, GridStore } from '../../../stores';
import { CompanyProfileService } from '../../companyProfile/services/CompanyProfileService';
import { LenderDetailsFormStore } from './lenderDetailsFormStore';
import { BaseLenderStore } from '../../companyProfile/stores/baseLenderStore';

export class LenderDocumentsStore {
  private globalStore: GlobalStore;
  private companyProfileService: CompanyProfileService;
  private lenderDetailsFormStore: LenderDetailsFormStore;
  private salesforceLenderStore: BaseLenderStore;

  documentPreview: {
    title: string;
    data: ArrayBuffer;
    headers: object;
  };

  gridStore: GridStore;

  isReady = false;

  constructor(globalStore, lenderDetailsFormStore) {
    this.globalStore = globalStore;
    this.lenderDetailsFormStore = lenderDetailsFormStore;
    this.salesforceLenderStore = lenderDetailsFormStore.salesforceLenderStore;
    this.companyProfileService = new CompanyProfileService();
    this.gridStore = new GridStore(() => this.fetchDocuments());

    makeObservable(this, {
      isReady: observable,
      documentPreview: observable,
      closeDocumentPreview: action,
      loadData: flow,
      uploadDocument: flow,
      downloadDocument: flow,
      deleteDocument: flow,
      previewDocument: flow,
    });
  }

  private async fetchDocuments() {
    const response = await this.salesforceLenderStore.companyProfileService.getLenderDocuments(this.globalStore.selectedCompanyId);
    this.isReady = true;

    const docs = response?.data?.data?.list;

    const { entityType } = this.lenderDetailsFormStore.getFormValues();

    const llcDocs = [
      {
        documentLabel: 'Operating Agreement (LLC)',
        documentType: 'Operating Agreement',
      },
      {
        documentLabel: 'Certificate of Formation (LLC)',
        documentType: 'Certificate of Formation',
      },
    ];

    const otherDocs = [
      {
        documentLabel: 'Articles of Incorporation (Corp)',
        documentType: 'Articles of Incorporation',
      },
      {
        documentLabel: 'Corporate Bylaws (Corp)',
        documentType: 'Corporate Bylaws',
      },
    ];

    const documentTypes = [
      {
        documentLabel: 'Certificate of Good Standing (>1yr)',
        documentType: 'Certificate of Good Standing',
      },
      {
        documentLabel: 'W-9',
        documentType: 'W9',
      },
      {
        documentLabel: 'EIN Letter',
        documentType: 'EIN Letter',
      },
      ...(entityType === 'LLC' ? llcDocs : otherDocs),
    ];

    documentTypes.sort((a, b) => a.documentLabel.localeCompare(b.documentLabel));
    const lenderDocuments = documentTypes.map(item => {
      const doc = docs.find(d => item.documentType === d.documentType);
      return {
        uploaded: Boolean(doc),
        documentLabel: item.documentLabel,
        documentType: item.documentType,
        documentId: doc?.lenderDocumentsId,
      };
    });

    for (const doc of docs) {
      const matchedDocumentType = documentTypes.find(
        (item) => item.documentType === doc.documentType
      );

      if (!matchedDocumentType) {
        lenderDocuments.push({
          uploaded: Boolean(doc),
          documentLabel: doc.documentType,
          documentType: doc.documentType,
          documentId: doc.lenderDocumentsId,
        });
      }
    }

    return {
      data: {
        data: {
          rows: lenderDocuments,
          totalCount: lenderDocuments.length,
        },
      },
      headers: null,
    };
  }

  *loadData() {
    yield this.gridStore.fetchGridData();
  }


  *uploadDocument(documentType, file) {
    const fileType = new Blob([documentType], {
      type: 'text/plain',
    });
    const companyId = new Blob([this.globalStore.selectedCompanyId], {
      type: 'text/plain',
    });
    const formData = new FormData();
    formData.append('file', file);
    formData.set('fileType', fileType);
    formData.set('companyId', companyId)
    yield this.salesforceLenderStore.companyProfileService.uploadLenderDocument(formData);
    yield this.gridStore.resetAndFetchGridData();
  }

  *deleteDocument(documentId) {
    yield this.salesforceLenderStore.companyProfileService.deleteLenderDocument(documentId);
    yield this.gridStore.resetAndFetchGridData();
  }

  *downloadDocument(documentId, documentLabel) {
    const response = yield this.salesforceLenderStore.companyProfileService.downloadLenderDocument(
      documentId
    );
    downloadDocument(response.data, response.headers, 'download');
  }

  closeDocumentPreview() {
    this.documentPreview = null;
  }

  *previewDocument(documentId, documentLabel) {
    const response = yield this.salesforceLenderStore.companyProfileService.downloadLenderDocument(
      documentId
    );
    this.documentPreview = {
      title: documentLabel,
      data: response.data,
      headers: response.headers,
    };
  }
}
