import React, { useEffect } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { LoginLayout } from './components/loginLayout';
import { useHistory } from 'react-router-dom';
import { useAppRoutes, useStore } from '../../hooks';
import { useAppConfigurations } from '@roc/client-portal-shared/hooks';
import { Button } from '@roc/ui';
import {
  createTextField,
  createPhoneField,
} from '@roc/client-portal-shared/components';
import { observer } from 'mobx-react';
import { LoginLogoImg } from '@roc/feature-app-core';

const useStyles = makeStyles(theme =>
  createStyles({
    accountLink: {
      marginTop: theme.spacing(-2),
      textDecoration: 'underline',
      textTransform: 'none',
      cursor: 'pointer',
    },
    signupButton: {
      marginTop: theme.spacing(4),
      color: 'white',
      backgroundColor: theme.palette.primary.main,
    },
  })
);

export const Register = observer(() => {
  const classes = useStyles();
  const history = useHistory();
  const { publicRoutes } = useAppRoutes();
  const { siteName } = useAppConfigurations();
  const { registerStore } = useStore();

  const handleAlreadyHasAccount = () => {
    history.push(publicRoutes.login.url);
  };

  const handleFormSubmit = () => {
    registerStore.onFormSubmit();
  };

  useEffect(() => {
    registerStore.reset();
    if (registerStore.isRegistrationEnabled) {
      registerStore.setSuccessUrl(publicRoutes.successRegistration.url);
    }
  }, []);

  return (
    <LoginLayout>
      <Grid container justifyContent="center">
        <Grid container item xs={8} spacing={2}>
          <Grid item xs={12}>
            <LoginLogoImg />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" align="center">
              {`Please fill in your information below to apply for a ${siteName} account.`}
            </Typography>
            <br />
          </Grid>
          <Grid item xs={12}>
            {createTextField({
              label: 'FirstName',
              fieldName: 'firstName',
              testId: 'firstName',
              store: registerStore,
              standaloneLabel: false,
            })}
          </Grid>
          <Grid item xs={12}>
            {createTextField({
              label: 'Last Name',
              fieldName: 'lastName',
              testId: 'lastName',
              store: registerStore,
              standaloneLabel: false,
            })}
          </Grid>
          <Grid item xs={12}>
            {createTextField({
              label: 'Email Address',
              fieldName: 'email',
              testId: 'email',
              store: registerStore,
              standaloneLabel: false,
            })}
          </Grid>
          <Grid item xs={12}>
            {createTextField({
              label: 'Company Name',
              fieldName: 'company',
              testId: 'company',
              store: registerStore,
              standaloneLabel: false,
            })}
          </Grid>
          <Grid item xs={12}>
            {createPhoneField({
              label: 'Phone Number',
              fieldName: 'phoneNumber',
              testId: 'phoneNumber',
              store: registerStore,
              standaloneLabel: false,
            })}
          </Grid>
          <Grid item xs={12}>
            <Button
              fullWidth
              className={classes.signupButton}
              variant="contained"
              type="submit"
              onClick={handleFormSubmit}
              testId="submit"
            >
              <span>SIGN UP</span>
            </Button>
          </Grid>
          <Grid item xs={12}>
            <br />
            <Box textAlign="center">
              <Typography
                className={`${classes.accountLink}`}
                variant="body2"
                color="textSecondary"
                onClick={handleAlreadyHasAccount}
              >
                Already have an account? Sign in here.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </LoginLayout>
  );
});
