import { LoanPurposeOption } from './liquidityCalculatorConstants';

export const mapLoanPurpose = loanPurposeFromLoan => {
  switch (loanPurposeFromLoan) {
    case 'Cash Out Refi':
    case 'Cashout':
      return LoanPurposeOption.CASHOUT;
    case 'Purchase':
    case 'Delayed Purchase':
      return LoanPurposeOption.PURCHASE;
    case 'Rate Term Refi':
      return LoanPurposeOption.RATE_TERM_REFI;
    default:
      return null;
  }
};
