import { Service } from './../../../services';

const url = {
  DEFAULT_VALUES: '/api/v1/pricer/dscr/getDefaultValues',
  LOAN_VALUES: '/api/v1/loan/getRentalLoanCalcDetail',
  CALCULATED_DSCR: '/api/v1/pricer/dscr/calculateDscrValues',
};

export class DscrCalculatorService extends Service {
  getDefaultValues() {
    return this.get(url.DEFAULT_VALUES);
  }

  getLoanValues(loanId, isDraft) {
    return this.get(`${url.LOAN_VALUES}?id=${loanId}&isDraft=${isDraft}`);
  }

  getCalculatedValues(data) {
    return this.post(url.CALCULATED_DSCR, data);
  }
}
