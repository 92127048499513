import { Page } from '@roc/feature-app-core';
import { LoanDetails } from '@roc/feature-loans';
import { getLoanRoutesConfig } from '@roc/feature-loans-routes-config';
import { Route } from 'react-router';
import { LoansHome } from '../servicerLoans/loansHome';
import { LoanSummaryBridge } from '../servicerLoans/loanSummaryBridge';
import { LoanSummaryTerm } from '../servicerLoans/loanSummaryTerm';

export enum LoanType {
  RESIDENTIAL_BRIDGE = 'Residential Bridge',
  RESIDENTIAL_TERM = 'Residential Term',
}

export const LoanSummary = ({ loanDetails }) => {
  const { loanType } = loanDetails;

  switch (loanType) {
    case LoanType.RESIDENTIAL_BRIDGE:
      return <LoanSummaryBridge data={loanDetails} />;
    case LoanType.RESIDENTIAL_TERM:
      return <LoanSummaryTerm data={loanDetails} />;
  }
};

export const getServicerLoanRoutes = (
  basePath: string,
  baseUrl: string
) => {
  const config = getLoanRoutesConfig(basePath, baseUrl);
  return {
    loansHome: (
      <Route exact path={config.loansHome.path}>
        <Page routeDefinition={config.loansHome}>
          <LoansHome />
        </Page>
      </Route>
    ),
    loanDetails: (
      <Route path={config.loans().path} >
        <LoanDetails
          hideEditBorrowers
          renderLoanSummary={({ loanDetails }) => (
            <LoanSummary loanDetails={loanDetails} />
          )}
        />
      </Route >
    ),
  };
};
