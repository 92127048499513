import { action, flow, makeObservable, observable } from 'mobx';
import { GlobalStore } from '../../../stores';
import { FormStore } from '@roc/feature-app-core';
import { LenderFees } from '../types/companyProfileTypes';
import { ExternalLenderStore } from './externalLenderStore';

const REQUIRED_MESSAGE = 'This field is required';

const form = {
  fields: {
    processingFees: {
      value: '',
      error: null,
      rule: 'required',
      message: REQUIRED_MESSAGE,
    },
    underWritingFees: {
      value: '',
      error: null,
      rule: 'required',
      message: REQUIRED_MESSAGE,
    },
    adminFees: {
      value: '',
      error: null,
      rule: 'required',
      message: REQUIRED_MESSAGE,
    },
    commitmentFees: {
      value: '',
      error: null,
      rule: 'required',
      message: REQUIRED_MESSAGE,
    },
    defaultTermPoints: {
      value: '',
      error: null,
      rule: '',
    }
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export class LenderFeesStore extends FormStore {
  private globalStore: GlobalStore;
  salesforceLenderStore: ExternalLenderStore;

  isEditing = false;
  lenderFees: LenderFees;

  constructor(globalStore, lenderStore) {
    super(form, globalStore);

    this.globalStore = globalStore;
    this.salesforceLenderStore = lenderStore;

    makeObservable(this, {
      isEditing: observable,
      lenderFees: observable,
      edit: action,
      cancel: action,
      loadLenderFees: action,
      saveLenderFees: flow,
    });
  }

  edit() {
    this.isEditing = true;
  }

  cancel() {
    this.isEditing = false;
    this.loadLenderFees();
  }

  loadLenderFees() {
    //yield this.salesforceLenderStore.fetchSalesforceLender();
    this.lenderFees = this.salesforceLenderStore.getFeesFromSalesforceLender();
    this.loadForm(this.lenderFees);
  }

  *saveLenderFees() {
    try {
      this.runFormValidation();
      if (this.form.meta.isValid) {
        this.isEditing = false;
        this.lenderFees = this.getFormValues();
        yield this.salesforceLenderStore.updateSalesforceLenderFees(
          this.lenderFees
        );
        this.globalStore.notificationStore.showSuccessNotification({
          message: 'Fees have been updated successfully.',
        });
      }
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while saving company fees',
      });
    }
  }
}
