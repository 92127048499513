import { observer } from 'mobx-react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { TodoItem, TodoStatus } from '../utils/loanDetailsTypes';
import { Box, Grid } from '@material-ui/core';
import { TitleWithTodoCount, TodoCard } from '../components/cards/todoCard';
import {
  ClosingStatusStepper,
  LoanDetailsStepper,
} from '../components/loanDetailsStepper';
import {
  DocumentForms,
  Overview,
  useDocumentStore,
} from '@roc/feature-documents';
import { useLoanStore } from '@roc/feature-loans';
import { LoanKeyInformationCard } from '../components/cards/loanKeyInformationCard';
import { CLOSING, ELMSURE, flatten, LoanProcess } from '@roc/feature-utils';
import { Document } from '@roc/feature-types';
import { useLoanFlags } from '../hooks/useLoanFlags';
import { LoanDetailsPaper } from '../components/loanDetailsPaper';
import { ResendInsuranceQuote } from 'libs/feature-documents/src/documents/components/overview/components/resendInsuranceQuote/resendInsuranceQuote';
import { RequestElmsureQuote } from 'libs/feature-documents/src/documents/components/overview/components/requestElmsureQuote/requestElmsureQuote';
import { Paper, PaperBorderType } from '@roc/ui';
import { isPendingTodoStatus } from '../utils/todoUtils';
import { useEffect } from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    border: '1px solid #d5d5d5 !important',
    borderRadius: 16,
    overflow: 'hidden',
  },
}));

const FILTERED_STATUS = ['Not Applicable'];
const CUSTOM_SORTED_DOCUMENT_SECTIONS = [43];

const applyFilters = (documents: Document[]) => {
  const filteredDocs = documents.filter(
    doc => !FILTERED_STATUS.includes(doc.status)
  );
  if (
    documents.length > 0 &&
    CUSTOM_SORTED_DOCUMENT_SECTIONS.includes(documents[0].sectionId)
  )
    return filteredDocs;
  return filteredDocs.sort((a, b) =>
    a.documentName.localeCompare(b.documentName)
  );
};

const processDocuments = (documentStore, section) => {
  const docs = applyFilters(
    documentStore.documents[CLOSING]?.[section.sectionName] || []
  );
  return docs.map((document: Document) =>
    documentStore.formatDocument(document, CLOSING)
  );
};

export const ClosingTab = observer(() => {
  const classes = useStyles();
  const { loanStore } = useLoanStore();
  const { documentStore } = useDocumentStore();
  const { showResendInsuranceButton } = useLoanFlags();
  const { loanDetails } = loanStore;
  const { loanTodosStore } = documentStore;
  const currentForm = documentStore.documentFormStore.currentForm;
  const loanId = loanDetails?.loanId;

  const closingSections = flatten(
    documentStore.displaySections[CLOSING]?.map(item => item.sections) ?? []
  );

  const documents = flatten(
    closingSections.map(section => processDocuments(documentStore, section))
  );

  const todos = documents.map(doc =>
    documentStore.loanTodosStore.mapDocumentToTodo(doc, LoanProcess.CLOSING)
  );
  const pendingTodos = todos.filter(t => isPendingTodoStatus(t.status));

  useEffect(() => {
    if (!currentForm && todos.length > 0) {
      loanTodosStore.fetchTodos(loanId, LoanProcess.CLOSING);
    }
  }, [loanId, currentForm]);

  return (
    <>
      <Grid item xs={12}>
        <Paper borderType={PaperBorderType.SOLID_BORDER} className={classes.paper}>
          <ClosingStatusStepper />
        </Paper>
      </Grid>
      {showResendInsuranceButton && (
        <Grid item xs={12}>
          <LoanDetailsPaper>
            <Box display="flex" justifyContent="flex-end" p={2}>
              <Box ml={2}>
                <ResendInsuranceQuote loanStore={loanStore} />
              </Box>
              <Box ml={2}>
                <RequestElmsureQuote loanStore={loanStore} />
              </Box>
            </Box>
          </LoanDetailsPaper>
        </Grid>
      )}
      <Grid item xs={12}>
        <LoanKeyInformationCard loanStore={loanStore} />
      </Grid>
      <Grid item xs={12}>
        <TodoCard
          title={<TitleWithTodoCount count={pendingTodos.length} />}
          showFilter
          showSectionActions
          showFileName
          showDownloadAll
          todos={todos}
          loanProcess={LoanProcess.CLOSING}
        />
        <DocumentForms loanId={loanId?.toString()} />
      </Grid>
    </>
  );
});
