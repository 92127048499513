// ----------------------------------------------------------------------

export default function Modal(theme) {
  return {
    MuiDialog: {
      paper: {
        borderRadius: 12
      }
    },
  };
}
