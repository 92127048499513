import { PDFPreview } from '@roc/ui';
import { observer } from 'mobx-react';
import { useGeneralTasksStore } from '../../hooks/useGeneralTasksStore';

export const AttachmentPreview = observer(() => {
  const { generalTasksStore } = useGeneralTasksStore();
  const { editTaskStore, assigneeOptions } = generalTasksStore;
  const { attachmentStore } = editTaskStore;
  const { documentPreview } = attachmentStore;

  return (
    <PDFPreview
      open={documentPreview != null}
      title={documentPreview?.title}
      data={documentPreview?.data}
      headers={documentPreview?.headers}
      onClose={() => attachmentStore.closeDocumentPreview()}
    />
  );
});
