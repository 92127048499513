import { FormControl, FormHelperText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { FieldLabel, Slider } from "@roc/ui";
import { observer } from "mobx-react";

const useSliderStyles = makeStyles(theme =>
  createStyles({
    slider: {
      marginTop: '2.5em',
      marginLeft: '2em',
      width: '85%',
    },
    helperText: {
      marginTop: theme.spacing(2),
    },
  })
);

const ficoMarks = [
  {
    value: 600,
    label: '600',
  },
  {
    value: 800,
    label: '800',
  },
];

export const BorrowerCreditScore = observer(({ store, multiFamilyStore, onChange = null, ...rest }) => {
  const { tooltip, ...restProps } = rest;
  const formFields = store.form.fields;
  const classes = useSliderStyles();

  const handleChange = value => {
    store.onFieldChange('borrowerCreditScore', value);
    if (onChange) onChange('borrowerCreditScore', value);
  };

  return (
    <FormControl fullWidth error={formFields.borrowerCreditScore.error}>
      <FieldLabel tooltip={tooltip}>Borrower Credit Score</FieldLabel>
      <Slider
        testId="borrowerCreditScore"
        disabled={false}
        key={formFields.borrowerId.value}
        onChangeCommitted={(event, value) => handleChange(value)}
        className={classes.slider}
        value={formFields.borrowerCreditScore.value}
        valueLabelDisplay="on"
        marks={ficoMarks}
        step={10}
        min={600}
        max={800}
        {...restProps}
      />
      {formFields.borrowerCreditScore.error && (
        <FormHelperText className={classes.helperText}>
          {formFields.borrowerCreditScore.error}
        </FormHelperText>
      )}
    </FormControl>
  );
});