import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import { Paper, TextField } from '@roc/ui';
import { DateField } from '@roc/ui';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useLoanRoutes } from '@roc/feature-loans-routes-config';
import { useLoanStore } from '../../../hooks/useLoanStore';
import { LoanDetailsLayout } from '../../components/loanDetailsLayout';
import RequestKeyDates from './components/requestKeyDates';
import { useExtensionRequestStore } from './hooks/useExtensionRequestStore';
import { PreferredInsurance } from '../../../../loanSubmission/components/formComponents';
import { LoanDetailsStore } from '../../../../loanSubmission/stores/common/loanDetailsStore';
import { StandardDialog } from '@roc/ui';
import { SelectField } from '@roc/ui';
import { isValid } from 'date-fns';
import { Label } from 'recharts';
import { makeStyles } from '@material-ui/core/styles';
import {
  FileUpload,
  FileUploadModal,
} from '@roc/ui';
import { ExtensionsFileUploadModal } from '../extensionsFileUploadModal';
import { extensionsConstants } from '../../../utils/loansConstants';

const useStyles = makeStyles(theme => ({

  title: {
    fontWeight: 500,
    marginTop: -35,
    paddingBottom: 12,
    fontFamily: 'Oswald, sans-serif',
    lineHeight: 1.235,
    fontSize: 34,
    color: '#000000',
  },
  buttonContainer: {
    display: 'flex',
    marginTop: 50,
    marginLeft: '-16px'
  },
  dropdownContainer: {
    marginLeft: '-15px',
  },
  buttonCancel: {
    width: '100%',
    marginRight: '8px',
  },
  buttonSubmit: {
    width: '100%',
    marginLeft: '8px',
  }
}));
interface ExtensionProps {
  closeModal: () => void;
}
const ExtensionRequestModal = (props: ExtensionProps) => {
  const { loanId } = useParams<{ loanId: string }>();
  const { push } = useHistory();
  const { loanStore } = useLoanStore();
  const { extensionRequestStore } = useExtensionRequestStore();
  const { form } = extensionRequestStore;
  const { loanRoutesConfig } = useLoanRoutes();
  const classes = useStyles();

  const optionsOneExtension = [
    {
      label: extensionsConstants.THREE_MONTHS,
      value: 3,
    },
    {
      label: extensionsConstants.SIX_MONTHS,
      value: 6,
    }
  ];
  const optionsTwoOrMoreExtensions = [
    {
      label: extensionsConstants.THREE_MONTHS,
      value: 3,
    }
  ];
  const insuranceOptions = [
    { label: "ElmSure", value: extensionsConstants.ELMSURE },
    { label: "Other", value: extensionsConstants.OTHER },
  ];
  useEffect(() => {
    if (form.meta.isValid) {
      const nearestExpDate = getNearestExpiryDate();
      if (
        nearestExpDate
        && nearestExpDate > extensionRequestStore.getNewMaturityDate()
      ) {
        setEnableButton(true);
      } else {
        setEnableButton(false);
        setIsInsuranceRequired(true);
      }
    } else {
      setEnableButton(false);
    }
  }, [form.fields.extensionDuration.value]);
  useEffect(() => {
    if (form.fields.preferredInsurance.value) {
      setEnableButton(true);
    }
  }, [form.fields.preferredInsurance.value]);
  useEffect(() => {
    closeAfterSubmission();
  }, [extensionRequestStore.saved]);
  const [enableButton, setEnableButton] = useState(false);
  const [isUploadOpen, setUploadOpen] = useState(false);
  const [isInsuranceRequired, setIsInsuranceRequired] = useState(false);

  const closeFileUploadModal = () => {
    setUploadOpen(false);
  }
  const closeAfterSubmission = () => {
    if (extensionRequestStore.saved) {
      props.closeModal();
      extensionRequestStore.setSaved(false);
    }
  }
  const onDocumentUpload = (file: FileUpload[], loanId) => {
    extensionRequestStore.uploadDocument(file[0], loanId);
  };
  const handleOnChange = (field, value) => {
    extensionRequestStore.onFieldChanged(field, value);
    extensionRequestStore.runFormValidation();
  };

  const getNearestExpiryDate = () => {
    if (!loanStore.loanDetails.properties?.length) {
      return false;
    }
    const expiryDate = loanStore.loanDetails.properties.reduce((currentExpDate, prop) => {
      if (!currentExpDate) {
        return prop.insuranceExpiryDate;
      }
      if (prop.insuranceExpiryDate && new Date(prop.insuranceExpiryDate) < new Date(currentExpDate)) {
        return prop.insuranceExpiryDate;
      }
      return currentExpDate;
    }, null);

    return expiryDate && new Date(expiryDate);
  }

  return (

    <Grid style={{ height: isInsuranceRequired ? '500px' : '380px' }} xs={12} md={12}>
      <Typography className={classes.title}>Request Extension</Typography>
      <Typography>By requesting an extension, you'll be asking for the maturity date to be extended on this loan.<br />
        Please fill out the information below to continue.</Typography>
      <Grid container justifyContent="space-around">
        {extensionRequestStore.saved && (
          <Grid item xs={12}>
            <Alert severity="success">Extension requested successfully!</Alert>
          </Grid>
        )}
        <Grid item xs={12}>

          <Grid container>
            <Grid item xs={12} md={12}>
              <Box display="flex" p={2}>
                <Grid container xs={12}>
                  <Grid item xs={12} md={12} className={classes.dropdownContainer}>
                    {extensionRequestStore.extension === extensionsConstants.TWO_OR_MORE_EXTENSIONS &&
                      <SelectField
                        standaloneLabel
                        label={'Extension (Duration)'}
                        name={'extensionDuration'}
                        value={form.fields.extensionDuration.value}
                        // errorText={formFields.duration.error}
                        // error={isNotBlank(formFields.duration.error)}
                        options={optionsTwoOrMoreExtensions}
                        onChange={value => handleOnChange('extensionDuration', value)}
                        variant="outlined"
                        fullWidth
                        shrink
                        required
                        testId="extensionDuration"
                        placeholder='Please Choose'
                      />
                    }
                    {extensionRequestStore.extension === extensionsConstants.ONE_EXTENSION &&
                      <SelectField
                        standaloneLabel
                        label={'Extension (Duration)'}
                        name={'extensionDuration'}
                        value={form.fields.extensionDuration.value}
                        // errorText={formFields.duration.error}
                        // error={isNotBlank(formFields.duration.error)}
                        options={optionsOneExtension}
                        onChange={value => handleOnChange('extensionDuration', value)}
                        variant="outlined"
                        fullWidth
                        shrink
                        required
                        testId="extensionDuration"
                        placeholder='Please Choose'
                      />
                    }
                    {isInsuranceRequired &&
                      <SelectField
                        standaloneLabel
                        label={'Preferred Insurance Company'}
                        name={'preferredInsurance'}
                        value={form.fields.preferredInsurance.value}
                        errorText={form.fields.preferredInsurance.error}
                        // error={isNotBlank(formFields.preferredInsurance.error)}
                        options={insuranceOptions}
                        onChange={value => handleOnChange('preferredInsurance', value)}
                        variant="outlined"
                        fullWidth
                        shrink
                        required
                        testId="preferredInsurance"
                        placeholder='Please Choose'
                      />
                    }
                    {form.fields.preferredInsurance.value === extensionsConstants.ELMSURE &&
                      <Typography><strong>We will be requesting an extension quote on your behalf</strong></Typography>
                    }
                    <ExtensionsFileUploadModal
                      title={""}
                      open={isUploadOpen}
                      multiple
                      onClose={closeFileUploadModal}
                      onUpload={files => onDocumentUpload(files, loanStore.loanDetails.loanId)}
                    />
                  </Grid>
                  <Grid item container justifyContent='space-around' className={classes.buttonContainer} xs={12}>
                    <Grid item xs={6} md={4}>
                      <Button className={classes.buttonCancel}
                        variant="outlined"
                        color="primary"
                        onClick={() => props.closeModal()}
                      >
                        CANCEL
                      </Button>
                    </Grid>
                    {form.fields.preferredInsurance.value !== extensionsConstants.OTHER &&
                      <Grid item xs={6} md={4}>
                        <Button className={classes.buttonSubmit}
                          variant="contained"
                          color="primary"
                          disabled={!enableButton}
                          onClick={() => extensionRequestStore.onFormSubmit()}
                        >
                          SUBMIT
                        </Button>
                      </Grid>
                    }
                    {form.fields.preferredInsurance.value === extensionsConstants.OTHER &&
                      <Grid item xs={6} md={4}>
                        <Button className={classes.buttonSubmit}
                          variant="contained"
                          color="primary"
                          onClick={() =>
                            setUploadOpen(true)}
                        >
                          CONTINUE
                        </Button>
                      </Grid>
                    }
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>

        </Grid>
        <Grid item xs={12}>

        </Grid>
      </Grid>
    </Grid>
  );
}

export default observer(ExtensionRequestModal);
