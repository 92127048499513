import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Box, Toolbar, Tooltip, Typography } from '@material-ui/core';
import { formatCurrency } from '@roc/ui/utils';
import { observer } from 'mobx-react';
import { ValuationReviewReportStore } from '../stores/valuationReviewReportStore';
import { Button, ConfirmationButton } from '@roc/ui';
import { CheckCircle, DeleteOutline } from '@material-ui/icons';
import { CompSelectionDialog } from './compSelectionDialog';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    '& .MuiTableCell-head': {
      background: '#f7f7f7'
    },
    '& td': {
      lineHeight: '20px',
      whiteSpace: 'nowrap',
    },
    '& .address': {
      position: 'relative',
      whiteSpace: 'normal',
      padding: '0 0 0 16px',
      '& .data-container': {
        overflow: 'auto',
        height: 84,
        padding: '6px 16px 6px 0',
        display: 'flex',
        alignItems: 'center',
      }
    }
  },
  innerTable: {
    minWidth: 150,
  },
  tableContainer: {
  },
});

export interface ComparableProp {
  address: string,
  distance: string,
  salePrice: number,
  dateOfSale?: string,
  daysOnMarket?: string,
  yearBuilt: string,
  beds: string,
  baths: string,
  gla: string
}

export function createComparableData(address: string, distance: string, salePrice: number, dateOfSale: string, daysOnMarket: string, yearBuilt: string, beds: string, baths: string, gla: string) {
  return { address, distance, salePrice, dateOfSale, daysOnMarket, yearBuilt, beds, baths, gla } as ComparableProp;
}

const DataContainer = ({ value }: {
  value?: any
}) => {
  return (
    <Box className='data-container'>
      {value ? value : <>&nbsp;</>}
    </Box>
  )
}

export type Props = {
  store: ValuationReviewReportStore;
}

const AppraisalCompTable = observer((appraisalData: any) =>{
  const classes = useStyles();
  const { appraisalCompData } = appraisalData;
  const { totalRoomsBreakDown } = appraisalCompData;

  return(
    <TableCell padding={'none'} style={{
      borderLeft: '1px solid #bbb',
      borderBottom: 'none'
    }}>
      <TableContainer>
        <Table className={classes.innerTable} size='small'>
          <TableBody>
            <TableRow>
              <TableCell variant='head'><DataContainer /></TableCell>
            </TableRow>
            <TableRow><TableCell variant='head' align="center" style={{
              minWidth: 100
            }} className='address'><DataContainer value={appraisalCompData?.address} /></TableCell></TableRow>
            <TableRow><TableCell variant='body' align="center"><DataContainer value={appraisalCompData?.proximity ? `${appraisalCompData?.proximity} mi.` : '0 mi.'} /></TableCell></TableRow>
            <TableRow><TableCell variant='body' align="center"><DataContainer value={appraisalCompData?.salePrice? formatCurrency(appraisalCompData?.salePrice) : '$0.00'} /></TableCell></TableRow>
            <TableRow><TableCell variant='body' align="center"><DataContainer value={appraisalCompData?.salesDate} /></TableCell></TableRow>
            <TableRow><TableCell variant='body' align="center"><DataContainer value={appraisalCompData?.dom} /></TableCell></TableRow>
            <TableRow><TableCell variant='body' align="center"><DataContainer value={appraisalCompData?.yearBuilt} /></TableCell></TableRow>
            <TableRow><TableCell variant='body' align="center"><DataContainer value={totalRoomsBreakDown?.totalBedroomCount} /></TableCell></TableRow>
            <TableRow><TableCell variant='body' align="center"><DataContainer value={totalRoomsBreakDown?.totalBathroomCount} /></TableCell></TableRow>
            <TableRow><TableCell variant='body' align="center"><DataContainer value={appraisalCompData?.gla ? `${appraisalCompData?.gla} sqft` : '0 sqft'} /></TableCell></TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </TableCell>
  )
})

export const CompAnalysisTable = observer((props: Props) => {
  const classes = useStyles();
  const { store } = props;
  const { valuationReviewInfo } = store;
  const { subjectComp, appraisalComps } = valuationReviewInfo;
  const subjectProperyData = createComparableData(subjectComp?.address, subjectComp?.proximity, subjectComp?.salePrice, null, null, subjectComp?.yearBuilt,
    subjectComp?.totalRoomsBreakdown?.totalBedroomCount, subjectComp?.totalRoomsBreakdown?.totalBathroomCount, subjectComp?.gla);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [open, setOpen] = useState<boolean>(false);

  const [datascienceComp1, setDatascienceComp1] = useState<ComparableProp>();
  const [datascienceComp2, setDatascienceComp2] = useState<ComparableProp>();
  const [datascienceComp3, setDatascienceComp3] = useState<ComparableProp>();

  return (
    <Paper elevation={0} variant='outlined'>
      <Toolbar style={{
        minHeight: 52,
        padding: '8px 16px',
        display: 'flex',
        justifyContent: 'space-between'
      }}>
        <Typography variant="h6" component="div">
          Comp Analysis
        </Typography>
        {/* <Box ml={2}>
          <Button testId='compTool' size="small"
            color="default"
            variant="outlined"
            onClick={() => { }}>
            Comp Tool
          </Button>
        </Box> */}
      </Toolbar>
      <TableContainer className={classes.tableContainer}>
        <Table className={classes.table} size='small'>
          <TableHead>
            <TableRow>
              <TableCell variant='head'></TableCell>
              <TableCell variant='head' align="center" style={{
                borderLeft: '1px solid #bbb'
              }}><strong>Subject</strong></TableCell>
              {/* <TableCell variant='head' align="center" colSpan={3} style={{
                borderLeft: '1px solid #bbb'
              }}><strong>Roc Comps</strong></TableCell> */}
              <TableCell variant='head' align="center" colSpan={3} style={{
                borderLeft: '1px solid #bbb'
              }}><strong>Appraisal Comps</strong></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell padding={'none'} style={{
                borderBottom: 'none'
              }}>
                <TableContainer>
                  <Table className={classes.innerTable} size='small'>
                    <TableBody>
                      <TableRow>
                        <TableCell variant='head'><DataContainer /></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell variant='head' align="left" className='address'>
                          <DataContainer value={<strong>Address</strong>} />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell variant='head' align="left"><strong>Distance</strong></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell variant='head' align="left"><strong>Price</strong></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell variant='head' align="left"><strong>Last Sold</strong></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell variant='head' align="left"><strong>Days on Market</strong></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell variant='head' align="left"><strong>Year Built</strong></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell variant='head' align="left"><strong>Beds</strong></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell variant='head' align="left"><strong>Baths</strong></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell variant='head' align="left"><strong>GLA</strong></TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </TableCell>
              <TableCell padding={'none'} style={{
                borderLeft: '1px solid #bbb',
                borderBottom: 'none'
              }}>
                <TableContainer>
                  <Table className={classes.innerTable} size='small'>
                    <TableBody>
                      <TableRow>
                        <TableCell variant='head'><DataContainer /></TableCell>
                      </TableRow>
                      <TableRow><TableCell variant='head' align="center" style={{
                        minWidth: 100
                      }} className='address'><DataContainer value={subjectProperyData.address} /></TableCell></TableRow>
                      <TableRow><TableCell variant='body' align="center"><DataContainer value={subjectProperyData.distance ? `${subjectProperyData.distance} mi.` : '0 mi.'} /></TableCell></TableRow>
                      <TableRow><TableCell variant='body' align="center"><DataContainer value={subjectProperyData.salePrice ? formatCurrency(subjectProperyData.salePrice) : '$0.00'} /></TableCell></TableRow>
                      <TableRow><TableCell variant='body' align="center"><DataContainer value={'---'} /></TableCell></TableRow>
                      <TableRow><TableCell variant='body' align="center"><DataContainer value={'---'} /></TableCell></TableRow>
                      <TableRow><TableCell variant='body' align="center"><DataContainer value={subjectProperyData.yearBuilt} /></TableCell></TableRow>
                      <TableRow><TableCell variant='body' align="center"><DataContainer value={subjectProperyData.beds} /></TableCell></TableRow>
                      <TableRow><TableCell variant='body' align="center"><DataContainer value={subjectProperyData.baths} /></TableCell></TableRow>
                      <TableRow><TableCell variant='body' align="center"><DataContainer value={subjectProperyData.gla ? `${subjectProperyData.gla} sqft` : '0 sqft'} /></TableCell></TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </TableCell>
              {/* <TableCell padding={'none'} style={{
                borderLeft: '1px solid #bbb',
                borderBottom: 'none'
              }}>
                {
                  datascienceComp1 ?
                    <TableContainer>
                      <Table className={classes.innerTable} size='small' >
                        <TableBody>
                          <TableRow>
                            <TableCell variant='head'>
                              <Box textAlign={'center'}>
                                <ConfirmationButton
                                  testId='removeComp'
                                  size="small"
                                  color="primary"
                                  variant="contained"
                                  style={{
                                    height: 20
                                  }}
                                  startIcon={<DeleteOutline fontSize='small' />}
                                  onClick={() => {
                                    setDatascienceComp1(undefined);
                                  }}
                                  confirmDialogProps={{
                                    title: 'Confirmation',
                                    body: (
                                      <>
                                        Are you sure you want to remove this comp?
                                        <br /><br />
                                      </>
                                    ),
                                    confirmButtonText: 'Yes',
                                    removeContentBox: true,
                                  }}>
                                  Remove
                                </ConfirmationButton>
                              </Box>
                            </TableCell>
                          </TableRow>
                          <TableRow><TableCell variant='head' align="center" style={{
                            minWidth: 100
                          }} className='address'>
                            <DataContainer value={datascienceComp1.address} />
                            {
                              datascienceComp1.address.includes('Appraisal Comp') &&
                              <Box position="absolute" bottom={0} right={0} m={0.5} display={'flex'}>
                                <Tooltip title="This comp matches with the Appraisal Comp">
                                  <CheckCircle fontSize="small" style={{
                                    color: 'green'
                                  }} />
                                </Tooltip>
                              </Box>
                            }
                          </TableCell>
                          </TableRow>
                          <TableRow><TableCell variant='body' align="center"><DataContainer value={datascienceComp1.distance} /></TableCell></TableRow>
                          <TableRow><TableCell variant='body' align="center"><DataContainer value={formatCurrency(datascienceComp1.salePrice)} /></TableCell></TableRow>
                          <TableRow><TableCell variant='body' align="center"><DataContainer value={datascienceComp1.dateOfSale} /></TableCell></TableRow>
                          <TableRow><TableCell variant='body' align="center"><DataContainer value={datascienceComp1.daysOnMarket} /></TableCell></TableRow>
                          <TableRow><TableCell variant='body' align="center"><DataContainer value={datascienceComp1.yearBuilt} /></TableCell></TableRow>
                          <TableRow><TableCell variant='body' align="center"><DataContainer value={datascienceComp1.beds} /></TableCell></TableRow>
                          <TableRow><TableCell variant='body' align="center"><DataContainer value={datascienceComp1.baths} /></TableCell></TableRow>
                          <TableRow><TableCell variant='body' align="center"><DataContainer value={datascienceComp1.gla} /></TableCell></TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer> :
                    <Box minWidth={150} textAlign={'center'}>
                      <Button testId='addComp' size="small" color="primary"
                        variant="contained"
                        // startIcon={<Add />}
                        onClick={() => {
                          setSelectedIndex(0);
                          setOpen(true);
                        }}>
                        Add
                      </Button>
                    </Box>
                }
              </TableCell>
              <TableCell padding={'none'} style={{
                borderLeft: '1px solid #eee',
                borderBottom: 'none'
              }}>
                {
                  datascienceComp2 ?
                    <TableContainer>
                      <Table className={classes.innerTable} size='small'>
                        <TableBody>
                          <TableRow>
                            <TableCell variant='head'>
                              <Box textAlign={'center'}>
                                <ConfirmationButton
                                  testId='removeComp'
                                  size="small"
                                  color="primary"
                                  variant="contained"
                                  style={{
                                    height: 20
                                  }}
                                  startIcon={<DeleteOutline fontSize='small' />}
                                  onClick={() => {
                                    setDatascienceComp2(undefined);
                                  }}
                                  confirmDialogProps={{
                                    title: 'Confirmation',
                                    body: (
                                      <>
                                        Are you sure you want to remove this comp?
                                        <br /><br />
                                      </>
                                    ),
                                    confirmButtonText: 'Yes',
                                    removeContentBox: true,
                                  }}>
                                  Remove
                                </ConfirmationButton>
                              </Box>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell variant='head' align="center" style={{
                              minWidth: 100
                            }} className='address'>
                              <DataContainer value={datascienceComp2.address} />
                              {
                                datascienceComp2.address.includes('Appraisal Comp') &&
                                <Box position="absolute" bottom={0} right={0} m={0.5} display={'flex'}>
                                  <Tooltip title="This comp matches with the Appraisal Comp">
                                    <CheckCircle fontSize="small" style={{
                                      color: 'green'
                                    }} />
                                  </Tooltip>
                                </Box>
                              }
                            </TableCell>
                          </TableRow>
                          <TableRow><TableCell variant='body' align="center"><DataContainer value={datascienceComp2.distance} /></TableCell></TableRow>
                          <TableRow><TableCell variant='body' align="center"><DataContainer value={formatCurrency(datascienceComp2.salePrice)} /></TableCell></TableRow>
                          <TableRow><TableCell variant='body' align="center"><DataContainer value={datascienceComp2.dateOfSale} /></TableCell></TableRow>
                          <TableRow><TableCell variant='body' align="center"><DataContainer value={datascienceComp2.daysOnMarket} /></TableCell></TableRow>
                          <TableRow><TableCell variant='body' align="center"><DataContainer value={datascienceComp2.yearBuilt} /></TableCell></TableRow>
                          <TableRow><TableCell variant='body' align="center"><DataContainer value={datascienceComp2.beds} /></TableCell></TableRow>
                          <TableRow><TableCell variant='body' align="center"><DataContainer value={datascienceComp2.baths} /></TableCell></TableRow>
                          <TableRow><TableCell variant='body' align="center"><DataContainer value={datascienceComp2.gla} /></TableCell></TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer> :
                    <Box minWidth={150} textAlign={'center'}>
                      <Button testId='addComp' size="small" color="primary"
                        variant="contained"
                        // startIcon={<Add />}
                        onClick={() => {
                          setSelectedIndex(1);
                          setOpen(true);
                        }}>
                        Add
                      </Button>
                    </Box>
                }
              </TableCell>
              <TableCell padding={'none'} style={{
                borderLeft: '1px solid #eee',
                borderBottom: 'none'
              }}>
                {
                  datascienceComp3 ?
                    <TableContainer>
                      <Table className={classes.innerTable} size='small'>
                        <TableBody>
                          <TableRow>
                            <TableCell variant='head'>
                              <Box textAlign={'center'}>
                                <ConfirmationButton
                                  testId='removeComp'
                                  size="small"
                                  color="primary"
                                  variant="contained"
                                  style={{
                                    height: 20
                                  }}
                                  startIcon={<DeleteOutline fontSize='small' />}
                                  onClick={() => {
                                    setDatascienceComp3(undefined);
                                  }}
                                  confirmDialogProps={{
                                    title: 'Confirmation',
                                    body: (
                                      <>
                                        Are you sure you want to remove this comp?
                                        <br /><br />
                                      </>
                                    ),
                                    confirmButtonText: 'Yes',
                                    removeContentBox: true,
                                  }}>
                                  Remove
                                </ConfirmationButton>
                              </Box>
                            </TableCell>
                          </TableRow>
                          <TableRow><TableCell variant='head' align="center" style={{
                            minWidth: 100
                          }} className='address'>
                            <DataContainer value={datascienceComp3.address} />
                            {
                              datascienceComp3.address.includes('Appraisal Comp') &&
                              <Box position="absolute" bottom={0} right={0} m={0.5} display={'flex'}>
                                <Tooltip title="This comp matches with the Appraisal Comp">
                                  <CheckCircle fontSize="small" style={{
                                    color: 'green'
                                  }} />
                                </Tooltip>
                              </Box>
                            }
                          </TableCell>
                          </TableRow>
                          <TableRow><TableCell variant='body' align="center"><DataContainer value={datascienceComp3.distance} /></TableCell></TableRow>
                          <TableRow><TableCell variant='body' align="center"><DataContainer value={formatCurrency(datascienceComp3.salePrice)} /></TableCell></TableRow>
                          <TableRow><TableCell variant='body' align="center"><DataContainer value={datascienceComp3.dateOfSale} /></TableCell></TableRow>
                          <TableRow><TableCell variant='body' align="center"><DataContainer value={datascienceComp3.daysOnMarket} /></TableCell></TableRow>
                          <TableRow><TableCell variant='body' align="center"><DataContainer value={datascienceComp3.yearBuilt} /></TableCell></TableRow>
                          <TableRow><TableCell variant='body' align="center"><DataContainer value={datascienceComp3.beds} /></TableCell></TableRow>
                          <TableRow><TableCell variant='body' align="center"><DataContainer value={datascienceComp3.baths} /></TableCell></TableRow>
                          <TableRow><TableCell variant='body' align="center"><DataContainer value={datascienceComp3.gla} /></TableCell></TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer> :
                    <Box minWidth={150} textAlign={'center'}>
                      <Button testId='addComp' size="small" color="primary"
                        variant="contained"
                        // startIcon={<Add />}
                        onClick={() => {
                          setSelectedIndex(2);
                          setOpen(true);
                        }}>
                        Add
                      </Button>
                    </Box>
                }
              </TableCell> */}
              {appraisalComps?.map(comp => {
                return(
                  <AppraisalCompTable appraisalCompData = {comp}/>
                )
              })}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <CompSelectionDialog
        subjectPropertyComp={subjectProperyData}
        open={open}
        onClose={() => setOpen(false)}
        onSelect={(data) => {
          if (selectedIndex == 0) {
            setDatascienceComp1(data);
          } else if (selectedIndex == 1) {
            setDatascienceComp2(data);
          } else if (selectedIndex == 2) {
            setDatascienceComp3(data);
          }
          setOpen(false);
        }} />
    </Paper>
  );
})