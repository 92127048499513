import { Box, Dialog, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Money } from '@material-ui/icons';
import { useBaseStore } from '@roc/feature-app-core';
import { Button, SelectField } from '@roc/ui';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useBorrowerLoanSubmissionStore } from '../../borrowerLoanSubmisison/hooks/useBorrowerLoanSubmissionStore';
import { selectLoanTypeModalBg } from './assets';
import { LoanApplicationCardTitle } from './components/loanApplicationComponents';
import { useNewLoanApplicationRoutes } from './hooks/useNewLoanApplicationRoutesConfig';
import { useNewLoanApplicationStore } from './hooks/useNewLoanApplicationStore';
import { LoanApplicationStep, loanTypeOptions } from './utils/loanApplicationConstants';

const useStyles = makeStyles(theme => ({
  headerImg: {
    width: '100%',
    height: 120,
    objectFit: 'cover',
  },
  dialog: {},
}));

export const NewLoanApplicationModal = observer(() => {
  const classes = useStyles();
  const { push } = useHistory();
  const { globalStore } = useBaseStore();
  const { newLoanApplicationStore } = useNewLoanApplicationStore();
  const { loanApplicationRoutesConfig } = useNewLoanApplicationRoutes();
  const { loanType, loanApplicationModalOpen } = newLoanApplicationStore;
  const { lenderTradeName } = globalStore.lenderInfo
  const { borrowerLoanSubmissionStore } = useBorrowerLoanSubmissionStore();

  return (
    <Dialog maxWidth={'sm'} open={loanApplicationModalOpen} className={classes.dialog}>
      <img src={selectLoanTypeModalBg} className={classes.headerImg} />
      <Box px={4} pt={4} pb={2}>
        <LoanApplicationCardTitle
          title={'Start Loan Submission'}
          icon={<Money />}
        />
      </Box>
      <Box px={4}>
        <Box py={2}>
          <Typography>
            Thank you for considering {lenderTradeName} for your next investment project!
            <br /><br />
            By following the steps in this process you can submit a loan directly with your account executive. Please begin by selecting a loan type
          </Typography>
        </Box>
        <Box py={2}>
          <SelectField
            label={'Select a Loan Type'}
            value={loanType}
            required={true}
            options={loanTypeOptions}
            onChange={value => newLoanApplicationStore.setLoanType(value)}
            variant={'outlined'}
            fullWidth
            testId={'loanType'}
          />
        </Box>
      </Box>
      <Box px={4} pt={2} pb={4} display={'flex'}>
        <>
          <Button
            style={{ marginRight: '8px' }}
            fullWidth
            onClick={() => newLoanApplicationStore.closeNewLoanApplicationModal()}
            color="primary"
            variant="outlined"
            testId="cancel"
          >
            CLOSE
          </Button>
          <Button
            fullWidth
            onClick={() => {
              newLoanApplicationStore.startLoanApplication()
              borrowerLoanSubmissionStore.fixFlipStore.resetForNewLoanApplication();
              borrowerLoanSubmissionStore.singlePropertyStore.resetForNewLoanApplication();
              push(loanApplicationRoutesConfig.loanApplication.url)
            }}
            color="primary"
            variant="contained"
            testId="save"
            disabled={!loanType}
          >
            START
          </Button>
        </>
      </Box>
    </Dialog>
  );
});
