import { Box } from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import {
  ConfirmationButton,
  DataGrid,
  GridColumnProps,
  LinkColumn,
  Toolbar,
} from '@roc/ui';
import { formatDate, LoanSubType } from '@roc/feature-utils';
import { Button, IconButton, FilterButton } from '@roc/ui';
import { filterProps, formatCurrency } from '@roc/ui/utils';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { Layout } from '@roc/feature-app-core';
import { useOneToolRoutes } from './hooks/useOneToolRoutes';
import { useOneToolStore } from './hooks/useOneToolStore';
import { useQuoteGeneratorRoutes } from '@roc/feature-quote-generator';
import { routeByLoanSubType } from '@roc/feature-loans';

const columns: GridColumnProps[] = [
  {
    field: 'quoteId',
    headerName: 'Number',
    maxWidth: 120,
    cellRenderer: 'addressCellRenderer',
    ...filterProps(),
  },
  {
    field: 'propertyAddress',
    headerName: 'Property Address',
    minWidth: 225,
    cellRenderer: 'addressCellRenderer',
    ...filterProps(),
  },
  {
    field: 'acquisitionPrice',
    headerName: 'Acquisition Price',
    minWidth: 225,
    cellRenderer: 'currencyCellRenderer',
    ...filterProps(),
  },
  {
    field: 'creation',
    headerName: 'Creation',
    minWidth: 225,
    cellRenderer: 'dateCellRenderer',
    filter: 'agDateColumnFilter',
    ...filterProps(),
  },
  {
    field: 'lastUpdate',
    headerName: 'Last Update',
    minWidth: 225,
    ...filterProps(),
  },
  {
    field: 'quoteId',
    headerName: 'Actions',
    minWidth: 225,
    cellRenderer: 'actionCellRenderer',
  },
];

export const QuotesDashboard = observer(() => {
  const history = useHistory();
  const { oneToolRoutesConfig } = useOneToolRoutes();
  const { oneToolStore } = useOneToolStore();
  const { quoteGeneratorRoutesConfig } = useQuoteGeneratorRoutes();
  const { quotesDashboardStore } = oneToolStore;
  const gridStore = quotesDashboardStore.quotesDashboardGridStore;

  const createQuote = () => {
    const url = oneToolRoutesConfig.oneTool.url;
    history.push(url);
  };

  const getRouteBySubtype = (quoteId, loanSubtype, oneToolQuote) => {
    if (!oneToolQuote) {
      return quoteGeneratorRoutesConfig.viewQuote(quoteId).url;
    } else {
      return oneToolRoutesConfig[routeByLoanSubType[loanSubtype]](quoteId).url;
    }
  };

  const editQuote = (quoteId, loanSubtype, oneToolQuote) => {
    history.push(getRouteBySubtype(quoteId, loanSubtype, oneToolQuote));
  };

  const deleteQuote = quoteId => {
    quotesDashboardStore.deleteQuote(quoteId);
  };

  useEffect(() => {
    gridStore.resetAndFetchGridData();
  }, []);

  const toolbar = (
    <Toolbar>
      <Box mr={2}>
        <FilterButton
          onToggleFilters={() => gridStore.toggleFilters()}
          onClearFilters={() => gridStore.resetFilters()}
        />
      </Box>
      <Box>
        <Button
          variant="contained"
          size="small"
          onClick={() => createQuote()}
          color="primary"
          testId="creqte-new-quote"
        >
          Create Quote
        </Button>
      </Box>
    </Toolbar>
  );

  const frameworkComponents = {
    addressCellRenderer(params) {
      const quoteId = params.node.data?.quoteId;
      const loanSubtype = params.node.data?.loanSubtype;
      const oneToolQuote = params.node.data?.oneToolQuote;
      return LinkColumn({
        ...params,
        url: getRouteBySubtype(quoteId, loanSubtype, oneToolQuote),
      });
    },
    currencyCellRenderer(params) {
      return params.value ? formatCurrency(params.value) : '';
    },
    dateCellRenderer(params) {
      return params.value
        ? formatDate(params.value, 'MM/dd/yyyy hh:mm:ss')
        : null;
    },
    actionCellRenderer(params) {
      const quoteId = params.node.data?.quoteId;
      const loanSubtype = params.node.data?.loanSubtype;
      const oneToolQuote = params.node.data?.oneToolQuote;
      return (
        <>
          <IconButton
            testId={`edit-quote-${quoteId}`}
            onClick={() => {
              editQuote(quoteId, loanSubtype, oneToolQuote);
            }}
          >
            <Edit color="primary" />
          </IconButton>

          <ConfirmationButton
            testId={`delete-quote-${quoteId}`}
            onClick={() => deleteQuote(quoteId)}
            confirmDialogProps={{
              body: 'Are you sure you want to remove this quote?',
            }}
          >
            <Delete color="primary" />
          </ConfirmationButton>
        </>
      );
    },
  };

  const onFilterChanged = params => {
    const filterModel = params.api.getFilterModel();
    gridStore.setFilterModel(filterModel);
  };

  const onSortChanged = params => {
    const sortModel = params.api.getSortModel();
    gridStore.setSortModel(sortModel);
  };

  return (
    <Layout title="Bridge Quotes">
      <DataGrid
        columns={columns}
        rows={gridStore.gridData.data.rows}
        frameworkComponents={frameworkComponents}
        toolbar={toolbar}
        onSortChanged={onSortChanged}
        onFilterChanged={onFilterChanged}
        isLoading={gridStore.isLoading}
        showFilters={gridStore.showFilters}
        sortModel={gridStore.sortModel}
        filterModel={gridStore.filterModel}
        paginationData={gridStore.gridData.meta}
        setPageNumber={gridStore.setPageNumber}
      />
    </Layout>
  );
});
