import { AppBar, Box, Container, Grid, Toolbar, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { BORROWER_PORTAL_MAX_WIDTH } from '@roc/feature-borrower-portal-core';
import { useAppConfigurations } from '@roc/feature-app-core';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  appBar: {
    color: '#212B36',
    background: '#fff',
    display: 'flex',
    flexDirection: 'column',
    backdropFilter: 'blur(6px)',
    borderBottom: '2px solid #555',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  toolbar: theme.mixins.toolbar,
  toolbarCustomized: {
    height: 80,
    maxWidth: BORROWER_PORTAL_MAX_WIDTH,
    margin: 'auto',
    width: '100%',
  },
  content: {
    flexGrow: 1,
    /* padding: theme.spacing(0, 1, 3, 1), */
    padding: theme.spacing(0, 0.5, 3, 0.5),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0
  },
  container: {
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0),
    },
  }
}));

export const PublicContainerWithLogo = observer(({ children }) => {
  const classes = useStyles();
  const { appLogo, appLogoBackground } = useAppConfigurations();

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={clsx(classes.appBar)}>
        <Toolbar className={classes.toolbarCustomized}>
          {
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              bgcolor={appLogoBackground ? appLogoBackground : 'inherit'}
              mr={2}
            >
              {appLogo && <img
                style={{
                  maxHeight: 80,
                  padding: 8,
                  cursor: 'pointer',
                }}
                alt="App Logo"
                src={appLogo}
              />}
            </Box>
          }
        </Toolbar>
      </AppBar>
      <main className={clsx(classes.content)}>
        <div className={clsx(classes.toolbar, classes.toolbarCustomized)} />
        <Container className={classes.container} maxWidth={'xl'}>
          {children}
        </Container>
      </main>
    </div>
  );
});
