import { useBaseStore } from "@roc/feature-app-core";
import { getBorrowersRoutes, getBorrowersRoutesConfig } from "../routes/borrowersRoutes";

export const useBorrowersRoutes = () => {
  const { globalStore } = useBaseStore();
  const { routeBasePath, routeBaseUrl } = globalStore;
  return {
    borrowersRoutes: getBorrowersRoutes(routeBasePath, routeBaseUrl),
    borrowersRoutesConfig: getBorrowersRoutesConfig(
      routeBasePath,
      routeBaseUrl
    ),
  };
};
