import {
  Dialog,
  DialogContent,
  DialogTitle as MuiDialogTitle,
  Typography,
  IconButton,
  Button,
  DialogActions,
} from '@material-ui/core';
import {
  Theme,
  WithStyles,
  createStyles,
  withStyles,
} from '@material-ui/core/styles';
import { Close } from '@material-ui/icons';

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const styles = (theme: Theme) =>
  createStyles({
    dialogContetBox: {
      width: '100%',
      margin: 0,
      position: 'absolute',
      top: '50%',
      left: '50%',
      msTransform: 'translate(-50%, -50%)',
      transform: 'translate(-50%, -50%)',
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography {...other}>
      <Typography variant="h4">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <Close />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export default function MersRMConfirmationModal({
  open,
  handleClose,
  handleSave,
}) {
  const handleDialogClose = e => {
    handleClose(e);
  };

  const handleSaveAfterConfirmation = e => {
    handleSave();
    handleDialogClose(e);
  };
  return (
    <div>
      <Dialog
        scroll={'paper'}
        maxWidth={'lg'}
        open={open}
        onClose={handleDialogClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle
          id="mers-on-boarding-title"
          onClose={() => handleDialogClose(null)}
        >
          Confirmation
        </DialogTitle>
        <DialogContent>MERS DocuSign executed for this account?</DialogContent>
        <DialogActions>
          <Button
            style={{ marginRight: 16 }}
            onClick={handleClose}
            variant="outlined"
            color="default"
          >
            No
          </Button>
          <Button
            onClick={handleSaveAfterConfirmation}
            variant="contained"
            color="primary"
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
