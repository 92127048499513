import { Grid } from '@material-ui/core';
import { Document } from '@roc/feature-types';
import { Button, StandardDialog } from '@roc/ui';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useDocumentStore } from '../../../hooks/useDocumentStore';
import BorrowerSurvey from './borrowerSurvey';

export type BorrowerSurveyDialogProps = {
  open?: boolean;
  onClose?: () => void;
  loanId: string;
  document: Document;
};

export const BorrowerSurveyDialog = observer(
  (props: BorrowerSurveyDialogProps) => {
    const { open, onClose, loanId, document } = props;
    const { documentStore } = useDocumentStore();
    const { documentFormStore } = documentStore;
    const { borrowerSurveyStore } = documentFormStore;

    const onSubmit = () => {
      borrowerSurveyStore.submit(document);
      onClose();
    };

    useEffect(() => {
      if (open) {
        borrowerSurveyStore.initForm(document.borrowerId);
      }
    }, [open]);

    return (
      <StandardDialog
        open={open}
        title="Borrower Survey Questionnaire"
        maxWidth="lg"
        handleClose={onClose}
        dialogContent={<BorrowerSurvey store={borrowerSurveyStore} />}
        dialogActions={
          <Grid container spacing={1} justifyContent="flex-end">
            <Grid item>
              <Button
                onClick={onClose}
                color="primary"
                variant="outlined"
                testId="cancel"
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={onSubmit}
                disabled={!borrowerSurveyStore.canSubmit}
                color="primary"
                variant="contained"
                testId="confirm"
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        }
      />
    );
  }
);

export default BorrowerSurveyDialog;
