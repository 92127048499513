import { ApiResponse, GlobalStore, GridStore } from "@roc/feature-app-core";
import { computed, makeObservable, observable, flow } from 'mobx';
import { ManageUsersBackUpsService } from "../services/manageUsersBackUpsService";

export class ManageUsersBackUpStore {
  private globalStore: GlobalStore;
  manageUsersBackUpsService: ManageUsersBackUpsService;
  gridStore: GridStore;

  processors: any[] = [];

  constructor(globalStore: GlobalStore) {
    this.globalStore = globalStore;
    this.manageUsersBackUpsService = new ManageUsersBackUpsService();
    this.gridStore = new GridStore(() => this.getAllUserBackUps());

    makeObservable(this, {
      processorOptions: computed,
      saveUserBackUp: flow,
      deleteUserBackUp: flow,
    })
  }

  private async getAllUserBackUps() {
    try {
      const response: ApiResponse = await this.manageUsersBackUpsService.getAllUserBackUps(
        this.gridStore.gridData.meta.pageNumber,
        this.gridStore.gridData.meta.pageSize,
        this.gridStore.gridData.meta.sortBy,
        this.gridStore.gridData.meta.sortDir,
        this.gridStore.gridData.meta.filters,
        this.gridStore.gridData.meta.dropdownFilters,
        this.globalStore.selectedCompanyId
      );
      if (!this.gridStore.gridData.meta.filters) {
        this.processors = response.data.data.rows.map((item) => ({
          processorUsername: item.processorUsername,
          userId: item.userId
        }));
      }
      return response;
    }
    catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching users'
      });
    }
  }


  *saveUserBackUp(userId, userBackupId, backupId) {
    try {
      yield this.manageUsersBackUpsService.saveUserBackUp(userId, userBackupId, backupId);
      this.gridStore.fetchGridData();
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Success!'
      })
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while saving user backup selection'
      });
    }
  }

  *deleteUserBackUp(userBackupId) {
    try {
      yield this.manageUsersBackUpsService.deleteUserBackUp(userBackupId);
      this.gridStore.fetchGridData();
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Success!'
      })
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while removing user backup selection'
      });
    }
  }

  get processorOptions() {
    const options = [
      ...this.processors.map(processor => ({
        label: processor.processorUsername,
        value: processor.userId,
      })),
      { label: 'Please select an option', value: 'default' },
    ];
    return options;
  }

}