import {
  Box
} from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { useCommunicationRoutesConfig, useLoanCommunicationStore } from '@roc/feature-communication';
import { useBorrowerLiteLoanRoutes } from '@roc/feature-loans-routes-config';
import { Paper } from '@roc/ui';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useBorrowerLoansStore } from '../../hooks/useBorrowerLoansStore';
import { BorrowerLoanDetailsLiteChildRoutes } from './components/borrowerLiteLoanDetailsChildRoutes';
import { LoanDetailsHeader } from './components/loanDetailsHeader';
import { LoanDetailsTabs } from './components/loanDetailsTabs';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2, 0),
    },
  },
  headingClass: {
    color: '#4B89DA',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
}));

export const BorrowerLoanDetailsLite = observer(() => {
  const classes = useStyles();
  const { loanId } = useParams<{ loanId: string }>();
  const [selectedTab, setSelectedTab] = useState(0);
  const { borrowerLoansStore } = useBorrowerLoansStore();
  const { borrowerLiteLoanRoutesConfig } = useBorrowerLiteLoanRoutes();
  const { communicationRoutesConfig } = useCommunicationRoutesConfig();
  const { loanCommunicationStore } = useLoanCommunicationStore();
  const { push } = useHistory();
  const { pathname } = useLocation();
  const {
    loanDetails
  } = borrowerLoansStore;

  const tabs = [
    {
      label: 'Loan Summary',
      url: borrowerLiteLoanRoutesConfig.loanview(loanId).children.dashboard.url
    },
    {
      label: 'Documents',
      url: borrowerLiteLoanRoutesConfig.loanview(loanId).children.borrowerDocuments.url
    }
  ];

  useEffect(() => {
    tabs.forEach((t, i) => {
      if (pathname.indexOf(t.url) !== -1) {
        setSelectedTab(i);
      }
    });
  }, [tabs, pathname]);

  useEffect(() => {
    borrowerLoansStore.fetchLoanDetails(loanId);
  }, [loanId]);

  if (!loanDetails) {
    return null;
  }

  const onTabChange = (e, index) => {
    push(tabs[index].url);
    setSelectedTab(index);
  }

  return (
    <Box className={classes.container}>
      <Box display="flex" flexDirection="column" mb={3}>
        <Paper style={{ padding: '24px 24px 0 24px' }}>
          <Box display="flex" alignItems="center" flexDirection="column">
            <Box width={'100%'} display="flex" flexDirection="column" overflow="hidden" textAlign={'center'}>
              <Box mb={3}>
                <LoanDetailsHeader loanDetails={loanDetails} overviewUrl={borrowerLiteLoanRoutesConfig.loanview(loanDetails?.loanId).children.dashboard.url} />
              </Box>
            </Box>
            <Box maxWidth={'100%'}>
              <LoanDetailsTabs tabs={tabs} tabIndex={selectedTab} onTabChange={onTabChange} />
            </Box>
          </Box>
        </Paper>
      </Box>
      <Box display="flex">
        <Box flexGrow={1}>
          <BorrowerLoanDetailsLiteChildRoutes isNew={true} />
        </Box>
      </Box>
    </Box>
  );
});
