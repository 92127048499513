import { useEffect } from 'react';
import { observer } from 'mobx-react';
import CalendlyWidget from './calendlyWidget';
import { useLoanStore } from '@roc/feature-loans';

export const UnderwriterCalendly = observer(({ calendlyUrl, prefill, showCalendly }) => {
  return (
    <div>
      {(showCalendly && calendlyUrl) ? (
         <CalendlyWidget url={calendlyUrl}  prefill={prefill} />
      ) : null}
    </div>
  );
});
