import { Box, Grid } from '@material-ui/core';
import {
  AutocompleteAddressField,
  Button,
  CurrencyField,
  DateField,
  TextField,
  NumberFormat,
} from '@roc/ui';
import { format, isValid } from 'date-fns';
import { observer } from 'mobx-react';
import React from 'react';
import { useStore } from '../../../hooks';
import { isNotBlank } from '@roc/client-portal-shared/utils';

const Address = observer(({ store }) => {
  const formFields = store.form.fields;
  const handleAddressChange = (event, name) => {
    const { value, fullInfo } = event?.target || {};
    const { address_components, geometry } = fullInfo || {};
    store.saveAddressField(value, address_components, geometry);
  };

  return (
    <>
      <AutocompleteAddressField
        label="Address"
        required={true}
        value={formFields.address.value}
        name="address"
        error={isNotBlank(formFields.address.error)}
        errorText={formFields.address.error}
        onChange={event => handleAddressChange(event, 'address')}
        fullWidth
        testId="address"
      />
    </>
  );
});

export const CreditAuthorizationForm = observer(() => {
  const { creditAuthorizationStore } = useStore();
  const formFields = creditAuthorizationStore.form.fields;

  const manageDate = (fieldName, date) => {
    let formattedDate = null;
    if (isValid(date)) {
      formattedDate = format(date, 'MM/dd/yyyy');
    }
    creditAuthorizationStore.onFieldChange(fieldName, formattedDate);
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} sm={6}>
        <TextField
          testId="firstName"
          required
          variant="outlined"
          label="First Name"
          value={formFields.firstName.value}
          onChange={e =>
            creditAuthorizationStore.onFieldChange('firstName', e.target.value)
          }
          error={isNotBlank(formFields.firstName.error)}
          helperText={formFields.firstName.error}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          testId="lastName"
          required
          variant="outlined"
          label="Last Name"
          value={formFields.lastName.value}
          onChange={e =>
            creditAuthorizationStore.onFieldChange('lastName', e.target.value)
          }
          error={isNotBlank(formFields.lastName.error)}
          helperText={formFields.lastName.error}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <DateField
          testId="dob"
          required
          inputVariant="outlined"
          format="MM/dd/yyyy"
          label="Date of Birth"
          value={formFields.dob.value}
          onChange={date => manageDate('dob', date)}
          disableFuture={true}
          error={isNotBlank(formFields.dob.error)}
          helperText={formFields.dob.error}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <NumberFormat
          customInput={TextField}
          format="###-##-####"
          mask="_"
          required={true}
          variant="outlined"
          label="SSN"
          testId="ssn"
          value={formFields.ssn.value}
          onChange={e =>
            creditAuthorizationStore.onFieldChange('ssn', e.target.value)
          }
          error={isNotBlank(formFields.ssn.error)}
          helperText={formFields.ssn.error}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={8}>
        <Address store={creditAuthorizationStore}></Address>
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          testId="aptNumber"
          standaloneLabel
          variant="outlined"
          label="Apt # (optional)"
          value={formFields.aptNumber.value}
          onChange={e =>
            creditAuthorizationStore.onFieldChange('aptNumber', e.target.value)
          }
          error={isNotBlank(formFields.aptNumber.error)}
          helperText={formFields.aptNumber.error}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <CurrencyField
          required
          variant="outlined"
          standaloneLabel
          label="Net Worth"
          value={formFields.netWorth.value}
          errorText={formFields.netWorth.error}
          error={isNotBlank(formFields.netWorth.error)}
          onChange={(name, value) =>
            creditAuthorizationStore.onFieldChange('netWorth', Number(value))
          }
          fullWidth
          testId="netWorth"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          testId="experience"
          required
          type="number"
          variant="outlined"
          standaloneLabel
          label="Number of real estate transactions made in last 5 years"
          value={formFields.experience.value}
          onChange={e =>
            creditAuthorizationStore.onFieldChange('experience', e.target.value)
          }
          error={isNotBlank(formFields.experience.error)}
          helperText={formFields.experience.error}
          fullWidth
        />
      </Grid>
    </Grid>
  );
});
