import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { FieldContainer } from '@roc/ui/formComponents';
import {
  PropertyAddress,
  PropertyType,
  PropertyOwnership,
  PurchasePrice,
  ArmsLength,
  ArmsLengthDescribe,
  PropertySource,
  PropertySourceOther,
  WholesalerPropertyPaid,
  WholesalerPropertySource,
  WholesalerPropertySourceOther,
  AnyDebt,
  DebtAmount,
  PurchaseDate,
  RenovationBudget,
  RenovationDescription,
  RefinanceRenovationBudget,
  RefinanceInvestedCapitalImprovements,
  RefinanceRenovationAmountRemaining,
  AsIsValue,
  AfterRepairValue,
  ExitStrategy,
  AdditionalCommentsMF,
  SubmitRushAppraisal,
  RedFlagComments
} from '@roc/ui/formComponents';
import { GridRowSelectedEvent } from '@roc/ui';
import { renovationDescriptionsByLoanSubtype } from '../../utils/renovationDescriptions';
import { CONDOMINIUM, MIXED_USE, MULTIFAMILY_5_PLUS } from '@roc/feature-utils';
import { ChooseExistingPropertyAppraisal } from './chooseExistingPropertyAppraisal';
import { useMultifamilyStore } from '../../hooks/useMultiFamilyStore';
import { MixedUsePropertyInformation } from './mixedUsePropertyInformation';
import { MultifamilyPropertyInformationForm } from './multifamilyPropertyInformationForm';
import { CashOutForm } from './cashOutForm';
import { DSCRForm } from './DSCRForm';

export const AppraisalInformation = observer(() => {
  const {
    multiFamilyStore: { propertyStore },
  } = useMultifamilyStore();
  const { form } = propertyStore.appraisalInformationStore;

  useEffect(() => {
    if (!form.fields.chooseExistingAppraisal.value) {
      propertyStore.reset();
      propertyStore.appraisalInformationStore.onFieldChange(
        'chooseExistingAppraisal',
        false
      );
    }
  }, [form.fields.chooseExistingAppraisal.value]);

  const onRowSelected = (e: GridRowSelectedEvent) => {
    const { node } = e;

    if (node.isSelected()) {
      propertyStore.setAppraisalId(
        node.data?.appraisalData?.propertyAppraisalId
      );
      propertyStore.loadPropertyFromAppraisal(node.data);
    } else {
      propertyStore.reset();
    }
  };

  return (
    <>
      <FieldContainer>
        <ChooseExistingPropertyAppraisal
          store={propertyStore.appraisalInformationStore}
          onRowSelected={onRowSelected}
        />
      </FieldContainer>
    </>
  );
});

export const PropertyInformation = observer(() => {
  const { propertyStore } = useMultifamilyStore().multiFamilyStore;
  const { multiFamilyStore } = useMultifamilyStore();
  const { propertyInformationStore, appraisalId } = propertyStore;
  const isCondo =
    propertyInformationStore.form.fields.useCode.value === CONDOMINIUM;
  const propertyTypeOptions = [
    { value: MULTIFAMILY_5_PLUS, label: '5+ Unit Multifamily' },
    { value: MIXED_USE, label: 'Mixed Use' },
  ];
  return (
    <>
      <FieldContainer>
        <PropertyAddress
          store={propertyInformationStore}
          required
          disabled={appraisalId}
        />
      </FieldContainer>
      <FieldContainer>
        <PropertyType
          store={propertyInformationStore}
          propertyTypeOptions={propertyTypeOptions}
          disabled={appraisalId}
        />
      </FieldContainer>
      {propertyInformationStore?.form.fields.useCode.value === MIXED_USE ? (
        <FieldContainer>
          <MixedUsePropertyInformation store={propertyStore.mixedUsePropertyInformationStore} multiFamilyStore={multiFamilyStore} />
        </FieldContainer>
      ) : null}
      {propertyInformationStore?.form.fields.useCode.value === MULTIFAMILY_5_PLUS ? (
        <MultifamilyPropertyInformationForm
          store={propertyStore.multiFamilyPropertyInformationStore}
          multiFamilyStore={multiFamilyStore}
        />
      ) : null}
      <FieldContainer>
        <PropertyOwnership
          store={propertyInformationStore}
          disabled={appraisalId}
        />
      </FieldContainer>
    </>
  );
});

export const PurchaseInformation = observer(() => {
  const { propertyStore } = useMultifamilyStore().multiFamilyStore;
  const { purchaseInformationStore, appraisalId, propertyInformationStore, dscrPropertyInformationStore, cashOutPropertyInformationStore } = propertyStore;
  const isWholesalerProperty =
    purchaseInformationStore.form.fields.propertySourcing.value ===
    'Wholesaler';
  const isArmsLengthNo =
    purchaseInformationStore.form.fields.armsLength.value === 'N';
  const isPropertySourceOther =
    purchaseInformationStore.form.fields.propertySourcing.value === 'Other';
  const isWholesalerPropertySourceOther =
    purchaseInformationStore.form.fields.wholesalerPurchase.value === 'Other';
  return (
    <>
      {propertyInformationStore?.form.fields.propertyOwnership.value === 'Owned By Borrower' ? (
        <FieldContainer>
          <CashOutForm store={cashOutPropertyInformationStore} />
        </FieldContainer>
      ) : null}
      {propertyInformationStore?.form.fields.propertyOwnership.value === 'Owned By Borrower' && cashOutPropertyInformationStore?.form.fields.cashOut.value === 'Y' ? (
        <FieldContainer>
          <DSCRForm store={dscrPropertyInformationStore} />
        </FieldContainer>
      ) : null}
      <FieldContainer>
        <PurchasePrice
          store={purchaseInformationStore}
          disabled={appraisalId}
        />
      </FieldContainer>
      <FieldContainer>
        <ArmsLength store={purchaseInformationStore} disabled={appraisalId} />
      </FieldContainer>
      {isArmsLengthNo && (
        <FieldContainer>
          <ArmsLengthDescribe
            store={purchaseInformationStore}
            disabled={appraisalId}
          />
        </FieldContainer>
      )}
      <FieldContainer>
        <PropertySource
          store={purchaseInformationStore}
          disabled={appraisalId}
        />
      </FieldContainer>
      {isPropertySourceOther && (
        <FieldContainer>
          <PropertySourceOther
            store={purchaseInformationStore}
            disabled={appraisalId}
          />
        </FieldContainer>
      )}
      {isWholesalerProperty && (
        <FieldContainer>
          <WholesalerPropertyPaid
            store={purchaseInformationStore}
            disabled={appraisalId}
          />
        </FieldContainer>
      )}
      {isWholesalerProperty && (
        <FieldContainer>
          <WholesalerPropertySource
            store={purchaseInformationStore}
            disabled={appraisalId}
          />
        </FieldContainer>
      )}
      {isWholesalerProperty && isWholesalerPropertySourceOther && (
        <FieldContainer>
          <WholesalerPropertySourceOther
            store={purchaseInformationStore}
            disabled={appraisalId}
          />
        </FieldContainer>
      )}
    </>
  );
});

export const Refinance_PurchaseInformation = observer(() => {
  const { propertyStore } = useMultifamilyStore().multiFamilyStore;
  const { refinance_purchaseInformationStore, appraisalId, propertyInformationStore, cashOutPropertyInformationStore, dscrPropertyInformationStore } = propertyStore;
  const isAnyDebt =
    refinance_purchaseInformationStore.form.fields.anyDebt.value === 'Y';
  return (
    <>
      {propertyInformationStore?.form.fields.propertyOwnership.value === 'Owned By Borrower' ? (
        <FieldContainer>
          <CashOutForm store={cashOutPropertyInformationStore} />
        </FieldContainer>
      ) : null}
      {propertyInformationStore?.form.fields.propertyOwnership.value === 'Owned By Borrower' && cashOutPropertyInformationStore?.form.fields.cashOut.value === 'Y' ? (
        <FieldContainer>
          <DSCRForm store={dscrPropertyInformationStore} />
        </FieldContainer>
      ) : null}
      <FieldContainer>
        <AnyDebt
          store={refinance_purchaseInformationStore}
          disabled={appraisalId}
        />
      </FieldContainer>
      {isAnyDebt && (
        <FieldContainer>
          <DebtAmount
            store={refinance_purchaseInformationStore}
            disabled={appraisalId}
          />
        </FieldContainer>
      )}
      <FieldContainer>
        <PurchaseDate
          store={refinance_purchaseInformationStore}
          disabled={appraisalId}
        />
      </FieldContainer>
      <FieldContainer>
        <PurchasePrice
          store={refinance_purchaseInformationStore}
          disabled={appraisalId}
        />
      </FieldContainer>
    </>
  );
});

export const RenovationDetails = observer(() => {
  const { propertyStore, loanSubtype } = useMultifamilyStore().multiFamilyStore;
  const { renovationDetailsStore, appraisalId } = propertyStore;
  const totalRenovationBudget =
    renovationDetailsStore.form.fields.totalRenovationBudget.value;
  const renovationDescriptionOptions = renovationDescriptionsByLoanSubtype(
    loanSubtype
  );
  return (
    <>
      <FieldContainer>
        <RenovationBudget
          store={renovationDetailsStore}
          disabled={appraisalId}
          onChange={() => renovationDetailsStore.handleRenovationBudgetChange()}
        />
      </FieldContainer>
      {totalRenovationBudget > 0 && (
        <FieldContainer>
          <RenovationDescription
            store={renovationDetailsStore}
            disabled={appraisalId}
            renovationDescriptionOptions={renovationDescriptionOptions}
          />
        </FieldContainer>
      )}
    </>
  );
});

export const Refinance_RenovationDetails = observer(() => {
  const { propertyStore, loanSubtype } = useMultifamilyStore().multiFamilyStore;
  const { refinance_renovationDetailsStore, appraisalId } = propertyStore;
  const amountRemaining =
    refinance_renovationDetailsStore.form.fields.renovationRemaining.value > 0;
  const renovationDescriptionOptions = renovationDescriptionsByLoanSubtype(
    loanSubtype
  );
  return (
    <>
      <FieldContainer>
        <RefinanceRenovationBudget
          store={refinance_renovationDetailsStore}
          onChange={() =>
            refinance_renovationDetailsStore.handleRenovationBudgetChange()
          }
          disabled={appraisalId}
        />
      </FieldContainer>
      <FieldContainer>
        <RefinanceInvestedCapitalImprovements
          store={refinance_renovationDetailsStore}
          onChange={() =>
            refinance_renovationDetailsStore.handleRenovationSpentToDateChange()
          }
          disabled={appraisalId}
        />
      </FieldContainer>
      <FieldContainer>
        <RefinanceRenovationAmountRemaining
          store={refinance_renovationDetailsStore}
        />
      </FieldContainer>
      {amountRemaining && (
        <FieldContainer>
          <RenovationDescription
            store={refinance_renovationDetailsStore}
            disabled={appraisalId}
            renovationDescriptionOptions={renovationDescriptionOptions}
          />
        </FieldContainer>
      )}
    </>
  );
});

export const OtherDetails = observer(() => {
  const { multiFamilyStore } = useMultifamilyStore();
  const { propertyStore } = multiFamilyStore;
  const { otherDetailsStore, appraisalId, renovationDetailsStore, refinance_renovationDetailsStore, afterRepairValueStore } = propertyStore;
  return (
    <>
      {renovationDetailsStore.form.fields.totalRenovationBudget.value > 0 ||
        refinance_renovationDetailsStore.form.fields.totalRenovationBudget.value > 0 ||
        refinance_renovationDetailsStore.form.fields.renovationBudget.value > 0 ?
        (
          <FieldContainer>
            <AfterRepairValue store={afterRepairValueStore} disabled={appraisalId} />
          </FieldContainer>
        ) : null}
      <FieldContainer>
        <ExitStrategy store={otherDetailsStore} disabled={appraisalId} />
      </FieldContainer>
      <FieldContainer>
        <AsIsValue store={otherDetailsStore} disabled={appraisalId} />
      </FieldContainer>
      <FieldContainer>
        <AdditionalCommentsMF store={otherDetailsStore} disabled={appraisalId} />
      </FieldContainer>
      <FieldContainer>
        <RedFlagComments store={otherDetailsStore} disabled={appraisalId} />
      </FieldContainer>
    </>
  );
});

export const Refinance_OtherDetails = observer(() => {
  const { multiFamilyStore } = useMultifamilyStore();
  const { propertyStore } = multiFamilyStore;
  const { refinance_renovationDetailsStore, refinance_otherDetailsStore, appraisalId, afterRepairValueRefStore } = propertyStore;
  const amountRemaining =
    refinance_renovationDetailsStore.form.fields.renovationRemaining.value > 0;

  return (
    <>
      <FieldContainer>
        <AsIsValue store={refinance_otherDetailsStore} disabled={appraisalId} />
      </FieldContainer>
      {refinance_renovationDetailsStore.form.fields.totalRenovationBudget.value > 0 ||
        refinance_renovationDetailsStore.form.fields.renovationBudget.value > 0 ?
        (
          <FieldContainer>
            <AfterRepairValue store={afterRepairValueRefStore} disabled={appraisalId} />
          </FieldContainer>
        ) : null}
      <FieldContainer>
        <ExitStrategy
          store={refinance_otherDetailsStore}
          disabled={appraisalId}
        />
      </FieldContainer>
      <FieldContainer>
        <AdditionalCommentsMF
          store={refinance_otherDetailsStore}
          disabled={appraisalId}
        />
      </FieldContainer>
      <FieldContainer>
        <RedFlagComments store={refinance_otherDetailsStore} disabled={appraisalId} />
      </FieldContainer>
    </>
  );
});
