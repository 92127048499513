import React, { useState } from 'react';
import { Grid, Checkbox, Typography } from '@material-ui/core';
import { formatCurrency } from '@roc/ui/utils';
import { observer } from 'mobx-react';
import { Button, DataGrid, FileUploadModal, IconButton } from '@roc/ui';
import InsuranceApprovalStore from '../../../../../../stores/documentForms/insuranceApproval/insuranceApprovalStore';
import {
  InsuranceCompanyType,
  InsuranceDocumentType,
} from '../../../constants';
import { CloudUpload } from '@material-ui/icons';

const flatten = arr => arr.reduce((a, b) => a.concat(b), []);

const columns = [
  {
    field: 'quote',
    headerName: 'Quote Type',
    cellRenderer: 'quoteTypeCellRenderer',
    minWidth: 350,
  },
  {
    field: 'documentType',
    headerName: 'Document',
    maxWidth: 200,
  },
  {
    field: 'uploadedFile',
    headerName: 'Uploaded File',
  },
  {
    field: 'actions',
    headerName: '',
    maxWidth: 80,
    cellRenderer: 'actionsCellRenderer',
  },
];

export type FinalChoiceQuotesGridProps = {
  insuranceApprovalStore: InsuranceApprovalStore;
  disableSelection?: boolean;
};

export const UploadOtherFinalDocumentsGrid = observer(
  (props: FinalChoiceQuotesGridProps) => {
    const { insuranceApprovalStore } = props;
    const { insuranceApprovalFormStore } = insuranceApprovalStore;
    const [uploadDocument, setUploadDocument] = useState(null);

    const quotes = insuranceApprovalFormStore.finalQuotes.filter(
      q => q.insuranceCompany === InsuranceCompanyType.OTHER && q.selectedQuote
    );

    const getFileNames = (quote, type) => {
      const files = insuranceApprovalFormStore.uploadedFiles
        .filter(
          u =>
            u.insuranceDetailId === quote.insuranceDetailId &&
            u.fileType === type
        )
        .map(f => f.originalFileName);
      return files.join(', ');
    };

    const rows = flatten(
      quotes.map(quote => [
        {
          quote,
          documentType: InsuranceDocumentType.BINDER,
          uploadedFile: getFileNames(quote, InsuranceDocumentType.BINDER),
        },
        {
          quote,
          documentType: InsuranceDocumentType.INVOICE,
          uploadedFile: getFileNames(quote, InsuranceDocumentType.INVOICE),
        },
      ])
    );

    const frameworkComponents = {
      quoteTypeCellRenderer: params => {
        const quote = params.node.data?.quote;
        return quote ? quote.insuranceCompany + ' - ' + quote.quoteType : '';
      },
      actionsCellRenderer: params => {
        return (
          <>
            <IconButton
              testId="upload"
              color="primary"
              onClick={() => setUploadDocument(params.node.data)}
            >
              <CloudUpload />
            </IconButton>
          </>
        );
      },
    };

    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography color="primary">Upload Other Final Documents</Typography>
        </Grid>
        <Grid item xs={12}>
          <DataGrid
            columns={columns}
            rows={rows}
            frameworkComponents={frameworkComponents}
            domLayout="autoHeight"
          />
        </Grid>
        <FileUploadModal
          open={!!uploadDocument}
          title={`Upload ${uploadDocument?.documentType}`}
          onClose={() => setUploadDocument(null)}
          onUpload={files =>
            insuranceApprovalFormStore.addFiles(
              uploadDocument.quote,
              uploadDocument.documentType,
              files
            )
          }
          multiple={true}
        />
      </Grid>
    );
  }
);
