import React, { useEffect } from 'react';
import {
  FormControl,
  Tooltip,
  FormHelperText,
  OutlinedInput,

} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Help } from '@material-ui/icons';
import { FieldLabel } from '../fieldLabel/fieldLabel';
import { TestDataAttribute } from '../testDataAttribute';
import { TextField } from '../textField/textField';

export interface AutocompleteAddressFieldProps {
  label?: string;
  name?: string;
  value?: string | null;
  onChange?: (name: string, value: number | string) => void;
  disabled?: boolean;
  required?: boolean;
  readOnly?: boolean;
  error?: boolean;
  errorText?: string;
  fullWidth?: boolean;
  margin?: any;
  inputClassname?: string;
  standaloneLabel?: boolean;
  testId: string;
  textField?: boolean;
  tooltip?: string;
  showToolTip?: boolean;
  InputLabelProps?: any;
  InputProps?: any;
  compTool?: boolean;
  autoFocus?: boolean;
}

const defaultTooltip =
  'Please enter the address in the below format: \nStreetNumber Street Name, City, State Abbreviation, Zipcode\n\nFor e.g.\n12345 Happy Avenue, New York City, NY 10001';

export const AutocompleteAddressField: React.FC<AutocompleteAddressFieldProps> = ({
  textField,
  label,
  name,
  value,
  onChange,
  disabled,
  required,
  readOnly,
  error,
  errorText,
  fullWidth,
  margin,
  inputClassname,
  standaloneLabel,
  testId,
  tooltip,
  showToolTip,
  InputLabelProps,
  InputProps,
  compTool = false,
  autoFocus = false,
}) => {
  useEffect(() => {
    initAutocomplete();
  }, []);

  const textFieldId = testId + 'ac-text-field';

  let autocomplete;

  const initAutocomplete = () => {
    const { maps } = (window as any).google;
    autocomplete = new maps.places.Autocomplete(document.getElementById(textFieldId), {
      types: ['address'],
    });

    autocomplete.setFields([
      'address_component',
      'formatted_address',
      'geometry',
    ]);
    autocomplete.addListener('place_changed', handlePlaceSelect);
  };

  const handlePlaceSelect = () => {
    const addressObject = autocomplete.getPlace();
    const address = addressObject.address_components;
    if (address) {
      handleChange({
        target: {
          value: addressObject.formatted_address,
          fullInfo: addressObject,
        },
      });
    }
  };

  const handleChange = event => {
    onChange(event, name);
  };

  return compTool ? (
    <TestDataAttribute id={testId}>
      <FormControl fullWidth={fullWidth} disabled={disabled} error={error}>
        {label && !InputLabelProps && (
          <FieldLabel showToolTip={showToolTip ?? true} tooltip={tooltip ?? defaultTooltip}>
            {required ? label + ' *' : label}
          </FieldLabel>
        )}
        <TextField
          id={textFieldId}
          name={name}
          value={value}
          label={InputLabelProps ? label : ''}
          onChange={handleChange}
          inputProps={{ maxLength: '200' }}
          disabled={readOnly}
          className={inputClassname}
          margin={margin}
          required={required}
          variant="outlined"
          testId={testId}
          InputLabelProps={InputLabelProps}
          InputProps={InputProps}
          standaloneLabel={standaloneLabel}
          autoFocus={autoFocus}
        />
        <FormHelperText>{errorText}</FormHelperText>
      </FormControl>
    </TestDataAttribute>
  ) : !textField ? (
    <TestDataAttribute id={testId}>
      <FormControl fullWidth={fullWidth} disabled={disabled} error={error}>
        {(label && standaloneLabel) && (
          <FieldLabel showToolTip={showToolTip ?? true} tooltip={tooltip ?? defaultTooltip}>
            {required ? label + ' *' : label}
          </FieldLabel>
        )}
        <TextField
          id={textFieldId}
          name={name}
          label={standaloneLabel ? null : label}
          value={value}
          onChange={handleChange}
          inputProps={{ maxLength: '200' }}
          className={inputClassname}
          standaloneLabel={standaloneLabel}
          margin={margin}
          error={error}
          required={required}
          variant="outlined"
          testId={testId}
          disabled={readOnly}
          autoFocus={autoFocus}
        />
        <FormHelperText>{errorText}</FormHelperText>
      </FormControl>
    </TestDataAttribute>
  ) : (
    <TextField
      id={textFieldId}
      label={label}
      name={name}
      value={value}
      onChange={handleChange}
      disabled={disabled}
      required={required}
      error={error}
      helperText={errorText}
      fullWidth={fullWidth}
      margin={margin}
      standaloneLabel={standaloneLabel}
      testId={testId}
      variant="outlined"
      autoFocus={autoFocus}
    />
  );
};
