import { useParams } from "react-router";
import { EncodedParams, TamariskPayments } from "./tamariskPaymentModule"
import { DecodedData, decodeData } from "./tamariskPaymentsUtils";
import { observer } from "mobx-react";
import { useEffect } from "react";
import CancelledOrderLink from "./cancelledOrderLink";
import { useTamariskPaymentsStore } from "./hooks/useTamariskPaymentsStore";

export const TamariskAppraisalOrderPaymentContainerPublic = observer(() => {
  const { encodedParams } = useParams<EncodedParams>();
  const entityData = decodeData(encodedParams);
  return <TamariskAppraisalOrderPaymentContainer entityData={entityData} />;
});

export const TamariskAppraisalOrderPaymentContainer = observer(({ entityData }: { entityData: DecodedData }) => {
  const { tamariskPaymentsStore } = useTamariskPaymentsStore();
  const tamariskDocumentStore = tamariskPaymentsStore.tamariskDocumentStore;

  useEffect(() => {
    if (entityData?.entityId) {
      tamariskDocumentStore.checkIfValidLink(entityData.entityId, entityData.tamariskFlowType)
    }
  }, [entityData]);

  if (typeof tamariskDocumentStore.isLinkValid == 'undefined' || !entityData?.entityId) {
    return <></>
  }
  return tamariskDocumentStore.isLinkValid ? <TamariskPayments entityData={entityData} /> : <CancelledOrderLink />;
})