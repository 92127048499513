import { Service } from '@roc/feature-app-core';

const url = {
  GET_BORROWER_PRE_SCREEN_INFO: '/api/v1/loan/borrowerSetup/getBorrowerPrescreenInfo',
  GET_BORROWER_DETAILS_VERIFICATION: '/api/v1/loan/borrowerSetup/getBorrowerDetailsVerification',
  GENERATE_AU10TIX_ID_VERIFICATION: '/api/v1/loan/borrowerSetup/generateAu10tixIdVerification',
  GET_BORROWER_SETUP_LINK: '/api/v1/loan/borrowerSetup/getBorrowerSetupLink',
  SEND_ID_VERIFICATION_EMAIL_REMINDER: '/api/v1/loan/borrowerSetup/sendIdVerificationEmailReminder',
  SUBMIT_CREDIT_BACKGROUND_REQUEST: '/api/v1/loan/borrowerSetup/submitCreditBackgroundRequest',
  SAVE_BORROWER_SETUP_OPTIONS: '/api/v1/loan/borrowerSetup/saveBorrowerSetupOptions',
  SAVE_PERSONAL_INFORMATION: '/api/v1/loan/borrowerSetup/savePersonalInformation',
  SAVE_ID_INFORMATION: '/api/v1/loan/borrowerSetup/saveIdInformation',
  SAVE_FINANCIAL_INFORMATION: '/api/v1/loan/borrowerSetup/saveFinancialInformation',
  SAVE_SOME_PERSONAL_FINANCIAL_INFORMATION: '/api/v1/loan/borrowerSetup/saveSomePersonalFinancialInformation',
  SAVE_CREDIT_INFORMATION: '/api/v1/loan/borrowerSetup/saveCreditInformation',
  DOWNLOAD_DRIVERS_LICENSE: '/api/v1/loan/borrowerSetup/downloadDriversLicense',
  CONFIRM_DOCUSIGN: '/api/v1/loan/borrowerSetup/confirmDocusign',
  ACCEPT_ID_INFORMATION_MANUAL: '/api/v1/loan/borrowerSetup/acceptIdInformationManual',
  GET_ID_INFORMATION_BORROWER_DATA_COLLECTION: '/api/v1/loan/borrowerSetup/getIdInformationBorrowerDataCollection',
  SAVE_BORROWER_IDENTITY_VERIFICATION_ID: '/api/v1/loan/borrowerSetup/saveBorrowerIdentityVerificationId',
  GET_BORROWER_IDV_IDENTITY_RESULT_BY_ID: '/api/v1/user/borrower-idv/getResultById',
  DOWNLOAD_ID_INFORMATION_WITH_ID_VERIFICATION_DOCUMENTS: '/api/v1/loan/borrowerSetup/downloadIDInformationWithIdVerificationDocuments',
};

export class BorrowerSetupToDoService extends Service {

  getBorrowerPrescreenInfo(borrowerId: number, loanId: number) {
    return this.get(`${url.GET_BORROWER_PRE_SCREEN_INFO}`, { borrowerId, loanId });
  }

  getBorrowerDetailsVerification(borrowerId: number, loanId: number, refreshEmbeddedDocusignLink: boolean) {
    return this.get(url.GET_BORROWER_DETAILS_VERIFICATION, { borrowerId, loanId, refreshEmbeddedDocusignLink });
  }

  generateAu10tixIdVerification(borrowerId: number) {
    return this.get(url.GENERATE_AU10TIX_ID_VERIFICATION, { borrowerId });
  }

  getBorrowerSetupLink(creditBackgroundRequestId: number) {
    return this.get(url.GET_BORROWER_SETUP_LINK, { creditBackgroundRequestId });
  }

  sendIdVerificationEmailReminder(requestToken: string) {
    return this.get(url.SEND_ID_VERIFICATION_EMAIL_REMINDER, { requestToken });
  }

  submitCreditBackgroundRequest(request: any) {
    return this.post(url.SUBMIT_CREDIT_BACKGROUND_REQUEST, request);
  }

  saveBorrowerSetupOptions(borrowerId: number, sendIdVerification: boolean, runCreditOnCreation: boolean, loanId: number, borrowerEntityId: number) {
    let urlWithParams = `${url.SAVE_BORROWER_SETUP_OPTIONS}?borrowerId=${borrowerId}&sendIdVerification=${sendIdVerification}&runCreditOnCreation=${runCreditOnCreation}`;
    urlWithParams = loanId ? `${urlWithParams}&loanId=${loanId}` : urlWithParams;
    urlWithParams = borrowerEntityId ? `${urlWithParams}&borrowerEntityId=${borrowerEntityId}` : urlWithParams;
    return this.post(urlWithParams, null);
  }

  savePersonalInformation(loanId: number, data: any) {
    const urlWithParams = loanId ? `${url.SAVE_PERSONAL_INFORMATION}?loanId=${loanId}` : url.SAVE_PERSONAL_INFORMATION;
    return this.post(urlWithParams, data);
  }

  saveIdInformation(data: any, driversLicense: File) {
    const borrowerDetailsForm = new Blob([JSON.stringify(data)], {
      type: 'application/json',
    });

    const formData = new FormData();
    formData.append('borrowerDetailsForm', borrowerDetailsForm);
    formData.append('driversLicense', driversLicense);

    return this.post(url.SAVE_ID_INFORMATION, formData);
  }

  saveFinancialInformation(data: any) {
    return this.post(url.SAVE_FINANCIAL_INFORMATION, data);
  }

  saveSomePersonalFinancialInformation(loanId: number, data: any) {
    const urlWithParams = loanId ? `${url.SAVE_SOME_PERSONAL_FINANCIAL_INFORMATION}?loanId=${loanId}` : url.SAVE_SOME_PERSONAL_FINANCIAL_INFORMATION;
    return this.post(urlWithParams, data);
  }

  saveCreditInformation(borrowerId: number, creditReportFileId: number, creditReport: File, loanId: number) {
    const formData = new FormData();
    formData.append('creditReport', creditReport);
    let urlWithParams = `${url.SAVE_CREDIT_INFORMATION}?borrowerId=${borrowerId}&creditReportFileId=${creditReportFileId}`
    urlWithParams = loanId ? `${urlWithParams}&loanId=${loanId}` : urlWithParams;
    return this.post(urlWithParams, formData);
  }

  downloadDriversLicense(requestId) {
    return this.get(url.DOWNLOAD_DRIVERS_LICENSE, { requestId }, { responseType: 'blob' });
  }

  confirmDocusign(token) {
    return this.post(`${url.CONFIRM_DOCUSIGN}?token=${token}`, null);
  }

  acceptIdInformationManual(data: any) {
    return this.post(url.ACCEPT_ID_INFORMATION_MANUAL, data);
  }

  getIdInformationBorrowerDataCollection(borrowerId: number) {
    return this.get(`${url.GET_ID_INFORMATION_BORROWER_DATA_COLLECTION}`, { borrowerId });
  }

  saveBorrowerIdentityVerificationId(token, borrowerIdentityVerificationId) {
    const borrowerIdentityVerificationIdUrlParam = borrowerIdentityVerificationId ? `&borrowerIdentityVerificationId=${borrowerIdentityVerificationId}` : '';
    return this.post(`${url.SAVE_BORROWER_IDENTITY_VERIFICATION_ID}?token=${token}${borrowerIdentityVerificationIdUrlParam}`, null);
  }

  getBorrowerIdvIdentityResultById(borrowerIdentityVerificationId: number, includeResultsJson = false) {
    return this.get(`${url.GET_BORROWER_IDV_IDENTITY_RESULT_BY_ID}`, { borrowerIdentityVerificationId, includeResultsJson });
  }

  downloadIDInformationWithIdVerificationDocuments(borrowerIdentityVerificationId: number) {
    return this.get(`${url.DOWNLOAD_ID_INFORMATION_WITH_ID_VERIFICATION_DOCUMENTS}`, { borrowerIdentityVerificationId });
  }

}