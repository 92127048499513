import { Service } from '@roc/feature-app-core';

const url = {
  GET_BANK_STATEMENT_DATA: '/api/v1//document/formDocument/getBankStatementData',
  UPDATE_BANK_STATEMENT_DATA: '/api/v1//document/formDocument/updateBankStatementData',
};

export class BankStatementService extends Service {

  getBankStatementData = (loanId, documentId) => {
    return this.get(url.GET_BANK_STATEMENT_DATA, { loanId, documentId });
  };

  updateBankStatementData = (data) => {
    return this.put(url.UPDATE_BANK_STATEMENT_DATA, data);
  };
}