import { FormStore } from '@roc/feature-app-core';
import { flow, makeObservable } from 'mobx';
import { FileUpload } from '@roc/ui';
import { LoanForeclosureService } from '../services/loanForeclosureService';
import { GlobalStore } from '@roc/feature-app-core';

const REQUIRED_FIELD = 'This field is required.';

const form = {
  fields: {
    invoice: {
      value: '',
      error: null,
      rule: 'required',
      message: REQUIRED_FIELD
    },
    fee: {
      value: '',
      error: null,
      rule: 'required',
      message: REQUIRED_FIELD
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export class ForeclosureInvoiceFormStore extends FormStore {
  loanForeclosureService: LoanForeclosureService;
  globalStore: GlobalStore;
  constructor(globalStore: GlobalStore, loanForeclosureService: LoanForeclosureService) {
    super({ ...form }, globalStore);
    this.loanForeclosureService = loanForeclosureService;
    this.globalStore = globalStore;

    makeObservable(this, {
      uploadForeclosureInvoice: flow,
    });
  }

  *uploadForeclosureInvoice(
    loanId: string,
    fileUpload: FileUpload,
    typeOfFee: string,
  ) {
    try {
      const file = fileUpload.file;
      const blob = file.slice(0, file.size, file.type);
      const newFile = new File([blob], fileUpload.name, { type: file.type });
      yield this.loanForeclosureService.addForeclosureInvoice(
        loanId,
        this.form.fields.invoice.value,
        this.form.fields.fee.value,
        newFile,
        typeOfFee
      );
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Legal invoice was uploaded successfully.',
      });
    } catch (error) {
      console.log(error);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while uploading legal invoice.',
      });
    }
  };

  reset = () => {
    super.reset();
  };
}