import { action, computed, flow, makeObservable, observable } from 'mobx';
import { GlobalStore, UserStore } from '@roc/feature-app-core';
import { LoanStore } from '@roc/feature-loans';
import { FormStore } from '@roc/feature-app-core';
import { Document } from '@roc/feature-types';
import { TitleCompanyBankInformationService } from '../../../services/documentForms/titleCompanyBankInformationService';
import { parseAddress } from '@roc/feature-utils';
import { DocumentStore } from '../../documentStore';

const BANK_INFO_ATTORNEY_TITLE_ENTITY_TYPE = 'AttorneyTitleEntity';
const GERACI_LAW_FIRM = 'Geraci Law Firm';

const form = {
  fields: {
    entityName: {
      value: '',
      error: null,
      rule: 'required',
      attribute: 'title company name',
    },
    bankName: {
      value: '',
      error: null,
      rule: 'required',
      attribute: 'bank name',
    },
    routingNumber: {
      value: '',
      error: null,
      rule: 'required|regex:/\\d{9}/',
      attribute: 'routing number',
    },
    accountName: {
      value: '',
      error: null,
      rule: 'required',
      attribute: 'account name',
    },
    accountNumber: {
      value: '',
      error: null,
      rule: 'required',
      attribute: 'account number',
    },
    referenceId: {
      value: '',
      error: null,
      rule: '',
      attribute: 'reference ID',
    },
    address: {
      value: '',
      error: null,
      rule: 'required',
      attribute: 'address',
    },
    city: {
      value: '',
      error: null,
      rule: '',
    },
    zipCode: {
      value: '',
      error: null,
      rule: '',
    },
    state: {
      value: '',
      error: null,
      rule: '',
    },
    streetName: {
      value: '',
      error: null,
      rule: '',
    },
    ffcBankName: {
      value: '',
      error: null,
      rule: '',
    },
    ffcRoutingNumber: {
      value: '',
      error: null,
      rule: '',
    },
    ffcAccountName: {
      value: '',
      error: null,
      rule: '',
    },
    ffcAccountNumber: {
      value: '',
      error: null,
      rule: '',
    },
  },
  meta: {
    isValid: true,
    error: null,
  },
};

export class TitleCompanyBankInformationStore extends FormStore {
  loanStore: LoanStore;
  documentStore: DocumentStore;
  globalStore: GlobalStore;
  titleCompanyBankInformationService: TitleCompanyBankInformationService;

  bankInfo;

  constructor(
    loanStore: LoanStore,
    documentStore: DocumentStore,
    globalStore: GlobalStore
  ) {
    super(form, globalStore);
    this.loanStore = loanStore;
    this.documentStore = documentStore;
    this.globalStore = globalStore;
    this.titleCompanyBankInformationService = new TitleCompanyBankInformationService();
    makeObservable(this, {
      bankInfo: observable,
      canEdit: computed,
      canSubmit: computed,
      isGeraci: computed,
      resetStore: action,
      initialize: flow,
      submit: flow,
      updateTitleCompanyBankInfo: flow,
      initializeForLender: flow,
    });
  }

  *initialize() {
    try {
      this.resetStore();
      const { loanId } = this.loanStore.loanDetails;
      const response = yield this.titleCompanyBankInformationService.getTitleCompanyBankInfo(
        loanId
      );
      this.bankInfo = response.data.data || {};
      this.loadForm(this.bankInfo);
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'There was an error trying to load the bank info data',
      });
    }
  }

  *initializeForLender() {
    try {
      this.resetStore();
      const companyId = this.loanStore.userStore.userInformation.companyId ?? this.globalStore.selectedCompanyId;
      const response = yield this.titleCompanyBankInformationService.getTitleCompanyBankInfoForLender(
        companyId
      );
      this.bankInfo = response.data || {};
      this.loadForm(this.bankInfo);
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'There was an error trying to load the bank info data',
      });
    }
  }

  resetStore() {
    this.bankInfo = null;
    this.reset();
  }

  *submit() {
    this.runFormValidationWithMessage();
    if (this.canSubmit && this.form.meta.isValid) {
      try {
        const { loanId } = this.loanStore.loanDetails;
        const data = this.getPayload();
        yield this.titleCompanyBankInformationService.updateTitleCompanyBankInfo(
          loanId,
          data
        );
        this.globalStore.notificationStore.showSuccessNotification({
          message: 'Request made successfuly',
        });
        yield this.documentStore.documentFormStore.closeCurrentForm();
        yield this.loanStore.refreshLoanDetails();
        yield this.documentStore.refetchDocuments();
      } catch (error) {
        this.globalStore.notificationStore.showErrorNotification({
          message: 'There was an error trying to make the request',
        });
      }
    }
  }

  *updateTitleCompanyBankInfo() {
    this.runFormValidationWithMessage();
    try {
      const data = this.getPayload();
      const companyId = this.loanStore.userStore.userInformation.companyId ?? this.globalStore.selectedCompanyId;
      yield this.titleCompanyBankInformationService.updateTitleCompanyBankInfoLenderEntity(
        companyId,
        data
      );
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Request made successfuly',
      });
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'There was an error trying to make the request',
      });
    }
  }

  private getPayload() {
    return {
      ...this.bankInfo,
      ...this.getFormValues(),
    };
  }

  handleAddressChange(address: string, addressDetails, geometryDetails) {
    const parsedAddress = parseAddress(
      address,
      addressDetails,
      geometryDetails
    );

    this.onFieldChange('city', parsedAddress.city);
    this.onFieldChange('zipCode', parsedAddress.zip);
    this.onFieldChange('state', parsedAddress.state);
    this.onFieldChange(
      'streetName',
      `${parsedAddress?.street_number} ${parsedAddress?.street}`
    );
  }

  get isGeraci() {
    const { entityType, entityName } = this.bankInfo || {};
    return (
      entityType === BANK_INFO_ATTORNEY_TITLE_ENTITY_TYPE &&
      entityName === GERACI_LAW_FIRM
    );
  }
  get canEdit() {
    return this.globalStore.userFeatures.isClosingAttorney;
  }

  get canSubmit() {
    return this.canEdit && this.form.meta.isValid;
  }
}
