import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { StandardDialog, Button, DateField } from '@roc/ui';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Box } from '@material-ui/core';
import { isValid, format } from 'date-fns';
import ForeclosureRequestStore from '../stores/foreclosureRequestStore';

const useStyles = makeStyles(() => ({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginLeft: 24,
  },
}));

interface ForeclosureDetatilsDialogProps {
  open: boolean;
  onClose: () => void;
  store: ForeclosureRequestStore;
}

export const LoanForeclosureDetailsDialog = observer(
  ({ open, onClose, store }: ForeclosureDetatilsDialogProps) => {
    const classes = useStyles();
    const { foreclosureDetailsFormStore } = store;
    const { form } = foreclosureDetailsFormStore;

    const onSubmit = async () => {
      store.updateForeclosureDetails();
      onClose();
    };

    const manageDate = date => {
      const selectedDate = isValid(date) ? format(date, 'MM/dd/yyyy') : null;
      foreclosureDetailsFormStore.onFieldChange('settlementDate', selectedDate);
    };

    useEffect(() => {
      return () => {
        foreclosureDetailsFormStore.reset();
      };
    }, []);

    const getContent = () => {
      return (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <DateField
              testId="settlementDate"
              inputVariant="outlined"
              format="MM/dd/yyyy"
              standaloneLabel
              label={"Expected Settlement Date"}
              value={form.fields.settlementDate.value}
              onChange={date => manageDate(date)}
              fullWidth
            />
          </Grid>
          <Grid item container className={classes.buttonContainer}>
            <Box mr={2}>
              <Button
                className={classes.button}
                size="small"
                variant="contained"
                color="secondary"
                onClick={onClose}
                testId="cancel"
              >
                CANCEL
              </Button>
              <Button
                disabled={false}
                className={classes.button}
                size="small"
                variant="contained"
                color="primary"
                onClick={onSubmit}
                testId="save"
              >
                SUBMIT
              </Button>
            </Box>
          </Grid>
        </Grid>
      );
    };

    return (
      <StandardDialog
        open={open}
        title="Loan Foreclosure Details"
        maxWidth="sm"
        handleClose={onClose}
        dialogContent={
          getContent()
        }
      />
    );
  }
);
