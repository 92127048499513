import { useState } from 'react';
import { ExpandMore } from '@material-ui/icons';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
} from '@material-ui/core';
import { TitleDivider } from '../../../components/titleDivider';
import { brokerTypeOptions, createRadioField } from '@roc/ui';
import { observer } from 'mobx-react';
import { QuoteTypeStore } from '../../../stores/quoteTypeStore';
import { yesNoOptionsBoolean } from '@roc/feature-utils';
import { PercentagePicker } from '../../leverage/components/leverageComponents';
import { useBaseStore } from '@roc/feature-app-core';

interface BrokerAccordionProps {
  store: QuoteTypeStore;
}

export const BrokerAccordion = observer(
  ({ store }: BrokerAccordionProps) => {
    const { brokerStore } = store;
    const { globalStore } = useBaseStore();
    const [expanded, setExpanded] = useState(true);

    const hasBroker =
      brokerStore.brokerFormStore.form.fields.hasBroker.value === true;

    return (
      <Accordion
        expanded={expanded}
        onChange={(e, expanded) => setExpanded(expanded)}
        elevation={0}
      >
        <AccordionSummary
          expandIcon={<ExpandMore fontSize="large" />}
          aria-controls="broker-accordion"
        >
          <TitleDivider title="Broker" />
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4} sm={6}>
              {createRadioField({
                label: 'Is there a broker involved in this loan?',
                fieldName: 'hasBroker',
                testId: 'hasBroker',
                options: yesNoOptionsBoolean,
                store: brokerStore.brokerFormStore,
                row: true,
                standaloneLabel: false,
              })}
            </Grid>
            {hasBroker &&
              <>
                {globalStore?.lenderInfo?.isInternal &&
                  <Grid item xs={12} md={4} sm={6}>
                    {createRadioField({
                      label: 'How do you want the broker to be paid?',
                      fieldName: 'brokerType',
                      testId: 'brokerType',
                      store: brokerStore.brokerFormStore,
                      options: brokerTypeOptions,
                      row: true,
                      standaloneLabel: false,
                    })}
                  </Grid>
                }
                <Grid item xs={12} md={4} sm={6}>
                  <PercentagePicker
                    fieldName={'brokerPoints'}
                    label="Broker Points *"
                    value={brokerStore.brokerFormStore.form.fields.brokerPoints.value}
                    min={0}
                    max={100}
                    onChange={value =>
                      brokerStore.brokerFormStore.onFieldChange('brokerPoints', +value)
                    }
                  />
                </Grid>
              </>
            }
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  }
);
