export interface VerifyEmailProps {
  onSubmit: () => void,
  fullWidthButton?: boolean,
};

export enum PasswordChangeType {
  CREATE,
  CREATE_MIGRATED_USER,
  UPDATE,
}

export interface CreatePasswordFormProps {
  passwordChangeType?: PasswordChangeType,
  handleFormSubmit: () => void
  submitButtonText?: string
  fullWidthButton?: boolean
}