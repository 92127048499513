import { Grid, Menu, MenuItem, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowDropDown } from '@material-ui/icons';
import { AutocompleteField, Button, SelectField, StandardDialog, Toolbar } from '@roc/ui';
import { observer } from 'mobx-react';
import { useRef, useState } from 'react';

const useStyles = makeStyles(theme => ({

}));

export const BulkChangeStatusModal = observer(({ statusSelected, handleSelect, options }) => {

  return (
    <Grid container>
      <Grid item xs={12}>
        <SelectField
          options={options}
          standaloneLabel
          label='Please select the new status for the transactions'
          testId={'foundation'}
          value={statusSelected}
          onChange={(value) => {
            handleSelect(value);
          }}
          fullWidth
          variant="outlined"
        // className={classes.selectField}
        />
      </Grid>
    </Grid>
  );
});
