import { Route } from 'react-router';
import { Page } from '@roc/feature-app-core';
import { PaidOffInterestAllocationAccordion } from '@roc/feature-loans';
import { useRocInternalPortalStore } from '../../hooks';

export const getInterestAllocationInternalRoutesConfig = (
  basePath: string,
  baseUrl: string
) => {
  return {
    foreclosureOverview: {
      path: `${basePath}/interestAllocation/:date?`,
      url: `${baseUrl}/interestAllocation`,
      documentTitle: 'Interest Allocation',
    },
  };
};

export const getInterestAllocationInternalRoutes = (
  basePath: string,
  baseUrl: string
) => {
  const config = getInterestAllocationInternalRoutesConfig(basePath, baseUrl);
  const { interestAllocationInternalStore } = useRocInternalPortalStore();
  return {
    interestAllocation: (
      <Route exact path={config.foreclosureOverview.path}>
        <Page routeDefinition={config.foreclosureOverview}>
          <PaidOffInterestAllocationAccordion store={interestAllocationInternalStore} />
        </Page>
      </Route>
    ),
  };
};
