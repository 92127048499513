import { useEffect } from 'react';
import { Box, Grid, List, ListItem, Typography, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { Button, Paper } from '@roc/ui';
import { observer } from 'mobx-react';
import { Alert, AlertTitle } from '@material-ui/lab';
import { LOAN_FASTRACK } from '../constants/loanFastrackPayments';
import { useLoanFastrackPaymentsStore } from '../hooks/useLoanFastrackPaymentsStore';
import CustomListItem from './customListItem';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      borderRadius: theme.spacing(2),
      padding: theme.spacing(3, 0),
      boxShadow:
        'rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px',
    },
    textBold: {
      fontWeight: 700,
    },
    textItalic: {
      fontStyle: 'italic',
    },
    totalCost: {
      marginTop: '20Px',
      display: 'flex',
      justifyContent: 'space-between'
    },
    description: {
      fontWeight: 'bold',
      marginRight: '8px',
    },
    filling: {
      flexGrow: 1,
      height: '19px',
      backgroundImage:
        'linear-gradient(to right, #313131 40%, rgba(255, 255, 255, 0) 20%)',
      backgroundPosition: 'bottom',
      backgroundSize: '3px 1px',
      backgroundRepeat: 'repeat-x',
    },
    price: {
      fontWeight: 'bold',
      marginLeft: '8px',
    },
    itemContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },

    totalText: {
      fontWeight: 'bold',
    },
    divider: {
      marginTop: '12px',
      marginBottom: '12px',
    },
    textContainer: {
      display: 'flex',
      flexDirection: 'row',
      backgroundColor: '#fdefbf',
      padding: '4px',
    },
  })
);

interface Props {
  handleNextStep: () => void;
  entityId: string;
  tamariskFlowType: string;
}

const LoanFastrackPaymentInformation = observer((props: Props) => {
  const { loanFastrackPaymentsStore } = useLoanFastrackPaymentsStore();
  const loanFastrackDocumentStore =
    loanFastrackPaymentsStore.loanFastrackDocumentStore;
  const { loading, form } = loanFastrackDocumentStore;
  const { paper, textBold, totalCost } = useStyles();
  const formatCurrency = (number, digits = 2) =>
    new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: digits,
    }).format(number);
  const { feeComponents: feeComps, fastTrackFees = {}, dealName } = loanFastrackDocumentStore;

  const renderPaymentDetails = () => {
    return (
      <Box mt={3}>
        <Box mt={6}>
          <Paper className={paper}>
            <Box mb={1} px={2}>
              <Typography className={textBold} variant="subtitle1">
                Fast Track estimation cost based on:
              </Typography>
            </Box>
            <List>
              <CustomListItem
                key="Fast Track Fee per property"
                label="Fast Track Fee per property"
                value={`$${fastTrackFees.fastTrackFee}`}
              />
            </List>
          </Paper>
          <Box className={totalCost} mb={1} px={2}>
            <Typography variant="subtitle1">* All payments made in favor of Fast Track are non-refundable.</Typography>
            <Typography variant="subtitle1" align="right">
              {`Total Fee: ${formatCurrency(loanFastrackDocumentStore.totalFee)}`}
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  };

  const renderErrorMessage = () => {
    return (
      <Grid item xs={12}>
        <Alert severity="error">
          <AlertTitle>Warning</AlertTitle>
          {`${loanFastrackDocumentStore.feeCalcError}`}
        </Alert>
      </Grid>
    );
  };

  const renderLoading = () => {
    return (
      <Grid item xs={12}>
        <Typography variant="subtitle1">Loading...</Typography>
      </Grid>
    );
  };

  useEffect(() => {
    switch (props.tamariskFlowType) {
      case LOAN_FASTRACK:
        loanFastrackDocumentStore.fetchLoanFastrackInfo(
          props.entityId,
          form.fields.fullName.value
        );
        break;
      default:
        break;
    }
  }, []);

  return (
    <>
      <Box>
        <Box mb={2}>
          <Typography variant="h4">{dealName}</Typography>
        </Box>
      </Box>
      {loading
        ? renderLoading()
        : loanFastrackDocumentStore.feeCalcError
          ? renderErrorMessage()
          : renderPaymentDetails()}
      <Box mt={16}>
        <Grid container justifyContent="center" spacing={2}>
          <Grid item xs={6} md={3}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={props.handleNextStep}
              testId="next"
              disabled={
                loanFastrackDocumentStore.feeCalcError || loading ? true : false
              }
            >
              Next
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
});

export default LoanFastrackPaymentInformation;
