import { FormStore } from '@roc/feature-app-core';
import { GlobalStore } from '@roc/feature-app-core';
import { makeObservable } from 'mobx';
import { OneToolService } from '../../services/oneToolService';

const entityForm = {
  fields: {
    entityId: {
      value: '',
      error: null,
      rule: '',
    },
    entityName: {
      value: '',
      error: null,
      rule: 'required',
      message: 'Required',
    },
    entityEin: {
      value: '',
      error: null,
      rule: 'required|regex:/^\\d{2}-?\\d{7}$/|alpha_dash',
      message: 'EIN must be a number with the format xx-xxxxxxx',
    },
    entityType: {
      value: '',
      error: null,
      rule: 'required',
      message: 'Required',
    },
    dateOfIncorporation: {
      value: null,
      error: null,
      rule: 'required',
    },
    address: {
      value: null,
      error: null,
      rule: 'required',
    },
    hasEntity: {
      value: null,
      error: null,
      rule: 'required',
    },
  },
  meta: {
    key: null,
    editMode: false,
    skipped: true,
    isValid: false,
    error: null,
  },
};

export class EntityFormStore extends FormStore {
  private globalStore: GlobalStore;
  private oneToolService: OneToolService;

  constructor(globalStore) {
    super({ ...entityForm }, globalStore);
    this.globalStore = globalStore;
    this.oneToolService = new OneToolService();

    this.init();
    makeObservable(this, {});
  }

  init() {
    this.form.meta.editMode = true;
  }

  setEditMode(value) {
    this.form.meta.editMode = value;
  }

  setSkipped(value: boolean) {
    this.form.meta.skipped = value;
  }

  setIsValid(value: boolean) {
    this.form.meta.isValid = value;
  }
}
