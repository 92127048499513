import { useEffect, useState } from 'react';

import { observer } from 'mobx-react';
import { useTwoFactorStore } from '../hooks/useTwoFactorStore';
import DisableTwoFactor from './twoFactorDisableView';
import EnableTwoFactor from './twoFactorEnableView';
import TwoFactorStatus from './twoFactorStatus';
import { TwoFactorStatusType, TwoFactorType } from '../types';
import { Box, CircularProgress } from '@material-ui/core';
import clsx from 'clsx';

export const SCREEN = {
  LOADING: 'LOADING',
  HOME: 'HOME',
  ENABLE: 'ENABLE',
  DISABLE: 'DISABLE'
};

const renderScreen = (enabled, setEnabled, screenName, setScreenName) => {
  const handleOnEnable = () => {
    setScreenName(SCREEN.ENABLE);
  }
  const handleOnDisable = () => {
    setScreenName(SCREEN.DISABLE);
  }
  const handleOnSuccessEnable = () => {
    setEnabled(true);
    setScreenName(SCREEN.HOME);
  }
  const handleOnSuccessDisable = () => {
    setEnabled(false);
    setScreenName(SCREEN.HOME);
  }
  const handleOnCancel = () => {
    setScreenName(SCREEN.HOME);
  }
  switch (screenName) {
    case SCREEN.LOADING:
      return <Box textAlign={'center'} border={'1px solid #e1e1e1'} borderRadius={'12px'} height={'100%'} p={2}>
        <Box mb={4}>
          Loading
        </Box>
        <CircularProgress color='primary' size={24} />
      </Box>;
    case SCREEN.HOME:
      return <TwoFactorStatus isEnabled={enabled} canDisable={true} onEnable={handleOnEnable} onDisable={handleOnDisable} />
    case SCREEN.ENABLE:
      return <EnableTwoFactor twoFactorType={TwoFactorType.LOGIN} onSuccess={handleOnSuccessEnable} onCancel={handleOnCancel} />
    case SCREEN.DISABLE:
      return <DisableTwoFactor twoFactorType={TwoFactorType.LOGIN} onSuccess={handleOnSuccessDisable} onCancel={handleOnCancel} />
    default:
      return <></>;
  }
}

export const TwoFactor = observer(({ className }: {
  className?: string;
}) => {
  const [screenName, setScreenName] = useState(SCREEN.LOADING);
  const [enabled, setEnabled] = useState<boolean>();
  const { twoFactorStore } = useTwoFactorStore();

  useEffect(() => {
    twoFactorStore.getTwoFactorInfo('LOGIN').then(x => {
      setEnabled(x.twoFactorStatus == TwoFactorStatusType.ACTIVE);
      setScreenName(SCREEN.HOME);
    }).catch(() => {
      setScreenName(undefined);
    });
  }, []);

  return (
    <div className={clsx("xx-container", className)}>
      {
        renderScreen(enabled, setEnabled, screenName, setScreenName)
      }
    </div>
  );
});