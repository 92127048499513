import { Service } from '@roc/feature-app-core';

const url = {
  GET_BORROWER_SURVEY: '/api/v1/document/formDocument/getBorrowerSurvey',
  UPDATE_BORROWER_SURVEY:
    '/api/v1/document/formDocument/updateLoanDocumentBorrowerSurvey',
};

export class BorrowerSurveyService extends Service {
  getBorrowerSurvey = borrowerId => {
    return this.get(url.GET_BORROWER_SURVEY, { borrowerId });
  };

  updateLoanDocumentBorrowerSurvey = payload => {
    return this.put(url.UPDATE_BORROWER_SURVEY, payload);
  };
}
