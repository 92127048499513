import { Service } from '@roc/feature-app-core';

const url = {
  GET_AVAILABLE_PROSPECT_PROPERTIES_GRID:
    '/api/v1/loan/prospectProperties/getAllProspectProperties',
  GET_MY_PROSPECT_PROPERTIES_GRID:
    '/api/v1/loan/prospectProperties/getAllCustomProperties',
  GET_PROSPECT_PROPERTY: '/api/v1/loan/prospectProperties/getPropertyDetailsByPropertyId',
  GET_PROSPECT_PROPERTY_IMAGE: '/api/v1/prospectProperty/getPropertyImage/',
  GET_PROPERTY_ZIPPED_DOCS: '/api/v1/prospectProperty/getPropertyZippedDocs/',
};

export class ProspectPropertyService extends Service {
  getAvailableProspectPropertiesGrid(
    page: number,
    pageSize: number,
    sortDir: string,
    sortBy: string,
    filters: string
  ) {
    return this.get(
      url.GET_AVAILABLE_PROSPECT_PROPERTIES_GRID,
      {
        page,
        pageSize,
        sortDir,
        sortBy,
        filters,
      },
      {
        disableGlobalLoading: true,
      }
    );
  }

  getMyProspectPropertiesGrid(
    page: number,
    pageSize: number,
    sortDir: string,
    sortBy: string,
    filters: string
  ) {
    return this.get(
      url.GET_MY_PROSPECT_PROPERTIES_GRID,
      {
        page,
        pageSize,
        sortDir,
        sortBy,
        filters,
      },
      {
        disableGlobalLoading: true,
      }
    );
  }

  getProspectProperty(id, propertyType) {
    return this.get(url.GET_PROSPECT_PROPERTY + '?id=' + id + '&propertyType=' + propertyType);
  }
}
