import { GlobalStore } from '@roc/client-portal-shared/stores';
import { action, flow, makeObservable, observable } from 'mobx';
import { FormStore } from '@roc/feature-app-core';
import {
  mapLoanToDscrCalculatorFormValues,
} from '../../dscrCalculator/utils/dscrCalculatorUtils';
import {
  CalculatedStabilizedBridgeAnalyzerValues,
  StabilizedBridgeAnalyzerFormValues,
} from '../types/stabilizedBridgeAnalyzerTypes';
import { LoanPurposeOption } from '../utils/stabilizedBridgeAnalyzerConstants';
import { mapLoanPurpose } from '../utils/stabilizedBridgeAnalyzerUtils';

const form = {
  fields: {
    loanId: {
      value: '',
      error: null,
      rule: '',
    },
    loanAmount: {
      value: '',
      error: null,
      attribute: 'Loan Amount',
    },
    rate: {
      value: '',
      error: null,
      rule: 'required',
      attribute: 'Rate',
    },
    annualTaxes: {
      value: '',
      error: null,
      rule: 'required',
      attribute: 'Annual Taxes',
    },
    annualInsurance: {
      value: '',
      error: null,
      rule: 'required',
      attribute: 'Annual Insurance',
    },
    loanPurpose: {
      value: '',
      error: null,
      rule: 'required',
      attribute: 'Loan Purpose',
    },
    propertyType: {
      value: '',
      error: null,
      rule: 'required',
      attribute: 'Property Type',
    },
    estimatedAsIsValue: {
      value: '',
      error: null,
      rule: 'required',
      attribute: 'As Is Value',
    },
    monthlyGrossRent: {
      value: '',
      error: null,
      rule: 'required',
      attribute: 'Monthly Gross Rent',
    },
    monthlyMarketRent: {
      value: '',
      error: null,
      rule: 'required',
      attribute: 'Monthly Market Rent',
    },
    annualHOA: {
      value: '',
      error: null,
      rule: 'required',
      attribute: 'Annual HOA Dues',
    },
    occupancy: {
      value: false,
      error: null,
      rule: 'required',
      attribute: 'Vacant Property',
    },
    exitRate: {
      value: '',
      error: null,
      rule: 'required',
      attribute: 'Exit Rate',
    },
    isBorrowerForeignNational: {
      value: false,
      error: null,
      rule: '',
    },
    isMultifamilyOrMixedUseProperty: {
      value: false,
      error: null,
      rule: '',
    },
    purchasePrice: {
      value: '',
      error: null,
      rule: 'required',
      attribute: 'Purchase Price',
    },
    purchaseDate: {
      value: '',
      error: null,
      rule: '',
    },
    capitalImprovements: {
      value: '',
      error: null,
      rule: '',
    },
    depositPaid: {
      value: '',
      error: null,
      rule: '',
    },
    originationFees: {
      value: '',
      error: null,
      rule: '',
    },
    payoff: {
      value: '',
      error: null,
      rule: '',
    },
    downPayment: {
      value: '',
      error: null,
      rule: '',
    },
    ficoProvidedAtOrigination: {
      value: 680,
      error: null,
      rule: 'required',
      attribute: 'Borrower Fico Score',
    },
    originationAmount: {
      value: '',
      error: null,
      rule: '',
    },
    closingCosts: {
      value: '',
      error: null,
      rule: '',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export abstract class StabilizedBridgeAnalyzerBaseStore extends FormStore {
  protected globalStore: GlobalStore;
  calculatedValues: any;

  abstract getStabilizedBridgeAnalyzerCalculatedData(data): CalculatedStabilizedBridgeAnalyzerValues;
  abstract getLoanValues(loanId: string): StabilizedBridgeAnalyzerFormValues;
  abstract getAutocompleteOptions(searchTerm: string);

  loanSearchText = '';
  loanSearchOptions: { label: string; value: string }[] = [];

  constructor(globalStore) {
    super(form, globalStore);
    this.globalStore = globalStore;

    makeObservable(this, {
      loanSearchText: observable,
      calculatedValues: observable,
      loanSearchOptions: observable,
      getStabilizedBridgeAnalyzerCalculatedData: flow,
      getAutocompleteOptions: flow,
      getLoanValues: flow,
      fetchOptions: flow,
      fetchLoanValues: flow,
      fetchStabillizedBridgeAnalyzerData: flow,
      clearCalculatedValues: action,
    });
  }

  *fetchOptions(searchText: string) {
    try {
      if (!searchText) {
        this.loanSearchOptions = [];
        return;
      }
      const options = yield this.getAutocompleteOptions(searchText);
      this.loanSearchOptions = options;
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'An error occurred while loading the list of loans.',
      });
    }
  }

  *fetchLoanValues(loanId: string) {
    try {
      const loanValues = yield this.getLoanValues(loanId);
      const values = mapLoanToDscrCalculatorFormValues(loanValues);
      this.loadForm(values);

      const loanPurpose = mapLoanPurpose(loanValues.loanPurpose);
      this.loadForm({ loanPurpose });
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'An error occurred while loading the loan data.',
      });
    }
  }

  *fetchStabillizedBridgeAnalyzerData() {
    try {
      console.log(this.form.meta)
      this.runFormValidationWithMessage();
      if (this.form.meta.isValid) {
        const loanPurpose = this.form.fields.loanPurpose.value;
        const loanAmount = this.form.fields.loanAmount.value;
        const rate = this.form.fields.rate.value;
        const annualTaxes = this.form.fields.annualTaxes.value;
        const annualInsurance = this.form.fields.annualInsurance.value;
        const purchasePrice = this.form.fields.purchasePrice.value;
        const purchaseDate = this.form.fields.purchaseDate.value;
        const capitalImprovements = this.form.fields.capitalImprovements.value;
        const propertyType = this.form.fields.propertyType.value;
        const estimatedAsIsValue = this.form.fields.estimatedAsIsValue.value;
        const monthlyGrossRent = this.form.fields.monthlyGrossRent.value;
        const monthlyMarketRent = this.form.fields.monthlyMarketRent.value;
        const annualHOA = this.form.fields.annualHOA.value;
        const occupancy = this.form.fields.occupancy.value;
        const exitRate = this.form.fields.exitRate.value;
        const ficoProvidedAtOrigination = this.form.fields.ficoProvidedAtOrigination.value;

        const data = {
          loanAmount: loanAmount,
          rate: rate,
          annualTaxes: annualTaxes,
          annualInsurance: annualInsurance,
          loanPurpose: loanPurpose,
          propertyType: propertyType,
          estimatedAsIsValue: estimatedAsIsValue,
          monthlyGrossRent: monthlyGrossRent,
          monthlyMarketRent: monthlyMarketRent,
          annualHOA: annualHOA,
          occupancy: occupancy,
          exitRate: exitRate,
          ficoProvidedAtOrigination: ficoProvidedAtOrigination,
          purchasePrice: purchasePrice,
          purchaseDate: purchaseDate,
          capitalImprovements: capitalImprovements,
        };


        const response = yield this.getStabilizedBridgeAnalyzerCalculatedData(data);
        this.calculatedValues = response.data;
      }
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'An error occurred while loading the calculated values.',
      });
    }
  }

  clearCalculatedValues() {
    this.calculatedValues = null;
  }
}
