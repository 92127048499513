import { Grid, Typography } from '@material-ui/core';
import { Build, Business } from '@material-ui/icons';
import { borrowerEntityTypeOptions } from '@roc/feature-utils';
import {
  createAutocompleteAddressField,
  createAutocompleteField,
  createDateField,
  createNumberFormatField,
  createSelectField,
  createTextField,
} from '@roc/ui/componentBuilder';
import { observer } from 'mobx-react-lite';
import { useNewLoanApplicationStore } from '../hooks/useNewLoanApplicationStore';
import { LoanApplicationCardTitle } from './loanApplicationComponents';

export const BorrowerEntityForm = observer(() => {
  const { newLoanApplicationStore } = useNewLoanApplicationStore();
  const { borrowerEntityFormStore } = newLoanApplicationStore;
  const store = borrowerEntityFormStore;

  const handleAddressChange = event => {
    const { value, fullInfo } = event?.target || {};
    const { address_components, geometry } = fullInfo || {};
    store.saveAddressField(value, address_components, geometry);
  };

  const disabled = Boolean(store.form.fields.borrowerEntityId.value);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        {createAutocompleteField({
          store,
          fieldName: 'borrowerEntityOption',
          inputFieldName: 'name',
          label: 'Entity Name',
          options: store.borrowerEntityOptions,
          clearOnBlur: false,
          onChange: option => store.setSelectedBorrowerEntity(option?.value),
        })}
      </Grid>
      <Grid item xs={12}>
        {createSelectField({
          store,
          disabled,
          testId: 'type',
          fieldName: 'type',
          label: 'Entity Type',
          options: borrowerEntityTypeOptions,
        })}
      </Grid>
      <Grid item xs={12}>
        {createNumberFormatField({
          store,
          disabled,
          testId: 'ein',
          fieldName: 'ein',
          label: 'Entity EIN',
          format: '##-#######',
        })}
      </Grid>
      <Grid item xs={12}>
        {createDateField({
          store,
          disabled,
          testId: 'operatingAgreementDate',
          fieldName: 'operatingAgreementDate',
          label: 'Date of Incorporation'
        })}
      </Grid>
      <Grid item xs={12}>
        {createAutocompleteAddressField({
          store,
          disabled,
          testId: 'address',
          fieldName: 'address',
          label: 'Entity Address',
          onChange: handleAddressChange,
        })}
      </Grid>
    </Grid>
  );
});