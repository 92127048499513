import { GlobalStore } from '@roc/feature-app-core';
import { sub } from 'date-fns';
import { observable, action, makeObservable, flow, computed } from 'mobx';
import { ScopeOfWorkV2FormBaseStore } from '.';
import {
  Category,
  CategoryDB,
  Subcategory,
  SubcategoryDB,
  SubcategoryDetailsDB,
} from '@roc/feature-sow-shared/types';

const flatten = (a, b) => a.concat(b);
const createUniqueKey = () => Symbol();
const mapByKey = (array, key) =>
  array.reduce((obj, item) => ({ ...obj, [item[key]]: item }), {});
const sum = (a, b) => parseFloat(a ?? 0) + parseFloat(b ?? 0);

/**
 * Categories to be displayed in the table
 */
export class CategoryStore {
  private globalStore: GlobalStore;
  private sowFormStore: ScopeOfWorkV2FormBaseStore;

  //Categories and subcategories from DB, mapped by their respective ids
  private categoryMetaById: Record<number, CategoryDB>;
  private subcategoryMetaById: Record<number, SubcategoryDB>;

  //Categories to be edited and displayed on the screen
  categories: Record<number, CategoryDB>;

  constructor(
    globalStore: GlobalStore,
    sowFormStore: ScopeOfWorkV2FormBaseStore
  ) {
    this.globalStore = globalStore;
    this.sowFormStore = sowFormStore;

    this.reset();

    makeObservable(this, {
      categories: observable,
      reset: action,
      loadCategories: action,
      totalCost: computed,
      sortedCategories: computed,
      selectCategoryOptions: computed,
      subcategoryOptions: computed,
      selectCategory: action,
      removeCategory: action,
      parseDataToSave: action,
      editSubcategory: action,
      deleteSubcategory: action,
      createSubcategory: action,
      updateSubcategory: action,
      cleanSubcategory: action,
      //   selectSubcategory: action,
      filterEmpty: action,
      saveComment: action,
      saveCommentDetailLineItem: action,
      updateReviewStatusSelectedItems: action,
      countItemsByReviewStatus: action,
      totalCapex: computed,
      totalRemainingReno: computed,
      remainingReno: computed,
    });
  }

  reset() {
    this.categories = null;
  }

  loadCategories(categoriesResponse: CategoryDB[]) {
    this.categoryMetaById = mapByKey(categoriesResponse, 'categoryId');
    const allSubcategories = categoriesResponse
      .map(c => c.subCategories)
      .reduce(flatten, []);
    this.subcategoryMetaById = mapByKey(allSubcategories, 'subcategoryId');

    const savedCategories =
      this.sowFormStore.scopeOfWork?.dataContent?.categoryMap ?? [];

    const mappedCategories = savedCategories.map(existingCategory =>
      this.mapCategory(
        existingCategory,
        this.categoryMetaById[existingCategory.id]
      )
    );

    this.categories = mapByKey(mappedCategories, 'categoryId');
  }

  get sortedCategories(): CategoryDB[] {
    return Object.values(this.categories).sort((a, b) => a.order - b.order);
  }

  get sortedCategoriesNotEmpty() {
    return this.sortedCategories.map(category => ({
      ...category,
      subCategories: category.subCategories
        .map(subcat => ({
          ...subcat,
          details: subcat.details.filter(d => d.cost),
        }))
        .filter(subcat => subcat.cost || subcat.details.length > 0)
    }));
  }

  private mapCategory(
    savedCategory: Category,
    categoryInfo: CategoryDB
  ): CategoryDB {
    const savedSubcategories = savedCategory?.subCategories ?? [];

    const newSubCategories: SubcategoryDB[] = savedSubcategories
      .filter(existingSub => !existingSub.id)
      .map(existingSub => {
        // Returns the subcategories that are not in the database
        return this.mapSubcategory(existingSub, categoryInfo);
      });

    const existingSubCategories: SubcategoryDB[] = categoryInfo.subCategories
      .map(subCat => {
        const savedItems = savedSubcategories.filter(
          s => subCat.subcategoryId === s.id
        );
        if (savedItems.length > 0) {
          // If it has saved items for the subcategory, returns the saved items
          return savedItems.map(saved =>
            this.mapSubcategory(saved, categoryInfo)
          );
        } else if (subCat.isDefault) {
          // If it does not have saved items, but it is default, returns an empty item
          return [this.copySubcategory(subCat)];
        } else {
          // Is not saved and is not default
          return [];
        }
      })
      .reduce(flatten, []);

    return {
      ...categoryInfo,
      subCategories: [...existingSubCategories, ...newSubCategories],
    };
  }

  private mapSubcategory(
    savedSubcategory: Subcategory,
    categoryMeta: CategoryDB
  ) {
    const subcategoryMeta = this.subcategoryMetaById[savedSubcategory.id];

    let details = [];

    if (subcategoryMeta && savedSubcategory.itemized?.length > 0) {
      const metaDetailsIDs = subcategoryMeta.details
        .filter(sub => sub.detailId)
        .map(sub => sub.detailId);

      //Checks if there are saved details that have ID and are not in DB meta (i.e. completed by the user and then deleted from DB meta)
      //Sets those details IDs to null to treat them as user-created details
      savedSubcategory.itemized?.filter(newDetailWithId => newDetailWithId.id && !metaDetailsIDs.includes(newDetailWithId.id))
        .forEach(newDetailWithId => newDetailWithId.id = null);

      const metaDetails = [];

      //Adds json details that have an ID, whether they are saved or come from metadata
      subcategoryMeta.details.map(metaDetail => {
        const savedCat = savedSubcategory.itemized.find(savedDetail => savedDetail.id === metaDetail.detailId);
        metaDetails.push(savedCat ? {
          detailId: savedCat.id,
          name: savedCat.name,
          cost: savedCat.cost,
          capex: savedCat.capex,
          description: savedCat.description,
          reviewStatus: savedCat.reviewStatus,
          comments: savedCat.comments,
        }
          : {
            name: metaDetail.name,
            detailId: metaDetail.detailId
          });
      });

      //Adds json details that don't have an ID
      const saved = savedSubcategory.itemized?.filter(item => !item.id).map(item => ({
        detailId: item.id,
        name: item.name,
        cost: item.cost,
        capex: item.capex,
        description: item.description,
        reviewStatus: item.reviewStatus,
        comments: item.comments,
      }));

      details = [...saved, ...metaDetails];

    }

    return {
      uniqueKey: createUniqueKey(),
      name: savedSubcategory.name,
      categoryId: categoryMeta.categoryId,
      subcategoryId: savedSubcategory.id,
      isDefault: subcategoryMeta?.isDefault,
      allowsMultiple: subcategoryMeta?.allowsMultiple,
      cost: savedSubcategory.general?.cost,
      description: savedSubcategory.general?.description,
      reviewStatus: savedSubcategory.general?.reviewStatus,
      comments: savedSubcategory.general?.comments,
      capex: savedSubcategory?.general?.capex,
      details: (details.length === 0 ?
        savedSubcategory.itemized?.map(item => ({
          detailId: item.id,
          name: item.name,
          cost: item.cost,
          capex: item.capex,
          description: item.description,
          reviewStatus: item.reviewStatus,
          comments: item.comments,
        })) :
        details),
    };
  }

  private copySubcategory(subcategoryInfo) {
    //Create a copy to prevent unwanted side effects when mutating the objects
    return {
      uniqueKey: createUniqueKey(),
      ...subcategoryInfo,
      details: [...subcategoryInfo.details || []],
    };
  }

  parseDataToSave() {
    return this.sortedCategories.map(category => ({
      id: category.categoryId,
      subCategories: this.filterEmpty(category.subCategories).map(
        subcategory => ({
          name: subcategory.name,
          id: subcategory.subcategoryId || null,
          general: {
            cost: subcategory.cost,
            description: subcategory.description,
            comments: subcategory.comments,
            reviewStatus: subcategory.reviewStatus,
            capex: subcategory.capex
          },
          itemized: subcategory.details.map(detail => ({
            id: detail.detailId,
            name: detail.name,
            cost: detail.cost,
            comments: detail.comments,
            reviewStatus: detail.reviewStatus,
            description: detail.description,
            capex: detail.capex
          })),
        })
      ),
    }));
  }

  get selectCategoryOptions() {
    const lastIndexFirstColumn = Math.ceil(Object.values(this.categoryMetaById).length / 2) - 1;
    return Object.values(this.categoryMetaById)
      .sort((a, b) => a.order - b.order)
      .map((c, i) => {
        if(c.name === 'LTC Catchup' && !!!this.categories[c.categoryId]){
          this.selectCategory(c);
        }
        return ({
        column: i <= lastIndexFirstColumn ? 0 : 1,
        checked: !!this.categories[c.categoryId],
        category: c,
        containsData: (this.categories[c.categoryId]?.subCategories || []).some(
          subcategory => this.subcategoryCost(subcategory) > 0
        ),
      })
      });
  }

  get subcategoryOptions() {
    let subcategoriesByCategoryId: Record<number, any[]> = [];
    Object.values(this.categoryMetaById)
      .map(c => {
        const category = this.categories[c.categoryId];
        const selected = category?.subCategories?.map(c => c.subcategoryId);

        subcategoriesByCategoryId = {
          ...subcategoriesByCategoryId,
          [c.categoryId]: []
        };

        return this.categoryMetaById[c.categoryId]?.subCategories
          .filter(s => s.allowsMultiple || !selected?.includes(s.subcategoryId))
          .forEach(subCat =>
            subcategoriesByCategoryId[c.categoryId].push({
              ...subCat,
              label: subCat.name,
              value: subCat.subcategoryId,
              subcategoryId: subCat.subcategoryId,
              subcategoryName: subCat.name,
            }));
      });

    return subcategoriesByCategoryId;
  }

  selectCategory(category: CategoryDB) {
    this.categories[category.categoryId] = this.mapCategory(null, category);
  }

  removeCategory = (category: CategoryDB) => {
    delete this.categories[category.categoryId];
  };

  editSubcategory = (subcategory: SubcategoryDB) => {
    const categoryMeta = this.categoryMetaById[subcategory.categoryId];
    let subcategoryMeta;
    if (this.subcategoryMetaById[subcategory.subcategoryId]) {

      subcategoryMeta = this.subcategoryMetaById[subcategory.subcategoryId];
    }
    else {
      subcategoryMeta = subcategory;
    }
    this.sowFormStore.subcategoryItemizedDetailsStore.editSubcategory(
      subcategory,
      categoryMeta,
      subcategoryMeta
    );
  };

  createSubcategory = subcategoryMeta => {
    const { categoryId, subcategoryId } = subcategoryMeta;
    const { subCategories } = this.categories[categoryId];

    if (
      subCategories.some(
        s => s.subcategoryId === subcategoryId && this.subcategoryCost(s) === 0
      )
    ) {
      this.globalStore.notificationStore.showWarningNotification({
        message: `Fill the existing ${subcategoryMeta.name} before adding a new one`,
      });
      return;
    }

    if (!subcategoryMeta.allowsMultiple &&
      subCategories.some(
        s => s.name.toLowerCase() === subcategoryMeta.name.toLowerCase()
      )
    ) {
      this.globalStore.notificationStore.showWarningNotification({
        message: `${subcategoryMeta.name} subcategory already exists`,
      });
      return;
    }

    let name = subcategoryMeta.name;
    if (subcategoryMeta.allowsMultiple) {
      const duplicates = subCategories.filter(
        s => s.subcategoryId === subcategoryId
      );
      const nextNumber = duplicates.length + 1;
      name = name + ' #' + nextNumber;
    }
    const newSubCategory = this.copySubcategory(subcategoryMeta);

    newSubCategory.name = name;

    this.editSubcategory(newSubCategory);
  };


  updateSubcategory(subcategory, data) {
    const modifiedSubcategory = { ...subcategory, ...data };
    const category = this.categories[subcategory.categoryId];

    const index = category.subCategories.findIndex(
      s => s.uniqueKey === subcategory.uniqueKey
    );

    if (index === -1) {
      //Add if not exists
      category.subCategories.push(modifiedSubcategory);
    } else {
      category.subCategories[index] = modifiedSubcategory;
    }
  }

  saveCommentDetailLineItem(detailId, comment, row) {
    const detailFound = row.details.find(detail => detail.detailId === detailId);
    detailFound.comments = comment;

    this.updateSubcategory(row, {
      ...row,
      details: row.details
    });

    this.sowFormStore.saveSOWDataContent();
  }

  saveComment = (comment, row) => {
    this.updateSubcategory(row, {
      ...row,
      comments: comment
    });

    this.sowFormStore.saveSOWDataContent();
  }

  cleanSubcategory = subcategory => {
    const { subcategoryId } = subcategory;
    const defaultDetails = this.subcategoryMetaById[subcategoryId]?.details;

    this.updateSubcategory(subcategory, {
      cost: null,
      description: null,
      details: defaultDetails ?? [],
      capex: null
    });
  };

  deleteSubcategory = subcategory => {
    let category = this.categories[subcategory.categoryId];
    category.subCategories = category.subCategories.filter(
      s => s.uniqueKey !== subcategory.uniqueKey
    );
  };

  selectSubCategory = (checked, subcategory, detail) => {
    if (subcategory.details.length > 0) {
      const details = [];
      subcategory.details.map(det => {
        if (det.detailId === detail.detailId) {
          detail.selected = checked
        }
        details.push(det);
      })
      this.updateSubcategory(subcategory, {
        ...subcategory,
        details: details,
      });
    } else {
      this.updateSubcategory(subcategory, {
        selected: checked
      });
    }
  };

  updateReviewStatusSelectedItems = (reviewStatus) => {
    this.sortedCategories.forEach(category => {
      const subcategories = category.subCategories;
      subcategories.map(subcategory => {
        if (subcategory.details.length > 0) {
          const details = [];
          subcategory.details.map(detail => {
            if (detail.selected) {
              detail.reviewStatus = reviewStatus
              details.push(detail);
            }
          });
          if (details.length) {
            this.updateSubcategory(subcategory, {
              ...subcategory,
              details: details,
            });
          }
        } else {
          if (subcategory.selected) {
            this.updateSubcategory(subcategory, {
              ...subcategory,
              reviewStatus: reviewStatus
            });
          }
        }
      });
    });
    this.sowFormStore.saveSOWDataContent();
  };


  filterEmpty(subCategories) {
    return subCategories
      .map(subcat => ({
        ...subcat,
        details: subcat.details.filter(d => d.cost),
      }))
      .filter(subcat => subcat.cost || subcat.details.length > 0);
  }

  private subcategoryCost(subcategory: SubcategoryDB) {
    const float = n => parseFloat(n ?? 0);
    return subcategory.details?.length > 0
      ? subcategory.details.map(d => d.cost).reduce(sum, 0)
      : float(subcategory.cost);
  }

  get totalCost() {
    return this.sortedCategories
      .map(category =>
        category.subCategories
          .map(subCategory => this.subcategoryCost(subCategory))
          .reduce(sum, 0)
      )
      .reduce(sum, 0);
  }

  countItemsByReviewStatus(reviewStatus) {
    let counter = 0;
    this.sortedCategories.forEach(category => {
      const subcategories = category.subCategories;
      subcategories.forEach(subcategory => {
        if (subcategory.reviewStatus === reviewStatus
          || subcategory.details?.filter(detail => detail.reviewStatus === reviewStatus).length) {
          counter += 1;
        }
      });
    });
    return counter;
  }

  private subcategoryCapex(subcategory: SubcategoryDB) {
    return subcategory.details?.length > 0
      ? subcategory.details.map(d => d.capex? (d.capex * d.cost/100): 0).reduce(sum, 0)
      : subcategory.capex?(subcategory.capex * subcategory.cost)/100:0;
  }

  private subcategoryRemaining(subcategory: SubcategoryDB) {
    return subcategory.details?.length > 0
    ? subcategory.details.map(d => d.capex ? d.cost - ((d.capex*d.cost)/100):d.cost).reduce(sum,0)
    : subcategory.capex? subcategory.cost - ((subcategory.capex*subcategory.cost)/100):subcategory.cost;
  }

  get totalCapex() {
    return this.sortedCategories
      .map(category =>
        category.subCategories
          .map(subCategory => this.subcategoryCapex(subCategory))
          .reduce(sum, 0)
      )
      .reduce(sum, 0);
  }

  get remainingReno() {
    return this.sortedCategories
      .map(category =>
        category.subCategories
          .map(subCategory => this.subcategoryRemaining(subCategory))
          .reduce(sum, 0)
      )
      .reduce(sum, 0);
  }

  get totalRemainingReno() {
    return this.totalCapex + this.remainingReno;
  }
}

export default CategoryStore;
