import { Document } from '@roc/feature-types';
import { Button, StandardDialog } from '@roc/ui';
import { observer } from 'mobx-react';
import { useDocumentStore } from '../../../hooks/useDocumentStore';
import BankStatement from './bankStatement';
import SaveButtonWithPermissions from 'libs/ui/src/lib/components/shared/save-button-with-permissions';

export type BankStatementDialogProps = {
  open?: boolean;
  onClose?: () => void;
  loan: any;
  document: Document;
};

export const BankStatementDialog = observer((props: BankStatementDialogProps) => {
  const { open, onClose, loan, document } = props;
  const { documentStore } = useDocumentStore();

  const dialogActions = (
    <>
      <Button
        style={{ marginRight: '8px' }}
        testId="cancel"
        color="default"
        variant="contained"
        onClick={onClose}
      >
        Cancel
      </Button>
      <SaveButtonWithPermissions
        testId="submit"
        color="primary"
        variant="contained"
        onClick={() => documentStore.documentFormStore?.bankStatementFormStore?.submitBankStatement(onClose)}
        label="Submit"
        loanId={loan?.loanId}
      />
    </>
  );

  return (
    <StandardDialog
      open={open}
      title="Bank Statement"
      maxWidth="md"
      handleClose={onClose}
      dialogActions={dialogActions}
      dialogContent={
        <BankStatement
          loan={loan}
          document={document}
        />
      }
      removeContentBox
    />
  );
});

export default BankStatementDialog;