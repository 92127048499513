export const mfbLoanTermValues = {
  values: [
    {
      fieldname: "Lender",
      mapvalue: "lenderName",
      specialtype: "customlink",
    },
    {
      fieldname: "Status",
      mapvalue: "status",
      specialtype: "",
    },
    {
      fieldname: "Interest Rate",
      mapvalue: "rate",
      specialtype: "percentage",
    },
    {
      fieldname: "Full Princ Interest",
      mapvalue: "fullPrincipalInterest",
      specialtype: "icon",
    },
    {
      fieldname: "Roc Int Rate",
      mapvalue: "rocRate",
      specialtype: "percentage",
    },
    {
      fieldname: "Appraisal Reviewed",
      mapvalue: "appraisalReviewed",
      htmlfield: "checkbox",
      specialtype: "icon",
    },
    {
      fieldname: "Loan Amount",
      mapvalue: "amount",
      specialtype: "currency",
    },
    {
      fieldname: "Loan SubType",
      mapvalue: "loanSubtype",
      specialtype: "",
    },
    {
      fieldname: "Rate Type Floating",
      mapvalue: "rateTypeFloating",
      specialtype: "icon",
    },
    {
      fieldname: "Int. Reserve (Months)",
      mapvalue: "interestReserveMonths",
      specialtype: "float",
    },
    {
      fieldname: "Duration (months)",
      mapvalue: "duration",
      specialtype: "numeric",
    },
    {
      fieldname: "Credit Reviewed",
      mapvalue: "creditReviewed",
      htmlfield: "checkbox",
      specialtype: "icon",
    },
    {
      fieldname: "Initial Loan Amount",
      mapvalue: "initialLoanAmount",
      specialtype: "currency",
    },
    {
      fieldname: "Initial LTC",
      mapvalue: "aggregateLoanToCurrentCostInitial",
      specialtype: "percentage",
    },
    {
      fieldname: "Points (I|O)",
      mapvalue: "points",
      specialtype: "",
    },
    {
      fieldname: "Interest Reserve",
      mapvalue: "interestReserve",
      specialtype: "currency",
    },
    {
      fieldname: "Pre Penalty (Months)",
      mapvalue: "prepayPenaltyMonths",
      specialtype: "numeric",
    },
    {
      fieldname: "Background Reviewed",
      mapvalue: "backgroundReviewed",
      htmlfield: "checkbox",
      specialtype: "icon",
    },
    {
      fieldname: "Construction Holdback",
      mapvalue: "constructionHoldback",
      specialtype: "currency",
    },
    {
      fieldname: "Total LTFC",
      mapvalue: "aggregateLoanToFutureCost",
      specialtype: "percentage",
    },
    {
      fieldname: "Broker Points (I|O)",
      mapvalue: "brokerPoints",
      specialtype: "",
    },
    {
      fieldname: "Pledge Of Shares",
      mapvalue: "pledgeOfShares",
      specialtype: "icon",
    },
    {
      fieldname: "Number Of Extensions",
      mapvalue: "numberOfExtensions",
      specialtype: "numeric",
    },
    {
      fieldname: "Funding Entity",
      mapvalue: "fundingPreference",
      specialtype: "",
    },
    {
      fieldname: "Construction Holdback Rate",
      mapvalue: "constructionHoldbackToRenovationBudgetPercent",
      specialtype: "percentage",
    },
    {
      fieldname: "Months Per Extension",
      mapvalue: "monthsPerExtension",
      specialtype: "numeric",
    },
    {
      fieldname: "Recourse",
      mapvalue: "recourseStructure",
      specialtype: "",
    },
    {
      fieldname: "Exception Message",
      mapvalue: "exceptionMessage",
      specialtype: "",
    },
    {
      fieldname: "Roc Extension Fee",
      mapvalue: "rocExtensionFee",
      specialtype: "percentage",
    },
    {
      fieldname: "Attorney Name",
      mapvalue: "lenderAttorneySelectedName",
      specialtype: "",
    },
    {
      fieldname: "Roc Points (I|O)",
      mapvalue: "rocPoints",
      specialtype: "",
    },
    {
      fieldname: "Mortgage Constant",
      mapvalue: "assumedExitLoanRate",
      specialtype: "percentage3",
    },
    {
      fieldname: "Exception Status",
      mapvalue: "exceptionStatus",
      specialtype: "",
    },
    {
      fieldname: "MERS",
      mapvalue: "customFields.mersProcess",
      specialtype: "",
    },
    {
      fieldname: "Partners Approval Required",
      mapvalue: "partnersApprovalRequired",
      specialtype: "icon",
    },
    {
      fieldname: "Attorney Email",
      mapvalue: "attorneyEmail",
      specialtype: "",
    },
  ],
};

export const mfbGuarantorValues = {
  values: [
    {
      fieldname: "Full Name",
      mapvalue: "fullName",
      specialtype: "customlink",
    },
    {
      fieldname: "Personal Guarantor",
      mapvalue: "personalGuarantor",
      specialtype: "icon",
    },
    {
      fieldname: "Credit",
      mapvalue: "medianScore",
      specialtype: "numeric",
    },
    {
      fieldname: "Paid Off",
      mapvalue: "portalProjectsCompleted",
      specialtype: "customlink",
    },
    {
      fieldname: "Exp",
      mapvalue: "experience",
      specialtype: "customlink",
    },
    {
      fieldname: "Ownership",
      mapvalue: "pctOwnership",
      specialtype: "percentage",
    },
    {
      fieldname: "Age",
      mapvalue: "age",
      specialtype: "numeric",
    },
    {
      fieldname: "Borrower Status",
      mapvalue: "status",
      specialtype: "",
    },
    {
      fieldname: "BK",
      mapvalue: "bankruptcy",
      specialtype: "",
    },
    {
      fieldname: "Funded",
      mapvalue: "portalProjectsActive",
      specialtype: "customlink",
    },
    {
      fieldname: "GC",
      mapvalue: "generalContractor",
      specialtype: "",
    },
    {
      fieldname: " ",
      mapvalue: "space-borrow5",
      specialtype: "blankspace",
    },
    {
      fieldname: "Networth",
      mapvalue: "netWorth",
      specialtype: "currency",
    },
    {
      fieldname: "Liquidity",
      mapvalue: "liquidity",
      specialtype: "currency",
    },
    {
      fieldname: "FC",
      mapvalue: "foreclosure",
      specialtype: "",
    },
    {
      fieldname: "Agreed",
      mapvalue: "portalProjectsAgreed",
      specialtype: "customlink",
    },
  ],
};


export const mfbCollateralValues = {
  values: [
    {
      fieldname: "Address",
      mapvalue: "address",
      specialtype: "bigvalue",
    },
    {
      fieldname: "",
      mapvalue: "",
      specialtype: "customlink",
    },
    {
      fieldname: "As-is Value",
      mapvalue: "asIsWithMedian",
      specialtype: "customlink",
    },
    {
      fieldname: "AC",
      mapvalue: "approved",
      specialtype: "icon",
    },
    {
      fieldname: "Current Occupancy%",
      mapvalue: "currentOccupancy",
      specialtype: "percentage",
    },
    {
      fieldname: "Unit Count",
      mapvalue: "units",
      specialtype: "numeric",
    },
    {
      fieldname: "Acquisition Price",
      mapvalue: "purchasePrice",
      specialtype: "currency",
    },
    {
      fieldname: "Reno Budget",
      mapvalue: "renovationBudget",
      specialtype: "currency",
    },
    {
      fieldname: "ARV",
      mapvalue: "arvWithMedian",
      specialtype: "customlink",
    },
    {
      fieldname: "Min Release $",
      mapvalue: "releaseAmount",
      specialtype: "currency",
    },
    {
      fieldname: "Appraised Cap Rate",
      mapvalue: "appraisedCapRate",
      specialtype: "percentage",
    },
    {
      fieldname: "Acquisition",
      mapvalue: "propertyOwnership",
      specialtype: "",
    },
    {
      fieldname: "Acquisition Date",
      mapvalue: "purchaseDate",
      specialtype: "",
    },
    {
      fieldname: "Completed Renovations",
      mapvalue: "sunkCost",
      specialtype: "currency",
    },
    {
      fieldname: "Arms Length",
      mapvalue: "armsLength",
      specialtype: "checkbox",
    },
    {
      fieldname: "Full Net Proceeds",
      mapvalue: "fullNetProceeds",
      specialtype: "checkbox",
    },
    {
      fieldname: "Annual In Place NCF",
      mapvalue: "ncf",
      specialtype: "currency",
    },
    {
      fieldname: "Exit Strategy",
      mapvalue: "exitStrategy",
      specialtype: "",
    },
    {
      fieldname: "RefiAmount",
      mapvalue: "refiAmountDisplay",
      specialtype: "currency",
    },
    {
      fieldname: "Total Reno Budget",
      mapvalue: "totalRenovationBudget",
      specialtype: "currency",
    },
    {
      fieldname: "Arms Comment",
      mapvalue: "armsLengthComment",
      specialtype: "",
    },
    {
      fieldname: "Release Comment",
      mapvalue: "releaseComment",
      specialtype: "",
    },
    {
      fieldname: "Annual Projected NCF",
      mapvalue: "projectedNcf",
      specialtype: "currency",
    },
    {
      fieldname: "Purchase Type",
      mapvalue: "propertySourcing",
      specialtype: "",
    },
    {
      fieldname: "WholeSale$",
      mapvalue: "wholesaleAmount",
      specialtype: "currency",
    },
    {
      fieldname: "Describe Renovation",
      mapvalue: "describeRenovation",
      specialtype: "",
    },
    {
      fieldname: "Seller's Credit Amount",
      mapvalue: "sellerCreditAmount",
      specialtype: "currency",
    },
    {
      fieldname: "Lien Pos",
      mapvalue: "lienPosition",
      specialtype: "numeric",
    },
    {
      fieldname: " ",
      mapvalue: "collateralspace7",
      specialtype: "blankspace",
    },
    {
      fieldname: "Wholesaler Purchase",
      mapvalue: "wholesalerPurchase",
      specialtype: "",
    },
    {
      fieldname: "WholeSale%  ",
      mapvalue: "wholesalePercentage",
      specialtype: "percentage",
    },
    {
      fieldname: " ",
      mapvalue: "collateralspace1",
      specialtype: "blankspace",
    },
    {
      fieldname: " ",
      mapvalue: "collateralspace1",
      specialtype: "blankspace",
    },
    {
      fieldname: "Sr Amt",
      mapvalue: "lienAmount",
      specialtype: "currency",
    },
    {
      fieldname: "Rural Census",
      mapvalue: "ruralProperty",
      specialtype: "checkbox",
    },
  ],
};


export const mfbDealEconomicsValues = {
  values: [
    {
      fieldname: "In Place Debt Service:",
      mapvalue: "inPlaceDebtService",
      specialtype: "currency",
    },
    {
      fieldname: "Projected Debt Service",
      mapvalue: "projectedDebtService",
      specialtype: "currency",
    },
    {
      fieldname: "Set. Costs:",
      mapvalue: "settlementCosts",
      specialtype: "currency",
    },
    {
      fieldname: "App. Exit Strat.",
      mapvalue: "approvedExitStrategy",
      specialtype: "icon",
    },
    {
      fieldname: "In Place NCF DSCR",
      mapvalue: "inPlaceNCFDSCR",
      specialtype: "",

    },
    {
      fieldname: "Projected NCF DSCR",
      mapvalue: "projectedNCFDSCR",
      specialtype: "",
    },
    {
      fieldname: "In Place NCF Debt Yield",
      mapvalue: "inPlaceNCFDebtYield",
      specialtype: "percentage",
    },
    {
      fieldname: "Projected NCF Debt Yield",
      mapvalue: "projectedNCFDebtYield",
      specialtype: "percentage",
    }
  ],
};
