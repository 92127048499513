import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Card, Select, MenuItem, FormControl, InputLabel, Chip, Typography, Grid, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useUserStore } from '../hooks/useUserStore';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    height: "100%"
  },
  formControl: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  saveButton: {
    marginTop: theme.spacing(2),
  }
}));

interface Company {
  label: string;
  value: number;
}

export const LenderSelectionCard = observer(() => {
  const classes = useStyles();
  const { companyDropdownStore, userStore } = useUserStore();
  const [selectedLenders, setSelectedLenders] = useState<Company[]>([]);
  const [hasChanges, setHasChanges] = useState(false);

  useEffect(() => {
    const selectedCompanies = companyDropdownStore.companies.filter(company => userStore.selectedCompaniesNotifications.includes(company.value));
    setSelectedLenders(selectedCompanies);
  }, []);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedLenders(event.target.value as Company[]);
    setHasChanges(true);
  };

  const handleDelete = (lenderToDelete: Company) => () => {
    setSelectedLenders((prev) => prev.filter((lender) => lender.value !== lenderToDelete.value));
    setHasChanges(true);
  };

  const handleSaveChanges = () => {
    const companiesIds = selectedLenders.map(company => company.value);
    userStore.updateSelectedCompaniesForNotifications(companiesIds);
    setHasChanges(false);
  };

  return (
    <Card className={classes.paper}>
      <Typography variant="h5" component="h2" gutterBottom>
        LENDER CONVERSATIONS
      </Typography>
      <Typography variant="body1" paragraph>
        Select the lenders to automatically include you in loan-related conversations
      </Typography>
      <FormControl className={classes.formControl}>
        <InputLabel id="multi-select-label">Lenders</InputLabel>
        <Select
          labelId="multi-select-label"
          id="multi-select"
          multiple
          value={selectedLenders}
          onChange={handleChange}
          renderValue={(selected) => (
            <div className={classes.chips}>
              {(selected as Company[]).map((company) => (
                <Chip
                  key={company.value}
                  label={company.label}
                  className={classes.chip}
                  onDelete={handleDelete(company)}
                  onMouseDown={(event) => {
                    event.stopPropagation();
                  }}
                />
              ))}
            </div>
          )}
        >
          {companyDropdownStore.companies.map((company) => (
            <MenuItem key={company.value} value={company}>
              {company.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Grid item xs={12} container justifyContent="flex-end">
        <Button
          variant="contained"
          color="primary"
          className={classes.saveButton}
          disabled={!hasChanges}
          onClick={handleSaveChanges}
        >
          Save Changes
        </Button>
      </Grid>
    </Card>
  );
});