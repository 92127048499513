import {
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Cancel, MoreVert } from '@material-ui/icons';
import { useLoanRoutes } from '@roc/feature-loans-routes-config';
import { useLoanStore } from '@roc/feature-loans-shared';
import { formatDate } from '@roc/feature-utils';
import {
  AgGridColumnProps,
  ConfirmDialog,
  DataGrid as DrawGrid,
  FieldLabel,
  LinkColumn,
  MenuItem,
  Paper,
} from '@roc/ui';
import { formatCurrency } from '@roc/ui/utils';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { LoanKeyDatesNew } from '../../common/loanKeyDates';
import { DRAW_INIT_STAGE, DRAW_REQUEST_STAGE } from '../../constants/draws';
import { useDrawsStore } from '../../hooks';
import { LoanValuesCard } from './loanValuesCard';
import { LoanDrawsGrid } from './loanDrawsGrid';

const noOpComparator = () => 0;

const columns: AgGridColumnProps[] = [
  {
    field: 'drawNumber',
    headerName: 'Draw #',
    minWidth: 100,
    cellRenderer: 'drawNumberCellRenderer',
    comparator: noOpComparator,
    sortable: false,
  },
  {
    field: 'stage',
    headerName: 'Status',
    minWidth: 100,
    comparator: noOpComparator,
    sortable: false,
  },
  {
    field: 'creationDate',
    headerName: 'Draw Submission Date',
    minWidth: 100,
    cellRenderer: 'dateCellRenderer',
    comparator: noOpComparator,
    sortable: false,
  },
  {
    field: 'amount',
    headerName: 'Amount Requested',
    minWidth: 100,
    cellRenderer: 'amountRenderer',
    comparator: noOpComparator,
    sortable: false,

  },
  {
    field: 'wireSentDate',
    headerName: 'Wire Sent Date',
    minWidth: 100,
    cellRenderer: 'wireSentDateRenderer',
    comparator: noOpComparator,
    sortable: false,
  },
  {
    field: 'wireAmount',
    headerName: 'Wire Amount',
    minWidth: 100,
    cellRenderer: 'wireAmountRenderer',
    comparator: noOpComparator,
    sortable: false,
  },
  {
    field: 'actions',
    headerName: 'Actions',
    maxWidth: 100,
    cellRenderer: 'actionsCellRenderer',
    comparator: noOpComparator,
    sortable: false,
  },
];

const useStyles = makeStyles(theme => ({
  paper: {
    padding: 24,
  },
  gridContainer: {
    paddingTop: 24,
  },
  cardSeparator: {
    marginTop: 24,
  }
}));

export const DrawsTab = observer(
  ({ loanId }: { loanId: string }) => {
    const {
      drawStore,
      drawStore: { drawList },
    } = useDrawsStore();
    const {
      loanStore: { loanDetails },
    } = useLoanStore();
    const classes = useStyles();

    useEffect(() => {
      drawStore.fetchDraws(loanId);
      return () => {
        drawStore.resetDraws();
      };
    }, []);

    return (
      <>
        <LoanValuesCard
          draws={drawList}
          chRemaining={
            loanDetails?.calculatedLoanData?.constructionHoldbackRemaining
              ? loanDetails?.calculatedLoanData?.constructionHoldbackRemaining
              : 0
          }
          chReimbursedToDate={
            loanDetails?.calculatedLoanData?.constructionHoldbackFunded
              ? loanDetails?.calculatedLoanData?.constructionHoldbackFunded
              : 0
          }
        />
        <Grid item xs={12} md={12} className={classes.gridContainer}>
          <LoanKeyDatesNew />
        </Grid>
        <Grid item xs={12} md={12} className={classes.cardSeparator}>
          <Paper>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} style={{
                padding: '24px 24px 0px 24px'
              }}>
                <FieldLabel
                  style={{
                    display: 'flex'
                  }}
                  tooltip={
                    'Click on the Draw # to see more details and to upload the documents that will allow you to complete your draw request.'
                  }
                >
                  DRAWS
                </FieldLabel>
              </Grid>
              <Grid item xs={12} md={12}>
                <LoanDrawsGrid loanId={loanId} drawList={drawList} />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </>
    );
  }
);
