import { GlobalStore } from '@roc/client-portal-shared/stores';
import { CreditBackgroundType, SegmentTrackerEvent, downloadDocument, segmentAnalytics } from '@roc/client-portal-shared/utils';
import { action, flow, makeObservable, observable } from 'mobx';
import { FormStore, UserStore } from '@roc/feature-app-core';
import { GridStore } from '@roc/feature-app-core';
import { SelectBorrowersStore } from '@roc/feature-borrowers';
import { ApiResponse } from '@roc/feature-app-core';
import { CreditBackgroundService } from './../services/creditBackgroundService';
import { Borrower } from '@roc/feature-types';
import { LoanParticipantsStore } from '@roc/feature-loan-participants';

export interface CreditBackground {
  requestId: number;
  borrowerId: number;
  envelopId: string;
  borrowerEmail: string;
  borrowerName: string;
  status: string;
  runBackground: boolean;
  runCredit: boolean;
  requestedBy: string;
  createdDate: string;
  lastUpdateDate: string;
  errorReasonMessage: string;
}

const submitCreditBackgroundForm = {
  fields: {
    __chooseBorrowerFeature__: {
      value: false,
      error: null,
      rule: '',
    },
    email: {
      value: '',
      error: null,
      rule: 'required|email',
    },
    runCredit: {
      value: false,
      error: null,
      rule: 'required',
    },
    runBackground: {
      value: true,
      error: null,
      rule: 'required',
    },
    // Borrower Information
    borrower: {
      value: null,
      error: null,
      rule: [{ required_if: ['__chooseBorrowerFeature__', true] }],
      message: 'The borrower selection field is required.',
    },
    originatorId: {
      value: null,
      rule: '',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export class CreditBackgroundStore extends FormStore {
  private globalStore: GlobalStore;
  private creditBackgroundService: CreditBackgroundService;
  userStore: UserStore;
  loanParticipantsStore: LoanParticipantsStore;
  gridStore: GridStore;
  liquidityRequestsGridStore: GridStore;
  selectBorrowersStore: SelectBorrowersStore;
  newRequestId: number;
  requestSuccess: boolean;
  requestType: string;
  isCAFSigned: boolean;

  constructor(globalStore: GlobalStore, userStore: UserStore) {
    super({ ...submitCreditBackgroundForm }, globalStore);
    this.globalStore = globalStore;
    this.userStore = userStore;
    this.requestSuccess = false;
    this.loanParticipantsStore = new LoanParticipantsStore(this.globalStore);
    this.gridStore = new GridStore(() => this.fetchData());
    this.liquidityRequestsGridStore = new GridStore(() =>
      this.fetchLiquidityGridData()
    );
    this.selectBorrowersStore = new SelectBorrowersStore(
      globalStore,
      undefined,
      false,
      undefined,
      x => this.handleBorrowerChange(x)
    );
    this.creditBackgroundService = new CreditBackgroundService();
    makeObservable(this, {
      newRequestId: observable,
      requestSuccess: observable,
      isCAFSigned: observable,
      requestType: observable,
      downloadReports: flow,
      markAsVoid: flow,
      markAsManuallyClosed: flow,
      submitCreditBackgroundRequest: flow,
      loadParticipants: flow,
      setRequestSuccess: action,
      manageDailyReminder: flow,
      getFormLink: flow,
    });
  }

  reset() {
    super.reset();
    this.selectBorrowersStore.reset();
    this.onFieldChange(
      '__chooseBorrowerFeature__',
      this.globalStore.userFeatures.creditBackgroundChooseBorrower
    );
  }

  private handleBorrowerChange(borrowers: Borrower[]) {
    const borrower = borrowers[0] || null;
    this.onFieldChange('borrower', borrower);
    this.onFieldChange('email', borrower?.emailAddress ?? '');
  }

  private async fetchData() {
    const filters = {
      ...this.gridStore.gridData.meta.filters,
      ...this.gridStore.gridData.meta.dropdownFilters,
      createdDate: this.formatDateAsISO(
        this.gridStore.gridData.meta.filters?.createdDate
      ),
      lastUpdateDate: this.formatDateAsISO(
        this.gridStore.gridData.meta.filters?.lastUpdateDate
      ),
    };
    try {
      const response: ApiResponse = await this.creditBackgroundService.getAllCreditBackgroundRequests(
        this.gridStore.gridData.meta.pageNumber,
        this.gridStore.gridData.meta.pageSize,
        this.gridStore.gridData.meta.sortDir,
        this.gridStore.gridData.meta.sortBy,
        filters
      );
      return response;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching credit background requests',
      });
    }
  }

  *loadParticipants() {
    yield this.loanParticipantsStore.fetchLoanParticipants();
    if (this.globalStore.userFeatures?.isLenderOriginator) {
      this.onFieldChange(
        'originatorId',
        this.userStore.userInformation.userId
      );
    }
  }

  private async fetchLiquidityGridData() {
    const filters = {
      ...this.liquidityRequestsGridStore.gridData.meta.filters,
      ...this.liquidityRequestsGridStore.gridData.meta.dropdownFilters,
      createdDate: this.formatDateAsISO(
        this.liquidityRequestsGridStore.gridData.meta.filters?.createdDate
      ),
      lastUpdateDate: this.formatDateAsISO(
        this.liquidityRequestsGridStore.gridData.meta.filters?.lastUpdateDate
      ),
    };
    try {
      const response: ApiResponse = await this.creditBackgroundService.getAllLiquidityRequests(
        this.liquidityRequestsGridStore.gridData.meta.pageNumber,
        this.liquidityRequestsGridStore.gridData.meta.pageSize,
        this.liquidityRequestsGridStore.gridData.meta.sortDir,
        this.liquidityRequestsGridStore.gridData.meta.sortBy,
        filters
      );
      return response;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching Plaid liquidity requests',
      });
    }
  }

  private formatDateAsISO(strDate: string) {
    if (strDate) {
      const [mm, dd, yyyy] = strDate.split('/');
      return [yyyy, mm, dd].filter(Boolean).join('-');
    } else {
      return undefined;
    }
  }

  setRequestSuccess(requestSuccess: boolean) {
    this.requestSuccess = requestSuccess;
    if (!requestSuccess) {
      this.newRequestId = null;
    }
  }

  *downloadReports(requestId) {
    try {
      const response: ApiResponse = yield this.creditBackgroundService.downloadReports(
        requestId
      );
      downloadDocument(response?.data, response?.headers, 'download');
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while downloading reports.',
      });
    }
  }

  *markAsVoid(requestId) {
    try {
      const response: ApiResponse = yield this.creditBackgroundService.markAsVoid(
        requestId
      );
      this.gridStore.refresh();
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Request marked as void successfully.',
      });
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while marking the request as void.',
      });
    }
  }

  *markAsManuallyClosed(requestId) {
    try {
      const response: ApiResponse = yield this.creditBackgroundService.markAsManuallyClosed(
        requestId
      );
      this.gridStore.refresh();
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Request marked as completed successfully.',
      });
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while marking the request as completed.',
      });
    }
  }

  *submitCreditBackgroundRequest() {
    this.runFormValidationWithMessage();

    const type = CreditBackgroundType.ID_VERIFICATION;

    if (this.form.meta.isValid) {
      try {
        const {
          email,
          runCredit,
          runBackground,
          borrower,
          originatorId,
        }: {
          email: string;
          runCredit: boolean;
          runBackground: boolean;
          borrower?: Borrower;
          originatorId: number;
        } = this.getFormValues();

        // if borrower is present and is not a lead, then pass borrowerId
        const borrowerId = !borrower?.leadId ? borrower?.borrowerId : null;

        // if borrower is present and is a lead, then pass sfLeadJson
        const sfLeadJson = borrower?.leadId
          ? {
            leadSfId: borrower.leadSfId,
            leadId: borrower.leadId,
            leadSource: borrower.leadSource,
            leadSourceGroup: borrower.leadSourceGroup,
            firstName: borrower.firstName,
            lastName: borrower.lastName,
            cellPhone: borrower.cellPhone,
          }
          : null;

        const response: ApiResponse = yield this.creditBackgroundService.submitCreditBackgroundRequest(
          {
            email,
            runCredit,
            runBackground,
            originatorId,
            type,
            isConfirmed: true,
            isSendCAFConfirmed: true,
            isDuplicateConfirmed: true,
            isBorrower: true,
            borrowerId: borrowerId,
            sfLeadJson: sfLeadJson,
          }
        );
        this.reset();
        this.newRequestId = response.data.data.requestId;
        this.isCAFSigned = response.data.data.isCAFSigned;
        this.setRequestSuccess(true);
        this.requestType = type;
        this.globalStore.notificationStore.showSuccessNotification({
          message: 'Credit background check requested successfully.',
        });
        segmentAnalytics.trackEvent({
          name: SegmentTrackerEvent.CREDIT_BACKGROUND,
          userName: this.userStore?.userInformation?.username,
        });
      } catch (error) {
        console.log(error);
        this.globalStore.notificationStore.showErrorNotification({
          message: 'Error while requesting credit background check.',
        });
      }
    }
  }


  *manageDailyReminder(requestId) {
    try {
      const response: ApiResponse = yield this.creditBackgroundService.manageDailyReminder(
        requestId
      );
      this.gridStore.refresh();
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Notifications preference change succesfully.',
      });
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while changing notifications preference.',
      });
    }
  }

  *getFormLink(requestId) {
    try {
      const response: ApiResponse = yield this.creditBackgroundService.getFormLink(requestId);
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Form link copied successfully!',
      });
      return response.data.data;
    }
    catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while getting form link.',
      });
    }
  }
}
