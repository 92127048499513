import { Box } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Add } from '@material-ui/icons';
import { Button, Paper } from '@roc/ui';
import { observer } from 'mobx-react';
import { GeneralTasksToolbar } from '../components/generalTasksToolbar';
import { useGeneralTasksStore } from '../hooks/useGeneralTasksStore';
import {
  GeneralTasksView
} from '../types/generalTasksTypes';
import { EditTaskDialog } from './editTask/editTaskDialog';
import { KanbanView } from './kanbanView';
import { TaskListView } from './taskListView';


const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    padding: theme.spacing(2),
    boxShadow: 'none !important',
    border: '1px solid #cdcdcd',
  }
}))

export const GeneralTasksComponent = observer(() => {
  const classes = useStyles();
  const { generalTasksStore } = useGeneralTasksStore();
  const { tab, view, editTaskStore } = generalTasksStore;

  return (
    <>
      <Box display="flex" flexDirection="column" height="100%">
        <Box pb={2}>
          <Paper className={classes.paper}>
            <GeneralTasksToolbar />
          </Paper>
        </Box>
        <Box flex={1}>
          <Paper className={classes.paper}>
            <Box pb={2} textAlign={"right"}>
              <Button
                testId="add-task"
                variant="contained"
                color="primary"
                size="small"
                onClick={() => editTaskStore.openAddTask()}
              >
                <Add />
                New Task
              </Button>
            </Box>
            {view === GeneralTasksView.KANBAN && <KanbanView />}
            {view === GeneralTasksView.LIST && <TaskListView />}
          </Paper>
        </Box>
      </Box>
      <EditTaskDialog />
    </>
  );
});
