import { Page } from '../../../components';
import { Route } from 'react-router';
import { useStore } from '@roc/client-portal-shared/hooks';
import { LiquidityCalculator } from '../liquidityCalculator';

export const getLiquidityCalculatorRoutesConfig = (
  basePath: string,
  baseUrl: string
) => {
  return {
    liquidityCalculator: {
      path: `${basePath}/liquidity-calculator`,
      url: `${baseUrl}/liquidity-calculator`,
      documentTitle: 'Liquidity Calculator',
    },
  };
};

export const getLiquidityCalculatorRoutes = (
  basePath: string,
  baseUrl: string
) => {
  const config = getLiquidityCalculatorRoutesConfig(basePath, baseUrl);
  const { liquidityCalculatorStore } = useStore();

  return {
    liquidityCalculator: (
      <Route exact path={config.liquidityCalculator.path}>
        <Page routeDefinition={config.liquidityCalculator}>
          <LiquidityCalculator store={liquidityCalculatorStore} />
        </Page>
      </Route>
    ),
  };
};
