import { action, flow, makeObservable, observable } from 'mobx';
import { GlobalStore, GridStore } from '../../../stores';
import { LinkedInService } from '../services/linkedInService';
import { SendBulkSMSFormStore } from 'apps/crm-portal/src/app/modules/createList/stores/SendBulkSMSFormStore';
import { ApiResponse } from '@roc/feature-app-core';;
import {
  BORROWER_OBJECT_TYPE,
  LEAD_OBJECT_TYPE,
  TPO_OBJECT_TYPE,
} from 'libs/feature-backoffice-users/src/backOfficeUsers/components/leads/constants/constants';

export class LinkedInStore {
  private globalStore: GlobalStore;
  private linkedInService: LinkedInService;
  public linkedInCreds: any;


  constructor(globalStore) {
    this.globalStore = globalStore;
    this.linkedInService = new LinkedInService();

    makeObservable(this, {
      linkedInCreds: observable,
      retriveTokenWithOAuthToken: flow,
      handleTokenCallback: flow,
    });
  }

  async getCreds() {
    const response = await this.linkedInService.getCreds();
    this.linkedInCreds = response?.data;
  }

  *retriveTokenWithOAuthToken(brand, host) {
    yield this.linkedInService.retrieveTokenWithOAuthCode(
      brand,
      host,
      this.linkedInCreds?.clientId
    );
  }

  *handleTokenCallback(code, state) {
    state = state.replace("LinkedIn", "");
    yield this.linkedInService.handleCallBack(code, state);
  }


}
