import { useContext } from 'react';
import { StoreContext } from '@roc/feature-app-core';
import { TitleLoansDashboardStore } from '../stores/titleLoansDashboardStore';
import { ForeclosureInvoiceStore } from '../stores/foreclosureInvoiceStore';

export const useTitleAgentLoansStore = () =>
  useContext<{
    foreclosureInvoiceStore: ForeclosureInvoiceStore;
    titleLoansDashboardStore: TitleLoansDashboardStore;
  }>(StoreContext);
