
import { useTheme } from '@material-ui/core/styles';
import React from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Typography, useMediaQuery } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { Accordion, AccordionDetails, AccordionSummary, DenseTable } from '@roc/ui';
import { guarantorValuesBridge } from '../../../utils/ColumnsBridgeLoanSummary';
import { useLoanSummaryStore } from '../../../hooks/useLoanSummaryStore';


export const BorrowersCard = (loanDetails) => {
  const [expanded, setExpanded] = React.useState(true);
  const theme = useTheme();
  const lgUp = useMediaQuery(theme.breakpoints.up('lg'), { noSsr: true });
  const borrowers = loanDetails?.loanDetails?.loanBorrowerInformation?.loanBorrowers?.rows;
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const { loanSummaryStore } = useLoanSummaryStore();

  return (
    <Accordion square expanded={expanded} onChange={handleExpandClick}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}>
        <Typography style={{
          fontSize: 18,
          fontWeight: 'bold'
        }}>
          Borrowers
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2} style={{
          margin: 'auto',
          width: '100%'
        }}>
          <Grid container spacing={2}>
            {borrowers && borrowers.map((borrower, index) => (
              <Grid item xs={12} key={index}>
                <Accordion square>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    style={{
                      background: 'none'
                    }}>
                    <Typography>
                      {borrower.fullName}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2} style={{
                      margin: 'auto',
                      width: '100%'
                    }}>
                      {Object.keys(loanSummaryStore.transformDataForBorrowers(borrower, guarantorValuesBridge, 6)).map((columnKey) => (
                        <Grid item xs={12} md={6} lg={4} key={columnKey} style={{
                          borderRight: (lgUp ? '1px dashed #eee' : 'none')
                        }}>
                          <DenseTable data={loanSummaryStore.transformDataForBorrowers(borrower, guarantorValuesBridge, 6)[columnKey]} />
                        </Grid>
                      ))}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </AccordionDetails >
    </Accordion>
  );
}