import { observer } from "mobx-react";
import {
  Typography,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import { useState, useRef, useEffect } from 'react';
import { Paper, ScreenshotGrid, StandardDialog } from '@roc/ui';
import { formatCurrency } from '@roc/ui/utils';
import { DrawRequestCategoryTable } from '@roc/feature-draws';
import { SubmitDrawRequestForm } from '@roc/feature-draws';
import ShareDownloadButtons from "../drawRequests/components/shareDownloadButtons";

const totalColumns: any[] = [
  {
    name: '',
    width: '29.5%',
    align: 'left',
  },
  {
    name: 'ORIGINAL COST',
    width: '7%',
    align: 'center',
  },
  {
    name: 'COMPLETED RENOVATIONS',
    width: '10%',
    align: 'center',
  },
  {
    name: 'PREVIOUSLY DISBURSED',
    width: '19%',
    align: 'center',
  },
  {
    name: '% COMPLETE TO DATE',
    width: '10%',
    align: 'center',
  },
  {
    name: 'REQUESTED THIS DRAW',
    width: '12%',
    align: 'center',
  },
  {
    name: '',
    width: '12,5%',
    align: 'center',
  },
];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      marginBottom: 8,
    },
    topHeaderSection: {
      marginBottom: 16,
    },
    loanValuesItem: {
      paddingRight: 24,
    },
    paper: {
      padding: theme.spacing(3, 0, 4),
    },
    headerGrid: {
      padding: '12px 48px 12px 48px',
    },
    leftHeaderData: {
      display: 'flex',
      flexDirection: 'row',
    },
    drawNumber: {
      paddingLeft: 24,
    },
    title: {
      color: '#333333',
      fontSize: 20,
      fontWeight: 'bold',
      textTransform: 'uppercase',
    },
    table: {
      minWidth: 750,
      border: '2px #e0e0e0 solid',
      '& td': {
        height: '40px',
        padding: 0,
        paddingTop: '8px',
        paddingBottom: '8px',
      },
      '& td:first-child': {
        height: '40px',
        paddingLeft: '16px',
        paddingTop: '8px',
        paddingBottom: '8px',
      },
      '& th:first-child, td[rowSpan]': {
        paddingLeft: theme.spacing(2),
        paddingRight: '3px',
      },
      '& table tr:last-child td, & table td[rowSpan]': {
        borderBottom: 'none',
      },
    },
    headerCell: {
      height: '41px',
      fontWeight: 'bold',
      padding: '0 4px 0 0',
    },
    totalRow: {
      backgroundColor: '#FDEFBF',
    },
    shareDownloadContainer: {
      paddingRight: 24,
      display: 'flex',
      justifyContent: 'flex-end',
    },
    buttonContainer: {
      marginTop: 32,
    },
  })
);

export const DrawRequestFormShared = observer(
  ({ onSave, onSubmit, store, drawId = null }) => {
    const classes = useStyles();
    const screenshotContainerRef = useRef(null);
    const [
      openSubmitDrawRequestFormModal,
      setOpenSubmitDrawRequestFormModal,
    ] = useState<boolean>(false);

    const defaultAmount = '$0.00';
    useEffect(() => {
      if (store && drawId) {
        store.reset();
        store.loadTableData(drawId);
      }
      return () => {
        store.reset();
      };
    }, [store]);

    const handleSubmit = () => {
      setOpenSubmitDrawRequestFormModal(false);
      onSubmit();
    };

    const requestSubmit = () => {
      if (store.validateRequestedThisDraw()) { setOpenSubmitDrawRequestFormModal(true); }
    };

    const getAmountToShow = (amount: any) => {
      return amount ? formatCurrency(amount, 2) : defaultAmount;
    }

    return (
      <Grid container spacing={2}>
        <Grid item xs={12} className={classes.header}>
          {!store.isPublic && <div className={classes.shareDownloadContainer}>
            <ShareDownloadButtons
              screenshotContainerRef={screenshotContainerRef}
              url={store?.url}
            />
          </div>}
          {store?.saved && (
            <Grid item xs={12}>
              <Alert severity="success">Draw requested successfully!</Alert>
            </Grid>
          )}
          <ScreenshotGrid
            fileName="Draw_request.pdf"
            ref={screenshotContainerRef}
          >
            <Grid item container spacing={2}>
              <Grid item xs={12} md={12}>
                <Paper className={classes.paper}>
                  <Grid container spacing={2} className={classes.headerGrid}>
                    <Typography variant="h4" className={classes.topHeaderSection}>
                      Draw Details
                    </Typography>
                    <Grid
                      container
                      justifyContent="space-between"
                      className={classes.topHeaderSection}
                    >
                      <Grid item xs={12} md={4}>
                        <Typography variant="body1">Project Address</Typography>
                        <Divider />
                        <Typography variant="h6">
                          {store?.drawDetailsData?.propertyAddress}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography variant="body1">Borrower</Typography>
                        <Divider />
                        <Typography variant="h6">
                          {store?.drawDetailsData?.borrowerName}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container justifyContent="space-between">
                      <Grid
                        item
                        xs={12}
                        md={4}
                        className={classes.leftHeaderData}
                      >
                        <Grid item xs={12} md={6}>
                          <Typography variant="body1">Loan ID</Typography>
                          <Divider />
                          <Typography variant="h6">
                            {store?.drawDetailsData?.draw?.loanId}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={6} className={classes.drawNumber}>
                          <Typography variant="body1">Draw Number</Typography>
                          <Divider />
                          <Typography variant="h6">
                            {`#${store?.drawDetailsData?.draw?.drawNumber}`}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography variant="body1">Lender</Typography>
                        <Divider />
                        <Typography variant="h6">{store?.drawDetailsData?.lenderName}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={12} md={12}>
                <Paper className={classes.paper}>
                  <Grid container spacing={2} className={classes.headerGrid}>
                    <Typography variant="h4" className={classes.topHeaderSection}>
                      Loan Values
                    </Typography>
                    <Grid
                      container
                      justifyContent="space-between"
                      className={classes.topHeaderSection}
                    >
                      <Grid
                        item
                        xs={12}
                        md={4}
                        className={classes.loanValuesItem}
                      >
                        <Typography variant="body1">Project Total</Typography>
                        <Divider />
                        <Typography variant="h6">
                          {getAmountToShow(store?.drawDetailsData?.loanAmount)}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={4}
                        className={classes.loanValuesItem}
                      >
                        <Typography variant="body1">
                          Construction Holdback Reimbursed to Date
                        </Typography>
                        <Divider />
                        <Typography variant="h6">
                          {getAmountToShow(store?.drawDetailsData?.constructionHoldbackReimbursedToDate)}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Typography variant="body1">
                          Construction Holdback Remaining
                        </Typography>
                        <Divider />
                        <Typography variant="h6">
                          {getAmountToShow(store?.drawDetailsData?.constructionHoldbackRemaining)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={12} md={12}>
                <Paper className={classes.paper}>
                  <Grid container spacing={2} className={classes.headerGrid}>
                    <Grid item xs={12} md={12}>
                      <Typography variant="h4">DRAW REQUEST</Typography>
                    </Grid>
                    {store &&
                      store.tableData &&
                      store.tableData.map(category => {
                        if (category.subCategories.length) {
                          return (
                            <DrawRequestCategoryTable
                              category={category}
                              key={category.name}
                            />
                          );
                        }
                      })}
                    <Grid item xs={12} md={12}>
                      <Table className={classes.table}>
                        <TableHead>
                          <TableRow>
                            {totalColumns.map(({ name, ...props }, index) => (
                              <TableCell
                                key={`${index}_${name}`}
                                className={classes.headerCell}
                                {...props}
                              >
                                {name}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow className={classes.totalRow}>
                            <TableCell>
                              <Typography variant="h6">Project Total</Typography>
                            </TableCell>
                            <TableCell align="center">
                              <Typography variant="h6">
                                {getAmountToShow(store?.drawDetailsData?.constructionHoldback)}
                              </Typography>
                            </TableCell>
                            <TableCell align="center">
                              <Typography variant="h6">
                                {`${store?.totalCapex.percentage}% | ${getAmountToShow(store?.totalCapex.amount)}`}
                              </Typography>
                            </TableCell>
                            <TableCell align="center">
                              <Typography variant="h6">
                                {`${store?.totalPreviouslyDisbursed.percentage}% | ${getAmountToShow(store?.totalPreviouslyDisbursed.amount)}`}
                              </Typography>
                            </TableCell>
                            <TableCell align="center">
                              <Typography variant="h6">
                                {`${store?.totalCompleteToDate.percentage}%`}
                              </Typography>
                            </TableCell>
                            <TableCell align="center">
                              <Typography variant="h6">
                                {`${store?.totalRequestedThisDraw.percentage}% | ${getAmountToShow(store?.totalRequestedThisDraw.amount)}`}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="h6">{''}</Typography>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </ScreenshotGrid>
          <Grid item xs={12} className={classes.buttonContainer}>
            <Grid container justifyContent="center" spacing={2}>
              <Grid item xs={6} md={2}>
                <Button
                  onClick={onSave}
                  color="primary"
                  size="large"
                  variant="outlined"
                  fullWidth
                >
                  SAVE
                </Button>
              </Grid>
              <Grid item xs={6} md={2}>
                <Button
                  disabled={!store?.isFilled}
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth
                  onClick={requestSubmit}
                >
                  SUBMIT
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <StandardDialog
          open={openSubmitDrawRequestFormModal}
          title="Submit Draw Request Form"
          maxWidth="sm"
          handleClose={() => setOpenSubmitDrawRequestFormModal(false)}
          dialogContent={
            <SubmitDrawRequestForm
              onSubmit={handleSubmit}
              onCancel={() => setOpenSubmitDrawRequestFormModal(false)}
            />
          }
        />
      </Grid>
    )

  })