import {
  Box,
  Grid,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { AddIcCallRounded, Call, CheckCircle, Event } from '@material-ui/icons';
import {
  DialogState,
  EditableDialog,
  Button,
  StandardDialog,
  StatusChip,
  StatusType,
  Tooltip,
} from '@roc/ui';
import { formatCurrency, formatPercentage } from '@roc/ui/utils';
import { observer } from 'mobx-react';
import { CustomCard } from '../customCard';
import { LoanDetailsProgressBar } from '../loanDetailsProgressBar';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { PopupModal } from 'react-calendly';
import { useEffect, useState } from 'react';
import { useLoanStore } from '@roc/feature-loans';
import { useLoanFlags } from '../../hooks/useLoanFlags';
import { LoanParticipantsModal } from '@roc/feature-loan-participants';

const useStyles = makeStyles((theme: Theme) => ({
  label: {
    fontSize: '16px',
    fontWeight: 'bold',
  },
  value: {
    fontSize: '16px',
    fontWeight: 'normal',
  },
}));

const NONE = 'None';
const SCHEDULE_A_CALL = 'Schedule a call'

export const LoanContactsCard = observer(() => {
  const classes = useStyles();
  const { loanStore } = useLoanStore();
  const { loanDetails } = loanStore;
  const [showEditDialog, setShowEditDialog] = useState(false);
  const { showEditContacts } = useLoanFlags();

  const loanId = loanStore.loanDetails?.loanId;
  const calendlyPreferences = loanStore.calendlyPreferences;
  const prefill = calendlyPreferences.calendlyPrefill;
  const showCalendly = calendlyPreferences.showCalendly;

  useEffect(() => {
     loanStore.getCalendlyPreferences(loanId);
  }, []);

  return (
    <CustomCard
      onEdit={() => setShowEditDialog(true)}
      editButtonProps={{
        disabled: !showEditContacts,
        style: {
          border: '1px solid rgba(0, 0, 0, 0.87)',
          borderRadius: '50%',
          fontSize: '1.5rem',
          marginBottom: '-60px',
          padding: '3px',
          marginRight: '20px',
        }
      }}
      cardContentProps={{
        style: {
          overflow: 'auto',
          paddingTop: 0,
          paddingBottom: 16
        }
      }}
    >
      <Box minWidth={1100}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Typography className={classes.label}>Underwriter:</Typography>
            <Box display="flex" alignItems="center">
              <Typography className={classes.value}>
                {loanDetails?.loanUnderwriter?.name ?? NONE}
              </Typography>
              <CalendlyWidget calendlyUrl={calendlyPreferences.calendlyUsers[loanDetails?.loanUnderwriter?.userId]?.calendlyUrl}  prefill={prefill} showCalendly={showCalendly}/>
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Typography className={classes.label}>Loan Coordinator:</Typography>
            <Box display="flex" alignItems="center">
               <Typography className={classes.value}>
                  {loanDetails?.loanCoordinator?.name ?? NONE}
                </Typography>
                <CalendlyWidget calendlyUrl={calendlyPreferences.calendlyUsers[loanDetails?.loanCoordinator?.userId]?.calendlyUrl}  prefill={prefill} showCalendly={showCalendly}/>
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Typography className={classes.label}>Originator</Typography>
            <Typography className={classes.value}>
              {loanDetails?.lenderOriginatorName ?? NONE}
            </Typography>

          </Grid>
          <Grid item xs={3}>
            <EditParticipantsModal
              open={showEditDialog}
              onClose={() => setShowEditDialog(false)}
            />
          </Grid>
        </Grid>
      </Box>
    </CustomCard>
  );
});

const CalendlyWidget = observer(({ calendlyUrl, prefill, showCalendly }) => {

  const [isCalendlyModalOpen, setIsCalendlyModalOpen] = useState(false);

  return (
    <>
      {showCalendly && calendlyUrl &&
        <Box pl={1}>
          <Tooltip title="Schedule a call">
            <Button
              testId="schedule-calendly-call"
              size="small"
              variant='text'
              unsetTextTransfrom
              startIcon={<Event />}
              color='primary'
              style={{ marginTop: '-50%', marginBottom: '-50%' }}
              onClick={() => setIsCalendlyModalOpen(true)}
            >
              {SCHEDULE_A_CALL}
            </Button>
          </Tooltip>
          <PopupModal
            url={`https://calendly.com/${calendlyUrl}`}
            prefill={prefill}
            onModalClose={() => setIsCalendlyModalOpen(false)}
            open={isCalendlyModalOpen}
            rootElement={document.getElementById('root')}
          />
        </Box>
      }
    </>
  );
});

const EditParticipantsModal = observer(({ open, onClose }) => {
  const { loanStore } = useLoanStore();
  const { loanDetails } = loanStore;

  useEffect(() => {
    if (open) {
      loanStore.getLoanParticipants();
      loanStore.loanParticipantsStore.setParticipants(loanDetails);
    }
  }, [open]);

  const handleClose = () => {
    loanStore.loanParticipantsStore.setParticipants(loanDetails);
    onClose();
  };
  const handleSubmit = () => {
    loanStore.saveLoanParticipants();
    onClose();
  };

  const dialogContent = (
    <>
      <LoanParticipantsModal
        data={loanDetails}
        store={loanStore.loanParticipantsStore}
      />
    </>
  );

  return (
    <EditableDialog
      title={'Loan Contacts'}
      dialogState={DialogState.EDIT}
      open={open}
      handleClose={handleClose}
      onClose={handleClose}
      dialogContent={dialogContent}
      handleEdit={handleSubmit}
      editButtonText={'Submit'}
    />
  );
});
