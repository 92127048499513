import { Page } from '@roc/feature-app-core';
import { Route } from 'react-router';
import { UpdateEmailSettingsScreen } from '../components/updateEmailSettingsScreen';


export const getEmailSettingsRoutesConfig = (basePath: string, baseUrl: string) => {
  return {
    emailSettings: {
      path: `${basePath}/email-settings`,
      url: `${baseUrl}/email-settings`,
      documentTitle: 'General Email Settings',
    }
  };
}

export const getEmailSettings = (basePath: string, baseUrl: string) => {
  const config = getEmailSettingsRoutesConfig(basePath, baseUrl);
  return {
    emailSettings: (
      <Route exact path={config.emailSettings.path}>
        <Page routeDefinition={config.emailSettings}>
          <UpdateEmailSettingsScreen />
        </Page>
      </Route>
    )
  };
}