import { FormStore } from '@roc/feature-app-core';

const form = {
  fields: {
    firstName: {
      value: '',
      error: null,
      rule: 'required',
      message: 'First Name is a required field.',
    },
    lastName: {
      value: '',
      error: null,
      rule: 'required',
      message: 'Last Name is a required field.',
    },
    phoneNumber: {
      value: '',
      error: null,
      rule: 'required|phone',
      message: 'Phone Number is a required field.',
    },
    email: {
      value: '',
      error: null,
      rule: 'required|email',
      message: 'Email is a required field.',
    },
    comments: {
      value: '',
      error: null,
    },
    inspectionProcess: {
      value: '',
      error: null,
      rule: '',
    }
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export class PointOfContactFormStore extends FormStore {
  constructor(globalStore) {
    super({ ...form }, globalStore);
  }

  resetStore() {
    this.reset();
    this.form.fields.firstName.value = '';
    this.form.fields.lastName.value = '';
    this.form.fields.email.value = '';
    this.form.fields.phoneNumber.value = '';
    this.form.fields.comments.value = '';
    this.form.fields.inspectionProcess.value = '';
  }
}
