import { Box, Grid, Typography, List, ListItem, ListItemText, Divider, Menu, MenuItem, IconButton, ListItemIcon } from '@material-ui/core';
import { DataGrid as HistoryStageGrid, AgGridColumnProps, Button, FileUpload } from '@roc/ui';
import { makeStyles } from '@material-ui/core/styles';
import { StageItem } from './stageItem';
import { useState } from 'react';
import { observer } from 'mobx-react';
import { formatDate } from '@roc/feature-utils';
import { useStore } from '@roc/client-portal-shared/hooks';


const useStyles = makeStyles(theme => ({
  disabled: {
    color: "#AAA",
  },
  stageDate: {
    fontSize: "14px",
    letterSpacing: "1px",
  },
  verticalLine: {
    width: "2px",
    height: "18px",
    backgroundColor: "rgb(209,213,218)",
    marginLeft: "40px",
  },
  container: {
    height: "100%",
  },
  container2: {
    height: "95%",
  },
  borderContainer: {
    border: "1px solid #DDD",
    borderRadius: "5px",
    height: "100%",
  },
  leftColumn: {
    padding: theme.spacing(1),
    overflowY: "auto",
    maxHeight: "100%",
  },
  rightColumn: {
    padding: theme.spacing(2),
    borderLeft: "1px solid #DDD",
    overflowY: "auto",
    maxHeight: "100%",
  },
  buttons: {
    paddingTop: theme.spacing(2),
    flexDirection: "row",
    justifyContent: "center",
    paddingRight: "8px",
    marginBottom: '12px',
  },
  buttonSave: {
    marginLeft: 24,
  },
}));

export const ForeclosureStageList = observer(({ loanId, store, handleClose, handleSave }) => {
  const classes = useStyles();
  const [stages, setStages] = useState(store.listForeclosureStages);
  const selectedStageIndex = stages.findLastIndex((s) => s.stageSelected);
  const noOpComparator = () => 0;
  const { globalStore } = useStore();

  const setSelectedStage = (stage) => {
    const selectedI = stages.indexOf(stage);
    setStages(
      stages.map((item, i) => ({
        ...item,
        stageSelected: i === selectedI,
        stageDate: i <= selectedI ? item.stageDate : null,
      }))
    )
  };

  const setStageDate = (stage, date) => {
    const updatedStages = [...stages];
    updatedStages[stages.indexOf(stage)] = {
      ...stage,
      stageDate: date,
    };
    setStages(updatedStages);
  };

  const uploadMilestoneFile = async (stageName, uploads: FileUpload[]) => {
    await store.uploadMilestoneFile(loanId, stageName, uploads);
    setStages(store.listForeclosureStages);
  }
  const _handleSave = () => {
    if (stages[selectedStageIndex].stageDate) {
      handleSave(stages)
    } else {
      globalStore.notificationStore.showErrorNotification({
        message: 'Please provide a date for the selected stage',
      });
    }
  }

  const invoiceColumns: AgGridColumnProps[] = [
    {
      field: 'stageName',
      headerName: 'Foreclosure Stage',
      minWidth: 100,
      comparator: noOpComparator,
      sortable: false,
    },
    {
      field: 'stageDate',
      headerName: 'Date Of Stage',
      minWidth: 100,
      comparator: noOpComparator,
      sortable: false,
    },
  ];

  return (
    <Grid container className={classes.container}>

      <Grid item xs={12} md={12} className={classes.container2}>
        <Grid container className={classes.borderContainer}>
          <Grid item xs={4} className={classes.leftColumn}>
            {stages.map((stage, i) => (
              <>
                {i !== 0 && <Box className={classes.verticalLine}></Box>}
                <StageItem
                  stage={stage}
                  stageNumber={i + 1}
                  pastStage={i < selectedStageIndex}
                  currentStage={i === selectedStageIndex}
                  onSelect={() => setSelectedStage(stage)}
                  onDateChange={(date) => setStageDate(stage, date)}
                  onUploadFile={uploadMilestoneFile}
                />
              </>
            ))}
          </Grid>

          <Grid item xs={8} className={classes.rightColumn}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <Typography variant="h5">Foreclosure History</Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <HistoryStageGrid
                  columns={invoiceColumns}
                  rows={stages.slice(0, selectedStageIndex + 1)}
                  domLayout="autoHeight"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={12} className={classes.container}>
        <Grid container spacing={2} className={classes.buttons}>
          <Grid item >
            <Button
              testId="claseForeclosureStages"
              variant="outlined"
              size="small"
              color="primary"
              onClick={() => handleClose()}
            >
              CLOSE
            </Button>
            <Button
              className={classes.buttonSave}
              testId="updateForeclosureStages"
              variant="contained"
              size="small"
              color="primary"
              disabled={false}
              onClick={() => _handleSave()}
            >
              SAVE
            </Button>
          </Grid>
        </Grid>
      </Grid>

    </Grid>

  );
});