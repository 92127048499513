import { GridColumnProps } from '@roc/client-portal-shared/components';
import { formatCurrency } from '@roc/ui/utils';

const noOpComparator = () => 0;

export const mailingListsColumns: GridColumnProps[] = [
  {
    field: 'email',
    headerName: 'Email Address',
    minWidth: 225,
  },
  {
    field: 'loanStatus',
    headerName: 'Loan Status',
    minWidth: 100,
    comparator: noOpComparator,
    cellRenderer: 'checkboxCellRenderer',
  },
  {
    field: 'dailyReport',
    headerName: 'Daily Report',
    minWidth: 100,
    comparator: noOpComparator,
    cellRenderer: 'checkboxCellRenderer',
  },
  {
    field: 'extensions',
    headerName: 'Extensions',
    minWidth: 100,
    comparator: noOpComparator,
    cellRenderer: 'checkboxCellRenderer',
  },
  {
    field: 'wireConfirm',
    headerName: 'Wire Confirm',
    minWidth: 100,
    comparator: noOpComparator,
    cellRenderer: 'checkboxCellRenderer',
  },
  {
    field: 'email',
    headerName: 'Actions',
    minWidth: 75,
    cellRenderer: 'actionCellRenderer',
    comparator: noOpComparator,
  },
];
