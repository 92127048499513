import React, { useEffect } from 'react';
import { Box, Divider, Grid, List, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { Button, Paper } from '@roc/ui';
import { observer } from 'mobx-react';
import { Alert, AlertTitle } from '@material-ui/lab';
import { useStore } from '../../hooks';
import CustomListItem from '../loanTerms/components/customListItem';
import { formatCurrency } from '@roc/ui/utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      margin: '0 auto',
      borderRadius: theme.spacing(2),
      padding: theme.spacing(3, 0),
      boxShadow:
        'rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px',
    },
    textBold: {
      fontWeight: 700,
    },
    textItalic: {
      fontStyle: 'italic',
    },
    totalCost: {
      marginTop: '20Px',
    },
  })
);

interface Props {
  handleNextStep: () => void;
  entityId: string;
  tamariskFlowType: string;
  setShowBanner?: any;
}

export const InformationStep = observer((props: Props) => {
  const { appraisal1004dPaymentStore } = useStore();
  const { paper, textBold } = useStyles();
  const appraisal1004dDocumentStore = appraisal1004dPaymentStore.appraisal1004dDocumentStore;
  const { loading, form } = appraisal1004dDocumentStore;
  const {setShowBanner} = props;

  const divider = <Divider variant="middle" component="li" />;

  const renderPaymentDetails = () => (
    <Box mt={3}>
      <Box mt={6}>
        <Paper className={paper}>
          <Box mb={1} px={2}>
            <Typography className={textBold} variant="subtitle1">
              Cost Details
            </Typography>
          </Box>
          <List>
            <CustomListItem
              key='price'
              label='Price'
              value={`$${appraisal1004dDocumentStore.totalAppraisalCost}`}
            />
            {/* <CustomListItem
              key='timeframe'
              label='Delivery Timeframe'
              value={`${appraisal1004dDocumentStore.businessDays} business days`}
            /> */}
            {appraisal1004dDocumentStore.properties?.map((prop) => (
              <React.Fragment key={prop.description}>
                {(prop.address && prop.description === 'Appraisal Fee') && (
                  <CustomListItem
                    key={prop.address}
                    label={prop.address + (prop.asIsValue ? ` (As-Is Value ${formatCurrency(prop.asIsValue)})` : '')}
                    value=""
                  />
                )}
                <div style={{ marginLeft: '20px' }}>
                  <CustomListItem
                    key={prop.description}
                    label={`${prop.description} ${prop.alreadyPaid ? '(already paid)' : ''
                      }`}
                    value={`$${prop.appraisalCost}`}
                  />
                </div>
              </React.Fragment>
            ))}
            {divider}
            <CustomListItem
              key="Total"
              label="Total ARR Cost"
              value={`$${appraisal1004dDocumentStore.totalAppraisalCost}`}
            />
          </List>
        </Paper>
      </Box>
    </Box>
  );

  const renderErrorMessage = () => {
    return (
      <Grid item xs={12}>
        <Alert severity="error">
          <AlertTitle>Warning</AlertTitle>
          {`${appraisal1004dDocumentStore.feeCalcError}`}
        </Alert>
      </Grid>
    )
  }

  const renderLoading = () => {
    return (
      <Grid item xs={12}>
        <Typography variant="subtitle1">
          Loading...
        </Typography>
      </Grid>
    )
  }

  useEffect(() => {
    appraisal1004dDocumentStore.getLoanInfoForARRPaymentLink(
      props.entityId,
      form.fields.fullName.value,
      setShowBanner
    );
  }, []);

  return (
    <>
      <Box style={{ maxWidth: 840, margin: 'auto' }}>
        <Box>
          <Grid container justifyContent="flex-start" spacing={2}>
            <Box mb={2}>
              <Typography variant="h4">1004D Order</Typography>
            </Box>
          </Grid>
        </Box>
        {
          loading ? renderLoading() : (appraisal1004dDocumentStore.feeCalcError ? renderErrorMessage() : renderPaymentDetails())
        }
        <Box mt={16}>
          <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={6} md={3}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={props.handleNextStep}
                testId="next"
                disabled={(appraisal1004dDocumentStore.feeCalcError || loading) ? true : false}
              >
                {appraisal1004dPaymentStore.paymentStatus ? 'Next' : 'Next'}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
});
