import { Document } from '@roc/feature-types';
import { useDocumentStore } from 'libs/feature-documents/src/documents/hooks/useDocumentStore';
import { useEffect } from 'react';
import { FastTrackModal } from '@roc/feature-loans'
import { LoanStatus } from '@roc/feature-utils';
import { observer } from 'mobx-react';
import { useBaseStore } from '@roc/feature-app-core';
import AddtionalFastTrackData from './AdditionalFastTrackData';

export type LoanFastTrackPaymentDialogProps = {
  open?: boolean;
  onClose?: () => void;
  loan: any;
  document: Document;
};

const fastTrackElegibleLoanStatuses = [
  LoanStatus.UNKNOWN,
  LoanStatus.PENDING,
  LoanStatus.TERM_SHEET_OUT,
  LoanStatus.IN_UNDERWRITING,
  LoanStatus.PRESCREEN,
  LoanStatus.PENDING_DECISION,
  LoanStatus.PENDING_TERM_SHEET,
  LoanStatus.AGREED,
  LoanStatus.PENDING_SUBMISSION
];

const LoanFastTrackPaymentDialog = observer((props: LoanFastTrackPaymentDialogProps) => {
  const { open, onClose, loan } = props;

  const { documentStore } = useDocumentStore();
  const { globalStore } = useBaseStore();
  const { documentFormStore, closingDocuments, underwritingDocuments } = documentStore;
  const { loanFastrackPaymentStore, borrowerClosingAgentStore } = documentFormStore;
  const { canEdit: canEditBorrowerInfo } = borrowerClosingAgentStore;

  if (!globalStore.userFeatures || !globalStore.userFeatures.fastTrack) {
    return <></>;
  }

  useEffect(() => {
    loanFastrackPaymentStore.reset();
    loanFastrackPaymentStore.loadFastTrackFees();
    if (loan?.fastTrack) {
      loanFastrackPaymentStore.loadFastTrackInfo(loan?.loanId);
    }
  }, [])

  useEffect(() => {
    if (open && !loan?.fastTrack) {
      loanFastrackPaymentStore.fetchDraftLoanId();
    }
  }, [open]);

  const onSubmitBorrowerInfo = () => {
    const docs = closingDocuments['Borrower Closing Agent'];
    if (docs.length) {
      borrowerClosingAgentStore.submitBorrowerAgent(docs[0]);
    }
  }

  const onSubmitTitleInfo = () => {
    const doc = closingDocuments['Title']?.find(doc => doc.documentName === 'Title Provider Information');
    if (doc) {
      loanFastrackPaymentStore.submitTitleInfo(doc);
    }
  }

  const onSubmit = () => {
    onSubmitBorrowerInfo();
    onSubmitTitleInfo();
  }

  const borrowerList = (borrowers) => {
    return borrowers.map(borrower => {
      return {
        ...borrower,
        roleInTransaction: ''
      };
    });
  }
  return (loanFastrackPaymentStore.loanFastTrackInfo?.paymentStatus) ?
    (
      <AddtionalFastTrackData
        open={open}
        onClose={onClose}
        fastTrackFees={loanFastrackPaymentStore.fastTrackFees}
        fastTrackInfo={loanFastrackPaymentStore.loanFastTrackInfo}
        canEditBorrowerInfo={canEditBorrowerInfo}
        onSubmit={onSubmit}
        properties={loan?.properties}
        underwritingDocuments={underwritingDocuments || {}}
        closingDocuments={closingDocuments || {}}
        onDocumentUpload={(file: any, docRef: any, loanProcess: any, onSuccess?: () => any, drawId?: any) => documentStore.uploadDocument(file, docRef, loanProcess, onSuccess, drawId)}
        loanFastrackPaymentStore={loanFastrackPaymentStore}
        loan={loan}
      />
    ) :
    (
      fastTrackElegibleLoanStatuses.includes(loan?.status) && (<FastTrackModal
        isOpen={open}
        onClose={onClose}
        onUpload={(fileUploads) => loanFastrackPaymentStore.uploadInsuranceDocument(fileUploads[0])}
        onConfirm={options => {
          loanFastrackPaymentStore.updateLoanFastrackInfo(options, loan?.loanId);
        }}
        sendPaymentEmail={() => loanFastrackPaymentStore.requestFastTrackPaymentEmail({
          loanId: loanFastrackPaymentStore.loanFastTrackInfo?.randomLoanId || loanFastrackPaymentStore.draftLoanId,
          borrowers: borrowerList(loan?.borrowers),
        })}
        options={loanFastrackPaymentStore.loanFastTrackInfo || { insuranceReview: false, titleSearch: false, insurance: false, rushedAppraisal: false, titleAttorneySearch: false }}
        prefInsurance={loan?.loanInsurance?.insuredThroughElmsure ? 'Elmsure' : ''}
        prefTitle={loan?.titleCompany?.name?.includes('Wimba') ? 'Wimba' : ''}
        fastTrackFees={loanFastrackPaymentStore.fastTrackFees}
        propertiesQuantity={loan?.properties?.length}
        paymentUrl={loanFastrackPaymentStore.fastTrackPaymentUrl}
        readOnly={loanFastrackPaymentStore.loanFastTrackInfo?.paymentStatus || !fastTrackElegibleLoanStatuses.includes(loan?.status)}
        canSubmitAppraisalWithTamarisk={loanFastrackPaymentStore.canSubmitAppraisalWithTamarisk}
      />
      ));
});

export default LoanFastTrackPaymentDialog;
