import * as React from 'react';
import * as ReactIs from 'react-is';
import { ConfigInterface, defaultConfig } from './config';
import { TestDataAttributeContext } from './testDataAttributeContext';

interface TestDataAttributeProps extends ConfigInterface {
  id: string;
  component?: 'div' | 'span';
}

export const TestDataAttribute: React.FC<TestDataAttributeProps> = ({
  children,
  ...props
}) => {
  const config = Object.assign(
    {},
    defaultConfig,
    React.useContext(TestDataAttributeContext),
    props
  );

  function withTestAttribute(nodes: React.ReactNode): React.ReactNode {
    const node = React.Children.only(nodes);
    const testAttributeName = `data-${config.suffix}`;

    // TODO: ReactIs.isFragment(node) is coming as false for most of the functional components.
    // Use <TestDataAttribute> directly instead of using withTestDataAttribute() in certain cases.
    if (ReactIs.isFragment(node)) {
      return React.createElement(
        config.component ?? 'div',
        { [testAttributeName]: config.id },
        node
      );
    } else if (ReactIs.isElement(node)) {
      return React.cloneElement(node as React.ReactElement, {
        [testAttributeName]: config.id,
      });
    } else {
      return node;
    }
  }

  return (
    <React.Fragment>
      {config.enable ? withTestAttribute(children) : children}
    </React.Fragment>
  );
};

export interface withTestDataAttributeProps {
  testId: string;
}

export const withTestDataAttribute = <P extends object>(
  Component: React.ComponentType<P>
): React.FC<P & withTestDataAttributeProps> => ({
  testId,
  ...props
}: withTestDataAttributeProps) => {
  return (
    <TestDataAttribute id={testId}>
      <Component {...(props as P)} />
    </TestDataAttribute>
  );
};
