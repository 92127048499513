import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { Box, Typography, Grid, Theme } from '@material-ui/core';
import {
  LocalPhone,
  Mail,
} from '@material-ui/icons';
import {
  useAppConfigurations,
} from '@roc/feature-app-core';
import { useHistory } from 'react-router-dom';
import { Button } from '@roc/ui';
import { observer } from 'mobx-react';
import { useRoutes } from '@roc/client-portal-shared/hooks';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    centerText: {
      textAlign: 'center',
    },
    spacedTypography: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    contactLine: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },
    contactIcon: {
      marginRight: '8px',
      fontSize: '1.40rem',
    },
  })
);

export interface LoanSuccessProps {
  loanRequestId: number;
  showRentalsEmail?: boolean;
  onStartAnotherLoan?: () => void;
}

const ContactUs = observer(({ showRentalsEmail }) => {
  const classes = useStyles();
  const { data } = useAppConfigurations();

  return (
    <>
      {(data.phoneNumber || data.rentalsEmail) && (
        <Box className={classes.centerText}>
          <Typography className={classes.spacedTypography} variant="body1">
            If you need further assistance, please contact us at:
          </Typography>
          <Typography className={classes.spacedTypography} variant="h6">
            <div className={classes.contactLine}><LocalPhone className={classes.contactIcon} />{data.phoneNumber}</div>
          </Typography>
          {showRentalsEmail && (
            <Typography className={classes.spacedTypography} variant="h6">
              <div className={classes.contactLine}><Mail className={classes.contactIcon} />{data.rentalsEmail}</div>
            </Typography>
          )}
        </Box>
      )}
    </>
  );
});



export function LoanRequestSuccess(props: LoanSuccessProps) {
  const {
    loanRequestId,
    showRentalsEmail = false,
  } = props;
  const classes = useStyles();
  const history = useHistory();
  const { dashboardRoutesConfig } = useRoutes();
  const dashboardURL = dashboardRoutesConfig.dashboard.url;

  return (
    <Grid container justifyContent="space-around">
      <Grid item xs={12}>
        <Box className={classes.centerText}>
          <Typography variant="h5">
            Your Loan Request Was Successfully Submitted!
          </Typography>
        </Box>
        <Box className={classes.centerText}>
          <Typography className={classes.spacedTypography} variant="body1">
            Thank you for submitting your loan request.
          </Typography>
          {loanRequestId && (
            <>
              <Typography className={classes.spacedTypography} variant="body1">
                Your loan request is {loanRequestId}.
              </Typography>
            </>
          )}
        </Box>
      </Grid>
      <Grid
        container
        item
        xs={12}
        direction="row"
        justifyContent="center"
        spacing={2}
      >
        <Grid item xs={10} md={3}>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            onClick={() => props.onStartAnotherLoan()}
            testId="startAnotherLoanButton"
          >
            START ANOTHER LOAN
          </Button>
        </Grid>
        <Grid item xs={12} />
        <Grid item xs={10} md={3}>
          <Button
            variant="outlined"
            color="secondary"
            fullWidth
            onClick={() => history.push(dashboardURL)}
            testId="returnToHomeButton"
          >
            RETURN TO HOME
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <ContactUs showRentalsEmail={showRentalsEmail} />
      </Grid>
    </Grid>
  );
}
export default LoanRequestSuccess;
