// ----------------------------------------------------------------------

export default function Autocomplete(theme) {
  return {
    MuiAutocomplete: {
      paper: {
        boxShadow: theme.customShadows.z20,
      },
    },
  };
}
