import { Box, Grid, TextField, InputAdornment } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { isNotBlank } from '@roc/client-portal-shared/utils';
import MailIcon from '@material-ui/icons/Mail';
import { AutocompleteAddressField, MultiSelectField, Paper } from '@roc/ui';
import { observer } from 'mobx-react-lite';
import {
  createSelectField,
  createTextField,
} from '../../../components/formComponents/common/componentBuilder';
import { NextBackButtons } from '../../lenderDetails/components/lenderDetailsFormComponents';
import { residentialProductsOptions } from '../utils/constants';
import { CompanyProfileStore } from '../../companyProfile/stores/companyProfileStore';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(2),
  },
}));

const entityTypes = [
  {
    value: 'Corp',
    label: 'Corp',
  },
  {
    value: 'LLC',
    label: 'LLC',
  },
];

interface CompanyDetailsProps {
  companyProfileStore: CompanyProfileStore;
}

export const CompanyDetails = observer(({ companyProfileStore }: CompanyDetailsProps) => {
  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <Box p={4}>
        <Grid container spacing={2}>
          <CompanyDetailsForm companyProfileStore={companyProfileStore} />
          <Grid item xs={12}>
            <NextBackButtons
              renderNext
              companyProfileStore={companyProfileStore}
            />
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
});

interface CompanyDetailsFormProps {
  disabled?: boolean;
  showAvailableProducts?: boolean;
  companyProfileStore: CompanyProfileStore;
}

export const CompanyDetailsForm = observer(({
  disabled = false,
  showAvailableProducts = false,
  companyProfileStore
}: CompanyDetailsFormProps) => {
  const { lenderDetailsStore } = companyProfileStore;
  const { lenderDetailsFormStore } = lenderDetailsStore;

  const handleAddressChange = event => {
    const { value, fullInfo } = event?.target || {};
    const { address_components, geometry } = fullInfo || {};
    lenderDetailsFormStore.saveAddressField(
      value,
      address_components,
      geometry
    );
  };

  function createOptionsArray(options) {
    const values = options && options?.length > 0 ? options.map(option => ({
      label: option,
      value: option,
    })) : [];
    return values;
  }

  const formFields = lenderDetailsFormStore.form.fields;
  return (
    <Grid container justifyContent={'flex-start'} spacing={2}>
      <Grid item xs={12}>
        {createTextField({
          label: 'Company Name',
          testId: 'companyName',
          fieldName: 'companyName',
          store: lenderDetailsFormStore,
          disabled: disabled
        })}
      </Grid>
      <Grid item xs={12}>
        {createTextField({
          label: 'Trade Name',
          testId: 'lenderTradeName',
          fieldName: 'lenderTradeName',
          store: lenderDetailsFormStore,
          disabled: disabled
        })}
      </Grid>
      <Grid item xs={12}>
        {createTextField({
          label: 'Tax Id /  EIN # for the Participant Entity',
          testId: 'einNumber',
          fieldName: 'einNumber',
          store: lenderDetailsFormStore,
          disabled: disabled

        })}
      </Grid>
      <Grid item xs={12}>
        {createSelectField({
          options: entityTypes,
          label: 'Entity Type',
          testId: 'entityType',
          fieldName: 'entityType',
          store: lenderDetailsFormStore,
          disabled: disabled
        })}
      </Grid>
      <Grid item xs={12}>
        <AutocompleteAddressField
          label="Address"
          required={true}
          value={formFields.address.value}
          name="address"
          error={isNotBlank(formFields.address.error)}
          errorText={formFields.address.error}
          onChange={handleAddressChange}
          fullWidth
          testId="address"
          disabled={disabled}
          readOnly={disabled}
        />
      </Grid>
      <Grid item xs={12}>
        {createTextField({
          label: 'Email',
          testId: 'companyEmail',
          fieldName: 'email',
          type: 'email',
          store: lenderDetailsFormStore,
          disabled: disabled,
          InputProps: {
            startAdornment: (
              <InputAdornment position="start">
                <MailIcon />
              </InputAdornment>
            ),
          }
        })}
      </Grid>
      {showAvailableProducts &&
        <Grid item xs={12}>
          <MultiSelectField
            standaloneLabel
            label='What kind of products do you offer?'
            name={'residentialProducts'}
            disabled={disabled}
            options={createOptionsArray(residentialProductsOptions)}
            getOptionSelected={(option, selectedValue) => {
              return option.value == selectedValue.value;
            }}
            value={
              lenderDetailsFormStore.form.fields.products.value?.length
                ? lenderDetailsFormStore.form.fields.products.value
                : []
            }
            onChange={(e, value) => {
              lenderDetailsFormStore.onFieldChange('products', value);
            }}
            renderInput={params => (
              <TextField {...params} variant="outlined" disabled={disabled} />
            )}
            fullWidth
            testId="products"
          />
        </Grid>
      }
    </Grid>
  );
});
