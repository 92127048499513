import { parseDate } from '@roc/feature-utils';

export const mapEntityResponse = data => {
  return data.map(item => ({
    borrowerEntity: {
      borrowerEntityId: item.borrowerEntityId,
      name: item.company.name,
      type: item.type,
      ein: item.ein,
      operatingAgreementDate: parseDate(item.operatingAgreementDate),
      address: item.company.address,
      latitude: item.company.latitude,
      longitude: item.company.longitude,
      streetNumber: item.company.streetNumber,
      streetName: item.company.streetName,
      city: item.company.address,
      state: item.company.state,
      zipCode: item.company.zipCode,
    },
    borrowers: item.borrowers.map(row => ({
      borrowerId: row.borrower.borrowerId,
      firstName: row.borrower.firstName,
      lastName: row.borrower.lastName,
      emailAddress: row.borrower.emailAddress,
      confirmEmailAddress: row.borrower.emailAddress,
      cellPhone: (row.borrower.cellPhone ?? '')
        .replace(/[^\d]/g, '')
        .replace(/^(\d{3})(\d{3})(\d{4})$/, '$1-$2-$3'),
      pctOwnership: row.pctOwnership,
      experienceProvidedAtOrigination: row.borrower.experience,
      ficoProvidedAtOrigination: row.borrower.ficoProvidedAtOrigination,
    })),
  }));
};
