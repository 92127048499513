import { Grid, Typography } from "@material-ui/core";
import { DateFormat, formatDate, isNil } from "@roc/feature-utils";
import { createDateField, createHtmlField, createSelectField, EditableDialog } from "@roc/ui";
import { observer } from "mobx-react";
import { activityTypeOptions } from "../leads/constants/constants";
import { useStore } from "@roc/client-portal-shared/hooks";

export const EditLeadActivityDialog = observer(({ editLeadActivityStore }) => {
  const { leadActivity } = editLeadActivityStore;
  const { salesforceLeadStore } = useStore();

  const store = editLeadActivityStore;

  const dialogContent = (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {createSelectField({
          store,
          testId: 'activityType',
          fieldName: 'activityType',
          label: 'Activity Type',
          options: activityTypeOptions
        })}
      </Grid>
      <Grid item xs={12}>
        {createDateField({
          store,
          testId: 'activityTime',
          fieldName: 'activityTime',
          label: 'Activity Date',
        })}
      </Grid>
      <Grid item xs={12}>
        {leadActivity?.emailContent ? (
          createHtmlField({
            store,
            testId: 'emailContent',
            fieldName: 'emailContent',
            label: 'Comments',
            multiline: true,
            minRows: 5,
          })
        ) : (
          createHtmlField({
            store,
            testId: 'text',
            fieldName: 'text',
            label: 'Comments',
            multiline: true,
            minRows: 5,
          })
        )}
      </Grid>
      {leadActivity?.lastUpdatedDate && (
        <Grid item xs={12}>
          <Typography variant="subtitle2" display="block" align="right">
            Last updated at {formatDate(leadActivity?.lastUpdatedDate, DateFormat.MMDDYYYY)}
          </Typography>
        </Grid>
      )}
    </Grid>
  );

  return (
    <EditableDialog
      open={!isNil(editLeadActivityStore.dialogState)}
      dialogState={editLeadActivityStore.dialogState}
      handleClose={() => editLeadActivityStore.closeLeadActivityDialog()}
      handleAdd={() => editLeadActivityStore.saveLeadActivity(salesforceLeadStore.gridBusinessUnit)}
      handleEdit={() => editLeadActivityStore.saveLeadActivity(salesforceLeadStore.gridBusinessUnit)}
      title={'Lead Activity'}
      dialogContent={dialogContent}
    />
  )
})
