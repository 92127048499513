import { action, computed, flow, makeObservable, observable } from 'mobx';
import { GlobalStore } from '@roc/client-portal-shared/stores';

export abstract class LoanVolumeReportBaseStore {
  protected globalStore: GlobalStore;

  abstract fetchLoanVolumeReport(startDate: any, endDate: any, lender: any, relationshipManager: any, leadSource: any,
    monthly: any, quarterly: any, annually: any, haus: any, faco: any, rocCapital: any,
    filters: any, budgetFilters: any, cumulative: any);

  abstract fetchRelationshipManagerAndLenderMap();

  constructor(globalStore) {
    this.globalStore = globalStore;

    makeObservable(this, {
      fetchLoanVolumeReport: flow,
      fetchRelationshipManagerAndLenderMap: flow,
    });
  }


}