import React from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { CardHeader, Tooltip } from '@material-ui/core';
import { Edit, DeleteOutline } from '@material-ui/icons';
import { Button, Paper, IconButton, TestDataAttribute } from '@roc/ui';
import HelpIcon from '@material-ui/icons/Help';

import { green, red, yellow } from '@material-ui/core/colors';
import { Borrower, BorrowerCardDisplayFields } from '@roc/feature-types';
import { formatCurrency } from '@roc/ui/utils';
import { prequalificationStatusProperties, sanitizeTestId } from '@roc/feature-utils';

const BooleanChip = ({ value }) => {
  const color = value ? green : red;
  return (
    <Chip
      label={value ? 'Yes' : 'No'}
      style={{
        fontWeight: 0,
        backgroundColor: color[200],
        color: color[900],
      }}
    />
  );
};

const TextChip = ({ value }) => {
  return (
    <Chip
      label={value}
      style={{
        fontWeight: 0,
      }}
    />
  );
};

const RolesInTransaction = ({ items }) => {
  return (
    <Grid container spacing={1} justifyContent="flex-end">
      {items &&
        items.map(item => (
          <Grid item>
            <TextChip value={item.label} />
          </Grid>
        ))}
    </Grid>
  );
};

const TextFlagChip = ({ value, color }) => {
  return (
    <Box display="flex" alignItems="center" >
      <Chip
        label={value}
        style={{
          fontWeight: 0,
          backgroundColor: color[200],
          color: color[900],
        }}
      />
    </Box>
  );
}

const divider = <Divider variant="middle" component="li" />;

const formatPercentage = (value, decimals) =>
  `${value != null ? Number(value).toFixed(decimals) : ''}%`;

const useStyles = makeStyles(theme => ({
  paper: {
    margin: theme.spacing(3),
    marginLeft: theme.spacing(0),
    borderRadius: theme.spacing(2),
    boxShadow:
      'rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px',
  },
  preQualificationStatus: {
    padding: '0px 16px 8px 16px',
    display: 'flex',
    justifyContent: 'flex-end'
  }
}));

export interface BorrowerCardProps {
  borrower: Borrower;
  displayFields?: BorrowerCardDisplayFields;
  title?: string;
  onRemove?: (value: any) => void;
  onEdit?: (value: any) => void;
  largeCard: boolean;
  className?: string;
  id?: string | number;
}

export const BorrowerCard: React.FC<BorrowerCardProps> = ({
  id = '',
  largeCard,
  borrower,
  title,
  onEdit,
  onRemove,
  className,
  displayFields = {
    firstName: true,
    lastName: true,
    cellPhone: true,
    emailAddress: true,
    personalGuarantor: true,
    pctOwnership: true,
    experience: true,
    generalContractor: true,
    age: true,
    foreclosure: true,
    bankruptcy: true,
    roleInEntity: true,
    broker: true,
    netWorth: true,
    medianScore: true,
    ficoProvidedAtOrigination: true,
    citizenshipStatus: true,
    borrowerExp: false,
    fullName: true,
  },
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const {
    firstName,
    lastName,
    cellPhone,
    emailAddress,
    personalGuarantor,
    pctOwnership,
    experience,
    generalContractor,
    age,
    foreclosure,
    bankruptcy,
    roleInEntity,
    broker,
    netWorth,
    medianScore,
    ficoProvidedAtOrigination,
    citizenshipStatus,
    borrowerExp,
    roleInTransaction,
    preQualificationStatus,
  } = borrower;

  const listItem = (label, value, component?, divider: boolean = true, tooltip = '') => {
    return (
      <>
        {divider && <Divider variant="middle" component="li" />}
        <ListItem>
          <ListItemText>
            <Box display="flex">
              <Box display="flex" flexGrow={1} alignItems='center'>
                {label}
                {tooltip && (
                  <Tooltip title={tooltip} placement='top'>
                    <span
                      style={{ cursor: 'pointer', marginLeft: '8px', display: 'flex' }}
                    >
                      <HelpIcon style={{ color: theme.palette.primary.main }} fontSize="small" />
                    </span>
                  </Tooltip>
                )}
              </Box>
              <span />
              <TestDataAttribute id={sanitizeTestId(`${label}-${id}`)}>
                <Box>{component || value}</Box>
              </TestDataAttribute>
            </Box>
          </ListItemText>
        </ListItem>
      </>
    );
  };

  const renderFirstSet = () => (
    <>
      {displayFields.fullName &&
        listItem('Full Name', `${firstName} ${lastName}`, null, false)}
      {displayFields.firstName &&
        listItem('First Name', firstName, null, false)}
      {displayFields.lastName && listItem('Last Name', lastName)}
      {displayFields.cellPhone && listItem('Phone Number', cellPhone)}
      {displayFields.emailAddress && listItem('Email', emailAddress)}
      {displayFields.personalGuarantor &&
        listItem(
          'Personal Guarantee',
          personalGuarantor,
          <BooleanChip value={personalGuarantor} />
        )}
      {displayFields.roleInEntity && listItem('Role in Entity', roleInEntity)}
      {displayFields.pctOwnership &&
        listItem(
          'Percentage of Ownership',
          pctOwnership || borrower?.percentOwnershipCurrent,
          formatPercentage(pctOwnership || borrower?.percentOwnershipCurrent, 0)
        )}
      {displayFields.ficoProvidedAtOrigination &&
        listItem('Qualifying FICO Score', ficoProvidedAtOrigination)}
    </>
  );

  const renderSecondSet = () => (
    <>
      {displayFields.age && listItem('Age', age, null, false)}
      {displayFields.citizenshipStatus &&
        listItem('Citizenship Status', citizenshipStatus)}
      {displayFields.foreclosure && listItem('Foreclosure', foreclosure)}
      {displayFields.generalContractor &&
        listItem(
          'General Contractor',
          generalContractor,
          <BooleanChip value={generalContractor} />
        )}
      {displayFields.broker &&
        listItem('Broker', broker, <BooleanChip value={broker} />)}
      {displayFields.netWorth &&
        listItem('Net Worth', netWorth && formatCurrency(netWorth))}
      {displayFields.medianScore && listItem('Credit', medianScore)}
      {displayFields.bankruptcy && listItem('Bankruptcy', bankruptcy)}
    </>
  );

  return (
    <>
      <Grid item xs={12}>
        <Box mt={1}>
          <Paper title={title} className={className ?? classes.paper} testId={`borrower-title-${id}`}>
            {onEdit || onRemove ? (
              <CardHeader
                action={
                  <>
                    {onEdit && (
                      <IconButton
                        testId="edit-borrower"
                        onClick={() => onEdit(borrower)}
                      >
                        <Edit color="primary" />
                      </IconButton>
                    )}
                    {onRemove && (
                      <IconButton
                        testId="remove-borrower"
                        onClick={() => onRemove(borrower)}
                      >
                        <DeleteOutline color="primary" />
                      </IconButton>
                    )}
                  </>
                }
              />
            ) : null}
            <Grid container direction="row" spacing={4}>
              <Grid item xs={12} md={largeCard ? 6 : 12}>
                <List>
                  {renderFirstSet()}
                  {!largeCard && (
                    <>
                      <Divider variant="middle" component="li" />
                      {renderSecondSet()}
                    </>
                  )}
                </List>
              </Grid>
              {largeCard && (
                <Grid item xs={12} md={largeCard ? 6 : 12}>
                  <List>{renderSecondSet()}</List>
                </Grid>
              )}
            </Grid>
          </Paper>
        </Box>
      </Grid>
    </>
  );
};

export default BorrowerCard;
