import { observer } from 'mobx-react';
import { Grid, Typography } from '@material-ui/core';
import {
  createNumberFormatField,
  createPhoneField,
  SelectField,
  createTextField,
} from '@roc/ui';
import { bankInformationPropertyStates, isNotBlank } from '@roc/feature-utils';
import { Tooltip } from '@roc/ui';
import HelpIcon from '@material-ui/icons/Help';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { InputAdornment } from '@material-ui/core';
import MailIcon from '@material-ui/icons/Mail';

const useStyles = makeStyles((theme: Theme) => {
  return {
    list: {
      padding: '0px',
    },
    netWorth: {
      color: '#48AD25',
      lineHeight: '56px',
    },
    icon: {
      fontSize: '18px',
      marginLeft: '5px',
      marginBottom: '2px',
    },
  };
});

export const LenderBankInformationDetailsForm = observer(({ store }) => {
  const { verifyBankInformationDocusignFormStore } = store;
  const classes = useStyles();
  return (
    <>
      <Grid container xs={12} sm={12} direction='row'>
        <Typography variant="h5">
          Account Details
          <Tooltip title="Please verify the account details to ensure accuracy before submitting">
            <HelpIcon className={classes.icon} fontSize="small" />
          </Tooltip>
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        {createTextField({
          label: 'Account Name/Title',
          fieldName: 'accountName',
          testId: 'accountName',
          store: verifyBankInformationDocusignFormStore,
        })}
      </Grid>
      <Grid item xs={12} sm={6}>
        {createNumberFormatField({
          label: 'ABA (FedWire) Routing Number:',
          fieldName: 'routingNumber',
          format: "#########",
          testId: 'routingNumber',
          store: verifyBankInformationDocusignFormStore,
        })}
      </Grid>
      <Grid item xs={12} sm={6}>
        {createNumberFormatField({
          label: 'Account Number:',
          fieldName: 'accountNumber',
          testId: 'accountNumber',
          store: verifyBankInformationDocusignFormStore,
        })}
      </Grid>
      <Grid item xs={12} sm={6}>
        {createTextField({
          label: 'Bank Name',
          fieldName: 'bankName',
          testId: 'bankName',
          store: verifyBankInformationDocusignFormStore,
        })}
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5">Contact Information</Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        {createTextField({
          label: 'Full Name',
          fieldName: 'fullName',
          testId: 'fullName',
          store: verifyBankInformationDocusignFormStore,
        })}
      </Grid>
      <PhoneNumber store={verifyBankInformationDocusignFormStore} />
      <Grid item xs={6}>
        {createTextField({
          label: 'Email',
          testId: 'emailAddress',
          fieldName: 'emailAddress',
          type: 'email',
          store: verifyBankInformationDocusignFormStore,
          InputProps: {
            startAdornment: (
              <InputAdornment position="start">
                <MailIcon />
              </InputAdornment>
            ),
          },
        })}
      </Grid>
      <Grid item xs={12} sm={6}>
        {createTextField({
          label: 'Street Name',
          fieldName: 'streetName',
          testId: 'streetName',
          store: verifyBankInformationDocusignFormStore,
        })}
      </Grid>
      <Grid item xs={12} sm={6}>
        {createTextField({
          label: 'City',
          fieldName: 'city',
          testId: 'city',
          store: verifyBankInformationDocusignFormStore,
        })}
      </Grid>
      <Grid item xs={12} sm={6}>
        {createTextField({
          label: 'Zip Code',
          fieldName: 'zipCode',
          testId: 'zipCode',
          store: verifyBankInformationDocusignFormStore,
        })}
      </Grid>
      <Grid item xs={12} sm={6}>
        <SelectField
          standaloneLabel
          label={'State'}
          name={'state'}
          value={verifyBankInformationDocusignFormStore.form.fields.state.value}
          error={isNotBlank(
            verifyBankInformationDocusignFormStore.form.fields.state.error
          )}
          errorText={
            verifyBankInformationDocusignFormStore.form.fields.state.error
          }
          options={bankInformationPropertyStates}
          onChange={label =>
            verifyBankInformationDocusignFormStore.onFieldChange('state', label)
          }
          variant="outlined"
          fullWidth
          shrink
          required
          testId="state"
        />
      </Grid>
    </>
  )
});

export const PhoneNumber = observer(({ store }) => {
  return <Grid item xs={12} sm={6}>
    {createPhoneField({
      label: 'Phone Number',
      fieldName: 'phoneNumber',
      testId: 'phoneNumber',
      store: store,
    })}
  </Grid>;
});