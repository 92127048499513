import { Grid, Divider } from '@material-ui/core';
import { observer } from 'mobx-react';
import { createPercentageField, PercentageField } from '@roc/ui';
import { Alert, AlertTitle } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: '48px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '116.7%',
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
  subTitle: {
    paddingLeft: theme.spacing(2),
  },
  body: {
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '175%',
    letterSpacing: '0.15px',
    paddingLeft: theme.spacing(2),
  },
  entityInfoTitle: {
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '166%',
    letterSpacing: '0.4px',
  },
  gridItem: {
    marginLeft: theme.spacing(4),
  },
  totalPercentageOwnership: {
    color: theme.palette.text.primary,
    '& .MuiInputBase-root': {
      backgroundColor: '#F0F0F0',
    },
    '& .MuiInputBase-root.Mui-error': {
      borderColor: theme.palette.error.main,
      backgroundColor: 'rgb(255, 236, 236)',
    },
  },
}));

export const BorrowerEntityOwnershipStep = observer(({ store }) => {
  const classes = useStyles();
  const { borrowerInformationStore } = store;
  const {
    totalOwnership,
    isTotalOwnershipExceedingLimit,
  } = borrowerInformationStore;

  const TotalOwnershipField = ({ totalOwnership, error }) => {
    const classes = useStyles();

    return (
      <PercentageField
        label={'Total Entity Ownership Between Borrowers'}
        value={totalOwnership}
        testId={'pctOwnership'}
        variant="outlined"
        disabled
        fullWidth
        inputClassname={classes.totalPercentageOwnership}
        error={error}
      />
    );
  };

  const TotalEntityOwnershipWarning = () => {
    return (
      <Alert severity="error">
        <AlertTitle>Entity Ownership Percentages</AlertTitle>
        The Entity’s Ownership Percentages are not adding up to a 100%. Please
        make sure the percentages are correct.
      </Alert>
    );
  };

  return (
    <Grid container direction="row" spacing={2}>
      <Grid item xs={12}>
        {createPercentageField({
          store: borrowerInformationStore.mainBorrowerFormStore,
          testId: 'pctOwnership',
          fieldName: 'pctOwnership',
          label: `${borrowerInformationStore.mainBorrower.firstName} ${borrowerInformationStore.mainBorrower.lastName}`,
          limitedPercentage: true,
        })}
      </Grid>
      {borrowerInformationStore.coBorrowersCount > 0 &&
        borrowerInformationStore.coBorrowers.map((borrower, index) => (
          <Grid item xs={12} key={`coBorrower${index + 1}`}>
            {createPercentageField({
              store: borrowerInformationStore.coBorrowersFormStores[index],
              testId: 'pctOwnership',
              fieldName: 'pctOwnership',
              label: `${borrower.firstName} ${borrower.lastName}`,
              limitedPercentage: true,
            })}
          </Grid>
        ))}
      <Grid item xs={12}>
        <Divider style={{ height: '1px', backgroundColor: '#B9B9B9' }} />
      </Grid>
      <Grid item xs={12}>
        <TotalOwnershipField totalOwnership={totalOwnership} error={false} />
      </Grid>
      {isTotalOwnershipExceedingLimit && (
        <Grid item xs={12}>
          <TotalEntityOwnershipWarning />
        </Grid>
      )}
    </Grid>
  );
});
