import { Theme, makeStyles, Grid, Typography, Divider, Paper, Button } from "@material-ui/core";
import { observer } from "mobx-react";
import placeHolderImage from './../images/Placeholder.svg';
import OpenInNew from "@material-ui/icons/OpenInNew";
import { PropertyDetail } from "libs/feature-comp-tool/src/compTool/components/propertyDetails";
import { formatCurrency } from "@roc/ui/utils";
import { ImagesGrid } from "./imagesGrid";


const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    width: '100%',
    padding: theme.spacing(2),
  },
  title: {
    fontSize: '28px',
    paddingLeft: theme.spacing(1),
    marginBottom: '0px'
  },
  button: {
    borderRadius: 1
  },
  image: {
    width: '100%',
    height: '335px',
    padding: '10px',
    paddingRight: '4px'
  },
  item: {
    marginTop: '10px',
    padding: '20px'
  },
  addressInfo: {
    marginTop: '10px',
  },
  imagesGrid: {
    width: '100%',
    height: '150px',
    padding: '0px 2px 0px 0px'
  },
  price: {
    fontSize: '52px',
    backgroundColor: '#285391',
    color: '#ffffff',
    fontFamily: 'Roboto',
    display: 'inline',
    padding: '10px',
    borderRadius: '4px'
  },
}));

export const SubjectProperty = observer(({store}) => {
  const classes = useStyles();
  const {images} = store;
  const imagesArray = Array(6).fill(placeHolderImage);
  const image = images?.find(image => image.recommended_preview)? images.find(image => image.recommended_preview).url : (images? images[0]?.url : imagesArray[0]?.url);
  const imagesGrid = images?.length > 0 ? images?.map(image => image.url) : imagesArray;

  const onError = (event) => {
    event.target.src = placeHolderImage;
  };
  const fields = store.form.fields;

  return (
    <>
      <Paper square className={classes.paper}>
        <Grid container spacing={1}>
          <Grid container xs={12}>
            <Grid item md={6}>
              <Typography align="left" className={classes.title}>
                Subject Property
              </Typography>
            </Grid>
            {/* <Grid item container direction='row-reverse' md={6} spacing={1}>
              <Grid item>
                <Button variant='outlined' color='primary' className={classes.button}>
                  <OpenInNew />
                  <Typography>
                    Open Scope of Work
                  </Typography>
                </Button>
              </Grid>
              <Grid item>
                <Button variant='outlined' color='primary' className={classes.button}>
                  <OpenInNew />
                  <Typography>
                    Open Appraisal Report
                  </Typography>
                </Button>
              </Grid>
            </Grid> */}
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6} lg={3}>
            <img
              src={image || placeHolderImage}
              loading="lazy"
              className={classes.image}
              onError={onError}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3} className={classes.item}>
            <ImagesGrid images={imagesGrid} imageStyles={classes.imagesGrid} openCarousel={(value: boolean) => { }} showAllPhotosButton={false} />
          </Grid>
          <Divider orientation="vertical" variant="middle" flexItem style={{ margin: '20px -1px 25px -1px' }} />
          <Grid item xs={12} md={6} className={classes.addressInfo}>
            <Typography align="left" variant="h5" style={{ paddingLeft: '10px' }} gutterBottom>
              {fields.address.value}
            </Typography>
            <Grid container spacing={1} style={{ marginTop: '20px' }} justifyContent="space-between">
              <Grid container spacing={1} style={{ marginTop: '20px' }} justifyContent="space-between"></Grid>
              <PropertyDetail label="Total Reno Budget" value={formatCurrency(fields.totalRenoBudget.value)} lg={2} md={3} xs={3} variant={"h4"} />
              <PropertyDetail label="Completed Reno" value={formatCurrency(fields.completedRenoBudget.value)} lg={2} md={3} xs={3} variant={"h4"} />
              <PropertyDetail label="Bd" value={fields.bedrooms.value} lg={1} md={3} xs={3} variant={"h4"} />
              <PropertyDetail label="Ba" value={fields.fullBathrooms.value} lg={2} md={3} xs={3} variant={"h4"} />
              <Grid item lg={12} xs={12} style={{ marginTop: '10px' }}>
                <Divider variant="middle" />
              </Grid>
            </Grid>
            <Grid container spacing={1} justifyContent="space-between" style={{ marginTop: '20px' }}>
              <Grid item lg={2} md={2} xs={4}></Grid>
              <PropertyDetail label="Sq.Ft" value={fields.area.value} lg={2} md={3} xs={6} variant={"h4"} />
              <PropertyDetail label="Lot Size" value={fields.lotSize.value} lg={2} md={2} xs={4} variant={"h5"} />
              <PropertyDetail label="Yr. Built" value={fields.yrBuilt.value} lg={2} md={2} xs={4} variant={"h5"} />
              {/* <PropertyDetail label="Rehab type" value={fields.rehabType.value} lg={2} md={2} xs={4} variant={"h5"} />
              <PropertyDetail label="Current Structure" value={fields.currentStructure.value} lg={2} md={2} xs={4} variant={"h5"} />
              <PropertyDetail label="Proposed Structure" value={fields.proposedStructure.value} lg={2} md={2} xs={4} variant={"h5"} />
              <PropertyDetail label="Proposed Sq. Ft." value={fields.proposedSqFt.value} lg={2} md={2} xs={4} variant={"h5"} /> */}
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </>
  )
})