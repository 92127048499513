import {
  IconButton,
  Typography,
  Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import {
  Button,
} from '@roc/ui';


const SOWRevisionRequestDialog = ({ onClose, onContinue }) => {
  return (
    <Grid container justifyContent="center" spacing={3}>
      <Grid item xs={12}>
        <Typography variant="body2">
          You are about to Request a Scope of Work revision. If you continue, you will not able to request a Draw for this loan until the Scope of Work Revision is finalized.
        </Typography>
      </Grid>
      <Grid item container justifyContent="space-around">
        <Grid item xs={3}>
          <Button
            variant="outlined"
            size="small"
            onClick={onClose}
            color="primary"
            testId="SOW_Revision_Request_Cancel"
            fullWidth={true}
          >
            Cancel
          </Button>
        </Grid>
        <Grid item xs={3}>
          <Button
            variant="contained"
            size="small"
            onClick={onContinue}
            color="primary"
            testId="SOW_Revision_In_Progress_OK"
            fullWidth={true}
          >
            Continue
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
};
export default SOWRevisionRequestDialog;