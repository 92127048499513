import { LoanStore } from '@roc/client-portal-shared/stores';
import { ApiResponse, GlobalStore, GridStore } from '@roc/feature-app-core';
import { BorrowerLoansService } from '@roc/feature-borrower-loans';
import { CommunicationService } from '@roc/feature-communication';
import { getPageFromObject } from '@roc/feature-utils';
import { computed, flow, makeObservable, observable } from 'mobx';

const PAGE_SIZE = 20;

export class LoanContactsGridStore extends GridStore {
  globalStore: GlobalStore;
  loanStore: LoanStore;
  communicationService: CommunicationService;

  constructor(globalStore: GlobalStore, loanStore: LoanStore) {
    super(() => this.fetchLoanContacts(), undefined, PAGE_SIZE);
    this.globalStore = globalStore;
    this.loanStore = loanStore;
    this.communicationService = new CommunicationService();
    makeObservable(this, {
      showContacts: computed,
    });
  }

  fetchLoanContacts = async () => {
    try {
      const response = await this.communicationService.getParticipantsAllowedByLoanId(
        this.loanStore.loanDetails.loanId
      );

      //Sort first name by default
      const sortedData = response.data.data.sort((a, b) =>
        a.firstName.localeCompare(b.firstName)
      );

      const data = getPageFromObject(sortedData, this.gridData.meta);
      return new ApiResponse({ data }, null);
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: `Error while fetching the list of contacts.`,
      });
    }
  };

  get showContacts() {
    return this.globalStore.userFeatures?.isClosingAttorney;
  }
}
