import { Grid, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Button } from '@roc/ui';
import { observer } from 'mobx-react';
import { useStore } from '../../../hooks/useStore';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const useStyles = makeStyles((theme: Theme) => {
  return {
    container: {
      flexDirection: 'column',
      justifyContent: 'center',
      textAlign: 'center',
      marginTop: '20px'
    },
    button: {
      width: '160px',
      marginBottom:'50px'
    },
    margins: {
      marginTop: '20px'
    }
  };
});

export const MersOnboardingThankYou = observer(({onFinish}) => {
  const {companyProfileStore} = useStore();
  const {loanFundingPreferenceStore} = companyProfileStore;
  const classes = useStyles();

  const handleFinish=()=>{
    onFinish();
  }
  return (
    <Grid container className={classes.container}>
      <Grid item >
        <CheckCircleIcon style={{ fontSize: '100px', color: 'green' }}/>
        <Typography variant="h2">
          Process Completed!
          <br/>
        </Typography>
      </Grid>
      <Grid item className={classes.margins}>
        <Typography>
          The MERs process has been completed.
        </Typography>
        <Typography>
          Your information was successfully received.
        </Typography>
      </Grid>
      <Grid item className={classes.margins}>
        <Button
          testId='finish'
          variant='contained'
          color='primary'
          className={classes.button}
          onClick={handleFinish}
        >FINISH</Button>
      </Grid>
    </Grid>
  );
});
