import { Business, ExitToApp, Person, NewReleases } from '@material-ui/icons';
import { useRoutes } from '@roc/client-portal-shared/hooks';
import { useRoutes as useLenderRoutes } from './useRoutes';
import { insertIf, Roles } from '@roc/client-portal-shared/utils';
import { useLenderPortalStore } from './useLenderPortalStore';
import { useProposalsStore } from '@roc/feature-proposals';
import { useUserStore } from '@roc/feature-app-core';
import FlipIcon from '@material-ui/icons/Flip';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';

export const useNavigation = () => {
  const {
    miscellaneousRoutesConfig,
    dashboardRoutesConfig,
    loanRoutesConfig,
    loanSubmissionRoutesConfig,
    borrowersRoutesConfig,
    appraisalsRoutesConfig,
    creditBackgroundRoutesConfig,
    companyProfileRoutesConfig,
    documentLibraryRoutesConfig,
    documentLibraryContentfulRoutesConfig,
    fixFlipSizerRoutesConfig,
    dscrCalculatorRoutesConfig,
    liquidityCalculatorRoutesConfig,
    multifamilyLiquidityCalculatorRoutesConfig,
    drawsRoutesConfig,
    internalPricerRoutesConfig,
    bridgePricerRoutesConfig,
    rocUniversityRoutesConfig,
    sigmaDashboardsRoutesConfig,
    proposalsRoutesConfig,
    stabilizedBridgeAnalyzerRoutesConfig,
    calculatorSelectionRoutesConfig,
    manageBrokersRoutesConfig,
    lenderExtensionsRoutesConfig,
    payoffRoutesConfig,
    scopeOfWorkRoutesConfigV2,
    liquidityReportRoutesConfig
  } = useRoutes();

  const {
    lenderProfileRoutesConfig,
    prospectPropertiesRoutesConfig,
    appsRoutesConfig,
    backOfficeRoutesConfig,
  } = useLenderRoutes();

  const { drawRequestStore, payoffNewRequestStore, extensionsNewRequestStore } = useLenderPortalStore();

  const { globalStore } = useLenderPortalStore();

  const { proposalsStore } = useProposalsStore();

  const { userStore } = useUserStore();

  const allowLoanPricing = userStore.allowLoanPricing;


  const { push } = useHistory();
  const [titleName, setTitleName] = useState('Switch to BackOffice View');
  const changeToBackOfficeView = () => {
    const currentPath = window.location.pathname;
    if (currentPath.includes("/backoffice/loans")) {
      setTitleName('Switch to BackOffice View')
      push(loanRoutesConfig.loansHome.url);
    } else {
      setTitleName('Switch to Lender View')
      push(backOfficeRoutesConfig.backOfficeDashboard.url)
    }
  }

  const navigationMenuData = [
    {
      title: 'LOANS',
      route: loanRoutesConfig.loansHome.url,
      subMenu: [
        {
          title: 'View Loans',
          route: loanRoutesConfig.loansHome.url,
        },
        {
          title: 'Submit New Loan',
          route: loanSubmissionRoutesConfig.submitloan.url,
        },
        ...insertIf(
          allowLoanPricing,
          [
            {
              title: 'Deal Room',
              onClick: () => {
                proposalsStore.setShowDealRoomPopup(true);
              },
            },
          ]
        ),
        ...insertIf(
          globalStore.userFeatures?.underwritingEntitlements
            .isScopeOfWorkFormEnabled,
          [
            {
              title: 'Scope of Work',
              route: scopeOfWorkRoutesConfigV2.scopeOfWork.url
            },
          ]
        ),
        ...insertIf(
          globalStore.lenderInfo?.brokerPortalEnabled,
          [
            {
              title: 'Manage Brokers',
              route: manageBrokersRoutesConfig.manageBrokers.url,
            }
          ]
        ),
      ],
    },
    {
      title: 'APPRAISALS',
      subMenu: [
        {
          title: 'View Appraisals',
          route:
            appraisalsRoutesConfig.appraisals.children.browseAppraisals.url,
        },
        {
          title: 'Order Appraisal',
          route: appraisalsRoutesConfig.appraisals.children.submitAppraisal.url,
        },
        ...insertIf(!globalStore.userFeatures?.tamariskAppraisal, [
          {
            title: 'Find Appraiser',
            route: appraisalsRoutesConfig.appraisals.children.findAppraiser.url,
          },
        ]),
      ],
    },
    {
      title: 'DRAWS',
      subMenu: [
        {
          title: 'View Draws',
          route: drawsRoutesConfig.draws.url,
        },
        {
          title: 'Request a Draw',
          onClick: () => {
            drawRequestStore.setShowLoansForDrawRequestModal(true);
          },
        },
      ],
    },
    {
      title: 'EXTENSIONS',
      subMenu: [
        {
          title: 'View Extensions',
          route: lenderExtensionsRoutesConfig.extensions.url,
        },
        {
          title: 'Request an Extension',
          onClick: () => {
            extensionsNewRequestStore.setShowLoansForExtensionsRequestModal(true);
          },
        },
      ],
    },
    {
      title: 'PAYOFFS',
      subMenu: [
        {
          title: 'View Payoffs',
          route: payoffRoutesConfig.payoffs.url,
        },
        {
          title: 'Request a Payoff',
          onClick: () => {
            payoffNewRequestStore.setShowLoansForPayoffRequestModal(true);
            console.log(payoffNewRequestStore.showLoansForPayoffRequestModal);
          },
        },

      ],
    },
    {
      title: 'BORROWERS',
      subMenu: [
        {
          title: 'View Borrowers',
          route: borrowersRoutesConfig.borrowers.url,
        },
        ...insertIf(!globalStore?.userFeatures?.hideCreditBackgroundChecks, [
          {
            title: 'Request Credit/Background Check',
            route: creditBackgroundRoutesConfig.submitCreditBackground.url,
          },
          {
            title: 'View Credit/Background Requests',
            route: creditBackgroundRoutesConfig.creditBackgroundRequests.url,
          },
        ]),
        ...insertIf(globalStore?.userFeatures?.plaidRegistration, [
          {
            title: 'Plaid Liquidity Verification',
            route: creditBackgroundRoutesConfig.plaidLiquidityVerification.url,
          },
        ]),
        ...insertIf(globalStore?.userFeatures?.plaidRegistration, [
          {
            title: 'Browse Plaid Liquidity Requests',
            route: creditBackgroundRoutesConfig.browsePlaidLiquidityRequests.url,
          },
        ])
      ],
    },

    {
      title: 'RESOURCES',
      subMenu: [
        ...insertIf(globalStore.userFeatures?.showTools, [
          {
            title: 'Calculators & Pricers',
            route: calculatorSelectionRoutesConfig.calculatorSelection.url,
          },
          ...insertIf(globalStore.userFeatures?.showLms, [
            {
              title: 'Roc University',
              route: rocUniversityRoutesConfig.rocUniversity.url,
            },
          ])]),
        ...insertIf(globalStore.userFeatures?.showSigmaDashboards && globalStore.userFeatures.availableSigmaDashboards.length > 0, [
          {
            title: "Sigma Dashboards",
            route: sigmaDashboardsRoutesConfig.sigmaDashboards.url,
          }
        ]),
        {
          title: 'Document Library',
          route: documentLibraryContentfulRoutesConfig.documentLibraryHome.url,
        },
        ...insertIf((userStore.userInformation.roles[0] == Roles.ADMIN || userStore.userInformation.roles[0] == Roles.LENDER_LEAD) , [{
          title: 'Liquidity Report',
          route: liquidityReportRoutesConfig.liquidityReport.url,
        }]),
        ...insertIf(globalStore.userFeatures.showApps, [{
          title: 'Apps',
          route: appsRoutesConfig.apps.url,
          isNew: true,
        }]),
      ],
    },
    ...insertIf(
      globalStore.userFeatures?.showProspectProperties,
      [
        {
          title: 'Off Market Properties',
          route: prospectPropertiesRoutesConfig.prospectProperties.url,
          showNewBadgeTill: '2022-12-31',
        }
      ]
    )
  ]

  const profileMenuData = [
    {
      title: 'My Profile',
      route: lenderProfileRoutesConfig.lenderProfile.url,
      icon: <Person fontSize="small" />,
    },
    ...insertIf(globalStore.userFeatures?.adminPortalConfiguration || globalStore.userFeatures?.backOfficeUser, [
      {
        title: 'Company Profile',
        route: companyProfileRoutesConfig.companyProfile.url,
        icon: <Business fontSize="small" />,
      },
    ]),
    ...insertIf(globalStore.userFeatures?.backOfficeUser, [
      {
        title: 'Switch to BackOffice View',
        onClick: () => {
          push(loanRoutesConfig.loansHomeBackoffice.url);
          window.location.href = window.location.href;
        },
        icon: <FlipIcon fontSize="small" />,
      },
    ]),
    {
      title: 'Logout',
      route: '',
      icon: <ExitToApp fontSize="small" />,
    },
  ];

  const redirectRoute = dashboardRoutesConfig.dashboard.url;
  const loanRoutes = loanRoutesConfig.loans;

  return {
    navigationMenuData,
    profileMenuData,
    redirectRoute,
    loanRoutes
  };
};
