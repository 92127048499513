import { Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { Person, Room, MonetizationOn, AssignmentTurnedIn, Replay, CloudUpload } from '@material-ui/icons';
import { insertIf } from '@roc/client-portal-shared/utils';
import { Layout } from '@roc/feature-app-core';
import { StepsContainer } from '@roc/ui';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useParams, useLocation } from 'react-router';
import LoanTerms from './components/loanTerms';
import Summary from './components/summary/summary';
import { useProposalsStore } from './hooks';
import BorrowerInformation from './components/borrowerComponents/borrowerInformation';
import PropertyInformation from './components/propertyComponents/propertyInformation';
import AppealTab from './components/appeal';


const useStyles = makeStyles(theme =>
  createStyles({
    paper: {
      width: '100%',
    },
  })
);

const ProposalDetails = observer(() => {
  const { proposalId } = useParams<{ proposalId: string }>();
  const { status } = useParams<{ status: string }>();
  const { proposalsStore, proposalTopicStore } = useProposalsStore();
  const classes = useStyles();
  const { detailsStore } = proposalsStore;
  const { isAppeal } = detailsStore;
  const location = useLocation();
  const loanSubtype = (location.state as { loanSubtype?: string })?.loanSubtype;

  const steps = [
    ...insertIf(isAppeal, [
      {
        title: 'Reason For The Appeal',
        icon: <Replay color="primary" fontSize="large" />,
        onBack: () => detailsStore.goPrevStep(),
        onNext: () => detailsStore.goNextStep(),
        allowBack: false,
        allowNext: true,
      },
    ]),
    {
      title: 'Borrower Information',
      icon: <Person color="primary" fontSize="large" />,
      onBack: () => detailsStore.goPrevStep(),
      onNext: () => detailsStore.goNextStep(),
      allowBack: isAppeal,
      allowNext: true,
    },
    {
      title: 'Property Information',
      icon: <Room color="primary" fontSize="large" />,
      onBack: () => detailsStore.goPrevStep(),
      onNext: () => detailsStore.goNextStep(),
      allowBack: true,
      allowNext: true,
      allowSave: true,
      hidden: false,
    },
    {
      title: 'Loan Terms',
      icon: <MonetizationOn color="primary" fontSize="large" />,
      onBack: () => detailsStore.goPrevStep(),
      onNext: () => detailsStore.goNextStep(),
      onSave: () => detailsStore.saveProposal(),
      allowBack: true,
      allowNext: true,
      allowSave: true,
    },
    {
      title: 'Deal Summary',
      icon: <AssignmentTurnedIn color="primary" fontSize="large" />,
      onBack: null,
      onNext: null,
      onSave: null,
      allowBack: false,
      allowNext: false,
      allowSave: false,
    },
  ];

  useEffect(() => {
    detailsStore.initForm(proposalId, loanSubtype);
  }, []);

  return (
    <Layout title="Deal Room">
      <Paper className={classes.paper}>
        <StepsContainer
          disableNavigation={false}
          activeStep={detailsStore.activeStep}
          handleStep={step => detailsStore.moveToStep(step)}
          steps={steps}
        >
          {[
            ...insertIf(isAppeal, [
              <AppealTab />
            ]),
            <BorrowerInformation store={detailsStore}></BorrowerInformation>,
            <PropertyInformation></PropertyInformation>,
            <LoanTerms />,
            <Summary />,
          ]}

        </StepsContainer>
      </Paper>
    </Layout>
  );
});

export default ProposalDetails;
