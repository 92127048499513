import { insertIf } from '@roc/feature-utils';
import { filterProps } from '@roc/ui';
import { AgGridColumnProps } from 'ag-grid-react';
import {
  FieldDefinition,
  FieldType,
} from '../../../stores/editLeadsDashboardStore';
import { formatCurrency } from '@roc/ui/utils';
import { BACKOFFICE_LEAD_DASHBOARDS, DISPLAY_CREATED_LAST_UPDATED_DATE_DASHBOARDS } from './constants';

const noOpComparator = () => 0;

const usdAmount: any = params => {
  return params.value && formatCurrency(Number(params.value));
};

export const getLeadsDashboardColumns = (
  showOwnerColumns, dashboardName
): AgGridColumnProps[] => [
    {
      field: 'id',
      headerName: 'id',
      minWidth: 100,
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      hide: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    {
      field: 'id',
      headerName: '#',
      cellRenderer: 'orderCellRenderer',
      minWidth: 60,
      maxWidth: 60,
      sortable: false,
      filter: false,
      floatingFilter: false,
    },
    {
      field: 'name',
      headerName: 'Name',
      minWidth: 100,
      width: 200,
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      sortingOrder: ['asc', 'desc'],
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      cellRenderer: 'firstNameCellRenderer',
    },
    {
      field: 'email',
      headerName: 'Email',
      minWidth: 150,
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      sortingOrder: ['asc', 'desc'],
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      cellRenderer: 'emailCellRenderer',
    },
    {
      field: 'phone',
      headerName: 'Phone',
      minWidth: 30,
      width: 142,
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      sortingOrder: ['asc', 'desc'],
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      cellRenderer: 'phoneCellRenderer',
    },
    {
      field: 'leadSource',
      headerName: 'Lead Source',
      minWidth: 100,
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      sortingOrder: ['asc', 'desc'],
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      minWidth: 50,
      maxWidth: 80,
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      sortingOrder: ['asc', 'desc'],
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    ...insertIf(showOwnerColumns, [
      {
        field: 'ownerName',
        headerName: 'Owner',
        minWidth: 100,
        comparator: noOpComparator,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        sortingOrder: ['asc', 'desc'],
        floatingFilterComponentParams: {
          suppressFilterButton: true,
        },
      },
    ]),
    {
      field: 'salesUserTag',
      headerName: 'Sales User Tag',
      minWidth: 100,
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      sortingOrder: ['asc', 'desc'],
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    {
      field: 'leadId',
      headerName: 'Action',
      minWidth: 70,
      cellRenderer: 'actionCellRenderer',
      comparator: noOpComparator,
      width: 70,
      pinned: 'right',
      lockPinned: true,
    },
    ...insertIf(BACKOFFICE_LEAD_DASHBOARDS.includes(dashboardName), [
      {
        field: 'lastIncomingDate',
        headerName: 'Last Incoming',
        minWidth: 150,
        cellRenderer: 'dateCellRenderer',
        comparator: noOpComparator,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        sortingOrder: ['asc', 'desc'],
        floatingFilterComponentParams: {
          suppressFilterButton: true,
        },
      },
    ]),
    {
      field: 'lastEmailDate',
      headerName: 'Last Email',
      minWidth: 100,
      cellRenderer: 'dateCellRenderer',
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      sortingOrder: ['asc', 'desc'],
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    {
      field: 'lastCallDate',
      headerName: 'Last Call',
      minWidth: 100,
      cellRenderer: 'dateCellRenderer',
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      sortingOrder: ['asc', 'desc'],
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    {
      field: 'lastTextDate',
      headerName: 'Last Text',
      minWidth: 100,
      cellRenderer: 'dateCellRenderer',
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      sortingOrder: ['asc', 'desc'],
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    ...insertIf(DISPLAY_CREATED_LAST_UPDATED_DATE_DASHBOARDS.includes(dashboardName), [
      {
        field: 'lastUpdatedDate',
        headerName: 'Last Modified',
        minWidth: 100,
        cellRenderer: 'dateCellRenderer',
        comparator: noOpComparator,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        sortingOrder: ['asc', 'desc'],
        floatingFilterComponentParams: {
          suppressFilterButton: true,
        },
      },
      {
        field: 'createdDate',
        headerName: 'Created Date',
        minWidth: 100,
        cellRenderer: 'dateCellRenderer',
        comparator: noOpComparator,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
        },
      },
    ]),
    {
      field: 'details',
      headerName: 'Show Details',
      cellRenderer: 'detailsCellRenderer',
      minWidth: 10,
      width: 10,
      sortable: false,
      filter: false,
      floatingFilter: false,
    },
  ];

export const getBorrowerDashboardColumns = (
  showOwnerColumns
): AgGridColumnProps[] => [
    {
      field: 'borrowerId',
      headerName: 'Borrower Id',
      minWidth: 100,
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      hide: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    {
      field: 'id',
      headerName: '#',
      cellRenderer: 'orderCellRenderer',
      minWidth: 60,
      maxWidth: 60,
      sortable: false,
      filter: false,
      floatingFilter: false,
    },
    {
      field: 'fullName',
      headerName: 'Name',
      minWidth: 200,
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      sortingOrder: ['asc', 'desc'],
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      cellRenderer: 'firstNameCellRenderer',
    },
    {
      field: 'email',
      headerName: 'Email',
      minWidth: 200,
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      sortingOrder: ['asc', 'desc'],
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      cellRenderer: 'emailCellRenderer',
    },
    {
      field: 'phone',
      headerName: 'Cell Phone',
      minWidth: 30,
      width: 142,
      cellRenderer: 'phoneCellRenderer',
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      sortingOrder: ['asc', 'desc'],
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    ...insertIf(showOwnerColumns, [
      {
        field: 'civicOwner',
        headerName: 'Civic Owner',
        minWidth: 100,
        comparator: noOpComparator,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        sortingOrder: ['asc', 'desc'],
        floatingFilterComponentParams: {
          suppressFilterButton: true,
        },
      },
      {
        field: 'facoOwner',
        headerName: 'Faco Owner',
        minWidth: 100,
        comparator: noOpComparator,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        sortingOrder: ['asc', 'desc'],
        floatingFilterComponentParams: {
          suppressFilterButton: true,
        },
      },
      {
        field: 'rocOwner',
        headerName: 'Roc Owner',
        minWidth: 100,
        comparator: noOpComparator,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        sortingOrder: ['asc', 'desc'],
        floatingFilterComponentParams: {
          suppressFilterButton: true,
        },
      },
    ]),
    {
      field: 'leadId',
      headerName: 'Action',
      minWidth: 70,
      cellRenderer: 'actionCellRenderer',
      comparator: noOpComparator,
      width: 70,
      pinned: 'right',
      lockPinned: true,
    },
    {
      field: 'lastEmailDate',
      headerName: 'Last Email',
      minWidth: 150,
      cellRenderer: 'dateCellRenderer',
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      sortingOrder: ['asc', 'desc'],
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    {
      field: 'lastCallDate',
      headerName: 'Last Call',
      minWidth: 150,
      cellRenderer: 'dateCellRenderer',
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      sortingOrder: ['asc', 'desc'],
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    {
      field: 'lastTextDate',
      headerName: 'Last Text',
      minWidth: 150,
      cellRenderer: 'dateCellRenderer',
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      sortingOrder: ['asc', 'desc'],
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    {
      field: 'borrowerLastUpdatedDate',
      headerName: 'Last Modified',
      minWidth: 150,
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      cellRenderer: 'dateCellRenderer',
      floatingFilter: true,
      sortingOrder: ['asc', 'desc'],
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    {
      field: 'borrowerCreatedDate',
      headerName: 'Created Date',
      minWidth: 150,
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      cellRenderer: 'dateCellRenderer',
      floatingFilter: true,
      sortingOrder: ['asc', 'desc'],
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    {
      field: 'details',
      headerName: 'Show Details',
      cellRenderer: 'detailsCellRenderer',
      minWidth: 60,
      maxWidth: 60,
      sortable: false,
      filter: false,
      floatingFilter: false,
    },
  ];

export const getBorrowerWithoutCreditInquiryColumns = (
  showOwnerColumns
): AgGridColumnProps[] => [
    {
      field: 'borrowerId',
      headerName: 'Borrower Id',
      minWidth: 200,
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      hide: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    {
      field: 'id',
      headerName: '#',
      cellRenderer: 'orderCellRenderer',
      minWidth: 60,
      maxWidth: 60,
      sortable: false,
      filter: false,
      floatingFilter: false,
    },
    {
      field: 'fullName',
      headerName: 'Name',
      minWidth: 200,
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      sortingOrder: ['asc', 'desc'],
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      cellRenderer: 'firstNameCellRenderer',
    },
    {
      field: 'email',
      headerName: 'Email',
      minWidth: 200,
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      sortingOrder: ['asc', 'desc'],
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      cellRenderer: 'emailCellRenderer',
    },
    {
      field: 'phone',
      headerName: 'Cell Phone',
      minWidth: 30,
      width: 142,
      cellRenderer: 'phoneCellRenderer',
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      sortingOrder: ['asc', 'desc'],
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    ...insertIf(showOwnerColumns, [
      {
        field: 'civicOwner',
        headerName: 'Civic Owner',
        minWidth: 200,
        comparator: noOpComparator,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        sortingOrder: ['asc', 'desc'],
        floatingFilterComponentParams: {
          suppressFilterButton: true,
        },
      },
      {
        field: 'facoOwner',
        headerName: 'Faco Owner',
        minWidth: 200,
        comparator: noOpComparator,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        sortingOrder: ['asc', 'desc'],
        floatingFilterComponentParams: {
          suppressFilterButton: true,
        },
      },
      {
        field: 'rocOwner',
        headerName: 'Roc Owner',
        minWidth: 200,
        comparator: noOpComparator,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        sortingOrder: ['asc', 'desc'],
        floatingFilterComponentParams: {
          suppressFilterButton: true,
        },
      },
    ]),
    {
      field: 'transunionTriggerDate',
      headerName: 'Transunion Credit Check',
      minWidth: 150,
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      sortingOrder: ['asc', 'desc'],
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    {
      field: 'leadId',
      headerName: 'Action',
      minWidth: 70,
      cellRenderer: 'actionCellRenderer',
      comparator: noOpComparator,
      width: 70,
      pinned: 'right',
      lockPinned: true,
    },
    {
      field: 'lastEmailDate',
      headerName: 'Last Email',
      minWidth: 150,
      cellRenderer: 'dateCellRenderer',
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      sortingOrder: ['asc', 'desc'],
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    {
      field: 'lastCallDate',
      headerName: 'Last Call',
      minWidth: 150,
      cellRenderer: 'dateCellRenderer',
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      sortingOrder: ['asc', 'desc'],
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    {
      field: 'lastTextDate',
      headerName: 'Last Text',
      minWidth: 150,
      cellRenderer: 'dateCellRenderer',
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      sortingOrder: ['asc', 'desc'],
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    {
      field: 'borrowerLastUpdatedDate',
      headerName: 'Last Modified',
      minWidth: 150,
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      cellRenderer: 'dateCellRenderer',
      floatingFilter: true,
      sortingOrder: ['asc', 'desc'],
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    {
      field: 'borrowerCreatedDate',
      headerName: 'Created Date',
      minWidth: 150,
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      cellRenderer: 'dateCellRenderer',
      floatingFilter: true,
      sortingOrder: ['asc', 'desc'],
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    {
      field: 'details',
      headerName: 'Show Details',
      cellRenderer: 'detailsCellRenderer',
      minWidth: 60,
      maxWidth: 60,
      sortable: false,
      filter: false,
      floatingFilter: false,
    },
  ];

export const getTpoDashboardColumns = (): AgGridColumnProps[] => [
  {
    field: 'companyContactId',
    headerName: 'Id',
    minWidth: 200,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    hide: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'id',
    headerName: '#',
    cellRenderer: 'orderCellRenderer',
    minWidth: 60,
    maxWidth: 60,
    sortable: false,
    filter: false,
    floatingFilter: false,
  },
  {
    field: 'name',
    headerName: 'Name',
    minWidth: 100,
    width: 200,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    sortingOrder: ['asc', 'desc'],
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
    cellRenderer: 'firstNameCellRenderer',
  },
  {
    field: 'email',
    headerName: 'Email',
    minWidth: 200,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    sortingOrder: ['asc', 'desc'],
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
    cellRenderer: 'emailCellRenderer',
  },
  {
    field: 'phone',
    headerName: 'Phone',
    minWidth: 30,
    width: 142,
    cellRenderer: 'phoneCellRenderer',
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    sortingOrder: ['asc', 'desc'],
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'company',
    headerName: 'Company',
    minWidth: 200,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    sortingOrder: ['asc', 'desc'],
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'companyAlias',
    headerName: 'Alias',
    minWidth: 100,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    sortingOrder: ['asc', 'desc'],
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'primaryRm',
    headerName: 'Primary RM',
    minWidth: 200,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    sortingOrder: ['asc', 'desc'],
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'secondaryRm',
    headerName: 'Secondary RM',
    minWidth: 200,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    sortingOrder: ['asc', 'desc'],
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'status',
    headerName: 'Account Status',
    minWidth: 200,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    sortingOrder: ['asc', 'desc'],
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'leadId',
    headerName: 'Action',
    minWidth: 70,
    cellRenderer: 'actionCellRenderer',
    comparator: noOpComparator,
    width: 70,
    pinned: 'right',
    lockPinned: true,
  },
  {
    field: 'lastEmailDate',
    headerName: 'Last Email',
    minWidth: 150,
    cellRenderer: 'dateCellRenderer',
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    sortingOrder: ['asc', 'desc'],
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'lastCallDate',
    headerName: 'Last Call',
    minWidth: 150,
    cellRenderer: 'dateCellRenderer',
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    sortingOrder: ['asc', 'desc'],
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'lastTextDate',
    headerName: 'Last Text',
    minWidth: 150,
    cellRenderer: 'dateCellRenderer',
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    sortingOrder: ['asc', 'desc'],
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'details',
    headerName: 'Show Details',
    cellRenderer: 'detailsCellRenderer',
    minWidth: 60,
    maxWidth: 60,
    sortable: false,
    filter: false,
    floatingFilter: false,
  },
];

export const getBrokerDashboardColumns = (): AgGridColumnProps[] => [
  {
    field: 'companyContactId',
    headerName: 'Id',
    minWidth: 200,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    hide: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'id',
    headerName: '#',
    cellRenderer: 'orderCellRenderer',
    minWidth: 60,
    maxWidth: 60,
    sortable: false,
    filter: false,
    floatingFilter: false,
  },
  {
    field: 'name',
    headerName: 'Name',
    minWidth: 200,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
    cellRenderer: 'firstNameCellRenderer',
  },
  {
    field: 'email',
    headerName: 'Email',
    minWidth: 200,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
    cellRenderer: 'emailCellRenderer',
  },
  {
    field: 'phone',
    headerName: 'Phone',
    minWidth: 200,
    cellRenderer: 'phoneCellRenderer',
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'company',
    headerName: 'Company',
    minWidth: 200,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'companyAlias',
    headerName: 'Alias',
    minWidth: 200,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'primaryRm',
    headerName: 'Owner',
    minWidth: 200,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'portalRole',
    headerName: 'Portal Role',
    minWidth: 200,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'lastUpdatedDate',
    headerName: 'Last Modified Date',
    minWidth: 200,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    cellRenderer: 'dateCellRenderer',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'createdDate',
    headerName: 'Created Date',
    minWidth: 200,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    cellRenderer: 'dateCellRenderer',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'lastEmailDate',
    headerName: 'Last Email Date',
    minWidth: 200,
    cellRenderer: 'dateCellRenderer',
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'lastCallDate',
    headerName: 'Last Call Date',
    minWidth: 200,
    cellRenderer: 'dateCellRenderer',
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'lastTextDate',
    headerName: 'Last Text Date',
    minWidth: 200,
    cellRenderer: 'dateCellRenderer',
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'companyContactId',
    headerName: 'Action',
    minWidth: 70,
    cellRenderer: 'actionCellRenderer',
    comparator: noOpComparator,
    width: 70,
    pinned: 'right',
    lockPinned: true,
  },
];

const mapCustomColumn = (column: FieldDefinition) => {
  const columnWidth = {
    city: 50,
    state: 50,
    status: 50,
  };

  const cellRendererByFieldName = {
    company: 'companyCellRenderer',
    keyContact: 'primaryContactCellRenderer',
  };

  const cellRendererByFieldType = {
    [FieldType.DATE_SELECT]: 'dateWithoutTimeCellRenderer',
  };

  return {
    field: column.fieldName,
    headerName: column.fieldDisplayName,
    comparator: noOpComparator,
    ...filterProps(),
    width: columnWidth[column.fieldName] || undefined,
    cellRenderer:
      cellRendererByFieldType[column.fieldType] ||
      cellRendererByFieldName[column.fieldName] ||
      undefined,
  };
};

export const customDashboardColumns = (
  columnsDefs: FieldDefinition[],
  fieldNames: string[]
): AgGridColumnProps[] => {
  const customColumns = fieldNames
    .map(fieldName => columnsDefs.find(c => c.fieldName === fieldName))
    .filter(Boolean)
    .map(column => ({
      ...mapCustomColumn(column),
      sortingOrder: ['asc', 'desc'],
    }));

  return [
    {
      field: 'id',
      headerName: 'id',
      minWidth: 100,
      comparator: noOpComparator,
      hide: true,
      ...filterProps(),
    },
    {
      field: 'id',
      headerName: '#',
      cellRenderer: 'orderCellRenderer',
      minWidth: 60,
      maxWidth: 60,
      sortable: false,
      filter: false,
      floatingFilter: false,
    },
    {
      field: 'name',
      headerName: 'Name',
      minWidth: 100,
      comparator: noOpComparator,
      sortingOrder: ['asc', 'desc'],
      cellRenderer: 'firstNameCellRenderer',
      ...filterProps(),
    },
    {
      field: 'email',
      headerName: 'Email',
      minWidth: 150,
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      sortingOrder: ['asc', 'desc'],
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      cellRenderer: 'emailCellRenderer',
    },
    {
      field: 'phone',
      headerName: 'Phone',
      minWidth: 30,
      width: 142,
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      sortingOrder: ['asc', 'desc'],
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      cellRenderer: 'phoneCellRenderer',
    },
    ...customColumns,
    {
      field: 'createdDate',
      headerName: 'Created Date',
      minWidth: 100,
      cellRenderer: 'dateCellRenderer',
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      sortingOrder: ['asc', 'desc'],
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    {
      field: 'leadId',
      headerName: 'Action',
      minWidth: 70,
      cellRenderer: 'actionCellRenderer',
      comparator: noOpComparator,
      width: 70,
      pinned: 'right',
      lockPinned: true,
    },
    {
      field: 'details',
      headerName: 'Show Details',
      cellRenderer: 'detailsCellRenderer',
      minWidth: 60,
      maxWidth: 60,
      sortable: false,
      filter: false,
      floatingFilter: false,
    },
  ];
};
