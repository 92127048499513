import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import { useBrokersStore } from '../../hooks/useBrokersStore';
import { Broker } from '../../types/brokerTypes';
import { CardItem, EntityCard } from './entityCard';
import { observer } from 'mobx-react';


export interface BrokerCardProps {
  broker: Broker;
}

export const BrokerCard = observer((props: BrokerCardProps) => {
  const { brokersStore } = useBrokersStore();
  const { selectBrokersStore } = brokersStore;
  const { broker } = props;
  const { entityName, entityTaxNumber, brokerId, firstName, lastName, emailAddress } = broker;
  const title = broker?.isReferrer
    ? 'REFERRAL PARTNER'
    : 'BROKER'

  return (
    <Grid item xs={12} md={6} key={brokerId}>
      <EntityCard
        testId={`broker-${brokerId}`}
        title={`${title} INFORMATION`}
        largeCard={false}
        onEdit={selectBrokersStore.canEditBroker ? () => selectBrokersStore.editBroker(broker) : undefined}
        onRemove={selectBrokersStore.canRemoveBroker ? () => selectBrokersStore.removeBroker(broker) : undefined}
      >{broker?.isReferrer ?
        <List>
          {firstName && lastName ? <CardItem label="Contact Name" value={`${firstName} ${lastName}`} /> : <CardItem label="Company Name" value={entityName} />}
          {emailAddress ? <CardItem label="Contact Email" value={emailAddress} /> : <CardItem label="Company Ein Number" value={entityTaxNumber} />}
        </List>
        :
        <List>
          <CardItem label="Company Name" value={entityName} />
          <CardItem label="Company Ein Number" value={entityTaxNumber} />
        </List>
        }
      </EntityCard >
    </Grid >
  );
});
