import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import { Paper } from '@roc/ui';
import { WarningOutlined } from '@material-ui/icons';
const useStyles = makeStyles(theme => ({
  paper: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    backgroundColor: '#FEF0ED',
    color: '#642B20',
    marginBottom: '-26px',
  },
  content: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(1),
  },
  icon: {
    color: theme.palette.warning.light,
  },
  titleContainer: {
    paddingBottom: '0px !important'
  }
}));
export const NewUiBannerMessages = observer((props) => {
  const classes = useStyles();
  const { messages } = props;
  let messageComponents = [];
  messageComponents = messages && messages.map(message => {
    const content: string = message.content;
    return (
      <Grid container className={classes.content} spacing={2}>
        <Grid item>
          <WarningOutlined className={classes.icon} />
        </Grid>
        <Grid item xs={11} className={classes.titleContainer}>
          <Typography>
            <strong>
              {message.title}
            </strong>
            <br />
            <div dangerouslySetInnerHTML={{ __html: content }}></div>
          </Typography>
        </Grid>
      </Grid >
    );
  }
  );
  return (
    <>
      {messages?.length > 0 ? (
        <>
          <Paper className={classes.paper} title="">
            {messageComponents}
          </Paper>
          <br />
        </>) : <Grid />
      }
    </>
  );
});