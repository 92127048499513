import { EnvironmentStore, GlobalStore } from '@roc/feature-app-core';
import { ContentfulClientApi, createClient, CreateClientParams } from 'contentful';
import { configure, makeAutoObservable } from 'mobx';

export class DocumentLibraryContentfulStore {
  globalStore: GlobalStore;
  environmentStore: EnvironmentStore;
  private _client: ContentfulClientApi;
  private _previewClient: ContentfulClientApi;

  constructor(globalStore, environmentStore) {
    this.globalStore = globalStore;
    this.environmentStore = environmentStore;
    makeAutoObservable(this);
  }

  getClient() {
    const { environmentVariables: { contentfulParams } } = this.environmentStore;
    if (!contentfulParams) {
      console.error('contentfulParams is null');
    }
    if (!this._client) {
      this._client = createClient({
        space: contentfulParams.space,
        accessToken: contentfulParams.accessToken,
        environment: contentfulParams.environment
      });
    }
    return this._client;
  }

  getPreviewClient() {
    const { environmentVariables: { contentfulParams } } = this.environmentStore;
    if (!contentfulParams) {
      console.error('contentfulParams is null');
    }
    if (!this._previewClient) {
      this._previewClient = createClient({
        space: contentfulParams.space,
        accessToken: contentfulParams.previewToken,
        environment: contentfulParams.environment,
        host: "preview.contentful.com"
      });
    }
    return this._previewClient;
  }

};

configure({
  enforceActions: 'never',
});
