export interface DrawDqDataInterface {
  tempId: number;
  drawsDqDataId: number;
  drawId: number;
  loanId: number;
  address: string;
  dqAmount?: number;
  appliedDqAmount?: number;
  comments?: string;
  applied?: boolean;
  isSelected?: boolean;
};