import { GlobalStore } from '@roc/feature-app-core';
import { flow, makeObservable, observable, override } from 'mobx';
import { NewLeadBaseService } from '../services/newLeadBaseService';
import { NewLeadService } from '../services/newLeadService';
import { UserDetailsBaseStore } from './userDetailsBaseStore';

export class UserDetailsStore extends UserDetailsBaseStore {

  private newLeadService: NewLeadService;
  public owners: any;

  constructor(globalStore: GlobalStore, newLeadService: NewLeadBaseService) {
    super(globalStore);
    this.newLeadService = newLeadService;
    makeObservable(this, {
      getLeadMetaData: override,
      getCompanyNamesOptions: override,
      getUserBusinessUnit: override,
      fetchOriginators: flow,
      owners: observable
    });
  }

  *getCompanyNamesOptions() {
    const response = yield this.newLeadService.getCompanyNames();
    this.companyNamesOptions = response.data.tpoAllNames;
  }

  *getLeadMetaData(leadType: string) {
    const response = yield this.newLeadService.getLeadMetadata(leadType);
    this.recordTypes = response.data.leadRecordTypes;
    this.leadSourceOptions = response.data.leadSourceOptions;
  }

  *getUserBusinessUnit() {
    const response = yield this.newLeadService.getBusinessUnit();
    this.businessUnit = response.data.businessUnit;
    this.sessionUser = response.data.name;
  }

  *fetchOriginators(userId) {
    const response = yield this.newLeadService.getAllOwnersByProcessor(userId);
    let owners = response?.data?.data;
    this.owners = owners
      .filter(filteredOwner => filteredOwner.active !== false)
      .map(filteredOwner => ({
        value: filteredOwner.firstName + " " + filteredOwner.lastName,
        label: filteredOwner.firstName + " " + filteredOwner.lastName,
        email: filteredOwner.userName
      }));
  }

}
