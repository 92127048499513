import { Paper, Typography } from '@material-ui/core';
import { Layout } from '@roc/feature-app-core';
import { observer } from 'mobx-react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Button, Card, FileUpload, FileUploadModal, IconButton, StandardDialog, TableCard } from '@roc/ui';
import { useCRMPortalStore } from 'apps/crm-portal/src/app/hooks/useCRMPortalStore';
import { useState } from 'react';
import { IonButton, IonIcon, IonModal } from '@ionic/react';
import { CheckCircle } from '@material-ui/icons';
import { green, orange } from '@material-ui/core/colors';


const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    padding: theme.spacing(4),
    marginLeft: '300px',
    marginRight: '300px',
  },
}));

export const ImportLeads = observer(() => {
  const styles = useStyles();
  const { drawRequestStore, payoffNewRequestStore, newLeadStore, userStore, userProfileStore } = useCRMPortalStore();
  const [isUploadOpen, setUploadOpen] = useState(false);

  const closeFileUploadModal = () => {
    setUploadOpen(false);
  };

  const onDownloadTemplateClick = () => {
    downloadTemplate();
  };

  function downloadTemplate() {
    newLeadStore.downloadLeadsTemplate();
  }

  const openFileUploadModal = () => {
    setUploadOpen(true);
  };

  const closeOpenViewLeadsModal = () => {
    newLeadStore.setOpenViewLeadsModal(false);
  };

  const onDocumentUpload = (files: FileUpload[]) => {
    for (const file of files) {
      newLeadStore.uploadLeads(removeAfterPeriod(file.name), file.file, userStore.userInformation.emailAddress);
    }
  };

  function removeAfterPeriod(inputString) {
    const index = inputString.indexOf('.');
    return index >= 0 ? inputString.substring(0, index) : inputString;
  }


  return (
    <Layout>
      <Card title="IMPORT LEADS">
        <Typography variant="h5" paragraph>
          Please follow the instructions below:
        </Typography>
        <Typography variant="body1" paragraph>
          1- Download the template: Begin by downloading the provided template to ensure accurate lead information.
        </Typography>
        <Typography variant="body1" paragraph>
          2- Fill in the leads information: Open the downloaded template and fill in the required lead details accurately.
        </Typography>
        <Typography variant="body1" paragraph>
          3- Upload the filled file: Once you've completed the template, click on 'Upload File' to submit the information.
        </Typography>
        <Button
          testId="uploadFile"
          onClick={openFileUploadModal}
          style={{ marginTop: '16px', marginRight: '8px' }}
          variant="outlined"
          color="primary"
        >
          Upload File
        </Button>
        <Button
          testId="downloadTemplate"
          onClick={downloadTemplate}
          style={{ marginTop: '16px', marginRight: '8px' }}
          variant="outlined"
          color="primary"
        >
          Download Template
        </Button>
        <FileUploadModal
          title={`IMPORT LEADS`}
          open={isUploadOpen}
          multiple
          onClose={closeFileUploadModal}
          onUpload={files => onDocumentUpload(files)}
        />
        <StandardDialog
          open={newLeadStore.openViewLeadsModal}
          handleClose={closeOpenViewLeadsModal}
          dialogContent={
            <>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <CheckCircle style={{ fontSize: 60, color: 'green', marginRight: '10px' }} />
                <br />
                <Typography variant="h5" paragraph>
                  File Uploaded Successfully!
                </Typography>
                <br />
                <Typography variant="h5" paragraph>
                  Upon uploading your leads, you will receive an email notification once the processing is complete.
                </Typography>
              </div>
            </>
          }
        />
      </Card>
    </Layout>
  );
});