import { Theme, ThemeOptions } from '@material-ui/core/styles';
import { Overrides } from '@material-ui/core/styles/overrides';

export type RouteDefinition = {
  path: string;
  url: string;
  documentTitle?: string;
  children?: {
    [route: string]: RouteDefinition;
  } | {
    [route: string]: () => RouteDefinition;
  };
};

export enum DashboardView {
  LIST,
  CARDS,
}

interface AppConfigData {
  fromEmail?: string;
}

export interface AppConfigurations {
  gtmTrackingId?: string;
  baseDomain: string;
  siteName: string;
  contextPath?: string;
  muiTheme: Theme;
  appLogo?: string;
  appLogoBackground?: string;
  loginBanner?: string;
  slogan?: string;
  favIcon?: string;
  dashboardImg?: string;
  data: Record<string, string>;
  allowEditBorrowers?: boolean;
  fontFamilies?: string[];
}

export interface PortalConfigurationData {
  generalConfiguration: {
    name: string;
    fromEmail: string;
    gtmTrackingId: string;
    favIconDocumentId: string;
    appLogoDocumentId: string;
    bannerImageDocumentId: string;
    slogan: string;
  };
  uiThemeConfiguration: {
    palette?: any;
    typography?: any;
    themeOverrides?: ThemeOverrides;
    fontFamilies?: string[];
  };
}

export interface PortalConfiguration {
  id: string;
  companyId: string;
  isDefault: boolean;
  host: string;
  portalConfiguration: PortalConfigurationData;
  previewPortalConfiguration: PortalConfigurationData;
  lastUpdatedDate: number;
}

export interface ThemeOverrides {
  appBar?: {
    backgroundColor?: string;
    color?: string;
    logoBackgroundColor?: string;
  };
  button?: {
    borderRadius?: number;
  };
}

export interface ThemeConfiguration {
  themeOptions: ThemeOptions,
  componentsOverride: (theme: Theme) => Overrides
}

export interface DropdownOption {
  label: string;
  value: any;
}

export interface BreadCrumbItem {
  link: boolean;
  title: string;
  url?: string;
  icon?: JSX.Element;
}

export interface OAuthResponse {
  access_token: string;
  expires_in: number;
  refresh_token_validity_period: number;
  token_inactive_period: number;
  portal_name: string;
  two_factor_authorized: boolean;
}

export type DeployEnvironment = 'LOCAL' | 'DEV' | 'QA' | 'STG' | 'PROD';