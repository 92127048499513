import { Box, Grid, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { CurrencyField, Slider, TextField } from '@roc/ui';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { formatCurrency } from '@roc/ui/utils';
import { TotalAmounts } from './totalAmounts';
import { FixFlipStore } from '../stores';
import { useEffect } from 'react';
import { isNotBlank } from '@roc/feature-utils';

const useStyles = makeStyles(theme =>
  createStyles({
    parentContainer: {
      marginTop: '12px',
    },
    rowContainer: {
      display: 'flex',
      marginTop: '8px',
      alignItems: 'center'
    },
    sectionName: {
      fontSize: '1.2rem',
      marginLeft: '12px'
    },
    slider: {
      marginTop: '2.5em',
      marginLeft: '2em',
      width: '85%',
    },
    grayBackground: {
      backgroundColor: '#E4E8EB'
    },
    totalAmountCard: {
      marginTop: theme.spacing(4),
    },
    labelFieldContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start'
    },
    label: {
      transform: 'translate(14px, -6px) scale(0.75)',
      color: 'rgba(0, 0, 0, 0.54)',
      marginBottom: '-24px',
      marginLeft: '-16px',
    },
  })
);

const calculateLTC = (initialAmount, purchasePrice) => {
  if (!initialAmount || !purchasePrice) {
    return 0;
  }
  return initialAmount / purchasePrice * 100;
}

export const LeverageException = observer(({ store }: { store: FixFlipStore }) => {
  const classes = useStyles();
  const { loanInformationStore } = store;
  const formFields = loanInformationStore.form.fields;

  useEffect(() => {
    loanInformationStore.initializeExceptionValues();
  }, []);

  return (
    <Grid>
      <Grid>
        <Typography variant="body1">
          You may request higher leverage here. These exceptions should have strong credit, great experience, and good profit. Please provide any additional information for your leverage request. The underwriter will review all information provided at time of prescreen and provide feedback on your request.
        </Typography>
      </Grid>
      {/* Exception values here */}
      <Grid className={classes.parentContainer} container spacing={2}>
        <Grid item xs={6} className={classes.rowContainer}>
          <Grid xs={12}>
            <CurrencyField
              testId="initialLoanAmount"
              fullWidth
              label="Initial Loan Amount"
              variant="outlined"
              value={formFields.exceptionInitialLoanAmount.value}
              onChange={(name, value) => {
                loanInformationStore.changeExceptionInitialLoanAmount('exceptionInitialLoanAmount', value);
              }}
              decimalScale={0}
              disabled={false}
            />
          </Grid>
        </Grid>
        <Grid item xs={6} className={`${classes.rowContainer} ${classes.labelFieldContainer}`}>
          <Box>
            <Typography className={classes.label}>Initial Loan Amount/Purchase Price</Typography>
          </Box>
          <Slider
            testId={'Initial Loan Amount/Purchase Price'}
            label={'Initial Loan Amount/Purchase Price'}
            value={formFields.exceptionInitialLoanToPurchase.value}
            onChangeCommitted={(e, value) => {
              loanInformationStore.changeExceptionInitialLoanToPurchase('exceptionInitialLoanToPurchase', value);
            }}
            valueLabelDisplay={'on'}
            className={classes.slider}
            min={0}
            max={90}
            step={1}
            valueLabelFormat={value => <div>{value}%</div>}
            marks={[
              {
                value: 0,
                label: `0%`,
              },
              {
                value: 90,
                label: '90%',
              },
            ]}
            disabled={false}
          />
        </Grid>
        <Grid item xs={6} className={classes.rowContainer}>
          <Grid xs={12}>
            <CurrencyField
              testId="constructionHoldback"
              fullWidth
              label="Construction Holdback"
              variant="outlined"
              value={formFields.exceptionConstructionHoldback.value}
              onChange={(name, value) => {
                loanInformationStore.changeExceptionConstructionHoldback('exceptionConstructionHoldback', Number(value));
              }}
              decimalScale={0}
              disabled={false}
            />
          </Grid>
        </Grid>
        <Grid item xs={6} className={`${classes.rowContainer} ${classes.labelFieldContainer}`}>
          <Box>
            <Typography className={classes.label} style={{ marginLeft: '-24px' }}>Construction Holdback/Renovation Budget</Typography>
          </Box>
          <Slider
            testId={'Construction Holdback/Renovation Budget'}
            value={formFields.exceptionConstructionHoldbackPercentage.value}
            onChangeCommitted={(e, value) => {
              loanInformationStore.changeExceptionConstructionHoldbackPercentage('exceptionConstructionHoldbackPercentage', value);
            }}
            valueLabelDisplay={'on'}
            className={classes.slider}
            min={0}
            max={100}
            step={1}
            valueLabelFormat={value => <div>{value}%</div>}
            marks={[
              {
                value: 0,
                label: `0%`,
              },
              {
                value: 100,
                label: '100%',
              },
            ]}
            disabled={false}
          />
        </Grid>
        <Grid item xs={12} className={classes.rowContainer}>
          <TextField
            testId="exceptionLeverageReasonUnconfirmed"
            variant="outlined"
            multiline
            label="Please Provide a Reason"
            value={formFields.exceptionLeverageReasonUnconfirmed.value}
            onChange={e => loanInformationStore.onFieldChange('exceptionLeverageReasonUnconfirmed', e.target.value)}
            fullWidth
            inputProps={{ maxLength: 1000 }}
            minRows={3}
            maxRows={Infinity}
            helperText={formFields.exceptionLeverageReasonUnconfirmed.error}
            error={isNotBlank(formFields.exceptionLeverageReasonUnconfirmed.error)}
          />
        </Grid>
      </Grid>

      {/* Totals */}
      <TotalAmounts
        store={store}
        initialAmount={formFields.exceptionInitialLoanAmount.value}
        ltc={calculateLTC(formFields.exceptionInitialLoanAmount.value, loanInformationStore.getTotalPurchasePrice())}
      />

      {/* Loan Amount */}
      <Grid className={classes.parentContainer}>
        <Card variant="outlined" className={classes.totalAmountCard}>
          <CardContent>
            <Typography align="center" variant="h6" color="primary">
              Total Loan Amount
            </Typography>

            <Typography align="center" variant="h4">
              {formatCurrency(formFields.exceptionAmount.value)}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
});

export default LeverageException;
