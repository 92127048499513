import { Layout } from "@roc/feature-app-core";
import { useEffect } from "react";
import { useLoanStore } from '@roc/feature-loans';
import { useParams } from "react-router";
import { PopupModal } from 'react-calendly';
import { observer } from "mobx-react";
import { useHistory } from "react-router";
import { Box, Typography, Button } from "@material-ui/core";
import { StandardDialog } from "@roc/ui";

export const CalendlyPage = observer(() => {
  const { loanId } = useParams<{ loanId: string }>();
  const { loanStore } = useLoanStore();
  const { push } = useHistory();

  useEffect(() => {
    loanStore.getCalendlyPreferences(loanId);
  }, []);

  const { loanDetails } = loanStore;
  const calendlyPreferences = loanStore.calendlyPreferences;
  const prefill = calendlyPreferences.calendlyPrefill;
  const showCalendly = calendlyPreferences.showCalendly;
  const calendlyUrl = calendlyPreferences.calendlyUsers[loanDetails?.loanSeniorUnderwriter?.userId]?.calendlyUrl

  return (
    <Layout>
      {loanStore.calendlyPreferences.successResponse && <>
        <PopupModal
          url={`https://calendly.com/${calendlyUrl}`}
          prefill={prefill}
          onModalClose={() => { push(`/lender-profile/loans/${loanId}/dashboard`) }}
          open={showCalendly}
          /*
           * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
           * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
           */
          rootElement={document.getElementById("root")}
        />
        <StandardDialog
          open={!showCalendly}
          maxWidth="sm"
          title={'Notification'}
          dialogContent={
            <Box display={'flex'} alignItems={'center'} width={'100%'} height={'100%'} flexDirection={'column'}>
              <Typography variant="h6">Sorry, this link is no longer valid</Typography>
            </Box>}
          dialogActions={
            <Box style={{ width: '90%', display: 'flex', justifyContent: 'flex-end' }}>
              <Button color='primary' variant='contained' onClick={() => push(`/lender-profile/loans/${loanId}/dashboard`)} style={{ margin: '0 10px' }}>Close</Button>
            </Box>
          } />
      </>}
    </Layout>
  );
});