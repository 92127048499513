import { Checkbox, FormControlLabel, Grid } from '@material-ui/core';
import { Button, TextField } from '@roc/ui';
import { useDocumentStore } from '@roc/feature-documents';
import { observer } from 'mobx-react';
import React, { ChangeEvent } from 'react';

type ExceptionsFormProps = {
  disabled?: boolean;
  onClose: () => void;
  onSave: () => void;
};

export const ExceptionsForm = observer((props: ExceptionsFormProps) => {
  const { disabled, onClose, onSave } = props;

  const {
    documentStore: { documentFormStore },
  } = useDocumentStore();
  const { insuranceApprovalStore } = documentFormStore;
  const {
    canSubmitInsurance,
    exceptionsFormStore,
    canApprove,
  } = insuranceApprovalStore;
  const { ignoreFloodCheck, ignoreFloodCheckReason } = exceptionsFormStore;

  const onIgnoreFloodCheckChange = (e: ChangeEvent, checked: boolean) => {
    exceptionsFormStore.setIgnoreFloodCheck(checked);
  };

  const onIgnoreFloodCheckReasonChange = e => {
    exceptionsFormStore.setIgnoreFloodCheckReason(e.target.value);
  };

  const saveFloodException = () => {
    exceptionsFormStore.saveFloodException(onSave);
  };

  return (
    <Grid container spacing={5} direction="row">
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={ignoreFloodCheck}
                  onChange={onIgnoreFloodCheckChange}
                  color="secondary"
                  disabled={!canApprove}
                />
              }
              label="Ignore flood quote requirement"
            />
          </Grid>
          {ignoreFloodCheck && (
            <Grid item xs={12}>
              <TextField
                label="Please explain why the flood quote is not required"
                standaloneLabel
                value={ignoreFloodCheckReason}
                onChange={onIgnoreFloodCheckReasonChange}
                testId="ignoreFloodCheckReason"
                variant="outlined"
                multiline
                minRows={4}
                fullWidth
                disabled={!canApprove}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
      {canSubmitInsurance && (
        <Grid item xs={12}>
          <Grid container spacing={1} justifyContent="flex-end">
            <Grid item>
              <Button
                onClick={onClose}
                variant="outlined"
                color="primary"
                testId="cancel"
              >
                Close
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={saveFloodException}
                variant="contained"
                color="primary"
                testId="save"
                disabled={!canApprove}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
});

export default ExceptionsForm;
