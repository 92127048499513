import {
  Box,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { useLoanStore } from '@roc/feature-loans-shared';
import { Paper } from '@roc/ui';
import { useEffect, useState } from 'react';
import { BorderColor, WarningOutlined } from '@material-ui/icons';
import { Variant } from '@material-ui/core/styles/createTypography';
import { fontWeight } from 'html2canvas/dist/types/css/property-descriptors/font-weight';
import orange from "@material-ui/core/colors/orange";
import { useExtensionRequestStore } from '../../../../../feature-loans/src/loans/loanDetails/modules/loanRequests/hooks/useExtensionRequestStore';
import { BorrowerPortalCard } from '@roc/feature-borrower-portal-core';
import { Alert } from '@material-ui/lab';
import { observer } from 'mobx-react';

const useStyles = makeStyles(theme => ({
  card: {
    background: '#ffeec2',
    borderColor: '#ffeec2',
    '& .MuiCardHeader-content *': {
      color: 'inherit'
    }
  },
  title: {
    fontWeight: 400,
    marginLeft: 50,
    marginTop: -35,
    fontFamily: 'Roboto Condensed, sans-serif',
    lineHeight: 1.334,
    fontSize: '1.5rem',
    color: '#000000',
  },
  subtitle: {
    fontFamily: 'Roboto Condensed, sans-serif',
    lineHeight: 1.334,
    fontSize: 11.5,
    color: '#808080',
    paddingLeft: '14px',
  },
  icon: {
    color: theme.palette.warning.light,
    marginTop: 17,
    marginLeft: 18,
  },
  customBorder: {
    border: `2px solid ${orange[200]}`,
    height: '240px',
  },
}));

const listItem = (label, value, divider: boolean = true) => {
  return (
    (
      <>
        {divider && <Divider variant="middle" component="li" />}
        <ListItem style={{ marginTop: '0px', marginBottom: '-15px' }}>
          <ListItemText>
            <Box display="flex">
              <Box flexGrow={1}>{label} </Box>
              <span />
              <Box >{value}</Box>
            </Box>
          </ListItemText>
        </ListItem>
      </>
    )
  );
};

export interface ExtensionsWarningsProps {
  isFullWidth?: boolean;
}

const ExtensionsWarnings = ({ isFullWidth }: ExtensionsWarningsProps) => {
  const { loanStore } = useLoanStore();
  const { extensionRequestStore } = useExtensionRequestStore();
  const classes = useStyles();
  const [insurace, setInsurance] = useState("");
  const date = new Date();
  const t = new Date();
  const dd = date.getDate();
  const mm = date.getMonth() + 1;
  const yyyy = date.getFullYear();
  const currentDate = new Date(mm + "/" + dd + "/" + yyyy);
  const extendedMaturityDate = new Date(loanStore.loanDetails.extendedMaturityDate);
  const nextDueDate = new Date(loanStore.loanDetails.calculatedLoanData.nextDueDate);
  return (
    <Grid item xs={12} md={12} style={isFullWidth ? { padding: '24px 0px 0px 0px' } : {}}>
      <Paper className={classes.customBorder}>
        <WarningOutlined className={classes.icon} />
        <Typography className={classes.title}>EXTENSION WARNINGS</Typography>
        <Typography className={classes.subtitle}>You might need approval to process this extension for the below stated reasons</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Grid container direction="column" style={{ marginTop: '2px' }}>
              {(extensionRequestStore.differenceInMonths(nextDueDate, currentDate)) > 0 &&
                <List>
                  {listItem('Loan is Delinquent', 'Payment Due', false)}
                </List>
              }
              {(extensionRequestStore.differenceInMonths(extendedMaturityDate, currentDate)) > 0 &&
                <List>
                  {listItem('Extension Expired', '')}
                </List>
              }
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>

  );
};

export const ExtensionsWarningsV2 = observer(() => {
  const classes = useStyles();
  const { loanStore } = useLoanStore();
  const { extensionRequestStore } = useExtensionRequestStore();
  const date = new Date();
  const dd = date.getDate();
  const mm = date.getMonth() + 1;
  const yyyy = date.getFullYear();
  const currentDate = new Date(mm + "/" + dd + "/" + yyyy);
  const extendedMaturityDate = new Date(loanStore.loanDetails.extendedMaturityDate);
  const nextDueDate = new Date(loanStore.loanDetails.calculatedLoanData.nextDueDate);
  return (
    <BorrowerPortalCard
      cardTitle={'Extension Warnings'}
      cardSubTitle={'You might need approval to process this extension for the below stated reasons'}
      cardRootClassName={classes.card}>
      {(extensionRequestStore.differenceInMonths(nextDueDate, currentDate)) > 0 &&
        <Box my={1}>
          <Alert severity='warning'>
            <span>Loan is Delinquent</span>
            <span> - </span>
            <strong>Payment Due</strong>
          </Alert>
        </Box>
      }
      {(extensionRequestStore.differenceInMonths(extendedMaturityDate, currentDate)) > 0 &&
        <Box my={1}>
          <Alert severity='warning'>
            <span>Extension Expired</span>
          </Alert>
        </Box>
      }
    </BorrowerPortalCard>
  );
});

export default ExtensionsWarnings;