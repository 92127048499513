import { observer } from "mobx-react";
import { Grid, makeStyles } from '@material-ui/core';
import LoanInformation from "libs/feature-loans/src/loanSubmission/fixflipGroundUp/components/loanInformation";
import { Button, Paper } from "@roc/ui";
import { useHistory } from "react-router";
import { useRoutes } from "@roc/client-portal-shared/hooks";
import { useEffect } from "react";

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(4),
  },
}));

export const EditLoanTerms = observer(props => {
  const { store, refreshLoan } = props;
  const { editLoansStore } = store;
  const classes = useStyles();
  const { push } = useHistory();
  const { loanRoutesConfig } = useRoutes();

  useEffect(() => {
    editLoansStore.loadStore(store.loanDetails);

    return () => {
      store.reset();
    };
  }, []);

  return (
    <Paper className={classes.container}>
      <LoanInformation store={editLoansStore} editMode={true} />
      <Grid container direction='row' justifyContent='flex-end' spacing={2}>
        <Grid item>
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={() => {
              editLoansStore.updateLoanTerms(() => {
                push(
                  loanRoutesConfig.loans(store.loanDetails?.loanId).children
                    .dashboard.url
                )
                editLoansStore.reset();
                refreshLoan();
              });
            }}
            testId="saveLoanTerms"
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
});