import { Page } from '@roc/feature-app-core';
import { Route } from 'react-router';
import { PayablesDashboard } from '../components/payables/payablesDashboard';
import { ReceivablesDashboard } from '../components/receivables/receivablesDashboard';
import { ReceivablesBillsDashboard } from '../components/receivablesBills/receivablesBillsDashboard';
import { SpecialServicingLoanDashboard } from '../components/loan/specialServicingLoanDashboard';
import { PayablesApprovedDashboard } from '../components/payables/payablesApprovedDashboard';
import { PayableAttestationDashboard } from '../components/Attestation/payableAttestationDashboard';

export const getSpecialServicingRoutesConfig = (
  basePath: string,
  baseUrl: string,
  loanId: any,
  type: any = '',
) => {
  return {
    specialServicingReceivableDashboard: {
      path: `${basePath}/special-servicing/receivables-dashboard`,
      url: `${baseUrl}/special-servicing/receivables-dashboard`,
      documentTitle: 'Special Servicing Receivables Dashboard',
    },
    specialServicingPayableDashboard: {
      path: `${basePath}/special-servicing/payables-dashboard`,
      url: `${baseUrl}/special-servicing/payables-dashboard`,
      documentTitle: 'Special Servicing Payable Dashboard',
    },
    specialServicingReceivableBillsDashboard: {
      path: `${basePath}/special-servicing/receivables-bills-dashboard`,
      url: `${baseUrl}/special-servicing/receivables-bills-dashboard`,
      documentTitle: 'Special Servicing Receivables Bills Dashboard',
    },
    specialServicingLoanDashboard: {
      path: `${basePath}/special-servicing/loan-dashboard`,
      url: `${baseUrl}/special-servicing/loan-dashboard`,
      documentTitle: `Special Servicing Loan=[${loanId}] Dashboard`,
    },
    specialServicingPayablesApprovedDashboard: {
      path: `${basePath}/special-servicing/payables-approved-dashboard`,
      url: `${baseUrl}/special-servicing/payables-approved-dashboard`,
      documentTitle: `Special Servicing Approved Dashboard`,
    },
    specialServicingAttestationDashboard: {
      path: `${basePath}/special-servicing/attestation`,
      url: `${baseUrl}/special-servicing/attestation`,
      documentTitle: `Attestation`,
      loanId: { loanId },
      type: { type }
    },
  };
};

export const getSpecialServicingRoutes = (basePath: string, baseUrl: string, loanId: any, type: any = '') => {
  const config = getSpecialServicingRoutesConfig(basePath, baseUrl, loanId, type);

  return {
    specialServicingReceivableDashboard: (
      <Route exact path={config.specialServicingReceivableDashboard.path}>
        <Page routeDefinition={config.specialServicingReceivableDashboard}>
          <ReceivablesDashboard />
        </Page>
      </Route>
    ),
    specialServicingPayableDashboard: (
      <Route exact path={config.specialServicingPayableDashboard.path}>
        <Page routeDefinition={config.specialServicingPayableDashboard}>
          <PayablesDashboard />
        </Page>
      </Route>
    ),
    specialServicingReceivableBillsDashboard: (
      <Route exact path={config.specialServicingReceivableBillsDashboard.path}>
        <Page routeDefinition={config.specialServicingReceivableBillsDashboard}>
          <ReceivablesBillsDashboard />
          {/*<PayablesApprovedDashboard />*/}
        </Page>
      </Route>
    ),
    specialServicingLoanDashboard: (
      <Route exact path={config.specialServicingLoanDashboard.path}>
        <Page routeDefinition={config.specialServicingLoanDashboard}>
          <SpecialServicingLoanDashboard loanId={loanId} />
        </Page>
      </Route>
    ),
    specialServicingPayablesApprovedDashboard: (
      <Route exact path={config.specialServicingPayablesApprovedDashboard.path}>
        <Page routeDefinition={config.specialServicingPayablesApprovedDashboard}>
          <PayablesApprovedDashboard />
        </Page>
      </Route>
    ),
    specialServicingAttestationDashboard: (
      <Route exact path={config.specialServicingAttestationDashboard.path}>
        <Page routeDefinition={config.specialServicingAttestationDashboard}>
          <PayableAttestationDashboard loanId={config.specialServicingAttestationDashboard.loanId} type={config.specialServicingAttestationDashboard.type} />
        </Page>
      </Route>
    ),
  };
};
