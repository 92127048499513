export enum LoanSubtypeOption {
  SINGLE_PROPERTY = 'Single Property Rental',
  FIVE_PLUS = '5+ Units',
  PORTFOLIO = 'Rental Portfolios',
  LARGE_PORTFOLIO = 'Large Rental Portfolios',
}

export const loanSubtypeOptions = [
  {
    label: 'Single Property Rental',
    value: LoanSubtypeOption.SINGLE_PROPERTY,
  },
  {
    label: '5+ Units',
    value: LoanSubtypeOption.FIVE_PLUS,
  },
  {
    label: 'Small Portfolios (≤10 properties AND UPB ≤$2MM)',
    value: LoanSubtypeOption.PORTFOLIO,
  },
  {
    label: 'Large Portfolios (>10 properties OR UPB >$2MM)',
    value: LoanSubtypeOption.LARGE_PORTFOLIO,
  },
];
