import { Route } from 'react-router';
import { useStore } from '@roc/client-portal-shared/hooks';
import { useRocInternalPortalStore } from '../../../hooks';
import { RocUniversity } from '@roc/client-portal-shared/modules/rocUniversity';
import { Page } from '@roc/feature-app-core';

export const getRocUniversityInternalRoutesConfig = (
  basePath: string,
  baseUrl: string
) => {
  return {
    rocUniversity: {
      path: `${basePath}/roc-university`,
      url: `${baseUrl}/roc-university`,
      documentTitle: 'Roc University',
    },
  };
};

export const getRocUniversityInternalRoutes = (
  basePath: string,
  baseUrl: string
) => {
  const config = getRocUniversityInternalRoutesConfig(basePath, baseUrl);
  const { rocUniversityInternalStore } = useRocInternalPortalStore();

  return {
    rocUniversity: (
      <Route exact path={config.rocUniversity.path}>
        <Page routeDefinition={config.rocUniversity}>
          <RocUniversity rocUniversityStore={rocUniversityInternalStore} />
        </Page>
      </Route>
    ),
  };
};
