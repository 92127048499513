import {
  isNotBlank,
} from '@roc/feature-utils';
import {
  CurrencyField,
} from '@roc/ui';
import { observer } from 'mobx-react';


export const MonthlyMarketRent = observer(({ store }) => {
  const { form } = store;
  return (
    <CurrencyField
      variant="outlined"
      standaloneLabel
      label="Monthly Market Rent"
      value={form.fields.monthlyMarketRentProvidedAtOrigination.value}
      onChange={(name, value) => store.onFieldChange('monthlyMarketRentProvidedAtOrigination', Number(value))}
      fullWidth
      testId="monthlyMarketRentProvidedAtOrigination"
      required
      error={isNotBlank(form.fields.monthlyMarketRentProvidedAtOrigination.error)}
      errorText={form.fields.monthlyMarketRentProvidedAtOrigination.error}
    />
  );
});

export const AnnualTaxes = observer(({ store }) => {
  const { form } = store;

  return (
    <CurrencyField
      variant="outlined"
      standaloneLabel
      label="Annual Taxes"
      value={form.fields.annualTaxesProvidedAtOrigination.value}
      onChange={(name, value) => store.onFieldChange('annualTaxesProvidedAtOrigination', Number(value))}
      fullWidth
      testId="annualTaxesProvidedAtOrigination"
      required
      error={isNotBlank(form.fields.annualTaxesProvidedAtOrigination.error)}
      errorText={form.fields.annualTaxesProvidedAtOrigination.error}

    />
  );
});

export const AnnualInsurance = observer(({ store }) => {
  const { form } = store;
  return (
    <CurrencyField
      variant="outlined"
      standaloneLabel
      label="Annual Insurance"
      value={form.fields.annualInsuranceProvidedAtOrigination.value}
      onChange={(name, value) => store.onFieldChange('annualInsuranceProvidedAtOrigination', Number(value))}
      fullWidth
      testId="annualInsuranceProvidedAtOrigination"
      required
      error={isNotBlank(form.fields.annualInsuranceProvidedAtOrigination.error)}
      errorText={form.fields.annualInsuranceProvidedAtOrigination.error}
    />
  );
});

export const AnnualHoa = observer(({ store }) => {
  const { form } = store;
  return (
    <CurrencyField
      variant="outlined"
      standaloneLabel
      label="Annual HOA Dues"
      value={form.fields.annualHOAProvidedAtOrigination.value}
      onChange={(name, value) => store.onFieldChange('annualHOAProvidedAtOrigination', Number(value))}
      fullWidth
      testId="annualHOAProvidedAtOrigination"
      required
      error={isNotBlank(form.fields.annualHOAProvidedAtOrigination.error)}
      errorText={form.fields.annualHOAProvidedAtOrigination.error}
    />
  );
});
