import { Grid, Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import {
  AutocompleteField,
  Button,
  createAutocompleteField,
  FieldLabel,
  PhoneField,
  SelectField,
  TextField,
} from '@roc/ui';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { isNotBlank } from '@roc/client-portal-shared/utils';
import { useNewLeadStore } from '../../hooks/useNewLeadStore';
import { Broker, TPO } from '../../utils/leadConstants';
import { Autocomplete } from '@material-ui/lab';
import { useStore } from '@roc/client-portal-shared/hooks';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    background: {
      display: 'flex',
      alignItems: 'start',
    },
    btnFilled: {
      backgroundColor: '#1565c0',
      color: 'white',
      '&:hover': {
        backgroundColor: '#2196f3',
        boxShadow: 'none',
      },
    },
    btnFilledAlign: {
      backgroundColor: '#1565c0',
      color: 'white',
      '&:hover': {
        backgroundColor: '#2196f3',
        boxShadow: 'none',
      },
      marginTop: '30px',
    },
    btnOutlined: {
      color: '#1565c0',
    },
    input: {
      width: '100%',
      '& .MuiOutlinedInput-root': {
        '&:hover fieldset': {
          borderColor: '#1565c0',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#64b5f6',
        },
      },
    },
    helperTextUserApproved: {
      color: 'green',
      textAlign: 'end',
    },
    helperTextUserNotApproved: {
      color: '#ff9800',
      textAlign: 'end',
    },
    title: {
      color: '#616161',
      marginTop: '20px',
    },
    userInfoSection: {
      paddingTop: '20px',
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    newCompanyCreationMessage: {
      color: 'green',
    },
    searcHelperText: {
      marginBottom: '50px',
    },
  })
);

export const UserDetails = observer(() => {
  const classes = useStyles();
  const { newLeadStore } = useNewLeadStore();
  const { userDetailStore, globalStore } = newLeadStore;
  const {
    form,
    leadSourceOptions,
    companyNamesOptions,
    onSelectedCompany,
  } = userDetailStore;
  const {
    email,
    firstName,
    lastName,
    phoneNumber,
    companyName,
    leadSource,
    ownerEmail,
  } = form.fields;
  const { salesforceLeadStore } = useStore();

  const options = [
    {
      label: "Borrower",
      value: "Borrower",
    },
    {
      label: "TPO",
      value: "TPO",
    },
    {
      label: "Broker",
      value: "Broker",
    }
  ];

  const variant = 'outlined';

  const fetchData = async () => {
    if (!newLeadStore.isBackofficeOriginator()) {
      if (newLeadStore.isBackofficeLead()) {
        await salesforceLeadStore.getAllOwners();
      } else {
        const userId = newLeadStore.getUserInformation().userId;
        await userDetailStore.fetchOriginators(userId);
      }
    }
  };

  useEffect(() => {
    userDetailStore.fetchCompanyNamesOptions();
    userDetailStore.fetchMetadata(newLeadStore.leadType);
    if (globalStore.userFeatures.backOfficeLead || globalStore.userFeatures.isBackOfficeProcessor) {
      userDetailStore.onFieldChange('ownerRequired', true);
    }
    fetchData();
    if (newLeadStore.activeStep !== 1) {
      return () => {
        newLeadStore.resetValues();
      };
    }
  }, []);

  useEffect(() => {
    if (newLeadStore.leadType) {
      userDetailStore.fetchMetadata(newLeadStore.leadType);
    }
  }, [userDetailStore, newLeadStore.leadType]);

  const getCompanyNames = () => {
    const currentOption = companyName.value;
    const companyOptions = getOptions(companyNamesOptions);
    if (!companyOptions.find(option => option.label === currentOption)) {
      return [
        ...companyOptions,
        {
          label: `Add "${companyName.value}"`,
          value: -1,
          originalName: companyName.value,
        },
      ];
    }
    return companyOptions;
  };

  const getOptions = array => {
    return (
      array?.map(element => ({
        label: element,
        value: element,
        id: element,
      })) || []
    );
  };

  const handleDetails = () => {
    newLeadStore.checkingMail = false;
    newLeadStore.goNextStep();
    if (newLeadStore.stepError !== '') {
      newLeadStore.findMatch = false;
      newLeadStore.notLoad = false;
    } else {
      newLeadStore.getPossibleMatches();
    }
  };

  const handlePreviousStep = () => {
    newLeadStore.dissableSubmit = true;
    newLeadStore.showCheckbox = false;
    userDetailStore.disabled = false;
    newLeadStore.goPrevStep();
  };

  const setSelectedOption = (value) => {
    newLeadStore.setLeadType(value);
  }

  return (
    <form>
      <Grid container xs={12}>
        <Grid item xs={12}>
          <Typography className={classes.searcHelperText}>
            Please enter the new lead's information to continue.
          </Typography>
        </Grid>
        <Grid container className={classes.background}>
          <Grid container xs={12} spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                label="First Name"
                required
                testId="firstName"
                variant={variant}
                standaloneLabel
                error={isNotBlank(firstName.error)}
                helperText={firstName.error}
                InputLabelProps={{ shrink: true }}
                value={firstName?.value}
                onChange={e =>
                  userDetailStore.onFieldChange('firstName', e.target.value)
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Last Name"
                required
                testId="lastName"
                variant={variant}
                standaloneLabel
                error={isNotBlank(lastName.error)}
                helperText={lastName.error}
                InputLabelProps={{ shrink: true }}
                value={lastName?.value}
                onChange={e =>
                  userDetailStore.onFieldChange('lastName', e.target.value)
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <PhoneField
                label="Phone Number"
                testId="phoneNumber"
                standaloneLabel
                variant={variant}
                fullWidth
                inputClassname={classes.input}
                value={phoneNumber?.value}
                error={isNotBlank(phoneNumber.error)}
                errorText={phoneNumber.error}
                onChange={(name, value) =>
                  userDetailStore.onFieldChange(
                    'phoneNumber',
                    value.match(/\d+/g) ? value.match(/\d+/g).join('') : ''
                  )
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Email"
                type="email"
                required
                testId="Email"
                variant={variant}
                standaloneLabel
                InputLabelProps={{ shrink: true }}
                value={email?.value}
                error={isNotBlank(email.error)}
                helperText={email.error}
                fullWidth
                onChange={e =>
                  userDetailStore.onFieldChange('email', e.target.value)
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FieldLabel>Lead Type</FieldLabel>
              <Box display="flex">
                <SelectField
                  required={true}
                  variant="outlined"
                  value={newLeadStore.leadType}
                  options={options}
                  onChange={e =>
                    setSelectedOption(e)}
                  fullWidth
                  testId="leadType"
                />
              </Box>
            </Grid>
            {(newLeadStore.leadType === TPO || newLeadStore.leadType === Broker) && (
              <Grid item xs={12} md={6}>
                <Box display="flex">
                  <AutocompleteField
                    className={classes.input}
                    label="Company Name"
                    placeHolder="Search"
                    required
                    options={getCompanyNames()}
                    error={isNotBlank(companyName.error)}
                    helperText={companyName.error}
                    value={companyName.value}
                    onChange={newValue => {
                      userDetailStore.onCompanyFieldChange(
                        'companyName',
                        newValue
                      );
                    }}
                    onSelect={(option: any) => {
                      onSelectedCompany(option);
                    }}
                  />
                </Box>
              </Grid>
            )}
            <Grid item xs={12} md={6}>
              <FieldLabel>Lead Source</FieldLabel>
              <Autocomplete
                id={'leadSource'}
                value={{ label: leadSource.value }}
                options={getOptions(leadSourceOptions)}
                renderInput={params => (
                  <TextField
                    testId="leadSource"
                    variant={'outlined'}
                    error={isNotBlank(leadSource.error)}
                    helperText={leadSource?.error}
                    {...params}
                  />
                )}
                getOptionLabel={(option: any) => option?.label ?? ''}
                getOptionSelected={(option, selectedOption) =>
                  option.label === selectedOption.label
                }
                onChange={(event, option) =>
                  userDetailStore.onFieldChange('leadSource', option.value)
                }
                fullWidth
              />
            </Grid>
            {newLeadStore.isBackofficeProcessor() && userDetailStore.owners && (
              <Grid item xs={12} md={6}>
                <FieldLabel>Owner Name</FieldLabel>
                <Autocomplete
                  id={'ownerEmail'}
                  value={{ label: ownerEmail.value }}
                  options={userDetailStore.owners}
                  renderInput={params => (
                    <TextField
                      testId="ownerEmail"
                      variant={'outlined'}
                      error={isNotBlank(ownerEmail.error)}
                      helperText={ownerEmail?.error}
                      {...params}
                    />
                  )}
                  getOptionLabel={(option: any) => option?.label ?? ''}
                  getOptionSelected={(option, selectedOption) =>
                    option.label === selectedOption.label
                  }
                  onChange={(event, option) =>
                    userDetailStore.onFieldChange('ownerEmail', option.value)
                  }
                  fullWidth
                />
              </Grid>
            )}
            {newLeadStore.isBackofficeLead() && salesforceLeadStore.allActiveOwners && (
              <Grid item xs={12} md={6}>
                <FieldLabel>Owner Name</FieldLabel>
                <Autocomplete
                  id={'ownerEmail'}
                  value={{ label: ownerEmail.value }}
                  options={salesforceLeadStore.allActiveOwners}
                  renderInput={params => (
                    <TextField
                      testId="ownerEmail"
                      variant={'outlined'}
                      error={isNotBlank(ownerEmail.error)}
                      helperText={ownerEmail?.error}
                      {...params}
                    />
                  )}
                  getOptionLabel={(option: any) => option?.label ?? ''}
                  getOptionSelected={(option, selectedOption) =>
                    option.label === selectedOption.label
                  }
                  onChange={(event, option) =>
                    userDetailStore.onFieldChange('ownerEmail', option.label)
                  }
                  fullWidth
                />
              </Grid>
            )}
            <Grid item xs={12}>
              {userDetailStore.checkCompany && (
                <Typography className={classes.newCompanyCreationMessage}>
                  {
                    'THIS IS A NEW COMPANY NAME THAT IS NOT CURRENTLY IN CRM. PROCEED.'
                  }
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" pt={8}>
            <Grid container spacing={2} justifyContent="flex-end">
              <Grid item xs={6} md={3}>
                <Button
                  variant="outlined"
                  className={classes.btnOutlined}
                  onClick={handlePreviousStep}
                  fullWidth
                  testId="back"
                >
                  Back
                </Button>
              </Grid>
              <Grid item xs={6} md={3}>
                <Button
                  variant="outlined"
                  className={classes.btnFilled}
                  onClick={handleDetails}
                  fullWidth
                  testId="Next"
                >
                  Next
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
});
