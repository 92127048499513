import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import {
  AuthorizedSignatory,
  CellPhone,
  CitizenshipStatus,
  CollectCreditReportWithMessage,
  EmailAddressDuplicated,
  FirstName,
  LastName,
  OwnershipNotKnown,
  PercentOwnership,
  PersonalGuarantor,
  RoleInTransaction,
  Liquidity
} from '@roc/ui/formComponents';
import { FormStore, useBaseStore } from '@roc/feature-app-core';
import { observer } from 'mobx-react';
import { SelectBorrowersStore } from '@roc/feature-borrowers';
import { MultiFamilyStore } from '../../stores';
import { BorrowerExperienceRealStateMultifamily } from './borrowerExperienceRealStateMultifamily';
import { BorrowerCreditScore } from './borrowerCreditScore';
import { Borrower } from '@roc/feature-types';

interface BorrowerFormProps {
  store: FormStore;
  selectBorrowersStore: SelectBorrowersStore;
  multiFamilyStore: MultiFamilyStore;
  currentBorrower?: Borrower;
}

const useStyles = makeStyles(theme =>
  createStyles({
    slider: {
      marginTop: '2.5em',
      marginLeft: '2em',
      width: '85%',
    },
    paragraph: {
      marginLeft: '2em',
    },
    textItalic: {
      fontStyle: 'italic',
    },
  })
);

export const BorrowerFormMultifamily = observer(
  ({ store, selectBorrowersStore, multiFamilyStore, currentBorrower }: BorrowerFormProps) => {
    const classes = useStyles();
    const { globalStore } = useBaseStore();
    currentBorrower = currentBorrower ?? selectBorrowersStore.currentBorrower;
    const checkBorrowerExistingInFunded =
      currentBorrower?.portalProjectsCompleted > 0 ||
      currentBorrower?.portalProjectsActive > 0;
    const disableEdit =
      !globalStore.userFeatures?.addNewBorrowerDuringSubmission ||
      checkBorrowerExistingInFunded;
    const ownershipNotKnown = store.form.fields.ownershipNotKnown.value;

    const validExistingEmail = email => {
      return (
        globalStore.userFeatures?.allowBorrowerDuplicateEmail ||
        !selectBorrowersStore.borrowers
          .filter(borrower => borrower.tempId != currentBorrower?.tempId)
          .some(borrower => borrower.emailAddress === email)
      );
    };

    return (
      <Grid container justifyContent={'center'} spacing={2}>
        <Grid item xs={12}>
          <FirstName store={store} disabled={checkBorrowerExistingInFunded} />
        </Grid>
        <Grid item xs={12}>
          <LastName store={store} disabled={checkBorrowerExistingInFunded} />
        </Grid>
        <Grid item xs={12}>
          <EmailAddressDuplicated
            store={store}
            disabled={disableEdit && !!currentBorrower?.emailAddress}
            onChanged={(name, value) =>
              store.onFieldChange(
                'emailAddressDuplicated',
                validExistingEmail(value)
              )
            }
          />
        </Grid>
        <Grid item xs={12}>
          <CellPhone store={store} disabled={disableEdit} />
        </Grid>
        <Grid item xs={12}>
          <CitizenshipStatus store={store} />
        </Grid>
        <Grid item xs={12}>
          <Liquidity store={store} />
        </Grid>
        <Grid item xs={12}>
          <PersonalGuarantor store={store} />
        </Grid>
        <Grid item xs={12}>
          <AuthorizedSignatory store={store} />
        </Grid>
        <Grid item xs={12}>
          <BorrowerExperienceRealStateMultifamily
            store={store}
            multiFamilyStore={multiFamilyStore}
          />
        </Grid>
        <Grid item xs={12}>
          <BorrowerCreditScore
            store={store}
            multiFamilyStore={multiFamilyStore}
          />
        </Grid>
        <Grid item xs={12}>
          <PercentOwnership store={store} disableEdit={ownershipNotKnown} />
        </Grid>
        <Grid item xs={12} justifyContent="flex-end">
          <OwnershipNotKnown store={store} />
        </Grid>
      </Grid>
    );
  }
);
