import { GlobalStore } from "@roc/feature-app-core";
import ScopeOfWorkV2Store from "./scopeOfWorkV2Store";
import { ScopeOfWorkV2, ScopeOfWorkV2FormParams } from "@roc/feature-sow-shared/types";
import { CALLBACK_TYPE } from "@roc/feature-sow-shared/constants/scopeOfWorkV2Form";
import { flow, makeObservable } from "mobx";

export class PublicScopeOfWorkV2Store extends ScopeOfWorkV2Store {

  constructor(globalStore: GlobalStore) {
    super(globalStore);
    this.inititalizeServiceMethods();
    makeObservable(this, {
      initLenderIdForShortLink: flow
    });
  }

  inititalizeServiceMethods() {
    this.setSubmitScopeOfWork(this.submitScopeOfWorkPublic)
  }

  setPublicParams(params: ScopeOfWorkV2FormParams) {
    this.params = params;
    this.fetchInitialData();
  }

  *fetchInitialData() {
    yield this.fetchScopeOfWork();
    if (!this.showShare) return;
  }

  *fetchScopeOfWork() {
    try {
      let response: ScopeOfWorkV2;
      response = yield this.getNewScopeOfWork();

      const categoriesResponse = yield this.getScopeOfWorkCategories();

      if (!response || !categoriesResponse) return;
      this.setIsDisabled(false);
      this.setIsPublicLink(true);
      this.params.isInternal = false;
      this.initParams(response);
      this.initScopeOfWork(response, categoriesResponse);
    } catch (error) {
      this.afterCall(CALLBACK_TYPE.GET, null, this.params.isInternal);
    }
  }

  submitScopeOfWorkPublic = async (
    params: ScopeOfWorkV2FormParams,
    data: any,
    silent: boolean
  ) => {
    const { data: response } = await this.scopeOfWorkService.submitScopeOfWorkPublic(
      params.lenderId,
      params.scopeOfWorkId,
      params.username,
      data,
      silent
    );
    return response;
  };

  setPublicParamsWithoutLenderId(params: ScopeOfWorkV2FormParams) {
    this.params = params
    this.initLenderIdForShortLink();
    this.fetchInitialData();
  }

  *initLenderIdForShortLink() {
    let response: ScopeOfWorkV2;
    response = yield this.getLenderIdByHost();
    this.setParamsForShortLink(response);
  }

  setParamsForShortLink(response) {
    this.params.lenderId = response?.data;
  }

  getLenderIdByHost = async () => {
    try {
      const host = this.getHost();
      const { data: response } = await this.scopeOfWorkService.getLenderIdByHost(host);
      return response;
    } catch (err) {
      this.globalStore.notificationStore.showErrorNotification({
        message:
          'An error occurred when getting user information',
      });
    }
  }

  getHost() {
    return window.location.origin;
  }

  resetParamsWithConditions() {
    if (!this.params)
      this.reset();
  }
}