import { observer } from 'mobx-react';
import { TextField, SelectField } from '@roc/ui';
import { Grid } from '@material-ui/core';
import { isNotBlank } from '@roc/feature-utils';
import { useStore } from '@roc/client-portal-shared/hooks';
import NumberFormat from 'react-number-format';
import { useEffect } from 'react';
import { TPO, Broker } from '../../utils/leadConstants';
import { createAutocompleteField } from '@roc/ui';

export const ConvertLeadToLenderModal = observer(({ record }) => {
  const { salesforceLeadStore, globalStore, salesforceLenderStore } = useStore();
  const { convertLeadToLenderStore } = salesforceLeadStore;

  const formFields = convertLeadToLenderStore.form.fields;

  useEffect(() => {
    convertLeadToLenderStore.reset();
  }, [record, convertLeadToLenderStore]);

  useEffect(() => {
    if (globalStore.userFeatures.isBackOfficeProcessor) {
      salesforceLenderStore.getRelationshipManagersForProcessor();
      convertLeadToLenderStore.onFieldChange('__ownerEmailRequired__', true);
    } else if (globalStore.userFeatures.backOfficeLead) {
      salesforceLenderStore.getRelationshipManagersUsers();
      convertLeadToLenderStore.onFieldChange('__ownerEmailRequired__', true);
    }

    if (['[Not Provided]', 'NA', '[NA Facebook Form]'].some(value => value.toLowerCase() === record.company.toLowerCase())) {
      convertLeadToLenderStore.onFieldChange('accountName', '');
    } else {
      convertLeadToLenderStore.onFieldChange('accountName', record.company);
    }
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <SelectField
          standaloneLabel
          testId="recordType"
          required
          variant="outlined"
          label="Record type"
          value={formFields.recordType.value}
          onChange={value =>
            convertLeadToLenderStore.onFieldChange(
              'recordType',
              value
            )
          }
          error={isNotBlank(formFields.recordType.error)}
          helperText={formFields.recordType.error}
          fullWidth
          options={[
            {
              label: 'Choose a record type',
              value: null
            },
            {
              label: TPO,
              value: TPO
            }, {
              label: Broker,
              value: Broker
            }
          ]}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          standaloneLabel
          testId="accountName"
          required
          variant="outlined"
          label="Account name"
          value={formFields.accountName.value}
          onChange={e =>
            convertLeadToLenderStore.onFieldChange(
              'accountName',
              e.target.value
            )
          }
          error={isNotBlank(formFields.accountName.error)}
          helperText={formFields.accountName.error}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <NumberFormat
          customInput={TextField}
          format="##-#######"
          mask="_"
          required={false}
          variant="outlined"
          label="Entity EIN"
          testId="ein"
          value={formFields.ein.value}
          onChange={e =>
            convertLeadToLenderStore.onFieldChange('ein', e.target.value)
          }
          standaloneLabel
          fullWidth
        />
      </Grid>
      {(globalStore.userFeatures.isBackOfficeProcessor || globalStore.userFeatures.backOfficeLead) && <Grid item xs={12}>
        {createAutocompleteField({
          label: 'Owner Name',
          fieldName: 'ownerEmail',
          store: convertLeadToLenderStore,
          options: salesforceLenderStore.relationshipManagers,
          placeholder: 'Choose owner',
          onChange: value => {
            convertLeadToLenderStore.onFieldChange('ownerEmail', value);
          },
        })}
      </Grid>}
    </Grid>
  );
});
