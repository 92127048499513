import { Business } from '@material-ui/icons';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useNewLoanApplicationStore } from '../hooks/useNewLoanApplicationStore';
import { BorrowerEntityForm } from './borrowerEntityForm';
import {
  LoanApplicationCardTitle,
  LoanApplicationDialog,
} from './loanApplicationComponents';

export const EntityInformationModal = observer(() => {
  const { newLoanApplicationStore } = useNewLoanApplicationStore();
  const { borrowerEntityFormStore } = newLoanApplicationStore;
  const { dialogState } = borrowerEntityFormStore;

  useEffect(() => {
    borrowerEntityFormStore.fetchBorrowerEntities();
  },[])

  return (
    <LoanApplicationDialog
      title={
        <LoanApplicationCardTitle
          title={'Entity Information'}
          icon={<Business />}
        />
      }
      dialogState={dialogState}
      handleClose={() => borrowerEntityFormStore.closeDialog()}
      handleSave={() => borrowerEntityFormStore.saveEntity()}
      dialogContent={<BorrowerEntityForm />}
    />
  );
});
