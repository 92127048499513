import { Grid, Typography } from "@material-ui/core";
import { DateFormat, formatDate, isNil } from "@roc/feature-utils";
import { createDateField, createSelectField, createTextField, EditableDialog } from "@roc/ui";
import { observer } from "mobx-react";
import { activityTypeOptions } from "../leads/constants/constants";
import { createHtmlField } from '../leadDetails/htmlField'
import { TaskStatusField } from "libs/feature-general-tasks/src/generalTasks/components/editTask/taskStatusField";
import { generalTaskStatusOptions } from "libs/feature-general-tasks/src/generalTasks/utils/generalTasksConstants";
import { useStore } from "@roc/client-portal-shared/hooks";

export const EditLeadTaskDialog = observer(({ editLeadTaskStore }) => {
  const { leadActivity } = editLeadTaskStore;
  const { salesforceLeadStore } = useStore();

  const store = editLeadTaskStore;

  const dialogContent = (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {createTextField({
          store,
          testId: 'taskTitle',
          fieldName: 'title',
          label: 'Title'
        })}
      </Grid>
      <Grid item xs={12}>
        {createDateField({
          store,
          testId: 'taskDueDate',
          fieldName: 'dueDate',
          label: 'Due Date',
        })}
      </Grid>
      <Grid item xs={12}>
        <TaskStatusField
          label={'Status'}
          value={store.form.fields.status.value}
          onChange={(e, value) => store.onFieldChange('status', value)}
          options={generalTaskStatusOptions}
          //disabled={isReadOnly}
          disabled={false}
        />
      </Grid>
      <Grid item xs={12}>
        {createHtmlField({
          store,
          testId: 'text',
          fieldName: 'description',
          label: 'Comments',
          multiline: true,
          minRows: 5
        })}
      </Grid>
      {leadActivity?.lastUpdatedDate && (
        <Grid item xs={12}>
          <Typography variant="subtitle2" display="block" align="right">
            Last updated at {formatDate(leadActivity?.lastUpdatedDate, DateFormat.MMDDYYYY)}
          </Typography>
        </Grid>
      )}
    </Grid>
  )

  return (
    <EditableDialog
      open={!isNil(editLeadTaskStore.dialogState)}
      dialogState={editLeadTaskStore.dialogState}
      handleClose={() => editLeadTaskStore.closeLeadTaskDialog()}
      handleAdd={() => editLeadTaskStore.saveLeadTask(salesforceLeadStore.gridBusinessUnit)}
      handleEdit={() => editLeadTaskStore.saveLeadTask(salesforceLeadStore.gridBusinessUnit)}
      title={'Task'}
      dialogContent={dialogContent}
    />
  )
})
