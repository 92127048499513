import React from 'react';
import { StandardDialog } from '@roc/ui';
import { FundingTemplate } from './fundingTemplate';

export type FundingTemplateDialogProps = {
  open?: boolean;
  onClose?: () => void;
};

export const FundingTemplateDialog = (props: FundingTemplateDialogProps) => {
  const { open, onClose } = props;

  return (
    <StandardDialog
      open={open}
      title="Funding Template"
      maxWidth="xl"
      handleClose={onClose}
      dialogContent={<FundingTemplate />}
    />
  );
};

export default FundingTemplateDialog;
