import { makeStyles } from '@material-ui/core/styles';
import { Layout } from '@roc/feature-app-core';
import { observer } from 'mobx-react';
import React from 'react';
import { useLoanStore } from '../../hooks/useLoanStore';
import { LoanDetailsLayout as LoanDetailsLayoutShared } from '@roc/feature-loans-shared';

const getDealNameForDisplay = (loanId: string, dealName: string) => {
  return `${loanId ? `[${loanId}]` : ''} ${dealName ? `- ${dealName}` : ''}`;
};

export const LoanDetailsLayout = observer(props => {
  const { loanStore } = useLoanStore();
  return (
    <LoanDetailsLayoutShared
      heading={getDealNameForDisplay(
        loanStore.loanDetails?.loanId,
        loanStore.loanDetails?.dealName
      )}
      {...props}
    />
  );
});
