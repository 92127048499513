import { AppraisalReviewStore } from "../stores"

export const foundationOptions = [
  { label: 'Please choose', value: '' },
  { label: 'Concrete Slab', value: 'Concrete Slab' },
  { label: 'Crawl Space', value: 'Crawl Space' },
  { label: 'Full Basement', value: 'Full Basement' },
  { label: 'Partial Basement', value: 'Partial Basement' },
  { label: 'No Basement', value: 'No Basement' },
  { label: 'N/A', value: 'N/A' },
]

export const exteriorWallsOptions = [
  { label: 'Please choose', value: '' },
  { label: 'Masonry', value: 'Masonry' },
  { label: 'Frame', value: 'Frame' },
  { label: 'Fire Resistive', value: 'Fire Resistive' },
  { label: 'Brick', value: 'Brick' },
  { label: 'Concrete', value: 'Concrete' },
  { label: 'Vinyl', value: 'Vinyl' },
]

export const neighborhoodOptions = [
  { label: 'Please choose', value: '' },
  { label: 'Suburban Stable', value: 'Suburban Stable' },
  { label: 'Urban Stable', value: 'Urban Stable' },
  { label: 'Urban Marginal', value: 'Urban Marginal' },
  { label: 'Suburban Marginal', value: 'Suburban MArginal' }
]

export const propertyTypeOptions = [
  {
    label: 'Single Family',
    value: 'Single Family'
  },
  {
    label: 'Duplex',
    value: 'Duplex'
  },
  {
    label: 'Triplex',
    value: 'Triplex'
  },
  {
    label: 'Quadruplex',
    value: 'Quadruplex'
  },
  {
    label: 'Condominium',
    value: 'Condominium'
  },
  {
    label: 'Townhome',
    value: 'Townhome'
  },
  {
    label: 'Planned Unit Development',
    value: 'Planned Unit Development'
  },
  {
    label: '5+ Unit Multifamily',
    value: '5Plus Unit Multifamily'
  },
  {
    label: 'Mixed Use',
    value: 'Mixed Use'
  }
]

export const floodZoneOptions = [
  { label: 'Please choose', value: '' },
  { label: 'Yes', value: true },
  { label: 'No', value: false },
]

export const locationOptions = [
  { label: 'Please choose', value: '' },
  { label: 'Urban', value: 'Urban' },
  { label: 'Suburban', value: 'Suburban' },
  { label: 'Rural', value: 'Rural' },
]

export const propertyConditionOptions = [
  { label: 'Please choose', value: '' },
  { label: 'C1 - New', value: 'C1' },
  { label: 'C2 - (Very) Good', value: 'C2' },
  { label: 'C3 - Fair', value: 'C3' },
  { label: 'C4 - Average', value: 'C4' },
  { label: 'C5 - Poor', value: 'C5' },
  { label: 'C6 - Bad / Dilapidated', value: 'C6' },
]

export const attachmentTypeOptions = [
  { label: 'Please choose', value: '' },
  { label: 'Attached', value: 'Attached' },
  { label: 'Detached', value: 'Detached' },
  { label: 'Semi Detached', value: 'Semi Detached' },
]

export enum fieldTypes {
  TEXT,
  DATE,
  DROPDOWN,
  NUMERIC,
  CURRENCY,
  DROPDOWN_DYNAMIC_OPTIONS,
  APPRAISER_NAME,
}

export const summaryDataRows = [
  {
    mapValue: 'appraisalReviewDate',
    title: 'Review Date',
    type: fieldTypes.DATE
  },
  {
    mapValue: 'address',
    title: 'Property Address',
    type: fieldTypes.TEXT,
    disabled: true
  },
  {
    mapValue: 'stories',
    title: 'Stories',
    type: fieldTypes.NUMERIC
  },
  {
    mapValue: 'foundation',
    title: 'Foundation',
    type: fieldTypes.DROPDOWN,
    options: foundationOptions
  },
  {
    mapValue: 'exteriorWalls',
    title: 'Exterior Walls',
    type: fieldTypes.DROPDOWN,
    options: exteriorWallsOptions
  },
  {
    mapValue: 'useCode',
    title: 'Use Code',
    type: fieldTypes.DROPDOWN,
    options: propertyTypeOptions
  },
  {
    mapValue: 'county',
    title: 'County',
    type: fieldTypes.TEXT
  },
  {
    mapValue: 'legalDescription',
    title: 'Legal Description',
    type: fieldTypes.TEXT
  },
  {
    mapValue: 'section',
    title: 'Section',
    type: fieldTypes.TEXT
  },
  {
    mapValue: 'block',
    title: 'Block',
    type: fieldTypes.TEXT
  },
  {
    mapValue: 'lot',
    title: 'Lot',
    type: fieldTypes.TEXT
  },
  {
    mapValue: 'parcel',
    title: 'Parcel',
    type: fieldTypes.TEXT
  },
  {
    mapValue: 'appraisalFloodZone',
    title: 'Appraisal Flood Zone',
    type: fieldTypes.DROPDOWN,
    options: floodZoneOptions
  },
  {
    mapValue: 'location',
    title: 'Location',
    type: fieldTypes.DROPDOWN,
    options: locationOptions
  },
  {
    mapValue: 'yearBuilt',
    title: 'Year Built',
    type: fieldTypes.TEXT
  },
  {
    mapValue: 'appraisalPropertyCondition',
    mapValueToSave: 'propertyCondition',
    title: 'Property Condition',
    type: fieldTypes.DROPDOWN,
    options: propertyConditionOptions
  },
  {
    mapValue: 'lotSizeSqFt',
    title: 'Lot Size (sqft)',
    type: fieldTypes.NUMERIC
  },
  {
    mapValue: 'grossLeasableRentableArea',
    mapValueToSave: 'grossLeasableAndRentableArea',
    title: 'Gross Building Area',
    type: fieldTypes.NUMERIC
  },
  {
    mapValue: 'bedrooms',
    title: 'Bedrooms',
    type: fieldTypes.NUMERIC
  },
  {
    mapValue: 'bathrooms',
    title: 'Bathrooms',
    type: fieldTypes.NUMERIC
  },
  {
    mapValue: 'annualTaxesPerAppraisal',
    title: 'Annual Taxes (per Appraisal)',
    type: fieldTypes.CURRENCY
  },
  {
    mapValue: 'appraisalAnnualHoaFees',
    title: 'Annual HOA Fees',
    type: fieldTypes.CURRENCY
  },
  {
    mapValue: 'attachmentType',
    title: 'Attachment Type',
    type: fieldTypes.DROPDOWN,
    options: attachmentTypeOptions
  },
  {
    mapValue: 'appraisalFormUsed',
    title: 'Appraisal Form Used',
    type: fieldTypes.TEXT
  },

  {
    mapValue: 'asIsValue',
    title: 'AsIs Value',
    type: fieldTypes.CURRENCY
  },
  {
    mapValue: 'asIsValueAtOrigination',
    title: 'AsIs Value At Origination',
    type: fieldTypes.CURRENCY,
    disabled: true
  },
  {
    mapValue: 'afterRepairValue',
    title: 'After Repair Value',
    type: fieldTypes.CURRENCY
  },
  {
    mapValue: 'afterRepairValueAtOrigination',
    title: 'After Repair Value At Origination',
    type: fieldTypes.CURRENCY,
    disabled: true
  },
  {
    mapValue: 'appraisalDate',
    mapValueToSave: 'effectiveAppraisalDate',
    title: 'Effective Appraisal Date',
    type: fieldTypes.DATE
  },
  {
    mapValue: 'appraiserFirmId',
    mapValueToSave: 'appraiserFirm',
    title: 'Appraiser Firm',
    type: fieldTypes.APPRAISER_NAME,
  },
  {
    mapValue: 'appraiserId',
    mapValueToSave: 'appraiserName',
    title: 'Appraiser Name',
    type: fieldTypes.DROPDOWN_DYNAMIC_OPTIONS,
    getOptions: (store: AppraisalReviewStore, mapping) => { return store.getAppraiserNames(mapping) }
  },
  {
    mapValue: 'appraisalAMC',
    title: 'Appraisal AMC',
    type: fieldTypes.TEXT
  },
  {
    mapValue: 'monthlyRent',
    mapValueToSave: 'marketRent',
    title: 'Monthly Market Rent',
    type: fieldTypes.CURRENCY
  },
  {
    mapValue: 'replacementCost',
    title: 'Replacement Cost',
    type: fieldTypes.CURRENCY
  },
  {
    mapValue: 'ownerOfRecord',
    title: 'Owner Of Record',
    type: fieldTypes.TEXT
  },
  {
    mapValue: 'design',
    title: 'Property Design',
    type: fieldTypes.TEXT,
    disabled: true
  },
  {
    mapValue: 'renovationBudget',
    title: 'Renovation Budget',
    type: fieldTypes.CURRENCY,
  },
  {
    mapValue: 'sunkCost',
    title: 'Sunk Cost',
    type: fieldTypes.CURRENCY,
  },
];

export const automatedColumns = [
  { name: 'Item', mapping: 'itemDisplayName' },
  { name: 'Review', mapping: 'review' },
  { name: 'Data', mapping: 'data' },
  { name: 'Needs Revision', mapping: 'needsRevisionFlag' },
  { name: 'Comments', mapping: 'reviewerComment' }
];

export const displayColumns = [
  { name: 'Item Name', mapping: 'itemDisplayName' },
  { name: 'Item Description', mapping: 'data' },
  { name: 'Comments', mapping: 'displayComment' }
];

export enum UW_APPRAISAL_REVIEW_STATUS {
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}