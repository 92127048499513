export enum LoaSubtypeOptions {
  FIX_AND_FLIP_1_4 = 'Fix and Flip (1-4)',
  GROUNDUP = 'Groundup (1-4)',
  STABILIZED_BRIDGE = 'Stabilized Bridge',
}

export const loanSubtypeOptions = [
  {
    label: 'Fix & Flip',
    value: LoaSubtypeOptions.FIX_AND_FLIP_1_4
  },
  {
    label: 'Ground Up',
    value: LoaSubtypeOptions.GROUNDUP
  },
  {
    label: 'Stabilized Bridge',
    value: LoaSubtypeOptions.STABILIZED_BRIDGE
  },
];

export const allLendersOptions = [
  {
    label: "0",
    value: "--select--"
  },
  {
    label: "0",
    value: "--select--"
  },
  {
    label: "0",
    value: "--select--"
  },
  {
    label: "0",
    value: "--select--"
  }
];

export const borrowerPointOptions = [
  {
    label: '1%',
    value: '1'
  },
  {
    label: '1.25%',
    value: '1.25'
  },
  {
    label: '1.5%',
    value: '1.5'
  },
  {
    label: '1.75%',
    value: '1.75'
  },
  {
    label: '2%',
    value: '2'
  },
  {
    label: '2.25%',
    value: '2.25'
  },
  {
    label: '2.5%',
    value: '2.5'
  },
  {
    label: '2.75%',
    value: '2.75'
  },
  {
    label: '3%',
    value: '3'
  },
  {
    label: '3.25%',
    value: '3.25'
  },
  {
    label: '3.5%',
    value: '3.5'
  },
];

export const borrowerRateOptions = [
  {
    label: '8.00%',
    value: '8.00'
  },
  {
    label: '8.25%',
    value: '8.25'
  },
  {
    label: '8.49%',
    value: '8.49'
  },
  {
    label: '8.50%',
    value: '8.50'
  },
  {
    label: '8.75%',
    value: '8.75'
  },
  {
    label: '8.95%',
    value: '8.95'
  },
  {
    label: '8.99%',
    value: '8.99'
  },
  {
    label: '9.25%',
    value: '9.25'
  },
  {
    label: '9.45%',
    value: '9.45'
  },
  {
    label: '9.49%',
    value: '9.49'
  },
  {
    label: '9.5%',
    value: '9.5'
  },
  {
    label: '9.75%',
    value: '9.75'
  },
  {
    label: '9.95%',
    value: '9.95'
  },
  {
    label: '9.99%',
    value: '9.99'
  },
  {
    label: '10.00%',
    value: '10.00'
  },
  {
    label: '10.25%',
    value: '10.25'
  },
  {
    label: '10.49%',
    value: '10.49'
  },
  {
    label: '10.50%',
    value: '10.50'
  },
  {
    label: '10.75%',
    value: '10.75'
  },
  {
    label: '10.95%',
    value: '10.95'
  },
  {
    label: '10.99%',
    value: '10.99'
  },
  {
    label: '11.00%',
    value: '11.00'
  },
  {
    label: '11.25%',
    value: '11.25'
  },
  {
    label: '11.49%',
    value: '11.49'
  },
  {
    label: '11.50%',
    value: '11.50'
  },
  {
    label: '11.75%',
    value: '11.75'
  },
  {
    label: '11.95%',
    value: '11.95'
  },
  {
    label: '11.99%',
    value: '11.99'
  },
  {
    label: '12.00%',
    value: '12.00'
  },
  {
    label: '12.25%',
    value: '12.25'
  },
  {
    label: '12.49%',
    value: '12.49'
  },
  {
    label: '12.5%',
    value: '12.5'
  },
  {
    label: '12.75%',
    value: '12.75'
  },
  {
    label: '12.95%',
    value: '12.95'
  },
  {
    label: '12.99%',
    value: '12.99'
  },
  {
    label: '13.00%',
    value: '13.00'
  },
  {
    label: '13.25%',
    value: '13.25'
  },
  {
    label: '13.49%',
    value: '13.49'
  },
  {
    label: '13.5%',
    value: '13.5'
  },
  {
    label: '13.75%',
    value: '13.75'
  },
  {
    label: '13.95%',
    value: '13.95'
  },
  {
    label: '13.99%',
    value: '13.99'
  },
  {
    label: '14.00%',
    value: '14.00'
  },
  {
    label: '14.25%',
    value: '14.25'
  },
  {
    label: '14.49%',
    value: '14.49'
  },
  {
    label: '14.5%',
    value: '14.5'
  },
  {
    label: '14.75%',
    value: '14.75'
  },
  {
    label: '14.95%',
    value: '14.95'
  },
  {
    label: '14.99%',
    value: '14.99'
  },
  {
    label: '15.00%',
    value: '15.00'
  },
  {
    label: '15.25%',
    value: '15.25'
  },
  {
    label: '15.49%',
    value: '15.49'
  },
  {
    label: '15.5%',
    value: '15.5'
  },
  {
    label: '15.75%',
    value: '15.75'
  },
  {
    label: '15.95%',
    value: '15.95'
  },
  {
    label: '15.99%',
    value: '15.99'
  },
  {
    label: '16.00%',
    value: '16.00'
  },
];

export const stateOptions = [
  {
    value: "AL",
    label: "Alabama"
  },
  {
    value: "AK",
    label: "Alaska"
  },
  {
    value: "AZ",
    label: "Arizona"
  },
  {
    value: "AR",
    label: "Arkansas"
  },
  {
    value: "CA",
    label: "California"
  },
  {
    value: "CO",
    label: "Colorado"
  },
  {
    value: "CT",
    label: "Connecticut"
  },
  {
    value: "DE",
    label: "Delaware"
  },
  {
    value: "DC",
    label: "Washington D.C.",
  },
  {
    value: "FL",
    label: "Florida"
  },
  {
    value: "GA",
    label: "Georgia"
  },
  {
    value: "HI",
    label: "Hawaii"
  },
  {
    value: "ID",
    label: "Idaho"
  },
  {
    value: "IL",
    label: "Illinois"
  },
  {
    value: "IN",
    label: "Indiana"
  },
  {
    value: "IA",
    label: "Iowa"
  },
  {
    value: "KS",
    label: "Kansas"
  },
  {
    value: "KY",
    label: "Kentucky"
  },
  {
    value: "LA",
    label: "Louisiana"
  },
  {
    value: "ME",
    label: "Maine"
  },
  {
    value: "MD",
    label: "Maryland"
  },
  {
    value: "MA",
    label: "Massachusetts"
  },
  {
    value: "MI",
    label: "Michigan"
  },
  {
    value: "MN",
    label: "Minnesota"
  },
  {
    value: "MS",
    label: "Mississippi"
  },
  {
    value: "MO",
    label: "Missouri"
  },
  {
    value: "MT",
    label: "Montana"
  },
  {
    value: "NE",
    label: "Nebraska"
  },
  {
    value: "NV",
    label: "Nevada"
  },
  {
    value: "NH",
    label: "New Hampshire"
  },
  {
    value: "NJ",
    label: "New Jersey"
  },
  {
    value: "NM",
    label: "New Mexico"
  },
  {
    value: "NY",
    label: "New York"
  },
  {
    value: "NC",
    label: "North Carolina"
  },
  {
    value: "ND",
    label: "North Dakota"
  },
  {
    value: "OH",
    label: "Ohio"
  },
  {
    value: "OK",
    label: "Oklahoma"
  },
  {
    value: "OR",
    label: "Oregon"
  },
  {
    value: "PA",
    label: "Pennsylvania"
  },
  {
    value: "RI",
    label: "Rhode Island"
  },
  {
    value: "SC",
    label: "South Carolina"
  },
  {
    value: "SD",
    label: "South Dakota"
  },
  {
    value: "TN",
    label: "Tennessee"
  },
  {
    value: "TX",
    label: "Texas"
  },
  {
    value: "UT",
    label: "Utah"
  },
  {
    value: "VT",
    label: "Vermont"
  },
  {
    value: "VA",
    label: "Virginia"
  },
  {
    value: "WA",
    label: "Washington"
  },
  {
    value: "WV",
    label: "West Virginia"
  },
  {
    value: "WI",
    label: "Wisconsin"
  },
  {
    value: "WY",
    label: "Wyoming"
  }
];

export const borrowerExperienceHeaders = ['Borrower Experience Score', 'What Counts?'];

export const tooltipDataBorrowerExperience = [
  {
    value: '1',
    data: 'Neither of the following: Verified investment property(ies) OR Real estate related profession/trade'
  },
  {
    value: '2',
    data: '1-2 verified investment properties on track record'
  },
  {
    value: '3',
    data: '3-5 verified investment properties w/ renovation on track record OR 5-7 verified investment properties on track record (rental properties)'
  },
  {
    value: '4',
    data: '6-9 verified investment properties w/ renovation on track record OR 10-15 verified investment properties on track record (rental properties)'
  },
  {
    value: '5',
    data: '10+ verified investment properties w/ renovation on track record OR 15+ verified investment properties on track record (rental properties)'
  }
];

export const borrowerExperienceData = [
  { score: '1', whatCounts: 'Neither of the following: \n- Verified investment property(ies) \n- Real estate related profession/trade(1)' },
  { score: '2', whatCounts: '- 1-2 verified investment properties on track record' },
  { score: '3', whatCounts: '- 3-5 verified investment properties w/ renovation on track record \nOR \n- 5-7 verified investment properties on track record (rental properties)' },
  { score: '4', whatCounts: '- 6-9 verified investment properties w/ renovation on track record \nOR \n- 10-15 verified investment properties on track record (rental properties)' },
  { score: '5', whatCounts: '- 10+ verified investment properties w/ renovation on track record \nOR \n- 15+ verified investment properties on track record (rental properties)' },
  { score: '(1) Real State related profession/trade \n+1 tier increase if criteria satisfied', whatCounts: 'Either of the following: \n-Real estate agent/broker \n -General Contractor/Home Improvement Contractor' }
];

export const NON_BREAKING_SPACE = '\u3000';

export const loanTermOptions = [
  {
    label: '12',
    value: 12,
  },
  {
    label: '18',
    value: 18,
  },
  {
    label: '24',
    value: 24,
  }
];