import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { Document } from '@roc/feature-types';
import { Button } from '@roc/ui';
export type TermSheetProps = {
  document: Document;
  downloadTermSheet: () => void;
};
const useStyles = makeStyles({
  instruction: {
    fontWeight: 600,
  },
});
export const TermSheet = (props: TermSheetProps) => {
  const { document, downloadTermSheet } = props;
  const classes = useStyles();
  return (
    <Grid container direction="column" alignItems="center" spacing={2}>
      <Grid item>
        <Typography className={classes.instruction}>
          Please click on the button to download the term sheet
        </Typography>
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          color="secondary"
          onClick={downloadTermSheet}
          testId="downloadTermSheetButton"
        >
          Download Term Sheet
        </Button>
      </Grid>
    </Grid>
  );
};
export default TermSheet;