import React, { useEffect } from 'react';
import { useAppConfigurations } from './useAppConfigurations';

export const useFavIcon = () => {
  const { favIcon } = useAppConfigurations();
  useEffect(() => {
    const favIconHtmlElement: HTMLLinkElement = document.getElementById(
      'favicon'
    ) as HTMLLinkElement;
    favIconHtmlElement.href = favIcon;
  }, []);
};
