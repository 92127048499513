import { Grid, Typography, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { InfoOutlined } from '@material-ui/icons';
import { CustomIconTooltip } from '@roc/ui';

type ItemDisplayProps = {
  label?: string;
  value: string;
  alignItems?: 'flex-start' | 'center' | 'flex-end';
  showBackground?: boolean;
  valueTextColor?: string;
  tooltip?: string;
};
interface styleProps {
  valueTextColor: string;
}

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: '#F7F7F7',
  },
  cardValue: ({ valueTextColor }: styleProps) => ({
    fontSize: '20px',
    fontWeight: 'bold',
    color: valueTextColor,
    textWrap: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }),
  cardLabel: {
    fontSize: '14px',
    color: '#939494',
    display: 'flex',
    alignItems: 'center',
  },
  tooltip: {
    whiteSpace: 'pre-line',
    fontSize: theme.typography.pxToRem(14),
    maxWidth: '75vw',
  },
  infoIcon: {
    width: '16px',
    color: '#939494',
    marginLeft: '5px',
  },
}));

export const ItemDisplay = ({
  label,
  value,
  alignItems = 'center',
  showBackground = false,
  valueTextColor = 'inherit',
  tooltip,
}: ItemDisplayProps) => {
  const classes = useStyles({ valueTextColor });

  return (
    <Grid
      container
      alignItems={alignItems}
      justifyContent="center"
      direction="column"
      className={showBackground ? classes.container : undefined}
    >
      {label && (
        <Grid item>
          <Paper
            elevation={0}
            style={{ textAlign: 'center', backgroundColor: 'transparent' }}
          >
            <Typography variant="body2" className={classes.cardLabel}>
              {label}
              {tooltip && (
                <CustomIconTooltip
                  title={tooltip}
                  placement="top"
                  icon={InfoOutlined}
                  style={{ marginLeft: '5px' }}
                />
              )}
            </Typography>
          </Paper>
        </Grid>
      )}
      <Grid item style={{ maxWidth: '100%' }}>
        <Typography variant="body2" className={classes.cardValue}>
          {value}
        </Typography>
      </Grid>
    </Grid>
  );
};
