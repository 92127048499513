import {
  action,
  flow,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';
import { ApiResponse } from '../services';
import { formatDate } from '@roc/feature-utils';

const getInitalGridObject = () => ({
  data: {
    totalCount: 0,
    columns: [],
    rows: [],
  },
  meta: {
    start: 1,
    pageNumber: 1,
    pageSize: 200,
    totalPages: 1,
    totalRecords: 0,
    sortBy: undefined,
    sortDir: undefined,
    filters: undefined,
    dropdownFilters: undefined,
  },
});

export class GridStore {
  private fetchData: () => Promise<ApiResponse>;
  private processData: () => void;
  gridData;
  isLoading: boolean;
  isError: boolean;
  showFilters = true;
  private pageSize;
  sortModel: object;
  filterModel: object;

  constructor(
    fetchData: () => Promise<ApiResponse>,
    processData?: () => void,
    pageSize?: number
  ) {
    this.pageSize = pageSize;
    this.fetchData = fetchData;
    this.processData = processData;
    this.gridData = getInitalGridObject();
    this.isLoading = false;
    this.isError = false;
    makeObservable(this, {
      gridData: observable,
      isLoading: observable,
      isError: observable,
      showFilters: observable,
      sortModel: observable,
      filterModel: observable,
      toggleFilters: action,
      resetFilters: action,
      setSortModel: flow,
      setFilterModel: flow,
      setPageNumber: flow,
      setSortParams: flow,
      setFiltersParam: flow,
      setData: flow,
      fetchGridData: flow,
      reset: action,
      resetAndFetchGridData: flow,
      refresh: flow,
      getRowsBatch: action,
    });
    this.setPageNumber = this.setPageNumber.bind(this);
  }

  *setPageNumber(pageNumber, fetch = true) {
    this.gridData.meta.pageNumber = pageNumber;
    if (!fetch) return;
    yield this.fetchGridData();
  }

  *setSortParams({ sortBy, sortDir }, fetch: boolean) {
    this.gridData.meta.sortBy = sortBy;
    this.gridData.meta.sortDir = sortDir;
    if (!fetch) return;
    yield this.fetchGridData();
  }

  *setFiltersParam({ filters, dropdownFilters }, fetch: boolean) {
    this.gridData.meta.filters = filters;
    this.gridData.meta.dropdownFilters = dropdownFilters;
    if (!fetch) return;
    yield this.fetchGridData();
  }

  *setData(rows) {
    this.gridData.data.rows = rows;
  }

  getRowsBatch = async params => {
    const { startRow } = params;
    const pageNumber = !this.gridData.data.totalCount
      ? 1
      : Math.ceil((startRow - 1) / this.gridData.meta.pageSize + 1);
    await this.setPageNumber(pageNumber);
    return {
      rows: this.gridData.data.rows,
      count: this.gridData.data.totalCount,
    };
  };

  *fetchGridData() {
    try {
      this.isError = false;
      this.isLoading = true;
      const response: ApiResponse = yield this.fetchData();
      runInAction(() => {
        this.gridData.data = response.data?.data ?? {
          ...getInitalGridObject().data,
        };
        this.processData && this.processData();
        this.gridData.meta = {
          ...this.gridData.meta,
          ...this.calculateMeta(
            this.gridData.meta.pageNumber,
            this.gridData.meta.pageSize,
            response.data?.data?.totalCount
          ),
        };
        this.isLoading = false;
      });
    } catch (error) {
      this.isLoading = false;
      this.isError = true;
    }
  }

  reset() {
    this.gridData = getInitalGridObject();
    this.showFilters = true;
    this.filterModel = null;
    this.sortModel = null;
  }

  *resetAndFetchGridData() {
    this.reset();
    yield this.fetchGridData();
  }

  *refresh() {
    yield this.fetchGridData();
  }

  toggleFilters() {
    this.showFilters = !this.showFilters;
  }

  resetFilters() {
    this.filterModel = null;
  }

  private calculateMeta(
    pageNumber: number,
    pageSize: number,
    totalCount: number
  ) {
    const start = (pageNumber - 1) * pageSize + 1;
    const totalPages = Math.ceil((totalCount ?? 0) / pageSize);
    const totalRecords = totalCount ?? 0;
    return { start, totalPages, totalRecords };
  }

  *setSortModel(sortModel, fetch = true) {
    this.sortModel = sortModel;
    yield this.setPageNumber(1, false);
    yield this.setSortParams(
      {
        sortBy: sortModel[0]?.colId ?? '',
        sortDir: sortModel[0]?.sort?.toUpperCase() ?? '',
      },
      fetch
    );
  }

  *setFilterModel(filterModel, fetch = true) {
    this.filterModel = filterModel;
    yield this.setPageNumber(1, false);
    const filterParams = this.getParamsFromFilterModel();
    this.setFiltersParam(filterParams, fetch);
  }

  private getParamsFromFilterModel() {
    const filters = {};
    const dropdownFilters = {};
    if (this.filterModel) {
      for (const column in this.filterModel) {
        const model = this.filterModel[column];
        if (model.filterType === 'date') {
          filters[column] = formatDate(model.dateFrom, 'MM/dd/yyyy');
        } else if (
          model.filterType === 'select' &&
          model.selectedOptions?.length > 0
        ) {
          dropdownFilters[column] = model.selectedOptions.join(',');
        } else if (model.filter) {
          filters[column] = model.filter;
        }
      }
    }
    return { filters, dropdownFilters };
  }
}
