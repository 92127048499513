import { Grid } from '@material-ui/core';
import { observer } from 'mobx-react';
import { useNewLoanApplicationStore } from '../hooks/useNewLoanApplicationStore';
import {
  LoanApplicationStep
} from '../utils/loanApplicationConstants';
import { BorrowerInformationModal } from './borrowerInformationModal';
import { BorrowersCard } from './borrowersCard';
import { EntityCard } from './entityCard';
import { EntityInformationModal } from './entityInformationModal';
import {
  LoanStep, TotalEntityOwnershipWarning
} from './loanApplicationComponents';

export const EntityInformationStep = observer(() => {
  const { newLoanApplicationStore } = useNewLoanApplicationStore();
  const { borrowerStore } = newLoanApplicationStore;
  const { showTotalOwnershipWarning } = borrowerStore;

  return (
    <LoanStep
      title={'Entity Information'}
      subtitle={
        'Please select or add the borrowing entity for this loan application.'
      }
      disableContinue={showTotalOwnershipWarning}
      onContinue={() => newLoanApplicationStore.goToStep(LoanApplicationStep.PROPERTY_INFORMATION)}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <EntityCard />
        </Grid>
        <Grid item xs={12}>
          <BorrowersCard  />
        </Grid>
        {showTotalOwnershipWarning && (
          <Grid item xs={12}>
            <TotalEntityOwnershipWarning />
          </Grid>
        )}
      </Grid>
      <EntityInformationModal />
      <BorrowerInformationModal />
    </LoanStep>
  );
});
