import { Grid } from '@material-ui/core';
import { Button, StandardDialog } from '@roc/ui';
import { observer } from 'mobx-react';
import { AppraisalOrderDetails } from './appraisalOrderDetails';

export type AppraisalOrderDetailsDialogProps = {
  open?: boolean;
  onClose?: () => void;
  loanId?: number;
  collateralId?: number;
  propertyAppraisalId?: number;
};

export const AppraisalOrderDetailsDialog = observer(
  (props: AppraisalOrderDetailsDialogProps) => {
    const { open, onClose, loanId, collateralId, propertyAppraisalId } = props;

    return (
      <StandardDialog
        open={open}
        title="Appraisal Order Details"
        maxWidth="lg"
        handleClose={onClose}
        dialogContent={<AppraisalOrderDetails
          loanId={loanId}
          collateralId={collateralId}
          propertyAppraisalId={propertyAppraisalId}
        />}
        dialogActions={
          <Grid container spacing={1} justifyContent="flex-end">
            <Grid item>
              <Button
                onClick={onClose}
                color="primary"
                variant="outlined"
                testId="cancel"
              >
                Close
              </Button>
            </Grid>
          </Grid>
        }
      />
    );
  }
);
