import { observer } from "mobx-react";
import { StandardDialog } from "../dialog";
import { Grid, Typography } from "@material-ui/core";
import { DataGrid, GridColumnProps } from "../dataGrid";
import { useState } from "react";
import { usdAmount } from "./utils";
import MatDataGrid from "../matDataGrid/matDataGrid";
export interface Props {
  store: any;
  property: any;
  open: boolean;
  setModalOpen: (val: boolean) => void;
  handleClose: () => void;
  titleHistory: any;
}

export const TrackRecordTitleHistory = observer((props: Props) => {
  const { store, property, titleHistory, open, setModalOpen, handleClose } = props;
  const titleHistoryColumns = [
    'Action Date',
    'Purchase Date',
    'Sell Date',
    'Recording Date',
    'Property Type',
    'Seller',
    'Buyer',
    'Lender',
    'Loan Amount',
    'Seller Price',
    'Interest Rate'
  ];

  const titleHistoryRows = titleHistory?.reduce((rows, titleHistory) => {
    rows.push(
      {
        id: titleHistory.id,
        object: titleHistory,
        type: 'property',
        cells: [
          titleHistory.actionDate ? titleHistory.actionDate : '-',
          titleHistory.purchaseDate ? titleHistory.purchaseDate : '-',
          titleHistory.sellDate ? titleHistory.sellDate : '-',
          titleHistory.recordingDate ? titleHistory.recordingDate : '-',
          titleHistory.propertyType ? titleHistory.propertyType : '-',
          titleHistory.seller ? titleHistory.seller : '-',
          titleHistory.buyer ? titleHistory.buyer : '-',
          titleHistory.lender ? titleHistory.lender : '-',
          titleHistory.loanAmount ? usdAmount({ value: titleHistory.loanAmount })
            : '$0.00',
          titleHistory.sellPrice ? usdAmount({ value: titleHistory.sellPrice })
            : '$0.00',
          titleHistory.interestRate ? titleHistory.interestRate : '-',
        ],
        expandableContent: (
          <Grid container>
            <Grid item xs={12} style={{ marginBottom: '3rem', display: 'flex', justifyContent: 'space-between' }}>
              <Grid>

                <Grid >
                  <Grid style={{ marginRight: '4rem', marginBottom: '4rem' }}>
                    <strong>Description: </strong>
                    <Grid style={{ display: 'flex', flexDirection: "column" }}>
                      {titleHistory.description
                      }
                    </Grid>
                  </Grid>

                </Grid>

              </Grid>
            </Grid>
          </Grid>
        )
      }
    )
    return rows;
  }, [])
  return (
    <StandardDialog
      open={open}
      maxWidth="xl"
      handleClose={() => setModalOpen(false)}
      title='Title History'
      dialogContent={
        <Grid container spacing={3}>
          <Typography variant="h6">{property?.fullAddress ? property?.fullAddress : `${property?.purchaseAddress}, ${property?.purchaseCity}, ${property?.purchaseState}, ${property?.purchaseZipcode}`}</Typography>
          <MatDataGrid
            store={store}
            isExpandable={true}
            enableSelection={false}
            headers={titleHistory ? titleHistoryColumns : []}
            rows={titleHistory ? titleHistoryRows : []}
          />
        </Grid>
      }
    />
  );
});
export default TrackRecordTitleHistory;