import { observer } from "mobx-react";
import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { Grid, Theme } from '@material-ui/core';
import { PropertiesMap } from "./propertiesMap";
import { CheckBoxes } from "./checkBoxes";
import { CompGridHeader } from "../header/compGridHeader";
import { CompPagination } from "../paginationBar";
import { useStore } from "@roc/client-portal-shared/hooks";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mapStyles: {
      position: `relative`,
      width: `100%`,
      height: `750px`,
    },
    checkBoxContainer: {
      overflowY: 'hidden',
      width: `100%`,
      overflowX: 'hidden',
      marginBottom: '40px'
    },
    pagination: {
      justifyContent: 'flex-start',
    },

  })
);


export const PropertiesMapExtended = observer(
  () => {
    const classes = useStyles();
    const { compToolStore } = useStore();

    const { subjectProperty } = compToolStore
    const { data } = subjectProperty

    return (
      <>
        <CompGridHeader />

        <Grid item xs={12}>
          <PropertiesMap mapStyles={classes.mapStyles} markers={compToolStore.compResponse.properties.slice(1)} />
        </Grid>

        <Grid item xs={12} className={classes.checkBoxContainer}>
          <Grid container spacing={2}>
            <CheckBoxes properties={compToolStore.compResponse.properties.filter(p => !p.is_subject)} />
          </Grid>
        </Grid>

        <Grid item xs={12} style={{ marginBottom: compToolStore.openCompareGadget ? '250px' : '' }}>
          <CompPagination
            paginationStyles={classes.pagination}
          />
        </Grid>
      </>
    );
  }
);