import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Paper } from '@roc/ui';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { ExpectedClosingsCardTitle } from './expectedClosingsCardTitle';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    accordionRoot: {
      margin: '0 0 16px 0 !important',
      borderRadius: '8px !important',
      boxShadow: 'none !important',
      border: '1px solid #e0e0e0',
      '&:before': {
        opacity: 0,
      },
      '& .MuiAccordionSummary-root': {
        borderTopRightRadius: 8,
        borderTopLeftRadius: 8,
      },
      '& .MuiAccordionSummary-content': {
        margin: '16px 0 !important',
      },
      '& .MuiAccordionDetails-root': {
        padding: 0,
      },
    },
  })
);

export const ExpectedClosingsAccordion = observer(({ title, children }) => {
  const [expanded, setExpanded] = useState(true);
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Accordion
        component={Paper}
        className={classes.accordionRoot}
        expanded={expanded}
        onChange={(e, expanded) => setExpanded(expanded)}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} id="panel1a-header">
          <ExpectedClosingsCardTitle title={title} />
        </AccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
      </Accordion>
    </div>
  );
});
