const params_regex = /^([\w]+:[A-z0-9_@.-]+)(;[\w]+:[A-z0-9_@.-]+)*$/;

const parseParams = (decodedParams: string) => {
  const response: any = {};
  const params = decodedParams.split(';');
  params.forEach(param => {
    const [key, value] = param.split(':');
    response[key] = value;
  });
  return {
    drawId: response.drawId
      ? parseInt(decodeURIComponent(response.drawId))
      : null,

    loanId: response.loanId
      ? parseInt(decodeURIComponent(response.loanId))
      : null,

    username: response?.username ? response?.username : '',
  };
};

export const encodeParams = (data: string): string => {
  return window.btoa(encodeURIComponent(data));
};

export const decodeParams = (data: string) => {
  const decodedParams = window.atob(data.replace('-', '/'));
  if (params_regex.test(decodedParams)) {
    return parseParams(decodedParams);
  }
};
