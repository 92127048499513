import { FormStore } from '@roc/feature-app-core';
import { action, makeObservable, observable, computed, flow } from 'mobx';
import { RentalPortfolioStore } from './rentalPortfolioStore';
import * as constants from '../common/pricerSummaryHelper';
import { PropertyTerm } from '@roc/feature-types';
import {
  MIXED_USE,
  MULTIFAMILY_5_PLUS,
  REFINANCE,
  propertyStates,
  FULL_RECOURSE,
  CONDOMINIUM,
  TOWNHOME,
  PLANNEDUNIT,
  SINGLE_FAMILY,
  DUPLEX,
  TRIPLEX,
  QUADRUPLEX,
  statePropertyTypeRestriction,
} from '@roc/feature-utils';
import { GlobalStore } from '@roc/feature-app-core';
import { mapLoanPurpose } from '../common/pricerSummaryHelper';
import { MSAService } from '../../services/msaService';

const propertyForm = {
  fields: {
    rentalPricerId: {
      value: '',
      error: null,
      rule: '',
    },
    pricerType: {
      value: '',
      error: null,
      rule: '',
    },
    propertyStates: {
      value: '',
      error: null,
      rule: 'required',
      message: 'Required',
    },
    propertyCounties: {
      value: '',
      error: null,
      rule: 'required',
      message: 'Required',
    },
    loanPurpose: {
      value: '',
      error: null,
      rule: 'required',
      message: 'Required',
    },
    acquisitionDate: {
      value: null,
      error: null,
      rule: [{ required_if: ['loanPurpose', REFINANCE] }],
      message: 'Required',
    },
    acquisitionPrice: {
      value: '',
      error: null,
      rule: 'required',
      message: 'Required',
    },
    capitalImprovements: {
      value: '',
      error: null,
      rule: [{ required_if: ['loanPurpose', REFINANCE] }],
      message: 'Required',
    },
    anyDebt: {
      value: '',
      error: null,
      rule: [{ required_with: ['capitalImprovements'] }],
      message: 'Required',
    },
    totalDebtPayoff: {
      value: '',
      error: null,
      rule: [{ required_if: ['anyDebt', 'Y'] }],
      message: 'Required',
    },
    targetLTV: {
      value: '',
      error: null,
      message: 'Required',
      rule: 'required',
    },
    minimumAsIsValue: {
      value: '',
      error: null,
      rule: 'required',
      message: 'Required',
    },
    maximumAsIsValue: {
      value: '',
      error: null,
      rule: 'required',
      message: 'Required',
    },
    totalEstimatedAsIsValue: {
      value: '',
      error: null,
      rule: 'required|min:1',
      message: 'Required. Must be greater than 0',
    },
    propertyManagement: {
      value: '',
      error: null,
      rule: 'required',
      message: 'Required',
    },
    totalMixUseEstimatedAsIsValue: {
      value: '',
      error: null,
      nextStep: 'requiredForMU',
      message: 'Required',
    },
    totalGrossMonthlyRent: {
      value: '',
      error: null,
      rule: 'required|min:1',
      message: 'Required. Must be greater than 0',
    },
    totalGrossAnnualTaxes: {
      value: '',
      error: null,
      rule: 'required',
      message: 'Required',
    },
    totalGrossAnnualInsurance: {
      value: '',
      error: null,
      rule: 'required',
      message: 'Required',
    },
    totalAnnualHOADues: {
      value: '',
      error: null,
      rule: 'required',
      message: 'Required',
    },
    totalAnnualUtilities: {
      value: '',
      error: null,
      rule: [
        {
          required_if_one_of: [
            'propertyType',
            CONDOMINIUM,
            MIXED_USE,
            MULTIFAMILY_5_PLUS,
            TOWNHOME,
            PLANNEDUNIT
          ],
        },
      ],
      message: 'Required',
    },
    totalAnnualRepairsMaintenance: {
      value: '',
      error: null,
      rule: [
        {
          required_if_one_of: [
            'propertyType',
            CONDOMINIUM,
            MIXED_USE,
            MULTIFAMILY_5_PLUS,
            TOWNHOME,
            PLANNEDUNIT
          ],
        },
      ],
      message: 'Required',
    },
    totalAnnualPropertyManagementFees: {
      value: '',
      error: null,
      rule: [
        {
          required_if_one_of: [
            'propertyType',
            CONDOMINIUM,
            MIXED_USE,
            MULTIFAMILY_5_PLUS,
            TOWNHOME,
            PLANNEDUNIT
          ],
        },
      ],
      message: 'Required',
    },
    totalAnnualNOI: {
      value: '',
      error: null,
      rule: 'min:1',
      message:
        'Property must have positive income to qualify for a loan, please verify monthly income and annual expenses provided',
    },
    totalAnnualIncome: {
      value: '',
      error: null,
    },
    totalAnnualExpenses: {
      value: '',
      error: null,
    },
    borrowerId: {
      value: null,
      error: null,
      rule: '',
    },
    borrowerExp: {
      value: '',
      error: null,
      rule: 'required',
      message: 'Required',
    },
    ficoProvidedAtOrigination: {
      value: 660,
      error: null,
      rule: 'required',
    },
    bankruptcy: {
      value: 'N',
      error: null,
      rule: '',
    },
    bankruptcyDate: {
      value: null,
      error: null,
      rule: { required_if: ['bankruptcy', 'Y'] },
    },
    foreclosure: {
      value: 'N',
      error: null,
      rule: '',
    },
    foreclosureDate: {
      value: null,
      error: null,
      rule: { required_if: ['foreclosure', 'Y'] },
    },
    citizenshipStatus: {
      value: 'US Citizen',
      error: null,
      rule: '',
    },
    loanRecourse: {
      value: '',
      error: null,
      rule: 'required',
      message: 'Required',
    },
    generalAdministrative: {
      value: '',
      error: null,
      rule: '',
      message: 'Required',
    },
    payroll: {
      value: '',
      error: null,
      rule: '',
      message: 'Required',
    },
    marketing: {
      value: '',
      error: null,
      rule: '',
      message: 'Required',
    },
    replacementReserves: {
      value: '',
      error: null,
      rule: '',
      message: 'Required',
    },
    properties: {
      value: '',
      error: null,
    },
    section8: {
      value: null,
      error: null,
      rule: 'required',
      message: 'Required',
    },
    rural: {
      value: null,
      error: null,
      rule: ''
    }
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export class PricerStore extends FormStore {
  private globalStore: GlobalStore;
  private rentalPortfolioStore: RentalPortfolioStore;
  isPauseMultifamilySubmissionsOpen: boolean;
  public isPropertyMSARural: boolean;
  private msaService: MSAService;
  constructor(globalStore, rentalPortfolioStore) {
    super({ ...propertyForm }, globalStore);
    this.globalStore = globalStore;
    this.rentalPortfolioStore = rentalPortfolioStore;
    this.msaService = new MSAService();
    makeObservable(this, {
      getTotalExpenses: action,
      getTotalAnnualNOI: action,
      handleLoanPurposeChange: action,
      handlePricerType: action,
      handleAnyDebtChange: action,
      handleIncomeChange: action,
      handleExpenseChange: action,
      getTotalIncome: action,
      handlePropertyTypeChange: action,
      defaultUnitsFromPropertyType: action,
      checkIfRequiredFieldsEmpty: action,
      checkIfRequiredPropertyFieldsEmpty: action,
      checkIfFieldIsEmpty: action,
      getPricerStepErrors: action,
      checkForPricerStepErrors: action,
      checkForPropertyStepErrors: action,
      getAnyDebt: action,
      getSunkCost: action,
      getProperty: action,
      counties: computed,
      pricerBorrower: computed,
      isPauseMultifamilySubmissionsOpen: observable,
      setIsPauseMultifamilySubmissionsOpen: action,
      isPropertyMSARural: observable,
      checkIfPropertyIsRural: flow
    });
  }

  get isSingleProperty() {
    return false;
  }

  get counties() {
    const { allCounties } = this.rentalPortfolioStore;
    const propertyStateId = propertyStates.find(
      x => x.value === this.form.fields.propertyStates.value
    )?.id;
    const states = propertyStateId ? [propertyStateId] : [];
    let selectedStateCounties = [];
    if (states && states.length > 0) {
      selectedStateCounties = states.reduce(
        (c, s) => [...c, ...(allCounties[s] ?? [])],
        []
      );
    }

    const counties = [];
    if (selectedStateCounties && selectedStateCounties.length > 0) {
      selectedStateCounties.sort().map(county => {
        counties.push({ value: county, label: county });
      });
    }

    return counties;
  }

  public *checkIfPropertyIsRural(address: string) {
    const response = yield this.msaService.checkIfPropertyIsRural(address);
    this.isPropertyMSARural = response.data.data;
  }

  onPropertyStateChanged(value) {
    this.onFieldChange('propertyStates', value);
    this.rentalPortfolioStore.loanDetailsStore.setDefaultPreferredTitle();
  }

  getTotalAnnualNOI = () => {
    const { totalAnnualIncome, totalAnnualExpenses } = this.form.fields;
    const totalIncome = isNaN(Number(totalAnnualIncome.value))
      ? 0
      : Number(totalAnnualIncome.value);
    const totalExpenses = isNaN(Number(totalAnnualExpenses.value))
      ? 0
      : Number(totalAnnualExpenses.value);
    const totalAnnualNOI = totalIncome - totalExpenses;
    this.onFieldChange('totalAnnualNOI', totalAnnualNOI);
  };

  handleLoanPurposeChange = (name, value) => {
    this.onFieldChange(name, value);

    if (this.form.fields.loanPurpose.value === constants.PURCHASE) {
      this.form.fields.acquisitionDate.value = null;
      this.form.fields.capitalImprovements.value = '';
      this.form.fields.anyDebt.value = '';
      this.form.fields.totalDebtPayoff.value = '';
    }
  };

  handlePricerType = (value) => {
    this.form.fields.pricerType.value = value;
  };

  handleAnyDebtChange = (name, value) => {
    this.onFieldChange(name, value);

    if (this.form.fields.anyDebt.value === constants.NO) {
      this.form.fields.totalDebtPayoff.value = '';
    }
  };

  handleIncomeChange = (name, value) => {
    this.onFieldChange(name, value);
    this.getTotalIncome();
    this.getTotalAnnualNOI();
  };

  handleExpenseChange = (name, value) => {
    this.onFieldChange(name, value);
    this.getTotalExpenses();
    this.getTotalAnnualNOI();
  };

  getTotalIncome = () => {
    const rent = isNaN(Number(this.form.fields.totalGrossMonthlyRent.value))
      ? 0
      : Number(this.form.fields.totalGrossMonthlyRent.value);
    const totalAnnualIncome = rent * 12;
    this.onFieldChange('totalAnnualIncome', totalAnnualIncome);
  };

  getTotalExpenses = () => {
    const {
      totalGrossAnnualTaxes,
      totalGrossAnnualInsurance,
      totalAnnualHOADues,
      totalAnnualUtilities,
      totalAnnualRepairsMaintenance,
      totalAnnualPropertyManagementFees,
      generalAdministrative,
      payroll,
      marketing,
      replacementReserves,
    } = this.form.fields;
    const taxes = isNaN(Number(totalGrossAnnualTaxes.value))
      ? 0
      : Number(totalGrossAnnualTaxes.value);
    const insurance = isNaN(Number(totalGrossAnnualInsurance.value))
      ? 0
      : Number(totalGrossAnnualInsurance.value);
    const hoa = isNaN(Number(totalAnnualHOADues.value))
      ? 0
      : Number(totalAnnualHOADues.value);
    const utilities = isNaN(Number(totalAnnualUtilities.value))
      ? 0
      : Number(totalAnnualUtilities.value);
    const repairsMaintenance = isNaN(
      Number(totalAnnualRepairsMaintenance.value)
    )
      ? 0
      : Number(totalAnnualRepairsMaintenance.value);
    const propertyManagementFees = isNaN(
      Number(totalAnnualPropertyManagementFees.value)
    )
      ? 0
      : Number(totalAnnualPropertyManagementFees.value);
    const generalAdministrativeCal = isNaN(Number(generalAdministrative.value))
      ? 0
      : Number(generalAdministrative.value);
    const payrollCal = isNaN(Number(payroll.value)) ? 0 : Number(payroll.value);
    const marketingCal = isNaN(Number(marketing.value))
      ? 0
      : Number(marketing.value);
    const replacementReservesCal = isNaN(Number(replacementReserves.value))
      ? 0
      : Number(replacementReserves.value);

    const totalAnnualExpenses =
      taxes +
      insurance +
      hoa +
      utilities +
      repairsMaintenance +
      propertyManagementFees +
      generalAdministrativeCal +
      payrollCal +
      marketingCal +
      replacementReservesCal;

    this.onFieldChange('totalAnnualExpenses', totalAnnualExpenses);
  };

  getAnyDebt() {
    return this.form.fields.anyDebt.value === 'Y';
  }

  getSunkCost() {
    return 0;
  }

  getUnitsOccupiedHave12MonthsLease() {
    return this.form.fields.unitsOccupiedHave12MonthsLease.value === 'Y';
  }

  getAcquisitionDate() {
    const { acquisitionDate } = this.form.fields;
    return acquisitionDate.value;
  }

  getLoanPurpose() {
    const { loanPurpose, anyDebt } = this.form.fields;
    const { form } = this.rentalPortfolioStore.pricerSummaryStore;
    const { seekingCashOut } = form.fields;
    const loanPurposeValue = mapLoanPurpose(loanPurpose.value, seekingCashOut.value, anyDebt.value);
    return constants.loanPurposeDic[loanPurposeValue];
  }

  getProperty(): PropertyTerm {
    const data = this.getFormValues();

    return {
      rentalPricerId: data['rentalPricerId'],
      pricerType: data['pricerType'],
      loanPurpose: this.getLoanPurpose(),
      acquisitionDate: this.getAcquisitionDate(),
      acquisitionPrice: data['acquisitionPrice'],
      capitalImprovements: data['capitalImprovements'],
      anyDebt: this.getAnyDebt(),
      totalDebtPayoff: data['totalDebtPayoff'],
      totalAnnualUtilities: data['totalAnnualUtilities'],
      totalAnnualRepairsMaintenance: data['totalAnnualRepairsMaintenance'],
      totalAnnualPropertyManagementFees:
        data['totalAnnualPropertyManagementFees'],
      residentialUnits: null,
      commercialUnits: null,
      residentialUnitsSqFtArea: '',
      commercialUnitsSqFtArea: '',
      residentialIncome: null,
      commercialIncome: null,
      commercialUnitsCurrentOnRent: '',
      maximumAsIsValue: data['maximumAsIsValue'],
      minimumAsIsValue: data['minimumAsIsValue'],
      targetLTV: data['targetLTV'],
      totalAnnualNOI: data['totalAnnualNOI'],
      totalAnnualIncome: data['totalAnnualIncome'],
      totalAnnualExpenses: data['totalAnnualExpenses'],
      propertyStates: [data['propertyStates']],
      state: data['propertyStates'],
      counties: this.rentalPortfolioStore.getPropertyCounties().join('-'),
      propertyCounties: this.rentalPortfolioStore.getPropertyCounties(),
      propertyCountiesSelectedValues: this.rentalPortfolioStore.getPropertyCountiesSelectedValues(),
      propertyType: this.rentalPortfolioStore.getPropertyTypesAsString(),
      numberOfProperties: this.rentalPortfolioStore.getNumberOfProperties(),
      propertyManagement: data['propertyManagement'],
      units: this.rentalPortfolioStore.getNumberOfUnits(),
      propertyValuation: data['totalEstimatedAsIsValue'],
      monthlyGrossRent: data['totalGrossMonthlyRent'],
      annualTaxes: data['totalGrossAnnualTaxes'],
      annualInsurance: data['totalGrossAnnualInsurance'],
      annualHOA: data['totalAnnualHOADues'],
      otherOwnerPaidExpenses: data['totalOtherAnnualExpenses'],
      propertyTypeData: this.rentalPortfolioStore.getPropertyDataPortfolio(),
      rural: data['rural'] === null ? null : data['rural'] == 'Y',
      section8: data['section8'] == 'Y',
      loanRecourse:
        data['loanRecourse'] === FULL_RECOURSE
          ? 'Full Recourse'
          : 'Non Recourse w/ bad boy carveouts',
    };
  }

  handlePropertyTypeChange = propertyType => {
    if (propertyType === MULTIFAMILY_5_PLUS || propertyType === MIXED_USE) {
      this.setIsPauseMultifamilySubmissionsOpen(true);
    }
    else {
      // if ( propertyType === MULTIFAMILY_5_PLUS &&
      //   this.currentBorrower?.borrowerExp === '0'
      // ) {
      //   SFRContext.setCurrentBorrower('borrowerExp', '');
      // }
      this.defaultUnitsFromPropertyType(propertyType);
      //this.setCalculateRatesFlag(true);
    }
  };

  defaultUnitsFromPropertyType = value => {
    this.onFieldChange('residentialUnits', '');
    this.onFieldChange('commercialUnits', '');
    switch (value) {
      case 'Single Family':
      case 'Townhome':
      case 'Planned Unit Development':
      case 'Condominium':
        this.onFieldChange('units', 1);
        break;
      case 'Duplex':
        this.onFieldChange('units', 2);
        break;
      case 'Triplex':
        this.onFieldChange('units', 3);
        break;
      case 'Quadruplex':
        this.onFieldChange('units', 4);
        break;
      case MULTIFAMILY_5_PLUS:
        this.onFieldChange('units', 5);
        break;
      case MIXED_USE:
        this.onFieldChange('units', '');
        this.onFieldChange('residentialUnits', 1);
        this.onFieldChange('commercialUnits', 1);
        break;
      default:
        this.onFieldChange('units', '');
        break;
    }
  };

  checkIfRequiredFieldsEmpty = () => {
    const fields = this.form.fields;
    for (let field in fields) {
      this.onFieldChange(field, fields[field].value);
    }
    return !this.form.meta.isValid;
  };

  checkIfRequiredPropertyFieldsEmpty = () => {
    let requiredFieldEmpty = false;
    const fields = this.form.fields;

    for (let property in fields) {
      if (
        fields[property].nextStep === 'requiredForProperty' &&
        !fields[property].value
      ) {
        requiredFieldEmpty = this.checkIfFieldIsEmpty(fields, property);
      }
    }
    return requiredFieldEmpty;
  };

  checkIfFieldIsEmpty(fields, property) {
    let requiredFieldEmpty = false;
    if (
      typeof fields[property].value === 'number' &&
      fields[property].value === 0
    ) {
      return requiredFieldEmpty;
    } else {
      this.onFieldChange(property, '');
      requiredFieldEmpty = true;
      return requiredFieldEmpty;
    }
  }

  getPricerStepErrors() {
    const totalNumberOfProperties = this.rentalPortfolioStore.getNumberOfProperties();
    const data = this.rentalPortfolioStore.getPropertyDataPortfolio();
    const totalEstimatedAsIsValue = data
      .map(p => p.estimatedAsIsValue)
      .reduce((a, b) => Number(a) + Number(b), 0);
    const fields = this.form.fields;
    let errors = {} as any;

    if (totalNumberOfProperties <= 1) {
      errors.totalNumberOfProperties =
        'Total Number of properties should be greater than 1';
    }
    if (totalEstimatedAsIsValue !== fields.totalEstimatedAsIsValue.value) {
      errors.totalEstimatedAsIsValue =
        'Estimated As is value for all the properties should be equal to the Total Estimated As Is Value';
    }
    if (fields.ficoProvidedAtOrigination.value < 660) {
      errors.ficoProvidedAtOrigination = 'FICO score too low';
    }
    if (
      fields.bankruptcy.value === 'Y' &&
      !this.rentalPortfolioStore.isValidBankruptcyDate(
        fields.bankruptcyDate.value
      )
    ) {
      errors.bankruptcyDate = 'Bankruptcy Date must be no less than 3 Years';
    }
    if (
      fields.foreclosure.value === 'Y' &&
      !this.rentalPortfolioStore.isValidForeclosureDate(
        fields.foreclosureDate.value
      )
    ) {
      errors.foreclosureDate = 'Foreclosure Date must be no less than 3 Years';
    }
    if (fields.targetLTV.value > 80) {
      errors.targetLTV = `Target LTV limit to <= 80% and "Maximum target LTV is 80%"`;
    }
    if (fields.totalAnnualNOI.value < 1) {
      errors.totalAnnualNOI =
        'Property must have positive income to qualify for a loan, please verify monthly income and annual expenses provided';
    }
    if(statePropertyTypeRestriction.states.includes(fields.propertyStates.value)) {
      const isFloridaCondo = data.map(p => p.propertyType).reduce((acc, val) => acc || statePropertyTypeRestriction.propertyTypes.includes(val), false);
      if(isFloridaCondo) {
        const errorMsg = statePropertyTypeRestriction.message;
        this.form.fields.propertyStates.error = errorMsg;
        this.rentalPortfolioStore.stepError = errorMsg;
      }
    }
    return errors;
  }

  checkForPricerStepErrors() {
    const fields = this.form.fields;

    const errors = this.getPricerStepErrors();
    for (let name in errors) {
      this.rentalPortfolioStore.stepError = errors[name];
      if (fields[name]) {
        fields[name].error = errors[name];
      }
    }

    if (this.rentalPortfolioStore.stepError) {
      return true;
    }
  }

  checkForPropertyStepErrors() {
    return false;
  }

  get pricerBorrower() {
    return {
      borrowerExp: this.form.fields.borrowerExp.value,
      ficoProvidedAtOrigination: this.form.fields.ficoProvidedAtOrigination
        .value,
      bankruptcy: this.form.fields.bankruptcy.value,
      foreclosure: this.form.fields.foreclosure.value,
      citizenshipStatus: this.form.fields.citizenshipStatus.value,
    };
  }

  get foreignNational() {
    return this.form.fields.citizenshipStatus.value;
  }

  get borrowerExp() {
    return this.form.fields.borrowerExp.value;
  }

  get ficoProvidedAtOrigination() {
    return this.form.fields.ficoProvidedAtOrigination.value;
  }

  get totalMixUseEstimatedAsIsValue() {
    return this.rentalPortfolioStore.getPropertyTypes().includes(MIXED_USE)
      ? this.form.fields.totalMixUseEstimatedAsIsValue.value
      : null;
  }

  get residentialUnits() {
    return 0;
  }

  get commercialUnits() {
    return 0;
  }

  get pledgeOfShares() {
    return true;
  }

  setIsPauseMultifamilySubmissionsOpen(open: boolean) {
    this.isPauseMultifamilySubmissionsOpen = open;
  }
}

export default PricerStore;
