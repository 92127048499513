import { Route } from 'react-router';
import { useStore } from '@roc/client-portal-shared/hooks';
import { Page } from '@roc/feature-app-core';
import { useRocInternalPortalStore } from '../../../hooks';
import { MultifamilyLiquidityCalculator } from 'libs/client-portal-shared/src/app/modules/multifamilyLiquidityCalculator';

export const getMultifamilyLiquidityCalculatorInternalRoutesConfig = (
  basePath: string,
  baseUrl: string
) => {
  return {
    multifamilyLiquidityCalculator: {
      path: `${basePath}/multifamily-liquidity-calculator`,
      url: `${baseUrl}/multifamily-liquidity-calculator`,
      documentTitle: 'Multifamily Liquidity Calculator',
    },
  };
};

export const getMultifamilyLiquidityCalculatorInternalRoutes = (
  basePath: string,
  baseUrl: string
) => {
  const config = getMultifamilyLiquidityCalculatorInternalRoutesConfig(basePath, baseUrl);
  const { multifamilyliquidityCalculatorInternalStore } = useRocInternalPortalStore();

  return {
    multifamilyLiquidityCalculator: (
      <Route exact path={config.multifamilyLiquidityCalculator.path}>
        <Page routeDefinition={config.multifamilyLiquidityCalculator}>
          <MultifamilyLiquidityCalculator store={multifamilyliquidityCalculatorInternalStore} />
        </Page>
      </Route>
    ),
  };
};
