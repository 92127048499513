import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { Box, Grid, Typography } from "@material-ui/core";
import { observer } from "mobx-react-lite";

const useStyles = makeStyles(theme =>
  createStyles({
    centerText: {
      textAlign: 'center',
    },
  })
);


export const DenialOfCreditSucess = observer(() => {
  const classes = useStyles();
  return (
    <Grid container justifyContent="space-around">
      <Grid item xs={12}>
        <Box className={classes.centerText}>
          <Typography variant="h5">
            Your Rationale Of Credit Denial Request Was Succesfully Submitted!
          </Typography>
        </Box>
        <Box className={classes.centerText}>
          <Typography>Your rationale of credit denial request has been sent to the commitee, we will be in contact with you shortly.</Typography>
        </Box>
      </Grid>
    </Grid>
  )
})