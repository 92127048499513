
import { useTheme } from '@material-ui/core/styles';
import React from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Box, Typography, useMediaQuery } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { Accordion, AccordionDetails, AccordionSummary, DenseTable } from '@roc/ui';
import { sourcesColumns } from './sourcesAndUsesColumns';



export const SourcesCard = () => {
  const [expanded, setExpanded] = React.useState(true);
  const theme = useTheme();
  const lgUp = useMediaQuery(theme.breakpoints.up('lg'), { noSsr: true });

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Accordion square expanded={expanded} onChange={handleExpandClick}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}>
        <Typography style={{
          fontSize: 18,
          fontWeight: 'bold'
        }}>
          Sources
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2} style={{
          margin: 'auto',
          width: '100%'
        }}>
          {Object.keys(sourcesColumns()).map((columnKey) => (
            <Grid item xs={12} md={4} lg={4} style={{
              borderRight: (lgUp ? '1px dashed #eee' : 'none')
            }}>
              <Box p={1}>
                <DenseTable data={sourcesColumns()[columnKey]} />
              </Box>
            </Grid>
          ))}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}