import { observer } from 'mobx-react';
import { PreflightStep } from '../../../utils/constants';
import { EntityInformationAccordion } from '../../summary/components/entityInformation/entityInformationAccordion';
import { EntityInformationEmptyCard } from '../../summary/components/entityInformation/entityInformationEmptyCard';
import { EntityInformationForm } from '../../summary/components/entityInformation/entityInformationForm';
import { PrefligthCard } from './preflightCard';

export const PrefligthEntityCard = observer(({ store }) => {
  const entityFormStore = store.entityStore.entityFormStore;
  const editMode = entityFormStore.form.meta.editMode;
  const hasEntity = entityFormStore.form.fields.hasEntity.value;
  const { preflightStore } = store;

  const onDeleteClick = () => {};
  const step = PreflightStep.ENTITY;

  return (
    <PrefligthCard
      store={store}
      step={step}
      onEdit={() => store.preflightStore.goToStep(step)}
      onReset={() => {
        store.entityStore.entityFormStore.reset();
      }}
      onSave={() => {
        store.preflightStore.goToNextStep();
      }}
      // onSkip={() => {
      //   store.entityStore.setHasEntity(false);
      //   store.preflightStore.goToNextStep();
      // }}
      FormComponent={() => (
        <EntityInformationForm
          store={store}
          onDeleteClick={onDeleteClick}
          showActions={false}
        />
      )}
      AccordionComponent={props => (
        <>
          {hasEntity && (
            <EntityInformationAccordion
              {...props}
              store={entityFormStore}
              borrowersStore={store.borrowersStore}
              showActions={true}
            />
          )}
          {!hasEntity && (
            <EntityInformationEmptyCard
              onAddBroker={() => {
                preflightStore.goToStep(step);
              }}
            />
          )}
        </>
      )}
    />
  );
});
