import { Grid } from '@material-ui/core';
import { createTextField, createPhoneField } from '@roc/ui/componentBuilder';
import { observer } from 'mobx-react';
import { DenialOfCreditFormStore } from '../stores/denialOfCreditFormStore';

interface DenialOfCreditField {
  store: DenialOfCreditFormStore;
}

const field = component => {
  const WrappedComponent = observer(component);
  return props => (
    <Grid item xs={12}>
      <WrappedComponent {...props}/>
    </Grid>
  );
};

export const FirstName = field(({store}: DenialOfCreditField) => {
  return createTextField({
    store,
    label: 'First name',
    fieldName: 'firstName',
    testId: 'firstName',
  });
});

export const LastName = field(({store}: DenialOfCreditField) => {
  return createTextField({
    store,
    label: 'Last name',
    fieldName: 'lastName',
    testId: 'lastName',
  });
});

export const PhoneNumber = field(({store}: DenialOfCreditField) => {
  return createPhoneField({
    testId: 'phoneNumber',
    label: 'Phone Number',
    fieldName: 'phoneNumber',
    format: '(###) ###-####',
    store,
  });
});

export const BorrowingEntityName = field(({store}: DenialOfCreditField) => {
  return createTextField({
    store,
    label: 'Borrowing entity name',
    fieldName: 'borrowingEntityName',
    testId: 'borrowingEntityName',
  });
});

export const LoanId = field(({store}: DenialOfCreditField) => {
  return createTextField({
    store,
    label: 'Loan Id',
    fieldName: 'loanId',
    testId: 'loanId',
    disabled: true,
  });
});

export const StreetNumber = field(({store}: DenialOfCreditField) => {
  return createTextField({
    store,
    label: 'Street number',
    fieldName: 'streetNumber',
    testId: 'streetNumber',
  });
});

export const Street = field(({store}: DenialOfCreditField) => {
  return createTextField({
    store,
    label: 'Street',
    fieldName: 'street',
    testId: 'street',
  });
});

export const City = field(({store}: DenialOfCreditField) => {
  return createTextField({
    store,
    label: 'City',
    fieldName: 'city',
    testId: 'city',
  });
});

export const ZipCode = field(({store}: DenialOfCreditField) => {
  return createTextField({
    store,
    label: 'Zip code',
    fieldName: 'zipCode',
    testId: 'zipCode',
  });
});

export const Email = field(({store}: DenialOfCreditField) => {
  return createTextField({
    store,
    label: 'Email',
    fieldName: 'email',
    type:'email',
    testId: 'email',
  });
});

export const Rationale = field(({store}: DenialOfCreditField) => {
  return createTextField({
    store,
    label: 'Further information',
    fieldName: 'rationale',
    testId: 'rationale',
  });
});