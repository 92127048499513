import { ConfirmationButton, StandardDialog, Button } from '@roc/ui';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { EditLoansStore } from '../stores/editLoansStore';
import { Alert, AlertTitle } from '@material-ui/lab';
import { createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) =>
  createStyles({
    text: {
      marginBottom: 32,
    },
  })
);

export interface MoveLoanStatusButtonProps {
  store: EditLoansStore;
  refreshLoan: () => void;
  loan: any,
}

export const MoveToPreSubmissionStatusButton = observer(({ store, loan, refreshLoan }: MoveLoanStatusButtonProps) => {
  useEffect(() => {
    store.loadStore(loan);
    return () => {
      store.reset();
    };
  }, []);

  return (
    <ConfirmationButton
      variant="contained"
      size="small"
      color="primary"
      onClick={() => {
        store.changeStatusToPreSubmission(loan?.loanId, refreshLoan);
      }}
      testId={`move-to-pre-submission-loan-button`}
      confirmDialogProps={{
        body: 'Are you sure you want to move this loan to Pre-Submission?',
      }}
    >
      Move to Pre-Submission
    </ConfirmationButton>
  );
});

export const MoveToPendingStatusButton = observer(({ store, loan, refreshLoan }: MoveLoanStatusButtonProps) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    return () => {
      store.reset();
    };
  }, []);

  const getModalBody = () => {
    if (store.allErrors.length > 0) {
      return (
        <>
          <Typography className={classes.text}>Please resolve the following errors in order to resubmit this loan:</Typography>
          <Grid container direction='row' style={{ marginBottom: '8px' }}>
            <Grid item xs={12}>
              <Alert severity="error">
                <AlertTitle>ERRORS</AlertTitle>
                {store.allErrors.map(error => (
                  <Grid key={error}>{error}</Grid>
                ))}
              </Alert>
            </Grid>
          </Grid>
        </>
      )
    } else {
      return (
        <>
          <Typography>Are you sure you want to resubmit this loan?</Typography>
        </>
      );
    }
  };

  return (
    <>
      <StandardDialog
        open={dialogOpen}
        title="Resubmit Loan"
        maxWidth="sm"
        handleClose={() => setDialogOpen(false)}
        dialogContent={getModalBody()}
        dialogActions={store.allErrors.length === 0 ?
          (
            <>
              <Button
                style={{ marginRight: '8px' }}
                variant="outlined"
                color="primary"
                onClick={() => { setDialogOpen(false) }}
                testId="cancelButton"
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setDialogOpen(false);
                  store.recalculateLoanStatus(refreshLoan);
                }}
                testId="confirmButton"
              >
                Confirm
              </Button>
            </>
          ) : null
        }
      />
      <Button
        variant="contained"
        size="small"
        onClick={() => {
          store.loadStore(loan);
          store.validateBridgeSubmission();
          setDialogOpen(true);
        }}
        color="primary"
        testId={`move-to-pending-submission-loan-button`}
      >
        Resubmit Loan
      </Button>
    </>
  )
});