import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
} from '@material-ui/core';
import {
  Theme,
  makeStyles,
} from '@material-ui/core/styles';
import {
  withConfirmDialog,
  WithConfirmDialogProps,
  withInformationDialog,
  withSubmitDialog,
} from '../dialog';
import { withTestDataAttribute } from '../testDataAttribute';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => ({
  unsetTextTransfrom: {
    textTransform: 'unset',
  },
  unsetLetterSpacing: {
    letterSpacing: 'unset',
  },
}));

export type ButtonProps = MuiButtonProps & {
  testId: string;
  unsetTextTransfrom?: boolean;
  unsetLetterSpacing?: boolean;
};

export type ConfirmationButtonProps = ButtonProps & WithConfirmDialogProps;

export const Button = withTestDataAttribute<ButtonProps>(props => {
  const classes = useStyles();
  const { unsetTextTransfrom, unsetLetterSpacing, className, ...rest } = props;
  return <MuiButton className={clsx(unsetTextTransfrom ? classes.unsetTextTransfrom : undefined, unsetLetterSpacing ? classes.unsetLetterSpacing : undefined, className)} {...rest} />;
});

export const ConfirmationButton = withConfirmDialog<ButtonProps>(props => {
  return <Button {...props} />;
});

export const InformationButton = withInformationDialog<ButtonProps>(props => {
  return <Button {...props} />;
});

export const SubmitButton = withSubmitDialog<ButtonProps>(props => {
  return <Button {...props} />;
});
