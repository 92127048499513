import { action, flow, makeObservable, observable } from 'mobx';
import { ApiResponse } from '../services';
import { GlobalStore } from '@roc/feature-app-core';
import { AuthenticationService } from '../services/authenticationService';
import { appLocalStorage, isElectronApp, isLocalHost, isMobileApp, LocalStorageKeys } from '@roc/feature-utils';
import { EnvironmentStore } from './environmentStore';
import ifVisible from 'ifvisible.js'
import { addSeconds, differenceInMilliseconds, differenceInSeconds, isAfter } from 'date-fns';
import { OAuthResponse } from '@roc/feature-types';

export class AuthenticationStore {
  globalStore: GlobalStore;
  environmentStore: EnvironmentStore;
  authenticationService: AuthenticationService;

  loginUrl: string;
  isAuthenticated: boolean = undefined;
  twoFactorRequired: boolean = undefined;
  refreshTokenTimeoutTimer: ReturnType<typeof setTimeout> | undefined;
  refreshTokenScheduledTimer: ReturnType<typeof setInterval> | undefined;
  jwtToken = '';
  jwtTokenExpiresAt: Date;

  private _jwtTokenPromiseId: Promise<string> = undefined;

  constructor(globalStore: GlobalStore, environmentStore: EnvironmentStore) {
    this.globalStore = globalStore;
    this.environmentStore = environmentStore;
    this.authenticationService = new AuthenticationService();
    makeObservable(this, {
      loginUrl: observable,
      isAuthenticated: observable,
      twoFactorRequired: observable,
      jwtToken: observable,
      setLoginUrl: action,
      checkAuthentiation: flow,
      verifyTwoFactor: action,
      doPostSuccessfulAuthentication: flow,
      setAuthenticated: flow,
      setTwoFactorRequired: action,
      refreshToken: action,
      getJwtToken: action,
    });
  }

  setLoginUrl(loginUrl) {
    this.loginUrl = loginUrl;
  }

  *setAuthenticated(value) {
    this.isAuthenticated = value;
  }

  setTwoFactorRequired(value) {
    this.twoFactorRequired = value;
  }

  *checkAuthentiation(successCallback = () => { }, failureCallback = () => { }) {
    try {
      // For local development,
      // use jwt from environment variables in case of localhost
      if (isLocalHost() && this.environmentStore.environmentVariables.jwt) {

        this.jwtToken = this.environmentStore.environmentVariables.jwt;
        this.jwtTokenExpiresAt = addSeconds(new Date(), 604800); // 7 days
        this.isAuthenticated = true;
        this.twoFactorRequired = false;
        successCallback && successCallback();
      }
      else {
        const refreshTokenResponse: ApiResponse = yield this.authenticationService.refreshToken(false);
        const oauthResponse = refreshTokenResponse.data as OAuthResponse;
        this.doPostSuccessfulAuthentication(oauthResponse);
        if (oauthResponse.two_factor_authorized) {
          this.isAuthenticated = true;
          this.twoFactorRequired = false;
          successCallback && successCallback();
        } else {
          this.twoFactorRequired = true;
        }
      }
    } catch (e) {
      this.isAuthenticated = false;
      failureCallback();
    }
  }

  async verifyTwoFactor(code) {
    try {
      const refreshTokenResponse: ApiResponse = await this.authenticationService.verifyTwoFactor(code);
      const oauthResponse = refreshTokenResponse.data as OAuthResponse;
      this.doPostSuccessfulAuthentication(oauthResponse);
      this.isAuthenticated = true;
      this.twoFactorRequired = false;
      return true;
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Invalid Code'
      });
      throw e;
    }
  }

  *doPostSuccessfulAuthentication(oauthResponse: OAuthResponse) {
    this.globalStore.setRefreshTokenExpired(false);
    this.jwtToken = oauthResponse.access_token;
    const timeFactor = 0.8;
    this.jwtTokenExpiresAt = addSeconds(new Date(), oauthResponse.expires_in * timeFactor);
    this.globalStore.setJwtTokenInactivePeriod(
      oauthResponse.token_inactive_period
    );
    //this.refreshTokenScheduledTimer = this.startRecurringAccessTokenRefreshTask(oauthResponse.expires_in);
    this.refreshTokenTimeoutTimer = this.trackRefreshTokenTimeout(oauthResponse.refresh_token_validity_period);
  }

  async refreshToken(disableGlobalLoading = true): Promise<string> {
    try {
      const refreshTokenResponse: ApiResponse = await this.authenticationService.refreshToken(disableGlobalLoading);
      const oauthResponse = refreshTokenResponse.data as OAuthResponse;
      this.doPostSuccessfulAuthentication(oauthResponse);
      return oauthResponse.access_token;
    } catch (e) {
      return null;
    }
  };

  /*private startRecurringAccessTokenRefreshTask(tokenValidityInSeconds: number) {
    const timeFactor = 0.8;
    const _refreshTokenIntervalInSeconds = tokenValidityInSeconds * timeFactor;
    let isLoading = false;
    clearInterval(this.refreshTokenScheduledTimer);
    return setInterval(async () => {
      if (!isLoading) {
        isLoading = true
        await this.refreshToken(true);
        isLoading = false
      }
    }, _refreshTokenIntervalInSeconds * 1000);
  }*/

  async getJwtToken(forceRefresh = false): Promise<string> {
    if (this.jwtTokenExpiresAt || forceRefresh) {
      const isExpired = isAfter(new Date(), this.jwtTokenExpiresAt);
      if (isExpired || forceRefresh) {
        if (!this._jwtTokenPromiseId) {
          this._jwtTokenPromiseId = this.refreshToken(false);
        }
        await this._jwtTokenPromiseId;
        this._jwtTokenPromiseId = undefined;
      }
    }
    return this.jwtToken;
  }

  private trackRefreshTokenTimeout(refreshTokenValidityPeriod: number) {
    const refreshTokenExpiresAt = addSeconds(new Date(), refreshTokenValidityPeriod);
    clearTimeout(this.refreshTokenTimeoutTimer);
    const _expireAt = differenceInMilliseconds(new Date(refreshTokenExpiresAt), new Date());
    console.log("Refresh token will expire in: " + (_expireAt / (60 * 60 * 1000)) + " hours");
    return setTimeout(() => {
      this.globalStore.setRefreshTokenExpired(true);
      console.log('Refresh Token Expired.');
    }, _expireAt);
  }
};
