import { Grid, Typography } from '@material-ui/core';
import { AlertDialog } from '@roc/ui';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useStore } from '../../../hooks/useStore';
import { createCheckboxField } from '../../../components/formComponents';

export const CurrentNotification = observer(() => {
  const { globalStore, currentNotificationStore } = useStore();
  const { open } = currentNotificationStore;
  const { routeBaseUrl } = globalStore;
  const messages = globalStore?.alertMessages;

  useEffect(() => {
    currentNotificationStore.getUserNotificationPreferences();
  }, []);

  const handleClose = () => {
    currentNotificationStore.checkAndSaveUserNotificationPreferences();
  };

  let messageComponents = [];
  messageComponents = messages && messages.map(message => {
    const content: string = message.content.replace(':portal-base-url', routeBaseUrl);
    return (
      <Grid item xs={12}>
        <Typography gutterBottom>
          <strong>
            {message.title}
          </strong>
          <br />
          <div dangerouslySetInnerHTML={{ __html: content }}></div>
        </Typography>
        <br />
      </Grid>
    );
  });

  return (
    <>
      {globalStore.userFeatures?.showCurrentNotification && messageComponents?.length > 0 && (
        <AlertDialog
          open={open}
          title="Notice"
          body={
            <Grid item xs={12}>
              {messageComponents}
              <br />
              <Grid item xs={12}>
                {createCheckboxField({
                  label: "Don't show me this message again",
                  fieldName: 'currentNotification',
                  store: currentNotificationStore,
                })}
              </Grid>
            </Grid>
          }
          handleClose={handleClose}
        />
      )}
    </>
  );
});
