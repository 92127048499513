import { Service } from '@roc/feature-app-core';
import { debounceRequest } from '@roc/feature-utils';
import { CancelToken } from 'axios';

const url = {
  GET_QUOTES_GRID: '/api/v1/loan/oneTool/getMyQuotesGrid',
  GET_QUOTE_BY_ID: '/api/v1/loan/oneTool/getQuoteById',
  SAVE_QUOTE: '/api/v1/loan/oneTool/saveQuote',
  SAVE_PUBLIC_QUOTE: '/api/v1/loan/public/oneTool/savePublicQuote',
  DELETE_QUOTE: '/api/v1/loan/oneTool/removeQuote',
  FIND_BORROWERS_BY_NAME: '/api/v1/loan/oneTool/findBorrowersByName',
  FIND_BORROWERS_AND_LEADS_BY_NAME:
    '/api/v1/loan/oneTool/findBorrowersAndLeadsByName',
  GET_BORROWER_BY_ID: '/api/v1/loan/borrower/getBorrowerById',
  GET_LEAD_BY_ID: '/api/v1/lender/lead/getLeadByIdForUser',
  CALCULATE_INSTA_QUOTE_RANGES:
    '/api/v1/validation/oneTool/quote/calculateInstaQuoteRanges',
  CALCULATE_FLOOR_RATE: '/api/v1/validation/oneTool/quote/calculateFloorRate',
  CALCULATE_QUOTE:
    '/api/v1/validation/oneTool/quote/calculateBridgeQuoteDetails',
  GET_DEFAULT_FEES: '/api/v1/loan/companyprofile/getDefaultFeesTable',
  GET_DRAFT_LOAN_FROM_QUOTE: '/api/v1/loan/oneTool/getDraftLoanFromQuote',
  SEND_QUOTE_TO_BORROWER: '/api/v1/loan/oneTool/sendQuoteToBorrower',
  GET_LENDER_ATTORNEYS_BY_STATE: '/api/v1/loan/getAllLenderAttorneysByState',
  GET_LENDER_BROKER_DROPDOWN_DATA_LIST:
    '/api/v1/loan/oneTool/getBrokersByLenderDropdownData',
  GET_BROKER_BY_ID: '/api/v1/loan/oneTool/getBrokerById',
  GET_ENTITY_DROPDOWN_DATA_LIST: '/api/v1/loan/oneTool/getEntitiesDropdownData',
  GET_ENTITY_BY_ID: '/api/v1/loan/oneTool/getEntityById',
  SUBMIT_LOAN: '/api/v1/loan/oneTool/submitLoan',
  GET_SUBMIT_BRIDGE_LOAN_REQUEST: '/api/v1/loan/oneTool/getSubmitBridgeLoanRequest',
  LOAN_ORIGINATORS: '/api/v1/loan/lenderOriginator/getLenderOriginators',
  GET_LOAN_PARTICIPANTS: '/api/v1/loan/lenderUsers/getLoanParticipants',
  VALIDATE_BRIDGE_LOAN: '/api/v1/validation/validateBridgeLoan',
  VALIDATE_BRIDGE_LOAN_STEP: '/api/v1/validation/validateStepBridgeLoan',
  GET_BORROWER_POINTS_OPTIONS: '/api/v1/pricer/bridgePricer/getBorrowerPointsValues',
  SEND_ERROR_EMAIL: '/api/v1/user/sendErrorEmail',

  // Fast track
  GET_LOAN_FAST_TRACK_FEES: '/api/v1/loan/fastTrackFees',
  FETCH_FAST_TRACK_LOAN_ID: '/api/v1/loan/fetchDraftLoanIdForFastrackPayment',
  GET_FAST_TRACK_PAYMENT_URL: '/api/v1/loan/loanFastTrackPaymentUrl',
  UPDATE_LOAN_FAST_TRACK_PAYMENTS: '/api/v1/document/formDocument/updateLoanFastrackInfoState',
  REQUEST_FAST_TRACK_PAYMENT: '/api/v1/loan/requestFastrackPayment',
  VALIDATE_BORROWERS: '/api/v1/validation/validateBorrowers',
  VALIDATE_ENTITY: '/api/v1/validation/validateEntity',
  GET_ARE_BORROWERS_PRE_QUALIFIED: '/api/v1/validation/getAreBorrowersPreQualifiedFromPrescreenValidations',
};

export class OneToolService extends Service {
  getMyQuotesGrid(
    page: number,
    pageSize: number,
    sortDir: string,
    sortBy: string,
    filters: string
  ): Promise<any> {
    return this.get(
      url.GET_QUOTES_GRID,
      {
        page,
        pageSize,
        sortDir,
        sortBy,
        filters,
      },
      {
        disableGlobalLoading: true,
      }
    );
  }

  getQuoteById(quoteId: number) {
    return this.get(url.GET_QUOTE_BY_ID, { quoteId });
  }

  getLenderBrokerData(cancelToken?) {
    return this.get(
      url.GET_LENDER_BROKER_DROPDOWN_DATA_LIST,
      {},
      { cancelToken }
    );
  }

  getBrokerById(brokerId: number) {
    return this.get(url.GET_BROKER_BY_ID, { brokerId });
  }

  getEntityDropdownData(cancelToken?) {
    return this.get(url.GET_ENTITY_DROPDOWN_DATA_LIST, {}, { cancelToken });
  }

  getEntityById(entityId: number) {
    return this.get(url.GET_ENTITY_BY_ID, { entityId });
  }

  saveQuote(quoteData: any) {
    return this.post(url.SAVE_QUOTE, quoteData);
  }

  savePublicQuote(quoteData: any, username: string) {
    return this.post(`${url.SAVE_PUBLIC_QUOTE}?username=${username}`, quoteData);
  }

  deleteQuote(quoteId: number) {
    return this.delete(url.DELETE_QUOTE, { quoteId });
  }

  getLoanParticipants() {
    return this.get(url.GET_LOAN_PARTICIPANTS);
  }

  getOriginators() {
    return this.get(url.LOAN_ORIGINATORS);
  }

  findBorrowersByName(name: string, cancelToken?) {
    return this.get(
      url.FIND_BORROWERS_BY_NAME,
      { name },
      { disableGlobalLoading: true, cancelToken }
    );
  }

  findBorrowersAndLeadsByName(name: string, cancelToken?) {
    return this.get(
      url.FIND_BORROWERS_AND_LEADS_BY_NAME,
      { name },
      { disableGlobalLoading: true, cancelToken }
    );
  }

  fetchBorrowerData(borrowerId) {
    return this.get(url.GET_BORROWER_BY_ID, { borrowerId });
  }

  fetchLeadData(leadId, businessUnit) {
    return this.get(url.GET_LEAD_BY_ID, { leadId, businessUnit });
  }

  calculateInstaQuoteRanges(quoteData) {
    return this.post(url.CALCULATE_INSTA_QUOTE_RANGES, quoteData);
  }

  calculateFloorRate(quoteData, lenderId) {
    return this.post(
      `${url.CALCULATE_FLOOR_RATE}?lenderId=${lenderId}`,
      quoteData
    );
  }

  calculateBorrowerPointsOptions(lenderId, loanTerm, submissionDate) {
    return this.get(url.GET_BORROWER_POINTS_OPTIONS, { lenderId, loanTerm, submissionDate });
  }

  calculateQuote(quoteData: any, cancelToken?: CancelToken) {
    return this.post(url.CALCULATE_QUOTE, quoteData, null, {
      cancelToken,
    }).then(response => response.data);
  }

  getDefaultFees() {
    return this.get(url.GET_DEFAULT_FEES);
  }

  getDraftLoanFromQuote(quoteId) {
    return this.get(url.GET_DRAFT_LOAN_FROM_QUOTE, { quoteId });
  }

  sendQuoteToBorrower(file, data, portalId) {
    const formData = new FormData();
    formData.append('portalId', portalId);
    formData.append('attachments', file);
    formData.append('borrowerEmail', data.borrowerEmail);
    formData.append('subject', data.emailSubject);
    formData.append('content', data.emailContent);

    return this.post(url.SEND_QUOTE_TO_BORROWER, formData);
  }
  getLenderAttorneysByState(
    state,
    isInternalClosingTeamOptionEnabled,
    loanAmount
  ) {
    return this.get(url.GET_LENDER_ATTORNEYS_BY_STATE, {
      isInternalClosingTeamOptionEnabled,
      state,
      loanAmount,
    });
  }

  submitLoan(quoteData) {
    return this.post(url.SUBMIT_LOAN, quoteData);
  }

  getLoanFastTrackFees() {
    return this.get(url.GET_LOAN_FAST_TRACK_FEES);
  }

  fetchFastTrackLoanId() {
    return this.get(url.FETCH_FAST_TRACK_LOAN_ID);
  }

  getLoanFastTrackPaymentUrl(loanId: string | number) {
    return this.get(url.GET_FAST_TRACK_PAYMENT_URL, { loanId });
  }

  updateLoanFastrackInfo(fastTrackOptions: any) {
    return this.put(url.UPDATE_LOAN_FAST_TRACK_PAYMENTS, fastTrackOptions);
  }

  requestFastTrackPaymentEmail(fastTrackInfo: any) {
    return this.post(url.REQUEST_FAST_TRACK_PAYMENT, fastTrackInfo);
  }

  getSubmitBridgeLoanRequest(quoteData) {
    return this.post(url.GET_SUBMIT_BRIDGE_LOAN_REQUEST, quoteData);
  }

  getValidateBridgeLoanErrors(loan: any, skipLeverageValidations: boolean) {
    const skipLeverageValidationsParam = skipLeverageValidations ? `?skipLeverageValidations=${skipLeverageValidations}` : '';
    return this.post(`${url.VALIDATE_BRIDGE_LOAN}${skipLeverageValidationsParam}`, loan);
  }

  validateBridgeLoanStep(loan: any, step: string, skipLeverageValidations: boolean) {
    const skipLeverageValidationsParam = skipLeverageValidations ? `&skipLeverageValidations=${skipLeverageValidations}` : '';
    return this.post(`${url.VALIDATE_BRIDGE_LOAN_STEP}?step=${step}${skipLeverageValidationsParam}`, loan);
  }

  getValidateBorrowerLoanErrors(data) {
    return this.post(url.VALIDATE_BORROWERS, data);
  }

  getValidateEntityLoanErrors(entityId: number) {
    const endpointUrl = `${url.VALIDATE_ENTITY}?entityId=${entityId}`
    return this.post(endpointUrl, {});
  }

  getAreBorrowersPreQualifiedFromPrescreenValidations(data: any) {
    return this.post(url.GET_ARE_BORROWERS_PRE_QUALIFIED, data);
  }

  sendErrorEmail(subject: string, error: string, details: string) {
    const body = {
      subject,
      body: `BrowserInfo: ${navigator.userAgent} Error message: ${error} \n Details: ${details}`,
    };
    return this.post(url.SEND_ERROR_EMAIL, body);
  }
}
