import { GlobalStore } from '@roc/feature-app-core';
import { action, computed, flow, makeObservable, observable } from 'mobx';
import { brokerFeeNames } from '../constants/brokerConstants';
import { BrokerService } from '../services/brokerService';
import { BrokersStore } from './brokersStore';
import { DialogState } from '@roc/ui';

export class BrokerFeesStore {
  private globalStore: GlobalStore;
  private brokersStore: BrokersStore;
  private brokerService: BrokerService;

  brokerFees;

  constructor(brokerStore: BrokersStore, globalStore: GlobalStore) {
    this.brokersStore = brokerStore;
    this.globalStore = globalStore;
    this.brokerService = brokerStore.brokerService;

    makeObservable(this, {
      brokerFees: observable,
      fetchBrokerFees: flow,
      saveBrokerFees: flow,
      reset: action,
      onBridgeFeeChange: action,
      onTermFeeChange: action,
    });
  }

  *fetchBrokerFees() {
    try {
      this.reset();
      const brokerFields = this.brokersStore.manageBrokerFormStore.form.fields;
      const lenderId = brokerFields.lenderId.value;
      const brokerId = brokerFields.brokerId.value;
      const response = yield this.brokerService.getDefaulBrokerEconomicsTable(
        lenderId,
        brokerId
      );
      this.brokerFees = JSON.parse(response.data.data);
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching the broker fees',
      });
    }
  }

  reset() {
    this.brokerFees = null;
  }

  onBridgeFeeChange(attribute, val) {
    this.brokerFees.bridgeFees[attribute] = val;
  }

  onTermFeeChange(attribute, val) {
    this.brokerFees.termFees[attribute] = val;
  }

  *saveBrokerFees() {
    try {
      if (this.brokerFees) {
        const data = [this.brokerFees.bridgeFees, this.brokerFees.termFees];
        yield this.brokerService.saveBrokerEconomics(data);

        this.globalStore.notificationStore.showSuccessNotification({
          message: 'Broker fees saved successfully',
        });

        this.brokersStore.selectBrokersStore.closeEditBrokerDialog();
      }
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while saving the broker fees',
      });
    }
  }
}
