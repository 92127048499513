import { Button, Paper, Box, Grid, IconButton, Typography } from '@material-ui/core';
import { useParams } from 'react-router';
import { observer } from 'mobx-react';
import { useForeclosureSummaryStore } from '../hooks/useForeclosureSummaryStore';
import { useEffect, useState } from 'react';
import { valuationTypeOptions, dqStatusOptions } from './foreclosureOverview/foreclosureOverviewUtils';
import { StandardDialog, SelectField, CurrencyField, DataGrid, TextField, DateField, Toolbar } from '@roc/ui';
import { valuationReviewGridColumns } from './foreclosureOverview/foreclosureOverviewUtils';
import { Comment } from '@material-ui/icons';
import { formatCurrency } from '@roc/ui/utils';
import { formatDate } from '@roc/client-portal-shared/utils';
import { Layout } from '@roc/feature-app-core';

export const LoanValuations = observer(() => {
  const { foreclosureSummaryStore } = useForeclosureSummaryStore();
  const { loanValuationStore } = foreclosureSummaryStore;
  const { valuationGridStore } = loanValuationStore
  const { loanId } = useParams<{ loanId: string }>();
  const { fields } = loanValuationStore.form;
  const [newValuationModalOpen, setNewValuationModalOpen] = useState(false);
  const [selectedValuation, setSelectedValuation] = useState(null);
  const [commentModalOpen, setCommentModalOpen] = useState(null);

  const frameworkComponents = {
    commentCellRenderer: params => {
      return <IconButton color='primary' onClick={() => {
        setSelectedValuation(params.node.data);
        setCommentModalOpen(true);
      }}>
        <Comment />
      </IconButton>
    },
    currencyCellRenderer: params => (
      <Box textAlign="right">{formatCurrency(params.value)}</Box>
    ),
    typeCellRenderer: params => {
      return <>{valuationTypeOptions.find(type => type.value == params.value)?.label}</>
    },
    dateCellRenderer: params => (
      <Box textAlign="right">{formatDate(params.value, 'MM/dd/yyyy')}</Box>
    )
  };

  const onFilterChanged = params => {
    const filterModel = params.api.getFilterModel();
    valuationGridStore.setFilterModel(filterModel);
  };

  const onSortChanged = params => {
    const sortModel = params.api.getSortModel();
    valuationGridStore.setSortModel(sortModel);
  };

  const afterSave = () => {
    valuationGridStore.resetAndFetchGridData();
    loanValuationStore.reset();
    setNewValuationModalOpen(false);
    setCommentModalOpen(false);
  }

  useEffect(() => {
    if (!loanValuationStore.loanDetails) loanValuationStore.getLoanDetails(loanId);
    valuationGridStore.resetAndFetchGridData();
  }, [loanValuationStore.loanDetails]);

  return (
    <>
      <Paper style={{ width: '100%', padding: '30px' }}>
        <Layout title={loanValuationStore.loanDetails?.dealName}>
          <Typography variant='h4'>Property Valuations</Typography>
          <DataGrid
            frameworkComponents={frameworkComponents}
            toolbar={
              <Toolbar>
                <Button color='primary' size='small' variant='contained' onClick={() => setNewValuationModalOpen(true)}>
                  Add Valuation
                </Button>
              </Toolbar>}
            columns={valuationReviewGridColumns}
            rows={valuationGridStore.gridData.data.rows}
            onSortChanged={onSortChanged}
            onFilterChanged={onFilterChanged}
            isLoading={valuationGridStore.isLoading}
            showFilters={valuationGridStore.showFilters}
            sortModel={valuationGridStore.sortModel}
            filterModel={valuationGridStore.filterModel}
            paginationData={valuationGridStore.gridData.meta}
            setPageNumber={valuationGridStore.setPageNumber}
            rowHeight={60}
            domLayout='autoHeight'
          />
        </Layout>
      </Paper>
      <StandardDialog
        open={newValuationModalOpen}
        title="New Valuation"
        maxWidth="md"
        handleClose={() => setNewValuationModalOpen(false)}
        dialogContent={
          <Grid container spacing={2}>
            {loanValuationStore.loanDetails && <Grid item xs={12}>
              <SelectField standaloneLabel variant='outlined' required fullWidth label={'Property Address'} testId={'type'} options={loanValuationStore.getPropertyAddressOptions} onChange={value => loanValuationStore.onFieldChange('propertyId', value)} value={fields.propertyId.value} />
            </Grid>}
            <Grid item xs={6}>
              <SelectField standaloneLabel variant='outlined' required fullWidth label={'Valuation Type'} testId={'type'} options={valuationTypeOptions}
                onChange={value => loanValuationStore.onFieldChange('type', value)} value={fields.type.value}
              />
            </Grid>
            <Grid item xs={6}>
              <SelectField standaloneLabel variant='outlined' fullWidth label={'Loan DQ Status'} testId={'loanDqStatus'} options={dqStatusOptions}
                onChange={value => loanValuationStore.onFieldChange('loanDqStatus', value)} value={fields.loanDqStatus.value} />
            </Grid>
            <Grid item xs={6}>
              <CurrencyField standaloneLabel variant='outlined' fullWidth testId={'arv'} label='ARV' onChange={(name, value) => loanValuationStore.onFieldChange('afterRepairValue', Number(value))} value={fields.afterRepairValue.value} />
            </Grid>
            <Grid item xs={6}>
              <CurrencyField standaloneLabel variant='outlined' fullWidth testId={'asIs'} label='AS-IS value' onChange={(name, value) => loanValuationStore.onFieldChange('asIsValue', Number(value))} value={fields.asIsValue.value} />
            </Grid>
            <Grid item xs={6}>
              <DateField format='MM/dd/yyyy' required standaloneLabel inputVariant='outlined' fullWidth label={'Date'} testId={'dqStatus'} onChange={(value) => loanValuationStore.onFieldChange('date', value)} value={fields.date.value} />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant='outlined'
                standaloneLabel
                fullWidth
                multiline
                minRows={2}
                maxRows={4}
                label='Comments'
                testId={'comments'}
                value={fields.comments.value}
                onChange={(e) => loanValuationStore.onFieldChange('comments', e.target.value)}
              />
            </Grid>
          </Grid>
        }
        dialogActions={
          <Box>
            <Button variant='outlined' color='primary' style={{ marginTop: 3, margin: 'auto 5px' }} onClick={() => setNewValuationModalOpen(false)}>Cancel</Button>
            <Button variant='contained' color='primary' style={{ marginTop: 3, margin: 'auto 5px' }} onClick={() => loanValuationStore.saveValuationForm(afterSave)}>Save</Button>
          </Box>
        }
      />
      <StandardDialog
        open={commentModalOpen}
        title="Valuation Comments"
        maxWidth="md"
        handleClose={() => setCommentModalOpen(false)}
        dialogContent={
          <Grid container spacing={2}>
            <TextField
              fullWidth
              variant="outlined"
              multiline
              minRows={4}
              maxRows={20}
              testId={'smt'}
              value={selectedValuation?.comments}
              onChange={(e) => setSelectedValuation(prev => ({ ...prev, comments: e.target.value }))}
            />
          </Grid>
        }
        dialogActions={
          <Box>
            <Button variant='outlined' color='primary' style={{ marginTop: 3, margin: 'auto 5px' }} onClick={() => setCommentModalOpen(false)}>Cancel</Button>
            <Button variant='contained' color='primary' style={{ marginTop: 3, margin: 'auto 5px' }} onClick={() => loanValuationStore.saveValuation(selectedValuation, afterSave)}>Save</Button>
          </Box>
        }
      />
    </>
  );
});