import { useStore } from "apps/client-portal-public/src/app/hooks";
import { useEffect, useState } from "react";
import { IframeHolder } from "@roc/client-portal-shared/components";
import { VerticalBarLoader } from "@roc/ui";
import { observer } from "mobx-react";

export const ReferralFeeDcousign = observer(() => {
  const [] = useState();
  const { referralAuthStore } = useStore();

  useEffect(() => {
    referralAuthStore.getDocusign();

  }, []);

  useEffect(() => {
    window.addEventListener('message', handleWindowMessage);
    return () => window.removeEventListener('message', handleWindowMessage);
  }, []);

  const handleWindowMessage = (event: MessageEvent) => {
    if (event.data == 'referral-docusign-success') {
      referralAuthStore.isSigned = true;
      return;
    }
  };

  return (
    <>
      {referralAuthStore.docusignLink ? (
        <IframeHolder src={referralAuthStore.docusignLink} width='100%' height='100%' id={'referralFee'} />
      ) : (
        <VerticalBarLoader />
      )}
    </>
  );
})