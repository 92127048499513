import { computed, flow, makeObservable, observable } from 'mobx';
import { GlobalStore } from '@roc/feature-app-core';
import { DocumentStore } from '@roc/feature-documents';
import { LoanStore } from '@roc/feature-loans';
import { FormStore } from '@roc/feature-app-core';
import { Document } from '@roc/feature-types';
import { LenderFeesService } from '../../services/documentForms/lenderFeesService';
import { shouldBeAbleToSubmitSpecificForm } from '../../utils/documentForms';
import { DocumentName } from '@roc/feature-utils';
const form = {
  fields: {
    servicingSetupFee: {
      value: '',
      error: null,
      rule: '',
    },
    wireFee: {
      value: '',
      error: null,
      rule: '',
    },
    underwritingFee: {
      value: '',
      error: null,
      rule: '',
    },
    legalReviewFee: {
      value: '',
      error: null,
      rule: '',
    },
    valuationReviewFee: {
      value: '',
      error: null,
      rule: '',
    },
    processingFee: {
      value: '',
      error: null,
      rule: '',
    },
    adminFee: {
      value: '',
      error: null,
      rule: '',
    },
    commitmentFee: {
      value: '',
      error: null,
      rule: '',
    },
    reimbursements: {
      value: '',
      error: null,
      rule: '',
    },
    others: {
      value: '',
      error: null,
      rule: '',
    },
    others1: {
      value: '',
      error: null,
      rule: '',
    },
    lenderBuydownFee: {
      value: '',
      error: null,
      rule: '',
    },
  },
  meta: {
    isValid: true,
    error: null,
  },
};

export class LenderFeesStore extends FormStore {
  loanStore: LoanStore;
  documentStore: DocumentStore;
  globalStore: GlobalStore;

  lenderFeesService: LenderFeesService;

  constructor(
    loanStore: LoanStore,
    documentStore: DocumentStore,
    globalStore: GlobalStore
  ) {
    super(form, globalStore);
    this.loanStore = loanStore;
    this.documentStore = documentStore;
    this.globalStore = globalStore;
    this.lenderFeesService = new LenderFeesService();
    makeObservable(this, {
      canEdit: computed,
      canSubmit: computed,
      initForm: flow,
      submit: flow,
    });
  }

  *initForm() {
    if(this.loanStore?.loanDetails?.loanType === 'Residential Term' && this.globalStore?.lenderInfo?.isInternal) {
      const retailFees = this.loanStore.loanDetails.loanRetailFees;
      this.form.fields.adminFee.value = retailFees.retailAdminFee;
      this.form.fields.lenderBuydownFee.value = retailFees.retailBuydownFee;
      this.form.fields.commitmentFee.value = retailFees.retailCommitmentFee;
      this.form.fields.legalReviewFee.value = retailFees.retailLegalReviewFee;
      this.form.fields.processingFee.value = retailFees.retailProcessingFee;
      this.form.fields.underwritingFee.value = retailFees.retailUnderwritingFee;
    } else {
      const loanLenderDetails = this.loanStore.loanDetails.loanLenderDetails;
      this.loadForm(loanLenderDetails);
    }
  }

  *submit(document: Document) {
    this.runFormValidationWithMessage();
    if (this.canSubmit && this.form.meta.isValid) {
      try {
        const loanLenderDetails = this.loanStore.loanDetails.loanLenderDetails;
        const formValues = this.getFormValues();
        yield this.lenderFeesService.updateLoanLenderDetails({
          loanDocument: document,
          loanLenderDetails: {
            ...loanLenderDetails,
            ...formValues,
            loanId: this.loanStore.loanDetails.loanId,
          },
        });
        this.globalStore.notificationStore.showSuccessNotification({
          message: 'Lender fees saved successfully',
        });
        yield this.loanStore.fetchLoanDetails(document.loanId);
      } catch (error) {
        this.globalStore.notificationStore.showErrorNotification({
          message: 'Error while submitting lender fees',
        });
      }
    }
  }

  get canEdit() {
    const entitlements = this.globalStore.userFeatures.closingEntitlements;
    return shouldBeAbleToSubmitSpecificForm(
      entitlements?.isSubmissionAllowed,
      entitlements?.isSubmissionNotAllowedOnly,
      entitlements?.isSubmissionAllowedOnly,
      DocumentName.LENDER_FEES
    );
  }

  get canSubmit() {
    return this.canEdit && this.form.meta.isValid;
  }
}

export default LenderFeesStore;
