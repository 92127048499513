import { Grid, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import { PersonalInformation } from './personalInformation';
import { CoBorrowerInformation } from './coBorrowerInformation';
import { BorrowerInformationSteps } from '../../utils/constants';
import { EditPersonalInformation } from './editPersonalInformation';
import { EditCoBorrowerInformation } from './editCoBorrowerInformation';
import { BorrowerEntityOwnershipInformation } from '../borrowerEntity/borrowerEntityOwnershipInformation';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: '34px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '123.5%',
    letterSpacing: '0.25px',
    paddingBottom: theme.spacing(1)
  }
}));

export const BorrowerInformationStep = observer(({ store, rental = false }) => {
  const { borrowerInformationStore } = store;
  const classes = useStyles();
  return (
    <Grid container direction='row' spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h4" className={classes.title}>
          Borrower Information
        </Typography>
      </Grid>
      {borrowerInformationStore.borrowerInformationActiveStep === BorrowerInformationSteps.PERSONAL_INFORMATION &&
        <Grid item xs={12}>
          <PersonalInformation store={borrowerInformationStore} rental={rental} />
        </Grid>
      }
      {borrowerInformationStore.borrowerInformationActiveStep === BorrowerInformationSteps.CO_BORROWER_INFORMATION &&
        <Grid item xs={12}>
          <CoBorrowerInformation store={borrowerInformationStore} rental={rental} />
        </Grid>
      }
      {borrowerInformationStore.borrowerInformationActiveStep === BorrowerInformationSteps.EDIT_PERSONAL_INFORMATION &&
        <Grid item xs={12}>
          <EditPersonalInformation store={borrowerInformationStore} rental={rental} />
        </Grid>
      }
      {borrowerInformationStore.borrowerInformationActiveStep === BorrowerInformationSteps.EDIT_CO_BORROWER_INFORMATION &&
        <Grid item xs={12}>
          <EditCoBorrowerInformation store={borrowerInformationStore}  rental={rental}  />
        </Grid>
      }
      {borrowerInformationStore.borrowerInformationActiveStep === BorrowerInformationSteps.ENTITY_OWNERSHIP &&
        <Grid item xs={12}>
          <BorrowerEntityOwnershipInformation store={store} hideTitle />
        </Grid>
      }
    </Grid>
  );
});