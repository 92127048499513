import { observer } from 'mobx-react';
import {
  StandardDialog,
} from '@roc/ui';
import { BackgroundItemExternalStore, BackgroundReviewForm } from '@roc/feature-borrower-review';
import { Typography } from '@material-ui/core';
import { useBackgroundReviewExternalStore } from 'libs/feature-borrower-review/src/hooks/useBackgroundReviewExternalStore';
import { Document } from '@roc/feature-types';
import { useUserStore } from '@roc/feature-app-core';


interface BorrowerBackgroundReviewDialogProps {
  open: boolean;
  onClose: () => void;
  loanId: any;
  document: Document;
  loan?: any;
}

export const BorrowerBackgroundReviewDialog = observer(({ open, onClose, loanId, document, loan }: BorrowerBackgroundReviewDialogProps) => {
  const { userStore } = useUserStore();
  const { backgroundReviewStore } = useBackgroundReviewExternalStore();
  const { backgroundItemStore } = backgroundReviewStore;

  backgroundItemStore.currentBorrower = document?.borrowerId;
  backgroundItemStore.loanId = parseInt(loanId);
  backgroundItemStore.isInternal = userStore.isNportalUser;
  backgroundItemStore.loan = loan;
  backgroundItemStore.initializeService();
  if (!document?.borrowerId) {
    return (<></>);
  }


  return (
    <StandardDialog
      open={open}
      maxWidth="md"
      handleClose={onClose}
      title={'Background Review Report'}
      removeContentBox
      dialogContent={
        <BackgroundReviewForm
          store={backgroundItemStore}
          onClose={onClose}
          hideTitle={true}
        />
      }
    />)
});