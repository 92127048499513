import { Box } from '@material-ui/core';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useGeneralTasksStore } from './hooks/useGeneralTasksStore';
import { EditTaskDialog } from './components/editTask/editTaskDialog';
import { makeStyles } from '@material-ui/core';
import { DialogState } from '@roc/ui';
import { SingleTaskView } from './components/singleTaskView';

const useStyles = makeStyles((theme) => ({
  '@global': {
    body: {
      backgroundColor: 'transparent !important',
    },
  },
}));

export const SingleTaskViewInternal = observer(() => {
  const { taskId } = useParams<{ taskId: string }>();
  useStyles();

  if (!taskId) {
    return null;
  }

  return (
    <SingleTaskView taskId={taskId} onClose={() => {
      window.parent.postMessage({ action: 'close-modal' }, '*');
    }} />
  );
});
