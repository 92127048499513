import { useBaseStore } from '@roc/feature-app-core';
import { getLenderExtensionsRoutes, getLenderExtensionsRoutesConfig } from '../routes/lenderExtensionsRoutes';

export const useLenderExtensionsRoutes = () => {
  const { globalStore } = useBaseStore();
  const { routeBasePath, routeBaseUrl } = globalStore;
  return {
    lenderExtensionsRoutes: getLenderExtensionsRoutes(routeBasePath, routeBaseUrl),
    lenderExtensionsRoutesConfig: getLenderExtensionsRoutesConfig(
      routeBasePath,
      routeBaseUrl
    ),
  };
};
