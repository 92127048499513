import { Service } from '@roc/client-portal-shared/services';
import { DenialOfCredit } from '../modules/denialOfCredit/denialOfCreditForm/types/denialOfCreditTypes';

const baseUrl = '/nportal/rest/pub/ecoa';

const url = {
  SEND_DENIAL_OF_CREDIT_REQUEST: `${baseUrl}/denialOfCredit`,
  CHECK_DENIAL_OF_CREDIT: `${baseUrl}/denialOfCreditExist`,
  SUBMIT_DENIAL_OF_CREDIT: `${baseUrl}/denialOfCreditSubmit`
};

export class DenialOfCreditService extends Service {
  sendDenialOfCredit(loanId: string) {
    return this.get(
      url.SEND_DENIAL_OF_CREDIT_REQUEST,
      { loanId }
    );
  }

  submitDenialOfCredit(denialOfCredit: DenialOfCredit){
    return this.post(url.SUBMIT_DENIAL_OF_CREDIT, denialOfCredit);
  }

  checkDenialOfCreditExist(loanId: string){
    return this.get(
      url.CHECK_DENIAL_OF_CREDIT,
      { loanId }
    );
  }

}
