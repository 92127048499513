import Validator from 'validatorjs';
import { PortalContextPath } from './constants';
import * as addresser from 'addresser';
import { PromiseHolder } from '@roc/ui/utils';
import { Borrower } from '@roc/feature-types';
import { stateNormalizerJson } from './constants';
import * as uuid from 'uuid';
import axios, { CancelToken, CancelTokenSource } from 'axios';
import { ApiResponse } from '@roc/feature-app-core';

export enum PortalName {
  LENDER_PORTAL = 'LENDER_PORTAL',
  BORROWER_PORTAL = 'BORROWER_PORTAL',
  ATTORNEY_PORTAL = 'ATTORNEY_PORTAL',
  CLOSING_REVIEWER_PORTAL = 'CLOSING_REVIEWER_PORTAL',
  INSURANCE_REVIEWER_PORTAL = 'INSURANCE_REVIEWER_PORTAL',
  BROKER_PORTAL = 'BROKER_PORTAL',
  MESSAGE_PORTAL = 'MESSAGE_PORTAL',
  SERVICER_PORTAL = 'SERVICER_PORTAL',
  TITLE_AGENT_PORTAL = 'TITLE_AGENT_PORTAL',
  AUCTION_PORTAL = 'AUCTION_PORTAL',
  MOBILE_UI = 'MOBILE_UI',
  CHAT_UI = 'CHAT_UI',
  N_PORTAL = 'N_PORTAL',
  ADMIN_PORTAL = 'ADMIN_PORTAL',
  ASSET_MANAGEMENT_PORTAL = 'ASSET_MANAGEMENT_PORTAL',
  CRM_PORTAL = 'CRM_PORTAL',
}

export type AddressComponents = {
  street_number: string;
  street: string;
  zip: string;
  state: string;
  county: string;
  city: string;
  latitude: number;
  longitude: number;
  aptNumber: string;
};

const getAddressComponents = (): AddressComponents => ({
  street_number: null,
  street: null,
  state: null,
  county: null,
  city: null,
  zip: null,
  latitude: null,
  longitude: null,
  aptNumber: null,
});
export const isProduction = process.env.NODE_ENV === 'production';
export const isMobileApp = () => process.env.NX_IS_MOBILE_APP == 'true';
export const isMobileAppLocalHost = () =>
  process.env.NX_IS_LOCAL_HOST == 'true';
export const isElectronApp = () => process.env.NX_IS_ELECTRON_APP == 'true';
export const isElectronAppLocalHost = () =>
  process.env.NX_IS_ELECTRON_LOCAL_HOST == 'true';
export const isEmptyString = (str: string) =>
  !str || str.length === 0 || str.trim() === '';
export const isLocalHost = () => {
  if (isMobileApp()) {
    return isMobileAppLocalHost();
  }
  if (isElectronApp()) {
    return isElectronAppLocalHost();
  }
  return /localhost/.test(location.hostname);
};
export const isRocLendingUrl = () => /roclending/.test(location.hostname);
export const isHausUrl = () => /hauslending/.test(location.hostname);
export const isFacoUrl = () => /facolending/.test(location.hostname);
export const isCivicUrl = () => /civicfs/.test(location.hostname);
export const isPrivateLenderPortalUrl = () =>
  /privatelenderportal/.test(location.hostname);
export const isBlank = (value: string | boolean) => !value || value == null;
export const isNotBlank = (value: string | boolean) => !isBlank(value);
export const isEmptyObject = (obj: object) => Object.keys(obj).length === 0;
export const insertIf = (condition: boolean, elements: any[]) =>
  condition ? elements : [];

export const getPortalContextPath = (portalName: PortalName) => {
  switch (portalName) {
    case PortalName.N_PORTAL:
      return PortalContextPath.nPortal;
    case PortalName.LENDER_PORTAL:
      return PortalContextPath.lenderPortal;
    case PortalName.BORROWER_PORTAL:
      return PortalContextPath.borrowerPortal;
    case PortalName.BROKER_PORTAL:
      return PortalContextPath.brokerPortal;
    case PortalName.CLOSING_REVIEWER_PORTAL:
      return PortalContextPath.closingReviewerPortal;
    case PortalName.INSURANCE_REVIEWER_PORTAL:
      return PortalContextPath.insuranceReviewerPortal;
    case PortalName.ATTORNEY_PORTAL:
      return PortalContextPath.attorneyPortal;
    case PortalName.MESSAGE_PORTAL:
      return PortalContextPath.messagePortal;
    case PortalName.SERVICER_PORTAL:
      return PortalContextPath.servicerPortal;
    case PortalName.TITLE_AGENT_PORTAL:
      return PortalContextPath.titleAgentPortal;
    case PortalName.AUCTION_PORTAL:
      return PortalContextPath.auctionPortal;
    case PortalName.MOBILE_UI:
      return PortalContextPath.mobileUi;
    case PortalName.CHAT_UI:
      return PortalContextPath.chatUi;
    case PortalName.ADMIN_PORTAL:
      return PortalContextPath.adminPortal;
    case PortalName.ASSET_MANAGEMENT_PORTAL:
      return PortalContextPath.assetManagementPortal;
    case PortalName.CRM_PORTAL:
      return PortalContextPath.crmPortal;
    default:
      throw Error(`PortalContextPath not mapped for PortalName: ${portalName}`);
  }
};
export const isNil = (value: any) => value === null || value === undefined;
export const isNumber = value => typeof value === 'number';

export const validateEmail = (email: string) => {
  const data = { email };
  const rules = { email: 'present|email' };

  return validate(data, rules).passes();
};

export const validate = (data: any, rules: any, config?: any) =>
  new Validator(data, rules, config);

export enum LoanStatus {
  UNKNOWN = 'UNKNOWN',
  PENDING = 'PENDING',
  AGREED = 'AGREED',
  FUNDED = 'FUNDED',
  PAID_OFF = 'PAID_OFF',
  DECLINED = 'DECLINED',
  RETRACTED = 'RETRACTED',
  UNINVESTABLE = 'UNINVESTABLE',
  PAID_OFF_BUYOUT = 'PAID_OFF_BUYOUT',
  TERM_SHEET_OUT = 'TERM_SHEET_OUT',
  IN_UNDERWRITING = 'IN_UNDERWRITING',
  PRESCREEN = 'PRESCREEN',
  PENDING_DECISION = 'PENDING_DECISION',
  PENDING_TERM_SHEET = 'PENDING_TERM_SHEET',
  LOST = 'LOST',
  SUBMITTED_EXT = 'SUBMITTED_EXT',
  REJECTED_EXT = 'REJECTED_EXT',
  ON_HOLD = 'ON_HOLD',
  PRE_SUBMISSION = 'PRE_SUBMISSION',
  PENDING_SUBMISSION = 'PENDING_SUBMISSION',
  APPLICATION_SUBMITTED = 'APPLICATION_SUBMITTED',
  APPLICATION_REJECTED = 'APPLICATION_REJECTED',
}

export const REASON_REQUIRED_STATUSES = [
  LoanStatus.RETRACTED,
  LoanStatus.DECLINED,
  LoanStatus.ON_HOLD,
];

export const proposalStatusProperties = {
  Rejected: {
    color: '#ff1010',
  },
  Completed: {
    name: 'Completed',
    color: '#89C053',
  },
  Pending: {
    name: 'Pending',
    color: '#4B89DA',
  },
  Draft: {
    name: 'Draft',
    color: '#F5B945',
  },
  Undefined: {
    name: 'Undefined',
    color: '',
  },
};

export const statusProperties = {
  FUNDED: {
    name: 'Funded',
    color: '#4B89DA',
  },
  PAID_OFF: {
    name: 'Paid Off',
    color: '#48AD25',
  },
  UNKNOWN: {
    name: 'Unknown',
    color: '#9579DA',
  },
  IN_UNDERWRITING: {
    name: 'In Underwriting',
    color: '#F5B945',
  },
  DECLINED: {
    name: 'Declined',
    color: '#9579DA',
  },
  PENDING: {
    name: 'Pending',
    color: '#F5B945',
  },
  AGREED: {
    name: 'Agreed',
    color: '#89C053',
  },
  RETRACTED: {
    name: 'Retracted',
    color: '#9579DA',
  },
  UNINVESTABLE: {
    name: 'Uninvestable',
    color: '#9579DA',
  },
  PAID_OFF_BUYOUT: {
    name: 'Paid Off Buyout',
    color: '#48AD25',
  },
  TERM_SHEET_OUT: {
    name: 'Term Sheet Out',
    color: '#F5B945',
  },
  PRESCREEN: {
    name: 'Prescreen',
    color: '#F5B945',
  },
  PENDING_DECISION: {
    name: 'Pending Decision',
    color: '#4B89DA',
  },
  PENDING_TERM_SHEET: {
    name: 'Pending Term Sheet',
    color: '#F5B945',
  },
  LOST: {
    name: 'Lost',
    color: '#9579DA',
  },
  SUBMITTED_EXT: {
    name: 'Submitted Ext',
    color: '#9579DA',
  },
  REJECTED_EXT: {
    name: 'Rejected Ext',
    color: '#9579DA',
  },
  ON_HOLD: {
    name: 'On Hold',
    color: '#9579DA',
  },
  PRE_SUBMISSION: {
    name: 'Pre-Submission',
    color: '#F54444',
  },
  PENDING_SUBMISSION: {
    name: 'Pre-Submission',
    color: '#F54444',
  },
  DEAL_ROOM: {
    name: 'Deal Room',
    color: '#F5B945',
  },
  APPLICATION_SUBMITTED: {
    name: 'Submitted',
    color: '#F5B945',
  },
  APPLICATION_REJECTED: {
    name: 'Rejected',
    color: '#9579DA',
  },
};

export const prescreenStatusProperties = {
  PRE_QUALIFIED: {
    name: 'Pre-Qualified',
    color: '#89C053',
  },
  PRE_REVIEW: {
    name: 'Pre-Review',
    color: '#F5B945',
  },
  NOT_APPLICABLE: {
    name: 'Not Applicable',
    color: '#9579DA',
  },
  DEAL_ROOM: {
    name: 'Deal Room',
    color: '#F5B945',
  },
  DEAL_ROOM_APPROVED: {
    name: 'Deal Room Approved',
    color: '#F5B945',
  },
  UNASSIGNED: {
    name: 'Unassigned',
    color: '#F54444',
  },
};

export const insuranceStatusProperties = {
  New: {
    name: 'New',
    color: '#F5B945',
  },
  Declined: {
    name: 'Declined',
    color: '#9579DA',
  },
  'Pending Final Approval': {
    name: 'Pending Final Approval',
    color: '#4B89DA',
  },
  Approved: {
    name: 'Approved',
    color: '#89C053',
  },
};

export const tradeStatusProperties = {
  Completed: {
    name: 'Completed',
    color: '#89C053',
  },
  Pending: {
    name: 'Pending',
    color: '#F5B945',
  },
  Retracted: {
    name: 'Retracted',
    color: '#9579DA',
  },
  Undefined: {
    name: 'Undefined',
    color: '',
  },
};

export const docStatusProperties = {
  PENDING: {
    name: 'Pending',
    color: '#D94452',
  },
  'MORE INFO NEEDED': {
    name: 'More Info Needed',
    color: '#D94452',
  },
  'BEING REVIEWED': {
    name: 'In Review',
    color: '#f2bc16',
  },
  ACCEPTED: {
    name: 'Accepted',
    color: '#48ad25',
  },
  REJECTED: {
    name: 'Rejected',
    color: '#ff1010',
  },
  UNDEFINED: {
    name: 'Undefined',
    color: '#9579DA',
  },
  CANCELED: {
    name: 'Canceled',
    color: '#ff1010',
  },
};

export const creditBackgroundProperties = {
  'Manually Closed': {
    color: '#4B89DA',
  },
  'Report(s) Delivered': {
    color: '#48AD25',
  },
  'Waiting Authorization': {
    color: '#F5B945',
  },
  'Report Request Error': {
    color: '#FA6C51',
  },
  Pending: {
    color: '#F5B945',
  },
  UNDEFINED: {
    name: 'Undefined',
    color: '#9579DA',
  },
};
export const liquidityVerificationProperties = {
  'Request Completed': {
    color: '#4B89DA',
  },
  'Request Initiated': {
    color: '#48AD25',
  },
  UNDEFINED: {
    name: 'Undefined',
    color: '#9579DA',
  },
};

export const pendingDocumentsProperties = {
  Underwriting: {
    color: '#9579DA',
  },
  Closing: {
    color: '#9579DA',
  },
};

export const userPortalStatusProperties = {
  NO_ACCOUNT: {
    name: 'No Account',
    value: 'NO_ACCOUNT',
    color: '#777',
  },
  INVITATION_EMAIL_SENT: {
    name: 'Invitation Email Sent',
    value: 'INVITATION_EMAIL_SENT',
    color: '#F2BC16',
  },
  ACCOUNT_CREATED: {
    name: 'Account Created',
    value: 'ACCOUNT_CREATED',
    color: '#48AD25',
  },
  DEACTIVATED_ACCOUNT: {
    name: 'Deactivated Account',
    value: 'DEACTIVATED_ACCOUNT',
    color: '#D94452',
  },
};

export const prequalificationStatusProperties = {
  PENDING: {
    name: 'Pending',
    value: 'Pending',
  },
  ACCEPTED: {
    name: 'Accepted',
    value: 'Accepted',
  },
};

export const generalTasksStatusProperties = {
  OPEN: {
    name: 'Open',
    color: '#808080',
  },
  IN_PROGRESS: {
    name: 'In Progress',
    color: '#4B89DA',
  },
  DONE: {
    name: 'Done',
    color: '#42b81a',
  },
  REJECTED: {
    name: 'Rejected',
    color: '#ff1010',
  },
};

export const priorityProperties = {
  VERY_CRITICAL: {
    name: 'Very Critical',
    color: '#D32F2F', // darker red for more urgency
  },
  CRITICAL: {
    name: 'Critical',
    color: '#FF5252', // bright red for high visibility
  },
  HIGH: {
    name: 'High',
    color: '#FFA726', // orange for alert
  },
  MEDIUM: {
    name: 'Medium',
    color: '#d3b937', // bright yellow for warning
  },
  LOW: {
    name: 'Low',
    color: '#42A5F5', // calming blue for lower severity
  },
};

export const drawsStatusProperties = {
  'Draw Initiated': {
    name: 'Draw Initiated',
    color: '#808080',
  },
  'Draw Request Form Submitted': {
    name: 'Draw Request Form Submitted',
    color: '#4B89DA',
  },
  'Draw In Review': {
    name: 'Draw In Review',
    color: '#F2BC16',
  },
  'Draw Approved': {
    name: 'Draw Approved',
    color: '#89C053',
  },
  'Wire Initialized': {
    name: 'Wire Initialized',
    color: '#42b81a',
  },
  'Wire Sent/Draw Resolved': {
    name: 'Wire Sent/Draw Resolved',
    color: '#42b81a',
  },
};

export const extensionsStatusProperties = {
  'Extension Initiated': {
    name: 'Extension Initiated',
    color: '#808080',
  },
  'Insurance under review': {
    name: 'Insurance under review',
    color: '#F2BC16',
  },
  'Insurance Review Completed': {
    name: 'Insurance Review Completed',
    color: '#F2BC16',
  },
  'Extension In Process': {
    name: 'Extension In Process',
    color: '#F2BC16',
  },
  'Extension Completed': {
    name: 'Extension Completed',
    color: '#42b81a',
  },
};

export const payoffsStatusProperties = {
  'Payoff Requested': {
    name: 'Payoff Requested',
    color: '#808080',
  },
  'Forwarded to Servicer': {
    name: 'Forwarded to Servicer',
    color: '#F2BC16',
  },
  'Approval & Forwarding Pending': {
    name: 'Approval & Forwarding Pending',
    color: '#F2BC16',
  },
  Approved: {
    name: 'Approved',
    color: '#42b81a',
  },
  Expired: {
    name: 'Expired',
    color: '#ff1010',
  },
  Retracted: {
    name: 'Retracted',
    color: '#9579DA',
  },
};
export const bankInStatusProperties = {
  PENDING: {
    name: 'Pending',
    color: '#ff1010',
  },
  PENDING_APPROVAL: {
    name: 'Pending Approval',
    color: '#4B89DA',
  },
  APPROVED: {
    name: 'Approved',
    color: '#42b81a',
  },
};

export enum PrequalificationLanes {
  PRE_QUALIFIED = 'PRE_QUALIFIED',
  PRE_REVIEW = 'PRE_REVIEW',
  UNASSIGNED = 'UNASSIGNED',
}

export enum PreQualificationDetailsEnum {
  PRE_QUALIFIED_BRIDGE = 'Pre-Qualified Bridge',
  PRE_QUALIFIED_TERM = 'Pre-Qualified Term',
}

export const getPublicDocumentDownloadUrl = (documentId, lastUpdatedDate) => {
  let host = '';
  if (isMobileApp() && !isMobileAppLocalHost()) {
    host = (window as any).MOBILE_UI_GLOBAL_DATA.restApiBaseURL;
  }
  if (isElectronApp() && !isElectronAppLocalHost()) {
    host = (window as any).CHAT_UI_GLOBAL_DATA.restApiBaseURL;
  }
  return documentId
    ? `${host ?? ''}/api/v1/document/public/download/${btoa(
        documentId
      )}?lastUpdatedDate=${lastUpdatedDate}`
    : '';
};

export const parseAddress = (
  address: string,
  addressDetails,
  geometryDetails?
): AddressComponents => {
  const parsedAddress = addressDetails
    ? parseGoogleMapsAddress(addressDetails, geometryDetails)
    : parseAddressString(address);
  return parsedAddress;
};

export const parseAddressString = (address: string): AddressComponents => {
  const response = getAddressComponents();

  try {
    const _address = addresser.parseAddress(address);

    if (_address.streetNumber) {
      response.street_number = _address.streetNumber;
    }
    if (_address.streetName) {
      response.street = _address.streetName;
    }
    if (_address.streetSuffix) {
      response.street = `${_address.streetName} ${_address.streetSuffix}`;
    }
    if (_address.zipCode) {
      response.zip = _address.zipCode;
    }
    if (_address.stateName && _address.stateAbbreviation) {
      response.state = _address.stateAbbreviation;
    }
    if (_address.placeName) {
      response.city = _address.placeName;
    }
  } catch (err) {
    console.log('err', err);
  }
  return response;
};

export const parseGoogleMapsAddress = (
  details,
  geometryDetails?
): AddressComponents => {
  const response = getAddressComponents();

  for (const field of details) {
    if (field?.types?.includes('street_number')) {
      response.street_number = field.long_name;
    }
    if (field?.types?.includes('subpremise')) {
      response.aptNumber = field.long_name;
    }
    if (field?.types?.includes('route')) {
      response.street = field.long_name;
    }
    if (field?.types?.includes('postal_code')) {
      response.zip = field.long_name;
    }
    if (field?.types?.includes('administrative_area_level_1')) {
      response.state = field.short_name;
    }
    if (field?.types?.includes('administrative_area_level_2')) {
      response.county = field.long_name;
    }
    if (field?.types?.includes('locality')) {
      response.city = field.long_name;
    } else if (field?.types?.includes('sublocality')) {
      response.city = field.long_name;
    } else if (field?.types?.includes('administrative_area_level_3')) {
      response.city = response.city ?? field.long_name;
    }
    if (
      field?.types?.includes('country') &&
      field.long_name === 'Puerto Rico'
    ) {
      response.state = field.short_name;
    }
  }

  if (geometryDetails) {
    response.latitude = geometryDetails.location.lat();
    response.longitude = geometryDetails.location.lng();
  }

  return response;
};

export const getAddressFromFields = (
  streetNumber: string,
  streetName: string,
  aptNumber: string,
  city: string,
  state: string,
  zipCode: string
) => {
  const line1 = [streetNumber ?? '', streetName ?? '', aptNumber ?? '']
    .filter(isNotBlank)
    .join(' ');

  const line2 = [city ? city + ',' : '', state ?? '', zipCode ?? '']
    .filter(isNotBlank)
    .join(' ');

  return [line1, line2].filter(isNotBlank).join(', ');
};

export const randomColor = () => {
  let hex = Math.floor(Math.random() * 0xffffff);
  let color = '#' + hex.toString(16);
  return color;
};

export const getRandomColor = (name: string) => {
  if (!name) {
    return randomColor();
  }
  // get first alphabet in upper case
  const firstAlphabet = name.charAt(0).toLowerCase();
  // get the ASCII code of the character
  const asciiCode = firstAlphabet.charCodeAt(0);
  // number that contains 3 times ASCII value of character -- unique for every alphabet
  const colorNum =
    asciiCode.toString() + asciiCode.toString() + asciiCode.toString();
  var num = Math.round(0xffffff * parseInt(colorNum));
  var r = (num >> 16) & 255;
  var g = (num >> 8) & 255;
  var b = num & 255;
  return 'rgb(' + r + ', ' + g + ', ' + b + ', 0.3)';
};

export const getNameInCamelCase = (firstName: string, lastName: string) => {
  try {
    firstName =
      firstName.charAt(0).toUpperCase() +
      firstName.substring(1, firstName.length).toLowerCase();
    const lastNameInitial = lastName?.charAt(0).toUpperCase() ?? '';
    return firstName + ' ' + lastNameInitial;
  } catch (e) {
    return firstName + ' ' + lastName;
  }
};

export const addQueryParam = (
  params: string,
  key: string,
  value: string | number
) => {
  if (!key || !value) {
    return params;
  }
  return `${params ? `${params}&` : ''}${key}=${value}`;
};

export const getUniqueId = () => {
  return Math.floor(Math.random() * Date.now());
};

export const getTemporaryId = () => {
  return Symbol('temporaryId');
};

export const isTemporaryId = id => {
  return typeof id === 'symbol';
};

export const sanitizeTestId = (str: string, separator: string = '-') => {
  try {
    return (str ?? '').replace(/[^A-z0-9]+/g, '-').toLowerCase();
  } catch (e) {
    return str;
  }
};

export const debounce = (func, timeout = 300) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => func.apply(this, args), timeout);
  };
};

export const debounceRequest = <
  R,
  A,
  T extends (cancelToken: CancelToken, ...args: A[]) => R
>(
  func: T,
  timeout = 300
): ((...args: A[]) => Promise<R>) => {
  let timer;
  let cancelToken: CancelTokenSource;
  return (...args) => {
    return new Promise(resolve => {
      cancelToken?.cancel();
      cancelToken = axios.CancelToken.source();
      clearTimeout(timer);
      timer = setTimeout(() => {
        const result = func.apply(this, [cancelToken?.token, ...args]);
        resolve(result);
      }, timeout);
    });
  };
};

export const uniq = arr => [...new Set(arr)];

export const roundHalf = (num: number) => {
  if ((num * 100) % 10 != 5) {
    num = parseFloat((Math.round(num * 100) / 100).toFixed(2));
  }
  return num;
};

export const formatPrecision = (num: number, precision: number = 2) => {
  return parseFloat(num.toFixed(precision));
};

export const passwordRequirementFields = [
  'passwordMin',
  'passwordSpecialOrNumber',
  'passwordLowerUpper',
  'passwordsMatch',
];

export const groupBy = <T>(
  array: T[],
  predicate: (value: T, index: number, array: T[]) => string
) =>
  array.reduce((acc, value, index, array) => {
    (acc[predicate(value, index, array)] ||= []).push(value);
    return acc;
  }, {} as { [key: string]: T[] });

export const loanRequestStatus = {
  Rejected: {
    color: '#ff1010',
  },
  Approved: {
    color: '#89C053',
  },
  'Being Reviewed': {
    color: '#F5B945',
  },
  UNDEFINED: {
    name: 'Undefined',
    color: '#9579DA',
  },
};

export const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const extractContent = (htmlString: string) => {
  var span = document.createElement('span');
  span.innerHTML = htmlString;
  return span.textContent || span.innerText;
};

export type ValidationResponse = {
  isValid: boolean;
  errors: string[];
  warnings: string[];
  outOfBox: boolean;
  lane?: string;
  status?: string;
};

export const numberWithCommas = number => {
  return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const roundToTwoDigits = (number: number) => {
  try {
    return Math.round(number * 100) / 100;
  } catch (e) {
    return number;
  }
};

export function createPromise<T>() {
  let promiseHolder: PromiseHolder<T>;
  const p = new Promise<T>((resolve, reject) => {
    promiseHolder.resolve = resolve;
    promiseHolder.reject = reject;
  });
  promiseHolder.promise = p;
  return promiseHolder;
}

export enum LoanPrescreenStatus {
  DEAL_ROOM = 'DEAL_ROOM',
  PRE_REVIEW = 'PRE_REVIEW',
  PRE_QUALIFIED = 'PRE_QUALIFIED',
  DEAL_ROOM_APPROVED = 'DEAL_ROOM_APPROVED',
  UNASSIGNED = 'UNASSIGNED',
  NOT_APPLICABLE = 'NOT_APPLICABLE',
}

export const loanEconomicsTier = [
  { value: 'Tier 6', label: 'Tier 6' },
  { value: 'Tier 5', label: 'Tier 5' },
  { value: 'Tier 4', label: 'Tier 4' },
  { value: 'Tier 3', label: 'Tier 3' },
  { value: 'Tier 2', label: 'Tier 2' },
  { value: 'Tier 1', label: 'Tier 1' },
];

export const pointSplitSchemes = [
  { value: 'All points to Roc', label: 'All points to Roc' },
  { value: '0 points to Roc', label: '0 points to Roc' },
  { value: '1 Point to Roc', label: '1 Point to Roc' },
  { value: '25bp to Roc', label: '25bp to Roc' },
  { value: '35bp to Roc', label: '35bp to Roc' },
  { value: '40bp to Roc', label: '40bp to Roc' },
  { value: '50bp to Roc', label: '50bp to Roc' },
  { value: '60bp to Roc', label: '60bp to Roc' },
  { value: '75bp to Roc', label: '75bp to Roc' },
  { value: '25% to Roc', label: '25% to Roc' },
  { value: '40% to Roc', label: '40% to Roc' },
  { value: 'Roc gets 50bp + 25% above', label: 'Roc gets 50bp + 25% above' },
  { value: 'Roc gets 50bp + 50% above', label: 'Roc gets 50bp + 50% above' },
  { value: '50/50 Split', label: '50/50 Split' },
];

export const approvalFlowStatus = [
  { value: 'Prospecting', label: 'Prospecting' },
  { value: 'Application in Progress', label: 'Application in Progress' },
  { value: 'Application Submitted', label: 'Application Submitted' },
  { value: 'Due Diligence', label: 'Due Diligence' },
  { value: 'Committee Decision', label: 'Committee Decision' },
  { value: 'Approved', label: 'Approved' },
  { value: 'Declined', label: 'Declined' },
];

export const normalizeState = (state: string) => {
  if (stateNormalizerJson[state]) {
    return stateNormalizerJson[state];
  } else {
    return state;
  }
};

export const generateUUID = () => {
  return uuid.v4();
};

export enum SegmentTrackerEvent {
  SOW_SUBMISSION = 'Scope Of Work Submission',
  SOW_REVISION = 'Scope of Work Revision Submitted',
  APPRAISAL_SUBMISSION = 'Appraisal Submission',
  LOAN_BORROWER_SUBMISSION = 'New Loan Application for Borrower Submission',
  CREDIT_BACKGROUND = 'Credit Background Check Request',
  LOAN_APPRAISAL = 'Loan Appraisal Order Submission',
  TERM_SUBMISSION = 'Term Loan Submission',
  BRIDGE_SUBMISSION = 'Bridge Loan Submission',
  MFB_SUBMISSION = 'MFB Loan Submission',
  STB_SUBMISSION = 'STB Loan Submission',
  PROPOSAL_SUBMISSION = 'Proposal Submission',
  PUBLIC_SUBMISSION = 'Public Loan Submission',
  SIGMA_REPORTS = 'Sigma Reports',
}

export enum SegmentTrackerEventCompTool {
  COMP_TOOL_SEARCH = 'Comp Tool Search',
  COMP_TOOL_FILTERS_SEARCH = 'Comp Tool Filters Search',
  COMP_TOOL_SUBJECT_PROPERTY_CLICKED = 'Comp Tool Subject Property Clicked',
  COMP_TOOL_COMP_PROPERTY_CLICKED = 'Comp Tool Comp Property Clicked',
  COMP_TOOL_COMP_PROPERTY_CLICKED_FROM_MAP = 'Comp Tool Comp Property Clicked From Map',
  COMP_TOOL_PAGE_CLICKED = 'Comp Tool Page Clicked',
  COMP_TOOL_RENTAL_REPORTS = 'Comp Tool Rental Reports',
  COMP_TOOL_LIST_VIEW_CLICKED = 'Comp Tool List View Clicked',
  COMP_TOOL_COMP_CHECKED = 'Comp Tool Comp Checked',
  COMP_TOOL_EDIT_SUBJECT_PROPERTY = 'Comp Tool Edit Subject Property',
  COMP_TOOL_OPEN_ALL_PHOTOS = 'Comp Tool Open All Photos',
  COMP_TOOL_OPEN_GOOGLE = 'Comp Tool Open Google',
  COMP_TOOL_OPEN_ZILLOW = 'Comp Tool Open Zillow',
  COMP_TOOL_OPEN_TRANSACTION_HISTORY = 'Comp Tool Open Transaction History',
}

export const flatten = (array: any[]) =>
  array.reduce((agg, item) => agg.concat(item), []);

export const normalizeHTMLTags = (value: string) =>
  value
    ?.replace('<ins>', '<u>')
    .replace('</ins>', '</u>')
    .replace(/<p><\/p>/gi, '<br>')
    .replace(/\n{2,}/g, '<br>');

export const imageInsertionOptions = [
  { value: 'noImage', label: 'No Image' },
  { value: 'oneImage', label: 'One Image' },
  { value: 'twoImagesSideBySide', label: 'Two Images Side By Side' },
];

export const getValidatedRangeValue = (value, min, max) => {
  if (min && value < min) {
    return min;
  } else if (max && value > max) {
    return max;
  }

  return value;
};
