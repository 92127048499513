
import { useState } from 'react';


import { Box, Button, Card, CardActions, CardContent, CircularProgress, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import { observer } from 'mobx-react';
import { useTwoFactorStore } from '../hooks/useTwoFactorStore';
import { TwoFactorType } from '../types';
import { TwoFactorOtpInput } from './twoFactorOtp';


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  }
}));

const DisableTwoFactor = ({ twoFactorType, onSuccess, onCancel }: {
  twoFactorType: TwoFactorType,
  onSuccess: () => void,
  onCancel: () => void,
}) => {
  const classes = useStyles();
  const [value, setValue] = useState();
  const [verifying, setVerifying] = useState<boolean>(false);
  const { twoFactorStore } = useTwoFactorStore();

  const handleCancel = () => {
    onCancel();
  }

  const handleChange = (newValue) => {
    setValue(newValue);
  }

  const handleSubmit = () => {
    setVerifying(true);
    return twoFactorStore.disableTwoFactor(twoFactorType, value)
      .then(() => {
        onSuccess();
      })
      .finally(() => {
        setVerifying(false);
      });
  }

  return (
    <>
      <Card className={classes.root}>
        <CardContent>
          <Typography variant="h5" align="center">
            Disable Two-Factor Authentication
          </Typography>
          {
            verifying ?
              <Typography variant="body1" align="center" color='primary'>
                <Box my={4}>
                  <CircularProgress color='primary' />
                </Box>
              </Typography> :
              <div className={classes.root}>
                <Box component="div">
                  <Box mx="auto" my={2} style={{ maxWidth: 400 }}>
                    <Alert severity="warning">Your account is more secure with 2FA enabled along with login password. Disabling this extra layer of security increases the risk of your account.</Alert>
                  </Box>
                  <Box m={4} display="flex" justifyContent="center">
                    <TwoFactorOtpInput onChange={handleChange} />
                  </Box>
                </Box>
              </div>
          }
        </CardContent>

        <CardActions>
          <Box display='flex' flex='1' p={4}>
            {
              verifying ? null :
                <Grid
                  justifyContent="space-between"
                  container
                  spacing={2}
                >
                  <Grid item>
                    {
                      <Button onClick={handleCancel} className={classes.button}>
                        Cancel
                      </Button>
                    }
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSubmit}
                      className={classes.button}
                    >
                      Turn OFF
                    </Button>
                  </Grid>
                </Grid>
            }
          </Box>
        </CardActions>
      </Card>
    </>
  );
}

export default observer(DisableTwoFactor);