import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { Warning } from '@material-ui/icons';
import { FastTrackModal } from '@roc/feature-loans';
import { ClosingFormStore } from '../../../../stores/closing/closingFormStore';
import { QuoteTypeStore } from '../../../../stores/quoteTypeStore';

const useStyles = makeStyles(theme =>
  createStyles({
    fastTrackLink: {
      fontWeight: 'bold',
      cursor: 'pointer',
      textDecoration: 'underline',
    },
    textContainer: {
      display: 'flex',
      flexDirection: 'row',
      backgroundColor: '#fdefbf',
      padding: '4px',
    },
    warningIcon: {
      color: '#F2BC16',
      fontSize: '20px',
    },
    fastTrackText: {
      paddingLeft: '8px',
      fontSize: '14px',
    },
  })
);

interface IFastTrackWarningProps {
  closingFormStore: ClosingFormStore;
  store: QuoteTypeStore;
  propertiesQuantity?: number;
}

export const FastTrackWarning = observer((props: IFastTrackWarningProps) => {
  const {
    closingFormStore,
    store,
    propertiesQuantity,
  } = props;
  const classes = useStyles();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [fullyLoaded, setFullyLoaded] = useState<boolean>(false);

  const formFields = closingFormStore.form.fields;
  const isFastTrack = formFields.fastTrack.value;
  const prefInsurance = formFields.preferredInsurance.value;
  const prefTitle = formFields.preferredTitle.value;
  const fastTrackOptions = {};

  useEffect(() => {
    store.fetchFastTrackLoanId();
    store.loadFastTrackFees();
    setFullyLoaded(true);
  }, []);

  useEffect(() => {
    if (isFastTrack && fullyLoaded) {
      setOpenModal(true);
    }
  }, [isFastTrack]);

  const handleConfirm = async (options) => {
    store.updateLoanFastTrackInfo(options);
  };

  const handleFastTrackLink = () => {
    if (isFastTrack) {
      setOpenModal(true);
    }
  };

  return (
    <Box>
      <Box className={classes.textContainer}>
        <Warning className={classes.warningIcon} />
        <p className={classes.fastTrackText}>
          The loan can be{' '}
          <span
            className={classes.fastTrackLink}
            onClick={handleFastTrackLink}
          >
            fast tracked
          </span>
          . You can also access the payment link in the Underwriting section
          of the loan after submitting the loan.
        </p>
      </Box>
      <FastTrackModal
        isOpen={openModal}
        onClose={() => setOpenModal(false)}
        onUpload={(file) => { }}
        options={fastTrackOptions}
        onConfirm={handleConfirm}
        sendPaymentEmail={() => store.requestFastTrackPaymentEmail()}
        prefInsurance={prefInsurance}
        prefTitle={prefTitle}
        loanId={store.fastTrackLoanId}
        onlyPayment={false}
        fastTrackFees={store.fastTrackFees}
        propertiesQuantity={propertiesQuantity}
        paymentUrl={store.fastTrackPaymentUrl}
      />
    </Box>
  );
});
