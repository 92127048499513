import { Box } from '@material-ui/core';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useParams } from 'react-router';
import { GeneralTasksComponent } from './components/generalTasksComponent';
import { useGeneralTasksStore } from './hooks/useGeneralTasksStore';
import { GeneralTasksTab } from './types/generalTasksTypes';

export const MyGeneralTasks = observer(() => {
  const { generalTasksStore } = useGeneralTasksStore();

  useEffect(() => {
    generalTasksStore.resetAndFetchOptions();
    generalTasksStore.setTab(GeneralTasksTab.MY_TASKS);
  }, []);

  return (
    <Box px={0.25} py={2} width="100%" bgcolor={'#fff'}>
      <GeneralTasksComponent />
    </Box>
  );
});
