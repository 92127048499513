import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { Button } from '@roc/ui';
import { AppraisalDetailsForm, PropertyForm, BorrowerForm } from 'libs/feature-appraisals/src/appraisals/submitAppraisal/components/submitAppraisalForm/components';
import { observer } from 'mobx-react';
import { LenderForm } from './lenderForm';

export const SubmitAppraisalFormInternal = observer(({ store }) => {
  const { isValidPropertyType, isValidLender } = store;

  const createOrder = () => {
    store.submitAppraisal();
  };

  useEffect(() => {
    store.allLenderOptions = store.getAllLenderIdNames();
  }, [])

  return (
    <Grid container>
      <Grid container item>
        <Grid item container xs={12} spacing={4}>
          <PropertyForm submitAppraisalStore={store} />
          {isValidPropertyType && (
            <>
              <AppraisalDetailsForm submitAppraisalStore={store} />
              <LenderForm submitAppraisalStore={store} />
            </>
          )}
          {isValidLender && (
            <BorrowerForm submitAppraisalStore={store} />
          )}
          <Grid item container justifyContent="flex-end">
            <Button
              variant="contained"
              type="submit"
              color="primary"
              onClick={createOrder}
              testId="createOrder"
            >
              Create Order
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
});
