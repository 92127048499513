import { Grid, Typography, Button, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { SelectBrokers } from '@roc/feature-brokers';
import { useStore } from '@roc/client-portal-shared/hooks';
import { RadioField } from '@roc/ui';
import { useLoanSummaryStore } from '@roc/feature-loan-summary';
import { useCRMPortalStore } from 'apps/crm-portal/src/app/hooks/useCRMPortalStore';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(3),
  },
  sectionTitle: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
  },
  description: {
    marginBottom: theme.spacing(2),
    fontSize: '1rem',
  },
  noteText: {
    fontSize: '0.875rem',
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(1),
  },
  buttonContainer: {
    marginTop: theme.spacing(3),
    display: 'flex',
    justifyContent: 'space-between',
  },
  button: {
    padding: theme.spacing(1.5, 3),
    fontWeight: 'bold',
    borderRadius: '4px',
  },
  nextButton: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
  },
  draftButton: {
    backgroundColor: '#f5f5f5',
  }
}));

export const BrokerInformation = observer(() => {
  const { globalStore } = useStore();
  const classes = useStyles();
  const title = 'Broker Information';
  const { brokersStore,loanStore} = useStore();
  const [editLoanJson, setEditLoanJson] = useState(null)

  useEffect(() => {
    const referralFee =
    loanStore.fixFlipStore.loanInformationStore.form?.fields?.referralFee?.value > 0
        ? loanStore.fixFlipStore.loanInformationStore.form?.fields?.referralFee?.value
        : loanStore.stabilizedBridgeStore.loanInformationStore.form?.fields?.referralFee?.value > 0
            ? loanStore.stabilizedBridgeStore.loanInformationStore.form?.fields?.referralFee?.value
            : loanStore.multiFamilyStore.loanInformationStore.form?.fields?.referralFee?.value > 0
                ? loanStore.multiFamilyStore.loanInformationStore.form?.fields?.referralFee?.value
                : null;
    if(brokersStore.onTheHud){
        referralFee ? brokersStore.setOnTheHud(false) : brokersStore.setOnTheHud(true);
    }
  }, []);


  const options = [
    { label: 'On the HUD', value: true },
    { label: 'Off the HUD', value: false }
  ];

  const handleChange = (value) => {
    if (value.toLowerCase() === 'true') {
      brokersStore.setOnTheHud(true);
    } else {
      brokersStore.setOnTheHud(false);
    }
  }


  return (
    <Box className={classes.container}>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h4">{title}</Typography>
          <br />
          <Typography className={classes.description}>
            Please choose an existing broker or enter the new broker's information.
          </Typography>
        </Grid>
        {(brokersStore.selectBrokersStore.brokers.length > 0 && globalStore?.lenderInfo?.isInternal) &&
          <Grid item xs={12} sm={6}>
            <RadioField
              standaloneLabel
              label="How do you want the broker to be paid ?"
              value={brokersStore.onTheHud}
              options={options}
              onChange={value => handleChange(value)}
              required={true}
              fullWidth
              testId='hud'
              row
              disabled= {brokersStore.isTermLoan}
            />
            <Typography className={classes.noteText}>
              Note: Brokers paid on the HUD will have a separate broker line item and brokers paid off the HUD will have their fee rolled into origination points.
            </Typography>
          </Grid>
        }
      </Grid>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <SelectBrokers />
        </Grid>
      </Grid>
    </Box>
  );
});
