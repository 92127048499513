import { DataGrid as Grid, GridColumnProps } from '@roc/ui';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useDocumentStore } from './../../../../hooks/useDocumentStore';

const noOpComparator = () => 0;

const columns: GridColumnProps[] = [
  {
    field: 'orderId',
    headerName: 'Order Assigned',
    maxWidth: 150,
    comparator: noOpComparator,
    sortable: false,
  },
  {
    field: 'name',
    headerName: 'Name',
    minWidth: 300,
    comparator: noOpComparator,
    sortable: false,
  },
  {
    field: 'email',
    headerName: 'Email Address',
    minWidth: 130,
    comparator: noOpComparator,
    sortable: false,
  },
  {
    field: 'phone',
    headerName: 'Contact Number',
    maxWidth: 150,
    sortable: false,
    comparator: noOpComparator,
  },
  {
    field: 'expectedAppraisalDate',
    headerName: 'Appraiser Due Date',
    minWidth: 150,
    sortable: false,
    comparator: noOpComparator,
  },
];

const frameworkComponents = {};

export const AppraiserDetailsGrid = observer(props => {
  const {
    documentStore: { documentFormStore },
  } = useDocumentStore();
  const { appraisalOrderDetailsStore } = documentFormStore;

  useEffect(() => {
    appraisalOrderDetailsStore.appraiserDetailsGridStore.fetchGridData();
  }, [appraisalOrderDetailsStore.appraisalDetailsGridStore.gridData.data]);

  return (
    <Grid
      columns={columns}
      rows={
        appraisalOrderDetailsStore.appraiserDetailsGridStore.gridData.data.rows
      }
      frameworkComponents={frameworkComponents}
      isLoading={appraisalOrderDetailsStore.appraiserDetailsGridStore.isLoading}
      showFilters={
        appraisalOrderDetailsStore.appraiserDetailsGridStore.showFilters
      }
      domLayout="autoHeight"
    />
  );
});
