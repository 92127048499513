import { Page } from "@roc/feature-app-core";
import { DrawDqData } from "@roc/feature-draw-dq-data";
import { Route } from "react-router";
import { useRocInternalPortalStore } from "../../../hooks";

export const getDrawDqDataInternalRoutesConfig = (basePath: string, baseUrl: string) => {
  return {
    drawDqData: {
      path: `${basePath}/draw-dq-data/:drawId`,
      url: `${baseUrl}/draw-dq-data/:drawId`,
      documentTitle: 'Draw DQ Information',
    },
  };
};

export const getDrawDqDataInternalRoutes = (basePath: string, baseUrl: string) => {
  const config = getDrawDqDataInternalRoutesConfig(basePath, baseUrl);
  const { drawDqDataInternalStore } = useRocInternalPortalStore();
  return {
    drawDqData: (
      <Route exact path={config.drawDqData.path}>
        <Page routeDefinition={config.drawDqData}>
          <DrawDqData store={drawDqDataInternalStore} />
        </Page>
      </Route>
    ),
  };
};