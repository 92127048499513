import React from 'react';
import { Typography } from '@material-ui/core';
import { ScopeOfWorkV2Option } from '@roc/feature-sow-shared/stores/v2';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';

export const scopeOfWorkLinkOptions = [
  {
    label: 'Link An Existing Scope Of Work',
    value: ScopeOfWorkV2Option.LINK_EXISTING_SCOPE_OF_WORK,
  },
  {
    label: 'Create A New Scope Of Work',
    value: ScopeOfWorkV2Option.NEW_SCOPE_OF_WORK,
  },
];

const useStyles = makeStyles((theme: Theme) => ({
  instruction: {
    marginBottom: theme.spacing(3),
    fontSize: 18,
    textAlign: 'left',
  },
  address: {
    fontWeight: 'bold',
    color: '#000000',
    fontSize: 18,
  },
}));

export type AddressMismatchProps = {
  propertyAddress: string;
  scopeOfWorkAddress: string;
};

export const AddressMismatch = (props: AddressMismatchProps) => {
  const { propertyAddress, scopeOfWorkAddress } = props;

  const classes = useStyles();

  return (
    <>
      <Typography className={classes.instruction}>
        The property address in the loan is different from the address specified
        in the Scope of Work form.
      </Typography>
      <Typography className={classes.instruction}>
        Property Address in the loan:{' '}
        <Typography component="span" className={classes.address}>
          {propertyAddress}
        </Typography>
        .
      </Typography>
      <Typography className={classes.instruction}>
        Property Address in the Scope of work form:{' '}
        <Typography component="span" className={classes.address}>
          {scopeOfWorkAddress}
        </Typography>
        .
      </Typography>
      <Typography className={classes.instruction}>
        Do you still want to continue?
      </Typography>
    </>
  );
};

export default AddressMismatch;
