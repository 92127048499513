import { CompanyProfileCRM } from "libs/feature-backoffice-users/src/backOfficeUsers/components/companyProfile/companyProfileCRM";
import { observer } from "mobx-react";
import { useRocInternalPortalStore } from "../../hooks";
import { useEffect, useState } from "react";


export const CompanyProfileCRMInternalWrapper = observer(({ companyId = null }) => {
  const { globalStore, companyProfileInternalStore, manageUsersStore, manageUsersBackUpStore, userStore } = useRocInternalPortalStore();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        globalStore.setSelectedCompanyId(companyId);
        await companyProfileInternalStore.salesforceLenderStore.getUser();
        companyProfileInternalStore.salesforceLenderStore.setPortal("Internal");
        userStore.setInternalUser(companyProfileInternalStore.salesforceLenderStore.internalUser, null);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        globalStore.notificationStore.showErrorNotification({
          message: 'An error occurred while fetching the user. Please contact tech support'
        });
      }
    };

    fetchData();
  }, [companyProfileInternalStore.salesforceLenderStore]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return <CompanyProfileCRM
    companyProfileStore={companyProfileInternalStore}
    globalStore={globalStore}
    manageUsersStore={manageUsersStore}
    manageUsersBackUpStore={manageUsersBackUpStore}
    role={"decisionCommittee"}
    isInternal />
})