import { GlobalStore } from '@roc/feature-app-core';
import { LoanSubmissionStep } from '@roc/feature-loans';
import { bridgeLoanSubTypes, isEmptyObject, isNil, LoanSubType, LoanType } from '@roc/feature-utils';
import { action, flow, makeObservable, observable } from 'mobx';
import {
  BorrowerEntityInformationSteps,
  BorrowerInformationSteps,
  borrowerLoanSubmissionSteps,
  PropertyInformationSteps,
} from '../utils/constants';
import { BorrowerEntityInformationStore } from './borrowerEntityInformationStore';
import { BaseBorrowerInformationStore } from './baseBorrowerInformationStore';
import { BasePropertiesStore } from './basePropertiesStore';

export abstract class BaseLoanTypeStore {
  public globalStore: GlobalStore;
  public borrowerInformationStore: BaseBorrowerInformationStore;
  public borrowerEntityInformationStore: BorrowerEntityInformationStore;
  public propertiesStore: BasePropertiesStore;

  public loanSubtype: LoanSubType;
  public loanType: LoanType;
  public isPublic: boolean;
  public activeStep: number;
  public newPublicLoanId: number = 0;
  public saved: boolean;

  abstract submitLoan();

  constructor(globalStore: GlobalStore) {
    this.globalStore = globalStore;

    makeObservable(this, {
      loanSubtype: observable,
      loanType: observable,
      isPublic: observable,
      activeStep: observable,
      newPublicLoanId: observable,
      saved: observable,
      resetForNewLoanApplication: action,
      reset: action,
      setDefaults: action,
      setLoanSubtype: action,
      handleNext: action,
      handleBack: action,
      handleBorrowerEntityInformationNextStep: action,
      handleSummaryBack: action,
      setActiveStep: action,
      submitLoan: flow,
    });
  }

  setDefaults() {
    this.loanSubtype = null;
    this.loanType = null;
    this.setActiveStep(LoanSubmissionStep.ENTITY_INFORMATION);
    this.saved = false;
  }

  resetForNewLoanApplication() {
    this.reset();
    this.borrowerInformationStore.reset();
    this.borrowerInformationStore.mainBorrowerFormStore.reset();
    this.borrowerEntityInformationStore.reset();
    this.borrowerEntityInformationStore.borrowerEntityFormStore.reset();
    this.propertiesStore.reset();
  }

  reset() {
    this.setDefaults();
  }

  setLoanSubtype(loanSubtype: LoanSubType) {
    this.loanSubtype = loanSubtype;
    if (bridgeLoanSubTypes.includes(loanSubtype)) {
      this.loanType = LoanType.RESIDENTIAL_BRIDGE;
    } else {
      this.loanType = LoanType.RESIDENTIAL_TERM;
    }
  }

  setActiveStep(borrowerLoanSubmissionStep: LoanSubmissionStep) {
    this.activeStep = borrowerLoanSubmissionSteps[borrowerLoanSubmissionStep];
  }

  handleBack = (currentStep: LoanSubmissionStep) => {
    if (currentStep === LoanSubmissionStep.ENTITY_INFORMATION) {
    } else if (currentStep === LoanSubmissionStep.BORROWER_INFORMATION) {
      this.handleBorrowerInformationBackStep();
    } else if (currentStep === LoanSubmissionStep.PROPERTY_INFORMATION) {
      this.handlePropertyInformationBack();
    } else if (currentStep === LoanSubmissionStep.SUMMARY) {
      this.handleSummaryBack();
    } else {
      this.activeStep--;
    }
  };

  handleNext = (currentStep: LoanSubmissionStep) => {
    if (currentStep === LoanSubmissionStep.ENTITY_INFORMATION) {
      this.handleBorrowerEntityInformationNextStep();
      if (
        this.borrowerEntityInformationStore
          .borrowerEntityInformationActiveStep ===
          BorrowerEntityInformationSteps.ENTITY_INFORMATION_COMPLETED ||
        this.borrowerEntityInformationStore
          .borrowerEntityInformationActiveStep ===
          BorrowerEntityInformationSteps.EDIT_ENTITY_INFORMATION
      ) {
        this.setActiveStep(LoanSubmissionStep.BORROWER_INFORMATION);
        this.borrowerInformationStore.borrowerInformationActiveStep =
          BorrowerInformationSteps.PERSONAL_INFORMATION;
      }
    } else if (currentStep === LoanSubmissionStep.BORROWER_INFORMATION) {
      this.handleBorrowerInformationNextStep();
      if (
        this.borrowerInformationStore.borrowerInformationActiveStep ===
        BorrowerInformationSteps.BORROWER_INFORMATION_COMPLETED
      ) {
        this.setActiveStep(LoanSubmissionStep.PROPERTY_INFORMATION);
      }
    } else if (currentStep === LoanSubmissionStep.PROPERTY_INFORMATION) {
      this.propertiesStore.handlePropertyInformationNextStep();
      if (
        this.propertiesStore.getPropertyInformationActiveStep() ===
        PropertyInformationSteps.PROPERTY_INFORMATION_COMPLETED
      ) {
        this.setActiveStep(LoanSubmissionStep.SUMMARY);
      }
    } else if (currentStep === LoanSubmissionStep.SUMMARY) {
      this.submitLoan();
    } else {
      this.activeStep++;
    }
  };

  handleBorrowerEntityInformationNextStep = () => {
    switch (
      this.borrowerEntityInformationStore.borrowerEntityInformationActiveStep
    ) {
      case BorrowerEntityInformationSteps.ENTITY_INFORMATION:
        if (
          this.borrowerEntityInformationStore.borrowerEntityFormStore
            .hasEntityInfo
        ) {
          this.borrowerEntityInformationStore.borrowerEntityFormStore.runFormValidation();
          if (
            this.borrowerEntityInformationStore.borrowerEntityFormStore.form
              .meta.isValid
          ) {
            this.borrowerEntityInformationStore.mainEntity = this.borrowerEntityInformationStore.borrowerEntityFormStore.getFormValues();
            this.borrowerEntityInformationStore.borrowerEntityInformationActiveStep =
              BorrowerEntityInformationSteps.ENTITY_INFORMATION_COMPLETED;
          } else {
            this.globalStore.notificationStore.showErrorNotification({
              message: 'Please fix the validation errors.',
            });
          }
        } else {
          this.borrowerEntityInformationStore.borrowerEntityInformationActiveStep =
            BorrowerEntityInformationSteps.ENTITY_INFORMATION_COMPLETED;
        }
        break;
    }
  };

  handleBorrowerInformationBackStep = () => {
    switch (this.borrowerInformationStore.borrowerInformationActiveStep) {
      case BorrowerInformationSteps.PERSONAL_INFORMATION:
        this.borrowerEntityInformationStore.borrowerEntityInformationActiveStep =
          BorrowerEntityInformationSteps.ENTITY_INFORMATION;
        this.setActiveStep(LoanSubmissionStep.ENTITY_INFORMATION);
        break;
      case BorrowerInformationSteps.CO_BORROWER_INFORMATION:
        this.borrowerInformationStore.borrowerInformationActiveStep =
          BorrowerInformationSteps.PERSONAL_INFORMATION;
        break;
      case BorrowerInformationSteps.EDIT_PERSONAL_INFORMATION:
      case BorrowerInformationSteps.EDIT_CO_BORROWER_INFORMATION:
      case BorrowerInformationSteps.ENTITY_OWNERSHIP:
        this.borrowerInformationStore.borrowerInformationActiveStep =
          this.borrowerInformationStore.coBorrowersCount > 0
            ? BorrowerInformationSteps.CO_BORROWER_INFORMATION
            : BorrowerInformationSteps.PERSONAL_INFORMATION;
        break;
    }
  };

  handleBorrowerInformationNextStep = () => {
    switch (this.borrowerInformationStore.borrowerInformationActiveStep) {
      case BorrowerInformationSteps.PERSONAL_INFORMATION:
        this.borrowerInformationStore.mainBorrowerFormStore.runFormValidationWithMessage();
        if (
          this.borrowerInformationStore.mainBorrowerFormStore.form.meta.isValid
        ) {
          this.borrowerInformationStore.mainBorrower = this.borrowerInformationStore.mainBorrowerFormStore.getFormValues();
        } else {
          return;
        }
        if (!isNil(this.borrowerInformationStore.coBorrowersCount)) {
          if (this.borrowerInformationStore.coBorrowersCount == 0) {
            if (
              this.borrowerEntityInformationStore.borrowerEntityFormStore
                .hasEntityInfo
            ) {
              this.borrowerInformationStore.mainBorrowerFormStore.onFieldChange(
                'pctOwnership',
                100
              );
              this.borrowerInformationStore.mainBorrower = this.borrowerInformationStore.mainBorrowerFormStore.getFormValues();
            }
            this.borrowerInformationStore.borrowerInformationActiveStep =
              BorrowerInformationSteps.BORROWER_INFORMATION_COMPLETED;
          } else {
            if (
              this.borrowerInformationStore.coBorrowersCount <
              this.borrowerInformationStore.coBorrowersFormStores.length
            ) {
              const coBorrowersToRemove =
                this.borrowerInformationStore.coBorrowersFormStores.length -
                this.borrowerInformationStore.coBorrowersCount;
              this.borrowerInformationStore.coBorrowersFormStores.splice(
                this.borrowerInformationStore.coBorrowersCount,
                coBorrowersToRemove
              );
              this.borrowerInformationStore.coBorrowers.splice(
                this.borrowerInformationStore.coBorrowersCount,
                coBorrowersToRemove
              );
            } else if (
              this.borrowerInformationStore.coBorrowersCount >
              this.borrowerInformationStore.coBorrowersFormStores.length
            ) {
              const coBorrowersToAdd =
                this.borrowerInformationStore.coBorrowersCount -
                this.borrowerInformationStore.coBorrowersFormStores.length;
              for (let index = 0; index < coBorrowersToAdd; index++) {
                this.borrowerInformationStore.coBorrowersFormStores.push(
                  this.borrowerInformationStore.createBorrowerForm()
                );
                this.borrowerInformationStore.coBorrowers.push({});
              }
            }
            this.borrowerInformationStore.borrowerInformationActiveStep =
              BorrowerInformationSteps.CO_BORROWER_INFORMATION;
          }
        } else {
          this.globalStore.notificationStore.showErrorNotification({
            message:
              'Please enter the number of additional co-borrowers in this loan.',
          });
        }
        break;
      case BorrowerInformationSteps.CO_BORROWER_INFORMATION:
        let areValidCoBorrowers = true;
        for (
          let index = 0;
          index < this.borrowerInformationStore.coBorrowersCount;
          index++
        ) {
          this.borrowerInformationStore.coBorrowersFormStores[
            index
          ].runFormValidation();
          if (
            this.borrowerInformationStore.coBorrowersFormStores[index].form.meta
              .isValid
          ) {
            this.borrowerInformationStore.coBorrowers[
              index
            ] = this.borrowerInformationStore.coBorrowersFormStores[
              index
            ].getFormValues();
          } else {
            areValidCoBorrowers = false;
          }
        }
        if (
          areValidCoBorrowers &&
          this.borrowerEntityInformationStore.borrowerEntityFormStore
            .hasEntityInfo
        ) {
          this.borrowerInformationStore.borrowerInformationActiveStep =
            BorrowerInformationSteps.ENTITY_OWNERSHIP;
        } else if (
          areValidCoBorrowers &&
          !this.borrowerEntityInformationStore.borrowerEntityFormStore
            .hasEntityInfo
        ) {
          this.borrowerInformationStore.borrowerInformationActiveStep =
            BorrowerInformationSteps.BORROWER_INFORMATION_COMPLETED;
        } else {
          this.globalStore.notificationStore.showErrorNotification({
            message: 'Please fix the validation errors.',
          });
        }
        break;
      case BorrowerInformationSteps.EDIT_PERSONAL_INFORMATION:
        this.borrowerInformationStore.mainBorrowerFormStore.runFormValidationWithMessage();
        if (
          this.borrowerInformationStore.mainBorrowerFormStore.form.meta.isValid
        ) {
          this.borrowerInformationStore.mainBorrower = this.borrowerInformationStore.mainBorrowerFormStore.getFormValues();
          this.borrowerInformationStore.borrowerInformationActiveStep =
            this.borrowerInformationStore.coBorrowersCount > 0
              ? BorrowerInformationSteps.CO_BORROWER_INFORMATION
              : BorrowerInformationSteps.BORROWER_INFORMATION_COMPLETED;
        }
        break;
      case BorrowerInformationSteps.EDIT_CO_BORROWER_INFORMATION:
        this.borrowerInformationStore.coBorrowersFormStores[
          this.borrowerInformationStore.editBorrowerActiveIndex
        ].runFormValidation();
        if (
          this.borrowerInformationStore.coBorrowersFormStores[
            this.borrowerInformationStore.editBorrowerActiveIndex
          ].form.meta.isValid
        ) {
          this.borrowerInformationStore.coBorrowers[
            this.borrowerInformationStore.editBorrowerActiveIndex
          ] = this.borrowerInformationStore.coBorrowersFormStores[
            this.borrowerInformationStore.editBorrowerActiveIndex
          ].getFormValues();
          this.borrowerInformationStore.borrowerInformationActiveStep =
            BorrowerInformationSteps.ENTITY_OWNERSHIP;
        } else {
          this.globalStore.notificationStore.showErrorNotification({
            message: 'Please fix the validation errors.',
          });
        }
        break;
      case BorrowerInformationSteps.ENTITY_OWNERSHIP:
        const pctOwnershipCompleted = this.borrowerInformationStore.handleBorrowerPctOwnershipNextStep();
        if (pctOwnershipCompleted) {
          this.borrowerInformationStore.updateBorrowersOwnershipFromFormStores();
          this.borrowerInformationStore.borrowerInformationActiveStep =
            BorrowerInformationSteps.BORROWER_INFORMATION_COMPLETED;
        } else {
          this.globalStore.notificationStore.showErrorNotification({
            message:
              'Please assign the correct ownership percentages to all borrowers.',
          });
        }
        break;
    }
  };

  handlePropertyInformationBack = () => {
    if (
      this.propertiesStore.getPropertyInformationActiveStep() ===
      PropertyInformationSteps.PROPERTY_INFORMATION
    ) {
      if (
        this.borrowerInformationStore.coBorrowersCount > 0 &&
        this.borrowerEntityInformationStore.borrowerEntityFormStore
          .hasEntityInfo
      ) {
        this.borrowerInformationStore.borrowerInformationActiveStep =
          BorrowerInformationSteps.ENTITY_OWNERSHIP;
      } else if (
        this.borrowerInformationStore.coBorrowersCount > 0 &&
        !this.borrowerEntityInformationStore.borrowerEntityFormStore
          .hasEntityInfo
      ) {
        this.borrowerInformationStore.borrowerInformationActiveStep =
          BorrowerInformationSteps.CO_BORROWER_INFORMATION;
      } else {
        this.borrowerInformationStore.borrowerInformationActiveStep =
          BorrowerInformationSteps.PERSONAL_INFORMATION;
      }
      this.setActiveStep(LoanSubmissionStep.BORROWER_INFORMATION);
    } else {
      this.propertiesStore.handlePropertyInformationBack();
    }
  };

  handleSummaryBack = () => {
    this.propertiesStore.setPropertyInformationActiveStep(PropertyInformationSteps.EDIT_PROPERTY_INFORMATION);
    this.setActiveStep(LoanSubmissionStep.PROPERTY_INFORMATION);
  };

  get propertiesSubLabels() {
    return this.propertiesStore.getProperties()
      .filter(property => !isEmptyObject(property))
      .map(property => property.address);
  };
}
