import { Grid, Typography } from '@material-ui/core';
import { useBaseStore } from '@roc/feature-app-core';
import { observer } from 'mobx-react';
import React from 'react';
import { SelectBorrowerMode, SelectBorrowers } from '@roc/feature-borrowers';
import { LoanStore } from '../../stores';
import { BorrowerFormLoan } from './borrowerFormLoan';

interface EditBorrowersFormProps {
  store: LoanStore;
}

export const EditBorrowersForm = observer((props: EditBorrowersFormProps) => {
  const { globalStore } = useBaseStore();
  const { store } = props;

  let modes = [SelectBorrowerMode.EXISTING];
  if (globalStore.userFeatures?.addNewBorrowerDuringSubmission) {
    modes = [...modes, SelectBorrowerMode.NEW];
  }

  return (
    <>
      <Grid container spacing={2}>
        <Typography variant="body1">
          Please add or remove the borrowers of this loan.
        </Typography>
      </Grid>

      <br />

      <Grid container spacing={2}>
        <SelectBorrowers
          multiple={true}
          modes={modes}
          store={store.selectBorrowersStore}
          borrowerCardDisplayFields={{
            firstName: true,
            lastName: true,
            emailAddress: true,
            pctOwnership: true,
          }}
          borrowerForm={(borrowerFormStore, currentBorrower) => (
            <BorrowerFormLoan store={borrowerFormStore} loanStore={store} currentBorrower={currentBorrower} />
          )}
        />
      </Grid>
    </>
  );
});
