import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { Paper, Breadcrumbs } from '@roc/ui';
import React from 'react';
import { useDocumentLibraryStore } from '../hooks/useDocumentLibraryStore';
import { Document } from '../types';
import { DocumentItem } from './documentItem';

type DocumentsProps = {
  documents: Document[];
};

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    padding: theme.spacing(3),
  },
  breadcrumbs: {
    marginBottom: theme.spacing(3),
  },
}));

export const Documents = (props: DocumentsProps) => {
  const { documents } = props;

  const { documentLibraryStore } = useDocumentLibraryStore();
  const { paths, showBreadcrumbs } = documentLibraryStore;

  const classes = useStyles();

  const onPathClick = (index: number) => {
    documentLibraryStore.moveToFolderInPath(index);
  };

  const onDocumentClick = (document: Document) => {
    if (document.folder) {
      documentLibraryStore.goToFolder(document.displayFileName);
    } else {
      documentLibraryStore.downloadDocument(document.path);
    }
  };

  const renderDocument = (document: Document, index: number) => {
    return (
      <DocumentItem key={index} document={document} onClick={onDocumentClick} />
    );
  };

  return (
    <Paper className={classes.paper}>
      {showBreadcrumbs && (
        <Breadcrumbs
          className={classes.breadcrumbs}
          paths={paths}
          onPathClick={onPathClick}
        />
      )}
      <Grid container direction="column" spacing={2}>
        {documents.map(renderDocument)}
      </Grid>
    </Paper>
  );
};

export default Documents;
