import { Box, Typography } from "@material-ui/core"
import { makeStyles } from '@material-ui/core/styles';
import { StandardDialog } from "@roc/ui"
import { observer } from "mobx-react"
import "../styles/mobileDialogStyle.css"

const useStyles = makeStyles(theme => ({
  mobileContainer: {
    '& .cs-button': {
      color: theme.palette.primary.dark + ' !important',
    },
    '& .cs-typing-indicator__dot': {
      backgroundColor: theme.palette.primary.dark + ' !important',
    },
    '& .cs-typing-indicator__text': {
      color: theme.palette.primary.dark + ' !important',
    },
    [theme.breakpoints.down(769)]: {
      '& .cs-sidebar.cs-sidebar--left': {
        flexGrow: 1,
        maxWidth: '100%',
        overflow: 'auto',
      },
      '& .cs-conversation__content': {
        display: 'block !important',
      },
    },
  },
}));

const DialogContent = observer(({ rephrasedComponent, initialMessage, isTabletSize }) => {
  const classes = useStyles();
  return (
    <Box mb={2} display={'flex'} flexDirection={'column'} flexGrow={1}>
      <Box>
        <Typography variant="h6" color="primary">
          ORIGINAL
        </Typography>
        <Box my={1} color={'red'} style={{
          textDecoration: 'line-through'
        }}>
          <div dangerouslySetInnerHTML={{ __html: initialMessage }} />
        </Box>
      </Box>
      <Box mt={2}>
        <Typography variant="h6" color="primary">
          REPHRASED
        </Typography>
        <Box className={isTabletSize ? classes.mobileContainer : ""} my={1} border={'1px solid #bbb'}>
          {
            rephrasedComponent
          }
        </Box>
      </Box>
    </Box>
  )
})

export const GPTMessageCorrectionDialog = observer(({
  open,
  onClose,
  rephrasedComponent,
  initialMessage,
  isTabletSize,
}) => {
  return (
    <StandardDialog
      open={open}
      rephraseClass={true}
      title={'Rephrase Sentence'}
      onClose={onClose}
      handleClose={onClose}
      maxWidth={'lg'}
      overflowDialog={true}
      dialogContent={<DialogContent rephrasedComponent={rephrasedComponent} initialMessage={initialMessage} isTabletSize={isTabletSize} />}
      removeContentBox
    />
  )
})