import { GlobalStore } from "@roc/client-portal-shared/stores";
import { ScopeOfWorkV2FormStore } from "@roc/feature-sow-shared/stores/v2";
import { ScopeOfWorkRevisionFormParams, ScopeOfWorkV2 } from "@roc/feature-sow-shared/types";
import { ScopeOfWorkRevisionFormService } from "../services";
import { CALLBACK_TYPE } from "@roc/feature-sow-shared/constants/scopeOfWorkV2Form";
import { SegmentTrackerEvent, segmentAnalytics } from "@roc/feature-utils";

export class ScopeOfWorkRevisionFormStore extends ScopeOfWorkV2FormStore {
  params: ScopeOfWorkRevisionFormParams;

  constructor(globalStore: GlobalStore) {
    super(globalStore);
    this.scopeOfWorkService = new ScopeOfWorkRevisionFormService();
  }

  setParams(params: ScopeOfWorkRevisionFormParams) {
    this.params = params;
    this.fetchInitialData();
  };

  *savePdf(response) {
    this.afterCall(CALLBACK_TYPE.SUBMIT, response);
  }

  splitCategories = async (drawId: any) => {
    const {
      data: response
    } = await this.scopeOfWorkService.getSplitedCategories(drawId)
    return response;
  }

  *handleSplitedCategories() {
    const response = yield this.splitCategories(
      this.params.drawId
    );
    this.splitedCategoriesObj = response;
  }

  *fetchScopeOfWork() {
    try {
      const response: ScopeOfWorkV2 = yield this.getScopeOfWork(this.params);
      const categoriesResponse = yield this.getScopeOfWorkCategories();
      if (!response || !categoriesResponse) return;
      this.initParams(response);
      this.initScopeOfWork(response, categoriesResponse);
      this.handleSplitedCategories()
    } catch (error) {
      console.log({ error })
      this.afterCall(CALLBACK_TYPE.GET, null);
    }
  };

  getScopeOfWork = async (params: ScopeOfWorkRevisionFormParams) => {
    try {
      const { data: response } = await this.scopeOfWorkService.getScopeOfWorkById(
        params.drawId.toString()
      );
      const sowRevision = JSON.parse(response.data).data;
      sowRevision.status = 'Pending';
      sowRevision.dataContent = JSON.parse(sowRevision.dataContent);
      sowRevision.originalData = JSON.parse(sowRevision.originalData);
      return sowRevision;
    } catch (error) {
      console.log({ error });
    }
  };

  submitScopeOfWork = async (
    params: ScopeOfWorkRevisionFormParams,
    data: any
  ) => {
    try {
      const { data: response } = await this.scopeOfWorkService.submitScopeOfWork(
        params.drawId.toString(),
        params.username,
        data,
      );
      segmentAnalytics.trackEvent({
        name: SegmentTrackerEvent.SOW_REVISION,
        userName: this.userStore?.userInformation?.username,
      });
      return response;
    } catch (error) {
      console.log({ error });
    }
  };

  getScopeOfWorkCategories = async () => {
    const { data: response } = await this.scopeOfWorkService.getCategories();
    return JSON.parse(JSON.parse(response.data).data);
  };

  getSowSubmissionErrors = async (loanId: any, propertyId: any, data: any) => {
    const {
      data: response
    } = await this.scopeOfWorkService.getSowSubmissionErrors(loanId, propertyId, data)
    return response;
  }

  saveScopeOfWorkAuditLogs = async (scopeOfWorkId: any, lenderId: any, data: any, isSubmit: boolean, userName: string) => {
  }

  reset(): void {
    super.reset();
  };
}