import { GlobalStore, GridStore } from '@roc/feature-app-core';
import { action, flow, makeObservable, observable } from 'mobx';
import { ApiResponse } from '@roc/feature-app-core';
import { AppraisalsService } from '../services/appraisalsService';
import { AppraisalType } from '../types/appraisalType';
import { FileUpload } from '@roc/ui';

export class AppraisalsStore {
  globalStore: GlobalStore;
  appraisalsService: AppraisalsService;
  loanAppraisalsGridStore: GridStore;
  standaloneAppraisalsGridStore: GridStore;

  constructor(globalStore) {
    this.globalStore = globalStore;
    this.appraisalsService = new AppraisalsService();

    this.loanAppraisalsGridStore = new GridStore(() =>
      this.fetchLoanAppraisals()
    );
    this.standaloneAppraisalsGridStore = new GridStore(() =>
      this.fetchStandaloneAppraisals()
    );

    makeObservable(this, {
      uploadAppraisalDocument: flow,
    });
  }

  async fetchLoanAppraisals() {
    this.loanAppraisalsGridStore.gridData;
    try {
      const response: ApiResponse = await this.appraisalsService.getLoanAppraisals(
        this.loanAppraisalsGridStore.gridData.meta.pageNumber,
        this.loanAppraisalsGridStore.gridData.meta.pageSize,
        this.loanAppraisalsGridStore.gridData.meta.sortDir,
        this.loanAppraisalsGridStore.gridData.meta.sortBy,
        this.loanAppraisalsGridStore.gridData.meta.filters,
        this.loanAppraisalsGridStore.gridData.meta.dropdownFilters
      );
      return response;
    } catch (error) {
      // do nothing
    }
  }

  async fetchStandaloneAppraisals() {
    try {
      const response: ApiResponse = await this.appraisalsService.getStandaloneAppraisals(
        this.standaloneAppraisalsGridStore.gridData.meta.pageNumber,
        this.standaloneAppraisalsGridStore.gridData.meta.pageSize,
        this.standaloneAppraisalsGridStore.gridData.meta.sortDir,
        this.standaloneAppraisalsGridStore.gridData.meta.sortBy,
        this.standaloneAppraisalsGridStore.gridData.meta.filters,
        this.standaloneAppraisalsGridStore.gridData.meta.dropdownFilters
      );
      return response;
    } catch (error) {
      // do nothing
    }
  }

  *uploadAppraisalDocument(
    appraisalId: number,
    isLoanAppraisal: boolean,
    documentType: string,
    fileUpload: FileUpload
  ) {
    const file = fileUpload.file;
    const blob = file.slice(0, file.size, file.type);
    const newFile = new File([blob], fileUpload.name, { type: file.type });
    yield this.appraisalsService.addAppraisalDocument(
      appraisalId,
      isLoanAppraisal,
      documentType,
      newFile
    );
  }
}
