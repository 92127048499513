import { action, flow, makeObservable, observable } from 'mobx';
import { ApiResponse, GlobalStore } from '@roc/feature-app-core';
import { CreditBackgroundType, downloadDocument, isEmptyObject, isNil, prequalificationStatusProperties } from '@roc/feature-utils';
import BorrowerSetupFormStore from './borrowerSetupFormStore';
import { BorrowerSetupInternalService } from '../../services/borrowerSetup/borrowerSetupInternalService';

export class BorrowerSetupInternalStore {

  private globalStore: GlobalStore;
  currentBorrower: any;
  isTokenValid: boolean;
  token: string;
  borrowerDetailsVerificationUrl: string;
  borrowerDetailsManualVerificationUrl: string;
  unsubscribeLink: string;
  currentRequestId: number;
  lenderId: number;
  lineItemDefaultStep: string;
  borrowerSetupFormStore: BorrowerSetupFormStore;
  private borrowerSetupInternalService: BorrowerSetupInternalService;

  constructor(
    globalStore: GlobalStore
  ) {
    this.globalStore = globalStore;
    this.setDefaults();
    this.borrowerSetupFormStore = new BorrowerSetupFormStore(globalStore);
    this.borrowerSetupInternalService = new BorrowerSetupInternalService();

    makeObservable(this, {
      currentBorrower: observable,
      isTokenValid: observable,
      token: observable,
      borrowerDetailsVerificationUrl: observable,
      borrowerDetailsManualVerificationUrl: observable,
      unsubscribeLink: observable,
      lineItemDefaultStep: observable,
      setDefaults: action,
      reset: action,
      init: flow,
      fetchBorrowerDetailsVerificationByBorrowerId: flow,
      getBorrowerDetailsVerificationLink: flow,
      getUnsubscribeLink: flow,
      sendIdVerificationEmailReminder: flow,
      saveBorrowerSelection: flow,
      submitCreditBackgroundRequest: flow,
      saveBorrower: flow,
      downloadReports: flow,
      getLoanBorrowerByLoanIdAndBorrowerId: flow,
      setLenderId: action,
      savePgForLoanBorrower: flow,
    });
  }

  setDefaults() {
    this.currentBorrower = null;
    this.isTokenValid = false;
    this.token = null;
    this.borrowerDetailsVerificationUrl = null;
    this.borrowerDetailsManualVerificationUrl = null;
    this.unsubscribeLink = '';
    this.currentRequestId = null;
    this.lenderId = null;
    this.lineItemDefaultStep = '';
  }

  reset() {
    this.setDefaults();
    this.borrowerSetupFormStore.reset();
  }

  *init(loanId, borrowerId, lenderId, loanType, callback?: () => void) {
    this.reset();
    this.setLenderId(lenderId);
    this.currentBorrower = yield this.getLoanBorrowerByLoanIdAndBorrowerId(loanId, borrowerId);
    const response = yield this.borrowerSetupInternalService.getBorrowerPrescreenInfoByBorrowerId(this.currentBorrower?.borrowerId, this.currentBorrower?.personalGuarantor, loanType);
    const borrowerData = response?.data;
    this.currentBorrower = { ...this.currentBorrower, ...borrowerData, creditBackgroundCheck: borrowerData.sendIdVerification };
    this.borrowerSetupFormStore.loadForm(this.currentBorrower);
    yield this.fetchBorrowerDetailsVerificationByBorrowerId(borrowerId);
    if (callback) {
      callback();
    }
  }

  *fetchBorrowerDetailsVerificationByBorrowerId(borrowerId) {
    try {
      let response;
      if (this.borrowerSetupFormStore.form.fields.creditBackgroundCheck.value) {
        response = yield this.borrowerSetupInternalService.getLoanApplicationBorrowerDetailsFormInfoByBorrowerId(borrowerId, this.lenderId, this.currentBorrower?.personalGuarantor);
      }
      else {
        response = yield this.borrowerSetupInternalService.getFormInformationByBorrowerId(borrowerId, this.lenderId, this.currentBorrower?.personalGuarantor);
      }
      if (!isEmptyObject(response?.data?.data) || (this.currentBorrower?.sendIdVerification && this.currentBorrower?.preQualificationStatus === prequalificationStatusProperties.ACCEPTED.name)) {
        this.isTokenValid = response?.data?.data?.isTokenValid;
        this.token = response?.data?.data?.savedForm?.requestToken;
        const creditBackgroundRequestId = response?.data?.data?.savedForm?.creditBackgroundRequestId;
        if (!isNil(creditBackgroundRequestId)) {
          this.currentRequestId = creditBackgroundRequestId;
          yield this.getBorrowerDetailsVerificationLink(creditBackgroundRequestId);
          yield this.getUnsubscribeLink(response?.data?.data?.savedForm?.requestToken);
        }
      }
      else {
        this.lineItemDefaultStep = 'Borrower Setup';
      }
    } catch (error) {
      console.log(error)
      this.globalStore.notificationStore.showErrorNotification({
        message: 'We had an issue while retrieving the borrower form details.',
      });
    }
  }

  *getBorrowerDetailsVerificationLink(requestId) {
    try {
      const { creditBackgroundCheck } = this.borrowerSetupFormStore.getFormValues();

      const response = yield this.borrowerSetupInternalService.getBorrowerSetupFormLink(requestId);
      const link = response?.data?.data;
      if (creditBackgroundCheck) {
        this.borrowerDetailsVerificationUrl = link;
        this.borrowerDetailsManualVerificationUrl = null;
      }
      else {
        this.borrowerDetailsManualVerificationUrl = link;
        this.borrowerDetailsVerificationUrl = null;
      }
    }
    catch (error) {
      console.log(error)
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while getting form link.',
      });
    }
  }

  *getUnsubscribeLink(requestToken: string) {
    try {
      const response = yield this.borrowerSetupInternalService.getUnsubscribeLink(this.lenderId, requestToken);
      this.unsubscribeLink = response?.data?.data;
    }
    catch (error) {
      console.log(error)
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while getting unsubscribe link.',
      });
    }
  }

  *sendIdVerificationEmailReminder(email) {
    try {
      const data = {
        formLink: this.borrowerDetailsVerificationUrl,
        email: email,
        unsubscribedLink: this.unsubscribeLink,
      }
      yield this.borrowerSetupInternalService.sendIdVerificationEmailReminder([data], this.lenderId);
    }
    catch (error) {
      console.log(error)
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while sending id verification email',
      });
    }
  }

  *saveBorrowerSelection(callback?: () => void) {
    try {
      const { borrowerId } = this.borrowerSetupFormStore.getFormValues();
      yield this.submitCreditBackgroundRequest();
      yield this.saveBorrower();
      yield this.fetchBorrowerDetailsVerificationByBorrowerId(borrowerId);
      if (callback) {
        callback();
      }
    } catch (error) {
      console.log(error);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while saving details.',
      });
    }
  }

  *submitCreditBackgroundRequest() {
    try {
      const { personalGuarantor, creditBackgroundCheck } = this.borrowerSetupFormStore.getFormValues();

      const type = creditBackgroundCheck ? CreditBackgroundType.ID_VERIFICATION : CreditBackgroundType.BG_ID_VERIFICATION_WITHOUT_NOTIFICATION;
      const response: ApiResponse = yield this.borrowerSetupInternalService.submitCreditBackgroundRequest({
        email: this.currentBorrower?.emailAddress,
        runCredit: creditBackgroundCheck ? personalGuarantor : false,
        runBackground: true,
        type,
        isConfirmed: true,
        isSendCAFConfirmed: true,
        checkIfAwaitingRequestsFromNportal: true,
        isDuplicateConfirmed: true,
        isBorrower: true,
        borrowerId: this.currentBorrower?.borrowerId,
        lenderId: this.lenderId,
        isFromNportal: true,
      });
      return response;
    }
    catch (error) {
      console.log(error);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while submiting request.',
      });
    }
  }

  *saveBorrower() {
    try {
      const { borrowerId, creditBackgroundCheck } = this.borrowerSetupFormStore.getFormValues();
      yield this.borrowerSetupInternalService.saveBorrower({
        ...this.currentBorrower,
        borrowerId,
        creditBackgroundCheck
      }, this.lenderId)
        .then((response) => {
          if (response?.data) {
            this.currentBorrower = { ...this.currentBorrower, ...response?.data };
          }
          this.globalStore.notificationStore.showSuccessNotification({
            message: 'Details saved successfully.',
          });
        });
    } catch (error) {
      console.log(error);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while saving borrower.',
      });
    }
  }

  *downloadReports() {
    try {
      const response: ApiResponse = yield this.borrowerSetupInternalService.downloadReports(this.currentRequestId);
      downloadDocument(response?.data, response?.headers, 'download');
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while downloading reports.',
      });
    }
  }

  *getLoanBorrowerByLoanIdAndBorrowerId(loanId, borrowerId) {
    const response = yield this.borrowerSetupInternalService.getLoanBorrower(loanId, borrowerId);
    return response.data;
  }

  setLenderId(lenderId: number) {
    this.lenderId = lenderId;
  }

  *savePgForLoanBorrower(loanId, borrowerEntityId, callback?: () => void) {
    try {
      const { borrowerId, personalGuarantor } = this.borrowerSetupFormStore.getFormValues();
      const response = yield this.borrowerSetupInternalService.savePgToLoanBorrower({
        loanId,
        borrowerId,
        borrowerEntityId,
        personalGuarantor
      });
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Details saved successfully.',
      });
      if (callback) {
        callback();
      }
    } catch (error) {
      console.log(error);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while saving details.',
      });
    }
  }

}

export default BorrowerSetupInternalStore;