import { Box } from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { BorrowerDocumentForms, useDocumentStore } from '@roc/feature-documents';
import {
  TodoCardCompponent,
  TodoItem
} from '@roc/feature-loan-details';
import { LoanProcess } from '@roc/feature-utils';
import { PDFPreview } from '@roc/ui';
import { isPendingTodoStatus } from 'libs/feature-loan-details/src/loanDetails/utils/todoUtils';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  cardContentClassName: {
    padding: '16px 0 8px 0 !important',
  },
}));

export const BorrowerDashboardTodos = observer(({ loanId, borrowerLoansStore }) => {
  const { documentStore } = useDocumentStore();
  const { loanTodosStore } = documentStore;
  const { loadingTodos, todosByLoanProcess } = loanTodosStore;
  const currentForm = documentStore.documentFormStore.currentForm;
  const [pendingTodos, setPendingTodos] = useState<TodoItem[]>();

  useEffect(() => {
    if (loanId || !currentForm) {
      documentStore.loanTodosStore.fetchTodos(loanId, LoanProcess.UNDERWRITING);
    }
  }, [loanId, currentForm]);

  useEffect(() => {
    if (loadingTodos != undefined && loadingTodos == false) {
      const _todos = todosByLoanProcess[LoanProcess.UNDERWRITING] ?? []
      const _pendingTodos = _todos.filter(t => isPendingTodoStatus(t.status));
      setPendingTodos(_pendingTodos);
      if (_todos.length) {
        borrowerLoansStore.updateLoanTodosSummary(loanId, _pendingTodos.length);
      }
    }
  }, [loadingTodos, todosByLoanProcess]);

  return (
    <Box>
      <TodoCardCompponent
        loanProcess={LoanProcess.UNDERWRITING}
        todos={pendingTodos ?? []}
        allowStatusChange={false}
        showFileName
        isLoading={documentStore.loanTodosStore.loadingTodos}
      />
      <BorrowerDocumentForms loanId={loanId} />
      <PDFPreview
        open={documentStore.documentPreview != null}
        title={documentStore.documentPreview?.title}
        data={documentStore.documentPreview?.data}
        headers={documentStore.documentPreview?.headers}
        onClose={() => documentStore.closeDocumentPreview()}
      />
    </Box>
  );
});
