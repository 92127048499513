import { StandardDialog } from "@roc/ui";
import { observer } from "mobx-react";
import { ConstructionFeasibilityToDo } from "./constructionFeasibilityToDoDialogContent";
import { Document } from '@roc/feature-types';

export type ConstructionFeasibilityToDoDialogProps = {
  open: boolean;
  loanId: any;
  onClose: () => void;
  document: Document;
}

export const ConstructionFeasibilityToDoDialog = observer((props: ConstructionFeasibilityToDoDialogProps)=> {
  const {open, loanId, onClose, document} = props;

  return (
    <StandardDialog
      open={open}
      title="Construction Feasibility Review Report"
      maxWidth="xl"
      handleClose={onClose}
      dialogContent={
        <ConstructionFeasibilityToDo
          loanId={loanId}
          onClose={onClose}
          document={document}
        />
      }
    />
  )
})

export default ConstructionFeasibilityToDoDialog;