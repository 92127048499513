import { Chip, Grid, Link, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Button, FileUploadModal, PDFPreview } from '@roc/ui';
import { TrackRecordTable } from '../trackRecordTable';
import { TRACK_RECORD_TEMPLATE, TRACK_RECORD_USER_INPUTTED, isNotBlank } from '@roc/feature-utils';
import { useState } from 'react';

const useStyles = makeStyles((theme: Theme) => {
  return {
    actionButton: {
      marginLeft: '1rem',
    },
  };
});

export const TrackRecordProperties = observer(({ store }) => {
  const classes = useStyles();

  const [uploadDocument, setUploadDocument] = useState(false);
  const [currentRowId, setCurrentRowId] = useState(null);
  const [currentRowType, setCurrentRowType] = useState(null);

  const trackRecordPropertiesHeaders = [
    {
      name: 'Type of Deal',
      width: '8%'
    },
    {
      name: 'Address',
      width: '74%'
    },
    {
      name: 'Verification Status',
      width: '12%'
    }
  ];

  const openUploadDocument = async (id, type) => {
    setCurrentRowId(id);
    setCurrentRowType(type);
    setUploadDocument(true);
  }

  const handleUploadDocument = async (id, files, type) => {
    const taskPropertyBody = {
      sectionProcess: 'TRACK_RECORD_REVIEW',
      objectType: 'PROPERTY_TRACK_RECORD',
      taskTemplateType: 'PROPERTY_TRACK_RECORD',
      objectId: id,
    };

    store.createTaskWithFile(taskPropertyBody, files[0]);
  }

  const previewDocument = (id, name, fileExtension) => {
    store.downloadTaskFile(id, name, fileExtension);
  }

  const trackRecordPropertiesRows = store.properties?.reduce((rows, property, currentIndex) => {
    rows.push({
      id: property.verifiedTrackRecordsId,
      object: property,
      type: 'property',
      cells: [
        <Grid container justifyContent='flex-start'>
          {property.dealType && (
            <Grid item>
              <Chip
                label={property.dealType?.toUpperCase()}
                variant='outlined'
                style={{
                  color: property.dealType === 'Bridge' ? '#3da3f4' : '#812291',
                  borderColor: property.dealType === 'Bridge' ? '#3da3f4' : '#812291',
                  fontWeight: 'bold'
                }}
                size='small'
              />
            </Grid>
          )}
        </Grid>,
        <Grid container justifyContent='flex-start'>
          <Typography
            style={{
              color: store.doesPropertyHasParsingError(property.purchaseAddress) ? '#d32f2f' : '#222222',
            }}
          >
            {property.fullAddress}
          </Typography>
        </Grid>,
        <Grid container justifyContent='flex-end'>
          <Grid item>
            <Chip
              label={property.isVerified ? 'Verified' : 'Not Verified'}
              variant="outlined"
              style={{
                color: property.isVerified ? '#48AD25' : '#F5B945',
                borderColor: property.isVerified ? '#48AD25' : '#F5B945',
              }}
              size='small'
            />
          </Grid>
        </Grid>,
      ],
      expandableContent: (
        <Grid container>
          <Grid item xs={12} style={{ marginBottom: '1rem' }}>
            <Typography variant="subtitle1">
              <strong>Documents</strong>
            </Typography>
            <Grid style={{ display: 'flex', flexDirection: "column" }}>
              {store.currentTasks && store.currentTasks.length > 0 ?
                store.currentTasks.map(task => {
                  if (task.originalFileName) {
                    return (
                      <Link key={task.taskId} onClick={() => previewDocument(task.taskId, task.originalFileName, task.fileExtension)}>{task.originalFileName}</Link>
                    )
                  }
                })
                : 'No Records'
              }
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            container
            justifyContent="flex-end"
            style={{ display: 'flex', marginBottom: 10 }}
          >
            {!property.isVerified && property.recordSourceType && (property.recordSourceType == TRACK_RECORD_USER_INPUTTED || property.recordSourceType === TRACK_RECORD_TEMPLATE) &&
              <Button
                variant="outlined"
                color="primary"
                onClick={() => store.handleRemoveTrackRecordProperty(property)}
                testId='removeProperty'
                className={classes.actionButton}
                size='small'
              >
                Remove Property
              </Button>
            }
            {!property.isVerified && property.recordSourceType && (property.recordSourceType === TRACK_RECORD_USER_INPUTTED || property.recordSourceType === TRACK_RECORD_TEMPLATE) &&
              <Button
                variant="outlined"
                color="primary"
                onClick={() => store.handleEditExistingTrackRecordProperty(property, currentIndex)}
                testId='editProperty'
                className={classes.actionButton}
                size='small'
              >
                Edit Property
              </Button>
            }
            <Button
              variant="outlined"
              color="primary"
              onClick={() => openUploadDocument(property.verifiedTrackRecordsId, 'property')}
              testId='uploadDocument'
              className={classes.actionButton}
              size='small'
            >
              Upload Document
            </Button>
          </Grid>
        </Grid>
      ),
    });
    return rows;
  }, []);

  return (
    <Grid container direction='row' spacing={2}>
      <Grid item xs={12} >
        <TrackRecordTable
          isExpandable={true}
          store={store}
          headers={trackRecordPropertiesHeaders}
          rows={trackRecordPropertiesRows ?? []}
          enablePagination={store.properties.length > 5}
        />
      </Grid>
      <FileUploadModal
        open={uploadDocument}
        title={`Upload Document`}
        onClose={() => setUploadDocument(false)}
        onUpload={(files) => handleUploadDocument(currentRowId, files, currentRowType)}
      />
      <PDFPreview
        open={store.isPdfPreviewModalOpen}
        title={store.pdfTitle}
        data={store.pdfData}
        headers={{}}
        fileName={store.pdfTitle}
        onClose={() => {
          store.pdfTitle = null;
          store.pdfData = null;
          store.isPdfPreviewModalOpen = false;
        }}
      />
    </Grid>
  );
});