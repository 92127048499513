import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import { DateField, Paper, TextField } from '@roc/ui';
import { isValid } from 'date-fns';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { useLoanRoutes } from '@roc/feature-loans-routes-config';
import { useLoanStore } from '../../hooks/useLoanStore';
import { LoanDetailsLayout } from '../components';
import RequestKeyDates from '../modules/loanRequests/components/requestKeyDates';
import { usePayoffRequestStore } from './hooks/usePayoffRequestStore';

const PayoffRequest = () => {
  const { loanId } = useParams<{ loanId: string }>();
  const { push } = useHistory();
  const { loanStore } = useLoanStore();
  const { payoffRequestStore } = usePayoffRequestStore();
  const { form } = payoffRequestStore;
  const { loanRoutesConfig } = useLoanRoutes();

  useEffect(() => {
    payoffRequestStore.setSaved(false);
  }, []);

  const manageDate = date => {
    const selectedDate = isValid(date) ? date : null;
    payoffRequestStore.onFieldChange('goodThroughDate', selectedDate);
  };

  const setMaxDate = () => {
    const maxDate = new Date();
    maxDate.setMonth(maxDate.getMonth() + 1);
    return maxDate;
  };

  const isWeekend = (date: Date) => {
    const day = date.getDay();
    return day === 0 || day === 6;
  };

  return (
    <LoanDetailsLayout
      title="Request Payoff"
      subtitle={`Current maturity date is: ${
        loanStore.loanDetails.extendedMaturityDate ??
        loanStore.loanDetails.maturityDate ??
        ''
      }`}
    >
      <Grid container justifyContent="space-around" spacing={2}>
        {payoffRequestStore.saved && (
          <Grid item xs={12}>
            <Alert severity="success">Payoff requested successfully!</Alert>
          </Grid>
        )}
        <Grid item xs={12}>
          <Paper>
            <Grid container>
              <Grid item xs={12} md={6}>
                <Box display="flex" p={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="h5">Payoff Details</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <DateField
                        testId="payoffGoodThroughDate"
                        inputVariant="outlined"
                        format="MM/dd/yyyy"
                        standaloneLabel
                        label="Payoff Good Through Date"
                        value={form.fields.goodThroughDate.value}
                        onChange={date => manageDate(date)}
                        fullWidth
                        disablePast={true}
                        maxDate={setMaxDate()}
                        shouldDisableDate={isWeekend}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        testId="comments"
                        variant="outlined"
                        standaloneLabel
                        label="Comments"
                        value={form.fields.comments.value}
                        onChange={e =>
                          payoffRequestStore.onFieldChange(
                            'comments',
                            e.target.value
                          )
                        }
                        multiline
                        minRows={2}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box display="flex" p={2}>
                  <RequestKeyDates />
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={6} md={2}>
              <Button
                onClick={() => push(loanRoutesConfig.loans(loanId).url)}
                color="primary"
                size="large"
                variant="outlined"
                fullWidth
              >
                Back
              </Button>
            </Grid>
            <Grid item xs={6} md={2}>
              <Button
                disabled={!form.meta.isValid}
                onClick={() => payoffRequestStore.onFormSubmit()}
                color="primary"
                variant="contained"
                size="large"
                fullWidth
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </LoanDetailsLayout>
  );
};

export default observer(PayoffRequest);
