import { FormStore } from '@roc/feature-app-core';
import { action, makeObservable, observable } from 'mobx';

const creditInformationForm = {
  fields: {
    creditReportFileId: {
      value: null,
      error: '',
      rule: 'required',
      message: 'This field is required',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};
export class CreditInformationStore extends FormStore {

  creditReport: File;

  constructor(globalStore) {
    super({ ...creditInformationForm }, globalStore);

    makeObservable(this, {
      creditReport: observable,
      setCreditReport: action,
    });
    this.setDefaults();
  }

  private setDefaults() {
    this.setCreditReport(null);
  }

  setCreditReport(file) {
    this.creditReport = file;
  }

  reset() {
    super.reset();
    this.setDefaults();
  }

}