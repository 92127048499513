import {
  Badge,
  Box,
  Button,
  Chip,
  Fade,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Tooltip,
} from '@material-ui/core';
import {
  withStyles,
} from '@material-ui/core/styles';
import {
  ConditionalWrapper,
  StandardDialog,
} from '@roc/ui';
import { ExpandLess, ExpandMore, FiberNew } from '@material-ui/icons';
import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { ExpandableMenu } from '@roc/ui';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { useUserStore } from '../../hooks';
import { useTechSupportCommunicationStore } from 'libs/feature-communication/src/communication/hooks/useTechSupportCommunicationStore';
import clsx from 'clsx';

interface NavigationMenuProps {
  data: any;
  isCrmPortal?: boolean;
}

interface MobileNavigationProps {
  data: any;
  onMenuItemClick: () => void;
  topMostLevel?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    navButton: {
      height: 60,
      borderRadius: 0,
      padding: theme.spacing(0, 2),
      '&.x-menu-active': {
        '&::after': {
          content: '""',
          position: 'absolute',
          bottom: 0,
          left: 0,
          margin: theme.spacing(0, 2),
          width: `calc(100% - ${2 * theme.spacing(2)}px)`,
          height: '2px',
        },
      },
    },
    navButtonCrmPortal: {
      height: 42,
    },
    newBadge: {
      '& .MuiBadge-badge': {
        top: '8px',
        right: '8px',
        fontSize: '12px',
        height: '16px',
        backgroundColor: '#df0000'
      }
    },
  })
);

const MenuTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    boxShadow: theme.shadows[1],
    fontSize: 11,
    padding: 0,
    marginTop: 0,
    left: 0,
    maxWidth: 'none',
    minWidth: 250,
    borderRadius: 0,
  },
}))(Tooltip);

interface IProps {
  activeClassName: any;
  data: any;
  isCrmPortal?: boolean;
}
const HoverDropdownMenu: React.FC<IProps> = ({ activeClassName, data, isCrmPortal }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const { push } = useHistory();
  const { techSupportCommunicationStore } = useTechSupportCommunicationStore();
  const { userStore } = useUserStore();
  const { userId, companyId } = userStore?.userInformation || {};
  const showMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const hideMenu = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = menu => {
    if (userId) {
      techSupportCommunicationStore.fetchUnreadMessagesCount(userId, companyId);
    }
    if (menu.newTabLink) {
      window.open(menu.newTabLink, "_blank");
    }
    else if (menu.onClick) {
      menu.onClick();
      hideMenu();
    }
    else {
      push(menu.route);
      hideMenu();
    }
  };
  return (
    <div style={{ display: 'inline-block' }}>
      <MenuTooltip
        interactive
        open={menuOpen}
        onClose={hideMenu}
        TransitionComponent={Fade}
        placement="bottom-start"
        title={
          <React.Fragment>
            {data.subMenu.map((menu, index) => {
              return (
                <MenuItem
                  key={menu.title}
                  onClick={() => handleMenuItemClick(menu)}
                  style={
                    { marginRight: 10 }
                  }
                >
                  <ListItemText>
                    <Box display={'flex'}>
                      {menu.title} {menu.isNew && <FiberNew style={{
                        color: '#df0000',
                        marginLeft: 4,
                        position: 'relative',
                        top: -4
                      }} />}
                    </Box>
                  </ListItemText>
                  {menu.newTabLink && (
                    <ListItemIcon>
                      <OpenInNewIcon fontSize="small" />
                    </ListItemIcon>
                  )}
                </MenuItem>
              );
            })}
          </React.Fragment>
        }
      >
        <Button
          className={clsx(classes.navButton, activeClassName, isCrmPortal ? classes.navButtonCrmPortal : '')}
          onMouseOver={showMenu}
          onClick={showMenu}
          endIcon={menuOpen ? <ExpandLess /> : <ExpandMore />}
        >
          {data.title}
        </Button>
      </MenuTooltip>
    </div>
  );
};

export const NavigationMenu = ({ data, isCrmPortal }: NavigationMenuProps) => {
  const classes = useStyles();
  const { push } = useHistory();
  const { pathname } = useLocation();
  const [value, setValue] = React.useState(0);
  const { userStore } = useUserStore();
  const { userId, companyId } = userStore?.userInformation || {};
  const { techSupportCommunicationStore } = useTechSupportCommunicationStore();

  const handleMenuItemClick = menu => {
    if (userId) {
      techSupportCommunicationStore.fetchUnreadMessagesCount(userId, companyId);
    }
    if (menu.newTabLink) {
      window.open(menu.newTabLink, "_blank");
    }
    else if (menu.onClick) {
      menu.onClick();
    }
    else {
      push(menu.route);
    }
  };

  useEffect(() => {
    let isValueSet = false;
    for (let i = 0; i < data.length; i++) {
      if (pathname.indexOf(data[i].route) == 0) {
        setValue(i);
        isValueSet = true;
        break;
      }
      if (data[i].subMenu) {
        for (let j = 0; j < data[i].subMenu.length; j++) {
          if (pathname.indexOf(data[i].subMenu[j].route) == 0) {
            setValue(i);
            isValueSet = true;
            i = data.length; // to break the outer loop
            break;
          }
        }
      }
    }
    if (!isValueSet) setValue(0);
  }, [pathname]);

  const getActiveClassname = index => {
    return value == index ? 'x-menu-active' : '';
  };

  return (
    data.length ?
      <Box mx={2} display="inline-block" alignItems="center" whiteSpace="nowrap">
        {data.map((menu, index) => {
          if (menu.subMenu) {
            return (
              <HoverDropdownMenu
                activeClassName={getActiveClassname(index)}
                data={data[index]}
                key={menu.title}
                isCrmPortal={isCrmPortal}
              />
            );
          } else {
            return (
              <ConditionalWrapper
                condition={menu.showNewBadgeTill && new Date(menu.showNewBadgeTill) >= new Date()}
                wrapper={children => {
                  return (
                    <Badge
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      badgeContent={'New'}
                      color={'error'}
                      className={classes.newBadge}
                    >
                      {children}
                    </Badge>
                  )
                }}>
                <Button
                  key={menu.title}
                  className={clsx(classes.navButton, getActiveClassname(index), isCrmPortal ? classes.navButtonCrmPortal : '')}
                  onClick={() => handleMenuItemClick(menu)}
                  style={{ display: 'inline-block' }}
                >
                  {menu.title}
                </Button>
              </ConditionalWrapper>
            );
          }
        })}
      </Box> : null
  );
};

export const MobileNavigationMenu = ({
  data,
  onMenuItemClick,
  topMostLevel = true,
}: MobileNavigationProps) => {
  const { push } = useHistory();

  const classes = useStyles();

  const handleMenuItemClick = menu => {
    if (menu.newTabLink) {
      window.open(menu.newTabLink, "_blank");
    }
    else if (menu.onClick) {
      menu.onClick();
      onMenuItemClick && onMenuItemClick();
    }
    else {
      push(menu.route);
      onMenuItemClick && onMenuItemClick();
    }
  };

  return (
    <List>
      {data.map((menu, index) => {
        if (menu.subMenu) {
          return (
            <ExpandableMenu
              title={menu.title}
              children={
                <MobileNavigationMenu
                  data={menu.subMenu}
                  onMenuItemClick={onMenuItemClick}
                  topMostLevel={false}
                />
              }
              key={menu.title}
            />
          );
        } else {
          return (
            <ListItem
              button
              key={menu.route}
              onClick={() => handleMenuItemClick(menu)}
              style={topMostLevel ? {
                marginBottom: 16,
                marginRight: 10
              } : {
                marginRight: 10
              }}
            >
              <ListItemText primaryTypographyProps={topMostLevel ? {
                style: {
                  fontWeight: 'bold'
                }
              } : {}} >{menu.title}{menu.isNew && <FiberNew style={{
                color: '#df0000',
                marginLeft: 4,
                position: 'relative',
                top: -4
              }} />}</ListItemText>
              {menu.newTabLink && (
                <ListItemIcon>
                  <OpenInNewIcon fontSize="small" />
                </ListItemIcon>
              )}
            </ListItem>
          );
        }
      })}
    </List>
  );
};
