import { FormStore, GlobalStore } from '@roc/feature-app-core';
import { DialogState } from '@roc/ui';
import { makeAutoObservable, makeObservable, observable, action, flow } from 'mobx';
import { CommunicationService } from './../services/communicationService';
import { CommunicationStore } from './communicationStore';

const REQUIRED_MESSAGE = 'This field is required';

const form = {
  fields: {
    loanId: {
      value: '',
      error: null,
      rule: 'required',
      message: REQUIRED_MESSAGE,
    },
    conversationName: {
      value: '',
      error: null,
      rule: 'required',
      message: REQUIRED_MESSAGE,
    }
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export class CreateNewConversationStore extends FormStore {
  private globalStore: GlobalStore;
  mode: DialogState;
  open = false;
  communicationService: CommunicationService;
  communicationStore: CommunicationStore;

  constructor(globalStore, communicationStore, communicationService) {
    super(form, globalStore);
    this.communicationService = communicationService;
    this.globalStore = globalStore;
    this.communicationStore = communicationStore;
    makeObservable(this, {
      mode: observable,
      open: observable,
      openAdd: action,
      openEdit: action,
      openDialog: action,
      closeDialog: action,
      createNewConversation: flow
    });
  }

  openAdd = () => {
    this.openDialog(DialogState.ADD);
  };

  openEdit = (conversationName: string) => {
    this.openDialog(DialogState.EDIT);
    this.loadForm({
      conversationName
    });
  };

  openDialog = (mode: DialogState) => {
    this.reset();
    this.mode = mode;
    this.open = true;
  };

  closeDialog = () => {
    this.open = false;
  };

  *createNewConversation(onSuccessfulCreation?: (conversationSid: string) => void) {
    const {
      loanId,
      conversationName
    } = this.getFormValues();
    if (this.runFormValidationWithMessage()) {
      this.communicationStore.createNewConversation(loanId, conversationName, (conversationSid) => {
        this.closeDialog();
        onSuccessfulCreation(conversationSid);
      });
    }
  }

}
