import { makeObservable, action, computed, observable } from 'mobx';
import { GlobalStore } from '@roc/feature-app-core';
import { BorrowerFormStore } from './borrowerFormStore';
import { Borrower } from '../types';
import { DialogState } from '@roc/ui';
import { FormStore } from '@roc/feature-app-core';

export class BorrowerFormDialogStore {
  private globalStore: GlobalStore;
  borrowerFormStore: FormStore;
  dialogState: DialogState;

  constructor(globalStore: GlobalStore, borrowerFormStore: BorrowerFormStore) {
    this.borrowerFormStore = borrowerFormStore;
    this.globalStore = globalStore;
    this.dialogState = DialogState.ADD;
    makeObservable(this, {
      reset: action,
      validate: action,
      isValid: computed,
      setBorrower: action,
      getBorrower: action,
      dialogState: observable,
    });
  }

  reset() {
    this.dialogState = DialogState.ADD;
    this.borrowerFormStore.reset();
  }

  validate() {
    this.borrowerFormStore.runFormValidationWithMessage();
  }

  get isValid() {
    return this.borrowerFormStore.form.meta.isValid;
  }

  setBorrower(borrower: Borrower) {
    this.dialogState = DialogState.EDIT;
    Object.entries(borrower).forEach(([field, value]: [string, any]) => {
      if (!this.borrowerFormStore.form.fields[field]) return;
      this.borrowerFormStore.onFieldChange(field, value);
    });
  }

  getBorrower(): Borrower {
    const values = this.borrowerFormStore.getFormValues();
    return values;
  }
}

export default BorrowerFormDialogStore;
