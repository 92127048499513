import { StoreContext } from '@roc/feature-app-core';
import { useContext } from 'react';
import { PayablesDashboardStore } from '../stores/payablesDashboardStore';
import { PayablesApprovedStore } from '../stores/payablesApprovedStore';
import { AttestationDashboardStore } from '../stores/attestationDashboardStore';

export const usePayablesDashboardStore = () =>
  useContext<{
    payablesDashboardStore: PayablesDashboardStore;
    payablesApprovedStore: PayablesApprovedStore;
    attestationDashboardStore: AttestationDashboardStore;
  }>(StoreContext);
