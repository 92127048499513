
import { useState } from 'react';

import { Box, Card, CardContent, CircularProgress, Typography } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import { useTwoFactorStore } from '../hooks/useTwoFactorStore';
import { TwoFactorType } from '../types';
import { TwoFactorOtpInput } from './twoFactorOtp';
import { AlertTitle } from '@material-ui/lab';

const screenNameByTwoFactorType = {
  [TwoFactorType.LENDER_ATTORNIES]: 'Attorneys Dashboard',
  [TwoFactorType.LOGIN]: 'Portal Login',
  [TwoFactorType.LENDERS]: 'Lenders Dashboard',
};

const useStyles = makeStyles((theme) => ({
  cardRoot: {
    width: '100%',
    margin: 'auto',
    boxShadow: 'none',
    maxWidth: 500,
    padding: 24,
    borderRadius: 16,
    border: '1px solid #eee',
    position: 'relative',
  },
  root: {
    width: '100%',
    marginTop: theme.spacing(4),
  },
  button: {
    marginRight: theme.spacing(1),
  }
}));

export const VerifyTwoFactorUI = ({ twoFactorType = TwoFactorType.LOGIN, onSubmit }: {
  twoFactorType?: TwoFactorType,
  onSubmit: (newValue: any) => Promise<boolean>,
}) => {
  const classes = useStyles();
  const [verifying, setVerifying] = useState<boolean>(false);
  const [verified, setVerified] = useState<boolean>(false);

  const handleSubmit = (value) => {
    setVerifying(true);
    setVerified(false);
    return onSubmit(value)
      .then((result) => {
        setVerified(result);
      })
      .finally(() => {
        setVerifying(false);
      });
  }

  return (
    <>
      <Card className={classes.cardRoot}>
        <CardContent>
          <Typography variant="h4" align="center" gutterBottom>
            Two-Factor Authentication
          </Typography>
          <Typography variant='body1' align='center'>{screenNameByTwoFactorType[twoFactorType]}</Typography>
          {
            !verifying && !verified &&
            <div className={classes.root}>
              <Box component="div">
                <Box m="auto" style={{ maxWidth: 385 }}>
                  <Alert severity="info">Enter the verification code generated by the authentication app on your device</Alert>
                </Box>
                <Box m={4} display="flex" justifyContent="center" >
                  <TwoFactorOtpInput onValidate={handleSubmit} />
                </Box>
              </Box>
            </div>
          }
          {
            verifying &&
            <Typography variant="body1" align="center" color='primary'>
              <Box my={4}>
                <Box mb={4}>
                  Verifying
                </Box>
                <CircularProgress color='primary' />
              </Box>
            </Typography>
          }
          {
            verified &&
            <div className={classes.root}>
              <Box component="div">
                <Box m="auto" style={{ maxWidth: 385 }}>
                  <Alert severity="success">
                    <AlertTitle>Success</AlertTitle>
                    Two-Factor verification was successful! <strong>Redirecting ...</strong>
                  </Alert>
                </Box>
              </Box>
            </div>
          }
        </CardContent>
      </Card>
    </>
  );
}


/* export const VerifyTwoFactor = observer(({ twoFactorType, onSuccess, onFailure }: {
  twoFactorType: TwoFactorType,
  onSuccess: () => void,
  onFailure?: () => void,
}) => {
  const { twoFactorStore } = useTwoFactorStore();

  const handleSubmit = (newValue) => {
    const response = twoFactorStore.verifyTwoFactor(twoFactorType, newValue);
    response.then(onSuccess, onFailure);
    return response;
  }

  return (
    <VerifyTwoFactorUI
      twoFactorType={twoFactorType}
      onSubmit={handleSubmit} />
  );
}); */
