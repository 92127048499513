import { GlobalStore } from '@roc/client-portal-shared/stores';
import { downloadDocument } from '@roc/client-portal-shared/utils';
import { action, computed, flow, makeObservable, observable } from 'mobx';
import { FormStore, UserStore } from '@roc/feature-app-core';
import { GridStore } from '@roc/feature-app-core';
import { SelectBorrowersStore } from '@roc/feature-borrowers';
import { ApiResponse } from '@roc/feature-app-core';
import { CreditBackgroundService } from '../services/creditBackgroundService';
import { Borrower } from '@roc/feature-types';
import { LoanParticipantsStore } from '@roc/feature-loan-participants';

export interface CreditBackground {
  requestId: number;
  borrowerId: number;
  envelopId: string;
  borrowerEmail: string;
  borrowerName: string;
  status: string;
  runBackground: boolean;
  runCredit: boolean;
  requestedBy: string;
  createdDate: string;
  lastUpdateDate: string;
  errorReasonMessage: string;
}

const submitLiquidityVerificationForm = {
  fields: {
    __chooseBorrowerFeature__: {
      value: false,
      error: null,
      rule: '',
    },
    email: {
      value: '',
      error: null,
      rule: 'required|email',
    },
    // Borrower Information
    borrower: {
      value: null,
      error: null,
      rule: [{ required_if: ['__chooseBorrowerFeature__', true] }],
      message: 'The borrower selection field is required.',
    },
    originatorId: {
      value: null,
      rule: '',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export class LiquidityVerificationStore extends FormStore {
  private globalStore: GlobalStore;
  private creditBackgroundService: CreditBackgroundService;
  liquidityRequestsGridStore: GridStore;
  selectBorrowersStore: SelectBorrowersStore;
  loanParticipantsStore: LoanParticipantsStore;
  userStore: UserStore;
  newRequestId: number;
  isInternal: boolean;
  requestSuccess: boolean;

  constructor(globalStore: GlobalStore, userStore: UserStore) {
    super({ ...submitLiquidityVerificationForm }, globalStore);
    this.userStore = userStore;
    this.loanParticipantsStore = new LoanParticipantsStore(globalStore);
    this.globalStore = globalStore;
    this.requestSuccess = false;
    this.liquidityRequestsGridStore = new GridStore(() =>
      this.fetchLiquidityGridData()
    );
    this.isInternal = false;
    this.selectBorrowersStore = new SelectBorrowersStore(
      globalStore,
      undefined,
      false,
      undefined,
      x => this.handleBorrowerChange(x)
    );
    this.creditBackgroundService = new CreditBackgroundService();
    makeObservable(this, {
      newRequestId: observable,
      requestSuccess: observable,
      submitLiquidityVerificationRequest: flow,
      updateRequest: flow,
      regenerateReport: flow,
      loadParticipants: flow,
      setRequestSuccess: action,
      isValidForm: computed,
    });
  }

  reset() {
    super.reset();
    this.selectBorrowersStore.reset();
    this.onFieldChange(
      '__chooseBorrowerFeature__',
      this.globalStore.userFeatures.creditBackgroundChooseBorrower
    );
  }

  private handleBorrowerChange(borrowers: Borrower[]) {
    const borrower = borrowers[0] || null;
    this.onFieldChange('borrower', borrower);
    this.onFieldChange('email', borrower?.emailAddress ?? '');
  }

  private async fetchLiquidityGridData() {
    const filters = {
      ...this.liquidityRequestsGridStore.gridData.meta.filters,
      ...this.liquidityRequestsGridStore.gridData.meta.dropdownFilters,
      createdDate: this.formatDateAsISO(
        this.liquidityRequestsGridStore.gridData.meta.filters?.createdDate
      ),
      lastUpdateDate: this.formatDateAsISO(
        this.liquidityRequestsGridStore.gridData.meta.filters?.lastUpdateDate
      ),
    };
    try {
      const response: ApiResponse = await this.creditBackgroundService.getAllLiquidityRequests(
        this.liquidityRequestsGridStore.gridData.meta.pageNumber,
        this.liquidityRequestsGridStore.gridData.meta.pageSize,
        this.liquidityRequestsGridStore.gridData.meta.sortDir,
        this.liquidityRequestsGridStore.gridData.meta.sortBy,
        filters
      );
      return response;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching Plaid liquidity requests',
      });
    }
  }

  private formatDateAsISO(strDate: string) {
    if (strDate) {
      const [mm, dd, yyyy] = strDate.split('/');
      return [yyyy, mm, dd].filter(Boolean).join('-');
    } else {
      return undefined;
    }
  }

  setRequestSuccess(requestSuccess: boolean) {
    this.requestSuccess = requestSuccess;
    if (!requestSuccess) {
      this.newRequestId = null;
    }
  }
  *submitLiquidityVerificationRequest() {
    this.runFormValidationWithMessage();
    if (this.form.meta.isValid) {
      try {
        const {
          borrower,
        }: {
          borrower?: Borrower;
        } = this.getFormValues();
        const response: ApiResponse = yield this.creditBackgroundService.submitLiquidityVerificationRequest(
          {
            borrowerId: Number(borrower.borrowerId),
            borrowerName: `${borrower.firstName} ${borrower.lastName}`,
            email: borrower.emailAddress,
            originatorId: this.form.fields.originatorId.value,
          }
        );
        if (response.data.success) {
          this.globalStore.notificationStore.showSuccessNotification({
            message: 'Liquidity Verification requested successfully.',
          });
        } else {
          this.globalStore.notificationStore.showSuccessNotification({
            message: response.data.responseMessage,
          });
        }
        this.reset();
      } catch (error) {
        console.log(error);
        this.globalStore.notificationStore.showErrorNotification({
          message: 'Error while requesting Liquidity Verification.',
        });
      }
    }
  }
  *updateRequest(record) {
    try {
      const response: ApiResponse = yield this.creditBackgroundService.updateRequest(
        {
          borrowerId: Number(record.borrowerId),
          borrowerName: record.borrowerName,
          email: record.email,
          originatorId: this.form.fields.originatorId.value,
        }
      );
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Update Liquidity Verification requested successfully.',
      });
    } catch (error) {
      console.log(error);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while requesting update Liquidity Verification.',
      });
    }
  }

  *regenerateReport(requestId) {
    try {
      const response: ApiResponse = yield this.creditBackgroundService.regenerateReport(
        requestId
      );
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Report regenerated Successfully',
      });
    } catch (error) {
      console.log(error);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while regenerating report',
      });
    }
  }

  *loadParticipants() {
    yield this.loanParticipantsStore.fetchLoanParticipants();
    if (this.globalStore.userFeatures?.isLenderOriginator) {
      this.onFieldChange(
        'originatorId',
        this.userStore.userInformation.userId
      );
    }
  }

  get isValidForm() {
    return this.form.meta.isValid;
  }
}
