import { useHistory, useLocation } from 'react-router';
import { LoanDetailsTab } from '../utils/loanDetailsTypes';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Tabs } from '@roc/ui';
import { useLoanRoutes } from '@roc/feature-loans-routes-config';
import { useLoanStore } from '@roc/feature-loans';
import {
  insertIf,
  LoanStatus,
  LoanSubType,
  LoanType,
} from '@roc/feature-utils';
import { useLoanFlags } from '../hooks/useLoanFlags';

const useStyles = makeStyles((theme: Theme) => ({
  tabs: {
    border: '0 !important',
    padding: theme.spacing(0, 2),
  },
}));

export const LoanDetailsTabs = () => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const { push } = useHistory();
  const { loanRoutesConfig } = useLoanRoutes();
  const { loanStore } = useLoanStore();
  const {
    showDecisionCommentsTab,
    showDelinquency,
    hideDrawsTab,
    hideExtensionsTab,
    hidePayoffsTab,
    hideLoanSumaryTab,
    hideUnderwritingTab,
    hideClosingTab,
    showContactsTab,
    isTitleAgent,
    showPostClosing,
    isClosingAttorney,
  } = useLoanFlags();
  const loanId = loanStore.loanDetails?.loanId;
  const loanDetails = loanStore.loanDetails;
  const isBridgeLoan = loanDetails?.loanType === LoanType.RESIDENTIAL_BRIDGE;
  const isBridgeLoanExceptStabilizedBridge =
    isBridgeLoan && loanDetails?.loanSubType !== LoanSubType.STABILIZED_BRIDGE;

  const TABS = [
    ...insertIf(!hideLoanSumaryTab, [
      {
        label: 'LOAN SUMMARY',
        value: LoanDetailsTab.LOAN_SUMMARY,
        url: loanRoutesConfig.loans(loanId).children.dashboard.url,
      },
    ]),
    ...insertIf(showDecisionCommentsTab, [
      {
        label: 'DECISION COMMENTS',
        value: LoanDetailsTab.DECISION_COMMENTS,
        url: loanRoutesConfig.loans(loanId).children.decisionTab.url,
      },
    ]),
    ...insertIf(!hideUnderwritingTab, [
      {
        label: 'UNDERWRITING',
        value: LoanDetailsTab.UNDERWRITING,
        url: loanRoutesConfig.loans(loanId).children.underwriting.url,
      },
    ]),
    ...insertIf(!hideClosingTab, [
      {
        label: 'CLOSING',
        value: LoanDetailsTab.CLOSING,
        url: loanRoutesConfig.loans(loanId).children.closing.url,
      },
    ]),
    ...insertIf(isBridgeLoanExceptStabilizedBridge && !hideDrawsTab, [
      {
        label: 'DRAWS',
        value: LoanDetailsTab.DRAWS,
        url: loanRoutesConfig.loans(loanId).children.draws.url,
        disabled: !loanDetails?.showDraws,
        childRoutes: [
          loanRoutesConfig.loans(loanId).children.drawRequest.url,
          loanRoutesConfig.loans(loanId).children.reviseScopeOfWork.url,
        ],
      },
    ]),
    ...insertIf((isBridgeLoan && !hideExtensionsTab) || (isBridgeLoan && isClosingAttorney), [
      {
        label: 'EXTENSIONS',
        value: LoanDetailsTab.EXTENSIONS,
        url: loanRoutesConfig.loans(loanId).children.extensions.url,
        disabled: !loanDetails?.showExtensions,
        childRoutes: [
          loanRoutesConfig.loans(loanId).children.extensionDetails().url,
        ],
      },
    ]),
    ...insertIf((isBridgeLoan && !hidePayoffsTab) || (isBridgeLoan && isClosingAttorney), [
      {
        label: 'PAYOFFS',
        value: LoanDetailsTab.PAYOFFS,
        url: loanRoutesConfig.loans(loanId).children.payoffs.url,
        disabled: !loanDetails?.showPayoffs,
        chilldRoutes: [
          loanRoutesConfig.loans(loanId).children.payoffRequest.url,
        ],
      },
    ]),
    ...insertIf(showPostClosing, [
      {
        label: 'POST CLOSING',
        value: LoanDetailsTab.POST_CLOSING,
        url: loanRoutesConfig.loans(loanId).children.postClosing.url,
      },
    ]),
    ...insertIf(showDelinquency, [
      {
        label: 'DELINQUENCY',
        value: isTitleAgent ? LoanDetailsTab.FORECLOSURE_INVOICE : LoanDetailsTab.FORECLOSURE_REQUEST,
        url: isTitleAgent ? loanRoutesConfig.loans(loanId).children.foreclosureInvoice.url : loanRoutesConfig.loans(loanId).children.foreclosureRequest.url,
      },
    ]),
    ...insertIf(showContactsTab, [
      {
        label: 'CONTACTS',
        value: LoanDetailsTab.CONTACTS,
        url: loanRoutesConfig.loans(loanId).children.loanContacts.url,
      },
    ])
  ];

  const onTabChange = (e, index) => {
    const tab = TABS[index];
    push(tab.url);
  };

  const tabIndex = TABS.findIndex(tab => {
    return (
      pathname.indexOf(tab.url) !== -1 ||
      tab.childRoutes?.some?.(url => pathname.indexOf(url) !== -1)
    );
  });

  return (
    <Tabs
      tabs={TABS}
      className={classes.tabs}
      selectedTab={tabIndex === -1 ? 0 : tabIndex}
      onTabChange={onTabChange}
    />
  );
};
