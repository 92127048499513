import React from 'react';
import {

  Step,
  StepButton,
  StepConnector,
  StepLabel,
  Stepper,
  Typography,

} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { StepTitle } from '@roc/client-portal-shared/components';
import clsx from 'clsx';

export interface StepperComponentProps {
  disableNavigation?: boolean;
  steps: Array<StepObj>;
  activeStep: number;
  handleStep?: (value: number) => void;
  className?: string;
  alternativeLabel?: boolean;
}

export interface StepObj {
  date: string;
  title: string;
  onBack?: () => void;
  onNext?: () => void;
  onSave?: () => void;
  onReprice?: () => void;
  onReject?: () => void;
  allowBack?: boolean;
  allowNext?: boolean;
  allowSave?: boolean;
  allowReprice?: boolean;
  completed?: boolean;
  nextButtonText?: string;
  saveButtonText?: string;
  allowReject?: boolean;
  disableNextButton?: boolean;
  disabledStep?: boolean;
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    stepper: {
      [theme.breakpoints.down('xs')]: {
        '& .MuiStepLabel-label': {
          display: 'none',
        },
        '& .MuiStepLabel-label.MuiStepLabel-active': {
          display: 'block',
        },
      },
    },
  })
);

export const ForeclosureStepper: React.FC<StepperComponentProps> = ({
  disableNavigation,
  steps,
  activeStep,
  handleStep,
  className,
  alternativeLabel
}) => {
  const classes = useStyle();
  return (
    <Stepper
      activeStep={activeStep}
      connector={<StepConnector />}
      className={clsx([classes.stepper, className])}
      alternativeLabel={alternativeLabel}
      nonLinear={true}
    >
      {steps.map((step, index) => {
        const isCompleted = step.completed && step.date && true;
        return (
          <Step key={`${step.title}-${index}`} completed={isCompleted}>

            <StepButton
              disabled={disableNavigation}
              onClick={() => handleStep && handleStep(index)}
            >
              <StepLabel>
                {step.date && (
                  <>
                    <Typography variant="subtitle1">{step.date}</Typography>
                  </>
                )}
                <StepTitle active={activeStep == index}>
                  {step.title}
                </StepTitle>
              </StepLabel>
            </StepButton>
          </Step>
        );
      })}
    </Stepper>
  );
};
