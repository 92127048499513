import { Service } from '@roc/feature-app-core';
import { addQueryParam } from '@roc/feature-utils';

const url = {
  GET_SCOPES_OF_WORK: '/api/v1/loan/scopeOfWork/getScopesOfWork',
  GET_UNASSIGNED_SCOPES_OF_WORK:
    '/api/v1/loan/scopeOfWork/getUnassignedScopesOfWork',
  GET_SCOPE_OF_WORK: '/api/v1/loan/scopeOfWork/getScopeOfWork',
  LINK_STANDALONE_SCOPE_OF_WORK:
    '/api/v1/loan/scopeOfWork/linkStandaloneScopeOfWork',
  LINK_NEW_SCOPE_OF_WORK: '/api/v1/loan/scopeOfWork/linkNewScopeOfWork',
};

export class ScopeOfWorkService extends Service {
  protected url: any;

  constructor() {
    super();
    this.url = url;
  }

  getScopesOfWork(
    page: number,
    pageSize: number,
    sortDir: string,
    sortBy: string,
    filters: any,
    dropdownFilters: any
  ) {
    return this.get(
      this.url.GET_SCOPES_OF_WORK,
      {
        page,
        pageSize,
        sortDir,
        sortBy,
        filters,
        dropdownFilters,
      },
      {
        disableGlobalLoading: true,
      }
    );
  }

  getUnassignedScopesOfWork(
    page: number,
    pageSize: number,
    sortDir: string,
    sortBy: string,
    filters: any,
    dropdownFilters: any
  ) {
    return this.get(
      this.url.GET_UNASSIGNED_SCOPES_OF_WORK,
      {
        page,
        pageSize,
        sortDir,
        sortBy,
        filters,
        dropdownFilters,
      },
      {
        disableGlobalLoading: true,
      }
    );
  }

  getScopeOfWork(loanId: number, propertyId: number) {
    return this.get(this.url.GET_SCOPE_OF_WORK, {
      loanId,
      propertyId,
    });
  }

  linkStandaloneScopeOfWork(
    loanId: number,
    propertyId: number,
    scopeOfWorkId: number
  ) {
    let params = addQueryParam('', 'loanId', loanId);
    params = addQueryParam(params, 'propertyId', propertyId);
    params = addQueryParam(params, 'scopeOfWorkId', scopeOfWorkId);
    return this.post(
      `${this.url.LINK_STANDALONE_SCOPE_OF_WORK}${params ? `?${params}` : ''}`,
      {
        loanId,
        propertyId,
        scopeOfWorkId,
      }
    );
  }

  linkNewScopeOfWork(loanId: number, propertyId: number) {
    let params = addQueryParam('', 'loanId', loanId);
    params = addQueryParam(params, 'propertyId', propertyId);
    return this.post(
      `${this.url.LINK_NEW_SCOPE_OF_WORK}${params ? `?${params}` : ''}`,
      {
        loanId,
        propertyId,
      }
    );
  }
}
