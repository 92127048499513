import { FormStore } from '@roc/feature-app-core';
import { GlobalStore } from '@roc/feature-app-core';
import { GENERIC_ERROR_MESSAGE } from '@roc/feature-utils';
import { action, flow, makeObservable, observable, override } from 'mobx';
import { OneToolService } from '../../services/oneToolService';
import { QuoteTypeStore } from '../quoteTypeStore';
import { PreflightStep, QuoteStep } from '../../utils/constants';

const borrowerEmailForm = {
  fields: {
    loanComments: {
      value: '',
      error: null,
      rule: '',
    },
    leverageExceptionComments: {
      value: '',
      error: null,
      rule: 'required_if_true:isLeverageException',
      message: 'This field is required',
    },
    isLeverageException: {
      value: false,
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export class LoanCommentsFormStore extends FormStore {
  private globalStore: GlobalStore;
  private quoteTypeStore: QuoteTypeStore;
  private oneToolService: OneToolService;

  showLoanCommentsDialog = false;

  constructor(globalStore, quoteTypeStore: QuoteTypeStore) {
    super(borrowerEmailForm, globalStore);
    this.globalStore = globalStore;
    this.quoteTypeStore = quoteTypeStore;
    this.oneToolService = new OneToolService();
    makeObservable(this, {
      showLoanCommentsDialog: observable,
      closeDialog: action,
      saveComments: flow,
      reset: override,
    });
  }

  reset() {
    super.reset();
    this.showLoanCommentsDialog = false;
  }

  openDialog() {
    this.showLoanCommentsDialog = true;
    const isLeverageException = this.quoteTypeStore.loanEconomicsStore
      .enableManualQuote;
    this.onFieldChange('isLeverageException', isLeverageException);
  }

  closeDialog() {
    this.showLoanCommentsDialog = false;
  }

  *saveComments() {
    try {
      if (this.runFormValidationWithMessage()) {
        this.quoteTypeStore.preflightStore.resetPreflightSteps();
        yield this.quoteTypeStore.goToStep(QuoteStep.PREFLIGHT);
        this.showLoanCommentsDialog = false;
      }
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE,
      });
    }
  }
}
