import { Box } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: 50,
    height: 50,
    backgroundColor: '#E4E8EB',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50px',
    boxShadow: '0px 3px 5px -1px #00000033',
  },
}));

export const PercentageIcon = () => {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
      >
        <path
          d="M3.9375 0C1.76625 0 0 1.76625 0 3.9375C0 6.10875 1.76625 7.875 3.9375 7.875C6.10875 7.875 7.875 6.10875 7.875 3.9375C7.875 1.76625 6.10875 0 3.9375 0ZM3.9375 5.625C3.00375 5.625 2.25 4.87125 2.25 3.9375C2.25 3.00375 3.00375 2.25 3.9375 2.25C4.87125 2.25 5.625 3.00375 5.625 3.9375C5.625 4.87125 4.87125 5.625 3.9375 5.625ZM14.0625 10.125C11.8912 10.125 10.125 11.8912 10.125 14.0625C10.125 16.2337 11.8912 18 14.0625 18C16.2337 18 18 16.2337 18 14.0625C18 11.8912 16.2337 10.125 14.0625 10.125ZM14.0625 15.75C13.1287 15.75 12.375 14.9963 12.375 14.0625C12.375 13.1287 13.1287 12.375 14.0625 12.375C14.9963 12.375 15.75 13.1287 15.75 14.0625C15.75 14.9963 14.9963 15.75 14.0625 15.75ZM1.58625 18L0 16.4137L16.4137 0L18 1.58625L1.58625 18Z"
          fill="black"
        />
      </svg>
    </Box>
  );
};
