import { Box, Card, CardContent, CardMedia, Container, Divider, FormControl, FormControlLabel, Grid, List, Paper, Radio, RadioGroup, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { observer } from "mobx-react"
import { House, Group } from '@material-ui/icons';
import { useEffect, useState } from "react";
import { listItem } from "@roc/feature-draw-dq-data";
import { Button, SelectField, StandardDialog, Tabs, TestDataAttribute } from "@roc/ui";
import { useSendgridTemplatesStore } from "../hooks/useSendgridTemplatesStore";
import { isNotBlank } from "@roc/feature-utils";
import { reaction } from "mobx";
import { Layout } from "@roc/feature-app-core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textBold: {
      fontWeight: 700,
    },
    table: {
      '& table tr:last-child td': {
        borderBottom: 'none',
      },
    },
    paper: {
      padding: theme.spacing(2),
      minHeight: 620
    },
    tableRow: {
      minHeigh: '20vh',
    }, card: {
      height: '600px',
    },
    media: {
      height: 'inherit',
      backgroundSize: 'auto'

    },
  })
);

const TABS = [
  {
    label: 'Civic Setup',
  },
  {
    label: 'Faco Setup',
  },
  {
    label: 'Roc Setup',

  }
];

export const AssignEmailTemplatesScreen = observer(() => {
  const { sendgridTemplatesStore } = useSendgridTemplatesStore();
  const { assignedTemplates } = sendgridTemplatesStore;
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const onTabChange = (e, index) => setSelectedTab(index);
  const rows = [
    { id: 'TPOLead', name: 'TPO Lead' },
    { id: 'BorrowerLead', name: 'Borrower Lead' },
    { id: 'LenderPrincipalContact', name: 'Lender Principal Contact' },
    { id: 'BorrowerContact', name: 'Borrower Contact' },
    { id: 'CorporateTPOLead', name: 'Corporate TPO Lead' },
    { id: 'CorporateBorrowerLead', name: 'Corporate Borrower Lead' },
    { id: 'Corporate LenderPrincipalContact', name: 'Corporate Lender Principal Contact' },
    { id: 'CorporateBorrowerContact', name: 'Corporate Borrower Contact' },
  ];
  const fetchData = async () => {
    await sendgridTemplatesStore.getAllAssignedTemplates();
    setIsDataLoaded(true);
  };

  useEffect(() => {
    fetchData();
    sendgridTemplatesStore.getAllTemplateOptions();

    const templateAssignmentReaction = reaction(
      () => sendgridTemplatesStore.isTemplateAssigned,
      (isAssigned) => {
        if (isAssigned) {
          fetchData();
          sendgridTemplatesStore.isTemplateAssigned = false;
        }
      }
    );

    return () => {
      templateAssignmentReaction();
    };
  }, [sendgridTemplatesStore]);

  return (
    <>
      {isDataLoaded && (
        <Container maxWidth={'md'}>
          <Layout title="Templates setup">
            <Tabs
              tabs={TABS}
              selectedTab={selectedTab}
              onTabChange={onTabChange}
            />
            {selectedTab === 0 && <AssignEmailTemplatesBrandCard brand="Civic" rows={rows} assignedTemplates={assignedTemplates} />}
            {selectedTab === 1 && <AssignEmailTemplatesBrandCard brand="Faco" rows={rows} assignedTemplates={assignedTemplates} />}
            {selectedTab === 2 && <AssignEmailTemplatesBrandCard brand="Roc" rows={rows} assignedTemplates={assignedTemplates} />}
          </Layout>
        </Container>
      )}
    </>
  );
});

export const AssignEmailTemplatesBrandCard = ({ brand, rows, assignedTemplates }) => {
  const { textBold, table, paper, tableRow } = useStyles();
  const [openAssignEmailTemplate, setOpenAssignEmailTemplate] = useState(false);
  const [selectedAudience, setSelectedAudience] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState(null);


  const handleOpenEmailTemplateAssignment = (audience, template) => {
    setSelectedTemplate(template)
    setSelectedAudience(audience);
    setOpenAssignEmailTemplate(true);
  };

  const handleCloseEmailTemplateAssignment = () => {
    setSelectedAudience(null);
    setOpenAssignEmailTemplate(false);
  };

  const getTemplateName = (assignedTemplates, row, brand) => {
    const matchingTemplate = assignedTemplates.find(
      template => template.audience === row.id && template.brand === brand.toLowerCase()
    );
    return matchingTemplate ? matchingTemplate.name : "-";
  };

  const getTemplateId = (assignedTemplates, row, brand) => {
    const matchingTemplate = assignedTemplates.find(
      template => template.audience === row.id && template.brand === brand.toLowerCase()
    );
    return matchingTemplate ? matchingTemplate.templateId : null;
  };


  return (
    <Grid item xs={12}>
      <Paper className={paper} >
        <Box p={2}>
          <Typography variant="subtitle1" className={textBold}>
            {brand} templates
          </Typography>
        </Box>
        <Box p={2}>
          <Typography>
            Please choose an existing marketing email template to be sent to each audience.
          </Typography>
        </Box>
        <div className={table}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Audience</TableCell>
                <TableCell>Selected template</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.id} className={tableRow}>
                  <TableCell>{row.name}</TableCell>
                  <TestDataAttribute id={`roc_${brand}_${row.id}`}>
                    <TableCell align="left">
                      {getTemplateName(assignedTemplates, row, brand)}
                    </TableCell>
                  </TestDataAttribute>
                  <TestDataAttribute id={`roc_${brand}_${row.id}_percentage`}>
                    <TableCell align="right">
                      <Box mr={2} mb={2} sx={{ marginTop: '15px' }} display="flex" justifyContent="flex-end">
                        <Button
                          variant="contained"
                          size="small"
                          onClick={() => handleOpenEmailTemplateAssignment(row.name, getTemplateId(assignedTemplates, row, brand))}
                          color="primary"
                          testId="emailTemplate"
                        >
                          Assign Template
                        </Button>
                      </Box>
                    </TableCell>
                  </TestDataAttribute>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </Paper>
      <EmailTemplateAssignmentDialog
        open={openAssignEmailTemplate}
        onClose={handleCloseEmailTemplateAssignment}
        audience={selectedAudience}
        brand={brand}
        template={selectedTemplate}
      />
    </Grid>
  );
};

export const EmailTemplateAssignmentDialog = ({ open, onClose, brand, audience, template }) => {
  const { sendgridTemplatesStore } = useSendgridTemplatesStore();
  return (
    <StandardDialog
      open={open}
      title={`Assign email template for ${brand} ${audience}`}
      maxWidth="md"
      handleClose={onClose}
      dialogContent={<SelectTemplateDialogContent store={sendgridTemplatesStore} onClose={() => onClose()} brand={brand} audience={audience} template={template} />}
    />

  );
};


const SelectTemplateDialogContent = observer(({ store, onClose, brand, audience, template }) => {
  const [selectedTemplatePreview, setSelectedTemplatePreview] = useState("");
  const formFields = store.form.fields;
  const { card, media } = useStyles();

  const templateOptions = store.templateOptions
    .filter(template => template.brand === brand.toLowerCase())
    .map(filteredTemplate => ({
      value: filteredTemplate.id,
      label: filteredTemplate.name
    }));

  const handleChange = (value) => {
    store.onFieldChange('templateId', value);
    const selectedTemplate = store.templateOptions.find(
      (template) => template.id === value
    );
    setSelectedTemplatePreview(selectedTemplate.versions[0].thumbnail_url);
  }

  const handleConfirmClick = () => {
    store.assignTemplate(formFields.templateId.value, brand, audience);
    store.reset();
    onClose()
  }

  useEffect(() => {
    if (template != null) {
      console.log(template)
      store.form.fields.templateId.value = template;
      const selectedTemplate = store.templateOptions.find(
        (i) => i.id === template
      );
      setSelectedTemplatePreview(selectedTemplate.versions[0].thumbnail_url);
    }
  }, []);


  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        <Box pb={2}>
          <SelectField
            standaloneLabel
            label={'Templates'}
            name={'templateId'}
            value={formFields.templateId.value}
            errorText={formFields.templateId.error}
            error={isNotBlank(formFields.templateId.error)}
            options={templateOptions}
            onChange={handleChange}
            variant="outlined"
            fullWidth
            shrink
            required
            testId="templateId"
          />
        </Box>
      </Grid>
      {selectedTemplatePreview &&
        <Grid item xs={12}>
          <Card className={card}>
            <CardMedia
              className={media}
              image={selectedTemplatePreview}
            />
          </Card>
        </Grid>
      }
      <Grid item container xs={12} spacing={1} justifyContent="flex-end" style={{ marginTop: '16px' }}>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={onClose}
            testId="close"
          >
            Close
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={handleConfirmClick}
            testId="confirm"
          >
            Confirm
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
});
