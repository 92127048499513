import React, { PureComponent } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label,
  ComposedChart,
} from 'recharts';
import { useTheme, withTheme } from '@material-ui/core/styles';

export const QuoteChart = ({ chartData = [] }) => {
  const theme = useTheme();
  return (
    <ResponsiveContainer width="100%" aspect={2}>
      <ComposedChart
        width={500}
        height={300}
        data={chartData}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="borrowerRate">
          <Label value={'Borrower Rate'} position={'centerBottom'} dy={14} />
        </XAxis>
        <YAxis dataKey="yourYieldSpread">
          <Label value={'Yield Spread'} position={'left'} angle={-90} dx={-3} />
        </YAxis>
        <Tooltip />
        <Line
          key={chartData['yourYieldSpread']}
          dataKey={'yourYieldSpread'}
          stroke={theme.palette.primary.main}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
};