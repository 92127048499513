import { formatCurrency, formatPercentage } from '@roc/ui/utils';
import { observer } from 'mobx-react';
import {
  ItemDisplay,
  LeverageCard,
  LeverageCardRow,
  PercentagePicker,
} from './leverageComponents';
import { LeverageCardProps } from '../leverageStep';

export const StabilizedBridgeLeverageCard = observer(
  ({ store, disabled, instaQuote = false, showEmptyValues = false, quoteTypeStore }: LeverageCardProps) => {
    const calculated = store.calculatedFields;
    const ranges = store.ranges;
    return (
      <LeverageCard
        title={
          instaQuote ? 'Insta-Leverage Quote' : 'Request Leverage'
        }
        disabled={disabled}
        instaQuote={instaQuote}
        LeverageMatrixCard={undefined}
        quoteTypeStore={quoteTypeStore}
        disabledWarning={showEmptyValues}
      >
        <LeverageCardRow
          dividend={
            <ItemDisplay
              label={'Loan Amount'}
              value={showEmptyValues ? '-' : formatCurrency(calculated.totalLoanAmount)}
              instaQuote={instaQuote}
            />
          }
          divisor={
            <ItemDisplay
              label={quoteTypeStore.getCostBasisLabel()}
              value={showEmptyValues ? '-' : formatCurrency(calculated.totalPurchasePrice)}
            />
          }
          result={
            <PercentagePicker
              fieldName={'loanToCostPercentage'}
              label="Loan to Cost"
              disabled={disabled}
              instaQuote={instaQuote}
              value={showEmptyValues ? 0 : store.form.fields.loanToCost.value}
              min={ranges?.minLoanToCost}
              max={ranges?.maxLoanToCost}
              onChange={value => store.onFieldChange('loanToCost', value)}
            />
          }
        />
      </LeverageCard>
    );
  }
);
