import { Avatar, Box, Grid, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Launch, Room } from '@material-ui/icons';
import {
  StabilizedBridgePropertiesGrid,
  TermPropertiesGrid,
  useLoanStore,
} from '@roc/feature-loans';
import { BooleanChip, GoogleMap, IconButton, StandardDialog } from '@roc/ui';
import { formatCurrency as _formatCurrency } from '@roc/ui/utils';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { CustomCard, CustomCardDividers, CustomCardRow } from '../customCard';

const formatCurrency = value => (value ? _formatCurrency(value) : null);

const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
  icon: {
    color: theme.palette.text.secondary,
    fontSize: '1.25rem',
  },
  mapStyles: {
    position: `relative`,
    width: `100%`,
    height: `400px`,
  },
}));

export const TermPropertiesGridCard = observer(() => {
  const { loanStore } = useLoanStore();
  const { loanDetails } = loanStore;

  useEffect(() => {
    loanStore.setPropertiesData(loanDetails.properties);
    loanStore.propertiesGridStore.fetchGridData();
  }, [loanDetails?.loanId]);

  return (
    <CustomCard title="Properties">
      <TermPropertiesGrid />
    </CustomCard>
  );
});

export const StabilizedBridgePropertiesGridCard = observer(() => {
  const { loanStore } = useLoanStore();
  const { loanDetails } = loanStore;

  useEffect(() => {
    loanStore.setPropertiesData(loanDetails.properties);
    loanStore.propertiesGridStore.fetchGridData();
  }, [loanDetails?.loanId]);

  return (
    <CustomCard title="Properties">
      <StabilizedBridgePropertiesGrid />
    </CustomCard>
  );
});
