import { observer } from 'mobx-react-lite';
import { AddMailDialog } from './addMailDialog';
import { MailingListsGrid } from './mailingListsGrid';

export const MailingLists = observer(() => {
  return (
    <>
      <MailingListsGrid />
      <AddMailDialog />
    </>
  );
});
