import React, { useMemo, useEffect, useState } from 'react';
import { IGetRowsParams, IDatasource } from '@roc/ui';
import { DataGrid as Grid, GridProps } from '@roc/ui';
import { GridStore } from '@roc/feature-app-core';
import { observer } from 'mobx-react';

type GetRowsResponse = {
  rows: any[];
  count: number;
};

class InfiniteScrollDatasource implements IDatasource {
  _getRowsBatch: (params: IGetRowsParams) => Promise<GetRowsResponse>;

  constructor(
    getRowsBatch: (params: IGetRowsParams) => Promise<GetRowsResponse>
  ) {
    this._getRowsBatch = getRowsBatch;
  }

  async getRows(params: IGetRowsParams) {
    const { rows, count } = await this._getRowsBatch(params);
    params.successCallback(rows, count);
  }
}

export type InfiniteScrollGridProps = Omit<GridProps, 'rows'> & {
  store: GridStore;
};

export const InfiniteScrollGrid = observer((props: InfiniteScrollGridProps) => {
  const { store, ...rest } = props;
  const [datasource, setDatasource] = useState(null);

  useEffect(() => {
    if (!store.gridData.data.totalCount) {
      setDatasource(new InfiniteScrollDatasource(store.getRowsBatch));
    }
  }, [store.gridData.data.totalCount]);

  const onSortChanged = params => {
    const sortModel = params.api.getSortModel();
    store.setSortModel(sortModel, false);
  };

  const onFilterChanged = params => {
    const filterModel = params.api.getFilterModel();
    store.setFilterModel(filterModel, false);
  };

  useEffect(() => {
    return () => {
      store.reset();
    };
  }, []);

  return (
    <Grid
      {...rest}
      rows={store.gridData.data.rows}
      isLoading={store.isLoading}
      showFilters={store.showFilters}
      sortModel={store.sortModel}
      filterModel={store.filterModel}
      paginationData={store.gridData.meta}
      setPageNumber={store.setPageNumber}
      datasource={datasource}
      showPagination={false}
      onSortChanged={onSortChanged}
      onFilterChanged={onFilterChanged}
    />
  );
});
