import { StandardDialog } from '@roc/ui';
import { Document } from '@roc/feature-types';
import RateLockExtension from './rateLockExtension';
import { useBaseStore } from '@roc/feature-app-core';
import { useDocumentStore } from '../../../hooks/useDocumentStore';
import { observer } from 'mobx-react';
export type RateLockExtendionDialogProps = {
  open?: boolean;
  onClose?: () => void;
  loan: any;
  document: Document;
};

export const RateLockExtensionDialog = observer((props: RateLockExtendionDialogProps) => {
  const { open, onClose, loan, document } = props;
  const { globalStore } = useBaseStore();
  const { documentStore } = useDocumentStore();
  const { documentFormStore } = documentStore;
  const { termSheetStore } = documentFormStore;

  const downloadRateLockAgreement = () => {
    termSheetStore.downloadTermSheet(document);
  };

  const closeModal = () => {
    return documentFormStore.setCurrentForm(null);
  }

  const showErrorNotification = (error) => {
    globalStore.notificationStore.showErrorNotification({
      message: error,
    });
  }

  return (
    <StandardDialog
      open={open}
      title="Rate Lock Extension Options"
      maxWidth="md"
      handleClose={onClose}
      dialogContent={
        <RateLockExtension
          document={document}
          downloadRateLockAgreement={downloadRateLockAgreement}
          loan={loan}
          closeModal={closeModal}
          showErrorNotification={showErrorNotification}
        />
      }
    />
  );
});

export default RateLockExtensionDialog;
