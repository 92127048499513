import { GlobalStore } from "@roc/feature-app-core";
import { makeObservable, observable } from "mobx";

export abstract class ReferralFeeStore {
  public globalStore: GlobalStore;
  public referralLink: string;
  public bankInfo: any;

  constructor(globalStore: GlobalStore) {
    this.globalStore = globalStore;
    makeObservable(this, {
      referralLink: observable,
      bankInfo: observable
    });
  }

  abstract getReferralFeeLink(loanId: string): void;
  abstract getBankInformation(referrerId: string): void;
  abstract approveBankInformation(referrerId: string, callback: () => void): void;
}