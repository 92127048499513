import { Service } from '@roc/feature-app-core';
import { addQueryParam } from '@roc/feature-utils';

const url = {
  GET_SELF_INSPECTION_IMAGES: '/api/v1/draws/getDrawPhotos',
  GET_DRAW_REQUEST: '/api/v1/draws/getRequestDraw',
};

export class DrawReviewReportService extends Service {

  getDrawRequest(drawId: number) {
    return this.get(`${url.GET_DRAW_REQUEST}?drawId=${drawId}`);
  }
  getImages(loanId: number, drawId: number) {
    return this.get(`${url.GET_SELF_INSPECTION_IMAGES}?loanId=${loanId}&drawId=${drawId}`);
  }
}
