import { Grid, IconButton, Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { Delete, Edit } from '@material-ui/icons';
import { Alert, AlertTitle } from '@material-ui/lab';
import { AgGridColumnProps, Card } from '@roc/ui';
import { formatCurrency, noOpComparator } from '@roc/ui/utils';
import { AgGridReact } from 'ag-grid-react/lib/agGridReact';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import {
  AnnualExpenses,
  AnnualIncome,
  AnnualNoi,
} from '../../components/formComponents';
import { RentalPortfolioStore } from '../../stores';
import PropertyDetailsModal, { PropertyModalState } from './propertyDetailsModal';
import { PropertySteps } from './propertySteps';

interface PropertyInformationPortfolioProps {
  store: RentalPortfolioStore;
}

const usdAmount: any = params => {
  return formatCurrency(Number(params.value ?? 0));
};

const hasAddress = params => {
  if (!params.node.rowPinned) {
    return params.data.address ? params.data.address : 'Pending';
  }
}

const propertyColumns: AgGridColumnProps[] = [
  {
    field: 'useCode',
    headerName: 'Property Type',
    maxWidth: 200,
    sortable: true,
    sort: 'desc',
    comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
      if (valueA == valueB) return 0;
      return (valueA > valueB) ? 1 : -1;
    },
  },
  {
    field: 'address',
    headerName: 'Property Address',
    minWidth: 250,
    valueGetter: hasAddress,
  },
  {
    field: 'purchasePrice',
    headerName: 'Purchase Price',
    minWidth: 250,
    valueFormatter: usdAmount,
    comparator: noOpComparator,
  },
  {
    field: 'estimatedAsIsValue',
    headerName: 'Estimated As-Is Value',
    minWidth: 250,
    valueFormatter: usdAmount,
    comparator: noOpComparator,
  },
  {
    field: 'propertyId',
    headerName: 'Actions',
    maxWidth: 120,
    cellRendererSelector: function (params) {
      if (!params.node.rowPinned) {
        return {
          component: 'actionsCellRenderer'
        }
      }
    },
    comparator: noOpComparator,
  },
]

const useStyles = makeStyles(theme => ({
  grid: {
    width: '100%',
    height: '100%',
    '& .ag-root-wrapper': {
      borderColor: '#ddd',
    },
  },
}));

const getRowStyle = params => {
  if (params.node.rowPinned) {
    return { 'font-weight': 'bold' }
  }
}

const PropertyTypesActions = ({ onEdit, onDelete, ...rest }) => {
  return (
    <Grid>
      <IconButton onClick={() => onEdit(rest)}>
        <Edit fontSize="small" />
      </IconButton>
      <IconButton onClick={() => onDelete(rest)}>
        <Delete fontSize="small" />
      </IconButton>
    </Grid>
  );
};

export const PropertyInformationPortfolio = observer(
  ({ store }: PropertyInformationPortfolioProps) => {
    const { pricerStore } = store;
    const classes = useStyles();
    const propertyData = pricerStore.getProperty();
    const [modalState, setModalState] = useState<PropertyModalState>(null);

    useEffect(() => {
      store.checkSmallPortfolio();
      store.setPropertiesDetails(store.properties);
    }, []);

    useEffect(() => {
      store.checkPropertyErrors();
    }, [store.propertiesDetails]);

    const onEdit = property => {
      setModalState(PropertyModalState.EDIT);
      store.propertyDetailsStore.setPropertyId(property.propertyId);
      store.propertyDetailsStore.loadProperty(property, false);
    };

    const onDelete = ({ propertyId, useCode }) => {
      store.deletePropertyDetails(propertyId, useCode);
    };

    const closeModal = () => {
      setModalState(null);
      store.propertyDetailsStore.reset();
    };

    return (
      <>
        {!store.isSmallPortfolio ?
          <>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h4">Cash Flow Information</Typography>
                <br />
              </Grid>
            </Grid>

            <br />

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Card title={''}>
                  <Grid item container direction="row" spacing={2}>
                    <Grid item xs={6} md={3}>
                      <Typography>
                        <b>Gross Monthly Rent</b>
                      </Typography>
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <Typography>
                        {formatCurrency(propertyData.monthlyGrossRent)}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <Typography>
                        <b>Total Gross Annual Taxes</b>
                      </Typography>
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <Typography>
                        {formatCurrency(propertyData.annualTaxes)}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <Typography>
                        <b>Total Gross Annual Insurance</b>
                      </Typography>
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <Typography>
                        {formatCurrency(propertyData.annualInsurance)}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <Typography>
                        <b>Total Annual HOA Dues</b>
                      </Typography>
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <Typography>
                        {formatCurrency(propertyData.annualHOA)}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <Typography>
                        <b>Total Annual Utilities</b>
                      </Typography>
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <Typography>
                        {formatCurrency(propertyData.totalAnnualUtilities)}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <Typography>
                        <b>Total Annual Repairs & Maintenance</b>
                      </Typography>
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <Typography>
                        {formatCurrency(propertyData.totalAnnualRepairsMaintenance)}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <Typography>
                        <b>Total Annual Property Management Fees</b>
                      </Typography>
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <Typography>
                        {formatCurrency(
                          propertyData.totalAnnualPropertyManagementFees
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            </Grid>

            <br />

            <Grid
              container
              direction="row"
              spacing={4}
              style={{
                width: '100%',
                border: 'solid 3px',
                margin: '32px 0px 32px 0px',
                borderColor: '#CECECE',
                borderRadius: '8px',
                padding: '3vw 5vw',
              }}
            >
              <AnnualIncome store={store} />
              <AnnualExpenses store={store} />
              <AnnualNoi store={store} required={false} />
            </Grid>
          </>
          :
          <Grid container spacing={4}>
            {store.propertyInformationErrors.length > 0 && (
              <>
                <Grid xs={12}>
                  <Alert severity='error'>
                    <AlertTitle>ERRORS</AlertTitle>
                    {store.propertyInformationErrors.map(error => (
                      <Grid>{error}</Grid>
                    ))}
                  </Alert>
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <Typography variant='h4'>Property Information</Typography>
              <Typography variant="body1">Please add the property details for each of the properties that are part of the portfolio.</Typography>
            </Grid>
            <br />
            <Grid item xs={12}>
              <Typography variant='h6'>Portfolio's Property Details</Typography>
              <br />
              <Grid item className={`ag-theme-alpine ${classes.grid}`}>
                <AgGridReact
                  defaultColDef={{
                    flex: 1,
                    minWidth: 100,
                    sortable: false,
                    resizable: false,
                    suppressMenu: true,
                  }}
                  columnDefs={propertyColumns}
                  rowData={[...store.propertiesDetails]}
                  domLayout="autoHeight"
                  suppressCellSelection={true}
                  frameworkComponents={{
                    actionsCellRenderer: ({ data }) => (
                      <PropertyTypesActions
                        onEdit={onEdit}
                        onDelete={onDelete}
                        {...data}
                      />
                    ),
                  }}
                  getRowStyle={getRowStyle}
                  getRowHeight={() => {
                    return 48;
                  }}
                  pinnedBottomRowData={store.propertyDetailsSummary}
                />
              </Grid>
            </Grid>
          </Grid>
        }
        <PropertyDetailsModal
          modalState={modalState}
          open={modalState != null}
          handleClose={closeModal}
          handleEdit={() => {
            (store.pricerStore.getLoanPurpose() === 'Purchase') ?
              store.updatePropertyDetails(store.propertyDetailsStore.getPropertyPurchase())
              :
              store.updatePropertyDetails(store.propertyDetailsStore.getProperty());
            store.updatePropertyDetailsSummary();
            store.checkPropertyErrors();
            closeModal();
          }}
          renderSteps={(modalState, handleEdit) => (
            <PropertySteps
              modalState={modalState}
              handleEdit={handleEdit}
            />
          )}
        />
      </>
    );
  }
);
