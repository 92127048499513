import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DeleteOutline } from '@material-ui/icons';
import {
  ConfirmationIconButton
} from '@roc/ui';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { useGeneralTasksStore } from '../../hooks/useGeneralTasksStore';
import {
  GeneralTaskAttachment
} from '../../types/generalTasksTypes';

const useStyles = makeStyles(theme => ({
  container: {
    borderRadius: theme.spacing(1),
    border: '1px solid rgba(0, 0, 0, 0.12)',
  },
  link: {
    color: theme.palette.primary.dark,
    textDecoration: 'underline'
  }
}));

export const Attachment = observer(
  ({ attachment }: { attachment: GeneralTaskAttachment }) => {
    const classes = useStyles();
    const { generalTasksStore } = useGeneralTasksStore();
    const { editTaskStore, assigneeOptions } = generalTasksStore;
    const { attachmentStore } = editTaskStore;

    const onRemove = () => {
      attachmentStore.removeAttachment(attachment);
    };

    const handlePreview = () => {
      if(attachment.attachmentName.endsWith('.pdf')){
        attachmentStore.previewDocument(attachment)
      } else {
        attachmentStore.downloadDocument(attachment);
      }
    }

    return (
      <Box
        className={classes.container}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box p={2}>
          <Typography>
            <Link className={classes.link} to={"#"} onClick={handlePreview}>
              {attachment.attachmentName}
            </Link>
          </Typography>
        </Box>
        <ConfirmationIconButton
          testId="remove"
          onClick={onRemove}
          confirmDialogProps={{
            body: 'Are you sure you want to remove this attachment?',
          }}
        >
          <DeleteOutline />
        </ConfirmationIconButton>
      </Box>
    );
  }
);
