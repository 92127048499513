import { FormStore } from '@roc/feature-app-core';

const form = {
  fields: {
    company: {
      value: '',
      error: null,
      rule: '',
    },
    firstName: {
      value: '',
      error: null,
      rule: '',
    },
    lastName: {
      value: '',
      error: null,
      rule: 'required',
    },
    profession: {
      value: '',
      error: null,
      rule: '',
    },
    type: {
      value: '',
      error: null,
      rule: '',
    },
    phoneNumber: {
      value: '',
      error: null,
      rule: 'regex:/^\\d{3}-?\\d{3}-?\\d{4}/|alpha_dash',
      message: 'phone must be a number with the format xxx-xxx-xxxx',
    },
    secondaryPhone: {
      value: '',
      error: null,
      rule: 'regex:/^\\d{3}-?\\d{3}-?\\d{4}/|alpha_dash',
      message: 'secondary phone must be a number with the format xxx-xxx-xxxx',
    },
    email: {
      value: '',
      error: null,
      rule: 'email',
      message: 'Email is required in correct format',
    },
    secondaryEmail: {
      value: '',
      error: null,
      rule: 'email',
      message: 'Secondary email is required in correct format',
    },
    leadSource: {
      value: '',
      error: null,
      rule: 'required',
    },
    recordType: {
      value: '',
      error: null,
      rule: 'required',
    },
    status: {
      value: '',
      error: null,
      rule: 'required',
    },
    subStatus: {
      value: '',
      error: null,
      rule: '',
    },
    howDidYouHearAboutUs: {
      value: '',
      error: null,
      rule: '',
    },
    experience: {
      value: '',
      error: null,
      rule: '',
    },
    brokered: {
      value: '',
      error: null,
      rule: '',
    },
    ownerName: {
      value: '',
      error: null,
      rule: '',
    },
    ownerId: {
      value: '',
      error: null,
      rule: '',
    },
    doNotCall: {
      value: '',
      error: null,
      rule: '',
    },
    doNotSendEmail: {
      value: '',
      error: null,
      rule: '',
    },
    doNotSms: {
      value: '',
      error: null,
      rule: '',
    },
    doNotMail: {
      value: '',
      error: null,
      rule: '',
    },
    promoCode: {
      value: '',
      error: null,
      rule: '',
    },
    dataSource: {
      value: '',
      error: null,
      rule: '',
    },
    dataSourceGroup: {
      value: '',
      error: null,
      rule: '',
    },
    leadFeedback: {
      value: '',
      error: null,
      rule: '',
    },
    keyContact: {
      value: '',
      error: null,
      rule: '',
    },
    leadTag: {
      value: '',
      error: null,
      rule: '',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export class EditLeadDetailsFormStore extends FormStore {
  constructor(globalStore) {
    super({ ...form }, globalStore);
  }
}
