import React, { FC, useState } from 'react';
import {

  Typography,
  Grid
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { CropFree, RotateLeft, RotateRight, ZoomIn, ZoomOut } from '@material-ui/icons';
import { ImageCarousel, StandardDialog } from '@roc/ui';

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontWeight: 'bold',
  },
  imgContainer: {
    position: 'relative',
    cursor: 'pointer',
  },
  img: {
    display: 'block',
  },
  icon: {
    color: '#5A5A5A',
  },
  enlargedImageContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export interface ImageDialogProps {
  title: string;
  image: any;
  store;
};

const ImageDialog: FC<ImageDialogProps> = ({ title, image, store }) => {
  const classes = useStyles();

  return (
    <Grid item className={classes.enlargedImageContainer}>
      <ImageCarousel store={store} selectedImage={image} />
    </Grid>
  );
};

export interface ImageViewerProps {
  title: string;
  showTitle?: boolean;
  subTitle?: string;
  image: any;
  heigth?: string;
  store;
}

export const ImageCarouselViewer: FC<ImageViewerProps> = ({ title, subTitle, image, showTitle = false, heigth = "300", store }) => {
  const classes = useStyles();
  const [openImageModal, setOpenImageModal] = useState<boolean>(false);

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} md={12} className={classes.imgContainer} onClick={() => setOpenImageModal(true)} >
        <img
          src={image.image}
          alt={title}
          width="100%"
          height={heigth}
          className={classes.img}
        />
        <div
          style={{
            position: 'absolute',
            right: 16,
            top: 12,
            backgroundColor: 'white',
            opacity: '50%',
            height: 24,
            width: 24,
          }}>
          <CropFree className={classes.icon} />
        </div>
      </Grid>
      {
        showTitle && (
          <Grid item xs={12} md={12}>
            <Typography variant="subtitle1" className={classes.title}>
              {title}
            </Typography>
          </Grid>
        )
      }
      {
        subTitle && (
          <Grid item xs={12} md={12}>
            <Typography variant="subtitle2">
              {subTitle}
            </Typography>
          </Grid>
        )
      }
      <StandardDialog
        open={openImageModal}
        title={title}
        maxWidth="xl"
        handleClose={() => setOpenImageModal(false)}
        dialogContent={
          <ImageDialog
            title={title}
            image={image}
            store={store}
          />
        }
      />
    </Grid>
  );
};