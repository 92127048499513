import { Grid, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { observer } from 'mobx-react';
import { DUPLEX, QUADRUPLEX, SINGLE_FAMILY, TRIPLEX, isNotBlank } from '@roc/feature-utils';
import { RentalPortfolioStore, SinglePropertyStore } from '../../stores';
import {
  MIXED_USE,
  MULTIFAMILY_5_PLUS,
  REFINANCE,
} from '@roc/feature-utils';
import {
  AnnualAdministrativeExpense,
  AnnualHoa,
  AnnualInsurance,
  AnnualMarketingExpense,
  AnnualPayrollExpense,
  AnnualPropertyManagementFees,
  AnnualRepairMaintenanceExpenses,
  AnnualReplacementReserve,
  AnnualTaxes,
  AnnualUtilityExpenses,
  AsIsValue,
  CommercialUnits,
  CommercialUnitsArea,
  CommercialUnitsCurrentOnRent,
  County,
  GrossMonthlyRent,
  PropertyType,
  PropertyAddress,
  ResidentialUnits,
  ResidentialUnitsArea,
  Units,
  BorrowerInformationFields,
  PricerLoanPurpose,
  PricerPurchasePrice,
  PricerPurchaseDate,
  PricerCapitalImprovements,
  PricerCurrentDebtOnProperty,
  PricerCurrentDebtAmount,
  PricerAnnualNoi,
  PricerAnnualExpenses,
  PricerAnnualIncome,
  PricerTargetLtv,
  ResidentialIncome,
  CommercialIncome,
  PricerPropertyLeased,
  LeasesRental,
} from '../../../rentalPricer/components/rentalPricerFields';
import { PricerFieldsConfig } from '../../../rentalPricer/components/rentalPricerFieldsContext';
import { CashOut } from '../../components/formComponents';
import PauseMultifamilySubmissions from '../../components/pausedMultifamilySubmissions';
import { RuralPropertyWarning } from '../../components/ruralPropertyWarning';
import { useEffect } from 'react';

interface LoanPricerProps {
  store: SinglePropertyStore;
}
function LoanPricerSingleProperty({ store }: LoanPricerProps) {
  const { pricerStore, pricerSummaryStore } = store;
  const { form } = pricerStore;
  const fields = form.fields;

  useEffect(() => {
    pricerStore.checkIfPropertyIsRural(buildAddress(fields));
  }, [fields.zipCode.value]);

  useEffect(() => {
    store.fetchStateCounties();
  }, [fields.state.value])

  const isNotMixedUse =
    fields.propertyType.value && form.fields.propertyType.value !== MIXED_USE;

  const isRefinance =
    form.fields.loanPurpose.value &&
    form.fields.loanPurpose.value === REFINANCE;

  const buildAddress = (fields) => {
    const address = fields.address?.value || '';
    const city = fields.city?.value || '';
    const state = fields.state?.value || '';
    const zipCode = fields.zipCode?.value || '';

    const fullAddress = `${address}, ${city}, ${state} ${zipCode}`.trim();

    return fullAddress;
}
  return (
    <>
      <PauseMultifamilySubmissions open={store.pricerStore.isPauseMultifamilySubmissionsOpen} handleClose={() => store.pricerStore.setIsPauseMultifamilySubmissionsOpen(false)} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4">Loan Pricer</Typography>
          <br />
        </Grid>
      </Grid>
      <br />
      <Grid item container md={12} lg={8}>
        <PricerFieldsConfig xs={12}>
          <PropertyAddress store={store} />
        </PricerFieldsConfig>
        {pricerStore.isPropertyMSARural && <RuralPropertyWarning store={pricerStore} />}
      </Grid>
      <br />
      <Grid item container md={12} lg={8}>
        <Grid item container spacing={2}>
          <PricerFieldsConfig xs={12} sm={6} >
            <PropertyType store={store} />
            {form.fields.address.value && <County store={store} />}
            {isNotMixedUse && <Units store={store} />}
            <PricerLoanPurpose store={store} />
            {(form.fields.loanPurpose.value && !isRefinance) && (<PricerPropertyLeased store={store} />)}
            <PricerPurchasePrice store={store} />
            <LeasesRental store={store} />
            {isRefinance && (
              <>
                <PricerPurchaseDate store={store} />
                <PricerCapitalImprovements store={store} />
                <PricerCurrentDebtOnProperty store={store} />
                {form.fields.anyDebt.value === 'Y' && (
                  <PricerCurrentDebtAmount store={store} />
                )}
                {form.fields.anyDebt.value === 'Y' &&
                  form.fields.totalDebtPayoff.value >= 0 && (
                    <Grid item xs={12}>
                      <CashOut
                        store={pricerSummaryStore}
                      />
                    </Grid>
                  )}
              </>
            )}
          </PricerFieldsConfig>
        </Grid>
      </Grid>
      <br />
      <MixedUse store={store} />
      <br />
      <PropertyInformation store={store} />
      <br />
      <BorrowerInformation store={store} />
      <br />
    </>
  );
}

const MixedUse = observer(({ store }) => {
  const { form } = store.pricerStore;
  return (
    <>
      {form.fields.propertyType.value &&
        form.fields.propertyType.value === MIXED_USE && (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6">
                  Provide Mixed use Property Information
                </Typography>
                <br />
              </Grid>
            </Grid>
            <Grid container spacing={4}>
              <ResidentialUnits store={store} />
              <CommercialUnits store={store} />
              <ResidentialUnitsArea store={store} />
              <CommercialUnitsArea store={store} />
              <ResidentialIncome store={store} />
              <CommercialIncome store={store} />
              <CommercialUnitsCurrentOnRent store={store} />
            </Grid>
          </>
        )}
    </>
  );
});

const PropertyInformation = observer(
  ({ store }: { store: SinglePropertyStore | RentalPortfolioStore }) => {
    const { form } = store.pricerStore;

    const isOneToFourProperty =
      form.fields.propertyType.value &&
      (form.fields.propertyType.value === SINGLE_FAMILY ||
        form.fields.propertyType.value === DUPLEX ||
        form.fields.propertyType.value === TRIPLEX ||
        form.fields.propertyType.value === QUADRUPLEX)


    return (
      <Grid container direction="row">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6">Provide Property Information</Typography>
            <br />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={4}>
            <AsIsValue store={store} />
            <GrossMonthlyRent store={store} />
            <AnnualTaxes store={store} />
            <AnnualInsurance store={store} />
            <AnnualHoa store={store} />
            {!isOneToFourProperty &&
              <>
                <AnnualUtilityExpenses store={store} />
                <AnnualRepairMaintenanceExpenses store={store} />
                <AnnualPropertyManagementFees store={store} />
              </>
            }
            {form.fields.propertyType.value &&
              form.fields.propertyType.value === MULTIFAMILY_5_PLUS && (
                <>
                  <AnnualAdministrativeExpense store={store} />
                  <AnnualPayrollExpense store={store} />
                  <AnnualMarketingExpense store={store} />
                  <AnnualReplacementReserve store={store} />
                </>
              )}
            <PricerTargetLtv store={store} />
          </Grid>

          <br />

          <Grid
            container
            spacing={4}
            style={{
              width: '100%',
              border: 'solid 3px',
              margin: '32px 0px 32px 0px',
              borderColor: '#CECECE',
              borderRadius: '8px',
              padding: '3vw 5vw',
            }}
          >
            <PricerAnnualIncome store={store} />
            <PricerAnnualExpenses store={store} />
            <PricerAnnualNoi store={store} />
          </Grid>
        </Grid>
      </Grid >
    );
  }
);

const BorrowerInformation = observer(
  ({ store }: { store: SinglePropertyStore }) => {
    const { pricerStore } = store;
    const { form } = pricerStore;

    return (
      <Grid container direction="row">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6">Provide Borrower Information</Typography>
            <br />
          </Grid>
        </Grid>
        <Grid item container spacing={4} xs={12} md={8} lg={4}>
          <PricerFieldsConfig xs={12}>
            <BorrowerInformationFields store={store} />
          </PricerFieldsConfig>
        </Grid>
      </Grid>
    );
  }
);

export default observer(LoanPricerSingleProperty);
