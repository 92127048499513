import { ApiResponse } from '@roc/client-portal-shared/services';
import { observable, flow, makeObservable, action, computed } from 'mobx';
import { GlobalStore, FormStore } from '@roc/feature-app-core';
import { differenceInYears, parse, startOfToday } from 'date-fns';
import { capitalize, formatDate, getDocumentAsBlob, isBlank, isNil, isNotBlank, NO, parseAddress, YES } from '@roc/feature-utils';
import { FileUpload } from '@roc/ui';
import { BorrowerTrackRecordVerificationStore } from '../../borrowerDetailsVerification/stores/borrowerTrackRecordVerificationStore';
import { FormInfo, IdVerificationForm } from '../types';
import { BorrowerDetailsVerificationService } from '@roc/feature-credit-background-check';
import { QualifyBorrowerService } from '../services/qualifyBorrowerService';

const detailsVerificationForm = {
  fields: {
    firstName: {
      value: '',
      error: null,
      rule: '',
      message: '',
    },
    middleName: {
      value: '',
      error: null,
      rule: '',
      message: '',
    },
    lastName: {
      value: '',
      error: null,
      rule: '',
      message: '',
    },
    phoneNumber: {
      value: '',
      error: null,
      rule: 'required|regex:/^\\d{3}-?\\d{3}-?\\d{4}$/|alpha_dash',
      message: 'phone must be a number with the format xxx-xxx-xxxx',
    },
    dateOfBirth: {
      type: 'DATE',
      value: null,
      error: null,
      rule: 'required',
      format: 'MM/dd/yyyy',
      message: 'Date of birth is required.',
    },
    idType: {
      value: 'US Driver\'s License / Government ID',
      error: null,
      rule: 'required',
      message: 'The last ID Type field is required.',
    },
    ssn: {
      value: '',
      error: null,
      message: 'SSN must be a number with the format xxx-xx-xxxx',
      rule: [
        {
          required_if_one_of: [
            'citizenshipStatus',
            'US Citizen',
            'US Permanent Resident(Green Card Holder)',
            'US Resident with Valid Visa',
          ],
        },
        { regex: '/^\\d{3}-?\\d{2}-?\\d{4}$/' },
      ],
    },
    address: {
      value: '',
      error: null,
      message: 'Address Line 1 is required',
      rule: 'required',
    },
    __isAddressInCorrectFormat__: {
      value: true,
      error: null,
      rule: 'accepted',
      message: 'Invalid Address Format',
    },
    streetNumber: {
      value: '',
      error: null,
      rule: '',
    },
    streetName: {
      value: '',
      error: null,
      rule: '',
    },
    aptNumber: {
      value: '',
      error: null,
      rule: '',
    },
    city: {
      value: '',
      error: null,
      rule: 'required',
      message: 'The city field is required.',
    },
    state: {
      value: '',
      error: null,
      rule: 'required',
      message: 'The state field is required.',
    },
    zipCode: {
      value: '',
      error: null,
      message: 'Zip code must be 5 digits',
      rule: 'required|regex:/^\\d{5}$/|alpha_dash',
    },
    citizenshipStatus: {
      value: 'US Citizen',
      error: null,
      rule: 'required',
    },
    assets: {
      value: '',
      error: null,
      rule: 'required',
      message: 'The assets field is required.',
    },
    liabilities: {
      value: '',
      error: null,
      rule: 'required',
      message: 'The liabilities field is required.',
    },
    netWorth: {
      value: '',
      error: null,
      rule: 'required',
      message: 'The net worth field is required.',
    },
    driversLicense: {
      value: YES,
      rule: 'required',
      message: 'This field is required',
    },
    driversLicenseState: {
      value: null,
      rule: [{ required_if: ['driversLicense', YES] }],
      message: 'This field is required',
    },
    driversLicenseNumber: {
      value: null,
      rule: [{ required_if: ['driversLicense', YES] }],
      message: 'This field is required',
    },
    passportNumber: {
      value: '',
      error: null,
      rule: [{ required_if: ['driversLicense', NO] }],
      message: 'The passport number field is required',
    },
    passportExpiration: {
      type: 'DATE',
      value: null,
      error: null,
      format: 'MM/dd/yyyy',
      rule: [{ required_if: ['driversLicense', NO] }],
      message: 'The passport expiration date field is required',
    },
    fileNumber: {
      value: null,
      error: '',
      rule: '',
      message: 'This field is required',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export class TpoAlreadyHaveInformationStore extends FormStore {
  protected globalStore: GlobalStore;
  private borrowerDetailsVerificationService: BorrowerDetailsVerificationService;
  private qualifyBorrowerService: QualifyBorrowerService;
  borrowerTrackRecordVerificationStore: BorrowerTrackRecordVerificationStore;

  token: string;
  formInfo: FormInfo;
  driversLicense: File;
  uwMatrixEnabled: boolean;
  creditUploads: FileUpload[];
  runCreditOnCreation: boolean;
  showDob: boolean;
  showSsn: boolean;
  existingDriversLicense: File;

  constructor(globalStore) {
    super({ ...detailsVerificationForm }, globalStore);
    this.globalStore = globalStore;
    this.borrowerDetailsVerificationService = new BorrowerDetailsVerificationService();
    this.qualifyBorrowerService = new QualifyBorrowerService();
    this.borrowerTrackRecordVerificationStore = new BorrowerTrackRecordVerificationStore(this.globalStore, this.borrowerDetailsVerificationService);

    makeObservable(this, {
      token: observable,
      formInfo: observable,
      driversLicense: observable,
      fetchFormInformation: flow,
      resetStore: flow,
      saveBorrowerDetails: flow,
      uwMatrixEnabled: observable,
      creditUploads: observable,
      setCreditUploads: flow,
      runCreditOnCreation: observable,
      saveForAlreadyHaveBorrowerInformation: flow,
      isInvalidDateOfBirthDate: action,
      saveTpoBorrowerPreScreen: flow,
      setFileNumber: action,
      showDob: observable,
      showSsn: observable,
      setShowDob: action,
      setShowSsn: action,
      loadDriversLicense: flow,
      basicInfoIsPresent: computed,
    });
    this.setDefaults();
  }
  private setDefaults() {
    this.token = null;
    this.formInfo = null;
    this.driversLicense = null;
    this.uwMatrixEnabled = false;
    this.creditUploads = [];
    this.showDob = true;
    this.showSsn = true;
  }

  *resetStore() {
    super.reset();
    this.setDefaults();
  }

  *fetchFormInformation(token) {
    try {
      this.token = token;
      const response = yield this.borrowerDetailsVerificationService.getFormInformation(
        token
      );
      this.formInfo = response.data.data;
      if (Object.keys(this.formInfo?.savedForm).length !== 0) {
        this.loadBorrowerForm(this.formInfo?.savedForm);
      }
    } catch (e) {
      console.log(e);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'We had an issue while retrieving your form details.',
      });
    }
  }

  setDriversLicense(file: File) {
    this.driversLicense = file;
  }

  *saveBorrowerDetails() {
    try {
      const values = this.getFormValues();
      const token = this.token;
      yield this.borrowerDetailsVerificationService.saveBorrowerDetails(
        {
          ...values,
          firstName: capitalize(values.firstName),
          lastName: capitalize(values.lastName),
          token,
          dateOfBirth: formatDate(values.dateOfBirth, 'MM/dd/yyyy'),
          passportExpiration: formatDate(
            values.passportExpiration,
            'MM/dd/yyyy'
          ),
          experience: values.experience,
        },
        this.driversLicense
      );
      yield this.fetchFormInformation(this.token);
    } catch (error) {
      console.log(error);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error occurred while saving the contact information',
      });
    }
  }

  saveAddressField(address: string, addressDetails, geometryDetails) {
    if (!isNil(addressDetails) && !isNil(geometryDetails)) {
      const parsedAddress = parseAddress(
        address,
        addressDetails,
        geometryDetails
      );
      const streetAddress = parsedAddress.street_number === null ? parsedAddress.street : parsedAddress.street_number + " " + parsedAddress.street;
      this.updateField('address', streetAddress);
      this.updateField('streetNumber', parsedAddress.street_number);
      this.updateField('streetName', parsedAddress.street);
      this.updateField('city', parsedAddress.city);
      this.updateField('state', parsedAddress.state);
      this.updateField('zipCode', parsedAddress.zip);
      this.updateField('aptNumber', parsedAddress.aptNumber);
    } else {
      this.onFieldChange('address', address);
      this.onFieldChange('streetName', address);
    }
  }

  updateField(fieldName, parsedValue) {
    const value = isNil(parsedValue) ? '' : parsedValue;
    this.onFieldChange(fieldName, value);
  }

  loadBorrowerForm(borrowerVerificationForm: IdVerificationForm) {
    this.setShowDob(isBlank(borrowerVerificationForm.dateOfBirth));
    this.setShowSsn(isBlank(borrowerVerificationForm.ssn));
    this.loadForm({
      ...borrowerVerificationForm,
      driversLicenseNumber: borrowerVerificationForm.driversLicenseNumber ?? '',
    });
  }

  *loadDriversLicense() {
    try {
      if (isNotBlank(this.formInfo.savedForm?.driversLicenseUploadPath)) {
        const response: ApiResponse = yield this.borrowerDetailsVerificationService.downloadDriversLicense(
          this.formInfo.savedForm?.creditBackgroundRequestId
        );
        const fileResult = getDocumentAsBlob(response?.data, response?.headers, response?.data?.type);
        this.existingDriversLicense = new File([fileResult.blob], fileResult.name);
        this.driversLicense = this.existingDriversLicense;
      }
    } catch (error) {
      console.log(error);
    }
  }

  *setCreditUploads(values) {
    this.creditUploads = values;
  }

  *saveTpoBorrowerPreScreen(values, loanId) {
    try {
      const borrowerId = this.borrowerTrackRecordVerificationStore.borrowerInfo?.borrowerId || null;
      const taskBody = {
        sectionProcess: 'BORROWER_PRE_QUALIFICATION',
        objectType: 'BORROWER',
        taskTemplateType: 'BORROWER_PRE_QUALIFICATION',
        objectId: borrowerId,
      };
      const task = yield this.borrowerTrackRecordVerificationStore.createTaskWithFile(taskBody, this.creditUploads[0]);
      const data = {
        loanId: loanId,
        fileId: values.fileNumber,
        borrowerId,
        taskId: task?.taskId,
        dob: values.dateOfBirth,
        socialSecurityNumber: values.ssn,
      };
      yield this.qualifyBorrowerService.qualifyBorrower(data);
    } catch (e) {
      console.log(e);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'We had an issue while submitting the borrower credit report.',
      });
    }
  }

  isInvalidDateOfBirthDate = (dateOfBirth) => {
    const date = formatDate(dateOfBirth, 'MM/dd/yyyy')
    return (
      dateOfBirth &&
      differenceInYears(startOfToday(), parse(date, 'MM/dd/yyyy', new Date())) < 18
    );
  }

  *saveForAlreadyHaveBorrowerInformation(borrowerId: string | number, personalGuarantor: boolean, loanId: number) {
    const values = this.getFormValues();
    let isCreditInfoValid = !personalGuarantor || (personalGuarantor && isNotBlank(values.fileNumber) && this.creditUploads.length > 0);
    if (this.basicInfoIsPresent) {
      this.driversLicense = isNil(this.driversLicense) ? this.existingDriversLicense : this.driversLicense;
      isCreditInfoValid = true;
    }
    if (this.form.meta.isValid && !isNil(this.driversLicense) && isCreditInfoValid) {
      if (this.isInvalidDateOfBirthDate(this.form.fields.dateOfBirth.value)) {
        this.globalStore.notificationStore.showErrorNotification({ message: 'Please fix the validation errors.', });
        this.form.fields.dateOfBirth.error = 'Age must be over 18 years'
      } else {
        yield this.saveBorrowerDetails();
        if (personalGuarantor) {
          this.borrowerTrackRecordVerificationStore.setBorrowerInfo({
            borrowerId
          });
          if (isNotBlank(values.fileNumber) && this.creditUploads.length > 0) {
            yield this.saveTpoBorrowerPreScreen(values, loanId);
          }
        }
        return true;
      }
    } else if (this.form.fields.__isAddressInCorrectFormat__.value == false) {
      this.globalStore.notificationStore.showErrorNotification({ message: 'Please fix the validation errors.', });
      this.form.fields.address.error = 'Invalid address format';
    } else {
      this.runFormValidationWithMessage();
    }
    if (isNil(this.driversLicense)) {
      this.globalStore.notificationStore.showErrorNotification({ message: "Please upload the driver's license image.", });
    }
    if (!this.basicInfoIsPresent && personalGuarantor && (isBlank(values.fileNumber) || this.creditUploads.length == 0)) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Please enter the File Number and upload a Credit Report',
      });
    }
    return false;
  }

  setFileNumber(fileNumber) {
    return this.onFieldChange('fileNumber', fileNumber);
  }

  setShowDob(newShowDob: boolean) {
    this.showDob = newShowDob;
  }

  setShowSsn(newShowSsn: boolean) {
    this.showSsn = newShowSsn;
  }

  get basicInfoIsPresent() {
    return !this.showDob && !this.showSsn;
  }

}
