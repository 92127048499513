import { Service } from '@roc/feature-app-core';

const url = {
  UPDATE_INSURANCE: '/api/v1/document/formDocument/updateInsuranceForm',
};

export class InsuranceProviderService extends Service {
  updateInsuranceForm(data: any) {
    return this.put(url.UPDATE_INSURANCE, data);
  }
}
