import { FormStore } from '@roc/feature-app-core';

const financialInformationForm = {
  fields: {
    assets: {
      value: '',
      error: null,
      rule: 'required',
      message: 'The assets field is required.',
    },
    liabilities: {
      value: '',
      error: null,
      rule: 'required',
      message: 'The liabilities field is required.',
    },
    netWorth: {
      value: '',
      error: null,
      rule: 'required',
      message: 'The net worth field is required.',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};
export class FinancialInformationFormStore extends FormStore {

  constructor(globalStore) {
    super({ ...financialInformationForm }, globalStore);
  }

}