import { Page } from '../../../components';
import { NotFound, ComingSoon, InternalServerError, InvalidCertificate } from '@roc/ui';
import { Route } from 'react-router';

export const getMiscellaneousRoutesConfig = (
  basePath: string,
  baseUrl: string
) => {
  return {
    notFound: {
      path: `${basePath}/notfound`,
      url: `${baseUrl}/notfound`,
      documentTitle: 'Not Found',
    },
    comingSoon: {
      path: `${basePath}/comingsoon`,
      url: `${baseUrl}/comingsoon`,
      documentTitle: 'Coming Soon',
    },
    internalServerError: {
      path: `${basePath}/internal-error`,
      url: `${baseUrl}/internal-error`,
      documentTitle: 'Internal Server Error',
    },
    certificateError: {
      path: `${basePath}/invalid-certificate`,
      url: `${baseUrl}/invalid-certificate`,
      documentTitle: 'Invalid Certificate',
    }
  };
};

export const getMiscellaneousRoutes = (basePath: string, baseUrl: string) => {
  const config = getMiscellaneousRoutesConfig(basePath, baseUrl);
  return {
    notFound: (
      <Route exact path={config.notFound.path}>
        <Page routeDefinition={config.notFound}>
          <NotFound />
        </Page>
      </Route>
    ),
    commingSoon: (
      <Route exact path={config.comingSoon.path}>
        <Page routeDefinition={config.comingSoon}>
          <ComingSoon />
        </Page>
      </Route>
    ),
    internalServerError: (
      <Route exact path={config.internalServerError.path}>
        <Page routeDefinition={config.internalServerError}>
          <InternalServerError />
        </Page>
      </Route>
    ),
    certificateError: (
      <Route exact path={config.certificateError.path}>
        <Page routeDefinition={config.certificateError}>
          <InvalidCertificate />
        </Page>
      </Route>
    )
  };
};
