import { GroupAdd, PermPhoneMsg } from '@material-ui/icons';
import { useLoanStore } from '@roc/feature-loans-shared';
import { ConfirmDialog, FabActionsButton, FabActionsMenuItem } from '@roc/ui';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { usePayoffRequestStore } from '../hooks/usePayoffRequestStore';

export const PayoffActionsButton = observer(({ version }) => {
  const { loanStore } = useLoanStore();
  const { payoffRequestStore } = usePayoffRequestStore();
  const { currentPayoff, globalStore } = payoffRequestStore;

  const [confirmAssistanceModalOpen, setConfirmAssistanceModalOpen] = useState(
    false
  );
  const [confirmAddAccountingModal, setConfirmAddAccountingModal] = useState(
    false
  );

  const handleCloseAssistanceModal = () => {
    setConfirmAssistanceModalOpen(false);
  };

  const handleCloseAddAccountingModal = () => {
    setConfirmAddAccountingModal(false);
  };

  if(loanStore.loanDetails?.toDosFrameworkEnabled && version !== 'v2'){
    return null;
  }

  return (
    <>
      <FabActionsButton version={version}>
        <FabActionsMenuItem
          icon={<GroupAdd />}
          label={'Add Accounting Team'}
          onClick={() => {
            setConfirmAddAccountingModal(true);
          }}
        />
        <FabActionsMenuItem
          icon={<PermPhoneMsg />}
          label={'Legal Or Waiver Assistance'}
          onClick={() => {
            setConfirmAssistanceModalOpen(true);
          }}
        />
      </FabActionsButton>
      <ConfirmDialog
        open={confirmAssistanceModalOpen}
        confirmButtonText={'Yes'}
        handleClose={handleCloseAssistanceModal}
        handleCancel={handleCloseAssistanceModal}
        handleConfirm={() => {
          payoffRequestStore.requestPayoffAssistance(
            loanStore?.loanDetails?.loanId
          );
          handleCloseAssistanceModal();
        }}
        title={'Request Assistance'}
        body={'Are you sure you want to request assistance for this file?'}
      />
      <ConfirmDialog
        open={confirmAddAccountingModal}
        confirmButtonText={'Yes'}
        handleClose={handleCloseAddAccountingModal}
        handleCancel={handleCloseAddAccountingModal}
        handleConfirm={() => {
          payoffRequestStore.addServicerAccountingTeam(
            loanStore?.loanDetails?.loanId
          );
          handleCloseAddAccountingModal();
        }}
        title={'Add Accounting Team'}
        body={'Are you sure you want to add the accounting team?'}
      />
    </>
  );
});
