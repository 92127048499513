import {
  Grid,
  List,
  ListItem,
  ListItemText,
  Divider,
  Link,
  TextField,
} from '@material-ui/core';
import { observer } from 'mobx-react';
import { yesNoOptions } from '../../utils/constants';
import { findOptionLabel } from '../../utils/proposalHelper';
import { useProposalsStore } from '../../hooks/useProposalsStore';
import { Paper, TestDataAttribute } from '@roc/ui';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { numberWithCommas } from '@roc/ui';
import { AlertDialog } from '@roc/ui';
import { useState } from 'react';
import { sanitizeTestId } from '@roc/feature-utils';

const testId = sanitizeTestId;
const listItem = (label, value, component?, divider: boolean = true) => {
  return (
    <>
      <ListItem>
        <ListItemText>
          <Box display="flex">
            <Box flexGrow={1}>{label}</Box>
            <span />
            <TestDataAttribute id={testId(label)}>
              <Box>{component || value}</Box>
            </TestDataAttribute>
          </Box>
        </ListItemText>
      </ListItem>
    </>
  );
};

const divider = <Divider variant="middle" />;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      margin: theme.spacing(3),
      marginLeft: theme.spacing(0),
      borderRadius: theme.spacing(2),
      boxShadow:
        'rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px',
      padding: '16px',
    },
    label: {
      paddingLeft: '10px',
    },
    summaryPaper: {
      width: '50%'
    },
    link: {
      color: 'blue',

      "&:hover": {
        background: "#efefef",
        cursor: 'hand',
      },
    },
    modal: {
      height: '300px',
      backgroundColor: 'red',
    }
  })
);


const modalDescription = (title, str, isOpen, handleClose) => {
  return (
    <>
      <AlertDialog
        open={isOpen}
        handleClose={handleClose}
        title={title}
        body={str}
      />
    </>
  )
}


const ProjectDetailsTab = observer(() => {
  const classes = useStyles();
  const [
    openModalDesc,
    setOpenModalDesc,
  ] = useState(false);
  const [
    propertyDesc,
    setpropertyDesc,
  ] = useState("");

  const { proposalsStore } = useProposalsStore();
  const {
    proposal,
    selectBorrowersStore,
    properties,
  } = proposalsStore.detailsStore;


  const handleModal = (flag) => {
    setOpenModalDesc(flag);
  }

  const truncate = (str: string, className, id) => {
    return (
      <>
        {(str?.length > 28) && <Link className={className} onClick={() => {
          setpropertyDesc(str);
          setOpenModalDesc(true);
        }}>
          {str?.substring(0, 25)}...
        </Link>}
        {(str?.length < 29) &&
          str
        }
      </>
    )
  }

  return (
    <Grid container>
      <Grid item xs={6}>
        <Paper title='Loan Terms' className={classes.paper} >
          <List>
            {listItem('Initial Loan Amount', `${(!proposal?.initialLoanAmount) ? '--' : '$' + numberWithCommas(proposal?.initialLoanAmount)}`)}
            {divider}
            {listItem('Construction HoldBack', `${!proposal?.constructionHoldback ? '--' : '$' + numberWithCommas(proposal?.constructionHoldback)}`)}
            {divider}
            {listItem('Total Loan Amount', `${!proposal?.amount ? '--' : '$' + numberWithCommas(proposal?.amount)}`)}
            {divider}
            {listItem('Interest Rate', `${!proposal?.rate ? '--' : proposal?.rate} %`)}
            {divider}
            {listItem('Points in', `${!proposal?.pointsIn ? '--' : proposal?.pointsIn} %`)}
            {divider}
            {listItem('Points out', `${!proposal?.pointsOut ? '--' : proposal?.pointsOut} %`)}
            {divider}
            {listItem('Term (Duration)', `${!proposal?.duration ? '--' : proposal?.duration}`)}
          </List>
        </Paper>
      </Grid>
      <Grid item container >
        <Grid item xs={6}>
          {selectBorrowersStore.borrowers.map((borrower, index) => (
            <Paper title='Borrower Information' className={classes.paper} >
              <List>
                {listItem('Full Name', `${borrower.firstName} ${borrower.lastName}`)}
                {divider}
                {listItem('Experience', borrower.experience)}
                {divider}
                {listItem('Qualifying FICO Score', borrower.ficoProvidedAtOrigination)}
                {divider}
                {listItem('Net Worth', `${!borrower.netWorth ? '--' : '$' + numberWithCommas( borrower.netWorth)}`)}
              </List>
            </Paper>
          ))}
        </Grid>
        <Grid item xs={6}>
          {properties.map((property, index) => (
            <Paper title='Property Information' className={classes.paper} >
              <List>
                {listItem('Address', property?.address)}
                {divider}
                {listItem('Property Description', truncate(property?.propertyDescription, classes.link, property?.propertyId))}
                {divider}
                {listItem('Renovation/Construction Desc', property?.describeRenovation)}
                {divider}
                {listItem('Adding Square Footage', findOptionLabel(
                  yesNoOptions,
                  property?.addingSquareFootage))}
              </List>
              {(openModalDesc) &&
                <AlertDialog
                  open={openModalDesc}
                  handleClose={() => { setOpenModalDesc(false) }}
                  title={'Property Description'}
                  body=
                  {
                    <TextField multiline id="outlined-basic" disabled={true} fullWidth
                      value={propertyDesc} variant="outlined" />
                  }
                />
              }
            </Paper>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
});

export default ProjectDetailsTab;
