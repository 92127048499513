import { Box, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { CLOSING, SERVICING, UNDERWRITING } from '@roc/feature-utils';
import { useBaseStore } from '@roc/feature-app-core';
import { Document } from '@roc/feature-types';
import { FileUpload, Tabs, ScreenshotGrid, SelectField } from '@roc/ui';
import { observer } from 'mobx-react';
import { useCallback, useEffect, useState, useRef } from 'react';
import { LoanDetailsLayout } from '@roc/feature-loans';
import { DocumentSection } from '../../documentSection';
import { useDocumentStore } from '../../hooks/useDocumentStore';
import DocumentForms from '../documentForms/documentForms';
import GetAppIcon from '@material-ui/icons/GetApp';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerButton: {
      border: 0,
      color: theme.palette.grey[600],
      '&:hover': {
        border: 0,
      },
      '&.Mui-disabled': {
        border: 0,
      },
    },
    iconButton: {
      color: theme.palette.grey[600],
      marginRight: theme.spacing(1),
      padding: theme.spacing(0.75),
    },
    tab: {
      marginBottom: theme.spacing(2),
    },
  })
);

const processes = [UNDERWRITING, CLOSING, SERVICING];
type PendingDocuments = {
  loanId: string;
};

const documentOptions = [
  { label: 'Pending Documents', value: 'Pending Documents' },
  { label: 'All Documents', value: 'All Documents' },
];

export const PendingDocuments = observer((props: PendingDocuments) => {
  const screenshotContainerRef = useRef(null);
  const classes = useStyles();
  const { headerButton, iconButton, tab } = classes;
  const [selectedTab, setSelectedTab] = useState(0);

  const [selectedOption, setSelectedOption] = useState(documentOptions[0].value)
  const onTabSelect = (e: any, index: number): void => {
    setSelectedTab(index);
  };

  const { loanId } = props;
  const { globalStore } = useBaseStore();
  const { documentStore } = useDocumentStore();
  const { pendingDocuments, showServicing, documents, underwritingDocuments, closingDocuments, servicingDocuments } = documentStore;

  const tabs = [
    {
      label: 'UNDERWRITING',
    },
    {
      label: 'CLOSING',
    },
    showServicing && {
      label: 'SERVICING',
    },
  ].filter(Boolean);

  const fetchAllDocuments = useCallback(
    (process: string) => {
      documentStore.fetchProcessDocuments(loanId, process);
    },
    [documentStore, loanId]
  );

  const fetchPendingDocuments = useCallback(
    (process: string) => {
      documentStore.fetchPendingDocuments(process, loanId);
    },
    [documentStore]
  );

  useEffect(() => {
    if (selectedOption === documentOptions[0].value) {
      fetchPendingDocuments(processes[selectedTab]);
    }
    else {
      fetchAllDocuments(processes[selectedTab]);
    }
  }, [selectedTab, selectedOption, fetchAllDocuments, fetchPendingDocuments]);


  const getSections = (value) => {
    switch (value) {
      case documentOptions[0].value:
        return getPendingSections();
      case documentOptions[1].value:
        return getAllDocuments();
    }
  }

  const getAllDocuments = () => {
    const { displaySections } = documentStore;
    const displaySectionsForLoanProcess =
      displaySections[processes[selectedTab]] ?? [];
    const allSections = [];

    displaySectionsForLoanProcess.forEach(displaySection => {
      displaySection.sections.forEach(section => {
        allSections.push(section);
      });
    });
    return allSections;
  }

  const getPendingSections = () => {
    const { displaySections } = documentStore;
    const displaySectionsForLoanProcess =
      displaySections[processes[selectedTab]] ?? [];
    const sectionsWithPendingDocs = Object.keys(pendingDocuments);
    const pendingSections = [];

    displaySectionsForLoanProcess.forEach(displaySection => {
      displaySection.sections.forEach(section => {
        if (sectionsWithPendingDocs.includes(section.sectionName)) {
          pendingSections.push(section);
        }
      });
    });

    return pendingSections;
  };

  const onSectionDocumentsUpload = (section, files: FileUpload[]) => {
    const { sectionId, sectionName } = section;

    documentStore.uploadSectionPendingDocuments(
      files,
      loanId,
      sectionId,
      sectionName,
      processes[selectedTab]
    );
  };

  const onDocumentUpload = (file, doc) => {
    documentStore.uploadPendingDocument(file, doc, processes[selectedTab], loanId);
  };

  const processDocuments = useCallback(
    section => {
      let docs = [];
      if (selectedOption === documentOptions[0].value) {
        docs = pendingDocuments[section.sectionName] || [];
      }
      else {
        if (selectedTab === 0) {
          docs = underwritingDocuments[section.sectionName] || [];
        }
        else if (selectedTab === 1) {
          docs = closingDocuments[section.sectionName] || [];
        }
        else {
          docs = servicingDocuments[section.sectionName] || [];
        }
      }
      return docs.map((document: Document) =>
        documentStore.formatDocument(document, processes[selectedTab])
      );
    },
    [documentStore, pendingDocuments, selectedTab, selectedOption, underwritingDocuments, closingDocuments, servicingDocuments]
  );

  const changeDocuments = (value) => {
    setSelectedOption(value);
  };

  return (
    <ScreenshotGrid
      fileName={`Loan_${loanId}_Documents.pdf`}
      ref={screenshotContainerRef}
    >
      <LoanDetailsLayout>
        <Box display="flex" justifyContent="flex-start">
          <SelectField
            standaloneLabel
            label={''}
            name={'changeDocumentsDropdown'}
            value={selectedOption}
            options={documentOptions}
            onChange={value => changeDocuments(value)}
            variant="outlined"
            shrink
            testId="changeDocumentsDropdown"
          />
        </Box>
        <Box display="flex" justifyContent="flex-end">
          <Button data-html2canvas-ignore
            className={headerButton}
            startIcon={<GetAppIcon />}
            variant="outlined"
            onClick={() => screenshotContainerRef.current.download()}
          >
            Capture As PDF
          </Button>
        </Box>
        <Tabs
          className={tab}
          tabs={tabs}
          selectedTab={selectedTab}
          onTabChange={onTabSelect}
        />
        {!globalStore.isLoading && getSections(selectedOption).length == 0 && (
          <Box m={2}>
            <Typography color="textSecondary">{`No ${tabs[
              selectedTab
            ].label.toLowerCase()} documents.`}</Typography>
          </Box>
        )}
        {!globalStore.isLoading &&
          getSections(selectedOption).map((section, index) => (
            <DocumentSection
              key={`${section.sectionName}-${index}`}
              entityId={loanId}
              section={section}
              loanProcess={processes[selectedTab]}
              documents={processDocuments(section)}
              onDocumentUpload={onDocumentUpload}
              onSectionDocumentsUpload={onSectionDocumentsUpload}
            />
          ))}
        <DocumentForms loanId={loanId} />
      </LoanDetailsLayout>
    </ScreenshotGrid>
  );
});

export default PendingDocuments;