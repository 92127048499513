import React from 'react';
import { Button, Typography, Box, Grid, Divider } from '@material-ui/core';
import { formatCurrency } from '@roc/ui/utils';

const RecordDetails = ({ record, onClose }) => {

  const isBorrower = !!record?.borrowerId
  const isTPO = !!record?.lenderId

  if (!record) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Box padding={2} minWidth={300} style={{ borderRadius: '12px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', backgroundColor: '#fff', maxWidth: '500px' }}>
      <Typography variant="h6" gutterBottom>
        {` ${record.firstName} ${record.lastName}`}
      </Typography>
      <Divider />

      <Grid container spacing={2} style={{ marginTop: '10px' }}>
        {(!isBorrower && !isTPO) &&
          <>
            <Grid item xs={6}>
              <Typography variant="subtitle2">Created Date:</Typography>
              <Typography variant="body1">{record.createdDate}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle2">Last Modified Date:</Typography>
              <Typography variant="body1">{record.lastUpdatedDate}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle2">Company:</Typography>
              <Typography variant="body1">{record.company}</Typography>
            </Grid>
          </>
        }

        {
          isBorrower &&
          <>
            <Grid item xs={6}>
              <Typography variant="subtitle2">LTD Submission:</Typography>
              <Typography variant="body1">{formatCurrency(record?.submittedAmount)}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle2">LTD Submission Count:</Typography>
              <Typography variant="body1">{record.submittedLoans}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle2">Last 12 Months Submission:</Typography>
              <Typography variant="body1">{formatCurrency(record?.lastYearSubmittedAmount)}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle2">Last 12 Months Submission Count:</Typography>
              <Typography variant="body1">{record.lastYearSubmittedLoans}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle2">LTD Funded:</Typography>
              <Typography variant="body1">{formatCurrency(record?.fundedLoansAmount)}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle2">LTD Funded Count:</Typography>
              <Typography variant="body1">{record.fundedLoans}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle2">Last 12 Months Funded:</Typography>
              <Typography variant="body1">{formatCurrency(record?.lastYearFundedAmount)}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle2">Last 12 Months Funded Count:</Typography>
              <Typography variant="body1">{record.lastYearFundedLoans}</Typography>
            </Grid>
          </>
        }

        {
          isTPO &&
          <>
            <Grid item xs={6}>
              <Typography variant="subtitle2">LTD Submission:</Typography>
              <Typography variant="body1">{formatCurrency(record?.ltdSubmission)}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle2">LTD Submission Count:</Typography>
              <Typography variant="body1">{record.ltdSubmissionCount}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle2">Last 12 Months Submission:</Typography>
              <Typography variant="body1">{formatCurrency(record?.last12MonthsSubmission)}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle2">Last 12 Months Submission Count:</Typography>
              <Typography variant="body1">{record.last12MonthsSubmissionCount}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle2">LTD Funded:</Typography>
              <Typography variant="body1">{formatCurrency(record?.ltdFunded)}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle2">LTD Funded Count:</Typography>
              <Typography variant="body1">{record.ltdFundedCount}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle2">Last 12 Months Funded:</Typography>
              <Typography variant="body1">{formatCurrency(record?.last12MonthsFunded)}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle2">Last 12 Months Funded Count:</Typography>
              <Typography variant="body1">{record.last12MonthsFundedCount}</Typography>
            </Grid>
          </>
        }


      </Grid>

      <Divider style={{ marginTop: '10px', marginBottom: '10px' }} />
      <Button variant="contained" color="primary" onClick={onClose}>
        Close
      </Button>
    </Box>
  );
};

export default RecordDetails;
