import { observer } from "mobx-react";
import { Grid, Typography } from '@material-ui/core';
import { DateField, TextField, createAutocompleteAddressField, createCurrencyField, createDateField, createNumberFormatField, createPhoneField, createRadioField, createSelectField, createTextField } from "@roc/ui";
import { borrowerStatesAbbreviation, borrowerStatesNames, NO, YES } from '@roc/feature-utils';
import { citizenshipStatusOptions, idTypeOptions } from '@roc/feature-loans';
import { format, isValid } from "date-fns";
import { DriversLicenseUpload } from "../../borrowerDetailsVerification/components/driversLicenseUpload";
import { useEffect, useMemo } from 'react';

const stateOptions = borrowerStatesAbbreviation.map((abbreviation, i) => ({
  value: abbreviation,
  label: borrowerStatesNames[i],
}));

const handleNetWorthFieldsChange = (name, value, store) => {
  store.onFieldChange(name, value);
  const netWorth = store.form.fields.assets.value - store.form.fields.liabilities.value;
  store.onFieldChange('netWorth', netWorth);
};

export const Identification = observer(({ store, showDob = true, showSsn = true }) => {
  const showPassportFields = useMemo(() => {
    return store.form.fields.idType.value !== 'US Driver\'s License / Government ID';
  }, [store.form.fields.idType.value]);

  const uploadImageTitle = useMemo(() => {
    if(showPassportFields) return "Document Upload - Document ID Image*";
    return "Document Upload - Driver's License Image*";
  }, [showPassportFields]);

  useEffect(() => {
    store.onFieldChange('driversLicense', showPassportFields ? NO : YES);
  }, [showPassportFields]);

  const manageDate = (fieldName, date) => {
    let formattedDate = null;
    if (isValid(date)) {
      formattedDate = format(date, 'MM/dd/yyyy');
    }
    store.onFieldChange(fieldName, formattedDate);
  };

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h5">Identification</Typography>
      </Grid>
      {showDob ?
        <Grid item xs={12} sm={6}>
          {createDateField({
            label: 'Date of Birth',
            fieldName: 'dateOfBirth',
            testId: 'dateOfBirth',
            format: 'MM/dd/yyyy',
            store: store,
            disableFuture: true,
            onChange: manageDate,
          })}
        </Grid>
        :
        <Grid item xs={12} sm={6}>
          <DateField
            label='Date of Birth'
            standaloneLabel
            inputVariant="outlined"
            placeholder='**********'
            value={null}
            onChange={() => { }}
            required
            format="MM/dd/yyyy"
            testId="dateOfBirth"
            disabled={true}
            fullWidth
          />
        </Grid>
      }
      <Grid item xs={12} sm={6}>
        {createSelectField({
          store: store,
          testId: 'citizenshipStatus',
          fieldName: 'citizenshipStatus',
          label: 'Citizenship Status',
          options: citizenshipStatusOptions,
          placeholder: 'Make Selection',
        })}
      </Grid>
      {showSsn ?
        <Grid item xs={12} sm={6}>
          {createNumberFormatField({
            label: 'Social Security Number',
            fieldName: 'ssn',
            format: "###-##-####",
            testId: 'ssn',
            store: store,
          })}
        </Grid>
        :
        <Grid item xs={12} sm={6}>
          <TextField
            label='Social Security Number'
            standaloneLabel
            type="text"
            variant="outlined"
            value={'***********'}
            required
            testId="ssn"
            disabled={true}
            fullWidth
          />
        </Grid>
      }
      <Grid item xs={12} sm={6}>
        {createSelectField({
          store: store,
          testId: 'idType',
          fieldName: 'idType',
          label: 'ID Type',
          options: idTypeOptions,
          placeholder: 'Make Selection',
        })}
      </Grid>
      {showPassportFields ? (
        <>
          <Grid item xs={12} sm={6}>
            {createTextField({
              store,
              testId: 'passportNumber',
              fieldName: 'passportNumber',
              label: 'Passport Number',
            })}
          </Grid>
          <Grid item xs={12} sm={6}>
            {createDateField({
              store,
              label: 'Passport Expiration',
              fieldName: 'passportExpiration',
              testId: 'passportExpiration',
              format: 'MM/dd/yyyy',
            })}
          </Grid>
        </>
      ) : (
        <>
          <Grid item xs={12} sm={6}>
            {createSelectField({
              store,
              testId: 'driversLicenseState',
              fieldName: 'driversLicenseState',
              label: "Driver's License State",
              options: stateOptions,
            })}
          </Grid>
          <Grid item xs={12} sm={6}>
            {createTextField({
              store,
              testId: 'driversLicenseNumber',
              fieldName: 'driversLicenseNumber',
              label: "Driver's License Number",
            })}
          </Grid>
        </>
      )}
      <Grid item xs={12} />
      <Grid item xs={12}>
        <DriversLicenseUpload
          title={uploadImageTitle}
          file={store.driversLicense}
          onChange={file =>
            store.setDriversLicense(file)
          }
        />
      </Grid>
    </>
  )
});

export const Address = observer(({ store }) => {

  const handleAddressChange = event => {
    const { value, fullInfo } = event?.target || {};
    const { address_components, geometry } = fullInfo || {};
    store.saveAddressField(value, address_components, geometry);
  };

  return (
    <>
      <Grid item xs={12} sm={6}>
        {createAutocompleteAddressField({
          fieldName: 'address',
          label: 'Address Line 1',
          testId: 'address',
          store: store,
          onChange: handleAddressChange,
          showToolTip: false
        })}
      </Grid>
      <Grid item xs={12} />
      <Grid item xs={12} sm={6}>
        {createTextField({
          label: 'Address Line 2',
          fieldName: 'aptNumber',
          testId: 'aptNumber',
          store: store,
        })}
      </Grid>
      <Grid item xs={12} />
      <Grid item xs={12} sm={4}>
        {createSelectField({
          store: store,
          testId: 'state',
          fieldName: 'state',
          label: 'State',
          options: stateOptions,
        })}
      </Grid>
      <Grid item xs={12} sm={4}>
        {createTextField({
          label: 'City',
          fieldName: 'city',
          testId: 'city',
          store: store,
        })}
      </Grid>
      <Grid item xs={12} sm={4}>
        {createTextField({
          label: 'Zip Code',
          fieldName: 'zipCode',
          testId: 'zipCode',
          store: store,
        })}
      </Grid>
    </>
  )
});

export const NetworthInformation = observer(({ store }) => {
  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h5">Financial Information</Typography>
      </Grid>
      <Grid item xs={12} sm={4}>
        {createCurrencyField({
          label: 'Assets',
          fieldName: 'assets',
          testId: 'assets',
          store: store,
          onChange: ((name, value) => handleNetWorthFieldsChange(name, value, store)),
        })}
      </Grid>
      <Grid item xs={12} sm={4}>
        {createCurrencyField({
          label: 'Liabilities',
          fieldName: 'liabilities',
          testId: 'liabilities',
          store: store,
          onChange: ((name, value) => handleNetWorthFieldsChange(name, value, store)),
        })}
      </Grid>
      <Grid item xs={12} sm={4}>
        {createCurrencyField({
          label: 'Net Worth',
          fieldName: 'netWorth',
          testId: 'netWorth',
          store: store,
          disabled: true,
        })}
      </Grid>
    </>
  )
});

export const PhoneNumber = observer(({ store }) => {
  return <Grid item xs={12} sm={6}>
    {createPhoneField({
      label: 'Phone Number',
      fieldName: 'phoneNumber',
      testId: 'phoneNumber',
      store: store,
    })}
  </Grid>;
});
