import { GlobalStore } from "@roc/feature-app-core";
import { DrawStore } from "../../stores/drawStore";
import { ScopeOfWorkRevisionFormService } from "../services";
import { ScopeOfWorkRevisionFormParams, ScopeOfWorkV2 } from "@roc/feature-sow-shared/types";
import { ScopeOfWorkV2FormStore } from "@roc/feature-sow-shared/stores/v2";
import { LoanStore } from "@roc/feature-loans";
import { CALLBACK_TYPE } from "@roc/feature-sow-shared/constants/scopeOfWorkV2Form";
import { SegmentTrackerEvent, segmentAnalytics } from "@roc/feature-utils";

export class ScopeOfWorkRevisionFormStore extends ScopeOfWorkV2FormStore {
  private drawStore: DrawStore;
  private loanStore: LoanStore;
  params: ScopeOfWorkRevisionFormParams;
  private closeDialog: () => void;

  constructor(globalStore: GlobalStore, drawStore: DrawStore, loanStore: LoanStore, closeDialog: () => void) {
    super(globalStore);
    this.scopeOfWorkService = new ScopeOfWorkRevisionFormService();
    this.drawStore = drawStore;
    this.loanStore = loanStore;
    this.closeDialog = closeDialog;
    this.readOnlyCapex = true;
  }

  setParams(params: ScopeOfWorkRevisionFormParams) {
    this.params = params;
    this.fetchInitialData();
  }


  splitCategories = async (drawId: any) => {
    const {
      data: response
    } = await this.scopeOfWorkService.getSplitedCategories(drawId)
    return response;
  }

  *handleSplitedCategories() {
    const response = yield this.splitCategories(
      this.params.drawId
    );
    this.splitedCategoriesObj = response;
  }

  *fetchScopeOfWork() {
    try {
      const response: ScopeOfWorkV2 = yield this.getScopeOfWork(this.params);

      const categoriesResponse = yield this.getScopeOfWorkCategories();

      if (!response || !categoriesResponse) return;

      this.initParams(response);
      this.initScopeOfWork({ ...response, standalone: true }, categoriesResponse);
      this.handleSplitedCategories()
    } catch (error) {
      this.afterCall(CALLBACK_TYPE.GET, null);
    }
  }

  getScopeOfWork = async (params: ScopeOfWorkRevisionFormParams) => {
    const { data: response } = await this.scopeOfWorkService.getScopeOfWorkById(
      params.drawId.toString()
    );
    response.data.dataContent = JSON.parse(response.data.dataContent);
    return response.data;
  };

  submitScopeOfWork = async (
    params: ScopeOfWorkRevisionFormParams,
    data: any,
    silent: boolean
  ) => {
    const { data: response } = await this.scopeOfWorkService.submitScopeOfWork(
      params.drawId.toString(),
      data,
      silent
    );
    segmentAnalytics.trackEvent({
      name: SegmentTrackerEvent.SOW_SUBMISSION,
      userName: this.userStore?.userInformation?.username,
    });
    this.closeDialog();
    if (response.data) {
      this.drawStore.fetchSOWDocuments(this.loanStore.loanDetails.loanId);
      return { success: response.data };
    } else {
      return null;
    }
  };

  getScopeOfWorkShareUrl = async (params: ScopeOfWorkRevisionFormParams) => {
    const {
      data: response,
    } = await this.scopeOfWorkService.getScopeOfWorkShareUrl(
      params.drawId.toString()
    );
    return response.data;
  };
}