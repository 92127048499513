import { GlobalStore, UserStore } from '@roc/feature-app-core';
import { GENERIC_ERROR_MESSAGE } from '@roc/feature-utils';
import { computed, flow, makeObservable, observable } from 'mobx';
import {
  getBorrowerDashboardColumns,
  customDashboardColumns,
  getLeadsDashboardColumns,
  getTpoDashboardColumns,
  getBorrowerWithoutCreditInquiryColumns,
  getBrokerDashboardColumns,
} from '../components/leads/constants/leadsDashboardColumns';
import { FieldDefinition } from '../components/leadsDashboard/createList/queryBuilder/queryBuilder';
import { LeadsDashboardService } from '../services/leadsDashboardService';
import { EditLeadsDashboardStore } from './editLeadsDashboardStore';
import { LeadsDashboardGridStore } from './leadsDashboardGridStore';
import {
  BORROWER_OBJECT_TYPE,
  BORROWER_WITHOUT_CREDIT_INQUIRY,
  LEAD_OBJECT_TYPE,
  TPO_OBJECT_TYPE,
  STANDARD_DASHBOARD_TYPE,
  BROKER_OBJECT_TYPE,
} from '../components/leads/constants/constants';
import { BaseLeadStore } from 'libs/client-portal-shared/src/app/modules/companyProfile/stores/baseLeadStore/BaseLeadStore';

const DEFAULT_DASHBOARD = 'incoming_not_contacted_leads';
const LEAD_BASE_OBJECT = 'Lead';

export class LeadsDashboardStore {
  private globalStore: GlobalStore;
  private userStore: UserStore;
  private leadsDashboardService: LeadsDashboardService;

  leadsDashboardGridStore: LeadsDashboardGridStore;
  editLeadsDashboardStore: EditLeadsDashboardStore;

  dashboards: any = [];
  columns: FieldDefinition[] = [];
  standardDashboards: any = [];
  allDashboards: any = [];
  dashboardActions: any;
  currentDashboardId;

  constructor(
    globalStore: GlobalStore,
    userStore: UserStore,
    leadStore: BaseLeadStore
  ) {
    this.globalStore = globalStore;
    this.userStore = userStore;
    this.leadsDashboardService = new LeadsDashboardService();
    this.leadsDashboardGridStore = new LeadsDashboardGridStore(
      globalStore,
      this,
      leadStore
    );
    this.editLeadsDashboardStore = new EditLeadsDashboardStore(
      globalStore,
      this
    );

    makeObservable(this, {
      dashboards: observable,
      columns: observable,
      standardDashboards: observable,
      currentDashboardId: observable,
      allDashboards: observable,
      dashboardActions: observable,
      fetchDashboards: flow,
      selectDashboard: flow,
      fetchColumns: flow,
      fetchDashboardActions: flow,
      currentDashboard: computed,
      isCustomDashboard: computed,
      dashboardColumns: computed,
    });
  }

  *fetchDashboards() {
    try {
      const response = yield this.leadsDashboardService.getCustomDashboards();
      this.dashboards = JSON.parse(
        response.data.data.customDashboards
      ).dashboards;
      this.standardDashboards = response.data.data.standardDashboards;
      this.allDashboards = [...this.standardDashboards, ...this.dashboards];
      this.currentDashboardId = this.userStore.userInformation?.userPreference?.defaultCrmDashboard;
      if (this.currentDashboardId) {
        this.selectDashboard(this.currentDashboardId);
      } else {
        const defaultDashboard = this.standardDashboards.find(
          d => d.apiName === DEFAULT_DASHBOARD
        );
        this.selectDashboard(defaultDashboard?.dashboardListId);
      }
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE,
      });
    }
  }

  *fetchColumns(baseObject) {
    const columnsResponse = yield this.leadsDashboardService.getColumnFields(
      baseObject || LEAD_BASE_OBJECT
    );
    this.columns = columnsResponse.data.data;
  }

  *fetchDashboardActions(dashBoardObject) {
    let dashboardActions;

    if (dashBoardObject?.dashboardType !== STANDARD_DASHBOARD_TYPE) {
      const response = yield this.leadsDashboardService.getDashBoardActions(
        dashBoardObject.baseObject
      );
      dashboardActions = response.data;
    } else {
      dashboardActions = JSON.parse(dashBoardObject.dashboardActions);
    }
    this.dashboardActions = dashboardActions;
    this.dashboardColumns;
  }

  *selectDashboard(dashboardId) {
    this.currentDashboardId = dashboardId;
    this.leadsDashboardGridStore.resetAndFetchGridData();
    const currentDashboardAmongAll = this.getCurrentDashboardAmongAll();
    currentDashboardAmongAll?.dashboardType === STANDARD_DASHBOARD_TYPE
      ? yield this.fetchColumns(
          this.dashboards.find(
            dashboard => dashboard.id == this.currentDashboardId
          )?.baseObject
        )
      : yield this.fetchColumns(
          this.dashboards.find(
            dashboard => dashboard.id == this.currentDashboardId
          ).baseObject
        );

    yield this.fetchDashboardActions(currentDashboardAmongAll);
  }

  get currentDashboard() {
    return (
      this.standardDashboards.find(
        d => d.dashboardListId === this.currentDashboardId
      ) || this.dashboards.find(d => d.id === this.currentDashboardId)
    );
  }

  getCurrentDashboardAmongAll() {
    return this.allDashboards.find(dashboard =>
      dashboard?.dashboardType === STANDARD_DASHBOARD_TYPE
        ? dashboard?.dashboardListId === this.currentDashboardId
        : dashboard?.id === this.currentDashboardId
    );
  }

  get isCustomDashboard() {
    return this.currentDashboard?.filters;
  }

  getActionsEnabled(dashboardType) {
    if (this.dashboardActions?.actions?.[dashboardType]) {
      const roleActions = this.dashboardActions?.actions?.[dashboardType]?.[
        this.userStore.userInfo.role
      ];
      return roleActions?.length > 0;
    } else {
      return false;
    }
  }

  actionsEnabledForUser(dashboardType) {
    return this.dashboardActions
      ? this.getActionsEnabled(dashboardType)
      : false;
  }

  get dashboardColumns() {
    if (this.isCustomDashboard) {
      return customDashboardColumns(
        this.columns,
        this.currentDashboard.columns.split(',')
      );
    } else if (
      this.currentDashboard?.apiName === BORROWER_WITHOUT_CREDIT_INQUIRY
    ) {
      return getBorrowerWithoutCreditInquiryColumns(
        this.actionsEnabledForUser(BORROWER_OBJECT_TYPE)
      );
    } else if (this.currentDashboard?.objectType === BORROWER_OBJECT_TYPE) {
      return getBorrowerDashboardColumns(
        this.actionsEnabledForUser(BORROWER_OBJECT_TYPE)
      );
    } else if (this.currentDashboard?.objectType === BROKER_OBJECT_TYPE) {
      return getBrokerDashboardColumns();
    } else if (this.currentDashboard?.objectType === TPO_OBJECT_TYPE) {
      return getTpoDashboardColumns();
    }

    return getLeadsDashboardColumns(
      this.globalStore?.userFeatures?.showOwnerColumns,
      this.currentDashboard?.apiName
    );
  }
}
