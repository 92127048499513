import { GlobalStore, GridStore } from '@roc/feature-app-core';
import { LoanService } from '@roc/feature-loans';


const PAGE_SIZE = 20;



export class FundedLoansForExtensionsGridStore extends GridStore {
  globalStore: GlobalStore;
  loanService: LoanService;

  constructor(globalStore: GlobalStore) {
    super(() => this.fetchFundedLoansForExtensionRequest(), undefined, PAGE_SIZE);
    this.globalStore = globalStore;
    this.loanService = new LoanService();
  }

  fetchFundedLoansForExtensionRequest = async () => {
    try {
      const response = await this.loanService.getFundedLoansForExtensionRequest(
        this.gridData.meta.pageNumber,
        this.gridData.meta.pageSize,
        this.gridData.meta.sortDir,
        this.gridData.meta.sortBy,
        this.gridData.meta.filters,
      );
      return response;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: `Error while fetching the loans.`,
      });
    }
  }
}