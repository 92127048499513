import { Badge, Fab, Tooltip, useMediaQuery } from '@material-ui/core';
import { Theme, useTheme, makeStyles, createStyles, withStyles } from '@material-ui/core/styles';
import { Forum } from '@material-ui/icons';
import { LoanCommunicationStore } from '@roc/feature-communication';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import { useMemo } from 'react';

const StyledBadge = withStyles((theme: Theme) =>
  createStyles({
    badge: {
      fontSize: 20,
      top: -30,
      right: -30,
      height: 30,
      width: 30,
      border: `2px solid ${theme.palette.background.paper}`,
      borderRadius: 20
    }
  })
)(Badge);

const useStyles = makeStyles(() => ({
  conversation: {
    color: '#fff',
    backgroundColor: '#006dff',
    transition: 'all .7s',
    position: 'fixed',
    bottom: '30px',
    right: '30px',
    width: 72,
    height: 72,
    '&:hover': {
      backgroundColor: '#0056c9',
    }
  },
  conversationSticky: {
    position: 'sticky',
    marginLeft: 'auto',
    marginRight: '-80px',
    marginTop: '-72px',
    float: 'right',
  }
}));

interface ConversationsFABProps {
  showConversations: boolean;
  loanCommunicationStore: LoanCommunicationStore;
  onOpenConversations: () => void;
  stickyMinWidth?: number;
}

export const ConversationsFAB = observer(({
  showConversations,
  loanCommunicationStore,
  onOpenConversations,
  stickyMinWidth = 1400,
}: ConversationsFABProps) => {
  const theme = useTheme();
  const shouldIconBeSticky = useMediaQuery(theme.breakpoints.up(stickyMinWidth), { noSsr: true });
  const classes = useStyles({
    stickyMinWidth
  });
  const { totalUnreadMessagesCount } = loanCommunicationStore;
  const title = useMemo(() => totalUnreadMessagesCount ? `${totalUnreadMessagesCount} Unread Conversations` : 'Conversations', [totalUnreadMessagesCount]);

  const badgeComponent = useMemo(() => {
    let _count = undefined;
    if (!!totalUnreadMessagesCount) {
      _count = totalUnreadMessagesCount;
    }
    return _count ? <StyledBadge badgeContent={_count} color="error" /> : null;
  }, [totalUnreadMessagesCount])

  return (
    <>
      {showConversations && (
        <Tooltip title={title}>
          <Fab
            className={clsx(classes.conversation, shouldIconBeSticky ? classes.conversationSticky : '')}
            onClick={onOpenConversations}
          >
            {badgeComponent}
            <Forum fontSize='large' />
          </Fab>
        </Tooltip>
      )}
    </>
  );
});