import { GridRowSelectedEvent } from '@roc/ui';
import { ApiResponse, GlobalStore } from '@roc/feature-app-core';
import { action, makeObservable, observable } from 'mobx';
import { GridStore } from '@roc/feature-app-core';
import { BrokerService } from '../services/brokerService';
import { Broker } from '../types/brokerTypes';
import { BrokersStore } from './brokersStore';

export class BrokersGridStore extends GridStore {
  private globalStore: GlobalStore;
  brokerService: BrokerService;

  selected: Broker[];

  constructor(brokersStore: BrokersStore, globalStore: GlobalStore) {
    super(() => this.fetchBrokers());
    this.globalStore = globalStore;
    this.brokerService = brokersStore.brokerService;

    makeObservable(this, {
      selected: observable,
      onRowSelected: action,
      resetSelected: action,
    });
  }

  private async fetchBrokers() {
    try {
      const response: ApiResponse = await this.brokerService.getAllBrokers(
        this.gridData.meta.pageNumber,
        this.gridData.meta.pageSize,
        this.gridData.meta.sortDir,
        this.gridData.meta.sortBy,
        this.gridData.meta.filters
      );
      return response;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching brokers',
      });
    }
  }

  onRowSelected = (e: GridRowSelectedEvent) => {
    const { node } = e;
    const broker = node.data as Broker;
    if (node.isSelected()) {
      this.selected = this.selected.concat(node.data);
    } else {
      this.selected = this.selected.filter(b => b.brokerId == broker.brokerId);
    }
  };

  resetSelected() {
    this.selected = [];
  }
}
