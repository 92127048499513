import { Service } from '@roc/feature-app-core';

const url = {
  GET_FUNDING_TEMPLATE: '/api/v1/document/formDocument/getFundingTemplateData',
  GET_ESTIMATED_FUNDING_TEMPLATE: '/api/v1/document/formDocument/getEstimatedFundingTemplateData',
};

export class FundingTemplateService extends Service {
  getFundingTemplate(loanId: string) {
    return this.get(url.GET_FUNDING_TEMPLATE, {
      loanId,
    });
  }

  getEstimatedFundingTemplate(loanId: string, closingDate) {
    return this.get(url.GET_ESTIMATED_FUNDING_TEMPLATE, {
      loanId,
      closingDate
    });
  }
}
