import { Box, Typography, Grid } from "@material-ui/core";
import { observer } from "mobx-react";
import { IframeHolder } from "@roc/client-portal-shared/components";
import { useEffect, useState } from "react";
import ErrorIcon from '@material-ui/icons/Error';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { VerticalBarLoader } from '@roc/ui';

const useStyles = makeStyles((theme: Theme) => {
  return {
    container: {
      flexDirection: 'column',
      justifyContent: 'center',
      textAlign: 'center',
      alignItems: 'center',
      marginTop: '20px'
    },
    button: {
      width: '160px',
      marginBottom: '50px'
    },
    margins: {
      marginTop: '20px'
    }
  };
});

export const VerifyLenderBankInfoDocusignIframe = observer(({ lenderBankInformationStore }) => {
  const [docusginError, setDocusignError] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    lenderBankInformationStore.submitVerifyBankInfo();
  }, []);

  useEffect(() => {
    window.addEventListener('message', handleWindowMessage);
    return () => window.removeEventListener('message', handleWindowMessage);
  }, []);

  const handleWindowMessage = (event: MessageEvent) => {
    if (event.data == 'verifyBankInfo-docusign-success') {
      console.log('incrementando el step')
      lenderBankInformationStore.goNextStep();
      return;
    }
  };

  return (
    <>
      {lenderBankInformationStore.docusignUrl ? (
        <IframeHolder src={lenderBankInformationStore.docusignUrl} width='100%' height='100%' id={'ach'} />
      ) : (
        <VerticalBarLoader />
      )}
    </>
  );
});