import { GlobalStore } from '@roc/feature-app-core';
import { LoanService, LoanStore } from '@roc/feature-loans';
import { CLOSING } from '@roc/feature-utils';
import { flow, makeObservable, observable } from 'mobx';
import { DocumentStore } from '../../documents';
import { LoanActionsService } from '../services/loanActionsService';
import { LoanActionsStore } from './loanActionsStore';

export class SnapdocsStore {
  private globalStore: GlobalStore;
  private loanStore: LoanStore;
  private documentStore: DocumentStore;
  private loanActionsStore: LoanActionsStore;

  private loanActionsService: LoanActionsService;

  checkResult;

  constructor(globalStore: GlobalStore, loanStore: LoanStore, loanActionsStore: LoanActionsStore) {
    this.globalStore = globalStore;
    this.loanStore = loanStore;
    this.loanActionsStore = loanActionsStore;

    this.loanActionsService = new LoanActionsService();

    makeObservable(this, {
      checkResult: observable,
      createInSnapdocs: flow,
      uploadDocsToSnapdocs: flow,
    });
  }

  resetStore() {
    this.checkResult = null;
  }

  *createInSnapdocs() {
    try {
      const { loanId } = this.loanStore.loanDetails;
      const response = yield this.loanActionsService.createInSnapdocs(loanId);
      if(response.data.data.success) {
        this.loanActionsStore.refreshLoanDetails();
        this.loanActionsStore.currentAction = null;
        this.globalStore.notificationStore.showSuccessNotification({
          message: 'Success',
        });
      } else {
        this.checkResult = response.data.data;
      }
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while making request',
      });
    }
  }

  *uploadDocsToSnapdocs() {
    try {
      const { loanId } = this.loanStore.loanDetails;
      const response = yield this.loanActionsService.uploadDocsToSnapdocs(loanId);
      if(response.data.data.success) {
        this.loanActionsStore.refreshLoanDetails();
        this.loanActionsStore.currentAction = null;
        this.globalStore.notificationStore.showSuccessNotification({
          message: 'Success',
        });
      } else {
        this.checkResult = response.data.data;
      }
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while making request',
      });
    }
  }

}