import { FormStore, GlobalStore } from '@roc/feature-app-core';
import { isBlank, isNotBlank } from '@roc/feature-utils';
import { action, flow, makeObservable, observable } from 'mobx';

const contactDetailsForm = {
  fields: {
    oldEmailAddress: {
      value: '',
      error: null,
      rule: '',
      message: ''
    },
    emailAddress: {
      value: '',
      error: null,
      rule: 'required|email',
      message: 'A valid Email Address is required.'
    },
    maskedEmailAddress: {
      value: '',
      error: null,
      rule: '',
      message: ''
    },
    verifyEmailAddress: {
      value: false,
      error: null,
      rule: '',
    },
    emailAddressVerified: {
      value: false,
      error: null,
      rule: 'required',
    },
    emailAddressVerificationCode: {
      value: '',
      error: null,
      rule: [{ required_if: ['verifyEmailAddress', true] }],
      message: 'A valid code is required.',
    },
    oldPhoneNumber: {
      value: '',
      error: null,
      rule: '',
      message: '',
    },
    phoneNumber: {
      value: '',
      error: null,
      rule: 'required|regex:/^\\d{3}-?\\d{3}-?\\d{4}$/|alpha_dash',
      message: 'Phone must be a number with the format xxx-xxx-xxxx',
    },
    maskedPhoneNumber: {
      value: '',
      error: null,
      rule: '',
      message: '',
    },
    verifyPhoneNumber: {
      value: false,
      error: null,
      rule: '',
    },
    phoneNumberVerified: {
      value: false,
      error: null,
      rule: 'required',
    },
    phoneNumberVerificationCode: {
      value: '',
      error: null,
      rule: [{ required_if: ['verifyPhoneNumber', true] }],
      message: 'A valid code is required.',
    }
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export class ContactDetailsFormStore extends FormStore {

  globalStore: GlobalStore;
  allowPhoneNumberChange: boolean;
  allowEmailAddressChange: boolean;

  constructor(globalStore) {
    super({ ...contactDetailsForm }, globalStore);

    this.globalStore = globalStore;

    makeObservable(this, {
      allowPhoneNumberChange: observable,
      allowEmailAddressChange: observable,
      setAllowPhoneNumberChange: action,
      setAllowEmailAddressChange: action,
      handleEditEmailAddress: action,
      handleCancelEmailAddress: action,
      handleSaveEmailAddress: action,
      handleEditPhoneNumber: action,
      handleCancelPhoneNumber: action,
      handleSavePhoneNumber: action,
      maskEmailAddress: action,
      maskPhoneNumber: action,
      verifyEmailAddress: flow,
      verifyPhoneNumber: flow,
      verifyEmailAddressOtp: flow,
      verifyPhoneNumberOtp: flow,
    });
    this.setDefaults();
  }

  setDefaults() {
    this.setAllowPhoneNumberChange(true);
  }

  reset() {
    super.reset();
    this.setDefaults();
  }

  setAllowPhoneNumberChange(allowPhoneNumberChange: boolean) {
    this.allowPhoneNumberChange = allowPhoneNumberChange;
  }

  setAllowEmailAddressChange(allowEmailAddressChange: boolean) {
    this.allowEmailAddressChange = allowEmailAddressChange;
  }

  handleEditEmailAddress() {
    this.onFieldChange('oldEmailAddress', this.form.fields.emailAddress.value);
    this.onFieldChange('emailAddress', '');
    this.form.fields.emailAddress.error = null;
    this.setAllowEmailAddressChange(true);
  }

  handleCancelEmailAddress() {
    this.onFieldChange('emailAddress', this.form.fields.oldEmailAddress.value);
    this.onFieldChange('oldEmailAddress', '');
    this.setAllowEmailAddressChange(false);
  }

  handleSaveEmailAddress() {
    if (isBlank(this.form.fields.emailAddress.error)) {
      this.onFieldChange('maskedEmailAddress', this.maskEmailAddress(this.form.fields.emailAddress.value));
      this.onFieldChange('oldEmailAddress', '');
      this.onFieldChange('emailAddressVerified', false);
      this.setAllowEmailAddressChange(false);
    }
    else {
      this.globalStore.notificationStore.showWarningNotification({ message: 'Please enter a valid email address.', });
    }
  }

  handleEditPhoneNumber() {
    this.onFieldChange('oldPhoneNumber', this.form.fields.phoneNumber.value);
    this.onFieldChange('phoneNumber', '');
    this.form.fields.phoneNumber.error = null;
    this.setAllowPhoneNumberChange(true);
  }

  handleCancelPhoneNumber() {
    this.onFieldChange('phoneNumber', this.form.fields.oldPhoneNumber.value);
    this.onFieldChange('oldPhoneNumber', '');
    this.setAllowPhoneNumberChange(false);
  }

  handleSavePhoneNumber() {
    if (isBlank(this.form.fields.phoneNumber.error)) {
      this.onFieldChange('maskedPhoneNumber', this.maskPhoneNumber(this.form.fields.phoneNumber.value));
      this.onFieldChange('oldPhoneNumber', '');
      this.onFieldChange('phoneNumberVerified', false);
      this.setAllowPhoneNumberChange(false);
    }
    else {
      this.globalStore.notificationStore.showWarningNotification({ message: 'Please enter a valid phone number.', });
    }
  }

  maskEmailAddress = (emailAddress: string, maskChar = '*', visibleChars = 2) => {
    if (isNotBlank(emailAddress)) {
      const [name, domain] = emailAddress.split('@');
      const visibleNamePart = name.slice(0, visibleChars);
      const maskedNamePart = maskChar.repeat(name.length - visibleChars);

      const visibleDomainPart = domain.slice(0, visibleChars);
      const maskedDomainPart = maskChar.repeat(domain.length - visibleChars);

      return `${visibleNamePart}${maskedNamePart}@${visibleDomainPart}${maskedDomainPart}`;
    }
    return emailAddress;
  }

  maskPhoneNumber = (phoneNumber: string, maskChar = '*', visibleChars = 4) => {
    if (isNotBlank(phoneNumber)) {
      if (phoneNumber.length <= visibleChars) {
        return phoneNumber;
      }
      let maskedPart = phoneNumber.replace(new RegExp("[0-9]", "g"), maskChar);
      maskedPart = maskedPart.slice(0, maskedPart.length - visibleChars);
      const visiblePart = phoneNumber.slice(-visibleChars);
      return maskedPart + visiblePart;
    }
    return phoneNumber;
  }

  *verifyEmailAddress() {
    try {
      this.onFieldChange('verifyEmailAddress', true);
      this.form.fields.emailAddress.error = '';
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Verification code sent to your email address.',
      });
    } catch (error) {
      console.log(error);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while sending verification code to your email address.',
      });
    }
  }

  *verifyPhoneNumber() {
    try {
      this.onFieldChange('verifyPhoneNumber', true);
      this.form.fields.phoneNumber.error = '';
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Verification code sent to your phone number.',
      });
    } catch (error) {
      console.log(error);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while sending verification code to your phone number.',
      });
    }
  }

  *verifyEmailAddressOtp() {
    try {
      this.onFieldChange('emailAddressVerified', true);
      this.form.fields.emailAddressVerified.error = '';
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Email address verification completed successfully.',
      });
    } catch (error) {
      console.log(error);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while doing email address verification.',
      });
    }
  }

  *verifyPhoneNumberOtp() {
    try {
      this.onFieldChange('phoneNumberVerified', true);
      this.form.fields.phoneNumberVerified.error = '';
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Phone number verification completed successfully.',
      });
    } catch (error) {
      console.log(error);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while doing phone number verification.',
      });
    }
  }

}