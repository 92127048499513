import { Grid, GridProps, GridSize } from '@material-ui/core';
import { createContext, ReactNode, useContext } from 'react';

const PricerFieldsContext = createContext<{ fieldSize: GridProps }>({
  fieldSize: { xs: 12, sm: 6, md: 4 },
});

interface PricerFieldsConfigProps {
  children: ReactNode | ReactNode[];
  xs?: GridSize;
  sm?: GridSize;
  md?: GridSize;
}

export const PricerFieldsConfig = (props: PricerFieldsConfigProps) => {
  const { children, xs, sm, md } = props;
  return (
    <PricerFieldsContext.Provider value={{ fieldSize: { xs, sm, md } }}>
      {children}
    </PricerFieldsContext.Provider>
  );
};

export const GridContainer = ({ children }) => {
  const { fieldSize } = useContext(PricerFieldsContext);
  const { xs, sm, md } = fieldSize;
  return (
    <Grid item xs={xs} sm={sm} md={md}>
      {children}
    </Grid>
  );
};
