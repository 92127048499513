import { createCurrencyField, createNumberFormatField, createSelectField } from '@roc/ui';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { Grid, Theme } from '@material-ui/core';
import { ComparableProperty } from '../../types/propertyTypes';
import { formatCurrency } from '@roc/ui/utils';
import { PropertyDetail } from '../propertyDetails';
import { observer } from 'mobx-react';
import { format, isValid } from 'date-fns';
import { formatDate } from '../../utils/formatter';
import { useStore } from '@roc/client-portal-shared/hooks';



const conditionDetailsOptions = [
  {
    label: 'Very Bad',
    value: 'Very bad',
  },
  {
    label: 'Bad',
    value: 'Bad',
  },
  {
    label: 'Moderate',
    value: 'Moderate',
  },
  {
    label: 'Good',
    value: 'Good',
  },
  {
    label: 'Very Good',
    value: 'Very good',
  },
];

const propertyTypeGroupOptions = [
  {
    label: 'Single',
    value: 'single',
  },
  {
    label: 'Multi',
    value: 'multi',
  },
];



export interface EditSubjectPropertyProps {
  property: ComparableProperty;
}


export const EditSubjectProperty: React.FC<EditSubjectPropertyProps> = observer(({
  property
}) => {
  const { compToolStore } = useStore();

  const { editSubjectPropertyFormStore } = compToolStore;

  const manageDate = (fieldName, date) => {
    let formattedDate = null;
    if (isValid(date)) {
      formattedDate = format(date, 'yyyy-MM-dd');
    }
    editSubjectPropertyFormStore.onFieldChange(fieldName, formattedDate);
  };

  const formFields = editSubjectPropertyFormStore.form.fields;

  return (
    <>

      <Grid item xs={4} sm={2}>
        {createNumberFormatField({
          store: editSubjectPropertyFormStore,
          testId: 'rooms',
          fieldName: 'rooms',
          label: 'Rooms',
          allowNegative: false,
          standaloneLabel: false
        })}
      </Grid>
      <Grid item xs={4} sm={2}>
        {createNumberFormatField({
          store: editSubjectPropertyFormStore,
          testId: 'bedrooms',
          fieldName: 'bedrooms',
          label: 'Bedrooms',
          allowNegative: false,
          standaloneLabel: false
        })}
      </Grid>
      <Grid item xs={4} sm={2}>
        {createNumberFormatField({
          store: editSubjectPropertyFormStore,
          testId: 'full_bathrooms',
          fieldName: 'full_bathrooms',
          label: 'Bathrooms',
          allowNegative: false,
          standaloneLabel: false
        })}
      </Grid>
      <Grid item xs={4} sm={2}>
        {createNumberFormatField({
          store: editSubjectPropertyFormStore,
          testId: 'half_bathrooms',
          fieldName: 'half_bathrooms',
          label: 'Half Baths',
          allowNegative: false,
          standaloneLabel: false
        })}
      </Grid>
      <Grid item xs={4} sm={2}>
        {createNumberFormatField({
          store: editSubjectPropertyFormStore,
          testId: 'area',
          fieldName: 'area',
          label: 'Sq. Ft.',
          allowNegative: false,
          standaloneLabel: false
        })}
      </Grid>
      <Grid item xs={4} sm={2}>
        {createSelectField({
          store: editSubjectPropertyFormStore,
          testId: 'condition_tag',
          fieldName: 'condition_tag',
          label: 'Condition',
          options: conditionDetailsOptions,
          standaloneLabel: false
        })}
      </Grid>
      <Grid item xs={4} sm={2}>
        {createNumberFormatField({
          store: editSubjectPropertyFormStore,
          testId: 'lot_size',
          fieldName: 'lot_size',
          label: 'Lot Size',
          allowNegative: false,
          standaloneLabel: false
        })}
      </Grid>
      {/* <Grid item xs={4} sm={2}>
        {createCurrencyField({
          label: 'Taxes/Yr.',
          fieldName: 'taxes',
          testId: 'taxes',
          store: editSubjectPropertyFormStore,
          allowNegative: false,
          standaloneLabel: false
        })}
      </Grid> */}
      <Grid item xs={4} sm={2}>
        {createSelectField({
          store: editSubjectPropertyFormStore,
          testId: 'property_type_group',
          fieldName: 'property_type_group',
          label: 'Property Type',
          options: propertyTypeGroupOptions,
          standaloneLabel: false,
        })}
      </Grid>
      <Grid item xs={4} sm={2}>
        {createNumberFormatField({
          store: editSubjectPropertyFormStore,
          testId: 'year_built',
          fieldName: 'year_built',
          label: 'Yr. Built',
          format: "####",
          allowNegative: false,
          standaloneLabel: false
        })}
      </Grid>
      {compToolStore.rentalComps ? (
        <PropertyDetail label="Rent/Sq.Ft" value={formatCurrency(property.data.sqft_rent, 0)} lg={2} md={1} xs={3} variant={"h5"} />
      ) : (
        <PropertyDetail label="Price/Sq.Ft" value={formatCurrency(property.data.sqft_price, 0)} lg={2} md={1} xs={3} variant={"h5"} />
      )}
      {compToolStore.rentalComps ? (
        <PropertyDetail label="Rent Date" value={formatDate(property.data.rent_date)} lg={2} md={2} xs={6} variant={"h5"} />
      ) : (
        <PropertyDetail label="Last Sold" value={formatDate(property.data.sale_date)} lg={2} md={2} xs={6} variant={"h5"} />
      )
      }
    </>
  );
});