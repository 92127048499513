import { Page } from '@roc/feature-app-core';
import { LoanSubType } from '@roc/feature-utils';
import { Route } from 'react-router';
import { EditFixAndFlip } from '../components/editSubmission/fixAndFlip/editFixAndFlip';
import { EditFixAndFlipPro } from '../components/editSubmission/fixAndFlipPro/editFixAndFlipPro';
import { EditStabilizedBridge } from '../components/editSubmission/stabilizedBridge/editStabilizedBridge';
import { EditGroundUp } from '../components/editSubmission/groundUp/editGroundUp';
import { EditSingleProperty } from '../components/editSubmission/singleProperty/editSingleProperty';

const LOAN_BASE_PATH = ':loanId';

export const routeByLoanSubType = {
  [LoanSubType.FIX_AND_FLIP]: 'fixFlip',
  [LoanSubType.FIX_AND_FLIP_PRO]: 'fixFlipPro',
  [LoanSubType.GROUND_UP]: 'groundUp',
  [LoanSubType.STABILIZED_BRIDGE]: 'stabilizedBridge',
  [LoanSubType.SINGLE_PROPERTY]: 'singleProperty',
};

export const getEditLoanSubmissionRoutesConfig = (
  basePath: string,
  baseUrl: string
) => {
  return {
    fixFlip: (loanId?: string) => ({
      path: `${basePath}/edit-fix-flip/${LOAN_BASE_PATH}`,
      url: `${baseUrl}/edit-fix-flip/${loanId}`,
      documentTitle: 'Edit Fix and Flip Loan',
    }),
    groundUp: (loanId?: string) => ({
      path: `${basePath}/edit-ground-up/${LOAN_BASE_PATH}`,
      url: `${baseUrl}/edit-ground-up/${loanId}`,
      documentTitle: 'Edit Ground Up Loan',
    }),
    stabilizedBridge: (loanId?: string) => ({
      path: `${basePath}/edit-stabilized-bridge/${LOAN_BASE_PATH}`,
      url: `${baseUrl}/edit-stabilized-bridge/${loanId}`,
      documentTitle: 'Edit Stabilized Bridge Loan',
    }),
    singleProperty: (loanId?: string) => ({
      path: `${basePath}/edit-single-property/${LOAN_BASE_PATH}`,
      url: `${baseUrl}/edit-single-property/${loanId}`,
      documentTitle: 'Edit Single Property Rental Loan',
    }),
    fixFlipPro: (loanId?: string) => ({
      path: `${basePath}/edit-fix-flip-pro/${LOAN_BASE_PATH}`,
      url: `${baseUrl}/edit-fix-flip-pro/${loanId}`,
      documentTitle: 'Edit Fix and Flip Pro Loan',
    })
  };
};

export const getEditLoanSubmissionRoutes = (basePath: string, baseUrl: string) => {
  const config = getEditLoanSubmissionRoutesConfig(basePath, baseUrl);
  return {
    fixFlip: (
      <Route exact path={config.fixFlip().path}>
        <Page routeDefinition={config.fixFlip()}>
          <EditFixAndFlip />
        </Page>
      </Route>
    ),
    groundUp: (
      <Route exact path={config.groundUp().path}>
        <Page routeDefinition={config.groundUp()}>
          <EditGroundUp />
        </Page>
      </Route>
    ),
    stabilizedBridge: (
      <Route exact path={config.stabilizedBridge().path}>
        <Page routeDefinition={config.stabilizedBridge()}>
          <EditStabilizedBridge />
        </Page>
      </Route>
    ),
    singleProperty: (
      <Route exact path={config.singleProperty().path}>
        <Page routeDefinition={config.singleProperty()}>
          <EditSingleProperty />
        </Page>
      </Route>
    ),
    fixFlipPro: (
      <Route exact path={config.fixFlipPro().path}>
        <Page routeDefinition={config.fixFlipPro()}>
          <EditFixAndFlipPro />
        </Page>
      </Route>
    )
  };
};
