import { Box } from '@material-ui/core';
import { FilterButton } from '@roc/ui';
import { Tabs } from '@roc/ui';
import { observer } from 'mobx-react';
import { useTitleAgentLoansStore } from '../../hooks/useTitleAgentLoansStore';
import { useStore } from '@roc/client-portal-shared/hooks';



export const TitleAgentTabs = observer(({ tabs }) => {
  const { titleLoansDashboardStore } = useTitleAgentLoansStore();
  const { loanStore } = useStore();

  const getCurrentTabGridStore = () => {
    switch (loanStore.selectedTabIndex) {
      case 0:
        return titleLoansDashboardStore.foreclosureLoansGridStore;
    }
  };

  const onTabChange = (e, index) => {
    loanStore.setSelectedTabIndex(index);
  };

  const handleFilterButtonClick = () => {
    const store = getCurrentTabGridStore();
    store.toggleFilters();
  };

  const handleClearFilters = () => {
    const store = getCurrentTabGridStore();
    store.resetFilters();
  };

  return (
    <Tabs
      tabs={tabs}
      selectedTab={loanStore.selectedTabIndex}
      onTabChange={onTabChange}
      right={
        <Box mr={2}>
          <FilterButton
            onToggleFilters={handleFilterButtonClick}
            onClearFilters={handleClearFilters}
          />
        </Box>
      }
    />
  );
});
