import { BorrowerBaseballCard } from 'libs/client-portal-shared/src/app/components/borrowerBaseballCard/borrowerBaseballCard';
import { Page } from '@roc/feature-app-core';
import { BorrowerInfoCardInternalStore } from './stores/borrowerInfoCardInternalStore';

export const BorrowerInfoCardComponent = (store: BorrowerInfoCardInternalStore, borrowerId: string) => {
  store.currentBorrower = borrowerId;
  if (!borrowerId) {
    return (<></>);
  }

  return (
    <BorrowerBaseballCard store={store} />
  );
}
