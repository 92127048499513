import React from 'react';
import {
  Box,

  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,

  Typography,
  useMediaQuery,

} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { Button, TextField } from '@roc/ui';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    title: {
      paddingRight: theme.spacing(6),
    },
    dialogContent: {
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(1, 3, 3),
    },
  })
);

interface Props {
  isOpen: boolean;
  url: string;
  handleClose: () => void;
  handleCopy: () => void;
}

export const PlaidCopyUrlModal: React.FC<Props> = props => {
  const { isOpen, url, handleClose, handleCopy } = props;

  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Dialog
      disableEscapeKeyDown
      fullScreen={fullScreen}
      fullWidth
      maxWidth="sm"
      open={isOpen}
      scroll="paper"
      onClose={handleClose}
      aria-labelledby="copy-url-dialog"
    >
      <DialogTitle disableTypography>
        <Typography className={classes.title} variant="h4">
          Share URL
        </Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Grid container justifyContent="center" spacing={3}>
          <Grid item xs={12} sm={10}>
            <Typography style={{ marginBottom: '30px' }}>Copy the URL and share the link with the borrower</Typography>
            <TextField
              type="text"
              label="URL"
              name="shareUrl"
              value={url}
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              fullWidth
              testId="shareUrl"
              disabled
            />
          </Grid>
        </Grid>

        <Box mt={3}>
          <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={12} sm={4}>
              <Button
                color="primary"
                onClick={handleCopy}
                disabled={!url}
                variant="contained"
                fullWidth
                testId="send"
              >
                Copy
              </Button>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
