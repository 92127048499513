import { Grid, makeStyles } from "@material-ui/core";
import { isNil } from "@roc/feature-utils";
import { DataGrid, Paper, VerticalBarLoader } from "@roc/ui";
import { observer } from "mobx-react";
import { useEffect } from "react";


const useStyles = makeStyles(theme => ({
  paper: {
    margin: theme.spacing(2),
    size: '5px',
    '& h5': {
      fontSize: '1.2rem',
    },
    borderRadius: '1px'
  },
  containerSmall: {
    maxHeight: '220px',
    overflow: 'auto'
  },
  containerLarge: {
    maxHeight: '900px',
    overflow: 'auto'
  }

}));

export const TomorrowExpectedClosingDataGrids = observer(({ store }) => {
  const classes = useStyles();
  const today = new Date();
  const completeDate = today.toDateString();

  useEffect(() => {
    store.fetchTomorrowExpectedClosingData();
  }, []);

  const { tomorrowClosingColumns, loading } = store;
  const renderTable = (title, data, tableColumns, smallGrid = false, pinnedRow = []) => {
    return (
      <>
        <>
          <Grid item xs={6}>
            <Paper title={title} className={classes.paper}>
              <Grid container className={smallGrid ? classes.containerSmall : classes.containerLarge}>
                <DataGrid
                  columns={tableColumns}
                  rows={isNil(data) ? [] : data.rows}
                  domLayout="autoHeight"
                  pinnedBottomRowData={pinnedRow}
                />
              </Grid>
            </Paper>
          </Grid>
        </>
      </>
    );
  };

  return (
    <>
      {store.tomorrowExpectedClosingData.length == 0 || loading ? (
        <>
          <VerticalBarLoader />
        </>
      ) : (
        <Grid container spacing={2}>
          {
            renderTable(store.tomorrowExpectedClosingData.totalClosedForToday.title, store.tomorrowExpectedClosingData.totalClosedForToday, tomorrowClosingColumns.totalClosedForToday, true)}
          {
            renderTable(store.tomorrowExpectedClosingData.futureFundRequirement.title, store.tomorrowExpectedClosingData.futureFundRequirement, tomorrowClosingColumns.futureFundRequirement)}
          {tomorrowClosingColumns.closingTomorrow && !!store.tomorrowExpectedClosingData.closingTomorrow.rows.length &&
            renderTable(`Closing numbers for ${store.tomorrowExpectedClosingData.closingTomorrow.nextExpectedClosingDate}`, store.tomorrowExpectedClosingData.closingTomorrow, tomorrowClosingColumns.closingTomorrow, false, store.totalClosingNumbers)}
        </Grid>
      )};
    </>

  );
});