import { Service } from "@roc/feature-app-core";


const url = {
  VALUATION_GRID: '/nportal/rest/priv/externalForeclosure/getValuationGrid',
  SAVE_VALUATION: '/nportal/rest/priv/externalForeclosure/saveValuation',
  GET_LOAN_BY_ID: '/nportal/rest/priv/loan/getLoanByIdInternalPortal',
};

export class LoanValuationService extends Service {
  getLoanDetails(loanId: string) {
    return this.get(url.GET_LOAN_BY_ID, { loanId });
  };

  saveValuation(valuation: any) {
    return this.post(url.SAVE_VALUATION, valuation);
  };

  getValuationGrid(
    loanId: number,
    page: number,
    pageSize: number,
    sortDir: string,
    sortBy: string,
    filters: string
  ) {
    return this.get(
      url.VALUATION_GRID,
      {
        loanId,
        page,
        pageSize,
        sortDir,
        sortBy,
        filters,
      },
      {
        disableGlobalLoading: true,
      }
    );
  }
}