import { action, flow, computed, observable, makeObservable } from 'mobx';
import { GlobalStore, GridStore, ApiResponse } from '@roc/feature-app-core';
import { ScopeOfWorkInternalService } from 'apps/roc-internal-portal/src/app/modules/scopeOfWork/services/scopeOfWorkInternalService';
import { ScopeOfWorkV2Service } from '../../services/scopeOfWorkV2Service';
import { ScopeOfWorkV2Store } from './scopeOfWorkV2Store';

export enum ScopeOfWorkV2Option {
  LINK_EXISTING_SCOPE_OF_WORK = 'LINK_EXISTING_SCOPE_OF_WORK',
  NEW_SCOPE_OF_WORK = 'NEW_SCOPE_OF_WORK',
  COPY_SCOPE_OF_WORK = 'COPY_SCOPE_OF_WORK',
}

export class ScopeOfWorkV2LinkBaseStore {
  globalStore: GlobalStore;
  scopeOfWorkGridStore: GridStore;
  scopeOfWorkOption: ScopeOfWorkV2Option;
  selectedScopesOfWork: any[];
  addressMismatchDialogOpen: boolean;
  loanId: any;
  propertyId: any;
  propertyAddress: string;
  scopeOfWorkCopyGridStore: GridStore;
  scopeOfWorkService: any;
  userName: string;
  scopeOfWorkId: any;
  renderScopeOfWorkV2Link: boolean = true;


  onSuccessLink: (scopeOfWork) => void;

  constructor(globalStore: GlobalStore, isInternal: boolean, fetchScopesOfWorkData: () => Promise<ApiResponse>, fetchScopesOfWorkCopyData: () => Promise<ApiResponse>) {
    this.globalStore = globalStore;
    this.scopeOfWorkService = isInternal ? new ScopeOfWorkInternalService() : new ScopeOfWorkV2Service();
    this.scopeOfWorkGridStore = new GridStore(fetchScopesOfWorkData, null, 4);
    this.scopeOfWorkCopyGridStore = new GridStore(fetchScopesOfWorkCopyData, null, 4);
    this.scopeOfWorkOption = ScopeOfWorkV2Option.LINK_EXISTING_SCOPE_OF_WORK;
    this.selectedScopesOfWork = [];

    makeObservable(this, {
      setValues: action,
      scopeOfWorkOption: observable,
      setScopeOfWorkOption: action,
      selectedScopesOfWork: observable,
      clearSelectedScopesOfWork: action,
      selectScopeOfWork: action,
      deselectScopeOfWork: action,
      selectedScopeOfWork: computed,
      canSubmit: computed,
      addressMismatchDialogOpen: observable,
      setAddressMismatchDialogOpen: action,
      loanId: observable,
      propertyId: observable,
      propertyAddress: observable,
      reset: action,
      setRenderScopeOfWorkV2Link: action,
      renderScopeOfWorkV2Link: observable,
      setUserName: action,
      scopeOfWorkId: observable,
      userName: observable,
      setScopeOfWorkId: action,
    });
  }

  setUserName(value) {
    this.userName = value;
  }

  setRenderScopeOfWorkV2Link(value) {
    this.renderScopeOfWorkV2Link = value;
  }

  setScopeOfWorkId(value) {
    this.scopeOfWorkId = value;
  }

  setValues(loanId: any, propertyId: any, propertyAddress: string) {
    this.loanId = loanId;
    this.propertyId = propertyId;
    this.propertyAddress = propertyAddress;
  }

  setOnSuccessLink(onSuccessLink) {
    this.onSuccessLink = onSuccessLink;
  }

  setScopeOfWorkOption(scopeOfWorkOption: ScopeOfWorkV2Option) {
    this.scopeOfWorkOption = scopeOfWorkOption;
    if (scopeOfWorkOption === ScopeOfWorkV2Option.LINK_EXISTING_SCOPE_OF_WORK)
      return;
    this.clearSelectedScopesOfWork();
  }

  clearSelectedScopesOfWork() {
    this.selectedScopesOfWork = [];
  }

  selectScopeOfWork(scopeOfWork) {
    this.selectedScopesOfWork = [...this.selectedScopesOfWork, scopeOfWork];
  }

  deselectScopeOfWork(scopeOfWork) {
    this.selectedScopesOfWork = this.selectedScopesOfWork.filter(
      selectedScopeOfWork =>
        scopeOfWork.scopeOfWorkId !== selectedScopeOfWork.scopeOfWorkId
    );
  }

  get selectedScopeOfWork() {
    return this.selectedScopesOfWork[0];
  }

  get canSubmit() {
    return (
      this.scopeOfWorkOption === ScopeOfWorkV2Option.NEW_SCOPE_OF_WORK ||
      this.selectedScopesOfWork.length > 0
    );
  }

  setAddressMismatchDialogOpen(addressMismatchDialogOpen: boolean) {
    this.addressMismatchDialogOpen = addressMismatchDialogOpen;
  }

  reset() {
    this.scopeOfWorkOption = ScopeOfWorkV2Option.LINK_EXISTING_SCOPE_OF_WORK;
    this.selectedScopesOfWork = [];
    this.scopeOfWorkGridStore.reset();
    this.propertyId = null;
    this.propertyAddress = null;
    this.addressMismatchDialogOpen = false;
  }

  async fetchScopesOfWorkData() {
    try {
      const response: ApiResponse = await this.scopeOfWorkService.getUnassignedScopesOfWork(
        this.scopeOfWorkGridStore.gridData.meta.pageNumber,
        this.scopeOfWorkGridStore.gridData.meta.pageSize,
        this.scopeOfWorkGridStore.gridData.meta.sortDir,
        this.scopeOfWorkGridStore.gridData.meta.sortBy,
        this.scopeOfWorkGridStore.gridData.meta.filters,
        this.scopeOfWorkGridStore.gridData.meta.dropdownFilters,
        this.loanId,
      );
      return response;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'An error occurred while loading the Scopes of Work',
      });
    }
  }

  async fetchScopesOfWorkCopyData() {
    try {
      const response: ApiResponse = await this.scopeOfWorkService.getScopesOfWork(
        this.scopeOfWorkCopyGridStore.gridData.meta.pageNumber,
        this.scopeOfWorkCopyGridStore.gridData.meta.pageSize,
        this.scopeOfWorkCopyGridStore.gridData.meta.sortDir,
        this.scopeOfWorkCopyGridStore.gridData.meta.sortBy,
        this.scopeOfWorkCopyGridStore.gridData.meta.filters,
        this.scopeOfWorkCopyGridStore.gridData.meta.dropdownFilters,
        this.loanId
      );
      return response;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'An error occurred while loading the Scopes of Work',
      });
    }
  }
}
