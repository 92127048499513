import React from 'react';
import { Link, Step, StepLabel, Stepper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { isNil } from '@roc/feature-utils';
import { useBorrowerPortalMediaQueries } from '@roc/feature-app-core';

export interface HorizontalStepperProps {
  steps: Array<HorizontalStepObj>;
  activeStep: number;
}

export interface HorizontalStepObj {
  label?: string;
  content: JSX.Element;
  onBack?: () => void;
  onNext?: () => void;
  allowBack?: boolean;
  allowNext?: boolean;
  nextButtonText?: string;
  backButtonText?: string;
  subLabels?: Array<string>;
  handleSubLabelClick?: (number) => void;
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    stepperSection: {
      borderRadius: '16px 16px 0px 0px',
      background: 'rgba(40, 83, 145, 0.10)',
      height: '100%',
    },
    stepper: {
      background: 'transparent',
      padding: theme.spacing(2),
    },
  })
);

export const HorizontalStepper: React.FC<HorizontalStepperProps> = ({
  steps,
  activeStep,
}) => {
  const classes = useStyle();
  const { isSmallScreen } = useBorrowerPortalMediaQueries();
  return (
    <div className={classes.stepperSection}>
      <Stepper
        activeStep={activeStep}
        className={classes.stepper}
        orientation={'horizontal'}
      >
        {steps
          .filter(step => step.label)
          .map(step => (
            <Step key={step.label}>
              <StepLabel>
                {isSmallScreen && steps[activeStep].label !== step.label ? '' : step.label}
              </StepLabel>
            </Step>
          ))}
      </Stepper>
    </div>
  );
};
