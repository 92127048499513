import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { darken } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { Card, createCheckboxField } from '@roc/ui';
import { formatPhoneNumber } from '@roc/ui/utils';
import { observer } from 'mobx-react';
import { useNewLeadStore } from '../../hooks/useNewLeadStore';
import { TPO } from '../../utils/leadConstants';
import { useCRMPortalStore } from 'apps/crm-portal/src/app/hooks/useCRMPortalStore';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    background: {
      display: 'flex',
      alignItems: 'start',
    },
    card: {
      background: theme.palette.background.default,
      borderColor: darken(theme.palette.background.default, 0.1),
    },
  })
);

export const Summary = observer(() => {
  const classes = useStyles();
  const { newLeadStore } = useNewLeadStore();
  const { globalStore } = useCRMPortalStore();

  return (
    <Grid container xs={12}>
      <Grid item xs={12}>
        <Typography>Here we will list the new lead details.</Typography>
      </Grid>
      <Grid container className={classes.background}>
        <Grid item xs={12} style={{ marginTop: '20px' }}>
          <Typography variant={'h4'}>Summary</Typography>
        </Grid>
      </Grid>
      <br />
      <Grid item xs={12}>
        <Typography color="primary" variant="h6">
          Lead information
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Card
          color="secondary"
          title={
            newLeadStore.userDetails.firstName +
            ' ' +
            newLeadStore.userDetails.lastName +
            ' - ' +
            newLeadStore.leadType
          }
          className={classes.card}
        >
          <Grid item container direction="row" spacing={2}>
            <Grid item container xs={6} direction="row">
              <div>
                Phone number:&nbsp;
                <strong>
                  {formatPhoneNumber(newLeadStore.userDetails.phoneNumber)}
                </strong>
                <br />
              </div>
            </Grid>
            <Grid item container xs={6} direction="row">
              <div>
                Email:&nbsp;
                <strong>{newLeadStore.userDetails.email}</strong>
                <br />
              </div>
            </Grid>
            {newLeadStore.leadType === TPO && (
              <Grid item container xs={6} direction="row">
                <div>
                  Company name:&nbsp;
                  <strong>{newLeadStore.userDetails.companyName}</strong>
                  <br />
                </div>
              </Grid>
            )}
            <Grid item container xs={6} direction="row">
              <div>
                Lead source:&nbsp;
                <strong>{newLeadStore.userDetails.leadSource}</strong>
                <br />
              </div>
            </Grid>
            <Grid item container xs={6} direction="row">
              <div>
                Status:&nbsp;
                <strong>{'New'}</strong>
                <br />
              </div>
            </Grid>
            {newLeadStore.leadType !== 'TPO' && (
              <Grid item container xs={6} direction="row">
                <div>
                  Sub status:&nbsp;
                  <strong>{'Contacted (Messaged)'}</strong>
                </div>
              </Grid>
            )}
            {newLeadStore.isBackofficeProcessor() ||
              (newLeadStore.isBackofficeLead() && (
                <Grid item container xs={6} direction="row">
                  <div>
                    Owner name:&nbsp;
                    <strong>{newLeadStore.userDetails.ownerEmail}</strong>
                  </div>
                </Grid>
              ))}
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
});
