import { makeStyles } from '@material-ui/core/styles';
import { useBorrowerLoansRoutes, useBorrowerLoansStore } from '@roc/feature-borrower-loans';
import {
  DataGrid,
  GridColumnProps,
  LinkColumn,
  StatusChip,
  StatusType,
} from '@roc/ui';
import { formatCurrency } from '@roc/ui/utils';
import { observer } from 'mobx-react';
import { useEffect } from 'react';

const usdAmount: any = params => {
  if (params.value === '-') {
    return params.value;
  }
  return params.value && formatCurrency(Number(params.value));
};

export const borrowerLoansColumns: GridColumnProps[] = [
  {
    field: 'dealid',
    headerName: 'Loan Id',
    maxWidth: 120,
    cellRenderer: 'loanIdCellRenderer',
  },
  {
    field: 'dealName',
    headerName: 'Deal Name',
    minWidth: 200,
    cellRenderer: 'dealNameCellRenderer',
  },
  {
    field: 'status',
    headerName: 'Status',
    maxWidth: 170,
    cellRenderer: 'statusCellRenderer',
  },
  {
    field: 'loanSubType',
    headerName: 'Loan Type',
    cellRenderer: 'loanSubTypeCellRenderer',
    maxWidth: 300,
  },
  {
    field: 'amount',
    headerName: 'Amount',
    maxWidth: 140,
    valueFormatter: usdAmount,
  },
  {
    field: 'submissionDate',
    headerName: 'Submission Date',
    maxWidth: 185,
  },
  {
    field: 'closingDate',
    headerName: 'Closing Date',
    maxWidth: 185,
  },
];

const useStyles = makeStyles(theme => ({
  paginationBar: {
    border: 'none',
    borderTop: '1px solid #eee',
    padding: 8,
    background: '#fafafa',
  },
}));

export const borrowerLoansFrameworkComponents = (borrowerLoanRoutesConfig) => {

  const loanSubTypeCellRenderer = params => {
    const loanSubType = params.node?.data?.loanSubType;
    return (
      <>
        <span>{loanSubType}</span>
      </>
    );
  };

  const loanDetailsLinkCellRenderer = params => {
    const loanId = params.node?.data?.dealid;
    const url = borrowerLoanRoutesConfig.loans(loanId).children.dashboard.url;
    return LinkColumn({ ...params, url });
  };

  return {
    loanIdCellRenderer: loanDetailsLinkCellRenderer,
    dealNameCellRenderer: loanDetailsLinkCellRenderer,
    loanSubTypeCellRenderer: loanSubTypeCellRenderer,
    statusCellRenderer: ({ value }) =>
      value ? (
        <StatusChip statusType={StatusType.LOAN} label={value} size='small' />
      ) : null,
  };
}

export const BorrowerLoansMyLoansGrid = observer(() => {
  const classes = useStyles();
  const { borrowerLoansStore } = useBorrowerLoansStore();
  const { borrowerLoansGridStore } = borrowerLoansStore;
  const gridStore = borrowerLoansGridStore;
  const { borrowerLoanRoutesConfig } = useBorrowerLoansRoutes();

  useEffect(() => {
    gridStore.resetAndFetchGridData();
  }, []);

  const onSortChanged = params => {
    const sortModel = params.api.getSortModel();
    gridStore.setSortModel(sortModel);
  };

  return (
    <DataGrid
      columns={borrowerLoansColumns}
      rows={gridStore.gridData.data.rows}
      frameworkComponents={borrowerLoansFrameworkComponents(borrowerLoanRoutesConfig)}
      onSortChanged={onSortChanged}
      isLoading={gridStore.isLoading}
      sortModel={gridStore.sortModel}
      filterModel={gridStore.filterModel}
      paginationData={gridStore.gridData.meta}
      paginationBarClassName={classes.paginationBar}
      setPageNumber={gridStore.setPageNumber}
      domLayout='autoHeight'
    />
  )
});
