import { Theme, createStyles, lighten, withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
export const Accordion = withStyles((theme: Theme) =>
  createStyles({
    root: {
      border: `1px solid ${lighten(theme.palette.primary.main, 0.9)}`,
      boxShadow: 'none',
      '&:not(:last-child)': {
        borderBottom: 0,
      },
      '&:before': {
        display: 'none',
      },
      '&$expanded': {
        margin: 'auto',
      },
    },
    expanded: {},
  })
)(MuiAccordion);
export const AccordionSummary = withStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: lighten(theme.palette.primary.main, 0.9),
      borderBottom: `1px solid ${lighten(theme.palette.primary.main, 0.9)}`,
      marginBottom: -1,
      minHeight: 56,
      '&$expanded': {
        minHeight: 56,
      },
    },
    content: {
      '&$expanded': {
        margin: '12px 0',
      },
    },
    expanded: {},
  })
)(MuiAccordionSummary);
export const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(0),
  },
}))(MuiAccordionDetails);