import { Service } from '@roc/feature-app-core';

const url = {
  GET_REVENUE_RECOGNITION_DASHBOARD: '/api/v1/loan/revenueRecognition/getRevenueRecognitionDashboard',
  GET_MONTHS: '/api/v1/loan/revenueRecognition/getMonths',
  EXPORT_DASHBOARD_DATA: '/api/v1/loan/revenueRecognition/exportDashboardData',
};

export class RevenueRecognitionService extends Service {

  getRevenueRecognitionDashboard(
    type: string,
    params: any,
    page: number,
    pageSize: number,
    sortDir: string,
    sortBy: string,
    filters: object,
    dropdownFilters: object
  ) {
    return this.get(
      url.GET_REVENUE_RECOGNITION_DASHBOARD,
      {
        type,
        params: encodeURIComponent(JSON.stringify(params)),
        page,
        pageSize,
        sortDir,
        sortBy,
        filters,
        dropdownFilters
      },
      {
        disableGlobalLoading: true,
      }
    );
  }

  getMonths() {
    return this.get(
      url.GET_MONTHS,
      {},
      { disableGlobalLoading: true, }
    );
  }

  exportDashboardData(
    type: string,
    params: any,
    filters: object,) {
    return this.get(
      `${url.EXPORT_DASHBOARD_DATA}`,
      {
        type,
        params: encodeURIComponent(JSON.stringify(params)),
        filters,
      },
      {
        responseType: 'arraybuffer',
      }
    );
  };
}
