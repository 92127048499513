import { observer } from "mobx-react";
import { useStore } from '@roc/client-portal-shared/hooks';
import { Button, Card } from "@roc/ui";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { CompanyDetailsForm } from "../../lenderDetails/components/companyDetails";
import { Documents, DocumentsForm } from "../../lenderDetails/components/documents";
import { Box } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  card: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));

export const EntityInformation = observer(({ companyProfileStore }) => {
  const { lenderDetailsStore } = companyProfileStore;
  const [editEntityInformation, setEditEntityInformation] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    lenderDetailsStore.initializeForm();
  }, []);

  const handleSave = () => {
    lenderDetailsStore.submitForm();
  }

  const getEditButton = () => {
    return editEntityInformation ? undefined : () => setEditEntityInformation(true);
  }

  return (
    <Card title='ENTITY INFORMATION' className={classes.card}
      onEdit={getEditButton()}>
      <CompanyDetailsForm
        disabled={!editEntityInformation}
        showAvailableProducts={true}
        companyProfileStore={companyProfileStore}
      />
      {editEntityInformation &&
        <Box m={2} textAlign="right">
          <Button
            style={{ marginRight: 16 }}
            variant="outlined"
            onClick={() => {
              setEditEntityInformation(false);
            }}
            color="default"
            testId="cancel_changes"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              handleSave();
              setEditEntityInformation(false);
            }}
            color="primary"
            testId="save_changes"
          >
            Save
          </Button>
        </Box>
      }
      <DocumentsForm companyProfileStore={companyProfileStore} />
    </Card >

  )
})