import { Grid, TextField as AutoCompleteTextField } from '@material-ui/core';
import {
  createNumberFormatField,
  createPhoneField,
  createTextField,
} from '@roc/ui/componentBuilder';
import { FormStore } from '@roc/feature-app-core';
import { observer } from 'mobx-react';
import { Broker } from '../../types/brokerTypes';
import React from 'react';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import { FieldLabel } from '@roc/ui';
import { isNotBlank, userPortalStatusProperties } from '@roc/feature-utils';

interface BrokerField {
  store: FormStore;
  disabled?: boolean;
}

const field = component => {
  const WrappedComponent = observer(component);
  return props => (
    <Grid item xs={12}>
      <WrappedComponent {...props} />
    </Grid>
  );
};

export const AutocompleteBrokers = field(({
  options,
  onSelectExistingBroker,
  onChangeAutocomplete,
  existingBroker
}: {
  options;
  existingBroker: Broker;
  onSelectExistingBroker: (x) => void;
  onChangeAutocomplete: (x) => void;
}) => {
  return (
    <Autocomplete
      style={{ width: '100%' }}
      id="addBrokerCompanySearch"
      options={options}
      freeSolo
      filterOptions={filterOptions}
      getOptionLabel={option => option.companyName}
      value={existingBroker}
      renderInput={params => (
        <div>
          <FieldLabel> Company Name </FieldLabel>
          <AutoCompleteTextField
            {...params}
            value={existingBroker?.companyName}
            variant="outlined"
            placeholder="Search..."
          />
        </div>
      )}
      renderOption={option => (
        <React.Fragment>
          <span>{`${option.companyName}`}</span>
        </React.Fragment>
      )}
      onChange={(event: any, newValue: Broker | null) => {
        onSelectExistingBroker(newValue);
      }}
      onKeyUp={(ev: any) => onChangeAutocomplete(ev.target?.value)}
    />
  );
});

const filterOptions = createFilterOptions({
  matchFrom: 'any',
  stringify: (option: Broker) => option.companyName,
});

export const CompanyName = field((props) => {
  return createTextField({
    store: props?.store,
    label: 'Company Name',
    fieldName: 'companyName',
    testId: 'companyName',
    disabled: props.disabled || props.store.isDisabled || props.store.isEdit
  });
});

export const EinNumber = field((props) => {
  return createNumberFormatField({
    store: props?.store,
    label: 'Company Ein Number',
    fieldName: 'einNumber',
    testId: 'einNumber',
    format: '##-#######',
    disabled: props.disabled || props.store.isDisabled || props.store.isEdit
  });
});

export const FirstName = field(({ store, disabled }: BrokerField) => {
  return createTextField({
    store,
    label: 'Contact First Name',
    fieldName: 'firstName',
    testId: 'firstName',
    disabled
  });
});

export const LastName = field(({ store, disabled }: BrokerField) => {
  return createTextField({
    store,
    label: 'Contact Last Name',
    fieldName: 'lastName',
    testId: 'lastName',
    disabled
  });
});

export const Phone = field(({ store, disabled }: BrokerField) => {
  return createPhoneField({
    store,
    label: 'Contact Phone Number',
    fieldName: 'phone',
    testId: 'phone',
    disabled
  });
});

export const Email = field((props) => {
  const broker = props.store.getBroker();
  return createTextField({
    store: props?.store,
    label: 'Contact Email',
    fieldName: 'email',
    type: 'email',
    testId: 'email',
    disabled: props.disabled || isNotBlank(broker.portalStatus) && broker.portalStatus !== userPortalStatusProperties.NO_ACCOUNT.value
  });
});
