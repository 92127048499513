import { GlobalStore, WhiteLabelStore } from '@roc/feature-app-core';
import { flow, makeObservable } from 'mobx';

export abstract class BaseGlobalDataStore {
  globalStore: GlobalStore;
  whiteLabelStore: WhiteLabelStore;

  constructor(globalStore, whiteLabelStore) {
    this.globalStore = globalStore;
    this.whiteLabelStore = whiteLabelStore;
    makeObservable(this, {
      _updatePortalConfiguration: flow
    });
  }

  *_updatePortalConfiguration(portalConfigurationHost = undefined, portalId = undefined) {
    if (portalId) {
      yield this.whiteLabelStore.fetchCorrectPortalConfigurationByPortalId(portalId);
    } else if (portalConfigurationHost) {
      yield this.whiteLabelStore.fetchCorrectPortalConfigurationByHost(portalConfigurationHost);
    } else {
      throw new Error("Either portalConfigurationHost or portalId is mandatory")
    }
  }

};
