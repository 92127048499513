import { action, flow, makeObservable, observable } from 'mobx';
import { ApiResponse, GlobalStore, UserStore } from '@roc/feature-app-core';
import QualifyBorrowerRequestFormStore from './qualifyBorrowerRequestFormStore';
import { QualifyBorrowerService } from '../services/qualifyBorrowerService';
import { Borrower } from '../types';
import { CreditBackgroundService } from '@roc/feature-credit-background-check';
import { CreditBackgroundType, isNil, segmentAnalytics } from '@roc/feature-utils';
import { Task } from '@roc/feature-types';
import { BorrowersService } from '../services/borrowersService';

export class QualifyBorrowerStore {
  private globalStore: GlobalStore;
  qualifyBorrowerRequestFormStore: QualifyBorrowerRequestFormStore;
  userStore: UserStore;
  qualifyBorrowerService: QualifyBorrowerService;
  creditBackgroundService: CreditBackgroundService;
  private borrowerService: BorrowersService;
  borrower: Borrower;
  task: Task;

  constructor(
    globalStore: GlobalStore,
    userStore: UserStore
  ) {
    this.globalStore = globalStore;
    this.qualifyBorrowerRequestFormStore = new QualifyBorrowerRequestFormStore(globalStore);
    this.userStore = userStore;
    this.qualifyBorrowerService = new QualifyBorrowerService();
    this.creditBackgroundService = new CreditBackgroundService();
    this.borrower = null;
    this.task = null;
    this.borrowerService = new BorrowersService();

    makeObservable(this, {
      borrower: observable,
      task: observable,
      reset: action,
      submitQualifyBorrower: flow,
      cafProcess: flow,
      creditReportProcess: flow,
      showErrorMessage: action,
      createTaskWithFile: flow,
      uploadTaskFile: flow,
      prepareDataForLoanSubmission: flow,
      submitQualifyBorrowerWithoutValidation: flow,
    });
  }

  reset() {
    this.qualifyBorrowerRequestFormStore.reset();
    this.qualifyBorrowerRequestFormStore.resetUploads();
    this.borrower = null;
    this.task = null;
  }

  setInitialValues(borrower: Borrower) {
    this.borrower = borrower;
    this.qualifyBorrowerRequestFormStore.onFieldChange('borrowerId', borrower.borrowerId);
    if (!borrower.hasDob) {
      this.qualifyBorrowerRequestFormStore.onFieldChange('dobRequired', true);
    }
    if (!borrower.hasSsn) {
      this.qualifyBorrowerRequestFormStore.onFieldChange('ssnRequired', true);
    }
    this.qualifyBorrowerRequestFormStore.initCreditBackgroundCheck(borrower);
  }

  *submitQualifyBorrower(onSuccess?: () => void) {
    try {
      this.qualifyBorrowerRequestFormStore.runFormValidationWithMessage();
      const formFields = this.qualifyBorrowerRequestFormStore.form.fields
      this.qualifyBorrowerRequestFormStore.form.fields;

      if (!formFields.creditBackgroundCheck.value
        && !this.qualifyBorrowerRequestFormStore.uploads[0]) {
        this.showErrorMessage('File is required.')
        return;
      }
      if (this.qualifyBorrowerRequestFormStore.form.meta.isValid) {
        if (formFields.creditBackgroundCheck.value) {
          yield this.cafProcess();
        } else {
          if (formFields.dobRequired.value && !formFields.dateOfBirth.value) {
            this.showErrorMessage('Date of birth is required.')
            return;
          }
          if (formFields.ssnRequired.value && !formFields.ssn.value) {
            this.showErrorMessage('Social Security Number is required.')
            return;
          }
          yield this.creditReportProcess();
        }

        if (onSuccess) {
          onSuccess();
        }
        this.reset();
        this.globalStore.notificationStore.showSuccessNotification({
          message: 'Pre-Qualify borrower process requested successfully.',
        });
      }
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while requesting the qualify borrower process.',
      });
    }
  }

  *submitQualifyBorrowerWithoutValidation(onSuccess?: () => void) {
    try {
      const formFields = this.qualifyBorrowerRequestFormStore.form.fields;
      const { borrowerId, personalGuarantor, creditBackgroundCheck } = this.qualifyBorrowerRequestFormStore.getFormValues();
      let response;

      yield this.cafProcess(formFields.personalGuarantor.value, formFields.creditBackgroundCheck.value);

      response = yield this.borrowerService.saveBorrower({
        borrowerId,
        runCreditOnCreation: personalGuarantor,
        creditBackgroundCheck: creditBackgroundCheck,
      })
        .then(() => {
          this.globalStore.notificationStore.showSuccessNotification({
            message: 'Pre-Qualify borrower process requested successfully.',
          });
        });


      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while qualifying borrower.',
      });
    }
  }

  *cafProcess(personalGuarantor?: boolean, creditBackgroundCheck?: boolean, borrowerParam?: any,) {
    try {
      const type = creditBackgroundCheck ? CreditBackgroundType.ID_VERIFICATION : CreditBackgroundType.BG_ID_VERIFICATION_WITHOUT_NOTIFICATION;

      const borrower = borrowerParam || this.borrower;

      // if borrower is present and is not a lead, then pass borrowerId
      const borrowerId = !borrower?.leadId ? borrower?.borrowerId : null;

      // if borrower is present and is a lead, then pass sfLeadJson
      const sfLeadJson = borrower?.leadId
        ? {
          leadSfId: borrower.leadSfId,
          leadId: borrower.leadId,
          leadSource: borrower.leadSource,
          leadSourceGroup: borrower.leadSourceGroup,
          firstName: borrower.firstName,
          lastName: borrower.lastName,
          cellPhone: borrower.cellPhone,
        }
        : null;

      // segmentAnalytics.trackEvent({
      //   name: 'New Credit Background Request Submission',
      //   userName: this.userStore?.userInformation?.username,
      // });

      const response: ApiResponse = yield this.creditBackgroundService.submitCreditBackgroundRequest(
        {
          email: borrower.emailAddress,
          runCredit: creditBackgroundCheck ? personalGuarantor : false,
          runBackground: true,
          type,
          isConfirmed: true,
          isSendCAFConfirmed: true,
          isDuplicateConfirmed: true,
          isBorrower: true,
          borrowerId: borrowerId,
          sfLeadJson: sfLeadJson,
        }
      );

      return response;
    }
    catch (error) {
      console.log(error);
    }
  }

  *creditReportProcess() {
    yield this.createTaskWithFile();
    const values = this.qualifyBorrowerRequestFormStore.getFormValues();
    const data = {
      ...values,
      dob: values.dateOfBirth,
      socialSecurityNumber: values.ssn,
      taskId: this.task?.taskId,
    };
    const response = yield this.qualifyBorrowerService.qualifyBorrower(data);

    return response;
  }

  showErrorMessage(message: string) {
    this.globalStore.notificationStore.showErrorNotification({
      message,
    });
  }

  *createTaskWithFile() {
    try {
      const taskBody = {
        sectionProcess: 'BORROWER_PRE_QUALIFICATION',
        objectType: 'BORROWER',
        taskTemplateType: 'BORROWER_PRE_QUALIFICATION',
        objectId: this.borrower?.borrowerId || null,
      };
      const taskResponse = yield this.qualifyBorrowerService.createTask(taskBody);

      this.task = taskResponse.data?.data[0];

      if (this.borrower) {
        this.borrower.taskId = this.task?.taskId;
      }

      yield this.uploadTaskFile();
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while uploading the file',
      });
    }
  }

  *uploadTaskFile() {
    const fileUpload = this.qualifyBorrowerRequestFormStore.uploads[0];
    const blob = fileUpload.file.slice(0, fileUpload.file.size, fileUpload.file.type);
    const newFile = new File([blob], fileUpload.name, { type: fileUpload.file.type });
    const formData = new FormData();
    formData.append('file', newFile);
    formData.append('task', JSON.stringify(this.task));

    const fileUploadResponse = yield this.qualifyBorrowerService.uploadTaskFile(formData);
    this.task.fileDownloadId = fileUploadResponse.data?.data?.fileDownloadId;
  }

  *prepareDataForLoanSubmission(onSuccess?: () => void) {
    if (!this.qualifyBorrowerRequestFormStore.form.fields.creditBackgroundCheck.value) {
      // yield this.cafProcess(this.qualifyBorrowerRequestFormStore.form.fields.personalGuarantor.value, this.qualifyBorrowerRequestFormStore.form.fields.creditBackgroundCheck.value);
      // if (!this.qualifyBorrowerRequestFormStore.uploads[0]) {
      //   this.showErrorMessage('File is required.')
      //   return;
      // }
      // yield this.createTaskWithFile();
    }
    onSuccess();
  }
}

export default QualifyBorrowerStore;
