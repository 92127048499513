import { FC, useCallback, useState } from 'react';
import { Menu, Button, MenuItem, Icon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { SvgIconComponent } from '@material-ui/icons';

interface option {
  icon?: SvgIconComponent;
  name: string;
  action: any;
  disabled?: boolean;
}

interface IDropdownMenu {
  options: option[];
  containerClass?: any;
  showArrow?: boolean;
  children: any;
}

const useStyles = makeStyles(() => ({
  icon: {
    paddingRight: 5,
  },
}));

export const DropdownMenu = ({
  children,
  containerClass,
  options,
  showArrow,
}: IDropdownMenu) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = useCallback(event => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleOptionClick = useCallback(action => {
    if (action) action();
    handleClose();
  }, []);

  return (
    <>
      <Button onClick={handleClick} className={containerClass} data-html2canvas-ignore>
        {children}
        {showArrow &&
          (Boolean(anchorEl) ? (
            <KeyboardArrowUpIcon />
          ) : (
            <KeyboardArrowDownIcon />
          ))}
      </Button>
      <Menu
        id="simple-menu"
        getContentAnchorEl={null}
        anchorEl={anchorEl}
        keepMounted
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {options.map((option: option) => (
          <MenuItem
            key={option.name}
            onClick={() => handleOptionClick(option.action)}
            disabled={option.disabled}
          >
            {option.icon && <option.icon className={classes.icon} />}
            {option.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
