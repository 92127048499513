import { Layout } from "@roc/feature-app-core";
import { BorrowerReviewCustomTheme } from "libs/feature-borrower-review/src/components/utils/borrowerReviewCustomTheme";
import { observer } from "mobx-react";
import { AppraisalReviewReportForm } from "./appraisalReviewForm";
import { AppraisalReviewStore } from "../stores/appraisalReviewStore";


interface AppraisalReviewReportFormProps {
  loanId: number;
  propertyId: number;
  store: AppraisalReviewStore;
}

export const AppraisalReview = observer(({ loanId, propertyId, store }: AppraisalReviewReportFormProps) => {
  return (
    <div style={{ background: 'white', width: '100%' }}>
      <Layout>
        <BorrowerReviewCustomTheme>
          <AppraisalReviewReportForm propertyId={propertyId} loanId={loanId} store={store} />
        </BorrowerReviewCustomTheme>
      </Layout>
    </div>
  )
});