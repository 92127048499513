import { Service } from '@roc/feature-app-core';
import { URLSearchParams } from 'url';

export class FundingPreferenceService extends Service {
  updateFundingPreference = (loanId: string, data) => {
    const url = {
      CHOOSE_FUNDING_PREFERENCE:
        '/api/v1/document/formDocument/updateLoanFundingPreference',
    };

    return this.post(`${url.CHOOSE_FUNDING_PREFERENCE}?loanId=${loanId}`, data);
  };

  getLenderOperatingState = (lenderId: string) => {
    return this.get(
      `/api/v1/loan/getLenderOperatingState?lenderId=${lenderId}`
    );
  };
}

// TO DO: METHOD TO GET USER ROLE.
