import { observer } from 'mobx-react';
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemText,
  Chip,
  Grid,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { green, red } from '@material-ui/core/colors';
import { StatusChip, StatusType } from '@roc/ui';
import { Paper } from '@roc/ui';
import { formatCurrency } from '@roc/ui/utils';
import { LoanEconomicBridge } from './loanEconomicBridge';
import { LoanWarningMessages } from './loanWarningMessages';
import { useUserStore } from '@roc/feature-app-core';

const useStyles = makeStyles(theme => ({
  paper: {
    marginBottom: theme.spacing(2),
  },
}));

const listItem = (label, value, icon = null) => {
  return (
    <ListItem>
      <ListItemText>
        <Box display="flex">
          {icon && <Box ml={1}>{icon}</Box>}
          <Box flexGrow={1}>{label}</Box>
          <Box>{value}</Box>
        </Box>
      </ListItemText>
    </ListItem>
  );
};

const BooleanChip = ({ value }) => {
  const color = value ? green : red;
  return (
    <Chip
      label={value ? 'Yes' : 'No'}
      style={{
        fontWeight: 0,
        backgroundColor: color[200],
        color: color[900],
      }}
    />
  );
};

const divider = <Divider variant="middle" component="li" />;

const formatPercentage = value =>
  `${value != null ? Number(value).toFixed(2) : ''}%`;

export const LoanKeyInformationBridge = observer(
  ({ loanDetails, closingRequirements }) => {
    const classes = useStyles();
    const {
      amount,
      expectedClosingDate,
      aggregateLoanToCurrentCostInitialA,
      constructionHoldbackToRenovationBudgetPercent,
      aggregateAfterRepairLoanToValue,
      status,
      initialLoanAmount,
      constructionHoldback,
      duration,
      fullPrincipalInterest,
      fullPrincipalInterestTime,
      interestReserve,
      prepayPenaltyMonths,
      pledgeOfShares,
      origLenderSubordination,
      buybackRight,
      buybackAfterMonths,
      buybackSkimReduction,
      participationPreference,
      loanClosingData: {
        readyToClose,
      }
    } = loanDetails;

    const shouldDisplayFpiTime = () => {
      return (
        fullPrincipalInterest &&
        fullPrincipalInterestTime &&
        fullPrincipalInterestTime > 0
      );
    };

    const getKeyRatios = () => {
      return [
        aggregateLoanToCurrentCostInitialA,
        constructionHoldbackToRenovationBudgetPercent,
        aggregateAfterRepairLoanToValue,
      ]
        .filter(item => item)
        .join(' / ');
    };

    const getFpiAfter = () => {
      return `${fullPrincipalInterestTime} months`;
    };

    const { closingStatusMessages, creditExpiryMessages, appraisalWarningMessages } = closingRequirements;
    const { userStore } = useUserStore();
    const allowLoanPricing = userStore.allowLoanPricing;
    return (
      <Paper title="KEY INFORMATION" className={classes.paper}>
        <Grid container>
          <Grid item xs={12} lg={6}>
            <Box p={2}>
              <List>
                <ListItem>
                  <Typography variant="h5">Loan Terms</Typography>
                </ListItem>
                {listItem('Loan Amount', formatCurrency(amount))}
                {divider}
                {listItem('Expected Closing Date', expectedClosingDate)}
                {divider}
                {listItem('Key Ratios', getKeyRatios())}
                {divider}
                {listItem(
                  'Status',
                  <StatusChip
                    statusType={StatusType.LOAN}
                    label={status}
                    variant="filled"
                  />
                )}
                {divider}
                {listItem('Ok to close',
                <BooleanChip value={readyToClose}/>
                )}
                {divider}
                {listItem(
                  'Initial Loan Amount',
                  formatCurrency(initialLoanAmount)
                )}
                {divider}
                {listItem(
                  'Construction Holdback',
                  formatCurrency(constructionHoldback)
                )}
                {divider}
                {listItem('Duration (months)', duration)}
                {divider}
                {listItem(
                  'Full Princ Interest',
                  <BooleanChip value={fullPrincipalInterest} />
                )}
                {shouldDisplayFpiTime() ? (
                  <>
                    {divider}
                    {listItem('FPI after', getFpiAfter())}
                  </>
                ) : null}
                {divider}
                {listItem('Interest Reserve', formatCurrency(interestReserve))}
                {divider}
                {listItem('Prepay Penalty (months)', prepayPenaltyMonths)}
                {divider}
                {listItem(
                  'Pledge of Shares',
                  <BooleanChip value={pledgeOfShares} />
                )}
              </List>
            </Box>
          </Grid>
          <Grid item xs={12} lg={6}>
            {allowLoanPricing &&
              <Box p={2}>
                <List>
                  <ListItem>
                    <Typography variant="h5">Lender Terms</Typography>
                  </ListItem>
                  {listItem(
                    'Subordination',
                    formatPercentage(origLenderSubordination)
                  )}
                  {divider}
                  {listItem(
                    'Buyback Right',
                    <BooleanChip value={buybackRight} />
                  )}
                  {divider}
                  {listItem('Buyback After (months)', buybackAfterMonths)}
                  {divider}
                  {listItem(
                    'Buyback Skim Reduction',
                    formatPercentage(buybackSkimReduction)
                  )}
                  {divider}
                  {listItem('Participation Preference', participationPreference)}
                </List>
              </Box>
            }
            <Box p={2}>
              <List>
                <ListItem>
                  <Typography variant="h5">Splits</Typography>
                </ListItem>
                <LoanEconomicBridge loanDetails={loanDetails} />
              </List>
            </Box>
          </Grid>
          {!readyToClose ? (
            <LoanWarningMessages warnings={closingStatusMessages} title={"Why loan can't be closed?"} />
          ) : null}
          {creditExpiryMessages?.length > 0 && (
            <LoanWarningMessages warnings={creditExpiryMessages} title={"Credit Expiry Warnings"} />
          )}
          {appraisalWarningMessages?.length > 0 && (
            <LoanWarningMessages warnings={appraisalWarningMessages} title={"Appraisal Warnings"} />
          )}
        </Grid>
      </Paper>
    );
  }
);
