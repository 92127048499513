import { Service } from "@roc/feature-app-core";


const url = {
  GET_ALL_LENDER_ADMIN_USERS: '/api/v1/user/getAllLenderAdminUsers',
};

export class ContactInformationService extends Service {

  getAllLenderAdminUsers() {
    return this.get(url.GET_ALL_LENDER_ADMIN_USERS);
  }
}
