import { Box, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Theme, Typography } from "@material-ui/core";
import { observer } from "mobx-react";
import { makeStyles } from '@material-ui/core/styles';
import { AppraisalReviewStore } from "../../stores";
import { AppraisalReviewCarousel } from "../appraisalReviewCarousel";


const useStyles = makeStyles((theme: Theme) => ({
  tableContainer: {
    borderRadius: '10px',
    border: '1px #e0e0e0 solid',
  },
  table: {
    margin: 0,
    padding: 0,
    '& td': {
      border: '1px solid #e0e0e0',
    },
    '& th:first-child, td[rowSpan], $addNewItemRow td': {
      paddingLeft: theme.spacing(2),
      paddingRight: '3px',
    },
    '& table tr:last-child td, & table td[rowSpan]': {
      borderBottom: 'none',
    },
  },
  titleCell: {
    height: '46px',
    paddingTop: 10,
    paddingLeft: 16,
    overflow: 'hidden',
    backgroundColor: '#E2F6FF',
    color: '#757575',
    fontSize: 20,
    fontWeight: 400
  },
  title: {
    fontSize: 20
  },
  addressGrid: {
    padding: '25px 25px 0 25px',
  },
  addressWrapper: {
    '& .MuiInputBase-root.Mui-disabled': {
      backgroundColor: theme.palette.grey[50],
    },
    marginBottom: '30px',
    width: '100%',
  },
}));

interface Props {
  loanId: number;
  propertyId: number;
  store: AppraisalReviewStore;
}

const AppraisalReviewPhotos = observer((props: Props) => {
  const { store } = props;
  const classes = useStyles();
  const automatedReviewPhotosSections = store.automatedReviewPhotosSections;
  const subjectSection = automatedReviewPhotosSections.find(section => section.sectionName === "Subject");
  const salesComparableSection = automatedReviewPhotosSections.find(section => section.sectionName === "Sales Comparable");
  const rentalComparableSection = automatedReviewPhotosSections.find(section => section.sectionName === "Rental Comparable");

  return (
    <>
      <Box mt={3}>
        <TableContainer className={classes.tableContainer}>
          <Table className={classes.table} >
            <TableHead>
              <TableRow>
                <TableCell className={classes.titleCell}>
                  <Typography className={classes.title}>Subject</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <Grid container item direction="column" className={classes.addressGrid} xs={12} alignContent="center">
                  <AppraisalReviewCarousel photosSection={subjectSection} />
                </Grid>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box mt={3}>
        <TableContainer className={classes.tableContainer}>
          <Table className={classes.table} >
            <TableHead>
              <TableRow>
                <TableCell className={classes.titleCell}>
                  <Typography className={classes.title}>Sales Comparables</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <Grid container item direction="column" className={classes.addressGrid} xs={12} alignContent="center">
                  <AppraisalReviewCarousel photosSection={salesComparableSection} />
                </Grid>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box mt={3}>
        <TableContainer className={classes.tableContainer}>
          <Table className={classes.table} >
            <TableHead>
              <TableRow>
                <TableCell className={classes.titleCell}>
                  <Typography className={classes.title}>Rental Comparables</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <Grid container item direction="column" className={classes.addressGrid} xs={12} alignContent="center">
                  <AppraisalReviewCarousel photosSection={rentalComparableSection} />
                </Grid>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  )
})

export default AppraisalReviewPhotos;