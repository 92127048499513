import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { DomainSetupLite } from './components/domainSetup';
import { WhiteLabelConfigs } from './components/whiteLabelConfigs';
import { useWhiteLabelStore } from './hooks/useWhiteLabelStore';

export enum UploadType {
  FAV_ICON = 'FAV_ICON',
  APP_LOGO = 'APP_LOGO',
  BANNER_IMAGE = 'BANNER_IMAGE',
}

export enum WhiteLabelState {
  DOMAIN_NOT_SETUP,
  HAS_WHITE_LABEL_CONFIGURATION
}

export const WhiteLabel = observer(() => {
  const { whiteLabelConfigStore } = useWhiteLabelStore();
  useEffect(() => {
    whiteLabelConfigStore.hasWhiteLabeledConfig();
  }, []);

  return (
    <>
      {
        whiteLabelConfigStore.whiteLabelState == WhiteLabelState.DOMAIN_NOT_SETUP && <DomainSetupLite />
      }
      {
        whiteLabelConfigStore.whiteLabelState == WhiteLabelState.HAS_WHITE_LABEL_CONFIGURATION && <WhiteLabelConfigs />
      }
    </>
  );
});
