import React, { useEffect, useState } from 'react';
import { Box, Button, Checkbox, Grid, IconButton, ListItemIcon, ListItemText, Menu, Paper, TextField, Typography } from '@material-ui/core';
import { lighten } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import { GridStore, Layout, useBaseStore } from '@roc/feature-app-core';
import { DataGrid, GridColumnProps, GridRowSelectedEvent, RowNode, SelectMode } from '../dataGrid';
import { formatCurrency } from '../../utils/loanUtils/loanUtils';
import { ConfirmDialog, ConfirmationButton, ConfirmationIconButton, ConfirmationMenuItem, DialogState, MenuItem, StandardDialog, SubmitButton, Tabs } from '@roc/ui';
import { Add, Edit, Delete, CheckCircleOutline, AssignmentTurnedInOutlined, MoreVert, CancelOutlined, PlaylistAddCheck, Search } from '@material-ui/icons';
import { useBorrowerBaseballCardStore } from 'libs/feature-borrowers/src/borrowers/hooks/useBorrowerBaseballCardStore';
import { TRACK_RECORD_USER_INPUTTED, TRACK_RECORD_REVIEW_TYPE, TRACK_RECORD_CAPITAL_PROVIDER, TRACK_RECORD_TEMPLATE } from 'libs/feature-utils/src/utils/constants';
import PropertyInformationDialog from './propertyInformationDialog';
import { format, isWednesday } from 'date-fns';
import { ADD_PROPERTY, EDIT_PROPERTY } from './utils';
import { BorrowerBaseballCardBaseStore } from 'libs/feature-borrowers/src/borrowers/stores/borrowerBaseballCardBaseStore';
import { BorrowerInfoCardInternalStore } from 'apps/roc-internal-portal/src/app/modules/borrowerInfoCard/stores/borrowerInfoCardInternalStore';
import { tabIndex } from '@roc/client-portal-shared/modules/lenderDetails';
import { BorrowerBaseballCardStore, SelectBorrowerEntityGrid } from '@roc/feature-borrowers';
import LinkEntityDialog from './linkEntityDialog';
import EntityModal, { BorrowerEntityModalState } from 'libs/feature-loans/src/loanSubmission/components/entityModal';
import { BORROWER_REVIEW_TYPES } from 'libs/feature-borrower-review/src/components/utils/backgroundUtils';
import TrackRecordAuditLogs from './trackRecordAuditLogs';

const useStyles = makeStyles(theme =>
  createStyles({
    paper: {
      borderRadius: theme.spacing(2),
      boxShadow:
        'rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px',
      marginRight: theme.spacing(2),
      backgroundColor: theme.palette.background.default,
    },
    textField: {
      backgroundColor: 'white',
      shrink: true,
      "& input.Mui-disabled": {
        color: "black"
      }
    },
    table: {
      marginTop: '1.5em',
      marginBottom: '2em'
    },
    tab: {
      marginBottom: theme.spacing(2),
    },
    buttonsGrid: {
      display: 'flex',
      alignItems: 'center',
      alignContent: 'center',
      paddingTop: '10px'
    },
    closeButton: {
      display: 'flex',
      alignItems: 'center',
      alignContent: 'center'
    },
    addressWrapper: {
      '& .MuiInputBase-root.Mui-disabled': {
        backgroundColor: theme.palette.grey[50],
      },
      padding: '16px',
    },
    addressWrapperTab: {
      '& .MuiInputBase-root.Mui-disabled': {
        backgroundColor: theme.palette.grey[50],
      },
      paddingLeft: '16px',
      paddingRight: '16px',
      paddingBottom: '26px',

    },
  })
);

export const filterProps = () => ({
  filter: 'agTextColumnFilter',
  floatingFilter: true,
  floatingFilterComponentParams: {
    suppressFilterButton: true,
  },
  filterParams: {
    caseSensitive: false,
  }
});

const usdAmount: any = params => {
  const value = params.value ?? 0;
  return formatCurrency(Number(value));
};
const noOpComparator = () => 0;
const boolToStr = param => {
  return param?.value ? 'Yes' : 'No';
}
const borrowingEntitiesCol: GridColumnProps[] = [
  {
    field: 'entityName',
    headerName: 'Entity Name',
    minWidth: 200,
    comparator: noOpComparator,
  },
  {
    field: 'entityVerified',
    headerName: 'Verified',
    minWidth: 100,
    comparator: noOpComparator,
  },
];
const verifiedExperienceCols: GridColumnProps[] = [
  {
    field: 'entityName',
    headerName: 'Entity Name',
    minWidth: 150,
    comparator: noOpComparator,
    ...filterProps(),
  },
  {
    field: 'purchaseAddress',
    headerName: 'Property Address',
    minWidth: 200,
    comparator: noOpComparator,
    ...filterProps(),
  },
  {
    field: 'purchaseCity',
    headerName: 'City',
    minWidth: 150,
    comparator: noOpComparator,
    ...filterProps(),
  },
  {
    field: 'purchaseState',
    headerName: 'State',
    minWidth: 100,
    comparator: noOpComparator,
    ...filterProps(),
  },
  {
    field: 'purchaseDate',
    headerName: 'Purchase Date',
    minWidth: 120,
    comparator: noOpComparator,
    ...filterProps(),
  },
  {
    field: 'purchasePrice',
    headerName: 'Purchase Amount',
    minWidth: 180,
    valueFormatter: usdAmount,
    comparator: noOpComparator,
    ...filterProps(),
  },
  {
    field: 'rehabBudget',
    headerName: 'Rehab Budget',
    minWidth: 150,
    valueFormatter: usdAmount,
    comparator: noOpComparator,
    ...filterProps(),
  },
  {
    field: 'mortgageAmount',
    headerName: 'Mortgage Amount',
    minWidth: 180,
    valueFormatter: usdAmount,
    comparator: noOpComparator,
    ...filterProps(),
  },
  {
    field: 'saleDate',
    headerName: 'Sale Date',
    minWidth: 120,
    comparator: noOpComparator,
    ...filterProps(),
  },
  {
    field: 'salePrice',
    headerName: 'Sale Amount',
    minWidth: 150,
    valueFormatter: usdAmount,
    comparator: noOpComparator,
    ...filterProps(),
  },
  {
    field: 'exitStrategy',
    headerName: 'Exit Strategy',
    minWidth: 150,
    comparator: noOpComparator,
    ...filterProps(),
  },
  {
    field: 'recordSourceType',
    headerName: 'Source Type',
    minWidth: 150,
    comparator: noOpComparator,
    ...filterProps(),
  },
];
const propertiesPurchasedCols: GridColumnProps[] = [
  {
    field: 'isVerificationRequested',
    headerName: 'Verification Requested',
    minWidth: 100,
    valueFormatter: boolToStr,
    comparator: noOpComparator,
  },
  {
    field: 'entityName',
    headerName: 'Entity Name',
    minWidth: 150,
    comparator: noOpComparator,
    ...filterProps(),
  },
  {
    field: 'soldTo',
    headerName: 'Buyer Name',
    minWidth: 150,
    comparator: noOpComparator,
    ...filterProps(),
  },
  {
    field: 'purchaseAddress',
    headerName: 'Property Address',
    minWidth: 200,
    comparator: noOpComparator,
    ...filterProps(),
  },
  {
    field: 'purchaseCity',
    headerName: 'City',
    minWidth: 150,
    comparator: noOpComparator,
    ...filterProps(),
  },
  {
    field: 'purchaseState',
    headerName: 'State',
    minWidth: 100,
    comparator: noOpComparator,
    ...filterProps(),
  },
  {
    field: 'purchaseDate',
    headerName: 'Purchase Date',
    minWidth: 150,
    comparator: noOpComparator,
    ...filterProps(),
  },
  {
    field: 'purchasePrice',
    headerName: 'Purchase Amount',
    minWidth: 180,
    valueFormatter: usdAmount,
    comparator: noOpComparator,
    ...filterProps(),
  },
  {
    field: 'rehabBudget',
    headerName: 'Rehab Budget',
    minWidth: 150,
    valueFormatter: usdAmount,
    comparator: noOpComparator,
    ...filterProps(),
  },
  {
    field: 'mortgageAmount',
    headerName: 'Mortgage Amount',
    minWidth: 180,
    valueFormatter: usdAmount,
    comparator: noOpComparator,
    ...filterProps(),
  },
  {
    field: 'purchasedFrom',
    headerName: 'Seller Name',
    minWidth: 150,
    comparator: noOpComparator,
    ...filterProps(),
  },
  {
    field: 'lenderName',
    headerName: 'Lender Name',
    minWidth: 150,
    comparator: noOpComparator,
    ...filterProps(),
  },
  {
    field: 'saleDate',
    headerName: 'Sale Date',
    minWidth: 120,
    comparator: noOpComparator,
    ...filterProps(),

  },
  {
    field: 'salePrice',
    headerName: 'Sale Amount',
    minWidth: 150,
    valueFormatter: usdAmount,
    comparator: noOpComparator,
    ...filterProps(),
  },
  {
    field: 'recordSourceType',
    headerName: 'Source Type',
    minWidth: 150,
    comparator: noOpComparator,
    ...filterProps(),
  },
];

const VerifiedTrackRecordGrid = observer(({ store, frameworkComponents, internal = false }) => {
  const gridStore: GridStore = store.verifiedPropertiesGrid;

  useEffect(() => {
    gridStore.resetAndFetchGridData();
  }, [])

  const getCols = () => {
    if (internal) {
      return [
        ...verifiedExperienceCols,
        {
          field: 'actions',
          headerName: '',
          minWidth: 80,
          cellRenderer: 'actionCellRenderer',
          comparator: noOpComparator,
        }
      ];
    }
    return verifiedExperienceCols;
  }

  const [cols, setCols] = useState(getCols());

  const onSortChanged = params => {
    const sortModel = params.api.getSortModel();
    gridStore.setSortModel(sortModel);
  };

  const onFilterChanged = params => {
    const filterModel = params.api.getFilterModel();
    gridStore.setFilterModel(filterModel);
  };

  return (
    <DataGrid
      columns={cols}
      rows={gridStore.gridData.data.rows}
      domLayout="autoHeight"
      frameworkComponents={frameworkComponents}
      isLoading={store.verifiedPropertiesGrid.isLoading}
      sortModel={store.verifiedPropertiesGrid.sortModel}
      filterModel={store.verifiedPropertiesGrid.filterModel}
      showFilters={store.verifiedPropertiesGrid.showFilters}
      paginationData={store.verifiedPropertiesGrid.gridData.meta}
      setPageNumber={store.verifiedPropertiesGrid.setPageNumber}
      onFilterChanged={onFilterChanged}
      onSortChanged={onSortChanged}
    />
  );
});


const SelectUnverifiedProperty = observer(({ store, node }) => {
  const id = node.data?.verifiedTrackRecordsId ?? node.data?.temporaryId
  const isSelected = store.oldSelectedProperties.some(
    p => id === (p.verifiedTrackRecordsId ?? p.temporaryId)
  )
  return (
    <Checkbox
      checked={isSelected}
      onChange={(e, checked) => {
        store.setOldSelectedProperty(node.data, checked);
      }}
      color="primary"
    />
  );
})

const UnverifiedTrackRecordGrid = observer(({
  store,
  frameworkComponents,
  internal = false,
}) => {
  const gridStore: GridStore = store.unverifiedPropertiesGrid;

  useEffect(() => {
    gridStore.resetAndFetchGridData();
  }, [])

  const getCols = () => {
    if (internal) {
      return [
        {
          field: 'verifiedTrackRecordsId',
          headerName: '',
          maxWidth: 80,
          cellRenderer: 'selectedCellRenderer',
          comparator: noOpComparator,
        },
        ...propertiesPurchasedCols,
        {
          field: 'actions',
          headerName: '',
          minWidth: 80,
          cellRenderer: 'actionCellRenderer',
          comparator: noOpComparator,
        }
      ];
    }
    return propertiesPurchasedCols;
  }

  const [cols, setCols] = useState(getCols());

  const setPageNumber = (pageNumber, fetch = true) => {
    gridStore.setPageNumber(pageNumber, fetch);
  }

  const onSortChanged = params => {
    const sortModel = params.api.getSortModel();
    gridStore.setSortModel(sortModel);
  };

  const onFilterChanged = params => {
    const filterModel = params.api.getFilterModel();
    gridStore.setFilterModel(filterModel);
  };

  return (
    <DataGrid
      key={'page-' + gridStore.gridData.meta.pageNumber}
      columns={cols}
      rows={gridStore.gridData.data.rows.concat([])}
      domLayout="autoHeight"
      frameworkComponents={{
        ...frameworkComponents,
        selectedCellRenderer: params => {
          return <SelectUnverifiedProperty store={store} node={params.node} />
        },
      }}
      sortModel={gridStore.sortModel}
      filterModel={gridStore.filterModel}
      showFilters={gridStore.showFilters}
      paginationData={gridStore.gridData.meta}
      setPageNumber={setPageNumber}
      onFilterChanged={onFilterChanged}
      onSortChanged={onSortChanged}
    />
  );
});

const formatDate = (date) => {
  if (date == null || date == '') return '';
  return format(new Date(date), 'MM/dd/yyyy');
};

interface Props {
  store: BorrowerInfoCardInternalStore | BorrowerBaseballCardStore;
  isInternal: Boolean
}

export const BorrowerTrackRecord = observer(({ store, isInternal = false }: Props) => {
  const { currentBorrower, baseballCardDetails, entityStore } = store;
  const classes = useStyles();
  const { tab } = classes;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const [verifiedTrackRecordsId, setTrackRecordId] = useState();
  const [recordSourceType, setRecordSourceType] = useState('');
  const [isVerificationRequested, setVerificationRequested] = useState();
  const [isVerified, setVerified] = useState();
  const [unverifiedProperties, setUnverifiedProperties] = useState();
  const [verifiedProperties, setVerifiedProperties] = useState();
  const [record, setRecord] = useState();
  const [purchaseDate, setPurchaseDate] = useState('');
  const [saleDate, setSaleDate] = useState('');
  const [propertyDialogTitle, setPropertyDialogTitle] = useState(ADD_PROPERTY);
  const [selectEntityOpen, setSelectEntityOpen] = useState(false);
  const [addEntityOpen, setAddEntityOpen] = useState(false);
  const [confirmCompleteReviewModalOpen, setConfirmCompleteReviewModalOpen] = useState(false);
  const [entityMenuAnchorEl, setEntityMenuAnchorEl] = React.useState<null | HTMLElement>(null);
  const entityMenuOpen = Boolean(entityMenuAnchorEl);
  const [currentEntity, setCurrentEntity] = useState(null);

  const onTabSelect = (e: any, index: number): void => {
    store.setSelectedTab(index);
  };

  useEffect(() => {
    store.fetchBaseballCardData(
      store.currentBorrower,
      store.loanId ? parseInt(store.loanId) : null
    );
    store.baseballCardDetails = null;
    store.verifiedTrackRecord = [];
    store.unverifiedTrackRecord = [];
  }, []);

  useEffect(() => {
    if (store.selectedTab === 0) {
      store.fetchVerifiedTrackRecord(store.currentBorrower, store.loanId);
    } else if (store.selectedTab === 1) {
      store.fetchUnverifiedTrackRecord(store.currentBorrower);
      store.fetchPublicTrackRecord(store.currentBorrower);
    }
  }, [store.selectedTab])

  const getEntitiesCols = () => {
    if (isInternal) {
      return [
        ...borrowingEntitiesCol,
        {
          field: 'actions',
          headerName: '',
          minWidth: 80,
          maxWidth: 100,
          cellRenderer: 'entityActionCellRenderer',
          comparator: noOpComparator,
        }
      ];
    }
    return borrowingEntitiesCol;
  }

  const [entitiesCols, setEntitiesCols] = useState(getEntitiesCols());

  const tabs = [
    {
      label: 'VERIFIED PROPERTIES',
    },
    {
      label: 'UNVERIFIED PROPERTIES',
    },
  ].filter(Boolean);

  const lockedDataTabs = [{ label: 'VERIFIED PROPERTIES' }]

  const handleCloseConfirmCompleteReviewModal = () => {
    setConfirmCompleteReviewModalOpen(false);
  }

  const handleConfirmationCompleteReviewAction = () => {
    store.submitReview(BORROWER_REVIEW_TYPES.BORROWER_TRACK_RECORD_REVIEW);
    handleCloseConfirmCompleteReviewModal();
  }

  const frameworkComponents = {
    actionCellRenderer: params => {
      return (
        <Box>
          {params.node.data?.recordSourceType != TRACK_RECORD_CAPITAL_PROVIDER && (
            <IconButton onClick={e => openMenu(e, params.node.data)}>
              <MoreVert color="primary" />
            </IconButton>
          )}
        </Box>
      );
    },
    entityActionCellRenderer: params => {
      return (
        <Box>
          {!baseballCardDetails?.lockedData && (
            <IconButton onClick={e => openEntityMenu(e, params.node.data)}>
              <MoreVert color="primary" />
            </IconButton>
          )}
        </Box>
      )
    },
  };

  const openMenu = (event: React.MouseEvent<HTMLElement>, record) => {
    const { verifiedTrackRecordsId, recordSourceType, isVerificationRequested, isVerified, purchaseDate, saleDate } = record;
    setAnchorEl(event.currentTarget);
    setTrackRecordId(verifiedTrackRecordsId);
    setRecordSourceType(recordSourceType);
    setVerificationRequested(isVerificationRequested);
    setVerified(isVerified);
    setRecord(record);
    setPurchaseDate(formatDate(purchaseDate));
    setSaleDate(formatDate(saleDate));
    record.purchaseDate = formatDate(purchaseDate);
    record.saleDate = formatDate(saleDate);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setTrackRecordId(null);
    setRecordSourceType('');
    setVerificationRequested(null);
    setRecord(null);
    setPurchaseDate(null);
    setSaleDate(null);
  };

  const openEntityMenu = (event: React.MouseEvent<HTMLElement>, entity) => {
    setEntityMenuAnchorEl(event.currentTarget);
    setCurrentEntity(entity);
  };

  const handleEntityMenuClose = () => {
    setEntityMenuAnchorEl(null);
    setCurrentEntity(null);
  };

  const handleRemoveEntity = async () => {
    if (currentEntity) {
      await store.removeEntity(currentEntity.borrowerEntityBorrowerId);
      refreshEntities();
      handleEntityMenuClose();
    }
  }

  const handleVerify = async () => {
    await store.verifyTrackRecord(record, store.loanId, 'Not Selected');
    refreshDataWithPublic();
    handleMenuClose();
  }

  const handleMultipleVerify = async () => {
    for (let property of store.oldSelectedProperties) {
      property.purchaseDate = formatDate(property.purchaseDate);
      property.saleDate = formatDate(property.saleDate);
    }
    await store.verifyMultipleTrackRecords(store.oldSelectedProperties, store.loanId, 'Not Selected');
    refreshDataWithPublic();
    store.oldSelectedProperties = [];
  }

  const handleUnverify = async () => {
    await store.unverifyTrackRecord(verifiedTrackRecordsId, store.loanId);
    refreshDataNoPublic();
    handleMenuClose();
  }

  const handleDelete = async () => {
    await store.deleteTrackRecord(verifiedTrackRecordsId);
    refreshDataNoPublic();
    handleMenuClose();
  }

  const handleSave = async () => {
    try {
      let request = { comment: store.baseballCardDetails.experienceComment };
      await store.saveExperienceComment(store.currentBorrower, request);
      store.showSuccessNotification('Experience saved successfully');
    } catch (error) {
      store.showErrorNotification('Error while saving experience');
    }
  }

  const handleRequestVerification = async () => {

    let resolutions = [];
    for (let property of store.oldSelectedProperties) {
      property.purchaseDate = formatDate(property.purchaseDate);
      property.saleDate = formatDate(property.saleDate);
      property.isVerificationRequested = true;
      if (property.verifiedTrackRecordsId == null) {
        const response = await store.savePropertyInformation(property) as any;
        property.verifiedTrackRecordsId = response.data.verifiedTrackRecordsId;
      }
    }

    let trackRecords = [];
    for (let trackRecord of store.combinedUnverifiedTrackRecord) {
      if (trackRecord.isVerificationRequested == true) {
        trackRecords.push(trackRecord);
        resolutions.push(getResolutionItem(trackRecord));
      }
    }
    let request = {
      borrowerId: store.currentBorrower,
      letterOfExplanation: resolutions,
      trackRecords: trackRecords,
    };
    await store.requestTrackRecordVerification(request, store.currentBorrower, store.loanId, TRACK_RECORD_REVIEW_TYPE);
    handleSavedSuccess();
    refreshDataWithPublic();
    handleMenuClose();
  }

  const getResolutionItem = (property) => {
    const fullAddress = (property.purchaseAddress ? property.purchaseAddress + ", " : "") +
      (property.purchaseCity ? property.purchaseCity + ", " : "") + (property.purchaseState ? property.purchaseState + " " : "") +
      (property.purchaseZipcode ? property.purchaseZipcode : "");

    const itemDescription = `Address: ${fullAddress}\n` + (property.entityName ? "Entity name: " + property.entityName : "") +
      (property.buyerName ? " Buyer name: " + property.buyerName : "");

    return {
      itemSection: TRACK_RECORD_REVIEW_TYPE + " " + property.verifiedTrackRecordsId,
      itemName: fullAddress.trim(),
      itemDescription: itemDescription,
    }
  }

  const refreshDataNoPublic = async () => {
    await store.fetchVerifiedTrackRecord(parseInt(store.currentBorrower), store.loanId);
    await store.fetchUnverifiedTrackRecord(store.currentBorrower);
  }

  const refreshDataWithPublic = async () => {
    await store.fetchVerifiedTrackRecord(store.currentBorrower, store.loanId);
    await store.fetchUnverifiedTrackRecord(store.currentBorrower);
    await store.fetchPublicTrackRecord(store.currentBorrower);
  }

  const refreshEntities = async () => {
    store.baseballCardDetails.borrowingEntities = [];
    await store.fetchBaseballCardData(store.currentBorrower, store.loanId ? parseInt(store.loanId) : null);
    if (store.selectedTab === 1) refreshDataWithPublic();
  }

  const handleAddProperty = async () => {
    setPropertyDialogTitle(ADD_PROPERTY);
    store.openPropertyInformationDialog(DialogState.ADD)
    await handleMenuClose();
  }

  const handleEditProperty = async () => {
    setPropertyDialogTitle(EDIT_PROPERTY);
    store.currentRecordSourceType = recordSourceType;
    store.openPropertyInformationDialog(DialogState.EDIT, verifiedTrackRecordsId, isVerified, false);
    await handleMenuClose();
  }

  const handleLookupProperty = async () => {
    setPropertyDialogTitle(ADD_PROPERTY);
    store.openPropertyInformationDialog(DialogState.ADD, undefined, undefined, true);
    await handleMenuClose();
  }

  const handleCloseButton = () => {
    window.parent.postMessage('close-modal', '*');
  }

  const handleSavedSuccess = () => {
    window.parent.postMessage('saved-success', '*');
  }

  const openLinkEntityModal = () => {
    setSelectEntityOpen(true);
    entityStore.setSelectedEntity(null);
  }

  const openAddEntityModal = () => {
    setAddEntityOpen(true);
    entityStore.setSelectedEntity(null);
    entityStore.reset();
  }

  const handleAddEntity = () => {
    entityStore.setSelectedEntity(entityStore.getEntity());
    entityStore.handleLinkEntity(store.currentBorrower, () => {
      setAddEntityOpen(false);
      store.baseballCardDetails.borrowingEntities = [];
      store.fetchBaseballCardData(store.currentBorrower, store.loanId ? parseInt(store.loanId) : null);
      if (store.selectedTab === 0) refreshDataNoPublic();
      else if (store.selectedTab === 1) refreshDataWithPublic();
    });
  }

  const handleUpdateVerifyEntity = async () => {
    if (currentEntity) {
      await store.updateVerifyEntity(currentEntity.borrowerEntityBorrowerId, currentEntity.entityVerified === 'Yes', '', store.loanId);
      refreshEntities();
      handleEntityMenuClose();
    }
    handleEntityMenuClose();
  }

  return (
    <Grid container>
      <PropertyInformationDialog
        store={store}
        recordSourceType={store.currentRecordSourceType === '' ? TRACK_RECORD_USER_INPUTTED : store.currentRecordSourceType}
      />
      <ConfirmDialog
        open={confirmCompleteReviewModalOpen}
        confirmButtonText={'Yes'}
        handleClose={handleCloseConfirmCompleteReviewModal}
        handleCancel={handleCloseConfirmCompleteReviewModal}
        handleConfirm={handleConfirmationCompleteReviewAction}
        title={'Complete Review'}
        body={`Are you sure you want to mark this review as completed?`}
      />
      <LinkEntityDialog
        borrowerId={store.currentBorrower}
        store={entityStore}
        open={selectEntityOpen}
        setModalOpen={setSelectEntityOpen}
        reloadData={refreshEntities}
      />
      <EntityModal
        modalState={BorrowerEntityModalState.ADD}
        open={addEntityOpen}
        handleClose={() => { setAddEntityOpen(false) }}
        store={store}
        handleAdd={handleAddEntity}
        handleEdit={() => { }}
      />
      <Grid item xs={12}>
        <br />
        <Grid item xs={12} style={{ marginLeft: '8px', marginBottom: '20px' }}>
          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '8px' }}>
            <Typography variant="h6">Borrowing Entities</Typography>
            <div>
              <Button
                onClick={openAddEntityModal}
                style={{ marginRight: '8px' }}
                variant="outlined" color="primary">
                ADD ENTITY
              </Button>
              <Button
                onClick={openLinkEntityModal}
                variant="outlined" color="primary">
                LINK ENTITY
              </Button>
            </div>
          </Grid>
          <DataGrid
            frameworkComponents={frameworkComponents}
            columns={entitiesCols}
            rows={baseballCardDetails ? baseballCardDetails?.borrowingEntities : []}
            domLayout="autoHeight"
          />
          <Menu
            id="menu-appbar"
            getContentAnchorEl={null}
            anchorEl={entityMenuAnchorEl}
            keepMounted
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left', }}
            transformOrigin={{ vertical: 'top', horizontal: 'left', }}
            open={entityMenuOpen}
            onClose={handleEntityMenuClose}
          >
            <ConfirmationMenuItem
              testId={`removeEntity`}
              onClick={handleRemoveEntity} confirmDialogProps={{
                body: 'Are you sure you want to remove this entity?',
              }}
              disabled={currentEntity?.isEntityPresentInLoan === 'true'}>
              <ListItemIcon>
                <Delete fontSize="small" />
              </ListItemIcon>
              <ListItemText>Remove Entity</ListItemText>
            </ConfirmationMenuItem>
            {currentEntity?.entityVerified === 'Yes' ? (
              <ConfirmationMenuItem
                testId={`unverifyEntity`}
                onClick={handleUpdateVerifyEntity} confirmDialogProps={{
                  body: 'Are you sure you want to mark this entity as unverified?',
                }}>
                <ListItemIcon>
                  <CancelOutlined fontSize="small" />
                </ListItemIcon>
                <ListItemText>Unverify Entity</ListItemText>
              </ConfirmationMenuItem>
            ) : (
              <ConfirmationMenuItem
                testId={`verifyEntity`}
                onClick={handleUpdateVerifyEntity}
                confirmDialogProps={{
                  body: 'Are you sure you want to mark this entity as verified?',
                }} >
                <ListItemIcon>
                  <CheckCircleOutline fontSize="small" />
                </ListItemIcon>
                <ListItemText>Verify Entity</ListItemText>
              </ConfirmationMenuItem>
            )}
          </Menu>
        </Grid>
        {isInternal &&
          <Grid item xs={12} style={{ marginLeft: '8px', marginBottom: '20px' }}>
            <Grid>
              <TextField
                variant="outlined"
                label="Experience Comments"
                value={baseballCardDetails ? baseballCardDetails.experienceComment : ""}
                onChange={(e) => store.setExperienceComment(e.target.value)}
                multiline
                rows={3}
                margin="normal"
                fullWidth
                style={{ background: 'white' }}
                disabled={baseballCardDetails?.lockedData}
              />
            </Grid>
            {
              !baseballCardDetails?.lockedData && (
                <Grid item xs={12} sm={2}>
                  <Button
                    onClick={handleSave}
                    variant="outlined" color="primary">
                    Save Comment
                  </Button>
                </Grid>
              )
            }
          </Grid>
        }
        <Grid item xs={12} style={{ marginLeft: '8px' }}>
          <Tabs
            className={tab}
            tabs={baseballCardDetails?.lockedData ? lockedDataTabs : tabs}
            selectedTab={store.selectedTab}
            onTabChange={onTabSelect}
          />
        </Grid>
        {store.selectedTab == 0 && (
          <Grid item xs={12} style={{ marginLeft: '8px', marginBottom: '20px' }} >
            <Typography variant="h6">Verified Experience</Typography>
            <VerifiedTrackRecordGrid store={store} frameworkComponents={frameworkComponents} internal={!!isInternal} />
            {isInternal &&
              <Menu
                id="menu-appbar"
                getContentAnchorEl={null}
                anchorEl={anchorEl}
                keepMounted
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left', }}
                transformOrigin={{ vertical: 'top', horizontal: 'left', }}
                open={menuOpen}
                onClose={handleMenuClose}
              >
                {recordSourceType && recordSourceType != TRACK_RECORD_CAPITAL_PROVIDER && (
                  <MenuItem
                    testId={`editProperty`}
                    onClick={handleEditProperty}
                  >
                    <ListItemIcon>
                      <Edit fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Edit Property</ListItemText>
                  </MenuItem>
                )}
                {recordSourceType && recordSourceType != TRACK_RECORD_CAPITAL_PROVIDER && !baseballCardDetails?.lockedData && (
                  <ConfirmationMenuItem
                    testId={`unverifyProperty`}
                    onClick={handleUnverify} confirmDialogProps={{
                      body: 'Are you sure you want to mark this property as unverified?',
                    }}>
                    <ListItemIcon>
                      <CancelOutlined fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Unverify Property</ListItemText>
                  </ConfirmationMenuItem>
                )}
              </Menu>
            }
          </Grid>
        )}
        {store.selectedTab == 1 && (
          <Grid item xs={12} style={{ marginLeft: '8px', marginBottom: '20px' }}>
            <Grid item xs={12} style={{ display: 'flex' }}>
              <Grid item xs={9}>
                <Typography variant="h6">Properties Purchased by Borrower and their Entities</Typography>
              </Grid>
              {isInternal &&
                <Grid item xs={3} style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '8px' }}>
                  <Button
                    variant='outlined'
                    color='primary'
                    startIcon={<Search />}
                    onClick={handleLookupProperty}
                    style={{ color: '#2199E0', borderColor: '#2199E0', backgroundColor: '#E2F6FF', marginRight: '10px' }}
                  >
                    Look Up Property
                  </Button>
                  <Button
                    variant='outlined'
                    color='primary'
                    startIcon={<Add />}
                    onClick={handleAddProperty}
                    style={{ color: '#2199E0', borderColor: '#2199E0', backgroundColor: '#E2F6FF' }}
                  >
                    Add New Property
                  </Button>
                </Grid>
              }
            </Grid>
            <Grid item xs={12}>
              <br />
              <UnverifiedTrackRecordGrid store={store} frameworkComponents={frameworkComponents} internal={!!isInternal} />
              {isInternal &&
                <Menu
                  id="menu-appbar"
                  getContentAnchorEl={null}
                  anchorEl={anchorEl}
                  keepMounted
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'left', }}
                  transformOrigin={{ vertical: 'top', horizontal: 'left', }}
                  open={menuOpen}
                  onClose={handleMenuClose}
                >
                  <ConfirmationMenuItem
                    testId={`verifyProperty`}
                    onClick={handleVerify}
                    confirmDialogProps={{
                      body: 'Are you sure you want to mark this property as verified?',
                    }} >
                    <ListItemIcon>
                      <CheckCircleOutline fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Verify Property</ListItemText>
                  </ConfirmationMenuItem>
                  {recordSourceType && (recordSourceType == TRACK_RECORD_USER_INPUTTED || recordSourceType == TRACK_RECORD_TEMPLATE) && (
                    <MenuItem
                      testId={`editProperty`}
                      onClick={handleEditProperty}
                    >
                      <ListItemIcon>
                        <Edit fontSize="small" />
                      </ListItemIcon>
                      <ListItemText>Edit Property</ListItemText>
                    </MenuItem>
                  )}
                  {recordSourceType && (recordSourceType == TRACK_RECORD_USER_INPUTTED || recordSourceType == TRACK_RECORD_TEMPLATE) && (
                    <MenuItem
                      testId={`deleteProperty`}
                      onClick={handleDelete}
                    >
                      <ListItemIcon>
                        <Delete fontSize="small" />
                      </ListItemIcon>
                      <ListItemText>Delete Property</ListItemText>
                    </MenuItem>
                  )}
                </Menu>
              }
            </Grid>

            {(store.selectedTab == 1 && isInternal) &&
              <Grid className={classes.addressWrapper}>
                <TrackRecordAuditLogs store={store} />
              </Grid>
            }

            <Grid container spacing={2} className={classes.buttonsGrid} justifyContent='center'>
              {isInternal &&
                <>
                  <Grid item xs={12} sm={2}>
                    <Button
                      onClick={handleCloseButton}
                      variant="outlined" color="primary" fullWidth>
                      CLOSE
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <ConfirmationButton
                      variant='outlined'
                      color='primary'
                      startIcon={<AssignmentTurnedInOutlined />}
                      onClick={handleRequestVerification}
                      testId="request-verification"
                      confirmDialogProps={{
                        title: 'Request Verification',
                        body: 'Are you sure you want to request verification for the selected properties?',
                      }}
                    >
                      Request Verification
                    </ConfirmationButton>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <ConfirmationButton
                      variant="outlined"
                      color="primary"
                      testId="mark-as-verified"
                      confirmDialogProps={{
                        title: 'Mark as Verified',
                        body: 'Are you sure you want to verify all of the selected properties?',
                      }}
                      onClick={() => handleMultipleVerify()}
                      startIcon={<PlaylistAddCheck />}
                    >
                      Mark as Verified
                    </ConfirmationButton>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <ConfirmationButton
                      variant="contained"
                      onClick={handleConfirmationCompleteReviewAction}
                      color="primary"
                      testId="confirmReview"
                      confirmDialogProps={{
                        title: 'Complete Review',
                        body: 'Are you sure you want to mark this review as completed?',
                      }}
                    >
                      REVIEW COMPLETE
                    </ConfirmationButton>
                  </Grid>
                </>
              }
            </Grid>
            <Grid xs={12} style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '32px' }}>
            </Grid>
          </Grid>
        )}
      </Grid>

      {(store.selectedTab == 0 && isInternal) &&
        <Grid item xs={12}>
          <Grid className={classes.addressWrapperTab}>
            <TrackRecordAuditLogs store={store} />
          </Grid>
        </Grid>
      }

      {store.selectedTab == 0 && isInternal &&
        <Grid container spacing={2} className={classes.closeButton} justifyContent='center'>
          <Grid item xs={12} sm={2}>
            <Button
              onClick={handleCloseButton}
              style={{ color: '#2199E0', borderColor: '#2199E0', backgroundColor: '#E2F6FF' }}
              variant="outlined" color="primary" fullWidth>
              CLOSE
            </Button>
          </Grid>
          <Grid item xs={12} sm={2}>
            <ConfirmationButton
              variant="contained"
              onClick={handleConfirmationCompleteReviewAction}
              color="primary"
              testId="confirmReview"
              confirmDialogProps={{
                title: 'Complete Review',
                body: 'Are you sure you want to mark this review as completed?',
              }}
            >
              REVIEW COMPLETE
            </ConfirmationButton>
          </Grid>
        </Grid>}
    </Grid >
  );
});

export default BorrowerTrackRecord;
