import { Service } from "@roc/feature-app-core";

const url = {
  GET_ALL_LENDER_NAMES: '/api/v1/loan/lenderUsers/getAllLenderNames',
  GET_RELATIONSHIP_MANAGER_AND_LENDER_MAP: '/api/v1/loan/lenderUsers/getRelationshipManagerAndLenderMap',
  GET_ALL_LEAD_SOURCES: '/api/v1/loan/borrower/getAllLeadSources',
  LOAN_VOLUME_DATA: '/api/v1/loan/loanVolumeData'
};

export class LoanVolumeReportService extends Service {
  getAllLenderNames() {
    return this.get(`${url.GET_ALL_LENDER_NAMES}`);
  }

  getRelationshipManagerAndLenderMap() {
    return this.get(`${url.GET_RELATIONSHIP_MANAGER_AND_LENDER_MAP}`);
  }

  getAllLeadSources() {
    return this.get(`${url.GET_ALL_LEAD_SOURCES}`);
  }

  loanVolumeData(startDate: any, endDate: any, lender: any, relationshipManager: any, quarterly: any, annually: any, filters: any) {
    return this.get(`${url.LOAN_VOLUME_DATA}?startDate=${startDate}&&endDate=${endDate}&&lender=${lender}&&quarterly=${quarterly}&&relationshipManager=${relationshipManager}&&
    annually=${annually}&&filters=${filters}`);
  }
}