import { Grid, Typography } from '@material-ui/core';
import { isNil } from '@roc/feature-utils';
import { ReactComponent as GreenArrow } from '../images/greenArrow.svg'
import { ReactComponent as RedArrow } from '../images/redArrow.svg'
import { useStore } from '@roc/client-portal-shared/hooks';

export type PropertyDetailProps = {
  label: string;
  value: any;
  lg: any;
  xs: any;
  variant: any;
  md: any;
  addArrow?: boolean;
  field?: string
};

export const PropertyDetail = (props: PropertyDetailProps) => {
  const { compToolStore } = useStore();
  const { label, value, lg, xs, variant, md, addArrow = false, field = '' } = props;

  const getArrowSymbol = () => {
    if (addArrow && compToolStore.subjectProperty.data[field] && value) {
      if (compToolStore.subjectProperty.data[field] > value) {
        return <RedArrow style={{ height: '38px', width: '20px' }} />;
      } else if (compToolStore?.subjectProperty.data[field] < value) {
        return <GreenArrow style={{ height: '38px', width: '20px' }} />;
      }
    }
    return null;
  }


  return (
    <>
      <Grid item lg={lg} xs={xs} md={md} style={{ alignContent: 'center' }}>
        <Typography align="center" variant={variant} gutterBottom>
          {isNil(value) || value == '' ? '-' : value}{addArrow && getArrowSymbol()}
        </Typography>
        <Typography align="center" variant="body2" color="textSecondary">
          {label}
        </Typography>
      </Grid>
    </>
  );
};