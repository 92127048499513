import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';
import { Theme, alpha, makeStyles, useTheme } from '@material-ui/core/styles';
import { Layout } from '@roc/feature-app-core';
import { Card, CurrencyFormatter, Tabs } from '@roc/ui';
import { formatCurrency } from '@roc/ui/utils';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { TodaysClosingAccordion } from './todaysClosingAccordion';
import { ClosingDate } from './closingDate';
import { observer } from 'mobx-react';
import { useExpectedClosingsStore } from '../hooks/useExpectedClosingsStore';
import { ExpectedClosingData } from '../utils/expectedClosingTypes';
import { ExpectedClosingsValueCell } from './expectedClosingsCell';
import { ExpectedClosingsCardTitle } from './expectedClosingsCardTitle';

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    height: '100%',
  },
  tabContainer: {
    '& .MuiTabs-indicator': {
      display: 'none',
    },
  },
  tab: {
    maxWidth: 'unset',
  },
  tabSelected: {
    background: alpha(theme.palette.primary.main, 0.1),
    border: `2px solid ${theme.palette.primary.main}`,
  },
  label: {
    fontSize: 16,
    color: '#777',
  },
  amount: {
    fontSize: 24,
  },
  green: {
    color: green[500],
  },
  red: {
    color: red[500],
  },
}));

interface Props {
  data: ExpectedClosingData;
  showInRed?: string[];
  showInGreen?: string[];
}

export const ExpectedClosingsCard = observer(
  ({ data, showInRed = [], showInGreen = [] }: Props) => {
    const { title, rows, columns, primaryKey } = data;
    const classes = useStyles();

    return (
      <Card
        title={<ExpectedClosingsCardTitle title={title} />}
        className={classes.card}
        cardHeaderProps={{
          style: {
            borderBottom: '1px solid #e0e0e0',
          },
        }}
        cardContentProps={{
          style: {
            padding: 0,
          },
        }}
      >
        <Table>
          <TableBody>
            {columns.map(column => (
              <TableRow hover>
                <TableCell component="th" scope="row" className={classes.label}>
                  {column.name}
                </TableCell>
                <TableCell
                  align="right"
                  className={clsx(
                    classes.amount,
                    showInRed.includes(column.mapping) && classes.red,
                    showInGreen.includes(column.mapping) && classes.green
                  )}
                >
                  <ExpectedClosingsValueCell
                    type={column.type}
                    value={rows?.[0]?.[column.mapping]}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Card>
    );
  }
);
