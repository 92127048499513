import { ApiResponse, Service } from "@roc/feature-app-core";

const url = {
  GET_REPORT: '/api/v1/assetm/reports/getConcentrationLimitReport',
};

export class ConcentrationReportsService extends Service {

  getReport(investorId: string){
    return this.get(url.GET_REPORT, {investorId},{disableGlobalLoading: false});
  }
}