import { Service } from "@roc/feature-app-core";

const url = {
  SAVE_LOAN_DATA: '/api/v1/loan/saveLoanData',
};

export class LoansDataFormService extends Service {

  saveLoanData(loanData) {
    return this.post(url.SAVE_LOAN_DATA, loanData);
  }
}
