import { Box, Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import { Layout, useUserStore } from '@roc/feature-app-core';
import { observer } from 'mobx-react';
import { OneToolQuoteStepsContainer } from '../../components/oneToolQuoteStepsContainer';
import { QuoteTypeStore } from '../../stores/quoteTypeStore';
import { Button, StepObj } from '@roc/ui';
import {
  preflightSteps,
  QuoteStep,
  PreflightStep as PrefligthStepEnum,
} from '../../utils/constants';
import { OneToolStepperComponent } from '../../components/oneToolStepperComponent';
import { LoanDetailsCardProps } from '../summary/summaryStep';
import { useEffect } from 'react';
import { EconomicsSummaryCard } from '../summary/components/economicsSummaryCard';
import { PreflightEarningsCard } from './components/preflightEarningsCard';
import {
  PropertyInformationCard,
  PropertyInformationFormProps,
} from '../propertyInformation/components/propertyInformationCard';
import { PrefligthPropertyCard } from './components/preflightPropertyCard';
import { BrokerInformationCard } from '../summary/components/brokerInformation/brokerInformationCard';
import { EntityInformationCard } from '../summary/components/entityInformation/entityInformationCard';
import { PrefligthBorrowerCard } from './components/preflightBorrowerCard';
import { PrefligthClosingCard } from './components/preflightClosingCard';
import { PrefligthCard } from './components/preflightCard';
import { BrokerInformationAccordion } from '../summary/components/brokerInformation/brokerInformationAccordion';
import { EntityInformationForm } from '../summary/components/entityInformation/entityInformationForm';
import { EntityInformationAccordion } from '../summary/components/entityInformation/entityInformationAccordion';
import { EntityInformationEmptyCard } from '../summary/components/entityInformation/entityInformationEmptyCard';
import { BrokerInformationEmptyCard } from '../summary/components/brokerInformation/brokerInformationEmptyCard';
import { ClosingForm } from '../summary/components/closing/closingForm';
import { PrefligthBrokerCard } from './components/preflightBrokerCard';
import { PrefligthEntityCard } from './components/preflightEntityCard';
import { SummaryActions } from '../summary/components/summaryActions';
import { EarningsCards } from '../../components/earningsCards';
import { PersonAdd } from '@material-ui/icons';
import { Alert, AlertTitle } from '@material-ui/lab';
import { useEarningsCardSticky } from '../../hooks/useEarningsCardSticky';
import { useStore } from '@roc/client-portal-shared/hooks';

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: '34px',
  },
  paper: {},

  stepperWrapper: {
    flex: 1,
    padding: theme.spacing(3.5, 3.5),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1, 1),
    },
  },
}));

export interface PreflightStepperProps {
  store: QuoteTypeStore;
  LoanDetailsCard: React.FC<LoanDetailsCardProps>;
  PropertyInformationForm: React.FC<PropertyInformationFormProps>;
}

export const PreflightStep = observer(
  ({
    store,
    LoanDetailsCard,
    PropertyInformationForm,
  }: PreflightStepperProps) => {
    const classes = useStyles();
    const { loanEconomicsStore } = store;

    return (
      <Layout maxWidth={'lg'}>
      
        <Paper className={classes.paper} elevation={0}>
          <Box display="flex">
            <Box className={classes.stepperWrapper}>
              <Preflight
                store={store}
                LoanDetailsCard={LoanDetailsCard}
                PropertyInformationForm={PropertyInformationForm}
              />
            </Box>
            {/* <PreflightEarningsCard store={store} /> */}
          </Box>
        </Paper>
      </Layout>
    );
  }
);

export const ErrorsAlert = observer(({ errors }) => {
  return (
    <>
      {
        errors.length > 0 && (
          <Grid item xs={12}>
            <Alert severity="error">
              <AlertTitle>ERRORS</AlertTitle>
              {errors.map(error => (
                <Grid>{error}</Grid>
              ))}
            </Alert>
          </Grid>
        )
      }
    </>
  );
});

const WarningsAlert = observer(({ warnings }) => {
  return (
    <>
      {
        warnings.length > 0 && (
          <Grid item xs={12}>
            <Alert severity="warning">
              <AlertTitle>WARNINGS</AlertTitle>
              {warnings.map(warning => (
                <Grid>{warning}</Grid>
              ))}
            </Alert>
          </Grid>
        )
      }
    </>
  );
});

const Preflight = observer(
  ({
    store,
    LoanDetailsCard,
    PropertyInformationForm,
  }: PreflightStepperProps) => {
    const { earningsCardsStickyStyle } = useEarningsCardSticky();
    const { preflightStore, allErrors, allWarnings } = store;
    const { globalStore } = useStore();
    const { userStore } = useUserStore();

    const enableSubmitLoan =
      preflightStore.nextStep === PrefligthStepEnum.SUBMISSION && !store.disableSubmitLoanButton;

    const hasBroker =
      store.brokerStore.brokerFormStore.form.fields.hasBroker.value;

    useEffect(() => {
      if (preflightStore.nextStep === PrefligthStepEnum.SUBMISSION) {
        store.doSubmitLoanValidation();
      }
    }, [preflightStore.nextStep]);

    const { propertyFormStores } = store.propertiesStore;
    const { borrowerFormStores } = store.borrowersStore;
    return (
      <>
        <Grid container spacing={3}>
          {userStore.canSeeEarnings && <Grid item xs={12} style={earningsCardsStickyStyle}>
            <EarningsCards store={store} />
          </Grid>}
          <Grid item xs={12}>
            <LoanDetailsCard store={store} />
          </Grid>
          <Grid item xs={12}>
            <EconomicsSummaryCard store={store} />
          </Grid>
          {hasBroker && (
            <Grid item xs={12}>
              <PrefligthBrokerCard store={store} />
            </Grid>
          )}
          <Grid item xs={12}>
            <PrefligthEntityCard store={store} />
          </Grid>
          {borrowerFormStores.map((borrowerFormStore, index) => (
            <Grid item xs={12} key={borrowerFormStore.key}>
              <PrefligthBorrowerCard
                store={store}
                borrowerFormStore={borrowerFormStore}
                borrowerIndex={index}
              />
            </Grid>
          ))}
          {propertyFormStores.map((propertyFormStore, index) => (
            <Grid item xs={12} key={propertyFormStore.form.meta.key}>
              <PrefligthPropertyCard
                store={store}
                propertyFormStore={propertyFormStore}
                propertyIndex={index}
              />
            </Grid>
          ))}
          <Grid item xs={12}>
            <PrefligthClosingCard store={store} />
          </Grid>
          <Grid item xs={12}>
            <ErrorsAlert errors={[...allErrors]} />
            <br />
            <WarningsAlert warnings={[...allWarnings]} />
          </Grid>
        </Grid>
        <Box pt={3}>
          <Grid container direction="row" justifyContent="center" spacing={2}>
            <Grid item xs={6} md={3}>
              <Button
                variant="outlined"
                color="primary"
                fullWidth
                onClick={() => store.goToStep(QuoteStep.SUMMARY)}
                testId="previous"
              >
                RETURN TO QUOTE
              </Button>
            </Grid>
            <Grid item xs={6} md={3}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => store.submitLoan()}
                testId={'submit'}
                disabled={!enableSubmitLoan}
              >
                SAVE & SUBMIT AS LOAN
              </Button>
            </Grid>
          </Grid>
        </Box>
      </>
    );
  }
);
