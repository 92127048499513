import {
  borrowerExpMapping,
  LoanSubType,
  MIXED_USE,
  SINGLE_FAMILY,
} from '@roc/feature-utils';
import PricerStore from './pricerStore';
import { action, flow, makeObservable, observable } from 'mobx';
import { SelectBorrowersStore } from '@roc/feature-borrowers';
import BorrowerFormStore from './common/borrowerFormStore';
import { Borrower } from '@roc/feature-types';
import { isBorrowerPersonGuarantor } from '@roc/feature-utils';
import { differenceInYears, startOfToday } from 'date-fns';
import { ApiResponse, GlobalStore } from '@roc/feature-app-core';
import { LoanService } from '@roc/feature-loans';
import { BasePropertiesStore } from '../basePropertiesStore';
import { PropertyInformationSteps } from '../../utils/constants';
import { BaseBorrowerInformationStore } from '../baseBorrowerInformationStore';

export class SinglePropertyPropertiesStore extends BasePropertiesStore {
  globalStore: GlobalStore;
  public loanSubtype: LoanSubType;
  pricerStore: PricerStore;
  public stepError: string;
  selectBorrowersStore: SelectBorrowersStore;
  borrowerFormStore: BorrowerFormStore;
  public allCounties;
  borrowerInformationStore: BaseBorrowerInformationStore;
  loanService: LoanService;

  constructor(
    globalStore,
    borrowerInformationStore: BaseBorrowerInformationStore
  ) {
    super();
    this.globalStore = globalStore;
    this.loanService = new LoanService();
    this.pricerStore = new PricerStore(globalStore, this);
    this.loanSubtype = LoanSubType.SINGLE_PROPERTY;
    this.borrowerInformationStore = borrowerInformationStore;
    this.selectBorrowersStore = new SelectBorrowersStore(
      globalStore,
      this.borrowerFormStore,
      undefined,
      undefined,
      () => this.handleBorrowersChange(),
      borrower => this.setNewSelectedBorrowerDefaults(borrower),
      () => new BorrowerFormStore(globalStore)
    );

    this.setDefaults();

    makeObservable(this, {
      stepError: observable,
      allCounties: observable,
      isValidBankruptcyDate: action,
      isValidForeclosureDate: action,
      handleBorrowerInformationChange: action,
      getNumberOfUnits: action,
      fetchStateCounties: flow,
    });
  }

  private setDefaults() {
    this.stepError = null;
    this.allCounties = {};
  }

  reset() {
    this.setDefaults();
    //this.borrowerFormStore.reset();
    this.pricerStore.reset();
    this.selectBorrowersStore.reset();
  }

  *fetchStateCounties() {
    try {
      const response: ApiResponse = yield this.loanService.getStateCountiesPublic();
      this.allCounties = response.data;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while getting state counties.',
      });
    }
  }

  isValidBankruptcyDate(bankruptcyDate) {
    return (
      bankruptcyDate &&
      differenceInYears(startOfToday(), new Date(bankruptcyDate)) >= 3
    );
  }

  isValidForeclosureDate(foreclosureDate) {
    return (
      foreclosureDate &&
      differenceInYears(startOfToday(), new Date(foreclosureDate)) >= 3
    );
  }

  private handleBorrowersChange() {
    this.prepareBorrowers();
  }

  handleBorrowerInformationChange() {
    this.prepareBorrowers();
  }

  private prepareBorrowers() {
    const existingBorrowers = this.selectBorrowersStore.existingBorrowers.map(
      borrower => this.setBorrowerDefaults(borrower)
    );
    this.selectBorrowersStore.setExistingBorrowersFromExternal(
      existingBorrowers
    );

    const newBorrowers = this.selectBorrowersStore.newBorrowers.map(borrower =>
      this.setBorrowerDefaults(borrower)
    );
    this.selectBorrowersStore.newBorrowers = newBorrowers;
  }

  private setBorrowerDefaults(borrower: Borrower) {
    const pricerFields = this.pricerStore.form.fields;
    return {
      ...borrower,
      borrowerExp: pricerFields.borrowerExp.value,
      ficoProvidedAtOrigination: pricerFields.ficoProvidedAtOrigination.value,
      personalGuarantor: isBorrowerPersonGuarantor(borrower.pctOwnership),
    };
  }

  private setNewSelectedBorrowerDefaults(borrower: Borrower) {
    const pricerFields = this.pricerStore.form.fields;
    return {
      ...borrower,
      citizenshipStatus: pricerFields.citizenshipStatus.value,
    };
  }

  getNumberOfUnits = () => {
    const { isSingleProperty } = this.pricerStore;
    const formValues = this.pricerStore.getFormValues();

    if (isSingleProperty) {
      return formValues['propertyType'] == MIXED_USE
        ? Number(formValues['residentialUnits']) +
            Number(formValues['commercialUnits'])
        : formValues['units'];
    }

    return '';
  };

  runStepValidations() {
    const fields = this.borrowerInformationStore.mainBorrowerFormStore.form
      .fields;
    this.pricerStore.onFieldChange(
      'borrowerExp',
      borrowerExpMapping[fields.rentalExperience.value] ?? '0'
    );
    this.pricerStore.onFieldChange(
      'citizenshipStatus',
      fields.citizenshipStatus.value
    );
    this.pricerStore.onFieldChange(
      'ficoProvidedAtOrigination',
      fields.ficoProvidedAtOrigination.value
    );
    this.pricerStore.onFieldChange('bankruptcy', fields.bankruptcy.value);
    this.pricerStore.onFieldChange(
      'bankruptcyDate',
      fields.bankruptcyDate.value
    );
    this.pricerStore.onFieldChange('foreclosure', fields.foreclosure.value);
    this.pricerStore.onFieldChange(
      'foreclosureDate',
      fields.foreclosureDate.value
    );

    this.stepError = this.pricerStore.checkIfRequiredFieldsEmpty()
      ? 'Please fill all the required fields'
      : null;
    return this.pricerStore.checkForPricerStepErrors();
  }

  getPropertyInformationActiveStep(): PropertyInformationSteps {
    this.runStepValidations();
    return !this.stepError
      ? PropertyInformationSteps.PROPERTY_INFORMATION_COMPLETED
      : PropertyInformationSteps.PROPERTY_INFORMATION;
  }
  setPropertyInformationActiveStep(value: PropertyInformationSteps) {
    return null;
  }
  getProperties(): any[] {
    return [this.pricerStore.getProperty()];
  }
  handlePropertyInformationBack() {
    return null;
  }
  handlePropertyInformationNextStep() {
    this.runStepValidations();
    if (this.stepError) {
      this.globalStore.notificationStore.showWarningNotification({
        message: this.stepError,
      });
    }
  }
}
