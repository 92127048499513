import { observer } from 'mobx-react';
import { ReactNode, useEffect, useState } from 'react';
import { AgGridColumnProps, DataGrid, LinkColumn, Paper, SubmitMenuItem, ConfirmationMenuItem } from '@roc/ui';
import { useStore } from '@roc/client-portal-shared/hooks';
import { IconButton, ListItemIcon, ListItemText, Menu } from '@material-ui/core';
import { Layout } from '@roc/feature-app-core';
import { MoreVert, RemoveFromQueue, Check, ImportExport } from '@material-ui/icons';
import { ConvertLeadToLenderModal } from '../../leadsDashboard/convertLeadToLenderModal';
import { useHistory } from 'react-router';
import { useLeadDetailsRoutes } from '../../../routes/loanRoutes';
import { SelectMode } from 'libs/client-portal-shared/src/app/components/grid';
import { useCRMPortalStore } from 'apps/crm-portal/src/app/hooks/useCRMPortalStore';

import { formatDate } from '@roc/feature-utils';
import { RemoveFromQueueModal } from '../../leadsDashboard/removeFromQueueModal';
import { useSendEmailsStore } from '@roc/feature-sendgrid';


const noOpComparator = () => 0;

const columns = (recordType): AgGridColumnProps[] => {
  let cols = [
    {
      field: 'id',
      headerName: 'id',
      minWidth: 180,
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      hide: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    {
      field: 'ownerEmailC',
      headerName: 'Owner Email',
      minWidth: 180,
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      hide: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    {
      field: 'firstName',
      headerName: 'First Name',
      minWidth: 260,
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      cellRenderer: 'firstNameCellRenderer',
    },
    {
      field: 'lastName',
      headerName: 'Last Name',
      minWidth: 260,
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      cellRenderer: 'lastNameCellRenderer',
    },
    {
      field: 'company',
      headerName: 'Company',
      minWidth: 260,
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      cellRenderer: 'companyCellRenderer',
    },
    {
      field: 'leadSource',
      headerName: 'Lead Source',
      minWidth: 260,
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      minWidth: 260,
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    {
      field: 'subStatus',
      headerName: 'Lead Sub-Status',
      minWidth: 260,
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    {
      field: 'experienceScore',
      headerName: 'Experience Score',
      minWidth: 260,
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    {
      field: 'lastUpdatedDate',
      headerName: 'Last Modified Date',
      minWidth: 260,
      cellRenderer: 'dateCellRenderer',
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    {
      headerName: 'Action',
      minWidth: 80,
      cellRenderer: 'actionCellRenderer',
      comparator: noOpComparator,
      width: 80,
      pinned: 'right',
      lockPinned: true
    },
  ];
  if (recordType === 'Borrower') {
    cols = cols.filter(column => column.headerName !== 'Action' && column.headerName !== 'Company');
  }
  return cols;
}


export const LeadDashboardGrid = observer(({ toolbar, recordType }: { toolbar: ReactNode, recordType: String }) => {
  const { salesforceLeadStore, companyProfileStore, companyDropdownStore, ringCentralStore } = useStore();
  const { removeLeadFromQueueStore } = salesforceLeadStore;
  const { push } = useHistory();
  const [anchorEl, setAnchorEl] = useState<HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const [record, setRecord] = useState({});
  const [leadName, setLeadName] = useState({});
  const { leadDetailsRoutesConfig } = useLeadDetailsRoutes();
  const { sendEmailsStore } = useSendEmailsStore();


  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  let gridStore;
  if (recordType === 'TPO') {
    gridStore = salesforceLeadStore.allTPOLeadsGridStore;
  } else {
    gridStore = salesforceLeadStore.allBorrowerLeadsGridStore;

  }

  useEffect(() => {
    gridStore.resetAndFetchGridData();
  }, [salesforceLeadStore]);

  const onSortChanged = params => {
    const sortModel = params.api.getSortModel();
    gridStore.setSortModel(sortModel);
  };

  const onFilterChanged = params => {
    const filterModel = params.api.getFilterModel();
    gridStore.setFilterModel(filterModel);
  };

  const leadNameCellRenderer = (params, propertyName) => {
    const selectedId = gridStore?.gridData?.data?.rows.find(row => row[propertyName] === params.value)?.leadId;
    const url = leadDetailsRoutesConfig.leadDetails(selectedId).url;
    return LinkColumn({ ...params, url });
  };

  const frameworkComponents = {
    dateCellRenderer: ({ value }) => formatDate(value) ?? null,
    firstNameCellRenderer: params => leadNameCellRenderer(params, 'firstName'),
    lastNameCellRenderer: params => leadNameCellRenderer(params, 'lastName'),
    companyCellRenderer: params => leadNameCellRenderer(params, 'company'),
    actionCellRenderer: params => {
      return (
        <IconButton onClick={e => openMenu(e, params.node.data)}>
          <MoreVert color="primary" />
        </IconButton>
      );
    },
  };

  const openMenu = (event: React.MouseEvent<HTMLElement>, record) => {
    setRecord(record)
    setLeadName(record.firstName + ' ' + record.lastName)
    setAnchorEl(event.currentTarget);
  };

  function onRowSelected(value) {
    const { leadsDashboardStore } = salesforceLeadStore;
    const dashboardType = leadsDashboardStore.currentDashboard?.objectType;
    ringCentralStore.setRecord(value?.data.phone, value?.data, value?.node.selected, dashboardType);
    sendEmailsStore.setBulkEmails({ email: value?.data?.email, firstName: value?.data?.firstName, lastName: value?.data?.lastName }, value?.node.selected);
  }

  return (
    <Layout>
      <Paper title={"All  " + recordType + " Leads Dashboard"}>
        <DataGrid
          selectMode={SelectMode.MULTIPLE}
          onRowSelected={e => onRowSelected(e)}
          suppressRowClickSelection={false}
          frameworkComponents={frameworkComponents}
          columns={columns(recordType)}
          toolbar={toolbar}
          rows={gridStore.gridData.data.rows}
          onSortChanged={onSortChanged}
          onFilterChanged={onFilterChanged}
          isLoading={gridStore.isLoading}
          showFilters={gridStore.showFilters}
          sortModel={gridStore.sortModel}
          filterModel={gridStore.filterModel}
          paginationData={gridStore.gridData.meta}
          setPageNumber={gridStore.setPageNumber}
          rowHeight={45}
          domLayout='autoHeight'
        />
      </Paper>
      <Menu
        id="menu-appbar"
        getContentAnchorEl={null}
        anchorEl={anchorEl}
        keepMounted
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={menuOpen}
        onClose={handleMenuClose}
      >
        <SubmitMenuItem
          testId={`change-loan-status-`}
          onClick={() => {
            salesforceLeadStore.convertLeadToLender(record);
            handleMenuClose();
          }}
          submitDialogProps={{
            title: `Convert Lead to Lender`,
            body: <ConvertLeadToLenderModal record={record} />,
            okButtonText: 'Submit',
            submitValidation: () => {
              salesforceLeadStore.convertLeadToLenderStore.runFormValidation();
              return salesforceLeadStore.convertLeadToLenderStore.form.meta.isValid;
            },
          }}
        >
          <ListItemIcon >
            <ImportExport fontSize="small" />
          </ListItemIcon>
          <ListItemText>Convert Lead</ListItemText>
        </SubmitMenuItem>

        {/* <SubmitMenuItem
          testId={`remove-from-queue`}
          onClick={() => {
            removeLeadFromQueueStore.removeLeadFromQueue(record);
            handleMenuClose();
          }}
          submitDialogProps={{
            title: `Remove From Queue`,
            body: <RemoveFromQueueModal record={record} />,
            okButtonText: 'Submit',
            submitValidation: () => {
              salesforceLeadStore.removeLeadFromQueueStore.runFormValidation();
              return salesforceLeadStore.removeLeadFromQueueStore.form.meta.isValid;
            },
          }}
        >
          <ListItemIcon >
            <RemoveFromQueue fontSize="small" />
          </ListItemIcon>
          <ListItemText>Remove From Queue</ListItemText>
        </SubmitMenuItem>

        <ConfirmationMenuItem
          testId={`mark-lead-as-contacted`}
          onClick={() => {
            removeLeadFromQueueStore.markLeadAsContacted(record);
            handleMenuClose();
          }}
          confirmDialogProps={{
            body: `Are you sure want to mark ${leadName} as contacted?`,
          }}
        >
          <ListItemIcon>
            <Check fontSize="small" />
          </ListItemIcon>
          <ListItemText>Mark As Contacted</ListItemText>
        </ConfirmationMenuItem> */}
      </Menu>
    </Layout>
  );
});
