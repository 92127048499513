import { useStore } from '@roc/client-portal-shared/hooks';
import { observer } from 'mobx-react';
import { useEffect } from 'react';

export const LiquidityCalculatorFieldCalculationsListener = observer(props => {
  const liquidityCalculatorStore = props.store;
  const { fields } = liquidityCalculatorStore.form;

  // Calculates Closing Costs value from Loan Amount
  /* useEffect(() => {
    liquidityCalculatorStore.calculateClosingCostValue();
  }, [fields.loanAmount.value, fields.payoff.value, fields.loanPurpose.value]);
 */
  // Calculates down payment
  /*  useEffect(() => {
     liquidityCalculatorStore.calculateDownPaymentValue();
   }, [
     fields.depositPaid.value,
     fields.loanAmount.value,
     fields.purchasePrice.value,
   ]); */

  // Calculates origination amount
  /* useEffect(() => {
    liquidityCalculatorStore.calculateOriginationAmountValue();
  }, [fields.originationFees.value, fields.loanAmount.value]);
 */
  return null;
});
