import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { Layout } from '@roc/client-portal-shared/components';
import { observer } from 'mobx-react';
import { Paper } from '@roc/ui';
import React, { useEffect, useState, useMemo } from 'react';
import {
  PaperList,
  PaperListItem,
  PaperListTitle,
} from './components/liquidityCalculatorComponents';
import {
  AmortizationType,
  AnnualInsurance,
  AnnualTaxes,
  BorrowerType,
  ClosingCosts,
  DepositPaid,
  DownPayment,
  LoanAmount,
  LoanPurpose,
  LoanSubtype,
  MultifamilyMixedUse,
  OriginationAmount,
  OriginationFees,
  Payoff,
  PurchasePrice,
  Rate,
} from './components/liquidityCalculatorFields';
import { LoanPurposeOption } from './utils/liquidityCalculatorConstants';
import { LiquidityCalculatorFieldCalculationsListener } from './components/liquidityCalculatorFieldCalculationsListener';
import { AutocompleteField } from '../dscrCalculator/components/autocompleteField';
import { debounce } from '@roc/client-portal-shared/utils';
import { formatCurrency } from '@roc/ui/utils';
import { LiquidityCalculatorBaseStore } from './stores/liquidityCalculatorBaseStore';
import { CalculateButton } from '../dscrCalculator/components/dscrCalculatorComponents';
import { form } from 'libs/feature-brokers/src/brokers/stores/brokerFormStore';

interface LiquidityCalcSectionProps {
  store: LiquidityCalculatorBaseStore;
}

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  tabs: {},
  calculatedValuesBorder: {
    border: '1px solid #BEBEBE',
    borderRadius: '15px',
    padding: 16,
  },
}));

export const LiquidityCalculator = observer(
  (props: LiquidityCalcSectionProps) => {
    const classes = useStyles();
    const liquidityCalculatorStore = props.store;
    const formStore = liquidityCalculatorStore;

    const calculateLiquidity = () => {
      liquidityCalculatorStore.fetchCalculatedLiquidity();
    };

    const fetchOptions = useMemo(
      () => debounce(searchText => formStore.fetchOptions(searchText), 500),
      [formStore]
    );

    return (
      <Layout title="Borrower Liquidity Calculator for Rental Loans" maxWidth={'xl'}>
        <Paper className={classes.paper}>
          <Grid container spacing={4}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Grid container xs={12} spacing={2}>
                  <Grid item xs={12}>
                    <AutocompleteField
                      value={formStore.loanSearchText}
                      options={formStore.loanSearchOptions}
                      onChange={text => {
                        formStore.loanSearchText = text;
                        fetchOptions(formStore.loanSearchText);
                      }}
                      onSelect={opt => {
                        formStore.loanSearchText = opt.label;
                        if (opt.value && typeof opt.value === 'string' && opt.value.includes('DRAFT')) {
                          formStore.fetchLoanValues(opt.value.replace('DRAFT', ''), true);
                        }
                        else {
                          formStore.fetchLoanValues(opt.value, false);
                        }
                      }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <LoanValuesSection store={formStore} />
                  </Grid>

                  <Grid item xs={12}>
                    <TransactionValuesSection store={formStore} />
                  </Grid>

                  <Grid item xs={12} container justifyContent="center">
                    <CalculateButton onClick={calculateLiquidity} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid
                  container
                  spacing={2}
                  className={classes.calculatedValuesBorder}
                >
                  <Grid item xs={12}>
                    <Typography variant={'h4'}>
                      Total Borrower Liquidity
                    </Typography>
                  </Grid>
                  <CalculatedValues store={formStore} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        <LiquidityCalculatorFieldCalculationsListener store={formStore} />
      </Layout>
    );
  }
);

const CalculatedValues = observer(({ store }: LiquidityCalcSectionProps) => {
  const escrowsMonthAmount = 3;
  const { calculatedValues } = store;
  const isBorrowerForeignNational = store.form.fields.isBorrowerForeignNational.value;
  const isMultifamilyOrMixedUseProperty = store.form.fields.isMultifamilyOrMixedUseProperty.value;
  const loanAmount = store.form.fields.loanAmount.value;
  const loanPurpose = store.form.fields.loanPurpose.value;

  const formatCurr = value =>
    value || value === 0 ? formatCurrency(value) : '';

  const showMixedUseMultifamilyOrLoanAmountTotal = () => {
    return isMultifamilyOrMixedUseProperty || loanAmount > 1000000;
  }

  return (
    <>
      <Grid item xs={12}>
        <PaperList>
          <PaperListTitle text="Costs at Close" />
          <PaperListItem
            label={`${escrowsMonthAmount} Months P&I (Closing Reserves)`}
            value={
              calculatedValues
                ? formatCurr(calculatedValues.monthlyPAndI * escrowsMonthAmount)
                : '-'
            }
          />
          <PaperListItem
            label={`${escrowsMonthAmount} Months Taxes (Closing Reserves)`}
            value={
              calculatedValues
                ? formatCurr(calculatedValues.monthlyTaxes * escrowsMonthAmount)
                : '-'
            }
          />
          <PaperListItem
            label={`${escrowsMonthAmount} Months Insurance (Closing Reserves)`}
            value={
              calculatedValues ? formatCurr(calculatedValues.monthlyInsurance * escrowsMonthAmount) : '-'
            }
          />
          <PaperListItem
            label={`Post Close Reserves`}
            value={
              calculatedValues ? formatCurr(calculatedValues.postCloseReserves) : '-'
            }
          />
          {loanPurpose === LoanPurposeOption.PURCHASE && (
            <>
              <PaperListItem
                label={`Down Payment`}
                value={store.getFormValues() ?
                  formatCurr(store.getFormValues().downPayment) : '-'}
              />
              <PaperListItem
                label={`Origination Fee Amount`}
                value={store.getFormValues() ?
                  formatCurr(store.getFormValues().originationAmount) : '-'}
              />
              <PaperListItem
                label={`Estimated Closing Costs`}
                value={store.getFormValues() ?
                  formatCurr(store.getFormValues().closingCosts) : '-'}
              />
            </>
          )}
          {loanPurpose === LoanPurposeOption.RATE_TERM_REFI && (
            <>
              <PaperListItem
                label={`Origination Fee Amount`}
                value={store.getFormValues() ?
                  formatCurr(store.getFormValues().originationAmount) : '-'}
              />
              <PaperListItem
                label={`Estimated Closing Costs`}
                value={store.getFormValues() ?
                  formatCurr(store.getFormValues().closingCosts) : '-'}
              />
            </>
          )}
        </PaperList>
      </Grid>
      <Grid item xs={12}>
        <PaperList>
          <PaperListTitle text="Liquidity Totals" />
          <PaperListItem
            label="Total Borrower Liquidity Needed"
            value={
              calculatedValues
                ? formatCurr(calculatedValues.totalLiquidity)
                : '-'
            }
          />
          {/*           {showMixedUseMultifamilyOrLoanAmountTotal() &&
            <PaperListItem
              label="Mixed Use/Multifamily or Loan>$1mm"
              value={
                calculatedValues
                  ? formatCurr(calculatedValues.mixedUse)
                  : '-'
              }
            />
          }
          {isBorrowerForeignNational &&
            <PaperListItem
              label="Foreign National"
              value={
                calculatedValues
                  ? formatCurr(calculatedValues.foreignNational)
                  : '-'
              }
            />
          } */}
        </PaperList>
      </Grid>
    </>
  );
});

export const LoanValuesSection = ({ store }: LiquidityCalcSectionProps) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant={'h5'}>Loan Values</Typography>
      </Grid>

      <LoanAmount store={store} />
      <Rate store={store} />
      <AnnualTaxes store={store} />
      <AnnualInsurance store={store} />
      <LoanPurpose store={store} />
      <AmortizationType store={store} />
      <LoanSubtype store={store} />
      <BorrowerType store={store} />
      <MultifamilyMixedUse store={store} />
    </Grid>
  );
};

export const TransactionValuesSection = observer(
  ({ store }: LiquidityCalcSectionProps) => {
    const loanPurpose = store.form.fields.loanPurpose.value;

    return loanPurpose === LoanPurposeOption.CASHOUT ? null : (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant={'h5'}>Transaction Values</Typography>
        </Grid>

        {loanPurpose === LoanPurposeOption.PURCHASE && (
          <>
            <PurchasePrice store={store} />
            <DepositPaid store={store} />
            <OriginationFees store={store} />
          </>
        )}
        {loanPurpose === LoanPurposeOption.RATE_TERM_REFI && (
          <>
            <Payoff store={store} />
            <OriginationFees store={store} />
          </>
        )}
      </Grid>
    );
  }
);
