import { Paper, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {  RadioField } from '@roc/ui';
import { loanTermLengthsOptions } from '../../../utils/constants';
import { observer } from 'mobx-react';
import { PointsAndFeesAccordion } from './pointsAndFeesAccordion';

import { QuoteTypeStore } from '../../../stores/quoteTypeStore';
import { isGroundUp, isNotBlank } from '@roc/feature-utils';
import { PercentagePicker } from '../../leverage/components/leverageComponents';
import { InterestRateSlider } from './interestRateSlider';
import { BrokerAccordion } from './brokerAccordion';
import { PricingAdjustmentsAccordion } from './pricingAdjustmentsAccordion';
import { useBaseStore } from '@roc/feature-app-core';

const useStyles = makeStyles(theme => ({
  paper: {
    border: `1px solid lightgray`,
    borderRadius: '16px !important',
    padding: theme.spacing(4),
  },
  tooltip: {
    backgroundColor: theme.palette.primary.main,
    fontSize: '1rem',
  },
}));

interface EconomicsCardProps {
  store: QuoteTypeStore;
}

export const EconomicsCard = observer(({ store }: EconomicsCardProps) => {
  const classes = useStyles();
  const { loanEconomicsStore } = store;
  const { globalStore } = useBaseStore();
  const formStore = store.loanEconomicsStore.loanEconomicsFormStore;
  const loanData = store.loanEconomicsStore.quoteTypeStore.getQuoteData();

  return (
    <Paper className={classes.paper} elevation={0}>
      <Grid container spacing={2}>
        <Grid item xs={12} style={{ paddingInline: '24px' }}>
          <Grid container spacing={2} justifyContent="space-between">
            <Grid item>
              <PercentagePicker
                fieldName={'interestRate'}
                label="Borrower Interest Rate"
                value={formStore.form.fields.interestRate.value}
                min={loanEconomicsStore.floorRate}
                max={14}
                onChange={value =>
                  formStore.onFieldChange('interestRate', value)
                }
              />
            </Grid>
            <Grid item>
              <InterestRateSlider store={store} />
            </Grid>
            <Grid item>
              <RadioField
                label={'Loan Duration'}
                value={(
                  formStore.form.fields.loanTermMonths.value ?? ''
                ).toString()}
                options={loanTermLengthsOptions.map(({ label, value }) => ({
                  label,
                  value: value.toString(),
                }))}
                onChange={value => {
                  formStore.onFieldChange('loanTermMonths', parseInt(value));
                  store.loanEconomicsStore.fetchBorrowerPointsOptions();
                }}
                fullWidth
                row={true}
                testId={'loanTermMonths'}
                standaloneLabel={true}
                error={isNotBlank(formStore.form.fields.loanTermMonths.error)}
                errorText={formStore.form.fields.loanTermMonths.error}
              />
            </Grid>
          </Grid>
        </Grid>
        {globalStore?.lenderInfo?.isInternal && isGroundUp(loanData?.loanSubtype) &&
        <Grid item xs={12}>
          <PricingAdjustmentsAccordion store={store} />
        </Grid>
        }
        <Grid item xs={12}>
          <BrokerAccordion store={store} />
        </Grid>
        <Grid item xs={12}>
          <PointsAndFeesAccordion store={store} />
        </Grid>
      </Grid>
    </Paper>
  );
});
