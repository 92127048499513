import { Page } from "@roc/feature-app-core";
import { Route } from "react-router";
import { DrawDqDataContainer } from "../components/drawDqDataContainer";

export const getDrawDqDataRoutesConfig = (
  basePath: string,
  baseUrl: string
) => {
  return {
    drawDqData: {
      path: `${basePath}/draw-dq-data`,
      url: `${baseUrl}/draw-dq-data`,
      documentTitle: 'Draw dq',
    },
  };
};

export const getDrawDqDataRoutes = (basePath: string, baseUrl: string) => {
  const config = getDrawDqDataRoutesConfig(basePath, baseUrl);
  return {
    drawDqData: (
      <Route exact path={config.drawDqData.path}>
        <Page routeDefinition={config.drawDqData}>
          <DrawDqDataContainer/>
        </Page>
      </Route>
    )
  }
}