import { lighten, makeStyles } from "@material-ui/core/styles";

export const getBorrowerPortalFullScreenDialogStyles = makeStyles(theme => ({
  dialogRoot: {
    '& .MuiDialogTitle-root': {
      background: lighten(theme.palette.primary.main, 0.9),
      borderBottom: '2px solid #555',
    }
  },
}));
