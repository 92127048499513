import { borrowerExpMapping, FULL_RECOURSE, getUniqueId, isNil, isNotBlank, LoanSubType, NON_RECOURSE } from '@roc/feature-utils';
import { renovationDescriptionsByLoanSubtype } from '../../utils/renovationDescriptions';
import {
  FULLY_AMORTIZING,
  FULLY_AMORTIZING_LABEL,
  mapRateType,
  PARTIAL_INTEREST,
  PARTIAL_INTEREST_LABEL,
  PURCHASE,
} from './pricerSummaryHelper';
import { mapPPPMethods } from 'libs/client-portal-shared/src/app/modules/internalPricer/utils/internalPricerUtils';


const mapParticipationValue = {
  'Participate through life of loan': 'Through life of loan',
  'Participate with subsequent buy-out': 'With subsequent buy-out',
  'Broker Loan': 'No Participation',
};

export const mapPrincipalInterest = {
  true: "Full Boat",
  false: "As disbursed"
};

const mapLoanPurpose = (loanPurpose) => (
  loanPurpose === PURCHASE ? "Purchase" : "Refinance"
)

const getInitialLoanToPurpose = (initialLoanToPurchase, calculatedLoanData) => (
  initialLoanToPurchase ?? calculatedLoanData?.intialLoanToPurchasePricePercentage
)

const getPoints = (points, calculatedLoanData) => (
  points ?? calculatedLoanData?.dealEconomics?.points
)

const getConstructionHoldbackPercentage = (constructionHoldbackPercentage, calculatedLoanData) => (
  calculatedLoanData?.constructionHoldbackToRenovationBudgetPercentage ?? constructionHoldbackPercentage
)

export const getBridgeLoanTerms = draftLoan => {
  const {
    lenderParticipation,
    submitAppraisalWithTamarisk,
    fullPrincipalInterest,
    initialLoanAmount,
    initialLoanToPurchase,
    constructionHoldback,
    constructionHoldbackPercentage,
    rate,
    points,
    rocPointsIn,
    brokerPoints,
    interestReserveMonths,
    duration,
    origLenderSubordination,
    attorneyEmail,
    preferredInsurance,
    requestedClosingDate,
    amount,
    loanInsuranceData,
    preferredTitle,
    titleCompany,
    lenderOriginatorId,
    calculatedLoanData,
    underwritingFee,
    processingFee,
    externalLenderUnderwritingFee,
    externalLenderProcessingFee,
    loanPurpose,
    condoConversion,
    freeAppraisalPromotion,
    referralFee,
    marketingPromotionId,
    referralFeePct
  } = draftLoan;
  const newInitialLoanToPurchase = getInitialLoanToPurpose(initialLoanToPurchase, calculatedLoanData);
  const newPoints = getPoints(points, calculatedLoanData);
  const newConstructionHoldbackPercentage = getConstructionHoldbackPercentage(
    constructionHoldbackPercentage,
    calculatedLoanData);

  const { selectedCompany } = loanInsuranceData
  return {
    participation: mapParticipationValue[lenderParticipation],
    lenderParticipation,
    submitAppraisalWithTamarisk,
    fullPrincipalInterest,
    initialLoanAmount,
    initialLoanToPurchase: newInitialLoanToPurchase,
    constructionHoldback,
    constructionHoldbackPercentage: newConstructionHoldbackPercentage,
    rate,
    points: newPoints,
    rocPointsIn,
    brokerPoints,
    interestReserveMonths,
    duration,
    origLenderSubordination,
    preferredClosingAttorney: attorneyEmail,
    preferredInsurance,
    requestedClosingDate,
    amount,
    otherInsuranceCompany: {
      ...selectedCompany,
      cellPhone: selectedCompany?.phone,
      emailAddress: selectedCompany?.email,
      contactName: selectedCompany?.contacts[0]?.fullName
    },
    preferredTitle,
    otherTitleCompany: {
      ...titleCompany,
      cellPhone: titleCompany?.phone,
      emailAddress: titleCompany?.email,
      contactName: titleCompany?.contacts[0]?.fullName
    },
    interestAccrualMethod:
      mapPrincipalInterest[fullPrincipalInterest] ?? 'As disbursed',
    loanOriginator: lenderOriginatorId,
    lenderUnderwritingFee: underwritingFee ?? 0,
    lenderProcessingFee: processingFee,
    externalLenderUnderwritingFee: externalLenderUnderwritingFee,
    externalLenderProcessingFee: externalLenderProcessingFee,
    loanPurpose,
    condoConversion: condoConversion ? 'Y' : 'N',
    freeAppraisalPromotion: freeAppraisalPromotion ? 'Y' : 'N',
    referralFee: referralFee ? referralFee : referralFeePct,
    marketingPromotionId: marketingPromotionId
  };
};

export const getBorrowerEntity = draftLoan => {
  const {
    borrowerEntityObj,
    loanBorrowerInformation: { loanBorrowers: { rows } },
  } = draftLoan;
  return {
    ...borrowerEntityObj,
    borrowers: rows,
  };
};

export const getBorrowerEntityFromProposal = proposal => {
  const {
    loanBorrowerInformation: {
      borrowerEntity: { company },
      loanBorrowers: { rows },
    },
  } = proposal;

  const { einNumber: ein, entityType: type, ...otherDetails } = company;

  return {
    ...otherDetails,
    ein,
    type,
    borrowers: rows,
  };
};

export const getBorrowerEntityObjFromLoanRequest = loanRequest => {

  return {
    ...loanRequest.borrowerEntityRequest,
    borrowers: loanRequest.loanBorrowerRequests,
  };
};

export const getPropertyDetails = draftLoan => {
  const {
    propertiesMap: { rows }
  } = draftLoan;
  return {
    rows,
  }
};

export const getBorrowersFromList = (rows, roleInTransactionOptions) => {

  // Compatibility with eportal, remove empty borrowers
  const borrowerNotEmpty = borrower => {
    return (
      borrower.firstName !== '' ||
      borrower.lastName !== '' ||
      borrower.emailAddress !== ''
    );
  };

  return rows.filter(borrowerNotEmpty).map(borrower => ({
    ...borrower,
    roleInTransaction: isNotBlank(borrower.roleInTransaction) ? getSelectFieldValue(
      borrower.roleInTransaction,
      roleInTransactionOptions
    ) : [],
    ownershipNotKnown: borrower.OwnershipNotKnown, // Compatibility with eportal
    creditBackgroundCheck: borrower.creditBackgroundCheck === 0 // Compatibility with eportal
      || borrower.wantCreditReport === 'Yes' // Compatibility with eportal
      || borrower.creditBackgroundCheck === true,
  }));
};

export const getBorrowers = (draftLoan, roleInTransactionOptions) => {
  const {
    loanBorrowerInformation: { loanBorrowers: { rows } },
  } = draftLoan;

  return getBorrowersFromList(rows, roleInTransactionOptions);
};

export const getBridgePropertiesFromList = (rows, loanSubtype) => {
  const renovationDescriptionOptions = renovationDescriptionsByLoanSubtype(
    loanSubtype
  );

  return rows.map(property => {
    if (!(loanSubtype === LoanSubType.STABILIZED_BRIDGE)) {
      property.describeRenovation = getSelectFieldValue(
        property.describeRenovation,
        renovationDescriptionOptions
      )
    }
    return {
      ...property,
      propertyId: property.propertyId || getUniqueId(),
      armsLength: property.armsLength ? 'Y' : 'N',
      anyDebt: property.anyDebt ? 'Y' : 'N',
      appraisalId: property.propertyAppraisalId,
      submitRushAppraisal: property.submitRushedAppraisal,
      renovationRemaining: property.renovationBudget,
      totalAnnualUtilities: property.annualUtilities,
      multipleLots: property?.numberOfLots > 0 ? true : false

    };
  });
};

export const getBridgeProperties = draftLoan => {
  const {
    propertiesMap: { rows },
    loanSubtype,
  } = draftLoan;

  return getBridgePropertiesFromList(rows, loanSubtype);
};

export const getStbBridgePropertiesFromExistingLoan = loan => {
  const {
    propertiesMap: { rows },
  } = loan;

  return getStbBridgePropertiesFromList(rows, loan);
};

export const getStbBridgePropertiesFromList = (rows, loan) => {
  return rows.map(property => {
    return {
      address: property?.address,
      aptNumber: property?.aptNumber,
      streetNumber: property?.streetNumber,
      streetName: property?.streetName,
      city: property?.city,
      state: property?.state,
      zipCode: property?.zipCode,
      latitude: property?.latitude,
      longitude: property?.longitude,
      __isAddressInCorrectFormat__: true,
      propertyType: property?.useCode,
      acquisitionPrice: property?.purchasePrice,
      loanPurpose: mapLoanPurpose(loan?.loanPurpose),
      armsLength: property?.armsLength,
      armsLengthComment: property?.armsLengthComment ?? ' ',
      propertySourcing: property?.propertySourcing,
      propertySourcingExplanation: property?.propertySourcingExplanation,
      wholesaleAmount: property?.wholesaleAmount,
      wholesalerPurchase: property?.wholesalerPurchase,
      wholesalerPropertySourcingExplanation: property?.propertySourcingExplanation,
      propertyValuation: property?.nsfrLoanPropertyFields?.propertyValuationAtOrigination,
      monthlyGrossRent: property?.nsfrLoanPropertyFields?.monthlyGrossRent,
      monthlyMarketRent: property?.nsfrLoanPropertyFields?.monthlyMarketRent,
      annualTaxes: property?.nsfrLoanPropertyFields?.annualTaxes,
      annualInsurance: property?.nsfrLoanPropertyFields?.propertyAnnualInsurance,
      annualHOA: property?.nsfrLoanPropertyFields?.annualHOA,
      exitStrategy: property?.exitStrategy,
      occupancy: property?.occupancy,
      acquisitionDate: property?.nsfrLoanPropertyFields?.acquisitionDate,
      purchaseDate: property?.nsfrLoanPropertyFields?.acquisitionDate,
      capitalImprovements: property?.nsfrLoanPropertyFields?.capitalImprovements,
      anyDebt: property.anyDebt,
      totalDebtPayoff: property?.nsfrLoanPropertyFields?.totalDebtPayoff,
      propertyId: property.propertyId || getUniqueId(),
      appraisalId: property.propertyAppraisalId,
      propertyOwnership: property.propertyOwnership,
      isOriginalAddress: property.isOriginalAddress,
      permitsInPlace: property.permitsInPlace,
      numberOfLots: property.numberOfLots
    };
  });
};

export const getSelectFieldValue = (selectedValues, options) => {
  if (isNil(selectedValues)) {
    return [];
  }

  const values = selectedValues.split(',');
  return options.filter(option => values.includes(option.value)) ?? [];
};

export const getTermPrimaryProperty = draftLoan => {
  const { primaryProperty } = draftLoan;
  const {
    nsfrLoanPropertyFields,
    extraFields,
    acquisitionDate,
    useCode,
    noOfUnitLeased,
    unitsLeaseType,
    anyDebt,
    bankruptcy,
    foreclosure,
    propertyCounties,
    rural,
    section8,
  } = primaryProperty;

  return {
    ...primaryProperty,
    ...nsfrLoanPropertyFields,
    ...extraFields,
    acquisitionDate: acquisitionDate, //ISO Date
    propertyType: useCode,
    unitsOccupied: noOfUnitLeased,
    unitsOccupiedHave12MonthsLease: unitsLeaseType ? 'Y' : 'N',
    anyDebt: anyDebt ? 'Y' : 'N',
    bankruptcy: bankruptcy ? 'Y' : 'N',
    foreclosure: foreclosure ? 'Y' : 'N',
    propertyCounty: propertyCounties?.find(county => county !== undefined),
    rural: rural ? 'Y' : 'N',
    section8: section8 ? 'Y' : 'N'
  };
};

export const getTermPricerDetails = draftLoan => {
  const property = getTermPrimaryProperty(draftLoan);
  const {
    loanBorrowerInformation: { loanBorrowers: { rows } },
    pricerBorrower,
  } = draftLoan;
  let borrower: any = {};

  if (rows.length > 0) {
    borrower = rows[0];
  } else {
    borrower = { ...pricerBorrower };
  }
  return {
    ...borrower,
    ...property,
    loanPurpose: mapLoanPurpose(draftLoan.loanPurpose),
    ficoProvidedAtOrigination: draftLoan.ficoProvidedAtOrigination,
  };
};

export const getTermPricerDetailsFromExistingLoan = existingLoan => {
  const {
    loanBorrowerInformation: { loanBorrowers: { rows } },
    propertiesMap
  } = existingLoan;
  const borrower = rows.length > 0 ? rows[0] : {};

  const property = propertiesMap.rows.length > 0 ? propertiesMap.rows[0] : {};
  const termProperty = getTermPropertyFromExistingLoan(existingLoan, property, borrower);


  return {
    ...borrower,
    ...termProperty,
    loanPurpose: mapLoanPurpose(existingLoan.loanPurpose),
    ficoProvidedAtOrigination: borrower.ficoProvidedAtOrigination,
  };
};

export const getTermPropertyFromExistingLoan = (existingLoan, property, borrower) => {
  const {
    nsfrLoanPropertyFields,
    extraFields,
    useCode,
    anyDebt,
    section8,
    bankruptcy,
    foreclosure,
    county,
  } = property;

  const {
    acquisitionDate
  } = nsfrLoanPropertyFields;

  return {
    ...property,
    ...nsfrLoanPropertyFields,
    ...extraFields,
    acquisitionDate: acquisitionDate, //ISO Date
    propertyType: useCode,
    anyDebt: anyDebt ? 'Y' : 'N',
    section8: section8 ? 'Y' : 'N',
    bankruptcy: bankruptcy ? 'Y' : 'N',
    foreclosure: foreclosure ? 'Y' : 'N',
    propertyCounty: county,
    propertyValuation: nsfrLoanPropertyFields?.propertyValuationAtOrigination,
    annualInsurance: nsfrLoanPropertyFields?.propertyAnnualInsurance,
    targetLTV: existingLoan?.loanToValueAtSubmission,
    borrowerExp: borrowerExpMapping[borrower?.rentalExperience],
  };
};

export const getLoanTermPricerDetails = draftLoan => {
  const { amortization, extraFields, rateType, duration, lenderPremium } = draftLoan;

  return {
    ...draftLoan,
    amortization: mapAmortization[amortization],
    seekingCashOut: (extraFields.seekingCashOut === 'Yes' // Compatibility with eportal
      || extraFields.seekingCashOut === 'Y') ? 'Y' : 'N',
    rateType: mapRateType[rateType],
    prePaymentPenalty: extraFields.prePaymentPenalty,
    oneTimeYieldSpreadPremium: lenderPremium,
    loanTerm: duration / 12,
  };
};

export const getLoanTermPricerDetailsFromExistingLoan = existingLoan => {
  const { amortization, prepaymentDescription, rateType, duration, lenderPremium, loanPurpose } = existingLoan;
  return {
    ...existingLoan,
    amortization: mapAmortization[amortization],
    rateType: mapRateType[rateType],
    prePaymentPenalty: mapPPPMethods(prepaymentDescription),
    oneTimeYieldSpreadPremium: lenderPremium,
    loanTerm: duration / 12,
    seekingCashOut: loanPurpose === 'Cash Out Refi' ? 'Y' : 'N'
  };
};

const mapAmortization = {
  [FULLY_AMORTIZING_LABEL]: FULLY_AMORTIZING,
  [PARTIAL_INTEREST_LABEL]: PARTIAL_INTEREST,
};

export const getTermLoanDetails = draftLoan => {
  const {
    submitAppraisalWithTamarisk,
    gfdPayment,
    attorneyEmail,
    lenderOriginatorId,
    fastTrack,
    freeAppraisalPromotion,
    titleCompany,
    referralFee,
    marketingPromotionId,
    referralFeePct
  } = draftLoan;

  return {
    ...draftLoan,
    otherTitleCompany: {
      ...titleCompany,
      cellPhone: titleCompany?.phone,
      emailAddress: titleCompany?.email,
      contactName: titleCompany?.contacts?.length > 0 ? titleCompany?.contacts[0]?.fullName : ''
    },
    submitAppraisalWithTamarisk: submitAppraisalWithTamarisk ? 'Y' : 'N',
    gfdPayment: gfdPayment ? 'Y' : 'N',
    fastTrack: fastTrack ? 'Y' : 'N',
    freeAppraisalPromotion: freeAppraisalPromotion ? 'Y' : 'N',
    preferredClosingAttorney: attorneyEmail,
    loanOriginator: lenderOriginatorId,
    referralFee: referralFee ? referralFee : referralFeePct,
    marketingPromotionId: marketingPromotionId
  };
};

export const getTermPortfolioPrimaryProperty = draftLoan => {
  const termPrimaryProperty = getTermPrimaryProperty(draftLoan);
  const {
    primaryProperty: {
      anyDebt,
      state,
      propertyCounties,
      propertyValuation,
      monthlyGrossRent,
      annualTaxes,
      annualInsurance,
      annualHOA,
      otherOwnerPaidExpenses,
      propertyTypeData
    },
    recourseStructure,
  } = draftLoan;

  const newPropertyTypeData = propertyTypeData.map(item => {
    return {
      ...item,
      numberOfProperties: Number(item.noOfProperties ?? 0),
    }
  });

  const newPropertyCounties = propertyCounties.map(county => {
    return {
      value: county,
      label: county,
    }
  });

  return {
    ...termPrimaryProperty,
    propertyStates: state,
    propertyCounties: [...newPropertyCounties],
    totalEstimatedAsIsValue: propertyValuation,
    totalGrossMonthlyRent: monthlyGrossRent,
    totalGrossAnnualTaxes: annualTaxes,
    totalGrossAnnualInsurance: annualInsurance,
    totalAnnualHOADues: annualHOA,
    totalOtherAnnualExpenses: otherOwnerPaidExpenses,
    loanRecourse: mapLoanRecourse[recourseStructure],
    propertyTypeData: newPropertyTypeData
  };
};

const mapLoanRecourse = {
  'Full Recourse': FULL_RECOURSE,
  'Non Recourse w/ bad boy carveouts': NON_RECOURSE,
};

export const getTermPortfolioPricerDetails = draftLoan => {
  const property = getTermPortfolioPrimaryProperty(draftLoan);
  const {
    loanBorrowerInformation: { loanBorrowers: { rows } },
    pricerBorrower,
  } = draftLoan;
  let borrower = {};

  if (rows.length > 0) {
    borrower = rows[0];
  } else {
    borrower = { ...pricerBorrower };
  }
  return {
    ...borrower,
    ...property,
    loanPurpose: mapLoanPurpose(draftLoan.loanPurpose),
    ficoProvidedAtOrigination: draftLoan.ficoProvidedAtOrigination,
  };
};

export const getBorrowerEntityForLoanApplication = loanApplication => {
  const {
    loanBorrowerInformation: { borrowerEntity, loanBorrowers: { rows } },
  } = loanApplication;
  return {
    ...borrowerEntity,
    ...borrowerEntity.company,
    ein: borrowerEntity.company.einNumber,
    type: borrowerEntity.company.entityType,
    borrowers: rows,
  };
};
