import { Page } from '@roc/feature-app-core';
import { Route } from 'react-router';
import { BackgroundReviewForm } from '../backgroundReview/backgroundReviewForm';
import { BackgroundReview } from '../backgroundReview/backgroundReview';

export const getBackgroundReviewRoutesConfig = (basePath: string, baseUrl: string, borrowerId: string, loanId: string) => {
  return {
    background: {
      path: `${basePath}/background-review`,
      url: `${baseUrl}/background-review`,
      documentTitle: 'Background',
    },
  };
}

export const getBackgroundReviewRoutes = (basePath: string, baseUrl: string, borrowerId: string, loanId: string, readOnly?: string) => {
  const config = getBackgroundReviewRoutesConfig(basePath, baseUrl, borrowerId, loanId);
  return {
    background: (
      <Route exact path={config.background.path}>
        <Page routeDefinition={config.background}>
          <BackgroundReview borrowerId={borrowerId} loanId={loanId} isInternal={isInternal(baseUrl)} readOnly={readOnly==="true"} />
        </Page>
      </Route>
    ),
  };
};

const isInternal = (baseUrl: string) => {
  return baseUrl.includes('internal');
}