import { observer } from "mobx-react"
import { useEffect, useRef, ComponentProps, useState } from "react";

interface HtmlContainerProps extends ComponentProps<"div"> {
  html: string;
}

export const HtmlContainer = observer(({ html, ...divProps }: HtmlContainerProps) => {
  const elementRef = useRef<HTMLDivElement>();
  useEffect(() => {
    const element = elementRef.current;
    if(element){
      if(!element.shadowRoot){
        element.attachShadow({ mode: "open" });
      }
      element.shadowRoot.innerHTML = html
    }
  },[elementRef.current, html])
  return <div ref={elementRef} {...divProps} />
})