import { FormStore } from '@roc/feature-app-core';
import { flow, makeObservable, observable, action } from 'mobx';
import { GlobalStore } from '@roc/feature-app-core';
import { LoanRequestsService } from './../services/loanRequestsService';

const REQUIRED_FIELD = 'This field is required.';

const form = {
  fields: {
    description: {
      value: '',
      error: null,
      rule: 'required',
      message: REQUIRED_FIELD
    },
    origAmt: {
      value: 0,
      error: null,
      rule: 'required',
      message: REQUIRED_FIELD
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export class ServicerFeesFormStore extends FormStore {
  loanRequestsService: LoanRequestsService;
  globalStore: GlobalStore;
  public servicerFees = [];
  constructor(globalStore: GlobalStore, loanRequestsService: LoanRequestsService) {
    super({ ...form }, globalStore);
    this.loanRequestsService = loanRequestsService;
    this.globalStore = globalStore;

    makeObservable(this, {
      editServicerFee: flow,
      addServicerFee: flow,
      deleteServicerFee: flow,
      servicerFees: observable,
      setServicerFees: action,
      resetStore: action,
    });
  }

  setServicerFees(servicerFees: string) {
    if (servicerFees) {
      this.servicerFees = JSON.parse(servicerFees);
    }
  };

  *editServicerFee(loanId: string, index: number) {
    try {
      const feesList = this.servicerFees.slice();
      feesList[index].description = this.form.fields.description.value;
      feesList[index].origAmt = this.form.fields.origAmt.value;
      const response = yield this.loanRequestsService.saveServicerFees(loanId, JSON.stringify(feesList));
      if (response.data.status === 'OK') {
        this.servicerFees = feesList;
        this.globalStore.notificationStore.showSuccessNotification({
          message: 'Fees were successfully updated.',
        });
      }
    } catch (error) {
      console.log(error);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while updating servicing fees.',
      });
    }
  };

  *deleteServicerFee(loanId: string, index: number) {
    try {
      const feesList = this.servicerFees.slice();
      feesList.splice(index, 1);
      const response = yield this.loanRequestsService.saveServicerFees(loanId, JSON.stringify(feesList));
      if (response.data.status === 'OK') {
        this.servicerFees = feesList;
        this.globalStore.notificationStore.showSuccessNotification({
          message: 'Servicing fee was successfully deleted.',
        });
      }
    } catch (error) {
      console.log(error);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while deleting servicing fee.',
      });
    }
  };

  *addServicerFee(loanId: string) {
    try {
      const newFee = {
        description: this.form.fields.description.value,
        origAmt: this.form.fields.origAmt.value,
      }
      const feesList = this.servicerFees.slice();
      feesList.push(newFee)
      const response = yield this.loanRequestsService.saveServicerFees(loanId, JSON.stringify(feesList));
      if (response.data.status === 'OK') {
        this.servicerFees = feesList;
        this.globalStore.notificationStore.showSuccessNotification({
          message: 'New fee successfully added.',
        });
      }
    } catch (error) {
      console.log(error);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while adding new servicing fee.',
      });
    }
  };

  reset = () => {
    super.reset();
  };

  resetStore = () => {
    this.servicerFees = [];
    this.reset();
  };
}