import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import ViewDrawsGrid from './viewDrawsGrid';

const useStyles = makeStyles(theme => ({
  container: {
    padding: 32,
  },
}));

export const ViewDraws = observer(() => {
  const classes = useStyles();

  return (
    <Grid
      container
      direction="column"
      spacing={2}
      className={classes.container}
    >
      <Grid item>
        <Typography variant="h4">DRAWS</Typography>
      </Grid>
      <Grid
        item
        style={{ display: 'flex', flexGrow: 1, flexDirection: 'column' }}
      >
        <ViewDrawsGrid />
      </Grid>
    </Grid>
  );
});

export default ViewDraws;
