import { Box, makeStyles, Theme } from '@material-ui/core';
import { Layout, Page } from '@roc/feature-app-core';
import { Route } from 'react-router';
import { CreditReportForm } from '../components/utils/creditReportForm';
import { EmbeddedFrameBoundaries } from '@roc/ui';
import { CreditReportExampleImage } from '../components/utils/creditReportExample';

export const getCreditReportRoutesConfig = (
  basePath: string,
  baseUrl: string,
  loanDocumentId: string
) => {
  return {
    creditReport: {
      path: `${basePath}/credit-report`,
      url: `${baseUrl}/credit-report`,
      documentTitle: 'Credit Report'
    },
  };
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    overflow: 'hidden',
    backgroundColor: 'white',
    margin: '0px 0px',
    padding: '0px 0px'
  },
}));

export const getCreditReportRoutes = (
  basePath: string,
  baseUrl: string,
  loanDocumentId: string
) => {
  const config = getCreditReportRoutesConfig(basePath, baseUrl, loanDocumentId);
  const classes = useStyles();

  return {
    creditReport: (
      <Route exact path={config.creditReport.path}>
        <Page routeDefinition={config.creditReport}>
          <Box pl={3} pr={3} style={{ background: 'white' }}>
            <Layout title='Credit Report - Upload' minHeight={100} containerClass={classes.container}>
              <EmbeddedFrameBoundaries>
                <CreditReportForm
                  open={true}
                  loanDocumentId={loanDocumentId}
                  handleClose={() => { }}
                  internal={isInternal(baseUrl)}
                />
              </EmbeddedFrameBoundaries>
            </Layout>
          </Box>
        </Page>
      </Route>
    ),
  };
}

export const getCreditReportExampleRoutesConfig = (
  basePath: string,
  baseUrl: string,
) => {
  return {
    creditReportExample: {
      path: `${basePath}/credit-report-example`,
      url: `${baseUrl}/credit-report-example`,
      documentTitle: 'Credit Report Example'
    },
  };
};

export const getCreditReportExampleRoutes = (
  basePath: string,
  baseUrl: string,
) => {
  const config = getCreditReportExampleRoutesConfig(basePath, baseUrl);
  const classes = useStyles();

  return {
    creditReportExample: (
      <Route exact path={config.creditReportExample.path}>
        <Page routeDefinition={config.creditReportExample}>
          <Box pl={3} pr={3} style={{ background: 'white' }}>
            <EmbeddedFrameBoundaries>
              <div style={{ display: 'flex', alignItems: 'center', height: '100vh' }}>
                <CreditReportExampleImage />
              </div>
            </EmbeddedFrameBoundaries>
          </Box>
        </Page>
      </Route>
    ),
  };
};

const isInternal = (baseUrl: string) => {
  return baseUrl.includes('internal');
}
