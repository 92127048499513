import { FormStore } from '@roc/feature-app-core';
import { parseAddress } from '@roc/feature-utils';

const trackRecordPropertyForm = {
  fields: {
    dealType: {
      value: '',
      error: null,
      message: 'The Deal Type is required',
      rule: 'required',
    },
    entityName: {
      value: '',
      error: null,
      message: 'The Entity Name is required',
      rule: 'required',
    },
    fullAddress: {
      value: '',
      error: null,
      message: 'The Address is required',
      rule: 'required',
    },
    address2: {
      value: '',
      error: null,
      rule: '',
    },
    purchaseAddress: {
      value: '',
      error: null,
      message: '',
      rule: '',
    },
    purchaseState: {
      value: '',
      error: null,
      message: 'The State is required',
      rule: 'required',
    },
    purchaseCity: {
      value: '',
      error: null,
      message: 'The City is required',
      rule: 'required',
    },
    purchaseZipcode: {
      value: '',
      error: null,
      message: 'The Zipcode is required and must be 5 digits',
      rule: 'required|regex:/^\\d{5}$/|alpha_dash',
    },
    purchaseDate: {
      value: null,
      error: null,
      message: 'The Purchase Date is required',
      rule: 'required',
    },
    purchasePrice: {
      value: null,
      error: null,
      message: 'The Purchase Price is required',
      rule: 'required',
    },
    saleDate: {
      value: null,
      error: null,
      rule: '',
    },
    salePrice: {
      value: null,
      error: null,
      rule: '',
    },
    rehabBudget: {
      value: null,
      error: null,
      message: 'The Rehab Budget is required',
      rule: 'required',
    },
    verifiedTrackRecordsId: {
      value: null,
      error: null,
      rule: '',
    },
    isVerified: {
      value: false,
      error: null,
      rule: '',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export class TrackRecordPropertyFormStore extends FormStore {

  constructor(globalStore) {
    super({ ...trackRecordPropertyForm }, globalStore);
  }

  saveAddressField(address: string, addressDetails, geometryDetails) {
    const parsedAddress = parseAddress(
      address,
      addressDetails,
      geometryDetails
    );
    const streetAddress = parsedAddress.street_number === null ? address : parsedAddress.street_number + " " + parsedAddress.street;
    this.onFieldChange('fullAddress', streetAddress);
    this.onFieldChange('purchaseAddress',streetAddress);
    this.onFieldChange('purchaseCity', parsedAddress.city);
    this.onFieldChange('purchaseState', parsedAddress.state);
    this.onFieldChange('purchaseZipcode', parsedAddress.zip);
  }

}