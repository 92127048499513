import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { useBaseStore } from '@roc/feature-app-core';
import { Button, ConfirmationButton, TextField } from '@roc/ui';
import { observer } from 'mobx-react';
import { useDocumentStore } from '../../../hooks/useDocumentStore';

export type GoodFaithDepositProps = {
  loanId: string,
  paymentStatus: boolean
};

const useStyles = makeStyles({
  instruction: {
    fontWeight: 600,
  },
});

export const GoodFaithDeposit = observer((props: GoodFaithDepositProps) => {
  const { globalStore
  } = useBaseStore();
  const {
    documentStore: { documentFormStore },
  } = useDocumentStore();
  const { goodFaithDepositStore, loanPaymentsStore } = documentFormStore;

  const {
    hasValidationErrors,
    validationErrors,
    depositUrl,
  } = goodFaithDepositStore;

  const classes = useStyles();

  const handleConfirmDeposit = () => {
    goodFaithDepositStore.requestBorrowerDeposit();
  };

  const handleDownloadDocument = () => {
    loanPaymentsStore.downloadSignedDocument(props.loanId, "GFD");
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(depositUrl);
    globalStore.notificationStore.showSuccessNotification({
      message: 'Link was copied to clipboard!',
    });
  };

  const renderPaymentPending = () => {
    return (
      <Grid container justifyContent="center">
        {hasValidationErrors ? (
          <Box mb={2}>
            <ul>
              {validationErrors.map(error => (
                <li key={error}>
                  <span>{error}</span>
                </li>
              ))}
            </ul>
          </Box>
        ) : (
          <Grid
            alignItems="center"
            container
            item
            justifyContent="center"
            xs={12}
          >
            <Grid item container xs={11} spacing={2}>
              <Grid item>
                <Typography className={classes.instruction}>
                  Link for the borrower to make the Good Faith Deposit
                </Typography>
              </Grid>
              <Grid item container alignItems="center" spacing={2}>
                <Grid item xs={12} sm>
                  <TextField
                    testId="goodFaithDepositUrl"
                    variant="outlined"
                    label="Deposit URL"
                    value={depositUrl}
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleCopy}
                    testId="copyGoodFaithDepositUrlButton"
                    fullWidth
                  >
                    COPY LINK
                  </Button>
                </Grid>
              </Grid>
              <Grid item container spacing={2}>
                <Grid item>
                  <Typography className={classes.instruction}>
                    Or you can send an email to the borrower with the payment details
                  </Typography>
                </Grid>
                <Grid item container justifyContent="center">
                  <ConfirmationButton
                    variant="contained"
                    onClick={handleConfirmDeposit}
                    color="primary"
                    testId="sendGoodFaithDepositButton"
                    confirmDialogProps={{
                      title: 'Confirmation',
                      body: 'Email will be sent out. Please confirm',
                    }}
                  >
                    Email Link to Borrower
                  </ConfirmationButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  };

  const renderPaymentDone = () => {
    return (
      <Grid item container justifyContent="center" spacing={2}>
        <Grid item>
          <Typography className={classes.instruction}>
            Click on the button to download the signed document
          </Typography>
        </Grid>
        <Grid item container justifyContent="center">
          <Button
            variant="contained"
            onClick={handleDownloadDocument}
            color="primary"
            testId="downloadSignedDocumentButton"
          >
            Download signed document
          </Button>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid container>
      {props.paymentStatus ? renderPaymentDone() : renderPaymentPending()}
    </Grid>
  );
});

export default GoodFaithDeposit;
