import { Box, Typography } from '@material-ui/core';
import { green, orange } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { CheckCircle, FindInPage } from '@material-ui/icons';
import { Layout } from '@roc/feature-app-core';
import { VerticalBarLoader, StepsContainer } from '@roc/ui';
import { Paper } from '@roc/ui';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useStore } from '../../hooks';
import {
  WireAuthorizationState,
  UrlParameters,
} from '../../types';
import { WireAuthorizationForm } from './components/wireAuthorizationForm';
import { DocumentSigning } from './components/documentSigning';
import { DocumentVerificationV2, ConsentVerification } from '@roc/ui';
import { DocumentVerificationState } from '@roc/feature-types';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(3, 0, 4),
      minHeight: 900
    },
    stepper: {
      maxWidth: 900,
      margin: 'auto',
      [theme.breakpoints.down('xs')]: {
        '& .MuiStepLabel-label': {
          display: 'none',
        },
        '& .MuiStepLabel-label.MuiStepLabel-active': {
          display: 'block',
        },
      },
    },
  })
);

const WireAuthCompleteView = () => {
  return (
    <Box mt={4} mx={'auto'} textAlign="center">
      <CheckCircle style={{ fontSize: 60, color: green[500] }} />
      <br />
      <Typography variant="h4" color="textPrimary">
        Thank you
      </Typography>
      <br />
      <Typography>
        Your wire authorization has been completed successfully
      </Typography>
      <br />
    </Box>
  );
};

const WireAuthInReviewView = () => {
  return (
    <Box mt={4} mx={'auto'} textAlign="center">
      <FindInPage style={{ fontSize: 60, color: orange[500] }} />
      <br />
      <Typography variant="h4" color="textPrimary">
        Thank you
      </Typography>
      <br />
      <Typography>
        Your wire authorization is being reviewed! We will send you an email
        regarding the status.
      </Typography>
      <br />
    </Box>
  );
};

export const WireAuthorizationV1 = observer(() => {
  const classes = useStyles();
  const { wireAuthorizationStore, globalStore, wireAuthorizationStoreV2 } = useStore();
  const { uuid } = useParams<UrlParameters>();
  const {
    wireAuthorizationState,
  } = wireAuthorizationStore;
  const [
    allowNavigationInDocVerifyStep,
    setAllowNavigationInDocVerifyStep,
  ] = useState(false);
  const [
    allowNavigationInDocSigningStep,
    setAllowNavigationInDocSigningStep,
  ] = useState(false);


  useEffect(() => {
    window.addEventListener('message', handleWindowMessage);
    return () => window.removeEventListener('message', handleWindowMessage);
  }, []);

  const handleWindowMessage = (event: MessageEvent) => {
    switch (event.data) {
      case 'wire-authorization-docusign-done':
        wireAuthorizationStore.confirmDocusign();
        break;
    }
  };

  const steps = [
    {
      title: 'Identity Verification',
      icon: null,
      onBack: null,
      onNext: () => {
        if (!wireAuthorizationStore.identityVerificationCompleted) {
          if (wireAuthorizationStore.experienceTransactionId) {
            wireAuthorizationStore.identityVerification();
          } else {
            globalStore.notificationStore.showErrorNotification({
              message: "Error storing identity information",
            });
          }
        } else {
          setAllowNavigationInDocVerifyStep(true);
          wireAuthorizationStore.goNextStep();
        }
      },
      allowBack: false,
      allowNext: allowNavigationInDocVerifyStep,
    },
    {
      title: 'Bank Information',
      icon: null,
      onBack: () => {
        wireAuthorizationStore.setIdentityVerificationCompleted(true)
        wireAuthorizationStore.setDocumentVerificationState(DocumentVerificationState.IN_REVIEW)
        wireAuthorizationStore.goPrevStep();
      },
      onNext: () => {
        if (wireAuthorizationStore.form.meta.isValid) {
          wireAuthorizationStore.goNextStep();
        } else {
          wireAuthorizationStore.runFormValidationWithMessage();
        }
      },
      allowBack: true,
      allowNext: true,
    },
    {
      title: 'Electronic Signature',
      icon: null,
      onBack: () => {
        wireAuthorizationStore.goPrevStep();
      },
      onNext: () => {
      },
      allowBack: allowNavigationInDocSigningStep,
      allowNext: false,
    },
  ];

  const renderView = () => {
    const [parentEffectCompleted, setParentEffectCompleted] = useState(false);
    useEffect(() => {
      const fetchTruliooShortCode = async () => {
        try {
          await wireAuthorizationStoreV2.getTruliooShortCode();
          setParentEffectCompleted(true);
        } catch (error) {
          console.error(error);
          setParentEffectCompleted(true);
        }
      };
      fetchTruliooShortCode();
    }, []);
    switch (wireAuthorizationState) {
      case WireAuthorizationState.DONE:
        return <WireAuthCompleteView />;
      case WireAuthorizationState.LOADING:
        return (
          <Box mt={4} mx={'auto'} textAlign="center">
            <br />
            <Typography>
              Loading necessary information...
            </Typography>
            <br />
          </Box>
        );
      default:
        return (
          <StepsContainer
            activeStep={wireAuthorizationStore.activeStep}
            disableNavigation={true}
            steps={steps}
            stepperClassName={classes.stepper}
          >
            {wireAuthorizationStore.activeStep === 0 && (
              <>
                <ConsentVerification setAllowNavigationInDocVerifyStep={setAllowNavigationInDocVerifyStep} />
              </>
            )}
            {wireAuthorizationStore.activeStep === 1 && (
              <WireAuthorizationForm />
            )}
            {wireAuthorizationStore.activeStep === 2 && <DocumentSigning />}
          </StepsContainer>
        );
    }
  };

  return (
    <Layout maxWidth="lg">
      <Box my={3}>
        <Typography variant="h3" gutterBottom>
          {'Wire Authorization'}
        </Typography>
      </Box>
      {wireAuthorizationStore.loading && <VerticalBarLoader />}
      <Paper className={classes.paper}>{renderView()}</Paper>
    </Layout>
  );
});
