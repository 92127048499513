import { Grid, Typography } from '@material-ui/core';
import { Button } from '@roc/ui';
import { Add } from '@material-ui/icons';
import { observer } from 'mobx-react';
import { QuoteTypeStore } from '../../stores/quoteTypeStore';
import {
  PropertyInformationCard,
  PropertyInformationFormProps,
} from './components/propertyInformationCard';
import { StepTitle } from '../../components/stepTitle';

interface Props {
  store: QuoteTypeStore;
  PropertyInformationForm: React.FC<PropertyInformationFormProps>;
}

export const PropertyInformationStep = observer(
  ({ store, PropertyInformationForm }: Props) => {
    const { propertiesStore } = store;
    const propertyFormStores = propertiesStore.propertyFormStores;

    function handleAddMoreProperties() {
      propertiesStore.addNewProperty();
    }

    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <StepTitle
              title={<>Property Information</>}
              subtitle={
                <>
                  Please fill out the following information for the property
                  that will be used on the quote.
                </>
              }
            />
          </Grid>
          {propertyFormStores.map(propertyFormStore => (
            <Grid item xs={12} key={propertyFormStore.form.meta.key}>
              <PropertyInformationCard
                store={store}
                propertyFormStore={propertyFormStore}
                PropertyInformationForm={PropertyInformationForm}
              />
            </Grid>
          ))}
          <Grid
            item
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <Button
                size="medium"
                variant="outlined"
                color="primary"
                startIcon={<Add />}
                testId="edit-borrower"
                onClick={handleAddMoreProperties}
              >
                ADD MORE PROPERTIES
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }
);
