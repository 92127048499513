import React from 'react';
import { Grid, Box, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import { Paper, CurrencyField } from '@roc/ui';
import { CostsTable } from './costsTable';
import { DealEconomicsStore } from '../../../../stores/documentForms/dealEconomicsStore';

const useStyles = makeStyles({
  snapshotCard: {
    width: '100%',
  },
});

type SnapshotProps = {
  store: DealEconomicsStore;
};

export const Snapshot = observer((props: SnapshotProps) => {
  const { store } = props;
  const classes = useStyles();

  return (
    <Paper title="SNAPSHOT" className={classes.snapshotCard}>
      <Box p={2}>
        <Grid container spacing={2}>
          <Grid item container spacing={2}>
            <Grid item xs={12} md={6}>
              <CurrencyField
                label="Sales Price"
                standaloneLabel
                value={store.dealEconomics?.salesPrice}
                variant="outlined"
                testId="salesPrice"
                fullWidth
                disabled
              />
            </Grid>
          </Grid>
          <Grid item container spacing={2}>
            <Grid item xs={12} md={6}>
              <CostsTable title="Total Cost" data={store.totalCosts} />
            </Grid>
            <Grid item xs={12} md={6}>
              <CostsTable title="Flip Economics" data={store.flipEconomics} />
            </Grid>
            <Hidden smDown>
              <Grid item md={6} />
            </Hidden>
            <Grid item xs={12} md={6}>
              {store.dealEconomics?.useNewDealEconomicsCalculations ?
                <CostsTable title="Rent Economics" data={store.newRentEconomics} />
                :
                <CostsTable title="Rent Economics" data={store.rentEconomics} />
              }
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
});

export default Snapshot;
