import { Grid, Typography, Box } from "@material-ui/core"

export const LoanInfo = (props) => {
  return (
    <Box style={{ display: 'flex', justifyContent: 'flex-start', width: '45%', flexDirection: 'column' }}>
      <Grid item md={12} style={{ display: 'flex', justifyContent: 'flex-start' }}>
        <Typography variant="h6" style={{ fontWeight: 'bold', paddingTop: '5px', paddingRight: '5px' }}>Loan:</Typography>
        <Typography variant="h6" style={{ paddingTop: '5px' }}>{props?.achData?.loanId}</Typography>
      </Grid>
      <Grid item md={12} style={{ display: 'flex', justifyContent: 'flex-start' }}>
        <Typography variant="h6" style={{ fontWeight: 'bold', paddingTop: '5px', paddingRight: '5px' }}>Property:</Typography>
        <Typography variant="h6" style={{ paddingTop: '5px' }}>{props?.achData?.loanPropertyAddress}</Typography>
      </Grid>
      {props?.achData?.verificationStep == 'signed' &&
        <Grid item md={12} style={{ display: 'flex', justifyContent: 'flex-start' }}>
          <Typography variant="h6" style={{ fontWeight: 'bold', paddingTop: '5px', paddingRight: '5px' }}>Auto Debit Date:</Typography>
          <Typography variant="h6" style={{ paddingTop: '5px' }}>{`${props?.achData?.withdrawalDay}${props?.achData?.withdrawalDay == 1 ? 'st' : props?.achData?.withdrawalDay == 2 ? 'nd' : props?.achData?.withdrawalDay == 3 ? 'rd' : 'th'} of the month`}</Typography>
        </Grid>}
    </Box>)
}