import { GlobalStore, GridStore } from '@roc/client-portal-shared/stores';
import { UserStore } from '@roc/feature-app-core';
import { LoanParticipantsStore } from '@roc/feature-loan-participants';
import { makeObservable, flow, observable, computed, action } from 'mobx';
import { FixFlipSizerService } from '../services/fixFlipSizerService';
import FixFlipSizerDetailsStore from './fixFlipSizerDetailsStore';

class FixFlipSizerStore {
  globalStore: GlobalStore;
  userStore: UserStore;
  sizerService: FixFlipSizerService;

  detailsStore: FixFlipSizerDetailsStore;
  gridStore: GridStore;

  constructor(globalStore, userStore) {
    this.globalStore = globalStore;
    this.userStore = userStore;
    this.sizerService = new FixFlipSizerService();
    this.detailsStore = new FixFlipSizerDetailsStore(globalStore, userStore);
    this.gridStore = new GridStore(this.fetchSizers);
    makeObservable(this, {
      deleteSizer: flow,
    });
  }

  fetchSizers = async () => {
    try {
      const response = await this.sizerService.getMySizersGrid(
        this.gridStore.gridData.meta.pageNumber,
        this.gridStore.gridData.meta.pageSize,
        this.gridStore.gridData.meta.sortDir,
        this.gridStore.gridData.meta.sortBy,
        this.gridStore.gridData.meta.filters
      );
      return response;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: `Error while retrieving the list of existing fix & flip sizers`,
      });
    }
  };

  *deleteSizer(sizerId: number) {
    try {
      yield this.sizerService.removeSizer(sizerId);
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Sizer request removed successfully',
      });
      this.gridStore.resetAndFetchGridData();
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while removing sizer request',
      });
    }
  }
}

export default FixFlipSizerStore;
