import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';

import { useParams } from 'react-router';
import { ScopeOfWorkV2Form, decodeParams } from '@roc/feature-sow-v2';
import { ScopeOfWorkV2FormParams } from '@roc/feature-sow-shared/types';
import { useStore } from 'apps/client-portal-public/src/app/hooks';
import { isNil } from '@roc/feature-utils';
import { PublicScopeOfWorkSuccess } from './publicScopeOfWorkSuccess';

export type EncodedParams = {
  encodedParams: string;
};

const defaultV2Params: ScopeOfWorkV2FormParams = {
  lenderId: '',
  scopeOfWorkId: '',
  username: '',
  isInternal: false,
};


export const PublicScopeOfWorkForm = observer(() => {
  const { publicscopeOfWorkV2Store } = useStore();

  const { encodedParams } = useParams<EncodedParams>();
  const [queryParams] = useState<ScopeOfWorkV2FormParams | null>(!isNil(encodedParams) ? decodeParams(encodedParams) : defaultV2Params);

  useEffect(() => {
    publicscopeOfWorkV2Store.resetParamsWithConditions();
    if (queryParams.lenderId) {
      publicscopeOfWorkV2Store.setPublicParams(queryParams);
    }
    else {
      publicscopeOfWorkV2Store.setPublicParamsWithoutLenderId(queryParams);
    }
  }, [publicscopeOfWorkV2Store, queryParams]);

  useEffect(() => {
    scrollTo(0, 0);
  }, [publicscopeOfWorkV2Store.activeStep]);

  return (publicscopeOfWorkV2Store.submittedScopeOfWorkId
    ? <PublicScopeOfWorkSuccess store={publicscopeOfWorkV2Store} />
    : <ScopeOfWorkV2Form store={publicscopeOfWorkV2Store} />);
});

export default PublicScopeOfWorkForm;
