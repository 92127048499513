import { Grid } from '@material-ui/core';
import { Document } from '@roc/feature-types';
import { Button, StandardDialog } from '@roc/ui';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { useDocumentStore } from '../../../hooks/useDocumentStore';
import LenderFees from './lenderFees';

export type LenderFeesDialogProps = {
  open?: boolean;
  onClose?: () => void;
  loanId: any;
  document: Document;
};

export const LenderFeesDialog = observer((props: LenderFeesDialogProps) => {
  const { open, onClose, loanId, document } = props;
  const { documentStore } = useDocumentStore();
  const { documentFormStore } = documentStore;
  const { lenderFeesStore } = documentFormStore;

  const onSubmit = () => {
    lenderFeesStore.submit(document);
    onClose();
  };

  useEffect(() => {
    if (open) {
      lenderFeesStore.initForm();
    }
  }, [open]);

  return (
    <StandardDialog
      open={open}
      title="Lender Fees"
      maxWidth="lg"
      handleClose={onClose}
      dialogContent={<LenderFees store={lenderFeesStore} />}
      dialogActions={
        <Grid container spacing={1} justifyContent="flex-end">
          <Grid item>
            <Button
              onClick={onClose}
              color="primary"
              variant="outlined"
              testId="cancel"
            >
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={onSubmit}
              disabled={!lenderFeesStore.canSubmit}
              color="primary"
              variant="contained"
              testId="confirm"
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      }
    />
  );
});

export default LenderFeesDialog;
