import { GlobalStore, GridStore } from "@roc/feature-app-core";
import { action, computed, flow, makeObservable, observable } from "mobx";
import { DrawDqDataInterface } from '@roc/feature-types';
import EditDqDataStore from "./editDqDataStore";
import { getUniqueId } from "@roc/feature-utils";

interface SummaryInformation {
  totalFunds: number;
  fundsApplied: number;
  fundsAvailable: number;
};

export abstract class DrawDqDataBaseStore {
  globalStore: GlobalStore;
  editDqDataStore: EditDqDataStore;
  drawDqDataGridStore: GridStore;

  addLoanFormDialogOpen: boolean;
  gridData: any;
  drawData: any;
  summaryInformation: SummaryInformation;
  drawId: string;
  selectedItems: DrawDqDataInterface[];

  abstract fetchAllData(drawId: string);
  abstract fetchDqData(id);
  abstract fetchDrawById(id);
  abstract saveData(drawId);

  constructor(globalStore: GlobalStore) {
    this.globalStore = globalStore;
    this.editDqDataStore = new EditDqDataStore(globalStore);
    this.addLoanFormDialogOpen = false;
    makeObservable(this, {
      setAddLoanFormDialogOpen: action,
      addLoanFormDialogOpen: observable,
      drawData: observable,
      gridData: observable,
      summaryInformation: observable,
      selectedItems: observable,
      calculateFunds: action,
      saveData: flow,
      drawId: observable,
      selectedRow: action,
      setLoanDqDataEdit: action,
      refreshRows: action,
      isSaveEnabled: computed,
    });
    this.initialize();
  }

  initialize() {
    this.summaryInformation = {
      totalFunds: 0,
      fundsApplied: 0,
      fundsAvailable: 0
    };
    this.drawId = "";
    this.selectedItems = [];
  }

  processData = () => {
    this.drawDqDataGridStore.gridData.data.rows = this.drawDqDataGridStore.gridData.data.rows.map(row =>
      this.processItem(row)
    );
  }

  processItem(row) {
    const updatedRow = {
      ...row,
      tempId: getUniqueId()
    };
    if (updatedRow.applied) {
      this.selectedItems.push(updatedRow);
    }

    return updatedRow;
  }

  setAddLoanFormDialogOpen(addLoanFormDialogOpen: boolean) {
    this.addLoanFormDialogOpen = addLoanFormDialogOpen;
  }

  calculateFunds() {
    const totalFunds = this.drawData?.draw?.amount ?? 0;
    const fundsApplied = this.gridData.data?.data?.rows?.filter(x => x.applied)
      .map(x => x.appliedDqAmount ?? 0)
      .reduce((sum, value) => sum + value, 0);

    this.summaryInformation = {
      totalFunds: totalFunds,
      fundsApplied: fundsApplied,
      fundsAvailable: totalFunds - fundsApplied
    };
  }

  setLoanDqDataEdit() {
    const formValues = this.editDqDataStore.getFormValues();
    const updatedItem = { ...formValues };
    this.updateSelectedItems(updatedItem);
    this.updateRows(updatedItem);
    this.calculateFunds();
  }

  updateRows(updatedItem) {
    const rows = this.drawDqDataGridStore.gridData.data.rows as Array<any>;
    const updatedRows = rows.map(row => {
      if (row.tempId === updatedItem.tempId) {
        return {
          ...row,
          ...updatedItem
        }
      }
      return row;
    });
    this.drawDqDataGridStore.setData(updatedRows);
  }

  refreshRows() {
    const rows = this.drawDqDataGridStore.gridData.data.rows as Array<any>;
    this.drawDqDataGridStore.setData(rows);
  }

  selectedRow(row: DrawDqDataInterface) {
    const index = this.selectedItems.findIndex(item => item.tempId === row.tempId);
    if (index > -1) {
      this.updateRows({ ...row, applied: false, isSelected: false });
      this.selectedItems.splice(index, 1);
    } else {
      const updatedRow = { ...row, applied: true };
      this.updateRows(updatedRow);
      this.selectedItems.push(updatedRow);
    }

    this.calculateFunds();
  }

  updateSelectedItems(updatedItem) {
    const index = this.selectedItems.findIndex(item => item.tempId === updatedItem.tempId);
    if (index > -1) {
      this.selectedItems[index].dqAmount = updatedItem.dqAmount;
      this.selectedItems[index].appliedDqAmount = updatedItem.appliedDqAmount;
      this.selectedItems[index].comments = updatedItem.comments;
    }
  }

  get isSaveEnabled() {
    return this.drawDqDataGridStore.gridData?.data?.rows?.length > 0;
  }
}
