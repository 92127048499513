import { Layout, useBaseStore, useUserStore } from '@roc/feature-app-core';
import {
  useLoanStore,
  LoanTabs
} from '@roc/feature-loans';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { MyLoansBackOfficeGrid } from '../backOfficeGrid/myLoansBackOfficeGrid';
import { useBackOfficeLoansStore } from '../../hooks/useBackOfficeLoansStore';
import { insertIf } from '@roc/feature-utils';
import { MyLoansInBackOfficeViewGrid } from '../backOfficeGrid/myLoansInBackOfficeViewGrid';
import { MyTasksBackOfficeGrid } from '../backOfficeGrid/myTasksBackOfficeGrid';
import { MyTasksTabActions } from '../backOfficeGrid/myTasksTabActions';

const renderTabContent = (globalStore, loanStore) => {
  const { backOfficeLoansStore } = useBackOfficeLoansStore();

  switch (loanStore.selectedTabIndex) {
    case 0:
      return globalStore.isListView ? <MyTasksBackOfficeGrid store={backOfficeLoansStore} /> : null;
    case 1:
      return globalStore.isListView ? <MyLoansBackOfficeGrid store={backOfficeLoansStore.pipelineBackOfficeLoansGridStore} /> : null;
    case 2:
      return globalStore.isListView ? <MyLoansBackOfficeGrid store={backOfficeLoansStore.primaryBackOfficeLoansGridStore} /> : null;
    case 3:
      return globalStore.isListView ? <MyLoansBackOfficeGrid store={backOfficeLoansStore.secondaryBackOfficeLoansGridStore} /> : null;
    case 4:
      return globalStore.isListView ? <MyLoansInBackOfficeViewGrid store={backOfficeLoansStore.myLoansGridStore} /> : null;
    default:
      console.error('Invalid tab index');
      break;
  }
};

export const BackOfficeLoansHome = observer(() => {
  const { globalStore } = useBaseStore();
  const { loanStore } = useLoanStore();
  const { userStore } = useUserStore();
  const { selectedTabIndex } = loanStore;
  const { backOfficeLoansStore } = useBackOfficeLoansStore();

  useEffect(() => {
    const isComingBackFromLoanDetails = globalStore.lastHistoryAction === 'POP';
    if (!isComingBackFromLoanDetails) {
      loanStore.setSelectedTabIndex(0);
    }
  }, []);

  const TABS = [
    {
      label: "MY TASKS",
    },
    {
      label: 'PIPELINE',
    },
    ...insertIf(userStore.isBackofficeOriginator, [
      {
        label: 'PRIMARY',
      },
      {
        label: 'SECONDARY'
      },
      {
        label: 'MY LOANS'
      },
    ]),
  ];
  const showSwitchView = () => {
    switch (selectedTabIndex) {
      case 1:
        return true;
      default:
        return false;
    }
  };

  const getRight = () => {
    return selectedTabIndex === 0 ? (<MyTasksTabActions store={backOfficeLoansStore} />) : null;
  }

  return (
    <Layout>
      <LoanTabs tabs={TABS} right={getRight()} showSwitchView={showSwitchView} />
      {renderTabContent(globalStore, loanStore)}
    </Layout>
  );
});