import { Theme, createStyles, lighten, makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { Box, Tooltip, Checkbox, Chip } from '@material-ui/core';
import { CopyText } from '@roc/ui';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import { FileCopy } from '@material-ui/icons';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableRow: {
      borderBottom: '1px solid #eee',
      '&:last-child': {
        border: 'none'
      },
      '& th': {
        padding: '8px 4px 8px 4px',
      },
      '& td': {
        padding: '8px 4px 8px 4px',
        '&:last-child': {
          padding: '8px 4px 8px 4px',
        }
      },
      '& .tableRowLabelHolder': {
        color: '#999'
      },
      '& .tableRowLabelHeader': {
        fontWeight: 'bold'
      },
      '&:hover': {
        background: lighten(theme.palette.primary.main, .9),
        '& *': {
          fontWeight: 'bold'
        }
      }
    },
    tableCell: {
      border: 'none'
    },
    copyTooltip: {
      margin: 0,
      padding: '0 0 0 8px',
      background: 'none'
    }
  }),
);
export const DenseTable = ({ data }) => {
  const classes = useStyles();
  return (
    <TableContainer>
      <Table size="small">
        <TableBody>
          {data && data.map((row) => {
            return (
              <TableRow key={row.name} className={classes.tableRow}>
                {row.header &&
                  <TableCell component="th" scope="row" className={classes.tableCell}>
                    <Box className='tableRowLabelHeader'>{row.header}</Box>
                  </TableCell>
                }
                {row.name &&
                  <TableCell scope="row" className={classes.tableCell}>
                    <Box className='tableRowLabelHolder'>{row.name}</Box>
                  </TableCell>
                }
                {row.value !== undefined && row.value !== null &&
                  <TableCell align="right" className={classes.tableCell}>
                    <Tooltip
                      classes={{
                        tooltip: classes.copyTooltip
                      }}
                      title={
                        <React.Fragment>
                          <CopyText tooltipText={`Copy ${row.name}`}>
                            <IconButton color='primary' size='small' onClick={() => {
                              navigator.clipboard.writeText(row.value);
                            }} style={{
                              border: '1px solid',
                              borderRadius: 20,
                              background: '#fff'
                            }}>
                              <FileCopy style={{
                                fontSize: 12
                              }} />
                            </IconButton>
                          </CopyText>
                        </React.Fragment>
                      }
                      placement='right'
                      interactive>
                      <Box className='tableRowValueHolder'>
                        {
                          typeof row.value === 'boolean' ? (
                            <Chip
                              size="small"
                              label={row.value ? 'Yes' : 'No'}
                              style={{
                                backgroundColor: row.value ? '#e9edf4' : '#fcdede'
                              }}
                            />
                          ) : (
                            row.renderer && row.onClick ?
                              row.renderer(row.value, row.onClick, row.data)
                              : row.value
                          )
                        }
                      </Box>
                    </Tooltip>
                  </TableCell>
                }
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};