import { InputAdornment, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { Search } from '@material-ui/icons';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import { FieldLabel } from '../fieldLabel/fieldLabel';

const useStyles = makeStyles(theme => ({
  searchIcon: {
    color: theme.palette.text.secondary,
  },
}));

interface Option {
  label: string;
  value: string;
}

interface AutocompleteFieldProps {
  value: string;
  placeHolder: string;
  label: string;
  required?: boolean;
  tooltip?: string;
  error?: boolean;
  helperText?: string;
  onChange: (text: string) => void;
  onSelect: (option: Option) => void;
  options: Option[];
  disabled?: boolean;
  className?: string;
  onBlur?: () => void;
}

const filter = createFilterOptions({
  matchFrom: 'any',
});

export const AutocompleteField = (props: AutocompleteFieldProps) => {
  const classes = useStyles();
  const { value, onChange, onSelect, options, onBlur } = props;

  const filterOptions = (options, params) => {
    const filtered = filter(options, params);
    return filtered;
  };

  const renderInput = params => (
    <>
      <FieldLabel
        tooltip={props.tooltip}>
        {props.label}
        {props.required && ' *'}
      </FieldLabel>
      <TextField
        {...params}
        InputProps={{
          ...params.InputProps,
          endAdornment: undefined,
          startAdornment: (
            <InputAdornment position="start" className={classes.searchIcon}>
              <Search />
            </InputAdornment>
          ),
        }}
        testId="autocomplete"
        onChange={e => onChange(e.target.value)}
        variant="outlined"
        placeholder={props.placeHolder}
        error={props.error}
        helperText={props.helperText}
        fullWidth
        onBlur={onBlur}
      />
    </>
  );

  return (
    <Autocomplete
      id="loan-autocomplete"
      selectOnFocus
      handleHomeEndKeys
      options={options}
      value={null}
      inputValue={value}
      blurOnSelect={true}
      onChange={(event, option: Option) => onSelect(option)}
      renderInput={renderInput}
      getOptionLabel={(option: Option) => option.label}
      getOptionSelected={(option: Option) => true}
      filterOptions={filterOptions}
      fullWidth
      className={props.className}
      disabled={props.disabled}
    />
  );
};