import { alpha, makeStyles, Theme } from '@material-ui/core/styles';
import { Layout } from '@roc/feature-app-core';
import { Tabs } from '@roc/ui';
import { useState } from 'react';
import { MonthlyClosingDataTab } from './components/monthlyClosingDataTab';
import { TodayClosingDataTab } from './components/todayClosingDataTab';
import { TomorrowClosingDataTab } from './components/tomorrowClosingDataTab';

const useStyles = makeStyles((theme: Theme) => ({
  tabContainer: {
    '& .MuiTabs-indicator': {
      display: 'none',
    },
  },
  tab: {
    maxWidth: 'unset',
  },
  tabSelected: {
    background: alpha(theme.palette.primary.main, 0.1),
    border: `2px solid ${theme.palette.primary.main}`,
  },
}));

const TABS = [
  {
    label: "Today's Closing Data",
  },
  {
    label: "Next Business Day's Expected Closing Data",
  },
  {
    label: 'Monthly Closing Data',
  },
];

export const ExpectedClosings = () => {
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = useState(0);

  const onTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue);
  };

  return (
    <Layout maxWidth={'lg'}>
      <Tabs
        tabs={TABS}
        selectedTab={selectedTab}
        onTabChange={onTabChange}
        className={classes.tabContainer}
        tabClassName={classes.tab}
        tabSelectedClassName={classes.tabSelected}
      />
      {selectedTab === 0 && <TodayClosingDataTab />}
      {selectedTab === 1 && <TomorrowClosingDataTab />}
      {selectedTab === 2 && <MonthlyClosingDataTab />}
    </Layout>
  );
};
