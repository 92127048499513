import { useTheme } from '@material-ui/core/styles';
import { isElectronApp } from '@roc/feature-utils';
import { CircularLoader, VerticalBarLoader } from '@roc/ui';
import { observer } from 'mobx-react';
import { useEffect, useRef } from 'react';
import LoadingBar from 'react-top-loading-bar';
import { useBaseStore } from '../../hooks/useBaseStore';

const _LoadingBar = observer(() => {
  const ref = useRef(null);
  const { palette } = useTheme();
  const { globalStore } = useBaseStore();
  const { loadingRequestsCount } = globalStore;

  useEffect(() => {
    if (loadingRequestsCount > 0) {
      ref.current.continuousStart(40);
    } else {
      ref.current.complete();
    }
  }, [loadingRequestsCount]);

  return (
    <>
      <LoadingBar
        color={palette.primary.main}
        waitingTime={500}
        ref={ref}
        shadow={true}
      />
    </>
  );
});

export const Loader = observer(() => {
  const { globalStore } = useBaseStore();
  const { isBorrowerPortal, isLoanChatApp } = globalStore;
  const { loadingRequestsCount } = globalStore;

  return (
    <>
      {
        isElectronApp() ? null : <_LoadingBar />
      }
      {loadingRequestsCount > 0 ? (isBorrowerPortal || isLoanChatApp ? <CircularLoader /> : <VerticalBarLoader />) : null}
    </>
  );
});
