import { Box, Drawer } from '@material-ui/core';
import { useRoutes } from '@roc/client-portal-shared/hooks';
import { Layout } from '@roc/feature-app-core';
import {
  ConversationDrawer,
  useCommunicationRoutesConfig,
  useAppraisalCommunicationStore,
} from '@roc/feature-communication';
import {
  AppraisalOrderDetailsDialog,
  useDocumentCommunicationStore,
} from '@roc/feature-documents';
import { ThreadMeta } from '@roc/feature-types';
import { FilterButton, Tabs } from '@roc/ui';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppraisalsStore } from '../hooks';
import { LoanAppraisalsGrid } from './components/loanAppraisalsGrid';
import { StandaloneAppraisalsGrid } from './components/standaloneAppraisalsGrid';
import { MyChatContainer } from '@roc/feature-communication';
import { useLoanCommunicationStore } from '@roc/feature-communication';
import { SelectConversation } from '@roc/feature-communication';
import { makeStyles, Theme } from '@material-ui/core/styles';

const TABS = [
  {
    label: 'SUBMITTED FOR LOANS',
  },
  {
    label: 'STANDALONE APPRAISALS',
  },
];

const APPRAISAL_ORDER_SECTION_ID = '20';
const SECOND_APPRAISAL_ORDER_SECTION_ID = '22';
const useStyles = makeStyles((theme: Theme) => ({
  drawer: {
    '& .MuiDrawer-paper': {
      [theme.breakpoints.up('md')]: {
        width: 1000,
      },
      [theme.breakpoints.up('lg')]: {
        width: 1200,
      },
    },
  },
  drawerContent: {
    [theme.breakpoints.up('md')]: {
      width: 1000,
    },
    [theme.breakpoints.up('lg')]: {
      width: 1200,
    },
  },
  chatContainer: {
    flex: 1,
  },
}));

export const BrowseAppraisals = observer(() => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [appraisalModalOpen, setAppraisalModalOpen] = useState(false);
  const [
    selectedPropertyAppraisalId,
    setSelectedPropertyAppraisalId,
  ] = useState(0);
  const { appraisalsStore } = useAppraisalsStore();
  const { documentCommunicationStore } = useDocumentCommunicationStore();
  const { appraisalsRoutesConfig } = useRoutes();
  const { communicationRoutesConfig } = useCommunicationRoutesConfig();
  const { appraisalCommunicationStore } = useAppraisalCommunicationStore();
  const { push } = useHistory();
  const { loanCommunicationStore } = useLoanCommunicationStore();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [loanId, setLoanId] = useState(0);
  const classes = useStyles();

  const onTabChange = (e, index) => setSelectedTab(index);

  const openAppraisalCommunication = async appraisal => {
    if (appraisal.twilioCommunicationEnabled && appraisal.loanId != null) {
      setLoanId(appraisal.loanId)
      setOpenDrawer(true);
    } else {
      openLegacyAppraisalCommunication(appraisal);
    }
  };

  const openLegacyAppraisalCommunication = appraisal => {
    const { appraisalData } = appraisal;
    const { orderId, loanId, propertyId, secondAppraisal } = appraisalData;
    const title = `Appraisal Communication - ${orderId}`;

    const sectionId = secondAppraisal
      ? SECOND_APPRAISAL_ORDER_SECTION_ID
      : APPRAISAL_ORDER_SECTION_ID;

    const meta: ThreadMeta = {
      loanId,
      collateralId: propertyId,
      sectionId,
    };

    documentCommunicationStore.getDocumentComments(title, meta);
  };

  const handleToggleFilter = () => {
    if (selectedTab === 0) {
      appraisalsStore.loanAppraisalsGridStore.toggleFilters();
    } else {
      appraisalsStore.standaloneAppraisalsGridStore.toggleFilters();
    }
  };

  const handleClearFilter = () => {
    if (selectedTab === 0) {
      appraisalsStore.loanAppraisalsGridStore.resetFilters();
    } else {
      appraisalsStore.standaloneAppraisalsGridStore.resetFilters();
    }
  };

  const handleSelectAppraisal = data => {
    setAppraisalModalOpen(true);
    setSelectedPropertyAppraisalId(data?.appraisalData?.propertyAppraisalId);
  };

  const closeAppraisalModal = () => {
    setAppraisalModalOpen(false);
  };

  const openTwilioAppraisalCommunication = appraisal => {
    push(
      communicationRoutesConfig.appraisalsConversations.children.conversation(
        appraisal.appraisalData.propertyAppraisalId
      ).url
    );
  };

  const closeDrawer = () => {
    setOpenDrawer(false)
  }

  const closeTwilioAppraisalCommunication = () => {
    push(appraisalsRoutesConfig.appraisals.children.browseAppraisals.url);
  };

  useEffect(() => {
    if (!documentCommunicationStore.isDrawerOpen) {
      if (selectedTab === 0) {
        appraisalsStore.loanAppraisalsGridStore.resetAndFetchGridData();
      } else {
        appraisalsStore.standaloneAppraisalsGridStore.resetAndFetchGridData();
      }
    }
  }, [documentCommunicationStore.isDrawerOpen, appraisalsStore, selectedTab]);

  return (
    <Layout title="Appraisals">
      <Tabs
        tabs={TABS}
        selectedTab={selectedTab}
        onTabChange={onTabChange}
        right={
          <Box py={1} pr={2}>
            <FilterButton
              onToggleFilters={handleToggleFilter}
              onClearFilters={handleClearFilter}
            />
          </Box>
        }
      />
      {selectedTab === 0 && (
        <LoanAppraisalsGrid
          onOpenCommunication={openAppraisalCommunication}
          onSelectAppraisal={handleSelectAppraisal}
        />
      )}
      {selectedTab === 1 && (
        <StandaloneAppraisalsGrid
          onOpenCommunication={openAppraisalCommunication}
          onSelectAppraisal={handleSelectAppraisal}
        />
      )}
      <AppraisalOrderDetailsDialog
        open={appraisalModalOpen}
        onClose={closeAppraisalModal}
        propertyAppraisalId={selectedPropertyAppraisalId}
      />
      <ConversationDrawer
        communicationStore={appraisalCommunicationStore}
        onClose={closeTwilioAppraisalCommunication}
      />
      <Drawer
        className={classes.drawer}
        anchor="right"
        open={openDrawer}
        onClose={closeDrawer}
      >
        <Box display="flex" height="100%" >
          <MyChatContainer
            id={Number(loanId)}
            communicationStore={loanCommunicationStore}
            allowNewConversations={false}
            showSnippet={false}
            onConversationSelect={() => { }}
            className={classes.chatContainer}
          />
          <SelectConversation communicationStore={loanCommunicationStore} />
        </Box>
      </Drawer>
    </Layout>
  );
});
