import { Box, Typography } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { formatCurrency } from '@roc/ui/utils';
import clsx from 'clsx';
import { DEFAULT_FEE_CORELOGIX, DEFAULT_FEE_LEGAL_REVIEW, DEFAULT_FEE_RETAIL_SERVICING_SETUP } from '../utils/constants';

const useStyles = makeStyles({
  table: {
    '& .MuiInputBase-root *': {
      fontSize: 14
    },
    '& .MuiSelect-root *': {
      fontSize: 14
    },
  },
});

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
      minHeight: 42,
    },
    title: {
      flex: '1 1 100%',
    },
  }),
);

const EnhancedTableToolbar = () => {
  const classes = useToolbarStyles();
  return (
    <Toolbar
      className={clsx(classes.root)}
    >
      <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
        Closing Fees
      </Typography>
    </Toolbar>
  );
};

export const ClosingFeesTable = ({ pricerSummaryStore }) => {
  const classes = useStyles();

  return (
    <Box bgcolor={'#fff'} border={'1px solid #eee'}>
      <EnhancedTableToolbar />
      <TableContainer>
        <Table className={classes.table} aria-label="simple table" size="small">
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row" colSpan={2} style={{
                minWidth: 350,
                paddingTop: 8,
                paddingBottom: 8,
              }}>
                <div style={{
                  fontWeight: 'bold',
                  textAlign: 'left'
                }}>Legal Review Fee:</div>
              </TableCell>
              <TableCell style={{
                minWidth: 200
              }}>
                <div style={{
                  fontWeight: 'bold',
                  textAlign: 'right',
                }}>{
                    formatCurrency(pricerSummaryStore.closingLegalReviewFee)
                  }</div>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row" colSpan={2} style={{
                paddingTop: 8,
                paddingBottom: 8,
              }}>
                <div style={{
                  fontWeight: 'bold',
                  textAlign: 'left'
                }}>Servicing Set Up Fee:</div>
              </TableCell>
              <TableCell>
                <div style={{
                  fontWeight: 'bold',
                  textAlign: 'right',
                }}>{
                    formatCurrency(DEFAULT_FEE_RETAIL_SERVICING_SETUP)
                  }</div>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row" colSpan={2} style={{
                paddingTop: 8,
                paddingBottom: 8,
              }}>
                <div style={{
                  fontWeight: 'bold',
                  textAlign: 'left'
                }}>Corelogix  Fee:</div>
              </TableCell>
              <TableCell>
                <div style={{
                  fontWeight: 'bold',
                  textAlign: 'right',
                }}>{
                    formatCurrency(DEFAULT_FEE_CORELOGIX)
                  }</div>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>

  );
}