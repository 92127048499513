import { Service } from '@roc/feature-app-core';

export abstract class RentalPricerBaseService extends Service {
  abstract getRentalPricerGrid(
    page: number,
    pageSize: number,
    sortDir: string,
    sortBy: string,
    filters: string
  ): Promise<any>;
  abstract getRentalPricerById(rentalPricerId);
  abstract saveRentalPricer(rentalPricerData);
  abstract deletePricer(rentalPricerId);
}
