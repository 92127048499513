import {
  FormControl,
  MenuItem,
  MenuProps,
  Select,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { sanitizeTestId } from '@roc/feature-utils';
import {
  FieldLabel,
  SelectOption,
  StatusChip,
  StatusType,
} from '@roc/ui';

const useStyles = makeStyles((theme: Theme) => ({
  select: {
    '& .description-text': {
      display: 'none',
    },
    '& .MuiOutlinedInput-input': {
      paddingTop: '16px',
      paddingBottom: '16px',
    },
  },
  placeholder: {
    display: 'none',
  },
}));

const menuProps: Partial<MenuProps> = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
  getContentAnchorEl: null,
};

const renderMenuItem = (option, className?: string) => {
  const { description, label, value } = option;

  return (
    <MenuItem key={value} value={value} className={className}>
      <StatusChip
        statusType={StatusType.PRIORITY}
        label={value}
        size="small"
        style={{
          fontWeight: 'bold'
        }}
      />
    </MenuItem>
  );
};

export const TaskPriorityField = ({
  label,
  value,
  options,
  onChange,
  disabled,
}) => {
  const classes = useStyles();

  return (
    <>
      <FieldLabel>{label}</FieldLabel>
      <FormControl fullWidth variant="outlined">
        <Select
          className={classes.select}
          id={sanitizeTestId(label)}
          name={sanitizeTestId(label)}
          value={value}
          onChange={event => onChange(event, event.target.value)}
          MenuProps={menuProps}
          disabled={disabled}
        >
          {options.map((option: SelectOption) => renderMenuItem(option))}
        </Select>
      </FormControl>
    </>
  );
};
