import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { StandardDialog } from '@roc/ui';
import { usePayoffRequestStore } from '../hooks/usePayoffRequestStore';
import LoanPayoffDetailsForm from './loanPayoffDetailsForm';

interface PayoffDetatilsDialogProps {
  open: boolean;
  onClose: () => void;
  loanId: string;
}

export const LoanPayoffDetailstDialog = observer(
  ({ open, onClose, loanId }: PayoffDetatilsDialogProps) => {
    const { payoffRequestStore } = usePayoffRequestStore();
    const isForeclosureRequest = payoffRequestStore.isForeclosure;

    const handleConfirm = () => {
      payoffRequestStore.getActivePayoffDataByLoanId(loanId);
      onClose();
    };

    const onSubmitPayoffDetails = () => {
      payoffRequestStore.onLoanPayoffDetailsSubmit(isForeclosureRequest);
      onClose();
    }

    return (
      <StandardDialog
        open={open}
        title="Loan Payoff Details"
        maxWidth="sm"
        handleClose={onClose}
        dialogContent={
          <LoanPayoffDetailsForm onSubmit={onSubmitPayoffDetails} onClose={() => onClose()} />
        }
      />
    );
  }
);
