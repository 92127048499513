import { observable, flow, makeObservable, action, computed, override } from 'mobx';
import { CreditInformationStore } from './creditInformationStore';
import { BorrowerSetupOptionsFormStore } from './borrowerSetupOptionsFormStore';
import { CreditBackgroundType, downloadDocument, getDocumentAsBlob, isBlank, isEmptyObject, isNil, isNotBlank, prequalificationStatusProperties } from '@roc/feature-utils';
import { BorrowerSetupToDoBaseStore } from './borrowerSetupToDoBaseStore';
import { BorrowerSetupToDoService } from '../services/borrowerSetupToDoService';
import { TodoStatus } from '@roc/feature-loan-details';
import { FormStatus } from '../../borrowers/types';
import { CreditBackgroundCheckStatus } from '@roc/feature-credit-background-check';

import { ForeignNationalOptions } from 'libs/client-portal-shared/src/app/modules/internalPricer/utils/internalPricerConstants';

export class BorrowerSetupToDoStore extends BorrowerSetupToDoBaseStore {

  borrowerSetupOptionsFormStore: BorrowerSetupOptionsFormStore;
  creditInformationStore: CreditInformationStore;
  borrowerSetupToDoService: BorrowerSetupToDoService;

  isEditOptionEnabled: boolean;
  currentLoan: any;
  borrowerDetailsVerificationUrl: string;
  existingDriversLicense: File;
  isPersonalInformationExpanded: boolean;
  isIDInformationExpanded: boolean;
  isFinancialInformationExpanded: boolean;
  isCreditInformationExpanded: boolean;
  setupPending: boolean;
  basicInfoIsPresent: boolean;
  personalInformationStatus: string;
  idInformationStatus: string;
  financialInformationStatus: string;
  creditInformationStatus: string;
  repullInProgress: boolean;
  showStepsLayout: boolean;
  borrowerIdentificationDocumentData: any;
  borrowerIdvResultsResponse: any;
  photoId: any;
  frontId: any;
  backId: any;
  selfie: any;

  constructor(globalStore) {
    super(globalStore);
    this.borrowerSetupOptionsFormStore = new BorrowerSetupOptionsFormStore(globalStore);
    this.creditInformationStore = new CreditInformationStore(globalStore);
    this.borrowerSetupToDoService = new BorrowerSetupToDoService();

    makeObservable(this, {
      isEditOptionEnabled: observable,
      currentLoan: observable,
      borrowerDetailsVerificationUrl: observable,
      existingDriversLicense: observable,
      isPersonalInformationExpanded: observable,
      isIDInformationExpanded: observable,
      isFinancialInformationExpanded: observable,
      isCreditInformationExpanded: observable,
      setupPending: observable,
      showStepsLayout: observable,
      basicInfoIsPresent: observable,
      personalInformationStatus: observable,
      idInformationStatus: observable,
      financialInformationStatus: observable,
      creditInformationStatus: observable,
      borrowerIdentificationDocumentData: observable,
      borrowerIdvResultsResponse: observable,
      photoId: observable,
      frontId: observable,
      backId: observable,
      selfie: observable,
      reset: override,
      setIsEditOptionEnabled: action,
      handleEditOption: action,
      handleCancelEditOption: action,
      setIsPersonalInformationExpanded: action,
      setIsIDInformationExpanded: action,
      setIsFinancialInformationExpanded: action, setIsCreditInformationExpanded: action,
      loadStores: action,
      getBasicInfoIsPresent: action,
      getPersonalInformationStatus: action,
      getIdInformationStatus: action,
      getFinancialInformationStatus: action,
      getCreditInformationStatus: action,
      personalGuarantor: computed,
      sendIdVerification: computed,
      isBorrowerAccepted: computed,
      sendIdVerificationPending: computed,
      isBorrowerUser: computed,
      loadBorrowerPrescreenInfo: flow,
      getBorrowerPrescreenInfo: flow,
      getBorrowerDetailsVerification: flow,
      refreshAndGetBorrowerDetailsVerification: flow,
      fetchBorrowerDetailsVerification: flow,
      generateAu10tixIdVerification: flow,
      getBorrowerDetailsVerificationLink: flow,
      editBorrowerSetupOptions: flow,
      submitCreditBackgroundRequest: flow,
      saveBorrowerSetupOptions: flow,
      sendIdVerificationEmailReminder: flow,
      savePersonalInformation: flow,
      saveIdInformation: flow,
      saveFinancialInformation: flow,
      saveSomePersonalFinancialInformation: flow,
      saveCreditInformation: flow,
      downloadExistingDriversLicense: flow,
      getIdFile: flow,
      repullReports: flow,
      repullInProgress: observable,
      confirmDocusign: flow,
      acceptIdInformation: flow,
      getIdInformationBorrowerDataCollection: flow,
      saveBorrowerIdentityVerificationId: flow,
      getBorrowerIdvIdentityResultById: flow,
      downloadIDInformationWithIdVerificationDocuments: flow,
      getImgSrcFromBased64String: action,
    });
    this.setDefaults();
  }

  setDefaults() {
    super.setDefaults();
    this.isEditOptionEnabled = false;
    this.currentLoan = null;
    this.borrowerDetailsVerificationUrl = null;
    this.existingDriversLicense = null;
    this.isPersonalInformationExpanded = false;
    this.isIDInformationExpanded = false;
    this.isFinancialInformationExpanded = false;
    this.isCreditInformationExpanded = false;
    this.setupPending = false;
    this.repullInProgress = false;
    this.showStepsLayout = null;
    this.borrowerIdentificationDocumentData = null;
    this.borrowerIdvResultsResponse = null;
    this.photoId = null;
    this.frontId = null;
    this.backId = null;
    this.selfie = null;
  }

  reset() {
    super.reset();
    this.borrowerSetupOptionsFormStore.reset();
    this.creditInformationStore.reset();
    this.setDefaults();
  }

  setIsEditOptionEnabled(isEditOptionEnabled) {
    this.isEditOptionEnabled = isEditOptionEnabled;
  }

  handleEditOption() {
    const currentForm = this.borrowerSetupOptionsFormStore.getFormValues();
    this.borrowerSetupOptionsFormStore.setOldFormValues(currentForm);
    this.setIsEditOptionEnabled(true);
  }

  handleCancelEditOption() {
    const currentForm = this.borrowerSetupOptionsFormStore.oldFormValues;
    this.borrowerSetupOptionsFormStore.loadForm(currentForm);
    this.setIsEditOptionEnabled(false);
  }

  setIsPersonalInformationExpanded(expanded: boolean) {
    this.isPersonalInformationExpanded = expanded;
  }

  setIsIDInformationExpanded(expanded: boolean) {
    this.isIDInformationExpanded = expanded;
  }

  setIsFinancialInformationExpanded(expanded: boolean) {
    this.isFinancialInformationExpanded = expanded;
  }

  setIsCreditInformationExpanded(expanded: boolean) {
    if (this.creditInformationStatus != TodoStatus.NOT_APPLICABLE) {
      if (!this.isBorrowerUser || isNotBlank(this.embeddedDocusignLink)) {
        this.isCreditInformationExpanded = expanded;
        if (expanded) {
          this.refreshAndGetBorrowerDetailsVerification();
        }
      }
    }
  }

  loadStores(data) {
    this.showDob = isBlank(data.dateOfBirth);
    this.showSsn = isBlank(data.ssn);
    if (data.firstName == "" || data.lastName == "") {
      this.disableNameFields = false;
    }
    this.personalInformationFormStore.loadForm(data);
    this.contactDetailsFormStore.setAllowPhoneNumberChange(isBlank(this.borrowerDetailsVerification?.savedForm?.phoneNumber));
    this.contactDetailsFormStore.loadForm({
      ...this.borrowerDetailsVerification,
      ...this.borrowerDetailsVerification?.savedForm,
      maskedEmailAddress: this.contactDetailsFormStore.maskEmailAddress(this.currentBorrower?.emailAddress),
      maskedPhoneNumber: this.contactDetailsFormStore.maskPhoneNumber(this.borrowerDetailsVerification?.savedForm?.phoneNumber),
    })
    this.idInformationStore.loadForm(data);
    this.financialInformationFormStore.loadForm(data);
    this.borrowerSetupOptionsFormStore.loadForm(this.currentBorrower);
    this.creditInformationStore.loadForm(this.currentBorrower);
  }

  getBasicInfoIsPresent() {
    const isForeignNational = ForeignNationalOptions.FOREIGN_NATIONAL === this.personalInformationFormStore?.form?.fields?.citizenshipStatus?.value;
    return !this.showDob && (isForeignNational || !this.showSsn);
  }

  getPersonalInformationStatus() {
    const foreignNationalPGNoSSN = this.currentBorrower?.citizenshipStatus === ForeignNationalOptions.FOREIGN_NATIONAL && this.currentBorrower?.runCreditOnCreation === true && (isNil(this.currentBorrower?.socialSecurityNumber) || this.currentBorrower?.socialSecurityNumber === '');
    if ((!this.isBorrowerAccepted && !this.basicInfoIsPresent) || foreignNationalPGNoSSN) {
      return TodoStatus.PENDING;
    }
    return TodoStatus.ACCEPTED;
  }

  getIdInformationStatus() {
    if (this.isBorrowerAccepted || this.currentBorrower?.isPersonalInformationFileUploaded
      || this.borrowerDetailsVerification?.isDriverLicenseFileUploaded || (this.basicInfoIsPresent && (
        (isNotBlank(this.idInformationStore?.form?.fields?.driversLicenseState?.value)
          && isNotBlank(this.idInformationStore?.form?.fields?.driversLicenseNumber?.value))
        || (isNotBlank(this.idInformationStore?.form?.fields?.passportNumber?.value)
          && isNotBlank(this.idInformationStore?.form?.fields?.passportExpiration?.value))
      ))) {
      return TodoStatus.ACCEPTED;
    }
    return TodoStatus.PENDING;
  }

  getFinancialInformationStatus() {
    if (this.isBorrowerAccepted || (this.basicInfoIsPresent
      && isNotBlank(this.financialInformationFormStore?.form?.fields?.netWorth?.value))
    ) {
      return TodoStatus.ACCEPTED;
    }
    return TodoStatus.PENDING;
  }

  getCreditInformationStatus() {
    if (!this.currentBorrower.runCreditOnCreation) {
      return TodoStatus.NOT_APPLICABLE;
    }
    else {
      if (!this.isBorrowerAccepted) {
        if (!this.basicInfoIsPresent || this.currentBorrower?.creditPending || this.currentBorrower?.creditExpired || this.currentBorrower?.reissueFailed || this.currentBorrower?.areCreditScoresFrozen) {
          if (this.repullInProgress) {
            return TodoStatus.BEING_REVIEWED;
          }
          return TodoStatus.PENDING;
        }
      }
      return TodoStatus.ACCEPTED;
    }
  }

  get personalGuarantor() {
    return !isNil(this.borrowerSetupOptionsFormStore.oldFormValues) ? this.borrowerSetupOptionsFormStore.oldFormValues.runCreditOnCreation : this.borrowerSetupOptionsFormStore.form.fields.runCreditOnCreation.value;
  }

  get sendIdVerification() {
    return !isNil(this.borrowerSetupOptionsFormStore.oldFormValues) ? this.borrowerSetupOptionsFormStore.oldFormValues.sendIdVerification : this.borrowerSetupOptionsFormStore.form.fields.sendIdVerification.value;
  }

  get isBorrowerAccepted() {
    return this.currentBorrower?.preQualificationStatus === prequalificationStatusProperties.ACCEPTED.name;
  }

  get sendIdVerificationPending() {
    return this.currentBorrower?.sendIdVerification && !this.isBorrowerAccepted;
  }

  get isBorrowerUser() {
    return this.globalStore?.userFeatures?.isBorrower;
  }

  *loadBorrowerPrescreenInfo(borrowerId, loan) {
    yield this.getBorrowerPrescreenInfo(borrowerId, loan);
    this.setupPending = (this.borrowerDetailsVerification?.status === FormStatus.PENDING || this.borrowerDetailsVerification?.status === FormStatus.UNSIGNED) && this.borrowerDetailsVerification?.requestState === CreditBackgroundCheckStatus.WAITING_AUTHORIZATION;
    this.showStepsLayout = Boolean(this.setupPending && !this.basicInfoIsPresent);
  }

  *getBorrowerPrescreenInfo(borrowerId, loan) {
    try {
      this.currentBorrowerId = borrowerId;
      this.currentLoan = loan;
      const response = yield this.borrowerSetupToDoService.getBorrowerPrescreenInfo(this.currentBorrowerId, this.currentLoan?.loanId);
      this.currentBorrower = response?.data?.data;
      const data = {
        ...this.currentBorrower,
        ssn: this.currentBorrower.socialSecurityNumber,
        dateOfBirth: this.currentBorrower.dob ?? null,
        phoneNumner: this.currentBorrower.cellPhone,
        driversLicenseNumber: this.currentBorrower.driverLicenseNumber ?? '',
        driversLicenseState: this.currentBorrower.driverLicenseState,
        assets: this.currentBorrower.assets ?? this.currentBorrower.netWorth,
        liabilities: this.currentBorrower.liabilities ?? 0,
      }
      this.loadStores(data);
      yield this.getBorrowerDetailsVerification();
      yield this.downloadExistingDriversLicense();
    } catch (error) {
      console.log(error);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'We had an issue while retrieving the borrower info.',
      });
    }
  }

  *getBorrowerDetailsVerification() {
    yield this.fetchBorrowerDetailsVerification(false);
  }

  *refreshAndGetBorrowerDetailsVerification() {
    yield this.fetchBorrowerDetailsVerification(true);
  }

  *fetchBorrowerDetailsVerification(refreshEmbeddedDocusignLink: boolean) {
    try {
      const response = yield this.borrowerSetupToDoService.getBorrowerDetailsVerification(this.currentBorrowerId, this.currentLoan?.loanId, refreshEmbeddedDocusignLink);
      if (!isNil(response?.data?.data?.savedForm)) {
        this.borrowerDetailsVerification = response?.data?.data;
        const data = {
          ...this.currentBorrower,
          ...this.borrowerDetailsVerification?.savedForm,
          driversLicenseNumber: this.borrowerDetailsVerification?.savedForm?.driversLicenseNumber ?? ''
        };
        this.loadStores(data);
        if (this.sendIdVerification) {
          yield this.getBorrowerDetailsVerificationLink();
          this.embeddedDocusignLink = this.borrowerDetailsVerification?.embeddedDocusignLink;
        }
      }
      else if (!this.isBorrowerAccepted) {
        this.handleEditOption();
      }
      this.basicInfoIsPresent = this.getBasicInfoIsPresent();
      this.personalInformationStatus = this.getPersonalInformationStatus();
      this.idInformationStatus = this.getIdInformationStatus();
      this.financialInformationStatus = this.getFinancialInformationStatus();
      this.creditInformationStatus = this.getCreditInformationStatus();
    } catch (error) {
      console.log(error)
      this.globalStore.notificationStore.showErrorNotification({
        message: 'We had an issue while retrieving the borrower form details.',
      });
    }
  }

  *generateAu10tixIdVerification() {
    try {
      const response = yield this.borrowerSetupToDoService.generateAu10tixIdVerification(this.currentBorrowerId);
      this.borrowerIdvRequestResponse = response?.data?.data;
    } catch (error) {
      console.log(error);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'We had an issue while generating ID verification.',
      });
    }
  }

  *getBorrowerDetailsVerificationLink() {
    try {
      const response = yield this.borrowerSetupToDoService.getBorrowerSetupLink(this.borrowerDetailsVerification?.savedForm?.creditBackgroundRequestId);
      this.borrowerDetailsVerificationUrl = response?.data?.data;
    }
    catch (error) {
      console.log(error)
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while getting borrower form link.',
      });
    }
  }

  *sendIdVerificationEmailReminder() {
    try {
      yield this.borrowerSetupToDoService.sendIdVerificationEmailReminder(this.borrowerDetailsVerification?.savedForm?.requestToken);
    }
    catch (error) {
      console.log(error)
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while sending id verification email',
      });
    }
  }

  *editBorrowerSetupOptions(refreshLoan?: () => void) {
    try {
      this.borrowerSetupOptionsFormStore.setOldFormValues(null);
      yield this.saveBorrowerSetupOptions();
      yield this.submitCreditBackgroundRequest();
      yield this.loadBorrowerPrescreenInfo(this.currentBorrowerId, this.currentLoan);
      if (refreshLoan) {
        refreshLoan();
      }
      this.setIsEditOptionEnabled(false);
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Borrower setup options saved successfully.',
      });
    } catch (error) {
      console.log(error);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while saving selection.',
      });
    }
  }

  *saveBorrowerSetupOptions() {
    try {
      yield this.borrowerSetupToDoService.saveBorrowerSetupOptions(this.currentBorrowerId, this.sendIdVerification, this.personalGuarantor, this.currentLoan?.loanId, this.currentLoan?.borrowerEntity?.borrowerEntityId);
    } catch (error) {
      console.log(error);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while saving borrower.',
      });
    }
  }

  *submitCreditBackgroundRequest() {
    try {
      const type = this.sendIdVerification ? CreditBackgroundType.ID_VERIFICATION : CreditBackgroundType.BG_ID_VERIFICATION_WITHOUT_NOTIFICATION;
      yield this.borrowerSetupToDoService.submitCreditBackgroundRequest({
        email: this.currentBorrower?.emailAddress,
        runCredit: this.sendIdVerification ? this.personalGuarantor : false,
        runBackground: true,
        type,
        isConfirmed: true,
        isSendCAFConfirmed: true,
        isDuplicateConfirmed: true,
        isBorrower: true,
        borrowerId: this.currentBorrowerId,
        lenderId: this.currentLoan?.lenderId,
      });
    }
    catch (error) {
      console.log(error);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while submiting request.',
      });
    }
  }

  *repullReports() {
    this.repullInProgress = true;
    yield this.submitCreditBackgroundRequest();
    yield this.getBorrowerPrescreenInfo(this.currentBorrowerId, this.currentLoan);
  }

  *savePersonalInformation() {
    try {
      if (this.isInvalidDateOfBirthDate(this.personalInformationFormStore.form.fields.dateOfBirth.value)) {
        this.personalInformationFormStore.form.fields.dateOfBirth.error = 'Age must be over 18 years';
        this.globalStore.notificationStore.showWarningNotification({ message: 'Please fix the validation errors.', });
      } else if (this.isInvalidSSN(this.personalInformationFormStore.form.fields.ssn.value)) {
        this.personalInformationFormStore.form.fields.ssn.error = 'Invalid SSN number.';
        this.globalStore.notificationStore.showWarningNotification({ message: 'Please fix the validation errors.', });
      } else if (this.personalInformationFormStore.form.fields.__isAddressInCorrectFormat__.value == false) {
        this.personalInformationFormStore.form.fields.address.error = 'Invalid address format';
        this.globalStore.notificationStore.showWarningNotification({ message: 'Please fix the validation errors.', });
      }
      else {
        this.personalInformationFormStore.runFormValidationWithMessage();
        if (this.personalInformationFormStore.form.meta.isValid) {
          const data = {
            ...this.personalInformationFormStore.getFormValues(),
            token: this.borrowerDetailsVerification?.savedForm?.requestToken,
          };
          const response = yield this.borrowerSetupToDoService.savePersonalInformation(this.currentLoan?.loanId, data);
          this.setIsPersonalInformationExpanded(false);
          yield this.getBorrowerPrescreenInfo(response?.data?.data?.borrowerId, this.currentLoan);
          this.goNextStep();
          this.globalStore.notificationStore.showSuccessNotification({
            message: 'Personal Information saved successfully.',
          });
        }
      }
    } catch (error) {
      console.log(error);
      if (isNotBlank(error?.error?.response?.data?.error?.message)) {
        this.globalStore.notificationStore.showErrorNotification({ message: error?.error?.response?.data?.error?.message });
      }
      else {
        this.globalStore.notificationStore.showErrorNotification({
          message: 'Error while saving Personal Information.',
        });
      }
    }
  }

  *saveIdInformation() {
    try {
      if (!isNil(this.existingDriversLicense) && isNil(this.idInformationStore.driversLicense)) {
        this.idInformationStore.setDriversLicense(this.existingDriversLicense);
      }
      else if (isNil(this.idInformationStore.driversLicense)) {
        this.globalStore.notificationStore.showWarningNotification({ message: "Please upload a Document.", });
      }
      this.idInformationStore.runFormValidationWithMessage();
      if (!isNil(this.idInformationStore.driversLicense) && this.idInformationStore.form.meta.isValid) {
        const data = {
          ...this.idInformationStore.getFormValues(),
          token: this.borrowerDetailsVerification?.savedForm?.requestToken,
        }
        yield this.borrowerSetupToDoService.saveIdInformation(data, this.idInformationStore.driversLicense);
        this.setIsIDInformationExpanded(false);
        yield this.getBorrowerPrescreenInfo(this.currentBorrowerId, this.currentLoan);
        this.goNextStep();
        this.globalStore.notificationStore.showSuccessNotification({
          message: 'ID Information saved successfully.',
        });
      }
    } catch (error) {
      console.log(error);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while saving ID Information.',
      });
    }
  }

  *saveFinancialInformation() {
    try {
      this.financialInformationFormStore.runFormValidationWithMessage();
      if (this.financialInformationFormStore.form.meta.isValid) {
        const data = {
          ...this.financialInformationFormStore.getFormValues(),
          token: this.borrowerDetailsVerification?.savedForm?.requestToken,
        }
        yield this.borrowerSetupToDoService.saveFinancialInformation(data);
        this.setIsFinancialInformationExpanded(false);
        yield this.getBorrowerPrescreenInfo(this.currentBorrowerId, this.currentLoan);
        this.goNextStep();
        this.globalStore.notificationStore.showSuccessNotification({
          message: 'Financial Information saved successfully.',
        });
      }
    } catch (error) {
      console.log(error);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while saving Financial Information.',
      });
    }
  }

  *saveSomePersonalFinancialInformation() {
    try {
      if (this.isInvalidDateOfBirthDate(this.personalInformationFormStore.form.fields.dateOfBirth.value)) {
        this.personalInformationFormStore.form.fields.dateOfBirth.error = 'Age must be over 18 years';
        this.globalStore.notificationStore.showWarningNotification({ message: 'Please fix the validation errors.', });
      } else if (this.isInvalidSSN(this.personalInformationFormStore.form.fields.ssn.value)) {
        this.personalInformationFormStore.form.fields.ssn.error = 'Invalid SSN number.';
        this.globalStore.notificationStore.showWarningNotification({ message: 'Please fix the validation errors.', });
      }
      else if (isBlank(this.personalInformationFormStore.form.fields.phoneNumber.value)) {
        this.personalInformationFormStore.form.fields.phoneNumber.error = 'Phone number is required.';
        this.globalStore.notificationStore.showWarningNotification({ message: 'Please fix the validation errors.', });
      } else if (this.personalInformationFormStore.form.fields.__isAddressInCorrectFormat__.value == false) {
        this.personalInformationFormStore.form.fields.address.error = 'Invalid address format';
        this.globalStore.notificationStore.showWarningNotification({ message: 'Please fix the validation errors.', });
      }
      else {
        this.personalInformationFormStore.runFormValidationWithMessage();
        this.financialInformationFormStore.runFormValidationWithMessage();
        if (this.personalInformationFormStore.form.meta.isValid && this.financialInformationFormStore.form.meta.isValid) {
          const data = {
            ...this.personalInformationFormStore.getFormValues(),
            ...this.financialInformationFormStore.getFormValues(),
            token: this.borrowerDetailsVerification?.savedForm?.requestToken,
          }
          const response = yield this.borrowerSetupToDoService.saveSomePersonalFinancialInformation(this.currentLoan?.loanId, data);
          this.setIsPersonalInformationExpanded(false);
          yield this.getBorrowerPrescreenInfo(response?.data?.data?.borrowerId, this.currentLoan);
          this.goNextStep();
          this.globalStore.notificationStore.showSuccessNotification({
            message: 'Some Personal Information saved successfully.',
          });
        }
      }
    } catch (error) {
      console.log(error);
      if (isNotBlank(error?.error?.response?.data?.error?.message)) {
        this.globalStore.notificationStore.showErrorNotification({ message: error?.error?.response?.data?.error?.message });
      }
      else {
        this.globalStore.notificationStore.showErrorNotification({
          message: 'Error while saving some Personal Information.',
        });
      }
    }
  }

  *saveCreditInformation(closeModal?: () => void) {
    try {
      if (this.personalGuarantor) {
        if (isNil(this.creditInformationStore.creditReport)) {
          this.globalStore.notificationStore.showWarningNotification({ message: 'Please upload a Credit Report.', });
        }
        this.creditInformationStore.runFormValidationWithMessage();
        if (!isNil(this.creditInformationStore.creditReport) && this.creditInformationStore.form.meta.isValid) {
          const data = this.creditInformationStore.getFormValues();
          yield this.borrowerSetupToDoService.saveCreditInformation(this.currentBorrowerId, data.creditReportFileId, this.creditInformationStore.creditReport, this.currentLoan?.loanId);
          this.setIsCreditInformationExpanded(false);
          yield this.getBorrowerPrescreenInfo(this.currentBorrowerId, this.currentLoan);
          this.globalStore.notificationStore.showSuccessNotification({
            message: 'Credit Information saved successfully.',
          });
          if (!isNil(closeModal)) {
            closeModal();
          }
        }
      } else {
        if (!isNil(closeModal)) {
          closeModal();
        }
      }
    } catch (error) {
      console.log(error);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while saving Credit Information.',
      });
    }
  }

  *downloadExistingDriversLicense() {
    try {
      if (isNotBlank(this.borrowerDetailsVerification?.savedForm?.driversLicenseUploadPath)) {
        const response = yield this.borrowerSetupToDoService.downloadDriversLicense(this.borrowerDetailsVerification?.savedForm?.creditBackgroundRequestId);
        const fileResult = getDocumentAsBlob(response?.data, response?.headers, response?.data?.type);
        this.existingDriversLicense = new File([fileResult.blob], fileResult.name);
        this.idInformationStore.setDriversLicense(this.existingDriversLicense);
      }
    } catch (error) {
      console.log(error);
    }
  }

  *getIdFile() {
    try {
      if (isNotBlank(this.borrowerDetailsVerification?.savedForm?.driversLicenseUploadPath)) {
        const response = yield this.borrowerSetupToDoService.downloadDriversLicense(this.borrowerDetailsVerification?.savedForm?.creditBackgroundRequestId);
        downloadDocument(response?.data, response?.headers, 'download');
      }
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while getting ID file.',
      });
    }
  }

  *confirmDocusign(closeModal?: () => void) {
    try {
      yield this.borrowerSetupToDoService.confirmDocusign(this.borrowerDetailsVerification?.savedForm?.requestToken);
      yield this.getBorrowerPrescreenInfo(this.currentBorrowerId, this.currentLoan);
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Credit Docusign Information saved successfully.',
      });
      if (!isNil(closeModal)) {
        closeModal();
      }
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while saving Credit Docusign Information.',
      });
    }
  }

  *acceptIdInformation() {
    try {
      const data = {
        ...this.idInformationStore.getFormValues(),
        token: this.borrowerDetailsVerification?.savedForm?.requestToken,
      }
      yield this.borrowerSetupToDoService.acceptIdInformationManual(data);
      this.setIsIDInformationExpanded(false);
      yield this.getBorrowerPrescreenInfo(this.currentBorrowerId, this.currentLoan);
      this.goNextStep();
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'ID Information accepted.',
      });
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while accepting ID Information.',
      });
    }
  }

  *getIdInformationBorrowerDataCollection() {
    try {
      const response = yield this.borrowerSetupToDoService.getIdInformationBorrowerDataCollection(this.currentBorrowerId);
      this.borrowerIdentificationDocumentData = JSON.parse(response?.data?.data);
    } catch (error) {
      console.log(error);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'We had an issue while retrieving the borrower data collection info.',
      });
    }
  }

  *saveBorrowerIdentityVerificationId() {
    try {
      yield this.borrowerSetupToDoService.saveBorrowerIdentityVerificationId(this.borrowerDetailsVerification?.savedForm?.requestToken, this.borrowerIdvRequestResponse?.borrowerIdentityVerificationId);
      yield this.getBorrowerPrescreenInfo(this.currentBorrowerId, this.currentLoan);
    } catch (error) {
      console.log(error);
    }
  }

  *getBorrowerIdvIdentityResultById() {
    try {
      if (!isNil(this.borrowerDetailsVerification?.savedForm?.borrowerIdentityVerificationId)) {
        const response = yield this.borrowerSetupToDoService.getBorrowerIdvIdentityResultById(this.borrowerDetailsVerification?.savedForm?.borrowerIdentityVerificationId);
        this.borrowerIdvResultsResponse = response?.data?.data;
      }
    } catch (error) {
      console.log(error);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'We had an issue while retrieving the borrower\'s id verification result.',
      });
    }
  }

  *downloadIDInformationWithIdVerificationDocuments() {
    try {
      if (!isNil(this.borrowerDetailsVerification?.savedForm?.borrowerIdentityVerificationId)) {
        const response = yield this.borrowerSetupToDoService.downloadIDInformationWithIdVerificationDocuments(this.borrowerDetailsVerification?.savedForm?.borrowerIdentityVerificationId);
        response.data.forEach(element => {
          const newElement = {
            ...element,
            based64: element.imgSrc,
            imgSrc: this.getImgSrcFromBased64String(element.imgSrc),
          };
          if (newElement.name == 'Photo') {
            this.photoId = newElement;
          }
          else if (newElement.name == 'Front') {
            this.frontId = newElement;
          }
          else if (newElement.name == 'Back') {
            this.backId = newElement;
          }
          else if (newElement.name == 'Selfie') {
            this.selfie = newElement;
          }
        });
      }
    } catch (error) {
      console.log(error);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'We had an issue while retrieving the borrower\'s id verification documents.',
      });
    }
  }

  getImgSrcFromBased64String = (base64String) => {
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray]);
    return URL.createObjectURL(blob);
  };
}