import { Box, Grid, IconButton, Typography } from '@material-ui/core';
import { ChevronLeft } from '@material-ui/icons';
import {
  DrawDetailsShared,
  DrawRequest as _DrawRequest,
  DrawRequestShared,
  DrawSuccess,
  useDrawsStore,
} from '@roc/feature-draws';
import { useLoanStore } from '@roc/feature-loans';
import { useLoanRoutes } from '@roc/feature-loans-routes-config';
import { getDrawId } from '@roc/feature-utils';
import { Button } from '@roc/ui';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { LoanDetailsPaper } from './loanDetailsPaper';

export const DrawDetails = observer(() => {
  const { loanStore } = useLoanStore();
  const { loanRoutesConfig } = useLoanRoutes();
  const { push } = useHistory();
  const { pathname } = useLocation();
  const { drawStore } = useDrawsStore();
  const { loanDetails } = loanStore;
  const loanId = loanDetails?.loanId;
  const drawId = getDrawId(pathname);
  const adminSpecialist = loanDetails?.drawAdminSpecialistFullName;
  const reviewSpecialist = loanDetails?.drawReviewSpecialistFullName;

  return (
    <>
      <Grid item xs={12}>
        <Box display="flex" alignItems="center">
          <IconButton
            aria-label="back"
            color="secondary"
            onClick={() =>
              push(loanRoutesConfig.loans(loanId).children.draws.url)
            }
          >
            <ChevronLeft fontSize="large" />
          </IconButton>
          {drawStore?.currentDraw?.propertyAddress ? (
            <Typography variant="h5">
              DRAW {drawStore?.currentDraw.drawNumber} -{' '}
              {drawStore.currentDraw.propertyAddress}
            </Typography>
          ) : (
            <Typography variant="h5">
              DRAW {drawStore?.currentDraw?.drawNumber}
            </Typography>
          )}
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <DrawDetailsShared
            loanId={loanId}
            drawId={drawId}
            adminSpecialist={adminSpecialist}
            reviewSpecialist={reviewSpecialist}
          />
        </Grid>
      </Grid>
    </>
  );
});
