import { makeStyles } from '@material-ui/core/styles';
import { useBorrowerLoansRoutes, useBorrowerLoansStore } from '@roc/feature-borrower-loans';
import {
  DataGrid
} from '@roc/ui';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { borrowerLoansColumns, borrowerLoansFrameworkComponents } from './borrowerLoansMyLoansGrid';

const useStyles = makeStyles(theme => ({
  paginationBar: {
    border: 'none',
    borderTop: '1px solid #eee',
    padding: 8,
    background: '#fafafa',
  },
}));

export const BorrowerLoansPaidoffRetractedLoansGrid = observer(() => {
  const classes = useStyles();
  const { borrowerLoansStore } = useBorrowerLoansStore();
  const { borrowerLoansGridStore } = borrowerLoansStore;
  const gridStore = borrowerLoansGridStore;
  const { borrowerLoanRoutesConfig } = useBorrowerLoansRoutes();

  useEffect(() => {
    gridStore.resetAndFetchGridData();
  }, []);

  const onSortChanged = params => {
    const sortModel = params.api.getSortModel();
    gridStore.setSortModel(sortModel);
  };

  return (
    <DataGrid
      columns={borrowerLoansColumns}
      rows={gridStore.gridData.data.rows}
      frameworkComponents={borrowerLoansFrameworkComponents(borrowerLoanRoutesConfig)}
      onSortChanged={onSortChanged}
      isLoading={gridStore.isLoading}
      sortModel={gridStore.sortModel}
      filterModel={gridStore.filterModel}
      paginationData={gridStore.gridData.meta}
      paginationBarClassName={classes.paginationBar}
      setPageNumber={gridStore.setPageNumber}
      domLayout='autoHeight'
    />
  )
});
