import { Page } from '../../../components';
import { Route } from 'react-router';
import { Dashboard } from '../dashboard';

export const getDashboardRoutesConfig = (basePath: string, baseUrl: string) => {
  return {
    dashboard: {
      path: `${basePath}/dashboard`,
      url: `${baseUrl}/dashboard`,
      documentTitle: 'Dashboard',
    },
  };
};

export const getDashboardRoutes = (basePath: string, baseUrl: string) => {
  const config = getDashboardRoutesConfig(basePath, baseUrl);
  return {
    dashboard: (
      <Route exact path={config.dashboard.path}>
        <Page routeDefinition={config.dashboard}>
          <Dashboard />
        </Page>
      </Route>
    ),
  };
};
