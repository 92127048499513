import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  AccordionSummaryProps,
  Box,
  Container,
  Divider,
  DividerProps,
  Grid,
  GridProps,
  LinearProgress,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import clsx from 'clsx';
import { ArrowDropDown, Person } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  card: {
    border: '1px solid #C4C4C4',
    borderRadius: theme.spacing(2),
  },
  cardError: {
    borderColor: theme.palette.error.main,
  },
  cardTitle: {
    fontSize: '24px',
    textTransform: 'uppercase',
  },
  cardIcon: {
    backgroundColor: '#E4E8EB',
    borderRadius: 50,
    flexShrink: 0,
    width: '50px',
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  cardDivider: {
    backgroundColor: '#C4C4C4',
  },
  cardField: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '36px',
    paddingTop: theme.spacing(1),
  },
  cardActions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

const useAccordionStyles = makeStyles(theme => ({
  accordion: {
    '&::before': {
      content: 'none',
    },
    '& .MuiIconButton-root': {
      marginRight: '0',
      color: 'black',
      paddingLeft: 0,
      paddingRight: 0,
    },
    '& .MuiInputBase-root': {
      backgroundColor: 'white',
    },
    '&.Mui-expanded': {
      margin: 0,
    },

    backgroundColor: 'transparent',
    borderRadius: '0 !important',
    padding: theme.spacing(2),
    transition: 'background-color 0.5s',
  },
  expanded: {
    backgroundColor: '#F4F6F9',
  },
  summary: {
    '& .MuiAccordionSummary-content': {
      margin: 0,
    },
    '& svg': {
      fontSize: '2rem',
    },

    backgroundColor: 'transparent !important',
    padding: 0,
    minHeight: '46px',
    fontSize: '20px',
    fontWeight: 'bold',
    flexDirection: 'row-reverse',
  },
  overflowBackground: {
    margin: theme.spacing(0, -100),
    padding: theme.spacing(0, 100),
  },
  details: {
    padding: 0,
    paddingTop: theme.spacing(2),
    display: 'block',
  },
}));

export const PublicLoanCardDividers = ({ children, px = 0, py = 0 }) => {
  const classes = useStyles();
  return (
    <Box px={px}>
      {React.Children.map(children, (child, i) => (
        <>
          {i !== 0 && child && (
            <Box>
              <Divider className={classes.cardDivider} />
            </Box>
          )}
          <Box py={py}>{child}</Box>
        </>
      ))}
    </Box>
  );
};

export const PublicLoanCardField = ({ label, value }) => {
  const classes = useStyles();
  return (
    <Box className={classes.cardField}>
      <Typography>
        <b>{label}</b>
      </Typography>
      <Typography>{value}</Typography>
    </Box>
  );
};

export const PublicLoanCard = ({
  title,
  icon,
  children,
  error = false,
}) => {
  const classes = useStyles();
  return (
    <Box className={clsx(classes.card, error && classes.cardError)}>
      <Box p={2} pb={0}>
        <PuublicLoanTitle title={title} icon={icon} />
      </Box>
      {children}
    </Box>
  );
};

export const PuublicLoanTitle = ({ title, icon }) => {
  const classes = useStyles();
  return (
    <Box display={'flex'} alignItems={'center'}>
      <Box mr={2} boxShadow={2} className={classes.cardIcon}>
        {icon}
      </Box>
      <Typography className={classes.cardTitle}>{title}</Typography>
    </Box>
  );
};

export const PublicLoanAccordion = ({
  className = undefined,
  expanded = undefined,
  onChange = undefined,
  overflowExpandedBackground = false,
  summary,
  children,
}) => {
  const classes = useAccordionStyles();
  return (
    <Box
      className={clsx(
        expanded && classes.expanded,
        overflowExpandedBackground && classes.overflowBackground
      )}
    >
      <Accordion
        className={classes.accordion}
        expanded={expanded}
        onChange={onChange}
      >
        <AccordionSummary
          className={classes.summary}
          expandIcon={<ArrowDropDown />}
        >
          {summary}
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          {children}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export const PublicLoanCardActions = ({ children }) => {
  const classes = useStyles();
  return <Box className={classes.cardActions}>{children}</Box>;
};