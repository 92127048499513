import { FormStore, GlobalStore } from "@roc/feature-app-core";
import { action, flow, makeObservable, observable } from "mobx";
import { ReferralFeeExternalService } from "../services/referralFeeExternalFeeService";
import { GENERIC_ERROR_MESSAGE } from "@roc/feature-utils";

const form = {
  fields: {
    bankName: {
      value: '',
      error: null,
      rule: 'required',
      attribute: 'bank name'
    },
    accountName: {
      value: '',
      error: null,
      rule: 'required',
      attribute: 'account name',
    },
    accountNumber: {
      value: '',
      error: null,
      rule: 'required',
      attribute: 'account number',
    },
    routingNumber: {
      value: '',
      error: null,
      rule: 'required|regex:/^\\d{9}$/',
      message: 'The routing number must be 9 digits.'
    },
    streetName: {
      value: '',
      error: null,
      rule: 'required',
      message: 'The Street Name field is required.',
    },
    city: {
      value: '',
      error: null,
      rule: 'required',
      message: 'The City field is required.',
    },
    zipCode: {
      value: '',
      error: null,
      message: 'Zip Code must be 5 digits',
      rule: 'required|regex:/^\\d{5}$/|alpha_dash',
    },
    state: {
      value: '',
      error: null,
      rule: 'required',
      message: 'The State field is required.',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};


export class ReferralAuthStore extends FormStore {
  public activeStep: number;
  public docusignLink: string;
  private referralFeeService: ReferralFeeExternalService;
  public globalStore: GlobalStore;
  public isSigned: boolean;
  public requestComplete: boolean;
  constructor(globalStore: GlobalStore) {
    super(form, globalStore);
    this.globalStore = globalStore;
    this.referralFeeService = new ReferralFeeExternalService();
    this.activeStep = 0;
    this.requestComplete = false;
    makeObservable(this, {
      activeStep: observable,
      moveToDocusignStep: action,
      docusignLink: observable,
      isSigned: observable,
      getDocusign: flow,
      getBankInfo: flow,
      requestComplete: observable
    });
  }

  moveToDocusignStep() {
    this.runFormValidationWithMessage();
    if (this.form.meta.isValid) {
      this.activeStep = 1;
    }
  }

  *getDocusign() {
    this.referralFeeService.getDocusign(this.getFormValues(), window.location.href.substring(
      window.location.href.lastIndexOf('/') + 1))
      .then(response => {
        this.docusignLink = response.data.data.viewUrl
      })
      .catch(error => this.globalStore.notificationStore.showErrorNotification({ message: GENERIC_ERROR_MESSAGE }))
  }

  *getBankInfo() {
    this.referralFeeService.getFilteredBankInfo(atob(window.location.href.substring(
      window.location.href.lastIndexOf('/') + 1)).split("-")[1]).then(response => {
        this.isSigned = ['Pending Approval', 'Approved'].includes(response.data?.bankInfoStatus);
        this.activeStep = this.isSigned ? 1 : 0;
        this.requestComplete = true;
      });
  }
}