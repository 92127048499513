import { Autocomplete, AutocompleteProps } from '@material-ui/lab';
import { useVirtualizedAutocomplete } from './useVirtualizedAutocomplete';

export interface AutocompleteFieldProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> extends AutocompleteProps<T, Multiple, DisableClearable, FreeSolo> {
  optionHeight: number;
}

export const VirtualizedAutocomplete = (
  {optionHeight, ...muiAutocompleteFieldProps}: AutocompleteFieldProps<any,any,any,any>
) =>  {
  const { ListboxComponent } = useVirtualizedAutocomplete(optionHeight)
  return (
    <Autocomplete
      ListboxComponent={ListboxComponent}
      {...muiAutocompleteFieldProps}
    />
  );
};
