import { Grid, Theme, Typography, makeStyles, Button, Box } from "@material-ui/core";
import { PropertyAccordion } from "./components";
import { useEffect } from "react";
import { observer } from "mobx-react";


const useStyles = makeStyles((theme: Theme) => ({
  container: {
    height: 'fit-content',
    width: '98%',
  },
  wrapper: {
    backgroundColor: 'white',
  }

}));

export const InsuranceReview = observer((props) => {
  const classes = useStyles();

  useEffect(() => {
    props.store.loanId = props.loanId;
    props.store.setLoanStatus(props.loanStatus);
    props.store.fetchInsuranceData(props.loanId);
  }, []);

  useEffect(() => {
    return () => props.store.initValues();
  }, [])

  return (
    <Grid container className={classes.wrapper}>
      <Grid item container className={classes.container}>
        {
          props.store?.properties?.length > 0 ? props.store.properties.map(property => (
            <Grid item xs={12} key={property}>
              <PropertyAccordion
                property={property}
                store={props.store}
              />
            </Grid>
          )) : (<Grid item>
            <Typography>No data to display at the moment</Typography>
          </Grid>)
        }
      </Grid>
      {!props.store.isInternal && <Grid xs={12}>
        <Box display={'flex'} flexDirection={'row-reverse'} width={'95%'}>
          <Button
            variant='contained'
            color='primary'
            size='large'
            onClick={() => props.store.saveInsuranceData()}
          >Save
          </Button>
        </Box>
      </Grid>}
    </Grid>

  )
});