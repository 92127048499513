import { observer } from 'mobx-react';
import { Layout } from '@roc/feature-app-core';
import { useCreditReviewStore } from '../hooks/useCreditReviewStore';
import CreditReviewForm from './creditReviewForm';
import { BorrowerReviewCustomTheme } from '../components/utils/borrowerReviewCustomTheme';

interface CreditReviewProps {
  borrowerId: string;
  loanId: string;
  isInternal: boolean;
}

export const CreditReview = observer(({ borrowerId, loanId, isInternal }: CreditReviewProps) => {
  const { creditReviewStore } = useCreditReviewStore();
  const { creditItemStore } = creditReviewStore;

  creditItemStore.currentBorrower = borrowerId;
  creditItemStore.loanId = loanId;
  creditItemStore.isInternal = isInternal;
  creditItemStore.initializeService();

  if (!borrowerId) {
    return (<></>);
  }


  return (
    <div style={{ background: 'white', width: '100%' }}>
      <Layout>
        <BorrowerReviewCustomTheme>
        <CreditReviewForm store={creditItemStore} />
        </BorrowerReviewCustomTheme>
      </Layout>
    </div>
  );
});