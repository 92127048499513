import { Divider, Grid, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Button, FileUpload, FileUploadArea, createAutocompleteAddressField, createCurrencyField, createDateField, createRadioField, createSelectField, createTextField } from '@roc/ui';
import { stateOptions } from 'libs/client-portal-shared/src/app/modules/bridgePricer/utils/bridgePricerConstants';
import { useEffect, useState } from 'react';

const useStyles = makeStyles((theme: Theme) => {
  return {
    button: {
      marginRight: theme.spacing(1)
    }
  };
});

export const dealTypeOptions = [
  {
    label: 'Bridge',
    value: 'Bridge',
  },
  {
    label: 'Rental',
    value: 'Rental',
  },
];

export const TrackRecordPropertyForm = observer(({ store }) => {
  const classes = useStyles();
  const formStore = store.trackRecordPropertyFormStore;

  const handleAddressChange = event => {
    const { value, fullInfo } = event?.target || {};
    const { address_components, geometry } = fullInfo || {};
    formStore.saveAddressField(value, address_components, geometry);
  };

  return (
    <Grid container direction='row' spacing={2}>
      <Grid item xs={12}>
        <Typography variant='h5'>{`Property #${store.currentPropertyIndex}`}</Typography>
      </Grid>
      <Grid item xs={12} container direction='row' spacing={1}>
        <Grid item xs={12}>
          {createRadioField({
            store: formStore,
            testId: 'dealType',
            fieldName: 'dealType',
            label: "What type of deal is this property?",
            options: dealTypeOptions,
            row: true,
          })}
        </Grid>
        <Grid item xs={12}>
          {createTextField({
            store: formStore,
            testId: 'entityName',
            fieldName: 'entityName',
            label: 'Entity on Title',
          })}
        </Grid>
        <Grid item xs={12}>
          {createAutocompleteAddressField({
            fieldName: 'fullAddress',
            label: 'Address Line 1',
            testId: 'fullAddress',
            store: formStore,
            onChange: handleAddressChange,
          })}
        </Grid>
        <Grid item xs={12}>
          {createTextField({
            label: 'Address Line 2',
            fieldName: 'address2',
            testId: 'address2',
            store: formStore,
          })}
        </Grid>
        <Grid item xs={3}>
          {createSelectField({
            store: formStore,
            testId: 'purchaseState',
            fieldName: 'purchaseState',
            label: 'State',
            options: stateOptions,
          })}
        </Grid>
        <Grid item xs={6}>
          {createTextField({
            label: 'City',
            fieldName: 'purchaseCity',
            testId: 'purchaseCity',
            store: formStore,
          })}
        </Grid>
        <Grid item xs={3}>
          {createTextField({
            label: 'Zip Code',
            fieldName: 'purchaseZipcode',
            testId: 'purchaseZipcode',
            store: formStore,
          })}
        </Grid>
        <Grid item xs={6}>
          {createDateField({
            label: 'Purchase Date',
            fieldName: 'purchaseDate',
            testId: 'purchaseDate',
            format: 'MM/dd/yyyy',
            store: formStore,
          })}
        </Grid>
        <Grid item xs={6}>
          {createCurrencyField({
            label: 'Purchase Price',
            fieldName: 'purchasePrice',
            testId: 'purchasePrice',
            store: formStore,
          })}
        </Grid>
        {formStore.form.fields.dealType.value != 'Rental' && (
          <>
            <Grid item xs={6}>
              {createDateField({
                label: 'Sold Date',
                fieldName: 'saleDate',
                testId: 'saleDate',
                format: 'MM/dd/yyyy',
                store: formStore,
              })}
            </Grid>
            <Grid item xs={6}>
              {createCurrencyField({
                label: 'Sold Price',
                fieldName: 'salePrice',
                testId: 'salePrice',
                store: formStore,
              })}
            </Grid>
          </>
        )}
        <Grid item xs={6}>
          {createCurrencyField({
            label: 'Rehab Budget',
            fieldName: 'rehabBudget',
            testId: 'rehabBudget',
            store: formStore,
          })}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <FileUploadArea
          items={
            store.currentDocumentsToUpload
          }
          setItems={(updateFunc) => {
            const updatedItems = updateFunc(store.currentDocumentsToUpload);
            store.currentDocumentsToUpload = updatedItems;
          }}
          multiple
        />
      </Grid>
      <Grid container justifyContent='flex-end'>
        <Button
          variant='outlined'
          color='primary'
          onClick={() => { store.resetStore() }}
          testId='removeProperty'
          className={classes.button}
        >
          Cancel
        </Button>
        <Button
          variant='outlined'
          color='primary'
          onClick={() => { store.deleteTrackRecordProperty() }}
          testId='removeProperty'
          className={classes.button}
        >
          Remove
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => { store.saveTrackRecordProperty() }}
          testId='saveProperty'
          className={classes.button}
        >
          Save
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Divider variant="fullWidth" />
      </Grid>
    </Grid>
  );
});