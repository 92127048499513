import { observer } from "mobx-react";
import { Grid } from "@material-ui/core";
import { createAutocompleteAddressField, createPhoneField, createTextField } from "@roc/ui";
import { TitleAgentWimbaFormStore } from "../../../stores/documentForms/titleAgent/titleAgentWimbaFormStore";

interface TitleAgentProps {
  store: TitleAgentWimbaFormStore;
  disabled: boolean;
}

const TitleAgentWimbaForm = observer((props: TitleAgentProps)=>{
  const {store, disabled} = props;

  const handleAddressChange = event => {
    const { value, fullInfo } = event?.target || {};
    const { address_components, geometry } = fullInfo || {};
    store.saveAddressField(value, address_components, geometry);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={4}>
        {createTextField({
          store,
          testId: 'name',
          fieldName: 'name',
          label: 'Name',
          disabled,
        })}
      </Grid>
      <Grid item xs={12} md={4}>
        {createTextField({
          store,
          testId: 'contactName',
          fieldName: 'contactName',
          label: 'Contact Name',
          disabled,
        })}
      </Grid>
      <Grid item xs={12} md={4}>
        {createPhoneField({
          store,
          testId: 'phone',
          fieldName: 'phone',
          label: 'Cellphone Number',
          disabled,
        })}
      </Grid>
      <Grid item xs={12} md={8}>
        {createAutocompleteAddressField({
          store,
          testId: 'address',
          fieldName: 'address',
          label: 'Address',
          disabled,
          onChange: handleAddressChange,
        })}
      </Grid>
      <Grid item xs={12} md={4}>
        {createTextField({
          store,
          testId: 'email',
          fieldName: 'email',
          type: 'email',
          label: 'Email Address',
          disabled,
        })}
      </Grid>
    </Grid>
  )
})

export default TitleAgentWimbaForm;