import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import { Help } from '@material-ui/icons';

const useStyles = makeStyles({
  tooltip: {
    backgroundColor: '#f5f5f5',
    color: 'black',
    maxWidth: 'none',
  },
  table: {
    minWidth: 700,
  },
  iconButton: {
    "&:hover": {
      backgroundColor: "transparent",
    }
  },
  icon: {
    color: '#285391',
    fontSize: 20,
  }
});

interface RowData {
  score: string;
  whatCounts: string;
}

interface TooltipTableProps {
  data: RowData[];
  headers: string[];
}

const TooltipTable: React.FC<TooltipTableProps> = ({ data, headers }) => {
  const classes = useStyles();
  return (
    <Table className={classes.table} size="small">
      <TableHead>
        <TableRow>
          {headers.map((header, index) => (
            <TableCell key={index}>{header}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((row, rowIndex) => (
          <TableRow key={rowIndex}>
            <TableCell>{row.score}</TableCell>
            <TableCell style={{ whiteSpace: 'pre-line' }}>{row.whatCounts}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

interface TooltipWithTableProps {
  tableData: RowData[];
  tableHeaders: string[];
}

const TooltipWithTable: React.FC<TooltipWithTableProps> = ({ tableData, tableHeaders }) => {
  const classes = useStyles();
  return (
    <Tooltip
      title={<TooltipTable data={tableData} headers={tableHeaders} />}
      classes={{ tooltip: classes.tooltip }}
    >
      <IconButton className={classes.iconButton}>
        <Help className={classes.icon}/>
      </IconButton>
    </Tooltip>
  );
};

export default TooltipWithTable;
