import {
  FormStore,
  GENERIC_VALIDATION_NOTIFICATION,
  GlobalStore,
} from '@roc/feature-app-core';
import { GENERIC_ERROR_MESSAGE, getTemporaryId } from '@roc/feature-utils';
import { DialogState } from '@roc/ui';
import { action, computed, flow, makeObservable, observable } from 'mobx';
import { NewLoanApplicationService } from '../services/newLoanApplicationService';
import { LoanApplicationBorrower } from '../utils/loanApplicationTypes';

const borrowerForm = {
  fields: {
    borrowerId: {
      value: '',
    },
    firstName: {
      value: '',
      error: null,
      rule: 'required',
      attribute: 'first name',
    },
    lastName: {
      value: '',
      error: null,
      rule: 'required',
      attribute: 'last name',
    },
    emailAddress: {
      value: '',
      error: null,
      rule: 'required|email',
      attribute: 'email address',
    },
    confirmEmailAddress: {
      value: '',
      error: null,
      rule: 'required|same:emailAddress',
      message: 'The email address does not match.',
    },
    cellPhone: {
      value: '',
      error: null,
      rule: 'required|regex:/^\\d{3}-?\\d{3}-?\\d{4}$/|alpha_dash',
      message: 'Phone must be a number with the format xxx-xxx-xxxx.',
    },
    pctOwnership: {
      value: '',
      error: null,
      rule: '',
    },
    experienceProvidedAtOrigination: {
      value: '',
      error: null,
      rule: '',
    },
    ficoProvidedAtOrigination: {
      value: '',
      error: null,
      rule: '',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

const getBlankBorrower = (): LoanApplicationBorrower => {
  const tmpBorrower: unknown = { borrowerId: getTemporaryId() };
  return tmpBorrower as LoanApplicationBorrower;
};
export class BorrowersStore {
  private globalStore: GlobalStore;
  private newLoanApplicationService: NewLoanApplicationService;

  addBorrowerForm: FormStore;
  editBorrowerForms: FormStore[] = [];

  userBorrower: LoanApplicationBorrower;
  dialogState: DialogState;
  borrowers: LoanApplicationBorrower[] = [];

  constructor(globalStore) {
    this.globalStore = globalStore;
    this.newLoanApplicationService = new NewLoanApplicationService();

    this.addBorrowerForm = new FormStore(borrowerForm, globalStore);

    makeObservable(this, {
      userBorrower: observable,
      dialogState: observable,
      borrowers: observable,
      editBorrowerForms: observable,
      reset: action,
      openAddBorrower: action,
      saveAddBorrower: action,
      openEditBorrowers: action,
      saveEditBorrowers: action,
      removeCoBorrower: action,
      closeDialog: action,
      fetchCurrentUserBorrower: flow,
      totalOwnership: computed,
      showTotalOwnershipWarning: computed,
    });
  }

  reset() {
    this.addBorrowerForm.reset();
    this.editBorrowerForms = [];
  }

  *fetchCurrentUserBorrower() {
    try {
      const response = yield this.newLoanApplicationService.getCurrentUserBorrower();
      const userBorrower: LoanApplicationBorrower = response.data.data;
      this.userBorrower = userBorrower;
      this.borrowers = [userBorrower];
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE,
      });
    }
  }

  openAddBorrower() {
    this.addBorrowerForm.reset();
    this.addBorrowerForm.loadForm(getBlankBorrower());
    this.dialogState = DialogState.ADD;
  }

  saveAddBorrower() {
    if (this.addBorrowerForm.runFormValidationWithMessage()) {
      const borrower = this.addBorrowerForm.getFormValues();
      this.borrowers = this.borrowers.concat([borrower]);
      this.closeDialog();
    }
  }

  openEditBorrowers() {
    this.editBorrowerForms = this.borrowers.map(borrower => {
      const form = new FormStore(borrowerForm, this.globalStore);
      form.loadForm(this.mapBorrower(borrower));
      return form;
    });
    this.dialogState = DialogState.EDIT;
  }

  saveEditBorrowers() {
    if (this.runAllEditFormValidationsWithMessage()) {
      this.borrowers = this.editBorrowerForms.map(formStore =>
        formStore.getFormValues()
      );
      this.closeDialog();
    }
  }

  removeCoBorrower(formStore: FormStore) {
    this.editBorrowerForms = this.editBorrowerForms.filter(x => x != formStore);
  }

  closeDialog() {
    this.dialogState = null;
  }

  setBorrowers(borrowers: LoanApplicationBorrower[]) {
    const isUserBorrower = b => this.userBorrower.borrowerId === b.borrowerId;
    let currentUserBorrower = borrowers.find(b => isUserBorrower(b));
    if (!currentUserBorrower) {
      currentUserBorrower = this.userBorrower;
    }
    const otherBorrowers = borrowers.filter(b => !isUserBorrower(b));
    this.borrowers = [currentUserBorrower, ...otherBorrowers];
  }

  private mapBorrower(borrower: LoanApplicationBorrower) {
    return {
      ...borrower,
      confirmEmailAddress: borrower.emailAddress,
    };
  }

  private runAllEditFormValidationsWithMessage() {
    this.editBorrowerForms.forEach(formStore => formStore.runFormValidation());
    const isValid = this.editBorrowerForms.every(s => s.form.meta.isValid);
    if (!isValid) {
      this.globalStore.notificationStore.showWarningNotification({
        message: GENERIC_VALIDATION_NOTIFICATION,
      });
    }
    return isValid;
  }

  get totalOwnership() {
    return this.borrowers.reduce((total, item) => total + (item.pctOwnership || 0), 0);
  }

  get showTotalOwnershipWarning() {
    return this.totalOwnership !== 100 && this.borrowers.length > 1;
  }
}
