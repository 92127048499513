import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  createRadioField,
  createSliderField,
  createSelectField,
  createTextField
} from '@roc/ui/componentBuilder';
import { DialogState, EditableDialog } from '@roc/ui';
import { observer } from 'mobx-react';
import React from 'react';
import { useDocumentStore } from '../../../hooks/useDocumentStore';
import {
  borrowerStatesNames,
  borrowerStatesAbbreviation,
} from '@roc/feature-utils';

const useStyles = makeStyles(theme => ({
  slider: {
    marginTop: '2.5em',
  },
}));

const yesNoOptions = [
  {
    label: 'Yes',
    value: 'Yes',
  },
  {
    label: 'No',
    value: 'No',
  },
];

const percentageMarks = [
  {
    value: 0,
    label: '0%',
  },
  {
    value: 100,
    label: '100%',
  },
];
const stateOptions = borrowerStatesAbbreviation.map((abbreviation, i) => ({
  value: abbreviation,
  label: borrowerStatesNames[i],
}));

export const EditBorrowerDialog = observer(() => {
  const classes = useStyles();
  const { documentStore } = useDocumentStore();
  const { borrowerEntityStore } = documentStore.documentFormStore;
  const store = borrowerEntityStore.editEntityBorrowerStore;

  const onSubmit = async () => {
    borrowerEntityStore.saveBorrower();
  };

  const dialogContent = (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        {createSliderField({
          store,
          fieldName: 'pctOwnership',
          label: 'Ownership percentage',
          className: classes.slider,
          valueLabelDisplay: 'on',
          marks: percentageMarks,
          step: 1,
          valueLabelFormat: value => <div>{value}%</div>,
          testId: 'percentOwnershipSlider',
        })}
      </Grid>
      <Grid item xs={12}>
        {createTextField({
          store,
          testId: 'roleInEntity',
          fieldName: 'roleInEntity',
          label: 'Title',
        })}
      </Grid>
      <Grid item xs={12}>
        {createRadioField({
          store,
          testId: 'authSignatory',
          fieldName: 'authSignatory',
          label: 'Authorized Signatory',
          options: yesNoOptions,
          row: true,
        })}
      </Grid>
      <Grid item xs={12}>
        {createRadioField({
          store,
          testId: 'personalGuarantor',
          fieldName: 'personalGuarantor',
          label: 'Personal Guarantor',
          options: yesNoOptions,
          row: true,
        })}
      </Grid>
      <Grid item xs={12}>
        {createSelectField({
          store,
          testId: 'stateForSigning',
          fieldName: 'stateForSigning',
          label: 'Signing State',
          options: stateOptions,
        })}
      </Grid>
      <Grid item xs={12}>
        {createTextField({
          store,
          testId: 'countyForSigning',
          fieldName: 'countyForSigning',
          label: 'Signing County',
        })}
      </Grid>
    </Grid>
  );

  return (
    <EditableDialog
      title={'Borrower: ' + store.entityBorrower?.fullName}
      open={store.open}
      handleClose={() => store.closeDialog()}
      dialogState={DialogState.EDIT}
      handleAdd={() => { }}
      handleEdit={onSubmit}
      dialogContent={dialogContent}
    />
  );
});

export default EditBorrowerDialog;
