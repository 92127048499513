import { QuoteTypes, quoteTypeOptions } from '@roc/feature-loans';
import { SubmitButton, SelectField, SubmitMenuItem } from '@roc/ui';
import { isNotBlank } from '@roc/feature-utils';
//import { useLoanStore } from 'libs/feature-loans-shared/src/hooks/useLoanStore';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';

export const RequestElmsureQuote = observer(({ loanStore }) => {
  const { loanId } = loanStore.loanDetails;
    const [
    selectQuoteType,
    setSelectQuoteType,
  ] = useState<QuoteTypes>();

  useEffect(() => {
    setSelectQuoteType(QuoteTypes.FULL_QUOTE);
  }, []);

  return (
     <SubmitButton
          size="small"
          variant="contained"
          color="primary"
          testId="requestElmsureQuote"
            onClick={() => {
              if (selectQuoteType) {
                loanStore.requestElmsureQuote(loanId, selectQuoteType);
              }
            }}
            submitDialogProps={{
              title: `Loan ${loanId}`,
              body: (
                <SelectField
                  standaloneLabel
                  label={'Please select the quote type:'}
                  value={selectQuoteType}
                  options={quoteTypeOptions}
                  onChange={value => setSelectQuoteType(value)}
                  variant={'outlined'}
                  fullWidth
                  testId={'select-quoteType-dropdown'}
                />
              ),
              okButtonText: 'Submit',
              cancelButtonText: 'Close',
            }}
          >
           REQUEST ELMSURE QUOTE
          </SubmitButton>
  );
});
