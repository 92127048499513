import { Page } from '@roc/feature-app-core';
import { Route } from 'react-router';
import { SendEmailsByOwner } from '../components/sendEmailsByOwner';


export const getEmailsRoutesConfig = (basePath: string, baseUrl: string) => {
  return {
    emails: {
      path: `${basePath}/emails`,
      url: `${baseUrl}/emails`,
      documentTitle: 'Emails',
    }
  };
}

export const getEmailsRoutes = (basePath: string, baseUrl: string) => {
  const config = getEmailsRoutesConfig(basePath, baseUrl);
  return {
    emails: (
      <Route exact path={config.emails.path}>
        <Page routeDefinition={config.emails}>
          <SendEmailsByOwner />
        </Page>
      </Route>
    )
  };
}