import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import {
  AssignmentTurnedIn, Business,
  Description, Person,
  Room, LocalOffer
} from '@material-ui/icons';
import { LoanSuccess } from '@roc/feature-loans';
import { Layout, useBaseStore, useUserStore } from '@roc/feature-app-core';
import { Paper, StandardDialog, StepsContainer } from '@roc/ui';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import BorrowerInformationTerm from '../components/borrowerInformationTerm';
import { BrokerInformation } from '../components/brokerInformation';
import EntityInformation from '../components/entityInformation';
import LoanDetailsTerm from '../components/loanDetailsTerm';
import LoanSummary from '../components/loanSummary';
import PricerSummaryTerm from '../components/pricerSummaryTerm';
import { useLoanSubmissionRoutes } from '../hooks/useLoanSubmissionRoutes';
import { useSinglePropertyStore } from '../hooks/useSinglePropertyStore';
import LoanPricerSingleProperty from './components/loanPricerSingleProperty';
import { PropertyInformationSingleProperty } from './components/propertyInformationSingleProperty';
import { DRAFT_LOAN_TYPE_LOAN, PURCHASE, REJECTED_STATE, REQUEST_LOAN_TYPE_LOAN } from '@roc/feature-utils';
import { useRoutes } from '@roc/client-portal-shared/hooks';
import { RejectRequestForm } from '../components/rejectRequestForm';
import LoanRequestSuccess from '../../loans/components/loanSuccess/loanRequestSuccess';
import { insertIf } from '@roc/client-portal-shared/utils';
import { LoanSubmissionStep } from '../utils/constants';
import PricerSummaryTerm_new from '../components/pricerSummaryTerm_new';
import { LoanDocuments } from '../components/loanDocuments';
import SelectPromotionGrid from '../components/selectPromotionGrid';

const useStyles = makeStyles(theme =>
  createStyles({
    paper: {
      padding: theme.spacing(4),
    },
  })
);

export const SingleProperty = observer(() => {
  const { singlePropertyStore } = useSinglePropertyStore();
  const classes = useStyles();
  const { push } = useHistory();
  const { loanSubmissionRoutesConfig } = useLoanSubmissionRoutes();
  const { globalStore } = useBaseStore();
  const { userStore } = useUserStore();
  const { dashboardRoutesConfig } = useRoutes();
  const [openRejectReason, setOpenRejectReason] = useState(false);
  const allowLoanPricing = userStore.allowLoanPricing;
  const isBroker = globalStore.userFeatures.isBroker;
  const isLenderOriginator = globalStore.userFeatures.isLenderOriginator;
  const showBrokerInformation = !isBroker;
  const showDocuments = globalStore.userFeatures.uploadDuringSubmissionEnabled;
  const { marketingPromotionsStore } = singlePropertyStore;

  useEffect(() => {
    marketingPromotionsStore.fetchGridData();
  }, []);

  useEffect(() => {
    singlePropertyStore.loadStore();
  }, [singlePropertyStore]);

  const onSubmit = () => {
    const isAllStepsValid = singlePropertyStore.allErrors.length === 0;
    if (singlePropertyStore.allErrors.length > 0) {
      globalStore.notificationStore.showErrorNotification({
        message: 'Please correct the errors listed above',
      });
    }

    if (isAllStepsValid) {
      if (singlePropertyStore.pricerStore.form.fields['isPropertyLeased'].value === 'N' &&
        singlePropertyStore.pricerStore.form.fields['loanPurpose'].value === PURCHASE)
        singlePropertyStore.pricerStore.onFieldChange('monthlyGrossRent', Number(singlePropertyStore.pricerStore.form.fields['monthlyGrossRent'].value) * 0.9);
      singlePropertyStore.onLoanSubmit();
    }
  };

  const onSave = (showSuccessNotification = true) => {
    return singlePropertyStore.onLoanSave(DRAFT_LOAN_TYPE_LOAN, showSuccessNotification);
  };

  const onSaveRequest = (showSuccessNotification = true) => {
    return singlePropertyStore.onLoanSave(REQUEST_LOAN_TYPE_LOAN, showSuccessNotification);
    //return singlePropertyStore.onLoanApplicationSave();
  };

  const saveDraftLoan = (showSuccessNotification = true) => {
    return singlePropertyStore.isLoanRequest ? onSaveRequest(showSuccessNotification) : onSave(showSuccessNotification)
  }

  const onRejectRequest = () => {
    setOpenRejectReason(true);
  }

  const rejectRequest = () => {
    singlePropertyStore.draftLoanInfo.status = REJECTED_STATE
    singlePropertyStore.onLoanSave(REQUEST_LOAN_TYPE_LOAN);
    //singlePropertyStore.rejectLoanApplication();
    push(
      dashboardRoutesConfig.dashboard.url
    )
  }

  const onReprice = () => {
    singlePropertyStore.onReprice();
  };

  const goNextStep = () => {
    const currentStep = loanSubmissionSteps[singlePropertyStore.activeStep].type;
    singlePropertyStore.goNextStep(currentStep);
  }

  const goPrevStep = () => {
    const currentStep = loanSubmissionSteps[singlePropertyStore.activeStep].type;
    singlePropertyStore.goPrevStep(currentStep);
  }

  const moveToStep = (stepIndex) => {
    const currentStep = loanSubmissionSteps[singlePropertyStore.activeStep].type;
    singlePropertyStore.moveToStep(stepIndex, currentStep);
  }

  const setPromotion = (promotionId: number) => {
    singlePropertyStore.loanDetailsStore.onFieldChange('marketingPromotionId', promotionId);
  }

  const loanSubmissionSteps = [
    // {
    //   title: 'Loan Pricer Summary',
    //   icon: <Description color="primary" fontSize="large" />,
    //   type: LoanSubmissionStep.LOAN_PRICER_SUMMARY_NEW,
    //   onBack: () => { },
    //   onNext: () => goNextStep(),
    //   onSave: () => { },
    //   onReject: () => { },
    //   allowBack: false,
    //   allowNext: false,
    //   allowSave: true,
    //   allowReprice: false,
    //   allowReject: singlePropertyStore.isLoanRequest,
    // },
    {
      title: 'Loan Pricer',
      icon: <Room color="primary" fontSize="large" />,
      type: LoanSubmissionStep.LOAN_PRICER,
      onBack: () => push(loanSubmissionRoutesConfig.submitloan.url),
      onNext: () => goNextStep(),
      onSave: () => saveDraftLoan(),
      onReject: () => onRejectRequest(),
      allowBack: true,
      allowNext: true,
      allowSave: !singlePropertyStore.isBorrowerLoanApplication,
      allowReprice: false,
      allowReject: singlePropertyStore.isLoanRequest || singlePropertyStore.isBorrowerLoanApplication,
    },
    ...insertIf(
      isBroker || allowLoanPricing || isLenderOriginator,
      [
        {
          title: 'Loan Pricer Summary',
          icon: <Description color="primary" fontSize="large" />,
          type: LoanSubmissionStep.LOAN_PRICER_SUMMARY,
          onBack: () => goPrevStep(),
          onNext: () => goNextStep(),
          onSave: () => saveDraftLoan(),
          onReject: () => onRejectRequest(),
          allowBack: true,
          allowNext: false,
          allowSave: !singlePropertyStore.isBorrowerLoanApplication,
          allowReprice: false,
          allowReject: singlePropertyStore.isLoanRequest || singlePropertyStore.isBorrowerLoanApplication,
        },
      ]
    ),
    ...insertIf(showBrokerInformation, [{
      title: 'Broker Information',
      icon: <Person color="primary" fontSize="large" />,
      type: LoanSubmissionStep.BROKER_INFORMATION,
      onBack: () => goPrevStep(),
      onNext: () => goNextStep(),
      onSave: () => saveDraftLoan(),
      onReject: () => onRejectRequest(),
      onReprice: () => onReprice(),
      allowBack: true,
      allowNext: true,
      allowSave: !singlePropertyStore.isBorrowerLoanApplication,
      allowReprice: true,
      allowReject: singlePropertyStore.isLoanRequest || singlePropertyStore.isBorrowerLoanApplication,
    }]),
    {
      title: 'Entity Information',
      icon: <Business color="primary" fontSize="large" />,
      type: LoanSubmissionStep.ENTITY_INFORMATION,
      onBack: () => goPrevStep(),
      onNext: () => {
        goNextStep();
      },
      onSave: () => saveDraftLoan(),
      onReject: () => onRejectRequest(),
      onReprice: () => onReprice(),
      allowBack: true,
      allowNext: true,
      allowSave: !singlePropertyStore.isBorrowerLoanApplication,
      allowReprice: true,
      allowReject: singlePropertyStore.isLoanRequest || singlePropertyStore.isBorrowerLoanApplication,
    },
    {
      title: 'Borrower Information',
      icon: <Person color="primary" fontSize="large" />,
      type: LoanSubmissionStep.BORROWER_INFORMATION,
      onBack: () => goPrevStep(),
      onNext: () => goNextStep(),
      onSave: () => saveDraftLoan(),
      onReject: () => onRejectRequest(),
      onReprice: () => onReprice(),
      allowBack: true,
      allowNext: true,
      allowSave: !singlePropertyStore.isBorrowerLoanApplication,
      allowReprice: true,
      allowReject: singlePropertyStore.isLoanRequest || singlePropertyStore.isBorrowerLoanApplication,
    },
    {
      title: 'Property Information',
      icon: <Business color="primary" fontSize="large" />,
      type: LoanSubmissionStep.PROPERTY_INFORMATION,
      onBack: () => goPrevStep(),
      onNext: () => goNextStep(),
      onSave: () => saveDraftLoan(),
      onReject: () => onRejectRequest(),
      onReprice: () => onReprice(),
      allowBack: true,
      allowNext: true,
      allowSave: !singlePropertyStore.isBorrowerLoanApplication,
      allowReprice: true,
      allowReject: singlePropertyStore.isLoanRequest || singlePropertyStore.isBorrowerLoanApplication,
    },
    {
      title: 'Loan Details',
      icon: <Business color="primary" fontSize="large" />,
      type: LoanSubmissionStep.LOAN_DETAILS,
      onBack: () => goPrevStep(),
      onNext: () => {
        goNextStep()
      },
      onSave: () => saveDraftLoan(),
      onReject: () => onRejectRequest(),
      onReprice: () => onReprice(),
      allowBack: true,
      allowNext: true,
      allowSave: !singlePropertyStore.isBorrowerLoanApplication,
      allowReprice: true,
      allowReject: singlePropertyStore.isLoanRequest || singlePropertyStore.isBorrowerLoanApplication,
    },
    ...insertIf(globalStore.userFeatures.uploadDuringSubmissionEnabled, [{
      title: 'Documents',
      icon: <Description color="primary" fontSize="large" />,
      type: LoanSubmissionStep.LOAN_DOCUMENTS,
      onBack: () => {
        goPrevStep();
      },
      onNext: () => {
        goNextStep();
      },
      onReject: () => onRejectRequest(),
      onSave: () => saveDraftLoan(),
      allowBack: true,
      allowNext: true,
      allowSave: !singlePropertyStore.isBorrowerLoanApplication,
      allowReject: singlePropertyStore.isLoanRequest || singlePropertyStore.isBorrowerLoanApplication,
    }]),
    ...insertIf(marketingPromotionsStore.showPromotions, [{
      title: 'Promotions',
      icon: <LocalOffer color="primary" fontSize="large" />,
      type: LoanSubmissionStep.PROMOTIONS,
      onBack: () => {
        goPrevStep();
      },
      onNext: () => {
        goNextStep();
      },
      onReject: () => onRejectRequest(),
      onSave: () => saveDraftLoan(),
      allowBack: true,
      allowNext: true,
      allowSave: true,
      allowReject: singlePropertyStore.isLoanRequest,
    }]),
    {
      title: 'Summary',
      icon: <AssignmentTurnedIn color="primary" fontSize="large" />,
      type: LoanSubmissionStep.SUMMARY,
      onBack: () => goPrevStep(),
      onNext: () => userStore?.allowLoanPricing ? onSubmit() : onSaveRequest(),
      onSave: () => saveDraftLoan(),
      onReject: () => onRejectRequest(),
      onReprice: () => onReprice(),
      allowBack: true,
      allowNext: true,
      allowSave: !singlePropertyStore.isBorrowerLoanApplication,
      allowReprice: true,
      allowReject: singlePropertyStore.isLoanRequest || singlePropertyStore.isBorrowerLoanApplication,
      disableNextButton: singlePropertyStore.disableSubmitButton,
    },
  ];

  const rejectLoanBorrowerApplicationRequest = () => {
    singlePropertyStore.rejectLoanApplication();
    push(
      dashboardRoutesConfig.dashboard.url
    )
  }

  const { loanDetailsStore } = singlePropertyStore;
  return (
    <Layout title="Submit Single Property Rental Loan">
      <Paper className={classes.paper}>
        {singlePropertyStore.saved ? (
          <>
            <LoanSuccess
              loanId={singlePropertyStore.savedLoanId}
              showTermsheetSection={true}
              showDocsAndNewLoanButtons
              onStartAnotherLoan={() => {
                singlePropertyStore.reset();
                push(
                  loanSubmissionRoutesConfig.submitloan.children.singleProperty
                    .url
                );
              }}
              hasFastTrack={singlePropertyStore.hasFastTrack}
              isFastTrackPaymentComplete={singlePropertyStore.isFastTrackPaymentComplete}
              fastTrackStore={loanDetailsStore}
              sendPaymentEmail={() => singlePropertyStore.sendFastTrackPayment()}
              propertiesQuantity={singlePropertyStore.properties.length || 1}
              fastTrackEnabled={globalStore.userFeatures.fastTrack}
            />
          </>
        ) : singlePropertyStore.requestSaved ? (
          <LoanRequestSuccess
            loanRequestId={singlePropertyStore?.draftLoanInfo?.draftLoanId}//loanRequestId={singlePropertyStore?.savedLoanId}
            onStartAnotherLoan={() => {
              singlePropertyStore.reset();
              push(loanSubmissionRoutesConfig.submitloan.children.singleProperty.url);
            }}
          />
        ) : (
          <>
            <StepsContainer
              activeStep={singlePropertyStore.activeStep}
              handleStep={moveToStep}
              steps={loanSubmissionSteps}
              scrollToTopOnStepChange={true}
            >
              {[
                // <PricerSummaryTerm_new store={singlePropertyStore} />,
                <LoanPricerSingleProperty store={singlePropertyStore} />,
                ...insertIf(isBroker || allowLoanPricing || isLenderOriginator, [<PricerSummaryTerm_new store={singlePropertyStore} onNext={() => goNextStep()} />]),
                // ...insertIf(isBroker || allowLoanPricing, [<PricerSummaryTerm store={singlePropertyStore} />]),
                ...insertIf(showBrokerInformation, [<BrokerInformation />]),
                <EntityInformation store={singlePropertyStore} />,
                <BorrowerInformationTerm store={singlePropertyStore} />,
                <PropertyInformationSingleProperty store={singlePropertyStore} />,
                <LoanDetailsTerm store={singlePropertyStore} />,
                ...insertIf(showDocuments, [<LoanDocuments store={singlePropertyStore} saveDraftLoan={() => saveDraftLoan(false)} />]),
                ...insertIf(marketingPromotionsStore.showPromotions, [<SelectPromotionGrid store={marketingPromotionsStore.marketingPromotionsGridStore} promotionData={{ setPromotion: setPromotion, promotionId: singlePropertyStore.loanDetailsStore.getValue('marketingPromotionId') }}
                />]),
                <LoanSummary store={singlePropertyStore} />
              ]}
            </StepsContainer>
          </>
        )}
      </Paper>
      <StandardDialog
        open={openRejectReason}
        title="Reject Request Reason"
        maxWidth="sm"
        handleClose={() => setOpenRejectReason(false)}
        dialogContent={
          <RejectRequestForm onConfirm={() => singlePropertyStore.isBorrowerLoanApplication ? rejectLoanBorrowerApplicationRequest() : rejectRequest()} onClose={() => setOpenRejectReason(false)} draftLoan={singlePropertyStore.draftLoanInfo} />
        }
      />
    </Layout>
  );
});
