import { Box, Typography, lighten } from "@material-ui/core";
import { Card } from "@roc/ui";
import { makeStyles } from '@material-ui/core/styles';
import { IconButton } from '@roc/ui';
import { Edit } from '@material-ui/icons';
import { DateFormat, formatDate } from '@roc/client-portal-shared/utils';

const useStyles = makeStyles(theme => ({
  noteCard: {
    borderRadius: 0,
    boxShadow: '0px 2px 3px #EEE'
  },
  noteHeader: {
    padding: '8px 16px',
    paddingBottom: '2px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  noteTime: {
    display: 'block',
    textAlign: 'right',
    color: lighten(theme.palette.text.primary, 0.3)
  }
}));

export const Note = ({ title, onEdit, children, createdDate, lastUpdatedDate, createdBy, lastModifiedBy }) => {
  const classes = useStyles();
  const action = (
    <>
      <IconButton testId="edit" onClick={onEdit}>
        <Edit />
      </IconButton>
    </>
  );

  return (
    <Card
      title={title}
      className={classes.noteCard}
      titleTypographyProps={{ variant: 'h6' }}
      cardHeaderProps={{
        action,
        className: classes.noteHeader,
      }}
      cardContentProps={{ style: { padding: '8px 16px' } }}
    >
      {children}
      {(createdDate || lastUpdatedDate) ? (
        <Box pt={2}>
          <Typography variant="subtitle2" className={classes.noteTime}>
            {createdDate && <>Created by {createdBy} at {formatDate(createdDate, DateFormat.MMDDYYYY)}</>}
            {lastUpdatedDate && <>, last updated by {lastModifiedBy} at {formatDate(lastUpdatedDate, DateFormat.MMDDYYYY)}</>}
          </Typography>
        </Box>
      ) : null}
    </Card>
  );
}