import { Service } from '@roc/feature-app-core';

const url = {
  EXPECTED_CLOSING_DATE:
    '/api/v1/document/formDocument/updateExpectedClosingDate',
};

export class ExpectedClosingDateService extends Service {
  submitExpectedClosingDate(loanId: string, expectedClosingDate: string) {
    return this.put(
      url.EXPECTED_CLOSING_DATE +
      '?loanID=' +
      loanId +
      (expectedClosingDate
        ? `&expectedClosingDate=${expectedClosingDate}`
        : ``),
      {}
    );
  }
}
