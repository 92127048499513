import { Grid } from '@material-ui/core';
import { observer } from 'mobx-react';
import SelectOriginatorsStore from '../stores/selectOriginatorStore';
import {
  LenderName,
  Percentage
} from './components/originatorFormFields';

export type OriginatorFormProps = {
  store: SelectOriginatorsStore;
};

export const OriginatorForm = observer((props: OriginatorFormProps) => {
  return (
    <Grid container spacing={2}>
      <LenderName store={props.store.originatorsStore.originatorFormStore} />
      <Percentage store={props.store.originatorsStore.originatorFormStore} />
    </Grid>
  );
});
