import { Grid, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import { RadioField, trueFalseOptions } from '@roc/ui';
import { BorrowersCard } from './borrowersCard';
import { EntityCard } from './entityCard';
import { PropertiesCard } from './propertiesCard';
import { makeStyles } from '@material-ui/core/styles';
import { RentalPropertiesCard } from './rentalPropertiesCard';

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: '34px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '123.5%',
    letterSpacing: '0.25px',
  },
  body: {
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '175%',
    letterSpacing: '0.15px',
  },
  entityInfoTitle: {
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '166%',
    letterSpacing: '0.4px',
  },
  gridItem: {
    marginLeft: theme.spacing(4),
  },
}));

export const LoanSummary = observer(({ store, rental = false }) => {
  const classes = useStyles();

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h4" className={classes.title}>
          Summary
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5" className={classes.body}>
          Please review you loan application details before submitting.
        </Typography>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <BorrowersCard store={store} />
        </Grid>
        <Grid item xs={12}>
          <EntityCard store={store} />
        </Grid>
        <Grid item xs={12}>
          {rental ? (
            <RentalPropertiesCard store={store} />
          ) : (
            <PropertiesCard store={store} />
          )}
        </Grid>
      </Grid>
    </>
  );
});
