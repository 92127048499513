import { Page } from '@roc/client-portal-shared/components';
import { Route } from 'react-router';
import { ClosingTrackerHome } from '../components/closingTrackerHome';

export const getClosingTrackerRoutesConfig = (
  basePath: string,
  baseUrl: string
) => {
  return {
    closingTrackerHome: {
      path: `${basePath}/backoffice/closing-tracker`,
      url: `${baseUrl}/backoffice/closing-tracker`,
      documentTitle: 'Closing Tracker',
    },
  };
};

export const getClosingTrackerRoutes = (basePath: string, baseUrl: string) => {
  const config = getClosingTrackerRoutesConfig(basePath, baseUrl);

  return {
    closingTrackerHome: (
      <Route exact path={config.closingTrackerHome.path}>
        <Page routeDefinition={config.closingTrackerHome}>
          <ClosingTrackerHome />
        </Page>
      </Route>
    ),
  };
};