import { TableHead, TableRow, TableCell, Checkbox, TableSortLabel, Box, Paper, TableContainer, Table, TableBody, Chip, Grid } from '@material-ui/core';
import { Chat } from '@material-ui/icons';
import { Button, StandardDialog, TextField, CurrencyField, DateField, PercentageField } from '@roc/ui';
import { observer } from "mobx-react";
import { useState, useEffect, useMemo } from 'react';
import { useRocInternalPortalStore } from 'apps/roc-internal-portal/src/app/hooks';
import { format, parseISO } from 'date-fns';

import { debounce } from '@roc/client-portal-shared/utils';

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface IHeadCells {
  id: string;
  disablePadding: boolean;
  label: string;
  alignment: 'left' | 'center' | 'right';
  width?: string | number;

}


const PropertyTableHead = ({ store, onSelectAllClick, order, orderBy, propertyItems, onRequestSort }) => {
  const createSortHandler =
    property => event => {
      onRequestSort(event, property);
    };

  const headCells: IHeadCells[] = [
    {
      id: 'sectionDisplayName',
      disablePadding: true,
      alignment: (store.isInternal ? 'center' : 'left'),
      label: 'Coverage',
      width: '15%'
    },
    {
      id: 'displayName',
      disablePadding: false,
      alignment: 'left',
      label: 'Data Point',
      width: '25%'
    },
    {
      id: 'expectedValue',
      disablePadding: false,
      alignment: 'left',
      label: 'Expected Value',
      width: '30%'
    },
    {
      id: 'ocrValue',
      disablePadding: false,
      alignment: 'left',
      label: 'Value',
      width: '30%'
    },
    {
      id: 'status',
      disablePadding: false,
      alignment: 'center',
      label: 'Match Status',
      width: '15%'
    },
    //{
    //  id: 'comments',
    //  disablePadding: false,
    //  alignment: 'center',
    //  label: 'Comment',
    //},
  ];

  return (
    <TableHead style={!store.isInternal ? { paddingLeft: '50px' } : {}}>
      <TableRow>
        {!store.isInternal && <TableCell padding="checkbox">
          <Checkbox
            color="primary"

            indeterminate={
              propertyItems.some(item => store.selectedItems.includes(item.insuranceReviewDataId)) &&
              propertyItems.some(item => !store.selectedItems.includes(item.insuranceReviewDataId))}

            checked={propertyItems.every(item => store.selectedItems.includes(item.insuranceReviewDataId))}
            onChange={onSelectAllClick}
          />
        </TableCell>}
        {headCells.map((headCell) => (
          <TableCell
            key={`${headCell.id}`}
            align={headCell.alignment ?? 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            width={headCell.width}
            style={{ fontWeight: 'bold' }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" style={{ display: 'none' }}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const StatusChip = ({ status }) => {
  switch (status) {
    case "Needs Attention":
      return (
        <Chip
          size="small"
          label={"Needs Attention"}
          style={{
            color: '#F5B945',
            borderColor: '#F5B945',
            backgroundColor: 'white',
            fontWeight: 700,
            borderStyle: 'solid',
            borderWidth: 1
          }}
        />
      );
    case "Approved":
      return (<Chip
        size="small"
        label={"Approved"}
        style={{
          color: '#89C053',
          borderColor: '#89C053',
          backgroundColor: 'white',
          fontWeight: 700,
          borderStyle: 'solid',
          borderWidth: 1
        }}
      />);
    case "Rejected":
      return (<Chip
        size="small"
        label={"Rejected"}
        style={{
          color: 'red',
          borderColor: 'red',
          backgroundColor: 'white',
          fontWeight: 700,
          borderStyle: 'solid',
          borderWidth: 1
        }}
      />);
    case "Not Applicable":
      return (<Chip
        size="small"
        label={"Not Applicable"}
        style={{
          color: 'grey',
          borderColor: 'grey',
          backgroundColor: 'white',
          fontWeight: 700,
          borderStyle: 'solid',
          borderWidth: 1
        }}
      />);
    default:
      return null;
  }
}

const CommentModalContent = observer(({ onClose, selectedRecord }) => {

  const { insuranceReviewInternalStore } = useRocInternalPortalStore();

  const onChange = value => {
    selectedRecord['comments'] = value;
  }
  const handleSave = () => {
    insuranceReviewInternalStore.updateComment(selectedRecord);
    onClose();
  }

  return (<Grid container justifyContent='flex-end' direction="column">
    <Grid item xs={12}>
      <TextField
        testId="comment"
        multiline
        variant="outlined"
        label=""
        value={selectedRecord.comments}
        onChange={e => onChange(e.target.value)}
        fullWidth
      />
    </Grid>
    <Grid item container xs={12} justifyContent="flex-end" style={{ paddingTop: 16 }}>
      <Grid item style={{ paddingRight: 16 }}>
        <Button
          testId="close-button"
          variant="outlined"
          color="primary"
          onClick={onClose}
        >
          Close
        </Button>
      </Grid>
      <Grid item>
        <Button
          testId="save-button"
          color="primary"
          variant="contained"
          onClick={handleSave}
        >
          Save
        </Button>
      </Grid>
    </Grid>

  </Grid>)
});

export const PropertyTable = observer(({ store, property }) => {
  const [order, setOrder] = useState<string>('asc');
  const [orderBy, setOrderBy] = useState<string>('');
  const [propertyItems, setPropertyItems] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState();
  const [isOpenCommentDialog, setIsOpenCommentDialog] = useState<boolean>(false);


  useEffect(() => {
    const insuranceData = emptyFilter() ? property.insuranceData : property.insuranceData.filter(item => item.status == store.statusFilter);
    setPropertyItems(orderBy ? stableSort(insuranceData, getComparator(order, orderBy)) : insuranceData);
  }, [orderBy, order, store.properties, store.statusFilter]);

  const emptyFilter = () => {
    return !store.statusFilter || store.statusFilter == 'No Filter'
  }

  const openCommentDialog = (ev, record) => {
    ev.preventDefault();
    ev.stopPropagation();
    setSelectedRecord(record);
    setIsOpenCommentDialog(true);
  }

  const handleRequestSort = (
    event,
    property,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      store.selectedItems = store.selectedItems.concat(propertyItems.map(item => item.insuranceReviewDataId));
    } else {
      store.selectedItems = store.selectedItems.filter(item => !propertyItems.map(item => item.insuranceReviewDataId).includes(item));
    }
  };

  const handleClick = (event, id) => {
    if (store.selectedItems.includes(id)) {
      store.selectedItems = store.selectedItems.filter(item => item != id);
    } else {
      store.selectedItems.push(id);
    }
  };

  const isSelected = (id) => store.selectedItems.includes(id);

  return (
    <Box sx={{ width: '100%' }}>
      <Paper>
        <TableContainer>
          <Table>
            <PropertyTableHead
              store={store}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              propertyItems={propertyItems}
            />
            <TableBody>
              {propertyItems.map((row) => {
                const isItemSelected = isSelected(row.insuranceReviewDataId);
                const labelId = `enhanced-table-checkbox-${row.insuranceReviewDataId}`;
                return (
                  <TableRow
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.insuranceReviewDataId}
                    selected={isItemSelected}
                  >
                    {!store.isInternal && <TableCell padding="checkbox">
                      <Checkbox
                        onClick={(event) => handleClick(event, row.insuranceReviewDataId)}
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                      />
                    </TableCell>}
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                      align={store.isInternal ? 'center' : 'left'}
                    >
                      {row.coverageSection}
                    </TableCell>
                    <TableCell align="left">{row.fieldName}</TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                      align={'left'}
                    >
                      {row.expectedValue}
                    </TableCell>
                    <TableCell align="right">
                      <InsuranceFormComponet row={row} readOnly={store.isInternal} store = {store} />
                    </TableCell>
                    <TableCell align="center"><StatusChip status={row.status} /></TableCell>
                    {/*<TableCell align="center">
                      <Chat color="primary" style={{ cursor: 'pointer' }} onClick={ev => openCommentDialog(ev, row)} />
                      </TableCell>*/}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      {/*<StandardDialog
        open={isOpenCommentDialog}
        title="Comment"
        maxWidth="md"
        handleClose={() => setIsOpenCommentDialog(false)}
        dialogContent={
          <CommentModalContent onClose={() => setIsOpenCommentDialog(false)} selectedRecord={selectedRecord} />
        }
      />*/}
    </Box>
  );
});

export const InsuranceFormComponet = ({ row, readOnly, store }) => {

  const [insuranceDataItemValue, setInsuranceDataItemValue] = useState<any>('');

  const func = (propertyId, fieldName, coverageSection) => {
      store.handleItemChange(store.loanId, propertyId, fieldName, coverageSection, 'Line Item Updated');
    };

   const handleChange = useMemo(
      () => debounce(func, 1000),
      []
    );




  useEffect(() => {
    setInsuranceDataItemValue(row.value);
  }, [])

  useEffect(() => {
    row.value = insuranceDataItemValue;
  }, [insuranceDataItemValue]
  );

  if (row.fieldType == 'boolean') {
    return (
      <Checkbox
        disabled={readOnly}
        checked={insuranceDataItemValue}
        value={insuranceDataItemValue}
        onChange={event => {
          setInsuranceDataItemValue(event.target.checked);
          handleChange(row.propertyId, row.fieldName, row.coverageSection)
        }}
      />
    )
  }
  else if (row.fieldType == 'currency') {
    return (
      <CurrencyField
        fullWidth
        disabled={readOnly}
        value={insuranceDataItemValue}
        testId={insuranceDataItemValue}
        onChange={(name, value) => {
          setInsuranceDataItemValue(value);
        }}
        onKeyDown={() => {
           handleChange(row.propertyId, row.fieldName, row.coverageSection);
        }}
      />)
  }
  else if (row.fieldType == 'text') {
    return (
      <TextField
        fullWidth
        disabled={readOnly}
        value={insuranceDataItemValue}
        testId={insuranceDataItemValue}
        onChange={event => {
          setInsuranceDataItemValue(event.target.value);
          handleChange(row.propertyId, row.fieldName, row.coverageSection)
        }}
      />)
  }
  else if (row.fieldType == 'date') {
    return (
      <DateField
        format='MM/dd/yyyy'
        fullWidth
        readOnly={readOnly}
        value={insuranceDataItemValue ? parseISO(insuranceDataItemValue) : null}
        testId={insuranceDataItemValue}
        onChange={date => {
          const formattedDate = format(date, 'yyyy-MM-dd');
          setInsuranceDataItemValue(formattedDate);
          handleChange(row.propertyId, row.fieldName, row.coverageSection )
        }}
      />)
  }
  else if (row.fieldType == 'percentage') {
    return (
      <PercentageField
        disabled={readOnly}
        style={{ width: '50px' }}
        value={insuranceDataItemValue}
        testId={insuranceDataItemValue}
        onChange={(name, value) => {
          setInsuranceDataItemValue(value);
        }}
        onKeyDown={() => {
           handleChange(row.propertyId, row.fieldName, row.coverageSection);
        }}
      />)
  }
  else { return null }
}
