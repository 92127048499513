import { Box, Container } from '@material-ui/core';
import { Footer } from '@roc/ui';
import { observer } from 'mobx-react';
import React from 'react';
import { useHeaderHeight } from '../../modules/portalConfiguration';

export interface BodyLayoutProps {
  hideFooter?: boolean;
  children?: React.ReactNode;
  bodyLayoutContainerClass?: string;
  bodyLayoutContentClass?: string;
  bodyLayoutFooterClass?: string;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
}

export const BodyLayout = observer(({ hideFooter = false, children, bodyLayoutContainerClass, bodyLayoutContentClass, bodyLayoutFooterClass, maxWidth = "xl" }: BodyLayoutProps) => {

  const headerHeight = useHeaderHeight();

  return (
    <Container maxWidth={maxWidth} style={{
      minHeight: `calc(100% - ${headerHeight}px)`,
      display: 'flex',
      flexDirection: 'column',
      padding: 0,
    }}
      className={bodyLayoutContainerClass}>
      <Box display="flex" flexGrow={1} className={bodyLayoutContentClass}>
        {children}
      </Box>
      <Box className={bodyLayoutFooterClass}>
        {
          hideFooter ? null : <Footer />
        }
      </Box>
    </Container>
  );

})