import { Theme, createTheme } from '@material-ui/core/styles';
import {
  AppConfigurations,
  PortalConfiguration,
  PortalConfigurationData,
  ThemeConfiguration,
} from '@roc/feature-types';
import { getPublicDocumentDownloadUrl } from '@roc/feature-utils';
import {
  commonMuiThemeProps,
  commonPalette,
  getMuiThemeOverrides,
} from './common';

export const getAppConfigurations = (
  portalConfigurationResponse: PortalConfiguration,
  isPreviewMode: boolean,
  isLoanChatApp: boolean,
  themeOptions: ThemeConfiguration,
  overridePrimaryColor: string
): AppConfigurations => {
  const {
    host,
    lastUpdatedDate,
    portalConfiguration,
    previewPortalConfiguration,
  } = portalConfigurationResponse;
  const portalConfigurationToUse =
    isPreviewMode && previewPortalConfiguration
      ? previewPortalConfiguration
      : portalConfiguration;
  const {
    generalConfiguration,
    uiThemeConfiguration,
  } = portalConfigurationToUse;
  let muiTheme: Theme;
  if (themeOptions) {
    muiTheme = generateThemeWithThemeConfiguration(themeOptions, portalConfigurationToUse);
  } else {
    muiTheme = generateTheme(portalConfigurationToUse, overridePrimaryColor, isLoanChatApp);
  }
  return {
    gtmTrackingId: '', //not used currently. we can maybe use it later to track cportals separately.
    baseDomain: host,
    siteName: generalConfiguration?.name ?? '',
    muiTheme,
    appLogo: getPublicDocumentDownloadUrl(
      generalConfiguration?.appLogoDocumentId,
      lastUpdatedDate
    ),
    appLogoBackground:
      uiThemeConfiguration?.themeOverrides?.appBar?.logoBackgroundColor,
    favIcon: getPublicDocumentDownloadUrl(
      generalConfiguration?.favIconDocumentId,
      lastUpdatedDate
    ),
    loginBanner: getPublicDocumentDownloadUrl(
      generalConfiguration?.bannerImageDocumentId,
      lastUpdatedDate
    ),
    slogan: generalConfiguration?.slogan ?? '',
    data: {
      fromEmail: generalConfiguration?.fromEmail ?? '',
    },
    fontFamilies: uiThemeConfiguration?.fontFamilies ?? [],
  };
};

const generateThemeWithThemeConfiguration = (themeOptions: ThemeConfiguration, portalConfiguration: PortalConfigurationData): Theme => {
  const { uiThemeConfiguration } = portalConfiguration;

  const primary = uiThemeConfiguration?.palette?.primary;
  const secondary = uiThemeConfiguration?.palette?.secondary;

  if (primary) {
    themeOptions.themeOptions.palette.primary = primary;
  }

  if (secondary) {
    themeOptions.themeOptions.palette.secondary = secondary;
  }

  const muiTheme = createTheme(themeOptions.themeOptions);
  muiTheme.overrides = themeOptions.componentsOverride(muiTheme);
  return muiTheme;
};

const generateTheme = (portalConfiguration: PortalConfigurationData, overridePrimaryColor: string, isLoanChatApp: boolean): Theme => {
  const { uiThemeConfiguration } = portalConfiguration;

  const palette = Object.assign(
    { ...commonPalette },
    { ...uiThemeConfiguration?.palette }
  );

  if (overridePrimaryColor) {
    palette.primary = {
      main: overridePrimaryColor,
    } as any;
  }

  let typography = { ...(uiThemeConfiguration?.typography ?? {}) };
  if (isLoanChatApp) {
    typography = {
      "fontFamily": "Rubik, sans-serif",
      "h1": {
        "fontFamily": "Rubik, sans-serif"
      },
      "h2": {
        "fontFamily": "Rubik, sans-serif"
      },
      "h3": {
        "fontFamily": "Rubik, sans-serif"
      },
      "h4": {
        "fontFamily": "Rubik, sans-serif"
      },
      "h5": {
        "fontFamily": "Rubik, sans-serif"
      },
      "h6": {
        "fontFamily": "Rubik, sans-serif"
      },
      "button": {
        "fontFamily": "Rubik, sans-serif",
        "fontWeight": 400,
        "letterSpacing": 'normal',
        "textTransform": 'capitalize'
      }
    }
  }

  const props = Object.assign({ ...commonMuiThemeProps }, {});

  const overrides = getMuiThemeOverrides(
    palette,
    uiThemeConfiguration?.themeOverrides
  );

  return createTheme({
    palette,
    typography,
    props,
    overrides,
  });
};
