import { observer } from "mobx-react";
import { Box, Button, Divider, Grid, ListItem, ListItemText, Typography } from '@material-ui/core';
import { createAutocompleteAddressField, createCurrencyField, createDateField, createNumberFormatField, createPhoneField, createRadioField, createSelectField, createTextField } from "@roc/ui";
import { YES, NO, isNil, borrowerStatesAbbreviation, borrowerStatesNames } from "@roc/feature-utils";
import { Tooltip } from '@roc/ui';
import HelpIcon from '@material-ui/icons/Help';
import { citizenshipStatusOptions, idTypeOptions } from '@roc/feature-loans';
import { DriversLicenseUpload } from "./driversLicenseUpload";
import { makeStyles, Theme } from '@material-ui/core/styles';
import { format, isValid } from "date-fns";
import { useEffect, useMemo } from 'react';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles((theme: Theme) => {
  return {
    list: {
      padding: '0px'
    },
    netWorth: {
      color: '#48AD25',
      lineHeight: '56px'
    },
    icon: {
      fontSize: '18px',
      marginLeft: '5px',
      marginBottom: '2px'
    },
    infoBox: {
      width: '100%',
      flexDirection: 'column',
    }
  };
});

const stateOptions = borrowerStatesAbbreviation.map((abbreviation, i) => ({
  value: abbreviation,
  label: borrowerStatesNames[i],
}));

const handleNetWorthFieldsChange = (name, value, store) => {
  store.onFieldChange(name, value);
  const netWorth = store.form.fields.assets.value - store.form.fields.liabilities.value;
  store.onFieldChange('netWorth', netWorth);
};

export const PersonalDetails = observer(({ store, isInternal }) => {
  const classes = useStyles();
  const { disableNameFields } = store
  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h5">
          Personal Details
          <Tooltip title="This should be your full legal name as it appears on your government issued ID.">
            <HelpIcon className={classes.icon} fontSize="small" />
          </Tooltip>
        </Typography>
      </Grid>
      {(isInternal || disableNameFields) && <Grid item xs={12}>
        <Box className={classes.infoBox}>
          <Alert severity="info">
            Please note you cannot change the first name and last name. If you feel the first name and last name is wrong, please reach out to your sales representative and he will work to change the name. Please note that you should fill out the information related to the person listed here only
          </Alert>
        </Box>
      </Grid>}
      <Grid item xs={12} sm={6}>
        {createTextField({
          label: 'First Name',
          fieldName: 'firstName',
          testId: 'firstName',
          store: store,
          disabled: isInternal || disableNameFields
        })}
      </Grid>
      <Grid item xs={12} sm={6}>
        {createTextField({
          label: 'Middle Name',
          fieldName: 'middleName',
          testId: 'middleName',
          store: store,
          disabled: isInternal || disableNameFields,
        })}
      </Grid>
      <Grid item xs={12} sm={6}>
        {createTextField({
          label: 'Last Name',
          fieldName: 'lastName',
          testId: 'lastName',
          store: store,
          disabled: isInternal || disableNameFields,
        })}
      </Grid>
      <PhoneNumber store={store} isInternal={isInternal} />
    </>
  )
});

export const Identification = observer(({ store, isInternal }) => {
  const showPassportFields = useMemo(() => {
    return store.form.fields.idType.value !== 'US Driver\'s License / Government ID';
  }, [store.form.fields.idType.value]);

  const uploadImageTitle = useMemo(() => {
    if (showPassportFields) return "Document Upload - Document ID Image*";
    return "Document Upload - Driver's License Image*";
  }, [showPassportFields]);

  useEffect(() => {
    store.onFieldChange('driversLicense', showPassportFields ? NO : YES);
  }, [showPassportFields]);

  const manageDate = (fieldName, date) => {
    let formattedDate = null;
    if (isValid(date)) {
      formattedDate = format(date, 'MM/dd/yyyy');
    }
    store.onFieldChange(fieldName, formattedDate);
  };

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h5">Identification</Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        {createDateField({
          label: 'Date of Birth',
          fieldName: 'dateOfBirth',
          testId: 'dateOfBirth',
          format: 'MM/dd/yyyy',
          store: store,
          disableFuture: true,
          disabled: isInternal,
          onChange: manageDate,
        })}
      </Grid>
      <Grid item xs={12} sm={6}>
        {createSelectField({
          store: store,
          testId: 'citizenshipStatus',
          fieldName: 'citizenshipStatus',
          label: 'Citizenship Status',
          options: citizenshipStatusOptions,
          placeholder: 'Make Selection',
          disabled: isInternal,
        })}
      </Grid>
      <Grid item xs={12} sm={6}>
        {createNumberFormatField({
          label: 'Social Security Number',
          fieldName: 'ssn',
          format: "###-##-####",
          testId: 'ssn',
          store: store,
          disabled: isInternal,
        })}
      </Grid>
      <Grid item xs={12} sm={6}>
        {createSelectField({
          store: store,
          testId: 'idType',
          fieldName: 'idType',
          label: 'ID Type',
          options: idTypeOptions,
          placeholder: 'Make Selection',
          disabled: isInternal,
        })}
      </Grid>
      {showPassportFields ? (
        <>
          <Grid item xs={12} sm={6}>
            {createTextField({
              store: store,
              testId: 'passportNumber',
              fieldName: 'passportNumber',
              label: 'Document Number',
              disabled: isInternal,
            })}
          </Grid>
          <Grid item xs={12} sm={6}>
            {createDateField({
              label: 'Document Expiration',
              fieldName: 'passportExpiration',
              testId: 'passportExpiration',
              format: 'MM/dd/yyyy',
              store: store,
              disabled: isInternal,
            })}
          </Grid>
          <Grid item xs={12} />
        </>
      ) : (
        <>
          <Grid item xs={12} sm={6}>
            {createSelectField({
              store,
              testId: 'driversLicenseState',
              fieldName: 'driversLicenseState',
              label: "Driver's License State",
              options: stateOptions,
              disabled: isInternal,
            })}
          </Grid>
          <Grid item xs={12} sm={6}>
            {createTextField({
              store,
              testId: 'driversLicenseNumber',
              fieldName: 'driversLicenseNumber',
              label: "Driver's License Number",
              disabled: isInternal,
            })}
          </Grid>
          <Grid item xs={12} />
        </>
      )}
      {isInternal ? (
        <Grid item xs={6}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            fullWidth
            onClick={() => store.downloanDriversLicense()}
          >
            Download Drivers License
          </Button>
        </Grid>
      ) : (
        <Grid item xs={12}>
          <DriversLicenseUpload
            title={uploadImageTitle}
            file={store.driversLicense}
            onChange={file => store.setDriversLicense(file)}
          />
        </Grid>
      )}
    </>
  )
});

export const Address = observer(({ store, isInternal }) => {

  const handleAddressChange = event => {
    const { value, fullInfo } = event?.target || {};
    const { address_components, geometry } = fullInfo || {};
    store.saveAddressField(value, address_components, geometry);
  };

  return (
    <>
      <Grid item xs={12} sm={6}>
        {createAutocompleteAddressField({
          fieldName: 'address',
          label: 'Address Line 1',
          testId: 'address',
          store: store,
          onChange: handleAddressChange,
          disabled: isInternal,
          showToolTip: false
        })}
      </Grid>
      <Grid item xs={12} />
      <Grid item xs={12} sm={6}>
        {createTextField({
          label: 'Address Line 2',
          fieldName: 'aptNumber',
          testId: 'aptNumber',
          store: store,
          disabled: isInternal,
        })}
      </Grid>
      <Grid item xs={12} />
      <Grid item xs={12} sm={4}>
        {createSelectField({
          store: store,
          testId: 'state',
          fieldName: 'state',
          label: 'State',
          options: stateOptions,
          disabled: isInternal,
        })}
      </Grid>
      <Grid item xs={12} sm={4}>
        {createTextField({
          label: 'City',
          fieldName: 'city',
          testId: 'city',
          store: store,
          disabled: isInternal,
        })}
      </Grid>
      <Grid item xs={12} sm={4}>
        {createTextField({
          label: 'Zip Code',
          fieldName: 'zipCode',
          testId: 'zipCode',
          store: store,
          disabled: isInternal,
        })}
      </Grid>
    </>
  )
});

export const NetworthInformation = observer(({ store, isInternal }) => {
  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h5">Financial Information</Typography>
      </Grid>
      {(!store.uwMatrixEnabled || (store.uwMatrixEnabled && !isNil(store.form.fields.experience.value))) &&
        <Grid item xs={12} container>
          <Grid item xs={12} sm={5}>
            {createNumberFormatField({
              store: store,
              testId: 'experience',
              fieldName: 'experience',
              label:
                'Total number of real estate transactions made in last 5 years',
              placeholder: 'Example: 1',
              disabled: isInternal,
            })}
          </Grid>
        </Grid>
      }
      <Grid item xs={12} sm={4}>
        {createCurrencyField({
          label: 'Assets',
          fieldName: 'assets',
          testId: 'assets',
          store: store,
          disabled: isInternal,
          onChange: ((name, value) => handleNetWorthFieldsChange(name, value, store)),
        })}
      </Grid>
      <Grid item xs={12} sm={4}>
        {createCurrencyField({
          label: 'Liabilities',
          fieldName: 'liabilities',
          testId: 'liabilities',
          store: store,
          disabled: isInternal,
          onChange: ((name, value) => handleNetWorthFieldsChange(name, value, store)),
        })}
      </Grid>
      <Grid item xs={12} sm={4}>
        {createCurrencyField({
          label: 'Net Worth',
          fieldName: 'netWorth',
          testId: 'netWorth',
          store: store,
          disabled: true,
        })}
      </Grid>
    </>
  )
});

export const PhoneNumber = observer(({ store, isInternal }) => {
  return <Grid item xs={12} sm={6}>
    {createPhoneField({
      label: 'Phone Number',
      fieldName: 'phoneNumber',
      testId: 'phoneNumber',
      store: store,
      disabled: isInternal,
    })}
  </Grid>;
});
