import {
  Checkbox,
  InputBase,
  ListItemText,
  Select,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import {
  IFilterComp,
  IFilterParams,
  IFloatingFilterParams,
} from 'ag-grid-community';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { MenuItem } from '../menuItem/menuItem';
import { withTestDataAttribute } from '../testDataAttribute';

const MENU_ITEM_HEIGHT = 54;
const MENU_LIST_PADDING_TOP = 8;
const MENU_LIST_PADDING_BOTTOM = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: MENU_ITEM_HEIGHT * 4 + MENU_LIST_PADDING_TOP + MENU_LIST_PADDING_BOTTOM,
      width: 250,
    },
  },
  MenuListProps: {
    style: {
      paddingTop: MENU_LIST_PADDING_TOP,
      paddingBottom: MENU_LIST_PADDING_BOTTOM,
    },
  },
};

const useStyles = makeStyles(theme => ({
  wrapper: {
    borderRadius: '3px',
    fontFamily: 'inherit',
    fontSize: 'inherit',
    //The width will be determined by flex-grow
    width: 0,
  },
  field: {
    fontFamily: 'Arial',
    fontSize: '16px',
    fontWeight: 400,
    marginLeft: '6px',
    marginRight: '6px',
    padding: 0,
  },
  menuItemRoot: {
    maxHeight: MENU_ITEM_HEIGHT,
  },
}));

export interface SelectFilterOption {
  label: string;
  value: string;
}

export interface SelectFloatingFilterComponentProps
  extends IFloatingFilterParams {
  options: SelectFilterOption[];
  testId: string;
}

const SelectComponent = withTestDataAttribute(Select);

export const SelectFloatingFilterComponent = forwardRef(
  (props: SelectFloatingFilterComponentProps, ref) => {
    const [selectedValues, setSelectedValues] = useState([]);
    const options: SelectFilterOption[] = props.options;
    const classes = useStyles();

    const handleChange = event => {
      const { value } = event.target;
      const selectedValues = typeof value === 'string' ? [value] : value;
      setSelectedValues(selectedValues);
      props.parentFilterInstance((instance: IFilterComp) => {
        instance.setModel({ selectedOptions: selectedValues });
      });
    };

    useImperativeHandle(ref, () => {
      return {
        onParentModelChanged(parentModel) {
          const selectedOptions = parentModel?.selectedOptions || [];
          setSelectedValues(selectedOptions);
        },
      };
    });

    const renderValue = selected => {
      return selectedValues
        .map(val => options.find(opt => opt.value === val).label)
        .join(', ');
    };

    return (
      <div className="ag-filter-select ag-labeled ag-label-align-left ag-select">
        <SelectComponent
          testId={props.testId}
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={selectedValues}
          onChange={handleChange}
          input={
            <InputBase
              classes={{
                root: `ag-wrapper ag-picker-field-wrapper  ${classes.wrapper}`,
                input: classes.field,
              }}
            />
          }
          renderValue={renderValue}
          MenuProps={MenuProps}
        >
          {options.map((option, i) => (
            <MenuItem
              testId={`menu-item-${option.value}`}
              key={option.label}
              value={option.value}
              classes={{
                root: classes.menuItemRoot,
              }}
            >
              <Checkbox checked={selectedValues.includes(option.value)} />
              <ListItemText primary={option.label} />
            </MenuItem>
          ))}
        </SelectComponent>
      </div>
    );
  }
);

export const SelectFilterComponent = forwardRef((props: IFilterParams, ref) => {
  const selectedValues = useRef([]);

  useImperativeHandle(ref, () => {
    return {
      doesFilterPass(params) {
        return true;
      },

      isFilterActive() {
        return selectedValues.current?.length > 0;
      },

      getModel() {
        if (selectedValues.current?.length > 0) {
          return {
            selectedOptions: selectedValues.current,
            filterType: 'select',
            type: 'contains',
          };
        }
        return null;
      },

      setModel(model) {
        selectedValues.current = model?.selectedOptions ?? [];
        props.filterChangedCallback();
      },

      onFloatingFilterChanged(type, value) {
        selectedValues.current = value;
        props.filterChangedCallback();
      },
    };
  });

  return null;
});
