import { Box, Drawer } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import { MyChatContainer, SelectConversation } from './myChatContainer';
import { CommunicationStore } from './stores/communicationStore';

const useStyles = makeStyles((theme: Theme) => ({
  drawer: {
    '& .MuiDrawer-paper': {
      [theme.breakpoints.up('md')]: {
        width: 1000,
      },
      [theme.breakpoints.up('lg')]: {
        width: 1200,
      },
    },
  },
  drawerContent: {
    [theme.breakpoints.up('md')]: {
      width: 1000,
    },
    [theme.breakpoints.up('lg')]: {
      width: 1200,
    },
  },
  chatContainer: {
    flex: 1,
  },
}));

interface Props {
  communicationStore: CommunicationStore;
  onClose: () => void;
}

export const ConversationDrawer = observer(
  ({ communicationStore, onClose }: Props) => {
    const classes = useStyles();
    const { appraisalId } = useParams<{ appraisalId: string }>();

    if (!appraisalId) {
      return null;
    }

    return (
      <Drawer
        className={classes.drawer}
        anchor="right"
        open={!!appraisalId}
        onClose={onClose}
      >
        {open && (
          <Box display="flex" height="100%" className={classes.drawerContent}>
            <MyChatContainer
              id={Number(appraisalId)}
              communicationStore={communicationStore}
              allowNewConversations={false}
              showSnippet={false}
              onConversationSelect={() => { }}
              className={classes.chatContainer}
            />
            <SelectConversation communicationStore={communicationStore} />
          </Box>
        )}
      </Drawer>
    );
  }
);
