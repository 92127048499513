import { observer } from 'mobx-react';
import { Grid, Typography } from '@material-ui/core';

import { PropertyAddressField } from './propertyFields/propertyAddressField';
import { PropertyAccordionItem } from './propertyAccordion/propertyAccordionItem';
import { makeStyles } from '@material-ui/core/styles';
import { BridgePropertiesStore } from '../../stores/bridge/bridgePropertiesStore';
import { LoanSubType } from '@roc/feature-utils';
import { TextField } from '@roc/ui';

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: '34px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '123.5%',
    letterSpacing: '0.25px',
  },
  body: {
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '175%',
    letterSpacing: '0.15px',
  },
}));

interface Props {
  propertiesStore: BridgePropertiesStore;
  loanSubtype: LoanSubType;
}

export const PropertyInformation = observer(
  ({ propertiesStore, loanSubtype }: Props) => {
    const classes = useStyles();
    return (
      <>
        <Grid item xs={12}>
          <Typography variant="h4" className={classes.title}>
            Property Information
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" className={classes.body}>
            Please provide the property information.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            testId="property-count"
            label="How many properties will there be in this loan?"
            standaloneLabel
            type="number"
            variant="outlined"
            fullWidth
            value={propertiesStore.propertiesCount}
            onChange={e => propertiesStore.setPropertiesCount(e.target.value)}
          />
        </Grid>
      </>
    );
  }
);
