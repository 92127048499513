import { Grid, Typography } from '@material-ui/core';
import { useLoanStore } from '@roc/feature-loans-shared';
import { Button, ConfirmDialog, StandardDialog } from '@roc/ui';
import { observer } from 'mobx-react';
import { useLoanActionsStore } from '../hooks/useLoanActionsStore';

export const MarkFundedWireCompletedModal = observer(
  ({ open, handleClose }) => {
    const { loanStore } = useLoanStore();
    const { loanActionsStore } = useLoanActionsStore();

    const confirm = () => {
      loanActionsStore.markAsFundedWireCompleted();
      handleClose();
    };

    return (
      <ConfirmMarkAsFunded
        title={'Mark Loan Funded/Wire Complete'}
        open={open}
        handleClose={handleClose}
        handleConfirm={confirm}
        closingDate={loanStore?.loanDetails?.closingDate}
      />
    );
  }
);

export const ConfirmMarkAsFunded = ({
  open,
  title,
  handleClose,
  handleConfirm,
  closingDate,
}) => {
  return (
    <StandardDialog
      open={open}
      title={title}
      handleClose={handleClose}
      dialogContent={
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <b>Closing Date (Date signatures were completed)</b>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5">
              - {closingDate ? closingDate : 'No closing date'}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            Note: Please reach out the capital provider if Closing Date needs to
            be changed.
          </Grid>
        </Grid>
      }
      dialogActions={
        <Grid container spacing={1} justifyContent="flex-end">
          <Grid item>
            <Button
              onClick={handleClose}
              color="primary"
              variant="outlined"
              testId="cancel"
            >
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={handleConfirm}
              disabled={!closingDate}
              color="primary"
              variant="contained"
              testId="confirm"
            >
              Mark Complete
            </Button>
          </Grid>
        </Grid>
      }
    />
  );
};
