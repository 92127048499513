import { useMemo, useEffect, useRef } from "react";
import { observer } from "mobx-react";
import { Grid, Typography, debounce, makeStyles } from "@material-ui/core"
import { AutocompleteField, Button, Paper } from "@roc/ui"
import GetApp from '@material-ui/icons/GetApp';
import {
  AdquisitionPrice,
  AfterRepairValue,
  AsIsValue,
  CapitalImprovements,
  ConstructionHoldback,
  ExitStrategy,
  InitialLoanAmount,
  LoanPurpose,
  LoanPurposeOption,
  LoanTerm,
  RefinanceFields,
  RenovationBudget,
  SaleFields,
} from "./profitAndDscrCalculcalorFormFields";
import { Summary } from "./summary";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
  },
  leftColumn: {
    paddingRight: theme.spacing(1),
    alignContent: 'flex-start',
  },
  rightColumn: {
    paddingLeft: theme.spacing(1),
  },
  loanContainer: {
    marginBottom: theme.spacing(2),
  },
  calculateContainer: {
    padding: theme.spacing(2),
  },
  calculatedValuesBorder: {
    border: '1px solid #BEBEBE',
    borderRadius: '15px',
    padding: 16,
    '&.in-process *': {
      boxShadow: 'none',
    },
  },
  sliderMargin: {
    margin: theme.spacing(1),
  },
  downloadContainer: {
    display: 'flex',
    flexDirection: 'row',
    cursor: 'pointer',
    marginLeft: '17px',
  },
  buttonLabel: {
    fontSize: '14px',
    paddingLeft: '5px',
  },
  icon: {
    fontSize: '24px',
  },
}));

export const ProfitAndDscrCalculatorTab = observer(({ store }) => {
  const classes = useStyles();
  const downloadEl = useRef(null);
  const formStore = store.bridgeProfitAndDscrCalculatorFormStore;
  const saleStore = formStore.saleExitStrategyFormStore;
  const refiStore = formStore.refinanceExitStrategyFormStore;
  const refiLoanPurposeStore = formStore.refinanceLoanPurposeFormStore;
  let currentDisabled;

  const fetchOptions = useMemo(
    () => debounce(() => formStore.fetchOptions(formStore.loanSearchText), 500),
    [formStore]
  );

  useEffect(() => {
    formStore.getDefaultExitRate();
  }, [formStore]);

  useEffect(() => {
    if (store.isDownloading) {
      const { current } = downloadEl;
      if (!current) {
        return;
      }

      current.classList.add('in-process');
      setTimeout(
        () =>
          html2canvas(current, { scrollX: 0, scrollY: 0, useCORS: true, allowTaint: false }).then(canvas => {
            afterConvertToCanvas(canvas, current);
          }),
        0
      );
    }
  }, [store.isDownloading]);

  const afterConvertToCanvas = (canvas, domConvertedEl) => {
    domConvertedEl.classList.remove('in-process');

    const pdf = new jsPDF(
      domConvertedEl.offsetWidth < domConvertedEl.offsetHeight ? 'p' : 'l',
      'pt',
      [domConvertedEl.offsetWidth, domConvertedEl.offsetHeight]
    );
    const {
      internal: { pageSize },
    } = pdf;

    pdf.addImage(
      canvas.toDataURL('image/png'),
      'PNG',
      0,
      0,
      pageSize.getWidth(),
      pageSize.getHeight(),
      undefined,
      'FAST'
    );
    pdf.save(`Bridge-Profit-Dscr.pdf`);
    store.setIsDownloading(false);
    store.setIsDisabled(currentDisabled);

  };

  const handleDownloadClick = () => {
    currentDisabled = store.isDisabled;
    store.setIsDownloading(true);
    store.setIsDisabled(false);
  };

  return (
    <div>
      <Paper className={classes.paper}>
        <Grid container direction="row">
          <Grid item container sm={12} md={6} className={classes.leftColumn}>
            <Grid item xs={12} className={classes.loanContainer}>
              <AutocompleteField
                label=""
                placeHolder="Select Loan Ids or Deal Names"
                value={formStore.loanSearchText}
                options={formStore.loanSearchOptions}
                onChange={text => {
                  formStore.loanSearchText = text;
                  fetchOptions();
                }}
                onSelect={opt => {
                  formStore.loanSearchText = opt.label;
                  formStore.fetchLoanValues(opt.value);
                }}
              />
            </Grid>
            <LoanPurpose store={formStore} />
            <AdquisitionPrice store={formStore} />
            <AsIsValue store={formStore} />
            {formStore.loanPurpose === LoanPurposeOption.REFINANCE
              && <CapitalImprovements store={formStore} />
            }
            <RenovationBudget store={formStore} />
            <AfterRepairValue store={formStore} />
            <LoanTerm store={formStore} />
            <ExitStrategy store={formStore} />
            <InitialLoanAmount store={formStore} md={12} className={classes.sliderMargin} />
            <ConstructionHoldback store={formStore} md={12} className={classes.sliderMargin} />
            {formStore.exitStrategy === "Sale" ? <SaleFields store={saleStore} isRefi={formStore.loanPurpose === 'Refi'} refiStore={refiLoanPurposeStore} /> : null}
            {formStore.exitStrategy === "Refinance" ? <RefinanceFields store={refiStore} /> : null}
            <Grid item container xs={12} justifyContent="center">
              <Grid item xs={6} className={classes.calculateContainer}>
                <Button
                  testId="start"
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled={!store.isValidForm}
                  onClick={() => store.calculateResult(store)}
                >
                  CALCULATE
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container sm={12} md={6} className={classes.rightColumn} alignContent="flex-start">
            <Grid
              container
              spacing={2}
              className={classes.calculatedValuesBorder}
              ref={downloadEl}
            >
              <Grid item container alignContent="space-between" justifyContent="center" direction="column">
                <Grid item xs={6}>
                  <Typography variant={'h4'}>
                    Summary
                  </Typography>
                </Grid>
                <Grid item xs={6}
                  alignItems="flex-end"
                  className={classes.downloadContainer}
                  onClick={handleDownloadClick}
                  data-html2canvas-ignore>
                  <GetApp className={classes.icon} />
                  <p className={classes.buttonLabel}>DOWNLOAD AS PDF</p>
                </Grid>
              </Grid>

              <Summary store={store} />
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </div>
  )
});