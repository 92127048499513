import { FormStore, GlobalStore } from '@roc/feature-app-core';
import { formatDate, isNil, parseDate } from '@roc/feature-utils';
import { DialogState } from '@roc/ui';
import { action, computed, makeObservable, observable } from 'mobx';
import { PublicRecordItem } from '../../types/backgroundReviewForm';
import { BackgroundItemBaseStore } from '../backgroundItemBaseStore';
import { EVENT_ACTION, PUBLIC_RECORD } from '../../components/utils/backgroundUtils';

const form = {
  fields: {
    item: {
      value: '',
      error: null,
      rule: 'required',
    },
    itemDescription: {
      value: null,
      error: null,
      rule: '',
    },
    loefFlag: {
      value: null,
      error: null,
      rule: '',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};
export class PublicRecordItemFormStore extends FormStore {
  private globalStore: GlobalStore;
  backgroundItemBaseStore: BackgroundItemBaseStore;

  mode: DialogState;
  record: PublicRecordItem;

  constructor(globalStore, backgroundItemBaseStore) {
    super(form, globalStore);
    this.globalStore = globalStore;
    this.backgroundItemBaseStore = backgroundItemBaseStore;
    makeObservable(this, {
      mode: observable,
      open: computed,
      openAddItem: action,
      openEditDitem: action,
      closeDialog: action,
    });
  }

  get open() {
    return !isNil(this.mode);
  }

  openAddItem() {
    this.reset();
    this.mode = DialogState.ADD;
    this.record = null;
  }

  openEditDitem(record: PublicRecordItem) {
    this.reset();
    this.mode = DialogState.EDIT;
    this.record = record;
    this.loadForm(record);
  }

  closeDialog() {
    this.mode = null;
  }

  save() {
    const newRecord = {
      ...(this.record || {}),
      ...this.getFormValues(),
    };

    let index = -1;
    const publicRecord = this.backgroundItemBaseStore.backgroundSectionDetails
      .backgroundSection.publicRecords;
    if (this.mode == DialogState.ADD) {
      publicRecord.push(newRecord);
      this.backgroundItemBaseStore.setAutomatedEvent(EVENT_ACTION.ITEM_ADDED, newRecord.item, PUBLIC_RECORD)
      index = publicRecord.length - 1;
    } else {
      index = publicRecord.findIndex(item => item === this.record);
      publicRecord[index] = newRecord;
      this.backgroundItemBaseStore.setAutomatedEvent(EVENT_ACTION.ITEM_UPDATED, newRecord.item, PUBLIC_RECORD)
    }
    this.backgroundItemBaseStore.updateLetterOfExplanationStatus(index, 'publicRecords');
    this.closeDialog();
  };

  removeItem(record: PublicRecordItem) {
    const { backgroundSectionDetails } = this.backgroundItemBaseStore;
    this.backgroundItemBaseStore.updateLOEListAfterRemoval(record, 'publicRecords');
    backgroundSectionDetails.backgroundSection.publicRecords = backgroundSectionDetails.backgroundSection.publicRecords.filter(
      item => item !== record
    );
    this.backgroundItemBaseStore.setAutomatedEvent(EVENT_ACTION.ITEM_REMOVED, record.item, PUBLIC_RECORD)
  };

  editRecordItemFlag(loefFlag: any) {
    this.onFieldChange('loefFlag', this.setFlagValue(loefFlag));
  }

  setFlagValue(isItemFlagged: string) {
    if (isItemFlagged === 'yes') {
      return true
    } else return null;
  }
}
