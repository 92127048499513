import { PPP_3_2_1, PPP_3_YEAR_LABEL, PPP_5_4_3_2_1, PPP_5_4_3_2_1_LABEL, PPP_YIELD_MAINTENANCE, FULL_RECOURSE, NON_RECOURSE, PPP_4_3_2_1 } from "@roc/client-portal-shared/utils";

export enum RateTypeOptions {
  FIVE_SIX_ARM = '5/6 ARM',
  SEVEN_SIX_ARM = '7/6 ARM',
  TEN_SIX_ARM = '10/6 ARM',
  THIRTY_YEAR_FRM = '30 Yr FRM'
}

export enum LoanPurposeOptions {
  PURCHASE = 'Purchase',
  CASHOUT_REFI = 'Cash-Out Refinance',
  RATE_TERM_REFI = 'Rate Term Refinance'
}

export enum ForeignNationalOptions {
  FOREIGN_NATIONAL = "Foreign National",
  CITIZEN = "US Citizen"
}

export enum UPBOptions {
  UPB_LESS_THAN_100K = '0',
  UPB_100K_199K = '100000',
  UPB_200K_599K = '200000',
  UPB_250K_599K = '250000',
  UPB_600K_999K = '600000',
  UPB_1M = '1000000',
  UPB_1_HALF_M = '1500000',
  UPB_2M = '2000000'
}

export enum CreditScoreOptions {
  BETWEEN_660_679 = 660,
  BETWEEN_680_699 = 680,
  BETWEEN_700_719 = 700,
  BETWEEN_720_739 = 720,
  BETWEEN_740_759 = 740,
  BETWEEN_760_779 = 760,
  ABOVE_780 = 780
}

export enum BorrowerExpOptions {
  ZERO = '0',
  ONE = '1-2',
  TWO = '3-4',
  THREE = '5-9',
  FOUR = '10-49',
  FIVE = '50+'
}

export enum GeoTierOptions {
  TOP = 'GeoTierTop',
  STANDARD = 'GeoTierStandard',
  SMALL = 'GeoTierSmall',
  VERY_SMALL = 'GeoTierVerySmall',
  NA = 'GeoTierNa'
}

export enum LoanSubtypeOptions {
  SINGLE_PROPERTY = 'Single Property Rental',
  PORTFOLIO = 'Rental Portfolios',
}

export enum DebtYieldOptions {
  LESS_EQUAL_THAN_NINE = '9.0',
  GREATER_THAN_NINE = '9.1',
}

export enum DSCROptions {
  ONE = '1.00',
  ONE_POINT_ONE_ZERO = '1.10',
  ONE_POINT_ONE_FIVE = '1.15',
  ONE_POINT_TWO = '1.20',
  ONE_POINT_THREE = '1.30',
  OVER_ONE_POINT_FOUR = '1.40'
}

export enum RequestedLTVOptions {
  ONE_TO_FIVE = '0.05',
  FIVE_TO_TEN = '0.10',
  TEN_TO_FIFTEEN = '0.15',
  FIFTEEN_TO_TWENTY = '0.20',
  TWENTY_TO_TWENTY_FIVE = '0.25',
  TWENTY_FIVE_TO_THIRTY = '0.30',
  THIRTY_TO_THIRTY_FIVE = '0.35',
  THIRTY_FIVE_TO_FORTY = '0.40',
  FORTY_TO_FORTY_FIVE = '0.45',
  FORTY_FIVE_TO_FIFTY = '0.50',
  FIFTY_TO_FIFTY_FIVE = '0.55',
  FIFTY_FIVE_TO_SIXTY = '0.60',
  SIXTY_TO_SIXTY_FIVE = '0.65',
  SIXTY_FIVE_TO_SEVENTY = '0.70',
  SEVENTY_TO_SEVENTY_FIVE = '0.75',
  SEVENTY_FIVE_TO_EIGHTY = '0.80'
}

export enum PPPOptions {
  THREE_ZERO_ZERO = '3-0-0',
  ONE_ZERO_ZERO = '1-0-0',
  THREE_TWO_ZERO = '3-2-0'
}

export const TargetPriceOptions = [
  '106.0000',
  '105.7500',
  '105.0750',
  '104.6500',
  '104.5500',
  '104.5000',
  '104.3500',
  '104.2000',
  '104.0500',
  '104.0000',
  '103.9500',
  '103.8750',
  '103.7500',
  '103.5000',
  '103.4500',
  '103.3500',
  '103.2500',
  '103.0000',
  '102.7500',
  '102.6750',
  '102.5500',
  '102.5000',
  '102.2500',
  '102.0000',
  '101.9500',
  '101.7500',
  '101.5000',
  '101.3500',
  '101.3250',
  '101.2500',
  '101.0000',
  '100.7500',
  '100.6500',
  '100.5000',
  '100.2500',
  '100.1250',
  '100.0000',
  '99.9000',
  '99.3750',
  '99.2500',
  '98.5000',
  '98.2500',
  '97.7500',
  '96.8500',
  '95.9500',
  '95.0500'
];

export const rateTypeOptions = [
  {
    label: '5/6 ARM',
    value: RateTypeOptions.FIVE_SIX_ARM,
  },
  {
    label: '7/6 ARM',
    value: RateTypeOptions.SEVEN_SIX_ARM,
  },
  {
    label: '10/6 ARM',
    value: RateTypeOptions.TEN_SIX_ARM,
  },
  {
    label: '30 Yr FRM',
    value: RateTypeOptions.THIRTY_YEAR_FRM,
  },
];

export const loanPurposeOptions = [
  {
    label: LoanPurposeOptions.PURCHASE,
    value: LoanPurposeOptions.PURCHASE,
  },
  {
    label: LoanPurposeOptions.CASHOUT_REFI,
    value: LoanPurposeOptions.CASHOUT_REFI,
  },
  {
    label: LoanPurposeOptions.RATE_TERM_REFI,
    value: LoanPurposeOptions.RATE_TERM_REFI,
  },
];

export const upbOptions = [
  {
    label: 'UPB < 100,000',
    value: UPBOptions.UPB_LESS_THAN_100K,
  },
  {
    label: 'UPB 100k - 199k',
    value: UPBOptions.UPB_100K_199K,
  },
  {
    label: 'UPB 200k - 599k',
    value: UPBOptions.UPB_200K_599K,
  },
  {
    label: 'UPB 600k - 999k',
    value: UPBOptions.UPB_600K_999K,
  },
  {
    label: 'UPB 1.0m - 1.499m',
    value: UPBOptions.UPB_1M,
  },
  {
    label: 'UPB 1.500m - 1.999m',
    value: UPBOptions.UPB_1_HALF_M,
  },
];

export const portfolioUpbOptions = [
  {
    label: 'UPB < 100,000',
    value: UPBOptions.UPB_LESS_THAN_100K,
  },
  {
    label: 'UPB 100k - 199k',
    value: UPBOptions.UPB_100K_199K,
  },
  {
    label: 'UPB 200k - 599k',
    value: UPBOptions.UPB_200K_599K,
  },
  {
    label: 'UPB 600k - 999k',
    value: UPBOptions.UPB_600K_999K,
  },
  {
    label: 'UPB 1.0m - 1.499m',
    value: UPBOptions.UPB_1M,
  },
  {
    label: 'UPB 1.500m - 1.999m',
    value: UPBOptions.UPB_1_HALF_M,
  },
  {
    label: 'UPB 2.000m - 2.999m',
    value: UPBOptions.UPB_2M,
  },
];

export const multifamilyUpbOptions = [
  {
    label: 'UPB < 100,000',
    value: UPBOptions.UPB_LESS_THAN_100K,
  },
  {
    label: 'UPB 100k - 199k',
    value: UPBOptions.UPB_100K_199K,
  },
  {
    label: 'UPB 200k - 249k',
    value: UPBOptions.UPB_200K_599K,
  },
  {
    label: 'UPB 250k - 599k',
    value: UPBOptions.UPB_250K_599K,
  },
  {
    label: 'UPB 600k - 999k',
    value: UPBOptions.UPB_600K_999K,
  },
  {
    label: 'UPB 1.0m - 1.499m',
    value: UPBOptions.UPB_1M,
  },
  {
    label: 'UPB 1.500m - 1.999m',
    value: UPBOptions.UPB_1_HALF_M,
  },
  {
    label: 'UPB 2.000m - 2.999m',
    value: UPBOptions.UPB_2M,
  },
];

export const creditScoreOptions = [
  {
    label: '660-679',
    value: CreditScoreOptions.BETWEEN_660_679
  },
  {
    label: '680-699',
    value: CreditScoreOptions.BETWEEN_680_699
  },
  {
    label: '700-719',
    value: CreditScoreOptions.BETWEEN_700_719
  },
  {
    label: '720-739',
    value: CreditScoreOptions.BETWEEN_720_739
  },
  {
    label: '740-759',
    value: CreditScoreOptions.BETWEEN_740_759
  },
  {
    label: '760-779',
    value: CreditScoreOptions.BETWEEN_760_779
  },
  {
    label: '780+',
    value: CreditScoreOptions.ABOVE_780
  },
];

export const prepaymentPenaltyOptions = [
  {
    label: 'Yield Maintenance',
    value: PPP_YIELD_MAINTENANCE
  },
  {
    label: '3-2-1 Prepay',
    value: PPP_3_2_1
  },
  {
    label: '4-3-2-1 Prepay',
    value: PPP_4_3_2_1
  },
  {
    label: '5-4-3-2-1 Prepay',
    value: PPP_5_4_3_2_1
  },
  {
    label: '3-2-0 Prepay',
    value: PPPOptions.THREE_TWO_ZERO
  },
  {
    label: '3-0-0 Prepay',
    value: PPPOptions.THREE_ZERO_ZERO
  },
  {
    label: '1-0-0 Prepay',
    value: PPPOptions.ONE_ZERO_ZERO
  },
];

export const foreignNationalOptions = [
  {
    label: ForeignNationalOptions.FOREIGN_NATIONAL,
    value: ForeignNationalOptions.FOREIGN_NATIONAL
  },
  {
    label: ForeignNationalOptions.CITIZEN,
    value: ForeignNationalOptions.CITIZEN
  },
];

export const borrowerExpOptions = [
  {
    label: '0 (0 properties)',
    value: BorrowerExpOptions.ZERO
  },
  {
    label: '1 (1-2 properties)',
    value: BorrowerExpOptions.ONE
  },
  {
    label: '2 (3-4 properties)',
    value: BorrowerExpOptions.TWO
  },
  {
    label: '3 (5-9 properties)',
    value: BorrowerExpOptions.THREE
  },
  {
    label: '4 (10-49 properties)',
    value: BorrowerExpOptions.FOUR
  },
  {
    label: '5 (50+ properties)',
    value: BorrowerExpOptions.FIVE
  },
];

export const geoTierOptions = [
  {
    label: 'Top',
    value: GeoTierOptions.TOP,
  },
  {
    label: 'Standard',
    value: GeoTierOptions.STANDARD,
  },
  {
    label: 'Small',
    value: GeoTierOptions.SMALL,
  },
  {
    label: 'Very Small',
    value: GeoTierOptions.VERY_SMALL,
  },
  {
    label: 'Not Applicable',
    value: GeoTierOptions.NA,
  },
];

export const loanSubtypeOptions = [
  {
    label: 'Single Property Rental',
    value: LoanSubtypeOptions.SINGLE_PROPERTY,
  },
  {
    label: 'Rental Portfolios',
    value: LoanSubtypeOptions.PORTFOLIO,
  },
];

export const debtYieldOptions = [
  {
    label: '<= 9.0%',
    value: DebtYieldOptions.LESS_EQUAL_THAN_NINE,
  },
  {
    label: '> 9.0%',
    value: DebtYieldOptions.GREATER_THAN_NINE,
  },
];

export const recourseOptions = [
  {
    label: FULL_RECOURSE,
    value: true,
  },
  {
    label: NON_RECOURSE,
    value: false,
  },
];

export const dscrOptions = [
  {
    label: '1.00 - 1.09',
    value: DSCROptions.ONE
  },
  {
    label: '1.10 - 1.14',
    value: DSCROptions.ONE_POINT_ONE_ZERO
  },
  {
    label: '1.15 - 1.19',
    value: DSCROptions.ONE_POINT_ONE_FIVE
  },
  {
    label: '1.20 - 1.29',
    value: DSCROptions.ONE_POINT_TWO
  },
  {
    label: '1.30 - 1.39',
    value: DSCROptions.ONE_POINT_THREE
  },
  {
    label: '>= 1.40',
    value: DSCROptions.OVER_ONE_POINT_FOUR
  }
]

export const requestedLTVOptions = [
  {
    label: '0.01 - 5',
    value: RequestedLTVOptions.ONE_TO_FIVE
  },
  {
    label: '5.01 - 10',
    value: RequestedLTVOptions.FIVE_TO_TEN
  },
  {
    label: '10.01 - 15',
    value: RequestedLTVOptions.TEN_TO_FIFTEEN
  },
  {
    label: '15.01 - 20',
    value: RequestedLTVOptions.FIFTEEN_TO_TWENTY
  },
  {
    label: '20.01 - 25',
    value: RequestedLTVOptions.TWENTY_TO_TWENTY_FIVE
  },
  {
    label: '25.01 - 30',
    value: RequestedLTVOptions.TWENTY_FIVE_TO_THIRTY
  },
  {
    label: '30.01 - 35',
    value: RequestedLTVOptions.THIRTY_TO_THIRTY_FIVE
  },
  {
    label: '35.01 - 40',
    value: RequestedLTVOptions.THIRTY_FIVE_TO_FORTY
  },
  {
    label: '40.01 - 45',
    value: RequestedLTVOptions.FORTY_TO_FORTY_FIVE
  },
  {
    label: '45.01 - 50',
    value: RequestedLTVOptions.FORTY_FIVE_TO_FIFTY
  },
  {
    label: '50.01 - 55',
    value: RequestedLTVOptions.FIFTY_TO_FIFTY_FIVE
  },
  {
    label: '55.01 - 60',
    value: RequestedLTVOptions.FIFTY_FIVE_TO_SIXTY
  },
  {
    label: '60.01 - 65',
    value: RequestedLTVOptions.SIXTY_TO_SIXTY_FIVE
  },
  {
    label: '65.01 - 70',
    value: RequestedLTVOptions.SIXTY_FIVE_TO_SEVENTY
  },
  {
    label: '70.01 - 75',
    value: RequestedLTVOptions.SEVENTY_TO_SEVENTY_FIVE
  },
  {
    label: '75.01 - 80',
    value: RequestedLTVOptions.SEVENTY_FIVE_TO_EIGHTY
  }
]

const getTargetOptions = () => {
  let options = [];
  for (const price of TargetPriceOptions) {
    options = [...options, {
      label: price,
      value: price
    }]
  }
  return options;
}
export const targetPriceOptions = getTargetOptions();