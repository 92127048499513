import { Theme, useMediaQuery } from '@material-ui/core';
import {
  useEffect,
  useRef
} from 'react';

const postMessage = (obj) => {
  window.parent.postMessage(obj, '*');
}

const observePositionChanges = (marginBottom) => {
  let pageHeight, topAnchor, bottomAnchor;

  const getAnchorElements = () => {
    topAnchor = document.getElementById('root').childNodes[0];
    bottomAnchor = document.getElementById('bottom-anchor');
  }

  const calculatePageHeight = () => {
    const { y:topY } = topAnchor.getBoundingClientRect();
    const { y:bottomY } = bottomAnchor.getBoundingClientRect();

    return (bottomY - topY)+marginBottom;
  }

  return setInterval(() => {
    if(topAnchor && bottomAnchor){
      const newPageHeight = calculatePageHeight();
      if(newPageHeight !== pageHeight){
        pageHeight = newPageHeight;
        postMessage({ action:'resize', pageHeight })
      }
    } else {
      getAnchorElements();
    }
  }, 10);
}

export const EmbeddedFrameBoundaries = ({ children }) => {
  const smallScreen = useMediaQuery((theme:Theme) => theme.breakpoints.down("sm"));
  const bottomMargin = smallScreen ? 30 : 28;
  const intervalRef = useRef<unknown>();

  useEffect(() => {
    intervalRef.current = observePositionChanges(bottomMargin);
    return () => clearInterval(intervalRef.current as number);
  }, [bottomMargin]);

  return (
    <>
      {children}
      <div id="bottom-anchor" />
    </>
  );
};
