import {
  FormHelperText,
  Grid,
  GridProps,
  GridSize,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import {
  Bankruptcy,
  BankruptcyDate,
  ExperienceLevel,
  Foreclosure,
  ForeclosureDate,
  QualifyingFicoScore,
} from '@roc/ui/formComponents';
import {
  RentalPortfolioStore,
  SinglePropertyStore,
} from '@roc/feature-loans';
import {
  exitStrategiesStabilizedBridge,
  isNotBlank,
  loanTermRateTermMapping,
  MIXED_USE,
  MULTIFAMILY_5_PLUS,
  notLicensedinState,
  PPP_5_4_3_2_1,
  propertyStates,
  sfrLoanTermTypes,
  sfrPortfolioPropertyManagement,
} from '@roc/feature-utils';
import { propertyTypesByLoanSubtype } from '@roc/feature-loans';
import {
  AutocompleteAddressField,
  CurrencyField,
  DateField,
  MultiSelectField,
  NumberFormat,
  RadioField,
  SelectField,
  Slider,
  StandardDialog,
  TextField,
  yesNoOptions,
} from '@roc/ui';
import { observe } from 'mobx';
import { observer } from 'mobx-react';
import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import {
  AnnualExpenses,
  AnnualIncome,
  AnnualNoi,
  CapitalImprovements,
  CurrentDebtAmount,
  CurrentDebtOnProperty,
} from '@roc/feature-loans';
import { StabilizedBridgeStore } from '@roc/feature-loans';
import { GridContainer } from '@roc/feature-loans';
import { loanPurpose } from '@roc/feature-utils';
import { format, isValid } from 'date-fns';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles(theme => ({
  alert: {
    backgroundColor: "#FDEFBF",
    color: theme.palette.text.primary
  }
}));
interface PricerFieldProps {
  store: StabilizedBridgeStore;
}

export const PropertyAddress = observer(({ store }) => {
  return (
    <GridContainer>
      <AddressNoLicensedText store={store} />
    </GridContainer>
  );
});

export const AddressNoLicensedText = observer(({ store }) => {
  const hasError = isNotBlank(
    store.propertyDetailsStore.form.fields.address.error ||
    store.propertyDetailsStore.form.fields.__isAddressInLicensedState__.error ||
    store.propertyDetailsStore.form.fields.__isAddressInCorrectFormat__.error
  );

  return (
    <>
      <Address store={store} />
      {!hasError && <FormHelperText>{notLicensedinState.message}</FormHelperText>}
    </>
  );
});

export const Address = observer(({ store }) => {
  const { appraisalId } = store
  const { form } = store.propertyDetailsStore;

  const handleAddressChange = (event, name) => {
    const { value, fullInfo } = event?.target || {};
    const { address_components, geometry } = fullInfo || {};
    store.propertyDetailsStore.saveAddressField(value, address_components, geometry);
  };
  return (
    <AutocompleteAddressField
      value={form.fields.address.value}
      name="address"
      label="Enter property address"
      error={
        isNotBlank(form.fields.address.error) ||
        isNotBlank(form.fields.__isAddressInLicensedState__.error) ||
        isNotBlank(form.fields.__isAddressInCorrectFormat__.error)
      }
      disabled={appraisalId}
      errorText={
        form.fields.address.error ||
        form.fields.__isAddressInLicensedState__.error ||
        form.fields.__isAddressInCorrectFormat__.error
      }
      onChange={event => handleAddressChange(event, 'address')}
      fullWidth
      required
      testId="address"
    />
  );
});

export const PricerLoanPurpose = observer(({ store }) => {
  return (
    <GridContainer>
      <LoanPurpose store={store} />
    </GridContainer>
  );
});

export const LoanPurpose = observer(({ store, ...rest }) => {
  const { appraisalId } = store;
  const { form } = store.propertyDetailsStore;
  return (
    <SelectField
      standaloneLabel
      label={'Loan Purpose'}
      name={'loanPurpose'}
      value={form.fields.loanPurpose.value}
      errorText={form.fields.loanPurpose.error}
      error={isNotBlank(form.fields.loanPurpose.error)}
      options={loanPurpose}
      onChange={value => store.propertyDetailsStore.handleLoanPurposeChange('loanPurpose', value)}
      variant="outlined"
      fullWidth
      shrink
      required
      testId="loanPurpose"
      {...rest}
      disabled={appraisalId}
    />
  );
});

export const PricerPurchasePrice = observer(({ store }) => {
  return (
    <GridContainer>
      <PurchasePrice store={store} />
    </GridContainer>
  );
});

export const PurchasePrice = observer(({ store, ...rest }) => {
  const { appraisalId } = store;
  const { form } = store.propertyDetailsStore;
  return (
    <CurrencyField
      variant="outlined"
      standaloneLabel
      label="Purchase Price"
      value={form.fields.acquisitionPrice.value}
      onChange={(name, value) =>
        store.propertyDetailsStore.onAcquisitionPriceChange(value)
      }
      fullWidth
      testId="acquisitionPrice"
      required
      error={form.fields.acquisitionPrice.value != "" ?
        isNotBlank(form.fields.__isAcquisitionPriceValid__.error) : false
      }
      errorText={form.fields.acquisitionPrice.value != "" ? form.fields.__isAcquisitionPriceValid__.error : ""
      }
      {...rest}
      disabled={appraisalId}
    />
  );
});

export const PropertyType = observer(({ store }) => {
  const { appraisalId } = store
  const { form } = store.propertyDetailsStore;
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const handlePropertyTypeChange = (name, value) => {
    store.propertyDetailsStore.onFieldChange(name, value);
    if (value) {
      store.propertyDetailsStore.handlePropertyTypeChange(value);
    }
  };

  return (
    <GridContainer>
      <SelectField
        standaloneLabel
        label={'Select the Property Type'}
        name={'propertyType'}
        value={form.fields.propertyType.value}
        errorText={form.fields.propertyType.error}
        error={isNotBlank(form.fields.propertyType.error)}
        options={propertyTypesByLoanSubtype(store.loanSubtype)}
        onChange={value => handlePropertyTypeChange('propertyType', value)}
        variant="outlined"
        fullWidth
        shrink
        required
        testId="propertyType"
        disabled={appraisalId}

      />
      <StandardDialog
        open={modalOpen}
        maxWidth="md"
        handleClose={() => setModalOpen(false)}
        dialogContent={
          <Typography>
            As of 5/3/22, The Capital Provider is not accepting rental loans for 5+ unit multifamily and mixed-use properties that are an exception to the guidelines and properties that have more than 8 units. Please refer to the product guidelines for details on which loans qualify.
          </Typography>
        }
      />
    </GridContainer>
  );
});


export const PricerPurchaseDate = observer(({ store }) => {
  return (
    <GridContainer>
      <PurchaseDate store={store} />
    </GridContainer>
  );
});

export const PurchaseDate = observer(({ store, ...rest }) => {
  const { appraisalId } = store;
  const formFields = store.form.fields;
  const manageDate = date => {
    let formattedDate = null;
    if (isValid(date)) {
      formattedDate = format(date, 'MM/dd/yyyy');
    }
    store.onFieldChange('acquisitionDate', formattedDate);
  };

  return (
    <DateField
      inputVariant="outlined"
      testId="acquisitionDate"
      format="MM/dd/yyyy"
      standaloneLabel
      label="Purchase Date"
      value={formFields.acquisitionDate.value}
      onChange={date => manageDate(date)}
      disableFuture={true}
      error={isNotBlank(formFields.acquisitionDate.error)}
      errorText={formFields.acquisitionDate.error}
      fullWidth
      required
      {...rest}
      disabled={appraisalId}
    />
  );
});

export const PricerCapitalImprovements = observer(
  ({ store }) => {
    return (
      <GridContainer>
        <CapitalImprovements store={store} />
      </GridContainer>
    );
  }
);

export const PricerCurrentDebtOnProperty = observer(
  ({ store }) => {
    return (
      <GridContainer>
        <CurrentDebtOnProperty store={store} />
      </GridContainer>
    );
  }
);

export const PricerCurrentDebtAmount = observer(
  ({ store }) => {
    return (
      <GridContainer>
        <CurrentDebtAmount store={store} />
      </GridContainer>
    );
  }
);

export const PricerAnnualIncome = observer(({ store }: PricerFieldProps) => {
  return <AnnualIncome store={store} />;
});

export const PricerAnnualExpenses = observer(({ store }: PricerFieldProps) => {
  return <AnnualExpenses store={store} />;
});

export const PricerAnnualNoi = observer(({ store }: PricerFieldProps) => {
  return <AnnualNoi store={store} />;
});

export const AsIsValue = observer(({ store }) => {
  const { form } = store.pricerStore;
  return (
    <CurrencyField
      variant="outlined"
      standaloneLabel
      label="Estimated As Is Value"
      value={form.fields.propertyValuation.value}
      onChange={(name, value) =>
        store.pricerStore.onFieldChange('propertyValuation', Number(value))
      }
      fullWidth
      testId="propertyValuation"
      required
      error={isNotBlank(form.fields.propertyValuation.error)}
      errorText={form.fields.propertyValuation.error}
    />
  );
});

export const GrossMonthlyRent = observer(({ store }) => {
  const { form } = store.pricerStore;
  return (
    <CurrencyField
      variant="outlined"
      standaloneLabel
      label="Gross Monthly Rent"
      value={form.fields.monthlyGrossRent.value}
      onChange={(name, value) =>
        store.pricerStore.handleIncomeChange(
          'monthlyGrossRent',
          Number(value)
        )
      }
      fullWidth
      testId="monthlyGrossRent"
      required
      error={isNotBlank(form.fields.monthlyGrossRent.error)}
      errorText={form.fields.monthlyGrossRent.error}
    />
  );
});

export const MonthlyMarketRent = observer(({ store }) => {
  const { form } = store.pricerStore;
  return (
    <CurrencyField
      variant="outlined"
      standaloneLabel
      label="Monthly Market Rent"
      value={form.fields.monthlyMarketRent.value}
      onChange={(name, value) =>
        store.pricerStore.handleIncomeChange(
          'monthlyMarketRent',
          Number(value)
        )
      }
      fullWidth
      testId="monthlyMarketRent"
      required
      error={isNotBlank(form.fields.monthlyMarketRent.error)}
      errorText={form.fields.monthlyMarketRent.error}
    />
  );
});

export const AnnualTaxes = observer(({ store }) => {
  const { form } = store.pricerStore;

  return (
    <CurrencyField
      variant="outlined"
      standaloneLabel
      label="Annual Taxes"
      value={form.fields.annualTaxes.value}
      onChange={(name, value) =>
        store.pricerStore.handleExpenseChange('annualTaxes', Number(value))
      }
      fullWidth
      testId="annualTaxes"
      required
      error={isNotBlank(form.fields.annualTaxes.error)}
      errorText={form.fields.annualTaxes.error}

    />
  );
});

export const AnnualInsurance = observer(({ store }) => {
  const { form } = store.pricerStore;
  return (
    <CurrencyField
      variant="outlined"
      standaloneLabel
      label="Annual Insurance"
      value={form.fields.annualInsurance.value}
      onChange={(name, value) =>
        store.pricerStore.handleExpenseChange(
          'annualInsurance',
          Number(value)
        )
      }
      fullWidth
      testId="annualInsurance"
      required
      error={isNotBlank(form.fields.annualInsurance.error)}
      errorText={form.fields.annualInsurance.error}
    />
  );
});

export const AnnualHoa = observer(({ store }) => {
  const { form } = store.pricerStore;
  return (
    <CurrencyField
      variant="outlined"
      standaloneLabel
      label="Annual HOA Dues"
      value={form.fields.annualHOA.value}
      onChange={(name, value) =>
        store.pricerStore.handleExpenseChange('annualHOA', Number(value))
      }
      fullWidth
      testId="annualHOA"
      required
      error={isNotBlank(form.fields.annualHOA.error)}
      errorText={form.fields.annualHOA.error}
    />
  );
});

export const ExitStrategy = observer(({ store, ...rest }) => {
  const formFields = store.pricerStore.form.fields;
  return (
    <SelectField
      standaloneLabel
      label={'Exit Strategy'}
      name={'exitStrategy'}
      options={exitStrategiesStabilizedBridge}
      variant="outlined"
      value={formFields.exitStrategy.value}
      errorText={formFields.exitStrategy.error}
      error={isNotBlank(formFields.exitStrategy.error)}
      onChange={value => {
        store.pricerStore.onFieldChange('exitStrategy', value);
      }}
      fullWidth
      required
      testId="exitStrategy"
      {...rest}
    />
  );
});

export const Occupancy = observer(({ store }) => {
  const { form } = store.pricerStore;

  return (
    <RadioField
      key={form.fields.occupancy.value}
      standaloneLabel
      label="Is the property occupied?"
      value={form.fields.occupancy.value}
      name={'occupancy'}
      options={yesNoOptions}
      onChange={value => {
        store.pricerStore.onFieldChange('occupancy', value);
      }}
      fullWidth
      row
      required
      testId="occupancy"
    />
  );
});

export const TenantInsuranceAlert = observer(({ store }) => {
  const classes = useStyles();

  return (
    <>
      {store.pricerStore.warningInsuranceTenant &&
        <Alert severity="warning" className={classes.alert}>
          Properties with a tenant require Renters Insurance
        </Alert>}
    </>
  );
});