import { useLoanRoutes } from "@roc/feature-loans-routes-config";
import { StandardDialog } from "@roc/ui";
import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";
import { useDrawsStore } from "../../hooks";
import { LoansForDrawRequestForm } from "./loansForDrawRequestForm";


export const LoansForDrawRequestModal = observer(() => {

  const { push } = useHistory();
  const { drawRequestStore } = useDrawsStore();
  const { loanRoutesConfig } = useLoanRoutes();

  return (
    <StandardDialog
      open={drawRequestStore.showLoansForDrawRequestModal}
      title="Request Draw"
      maxWidth="md"
      handleClose={() => drawRequestStore.setShowLoansForDrawRequestModal(false)}
      dialogContent={
        <LoansForDrawRequestForm
          onSubmit={(loan) => {
            push(loanRoutesConfig.loans(loan).children.drawRequest.url);
            drawRequestStore.setShowLoansForDrawRequestModal(false);
          }}
          onClose={() => drawRequestStore.setShowLoansForDrawRequestModal(false)}
        />
      }
    />
  );

});