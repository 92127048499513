import { GlobalStore } from '@roc/client-portal-shared/stores';
import { action, flow, makeObservable, observable, override } from 'mobx';
import { FormStore, UserStore } from '@roc/feature-app-core';
import { GridStore } from '@roc/feature-app-core';
import { SelectBorrowersStore } from '@roc/feature-borrowers';
import { ApiResponse } from '@roc/feature-app-core';
import { Borrower } from '@roc/feature-types';
import { LoanParticipantsStore } from '@roc/feature-loan-participants';
import {
  GeneralTaskAttachment,
  GeneralTaskComment,
  GeneralTasksView,
  Task,
  TaskStatus,
} from '../types/generalTasksTypes';
import { GeneralTasksGridStore } from './generalTasksGridStore';
import { GeneralTasksStore } from './generalTasksStore';
import { DialogState, FileUpload } from '@roc/ui';
import {
  formatDate,
  DateFormat,
  GENERIC_ERROR_MESSAGE,
  downloadDocument,
} from '@roc/feature-utils';
import { GeneralTasksService } from '../services/generalTasksService';
import { assign } from 'mobx/dist/internal';
import { EditTaskStore } from './editTaskStore';

export class GeneralTasksAttachmentStore {
  private globalStore: GlobalStore;
  private editTasksStore: EditTaskStore;
  private generalTaskService: GeneralTasksService;

  showAttachmentsDialog: boolean;
  documentPreview;

  constructor(globalStore: GlobalStore, editTasksStore: EditTaskStore) {
    this.globalStore = globalStore;
    this.editTasksStore = editTasksStore;
    this.generalTaskService = new GeneralTasksService();

    makeObservable(this, {
      showAttachmentsDialog: observable,
      documentPreview: observable,
      downloadDocument: observable,
      closeDocumentPreview: action,
      closeAttachmentDialog: action,
      openAttachmentDialog: flow,
      previewDocument: flow,
      addAttachments: flow,
      removeAttachment: flow
    });
  }

  reset() {
    this.showAttachmentsDialog = false;
    this.documentPreview = null;
  }

  *openAttachmentDialog(){
    // Task must be saved before adding an attachment
    if (!this.editTasksStore.task.taskDetails.taskId) {
      const success = yield this.editTasksStore.saveTask();
      if(!success){
        return;
      }
    }

    this.showAttachmentsDialog = true;
  }

  closeAttachmentDialog() {
    this.showAttachmentsDialog = false;
  }

  *previewDocument(attachment: GeneralTaskAttachment){
    const response = yield this.generalTaskService.downloadDocument(attachment.documentId);
    this.documentPreview = {
      title: attachment.attachmentName,
      data: response.data,
      headers: response.headers,
    };
  }

  *downloadDocument(attachment: GeneralTaskAttachment){
    const response = yield this.generalTaskService.downloadDocument(
      attachment.documentId
    );
    downloadDocument(
      response.data,
      response.headers,
      attachment.attachmentName
    );
  }

  *addAttachments(uploads: FileUpload[]) {
    try {
      const taskId = this.editTasksStore.task.taskDetails.taskId;

      const responses = yield Promise.all(
        uploads.map(upload => {
          const attachment = {
            taskId,
            attachmentName: upload.name,
          };
          return this.generalTaskService.uploadAttachment(
            attachment,
            upload.file
          );
        })
      );

      const newAttachments = responses.map(response => response.data.data);
      this.editTasksStore.attachments = this.editTasksStore.attachments.concat(newAttachments)
      this.closeAttachmentDialog();
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Attachment uploaded successfully!'
      })
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE,
      });
    }
  }

  *removeAttachment(attachment: GeneralTaskAttachment){
    try {
      yield this.generalTaskService.removeAttachment(attachment.taskAttachmentId);
      const attachments = this.editTasksStore.attachments.filter(item => item !=attachment)
      this.editTasksStore.setAttachments(attachments);
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Attachment removed successfully!'
      })
    } catch(e){
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE,
      });
    }
  }

  closeDocumentPreview(){
    this.documentPreview = null;
  }
}
