import { observer } from 'mobx-react';
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemText,
  Grid,
  Typography,
  Chip
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Button } from '@roc/ui';
import { useEffect } from 'react';
import { useStore } from '@roc/client-portal-shared/hooks';
import { CheckCircle } from '@material-ui/icons';
import { useBaseStore } from '@roc/feature-app-core';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  button: {
    margin: '10px',
    minWidth: '150px',
  },
  notAuthtenticatedButton: {
    margin: '10px',
    minWidth: '150px',
    backgroundColor: 'red',
    '&:hover': {
      backgroundColor: 'red',
    },
  },
}));

const divider = <Divider variant="middle" component="li" />;


export const PhoneBurnerAuthCard = observer(() => {

  const classes = useStyles();
  const { salesforceLeadStore, phoneBurnerStore, userStore, whiteLabelStore } = useStore();
  const { globalStore } = useBaseStore();
  async function handleOnClick() {
    const host = await whiteLabelStore.getHostByPortaId(userStore.userInformation.portalId);
    phoneBurnerStore.retriveTokenWithOAuthToken(userStore.userInformation.userId, host);

  }

  return (
    <Paper className={classes.paper} style={{ display: 'flex', flexDirection: 'column', padding: '20px', minHeight: '204px' }}>
      <Box display="flex" alignItems="center" mb={2} style={{ paddingBottom: '25px' }}>
        <img
          src="https://assets-global.website-files.com/6144f395296d182738857698/61956e73c1edd1103ebabe51_phoneburner-logo-dark.svg"
          alt="Logo"
          style={{ width: '60%', maxWidth: 80 }}
        />
        <Box flexGrow={1} ml={2}>
          <Typography variant="h6">
            {'PhoneBurner Connection'}
          </Typography>
          <Typography variant="body2">
            {'Connect your PhoneBurner account to make calls from Portal'}
          </Typography>
        </Box>
      </Box>
      <Box display="flex" alignItems="center" mb={2}>
        <Box mr={2}>
          {
            globalStore.userFeatures?.connectedWithPhoneBurner ? (
              <Chip
                icon={<CheckCircle style={{
                  color: 'white'
                }} />}
                label="CONNECTED"
                style={{
                  backgroundColor: '#008f39',
                  letterSpacing: 2,
                  color: '#FFFFFF',
                  fontWeight: 'bold'
                }}
              />
            ) : (
              <Chip
                label="NOT CONNECTED"
                style={{
                  backgroundColor: '#b3b3b3',
                  letterSpacing: 2,
                  color: '#777',
                  fontWeight: 'bold'
                }}
              />
            )
          }
        </Box>
        <Button
          className={classes.button}
          variant="contained"
          size="small"
          color="primary"
          testId="Authenticate_With_Phone_Burner"
          onClick={handleOnClick}
        >
          Connect PhoneBurner
        </Button>
      </Box>
    </Paper>);
});
