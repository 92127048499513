import { ApiResponse, GlobalStore } from "@roc/feature-app-core";
import { action, makeObservable, observable } from "mobx";
import { ConcentrationReportsService } from "../service/concentrationReportsService";

export class ConcentrationReportsStore {
  private globalStore: GlobalStore;
  public selectedEntity: any;
  public entityList: any[];
  public reportData: any;
  private concentratioReportsService: ConcentrationReportsService;

  constructor(globalStore: GlobalStore){
    this.globalStore = globalStore;
    this.concentratioReportsService = new ConcentrationReportsService();
    this.initialize();
    makeObservable(this, {
      selectedEntity: observable,
      entityList: observable,
      reset: action,
      reportData: observable,
      loadReport: action,
    })
  }

  initialize() {
    this.selectedEntity = null;
    this.entityList = [{ label: 'Angsana', value: 38 }];
    this.reportData = [];
  }

  reset() {
    this.selectedEntity = null;
    this.reportData = [];
  }

  getLabelFromValue = (value) => {
    return this.entityList.find((entity) => entity.value === value).label;
  }

  async loadReport(selectedEntity){
    try {
      const response: ApiResponse = await this.concentratioReportsService.getReport(selectedEntity);
      this.reportData = response.data.data;
      if (this.reportData.length == 0){
        this.globalStore.notificationStore.showInfoNotification({
        message: `There is no information for entity ${this.getLabelFromValue(this.selectedEntity)}`,
      })
      }
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: `Error while getting report information for entity ${this.getLabelFromValue(this.selectedEntity)}`,
      })
    }
  }
}