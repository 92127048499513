import { Box, Grid } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { LoanSummaryExtended } from '@roc/feature-loan-summary';
import { SelectField } from '@roc/ui';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { LoanDetailsPaper } from '../components/loanDetailsPaper';
import { LoanSummaryTab } from './loanSummaryTab';

export const CrmLoanSummaryTab = observer(() => {
  const [crmViewEnabled, setCrmViewEnabled] = useState(false);

  return (
    <>
      <Grid item xs={12}>
        <CrmViewField
          value={crmViewEnabled}
          onChange={value => setCrmViewEnabled(value)}
        />
      </Grid>
      {!crmViewEnabled && <LoanSummaryTab />}
      {crmViewEnabled && <CrmView />}
    </>
  );
});

const CrmView = observer(() => {
  return (
    <Grid item xs={12}>
      <LoanDetailsPaper>
        <LoanSummaryExtended />
      </LoanDetailsPaper>
    </Grid>
  );
});


const useStyles = makeStyles((theme: Theme) => ({
  select: {
    '& .MuiSelect-root': {
      padding: '8px 32px 8px 8px'
    }
  }
}));


const CrmViewField = ({ value, onChange }) => {
  const classes = useStyles();
  return (
    <LoanDetailsPaper>
      <Box textAlign="right" p={1} pb={.5}>
        <SelectField
          value={value}
          options={[
            { label: 'TPO View ', value: false },
            { label: 'Sales User View', value: true },
          ]}
          onChange={onChange}
          variant={'outlined'}
          testId={'enableCrmView'}
          className={classes.select}
        />
      </Box>
    </LoanDetailsPaper>
  );
};
