import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { observer } from "mobx-react";
import { useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { Button } from '@roc/ui';
import { useCreateListStore } from '../createList/hooks/useCreateListStore';
import { useCRMPortalStore } from 'apps/crm-portal/src/app/hooks/useCRMPortalStore';
import { useStore } from '@roc/client-portal-shared/hooks';
import {
  alertCircleOutline
} from 'ionicons/icons';
import { IonIcon } from '@ionic/react';



const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(3, 0, 4),
      borderRadius: theme.spacing(2),
      boxShadow:
        'rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px',
      display: 'flex',
      justifyContent: 'center',
    },
    container: {
      display: 'flex',
      justifyContent: 'center',
    },
    modalContainer: {
      width: '100%',
    },
    buttonContainer: {
      paddingTop: theme.spacing(4),
    },
    contentContainer: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    title: {
      paddingLeft: theme.spacing(2),
      display: 'flex',
      justifyContent: 'flex-start',
    },
  })
);


const currentPath = window.location.pathname;


export const PhoneBurnerModal = observer(({ handleClose = () => { } }) => {
  const classes = useStyles();
  const { createListStore } = useCreateListStore();
  const { phoneBurnerStore, globalStore, whiteLabelStore, userStore } = useStore();
  const { ringCentralStore } = useStore();

  useEffect(() => {
    const fetchData = async () => {
      try {
        await ringCentralStore.getCreds();
      } catch (error) {
        console.error('Error fetching creds:', error);
      }
    };
    fetchData();
  }, []);


  async function handleOnClick() {
    const host = await whiteLabelStore.getHostByPortaId(userStore.userInformation.portalId);
    await phoneBurnerStore.retriveTokenWithOAuthToken(userStore.userInformation.userId, host);
    userStore.getUserInfoAndEntitlements();
  }

  return !globalStore.userFeatures.connectedWithPhoneBurner &&
    (
      <Grid container justifyContent="center" alignItems='center' className={classes.modalContainer}>
        <Grid item container className={classes.container}>
          <Grid item>
            <IonIcon slot="end" icon={alertCircleOutline} size='large' />
          </Grid>
          <Grid item>
            <Typography variant="h4">You are not connected to PhoneBurner</Typography>
          </Grid>
          <Grid item container className={classes.container}>
            <Box p={3}>
              <Typography variant='body1'>
                To start a dial session you need to connect to your PhoneBurner account.
              </Typography>
            </Box>
          </Grid>
          <Grid item container justifyContent='center' className={classes.buttonContainer}>
            <Grid item sm={7} md={5} xs={7}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                testId="next"
                onClick={handleOnClick}
              >Connect PhoneBurner</Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
});
