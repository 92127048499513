import { Service } from '@roc/feature-app-core';

const url = {
  BORROWER_ENTITIES: '/api/v1/loan/borrowerEntity/getAllEntities',
  LINK_ENTITY_TO_BORROWER: '/api/v1/loan/saveBorrowerEntityBorrowerExternal',
  BORROWER_ENTITIES_INTERNAL: '/nportal/rest/priv/borrowerEntities',
  LINK_ENTITY_TO_BORROWER_INTERNAL: '/nportal/rest/priv/borrower/saveBorrowerEntityBorrower',
};

export class BorrowerEntityService extends Service {
  getAllEntities(
    page: number,
    pageSize: number,
    sortDir: string,
    sortBy: string,
    filters: string
  ) {
    return this.get(
      url.BORROWER_ENTITIES,
      {
        page,
        pageSize,
        sortDir,
        sortBy,
        filters,
      },
      {
        disableGlobalLoading: true,
      }
    );
  };

  linkEntity(borrowerId: string, entity: any) {
    return this.post(`${url.LINK_ENTITY_TO_BORROWER}?borrowerId=${borrowerId}`, entity);
  }

  getInternalEntities(
    page: number,
    pageSize: number,
    sortDir: string,
    sortBy: string,
    filters: string
  ) {
    return this.get(
      url.BORROWER_ENTITIES_INTERNAL,
      {
        start: page === 1 ? page : (page - 1) * pageSize + 1,
        count: pageSize,
        sortDir,
        sortBy,
        filters,
      },
      {
        disableGlobalLoading: true,
      }
    );
  };

  linkEntityInternal(borrowerId: string, entity: any) {
    return this.post(`${url.LINK_ENTITY_TO_BORROWER_INTERNAL}?borrowerId=${borrowerId}`, entity);
  }
}
