import { Grid, IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { ChevronLeft } from '@material-ui/icons';
import { useLoanRoutes } from '@roc/feature-loans-routes-config';
import { useLoanStore } from '@roc/feature-loans-shared';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router';
import { useExtensionRequestStore } from '../../../../../feature-loans/src/loans/loanDetails/modules/loanRequests/hooks/useExtensionRequestStore';
import ExtensionDetailsShared from './extensionDetailsShared';

const useStyles = makeStyles(theme => ({
  container: {
    padding: 32,
  },
  dealName: {
    display: 'flex',
    justifyContent: 'center',
  },
  paper: {
    padding: 24,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  title: {
    marginLeft: '-8px',
  },
}));

export const ExtensionDetails = observer(
  ({ loanId, extensionId }: { loanId: string; extensionId: string }) => {
    const { loanStore } = useLoanStore();
    const {
      extensionRequestStore
    } = useExtensionRequestStore();
    const { push } = useHistory();
    const { loanRoutesConfig } = useLoanRoutes();
    const classes = useStyles();

    return (
      <>
        <Grid
          container
          direction="column"
          spacing={2}
          className={classes.container}
        >
          <Grid item className={classes.dealName}>
            <Typography variant="h6" color="textSecondary">
              {loanStore?.loanDetails.dealName}
            </Typography>
          </Grid>
          <Grid item className={classes.titleContainer}>
            <IconButton
              aria-label="back"
              color="secondary"
              onClick={() =>
                push(loanRoutesConfig.loans(loanId).children.extensions.url)
              }
            >
              <ChevronLeft fontSize="large" />
            </IconButton>
            <Typography variant="h4" className={classes.title}>
              Extension {extensionRequestStore?.extensionByNumber?.extensionNumber}
            </Typography>
          </Grid>
          <ExtensionDetailsShared loanId={loanId} extensionId={extensionId} />
        </Grid>
      </>
    );
  }
);
