import {
  DataGrid as Grid,
  GridColumnProps,
  StatusChip,
  StatusType
} from '@roc/ui';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useDocumentStore } from './../../../../hooks/useDocumentStore';

const noOpComparator = () => 0;

const columns: GridColumnProps[] = [
  {
    field: 'orderId',
    headerName: 'Order Id',
    maxWidth: 150,
    comparator: noOpComparator,
    sortable: false,
  },
  {
    field: 'propertyAddress',
    headerName: 'Property Address',
    minWidth: 550,
    comparator: noOpComparator,
    sortable: false,
  },
  {
    field: 'orderStatus',
    headerName: 'Status',
    maxWidth: 150,
    comparator: noOpComparator,
    cellRenderer: 'statusCellRenderer',
    sortable: false,
  },
  {
    field: 'creationDate',
    headerName: 'Order Creation Date',
    minWidth: 150,
    sortable: false,
    comparator: noOpComparator,
  },
  {
    field: 'paymentStatus',
    headerName: 'Payment Status',
    maxWidth: 150,
    comparator: noOpComparator,
    cellRenderer: 'paymentStatusCellRenderer',
    sortable: false,
  },
];

const frameworkComponents = {
  statusCellRenderer: ({ value }) =>
    value ? (
      <StatusChip statusType={StatusType.DOCUMENT} label={value} size="small" />
    ) : null,
  paymentStatusCellRenderer: ({ value }) =>
    value === true ? (
      "Paid"
    ) : "Pending",
};

export const AppraisalDetailsGrid = observer(
  ({ loanId, collateralId, propertyAppraisalId }: { loanId: number, collateralId: number, propertyAppraisalId: number }) => {
    const {
      documentStore: { documentFormStore },
    } = useDocumentStore();
    const { appraisalOrderDetailsStore } = documentFormStore;

    useEffect(() => {
      appraisalOrderDetailsStore.setLoanId(loanId);
      appraisalOrderDetailsStore.setPropertyId(collateralId);
      appraisalOrderDetailsStore.setPropertyAppraisalId(propertyAppraisalId)
      appraisalOrderDetailsStore.appraisalDetailsGridStore.fetchGridData();
    }, [appraisalOrderDetailsStore]);

    return (
      <Grid
        columns={columns}
        rows={
          appraisalOrderDetailsStore.appraisalDetailsGridStore.gridData.data
            .rows
        }
        frameworkComponents={frameworkComponents}
        isLoading={
          appraisalOrderDetailsStore.appraisalDetailsGridStore.isLoading
        }
        showFilters={
          appraisalOrderDetailsStore.appraisalDetailsGridStore.showFilters
        }
        domLayout="autoHeight"
      />
    );
  }
);
