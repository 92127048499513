import React from 'react';
import { useFavIcon, useFontFamilies } from '@roc/feature-app-core';
import { UnAuthenticatedView } from './routes';
import { observer } from 'mobx-react';

export const App = observer(() => {
  useFavIcon();
  useFontFamilies();
  return <UnAuthenticatedView />;
});
