import {
  Box,
  Button,
  Grid,
  IconButton,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { BusinessRounded, DashboardRounded, EventRounded, MoreVert, UpdateRounded } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import { useBaseStore } from '@roc/feature-app-core';
import { BorrowerPortalCard } from '@roc/feature-borrower-portal-core';
import { useLoanRoutes } from '@roc/feature-loans-routes-config';
import { useLoanStore } from '@roc/feature-loans-shared';
import { formatDate } from '@roc/feature-utils';
import {
  AgGridColumnProps,
  DataGrid,
  LinkColumn,
  StandardDialog,
  StatusChip,
  StatusType
} from '@roc/ui';
import { formatCurrency } from '@roc/ui/utils';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { ExtensionsWarningsV2 } from './extensionsWarnings';
import ExtensionRequestModal from './loanRequests/extensionRequestModal';
import { useExtensionRequestStore } from './loanRequests/hooks/useExtensionRequestStore';


const noOpComparator = () => 0;

const columns: AgGridColumnProps[] = [
  {
    field: 'extensionNumber',
    headerName: 'Extension #',
    minWidth: 100,
    cellRenderer: 'extensionNumberCellRenderer',
    comparator: noOpComparator,
    sortable: false,
  },
  {
    field: 'requestDate',
    headerName: 'Requested Date',
    minWidth: 100,
    comparator: noOpComparator,
    sortable: false,
  },
  {
    field: 'extensionDuration',
    headerName: 'Extension Duration',
    minWidth: 100,
    cellRenderer: 'extensionDurationCellRenderer',
    comparator: noOpComparator,
    sortable: false,
  },
  {
    field: 'status',
    headerName: 'Status',
    minWidth: 230,
    maxWidth: 350,
    cellRenderer: 'statusCellRenderer',
    comparator: noOpComparator,
    sortable: false,
  },

];

const useStyles = makeStyles(theme => ({
  paper: {
    padding: 24,
  },
  gridContainer: {
    paddingTop: 24,
  },

}));


export const ExtensionsDashboardV2 = observer(
  ({ loanId }: { loanId: string }) => {
    const { loanStore } = useLoanStore();
    const { loanRoutesConfig } = useLoanRoutes();
    const {
      extensionRequestStore,
      extensionRequestStore: { extensionList, currentExtension, disabledCreateExtesion },
    } = useExtensionRequestStore();
    const [openRequestExtensionModal, setopenRequestExtensionModal] = useState(
      false
    );
    const { globalStore } = useBaseStore();
    const closeModal = () => {
      setopenRequestExtensionModal(false);
    }
    const [anchorEl, setAnchorEl] = useState<HTMLElement>(null);
    const classes = useStyles();
    const menuOpen = Boolean(anchorEl);
    const [extensionId, setExtensionId] = useState(null);
    const [isConfirmOpen, setIsConfirmOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [insurace, setInsurance] = useState("");
    const { breadCrumbsStore } = globalStore;
    const { breadCrumbItems } = breadCrumbsStore;

    useEffect(() => {
      breadCrumbsStore.setBreadCrumbItems([
        {
          link: true,
          title: 'Dashboard',
          url: globalStore.routeBaseUrl,
          icon: <DashboardRounded />,
        },
        {
          link: true,
          title: `Loan# ${loanId}`,
          url: loanRoutesConfig.loans(loanId).url,
        },
        {
          link: false,
          title: 'Extensions',
        },
      ]);
    }, []);

    useEffect(() => {
      if (loanStore?.loanDetails?.loanInsurance?.insuredThroughElmsure === true || loanStore?.loanDetails?.insuredThroughElmsure === true) {
        setInsurance("ElmSure")
      } else {
        setInsurance("Other")
      }
    }, []);

    useEffect(() => {
      extensionRequestStore.setSaved(false);
      extensionRequestStore.fetchExtensionsByLoanId(loanId, () => {
        setLoading(false);
      });
    }, []);

    const frameworkComponents = {
      dateCellRenderer: ({ value }) => {
        return value ? formatDate(value) : '';
      },
      extensionNumberCellRenderer: params => {
        const extensionId = params.node.data?.extensionId;
        const { onClick } = params;
        const value = `Extension ${params.value}`;
        return LinkColumn({
          value,
          onClick,
          url: loanRoutesConfig
            .loans(loanId)
            .children.extensionDetails(params.node.data?.loanExtensionId).url,
        });
      },
      amountRenderer: ({ value }) => {
        return value ? formatCurrency(value) : '';
      },
      actionsCellRenderer: params => {

        return (
          <IconButton onClick={e => openMenu(e, params.node.data.extensionId)}>
            <MoreVert color="primary" />
          </IconButton>
        );
      },
      extensionDurationCellRenderer: ({ value }) => {
        return `${value} months`;
      },
      statusCellRenderer: ({ value }) =>
        value ? (
          <StatusChip
            statusType={StatusType.EXTENSIONS}
            label={value}
            size='small'
            variant={(value == 'Extension Completed' || value == 'Extension In Process') ? 'filled' : 'outlined'} />
        ) : null,
    };

    const openMenu = (event: React.MouseEvent<HTMLElement>, record) => {
      setExtensionId(record);
      setAnchorEl(event.currentTarget);
    };

    const renderCard = (
      icon,
      value1,
      value2
    ) => {
      return (
        <BorrowerPortalCard noPaddingCardContent>
          <Box p={3} width={'100%'} height={'100%'} textAlign={'center'}>
            <Box mb={.5}>
              {icon}
            </Box>
            <Typography variant='h4' gutterBottom color='primary'>
              {value1}
            </Typography>
            <Typography variant='body2'>
              {value2}
            </Typography>
          </Box>
        </BorrowerPortalCard>
      )
    }

    return (
      <>
        <Box>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              {renderCard(<BusinessRounded />, insurace, 'Insurance Company')}
            </Grid>
            <Grid item xs={12} md={4}>
              {renderCard(<EventRounded />, loanStore?.loanDetails?.maturityDate ?? '-', 'Maturity Date')}
            </Grid>
            <Grid item xs={12} md={4}>
              {renderCard(<UpdateRounded />, loanStore?.loanDetails?.extendedMaturityDate ?? '-', 'Extended Maturity Date')}
            </Grid>
          </Grid>
        </Box>
        {
          extensionRequestStore.checkWarningsForDashboard(loanStore.loanDetails.calculatedLoanData.nextDueDate, loanStore.loanDetails.extendedMaturityDate) === true &&
          <Box mt={3}>
            <ExtensionsWarningsV2 />
          </Box>
        }
        <Box mt={3}>
          <BorrowerPortalCard
            noPaddingCardContent
            cardTitle={'Extensions'}
            cardHeaderAction={
              globalStore.userFeatures.isInsuranceReviewer ? undefined :
                <Button
                  variant="contained"
                  color="primary"
                  disabled={disabledCreateExtesion}
                  onClick={() =>
                    setopenRequestExtensionModal(true)
                  }
                >
                  Request Extension
                </Button>
            }>
            <Box mt={2}>
              {
                !loading && extensionList?.length == 0 &&
                <Box m={1}>
                  <Alert severity="info">
                    There is no extension requested for this loan.
                  </Alert>
                </Box>
              }
              {
                !loading && extensionList?.length > 0 &&
                <>
                  <Box m={1}>
                    <Alert severity="info">
                      Click on the Extension # to see more details about the extension request.
                    </Alert>
                  </Box>
                  <DataGrid
                    columns={columns}
                    rows={extensionList}
                    frameworkComponents={frameworkComponents}
                    domLayout="autoHeight"
                  />
                </>
              }
            </Box>
          </BorrowerPortalCard>
        </Box>
        <StandardDialog
          open={openRequestExtensionModal}
          title=""
          maxWidth="md"
          handleClose={() => setopenRequestExtensionModal(false)}
          dialogContent={
            <ExtensionRequestModal closeModal={() => closeModal()} />
          }
        />
      </>
    );
  }
);
