import { Box } from '@material-ui/core';
import { Button } from '@roc/ui';
import { observer } from 'mobx-react';
import { useGeneralTasksStore } from '../hooks/useGeneralTasksStore';
import { EditTaskDialog } from './editTask/editTaskDialog';
import { useEffect } from 'react';
import { generalTaskStatusOptions } from '../utils/generalTasksConstants';

export const AddNewTaskButton = observer(({ loanId }) => {
  const { generalTasksStore } = useGeneralTasksStore();
  const { editTaskStore } = generalTasksStore;

  useEffect(() => {
    generalTasksStore.setLoanId(loanId);
    generalTasksStore.fetchUserInfoAndAssigneeOptions();
  }, [loanId])

  return (
    <>
      <Box display="flex" justifyContent="flex-end">
        <Button
          variant="contained"
          size="small"
          onClick={() => editTaskStore.openAddTask()}
          color="primary"
          testId="create_task">
            Create task
        </Button>
      </Box>
      <EditTaskDialog />
    </>
  );
});

