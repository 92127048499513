import { Box, Grid } from "@material-ui/core"
import { PropertyCard } from "./components"
import { AppraiserCard } from "./components/appraiserCard"
import { TitleHistoryTable } from "./components/titleHistoryTable"
import { CompAnalysisTable } from "./components/compAnalysisTable"
import { QuickLinks } from "./components/quickLinks"
import { ValuationReviewCard } from "./components/valuationReviewCard"
import { MarketSaleDataComparisonTable } from "./components/marketSaleDataComparisonTable"
import { MarketLiquidityDataTable } from "./components/marketLiquidityDataTable"


export const ValuationReviewDesign = () => {
  return (
    <Box width={'100%'}>
      <Grid container spacing={2} style={{
        width: '100%',
        margin: 'auto',
        padding: 8,
      }}>
        <Grid item xs={12} md={8}>
          <Box mb={2}>
            <PropertyCard />
          </Box>
          <Box>
            <AppraiserCard />
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <QuickLinks />
        </Grid>
        <Grid item xs={12}>
          <Box mb={2}>
            <ValuationReviewCard layout={1} />
          </Box>
          <Box mb={2}>
            <TitleHistoryTable />
          </Box>
          <Box>
            <CompAnalysisTable />
          </Box>
        </Grid>
        <Grid item xs={12} md={8}>
          <Box mb={2}>
            <MarketSaleDataComparisonTable />
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box mb={2}>
            <MarketLiquidityDataTable />
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export const ValuationReviewDesign2 = () => {
  return (
    <Box width={'100%'}>
      <Grid container spacing={2} style={{
        width: '100%',
        margin: 'auto',
        padding: 8,
      }}>
        <Grid item xs={12} md={6}>
          <Box mb={2}>
            <PropertyCard />
          </Box>
          <Box mb={2}>
            <QuickLinks />
          </Box>
          <Box>
            <AppraiserCard />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <ValuationReviewCard layout={2} />
        </Grid>
        <Grid item xs={12}>
          <Box mb={2}>
            <TitleHistoryTable />
          </Box>
          <Box>
            <CompAnalysisTable />
          </Box>
        </Grid>
        <Grid item xs={12} md={8}>
          <Box mb={2}>
            <MarketSaleDataComparisonTable />
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box mb={2}>
            <MarketLiquidityDataTable />
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}