import { Grid, makeStyles } from '@material-ui/core';
import {
  createCurrencyField,
  createSelectField,
  createPercentageField,
  createDateField,
  createSliderField,
} from '@roc/client-portal-shared/components';
import { observer } from 'mobx-react';
import { BridgeProfitAndDscrCalculatorStore } from '../../stores';

interface ProfitAndDscrCalculcalorFormFieldsProps {
  store: BridgeProfitAndDscrCalculatorStore;
  tooltip: string;
}

const useStyles = makeStyles(theme => ({
  slider: {
    marginTop: '2.5em',
  },
}));

const radioFieldOptions = [
  {
    label: 'Yes',
    value: true,
  },
  {
    label: 'No',
    value: false,
  },
];

export enum LoanPurposeOption {
  PURCHASE = 'Purchase',
  REFINANCE = 'Refi',
  PURCHASEANDREHAB = 'Purchase and Rehab',
}

const loanPurposeOptions = [
  {
    label: 'Purchase',
    value: LoanPurposeOption.PURCHASE,
  },
  {
    label: 'Refinance',
    value: LoanPurposeOption.REFINANCE,
  },
];

const loanTermOptions = [
  {
    label: '12',
    value: 12,
  },
  {
    label: '18',
    value: 18,
  },
];
const exitStrategyOptions = [
  {
    label: 'Sale',
    value: 'Sale',
  },
  {
    label: 'Refinance',
    value: 'Refinance',
  },
];

const percentageMarks = [
  {
    value: 0,
    label: '0%',
  },
  {
    value: 100,
    label: '100%',
  },
];


const field = FieldComponent => {
  const WrappedFieldComponent = observer(FieldComponent);
  return props => (
    <Grid item xs={6} md={props.md} style={{ padding: 8 }}>
      <WrappedFieldComponent {...props} />
    </Grid>
  );
};

export const LoanPurpose = field(({ store }: ProfitAndDscrCalculcalorFormFieldsProps) => {
  return createSelectField({
    store,
    label: 'Loan Purpose',
    testId: 'loanPurpose',
    fieldName: 'loanPurpose',
    options: loanPurposeOptions,
  });
});

export const AdquisitionPrice = field(({ store }: ProfitAndDscrCalculcalorFormFieldsProps) => {
  return createCurrencyField({
    store,
    label: 'Acquisition Price',
    testId: 'acquisition',
    fieldName: 'acquisition',
  });
});

export const AsIsValue = field(({ store }: ProfitAndDscrCalculcalorFormFieldsProps) => {
  return createCurrencyField({
    store,
    label: 'As Is Value (if applicable)',
    testId: 'asIsValue',
    fieldName: 'asIsValue',
  });
});

export const CapitalImprovements = field(({ store }: ProfitAndDscrCalculcalorFormFieldsProps) => {
  return createCurrencyField({
    store,
    label: 'Completed Renovations',
    testId: 'capitalImprovements',
    fieldName: 'capitalImprovements',
  });
});

export const RenovationBudget = field(({ store }: ProfitAndDscrCalculcalorFormFieldsProps) => {
  return createCurrencyField({
    store,
    label: 'Renovation Budget (to Complete)',
    testId: 'renovationBudget',
    fieldName: 'renovationBudget',
  });
});

export const AfterRepairValue = field(({ store }: ProfitAndDscrCalculcalorFormFieldsProps) => {
  return createCurrencyField({
    store,
    label: 'After Repair Value (if applicable)',
    testId: 'afterRepairValue',
    fieldName: 'afterRepairValue',
  });
});

export const LoanTerm = field(({ store }: ProfitAndDscrCalculcalorFormFieldsProps) => {
  return createSelectField({
    store,
    label: 'Loan Term (Months)',
    testId: 'loanTerm',
    fieldName: 'loanTerm',
    options: loanTermOptions
  });
});

export const InitialLoanAmount = field(({ store }: ProfitAndDscrCalculcalorFormFieldsProps) => {
  const classes = useStyles();
  return createSliderField({
    store,
    label: 'Initial Loan Amount (% of Cost)',
    testId: 'initialLoanAmount',
    fieldName: 'initialLoanAmount',
    step: 1,
    marks: percentageMarks,
    valueLabelFormat: value => <div>{value}%</div>,
    valueLabelDisplay: 'on',
    className: classes.slider,
  });
});

export const ConstructionHoldback = field(({ store }: ProfitAndDscrCalculcalorFormFieldsProps) => {
  const classes = useStyles();
  return createSliderField({
    store,
    label: 'Construction Holdback',
    testId: 'constructionHoldback',
    fieldName: 'constructionHoldback',
    step: 1,
    marks: percentageMarks,
    valueLabelFormat: value => <div>{value}%</div>,
    valueLabelDisplay: 'on',
    className: classes.slider,
  });
});

export const ExitStrategy = field(({ store }: ProfitAndDscrCalculcalorFormFieldsProps) => {
  return createSelectField({
    store,
    label: 'Exit Strategy',
    testId: 'exitStrategy',
    fieldName: 'exitStrategy',
    options: exitStrategyOptions
  });
});



// For Sale Exit Strategy

export const InterestRate = field(({ store }: ProfitAndDscrCalculcalorFormFieldsProps) => {
  return createPercentageField({
    store,
    label: 'Interest Rate',
    testId: 'interestRate',
    fieldName: 'interestRate',
  });
});

export const PurchaseDate = field(({ store }: ProfitAndDscrCalculcalorFormFieldsProps) => {
  return createDateField({
    store,
    label: 'Purchase Date',
    testId: 'purchaseDate',
    fieldName: 'purchaseDate',
  });
});

export const PayoffAmount = field(({ store }: ProfitAndDscrCalculcalorFormFieldsProps) => {
  return createCurrencyField({
    store,
    label: 'Payoff Amount',
    testId: 'payoffAmount',
    fieldName: 'payoffAmount',
  });
});

export const LoanClosingDate = field(({ store }: ProfitAndDscrCalculcalorFormFieldsProps) => {
  return createDateField({
    store,
    label: 'Loan Closing Date',
    testId: 'loanClosingDate',
    fieldName: 'loanClosingDate',
  });
});

export const Points = field(({ store }: ProfitAndDscrCalculcalorFormFieldsProps) => {
  return createPercentageField({
    store,
    label: 'Points',
    testId: 'points',
    fieldName: 'points',
  });
});

export const MortgageTax = field(({ store }: ProfitAndDscrCalculcalorFormFieldsProps) => {
  return createPercentageField({
    store,
    label: 'Mortgage Tax',
    testId: 'mortgageTax',
    fieldName: 'mortgageTax',
  });
});

export const TransferTax = field(({ store }: ProfitAndDscrCalculcalorFormFieldsProps) => {
  return createPercentageField({
    store,
    label: 'Transfer Tax',
    testId: 'transferTax',
    fieldName: 'transferTax',
  });
});

export const MansionTax = field(({ store }: ProfitAndDscrCalculcalorFormFieldsProps) => {
  return createPercentageField({
    store,
    label: 'Mansion Tax',
    testId: 'mansionTax',
    fieldName: 'mansionTax',
  });
});

export const TitleInsurance = field(({ store }: ProfitAndDscrCalculcalorFormFieldsProps) => {
  return createPercentageField({
    store,
    label: 'Title Insurance',
    testId: 'titleInsurance',
    fieldName: 'titleInsurance',
  });
});

export const BrokerFee = field(({ store }: ProfitAndDscrCalculcalorFormFieldsProps) => {
  return createPercentageField({
    store,
    label: 'Broker Fee',
    testId: 'brokerFee',
    fieldName: 'brokerFee',
  });
});

export const PropertyTaxAndMaintenance = field(({ store }: ProfitAndDscrCalculcalorFormFieldsProps) => {
  return createPercentageField({
    store,
    label: 'Property Tax and Maintenance',
    testId: 'propertyTaxAndMaintenance',
    fieldName: 'propertyTaxAndMaintenance',
  });
});

// For Refinance Exit Strategy

export const ExitLoanRate = field(({ store }: ProfitAndDscrCalculcalorFormFieldsProps) => {
  return createPercentageField({
    store,
    label: 'Exit Loan Rate',
    testId: 'exitLoanRate',
    fieldName: 'exitLoanRate',
  });
});

export const MonthlyRent = field(({ store }: ProfitAndDscrCalculcalorFormFieldsProps) => {
  return createCurrencyField({
    store,
    label: 'Monthly Rent',
    testId: 'monthlyRent',
    fieldName: 'monthlyRent',
  });
});

export const AnnualTaxes = field(({ store }: ProfitAndDscrCalculcalorFormFieldsProps) => {
  return createCurrencyField({
    store,
    label: 'Annual Taxes',
    testId: 'annualTaxes',
    fieldName: 'annualTaxes',
  });
});

export const AnnualHOADues = field(({ store }: ProfitAndDscrCalculcalorFormFieldsProps) => {
  return createCurrencyField({
    store,
    label: 'Annual HOA Dues',
    testId: 'annualHOADues',
    fieldName: 'annualHOADues',
  });
});

export const AnnualInsurance = field(({ store }: ProfitAndDscrCalculcalorFormFieldsProps) => {
  return createCurrencyField({
    store,
    label: 'Annual Insurance',
    testId: 'annualInsurance',
    fieldName: 'annualInsurance',
  });
});


export const SaleFields = ({ store, isRefi, refiStore }) => {
  return (
    <Grid item container xs={12}>
      <InterestRate store={store} />
      {isRefi ? (<>
        <PurchaseDate store={refiStore} />
        <PayoffAmount store={refiStore} />
        <LoanClosingDate store={refiStore} />
      </>) : null}
      <Points store={store} />
      <MortgageTax store={store} />
      <TransferTax store={store} />
      <MansionTax store={store} />
      <TitleInsurance store={store} />
      <BrokerFee store={store} />
      <PropertyTaxAndMaintenance store={store} />
    </Grid>
  )
}
export const RefinanceFields = ({ store }) => {
  return (
    <Grid item container xs={12}>
      <ExitLoanRate store={store} />
      <MonthlyRent store={store} />
      <AnnualTaxes store={store} />
      <AnnualHOADues store={store} />
      <AnnualInsurance store={store} />
    </Grid>
  )
}
