import { FormStore } from '@roc/feature-app-core';
import { GlobalStore } from '@roc/feature-app-core';
import { action, makeObservable } from 'mobx';
import { parseAddress } from '@roc/feature-utils';

export class CompanyFormStore extends FormStore {
  globalStore: GlobalStore;

  constructor(form, globalStore) {
    super(form, globalStore);
    this.globalStore = globalStore;
    makeObservable(this, {
      saveAddressField: action,
    });
  }

  saveAddressField(address: string, addressDetails, geometryDetails) {
    const parsedAddress = parseAddress(
      address,
      addressDetails,
      geometryDetails
    );
    this.onFieldChange('address', address);
    this.onFieldChange('streetNumber', parsedAddress.street_number);
    this.onFieldChange('streetName', parsedAddress.street);
    this.onFieldChange('city', parsedAddress.city);
    this.onFieldChange('state', parsedAddress.state);
    this.onFieldChange('zipcode', parsedAddress.zip);
  }
}

export default CompanyFormStore;
