import EntityStore from "libs/feature-loans/src/loanSubmission/stores/common/entityStore";
import { action, makeObservable, observable, flow } from 'mobx';

export class ExternalEntityStore extends EntityStore {
  selectedEntity?: any;

  constructor(globalStore, parentStore) {
    super(globalStore, parentStore, true);
    this.selectedEntity = null;
    makeObservable(this, {
      setSelectedEntity: action,
      handleLinkEntity: flow,
    });
  }

  setSelectedEntity(entity) {
    this.selectedEntity = entity;
  }

  *handleLinkEntity(borrowerId: string, onSuccess: () => void) {
    if (this.selectedEntity) {
      try {
        yield this.borrowerEntityService.linkEntity(borrowerId, this.selectedEntity);

        this.globalStore.notificationStore.showSuccessNotification({
          message: 'Entity linked successfully!',
        });
        onSuccess();
      } catch (e) {
        this.globalStore.notificationStore.showErrorNotification({
          message: 'Error saving data',
        });
      }
    } else {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Please select an entity.',
      });
    }
  }
}
