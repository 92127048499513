import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import { Box, Grid } from '@material-ui/core';
import { Button } from '@roc/ui';
import { ValuationReviewReportStore } from '../stores/valuationReviewReportStore';
import { observer } from 'mobx-react';

const useStyles = makeStyles({
  root: {
  },
});

export type Props = {
  store: ValuationReviewReportStore,
  propertyId: number
}

export const AppraiserCard = observer((props: Props) => {
  const classes = useStyles();
  const { store, propertyId } = props;
  const appraiserInfo = store?.valuationReviewInfo?.appraiserInformation;

  const downloadAppraisalReport = () => {
    store.downloadAppraisalReport(propertyId);
  }
  return (
    <Card className={classes.root} elevation={0} variant='outlined'>
      <Box display={'flex'} flexDirection={'row'}>
        <Box flexGrow={1}>
          <CardContent style={{
            padding: 16
          }}>
            <Box>
              <Grid container>
                <Grid item xs={6}>
                  <Typography variant="body2" color="textSecondary" component="p">
                    Appraiser
                  </Typography>
                  <Typography variant="h5" component="h2">
                    {appraiserInfo?.appraiserName}
                  </Typography>
                </Grid>
                <Grid item xs={6} style={{
                  textAlign: 'right'
                }}>
                  <Button testId='appraisalReport' size="small"
                    color="default"
                    variant="outlined"
                    onClick={() => downloadAppraisalReport()}>
                    Appraisal Report
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </CardContent>
        </Box>
      </Box>
    </Card>
  );
})
