import { flow, observable, makeObservable } from 'mobx';
import { GlobalStore } from '@roc/feature-app-core';
import { LoanStore } from '@roc/feature-loans';
import { FormStore } from '@roc/feature-app-core';
import { InsuranceProviderService } from './../../../services/documentForms/insuranceApproval/insuranceProviderService';

const insuranceProviderForm = {
  fields: {
    name: {
      value: '',
      error: null,
      rule: 'required',
    },
    phone: {
      value: '',
      error: null,
      rule: 'required|regex:/^\\(?\\d{3}\\)?[\\s.-]?\\d{3}[\\s.-]?\\d{4}$/',
      message: 'Phone must be a number with the format xxx-xxx-xxxx.',
    },
    emailAddress: {
      value: '',
      error: null,
      rule: 'required|email',
      message: 'Email address is required.',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export class InsuranceProviderFormStore extends FormStore {
  private loanStore: LoanStore;
  private globalStore: GlobalStore;
  private insuranceProviderService: InsuranceProviderService;
  requiredInsuranceProviderDetails: boolean;

  constructor(loanStore: LoanStore, globalStore: GlobalStore) {
    super({ ...insuranceProviderForm }, globalStore);

    this.loanStore = loanStore;
    this.globalStore = globalStore;
    this.insuranceProviderService = new InsuranceProviderService();

    this.requiredInsuranceProviderDetails = false;

    makeObservable(this, {
      loadAdvocateBrokerDetails: flow,
      saveInsuranceProviderDetails: flow,
      requiredInsuranceProviderDetails: observable,
    });
  }

  initialize() {
    const { insuranceBroker } = this.loanStore.loanDetails.loanInsurance;

    const areBrokerDetailsRequired = this.globalStore.userFeatures
      ?.closingEntitlements?.areBrokerDetailsRequired;
    const isAdvocateUser = this.globalStore.userFeatures
      ?.isInsuranceReviewerAdvocate;

    this.requiredInsuranceProviderDetails = areBrokerDetailsRequired ?? true;

    this.loadInsuranceProvider(insuranceBroker);

    if (isAdvocateUser && !this.isBrokerDetailsComplete()) {
      this.loadAdvocateBrokerDetails();
    }
  }

  isBrokerDetailsComplete() {
    const { name, phone, emailAddress } = this.getFormValues();
    return name && phone && emailAddress;
  }

  *loadAdvocateBrokerDetails() {
    try {
      const { loanId } = this.loanStore.loanDetails;
      const {
        data,
      } = yield this.insuranceProviderService.getAdvocateBrokerDetails(loanId);

      const response = JSON.parse(data.data);

      this.loadInsuranceProvider(response);
    } catch (err) {
      // do nothing
    }
  }

  private loadInsuranceProvider(data) {
    const { name = '', phone = '', email = '' } = data || {};
    this.loadForm({
      name,
      phone,
      emailAddress: email,
    });
  }

  *saveInsuranceProviderDetails(onSave: () => void) {
    try {
      this.runFormValidation();
      if (this.form.meta.isValid) {
        const { loanId } = this.loanStore.loanDetails;
        const { name, phone, emailAddress } = this.getFormValues();
        yield this.insuranceProviderService.saveInsuranceProviderDetails({
          loanId,
          insuranceBrokerName: name,
          insuranceBrokerPhone: phone,
          insuranceBrokerEmail: emailAddress,
        });

        this.globalStore.notificationStore.showSuccessNotification({
          message: 'Insurance information saved successfully.',
        });

        this.loanStore.refreshLoanDetails();

        if (!onSave) return;
        onSave();
      }
    } catch (err) {
      this.globalStore.notificationStore.showErrorNotification({
        message:
          'An error occurred when saving the insurance information. Please try again.',
      });
    }
  }
}

export default InsuranceProviderFormStore;
