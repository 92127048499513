import { action, flow, makeObservable, observable } from 'mobx';
import { ApiResponse } from '@roc/client-portal-shared/services';
import { GlobalStore, FormStore } from '@roc/feature-app-core';
import { DialogState } from '@roc/ui';
import FundingPreferenceStore, {
  AdditionalBorrowerEntityMember,
} from './fundingPreferenceStore';

const REQUIRED_FIELD = 'This field is required.';

const form = {
  fields: {
    roleInEntity: {
      value: '',
      error: null,
      rule: '',
      message: REQUIRED_FIELD,
    },
    firstName: {
      value: '',
      error: null,
      rule: 'required',
      message: REQUIRED_FIELD,
    },
    middleName: {
      value: '',
      error: null,
      rule: '',
      message: REQUIRED_FIELD,
    },
    lastName: {
      value: '',
      error: null,
      rule: '',
      message: REQUIRED_FIELD,
    },
    suffix: {
      value: '',
      error: null,
      rule: '',
      message: REQUIRED_FIELD,
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export class EditBorrowerStore extends FormStore {
  private globalStore: GlobalStore;
  private fundingPreferenceStore: FundingPreferenceStore;
  private selectedBorrower: AdditionalBorrowerEntityMember;
  dialogState: DialogState = null;

  constructor(globalStore, fundingPreferenceStore) {
    super(form, globalStore);
    this.globalStore = globalStore;
    this.fundingPreferenceStore = fundingPreferenceStore;

    makeObservable(this, {
      dialogState: observable,
      openEdit: action,
      closeDialog: action,
      resetStore: action,
      submit: action,
    });
  }

  openEdit(borrower) {
    this.resetStore();
    this.selectedBorrower = borrower;
    this.loadForm(borrower);
    this.dialogState = DialogState.EDIT;
  }

  closeDialog() {
    this.dialogState = null;
  }

  resetStore() {
    this.selectedBorrower = null;
    this.reset();
  }

  submit() {
    this.runFormValidationWithMessage();
    if (this.form.meta.isValid) {
      const data = {
        ...this.selectedBorrower,
        ...this.getFormValues(),
      };

      const borrowers = this.fundingPreferenceStore.borrowers.concat();
      const i = borrowers.indexOf(this.selectedBorrower);
      borrowers[i] = data;
      this.fundingPreferenceStore.borrowers = borrowers;

      this.closeDialog();
    }
  }
}
