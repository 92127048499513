import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { observer } from "mobx-react";
import { useEffect, useState } from 'react';
import { Box, Grid, Select, TextField, Typography } from '@material-ui/core';
import { AutocompleteField, Button, MultiSelectField, createMultiSelectField, createTextField } from '@roc/ui';
import RichTextWithAttachments from '../../utils/richTextWithAttachments';
import { templatePlaceHolders } from 'libs/feature-communication/src/communication/utils/constants';
import { useUserStore } from "@roc/feature-app-core";
import { HtmlContainer } from '../leadDetails/htmlContainer';
import { MAX_FILE_SIZE } from 'libs/feature-communication/src/communication/utils/constants';
import { useStore } from 'apps/client-portal-public/src/app/hooks';
import { divider } from '../contactDetails/contactDetailsComponent';
import { BaseLeadStore } from 'libs/client-portal-shared/src/app/modules/companyProfile/stores/baseLeadStore/BaseLeadStore';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%'
    },
    contentContainer: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    previewBox: {
      [theme.breakpoints.up('sm')]: {
        width: '100%'
      },
      [theme.breakpoints.up('lg')]: {
        width: '75%',
      },
    },
    divider: {
      position: 'absolute',
      border: '1px solid grey',
      height: '95%',
      [theme.breakpoints.up('lg')]: {
        left: '53%',
      },
      [theme.breakpoints.down('md')]: {
        left: '50%',
      },
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    modalContainer: {
      position: 'relative'
    }
  })
);

export const SendBulkEmailsModal = observer(({ handleClose = () => { }, salesforceLeadStore, type, fromGrid = false }: { handleClose, salesforceLeadStore: BaseLeadStore, type, fromGrid?}) => {
  const { sendEmailsStore } = salesforceLeadStore;
  const { sendBulkEmailFormStore } = sendEmailsStore;
  const { userStore: { userInformation } } = useUserStore();
  const userEmail = salesforceLeadStore.isSalesForce ? salesforceLeadStore.emailAddress : userInformation.username;
  const [attachments, setAttachments] = useState<File[]>([]);
  const [mockAttachments, setMockAttachments] = useState([]);
  const [sendTo, setSendTo] = useState([]);
  const { globalStore } = useStore();

  useEffect(() => {
    fetchData();
    sendEmailsStore.initSentToOptions(type);
  }, []);


  useEffect(() => {
    sendEmailsStore.mockAttachments = mockAttachments;
  }, [mockAttachments]);


  useEffect(() => {
    const lead = salesforceLeadStore.getLeadForEmailPreview();
    sendEmailsStore.updatePreview(userEmail, sendEmailsStore.getContentText, lead);
    setMockAttachments(sendEmailsStore.mockAttachments);
  }, [
    sendBulkEmailFormStore.form.fields.contentText.value,
    sendEmailsStore.selectedTemplateOption
  ]);

  const onFilesChange = (event): void => {
    const { files } = event.target as HTMLInputElement;
    if (files?.length) {
      addFiles(Array.from(files));
    }
  };

  const addFiles = (newFiles: File[]) => {
    const validFiles = newFiles.filter(
      ({ size }) => size < MAX_FILE_SIZE + 1
    );
    setAttachments(currentFiles => [...currentFiles, ...validFiles]);
  }

  const fetchData = async () => {
    sendEmailsStore.getTemplateOptions();
    sendEmailsStore.selectedTemplateOption = '';
  };

  function handleSendClick() {
    let sentToAll = sendEmailsStore.bulkEmails.length > 0 ? false : true;
    sendEmailsStore.sendToAll = sentToAll;
    if (sentToAll) {
      sendEmailsStore.listId = salesforceLeadStore?.option?.value;
    }
    const fields = sendBulkEmailFormStore.form.fields;
    if (sendTo.length > 0 && fields.subject.value && fields.contentText.value) {
      sendEmailsStore.sendBulkEmail(sentToAll, userEmail, attachments, sendTo, fromGrid, salesforceLeadStore?.gridBusinessUnit);
      handleClose();
    } else {
      globalStore.notificationStore.showErrorNotification({
        message: 'Please fill out the form',
      })
    }
  }

  const handleSendClickToSelf = () => {
    sendEmailsStore.sendToSelf(userEmail);
    handleClose();
  }

  const onEditorChange = (value: string) => {
    sendBulkEmailFormStore.onFieldChange('contentText', value);
  }


  const allOption = { label: "All", value: 'all' };
  ;


  const isDisabled = (option) => {
    const selectedValues = sendTo.map(opt => opt.value);
    if (option.value === 'additional2' && !selectedValues.includes('additional1')) return true;
    if (option.value === 'additional3' && !selectedValues.includes('additional2')) return true;
    if (option.value === 'additional4' && !selectedValues.includes('additional3')) return true;
    if (option.value === 'additional5' && !selectedValues.includes('additional4')) return true;
    return false;
  };


  const handleSendToChange = (_, sendTo) => {
    if (sendTo.some(owner => owner.value === allOption.value)) {
      sendTo = [allOption];
    }
    setSendTo(sendTo);
    sendBulkEmailFormStore.onFieldChange('sendTo', sendTo.map(o => o.value))
  };

  const classes = useStyles();
  return (
    <Grid container spacing={2} className={classes.modalContainer}>
      <Grid item style={{ flex: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6">Send to</Typography>
          </Grid>
          <Grid item xs={12}>
            <MultiSelectField
              name={'share-with-owners'}
              options={sendEmailsStore.sendToOptions}
              getOptionSelected={(option, selectedValue) => {
                return option.value == selectedValue.value;
              }}
              value={sendTo}
              onChange={handleSendToChange}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="" />
              )}
              fullWidth
              required
              testId="sendTo"
              getOptionDisabled={(option) => isDisabled(option)}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">Select template</Typography>
            <AutocompleteField
              label=''
              placeHolder='Search template'
              options={sendEmailsStore.templateOptions}
              value={sendEmailsStore.selectedTemplateOption}
              onChange={(newValue) => {
                sendEmailsStore.setSelectedTemplateOption(newValue);
              }}
              onSelect={(option: any) => {
                sendEmailsStore.setSelectedTemplateOption(option);
              }} />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">Subject</Typography>
            {createTextField({
              label: '',
              fieldName: 'subject',
              testId: 'subject',
              store: sendBulkEmailFormStore,
            })}
          </Grid>


          <Grid item container className={classes.contentContainer}>
            <Typography variant="h6">Template</Typography>
            <Grid item xs={12}>
              <RichTextWithAttachments
                mockAttachments={mockAttachments}
                setMockAttachments={setMockAttachments}
                value={sendBulkEmailFormStore.form.fields.contentText.value}
                onChange={onEditorChange}
                attachments={attachments}
                onFilesChange={onFilesChange}
                setAttachments={setAttachments}
                reloadDependencies={[sendEmailsStore.selectedTemplateOption]}
                placeHolders={templatePlaceHolders}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {!!sendEmailsStore.previewHtml && (
        <>
          <div className={classes.divider}></div>
          <Grid item xs={12} md={6} className={classes.container}>
            <Box className={classes.previewBox}>
              <Box>
                <Box>
                  <Typography variant="h6">From: {userEmail}</Typography>
                </Box>
                <Box>
                  <Typography variant="h6">Subject: {sendEmailsStore.sendBulkEmailFormStore.form.fields.subject.value}</Typography>
                </Box>
              </Box>
              <Box>
                <HtmlContainer html={sendEmailsStore.previewHtml} />
              </Box>
            </Box>
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        <Box display="flex" justifyContent="flex-end">
          <Button
            variant="contained"
            color="secondary"
            onClick={handleSendClickToSelf}
            style={{ width: '200px', margin: '0 5px' }}
            testId="next"
          >Send To Myself</Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSendClick}
            style={{ width: '200px', margin: '0 5px' }}
            testId="next"
          >Send</Button>
        </Box>
      </Grid>
    </Grid>
  )
});