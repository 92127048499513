import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu
} from '@material-ui/core';
import { Delete, MoreVert, NavigateNext } from '@material-ui/icons';
import {
  InfiniteScrollGrid
} from '@roc/feature-app-core';
import { DraftLoan } from '@roc/feature-types';
import {
  ConfirmationMenuItem, LinkColumn, MenuItem, SelectFilterComponent,
  SelectFloatingFilterComponent, StatusChip,
  StatusType, TextColumn
} from '@roc/ui';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { routeByLoanSubType, useLoanSubmissionRoutes } from '../../loanSubmission';
import { useLoanRoutes } from '@roc/feature-loans-routes-config';
import { useLoanStore } from '../hooks/useLoanStore';

export const MyDraftLoansGrid = observer(({ columns }) => {
  const { loanStore } = useLoanStore();
  const { loanRoutesConfig } = useLoanRoutes();
  const { loanSubmissionRoutesConfig } = useLoanSubmissionRoutes();
  const [loanId, setLoanId] = useState(0);
  const [currentRow, setCurrentRow] = useState<DraftLoan>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const { push } = useHistory();

  const frameworkComponents = {
    selectFilter: SelectFilterComponent,
    selectFloatingFilter: SelectFloatingFilterComponent,
    loanIdCellRenderer: params =>
      loanStore.isLoanSubtypeSupported(params.node.data?.loanSubType)
        ? LinkColumn({
          ...params,
          url: loanRoutesConfig.loans(params.value).children.dashboard.url,
        })
        : TextColumn(params),
    statusCellRenderer: ({ value }) =>
      value ? (
        <StatusChip label={value} size="small" statusType={StatusType.LOAN} />
      ) : null,
    actionCellRenderer: params => {
      return (
        <IconButton onClick={e => openMenu(e, params.node.data)}>
          <MoreVert color="primary" />
        </IconButton>
      );
    },
  };

  const openMenu = (event: React.MouseEvent<HTMLElement>, record) => {
    const { id } = record;
    setLoanId(id);
    setCurrentRow(record);
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <InfiniteScrollGrid
        columns={columns}
        frameworkComponents={frameworkComponents}
        store={loanStore.myDraftLoansGridStore}
      />
      <Menu
        id="menu-appbar"
        getContentAnchorEl={null}
        anchorEl={anchorEl}
        keepMounted
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={menuOpen}
        onClose={handleMenuClose}
      >
        <MenuItem
          testId={`continue-draft-loan-${loanId}`}
          onClick={() => {
            loanStore.continueDraftLoan(currentRow);
            handleMenuClose();
            push(
              loanSubmissionRoutesConfig.submitloan.children[
                routeByLoanSubType[currentRow?.loanSubType]
              ].url
            );
          }}
        >
          <ListItemIcon>
            <NavigateNext fontSize="small" />
          </ListItemIcon>
          <ListItemText>Continue</ListItemText>
        </MenuItem>
        <ConfirmationMenuItem
          testId={`delete-draft-loan-${loanId}`}
          onClick={() => {
            loanStore.deleteDraftLoan(loanId);
            handleMenuClose();
            loanStore.myDraftLoansGridStore.fetchGridData();
          }}
          confirmDialogProps={{
            body: `Are you sure you want to delete this draft loan?`,
          }}
        >
          <ListItemIcon>
            <Delete fontSize="small" />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </ConfirmationMenuItem>
      </Menu>
    </>
  );
});
