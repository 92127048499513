import { GlobalStore } from "@roc/feature-app-core";
import { ReferralFeeStore } from "./referralFeeStore";
import { makeObservable, override, flow } from "mobx";
import { ReferralFeeExternalService } from "../services/referralFeeExternalFeeService";
import { GENERIC_ERROR_MESSAGE } from "@roc/feature-utils";

export class ReferralFeeExternalStore extends ReferralFeeStore {
  private referralFeeService: ReferralFeeExternalService;

  constructor(globalStore: GlobalStore) {
    super(globalStore);
    this.referralFeeService = new ReferralFeeExternalService();
    makeObservable(this, {
      referralLink: override,
      getReferralFeeLink: flow,
      getBankInformation: flow,
      approveBankInformation: flow
    })
  }

  *getReferralFeeLink(loanId: string) {
    this.referralFeeService.getReferralFeeLink(loanId).then(response => {
      this.referralLink = response.data.data;
    }).catch(error => this.globalStore.notificationStore.showErrorNotification({
      message: 'Error getting referral fee authorization link'
    }));
  }

  *getBankInformation(referrerId: string) {
    this.referralFeeService.getBankInfo(referrerId).then(response => {
      this.bankInfo = response.data
    });
  }

  *approveBankInformation(referrerId: string, callback: () => void) {
    this.referralFeeService.approveBankInformation(referrerId).then(response => {
      this.bankInfo = null;
      callback();
    }).catch(error => this.globalStore.notificationStore.showErrorNotification({ message: GENERIC_ERROR_MESSAGE }));
  }
}