interface GridMeta {
  pageNumber: number;
  pageSize: number;
  sortDir: 'ASC' | 'DESC';
  sortBy: string;
  filters: object;
  dropdownFilters: object;
}

function filterRows(rows: Array<object>, gridMeta: GridMeta) {
  const filters = {
    ...gridMeta.filters,
    ...gridMeta.dropdownFilters,
  };

  const rowMatches = row =>
    Object.keys(filters)
      .filter(columnName => !!filters[columnName])
      .every(columnName => {
        const filter = filters[columnName];
        const value = '' + row[columnName];
        return filter.split(',').some(option => value.includes(option));
      });

  return rows.filter(rowMatches);
}

function sortRows(rows: Array<object>, gridMeta: GridMeta) {
  const { pageNumber, pageSize, sortDir, sortBy } = gridMeta;
  let sortedRows = [...rows];

  if (sortBy) {
    const isColumnString = typeof rows?.[0]?.[sortBy] === 'string';
    if (isColumnString) {
      sortedRows.sort((rowA, rowB) => {
        return sortDir === 'ASC'
          ? rowA[sortBy].localeCompare(rowB[sortBy])
          : rowB[sortBy].localeCompare(rowA[sortBy]);
      });
    } else {
      sortedRows.sort((rowA, rowB) => {
        return sortDir === 'ASC'
          ? rowA[sortBy] - rowB[sortBy]
          : rowB[sortBy] - rowA[sortBy];
      });
    }
  }

  return sortedRows;
}

export function getPageFromObject(rows: Array<object>, gridMeta: GridMeta) {
  const { pageNumber, pageSize, sortDir, sortBy } = gridMeta;

  const filteredRows = filterRows(rows, gridMeta);
  const sortedRows = sortRows(filteredRows, gridMeta);

  const pageStart = pageSize * (pageNumber - 1);
  const pageEnd = pageStart + pageSize;
  return {
    rows: sortedRows.slice(pageStart, pageEnd),
    totalCount: sortedRows.length,
  };
}
