import React, { useEffect } from 'react';
import {
  Box,

  Dialog,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  DialogTitle as MuiDialogTitle,
  IconButton,
  Typography,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Grid
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { WithStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { Button } from '@roc/ui';
import { observer } from 'mobx-react';
import { Close } from '@material-ui/icons';
import { AvailableUntil, FieldContainer, FinishedBasement, NeedsRepairs, SellingPrice, Bedrooms, Bathrooms, FieldContainerNoSpacing, FlipAssumptions, RentalAssumptions, SquareFootage, PotentialExitStrategy } from '@roc/ui/formComponents';
import {
  PropertyAddress,
  PropertyType
} from '@roc/ui/formComponents';
import { ProspectPropertyFormStore } from '../stores/prospectPropertyFormStore';
import { ImageUploadPreview } from './imagePreviewUpload';


function getSteps() {
  return [
    'Property information',
    'Home Photos'
  ];
}

function getStepContent(step: number, prospectPropertyFormStore: ProspectPropertyFormStore) {

  const propertyTypeOptions = [
    { value: 'Single-Family', label: 'Single-Familiy' },
    { value: 'Duplex', label: 'Duplex' },
    { value: 'Triplex', label: 'Triplex' },
    { value: 'Multi-Family', label: 'Multi-Family' }
  ];

  const exitOptions = [
    { value: 'Flip', label: 'Flip' },
    { value: 'Rental', label: 'Rental' },
  ];


  switch (step) {
    case 0:
      return (
        <>
          <FieldContainer>
            <PropertyAddress
              store={prospectPropertyFormStore}
              required
              disabled={false}
            />
          </FieldContainer>
          <Grid style={{ display: 'flex', justifyContent: 'center', width: '85%', margin: '0 85px' }}>
            <FieldContainerNoSpacing>
              <PropertyType
                store={prospectPropertyFormStore}
                propertyTypeOptions={propertyTypeOptions}
                disabled={false}
              />
            </FieldContainerNoSpacing>
            <FieldContainerNoSpacing>
              <PotentialExitStrategy
                store={prospectPropertyFormStore}
                exitOptions={exitOptions}
                disabled={false}
              />
            </FieldContainerNoSpacing>
          </Grid>
          <Grid style={{ display: 'flex', justifyContent: 'center', width: '85%', margin: '0 85px' }}>
            <FieldContainerNoSpacing>
              <SellingPrice
                store={prospectPropertyFormStore}
                disabled={false}
              />
            </FieldContainerNoSpacing>
            <FieldContainerNoSpacing>
              <AvailableUntil
                store={prospectPropertyFormStore}
              />
            </FieldContainerNoSpacing>
          </Grid>
          <Grid style={{ display: 'flex', justifyContent: 'center', width: '85%', margin: '0 85px' }}>
            <FieldContainerNoSpacing>
              <Bedrooms
                store={prospectPropertyFormStore}
              />
            </FieldContainerNoSpacing>
            <FieldContainerNoSpacing>
              <Bathrooms
                store={prospectPropertyFormStore}
              />
            </FieldContainerNoSpacing>
          </Grid>
          <Grid style={{ display: 'flex', justifyContent: 'center', width: '85%', margin: '0 85px' }}>
            <FieldContainerNoSpacing>
              <NeedsRepairs
                store={prospectPropertyFormStore}
              />
            </FieldContainerNoSpacing>
            <FieldContainerNoSpacing>
              <SquareFootage
                store={prospectPropertyFormStore}
              />
            </FieldContainerNoSpacing>
          </Grid>
          <FieldContainer>
            <FinishedBasement
              store={prospectPropertyFormStore}
            />
          </FieldContainer>
          <FieldContainer>
            <FlipAssumptions
              store={prospectPropertyFormStore}
            />
          </FieldContainer>
          <FieldContainer>
            <RentalAssumptions
              store={prospectPropertyFormStore}
            />
          </FieldContainer>
        </>
      );
    case 1:
      return (
        <Box display={'flex'} justifyContent={'center'}>
          <Box width={'70%'} >
            <ImageUploadPreview store={prospectPropertyFormStore} />
          </Box>
        </Box>
      )
    default:
      return 'Unknown step';
  }
}

function isFormValid(step: number, store: ProspectPropertyFormStore) {
  switch (step) {
    case 0:
      return store.form.meta.isValid;
    case 1:
      return store.images.length > 0;
    default:
      return 'Unknown step';
  }
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    button: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    actionsContainer: {
      marginBottom: theme.spacing(2),
    },
    resetContainer: {
      padding: theme.spacing(3),
    },
  })
);

export const SellPropertySteps = observer(
  ({ store }) => {
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps();

    const handleNext = () => {
      if (activeStep === steps.length - 1) {
        store.handleAdd();
        return;
      }
      setActiveStep(prevActiveStep => prevActiveStep + 1);
    };

    const handleBack = () => {
      setActiveStep(prevActiveStep => prevActiveStep - 1);
    };

    return (
      <div className={classes.root}>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
              <StepContent TransitionProps={{ unmountOnExit: false }}>
                {getStepContent(index, store?.prospectPropertiesFormStore)}
                <div className={classes.actionsContainer}>
                  <FieldContainer>
                    <>
                      <Button
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        className={classes.button}
                        testId="back"
                      >
                        Back
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          if (activeStep === 0) {
                            handleNext();
                            return;
                          }
                          //submit
                        }}
                        className={classes.button}
                        testId="next"
                        disabled={!isFormValid(index, store?.prospectPropertiesFormStore)}
                      >
                        {index != 1 ? 'Next' : 'Submit'}
                      </Button>
                    </>
                  </FieldContainer>
                </div>
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </div>
    );
  }
);

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const styles = (theme: Theme) =>
  createStyles({
    dialogContetBox: {
      width: '100%',
      margin: 0,
      position: 'absolute',
      top: '50%',
      left: '50%',
      msTransform: 'translate(-50%, -50%)',
      transform: 'translate(-50%, -50%)',
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography {...other}>
      <Typography variant="h4">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <Close />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    height: '100%',
  },
}))(MuiDialogContent);

export const PropertyModal = ({ store }) => {

  const handleDialogClose = () => {
    store.isAddPropertyModalOpen = false;
    store.prospectPropertiesFormStore.images = [];
  };

  return (
    <div>
      <Dialog
        disableEscapeKeyDown
        scroll={'paper'}
        fullWidth={true}
        maxWidth={'lg'}
        open={store.isAddPropertyModalOpen}
        onClose={handleDialogClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle
          id="property-dialog-title"
          onClose={() => handleDialogClose()}
        >
          Sell New Property
        </DialogTitle>
        <DialogContent>
          <Box>
            <SellPropertySteps store={store} />
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}