
import { Page } from '@roc/client-portal-shared/components';
import { Route } from 'react-router';
import { Apps } from '../apps';


export const getAppsRoutesConfig = (
  basePath: string,
  baseUrl: string
) => {

  return {
    apps: {
      path: `${basePath}/apps`,
      url: `${baseUrl}/apps`,
      documentTitle: 'Apps',
    },
  };
};

export const getInternalAppsRoutesConfig = (
  basePath: string,
  baseUrl: string
) => {

  return {
    apps: {
      path: `${basePath}/apps`,
      url: `${baseUrl}/apps`,
      documentTitle: 'Apps',
    },
  };
};

export const getAppsRoutes = (basePath: string, baseUrl: string) => {
  const config = getAppsRoutesConfig(basePath, baseUrl);
  return {
    apps: (
      <Route exact path={config.apps.path}>
        <Page routeDefinition={config.apps}>
          <Apps showInternalApps={false} />
        </Page>
      </Route>
    ),
  };
};

export const getInternalAppsRoutes = (basePath: string, baseUrl: string) => {
  const config = getInternalAppsRoutesConfig(basePath, baseUrl);
  return {
    apps: (
      <Route exact path={config.apps.path}>
        <Page routeDefinition={config.apps}>
          <Apps showInternalApps={true} />
        </Page>
      </Route>
    ),
  };
};
