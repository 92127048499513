import { PropertyInformationSteps } from '../utils/constants';

export abstract class BasePropertiesStore {
  abstract reset();
  abstract getPropertyInformationActiveStep(): PropertyInformationSteps;
  abstract setPropertyInformationActiveStep(value: PropertyInformationSteps);
  abstract getProperties(): any[];
  abstract handlePropertyInformationBack();
  abstract handlePropertyInformationNextStep();
}
