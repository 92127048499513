import { GlobalStore } from '@roc/client-portal-shared/stores';
import { action, computed, flow, makeObservable, observable } from 'mobx';
import { FormStore } from '@roc/feature-app-core';
import {
  CalculatedPricerValues,
  InternalPricerFormValues
} from '../types/internalPricerTypes';
import { LoanSubtypeOptions, } from '../utils/internalPricerConstants';
import { calcAsIsValue, calcLoanAmount, calcPointSplit, calcYieldSpreadPremium, mapAmortization, mapCreditScore, mapDebtYield, mapDSCR, mapExperience, mapForeignNational, mapGeoTier, mapLoanSubtype, mapLoanToPricerFormValues, mapLtv, mapNumberOfUnits, mapPPPMethods, mapPurpose, mapRateType, mapRecourse } from '../utils/internalPricerUtils';
import { ActionsColumn } from '../../../components/grid';

const form = {
  fields: {
    rateType: {
      value: '',
      error: null,
      rule: 'required'
    },
    creditScore: {
      value: '',
      error: null,
      rule: 'required',
    },
    requestedLtv: {
      value: '',
      error: null,
      rule: 'required',
      attribute: 'requested LTV',
    },
    loanPurpose: {
      value: '',
      error: null,
      rule: 'required',
    },
    amortizationType: {
      value: '',
      error: null,
      rule: 'required',
    },
    propertyType: {
      value: '',
      error: null,
      rule: 'required',
    },
    propertyTypes: {
      value: null
    },
    dscr: {
      value: '',
      error: null,
      rule: 'required',
    },
    prePaymentPenalty: {
      value: '',
      error: null,
      rule: 'required',
    },
    foreignNationalFlag: {
      value: '',
      error: null,
      rule: 'required',
      message: 'This question is required.'
    },
    borrowerExp: {
      value: '',
      error: null,
      rule: 'required',
    },
    geoTier: {
      value: '',
      error: null,
      rule: '',
    },
    state: {
      value: '',
      error: null,
      rule: ''
    },
    county: {
      value: '',
      error: null,
      rule: ''
    },
    loanSubtype: {
      value: LoanSubtypeOptions.SINGLE_PROPERTY,
      error: null,
      rule: 'required'
    },
    debtYield: {
      value: '',
      error: null,
      rule: [{ required_if_one_of: ['loanSubtype', LoanSubtypeOptions.PORTFOLIO] }]
    },
    fullRecourse: {
      value: '',
      error: null,
      rule: [{ required_if_one_of: ['loanSubtype', LoanSubtypeOptions.PORTFOLIO] }],
      message: 'The recourse is required.'
    },
    targetPrice: {
      value: '',
      error: null,
      rule: [{ required_if: ['__targetPriceRequired__', true] }],
      message: 'The target price is required.'
    },
    asIsValue: {
      value: '',
      error: null,
      rule: 'required',
    },
    numberOfUnits: {
      value: 1
    },
    numberOfProperties: {
      value: '',
      error: null,
      rule: [{ required_if_one_of: ['loanSubtype', LoanSubtypeOptions.PORTFOLIO] }, 'numeric'],
      message: 'Number of Properties must be 2 or more'
    },
    isClientPortal: {
      value: false,
      error: null,
      rule: ''
    },
    __targetPriceRequired__: {
      value: false,
      error: null,
      rule: ''
    },
    currentBuydown: {
      value: 0,
      error: null,
      rule: ''
    },
    yieldSpread: {
      value: 0,
      error: null,
      rule: ''
    },
    lenderId: {
      value: '',
      error: null,
      rule: [{ required_if: ['isClientPortal', false] }],
      message: 'The lender is required.'
    },
    totalOriginationPoints: {
      value: 0,
      error: null,
      rule: ''
    },
    totalFees: {
      value: 0,
      error: null,
      rule: ''
    },
    brokerName: {
      value: '',
      error: null,
      rule: ''
    },
    brokerFees: {
      value: 0,
      error: null,
      rule: ''
    }
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export abstract class InternalPricerBaseStore extends FormStore {
  private globalStore: GlobalStore;
  public isBroker: boolean = false;
  public exceptions: string[];
  public swapRates: any;
  public exceptionCounter: number;
  public maxInvestorLtvBands: [];
  public currentInvestorLtvBands: [];
  public calculatedPricerValues: CalculatedPricerValues;
  public allCounties;
  public marketTier;
  public maxBuydown = 0.4;
  public maxYsp;

  abstract getRatesDictionary(data: InternalPricerFormValues);
  abstract getLoanValues(loanId: string, isDraft: boolean): InternalPricerFormValues;
  abstract getAutocompleteOptions(searchTerm: string);
  abstract getDefaultValues(): InternalPricerFormValues;
  abstract getPricerValues(formValues: any);
  abstract getStateCounties();
  abstract getMonthlyPayment(data: any);
  abstract getMarketTier(stateCounty: any);
  abstract getLenderNames();

  loanSearchText: string = '';
  loanSearchOptions: { label: string; value: string }[] = [];

  calculatedValues: any[];
  calculatedBestLtv: any;
  defaultValues: any;
  investors: any;
  isClientPortal: boolean;
  showSensitiveInfo: boolean;
  ltvRates: any;
  isYspOverMax: boolean;

  constructor(globalStore) {
    super(form, globalStore);
    this.globalStore = globalStore;

    makeObservable(this, {
      loanSearchText: observable,
      loanSearchOptions: observable,
      calculatedValues: observable,
      calculatedBestLtv: observable,
      defaultValues: observable,
      isBroker: observable,
      getRatesDictionary: flow,
      getLoanValues: flow,
      getAutocompleteOptions: flow,
      getDefaultValues: flow,
      getStateCounties: flow,
      getMarketTier: flow,
      fetchOptions: flow,
      fetchLoanValues: flow,
      fetchRatesDictionary: flow,
      fetchDefaultValues: flow,
      fetchStateCounties: flow,
      fetchMarketTier: flow,
      getMonthlyPayment: flow,
      fetchMonthlyPayment: flow,
      getPricerValues: flow,
      investors: observable,
      ltvRates: observable,
      isClientPortal: observable,
      allCounties: observable,
      marketTier: observable,
      isYspOverMax: observable,
      counties: computed,
      maxBuydown: observable,
      buydownList: computed,
      loanAmount: computed,
      getLenderNames: flow,
      maxYsp: observable,
      setShowSensitiveInfo: action,
      showSensitiveInfo: observable,
      yieldSpreadPremium: computed,
      pointSplit: computed,
      underwritingProcessingFees: computed,
      pricerFeesEnabled: computed,
    });
  }

  get counties() {
    const states = this.form.fields.state.value
      ? [this.form.fields.state.value]
      : [];
    let selectedStateCounties = [];
    if (states && states.length > 0 && this.allCounties) {
      selectedStateCounties = states.reduce(
        (c, s) => [...c, ...(this.allCounties[s] ?? [])],
        []
      );
    }

    let counties = [];
    if (selectedStateCounties && selectedStateCounties.length > 0) {
      selectedStateCounties.sort().map(county => {
        counties = [...counties, { value: county, label: county }];
      });
    }
    return counties;
  }

  get buydownList() {
    let buydownList = [];
    for (let i = 0; i <= this.maxBuydown; i = parseFloat((i + 0.01).toFixed(4))) {
      buydownList.push({
        label: (i * 100).toFixed(0) + ' bps',
        value: i
      });
    }
    return buydownList;
  }

  get loanAmount() {
    return calcLoanAmount(this.form.fields.asIsValue.value, this.form.fields.requestedLtv.value);
  }

  get yieldSpreadPremium() {
    return calcYieldSpreadPremium(this.form.fields.yieldSpread.value, this.loanAmount);
  }

  get pointSplit() {
    return calcPointSplit(this.form.fields.totalOriginationPoints.value, this.loanAmount);
  }

  get underwritingProcessingFees() {
    return this.form.fields.totalFees.value || 0;
  }

  get pricerFeesEnabled() {
    return this.globalStore.userFeatures?.pricerFeesEnabled || !this.isClientPortal;
  }

  resetStore({ keepSearchText = false }) {
    if (!keepSearchText) {
      this.loanSearchText = '';
      this.loanSearchOptions = [];
    }
    this.calculatedValues = null;
    this.calculatedBestLtv = null;

    this.reset();
  }
  //loan search
  *fetchOptions(searchText: string) {
    try {
      if (!searchText) {
        this.loanSearchOptions = [];
        return;
      }
      return this.getAutocompleteOptions(searchText);
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'An error occurred while loading the list of loans.',
      });
    }
  }
  //loan search
  *fetchLoanValues(loanId: string, isDraft: boolean) {
    try {
      const loanValues = yield this.getLoanValues(loanId, isDraft);
      const values = mapLoanToPricerFormValues(loanValues);
      const loanSubtype = mapLoanSubtype(loanValues.loanSubType);

      this.loadForm({ loanSubtype });
      yield this.fetchDefaultValues();
      this.loadForm(values);

      const loanPurpose = mapPurpose(loanValues.loanPurpose);
      const dscr = mapDSCR(loanValues.dscr);
      const amortizationType = mapAmortization(loanValues.amortizationType);
      const prePaymentPenalty = mapPPPMethods(loanValues.prePaymentPenalty);
      const rateType = mapRateType(loanValues.rateType);
      const foreignNationalFlag = mapForeignNational(loanValues.isBorrowerForeignNational);
      const creditScore = mapCreditScore(loanValues.creditScore);
      const fullRecourse = mapRecourse(loanValues.recourse);
      const county = loanValues.county + ", " + loanValues.state;
      const requestedLtv = mapLtv(loanValues.requestedLtv);
      const borrowerExp = mapExperience(loanValues.borrowerExp);
      const debtYield = mapDebtYield(loanValues.debtYield);
      const asIsValue = calcAsIsValue(loanValues.amount, loanValues.requestedLtv);
      this.loadForm({
        loanPurpose,
        dscr,
        amortizationType,
        prePaymentPenalty,
        rateType,
        foreignNationalFlag,
        creditScore,
        asIsValue,
        fullRecourse,
        county,
        requestedLtv,
        borrowerExp,
        debtYield,
      });
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'An error occurred while loading the loan data.',
      });
    }
  }

  *fetchRatesDictionary() {
    try {
      this.runFormValidationWithMessage();
      if (this.form.meta.isValid) {
        const formValues = this.getFormValues();
        Object.keys(formValues).forEach(key => {
          formValues[key] = formValues[key] === '' ? null : formValues[key];
        });
        formValues["numberOfUnits"] = mapNumberOfUnits(formValues["propertyType"]);
        formValues["upbPerProperty"] = this.loanAmount;
        if (formValues["loanSubtype"] === LoanSubtypeOptions.PORTFOLIO) {
          formValues["propertyTypes"] = [formValues["propertyType"]];
          formValues["propertyType"] = null;
          formValues["upbPerProperty"] = this.loanAmount / parseFloat(formValues["numberOfProperties"]);
        }
        formValues["propertyCounties"] = [formValues["county"]];
        formValues["propertyStates"] = [formValues["state"]];

        const response = yield this.getRatesDictionary({
          ...formValues,
          isBroker: this.isBroker,
          loanAmount: this.loanAmount,
          underwritingProcessingFees: [this.pointSplit, parseFloat(formValues["totalFees"])],
          pricerFeesEnabled: this.pricerFeesEnabled,
        });
        this.investors = response?.allInvestorRates;
        this.ltvRates = response?.ltvRates;
        this.isYspOverMax = response?.isYspOverMaxBasedOnAmortization ? response.isYspOverMaxBasedOnAmortization[this.form.fields.amortizationType.value] : false;
        this.maxYsp = response?.maxYspBasedonAmortizationType[this.form.fields.amortizationType.value];

        yield this.getPricerValues(formValues);
      }
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'An error occurred while loading the calculated values.',
      });
    }
  }

  * fetchDefaultValues() {
    try {
      if (!this.defaultValues) {
        this.defaultValues = yield this.getDefaultValues();
      }
      const loanSubtype = this.form.fields.loanSubtype.value;
      const defaults = { ...this.defaultValues[loanSubtype], loanSubtype, isClientPortal: this.isClientPortal, __targetPriceRequired__: this.showSensitiveInfo };
      this.resetStore({ keepSearchText: true });
      this.loadForm(defaults);
      this.fetchStateCounties();
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'An error occurred while loading the default values.',
      });
    }
  }

  *fetchStateCounties() {
    try {
      const response = yield this.getStateCounties();
      this.allCounties = response;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while getting state counties.',
      });
    }
  }

  *fetchMonthlyPayment(rate) {
    const request = {
      loanAmount: this.loanAmount,
      rate: rate,
      fullyAmortizing: this.form.fields.amortizationType.value === 'fullyAmortizing'
    }
    try {
      return yield this.getMonthlyPayment(request);
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while getting monthly payment.',
      });
    }
  }

  *fetchMarketTier() {
    const { county } = this.getFormValues();

    try {
      const response = yield this.getMarketTier(county);
      const mappedGeoTier = mapGeoTier(response.data.tier);
      if (county) {
        this.onFieldChange('geoTier', mappedGeoTier);
      }
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while getting market tier.',
      });
    }
  }

  resetYieldSpread() {
    this.form.fields.yieldSpread.value = 0;
  }

  setShowSensitiveInfo(showSensitiveInfo: boolean) {
    this.showSensitiveInfo = showSensitiveInfo;
  }
}