import { Grid, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { CurrencyField, Slider } from '@roc/ui';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { formatCurrency } from '@roc/ui/utils';
import { FixFlipStore } from '../stores';

const useStyles = makeStyles(theme =>
  createStyles({
    totalsContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-around',
      backgroundColor: '#F7F7F7',
      marginTop: '24px',
      paddingTop: '8px',
      paddingBottom: '8px',
    },
    singleTotalContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    label: {
      color: '#333333'
    },
    amountLabel: {
      fontSize: '1.2rem',
      fontWeight: 'bold',
    },
    separatorLabel: {
      fontSize: '3rem',
      color: '#BABEC5',
    },
    greenText: {
      color: '#34A853'
    },
  })
);

export const TotalAmounts = observer(({ store, initialAmount, ltc }: { store: FixFlipStore, initialAmount: number, ltc: number }) => {
  const classes = useStyles();
  const { loanInformationStore } = store;

  return (
    <Grid>
      {/* Totals */}
      <Grid className={classes.totalsContainer}>
        <Grid className={classes.singleTotalContainer}>
          <Typography className={classes.label}>Initial Advance</Typography>
          <Typography className={`${classes.amountLabel} ${classes.greenText}`}>{formatCurrency(initialAmount)}</Typography>
        </Grid>
        <Grid className={classes.separatorLabel}>
          /
        </Grid>
        <Grid className={classes.singleTotalContainer}>
          <Typography className={classes.label}>Purchase Price</Typography>
          <Typography className={classes.amountLabel}>{formatCurrency(loanInformationStore.getTotalPurchasePrice())}</Typography>
        </Grid>
        <Grid className={classes.separatorLabel}>=</Grid>
        <Grid className={classes.singleTotalContainer}>
          <Typography className={classes.label}>Loan To Cost</Typography>
          <Typography className={classes.amountLabel}>{ltc || 0}%</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
});

export default TotalAmounts;
