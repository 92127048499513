import { Box, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { observer } from "mobx-react";
import { AppraisalAutomatedReviewSection } from "./appraisalAutomatedReviewSection";
import { useEffect } from "react";
import { AppraisalReviewStore } from "../stores/appraisalReviewStore";
import { StandardDialog } from "@roc/ui";
import { ResolutionForm, useResolutionStore } from "@roc/feature-borrower-review";
import { Tooltip } from '@material-ui/core';
import { Help } from '@material-ui/icons';

export const useStyles = makeStyles(theme => ({
  addressWrapper: {
    '& .MuiInputBase-root.Mui-disabled': {
      backgroundColor: theme.palette.grey[50],
    },
    marginBottom: '30px',
    width: '100%',
  },
  addressGrid: {
    padding: '25px 25px 0 25px',
  },
  tableContainer: {
    borderRadius: '10px',
    border: '1px #e0e0e0 solid',
  },
  table: {
    margin: 0,
    padding: 0,
    '& td': {
      border: '1px solid #e0e0e0',
    },
    '& th:first-child, td[rowSpan], $addNewItemRow td': {
      paddingLeft: theme.spacing(2),
      paddingRight: '3px',
    },
    '& table tr:last-child td, & table td[rowSpan]': {
      borderBottom: 'none',
    },
  },
  titleCell: {
    height: '46px',
    paddingTop: 10,
    paddingLeft: 16,
    overflow: 'hidden',
    backgroundColor: '#E2F6FF',
    color: '#757575',
    fontSize: 20,
    fontWeight: 400
  },
  title: {
    fontSize: 20
  },
}));

interface AppraisalAutomatedReviewProps {
  loanId: number;
  propertyId: number;
  store: AppraisalReviewStore;
}

export const AppraisalAutomatedReview = observer(({ loanId, propertyId, store }: AppraisalAutomatedReviewProps) => {
  const classes = useStyles();
  const { resolutionStore } = useResolutionStore();

  useEffect(() => {
    resolutionStore.setInternal(true);
  }, []);

  useEffect(() => {
    store.getAutomatedReviewItems(propertyId, loanId);
  }, [store, propertyId, loanId]);

  const handleCloseResolutionInfo = () => {
    resolutionStore.setResolution(null);
    resolutionStore.setResolutionInfoOpen(false);
  }

  return (
    <Box mb={3}>
      <StandardDialog
        open={resolutionStore.resolutionInfoOpen}
        title={<div>
          Provide Resolution
          <Tooltip
            placement="right"
            title={<p style={{ fontSize: '12px' }}> {resolutionStore.tooltTipTitle} </p>}
          >
            <Help color="primary" style={{ fontSize: '18px', marginLeft: '3px', marginBottom: '10px' }} />
          </Tooltip>
        </div>}
        maxWidth="sm"
        handleClose={handleCloseResolutionInfo}
        dialogContent={
          <Grid container>
            <ResolutionForm
              handleClose={handleCloseResolutionInfo}
              loanDocumentId={resolutionStore.documentId}
              readOnly={resolutionStore.readOnly}
              internal={true}
            />
          </Grid>
        }
      />
      <TableContainer className={classes.tableContainer}>
        <Table className={classes.table} >
          <TableHead>
            <TableRow>
              <TableCell className={classes.titleCell}>
                <Typography className={classes.title}>Appraisal Review</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <Grid container item direction="column" className={classes.addressGrid} xs={12} alignContent="center">
                {store.automatedReviewSectionsToDisplay?.map(section => {
                  return (
                    <Grid className={classes.addressWrapper}>
                      <AppraisalAutomatedReviewSection section={section} store={store} />
                    </Grid>
                  )
                })}
              </Grid>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
});