import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { CheckCircle } from '@material-ui/icons';
import { useLoanStore } from '@roc/feature-loans';
import { isFundedLoan } from '@roc/feature-utils';
import { formatCurrency, formatPercentage } from '@roc/ui/utils';
import { observer } from 'mobx-react';
import { CustomCard, CustomCardDividers, CustomCardRow } from '../customCard';
import {
  LoanDetailsCustomCard,
  LoanDetailsInfoItem,
} from '../loanDetailsCustomCard';

export const KeyDatesCardTerm = observer(() => {
  const { loanStore } = useLoanStore();
  const { loanDetails } = loanStore;
  const showLoanExpirationDates = !isFundedLoan(loanDetails?.status);

  return (
    <CustomCard title="Key Dates">
      <CustomCardDividers>
        {showLoanExpirationDates ? (
          <>
            <CustomCardRow
              label={'COGS Expiration Date'}
              value={loanStore?.cogsExpirationDate}
            />
            <CustomCardRow
              label={'Credit Expiration Date'}
              value={loanStore?.creditExpirationDate}
            />
            <CustomCardRow
              label={'Appraisal Expiration Date'}
              value={loanStore?.appraisalExpirationDate}
            />
          </>
        ) : (
          <CustomCardRow
            label={'Next Due Date'}
            value={loanDetails?.calculatedLoanData?.nextDueDate}
          />
        )}
      </CustomCardDividers>
    </CustomCard>
  );
});

export const KeyDatesCardBridge = observer(() => {
  const { loanStore } = useLoanStore();
  const { loanDetails } = loanStore;
  const showLoanExpirationDates = !isFundedLoan(loanDetails?.status);

  /*

              */
  return (
    <CustomCard title="Key Dates">
      <CustomCardDividers>
        <CustomCardRow
          label={'Submission'}
          value={loanDetails?.submissionDate}
        />
        <CustomCardRow
          label={'Exp. Closing'}
          value={loanDetails?.expectedClosingDate}
        />
        <CustomCardRow label={'Agreed Date'} value={loanDetails?.agreedDate} />
        <CustomCardRow
          label={'Closing Date'}
          value={loanDetails?.closingDate}
        />
        <CustomCardRow label={'PayOff Date'} value={loanDetails?.payoffDate} />
        <CustomCardRow label={'Maturity'} value={loanDetails?.maturityDate} />
        {showLoanExpirationDates && (
          <>
            <CustomCardRow
              label={'COGS Expiration Date'}
              value={loanStore?.cogsExpirationDate}
            />
            <CustomCardRow
              label={'Credit Expiration Date'}
              value={loanStore?.creditExpirationDate}
            />
            <CustomCardRow
              label={'Appraisal Expiration Date'}
              value={loanStore?.appraisalExpirationDate}
            />
          </>
        )}
        <CustomCardRow
          label={'Next Due Date'}
          value={loanDetails?.calculatedLoanData?.nextDueDate}
        />
      </CustomCardDividers>
    </CustomCard>
  );
});
