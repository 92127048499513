import { Grid } from '@material-ui/core';
import {
  createSelectField,
  createDateField,
  createNumberFormatField
} from '@roc/client-portal-shared/components';
import { observer } from 'mobx-react';
import { EscrowDepositCalculatorBaseStore } from '../stores/escrowDepositCalculatorBaseStore';
import { isNotBlank, statesAbbreviation, statesNames } from '@roc/feature-utils';
import { DateField } from '@roc/ui';

interface EscrowCalculatorFieldProps {
  store: EscrowDepositCalculatorBaseStore;
  tooltip: string;
}

const stateOptions = statesAbbreviation.map((abbreviation, i) => ({
  value: statesNames[i],
  label: statesNames[i],
}));

const field = FieldComponent => {
  const WrappedFieldComponent = observer(FieldComponent);
  return props => (
    <Grid item xs={12} md={props.md}>
      <WrappedFieldComponent {...props} />
    </Grid>
  );
};

export const NextPaymentDueDateDateField = field(({ store }: EscrowCalculatorFieldProps) =>{
  const formFields = store.form.fields;
  const fieldName = 'nextTaxPaymentDue';
  const field = formFields[fieldName];
  return <DateField
    testId={fieldName}
    format = 'MM/dd/yyyy'
    fullWidth
    onChange={value => store.onNextTaxPaymentDateChange(fieldName, value)}
    value={field.value}
    helperText={field.error}
    inputVariant='outlined'
    label='Next Tax Payment Due'
    error={isNotBlank(field.error)}
    standaloneLabel
    />
});

export const ExpectedClosingDate = field(({ store }: EscrowCalculatorFieldProps) => {
  const formFields = store.form.fields;
  const fieldName = 'expectedClosingDate';
  const field = formFields[fieldName];
  return <DateField
    testId={fieldName}
    format='MM/dd/yyyy'
    fullWidth
    onChange={value => store.onExpectedClosingDateChange(fieldName, value)}
    value={field.value}
    helperText={field.error}
    inputVariant='outlined'
    label='Expected Closing Date'
    error={isNotBlank(field.error)}
    standaloneLabel
  />
});


export const StateSelectField = field(({ store }: EscrowCalculatorFieldProps) => {
  return createSelectField({
    store: store,
    testId: 'state',
    fieldName: 'state',
    label: 'State',
    options: stateOptions,
    disabled: true,
  })
});

export const NumberOfMonthsPaymentCovers = field(({ store }: EscrowCalculatorFieldProps) => {
  return createNumberFormatField({
    store,
    testId: 'numberOfMonthsPaymentCovers',
    fieldName: 'numberOfMonthsPaymentCovers',
    label: 'Number of months payment covers',
    placeholder: '',
    allowNegative: false,
    disabled: true,
  })
});


export const FirstPaymentDueDate = field(({ store }: EscrowCalculatorFieldProps) => {
  return createDateField({
    label: 'First (legal) Payment Due Date',
    fieldName: 'firstPaymentDueDate',
    testId: 'firstPaymentDueDate',
    format: 'MM/dd/yyyy',
    store: store,
    disabled: true,
  })
});


export const PaymentsPriorToDue = field(({ store }: EscrowCalculatorFieldProps) => {
  return createNumberFormatField({
    store,
    testId: 'paymentsPriorToDue',
    fieldName: 'paymentsPriorToDue',
    label: 'Number of Payments Borrower will make prior to Taxes due',
    placeholder: '',
    allowNegative: false,
    disabled: true,
  })
});


export const TaxEscrowMonthsOverride = field(({ store }: EscrowCalculatorFieldProps) => {
  return createNumberFormatField({
    store,
    testId: 'taxEscrowMonthsOverride',
    fieldName: 'taxEscrowMonthsOverride',
    label: 'Tax Escrow Months',
    placeholder: '',
    allowNegative: false
  })
});
