import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { observer } from 'mobx-react';
import { insertIf, UNDERWRITING } from '@roc/feature-utils';
import { Typography } from '@material-ui/core';
import {
  FileUpload,
  FileUploadItem,
  FileUploadModal,
  PDFPreview,
  StatusChip,
  StatusType,
  TableCard,
} from '@roc/ui';
import DocumentActions from 'libs/feature-documents/src/documents/components/documentsTable/components/documentActions';
import DocumentName from 'libs/feature-documents/src/documents/components/documentsTable/components/documentName';
import { useLoanStore } from '../../loans/hooks/useLoanStore';
import { Document } from '@roc/feature-types';
import { useDocumentStore } from '@roc/feature-documents';
import { Add } from '@material-ui/icons';

const OBJECT_TYPE_BORROWER = 'BORROWER';
const OBJECT_TYPE_BORROWER_ENTITY = 'BORROWER_ENTITY';
const enum ObjectType {
  BORROWER = 'BORROWER',
  BORROWER_ENTITY = 'BORROWER_ENTITY',
  LOAN = 'LOAN',
  PROPERTY = 'PROPERTY',
}

enum TaskStatus {
  BEING_REVIEWED = 'BEING_REVIEWED',
  NOT_APPLICABLE = 'NOT_APPLICABLE',
}

const columns = [
  {
    name: 'Document Name',
    width: '35%',
  },
  {
    name: 'Filename',
    width: '30%',
  },
  {
    name: 'Status',
    style: { width: 120 },
  },
  {
    name: 'Actions',
    style: { width: 120 },
  },
];

export const LoanDocuments = observer(({ store, saveDraftLoan }) => {
  const { loanStore } = useLoanStore();
  const { draftLoanTasksStore } = loanStore;
  const { borrowerEntityObj, borrowersRows, propertiesRows } = store;
  const { tasks, sections } = draftLoanTasksStore;

  useEffect(() => {
    saveAndFetchDraftLoanTasks();
  }, []);

  const saveAndFetchDraftLoanTasks = async () => {
    await saveDraftLoan();
    const draftLoanId = store.draftLoanInfo.draftLoanId;
    draftLoanTasksStore.fetchDraftLoanTasks(draftLoanId);
  };

  const getSectionName = task => {
    switch (task.objectType) {
      case ObjectType.BORROWER_ENTITY:
        return `${borrowerEntityObj?.name} - ${task.taskSectionName}`;
      case ObjectType.BORROWER:
        return `${borrowersRows.find(b => b.loanApplicationUuid == task.objectUuid)?.fullName
          } - ${task.taskSectionName}`;
      case ObjectType.PROPERTY:
        return `${propertiesRows.find(b => b.loanApplicationUuid == task.objectUuid)?.address
          } - ${task.taskSectionName}`;
      default:
        return task.taskSectionName;
    }
  };

  const loanTasks = draftLoanTasksStore.tasks
    .slice()
    .map(task => {
      const section = sections.find(
        s => task.taskSectionId === s.taskSectionId
      );
      return {
        ...task,
        ...section,
      };
    })
    .sort((a, b) => a.displayOrder - b.displayOrder)
    .filter(task => task.taskStatus !== TaskStatus.NOT_APPLICABLE)
    .reduce((sections, task) => {
      const sectionName = getSectionName(task);
      if (!sections[sectionName]) {
        sections[sectionName] = [];
      }
      sections[sectionName].push(task);
      return sections;
    }, {});

  return (
    <>
      <Typography variant="h4">Documents</Typography>
      <br />
      <Typography variant="body1">
        Uploading documents during submission is optional.
      </Typography>
      <br />
      <br />
      <Grid container spacing={2} alignItems="center">
        {Object.keys(loanTasks).map(section => (
          <Grid key={section} item xs={12} md={12}>
            <LoanSubmissionDocumentsTable
              loanTasks={loanTasks}
              section={section}
            />
          </Grid>
        ))}
      </Grid>

      <PDFPreview
        open={draftLoanTasksStore.filePreview != null}
        title={draftLoanTasksStore.filePreview?.title}
        data={draftLoanTasksStore.filePreview?.data}
        headers={draftLoanTasksStore.filePreview?.headers}
        onClose={() => draftLoanTasksStore.closeFilePreview()}
      />
    </>
  );
});

const LoanSubmissionDocumentsTable = observer(({ loanTasks, section }) => {
  const inputRef = useRef(null);
  const { documentStore } = useDocumentStore();
  const [sectionUpload, setSectionUpload] = useState(null);
  const { loanStore } = useLoanStore();
  const { draftLoanTasksStore } = loanStore;
  const [currentTask, setCurrentTask] = useState(null);
  const [isUploadOpen, setUploadOpen] = useState(false);

  const onSectionUpload = (files: FileUpload[]) => {
    draftLoanTasksStore.createTask(sectionUpload, files[0]);
  };

  const mapTaskToDoc = task => {
    return {
      documentName: task.taskName,
      canUploadFile: true,
      canDownloadFile: !!task.fileDownloadId,
    } as Document;
  };

  const onOpenForm = () => {
    return null;
  };

  const onDocumentUpload = (file: any, document: any) => {
    const updatedTask = {
      ...currentTask,
      originalFileName: file.name,
      taskStatus: TaskStatus.BEING_REVIEWED,
    };
    draftLoanTasksStore.uploadTaskFile(updatedTask, {
      name: file.name,
      file,
    });
  };

  const getRow = task => [
    {
      value: task.taskName,
      component: () => (
        <DocumentName
          document={mapTaskToDoc(task)}
          onDownload={() => draftLoanTasksStore.downloadTaskFile(task)}
          onOpenForm={onOpenForm}
        />
      ),
    },
    {
      value: task.originalFileName || '',
    },
    {
      value: task.taskStatus,
      component: () => (
        <StatusChip
          label={task.taskStatus?.toUpperCase().replace('_', ' ') || 'PENDING'}
          statusType={StatusType.DOCUMENT}
          variant="filled"
        />
      ),
    },
    {
      value: 'menu',
      component: () => (
        <DocumentActions
          document={mapTaskToDoc(task)}
          onUpload={() => {
            inputRef.current.click();
            setCurrentTask(task);
          }}
          onDownload={() => draftLoanTasksStore.downloadTaskFile(task)}
          onPreview={() => draftLoanTasksStore.previewTaskFile(task)}
        />
      ),
    },
  ];

  return (
    <>
      <TableCard
        title={section}
        optionsTitle="Section Actions"
        options={[
          documentStore.isUploadAllowed(section, UNDERWRITING) && {
            name: 'Add New Documents',
            action: () => setSectionUpload(loanTasks[section][0]),
            icon: Add,
          },
        ]}
        rows={loanTasks[section].map(getRow)}
        columns={columns}
        emptyText="No Records."
      />
      <FileUploadModal
        title={`ADD NEW DOCUMENTS: ${section}`}
        open={!!sectionUpload}
        multiple
        onClose={() => setSectionUpload(null)}
        onUpload={files => onSectionUpload(files)}
      />
      <input
        accept="*"
        type="file"
        onChange={event => onDocumentUpload(event.target.files[0], currentTask)}
        hidden
        ref={inputRef}
      />
    </>
  );
});
