import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { observer } from 'mobx-react';
import { Paper } from '@roc/ui';
import { formatPercentage } from '@roc/ui/utils';
import { useAppConfigurations, useUserStore } from '@roc/feature-app-core';

const renderHeading = value => {
  return (
    <Typography variant="body1">
      <strong>{value}</strong>
    </Typography>
  );
};

const renderValue = (value, render) => (value != null ? render : '-');

export const LoanEconomicBridge = observer(({ loanDetails }) => {
  const {
    rate,
    rocRate,
    rocPointsIn,
    rocPointsOut,
    pointsIn,
    brokerPointsIn,
    pointsOut,
    brokerPointsOut,
  } = loanDetails;
  let {
    lenderPointsIn = 0,
    lenderPointsOut = 0,
  } = loanDetails.calculatedLoanData;
  const { userStore } = useUserStore();
  const allowLoanPricing = userStore.allowLoanPricing;

  const calculateBorrowerPointsIn = () => {
    return Number(pointsIn ?? 0 + brokerPointsIn ?? 0);
  };

  const calculateBorrowerPointsOut = () => {
    return Number(pointsOut ?? 0 + brokerPointsOut ?? 0);
  };

  const calculateLenderPointsOut = () => {
    return Number(rocPointsOut ?? 0 + lenderPointsOut ?? 0);
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{renderHeading('')}</TableCell>
            <TableCell>{renderHeading('Borrower')}</TableCell>
            {allowLoanPricing ? (
              <><TableCell>{renderHeading('Capital Provider')}</TableCell>
                <TableCell>{renderHeading("You")}</TableCell>
                <TableCell>{renderHeading('Broker')}</TableCell></>
            ) : (
              <><><TableCell>{renderHeading("Lender")}</TableCell>
                <TableCell>{renderHeading('Broker')}</TableCell></>
                <TableCell>{renderHeading('')}</TableCell></>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>{renderHeading('Interest Rate')}</TableCell>
            <TableCell>
              {renderValue(rate, formatPercentage(rate, 3))}
            </TableCell>
            {allowLoanPricing ? (
              <><TableCell>
                {renderValue(rocRate, formatPercentage(rocRate, 3))}
              </TableCell><TableCell>
                  {renderValue(rate - rocRate, formatPercentage(rate - rocRate, 2))}
                </TableCell><TableCell>{formatPercentage(0, 0)}</TableCell></>
            ) : (<><><TableCell>
              {renderValue(rate + rocRate, formatPercentage(rocRate + (rate - rocRate), 3))}
            </TableCell>
              <TableCell>{formatPercentage(0, 0)}</TableCell></>
              <TableCell></TableCell></>
            )}
          </TableRow>
          <TableRow>
            <TableCell>{renderHeading('Points In')}</TableCell>
            <TableCell>
              {renderValue(
                calculateBorrowerPointsIn(),
                formatPercentage(calculateBorrowerPointsIn(), 3)
              )}
            </TableCell>
            {allowLoanPricing ? (
              <><TableCell>
                {renderValue(rocPointsIn, formatPercentage(rocPointsIn, 3))}
              </TableCell><TableCell>
                  {renderValue(lenderPointsIn, formatPercentage(lenderPointsIn, 3))}
                </TableCell><TableCell>
                  {renderValue(brokerPointsIn, formatPercentage(brokerPointsIn, 3))}
                </TableCell></>
            ) : (<><><TableCell>
              {renderValue(rocPointsIn + lenderPointsIn, formatPercentage(rocPointsIn + lenderPointsIn, 3))}
            </TableCell><TableCell>
                {renderValue(brokerPointsIn, formatPercentage(brokerPointsIn, 3))}
              </TableCell></>
              <TableCell></TableCell></>
            )}
          </TableRow>
          <TableRow>
            <TableCell>{renderHeading('Points Out')}</TableCell>
            <TableCell>
              {renderValue(
                calculateBorrowerPointsOut(),
                formatPercentage(calculateBorrowerPointsOut(), 3)
              )}
            </TableCell>
            {allowLoanPricing ? (
              <><TableCell>
                {renderValue(rocPointsOut, formatPercentage(rocPointsOut, 3))}
              </TableCell><TableCell>
                  {renderValue(
                    lenderPointsOut,
                    formatPercentage(lenderPointsOut, 3)
                  )}
                </TableCell><TableCell>
                  {renderValue(
                    brokerPointsOut,
                    formatPercentage(brokerPointsOut, 3)
                  )}
                </TableCell></>
            ) : (<><TableCell>
                {renderValue(calculateLenderPointsOut(), formatPercentage(calculateLenderPointsOut(), 3))}
            </TableCell><TableCell>
                {renderValue(brokerPointsIn, formatPercentage(brokerPointsIn, 3))}
              </TableCell></>
            )}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
});
