import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { decodeParams } from './drawRequestUtils';
import { useParams } from 'react-router';
import { useStore } from '../../hooks';
import { DrawRequestFormShared } from '@roc/client-portal-shared/stores';

export type EncodedParams = {
  encodedParams: string;
};

export const DrawRequest = observer(() => {
  const { drawRequestStore } = useStore();

  const { encodedParams } = useParams<EncodedParams>();
  const [queryParams] = useState(decodeParams(encodedParams));

  const handleSubmit = () => {
    // TODO: add Submit API call
    drawRequestStore.submitForm(queryParams?.drawId);
    // TODO: success or error message
  };

  const handleSave = () => {
    // TODO: add save API call
    drawRequestStore.saveForm(queryParams?.drawId);
    // TODO: success or error message
  };

  return (
    <DrawRequestFormShared
      drawId={queryParams?.drawId}
      onSubmit={handleSubmit}
      onSave={handleSave}
      store={drawRequestStore}
    />
  );
});

export default DrawRequest;
