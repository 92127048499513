import { Grid, Typography } from '@material-ui/core';
import { propertyTypesByLoanSubtype } from '../../../utils/propertyTypes';
import { LoanSubType } from '@roc/feature-utils';
import {
  AfterRepairValue,
  ExitStrategy,
  PayoffAmount,
  PropertyAddress,
  PropertyOwnership,
  PropertySource,
  PropertyType,
  PurchaseDate,
  PurchasePrice,
  RefinanceInvestedCapitalImprovements,
  RefinanceRenovationBudget,
  RenovationBudget,
  WholesalerPropertyPaid,
} from '@roc/ui/formComponents';
import { useSubmitAppraisalStore } from './../../../../hooks';
import { observer } from 'mobx-react';
import React from 'react';
import { ScopeOfWorkFileUpload } from './formComponents';

const PurchaseForm = observer(({ submitAppraisalStore }) => {
  const selectedLoanSubType = submitAppraisalStore.selectedLoanSubType;
  const isLoanSubTypeStabilizedOrTerm =
    selectedLoanSubType === LoanSubType.STABILIZED_BRIDGE ||
    selectedLoanSubType === LoanSubType.SINGLE_PROPERTY ||
    selectedLoanSubType === LoanSubType.RENTAL_PORTFOLIO;
  const { propertySourcing } = submitAppraisalStore.form.fields;
  const isWholesalerProperty = propertySourcing.value === 'Wholesaler';

  const onRenovationBudgetChange = () => {
    submitAppraisalStore.handleRenovationBudgetChange();
  };

  const onPropertySourcingChange = () => {
    submitAppraisalStore.handlePropertySourcingChange();
  };

  return (
    <>
      <Grid item xs={12}>
        <PropertySource
          store={submitAppraisalStore}
          label="Please select how the borrower is sourcing the property:"
          onChange={onPropertySourcingChange}
        />
      </Grid>
      {isWholesalerProperty && (
        <>
          <Grid item xs={12}>
            <WholesalerPropertyPaid
              store={submitAppraisalStore}
              label="How much is the wholesaler purchasing the property for?"
            />
          </Grid>
          <Grid item xs={12}>
            <PurchasePrice
              store={submitAppraisalStore}
              label="What is the purchase price of the property (including the wholesaler fee)?"
            />
          </Grid>
        </>
      )}
      {!isWholesalerProperty && propertySourcing.value && (
        <Grid item xs={12}>
          <PurchasePrice
            store={submitAppraisalStore}
            label="What is the purchase price of the property?"
          />
        </Grid>
      )}
      {!isLoanSubTypeStabilizedOrTerm && (
        <>
          <Grid item xs={12}>
            <RenovationBudget
              store={submitAppraisalStore}
              onChange={onRenovationBudgetChange}
            />
          </Grid>
          <Grid item xs={12}>
            <ScopeOfWorkFileUpload store={submitAppraisalStore} />
          </Grid>
          <Grid item xs={12}>
            <AfterRepairValue
              store={submitAppraisalStore}
              label="What is the estimated ARV (after repair value)?"
            />
          </Grid>
          <Grid item xs={12}>
            <ExitStrategy
              store={submitAppraisalStore}
              label="What is the exit strategy?"
            />
          </Grid>
        </>
      )}
    </>
  );
});

const RefinanceForm = observer(({ submitAppraisalStore }) => {
  const selectedLoanSubType = submitAppraisalStore.selectedLoanSubType;
  const isLoanSubTypeFixAndFlip =
    selectedLoanSubType === LoanSubType.FIX_AND_FLIP || selectedLoanSubType === LoanSubType.FIX_AND_FLIP_PRO;
  const isLoanSubTypeStabilizedOrTerm =
    selectedLoanSubType === LoanSubType.STABILIZED_BRIDGE ||
    selectedLoanSubType === LoanSubType.SINGLE_PROPERTY ||
    selectedLoanSubType === LoanSubType.RENTAL_PORTFOLIO;

  const onRenovationBudgetChange = () => {
    submitAppraisalStore.handleRenovationBudgetChange();
  };

  const onRenovationSpentToDateChange = () => {
    submitAppraisalStore.handleRenovationSpentToDateChange();
  };

  return (
    <>
      <Grid item xs={12}>
        <PurchaseDate
          store={submitAppraisalStore}
          label="When did the borrower purchase the property?"
        />
      </Grid>
      <Grid item xs={12}>
        <PurchasePrice
          store={submitAppraisalStore}
          label="What was the purchase price?"
        />
      </Grid>
      <Grid item xs={12}>
        <PayoffAmount
          store={submitAppraisalStore}
          label="What is the payoff amount?"
        />
      </Grid>
      {!isLoanSubTypeStabilizedOrTerm && (
        <>
          <Grid item xs={12}>
            <RefinanceRenovationBudget
              store={submitAppraisalStore}
              onChange={onRenovationBudgetChange}
            />
          </Grid>
          <Grid item xs={12}>
            <RefinanceInvestedCapitalImprovements
              store={submitAppraisalStore}
              label="How much has been invested in completed renovations since the date of purchase until today?"
              onChange={onRenovationSpentToDateChange}
            />
          </Grid>
          <Grid item xs={12}>
            <ScopeOfWorkFileUpload
              store={submitAppraisalStore}
              note="Make sure to include the full budget, including (A) Costs spent + (B) Reno budget remaining to complete = (C) Total Renovation Budget."
            />
          </Grid>
          <Grid item xs={12}>
            <AfterRepairValue
              store={submitAppraisalStore}
              label="What is the estimated ARV (after repair value)?"
            />
          </Grid>
          <Grid item xs={12}>
            <ExitStrategy
              store={submitAppraisalStore}
              label="What is the exit strategy?"
            />
          </Grid>
        </>
      )}
    </>
  );
});

export const PropertyForm = observer(({ submitAppraisalStore }) => {
  const { isValidPropertyType, form } = submitAppraisalStore;
  const { useCode, propertyOwnership } = form.fields;

  const propertyTypeOptions = propertyTypesByLoanSubtype(
    submitAppraisalStore.selectedLoanSubType as LoanSubType
  );

  const onPropertyOwnershipChange = () => {
    submitAppraisalStore.handlePropertyOwnershipChange();
  };

  return (
    <Grid item container justifyContent="center" spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5">Property Details</Typography>
      </Grid>
      <Grid item xs={12}>
        <PropertyType
          store={submitAppraisalStore}
          propertyTypeOptions={propertyTypeOptions}
          label="What type of property would you like to order an appraisal for?"
        />
      </Grid>
      {isValidPropertyType && (
        <>
          <Grid item xs={12}>
            <PropertyAddress
              store={submitAppraisalStore}
              label="What is the address of the property?"
              required
            />
          </Grid>
          <Grid item xs={12}>
            <PropertyOwnership
              store={submitAppraisalStore}
              label="Does the borrower already own the property or will this be a new purchase transaction?"
              onChange={onPropertyOwnershipChange}
            />
          </Grid>
          {propertyOwnership.value === 'Purchase' && <PurchaseForm submitAppraisalStore={submitAppraisalStore} />}
          {propertyOwnership.value === 'Owned By Borrower' && <RefinanceForm submitAppraisalStore={submitAppraisalStore} />}
        </>
      )}
    </Grid>
  );
});
