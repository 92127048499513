import { Box, Grid } from '@material-ui/core';
import { observer } from 'mobx-react';
import { useGeneralTasksStore } from '../../../hooks/useGeneralTasksStore';
import { FilterType, MyChatContainer, SelectConversation, useAppraisalTaskCommunicationStore, useLoanCommunicationStore } from '@roc/feature-communication';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { useEffect } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    containerWrapperInternal: {
      overflow: 'auto',
      flexGrow: 1,
    },
    container: {
      width: '100%',
    },
    warningButton: {
      color: '#ff0000',
      borderColor: '#ff0000',
      marginRight: '8px',
    },
  }),
);

export const AppraisalCommentsTab = observer(() => {
  const { generalTasksStore } = useGeneralTasksStore();
  const { editTaskStore } = generalTasksStore;
  const { appraisalTaskCommunicationStore } = useAppraisalTaskCommunicationStore();
  const { loanCommunicationStore } = useLoanCommunicationStore();
  const classes = useStyles();
  return (
    <>
      {editTaskStore?.hasConversation ? (
        <Grid item xs={12} md={12}>
          <Box display="flex" height={'70vh'} >
            <MyChatContainer
              id={Number(editTaskStore?.task?.attributes?.loanId)}
              communicationStore={appraisalTaskCommunicationStore}
              allowNewConversations={false}
              allowJoinConversation={true}
              showEmailAddress={false}
              showSnippet={false}
              className={classes.containerWrapperInternal}
              allowRemoveParticipants={true}
              allowManageParticipants={true}
              allowAddInternalParticipants={true}
              onConversationSelect={conversationId => {
                appraisalTaskCommunicationStore?.setCurrentConversationId(conversationId)
              }
              }
            />
            <SelectConversation communicationStore={appraisalTaskCommunicationStore} />
          </Box>
        </Grid>
      ) : (
        <Grid item xs={12} md={12}>
          <Box display="flex"
            justifyContent="flex-start"
            alignItems="flex-start"
            height="50vh"
            textAlign="left"
            padding={2}
          >
          </Box>
        </Grid>
      )}
    </>
  );
});
