import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Box, Grid, Toolbar, Typography } from '@material-ui/core';
import { formatCurrency } from '@roc/ui/utils';
import { ValuationReviewReportStore } from '../stores/valuationReviewReportStore';
import { observer } from 'mobx-react';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    '& .MuiTableRow-head th': {
      fontWeight: 'bold',
    },
  },
  tableContainer: {
    maxHeight: 200,
  },
  loadingContainer: {
    margin: '40px',
  }
});

export type Props = {
  store: ValuationReviewReportStore;
}

export const TitleHistoryTable = observer((props: Props) => {
  const classes = useStyles();
  const { propertyActivityInfo } = props.store;

  return (
    <Paper elevation={0} variant='outlined'>
      <Toolbar style={{
        minHeight: 52,
        padding: '8px 16px',
      }}>
        <Typography variant="h6" component="div">
          Title History
        </Typography>
      </Toolbar>
      {!propertyActivityInfo ? (
        <Grid container xs={12} justifyContent='center' className={classes.loadingContainer}>
          <Grid item>
            <Typography variant="subtitle1">
              <strong>Loading...</strong>
            </Typography>
          </Grid>
        </Grid>) : (
        <TableContainer className={classes.tableContainer}>
          <Table className={classes.table} size='small' stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell align="center">Date</TableCell>
                <TableCell align="center">Description</TableCell>
                <TableCell align="center">Seller</TableCell>
                <TableCell align="center">Buyer</TableCell>
                <TableCell align="center">Lender</TableCell>
                <TableCell align="center">Price</TableCell>
                <TableCell align="center">Loan Amount</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {propertyActivityInfo?.map((row, i) => (
                <TableRow hover key={i}>
                  <TableCell component="th" scope="row" align="center">
                    {i + 1}
                  </TableCell>
                  <TableCell component="th" scope="row" align="center">
                    {row.actionDate ? `${row.actionDate[1].toString().padStart(2, '0')}/${row.actionDate[2].toString().padStart(2, '0')}/${row.actionDate[0]}`
                      : 'No Date'}
                  </TableCell>
                  <TableCell component="th" scope="row" align="center">
                    {row.description}
                  </TableCell>
                  <TableCell component="th" scope="row" align="center">
                    {row.seller}
                  </TableCell>
                  <TableCell component="th" scope="row" align="center">
                    {row.buyer}
                  </TableCell>
                  <TableCell component="th" scope="row" align="center">
                    {row.lender}
                  </TableCell>
                  <TableCell component="th" scope="row" align="center">
                    {formatCurrency(row.sellPrice)}
                  </TableCell>
                  <TableCell component="th" scope="row" align="center">
                    {formatCurrency(row.loanAmount)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {/* <Box p={2} bgcolor={'#fff5e7'} borderTop={'1px solid #eee'}>
        <Typography style={{
          fontWeight: 'bold'
        }}>
          Warnings
        </Typography>
        <ol style={{
          marginLeft: 16
        }}>
          <li>Price Run up (Property is sold for less than 80% subject purchase price within 3 years)</li>
          <li>Previous Private Lender flag</li>
          <li>Previous Roc loan</li>
        </ol>
      </Box> */}
    </Paper>
  );
})
