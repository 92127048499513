import { NotFound, Paper } from '@roc/ui';
import { Box, Button, Card, Grid, Tab, Tabs, Typography } from '@material-ui/core';

import { appStoreBadge, playStoreBadge } from '@roc/feature-app-core/assets';
import downloadMacImg from '../assets/download_mac_badge.png';
import downloadWindowsImg from '../assets/download_windows_badge.png';
import chatRoc360MobileAppIosQr from '../assets/mobile_app_chatroc360_qr_code_ios.png';
import chatRoc360MobileAppAndroidQr from '../assets/mobile_app_chatroc360_qr_code_android.png';

import loanChatMobileAppAndroidQr from '../assets/mobile_app_loanchat_qr_code_android.png';
import loanChatMobileAppIosQr from '../assets/mobile_app_loanchat_qr_code_ios.png'

import loanChatsImg from '../assets/loanChats.png';

import chatRoc360Img from '../assets/roc360.png';

import { FC, ReactNode, useEffect, useState } from 'react';
import { useAppsStore } from './hooks/useAppsStore';
import { observer } from 'mobx-react';


interface AppProps {
  isInternal: boolean,
  logo: string,
  name: string,
  windowsContent: ReactNode,
  macContent: ReactNode,
  mobileContent: ReactNode
};

const AppComponent: FC<AppProps> = observer(({
  logo,
  name,
  windowsContent,
  macContent,
  mobileContent

}) => {
  const [selectedTab, setSelectedTab] = useState('windows');

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };


  return (
    <Card style={{
      height: '100%'
    }} >
      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab label={'windows'} value={'windows'} />
        <Tab label={'mac'} value={'mac'} />
        <Tab label={'mobile'} value={'mobile'} />
      </Tabs>

      <Box m={4}>
        <Box m={1} marginBottom={5} >
          <Grid direction='row' container alignItems='center' spacing={2}>
            <Grid item>
              <img src={logo} style={{ height: '125px' }} />
            </Grid>
            <Grid item>
              <Typography variant="h3"  >
                <strong>{name}</strong>
              </Typography>
            </Grid>
          </Grid>
        </Box>

        {selectedTab === 'windows' &&
          windowsContent
        }

        {selectedTab === 'mac' &&
          macContent
        }

        {selectedTab === 'mobile' &&
          mobileContent
        }
      </Box>

    </Card>)
})

interface AppsComponentProps {
  showInternalApps: boolean;
}
export const Apps: FC<AppsComponentProps> = observer(({ showInternalApps = false }) => {

  const { appsStore } = useAppsStore();

  useEffect(() => {
    appsStore.loadData();
  }, [])

  const getAppFromContentful = (value: string) => {
    return appsStore.data.find(app => app.value === value);
  }

  const showApp = (value: string) => {
    const app = getAppFromContentful(value);
    if (app) {
      if (showInternalApps && app.isInternal) {
        return true;
      }
      else if (showInternalApps && !app.isInternal) {
        return true;
      }
      else if (!showInternalApps && !app.isInternal) {
        return true;
      }
      else return false;
    }
  }
  if (showInternalApps || appsStore.globalStore.userFeatures.showApps) {
    return appsStore.data ? (
      <Box>
        <Grid container spacing={2} style={{ padding: '2%', marginTop: '2%', marginBottom: '2%' }}>

          {showApp('chat-roc-360') &&
            <Grid item xs={12} md={6} >
              <AppComponent
                isInternal={appsStore.data[1].isInternal}
                logo={chatRoc360Img}
                name="ChatRoc360"
                windowsContent={
                  <>
                    <Box m={1} mt={2}>
                      <Typography variant="h6" align='justify' style={{ color: 'grey' }}>
                        ChatRoc360 enables Roc360 employees to use their windows devices to appropriately communicate with one another and with clients.
                        <br />
                        <br />
                        Now, Roc360 employees can take their official lines of communication on the go without missing a beat."
                      </Typography>
                    </Box>
                    <Box marginTop={5} justifyContent={'center'} display={'flex'}>
                      <a href={appsStore.data[1].devices[0].url} target="_blank">
                        <img alt='Download on Windows' src={downloadWindowsImg} style={{ height: '80px', display: 'block' }} />
                      </a>
                    </Box>
                  </>

                }
                macContent={
                  <>
                    <Box m={1} mt={2}>
                      <Typography variant="h6" align='justify' style={{ color: 'grey' }}>
                        ChatRoc360 enables Roc360 employees to use their mac devices to appropriately communicate with one another and with clients.
                        <br />
                        <br />
                        Now, Roc360 employees can take their official lines of communication on the go without missing a beat."
                      </Typography>
                    </Box>
                    <Box marginTop={5} justifyContent={'center'} display={'flex'} >
                      <a href={appsStore.data[1].devices[1].url} target="_blank">
                        <img alt='Download on Mac' src={downloadMacImg} style={{ height: '80px', display: 'block' }} />
                      </a>
                    </Box>
                  </>

                }

                mobileContent={
                  <>
                    <Box m={1} mt={2}>
                      <Typography variant="h6" align='justify' style={{ color: 'grey' }}>
                        ChatRoc360 enables Roc360 employees to use their mobile devices to appropriately communicate with one another and with clients.
                        <br />
                        <br />
                        Now, Roc360 employees can take their official lines of communication on the go without missing a beat."
                      </Typography>
                    </Box>

                    <Grid direction='row' justifyContent="center" container spacing={4}>

                      <Grid item xs style={{ margin: '1rem' }}>
                        <Grid direction='column'>
                          <Typography variant="h3" align="center">
                            IOS
                          </Typography>
                          <Typography variant="h4" align="center">
                            Scan QR Code
                          </Typography>
                        </Grid>
                        <Box m={1}>
                          <img alt='Scan me' src={chatRoc360MobileAppIosQr} style={{ height: '225px', display: 'block', margin: 'auto' }}></img>
                        </Box>
                        <Grid>
                          <Typography variant="h4" align="center">
                            OR
                          </Typography>
                        </Grid>
                        <Box m={2.5} justifyContent={'center'} display={'flex'} >
                          <a href={'https://apps.apple.com/us/app/chat-roc360/id6448926261'} target="_blank">
                            <img alt='Get it on Google Play' src={appStoreBadge} style={{ height: '69px', display: 'block' }} />
                          </a>
                        </Box>
                      </Grid>


                      <Grid item xs style={{ margin: '1rem' }}>
                        <Grid direction='column'>
                          <Typography variant="h3" align="center">
                            Android
                          </Typography>
                          <Typography variant="h4" align="center">
                            Scan QR Code
                          </Typography>
                        </Grid>
                        <Box m={1}>
                          <img alt='Scan me' src={chatRoc360MobileAppAndroidQr} style={{ height: '225px', display: 'block', margin: 'auto' }}></img>
                        </Box>
                        <Grid>
                          <Typography variant="h4" align="center">
                            OR
                          </Typography>
                        </Grid>
                        <Box m={1} justifyContent={'center'} display={'flex'}>
                          <a href={'https://play.google.com/store/apps/details?id=com.rocinternal.mobileapp'} target="_blank">
                            <img alt='Get it on Google Play' src={playStoreBadge} style={{ height: '100px', display: 'block' }} />
                          </a>
                        </Box>
                      </Grid>


                    </Grid>
                  </>
                }
              />
            </Grid>}

          {showApp('loan-chat') &&
            <Grid item xs={12} md={6}>
              <AppComponent
                isInternal={appsStore.data[0].isInternal}
                logo={loanChatsImg}
                name="LoanChat"
                windowsContent={
                  <>
                    <Box m={1} mt={2}>
                      <Typography variant="h6" align='justify' style={{ color: 'grey' }}>
                        LoanChat is a tool designed to help private lenders of business purpose residential real estate investment loans connect their back offices and streamline their teams' communication.
                        <br />
                        <br />
                        LoanChat (Windows Desktop App) consolidates all your origination conversations in one view similar to Slack.
                        <br />
                        <br />
                        Key Features:
                        <br />
                        <ul style={{ marginLeft: '30px' }}>
                          <li>Connect to the entire back office of underwriters, processors, loan coordinators and more.</li>
                          <li>Use stage-based threads to communicate with the right people at the right time (Underwriting Room, Closing Room, Draws Room, etc.)</li>
                          <li>View all messages about your loans with the latest messages moving to the top of your queue</li>
                          <li>Filter through your threads by searching for any specific loan</li>
                        </ul>
                      </Typography>
                    </Box>

                    <Box marginTop={5} justifyContent={'center'} display={'flex'}>
                      <a href={appsStore.data[0].devices[0].url} target="_blank">
                        <img alt='Download on Windows' src={downloadWindowsImg} style={{ height: '80px', display: 'block' }} />
                      </a>
                    </Box>
                  </>
                }
                macContent={
                  <>
                    <Box m={1} mt={2}>
                      <Typography variant="h6" align='justify' style={{ color: 'grey' }}>
                        LoanChat is a tool designed to help private lenders of business purpose residential real estate investment loans connect their back offices and streamline their teams' communication.
                        <br />
                        <br />
                        LoanChat (Macbook Desktop App) consolidates all your origination conversations in one view similar to Slack.
                        <br />
                        <br />
                        Key Features:
                        <br />
                        <ul style={{ marginLeft: '30px' }}>
                          <li>Connect to the entire back office of underwriters, processors, loan coordinators and more.</li>
                          <li>Use stage-based threads to communicate with the right people at the right time (Underwriting Room, Closing Room, Draws Room, etc.)</li>
                          <li>View all messages about your loans with the latest messages moving to the top of your queue</li>
                          <li>Filter through your threads by searching for any specific loan</li>
                        </ul>
                      </Typography>
                    </Box>

                    <Box marginTop={5} justifyContent={'center'} display={'flex'}>
                      <a href={appsStore.data[0].devices[1].url} target="_blank">
                        <img alt='Download on Mac' src={downloadMacImg} style={{ height: '80px', display: 'block' }} />
                      </a>
                    </Box>
                  </>
                }
                mobileContent={
                  <>
                    <Box m={1} mt={2}>
                      <Typography variant="h6" align='justify' style={{ color: 'grey' }}>
                        LoanChat is a tool designed to help private lenders of business purpose residential real estate investment loans connect their back offices and streamline their teams' communication.
                        <br />
                        <br />
                        LoanChat (Mobile App) consolidates all your origination conversations in one view similar to Slack.
                        <br />
                        <br />
                        Key Features:
                        <br />
                        <ul style={{ marginLeft: '30px' }}>
                          <li>Connect to the entire back office of underwriters, processors, loan coordinators and more.</li>
                          <li>Use stage-based threads to communicate with the right people at the right time (Underwriting Room, Closing Room, Draws Room, etc.)</li>
                          <li>View all messages about your loans with the latest messages moving to the top of your queue</li>
                          <li>Filter through your threads by searching for any specific loan</li>
                        </ul>
                      </Typography>
                    </Box>
                    <Grid direction='row' justifyContent="center" container spacing={4}>
                      <Grid item xs style={{ margin: '1rem' }}>
                        <Grid direction='column'>
                          <Typography variant="h3" align="center">
                            IOS
                          </Typography>
                          <Typography variant="h4" align="center">
                            Scan QR Code
                          </Typography>
                        </Grid>
                        <Box m={1}>
                          <img alt='Scan me' src={loanChatMobileAppIosQr} style={{ height: '225px', display: 'block', margin: 'auto' }}></img>
                        </Box>
                        <Grid>
                          <Typography variant="h4" align="center">
                            OR
                          </Typography>
                        </Grid>
                        <Box m={2.5} justifyContent={'center'} display={'flex'} >
                          <a href={'https://apps.apple.com/app/loanchat/id6451215518'} target="_blank">
                            <img alt='Get it on Apps Store' src={appStoreBadge} style={{ height: '69px', display: 'block' }} />
                          </a>
                        </Box>
                      </Grid>

                      <Grid item xs style={{ margin: '1rem' }}>
                        <Grid direction='column'>
                          <Typography variant="h3" align="center">
                            Android
                          </Typography>
                          <Typography variant="h4" align="center">
                            Scan QR Code
                          </Typography>
                        </Grid>
                        <Box m={1}>
                          <img alt='Scan me' src={loanChatMobileAppAndroidQr} style={{ height: '225px', display: 'block', margin: 'auto' }}></img>
                        </Box>
                        <Grid>
                          <Typography variant="h4" align="center">
                            OR
                          </Typography>
                        </Grid>
                        <Box m={1} justifyContent={'center'} display={'flex'}>
                          <a href={'https://play.google.com/store/apps/details?id=com.plp.loanchat'} target="_blank">
                            <img alt='Get it on Google Play' src={playStoreBadge} style={{ height: '100px', display: 'block' }} />
                          </a>
                        </Box>
                      </Grid>

                    </Grid>


                  </>
                }
              />
            </Grid>
          }

        </Grid>
      </Box>
    ) : <></>
  } else {
    return <NotFound />
  };
});
