import { Box, Container, Grid, Paper, TextField, Typography } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { observer } from "mobx-react"
import { useEffect, useState } from "react";
import { ConfirmationButton, MultiSelectField, SelectField } from "@roc/ui";
import { useSendEmailsStore } from "../hooks/useSendEmailsStore";
import { Autocomplete } from "@material-ui/lab";
import { InfoOutlined } from "@material-ui/icons";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textBold: {
      fontWeight: 700,
    },
    table: {
      '& table tr:last-child td': {
        borderBottom: 'none',
      },
    },
    paper: {
      padding: theme.spacing(4),
      minHeight: "600px",
      boxShadow:
        'rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px',

    },
    tableRow: {
      minHeigh: '12vh',
    },
    sendButton: {
      marginTop: '250px',

    },
  })
);

export const audienceTypes = [
  {
    value: 'BorrowerLead',
    label: 'Borrower Lead',
  },
  {
    value: 'TPOLead',
    label: 'TPO Lead',
  },
  {
    value: 'LenderPrincipalContact',
    label: 'Lender Principal Contact',
  },
  {
    value: 'BorrowerContact',
    label: 'Borrower Contact',
  },
];

export const brandOptions = [
  {
    value: 'roc',
    label: 'Roc',
  },
  {
    value: 'faco',
    label: 'Faco',
  },
  {
    value: 'civic',
    label: 'Civic',
  },
];

const mailSendOptions = [
  {
    value: 'brand',
    label: 'By Brand',
  },
  {
    value: 'originator',
    label: 'By Originator',
  },
];


export const SendEmailsByOwner = observer(() => {
  const { sendEmailsStore } = useSendEmailsStore()
  const { ownerOptions } = sendEmailsStore;
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [selectedBrand, setSelectedBrand] = useState('');
  const [selectedSendOption, setSelectedSendOption] = useState('');
  const [selectedOwners, setSelectedOwners] = useState([]);
  const [selectedAudiences, setSelectedAudiences] = useState([]);
  const { paper } = useStyles();
  const classes = useStyles();

  const handleOwnersChange = (_, newOwners) => {
    const allOption = { label: "All", value: 'all' };
    if (newOwners.some(owner => owner.value === allOption.value)) {
      newOwners = [allOption];
    }
    setSelectedOwners(newOwners);;
  };

  const fetchData = async () => {
    await sendEmailsStore.getAllOwnerOptions();
    setIsDataLoaded(true);
  };

  const handleAudienceChange = (e, value) => {
    setSelectedAudiences(value);
  };

  const handleBrandChange = (value) => {
    setSelectedBrand(value);
  };

  const handleSendOptionChange = (value) => {
    if (value === 'brand') {
      setSelectedOwners([]);
      setSelectedSendOption(value)
    } else {
      setSelectedSendOption(value);
      setSelectedBrand('');
    }

  };

  useEffect(() => {
    fetchData();
  }, []);


  return (
    <>
      {isDataLoaded && (
        <Container maxWidth={'md'}>
          <Grid item xs={12} md={12}>
            <Box pt={2} pb={2}>
              <Typography variant="h4">
                Send marketing emails
              </Typography>
            </Box>
          </Grid>
          <Paper className={paper} >
            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                <Box pt={2}>
                  <Typography>
                    Please select either one brand or originator(s) and select one or multiple audiences (to whom the email will be sent).
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} spacing={4}>
                <SelectField
                  testId="selectedSend"
                  options={mailSendOptions}
                  label={'Select option to send emails'}
                  value={selectedSendOption}
                  onChange={(value) => {
                    handleSendOptionChange(value);

                  }}
                  fullWidth
                  variant={'outlined'}
                />
              </Grid>
              {selectedSendOption === 'brand' && (
                <Grid item xs={12} spacing={4}>
                  <SelectField
                    testId="selectedBrand"
                    options={brandOptions}
                    label={'Choose brand'}
                    value={selectedBrand}
                    onChange={(value) => {
                      handleBrandChange(value);

                    }}
                    fullWidth
                    variant={'outlined'}
                  />
                </Grid>
              )}
              {selectedSendOption === 'originator' && (
                <Grid item xs={12} spacing={4}>
                  <MultiSelectField
                    name={'ownerName'}
                    options={ownerOptions}
                    getOptionSelected={(option, selectedValue) => {
                      return option.value == selectedValue.value;
                    }}
                    value={selectedOwners}
                    onChange={handleOwnersChange}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" label="Select owner(s)" />
                    )}
                    fullWidth
                    required
                    testId="selectedOwners"
                  />
                </Grid>
              )}
              {selectedSendOption && (
                <Grid item xs={12} spacing={4}>
                  <MultiSelectField
                    name={'selectedAudience'}
                    options={audienceTypes}
                    getOptionSelected={(option, selectedValue) => {
                      return option.value == selectedValue.value;
                    }}
                    value={selectedAudiences}
                    onChange={handleAudienceChange}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" label="Select audience" />
                    )}
                    fullWidth
                    required
                    testId="selectedAudiences"
                  />
                </Grid>
              )}
              <Grid item xs={9}></Grid>
              <Grid item xs={3} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                <ConfirmationButton
                  disabled={!((selectedOwners.length != 0 || selectedBrand) && selectedAudiences.length != 0)}
                  variant='outlined'
                  color='primary'
                  onClick={() => { sendEmailsStore.sendEmails(selectedOwners, selectedAudiences, selectedBrand) }}
                  testId="send-emails"
                  confirmDialogProps={{
                    title: 'Send marketing emails',
                    body: `Are you sure you want to send emails to selected audience?`,
                  }}
                  fullWidth
                  className={classes.sendButton}
                >Send</ConfirmationButton>
              </Grid>
            </Grid>
          </Paper>
        </Container>
      )
      }
    </>
  );
});
