import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { Box, Chip, Grid } from '@material-ui/core';
import { Bathtub, KingBed, SquareFoot, ExitToApp, Build } from '@material-ui/icons';
import { numberWithCommas, NoImageAvailable } from '@roc/ui';

const useStyles = makeStyles((theme: Theme) => ({
  rootComp: {
    margin: 4,
    cursor: 'pointer',
    '& .MuiCardActionArea-root': {
      background: 'white',
      borderRadius: 4,
    }
  },
  root: {
    minHeight: '452px',
    margin: 4,
    '&hover': {
      border: '5px solid #e88c05'
    },
    '& .MuiCardActionArea-root': {
      background: 'white',
    },
    borderRadius: '1rem',
    cursor: 'pointer'
  },
  cardComponent: {
    height: '300px'
  },
  chip: {
    padding: '0 4px',
    marginTop: 4,
    background: '#f3f3f3',
    '& .MuiChip-label': {
      fontSize: 12,
      fontWeight: 'bold',
    }
  },
  compImage: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    objectFit: 'cover',
    zIndex: -1
  }
}));

export const ListingCard = (props) => {

  let color;
  let transparentColor;
  switch (props?.property?.propertyType) {
    case 'Single Family Detached':
    case 'Single-Family (detached)':
      color = 'rgba(115, 177, 244, 1)';
      transparentColor = 'rgba(115, 177, 244, .3)';
      break;
    case 'Duplex':
      color = 'rgba(15, 185, 177, 1)';
      transparentColor = 'rgba(15, 185, 177, .3)';
      break;
    case 'Triplex':
      color = 'rgba(250, 130, 49, 1)';
      transparentColor = 'rgba(250, 130, 49, .3)';
      break;
    case 'Multi-Family':
      color = 'rgba(136, 84, 208, 1)';
      transparentColor = 'rgba(136, 84, 208, .3)';
      break;
  }


  const classes = useStyles();

  let source;
  if (props?.isComp) {
    source = props?.property?.photoUrl;
  }
  else if (props?.property?.images?.length > 0) {
    source = `/api/v1/loan/public/prospectProperties/download?path=${props?.property?.images[0]}`;
  }

  let price;
  if (props?.property?.soldPrice) {
    price = '$' + numberWithCommas(props?.property?.soldPrice);
  }
  else if (props?.property?.askingPrice) {
    price = '$' + numberWithCommas(props?.property?.askingPrice);
  }
  else {
    price = 'Price Unavailable';
  }


  return (
    <Card className={props?.isComp ? classes.rootComp : classes.root} onClick={() => props?.onClick()}>
      {source ? <Box className={classes.cardComponent}>
        <img src={source} width={'100%'} height={'100%'} style={{ overflow: 'hidden', objectFit: 'cover' }} />
      </Box> :
        <Box className={classes.cardComponent}>
          <NoImageAvailable />
        </Box>}
      <CardContent style={{ padding: '10px 15px' }}>
        <Grid container style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <Typography gutterBottom variant='h5' component='h2'>
            {price}
          </Typography>
          <Typography gutterBottom component='p' style={{ padding: '0 10px', color: color, background: transparentColor, height: '50%', alignSelf: 'center', borderRadius: '0.2rem' }}>
            {props?.property?.propertyType}
          </Typography>
        </Grid>
        <Typography variant="body2" color="textSecondary" component="p">
          {props?.isComp ? props?.property?.address :
            `${props?.property?.address}, ${props?.property?.city}, ${props?.property?.state} ${props?.property?.zipCode}`}
        </Typography>
        <Box my={1} display={'flex'} flexWrap={'wrap'}>
          {props?.property?.bedrooms && <Chip
            icon={<KingBed style={{
              color: '#e88c05',
            }} />}
            className={classes.chip}
            label={props?.property?.bedrooms + " Beds"}
            style={{
              marginRight: 8,
            }} />}
          {props?.property?.bathrooms && <Chip
            icon={<Bathtub style={{
              color: '#1e97e9',
            }} />}
            className={classes.chip}
            label={props?.property?.bathrooms + " Baths"}
            style={{
              marginRight: 8,
            }} />}
          {props?.property?.squareFootage && <Chip
            icon={<SquareFoot style={{
              color: '#53a457',
            }} />}
            className={classes.chip}
            label={props?.property?.squareFootage + " sq.ft."}
            style={{
              marginRight: 8,
            }} />}
          {!props?.isComp && props?.property?.potentialExitStrategy && <Chip
            icon={<ExitToApp style={{
              color: '#e88c05'
            }} />}
            className={classes.chip}
            label={props?.property?.potentialExitStrategy} />}
          {!props?.isComp && props?.property?.needsRepair && <Chip
            icon={<Build style={{
              color: '#e88c05'
            }} />}
            className={classes.chip}
            label={'Needs Repair'} />}
        </Box>
      </CardContent>
    </Card>
  );
};