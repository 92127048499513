import { Service } from './../../../services';

const baseUrl = '/api/v1/pricer/liquidity';

const url = {
  GET_LIQUIDITY: `${baseUrl}/getLiquidity`,
};

export class LiquidityCalculatorService extends Service {

  getLiquidity(request) {
    return this.post(url.GET_LIQUIDITY, request);
  }

}
