import { Box, Grid, makeStyles, TextField } from '@material-ui/core';
import { PercentageField } from '@roc/ui';
import { observer } from 'mobx-react';
import { TableCell, TableRow } from '@material-ui/core';
import { QuoteTypeStore } from '../../../stores/quoteTypeStore';
import { PercentagePicker } from '../../leverage/components/leverageComponents';
import { createPercentageField } from '../../../components/temp/componentBuiderTemp';
import { useBaseStore } from '@roc/feature-app-core';

interface OriginationPointsItemProps {
  store: QuoteTypeStore;
}

const useStyles = makeStyles(theme => ({
  selectField: {},
  noBorders: {
    border: 'none',
  },
  disabled: {
    background: '#eee'
  }
}));

export const OriginationPointsItem = observer(
  ({ store }: OriginationPointsItemProps) => {
    const classes = useStyles();
    const { loanEconomicsStore } = store;
    const formStore = loanEconomicsStore.loanEconomicsFormStore;
    const outputs = loanEconomicsStore.outputs;
    const { globalStore } = useBaseStore();



    return (
      <TableRow>
        <TableCell
          component="th"
          scope="row"
          style={{ width: '20%' }}
          className={classes.noBorders}
        >
          <TextField
            fullWidth
            defaultValue="Origination Points"
            InputProps={{
              readOnly: true,
              tabIndex: -1,
            }}
          />
        </TableCell>
        <TableCell
          align="center"
          component="th"
          scope="row"
          style={{ width: '20%' }}
          className={classes.noBorders}
        >
          <Box >
            <PercentageField
              value={formStore?.form?.fields?.totalPoints.value}
              variant={'outlined'}
              testId={'totalPoints'}
              fullWidth={true}
              onChange={(name, value) =>
                formStore.onFieldChange('totalPoints', value)
              }
              limitedPercentage={true}
              decimalScale={2}
              min={loanEconomicsStore?.floorPoints ?? 1}
              max={5}
            />
          </Box>
        </TableCell>
        <TableCell
          align="center"
          component="th"
          scope="row"
          style={{ width: '20%' }}
          className={classes.noBorders}
        >
          <Box >
            <PercentageField
              value={outputs?.bridgePricerResponseFields?.rocPoints ?? 0}
              variant={'outlined'}
              testId={'rocPoints'}
              fullWidth={true}
              onChange={(name, value) => { }}
              disabled={true}
              inputClassname={classes.disabled}
              limitedPercentage={true}
              decimalScale={2}
            />
          </Box>
          {/* {createPercentageField({
            store: formStore,
            testId: 'capitalProviderPoints',
            fieldName: 'capitalProviderPoints',
            label: null,
            inputClassName: classes.inputField,
            autoFocus: true,
          })} */}
        </TableCell>
        {!globalStore?.lenderInfo?.isInternal &&
          <TableCell
            align="center"
            component="th"
            scope="row"
            style={{ width: '20%' }}
            className={classes.noBorders}
          >
            <Box >
              {createPercentageField({
                store: formStore,
                testId: 'lenderPoints',
                fieldName: 'lenderPoints',
                fullWidth: true,
                label: null,
                autoFocus: true,
                limitedPercentage: true,
                disabled: true,
                inputClassName: classes.disabled
              })}
            </Box>
          </TableCell>
        }
      </TableRow>
    );
  }
);
