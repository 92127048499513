import { Box, Paper, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import { Task } from './task';
import { InfiniteScrollList } from './infiniteScrollList';
import { TaskGridRow } from '../types/generalTasksTypes';

const useStyles = makeStyles(theme => ({
  scroll: {
    padding:theme.spacing(2)
    //XmaxHeight: '80vh',
  },
}));

export const Swimlane = observer(({ title, gridStore }) => {
  const classes = useStyles();

  return (
    <Box style={{height:'100%', position:'relative'}}>
      <Box  style={{position:'absolute', inset:0 }}>
        <Paper style={{ maxHeight: '100%', display:"flex", flexDirection:'column' }}>
          <Box p={2} pb={0}>
            <Typography variant="h5">{title}</Typography>
          </Box>
          <InfiniteScrollList
            className={classes.scroll}
            gridStore={gridStore}
            renderItem={(task: TaskGridRow) => (
              <Box py={2}>
                <Task title={task.title} description={task.description} />
              </Box>
            )}
            renderEmpty={() => <>No tasks</>}
          />
        </Paper>
      </Box>
    </Box>
  );
});
