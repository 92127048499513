import { Page } from '@roc/feature-app-core';
import { Route } from 'react-router';
import { useRocInternalPortalStore } from '../../../hooks';
import { ProspectPropertiesDashboard } from '@roc/feature-prospect-properties';
import { PropertyDetailsLayout } from '@roc/feature-prospect-properties';

const PROPERTY_BASE_PATH = ':propertyId';
const PROPERTY_TYPE_BASE_PATH = ':propertyType';

export const getProspectPropertiesInternalRoutesConfig = (
  basePath: string,
  baseUrl: string
) => {
  return {
    prospectProperties: {
      path: `${basePath}/prospect-properties`,
      url: `${baseUrl}/prospect-properties`,
      documentTitle: 'Prospect Properties',
    },
    propertyDetails: (propertyId?: string, propertyType?: string) => ({
      path: `${basePath}/prospect-properties/${PROPERTY_BASE_PATH}/${PROPERTY_TYPE_BASE_PATH}`,
      url: `${baseUrl}/prospect-properties/${propertyId}/${propertyType}`,
    })
  };
};

export const getProspectPropertiesInternalRoutes = (
  basePath: string,
  baseUrl: string
) => {
  const config = getProspectPropertiesInternalRoutesConfig(basePath, baseUrl);
  const { prospectPropertiesInternalStore } = useRocInternalPortalStore();
  return {
    proespectProperties: (
      <Route exact path={config.prospectProperties.path}>
        <Page routeDefinition={config.prospectProperties}>
          <ProspectPropertiesDashboard store={prospectPropertiesInternalStore} />
        </Page>
      </Route>
    ),
    prospectPropertyDetails: (
      <Route exact path={config.propertyDetails().path}>
        <Page routeDefinition={config.prospectProperties}>
          <PropertyDetailsLayout store={prospectPropertiesInternalStore} />
        </Page>
      </Route>
    )
  };
};
