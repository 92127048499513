import { observer } from "mobx-react";
import { useStore } from '@roc/client-portal-shared/hooks';
import { Button, Card, DateField, Paper, TestDataAttribute, createSelectField } from "@roc/ui";
import { makeStyles } from "@material-ui/core/styles";
import { Box, ListItem, ListItemText } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { sanitizeTestId } from "@roc/feature-utils";
import MersOnboardingModal from "./mersOnBoardingModal";
import { DBA, LOAN_FUNDER, LOAN_FUNDER_WITH_MERS, MERS_TPO } from "../stores/loanFundinPreferenceStore";
import MersRMConfirmationModal from './mersRMConfirmationModal';
import { format, isValid } from 'date-fns';
import { DatePickerInput } from "@roc/react-native-ui";

const useStyles = makeStyles(theme => ({
  card: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  agreementDate: {
    padding: '18px'
  },
}));

const CustomListItem = ({ label, value, valueComp }: {
  label: string,
  value?: any,
  valueComp?: React.ReactNode,
  icon?: any
}) => {
  const testId = sanitizeTestId(label);
  return (
    <ListItem>
      <ListItemText>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>{label}</Box>
          {
            valueComp ? valueComp : <TestDataAttribute id={testId}>
              <Box>{value}</Box>
            </TestDataAttribute>
          }
        </Box>
      </ListItemText>
    </ListItem>
  );
};

const fundingPreferenceOptions = [
  {
    label: MERS_TPO,
    value: MERS_TPO,
  },
  {
    label: LOAN_FUNDER,
    value: LOAN_FUNDER,
  },
  {
    label: LOAN_FUNDER_WITH_MERS,
    value: LOAN_FUNDER_WITH_MERS,
  },
  {
    label: DBA,
    value: DBA,
  },
]


export const LoanFundingPreferences = observer(({ companyProfileStore }) => {
  const { userStore } = useStore();
  const { loanFundingPreferenceStore, salesforceLenderStore } = companyProfileStore;
  const { loanFundingPreferenceFormStore } = loanFundingPreferenceStore;
  const [editFundingPreference, setEditFundingPreference] = useState(false);
  const [mersOnboarding, setMersOnboarding] = useState(false);
  const [mersRMConfirmation, setmersRMConfirmation] = useState(false);
  const classes = useStyles();

  const formFields = loanFundingPreferenceFormStore.form.fields;
  const manageDate = date => {
    let formattedDate = null;
    if (isValid(date)) {
      formattedDate = format(date, 'yyyy-MM-dd');
    }
    loanFundingPreferenceStore.setAgreementDate(formattedDate)
  };

  useEffect(() => {
    loanFundingPreferenceStore.getLenderFundingPreference();
  }, [])

  const handleSave = () => {
    if ((salesforceLenderStore.isDecisionCommittee || salesforceLenderStore.isLimitedRelationshipManager || userStore.isBackOfficeUser) && (formFields.fundingPreference.value === 'TPO with MERS as nominee') && (formFields.agreementDate.value === null)) {
      setEditFundingPreference(true);
      return loanFundingPreferenceStore.showAgreementDateMessage();
    }
    loanFundingPreferenceStore.isMersSelected
      ? (salesforceLenderStore.isDecisionCommittee || salesforceLenderStore.isLimitedRelationshipManager || userStore.isBackOfficeUser
        ? setmersRMConfirmation(true)
        : setMersOnboarding(loanFundingPreferenceStore.isMersSelected))
      : loanFundingPreferenceStore.saveChanges();
    setEditFundingPreference(false);
  };

  const handleRMSave = () => loanFundingPreferenceStore.saveChanges();

  const closeModal = () => {
    setMersOnboarding(false)
  }

  const closeRMConfirmationModal = () => {
    setmersRMConfirmation(false);
    loanFundingPreferenceStore.resetAgreementDate();
    loanFundingPreferenceStore.getLenderFundingPreference();
  };

  const getEditButton = () => {
    return editFundingPreference || loanFundingPreferenceStore.isMersSaved ? undefined : () => setEditFundingPreference(true);
  }

  return (
    <Card title='LOAN FUNDING PREFERENCES' cardContentProps={{ style: { padding: 0 } }} className={classes.card}
      onEdit={getEditButton()}>
      <CustomListItem label='Your Funding Preference' valueComp={
        editFundingPreference ?
          <Box width={300}>
            {createSelectField({
              store: loanFundingPreferenceFormStore,
              fieldName: 'fundingPreference',
              testId: 'fundingPreference',
              label: '',
              options: fundingPreferenceOptions,
            })}
          </Box> : loanFundingPreferenceStore.fundingPreferenceLabel
      }
      />
      {(salesforceLenderStore.isDecisionCommittee || salesforceLenderStore.isLimitedRelationshipManager || userStore.isBackOfficeUser) && (formFields.fundingPreference.value === 'TPO with MERS as nominee') && (editFundingPreference) &&
        <Box width={300} className={classes.agreementDate}>
          < DateField
            inputVariant="outlined"
            testId="AgreementDate"
            format="yyyy-MM-dd"
            standaloneLabel
            label="Requested Agreement Date"
            value={formFields.agreementDate.value}
            onChange={date => manageDate(date)}
            disablePast={false}
            required
          />
        </Box>
      }
      {editFundingPreference && (
        <Box m={2} textAlign="right">
          <Button
            style={{ marginRight: 16 }}
            variant="outlined"
            onClick={() => {
              setEditFundingPreference(false);
              loanFundingPreferenceStore.resetAgreementDate();
              loanFundingPreferenceStore.getLenderFundingPreference();
            }}
            color="default"
            testId="cancel_changes"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              handleSave();
            }}
            color="primary"
            testId="save_changes"
          >
            Save
          </Button>
        </Box>
      )}
      <MersOnboardingModal open={mersOnboarding} handleClose={closeModal} companyProfileStore={companyProfileStore} />
      <MersRMConfirmationModal
        open={mersRMConfirmation}
        handleClose={closeRMConfirmationModal}
        handleSave={handleRMSave}
      />
    </Card>
  );
});
