import React, { useEffect } from 'react';
import { Box, Grid, Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import {
  FixFlipStore,
} from './../stores';
import { SelectBorrowers, SelectBorrowerMode, useBorrowersRoutes } from '@roc/feature-borrowers';
import { BorrowerFormFixFlip } from './borrowerFormFixFlip';
import { useBaseStore } from '@roc/feature-app-core';
import { ErrorsAlert, WarningsAlert } from './alerts';
import { LoanType } from '@roc/feature-utils';

const useStyles = makeStyles(theme =>
  createStyles({
    fieldSpacing: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    button: {
      marginRight: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    rowSpacing: {
      marginBottom: theme.spacing(2),
    },
    cardSpacing: {
      paddingRight: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  })
);

interface BorrowerInformationProps {
  store: FixFlipStore;
}

const BorrowerInformation = ({ store }: BorrowerInformationProps) => {
  const { globalStore } = useBaseStore();
  const { isNewEntity, borrowersErrors, borrowersWarnings, stepErrors, stepWarnings } = store;
  const isMultiple = !store.isDealRoom;
  const { borrowersRoutesConfig } = useBorrowersRoutes();

  // const { allowEditBorrowers } = useAppConfigurations();

  let modes = [SelectBorrowerMode.EXISTING];
  if (globalStore.userFeatures?.addNewBorrowerDuringSubmission) {
    modes = [...modes, SelectBorrowerMode.NEW];
  }
  useEffect(() => {
    store.selectBorrowersStore.canRemoveSelectedBorrowers = !store.isDealRoom;
  }, []);

  const validateBorrowers = () => {
    store.checkBorrowersErrors();
    store.checkBorrowersWarnings();
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4">Borrower information</Typography>
          <br />
          <Typography variant="body1">
            {isNewEntity
              ? 'Please add the borrowers of this loan.'
              : 'Please review the borrowers of this loan.'}
            <>
              To add a new borrower, please navigate to
              <Link
                href={borrowersRoutesConfig.borrowers.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span> View Borrowers.</span>
              </Link>
            </>
          </Typography>
        </Grid>
      </Grid>

      <br />

      <Grid container spacing={2}>
        <SelectBorrowers
          multiple={isMultiple}
          modes={modes}
          store={store.selectBorrowersStore}
          showAccordion={true}
          borrowerForm={(borrowerFormStore, currentBorrower) => (
            <BorrowerFormFixFlip
              store={borrowerFormStore}
              selectBorrowersStore={store.selectBorrowersStore}
              fixFlipStore={store}
              currentBorrower={currentBorrower}
            />
          )}
          validateBorrowers={validateBorrowers}
          borrowerCardDisplayFields={{
            firstName: true,
            lastName: true,
            cellPhone: true,
            emailAddress: true,
            personalGuarantor: true,
            pctOwnership: true,
            experience: true,
            citizenshipStatus: true,
            roleInTransaction: true,
          }}
          loanType={LoanType.RESIDENTIAL_BRIDGE}
        />
      </Grid>
      <Grid>
        <Box marginTop={'36px'}>
          <ErrorsAlert errors={[...borrowersErrors, ...stepErrors]} />
        </Box>
        <Box marginTop={'16px'}>
          <WarningsAlert warnings={[...borrowersWarnings, ...stepWarnings]} />
        </Box>
      </Grid>
    </>
  );
};

export default observer(BorrowerInformation);
