import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import { Folder, PictureAsPdf } from '@material-ui/icons';
import { Paper } from '@roc/ui';
import { observer } from 'mobx-react';
import { ReportItem } from './reportItem';

const useStyles = makeStyles({
  table: {
    minWidth: 350,
  },
});

export const ReportsList = observer(({ items, onReportItemClick }) => {
  const classes = useStyles();
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size="medium">
        <TableBody>
          {items.map((item) => (
            <TableRow key={item.sigmaEmbedDashboardId}>
              <TableCell scope="row" padding={'none'}>
                <ReportItem
                  name={item.menuText}
                  onClick={() => onReportItemClick(item)}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
});