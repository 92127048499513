import { Box, Grid } from '@material-ui/core';
import { LoanDrawsDashboard } from '@roc/feature-draws';
import { useLoanStore } from '@roc/feature-loans';
import { useLoanRoutes } from '@roc/feature-loans-routes-config';
import { Button } from '@roc/ui';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router';
import { LoanDetailsPaper } from '../components/loanDetailsPaper';

export const DrawsTab = observer(() => {
  const { loanStore } = useLoanStore();
  const { loanRoutesConfig } = useLoanRoutes();
  const { push } = useHistory();
  const { loanDetails } = loanStore;
  const loanId = loanDetails?.loanId;

  return (
    <Box width="100%">
      <Box p={1}>
        <LoanDetailsPaper>
          <Box p={1} textAlign="right">
            <Button
              testId="request-draw"
              variant="contained"
              color="primary"
              onClick={() =>
                push(loanRoutesConfig.loans(loanId).children.drawRequest.url)
              }
            >
              Request Draw
            </Button>
            <Button
              testId="revise-sow"
              variant="contained"
              color="primary"
              style={{ marginLeft: '8px' }}
              onClick={() =>
                push(
                  loanRoutesConfig.loans(loanId).children.reviseScopeOfWork.url
                )
              }
            >
              Revise Scope of Work
            </Button>
          </Box>
        </LoanDetailsPaper>
      </Box>
      <LoanDrawsDashboard
        loanId={loanId}
        showTitle={false}
        style={{
          padding: 0,
        }}
      />
    </Box>
  );
});
