import { Grid } from '@material-ui/core';
import { useUserStore } from '@roc/feature-app-core';
import { observer } from "mobx-react";
import { UserPreferenceCard } from './userPreferenceCard';
import { LenderSelectionCard } from './lenderSelectionCard';

interface NotiticationProps {
  children?: JSX.Element
  profileTitle?: string
  userPreferenceCardTitle?: string
}

export const Notifications = observer(({ children, profileTitle, userPreferenceCardTitle }: NotiticationProps) => {
  const { userStore } = useUserStore();
  const allowLoanPricing = userStore?.allowLoanPricing;

  return (
    <Grid container spacing={2} alignItems="stretch">
      {allowLoanPricing ? (
        <>
          <Grid item md={7} lg={7} xl={8} xs={12}>
            <LenderSelectionCard />
          </Grid>
          <Grid item md={5} lg={5} xl={4} xs={12}>
            <UserPreferenceCard title={userPreferenceCardTitle} />
          </Grid>
        </>
      ) : (
        <Grid item md={12} lg={12} xl={12} xs={12}>
          <LenderSelectionCard />
        </Grid>
      )}
    </Grid>
  );
});