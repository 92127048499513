import { FormStore, GlobalStore } from "@roc/feature-app-core";
import { action, computed, flow, makeObservable, observable } from "mobx";
import { parseAddress } from "@roc/feature-utils";
import { Property } from "@roc/feature-types";

const propertyInformationForm = {
  fields: {
    aptNumber: {
      value: '',
      error: null
    },
    address: {
      value: '',
      error: null,
      rule: 'required',
    },
    __isAddressInCorrectFormat__: {
      value: true,
      error: null,
      rule: 'accepted',
      message: 'Invalid Address Format',
    },
    streetNumber: {
      value: '',
      error: null,
      rule: '',
    },
    streetName: {
      value: '',
      error: null,
      rule: 'required',
    },
    city: {
      value: '',
      error: null,
      rule: 'required',
    },
    state: {
      value: '',
      error: null,
      rule: 'required',
    },
    zipCode: {
      value: '',
      error: null,
      rule: 'required',
    },
    latitude: {
      value: 0,
      error: null,
      rule: '',
    },
    longitude: {
      value: 0,
      error: null,
      rule: '',
    },
    useCode: {
      value: '',
      error: null,
      rule: 'required',
    },
    purchasePrice: {
      value: '',
      error: null,
      rule: 'required|max:1000000000000',
    },
    purchaseDate: {
      value: null,
      error: null,
      rule: 'required',
    },
    estimatedAsIsValue: {
      value: '',
      error: null,
      rule: 'required|max:1000000000000',
    },
    capitalImprovements: {
      value: '',
      error: null,
      rule: 'required|max:1000000000000'
    },
    rural: {
      value: null,
      error: null,
      rule: '',
    },

  },
  meta: {
    isValid: false,
    error: null,
  },
};

const propertyInformationFormPurchase = {
  fields: {
    aptNumber: {
      value: '',
      error: null
    },
    address: {
      value: '',
      error: null,
      rule: 'required',
    },
    __isAddressInCorrectFormat__: {
      value: true,
      error: null,
      rule: 'accepted',
      message: 'Invalid Address Format',
    },
    streetNumber: {
      value: '',
      error: null,
      rule: '',
    },
    streetName: {
      value: '',
      error: null,
      rule: 'required',
    },
    city: {
      value: '',
      error: null,
      rule: 'required',
    },
    state: {
      value: '',
      error: null,
      rule: 'required',
    },
    zipCode: {
      value: '',
      error: null,
      rule: '',
    },
    latitude: {
      value: 0,
      error: null,
      rule: 'required',
    },
    longitude: {
      value: 0,
      error: null,
      rule: '',
    },
    useCode: {
      value: '',
      error: null,
      rule: 'required',
    },
    purchasePrice: {
      value: '',
      error: null,
      rule: 'required|max:1000000000000',
    },
    estimatedAsIsValue: {
      value: '',
      error: null,
      rule: 'required|max:1000000000000',
    },
    rural: {
      value: null,
      error: null,
      rule: '',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

class PropertyInformationStore extends FormStore {
  private globalStore: GlobalStore;
  constructor(globalStore) {
    super({ ...propertyInformationForm }, globalStore);
    this.globalStore = globalStore;

    makeObservable(this, {
      onFormSubmit: flow,
    });
  }

  *onFormSubmit() {
    this.form.meta.error = '';
  }

  private saveAddressField(address: string, addressDetails, geometryDetails) {
    const parsedAddress = parseAddress(
      address,
      addressDetails,
      geometryDetails
    );
    this.onFieldChange('address', address);
    this.onFieldChange('streetNumber', parsedAddress.street_number);
    this.onFieldChange('streetName', parsedAddress.street);
    this.onFieldChange('city', parsedAddress.city);
    this.onFieldChange('state', parsedAddress.state);
    this.onFieldChange('zipCode', parsedAddress.zip);
    this.onFieldChange('latitude', parsedAddress.latitude);
    this.onFieldChange('longitude', parsedAddress.longitude);

    const addressInCorrectFormat = true;
    this.onFieldChange('__isAddressInCorrectFormat__', addressInCorrectFormat);
  }
}

class PropertyInformationStorePurchase extends FormStore {
  private globalStore: GlobalStore;
  constructor(globalStore) {
    super({ ...propertyInformationFormPurchase }, globalStore);
    this.globalStore = globalStore;
    makeObservable(this, {
      onFormSubmit: flow,
    });
  }

  *onFormSubmit() {
    this.form.meta.error = '';
  }

  private saveAddressField(address: string, addressDetails, geometryDetails) {
    const parsedAddress = parseAddress(
      address,
      addressDetails,
      geometryDetails
    );
    const streetAddress = parsedAddress.street_number === null ? parsedAddress.street : parsedAddress.street_number + " " + parsedAddress.street;
    this.onFieldChange('address', streetAddress);
    this.onFieldChange('streetNumber', parsedAddress.street_number);
    this.onFieldChange('streetName', parsedAddress.street);
    this.onFieldChange('city', parsedAddress.city);
    this.onFieldChange('state', parsedAddress.state);
    this.onFieldChange('zipCode', parsedAddress.zip);
    this.onFieldChange('latitude', parsedAddress.latitude);
    this.onFieldChange('longitude', parsedAddress.longitude);
    this.onFieldChange('aptNumber', parsedAddress.aptNumber);

    const addressInCorrectFormat = true;
    this.onFieldChange('__isAddressInCorrectFormat__', addressInCorrectFormat);
  }
}

export class PropertyDetailsStore {
  private globalStore: GlobalStore;
  propertyId: string;
  propertyInformationStore: PropertyInformationStore;
  propertyInformationStorePurchase: PropertyInformationStorePurchase;


  constructor(globalStore) {
    this.propertyInformationStore = new PropertyInformationStore(this.globalStore);
    this.propertyInformationStorePurchase = new PropertyInformationStorePurchase(this.globalStore);

    makeObservable(this, {
      reset: action,
      loadProperty: action,
      checkCondominium: computed,
    })
  }

  setPropertyId(propertyId) {
    this.propertyId = propertyId;
  }

  runAllFormValidations() {
    this.propertyInformationStore.runFormValidation();
    this.propertyInformationStorePurchase.runFormValidation();
  }

  getProperty(): Property {

    const data = this.propertyInformationStore.getFormValues();

    return {
      propertyId: this.propertyId,
      useCode: data['useCode'],
      aptNumber: data['aptNumber'],
      address: data['address'],
      streetNumber: data['streetNumber'],
      streetName: data['streetName'],
      city: data['city'],
      state: data['state'],
      zipCode: data['zipCode'],
      latitude: data['latitude'],
      longitude: data['longitude'],
      purchasePrice: data['purchasePrice'],
      purchaseDate: data['purchaseDate'],
      estimatedAsIsValue: data['estimatedAsIsValue'],
      capitalImprovements: data['capitalImprovements'],
      rural: data['rural']
    }
  }

  getPropertyPurchase(): Property {

    const data = this.propertyInformationStorePurchase.getFormValues();

    return {
      propertyId: this.propertyId,
      useCode: data['useCode'],
      aptNumber: data['aptNumber'],
      address: data['address'],
      streetNumber: data['streetNumber'],
      streetName: data['streetName'],
      city: data['city'],
      state: data['state'],
      zipCode: data['zipCode'],
      latitude: data['latitude'],
      longitude: data['longitude'],
      purchasePrice: data['purchasePrice'],
      estimatedAsIsValue: data['estimatedAsIsValue'],
      rural: data['rural']
    }
  }

  loadProperty(property: Property, runValidations: boolean = true) {
    this.propertyInformationStore.loadForm(property);
    this.propertyInformationStorePurchase.loadForm(property);
    if (runValidations) {
      this.runAllFormValidations();
    }
  }

  reset() {
    this.propertyInformationStore.reset();
    this.propertyInformationStorePurchase.reset();
  }

  get checkCondominium() {
    return (this.propertyInformationStorePurchase.form.fields.useCode.value === "Condominium" ||
      this.propertyInformationStore.form.fields.useCode.value === "Condominium")
  }
}

export default PropertyDetailsStore;