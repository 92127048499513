import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { observer } from 'mobx-react-lite';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const FilterTagsContainer = observer(({
  data,
  selectedData,
  onChange
}: {
  data: string[],
  selectedData: string[],
  onChange: (newValues: string[]) => void,
}) => {
  return (
    <Autocomplete
      multiple
      size="small"
      id="filter-tags-container"
      options={data}
      disableCloseOnSelect
      getOptionLabel={(option) => option}
      value={selectedData}
      onChange={(event: any, newValues: string[]) => {
        onChange(newValues);
      }}
      renderOption={(option, { selected }) => (
        <React.Fragment>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option}
        </React.Fragment>
      )}
      renderInput={(params) => (
        <TextField {...params} variant="outlined" label="Filter By" placeholder="Search" />
      )}
    />
  );
});
