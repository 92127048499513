import React, { useState, useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import {
  TextField,
  InputAdornment,
  IconButton,
  Divider,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';

export const ChatRoomSearchBar = observer(({ onSearch, conversationGroup }) => {
  const [query, setQuery] = useState('');
  const isInitialMount = useRef(true);

  const handleSearch = async () => {
    try {
      onSearch(conversationGroup, query);
    } catch (error) {
      console.error('Search failed', error);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else if (query === '') {
      handleSearch();
    }
  }, [query]);

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        margin: '10px 30px',
        borderRadius: '5px',
      }}
    >
      <TextField
        variant="outlined"
        placeholder="Search"
        value={query}
        onChange={e => setQuery(e.target.value)}
        onKeyPress={handleKeyPress}
        InputProps={{
          style: { height: '40px', padding: '0px' },
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={() => setQuery('')}
                size="small"
                style={{ padding: '5px', visibility: query === '' ? 'hidden' : 'visible' }}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
              <Divider
                orientation="vertical"
                flexItem
                style={{
                  backgroundColor: 'grey',
                  height: '20px',
                  alignSelf: 'center',
                  visibility: query === '' ? 'hidden' : 'visible'
                }}
              />
              <IconButton
                onClick={handleSearch}
                size="small"
                style={{ padding: '5px' }}
              >
                <SearchIcon fontSize="small" />
              </IconButton>
            </InputAdornment>
          ),
        }}

      />
    </div>
  );
});
