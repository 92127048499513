import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Layout } from '@roc/feature-app-core';
import { useBackgroundReviewStore } from '../hooks/useBackgroundReviewStore';
import BackgroundReviewForm from './backgroundReviewForm';
import { BorrowerReviewCustomTheme } from '../components/utils/borrowerReviewCustomTheme';

interface BackgroundReviewProps {
  borrowerId: string;
  loanId: string;
  isInternal: boolean;
  readOnly: boolean;
}

export const BackgroundReview = observer(({ borrowerId, loanId, isInternal, readOnly }: BackgroundReviewProps) => {
  const { backgroundReviewStore } = useBackgroundReviewStore();
  const { backgroundReviewFormStore, backgroundItemStore } = backgroundReviewStore;

  backgroundItemStore.currentBorrower = borrowerId;
  backgroundItemStore.loanId = loanId;
  backgroundItemStore.isInternal = isInternal;
  backgroundItemStore.readOnly = readOnly;
  backgroundItemStore.initializeService();
  if (!borrowerId) {
    return (<></>);
  }

  return (
    <div style={{ background: 'white', width: '100%' }}>
      <Layout>
        <BorrowerReviewCustomTheme>
        <BackgroundReviewForm store={backgroundItemStore} />
        </BorrowerReviewCustomTheme>
      </Layout>
    </div>
  );
});