import { GlobalStore } from '@roc/client-portal-shared/stores';
import { FormStore, UserStore } from '@roc/feature-app-core';
import {
  DateFormat,
  formatDate,
  GENERIC_ERROR_MESSAGE,
} from '@roc/feature-utils';
import { DialogState } from '@roc/ui';
import { action, flow, makeObservable, observable, override } from 'mobx';
import { GeneralTasksService } from '../services/generalTasksService';
import { GeneralTaskComment } from '../types/generalTasksTypes';
import { EditTaskStore } from './editTaskStore';

const form = {
  fields: {
    text: {
      value: '',
      error: null,
      rule: 'required',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export class GeneralTasksCommentStore extends FormStore {
  private globalStore: GlobalStore;
  private userStore: UserStore;
  private editTasksStore: EditTaskStore;
  private generalTaskService: GeneralTasksService;

  dialogState: DialogState;
  comment: GeneralTaskComment;

  constructor(
    globalStore: GlobalStore,
    userStore: UserStore,
    editTasksStore: EditTaskStore
  ) {
    super(form, globalStore);
    this.globalStore = globalStore;
    this.userStore = userStore;
    this.editTasksStore = editTasksStore;
    this.generalTaskService = new GeneralTasksService();

    makeObservable(this, {
      reset: override,
      dialogState: observable,
      comment: observable,
      closeDialog: action,
      openAddComment: flow,
      openEditComment: flow,
      saveComment: flow,
    });
  }

  reset() {
    super.reset();
    this.comment = null;
    this.dialogState = null;
  }

  *openAddComment() {
    this.reset();
    this.dialogState = DialogState.ADD;
  }

  *openEditComment(comment) {
    this.reset();
    this.loadForm(comment);
    this.comment = comment;
    this.dialogState = DialogState.EDIT;
  }

  removeComment(comment) {
    const comments = this.editTasksStore.comments.filter(
      item => item != comment
    );
    this.editTasksStore.setComments(comments);
  }

  *saveComment() {
    try {
      if (this.runFormValidationWithMessage()) {
        const { comments } = this.editTasksStore;
        const values = this.getFormValues();
        const isExistingComment = !!this.comment;

        const newComment: GeneralTaskComment = {
          authorId: isExistingComment
            ? this.comment.authorId
            : this.userStore.userInformation.userId,
          text: values.text,
          createdDate: isExistingComment
            ? this.comment?.createdDate
            : formatDate(new Date(), 'MM/dd/yyyy HH:mm:ss'),
        };

        const updatedComments = isExistingComment
          ? comments.map(item => (item == this.comment ? newComment : item))
          : comments.concat(newComment);

        yield this.generalTaskService.saveComments(
          this.editTasksStore?.task?.taskDetails?.taskId,
          comments
        );
        this.globalStore.notificationStore.showSuccessNotification({
          message: 'Comment saved successfully!',
        });
        this.editTasksStore.setComments(updatedComments);
        this.closeDialog();
      }
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE,
      });
    }
  }

  closeDialog() {
    this.dialogState = null;
  }
}
