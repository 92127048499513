import { Grid } from '@material-ui/core';
import { observer } from 'mobx-react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { createRadioField, FileUpload, FileUploadArea } from '@roc/ui';
import { yesNoOptions } from '@roc/feature-utils';
import { useEffect, useState } from 'react';

const useStyles = makeStyles((theme: Theme) => {
  return {

  };
});

export const TrackRecordThirdStep = observer(({ store }) => {
  const formStore = store.trackRecordOtherFormStore;
  const classes = useStyles();
  const [generalContractorUploads, setGeneralContractorUploads] = useState<FileUpload[]>([]);
  const [brokerUploads, setBrokerUploads] = useState<FileUpload[]>([]);

  useEffect(() => {
    store.setGeneralContractorUploads(generalContractorUploads);
  }, [generalContractorUploads]);

  useEffect(() => {
    store.setBrokerUploads(brokerUploads);
  }, [brokerUploads]);

  return (
    <Grid container direction='row' spacing={2}>
      <Grid item xs={12} />
      <Grid item xs={12}>
        {createRadioField({
          store: formStore,
          testId: 'generalContractor',
          fieldName: 'generalContractor',
          label: store?.isTpoFlow ? "Is the borrower a General Contract?" : "Are you a General Contractor?",
          options: yesNoOptions,
          row: true,
        })}
      </Grid>
      {formStore.form.fields.generalContractor.value == 'Yes' &&
        <Grid item xs={12}>
          <FileUploadArea
            items={generalContractorUploads}
            setItems={(items) => setGeneralContractorUploads(items)}
            multiple={false}
          />
        </Grid>
      }
      <Grid item xs={12}>
        {createRadioField({
          store: formStore,
          testId: 'broker',
          fieldName: 'broker',
          label: store?.isTpoFlow ? "Is the borrower a Real Estate Broker / Agent?" : "Are you a Real Estate Broker / Agent?",
          options: yesNoOptions,
          row: true,
        })}
      </Grid>
      {formStore.form.fields.broker.value == 'Yes' &&
        <Grid item xs={12}>
          <FileUploadArea
            items={brokerUploads}
            setItems={(items) => setBrokerUploads(items)}
            multiple={false}
          />
        </Grid>
      }
    </Grid>
  );
});
