import { observer } from 'mobx-react';
import { Button } from '@roc/ui';
import { Edit, Delete } from '@material-ui/icons';
import { Grid, makeStyles } from '@material-ui/core';
import { deleteButtonStyle } from '../../../utils/constants';

const useStyles = makeStyles(() => ({
  deleteButton: deleteButtonStyle,
}));

interface PreflightActionButtonsProps {
  onDelete?: () => void;
  onReset?: () => void;
  onSave?: () => void;
  onSkip?: () => void;
  disableDelete?: boolean;
  disableSave?: boolean;
  disableReset?: boolean;
  disableSkip?: boolean;
}

export const PreflightActionButtons = observer(
  ({
    onDelete,
    onReset,
    onSave,
    onSkip,
    disableDelete = false,
    disableReset = false,
    disableSave = false,
    disableSkip = false,
  }: PreflightActionButtonsProps) => {
    const classes = useStyles();

    return (
      <Grid container spacing={2} justifyContent="center">
        <Grid item>
          {onDelete && (
            <Button
              size="medium"
              variant="outlined"
              color="primary"
              startIcon={<Delete />}
              testId="delete-borrower"
              onClick={onDelete}
              className={classes.deleteButton}
              disabled={disableDelete}
            >
              REMOVE
            </Button>
          )}
          {onReset && (
            <Button
              size="medium"
              variant="outlined"
              testId="reset"
              color="primary"
              onClick={onReset}
              disabled={disableReset}
            >
              RESET
            </Button>
          )}
        </Grid>
        <Grid item>
          {onSave && (
            <Button
              size="medium"
              variant="outlined"
              testId="save"
              color="primary"
              onClick={onSave}
              disabled={disableSave}
            >
              SAVE
            </Button>
          )}
        </Grid>
        <Grid item>
          {onSkip && (
            <Button
              size="medium"
              variant="outlined"
              testId="skip"
              color="primary"
              onClick={onSkip}
              disabled={disableSkip}
            >
              SKIP
            </Button>
          )}
        </Grid>
      </Grid>
    );
  }
);
