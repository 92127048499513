import React, { useState } from 'react';
import { observer } from "mobx-react";
import { Grid, Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  AgGridColumnProps,
  Paper,
  DataGrid as ServicerFeesGrid
} from '@roc/ui';
import { useEffect } from 'react';
import { formatCurrency } from '@roc/ui/utils';
import { PayoffRequestStore } from '@roc/client-portal-shared/stores';
import { StandardDialog, SelectField, CurrencyField, Button, DropdownMenu, ConfirmDialog } from '@roc/ui';
import { MoreVert, Edit, Delete } from '@material-ui/icons';
import { servicerFeesOptions } from '../constants/payoffs';

const noOpComparator = () => 0;

const useStyles = makeStyles(theme => ({
  paper: {
    padding: 24,
  },
  gridContainer: {
    paddingTop: 24,
  },
  typographyStyle: {
    marginRight: 10
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginLeft: 24,
  },
}));

interface IServicerFeesProps {
  loanId: string;
  store: PayoffRequestStore;
}

export const ServicerFees = observer(({ loanId, store }: IServicerFeesProps) => {
  const classes = useStyles();
  const { servicerFeesFormStore } = store;
  const { form } = servicerFeesFormStore;
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  // Record index
  const [record, setRecord] = useState(null);

  const onFieldChange = (fieldName: string, value: any) => {
    servicerFeesFormStore.onFieldChange(fieldName, value);
  };

  const onSubmitEditModal = () => {
    if (record != null) {
      servicerFeesFormStore.editServicerFee(loanId, record);
    } else {
      servicerFeesFormStore.addServicerFee(loanId);
    }
    closeEditModal();
  };

  const closeEditModal = () => {
    resetFormFields();
    setEditModalOpen(false);
  };

  const addNewFee = () => {
    setRecord(null);
    setEditModalOpen(true);
  };

  const deleteFee = (index: number) => {
    setRecord(index);
    setConfirmationModalOpen(true);
  };

  const editExistingFee = (index: number) => {
    setRecord(index);
    const existingFee = servicerFeesFormStore.servicerFees[index];
    onFieldChange('description', existingFee.description)
    onFieldChange('origAmt', existingFee.origAmt)
    setEditModalOpen(true);
  };

  const resetFormFields = () => {
    servicerFeesFormStore.reset();
  };

  useEffect(() => {
    return () => {
      servicerFeesFormStore.resetStore();
    }
  }, []);

  const servicerFeesColumns: AgGridColumnProps[] = [
    {
      field: 'description',
      headerName: 'Category',
      minWidth: 100,
      comparator: noOpComparator,
      sortable: false,
    },
    {
      field: 'origAmt',
      headerName: 'Amount',
      minWidth: 120,
      comparator: noOpComparator,
      sortable: false,
      cellRenderer: 'currencyCellRenderer',
    },
    {
      field: 'actions',
      headerName: 'Actions',
      minWidth: 100,
      cellRenderer: 'actionCellRenderer',
      comparator: noOpComparator,
      width: 100,
      pinned: 'right',
      lockPinned: true,
    },
  ];

  const frameworkComponents = {
    currencyCellRenderer({ value }) {
      return formatCurrency(value);
    },
    actionCellRenderer: params => {
      const data = params.node;
      return (
        <>
          <DropdownMenu options={getActions(data)}>
            <MoreVert />
          </DropdownMenu>
        </>
      );
    },
  };

  const getActions = (node) => {
    const actionList = [];
    if (servicerFeesOptions.some(e => e.value === node.data?.description)) {
      actionList.push(
        {
          icon: Edit,
          name: 'Edit Fee',
          action: () => editExistingFee(node.rowIndex),
        }
      );
    }
    actionList.push(
      {
        icon: Delete,
        name: 'Remove Fee',
        action: () => deleteFee(node.rowIndex),
      }
    );
    return actionList;
  };

  const getServicerFeesEditModal = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <SelectField
            standaloneLabel
            label={'Description'}
            options={servicerFeesOptions}
            testId={'select-fee-description-dropdown'}
            value={form.fields.description.value}
            variant={'outlined'}
            fullWidth
            onChange={(value) => { onFieldChange('description', value) }
            }
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <CurrencyField
            testId={'fee-amount-field'}
            variant={'outlined'}
            label={'Amount'}
            standaloneLabel
            value={form.fields.origAmt.value}
            onChange={(name, value) => onFieldChange('origAmt', Number(value))}
            fullWidth
          />
        </Grid>
        <Grid item container className={classes.buttonContainer}>
          <Box mr={2}>
            <Button
              className={classes.button}
              size="small"
              variant="contained"
              color="secondary"
              onClick={() => closeEditModal()}
              testId="cancel"
            >
              CANCEL
            </Button>

            <Button
              className={classes.button}
              size="small"
              variant="contained"
              color="primary"
              onClick={() => onSubmitEditModal()}
              testId="save"
              disabled={!(form.fields.description?.value && form.fields.origAmt?.value)}
            >
              SUBMIT
            </Button>
          </Box>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <Grid item xs={12} md={12} className={classes.gridContainer}>
        <Paper className={classes.paper}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Typography className={classes.typographyStyle} variant="h5">SERVICER FEES</Typography>
                </Grid>
                <Grid item>
                  <Box display="flex" justifyContent="flex-end">
                    <Button
                      testId="addServicerFee"
                      variant="contained"
                      size="small"
                      color="primary"
                      onClick={() => addNewFee()}
                    >
                      ADD NEW FEE
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <ServicerFeesGrid
              columns={servicerFeesColumns}
              rows={servicerFeesFormStore.servicerFees}
              domLayout="autoHeight"
              frameworkComponents={frameworkComponents}
            />
          </Grid>
        </Paper>
      </Grid>
      <StandardDialog
        open={editModalOpen}
        title="Servicer Fees"
        maxWidth="sm"
        handleClose={() => closeEditModal()}
        dialogContent={
          getServicerFeesEditModal()
        }
      />
      <ConfirmDialog
        open={confirmationModalOpen}
        confirmButtonText={'Yes'}
        handleClose={() => setConfirmationModalOpen(false)}
        handleCancel={() => setConfirmationModalOpen(false)}
        handleConfirm={() => {
          servicerFeesFormStore.deleteServicerFee(loanId, record);
          setConfirmationModalOpen(false);
        }}
        title={'Delete Servicing Fee'}
        body={`Are you sure you want to delete the servicing fee?`}
      />
    </>
  );
});