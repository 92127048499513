import { action, flow, makeObservable, observable } from 'mobx';
import { GlobalStore } from '../../../stores';
import { FormStore } from '@roc/feature-app-core';
import { CompanyProfileService } from '../services/CompanyProfileService';
import { ExternalLenderStore } from './externalLenderStore';

const REQUIRED_MESSAGE = 'This field is required';

const form = {
  fields: {
    pointSplitSchemes: {
      value: '',
      error: null,
      rule: '',
      message: REQUIRED_MESSAGE,
    },
    lenderPointsShare: {
      value: '',
      error: null,
      rule: '',
      message: REQUIRED_MESSAGE,
    },
    yieldSpreadMultiplier: {
      value: '',
      error: null,
      rule: '',
      message: REQUIRED_MESSAGE,
    },
    tier: {
      value: '',
      error: null,
      rule: '',
      message: REQUIRED_MESSAGE,
    },
    yspNormalizationFactor: {
      value: '',
      error: null,
      rule: '',
      message: REQUIRED_MESSAGE,
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export class LoanEconomicsStore extends FormStore {
  private globalStore: GlobalStore;
  companyProfileService: CompanyProfileService;
  salesForceLenderStore: ExternalLenderStore;

  isEditing = false;
  lender;

  constructor(globalStore, lenderStore) {
    super(form, globalStore);
    this.companyProfileService = new CompanyProfileService();
    this.salesForceLenderStore = lenderStore;
    this.globalStore = globalStore;
    makeObservable(this, {
      isEditing: observable,
      edit: action,
      cancel: action,
      loadLenderDetails: action,
      saveLenderDetails: flow,
    });
  }

  edit() {
    this.isEditing = true;
  }

  cancel() {
    this.isEditing = false;
    this.loadLenderDetails();
  }

  loadLenderDetails() {
    this.lender = this.salesForceLenderStore.salesForceLender;
    this.loadForm(this.lender)
  }

  *saveLenderDetails() {
    try {
      if (this.form.meta.isValid) {
        this.isEditing = false;
        const values = this.getFormValues();

        this.lender = {
          ...this.lender,
          pointSplitSchemes: values.pointSplitSchemes,
          lenderPointsShare: values.lenderPointsShare,
          yieldSpreadMultiplier: values.yieldSpreadMultiplier,
          tier: values.tier,
          yspNormalizationFactor: values.yspNormalizationFactor,
        }

        yield this.companyProfileService.updateLender({ ...this.lender });

        this.globalStore.notificationStore.showSuccessNotification({
          message: 'Loan Economics have been updated successfully.',
        });
      }
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while saving Loan Economics details',
      });
    }
  }
}
