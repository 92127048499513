import { Page } from '@roc/feature-app-core';
import { HtmlDocument } from 'libs/feature-document-library-contentful/src/documentLibrary/components/htmlDocument';
import { DocumentLibraryHome } from 'libs/feature-document-library-contentful/src/documentLibrary/documentLibraryHome';
import { Route } from 'react-router';
import { useRocInternalPortalStore } from '../../../hooks';

const DOCUMENT_PATH = ':contentId';

export const getDocumentLibraryInternalRoutesConfig = (
  basePath: string,
  baseUrl: string,
  portalId = null,
  nportalInternal?: string,
) => {
  return {
    documentLibraryHome: {
      path: `${basePath}/document-library-home`,
      url: `${baseUrl}/document-library-home`,
      documentTitle: 'Document Library-home'
    },
    documentLibraryDocument: (contentId?: string) => ({
      path: `${basePath}/document-library/${DOCUMENT_PATH}`,
      url: `${baseUrl}/document-library/${contentId}`,
      documentTitle: `Document Library - ${contentId}`
    }),
    documentLibraryDocumentPreview: (contentId?: string) => ({
      path: `${basePath}/document-library/${DOCUMENT_PATH}/preview`,
      url: `${baseUrl}/document-library/${contentId}/preview`,
      documentTitle: `Document Library - ${contentId} - Preview`
    }),
  };
};

export const getDocumentLibraryInternalRoutes = (basePath: string, baseUrl: string, portalId = null, nportalInternal?: string) => {
  const config = getDocumentLibraryInternalRoutesConfig(basePath, baseUrl, portalId, nportalInternal);
  const { documentLibraryInternalStoreNew } = useRocInternalPortalStore();

  return {
    documentLibraryHome: (
      <Route exact path={config.documentLibraryHome.path}>
        <Page routeDefinition={config.documentLibraryHome}>
          <DocumentLibraryHome documentLibraryStoreNew={documentLibraryInternalStoreNew} isInternal={true} nportalInternal={nportalInternal} />
        </Page>
      </Route>
    ),
    documentLibraryDocument: (
      <Route exact path={config.documentLibraryDocument().path}>
        <HtmlDocument documentLibraryStoreNew={documentLibraryInternalStoreNew} />
      </Route>
    ),
    documentLibraryDocumentPreview: (
      <Route exact path={config.documentLibraryDocumentPreview().path}>
        <HtmlDocument preview documentLibraryStoreNew={documentLibraryInternalStoreNew} />
      </Route>
    ),
  };
};
