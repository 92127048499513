import { Box, Tooltip } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Delete, Edit, Person, Warning } from '@material-ui/icons';
import { Button, IconButton } from '@roc/ui';
import { observer } from 'mobx-react';
import { useNewLoanApplicationStore } from '../hooks/useNewLoanApplicationStore';
import {
  LoanApplicationCard,
  LoanApplicationCardActions,
  LoanApplicationCardDividers,
  LoanApplicationCardField,
} from './loanApplicationComponents';

export const BorrowersCard = observer(({ editable = true }) => {
  const theme = useTheme();
  const { newLoanApplicationStore } = useNewLoanApplicationStore();
  const { borrowerEntityFormStore, borrowerStore } = newLoanApplicationStore;
  const { showTotalOwnershipWarning, borrowers } = borrowerStore;

  return (
    <LoanApplicationCard
      icon={<Person />}
      title={'Borrowers'}
      error={showTotalOwnershipWarning}
    >
      <LoanApplicationCardDividers px={2} py={2}>
        {borrowers.map((borrower, i) => (
          <LoanApplicationCardField
            label={
              <>
                {borrower.firstName} {borrower.lastName}
              </>
            }
            value={
              <Box display={'flex'} alignItems={'center'}>
                {borrowers.length > 1 && (
                  <>{borrower.pctOwnership || '0'}% Ownership</>
                )}
              </Box>
            }
          />
        ))}
        {editable && (
          <LoanApplicationCardActions>
            <Button
              style={{ marginRight: '8px' }}
              color="primary"
              testId={'editEntity'}
              variant="outlined"
              onClick={() => borrowerStore.openAddBorrower()}
            >
              ADD NEW BORROWER
            </Button>
            <Button
              color="primary"
              testId={'editEntity'}
              variant="outlined"
              onClick={() => borrowerStore.openEditBorrowers()}
            >
              EDIT BORROWER(S)
            </Button>
          </LoanApplicationCardActions>
        )}
      </LoanApplicationCardDividers>
    </LoanApplicationCard>
  );
});
