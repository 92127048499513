import { Service } from './../../../services';

/*const url = {
  GET_INFORMATION:
    '/nportal/rest/priv/borrowerPfs/getPersonalFinancialStatement',
  SUBMIT_FORM: '/nportal/rest/priv/borrowerPfs/savePersonalFinancialStatement',
};*/

const url = {
  GET_INFORMATION: '',
  SUBMIT_FORM: '',
};

export class BorrowerPfsService extends Service {
  getForm(borrowerId) {
    return this.get(url.GET_INFORMATION, {
      borrowerId,
    });
  }
  submitForm(borrowerId, data) {
    return this.post(`${url.SUBMIT_FORM}?borrowerId=${borrowerId}`, data);
  }
}
