import { Box, Grid, Typography } from "@material-ui/core";
import { observer } from "mobx-react";
import { PropertyCard } from "./components/propertyCard";
import { QuickLinks } from "./components/quickLinksCard";
import { AppraiserCard } from "./components/appraiserCard";
import { TitleHistoryTable } from "./components/titleHistoryTable";
import { CompAnalysisTable } from "./components/compAnalysisTable";
import { MarketSaleDataComparisonTable } from "./components/marketSaleDataComparisonTable";
import { MarketLiquidityDataTable } from "./components/marketLiquidityDataTable";
import { ValuationReviewReportStore } from "./stores/valuationReviewReportStore";
import { useEffect } from "react";
import { ValuationReviewCard } from "./components/valuationReviewCard";
import { InternalValuationReportCard } from "./components/internalValuationReportCard";

export type Props = {
  store: ValuationReviewReportStore;
  loanId: number,
  propertyId: number,
  readOnlyMode?: boolean
}
export const ValuationReview = observer((props: Props) => {
  const { store, loanId, propertyId, readOnlyMode = false } = props;
  useEffect(() => {
    store.setDefaults();
    if (!readOnlyMode) {
      store.getPopertyActivityList(propertyId);
    }
    store.getAppraisalReviewInformation(loanId, propertyId);
  }, [store, loanId, propertyId, readOnlyMode]);


  if (readOnlyMode) {
    return (<Box>
      <Grid container spacing={2} style={{
        width: '100%',
        margin: 'auto',
        padding: 8,
      }}>
        <Grid item xs={12}>
          <ValuationReviewCard layout={2} store={store} propertyId={propertyId} loanId={loanId} disabled={readOnlyMode} />
        </Grid>
        {document && (
          <Grid item xs={12}>
            <Box>
              <InternalValuationReportCard loanId={loanId} propertyId={propertyId} readOnly={readOnlyMode} />
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>);
  }

  return (
    <Box width={'100%'}>
      <Box mb={2}>
        <Typography variant="h4">Valuation Review</Typography>
      </Box>
      <Grid container spacing={2} style={{
        width: '100%',
        margin: 'auto',
        padding: 8,
      }}>
        <Grid item xs={12} md={6}>
          <Box mb={2}>
            <PropertyCard store={store} />
          </Box>
          <Box mb={2}>
            <QuickLinks store={store} propertyId={propertyId} loanId={loanId} />
          </Box>
          <Box mb={2}>
            <AppraiserCard store={store} propertyId={propertyId} />
          </Box>
          <Box>
            <InternalValuationReportCard loanId={loanId} propertyId={propertyId} readOnly={false} />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <ValuationReviewCard layout={2} store={store} propertyId={propertyId} loanId={loanId} />
        </Grid>
        <Grid item xs={12}>
          <Box mb={2}>
            <TitleHistoryTable store={store} />
          </Box>
          <Box>
            <CompAnalysisTable store={store} />
          </Box>
        </Grid>
        <Grid item xs={12} md={8}>
          <Box mb={2}>
            <MarketSaleDataComparisonTable store={store} />
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box mb={2}>
            <MarketLiquidityDataTable store={store} propertyId={propertyId} loanId={loanId} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
})