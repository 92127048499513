import { FormStore } from '@roc/feature-app-core';

const trackRecordEntityForm = {
  fields: {
    entityName: {
      value: '',
      error: null,
      message: 'The Entity Name is required',
      rule: 'required',
    },
    entityState: {
      value: '',
      error: null,
      message: 'The Entity State is required',
      rule: 'required',
    },
    borrowerEntityBorrowerId: {
      value: null,
      error: null,
      rule: '',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export class TrackRecordEntityFormStore extends FormStore {

  constructor(globalStore) {
    super({ ...trackRecordEntityForm }, globalStore);
  }

}