import { Box } from '@material-ui/core';
import Menu, { MenuProps } from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { useTheme, withStyles } from '@material-ui/core/styles';
import { IconButton } from '@roc/ui';
import { observer } from 'mobx-react';
import React from 'react';
import { useLoanFlags } from '../hooks/useLoanFlags';
import { CreateAppraisalOrderButton } from './common/createAppraisalOrderButton';
import { EditSubmissionButton } from '@roc/feature-edit-loans';
import { EditLoanButton } from '@roc/feature-loans-shared';
import { useLoanStore } from '@roc/feature-loans';
import { EditRounded } from '@material-ui/icons';
import { LoanSubType } from '@roc/feature-utils';
import { AddNewTaskButton } from '@roc/feature-general-tasks';
import { useUserStore } from '@roc/feature-app-core';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles(theme => ({
  root: {
    margin: 8,
    padding: 0,
    minWidth: 200,
    '& button': {
      padding: 16,
      width: '100%',
      justifyContent: 'flex-start',
    },
    '&>.MuiBox-root': {
      width: '100%',
      margin: 0,
    },
  },
}))(MenuItem);

export const LoanDetailsCardActionsButton = observer(() => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { loanStore } = useLoanStore();
  const { loanDetails } = loanStore;
  const loanId = loanDetails?.loanId;
  const theme = useTheme();
  const {
    showCreateApprasialOrderButton,
    showEditLoanButton,
    showEditSubmissionButton,
  } = useLoanFlags();
  const { userStore } = useUserStore();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const displayCreateAppraisalOrderButton = showCreateApprasialOrderButton && loanDetails?.loanSubType !== LoanSubType.FIX_AND_FLIP_PRO;

  const showCreateTask = userStore?.isBackOfficeUser;

  return displayCreateAppraisalOrderButton ||
    showEditSubmissionButton ||
    showEditLoanButton || 
    showCreateTask ? (
    <Box position={'absolute'} top={-8} right={-12}>
      <IconButton
        testId="menu"
        color="default"
        onClick={handleClick}
        size="small"
        style={{
          border: `1px solid ${theme.palette.common.black}`,
          marginTop: 4,
          borderRadius: '50%',
        }}
      >
        <EditRounded />
      </IconButton>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {showEditLoanButton && (
          <StyledMenuItem>
            <EditLoanButton loanStore={loanStore} data={loanDetails} />
          </StyledMenuItem>
        )}
        {displayCreateAppraisalOrderButton && (
          <StyledMenuItem>
            <CreateAppraisalOrderButton />
          </StyledMenuItem>
        )}
        {showEditSubmissionButton && (
          <StyledMenuItem>
            <EditSubmissionButton loanStore={loanStore} loanId={loanId} />
          </StyledMenuItem>
        )}
        {showCreateTask && (
          <StyledMenuItem>
            <AddNewTaskButton loanId={loanId}/>
          </StyledMenuItem>
        )}
      </StyledMenu>
    </Box>
  ) : null;
});
