import { Service } from '@roc/feature-app-core';

export abstract class NewLeadBaseService extends Service {
  abstract getCompanyNames();
  abstract getLeadMetadata(leadType: string);
  abstract getBusinessUnit();
  abstract submitNewLead(userDetails: any);
  abstract validateLeadToConvert(userDetails: any);
  abstract possibleMatchesByEmail(
    email: string,
    page: number,
    pageSize: number,
    sortDir: string,
    sortBy: string,
    filters: string
  );
  abstract possibleMatchesByDomain(
    domain: string,
    page: number,
    pageSize: number,
    sortDir: string,
    sortBy: string,
    filters: string
  );
  abstract possibleMatchesByPhone(
    phone: string,
    page: number,
    pageSize: number,
    sortDir: string,
    sortBy: string,
    filters: string
  );
  abstract possibleMatchesByName(
    name: string,
    page: number,
    pageSize: number,
    sortDir: string,
    sortBy: string,
    filters: string
  );
  abstract assignOwner(recordId: string, businessUnit: string, objectType: string);
  abstract uploadLeads(formData, userName);
  abstract downloadLeadsTemplate();
  abstract getAllOwnersByProcessor(userId);
  abstract saveLeadSearchHistory(searchDetails);
}
