import { IconButton } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import { InfiniteScrollGrid } from '@roc/feature-app-core';
import {
  LinkColumn,
  SelectFilterComponent,
  SelectFloatingFilterComponent,
  StatusChip,
  StatusType,
  TextColumn, FastForwardIcon
} from '@roc/ui';
import { observer } from 'mobx-react';
import { useLoanRoutes } from '@roc/feature-loans-routes-config';
import { useLoanStore } from '../hooks/useLoanStore';
import { getUnreadMessagesCount, StyledBadge } from './myPipelineLoansGrid';

export const PriorityLoansV2Grid = observer(({ columns }) => {
  const { loanStore } = useLoanStore();
  const { loanRoutesConfig } = useLoanRoutes();

  const frameworkComponents = {
    selectFilter: SelectFilterComponent,
    selectFloatingFilter: SelectFloatingFilterComponent,
    loanIdCellRenderer: params =>
      loanStore.isLoanSubtypeSupported(params.node.data?.loanSubType) ? (
        <StyledBadge
          badgeContent={getUnreadMessagesCount(
            params.node.data?.unreadMessages
          )}
        >
          {LinkColumn({
            ...params,
            url: loanRoutesConfig.loans(params.value).children.dashboard.url,
          })}
        </StyledBadge>
      ) : (
        <StyledBadge
          badgeContent={getUnreadMessagesCount(
            params.node.data?.unreadMessages
          )}
        >
          {TextColumn(params)}
        </StyledBadge>
      ),
    dealNameCellRenderer: params => {
      const loanId = params.node.data?.dealid;
      return LinkColumn({
        ...params,
        url: loanRoutesConfig.loans(loanId).children.dashboard.url,
      });
    },
    fastTrackCellRenderer: ({ value }) => {
      if (!value) {
        return null;
      }
      return (<FastForwardIcon value={value} />)
    },
    statusCellRenderer: ({ value }) =>
      value ? (
        <StatusChip
          label={value?.toUpperCase()}
          size="small"
          statusType={StatusType.DOCUMENT}
        />
      ) : null,
    actionCellRenderer: params => {
      return (
        <IconButton>
          <MoreVert color="primary" />
        </IconButton>
      );
    },
  };

  return (
    <InfiniteScrollGrid
      columns={columns}
      frameworkComponents={frameworkComponents}
      store={loanStore.priorityLoansV2GridStore}
    />
  );
});
