import { Home } from '@material-ui/icons';
import { Page, useBaseStore } from '@roc/feature-app-core';
import { useCommunicationRoutesConfig, useLoanCommunicationStore } from '@roc/feature-communication';
import { useBorrowerLiteLoanRoutes } from '@roc/feature-loans-routes-config';
import { LoanDetailsPaperLayout } from '@roc/feature-loans-shared';
import { LoanType } from '@roc/feature-utils';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useParams
} from 'react-router-dom';
import { useBorrowerLoansRoutes } from '../../../hooks/useBorrowerLoansRoutes';
import { useBorrowerLoansStore } from '../../../hooks/useBorrowerLoansStore';
import { BorrowerLoanOverviewBridgeLoans } from '../overview/borrowerLoanOverviewBridgeLoans';
import { BorrowerLoanOverviewTermLoans } from '../overview/borrowerLoanOverviewTermLoans';

export const LoanOverview = observer(({ loanDetails }) => {
  const { globalStore } = useBaseStore();
  const { loanCommunicationStore } = useLoanCommunicationStore();
  const { breadCrumbsStore } = globalStore;
  const { loanId } = useParams<{ loanId: string }>();
  const {
    borrowerLoanRoutesConfig: loanRoutesConfig,
  } = useBorrowerLoansRoutes();

  useEffect(() => {
    breadCrumbsStore.setBreadCrumbItems([
      {
        link: true,
        title: 'Dashboard',
        url: globalStore.routeBaseUrl,
        icon: <Home />,
      },
      {
        link: true,
        title: `Loan# ${loanId}`,
        url: loanRoutesConfig.loans(loanId).url,
      },
      {
        link: false,
        title: 'Loan Summary',
      },
    ]);
  });

  useEffect(() => {
    if (loanDetails?.loanId) {
      loanCommunicationStore.fetchUnreadMessagesCount(loanId);
    }
  }, [loanDetails?.loanId]);

  switch (loanDetails?.loanType) {
    case LoanType.RESIDENTIAL_BRIDGE:
      return <BorrowerLoanOverviewBridgeLoans />;
    case LoanType.RESIDENTIAL_TERM:
      return <BorrowerLoanOverviewTermLoans />;
  }
});

export const BorrowerLoanDetailsLiteChildRoutes = observer(({ isNew = false }) => {
  const { loanId } = useParams<{ loanId: string; }>();
  const { push } = useHistory();
  const { borrowerLoansStore } = useBorrowerLoansStore();
  const { loanDetails } = borrowerLoansStore;
  const { borrowerLiteLoanRoutesConfig } = useBorrowerLiteLoanRoutes();
  const loanDetailsChildRoutes = borrowerLiteLoanRoutesConfig.loanview(loanId).children;
  const { communicationRoutesConfig } = useCommunicationRoutesConfig();

  if (!loanDetails) {
    return null;
  }

  return (
    <Switch>
      <Route
        exact
        path={`${borrowerLiteLoanRoutesConfig.loanview(loanId).path}/`}
        render={() => {
          return <Redirect to={loanDetailsChildRoutes.dashboard.url} />;
        }}
      />
      <Route exact path={loanDetailsChildRoutes.dashboard.path}>
        <Page routeDefinition={loanDetailsChildRoutes.dashboard}>
          <LoanDetailsPaperLayout title="Loan Summary">
            <LoanOverview loanDetails={loanDetails} />
          </LoanDetailsPaperLayout>
        </Page>
      </Route>
    </Switch>
  );
});
