import { useBaseStore } from '@roc/feature-app-core';
import { getPayoffsRoutes, getPayoffsRoutesConfig } from '../routes/payoffsRoutes';

export const usePayoffsRoutes = () => {
  const { globalStore } = useBaseStore();
  const { routeBasePath, routeBaseUrl } = globalStore;
  return {
    borrowerPayoffsRoutes: getPayoffsRoutes(routeBasePath, routeBaseUrl),
    borrowerPayoffsRoutesConfig: getPayoffsRoutesConfig(
      routeBasePath,
      routeBaseUrl
    ),
  };
};
