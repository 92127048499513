import { observer } from 'mobx-react';
import { Box, List, ListItem, Grid, Typography } from '@material-ui/core';

export const LoanWarningMessages = observer(({ warnings, title }) => {
  return (
    <>
      <Grid item xs={12} lg={6}>
        <Box p={2}>
          <List>
            <ListItem>
              <Typography variant="h5">{title}</Typography>
            </ListItem>
            {warnings?.map(item => (
              <ListItem key={item}>{item}</ListItem>
            ))}
          </List>
        </Box>
      </Grid>
    </>
  );
});
