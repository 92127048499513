import { format as dateFnsFormat, format, isValid, parse, parseISO, differenceInCalendarDays } from 'date-fns';
import { isNil } from './utils';

export enum DateFormat {
  MMDDYYYY = 'MM/dd/yyyy'
}

export const formatDate = (date, format?: string) => {
  const dateObject = parseDate(date);
  if (dateObject && isValid(dateObject)) {
    return dateFnsFormat(dateObject, format ?? 'MMMM d, yyyy  hh:mm aaa');
  }
  return date;
};

export const formatDateAndIsoDate = (date, format?: string) => {
  try {
    const dateObject = parseDate(date);
    if (dateObject && isValid(dateObject)) {
      return `${dateObject.getMonth() + 1}/${dateObject.getDate()}/${dateObject.getFullYear()}`
    } else {
      const dateIso = new Date(date);
      const formattedDate = `${dateIso.getMonth() + 1}/${dateIso.getDate()}/${dateIso.getFullYear()}`;
      return formattedDate;
    }
  } catch (e) {
    return date;
  }
};

export const formatDateIgnoreTimezone = date => {
  if (!date) return;
  const dateObject = new Date(date);
  const [, year, month, day] = dateObject
    .toISOString()
    .match(/(\d+)-(\d+)-(\d+)/);
  return `${month}/${day}/${year}`;
};

export const parseMMDDYYYYDate = (date: string) => {
  const dateWithoutMilisseconds = date.replace(/\.\d+$/, '');
  const hasTime = date.includes(':');
  const format = hasTime ? 'MM/dd/yyyy HH:mm:ss' : 'MM/dd/yyyy';
  return parse(dateWithoutMilisseconds, format, new Date());
};

export const parseYYYYMMDDDate = (date: string) => {
  const dateWithoutMilisseconds = date.replace(/\.\d+$/, '');
  const hasTime = date.includes(':');
  const format = hasTime ? 'yyyy-MM-dd HH:mm:ss' : 'yyyy-MM-dd';
  return parse(dateWithoutMilisseconds, format, new Date());
};

const isISODate = (date: string) => /^\d{4}-\d{2}-\d{2}/.test(date);

export const parseDate = date => {
  if (isNil(date)) {
    return null;
  } else if (typeof date === 'string') {
    if (isISODate(date)) {
      return parseISO(date);
    } else {
      return parseMMDDYYYYDate(date);
    }
  } else {
    return new Date(date);
  }
};


export const checkIfFirstDateGreaterThanSecondDate = (firstDate: Date, secondDate: Date) => {
  if (firstDate.getFullYear() > secondDate.getFullYear()) {
    return true;
  } else if (firstDate.getFullYear() < secondDate.getFullYear()) {
    return false;
  } else {
    return validateMonths(firstDate, secondDate)
  }
};

const validateMonths = (firstDate: Date, secondDate: Date) => {
  if (firstDate.getMonth() > secondDate.getMonth()) {
    return true;
  } else if (firstDate.getMonth() < secondDate.getMonth()) {
    return false;
  } else {
    return validateDates(firstDate, secondDate);
  }
  return false;
};

const validateDates = (firstDate: Date, secondDate: Date) => {
  if (firstDate.getDate() > secondDate.getDate()) {
    return true;
  } else if (firstDate.getDate() <= secondDate.getDate()) {
    return false;
  }
};

export const formatStringToMMDDYYYYDate = (date: string) => {
  return date ? format(new Date(date), 'MM/dd/yyyy') : null;
}

export const getDaysBetween = (firstDate: Date, secondDate: Date) => {
  return differenceInCalendarDays(firstDate, secondDate);
};