import {
  Box,
  Grid,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemText,
  Divider
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { SelectField, SubmitButton, DialogState, TextField, TestDataAttribute, } from '@roc/ui';
import { observer } from 'mobx-react-lite';
import { useBrokersStore } from '../hooks/useBrokersStore';
import { ELMSURE, WIMBA, isNotBlank } from '@roc/feature-utils';
import { insuranceOptions, OTHER } from '@roc/feature-utils';
import CompanyForm from 'libs/feature-loans/src/loanSubmission/components/companyForm';
import { Add } from '@material-ui/icons';
import { useEffect } from 'react';
import { sanitizeTestId } from '@roc/feature-utils';
import {
  CellPhone,
  EmailAddress,
  Address,
} from '@roc/ui/formComponents';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(2),
  },
  tableContainer: {},
  table: {
    '& td': {
      padding: theme.spacing(2, 4),
    },
    '& tr td:first-child': {
      width: 'auto',
      padding: theme.spacing(0, 4),
    },
  },
  row: {
    borderTop: '1px solid #BEBEBE',
  },
  bold: {
    fontWeight: 'bold',
  },
  fieldCell: {
    padding: theme.spacing(0, 1),
  },
  totalRow: {
    fontWeight: 'bold',
    backgroundColor: '#FAFAFA',
    '& tr td:nth-child(1), & tr td:nth-child(3)': {
      textAlign: 'center',
    },
  },
  headerRow: {
    backgroundColor: '#EEE',
  },
  rateFieldInput: {
    '& input': {
      padding: theme.spacing(1),
    },
  },
  button: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  orderFormCard: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(0),
    },
    width:'700px'
  },
}));

interface BrokerVendorClosingSettingsProps {
  preferredTitleOptions: any;
}

export const BrokerVendorClosingSettings = observer(({preferredTitleOptions}: BrokerVendorClosingSettingsProps) => {
  const classes = useStyles();
  const { brokersStore } = useBrokersStore();
  const { brokerFeesStore, manageBrokersStore } = brokersStore;
  const { brokerFees } = brokerFeesStore;
  const { selectBrokersStore } = brokersStore;
  const { editDialogState } = selectBrokersStore;
  const brokerFields = brokersStore.manageBrokerFormStore.form.fields;
  const brokerId = brokerFields.brokerId.value;

  const setOtherInsuranceCompanyData = () => {
    if (manageBrokersStore.brokerVendorClosingSettings.preferredInsuranceCompany === ELMSURE){
      brokersStore.brokerVendorClosingSettingsFormStore.form.fields.preferredInsuranceCompany.value = manageBrokersStore.brokerVendorClosingSettings.preferredInsuranceCompany;
    } else if (manageBrokersStore.brokerVendorClosingSettings.preferredInsuranceCompany === null){
      brokersStore.brokerVendorClosingSettingsFormStore.form.fields.preferredInsuranceCompany.value = '';
    }else{
      brokersStore.brokerVendorClosingSettingsFormStore.form.fields.preferredInsuranceCompany.value = OTHER;
      brokersStore.otherInsuranceFormStore.loadForm(JSON.parse(manageBrokersStore.brokerVendorClosingSettings.preferredInsuranceCompany))
    }
  }

  const setOtherTitleCompanyData = () => {
    if (manageBrokersStore.brokerVendorClosingSettings.preferredTitleCompany === WIMBA){
      brokersStore.brokerVendorClosingSettingsFormStore.form.fields.preferredTitleCompany.value = manageBrokersStore.brokerVendorClosingSettings.preferredTitleCompany;
    } else if (manageBrokersStore.brokerVendorClosingSettings.preferredTitleCompany === null){
      brokersStore.brokerVendorClosingSettingsFormStore.form.fields.preferredTitleCompany.value = '';
    }else{
      brokersStore.brokerVendorClosingSettingsFormStore.form.fields.preferredTitleCompany.value = OTHER;
      brokersStore.otherTitleFormStore.loadForm(JSON.parse(manageBrokersStore.brokerVendorClosingSettings.preferredTitleCompany))
    }
  }

  const setAttorneyData = () => {
    brokersStore.brokerVendorClosingSettingsFormStore.form.fields.preferredClosingAttorney.value = manageBrokersStore.brokerVendorClosingSettings.preferredClosingAttorney;
  }

  const setBrokerVendorSettingsId = () => {
    if (manageBrokersStore.brokerVendorClosingSettings.preferredClosingAttorney != null) {
      brokersStore.brokerVendorClosingSettingsFormStore.form.fields.brokerVendorClosingSettingsId.value = manageBrokersStore.brokerVendorClosingSettings.brokerVendorClosingSettingsId;
    }
  }


  useEffect(() => {
    const fetchData = async () => {
      if (editDialogState !== DialogState.ADD) {
        brokersStore.brokerVendorClosingSettingsFormStore.reset();
        await manageBrokersStore.getBrokerVendorClosingSettingsByBrokerId(brokerId);
        setBrokerVendorSettingsId();
        setAttorneyData();
        setOtherInsuranceCompanyData();
        setOtherTitleCompanyData();
      }
    };

    if (editDialogState !== DialogState.ADD) {
      fetchData();
    }
  }, [brokerId]);
  const listItem = (label, value, icon = null, customTestId = null) => {
    const testId = sanitizeTestId(customTestId || label);
    return (
      <ListItem>
        <ListItemText>
          <Box display="flex">
            {icon && <Box ml={1}>{icon}</Box>}
            <Box flexGrow={1}>{label}</Box>
            <TestDataAttribute id={testId}>
              <Box>{value}</Box>
            </TestDataAttribute>
          </Box>
        </ListItemText>
      </ListItem>
    );
  };
  const divider = <Divider variant="middle" component="li" />;
  return (
    <Grid container spacing={10}>
      <Grid item xs={12} md={5}>
        <Grid item xs={12}>
          <SelectField
            standaloneLabel
            label={'Preferred Closing Attorney'}
            name={'preferredClosingAttorney'}
            value={brokersStore.brokerVendorClosingSettingsFormStore.form.fields.preferredClosingAttorney.value}
            errorText={brokersStore.brokerVendorClosingSettingsFormStore.form.fields.preferredClosingAttorney.error}
            error={isNotBlank(brokersStore.brokerVendorClosingSettingsFormStore.form.fields.preferredClosingAttorney.error)}
            options={manageBrokersStore.lenderAttorneysOptionList}
            onChange={value => brokersStore.brokerVendorClosingSettingsFormStore.onFieldChange('preferredClosingAttorney', value)}
            variant="outlined"
            fullWidth
            shrink
            required
            testId="preferredClosingAttorney"
          />
          <SelectField
            standaloneLabel
            label={'Preferred Insurance Company'}
            name={'preferredInsurance'}
            value={brokersStore.brokerVendorClosingSettingsFormStore.form.fields.preferredInsuranceCompany.value}
            errorText={brokersStore.brokerVendorClosingSettingsFormStore.form.fields.preferredInsuranceCompany.error}
            error={isNotBlank(brokersStore.brokerVendorClosingSettingsFormStore.form.fields.preferredInsuranceCompany.error)}
            options={insuranceOptions}
            onChange={value => brokersStore.brokerVendorClosingSettingsFormStore.onFieldChange('preferredInsuranceCompany', value)}
            variant="outlined"
            fullWidth
            shrink
            required
            testId="preferredInsurance"
          />
          {brokersStore.brokerVendorClosingSettingsFormStore.form.fields.preferredInsuranceCompany.value === OTHER && (
            <Paper className={classes.orderFormCard}>
              <Box p={2}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      testId="name"
                      variant="outlined"
                      label="Name"
                      type="text"
                      value={brokersStore.otherInsuranceFormStore.form.fields.name.value}
                      onChange={e => brokersStore.otherInsuranceFormStore.onFieldChange('name', e.target.value)}
                      helperText={brokersStore.otherInsuranceFormStore.form.fields.name.error}
                      error={isNotBlank(brokersStore.otherInsuranceFormStore.form.fields.name.error)}
                      standaloneLabel
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      testId="contactName"
                      variant="outlined"
                      label="Contact Name"
                      type="text"
                      value={brokersStore.otherInsuranceFormStore.form.fields.contactName.value}
                      onChange={e => brokersStore.otherInsuranceFormStore.onFieldChange('contactName', e.target.value)}
                      helperText={brokersStore.otherInsuranceFormStore.form.fields.contactName.error}
                      error={isNotBlank(brokersStore.otherInsuranceFormStore.form.fields.contactName.error)}
                      standaloneLabel
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <CellPhone store={brokersStore.otherInsuranceFormStore} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Address store={brokersStore.otherInsuranceFormStore} name={"addressInsurance"} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <EmailAddress store={brokersStore.otherInsuranceFormStore} />
                  </Grid>
                </Grid>
              </Box>
            </Paper>

          )}
          <SelectField
            standaloneLabel
            label={'Preferred Title Company'}
            name={'preferredTitle'}
            value={brokersStore.brokerVendorClosingSettingsFormStore.form.fields.preferredTitleCompany.value}
            errorText={brokersStore.brokerVendorClosingSettingsFormStore.form.fields.preferredTitleCompany.error}
            error={isNotBlank(brokersStore.brokerVendorClosingSettingsFormStore.form.fields.preferredTitleCompany.error)}
            options={preferredTitleOptions}
            onChange={value => brokersStore.brokerVendorClosingSettingsFormStore.onFieldChange('preferredTitleCompany', value)}
            variant="outlined"
            fullWidth
            shrink
            testId="preferredTitle"
          />
          {brokersStore.brokerVendorClosingSettingsFormStore.form.fields.preferredTitleCompany.value === OTHER && (
            <Paper className={classes.orderFormCard}>
              <Box p={2}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      testId="name"
                      variant="outlined"
                      label="Name"
                      type="text"
                      value={brokersStore.otherTitleFormStore.form.fields.name.value}
                      onChange={e => brokersStore.otherTitleFormStore.onFieldChange('name', e.target.value)}
                      helperText={brokersStore.otherTitleFormStore.form.fields.name.error}
                      error={isNotBlank(brokersStore.otherTitleFormStore.form.fields.name.error)}
                      standaloneLabel
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      testId="contactName"
                      variant="outlined"
                      label="Contact Name"
                      type="text"
                      value={brokersStore.otherTitleFormStore.form.fields.contactName.value}
                      onChange={e => brokersStore.otherTitleFormStore.onFieldChange('contactName', e.target.value)}
                      helperText={brokersStore.otherTitleFormStore.form.fields.contactName.error}
                      error={isNotBlank(brokersStore.otherTitleFormStore.form.fields.contactName.error)}
                      standaloneLabel
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <CellPhone store={brokersStore.otherTitleFormStore} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Address store={brokersStore.otherTitleFormStore} name={"addressTitle"} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <EmailAddress store={brokersStore.otherTitleFormStore} />
                  </Grid>
                </Grid>
              </Box>
            </Paper>

          )}
        </Grid>
      </Grid>
    </Grid>

  );

})
