import { useBaseStore, useUserStore } from '@roc/feature-app-core';
import {
  getLoanSubmissionRoutes,
  getLoanSubmissionRoutesConfig
} from './../routes/loanSubmissionRoutes';

export const useLoanSubmissionRoutes = () => {
  const { globalStore } = useBaseStore();
  const { userStore } = useUserStore();
  const { routeBasePath, routeBaseUrl } = globalStore;

  return {
    loanSubmissionRoutes: getLoanSubmissionRoutes(routeBasePath, routeBaseUrl, userStore.allowLoanPricing),
    loanSubmissionRoutesConfig: getLoanSubmissionRoutesConfig(
      routeBasePath,
      routeBaseUrl
    ),
  };
};
