import { Grid } from '@material-ui/core';
import { Tabs } from '@roc/ui';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { DocumentStatus } from '../../../constants';
import { useDocumentStore } from '../../../hooks/useDocumentStore';
import { ExceptionsForm } from './components/exceptionsForm';
import { FloodZoneForm } from './components/floodZoneForm';
import { InsuranceApprovalForm } from './components/insuranceApprovalForm/insuranceApprovalForm';
import { InsuranceProviderForm } from './components/insuranceProviderForm';
import { insertIf } from '@roc/feature-utils';
import { InsuranceReview } from '@roc/feature-insurance-review';
import { useInsuranceReviewStore } from '@roc/feature-insurance-review';
import { useStore } from '@roc/client-portal-shared/hooks';

export const InsuranceApproval = observer(() => {
  const { documentStore } = useDocumentStore();
  const { currentDocument, documentFormStore } = documentStore;
  const { insuranceApprovalStore } = documentFormStore;
  const { insuranceReviewStore } = useInsuranceReviewStore();
  const { globalStore } = useStore();

  const isInternalInsuranceReviewer = insuranceApprovalStore.loanStore.loanDetails.insuranceReviewerId == 4 && globalStore.userFeatures.isInsuranceReviewer

  const TABS = [
    {
      label: 'Approval Form',
    },
    {
      label: 'Flood Zone Check',
    },
    {
      label: 'Exceptions',
    },
    {
      label: 'Insurance Details',
    },
    ...insertIf(isInternalInsuranceReviewer, [{
      label: 'Insurance Review',
    }]),
  ];

  const [selectedTab, setSelectedTab] = useState(0);

  const isDocumentAccepted = currentDocument.status === DocumentStatus.ACCEPTED;

  const onTabChange = (e, index) => setSelectedTab(index);

  const closeCurrentForm = () => {
    documentFormStore.closeCurrentForm();
  };

  const onConfirmFloodZone = () => {
    setSelectedTab(0);
  };

  const onSaveExceptions = () => {
    setSelectedTab(0);
  };

  const onSaveInsuranceProvider = () => {
    setSelectedTab(0);
  };

  useEffect(() => {
    insuranceApprovalStore.initialize();
  }, [insuranceApprovalStore]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Tabs tabs={TABS} selectedTab={selectedTab} onTabChange={onTabChange} />
      </Grid>
      <Grid item xs={12}>
        {selectedTab === 0 && (
          <InsuranceApprovalForm onClose={closeCurrentForm} />
        )}
        {selectedTab === 1 && (
          <FloodZoneForm
            onClose={closeCurrentForm}
            onConfirm={onConfirmFloodZone}
          />
        )}
        {selectedTab === 2 && (
          <ExceptionsForm
            disabled={isDocumentAccepted}
            onClose={closeCurrentForm}
            onSave={onSaveExceptions}
          />
        )}
        {selectedTab === 3 && (
          <InsuranceProviderForm
            disabled={isDocumentAccepted}
            onClose={closeCurrentForm}
            onSave={onSaveInsuranceProvider}
          />
        )}
        {selectedTab === 4 && (
          <InsuranceReview
            store={insuranceReviewStore}
            loanId={insuranceApprovalStore.loanStore.loanDetails.loanId}
            loanStatus={insuranceApprovalStore.loanStore.loanDetails.status}
          />
        )}
      </Grid>
    </Grid>
  );
});

export default InsuranceApproval;
