import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      fontSize: '1rem',
      fontWeight: 600,
      '&.active': {
        color: theme.palette.primary.main,
      },
    },
  })
);

interface Props {
  active: boolean;
  children: React.ReactNode;
}

export const StepTitle: React.FC<Props> = props => {
  const classes = useStyles();

  return (
    <span className={`${classes.root} ${props.active ? 'active' : ''}`}>
      {props.children}
    </span>
  );
};