import FlagIcon from '@material-ui/icons/Flag';
import InfoIcon from '@material-ui/icons/Info';
import IconButton from '@material-ui/core/IconButton';
import { isNil } from '@roc/feature-utils';

export const ARREST_RECORD = 'arrestRecord';
export const BANKRUPTCY_RECORD = 'bankruptcyRecord';
export const CRIMINAL_RECORD = 'criminalRecord';
export const DOCKET_RECORD = 'docketRecord';
export const FORECLOSURE_RECORD = 'foreclosureRecord';
export const LAWSUIT_RECORD = 'lawsuitRecord';
export const LIEN_JUDGEMENT_RECORD = 'lienJudgmentRecord';
export const OTHER_SSN_RECORD = 'otherSSNAssociated';
export const SSN_ADDRESS_FRAUD_RECORD = "ssnAddressFraudRecord";
export const EXCLUDED_PARTY_RECORD = "excludedPartyRecord";
export const OTHER_NAMES_FOR_SSN_RECORD = "otherNamesRecord";
export const GLOBAL_SANCTION_RECORD = 'globalSanction';
export const QUICK_ANALYSIS_FLAGS = 'quickAnalysisFlags';
export const QUICK_CHECKS = 'quickChecks';
export const ESCALATED_KEYWORDS_FLAGS = 'escalatedKeywordFlags';
export const TRADELINE_SUMMARY_RECORD = 'tradelineSummary';
export const TRADELINE_DATA_RECORD = 'tradelinesData';
export const MORTGAGE_ACCOUNTS_RECORD = 'mortgageLateTradelines';
export const OTHER_ACCOUNTS_RECORD = 'otherLateTradelines';
export const MORTGAGE_HISTORY_RECORD = 'mortgageHistory';
export const COLLECTION_ACCOUNTS_RECORD = 'collectionTradelines';
export const CHARGE_OFF_ACCOUNTS_RECORD = 'chargeOffTradelines';
export const PUBLIC_RECORD = 'publicRecords';

export const BORROWER_REVIEW_TYPES = {
  BORROWER_BACKGROUND_REVIEW: 'Borrower Background Review',
  BORROWER_CREDIT_REVIEW: 'Borrower Credit Review',
  BORROWER_TRACK_RECORD_REVIEW: 'Borrower Track Record Review'
};

export const getResolutionFlag = (flag: any) => {
  if (isNil(flag.loefFlag)) {
    return (<FlagIcon htmlColor='#757575' />);
  }
  else if (flag.loefFlag === true) {
    return (<FlagIcon htmlColor='#E9843D' />);
  }
  else {
    return (<FlagIcon htmlColor='#35b064' />);
  }
};

export const getResolutionFlagQuickChecks = (flag: any) => {
  if (isNil(flag.flagCheck)) {
    return (<FlagIcon htmlColor='#757575' />);
  }
  else if (flag.flagCheck === true) {
    return (<FlagIcon htmlColor='#E9843D' />);
  }
  else {
    return (<FlagIcon htmlColor='#35b064' />);
  }
};

export const EVENT_ACTION = {
  ITEM_ADDED: 'ITEM ADDED',
  ITEM_REMOVED: 'ITEM REMOVED',
  ITEM_UPDATED: 'ITEM UPDATED',
  ITEM_FLAGGED: 'ITEM FLAGGED',
  ITEAM_UNFLAGGED: 'ITEM UNFLAGGED'
}

export const getInfoButton = (flag, callback) => {
  if (flag && flag.loefFlag === false) {
    return (
      <IconButton onClick={callback}>
        <InfoIcon style={{ color: '#2199E0' }} />
      </IconButton>
    )
  }
  return null;
}

export const getInfoButtonQuickChecks = (flag, callback) => {
  if (flag && flag.flagCheck === false) {
    return (
      <IconButton onClick={callback}>
        <InfoIcon style={{ color: '#2199E0' }} />
      </IconButton>
    )
  }
  return null;
}
