import { DashboardRounded } from '@material-ui/icons';
import { Page, useBaseStore } from '@roc/feature-app-core';
import {
  BorrowerDrawDetailsModal,
  BorrowerDrawRequest,
  BorrowerLoanDrawsDashboardV2,
  BorrowerReviseScopeOfWork
} from '@roc/feature-borrower-draws';
import { BorrowerTodos } from '@roc/feature-borrower-todos';
import { ConversationsDialog, SelectConversation, useCommunicationRoutesConfig, useLoanCommunicationStore } from '@roc/feature-communication';
import {
  ExtensionDetailsModal,
  ExtensionsDashboardV2,
  PayoffDashboardV2,
  useLoanStore
} from '@roc/feature-loans';
import { useLoanRoutes } from '@roc/feature-loans-routes-config';
import { LoanType, getExtensionId } from '@roc/feature-utils';
import { observer } from 'mobx-react';
import { PropsWithChildren, useEffect } from 'react';
import {
  useLocation,
} from 'react-router';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useParams,
} from 'react-router-dom';
import { useBorrowerLoansRoutes } from '../../../hooks/useBorrowerLoansRoutes';
import { useBorrowerLoansStore } from '../../../hooks/useBorrowerLoansStore';
import { BorrowerLoanOverviewBridgeLoans } from '../overview/borrowerLoanOverviewBridgeLoans';
import { BorrowerLoanOverviewTermLoans } from '../overview/borrowerLoanOverviewTermLoans';
import { Theme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    '& .MuiDialog-paper': {
      maxWidth: 1000,
    },
  },
}));

export const LoanOverview = observer(({ loanDetails }) => {
  const { globalStore } = useBaseStore();
  const { breadCrumbsStore } = globalStore;
  const { loanId } = useParams<{ loanId: string }>();
  const {
    borrowerLoanRoutesConfig: loanRoutesConfig,
  } = useBorrowerLoansRoutes();

  useEffect(() => {
    breadCrumbsStore.setBreadCrumbItems([
      {
        link: true,
        title: 'Dashboard',
        url: globalStore.routeBaseUrl,
        icon: <DashboardRounded />,
      },
      {
        link: true,
        title: `Loan# ${loanId}`,
        url: loanRoutesConfig.loans(loanId).url,
      },
      {
        link: false,
        title: 'Loan Summary',
      },
    ]);
  });

  switch (loanDetails?.loanType) {
    case LoanType.RESIDENTIAL_BRIDGE:
      return <BorrowerLoanOverviewBridgeLoans />;
    case LoanType.RESIDENTIAL_TERM:
      return <BorrowerLoanOverviewTermLoans />;
  }
});

function ConversationsDialogWrapper({ loanDetails, children }: PropsWithChildren<{ loanDetails: any }>) {
  return <>
    <LoanOverview loanDetails={loanDetails} />
    {children}
  </>;
}


export const BorrowerLoanDetailsChildRoutes = observer(({ isNew = false }) => {
  const { loanId, drawId } = useParams<{ loanId: string; drawId: string }>();
  const { push, replace } = useHistory();
  const { borrowerLoansStore } = useBorrowerLoansStore();
  const { loanDetails } = borrowerLoansStore;
  const { loanStore } = useLoanStore();
  const { loanRoutesConfig } = useLoanRoutes();
  const loanDetailsChildRoutes = loanRoutesConfig.loans(loanId).children;
  const { communicationRoutesConfig } = useCommunicationRoutesConfig();
  const { loanCommunicationStore } = useLoanCommunicationStore();
  const { pathname, state } = useLocation<{ from?: string }>();
  const classes = useStyles();

  function handleCloseConversationsDialog() {
    push(state?.from || loanRoutesConfig.loans(loanId).children.dashboard.url);
    loanCommunicationStore.fetchUnreadMessagesCount(loanId);
  }
  function handleConversationSelect(conversationId: string) {
    const path = communicationRoutesConfig.conversations(loanId).children.conversation(conversationId).url;
    try {
      replace(path, state);
    } catch (e) {
      push(path, { from: loanDetailsChildRoutes.dashboard.url });
    }
  }

  const renderPayoffScreen = (servicer) => {
    const showPayoffModule = ['SLS', 'Keybank', 'Fay Servicing', 'Planet Home Lending', 'Planet Portfolio'].includes(servicer);
    return showPayoffModule ? <PayoffDashboardV2 loanId={loanDetails.loanId} /> : (null);
  };

  const renderExtensionDetails = () => {
    const extensionId = getExtensionId(pathname);
    return (
      loanDetails?.showExtensions ?
        <Route exact path={loanRoutesConfig.loans(loanId).children.extensionDetails(extensionId).path}>
          <Page routeDefinition={loanRoutesConfig.loans(loanId).children.extensionDetails(extensionId)}>
            <ExtensionDetailsModal loanId={loanId} extensionId={extensionId} />
          </Page>
        </Route> : null
    );
  };

  if (!loanDetails) {
    return null;
  }

  const borrowerTodosRoute = (
    <Route exact path={loanDetailsChildRoutes.borrowerTodos.path}>
      <Page routeDefinition={loanDetailsChildRoutes.borrowerTodos}>
        <BorrowerTodos loanId={loanId} />
      </Page>
    </Route>
  );

  return (
    <Switch>
      <Route exact path={`${loanRoutesConfig.loans(loanId).path}/`}
        render={() => <Redirect to={loanDetailsChildRoutes.dashboard.url} />}
      />
      <Route exact path={loanDetailsChildRoutes.dashboard.path}>
        <Page routeDefinition={loanDetailsChildRoutes.dashboard}>
          <LoanOverview loanDetails={loanDetails} />
        </Page>
      </Route>
      {
        loanDetails?.toDosFrameworkEnabled && borrowerTodosRoute
      }
      {
        /* <Route exact path={loanDetailsChildRoutes.borrowerDocuments.path}>
          <Page routeDefinition={loanDetailsChildRoutes.borrowerDocuments}>
            <BorrowerDocumentsDashboard loanId={loanId} />
          </Page>
        </Route> */
      }
      {
        loanDetails?.showDraws &&
        <Route path={loanDetailsChildRoutes.draws.path}>
          <Page routeDefinition={loanDetailsChildRoutes.draws}>
            <BorrowerLoanDrawsDashboardV2 loanId={loanId} />
            {
              drawId &&
              <Route exact path={loanDetailsChildRoutes.drawDetails(drawId).path}>
                <Page routeDefinition={loanDetailsChildRoutes.drawDetails(drawId)}>
                  <BorrowerDrawDetailsModal loanId={loanId} drawId={drawId} />
                </Page>
              </Route>
            }
          </Page>
        </Route>
      }
      {/* {
        loanDetails?.showDraws && drawId &&
        <Route exact path={loanDetailsChildRoutes.drawDetails(drawId).path}>
          <Page routeDefinition={loanDetailsChildRoutes.drawDetails(drawId)}>
            <BorrowerDrawDetailsV2 loanId={loanId} drawId={drawId} />
          </Page>
        </Route>
      } */}
      {
        loanDetails?.showDraws &&
        <Route exact path={loanDetailsChildRoutes.drawRequest.path}>
          <Page routeDefinition={loanDetailsChildRoutes.drawRequest}>
            <BorrowerDrawRequest />
          </Page>
        </Route>
      }
      {
        loanDetails?.showDraws &&
        <Route exact path={loanDetailsChildRoutes.reviseScopeOfWork.path}>
          <Page routeDefinition={loanDetailsChildRoutes.reviseScopeOfWork}>
            <BorrowerReviseScopeOfWork loanId={loanId} />
          </Page>
        </Route>
      }
      {
        loanDetails?.showExtensions &&
        <Route path={loanRoutesConfig.loans(loanId).children.extensions.path}>
          <Page routeDefinition={loanRoutesConfig.loans(loanId).children.extensions}>
            <ExtensionsDashboardV2 loanId={loanId} />
            {renderExtensionDetails()}
          </Page>
        </Route>
      }
      {
        loanDetails?.showPayoffs &&
        <Route exact path={loanRoutesConfig.loans(loanId).children.payoffRequest.path}>
          <Page routeDefinition={loanRoutesConfig.loans(loanId).children.payoffRequest}>
            {renderPayoffScreen(loanStore.loanDetails.servicer)}
          </Page>
        </Route>
      }
      <Route path={communicationRoutesConfig.conversations(loanId).path}>
        <Page routeDefinition={communicationRoutesConfig.conversations(loanId)}>
          <ConversationsDialogWrapper loanDetails={loanDetails}>
            <ConversationsDialog
              open={true}
              loanId={Number(loanId)}
              onClose={handleCloseConversationsDialog}
              communicationStore={loanCommunicationStore}
              onConversationSelect={handleConversationSelect}
              hideSidebar={!loanDetails?.showDraws}
              allowManageParticipants={true}
              allowNewConversations={false}
              allowAddParticipants={false}
              allowAddInternalParticipants={false}
              allowRemoveParticipants={false}
              allowJoinConversation={false}
              showSnippet={false}
              showEmailAddress={false}
              dialogClass={classes.dialog}
              routePath={communicationRoutesConfig.conversations(loanId).children.conversation().path}
            />
          </ConversationsDialogWrapper>
        </Page>
      </Route>
    </Switch>
  );
});
