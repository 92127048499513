import { darken } from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { observer } from 'mobx-react';

import { Card, Paper, GoogleMap, TestDataAttribute } from '@roc/ui';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { formatCurrency } from '@roc/ui/utils';
import { SinglePropertyStore, RentalPortfolioStore } from './../stores';
import WimbaModal from './wimbaModal';
import { Roles, WIMBA } from '@roc/feature-utils';
import { Alert, AlertTitle } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { useUserStore } from '@roc/feature-app-core';
import { useEffect } from 'react';
import { LoanSubmissionStep } from '../utils/constants';
import { PricerEconomicsSummaryTable } from '../../rentalPricer/pricerSummary/components/pricerEconomicsSummaryTable';
import { useLoanCommissionStore } from 'libs/feature-loan-summary/src/loanSummary/hooks/useCommissionStore';
import { WarningsAlert } from './alerts';
import { useBrokersStore } from '@roc/feature-brokers';

export interface LoanSummaryProps {
  store: SinglePropertyStore | RentalPortfolioStore;
}

const showPercentage = value => {
  return value && value ? formatPercentage(value, 3) : 'N/A';
};

export const formatPercentage = (value, digits) =>
  checkIfNullOrUndefined(value, '') === ''
    ? ''
    : new Intl.NumberFormat('en-US', {
      style: 'percent',
      maximumFractionDigits: digits || 2,
    }).format(value / 100);

export const checkIfNullOrUndefined = (field, defaultValue) => {
  const updatedField =
    field === null || field === undefined ? defaultValue : field;
  return updatedField;
};

const listItem = (label, value, testId = null) => {
  return (
    <ListItem>
      <ListItemText>
        <Box display="flex">
          <Box flexGrow={1}>{label}</Box>
          <TestDataAttribute id={testId}>
            <Typography variant="body1">
              <strong>{value}</strong>
            </Typography>
          </TestDataAttribute>
        </Box>
      </ListItemText>
    </ListItem>
  );
};

const renderHeading = value => {
  return (
    <Typography
      variant="body1"
    //className={isSelectedRateValue && classes.selectedRate}
    >
      <strong>{value}</strong>
    </Typography>
  );
};

const divider = <Divider variant="middle" component="li" />;

const useStyles = makeStyles(theme =>
  createStyles({
    card: {
      background: theme.palette.background.default,
      borderColor: darken(theme.palette.background.default, 0.1),
    },
    mapStyles: {
      position: `relative`,
      width: `100%`,
      height: `150px`,
    },
    cardSpacing: {
      paddingRight: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  })
);

function LoanSummary({ store }: LoanSummaryProps) {
  const classes = useStyles();
  const { pricerSummaryStore, getNumberOfProperties, getPrepaymentPenaltyDescription, goPrevStep, allErrors, globalStore, allWarnings } = store;
  const {
    spreadRate,
    amount,
    amortizationLabel,
    prePaymentPenalty,
    loanToValue,
  } = pricerSummaryStore.getPricerLoanTerms();
  const {
    oneTimeYieldSpreadPremium,
    rateType,
  } = pricerSummaryStore.form.fields;

  const { entity, selectBorrowersStore, loanDetailsStore } = store;
  const { borrowers } = selectBorrowersStore;
  const {
    preferredClosingAttorney,
    brokerPointsIn,
    preferredTitle,
    wimbaAcknowledged,
    referralFee
  } = loanDetailsStore.form.fields;
  const {
    rocPointsIn,
    sfrCalculatedLenderPointsIn,
    lenderAttorneysOptionList,
  } = loanDetailsStore;
  const { isSingleProperty } = store.pricerStore;
  const { totalEstimatedAsIsValue } = store.pricerStore.form.fields;
  const { userStore } = useUserStore();
  const allowLoanPricing = userStore.allowLoanPricing;
  const isInternal = globalStore.lenderInfo?.isInternal;
  const isBackOfficeProcessor = userStore.userInformation?.roles?.includes(Roles.BACK_OFFICE_PROCESSOR);
  const isBackOfficeUser = userStore.isBackOfficeUser;
  const { lenderFeesFormStore } = pricerSummaryStore;
  const { originationPointsRetail } = lenderFeesFormStore.form.fields;
  const { loanCommissionStore } = useLoanCommissionStore();
  const { brokersStore } = useBrokersStore();
  let totalPoints = !isNaN(Number(rocPointsIn)) ? Number(rocPointsIn) : 0;
  totalPoints += !isNaN(Number(sfrCalculatedLenderPointsIn))
    ? Number(sfrCalculatedLenderPointsIn)
    : 0;
  totalPoints += !isNaN(Number(brokerPointsIn.value))
    ? Number(brokerPointsIn.value)
    : 0;
  if (isInternal) {
    totalPoints += !isNaN(Number(originationPointsRetail.value))
      ? Number(originationPointsRetail.value)
      : 0;
  }

  const preferredClosingAttorneyName = lenderAttorneysOptionList?.find(
    o => o.value == preferredClosingAttorney.value
  )?.label;

  const closeModal = () => {
    if (!wimbaAcknowledged.value) {
      goPrevStep(LoanSubmissionStep.SUMMARY);
    }
  };

  const confirmModal = () => {
    loanDetailsStore.onChange('wimbaAcknowledged', true);
  };

  const brokerLabel = !isInternal ? 'Broker' : brokersStore.onTheHud ? 'On Hud Broker' : 'Off Hud Broker';
  const brokerPoints = !isInternal || brokersStore.onTheHud ? brokerPointsIn.value : referralFee?.value;



  useEffect(() => {
    store.validateBorrowerErrors();
    store.validateEntityErrors();
    store.getAreBorrowersPreQualifiedFromPrescreenValidationsForTermLoans();
    store.validateProtectedBorrowers();
  }, []);

  const commissionData = loanCommissionStore?.commissionData || pricerSummaryStore.selectedPricingOption?.commissions || {};

  const getCompleteAddress = (property) => `${property?.streetNumber?.value ? property?.streetNumber?.value + ' ' : ''}${property?.streetName?.value}${property?.aptNumber.value ? ' ' + property?.aptNumber.value : ''}, ${property?.city?.value}, ${property?.state?.value} ${property?.zipCode?.value}`;

  return (
    <>
      {
        allErrors.length > 0 && (
          <>
            <Grid xs={12}>
              <Alert severity="error">
                <AlertTitle>ERRORS</AlertTitle>
                {allErrors.map(error => (
                  <Grid>{error}</Grid>
                ))}
              </Alert>
            </Grid>
            <br />
          </>
        )
      }

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4">Loan Summary</Typography>
          <br />
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}></Grid>
        </Grid>
      </Grid>
      <br />
      <br />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography color="primary" variant="h6">
            ENTITY
          </Typography>
          <Grid item xs={12} md={6} lg={4} className={classes.cardSpacing}>
            <TestDataAttribute id='entity'>
              <Card
                color="secondary"
                title={entity.name}
                value={entity}
                className={classes.card}
              >
                <div>
                  Ein:&nbsp;
                  <strong>{entity.ein}</strong>
                  <br />
                  Type:&nbsp;
                  <strong>{entity.type}</strong>
                </div>
              </Card>
            </TestDataAttribute>
          </Grid>
        </Grid>
        <br />
        <br />

        <Grid item xs={12}>
          <Typography color="primary" variant="h6">
            BORROWERS
          </Typography>
          {borrowers.length > 0 && (
            <Grid container spacing={2}>
              {borrowers.map(borrower => (
                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={4}
                  className={classes.cardSpacing}
                  key={borrower.borrowerId}
                >
                  <TestDataAttribute id='borrower'>
                    <Card
                      color="secondary"
                      title={`${borrower.firstName} ${borrower.lastName}`}
                      value={borrower}
                      className={classes.card}
                    >
                      <div>
                        Credit Guarantor:&nbsp;
                        <strong>
                          {borrower.personalGuarantor ? 'Yes' : 'No'}
                        </strong>
                        <br />
                        Percentage of Ownership:&nbsp;
                        <strong>{borrower.pctOwnership}</strong>
                      </div>
                    </Card>
                  </TestDataAttribute>
                </Grid>
              ))}
            </Grid>
          )}
        </Grid>
      </Grid>

      <br />
      <br />

      {isSingleProperty && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography color="primary" variant="h6">
              PROPERTY
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            lg={4}
            className={classes.cardSpacing}
            key={getCompleteAddress(store.pricerStore.form.fields)}
          >
            <TestDataAttribute id='property'>
              <Card
                color="secondary"
                title={getCompleteAddress(store.pricerStore.form.fields)}
                value={location}
                className={classes.card}
              >
                {store.pricerStore.form.fields.latitude.value &&
                  store.pricerStore.form.fields.longitude.value ? (
                  <GoogleMap
                    mapStyles={classes.mapStyles}
                    latitude={store.pricerStore.form.fields.latitude.value}
                    longitude={store.pricerStore.form.fields.longitude.value}
                  />
                ) : null}
              </Card>
            </TestDataAttribute>
          </Grid>
        </Grid>
      )}

      {!isSingleProperty && (
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={6}>
            <Typography color="primary" variant="h6">
              PORTFOLIO
            </Typography>
            <List>
              {listItem('Number of Properties', getNumberOfProperties())}
              {divider}
              {listItem(
                'Total Estimated As Is Value',
                formatCurrency(totalEstimatedAsIsValue?.value)
              )}
            </List>
          </Grid>
        </Grid>
      )}

      <br />
      <br />

      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={6}>
          <Typography color="primary" variant="h6">
            LOAN DETAILS
          </Typography>
          <List>
            {listItem('Total Loan Amount', formatCurrency(amount), 'total_loan_amount')}
            {divider}
            {listItem('Rate Type', rateType.value, 'rate_type')}
            {divider}
            {allowLoanPricing && listItem(
              'Preferred Closing Attorney',
              preferredClosingAttorneyName,
              'preferred_closing_attorney'
            )}
            {divider}
            {listItem('Amortization', amortizationLabel, 'amortization')}
            {divider}
            {listItem(
              'Pre-Payment Penalty',
              getPrepaymentPenaltyDescription(),
              'pre-payment-penalty'
            )}
            {divider}
            {listItem('LTV', showPercentage(loanToValue), 'ltv')}
          </List>
          <br />
          <br />
          {allowLoanPricing &&
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>{renderHeading('')}</TableCell>
                    <TableCell>{renderHeading('Borrower')}</TableCell>
                    <TableCell>{renderHeading('Capital Provider')}</TableCell>
                    <TableCell>{renderHeading('Lender')}</TableCell>
                    <TableCell>{renderHeading(brokerLabel)}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>{renderHeading('Spread Rate')}</TableCell>
                    <TestDataAttribute id='interest_rate_borrower'>
                      <TableCell>{showPercentage(spreadRate)}</TableCell>
                    </TestDataAttribute>
                    <TestDataAttribute id='interest_rate_roc'>
                      <TableCell>{showPercentage(spreadRate)}</TableCell>
                    </TestDataAttribute>
                    <TestDataAttribute id='interest_rate_lender"'>
                      <TableCell>{showPercentage('')}</TableCell>
                    </TestDataAttribute>
                    <TestDataAttribute id='interest_rate_broker'>
                      <TableCell>{showPercentage('')}</TableCell>
                    </TestDataAttribute>
                  </TableRow>
                  <TableRow>
                    <TableCell>{renderHeading('Points In')}</TableCell>
                    <TestDataAttribute id='points_in_borrower'>
                      <TableCell>{showPercentage(totalPoints)}</TableCell>
                    </TestDataAttribute>
                    <TestDataAttribute id='points_in_roc'>
                      <TableCell>{showPercentage(rocPointsIn + (isInternal ? originationPointsRetail.value : 0))}</TableCell>
                    </TestDataAttribute>
                    <TestDataAttribute id='points_in_lender'>
                      <TableCell>
                        {showPercentage(sfrCalculatedLenderPointsIn)}
                      </TableCell>
                    </TestDataAttribute>
                    <TestDataAttribute id='points_in_broker'>
                      <TableCell>{showPercentage(brokerPoints)}</TableCell>
                    </TestDataAttribute>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      {renderHeading('Premium Payments (At Close)')}
                    </TableCell>
                    <TestDataAttribute id='premium_payments_borrower"'>
                      <TableCell>{showPercentage('')}</TableCell>
                    </TestDataAttribute>
                    <TestDataAttribute id='premium_payments_roc'>
                      <TableCell>{showPercentage('')}</TableCell>
                    </TestDataAttribute>
                    <TestDataAttribute id='premium_payments_lender'>
                      <TableCell>
                        {showPercentage(oneTimeYieldSpreadPremium.value)}
                      </TableCell>
                    </TestDataAttribute>
                    <TestDataAttribute id='premium_payments_broker'>
                      <TableCell>{showPercentage('')}</TableCell>
                    </TestDataAttribute>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          }
          <br />
          {isBackOfficeUser && !isBackOfficeProcessor && isInternal &&
            <TableContainer component={Paper}>
              <Table>
                <TableRow>
                  <TableCell>{renderHeading('Add-on Fees')}</TableCell>
                  <TableCell>
                    {formatCurrency(lenderFeesFormStore.retailFeeRowTotal ?? 0)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{renderHeading('Rate Buydown Fees')}</TableCell>
                  <TableCell>
                    {formatCurrency(lenderFeesFormStore.rocFeeRowTotal ?? 0)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{renderHeading('Your Commission %')}</TableCell>
                  <TableCell>
                    <strong>{formatPercentage(
                      commissionData?.commissionPercentage ?? 0, 4
                    )}</strong>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{renderHeading('Your Commission Amount')}</TableCell>
                  <TableCell>
                    <strong>{formatCurrency(
                      commissionData?.commissionAmount ?? 0
                    )}</strong>
                  </TableCell>
                </TableRow>
              </Table>
            </TableContainer>
          }
        </Grid>
        <WarningsAlert warnings={allWarnings} />
      </Grid >
      <WimbaModal
        open={preferredTitle.value === WIMBA && !wimbaAcknowledged.value}
        handleClose={closeModal}
        handleConfirm={confirmModal}
      />
    </>
  );
}

export default observer(LoanSummary);
