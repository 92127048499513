import { Grid } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import {
  AddressComponents,
  isNil,
  parseAddress,
  statesAbbreviation,
  statesNames,
} from '@roc/feature-utils';
import { observer } from 'mobx-react';
import { AutocompleteAddressField } from './autocompleteAddressField';
import { TextField } from '../textField/textField';
import { GoogleMap } from '../googleMap/googleMap';
import { SelectField } from '../selectField/selectField';
import { StreetNumber } from '../borrowerBaseballCard/propertyFormFields';

const STREET_NUMBER_REGEX = /^(\d+)\s(.*)$/;

const useAddressStyles = makeStyles((theme: Theme) => ({
  autocompleteContainer: {
    marginBottom: theme.spacing(2),
  },
  mapStyles: {
    position: 'relative',
    width: '100%',
    height: '150px',
  },
}));

export interface AddressDetailsValue {
  streetNumber: string;
  streetName: string;
  zip: string;
  state: string;
  county: string;
  city: string;
  latitude: number;
  longitude: number;
  aptNumber: string;
  address: string;
}

export interface AddressDetailsProps {
  name: string;
  value: AddressDetailsValue;
  onChange: (value: AddressDetailsValue) => void;
  error?: boolean;
  errorText?: string;
  disabled?: boolean;
  required?: boolean;
  helperText?: string;
  showMap?: boolean;
  standaloneLabel?: boolean;
}

const addressLine2Tooltip =
  'Address Line 2 can be used for additional information\nsuch as apartment number, suite number, unit number\nor others details that can help in identifying the\nexact location of the address.';
const stateOptions = statesAbbreviation.map((abbreviation, i) => ({
  value: abbreviation,
  label: statesNames[i],
}));
const stateOptionsFiltered = [...stateOptions];
stateOptionsFiltered.shift();
export const AddressDetailsField = observer(
  ({
    name,
    onChange,
    error,
    errorText,
    disabled,
    required,
    helperText,
    value,
    standaloneLabel = true,
    showMap = true,
  }: AddressDetailsProps) => {
    const current = (value ?? {}) as AddressDetailsValue;
    const classes = useAddressStyles();
    const handleAddressChange = event => {
      const fullInfo = event?.target?.fullInfo;
      const { address_components, geometry } = fullInfo || {};
      if (!isNil(address_components) && !isNil(geometry)) {
        const parsedAddress = parseAddress(
          event?.target?.value,
          address_components,
          geometry
        );
        // Google Address
        const {
          street_number: streetNumber,
          street: streetName,
          ...addressFields
        } = parsedAddress;

        onChange({
          ...addressFields,
          streetName,
          streetNumber,
          address: `${streetNumber ?? ''} ${streetName ?? ''}`.trim(),
        });
      } else {
        // User Input
        const address = event?.target?.value;
        const { streetNumber, streetName } = parseStreet(address);
        onChange({
          ...current,
          address,
          streetNumber,
          streetName,
        });
      }
    };
    const handleChange = (fieldName, fieldValue) => {
      onChange({ ...current, [fieldName]: fieldValue });
    };
    const missingRequired =
      error &&
      required &&
      (!current?.address || !current?.city || !current?.state || !current?.zip);
    // Only show additional errors if all required messages are cleared
    const addressFieldError =
      (missingRequired && !current.address && 'The address is required') ||
      (!missingRequired && error && errorText);
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <AutocompleteAddressField
            label="Address Line 1"
            disabled={disabled}
            readOnly={disabled && !!current?.address}
            required={required}
            name={name}
            value={current?.address || ''}
            error={!!addressFieldError}
            errorText={addressFieldError ?? helperText}
            onChange={handleAddressChange}
            fullWidth
            testId={name}
            standaloneLabel={standaloneLabel}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            type={'text'}
            testId={'aptNumber'}
            disabled={disabled && !!current?.aptNumber}
            variant={'outlined'}
            label="Address Line 2"
            value={current?.aptNumber || ''}
            onChange={e => handleChange('aptNumber', e.target.value)}
            fullWidth
            standaloneLabel={standaloneLabel}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            type={'text'}
            testId={'city'}
            disabled={disabled && !!current?.city}
            variant={'outlined'}
            label="City"
            value={current?.city || ''}
            onChange={e => handleChange('city', e.target.value)}
            error={error && !current?.city}
            helperText={
              missingRequired && !current?.city && 'The city is required'
            }
            required={required}
            fullWidth
            standaloneLabel={standaloneLabel}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <SelectField
            testId={'state'}
            disabled={disabled && !!current?.state}
            variant={'outlined'}
            label="State"
            value={current?.state || ''}
            onChange={value => handleChange('state', value)}
            error={error && !current?.state}
            errorText={
              missingRequired && !current?.state && 'The state is required'
            }
            required={required}
            options={stateOptionsFiltered}
            fullWidth
            standaloneLabel={standaloneLabel}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            type={'text'}
            testId={'zipCode'}
            disabled={disabled && !!current?.zip}
            variant={'outlined'}
            label="Zip Code"
            value={current?.zip || ''}
            onChange={e => handleChange('zip', e.target.value)}
            error={error && !current?.zip}
            helperText={
              missingRequired && !current?.zip && 'The zip code is required'
            }
            required={required}
            fullWidth
            standaloneLabel={standaloneLabel}
          />
        </Grid>
        <Grid item xs={12}>
          {Boolean(showMap && current?.latitude && current?.longitude) && (
            <GoogleMap
              mapStyles={classes.mapStyles}
              latitude={current?.latitude}
              longitude={current?.longitude}
            />
          )}
        </Grid>
      </Grid>
    );
  }
);

const parseStreet = address => {
  if (STREET_NUMBER_REGEX.test(address)) {
    const [, streetNumber, streetName] = address.match(STREET_NUMBER_REGEX);
    return { streetName, streetNumber };
  } else {
    return { streetName: address, streetNumber: '' };
  }
};
