import { observer } from "mobx-react";
import { Grid } from '@material-ui/core';
import { CurrencyField, NumberFormat, PercentageField, RadioField, SelectField, TextField, trueFalseOptions } from "@roc/ui";
import { DURATION, isPendingLoanStatus, isPreagreedLoanStatus, isResidentialBridgeLoan, prePaymentPenaltyOptionsLoanData } from "@roc/feature-utils";
import { rateOptions } from "libs/feature-loans/src/loanSubmission/fixflipGroundUp/components/loanInformation";
import { useEffect } from 'react';
import { useStore } from "@roc/client-portal-shared/hooks";

export const LoanDataModal = observer(props => {
  const { store } = props;
  const { attorneys } = store
  const formFields = store.form.fields;
  const { status, loanType } = props.data;
  const { globalStore } = useStore();

  useEffect(() => {
    store.setAttorney(props.data)
  }, []);


  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <SelectField
            testId="editAttorneyValue"
            disabled={!isPreagreedLoanStatus(status)}
            required={false}
            standaloneLabel
            variant="outlined"
            label="Attorney Name"
            name="attorneyValue"
            options={attorneys}
            value={formFields.attorneyValue.value}
            onChange={(value) => {
              store.onFieldChange('attorneyValue', value)
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <SelectField
            testId="editRate"
            disabled={!isPendingLoanStatus(status) || !isResidentialBridgeLoan(loanType)}
            required={false}
            variant="outlined"
            standaloneLabel
            label="Interest Rate"
            options={rateOptions}
            name="rate"
            onChange={(value) =>
              store.onFieldChange('rate', value)
            }
            value={formFields.rate.value}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <PercentageField
            testId="editPointsIn"
            disabled={!isPreagreedLoanStatus(status)}
            required={false}
            variant="outlined"
            standaloneLabel
            onChange={(name, value) =>
              store.onFieldChange('pointsIn', Number(value))
            }
            label="Origination Fee"
            value={formFields.pointsIn.value}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <PercentageField
            testId="editExitFee"
            disabled={!isPreagreedLoanStatus(status) || !isResidentialBridgeLoan(loanType)}
            required={false}
            variant="outlined"
            standaloneLabel
            label="Exit fee"
            onChange={(name, value) =>
              store.onFieldChange('pointsOut', Number(value))
            }
            value={formFields.pointsOut.value}
            fullWidth
          />
        </Grid>
        {props?.data?.referralFeePct && props?.data?.referralFeePct > 0 ?
          (<Grid item xs={6}>
            <PercentageField
              testId="editOffHudBrokerFee"
              disabled={!isPendingLoanStatus(status)}
              required={false}
              variant="outlined"
              standaloneLabel
              label="Off Hud Broker Fee"
              onChange={(name, value) =>
                store.onFieldChange('referralFeePct', Number(value))
              }
              value={formFields.referralFeePct.value}
              fullWidth
            />
           </Grid>):(
           <Grid item xs={6}>
            <PercentageField
              testId="editBrokerFee"
              disabled={!isPendingLoanStatus(status)}
              required={false}
              variant="outlined"
              standaloneLabel
              label= {globalStore?.lenderInfo?.isInternal ? "On Hud Broker Fee" : "Broker Fee" }
              onChange={(name, value) =>
                store.onFieldChange('brokerPointsIn', Number(value))
              }
              value={formFields.brokerPointsIn.value}
              fullWidth
            />
           </Grid>
          )

        }

        <Grid item xs={6}>
          <NumberFormat
            customInput={TextField}
            testId="editNumberOfExtensions"
            disabled={!isPendingLoanStatus(status) || !isResidentialBridgeLoan(loanType)}
            required={false}
            variant="outlined"
            standaloneLabel
            label="Number of Extensions"
            onChange={(e) =>
              store.onFieldChange('numberOfExtensions', Number(e.target.value))
            }
            value={formFields.numberOfExtensions.value}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <NumberFormat
            customInput={TextField}
            testId="editMonthsPerExtension"
            disabled={!isPendingLoanStatus(status) || !isResidentialBridgeLoan(loanType)}
            required={false}
            variant="outlined"
            standaloneLabel
            label="Months per Extension"
            onChange={(e) =>
              store.onFieldChange('monthsPerExtension', Number(e.target.value))
            }
            value={formFields.monthsPerExtension.value}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <PercentageField
            testId="editExtensionFee"
            disabled={!isPendingLoanStatus(status) || !isResidentialBridgeLoan(loanType)}
            required={false}
            variant="outlined"
            standaloneLabel
            label="Extension Fee"
            onChange={(name, value) =>
              store.onFieldChange('rocExtensionFee', Number(value))
            }
            value={formFields.rocExtensionFee.value}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <SelectField
            testId="editPrepayPenalty"
            disabled={!isPendingLoanStatus(status) || isResidentialBridgeLoan(loanType)}
            required={false}
            variant="outlined"
            standaloneLabel
            label="Pre-Payment Penalty Description"
            options={prePaymentPenaltyOptionsLoanData}
            onChange={(value) =>
              store.onFieldChange('prepaymentDescription', value)
            }
            value={formFields.prepaymentDescription.value}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <SelectField
            testId="editDuration"
            disabled={!isPendingLoanStatus(status) || !isResidentialBridgeLoan(loanType)}
            required={false}
            variant="outlined"
            standaloneLabel
            label="Duration"
            name="duration"
            options={DURATION}
            onChange={(value) =>
              store.onFieldChange('duration', value)
            }
            value={formFields.duration.value}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <CurrencyField
            testId="editCapitalProviderUnderwritingFee"
            disabled={!isPendingLoanStatus(status)}
            required={false}
            variant="outlined"
            standaloneLabel
            label="Capital Provider Underwriting Fee"
            onChange={(name, value) =>
              store.onFieldChange('underwritingFee', Number(value))
            }
            value={formFields.underwritingFee.value}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <PercentageField
            testId="editCapitalProviderPointsIn"
            disabled={!isPendingLoanStatus(status)}
            required={false}
            variant="outlined"
            standaloneLabel
            label="Capital Provider Points In"
            onChange={(name, value) =>
              store.onFieldChange('rocPointsIn', Number(value))
            }
            value={formFields.rocPointsIn.value}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <PercentageField
            testId="editCapitalProviderPointsOut"
            disabled={!isPendingLoanStatus(status)}
            required={false}
            variant="outlined"
            standaloneLabel
            label="Capital Provider Points Out"
            onChange={(name, value) =>
              store.onFieldChange('rocPointsOut', Number(value))
            }
            value={formFields.rocPointsOut.value}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <PercentageField
            testId="editPointsIn"
            disabled={!isPendingLoanStatus(status)}
            required={false}
            variant="outlined"
            standaloneLabel
            label="Points In"
            onChange={(name, value) =>
              store.onFieldChange('pointsIn', Number(value))
            }
            value={formFields.pointsIn.value}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <PercentageField
            testId="editPointsOut"
            disabled={!isPendingLoanStatus(status)}
            required={false}
            variant="outlined"
            standaloneLabel
            label="Points Out"
            onChange={(name, value) =>
              store.onFieldChange('pointsOut', Number(value))
            }
            value={formFields.pointsOut.value}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <PercentageField
            testId="editBrokerPointsIn"
            disabled={!isPendingLoanStatus(status)}
            required={false}
            variant="outlined"
            standaloneLabel
            label="Broker Points In"
            onChange={(name, value) =>
              store.onFieldChange('brokerPointsIn', Number(value))
            }
            value={formFields.brokerPointsIn.value}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <PercentageField
            testId="editBrokerPointsOut"
            disabled={!isPendingLoanStatus(status)}
            required={false}
            variant="outlined"
            standaloneLabel
            label="Broker Points Out"
            onChange={(name, value) =>
              store.onFieldChange('brokerPointsOut', Number(value))
            }
            value={formFields.brokerPointsOut.value}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <RadioField
            testId="editPledgeOfShares"
            disabled={!isPendingLoanStatus(status)}
            required={false}
            options={trueFalseOptions}
            standaloneLabel
            name={'editPledgeOfShares'}
            label="Pledge of Shares"
            onChange={value =>
              store.onFieldChange('pledgeOfShares', value === 'true')
            }
            value={formFields.pledgeOfShares.value}
            fullWidth
            row
          />
        </Grid>
        {/* <Grid item xs={6}>
          <RadioField
            testId="editIsReferred"
            disabled={!isPendingLoanStatus(status)}
            required={false}
            options={trueFalseOptions}
            standaloneLabel
            name={'editIsReferred'}
            label="Is Referred"
            onChange={value =>
              store.onFieldChange('isReferred', value === 'true')
            }
            value={formFields.isReferred.value}
            fullWidth
            row
          />
        </Grid> */}
        {/* <Grid item xs={6}>
          <CurrencyField
            testId="editReferralFee"
            disabled={!isPendingLoanStatus(status) || !formFields.isReferred.value}
            required={false}
            variant="outlined"
            standaloneLabel
            label="Referral Fee"
            onChange={(name, value) =>
              store.onFieldChange('referralFee', Number(value))
            }
            value={formFields.referralFee.value}
            fullWidth
          />
        </Grid> */}
      </Grid>
    </>
  );
});