import { useRef, useState } from 'react';
import {
  Box,
  ButtonGroup,
  ClickAwayListener,
  Grid,
  Grow,
  MenuList,
  Typography,
  MenuItem,
  Popper,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { useStore } from '@roc/client-portal-shared/hooks';
import { observer } from 'mobx-react';
import { Grid as DataGrid } from '@roc/client-portal-shared/components';
import { Tabs } from '@roc/ui';
import { createAutocompleteAddressField } from '../../../components/formComponents/common/componentBuilder';
import {
  creditOptions,
  propertyOwnership,
  yesNoOptions,
  addingSquareFootageOptions,
} from '../utils/constants';
import SummaryPurchaseTab from './sumary/summaryPurchaseTab';
import SummaryProfitAndDscrTab from './sumary/summaryProfitAndDscrTab';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import SummaryAlreadyOwn from './sumary/summaryAlreadyOwn';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { AlertDialog, Button, Paper } from '@roc/ui';
import {
  CloudDownload,
  Mail,
  MailOutline,
  MailOutlined,
} from '@material-ui/icons';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import DownloadableSummary, {
  DownloadableSummaryRef,
} from './sumary/downloadableSummary';

const SuccessDialog = ({ open, onClose, sizerId }) => {
  return (
    <AlertDialog
      open={open}
      handleClose={onClose}
      handleOk={onClose}
      title="Success"
      body={
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>
              Your Fix & Flip Sizer request was created successfully!
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              You request number is <b>{sizerId}</b>
            </Typography>
          </Grid>
        </Grid>
      }
    />
  );
};

const Summary = observer(() => {
  const { fixFlipSizerStore } = useStore();
  const { detailsStore } = fixFlipSizerStore;
  const sizer = detailsStore.sizer;

  const aquisition = sizer.property.propertyOwnership;
  const isPurchase = propertyOwnership.PURCHASED === aquisition;
  const isOwned = propertyOwnership.OWNED_BY_BORROWER === aquisition;

  const [tab, setTab] = useState(0);

  const downloadableSummaryRef = useRef<DownloadableSummaryRef>();

  const download = () => {
    downloadableSummaryRef.current.downloadAsPdf();
  };

  const sendEmail = async () => {
    const blob = await downloadableSummaryRef.current.getPdfAsBlob();
    detailsStore.sendEmail(blob);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Tabs
          tabs={[
            { label: isPurchase ? 'Purchase' : 'Already Own' },
            { label: 'Profit & DSCR' },
          ]}
          selectedTab={tab}
          onTabChange={(e, index) => setTab(index)}
        />
      </Grid>
      {tab == 0 && isPurchase && <SummaryPurchaseTab />}
      {tab == 0 && isOwned && <SummaryAlreadyOwn />}
      {tab == 1 && <SummaryProfitAndDscrTab />}

      <Grid item xs={12}>
        <Box pt={4}>
          <Grid container direction="row" justifyContent="center" spacing={2}>
            <Grid item xs={10} md={5}>
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                startIcon={<MailOutlined />}
                onClick={() => sendEmail()}
                testId="mail"
              >
                Send by email to RM
              </Button>
            </Grid>
            <Grid item xs={10} md={5}>
              <Button
                variant="contained"
                color="secondary"
                startIcon={<CloudDownload />}
                fullWidth
                onClick={() => download()}
                testId={'save'}
              >
                Download
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box p={4}>
          <Grid container direction="row" justifyContent="center" spacing={2}>
            <Grid item xs={6} md={3}>
              <Button
                variant="outlined"
                color="primary"
                fullWidth
                onClick={() => {
                  detailsStore.goPrevStep();
                }}
                testId="previous"
              >
                Back
              </Button>
            </Grid>
            <Grid item xs={6} md={3}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => {
                  detailsStore.saveSizer();
                }}
                testId={'save'}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <DownloadableSummary ref={downloadableSummaryRef} />
      <SuccessDialog
        open={detailsStore.showSuccess}
        onClose={() => detailsStore.closeSuccessDialog()}
        sizerId={detailsStore.sizerId}
      />
    </Grid>
  );
});

export default Summary;
