export const getLoanApplicationRoutesConfig = (basePath: string, baseUrl: string) => {
  return {
    loanApplication: {
      path: `${basePath}/apply-for-a-loan`,
      url: `${baseUrl}/apply-for-a-loan`,
      documentTitle: 'Apply for a Loan',
      children: {
        fixFlip: {
          path: `${basePath}/apply-for-a-loan/fix-flip`,
          url: `${baseUrl}/apply-for-a-loan/fix-flip`,
          documentTitle: 'New Fix & Flip Loan',
        },
        groundUp: {
          path: `${basePath}/apply-for-a-loan/ground-up`,
          url: `${baseUrl}/apply-for-a-loan/ground-up`,
          documentTitle: 'New Ground Up Loan',
        },
        singleProperty: {
          path: `${basePath}/apply-for-a-loan/single-property`,
          url: `${baseUrl}/apply-for-a-loan/single-property`,
          documentTitle: 'New Single Property Loan',
        },
      }
    },
  };
};