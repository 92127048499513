import { Box, useMediaQuery } from '@material-ui/core';
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles';
import { useBaseStore, useMiscellaneousRoutes } from '@roc/feature-app-core';
import {
  useCommunicationRoutesConfig,
  useLoanCommunicationStore,
} from '@roc/feature-communication';
import { useDocumentStore } from '@roc/feature-documents';
import { LoanDetailsComponent } from '@roc/feature-loan-details';
import { useLoanRoutes } from '@roc/feature-loans-routes-config';
import { getDocumentSection, LoanType } from '@roc/feature-utils';
import { PDFPreview } from '@roc/ui';
import { observer } from 'mobx-react';
import React from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useHistory, useLocation, useParams } from 'react-router';
import { useLoanStore } from '../hooks/useLoanStore';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
    },
    chatBox: {
      overflow: 'auto',
      [theme.breakpoints.down(769)]: {
        margin: '8px 0 0 0',
      },
    },
  })
);

interface LoanDetailsProps {
  hideEditBorrowers?: boolean;
  renderLoanSummary: (props) => React.ReactNode;
  isLoanSummary: boolean;
  onUserNewConversation: (inviteUserId: number) => Promise<void>;
  onOpenAddTask?: (assigneeId: number) => void;
  renderFastTrackPaymentReminder: () => React.ReactNode;
  renderLoanClosingCondition: () => React.ReactNode;
  renderDrawDetails: () => React.ReactNode;
  renderExtensionDetails: () => React.ReactNode;
  renderPayoffScreen: (servicer: any) => React.ReactNode;
  renderRepeatPropertyWarning: () => React.ReactNode;
}

export const LoanDetailsV2 = observer((props: LoanDetailsProps) => {
  const classes = useStyles();
  const {
    onOpenAddTask,
    renderLoanSummary,
    isLoanSummary,
    onUserNewConversation,
    renderFastTrackPaymentReminder,
    renderLoanClosingCondition,
    renderDrawDetails,
    renderExtensionDetails,
    renderPayoffScreen,
    renderRepeatPropertyWarning,
  } = props;
  const { globalStore } = useBaseStore();
  const { loanId } = useParams<{ loanId: string }>();
  const { pathname } = useLocation();
  const { loanStore } = useLoanStore();
  const { documentStore } = useDocumentStore();
  const { loanCommunicationStore } = useLoanCommunicationStore();
  const { push } = useHistory();
  const { miscellaneousRoutes } = useMiscellaneousRoutes();
  const { loanRoutesConfig } = useLoanRoutes();
  const twilioCommunicationEnabled =
    loanStore.loanDetails?.twilioCommunicationEnabled;
  const theme = useTheme();
  const isTabletSize = useMediaQuery(theme.breakpoints.down(769));
  const isBridgeLoan =
    loanStore.loanDetails?.loanType == LoanType.RESIDENTIAL_BRIDGE;

  const { communicationRoutesConfig } = useCommunicationRoutesConfig();

  const documentSection = getDocumentSection(pathname);
  const isLargeScreen = useMediaQuery(theme.breakpoints.up(1400));

  return (
    <Box display="flex" className={classes.container}>
      <Box flexGrow={1} pb={isLargeScreen ? 0 : 5}>
        <LoanDetailsComponent
          onOpenAddTask={onOpenAddTask}
          renderDrawDetails={renderDrawDetails}
          renderExtensionDetails={renderExtensionDetails}
          renderPayoffScreen={renderPayoffScreen}
          renderLoanClosingCondition={renderLoanClosingCondition}
          renderRepeatPropertyWarning={renderRepeatPropertyWarning}
        />
      </Box>
      <PDFPreview
        open={documentStore.documentPreview != null}
        title={documentStore.documentPreview?.title}
        data={documentStore.documentPreview?.data}
        headers={documentStore.documentPreview?.headers}
        onClose={() => documentStore.closeDocumentPreview()}
      />
    </Box>
  );
});
