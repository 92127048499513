import { DialogState } from '@roc/ui';
import { action, computed, flow, makeObservable, observable } from 'mobx';
import { Broker } from '../types/brokerTypes';
import { BrokersStore } from './brokersStore';
import { UserStore, GlobalStore } from '@roc/feature-app-core';
import { ManageBrokerFormStore } from './manageBrokerFormStore';
import { isNil } from '@roc/feature-utils';

export class SelectBrokersStore {
  globalStore: GlobalStore;
  userStore: UserStore;
  brokersStore: BrokersStore;
  manageBrokerFormStore: ManageBrokerFormStore;

  selectDialogState: DialogState = null;
  editDialogState: DialogState = null;
  openEditBrokerInfoStep;
  openEditFeesInfoStep;
  openEditVendorSettings;

  brokers: Broker[];

  allowMultiple: boolean;

  constructor(brokersStore: BrokersStore, globalStore: GlobalStore) {
    this.globalStore = globalStore;
    this.brokersStore = brokersStore;
    this.brokers = [];

    makeObservable(this, {
      brokers: observable,
      reset: action,
      selectDialogState: observable,
      editDialogState: observable,
      allowMultiple: observable,
      canAddMore: computed,
      loadBrokerUser: flow,
      openEditBrokerInfoStep: observable,
      openEditFeesInfoStep: observable,
      openEditVendorSettings: observable,
    });
  }

  reset() {
    this.selectDialogState = null;
    this.editDialogState = null;
    this.brokers = [];
    this.openEditBrokerInfoStep = false;
    this.openEditFeesInfoStep = false;
    this.openEditVendorSettings = false;
  }

  openSelectBrokersDialog() {
    this.brokersStore.brokersGridStore.resetSelected();
    this.selectDialogState = DialogState.ADD;
  }

  closeSelectBrokersDialog() {
    this.selectDialogState = null;
  }

  confirmSelectedBrokers() {
    const selected = this.brokersStore.brokersGridStore.selected;
    this.brokers = this.brokers.concat(selected);
    this.closeSelectBrokersDialog();
  }

  openAddNewBrokerDialog() {
    this.brokersStore.manageBrokerFormStore.reset();
    this.reset();
    this.editDialogState = DialogState.ADD;
    this.brokersStore.manageBrokerFormStore.setIsEdit(false);
    this.brokersStore.manageBrokerFormStore.setIsDisabled(false);
    this.brokersStore.brokerFeesStore.reset();
    this.brokersStore.brokerVendorClosingSettingsFormStore.reset();
  }

  editBroker(broker: Broker) {
    this.brokersStore.manageBrokerFormStore.setBroker(broker);
    this.editDialogState = DialogState.EDIT;
    this.brokersStore.manageBrokerFormStore.setIsEdit(true);
    this.brokersStore.manageBrokerFormStore.setIsDisabled(true);
  }

  closeEditBrokerDialog() {
    this.editDialogState = null;
  }

  confirmEditBrokerDialog() {
    const { brokerFormStore } = this.brokersStore;
    brokerFormStore.runFormValidationWithMessage();
    if (brokerFormStore.form.meta.isValid) {
      const broker = brokerFormStore.getBroker();
      if (this.editDialogState === DialogState.EDIT) {
        this.brokers = this.brokers.map(b =>
          this.isSameBroker(b, broker) ? broker : b
        );
      } else {
        this.brokers = this.brokers.concat(broker);
      }
      this.closeEditBrokerDialog();
    }
  }

  removeBroker(broker: Broker) {
    this.brokers = this.brokers.filter(b => !this.isSameBroker(b, broker));
    this.brokersStore.brokerFormStore.reset();
  }

  private isSameBroker(broker1: Broker, broker2: Broker) {
    const isExistingBroker = broker1.brokerId || broker2.brokerId;
    return isExistingBroker
      ? broker1.brokerId == broker2.brokerId
      : broker1.email == broker2.email;
  }

  get canAddMore() {
    return this.allowMultiple || this.brokers.length === 0;
  }

  get canEditBroker() {
    return !this.globalStore?.userFeatures?.isBroker && isNil(this.brokers[0].brokerId);
  }

  get canRemoveBroker() {
    return !this.globalStore?.userFeatures?.isBroker;
  }

  *loadBrokerUser(userId: number) {
    const { brokerService } = this.brokersStore;
    const response = yield brokerService.getBrokerByUserId(userId);
    const broker = response.data.data;
    const isDuplicate = this.brokers.some(existingBroker => existingBroker.brokerId === broker.lenderId);
    if (!isDuplicate) {
      this.brokers = this.brokers.concat({
        ...broker,
        entityName: broker?.company?.name,
        entityTaxNumber: broker?.company?.einNumber,
        brokerId: broker?.lenderId,
        companyId: broker?.company?.companyId,
      });
    }

  }
}

export default SelectBrokersStore;
