import { Button } from '@material-ui/core';
import { AttachFile } from '@material-ui/icons';
import { observer } from 'mobx-react';
import { ChangeEvent, useState } from 'react';
import { MAX_FILE_SIZE } from '../../utils/constants';

interface Props {
  files: File[];
  onAttachClick: () => void;
}

export const AttachFileButton = observer(({ files, onAttachClick }: Props) => {
  const [filesInputKey, setFilesInputKey] = useState<string>('input-key');

  return (
    <Button
      style={{ marginRight: 8, fontSize: 12 }}
      variant="outlined"
      color="default"
      startIcon={<AttachFile />}
      size="small"
      component="label"
      onClick={onAttachClick}
    >
      Add Attachment
    </Button>
  );
});
