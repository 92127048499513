import { useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { Button, TextField } from '@roc/ui';
import { Alert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';

import { useStore } from '../../../hooks';
import { isNotBlank } from '@roc/client-portal-shared/utils';
import GFDDocusignModal from './GFDDocusignModal';
import {
  DOCUSIGN_DONE,
  DOCUSIGN_INCOMPLETE,
  DOCUSIGN_CALLBACK,
} from '../constants/loanTerms';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    successAlert: {
      backgroundColor: 'transparent',
      '& .MuiAlert-icon': {
        marginRight: theme.spacing(1),
      },
    },
    borrowerInfoGrid: {
      padding: theme.spacing(4),
    },
  })
);

interface Props {
  handleNextStep: () => void;
  loanId: string;
  subTitle: string;
  firstName: string;
  lastName: string;
}

const TermSheet = observer((props: Props) => {
  const [docusingError, setDocusingError] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const { loanTermsStore } = useStore();
  const { docusignStatus } = loanTermsStore;
  const classes = useStyles();

  const setDocusignStatus = value => loanTermsStore.setDocusignStatus(value);

  const handleModalClose = () => setModalOpen(false);

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleWindowMessage = (event: MessageEvent) => {
    switch (event.data) {
      case DOCUSIGN_DONE:
        setDocusignStatus(true);
        break;
      case DOCUSIGN_INCOMPLETE:
        setDocusignStatus(false);
        setDocusingError(true);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    window.addEventListener('message', handleWindowMessage);

    return () => window.removeEventListener('message', handleWindowMessage);
  }, []);

  useEffect(() => {
    if (modalOpen) {
      setDocusingError(false);
      loanTermsStore.fetchGFDDocument(
        props.loanId,
        DOCUSIGN_CALLBACK
      );
    } else {
      loanTermsStore.setLoanTermsUrl(null);
    }
  }, [modalOpen]);

  return (
    <>
      <Box>
        <Box mb={2}>
          <Typography variant="h4">Good Faith Deposit Disclosure</Typography>
        </Box>
        <Typography variant="h6">{props.subTitle}</Typography>
      </Box>

      <Box mt={3}>
        <Typography variant="body1">
          Please review the Credit Card Payment Authorization Form carefully before continuing. Once you
          have read and agreed to the conditions, please sign and
          accept it to proceed to the Good Faith Deposit.
        </Typography>
        <br />
        <br />
        <Typography variant="h6">Personal Information</Typography>
        <br />
        <Grid container direction="row" spacing={3}>
          <Grid item xs={12} md={3} className={classes.borrowerInfoGrid}>
            <TextField
              testId="firstName"
              variant="outlined"
              standaloneLabel
              label="First Name"
              value={props.firstName}
              disabled
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={3} className={classes.borrowerInfoGrid}>
            <TextField
              testId="lastName"
              variant="outlined"
              standaloneLabel
              label="Last Name"
              value={props.lastName}
              disabled
              fullWidth
            />
          </Grid>
        </Grid>
        <Box mb={1} mt={3}>
          <Grid container>
            <Button
              variant="contained"
              color="primary"
              onClick={handleModalOpen}
              disabled={docusignStatus}
              size="large"
              testId="acceptGFDAndSign"
            >
              {'Review & Sign'}
            </Button>
            {docusignStatus && (
              <Alert className={classes.successAlert} severity="success">
                Accepted
              </Alert>
            )}
          </Grid>
        </Box>
      </Box>
      <Box mt={16}>
        <Grid container justifyContent="center" spacing={2}>
          <Grid item xs={6} md={3}>
            <Button
              variant="contained"
              color="primary"
              disabled={!docusignStatus}
              fullWidth
              onClick={props.handleNextStep}
              testId="next"
            >
              Next
            </Button>
          </Grid>
        </Grid>
      </Box>

      <GFDDocusignModal
        onClose={handleModalClose}
        open={modalOpen}
        successPage={docusignStatus || docusingError}
      />
    </>
  );
});

export default TermSheet;
