import { Box, Table, TableBody, TableCell, TableHead, TableRow, Theme, Typography, makeStyles } from '@material-ui/core';
import { alpha } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { DateField, RadioField } from '@roc/ui';
import { BackgroundItemStore } from '../stores/backgroundItemStore';
import { yesNoOptionsBoolean } from '@roc/feature-utils';

const modalStyles = makeStyles((theme: Theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '10px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    minWidth: '400px',
  },
}));

const useStyles = makeStyles((theme: Theme) => ({
  fixedWidthText: {
    fontSize: '1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%'
  },
  ssnCell: {
    textAlign: 'center',
    minWidth: '120px',
  },
  tableContainer: {
    borderRadius: '10px',
    border: '1px #e0e0e0 solid',
    overflow: 'hidden',
  },
  table: {
    minWidth: 750,
    width: '100%',
    '& td': {
      height: '40px',
      padding: 0,
      paddingTop: '8px',
      paddingBottom: '8px',
      border: '1px solid #e0e0e0',
    },
    '& th:first-child, td[rowSpan], $addNewItemRow td': {
      paddingLeft: theme.spacing(2),
      paddingRight: '3px',
    },
    '& table tr:last-child td, & table td[rowSpan]': {
      borderBottom: 'none',
    },
  },

  editableRow: {
    position: 'relative',
    cursor: 'pointer',
    textAlign: 'center',
    '& td': {
      textAlign: 'center'
    },
  },

  headerCell: {
    height: '41px',
    fontWeight: 'bold',
    color: '#757575',
    padding: 0,
    backgroundColor: '#F0FAFF',
    textAlign: 'center',
    border: '1px solid #e0e0e0',
  },

  titleCell: {
    height: '46px',
    padding: 0,
    overflow: 'hidden',
    backgroundColor: '#E2F6FF'
  },

  descriptionCell: {
    whiteSpace: 'pre-wrap',
  },

  inputBase: {
    width: '100%',
    '& input': {
      backgroundColor: '#fdefbf',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      fontSize: 14,
      color: 'black',
      fontWeight: theme.typography.fontWeightBold,
      padding: '8px 4px',
      cursor: 'pointer',
      '&:focus': {
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  autocompletePaper: {
    marginLeft: 0,
    marginRight: 0,
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    fontSize: 13,
  },
  autocompleteOption: {
    minHeight: 'auto',
    alignItems: 'flex-start',
    padding: 8,
    '&[data-focus="true"]': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  addNewItemCell: {
    padding: 0,
    height: '40px',
    backgroundColor: '#fdefbf',
  },
  addNewItemRow: {
    '&:hover': {
      backgroundColor: '#fff',
    },
  },
  rowActions: {
    visibility: 'hidden',
    position: 'absolute',
    right: 0,
    top: 0,
    bottom: 0,
    zIndex: 99,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      visibility: 'visible',
    },
  },
  horizontalScroll: {
    [theme.breakpoints.down('md')]: {
      overflowX: 'scroll',
    },
  },
  clickHereToEdit: {
    color: theme.palette.grey[400],
    fontSize: theme.typography.fontSize * 0.8,
  },
  clickHereToEditHidden: {
    visibility: 'hidden',
  },
  title: {
    color: '#757575',
    fontSize: 20
  },
}));

interface BorrowerExperienceDataProps {
  store: BackgroundItemStore;
}

const BorrowerRecords = observer(({ store }: BorrowerExperienceDataProps) => {
  const columns = [
    {
      name: 'Criminal Record',
      width: '25%',
    },
    {
      name: 'Financial Crimes',
      width: '25%',
    },
    {
      name: 'Forclosure Date',
      width: '25%',
    },
    {
      name: 'Bankruptcy Date',
      width: '25%',
    },
  ];

  const classes = useStyles();

  return (
    <Box style={{ paddingTop: '15px' }}>
      <div className={classes.tableContainer}>
        <Table className={classes.table}>
          <TableHead><TableRow>
            <TableCell colSpan={columns.length} className={classes.titleCell}>
              <Typography className={classes.title}>Borrower Record</Typography>
            </TableCell>
          </TableRow>
            <TableRow>
              {columns.map(({ name, ...props }) => (
                <TableCell key={name} className={classes.headerCell} style={{ paddingLeft: '8px' }}>
                  {name}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody className={classes.editableRow}>
            <TableRow>
              <TableCell style={{ paddingRight: '5px', paddingLeft: '5px' }}>
                <RadioField
                  value={store.criminalRecord === undefined ? '' : store.criminalRecord}
                  name={'criminalRecord'}
                  options={yesNoOptionsBoolean}
                  onChange={(value) => store.setCriminalRecord(value)}
                  row
                  testId="criminalRecord"
                  disabled={!store.isInternal}
                />
              </TableCell>
              <TableCell style={{ paddingRight: '5px', paddingLeft: '5px' }}>
                <RadioField
                  value={store.financialCrimes === undefined ? '' : store.financialCrimes}
                  name={'financialCrimes'}
                  options={yesNoOptionsBoolean}
                  onChange={(value) => store.setFinancialCrimes(value)}
                  row
                  testId="financialCrimes"
                  disabled={!store.isInternal}
                />
              </TableCell>
              {/* <TableCell style={{ paddingRight: '5px', paddingLeft: '5px' }}>
                <SelectField
                  standaloneLabel
                  value={store.foreclosure ? store.foreclosure : options[0].value}
                  name={'foreclosure'}
                  label=''
                  placeholder='None'
                  options={options}
                  onChange={(value) => store.setForeclosure(value)}
                  testId="foreclosure"
                  disabled={!store.isInternal}
                />
              </TableCell> */}
              <TableCell style={{ paddingRight: '5px', paddingLeft: '5px' }}>
                <DateField
                  inputVariant="outlined"
                  value={store.foreclosureDate ? new Date(store.foreclosureDate) : null}
                  onChange={(date) => {
                    store.setForeclosureDate(date);
                  }}
                  required
                  testId="foreclosureDate"
                  format="MM/dd/yyyy"
                  fullWidth={false}
                  disabled={!store.isInternal}
                />
              </TableCell>
              {/* <TableCell style={{ paddingRight: '5px', paddingLeft: '5px' }}>
                <SelectField
                  standaloneLabel
                  value={store.bankruptcy ? store.bankruptcy : options[0].value}
                  name={'bankruptcy'}
                  label=''
                  placeholder='None'
                  options={options}
                  onChange={(value) => store.setBankruptcy(value)}
                  testId="bankruptcy"
                  disabled={!store.isInternal}
                />
              </TableCell> */}
              <TableCell style={{ paddingRight: '5px', paddingLeft: '5px' }}>
                <DateField
                  inputVariant="outlined"
                  value={store.bankruptcyDate ? new Date(store.bankruptcyDate) : null}
                  onChange={(date) => {
                    store.setBankruptcyDate(date);
                  }}
                  required
                  testId="bankruptcyDate"
                  format="MM/dd/yyyy"
                  fullWidth={false}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    </Box>
  );
});

export default BorrowerRecords;