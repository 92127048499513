import { Box, Button, Paper, Table, TableBody, TableCell, TableHead, TableRow, Theme, Typography, makeStyles, Modal, Backdrop } from '@material-ui/core';
import { alpha } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import IconButton from '@material-ui/core/IconButton';
import react, { Fragment, useEffect, useState } from 'react';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { BackgroundItemStore } from '../stores/backgroundItemStore';
import NewItemForm from './newItemForm';
import { BANKRUPTCY_RECORD, getInfoButton, getResolutionFlag } from './utils/backgroundUtils';
import { ItemsShownCounterChip, XmlCounterChip } from './itemsCounterChip';

const modalStyles = makeStyles((theme: Theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '10px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    minWidth: '400px',
  },
}));

const useStyles = makeStyles((theme: Theme) => ({
  fixedWidthText: {
    fontSize: '1rem',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'left',
    paddingLeft: '10px',
    width: '100%'
  },
  ssnCell: {
    textAlign: 'center',
    minWidth: '120px',
  },
  tableContainer: {
    borderRadius: '10px',
    border: '1px #e0e0e0 solid',
    overflow: 'hidden',
  },
  table: {
    minWidth: 750,
    width: '100%',
    '& td': {
      height: '40px',
      padding: 0,
      paddingTop: '8px',
      paddingBottom: '8px',
      border: '1px solid #e0e0e0',
    },
    '& th:first-child, td[rowSpan], $addNewItemRow td': {
      paddingLeft: theme.spacing(2),
      paddingRight: '3px',
    },
    '& table tr:last-child td, & table td[rowSpan]': {
      borderBottom: 'none',
    },
  },

  editableRow: {
    position: 'relative',
    cursor: 'pointer',
    textAlign: 'center',
    '& td': {
      textAlign: 'center'
    },
  },

  headerCell: {
    height: '41px',
    fontWeight: 'bold',
    color: '#757575',
    padding: 0,
    backgroundColor: '#F0FAFF',
    textAlign: 'center',
    border: '1px solid #e0e0e0',
  },

  titleCell: {
    height: '46px',
    padding: 0,
    overflow: 'hidden',
    backgroundColor: '#E2F6FF'
  },

  descriptionCell: {
    whiteSpace: 'pre-wrap',
  },

  inputBase: {
    width: '100%',
    '& input': {
      backgroundColor: '#fdefbf',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      fontSize: 14,
      color: 'black',
      fontWeight: theme.typography.fontWeightBold,
      padding: '8px 4px',
      cursor: 'pointer',
      '&:focus': {
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  autocompletePaper: {
    marginLeft: 0,
    marginRight: 0,
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    fontSize: 13,
  },
  autocompleteOption: {
    minHeight: 'auto',
    alignItems: 'flex-start',
    padding: 8,
    '&[data-focus="true"]': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  addNewItemCell: {
    padding: 0,
    height: '40px',
    backgroundColor: '#fdefbf',
  },
  addNewItemRow: {
    '&:hover': {
      backgroundColor: '#fff',
    },
  },
  rowActions: {
    visibility: 'hidden',
    position: 'absolute',
    right: 0,
    top: 0,
    bottom: 0,
    zIndex: 99,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      visibility: 'visible',
    },
  },
  horizontalScroll: {
    [theme.breakpoints.down('md')]: {
      overflowX: 'scroll',
    },
  },
  clickHereToEdit: {
    color: theme.palette.grey[400],
    fontSize: theme.typography.fontSize * 0.8,
  },
  clickHereToEditHidden: {
    visibility: 'hidden',
  },
  title: {
    color: '#757575',
    fontSize: 20
  },
  flexContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
}));

interface BankruptcyRecordProps {
  store: BackgroundItemStore;
  openResolutionInfo?: (data?: any) => void;
}

const BankruptcyRecord = observer(({ store, openResolutionInfo }: BankruptcyRecordProps) => {
  const classes = useStyles();
  const columns = [
    {
      name: 'ITEM',
      width: '35%',
    },
    {
      name: 'ITEM DESCRIPTION',
      width: '35%',
    },
    {
      name: 'RESOLUTION FLAG',
      width: '15%',
    },
    ...((store.isInternal && !store.readOnly)
      ? [
        {
          name: 'ACTIONS',
          width: '15%',
        },
      ]
      : []),
  ];

  const updateRecordItem = (index: number) => {
    store.updateRecordItem(index, BANKRUPTCY_RECORD);
  };

  const removeRecordItem = (index: number) => {
    store.removeRecordItem(index, BANKRUPTCY_RECORD);
  };

  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [indexToEdit, setIndexToEdit] = useState(null);
  const modalClasses = modalStyles();
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenEdit = (index: number) => {
    setIndexToEdit(index);
    setOpenEdit(true);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  interface AddNewItemModal {
    store: BackgroundItemStore;
  }

  interface EditItemModal {
    store: BackgroundItemStore;
  }

  const NewItemModal = observer(({ store }: AddNewItemModal) => {
    return (
      <Modal
        className={modalClasses.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 100,
        }}
      >
        <div className={modalClasses.paper}>
          <NewItemForm store={store} handleClose={handleClose} subtitle={store.itemsMap[BANKRUPTCY_RECORD].name} record={BANKRUPTCY_RECORD} />
        </div>
      </Modal>
    );
  });

  const EditItemModal = observer(({ store }: EditItemModal) => {
    return (
      <Modal
        className={modalClasses.modal}
        open={openEdit}
        onClose={handleCloseEdit}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 100,
        }}
      >
        <div className={modalClasses.paper}>
          <NewItemForm store={store} handleClose={handleCloseEdit} subtitle={store.itemsMap[BANKRUPTCY_RECORD].name} index={indexToEdit} record={BANKRUPTCY_RECORD} />
        </div>
      </Modal>
    );
  });

  return (
    <Box style={{ paddingTop: '15px' }}>
      {<NewItemModal store={store} />}
      {<EditItemModal store={store} />}
      {store.isInternal && !store.readOnly &&
        <Box style={{ display: 'flex', justifyContent: 'right', marginBottom: '10px' }}>
          <Button
            variant="outlined"
            size="small"
            onClick={handleOpen}
            disabled={false}
            startIcon={<AddCircleOutlineIcon />}
            style={{ color: '#2199E0' }}
          >
            ADD NEW ITEM
          </Button>
        </Box>
      }
      <div className={classes.tableContainer}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell colSpan={columns.length} className={classes.titleCell}>
                <div className={classes.flexContainer}>
                  <Typography className={classes.title}>Bankruptcy Records</Typography>
                  {store.isInternal && !store.readOnly &&
                    <div>
                      <XmlCounterChip value={(store.backgroundSectionDetails.backgroundSectionCounter?.[store.itemsMap[BANKRUPTCY_RECORD].name] || 0)} />
                      <ItemsShownCounterChip value={store.backgroundSectionDetails.backgroundSection?.bankruptcyRecord?.length} />
                    </div>}
                </div>
              </TableCell>
            </TableRow>

            <TableRow>
              {columns.map(({ name, ...props }) => (
                <TableCell key={name} className={classes.headerCell} style={{ paddingLeft: '8px' }}>
                  {name}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody className={classes.editableRow}>
            {store.backgroundSectionDetails.backgroundSection?.bankruptcyRecord?.map((q, index) => {
              return (
                <TableRow key={index}>
                  <TableCell width={columns[0].width}>
                    <span className={classes.fixedWidthText} style={{ fontSize: '14px' }}>{q.item}</span>
                  </TableCell>
                  <TableCell width={columns[1].width}>
                    <span className={classes.fixedWidthText} style={{ fontSize: '14px' }}>
                      {q.itemDescription.split('\n').map((str, i) => <Fragment key={i}>{str}<br /></Fragment>)}
                    </span>
                  </TableCell>
                  <TableCell width={columns[2].width}>
                    <IconButton onClick={() => {
                      if (store.isInternal) {
                        updateRecordItem(index);
                      }
                    }}>
                      {getResolutionFlag(q)}
                    </IconButton>
                    {getInfoButton(q, () => openResolutionInfo(q))}
                  </TableCell>
                  {store.isInternal && !store.readOnly &&
                    <TableCell width={columns[3].width}>
                      <IconButton onClick={() => { handleOpenEdit(index) }}>
                        <EditIcon style={{ color: '#2199E0' }} />
                      </IconButton>
                      <IconButton onClick={() => { removeRecordItem(index) }}>
                        <DeleteIcon style={{ color: '#2199E0' }} />
                      </IconButton>
                    </TableCell>
                  }
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    </Box>
  );
});

export default BankruptcyRecord;