import { Service } from '@roc/feature-app-core';

const url = {
  EXPECTED_WIRE_RELEASE_DATE:
    '/api/v1/document/formDocument/updateExpectedWireReleaseDate',
};

export class ExpectedWireReleaseDateService extends Service {
  submitExpectedWireReleaseDate(
    loanId: string,
    expectedWireReleaseDate: string
  ) {
    return this.put(
      url.EXPECTED_WIRE_RELEASE_DATE +
      '?loanID=' +
      loanId +
      (expectedWireReleaseDate
        ? `&expectedWireReleaseDate=${expectedWireReleaseDate}`
        : ``),
      {}
    );
  }
}
