import { Box, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Layout } from '@roc/feature-app-core';
import { Paper } from '@roc/ui';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { BorrowerDetailsVerificationForm } from './borrowerDetailsVerificationForm';
import { useStore } from '@roc/client-portal-shared/hooks';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    padding: theme.spacing(3, 0, 4),
    borderRadius: theme.spacing(2),
  },
}));

export const BorrowerDetailsFormInternal = observer(({ token, isInternal }) => {
  const classes = useStyles();
  const { borrowerDetailsVerificationStore } = useStore();
  useEffect(() => {
    const fetchInformation = async () => {
      await borrowerDetailsVerificationStore.fetchFormInformation(token);
      await borrowerDetailsVerificationStore.isFeatureEnabledForBorrowerDetailsVerification('uwMatrixEnabled');
    }
    fetchInformation();
  }, []);

  return (
    <Layout maxWidth={'xl'}>
      <Box my={3}>
        <Typography variant="h3">
          {'Identity Verification Process'}
        </Typography>
      </Box>
      <Paper className={classes.paper}>
        <BorrowerDetailsVerificationForm isInternal={isInternal} store={borrowerDetailsVerificationStore} />
      </Paper>
    </Layout>
  );
});
