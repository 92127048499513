import { GlobalStore, FormStore } from '@roc/feature-app-core';
import { makeObservable, flow, observable, action } from 'mobx';
import { ProposalsService } from '../services/proposalsService';
import { SelectBorrowersStore } from '@roc/feature-borrowers';
import BorrowerFormStore from 'libs/feature-loans/src/loanSubmission/stores/fixFlip/borrowerFormStore';
import { Borrower } from '@roc/feature-types';
import { renovationDescriptionsByLoanSubtype } from '@roc/feature-loans';
import { SegmentTrackerEvent, formatDate, segmentAnalytics } from '@roc/feature-utils';
import { getUniqueId } from '@roc/feature-utils';
import PropertyStore from './propertyStore';
import { proposalStatus as statusProposal } from './../utils/constants';
import { LoanStatus, LoanSubType, LoanType, Utility } from '@roc/feature-utils';
import { ProposalsStore } from './proposalsStore';
import { LoanParticipantsStore } from '@roc/feature-loan-participants';


const REQUIRED_MESSAGE = 'This field is required';

//Step 2:  Loan Terms
const loanTermsForm = {
  fields: {
    initialLoanAmountPercentage: {
      value: 0,
      error: null,
      rule: 'required',
      message: REQUIRED_MESSAGE,
    },
    initialLoanAmount: {
      value: '',
      error: null,
      rule: 'required',
      message: REQUIRED_MESSAGE,
    },
    amount: {
      value: 0,
      error: null,
      rule: '',
      message: REQUIRED_MESSAGE,
    },
    constructionHoldbackPercentage: {
      value: 0,
      error: null,
      rule: 'required',
      message: REQUIRED_MESSAGE,
    },
    constructionHoldback: {
      value: '',
      error: null,
      rule: 'required',
      message: REQUIRED_MESSAGE,
    },
    rate: {
      value: '',
      error: null,
      rule: 'required',
      message: 'The interest rate is required',
    },
    pointsIn: {
      value: '',
      error: null,
      rule: 'required|numeric|min:0|max:6',
      message: 'Percentage must be between 0 and 6',
    },
    pointsOut: {
      value: '',
      error: null,
      rule: 'required|numeric|min:0|max:6',
      message: 'Percentage must be between 0 and 6',
    },
    duration: {
      value: '',
      error: null,
      rule: 'required',
      message: REQUIRED_MESSAGE,
    },
    projectDescription: {
      value: '',
      error: null,
      rule: 'required|min:50|max:500',
      message: '50 characters minimum',
    },
    lenderOriginatorId: {
      value: '',
      rule: '',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

const borrowerForm = {
  fields: {
    borrowerId: {
      value: null,
      error: null,
      rule: '',
    },
    firstName: {
      value: '',
      error: null,
      rule: 'required',
    },
    lastName: {
      value: '',
      error: null,
      rule: 'required',
    },
    cellPhone: {
      value: '',
      error: null,
      rule: '',
    },
    emailAddress: {
      value: '',
      error: null,
      rule: '',
    },
    ficoProvidedAtOrigination: {
      value: 680,
      error: null,
      rule: 'required',
    },
    experience: {
      value: 1,
      error: null,
      rule: 'required',
    },
    experienceComments: {
      value: '',
      error: null,
      rule: 'required',
    },
    leadSource: {
      value: '',
      error: null,
      rule: '',
    },
    leadSourceGroup: {
      value: '',
      error: null,
      rule: '',
    },
    leadSfId: {
      value: '',
      error: null,
      rule: '',
    },
    leadId: {
      value: null,
      error: null,
      rule: '',
    },
    netWorth: {
      value: '',
      error: null,
      rule: 'required',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

const appealForm = {
  fields: {
    appealDescription: {
      value: '',
      error: null,
      rule: 'required|min:50|max:500',
      message: '50 characters minimum',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

const proposalTopicForm = {
  fields: {
    type: {
      value: '',
      error: null,
      rule: 'required',
    },
    topic: {
      value: '',
      error: null,
      rule: 'required',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export class ProposalsDetailsStore {
  proposalId: string;
  propertyId: string;
  globalStore: GlobalStore;
  proposalsStore: ProposalsStore;
  proposalsService: ProposalsService;
  selectBorrowersStore: SelectBorrowersStore;
  loanParticipantsStore: LoanParticipantsStore;
  propertyAndProjectForm: FormStore;
  loanTermsForm: FormStore;
  borrowerFormStore: BorrowerFormStore;
  borrowerForm: FormStore;
  appealForm: FormStore;
  proposalTopicForm: FormStore;
  propertyStore: PropertyStore;
  proposal: any;
  proposalTopic: any;
  renovationDescriptionOptions: any[];
  properties: any[];
  activeStep: number = 0;
  isNewEntity: boolean;
  totalPurchasePrice;
  propertyDesc: string;
  isAppeal: boolean = false;
  proposalsDashboardUrl: string;
  loanSubtype: string;

  constructor(globalStore, proposalsStore) {
    this.globalStore = globalStore;
    this.proposalsStore = proposalsStore;
    this.proposalsService = new ProposalsService();

    this.loanTermsForm = new FormStore(loanTermsForm, globalStore);
    this.borrowerForm = new FormStore(borrowerForm, globalStore);
    this.borrowerFormStore = new BorrowerFormStore(this.globalStore);
    this.loanParticipantsStore = new LoanParticipantsStore(this.globalStore);
    this.selectBorrowersStore = new SelectBorrowersStore(
      this.globalStore,
      this.borrowerForm,
      true,
      true,
      x => this.handleBorrowerChange(x),
      undefined,
      () => new FormStore(borrowerForm, globalStore)
    );
    this.appealForm = new FormStore(appealForm, globalStore);
    this.propertyStore = new PropertyStore(this.globalStore);
    this.setDefaults();
    makeObservable(this, {
      proposalId: observable,
      propertyId: observable,
      proposal: observable,
      activeStep: observable,
      properties: observable,
      propertyDesc: observable,
      isAppeal: observable,
      goNextStep: action,
      goPrevStep: action,
      moveToStep: action,
      calculateProposal: action,
      changeInitialLoanToPurchase: action,
      changeConstructionHoldbackPercentage: action,
      calculateConstructionHoldbackCurrency: action,
      calculateConstructionHoldbackPercentage: action,
      calculateTotalRenovationBudget: action,
      changeConstructionHoldback: action,
      setProposalsDashboardUrl: action,
      setLoanSubtype: action,
      initForm: flow,
      loadParticipants: flow,
      saveProposal: flow,
      submitProposal: flow,
      getCalculatedValues: flow,
      getProperty: flow,
      resetProperty: action,
      addProperty: action,
      updateProperty: action,
      deleteProperty: action,
      updateProjectDescById: action,
      loadProposal: flow,
      setCustomError: action,
      triggerValidationMessage: action,
    });
  }

  private setDefaults() {
    this.properties = [];
    this.activeStep = 0;
    this.isNewEntity = false;
    this.proposalsDashboardUrl = '';
  }

  private handleBorrowerChange(borrowers: Borrower[]) {
    const borrower = borrowers[0] || null;
    this.borrowerForm.onFieldChange('firstName', borrower?.firstName);
    this.borrowerForm.onFieldChange('lastName', borrower?.lastName);
    this.borrowerForm.onFieldChange('leadSource', borrower?.leadSource);
    this.borrowerForm.onFieldChange(
      'leadSourceGroup',
      borrower?.leadSourceGroup
    );
    this.borrowerForm.onFieldChange('emailAddress', borrower?.emailAddress);
    this.borrowerForm.onFieldChange('cellPhone', borrower?.cellPhone);
    this.borrowerForm.onFieldChange('netWorth', borrower?.netWorth);
    this.borrowerForm.onFieldChange(
      'experienceComments',
      borrower?.experienceComments
    );
    this.borrowerForm.onFieldChange(
      'ficoProvidedAtOrigination',
      borrower?.ficoProvidedAtOrigination
    );
    this.borrowerForm.onFieldChange('borrowerId', borrower?.borrowerId);
  }

  *getCalculatedValues() {
    try {
      if (this.areFormsValid()) {
        const response = yield this.proposalsService.getCalculatedValues({
          ...this.proposal,
          proposalRequestId: this.proposalId,
        });
        const proposal = response.data?.data;
        this.proposal = proposal;
      }
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: `Error while getting calculated values for Proposal `,
      });
    }
  }

  *initForm(proposalId: string, loanSubtype: string) {
    this.resetStore();
    yield this.loadParticipants(proposalId);
    if (proposalId) {
      try {
        const response = yield this.proposalsService.getProposalById(
          proposalId
        );
        const proposal = response.data?.data;

        this.proposalId = proposalId;
        this.loanSubtype = proposal.loanSubtype;
        this.proposal = proposal;

        const borrowers = this.getBorrowers(proposal);
        this.properties = this.getProperties(proposal);
        this.setBorrowers(borrowers.filter(b => b.borrowerId));
        this.setNewBorrowers(borrowers.filter(b => !b.borrowerId));

        const {
          loanTermsFormData,
          appealFormData,
        } = this.buildFormDataFromProposal(proposal);
        this.loanTermsForm.loadForm(loanTermsFormData);
        this.appealForm.loadForm(appealFormData);
      } catch (error) {
        console.log('Err', error);
        this.globalStore.notificationStore.showErrorNotification({
          message: `Error while retrieving the proposal`,
        });
      }
    } else {
      this.loanSubtype = loanSubtype;
    }
    this.setRenovationDescriptionOptions(this.loanSubtype);
  }

  setRenovationDescriptionOptions(loanSubtype) {
    this.renovationDescriptionOptions = renovationDescriptionsByLoanSubtype(
      loanSubtype
    );
  }

  *loadParticipants(proposalId: string) {
    yield this.loanParticipantsStore.fetchLoanParticipants();
    const { userInformation } = this.proposalsStore.userStore;
    if (!proposalId) {
      if (this.globalStore.userFeatures.isLenderOriginator) {
        this.loanTermsForm.onFieldChange(
          'lenderOriginatorId',
          userInformation?.userId
        );
      }
    }
  }

  *saveProposal() {
    try {
      if (this.areFormsValid()) {
        let propStatus = statusProposal.DRAFT;

        this.proposal = this.buildProposalFromFormValues(
          this.proposalId,
          this.getFormsValues()
        );
        const response = yield this.proposalsService.saveProposal({
          ...this.proposal,
          proposalId: this.proposalId,
          proposalStatus: propStatus,
        });
        if (this.isAppeal) {
          this.loadProposal(this.proposalId);
        } else {
          this.proposal = response.data?.data;
          this.proposalId = this.proposal?.proposalId;
        }
        if (!this.proposalId) {
          this.proposalId = this.proposal?.proposalId;
        }
        this.globalStore.notificationStore.showSuccessNotification({
          message: `Proposal saved successfully`,
        });
      }
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: `Error while submitting Proposal`,
      });
    }
  }

  *submitProposal() {
    try {
      if (this.areFormsValid()) {
        this.proposal = this.buildProposalFromFormValues(
          this.proposalId,
          this.getFormsValues()
        );
        const response = yield this.proposalsService.submitProposal({
          ...this.proposal,
          proposalId: this.proposalId,
          proposalStatus: statusProposal.NEW,
        });
        this.proposal = response.data?.data;
        if (!this.proposalId) {
          this.proposalId = this.proposal.proposalId;
        }
        this.globalStore.notificationStore.showSuccessNotification({
          message: `Proposal saved successfully`,
        });
        this.proposalsStore.proposalsGridStore.resetAndFetchGridData();
        this.globalStore.history.push(this.proposalsDashboardUrl);
        segmentAnalytics.trackEvent({
          name: SegmentTrackerEvent.PROPOSAL_SUBMISSION,
          userName: this.proposalsStore.userStore.userInformation.username,
        });
      }
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: `Error while submitting Proposal`,
      });
    }
  }

  goNextStep() {
    this.moveToStep(this.activeStep + 1);
  }

  goPrevStep() {
    this.moveToStep(this.activeStep - 1);
  }

  calculateProposal() {
    this.proposal = this.buildProposalFromFormValues(
      this.proposalId,
      this.getFormsValues()
    );
  }

  moveToStep(nextStep: number) {
    let addStep = 0;
    if (this.isAppeal) {
      addStep = 1;
      if (this.activeStep === 0) {
        this.appealForm.runFormValidationWithMessage();
        if (this.appealForm.form.meta.isValid === false) {
          return;
        }
      }
    }
    switch (this.activeStep) {
      case 0 + addStep:
        if (this.selectBorrowersStore.borrowers.length === 0) {
          this.globalStore.notificationStore.showErrorNotification({
            message: 'Please add at least one borrower',
          });
          return;
        }
        break;
      case 1 + addStep:
        if (this.properties.length === 0) {
          this.globalStore.notificationStore.showErrorNotification({
            message: 'Please add at least one property',
          });
          return;
        }
        break;
      case 2 + addStep:
        this.loanTermsForm.runFormValidationWithMessage();
        if (!this.loanTermsForm.form.meta.isValid) {
          return;
        }
    }

    if (nextStep === 3 + addStep) {
      this.calculateProposal();
      this.saveProposal();
    }
    this.activeStep = nextStep;
  }

  changeInitialLoanToPurchase() {
    this.calculateInitialLoanAmount();
    this.calculateTotalLoanAmount();
  }
  private calculateTotalLoanAmount() {
    this.loanTermsForm.form.fields.amount.value =
      this.loanTermsForm.form.fields.initialLoanAmount.value +
      this.loanTermsForm.form.fields.constructionHoldback.value;
  }

  private calculateInitialLoanAmount() {
    const initialPercentage = this.loanTermsForm.form.fields
      .initialLoanAmountPercentage.value;
    const totalPurchase = this.calculateTotalPurchasePrice();
    let initialLoanAmount = 0;
    if (totalPurchase > 0 && initialPercentage > 0) {
      initialLoanAmount = totalPurchase * (initialPercentage / 100);
    }
    this.loanTermsForm.form.fields.initialLoanAmount.value = initialLoanAmount;
  }

  calculateConstructionHoldbackCurrency() {
    const { constructionHoldbackPercentage } = this.loanTermsForm.form.fields;
    const renovationBudget = this.calculateTotalRenovationBudget();
    const value =
      renovationBudget * (constructionHoldbackPercentage.value / 100);
    this.loanTermsForm.onFieldChange('constructionHoldback', value);
  }
  calculateConstructionHoldbackPercentage() {
    const constructionHoldback = this.loanTermsForm.form.fields
      .constructionHoldback.value;
    let constructionHoldbackPercentage = 0;
    const totalRenovation = this.calculateTotalRenovationBudget() || 0;
    if (totalRenovation > 0 && constructionHoldback > 0) {
      constructionHoldbackPercentage =
        (constructionHoldback * 100) / totalRenovation;
    }
    this.loanTermsForm.form.fields.constructionHoldbackPercentage.value = Math.round(
      constructionHoldbackPercentage
    );
  }

  private getFormsValues() {
    return {
      ...this.loanTermsForm.getFormValues(),
      ...this.propertyStore.propertyForm.getFormValues(),
      ...this.borrowerFormStore.getFormValues(),
      ...this.appealForm.getFormValues(),
    };
  }

  resetStore = () => {
    this.loanTermsForm.reset();
    this.selectBorrowersStore.reset();
    this.selectBorrowersStore.reset();
    this.activeStep = 0;
    this.proposal = null;
    this.proposalId = null;
    this.propertyStore.reset();
    this.properties = [];
    this.isAppeal = false;
    this.appealForm.reset();
  };

  private areFormsValid() {
    this.loanTermsForm.runFormValidationWithMessage();
    return this.loanTermsForm.form.meta.isValid;
  }

  buildProposalFromFormValues = (proposalId, formValues) => {
    const values = {
      ...formValues,
      purchaseDate: formatDate(formValues.acquisitionDate, 'MM/dd/yyyy'),
      pointsIn: parseFloat(formValues.pointsIn),
      pointsOut: parseFloat(formValues.pointsOut),
      rate: parseFloat(formValues.rate),
      duration: parseFloat(formValues.duration),
    };
    const proposal = {
      ...this.proposal,
      proposalId,
      appealDescription: values.appealDescription,
      currentLender: values.currentLender,
      projectDescription: values.projectDescription,
      initialLoanAmount: values.initialLoanAmount,
      constructionHoldback: values.constructionHoldback,
      rate: values.rate,
      amount: values.amount,
      pointsIn: values.pointsIn,
      pointsOut: values.pointsOut,
      duration: values.duration,
      payoffAmount: values.payoffAmount,
      marketRent: values.marketRent,
      inPlaceRent: values.inPlaceRent,
      lenderOriginatorId: values.lenderOriginatorId,
      loanSubtype: this.loanSubtype,
      loanType: LoanType.RESIDENTIAL_BRIDGE,
      utility: Utility.PROPOSAL_INFORMATION,
      status: LoanStatus.PENDING,
      propertiesMap: {
        rows: [...this.propertiesRows],
      },
      loanBorrowerInformation: {
        loanBorrowers: {
          rows: [...this.borrowersRows],
        },
      },
    };
    return proposal;
  };

  buildFormDataFromProposal = proposal => {
    const loanTermsFormData = {
      initialLoanAmountPercentage: this.initInitialLoanToPurchase(
        proposal.initialLoanAmount
      ),
      initialLoanAmount: proposal.initialLoanAmount,
      constructionHoldbackPercentage: this.initConstructionHoldbackPercentage(
        proposal.constructionHoldback
      ),
      constructionHoldback: proposal.constructionHoldback,
      rate: proposal.rate,
      pointsIn: proposal.pointsIn,
      pointsOut: proposal.pointsOut,
      duration: proposal.duration,
      projectDescription: proposal.projectDescription,
      amount: proposal.initialLoanAmount + proposal.constructionHoldback,
      status: LoanStatus.PENDING,
      lenderOriginatorId: proposal.lenderOriginatorId,
    };

    const appealFormData = {
      appealDescription: proposal.appealDescription,
    };

    return {
      loanTermsFormData,
      appealFormData,
    };
  };

  initConstructionHoldbackPercentage(constructionHoldback) {
    let constructionHoldbackPercentage = 0;
    const totalRenovation = this.calculateTotalRenovationBudget() || 0;
    if (totalRenovation > 0 && constructionHoldback > 0) {
      constructionHoldbackPercentage =
        (constructionHoldback * 100) / totalRenovation;
    }
    return Math.round(constructionHoldbackPercentage);
  }

  private initInitialLoanToPurchase(initialLoanAmount) {
    const totalPurchase = this.calculateTotalPurchasePrice();
    let initialLoanToPurchase = 0;
    if (totalPurchase > 0 && initialLoanAmount > 0) {
      initialLoanToPurchase = (initialLoanAmount * 100) / totalPurchase;
    }
    return Math.round(initialLoanToPurchase);
  }

  get borrowersRows() {
    const defaultFormValues = this.borrowerFormStore.getFormValues();

    return this.selectBorrowersStore.borrowers.map(borrower => {
      let { borrowerId } = borrower;

      if (typeof borrowerId === 'string' && borrowerId.includes('LEAD')) {
        borrowerId = null;
      }

      return {
        borrowerId,
        firstName: borrower.firstName,
        lastName: borrower.lastName,
        fullName: `${borrower.firstName} ${borrower.lastName}`,
        emailAddress: borrower.emailAddress,
        cellPhone: borrower.cellPhone,
        experience: borrower.experience ?? defaultFormValues.experience,
        leadSfId: borrower.leadSfId,
        leadId: borrower.leadId,
        leadSource: borrower.leadSource,
        leadSourceGroup: borrower.leadSourceGroup,
        netWorth: borrower.netWorth,
        ficoProvidedAtOrigination: borrower.ficoProvidedAtOrigination,
        experienceComments: borrower.experienceComments,
      };
    });
  }

  getBorrowers = draftLoan => {
    const {
      loanBorrowerInformation: {
        loanBorrowers: { rows },
      },
    } = draftLoan;

    const borrowerNotEmpty = borrower => {
      return borrower.firstName !== '' || borrower.lastName !== '';
    };

    return rows.filter(borrowerNotEmpty).map(borrower => ({
      ...borrower,
      firstName: borrower.firstName,
      lastName: borrower.lastName,
      leadSource: borrower.leadSource,
      leadSourceGroup: borrower.leadSourceGroup,
      cellPhone: borrower.cellPhone,
      ficoProvidedAtOrigination: borrower.ficoProvidedAtOrigination,
      netWorth: borrower.netWorth,
      experience: borrower.experience,
      experienceComments: borrower.experienceComments,
      emailAddress: borrower.emailAddress,
      borrower: borrower.borrowerId,
    }));
  };

  setBorrowers(borrowers) {
    this.selectBorrowersStore.setExistingBorrowersFromExternal([...borrowers]);
  }

  setNewBorrowers(borrowers) {
    this.selectBorrowersStore.setNewBorrowersFromExternal([...borrowers]);
  }

  addProperty = property => {
    this.propertyStore.propertyForm.runFormValidationWithMessage();
    if (this.propertyStore.propertyForm.form.meta.isValid) {
      this.properties.push(property);
    }
  };

  updateProperty = updatedProperty => {
    this.properties = this.properties.map(property => {
      if (property.propertyId == updatedProperty.propertyId) {
        return updatedProperty;
      }
      return property;
    });
  };

  updateProjectDescById = id => {
    this.properties = this.properties.map(property => {
      if (property.propertyId == id) {
        this.propertyDesc == property.propertyDescription;
      }
    });
  };

  deleteProperty = (propertyId: string) => {
    this.properties = this.properties.filter(x => x.propertyId !== propertyId);
    if (!this.properties.length) {
      this.propertyStore.reset();
    }
  };

  getProperty() {
    const values = this.propertyAndProjectForm.getFormValues();
    return {
      property: {
        propertyId: this.propertyId ?? getUniqueId(),
        address: values.address,
        streetNumber: values.streetNumber,
        streetName: values.streetName,
        city: values.city,
        state: values.state,
        zipCode: values.zipCode,
        latitude: values.latitude,
        longitude: values.longitude,
        propertySourcingExplanation: values.propertySourcingExplanation,
        purchasePrice: values.acquisitionPrice,
        purchaseDate: values.acquisitionDate
          ? formatDate(values.acquisitionDate, 'MM/dd/yyyy')
          : null,
        sunkCost: values.sunkCost,
        exitStrategy: values.exitStrategy,
        renovationBudget: values.renovationBudget,
        asIsValue: values.asIsValue,
        afterRepairValue: values.afterRepairValue,
        describeRenovation: values.describeRenovation,
        wholesaleAmount: values.wholesaleAmount,
        wholesalerPurchase: values.wholesalerPurchase,
        propertySourcing: values.propertySourcing,
        propertyOwnership: values.acquisition,
        monthlyRent: values.inPlaceRent,
        marketRent: values.marketRent,
      },
    };
  }
  setPropertyId(propertyId) {
    this.propertyId = propertyId;
  }

  resetProperty() {
    this.propertyAndProjectForm.reset();
    this.propertyId = null;
  }

  getProperties = draftLoan => {
    const {
      propertiesMap: { rows },
    } = draftLoan;

    return rows.map(propertyValues => ({
      ...propertyValues,
      propertyId: propertyValues.propertyId ?? getUniqueId(),
      address: propertyValues.address,
      streetNumber: propertyValues.streetNumber,
      streetName: propertyValues.streetName,
      city: propertyValues.city,
      state: propertyValues.state,
      zipcode: propertyValues.zipcode,
      acquisition: propertyValues.propertyOwnership,
      acquisitionPrice: propertyValues.purchasePrice,
      acquisitionDate: propertyValues.purchaseDate,
      sunkCost: propertyValues.sunkCost,
      exitStrategy: propertyValues.exitStrategy,
      renovationBudget: propertyValues.renovationBudget,
      describeRenovation: propertyValues.describeRenovation,
      asIsValue: propertyValues.asIsValue,
      afterRepairValue: propertyValues.afterRepairValue,
      propertyDescription: propertyValues.propertyDescription,
      addingSquareFootage: propertyValues.addingSquareFootage,
      wholesaleAmount: propertyValues.wholesaleAmount,
      wholesalerPurchase: propertyValues.wholesalerPurchase,
      propertySourcingExplanation: propertyValues.propertySourcingExplanation,
      propertySourcing: propertyValues.propertySourcing,
      payoffAmount: propertyValues.payoffAmount,
      marketRent: propertyValues.marketRent,
      inPlaceRent: propertyValues.monthlyRent,
    }));
  };

  changeInitialLoanAmount() {
    this.calculateInitialLoanToPurchase();
    this.calculateTotalLoanAmount();
  }

  calculateTotalPurchasePrice() {
    const properties = this.properties;
    let total = 0;
    let purchasePrice = 0;
    let renoRemaining = 0;
    if (properties.length > 0) {
      properties.forEach(value => {
        if (value.acquisition === 'Purchase') {
          purchasePrice = isNaN(Number(value.acquisitionPrice))
            ? 0
            : Number(value.acquisitionPrice);
          total += purchasePrice;
        } else {
          purchasePrice = isNaN(Number(value.acquisitionPrice))
            ? 0
            : Number(value.acquisitionPrice);
          renoRemaining = isNaN(Number(value.sunkCost))
            ? 0
            : Number(value.sunkCost);
          total += purchasePrice + renoRemaining;
        }
      });
    }
    this.totalPurchasePrice = total;
    return total;
  }

  changeConstructionHoldbackPercentage() {
    this.calculateConstructionHoldback();
    this.calculateTotalLoanAmount();
  }

  changeConstructionHoldback() {
    this.calculateConstructionHoldbackPercentage();
    this.calculateTotalLoanAmount();
  }

  private calculateInitialLoanToPurchase() {
    const initialLoanAmount = this.loanTermsForm.form.fields.initialLoanAmount
      .value;
    const totalPurchase = this.calculateTotalPurchasePrice();
    let initialLoanToPurchase = 0;
    if (totalPurchase > 0 && initialLoanAmount > 0) {
      initialLoanToPurchase = (initialLoanAmount * 100) / totalPurchase;
    }
    this.loanTermsForm.form.fields.initialLoanAmountPercentage.value = Math.round(
      initialLoanToPurchase
    );
  }

  private calculateConstructionHoldback() {
    const totalRenovation = this.calculateTotalRenovationBudget() || 0;
    let constructionHoldbackPercentage = 0;
    if (
      totalRenovation > 0 &&
      this.loanTermsForm.form.fields.constructionHoldbackPercentage.value > 0
    ) {
      constructionHoldbackPercentage =
        totalRenovation *
        (this.loanTermsForm.form.fields.constructionHoldbackPercentage.value /
          100);
    }
    this.loanTermsForm.form.fields.constructionHoldback.value = constructionHoldbackPercentage;
  }

  calculateTotalRenovationBudget() {
    let total = 0;
    let renoBudget = 0;
    const properties = this.properties;
    if (properties.length > 0) {
      properties.forEach(value => {
        renoBudget = isNaN(Number(value.renovationBudget))
          ? 0
          : Number(value.renovationBudget);

        total += renoBudget;
      });
    }
    return total;
  }

  get propertiesRows() {
    return this.properties.map(property => ({
      propertyId: null,
      address: property.address,
      streetNumber: property.streetNumber,
      streetName: property.streetName,
      aptNumber: property.aptNumber,
      city: property.city,
      state: property.state,
      zipCode: property.zipCode,
      latitude: property.latitude,
      longitude: property.longitude,
      exitStrategy: property.exitStrategy,
      propertyOwnership: property.acquisition,
      purchasePrice: property.acquisitionPrice,
      purchaseDate: property.acquisitionDate
        ? formatDate(property.acquisitionDate, 'MM/dd/yyyy')
        : null,
      renovationBudget: property.renovationBudget,
      describeRenovation: property.describeRenovation,
      useCode: property.useCode,
      asIsValue: property.asIsValue,
      afterRepairValue: property.afterRepairValue,
      propertySourcingExplanation: property.propertySourcingExplanation,
      wholesaleAmount: property.wholesaleAmount,
      wholesalerPurchase: property.wholesalerPurchase,
      sunkCost: property.sunkCost || 0,
      propertySourcing: property.propertySourcing,
      monthlyRent: property.inPlaceRent,
      marketRent: property.marketRent,
      payoffAmount: property.payoffAmount,
      propertyDescription: property.propertyDescription,
      addingSquareFootage: property.addingSquareFootage,
    }));
  }

  setProposalsDashboardUrl(proposalsDashboardUrl) {
    this.proposalsDashboardUrl = proposalsDashboardUrl;
  }

  setLoanSubtype(subtype: string) {
    this.loanSubtype = subtype;
  }

  *loadProposal(proposalId) {
    try {
      if (this.isAppeal) {
        const response = yield this.proposalsService.getProposalById(
          proposalId
        );
        this.proposal = response.data?.data;
      }
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: `Error while submitting Proposal`,
      });
    }
  }

  setCustomError(fieldId, error) {
    if (this.propertyStore.propertyForm.form.fields[fieldId]) {
      this.propertyStore.propertyForm.form.fields[fieldId].error = error;
    }
  }

  triggerValidationMessage() {
    if (this.propertyStore.propertyForm.form.meta.isValid) {
      this.globalStore.notificationStore.showWarningNotification({
        message: 'Please fix the validation errors.',
      });
    }
  }
}
