import { useBaseStore } from '@roc/feature-app-core';
import { getLoanRoutesConfig } from './../routes/loanRoutesConfig';

export const useLoanRoutes = () => {
  const { globalStore } = useBaseStore();
  const { routeBasePath, routeBaseUrl } = globalStore;

  return {
    loanRoutesConfig: getLoanRoutesConfig(routeBasePath, routeBaseUrl),
  };
};
