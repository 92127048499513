import { Box, Grid, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { MoreVert, CloudDownload } from '@material-ui/icons';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { DollarColumn, IconButton, Tabs } from '@roc/ui';
import {
  GridColumnProps,
} from '@roc/ui';
import { Layout, useBaseStore } from '@roc/feature-app-core';
import { FilterButton, DropdownMenu } from '@roc/ui';
import { AvailableListingGrid } from './components/availableListingGrid';
import { LinkColumn } from '@roc/ui';
import { formatDateIgnoreTimezone } from '@roc/feature-utils';
import DownloadableProperty from './components/donwloadableProperty';
import { useRef } from 'react';
import { DownloadablePropertytRef } from './components/donwloadableProperty';
import { useProspectPropertiesRoutes } from './hooks/useProspectPropertiesRoutes';
import ViewListIcon from '@material-ui/icons/ViewList';
import ViewComfyIcon from '@material-ui/icons/ViewComfy';
import { Alert } from '@material-ui/lab';
import { ListingCard } from './components/ListingCard';
import { PropertyModal } from './components/sellPropertyModal';
import { VerticalBarLoader } from '@roc/ui';



const filter = {
  filter: 'agTextColumnFilter',
  floatingFilter: true,
  floatingFilterComponentParams: {
    suppressFilterButton: true,
  },
};

const TABS = [
  {
    label: 'AVAILABLE',
  },
  // {
  //   label: 'MY PROPERTIES',
  // }
];

const availableColumns: GridColumnProps[] = [
  {
    field: 'address',
    headerName: 'Address',
    minWidth: 250,
    cellRenderer: 'addressCellRenderer',
    ...filter
  },
  {
    field: 'city',
    headerName: 'City',
    minWidth: 125,
    cellRenderer: 'propertyCellRenderer',
    ...filter,
  },
  {
    field: 'state',
    headerName: 'State',
    minWidth: 100,
    cellRenderer: 'propertyCellRenderer',
    ...filter,
  },
  {
    field: 'zipCode',
    headerName: 'Zip Code',
    minWidth: 100,
    cellRenderer: 'propertyCellRenderer',
    ...filter,
  },
  {
    field: 'propertyType',
    headerName: 'Property Type',
    cellRenderer: 'propertyCellRenderer',
    minWidth: 125,
    ...filter
  },
  {
    field: 'askingPrice',
    headerName: 'Asking Price',
    minWidth: 125,
    cellRenderer: 'moneyRenderer',
  },
  {
    field: 'fixFlipValuation.estimatedRenoBudget',
    headerName: 'Est. Reno Cost',
    minWidth: 150,
    cellRenderer: 'moneyRenderer',
  },
  {
    field: 'fixFlipValuation.estimatedArv',
    headerName: 'Est. ARV',
    minWidth: 125,
    cellRenderer: 'moneyRenderer',
  },
  {
    field: 'squareFootage',
    headerName: 'Est. Sq. Ft.',
    minWidth: 125,
    cellRenderer: 'propertyCellRenderer',
  },
  {
    field: 'potentialExitStrategy',
    headerName: 'Suggested Exit Strategy',
    cellRenderer: 'propertyCellRenderer',
    minWidth: 200,
    ...filter
  },
  {
    field: 'availableUntil',
    headerName: 'Available Until',
    minWidth: 150,
    cellRenderer: 'dateCellRenderer',
  },
  {
    field: 'sfID',
    headerName: '',
    minWidth: 100,
    cellRenderer: 'actionCellRenderer',
  }
];

const useStyles = makeStyles(theme => ({
  alert: {
    margin: theme.spacing(3),
    marginBottom: theme.spacing(0),
  },
  selectedView: {
    backgroundColor: theme.palette.primary.light,
    width: 44,
    height: 44,
    marginRight: 4,
  },
  iconButton: {
    width: 44,
    height: 44,
    marginRight: 4,
  }
}));

export const ProspectPropertiesDashboard = observer(({ store }) => {
  const { availablePropertiesGridStore, myPropertiesGridStore } = store;

  const downloadablePropertyRef = useRef<DownloadablePropertytRef>();

  const classes = useStyles();

  useEffect(() => {
    availablePropertiesGridStore.resetAndFetchGridData();
    //myPropertiesGridStore.resetAndFetchGridData();
    store.setSelectedTabIndex(0);
  }, []);

  const getPropertyActions = (prospectProperty) => {
    const actionList = [];
    actionList.push({
      icon: CloudDownload,
      name: 'Download Document',
      action: () => { downloadProperty(prospectProperty) }
    });
    return actionList;
  }

  const renderTabContent = () => {
    const history = useHistory();
    const { globalStore } = useBaseStore();
    const { prospectPropertiesRoutesConfig } = useProspectPropertiesRoutes();

    switch (store.selectedTabIndex) {
      case 0:
        return globalStore.isListView ?
          store.isCardView ?
            (
              <>
                {toolbar}
                <Grid container spacing={6} style={{
                  width: '100%',
                  margin: 0,
                }}>
                  {store?.propertiesForCardView?.map((property) => {
                    return (<Grid item xs={12} md={6} sm={6} lg={3}>
                      <ListingCard property={property} onClick={() => { history.push(prospectPropertiesRoutesConfig.propertyDetails(property?.id, property?.internalPropertyType).url); }} isComp={false} />
                    </Grid>)
                  })}
                </Grid>
              </>
            ) : (
              <AvailableListingGrid
                availablePropertiesGridStore={availablePropertiesGridStore}
                toolbar={toolbar}
                onSortChanged={onSortChanged}
                onFilterChanged={onFilterChanged}
                frameworkComponents={frameworkComponents}
                columns={availableColumns}
              />
            ) : null;
      case 1:
        return globalStore.isListView ?
          store.isCardView ?
            (<>
              {toolbar}
              <Grid container spacing={6} style={{
                width: '100%',
                margin: 0,
              }}>
                {store?.propertiesForCardView?.map((property) => {
                  return (<Grid item xs={12} sm={3} lg={3} xl={3}>
                    <ListingCard property={property} onClick={() => { history.push(prospectPropertiesRoutesConfig.propertyDetails(property?.id, property?.internalPropertyType).url); }} isComp={false} />
                  </Grid>)
                })}
              </Grid>
            </>
            ) : (
              // <AvailableListingGrid
              //   availablePropertiesGridStore={myPropertiesGridStore}
              //   toolbar={toolbar}
              //   onSortChanged={onSortChanged}
              //   onFilterChanged={onFilterChanged}
              //   frameworkComponents={frameworkComponents}
              //   columns={availableColumns}
              // />
              null
            ) : null;
      default:
        console.error('Invalid tab index');
        break;
    }
  };

  const toolbar = (
    <Tabs
      tabs={TABS}
      selectedTab={store.selectedTabIndex}
      onTabChange={store.onTabChange}
      right={
        <Box py={1} pr={2} display="flex" flexDirection="row" alignItems="center">
          {/* <Box pr={2}>
            <Button size="small"
              variant="contained"
              aria-label="split button"
              disableElevation
              color="primary"
              onClick={() => { store.openAddNewProspectPropertyDialog() }}>
              + Sell New Property
            </Button>
          </Box> */}
          <Box pr={2}>
            <FilterButton
              onToggleFilters={() => { availablePropertiesGridStore.toggleFilters(); }}
              onClearFilters={() => { availablePropertiesGridStore.resetFilters(); }}
            />
          </Box>
          <Box pr={2}>
            <IconButton
              aria-label="card"
              className={store.isCardView ? classes.selectedView : classes.iconButton}
              onClick={() => store.isCardView = true}
              testId="cardView"
            >
              <ViewComfyIcon
                fontSize="large"
                style={{ cursor: 'pointer' }} />
            </IconButton>
            <IconButton
              aria-selected={true}
              aria-label="list"
              className={!store.isCardView ? classes.selectedView : classes.iconButton}
              onClick={() => store.isCardView = false}
              testId="listView"
            >
              <ViewListIcon
                fontSize="large"
                style={{ cursor: 'pointer' }} />
            </IconButton>
          </Box>
        </Box>
      }
    />
  );

  const downloadProperty = (prospectProperty) => {
    store.fetchProspectProperty(prospectProperty.sfID, prospectProperty.internalPropertyType).then(() => { downloadablePropertyRef.current.downloadAsPdf(prospectProperty) });
  };

  const frameworkComponents = {
    addressCellRenderer(params) {
      const { prospectPropertiesRoutesConfig } = useProspectPropertiesRoutes();
      return LinkColumn({
        ...params,
        url: prospectPropertiesRoutesConfig.propertyDetails(params.data?.id, params.data?.internalPropertyType).url,
      })
    },
    moneyRenderer(params) {
      return DollarColumn(
        params.value
      )
    },
    dateCellRenderer(params) {
      return params.value
        ? formatDateIgnoreTimezone(params.value)
        : '--';
    },
    propertyCellRenderer(params) {
      return params.value
        ? params.value
        : '--';
    },
    actionCellRenderer(params) {
      const prospectProperty = params.node.data;
      return (
        <>
          <DropdownMenu options={getPropertyActions(prospectProperty)}>
            <MoreVert />
          </DropdownMenu>
        </>
      );
    },
  };

  const onFilterChanged = params => {
    const filterModel = params.api.getFilterModel();
    availablePropertiesGridStore.setFilterModel(filterModel);
    // myPropertiesGridStore.setFilterModel(filterModel);
  };

  const onSortChanged = params => {
    const sortModel = params.api.getSortModel();
    availablePropertiesGridStore.setSortModel(sortModel);
    //myPropertiesGridStore.setSortModel(sortModel);
  };

  return (
    <Box>
      <Alert severity="info" className={classes.alert}>
        <Typography gutterBottom>
          <strong>Introducing our newest sister brand, Really!</strong>
          <br />
          Really sources "As-Is" properties, offering them exclusively to our network of investors. Here's our current list of active Really properties, which are updated daily. We have purchase contracts at the asking prices listed below and are prepared to assign them to your borrowers. Please review and share with your borrowers. To submit offers, request a walkthrough, or share feedback contact the Really Team directly. You can contact Chad Belcher at <strong>407-951-2423</strong> or <strong>chad.belcher@really.homes</strong> with any questions or concerns.
        </Typography>
      </Alert>

      <Layout title='Off Market Properties'>
        {renderTabContent()}
        {store.isLoadingProperties && <VerticalBarLoader />}
      </Layout>
      <PropertyModal store={store} />
      <DownloadableProperty {...store} ref={downloadablePropertyRef}
      />
    </Box>
  );
});
