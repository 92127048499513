import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { SelectField } from '@roc/ui';

import {
  FileUploadModal,
  FileUpload,
} from '@roc/ui';
import { observer } from 'mobx-react';
import { useGeneralTasksStore } from '../../hooks/useGeneralTasksStore';

export const AddAttachmentDialog = observer(() => {
  const { generalTasksStore } = useGeneralTasksStore();
  const { editTaskStore, assigneeOptions } = generalTasksStore;
  const { attachmentStore } = editTaskStore;

  const handleUpload = (uploads: FileUpload[]) => {
    attachmentStore.addAttachments(uploads);
  };

  return (
    <FileUploadModal
      title="Add Attachment"
      open={attachmentStore.showAttachmentsDialog}
      multiple={true}
      onClose={() => attachmentStore.closeAttachmentDialog()}
      onUpload={handleUpload}
    />
  );
});
