import {
  Box,
  Grid,
  makeStyles,
  TextField as MuiTextField,
  Typography,
} from '@material-ui/core';
import { TitleDivider } from '../../../../components/titleDivider';
import {
  createRadioField,
  createSelectField,
  createDateField,
} from '../../../../components/temp/componentBuiderTemp';
import { Button, trueFalseOptions } from '@roc/ui';
import { observer } from 'mobx-react';
import { EditControls } from '../../../../components/editControls';
import { ItemDisplay } from '../../../../components/itemDisplay';
import { ClosingFormStore } from '../../../../stores/closing/closingFormStore';
import {
  FORM_MAX_WIDTH,
  participationOptions,
  participationPercetangeOptions,
} from '../../../../utils/constants';
import {
  insuranceOptions,
  titleOptionsAllowedWimba,
  titleOptionsQuote,
  parseDate,
  OTHER,
  ELMSURE,
} from '@roc/client-portal-shared/utils';
import { RoundedBackgroundIcon } from 'libs/feature-one-tool/src/quote/components/roundedBackgroundIcon';
import { Add, UpdateOutlined } from '@material-ui/icons';
import { ClosingStore } from 'libs/feature-one-tool/src/quote/stores/closing/closingStore';
import { useState } from 'react';
import { OtherInsuranceDialog } from './otherInsuranceDialog';
import { QuoteTypeStore } from 'libs/feature-one-tool/src/quote/stores/quoteTypeStore';
import { OtherTitleDialog } from './otherTitleDialog';
import { Originator } from 'libs/feature-one-tool/src/quote/components/originatorField';
import { useEffect } from 'react';
import { useStore } from '@roc/client-portal-shared/hooks';
import { FastTrackWarning } from './fastTrackWarning';

const useStyles = makeStyles(theme => ({
  infoMessage: {
    fontStyle: 'italic',
    marginTop: '0rem',
    marginLeft: '1rem',
    color: 'gray',
    fontSize: '12px',
  },
  autocomplete: {
    '& .MuiAutocomplete-endAdornment': {
      display: 'none',
    },
  },
}));

interface ClosingFormProps {
  quoteTypeStore: QuoteTypeStore;
  store: ClosingFormStore;
  closingStore: ClosingStore;
  allowParticipation: boolean;
}

export const ClosingForm = observer(
  ({
    quoteTypeStore,
    store,
    closingStore,
    allowParticipation,
  }: ClosingFormProps) => {
    const { globalStore } = useStore();

    const fastTrack = !!store.form.fields.fastTrack.value;
    const filteredTitleOptions = titleOptionsQuote.filter(option => !!option.value);
    const { allowOriginatorSelection } = store.loanOriginatorStore;
    const preferredInsurance = store.form.fields.preferredInsurance.value;
    const preferredTitle = store.form.fields.preferredTitle.value;
    const propertiesQuantity = quoteTypeStore.propertiesStore.getProperties()
      .length;

    useEffect(() => {
      store.loanOriginatorStore.loadOriginators();
      if (!store.form.fields.submitAppraisalWithTamarisk.value) {
        store.onFieldChange(
          'submitAppraisalWithTamarisk',
          globalStore.lenderInfo?.tamariskDefaultOption
        );
      }
    }, []);

    useEffect(() => {
      if (!store.form.fields.preferredInsurance.value) {
        store.onFieldChange('preferredInsurance', ELMSURE);
      }
    }, [store.form.fields.preferredInsurance]);

    return (
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item container spacing={2} style={{ maxWidth: FORM_MAX_WIDTH }}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xs={6}>
              <Grid
                container
                spacing={2}
                justifyContent="flex-start"
                alignItems="center"
              >
                <Grid item alignItems="center" style={{ height: '100%' }}>
                  <RoundedBackgroundIcon
                    Icon={UpdateOutlined}
                    iconColor="#6D6D6D"
                  />
                </Grid>
                <Grid item>
                  <Typography variant="h5">Closing</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ marginBottom: 10, marginTop: 10 }}>
            <TitleDivider title="Closing Details" />
          </Grid>
          <Grid item xs={12} sm={6}>
            {createSelectField({
              store,
              testId: 'preferredClosingAttorney',
              fieldName: 'preferredClosingAttorney',
              label: 'Preferred Closing Attorney',
              options: closingStore.attorneyOptions,
              standaloneLabel: false,
              addMarkForRequiredFields: true,
              isRequired: true,
            })}
          </Grid>
          <Grid item xs={12} sm={6}>
            {store.form.fields.preferredInsurance.value && createSelectField({
              store,
              testId: 'preferredInsurance',
              fieldName: 'preferredInsurance',
              label: 'Preferred Insurance Company',
              options: insuranceOptions,
              standaloneLabel: false,
              addMarkForRequiredFields: true,
              isRequired: true,
            })}
            {preferredInsurance === OTHER && (
              <Box pt={1} pb={1}>
                <Button
                  testId="add-insurance-info"
                  variant="contained"
                  size="small"
                  color="secondary"
                  onClick={() => closingStore.insuranceFormStore.openDialog()}
                >
                  <Add /> ADD INFO
                </Button>
              </Box>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            {createDateField({
              label: 'Requested Closing Date',
              fieldName: 'requestedClosingDate',
              testId: 'requestedClosingDate',
              store,
              format: 'MM/dd/yyyy',
              disablePast: true,
              standaloneLabel: false,
              addMarkForRequiredFields: true,
              isRequired: true,
            })}
          </Grid>
          <Grid item xs={12} sm={6}>
            {createSelectField({
              store,
              testId: 'preferredTitle',
              fieldName: 'preferredTitle',
              label: 'Preferred Title Company',
              options: filteredTitleOptions,
              standaloneLabel: false,
              addMarkForRequiredFields: true,
              isRequired: true,
            })}
            {preferredTitle === OTHER && (
              <Box pt={1} pb={1}>
                <Button
                  testId="add-title-info"
                  variant="contained"
                  size="small"
                  color="secondary"
                  onClick={() => closingStore.titleFormStore.openDialog()}
                >
                  <Add /> ADD INFO
                </Button>
              </Box>
            )}
          </Grid>
          {allowParticipation && (
            <>
              <Grid item xs={12} style={{ marginBottom: 10, marginTop: 10 }}>
                <TitleDivider title="Participation" />
              </Grid>
              <Grid item xs={12} sm={6}>
                {createSelectField({
                  store,
                  testId: 'lenderParticipation',
                  fieldName: 'lenderParticipation',
                  label: 'Participation',
                  options: participationOptions,
                  standaloneLabel: false,
                  addMarkForRequiredFields: false,
                })}
              </Grid>
              <Grid item xs={12} sm={6}>
                {createSelectField({
                  store,
                  testId: 'origLenderSubordination',
                  fieldName: 'origLenderSubordination',
                  label: 'Participation Percentage',
                  options: participationPercetangeOptions,
                  standaloneLabel: false,
                  addMarkForRequiredFields: false,
                })}
              </Grid>
            </>
          )}
          {allowOriginatorSelection && (
            <Grid item xs={12} sm={6}>
              <Originator store={store} />
            </Grid>
          )}
          <Grid item xs={12} style={{ marginBottom: 10, marginTop: 10 }}>
            <TitleDivider title="Fast Track" />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    {createRadioField({
                      label: 'Do you want to FAST TRACK this loan?',
                      fieldName: 'fastTrack',
                      testId: 'fastTrack',
                      store,
                      options: trueFalseOptions,
                      row: true,
                      standaloneLabel: false,
                    })}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FastTrackWarning
                  closingFormStore={store}
                  store={quoteTypeStore}
                  propertiesQuantity={propertiesQuantity}
                />
              </Grid>
            </Grid>
            {closingStore.canSubmitAppraisalWithTamarisk && (
              <>
                <Grid item xs={12} style={{ marginBottom: 10, marginTop: 10 }}>
                  <TitleDivider title="Appraisals" />
                </Grid>
                <Grid item xs={12} sm={12}>
                  {createRadioField({
                    label: 'Do you want to order an Appraisal with Tamarisk?',
                    fieldName: 'submitAppraisalWithTamarisk',
                    testId: 'submitAppraisalWithTamarisk',
                    store,
                    options: trueFalseOptions,
                    row: true,
                    standaloneLabel: false,
                  })}
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
        <OtherInsuranceDialog store={quoteTypeStore} />
        <OtherTitleDialog store={quoteTypeStore} />
      </Grid>
    );
  }
);
