import { Grid, Box, Typography } from "@material-ui/core";
import { Card, DataGrid, GridColumnProps, SelectMode, StandardDialog } from "@roc/ui";
import { observer } from "mobx-react";
import { activityTypeOptions } from "../leads/constants/constants";
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@roc/ui';
import { Add } from '@material-ui/icons';
import { HtmlContainer } from '../leadDetails/htmlContainer';
import { EditLeadActivityDialog } from "./editLeadActivityDialog";
import { EditLeadTaskDialog } from "./editLeadTaskDialog";
import { LeadDetailsStore } from "../../stores/leadDetails/leadDetailsStore";
import React, { useEffect, useState } from "react";
import { activityTypeOptionsMapping } from "../leads/constants/constants";
import { Note } from "./note";
import { EditLeadActivityStore } from "../../stores/leadDetails/editLeadActivityStore";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from '@material-ui/lab';

const useStyles = makeStyles(theme => ({
  button: {
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(2),
    minWidth: '150px',
  },
  notesScroll: {
    backgroundColor: '#FAFAFA',
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    overflow: 'hidden',
    boxShadow: '0 0 2px #DDD inset',
    paddingBottom: '100px'
  },
  timelineItem: {
    '&:before': {
      display: 'none',
    },
    paddingLeft: 0,
    marginLeft: 0,
  },
}));

const columns: GridColumnProps[] = [
  {
    field: 'action',
    headerName: 'Action',
    maxWidth: 200,
  },
  {
    field: 'executedBy',
    headerName: 'Executed by',
    minWidth: 250,
    cellRenderer: 'dealNameCellRenderer',
  },
  {
    field: 'assignedTo',
    headerName: 'Assigned to',
    minWidth: 250,
  },
  {
    field: 'daysAgo',
    headerName: 'Date',
    minWidth: 120,
  },
  {
    field: 'additionalInfo',
    headerName: 'Additional Info',
    minWidth: 130,
  }
];

export const ContactHistoryCard = observer(({ leadDetailsStore, type }) => {
  const classes = useStyles();
  const [isContactHistoryGridOpen, setIsContactHistoryGridOpen] = useState(false);
  const { recordHistoryStore } = leadDetailsStore;
  const { recordHistoryGrid } = recordHistoryStore;
  const [rows, setRows] = useState([]);


  useEffect(() => {
    if (recordHistoryGrid?.gridData?.data?.rows) {
      setRows(recordHistoryGrid.gridData.data.rows.slice(0, 5));
    }
  }, [recordHistoryGrid.gridData.data.rows]);

  const openContactHistoryGrid = () => {
    setIsContactHistoryGridOpen(true);
  };

  const handleCloseContactHistoryGrid = () => {
    setIsContactHistoryGridOpen(false);
  };

  useEffect(() => {
    recordHistoryGrid.resetAndFetchGridData();
  }, [leadDetailsStore.objectId]);

  const action = (
    <Box p={1} mb={-10}>
      <Button
        testId="see-record-history"
        className={classes.button}
        variant="contained"
        size="small"
        color="primary"
        onClick={() => openContactHistoryGrid()}
      >
        More details
      </Button>
    </Box >
  );





  return (
    <Card
      title="History"
      cardHeaderProps={{}}
      cardContentProps={{ className: classes.notesScroll }}

    >

      <Grid container direction="row" justifyContent="center">
        {rows.length > 0 ? (
          <>
            <Timeline>
              {rows.map((x, index) => (
                <TimelineItem key={index} className={classes.timelineItem}>
                  <TimelineSeparator>
                    <TimelineDot color="primary" />
                    {index !== rows.length - 1 && <TimelineConnector />}
                  </TimelineSeparator>
                  <TimelineContent>
                    <Grid container spacing={2}>
                      <Grid item xs={10}>
                        <Typography>
                          {(() => {
                            switch (x.action) {
                              case 'Record created':
                                return (
                                  <>
                                    Record created by <strong>{x.executedBy}</strong>
                                  </>
                                );
                              default:
                                return (
                                  <>
                                    {x.action} {x.assignedTo && <>to <strong>{x.assignedTo}</strong></>} by {x.executedBy}
                                  </>
                                );
                            }
                          })()}
                        </Typography>

                      </Grid>
                      <Grid item xs={2}>
                        <Typography align="right">
                          {x.daysAgo}
                        </Typography>
                      </Grid>
                    </Grid>
                  </TimelineContent>
                </TimelineItem>
              ))}
            </Timeline>
            <Grid container justifyContent="flex-end">
              <Box p={1} mb={1}>
                <Button
                  testId="see-record-history"
                  className={classes.button}
                  variant="contained"
                  size="small"
                  color="primary"
                  onClick={() => openContactHistoryGrid()}
                >
                  More details
                </Button>
              </Box >
            </Grid>
          </>

        ) : (
          <Box textAlign="center">No records.</Box>
        )}
      </Grid>
      <StandardDialog
        open={isContactHistoryGridOpen}
        title={type.charAt(0) + type.toLowerCase().slice(1) + ' History Grid'}
        maxWidth="lg"
        handleClose={handleCloseContactHistoryGrid}
        dialogContent={
          <ContactHistoryGrid gridStore={recordHistoryGrid} />
        }
      />
    </Card>
  );
});


export const ContactHistoryGrid = ({ gridStore }) => (
  <DataGrid
    columns={columns}
    rows={gridStore.gridData.data.rows}
    // frameworkComponents={frameworkComponents}
    // onSortChanged={onSortChanged}
    isLoading={gridStore.isLoading}
    sortModel={gridStore.sortModel}
    filterModel={gridStore.filterModel}
    paginationData={gridStore.gridData.meta}
    // paginationBarClassName={classes.paginationBar}
    setPageNumber={gridStore.setPageNumber}
    domLayout='autoHeight'
  />
);
