import { useTheme } from '@material-ui/styles';
import { observer } from 'mobx-react';
import { useEffect } from 'react';

import { ChooseLoanTypeStep } from './components/chooseLoanTypeStep';
import { EntityInformationStep } from './components/entityInformationStep';
import { LoanApplicationSummaryStep } from './components/loanApplicationSummaryStep';
import { PropertyInformationStep } from './components/propertyInformationStep';
import { ThankYouStep } from './components/thankYouStep';
import { useNewLoanApplicationStore } from './hooks/useNewLoanApplicationStore';
import { LoanApplicationStep } from './utils/loanApplicationConstants';

export const NewLoanApplication = observer(
  ({
    publicMode = false,
  }: {
    publicMode?: boolean;
  }) => {

    const { newLoanApplicationStore } = useNewLoanApplicationStore();
    const { loanType, step } = newLoanApplicationStore;

    useEffect(() => {
      newLoanApplicationStore.borrowerStore.fetchCurrentUserBorrower();
      if(loanType){
        //User coming from the select loan type modal
        newLoanApplicationStore.goToStep(LoanApplicationStep.ENTITY_INFORMATION)
      } else {
        newLoanApplicationStore.reset();
      }
    },[])

    return (
      <>
        {step === LoanApplicationStep.LOAN_TYPE && <ChooseLoanTypeStep />}
        {step === LoanApplicationStep.ENTITY_INFORMATION && <EntityInformationStep />}
        {step === LoanApplicationStep.PROPERTY_INFORMATION && <PropertyInformationStep />}
        {step === LoanApplicationStep.SUMMARY && <LoanApplicationSummaryStep />}
        {step === LoanApplicationStep.THANK_YOU && <ThankYouStep />}
      </>
    );
  }
);
