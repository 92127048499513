import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Box, Grid, Link, Typography } from '@material-ui/core';
import { Borrower } from '../../types';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { ConfirmDialog, NumberFormat, StatusChip, StatusType, TextField } from '@roc/ui';
import { BorrowersStore } from '../../stores';
import * as Validator from 'validatorjs';
import { isNotBlank, prequalificationStatusProperties, userPortalStatusProperties } from '@roc/feature-utils';
import { useBaseStore } from '@roc/feature-app-core';
import { BorrowerTierRating } from '../../components/borrowerTierRating';

const { NO_ACCOUNT } = userPortalStatusProperties;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    primaryFieldsContainer: {
      paddingBottom: '24px',
    },
    editLabelContainer: {
      display: 'flex',
    },
    labelText: {
      fontWeight: 'bold',
      color: 'gray',
      paddingRight: '8px'
    },
    editLabelText: {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
    editLabelLink: {
      paddingRight: '8px'
    },
    valueText: {
      fontWeight: 'bold',
    },
    input: {
      padding: '8px'
    },
    customValuesContainer: {
      display: 'flex',
      paddingTop: '8px'
    },
    portalStatusLink: {
      paddingLeft: '8px',
      textDecoration: 'underline',
      alignItems: 'baseline',
      cursor: 'pointer',
    },
    button: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
  })
);

const FORM_VALIDATION_PARAMS = {
  rules: {
    emailAddress: 'email',
    cellPhone: 'regex:/^\\d{3}-?\\d{3}-?\\d{4}$/|alpha_dash',
    firstName: 'required',
    lastName: 'required',

  },
  errorMessages: {
    emailAddress: 'The email address format is invalid.',
    cellPhone: 'Invalid phone number.',
    firstName: 'First name is required',
    lastName: 'Last name is required',
  }
}

export type BorrowerInformationProps = {
  borrower?: Borrower;
  borrowersStore: BorrowersStore;
  companyId: number;
};

export const BorrowerInformation = observer((props: BorrowerInformationProps) => {
  const { borrower, borrowersStore, companyId } = props;
  const { globalStore } = useBaseStore();
  const classes = useStyles();
  const [editingEmailAddress, setEdittingEmailAddress] = useState(false);
  const [editingCellphone, setEdittingCellphone] = useState(false);
  const [emailAddress, setEmailAddress] = useState(borrower?.emailAddress || '');
  const [cellPhone, setCellPhone] = useState(borrower?.cellPhone || '');
  const [emailAddressError, setEmailAddressError] = useState('');
  const [cellPhoneError, setCellPhoneError] = useState('');
  const [editingFirstName, setEdittingFirstName] = useState(false);
  const [editingLastName, setEdittingLastName] = useState(false);
  const [firstName, setFirstName] = useState(borrower?.firstName || '');
  const [lastName, setLastName] = useState(borrower?.lastName || '');
  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [showCreateAccount, setShowCreateAccount] = useState(false);

  useEffect(() => {
    borrowersStore.fetchTierRating();
  }, [])

  useEffect(() => {
    if (borrower) {
      if (borrower?.emailAddress) {
        setEmailAddress(borrower?.emailAddress);
      }
      if (borrower?.cellPhone) {
        setCellPhone(borrower?.cellPhone);
      }
      if (borrower?.firstName) {
        setFirstName(borrower?.firstName);
      }
      if (borrower?.lastName) {
        setLastName(borrower?.lastName);
      }
    }
  }, [borrower])

  const disableFieldsIfExistingBorrowerInFunded =
    borrower?.portalProjectsCompleted > 0 ||
    borrower?.portalProjectsActive > 0;

  const validateFields = () => {
    const validator = new Validator(
      { emailAddress, cellPhone, firstName, lastName },
      FORM_VALIDATION_PARAMS.rules,
      FORM_VALIDATION_PARAMS.errorMessages,
    );
    let emailError = false;
    let phoneError = false;
    let firstNameError = false;
    let lastNameError = false;
    validator.passes();

    // email validation
    if (editingEmailAddress && validator.errors?.errors?.emailAddress) {
      setEmailAddressError(FORM_VALIDATION_PARAMS.errorMessages.emailAddress);
      emailError = true;
    } else {
      setEmailAddressError('');
    }

    // phone validation
    if (editingCellphone && validator.errors?.errors?.cellPhone) {
      setCellPhoneError(FORM_VALIDATION_PARAMS.errorMessages.cellPhone);
      phoneError = true;
    } else {
      setCellPhoneError('');
    }

    // first name validation
    if (editingFirstName && validator.errors?.errors?.firstName) {
      setFirstNameError(FORM_VALIDATION_PARAMS.errorMessages.firstName);
      firstNameError = true;
    } else {
      setFirstNameError('');
    }

    // last name validation
    if (editingLastName && validator.errors?.errors?.lastName) {
      setLastNameError(FORM_VALIDATION_PARAMS.errorMessages.lastName);
      lastNameError = true;
    } else {
      setLastNameError('');
    }

    return !emailError && !phoneError && !firstNameError && !lastNameError;
  };

  const handleSave = () => {
    if (validateFields()) {
      borrowersStore.saveEditBorrower(emailAddress, cellPhone, firstName, lastName, handleCloseEdit);
    }
  }

  const handleCloseEdit = () => {
    setEdittingCellphone(false);
    setEdittingEmailAddress(false);
    setEdittingFirstName(false);
    setEdittingLastName(false);
  };

  const handleCloseConfirm = () => {
    setShowCreateAccount(false);
  };

  const handleCreateAccount = () => {
    borrowersStore.createBorrowerUserAccount(borrower?.contactId, companyId);
    handleCloseConfirm();
  };

  return (
    <Grid container>
      <ConfirmDialog
        open={showCreateAccount}
        confirmButtonText={'Yes'}
        handleClose={handleCloseConfirm}
        handleCancel={handleCloseConfirm}
        handleConfirm={handleCreateAccount}
        title={'Create portal account'}
        body={`Are you sure want to create a borrower portal account for ${borrower?.emailAddress}?`}
      />
      <Grid item xs={12} sm={8}>
        <Grid container className={classes.primaryFieldsContainer}>
          <Grid item xs={12} sm={6} md={3}>
            <Grid className={classes.editLabelContainer}>
              <Typography variant='body2' className={classes.labelText}>First Name</Typography>
              {!disableFieldsIfExistingBorrowerInFunded && (
                <Typography variant='body2' className={classes.editLabelText}>
                  {editingFirstName ? (
                    <>
                      <Link onClick={() => { handleCloseEdit() }} className={classes.editLabelLink}>Cancel</Link>
                      <Link onClick={() => { handleSave() }}>Save</Link>
                    </>
                  ) : (
                    <Link onClick={() => { setEdittingFirstName(!editingFirstName) }}>Edit</Link>
                  )}

                </Typography>
              )}
            </Grid>
            {editingFirstName ? (
              <TextField
                testId="firstname"
                variant="outlined"
                standaloneLabel
                value={firstName}
                inputProps={{
                  className: classes.input
                }}
                onChange={e => setFirstName(e.target.value)}
                error={isNotBlank(firstNameError)}
                helperText={firstNameError}
              />
            ) : (
              <Typography className={classes.valueText}>{borrower?.firstName || '-'}</Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Grid className={classes.editLabelContainer}>
              <Typography variant='body2' className={classes.labelText}>Last Name</Typography>
              {!disableFieldsIfExistingBorrowerInFunded && (
                <Typography variant='body2' className={classes.editLabelText}>
                  {editingLastName ? (
                    <>
                      <Link onClick={() => { handleCloseEdit() }} className={classes.editLabelLink}>Cancel</Link>
                      <Link onClick={() => { handleSave() }}>Save</Link>
                    </>
                  ) : (
                    <Link onClick={() => { setEdittingLastName(!editingLastName) }}>Edit</Link>
                  )}

                </Typography>
              )}
            </Grid>
            {editingLastName ? (
              <TextField
                testId="lastname"
                variant="outlined"
                standaloneLabel
                value={lastName}
                inputProps={{
                  className: classes.input
                }}
                onChange={e => setLastName(e.target.value)}
                error={isNotBlank(lastNameError)}
                helperText={lastNameError}
              />
            ) : (
              <Typography className={classes.valueText}>{borrower?.lastName || '-'}</Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Grid className={classes.editLabelContainer}>
              <Typography variant='body2' className={classes.labelText}>Email Address</Typography>
              {!disableFieldsIfExistingBorrowerInFunded && (
                <Typography variant='body2' className={classes.editLabelText}>
                  {editingEmailAddress ? (
                    <>
                      <Link onClick={() => { handleCloseEdit() }} className={classes.editLabelLink}>Cancel</Link>
                      <Link onClick={() => { handleSave() }}>Save</Link>
                    </>
                  ) : (
                    <Link onClick={() => { setEdittingEmailAddress(!editingEmailAddress) }}>Edit</Link>
                  )}

                </Typography>
              )}
            </Grid>
            {editingEmailAddress ? (
              <TextField
                testId="email"
                variant="outlined"
                standaloneLabel
                value={emailAddress}
                inputProps={{
                  className: classes.input
                }}
                onChange={e => setEmailAddress(e.target.value)}
                error={isNotBlank(emailAddressError)}
                helperText={emailAddressError}
              />
            ) : (
              <Typography className={classes.valueText}>{borrower?.emailAddress || '-'}</Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Grid className={classes.editLabelContainer}>
              <Typography variant='body2' className={classes.labelText}>Cell Phone</Typography>
              {!disableFieldsIfExistingBorrowerInFunded && (
                <Typography variant='body2' className={classes.editLabelText}>
                  {editingCellphone ? (
                    <>
                      <Link onClick={() => { handleCloseEdit() }} className={classes.editLabelLink}>Cancel</Link>
                      <Link onClick={() => { handleSave() }}>Save</Link>
                    </>
                  ) : (
                    <Link onClick={() => { setEdittingCellphone(!editingCellphone) }}>Edit</Link>
                  )}

                </Typography>
              )}
            </Grid>
            {editingCellphone ? (
              <NumberFormat
                testId="cellPhone"
                customInput={TextField}
                format="###-###-####"
                mask="_"
                variant="outlined"
                standaloneLabel
                value={cellPhone}
                inputProps={{
                  className: classes.input
                }}
                onChange={e => setCellPhone(e.target.value)}
                error={isNotBlank(cellPhoneError)}
                helperText={cellPhoneError}
              />
            ) : (
              <Typography className={classes.valueText}>{borrower?.cellPhone || '-'}</Typography>
            )}
          </Grid>
        </Grid>
        <Grid container>
          {globalStore.lenderInfo?.enableBorrowerPortal && (
            <Grid item xs={12} sm={6} md={6}>
              <Grid className={classes.editLabelContainer}>
                <Typography variant='body2' className={classes.labelText}>Borrower Portal Account Status</Typography>
                {globalStore.userFeatures?.allowManageBorrowerAccounts && borrower?.portalStatus === NO_ACCOUNT.value && (
                  <Typography variant='body2' className={classes.portalStatusLink}>
                    <Link onClick={() => { setShowCreateAccount(true) }}>Create</Link>
                  </Typography>
                )}
              </Grid>
              <Grid className={classes.customValuesContainer}>
                <StatusChip statusType={StatusType.USER_PORTAL_STATUS} label={borrower?.portalStatus} size="small" />
              </Grid>
            </Grid>
          )}
          <Grid item xs={12} sm={6} md={6}>
            <Grid className={classes.editLabelContainer}>
              <Typography variant='body2' className={classes.labelText}>Borrower Setup Questionaries</Typography>
            </Grid>
            <Grid className={classes.customValuesContainer}>
              <StatusChip statusType={StatusType.USER_PORTAL_STATUS} label={'Borrower Setup'} size="small" onClick={() => { borrowersStore.viewLink(borrower) }} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={4}>
        {!!borrowersStore.borrowerTier && (
          <Box display="flex" justifyContent="flex-end">
            <BorrowerTierRating bridgeTier={borrowersStore.borrowerTier.bridgeTier} rentalTier={borrowersStore.borrowerTier.rentalTier} />
          </Box>
        )}
      </Grid>
    </Grid>
  );
});

export default BorrowerInformation;
