import React, { useCallback, useEffect, useState } from 'react';
import '@trulioo/docv/style.css';
import { useStore } from 'apps/client-portal-public/src/app/hooks';
import { Box, Typography, Paper, Button, Link, CardMedia } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import consentImage from '../consentVerification/assets/consumer_consent_icon.svg';
import { DocumentVerificationState } from '@roc/feature-types';
import { FindInPage } from '@material-ui/icons';
import { orange } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    maxWidth: '600px',
    padding: theme.spacing(2),
    textAlign: 'center',
  },
  subtitle: {
    color: 'gray',
    '& a': {
      textDecoration: 'underline',
      color: 'gray',
    },
  },
  title: {
    fontWeight: 'bold',
  },
  button: {
    width: '92%',
    height: '2.8rem'
  },
  img: {
    width: '100%',
    maxHeight: '50%',
    objectFit: 'contain',
  },
  verificationIframe: {
    border: 0,
    height: '650px',
    width: '100%',
  },
}));
export const ConsentVerificationV2 = () => {
  const { wireAuthorizationStore, globalStore } = useStore();
  const classes = useStyles();
  const [componentToDisplay, setComponentToDisplay] = useState(!wireAuthorizationStore.identityVerificationCompleted ? 'CONSENT' : 'VIEW');


  const portalName =
    globalStore.portalConfiguration?.portalConfiguration?.generalConfiguration
      ?.name || '';
  const handleContinueClick = () => {
    wireAuthorizationStore.consentRequest();
    setComponentToDisplay('VERIFICATION');
  };

  const verificationCompleteHandler = useCallback((sessionId) => {
    wireAuthorizationStore.setExperienceId(sessionId);
    wireAuthorizationStore.setDocumentVerificationState(DocumentVerificationState.IN_REVIEW);
    setComponentToDisplay('VIEW');
  }, [wireAuthorizationStore]);

  return (
    <>
      {componentToDisplay === 'CONSENT' &&
        <Box className={classes.container}>
          <Paper elevation={3} className={classes.paper} style={{ height: '400px' }}>
            <CardMedia
              className={classes.img}
              component="img"
              alt="Identity verification"
              image={consentImage}
            />
            <Typography variant="h6" className={`${classes.title} title-text`}>
              Let's verify your identity
            </Typography>
            <Typography paragraph className={classes.subtitle}>
              By selecting “Continue” you agree to the {portalName}{' '}
              <Link
                href={`https://${window.location.host}/privacy-policy`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span style={{ textDecoration: 'underline', color: 'gray' }}>
                  End User Privacy Policy
                </span>
              </Link>
            </Typography>
            <Button
              type="button"
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={handleContinueClick}
            >
              Continue
            </Button>
          </Paper>
        </Box>
      }
      {componentToDisplay === 'VERIFICATION' &&
        <DocumentVerificationV3 secureMeLink={wireAuthorizationStore.secureMeLink} onVerificationComplete={verificationCompleteHandler} />
      }
      {componentToDisplay === 'VIEW' && (
        <Box mt={4} mx={'auto'} textAlign="center">
          <FindInPage style={{ fontSize: 60, color: orange[500] }} />
          <br />
          <Typography variant="h4" color="textPrimary">
            In Review
          </Typography>
          <br />
          <Typography>Your document has been received for verification.</Typography>
          <br />
          <Typography>
            You can proceed to the next step for electronic signature.
          </Typography>
        </Box>
      )
      }
    </>
  );
};

export const DocumentVerificationV3 = ({ secureMeLink, onVerificationComplete }) => {

  const classes = useStyles();

  useEffect(() => {
    const { hostname, protocol } = new URL(secureMeLink);
    const origin = `${protocol}//${hostname}`;

    function receiveMessage(event) {
      if (event.origin === origin) {
        if (event.data.payload.value === '/success') {
          setTimeout(() => {
            onVerificationComplete(event?.data?.id);
          }, 3000);
        }
      }
    }
    window.addEventListener("message", receiveMessage, false);

    return () => window.removeEventListener("message", receiveMessage);
  }, [onVerificationComplete, secureMeLink]);

  return (
    <iframe allow="geolocation;camera; " id="verificationIframe" title="verificationIframe" className={classes.verificationIframe} src={secureMeLink} />
  )
};


export default ConsentVerificationV2;