import React, { useState } from 'react';

import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  AgGridColumnProps,
  Paper,
  DataGrid as DefaultAllocationGrid, DropdownMenu
} from '@roc/ui';
import { useEffect } from 'react';
import { observer } from 'mobx-react';
import { usePayoffRequestStore } from '../hooks/usePayoffRequestStore';
import { useLoanStore } from '@roc/feature-loans-shared';
import { formatCurrency } from '@roc/ui/utils';
import { InterestAllocationStatusChip } from './interestAllocationStatusChip';

const noOpComparator = () => 0;

const useStyles = makeStyles(theme => ({
  paper: {
    padding: 24,
  },
  gridContainer: {
    paddingTop: 24,
  },
  dealName: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 40,
  },
  alert: {
    textAlign: 'center',
  },
  alertContainer: {
    paddingTop: 20,
  },
  typographyStyle: {
    marginRight: 10
  },
}));

export const ForeclosureInterestAllocationDashboard = observer(({ loanId }: { loanId: string }) => {
  const {
    payoffRequestStore,
    payoffRequestStore: { foreclosureInterestAllocation, currentPayoff },
  } = usePayoffRequestStore();
  const {
    loanStore: { loanDetails },
  } = useLoanStore();
  const classes = useStyles();

  useEffect(() => {
    payoffRequestStore.getForeclosureInterestAllocationDashboard(loanId);
  }, [loanId]);

  const foreclosureAllocation: AgGridColumnProps[] = [
    {
      field: 'description',
      headerName: 'Description',
      minWidth: 100,
      comparator: noOpComparator,
      sortable: false,
    },
    {
      field: 'amount',
      headerName: 'Amount',
      minWidth: 120,
      comparator: noOpComparator,
      sortable: false,
      cellRenderer: 'currencyCellRenderer',
    },
    {
      field: 'loanServicer',
      headerName: 'Loan Servicer (LS)',
      minWidth: 130,
      comparator: noOpComparator,
      sortable: false,
      cellRenderer: 'currencyCellRenderer',
    },
    {
      field: 'tpo',
      headerName: 'TPO',
      minWidth: 40,
      comparator: noOpComparator,
      sortable: false,
      cellRenderer: 'currencyCellRenderer',
    },
    {
      field: 'servicer',
      headerName: 'SLS',
      minWidth: 40,
      comparator: noOpComparator,
      sortable: false,
      cellRenderer: 'currencyCellRenderer',
    },
    {
      field: 'investor',
      headerName: loanDetails.investorName || 'Investor',
      minWidth: 50,
      comparator: noOpComparator,
      cellRenderer: 'currencyCellRenderer',
    },
    {
      field: 'funder',
      headerName: 'Funder',
      minWidth: 50,
      cellRenderer: 'currencyCellRenderer',
      comparator: noOpComparator,
    },
  ];

  const frameworkComponents = {
    currencyCellRenderer(params) {
      return formatCurrency(params.value);
    },
  };

  return (
    <>
      <Grid item xs={6} md={12} className={classes.gridContainer}>
        <Paper className={classes.paper}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Grid container justifyContent="space-between">
                <Grid container justifyContent='flex-start'>
                  <Typography className={classes.typographyStyle} variant="h5">FORECLOSURE INTEREST ALLOCATION</Typography>
                  <InterestAllocationStatusChip value={currentPayoff?.interestAllocationApproved} />
                </Grid>
              </Grid>
            </Grid>
            <DefaultAllocationGrid
              columns={foreclosureAllocation}
              rows={foreclosureInterestAllocation || []}
              domLayout="autoHeight"
              frameworkComponents={frameworkComponents}
            />
          </Grid>
        </Paper>
      </Grid>
    </>
  );
});
