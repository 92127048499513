import { ApiResponse, GlobalStore } from '@roc/feature-app-core';
import { Task } from '@roc/feature-types';
import { downloadDocument, GENERIC_ERROR_MESSAGE } from '@roc/feature-utils';
import { FileUpload } from '@roc/ui';
import { action, flow, makeObservable, observable } from 'mobx';
import { FixFlipStore, TermStore } from '../../loanSubmission';
import { DraftLoanTasksService } from '../services/draftLoanTasksService';
import { LoanService } from '../services/loanService';
import { LoanStore } from './loanStore';

export class DraftLoanTasksStore {
  private globalStore: GlobalStore;
  private draftLoanTasksService: DraftLoanTasksService;
  private draftLoanId;

  tasks = [];
  sections = [];
  filePreview: any = null;

  constructor(globalStore: GlobalStore) {
    this.globalStore = globalStore;
    this.draftLoanTasksService = new DraftLoanTasksService();

    makeObservable(this, {
      tasks: observable,
      sections: observable,
      filePreview: observable,
      reset: action,
      fetchDraftLoanTasks: flow,
      uploadTaskFile: flow,
      updateTask: action,
      downloadTaskFile: flow,
      previewTaskFile: flow,
      closeFilePreview: action,
      createTask: flow
    });
  }

  reset() {
    this.draftLoanId = null;
    this.sections = []
    this.tasks = [];
  }

  *fetchDraftLoanTasks(draftLoanId) {
    try {
      this.draftLoanId = draftLoanId;
      const response: ApiResponse = yield this.draftLoanTasksService.getDraftLoanTasks(
        draftLoanId
      );
      this.tasks = response.data.data.tasks;
      this.sections = response.data.data.sections;
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE,
      });
    }
  }

  *uploadTaskFile(task, fileUpload: FileUpload) {
    try {
      const blob = fileUpload.file.slice(
        0,
        fileUpload.file.size,
        fileUpload.file.type
      );
      const newFile = new File([blob], fileUpload.name, {
        type: fileUpload.file.type,
      });
      yield this.draftLoanTasksService.uploadDraftLoanTaskFile(task, newFile);
      this.updateTask(task);
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'File uploaded successfully',
      });
      yield this.fetchDraftLoanTasks(this.draftLoanId);
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE,
      });
    }
  }

  *downloadTaskFile(task) {
    try {
      const response = yield this.draftLoanTasksService.downloadTaskFile(
        task.taskId
      );

      downloadDocument(
        response?.data,
        response?.headers,
        'download',
        task.originalFileName
      );
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE,
      });
    }
  }

  *createTask(section, fileUpload: FileUpload){
    yield this.uploadTaskFile({
      draftLoanId: section.draftLoanId,
      objectType: section.objectType,
      objectUuid: section.objectUuid,
      taskSectionId: section.taskSectionId,
      taskTemplateId: section.taskTemplateId,
      taskName: fileUpload.name,
      taskStatus: 'BEING_REVIEWED',
      taskType: 'DOCUMENT',
    },fileUpload);
  }

  updateTask(updatedTask) {
    this.tasks = this.tasks.map(task =>
      task.taskId === updatedTask.taskId ? updatedTask : task
    );
  }

  *previewTaskFile(task) {
    try {
      const response = yield this.draftLoanTasksService.downloadTaskFile(
        task.taskId
      );
      this.filePreview = {
        title: task.taskName,
        data: response.data,
        headers: response.headers,
      };
    } catch (err) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE,
      });
    }
  }

  closeFilePreview() {
    this.filePreview = null;
  }
}
