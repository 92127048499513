import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useEffect, useState } from 'react';
import { CdaArrPaymentLink } from './CDAARRPaymentLink';
import { CdaArrRadioButtonsSubmission } from './CdaArrRadioButtonsSubmission';
import { ConfirmCloseButtons } from './confirmCloseComponent';
import { Card } from '@roc/ui';
import { Box, Typography, Paper, Button } from '@material-ui/core';
import { useDocumentStore } from 'libs/feature-documents/src/documents/hooks/useDocumentStore';

const useStyles = makeStyles({
  container: {
    paddingTop: 16,
  }
});

const useStylesForCard = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    borderRadius: '8px',
  },
  gridStyle: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  buttonStyle: {
    margin: theme.spacing(1),
  },
  radioGroupStyle: {
    margin: theme.spacing(2, 0),
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  contentContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  card: {
    width: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));


export const CDAManualSubmission = ({ onConfirm, isInternal, url, borrowersEmail, loanId, cdaEnabled }) => {//RADIO BOTONES

  const classes = useStyles();

  useEffect(() => {
    if (isInternal)
      window.parent.postMessage('cda-default', '*');
  }, [])

  return (<Grid container justifyContent="center" className={classes.container}>
    <>
      <CdaArrRadioButtonsSubmission
        secondOrder={false}
        isCdaOrdered={false}
        editOnButton={true}
        onConfirm={onConfirm}
      />
      <CdaArrPaymentLink
        url={url}
        borrowersEmail={borrowersEmail}
        loanId={loanId}
        cdaEnabled={cdaEnabled}
        isInternal={isInternal}
      />
    </>
  </Grid>
  )
}

export const CardRadioButtons = ({ onConfirm, isInternal }) => {
  const [editCdaArrOption, setEditCdaArrOption] = useState(false);
  const classes = useStylesForCard();

  const { documentStore } = useDocumentStore();
  const { documentFormStore } = documentStore;
  const { cdaAndAppraisalOrderDetailsStore } = documentFormStore;

  const { radioValue, secondRadioValue, isCdaRushed } = cdaAndAppraisalOrderDetailsStore;

  const cardProps = { onEdit: () => setEditCdaArrOption(true) };

  return (
    <Card title='CDA/ARR PREFERENCES' cardContentProps={{ style: { padding: 0 } }} className={classes.card} {...cardProps}>
      <Box p={4}>
        <Box display="flex" flexDirection="row" flexWrap="wrap" justifyContent="space-between">
          <Box flex={1} mr={2}>
            <CdaArrRadioButtonsSubmission
              secondOrder={false}
              isCdaOrdered={false}
            />
          </Box>
        </Box>
        {editCdaArrOption && (
          <ConfirmCloseButtons isInternal={isInternal} hasConfirmButton={true} onConfirm={onConfirm} showCloseButton={false} />

        )}
      </Box>
    </Card>
  );
}