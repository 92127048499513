import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import Carousel from 'react-material-ui-carousel';
import { Box, Grid, Paper } from '@material-ui/core';

import img1 from './../assets/img1.png';
import img2 from './../assets/img2.png';
import img3 from './../assets/img3.png';
import img4 from './../assets/img4.png';
import img5 from './../assets/img5.png';

var items = [img1, img2, img3, img4, img5];

function Item(props) {
  return (
    <Box display={'flex'}>
      <img src={props.item} width={'100%'} height={'auto'}></img>
    </Box>
  )
}

const useStyles = makeStyles({
  root: {
  },
  carousel: {
    height: '100%',
    background: '#eee',
    '& .CarouselItem': {
      display: 'flex',
      height: '100%',
      alignItems: 'center',
    }
  }
});

export const PropertyCard = () => {
  const classes = useStyles();

  return (
    <Card className={classes.root} elevation={0} variant='outlined'>
      <Box display={'flex'} flexDirection={'row'}>
        <Box width={200}>
          <CardMedia style={{ height: '100%' }}>
            <Carousel
              className={classes.carousel}
              autoPlay={false}
              indicators={false}
              navButtonsAlwaysVisible={true}
              cycleNavigation={true}
              fullHeightHover={true}
              animation={'slide'}
              navButtonsProps={{
                style: {
                  padding: 4,
                  borderRadius: 24,
                  opacity: 0.5,
                }
              }}
            >
              {
                items.map((item, i) => <Item key={i} item={item} />)
              }
            </Carousel>
          </CardMedia>
        </Box>
        <Box flexGrow={1}>
          <CardContent style={{
            padding: 16
          }}>
            <Typography gutterBottom variant="h5" component="h2">
              17912 Commercial Ave, Lansing, IL, 60438
            </Typography>
            <Box>

              <Grid container>
                <Grid item xs={12}>
                  <Typography variant="body1" component="p" style={{
                    fontWeight: 'bold'
                  }}>
                    Single Family Residential
                  </Typography>
                </Grid>
              </Grid>

              <Box width={'100%'} height={'1px'} bgcolor={'#eee'} my={1}></Box>

              <Grid container style={{
                textAlign: 'center'
              }} spacing={1}>
                <Grid item xs={3}>
                  <Typography variant="body1" component="p" style={{
                    fontWeight: 'bold'
                  }}>
                    3
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p">
                    Beds
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body1" component="p" style={{
                    fontWeight: 'bold'
                  }}>
                    1
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p">
                    Baths
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body1" component="p" style={{
                    fontWeight: 'bold'
                  }}>
                    3
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p">
                    Half Baths
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body1" component="p" style={{
                    fontWeight: 'bold'
                  }}>
                    45,822
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p">
                    GLA
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body1" component="p" style={{
                    fontWeight: 'bold'
                  }}>
                    $160,000
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p">
                    As Is Value
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body1" component="p" style={{
                    fontWeight: 'bold'
                  }}>
                    $41,300
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p">
                    Reno Budget
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body1" component="p" style={{
                    fontWeight: 'bold'
                  }}>
                    $245,000
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p">
                    ARV
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body1" component="p" style={{
                    fontWeight: 'bold'
                  }}>
                    $2,500/mo.
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p">
                    Market Rent
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </CardContent>
        </Box>
      </Box>
    </Card>
  );
}
