import {
  DataGrid as Grid,
  GridColumnProps,
  StatusChip,
  StatusType
} from '@roc/ui';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useDocumentStore } from '@roc/feature-documents';

const noOpComparator = () => 0;

const columns: GridColumnProps[] = [
  {
    field: 'orderId',
    headerName: 'Order Id',
    maxWidth: 120,
    comparator: noOpComparator,
    sortable: false,
  },
  {
    field: 'propertyAddress',
    headerName: 'Property Address',
    minWidth: 300,
    comparator: noOpComparator,
    sortable: false,
  },
  {
    field: 'orderStatus',
    headerName: 'Status',
    maxWidth: 150,
    comparator: noOpComparator,
    cellRenderer: 'statusCellRenderer',
    sortable: false,
  },
  {
    field: 'creationDate',
    headerName: 'Order Creation Date',
    minWidth: 120,
    sortable: false,
    comparator: noOpComparator,
  },
  {
    field: 'paymentStatus',
    headerName: 'Payment Status',
    maxWidth: 100,
    comparator: noOpComparator,
    cellRenderer: 'paymentStatusCellRenderer',
    sortable: false,
  },
];

const frameworkComponents = {
  statusCellRenderer: ({ value }) =>
    value ? (
      <StatusChip statusType={StatusType.DOCUMENT} label={value} size="small" />
    ) : null,
  paymentStatusCellRenderer: ({ value }) =>
    value === true ? (
      "Paid"
    ) : "Pending",
};

export const AppraisalDetailsGrid = observer(
  ({ loanId }: { loanId: number }) => {
    const {
      documentStore: { documentFormStore },
    } = useDocumentStore();
    const { cdaAndAppraisalOrderDetailsStore } = documentFormStore;

    useEffect(() => {
      cdaAndAppraisalOrderDetailsStore.setLoanId(loanId);
      cdaAndAppraisalOrderDetailsStore.appraisalDetailsGridStore.fetchGridData();
    }, [cdaAndAppraisalOrderDetailsStore]);

    return (
      <Grid
        columns={columns}
        rows={
          cdaAndAppraisalOrderDetailsStore.appraisalDetailsGridStore.gridData.data
            .rows
        }
        frameworkComponents={frameworkComponents}
        isLoading={
          cdaAndAppraisalOrderDetailsStore.appraisalDetailsGridStore.isLoading
        }
        showFilters={
          cdaAndAppraisalOrderDetailsStore.appraisalDetailsGridStore.showFilters
        }
        domLayout="autoHeight"
      />
    );
  }
);
