import { Box, Button, List, ListItem, ListItemIcon, ListItemText, Toolbar, Typography } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import { Skeleton } from '@material-ui/lab';
import { IconButton } from '@roc/ui';

export const ConversationListToolbar = ({
  title,
  onClose,
  children
}: {
  title: string,
  onClose?: () => void,
  children?: React.ReactNode
}) => {
  return (
    <Toolbar style={{
      padding: '0 0 0 16px'
    }}>
      <Box>
        <Typography variant="h5">
          <strong>{title}</strong>
        </Typography>
      </Box>
      {
        children
      }
      {
        onClose ?
          <Box display="flex" flexGrow={1} justifyContent={"flex-end"}>
            <IconButton
              testId="close-icon"
              color="default"
              onClick={onClose}
            >
              <Close fontSize='medium' />
            </IconButton>
          </Box> : null
      }
    </Toolbar>
  )
}

export const SkeletonLoader = () => {
  return (
    <Box>
      <SimpleListSkeleton count={3} />
    </Box>
  )
}

export const ListSkeleton = ({ title = undefined }) => {
  return (
    <>
      {
        title && <ConversationListToolbar title={title} />
      }
      <SimpleListSkeleton count={50} />
    </>
  )
}

export const SimpleListSkeleton = ({
  count
}: {
  count: number
}) => {
  return (
    <>
      <List>
        {
          [...Array(count).keys()].map((x) => {
            return (
              <ListItem key={x} style={{
                borderBottom: '1px solid #eee',
              }}>
                <ListItemIcon>
                  <Skeleton animation="wave" variant="circle" width={30} height={30} />
                </ListItemIcon>
                <ListItemText>
                  <Skeleton animation="wave" height={20} width="50%" style={{
                    marginLeft: 16,
                    marginBottom: 4,
                  }} />
                  <Skeleton animation="wave" height={20} width="70%" style={{
                    marginLeft: 16
                  }} />
                </ListItemText>
              </ListItem>
            )
          })
        }
      </List>
    </>
  )
}

export const Error = ({ title = undefined, hideRetry = false }) => {
  return (
    <>
      <ConversationListToolbar title={title} />
      <List>
        <ListItem>
          <ListItemText>
            <Typography color='error'>
              Oops, something went wrong. Please try again later.
            </Typography>
          </ListItemText>
        </ListItem>
        {
          hideRetry ? null :
            <ListItem>
              <ListItemText>
                <Box>
                  <Button variant="outlined" color="default" onClick={() => (window as any).location.reload()}>
                    Retry
                  </Button>
                </Box>
              </ListItemText>
            </ListItem>
        }
      </List>
    </>
  )
}
