import { Grid } from '@material-ui/core';
import {
  createCurrencyField,
  createNumberFormatField,
  createPercentageField,
  createSelectField,
} from '@roc/client-portal-shared/components';
import { observer } from 'mobx-react';
import { DscrCalculatorStore } from '../stores/dscrCalculatorStore';
import { loanSubtypeOptions } from '../utils/dscrCalculatorConstants';

interface DscrCalcFieldProps {
  store: DscrCalculatorStore;
  helperText: string;
}

const field = FieldComponent => {
  const WrappedFieldComponent = observer(FieldComponent);
  return props => (
    <Grid item xs={props.xs ?? 12} md={props.md ?? 6}>
      <WrappedFieldComponent {...props} />
    </Grid>
  );
};

export const LoanSubtype = field(({ store }: DscrCalcFieldProps) => {
  return createSelectField({
    store,
    label: 'Loan Subtype',
    testId: 'loanSubtype',
    fieldName: 'loanSubtype',
    options: loanSubtypeOptions,
  });
});

export const EstimatedAsIsValue = field(({ store }: DscrCalcFieldProps) => {
  return createCurrencyField({
    store,
    label: 'Estimated As-Is Value',
    testId: 'estimatedAsIsValue',
    fieldName: 'estimatedAsIsValue',
  });
});

export const RequestedLTV = field(({ store }: DscrCalcFieldProps) => {
  return createPercentageField({
    store,
    label: 'Requested LTV',
    testId: 'requestedLTV',
    fieldName: 'requestedLTV',
    decimalScale: 2,
  });
});

export const NoteInterestRate = field(({ store }: DscrCalcFieldProps) => {
  return createPercentageField({
    store,
    label: 'Note Interest Rate',
    testId: 'rate',
    fieldName: 'rate',
    decimalScale: 2,
  });
});

export const InterestOnlyPeriodMonths = field(
  ({ store }: DscrCalcFieldProps) => {
    return createNumberFormatField({
      store,
      label: 'Interest Only Period (Months)',
      testId: 'interestOnlyPeriod',
      fieldName: 'interestOnlyPeriod',
      allowNegative: false,
    });
  }
);

export const FullyAmortizingPeriodMonths = field(
  ({ store }: DscrCalcFieldProps) => {
    return createNumberFormatField({
      store,
      label: 'Fully Amortizing Period (Months)',
      testId: 'fullyAmortizingPeriod',
      fieldName: 'fullyAmortizingPeriod',
      disabled: true,
    });
  }
);

export const AmortizingPeriodMonths = field(({ store }: DscrCalcFieldProps) => {
  return createNumberFormatField({
    store,
    label: 'Amortizing Period (Months)',
    testId: 'fullyAmortizingPeriod',
    fieldName: 'fullyAmortizingPeriod',
    allowNegative: false,
    disabled: true,
  });
});

export const LoanAmount = field(({ store }: DscrCalcFieldProps) => {
  return createCurrencyField({
    store,
    label: 'Loan Amount',
    testId: 'loanAmount',
    fieldName: 'loanAmount',
    disabled: true,
  });
});

export const InPlaceMonthlyRent = field(({ store }: DscrCalcFieldProps) => {
  return createCurrencyField({
    store,
    label: 'In Place Monthly Rent',
    testId: 'inPlaceMonthlyRent',
    fieldName: 'inPlaceMonthlyRent',
  });
});

export const MonthlyMarketRent = field(({ store }: DscrCalcFieldProps) => {
  return createCurrencyField({
    store,
    label: 'Monthly Market Rent',
    testId: 'monthlyMarketRent',
    fieldName: 'monthlyMarketRent',
  });
});

export const AnnualGrossRent = field(({ store }: DscrCalcFieldProps) => {
  return createCurrencyField({
    store,
    label: 'Annual Gross Rent',
    testId: 'annualQualifyingRent',
    fieldName: 'annualQualifyingRent',
    disabled: true,
  });
});

export const Vacancy = field(({ store, helperText }: DscrCalcFieldProps) => {
  return createCurrencyField({
    store,
    label: 'Vacancy',
    testId: 'vacancy',
    fieldName: 'vacancy',
    helperText,
  });
});

export const Concession = field(({ store, helperText }: DscrCalcFieldProps) => {
  return createCurrencyField({
    store,
    label: 'Concessions',
    testId: 'concession',
    fieldName: 'concession',
    helperText,
    disabled: true,
  });
});

export const CreditLoss = field(({ store, helperText }: DscrCalcFieldProps) => {
  return createCurrencyField({
    store,
    label: 'Credit Loss',
    testId: 'creditLoss',
    fieldName: 'creditLoss',
    helperText,
    disabled: true,
  });
});

export const GrossPotentialRent = field(({ store }: DscrCalcFieldProps) => {
  return createCurrencyField({
    store,
    label: 'Gross Potential Rent',
    testId: 'grossPotentialRent',
    fieldName: 'grossPotentialRent',
    disabled: true,
  });
});

export const AnnualTaxes = field(({ store }: DscrCalcFieldProps) => {
  return createCurrencyField({
    store,
    label: 'Annual Taxes',
    testId: 'annualTaxes',
    fieldName: 'annualTaxes',
  });
});

export const AnnualInsurance = field(({ store }: DscrCalcFieldProps) => {
  return createCurrencyField({
    store,
    label: 'Annual Insurance',
    testId: 'annualInsurance',
    fieldName: 'annualInsurance',
  });
});

export const AnnualHOAFees = field(({ store }: DscrCalcFieldProps) => {
  return createCurrencyField({
    store,
    label: 'Annual HOA Fees',
    testId: 'annualHOAFees',
    fieldName: 'annualHOAFees',
  });
});

export const UnitCount = field(({ store }: DscrCalcFieldProps) => {
  return createNumberFormatField({
    store,
    label: 'Unit Count',
    testId: 'unitCount',
    fieldName: 'unitCount',
    allowNegative: false,
  });
});

export const PropertyManagementFee = field(
  ({ store, helperText }: DscrCalcFieldProps) => {
    return createCurrencyField({
      store,
      label: 'Property Management Fee',
      testId: 'propertyManagementFee',
      fieldName: 'propertyManagementFee',
      helperText,
    });
  }
);

export const Taxes = field(({ store, helperText }: DscrCalcFieldProps) => {
  return createCurrencyField({
    store,
    label: 'Taxes',
    testId: 'annualTaxes',
    fieldName: 'annualTaxes',
    helperText,
  });
});

export const Insurance = field(({ store, helperText }: DscrCalcFieldProps) => {
  return createCurrencyField({
    store,
    label: 'Insurance',
    testId: 'annualInsurance',
    fieldName: 'annualInsurance',
    helperText,
  });
});

export const GeneralAndAdministrativeFee = field(
  ({ store, helperText }: DscrCalcFieldProps) => {
    return createCurrencyField({
      store,
      label: 'General & Administrative',
      testId: 'generalAndAdministrativeFee',
      fieldName: 'generalAndAdministrativeFee',
      helperText,
    });
  }
);

export const RepairsAndMaintenance = field(
  ({ store, helperText }: DscrCalcFieldProps) => {
    return createCurrencyField({
      store,
      label: 'Repairs & Maintenance',
      testId: 'repairsAndMaintenance',
      fieldName: 'repairsAndMaintenance',
      helperText,
    });
  }
);

export const Payroll = field(({ store, helperText }: DscrCalcFieldProps) => {
  return createCurrencyField({
    store,
    label: 'Payroll',
    testId: 'payroll',
    fieldName: 'payroll',
    helperText,
  });
});

export const AnnualHOA = field(({ store }: DscrCalcFieldProps) => {
  return createCurrencyField({
    store,
    label: 'Annual HOA',
    testId: 'annualHOAFees',
    fieldName: 'annualHOAFees',
  });
});

export const TurnoverCosts = field(
  ({ store, helperText }: DscrCalcFieldProps) => {
    return createCurrencyField({
      store,
      label: 'Turnover Costs',
      testId: 'turnoverCosts',
      fieldName: 'turnoverCosts',
      helperText,
    });
  }
);

export const Utilities = field(({ store, helperText }: DscrCalcFieldProps) => {
  return createCurrencyField({
    store,
    label: 'Utilities',
    testId: 'utilities',
    fieldName: 'utilities',
    helperText,
  });
});

export const LeasingAndMarketing = field(
  ({ store, helperText }: DscrCalcFieldProps) => {
    return createCurrencyField({
      store,
      label: 'Leasing & marketing',
      testId: 'leasingAndMarketing',
      fieldName: 'leasingAndMarketing',
      helperText,
    });
  }
);

export const Miscellaneous = field(
  ({ store, helperText }: DscrCalcFieldProps) => {
    return createCurrencyField({
      store,
      label: 'Miscellaneous',
      testId: 'miscellaneous',
      fieldName: 'miscellaneous',
      helperText,
    });
  }
);

export const ReplacementReserves = field(
  ({ store, helperText }: DscrCalcFieldProps) => {
    return createCurrencyField({
      store,
      label: 'Replacement Reserves',
      testId: 'replacementReserves',
      fieldName: 'replacementReserves',
      helperText,
    });
  }
);
