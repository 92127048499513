import {
  Box,
  Divider,
  Grid,
  InputAdornment,
  makeStyles,
  TextField as MuiTextField,
  SvgIconProps,
  TextField,
  Typography,
} from '@material-ui/core';
import { Search, Work } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import { createTextField } from '../../../../components/temp/componentBuiderTemp';
import {
  AddressDetailsField,
  createAutocompleteAddressField,
  createDateField,
  createNumberFormatField,
  createPercentageField,
  createPhoneField,
  createRadioField,
  createSelectField,
  trueFalseOptions,
} from '@roc/ui';
import { observer } from 'mobx-react';
import { EditControlsPreflightCards } from '../../../../components/editControls';
import { ItemDisplay } from '../../../../components/itemDisplay';
import { RoundedBackgroundIcon } from '../../../../components/roundedBackgroundIcon';
import { FORM_MAX_WIDTH } from '../../../../utils/constants';
import { EntityFormStore } from 'libs/feature-one-tool/src/quote/stores/entity/entityFormStore';
import { EntityStore } from 'libs/feature-one-tool/src/quote/stores/entity/entityStore';
import { useEffect } from 'react';
import { QuoteTypeStore } from 'libs/feature-one-tool/src/quote/stores/quoteTypeStore';
import { isNotBlank, yesNoOptionsBoolean } from '@roc/feature-utils';

const entityTypes = [
  { value: 'Corporation', label: 'Corporation' },
  { value: 'Limited Liability Company', label: 'Limited Liability Company' },
];

const useStyles = makeStyles(theme => ({
  infoMessage: {
    fontStyle: 'italic',
    marginTop: '0rem',
    marginLeft: '1rem',
    color: 'gray',
    fontSize: '12px',
  },
  autocomplete: {
    '& .MuiAutocomplete-endAdornment': {
      display: 'none',
    },
  },
}));

interface EntityInformationFormProps {
  store: QuoteTypeStore;
  onDeleteClick: (borrowerKey: string) => void;
  showActions?: boolean;
}

export const EntityInformationForm = observer(
  ({ store, showActions = true }: EntityInformationFormProps) => {
    const classes = useStyles();
    const entityFormStore = store.entityStore.entityFormStore;
    const entitystore = store.entityStore;
    const hasEntity = entityFormStore.form.fields.hasEntity.value === true;
    const entityFields = entityFormStore.form.fields;
    const isExistingEntity = !!entityFields.entityId.value;

    function saveHandler() {
      const isValid = store.entityStore.entityFormStore.runFormValidationWithMessage(
        'Please complete all the required fields'
      );
      if (isValid) {
        store.entityStore.entityFormStore.setSkipped(false);
        store.entityStore.entityFormStore.setEditMode(false);
      }
    }

    const handleReset = () => {
      store.entityStore.entityFormStore.reset();
      store.entityStore.borrowersFormsStoreIds.forEach(id =>
        store.borrowersStore.removeBorrower(id)
      );
      store.entityStore.entityFormStore.setEditMode(true);
    };

    const handleSkip = () => {
      store.entityStore.entityFormStore.reset();
      store.entityStore.borrowersFormsStoreIds.forEach(id =>
        store.borrowersStore.removeBorrower(id)
      );
      store.entityStore.entityFormStore.setSkipped(true);
      store.entityStore.entityFormStore.setEditMode(false);
    };

    return (
      <>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          style={{ marginBottom: '16px' }}
        >
          <Grid item xs={6}>
            <Grid
              container
              spacing={2}
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item alignItems="center" style={{ height: '100%' }}>
                <RoundedBackgroundIcon
                  Icon={(props: SvgIconProps) => <Work />}
                  iconColor="#6D6D6D"
                />
              </Grid>
              <Grid item>
                <ItemDisplay
                  value={'Entity Information'}
                  alignItems="flex-start"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Grid item container spacing={2} style={{ maxWidth: FORM_MAX_WIDTH }}>
            <Grid item xs={12}>
              {createRadioField({
                label: 'Do you have the borrowing entity information?',
                fieldName: 'hasEntity',
                testId: 'hasEntity',
                options: yesNoOptionsBoolean,
                store: store.entityStore.entityFormStore,
                row: true,
                standaloneLabel: false,
              })}
            </Grid>
            {hasEntity && (
              <>
                <Grid item xs={12} className={classes.autocomplete}>
                  <SearchEntityAutocompleteField store={store} />
                </Grid>
                <Grid item xs={12}>
                  <OrDivider />
                </Grid>
                <Grid item xs={12} sm={6}>
                  {createTextField({
                    label: 'Entity Name',
                    fieldName: 'entityName',
                    testId: 'entityName',
                    store: store.entityStore.entityFormStore,
                    standaloneLabel: false,
                    disabled: isExistingEntity && !!entityFields.entityName.value,
                  })}
                </Grid>
                <Grid item xs={12} sm={6}>
                  {createNumberFormatField({
                    label: 'Entity EIN',
                    fieldName: 'entityEin',
                    testId: 'entityEin',
                    store: store.entityStore.entityFormStore,
                    format: '##-#######',
                    standaloneLabel: false,
                    disabled: isExistingEntity && !!entityFields.entityEin.value,
                  })}
                </Grid>
                <Grid item xs={12} sm={6}>
                  {createSelectField({
                    label: 'Entity Type',
                    fieldName: 'entityType',
                    testId: 'entityType',
                    options: entityTypes,
                    store: store.entityStore.entityFormStore,
                    standaloneLabel: false,
                    disabled: isExistingEntity && !!entityFields.entityType.value,
                  })}
                </Grid>
                <Grid item xs={12} sm={6}>
                  {createDateField({
                    label: 'Date of Incorporation',
                    fieldName: 'dateOfIncorporation',
                    testId: 'dateOfIncorporation',
                    store: store.entityStore.entityFormStore,
                    standaloneLabel: false,
                    disableFuture: true,
                    disabled: isExistingEntity && !!entityFields.dateOfIncorporation.value,
                  })}
                </Grid>
                <Grid item xs={12}>
                  <AddressDetailsField
                    name={'address'}
                    value={entityFormStore.form.fields.address.value}
                    onChange={value =>
                      entityFormStore.onFieldChange('address', value)
                    }
                    required
                    standaloneLabel={false}
                    error={
                      !entityFormStore.form.meta.isValid &&
                      isNotBlank(entityFormStore.form.fields.address.error)
                    }
                    helperText={
                      !entityFormStore.form.meta.isValid &&
                      entityFormStore.form.fields.address.error
                    }
                    disabled={isExistingEntity}
                  />
                </Grid>
              </>
            )}
            {showActions && (
              <Grid
                item
                container
                spacing={2}
                justifyContent="center"
                alignItems="center"
                style={{ padding: '1rem' }}
              >
                <EditControlsPreflightCards
                  onSaveClick={saveHandler}
                  onResetClick={handleReset}
                  onSkipClick={handleSkip}
                  disableButtons={
                    !store.entityStore.entityFormStore.form.meta.isValid
                  }
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </>
    );
  }
);

export const SearchEntityAutocompleteField = observer(
  ({ store }: { store: QuoteTypeStore }) => {
    const classNames = useStyles();

    useEffect(() => {
      store.entityStore.getEntityDropdownData();
    }, []);

    const handleSelection = entity => {
      store.entityStore.borrowersFormsStoreIds.forEach(id =>
        store.borrowersStore.removeBorrower(id)
      );
      store.entityStore.fetchEntityById(entity.value, () => {
        if (store.entityStore.borrowers.length > 0) {
          store.borrowersStore.resetBorrowerFormStores();
          store.entityStore.borrowers.forEach(borrower => {
            const borrowerFormStore = store.borrowersStore.createBorrowerForm();
            //borrowerFormStore.loadForm(borrower);
            borrowerFormStore.loadForm({
              borrowerId: borrower.borrowerId,
              firstName: borrower.firstName,
              lastName: borrower.lastName,
              emailAddress: borrower.emailAddress,
              ficoScore: borrower.ficoScore,
              numberOfVerifiedInvestmentProperties: borrower.experience === 0 ? '' : borrower.experience,
              assets: borrower.assets,
              liabilities: borrower.liabilities,
              projectsCompleted: !!borrower.portalProjectsCompleted || !!borrower.portalProjectsActive,
              percentageOfOwnership: borrower.percentOwnershipCurrent,
              experience: borrower.experience,
              citizenshipStatus: borrower.citizenshipStatus,
            });
            borrowerFormStore.setEditMode(false);
            store.entityStore.borrowersFormsStoreIds.push(borrowerFormStore.key);
            store.borrowersStore.addBorrower(borrowerFormStore);
          });
        }
        store.entityStore.entityFormStore.setIsValid(true);
      });
    };

    return (
      <Autocomplete
        id="autocomplete-entity"
        disableClearable={true}
        className={classNames.autocomplete}
        options={store.entityStore.entityDropdownData}
        getOptionLabel={option => option?.label ?? ''}
        renderInput={params => (
          <TextField
            {...params}
            label="Search entity"
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <InputAdornment position="end">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        )}
        onChange={(event, selected) => handleSelection(selected)}
        fullWidth
      />
    );
  }
);

const OrDivider = () => {
  return (
    <Box display="flex" alignItems="center" my={2}>
      <Divider style={{ flex: 1 }} />
      <Typography
        variant="body1"
        style={{
          marginLeft: '6px',
          marginRight: '6px',
          color: 'gray',
          fontWeight: 'bold',
        }}
      >
        or
      </Typography>
      <Divider style={{ flex: 1 }} />
    </Box>
  );
};
