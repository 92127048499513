import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { useProposalsRoutes } from '@roc/feature-proposals';
import { StandardDialog } from '@roc/ui';
import { observer } from 'mobx-react';
import { Route, Switch, useHistory, useParams } from 'react-router-dom';
import { useCommunicationRoutesConfig } from './hooks';
import { useLoanCommunicationStore } from './hooks/useLoanCommunicationStore';
import { MyChatContainer, SelectConversation } from './myChatContainer';

const useStyles = makeStyles((theme: Theme) => ({
  chatContainer: {
    flex: 1,
  },
}));

interface DealRoomConversationProps {
  showEmailAddress?: boolean;
  proposalId: string;
  tab: string;
}

export const DealRoomConversation = observer(
  ({
    showEmailAddress = false,
    proposalId,
    tab,
  }: DealRoomConversationProps) => {
    const classes = useStyles();
    const { loanCommunicationStore } = useLoanCommunicationStore();
    const { communicationRoutesConfig } = useCommunicationRoutesConfig();
    const { push } = useHistory();
    const { proposalsRoutesConfig } = useProposalsRoutes();
    return (
      <Switch>
        <Route path={communicationRoutesConfig.dealRoomConversations().path}>
          <>
            <StandardDialog
              open={true}
              maxWidth="lg"
              handleClose={() =>
                push(proposalsRoutesConfig.viewProposal(proposalId).url)
              }
              dialogContent={
                <>
                  <Box display="flex" height={'80vh'}>
                    <MyChatContainer
                      id={Number(proposalId)}
                      communicationStore={loanCommunicationStore}
                      allowNewConversations={false}
                      allowJoinConversation={true}
                      showEmailAddress={showEmailAddress}
                      showSnippet={false}
                      onConversationSelect={conversationId =>
                        push(
                          communicationRoutesConfig
                            .dealRoomConversations(proposalId, tab)
                            .children.conversation(conversationId).url
                        )
                      }
                      className={classes.chatContainer}
                    />
                  </Box>
                  <Switch>
                    <Route
                      exact
                      path={
                        communicationRoutesConfig
                          .dealRoomConversations()
                          .children.conversation().path
                      }
                    >
                      <SelectConversation
                        communicationStore={loanCommunicationStore}
                      />
                    </Route>
                  </Switch>
                </>
              }
            />
          </>
        </Route>
      </Switch>
    );
  }
);
