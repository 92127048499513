import { Grid, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import { BorrowerForm } from './borrowerForm';
import { PersonIcon } from './personIcon';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  subTitle: {
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '133.4%',
  },
  body: {
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '175%',
    letterSpacing: '0.15px',
  },
}));

export const EditPersonalInformation = observer(({ store, rental = false }) => {
  const classes = useStyles();

  return (
    <Grid container direction="row" spacing={2}>
      <Grid
        item
        xs={12}
        container
        direction="row"
        spacing={2}
        alignItems="center"
      >
        <Grid item>
          <PersonIcon />
        </Grid>
        <Grid item>
          <Typography variant="h5" className={classes.subTitle}>
            Edit Information
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5" className={classes.body}>
          Please fill out your information.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <BorrowerForm store={store.mainBorrowerFormStore} rental={rental} mainBorrower />
      </Grid>
    </Grid>
  );
});
