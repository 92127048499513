import React from 'react';
import { Grid } from '@material-ui/core';
import { observer } from 'mobx-react';
import { CostsTable } from './costsTable';
import { DealEconomicsStore } from '../../../../stores/documentForms/dealEconomicsStore';

export type CostsTablesProps = {
  store: DealEconomicsStore;
};

export const CostsTables = observer((props: CostsTablesProps) => {
  const { store } = props;

  return (
    <Grid item container spacing={2}>
      <Grid item xs={12} md={6}>
        <CostsTable title="Acquisition Costs" data={store.acquisitionCosts} />
      </Grid>
      <Grid item xs={12} md={6}>
        <CostsTable
          title="Sale Costs (Paid from Proceeds of Sale)"
          data={store.saleCosts}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <CostsTable title="Interim Expenses" data={store.interimExpenses} />
      </Grid>
      <Grid item xs={12} md={6}>
        <CostsTable
          title="Borrower Profit/Loss"
          data={store.borrowerProfitLoss}
        />
      </Grid>
    </Grid>
  );
});

export default CostsTables;
