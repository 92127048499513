import { Grid } from '@material-ui/core';
import {
  Button,
  ConfirmationButton,
  createAutocompleteAddressField,
  createNumberFormatField,
  createTextField,
  StandardDialog,
} from '@roc/ui';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useDocumentStore } from '../../../hooks/useDocumentStore';

interface Props {
  open?: boolean;
  onClose?: () => void;
}

export const TitleCompanyBankInformationDialog = observer((props: Props) => {
  const { open, onClose } = props;
  const { documentStore } = useDocumentStore();
  const { documentFormStore } = documentStore;
  const { titleCompanyBankInformationStore } = documentFormStore;
  const { canEdit, canSubmit } = titleCompanyBankInformationStore;
  const store = titleCompanyBankInformationStore;

  const onSubmit = () => {
    titleCompanyBankInformationStore.submit();
  };

  useEffect(() => {
    if (open) {
      titleCompanyBankInformationStore.initialize();
    }
  }, [open]);

  const handleAddressChange = event => {
    const { value, fullInfo } = event?.target || {};
    const { address_components, geometry } = fullInfo || {};
    titleCompanyBankInformationStore.handleAddressChange(
      value,
      address_components,
      geometry
    );
  };

  const disabled = !canEdit;

  return (
    <StandardDialog
      open={open}
      title="Title Company Bank Information"
      maxWidth="lg"
      handleClose={onClose}
      dialogContent={
        <Grid container spacing={2}>
          <Grid item xs={6}>
            {createTextField({
              store,
              testId: 'entityName',
              fieldName: 'entityName',
              label: 'Title Company Name',
              disabled,
            })}
          </Grid>
          <Grid item xs={6}>
            {createTextField({
              store,
              testId: 'bankName',
              fieldName: 'bankName',
              label: 'Bank Name',
              disabled,
            })}
          </Grid>
          <Grid item xs={6}>
            {createNumberFormatField({
              store,
              testId: 'routingNumber',
              fieldName: 'routingNumber',
              label: 'Routing Number',
              format: '#########',
              disabled,
            })}
          </Grid>
          <Grid item xs={6}>
            {createTextField({
              store,
              testId: 'accountName',
              fieldName: 'accountName',
              label: 'Account Name',
              disabled,
            })}
          </Grid>
          <Grid item xs={6}>
            {createNumberFormatField({
              store,
              testId: 'accountNumber',
              fieldName: 'accountNumber',
              label: 'Account Number',
              disabled,
            })}
          </Grid>
          <Grid item xs={6}>
            {createTextField({
              store,
              testId: 'referenceId',
              fieldName: 'referenceId',
              label: 'Reference Id',
              disabled,
            })}
          </Grid>
          {store.isGeraci && (
            <>
              <Grid item xs={6}>
                {createNumberFormatField({
                  store,
                  testId: 'ffcBankName',
                  fieldName: 'ffcBankName',
                  label: 'FFC Bank Name',
                  disabled,
                })}
              </Grid>
              <Grid item xs={6}>
                {createNumberFormatField({
                  store,
                  testId: 'ffcRoutingNumber',
                  fieldName: 'ffcRoutingNumber',
                  label: 'FFC Routing Number',
                  disabled,
                })}
              </Grid>
              <Grid item xs={6}>
                {createNumberFormatField({
                  store,
                  testId: 'ffcAccountName',
                  fieldName: 'ffcAccountName',
                  label: 'FFC Account Name',
                  disabled,
                })}
              </Grid>
              <Grid item xs={6}>
                {createNumberFormatField({
                  store,
                  testId: 'ffcAccountNumber',
                  fieldName: 'ffcAccountNumber',
                  label: 'FFC Account Number',
                  disabled,
                })}
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            {createAutocompleteAddressField({
              store,
              testId: 'address',
              fieldName: 'address',
              label: 'Address',
              onChange: handleAddressChange,
              disabled,
            })}
          </Grid>
        </Grid>
      }
      dialogActions={
        <Grid container spacing={1} justifyContent="flex-end">
          <Grid item>
            <Button
              onClick={onClose}
              color="primary"
              variant="outlined"
              testId="cancel"
            >
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <ConfirmationButton
              confirmDialogProps={{
                body: 'Are you sure you want to submit this bank information?',
              }}
              onClick={onSubmit}
              disabled={!canSubmit}
              color="primary"
              variant="contained"
              testId="confirm"
            >
              Submit
            </ConfirmationButton>
          </Grid>
        </Grid>
      }
    />
  );
});
