import { Service } from './service';

const url = {
  GET_LENDER_BO_BY_COMPANY_ID: '/api/v1/loan/getLenderBOByCompanyId',
};

export class LenderService extends Service {
  getLenderBOByCompanyId(companyId) {
    return this.get(url.GET_LENDER_BO_BY_COMPANY_ID, { companyId });
  }
}
