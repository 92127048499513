import { CSSProperties, useEffect, useRef } from 'react';

export const HtmlMessageContent = ({ id, html, style }: {
  id?: string,
  html: any,
  style?: CSSProperties
}) => {
  const ref = useRef<HTMLDivElement>();
  const shadowRef = useRef<ShadowRoot>();
  useEffect(() => {
    if (ref.current && !shadowRef.current) {
      shadowRef.current = ref.current.attachShadow({ mode: 'open' });
    }
  }, [ref.current]);
  useEffect(() => {
    if (shadowRef.current) {
      shadowRef.current.innerHTML = `
        <div class="root">
          <style>
            :host {
              display: inline-flex;
            }
            .root {
              white-space: normal;
            }
            .root * {
              line-height: normal !important;
            }
            .root-body>p:first-child,
            .root-body>p:last-child {
              margin:0;
              min-height: auto;
            }
            .root-body>p {
              min-height: 21px;
            }
            .root-body>p,
            .root-body>ul {
              margin: 4px 0;
            }
            .root table,
            .root #backgroundTable {
              width: 100% !important;
            }
            .root table td,
            .root h1,
            .root h2,
            .root h3,
            .root h4,
            .root h5,
            .root h6 {
              line-height: normal;
            }
            .root blockquote {
              border-left: 3px solid;
              padding: 5px 0 5px 5px;
            }
            .root .wysiwyg-mention {
              color: #005f9b;
              background: #d7efff;
              padding: 2px 4px;
              font-size: 12px;
              border-radius: 4px;
              text-decoration: none;
              cursor: auto;
              font-weight: bold;
            }
          </style>
          <div class="root-body">${html}</div>
        </div>
      `;
      setTimeout(() => {
        var linkList = shadowRef.current.querySelectorAll('a');
        linkList.forEach(link => {
          link.addEventListener('click', (event) => {
            event.preventDefault();

            const url = link.getAttribute('href');
            if ((window as any).require) {
              const { shell } = (window as any).require('electron');
              shell?.openExternal(url);
            } else {
              window.open(url);
            }
          });
        });
      }, 1000);
    }
  }, [html, shadowRef.current]);
  return <div id={id} data-message-sid={id} ref={ref} style={style} />;
};