import { observer } from "mobx-react";
import { Checkbox, IconButton } from "@material-ui/core";
import { DataGrid, DialogState, EditableDialog, GridColumnProps, SelectMode } from "@roc/ui";
import { useEffect, useState } from "react";
import { useRocInternalPortalStore } from "apps/roc-internal-portal/src/app/hooks";
import { Edit } from "@material-ui/icons";
import { formatCurrency } from "@roc/ui/utils";
import { EditDqDataModalForm } from "./editDataModalForm";

const columns: GridColumnProps[] = [
  {
    field: 'tempId',
    headerName: '',
    maxWidth: 75,
    cellRenderer: 'selectedCellRenderer',
    sortable: false,
  },
  {
    field: 'loanId',
    headerName: 'Loan Id',
    maxWidth: 120,
    sortable: false,
  },
  {
    field: 'servicerId',
    headerName: 'Servicer Id',
    maxWidth: 150,
    sortable: false,
  },
  {
    field: 'address',
    headerName: 'Address',
    minWidth: 300,
    sortable: false,
  },
  {
    field: 'dqAmount',
    headerName: 'Amount',
    cellRenderer: 'amountRenderer',
    maxWidth: 120,
    sortable: false,
  },

  {
    field: 'appliedDqAmount',
    headerName: 'Applied Amount',
    cellRenderer: 'amountRenderer',
    maxWidth: 150,
    sortable: false,
  },
  {
    field: 'comments',
    headerName: 'Comments',
    minWidth: 100,
    sortable: false,
  },
  {
    headerName: '',
    cellRenderer: 'actionCellRenderer',
    maxWidth: 100,
    sortable: false,
  }
]

export const DrawDqGrid = observer(() => {
  const { drawDqDataInternalStore } = useRocInternalPortalStore();
  const gridStore = drawDqDataInternalStore.drawDqDataGridStore;
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (drawDqDataInternalStore.drawId) { gridStore.fetchGridData() };
  }, [gridStore, drawDqDataInternalStore.drawId])

  const CheckboxColumn = observer(({ store, node }) => {
    const id = node.data?.tempId;
    const isSelected = store.selectedItems.some(
      p => id === p.tempId
    )
    return (
      <Checkbox
        checked={isSelected}
        onChange={(e, checked) => {
          store.selectedRow(node.data);
        }}
        color="primary"
      />
    );
  })

  const frameworkComponents = {
    actionCellRenderer: (params) => {
      return (
        <IconButton onClick={() => onEditData(params.node.data)}>
          <Edit color="primary" />
        </IconButton>
      )
    },
    amountRenderer: ({ value }) => {
      return value ? formatCurrency(value) : '';
    },
  }

  const getDialogContent = () => {
    return <EditDqDataModalForm store={drawDqDataInternalStore} />
  };

  const onEditData = dqData => {
    setModalOpen(true);
    drawDqDataInternalStore.editDqDataStore.loadDqData(dqData);
  };

  const closeModal = () => {
    setModalOpen(false);
  }

  const handleEdit = () => {
    drawDqDataInternalStore.setLoanDqDataEdit();
    closeModal();
  }

  return (
    <>
      <DataGrid
        columns={columns}
        rows={gridStore.gridData.data.rows}
        domLayout="autoHeight"
        suppressRowClickSelection={true}
        frameworkComponents={{
          ...frameworkComponents,
          selectedCellRenderer: params => {
            return <CheckboxColumn store={drawDqDataInternalStore} node={params.node} />
          },
        }}
      />
      <EditableDialog
        title={'Delinquent Loan'}
        open={modalOpen}
        dialogState={DialogState.EDIT}
        handleClose={closeModal}
        dialogContent={getDialogContent()}
        handleEdit={() => handleEdit()}
      />
    </>
  )
})
