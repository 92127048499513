import { Grid } from '@material-ui/core';
import {
  createAutocompleteAddressField,
  createCurrencyField,
  createDateField,
  createNumberFormatField,
  createPhoneField,
  createSelectField,
  createTextField,
} from '@roc/ui/componentBuilder';
import { FormStore } from '@roc/feature-app-core';
import { observer } from 'mobx-react';
import { BorrowerBaseballCardBaseStore } from 'libs/feature-borrowers/src/borrowers/stores/borrowerBaseballCardBaseStore';
import { PropertyFormStore } from 'libs/feature-borrowers/src/borrowers/stores/propertyFormStore';
import { stateMapping, exitStrategies, isNotBlank } from '@roc/feature-utils';
import { useStore } from 'apps/client-portal-public/src/app/hooks/useStore';
import { AutocompleteAddressField } from '../autocompleteAddressField/autocompleteAddressField';
import { useRef } from 'react';

const addressLine2Tooltip =
  'Address Line 2 can be used for additional information\nsuch as apartment number, suite number, unit number\nor others details that can help in identifying the\nexact location of the address.';

interface PropertyField {
  store: PropertyFormStore;
  disabled?: boolean;
  onChange?: () => void;
}

const field = component => {
  const WrappedComponent = observer(component);
  return props => (
    <Grid item xs={12}>
      <WrappedComponent {...props} />
    </Grid>
  );
};

export const DealType = field(({ store, disabled }: PropertyField) => {
  return createSelectField({
    store,
    options: [{ label: 'Bridge', value: 'Bridge' }, { label: 'Rental', value: 'Rental' }],
    label: 'Deal Type',
    fieldName: 'dealType',
    testId: 'dealType',
  });
});

export const PropertyType = field(({ store, disabled }: PropertyField) => {
  return createSelectField({
    store,
    options: [{ label: 'Residential', value: 'Residential' }, { label: 'Commercial', value: 'Commercial' }],
    label: 'Property Type',
    fieldName: 'propertyType',
    testId: 'propertyType',
  });
});

export const EntityName = field(({ store, disabled }: PropertyField) => {
  return createTextField({
    store,
    label: 'Entity Name',
    fieldName: 'entityName',
    testId: 'entityName',
    disabled: disabled,
  });
});

export const BuyerName = field(({ store, disabled }: PropertyField) => {
  return createTextField({
    store,
    label: 'Buyer Name',
    fieldName: 'soldTo',
    testId: 'soldTo',
    disabled: disabled,
  });
});

export const PropertyCity = field(({ store, disabled }: PropertyField) => {
  return createTextField({
    store,
    label: 'City',
    fieldName: 'purchaseCity',
    testId: 'purchaseCity',
    disabled,
  });
});

export const PropertyState = field(({ store, disabled }: PropertyField) => {
  return createSelectField({
    store,
    label: 'State',
    fieldName: 'purchaseState',
    testId: 'purchaseState',
    disabled,
    options: stateMapping
  });
});

export const PurchaseDate = field(({ store, disabled }: PropertyField) => {
  return createDateField({
    store,
    label: 'Purchase Date',
    fieldName: 'purchaseDate',
    testId: 'purchaseDate',
    disabled: disabled,
  });
});

export const PurchaseAmount = field(({ store, disabled }: PropertyField) => {
  return createCurrencyField({
    store,
    label: 'Purchase Amount',
    fieldName: 'purchasePrice',
    testId: 'purchasePrice',
    disabled: disabled,
  });
});

export const MortgageAmount = field(({ store, disabled }: PropertyField) => {
  return createCurrencyField({
    store,
    label: 'Mortgage Amount',
    fieldName: 'mortgageAmount',
    testId: 'mortgageAmount',
    disabled: disabled,
  });
});

export const SellerName = field(({ store, disabled }: PropertyField) => {
  return createTextField({
    store,
    label: 'Seller Name',
    fieldName: 'purchasedFrom',
    testId: 'purchasedFrom',
    disabled: disabled,
  });
});

export const LenderName = field(({ store, disabled }: PropertyField) => {
  return createTextField({
    store,
    label: 'Lender Name',
    fieldName: 'lenderName',
    testId: 'lenderName',
    disabled: disabled,
  });
});

export const SaleDate = field(({ store, disabled }: PropertyField) => {
  return createDateField({
    store,
    label: 'Sale Date',
    fieldName: 'saleDate',
    testId: 'saleDate',
    disabled: disabled,
  });
});

export const SaleAmount = field(({ store, disabled }: PropertyField) => {
  return createCurrencyField({
    store,
    label: 'Sale Amount',
    fieldName: 'salePrice',
    testId: 'salePrice',
    disabled: disabled,
  });
});

export const RehabBudgetAmount = field(({ store }: PropertyField) => {
  return createCurrencyField({
    store,
    label: 'Rehab Budget',
    fieldName: 'rehabBudget',
    testId: 'rehabBudget',
  });
});

export const ExitStrategy = field(({ store }: PropertyField) => {
  return createSelectField({
    store,
    label: 'Exit Strategy',
    fieldName: 'exitStrategy',
    testId: 'exitStrategy',
    options: exitStrategies,
  });
});

export const PropertyAddress = field(({ store, disabled, onChange }: PropertyField) => {
  return <Address store={store} disabled={disabled} onChange={onChange} />;
})

export const Address = observer(({ store, disabled, onChange }) => {
  const { form } = store;

  const handleAddressChange = (event, name) => {
    const { value, fullInfo } = event?.target || {};
    const { address_components, geometry } = fullInfo || {};
    store.saveAddressField(value, address_components, geometry);
  };
  return (
    <AutocompleteAddressField
      label="Address Line 1"
      disabled={disabled}
      required={true}
      name="purchaseAddress"
      value={form.fields.purchaseAddress.value}
      error={isNotBlank(form.fields.purchaseAddress.error)}
      errorText={form.fields.purchaseAddress.error}
      onChange={handleAddressChange}
      fullWidth
      testId="address"
      standaloneLabel={true}
    />
  );
});

export const ZipCode = field(({ store, disabled }: PropertyField) => {
  return createTextField({
    store,
    label: 'Zip Code',
    fieldName: 'purchaseZipcode',
    testId: 'purchaseZipcode',
    disabled,
  });
});

export const AddressLine2 = field(({ store, disabled }: PropertyField) => {
  return createTextField({
    label: 'Address Line 2',
    fieldName: 'aptNumber',
    testId: 'aptNumber',
    store: store,
    tooltip: addressLine2Tooltip,
    disabled,
  });
});

export const StreetName = field(({ store }: PropertyField) => {
  return createTextField({
    store,
    label: 'Street Name',
    fieldName: 'streetName',
    testId: 'streetName',
    disabled: true,
  });
});

export const StreetNumber = field(({ store }: PropertyField) => {
  return createTextField({
    store,
    label: 'Street #',
    fieldName: 'streetNumber',
    testId: 'streetNumber',
    disabled: true,
  });
});

export const UnitNumber = field(({ store }: PropertyField) => {
  return createTextField({
    store,
    label: 'Apt/Unit #',
    fieldName: 'unitNumber',
    testId: 'unitNumber',
  });
});