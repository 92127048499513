import { Box, Typography } from "@material-ui/core";
import { observer } from "mobx-react";
import { makeStyles } from '@material-ui/core/styles';
import { HtmlContainer } from "@roc/ui";

const useStyles = makeStyles(theme => ({

}));


export const Task = observer(({title, description}) => {
  return (
    <Box>
      <Typography variant={'h6'}>
        {title}
      </Typography>
      <Typography>
        <HtmlContainer html={description} />
      </Typography>
    </Box>
  )
})