import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  notAvailableText: {
    fontWeight: 'bold',
    textAlign: 'center',
  },
});

export const FundingTemplateNotAvailable = () => {
  const classes = useStyles();
  return (
    <Grid container alignItems="center" justifyContent="center">
      <Grid item xs={6}>
        <Typography className={classes.notAvailableText}>
          Funding Template is not available right now, it will be available
          soon.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default FundingTemplateNotAvailable;
