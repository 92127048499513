import { Box, Button, Grid, Slide, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import Carousel from 'react-material-ui-carousel';
import google_logo from 'libs/feature-valuation-review/src/assets/Google_logo.png';
import zillow_logo from 'libs/feature-valuation-review/src/assets/zillow_logo.png';

interface AppraisalReviewRevisionListProps {
  photosSection: any;
}

export const AppraisalReviewCarousel = observer(({ photosSection }: AppraisalReviewRevisionListProps) => {

  const getAddressFromText = (text: string): string => {
    if (text?.includes('\n')) {
      const lines = text.split('\n');
      if (lines.length >= 3) {
        return `${lines[1]}, ${lines[2]}`;
      }
    }
    return text;
  };


  return (
    <Box mb={3} display="flex" justifyContent="center">
      <Grid container justifyContent="center">
        <Carousel autoPlay={false} navButtonsAlwaysVisible>
          {photosSection?.photos?.map((photo, index) => (
            <Grid item key={index} >
              <Slide direction="right" in={true} timeout={1000} mountOnEnter unmountOnExit>
                <div>
                  <img src={photo.path} alt={`Photo ${index + 1}`} style={{ width: '100%', height: 'auto', minWidth: '450px', maxWidth: '700px', minHeight: '550px', maxHeight: '750px' }} loading="lazy" />
                  <Box maxWidth="700px" style={{ whiteSpace: 'pre-line' }}>
                    <Typography variant="subtitle1" align="center" paragraph>
                      {getAddressFromText(photo.text)}
                    </Typography>
                  </Box>
                  <Box display="flex" justifyContent="center" mt={2}>
                    {photo.googleLink && (
                      <a href={photo.googleLink} target="_blank" rel="noopener noreferrer">
                        <Button variant="contained" style={{ marginLeft: '10px' }}>
                          <img src={google_logo} style={{ height: 20, width: 'auto' }} alt="Google Logo" />
                        </Button>
                      </a>)}
                    {photo.zillowLink && (
                      <a href={photo.zillowLink} target="_blank" rel="noopener noreferrer">
                        <Button variant="contained" style={{ marginLeft: '10px' }}>
                          <img src={zillow_logo} style={{ height: 20, width: 'auto' }} alt="Zillow Logo" />
                        </Button>
                      </a>)}
                  </Box>
                </div>
              </Slide>
            </Grid>
          ))}
        </Carousel>
      </Grid>
    </Box>
  );
});