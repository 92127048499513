import { GlobalStore } from '@roc/feature-app-core';
import { LoanService, LoanStore } from '@roc/feature-loans';
import { CLOSING } from '@roc/feature-utils';
import { flow, makeObservable, observable } from 'mobx';
import { LoanActionsService } from '../services/loanActionsService';

export class GenerateClosingDocsStore {
  private globalStore: GlobalStore;
  private loanStore: LoanStore;

  private loanActionsService: LoanActionsService;

  checkResult;

  constructor(globalStore: GlobalStore, loanStore: LoanStore) {
    this.globalStore = globalStore;
    this.loanStore = loanStore;

    this.loanActionsService = new LoanActionsService();

    makeObservable(this, {
      checkResult: observable,
      checkRequirements: flow,
      generateClosingDocuments: flow,
    });
  }

  resetStore() {
    this.checkResult = null;
  }

  *checkRequirements() {
    try {
      const { loanId } = this.loanStore.loanDetails;
      const response = yield this.loanActionsService.checkRequirementsForGeneratingClosingDocs(
        loanId
      );
      this.checkResult = response.data.data;
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message:
          'Error while checking requirements for generating the closing docs',
      });
    }
  }

  *generateClosingDocuments() {
    try {
      const { loanId } = this.loanStore.loanDetails;
      yield this.loanActionsService.generateClosingDocs(loanId);
      this.globalStore.notificationStore.showInfoNotification({
        message:
          'The closing documents are being generated. This operation may take up to a couple of minutes.',
      });
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while making request',
      });
    }
  }
}
