import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Navigation, useAppConfigurations, useBaseStore } from '@roc/feature-app-core';
import { DisclosureLoanDetails } from '@roc/feature-loans';
import { VerticalBarLoader } from '@roc/ui';
import axios from 'axios';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';

const useStyles = makeStyles((theme) => ({
  container: {
    margin: 'auto',
    '& h1,h2,h3,h4,h5': {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(1),
    },
    '& ul': {
      margin: theme.spacing(1, 3),
    },
  },
}));

const IubendaEmbedPolicy = ({ showHeader, url }) => {

  const classes = useStyles();
  const { globalStore } = useBaseStore();
  const { siteName, data: { fromEmail } } = useAppConfigurations();
  const [loading, setLoading] = useState(true);
  const [htmlContent, setHtmlContent] = useState<any>();

  useEffect(() => {
    globalStore.setShowNavigation(showHeader);
  }, [showHeader])

  useEffect(() => {
    const getContent = async () => {
      const response = await axios.get(url);
      let content: string = response.data.content;
      content = content.replace(/Private Lender Portal/g, siteName);
      content = content.replace(/info@privatelenderportal.com/g, fromEmail);
      setHtmlContent(content);
      setLoading(false);
    }
    getContent();
  }, []);

  return (
    <Container maxWidth='md' className={classes.container}>
      {showHeader && <Navigation showSupportIcon={false} />}
      {loading && <VerticalBarLoader />}
      <div dangerouslySetInnerHTML={{
        __html: htmlContent
      }}></div>
      <br />
      <DisclosureLoanDetails />
    </Container>
  )
}

export const PrivacyPolicy = observer(({
  showHeader = true
}: {
  showHeader?: boolean
}) => {
  return (
    <IubendaEmbedPolicy showHeader={showHeader} url="https://www.iubenda.com/api/privacy-policy/16493128/no-markup" />
  );
});

export const CookiePolicy = observer(({
  showHeader = true
}: {
  showHeader?: boolean
}) => {
  return (
    <IubendaEmbedPolicy showHeader={showHeader} url="https://www.iubenda.com/api/privacy-policy/16493128/cookie-policy/no-markup" />
  );
});

export const TermsAndConditions = observer(({
  showHeader = true
}: {
  showHeader?: boolean
}) => {
  return (
    <IubendaEmbedPolicy showHeader={showHeader} url="https://www.iubenda.com/api/terms-and-conditions/16493128/no-markup" />
  );
});