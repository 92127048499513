import {ApiResponse} from "@roc/feature-app-core";
import {action,computed,flow,makeObservable,observable} from "mobx";
import {ConversationAttribute,ResetUnreadCountRequest} from "../types/communicationTypes";
import {CommunicationStore} from "./communicationStore";


export class DealRoomTopicCommunicationStore extends CommunicationStore {
  responseValues: any[];
  openModal: boolean=false;
  proposalsConversationSid: string;

  constructor(globalStore,userStore,communicationService,isInternal=false) {
    super(globalStore,userStore,communicationService,isInternal);
    this.openModal = false;
    makeObservable(this,{
      fetchUnreadMessagesCount: flow,
      resetUnreadCount: flow,
      updateConversationStarredStatus: flow,
      getParticipantsAllowedByLoanId: flow,
      getParticipantsAllowedByChatRoomId: flow,
      responseValues: observable,
      openModal: observable,
      setModalOpen: action,
      proposalsConversationSid: observable
    });
  }

  setModalOpen(value: boolean) {
    this.openModal=value;
  }

  setproposalsConversationSid(value: string) {
    this.proposalsConversationSid=value;
    this.currentConversationSid=value;
  }

  get getTotalUnreadMessagesCount() {
    return Object.values(this.unreadMessagesCount).reduce((p,n) => p+n,0)
  }

  *fetchUnreadMessagesCount(userId: number,proposalTopicId?: number) {
    this.unreadMessagesCount={};
    const response=yield this.communicationService.getUnreadCountByUserId(proposalTopicId);
    const data=response.data.data;
    data.forEach(item => this.unreadMessagesCount[item.conversationSid]=item.unreadCount)
  }

  *resetUnreadCount(proposalTopicId: number) {
    try {
      const request: ResetUnreadCountRequest={
        chatRoomId: this.currentChatRoomId,
        chatRoomType: this.currentChatRoomType,
        userId: this.userId,
        conversationSid: this.currentConversationSid,
        proposalTopicId,
      }
      this.communicationService.resetUnreadCount(request);
    } catch(e) {
      // do nothing
    }
  }

  *updateConversationStarredStatus(isStarred: boolean,chatRoomId: number,conversationSid: string,callBack?: () => void) {
    throw Error('Not Implemented!');
  }

  *getParticipantsAllowedByChatRoomId(chatroomId: number,disableGlobalLoading=false) {
    try {
      const response: ApiResponse=yield this.communicationService.getParticipantsAllowedByChatroomId(chatroomId,disableGlobalLoading);
      const {data}=response;
      return data.data;
    } catch(error) {
      return {};
    }
  }


  *getParticipantsAllowedByLoanId(id, disableGlobalLoading = false) {
    throw new Error("getParticipantsAllowedByLoanId not implemented for Support Chatrooms");
  }


  *setConversationMessageDefaultTag(conversationAttribute: ConversationAttribute,conversationInfo) {
    if(conversationAttribute?.loanId) {
      const response: ApiResponse=yield this.communicationService.getConversationMessageDefaultTag(conversationAttribute?.loanId,[conversationInfo]);
      if(response?.data?.data) {
        const result=response?.data?.data.result;
        [conversationInfo].forEach(c => {
          if(result[c.conversationSid]) {
            this.conversationMessageDefaultTag[c.conversationSid]=result[c.conversationSid];
          }
        });
      }
    }
  }

}