import { Box } from '@material-ui/core';
import { observer } from 'mobx-react';
import { useLoanActionsStore } from '../hooks/useLoanActionsStore';

export const LoanActionsPadding = observer(() => {
  const { loanActionsStore } = useLoanActionsStore();
  // Add some padding on the bottom of the screen to prevent the
  // "Take Action" button from overlapping the loan details.
  return <>{loanActionsStore?.showActions && <Box pt={8} />}</>;
});
