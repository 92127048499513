import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Link,

  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { BreadCrumbItem } from '@roc/feature-types';
import clsx from 'clsx';
import React from 'react';
import { useHistory } from 'react-router';

type BreadcrumbsNewProps = {
  breadCrumbItems: BreadCrumbItem[];
  className?: string;
  breadCrumbItemClick?: (index: number) => void;
  separator?: React.ReactNode;
};

type BreadcrumbsProps = {
  className?: string;
  paths: string[];
  onPathClick: (index: number) => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  breadcrumbLink: {
    color: theme.palette.primary.dark,
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.primary.dark,
      textDecoration: 'underline',
    },
    '& svg': {
      width: 16,
      height: 16,
    }
  },
  breadcrumbContainer: {
    overflow: 'auto',
    '& ol': {
      flexWrap: 'nowrap',
      whiteSpace: 'nowrap',
    },
  },
}));

export const Breadcrumbs = (props: BreadcrumbsProps) => {
  const { className, paths, onPathClick } = props;
  const breadCrumbItems: BreadCrumbItem[] = paths.map((path) => {
    return {
      link: true,
      title: path
    }
  })
  return (
    <BreadcrumbsNew className={className} breadCrumbItems={breadCrumbItems} breadCrumbItemClick={onPathClick} />
  )
};

export const BreadcrumbsNew = (props: BreadcrumbsNewProps) => {
  const { className, breadCrumbItems, breadCrumbItemClick, separator } = props;
  const { push } = useHistory();
  const classes = useStyles();

  const renderBreadcrumbItem = (breadCrumbItem: BreadCrumbItem, index: number) => {
    return (
      breadCrumbItem.link ?
        <Link
          key={breadCrumbItem.title}
          variant="body1"
          className={classes.breadcrumbLink}
          onClick={() => breadCrumbItemClick ? breadCrumbItemClick(index) : push(breadCrumbItem.url)}
          style={{ display: 'flex' }}
        >
          <Box display='flex' alignItems='center' mr={.5}>
            {breadCrumbItem.icon}
          </Box>
          {breadCrumbItem.title}
        </Link> :
        <Box display="flex" key={breadCrumbItem.title}>
          <Box display='flex' alignItems='center' mr={.5}>
            {breadCrumbItem.icon}
          </Box>
          <Typography component={'span'} style={{
            color: '#111'
          }}>
            {breadCrumbItem.title}
          </Typography>
        </Box>
    );
  };

  return (
    <MuiBreadcrumbs
      separator={separator}
      className={clsx([classes.breadcrumbContainer, className])}>
      {breadCrumbItems?.map(renderBreadcrumbItem)}
    </MuiBreadcrumbs>
  );
};