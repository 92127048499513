import { SelectField } from '@roc/ui';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { TodoStatus, StatusFilterType } from '@roc/feature-loan-details';

const useStyles = makeStyles((theme: Theme) => ({
  selectField: {
    '& .MuiOutlinedInput-input': {
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),
    },
  },
}));

interface StatusFilterFieldProps {
  value: string | boolean;
  onChange: (val: string|boolean) => void;
  version?: 'v1' | 'v2';
}

export const StatusFilterField = ({ value, onChange, version = 'v1' }: StatusFilterFieldProps) => {
  const classes = useStyles();
  const options = version === 'v1'
    ? [
      { label: 'Show Pending To-dos', value: true },
      { label: 'Show All To-dos', value: false }
    ] : [
      { label: 'Show In Review and Pending To-dos', value: StatusFilterType.IN_REVIEW_OR_PENDING },
      { label: 'Show Pending To-dos', value: TodoStatus.PENDING },
      { label: 'Show All To-dos', value: TodoStatus.ALL }
    ];
  return (
    <SelectField
      value={value}
      options={options}
      onChange={onChange}
      variant={'outlined'}
      testId={'pendingTodosFilter'}
      className={classes.selectField}
    />
  );
};
