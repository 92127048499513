import { Service } from '@roc/client-portal-shared/services';

const url = {
  DOCUSIGN_PAYMENT_STATUS:
    '/nportal/rest/pub/payments/getPaymentStatus?loanId=',
  APPRAISAL_PAYMENT_STATUS:
    '/nportal/rest/pub/payments/getAppraisalPaymentStatus?entityId=',
  TAMARISK_DOCUMENT: '/nportal/rest/pub/payments/getTamariskDocusign?entityId=',
  CDA_DOCUMENT: '/nportal/rest/pub/payments/getCDADocusign?entity=',
  ARR_CDA_DOCUMENT: '/nportal/rest/pub/payments/getARRDocusign?entity=',
  APPRAISAL_RECERTIFICATION_DOCUMENT: '/nportal/rest/pub/payments/getAppraisalRecertificationDocusign?entity=',
  LOAN_FASTRACK_DOCUMENT: '/nportal/rest/pub/payments/getLoanFastrackDocusign?entityId=',
  GFD_DOCUMENT: '/nportal/rest/pub/payments/getGFDDocusign?loanId=',
  PERSIST_BORROWER_DATA: '/nportal/rest/pub/payments/persistBorrowerData',
};

export class LoanTermsService extends Service {
  getDocusignAndPaymentStatus(loanId: string, entityType: string) {
    return this.get(`${url.DOCUSIGN_PAYMENT_STATUS}${loanId}&entityType=${entityType}`);
  }

  getAppraisalPaymentStatus(entityId: string, type: string) {
    return this.get(`${url.APPRAISAL_PAYMENT_STATUS}${entityId}&type=${type}`);
  }

  getTamariskDocusign(request) {
    return this.post(
      `${url.TAMARISK_DOCUMENT}`,
      request,
      undefined,
      {
        responseType: 'text',
      }
    );
  }

  getArrDocusign(request) {
    return this.post(
      `${url.ARR_CDA_DOCUMENT}`,
      request,
      undefined,
      {
        responseType: 'text',
      }
    );
  }

  getAppraisalRecertificationDocusign(request) {
    return this.post(
      `${url.APPRAISAL_RECERTIFICATION_DOCUMENT}`,
      request,
      undefined,
      {
        responseType: 'text',
      }
    );
  }

  getCDADocusign(request) {
    return this.post(
      `${url.CDA_DOCUMENT}`,
      request,
      undefined,
      {
        responseType: 'text',
      }
    );
  }

  getLoanFastrackDocusign(request) {
    return this.post(
      `${url.LOAN_FASTRACK_DOCUMENT}`,
      request,
      undefined,
      {
        responseType: 'text',
      }
    );
  }

  getGFDDocusign(loanId: string, callbackUrl: string) {
    return this.get(
      `${url.GFD_DOCUMENT}${loanId}&callbackUrl=${callbackUrl}`,
      undefined,
      {
        responseType: 'text',
      }
    );
  }

  persistBorrowerData(
    id: string,
    cardholderName: string,
    cardholderEmail: string,
    tamariskFlowType: string
  ) {
    const body = {
      id,
      cardholderName,
      cardholderEmail,
      tamariskFlowType
    };

    return this.post(`${url.PERSIST_BORROWER_DATA}`, body);
  }

  persistBorrowerDataForFastrack(
    id: string,
    cardholderName: string,
    cardholderEmail: string,
    flowType: string
  ) {
    const body = {
      id,
      cardholderName,
      cardholderEmail,
      tamariskFlowType: flowType
    };

    return this.post(`${url.PERSIST_BORROWER_DATA}`, body);
  }
}
