import * as React from 'react';
import {
  Paper,
  StepLabel,
  Step,
  Stepper,
  Box,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography
} from '@material-ui/core';
import { observer } from 'mobx-react';
import { approvalFlowStatus } from '@roc/feature-utils';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  paper: {
    margin: theme.spacing(2, 0),
    boxShadow:
      '0px 0px 2px 0px rgba(145, 158, 171, 0.24), 0px 16px 32px -4px rgba(145, 158, 171, 0.24)',
    borderRadius: '8px',
  },
  stepLabel: {
    transition: 'color 0.4s',
    '&:hover': {
      cursor: 'pointer',
      transform: 'scale(1.2)',
    },
  },
  root: {
    '& .MuiStepIcon-active': {
      color: '#3BA755',
      //border: '4px solid #3BA755',
      borderRadius: '50%',
    },
    '& .MuiStepIcon-completed': { color: '#3BA755' },
  },
}));

export const ApprovalFlowStepper = observer(({ companyProfileStore }) => {
  const { salesforceLenderStore } = companyProfileStore;
  const formStore = companyProfileStore.approvalFlowStepperStore;
  const classes = useStyles();
  const [confirmationStep, setConfirmationStep] = React.useState(null);
  const handleStepClick = step => {
    setConfirmationStep(step);
  };

  const handleStepConfirmation = async confirm => {
    if (confirm) {
      const selectedStatus = approvalFlowStatus[confirmationStep].value;
      await formStore.saveLenderDetails(selectedStatus);
    }
    formStore.reloadLenderDetails();
    setConfirmationStep(null);
  };

  const handleCloseConfirmation = () => {
    setConfirmationStep(null);
  }

  const getConfirmationDialogText = step => {
    const stepData = approvalFlowStatus[step];
    return stepData ? stepData.label : 'Unknown Step';
  };

  React.useEffect(() => {
    formStore.loadLenderDetails();
  }, []);

  React.useEffect(() => {
    formStore.loadLenderDetails();
  }, [salesforceLenderStore.salesForceLender]);

  const closeErrorModal = () => {
    formStore.setErrorModalOpen(false);
    formStore.setErrorModalMessage('');
  };

  return (
    <Paper className={classes.paper}>
      <Box>
        <Grid container>
          <Grid item xs={12} container justifyContent="center">
            <Stepper
              alternativeLabel
              className={classes.root}
              activeStep={approvalFlowStatus.findIndex(
                status => status.value === formStore.form.fields.status?.value
              )}
            >
              {approvalFlowStatus.map((label, index) => (
                <Step key={index} onClick={() => handleStepClick(index)}>
                  <StepLabel className={classes.stepLabel} icon=" ">
                    {label.label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Grid>
        </Grid>
      </Box>
      <Dialog
        open={confirmationStep !== null}
        onClose={handleCloseConfirmation}
      >
        <DialogTitle>
          Change account status to {getConfirmationDialogText(confirmationStep)}?
        </DialogTitle>
        <DialogContent>Are you sure you want to proceed?</DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmation} color="default" variant="outlined">
            No
          </Button>
          <Button onClick={() => handleStepConfirmation(true)} color="primary" variant="contained">
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={formStore.errorModalOpen}
        onClose={closeErrorModal}
      >
        <DialogTitle>
          Error trying to update Lender Status
        </DialogTitle>
        <DialogContent>
          <pre style={{ whiteSpace: 'pre-wrap', fontFamily: 'Arial' }}>
            {formStore.errorModalMessage}
          </pre>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeErrorModal} color="default" variant="outlined">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
});
