import { observer } from 'mobx-react';
import { StandardDialog, Button, DateField } from '@roc/ui';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Box } from '@material-ui/core';
import { isValid, format } from 'date-fns';
import { useForeclosureRequestStore } from '../hooks/useForeclosureRequestStore';
import { useState } from 'react';

const useStyles = makeStyles(() => ({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginLeft: 24,
  },
}));

interface ForeclosureBreakdownProps {
  open: boolean;
  onClose: () => void;
}

export const LoanForeclosureBreakdownDialog = ({ open, onClose }: ForeclosureBreakdownProps) => {
  const classes = useStyles();
  const { foreclosureRequestStore } = useForeclosureRequestStore();

  const [formattedDate, setFormattedDate] = useState(null)

  const onSubmit = async () => {
    foreclosureRequestStore.requestLoanBreakdown(formattedDate);
    onClose();
  };

  const manageDate = newDate => {
    const newFormattedDate = isValid(newDate) ? format(newDate, 'MM/dd/yyyy') : null;
    setFormattedDate(newFormattedDate);
  };

  const getContent = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <DateField
            label={"Breakdown Date"}
            testId="breakdown-formatted-date"
            format="MM/dd/yyyy"
            inputVariant="outlined"
            standaloneLabel
            fullWidth
            value={formattedDate}
            onChange={newDate => manageDate(newDate)}
          />
        </Grid>
        <Grid item container className={classes.buttonContainer}>
          <Box mr={2}>
            <Button
              className={classes.button}
              size="small"
              variant="contained"
              color="secondary"
              onClick={onClose}
              testId="breakdown-cancel"
            >
              CANCEL
            </Button>
            <Button
              disabled={!formattedDate}
              className={classes.button}
              size="small"
              variant="contained"
              color="primary"
              onClick={onSubmit}
              testId="breakdown-save"
            >
              SUBMIT
            </Button>
          </Box>
        </Grid>
      </Grid>
    );
  };

  return (
    <StandardDialog
      title="Request Loan Breakdown"
      maxWidth="sm"
      open={open}
      handleClose={onClose}
      dialogContent={getContent()}
    />
  );
};
