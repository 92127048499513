import { Person } from '@material-ui/icons';
import { DialogState } from '@roc/ui';
import { observer } from 'mobx-react';
import { useNewLoanApplicationStore } from '../hooks/useNewLoanApplicationStore';
import { AddBorrowerForm, MultipleBorrowersForm, SingleBorrowerForm } from './borrowersForms';
import {
  LoanApplicationCardTitle,
  LoanApplicationDialog,
} from './loanApplicationComponents';

export const BorrowerInformationModal = observer(() => {
  const { newLoanApplicationStore } = useNewLoanApplicationStore();
  const { borrowerStore } = newLoanApplicationStore;
  const { dialogState } = borrowerStore;

  const multipleBorrowers = borrowerStore.borrowers.length > 1;

  const title = dialogState === DialogState.ADD
    ? 'Co-Borrower Information'
    : 'Borrower Information';

  const handleSave = () => dialogState === DialogState.ADD
    ? borrowerStore.saveAddBorrower()
    : borrowerStore.saveEditBorrowers();

  return (
    <LoanApplicationDialog
      title={
        <LoanApplicationCardTitle
          title={title}
          icon={<Person />}
        />
      }
      dialogState={dialogState}
      handleClose={() => borrowerStore.closeDialog()}
      handleSave={handleSave}
      dialogContent={
        <>
          {dialogState === DialogState.ADD && <AddBorrowerForm />}
          {dialogState === DialogState.EDIT && !multipleBorrowers && <SingleBorrowerForm />}
          {dialogState === DialogState.EDIT && multipleBorrowers && <MultipleBorrowersForm />}
        </>
      }
    />
  );
});
