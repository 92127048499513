import { Card } from '@roc/ui';
import { Button, Typography, Box, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { PlaidLink } from '@roc/feature-plaid';
import { useEffect, useState } from 'react';
import { observer } from "mobx-react";
import { StandardDialog } from '@roc/ui';
import { MoreVert, LinkOff } from '@material-ui/icons';
import { DropdownMenu } from '@roc/ui';
import {
  DataGrid as Grid,
} from '@roc/ui';
import {
  GridColumnProps,
} from '@roc/ui';
import { useParams } from 'react-router';


const filter = {
  filter: 'agTextColumnFilter',
  floatingFilter: true,
  floatingFilterComponentParams: {
    suppressFilterButton: true,
  },
};


const columns: GridColumnProps[] = [
  {
    field: 'mask',
    headerName: `Account's Last 4 Numbers`,
    minWidth: 250,
    ...filter
  },
  {
    field: 'bankName',
    headerName: 'Bank',
    minWidth: 250,
    ...filter,
  },
  {
    field: 'requestedDate',
    headerName: 'Linked Date',
    minWidth: 250,
    ...filter,
  },
  {
    field: 'accountId',
    headerName: 'Actions',
    maxWidth: 100,
    cellRenderer: 'actionCellRenderer',
  }
];


const useStyles = makeStyles(theme => ({
  paper: {
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(2),
    '& .MuiCardHeader-root': {
      padding: '16px 16px 0 16px',
    },
    '& .MuiCardContent-root': {
      padding: '0 0 16px 0',
    },
  },
  text: {
    fontSize: 11,
    color: '#bdbdbd',
  },
  modalAlignment: {
    margin: '0 auto'
  }
}));


export const BankAccounts = observer(({ store }) => {
  const { plaidGrid } = store;
  const classes = useStyles();
  const params = useParams<any>();

  const frameworkComponents = {
    actionCellRenderer(params) {
      const account = params.node.data;
      return (
        <>
          <DropdownMenu options={getAccountActions(account?.accountId)}>
            <MoreVert />
          </DropdownMenu>
        </>
      );
    },
  };

  useEffect(() => {
    if (!store.isPublic) {
      plaidGrid.resetAndFetchGridData();
    } else {
      store.urlId = params?.id;
    }
    store.fetchLinkToken();
  }, []);

  const openUnlinkPopUp = (param) => {
    store.setUnlinkModalOpen(true);
    store.setAccountId(param);
  }

  const getAccountActions = (accountId) => {
    const actionList = [];
    actionList.push({
      icon: LinkOff,
      name: 'Unlink Account',
      action: () => { openUnlinkPopUp(accountId) }
    });
    return actionList;
  }

  const onFilterChanged = params => {
    const filterModel = params.api.getFilterModel();
    plaidGrid.setFilterModel(filterModel);
  };

  const onSortChanged = params => {
    const sortModel = params.api.getSortModel();
    plaidGrid.setSortModel(sortModel);
  };

  return (
    <>
      {
        store.isPublic ?
          !store.isAlreadyLinked ?
            (
              <Card title="Account Linking Via Plaid" className={classes.paper}>
                <Typography variant='subtitle1' style={{ margin: '16px' }}>Please link your bank accounts via Plaid in order to allow the portal to retrieve your bank statements for you</Typography>
                <PlaidLink linkToken={store.linkToken} isPublic={true} isDisabledLinked={store.isDisabledLinked} />
              </Card >
            ) : (
              <Card title="Account Linking Via Plaid" className={classes.paper}>
                <Typography variant='subtitle1' style={{ margin: '16px' }}>Your bank accounts via Plaid are already linked, if you want to review your verification <Link href={`https://my.plaid.com/`} target="_blank" rel="noopener">Click here</Link></Typography>
              </Card >
            ) :
          (<>
            <Card title="Account Linking Via Plaid" className={classes.paper}>
              <Typography variant='subtitle1' style={{ margin: '16px' }}>Please link your bank accounts via Plaid in order to allow the portal to retrieve your bank statements for you</Typography>
              <PlaidLink linkToken={store.linkToken} isPublic={false} />
            </Card>
            {
              plaidGrid?.gridData?.data?.rows?.length > 0 &&
              <Grid
                columns={columns}
                rows={plaidGrid.gridData.data.rows}
                frameworkComponents={frameworkComponents}
                //toolbar={toolbar}
                onSortChanged={onSortChanged}
                onFilterChanged={onFilterChanged}
                isLoading={plaidGrid.isLoading}
                showFilters={plaidGrid.showFilters}
                sortModel={plaidGrid.sortModel}
                filterModel={plaidGrid.filterModel}
                paginationData={plaidGrid.gridData.meta}
                setPageNumber={plaidGrid.setPageNumber}
                domLayout="autoHeight"
              />
            }
          </>)
      }
      <StandardDialog
        open={store.unlinkModalOpen}
        maxWidth="sm"
        dialogContent={<>
          <Typography variant='h5' className={classes.modalAlignment} align={'center'}>{'Are you sure you want to unlink this account?'}</Typography>
        </>}
        dialogActions={
          <Box style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <Button color='primary' size='small' variant='contained' onClick={() => store.unlinkAccount()} style={{ margin: '0 10px', width: '100px' }}>Ok
            </Button>
            <Button color='primary' size='small' variant='contained' onClick={
              () => store.setUnlinkModalOpen(false)} style={{ margin: '0 10px' }}>Cancel
            </Button>
          </Box>
        } />
    </>
  );
});