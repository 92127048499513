const mapper = {
  Plumbing: {
    helperText: 'Please provide the cost and details of the following items',
    sections: [
      {
        title: 'Plumbing',
        fields: [
          {
            label: 'Supply Rough',
            name: 'supplyRough',
          },
          {
            label: 'Waste Rough',
            name: 'wasteRough',
          },
        ],
      },
      {
        title: 'Fixtures',
        displayHowMany: true,
        fields: [
          {
            label: 'Kitchen Sink',
            name: 'kitchenSink',
          },
          {
            label: 'Kitchen Faucet',
            name: 'kitchenFaucet',
          },
          {
            label: 'Bathroom Sink',
            name: 'bathroomSink',
          },
          {
            label: 'Bathroom Faucet',
            name: 'bathroomFaucet',
          },
          {
            label: 'Tub',
            name: 'tub',
          },
          {
            label: 'Toilet',
            name: 'toilet',
          },
          {
            label: 'Shower',
            name: 'shower',
          },
        ],
      },
      {
        title: 'Gas Piping',
        fields: [
          {
            label: 'Amount',
            name: 'gasPipingAmount',
          },
        ],
      },
      {
        title: 'Other',
        tooltip: 'Any other relevant items related to this category',
        fields: [
          {
            label: 'Amount',
            name: 'otherAmount',
          },
        ],
      },
    ],
  },
  HVAC: {
    helperText: 'Please provide the cost and details of the following items',
    sections: [
      {
        title: 'Heating EQ',
        displayHowMany: true,
        fields: [
          {
            label: 'Boiler',
            name: 'boiler',
          },
          {
            label: 'Furnace',
            name: 'furnace',
          },
          {
            label: 'Radiators',
            name: 'radiators',
          },
          {
            label: 'Radiant Heat',
            name: 'radiantHeat',
          },
          {
            label: 'Compressors / Heat Pumps',
            name: 'compressors',
          },
          {
            label: 'Fan Coils',
            name: 'fanCoils',
          },
        ],
      },
      {
        title: 'Coding EQ',
        fields: [
          {
            label: 'Amount',
            name: 'codingEQAmount',
          },
        ],
      },
      {
        title: 'Duct Work',
        fields: [
          {
            label: 'Amount',
            name: 'ductWorkAmount',
          },
        ],
      },
      {
        title: 'Ventilation',
        displayHowMany: true,
        fields: [
          {
            label: 'Exhaust Air',
            name: 'exhaustAir',
          },
          {
            label: 'Kitchen Hood',
            name: 'kitchenHood',
          },
        ],
      },
      {
        title: 'Other',
        tooltip: 'Any other relevant items related to this category',
        fields: [
          {
            label: 'Amount',
            name: 'otherAmount',
          },
        ],
      },
    ],
  },
  Electrical: {
    helperText: 'Please provide the cost and details of the following items',
    sections: [
      {
        title: 'Electric Rough',
        fields: [
          {
            label: 'Wiring / Conduit',
            name: 'wiring',
          },
          {
            label: 'New Panel',
            name: 'newPanel',
          },
        ],
      },
      {
        title: 'Electric Finish Service Distribution',
        fields: [
          {
            label: 'Switches / Outlets',
            name: 'switches',
          },
          {
            label: 'Lighting',
            name: 'lighting',
          },
        ],
      },
      {
        title: 'Other',
        tooltip: 'Any other relevant items related to this category',
        fields: [
          {
            label: 'Amount',
            name: 'otherAmount',
          },
        ],
      },
    ],
  },
  'Fire Protection': {
    helperText: 'Please provide the cost and details of the following items',
    sections: [
      {
        title: 'Fire Suppression',
        fields: [
          {
            label: 'Amount',
            name: 'fireSuppressionAmount',
          },
        ],
      },
      {
        title: 'Fire Protection Specialities',
        fields: [
          {
            label: 'Amount',
            name: 'fireProtectionSpecialitiesAmount',
          },
        ],
      },
      {
        title: 'Other',
        tooltip: 'Any other relevant items related to this category',
        fields: [
          {
            label: 'Amount',
            name: 'otherAmount',
          },
        ],
      },
    ],
  },
  Communications: {
    helperText: 'Please provide the cost and details of the following items',
    sections: [
      {
        title: 'A/V Cables/Cabinet',
        fields: [
          {
            label: 'Amount',
            name: 'cabinetAmount',
          },
        ],
      },
      {
        title: 'Other',
        tooltip: 'Any other relevant items related to this category',
        fields: [
          {
            label: 'Amount',
            name: 'otherAmount',
          },
        ],
      },
    ],
  },
  Elevator: {
    helperText: 'Please provide the cost and details of the following items',
    sections: [
      {
        title: 'Elevator',
        fields: [
          {
            label: 'Amount',
            name: 'elevatorAmount',
          },
        ],
      },
      {
        title: 'Other',
        tooltip: 'Any other relevant items related to this category',
        fields: [
          {
            label: 'Amount',
            name: 'otherAmount',
          },
        ],
      },
    ],
  },
  'Electronic Safety & Security "Smoke/CO Detectors, Security System"': {
    helperText: 'Please provide the cost and details of the following items',
    sections: [
      {
        title: 'Smoke/CO Detectors',
        fields: [
          {
            label: 'Amount',
            name: 'smokeDetectorsAmount',
          },
        ],
      },
      {
        title: 'Security System',
        fields: [
          {
            label: 'Amount',
            name: 'securitySystemAmount',
          },
        ],
      },
      {
        title: 'Other',
        tooltip: 'Any other relevant items related to this category',
        fields: [
          {
            label: 'Amount',
            name: 'otherAmount',
          },
        ],
      },
    ],
  },
};

export default mapper;
