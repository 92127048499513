import { LoanPurposeOption } from './stabilizedBridgeAnalyzerConstants';

export const mapLoanPurpose = loanPurposeFromLoan => {
  switch (loanPurposeFromLoan) {
    case 'Purchase':
      return LoanPurposeOption.PURCHASE;
    case 'Refi':
      return LoanPurposeOption.REFINANCE;
    default:
      return null;
  }
};
