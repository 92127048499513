import { Page, useBaseStore } from '@roc/feature-app-core';
import { Route } from 'react-router';
import { LenderDetailedScreen } from '../components/lenderDetails/lenderDetailedScreen';

const BACK_OFFICE_BASE_PATH = 'backoffice';

export const getTPOContactDetailsRoutesConfig = (basePath: string, baseUrl: string) => {
  return {
    tpoContactDetails: (companyContactId?: string, recordType?: string) => ({
      path: `${basePath}/${BACK_OFFICE_BASE_PATH}/lender-contact-details/:companyContactId/:recordType`,
      url: `${baseUrl}/${BACK_OFFICE_BASE_PATH}/lender-contact-details/${companyContactId}/${recordType}`,
      documentTitle: `Lender Details`,
    })
  }
};

export const getTPOContactDetailsRoutes = (basePath: string, baseUrl: string) => {
  const config = getTPOContactDetailsRoutesConfig(basePath, baseUrl);
  return {
    tpoContactDetails: (
      <Route exact path={config.tpoContactDetails().path}>
        <Page routeDefinition={config.tpoContactDetails()}>
          <LenderDetailedScreen />
        </Page>
      </Route>
    )
  };
};

export const useTPOContactDetailsRoutes = () => {
  const { globalStore } = useBaseStore();
  const { routeBasePath, routeBaseUrl } = globalStore;
  return {
    tpoContactDetailsRoutes: getTPOContactDetailsRoutes(routeBasePath, routeBaseUrl),
    tpoContactDetailsRoutesConfig: getTPOContactDetailsRoutesConfig(
      routeBasePath,
      routeBaseUrl
    ),
  };
};