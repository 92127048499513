import { Service } from '@roc/feature-app-core';

const url = {
  UPDATE_LOANPAYMENTS:
    '/api/v1/document/formDocument/updateLoanPaymentDocumentState',
  UPDATE_LOAN_FAST_TRACK_PAYMENTS:
    '/api/v1/document/formDocument/updateLoanFastrackInfoState',
  DOWNLOAD_SIGNED_DOCUMENT:
    '/api/v1/document/formDocument/downloadSignedDocument',
  GET_LOAN_FAST_TRACK_FEES: '/api/v1/loan/fastTrackFees',
  GET_LOAN_FAST_TRACK_INFO: '/api/v1/loan/loanFastTrackInfo',
  GET_CDA_ARR_ORDER_GRID: '/api/v1/document/formDocument/getPropertiesCdaArrExternal',
  INTERNAL_GET_CDA_ARR_ORDER_GRID: '/nportal/rest/priv/cdaArr/getPropertiesCdaArr',
  GET_IS_ALL_APPRAISAL_DOCUMENT_ACCEPTED: '/api/v1/document/formDocument/isAllAppraisalDocumentAccepted',
  PROPERTY_APPRAISALS: '/api/v1/document/formDocument/getPropertyAppraisal',
  INTERNAL_PROPERTY_APPRAISALS: '/nportal/rest/priv/valueLink/getPropertyAppraisal',
  REQUEST_PAYMENT:
    '/api/v1/document/formDocument/requestAppraisalPayment',
  INTERNAL_REQUEST_PAYMENT: '/nportal/rest/priv/payments/requestAppraisalPaymentExternal',
  UPDATE_APPRAISAL_AND_CDA_CHECK:
    '/api/v1/document/formDocument/updateAppraisalAndCdaCheck',
  INTERNAL_UPDATE_APPRAISAL_AND_CDA_CHECK: '/nportal/rest/priv/svi/updateAppraisalAndCdaCheckExternalPortal',

  INTERNAL_DOWNLOAD_SIGNED_DOCUMENT: '/nportal/rest/priv/payments/downloadSignedDocumentExternal',

  GET_CDA_PAYMENT_URL: '/api/v1/loan/loanCdaPaymentUrl',
  INTERNAL_GET_CDA_PAYMENT_URL: '/nportal/rest/priv/clearCapital/getCdaPaymentUrl',

  GET_DEVIATION_PROPERTIES_URL: '/api/v1/document/formDocument/getPropertiesWithDeviationAboveLimit',
  INTERNAL_GET_DEVIATION_PROPERTIES_URL: '/nportal/rest/priv/cdaArr/getPropertiesWithDeviationAboveLimit',

  INTERNAL_GET_DESKTOP_APPRAISAL_PREFERENCE: '/nportal/rest/priv/companyProfileNportal/getDesktopAppraisalPreferences',
  GET_DESKTOP_APPRAISAL_PREFERENCE: '/api/v1/loan/companyprofile/getDesktopAppraisalPreferences',
};

export class CdaAndAppraisalOrderDetailsService extends Service {

  updateLoanPaymentFlowData(paymentOptions: any) {
    return this.put(url.UPDATE_LOANPAYMENTS, paymentOptions);
  }

  updateLoanFastrackInfo(fastTrackOptions: any) {
    return this.put(url.UPDATE_LOAN_FAST_TRACK_PAYMENTS, fastTrackOptions);
  }

  downloadSignedDocument(isInternal: boolean, entityId: string, entityType: string) {
    return this.get(
      isInternal ? url.INTERNAL_DOWNLOAD_SIGNED_DOCUMENT : url.DOWNLOAD_SIGNED_DOCUMENT,
      {
        entityId,
        entityType,
      },
      {
        responseType: 'arraybuffer',
      }
    );
  }

  getLoanFastTrackFees() {
    return this.get(url.GET_LOAN_FAST_TRACK_FEES);
  }

  getLoanFastTrackInfo(loanId) {
    return this.get(url.GET_LOAN_FAST_TRACK_INFO, { loanId });
  }

  getCdaArrOrderGrid(isInternal: boolean, loanId, type) {
    return this.get(isInternal ? url.INTERNAL_GET_CDA_ARR_ORDER_GRID : url.GET_CDA_ARR_ORDER_GRID, { loanId, type });
  }


  getIsAllAppraisalDocumentAccepted(loanId) {
    return this.get(url.GET_IS_ALL_APPRAISAL_DOCUMENT_ACCEPTED, { loanID: loanId });
  }

  getPropertyAppraisal(isInternal: boolean, loanId: string, type: string) {
    return this.get(isInternal ? url.INTERNAL_PROPERTY_APPRAISALS : url.PROPERTY_APPRAISALS, { loanId, type });
  }

  requestCdaPayment = (isInternal: boolean, entityId: any, type: string) => {
    return this.get(isInternal ? url.INTERNAL_REQUEST_PAYMENT : url.REQUEST_PAYMENT, {
      entityId,
      type,
    });
  };

  updateAppraisalAndCdaCheck(isInternal: boolean, loanID, isAllowedOnAppraisalFromLender: boolean, type, cdaRushed: boolean) {
    const uri = isInternal ? url.INTERNAL_UPDATE_APPRAISAL_AND_CDA_CHECK : url.UPDATE_APPRAISAL_AND_CDA_CHECK;
    return this.put(`${uri}?loanID=${loanID}&isAllowedOnAppraisalFromLender=${isAllowedOnAppraisalFromLender}&type=${type}&cdaRushed=${cdaRushed}`, null);
  }

  getCdaPaymentUrl(isInternal: boolean, loanId) {
    const uri = isInternal ? url.INTERNAL_GET_CDA_PAYMENT_URL : url.GET_CDA_PAYMENT_URL;
    return this.get(uri, { loanId });
  }

  getPropertiesWithDeviation(isInternal: boolean, loanId: number) {
    const uri = isInternal ? url.INTERNAL_GET_DEVIATION_PROPERTIES_URL : url.GET_DEVIATION_PROPERTIES_URL;
    return this.get(uri, { loanId });
  }

  getDesktopAppraisalPreference(isInternal: boolean, lenderId) {
    const uri = isInternal ? url.INTERNAL_GET_DESKTOP_APPRAISAL_PREFERENCE : url.GET_DESKTOP_APPRAISAL_PREFERENCE;
    return this.get(uri + "?lenderId=" + lenderId);
  }
}
