import { Service } from '@roc/feature-app-core';

const url = {
  LOAN_DETAILS: '/api/v1/loan/getLoan',
  FORECLOSURE_VALUATIONS: '/api/v1/loan/appraisal/getForeclosureValuations',
  CREATE_BPO: '/api/v1/loan/appraisal/createBpoOrder',
  CREATE_APPRAISAL: '/api/v1/loan/appraisal/createForeclosureAppraisalOrder'
};

export class DelinquencyBpoService extends Service {

  getLoanDetails(id: string) {
    return this.get(url.LOAN_DETAILS, { id });
  }

  getLoanForeclosureValuations(loanId: string) {
    return this.get(url.FORECLOSURE_VALUATIONS, { loanId })
  }

  createBpoOrder(loanId: string, properties: any) {
    return this.post(`${url.CREATE_BPO}?loanId=${loanId}`, properties)
  }

  createForeclosureAppraisalOrder(loanId: string, properties: any) {
    return this.post(`${url.CREATE_APPRAISAL}?loanId=${loanId}`, properties)
  }
}