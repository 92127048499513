import { GlobalStore, GridStore } from '@roc/feature-app-core';
import { flow, makeObservable } from 'mobx';
import { QuoteGeneratorBaseService } from '../services/quoteGeneratorBaseService';
import { QuoteGeneratorDetailsStore } from './quoteGeneratorDetailsStore';
import { QuoteGeneratorGridStore } from './quoteGeneratorGridStore';

export abstract class QuoteGeneratorBaseStore {
  public globalStore: GlobalStore;
  protected quoteGeneratorService: QuoteGeneratorBaseService;

  quoteGeneratorDetailsStore: QuoteGeneratorDetailsStore;
  quoteGeneratorGridStore: QuoteGeneratorGridStore;

  constructor(globalStore: GlobalStore) {
    this.globalStore = globalStore;
    makeObservable(this, {
      deleteQuote: flow,
    });
  }

  *deleteQuote(quoteId) {
    try {
      yield this.quoteGeneratorService.deleteQuote(quoteId);
      yield this.quoteGeneratorGridStore.fetchGridData();
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while removing quote',
      });
    }
  }
}
