import { action, observable, flow, computed, makeObservable } from 'mobx';
import { GlobalStore } from '@roc/feature-app-core';
import { LoanStore } from '@roc/feature-loans';
import { DocumentStore } from '@roc/feature-documents';
import { InsuranceFormStore } from './insuranceFormStore';
import { PropertyFormStore } from './propertyFormStore';
import { InsuranceProviderService } from './../../../services/documentForms/insuranceProviderService';
import { shouldBeAbleToSubmitSpecificForm } from '../../../utils/documentForms';
import { DocumentName, DocumentStatus } from '../../../constants';
import { ELMSURE, OTHER } from '@roc/feature-utils';
import { Property, Document } from '@roc/feature-types';

export class InsuranceProviderStore {
  private loanStore: LoanStore;
  private documentStore: DocumentStore;
  private globalStore: GlobalStore;
  insurance: string;
  currentInsurance: InsuranceFormStore;
  selectedInsurance: InsuranceFormStore;
  properties: PropertyFormStore[];
  private insuranceProviderService: InsuranceProviderService;

  constructor(
    loanStore: LoanStore,
    documentStore: DocumentStore,
    globalStore: GlobalStore
  ) {
    this.loanStore = loanStore;
    this.documentStore = documentStore;
    this.globalStore = globalStore;
    this.insurance = null;
    this.currentInsurance = new InsuranceFormStore(globalStore);
    this.selectedInsurance = new InsuranceFormStore(globalStore);
    this.properties = [];
    this.insuranceProviderService = new InsuranceProviderService();

    makeObservable(this, {
      initialize: action,
      insurance: observable,
      setInsurance: action,
      properties: observable,
      submitInsuranceForm: flow,
      reset: action,
      currentInsurance: observable,
      selectedInsurance: observable,
      insuredThroughElmsure: computed,
      canEdit: computed,
      areFormsValid: computed,
      canSubmit: computed,
    });
  }

  initialize() {
    this.initializeInsurance();
    this.initializeProperties();
  }

  private initializeInsurance() {
    const {
      currentCompany,
      selectedCompany,
    } = this.loanStore.loanDetails?.loanInsurance;

    if (this.globalStore.userFeatures.isLenderOriginator) {
      this.insurance = OTHER;
    } else {
      this.insurance = this.insuredThroughElmsure ? ELMSURE : OTHER;
    }
    this.currentInsurance.initialize(currentCompany);
    this.selectedInsurance.initialize(selectedCompany);
  }

  private initializeProperties() {
    const {
      loanDetails: { properties },
    } = this.loanStore;
    this.properties = properties.map(
      (property: Property) => new PropertyFormStore(property, this.globalStore)
    );
  }

  setInsurance(insurance: string) {
    this.insurance = insurance;
  }

  *submitInsuranceForm(document: Document) {
    try {
      const doc = {
        ...document,
        status:
          this.insurance === ELMSURE
            ? DocumentStatus.ACCEPTED
            : DocumentStatus.PENDING,
      };
      const payload = {
        insuranceFormDetails: {
          ...this.getInsuranceInformationForSubmission(),
          collaterals: this.getCollateralsInformationForSubmission(),
          isElmsure: this.insurance === ELMSURE,
        },
        loanDocument: doc,
        loanId: doc.loanId,
      };
      yield this.insuranceProviderService.updateInsuranceForm(payload);
      yield this.loanStore.fetchLoanDetails(doc.loanId);
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Insurance form information updated successfully.',
      });
    } catch (err) {
      this.globalStore.notificationStore.showErrorNotification({
        message:
          'An error occurred while updating the insurance form. Please try again.',
      });
    }
  }

  private getInsuranceInformationForSubmission() {
    const loanInsuranceId = this.loanStore.loanDetails.loanInsurance
      .loanInsuranceId;
    const currentInsuranceValues = this.currentInsurance.getFormValues();
    const selectedInsuranceValues = this.selectedInsurance.getFormValues();

    return {
      loanInsuranceId,
      selectedInsuranceName: selectedInsuranceValues.name,
      selectedInsuranceContactName: selectedInsuranceValues.contactName,
      selectedInsuranceCellPhone: selectedInsuranceValues.cellPhone,
      selectedInsuranceEmail: selectedInsuranceValues.emailAddress,
      selectedInsuranceAddress: selectedInsuranceValues.address,
      selectedInsuranceStreetNumber: selectedInsuranceValues.streetNumber,
      selectedInsuranceStreetName: selectedInsuranceValues.streetName,
      selectedInsuranceCity: selectedInsuranceValues.city,
      selectedInsuranceState: selectedInsuranceValues.state,
      selectedInsuranceZipCode: selectedInsuranceValues.zipCode,
      selectedInsuranceCost: selectedInsuranceValues.cost,
      currentInsuranceName: currentInsuranceValues.name,
      currentInsuranceContactName: currentInsuranceValues.contactName,
      currentInsuranceCellPhone: currentInsuranceValues.cellPhone,
      currentInsuranceEmail: currentInsuranceValues.emailAddress,
      currentInsuranceAddress: currentInsuranceValues.address,
      currentInsuranceStreetNumber: currentInsuranceValues.streetNumber,
      currentInsuranceStreetName: currentInsuranceValues.streetName,
      currentInsuranceCity: currentInsuranceValues.city,
      currentInsuranceState: currentInsuranceValues.state,
      currentInsuranceZipCode: currentInsuranceValues.zipCode,
      currentInsuranceCost: currentInsuranceValues.cost,
    };
  }

  private getCollateralsInformationForSubmission() {
    return this.properties.map((store: PropertyFormStore) => ({
      propertyId: store.propertyId,
      loanPropertyId: store.loanPropertyId,
      ...store.getFormValues(),
    }));
  }

  reset() {
    this.currentInsurance = new InsuranceFormStore(this.globalStore);
    this.selectedInsurance = new InsuranceFormStore(this.globalStore);
    this.initialize();
  }

  get insuredThroughElmsure() {
    return this.loanStore.loanDetails.loanInsurance.insuredThroughElmsure;
  }

  get canEdit() {
    const entitlements = this.globalStore.userFeatures?.closingEntitlements;
    return shouldBeAbleToSubmitSpecificForm(
      entitlements.isSubmissionAllowed,
      entitlements.isSubmissionNotAllowedOnly,
      entitlements.isSubmissionAllowedOnly,
      DocumentName.INSURANCE_PROVIDER
    );
  }

  get areFormsValid() {
    return (
      this.currentInsurance.form.meta.isValid &&
      this.selectedInsurance.form.meta.isValid
    );
  }

  get canSubmit() {
    return this.canEdit && this.areFormsValid;
  }
}

export default InsuranceProviderStore;
