import { Page } from '@roc/client-portal-shared/components';
import { useBaseStore } from '@roc/feature-app-core';
import { QuotesDashboard } from 'libs/feature-one-tool/src/quote/quotesDashboard';
import { observer } from 'mobx-react';
import { Route } from 'react-router';
import { QuoteDetails } from '../quoteDetails';
import { QuoteGeneratorDashboard } from '../quoteGeneratorDashboard';

export const getQuoteGeneratorRoutesConfig = (
  basePath: string,
  baseUrl: string
) => {
  return {
    dashboard: {
      path: `${basePath}/bridge-quote`,
      url: `${baseUrl}/bridge-quote`,
      documentTitle: 'Bridge Quotes',
    },
    createQuote: {
      path: `${basePath}/bridge-quote/create-quote`,
      url: `${baseUrl}/bridge-quote/create-quote`,
      documentTitle: 'Bridge Quote',
    },
    viewQuote: (quoteId?) => ({
      path: `${basePath}/bridge-quote/quote/:quoteId`,
      url: `${baseUrl}/bridge-quote/quote/${quoteId}`,
      documentTitle: 'Bridge Quote',
    }),
  };
};

export const getQuoteGeneratorRoutes = (basePath: string, baseUrl: string) => {
  const config = getQuoteGeneratorRoutesConfig(basePath, baseUrl);
  return {
    dashboard: (
      <Route exact path={config.dashboard.path}>
        <Page routeDefinition={config.dashboard}>
          <_Dashboard />
        </Page>
      </Route>
    ),
    createQuote: (
      <Route exact path={config.createQuote.path}>
        <Page routeDefinition={config.createQuote}>
          <QuoteDetails />
        </Page>
      </Route>
    ),
    viewQuote: (
      <Route exact path={config.viewQuote().path}>
        <Page routeDefinition={config.viewQuote()}>
          <QuoteDetails />
        </Page>
      </Route>
    ),
  };
};

const _Dashboard = observer(() => {
  const { globalStore } = useBaseStore();
  return globalStore.userFeatures.enableOneToolPricer ? (
    <QuotesDashboard />
  ) : (
    <QuoteGeneratorDashboard />
  );
});
