import { ArrowBackIosOutlined, CloudDownloadOutlined, FontDownload, Search } from '@material-ui/icons';
import { Button, ScreenshotGrid } from '@roc/ui';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Theme, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import placeHolderImage from "../../images/Placeholder.svg"
import { useRef } from 'react';
import { formatDate, formatPrice } from '../../utils/formatter';
import { useStore } from '@roc/client-portal-shared/hooks';




const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontSize: '34px'
    },
    button: {
      textAlign: 'end',
      padding: '10px',
    },
    removeButton: {
      textAlign: 'end',
      padding: '10px 20px',
    },
    buttonContainer: {
      textAlign: 'end',
      padding: '0px 10px 0px 0px'
    },
    headerContainer: {
      padding: '2px'
    },
    images: {
      width: '300px',
      height: '220px'
    },
    addressText: {
      fontSize: '18px',
      fontWeight: 'bold'
    },
    tableHeader: {
      padding: '4px',
      border: '1px solid #D9DADC',
      width: '300px',
      height: '280px'
    },
    cell: {
      fontSize: '18px',
      border: '1px solid #D9DADC'
    }
  })
);

const ImageHeader = ({ title, image }) => {
  const classes = useStyles();

  const onError = (event) => {
    event.target.src = placeHolderImage;
  };

  return (
    <Grid container spacing={1} justifyContent='center' alignContent='center' alignItems='center'>
      <Grid item lg={12}>
        <Typography className={classes.addressText} align='center'>
          {title.split(',')[0].trim()}
        </Typography>
      </Grid>
      <Grid item lg={12}>
        <img
          src={image}
          loading="lazy"
          className={classes.images}
          onError={onError}
        />
      </Grid>
    </Grid>
  )
}


export const CompareScreen = observer(() => {
  const classes = useStyles();
  const { compToolStore } = useStore();

  const { selectedCompareProperties, subjectProperty } = compToolStore;

  const properties = [subjectProperty, ...selectedCompareProperties];

  const getPropertyImage = (property) => {
    const image = property.data.images.find(image => image.recommended_preview) ? property.data.images.find(image => image.recommended_preview).url : property.data.images[0]?.url

    return image || placeHolderImage
  }
  const screenshotContainerRef = useRef(null);

  const downloadGrid = () => {
    screenshotContainerRef.current.download();
  };

  return (
    <>
      <Grid item xs={12} lg={12} className={classes.headerContainer}>
        <Typography className={classes.title}>
          Comparable Properties Analytics
        </Typography>
      </Grid>
      <Grid item xs={12} lg={6}>
        <Button
          variant="text"
          color="primary"
          testId="backToSearch"
          className={classes.button}
          onClick={() => {
            compToolStore.setCompareScreen(false)
            compToolStore.setOpenCompareGadget(true)
          }}
          startIcon={<ArrowBackIosOutlined />}

        >

          Back to Search Results
        </Button>
      </Grid>
      <Grid item xs={12} lg={6} className={classes.buttonContainer}>
        <Button
          variant="contained"
          color="primary"
          testId="downloadCompProperties"
          className={classes.button}
          onClick={downloadGrid}
          startIcon={<CloudDownloadOutlined />}
        >

          DOWNLOAD
        </Button>
      </Grid>
      <Grid item xs={12} lg={12} justifyContent='center' alignItems='center' style={{ textAlign: 'center' }}>
        <ScreenshotGrid
          fileName={`ComparableProperties.pdf`}
          ref={screenshotContainerRef}
        >
          <TableContainer>
            <Table style={{ border: '1px solid #D9DADC', display: 'inline', textAlign: 'center' }}>
              <TableHead >
                <TableRow >
                  <TableCell style={{ width: '300px', height: '280px', border: '1px solid #D9DADC', backgroundColor: '#F4F4F4' }}></TableCell>
                  {properties.map(property =>
                    <TableCell className={classes.tableHeader} align="center"><ImageHeader title={property.data.address} image={getPropertyImage(property)} /></TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>

                <TableRow style={{ border: '1px solid #D9DADC' }}>
                  <TableCell style={{ backgroundColor: '#F4F4F4' }} className={classes.cell} component="th" scope="row">
                    <Typography className={classes.addressText} align='center'>
                      Distance from Subject Property
                    </Typography>
                  </TableCell>
                  {properties.map((property) => (
                    <>
                      <TableCell style={{ backgroundColor: property.is_subject ? '#285391' : '', color: property.is_subject ? '#FFFFFF' : '' }} className={classes.cell} align="center">{property.similarity.mi_distance.toFixed(2)} mi</TableCell>
                    </>
                  ))}
                </TableRow>


                <TableRow>
                  <TableCell style={{ backgroundColor: '#F4F4F4' }} className={classes.cell} component="th" scope="row">
                    <Typography className={classes.addressText} align='center'>
                      Year Built
                    </Typography>
                  </TableCell>
                  {properties.map((property) => (
                    <>
                      <TableCell style={{ backgroundColor: property.is_subject ? '#285391' : '', color: property.is_subject ? '#FFFFFF' : '' }} className={classes.cell} align="center">{property.data.year_built}</TableCell>
                    </>
                  ))}
                </TableRow>
                <TableRow>
                  <TableCell style={{ backgroundColor: '#F4F4F4' }} className={classes.cell} component="th" scope="row">
                    <Typography className={classes.addressText} align='center'>
                      {compToolStore.rentalComps ? 'Rent' : 'Price'}
                    </Typography>
                  </TableCell>
                  {properties.map((property) => (
                    <>
                      <TableCell style={{ backgroundColor: property.is_subject ? '#285391' : '', color: property.is_subject ? '#FFFFFF' : '' }} className={classes.cell} align="center">
                        {formatPrice(property.data.price, property.data.rent, compToolStore.rentalComps)}{compToolStore.rentalComps && '/mo'}
                      </TableCell>
                    </>
                  ))}
                </TableRow>
                {compToolStore.rentalComps ? (

                  <TableRow>
                    <TableCell style={{ backgroundColor: '#F4F4F4' }} className={classes.cell} component="th" scope="row">
                      <Typography className={classes.addressText} align='center'>
                        Rent Date
                      </Typography>
                    </TableCell>
                    {properties.map((property) => (
                      <>
                        <TableCell style={{ backgroundColor: property.is_subject ? '#285391' : '', color: property.is_subject ? '#FFFFFF' : '' }} className={classes.cell} align="center">{formatDate(property.data.rent_date)}</TableCell>
                      </>
                    ))}
                  </TableRow>

                ) : (

                  <TableRow>
                    <TableCell style={{ backgroundColor: '#F4F4F4' }} className={classes.cell} component="th" scope="row">
                      <Typography className={classes.addressText} align='center'>
                        Last Sold
                      </Typography>
                    </TableCell>
                    {properties.map((property) => (
                      <>
                        <TableCell style={{ backgroundColor: property.is_subject ? '#285391' : '', color: property.is_subject ? '#FFFFFF' : '' }} className={classes.cell} align="center">{formatDate(property.data.sale_date)}</TableCell>
                      </>
                    ))}
                  </TableRow>

                )}

                <TableRow>
                  <TableCell style={{ backgroundColor: '#F4F4F4' }} className={classes.cell} component="th" scope="row">
                    <Typography className={classes.addressText} align='center'>
                      Rooms
                    </Typography>
                  </TableCell>
                  {properties.map((property) => (
                    <>
                      <TableCell style={{ backgroundColor: property.is_subject ? '#285391' : '', color: property.is_subject ? '#FFFFFF' : '' }} className={classes.cell} align="center">{property.data.rooms}</TableCell>
                    </>
                  ))}
                </TableRow>

                <TableRow>
                  <TableCell style={{ backgroundColor: '#F4F4F4' }} className={classes.cell} component="th" scope="row">
                    <Typography className={classes.addressText} align='center'>
                      Beds
                    </Typography>
                  </TableCell>
                  {properties.map((property) => (
                    <>
                      <TableCell style={{ backgroundColor: property.is_subject ? '#285391' : '', color: property.is_subject ? '#FFFFFF' : '' }} className={classes.cell} align="center">{property.data.bedrooms}</TableCell>
                    </>
                  ))}
                </TableRow>

                <TableRow>
                  <TableCell style={{ backgroundColor: '#F4F4F4' }} className={classes.cell} component="th" scope="row">
                    <Typography className={classes.addressText} align='center'>
                      Baths
                    </Typography>
                  </TableCell>
                  {properties.map((property) => (
                    <>
                      <TableCell style={{ backgroundColor: property.is_subject ? '#285391' : '', color: property.is_subject ? '#FFFFFF' : '' }} className={classes.cell} align="center">{property.data.full_bathrooms}</TableCell>
                    </>
                  ))}
                </TableRow>

                <TableRow>
                  <TableCell style={{ backgroundColor: '#F4F4F4' }} className={classes.cell} component="th" scope="row">
                    <Typography className={classes.addressText} align='center'>
                      Half Baths
                    </Typography>
                  </TableCell>
                  {properties.map((property) => (
                    <>
                      <TableCell style={{ backgroundColor: property.is_subject ? '#285391' : '', color: property.is_subject ? '#FFFFFF' : '' }} className={classes.cell} align="center">{property.data.half_bathrooms}</TableCell>
                    </>
                  ))}
                </TableRow>

                <TableRow>
                  <TableCell style={{ backgroundColor: '#F4F4F4' }} className={classes.cell} component="th" scope="row">
                    <Typography className={classes.addressText} align='center'>
                      Sq. Ft.
                    </Typography>
                  </TableCell>
                  {properties.map((property) => (
                    <>
                      <TableCell style={{ backgroundColor: property.is_subject ? '#285391' : '', color: property.is_subject ? '#FFFFFF' : '' }} className={classes.cell} align="center">{property.data.area}</TableCell>
                    </>
                  ))}
                </TableRow>


                <TableRow>
                  <TableCell style={{ backgroundColor: '#F4F4F4' }} className={classes.cell} component="th" scope="row">
                    <Typography className={classes.addressText} align='center'>
                      Lot Size
                    </Typography>
                  </TableCell>
                  {properties.map((property) => (
                    <>
                      <TableCell style={{ backgroundColor: property.is_subject ? '#285391' : '', color: property.is_subject ? '#FFFFFF' : '' }} className={classes.cell} align="center">{property.data.lot_size}</TableCell>
                    </>
                  ))}
                </TableRow>


                <TableRow>
                  <TableCell style={{ backgroundColor: '#F4F4F4' }} className={classes.cell} component="th" scope="row">
                    <Typography className={classes.addressText} align='center'>
                      {compToolStore.rentalComps ? 'Rent/Sq.Ft.' : 'Price/Sq.Ft.'}
                    </Typography>
                  </TableCell>
                  {properties.map((property) => (
                    <>
                      <TableCell style={{ backgroundColor: property.is_subject ? '#285391' : '', color: property.is_subject ? '#FFFFFF' : '' }} className={classes.cell} align="center">
                        {formatPrice(property.data.sqft_price, property.data.sqft_rent, compToolStore.rentalComps)}
                      </TableCell>
                    </>
                  ))}
                </TableRow>


                <TableRow>
                  <TableCell style={{ backgroundColor: '#F4F4F4' }} className={classes.cell} component="th" scope="row">
                    <Typography className={classes.addressText} align='center'>
                      Taxes/Yr.
                    </Typography>
                  </TableCell>
                  {properties.map((property) => (
                    <>
                      <TableCell style={{ backgroundColor: property.is_subject ? '#285391' : '', color: property.is_subject ? '#FFFFFF' : '' }} className={classes.cell} align="center">
                        {formatPrice(property.data.taxes)}
                      </TableCell>
                    </>
                  ))}
                </TableRow>



              </TableBody>
            </Table>
          </TableContainer>
        </ScreenshotGrid>
      </Grid>



      <Grid item xs={12} lg={12} justifyContent='center' alignItems='center' style={{ textAlign: 'center' }}>
        <ScreenshotGrid
        >
          <TableContainer>
            <Table style={{ display: 'inline', textAlign: 'center' }}>
              <TableBody>

                <TableRow>
                  <TableCell style={{ width: '300px' }} component="th" scope="row">
                  </TableCell>
                  <TableCell style={{ width: '300px' }} component="th" scope="row">
                  </TableCell>
                  {properties.filter(p => !p.is_subject).map((property) => (
                    <>
                      <TableCell style={{ width: '300px' }} align="center">
                        <Button
                          variant="outlined"
                          color="primary"
                          testId="removeProperty"
                          className={classes.removeButton}
                          onClick={() => {
                            compToolStore.removePropertyFromCompareTool(property.property_id)
                          }}
                        >
                          Remove
                        </Button>
                      </TableCell>
                    </>
                  ))}
                </TableRow>



              </TableBody>
            </Table>
          </TableContainer>
        </ScreenshotGrid>
      </Grid>

    </>
  );
});