import { Route } from 'react-router';
import { Page } from '@roc/feature-app-core';
import { useRocInternalPortalStore } from '../../../hooks';
import { InsuranceReview } from '@roc/feature-insurance-review';

export const getInsuranceReviewInternalRoutesConfig = (
  basePath: string,
  baseUrl: string,
  loanId: string
) => {
  return {
    insuranceReview: {
      path: `${basePath}/insurance-review`,
      url: `${baseUrl}/insurance-review`,
      documentTitle: 'Insurance Review',
    },
  };
};

export const getInsuranceReviewInternalRoutes = (
  basePath: string,
  baseUrl: string,
  loanId: string,
  status: string
) => {
  const config = getInsuranceReviewInternalRoutesConfig(basePath, baseUrl, loanId);
  const { insuranceReviewInternalStore } = useRocInternalPortalStore();

  return {
    insuranceReview: (
      <Route exact path={config.insuranceReview.path}>
        <Page routeDefinition={config.insuranceReview}>
          <InsuranceReview store={insuranceReviewInternalStore} loanId={loanId} loanStatus={status} />
        </Page>
      </Route>
    ),
  };
};
