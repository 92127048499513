import {
  getMiscellaneousRoutes,
  getMiscellaneousRoutesConfig
} from '@roc/feature-app-core';
import { useBaseStore } from './useBaseStore';

export const useMiscellaneousRoutes = () => {
  const { globalStore } = useBaseStore();
  const { routeBasePath, routeBaseUrl } = globalStore;

  return {
    miscellaneousRoutes: getMiscellaneousRoutes(routeBasePath, routeBaseUrl),
    miscellaneousRoutesConfig: getMiscellaneousRoutesConfig(
      routeBasePath,
      routeBaseUrl
    ),
  };
};
