import { Box, List, ListItem, ListItemText, Popover, Typography } from "@material-ui/core";
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { Close, Done, DoneAll, HourglassEmpty } from "@material-ui/icons";
import { Alert } from '@material-ui/lab';
import { CircularLoader, IconButton, Tooltip } from "@roc/ui";
import { Message } from '@twilio/conversations';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { CommunicationStore } from '../stores';
import { MessageDeliveryParticipantData, MessageDeliveryReceipts, MessageStatus } from '../types/communicationTypes';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    typography: {
      padding: theme.spacing(2),
    },
  }),
);

const DeliveryStatusBox = ({ data, title, icon }: {
  title: string,
  icon?: any,
  data: MessageDeliveryParticipantData[]
}) => {
  return (
    data?.length ?
      <Box mt={2}>
        <Box display={"flex"}>
          {icon} <Box component={'span'} pl={1} fontWeight={'bold'}>{title}</Box>
        </Box>
        <List dense={true} style={{ padding: 0 }}>
          {
            data.map(x => {
              return (
                <ListItem key={x.participantSid} style={{ padding: 0 }}>
                  <ListItemText
                    primary={x.name}
                    style={{
                      margin: 0
                    }}
                  />
                </ListItem>
              )
            })
          }
        </List>
      </Box> : null
  )
}

export const ReadReceiptButton = observer(({
  communicationStore,
  message
}: {
  communicationStore: CommunicationStore,
  message: Message
}) => {

  const classes = useStyles();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [deliveryReceipts, setDeliveryReceipts] = useState<MessageDeliveryReceipts>();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (Boolean(anchorEl)) {
      setLoading(true);
      setError(false);
      communicationStore.getMessageDeliveryReceipts(message)
        .then(x => {
          setDeliveryReceipts(x);
          if (!x) {
            setError(true);
          }
        })
        .catch((e) => {
          setDeliveryReceipts(undefined);
          setError(true);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [anchorEl]);

  return (
    <Box>
      <Box position={'absolute'}
        left={0}
        bottom={-2}
      >
        <Tooltip title="Read Receipts" placement="bottom" arrow>
          <div>
            <IconButton testId="readReceipt" onClick={handleClick} style={{
              padding: 4
            }}>
              <DoneAll style={{
                fontSize: 14
              }} />
            </IconButton>
          </div>
        </Tooltip>
      </Box>
      <Popover
        id={'read-receipts-popover'}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
      >
        {
          loading && <CircularLoader position="absolute" thickness={5} color="primary" size={36} />
        }
        {
          !loading && error && <Alert severity="error">Error retrieving read receipts</Alert>
        }
        {
          !loading && !error && deliveryReceipts &&
          <Box p={2}>
            <Box display="flex" alignItems={'center'}>
              <Box flexGrow={1} width={200}>
                <Typography variant="h6" gutterBottom>
                  <strong>Read Receipts</strong>
                </Typography>
              </Box>
              <Box>
                <IconButton
                  testId="close"
                  aria-label="close"
                  onClick={handleClose}
                >
                  <Close />
                </IconButton>
              </Box>
            </Box>
            <DeliveryStatusBox title={"Read By"} icon={<DoneAll style={{ color: 'green' }} />} data={deliveryReceipts[MessageStatus.Read]} />
            <DeliveryStatusBox title={"Delivered"} icon={<DoneAll style={{ color: '#444' }} />} data={deliveryReceipts[MessageStatus.Delivered]} />
            <DeliveryStatusBox title={"Sent"} icon={<Done style={{ color: '#999' }} />} data={deliveryReceipts[MessageStatus.Sent]} />
            <DeliveryStatusBox title={"Sending"} icon={<HourglassEmpty style={{ color: '#999' }} />} data={deliveryReceipts[MessageStatus.Sending]} />
            <DeliveryStatusBox title={"Failed"} icon={<Done style={{ color: 'red' }} />} data={deliveryReceipts[MessageStatus.Failed]} />
            <DeliveryStatusBox title={"None"} data={deliveryReceipts[MessageStatus.None]} />
          </Box>
        }
      </Popover>
    </Box>
  )
});