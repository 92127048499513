import { useBaseStore } from '@roc/feature-app-core';
import { getCreditReviewRoutes } from '../routes/creditReviewRoutes';

export const useCreditReviewRoutes = () => {
  const { globalStore } = useBaseStore();
  const { routeBasePath, routeBaseUrl } = globalStore;
  const { borrowerId, loanId } = globalStore.queryParams;
  return {
    creditReviewRoutes: getCreditReviewRoutes(
      routeBasePath,
      routeBaseUrl,
      borrowerId,
      loanId
    ),
  };
};