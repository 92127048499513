import { Page } from '../../../components';
import { Route } from 'react-router';
import { useStore } from '@roc/client-portal-shared/hooks';
import { InternalPricer } from '../internalPricer';

export const getInternalPricerRoutesConfig = (
  basePath: string,
  baseUrl: string
) => {
  return {
    internalPricer: {
      path: `${basePath}/internal-pricer`,
      url: `${baseUrl}/internal-pricer`,
      documentTitle: 'Internal Pricer',
    },
  };
};

export const getInternalPricerRoutes = (
  basePath: string,
  baseUrl: string
) => {
  const config = getInternalPricerRoutesConfig(basePath, baseUrl);
  const { internalPricerStore } = useStore();
  return {
    internalPricer: (
      <Route exact path={config.internalPricer.path}>
        <Page routeDefinition={config.internalPricer}>
          <InternalPricer showSensitiveInfo={true} internalPricerStore={internalPricerStore} />
        </Page>
      </Route>
    ),
  };
};
