import { useBaseStore, useUserStore } from '@roc/feature-app-core';

import { appLocalStorage, selectedCompanyStorage, getPortalContextPath, isLocalHost, isNil, LocalStorageKeys } from '@roc/feature-utils';
import { observer } from 'mobx-react';
import { useEffect } from 'react';

const _getPortalContextPath = (portalName) => {
  const portalContextPath = portalName ? getPortalContextPath(portalName) : undefined;
  return portalContextPath;
}

export const UserContextChecker = observer(() => {

  const { userStore } = useUserStore();
  const { globalStore } = useBaseStore();

  const getLocalStorageUserName = () => {
    return appLocalStorage.getValue(LocalStorageKeys.userName);
  };

  useEffect(() => {
    if (!isLocalHost()) {

      let intervalId;
      const userContextCheckerFunction = () => {

        const userStore_userName = userStore.userInformation?.username?.toLowerCase();
        const userStore_preferredUserName = userStore.userInformation?.preferredUsername?.toLowerCase();
        const localStorage_userName = getLocalStorageUserName();
        const localStoragePrimaryCompany = selectedCompanyStorage.getSelectedCompanyIdFromStorage();

        // if the localStorage doesn't have user context, then set the current user in context
        if (isNil(localStorage_userName)) {
          appLocalStorage.setValue(LocalStorageKeys.userName, userStore_userName);
        }
        if (isNil(localStoragePrimaryCompany)) {
          selectedCompanyStorage.setSelectedCompanyIdToStorage(globalStore?.selectedCompanyId);
        }

        if (userStore_userName != localStorage_userName && userStore_preferredUserName != localStorage_userName) {
          console.warn(`User Context Changed from ${userStore.userInformation?.username} to ${localStorage_userName}`);
          userStore.getUserInformation()
            .then(result => {
              const user = result?.user;
              const username = user?.userName?.toLowerCase();
              const portalName = user?.portalName;
              if (username && portalName) {
                const portalContextPath = _getPortalContextPath(portalName);
                appLocalStorage.setValue(LocalStorageKeys.userName, username);
                appLocalStorage.setValue(LocalStorageKeys.portalContextPath, portalContextPath);
                if (userStore_userName != username) {
                  console.warn(`Refreshing the session to use current user: ${username}`);
                  window.location.href = portalContextPath ? `/${portalContextPath}` : '/';
                }
              }
            });
        }
        else {
          intervalId = setTimeout(userContextCheckerFunction, 1000);
        }
      };
      intervalId = setTimeout(userContextCheckerFunction, 1000);

      return () => clearTimeout(intervalId);
    }
  }, []);

  return <></>;
});
