import { action, flow, makeObservable, observable } from 'mobx';
import { TamariskPaymentsService } from './../services/tamariskPaymentsService';
import { GlobalStore, FormStore, ApiResponse } from '@roc/feature-app-core';
import { AppraisalDetails } from './../types/tamariskPayments';

const form = {
  fields: {
    fullName: {
      value: '',
      error: null,
      rule: 'required|regex:/^[A-Za-z\\s]+$/',
      message: 'Name is required (use only alphabetic characters)',
    },
    email: {
      value: '',
      error: null,
      rule: 'required|email',
      message: 'Email is required in correct format',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export class TamariskDocumentStore extends FormStore {
  private globalStore: GlobalStore;
  private tamariskPaymentsService: TamariskPaymentsService;
  fullName: string = null;
  email: string = null;
  properties: AppraisalDetails[] = null;
  totalAppraisalCost: number = null;
  feeCalcError: string = null;
  cardKnoxURL: string = null;
  loading: boolean = true;
  isLinkValid: boolean;

  constructor(globalStore) {
    super({ ...form }, globalStore);
    this.tamariskPaymentsService = new TamariskPaymentsService();
    makeObservable(this, {
      loading: observable,
      isLinkValid: observable,
      fetchLoanInfo: flow,
      fetchStandaloneInfo: flow,
      fetchBorrowerInfo: flow,
      checkIfValidLink: flow,
      setCardKnoxUrl: action,
      setProperties: action,
      setLoading: action,
      setTotalAppraisalCost: action,
      resetStore: action,
      setIsLinkValid: action
    });
  }

  setLoading(value: boolean) {
    this.loading = value;
  }

  setCardKnoxUrl(url: string) {
    this.cardKnoxURL = url;
  }

  setProperties(properties: AppraisalDetails[]) {
    this.properties = properties;
  }

  setTotalAppraisalCost(cost: number) {
    this.totalAppraisalCost = cost;
  }

  setIsLinkValid(isValid: boolean) {
    this.isLinkValid = isValid;
  }

  setInitialData(data) {
    const { cardKnoxURL, appraisalDetailsList, totalAppraisalCost, feeCalcError } = data;
    this.setCardKnoxUrl(cardKnoxURL);
    this.setProperties(appraisalDetailsList);
    this.setTotalAppraisalCost(totalAppraisalCost);
    this.feeCalcError = feeCalcError;
  }

  *fetchStandaloneInfo(propertyAppraisalId: string, borrowerName: string) {
    this.setLoading(true);
    try {
      const response: ApiResponse = yield this.tamariskPaymentsService.getStandaloneInfoForTamariskPayment(
        propertyAppraisalId,
        borrowerName
      );
      const { data } = response;

      if (data) {
        this.setInitialData(data);
        this.setLoading(false);
      }
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'There was an error while retrieving payment information',
      });
    }
  }

  *fetchLoanInfo(loanId: string, borrowerName: string) {
    this.setLoading(true);
    try {
      const response: ApiResponse = yield this.tamariskPaymentsService.getLoanInfoForTamariskPayment(
        loanId,
        borrowerName
      );
      const { data } = response;

      if (data) {
        this.setInitialData(data);
        this.setLoading(false);
      }
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'There was an error while retrieving payment information',
      });
    }
  }

  *fetchBorrowerInfo(id: string, type: string) {
    try {
      const response: ApiResponse = yield this.tamariskPaymentsService.getCardholderInfo(
        id,
        type,
      );
      const { data } = response;

      if (data) {
        const { cardholderName, cardholderEmail } = data;
        this.onFieldChange('fullName', cardholderName);
        this.onFieldChange('email', cardholderEmail);
      }
    } catch (error) {
    }
  }

  *checkIfValidLink(id: string, tamariskFlowType: string) {
    this.setLoading(true);
    try {
      const response: ApiResponse = yield this.tamariskPaymentsService.checkIfValidLink(id, tamariskFlowType);
      const { data } = response;
      this.setIsLinkValid(data);
      return data;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'There was an error while verifying payment link',
      });
    } finally {
      this.setLoading(false);
    }
  }

  resetStore() {
    this.form.fullName = null;
    this.form.email = null;
    this.fullName = null;
    this.properties = null;
    this.totalAppraisalCost = null;
    this.feeCalcError = null;
    this.cardKnoxURL = null;
    this.loading = false;
  }
}

export default TamariskDocumentStore;
