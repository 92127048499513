import { FormStore, GlobalStore } from '@roc/feature-app-core';
import { generateUUID, GENERIC_ERROR_MESSAGE } from '@roc/feature-utils';
import { action, flow, makeObservable, observable } from 'mobx';
import { LoanForeclosureService } from '../services/loanForeclosureService';

const soldAtDetailsForm = {
  fields: {
    saleDate: {
      value: '',
      error: null,
      rule: '',
    },
    salePrice: {
      value: '',
      error: null,
      rule: '',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export class SoldAtForeclosureDetailsStore {
  private globalStore: GlobalStore;
  soldAtDetailsFormStore: FormStore;
  loanForeclosureService: LoanForeclosureService;
  isSoldAtDetailDialogOpen: boolean;

  constructor(globalStore: GlobalStore, loanForeclosureService: LoanForeclosureService) {
    this.globalStore = globalStore;
    this.soldAtDetailsFormStore = new FormStore(soldAtDetailsForm, globalStore);
    this.loanForeclosureService = loanForeclosureService;

    makeObservable(this, {
      isSoldAtDetailDialogOpen: observable,
      openSoldAtDetailsDialog: action,
      closeSoldAtDetailsDialog: action,
      reset: action,
      saveSoldAtDetails: flow,
    });
  }

  reset() {
    this.soldAtDetailsFormStore.reset();
  }

  openSoldAtDetailsDialog(data) {
    this.reset();
    this.isSoldAtDetailDialogOpen = true;
    this.soldAtDetailsFormStore.loadForm(data);
  }

  closeSoldAtDetailsDialog() {
    this.isSoldAtDetailDialogOpen = false;
    this.reset();
  }

  *saveSoldAtDetails(loanId) {
    try {
      const data = {
        loanId,
        ...this.soldAtDetailsFormStore.getFormValues(),
      }
      yield this.loanForeclosureService.saveSoldAtDetails(data);
      this.closeSoldAtDetailsDialog();
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE,
      });
    }
  }
}