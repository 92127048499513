import { Service } from '@roc/feature-app-core';

const url = {
  PAYOFF_LOANS: '/api/v1/loan/getPayoffsDashboard',
  GET_FUNDED_LOANS_FOR_PAYOFF_REQUEST: '/api/v1/loan/getFundedLoansForPayoffRequest',
  GET_FUNDED_FORECLOSURE_LOANS_FOR_PAYOFF_REQUEST: '/api/v1/loan/getForeclosureFundedLoansForPayoffRequest',
};

export class ServicerLoanService extends Service {
  getPayoffLoans(
    status: string,
    page: number,
    pageSize: number,
    sortDir: string,
    sortBy: string,
    filters: object,
    dropdownFilters: object
  ) {
    return this.get(
      url.PAYOFF_LOANS,
      {
        status,
        page,
        pageSize,
        sortDir,
        sortBy,
        filters,
        dropdownFilters,
        includeDealName: true
      },
      {
        disableGlobalLoading: true,
      }
    );
  }
  getFundedLoansForPayoffRequest(
    page: number,
    pageSize: number,
    sortDir: string,
    sortBy: string,
    filters: string,
    dropdownFilters: string
  ) {
    return this.get(
      url.GET_FUNDED_LOANS_FOR_PAYOFF_REQUEST,
      {
        page,
        pageSize,
        sortDir,
        sortBy,
        filters,
        dropdownFilters,
      },
      {
        disableGlobalLoading: true,
      }
    );
  }

  getFundedForeclosureLoansForPayoffRequest(
    page: number,
    pageSize: number,
    sortDir: string,
    sortBy: string,
    filters: string,
    dropdownFilters: string
  ) {
    return this.get(
      url.GET_FUNDED_FORECLOSURE_LOANS_FOR_PAYOFF_REQUEST,
      {
        page,
        pageSize,
        sortDir,
        sortBy,
        filters,
        dropdownFilters,
      },
      {
        disableGlobalLoading: true,
      }
    );
  }

}
