import { Page } from '@roc/feature-app-core';
import { Route } from 'react-router';
import { AssignEmailTemplatesScreen } from '../components/assignEmailTemplatesScreen';
import { SendEmailsByOwner } from '../../emails';


export const getMarketingSetupRoutesConfig = (basePath: string, baseUrl: string) => {
  return {
    marketingSetup: {
      path: `${basePath}/marketing-setup`,
      url: `${baseUrl}/marketing-setup`,
      documentTitle: 'Marketing Setup',
    }
  };
}

export const getMarketingSetupRoutes = (basePath: string, baseUrl: string) => {
  const config = getMarketingSetupRoutesConfig(basePath, baseUrl);
  return {
    marketingSetup: (
      <Route exact path={config.marketingSetup.path}>
        <Page routeDefinition={config.marketingSetup}>
          <AssignEmailTemplatesScreen />
        </Page>
      </Route>
    )
  };
}