import React, { useEffect, useState } from 'react';
import { Trulioo, event } from '@trulioo/docv';
import '@trulioo/docv/style.css';
import { useStore } from 'apps/client-portal-public/src/app/hooks';
import { Box, Typography } from '@material-ui/core';
import { FindInPage } from '@material-ui/icons';
import { orange } from '@material-ui/core/colors';
import { DocumentVerificationState } from '@roc/feature-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  verification: {
    '& .ooMain': {
      marginTop:'300px'
    },
  },
});

export const DocumentVerificationV2 = ({ setAllowNavigationInDocVerifyStep, setShowView }) => {
  const elementID = 'trulioo-sdk'; // The HTML element id to attach to
  const host = "/mobile-trulioo-verification" // Set the QR Code redirect url host
  const { wireAuthorizationStore, globalStore, wireAuthorizationStoreV2 } = useStore();
  const classes = useStyles();
  useEffect(() => {
    if (!wireAuthorizationStore.identityVerificationCompleted) {
    const setURL = true;
      let selectedShortCode = wireAuthorizationStoreV2.shortCode;
    const workflowTheme = Trulioo.workflowTheme() // Create the WorkflowTheme object
      .setPrimaryButtonColor("#FF9800")
      .setPrimaryButtonTextColor("#007f5c")
      .build()

    const workflowOption = Trulioo.workflow().setShortCode(selectedShortCode)
      .setTheme(workflowTheme)

    if (setURL) {

      workflowOption.setRedirectUrl(host);
    }

    const callbacks = new event.adapters.ListenerCallback({
      onComplete: (success) => {
        console.info(`Verification Successful: ${success.transactionId} `)
        wireAuthorizationStore.setExperienceId(success.transactionId)
        setAllowNavigationInDocVerifyStep(true);
        wireAuthorizationStore.setDocumentVerificationState(DocumentVerificationState.IN_REVIEW);
        setShowView(true);
      },
      onError: (error) => {
        globalStore.notificationStore.showErrorNotification({
          message: "Verification Failed",
        });
        console.error(`Verification Failed with Error Code: ${error.code}, TransactionID: ${error.transactionId}, Reason: ${error.message}`)
      },
      onException: (exception) => {
        globalStore.notificationStore.showErrorNotification({
          message: "Verification Failed with Exception",
        });
        console.error("Verification Failed with Exception:", exception)
      }
    })
    const callbackOption = Trulioo.event().setCallbacks(callbacks);
    Trulioo.initialize(workflowOption)
      .then((complete) => {
        console.info('Initialize complete:', complete);
        Trulioo.launch(elementID, callbackOption)
          .then((success) => {
            console.info('Launch success:', success);
          });
      })
      .catch((error) => {
        console.error('Error:', error);
      })
    } else {
      setShowView(true);
      setAllowNavigationInDocVerifyStep(true);
    }
  }, []);
  return (
    <div id={elementID} className={classes.verification}>
    </div>
  )
};
export default DocumentVerificationV2;