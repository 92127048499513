import React, { useEffect, useState } from 'react';
import {


  Grid,
  Typography,
  Checkbox,
} from '@material-ui/core';
import { darken } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { Add, Business } from '@material-ui/icons';

import {
  Button,
  Card,
  RadioField,
  SubmitButton,
  trueFalseOptions,
} from '@roc/ui';

import EntityModal, { BorrowerEntityModalState } from './entityModal';
import {
  FixFlipStore,
  SinglePropertyStore,
  RentalPortfolioStore,
  MultiFamilyStore,
} from './../stores';
import { StabilizedBridgeStore } from '../stores/stabilizedBridge/stabilizedBridgeStore';
import { SelectBorrowerEntityGrid } from '@roc/feature-borrowers';

import { observer } from 'mobx-react';
import { GridRowSelectedEvent } from '@roc/ui';

const useStyles = makeStyles(theme =>
  createStyles({
    text: {
      flexGrow: 1,
      width: 400,
    },
    button: {
      marginRight: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    cardSpacing: {
      paddingRight: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    card: {
      background: theme.palette.background.default,
      borderColor: darken(theme.palette.background.default, 0.1),
    },
  })
);
interface BorrowerEntityProps {
  store: FixFlipStore | SinglePropertyStore | RentalPortfolioStore | StabilizedBridgeStore | MultiFamilyStore;
}

const BorrowerEntity = observer((props: BorrowerEntityProps) => {
  const classes = useStyles();
  const { store } = props;
  const { entityStore, entity } = store;
  const { hasEntityInfo } = entityStore;
  const [modalState, setModalState] = useState<BorrowerEntityModalState>(null);

  const closeModal = () => {
    setModalState(null);
    entityStore.reset();
  };

  const onRowSelected = (e: GridRowSelectedEvent) => {
    const { node } = e;
    store.setSelectedEntity(node.data, node.isSelected());
  };

  const handleHasEntityInfoChange = value => {
    const newValue = value === 'true';
    entityStore.setHasEntityInfo(newValue);

    if (newValue) {
      store.deleteEntity();
    } else {
      store.setDefaultEntity();
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4">Entity Information</Typography>
          <br />
          <Typography variant="body1">
            Please choose one existing entity or enter the information about the
            new borrowing entity.
          </Typography>
        </Grid>
      </Grid>

      <br />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <RadioField
            key={'hasEntityInfo'}
            standaloneLabel
            label="Do You Have The Borrowing Entity Information?"
            value={hasEntityInfo}
            name={'hasEntityInfo'}
            options={trueFalseOptions}
            onChange={handleHasEntityInfoChange}
            fullWidth
            row
            testId="hasEntityInfo"
          />
        </Grid>
        <Grid item xs={12}>
          <SubmitButton
            size="small"
            className={classes.button}
            disabled={!hasEntityInfo || !!entity.name}
            variant="contained"
            color="secondary"
            startIcon={<Business />}
            testId="chooseEntityButton"
            onClick={() => store.setExistingEntity()}
            submitDialogProps={{
              title: 'Select Entity',
              body: (
                <SelectBorrowerEntityGrid
                  multiple={false}
                  store={entityStore.existentEntitiesGridStore}
                  onRowSelected={onRowSelected}
                />
              ),
              okButtonText: 'Confirm',
            }}
          >
            CHOOSE EXISTING ENTITY
          </SubmitButton>
          <Button
            size="small"
            className={classes.button}
            disabled={!hasEntityInfo || !!entity.name}
            variant="contained"
            color="secondary"
            startIcon={<Add />}
            onClick={() => setModalState(BorrowerEntityModalState.ADD)}
            testId="add"
          >
            ADD NEW ENTITY
          </Button>
        </Grid>
      </Grid>

      {Object.keys(entity).length !== 0 && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12} md={6} lg={4} className={classes.cardSpacing}>
                <Card
                  color="secondary"
                  title={entity.name}
                  value={entity}
                  className={classes.card}
                  onEdit={
                    !hasEntityInfo
                      ? undefined
                      : entity => {
                        setModalState(BorrowerEntityModalState.EDIT);
                        entityStore.loadEntity(entity);
                      }
                  }
                  onRemove={
                    !hasEntityInfo
                      ? undefined
                      : entity => {
                        store.deleteEntity();
                      }
                  }
                >
                  <div className={classes.text}>
                    Entity EIN:&nbsp;
                    <strong>{entity.ein}</strong>
                    <br />
                    Entity Type:&nbsp;
                    <strong>{entity.type}</strong>
                    {entity.operatingAgreementDate && (
                      <>
                        <br />
                        <span>Date of Incorporation:&nbsp;</span>
                        <strong>{entity.operatingAgreementDate}</strong>
                      </>
                    )}
                    {entity.address && (
                      <>
                        <br />
                        <span>Address:&nbsp;</span>
                        <strong>{entity.address}</strong>
                      </>
                    )}
                  </div>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}

      <EntityModal
        modalState={modalState}
        open={modalState != null}
        handleClose={closeModal}
        store={store}
        handleAdd={() => {
          store.setIsNewEntity(true);
          store.setEntity(
            entityStore.getEntity(),
            entityStore.getEntity()?.borrowers
          );
          entityStore.reset();
          closeModal();
        }}
        handleEdit={() => {
          store.setIsNewEntity(false);
          store.setEntity(
            entityStore.getEntity(),
            entityStore.getEntity()?.borrowers
          );
          entityStore.reset();
          closeModal();
        }}
      />
    </>
  );
});

export default BorrowerEntity;
