import fix_flip_icon from './icons/fix-flip.png';
import fix_flip_2_icon from './icons/fix-flip-2.png';
import ground_up_icon from './icons/ground-up.png';
import mf_1_icon from './icons/mf-1.png';
import mf_2_icon from './icons/mf-2.png';
import mf_3_icon from './icons/mf-3.png';
import sfr_icon from './icons/sfr.png';
import sfr_2_icon from './icons/sfr-2.png';
import rental_portfolios_1_icon from './icons/rental-portfolios-1.png';
import rental_portfolios_2_icon from './icons/rental-portfolios-2.png';
import loanChatLogo from './loanchat-logo.png';
import roc360Logo from './roc360-logo.png';
import appStoreBadge from './app_store_badge.png';
import playStoreBadge from './play_store_badge.png';

export {
  fix_flip_icon,
  fix_flip_2_icon,
  ground_up_icon,
  mf_1_icon,
  mf_2_icon,
  mf_3_icon,
  sfr_icon,
  sfr_2_icon,
  rental_portfolios_1_icon,
  rental_portfolios_2_icon,
  loanChatLogo,
  roc360Logo,
  appStoreBadge,
  playStoreBadge
};
