import React, { useEffect } from 'react';
import { Layout, useQuery } from '@roc/feature-app-core';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router';
import { Tabs } from '@roc/ui';
import { Documents } from './components';
import { useDocumentLibraryStore } from './hooks/useDocumentLibraryStore';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  tabs: {
    marginBottom: theme.spacing(2),
  },
}));

export const DocumentLibrary = observer(() => {
  const { documentLibraryStore } = useDocumentLibraryStore();
  const { tabs, selectedTab, showDocuments, files } = documentLibraryStore;

  const query = useQuery();
  const history = useHistory();

  const classes = useStyles();

  const onTabChange = (e, index) => documentLibraryStore.changeTab(index);

  useEffect(() => {
    const file = query.get('file');
    if (file) {
      documentLibraryStore.downloadDocument(file);
      history.push("document-library");
    }
  }, [documentLibraryStore, history, query]);

  useEffect(() => {
    documentLibraryStore.reset();
    documentLibraryStore.getDocuments();
  }, [documentLibraryStore]);

  return (
    <Layout title="Document Library" maxWidth={'md'}>
      {showDocuments && (
        <>
          <Tabs
            className={classes.tabs}
            tabs={tabs}
            selectedTab={selectedTab}
            onTabChange={onTabChange}
          />
          <Documents documents={files} />
        </>
      )}
    </Layout>
  );
});
