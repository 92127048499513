import React from 'react';
import { Box, Link, Grid, Typography } from '@material-ui/core';
import { lighten } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import PhoneIcon from '@material-ui/icons/Phone';
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows';
import { Link as RouterLink } from 'react-router-dom';
import {
  useAppConfigurations,
  Layout,
} from '@roc/feature-app-core';
import { Paper } from '@roc/ui';
import { useRentalPricerRoutes } from './hooks/useRentalPricerRoutes';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(4),
  },
  loanTypePaper: {
    padding: theme.spacing(4),
    border: `1px solid ${theme.palette.primary.light}`,
    '&:hover': {
      background: lighten(theme.palette.primary.main, 0.9),
    },
  },
  icon: {
    width: 20,
    height: 20,
  },
}));

export const RentalPricerHome = () => {
  const classes = useStyles();
  const { siteName } = useAppConfigurations();
  const { rentalPricerRoutesConfig } = useRentalPricerRoutes();

  return (
    <Layout maxWidth={'md'}>
      <Paper className={classes.paper}>
        <Box mb={4}>
          <Typography align="center" variant="h3">
            Loan Pricers
          </Typography>
        </Box>
        <Box mb={4} style={{ display: 'none' }}>
          <Typography align="center" variant="body1">
            Welcome to {siteName}, we're very glad to assist you through this
            process.
          </Typography>
          <Typography align="center" variant="body1">
            If you have any questions, we're open to help you at any point.
          </Typography>
        </Box>
        <Box mb={4}>
        </Box>
        <Box my={4} mt={8}>
          <Typography align="center" variant="h4" color="primary">
            RENTAL LOAN PRICERS
          </Typography>
        </Box>
        <Box mx={'auto'} mb={4} maxWidth={500}>
          <Grid container direction="row" justifyContent="center" spacing={4}>
            <Grid item xs={12} md={6}>
              <RouterLink to={rentalPricerRoutesConfig.singleProperty.url}>
                <Paper className={classes.loanTypePaper}>
                  <Typography align="center" variant="h6" gutterBottom>
                    SINGLE PROPERTY
                  </Typography>
                  <Typography
                    align="center"
                    variant="body2"
                    color="textSecondary"
                  >
                    Single Family / 2-4 Unit / Townhomes / PUD / Warrantable
                    Condos / 5+ Unit Multifamily / Mixed Use
                  </Typography>
                </Paper>
              </RouterLink>
            </Grid>
            <Grid item xs={12} md={6}>
              <RouterLink to={rentalPricerRoutesConfig.portfolio.url}>
                <Paper className={classes.loanTypePaper}>
                  <Typography align="center" variant="h6" gutterBottom>
                    RENTAL PORTFOLIOS
                  </Typography>
                  <Typography
                    align="center"
                    variant="body2"
                    color="textSecondary"
                  >
                    Single Family / 2-4 Unit / Townhomes / PUD / Warrantable
                    Condos / 5+ Unit Multifamily / Mixed Use
                  </Typography>
                </Paper>
              </RouterLink>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Layout>
  );
};
