import { makeObservable, observable } from 'mobx';
import { GlobalStore } from '@roc/feature-app-core';
import { LogInternalService } from '../services/logInternalService';

export abstract class InternalInsuranceReviewBaseStore {
  protected globalStore: GlobalStore;
  logService: LogInternalService;
  loanId: string;
  properties: any[];
  selectedItems: any[];
  statusFilter: string;
  isInternal: boolean;
  selectedActionApprove: boolean;
  loanStatus: string;
  automatedEvents: any[];

  constructor(globalStore) {
    this.globalStore = globalStore;
    this.initValues();
    makeObservable(this, {
      properties: observable,
      selectedItems: observable,
      statusFilter: observable,
      selectedActionApprove: observable,
      loanStatus: observable,
      automatedEvents: observable
    });
  }
  initValues() {
    this.selectedItems = [];
    this.properties = [];
    this.loanId = '';
    this.statusFilter = null;
    this.loanStatus = '';
    this.automatedEvents = [];
  }
  protected abstract fetchInsuranceData(loanId: string): void;
  protected abstract approveOrRejectFields(propertyInsuranceData: any[], action: string): void;
  protected abstract saveInsuranceData(): void;

  public setLoanStatus(loanStatus: string) {
    this.loanStatus = loanStatus;
  };
}
