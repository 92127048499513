import { NewBorrowerTrackRecord } from '@roc/ui';
import { observer } from 'mobx-react';
import { useBorrowerBaseballCardStore } from '../../hooks/useBorrowerBaseballCardStore';

export const TrackRecordInfoTab = observer(({ borrower }) => {
  const { borrowerBaseballCardStore } = useBorrowerBaseballCardStore();

  if (!borrower?.borrowerId) {
    return null;
  }

  borrowerBaseballCardStore.currentBorrower = borrower.borrowerId;
  borrowerBaseballCardStore.fetchBaseballCardData(borrower.borrowerId, null);

  return (
    <NewBorrowerTrackRecord store={borrowerBaseballCardStore} isInternal={false} />
  );
});
