import {
  Box,
  Divider,
  ListItem,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { sanitizeTestId } from '@roc/feature-utils';
import { Paper, PaperBorderType, TestDataAttribute } from '@roc/ui';

const useStyles = makeStyles(theme => ({
  paper: {
    border: '1px solid',
    borderColor: theme.palette.grey['200'],
    width: '100%',
    padding: theme.spacing(1),
  },
  divider: {
    listStyle: 'none',
  },
}));

export const PaperList = ({ children }) => {
  const classes = useStyles();
  return <Paper borderType={PaperBorderType.SOLID_BORDER} className={classes.paper}>{children}</Paper>;
};

export const PaperListTitle = ({ text, last = false }) => {
  const classes = useStyles();
  return (
    <ListItem>
      <ListItemText>
        <Typography variant="h5">{text}</Typography>
      </ListItemText>
    </ListItem>
  );
};

export const PaperListItem = ({ label, value, last = false }) => {
  const classes = useStyles();
  return (
    <>
      <Divider variant="middle" component="li" className={classes.divider} />
      <ListItem>
        <ListItemText>
          <Box display="flex">
            <Box flexGrow={1}>{label}</Box>
            <TestDataAttribute id={sanitizeTestId(label)}>
              <Typography variant="body1">{value}</Typography>
            </TestDataAttribute>
          </Box>
        </ListItemText>
      </ListItem>
    </>
  );
};

export const PaperListItemError = ({ label, value, last = false }) => {
  const classes = useStyles();
  return (
    <>
      <Divider variant="middle" component="li" className={classes.divider} />
      <ListItem>
        <ListItemText>
          <Box display="flex">
            <Box flexGrow={1}>{label}</Box>
            <Typography color="error" variant="body1">{value}</Typography>
          </Box>
        </ListItemText>
      </ListItem>
    </>
  );
};
