import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Typography,
} from '@material-ui/core';
import { DeleteOutline, ExpandMore, Person } from '@material-ui/icons';
import { FormStore } from '@roc/feature-app-core';
import {
  isNil,
  LoanType,
  prequalificationStatusProperties,
} from '@roc/feature-utils';
import { Button, IconButton, StandardDialog } from '@roc/ui';
import { observer } from 'mobx-react';
import { ReactNode, useEffect, useState } from 'react';
import {
  BorrowersStore,
  QualifyBorrowerStore,
  SelectBorrowersStore,
} from '../stores';
import { Borrower } from '../types';
import QualifyBorrowerForm from './qualifyBorrowerForm';

export const BorrowersAccordion = observer(
  ({
    selectBorrowersStore,
    qualifyBorrowerStore,
    borrowerForm,
    onRemove,
    handlePreQualifyBorrower,
    borrowersStore,
    validateBorrowers,
    loanType = LoanType.RESIDENTIAL_BRIDGE,
  }: {
    selectBorrowersStore: SelectBorrowersStore;
    qualifyBorrowerStore: QualifyBorrowerStore;
    borrowerForm: (
      formStore: FormStore,
      currentBorrower?: Borrower
    ) => ReactNode;
    onRemove: (borrower: Borrower) => void;
    handlePreQualifyBorrower: () => void;
    borrowersStore: BorrowersStore;
    validateBorrowers: () => void;
    loanType: string;
  }) => {
    const { borrowerAccordionStore } = selectBorrowersStore;
    const { borrowerForms } = borrowerAccordionStore;

    const prequalifyBorrower = (borrower, qualifyBorrowerRequestFormStore) => {
      if (!isNaN(borrower?.borrowerId)) {
        const creditBackgroundCheck =
          qualifyBorrowerRequestFormStore.form.fields.creditBackgroundCheck.value;
        if (!isNil(creditBackgroundCheck)) {
          selectBorrowersStore.setCurrentBorrower(borrower);
          qualifyBorrowerStore.reset();
          qualifyBorrowerStore.setInitialValues(borrower);
          const personalGuarantor = !isNil(borrower?.personalGuarantor)
            ? borrower?.personalGuarantor
            : borrower?.runCreditOnCreation;
          borrowersStore.borrowerSetupStore.init(
            borrower,
            personalGuarantor,
            LoanType.RESIDENTIAL_BRIDGE
          );
          qualifyBorrowerStore.qualifyBorrowerRequestFormStore.loadForm(
            qualifyBorrowerRequestFormStore.getFormValues()
          );
          handlePreQualifyBorrower();
        }
      }
    };

    useEffect(() => {
      borrowerAccordionStore.setPrequalifyBorrowerCallback(prequalifyBorrower);
      borrowerAccordionStore.refreshAccordionBorrowers();
      validateBorrowers();
    }, []);

    useEffect(() => {
      borrowerAccordionStore.refreshAccordionBorrowers();
      validateBorrowers();
    }, [selectBorrowersStore?.borrowers?.length]);

    return (
      <Grid container spacing={2}>
        {borrowerForms?.map(item => (
          <Grid item xs={12} md={6} lg={4}>
            <BorrowersAccordionItem
              borrower={item.borrower}
              borrowerAccordionStore={borrowerAccordionStore}
              borrowerFormStore={item.borrowerFormStore}
              qualifyBorrowerRequestFormStore={
                item.qualifyBorrowerRequestFormStore
              }
              onRemove={onRemove}
              borrowerForm={borrowerForm}
              validateBorrowers={validateBorrowers}
              loanType={loanType}
            />
          </Grid>
        ))}
      </Grid>
    );
  }
);

const BorrowersAccordionItem = observer(
  ({
    borrower,
    borrowerAccordionStore,
    borrowerFormStore,
    qualifyBorrowerRequestFormStore,
    onRemove,
    borrowerForm,
    validateBorrowers,
    loanType,
  }) => {
    const borrowerFields = borrowerFormStore.form.fields;
    const personalGuarantor = borrowerFields.personalGuarantor.value;

    const [expanded, setExpanded] = useState(true);

    const fieldValues = Object.values(borrowerFields).map((f: any) => f.value);

    useEffect(() => {
      borrowerAccordionStore.saveBorrower(borrower, borrowerFormStore);
      validateBorrowers();
    }, [...fieldValues]);

    useEffect(() => {
      if (!isNil(personalGuarantor) && borrower.borrowerId && !isNaN(borrower.borrowerId)) {
        borrowerAccordionStore.loadBorrowerPrescreen(borrower, personalGuarantor, loanType, borrowerFormStore);
      }
    }, [personalGuarantor]);

    return (
      <Box style={{ height: '100%' }} pt={4}>
        <Accordion
          expanded={expanded}
          onChange={(e, expanded) => setExpanded(expanded)}
          elevation={0}
          style={{
            borderRadius: 8,
            boxShadow:
              'rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px',
            height: expanded ? '100%' : undefined,
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography variant="h6">
              <Box pl={1} display="flex" alignItems="center">
                <Person fontSize={'large'} />
                <Box pl={2}>
                  {borrower?.firstName} {borrower?.lastName}
                </Box>
              </Box>
            </Typography>
            <Box flex={1} textAlign="right">
              {!!borrower.showDelete && onRemove && (
                <IconButton
                  style={{ marginTop: '-8px', marginBottom: '-8px' }}
                  testId="remove-borrower"
                  onClick={e => {
                    onRemove(borrower);
                    e.stopPropagation();
                  }}
                >
                  <DeleteOutline color="primary" />
                </IconButton>
              )}
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Box px={2}>
              {borrowerForm(borrowerFormStore, borrower)}
              {!isNil(personalGuarantor) && (
                <Box pt={2}>
                  <QualifyBorrowerForm
                    store={qualifyBorrowerRequestFormStore}
                    borrower={{
                      ...borrower,
                      personalGuarantor,
                    }}
                  />
                </Box>
              )}
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
    );
  }
);
