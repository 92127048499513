import { RocUniversityBaseStore } from './rocUniversityBaseStore';
import { makeObservable, override } from 'mobx';
import { RocUniversityService } from '../services/rocUniversityService';

export class RocUniversityStore extends RocUniversityBaseStore {
  private rocUniversityService: RocUniversityService;

  constructor(globalStore) {
    super(globalStore);
    this.rocUniversityService = new RocUniversityService();

    makeObservable(this, {
      getRocUniversityURL: override,
      getLogoLMS: override,
    });
  }

  *getRocUniversityURL() {
    try {
      const response = yield this.rocUniversityService.getRocUniversityUserUrl();
      this.updateRocUniversityURL(response.data.data);
    } catch (err) {
      this.setShowAlert(true);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while logging to Roc University',
      });
    }
  }
  
  getLogoLMS() {
    const logoUrl = this.rocUniversityService.getLogoLMS();
    this.setLogoLMS(logoUrl);
  }
}
