import { useEffect, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { Paper, StepperComponent } from '@roc/ui';
import { observer } from 'mobx-react';
import { useParams } from 'react-router';

import { DecodedData, decodeData } from './loanFastrackPaymentsUtils';

import LoanFastrackPaymentInformation from './components/loanFastrackPaymentInformation';
import LoanFastrackPayment from './components/loanFastrackPayment';
import LoanFastrackDocusign from './components/loanFastrackDocusign';
import { useLoanFastrackPaymentsStore } from './hooks/useLoanFastrackPaymentsStore';
import { Layout } from '@roc/feature-app-core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(3, 0, 4),
      borderRadius: theme.spacing(2),
      boxShadow:
        'rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px',
    },
    stepper: {
      maxWidth: 900,
      margin: 'auto',
      [theme.breakpoints.down('xs')]: {
        paddingLeft: theme.spacing(0.5),
        paddingRight: theme.spacing(0.5),
      },
    },
    stepContentWrapper: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      [theme.breakpoints.down('xs')]: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
      },
    },
  })
);

export type EncodedParams = {
  encodedParams: string;
};

export const LoanFastrackPayments = observer(() => {
  const classes = useStyles();
  const [entityData, setEntityData] = useState(null);
  const { encodedParams } = useParams<EncodedParams>();

  useEffect(() => {
    setEntityData(decodeData(encodedParams));
  }, []);

  return (
    <Layout>
      <Box my={3}>
        <Typography variant="h3" gutterBottom>
          {'Loan Fast Track Payment'}
        </Typography>
      </Box>
      <Paper className={classes.paper}>
        {entityData && <LoanFastrackPaymentsBody entityData={entityData} />}
      </Paper>
    </Layout>
  );
});

export const LoanFastrackPaymentsBody = observer(({ entityData }: { entityData: DecodedData }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [disableStepperNavigation, setDisableStepperNavigation] = useState(
    false
  );
  const { entityId, tamariskFlowType } = entityData;
  const { loanFastrackPaymentsStore } = useLoanFastrackPaymentsStore();
  const { docusignStatus } = loanFastrackPaymentsStore;
  const classes = useStyles();

  const loanTermsSteps = [
    {
      title: 'Payment Details',
      icon: null,
      onBack: () => { },
      onNext: () => { },
      allowBack: false,
      allowNext: true,
    },
    {
      title: 'Acknowledgment',
      icon: null,
      onBack: () => { },
      onNext: () => { },
      allowBack: true,
      allowNext: true,
    },
    {
      title: 'Payment',
      icon: null,
      onBack: () => { },
      onNext: () => { },
      allowBack: false,
      allowNext: true,
    },
  ];

  const handleBackStep = () => setActiveStep(activeStep - 1);

  const handleNextStep = () => setActiveStep(activeStep + 1);

  const handleStep = (index: number) => setActiveStep(index);

  useEffect(() => {
    return () => {
      loanFastrackPaymentsStore.reset();
      loanFastrackPaymentsStore.loanFastrackDocumentStore.reset();
    };
  }, []);

  useEffect(() => {
    if (entityId) {
      loanFastrackPaymentsStore.fetchDocusignAndPaymentStatus(
        entityId,
        tamariskFlowType
      );
    }
  }, [entityId]);

  useEffect(() => {
    setDisableStepperNavigation(!docusignStatus);
  }, [docusignStatus]);

  if (entityId) {
    return (
      <>
        <StepperComponent
          activeStep={activeStep}
          disableNavigation={disableStepperNavigation}
          handleStep={handleStep}
          steps={loanTermsSteps}
          className={classes.stepper}
        />
        <Box
          mt={3}
          mx={'auto'}
          className={classes.stepContentWrapper}
        >
          {activeStep === 0 && (
            <LoanFastrackPaymentInformation
              handleNextStep={handleNextStep}
              entityId={entityId}
              tamariskFlowType={tamariskFlowType}
            />
          )}
          {activeStep === 1 && (
            <LoanFastrackDocusign
              handleBackStep={handleBackStep}
              handleNextStep={handleNextStep}
              entityId={entityId}
              tamariskFlowType={tamariskFlowType}
            />
          )}
          {activeStep === 2 && (
            <LoanFastrackPayment
              handleDisableStepperNavigation={setDisableStepperNavigation}
              entityData={entityData}
            />
          )}
        </Box>
      </>
    );
  } else {
    return null;
  }
});