import { Grid, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import { useTheme } from '@material-ui/core/styles';
import { Button, FileUpload, FileUploadArea } from '@roc/ui';
import { TrackRecordPropertyForm } from './trackRecordPropertyForm';
import { TrackRecordProperties } from './trackRecordProperties';
import { PreScreenBorrowerTierNotification } from '@roc/feature-borrowers';
import ExcelIcon from './../../../../../assets/icon-microsoft-excel.png';
import { useEffect, useState } from 'react';
import { useDocumentLibraryStore } from '@roc/feature-document-library';
import { trackRecordTemplateFilePath } from '@roc/feature-utils';



export const TrackRecordFirstStep = observer(({ store, onDocUploadCallback = () => { } }) => {
  const { borrowerTier } = store;
  const theme = useTheme();
  const [templateUploads, setTemplateUploads] = useState<FileUpload[]>([]);
  const { documentLibraryStore } = useDocumentLibraryStore();

  useEffect(() => {
    store.isTrackRecordTemplateParsingAllowed();
  }, []);

  useEffect(() => {
    store.setTemplateUploads(templateUploads);
    store.parseTrackRecordTemplate(onDocUploadCallback);
  }, [templateUploads]);

  const downloadTrackRecordTemplate = () => {
    try {
      documentLibraryStore.downloadPublicDocument(trackRecordTemplateFilePath);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Grid container direction='row' spacing={2} justifyContent='flex-end'>
      {store.trackRecordTemplateParsingAllowed &&
        <Grid item>
          <Typography
            component="span"
            variant='subtitle2'
            style={{ cursor: 'pointer', color: theme.palette.primary.main, textDecoration: 'underline' }}
            onClick={() => store.setDigitalTrackRecord(!store.digitalTrackRecord)}
          >
            {store.digitalTrackRecord ? 'Upload using Track Record Template' : 'Continue with digital track record'}
          </Typography>
        </Grid>
      }
      {store.digitalTrackRecord ?
        <>
          <PreScreenBorrowerTierNotification
            bridgeTier={borrowerTier?.bridgeTier}
            propertiesToNextBridgeTier={borrowerTier?.propertiesToNextBridgeTier}
            rentalTier={borrowerTier?.rentalTier}
            propertiesToNextRentalTier={borrowerTier?.propertiesToNextRentalTier}
          />
          <Grid item xs={12}>
            <Typography>{store?.isTpoFlow ? 'To calculate the borrower\'s experience ranking, please add the properties of the deals that he has done in the past' : 'To calculate your experience ranking, please add the properties of the deals that you have done in the past'}</Typography>
          </Grid>
          {store.properties.length > 0 && (
            <Grid item xs={12}>
              <TrackRecordProperties store={store} />
            </Grid>
          )}
          {store.isPropertyFormActive && (
            <Grid item xs={12}>
              <TrackRecordPropertyForm store={store} />
            </Grid>
          )}
          {!store.isPropertyFormActive && (
            <Grid item xs={12}>
              <Button
                variant='outlined'
                color='primary'
                onClick={() => store.handleAddNewTrackRecordProperty()}
                testId={`addNewProperty`}
              >
                Add New Property
              </Button>
            </Grid>
          )}
        </>
        :
        <Grid item xs={12}>
          <Grid item container direction='row' alignItems='center'>
            <Grid item>
              <img
                src={ExcelIcon}
                style={{
                  cursor: 'pointer',
                  maxWidth: 30,
                }}
                onClick={() => downloadTrackRecordTemplate()}
              />
            </Grid>
            <Grid item>
              <Typography
                component="span"
                variant='subtitle2'
                style={{ cursor: 'pointer', color: theme.palette.primary.main, textDecoration: 'underline' }}
                onClick={() => downloadTrackRecordTemplate()}
              >
                Download Template
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <FileUploadArea
              items={templateUploads}
              setItems={(items) => setTemplateUploads(items)}
              multiple={false}
            />
          </Grid>
        </Grid>
      }
    </Grid>
  );
});
