import { makeObservable, flow, action, computed, observable, toJS } from 'mobx';
import { GlobalStore, ApiResponse } from '@roc/feature-app-core';
import { WhatsNewService } from '../services/whatsNewService';
import { Tab } from '@roc/ui';
import { IVideoUrl, IVideo } from '../types';

const OLDER_UPDATES = 'Older Updates';

export class WhatsNewStore {
  globalStore: GlobalStore;
  whatsNewService: WhatsNewService;
  currentTab: number;
  videos: IVideo[];
  currentVideo: number;
  videoUrls: IVideoUrl;

  constructor(globalStore) {
    this.globalStore = globalStore;
    this.whatsNewService = new WhatsNewService();
    this.currentTab = 0;
    this.videos = [];
    this.currentVideo = null;
    this.videoUrls = {};
    makeObservable(this, {
      reset: action,
      getVideos: flow,
      setCurrentVideo: action,
      getVideoUrl: flow,
      changeTab: action,
      currentTab: observable,
      videos: observable,
      currentVideo: observable,
      videoUrls: observable,
      showVideos: computed,
      tabs: computed,
      currentTabVideos: computed,
    });
  }

  reset() {
    this.videos = [];
    this.currentVideo = null;
    this.videoUrls = {};
  }

  *getVideos() {
    try {
      const response: ApiResponse = yield this.whatsNewService.getVideos();
      this.videos = response.data?.data;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while getting videos.',
      });
    }
  }

  setCurrentVideo(index: number) {
    this.currentVideo = this.currentVideo !== index ? index : null;
  }

  *getVideoUrl(video: IVideo) {
    try {
      const response: ApiResponse = yield this.whatsNewService.getVideoUrl(
        video.path
      );
      this.videoUrls[video.displayFileName] = response.data?.data.replace(
        '?dl=0',
        '?raw=1'
      );
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while loading video.',
      });
    }
  }

  get showVideos() {
    return this.videos && this.videos.length > 1;
  }

  get tabs() {
    return (
      this.videos
        .filter(
          (x, i, a) =>
            x.displayCategory &&
            a.findIndex(b => b.displayCategory === x.displayCategory) === i
        )
        // As of now, we have not enabled Older Updates logic
        .filter(x => x.displayCategory !== OLDER_UPDATES)
        .map(
          (video): Tab => ({
            label: video.displayCategory,
          })
        )
    );
  }

  changeTab(selectedTab: number) {
    this.currentTab = selectedTab;
    this.currentVideo = null;
    this.videoUrls = {};
  }

  get currentTabVideos(): IVideo[] {
    const tab = this.tabs[this.currentTab];
    return this.videos.filter(video => video.displayCategory === tab.label);
  }
}

export default WhatsNewStore;
