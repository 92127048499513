import { makeObservable } from "mobx";
import { FormStore } from "@roc/feature-app-core";
import { GlobalStore } from "@roc/feature-app-core";

const form = {
  fields: {
    field: {
      value: '',
      error: null,
      rule: 'required',
      message: 'This value is required',
    },
    operator: {
      value: '',
      error: null,
      rule: 'required',
      message: 'This value is required',
    },
    value: {
      value: null,
      error: null,
      rule: 'required',
      message: 'This value is required',
    }
  },
  meta: {
    isValid: false,
    error: null,
  },
};
export class AddFilterFormStore extends FormStore {
  constructor(globalStore: GlobalStore) {
    super(form, globalStore);
    makeObservable(this, {
    });
  }

  onFieldChange(field, value) {
    if (value instanceof Date) {
      const newVal = value.toLocaleDateString("en-US");
      super.onFieldChange(field, newVal);
    } else if (field === 'field') {
      this.form.fields.operator.value = null;
      this.form.fields.value.value = null;
      super.onFieldChange(field, value);
    } else {
      super.onFieldChange(field, value);
    }
  }
}
