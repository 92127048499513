import { InputAdornment, TextField } from '@material-ui/core';
import React from 'react';
import { FieldLabel } from '../fieldLabel/fieldLabel';
import { NumberFormat } from '../numberFormat/numberFormat';
export interface CurrencyFieldProps {
  label?: string;
  name?: string;
  value?: number | string;
  onChange?: (name: string, value: number | string) => void;
  onBlur?: () => void;
  disabled?: boolean;
  required?: boolean;
  error?: boolean;
  errorText?: string;
  fullWidth?: boolean;
  margin?: string;
  variant?: string;
  inputClassname?: string;
  standaloneLabel?: boolean;
  testId: string;
  tooltip?: string;
  placeholder?: string;
  groupSeparator?: string;
  allowNegative?: boolean;
  style?: any;
  decimalScale?: number;
  onKeyDown?: any;
}

const _CurrencyField: React.FC<CurrencyFieldProps> = ({
  label,
  name,
  value,
  onChange,
  onBlur,
  disabled,
  required,
  error,
  errorText,
  fullWidth,
  margin,
  variant,
  inputClassname,
  testId,
  placeholder,
  groupSeparator,
  allowNegative = false,
  decimalScale = 2,
  style,
  onKeyDown,
}) => {
  return (
    <NumberFormat
      testId={testId}
      label={label}
      name={name}
      value={value}
      onValueChange={values => {
        onChange &&
          onChange(name, values.floatValue ? values.floatValue : values.value);
      }}
      onBlur={onBlur}
      displayType={'input'}
      mask=","
      thousandSeparator={true}
      decimalScale={decimalScale}
      allowNegative={allowNegative}
      customInput={TextField}
      inputProps={{ maxLength: '80' }}
      InputProps={{
        startAdornment: <InputAdornment position="start">$</InputAdornment>,
      }}
      disabled={disabled}
      required={required}
      error={error}
      helperText={errorText}
      fullWidth={fullWidth}
      margin={margin}
      variant={variant}
      className={inputClassname}
      placeholder={placeholder}
      groupSeparator={groupSeparator}
      style={style}
      onKeyDown={onKeyDown}
    />
  );
};

export const CurrencyField = (props: CurrencyFieldProps) => {
  const { standaloneLabel, tooltip, ...muiComponentProps } = props;
  return (
    <>
      {standaloneLabel && muiComponentProps.label ? (
        <FieldLabel tooltip={tooltip}>
          {muiComponentProps.label}
          {muiComponentProps.required && ' *'}
        </FieldLabel>
      ) : null}
      <_CurrencyField
        {...muiComponentProps}
        label={
          standaloneLabel
            ? undefined
            : muiComponentProps.label
        }
      />
    </>
  );
};
