import { Box, Link } from '@material-ui/core';
import { VerifyTwoFactorUI } from '@roc/feature-2fa';
import { LoginLogoImg, useAuthenticationStore } from '@roc/feature-app-core';
import { useLogoutStore } from './../../modules/logout';
import { observer } from 'mobx-react';

export const VerifyTwoFactorLoginPage = observer(({ showLogout = true, onSuccess, onFailure }: {
  showLogout?: boolean,
  onSuccess?: () => void,
  onFailure?: () => void,
}) => {
  const { logoutStore } = useLogoutStore();
  const { authenticationStore } = useAuthenticationStore();

  const verifyTwoFactorCode = (code) => {
    return authenticationStore.verifyTwoFactor(code)
      .then(() => {
        onSuccess && onSuccess();
        return true;
      })
      .catch((e) => {
        onFailure && onFailure();
        return false;
      });
  };

  return (
    <Box display={"flex"} flexDirection={"column"} pt={2} maxWidth={1000} margin={'auto'} position={'relative'}>
      <Box margin={'auto'}>
        <Box>
          <LoginLogoImg imgStyle={{
            maxWidth: 200
          }} />
        </Box>
        {
          showLogout && <Box position={'absolute'} right={30} top={30}>
            <Link style={{
              cursor: 'pointer',
              color: 'blue',
              textDecoration: 'underline'
            }} onClick={() => logoutStore.logout()}>
              Logout
            </Link>
          </Box>
        }
      </Box>
      <Box>
        <VerifyTwoFactorUI onSubmit={verifyTwoFactorCode} />
      </Box>
    </Box>
  );
});