import { StandardDialog } from '@roc/ui';
import { IndividualPhoto } from './individualPhoto';
import { CompToolPhotosStore } from '../../stores';

export type IndividualPhotoDialogProps = {
  open?: boolean;
  onClose?: () => void;
  compToolPhotosStore: CompToolPhotosStore;
  closeButtonLabel?: string;
};


export const IndividualPhotoDialog = (props: IndividualPhotoDialogProps) => {
  const { open, onClose, compToolPhotosStore, closeButtonLabel } = props;

  return (
    <StandardDialog
      open={open}
      maxWidth="md"
      onClose={onClose}
      removeContentBox={true}
      style={{ overflow: 'hidden' }}
      dialogContent={<IndividualPhoto onClose={onClose} compToolPhotosStore={compToolPhotosStore} closeButtonLabel={closeButtonLabel}/>}
    />
  );
};

export default IndividualPhotoDialog;
