import { ApiResponse, GlobalStore, GridStore } from '@roc/feature-app-core';
import { BorrowerLoansService } from '../services/borrowerLoansService';
import { GENERIC_ERROR_MESSAGE } from '@roc/feature-utils';

const PAGE_SIZE = 10;
export class BorrowerPipelineLoansGridStore extends GridStore {
  globalStore: GlobalStore;
  borrowerLoansService: BorrowerLoansService;

  constructor(globalStore: GlobalStore) {
    super(() => this.fetchBorrowerPipelineLoans(), undefined, PAGE_SIZE);
    this.globalStore = globalStore;
    this.borrowerLoansService = new BorrowerLoansService();
  }

  fetchBorrowerPipelineLoans = async () => {
    try {
      const response = await this.borrowerLoansService.getBorrowerPipelineLoansGrid(
        this.gridData.meta.pageNumber,
        this.gridData.meta.pageSize,
        this.gridData.meta.sortDir,
        this.gridData.meta.sortBy,
        this.gridData.meta.filters,
        this.gridData.meta.dropdownFilters
      );
      return response;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE
      });
    }
  };
}
