import { MultiFamilyStore } from './multifamilyStore';
import { FormStore } from '@roc/feature-app-core';
import { action, makeObservable, observable, flow, computed } from 'mobx';
import { GlobalStore } from '@roc/feature-app-core';
import { AttorneysStore } from '../common/attorneysStore';
import CompanyFormStore from '../common/companyFormStore';
import { LoanService } from '../../../loans/services/loanService';
import { BRIDGE, LoanSubType, AllowedWimba, WIMBA, ELMSURE, OTHER, isNotBlank } from '@roc/feature-utils';
import { LoanOriginatorStore } from '../common/loanOriginatorStore';
import { InsuranceCompanyFormStore } from '../common/insuranceCompanyFormStore';
import { TitleCompanyFormStore } from '../common/titleCompanyFormStore';
import { ApiResponse } from '@roc/client-portal-shared/services';
import { FileUpload } from '@roc/ui';
import { getDealName } from '../../utils/dealNameUtils';
import { LoanParticipantsStore } from '@roc/feature-loan-participants';

const loanInformationForm = {
  fields: {
    initialLoanAmount: {
      value: 0,
      error: null,
      rule: 'required',
    },
    initialLoanToPurchase: {
      value: 70,
      error: null,
      rule: 'required',
    },
    constructionHoldback: {
      value: 0,
      error: null,
      rule: 'required',
    },
    constructionHoldbackPercentage: {
      value: 100,
      error: null,
      rule: 'required',
    },
    rate: {
      value: 12.0,
      error: null,
      rule: 'required',
    },
    pointsFormat: {
      value: 'percentage',
      error: null,
      rule: 'required',
    },
    points: {
      value: 2,
      error: null,
      rule: 'required|numeric|min:0|max:6',
      message:
        'The points field is required. Percentage must be between 0 and 6',
    },
    pointsNumber: {
      value: 0,
      error: null,
      rule: 'required|numeric',
    },
    exitFees: {
      value: 0,
      error: null,
      rule: 'required',
    },
    capitalProviderExitFees: {
      value: 0,
      error: null,
      rule: 'required',
    },
    brokerPointsFormat: {
      value: 'percentage',
      error: null,
      rule: 'required',
    },
    brokerPoints: {
      value: 0.0,
      error: null,
      rule: 'required|numeric|min:0|max:6',
      message:
        'The broker points field is required. Percentage must be between 0 and 6',
    },
    brokerPointsNumber: {
      value: 0,
      error: null,
      rule: 'required|numeric',
    },
    lenderUnderwritingFee: {
      value: 0,
      error: null,
      rule: 'required',
    },
    rocPointsIn: {
      value: null,
      error: null,
      rule: '',
    },
    interestAccrualMethod: {
      value: 'As disbursed',
      error: null,
      rule: 'required',
    },
    interestReserveMonths: {
      value: '1',
      error: null,
      rule: 'required',
    },
    duration: {
      value: 12,
      error: null,
      rule: 'required',
    },
    participation: {
      value: 'No Participation',
      error: null,
      rule: 'required',
    },
    preferredClosingAttorney: {
      value: 'lawyer.hdrbb@roccapital.com',
      error: null,
      rule: 'required',
    },
    preferredInsurance: {
      value: '',
      error: null,
      rule: 'required',
    },
    loanOriginator: {
      value: null,
      error: null,
      rule: '',
    },
    submitAppraisalWithTamarisk: {
      value: 'Y',
      error: null,
      rule: 'required',
    },
    requestedClosingDate: {
      value: null,
      error: null,
      rule: '',
    },
    amount: {
      value: 0,
      error: null,
      rule: '',
    },
    preferredTitle: {
      value: '',
      error: null,
      rule: '',
    },
    wimbaAcknowledged: {
      value: null,
      error: null,
      rule: '',
    },
    fastTrack: {
      value: 'N',
      error: null,
      rule: 'required',
    },
    fastTrackOptions: {
      value: { insuranceReview: false, titleSearch: false, insurance: false, rushedAppraisal: false, titleAttorneySearch: false },
      error: null,
      rule: ''
    },
    freeAppraisalPromotion: {
      value: 'N',
      error: null,
      rule: '',
    },
    referralFee: {
      value: 0,
      error: null,
      rule: 'required|numeric|min:0|max:1',
      message:
        'The referral fee field is required and cannot exceed 1% of the total loan amount',
    },
    referralFeesFormat: {
      value: 'percentage',
      error: null,
      rule: 'required',
    },
    referralFeesAmount: {
      value: 0,
      error: null,
      rule: 'required|numeric',
      message: 'The referral fee field is required',
    },
  },
  meta: {
    isValid: false,

    error: null,
  },
};

export class LoanInformationStore extends FormStore {
  private globalStore: GlobalStore;
  private multiFamilyStore: MultiFamilyStore;
  private attorneysStore: AttorneysStore;
  private totalPurchasePrice = 0;
  private totalRenovationBudget = 0;
  private otherInsuranceDetails = {};
  private otherTitleDetails = {};
  otherInsuranceFormStore: CompanyFormStore;
  otherTitleFormStore: CompanyFormStore;
  loanOriginatorStore: LoanOriginatorStore;
  loanParticipantsStore: LoanParticipantsStore;
  public lenderAttorneysOptionList: any[];
  public wimbaStates: any[];
  public loanService: LoanService;
  public draftLoanId: string;
  public fastTrackFees = {};
  public fastTrackPaymentUrl: string;
  public tertiaryMarketZipCodes: any[];
  public licenseUpload: FileUpload[];
  public preferredAttorneyForBroker: string;

  constructor(globalStore, multiFamilyStore) {
    super({ ...loanInformationForm }, globalStore);
    this.globalStore = globalStore;
    this.multiFamilyStore = multiFamilyStore;
    this.attorneysStore = new AttorneysStore(this.globalStore);
    this.loanOriginatorStore = new LoanOriginatorStore(this.globalStore);
    this.otherInsuranceFormStore = new InsuranceCompanyFormStore(this.globalStore);
    this.loanParticipantsStore = new LoanParticipantsStore(this.globalStore);
    this.otherTitleFormStore = new TitleCompanyFormStore(this.globalStore);
    this.loanService = new LoanService();
    this.setDefaults();
    makeObservable(this, {
      draftLoanId: observable,
      lenderAttorneysOptionList: observable,
      wimbaStates: observable,
      tertiaryMarketZipCodes: observable,
      resetLoanInformation: action,
      makeInitialCalculations: action,
      changeInitialLoanAmount: action,
      changeInitialLoanToPurchase: action,
      changeConstructionHoldback: action,
      changeConstructionHoldbackPercentage: action,
      changePointsPercentage: action,
      changePointsAmount: action,
      onChange: action,
      getPreferredAttorney: action,
      setOtherInsuranceCompany: action,
      initForm: action,
      getLenderAttorneys: flow,
      getWimbaStates: flow,
      getAllTertiaryMarketZipCode: flow,
      recourseGuarantyStructureValue: computed,
      uploadInsuranceDocument: flow,
      fetchLoanId: flow,
      updateLoanFastrackInfo: flow,
      requestFastTrackPaymentEmail: flow,
      loadFastTrackFees: flow,
      getLoanFastTrackPaymentUrl: flow,
      canSubmitAppraisalWithTamarisk: computed,
      licenseUpload: observable,
      setLicenseUpload: action,
      preferredAttorneyForBroker: observable,
      resetBrokerPointsBasedOnHud: action,
    });
  }

  setDefaults() {
    this.otherInsuranceDetails = {};
    this.otherTitleDetails = {};
    this.lenderAttorneysOptionList = [];
    this.fastTrackFees = {};
    this.fastTrackPaymentUrl = '';
    this.draftLoanId = '';
    this.licenseUpload = [];
  }
  *getLenderAttorneys() {
    const isBroker = this.globalStore.userFeatures.isBroker;
    if (isBroker) {
      const response = yield this.multiFamilyStore.brokersStore.manageBrokersStore.getBrokerVendorClosingSettingsByBrokerId(this.multiFamilyStore.brokersStore.selectBrokersStore.brokers[0].brokerId);
      this.preferredAttorneyForBroker = response.preferredClosingAttorney;
    }
    yield this.attorneysStore.getLenderAttorneys(BRIDGE, this.getStatesFromProperties(this.multiFamilyStore.properties), this.form?.fields?.amount?.value);
    if (isBroker) {
      this.setDefaultPreferredClosingAttorneyForBroker(this.attorneysStore.lenderAttorneysOptionList, this.preferredAttorneyForBroker);
    } else {
      this.setDefaultPreferredClosingAttorney(this.attorneysStore.lenderAttorneysOptionList)
    }
    this.lenderAttorneysOptionList = this.attorneysStore.lenderAttorneysOptionList;
  }

  setDefaultPreferredClosingAttorney(attorneys) {
    if (isNotBlank(this.form.fields.preferredClosingAttorney.value) && !this.isClosingAttorneyPresentOnList(attorneys, this.form.fields.preferredClosingAttorney.value)) {
      this.isClosingAttorneyPresentOnList(attorneys, 'lawyer.hdrbb@roccapital.com') ?
        this.onFieldChange('preferredClosingAttorney', 'lawyer.hdrbb@roccapital.com') :
        this.onFieldChange('preferredClosingAttorney', 'lawyer.privatelenderlaw@roccapital.com');
    }
  }

  isClosingAttorneyPresentOnList(attorneys, preferredClosingAttorney) {
    return attorneys.some(attorney => attorney.value === preferredClosingAttorney);
  }

  setDefaultPreferredClosingAttorneyForBroker(attorneys, preferredClosingAttorney) {
    if (isNotBlank(this.form.fields.preferredClosingAttorney.value)) {
      console.log(this.isClosingAttorneyPresentOnList(attorneys, preferredClosingAttorney))
      this.isClosingAttorneyPresentOnList(attorneys, preferredClosingAttorney) ?
        this.onFieldChange('preferredClosingAttorney', preferredClosingAttorney) :
        this.onFieldChange('preferredClosingAttorney', 'lawyer.hdrbb@roccapital.com');

    }
  }

  *getWimbaStates() {
    try {
      const response = yield this.loanService.getWimbaStates();
      if (response && response.data && response.data.data) {
        this.wimbaStates = Array.isArray(response.data.data) ? response.data.data : JSON.parse(response.data.data)
      }
      if (this.wimbaStates.length == 0) {
        this.wimbaStates = AllowedWimba;
      }
    } catch (error) {
      console.log(error);
      this.wimbaStates = AllowedWimba;
    }
  }

  getStatesFromProperties(properties) {
    const states = properties
      .filter(property => property.state)
      .map(property => property.state);

    return states.join(',');
  }

  *getAllTertiaryMarketZipCode() {
    try {
      const response = yield this.loanService.getAllTertiaryMarketZipCode();
      this.tertiaryMarketZipCodes = response.data.data;
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching the tertiary market zipcodes',
      });
    }
  }

  getLoanOriginators() {
    this.loanOriginatorStore.fetchOriginators();
  }
  resetLoanInformation() {
    this.reset();
    this.setDefaults();
    this.form = { ...loanInformationForm };
    this.otherInsuranceFormStore.reset();
    this.otherTitleFormStore.reset();
    const rocPointsIn = this.multiFamilyStore.isMultifamilyBridge ? 1.0 : null;
    this.onChange('rocPointsIn', rocPointsIn);
    this.onChange(
      'submitAppraisalWithTamarisk', 'N'
    );
    this.onChange(
      'loanOriginator',
      this.globalStore.userFeatures.isLenderOriginator
        ? this.multiFamilyStore.userStore.userInformation.userId
        : null
    );
    this.onChange(
      'preferredInsurance',
      this.multiFamilyStore.userStore.allowLoanPricing ? ELMSURE : OTHER
    );
  }

  initForm(data) {
    this.loadForm({
      ...data,
      submitAppraisalWithTamarisk: data.submitAppraisalWithTamarisk ? 'Y' : 'N',
    });
  }

  makeInitialCalculations() {
    this.calculateTotalPurchasePrice();
    this.calculateInitialLoanAmount();
    this.calculateConstructionHoldback();
    this.calculateTotalLoanAmount();
    this.handleLoanTerms();
  }

  changeInitialLoanAmount(key, value) {
    this.onFieldChange(key, value);
    this.calculateInitialLoanToPurchase();
    this.calculateTotalLoanAmount();
    this.updateInterestAccrualMethod();
    this.onChange(key, value);
  }

  changeInitialLoanToPurchase(key, value) {
    this.onFieldChange(key, value);
    this.calculateInitialLoanAmount();
    this.calculateTotalLoanAmount();
    this.updateInterestAccrualMethod();
    this.getLenderAttorneys();
    this.onChange(key, value);
  }

  changeConstructionHoldback(key, value) {
    this.onFieldChange(key, value);
    this.calculateConstHoldbackPercentage();
    this.calculateTotalLoanAmount();
    this.updateInterestAccrualMethod();
    this.onChange(key, value);
  }

  changeConstructionHoldbackPercentage(key, value) {
    this.onFieldChange(key, value);
    this.calculateConstructionHoldback();
    this.calculateTotalLoanAmount();
    this.updateInterestAccrualMethod();
    this.getLenderAttorneys();
    this.onChange(key, value);
  }

  changePointsPercentage(key, value, keyAmount) {
    this.onChange(key, value);
    const amount = this.calculatePointsAmount(value);
    this.onChange(keyAmount, amount);
  }

  changePointsAmount(key, value, keyPercentage) {
    this.onChange(key, value);
    const percentage = this.calculatePointsPercentage(value);
    this.onChange(keyPercentage, percentage);
  }

  onChange = (field, value) => {
    this.onFieldChange(field, value);
    this.handleLoanTerms();
  }

  handleLoanTerms = () => {
    const formValues = this.getFormValues();
    this.multiFamilyStore.setLoanTerms({
      ...formValues,
      rate: this.multiFamilyStore.loanTerms.rate,
      participation: formValues['participation'],
      lenderParticipation: this.mapLenderParticipation(
        formValues['participation']
      ),
      sellAs: this.mapSellAs(formValues['participation']),
      submitAppraisalWithTamarisk:
        formValues['submitAppraisalWithTamarisk'] === 'Y',
      interestAccrualMethod: formValues['interestAccrualMethod'],
      fullPrincipalInterest:
        formValues['interestAccrualMethod'] === 'Full Boat',
      otherInsuranceCompany: {
        ...this.otherInsuranceDetails,
      },
      otherTitleCompany: {
        ...this.otherTitleDetails,
      },
    });
  };

  private calculatePointsAmount(percentage) {
    const loanAmount = this.form.fields.amount.value;
    return loanAmount ? (loanAmount / 100) * percentage : 0;
  }

  private calculatePointsPercentage(amount) {
    const loanAmount = this.form.fields.amount.value;
    return loanAmount ? (amount / loanAmount) * 100 : 0;
  }

  private updateInterestAccrualMethod() {
    const loanAmount = this.form.fields.amount.value;
    this.form.fields.interestAccrualMethod.value = loanAmount > 100000 ? 'As disbursed' : 'Full Boat';
  }

  private calculateInitialLoanAmount() {
    const initialPercentage = this.form.fields.initialLoanToPurchase.value;
    const totalPurchase = this.totalPurchasePrice || 0;
    let initialLoanAmount = 0;
    if (totalPurchase > 0 && initialPercentage > 0) {
      initialLoanAmount = totalPurchase * (initialPercentage / 100);
    }
    this.form.fields.initialLoanAmount.value = initialLoanAmount;
  }

  private calculateInitialLoanToPurchase() {
    const initialLoanAmount = this.form.fields.initialLoanAmount.value;
    const totalPurchase = this.totalPurchasePrice || 0;
    let initialLoanToPurchase = 0;
    if (totalPurchase > 0 && initialLoanAmount > 0) {
      initialLoanToPurchase = (initialLoanAmount * 100) / totalPurchase;
    }
    this.form.fields.initialLoanToPurchase.value = Math.round(initialLoanToPurchase);
  }

  private calculateConstructionHoldback() {
    const totalRenovation = this.getTotalRenovationBudget() || 0;
    let constructionHoldbackPercentage = 0;
    if (
      totalRenovation > 0 &&
      this.form.fields.constructionHoldbackPercentage.value > 0
    ) {
      constructionHoldbackPercentage =
        totalRenovation *
        (this.form.fields.constructionHoldbackPercentage.value / 100);
    }
    this.form.fields.constructionHoldback.value = constructionHoldbackPercentage;
  }

  private calculateConstHoldbackPercentage() {
    const constructionHoldback = this.form.fields.constructionHoldback.value;
    let constructionHoldbackPercentage = 0;
    const totalRenovation = this.getTotalRenovationBudget() || 0;
    if (totalRenovation > 0 && constructionHoldback > 0) {
      constructionHoldbackPercentage =
        (constructionHoldback * 100) / totalRenovation;
    }
    this.form.fields.constructionHoldbackPercentage.value = Math.round(constructionHoldbackPercentage);
  }

  private calculateTotalRenovationBudget() {
    let total = 0;
    let renoBudget = 0;
    const properties = this.multiFamilyStore.properties;
    if (properties.length > 0) {
      properties.forEach(value => {
        renoBudget = isNaN(Number(value.totalRenovationBudget))
          ? 0
          : Number(value.totalRenovationBudget);

        total += renoBudget;
      });
    }
    this.totalRenovationBudget = total;
  }

  private getTotalRenovationBudget() {
    this.calculateTotalRenovationBudget();
    return this.totalRenovationBudget;
  }

  calculateTotalPurchasePrice() {
    const properties = this.multiFamilyStore.properties;
    let total = 0;
    let purchasePrice = 0;
    let renoRemaining = 0;
    if (properties.length > 0) {
      properties.forEach(value => {
        if (value.propertyOwnership === 'Purchase') {
          purchasePrice = isNaN(Number(value.purchasePrice))
            ? 0
            : Number(value.purchasePrice);

          total += purchasePrice;
        } else {
          purchasePrice = isNaN(Number(value.purchasePrice))
            ? 0
            : Number(value.purchasePrice);

          renoRemaining = isNaN(Number(value.sunkCost))
            ? 0
            : Number(value.sunkCost);

          total += purchasePrice + renoRemaining;
        }
      });
    }
    this.totalPurchasePrice = total;
    return total;
  }

  private calculateTotalLoanAmount() {
    this.form.fields.amount.value =
      this.form.fields.initialLoanAmount.value +
      this.form.fields.constructionHoldback.value;
  }

  private mapLenderParticipation(participation) {
    if (participation === 'Through life of loan') {
      return 'Participate through life of loan';
    } else if (participation === 'With subsequent buy-out') {
      return 'Participate with subsequent buy-out';
    } else {
      return 'Broker Loan';
    }
  }

  private mapSellAs(participation) {
    if (participation == 'Through life of loan') {
      return 'A-Note';
    } else {
      return 'Whole Loan';
    }
  }

  getPreferredAttorney() {
    return this.lenderAttorneysOptionList.find(
      attorney =>
        attorney.value === this.form.fields.preferredClosingAttorney.value
    );
  }

  setOtherInsuranceCompany() {
    const values = this.otherInsuranceFormStore.getFormValues();
    this.otherInsuranceDetails = {
      name: values.name,
      contactName: values.contactName,
      phone: values.cellPhone,
      address: values.address,
      email: values.emailAddress,
      state: values.state,
      streetName: values.streetName,
      streetNumber: values.streetNumber,
      zipCode: values.zipcode,
      city: values.city,
    };
    this.onChange(
      'preferredInsurance',
      this.form.fields.preferredInsurance.value
    );
  }

  setOtherTitleCompany() {
    const values = this.otherTitleFormStore.getFormValues();
    this.otherTitleDetails = {
      name: values.name,
      contactName: values.contactName,
      phone: values.cellPhone,
      address: values.address,
      email: values.emailAddress,
      state: values.state,
      streetName: values.streetName,
      streetNumber: values.streetNumber,
      zipCode: values.zipcode,
      city: values.city,
    };
    this.onChange('preferredTitle', this.form.fields.preferredTitle.value);
  }

  showWimba = () => {
    const { allowLoanPricing } = this.multiFamilyStore.userStore;
    return (
      allowLoanPricing &&
      this.multiFamilyStore.properties.some(p =>
        this.wimbaStates.includes(p.state)
      ) &&
      this.multiFamilyStore.loanTerms.preferredTitle === WIMBA
    );
  };

  setDefaultPreferredTitle = () => {
    if (!this.showWimba()) {
      this.onChange('preferredTitle', '');
    } else if (!this.multiFamilyStore.loanTerms.preferredTitle) {
      this.onChange('preferredTitle', '');
    }
  };

  get recourseGuarantyStructureValue() {
    return this.form.fields.amount.value >= 2_000_000 ? "Non Recourse with Bad Boy Carveouts and Environmental Indemnity" : "Full Recourse";
  }

  *uploadInsuranceDocument(fileUpload: FileUpload) {
    try {
      const file = fileUpload.file;
      const blob = file.slice(0, file.size, file.type);
      const newFile = new File([blob], fileUpload.name, { type: file.type });
      const apiResponse: ApiResponse = yield this.loanService.uploadInsuranceDocument(
        newFile
      );
      //const documentId = apiResponse.data.data.id; -- use this id
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'File uploaded successfully',
      });
    } catch (error) {
      this.globalStore.notificationStore.showSuccessNotification({
        //message: 'Error uploading file ',
        message: 'File uploaded successfully',
      });
    }
  }
  *fetchLoanId() {
    try {
      if (!this.draftLoanId) {
        const apiResponse: ApiResponse = yield this.loanService.fetchDraftLoanId();
        this.draftLoanId = apiResponse.data.data;
        yield this.getLoanFastTrackPaymentUrl();
      }
    } catch (error) {
      // do nothing
    }
  }
  *updateLoanFastrackInfo(loanId) {
    try {
      const { borrowersRows, properties } = this.multiFamilyStore;
      const data = {
        loanId: loanId,
        randomLoanId: this.draftLoanId,
        ...this.getFormValues().fastTrackOptions,
        properties: properties.length,
        propertiesAddresses: properties.map(property => property.address).join(', '),
        dealName: getDealName(borrowersRows, properties)
      }
      yield this.loanService.updateLoanFastrackInfo(data);
    }
    catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while saving the modal state',
      });
    }
  }
  *requestFastTrackPaymentEmail(fastTrackInfo: any) {
    try {
      yield this.loanService.requestFastTrackPaymentEmail(fastTrackInfo);
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Email sent successfully',
      });
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while sending the email',
      });
    }
  }
  *loadFastTrackFees() {
    try {
      const response = yield this.loanService.getLoanFastTrackFees();
      this.fastTrackFees = response.data.data;
    } catch (err) {
    }
  }

  *getLoanFastTrackPaymentUrl() {
    try {
      const response = yield this.loanService.getLoanFastTrackPaymentUrl(this.draftLoanId);

      this.fastTrackPaymentUrl = response.data.data.url;
    } catch (err) {
    }
  }

  get canSubmitAppraisalWithTamarisk() {
    return this.multiFamilyStore.canSubmitAppraisalWithTamarisk;
  }

  setLicenseUpload(items: FileUpload[]) {
    this.licenseUpload = items;
  }

  resetBrokerPointsBasedOnHud(onTheHud: boolean) {
    if (onTheHud) {
      this.form.fields.referralFee.value = 0.0;
    } else {
      this.form.fields.brokerPoints.value = 0.0;
    }
  }

}

export default LoanInformationStore;
