import { Grid, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import { TextField, Button } from '@roc/ui';
import { usePayoffRequestStore } from '../hooks/usePayoffRequestStore';
import { useState, useEffect } from 'react';
import { ConfirmDialog } from '@roc/ui';

const useStyles = makeStyles(() => ({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginLeft: 24,
  },
}));

interface payoffRetractFormProps {
  loanId: string;
  onClose: () => void;
}

const PayoffRetractForm = observer(
  ({ loanId, onClose }: payoffRetractFormProps) => {

    useEffect(() => {
      payoffRequestStore.setSaved(false);
    }, []);

    const classes = useStyles();
    const { payoffRequestStore } = usePayoffRequestStore();
    const { form } = payoffRequestStore;
    const [isConfirmOpen, setIsConfirmOpen] = useState(false);
    const retractPayoff = () => {
      payoffRequestStore.retractPayoff(loanId);
      setIsConfirmOpen(false);
      onClose();
      payoffRequestStore.reset();
    };

    const openRetractPayoffConfirmation = () => {
      setIsConfirmOpen(true);
    };
    return (
      <>
        <Grid item xs={12} sm={12}>
          <Grid item xs={12} sm={12}>
            <TextField
              testId="comments"
              variant="outlined"
              standaloneLabel
              label="Comments"
              value={form.fields.comments.value}
              onChange={e =>
                payoffRequestStore.onFieldChange('comments', e.target.value)
              }
              multiline
              minRows={2}
              fullWidth
            />
          </Grid>
          <br></br>
          <Grid item container className={classes.buttonContainer}>
            <Box mr={2}>
              <Button
                className={classes.button}
                size="small"
                variant="contained"
                color="secondary"
                onClick={onClose}
                testId="cancel"
              >
                CANCEL
              </Button>
              <Button
                className={classes.button}
                size="small"
                variant="contained"
                color="primary"
                onClick={() => {
                  openRetractPayoffConfirmation();
                }}
                testId="retract"
              >
                SUBMIT
              </Button>
            </Box>
          </Grid>
        </Grid>
        <ConfirmDialog
          open={isConfirmOpen}
          title={`Retract Payoff`}
          body={`Are you sure you want to retract this Payoff Request?`}
          handleCancel={() => setIsConfirmOpen(false)}
          handleClose={() => setIsConfirmOpen(false)}
          handleConfirm={retractPayoff}
          confirmButtonText={'Yes'}
          cancelButtonText={'No'}
        />
      </>
    );
  }
);

export default PayoffRetractForm;
