import { Page } from '../../../components';
import { Route } from 'react-router';
import { useStore } from '@roc/client-portal-shared/hooks';
import { MultifamilyLiquidityCalculator } from '../multifamilyLiquidityCalculator';

export const getMultifamilyLiquidityCalculatorRoutesConfig = (
  basePath: string,
  baseUrl: string
) => {
  return {
    multifamilyLiquidityCalculator: {
      path: `${basePath}/multifamily-liquidity-calculator`,
      url: `${baseUrl}/multifamily-liquidity-calculator`,
      documentTitle: 'Multifamily Liquidity Calculator',
    },
  };
};

export const getMultifamilyLiquidityCalculatorRoutes = (
  basePath: string,
  baseUrl: string
) => {
  const config = getMultifamilyLiquidityCalculatorRoutesConfig(basePath, baseUrl);
  const { multifamilyLiquidityCalculatorStore } = useStore();

  return {
    liquidityCalculator: (
      <Route exact path={config.multifamilyLiquidityCalculator.path}>
        <Page routeDefinition={config.multifamilyLiquidityCalculator}>
          <MultifamilyLiquidityCalculator store={multifamilyLiquidityCalculatorStore} />
        </Page>
      </Route>
    ),
  };
};
