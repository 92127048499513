import React, { useEffect } from 'react';
import { Grid, Box, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import { SelectScopeOfWorkV2Grid } from './components/selectScopeOfWorkV2Grid/selectScopeOfWorkV2Grid';
import { AddressMismatchDialog } from './components/addressMismatchDialog/addressMismatchDialog';
import ScopeOfWorkV2Options from './components/scopeOfWorkV2Options/scopeOfWorkV2Options';
import {
  ScopeOfWorkV2Option
} from '@roc/feature-sow-shared/stores/v2';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  subtitle: {
    fontSize: 18,
    fontWeight: 'lighter',
  },
  box: {
    borderWidth: 1,
    borderColor: theme.palette.primary.light,
    borderStyle: 'solid',
    padding: theme.spacing(2),
  },
}));

export type ScopeOfWorkCreateStandaloneProps = {
  propertyAddress: any;
  store: any;
  userName?: any;
};

export const ScopeOfWorkCreateStandalone = observer((props: ScopeOfWorkCreateStandaloneProps) => {
  const {propertyAddress, store, userName } = props;

  const {
    scopeOfWorkOption,
    selectedScopeOfWork,
    scopeOfWorkGridStore,
    addressMismatchDialogOpen,
  } = store;

  const classes = useStyles();

  const onOptionChange = (value: ScopeOfWorkV2Option) => {
    store.setScopeOfWorkOption(value);
  };

  const onScopeOfWorkSelected = (scopeOfWork, isSelected: boolean) => {
    if (isSelected) {
      store.selectScopeOfWork(scopeOfWork);
    } else {
      store.deselectScopeOfWork(scopeOfWork);
    }
  };

  const clearSelectedScopeOfWork = () => {
    store.clearSelectedScopesOfWork();
  };

  const onAddressMismatchClose = () => {
    store.setAddressMismatchDialogOpen(false);
  };

  const onAddressMismatchConfirm = () => {
    store.setAddressMismatchDialogOpen(false);
    store.linkScopeOfWork();
  };

  const scopeOfWorkV2LinkOptions = [
      {
        label: 'Create A New Scope Of Work',
        value: ScopeOfWorkV2Option.NEW_SCOPE_OF_WORK,
      },
      {
        label: 'Copy An Existing Scope Of Work',
        value: ScopeOfWorkV2Option.COPY_SCOPE_OF_WORK,
      },
    ];

  useEffect(() => {
    store.reset();
    store.setValues(propertyAddress);
    store.setScopeOfWorkOption(ScopeOfWorkV2Option.NEW_SCOPE_OF_WORK);
    if(userName){
      store.setUserName(userName);
    }
    return () => {
      store.reset();
    };
  }, [propertyAddress, store]);

  return (
    <Grid container spacing={2}>
       <Grid item xs={12}>
        <Typography className={classes.subtitle}>
          Please select one of the following options to continue with the Scope of Work.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Box className={classes.box}>
          <ScopeOfWorkV2Options
            option={scopeOfWorkOption}
            optionsToSelect={scopeOfWorkV2LinkOptions}
            onOptionChange={onOptionChange}
          />
        </Box>
      </Grid>
      {scopeOfWorkOption === ScopeOfWorkV2Option.COPY_SCOPE_OF_WORK && (
        <Grid item xs={12}>
          <Box className={classes.box}>
            <SelectScopeOfWorkV2Grid
              onScopeOfWorkSelected={onScopeOfWorkSelected}
              onPageChange={clearSelectedScopeOfWork}
              store={scopeOfWorkGridStore}
            />
          </Box>
        </Grid>
      )}
      <AddressMismatchDialog
        open={addressMismatchDialogOpen}
        propertyAddress={propertyAddress}
        scopeOfWorkAddress={selectedScopeOfWork?.address}
        onClose={onAddressMismatchClose}
        onLink={onAddressMismatchConfirm}
      />
    </Grid>
  );
});

export default ScopeOfWorkCreateStandalone;
