import { Box, Divider, Grid, List, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { Button, Paper } from '@roc/ui';
import { observer } from 'mobx-react';

import { useStore } from '../../../hooks';

import CustomListItem from './customListItem';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      borderRadius: theme.spacing(2),
      padding: theme.spacing(3, 0),
      boxShadow:
        'rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px',
    },
    textBold: {
      fontWeight: 700,
    },
    textItalic: {
      fontStyle: 'italic',
    },
  })
);

interface Props {
  handleBackStep: () => void;
  handleNextStep: () => void;
  subTitle: string;
}

const GoodFaithDeposit = observer((props: Props) => {
  const { loanTermsStore } = useStore();
  const { paper, textBold, textItalic } = useStyles();

  return (
    <>
      <Box>
        <Box mb={2}>
          <Typography variant="h4">Good Faith Deposit Details</Typography>
        </Box>
        <Typography variant="h6">{props.subTitle}</Typography>
      </Box>

      <Box mt={3}>
        <Typography className={textBold} variant="subtitle1">
          Estimated Good Faith Deposit: $1,000
        </Typography>
        <Box mt={6}>
          <Paper className={paper}>
            <Box mb={1} px={2}>
              <Typography className={textBold} variant="subtitle1">
                Good Faith Deposit estimation is based on:
              </Typography>
            </Box>
            <List>
              <CustomListItem
                label="Appraisals*"
                value="$250 - $600 / Property (Loans above 1.5 m requires a secong appraisal)"
              />
              <Divider variant="middle" component="li" />
              <CustomListItem label="BPO*" value="$100 - $250 / Property" />
              <Divider variant="middle" component="li" />
              <CustomListItem
                label={'Background & Credit Checks'}
                value="$32.56 / Per Person"
              />
              <Divider variant="middle" component="li" />
              <CustomListItem
                label="Flood Certificates"
                value="$25 / Property"
              />
              <Divider variant="middle" component="li" />
              <CustomListItem label="Servicing Set Up" value="$30" />
              <Divider variant="middle" component="li" />
              <CustomListItem
                label="ARR/CDA Review For 1-4 Unit Properties"
                value="$165 - $175 / Property (ARR/CDA may both apply if second appraisal is required)"
              />
            </List>
            <Box mt={2} px={3}>
              <Typography className={textItalic} variant="body1">
                *Appraisal BPO and ARR/CDA costs can vary based on a number of
                factors including geographic region, the service provider and
                the type of report ordered. Costs are controlled by independent
                third parties, subject to change without notice and are not
                within the Lender's control.
              </Typography>
            </Box>
          </Paper>
        </Box>
        <Box mt={8}>
          <Paper className={paper}>
            <Box mb={1} px={2}>
              <Typography className={textBold} variant="subtitle1">
                Additional costs may apply at closing:
              </Typography>
            </Box>
            <List>
              <CustomListItem
                label="Legal Costs"
                value={[
                  '$955 For One Loan / One Property',
                  '$1,495 For One Loan / 2 Properties',
                  '$1,995 For One Loan / 3 - 5 Properties',
                  '$2,495 For One Loan / 6 - 10 Properties',
                  '$2,995 For One Loan / 11 - 15 Properties\nAbove 15 Properties On a Deal By Deal Basis',
                ]}
              />
            </List>
            <Box mt={2} px={3}>
              <Typography className={textItalic} variant="body1">
                All costs listed above are estimated and may be subject to
                change depending on the circumstances of the loan. Note that the
                above estimates do not include title fees, borrower's legal fees
                or any other third party costs incurred during the process of
                closing the loan.
              </Typography>
            </Box>
          </Paper>
        </Box>
      </Box>

      <Box mt={16}>
        <Grid container justifyContent="center" spacing={2}>
          <Grid item xs={6} md={3}>
            <Button
              variant="outlined"
              color="primary"
              fullWidth
              onClick={props.handleBackStep}
              testId="back"
            >
              Back
            </Button>
          </Grid>
          <Grid item xs={6} md={3}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={props.handleNextStep}
              testId="next"
            >
              {loanTermsStore.paymentStatus ? 'Next' : 'Make Payment'}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
});

export default GoodFaithDeposit;
