import { Paper } from '@material-ui/core';
import { BorrowerInformationAccordion } from './borrowerInformationAccordion';
import { makeStyles } from '@material-ui/core/styles';
import { BorrowerInformationForm } from './borrowerInformationForm';
import { observer } from 'mobx-react';
import { BorrowerFormStore } from '../../../stores/borrowers/borrowerFormStore';
import { useState } from 'react';

const useStyles = makeStyles(theme => ({
  paper: {
    border: `1px solid lightgray`,
    borderRadius: '16px !important',
    padding: theme.spacing(4),
  },
}));

interface BorrowerInformationCardProps {
  borrowerFormStore: BorrowerFormStore;
  onDeleteClick: (borrowerKey: string) => void;
  showActions?: boolean;
  removeButtonDisabled?: boolean;
  displayPGField: boolean; //Field that determines if we should display the PG Field in the borrower form
}

export const BorrowerInformationCard = observer(
  ({
    borrowerFormStore,
    onDeleteClick,
    showActions = true,
    removeButtonDisabled = false,
    displayPGField,
  }: BorrowerInformationCardProps) => {
    const classes = useStyles();

    const editMode = borrowerFormStore.form.meta.editMode;
    const [expanded, setExpanded] = useState(false);

    return (
      <Paper className={classes.paper} elevation={0}>
        {editMode && (
          <BorrowerInformationForm
            store={borrowerFormStore}
            onDeleteClick={onDeleteClick}
            showActions={showActions}
            removeButtonDisabled={removeButtonDisabled}
            displayPGField={displayPGField}
          />
        )}
        {!editMode && (
          <BorrowerInformationAccordion
            expanded={expanded}
            onExpand={expanded => setExpanded(expanded)}
            store={borrowerFormStore}
            onDeleteClick={onDeleteClick}
            showActions={showActions}
            removeButtonDisabled={removeButtonDisabled}
          />
        )}
      </Paper>
    );
  }
);
