import GetAppIcon from '@material-ui/icons/GetApp';
import PrintIcon from '@material-ui/icons/Print';
import { Button, PrintableAreaRef, StandardDialog } from '@roc/ui';
import { observer } from 'mobx-react';
import { useEffect, useRef } from 'react';
import { TermStore } from '../../../loanSubmission/stores/common/termStore';
import { DownloadableQuoteHtml } from './downloadableQuoteHtml';


interface Props {
  open?: boolean;
  onClose?: () => void;
  store: TermStore;
  rateBuydownOptions: any[];
}

export const DownloadableQuotesDialog = observer((props: Props) => {
  const { open, onClose, store, rateBuydownOptions } = props;
  const downloadableLoanRatesRef = useRef<PrintableAreaRef>(null);

  useEffect(() => {
    if (open) {
      store.pricerSummaryStore.fetchLoanRatesPdfData();
    }
  }, [open]);

  const fields = store.pricerStore.getFormValues();
  const loanRatesData = store.pricerSummaryStore.loanRatesPdfData;

  const download = () => {
    let documentName;
    if(fields.address) {
      const [addressNumber, ...streetNameArray] = fields.address.split(" ");
      const streetName = streetNameArray.join(" ").split(",")[0];
      documentName = `${addressNumber} ${streetName} Quote.pdf`
    } else {
      documentName = `${loanRatesData.numberOfProperties} Property Portfolio in ${fields.propertyStates}`;
    }
    downloadableLoanRatesRef.current.downloadAsPdf(documentName);
  };

  const print = async () => {
    const url = await downloadableLoanRatesRef.current.getPdfBlobUrl();
    const printWindow = window.open(url.toString(), '_blank');
    printWindow?.print();
  };

  const dialogActions = (
    <>
      <Button
        style={{ marginRight: '8px' }}
        testId="print-pdf"
        color="default"
        variant="contained"
        onClick={print}
        startIcon={<PrintIcon />}
      >
        Print
      </Button>
      <Button
        testId="download-pdf"
        color="primary"
        variant="contained"
        startIcon={<GetAppIcon />}
        onClick={download}
      >
        Download
      </Button>
    </>
  );

  return (
    <StandardDialog
      open={open}
      title="Loan Rates"
      maxWidth="md"
      handleClose={onClose}
      dialogActions={dialogActions}
      dialogContent={
        loanRatesData ? (
          <DownloadableQuoteHtml
            ref={downloadableLoanRatesRef}
            data={loanRatesData}
            store={store}
            pricingOptions={store.pricerSummaryStore.pricingOptions}
            rateBuydownOptions={rateBuydownOptions}
          />
        ) : null
      }
    />
  );
})