import { observer } from 'mobx-react';
import React, { ReactNode, useEffect, useState } from 'react';
import { AgGridColumnProps, DataGrid } from '@roc/ui';
import { useRoutes } from '../../hooks/useRoutes';
import { useStore } from '@roc/client-portal-shared/hooks';
import { Button, Grid } from '@material-ui/core';
import { Layout } from '@roc/feature-app-core';
import { makeStyles } from '@material-ui/core/styles';
import { CompanyProfileCRMWrapper } from '../companyProfile/companyProfileCRMWrapper';

const noOpComparator = () => 0;

const columns: AgGridColumnProps[] = [
  {
    field: 'companyId',
    headerName: 'Company Id',
    minWidth: 180,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    hide: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'lenderName',
    headerName: 'Lender Alias',
    minWidth: 260,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
    cellRenderer: 'lenderNameCellRenderer',
  },
  {
    field: 'lenderId',
    headerName: 'Lender Id',
    minWidth: 180,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    hide: false,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'tradeName',
    headerName: 'Lender Name',
    minWidth: 180,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    hide: false,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'primaryRelationshipManagerEmail',
    headerName: 'Primary RM Email',
    minWidth: 260,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'secondaryRelationshipManagerEmail',
    headerName: 'Secondary RM Email',
    minWidth: 260,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'status',
    headerName: 'Status',
    minWidth: 260,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
];

export const LendersGrid = observer(({ toolbar }: { toolbar: ReactNode }) => {
  const { salesforceLenderStore, companyProfileStore, companyDropdownStore } = useStore();

  useEffect(() => {
  }, [companyProfileStore.displayCompanyProfile]);

  const onSortChanged = params => {
    const sortModel = params.api.getSortModel();
    salesforceLenderStore.gridStore.setSortModel(sortModel);
  };

  const onFilterChanged = params => {
    const filterModel = params.api.getFilterModel();
    salesforceLenderStore.gridStore.setFilterModel(filterModel);
  };

  const lenderNameCellRenderer = params => {
    const lenderName = params.value;
    return (
      <Button onClick={() => handleOnclick(params)}>
        {lenderName}
      </Button>
    );
  }

  const handleOnclick = async (params) => {
    await companyDropdownStore.changeCompany(params.data?.companyId);
    companyProfileStore.setDisplayCompanyProfile(true);
  }

  const frameworkComponents = {
    lenderNameCellRenderer: lenderNameCellRenderer,
  };

  return companyProfileStore.displayCompanyProfile ? (
    <CompanyProfileCRMWrapper />
  ) : (
    <Layout>
      <DataGrid
        suppressRowClickSelection={false}
        frameworkComponents={frameworkComponents}
        toolbar={toolbar}
        columns={columns}
        rows={salesforceLenderStore.gridStore.gridData.data.rows}
        onSortChanged={onSortChanged}
        onFilterChanged={onFilterChanged}
        isLoading={salesforceLenderStore.gridStore.isLoading}
        showFilters={salesforceLenderStore.gridStore.showFilters}
        sortModel={salesforceLenderStore.gridStore.sortModel}
        filterModel={salesforceLenderStore.gridStore.filterModel}
        paginationData={salesforceLenderStore.gridStore.gridData.meta}
        setPageNumber={salesforceLenderStore.gridStore.setPageNumber}
        rowHeight={60}
      />
    </Layout>
  );
});
