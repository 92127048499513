import { Grid } from '@material-ui/core';
import { observer } from 'mobx-react';
import { useNewLoanApplicationStore } from '../hooks/useNewLoanApplicationStore';
import { LoanApplicationStep } from '../utils/loanApplicationConstants';
import { BorrowerInformationModal } from './borrowerInformationModal';
import { BorrowersCard } from './borrowersCard';
import { EntityCard } from './entityCard';
import { EntityInformationModal } from './entityInformationModal';
import { LoanStep } from './loanApplicationComponents';
import { PropertiesCard } from './propertiesCard';

export const LoanApplicationSummaryStep = observer(() => {
  const { newLoanApplicationStore } = useNewLoanApplicationStore();

  return (
    <LoanStep
      percentageComplete={75}
      title={'Summary'}
      subtitle={'Please review you loan details before submitting.'}
      onBack={() =>
        newLoanApplicationStore.goToStep(
          LoanApplicationStep.PROPERTY_INFORMATION
        )
      }
      continueButtonText={'Submit Loan Submission'}
      onContinue={() => newLoanApplicationStore.submitLoanApplication()}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <EntityCard editable={false} />
        </Grid>
        <Grid item xs={12}>
          <BorrowersCard editable={false} />
        </Grid>
        <Grid item xs={12}>
          <PropertiesCard editable={false} />
        </Grid>
      </Grid>
      <EntityInformationModal />
      <BorrowerInformationModal />
    </LoanStep>
  );
});
