import { Grid } from '@material-ui/core';
import { Document } from '@roc/feature-types';
import { Button, StandardDialog } from '@roc/ui';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { useDocumentStore } from '../../../hooks/useDocumentStore';
import TitleAgent from './titleAgent';

export type TitleAgentDialogProps = {
  open?: boolean;
  onClose?: () => void;
  loanId: any;
  document: Document;
};

export const TitleAgentDialog = observer((props: TitleAgentDialogProps) => {
  const { open, onClose, loanId, document } = props;
  const { documentStore } = useDocumentStore();
  const { documentFormStore } = documentStore;
  const { titleAgentStore } = documentFormStore;

  const onSubmit = () => {
    titleAgentStore.submit(document, loanId);
  };

  useEffect(() => {
    if (open) {
      titleAgentStore.initForm(loanId);
    }
  }, [open]);

  return (
    <StandardDialog
      open={open}
      title="Title Agent"
      maxWidth="lg"
      handleClose={onClose}
      dialogContent={<TitleAgent store={titleAgentStore} />}
      dialogActions={
        <Grid container spacing={1} justifyContent="flex-end">
          <Grid item>
            <Button
              onClick={onClose}
              color="primary"
              variant="outlined"
              testId="cancel"
            >
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={onSubmit}
              disabled={!titleAgentStore.canSubmit}
              color="primary"
              variant="contained"
              testId="confirm"
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      }
    />
  );
});

export default TitleAgentDialog;
