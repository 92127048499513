import React from 'react';

/* eslint-disable-next-line */
export interface FeatureDrawDqDataProps {}

export function FeatureDrawDqData(props: FeatureDrawDqDataProps) {
  return (
    <div>
      <h1>Welcome to feature-draw-dq-data!</h1>
    </div>
  );
}

export default FeatureDrawDqData;
