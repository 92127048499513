import { useAppConfigurations } from '@roc/feature-app-core';
import { RouteDefinition } from '@roc/feature-types';
import { isElectronApp } from '@roc/feature-utils';
import { useEffect } from 'react';

interface PageProps {
  routeDefinition: RouteDefinition;
  children?: React.ReactNode;
}

export const Page = ({ routeDefinition, children }: PageProps) => {
  const { documentTitle } = routeDefinition;

  return (
    <PageWrapper title={documentTitle ?? 'Portal'}>
      {children}
    </PageWrapper>
  );
};

interface PageWrapperProps {
  title: string;
  children?: React.ReactNode;
}

export const PageWrapper = ({ title, children }: PageWrapperProps) => {
  const { siteName } = useAppConfigurations();

  useEffect(() => {
    let _title = `${title}`;
    if (isElectronApp()) {
      const productName = (window as any).CHAT_UI_GLOBAL_DATA?.productName ?? 'LoanChat';
      _title += ` | ${productName}`;
    }
    else if (siteName) {
      _title += ` | ${siteName}`;
    }
    document.title = _title;
  }, [title]);

  return <>{children}</>;
};
