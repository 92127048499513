import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useStore } from '@roc/client-portal-shared/hooks';
import BorrowerPreScreen from 'libs/feature-borrowers/src/borrowers/borrowerPreScreen/borrowerPreScreen';
import { BorrowersStore } from '@roc/feature-borrowers';

interface IBorrowerDetailsWrapper {
  borrowerId: number | string;
  borrowersStore: BorrowersStore;
}

export const BorrowerDetailsWrapper = observer(({ borrowerId, borrowersStore }: IBorrowerDetailsWrapper) => {
  const { globalStore, userStore } = useStore();
  const companyIdValue = userStore.userInformation.companyId ?? globalStore?.selectedCompanyId;
  const [borrower, setBorrower] = useState(null);

  useEffect(() => {
    initialize();
    return () => {
      borrowersStore.resetBorrowerData();
    };
  }, []);

  const initialize = async () => {
    await borrowersStore.getBorrowerData(borrowerId);
    setBorrower(borrowersStore.currentBorrower);
  };

  return (
    <>
      {borrower &&
        <BorrowerPreScreen
          borrower={borrower}
          borrowersStore={borrowersStore}
          companyId={companyIdValue}
        />
      }
    </>
  );
});
