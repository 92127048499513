import { StandardDialog } from '@roc/ui';
import { observer } from 'mobx-react';
import { BorrowerSetupToDo } from './borrowerSetupToDo';

export type BorrowerSetupToDoDialogProps = {
  open: boolean;
  borrowerId: number;
  loan?: any;
  handleClose: () => void;
  refreshLoan?: () => void;
};

export const BorrowerSetupToDoDialog = observer((props: BorrowerSetupToDoDialogProps) => {
  const { open, borrowerId, loan, handleClose, refreshLoan } = props;

  return (
    <StandardDialog
      maxWidth={'lg'}
      open={open}
      title='Borrower Setup'
      handleClose={handleClose}
      dialogContent={
        <BorrowerSetupToDo
          borrowerId={borrowerId}
          loan={loan}
          handleClose={handleClose}
          refreshLoan={refreshLoan}
        />
      }
      removeContentBox
    />
  );
});

export default BorrowerSetupToDoDialog;