import { ApiResponse, FormStore, GlobalStore, GridStore } from "@roc/feature-app-core";
import { computed, flow, action, makeObservable, observable } from 'mobx';
import { DialogState } from '@roc/ui';
import { ManageUsersService } from "../services/manageUsersService";
import { ChatRoomType } from "@roc/feature-communication";
import { LoanService } from "@roc/feature-loans";
import { LoanParticipantsService } from "libs/feature-loan-participants/src/loan-participants/services/loanParticipantsService";
import { LenderUserDocumentsStore } from "./lenderUserDocumentsStore";

const usersForm = {
  fields: {
    userId: {
      value: 0,
      error: null,
      rule: '',
    },
    contactId: {
      value: 0,
      error: null,
      rule: '',
    },
    firstName: {
      value: '',
      error: null,
      rule: 'required',
    },
    lastName: {
      value: '',
      error: null,
      rule: 'required',
    },
    emailAddress: {
      value: '',
      error: null,
      rule: 'required|email',
    },
    cellPhone: {
      value: '',
      error: null,
      rule: 'required|regex:/^\\d{3}-?\\d{3}-?\\d{4}$/|alpha_dash',
      message: 'Phone must be a number with the format xxx-xxx-xxxx',
    },
    role: {
      value: '',
      error: null,
      rule: 'required',
    },
    roleInEntityCurrent: {
      value: '',
      error: null,
      rule: '',
    },
    ownershipPercentage: {
      value: 0,
      error: null,
      rule: '',
    },
    allowLoanPricing: {
      value: '',
      error: null,
      rule: '',
    },
    originatorIds: {
      value: [],
      error: null,
      rule: '',
    },
    processorIds: {
      value: [],
      error: null,
      rule: '',
    }
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export class ManageUsersStore extends FormStore {
  public globalStore: GlobalStore;
  manageUsersService: ManageUsersService;
  loanService: LoanService;
  loanParticipantsService: LoanParticipantsService;
  public lenderUserDocumentStore: LenderUserDocumentsStore;
  public gridStore: GridStore;
  dialogState: DialogState;

  originators: any[] = [];
  processors: any[] = [];
  public OWNER_PRINCIPAL = 'Owner/Principal';

  constructor(globalStore: GlobalStore) {
    super({ ...usersForm }, globalStore);
    this.globalStore = globalStore;
    this.manageUsersService = new ManageUsersService();
    this.loanService = new LoanService();
    this.loanParticipantsService = new LoanParticipantsService();
    this.lenderUserDocumentStore = new LenderUserDocumentsStore(globalStore);
    this.gridStore = new GridStore(() => this.getAllUsers());

    makeObservable(this, {
      dialogState: observable,
      originators: observable,
      processors: observable,
      showDialog: computed,
      resetDialogState: action,
      addNewUser: action,
      editUser: action,
      editUserFromGrid: action,
      isOwner: action,
      createUser: flow,
      updateUser: flow,
      changeRole: flow,
      deactivateUser: flow,
      requestBackgroundForUser: flow,
      resetPassword: flow,
      updateAllowedChatRoomTypes: flow,
      fetchProcessorsAndOriginators: flow,
      fetchLenderOriginatorRelationships: flow,
      originatorOptions: computed,
      processorOptions: computed,
      updateLenderUser: flow,
      updateLenderOwnershipPercentageandRoleinEntity: flow,
      reactivateUser: flow,
      copySetupEmailLink: flow,
    });
  }



  protected async getAllUsers() {
    try {
      const response: ApiResponse = await this.manageUsersService.getAllCompanyUsers(
        this.gridStore.gridData.meta.pageNumber,
        this.gridStore.gridData.meta.pageSize,
        this.gridStore.gridData.meta.sortBy,
        this.gridStore.gridData.meta.sortDir,
        this.gridStore.gridData.meta.filters,
        this.gridStore.gridData.meta.dropdownFilters,
        this.globalStore.selectedCompanyId
      );
      return response;
    }
    catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching users'
      });
    }
  }

  private async getTotalCompanyOwnerShip() {
    const totalOwnership = await this.manageUsersService.getTotalCompanyOwnership(this.globalStore.selectedCompanyId);
    return totalOwnership;
  }

  resetDialogState() {
    this.dialogState = undefined;
  }

  addNewUser() {
    this.reset();
    this.dialogState = DialogState.ADD;
  }

  isOwner() {
    return this.form.fields.roleInEntityCurrent.value === this.OWNER_PRINCIPAL
  }

  editUser(record) {
    this.reset();
    this.dialogState = DialogState.EDIT;
    this.loadForm(record);
  }

  editUserFromGrid(record) {
    this.reset();
    this.loadForm(record);
  }

  get showDialog() {
    return this.dialogState != null;
  }

  *createUser() {
    this.runFormValidationWithMessage();
    if (this.form.meta.isValid) {
      try {
        const { firstName, lastName, emailAddress, cellPhone, role, roleInEntityCurrent, allowLoanPricing, originatorIds, processorIds, ownershipPercentage } = this.getFormValues();
        const totalOwnership = yield this.getTotalCompanyOwnerShip();
        if (totalOwnership.data.percentage + ((ownershipPercentage ?? 0) * 1) > 100) {
          this.globalStore.notificationStore.showErrorNotification({
            message: "The total ownership percentage cannot exceed 100%. Please enter a value that is equal to or less than " + (100 - totalOwnership.data.percentage)
          });
          return;
        }
        const companyId = this.globalStore.selectedCompanyId;
        const response = yield this.manageUsersService.createLenderUser({
          firstName, lastName, emailAddress, cellPhone, role, roleInEntityCurrent, allowLoanPricing, processorIds, originatorIds, companyId, percentageOwnership: ownershipPercentage
        });
        this.handleUserCreationResponse(response);
        this.resetDialogState();
        this.gridStore.resetAndFetchGridData();
      }
      catch (error) {
        console.log(error)
        this.globalStore.notificationStore.showErrorNotification({
          message: "Error while creating new user"
        });
      }
    }
  }

  handleUserCreationResponse(response) {
    if (response.data.userCreated) {
      this.globalStore.notificationStore.showSuccessNotification({
        message: "User created successfully!"
      });

      this.handleOwnerPrincipal(response.data.owner_principal);

    } else {
      this.globalStore.notificationStore.showErrorNotification({
        message: "User was not created successfully"
      });
    }
  }

  handleOwnerPrincipal(ownerPrincipal) {
    if (ownerPrincipal !== undefined) {
      if (ownerPrincipal) {
        this.globalStore.notificationStore.showSuccessNotification({
          message: "Background check requested successfully for this user"
        });
      } else {
        this.globalStore.notificationStore.showErrorNotification({
          message: "Background check was not requested successfully for this user"
        });
      }
    }
  }


  *updateUser() {
    this.runFormValidationWithMessage();
    if (this.form.meta.isValid) {
      try {
        const { contactId, firstName, lastName, emailAddress } = this.getFormValues();
        yield this.manageUsersService.updateLenderContact({
          contactId, firstName, lastName, emailAddress
        });
        this.globalStore.notificationStore.showSuccessNotification({
          message: "User details updated successfully!"
        });
        this.resetDialogState();
        this.gridStore.resetAndFetchGridData();
      }
      catch (error) {
        this.globalStore.notificationStore.showErrorNotification({
          message: "Error while updating the user"
        });
      }
    }
  }

  *changeRole(userId: number, currentRole: string) {
    const { role, allowLoanPricing, originatorIds, processorIds } = this.getFormValues();
    try {
      yield this.manageUsersService.changeRole({
        userId,
        currentRole,
        newRole: role,
        allowLoanPricing,
        processorIds,
        originatorIds
      });
      this.gridStore.refresh();
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'User role changed successfully.',
      });
    }
    catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while changing user role.',
      });
    }
  }

  *deactivateUser(userId) {
    try {
      yield this.manageUsersService.deactivateUser({ userId });
      this.gridStore.refresh();
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'User deactivated successfully.',
      });
    }
    catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while deactivating the user.',
      });
    }
  }

  *requestBackgroundForUser(userId) {
    try {
      yield this.manageUsersService.requestBackgroundForUser(userId);
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Background successfully requested.',
      });
    }
    catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while requesting the user background.',
      });
    }
  }

  *resetPassword(username: string) {
    try {
      const response: ApiResponse = yield this.manageUsersService.forgotPasswordSendEmail(username, this.globalStore.portalConfiguration.id);
      if (response.data?.data) {
        this.globalStore.notificationStore.showSuccessNotification({
          message: 'Reset password email sent successfully.',
        });
      } else {
        this.globalStore.notificationStore.showErrorNotification({
          message: 'Error while sending reset password email.',
        });
      }
    }
    catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error in reset password.',
      });
    }
  }

  *updateAllowedChatRoomTypes(userId: number, allowedChatRoomTypes: ChatRoomType[]) {
    try {
      yield this.manageUsersService.updateAllowedChatRoomTypes(userId, allowedChatRoomTypes)
      this.gridStore.fetchGridData();
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Success!'
      })
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while updating the allowed chat room types'
      })
    }
  }

  *fetchProcessorsAndOriginators() {
    this.originators = [];
    this.processors = [];
    try {
      const response = yield this.loanParticipantsService.getLoanParticipants();
      const { loanOriginators, loanProcessors } = response.data.data;
      this.originators = loanOriginators;
      this.processors = loanProcessors;
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching the possible originators',
      });
    }
  }

  *fetchLenderOriginatorRelationships(userId) {
    try {
      this.form.fields.processorIds.value = [];
      this.form.fields.originatorIds.value = [];

      yield this.fetchProcessorsAndOriginators();

      const response = yield this.manageUsersService.getProcessorOriginatorRelationships(userId);
      const { processorIds, originatorIds } = response.data.data;
      this.onFieldChange('processorIds', processorIds)
      this.onFieldChange('originatorIds', originatorIds)
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching lender/originator relationships',
      });
    }
  }

  *updateLenderUser(userId: number, currentRole: string, roleInEntityCurrent: string, user: any, newRole: string) {
    try {
      const { originatorIds, processorIds, contactId, firstName, lastName, emailAddress, cellPhone, allowLoanPricing } = this.getFormValues();
      const ownershipPercentageValue = (this.form.fields.ownershipPercentage.value ?? 0) * 1;
      const totalOwnership = yield this.getTotalCompanyOwnerShip();

      if ((totalOwnership.data.percentage - user.ownershipPercentage) + ownershipPercentageValue > 100) {
        this.globalStore.notificationStore.showErrorNotification({
          message: "The total ownership percentage cannot exceed 100%. Please enter a value that is equal to or less than " + (100 - (totalOwnership.data.percentage - user.ownershipPercentage))
        });
        return;
      }

      yield this.updateLenderOwnershipPercentageandRoleinEntity(userId, roleInEntityCurrent, ownershipPercentageValue);

      user.ownershipPercentage = ownershipPercentageValue;

      yield this.manageUsersService.updateLenderContact({
        contactId, firstName, lastName, emailAddress, cellPhone
      });

      yield this.manageUsersService.changeRole({ userId, currentRole, newRole, originatorIds, processorIds, allowLoanPricing });

      this.gridStore.refresh();
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Done',
      });
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error',
      });
    }
  }

  *updateLenderOwnershipPercentageandRoleinEntity(userId, roleInEntityCurrent, ownership) {
    const roleInEntity = this.form.fields.roleInEntityCurrent.value;

    yield this.manageUsersService.updateLenderOwnershipAndRoleInEntity(
      {
        userId,
        ownership,
        roleInEntityCurrent,
        roleInEntity
      }, this.globalStore.selectedCompanyId
    );
  }

  get originatorOptions() {
    const options = this.originators.map(originator => ({
      label: `${originator.contact?.firstName ?? ''} ${originator.contact?.lastName ?? ''
        }`,
      value: originator.userId,
    }));
    return [...options];
  }

  get processorOptions() {
    const options = this.processors.map(processor => ({
      label: `${processor.contact?.firstName ?? ''} ${processor.contact?.lastName ?? ''
        }`,
      value: processor.userId,
    }));
    return [...options];
  }

  *reactivateUser(userId) {
    try {
      yield this.manageUsersService.reactivateUser({ userId });
      this.gridStore.refresh();
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'User reactivated successfully.',
      });
    }
    catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while reactivating the user.',
      });
    }
  }

  *copySetupEmailLink(userId) {
    try {
      const resetPasswordLink = yield this.manageUsersService.copySetupEmailLink(
        { userId }
      );

      navigator.clipboard.writeText(resetPasswordLink.data.data);
      window.parent.postMessage('copy-to-clipboard;' + resetPasswordLink.data.data, '*');

      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Setup email link has been copied to clipboard.',
      });
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while generating the Setup User Link.',
      });
    }
  }
}