import { observer } from 'mobx-react';
import { LoanType } from '@roc/feature-utils';
import { useEffect } from 'react';
import { LoanKeyInformationBridge } from './loanKeyInformationBridge';
import { LoanKeyInformationTerm } from './loanKeyInformationTerm';

export const LoanKeyInformation = observer(({ loanStore }) => {
  const { loanDetails, closingRequirements } = loanStore;
  const { loanType } = loanDetails;

  useEffect(() => {
    loanStore.fetchClosingRequirements(loanDetails.loanId);
  }, [loanStore, loanDetails.loanId]);

  switch (loanType) {
    case LoanType.RESIDENTIAL_BRIDGE:
      return (
        <LoanKeyInformationBridge
          loanDetails={loanDetails}
          closingRequirements={closingRequirements}
        />
      );
    case LoanType.RESIDENTIAL_TERM:
      return (
        <LoanKeyInformationTerm
          loanDetails={loanDetails}
          closingRequirements={closingRequirements}
        />
      );
  }
});
