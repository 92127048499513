import { observer } from 'mobx-react';
import { PropertiesGridView } from '../gridViewScreen/propertiesGridView';
import { SearchPropertyScreen } from '../searchProperty';
import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import { isNil } from '@roc/feature-utils';
import { VerticalBarLoader } from '@roc/ui';
import { useStore } from '@roc/client-portal-shared/hooks';


export const CollateralCompScreen = observer(
  () => {
    const { compToolStore } = useStore();
    const { searchPropertyFormStore } = compToolStore;
  
    const { address } = useParams<{ address: string }>();
    const location = useLocation();
  
    const params = new URLSearchParams(location.search);
    const loanId = parseInt(params.get('loanId'));
    const propertyId = parseInt(params.get('propertyId'));
  
    const { loading } = compToolStore;
    const [isLoanDetailsFetched, setIsLoanDetailsFetched] = useState(false);

    useEffect(() => {
      const fetchLoanDetails = async () => {
        searchPropertyFormStore.saveAddressField(address);
        searchPropertyFormStore.onFieldChange('completeAddress', address);
        compToolStore.setShowWarningMessage(false);
  
        if (!isNil(address) && address !== "") {
          compToolStore.reset();
  
          if (loanId && propertyId) {
            compToolStore.setLoanInformation(loanId, propertyId);
          }

          compToolStore.getPropertyById(propertyId, () => {
            setIsLoanDetailsFetched(true);
          });

          if (compToolStore.property) {
            searchPropertyFormStore.saveAddressField(address, null, null, compToolStore.property.latitude, compToolStore.property.longitude);
          }

          if (isLoanDetailsFetched) {
            compToolStore.fetchCompToolProperties(1, true);
          }
        } else {
          compToolStore.compResponse = null;
          compToolStore.setShowWarningMessage(true);
        }
      };
  
      fetchLoanDetails();
    }, [loanId, propertyId, address, searchPropertyFormStore, compToolStore, isLoanDetailsFetched]);

    return (
      <>
        {
          loading ? (
            <>
              <VerticalBarLoader />
            </>
          ) :
            (
              <>
                {(compToolStore.showWarningMessage == true || isNil(compToolStore.compResponse)) &&
                  <SearchPropertyScreen />
                }
                {compToolStore.compResponse &&
                  <PropertiesGridView />
                }
              </>
            )
        }
      </>
    );
  }
);
