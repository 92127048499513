import { Box, Grid } from '@material-ui/core';
import { useLoanStore } from '@roc/feature-loans';
import { observer } from 'mobx-react';
import { useLoanFlags } from '../../hooks/useLoanFlags';
import { BrokerCard } from '../cards/brokerCard';
import { CommissionsCard } from '../cards/commissionsCard';
import { EntityCard } from '../cards/entityCard';
import { EstimatedClosingCosts } from '../cards/estimatedClosingCosts';
import { InsuranceCard } from '../cards/insuranceCard';
import { KeyDatesCardBridge } from '../cards/keyDatesCard';
import { LoanContactsCard } from '../cards/loanContactsCard';
import { LoanSummaryTodosCard } from '../cards/loanSummaryTodosCard';
import { OriginatorsCard } from '../cards/originatorsCard';
import { PropertiesCard } from '../cards/propertiesCard';
import { TermsCard } from '../cards/termsCard';
import { LoanSummaryActions } from '../common/loanSummaryActions';
import { LoanDetailsPaper } from '../loanDetailsPaper';
import { LoanStatusStepper } from '../loanDetailsStepper';
import { LoanDetailsCardFixFlip } from './loanDetailsCardFixFlip';
import { LoanTermsCardMultifamilyBridge } from './loanTermsCardMultifamilyBridge';
import { PricingModelCardMultifamily } from './multifamily/pricingModelCardMultifamily';
import { SourcesCardMultifamily } from './multifamily/sourcesCardMultifamily';
import { UsesCardMultifamily } from './multifamily/usesCardMultifamily';
import { useBaseStore } from '@roc/feature-app-core';

export const LoanSummaryMultifamilyBridge = observer(({}) => {
  const { showOriginatorsCard, showCommissionsCard, allowLoanPricing } = useLoanFlags();
  const { globalStore } = useBaseStore();
  const isEstimatedClosingCostEnabled = globalStore.userFeatures.isEstimatedClosingCostEnabled;

  return (
    <>
      <Grid item xs={12}>
        <LoanContactsCard />
      </Grid>
      <Grid item xs={12}>
        <LoanDetailsPaper>
          <LoanStatusStepper />
        </LoanDetailsPaper>
      </Grid>
      <Grid item xs={12}>
        <LoanDetailsCardFixFlip />
      </Grid>
      <Grid item xs={12}>
        <LoanSummaryTodosCard />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Box height={'100%'} display={'flex'} flexDirection={'column'}>
              <Grid container spacing={2}>
                {allowLoanPricing &&
                  <Grid item xs={12}>
                    <TermsCard />
                  </Grid>
                }
                <Grid item xs={12}>
                  <InsuranceCard />
                </Grid>
              </Grid>
              <Box pt={2} flexGrow={1}>
                <EntityCard />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box height={'100%'} display={'flex'} flexDirection={'column'}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <KeyDatesCardBridge />
                </Grid>
                {showOriginatorsCard &&
                  <Grid item xs={12}>
                    <OriginatorsCard />
                  </Grid>
                }
                <Grid item xs={12}>
                  <BrokerCard />
                </Grid>
              </Grid>
              <Box pt={2} flexGrow={1}>
                <PropertiesCard />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <LoanTermsCardMultifamilyBridge />
      </Grid>
      <Grid item xs={6}>
        <SourcesCardMultifamily />
      </Grid>
      <Grid item xs={6}>
        <UsesCardMultifamily />
      </Grid>
      <Grid item xs={12}>
        <PricingModelCardMultifamily />
      </Grid>
      {showCommissionsCard && (
        <Grid item xs={12}>
          <CommissionsCard />
        </Grid>
      )}
      {isEstimatedClosingCostEnabled && (
        <Grid item xs={12}>
          <EstimatedClosingCosts />
        </Grid>
      )}
    </>
  );
});
