import { Grid } from '@material-ui/core';
import { useStore } from '@roc/client-portal-shared/hooks';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { createPercentageField } from '../../../../components/formComponents/common/componentBuilder';
import {
  LabeledText,
  Section,
} from '../../components/fixFlipSizerSummaryComponents';

const SummaryProfitAndDscrTab = observer(props => {
  const { fixFlipSizerStore } = useStore();
  const sizer = fixFlipSizerStore.detailsStore.sizer;

  const store = fixFlipSizerStore.detailsStore.summaryTaxesAndFeesForm;

  useEffect(() => {
    fixFlipSizerStore.detailsStore.calculateSizer();
  }, [
    store.form.fields.mortgageTaxPercent.value,
    store.form.fields.transferTaxPercent.value,
    store.form.fields.mansionTaxPercent.value,
    store.form.fields.titleInsurancePercent.value,
    store.form.fields.brokerFeePercent.value,
    store.form.fields.propertyTaxAndMaintenancePercent.value,
  ]);

  const outputData = sizer.outputData;
  const data = sizer;

  return (
    <Grid container spacing={1}>
      <Section label="Inputs, Taxes & Fees" />
      <LabeledText
        label="Purchase Price + Rehab Spent"
        value={outputData.purchasePrice}
        currency
        testId="purchasePrice"
      />
      <LabeledText
        label="Value"
        value={outputData.value}
        currency
        testId="value"
      />
      <LabeledText
        label="Loan Amount"
        value={outputData.totalLoan}
        currency
        testId="totalLoan"
      />
      <LabeledText
        label="ARLTV"
        value={outputData.arltv * 100}
        percentage
        testId="arltv"
      />
      <LabeledText
        label="Renovation Budget to Complete"
        value={data.rehabToCompleteProject}
        currency
        testId="rehabToCompleteProject"
      />
      <LabeledText
        label="Time to flip (Months)"
        value={Math.round(outputData.timeToFlip)}
        number
        testId="timeToFlip"
      />
      <LabeledText
        label="Interest"
        value={data.interestRate}
        percentage
        testId="interestRate"
      />
      <LabeledText
        label="Points"
        value={outputData.points * 100}
        percentage
        testId="points"
      />
      {props.isReadOnly && (
        <>
          <LabeledText
            label="Mortgage Tax"
            value={outputData.mortgageTaxPercent}
            percentage
            testId="mortgageTaxPercent"
          />
          <LabeledText
            label="Transfer Tax"
            value={outputData.transferTaxPercent}
            percentage
            testId="transferTaxPercent"
          />
          <LabeledText
            label="Mansion Tax"
            value={outputData.mansionTaxPercent}
            percentage
            testId="mansionTaxPercent"
          />
          <LabeledText
            label="Title Insurance"
            value={outputData.titleInsurancePercent}
            percentage
            testId="titleInsurancePercent"
          />
          <LabeledText
            label="Broker Fee"
            value={outputData.brokerFeePercent}
            percentage
            testId="brokerFeePercent"
          />
          <LabeledText
            label="Property Tax and Maintenance"
            value={outputData.propertyTaxAndMaintenancePercent}
            percentage
            testId="propertyTaxAndMaintenancePercent"
          />
        </>
      )}
      {!props.isReadOnly && (
        <>
          <Grid item xs={12}>
            {createPercentageField({
              store,
              testId: 'mortgageTaxPercent',
              fieldName: 'mortgageTaxPercent',
              label: 'Mortgage Tax',
            })}
          </Grid>
          <Grid item xs={12}>
            {createPercentageField({
              store,
              testId: 'transferTaxPercent',
              fieldName: 'transferTaxPercent',
              label: 'Transfer Tax',
            })}
          </Grid>
          <Grid item xs={12}>
            {createPercentageField({
              store,
              testId: 'mansionTaxPercent',
              fieldName: 'mansionTaxPercent',
              label: 'Mansion Tax',
            })}
          </Grid>
          <Grid item xs={12}>
            {createPercentageField({
              store,
              testId: 'titleInsurancePercent',
              fieldName: 'titleInsurancePercent',
              label: 'Title Insurance',
            })}
          </Grid>
          <Grid item xs={12}>
            {createPercentageField({
              store,
              testId: 'brokerFeePercent',
              fieldName: 'brokerFeePercent',
              label: 'Broker Fee',
            })}
          </Grid>
          <Grid item xs={12}>
            {createPercentageField({
              store,
              testId: 'propertyTaxAndMaintenancePercent',
              fieldName: 'propertyTaxAndMaintenancePercent',
              label: 'Property Tax and Maintenance',
            })}
          </Grid>
        </>
      )}
      <Section label="Acquisition Cost" />
      <LabeledText
        label="Purchase Price"
        value={outputData.purchasePrice}
        currency
        testId="acquisitionPurchasePrice"
      />
      <LabeledText
        label="Legal Fees"
        value={outputData.legalFees}
        currency
        testId="legal-fees"
      />
      <LabeledText
        label="Mortgage Recording Tax"
        value={outputData.mortageRecordingTax}
        currency
        testId="mortageRecordingTax"
      />
      <LabeledText
        label="Filling Fees"
        value={outputData.fillingFees}
        currency
        testId="fillingFees"
      />
      <LabeledText
        label="Appraisal"
        value={outputData.appraisal}
        currency
        testId="appraisal"
      />
      <LabeledText
        label="PL Legal Fee"
        value={outputData.plLegalFee}
        currency
        testId="plLegalFee"
      />
      <LabeledText
        label="Title Insurance"
        value={outputData.titleInsuranceAmount}
        currency
        testId="titleInsuranceAmount"
      />
      <LabeledText
        label="Origination Points"
        value={outputData.originationPoints}
        currency
        testId="originationPoints"
      />
      <LabeledText
        label="Recording Fee"
        value={outputData.recordingFee}
        currency
        testId="recordingFee"
      />
      <LabeledText
        label="Total"
        value={outputData.totalAcquisitionCosts}
        currency
        testId="totalAcquisitionCosts"
      />
      <Section label="Interim Expenses" />
      <LabeledText
        label="Renovation"
        value={data.rehabToCompleteProject}
        currency
        testId="rehabToCompleteProject"
      />
      <LabeledText
        label="Interest"
        value={outputData.interest}
        currency
        testId="interest"
      />
      <LabeledText
        label="Property Tax & Maintenance"
        value={outputData.propertyTaxAndMaintenanceAmount}
        currency
        testId="propertyTaxAndMaintenanceAmount"
      />
      <LabeledText
        label="Total"
        value={outputData.totalInterimExpenses}
        currency
        testId="totalInterimExpenses"
      />
      <Section label="Sale Cost (Paid from Proceeds of Sale)" />
      <LabeledText
        label="Broker Fee"
        value={outputData.brokerFeeAmount}
        currency
        testId="brokerFeeAmount"
      />
      <LabeledText
        label="Transfer Tax"
        value={outputData.transferTaxAmount}
        currency
        testId="transferTaxAmount"
      />
      <LabeledText
        label="Mansion Tax"
        value={outputData.mansionTaxAmount}
        currency
        testId="mansionTaxAmount"
      />
      <LabeledText
        label="Legal"
        value={outputData.legalSaleCosts}
        currency
        testId="legalSaleCosts"
      />
      <LabeledText
        label="Filling Fees"
        value={outputData.fillingFees}
        currency
        testId="fillingFeesSaleCost"
      />
      <LabeledText
        label="Total"
        value={outputData.totalSaleCosts}
        currency
        testId="totalSaleCosts"
      />
      <Section label="Final Costs" />
      <LabeledText
        label="Sale Price"
        value={outputData.value}
        currency
        testId="valueFinalCost"
      />
      <LabeledText
        label="Costs"
        value={outputData.totalCosts}
        currency
        testId="totalCosts"
      />
      <LabeledText
        label="Borrower Profit/Loss"
        value={outputData.borrowerProfitLoss}
        currency
        testId="borrowerProfitLoss"
      />
      <Section label="Snapshot" />
      <LabeledText
        label="Sales Price"
        value={outputData.value}
        currency
        testId="valueSnapshot"
      />
      <LabeledText
        label="Purchase Price"
        value={outputData.purchasePrice}
        currency
        testId="purchasePriceSnapshot"
      />
      <LabeledText
        label="Renovation"
        value={data.rehabToCompleteProject}
        currency
        testId="rehabToCompleteProjectSnapshot"
      />
      <LabeledText
        label="Interest & Origination Points"
        value={outputData.interestAndOriginationPoints}
        currency
        testId="interestAndOriginationPoints"
      />
      <LabeledText
        label="Property Tax And Maintenance"
        value={outputData.propertyTaxAndMaintenanceAmount}
        currency
        testId="propertyTaxAndMaintenanceAmount"
      />
      <LabeledText
        label="Taxes & Other Costs"
        value={outputData.taxesAndOtherCosts}
        currency
        testId="taxesAndOtherCosts"
      />
      <LabeledText
        label="Total"
        value={outputData.totalSnapshot}
        currency
        testId="totalSnapshot"
      />
      <Section label="Flip Economics" />
      <LabeledText
        label="Total Profit Of Flip"
        value={outputData.totalProfitOnFlip}
        currency
        testId="totalProfitOnFlip"
      />
      <LabeledText
        label="Borrower Cash In Deal (At Purchase)"
        value={outputData.borrowerCashAtPurchase}
        currency
        testId="borrowerCashAtPurchase"
      />
      <LabeledText
        label="Borrower Cash In Deal (53 Months)"
        value={outputData.borrowerCashMonths}
        currency
        testId="borrowerCashMonths"
      />
      <LabeledText
        label="Borrower ROI On Cash In Deal (53 Months)"
        value={outputData.borrowerRoi * 100}
        percentage
        testId="borrowerRoi"
      />
    </Grid>
  );
});

export default SummaryProfitAndDscrTab;
