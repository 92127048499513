import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Toolbar } from '@material-ui/core';
import { Button, FileUpload, FileUploadModal } from '@roc/ui';
import { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import { useDocumentStore } from '@roc/feature-documents';
import { Alert } from '@material-ui/lab';
import { UNDERWRITING } from '@roc/feature-utils';
import {
  formatDate,
} from '@roc/feature-utils';

const useStyles = makeStyles({
  root: {
    height: '100%',
    '& .gauge-container': {
      '& text': {
        fontWeight: 'bold'
      }
    }
  },
});

export type InternalValuationReportProps = {
  loanId: number,
  propertyId: number
  readOnly: boolean,
}

export const InternalValuationReportCard = observer(({ loanId, propertyId, readOnly }: InternalValuationReportProps) => {
  const classes = useStyles();
  const { documentStore } = useDocumentStore();
  const [isUploadOpen, setUploadOpen] = useState(false);
  const [document, setDocument] = useState<any>();

  const fetchPropertyLoanDocument = useCallback(async () => {
    const responseDoc = await documentStore.getPropertyLoanDocument(loanId, "Internal Valuation Report", propertyId);
    setDocument(responseDoc);
  }, [loanId, propertyId, documentStore]);


  useEffect(() => {
    fetchPropertyLoanDocument();
  }, [fetchPropertyLoanDocument]);

  if (!document) {
    return null;
  }


  const canDownload = !!document.dropboxPath;

  const message = !canDownload
    ? `${document.documentName} has not been uploaded`
    : `${document.documentName} was last uploaded at ${formatDate(document.lastUpdateDate)}`;

  const severity = canDownload ? "info" : "warning";

  return (
    <Card className={clsx(classes.root)} elevation={0} variant='outlined'>
      <Toolbar style={{
        minHeight: 52,
        padding: '8px 16px',
      }}>
        <Typography variant="h6" component="div">
          Internal Valuation Report
        </Typography>
      </Toolbar>
      <CardContent style={{
        padding: 16
      }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Alert severity={severity}>{message}</Alert>
          </Grid>
          <Grid item xs={12} style={{ paddingLeft: "2rem", paddingBottom: "1rem" }}>
            {!readOnly && (<Button
              style={{ marginRight: '8px' }}
              onClick={() => setUploadOpen(true)}
              color="primary"
              variant="outlined"
              testId="upload"
            >
              Upload
            </Button>)}
            <Button
              variant="outlined"
              color="primary"
              disabled={!canDownload}
              onClick={() => documentStore.downloadDocument(document)}
              testId="download"
            >
              Download
            </Button>
          </Grid>
          <FileUploadModal
            title={`Upload: ${document.documentName}`}
            open={isUploadOpen}
            onClose={() => setUploadOpen(false)}
            onUpload={(uploads: FileUpload[]) =>
              documentStore.uploadDocument(uploads[0].file, document, UNDERWRITING, fetchPropertyLoanDocument)
            }
          />
        </Grid>
      </CardContent>
    </Card>);
});