import { isElectronApp, isElectronAppLocalHost, isMobileApp, isMobileAppLocalHost } from '@roc/feature-utils';
import axios, { AxiosRequestConfig, ResponseType, CancelToken } from 'axios';
import { _getBaseStoreInstance } from '../stores/_baseStore';
import { AuthenticationStore, GlobalStore, UserStore } from '../stores';

const REFRESH_TOKEN_URL = '/api/v1/oauth2/token';

interface ServiceMetada {
  responseType?: string;
  disableGlobalLoading?: boolean;
  cancelToken?: CancelToken;
}

const _axios = axios.create({
  baseURL: '',
  withCredentials: true,
});

const _isRefreshTokenApi = (config: AxiosRequestConfig) => {
  if (config.url.includes(REFRESH_TOKEN_URL)) {
    const requestData = (config.data && typeof config.data === "string") ? JSON.parse(config.data) : config.data;
    return (requestData?.grant_type == 'password'
      || requestData?.grant_type == 'password_mobile'
      || requestData?.grant_type == 'password_chat'
      || requestData?.grant_type == 'refresh_token'
      || requestData?.grant_type == 'msal')
  }
  return false;
};

const _isPublicApi = (config: AxiosRequestConfig) => {
  return config.url.includes('/public/');
};

_axios.interceptors.request.use(
  async config => {
    config.baseURL = getRestApiBaseURL();
    if (!_isRefreshTokenApi(config) && !_isPublicApi(config)) {
      const jwtToken = await _getJwtToken(false);
      const extraHeaders = await _getExtraHeaders();
      if (jwtToken) {
        config.headers = config.headers ?? {};
        config.headers = {
          ...config.headers,
          ...extraHeaders,
          Authorization: `Bearer ${jwtToken}`,
        };
      }
    }

    return config;
  },
  error => Promise.reject(error)
);

_axios.interceptors.response.use(
  response => response,
  async error => {
    const config = error?.config;
    const storeInstance = _getBaseStoreInstance();
    if (error?.response?.status === 401) {
      if (!_isRefreshTokenApi(config) && !config?.sent) {
        // refresh the token and try again once
        config.sent = true;
        const jwtToken = await _getJwtToken(true);
        if (jwtToken) {
          config.headers = config.headers ?? {};
          config.headers = {
            ...config.headers,
            Authorization: `Bearer ${jwtToken}`,
          };
          // trigger the request again
          return _axios(config);
        }
      }
      else {
        let pathName = location.pathname;
        pathName = pathName != '/' ? pathName : undefined;
        if (storeInstance && storeInstance.mobileLogoutStore) {
          storeInstance.mobileLogoutStore.mobileLogout(pathName, true);
        }
        else if (storeInstance && storeInstance.rocChatLogoutStore) {
          storeInstance.rocChatLogoutStore.logout(pathName);
        }
        else if (storeInstance && storeInstance.logoutStore) {
          storeInstance.logoutStore.logout(pathName);
        }
      }
    }
    else if (error?.response?.status === 403
      && error?.response?.data?.error?.errorCode === 'TWO_FACTOR') {
      storeInstance?.authenticationStore?.setTwoFactorRequired(true);
    }
    else if (error?.response?.status === 503) {
      const isMaintenanceMode = error?.response?.headers && (
        error?.response?.headers['x-maintenance-mode'] == 'true' || error?.response?.headers['x-maintenance-mode'] == true);
      if (isMaintenanceMode) {
        window.location.reload();
      }
    } else if (error?.response?.status === 400 && error?.response?.data?.error?.errorCode === 'SELECTED_COMPANY_ID_DOES_NOT_MATCH') {
      storeInstance?.companyDropdownStore.setShowWrongCompanyBanner?.(true);
    }
    return Promise.reject(error);
  }
);

export class ApiResponse {
  data: any;
  headers: any;
  constructor(data, headers) {
    this.data = data;
    this.headers = headers;
  }
}

export class ApiError {
  error: any;
  isCancel: boolean;
  constructor(error) {
    this.error = error;
    this.isCancel = axios.isCancel(error);
  }
}

const _getJwtToken = async (forceRefresh = false): Promise<string> => {
  const storeInstance = _getBaseStoreInstance();
  if (storeInstance?.authenticationStore) {
    const authenticationStore: AuthenticationStore = storeInstance.authenticationStore;
    const jwtToken = await authenticationStore.getJwtToken(forceRefresh)
    return jwtToken ?? null;
  }
  return null;
};

const _getExtraHeaders = async (): Promise<Record<string, any>> => {
  const storeInstance = _getBaseStoreInstance();
  const globalStore: GlobalStore = storeInstance?.globalStore;
  const userStore: UserStore = storeInstance?.userStore;

  const extraHeaders = {};
  if (userStore?.userInfo?.backOfficeUser && globalStore.selectedCompanyId) {
    extraHeaders['x-selected-company-id'] = globalStore.selectedCompanyId;
  }
  return extraHeaders;
};

const getRestApiBaseURL = () => {
  const storeInstance = _getBaseStoreInstance();
  if (storeInstance) {
    if (isMobileApp() && !isMobileAppLocalHost()) {
      return (window as any).MOBILE_UI_GLOBAL_DATA.restApiBaseURL;
    }
    if (isElectronApp() && !isElectronAppLocalHost()) {
      return (window as any).CHAT_UI_GLOBAL_DATA.restApiBaseURL;
    }
    const { restApiBaseURL } = storeInstance.environmentStore.environmentVariables;
    return restApiBaseURL ?? '';
  }
  return '';
};

const resetSessionActivity = (metadata?: ServiceMetada) => {
  const storeInstance = _getBaseStoreInstance();
  if (storeInstance) {
    const { globalStore } = storeInstance;
    globalStore.resetSessionActivity();
  }
};

const showLoading = (metadata?: ServiceMetada) => {
  const storeInstance = _getBaseStoreInstance();
  if (storeInstance && !metadata?.disableGlobalLoading) {
    const { globalStore } = storeInstance;
    globalStore.incrementLoadingRequestsCount();
  }
};

const hideLoading = (metadata?: ServiceMetada) => {
  const storeInstance = _getBaseStoreInstance();
  if (storeInstance && !metadata?.disableGlobalLoading) {
    const { globalStore } = storeInstance;
    globalStore.decrementLoadingRequestsCount();
  }
};

export class Service {

  protected getHost() {
    return getRestApiBaseURL() ?? window.location.host;
  }

  protected async get(
    url: string,
    queryParams?: {},
    metadata?: ServiceMetada
  ): Promise<ApiResponse> {
    return new Promise((resolve, reject) => {
      (async () => {
        if (url.indexOf(REFRESH_TOKEN_URL) == -1) {
          resetSessionActivity();
        }
        try {
          showLoading(metadata);
          const response = await _axios.get(url, {
            params: queryParams || {},
            responseType: metadata?.responseType
              ? <ResponseType>metadata.responseType
              : 'json',
            cancelToken: metadata?.cancelToken,
          });
          hideLoading(metadata);
          resolve(new ApiResponse(response.data, response.headers));
        } catch (e) {
          hideLoading(metadata);
          console.error(e);
          reject(new ApiError(e));
        }
      })();
    });
  }

  protected post(
    url: string,
    data: any,
    headers?: object,
    metadata?: ServiceMetada
  ): Promise<ApiResponse> {
    return new Promise((resolve, reject) => {
      (async () => {
        if (url.indexOf(REFRESH_TOKEN_URL) == -1) {
          resetSessionActivity();
        }
        try {
          showLoading(metadata);
          const response = await _axios.post(url, data, {
            responseType: metadata?.responseType
              ? <ResponseType>metadata.responseType
              : 'json',
            headers: {
              ...headers,
            },
            cancelToken: metadata?.cancelToken,
          });
          hideLoading(metadata);
          resolve(new ApiResponse(response.data, response.headers));
        } catch (e) {
          hideLoading(metadata);
          console.error(e);
          reject(new ApiError(e));
        }
      })();
    });
  }

  protected postWithTimeOut(
    url: string,
    data: any,
    timeout: number,
    headers?: object,
    metadata?: ServiceMetada
  ): Promise<ApiResponse> {
    return new Promise((resolve, reject) => {
      (async () => {
        if (url.indexOf(REFRESH_TOKEN_URL) == -1) {
          resetSessionActivity();
        }
        try {
          showLoading(metadata);
          const response = await _axios.post(url, data, {
            responseType: metadata?.responseType
              ? <ResponseType>metadata.responseType
              : 'json',
            headers: {
              ...headers,
            },
            timeout: timeout,
          });
          hideLoading(metadata);
          resolve(new ApiResponse(response.data, response.headers));
        } catch (e) {
          hideLoading(metadata);
          console.error(e);
          reject(new ApiError(e));
        }
      })();
    });
  }

  protected patch(
    url: string,
    data: any,
    headers?: object,
    metadata?: ServiceMetada
  ): Promise<ApiResponse> {
    return new Promise((resolve, reject) => {
      (async () => {
        if (url.indexOf(REFRESH_TOKEN_URL) == -1) {
          resetSessionActivity();
        }
        try {
          showLoading(metadata);
          const response = await _axios.patch(url, data, {
            responseType: metadata?.responseType
              ? <ResponseType>metadata.responseType
              : 'json',
            headers: {
              ...headers,
            },
            cancelToken: metadata?.cancelToken,
          });
          hideLoading(metadata);
          resolve(new ApiResponse(response.data, response.headers));
        } catch (e) {
          hideLoading(metadata);
          console.error(e);
          reject(new ApiError(e));
        }
      })();
    });
  }

  protected put(
    url: string,
    data: any,
    responseType?: string,
    headers?: object,
    metadata?: ServiceMetada
  ): Promise<ApiResponse> {
    return new Promise((resolve, reject) => {
      (async () => {
        if (url.indexOf(REFRESH_TOKEN_URL) == -1) {
          resetSessionActivity();
        }
        try {
          showLoading(metadata);
          const response = await _axios.put(url, data, {
            responseType: responseType ? <ResponseType>responseType : 'json',
            headers: {
              ...headers,
            },
            cancelToken: metadata?.cancelToken,
          });
          hideLoading(metadata);
          resolve(new ApiResponse(response.data, response.headers));
        } catch (e) {
          hideLoading(metadata);
          console.error(e);
          reject(new ApiError(e));
        }
      })();
    });
  }

  protected async delete(
    url: string,
    queryParams?: {},
    metadata?: ServiceMetada
  ): Promise<ApiResponse> {
    return new Promise((resolve, reject) => {
      (async () => {
        if (url.indexOf(REFRESH_TOKEN_URL) == -1) {
          resetSessionActivity();
        }
        try {
          showLoading(metadata);
          const response = await _axios.delete(url, {
            params: queryParams || {},
            responseType: metadata?.responseType
              ? <ResponseType>metadata.responseType
              : 'json',
            cancelToken: metadata?.cancelToken,
          });
          hideLoading(metadata);
          resolve(new ApiResponse(response.data, response.headers));
        } catch (e) {
          hideLoading(metadata);
          console.error(e);
          reject(new ApiError(e));
        }
      })();
    });
  }
}
