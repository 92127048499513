import { capitalize } from '@roc/feature-utils';

const LOANS_HOME_BASE_PATH = 'loans';
const LOAN_BASE_PATH = ':loanId';
const DOCUMENTS_BASE_PATH = 'documents';
const DRAWS_BASE_PATH = ':drawId';
const EXTENSIONS_BASE_PATH = ':extensionId';
const BACK_OFFICE_BASE_PATH = 'backoffice';
const FORECLOSURE_BASE_PATH = 'foreclosure';
const LOANS_APPLICATION_HOME_BASE_PATH = 'loan-applications';
const LOAN_APPLICATION_BASE_PATH = ':loanId';

export const getLoanRoutesConfig = (basePath: string, baseUrl: string) => {
  return {
    loansHome: {
      path: `${basePath}/${LOANS_HOME_BASE_PATH}`,
      url: `${baseUrl}/${LOANS_HOME_BASE_PATH}`,
      documentTitle: 'Loans Home',
    },
    loansHomeForeclosure: {
      path: `${basePath}/${FORECLOSURE_BASE_PATH}/${LOANS_HOME_BASE_PATH}`,
      url: `${baseUrl}/${FORECLOSURE_BASE_PATH}/${LOANS_HOME_BASE_PATH}`,
      documentTitle: 'Loans Home',
    },
    loansHomeBackoffice: {
      path: `${basePath}/${BACK_OFFICE_BASE_PATH}/${LOANS_HOME_BASE_PATH}`,
      url: `${baseUrl}/${BACK_OFFICE_BASE_PATH}/${LOANS_HOME_BASE_PATH}`,
      documentTitle: 'Loans Home',
    },
    draws: {
      draws: {
        path: `${basePath}/${LOANS_HOME_BASE_PATH}/${LOAN_BASE_PATH}/draws`,
        url: `${baseUrl}/${LOANS_HOME_BASE_PATH}/draws`,
        documentTitle: `View Draws`,
      },
      requestDraw: {
        path: `${basePath}/${LOANS_HOME_BASE_PATH}/${LOAN_BASE_PATH}/request-draw`,
        url: `${baseUrl}/${LOANS_HOME_BASE_PATH}/request-draw`,
        documentTitle: `Request Draws`,
      },
    },
    loans: (loanId?: string) => ({
      path: `${basePath}/${LOANS_HOME_BASE_PATH}/${LOAN_BASE_PATH}`,
      url: `${baseUrl}/${LOANS_HOME_BASE_PATH}/${loanId}`,
      children: {
        dashboard: {
          path: `${basePath}/${LOANS_HOME_BASE_PATH}/${LOAN_BASE_PATH}/dashboard`,
          url: `${baseUrl}/${LOANS_HOME_BASE_PATH}/${loanId}/dashboard`,
          documentTitle: `${loanId} - Loan Dashboard`,
        },
        loanSummary: {
          path: `${basePath}/${LOANS_HOME_BASE_PATH}/${LOAN_BASE_PATH}/loan-summary`,
          url: `${baseUrl}/${LOANS_HOME_BASE_PATH}/${loanId}/loan-summary`,
          documentTitle: `${loanId} - Loan Summary`,
        },
        pendingDocuments: {
          path: `${basePath}/${LOANS_HOME_BASE_PATH}/${LOAN_BASE_PATH}/all-${DOCUMENTS_BASE_PATH}`,
          url: `${baseUrl}/${LOANS_HOME_BASE_PATH}/${loanId}/all-${DOCUMENTS_BASE_PATH}`,
          documentTitle: `All Documents - ${loanId}`,
        },
        borrowerDocuments: {
          path: `${basePath}/${LOANS_HOME_BASE_PATH}/${LOAN_BASE_PATH}/documents`,
          url: `${baseUrl}/${LOANS_HOME_BASE_PATH}/${loanId}/documents`,
          documentTitle: `Documents - ${loanId}`,
        },
        borrowerTodos: {
          path: `${basePath}/${LOANS_HOME_BASE_PATH}/${LOAN_BASE_PATH}/todos`,
          url: `${baseUrl}/${LOANS_HOME_BASE_PATH}/${loanId}/todos`,
          documentTitle: `Todos - ${loanId}`,
        },
        allMessages: {
          path: `${basePath}/${LOANS_HOME_BASE_PATH}/${LOAN_BASE_PATH}/all-messages`,
          url: `${baseUrl}/${LOANS_HOME_BASE_PATH}/${loanId}/all-messages`,
          documentTitle: `All Messages - ${loanId}`,
        },
        commissions: {
          path: `${basePath}/${LOANS_HOME_BASE_PATH}/${LOAN_BASE_PATH}/commissions`,
          url: `${baseUrl}/${LOANS_HOME_BASE_PATH}/${loanId}/commissions`,
          documentTitle: `Commissions - ${loanId}`,
        },
        decisionTab: {
          path: `${basePath}/${LOANS_HOME_BASE_PATH}/${LOAN_BASE_PATH}/decision`,
          url: `${baseUrl}/${LOANS_HOME_BASE_PATH}/${loanId}/decision`,
          documentTitle: `Decision - ${loanId}`,
        },
        documents: (loanId: string, section: string, sectionName: string) => ({
          path: `${basePath}/${LOANS_HOME_BASE_PATH}/${LOAN_BASE_PATH}/${section}-${DOCUMENTS_BASE_PATH}/${sectionName}`,
          url: `${baseUrl}/${LOANS_HOME_BASE_PATH}/${loanId}/${section}-${DOCUMENTS_BASE_PATH}/${sectionName}`,
          documentTitle: `Documents - ${capitalize(section)}`,
        }),
        properties: {
          path: `${basePath}/${LOANS_HOME_BASE_PATH}/${LOAN_BASE_PATH}/properties`,
          url: `${baseUrl}/${LOANS_HOME_BASE_PATH}/${loanId}/properties`,
          documentTitle: `Loan Properties - ${loanId}`,
        },
        borrowerEntity: {
          path: `${basePath}/${LOANS_HOME_BASE_PATH}/${LOAN_BASE_PATH}/borrower-entity`,
          url: `${baseUrl}/${LOANS_HOME_BASE_PATH}/${loanId}/borrower-entity`,
          documentTitle: `Loan Borrower Entity - ${loanId}`,
        },
        extensions: {
          path: `${basePath}/${LOANS_HOME_BASE_PATH}/${LOAN_BASE_PATH}/extension`,
          url: `${baseUrl}/${LOANS_HOME_BASE_PATH}/${loanId}/extension`,
          documentTitle: `Loan Extension Request - ${loanId}`,
        },
        extensionDetails: (extensionId?: string) => ({
          path: `${basePath}/${LOANS_HOME_BASE_PATH}/${LOAN_BASE_PATH}/extension/${EXTENSIONS_BASE_PATH}`,
          url: `${baseUrl}/${LOANS_HOME_BASE_PATH}/${loanId}/extension/${extensionId}`,
          documentTitle: `Extension Details - ${extensionId}`,
        }),
        payoffRequest: {
          path: `${basePath}/${LOANS_HOME_BASE_PATH}/${LOAN_BASE_PATH}/payoff`,
          url: `${baseUrl}/${LOANS_HOME_BASE_PATH}/${loanId}/payoff`,
          documentTitle: `Loan Payoff Request - ${loanId}`,
        },
        foreclosureRequest: {
          path: `${basePath}/${LOANS_HOME_BASE_PATH}/${LOAN_BASE_PATH}/foreclosure`,
          url: `${baseUrl}/${LOANS_HOME_BASE_PATH}/${loanId}/foreclosure`,
          documentTitle: `Loan Foreclosure Request - ${loanId}`,
        },
        foreclosureInvoice: {
          path: `${basePath}/${LOANS_HOME_BASE_PATH}/${LOAN_BASE_PATH}/foreclosure-invoice`,
          url: `${baseUrl}/${LOANS_HOME_BASE_PATH}/${loanId}/foreclosure-invoice`,
          documentTitle: `Loan Foreclosure invoice - ${loanId}`,
        },
        draws: {
          path: `${basePath}/${LOANS_HOME_BASE_PATH}/${LOAN_BASE_PATH}/draws`,
          url: `${baseUrl}/${LOANS_HOME_BASE_PATH}/${loanId}/draws`,
          documentTitle: `Loan Draws - ${loanId}`,
        },
        drawDetails: (drawId?: string) => ({
          path: `${basePath}/${LOANS_HOME_BASE_PATH}/${LOAN_BASE_PATH}/draws/${DRAWS_BASE_PATH}`,
          url: `${baseUrl}/${LOANS_HOME_BASE_PATH}/${loanId}/draws/${drawId}`,
          documentTitle: `Draw Details - ${drawId}`,
        }),
        drawRequest: {
          path: `${basePath}/${LOANS_HOME_BASE_PATH}/${LOAN_BASE_PATH}/draw/request`,
          url: `${baseUrl}/${LOANS_HOME_BASE_PATH}/${loanId}/draw/request`,
          documentTitle: `Loan Draw Request - ${loanId}`,
        },
        reviseScopeOfWork: {
          path: `${basePath}/${LOANS_HOME_BASE_PATH}/${LOAN_BASE_PATH}/draw/revise-scope-of-work`,
          url: `${baseUrl}/${LOANS_HOME_BASE_PATH}/${loanId}/draw/revise-scope-of-work`,
          documentTitle: `Draw - Revise Scope Of Work - ${loanId}`,
        },
        pointOfContact: {
          path: `${basePath}/${LOANS_HOME_BASE_PATH}/${LOAN_BASE_PATH}/draw/point-of-contact`,
          url: `${baseUrl}/${LOANS_HOME_BASE_PATH}/${loanId}/draw/point-of-contact`,
          documentTitle: `Draw - Point of Contact - ${loanId}`,
        },
        revisionRequest: {
          path: `${basePath}/${LOANS_HOME_BASE_PATH}/${LOAN_BASE_PATH}/draw/revision`,
          url: `${baseUrl}/${LOANS_HOME_BASE_PATH}/${loanId}/draw/revision`,
          documentTitle: `SOW Revision Request - ${loanId}`,
        },
        propertyDocuments: {
          path: `${basePath}/${LOANS_HOME_BASE_PATH}/${LOAN_BASE_PATH}/property-documents`,
          url: `${baseUrl}/${LOANS_HOME_BASE_PATH}/${loanId}/property-documents`,
          documentTitle: `Property documents`,
        },
        loanContacts: {
          path: `${basePath}/${LOANS_HOME_BASE_PATH}/${LOAN_BASE_PATH}/loan-contacts`,
          url: `${baseUrl}/${LOANS_HOME_BASE_PATH}/${loanId}/loan-contacts`,
          documentTitle: `Contacts`,
        },
        estimatedClosingCosts: {
          path: `${basePath}/${LOANS_HOME_BASE_PATH}/${LOAN_BASE_PATH}/closing-costs`,
          url: `${baseUrl}/${LOANS_HOME_BASE_PATH}/${loanId}/closing-costs`,
          documentTitle: 'Estimated Closing Costs',
        },
        editProperties: {
          path: `${basePath}/${LOANS_HOME_BASE_PATH}/${LOAN_BASE_PATH}/edit-properties`,
          url: `${baseUrl}/${LOANS_HOME_BASE_PATH}/${loanId}/edit-properties`,
          documentTitle: `Edit Loan Properties - ${loanId}`,
        },
        editBorrowers: {
          path: `${basePath}/${LOANS_HOME_BASE_PATH}/${LOAN_BASE_PATH}/edit-borrowers`,
          url: `${baseUrl}/${LOANS_HOME_BASE_PATH}/${loanId}/edit-borrowers`,
          documentTitle: `Edit Loan Borrowers - ${loanId}`,
        },
        editLoanTerms: {
          path: `${basePath}/${LOANS_HOME_BASE_PATH}/${LOAN_BASE_PATH}/edit-loan-terms`,
          url: `${baseUrl}/${LOANS_HOME_BASE_PATH}/${loanId}/edit-loan-terms`,
          documentTitle: `Edit Loan Terms - ${loanId}`,
        },
        underwriting: {
          path: `${basePath}/${LOANS_HOME_BASE_PATH}/${LOAN_BASE_PATH}/underwriting`,
          url: `${baseUrl}/${LOANS_HOME_BASE_PATH}/${loanId}/underwriting`,
          documentTitle: `Underwriting - ${loanId}`,
        },
        closing: {
          path: `${basePath}/${LOANS_HOME_BASE_PATH}/${LOAN_BASE_PATH}/closing`,
          url: `${baseUrl}/${LOANS_HOME_BASE_PATH}/${loanId}/closing`,
          documentTitle: `Closing - ${loanId}`,
        },
        payoffs: {
          path: `${basePath}/${LOANS_HOME_BASE_PATH}/${LOAN_BASE_PATH}/payoffs`,
          url: `${baseUrl}/${LOANS_HOME_BASE_PATH}/${loanId}/payoffs`,
          documentTitle: `Payoffs - ${loanId}`,
        },
        postClosing: {
          path: `${basePath}/${LOANS_HOME_BASE_PATH}/${LOAN_BASE_PATH}/post-closing`,
          url: `${baseUrl}/${LOANS_HOME_BASE_PATH}/${loanId}/post-closing`,
          documentTitle: `Post Closing - ${loanId}`,
        },
      },
    }),
    loanApplications: (loanApplicationId?: string) => ({
      path: `${basePath}/${LOANS_APPLICATION_HOME_BASE_PATH}/${LOAN_APPLICATION_BASE_PATH}`,
      url: `${baseUrl}/${LOANS_APPLICATION_HOME_BASE_PATH}/${loanApplicationId}`,
      children: {
        dashboard: {
          path: `${basePath}/${LOANS_APPLICATION_HOME_BASE_PATH}/${LOAN_APPLICATION_BASE_PATH}/dashboard`,
          url: `${baseUrl}/${LOANS_APPLICATION_HOME_BASE_PATH}/${loanApplicationId}/dashboard`,
          documentTitle: `${loanApplicationId} - Loan Application Dashboard`,
        },
        todos: {
          path: `${basePath}/${LOANS_APPLICATION_HOME_BASE_PATH}/${LOAN_APPLICATION_BASE_PATH}/todos`,
          url: `${baseUrl}/${LOANS_APPLICATION_HOME_BASE_PATH}/${loanApplicationId}/todos`,
          documentTitle: `Todos - ${loanApplicationId}`,
        },
      },
    }),
  };
};
