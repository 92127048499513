import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { Layout } from '@roc/feature-app-core';
import { Paper } from '@roc/ui';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { SubmitAppraisalForm } from './components/submitAppraisalForm/submitAppraisalForm';
import { SubmitAppraisalSuccess } from './components/submitAppraisalSuccess/submitAppraisalSuccess';
import { LoanTypeSelection } from './components/submitAppraisalForm/components/loanTypeSelection';
import { SubmitAppraisalFormInternal } from '../../../../../apps/roc-internal-portal/src/app/modules/standAloneAppraisal/components/submitAppraisalFormInternal';
const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    padding: theme.spacing(4),
  },
}));

export const SubmitAppraisal = observer(({ store }) => {
  const { isSubmitted, selectedLoanSubType } = store;
  const styles = useStyles();
  const formTitle = 'Order Appraisal';

  useEffect(() => {
    return () => {
      store.reset();
    };
  }, [store]);

  if (!selectedLoanSubType) return <LoanTypeSelection store={store}></LoanTypeSelection>;

  return (
    <Layout title={formTitle.concat(' - ', selectedLoanSubType)} maxWidth={'md'}>
      <Paper className={styles.paper}>
        {!isSubmitted && !store.isClientPortal && (
          <SubmitAppraisalFormInternal store={store} />
        )}
        {!isSubmitted && store.isClientPortal && (
          <SubmitAppraisalForm store={store} />
        )}
        {isSubmitted && <SubmitAppraisalSuccess store={store} />}
      </Paper>
    </Layout>
  );
});
