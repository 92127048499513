import { Grid, Typography, Paper, Box } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { ScopeOfWorkV2FormBaseStore } from '@roc/feature-sow-shared/stores/v2';
import { Button, CopyText } from '@roc/ui';
import { Layout } from '@roc/feature-app-core';
import ScopeOfWorkV2Header from './components/scopeOfWorkV2Header';
import PublicScopeOfWorkHeaderSuccess from './publicScopeOfWorkHeaderSuccess';

const useStyles = makeStyles((theme: Theme) => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      textAlign: 'center',
      marginTop: '20px',
    },
    button: {
      marginTop: theme.spacing(2),
    },
    margins: {
      marginTop: '20px'
    },
    paper: {
      display: 'flex',
      height: '100%',
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(4),
      },
    },
  };
});

export type ScopeOfWorkV2FormProps = {
  store: ScopeOfWorkV2FormBaseStore;
};


export const PublicScopeOfWorkSuccess = observer((props: ScopeOfWorkV2FormProps) => {
  const classes = useStyles();

  const { store } = props;

  const redirectToScopeOfWork = () => {
    window.location.href = store.scopeOfWorkShareUrl;
  }
  return (
    <Layout>
      <PublicScopeOfWorkHeaderSuccess
        store={store}
        screenshotContainerRef={null}
      />
      <Paper className={classes.paper}>
        <Grid container className={classes.container}>
          <Grid item >
            <CheckCircleIcon style={{ fontSize: '100px', color: 'green' }} />
            <Typography variant="h2">
              Process Completed!
              <br />
            </Typography>
          </Grid>
          <Grid item className={classes.margins}>
            <Typography>
              The submission of the scope of work has been completed.
            </Typography>
            <Typography>
              If you would like to save it for future use, you can select <strong>Copy Link</strong>.
            </Typography>
            <Typography>
              If you wish to make edits, you may click on <strong>Go To Scope of Work</strong>.
            </Typography>
            <Grid container spacing={2} justifyContent='center'>
              <Grid item>
                <Button
                  testId="createScopeOfWorkButton"
                  variant="contained"
                  size="small"
                  color="primary"
                  className={classes.button}
                  onClick={redirectToScopeOfWork}
                >
                  Go to Scope of Work
                </Button>
              </Grid>
              <Grid item>
                <CopyText valueToCopy={store.scopeOfWorkShareUrl}>
                  <Button
                    testId="createScopeOfWorkButton"
                    variant="contained"
                    size="small"
                    color="primary"
                    className={classes.button}
                  >
                    Copy Link
                  </Button>
                </CopyText>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Layout>
  );
});
