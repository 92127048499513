import { Grid } from "@material-ui/core"
import { Button, createDateField, createMultiSelectField, createNumberFormatField, createRadioField, createSelectField, createTextField } from "@roc/ui"
import { useCreateListStore } from "../../hooks/useCreateListStore";
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { observer } from "mobx-react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonContainer: {
      paddingTop: theme.spacing(2),
    },
    operatorContainer: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    }
  })
);

const trueFalseOptions = [
  {
    label: 'true',
    value: true,
  },
  {
    label: 'false',
    value: false,
  },
];

export const createOptions = (options) => {
  const valuesArray = options.substring(1, options.length - 1).split(", ");

  const recordTypeContactOptions = valuesArray.map(value => {
    return {
      label: value,
      value: value,
    };
  });
  return recordTypeContactOptions;
}

export const FilterModalContent = observer(({ onAddFilter = () => { }, isEdit = false, onEditFilter = index => { }, index = null }) => {
  const classes = useStyles();
  const { createListStore } = useCreateListStore();
  const { addFilterFormStore } = createListStore;

  const getValueField = (value) => {
    switch (value?.type) {
      case 'Text':
        return createTextField({
          label: 'Value',
          fieldName: 'value',
          testId: 'value',
          store: addFilterFormStore,
        });
      case 'boolean':
        return createRadioField({
          label: 'Value',
          fieldName: 'value',
          testId: 'value',
          store: addFilterFormStore,
          options: trueFalseOptions,
          row: true,
        });
      case 'Number':
        return createNumberFormatField({
          label: 'Value',
          fieldName: 'value',
          testId: 'value',
          store: addFilterFormStore,
          placeholder: 'Example: 2000',
          allowNegative: false
        });
      case 'date':
        return createDateField({
          label: 'Value',
          fieldName: 'value',
          testId: 'value',
          store: addFilterFormStore,
          format: 'MM/dd/yyyy',
        });
      case 'multiselect':
        return createMultiSelectField({
          store: addFilterFormStore,
          testId: 'value',
          fieldName: 'value',
          label: 'Value',
          options: createOptions(value?.options),
          placeholder: 'Make Selection',
        })
      default:
        return createTextField({
          label: 'Value',
          fieldName: 'value',
          testId: 'value',
          store: addFilterFormStore,
        });
    }
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        {createSelectField({
          label: 'Field',
          fieldName: 'field',
          testId: 'field',
          options: createListStore.fieldsDropdownValues,
          store: addFilterFormStore,
        })}
      </Grid>
      <Grid item xs={12} className={classes.operatorContainer}>
        {createListStore.currentOperatorsValue.length > 0 ? createSelectField({
          label: 'Operator',
          fieldName: 'operator',
          testId: 'operator',
          options: createListStore.currentOperatorsValue,
          store: addFilterFormStore,
        }) : null}
      </Grid>
      <Grid item xs={12}>
        {createListStore.currentOperatorsValue.length > 0 ? getValueField(createListStore.selectedField) : null}
      </Grid>
      <Grid item container justifyContent="flex-end" className={classes.buttonContainer}>
        <Grid item>
          {isEdit ? (
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => onEditFilter(index)}
              disabled={!addFilterFormStore?.form?.meta?.isValid}
              testId="editFilter"
            >Edit</Button>
          ) : (<Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={onAddFilter}
            disabled={!addFilterFormStore?.form?.meta?.isValid}
            testId="addFilter"
          >Done</Button>)}
        </Grid>
      </Grid>
    </Grid>
  )
});