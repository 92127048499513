import { Service } from "@roc/feature-app-core";

const url = {
  ROC_UNIVERSITY_USER_URL: '/api/v1/loan/loginUserToLMS',
  LOGO_LMS: '/api/v1/document/public/downloadStaticImageByName?name=roc-university.png',
};

export class RocUniversityService extends Service {
  getRocUniversityUserUrl() {
    return this.get(url.ROC_UNIVERSITY_USER_URL);
  }

  getLogoLMS() {
    return url.LOGO_LMS;
  }
}