import { Box, Grid, Theme, makeStyles,Typography } from '@material-ui/core';
import { Button, DialogState, EditableDialog, FilterButton, Toolbar } from '@roc/ui';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { ManageTemplatesGrid } from './manageTemplatesGrid';
import {useStore} from 'libs/client-portal-shared/src/app/hooks/index';

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between !important',
    width: '100%',
  },
  button: {
    margin: '10px',
    minWidth: '150px',
    display: 'flex-end'
  },
}));

export const ManageTemplatesDashboard = observer(() => {

  const { salesforceLenderStore, ringCentralStore } = useStore();

  const classes = useStyles();


  useEffect(() => {
    ringCentralStore.templatesGrid.resetAndFetchGridData();
  }, [salesforceLenderStore]);

  const onCreateTemplate = () => {
    ringCentralStore.setOpenCreateTemplateModal(true);
  }

  return (
    <>
      <ManageTemplatesGrid
        toolbar={
          <Toolbar>
            <Grid container direction="row" className={classes.header}>
              <Button className={classes.button} variant="contained" onClick={onCreateTemplate} size="small" color="primary" testId="send_bulk_messages">
                Create Template
              </Button>
            </Grid>
          </Toolbar>
        }
      />
    </>
  );
});