import { useEffect } from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Layout } from '@roc/feature-app-core';
import { Paper, GridColumnProps } from '@roc/ui';
import { observer } from 'mobx-react';
import { filterProps, noOpComparator } from '@roc/ui/utils';
import { usePayablesDashboardStore } from '../../hooks/usePayablesDashboardStore';
import { useDocumentStore } from '@roc/feature-documents';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { AttestationGrid } from './attestationGrid';
import { Button } from '@roc/ui';
import { useState } from 'react';

const useStyles = makeStyles(() => ({
  container: {
    maxWidth: '1400px',
  },
  paper: {
    boxShadow: 'none !important',
    border: '1px solid #cdcdcd',
    padding: '0px',
    borderRadius: '0 !important',
    margin: '0px'
  },
}));

export const PayableAttestationDashboard = observer((props) => {
  const classes = useStyles();
  const { attestationDashboardStore } = usePayablesDashboardStore();
  const { globalStore } = attestationDashboardStore;
  const isInternal = globalStore?.isInternalPortal;
  const type = isInternal ? props.type?.type : props.type;
  const loanId = isInternal ? props.loanId?.loanId : props.loanId;
  const { documentStore } = useDocumentStore();
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const attestDisabled = !checkboxChecked || !attestationDashboardStore.attestEnabled;
  const toggle = (check) => {
    setCheckboxChecked(check);
  }

  useEffect(() => {
    if (loanId) {
      attestationDashboardStore.setData(loanId, type);
      attestationDashboardStore.specialServicingGridStore.resetAndFetchGridData();
    }
  }, []);

  const handleClose = () => {
    if (isInternal) {
      window.parent.postMessage('close-modal', '*');
    } else {
      props.handleClose();
    }
  };

  const handleSubmit = async () => {
    await attestationDashboardStore.attest(loanId, type);
    if (attestationDashboardStore?.globalStore?.isInternalPortal) {
      window.parent.postMessage('saved-success', '*');
    } else {
      globalStore.notificationStore.showSuccessNotification({
        message: 'The attestation has been done out successfully.',
      });
      props.handleClose();
    }
  };

  const COLUMNS: GridColumnProps[] = [
    {
      field: 'feeType',
      headerName: 'Type',
      minWidth: 125,
      comparator: noOpComparator,
      ...filterProps(),
    },
    {
      field: 'invoice',
      headerName: 'Invoice #',
      minWidth: 125,
      comparator: noOpComparator,
      ...filterProps(),
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 125,
      comparator: noOpComparator,
      ...filterProps(),
      editable: true,
      cellEditor: 'statusEditorAttestation',
    },
    {
      field: 'amount',
      headerName: 'Amount',
      minWidth: 125,
      cellRenderer: 'currencyCellRenderer',
      comparator: noOpComparator,
      ...filterProps(),
    },
    {
      field: 'entity',
      headerName: 'Vendor',
      minWidth: 125,
      comparator: noOpComparator,
      ...filterProps(),
    },

  ];

  return (
    <Layout title={type + " Attestation"} containerClass={classes.container}>
      <Box display="flex" flexDirection="column" height="100%" marginLeft={"30px"} marginRight={"30px"}>
        <Typography>Please review the following payables and modify the status accordingly.</Typography>
        <br />
        <Box flex={1}>
          <Paper className={classes.paper}>
            <AttestationGrid
              dashboardStore={attestationDashboardStore}
              columns={COLUMNS}
            />
          </Paper>
        </Box>

        <br />
        <Box flex={1} marginLeft={0}>
          <FormControlLabel
            control={
              <Checkbox
                required={true}
                checked={checkboxChecked}
                onChange={(e, checked) => toggle(checked)}
                color="secondary"
              />
            }
            label={type == "Legal" ? <b>I confirm that all legal fees for this loan have been submitted and accounted for. No further bills are pending on this loan. </b> : <b>I confirm that servicing fees to all external parties for this loan have been mitigated and accounted for.
              No further bills are pending on this loan.</b>}
          />


        </Box>

        <Box display="flex" height="100%" justifyContent={'end'} alignContent={'end'}>
          <Button style={{ height: '40px', marginTop: '10px', marginRight: '20px', width: '80px' }}
            size='small'
            variant="contained"
            type="submit"
            onClick={() => handleClose()}
            testId="submit"
            color='primary'
            disabled={false}
          >
            <span>CANCEL</span>
          </Button>
          <Button style={{ height: '40px', marginTop: '10px', width: '80px' }}
            size='small'
            variant="contained"
            type="submit"
            onClick={() => handleSubmit()}
            testId="submit"
            color='primary'
            disabled={attestDisabled}
          >
            <span>ATTEST</span>
          </Button>
        </Box>

      </Box>

    </Layout>
  );
});
