// ----------------------------------------------------------------------

export default function Tab(theme) {
  return {
    MuiTab: {
      root: {
        minWidth: 80,
        fontSize: '1rem',
        '@media (min-width:600px) and (max-width:1400px)': {
          minWidth: 100,
        },
        '@media (min-width:1400px)': {
          minWidth: 140,
        },
      },
    },
  };
}
