import { StepsContainer } from '@roc/ui';
import { observer } from 'mobx-react';
import { PersonalInformation } from './personalInformation';
import { IdInformation } from './idInformation';
import { CreditInformation } from './creditInformation';
import { FinanciallInformation } from './financialInformation';
import { PublicCreditInformation } from './public/publicCreditInformation';

export const BorrowerSetupSteps = observer((props) => {

  const { store, handleClose } = props;
  const { activeStep, isBorrowerUser, personalGuarantor } = store;

  const steps = [
    {
      title: 'Personal Information',
      icon: null,
      onNext: async () => {
        store.savePersonalInformation();
      },
      allowBack: false,
      allowNext: true,
    },
    {
      title: 'ID Information',
      icon: null,
      onBack: () => {
        store.goPrevStep();
      },
      onNext: async () => {
        store.saveIdInformation();
      },
      allowBack: true,
      allowNext: true,
    },
    {
      title: 'Financial Information',
      icon: null,
      onBack: () => {
        store.goPrevStep();
      },
      onNext: async () => {
        store.saveFinancialInformation();
      },
      allowBack: true,
      allowNext: true,
    },
    {
      title: 'Credit Information',
      icon: null,
      onBack: () => {
        store.goPrevStep();
      },
      onNext: async () => {
        store.saveCreditInformation(handleClose);
      },
      allowBack: true,
      allowNext: !isBorrowerUser,
      nextButtonText: 'Finish',
    },
  ];

  const getCreditContent = () => {
    return isBorrowerUser && personalGuarantor
      ? <PublicCreditInformation store={store} handleClose={handleClose} />
      : <CreditInformation store={store} />
  };

  const getStepsContent = () => {
    return [
      <PersonalInformation store={store} />,
      <IdInformation store={store} />,
      <FinanciallInformation store={store} />,
      getCreditContent()
    ];
  };

  return (
    <StepsContainer
      activeStep={activeStep}
      disableNavigation={true}
      steps={steps}
    >
      {getStepsContent()}
    </StepsContainer>
  )
});