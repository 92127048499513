import { Box, Grid, Theme, Typography } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { makeStyles } from '@material-ui/styles';
import { isBlank } from '@roc/feature-utils';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import {
  bridgeTier1Badge,
  bridgeTier2Badge,
  bridgeTier3Badge,
  bridgeTier4Badge,
  bridgeTier5Badge,
  rentalTier0Badge,
  rentalTier1Badge,
  rentalTier2Badge,
  rentalTier3Badge,
  rentalTier4Badge,
  rentalTier5Badge,
} from '../../assets';

const bridgeImgByTier = {
  1: bridgeTier1Badge,
  2: bridgeTier2Badge,
  3: bridgeTier3Badge,
  4: bridgeTier4Badge,
  5: bridgeTier5Badge,
};

const rentalImgByTier = {
  0: rentalTier0Badge,
  1: rentalTier1Badge,
  2: rentalTier2Badge,
  3: rentalTier3Badge,
  4: rentalTier4Badge,
  5: rentalTier5Badge,
};


const ANIMATION_DURATION = 0.8;

const isNumber = (value) => typeof value === 'number';

const useStyles = makeStyles((theme: Theme) => ({
  img: {
    objectFit: 'contain',
    //width: '50%',
    width: 100,
  },
  preeScreenTier: {
    textAlign: 'center',
    fontSize: '12px',
    padding: theme.spacing(1),
    background: theme.palette.primary.main,
    borderRadius: theme.spacing(1),
    color: 'white',
    marginBottom: theme.spacing(2),
  },
  flipCardContainer: {
    perspective: '1000px',
  },
  flipCard: {
    animation: `$flip ${ANIMATION_DURATION}s ease`,
    transformStyle: 'preserve-3d',
    '*': {
      animation: `$invert ${ANIMATION_DURATION / 2}s ease ${ANIMATION_DURATION / 2}s`
    }
  },
  '@keyframes flip': {
    from: {
      transform: 'rotateY(0deg) scaleX(1)',
    },
    to: {
      transform: 'rotateY(180deg) scaleX(-1)',
    }
  },
  '@keyframes invert': {
    from: {
      transform: 'scaleX(-1)',
    },
    to: {
      transform: 'scaleX(-1)',
    }
  },
  alert: {
    lineHeight: '1.7em'
  }
}));

export const BorrowerTierRating = observer(({ bridgeTier, rentalTier }) => {
  const classes = useStyles();
  return (
    <Box display={'flex'}>
      <img className={classes.img} src={bridgeImgByTier[bridgeTier]} />
      <img className={classes.img} src={rentalImgByTier[rentalTier]} />
    </Box>
  );
});

export const PreScreenBorrowerTierRating = observer(
  ({
    bridgeTier,
    propertiesToNextBridgeTier,
    rentalTier,
    propertiesToNextRentalTier,
  }) => {
    const classes = useStyles();
    const [previousBridgeTier, setPreviousBridgeTier] = useState(bridgeTier);
    const [previousRentalTier, setPreviousRentalTier] = useState(rentalTier);
    const [playingBridgeAnimation, setPlayingBridgeAnimation] = useState(false);
    const [playingRentalAnimation, setPlayingRentalAnimation] = useState(false);

    useEffect(() => {
      if(bridgeTier > previousBridgeTier && isNumber(previousBridgeTier)){
        animateBridge();
      }
      if(rentalTier > previousRentalTier && isNumber(previousRentalTier)){
        animateRental();
      }
      setPreviousBridgeTier(bridgeTier);
      setPreviousRentalTier(rentalTier);
    }, [bridgeTier, rentalTier])

    const animateBridge = () => {
      setPlayingBridgeAnimation(true);
      setTimeout(() => setPlayingBridgeAnimation(false), ANIMATION_DURATION * 1000);
    }

    const animateRental = () => {
      setPlayingRentalAnimation(true);
      setTimeout(() => setPlayingRentalAnimation(false), ANIMATION_DURATION * 1000);
    }

    const bridgeMsg = propertiesToNextBridgeTier
      ? `Enter at least ${propertiesToNextBridgeTier} ${propertiesToNextBridgeTier > 1 ? 'properties' : 'property'} to be qualified experience "Tier ${bridgeTier + 1}"`
      : `Congratulations, you've achieved the maximum experience tier!`;

    const rentalMsg = propertiesToNextRentalTier
      ? `Enter at least ${propertiesToNextRentalTier} ${propertiesToNextRentalTier > 1 ? 'properties' : 'property'} to be qualified experience "Tier ${rentalTier + 1}"`
      : `Congratulations, you've achieved the maximum experience tier!`;

    return (
      <Box display={'flex'} flexDirection={'column'} className={classes.flipCardContainer}>
        <Box className={clsx(classes.preeScreenTier, playingBridgeAnimation && classes.flipCard)}>
          <img className={classes.img} src={bridgeImgByTier[bridgeTier]} />
          <Box>{bridgeMsg}</Box>
        </Box>
        <Box className={clsx(classes.preeScreenTier, playingRentalAnimation && classes.flipCard)}>
          <img className={classes.img} src={rentalImgByTier[rentalTier]} />
          <Box>{rentalMsg}</Box>
        </Box>
      </Box>
    );
  });

export const PreScreenBorrowerTierNotification = observer(
  ({
    bridgeTier,
    propertiesToNextBridgeTier,
    rentalTier,
    propertiesToNextRentalTier,
  }) => {
    const classes = useStyles();
    const [previousBridgeTier, setPreviousBridgeTier] = useState(bridgeTier);
    const [previousRentalTier, setPreviousRentalTier] = useState(rentalTier);
    const [showAlert, setShowAlert] = useState(false);

    useEffect(() => {
      const bridgeTierIncreased = bridgeTier > previousBridgeTier && !isBlank(previousBridgeTier);
      const rentalTierIncreased = rentalTier > previousRentalTier && !isBlank(previousRentalTier);
      if (bridgeTierIncreased || rentalTierIncreased) {
        setShowAlert(true);
      }
      setPreviousBridgeTier(bridgeTier);
      setPreviousRentalTier(rentalTier);
    }, [bridgeTier, rentalTier])


    const getMsgForNextTier = () => {
      if (propertiesToNextBridgeTier && propertiesToNextRentalTier) {
        return (
          <>
            Congratulations on increasing the tier! You are doing a great job.
            <br />
            To reach the next level, you need to add either{' '}
            {propertiesToNextBridgeTier} more flip deals or{' '}
            {propertiesToNextRentalTier} more rental deals.
          </>
        );
      } else if (propertiesToNextBridgeTier) {
        return (
          <>
            Congratulations on increasing the tier! You are doing a great job.
            <br />
            To reach the next level, you need to add{' '}
            {propertiesToNextBridgeTier} more flip deals.
          </>
        );
      } else if (propertiesToNextRentalTier) {
        return (
          <>
            Congratulations on increasing the tier! You are doing a great job.
            <br />
            To reach the next level, you need to add{' '}
            {propertiesToNextRentalTier} more rental deals.
          </>
        );
      } else {
        return (
          <>
            Congratulations! You have reached the maximum tier.
          </>
        );
      }
    };

    return (
      <>
        {showAlert && (
          <Grid item xs={12}>
            <Alert onClose={() => setShowAlert(false)} severity="success" className={classes.alert}>
              {getMsgForNextTier()}
              <br />
              <Typography variant="caption"><i>Note: Tier upgrade is subject to verification of the information provided.</i></Typography>
            </Alert>
          </Grid>
        )}
      </>
    );
  });