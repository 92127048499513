import { GlobalStore } from '@roc/feature-app-core';
import { action, flow, makeObservable, observable, override } from 'mobx';
import { PropertiesStore } from '../properties/propertiesStore';
import { QuoteTypeStore } from '../quoteTypeStore';
import { LeverageFormStore } from './leverageFormStore';
import { sum } from '../../utils/utils';
import { LoanEconomicsStore } from './loanEconomicsStore';
import { formatPercentage } from '@roc/ui/utils';

export class FixFlipLoanEconomicsStore extends LoanEconomicsStore {
  constructor(globalStore: GlobalStore, quoteTypeStore: QuoteTypeStore) {
    super(globalStore, quoteTypeStore);
    makeObservable(this, {
      caclulateFields: override,
      mapCalculatedFields: override,
    });
  }

  caclulateFields(properties: any[], leverage: any): Record<string, number> {
    //Leverage
    const totalConstructionRenoSpendToDate = this.quoteTypeStore.isMidConstructionRefinance() ?
      sum(properties.map(p => p.constructionRenoSpendToDate)) : 0;

    const totalSellerCredit = sum(properties.filter(p => !!p.sellerCredit).map(p => p.sellerCreditAmount));

    const { loanToCost, constructionReserve } = leverage;
    const totalPurchasePrice = sum(properties.map(p => p.purchasePrice)) + totalConstructionRenoSpendToDate - totalSellerCredit;
    const totalProposedConstructionBudget = sum(
      properties.map(p => p.renovationBudget)
    );
    const totalAfterRepairValue = sum(
      properties.map(p => p.marketValueAfterCompletion)
    );
    const totalAsIsValue = sum(properties.map(p => p.currentMarketValue));

    const initialAdvance = totalPurchasePrice * (loanToCost / 100);
    const constructionHoldback =
      totalProposedConstructionBudget * (constructionReserve / 100);
    const totalLoanAmount = initialAdvance + constructionHoldback;
    const afterRepairLTV = (totalLoanAmount / totalAfterRepairValue) * 100;

    return {
      totalPurchasePrice,
      totalProposedConstructionBudget,
      totalAfterRepairValue,
      initialAdvance,
      constructionHoldback,
      totalLoanAmount,
      afterRepairLTV,
    };
  }

  mapCalculatedFields() {
    const manualExceptionQuoteData = this.manualExceptionQuoteFormStore.calculatedFields;
    const instaLeverageQuote = this.instaLeverageQuoteFormStore.calculatedFields;

    return {
      totalLoanAmount: instaLeverageQuote.totalLoanAmount,
      exceptionTotalLoanAmount: manualExceptionQuoteData.totalLoanAmount,

      totalARLTVPercentage: instaLeverageQuote.afterRepairLTV,
      exceptionTotalARLTVPercentage: manualExceptionQuoteData.afterRepairLTV,
    }
  }

  calculateKeyRatios() {
    const leverage = this.getLoanEconomics();
    const calculatedFields = this.calculatedFields;

    const ltcPurchasePricePercentage = formatPercentage(leverage.loanToCost);
    const ltcConstructionBudgetHoldbackPercentage = formatPercentage(
      leverage.constructionReserve
    );
    const ARLTV = formatPercentage(calculatedFields.afterRepairLTV);

    return `${ltcPurchasePricePercentage} / ${ltcConstructionBudgetHoldbackPercentage} / ${ARLTV}`;
  }

  validateLeverageAmounts() {
    const errors = [];

    if (!this.enableManualQuote) {
      const maxARLTV = this.instaQuoteRanges.maxARLTV || 70; // default value in case it's missing
      const afterRepairLTV = this.calculatedFields?.afterRepairLTV || 0;

      if (afterRepairLTV > maxARLTV) {
        errors.push(`At your expected ARV, the loan amount you are requesting is over ${maxARLTV}% ARLTV. Please reduce either the LTC or holdback so the loan request is ${maxARLTV}% ARLTV or less.`);
      }
    }

    return errors;
  }
}
