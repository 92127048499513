import react, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Grid,
  IconButton
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { PercentageField, StandardDialog, CurrencyField } from '@roc/ui';
import { Chat } from '@material-ui/icons';
import { alpha } from '@material-ui/core/styles';
import { formatCurrency } from '@roc/ui/utils';
import { observer } from 'mobx-react';
import CommentDialog from '../../common/commentDialog';
import { formatPrecision, roundHalf } from '@roc/feature-utils';
import CommentIcon from '@material-ui/icons/Comment'; import ReviewerCommentDialog from '../../common/reviewerCommentDialog';

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    minWidth: 750,
    border: '2px #e0e0e0 solid',
    '& td': {
      height: '40px',
      padding: 0,
      paddingTop: '8px',
      paddingBottom: '8px',
    },
    '& th:first-child, td[rowSpan], $addNewItemRow td': {
      paddingLeft: theme.spacing(2),
      paddingRight: '3px',
    },
    '& table tr:last-child td, & table td[rowSpan]': {
      borderBottom: 'none',
    },
  },

  editableRow: {
    position: 'relative',
    cursor: 'pointer',
    '&:hover td': {
      backgroundColor: '#fdefbf',
      transition: theme.transitions.create(['background-color', 'transform'], {
        duration: theme.transitions.duration.standard,
      }),
    },
    '&:hover $rowActions': {
      visibility: 'visible',
    },
    '& td:last-child': {
      [theme.breakpoints.down('md')]: {
        paddingRight: '100px',
      },
    },
  },
  headerCell: {
    height: '41px',
    fontWeight: 'bold',
    padding: '0 4px 0 0',
    fontFamily: 'Oswald, sans-serif;',
  },
  titleCell: {
    height: '46px',
    padding: 0,
    borderBottom: '2px #e0e0e0 solid',
  },

  descriptionCell: {
    whiteSpace: 'pre-wrap',
  },

  inputBase: {
    width: '100%',
    '& input': {
      backgroundColor: '#fdefbf',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      fontSize: 14,
      color: 'black',
      fontWeight: theme.typography.fontWeightBold,
      padding: '8px 4px',
      cursor: 'pointer',
      '&:focus': {
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  autocompletePaper: {
    marginLeft: 0,
    marginRight: 0,
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    fontSize: 13,
  },
  autocompleteOption: {
    minHeight: 'auto',
    alignItems: 'flex-start',
    padding: 8,
    '&[data-focus="true"]': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  addNewItemCell: {
    padding: 0,
    height: '40px',
    backgroundColor: '#fdefbf',
  },
  addNewItemRow: {
    '&:hover': {
      backgroundColor: '#fff',
    },
  },
  rowActions: {
    visibility: 'hidden',
    position: 'absolute',
    right: 0,
    top: 0,
    bottom: 0,
    zIndex: 99,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      visibility: 'visible',
    },
  },
  horizontalScroll: {
    [theme.breakpoints.down('md')]: {
      overflowX: 'scroll',
    },
  },
  clickHereToEdit: {
    color: theme.palette.grey[400],
    fontSize: theme.typography.fontSize * 0.8,
  },
  clickHereToEditHidden: {
    visibility: 'hidden',
  },
  title: {
    color: '#333333',
    fontSize: 20,
    fontWeight: 'bold',
    fontFamily: 'Oswald, sans-serif;',
  },
  percentageInput: {
    marginLeft: 10,
    marginRight: 48,
  },
  activeChatIcon: {
    color: '#F2BC16',
    cursor: 'pointer',
  },
  disabledChatIcon: {
    color: '#e0e0e0',
  },
  border: {
    borderBottom: 0
  }
}));

const columns: any[] = [
  {
    name: 'LINE ITEM',
    width: '15%',
    align: 'left',
    ignore: false,
    ignoreComplete: false,
  },
  {
    name: '',
    width: '5%',
    align: 'left',
    ignore: true,
    ignoreComplete: true,
  },
  {
    name: 'DETAIL',
    width: '10%',
    align: 'left',
    ignore: false,
    ignoreComplete: false,
  },
  {
    name: 'DESCRIPTION',
    width: '5%',
    align: 'left',
    ignore: false,
    ignoreComplete: false,
  },
  {
    name: 'TOTAL ITEM BUDGET',
    width: '8%',
    align: 'center',
    ignore: false,
    ignoreComplete: false,
  },
  {
    name: 'COMPLETED RENOVATIONS',
    width: '5%',
    align: 'center',
    ignore: false,
    ignoreComplete: false,
  },
  {
    name: 'PREVIOUSLY DISBURSED',
    width: '8%',
    align: 'center',
    ignore: false,
    ignoreComplete: false,
  },
  {
    name: '% COMPLETE TO DATE',
    width: '6%',
    align: 'center',
    ignore: false,
    ignoreComplete: false,
  },
  {
    name: 'REQUESTED THIS DRAW',
    width: '8%',
    align: 'center',
    ignore: false,
    ignoreComplete: false,
  },
  {
    name: 'BORROWER\'S COMMENTS',
    width: '6%',
    align: 'center',
    ignore: true,
    ignoreComplete: true,
  },
  {
    name: 'LENDER APPROVED',
    width: '8%',
    align: 'center',
    ignore: true,
    ignoreComplete: false,
  },
  {
    name: 'DISBURSEMENT THIS DRAW',
    width: '10%',
    align: 'center',
    ignore: true,
    ignoreComplete: false,
  },
];

const calculatePercentage = (partialValue: number, totalValue: number) => roundHalf((partialValue / totalValue) * 100);

const DrawReviewReportCategoryTable = observer(({ category, store, isInternal }) => {
  const classes = useStyles();
  const [openCommentModal, setOpenCommentModal] = useState<boolean>(false);
  const [comment, setComment] = useState<string>("");
  const [currentItem, setCurrentItem] = useState<any>({});
  const { editable } = store;
  const [openReviewerComments, setOpenReviewerComments] = useState<boolean>(false);

  const handleRowItemChange = (categoryName, lineItemName, isDetail, detailIndex, value, key) => {
    if (isDetail) {
      store.onTableContentChange(categoryName, lineItemName, detailIndex, value, key);
    } else {
      store.onTableContentChange(categoryName, lineItemName, null, value, key);
    }
  };

  const handleOpenCommentModal = comment => {
    setComment(comment);
    setOpenCommentModal(true);
  };

  const handleOpenReviewerCommentsModal = (item, category, row, idx) => {
    setCurrentItem({ item, category, row, idx });
    setOpenReviewerComments(true)
  }

  const getOriginalCost = (item, category, row, idx) => {
    if (editable) {
      return (
        <CurrencyField
          value={item.cost ? item.cost : ''}
          variant="outlined"
          testId="cost"
          onChange={(name, value) => handleRowItemChange(
            category.name, row.name, !!row.itemized.length, idx, value, 'cost'
          )}

          fullWidth
        />
      );
    } else {
      return (
        `${formatCurrency(item.cost, 0)}`
      );
    }
  };

  const getPreviouslyDisbursed = (item, category, row, idx) => {
    if (editable) {
      return (
        <CurrencyField
          value={item.previouslyDisbursed ? item.previouslyDisbursed : ''}
          variant="outlined"
          testId="previouslyDisbursed"
          onChange={(name, value) => handleRowItemChange(
            category.name, row.name, !!row.itemized.length, idx, value, 'previouslyDisbursed'
          )}
          fullWidth

        />
      );
    } else {
      return (
        `${calculatePercentage(item.previouslyDisbursed, item.cost)}% | ${formatCurrency(item.previouslyDisbursed, 0)}`
      );
    }
  };

  const getCapex = (item, category, row, idx) => {
    if (editable) {
      return (
        <PercentageField
          value={item.capex ? item.capex : 0}
          variant="outlined"
          testId="capex"
          onChange={(name, value) => handleRowItemChange(
            category.name, row.name, !!row.itemized.length, idx, value, 'capex'
          )}
          fullWidth
          limitedPercentage
        />
      );
    } else {
      return item.capex ? (`${item.capex}% | ${formatCurrency(((item.capex * item.cost) / 100), 2)}`) : '0% | $0';
    }
  };

  const getPercentageCompleteToDate = (item, category, row, idx) => {
    if (editable) {
      return (
        <PercentageField
          value={item.percentageCompleteToDate ? item.percentageCompleteToDate : ''}
          variant="outlined"
          testId="percentageCompleteToDate"
          onChange={(name, value) => handleRowItemChange(
            category.name, row.name, !!row.itemized.length, idx, value, 'percentageCompleteToDate'
          )}
          fullWidth
          limitedPercentage

        />
      );
    } else {
      return (
        `${item.percentageCompleteToDate}%`
      );
    }
  };

  const getLenderApprovedData = (item, category, row, idx) => {
    if (isInternal) {
      return (
        <PercentageField
          value={item.loanFunderLLCApprovedPercentage ? item.loanFunderLLCApprovedPercentage : ''}
          variant="outlined"
          testId="loanFunderLLCApprovedPercentage"
          onChange={(name, value) => handleRowItemChange(
            category.name, row.name, !!row.itemized.length, idx, value, 'loanFunderLLCApprovedPercentage'
          )}
          fullWidth
          limitedPercentage
        />
      );
    } else {
      return (
        `${item.loanFunderLLCApprovedPercentage}%`
      );
    }
  };

  const getComments = (item) => {
    return (
      <Grid container direction='column'>
        <Typography style={{ fontSize: "0.9rem", paddingLeft: '16px', marginBottom: '5px', fontWeight: 'bold' }}>
          Reviewer's Comments
        </Typography>
        <Typography style={{ fontSize: "0.9rem", paddingLeft: '16px' }}>
          {item.reviewerComments ? item.reviewerComments : ''}
        </Typography>
      </Grid>
    )
  };

  const getBorderClass = (reviewerComments: string) => {
    return reviewerComments ? classes.border : '';
  }

  const getRowSpan = (itemized) => {
    let totalRows = itemized.length;
    if (totalRows > 1) {
      itemized.map((item) => {
        if (item.reviewerComments)
          totalRows += 1;
      })
      return totalRows;
    }
    return totalRows;
  }

  const getRowColor = (row) => {
    if (row.id === null){
      return 'lightyellow'
    }
  }

  return (
    <Grid item xs={12} md={12}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell colSpan={columns.length} className={classes.titleCell}>
              <Typography className={classes.title}>{category.name}</Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            {columns.map(({ name, ...props }) => (
              <>
                {props.ignore && store.addToIgnoredList(`${name}_${category.name}`)}
                {props.ignoreComplete && store.addToIgnoreListCompletePdf(`${name}_${category.name}`)}
                <TableCell id={`${name}_${category.name}`} key={name} className={classes.headerCell} {...props}>
                  {name}
                </TableCell>
              </>
            ))}
          </TableRow>
        </TableHead>
        {category.subCategories.map((row, index) => {
          const itemized =
            row.itemized.length > 0
              ? row.itemized
              : [
                {
                  cost: row.general.cost,
                  previouslyDisbursed: row.general.previouslyDisbursed,
                  percentageCompleteToDate: row.general.percentageCompleteToDate,
                  requestedThisDraw: row.requestedThisDraw,
                  comments: row.general.comments,
                  capex: row.general.capex,
                  loanFunderLLCApprovedPercentage: row.general.loanFunderLLCApprovedPercentage,
                  reviewerComments: row.general.reviewerComments,
                },
              ];

          return (
            <TableBody
              key={`${row?.id}_${row?.name}_${index}`}
            >
              {itemized.map((item, idx) => {
                return (
                  <>
                    <TableRow style={{backgroundColor: getRowColor(row)}}
                      key={`${row?.id}_${row?.name}_'${item.name}_${index}`}
                    >
                      {idx === 0 && (
                        <TableCell
                          width={columns[0].width}
                          rowSpan={getRowSpan(itemized)}
                          className={getBorderClass(item.reviewerComments)}
                        >
                          {row.name}
                        </TableCell>
                      )}
                      <TableCell
                        width={columns[1].width}
                        className={getBorderClass(item.reviewerComments)}
                        id={`REVIEWER'S_COMMENTS_ICON${category?.name}_${row?.name}_${index + idx}`}
                      >
                        {columns[1].ignore && store.addToIgnoredList(`REVIEWER'S_COMMENTS_ICON${category?.name}_${row?.name}_${index + idx}`)}
                        {store.addToIgnoreListCompletePdf(`REVIEWER'S_COMMENTS_ICON${category?.name}_${row?.name}_${index + idx}`)}
                        <IconButton disabled={!isInternal} onClick={(() => handleOpenReviewerCommentsModal(item, category, row, idx))}>
                          <CommentIcon style={{ fontSize: '20px', color: isInternal ? '#F2BC16' : '#E0E0E0' }} />
                        </IconButton>
                      </TableCell>
                      <TableCell width={columns[2].width} className={getBorderClass(item.reviewerComments)}>
                        {item.name}
                      </TableCell>
                      <TableCell width={columns[3].width} className={getBorderClass(item.reviewerComments)}>
                        {item.description}
                      </TableCell>
                      <TableCell width={columns[4].width} align="center" className={getBorderClass(item.reviewerComments)}>
                        {getOriginalCost(item, category, row, idx)}
                      </TableCell>
                      <TableCell width={columns[5].width} align="center" className={getBorderClass(item.reviewerComments)}>
                        {getCapex(item, category, row, idx)}
                      </TableCell>
                      <TableCell
                        width={columns[6].width}
                        align="center"
                        className={getBorderClass(item.reviewerComments)}
                      >
                        {getPreviouslyDisbursed(item, category, row, idx)}
                      </TableCell>
                      <TableCell
                        width={columns[7].width}
                        align="center"
                        className={getBorderClass(item.reviewerComments)}
                      >
                        {getPercentageCompleteToDate(item, category, row, idx)}
                      </TableCell>
                      <TableCell
                        width={columns[8].width}
                        align="center"
                        className={getBorderClass(item.reviewerComments)}
                      >
                        {`${formatPrecision(roundHalf((item.percentageCompleteToDate - calculatePercentage((item.previouslyDisbursed || 0), item.cost))), 2)}% |
                        ${formatCurrency(roundHalf((item.percentageCompleteToDate / 100) * item.cost) - (item.previouslyDisbursed || 0), 0)}`}
                      </TableCell>
                      <TableCell
                        width={columns[9].width}
                        align="center"
                        id={`BORROWER_COMMENTS_${category?.name}_${row?.name}_${index + idx}`}
                        className={getBorderClass(item.reviewerComments)}
                      >
                        {store.addToIgnoreListCompletePdf(`BORROWER_COMMENTS_${category?.name}_${row?.name}_${index + idx}`)}
                        {columns[9].ignore && store.addToIgnoredList(`BORROWER_COMMENTS_${category?.name}_${row?.name}_${index + idx}`)}
                        {
                          item.comments ? (
                            <Chat className={classes.activeChatIcon} onClick={() => handleOpenCommentModal(item.comments)} />
                          ) :
                            <Chat className={classes.disabledChatIcon} />
                        }
                      </TableCell>
                      <TableCell
                        width={columns[10].width}
                        align="center"
                        id={`LENDER_APPROVED_DATA_${category?.name}_${row?.name}_${index + idx}`}
                        className={getBorderClass(item.reviewerComments)}
                      >
                        {columns[10].ignore && store.addToIgnoredList(`LENDER_APPROVED_DATA_${category?.name}_${row?.name}_${index + idx}`)}
                        {getLenderApprovedData(item, category, row, idx)}
                      </TableCell>
                      <TableCell
                        width={columns[11].width}
                        align="center"
                        id={`DISBURSEMENT_THIS_DRAW_${category?.name}_${row?.name}_${index + idx}`}
                        className={getBorderClass(item.reviewerComments)}
                      >
                        {columns[11].ignore && store.addToIgnoredList(`DISBURSEMENT_THIS_DRAW_${category?.name}_${row?.name}_${index + idx}`)}
                        {`${formatPrecision(roundHalf((item.loanFunderLLCApprovedPercentage - calculatePercentage((item.previouslyDisbursed || 0), item.cost))), 2)}% |
                        ${formatCurrency(roundHalf((item.loanFunderLLCApprovedPercentage / 100) * item.cost) - (item.previouslyDisbursed || 0), 0)}`}
                      </TableCell>
                    </TableRow>
                    {item.reviewerComments && <TableRow>
                      <TableCell
                        width={columns[1].width}
                        style={{ textAlign: 'left', borderTop: 0 }}
                        colSpan={12}
                        id={`REVIEWER'S_COMMENTS${category?.name}_${row?.name}_${index + idx}`}
                        align="left"
                      >
                        {true && store.addToIgnoredList(`REVIEWER'S_COMMENTS${category?.name}_${row?.name}_${index + idx}`)}
                        {getComments(item)}
                      </TableCell>
                    </TableRow>}
                  </>
                )
              })}
            </TableBody>
          );
        })}
      </Table>
      <StandardDialog
        open={openCommentModal}
        title="Borrower's Comments"
        maxWidth="sm"
        handleClose={() => setOpenCommentModal(false)}
        dialogContent={
          <CommentDialog
            onClose={() => setOpenCommentModal(false)}
            subTitle={"Borrower's Comment"}
            comment={comment}
          />
        }
      />
      <StandardDialog
        open={openReviewerComments}
        title="Reviewer Comments"
        maxWidth="sm"
        handleClose={() => setOpenReviewerComments(false)}
        dialogContent={
          <ReviewerCommentDialog
            onClose={() => setOpenReviewerComments(false)}
            subTitle={"Reviewer Comment"}
            props={currentItem}
            handleRowItemChange={handleRowItemChange}
          />
        }
      />
    </Grid>
  );
}
);
export default DrawReviewReportCategoryTable;