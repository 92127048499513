import { Box, Container, Grid } from '@material-ui/core';
import {
  Button,
  createNumberFormatField,
  createTextField,
  createSelectField
} from '@roc/ui';
import { observer } from 'mobx-react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useStore } from 'apps/client-portal-public/src/app/hooks';
import { stateOptions } from 'libs/client-portal-shared/src/app/modules/bridgePricer/utils/bridgePricerConstants';

const useStyles = makeStyles((theme: Theme) => {
  return {
    button: {
      width: '160px'
    },
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100%'
    }
  };
});

export const BankInformation = observer(() => {
  const classes = useStyles();
  const { referralAuthStore } = useStore();

  return (
    <Container maxWidth={'md'} disableGutters className={classes.container}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <BankInformationFields />
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center" alignItems={'center'}>
            <Button
              testId="start"
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={() => referralAuthStore.moveToDocusignStep()}
            >
              CONTINUE
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
});

const BankInformationFields = observer(() => {
  const { referralAuthStore } = useStore();

  return (
    <>
      <Grid container spacing={6}>
        <Grid item xs={12} md={6}>
          {createTextField({
            store: referralAuthStore,
            testId: 'bankName',
            fieldName: 'bankName',
            label: 'Bank Name',
          })}
        </Grid>
        <Grid item xs={12} md={6}>
          {createTextField({
            store: referralAuthStore,
            testId: 'accountName',
            fieldName: 'accountName',
            label: 'Bank Account Name',
          })}
        </Grid>
        <Grid item xs={12} md={6}>
          {createNumberFormatField({
            store: referralAuthStore,
            testId: 'routingNumber',
            fieldName: 'routingNumber',
            label: 'Routing Number',
            format: '#########',
          })}
        </Grid>
        <Grid item xs={12} md={6}>
          {createNumberFormatField({
            store: referralAuthStore,
            testId: 'accountNumber',
            fieldName: 'accountNumber',
            label: 'Bank Account Number',
          })}
        </Grid>
        <Grid item xs={12} sm={6}>
          {createTextField({
            label: 'Street Name',
            fieldName: 'streetName',
            testId: 'streetName',
            store: referralAuthStore,
          })}
        </Grid>
        <Grid item xs={12} sm={6}>
          {createTextField({
            label: 'City',
            fieldName: 'city',
            testId: 'city',
            store: referralAuthStore,
          })}
        </Grid>
        <Grid item xs={12} sm={6}>
          {createTextField({
            label: 'Zip Code',
            fieldName: 'zipCode',
            testId: 'zipCode',
            store: referralAuthStore,
          })}
        </Grid>
        <Grid item xs={12} sm={6}>
          {createSelectField({
            store: referralAuthStore,
            testId: 'state',
            fieldName: 'state',
            label: 'State',
            options: stateOptions,
          })}
        </Grid>
      </Grid>
    </>
  );
});
