import { Service } from "@roc/feature-app-core";

export const url = {
  GET_LEAD_METADATA_FROM_SF: '/salesforce-ui/api/v1/salesforce/lead/getLeadMetaData',
  GET_POSSIBLE_MATCHES: '/salesforce-ui/api/v1/salesforce/lead/possibleMatches',
  GET_COMPANY_NAMES: '/salesforce-ui/api/v1/salesforce/lead/getCompanyNames',
  SUBMIT_NEW_LEAD: '/salesforce-ui/api/v1/salesforce/lead/submitNewLead',
  SEARCH_BY_EMAIL: '/salesforce-ui/api/v1/salesforce/lead/getExistingLeadsByEmail',
  GET_BUSINESS_UNIT: '/salesforce-ui/api/v1/salesforce/lead/getBusinessUnit',
  ASSIGN_NEW_OWNER: '/salesforce-ui/api/v1/salesforce/lead/assignNewOwner',
  SAVE_LEAD_SEARCH_HISTORY: '/salesforce-ui/api/v1/salesforce/lead/saveLeadSearchHistorySearch',
  SAVE_LEAD_ASSIGNMENT_HISTORY: '/salesforce-ui/api/v1/salesforce/lead/saveLeadAssignmentHistory',
  CHECK_LEAD_SEARCH_HISTORY: '/salesforce-ui/api/v1/salesforce/lead/checkSearchesForUser',
  CHECK_LEAD_ASSIGNMENT_HISTORY: '/salesforce-ui/api/v1/salesforce/lead/checkLeadAssignmentsForUser',
  GET_LEADS: '/api/v1/lender/lead/getLeadsDashboardByUser',
  CONVERT_LEAD_TO_LENDER: '/api/v1/lender/lead/convertLeadToLender',
  GET_LEAD_DETAILS: '/api/v1/lender/lead/getLeadDetails',
  GET_LEAD_CONTACT_DETAILS: '/api/v1/lender/lead/getLeadContactDetails',
  EDIT_LEAD_DETAILS: '/api/v1/lender/lead/editLeadDetails',
  UPDATE_LEAD_STATUS_BY_ID: '/api/v1/lender/lead/updateLeadStatusById',
  EDIT_LEAD_CONTACT_DETAILS: '/api/v1/lender/lead/editLeadContactDetails',
  GET_ALL_LEADS_LISTS: '/api/v1/lender/dashboardBuilder/getAllDasboardListsForUser',
  GET_LEAD_LIST_BY_ID: '/api/v1/lender/lead/getLeadListById',
  REMOVE_LEAD_FROM_QUEUE: '/api/v1/lender/lead/removeLeadFromQueue',
  REMOVE_FROM_QUEUE: '/api/v1/lender/lead/removeFromQueue',
  MARK_LEAD_AS_CONTACTED: '/api/v1/lender/lead/markLeadAsContacted',
  GET_INCOMING_LEADS: '/api/v1/lender/lead/getIncomingLeadsByUser',
  GET_ALL_LEADS: '/api/v1/lender/lead/getAllLeadsDashboardByUser',
  GET_ALL_OWNERS: '/api/v1/lender/lead/getAllOwners',
  BULK_CHANGE_LEAD_OWNER: '/api/v1/lender/lead/bulkChangeOwner',
  BULK_CHANGE_BORROWER_OWNER: '/api/v1/lender/lead/changeBorrowerOwner',
  BULK_CHANGE_BROKER_OWNER: '/api/v1/lender/lead/changeBrokerOwner',
  BULK_CHANGE_STATUS: '/api/v1/lender/lead/bulkChangeStatus',
  GET_COMPANIES_CONTACTS: '/api/v1/user/getCompaniesContactsById',
  GET_LOAN_VOLUME: '/api/v1/lender/lead/getLoanVolume',
  AVAILABLE_FOR_NEW_OWNER: '/api/v1/lender/lead/recordIsAvailableForNewOwner',
  ASSIGN_NEW_OWNER_ROC_SERVICES: '/api/v1/lender/lead/assignNewOwner',
  SAVE_TAG: '/api/v1/lender/lead/saveTag',
  DELETE_TAG: '/api/v1/lender/lead/deleteTag',

};

export class NewLeadService extends Service {

  getCompanyNames() {
    return this.get(url.GET_COMPANY_NAMES);
  }

  getLeadMetadata(leadType: string) {
    return this.get(url.GET_LEAD_METADATA_FROM_SF, { leadType });
  }

  getBusinessUnit() {
    return this.get(url.GET_BUSINESS_UNIT);
  }

  saveLeadSearchHistory(searchDetails: string) {
    return this.get(url.SAVE_LEAD_SEARCH_HISTORY, {
      searchDetails
    })
  }

  saveLeadAssignmentHistory(leadAssigned: string) {
    return this.get(url.SAVE_LEAD_ASSIGNMENT_HISTORY, {
      leadAssigned
    })
  }

  getPossibleMatches(
    page: number,
    pageSize: number,
    sortDir: string,
    sortBy: string,
    filters: string,
    firstName: string,
    lastName: string,
    email: string,
    phoneNumber: string,
    companyName: string,) {
    return this.get(url.GET_POSSIBLE_MATCHES,
      {
        page,
        pageSize,
        sortDir,
        sortBy,
        filters,
        firstName,
        lastName,
        email,
        phoneNumber,
        companyName
      }
    );

  }

  submitNewLead(userDetails: any) {
    return this.post(url.SUBMIT_NEW_LEAD, userDetails);
  }

  verifyIfDetailsExists(
    email: string,
    page: number,
    pageSize: number,
    sortDir: string,
    sortBy: string,
    filters: string,
  ) {
    return this.get(url.SEARCH_BY_EMAIL, {
      email,
      page,
      pageSize,
      sortDir,
      sortBy,
      filters,
    })
  }

  assignOwner(recordId: string,
    businessUnit: string,
    objectType: string) {
    return this.get(url.ASSIGN_NEW_OWNER, {
      recordId,
      businessUnit,
      objectType
    })
  }

  getLeads(pageNumber: number, pageSize: number, sortBy: string, sortDir: string, filters: string, dropdownFilters: string, recordType: string, subStatus: string, businessUnit: string) {
    return this.get(url.GET_LEADS,
      { pageNumber, pageSize, sortBy, sortDir, filters, dropdownFilters, recordType, subStatus, businessUnit },
      { disableGlobalLoading: true }
    );
  }

  getIncomingLeads(pageNumber: number, pageSize: number, sortBy: string, sortDir: string, filters: string, dropdownFilters: string, allRecords: Boolean, businessUnit: string) {
    return this.get(url.GET_INCOMING_LEADS,
      { pageNumber, pageSize, sortBy, sortDir, filters, dropdownFilters, allRecords, businessUnit },
      { disableGlobalLoading: true }
    );
  }

  getLeadsListById(listId: string, pageNumber: number, pageSize: number, sortBy: string, sortDir: string, filters: string, dropdownFilters: string, businessUnit: string) {
    return this.get(url.GET_LEAD_LIST_BY_ID,
      { listId, pageNumber, pageSize, sortBy, sortDir, filters, dropdownFilters, businessUnit },
      { disableGlobalLoading: true }
    );
  }

  getAllLeads(pageNumber: number, pageSize: number, sortBy: string, sortDir: string, filters: string, dropdownFilters: string, businessUnit: string) {
    return this.get(url.GET_ALL_LEADS,
      { pageNumber, pageSize, sortBy, sortDir, filters, dropdownFilters, businessUnit },
      { disableGlobalLoading: true }
    );
  }

  convertLeadToLender(leadInfo) {
    return this.post(url.CONVERT_LEAD_TO_LENDER, leadInfo);
  }

  getLeadDetails(leadId: string, businessUnit: string) {
    return this.get(url.GET_LEAD_DETAILS, { leadId, businessUnit });
  }

  getLeadContactDetails(contactId: string, companyContactId: number) {
    return companyContactId ? this.get(url.GET_LEAD_CONTACT_DETAILS + "?contactId=" + contactId + "&companyContactId=" + companyContactId) : this.get(url.GET_LEAD_CONTACT_DETAILS + "?contactId=" + contactId);
  }

  updateLeadContactDetails(contactDetails) {
    return this.post(url.EDIT_LEAD_CONTACT_DETAILS, contactDetails);
  }

  updateLeadDetails(leadDetails) {
    return this.post(url.EDIT_LEAD_DETAILS, leadDetails);
  }

  updateLeadStatusById(leadDetails) {
    return this.post(url.UPDATE_LEAD_STATUS_BY_ID, leadDetails);
  }

  getAllLeadsLists() {
    return this.get(url.GET_ALL_LEADS_LISTS);
  }

  removeLeadFromQueue(leadId, removalReason, businessUnit) {
    return this.get(url.REMOVE_LEAD_FROM_QUEUE, { leadId, removalReason, businessUnit });
  }

  removeFromQueue(requestId, dashboardListId) {
    return this.put(`${url.REMOVE_FROM_QUEUE}?requestId=${requestId}&dashboardListId=${dashboardListId}`, null);
  }

  markLeadAsContacted(incomingLeadId) {
    return this.get(url.MARK_LEAD_AS_CONTACTED, { incomingLeadId });
  }

  getAllOwners() {
    return this.get(url.GET_ALL_OWNERS);
  }

  bulkChangeLeadOwner(request) {
    return this.post(url.BULK_CHANGE_LEAD_OWNER, request);
  }

  changeOwner(recordId, objectType, userId, isMoveNonFundedLoans = false, isMoveFundedLoans = false) {
    return this.get(url.ASSIGN_NEW_OWNER_ROC_SERVICES, { recordId, objectType, userId, isMoveNonFundedLoans, isMoveFundedLoans });
  }

  changeBorrowerOwner(request) {
    return this.post(url.BULK_CHANGE_BORROWER_OWNER, request);
  }

  changeBrokerOwner(request) {
    return this.post(url.BULK_CHANGE_BROKER_OWNER, request);
  }

  bulkChangeStatus(sendSMSRequest) {
    return this.post(url.BULK_CHANGE_STATUS, sendSMSRequest);
  }

  getCompaniesContacts(companyContactId: number | string) {
    return this.get(url.GET_COMPANIES_CONTACTS, { companyContactId });
  }

  getLoanVolume(leadId, type) {
    return this.get(url.GET_LOAN_VOLUME, { leadId, type });
  }

  isRecordAvailableForNewOwner(
    recordId: string,
    businessUnit: string,
    objectType: string,
  ) {
    return this.get(url.AVAILABLE_FOR_NEW_OWNER, {
      recordId,
      businessUnit,
      objectType
    });
  }

  public saveTag(request) {
    return this.post(url.SAVE_TAG, request);
  }

  public deleteTag(leadTagId: number) {
    return this.delete(url.DELETE_TAG, { leadTagId });
  }

}
