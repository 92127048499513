import React from 'react';
import {
  Box,
  Dialog,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  DialogTitle as MuiDialogTitle,
  IconButton,
  Typography,
  useMediaQuery,

} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Close } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import { WithStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { AddBrokerSteps } from './addBrokerSteps';
import { useBrokersStore } from '../hooks/useBrokersStore';
import { DialogState } from '@roc/ui';

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const styles = (theme: Theme) =>
  createStyles({
    dialogContetBox: {
      width: '100%',
      margin: 0,
      position: 'absolute',
      top: '50%',
      left: '50%',
      msTransform: 'translate(-50%, -50%)',
      transform: 'translate(-50%, -50%)',
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography {...other}>
      <Typography variant="h4">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <Close />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    height: '100%',
  },
}))(MuiDialogContent);

export default function BrokerDialogV2({
  open,
  handleClose,
}) {
  const { brokersStore } = useBrokersStore();
  const {
    selectBrokersStore,
    manageBrokersStore,
    brokersGridStore,
    brokerFeesStore,
  } = brokersStore;
  const { editDialogState } = selectBrokersStore;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleDialogClose = e => {
    handleClose(e);
  };

  return (
    <div>
      <Dialog
        disableEscapeKeyDown
        disableBackdropClick
        fullScreen={fullScreen}
        scroll={'paper'}
        fullWidth={true}
        maxWidth={'lg'}
        open={open}
        onClose={handleDialogClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle
          id="property-dialog-title"
          onClose={() => handleDialogClose(null)}
        >
          {editDialogState == DialogState.ADD ? `Add Broker` : `Edit Broker`}
        </DialogTitle>
        <DialogContent>
          <Box>
            <AddBrokerSteps />
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}