export const tooltips = {
  interestEconomics:
    'Rate/Points In/Your Points In\n' +
    'Rate - Interest Rate for the loan\n' +
    'Points In - Points received at the time of the funding the loan\n' +
    'Your Points In - Points received by you at the time of funding the loan',
  keyRatios:
    'Initial Loan Amount To Current Cost Anote/Construction Holdback To Renovation Budget/Total After Repair Loan To Value\n' +
    'Initial Loan Amount To Current Cost A-note - (Initial Loan Amount/Current Cost) * A-note percent\n' +
    'Construction Holdback To Renovation Budget - Total Construction Holdback/Total Renovation Budget\n' +
    'Total After Repair Loan To Value - Total Loan Amount/Total After Repair Value',
};
