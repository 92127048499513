import { ReactNode, useEffect, useState } from 'react';
import { Box, Grid, IconButton, Menu } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { formatDate } from '@roc/feature-utils';
import { DataGrid, GridColumnProps, SelectMode } from '@roc/ui';
import { formatCurrency } from '@roc/ui/utils';
import { observer } from 'mobx-react';
import { StatusDropdownGridEditor } from '../receivables/statusDropdownGridEditor';
import { MoreVert } from '@material-ui/icons';
import { StatusEditorAttestation } from './statusEditorAttestation';

const useStyles = makeStyles(theme => ({
  gridContainer: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    height: '350px',
    overflow: 'hidden',
  },
}));

const ListenForSelectedRow = observer(({ gridStore, params, recordsIds }) => {

  useEffect(() => {
    const selected = recordsIds.includes(params.node.loanId);
    params.node.setSelected(selected);
  }, [gridStore.gridData.meta?.pageNumber, recordsIds]);

  return <></>;
});

interface attestationDashboardGridInterface {
  dashboardStore: any;
  columns: GridColumnProps[];
  loanId?: any;
}

export const AttestationGrid = observer(({ dashboardStore, columns, loanId }: attestationDashboardGridInterface) => {
  const classes = useStyles();
  const { specialServicingGridStore } = dashboardStore;
  const [anchorEl, setAnchorEl] = useState<HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const [record, setRecord] = useState<any>({});

  const frameworkComponents = {
    dateCellRenderer: ({ value }) => {
      return value ? formatDate(value, 'MM/dd/yyyy') : '';
    },
    currencyCellRenderer({ value }) {
      return formatCurrency(value);
    },
    booleanCellRenderer({ value }) {
      return value ? 'Yes' : 'No';
    },
    actionCellRenderer: params => {
      return (
        <>
          <IconButton onClick={e => {
            openMenu(e, params.node.data)
          }}>
            <MoreVert color="primary" />
          </IconButton>
          <ListenForSelectedRow
            params={params}
            gridStore={specialServicingGridStore}
            recordsIds={loanId}
          />

        </>
      );
    },
    dropdownGridEditor: StatusDropdownGridEditor,
    statusEditorAttestation: StatusEditorAttestation,
  };

  const onSortChanged = params => {
    const sortModel = params.api.getSortModel();
    specialServicingGridStore.setSortModel(sortModel);
  };

  const onFilterChanged = params => {
    const filterModel = params.api.getFilterModel();
    specialServicingGridStore.setFilterModel(filterModel);
  };

  const openMenu = (event: React.MouseEvent<HTMLElement>, record) => {
    console.log('opening menu - record : ', record);
    setRecord(record);
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const onRowSelected = (params) => {
    specialServicingGridStore.setRecordsId(params?.data?.loanId, params?.node.selected);
  };

  return (
    <Box>
      <Grid item className={classes.gridContainer}>
        <DataGrid
          columns={columns}
          rows={specialServicingGridStore.gridData.data.rows}
          frameworkComponents={frameworkComponents}
          headerCheckboxSelection={true}
          suppressRowClickSelection={true}
          onSortChanged={onSortChanged}
          onFilterChanged={onFilterChanged}
          isLoading={specialServicingGridStore?.isLoading}
          showFilters={specialServicingGridStore?.showFilters}
          sortModel={specialServicingGridStore?.sortModel}
          filterModel={specialServicingGridStore?.filterModel}
          paginationData={specialServicingGridStore?.gridData.meta}
          setPageNumber={specialServicingGridStore?.setPageNumber}
          onRowSelected={onRowSelected}
        />
      </Grid>
      <Menu
        id="menu-appbar"
        getContentAnchorEl={null}
        anchorEl={anchorEl}
        keepMounted
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={menuOpen}
        onClose={handleMenuClose}
      >
      </Menu>
    </Box>
  );
});
