import { flow, makeObservable, action, observable } from 'mobx';
import { GlobalStore, ApiResponse } from '@roc/feature-app-core';
import { LoanStore } from '@roc/feature-loans';
import { PointOfContactFormStore } from './pointOfContactFormStore';
import { DrawService } from '../../services/drawService';

export class PointOfContactStore {
  globalStore: GlobalStore;
  loanStore: LoanStore;
  pointOfContactFormStore: PointOfContactFormStore;
  private drawService: DrawService;

  constructor(globalStore, loanStore) {
    this.globalStore = globalStore;
    this.loanStore = loanStore;
    this.drawService = new DrawService();
    this.pointOfContactFormStore = new PointOfContactFormStore(globalStore);
    makeObservable(this, {
      getPointOfContact: flow,
      resetStore: action,
    });
  }

  *getPointOfContact(loanId: string) {
    try {
      const response: ApiResponse = yield this.drawService.getPointOfContact(
        loanId
      );
      const data = response.data?.data || {};
      this.pointOfContactFormStore.form.fields.firstName.value = data?.contactFirstName || '';
      this.pointOfContactFormStore.form.fields.lastName.value = data?.contactLastName || '';
      this.pointOfContactFormStore.form.fields.phoneNumber.value = data?.contactPhone || '';
      this.pointOfContactFormStore.form.fields.email.value = data?.contactEmail || '';
      this.pointOfContactFormStore.form.fields.comments.value = data?.contactComment || '';
      this.pointOfContactFormStore.form.fields.inspectionProcess.value =  JSON.parse(data?.drawRequestJsonContent)?.inspectionProcess;
    } catch (e) {
      console.log(e);
    }
  }

  savePointOfContact(drawId: number, pointOfContact) {
    this.drawService.updatePointOfContact(drawId, pointOfContact);
  }

  resetStore = () => {
    this.pointOfContactFormStore.resetStore();
  }

}
