import { ApiResponse } from '@roc/client-portal-shared/services';
import { observable, action, flow, makeObservable } from 'mobx';
import { GlobalStore } from '@roc/client-portal-shared/stores';
import CdaDocumentStore from './cdaArrDocumentStore';
import { ArrRequest } from '../modules/arrPaymentModule/types/ArrRequest';
import { LoanTermsService } from '@roc/feature-loan-fasttrack';
import Appraisal1004dDocumentStore from './appraisal1004dDocumentStore';

export class Appraisal1004dPaymentsStore {
  private globalStore: GlobalStore;
  private loanTermsService: LoanTermsService;
  public appraisal1004dDocumentStore: Appraisal1004dDocumentStore;
  docusignStatus: boolean = false;
  paymentStatus: boolean = false;
  tamariskDocumentUrl: string = null;

  constructor(globalStore) {
    this.loanTermsService = new LoanTermsService();
    this.appraisal1004dDocumentStore = new Appraisal1004dDocumentStore(globalStore);
    makeObservable(this, {
      docusignStatus: observable,
      fetchDocusignAndPaymentStatus: flow,
      fetchARRDocument: flow,
      fetchCDADocument: flow,
      paymentStatus: observable,
      setDocusignStatus: action,
      setTamariskDocumentUrl: action,
      tamariskDocumentUrl: observable,
      reset: action,
      persistDataAfterDocusignComplete: flow,
    });
  }

  setDocusignStatus(status: boolean) {
    this.docusignStatus = status;
  }

  setTamariskDocumentUrl(url: string) {
    this.tamariskDocumentUrl = url;
  }

  *fetchDocusignAndPaymentStatus(entityId: string, type: string) {
    try {
      const response: ApiResponse = yield this.loanTermsService.getAppraisalPaymentStatus(
        entityId,
        type
      );
      const { data } = response;

      if (data) {
        this.docusignStatus = data.docusignStatus;
        this.paymentStatus = data.paymentStatus;
      }
      this.appraisal1004dDocumentStore.fetchBorrowerInfo(entityId, type);
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'There was an error while retrieving payment status',
      });
    }
  }

  *fetchARRDocument(
    request: ArrRequest
  ) {
    try {
      const response: ApiResponse = yield this.loanTermsService.getAppraisalRecertificationDocusign(
        request
      );
      const { data } = response;

      this.tamariskDocumentUrl = data ? data : null;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'There was an error while retrieving acknowledgment document',
      });
    }
  }

  *fetchCDADocument(
    request: ArrRequest
  ) {
    try {
      const response: ApiResponse = yield this.loanTermsService.getCDADocusign(
        request
      );
      const { data } = response;

      this.tamariskDocumentUrl = data ? data : null;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'There was an error while retrieving acknowledgment document',
      });
    }
  }

  *persistDataAfterDocusignComplete(
    id: string,
    cardholderName: string,
    cardholderEmail: string,
    tamariskFlowType: string,
  ) {
    const response: ApiResponse = yield this.loanTermsService.persistBorrowerData(
      id,
      cardholderName,
      cardholderEmail,
      tamariskFlowType,
    );
  }

  reset() {
    this.docusignStatus = false;
    this.paymentStatus = false;
    this.tamariskDocumentUrl = null;
  }
}

export default Appraisal1004dPaymentsStore;
