import { Box, Divider, Grid, List, ListItem, ListItemText, Typography, Checkbox, FormControlLabel, GridSize } from '@material-ui/core';
import { Theme, createStyles, darken, makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { CopyText, StandardDialog, Toolbar, FilterButton, TestDataAttribute } from '@roc/ui';
import { useLoanSummaryStore } from '../hooks/useLoanSummaryStore';
import { useParams } from 'react-router';
import { LoanSubType, LoanType } from '@roc/feature-utils';
import { formatCurrency, formatPercentage } from '@roc/ui/utils';
import { useLoanCommissionStore } from '../hooks/useCommissionStore';
import { useLoanStore } from '@roc/feature-loans';
import { Warning as WarningIcon } from '@material-ui/icons';
import { Paper } from '@roc/ui';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      background: '#fff'
    },
    paper: {
      marginBottom: theme.spacing(2),
    },
    title: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacing(1),
    },
    warningIcon: {
      color: '#F2BC16',
      fontSize: '20px',
      marginRight: theme.spacing(1),
    },
    divider: {
      margin: theme.spacing(2, 0),
    },
    commentContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      flexWrap: 'wrap',
    },
    approvalText: {
      '& .MuiFormControlLabel-label.Mui-disabled': {
        color: 'black'
      },
      marginBottom: '40px'
    },
  }),
);

const listItem = (label, value, icon = null) => {
  return (
    <ListItem>
      <ListItemText>
        <Box display="flex">
          {icon && <Box ml={1}>{icon}</Box>}
          <Box flexGrow={1} mr={1}>
            <Typography dangerouslySetInnerHTML={{ __html: label }} />
          </Box>
          <TestDataAttribute id={label}>
            <Box textAlign="right">{value}</Box>
          </TestDataAttribute>
        </Box>
      </ListItemText>
    </ListItem>
  );
};

const divider = <Divider variant="middle" component="li" />;

const formatDate = (dateString) => {
  const options = { year: 'numeric', month: '2-digit', day: '2-digit' } as const;
  return new Date(dateString).toLocaleDateString(undefined, options);
};

export const LoanSummaryDecisionTab = observer(({ md=10 }: {md?:GridSize}) => {
  const classes = useStyles();
  const { loanStore } = useLoanStore();
  const { exceptions, comments, mitigatingFactors, loanApprovalData } = loanStore;

  const noExceptions = !exceptions || exceptions.length === 0;
  const noComments = !comments || comments.length === 0;
  const noMitigatingFactors = !mitigatingFactors || mitigatingFactors === null;

  const getApprovalStateFromBoolean = (status) => {
    return status === 'Approved' ? true : false;
  }

  return (
    <>
      <Grid item xs={12} md={md}>
        <Paper title='COMMITTE APPROVAL' className={classes.paper}>
          <FormControlLabel
            control={
              <Checkbox checked={getApprovalStateFromBoolean(loanApprovalData?.decisionOne)} disabled={true} />
            }
            label={"Committee Approval 1"}
            labelPlacement='start'
            className={classes.approvalText}
          />
          <FormControlLabel
            control={
              <Checkbox checked={getApprovalStateFromBoolean(loanApprovalData?.decisionTwo)} disabled={true} />
            }
            label={"Committee Approval 2"}
            labelPlacement='start'
            className={classes.approvalText}
          />
          <FormControlLabel
            control={
              <Checkbox checked={getApprovalStateFromBoolean(loanApprovalData?.decisionThree)} disabled={true} />
            }
            label={"Partners Approval"}
            labelPlacement='start'
            className={classes.approvalText}
          />
        </Paper>
      </Grid>
      <Grid item xs={12} md={md}>
        <Paper title='EXCEPTIONS'>
          {noExceptions ? (
            <Typography variant="h6" align="center" style={{ marginBottom: '40px' }}>
              No records
            </Typography>
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <List>
                  {exceptions.map((exception, index) => (
                    <React.Fragment key={index}>
                      <ListItem>
                        <WarningIcon className={classes.warningIcon} />
                        <Typography>{exception}</Typography>
                      </ListItem>
                      {index < exceptions.length - 1 && divider}
                    </React.Fragment>
                  ))}
                </List>
              </Grid>
            </Grid>
          )}
        </Paper>
      </Grid>
      <br />
      <Grid item xs={12} md={md}>
        <Paper title='INTERNAL COMMENTS' className={classes.paper}>
          {noComments ? (
            <Typography variant="h6" align="center" style={{ marginBottom: '40px' }}>
              No records
            </Typography>
          ) : (
            <List>
              {comments.map((comment, index) => (
                <React.Fragment key={index}>
                  <ListItem>
                    {listItem(comment.comment, `${comment.createdBy} - ${comment.userRole.charAt(0).toUpperCase() + comment.userRole.slice(1)} - ${formatDate(comment.commentDate)}`)}
                  </ListItem>
                  {index < comments.length - 1 && <Divider variant="middle" component="li" />}
                </React.Fragment>
              ))}
            </List>
          )}
        </Paper>
      </Grid>
      <br />
      <Grid item xs={12} md={md}>
        <Paper title='MITIGATING FACTORS' className={classes.paper}>
          {noMitigatingFactors ? (
            <Typography variant="h6" align="center" style={{ marginBottom: '40px' }}>
              No records
            </Typography>
          ) : (
            <List>
              {listItem(mitigatingFactors, '')}
            </List>
          )}
        </Paper>
      </Grid>
    </>
  );
});