import { Badge } from '@material-ui/core';
import { AttachMoney, Chat, CloudUpload } from '@material-ui/icons';
import { TamariskPayment } from '@roc/feature-documents';
import {
  ActionsColumn,
  DataGrid as Grid,
  FileUpload,
  FilterButton,
  GridColumnProps,
  LinkColumn,
  SelectFilterComponent,
  SelectFloatingFilterComponent,
  StandardDialog,
  TextColumn,
  Toolbar,
} from '@roc/ui';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { useAppraisalsStore } from '../../hooks';
import { AppraisalFileUploadModal } from './appraisalFileUploadModal';

const APPRAISAL_PAYMENT_STANDALONE = 'Appraisal_Standalone';

const columns: GridColumnProps[] = [
  {
    field: 'propertyAddress',
    headerName: 'Property Address',
    minWidth: 300,
    cellRenderer: 'orderIdCellRenderer',
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'orderId',
    headerName: 'Order Id',
    minWidth: 110,
    cellRenderer: 'orderIdCellRenderer',
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'status',
    headerName: 'Status',
    minWidth: 120,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'orderCreationDate',
    headerName: 'Order Creation Date',
    minWidth: 170,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'originatorName',
    headerName: 'Originator',
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'actions',
    headerName: 'Actions',
    minWidth: 125,
    cellRenderer: 'actionsCellRenderer',
  },
];

export const StandaloneAppraisalsGrid = observer(
  ({
    onOpenCommunication,
    selectMode = null,
    onRowSelected = null,
    showActions = true,
    showFilterButton = false,
    domLayout = 'normal',
    onSelectAppraisal = null,
  }) => {
    const [currentAppraisal, setCurrentAppraisal] = useState(null);
    const [isFileUploadModalOpen, setIsFileUploadModalOpen] = useState(false);
    const [isTamariskPaymentModalOpen, setIsTamariskModalOpen] = useState(
      false
    );

    const { appraisalsStore } = useAppraisalsStore();

    const frameworkComponents = {
      selectFilter: SelectFilterComponent,
      selectFloatingFilter: SelectFloatingFilterComponent,
      orderIdCellRenderer: params => {
        if (!onSelectAppraisal) {
          return TextColumn(params);
        }

        return LinkColumn({
          ...params,
          url: '#',
          onClick: () => onSelectAppraisal(params.node.data),
        });
      },
      actionsCellRenderer: params =>
        ActionsColumn({
          ...params,
          actions: getActions(params),
        }),
    };

    const getActions = params => [
      {
        icon: (
          <Badge
            badgeContent={params.node.data?.unreadMessages}
            color="primary"
          >
            <Chat color="primary" />
          </Badge>
        ),
        onClick: () => onOpenCommunication(params.node.data),
      },
      {
        icon: <CloudUpload color="primary" />,
        onClick: () => openFileUploadModal(params.node.data),
      },
      {
        icon: <AttachMoney color="primary" />,
        onClick: () => openTamariskPaymentModal(params.node.data),
      },
    ];

    const openFileUploadModal = appraisal => {
      setCurrentAppraisal(appraisal);
      setIsFileUploadModalOpen(true);
    };

    const closeFileUploadModal = () => {
      setIsFileUploadModalOpen(false);
      setCurrentAppraisal(null);
    };

    const openTamariskPaymentModal = appraisal => {
      setCurrentAppraisal(appraisal);
      setIsTamariskModalOpen(true);
    };

    const closeTamariskPaymentModal = () => {
      setIsTamariskModalOpen(false);
      setCurrentAppraisal(null);
    };

    const onFilterChanged = params => {
      const filterModel = params.api.getFilterModel();
      appraisalsStore.standaloneAppraisalsGridStore.setFilterModel(filterModel);
    };

    const onUpload = (documentType: string, fileUpload: FileUpload) => {
      const {
        appraisalData: { propertyAppraisalId },
      } = currentAppraisal;
      appraisalsStore.uploadAppraisalDocument(
        propertyAppraisalId,
        false,
        documentType,
        fileUpload
      );
    };

    const onSortChanged = params => {
      const sortModel = params.api.getSortModel();
      appraisalsStore.standaloneAppraisalsGridStore.setSortModel(sortModel);
    };

    const getColumns = showActions => {
      let newColumns = [...columns];

      if (!showActions) {
        newColumns = [...columns.filter(col => col['field'] !== 'actions')];
      }

      return newColumns;
    };

    const toolbar = showFilterButton ? (
      <Toolbar>
        <FilterButton
          onToggleFilters={() =>
            appraisalsStore.standaloneAppraisalsGridStore.toggleFilters()
          }
          onClearFilters={() =>
            appraisalsStore.standaloneAppraisalsGridStore.resetFilters()
          }
        />
      </Toolbar>
    ) : undefined;

    return (
      <>
        <Grid
          columns={getColumns(showActions)}
          rows={
            appraisalsStore.standaloneAppraisalsGridStore.gridData.data.rows
          }
          toolbar={toolbar}
          frameworkComponents={frameworkComponents}
          onSortChanged={onSortChanged}
          onFilterChanged={onFilterChanged}
          isLoading={appraisalsStore.standaloneAppraisalsGridStore.isLoading}
          showFilters={
            appraisalsStore.standaloneAppraisalsGridStore.showFilters
          }
          selectMode={selectMode}
          onRowSelected={onRowSelected}
          sortModel={appraisalsStore.standaloneAppraisalsGridStore.sortModel}
          filterModel={
            appraisalsStore.standaloneAppraisalsGridStore.filterModel
          }
          domLayout={domLayout}
          paginationData={
            appraisalsStore.standaloneAppraisalsGridStore.gridData.meta
          }
          setPageNumber={
            appraisalsStore.standaloneAppraisalsGridStore.setPageNumber
          }
        />
        <AppraisalFileUploadModal
          open={isFileUploadModalOpen}
          values={currentAppraisal?.documentTypesList}
          onClose={closeFileUploadModal}
          onUpload={onUpload}
        />
        <StandardDialog
          open={isTamariskPaymentModalOpen}
          title="Tamarisk Payment"
          maxWidth="md"
          handleClose={closeTamariskPaymentModal}
          dialogContent={
            <TamariskPayment
              borrowersEmail={currentAppraisal?.borrowersEmail}
              tamariskURL={currentAppraisal?.tamariskUrl}
              entityId={currentAppraisal?.appraisalData.propertyAppraisalId}
              paymentStatus={currentAppraisal?.paymentStatus}
              type={APPRAISAL_PAYMENT_STANDALONE}
            />
          }
        />
      </>
    );
  }
);
