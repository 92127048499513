import { IconButton } from '@roc/ui';
import Edit from '@material-ui/icons/Edit';
import { observer } from 'mobx-react-lite';
import {
  DataGrid,
  GridColumnProps,
} from '@roc/ui';
import BorrowerEntityStore from '../../../stores/documentForms/borrowerEntity/borrowerEntityStore';

const membersColumns: GridColumnProps[] = [
  {
    field: 'fullName',
    headerName: 'Full Name',
  },
  {
    field: 'pctOwnership',
    headerName: 'Ownership percentage',
    cellRenderer: 'percentageCellRenderer',
  },
  {
    field: 'roleInEntity',
    headerName: 'Title',
    cellRenderer: 'textCellRenderer',
  },
  {
    field: 'authSignatory',
    headerName: 'Authorized Signatory',
  },
  {
    field: 'personalGuarantor',
    headerName: 'Personal Guarantor',
  },
  {
    field: 'stateForSigning',
    headerName: 'Signing State'
  },
  {
    field: 'countyForSigning',
    headerName: 'Signing County'
  },
  {
    headerName: 'Action',
    maxWidth: 100,
    cellRenderer: 'actionCellRenderer',
    width: 80,
    pinned: 'right',
    lockPinned: true
  },
];

interface BorrowerEntityGridProps {
  store: BorrowerEntityStore;
}

const BorrowerEntityGrid = observer((props: BorrowerEntityGridProps) => {
  const { store } = props;

  const frameworkComponents = {
    percentageCellRenderer({ value }) {
      if (value != null)
        return value + '%';
      else
        return '';
    },
    actionCellRenderer: params => {
      return (
        <IconButton
          aria-label="edit"
          testId={`edit-borrower-${params.node.data?.borrowerId}`}
          onClick={() => store.editBorrower(params.node.data)}
        >
          <Edit color="primary" />
        </IconButton>
      );
    },
  };

  return (
    <DataGrid
      columns={membersColumns}
      frameworkComponents={frameworkComponents}
      rows={store.entityBorrowersGridStore.gridData.data.rows}
      isLoading={store.entityBorrowersGridStore.isLoading}
      domLayout="autoHeight"
    />
  );
});

export default BorrowerEntityGrid;
