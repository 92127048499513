import {
  GlobalStore,
  GridStore,
} from '@roc/feature-app-core';
import { ApiResponse } from '@roc/feature-app-core';
import { action, makeObservable } from 'mobx';
import { AppraisalOrderDetailsService } from '../../services/documentForms/appraisalOrderDetailsService';

export class AppraisalOrderDetailsStore {
  private globalStore: GlobalStore;
  private appraisalOrderDetailsService: AppraisalOrderDetailsService;
  private loanId: number;
  private propertyId: number;
  private propertyAppraisalId: number;
  appraisalDetailsGridStore: GridStore;
  appraiserDetailsGridStore: GridStore;

  constructor(globalStore: GlobalStore) {
    this.globalStore = globalStore;
    this.appraisalOrderDetailsService = new AppraisalOrderDetailsService();
    this.appraisalDetailsGridStore = new GridStore(
      () => this.fetchPropertyAppraisals(),
      () => this.processPropertyAppraisals()
    );
    this.appraiserDetailsGridStore = new GridStore(() =>
      this.setPropertyAppraiserDetails()
    );
    makeObservable(this, {
      setLoanId: action,
      setPropertyId: action,
    });
  }

  private async fetchPropertyAppraisals() {
    try {
      const response: ApiResponse = this.propertyAppraisalId
        ? await this.appraisalOrderDetailsService.getPropertyAppraisalById(
          this.propertyAppraisalId,
        )
        : await this.appraisalOrderDetailsService.getPropertyAppraisal(
          this.loanId,
          this.propertyId
        );
      const newResponse = {
        ...response,
        data: {
          ...response.data,
          data: {
            rows: JSON.parse(response.data?.data),
          },
        },
      };
      return newResponse;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching property appraisal details.',
      });
    }
  }

  private async processPropertyAppraisals() {
    this.appraisalDetailsGridStore.gridData.data.rows = this.appraisalDetailsGridStore.gridData.data.rows.map(
      x => this.processPropertyAppraisal(x)
    );
  }

  private processPropertyAppraisal(row) {
    const appraisalOrderJson = JSON.parse(
      row.propertyAppraisalOrderJson || null
    );

    return {
      ...row,
      orderId: appraisalOrderJson?.orderId,
      propertyAddress: appraisalOrderJson?.property?.address,
      additionalAppraisalDetailsJson: row.additionalAppraisalDetailsJson,
    };
  }

  private async setPropertyAppraiserDetails() {
    const rows = this.appraisalDetailsGridStore.gridData.data.rows
      .filter(x => x.additionalAppraisalDetailsJson)
      .map(x => this.processPropertyAppraiser(x));

    const response: ApiResponse = {
      data: {
        data: {
          rows,
        },
      },
      headers: null,
    };
    return response;
  }

  private processPropertyAppraiser(row) {
    return {
      ...row.additionalAppraisalDetailsJson?.appraiserDetails,
      orderId: row?.orderId,
    };
  }

  setLoanId(loanId) {
    this.loanId = loanId;
  }

  setPropertyId(propertyId) {
    this.propertyId = propertyId;
  }

  setPropertyAppraisalId(propertyAppraisalId) {
    this.propertyAppraisalId = propertyAppraisalId;
  }
}
