import { Avatar as ChatUiAvatar, Conversation, ConversationList } from "@chatscope/chat-ui-kit-react";
import { lighten, makeStyles } from "@material-ui/core/styles";
import { AlternateEmail } from "@material-ui/icons";
import { ConversationExpandableMenu } from "./conversationExpandableMenu";
import { getIcon, getLastMessageAuthor, getMessagePreviewText } from "./helper";
import { CommunicationStore } from "./stores/communicationStore";
import { TechSupportCommunicationStore } from "./stores/techSupportCommunicationStore";
import { ChatRoomType, ChatRoomStatus } from "./types/communicationTypes";
import { observer } from 'mobx-react';
import { BorrowerIndicatorIcon } from "@roc/ui";

interface Items {
  title: string;
  conversations: any;
  messages: any;
  communicationStore: CommunicationStore;
  chatRoomTypes?: Array<String>;
  chatRoomStatus?: Array<ChatRoomStatus>;
  onConversationSelect: any;
  classes: any;
}

const useStyles = makeStyles(theme => ({
  conversationAvatar: {
    background: '#EEE',
    padding: 4,
  },
  conversation: {
    '& .cs-conversation__unread': {
      top: 13,
      right: 4,
      borderRadius: 16,
      padding: theme.spacing(0, 1),
      backgroundColor: '#7fadea'
    },
    '&:hover': {
      backgroundColor: '#f3f3f3',
    },
    '&.cs-conversation.cs-conversation--active, &.cs-conversation.cs-conversation:active': {
      backgroundColor: lighten(theme.palette.primary.main, 0.9),
    },
  },
  mention: {
    position: 'absolute',
    right: 36,
    top: 12,
    fontSize: 22,
    color: '#7fadea'
  }
}));

export const ConversationSections = observer(({
  title,
  conversations,
  messages,
  communicationStore,
  chatRoomTypes,
  chatRoomStatus,
  onConversationSelect,
}: Items) => {
  const classes = useStyles();
  const {
    currentConversationSid
  } = communicationStore;
  const isBorrowerUser = communicationStore.globalStore.isBorrowerUser;

  return (
    <ConversationExpandableMenu
      title={title}
      defaultOpen={chatRoomStatus?.includes(ChatRoomStatus.ARCHIVED) ? false : true}
      children={
        <ConversationList>
          {
            Object.keys(conversations)?.map((cSid) => {
              const c = conversations[cSid];
              const chatRoomType = conversations[cSid].attributes.chatRoomType;
              const conversationStatus = communicationStore.conversationChatRoomStatus[cSid];

              const isChatRoomTypeIncluded = chatRoomTypes?.includes(chatRoomType);
              const isChatRoomActive = conversationStatus === ChatRoomStatus.ACTIVE;
              const isChatRoomArchived = chatRoomStatus?.includes(conversationStatus);

              if ((isChatRoomTypeIncluded && isChatRoomActive) || isChatRoomArchived) {
                const lastMessageAuthor = getLastMessageAuthor(messages[c.sid], []);
                const messagePreviewText = getMessagePreviewText(messages[c.sid], []);
                let unreadCnt;
                if (communicationStore instanceof TechSupportCommunicationStore) {
                  unreadCnt = communicationStore.isConversationReadOnly(c.sid) ? 0 : communicationStore.unreadTechSupportMessagesCount[c.sid];
                } else {
                  unreadCnt = communicationStore.isConversationReadOnly(c.sid) ? 0 : communicationStore.unreadMessagesCount[c.sid];
                }

                return (
                  <Conversation
                    key={c.sid}
                    name={<>
                      {communicationStore.isBorrowerRoom(c) && !isBorrowerUser && <BorrowerIndicatorIcon />}
                      {communicationStore.getConversationDisplayName(c)}
                    </>}
                    lastSenderName={lastMessageAuthor ? "Last Message From" : undefined}
                    info={lastMessageAuthor ? lastMessageAuthor : messagePreviewText}
                    unreadCnt={unreadCnt}
                    onClick={() => {
                      if (c.sid != currentConversationSid) {
                        communicationStore.clearCurrentConversation();
                      }
                      onConversationSelect && onConversationSelect(c.sid);
                      communicationStore.setCurrentConversation(c);
                    }}
                    active={c.sid == currentConversationSid}
                    lastActivityTime={communicationStore.conversationMentioned[c.sid] ? <AlternateEmail className={classes.mention} /> : undefined}
                    className={classes.conversation}>
                    <ChatUiAvatar src={getIcon(c)} className={classes.conversationAvatar} />
                  </Conversation>
                )
              }
            })
          }
        </ConversationList>
      }
    />
  );
});