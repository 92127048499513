import { StandardDialog } from "@roc/ui";
import { observer } from "mobx-react";
import { useProposalsStore } from "../hooks";
import { Link as RouterLink } from 'react-router-dom';
import {
  Grid,
  Typography,

  Button,
} from '@material-ui/core';
import { useRoutes, useStore } from "@roc/client-portal-shared/hooks";
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';

export const DealRoomPopup = observer(() => {
  const { proposalsRoutesConfig } = useRoutes();
  const useStyles = makeStyles((theme) =>
    createStyles({
      buttonContainer: {
        marginTop: 32,
      },
      container: {
        display: 'flex',
        flexDirection: 'column',
      },
      gridContainer: {
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        height: '50vh',
        overflow: 'hidden',
      },
      paragraph: {
        marginLeft: '2em',
      },
    })
  );
  const { proposalsStore } = useProposalsStore();
  const { globalStore } = useStore();
  const classes = useStyles();
  return (
    <StandardDialog
      open={proposalsStore.showDealRoomPopup}
      title="Deal Room"
      maxWidth="md"
      handleClose={() => proposalsStore.setShowDealRoomPopup(false)}
      dialogContent={
        <Grid container spacing={2} className={classes.container}>
          <Grid item>
            <Typography variant="h6" >
              {<p>Deal Room is designed to prescreen outside of the box or larger loans directly with committee. Deal Room should not be used for down the middle, or in the box loans. With a Deal Room submission please clearly outline the details of the project, why it is being shown in Deal Room (explain the exception or why it's outside of the box), and any other relevant information. Please also include:
                <br /><br /></p>}
            </Typography>
            <Typography variant="h6" >
              {
                <ul className={classes.paragraph}>
                  <li>Borrower's SREO and track record</li>
                  <li>Credit report (if available)</li>
                  <li>Appraisal (if available) </li>
                  <li>Any additional supporting documents</li>
                  <br />
                  <br />
                </ul>

              }
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.buttonContainer}>
            <Grid container justifyContent="center" spacing={2}>
              <Grid item xs={6} md={2}>
                <Button
                  onClick={() => { proposalsStore.setShowDealRoomPopup(false) }}
                  color="primary"
                  size="large"
                  variant="outlined"
                  fullWidth
                >
                  CANCEL
                </Button>
              </Grid>
              <Grid item xs={6} md={2}>
                <Button
                  onClick={() => { proposalsStore.setShowDealRoomPopup(false) }}
                  color="primary"
                  size="large"
                  variant="outlined"
                  fullWidth
                >
                  <RouterLink
                    to={globalStore.isBackOfficeView ? proposalsRoutesConfig.proposalsBackofficeHome.url : proposalsRoutesConfig.proposalsHome.url}
                    onClick={() => { proposalsStore.setShowDealRoomPopup(false) }}
                  >
                    <Typography align="center" >
                      Continue
                    </Typography>
                  </RouterLink>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }
    />
  );
});