import { useBaseStore } from '@roc/feature-app-core';
import { getInternalAppsRoutesConfig, getInternalAppsRoutes } from '../routes/appsRoutes';

export const useInternalAppsRoutes = () => {
  const { globalStore } = useBaseStore();
  const { routeBasePath, routeBaseUrl } = globalStore;

  return {
    appsRoutes: getInternalAppsRoutes(routeBasePath, routeBaseUrl),
    appsRoutesConfig: getInternalAppsRoutesConfig(routeBasePath, routeBaseUrl),
  };
};