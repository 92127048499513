import { Service } from '@roc/feature-app-core';

const url = {
  SAVE_FLOOD_EXCEPTION_REASON:
    '/api/v1/document/formDocument/saveFloodExceptionReason',
};

export class ExceptionsService extends Service {
  saveFloodExceptionReason = (loanId: string, reason: string) => {
    return this.put(url.SAVE_FLOOD_EXCEPTION_REASON, {
      loanId,
      floodExceptionReason: reason,
    });
  };
}
