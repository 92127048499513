import { Box, ButtonGroup, Grid, TextField } from '@material-ui/core';
import { Add, DashboardOutlined, ListOutlined } from '@material-ui/icons';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';
import { Button, MultiSelectField } from '@roc/ui';
import { observer } from 'mobx-react';
import { useGeneralTasksStore } from '../hooks/useGeneralTasksStore';
import { GeneralTasksView, TaskStatus } from '../types/generalTasksTypes';
import { makeStyles } from '@material-ui/core/styles';
import { GeneralTasksToolbarFilters } from './generalTasksToolbarFilters';

const useStyles = makeStyles(theme => ({
  selectView: {
    '& .MuiToggleButton-sizeSmall': {
      paddingTop: '3px',
      paddingBottom: '3px',
    },
  },
}));

export const GeneralTasksToolbar = observer(() => {
  const classes = useStyles();
  const { generalTasksStore } = useGeneralTasksStore();
  const { editTaskStore } = generalTasksStore;
  const { view, filters } = generalTasksStore;

  const handleViewChange = (e, value) => {
    generalTasksStore.setView(value);
  };

  return (
    <Grid container spacing={2} alignItems="flex-end">
      {/* <Grid item>
        <ToggleButtonGroup
          className={classes.selectView}
          value={view}
          exclusive
          size="small"
          onChange={handleViewChange}
        >
          <ToggleButton value={GeneralTasksView.LIST}>
            <ListOutlined />
          </ToggleButton>
          <ToggleButton value={GeneralTasksView.KANBAN}>
            <DashboardOutlined />
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid> */}
      <Grid item>
        <GeneralTasksToolbarFilters />
      </Grid>
    </Grid>
  );
});
