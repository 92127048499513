import { Grid, Typography } from '@material-ui/core';
import { Layout } from '@roc/client-portal-shared/components';
import { Button, Paper, SelectField } from '@roc/ui';
import { loanSubTypeOptions } from '../utils/constants';
import { useHistory } from 'react-router';
import { routeByLoanSubType } from '@roc/feature-loans';
import { selectLoanTypeModalBg } from '../assets';
import { isBlank } from '@roc/feature-utils';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import { useBorrowerLoanSubmissionStore } from '../hooks/useBorrowerLoanSubmissionStore';
import { PublicLoanSubmissionStore } from '../stores';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(4),
    borderRadius: theme.spacing(2),
    boxShadow:
      'rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px',
  },
  title: {
    fontSize: '48px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '116.7%',
    padding: theme.spacing(2),
  },
  subTitle: {
    fontSize: '34px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '123.5%',
    letterSpacing: '0.25px',
  },
  body: {
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '175%',
    letterSpacing: '0.15px',
  },
  headerImg: {
    width: '100%',
    height: 120,
    objectFit: 'cover',
  },
}));

export const LandingPage = observer(({ currentRoutes }) => {
  const classes = useStyles();
  const { push } = useHistory();
  const { borrowerLoanSubmissionStore } = useBorrowerLoanSubmissionStore();
  const publicLoanSubmissionStore = borrowerLoanSubmissionStore as PublicLoanSubmissionStore;

  return (
    <Layout maxWidth='md'>
      <Typography variant="h3" className={classes.title}>
        Apply for a Loan
      </Typography>
      <Paper className={classes.paper}>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h4" className={classes.subTitle}>
              Welcome to the Loan Submission Process
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <img src={selectLoanTypeModalBg} className={classes.headerImg} />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5" className={classes.body}>
              Thank you for considering us for your real estate investment
              financing needs.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5" className={classes.body}>
              Here, you can start a new loan submission on your own. Provide
              some basic information to get your new loan started. Begin by
              selecting the type of loan you need from the dropdown below, then
              click “Start”.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <SelectField
              label={'Select a Loan Type'}
              value={publicLoanSubmissionStore.loanSubtype}
              required={true}
              options={loanSubTypeOptions}
              onChange={value => {
                publicLoanSubmissionStore.setLoanSubtype(value);
              }}
              variant={'outlined'}
              fullWidth
              testId={'loanSubType'}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              fullWidth
              onClick={() => {
                push(
                  currentRoutes.children[
                    routeByLoanSubType[publicLoanSubmissionStore.loanSubtype]
                  ].url
                );
              }}
              color="primary"
              variant="contained"
              testId="save"
              disabled={isBlank(publicLoanSubmissionStore.loanSubtype)}
            >
              Start
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Layout>
  );
});
