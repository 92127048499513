import { FormStore, UserStore } from '@roc/feature-app-core';
import { GlobalStore } from '@roc/feature-app-core';
import {
  LoanType,
  formatDate,
  formatDateIgnoreTimezone,
  getAddressFromFields,
  parseAddress,
  parseDate,
} from '@roc/feature-utils';
import { action, computed, flow, makeObservable, observable, toJS } from 'mobx';
import { QuoteGeneratorBaseService } from '../services/quoteGeneratorBaseService';
import { BridgePricerService } from '@roc/client-portal-shared/modules/bridgePricer';
import { borrowerRateOptions, LoaSubtypeOptions } from 'libs/client-portal-shared/src/app/modules/bridgePricer/utils/bridgePricerConstants';
import { ClientBridgePricerService } from 'libs/client-portal-shared/src/app/modules/bridgePricer/services/clientBridgePricerService';
import { mapNumberOfUnits } from 'libs/client-portal-shared/src/app/modules/internalPricer/utils/internalPricerUtils';

const borrowerForm = {
  fields: {
    quoteId: {
      value: null,
      error: null,
      rule: '',
    },
    propertyAddress: {
      value: '',
      error: null,
      rule: 'required|valid_address:streetNumber,streetName,city,state,zipCode',
      message: 'A valid address is required',
    },
    streetNumber: {
      value: '',
      error: null,
      rule: '',
    },
    streetName: {
      value: '',
      error: null,
      rule: '',
    },
    city: {
      value: '',
      error: null,
      rule: '',
    },
    state: {
      value: '',
      error: null,
      rule: '',
    },
    zipCode: {
      value: '',
      error: null,
      rule: '',
    },
    loanSubtype: {
      value: 'Fix and Flip (1-4)',
      error: null,
      rule: 'required',
      message: 'The loan program is required',
    },
    loanPurpose: {
      value: '',
      error: null,
      rule: 'required',
      message: 'The loan purpose is required',
    },
    acquisitionPrice: {
      value: '',
      error: null,
      rule: 'required',
      message: 'The acquisition price is required',
    },
    asIsValue: {
      value: '',
      error: null,
      rule: 'required',
      message: 'The as is value is required',
    },
    renoBudget: {
      value: '',
      error: null,
      rule: 'required',
      message: 'The renovation budget is required',
    },
    completedCapitalImprovements: {
      value: '',
      error: null,
      rule: '',
    },
    afterRepairValue: {
      value: '',
      error: null,
      rule: 'required',
      message: 'The after repair value is required',
    },
    propertyType: {
      value: '',
      error: null,
      rule: 'required',
      message: 'The property type is required'
    },
    purchaseDate: {
      value: '',
      error: null,
      rule: [{ required_if: ['loanPurpose', 'Refinance'] }],
      message: 'The purchase date is required',
    },
    numberOfUnits: {
      value: '',
      error: null,
      rule: '',
    },
    borrowerFirstName: {
      value: '',
      error: null,
      rule: '',
    },
    borrowerLastName: {
      value: '',
      error: null,
      rule: '',
    },
    useBorrowerEntity: {
      value: false,
      error: null,
      rule: '',
    },
    isRealEstateAgent: {
      value: false,
      error: null,
      rule: '',
    },
    borrowerEntity: {
      value: '',
      error: null,
      rule: '',
    },
    estimatedFicoScore: {
      value: 660,
      error: null,
      rule: '',
    },
    experienceScore: {
      value: 1,
      error: null,
      rule: '',
    },
    initialLTCPercentage: {
      value: 70,
      error: null,
      rule: 'required',
      message: 'The inital loan LTC is required',
    },
    constructionHoldbackPercentage: {
      value: 100,
      error: null,
      rule: 'required',
      message: 'The construction holdback is required',
    },
    borrowerRate: {
      value: '',
      error: null,
      rule: 'required',
      message: 'The borrower rate is required',
    },
    borrowerPoints: {
      value: '',
      error: null,
      rule: '',
    },
    loanTermsMonths: {
      value: '',
      error: null,
      rule: '',
    },
    exitStrategy: {
      value: '',
      error: null,
      rule: 'required',
      message: 'The exit strategy is required'
    },
    interestRate: {
      value: '',
      error: null,
      rule: '',
    },
    payoffAmount: {
      value: '',
      error: null,
      rule: '',
    },
    loanClosingDate: {
      value: '',
      error: null,
      rule: '',
    },
    points: {
      value: '',
      error: null,
      rule: '',
    },
    mortgageTax: {
      value: 0,
      error: null,
      rule: '',
    },
    transferTax: {
      value: 1,
      error: null,
      rule: '',
    },
    mansionTax: {
      value: 0,
      error: null,
      rule: '',
    },
    titleInsurance: {
      value: 1,
      error: null,
      rule: '',
    },
    brokerFee: {
      value: 4,
      error: null,
      rule: '',
    },
    brokerName: {
      value: '',
      error: null,
      rule: '',
    },
    propertyTaxAndMaintenance: {
      value: 1,
      error: null,
      rule: '',
    },
    exitLoanRate: {
      value: 6.5,
      error: null,
      rule: '',
    },
    monthlyRent: {
      value: '',
      error: null,
      rule: '',
    },
    annualTaxes: {
      value: '',
      error: null,
      rule: '',
    },
    annualHOADues: {
      value: '',
      error: null,
      rule: '',
    },
    annualInsurance: {
      value: '',
      error: null,
      rule: '',
    },
    fpi: {
      value: '',
      error: null,
      rule: '',
    },
    processingFee: {
      value: '',
      error: null,
      rule: '',
    },
    interestReserveMonths: {
      value: 1,
      error: null,
      rule: 'numeric|min:1',
      message: 'Minimum value is 1'
    },
    underwritingFee: {
      value: '',
      error: null,
      rule: '',
    },
    initialLoanAmount: {
      value: '',
      error: null,
      rule: '',
    },
    constructionHoldback: {
      value: '',
      error: null,
      rule: '',
    },
    totalLoanAmount: {
      value: '',
      error: null,
      rule: '',
    },
    lender: {
      value: '',
      error: null,
      rule: [{ required_if: ['isInternal', true] }],
      message: 'The lender is required',
    },
    lenderId: {
      value: '',
      error: null,
      rule: '',
    },
    timeToFlip: {
      value: '',
      error: null,
      rule: '',
    },
    brokerPointsAtOrigination: {
      value: '',
      error: null,
      rule: '',
    },
    latitude: {
      value: '',
      error: null,
      rule: '',
    },
    longitude: {
      value: '',
      error: null,
      rule: '',
    },
    borrowerFloorRate: {
      value: '',
      error: null,
      rule: 'required',
    },
    submissionDate: {
      value: new Date(),
      error: null,
      rule: 'required',
    },
    isInternal: {
      value: false,
      error: null,
      rule: '',
    }
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export interface QuoteDetails {
  quoteId: number;
}

export interface QuoteCalculationDetails {
  bridgeProfitAndDscr: any;
  bridgePricerResponseFields: any;
  validationResponse: any;
  bridgeClosingCostsResponseFields: any;
  calculatedFields: any;
}

export interface BrokerEconomicsSettings {
  rateFloor: number;
  originationPoints: number;
  processingFee: number;
  underwritingFee: number;
}

export class QuoteGeneratorDetailsStore extends FormStore {
  private globalStore: GlobalStore;
  private userStore: UserStore;
  private quoteGeneratorService: QuoteGeneratorBaseService;
  private bridgePricerService: BridgePricerService;
  private clientBridgePricerService: ClientBridgePricerService;

  quoteId: number;
  quoteCalculationDetails: QuoteCalculationDetails;
  quotePrescreenDetails: any;
  chartData: any[];
  isInternal: boolean;
  allLenderOptions: any = [];
  brokerEconomicsSettings: BrokerEconomicsSettings;
  borrowerRateOptions: any[];
  isPrescreenLoading: boolean;
  calculationStarted: boolean;
  borrowerPointsOptions: any = [];
  loanSearchText: string = '';
  loanSearchOptions: { label: string; value: string }[] = [];

  constructor(globalStore, userStore, quoteGeneratorService: QuoteGeneratorBaseService, isInternal: boolean) {
    super({ ...borrowerForm }, globalStore);
    this.globalStore = globalStore;
    this.userStore = userStore;
    this.quoteGeneratorService = quoteGeneratorService;
    this.bridgePricerService = new BridgePricerService();
    this.clientBridgePricerService = new ClientBridgePricerService();
    this.quoteCalculationDetails = {
      bridgeProfitAndDscr: {},
      bridgePricerResponseFields: {},
      validationResponse: {},
      bridgeClosingCostsResponseFields: {},
      calculatedFields: {}
    };
    this.chartData = [
      {
        'borrowerRate': '0',
        'yourYieldSpread': 0.0,
      }
    ];
    this.allLenderOptions = [];
    this.isInternal = isInternal;
    this.isPrescreenLoading = false;
    this.calculationStarted = false;
    makeObservable(this, {
      resetStore: action,
      fetchQuote: flow,
      saveQuote: flow,
      calculateQuote: flow,
      getQuotePrescreen: flow,
      quoteCalculationDetails: observable,
      chartData: observable,
      isInternal: observable,
      allLenderOptions: observable,
      getAllLenderIdNames: flow,
      totalCosts: computed,
      totalProfitOnFlip: computed,
      lenderName: computed,
      brokerEconomicsSettings: observable,
      fetchBrokerEconomicsSettings: flow,
      borrowerRateOptions: observable,
      isPrescreenLoading: observable,
      calculationStarted: observable,
      borrowerPointsOptions: observable,
      getBorrowerPointsOptions: flow,
      fetchRateFloor: flow,
      loanSearchText: observable,
      loanSearchOptions: observable,
      fetchOptions: flow,
      fetchLoanValues: flow,
      getAutocompleteOptions: flow,
    });
  }

  resetStore() {
    this.quoteId = null;
    this.reset();
    this.quoteCalculationDetails = {
      bridgeProfitAndDscr: {},
      bridgePricerResponseFields: {},
      validationResponse: {},
      bridgeClosingCostsResponseFields: {},
      calculatedFields: {},
    };
    this.brokerEconomicsSettings = {
      rateFloor: 0,
      originationPoints: 0,
      processingFee: 0,
      underwritingFee: 0,
    }
    this.quotePrescreenDetails = {};
    this.loanSearchText = '';
    this.calculationStarted = false;
    this.isPrescreenLoading = false;
    this.allLenderOptions = [];
  }

  *fetchQuote(quoteId) {
    try {
      const response = yield this.quoteGeneratorService.getQuoteById(quoteId);
      this.quoteId = quoteId;
      const data = response.data.data;
      this.loadForm({
        ...data,
        purchaseDate: parseDate(data.purchaseDate),
        loanClosingDate: parseDate(data.loanClosingDate),
        propertyAddress: getAddressFromFields(
          data.streetNumber,
          data.streetName,
          null,
          data.city,
          data.state,
          data.zipCode
        ),
        constructionHoldbackPercentage: Number(data.constructionHoldbackPercentage ?? 0),
      });
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching quote details',
      });
    }
  }

  *saveQuote() {
    try {
      const quoteData = this.getFormValues();
      const response = yield this.quoteGeneratorService.saveQuote({
        ...quoteData,
        purchaseDate: formatDateIgnoreTimezone(quoteData.purchaseDate),
        loanClosingDate: formatDateIgnoreTimezone(quoteData.loanClosingDate),
        quoteId: this.quoteId,
      });
      const data = response.data.data;
      this.quoteId = data.quoteId;
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while saving quote',
      });
    }
  }

  *fetchOptions(searchText: string) {
    try {
      if (!searchText) {
        this.loanSearchOptions = [];
        return;
      }
      const options = yield this.getAutocompleteOptions(searchText);
      this.loanSearchOptions = options;
    } catch (e) {
      console.log(e);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'An error occurred while loading the list of loans.',
      });
    }
  }

  *getAutocompleteOptions(searchTerm: string) {
    const filters = { dealName: searchTerm };
    let response = null;
    let rows = null;
    if (this.isInternal) {
      response = yield this.bridgePricerService.getBridgeDealNames(
        1,
        25,
        null,
        null,
        filters
      );
      rows = response.data.rows;
    } else {
      response = yield this.clientBridgePricerService.getBridgeDealNames(
        1,
        25,
        null,
        null,
        filters
      );
      rows = response.data.data.rows;
    }
    return rows.map(row => ({
      label: row.dealName,
      value: row.dealid,
    }));
  }

  async *getBorrowerPointsOptions(isInternal) {
    let response = [];
    if (isInternal) {
      response = yield this.bridgePricerService.getBorrowerPointsOptions(
        this.form.fields['lenderId'].value,
        this.form.fields['loanTermsMonths'].value,
        formatDate(this.form.fields['submissionDate'].value, 'MM/dd/yyyy'),
      ).then(r => r.data);
    } else {
      response = yield this.clientBridgePricerService.getBorrowerPointsOptions(
        this.globalStore.lenderInfo?.lenderId,
        this.form.fields['loanTermsMonths'].value,
        formatDate(this.form.fields['submissionDate'].value, 'MM/dd/yyyy'),
      ).then(r => r.data);
    }
    this.borrowerPointsOptions = response;
    if (!this.form.fields['borrowerPoints'].value && response && (response?.length || 0) > 0) {
      this.onFieldChange('borrowerPoints', Number(response[0].value));
    }
    return response;
  }

  async *fetchRateFloor() {
    const data = {
      "submissionDate": this.form.fields['submissionDate'].value,
      "lenderName": this.form.fields['lender'].value?.label || '',
      "propertyState": this.form.fields['state'].value,
      "loanSubtype": this.form.fields['loanSubtype'].value,
    };

    let extraPayload = {};
    if (this.form.fields.loanSubtype.value === LoaSubtypeOptions.GROUNDUP && this.globalStore.lenderInfo?.isInternal) {
      extraPayload = {
        rateAdjustmentsRequestFields: {
          experience: this.form.fields.experienceScore.value,
          fico: this.form.fields.estimatedFicoScore.value,
          transactionType: this.form.fields.loanPurpose.value,
          completedRenovations: this.form.fields.completedCapitalImprovements.value,
          loanTerm: this.form.fields.loanTermsMonths.value,
          totalRenoBudget: this.form.fields.renoBudget.value,
          leverage: this.form.fields.initialLTCPercentage.value,
        },
        internalLender: this.globalStore.lenderInfo?.isInternal
      }
    }

    let response = null;
    if (this.isInternal) {
      response = await this.bridgePricerService.getRateFloor({
        ...data,
        ...extraPayload,
      })
        .then(r => r.data)
        .then(r => {
          this.onFieldChange('borrowerFloorRate', r);
          if (!this.form.fields['borrowerRate'].value || this.form.fields['borrowerRate'].value < r)
            this.onFieldChange('borrowerRate', r);
          this.filterBorowerRateOptions(borrowerRateOptions, r);
        })
        .finally(() => this.applyValidations());
    } else {
      const userId = this.userStore?.userInformation?.userId;
      response = await this.clientBridgePricerService.getRateFloor({
        ...data,
        ...extraPayload,
        userId: userId,
      })
        .then(r => r.data)
        .then(r => {
          this.onFieldChange('borrowerFloorRate', r);
          if (!this.form.fields['borrowerRate'].value || this.form.fields['borrowerRate'].value < r)
            this.onFieldChange('borrowerRate', r);
          this.filterBorowerRateOptions(borrowerRateOptions, r);
        })
        .finally(() => this.applyValidations());
    }
  }

  *fetchLoanValues(loanId: string) {
    try {
      let loanValues = null;
      if (this.isInternal) {
        const response = yield this.bridgePricerService.getLoanValues(loanId);
        loanValues = response?.data;
      } else {
        const response = yield this.clientBridgePricerService.getLoanValues(loanId);
        loanValues = response?.data.data;
      }
      const date = new Date(loanValues['submissionDate']);
      const purchaseDate = loanValues['purchaseDate'] ? new Date(loanValues['purchaseDate']) : null;
      const loanClosingDate = loanValues['loanClosingDate'] ? new Date(loanValues['loanClosingDate']) : null;
      const loanPurpose = loanValues['loanPurpose'] ? (String(loanValues['loanPurpose']).includes('Refi') ?
        'Refinance' : 'Purchase') : '';
      this.onFieldChange('submissionDate', date);
      this.onFieldChange('loanSubtype', loanValues['loanSubtype']);
      if (!isNaN(loanValues['rate'])) this.onFieldChange('borrowerRate', Number(loanValues['rate']));
      if (!isNaN(loanValues['points'])) {
        const points = Number(loanValues['points']);
        this.onFieldChange('borrowerPoints', points >= 1 ? points : 1);
      }
      this.onFieldChange('state', loanValues['propertyState']);
      this.onFieldChange('loanTermsMonths', loanValues['duration']);
      this.onFieldChange('lender', { label: loanValues['lenderName'], value: loanValues['lenderId'] });
      this.onFieldChange('lenderId', loanValues['lenderId']);
      this.onFieldChange('streetNumber', loanValues['streetNumber']);
      this.onFieldChange('streetName', loanValues['streetName']);
      this.onFieldChange('city', loanValues['city']);
      this.onFieldChange('zipCode', loanValues['zipCode']);
      this.onFieldChange('propertyAddress', loanValues['propertyAddress']);
      if (!isNaN(loanValues['acquisitionPrice'])) this.onFieldChange('acquisitionPrice', Number(loanValues['acquisitionPrice']).toFixed(2));
      if (!isNaN(loanValues['asIsValue'])) this.onFieldChange('asIsValue', Number(loanValues['asIsValue']).toFixed(2));
      if (!isNaN(loanValues['renoBudget'])) this.onFieldChange('renoBudget', Number(loanValues['renoBudget']).toFixed(2));
      if (!isNaN(loanValues['completedCapitalImprovements'])) this.onFieldChange('completedCapitalImprovements', Number(loanValues['completedCapitalImprovements']).toFixed(2));
      if (!isNaN(loanValues['afterRepairValue'])) this.onFieldChange('afterRepairValue', Number(loanValues['afterRepairValue']).toFixed(2));
      this.onFieldChange('propertyType', loanValues['propertyType']);
      this.onFieldChange('numberOfUnits', mapNumberOfUnits(loanValues['propertyType']));
      this.onFieldChange('purchaseDate', purchaseDate);
      this.onFieldChange('loanClosingDate', loanClosingDate);
      this.onFieldChange('borrowerFirstName', loanValues['borrowerFirstName']);
      this.onFieldChange('borrowerLastName', loanValues['borrowerLastName']);
      this.onFieldChange('estimatedFicoScore', loanValues['fico']);
      this.onFieldChange('experienceScore', loanValues['borrowerExperience']);
      this.onFieldChange('initialLTCPercentage', loanValues['initialLTCPercentage']);
      if (!isNaN(loanValues['constructionHoldbackPercentage'])) this.onFieldChange('constructionHoldbackPercentage', Number(loanValues['constructionHoldbackPercentage']));
      this.onFieldChange('exitStrategy', loanValues['exitStrategy']);
      this.onFieldChange('payoffAmount', loanValues['payoffAmount']);
      this.onFieldChange('mortgageTax', loanValues['mortgageTax']);
      this.onFieldChange('transferTax', loanValues['transferTax']);
      this.onFieldChange('mansionTax', loanValues['mansionTax']);
      this.onFieldChange('titleInsurance', loanValues['titleInsurance']);
      this.onFieldChange('brokerFee', loanValues['saleBrokerFee']);
      this.onFieldChange('brokerName', loanValues['brokerName']);
      this.onFieldChange('propertyTaxAndMaintenance', loanValues['propertyTaxAndMaintenance']);
      this.onFieldChange('exitLoanRate', loanValues['exitLoanRate']);
      this.onFieldChange('monthlyRent', loanValues['monthlyRent']);
      this.onFieldChange('annualTaxes', loanValues['annualTaxes']);
      this.onFieldChange('annualHOADues', loanValues['annualHOADues']);
      this.onFieldChange('annualInsurance', loanValues['annualInsurance']);
      this.onFieldChange('fpi', loanValues['fullPrincipalInterest'] ? 'Yes' : 'No');
      this.onFieldChange('processingFee', loanValues['processingFee']);
      this.onFieldChange('underwritingFee', loanValues['underwritingFee']);
      this.onFieldChange('interestReserveMonths', loanValues['interestReserveMonths']);
      this.onFieldChange('timeToFlip', loanValues['timeToFlip']);
      this.onFieldChange('brokerPointsAtOrigination', loanValues['brokerPointsAtOrigination']);
      this.onFieldChange('loanPurpose', loanPurpose);

      this.fetchRateFloor()
      this.getBorrowerPointsOptions(this.isInternal);
      this.applyValidations();
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'An error occurred while loading the loan data',
      });
    }
    this.setDefaultValues();
  }

  applyValidations() {
    if (this.form.fields['borrowerFloorRate'].value > 0 && this.form.fields['borrowerRate'].value < this.form.fields['borrowerFloorRate'].value) {
      this.globalStore.notificationStore.showInfoNotification({
        message: 'Borrower Rate from loan is less than floor rate',
      });
      this.onFieldChange('borrowerRate', this.form.fields['borrowerFloorRate'].value);
    }

    if (this.form.fields['borrowerPoints'].value == 0) {
      this.globalStore.notificationStore.showInfoNotification({
        message: 'Borrower points from loan are zero.',
      });
      this.onFieldChange('borrowerPoints', 1);
    }
  }

  saveAddressField(address: string, addressDetails, geometryDetails) {
    const parsedAddress = parseAddress(
      address,
      addressDetails,
      geometryDetails
    );
    this.onFieldChange('streetNumber', parsedAddress.street_number);
    this.onFieldChange('streetName', parsedAddress.street);
    this.onFieldChange('city', parsedAddress.city);
    this.onFieldChange('state', parsedAddress.state);
    this.onFieldChange('zipCode', parsedAddress.zip);
    this.onFieldChange('propertyAddress', address);
    this.onFieldChange('latitude', parsedAddress.latitude);
    this.onFieldChange('longitude', parsedAddress.longitude);
  }

  getCalculatedFields() {
    this.calculateInitialLoanAmount();
    this.calculateConstructionHoldback();
    this.calculateTotalLoanAmount();
  }

  private calculateInitialLoanAmount() {
    const initialPercentage = this.form.fields.initialLTCPercentage.value;
    const totalPurchase = this.form.fields.loanPurpose.value == 'Purchase'
      ? this.form.fields.acquisitionPrice.value
      : this.form.fields.acquisitionPrice.value + Number(this.form.fields.completedCapitalImprovements.value) ?? 0;
    let initialLoanAmount = 0;
    if (totalPurchase > 0 && initialPercentage > 0) {
      initialLoanAmount = totalPurchase * (initialPercentage / 100);
    }
    this.form.fields.initialLoanAmount.value = initialLoanAmount;
  }

  private calculateConstructionHoldback() {
    const totalRenovation = this.form.fields.renoBudget.value ?? 0;
    let constructionHoldback = 0;
    if (
      totalRenovation > 0 &&
      this.form.fields.constructionHoldbackPercentage.value > 0
    ) {
      constructionHoldback =
        (totalRenovation - (this.form.fields.completedCapitalImprovements.value ?? 0)) *
        (this.form.fields.constructionHoldbackPercentage.value / 100);
    }
    this.form.fields.constructionHoldback.value = constructionHoldback;
  }

  private calculateTotalLoanAmount() {
    this.form.fields.totalLoanAmount.value =
      this.form.fields.initialLoanAmount.value +
      this.form.fields.constructionHoldback.value;
  }

  *calculateQuote(loanDetails = null) {
    try {
      this.form.fields.isInternal.value = this.isInternal;
      this.runFormValidationWithMessage();
      if (this.form.meta.isValid) {
        this.getCalculatedFields();
        const quoteData = this.getFormValues();
        if (this.form.fields.loanPurpose.value === 'Purchase') {
          quoteData.timeToFlip = this.form.fields.loanTermsMonths.value
        }
        const response = yield this.quoteGeneratorService.calculateQuote({
          ...quoteData,
          purchaseDate: quoteData.purchaseDate ? formatDate(quoteData.purchaseDate, 'MM/dd/yyyy') : null,
          loanClosingDate: quoteData.loanClosingDate ? formatDate(quoteData.loanClosingDate, 'MM/dd/yyyy') : null,
          submissionDate: quoteData.submissionDate ? formatDate(quoteData.submissionDate, 'MM/dd/yyyy') : null,
          fpi: quoteData.fpi == 'Yes',
          userId: this.userStore?.userInformation?.userId,
          renoBudget: quoteData.renoBudget - (quoteData.completedCapitalImprovements ?? 0),
          referralFeePct: loanDetails?.referralFee
        });
        this.quoteCalculationDetails = response;
        this.chartData[0]['borrowerRate'] = this.quoteCalculationDetails.bridgePricerResponseFields['floorRate'];
        this.chartData = [
          this.chartData[0],
          this.quoteCalculationDetails.bridgePricerResponseFields['firstCurve'],
          this.quoteCalculationDetails.bridgePricerResponseFields['secondCurve'],
          this.quoteCalculationDetails.bridgePricerResponseFields['aboveSecondCurve']
        ];
      }
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while calculating quote',
      });
    }
  }

  *getQuotePrescreen() {
    try {
      this.isPrescreenLoading = true;
      this.quotePrescreenDetails = {};
      this.getCalculatedFields();
      const quoteData = this.getFormValues();
      const response = yield this.quoteGeneratorService.getQuotePrescreen({
        ...quoteData,
        purchaseDate: quoteData.purchaseDate ? formatDate(quoteData.purchaseDate, 'MM/dd/yyyy') : null,
        loanClosingDate: quoteData.loanClosingDate ? formatDate(quoteData.loanClosingDate, 'MM/dd/yyyy') : null,
        submissionDate: quoteData.submissionDate ? formatDate(quoteData.submissionDate, 'MM/dd/yyyy') : null,
        fpi: quoteData.fpi == 'Yes',
        userId: this.userStore?.userInformation?.userId,
        submittedFrom: '',
      });
      this.quotePrescreenDetails = response;
      this.isPrescreenLoading = false;
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while getting prescreen',
      });
      this.isPrescreenLoading = false;
    }
  }

  *getAllLenderIdNames() {
    if (this.isInternal) {
      const response = yield this.bridgePricerService.getAllLenderIdNames();
      this.allLenderOptions = toJS(response.data);
    }
  }

  get totalCosts() {
    const {
      acquisitionPrice,
      renovations,
      interestAndOriginationPoints,
      propertyTaxAndMaintenanceAmount,
      taxesAndOtherCosts,
    } = this.quoteCalculationDetails.bridgeProfitAndDscr;

    return (acquisitionPrice ?? 0) + (renovations ?? 0) + (interestAndOriginationPoints ?? 0) + (propertyTaxAndMaintenanceAmount ?? 0) + (taxesAndOtherCosts ?? 0);
  }

  get totalProfitOnFlip() {
    const {
      salesPrice
    } = this.quoteCalculationDetails.bridgeProfitAndDscr;

    return (salesPrice ?? 0) - this.totalCosts;
  }

  get lenderName() {
    if (this.form.fields.lenderId?.value) {
      return this.form.fields.lender?.value?.label;
    }

    if (this.globalStore?.lenderInfo?.lenderTradeName) {
      return this.globalStore?.lenderInfo?.lenderTradeName;
    }

    return this.globalStore?.lenderInfo?.companyName;
  }

  *fetchBrokerEconomicsSettings() {
    try {
      const response = yield this.quoteGeneratorService.getBrokerEconomicsByUserId(this.userStore?.userInformation?.userId, LoanType.RESIDENTIAL_BRIDGE);
      this.brokerEconomicsSettings = response.data.data;
      this.filterBorowerRateOptions(borrowerRateOptions, this.brokerEconomicsSettings?.rateFloor);
      this.loadForm({
        processingFee: this.brokerEconomicsSettings?.processingFee,
        underwritingFee: this.brokerEconomicsSettings?.underwritingFee,
        brokerName: this.userStore?.userInformation?.fullName,
      })
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching broker economics settings',
      });
    }
  }

  filterBorowerRateOptions(borrowerRateOptions, floorRate) {
    const options = borrowerRateOptions.filter((o) => {
      return parseFloat(o.value) >= parseFloat(floorRate);
    })

    const lowest = options.find((o =>
      parseFloat(o.value) === parseFloat(floorRate)
    ));

    if (!lowest) {
      options.push({
        label: floorRate + "%",
        value: floorRate
      });
    }

    options.sort((a, b) => parseFloat(a.value) - parseFloat(b.value));

    this.borrowerRateOptions = options;
  }

  setCalculationStarted() {
    this.calculationStarted = true;
  }

  setDefaultValues() {
    this.onFieldChange('rocRate', "");
    this.onFieldChange('yourYieldSpread', "");
    this.onFieldChange('yourPoints', "");
    this.onFieldChange('rocPoints', "");
  }

  continueQuoteSubmission(store) {
    try {
      const quoteData = this.getFormValues();
      store.reset();
      store.loadQuoteDataToStore(quoteData);
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while trying to continue with quote submission.',
      });
    }
  }
}
