import { useUserStore } from '@roc/feature-app-core';
import { useLoanStore } from '@roc/feature-loans';
import { observer } from 'mobx-react';
import { mapFundingEntity } from '../../utils/loanSummaryUtils';
import { CustomCard, CustomCardDividers, CustomCardRow } from '../customCard';
import { isNil } from '@roc/feature-utils';

export const TermsCard = observer(() => {
  const { loanStore } = useLoanStore();
  const { userStore } = useUserStore();
  const { loanDetails } = loanStore;
  const { allowLoanPricing } = userStore;

  return (
    <CustomCard title="Terms">
      <CustomCardDividers>
        {allowLoanPricing && (
          <CustomCardRow
            label={'Capital Provider Int Rate'}
            percentage
            value={loanDetails?.rocRate}
          />
        )}
        {allowLoanPricing && (
          <CustomCardRow
            label={'Capital Provider Points (I|O)'}
            value={
              `${isNil(loanDetails?.rocPointsIn) ? '0' : loanDetails?.rocPointsIn} | ${isNil(loanDetails?.rocPointsOut) ? '0' : loanDetails?.rocPointsOut}`
            }
          />
        )}
        {allowLoanPricing && (
          <CustomCardRow
            label={'Capital Provider Underwriting Fee'}
            currency
            value={loanDetails?.underwritingFee}
          />
        )}
        {allowLoanPricing && (
          <CustomCardRow
            label={'Capital Provider Processing Fee'}
            currency
            value={loanDetails?.processingFee}
          />
        )}
        {allowLoanPricing && (
          <CustomCardRow
            label={'Subordination'}
            percentage
            value={loanDetails?.origLenderSubordination}
          />
        )}
        {allowLoanPricing && (
          <CustomCardRow
            label={'Buy. Skim Red.'}
            value={loanDetails?.buybackSkimReduction}
          />
        )}
        {allowLoanPricing && (
          <CustomCardRow label={'Participation'} value={loanDetails?.sellAs} />
        )}
        {allowLoanPricing && (
          <CustomCardRow
            label={'Funding Entity'}
            value={mapFundingEntity(loanDetails?.fundingPreference)}
          />
        )}
      </CustomCardDividers>
    </CustomCard>
  );
});
