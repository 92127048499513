import { ApiResponse, Service } from '@roc/feature-app-core';

const url = {
  GET_USER_DOCUMENTS: '/api/v1/user/userdocuments/getUserDocuments',
  UPLOAD_USER_DOCUMENT: '/api/v1/user/userdocuments/addUserDocument',
  DOWNLOAD_USER_DOCUMENT: '/api/v1/user/userdocuments/downloadUserDocument',
  DELETE_USER_DOCUMENT: '/api/v1/user/userdocuments/deleteUserDocument',
};



export class UserDocumentsService extends Service {
  url;

  getUserDocuments(userFolder: string) {
    return this.get(`${url.GET_USER_DOCUMENTS}?folderPath=${userFolder}`);
  }

  uploadUserDocument(folderPath: string, fileName: string, formData: FormData) {
    return this.post(`${url.UPLOAD_USER_DOCUMENT}?folderPath=${folderPath}&fileName=${fileName}`, formData);
  }

  downloadUserDocument(filePath: string) {
    return this.get(
      `${url.DOWNLOAD_USER_DOCUMENT}`,
      {
        filePath,
      },
      {
        responseType: 'arraybuffer',
      }
    );
  }

  deleteUserDocument(filePath: string) {
    return this.delete(
      `${url.DELETE_USER_DOCUMENT}?filePath=${filePath}`
    );
  }
}
