import { makeStyles } from '@material-ui/core/styles';
import { EditorState, Modifier } from 'draft-js';
import { observer } from 'mobx-react';
import React from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useDocumentCommunicationStore } from '../hooks/useDocumentCommunicationStore';

const toolbarHeight = '64px';
const textEditorHeight = '300px';

const toolbar = {
  options: ['inline', 'blockType', 'list'],
  list: {
    inDropdown: false,
    options: ['unordered', 'ordered'],
  },
  blockType: {
    inDropdown: false,
    options: ['Blockquote', 'Code'],
  },
};

const useStyles = makeStyles(theme => ({
  toolbarClassName: {
    margin: 0,
    padding: theme.spacing(2),
    height: toolbarHeight,
    '& .rdw-option-wrapper': {
      padding: '14px 6px',
    },
    [theme.breakpoints.down('sm')]: {
      overflow: 'auto',
    },
  },
  wrapperClassName: {
    height: textEditorHeight,
  },
  editorClassName: {
    padding: theme.spacing(2),
    height: `calc(${textEditorHeight} - ${toolbarHeight})`,
    '& .DraftEditor-root': {
      height: `calc(${textEditorHeight} - ${toolbarHeight} - 50px)`,
    },
  },
}));

export const MessageEditor = observer(() => {
  const { documentCommunicationStore } = useDocumentCommunicationStore();
  const { editorState, setEditorState } = documentCommunicationStore;

  const classes = useStyles();

  const handleFiles = files => {
    documentCommunicationStore.uploadDocumentCommentAttachments(files);
  };

  const handlePastedText = () => {
    const currentContent = editorState.getCurrentContent();
    const currentSelection = editorState.getSelection();
    const newContent = Modifier.applyInlineStyle(
      currentContent,
      currentSelection,
      'background-color: inherit;'
    );
    setEditorState(
      EditorState.push(editorState, newContent, 'change-inline-style')
    );
  };

  return (
    <div style={{ cursor: 'text' }}>
      <Editor
        toolbar={toolbar}
        handlePastedText={handlePastedText}
        handlePastedFiles={handleFiles}
        handleDroppedFiles={(data, files) => handleFiles(files)}
        editorState={editorState}
        wrapperClassName={classes.wrapperClassName}
        toolbarClassName={classes.toolbarClassName}
        editorClassName={classes.editorClassName}
        onEditorStateChange={setEditorState}
      />
    </div>
  );
});
