import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const dpiConstant = 0.264583;

const addIgnoreToTags = (ignoreTagsIds) => {
  ignoreTagsIds.forEach(function (value) {
    const columnTag = document.getElementById(value);
    columnTag.setAttribute('data-html2canvas-ignore', 'true');
  })
};

const clearIgnoreTagsList = (ignoreTagsIds) => {
    ignoreTagsIds.forEach(function (value) {
      const columnTag = document.getElementById(value);
      columnTag.removeAttribute('data-html2canvas-ignore');
  })
};

const afterConvertToCanvas = (canvasList, fileName) => {
  const pdf = new jsPDF(null, 'mm', 'a4');
  pdf.deletePage(1);

  for (let i = 0; i < canvasList.length; i++) {
    const canvas = canvasList[i];
    const width = canvas.width;
    const height = canvas.height;
    const millimeters = { width: 0, height: 0 };
    millimeters.width = Math.floor(width * dpiConstant);
    millimeters.height = Math.floor(height * dpiConstant);

    pdf.addPage([millimeters.width, millimeters.height], width < height ? 'p' : 'l');

    const imgData = canvas.toDataURL('image/jpeg');
    pdf.addImage(imgData, 'JPEG', 0, 0, millimeters.width, millimeters.height, undefined, 'FAST');
  };
  return pdf;
};

export const multiPageScreenshotGrid = (fileName: string, screenshotsIds: string[], ignoreTagsIds: string[]) => {

  const downloadPdf = async () => {
    addIgnoreToTags(ignoreTagsIds);
    let listOfCanvas = []
    for (const htmlTag of screenshotsIds) {
      const canvas = await html2canvas(document.getElementById(htmlTag), {
        scrollX: 0,
        scrollY: 0,
        useCORS: true,
      });
      listOfCanvas.push(canvas);
    };
    const pdf = afterConvertToCanvas(listOfCanvas, fileName);
    pdf.save(fileName);
    clearIgnoreTagsList(ignoreTagsIds);
  };

  downloadPdf();
};

const getFormData = (pdf) => {
  const pdfBlob = pdf.output('blob');
  const newFile = new File([pdfBlob], 'Scope of Work.pdf', { type: 'pdf' });
  const formData = new FormData();
  formData.append('file', newFile);
  return formData;
}

export const getMultiPageScreenshotGrid = (fileName: string, screenshotsIds: string[], ignoreTagsIds: string[]) => {

  const getPdf = async () => {
    addIgnoreToTags(ignoreTagsIds);
    let listOfCanvas = []
    for (const htmlTag of screenshotsIds) {
      try{
        const canvas = await html2canvas(document.getElementById(htmlTag), {
          scrollX: 0,
          scrollY: 0,
          useCORS: true,
        });

        listOfCanvas.push(canvas);
      }catch(error){
      }
    };

    const pdf = afterConvertToCanvas(listOfCanvas, fileName);
    clearIgnoreTagsList(ignoreTagsIds);

    return getFormData(pdf);
  };

  return getPdf();
}

export default multiPageScreenshotGrid;