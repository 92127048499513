import { useRoutes } from '@roc/client-portal-shared/hooks';
import { lenderStatus } from '@roc/client-portal-shared/modules/lenderDetails';
import { insertIf } from '@roc/client-portal-shared/utils';
import { BodyLayout, Navigation } from '@roc/feature-app-core';
import { DocumentCommunication } from '@roc/feature-documents';
import { LoansForDrawRequestModal } from '@roc/feature-draws';
import { DealRoomPopup } from '@roc/feature-proposals';
import { LoansForPayoffRequestModal } from '@roc/feature-servicer-loans';
import { NotFound } from '@roc/ui';
import React, { useEffect } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { useLenderPortalStore, useRoutes as useLenderRoutes } from '../hooks';
import { useNavigation } from '../hooks/useNavigation';
import { LoansForExtensionsRequestModal } from '@roc/feature-extensions';
import { observer } from 'mobx-react';

export const AuthenticatedView = observer(() => {
  const { globalStore } = useLenderPortalStore();
  const { lenderDetailsRoutesConfig } = useRoutes();
  const { push } = useHistory();

  useEffect(() => {
    if (globalStore.lenderInfo?.status === lenderStatus.APPLICATION_IN_PROGRESS) {
      push(lenderDetailsRoutesConfig.lenderDetails.url);
    }
  }, [globalStore.lenderInfo?.status]);

  if (!globalStore.userFeatures || !globalStore.lenderInfo) {
    console.error(`userFeatures and lenderInfo are missing`);
    return null;
  }

  const {
    miscellaneousRoutes,
    dashboardRoutes,
    dashboardRoutesConfig,
    loanSubmissionRoutes,
    messagesRoutes,
    companyProfileRoutes,
    lenderDetailsRoutes,
    borrowersRoutes,
    appraisalsRoutes,
    creditBackgroundRoutes,
    documentLibraryRoutes,
    documentLibraryContentfulRoutes,
    fixFlipSizerRoutes,
    scopeOfWorkRevisionRoutes,
    dscrCalculatorRoutes,
    liquidityCalculatorRoutes,
    multifamilyLiquidityCalculatorRoutes,
    drawsRoutes,
    internalPricerRoutes,
    bridgePricerRoutes,
    backgroundReviewRoutes,
    rocUniversityRoutes,
    sigmaDashboardsRoutes,
    proposalsRoutes,
    stabilizedBridgeAnalyzerRoutes,
    calculatorSelectionRoutes,
    manageBrokersRoutes,
    lenderExtensionsRoutes,
    payoffRoutes,
    scopeOfWorkRoutesV2,
    verifyBankInformationCallBackRoutes,
    editLoanSubmissionRoutes,
    liquidityReportRoutes,
    oneToolRoutes
  } = useRoutes();
  const {
    loanRoutes,
    whatsNewRoutes,
    lenderProfileRoutes,
    prospectPropertiesRoutes,
    bridgeProfitAndDscrCalculatorRoutes,
    quoteGeneratorRoutes,
    appsRoutes,
    backOfficeRoutes,
    calendlyRoutes,
  } = useLenderRoutes();

  const routes = [
    miscellaneousRoutes.commingSoon,
    miscellaneousRoutes.notFound,
    dashboardRoutes.dashboard,
    loanRoutes.loansHome,
    loanRoutes.loanDetails,
    loanRoutes.loanApplications,
    loanSubmissionRoutes.submitloan,
    loanSubmissionRoutes.fixFlip,
    loanSubmissionRoutes.groundUp,
    loanSubmissionRoutes.multifamilyBridge,
    loanSubmissionRoutes.singleProperty,
    loanSubmissionRoutes.rentalPortfolio,
    loanSubmissionRoutes.stabilizedBridge,
    ...insertIf(globalStore.lenderInfo?.showFixFlipProSubtype, [
      loanSubmissionRoutes.fixFlipPro,
    ]),
    messagesRoutes.messages,
    companyProfileRoutes.companyProfile,
    companyProfileRoutes.mersOnboardingCallback,
    verifyBankInformationCallBackRoutes.verifyBankInformationCallBack,
    lenderDetailsRoutes.lenderDetails,
    borrowersRoutes.borrowers,
    appraisalsRoutes.browseAppraisals,
    appraisalsRoutes.findAppraiser,
    appraisalsRoutes.submitAppraisal,
    appraisalsRoutes.appraisals,
    creditBackgroundRoutes.submitCreditBackground,
    creditBackgroundRoutes.creditBackgroundRequests,
    creditBackgroundRoutes.browsePlaidLiquidityRequests,
    creditBackgroundRoutes.plaidLiquidityVerification,
    documentLibraryRoutes.documentLibrary,
    documentLibraryContentfulRoutes.documentLibraryHome,
    documentLibraryContentfulRoutes.documentLibraryDocument,
    documentLibraryContentfulRoutes.documentLibraryDocumentPreview,
    whatsNewRoutes.whatsNew,
    fixFlipSizerRoutes.sizerHome,
    fixFlipSizerRoutes.submitSizer,
    fixFlipSizerRoutes.sizerDetails,
    scopeOfWorkRoutesV2.scopeOfWork,
    scopeOfWorkRevisionRoutes.scopeOfWorkRevision,
    drawsRoutes.draws,
    internalPricerRoutes.internalPricer,
    bridgePricerRoutes.bridgePricer,
    backgroundReviewRoutes.background,
    rocUniversityRoutes.rocUniversity,
    appsRoutes.apps,
    sigmaDashboardsRoutes.sigmaDashboards,
    proposalsRoutes.proposalsHome,
    proposalsRoutes.submitProposal,
    proposalsRoutes.proposalDetails,
    proposalsRoutes.viewProposal,
    proposalsRoutes.documentUpload,
    manageBrokersRoutes.manageBrokers,
    lenderExtensionsRoutes.extensions,
    payoffRoutes.payoffs,
    calculatorSelectionRoutes.calculatorSelection,
    bridgeProfitAndDscrCalculatorRoutes.bridgeProfitAndDscrCalculator,
    quoteGeneratorRoutes.dashboard,
    quoteGeneratorRoutes.createQuote,
    quoteGeneratorRoutes.viewQuote,
    editLoanSubmissionRoutes.fixFlip,
    editLoanSubmissionRoutes.groundUp,
    editLoanSubmissionRoutes.stabilizedBridge,
    editLoanSubmissionRoutes.singleProperty,
    oneToolRoutes.oneTool,
    oneToolRoutes.fixFlip,
    oneToolRoutes.groundUp,
    oneToolRoutes.stabilizedBridge,
    ...insertIf(globalStore.userFeatures?.showTools, [
      dscrCalculatorRoutes.dscrCalculator,
      multifamilyLiquidityCalculatorRoutes.liquidityCalculator
    ]),
    ...insertIf(globalStore.lenderInfo?.showFixFlipProSubtype, [
      editLoanSubmissionRoutes.fixFlipPro,
    ]),
    lenderProfileRoutes.lenderProfile,
    ...insertIf(globalStore.userFeatures?.showProspectProperties, [
      prospectPropertiesRoutes.prospectProperties,
      prospectPropertiesRoutes.prospectPropertyDetails,
    ]),
    ...insertIf(globalStore.userFeatures?.showStabilizedBridgeAnalyzer, [
      stabilizedBridgeAnalyzerRoutes.stabilizedBridgeAnalyzer,
    ]),
    backOfficeRoutes.backOfficeDashboard,
    calendlyRoutes.calendlyPage,
    liquidityReportRoutes.liquidityReport,
  ];

  const getRedirectToUrl = () => {
    const { redirectPath, ...rest } = globalStore.queryParams;
    globalStore.setQueryParams(rest);
    return redirectPath ? redirectPath : dashboardRoutesConfig.dashboard.url;
  };

  const navigationProps = useNavigation();

  return (
    <>
      <Navigation {...navigationProps} />

      <BodyLayout>
        <Switch>
          {React.Children.toArray(routes.map(x => x))}
          <Route
            exact
            path={'/'}
            render={() => {
              return <Redirect to={getRedirectToUrl()} />;
            }}
          />
          ,
          <Route
            exact
            path={globalStore.routeBasePath}
            render={() => {
              return <Redirect to={getRedirectToUrl()} />;
            }}
          />
          ,
          <Route>
            <NotFound />
          </Route>
        </Switch>
      </BodyLayout>

      <DocumentCommunication />
      <DealRoomPopup />
      <LoansForDrawRequestModal />
      <LoansForPayoffRequestModal />
      <LoansForExtensionsRequestModal />
    </>
  );
});
