import { Alert } from '@material-ui/lab';
import { observer } from 'mobx-react';
import React, { useEffect, useRef } from 'react';

const IframeHolder = ({ id, src, width, height, store }) => {
  store.loading = true;
  useEffect(() => {
    const frm = document.createElement('iframe');
    frm.src = src;
    frm.width = width;
    frm.height = height;
    frm.setAttribute('style', 'border: none;');
    document.getElementById(id).appendChild(frm);
    frm.onload = () => {
      store.loading = false;
    };
    return () => {
      store.loading = false;
    }
  }, []);

  return (
    <div id={id} style={{ minHeight: 'calc(100vh - 360px)', height: '100%' }} />
  );
};

export const DocumentSigning = observer(({ store }) => {
  const docusignUrl = store.formInfo.embeddedDocusignLink;

  useEffect(() => {
    window.addEventListener('message', handleWindowMessage);
    return () => window.removeEventListener('message', handleWindowMessage);
  }, []);
  const handleWindowMessage = (event: MessageEvent) => {
    switch (event.data) {
      case 'borrower-details-verification-docusign-done':
        store.confirmDocusign();
        break;
    }
  };

  return docusignUrl ? (
    <IframeHolder
      id="authorization-form"
      src={docusignUrl}
      width="100%"
      height="100%"
      store={store}
    />
  ) : null;
});
