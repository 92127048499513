import { Step, StepContent, StepLabel, Stepper } from "@material-ui/core"
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { FieldContainer } from "@roc/ui/formComponents"
import { observer } from "mobx-react"
import { useState } from "react"
import { PropertyInformation, PropertyInformationPurchase } from "./propertyDetailsForm"
import { Button } from '@roc/ui';
import PropertyDetailsStore from "../../stores/rentalPortfolio/propertyDetailsStore"
import { useRentalPortfolioStore } from "../../hooks/useRentalPortfolioStore"

function getSteps() {
  return [
    'Property information',
  ]
}

function getStepContent(loanPurpose: string) {
  if (loanPurpose === 'Purchase') {
    return <PropertyInformationPurchase />
  } return <PropertyInformation />;
}

function isFormValid(step: number, store: PropertyDetailsStore, loanPurpose: String) {
  if (loanPurpose === 'Purchase') return store.propertyInformationStorePurchase.form.meta.isValid;
  return store.propertyInformationStore.form.meta.isValid;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    button: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    actionsContainer: {
      marginBottom: theme.spacing(2),
    },
    resetContainer: {
      padding: theme.spacing(3),
    },
  })
)

export const PropertySteps = observer(
  ({ modalState, handleEdit }) => {
    const classes = useStyles();
    const [activeStep, setActiveStep] = useState(0);
    const steps = getSteps();
    const { rentalPortfolioStore: { propertyDetailsStore, pricerStore }, } = useRentalPortfolioStore();
    const { propertyInformationStore } = propertyDetailsStore;
    const loanPurpose = pricerStore.getLoanPurpose();
    const purchaseDate = pricerStore.getAcquisitionDate();
    const handleNext = () => {
      if (activeStep === steps.length - 1) {
        handleEdit();
        return;
      }
      setActiveStep(prevActiveStep => prevActiveStep + 1);
    };

    const handleBack = () => {
      setActiveStep(prevActiveStep => prevActiveStep - 1);
    };

    return (
      <div className={classes.root}>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
              <StepContent TransitionProps={{ unmountOnExit: false }}>
                {getStepContent(loanPurpose)}
                <div className={classes.actionsContainer}>
                  <FieldContainer>
                    <>
                      <Button
                        disabled={!isFormValid(index, propertyDetailsStore, loanPurpose)}
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        className={classes.button}
                        testId="next"
                      >
                        {activeStep === steps.length - 1
                          ? 'Finish'
                          : 'Next'}
                      </Button>
                    </>
                  </FieldContainer>
                </div>
              </StepContent>
            </Step>
          ))}
        </Stepper>

      </div>
    )

  }
)