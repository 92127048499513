import { Button } from '@roc/ui';
import React, { useState, ReactNode } from 'react';
import { StandardDialog } from './dialog';
import { Grid } from '@material-ui/core';

export interface SubmitDialogProps {
  open: boolean;
  title?: string;
  body: ReactNode;
  cancelButtonText?: string;
  okButtonText?: string;
  handleClose: React.ReactEventHandler<{}>;
  handleOk?: React.MouseEventHandler<HTMLButtonElement>;
}

export const SubmitDialog = ({
  open,
  title = 'Submit',
  body,
  cancelButtonText = 'Close',
  okButtonText = 'Submit',
  handleOk,
  handleClose,
}: SubmitDialogProps) => {
  return (
    <StandardDialog
      open={open}
      title={title}
      handleClose={handleClose}
      dialogContent={body}
      dialogActions={
        <Grid container justifyContent="flex-end" spacing={2}>
          <Grid item>
            <Button
              onClick={handleClose}
              color="primary"
              variant="contained"
              testId="cancel"
            >
              {cancelButtonText}
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={handleOk ?? handleClose}
              color="primary"
              variant="contained"
              testId="ok"
            >
              {okButtonText}
            </Button>
          </Grid>
        </Grid>
      }
    />
  );
};

export interface WithSubmitDialogProps {
  onClick: (event: React.MouseEventHandler<HTMLElement>) => void;
  onCancelClick?: () => void;
  submitDialogProps: {
    title?: string;
    body: ReactNode;
    okButtonText?: string;
    cancelButtonText?: string;
    submitValidation?: () => boolean;
  };
}

export const withSubmitDialog = <P extends object>(
  Component: React.ComponentType<P>
): React.FC<P & WithSubmitDialogProps> => ({
  onClick,
  onCancelClick,
  submitDialogProps,
  ...props
}: WithSubmitDialogProps) => {
    const {
      title,
      body,
      okButtonText,
      cancelButtonText,
      submitValidation,
    } = submitDialogProps;
    const [open, setOpen] = useState(false);
    const [targetButtonEvent, setTargetButtonEvent] = useState(null);

    const onTargetButtonClick = (
      event: React.MouseEventHandler<HTMLButtonElement>
    ) => {
      setTargetButtonEvent(event);
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
      if (onCancelClick) onCancelClick();
    };

    return (
      <>
        <SubmitDialog
          open={open}
          title={title}
          body={body}
          cancelButtonText={cancelButtonText}
          okButtonText={okButtonText}
          handleClose={handleClose}
          handleOk={() => {
            if (submitValidation && !submitValidation()) return;
            setOpen(false);
            onClick(targetButtonEvent);
          }}
        />
        <Component onClick={onTargetButtonClick} {...(props as P)} />
      </>
    );
  };
