import { formatDate } from '@roc/client-portal-shared/utils';
import { differenceInMonths, parse, startOfToday } from 'date-fns';
import { propertyOwnership, timeToFlipValues } from './constants';
const { PURCHASED, OWNED_BY_BORROWER } = propertyOwnership;

const parseNumbers = data => ({
  ...data,
  purchasePrice: parseFloat(data.purchasePrice) || 0,
  rehabToDate: parseFloat(data.rehabToDate) || 0,
  initialLoanAmount: parseFloat(data.initialLoanAmount) || 0,
  rehabToCompleteProject: parseFloat(data.rehabToCompleteProject) || 0,
  constructionHoldback: parseFloat(data.constructionHoldback) || 0,
  interestRate: parseFloat(data.interestRate) || 0,
  afterRepairValue: parseFloat(data.afterRepairValue) || 0,
  asIsValue: parseFloat(data.asIsValue) || 0,
  experienceScore: parseFloat(data.experienceScore) || 0,
  termInMonths: parseFloat(data.termInMonths) || 0,
  mortgageTaxPercent: parseFloat(data.mortgageTaxPercent) || 0,
  titleInsurancePercent: parseFloat(data.titleInsurancePercent) || 0,
  transferTaxPercent: parseFloat(data.transferTaxPercent) || 0,
  mansionTaxPercent: parseFloat(data.mansionTaxPercent) || 0,
  brokerFeePercent: parseFloat(data.brokerFeePercent) || 0,
  propertyTaxAndMaintenancePercent:
    parseFloat(data.propertyTaxAndMaintenancePercent) || 0,
});

const calculate = rawData => {
  const data = parseNumbers(rawData);
  const purchasePrice =
    data.acquisition === PURCHASED
      ? data.purchasePrice
      : data.purchasePrice + data.rehabToDate;
  const initialLoanCost = data.purchasePrice;
  const purchaseInitialLoanLoan =
    (data.initialLoanAmount / 100) * initialLoanCost;
  const initialLoanLoan =
    data.acquisition === PURCHASED
      ? purchaseInitialLoanLoan
      : (data.initialLoanAmount / 100) * purchasePrice;
  const initialLoanLtc = initialLoanLoan / purchasePrice;
  const constructionHoldbackCost = data.rehabToCompleteProject;
  const constructionHoldbackLoan =
    (data.constructionHoldback / 100) * constructionHoldbackCost;
  const constructionHoldbackLtc =
    data.acquisition === PURCHASED
      ? constructionHoldbackLoan / constructionHoldbackCost
      : constructionHoldbackLoan / constructionHoldbackCost; //pending formula here
  const totalLoan = initialLoanLoan + constructionHoldbackLoan;
  const loanAmount =
    data.acquisition === PURCHASED
      ? totalLoan
      : purchaseInitialLoanLoan + constructionHoldbackLoan;
  const interestReserveCurrency =
    data.interestReserveMonths *
    ((loanAmount * (data.interestRate / 100)) / 12);
  const totalCost =
    data.acquisition === PURCHASED
      ? initialLoanCost + constructionHoldbackCost
      : purchasePrice + constructionHoldbackCost;
  const totalLoanLtc =
    data.acquisition === PURCHASED ? totalLoan / totalCost : 0; //pending formula here
  const value =
    data.rehabToCompleteProject > 0 ? data.afterRepairValue : data.asIsValue;
  const monthsDiff = differenceInMonths(
    parse(data.purchaseDate, 'MM/dd/yyyy', new Date()),
    startOfToday()
  );
  const timeToFlip = 12;
  const mortgageTaxPercent = data.mortgageTaxPercent || 0;
  const titleInsurancePercent = data.titleInsurancePercent || 0;
  const transferTaxPercent = data.transferTaxPercent || 0;
  const mansionTaxPercent = data.mansionTaxPercent || 0;
  const brokerFeePercent = data.brokerFeePercent || 0;
  const propertyTaxAndMaintenancePercent =
    data.propertyTaxAndMaintenancePercent || 0;
  const points =
    data.acquisition === PURCHASED
      ? (data.pointsIn + data.pointsOut) / 100
      : (2 * (data.pointsIn + data.pointsOut)) / 100;
  const legalFees = 1500;
  const mortageRecordingTax = totalLoan * mortgageTaxPercent;
  const fillingFees = 250;
  const appraisal = 400;
  const plLegalFee = 1500;
  const recordingFee = 500;
  const titleInsuranceAmount = (titleInsurancePercent / 100) * purchasePrice;
  const originationPoints = totalLoan * points;
  const interest = timeToFlip * ((totalLoan * (data.interestRate / 100)) / 12);
  const propertyTaxAndMaintenanceAmount =
    timeToFlip * (((propertyTaxAndMaintenancePercent / 100) * value) / 12);
  const brokerFeeAmount = value * (brokerFeePercent / 100);
  const transferTaxAmount = value * (transferTaxPercent / 100);
  const mansionTaxAmount = value * (mansionTaxPercent / 100);
  const legalSaleCosts = 1500;
  const totalAcquisitionCosts =
    purchasePrice +
    legalFees +
    mortageRecordingTax +
    fillingFees +
    appraisal +
    plLegalFee +
    titleInsuranceAmount +
    originationPoints+
    recordingFee;
  const totalInterimExpenses =
    data.rehabToCompleteProject + interest + propertyTaxAndMaintenanceAmount;
  const totalSaleCosts =
    brokerFeeAmount +
    transferTaxAmount +
    mansionTaxAmount +
    legalSaleCosts +
    fillingFees;
  const totalCosts =
    totalAcquisitionCosts + totalSaleCosts + totalInterimExpenses;
  const interestAndOriginationPoints = originationPoints + interest;
  const taxesAndOtherCosts =
    legalFees +
    mortgageTaxPercent +
    fillingFees +
    appraisal +
    plLegalFee +
    titleInsuranceAmount +
    totalSaleCosts+
    recordingFee;
  const totalSnapshot =
    purchasePrice +
    data.rehabToCompleteProject +
    interestAndOriginationPoints +
    propertyTaxAndMaintenanceAmount +
    taxesAndOtherCosts;
  const totalProfitOnFlip = value - totalSnapshot;
  const borrowerCashAtPurchase =
    purchasePrice +
    data.rehabToCompleteProject +
    (legalFees +
      mortageRecordingTax +
      fillingFees +
      appraisal +
      plLegalFee +
      titleInsuranceAmount +
      originationPoints+
      recordingFee) -
    totalLoan;
  const borrowerCashMonths =
    borrowerCashAtPurchase + interest + propertyTaxAndMaintenanceAmount;
  const borrowerRoi = totalProfitOnFlip / borrowerCashMonths;
  const pledgeOfShares = data.pledgeOfShares;

  return {
    initialLoanCost: initialLoanCost,
    initialLoanLoan: initialLoanLoan,
    initialLoanLtc: initialLoanLtc,
    constructionHoldbackCost: constructionHoldbackCost,
    constructionHoldbackLoan: constructionHoldbackLoan,
    constructionHoldbackLtc: constructionHoldbackLtc,
    totalLoan: initialLoanLoan + constructionHoldbackLoan,
    totalCost: totalCost,
    totalLtc: totalLoanLtc,
    asIsLtv: initialLoanLoan / data.asIsValue,
    afterRepairLtv: totalLoan / data.afterRepairValue,
    loanAmount: loanAmount,
    interestReserveAmount: interestReserveCurrency,
    purchasePrice: purchasePrice,
    value: value,
    arltv: totalLoan / value,
    timeToFlip: timeToFlip,
    points: points,
    mortgageTaxPercent: mortgageTaxPercent,
    transferTaxPercent: transferTaxPercent,
    mansionTaxPercent: mansionTaxPercent,
    titleInsurancePercent: titleInsurancePercent,
    brokerFeePercent: brokerFeePercent,
    propertyTaxAndMaintenancePercent: propertyTaxAndMaintenancePercent,
    legalFees: legalFees,
    mortageRecordingTax: mortageRecordingTax,
    fillingFees: fillingFees,
    appraisal: appraisal,
    plLegalFee: plLegalFee,
    recordingFee:recordingFee,
    titleInsuranceAmount: (titleInsurancePercent / 100) * purchasePrice,
    originationPoints: originationPoints,
    totalAcquisitionCosts: totalAcquisitionCosts,
    interest: interest,
    propertyTaxAndMaintenanceAmount: propertyTaxAndMaintenanceAmount,
    totalInterimExpenses:
      data.rehabToCompleteProject + interest + propertyTaxAndMaintenanceAmount,
    brokerFeeAmount: brokerFeeAmount,
    transferTaxAmount: transferTaxAmount,
    mansionTaxAmount: mansionTaxAmount,
    legalSaleCosts: legalSaleCosts,
    totalSaleCosts: totalSaleCosts,
    totalCosts: totalCosts,
    borrowerProfitLoss: value - totalCosts,
    interestAndOriginationPoints: interestAndOriginationPoints,
    taxesAndOtherCosts: taxesAndOtherCosts,
    totalSnapshot: totalSnapshot,
    totalProfitOnFlip: totalProfitOnFlip,
    borrowerCashAtPurchase: borrowerCashAtPurchase,
    borrowerCashMonths: borrowerCashMonths,
    borrowerRoi: borrowerRoi,
    purchaseInitialLoanLoan: purchaseInitialLoanLoan,
    pledgeOfShares: pledgeOfShares,
    interestRate: data.interestRate,
  };
};

const calculateInitialLoanAmount = data => {
  return calculatePurchasePrice(data) * (data.initialLoanAmount / 100);
};

const calculatePurchasePrice = data => {
  return data.property.propertyOwnership === PURCHASED
    ? data.property.purchasePrice
    : data.property.purchasePrice + data.rehabToDate;
};

const calculateConstructionHoldback = data => {
  return (
    calculateTotalRenovationBudget(data) * (data.constructionHoldback / 100)
  );
};

const calculateTotalRenovationBudget = data => {
  const { rehabToCompleteProject } = data;
  return rehabToCompleteProject;
};

export const buildFormDataFromSizer = sizer => {
  const propertyAndProjectFormData = {
    address: sizer.address,
    acquisition: sizer.property.propertyOwnership,
    purchasePrice: sizer.property.purchasePrice,
    rehabToCompleteProject: sizer.rehabToCompleteProject,
    purchaseDate: sizer.property.purchaseDate,
    rehabToDate: sizer.rehabToDate,
    loanOutstanding: sizer.loanOutstanding,
    currentLender: sizer.currentLender,
    everBeenInDefault: sizer.everBeenInDefault,
    principalBalance: sizer.principalBalance,
    sumOfLoanFees: sizer.sumOfLoanFees,
    asIsValue: sizer.property.asIsValue,
    afterRepairValue: sizer.property.afterRepairValue,
    projectDescription: sizer.projectDescription,
    renovationConstruction: sizer.property.describeRenovation,
    addingSquareFootage: sizer.addingSquareFootage,
    lenderOriginatorId: sizer.lenderOriginatorId,
  };

  const borrowersAndLoanTermsFormData = {
    credit: sizer.credit,
    netWorth: sizer.borrower.netWorth,
    experienceScore: sizer.borrower.experience,
    experienceDescription: sizer.borrower.experienceComments,
    initialLoanAmount: sizer.initialLoanAmount,
    initialLoanAmountCurrency: calculateInitialLoanAmount(sizer),
    constructionHoldback: sizer.constructionHoldback,
    constructionHoldbackCurrency: calculateConstructionHoldback(sizer),
    interestRate: sizer.outputData.interestRate,
    pointsIn: sizer.pointsIn,
    pointsOut: sizer.pointsOut,
    termInMonths: sizer.termInMonths?.toString(),
    interestReserveMonths: sizer.interestReserveMonths,
    pledgeOfShares: sizer.pledgeOfShares,
  };

  const summaryTaxesAndFeesFormData = {
    mortgageTaxPercent: sizer.outputData.mortgageTaxPercent,
    transferTaxPercent: sizer.outputData.transferTaxPercent,
    mansionTaxPercent: sizer.outputData.mansionTaxPercent,
    titleInsurancePercent: sizer.outputData.titleInsurancePercent,
    brokerFeePercent: sizer.outputData.brokerFeePercent,
    propertyTaxAndMaintenancePercent:
      sizer.outputData.propertyTaxAndMaintenancePercent,
  };

  return {
    propertyAndProjectFormData,
    borrowersAndLoanTermsFormData,
    summaryTaxesAndFeesFormData,
  };
};

export const buildSizerFromFormValues = (sizerRequestId, formValues) => {
  const values = {
    ...formValues,
    purchaseDate: formatDate(formValues.purchaseDate, 'MM/dd/yyyy'),
    pointsIn: parseFloat(formValues.pointsIn),
    pointsOut: parseFloat(formValues.pointsOut),
    interestRate: parseFloat(formValues.interestRate),
    termInMonths: parseFloat(formValues.termInMonths),
    interestReserveMonths: parseFloat(formValues.interestReserveMonths),
  };
  const sizer = {
    sizerRequestId,
    rehabToDate: values.rehabToDate,
    rehabToCompleteProject: values.rehabToCompleteProject,
    loanOutstanding: values.loanOutstanding,
    currentLender: values.currentLender,
    everBeenInDefault: values.everBeenInDefault,
    principalBalance: values.principalBalance,
    sumOfLoanFees: values.sumOfLoanFees,
    addingSquareFootage: values.addingSquareFootage,
    projectDescription: values.projectDescription,
    credit: values.credit,
    initialLoanAmount: values.initialLoanAmount,
    constructionHoldback: values.constructionHoldback,
    pointsIn: values.pointsIn,
    pointsOut: values.pointsOut,
    termInMonths: values.termInMonths,
    interestRate: values.interestRate,
    interestReserveMonths: values.interestReserveMonths,
    pledgeOfShares: values.pledgeOfShares,
    address: values.address,
    lenderOriginatorId: values.lenderOriginatorId,
    property: {
      propertyOwnership: values.acquisition,
      purchasePrice: values.purchasePrice,
      purchaseDate: values.purchaseDate,
      afterRepairValue: values.afterRepairValue,
      asIsValue: values.asIsValue,
      describeRenovation: values.renovationConstruction,
    },
    borrower: {
      netWorth: values.netWorth,
      credit: values.credit,
      experience: values.experienceScore,
      experienceComments: values.experienceDescription,
    },
    outputData: calculate(values),
  };
  return sizer;
};

export const findOptionLabel = (opts, value) => {
  return opts.find(x => x.value === value)?.label;
};
