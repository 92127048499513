import { useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import { useLocation } from 'react-router-dom';
import { useBaseStore } from './useBaseStore';
import { useUserStore } from './useUserStore';
import { segmentAnalytics } from '@roc/feature-utils';

const GoogleAnalytics = {
  initialize: (gtmId) => {
    const tagManagerArgs = {
      gtmId
    };
    TagManager.initialize(tagManagerArgs);
  },
  sendEvent: (eventParameters: EventParameters) => {
    if ((window as any).dataLayer) {
      (window as any).dataLayer.push(eventParameters);
    }
  }
};

enum GAEventName {
  n_first_page_load = 'n_first_page_load',
  n_pageview = 'n_pageview'
};

interface EventParameters {
  event: GAEventName;
  n_page_title: string;
  n_page_pathname: string;
  n_user_id?: number;
  n_user_first_name?: string;
  n_user_last_name?: string;
  n_portal_name?: string;
  n_portal_id?: string;
  n_company_id?: number;
  n_is_borrower?: boolean;
}

export const usePageTrackingInternal = (gtmTrackingId: string) => {
  const { pathname } = useLocation();
  const { globalStore } = useBaseStore();
  const { portalConfiguration, userFeatures } = globalStore;
  const { userStore } = useUserStore();
  const [initialized, setInitialized] = useState(false);

  const createEventObject = (gaEventName: GAEventName): EventParameters => {
    const n_user_id = userStore?.userInformation?.userId;
    const n_user_first_name = userStore?.userInformation?.firstName;
    const n_user_last_name = userStore?.userInformation?.lastName;
    const n_portal_name = userStore?.userInformation?.portalName;
    const n_portal_id = portalConfiguration.id;
    const n_company_id = userStore?.userInformation?.companyId ?? globalStore?.selectedCompanyId;
    const n_is_borrower = userFeatures?.isBorrower;

    return {
      event: gaEventName,
      n_page_title: document.title,
      n_page_pathname: pathname,
      ...((n_user_id) && { n_user_id }),
      ...((n_user_first_name) && { n_user_first_name }),
      ...((n_user_last_name) && { n_user_last_name }),
      ...((n_portal_name) && { n_portal_name }),
      ...((n_portal_id) && { n_portal_id }),
      ...((n_company_id) && { n_company_id }),
      ...((n_is_borrower === true) && { n_is_borrower }),
    };
  }

  useEffect(() => {
    const initializeAnalytics = async () => {
      if (gtmTrackingId) {
        GoogleAnalytics.initialize(gtmTrackingId);
        setInitialized(true);
        GoogleAnalytics.sendEvent(createEventObject(GAEventName.n_first_page_load));
      }
  
      let user = userStore?.userInformation;
      if (!user?.userId) {
        const userRequest = await userStore.getUserInformation();
        user = {
          ...userRequest.user,
          username: userRequest.user.userName,
          fullName: userRequest.user.contact.fullName
        };
      }
  
      if (user) {
        segmentAnalytics.identify({
          userId: String(user?.userId),
          name: user?.fullName,
          userName: user?.username,
        });
      }
    };
  
    initializeAnalytics();
  }, []);

  useEffect(() => {
    if (initialized) {
      // setTimeout is needed to defer the execution after document title update in page.tsx
      setTimeout(() => {
        GoogleAnalytics.sendEvent(createEventObject(GAEventName.n_pageview));
      });
    }
  }, [initialized, pathname]);

  useEffect(() => {
    setTimeout(() => {
      segmentAnalytics.initialize();
      segmentAnalytics.trackPage();
    });
  }, [pathname])
};
