import { Grid } from '@material-ui/core';
import { useStore } from '@roc/client-portal-shared/hooks';
import { observer } from 'mobx-react';
import {
  Currency,
  Label,
  LabeledText,
  Percentage,
  Section,
  Value,
} from '../../components/fixFlipSizerSummaryComponents';
import {
  addingSquareFootageOptions,
  creditOptions,
  experienceScoreValues,
  renovationConstructionOptions,
  yesNoOptions,
} from '../../utils/constants';
import { findOptionLabel } from '../../utils/sizerHelper';

const SummaryPurchaseTab = observer(() => {
  const { fixFlipSizerStore } = useStore();
  const sizer = fixFlipSizerStore.detailsStore.sizer;
  const output = sizer.outputData;

  return (
    <Grid container spacing={2}>
      <Section label="Property and Project" />
      <LabeledText label="Address" value={sizer.address} testId="address"/>
      <LabeledText label="Project Desc" value={sizer.projectDescription} testId="projectDescription"/>
      <LabeledText
        label="Renovation/Construction Desc"
        value={findOptionLabel(
          renovationConstructionOptions,
          sizer.property.describeRenovation
        )}
        testId="describeRenovation"
      />
      <LabeledText
        label="Adding Square Footage?"
        value={findOptionLabel(
          addingSquareFootageOptions,
          sizer.addingSquareFootage
        )}
        testId="addingSquareFootage"
      />
      <Section label="Loan Terms" />
      <LabeledText label="Loan Amount" value={output.loanAmount} currency testId="loanAmount"/>
      <LabeledText
        label="Interest Rate"
        value={output.interestRate}
        percentage
        testId="interestRate"
      />
      <Label label="Points (In|Out)" xs={12} md={3} />
      <Percentage value={sizer.pointsIn} xs={6} md={3} testId="pointsIn" />
      <Percentage value={sizer.pointsOut} xs={6} md={6} testId="pointsOut" />
      <LabeledText label="Term (Months)" value={sizer.termInMonths} testId="termInMonths" />
      <Label label="Interest Reserve (months|$)" xs={12} md={3} />
      <Value value={sizer.interestReserveMonths} xs={6} md={3} testId="interestReserveMonths" />
      <Currency value={output.interestReserveAmount} xs={6} md={6} testId="interestReserveAmount" />
      <LabeledText
        label="Pledge of Shares"
        value={findOptionLabel(yesNoOptions, sizer.pledgeOfShares)}
        testId="pledgeOfShares"
      />
      <Section label="Guarantors" />
      <LabeledText
        label="Credit"
        value={findOptionLabel(creditOptions, sizer.credit)}
        testId="credit"
      />
      <LabeledText label="Net Worth" value={sizer.borrower.netWorth} currency testId="netWorth"/>
      <Label label="Experience Score" xs={12} md={3} />
      <Value value={sizer.borrower.experience} xs={6} md={3} testId="experience" />
      <Value
        value={experienceScoreValues?.[sizer.borrower.experience]}
        xs={6}
        md={6}
        testId="experienceScore"
      />
      <LabeledText
        label="Experience Description"
        value={sizer.borrower.experienceComments}
        testId="experienceComments"
      />
      <Section label="LTC/LTV" />
      <Grid item xs={12} md={3} />
      <Label label="Loan" xs={4} md={3} />
      <Label label="Cost" xs={4} md={3} />
      <Label label="LTC" xs={4} md={3} />
      <Label label="Initial Loan" xs={12} md={3} />
      <Currency value={output.initialLoanLoan} xs={12} md={3} testId="initialLoanLoan" />
      <Currency value={output.initialLoanCost} xs={12} md={3} testId="initialLoanCost" />
      <Percentage value={output.initialLoanLtc * 100} decimals={1} xs={12} md={3} testId="initialLoanLtc" />
      <Label label="Construction Holdback" xs={12} md={3} />
      <Currency value={output.constructionHoldbackLoan} xs={12} md={3} testId="constructionHoldbackLoan" />
      <Currency value={output.constructionHoldbackCost} xs={12} md={3} testId="constructionHoldbackCost" />
      <Percentage
        value={output.constructionHoldbackLtc * 100}
        decimals={1}
        xs={12}
        md={3}
        testId="constructionHoldbackLtc"
      />
      <Label label="-" xs={12} md={3} />
      <Currency value={output.totalLoan} xs={12} md={3} testId="totalLoan" />
      <Currency value={output.totalCost} xs={12} md={3} testId="totalCost" />
      <Percentage value={output.totalLtc * 100} decimals={1} xs={12} md={3} testId="totalLtc"/>
      <Label label="" xs={0} md={3} />
      <Label label="Value" xs={4} md={3} />
      <Label label="LTV" xs={4} md={6} />
      <Label label="As-Is Value" xs={12} md={3} />
      <Currency value={sizer.property.asIsValue} xs={12} md={3} testId="asIsValue" />
      <Percentage value={output.asIsLtv * 100} xs={12} md={6} testId="asIsLtv" />
      <Label label="After Repair Value" xs={12} md={3} />
      <Currency value={sizer.property.afterRepairValue} xs={12} md={3} testId="afterRepairValue" />
      <Percentage value={output.afterRepairLtv * 100} xs={12} md={6} testId="afterRepairLtv" />
      <Section label="Exit Strategy" />
      <LabeledText
        label="Profit Projection"
        value={output.borrowerProfitLoss}
        currency
        testId="borrowerProfitLoss"
      />
      <LabeledText label="DSCR" value={'-'} testId="dscr" />
    </Grid>
  );
});

export default SummaryPurchaseTab;
