import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import {
  debounce,
  exitStrategies,
  loanPurpose,
  LoanSubType,
  propertyTypesByLoanSubtype,
  yesNoOptions,
  yesNoOptionsBoolean,
} from '@roc/feature-utils';
import {
  Button,
  createAutocompleteAddressField,
  createAutocompleteField,
  createCurrencyField,
  createDateField,
  createNumberFormatField,
  createPercentageField,
  createRadioField,
  createSelectField,
  createSliderField,
  createSliderInputField,
  createTextField
} from '@roc/ui';
import { observer } from 'mobx-react';
import { useQuoteGeneratorStore } from '../hooks/useQuoteGeneratorStore';
import { useEffect, useMemo } from 'react';
import { mapNumberOfUnits } from 'libs/client-portal-shared/src/app/modules/internalPricer/utils/internalPricerUtils';
import TooltipWithTable from './tooltipWithTable';
import { borrowerPointOptions, borrowerRateOptions } from 'libs/client-portal-shared/src/app/modules/bridgePricer/utils/bridgePricerConstants';
import { QuoteGeneratorDetailsStore } from '../stores/quoteGeneratorDetailsStore';
import { AutocompleteField } from 'libs/client-portal-shared/src/app/modules/dscrCalculator/components/autocompleteField';
import { useLoanStore } from '@roc/feature-loans';

interface QuoteGeneratorFieldProps {
  store: QuoteGeneratorDetailsStore;
  helperText: string;
}

const useStyles = makeStyles(theme => ({
  slider: {
    marginTop: '2.5em',
    marginLeft: '2em',
    width: '85%',
  }
}));

const loanTermOptions = [
  {
    label: '12',
    value: 12,
  },
  {
    label: '18',
    value: 18,
  },
  {
    label: '24',
    value: 24,
  }
];

const loanProgramOptions = [
  {
    label: LoanSubType.FIX_AND_FLIP,
    value: LoanSubType.FIX_AND_FLIP
  },
  {
    label: LoanSubType.GROUND_UP,
    value: LoanSubType.GROUND_UP
  },
  {
    label: LoanSubType.STABILIZED_BRIDGE,
    value: LoanSubType.STABILIZED_BRIDGE
  },
]

const NON_BREAKING_SPACE = '\u3000';

const borrowerExperienceData = [
  { score: '1', whatCounts: 'Neither of the following: \n- Verified investment property(ies) \n- Real estate related profession/trade(1)' },
  { score: '2', whatCounts: '- 1-2 verified investment properties on track record' },
  { score: '3', whatCounts: '- 3-5 verified investment properties w/ renovation on track record \nOR \n- 5-7 verified investment properties on track record (rental properties)' },
  { score: '4', whatCounts: '- 6-9 verified investment properties w/ renovation on track record \nOR \n- 10-15 verified investment properties on track record (rental properties)' },
  { score: '5', whatCounts: '- 10+ verified investment properties w/ renovation on track record \nOR \n- 15+ verified investment properties on track record (rental properties)' },
  { score: '(1) Real State related profession/trade \n+1 tier increase if criteria satisfied', whatCounts: 'Either of the following: \n-Real estate agent/broker \n -General Contractor/Home Improvement Contractor' }
];

const borrowerExperienceHeaders = ['Borrower Experience Score', 'What Counts?'];

const tooltipDataBorrowerExperience = [
  {
    value: '1',
    data: 'Neither of the following: Verified investment property(ies) OR Real estate related profession/trade'
  },
  {
    value: '2',
    data: '1-2 verified investment properties on track record'
  },
  {
    value: '3',
    data: '3-5 verified investment properties w/ renovation on track record OR 5-7 verified investment properties on track record (rental properties)'
  },
  {
    value: '4',
    data: '6-9 verified investment properties w/ renovation on track record OR 10-15 verified investment properties on track record (rental properties)'
  },
  {
    value: '5',
    data: '10+ verified investment properties w/ renovation on track record OR 15+ verified investment properties on track record (rental properties)'
  }
];

const timeToFlipOptions = [
  {
    label: '3',
    value: 3,
  },
  {
    label: '6',
    value: 6,
  },
  {
    label: '9',
    value: 9,
  },
  {
    label: '12',
    value: 12,
  },
  {
    label: '15',
    value: 15,
  },
  {
    label: '18',
    value: 18,
  },
  {
    label: '21',
    value: 21,
  },
  {
    label: '24',
    value: 24,
  },
];

const preConfiguredBorrowerExperienceTooltip = () => {
  return <TooltipWithTable tableData={borrowerExperienceData} tableHeaders={borrowerExperienceHeaders} />
};

const QuoteInputFieldsetBox = ({ title, children }) => {
  return (
    <Box component="fieldset" mb={1} borderColor={'#555'} borderRadius={4} style={{
      borderStyle: 'solid',
      borderWidth: '1px',
    }}>
      <legend style={{
        padding: '0 8px',
        marginBottom: 8,
        marginLeft: 8,
        fontSize: 20,
        fontWeight: 'bold',
      }}><Typography variant="h5">{title}</Typography></legend>
      <Box p={2}>
        {children}
      </Box>
    </Box>
  )
}

const field = FieldComponent => {
  const WrappedFieldComponent = observer(FieldComponent);
  return props => (
    <Grid item xs={props.xs ?? 12} md={props.md ?? 6}>
      <WrappedFieldComponent {...props} />
    </Grid>
  );
};

export const InitialLTCPercentage = field(({ store }: QuoteGeneratorFieldProps) => {
  const classes = useStyles();

  let min = 0;
  let max = 100;

  return createSliderInputField({
    store,
    label: 'Initial Loan LTC',
    fieldName: 'initialLTCPercentage',
    testId: 'initialLTCPercentage',
    className: classes.slider,
    valueLabelDisplay: 'on',
    min,
    max,
    step: 1,
    marks: [
      {
        value: min,
        label: min + '%',
      },
      {
        value: max,
        label: max + '%',
      },
    ],
    onBlur: () => {
      store.fetchRateFloor();
    }
  });
});

export const ConstructionHoldbackPercentage = field(({ store }: QuoteGeneratorFieldProps) => {
  const classes = useStyles();

  let min = 0;
  let max = 100;

  return createSliderInputField({
    store,
    label: 'Construction Holdback',
    fieldName: 'constructionHoldbackPercentage',
    testId: 'constructionHoldbackPercentage',
    className: classes.slider,
    valueLabelDisplay: 'on',
    min,
    max,
    step: 1,
    marks: [
      {
        value: min,
        label: min + '%',
      },
      {
        value: max,
        label: max + '%',
      },
    ]
  });
});

export const BorrowerRate = field(({ store }: QuoteGeneratorFieldProps) => {
  const classes = useStyles();

  const options = store.borrowerRateOptions ? store.borrowerRateOptions : borrowerRateOptions;
  let min = 0;
  let max = 0;

  if (options.length > 0) {
    min = Number(options[0].value);
    max = Number(options[options.length - 1].value);
  }

  return createSliderInputField({
    store,
    label: "Borrower Rate",
    fieldName: 'borrowerRate',
    testId: 'borrowerRate',
    className: classes.slider,
    valueLabelDisplay: 'on',
    min,
    max,
    step: 0.01,
    marks: [
      {
        value: min,
        label: min + '%',
      },
      {
        value: max,
        label: max + '%',
      },
    ]
  });
});

export const TotalPointsLender = field(({ store }: QuoteGeneratorFieldProps) => {
  const classes = useStyles();
  const options = store.borrowerPointsOptions ? store.borrowerPointsOptions : borrowerPointOptions;
  let min = 0;
  let max = 0;

  if (options.length > 0) {
    min = Number(options[0].value);
    max = Number(options[options.length - 1].value);
  }

  return createSliderInputField({
    store,
    label: "Total Points (Lender)",
    fieldName: 'borrowerPoints',
    testId: 'borrowerPoints',
    className: classes.slider,
    valueLabelDisplay: 'on',
    min,
    max,
    step: 0.25,
    marks: min > 0 ? [
      {
        value: min,
        label: min + '%',
      },
      {
        value: max,
        label: max + '%',
      },
    ] : false,
  })
});

export const QuoteGeneratorInputs = observer(() => {
  const classes = useStyles();
  const { loanStore } = useLoanStore();
  const { quoteGeneratorStore } = useQuoteGeneratorStore();
  const { quoteGeneratorDetailsStore } = quoteGeneratorStore;
  const store = quoteGeneratorDetailsStore;
  const { isBroker } = !store.isInternal && quoteGeneratorStore?.globalStore?.userFeatures;

  useEffect(() => {
    store.getAllLenderIdNames();
    if (isBroker) {
      store.fetchBrokerEconomicsSettings();
    }
    store.getBorrowerPointsOptions(store.isInternal);
    store.fetchRateFloor();
  }, [])

  const handleAddressChange = event => {
    const { value, fullInfo } = event?.target || {};
    const { address_components, geometry } = fullInfo || {};
    store.saveAddressField(value, address_components, geometry);
    store.fetchRateFloor();
  };

  const handleLenderAutocompleteChange = (value) => {
    store.onFieldChange('lender', value);
    store.onFieldChange('lenderId', value.value);
    if (!store.form.fields.submissionDate.value) store.onFieldChange('submissionDate', new Date());
    store.fetchRateFloor();
    store.getBorrowerPointsOptions(store.isInternal);
  }

  const handlePropertyTypeChange = (value) => {
    store.onFieldChange('propertyType', value);
    store.onFieldChange('numberOfUnits', mapNumberOfUnits(value));
  }

  const handleSubmissionDateChange = (value) => {
    store.onFieldChange('submissionDate', value);
    if (value && validateDate(value)) {
      store.fetchRateFloor();
      store.getBorrowerPointsOptions(store.isInternal);
    }
  }

  useEffect(() => {
    const value = store.form.fields.useBorrowerEntity.value;
    if (value) {
      store.onFieldChange('borrowerFirstName', '');
      store.onFieldChange('borrowerLastName', '');
    } else {
      store.onFieldChange('borrowerEntity', '');
    }
  }, [store.form.fields.useBorrowerEntity.value]);

  useEffect(() => {
    const value = store.form.fields.isRealEstateAgent.value;
    if (value) {
      store.onFieldChange('brokerFee', 2.5);
    } else {
      store.onFieldChange('brokerFee', 4);
    }
  }, [store.form.fields.isRealEstateAgent.value]);

  const onFormChangeForFloorRate = async (store) => {
    try {
      store.fetchRateFloor();
    } catch (err) {
      store.globalStore.notificationStore.showErrorNotification({
        message: 'An error occurred while loading the loan data',
      });
    }
  }

  const fetchOptions = useMemo(
    () => debounce(searchText => store.fetchOptions(searchText), 500),
    [store]
  );

  const validateDate = (value) => {
    const date = new Date(value);
    return date && date.getFullYear();
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <AutocompleteField
          value={store.loanSearchText}
          options={store.loanSearchOptions}
          onChange={(text) => {
            store.loanSearchText = text;
            fetchOptions(store.loanSearchText);
          }}
          onSelect={opt => {
            store.loanSearchText = opt.label;
            store.fetchLoanValues(opt.value);
          }}
        />
      </Grid>
      {store.isInternal &&
        <Grid item xs={12}>
          {createAutocompleteField({
            store,
            label: 'Lender',
            fieldName: 'lender',
            options: store.allLenderOptions,
            onChange: handleLenderAutocompleteChange,
          })}
        </Grid>
      }
      <Grid item xs={12}>
        <QuoteInputFieldsetBox title="Property Information">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {createAutocompleteAddressField({
                store,
                label: 'Property Address',
                fieldName: 'propertyAddress',
                testId: 'propertyAddress',
                onChange: handleAddressChange,
              })}
            </Grid>
            <Grid item xs={6}>
              {createSelectField({
                store,
                label: 'Loan Program',
                fieldName: 'loanSubtype',
                testId: 'loanSubtype',
                options: loanProgramOptions,
                onChange: (value) => {
                  store.onFieldChange('loanSubtype', value);
                  onFormChangeForFloorRate(store);
                }
              })}
            </Grid>
            <Grid item xs={6}>
              {createSelectField({
                store,
                label: 'Property Type',
                fieldName: 'propertyType',
                testId: 'propertyType',
                options: propertyTypesByLoanSubtype(LoanSubType.FIX_AND_FLIP),
                onChange: handlePropertyTypeChange,
              })}
            </Grid>
            <Grid item xs={6}>
              {createSelectField({
                store,
                label: 'Loan Purpose',
                fieldName: 'loanPurpose',
                testId: 'loanPurpose',
                options: loanPurpose,
                onChange: (value) => {
                  store.onFieldChange('loanPurpose', value);
                  onFormChangeForFloorRate(store);
                }
              })}
            </Grid>
            {store.form.fields.loanPurpose.value === 'Refinance' &&
              <Grid item xs={6}>
                {createDateField({
                  store,
                  label: 'Purchase Date',
                  fieldName: 'purchaseDate',
                  testId: 'purchaseDate',
                })}
              </Grid>
            }
            <Grid item xs={6}>
              {createCurrencyField({
                store,
                label: 'Acquisition Price',
                fieldName: 'acquisitionPrice',
                testId: 'acquisitionPrice',
              })}
            </Grid>
            <Grid item xs={6}>
              {createCurrencyField({
                store,
                label: 'As Is Value',
                fieldName: 'asIsValue',
                testId: 'asIsValue',
              })}
            </Grid>
            <Grid item xs={6}>
              {createCurrencyField({
                store,
                label: 'Total Renovation Budget',
                fieldName: 'renoBudget',
                testId: 'renoBudget',
                onBlur: () => {
                  onFormChangeForFloorRate(store);
                }
              })}
            </Grid>
            {store.form.fields.loanPurpose.value === 'Refinance' &&
              (<>
                <Grid item xs={6}>
                  {createCurrencyField({
                    store,
                    label: 'Completed Renovations',
                    fieldName: 'completedCapitalImprovements',
                    testId: 'completedCapitalImprovements',
                    onBlur: () => {
                      onFormChangeForFloorRate(store);
                    }
                  })}
                </Grid>
              </>)
            }
            <Grid item xs={6}>
              {createCurrencyField({
                store,
                label: 'After Repair Value',
                fieldName: 'afterRepairValue',
                testId: 'afterRepairValue',
              })}
            </Grid>
            <Grid item xs={6}>
              {createSelectField({
                store,
                label: 'Exit Strategy',
                fieldName: 'exitStrategy',
                testId: 'exitStrategy',
                options: exitStrategies
              })}
            </Grid>
          </Grid>
        </QuoteInputFieldsetBox>
      </Grid>

      <Grid item xs={12}>
        <QuoteInputFieldsetBox title="Borrower Information">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {createRadioField({
                store,
                label: 'Do you have the borrowing entity information?',
                fieldName: 'useBorrowerEntity',
                testId: 'useBorrowerEntity',
                options: yesNoOptionsBoolean,
                row: true,
              })}
            </Grid>
            {!store.form.fields.useBorrowerEntity.value ? (
              <>
                <Grid item xs={6}>
                  {createTextField({
                    store,
                    label: "Borrower's First Name",
                    fieldName: 'borrowerFirstName',
                    testId: 'borrowerFirstName',
                  })}
                </Grid>
                <Grid item xs={6}>
                  {createTextField({
                    store,
                    label: "Borrower's Last Name",
                    fieldName: 'borrowerLastName',
                    testId: 'borrowerLastName',
                  })}
                </Grid>
              </>
            ) : (
              <Grid item xs={12}>
                {createTextField({
                  store,
                  label: "Borrower Entity",
                  fieldName: 'borrowerEntity',
                  testId: 'borrowerEntity',
                })}
              </Grid>
            )}
            <Grid item xs={6}>
              {createSliderField({
                store,
                label: "Borrower's FICO Score",
                fieldName: 'estimatedFicoScore',
                testId: 'estimatedFicoScore',
                className: classes.slider,
                valueLabelDisplay: 'on',
                min: 300,
                max: 800,
                step: 10,
                marks: [
                  {
                    value: 300,
                    label: '300',
                  },
                  {
                    value: 800,
                    label: '800',
                  },
                ],
                onBlur: () => {
                  onFormChangeForFloorRate(store);
                }
              })}
            </Grid>
            <Grid item xs={6}>
              {createSliderField({
                store,
                label: "Borrower's Experience Score",
                fieldName: 'experienceScore',
                testId: 'experienceScore',
                className: classes.slider,
                valueLabelDisplay: 'on',
                defaultValue: 1,
                tooltipData: tooltipDataBorrowerExperience,
                isCustomLabelTooltip: true,
                CustomTooltipComponent: preConfiguredBorrowerExperienceTooltip,
                min: 1,
                max: 5,
                step: 1,
                marks: [
                  {
                    value: 1,
                    label: 'Newbie',
                  },
                  {
                    value: 5,
                    label: 'Seasoned investor'.padEnd(22, NON_BREAKING_SPACE),
                  },
                ],
                onBlur: () => {
                  onFormChangeForFloorRate(store);
                }
              })}
            </Grid>
          </Grid>
        </QuoteInputFieldsetBox>
      </Grid>

      <Grid item xs={12}>
        <QuoteInputFieldsetBox title="Broker Information">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {createRadioField({
                store,
                label: 'Is there a broker involved in this loan?',
                fieldName: 'isRealEstateAgent', //todo: this is new field need to add a field to store
                testId: 'isBrokerInvolved',
                options: yesNoOptionsBoolean,
                row: true,
              })}
            </Grid>
            <Grid item xs={6}>
              {createTextField({
                store,
                label: "Broker Name",
                fieldName: 'brokerName',
                testId: 'brokerName',
              })}
            </Grid>
            <Grid item xs={6}>
              {createPercentageField({
                store,
                label: 'Broker Points',
                fieldName: 'brokerPointsAtOrigination',
                testId: 'brokerPointsAtOrigination',
                disabled: isBroker
              })}
            </Grid>
          </Grid>
        </QuoteInputFieldsetBox>
      </Grid>

      <Grid item xs={12}>
        <QuoteInputFieldsetBox title="Loan Terms">
          <Grid container spacing={2}>
            <InitialLTCPercentage store={store} />
            <ConstructionHoldbackPercentage store={store} />
            <Grid item xs={6}>
              {createDateField({
                store,
                label: 'Submission Date',
                fieldName: 'submissionDate',
                testId: 'submissionDate',
                onChange: (label, value) => { handleSubmissionDateChange(value) },
              })}
            </Grid>
            <Grid item xs={6}>
              {
                createPercentageField({
                  store,
                  label: 'Floor Rate',
                  fieldName: 'borrowerFloorRate',
                  testId: 'borrowerFloorRate',
                  disabled: true
                })
              }
            </Grid>
            <BorrowerRate store={store} />
            <TotalPointsLender store={store} />
            <Grid item xs={6}>
              {createSelectField({
                store,
                label: 'Loan Term',
                fieldName: 'loanTermsMonths',
                testId: 'loanTermsMonths',
                options: loanTermOptions,
                onChange: (value) => {
                  store.onFieldChange('loanTermsMonths', value);
                  onFormChangeForFloorRate(store);
                  store.getBorrowerPointsOptions(store.isInternal);
                }
              })}
            </Grid>
            {/* {!isBroker &&
              <Grid item xs={6}>
                {createNumberFormatField({
                  store,
                  label: 'Borrower Rate',
                  fieldName: 'borrowerRate',
                  testId: 'borrowerRate',
                })}
              </Grid>
            }
            {isBroker &&
              <>
                <Grid item xs={6}>
                  {createSelectField({
                    store,
                    label: 'Borrower Rate',
                    testId: 'borrowerRate',
                    fieldName: 'borrowerRate',
                    options: store.borrowerRateOptions ? store.borrowerRateOptions : borrowerRateOptions,
                  })}
                </Grid>
              </>
            }
            <Grid item xs={6}>
              {createNumberFormatField({
                store,
                label: 'Total Points (Lender)',
                fieldName: 'borrowerPoints',
                testId: 'borrowerPoints',
              })}
            </Grid> */}
            <Grid item xs={6}>
              {createNumberFormatField({
                store,
                label: 'Interest Reserve (Months)',
                fieldName: 'interestReserveMonths',
                testId: 'interestReserveMonths',
                allowNegative: false
              })}
            </Grid>
            <Grid item xs={6}>
              {createRadioField({
                store,
                label: 'Full Principal Interest',
                fieldName: 'fpi',
                testId: 'fpi',
                options: yesNoOptions,
                row: true,
              })}
            </Grid>
            <Grid item xs={6}>
              {createCurrencyField({
                store,
                label: 'Processing Fee',
                fieldName: 'processingFee',
                testId: 'processingFee',
              })}
            </Grid>
            <Grid item xs={6}>
              {createCurrencyField({
                store,
                label: 'Underwriting Fee',
                fieldName: 'underwritingFee',
                testId: 'underwritingFee',
              })}
            </Grid>
            {store.form.fields.loanPurpose.value === 'Refinance' && (
              <>
                <Grid item xs={6}>
                  {createCurrencyField({
                    store,
                    label: 'Payoff Amount',
                    testId: 'payoffAmount',
                    fieldName: 'payoffAmount',
                  })}
                </Grid>
              </>
            )}
            {store.form.fields.loanPurpose.value === 'Refinance' && store.form.fields.payoffAmount.value && store.form.fields.payoffAmount.value > 0 && (
              <>
                <Grid item xs={6}>
                  {createDateField({
                    store,
                    label: 'Original Loan Closing Date',
                    fieldName: 'loanClosingDate',
                    testId: 'loanClosingDate',
                  })}
                </Grid>
              </>
            )}
            {store.form.fields.exitStrategy.value === 'Refinance' && (
              <>
                <Grid item xs={6}>
                  {createPercentageField({
                    store,
                    label: 'Exit Loan Rate',
                    testId: 'exitLoanRate',
                    fieldName: 'exitLoanRate',
                    disabled: true,
                  })}
                </Grid>
                <Grid item xs={6}>
                  {createCurrencyField({
                    store,
                    label: 'Monthly Rent',
                    testId: 'monthlyRent',
                    fieldName: 'monthlyRent',
                  })}
                </Grid>
                <Grid item xs={6}>
                  {createCurrencyField({
                    store,
                    label: 'Annual Taxes',
                    testId: 'annualTaxes',
                    fieldName: 'annualTaxes',
                  })}
                </Grid>
                <Grid item xs={6}>
                  {createCurrencyField({
                    store,
                    label: 'Annual Insurance',
                    testId: 'annualInsurance',
                    fieldName: 'annualInsurance',
                  })}
                </Grid>
                <Grid item xs={6}>
                  {createCurrencyField({
                    store,
                    label: 'Annual HOA Dues',
                    testId: 'annualHOADues',
                    fieldName: 'annualHOADues',
                  })}
                </Grid>
              </>
            )}
          </Grid>
        </QuoteInputFieldsetBox>
      </Grid>

      <Grid item xs={12}>
        <Grid container direction='row' spacing={2}>
          <Grid item xs={6}>
            <Box pt={2}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                testId="calculate-button"
                onClick={() => {
                  quoteGeneratorDetailsStore.setCalculationStarted();
                  quoteGeneratorDetailsStore.calculateQuote(loanStore?.loanDetails);
                  (window as any).scrollTo({
                    behavior: 'smooth',
                    top: 0
                  });
                }}
              >
                Generate Quote
              </Button>
            </Box>
          </Grid>
          {!store.isInternal &&
            <Grid item xs={6}>
              <Box pt={2}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  testId="download"
                  onClick={() => quoteGeneratorDetailsStore.saveQuote()}
                >
                  Save
                </Button>
              </Box>
            </Grid>
          }
        </Grid>
      </Grid>
    </Grid>
  );
});
