import { Box, Button, Divider, Grid, ListItem, ListItemText, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import { PricerLayout } from '../../internalPricer/components/internalPricerComponents';
import { PropertyState, LoanSubType, SubmissionDate, FloorRate, BorrowerRate, BorrowerPoints, RocRate, RocPoints, YieldSpread, YourPoints, createCustomSelectField } from './clientBridgePricerFields';
import { ClientBridgePricerStore } from '../stores/clientBridgePricerStore';
import BridgePricerChart from '../utils/bridgePricerChart';

import { useEffect, useMemo } from 'react';
import { LoaSubtypeOptions, NON_BREAKING_SPACE, borrowerExperienceData, borrowerExperienceHeaders, borrowerRateOptions, loanTermOptions, tooltipDataBorrowerExperience } from '../utils/bridgePricerConstants';
import { AutocompleteField } from '../../dscrCalculator/components/autocompleteField';
import { debounce, estimatedGUExperienceOptions, formatDate, loanPurpose, sanitizeTestId } from '@roc/client-portal-shared/utils';
import { createCurrencyField, createSelectField, createSliderField, Paper, PaperBorderType, TestDataAttribute } from '@roc/ui';
import TooltipWithTable from './tooltipWithTable';
import { formatPercentage } from '@roc/ui/utils';

const useStyles = makeStyles(theme => ({
  slider: {
    marginTop: '2.5em',
    marginLeft: '2em',
    width: '85%',
  },
  paper: {
    border: '1px solid',
    borderColor: theme.palette.grey['200'],
    width: '100%',
    padding: theme.spacing(1),
  },
  divider: {
    listStyle: 'none',
  },
}));

const preConfiguredBorrowerExperienceTooltip = () => {
  return <TooltipWithTable tableData={borrowerExperienceData} tableHeaders={borrowerExperienceHeaders} />
};

const formatPercentageValue = value => {
  if (value === null || value === undefined) {
    return '-';
  }
  return formatPercentage(value, 2);
}

export const PaperList = ({ children }) => {
  const classes = useStyles();
  return <Paper borderType={PaperBorderType.SOLID_BORDER} className={classes.paper}>{children}</Paper>;
};

export const PaperListTitle = ({ text, last = false }) => {
  const classes = useStyles();
  return (
    <ListItem>
      <ListItemText>
        <Typography variant="h5">{text}</Typography>
      </ListItemText>
    </ListItem>
  );
};

export const PaperListItem = ({ label, value, last = false }) => {
  const classes = useStyles();
  return (
    <>
      <Divider variant="middle" component="li" className={classes.divider} />
      <ListItem>
        <ListItemText>
          <Box display="flex">
            <Box flexGrow={1}>{label}</Box>
            <TestDataAttribute id={sanitizeTestId(label)}>
              <Typography variant="body1">{value}</Typography>
            </TestDataAttribute>
          </Box>
        </ListItemText>
      </ListItem>
    </>
  );
};

interface PricerCalcSectionProps {
  store: ClientBridgePricerStore;
  getNewFloorRate?: () => void;
}

export const ClientBridgePricer = observer(({ store }: PricerCalcSectionProps) => {
  const formValues = store.getFormValues();

  useEffect(() => {
    const actualDate = new Date();
    store.onFieldChange('submissionDate', actualDate);
    store.onFieldChange('lenderId', store.lenderId);
    onChangedDate(store, actualDate);
  }, []);

  const classes = useStyles();

  const onChangedDate = async (store, actualDate = null) => {
    try {
      const response = await store.getRateFloor(
        {
          "submissionDate": actualDate || store.form.fields["submissionDate"].value,
          "lenderName": store.form.fields["lenderName"].value,
          "propertyState": store.form.fields["propertyState"].value,
          "loanSubtype": store.form.fields["loanSubtype"].value
        });
      store.onFieldChange("floorRate", response.data);
      store.filterBorowerRateOptions(borrowerRateOptions, response.data);
      store.getBorrowerPointsOptions(
        store.form.fields["lenderId"].value,
        store.form.fields["loanTermsMonths"].value,
        formatDate(store.form.fields["submissionDate"].value, 'MM/dd/yyyy')
      );
    } catch (err) {

    }
  }

  const fetchOptions = useMemo(
    () => debounce(searchText => store.fetchOptions(searchText), 500),
    [store]
  );

  return (
    <PricerLayout
      left={
        <>
          <Grid item xs={12}>
            <AutocompleteField
              value={store.loanSearchText}
              options={store.loanSearchOptions}
              onChange={text => {
                store.loanSearchText = text;
                fetchOptions(store.loanSearchText);
              }}
              onSelect={opt => {
                store.loanSearchText = opt.label;
                store.fetchLoanValues(opt.value);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <PropertyDetailsSection store={store} getNewFloorRate={() => { onChangedDate(store) }} />
          </Grid>
          <Grid item xs={12} style={{ display: store.form.fields.loanSubtype.value !== LoaSubtypeOptions.GROUNDUP || !store.isInternal ? 'none' : '' }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant={'h5'}>Borrower Details</Typography>
              </Grid>
              <Grid item xs={6}>
                {createSliderField({
                  store,
                  label: "Borrower's FICO Score",
                  fieldName: 'estimatedFicoScore',
                  testId: 'estimatedFicoScore',
                  className: classes.slider,
                  valueLabelDisplay: 'on',
                  min: 680,
                  max: 800,
                  step: 10,
                  marks: [
                    {
                      value: 680,
                      label: '680',
                    },
                    {
                      value: 800,
                      label: '800',
                    },
                  ],
                  onBlur: () => {
                    onChangedDate(store);
                  }
                })}
              </Grid>
              <Grid item xs={6}>
                {createCustomSelectField({
                  store,
                  label: `Borrower's Experience Score`,
                  fieldName: 'experienceScore',
                  testId: 'experienceScore',
                  options: estimatedGUExperienceOptions,
                })}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <RatesSection store={store} />
          </Grid>
          <Grid item xs={12}>
            <Button color='primary' variant='contained' size='large' fullWidth onClick={() => store.fetchValues()}>
              CALCULATE
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Notes store={store} />
          </Grid>
        </>
      }
      right={
        <>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <PaperList>
                <Box p={1}>
                  <Chart key={store.chartData['yourYieldSpread']} store={store} />
                </Box>
              </PaperList>
            </Grid>
            <Grid item xs={12}>
              <PaperList>
                <PaperListTitle text="Yield Spread Values" />
                <PaperListItem label="Capital Provider Rate" value={formatPercentageValue(formValues.rocRate)} />
                <PaperListItem label="Capital Provider Points" value={formatPercentageValue(formValues.rocPoints)} />
                <PaperListItem label="Your Yield Spread" value={formatPercentageValue(formValues.yourYieldSpread)} />
                <PaperListItem label="Your Points" value={formatPercentageValue(formValues.yourPoints)} />
              </PaperList>
            </Grid>
          </Grid>
        </>
      }
    />
  )
});

export const PropertyDetailsSection =
  observer(({ store, getNewFloorRate }: PricerCalcSectionProps) => {
    const classes = useStyles();

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant={'h5'}>Property Details</Typography>
        </Grid>
        <PropertyState store={store} />
        <LoanSubType store={store} />
        <Grid item xs={6}>
          {createCustomSelectField({
            store,
            label: 'Loan Term',
            fieldName: 'loanTermsMonths',
            testId: 'loanTermsMonths',
            options: loanTermOptions
          })}
        </Grid>
        <Grid item xs={6} style={{ display: store.form.fields.loanSubtype.value !== LoaSubtypeOptions.GROUNDUP || !store.isInternal ? 'none' : '' }}>
          {createCustomSelectField({
            store,
            label: 'Loan Purpose',
            fieldName: 'loanPurpose',
            testId: 'loanPurpose',
            options: loanPurpose,
          })}
        </Grid>
        <Grid item xs={12} style={{ display: store.form.fields.loanSubtype.value !== LoaSubtypeOptions.GROUNDUP || !store.isInternal ? 'none' : '' }}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              {createCurrencyField({
                store,
                label: 'Total Renovation Budget',
                fieldName: 'renoBudget',
                testId: 'renoBudget',
                onBlur: getNewFloorRate
              })}
            </Grid>
            <Grid item xs={6}>
              {createSliderField({
                store,
                label: "Loan To Cost",
                fieldName: 'loanToPurchase',
                testId: 'loanToPurchase',
                className: classes.slider,
                valueLabelDisplay: 'on',
                valueLabelFormat: (value) => (<div>{Math.round(value)}%</div>),
                min: 0,
                max: 90,
                step: 5,
                marks: [
                  {
                    value: 0,
                    label: '0%',
                  },
                  {
                    value: 90,
                    label: '90%',
                  },
                ],
                onBlur: getNewFloorRate,
              })}
            </Grid>
            {store.form.fields.loanPurpose.value === 'Refinance' &&
              (<>
                <Grid item xs={6}>
                  {createCurrencyField({
                    store,
                    label: 'Completed Renovations',
                    fieldName: 'completedCapitalImprovements',
                    testId: 'completedCapitalImprovements',
                    onBlur: getNewFloorRate,
                  })}
                </Grid>
              </>)
            }
          </Grid>
        </Grid>
      </Grid>
    );
  });

export const RatesSection =
  ({ store }: PricerCalcSectionProps) => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant={'h5'}>Fix & Flip and Ground Up Rates</Typography>
        </Grid>
        <SubmissionDate store={store} />
        <FloorRate store={store} />
        <BorrowerRate store={store} />
        <BorrowerPoints store={store} />
      </Grid>
    );
  }

const Chart = observer(({
  store,
}: PricerCalcSectionProps) => {
  const { chartData } = store;
  return (
    <>
      <Grid item xs={12}>
        <Typography variant={'h5'}>Your Yield Spread vs. Borrower Rate</Typography>
        <BridgePricerChart key={chartData['yourYieldSpread']} chartData={chartData} />
      </Grid>
    </>
  );
})

export const Notes = observer(
  ({ store }: PricerCalcSectionProps) => {
    return store.chartData.length > 1 ? (
      <>
        <Typography variant={'subtitle2'}>
          Notes:
        </Typography>
        <Typography variant={'caption'}>
          While the rate floor is set at {store.form.fields['floorRate'].value}, a higher yield spread is available at rates above {store.chartData[1]['borrowerRate']} and the point share to the Capital Provider is increased for rates below {Number(store.form.fields['floorRate'].value) + Number(store.penaltyCeiling)}. All rates quoted are valid for 30 days from the date of submission.
        </Typography>
      </>
    ) : null;
  }
)