import { ApiResponse } from "@roc/feature-app-core";
import { TradeService } from "../service/tradeService";
import { GlobalStore } from "@roc/feature-app-core";
import { makeObservable, observable, action } from "mobx";

export class InvestorEntityStore {
  public entityList: any[];
  public selectedEntity: any;
  private tradeService: TradeService;
  private globalStore: GlobalStore;

  constructor(globalStore: GlobalStore) {
    this.globalStore = globalStore;
    this.tradeService = new TradeService();
    this.initialize();

    makeObservable(this, {
      selectedEntity: observable,
      entityList: observable,
      setSelectedEntity: action,
      reset: action
    });
  }

  getLabelFromValue = (value: number) => {
    return this.entityList.find((entity) => entity.value === value).label;
  }

  initialize() {
    this.selectedEntity = 0;
    // Hardcoded value. Will change to list fetched from the backend once new entities are included
    this.entityList = [{ label: '--- Select ---', value: 0 }, { label: 'Angsana', value: 38 }];
  }

  reset() {
    this.selectedEntity = 0;
  }

  setSelectedEntity(value: number) {
    this.selectedEntity = value;
  }

}