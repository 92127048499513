import { observer } from 'mobx-react';
import { LoanType } from '@roc/feature-utils';
import { useEffect } from 'react';
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemText,
  Chip,
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { green, red } from '@material-ui/core/colors';
import { Paper } from '@roc/ui';
import { formatCurrency } from '@roc/ui/utils';
import { formatDate, isNil } from '@roc/client-portal-shared/utils';
import { StatusChip, StatusType } from '@roc/ui';
import { useUserStore } from '@roc/feature-app-core';
import { LoanEconomicBridge } from 'libs/feature-documents/src/documents/components/overview/components/loanKeyInformation/loanEconomicBridge';
import { LoanWarningMessages } from 'libs/feature-documents/src/documents/components/overview/components/loanKeyInformation/loanWarningMessages';
import { LoanEconomicTerm } from 'libs/feature-documents/src/documents/components/overview/components/loanKeyInformation/loanEconomicTerm';
import { CustomCard } from '../customCard';
import { ExpandMore } from '@material-ui/icons';
import { LoanDetailsPaper } from '../loanDetailsPaper';
import { RelationshipManagerCardContent } from './relationshipManagerCard';

const useStyles = makeStyles(theme => ({
  paper: {
    marginBottom: theme.spacing(2),
  },
  list: {
    '& li': {
      marginLeft: '0 !important',
      marginRight: '0 !important',
      paddingLeft: '0 !important',
      paddingRight: '0 !important',
    },
  },
}));

export const LoanKeyInformationCard = observer(({ loanStore }) => {
  const { loanDetails, closingRequirements } = loanStore;
  const { loanType } = loanDetails;

  useEffect(() => {
    loanStore.fetchClosingRequirements(loanDetails.loanId);
  }, [loanStore, loanDetails.loanId]);

  return (
    <LoanDetailsPaper>
      <Accordion elevation={0}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Box>
            <Typography variant="h5">Key Information</Typography>
            <RelationshipManagerCardContent />
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          {loanType === LoanType.RESIDENTIAL_BRIDGE && (
            <LoanKeyInformationBridge
              loanDetails={loanDetails}
              closingRequirements={closingRequirements}
            />
          )}
          {loanType === LoanType.RESIDENTIAL_TERM && (
            <LoanKeyInformationTerm
              loanDetails={loanDetails}
              closingRequirements={closingRequirements}
            />
          )}
        </AccordionDetails>
      </Accordion>
    </LoanDetailsPaper>
  );
});

const listItem = (label, value, icon = null) => {
  return (
    <ListItem>
      <ListItemText>
        <Box display="flex">
          {icon && <Box ml={1}>{icon}</Box>}
          <Box flexGrow={1}>{label}</Box>
          <Box>{value}</Box>
        </Box>
      </ListItemText>
    </ListItem>
  );
};

const BooleanChip = ({ value }) => {
  const color = value ? green : red;
  return (
    <Chip
      label={value ? 'Yes' : 'No'}
      style={{
        fontWeight: 0,
        backgroundColor: color[200],
        color: color[900],
      }}
    />
  );
};

const divider = <Divider variant="middle" component="li" />;

const formatPercentage = (value, digits = 2) =>
  `${value != null ? Number(value).toFixed(digits) : ''}%`;

const usdAmount: any = value => {
  return value && formatCurrency(Number(value));
};

export const LoanKeyInformationBridge = observer(
  ({ loanDetails, closingRequirements }) => {
    const classes = useStyles();
    const {
      amount,
      expectedClosingDate,
      aggregateLoanToCurrentCostInitialA,
      constructionHoldbackToRenovationBudgetPercent,
      aggregateAfterRepairLoanToValue,
      status,
      initialLoanAmount,
      constructionHoldback,
      duration,
      fullPrincipalInterest,
      fullPrincipalInterestTime,
      interestReserve,
      prepayPenaltyMonths,
      pledgeOfShares,
      origLenderSubordination,
      buybackRight,
      buybackAfterMonths,
      buybackSkimReduction,
      participationPreference,
      loanClosingData: { readyToClose },
    } = loanDetails;

    const shouldDisplayFpiTime = () => {
      return (
        fullPrincipalInterest &&
        fullPrincipalInterestTime &&
        fullPrincipalInterestTime > 0
      );
    };

    const getKeyRatios = () => {
      return [
        aggregateLoanToCurrentCostInitialA,
        constructionHoldbackToRenovationBudgetPercent,
        aggregateAfterRepairLoanToValue,
      ]
        .filter(item => item)
        .join(' / ');
    };

    const getFpiAfter = () => {
      return `${fullPrincipalInterestTime} months`;
    };

    const {
      closingStatusMessages,
      creditExpiryMessages,
      appraisalWarningMessages,
    } = closingRequirements;
    const { userStore } = useUserStore();
    const allowLoanPricing = userStore.allowLoanPricing;
    return (
      <Grid container spacing={4}>
        <Grid item xs={12} lg={6}>
          <Box>
            <List className={classes.list}>
              <ListItem>
                <Typography variant="h5">Loan Terms</Typography>
              </ListItem>
              {listItem('Loan Amount', formatCurrency(amount))}
              {divider}
              {listItem('Expected Closing Date', expectedClosingDate)}
              {divider}
              {listItem('Key Ratios', getKeyRatios())}
              {divider}
              {listItem(
                'Status',
                <StatusChip
                  statusType={StatusType.LOAN}
                  label={status}
                  variant="filled"
                />
              )}
              {divider}
              {listItem('Ok to close', <BooleanChip value={readyToClose} />)}
              {divider}
              {listItem(
                'Initial Loan Amount',
                formatCurrency(initialLoanAmount)
              )}
              {divider}
              {listItem(
                'Construction Holdback',
                formatCurrency(constructionHoldback)
              )}
              {divider}
              {listItem('Duration (months)', duration)}
              {divider}
              {listItem(
                'Full Princ Interest',
                <BooleanChip value={fullPrincipalInterest} />
              )}
              {shouldDisplayFpiTime() ? (
                <>
                  {divider}
                  {listItem('FPI after', getFpiAfter())}
                </>
              ) : null}
              {divider}
              {listItem('Interest Reserve', formatCurrency(interestReserve))}
              {divider}
              {listItem('Prepay Penalty (months)', prepayPenaltyMonths)}
              {divider}
              {listItem(
                'Pledge of Shares',
                <BooleanChip value={pledgeOfShares} />
              )}
            </List>
          </Box>
        </Grid>
        <Grid item xs={12} lg={6}>
          {allowLoanPricing && (
            <Box>
              <List className={classes.list}>
                <ListItem>
                  <Typography variant="h5">Lender Terms</Typography>
                </ListItem>
                {listItem(
                  'Subordination',
                  formatPercentage(origLenderSubordination)
                )}
                {divider}
                {listItem(
                  'Buyback Right',
                  <BooleanChip value={buybackRight} />
                )}
                {divider}
                {listItem('Buyback After (months)', buybackAfterMonths)}
                {divider}
                {listItem(
                  'Buyback Skim Reduction',
                  formatPercentage(buybackSkimReduction)
                )}
                {divider}
                {listItem('Participation Preference', participationPreference)}
              </List>
            </Box>
          )}
          <Box>
            <List className={classes.list}>
              <ListItem>
                <Typography variant="h5">Splits</Typography>
              </ListItem>
              <LoanEconomicBridge loanDetails={loanDetails} />
            </List>
          </Box>
        </Grid>
        <Box mx={-2} mt={-2} width="100%">
          {!readyToClose ? (
            <LoanWarningMessages
              warnings={closingStatusMessages}
              title={"Why loan can't be closed?"}
            />
          ) : null}
          {creditExpiryMessages?.length > 0 && (
            <LoanWarningMessages
              warnings={creditExpiryMessages}
              title={'Credit Expiry Warnings'}
            />
          )}
          {appraisalWarningMessages?.length > 0 && (
            <LoanWarningMessages
              warnings={appraisalWarningMessages}
              title={'Appraisal Warnings'}
            />
          )}
        </Box>
      </Grid>
    );
  }
);

export const LoanKeyInformationTerm = observer(
  ({ loanDetails, closingRequirements }) => {
    const classes = useStyles();
    const {
      amount,
      expectedClosingDate,
      interestReserve,
      pledgeOfShares,
      loanPurpose,
      recourseStructure,
      duration,
      aggregateLoanToAsIs,
      interestOnlyMonths,
      interestPayment,
      rateType,
      prepaymentDescription,
      properties,
      amortizationATF,
      cashManagement,
      taxEscrowAtClose,
      taxEscrowOngoingMonthly,
      insuranceEscrowAtClose,
      insuranceEscrowOngoingMonthly,
      aggregateLoanToCurrentCost,
      loanClosingData: { readyToClose },
      loanPricingData,
    } = loanDetails;

    const getLoanTermYears = () => {
      return `${duration / 12} years`;
    };

    const {
      closingStatusMessages,
      creditExpiryMessages,
      appraisalWarningMessages,
    } = closingRequirements;

    return (
      <Grid container spacing={4}>
        <Grid item xs={12} lg={6}>
          <Box>
            <List className={classes.list}>
              <ListItem>
                <Typography variant="h5">Loan Terms</Typography>
              </ListItem>
              {listItem('Loan Amount', usdAmount(amount))}
              {divider}
              {listItem('Expected Closing Date', expectedClosingDate)}
              {divider}
              {listItem('Ok to close', <BooleanChip value={readyToClose} />)}
              {divider}
              {properties?.length > 1 && (
                <>
                  {listItem('Property Count', properties.length)}
                  {divider}
                </>
              )}
              {listItem('Loan Term (years)', getLoanTermYears())}
              {divider}
              {listItem('Loan Rate-Type', rateType)}
              {divider}
              {listItem('Loan Amortization', amortizationATF)}
              {divider}
              {listItem(
                'Prepay Penalty (Years/Stepdown)',
                prepaymentDescription
              )}
              {divider}
              {listItem(
                'Maximum Loan to Value',
                formatPercentage(aggregateLoanToAsIs, 3)
              )}
              {divider}
              {listItem('Loan Purpose', loanPurpose)}
              {divider}
              {listItem('Recourse', recourseStructure)}
              {divider}
              {listItem(
                'Pledge of Shares',
                <BooleanChip value={pledgeOfShares} />
              )}
              {divider}
              {listItem(
                'Cash Management',
                <BooleanChip value={cashManagement} />
              )}
              {listItem(
                'Loan LTC at Origination',
                formatPercentage(aggregateLoanToCurrentCost)
              )}
              {divider}
              {listItem('Int Only Periods (Months)', interestOnlyMonths)}
              {divider}
              {listItem(
                'Monthly Principal And Interest Payment',
                usdAmount(interestPayment)
              )}
              {divider}
              {listItem(
                'Borrower Gross Rate Lock Expiry Date',
                !isNil(loanPricingData.borrowerGrossRateLockExpiryDate) &&
                formatDate(
                  loanPricingData.borrowerGrossRateLockExpiryDate,
                  'MMMM d, yyyy'
                )
              )}
              {divider}
              {listItem(
                'Borrower Rate Lock Extension 1 Expiry Date',
                !isNil(
                  loanPricingData.firstPaidBorrowerGrossRateLockExpiryDate
                ) &&
                formatDate(
                  loanPricingData.firstPaidBorrowerGrossRateLockExpiryDate,
                  'MMMM d, yyyy'
                )
              )}
              {divider}
              {listItem(
                'Borrower Rate Lock Extension 2 Expiry Date',
                !isNil(
                  loanPricingData.secondPaidBorrowerGrossRateLockExpiryDate
                ) &&
                formatDate(
                  loanPricingData.secondPaidBorrowerGrossRateLockExpiryDate,
                  'MMMM d, yyyy'
                )
              )}
              {divider}
            </List>
          </Box>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Box>
            <List className={classes.list}>
              {listItem(
                'Interest Reserve (at closing)',
                usdAmount(interestReserve)
              )}
              {divider}
              {listItem(
                'Property Taxes Reserve (at closing)',
                usdAmount(taxEscrowAtClose)
              )}
              {divider}
              {listItem(
                'Property Tax Escrow (ongoing monthly amount)',
                usdAmount(taxEscrowOngoingMonthly)
              )}
              {divider}
              {listItem(
                'Insurance Reserve (at closing)',
                usdAmount(insuranceEscrowAtClose)
              )}
              {divider}
              {listItem(
                'Insurance Escrow (ongoing monthly amount)',
                usdAmount(insuranceEscrowOngoingMonthly)
              )}
            </List>
          </Box>
          <Box>
            <List className={classes.list}>
              <ListItem>
                <Typography variant="h5">Splits</Typography>
              </ListItem>
              <LoanEconomicTerm loanDetails={loanDetails} />
            </List>
          </Box>
        </Grid>
        <Box mx={-2} mt={-2} width="100%">
          {!readyToClose ? (
            <LoanWarningMessages
              warnings={closingStatusMessages}
              title={"Why loan can't be closed?"}
            />
          ) : null}
          {creditExpiryMessages?.length > 0 && (
            <LoanWarningMessages
              warnings={creditExpiryMessages}
              title={'Credit Expiry Warnings'}
            />
          )}
          {appraisalWarningMessages?.length > 0 && (
            <LoanWarningMessages
              warnings={appraisalWarningMessages}
              title={'Appraisal Warnings'}
            />
          )}
        </Box>
      </Grid>
    );
  }
);
