import { flow, observable, makeObservable, computed } from 'mobx';
import { GlobalStore } from '@roc/feature-app-core';
import { LoanStore } from '@roc/feature-loans';
import { InsuranceApprovalService } from './../../../services/documentForms/insuranceApproval/insuranceApprovalService';
import { FormStore } from '@roc/feature-app-core';
import {
  DocumentName,
  ELMSURE,
  STATUS_QUOTE_ACCEPTED_BY_BORROWER,
  STATUS_QUOTE_BOUND,
  STATUS_QUOTE_PAID_BY_BORROWER,
} from '@roc/feature-utils';
import InsuranceProviderFormStore from './insuranceProviderFormStore';
import { DocumentStore } from '../../documentStore';
import { NewQuoteFormStore } from './newQuoteFormStore';
import {
  InsuranceCompanyType,
  InsuranceDocumentType,
} from '../../../components/documentForms/insuranceApproval/constants';
import { DialogState } from '@roc/ui';
import { InsuranceDocumentUpload } from '../../../components/documentForms/insuranceApproval/types';

export class InsuranceApprovalFormStore {
  private loanStore: LoanStore;
  private globalStore: GlobalStore;
  private documentStore: DocumentStore;
  private insuranceProviderForm: FormStore;
  private insuranceApprovalService: InsuranceApprovalService;

  newQuoteFormStore: NewQuoteFormStore;

  elmsureQuotes: any[];
  otherQuotes: any[];
  delayReason: String;

  uploadedFiles: InsuranceDocumentUpload[] = [];

  constructor(
    loanStore: LoanStore,
    insuranceProviderFormStore: InsuranceProviderFormStore,
    globalStore: GlobalStore,
    documentStore: DocumentStore
  ) {
    this.loanStore = loanStore;
    this.globalStore = globalStore;
    this.documentStore = documentStore;
    this.insuranceApprovalService = new InsuranceApprovalService();
    this.insuranceProviderForm = insuranceProviderFormStore;
    this.newQuoteFormStore = new NewQuoteFormStore(
      globalStore,
      loanStore,
      this
    );

    this.elmsureQuotes = [];
    this.otherQuotes = [];

    makeObservable(this, {
      getInsuranceSummary: flow,
      elmsureQuotes: observable,
      otherQuotes: observable,
      uploadedFiles: observable,
      finalQuotes: computed,
      addFiles: flow,
      toggleSelectedQuote: flow,
      saveInsuranceSummary: flow,
      saveInsuranceSummaryForm: flow,
      deleteInsuranceDetail: flow,
      getDelayReason: flow,
      delayReason: observable,
    });
  }

  initialize() {
    this.getInsuranceSummary();
    this.getDelayReason();
  }
  *getDelayReason() {
    try {
      const { loanId } = this.loanStore.loanDetails;
      const { data } = yield this.insuranceApprovalService.getDelayReason(
        loanId
      );
      const response = JSON.parse(data.data);
      this.delayReason = JSON.parse(data.data);
      this
    } catch (err) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'An error occurred while getting delay reason.',
      });
    }
  }
  *getInsuranceSummary() {
    try {
      const { loanId } = this.loanStore.loanDetails;
      const { data } = yield this.insuranceApprovalService.getInsuranceSummary(
        loanId
      );
      const response = JSON.parse(data.data);
      const { elmsureQuotes, otherQuotes } = response;
      this.elmsureQuotes = elmsureQuotes;
      this.otherQuotes = otherQuotes;

      if (!this.finalQuotes.some(quote => quote.selectedQuote === true)) {
        this.finalQuotes
          .filter(quote => quote.insuranceCompany === ELMSURE)
          .forEach(quote => {
            quote.selectedQuote = true;
          });
      }
    } catch (err) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'An error occurred while getting insurance information.',
      });
    }
  }

  get finalQuotes() {
    const elmsureFinalQuotes = this.getFinalQuotesForElmsure();
    return [...elmsureFinalQuotes, ...this.otherQuotes];
  }

  private getFinalQuotesForElmsure() {
    return this.elmsureQuotes.filter(
      quote =>
        this.isFinalElmsureQuoteAccepted(quote) &&
        this.isDocusignCompletedOrNotRequired(quote)
    );
  }

  *toggleSelectedQuote(quote, isSelected) {
    const replaceSelected = item =>
      item === quote
        ? {
          ...item,
          selectedQuote: isSelected,
        }
        : item;

    if (quote.insuranceCompany === InsuranceCompanyType.ELMSURE) {
      this.elmsureQuotes = this.elmsureQuotes.map(replaceSelected);
    } else if (quote.insuranceCompany === InsuranceCompanyType.OTHER) {
      this.otherQuotes = this.otherQuotes.map(replaceSelected);
    }
  }

  *saveInsuranceSummary() {
    const { loanId } = this.loanStore.loanDetails;
    const {
      name,
      phone,
      emailAddress,
    } = this.insuranceProviderForm.getFormValues();
    yield this.insuranceApprovalService.saveInsuranceSummary({
      loanId,
      insuranceBrokerEmail: emailAddress,
      insuranceBrokerName: name,
      insuranceBrokerPhone: phone,
      insuranceFormDetails: this.finalQuotes,
    });
  }

  *saveInsuranceSummaryForm() {
    try {
      if (!this.finalQuotes.some(quote => quote.selectedQuote)) {
        this.globalStore.notificationStore.showWarningNotification({
          message:
            'Insurance cannot be saved without selecting any quotes. Please try again',
        });
        return;
      }

      yield this.saveInsuranceSummary();

      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Insurance information saved successfully.',
      });

      this.loanStore.refreshLoanDetails();
    } catch (err) {
      this.globalStore.notificationStore.showErrorNotification({
        message:
          'An error occurred when saving the insurance information. Please try again.',
      });
    }
  }

  *addFiles(quote, type, uploads) {
    const removeExisting = u =>
      !(u.insuranceDetailId === quote.insuranceDetailId && u.fileType === type);

    this.uploadedFiles = this.uploadedFiles.filter(removeExisting);

    for (let upload of uploads) {
      const file = upload.file;
      const quoteType = quote.quoteType;

      const name = `Final ${type} - ${quote.insuranceCompany} - ${quoteType}`
        .replace('/', ' ')
        .replace(/ /g, ' ');

      this.uploadedFiles.push({
        insuranceDetailId: quote.insuranceDetailId,
        file: new File([file], name, { type: file.type }),
        fileType: type,
        originalFileName: file.name,
      });
    }
  }

  *deleteInsuranceDetail(quote) {
    try {
      yield this.insuranceApprovalService.deleteInsuranceSummaryDetail(quote);
      yield this.getInsuranceSummary();
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Quote removed successfully.',
      });
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'An error occurred while removing the quote',
      });
    }
  }

  private isFinalElmsureQuoteAccepted(quote) {
    return (
      quote.quoteStatus === STATUS_QUOTE_ACCEPTED_BY_BORROWER ||
      quote.quoteStatus === STATUS_QUOTE_PAID_BY_BORROWER ||
      quote.quoteStatus === STATUS_QUOTE_BOUND
    );
  }

  private isDocusignCompletedOrNotRequired(quote) {
    return (
      !quote.isDocusignRequired ||
      (quote.isDocusignRequired && quote.docusignCompleted)
    );
  }

  reset() {
    this.uploadedFiles = [];
    this.elmsureQuotes = [];
    this.otherQuotes = [];
  }
}

export default InsuranceApprovalFormStore;
