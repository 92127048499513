import React from 'react';
import { Grid, TextField, Button } from '@material-ui/core';
import { CurrencyField } from '../currencyField/currencyField';

const MergeProperties = ({ mergedRecordsValues, handleChange }) => {
  return (
    <Grid container spacing={2} style={{ marginTop: '5px' }}>
      {mergedRecordsValues.map((record, index) => (
        <Grid item xs={12} key={index}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                label="Purchase Date"
                value={record?.purchaseDate}
                fullWidth
                variant="outlined"
                margin="normal"
                onChange={(e) => handleChange(index, 'purchaseDate', e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Sell Date"
                value={record?.sellDate}
                fullWidth
                variant="outlined"
                margin="normal"
                onChange={(e) => handleChange(index, 'sellDate', e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Recording Date"
                value={record?.recordingDate}
                fullWidth
                variant="outlined"
                margin="normal"
                onChange={(e) => handleChange(index, 'recordingDate', e.target.value)} />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Property Type"
                value={record?.propertyType}
                fullWidth
                variant="outlined"
                margin="normal"
                onChange={(e) => handleChange(index, 'propertyType', e.target.value)} />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Seller"
                value={record?.seller}
                fullWidth
                variant="outlined"
                margin="normal"
                onChange={(e) => handleChange(index, 'seller', e.target.value)} />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Buyer"
                value={record?.buyer}
                fullWidth
                variant="outlined"
                margin="normal"
                onChange={(e) => handleChange(index, 'buyer', e.target.value)} />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Lender"
                value={record?.lender}
                fullWidth
                variant="outlined"
                margin="normal"
                onChange={(e) => handleChange(index, 'lender', e.target.value)} />
            </Grid>
            <Grid item xs={6}>
              <CurrencyField
                testId="loanAmount"
                variant="outlined"
                value={record?.loanAmount}
                onChange={(name, value) => handleChange(index,'loanAmount', value)}
                fullWidth
                groupSeparator="."
                allowNegative={false}
                margin="normal"
                label="Loan Amount"
              />
            </Grid>
            <Grid item xs={6}>
              <CurrencyField
                testId="purchasePrice"
                variant="outlined"
                value={record?.purchasePrice}
                onChange={(name, value) => handleChange(index, 'purchasePrice', value)}
                fullWidth
                groupSeparator="."
                allowNegative={false}
                margin="normal"
                label="Purchase Price"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Interest Rate"
                value={record?.interestRate}
                fullWidth
                variant="outlined"
                margin="normal"
                onChange={(e) => handleChange(index, 'interestRate', e.target.value)} />
            </Grid>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

export default MergeProperties;
