import {
  Typography,
  IconButton,
  Switch,
  Grid,
  makeStyles,
  Box,
} from '@material-ui/core';

import DeleteIcon from '@material-ui/icons/Delete';

import { observer } from 'mobx-react';
import { TableCell, TableRow } from '@material-ui/core';
import { FeeFormStore } from '../../../stores/loanEconomics/feeFormStore';
import { deleteIconButtonStyle } from '../../../utils/constants';
import {
  createCurrencyField,
  createPercentageField,
  createSelectField,
} from '../../../components/temp/componentBuiderTemp';
import { useBaseStore } from '@roc/feature-app-core';
import { LoanEconomicsStore } from '../../../stores/loanEconomics/loanEconomicsStore';

interface FeeItemProps {
  feeSelectionDisabled : boolean,
  store: FeeFormStore;
  onDeleteFee: (feeKey: string) => void;
  feeOptions: any[];
}

const useStyles = makeStyles(theme => ({
  label: {
    fontWeight: 'bold',
    fontSize: '1rem',
  },
  activeLabel: {
    color: theme.palette.primary.main,
  },
  inactiveLabel: {
    color: theme.palette.text.secondary,
  },
  deleteButton: deleteIconButtonStyle,
  noBorders: {
    border: 'none',
  },
  disabled: {
    background: '#eee'
  }
}));

export const FeeItem = observer(({ store, onDeleteFee, feeSelectionDisabled, feeOptions }: FeeItemProps) => {
  const classes = useStyles();

  const feeKey = store.form.meta.key;
  const displayPercentage = store.form.fields.usePercentage.value;
  const { globalStore } = useBaseStore();

  return (
    <TableRow>
      <TableCell
        component="th"
        scope="row"
        style={{ width: '20%' }}
        className={classes.noBorders}
      >
        {createSelectField({
          disabled : feeSelectionDisabled,
          store,
          testId: `name-${feeKey}`,
          fieldName: `name`,
          label: '',
          options: feeOptions,
          variant: 'standard',
        })}
      </TableCell>
      <TableCell
        align="center"
        component="th"
        scope="row"
        style={{ width: '20%' }}
        className={classes.noBorders}
      ></TableCell>
      <TableCell
        align="center"
        component="th"
        scope="row"
        style={{ width: '20%' }}
        className={classes.noBorders}
      >
        <Box >
          {displayPercentage &&
            createPercentageField({
              store,
              testId: 'capitalProviderPoints',
              fieldName: 'capitalProviderPoints',
              label: null,
              autoFocus: true,
              disabled: store.disabledForCapitalProvider,
              inputClassName: store.disabledForCapitalProvider ? classes.disabled : '',
              limitedPercentage: true,
            })}
          {!displayPercentage &&
            createCurrencyField({
              label: null,
              fieldName: 'capitalProviderAmount',
              testId: 'capitalProviderAmount',
              store,
              disabled: store.disabledForCapitalProvider,
              inputClassName: store.disabledForCapitalProvider ? classes.disabled : '',
            })}
        </Box>
      </TableCell>
      {!globalStore?.lenderInfo?.isInternal &&
        <TableCell
          align="center"
          component="th"
          scope="row"
          style={{ width: '20%' }}
          className={classes.noBorders}
        >
          <Box >
            {displayPercentage &&
              createPercentageField({
                store,
                testId: 'lenderPoints',
                fieldName: 'lenderPoints',
                label: null,
                autoFocus: true,
                limitedPercentage: true,
              })}
            {!displayPercentage &&
              createCurrencyField({
                label: null,
                fieldName: 'lenderAmount',
                testId: 'lenderAmount',
                store,
              })}
          </Box>
        </TableCell>
      }
      <TableCell
        component="th"
        scope="row"
        style={{ width: '20%' }}
        className={classes.noBorders}
      >
        <Grid container alignItems="center" justifyContent="flex-start">
          <Grid item>
            <Typography
              className={`${classes.label} ${!displayPercentage ? classes.activeLabel : classes.inactiveLabel
                }`}
            >
              $
            </Typography>
          </Grid>
          <Grid item>
            <Switch
              checked={displayPercentage}
              onChange={() => store.toggleTypeOfValue()}
              color="primary"
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </Grid>
          <Grid item>
            <Typography
              className={`${classes.label} ${displayPercentage ? classes.activeLabel : classes.inactiveLabel
                }`}
            >
              %
            </Typography>
          </Grid>
          <Grid item>
            <IconButton
              className={classes.deleteButton}
              onClick={() => {
                onDeleteFee(feeKey);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Grid>
        </Grid>
      </TableCell>
    </TableRow>
  );
});
