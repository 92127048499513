import { action, flow, makeObservable, observable } from 'mobx';
import { LoanFastrackPaymentsService } from './../services/loanFastrackPaymentsService';
import { ApiResponse, FormStore, GlobalStore } from '@roc/feature-app-core';

const form = {
  fields: {
    fullName: {
      value: '',
      error: null,
      rule: 'required|regex:/^[A-Za-z\\s]+$/',
      message: 'Name is required (use only alphabetic characters)',
    },
    email: {
      value: '',
      error: null,
      rule: 'required|email',
      message: 'Email is required in correct format',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export class LoanFastrackDocumentStore extends FormStore {
  private globalStore: GlobalStore;
  private loanFastrackPaymentsService: LoanFastrackPaymentsService;
  fullName: string = null;
  email: string = null;
  totalFee: number = null;
  feeComponents: string = null;
  feeCalcError: string = null;
  cardKnoxURL: string = null;
  dealName: string = null;
  loading: boolean = true;
  fastTrackFees: any = {};
  randomLoanId: number;

  constructor(globalStore) {
    super({ ...form }, globalStore);
    this.loanFastrackPaymentsService = new LoanFastrackPaymentsService();
    makeObservable(this, {
      loading: observable,
      randomLoanId: observable,
      fetchBorrowerInfo: flow,
      fetchLoanFastrackInfo: flow,
      setCardKnoxUrl: action,
      setDealName: action,
      setFeeComponents: action,
      setRandomLoanId: action,
      setLoading: action,
      setTotalFee: action,
      resetStore: action,
      setFastTrackFees: action,
    });
  }

  setLoading(value: boolean) {
    this.loading = value;
  }

  setCardKnoxUrl(url: string) {
    this.cardKnoxURL = url;
  }

  setDealName(name: string) {
    this.dealName = name;
  }

  setTotalFee(cost: number) {
    this.totalFee = cost;
  }

  setFeeComponents(components: string) {
    this.feeComponents = components;
  }

  setRandomLoanId(randomLoanId: number) {
    this.randomLoanId = randomLoanId;
  }

  setFastTrackFees(data) {
    this.fastTrackFees = {
      insuranceFee: data.insuranceFee,
      insuranceReviewFee: data.insuranceReviewFee,
      titleSearchFee: data.titleSearchFee,
      rushedAppraisalFee: data.rushedAppraisalFee,
      fastTrackFee: data.fastTrackFee
    };
  }

  setInitialData(data) {
    const { cardKnoxURL, totalFee, feeCalcError, feeComponents, dealName, randomLoanId } = data;
    this.setCardKnoxUrl(cardKnoxURL);
    this.setTotalFee(totalFee);
    this.setFeeComponents(feeComponents);
    this.setFastTrackFees(data);
    this.setDealName(dealName);
    this.setRandomLoanId(randomLoanId);
    this.feeCalcError = feeCalcError;
  }

  *fetchLoanFastrackInfo(loanId: string, borrowerName: string) {
    this.setLoading(true);
    try {
      const response: ApiResponse = yield this.loanFastrackPaymentsService.getLoanInfoForFastrack(
        loanId,
        borrowerName
      );
      const { data } = response;

      if (data) {
        this.setInitialData(data);
        this.setLoading(false);
      }
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'There was an error while retrieving payment information',
      });
    }
  }

  *fetchBorrowerInfo(id: string, type: string) {
    try {
      const response: ApiResponse = yield this.loanFastrackPaymentsService.getCardholderInfo(
        id,
        type,
      );
      const { data } = response;

      if (data) {
        const { cardholderName, cardholderEmail } = data;
        this.onFieldChange('fullName', cardholderName);
        this.onFieldChange('email', cardholderEmail);
      }
    } catch (error) {
    }
  }

  resetStore() {
    this.form.fullName = null;
    this.form.email = null;
    this.fullName = null;
    this.totalFee = null;
    this.feeComponents = null;
    this.feeCalcError = null;
    this.cardKnoxURL = null;
    this.loading = false;
    this.randomLoanId = null;
  }
}

export default LoanFastrackDocumentStore;
