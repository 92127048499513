const mapper = {
  Appliances: {
    helperText: 'Please provide the cost and details of the following items',
    sections: [
      {
        title: 'Appliances',
        displayHowMany: true,
        fields: [
          {
            label: 'REF',
            name: 'ref',
          },
          {
            label: 'Range',
            name: 'range',
          },
          {
            label: 'D/W',
            name: 'dw',
          },
          {
            label: 'Microwave',
            name: 'microwave',
          },
          {
            label: 'Hood',
            name: 'hood',
          },
          {
            label: 'Clothes Washer',
            name: 'clothesWasher',
          },
          {
            label: 'Clothes Dryer',
            name: 'clothesDryer',
          },
        ],
      },
      {
        title: 'Other',
        tooltip: 'Any other relevant items related to this category',
        fields: [
          {
            label: 'Amount',
            name: 'otherAmount',
          },
        ],
      },
    ],
  },
  'Cabinets, Countertops': {
    helperText: 'Please provide the cost and details of the following items',
    sections: [
      {
        title: 'Cabinets',
        displayHowMany: true,
        fields: [
          {
            label: 'Kitchen',
            name: 'kitchenCabinets',
          },
          {
            label: 'Bath',
            name: 'bathCabinets',
          },
        ],
      },
      {
        title: 'Countertops',
        displayHowMany: true,
        fields: [
          {
            label: 'Kitchen',
            name: 'kitchenCountertops',
          },
          {
            label: 'Bath',
            name: 'bathCountertops',
          },
        ],
      },
      {
        title: 'Other',
        tooltip: 'Any other relevant items related to this category',
        fields: [
          {
            label: 'Amount',
            name: 'otherAmount',
          },
        ],
      },
    ],
  },
};

export default mapper;
