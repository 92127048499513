import { useBaseStore, useFavIcon, useFontFamilies } from '@roc/feature-app-core';
import { appLocalStorage, LocalStorageKeys } from '@roc/feature-utils';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useAppRoutes } from '../../../hooks';
import { useAuthenticationStorePublic } from '../../../hooks/useAuthenticationStorePublic';

// AutoLogin is also done in index.html for performance reasons
export const AutoLogin = observer(() => {

  const [renderView, setRenderView] = useState(false);
  const { publicRoutes } = useAppRoutes();
  const { globalStore } = useBaseStore();
  const { authenticationStore } = useAuthenticationStorePublic();
  const portalContextPath = appLocalStorage.getValue(LocalStorageKeys.portalContextPath);

  useFavIcon();
  useFontFamilies();

  useEffect(() => {
    if (portalContextPath) {
      /*authenticationStore.checkAuthentiationPublic(() => {
        if (authenticationStore.isAuthenticated) {
          window.location.href = `/${portalContextPath}`;
        } else {
          setRenderView(true);
        }
      });*/
      window.location.href = `/${portalContextPath}`;
    } else {
      setRenderView(true);
    }
  }, []);

  const getRedirectToUrl = () => {
    let _url = publicRoutes.login.url;
    return globalStore.redirectPath
      ? `${_url}?redirectPath=${globalStore.redirectPath}`
      : _url;
  };

  return (renderView ? (<Route
    render={() => {
      return <Redirect to={getRedirectToUrl()} />;
    }}
  />) : null);
});
