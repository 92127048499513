import { SendgridService } from '@roc/feature-sendgrid';
import { ApiResponse, FormStore, GlobalStore, GridStore } from '@roc/feature-app-core';
import { action, flow, makeObservable, observable } from 'mobx';

const form = {
  fields: {
    templateId: {
      value: '',
      error: null,
      rule: 'required',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export class SendgridTemplatesStore extends FormStore {
  private globalStore: GlobalStore;
  sendgridService: SendgridService;
  templateOptions = [];
  assignedTemplates = [];
  isTemplateAssigned = false;
  constructor(globalStore) {
    super({ ...form }, globalStore);
    this.globalStore = globalStore;
    this.sendgridService = new SendgridService();

    makeObservable(this, {
      assignTemplate: flow,
      getAllTemplateOptions: flow,
      templateOptions: observable,
      getAllAssignedTemplates: observable,
      assignedTemplates: observable,
      isTemplateAssigned: observable,
    });
  }

  *getAllTemplateOptions() {
    const response = yield this.sendgridService.getAllTemplates();
    this.templateOptions = response?.data?.data;
  }

  *getAllAssignedTemplates() {
    const response = yield this.sendgridService.getAllAssignedTemplates();
    this.assignedTemplates = response?.data?.data;
  }

  removeSpaces(inputString) {
    return inputString.replace(/\s+/g, '');
  }

  *assignTemplate(templateId,
    brand,
    audience) {
    try {
      const response: ApiResponse = yield this.sendgridService.assignTemplate(
        templateId,
        brand,
        this.removeSpaces(audience)
      );

      if (response.data?.data?.success === false) {
        const additionalDescription =
          `: Failed to add template to ${brand} ${audience}`;
        this.globalStore.notificationStore.showErrorNotification({
          message: response.data?.data?.responseMessage + additionalDescription,
        });
      } else {
        this.globalStore.notificationStore.showSuccessNotification({
          message: 'Template assigned succesfully',
        });
        this.isTemplateAssigned = true;
      }
    } catch (error) {
      console.log(error);
    }
  }



}
