import { makeStyles } from "@material-ui/core/styles";

export const getGridCardStyles = makeStyles(theme => ({
  cardContent: {
    padding: 0,
    borderTop: '1px solid #e1e1e1',
    marginTop: 16,
    '&:last-child': {
      padding: 0,
    },
    '& .pagination-bar': {
      border: 0,
      borderTop: '1px solid #eee',
    }
  },
}));
