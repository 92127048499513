import {
  InfiniteScrollGrid
} from '@roc/feature-app-core';
import {
  GridColumnProps,
  LinkColumn, SelectFilterComponent,
  SelectFloatingFilterComponent, StatusChip,
  StatusType, TextColumn,
  tooltipHeaderTemplate, FastForwardIcon, SubmitMenuItem
} from '@roc/ui';
import { formatCurrency } from '@roc/ui/utils';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { useLoanRoutes } from '@roc/feature-loans-routes-config';
import { useLoanStore } from '../hooks/useLoanStore';
import { tooltips } from '../utils/tooltips';
import { getUnreadMessagesCount, StyledBadge } from './myPipelineLoansGrid';
import { useUserStore } from '@roc/feature-app-core';
import { IconButton, ListItemIcon, ListItemText, Menu } from '@material-ui/core';
import { ImportExport, MoreVert } from '@material-ui/icons';
import { ChangeLoanStatusForm } from './changeLoanStatusForm';

const usdAmount: any = params => {
  return params.value && formatCurrency(Number(params.value));
};

const noOpComparator = () => 0;

const columns: GridColumnProps[] = [
  {
    field: 'dealid',
    headerName: 'Loan Id',
    minWidth: 100,
    cellRenderer: 'loanIdCellRenderer',
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
    pinned: 'left',
    lockPinned: true,
  },
  {
    field: 'dealName',
    headerName: 'Deal Name',
    minWidth: 275,
    cellRenderer: 'dealNameCellRenderer',
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'status',
    headerName: 'Status',
    minWidth: 120,
    cellRenderer: 'statusCellRenderer',
    comparator: noOpComparator,
    filter: 'selectFilter',
    floatingFilter: true,
    floatingFilterComponent: 'selectFloatingFilter',
    floatingFilterComponentParams: {
      suppressFilterButton: true,
      options: [
        { label: 'Declined', value: 'DECLINED' },
        { label: 'Funded', value: 'FUNDED' },
        { label: 'Lost', value: 'LOST' },
        { label: 'On Hold', value: 'ON_HOLD' },
        { label: 'Paid Off', value: 'PAID_OFF' },
        { label: 'Paid Off Buyout', value: 'PAID_OFF_BUYOUT' },
        { label: 'Retracted', value: 'RETRACTED' },
        { label: 'Unknown', value: 'UNKNOWN' },
      ],
    },
  },
  {
    field: 'loanSubType',
    headerName: 'Loan Type',
    minWidth: 200,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'amount',
    headerName: 'Amount',
    minWidth: 130,
    valueFormatter: usdAmount,
    comparator: noOpComparator,
  },
  {
    field: 'fastrackPaymentComplete',
    headerName: 'Fast Tracked Loan',
    minWidth: 125,
    cellRenderer: 'fastTrackCellRenderer',
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'submissionDate',
    headerName: 'Submission Date',
    minWidth: 185,
    comparator: noOpComparator,
    filter: 'agDateColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'closingDate',
    headerName: 'Closing Date',
    minWidth: 185,
    comparator: noOpComparator,
    filter: 'agDateColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'borrowerEntity',
    headerName: 'Borrower Entity',
    minWidth: 135,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'interestEconomics',
    headerName: 'Interest Economics',
    minWidth: 200,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
    headerTooltip: tooltips.interestEconomics,
    headerComponentParams: {
      template: tooltipHeaderTemplate,
    },
  },
  {
    field: 'keyRatios',
    headerName: 'Key Ratios',
    minWidth: 200,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
    headerTooltip: tooltips.keyRatios,
    headerComponentParams: {
      template: tooltipHeaderTemplate,
    },
  },
  {
    field: 'payoffDate',
    headerName: 'Payoff Date',
    minWidth: 125,
    comparator: noOpComparator,
    filter: 'agDateColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'submittedBy',
    headerName: 'Submitted By',
    minWidth: 300,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'coordinator',
    headerName: 'Processor',
    minWidth: 300,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'underwriter',
    headerName: 'Underwriter',
    minWidth: 300,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'lenderOriginatorName',
    headerName: 'Originator',
    minWidth: 300,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    headerName: 'Action',
    minWidth: 80,
    cellRenderer: 'actionCellRenderer',
    comparator: noOpComparator,
    width: 80,
    pinned: 'right',
    lockPinned: true,
    field: 'dealid'
  },
];

const UnreadMessagesBadge = observer(({ rowData, loanId, store, children }) => {
  const loan = store.gridData.data.rows.find(row => row.dealid === loanId);
  return (
    <StyledBadge
      badgeContent={getUnreadMessagesCount(
        loan ? loan?.unreadMessages : rowData?.unreadMessages
      )}
    >
      {children}
    </StyledBadge>
  )
});

export const MyLoansGrid = observer(({ isForBackUpLoans = false }) => {
  const { loanStore } = useLoanStore();
  const { loanRoutesConfig } = useLoanRoutes();
  const { userStore } = useUserStore();
  const [anchorEl, setAnchorEl] = useState<HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const [loanId, setLoanId] = useState(0);
  const allowLoanPricing = userStore.allowLoanPricing;
  const frameworkComponents = {
    selectFilter: SelectFilterComponent,
    selectFloatingFilter: SelectFloatingFilterComponent,
    loanIdCellRenderer: params =>
      loanStore.isLoanSubtypeSupported(params.node.data?.loanSubType)
        ? (
          <UnreadMessagesBadge
            loanId={params.node.data?.dealid}
            rowData={params.node.data}
            store={isForBackUpLoans ? loanStore.myBackUpLoansGridStore : loanStore.myLoansGridStore}
          >
            {LinkColumn({
              ...params,
              url: loanRoutesConfig.loans(params.value).children.dashboard.url,
            })}
          </UnreadMessagesBadge>
        )
        : TextColumn(params),
    dealNameCellRenderer: params => {
      const loanId = params.node.data?.dealid;
      return LinkColumn({
        ...params,
        url: loanRoutesConfig.loans(loanId).children.dashboard.url,
      });
    },
    fastTrackCellRenderer: ({ value }) => {
      if (!value) {
        return null;
      }
      return (<FastForwardIcon value={value} />)
    },
    actionCellRenderer: params => {
      if (loanStore.isLoanStatusRetractedOrOnHold(params.node.data?.status)) {
        return (
          <IconButton onClick={e => openMenu(e, params.node.data)}>
            <MoreVert color="primary" />
          </IconButton>
        );
      }
      return null;
    },
    statusCellRenderer: ({ value }) =>
      value ? (
        <StatusChip statusType={StatusType.LOAN} label={value} size="small" />
      ) : null,
  };
  const checkLoanPricingFlag = (columns) => {
    if (!allowLoanPricing) {
      return columns.filter(c => c.field != "interestEconomics" && c.field != "keyRatios");
    } else {
      return columns;
    }
  }


  const openMenu = (event: React.MouseEvent<HTMLElement>, record) => {
    const {
      dealid,
    } = record;

    setLoanId(dealid);
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <InfiniteScrollGrid
        columns={checkLoanPricingFlag(columns)}
        frameworkComponents={frameworkComponents}
        store={isForBackUpLoans ? loanStore.myBackUpLoansGridStore : loanStore.myLoansGridStore}
      />
      <Menu
        id="menu-appbar"
        getContentAnchorEl={null}
        anchorEl={anchorEl}
        keepMounted
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={menuOpen}
        onClose={handleMenuClose}
      >
        <SubmitMenuItem
          testId={`change-loan-status-${loanId}`}
          onClick={() => {
            loanStore.submitFormStatus(loanId, 'myLoans');
            handleMenuClose();
          }}
          submitDialogProps={{
            title: `Change Status for Loan ${loanId}`,
            body: <ChangeLoanStatusForm fromMyLoans={true} loanId={loanId} />,
            okButtonText: 'Submit',
            submitValidation: () => {
              loanStore.loanStatusStore.runFormValidation();
              return loanStore.loanStatusStore.form.meta.isValid;
            },
          }}
        >
          <ListItemIcon>
            <ImportExport fontSize="small" />
          </ListItemIcon>
          <ListItemText>Change Status</ListItemText>
        </SubmitMenuItem>
      </Menu>
    </>
  );
});
