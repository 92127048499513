import {
  Box,
  Typography,
  Divider,
  Button,
  Grid,
  FormControlLabel,
  Checkbox,
  TextField as MuiTextField,
} from '@material-ui/core';
import { FormStore } from '@roc/feature-app-core';
import {
  citizenshipStatusOptions,
  DateFormat,
  isNotBlank,
} from '@roc/feature-utils';
import {
  AddressDetailsField,
  createCurrencyField,
  createNumberFormatField,
  createNumberFormatFieldNoDecimalsAndWithLimit,
  createRadioField,
  createSelectField,
  createSliderField,
  createTextField,
  DateField,
  FieldLabel,
  NumberFormat,
  RadioField,
  SelectField,
  Slider,
  TextField,
  trueFalseOptions,
} from '@roc/ui';
import { observer } from 'mobx-react';
import { BorrowerFormStore } from '../../../stores/borrowers/borrowerFormStore';
import { ContactMail } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { Alert, Autocomplete } from '@material-ui/lab';
import {
  verifiedInvestmentPropertiesOptions,
  CreditPull as CreditPullEnum,
  CreditStatus,
} from '../../../utils/constants';
import { formatCurrency } from '@roc/ui/utils';
import { ItemDisplay } from '../../../components/itemDisplay';
import { useEffect } from 'react';
import { useStore } from '@roc/client-portal-shared/hooks';

export const BorrowerEmail = observer(
  ({ store, disabled, required }: { store: BorrowerFormStore, disabled?: boolean, required?: boolean }) => {
    const fieldName = 'emailAddress';
    const field = store.form.fields[fieldName];
    const { cannotCreateBorrower, isNewBorrower } = store;

    return (
      <TextField
        label="Email"
        name={fieldName}
        testId={fieldName}
        value={field.value}
        onChange={e => store.onFieldChange(fieldName, e.target.value)}
        variant="outlined"
        fullWidth
        error={!store.form.meta.isValid && isNotBlank(field.error)}
        helperText={!store.form.meta.isValid && field.error}
        disabled={disabled || cannotCreateBorrower}
        required={required}
      />
    );
  }
);

export const CreditPull = observer(
  ({
    store,
    creditStatus,
    disabled,
  }: {
    store: FormStore,
    creditStatus: CreditStatus,
    disabled?: boolean,
  }) => {
    const borrower = store.getFormValues();
    const field = store.form.fields.creditPull;
    const { globalStore } = useStore();

    useEffect(() => {
      if (!store.getValue('creditPull')) {
        if (!globalStore.lenderInfo?.isInternal && globalStore.lenderInfo?.restrictCustomCreditReport) {
          store.onFieldChange('creditPull', CreditPullEnum.BORROWER_AUTHORIZATION_FORM);
          return;
        }
        store.onFieldChange('creditPull', globalStore.lenderInfo?.creditProvidedByTpo ? CreditPullEnum.XACTUS_360 : CreditPullEnum.BORROWER_AUTHORIZATION_FORM);
      }
    }, []);

    if (CreditStatus.CREDIT_AND_BACKGROUND_OK === creditStatus) {
      return (
        <Alert severity="info">
          <Typography>
            {borrower.personalGuarantor ? (
              <>
                We already have the credit information of borrower{' '}
                <strong>{`${borrower?.firstName} ${borrower?.lastName} (${borrower?.emailAddress})`}</strong>
                .
              </>
            ) : (
              <>
                We already have the background information of borrower{' '}
                <strong>{`${borrower?.firstName} ${borrower?.lastName} (${borrower?.emailAddress})`}</strong>
                .
              </>
            )}
          </Typography>
        </Alert>
      );
    }

    if (CreditStatus.CREDIT_AND_BACKGROUND_CHECK_SENT === creditStatus) {
      return (
        <Alert severity="info">
          <Typography>
            We are waiting on borrower{' '}
            <strong>{`${borrower?.firstName} ${borrower?.lastName} (${borrower?.emailAddress})`}</strong>{' '}
            to provide data.
          </Typography>
        </Alert>
      );
    }

    return (
      <RadioField
        label={''}
        value={field.value}
        options={[
          {
            label:
              'Send the borrower Authorization Form to run a soft credit pull & background from Portal.',
            value: CreditPullEnum.BORROWER_AUTHORIZATION_FORM,
          },
          {
            label: 'I have a Xactus 360 hard credit pull to upload.',
            value: CreditPullEnum.XACTUS_360,
          },
        ]}
        onChange={value => store.onFieldChange('creditPull', value)}
        fullWidth
        testId={'creditPull'}
        error={!store.form.meta.isValid && isNotBlank(field.error)}
        errorText={!store.form.meta.isValid && field.error}
        disabled={disabled || globalStore.lenderInfo?.restrictCustomCreditReport}
      />
    );
  }
);

export const Xactus360Upload = observer(
  ({ store }: { store: BorrowerFormStore }) => {
    const field = store.form.fields.creditPull;
    const { cannotCreateBorrower, isNewBorrower } = store;
    return (
      <Box display="flex" alignItems={'center'}>
        <Typography variant="subtitle1" style={{ color: '#5E5E5E' }}>
          Xactus 360 Upload
        </Typography>
        <Box flex={1} px={2}>
          <Divider />
        </Box>
        <Button variant="outlined" size="small" onClick={() => { }}>
          <ContactMail />
          &nbsp;UPLOAD DOCUMENT
        </Button>
      </Box>
    );
  }
);

export const PhoneNumber = observer(
  ({ store }: { store: BorrowerFormStore }) => {
    const fieldName = 'phoneNumber';
    const field = store.form.fields[fieldName];
    const { cannotCreateBorrower, isNewBorrower } = store;
    return (
      <NumberFormat
        testId={fieldName}
        variant="outlined"
        customInput={TextField}
        format={'###-###-####'}
        mask="_"
        label={'Phone Number'}
        value={field.value}
        onChange={e => store.onFieldChange(fieldName, e.target.value)}
        error={!store.form.meta.isValid && isNotBlank(field.error)}
        helperText={!store.form.meta.isValid && field.error}
        disabled={cannotCreateBorrower}
        fullWidth
      />
    );
  }
);

export const DateOfBirth = observer(
  ({ store }: { store: BorrowerFormStore }) => {
    const fieldName = 'dateOfBirth';
    const field = store.form.fields[fieldName];
    const { cannotCreateBorrower, isNewBorrower } = store;
    return (
      <DateField
        testId={fieldName}
        inputVariant="outlined"
        format={DateFormat.MMDDYYYY}
        label={'Date of Birth'}
        value={field.value}
        onChange={date => store.onFieldChange(fieldName, date)}
        disableFuture={true}
        fullWidth
        error={!store.form.meta.isValid && isNotBlank(field.error)}
        helperText={!store.form.meta.isValid && field.error}
        standaloneLabel={false}
        disabled={cannotCreateBorrower && isNewBorrower}
      />
    );
  }
);

export const SocialSecurityNumber = observer(
  ({ store }: { store: BorrowerFormStore }) => {
    const fieldName = 'ssn';
    const field = store.form.fields[fieldName];
    const { cannotCreateBorrower, isNewBorrower } = store;
    return (
      <TextField
        label="Social Security Number"
        name={fieldName}
        testId={fieldName}
        value={field.value}
        onChange={e => store.onFieldChange(fieldName, e.target.value)}
        variant="outlined"
        fullWidth
        error={!store.form.meta.isValid && isNotBlank(field.error)}
        helperText={!store.form.meta.isValid && field.error}
        disabled={cannotCreateBorrower && isNewBorrower}
      />
    );
  }
);

export const CitizenshipStatus = observer(
  ({ store }: { store: BorrowerFormStore }) => {
    const fieldName = 'citizenshipStatus';
    const field = store.form.fields[fieldName];
    const { cannotCreateBorrower, isNewBorrower } = store;
    return (
      <SelectField
        label="Citizenship Status"
        name={fieldName}
        testId={fieldName}
        value={field.value}
        onChange={value => store.onFieldChange(fieldName, value)}
        options={citizenshipStatusOptions}
        disabled={cannotCreateBorrower && isNewBorrower}
        variant="outlined"
        fullWidth
        error={!store.form.meta.isValid && isNotBlank(field.error)}
        helperText={!store.form.meta.isValid && field.error}
      />
    );
  }
);

export const BorrowerAddress = observer(
  ({ store }: { store: BorrowerFormStore }) => {
    const fieldName = 'address';
    const field = store.form.fields[fieldName];
    const { cannotCreateBorrower, isNewBorrower } = store;
    return (
      <AddressDetailsField
        name={fieldName}
        value={field.value}
        onChange={value => store.onFieldChange(fieldName, value)}
        error={!store.form.meta.isValid && isNotBlank(field.error)}
        helperText={!store.form.meta.isValid && field.error}
        disabled={cannotCreateBorrower && isNewBorrower}
      />
    );
  }
);

const usePersonalGuarantorStyles = makeStyles(theme => ({
  infoMessage: {
    fontStyle: 'italic',
    marginTop: '0rem',
    marginLeft: '1rem',
    color: 'gray',
    fontSize: '12px',
  },
}));

export const PersonalGuarantor = observer(
  ({ store }: { store: BorrowerFormStore }) => {
    const classes = usePersonalGuarantorStyles();
    const fieldName = 'personalGuarantor';
    const field = store.form.fields[fieldName];
    const { cannotCreateBorrower, isNewBorrower } = store;
    return (
      <Grid container justifyContent="center" alignItems="center">
        <Grid item>
          <FormControlLabel
            control={
              <Checkbox
                checked={field.value}
                onChange={(e, checked) =>
                  store.onFieldChange(fieldName, checked)
                }
                color="primary"
              />
            }
            label={'This borrower is a personal guarantor for the loan.'}
            disabled={cannotCreateBorrower && isNewBorrower}
          />
        </Grid>
        <Grid item>
          <Typography variant="body2" className={classes.infoMessage}>
            If yes, the borrower's credit and background reports are needed. if
            no, only a background report is needed.
          </Typography>
        </Grid>
      </Grid>
    );
  }
);

const useFirstNameAutocompleteFieldStyles = makeStyles(theme => ({
  autocomplete: {
    '& .MuiAutocomplete-endAdornment': {
      display: 'none',
    },
  },
}));

export const FirstNameAutocompleteField = observer(
  ({ store, optional, disabled, required }: { store: BorrowerFormStore; optional?: boolean, disabled?: boolean, required?: boolean }) => {
    const inputFieldName = 'firstName';
    const formFields = store.form.fields;
    const inputField = formFields[inputFieldName];
    const options = store.borrowerOptions;
    const classes = useFirstNameAutocompleteFieldStyles();

    return (
      <Box className={classes.autocomplete}>
        <Autocomplete
          id={`autocomplete-${store.key}`}
          inputValue={inputField?.value}
          onInputChange={(e, value) => {
            // Ignore clear
            if (e?.nativeEvent?.type === 'input') {
              store.onFieldChange(inputFieldName, value);
            }
          }}
          disabled={disabled}
          options={options}
          renderInput={params => (
            <MuiTextField
              variant={'outlined'}
              label={'First Name'}
              required={required}
              error={isNotBlank(inputField?.error)}
              helperText={inputField?.error}
              {...params}
            />
          )}
          getOptionLabel={(option: any) => option?.label ?? ''}
          getOptionSelected={(option: any, value) =>
            option.label === value.label
          }
          onChange={(event, opt) => store.fetchRecordData(opt.value)}
          fullWidth
          PopperComponent={options.length ? undefined : () => null}
        />
      </Box>
    );
  }
);

export const LastName = observer(
  ({ store, optional, disabled }: { store: BorrowerFormStore; optional?: boolean, disabled?: boolean }) => {
    const { cannotCreateBorrower, isNewBorrower } = store;

    return (
      <>
        {createTextField({
          label: 'Last Name',
          fieldName: 'lastName',
          testId: 'lastName',
          store,
          standaloneLabel: false,
          disabled: disabled || (cannotCreateBorrower && isNewBorrower),
        })}
      </>
    );
  }
);

export const IsRealEstateBrokerAgent = observer(
  ({ store }: { store: BorrowerFormStore }) => {
    const { cannotCreateBorrower, isNewBorrower } = store;
    return (
      <>
        {createRadioField({
          label: 'Is the Borrower a Real Estate Broker/Agent?',
          fieldName: 'isRealEstateBrokerAgent',
          testId: 'isRealEstateBrokerAgent',
          store,
          options: trueFalseOptions,
          row: true,
          standaloneLabel: false,
          disabled: cannotCreateBorrower && isNewBorrower,
        })}
      </>
    );
  }
);

export const IsTheBorrowerAGeneralContractor = observer(
  ({ store }: { store: BorrowerFormStore }) => {
    const { cannotCreateBorrower, isNewBorrower } = store;
    return (
      <>
        {createRadioField({
          label: 'Is the Borrower a General Contractor?',
          fieldName: 'isGeneralContractor',
          testId: 'isGeneralContractor',
          store,
          options: trueFalseOptions,
          row: true,
          standaloneLabel: false,
          disabled: cannotCreateBorrower && isNewBorrower,
        })}
      </>
    );
  }
);

export const FicoScore = observer(({ store, disabled }: { store: BorrowerFormStore, disabled?: boolean }) => {
  const { cannotCreateBorrower, isNewBorrower } = store;

  return (
    <>
      {createNumberFormatFieldNoDecimalsAndWithLimit({
        label: 'Fico Score',
        fieldName: 'ficoScore',
        testId: 'ficoScore',
        store,
        standaloneLabel: false,
        showErrors: store.form.meta.showErrors,
        disabled: disabled || (cannotCreateBorrower && isNewBorrower),
      })}
    </>
  );
});

export const NumberOfVerifiedInvestmentProperties = observer(
  ({ store, disabled }: { store: BorrowerFormStore, disabled?: boolean }) => {
    const { cannotCreateBorrower, isNewBorrower } = store;

    return (
      <>
        {createSelectField({
          store,
          testId: 'numberOfVerifiedInvestmentProperties',
          fieldName: 'numberOfVerifiedInvestmentProperties',
          label: 'Number of Verified Investment Properties',
          options: verifiedInvestmentPropertiesOptions,
          standaloneLabel: false,
          disabled: disabled || (cannotCreateBorrower && isNewBorrower),
          isRequired: true
        })}
      </>
    );
  }
);

export const Assets = observer(
  ({ store, optional }: { store: BorrowerFormStore; optional?: boolean }) => {
    const { cannotCreateBorrower, isNewBorrower } = store;
    return (
      <>
        {createCurrencyField({
          label: 'Assets',
          fieldName: 'assets',
          testId: 'assets',
          store,
          standaloneLabel: false,
        })}
      </>
    );
  }
);

export const Liabilities = observer(
  ({ store, optional }: { store: BorrowerFormStore; optional?: boolean }) => {
    const { cannotCreateBorrower, isNewBorrower } = store;
    return (
      <>
        {createCurrencyField({
          label: 'Liabilities',
          fieldName: 'liabilities',
          testId: 'liabilities',
          store,
          standaloneLabel: false,
          disabled: cannotCreateBorrower && isNewBorrower,
        })}
      </>
    );
  }
);

export const NetWorth = observer(
  ({ store, optional }: { store: BorrowerFormStore; optional?: boolean }) => {
    const assets = store.form.fields.assets.value
      ? store.form.fields.assets.value
      : 0;
    const liabilities = store.form.fields.liabilities.value
      ? store.form.fields.liabilities.value
      : 0;
    const netWorth = formatCurrency(assets - liabilities);

    return <ItemDisplay label="Net Worth" value={netWorth} showBackground />;
  }
);

const useSliderStyles = makeStyles(theme => ({
  slider: {
    marginTop: '2.5em',
    marginLeft: '2em',
    width: '85%',
  },
  helperText: {
    marginTop: theme.spacing(2),
  },
}));

export const OwnershipPercentage = observer(({ store }) => {
  const formFields = store.form.fields;
  const classes = useSliderStyles();
  const fieldName = 'percentageOfOwnership';
  const { cannotCreateBorrower, isNewBorrower } = store;

  return (
    <>
      <FieldLabel>
        {'What percentage of the borrowing entity does this borrower own?*'}
      </FieldLabel>
      <Slider
        testId={fieldName}
        key={formFields.borrowerId.value}
        onChangeCommitted={(event, value) =>
          store.onFieldChange(fieldName, value)
        }
        className={classes.slider}
        value={formFields[fieldName].value}
        valueLabelDisplay="on"
        valueLabelFormat={value => <div>{formFields[fieldName].value}%</div>}
        disabled={cannotCreateBorrower && isNewBorrower}
        marks={[
          {
            value: 0,
            label: '0%',
          },
          {
            value: 100,
            label: '100%',
          },
        ]}
        step={1}
        min={0}
        max={100}
      />
    </>
  );
});
