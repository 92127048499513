import { Box, Grid, Step, StepContent, StepLabel, Stepper, Container } from '@material-ui/core';
import { observer } from 'mobx-react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Button } from '@roc/ui';
import { TrackRecordFirstStep } from './subcomponents/trackRecordFirstStep/trackRecordFirstStep';
import { TrackRecordSecondStep } from './subcomponents/trackRecordSecondStep/trackRecordSecondStep';
import { TrackRecordThirdStep } from './subcomponents/trackRecordThirdStep/trackRecordThirdStep';
import { useEffect } from 'react';
import { PreScreenBorrowerTierRating } from '@roc/feature-borrowers';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles((theme: Theme) => {
  return {
    button: {
      marginRight: theme.spacing(1),
    },
    reverseWrap: {
      flexWrap: 'wrap-reverse'
    }
  };
});

const STEPS = [
  'Track Record',
  'Borrower Entities',
  'GC / Real Estate Broker',
];

export const BorrowerTrackRecordVerification = observer(({
  token,
  isInternal = false,
  isTpoFlow = false,
  store,
  showBanner = true,
  borrowerId = null,
  onDocUploadCallback = () => { }
}) => {
  const { borrowerTrackRecordVerificationStore } = store;
  const { borrowerTier, activeStep } = borrowerTrackRecordVerificationStore;
  const classes = useStyles();

  useEffect(() => {
    borrowerTrackRecordVerificationStore.setCurrentToken(token);
    borrowerTrackRecordVerificationStore.checkBorrowerId(borrowerId);
    borrowerTrackRecordVerificationStore.fetchBorrowerInfo();
    borrowerTrackRecordVerificationStore.resetActiveStep();
    borrowerTrackRecordVerificationStore.setIsTpoFlow(isTpoFlow);
  }, []);

  const getStepContent = (step: number) => {
    if (step === 0) {
      return <TrackRecordFirstStep store={borrowerTrackRecordVerificationStore} onDocUploadCallback={onDocUploadCallback} />
    }
    if (step === 1) {
      return <TrackRecordSecondStep store={borrowerTrackRecordVerificationStore} />;
    }
    if (step === 2) {
      return <TrackRecordThirdStep store={borrowerTrackRecordVerificationStore} />;
    }
    return <></>;
  }

  const handleBack = () => {
    borrowerTrackRecordVerificationStore.goPrevStep();
  }

  const handleNext = () => {
    if (activeStep === 0) {
      borrowerTrackRecordVerificationStore.validateTrackRecordTemplate();
    }
    else if (activeStep === 2) {
      borrowerTrackRecordVerificationStore.saveAndUploadGCAndBrokerData((token) => store.fetchPublicFormInformation(token));
    }
    else {
      borrowerTrackRecordVerificationStore.goNextStep();
    }
  }

  return (
    <>
      {showBanner && (
        <Container>
          <Box mb={10}>
            <Alert severity="info">
              If you have already sent this information, you do not need to fill it out again.
            </Alert>
          </Box>
        </Container>
      )}


      <Grid container spacing={2} className={classes.reverseWrap}>
        <Grid item xs={12} md={10} xl={11} style={{ marginTop: '-24px' }}>
          <Stepper activeStep={borrowerTrackRecordVerificationStore.activeStep} orientation="vertical">
            {STEPS.map((label, index) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
                <StepContent TransitionProps={{ unmountOnExit: false }} >
                  <Grid container direction='row' spacing={2} justifyContent='center'>
                    <Grid item xs={11}>
                      {getStepContent(index)}
                    </Grid>
                    <Grid item xs={11}>
                      {activeStep != 0 && (
                        <Button
                          disabled={activeStep === 0}
                          onClick={handleBack}
                          testId={`back-${activeStep + 1}`}
                          className={classes.button}
                          color='primary'
                          variant='outlined'
                        >
                          Previous Step
                        </Button>
                      )}
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        testId={activeStep === STEPS.length - 1 ? `finish` : `next-${activeStep + 1}`}
                        className={classes.button}
                      >
                        {activeStep === STEPS.length - 1 ? 'Save and Finish' : 'I\'m Done. Next'}
                      </Button>
                    </Grid>
                  </Grid>
                </StepContent>
              </Step>
            ))}
          </Stepper>
        </Grid>
        <Grid item xs={12} md={2} xl={1}>
          {!!borrowerTier && (
            <Box display="flex" justifyContent="flex-end">
              <PreScreenBorrowerTierRating
                bridgeTier={borrowerTier.bridgeTier}
                propertiesToNextBridgeTier={borrowerTier.propertiesToNextBridgeTier}
                rentalTier={borrowerTier.rentalTier}
                propertiesToNextRentalTier={borrowerTier.propertiesToNextRentalTier}
              />
            </Box>
          )}
        </Grid>
      </Grid>
    </>
  );
});
