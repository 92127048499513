import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import {
  Layout, WhiteLabel
} from '@roc/feature-app-core';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { Redirect, useParams } from 'react-router';
import { Tabs } from '@roc/ui';
import { useRoutes, useStore } from '../../hooks';
import { lenderStatus } from '../lenderDetails/utils/constants';
import { Economics } from './economics/economics';
import { MailingLists } from './mailingLists/mailingLists';
import { SubscriptionDetails } from './subscriptionDetails/subscriptionDetails';
import { insertIf, isNotBlank } from '@roc/feature-utils';
import { LoanFundingPreferences } from './loanFundingPreferences/loanFundingPreferences';
import { EntityInformation } from './loanFundingPreferences/entityInformation';
import { DefaultFees } from './defaultFees/defaultFees';
import { ManageUsers, ManageUsersBackUps } from '@roc/feature-manage-users';
import { CompanyBankInformation } from 'libs/feature-backoffice-users/src/backOfficeUsers/components/companyProfile/titleCompanyBankInformation/companyBankInformation';

const MAILING_LISTS_TAB = 'Mailing Lists';
const ECONOMICS_TAB = 'Economics';
const DEFAULT_FEES_TAB = 'Fees';
const PREFERENCES = 'Preferences';
const LOAN_FUNDING_PREFERENCES_TAB = 'Loan Funding Preferences';
const USERS = 'USERS';
const BANK_INFORMATION = 'BANK INFORMATION';
const USERS_BACKUPS = 'USERS BACKUPS';
const WHITE_LABEL = 'WHITE LABEL';
const LENDER_OVERVIEW = 'Lender Overview';

const useStyles = makeStyles(theme =>
  createStyles({
    grid: {
      padding: theme.spacing(3),
    },
    paper: {
      padding: theme.spacing(2),
      marginTop: theme.spacing(1),
      borderRadius: theme.spacing(2),
      boxShadow:
        'rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px',
    },
  })
);

export const CompanyProfile = observer(() => {
  const { selectedTab } = useParams<{ selectedTab?: string }>();
  const { lenderDetailsRoutesConfig } = useRoutes();
  const classes = useStyles();
  const { companyProfileStore, globalStore, manageUsersStore, manageUsersBackUpStore } = useStore();
  const tabIndex = companyProfileStore.selectedTabIndex;
  useEffect(() => {
    companyProfileStore.salesforceLenderStore.fetchSalesforceLender();
    if (selectedTab) {
      companyProfileStore.getIndexFromTab(TABS, selectedTab)
    }
  }, []);

  const onTabChange = (e, index) => {
    companyProfileStore.setSelectedTabIndex(index);
  };

  const TABS = [
    {
      label: LENDER_OVERVIEW,
    },
    {
      label: ECONOMICS_TAB,
    },
    ...insertIf(globalStore.userFeatures?.adminPortalConfiguration || globalStore.userFeatures?.backOfficeUser, [
      {
        label: USERS,
      },
      {
        label: BANK_INFORMATION,
      },
      {
        label: USERS_BACKUPS,
      },
    ]),
    ...insertIf(globalStore.userFeatures?.adminPortalConfiguration, [
      {
        label: WHITE_LABEL,
      },
    ]),
    ...insertIf((globalStore.userFeatures?.showLoanFundingPreference && globalStore.userFeatures?.adminPortalConfiguration) || (globalStore.userFeatures?.showLoanFundingPreference && globalStore.userFeatures?.backOfficeUser), [{
      label: LOAN_FUNDING_PREFERENCES_TAB,
    }]),
    {
      label: PREFERENCES,
    },
    ...insertIf(globalStore.userFeatures?.showMailingLists, [{
      label: MAILING_LISTS_TAB,
    }]),
  ];

  const lender = companyProfileStore.salesforceLenderStore.salesForceLender;
  if (lender && lender.status === lenderStatus.APPLICATION_IN_PROGRESS) {
    return <Redirect to={lenderDetailsRoutesConfig.lenderDetails.url} />;
  }

  return (
    <Layout title="Company Profile" maxWidth={'lg'}>
      <Tabs tabs={TABS} selectedTab={tabIndex} onTabChange={onTabChange} />
      {TABS[tabIndex]?.label === LENDER_OVERVIEW && <EntityInformation companyProfileStore={companyProfileStore} />}
      {TABS[tabIndex]?.label === DEFAULT_FEES_TAB && <DefaultFees companyProfileStore={companyProfileStore} />}
      {TABS[tabIndex]?.label === ECONOMICS_TAB && <Economics companyProfileStore={companyProfileStore} />}
      {TABS[tabIndex]?.label === USERS && <ManageUsers manageUsersStore={manageUsersStore} />}
      {TABS[tabIndex]?.label === BANK_INFORMATION && <CompanyBankInformation companyProfileStore={companyProfileStore} />}
      {TABS[tabIndex]?.label === USERS_BACKUPS && <ManageUsersBackUps manageUsersBackUpStore={manageUsersBackUpStore} />}
      {(TABS[tabIndex]?.label === WHITE_LABEL && globalStore.userFeatures?.adminPortalConfiguration) && <WhiteLabel />}
      {TABS[tabIndex]?.label === LOAN_FUNDING_PREFERENCES_TAB && <LoanFundingPreferences companyProfileStore={companyProfileStore} />}
      {TABS[tabIndex]?.label === PREFERENCES && <SubscriptionDetails />}
      {TABS[tabIndex]?.label === MAILING_LISTS_TAB && <MailingLists />}
    </Layout>
  );
});
