import { Alert } from '@material-ui/lab';
import { VerticalBarLoader } from '@roc/ui';
import { observer } from 'mobx-react';
import { useEffect, useRef } from 'react';
import { useStore } from '../../../hooks';
import { IframeHolder } from '@roc/client-portal-shared/components';

export const DocumentSigning = observer(() => {
  const { creditAuthorizationStore } = useStore();

  useEffect(() => {
    creditAuthorizationStore.submitFormToDocusign();
  }, []);

  if (creditAuthorizationStore.docusignError) {
    return <Alert severity="error">Error loading document signing view</Alert>;
  }

  return (
    <>
      {creditAuthorizationStore.docusignUrl ? (
        <IframeHolder
          id="credit-authorization-form"
          src={creditAuthorizationStore.docusignUrl}
          width="100%"
          height="100%"
        />
      ) : (
        <VerticalBarLoader />
      )}
    </>
  );
});
