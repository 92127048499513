import react, { useState, useEffect } from 'react';
import {
  InputBase,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Typography,
  Chip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { ConfirmDialog, IconButton, Button, StandardDialog, TextField, Tooltip } from '@roc/ui';
import { Chat, Help } from '@material-ui/icons';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import { Paper } from '@roc/ui';
import clsx from 'clsx';
import { Category, CategoryDB, SubcategoryDB } from '../../../types';
import { alpha } from '@material-ui/core/styles';
import { ScopeOfWorkV2FormBaseStore } from '@roc/feature-sow-shared/stores/v2';
import { Delete, Edit } from '@material-ui/icons';
import { formatCurrency } from '@roc/ui/utils';
import { observer } from 'mobx-react';
import {
  Checkbox
} from '@material-ui/core';
import { roundHalf } from '@roc/feature-utils';

const filter = createFilterOptions();

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    minWidth: 750,
    border: '2px #e0e0e0 solid',
    '& td': {
      height: '40px',
      padding: 0,
      paddingTop: '8px',
      paddingBottom: '8px',
    },
    '& th:first-child, td[rowSpan], $addNewItemRow td': {
      paddingLeft: theme.spacing(2),
      paddingRight: '3px',
    },
    '& table tr:last-child td, & table td[rowSpan]': {
      borderBottom: 'none',
    },
  },

  editableRow: {
    position: 'relative',
    cursor: 'pointer',
    '&:hover td': {
      backgroundColor: '#fdefbf',
      transition: theme.transitions.create(['background-color', 'transform'], {
        duration: theme.transitions.duration.standard,
      }),
    },
    '&:hover $rowActions': {
      visibility: 'visible',
    },
    '& td:last-child': {
      [theme.breakpoints.down('md')]: {
        paddingRight: '100px',
      },
    },
  },

  headerCell: {
    height: '41px',
    fontWeight: 'bold',
    marginRight: '3px',
    padding: 0,
  },

  titleCell: {
    height: '46px',
    padding: 0,
    borderBottom: '2px #e0e0e0 solid',
  },

  descriptionCell: {
    whiteSpace: 'pre-wrap',
  },

  inputBase: {
    width: '100%',
    '& input': {
      backgroundColor: '#fdefbf',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      fontSize: 14,
      color: 'black',
      fontWeight: theme.typography.fontWeightBold,
      padding: '8px 4px',
      cursor: 'pointer',
      '&:focus': {
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  autocompletePaper: {
    marginLeft: 0,
    marginRight: 0,
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    fontSize: 13,
  },
  autocompleteOption: {
    minHeight: 'auto',
    alignItems: 'flex-start',
    padding: 8,
    '&[data-focus="true"]': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  addNewItemCell: {
    padding: 0,
    height: '40px',
    backgroundColor: '#fdefbf',
  },
  addNewItemRow: {
    '&:hover': {
      backgroundColor: '#fff',
    },
  },
  rowActions: {
    visibility: 'hidden',
    position: 'absolute',
    right: 0,
    top: 0,
    bottom: 0,
    zIndex: 99,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      visibility: 'visible',
    },
  },
  horizontalScroll: {
    [theme.breakpoints.down('md')]: {
      overflowX: 'scroll',
    },
  },
  clickHereToEdit: {
    color: theme.palette.grey[400],
    fontSize: theme.typography.fontSize * 0.8,
  },
  clickHereToEditHidden: {
    visibility: 'hidden',
  },
  title: {
    color: '#333333',
    fontSize: 20,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    paddingRight: '10px'
  },
  div: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

interface ITableCard {
  store: ScopeOfWorkV2FormBaseStore;
  title: string | JSX.Element;
  rows: Array<any>;
  columns: Array<any>;
  readonly?: boolean;
  addNewItemRows?: any[];
  category: CategoryDB;
  showReviewColumns?: boolean;
  handleAddNewItemChange?: (event, value) => void;
  tooltip?: string;
}

const AddNewLineInput = ({ options, onAdd, categoryId, categoryName }) => {
  const classes = useStyles();
  const [inputValue, setInputValue] = useState('');

  const handleChange = (event, subcategory) => {
    setInputValue('');
    onAdd(subcategory);
  };

  const filterOptions = (options, params) => {
    const filtered = filter(options, params);
    const { inputValue } = params;
    const isExisting = options.some(option => inputValue === option.label);
    if (inputValue !== '' && !isExisting) {
      const addNew = {
        label: `Add "${inputValue}"`,
        categoryId,
        subcategoryId: null,
        name: inputValue,
      };
      return [addNew, ...filtered];
    } else {
      return filtered;
    }
  };


  return (
    <Autocomplete
      id="add-new-item"
      selectOnFocus
      handleHomeEndKeys
      blurOnSelect
      value={null}
      options={options}
      classes={{
        paper: classes.autocompletePaper,
        option: classes.autocompleteOption,
      }}
      onChange={handleChange}
      inputValue={inputValue}
      onInputChange={(e, val) => setInputValue(val)}
      renderInput={params => (
        <InputBase
          ref={params.InputProps.ref}
          inputProps={params.inputProps}
          autoFocus
          className={classes.inputBase}
          placeholder="+ Add New Line Item"
        />
      )}
      getOptionLabel={option => option.label}
      filterOptions={filterOptions}
      fullWidth
    />
  );
};

const RowActions = ({ subcategory, onEdit, onDelete }) => {
  const classes = useStyles();

  return (
    <div className={classes.rowActions}>
      <IconButton
        aria-label="edit"
        testId={`edit-subcat-${subcategory.name}`}
        onClick={e => {
          onEdit();
          e.stopPropagation();
        }}
      >
        <Edit />
      </IconButton>
      <IconButton
        aria-label="edit"
        testId={`edit-subcat-${subcategory.name}`}
        onClick={e => {
          onDelete();
          e.stopPropagation();
        }}
      >
        <Delete />
      </IconButton>
    </div>
  );
};

const ReviewCommentForm = observer(({ comment, onClose, onSave }) => {
  const [commentValue, setCommentValue] = useState(comment);

  const onChange = value => {
    setCommentValue(value);
  }

  const handleSave = () => {
    onSave(commentValue);
    onClose();
  }

  return (<Grid container justifyContent='flex-end' direction="column">
    <Grid item xs={12}>
      <TextField
        testId="comment"
        multiline
        variant="outlined"
        label=""
        value={commentValue}
        onChange={e => onChange(e.target.value)}
        fullWidth
      />
    </Grid>
    <Grid item container xs={12} justifyContent="flex-end" style={{ paddingTop: 16 }}>
      <Grid item style={{ paddingRight: 16 }}>
        <Button
          testId="close-button"
          variant="outlined"
          color="primary"
          onClick={onClose}
        >
          Close
        </Button>
      </Grid>
      <Grid item>
        <Button
          testId="save-button"
          color="primary"
          variant="contained"
          onClick={handleSave}
        >
          Save
        </Button>
      </Grid>
    </Grid>
  </Grid>)
});

const StatusChip = ({ value }) => (
  <Chip
    size="small"
    label={value}
    variant='outlined'
    style={{
      color: '#F5B945',
      borderColor: '#F5B945',
      backgroundColor: 'white',
      fontWeight: 700,
      borderStyle: 'solid',
      borderWidth: 1
    }}
  />
)

const calculateTotal = (rows) => {
  return rows.reduce((acc, row) => {
    const rowCost = +row.cost || 0;
    const detailsCost = row.details && Array.isArray(row.details) ?
      row.details.reduce((accDetail, detail) => accDetail + (+detail.cost || 0), 0) : 0;
    return acc + rowCost + detailsCost;
  }, 0);
};

export const SOWTableCard = observer(
  ({
    store,
    columns,
    title,
    rows,
    readonly,
    addNewItemRows,
    showReviewColumns = false,
    category,
    tooltip,
  }: ITableCard) => {
    const classes = useStyles();
    const { categoryStore, scopeOfWorkAuditLogs } = store;
    const [selectedComment, setSelectedComment] = useState<string>('');
    const [isOpenCommentDialog, setIsOpenCommentDialog] = useState<boolean>(false);
    const [selectedRow, setSelectedRow] = useState<any>({});
    const [selectedDetail, setSelectedDetail] = useState<any>('');
    const total = calculateTotal(rows);

    const openCommentDialog = (ev, comment, row, detail) => {
      ev.preventDefault();
      ev.stopPropagation();
      setSelectedComment(comment);
      setIsOpenCommentDialog(true);
      setSelectedRow(row);
      setSelectedDetail(detail);
    }

    const [subcategoryToRemove, setSubcategoryToRemove] = useState(null);
    const [updated, setUpdated] = useState(false)

    const handleAddNewItem = subcategory => {
      categoryStore.createSubcategory(subcategory);
    };

    const handleItemCheck = (e, subcategory, detail) => {
      categoryStore.selectSubCategory(e.target.checked, subcategory, detail);
    };

    const handleEdit = (subcategory: SubcategoryDB) => {
      categoryStore.editSubcategory(subcategory);
    };

    const handleDelete = (subcategory: SubcategoryDB, categoryName: string) => {
      if (subcategory.isDefault) {
        categoryStore.cleanSubcategory(subcategory);
        const logToRemove = {
          category: categoryName,
          subcategory: subcategory.name,
          actionPerformed: 'REMOVED',
          columnChanged: null,
          previousValue: null,
          newValue: null
        }
        scopeOfWorkAuditLogs.push(logToRemove)
      } else {
        categoryStore.deleteSubcategory(subcategory);
        const logToRemove = {
          category: categoryName,
          subcategory: subcategory.name,
          actionPerformed: 'REMOVED',
          columnChanged: null,
          previousValue: null,
          newValue: null
        }
        scopeOfWorkAuditLogs.push(logToRemove)
      }
    };

    const getRemaining = (row: any) => {
      return row.capex ? row.cost - ((row.capex * row.cost) / 100) : row.cost;
    }

    const getCapex = (lineitem: any) => {
      if (lineitem.capex && lineitem.capex > 0) {
        return `${lineitem.capex}% | ${formatCurrency((lineitem.capex * lineitem.cost) / 100)}`;
      } else {
        return '0% | $0';
      }
    }

    const hasTooltip = (tooltip : any) => {
      return typeof tooltip === 'string' && tooltip.trim() !== '';
    }
    useEffect(() => {
        if(category.name === 'LTC Catchup' && !updated){
        categoryStore.updateSubcategory(category.subCategories[0], {
                    ...category.subCategories[0],
                    cost: store?.ltcCatchupAmount,
        })
        setUpdated(true)
      }
    }, [category])

    return (
      <div className={classes.horizontalScroll}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell colSpan={columns.length} className={classes.titleCell}>
                <div className={classes.div}>
                  <div className={classes.div}>
                    <Typography className={classes.title}>{`${title}`}</Typography>
                    {hasTooltip(tooltip) && <Tooltip title={tooltip} placement='right' arrow><Help color='secondary'/></Tooltip>}
                  </div>
                  <Typography className={classes.title}>{`Sub Total ${formatCurrency(total)}`}</Typography>
                </div>
              </TableCell>
            </TableRow>
            <TableRow>
              {columns.map(({ name, ...props }) => (
                <TableCell key={name} className={classes.headerCell} {...props} style={{ paddingLeft: '8px' }}>
                  {name}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {rows.map((row, index) => {
            let lineItems =
              row.details.length > 0
                ? row.details
                : [
                  {
                    cost: row.cost,
                    description: row.description,
                    reviewStatus: row.reviewStatus,
                    comments: row.comments,
                    capex: row.capex ? row.capex : 0,
                    selected: row?.selected ?? false,
                  },
                ];
            if(row.name === 'LTC Catchup' && store?.ltcCatchupAmount){
              lineItems = [
                  {
                    cost: store?.ltcCatchupAmount,
                    description: row.description,
                    reviewStatus: row.reviewStatus,
                    comments: row.comments,
                    capex: row.capex ? row.capex : 0,
                    selected: row?.selected ?? false,
                  },
                ];
            }
            return (
              <TableBody
                key={`${row?.value}_${index}`}
                className={!readonly ? classes.editableRow : ''}
              >
                {lineItems.map((lineitem, idx) => (
                  <TableRow
                    style={{ backgroundColor: store.getSubCategoryStyle(row.categoryId, row.name) }}
                    key={lineitem.name + ' ' + index}
                    onClick={() => !readonly && handleEdit(row)}
                  >

                    {showReviewColumns && (
                      <TableCell
                        width={columns[0].width}
                        style={{ backgroundColor: store.getItemStyle(row.categoryId, row.name, lineitem.name) }}
                      >

                        <Checkbox
                          key={lineitem.name + ' ' + index + ' checkbox'}
                          checked={lineitem.selected}
                          onChange={(e) => {
                            handleItemCheck(e, row, lineitem);
                          }}
                        />
                      </TableCell>
                    )
                    }
                    {idx === 0 && (
                      <TableCell
                        width={columns[0]?.width}
                        rowSpan={lineItems.length}
                        style={{ backgroundColor: store.getSubCategoryStyle(row.categoryId, row.name) }}
                      >
                        {row.name}

                        {!readonly && (
                          <RowActions
                            subcategory={row}
                            onEdit={() => handleEdit(row)}
                            onDelete={() => setSubcategoryToRemove(row)}
                          />
                        )}
                      </TableCell>
                    )}
                    <TableCell width={columns[1]?.width} style={{ backgroundColor: store.getItemStyle(row.categoryId, row.name, lineitem.name), paddingLeft: '8px' }}>
                      {lineitem.name}
                    </TableCell>
                    {lineitem.cost ? (
                      <>
                        <TableCell width={columns[2]?.width} style={{ backgroundColor: store.getItemStyle(row.categoryId, row.name, lineitem.name) }} >
                          {formatCurrency(lineitem.cost)}
                        </TableCell>
                        <TableCell align='center' width={columns[3]?.width} style={{ backgroundColor: store.getItemStyle(row.categoryId, row.name, lineitem.name) }} >
                          {getCapex(lineitem)}
                        </TableCell>
                        <TableCell align='center' width={columns[4]?.width} style={{ backgroundColor: store.getItemStyle(row.categoryId, row.name, lineitem.name) }} >
                          {formatCurrency(getRemaining(lineitem))}
                        </TableCell>
                        <TableCell
                          className={classes.descriptionCell}
                          width={columns[5]?.width}
                          style={{ backgroundColor: store.getItemStyle(row.categoryId, row.name, lineitem.name), paddingLeft: '10px' }}
                        >
                          {lineitem.description}
                        </TableCell>

                      </>
                    ) : (
                      <TableCell
                        className={`${classes.clickHereToEdit} ${store.isDisabled && classes.clickHereToEditHidden}`}
                        colSpan={5}
                        width={'58.5%'}
                        align="center"
                      >
                        CLICK HERE TO EDIT
                      </TableCell>
                    )}

                    {showReviewColumns &&
                      <>
                        <TableCell
                          className={classes.descriptionCell}
                          width={columns[1]?.width}
                          style={{ backgroundColor: store.getItemStyle(row.categoryId, row.name, lineitem.name) }}
                        >
                          {lineitem.reviewStatus === 'Needs Attention'
                            ? <StatusChip value={lineitem.reviewStatus} />
                            : <div>{lineitem.reviewStatus}</div>}
                        </TableCell>
                        <TableCell align="center"
                          width={columns[1]?.width}
                        >
                          {
                            lineitem?.comments?.length ? (
                              <Chat color="primary" style={{ cursor: 'pointer' }}
                                onClick={ev => { openCommentDialog(ev, lineitem.comments, row, lineitem); }}
                              />
                            ) : (
                              <Chat color="disabled" style={{ cursor: 'pointer' }}
                                onClick={ev => openCommentDialog(ev, '', row, lineitem)} />
                            )
                          }
                        </TableCell>
                      </>
                    }
                  </TableRow>
                ))}
              </TableBody>
            );
          })}
          <TableBody>
            {!readonly && (
              <TableRow className={classes.addNewItemRow}>
                <TableCell colSpan={2} className={classes.addNewItemCell}>
                  <AddNewLineInput
                    options={addNewItemRows}
                    categoryId={category.categoryId}
                    categoryName={category.name}
                    onAdd={handleAddNewItem}
                  />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <ConfirmDialog
          open={!!subcategoryToRemove}
          body={`This will delete all of the information entered for this Subcategory. Are you sure you want to remove it?`}
          handleClose={() => setSubcategoryToRemove(null)}
          handleCancel={() => setSubcategoryToRemove(null)}
          handleConfirm={() => {
            setSubcategoryToRemove(null);
            handleDelete(subcategoryToRemove, category.name);
          }}
          cancelButtonText={'Cancel'}
          confirmButtonText={'Yes, Remove'}
        />

        <StandardDialog
          open={isOpenCommentDialog}
          title="Comment"
          maxWidth="md"
          handleClose={() => setIsOpenCommentDialog(false)}
          dialogContent={
            <ReviewCommentForm
              comment={selectedComment}
              onClose={() => setIsOpenCommentDialog(false)}
              onSave={(commentValue) => {
                if (selectedRow.details.length) {
                  categoryStore.saveCommentDetailLineItem(selectedDetail.detailId, commentValue, selectedRow);
                } else {
                  categoryStore.saveComment(commentValue, selectedRow);
                }
              }}
            />
          }
        />
      </div>
    );
  }
);