import { Box, makeStyles, SvgIconProps } from "@material-ui/core";

interface styleProps {
  iconColor: string;
  backgroundColor: string;
}

const useStyles = makeStyles(() => ({
  iconContainer: ({ backgroundColor }: styleProps) => ({
    backgroundColor,
    borderRadius: "50%",
    padding: "10px",
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    width: "50px",
    height: "50px",
  }),
  icon: ({ iconColor }: styleProps) => ({
    color: iconColor
  })
}));

interface RoundedBackgroundIconProps {
  Icon: React.ComponentType<SvgIconProps>;
  backgroundColor?: string;
  iconColor?: string;
  size?: "inherid" | "medium" | "default" | "large" | "small" | undefined;
}



export const RoundedBackgroundIcon = ({ Icon, iconColor = "inherit", backgroundColor = "#E4E8EB", size = "large" }: RoundedBackgroundIconProps) => {
  const classes = useStyles({ iconColor, backgroundColor });

  return (
    <Box className={classes.iconContainer}>
      <Icon className={classes.icon} />
    </Box>
  );
}