import {
  Box,
  Grid
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import { Button, createSelectField, createTextField, StandardDialog } from '@roc/ui';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { CommunicationStore } from '../stores/communicationStore';
import { ManageParticipantsStore } from '../stores/manageParticipantsStore';
import { ChatRoomType, EMAIL_CHATROOM_TYPES_ALLOWED, UserOption } from '../types/communicationTypes';
import { isNil } from '@roc/feature-utils';
import { SelectUserAutocompleteVirtualized } from './selectUserAutocomplete';


const options = [{ label: 'Email', value: 'Email' }, { label: 'Loan Chat', value: 'LoanChat' }];

const useDialogContentStyles = makeStyles(theme => ({
  addButtonContainer: {
    justifyContent: 'flex-end',
    marginTop: 32,
  },
  container: {
    minHeight: 175,
  },
}));

interface IAddNewParticipant {
  store: ManageParticipantsStore;
  option: UserOption;
  addNewParticipant: () => void;
  resetValues: () => void;
  allowAddInternalParticipants: boolean;
}

const AddNewParticipant: React.FC<IAddNewParticipant> = observer(
  ({ store, option, addNewParticipant, resetValues, allowAddInternalParticipants }) => {
    const dialogContentclasses = useDialogContentStyles();
    const currentChatRoomType = store?.communicationStore?.currentChatRoomType;
    useEffect(() => {
      store.externalParticipantStore.loadForm(option ?? {});
    }, []);

    return (
      <Grid container spacing={2}>
        <Grid item>
          <Alert severity='info'>
            By clicking the Add button below, the user will receive two emails.
            <ol style={{ margin: "8px 0px 0px 16px" }}>
              <li style={{ marginBottom: "8px" }}>
                Link to create a portal account for viewing the conversations securely (One time process per user and this email will be sent only if the user is added for the first time. All future conversations with the same email address will be associated to the same account.)
              </li>
              <li>
                Link to view this conversation and communicate securely.
              </li>
            </ol>
          </Alert>
        </Grid>
        <Grid item md={6} xs={12}>
          {createTextField({
            label: 'First Name',
            fieldName: 'firstName',
            testId: 'firstName',
            store: store.externalParticipantStore,
          })}
        </Grid>
        <Grid item md={6} xs={12}>
          {createTextField({
            label: 'Last Name',
            fieldName: 'lastName',
            testId: 'lastName',
            store: store.externalParticipantStore,
          })}
        </Grid>
        <Grid item md={12} xs={12}>
          {createTextField({
            label: 'Email Address',
            fieldName: 'emailAddress',
            testId: 'email',
            store: store.externalParticipantStore,
            type: 'email',
          })}
        </Grid>
        {EMAIL_CHATROOM_TYPES_ALLOWED.includes(currentChatRoomType) &&
          <Grid item md={12} xs={12}>
            {createSelectField({
              store: store.externalParticipantStore,
              testId: 'participantType',
              fieldName: 'participantType',
              label: 'Communicate Via',
              options: options,
            })}
          </Grid>}

        <Grid item container className={dialogContentclasses.addButtonContainer}>
          {
            allowAddInternalParticipants &&
            <Grid item style={{ marginRight: 12 }}>
              <Button
                color="default"
                onClick={resetValues}
                variant="outlined"
                fullWidth
                testId="goBack"
              >
                BACK
              </Button>
            </Grid>
          }
          <Grid item>
            <Button
              color="primary"
              onClick={addNewParticipant}
              disabled={!store.externalParticipantStore.form.meta.isValid}
              variant="contained"
              fullWidth
              testId="addExternalParticipant"
            >
              ADD
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  }
);

interface IAddParticipantDialog {
  communicationStore: CommunicationStore;
  handleAddNewParticipant: () => void;
  handleAddExistingParticipant: () => void;
  allowAddInternalParticipants: boolean;
  showEmailAddress: boolean;
  loanId: number;
}

export const AddParticipantDialog: React.FC<IAddParticipantDialog> = observer(
  ({ communicationStore, handleAddNewParticipant, handleAddExistingParticipant, allowAddInternalParticipants, showEmailAddress, loanId }) => {
    const {
      manageParticipantsStore,
      participants,
      currentConversationSid,
    } = communicationStore;
    const [newParticipant, setNewParticipant] = useState<UserOption>(null);
    const dialogContentclasses = useDialogContentStyles();


    useEffect(() => {
      if (isNil(loanId)) {
        manageParticipantsStore.getParticipantsAllowedByChatRoomId(communicationStore.currentChatRoomId, false);
      } else {
        manageParticipantsStore.getParticipantsAllowedByLoanId(loanId, false);
      }

    }, []);

    useEffect(() => {
      if (manageParticipantsStore.openAddParticipant) {
        resetValues();
      }
    }, [manageParticipantsStore.openAddParticipant]);

    const resetValues = () => {
      setNewParticipant(null);
      manageParticipantsStore.externalParticipantStore.reset();
    };

    const onSelectExistingUser = (option: UserOption[]) => {
      setNewParticipant(null);
      manageParticipantsStore.selectedParticipant = option;
    };

    const onSelectCreateNewUser = (option: UserOption) => {
      setNewParticipant(option);
    };

    return (
      <StandardDialog
        title={'Add Participant'}
        open={manageParticipantsStore.openAddParticipant}
        handleClose={() => manageParticipantsStore.closeAddParticipantDialog()}
        maxWidth={'sm'}
        removeContentBox={true}
        dialogContent={
          <Box flexGrow={1}>
            <Grid
              container
              spacing={2}
              className={dialogContentclasses.container}
            >
              {newParticipant || !allowAddInternalParticipants ? (
                <Grid item xs={12}>
                  <AddNewParticipant
                    store={manageParticipantsStore}
                    addNewParticipant={handleAddNewParticipant}
                    resetValues={resetValues}
                    option={newParticipant}
                    allowAddInternalParticipants={allowAddInternalParticipants}
                  />
                </Grid>
              ) : (
                <>
                  <Grid item container xs={12}>
                    <Grid item xs={12}>
                      <SelectUserAutocompleteVirtualized
                        options={manageParticipantsStore?.internalParticipants}
                        multiple={true}
                        showEmailAddress={showEmailAddress}
                        existingParticipants={
                          participants[currentConversationSid]
                        }
                        onSelectExistingUser={onSelectExistingUser}
                        allowCreateNewuser={true}
                        onSelectCreateNewUser={onSelectCreateNewUser}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    className={dialogContentclasses.addButtonContainer}
                  >
                    <Grid item>
                      <Button
                        color="primary"
                        onClick={handleAddExistingParticipant}
                        disabled={
                          manageParticipantsStore?.selectedParticipant?.length == 0
                        }
                        variant="contained"
                        fullWidth
                        testId="addParticipant"
                      >
                        ADD
                      </Button>
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
          </Box>
        }
      />
    );
  }
);
