import { GlobalStore } from '@roc/feature-app-core';
import { action, flow, makeObservable, observable } from 'mobx';
import { QuoteTypeStore } from '../quoteTypeStore';
import { PreflightStep, preflightSteps } from '../../utils/constants';

export class PreflightStore {
  private globalStore: GlobalStore;
  private quoteTypeStore: QuoteTypeStore;

  step: PreflightStep;
  nextStep: PreflightStep;
  itemIndex?: number;

  completedBorrowers: string[];
  completedProperties: string[];

  acknowledgeTerms: boolean;

  constructor(globalStore: GlobalStore, quoteTypeStore: QuoteTypeStore) {
    this.globalStore = globalStore;
    this.quoteTypeStore = quoteTypeStore;
    makeObservable(this, {
      reset: action,
      step: observable,
      nextStep: observable,
      itemIndex: observable,
      acknowledgeTerms: observable,
      completedBorrowers: observable,
      completedProperties: observable,
      toggleAcknowledgeTerms: action,
      validateCurrentStep: action,
      getNextItemIndex: action,
      goToStep: flow,
      goToNextStep: flow,
      loadPreflightState: action,
      addBorrower: action,
      resetPreflightSteps: action,
    });
  }

  *goToStep(step: PreflightStep, index?: number) {
    this.itemIndex = index ?? 0;
    if (preflightSteps.indexOf(step) < preflightSteps.indexOf(this.step)) {
      this.step = step;
    } else if (this.validateCurrentStep()) {
      this.step = step;
      this.updateNextStep();
      yield this.quoteTypeStore.saveQuote();
    }
  }

  *goToNextStep() {
    if (this.validateCurrentStep()) {
      const nextItemIndex = this.getNextItemIndex();
      if (nextItemIndex !== null) {
        yield this.goToStep(this.step, nextItemIndex);
      } else {
        const stepIndex = preflightSteps.indexOf(this.step);
        const nextStep = preflightSteps?.[stepIndex + 1];
        yield this.goToStep(nextStep);
      }
    }
  }

  getNextItemIndex() {
    if (this.step === PreflightStep.BORROWER) {
      const borrowers = this.quoteTypeStore.borrowersStore.borrowerFormStores;
      return borrowers?.[this.itemIndex + 1] ? this.itemIndex + 1 : null;
    } else if (this.step === PreflightStep.PROPERTY) {
      const properties = this.quoteTypeStore.propertiesStore.propertyFormStores;
      return properties?.[this.itemIndex + 1] ? this.itemIndex + 1 : null;
    }
    return null;
  }

  private updateNextStep() {
    if (
      preflightSteps.indexOf(this.nextStep) < preflightSteps.indexOf(this.step)
    ) {
      this.nextStep = this.step;
    }
  }

  loadPreflightState(quoteData) {
    const uiState = quoteData?.uiState;
    if (uiState) {
      this.step = uiState.preflightStep;
      this.nextStep = uiState.preflightNextStep;
      this.completedBorrowers = uiState.completedBorrowers;
      this.completedProperties = uiState.completedProperties;
    }
  }

  getPrefligthState() {
    return {
      preflightStep: this.step,
      preflightNextStep: this.nextStep,
      completedBorrowers: this.completedBorrowers,
      completedProperties: this.completedProperties,
    };
  }

  validateCurrentStep() {
    const borrowerFormStore = this.quoteTypeStore.borrowersStore
      .borrowerFormStores?.[this.itemIndex];
    const propertyFormStore = this.quoteTypeStore.propertiesStore
      .propertyFormStores?.[this.itemIndex];

    switch (this.step) {
      case PreflightStep.BROKER:
        return this.quoteTypeStore.brokerStore.validateBroker_preflight();
      case PreflightStep.ENTITY:
        return this.quoteTypeStore.entityStore.validateEntity();
      case PreflightStep.BORROWER:
        if (borrowerFormStore) {
          this.completedBorrowers = this.completedBorrowers.concat(
            borrowerFormStore.key
          );
          return borrowerFormStore.validateBorrower(true);
        } else {
          return true;
        }
      case PreflightStep.PROPERTY:
        this.completedProperties = this.completedProperties.concat(
          propertyFormStore.key
        );
        return propertyFormStore.validateProperty(true);
      case PreflightStep.CLOSING:
        return this.quoteTypeStore.closingStore.validateClosingData();
      default:
        return true;
    }
  }

  isStepComplete(step: PreflightStep, itemIndex?: number) {
    if (preflightSteps.indexOf(step) <= preflightSteps.indexOf(this.nextStep)) {
      if (step === PreflightStep.BORROWER) {
        const key = this.quoteTypeStore.borrowersStore.borrowerFormStores[
          itemIndex
        ].key;
        return this.completedBorrowers.includes(key);
      } else if (step === PreflightStep.PROPERTY) {
        const key = this.quoteTypeStore.propertiesStore.propertyFormStores[
          itemIndex
        ].key;
        return this.completedProperties.includes(key);
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  addBorrower() {
    if (this.validateCurrentStep()) {
      this.quoteTypeStore.borrowersStore.addNewBorrower();
      const borrowerIndex =
        this.quoteTypeStore.borrowersStore.borrowerFormStores.length - 1;
      this.goToStep(PreflightStep.BORROWER, borrowerIndex);
    }
  }

  reset() {
    this.step = PreflightStep.BROKER;
    this.nextStep = PreflightStep.BROKER;
    this.acknowledgeTerms = false;
    this.itemIndex = 0;
    this.completedBorrowers = [];
    this.completedProperties = [];
  }

  toggleAcknowledgeTerms() {
    this.acknowledgeTerms = !this.acknowledgeTerms;
  }

  resetPreflightSteps() {
    const { brokerStore } = this.quoteTypeStore;
    const hasBroker = brokerStore.brokerFormStore.getFormValues()?.hasBroker;
    if (hasBroker) {
      this.step = PreflightStep.BROKER;
    } else {
      this.step = PreflightStep.ENTITY;
    }
  }
}
