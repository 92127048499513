import {
  Box, Dialog, DialogContent, useMediaQuery,
  DialogTitle as MuiDialogTitle,
  Typography,
  IconButton,
} from "@material-ui/core";
import { Theme, WithStyles, createStyles, useTheme, withStyles } from '@material-ui/core/styles';
import { Close } from "@material-ui/icons";
import { MersOnboarding } from "./mersOnBoarding";

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const styles = (theme: Theme) =>
  createStyles({
    dialogContetBox: {
      width: '100%',
      margin: 0,
      position: 'absolute',
      top: '50%',
      left: '50%',
      msTransform: 'translate(-50%, -50%)',
      transform: 'translate(-50%, -50%)',
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography {...other}>
      <Typography variant="h4">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <Close />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export default function MersOnboardingModal({
  open,
  handleClose,
  companyProfileStore
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleDialogClose = e => {
    handleClose(e);
  };

  return (
    <div>
      <Dialog
        disableEscapeKeyDown
        fullScreen={fullScreen}
        scroll={'paper'}
        fullWidth={true}
        maxWidth={'lg'}
        open={open}
        onClose={handleDialogClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle
          id="mers-on-boarding-title"
          onClose={() => handleDialogClose(null)}
        >
        </DialogTitle>
        <DialogContent>
          <MersOnboarding onClose={handleDialogClose} companyProfileStore={companyProfileStore} />
        </DialogContent>
      </Dialog>
    </div>
  )
}