import { observer } from 'mobx-react';
import { Grid } from '@material-ui/core';
import { PropertyInformationSteps } from '../../utils/constants';
import { PropertyInformation } from './propertyInformation';
import { EditPropertyInformation } from './editPropertyInformation';
import { BaseLoanTypeStore } from '../../stores/baseLoanTypeStore';

export const PropertiesInformationStep = observer(({ loanSubtype, store }) => {
  const step = store.propertiesStore.propertyInformationActiveStep;
  return (
    <Grid container direction="row" spacing={2}>
      {step === PropertyInformationSteps.PROPERTY_INFORMATION && (
        <PropertyInformation
          propertiesStore={store.propertiesStore}
          loanSubtype={loanSubtype}
        />
      )}
      {step === PropertyInformationSteps.EDIT_PROPERTY_INFORMATION && (
        <EditPropertyInformation
          propertiesStore={store.propertiesStore}
          loanSubtype={loanSubtype}
        />
      )}
    </Grid>
  );
});
