import { Box, Grid } from "@material-ui/core";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { CloseOutlined } from "@material-ui/icons";
import { HtmlMessageContent, IconButton } from "@roc/ui";
import { Message } from "@twilio/conversations";
import { getAuthorName } from "../helper";
import { lighten } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import { ConversationMessage } from "../types/communicationTypes";


export const ReplyToMessage = ({ message, onClose, onClick, replyToPrefix = '', style }: {
  message: Message | ConversationMessage;
  onClose?: () => void;
  onClick?: (replyMessageId) => void;
  replyToPrefix?: string;
  style?: CSSProperties;
}) => {
  const theme = useTheme();

  if (!message) {
    return null;
  }

  const inlineStyle = Object.assign({}, {
    padding: '2px 8px 2px 10px',
    borderBottom: '1px solid #ccc',
    borderLeft: '5px solid',
    borderLeftColor: theme.palette.primary.dark,
    background: lighten(theme.palette.primary.main, 0.9),
    width: '100%'
  }, style ?? {})

  return (
    <Grid container justifyContent="space-between" alignItems="center" style={inlineStyle}>
      <Grid item style={{
        flex: 1,
        overflow: 'hidden',
        position: 'relative'
      }}>
        <Box color={theme.palette.primary.dark}>
          <Box fontWeight={'bold'} mb={0.5}>
            {
              replyToPrefix && <Box component={'span'} pr={1}>{replyToPrefix}</Box>
            }
            <span>{getAuthorName(message)}</span>
          </Box>
          <Box color={theme.palette.primary.dark} overflow={'hidden'} textOverflow={'ellipsis'} maxHeight={46} fontSize={14}>
            <HtmlMessageContent html={message.body} />
          </Box>
        </Box>
        <Box style={{
          position: 'absolute',
          top: 44,
          height: 38,
          width: '100%',
          background: `-webkit-linear-gradient(top, #ffffff00 0%, ${lighten(theme.palette.primary.main, 0.9)} 100%)`
        }}></Box>
        <Box style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          top: 0,
          cursor: onClick ? 'pointer' : 'default'
        }} onClick={() => onClick(message.sid)}></Box>
      </Grid>
      {
        onClose && <Grid item>
          <IconButton
            style={{}}
            testId="clearReply"
            color="default"
            onClick={() => onClose()}
            size='small'
          >
            <CloseOutlined />
          </IconButton>
        </Grid>
      }
    </Grid>
  );
};