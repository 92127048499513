import React, { createContext, useEffect, useState } from 'react';
import { ThemeProvider as MuiThemeProvider, darken, lighten } from '@material-ui/core/styles';

import { getAppConfigurations } from './../theme';
import { AppConfigurations } from '@roc/feature-types';
import { useAppConfigurations, useThemeConfiguration } from './../hooks';
import { useBaseStore } from '@roc/feature-app-core';
import { observer } from 'mobx-react';
import { NotFound } from '@roc/ui';
import { useWhiteLabelStore } from '../hooks/useWhiteLabelStore';

export const AppConfigurationsContext = createContext<AppConfigurations>(null);

export const AppConfigurationsProvider = observer(({ children, overridePrimaryColor }) => {
  const { globalStore } = useBaseStore();
  const { whiteLabelStore } = useWhiteLabelStore();
  const { portalId } = globalStore.queryParams;
  const [appConfigurations, setAppConfigurations] = useState<AppConfigurations>();
  const themeOptions = useThemeConfiguration();

  useEffect(() => {
    whiteLabelStore.fetchPortalConfiguration(portalId);
  }, []);

  useEffect(() => {
    if (globalStore.portalConfiguration) {
      const appConfigurations = getAppConfigurations(
        globalStore.portalConfiguration,
        globalStore.isPreviewMode,
        globalStore.isLoanChatApp,
        themeOptions,
        overridePrimaryColor
      );
      setAppConfigurations(appConfigurations);
    }
  }, [globalStore.portalConfiguration]);

  if (whiteLabelStore.showErrorPage) {
    const el = document.getElementById('lds-ellipsis-container');
    if (el) el.remove();
    return (<NotFound />);
  }

  return (
    appConfigurations ?
      <AppConfigurationsContext.Provider value={appConfigurations}>
        {children}
      </AppConfigurationsContext.Provider > : null
  );
});

export function ThemeProvider({ children }) {
  const { muiTheme } = useAppConfigurations();
  return <MuiThemeProvider theme={muiTheme}>{children}</MuiThemeProvider>;
}
