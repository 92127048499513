import { Service } from '@roc/feature-app-core';

const url = {
  SUBMIT_LIQUIDITY_REPORT: '/api/v1/loan/liquidity/sendLiquidityEmail',
  SUBMIT_LIQUIDITY_REPORT_HELP: '/api/v1/loan/liquidity/liquidityReportHelp',
};

export class LiquidityReportService extends Service {
  submitLiquidityReport(data) {
    data.recipients = data.recipients? data.recipients.replace(/\s+/g,'').split(","):[];
    data.zipcodes = data.zipcodes? data.zipcodes.replace(/\s+/g,'').split(","):[];
    data.arv = data.arv.toString();
    return this.post(url.SUBMIT_LIQUIDITY_REPORT, data);
  }

  submitLiquidityReportHelp(data) {
    return this.post(url.SUBMIT_LIQUIDITY_REPORT_HELP, data);
  }
}
