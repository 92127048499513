import { Service } from '@roc/feature-app-core';

const url = {
  GET_BORROWER_LOAN: '/api/v1/loan/borrowerLoans/getBorrowerLoan',
};

export class BorrowerLoanDetailsService extends Service {
  getBorrowerLoan(loanId) {
    return this.get(url.GET_BORROWER_LOAN, { loanId });
  }
}
