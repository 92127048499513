import { Badge } from '@material-ui/core';
import { AttachMoney, Chat, CloudUpload } from '@material-ui/icons';
import { useLoanRoutes } from '@roc/feature-loans-routes-config';
import { TamariskPayment } from '@roc/feature-documents';

import {
  ActionsColumn,
  DataGrid as Grid, FileUpload, GridColumnProps, StandardDialog,

  LinkColumn,
  SelectFilterComponent,
  SelectFloatingFilterComponent, TextColumn
} from '@roc/ui';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { useAppraisalsStore } from '../../hooks';
import { AppraisalFileUploadModal } from './appraisalFileUploadModal';
const APPRAISAL_PAYMENT_LOAN = 'Appraisal_Loan';

const columns: GridColumnProps[] = [
  {
    field: 'loanId',
    headerName: 'Loan Id',
    minWidth: 100,
    cellRenderer: 'loanIdCellRenderer',
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'orderId',
    headerName: 'Order Id',
    minWidth: 110,
    cellRenderer: 'orderIdCellRenderer',
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'borrowerName',
    headerName: 'Borrower Name',
    minWidth: 110,
    cellRenderer: 'borrowerNameCellRenderer',
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'propertyAddress',
    headerName: 'Property Address',
    minWidth: 300,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'status',
    headerName: 'Status',
    minWidth: 120,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'orderCreationDate',
    headerName: 'Order Creation Date',
    minWidth: 170,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'originatorName',
    headerName: 'Originator',
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'actions',
    headerName: 'Actions',
    minWidth: 125,
    cellRenderer: 'actionsCellRenderer',
  },
];

export const LoanAppraisalsGrid = observer(({ onOpenCommunication, onSelectAppraisal = null }) => {
  const [currentAppraisal, setCurrentAppraisal] = useState(null);
  const [isFileUploadModalOpen, setIsFileUploadModalOpen] = useState(false);

  const { appraisalsStore } = useAppraisalsStore();

  const { loanRoutesConfig } = useLoanRoutes();

  const [isTamariskPaymentModalOpen, setIsTamariskModalOpen] = useState(false);

  const frameworkComponents = {
    selectFilter: SelectFilterComponent,
    selectFloatingFilter: SelectFloatingFilterComponent,
    loanIdCellRenderer: params => {
      return LinkColumn({
        ...params,
        url: loanRoutesConfig.loans(params.value).children.dashboard.url,
      });
    },
    orderIdCellRenderer: params => {
      if (!onSelectAppraisal) {
        return TextColumn(params);
      }

      return LinkColumn({
        ...params,
        url: '#',
        onClick: () => onSelectAppraisal(params.node.data),
      });
    },
    actionsCellRenderer: params =>
      ActionsColumn({
        ...params,
        actions: getActions(params),
      }),
  };

  const onFilterChanged = params => {
    const filterModel = params.api.getFilterModel();
    appraisalsStore.loanAppraisalsGridStore.setFilterModel(filterModel);
  };

  const getActions = params => [
    {
      icon: (
        <Badge badgeContent={params.node.data?.unreadMessages} color="primary">
          <Chat color="primary" />
        </Badge>
      ),
      onClick: () => onOpenCommunication(params.node.data),
    },
    {
      icon: <CloudUpload color="primary" />,
      onClick: () => openFileUploadModal(params.node.data),
    }
  ];

  const openTamariskPaymentModal = appraisal => {
    setCurrentAppraisal(appraisal);
    setIsTamariskModalOpen(true);
  };

  const closeTamariskPaymentModal = () => {
    setIsTamariskModalOpen(false);
    setCurrentAppraisal(null);
  };

  const openFileUploadModal = appraisal => {
    setCurrentAppraisal(appraisal);
    setIsFileUploadModalOpen(true);
  };

  const closeFileUploadModal = () => {
    setIsFileUploadModalOpen(false);
    setCurrentAppraisal(null);
  };

  const onUpload = (documentType: string, fileUpload: FileUpload) => {
    const {
      appraisalData: { propertyAppraisalId },
    } = currentAppraisal;
    appraisalsStore.uploadAppraisalDocument(
      propertyAppraisalId,
      true,
      documentType,
      fileUpload
    );
  };

  const onSortChanged = params => {
    const sortModel = params.api.getSortModel();
    appraisalsStore.loanAppraisalsGridStore.setSortModel(sortModel);
  };

  return (
    <>
      <Grid
        columns={columns}
        rows={appraisalsStore.loanAppraisalsGridStore.gridData.data.rows}
        frameworkComponents={frameworkComponents}
        onSortChanged={onSortChanged}
        onFilterChanged={onFilterChanged}
        isLoading={appraisalsStore.loanAppraisalsGridStore.isLoading}
        showFilters={appraisalsStore.loanAppraisalsGridStore.showFilters}
        sortModel={appraisalsStore.loanAppraisalsGridStore.sortModel}
        filterModel={appraisalsStore.loanAppraisalsGridStore.filterModel}
        paginationData={appraisalsStore.loanAppraisalsGridStore.gridData.meta}
        setPageNumber={appraisalsStore.loanAppraisalsGridStore.setPageNumber}
      />
      <AppraisalFileUploadModal
        open={isFileUploadModalOpen}
        values={currentAppraisal?.documentTypesList}
        onClose={closeFileUploadModal}
        onUpload={onUpload}
      />
      <StandardDialog
        open={isTamariskPaymentModalOpen}
        title="Tamarisk Payment"
        maxWidth="md"
        handleClose={closeTamariskPaymentModal}
        dialogContent={
          <TamariskPayment
            borrowersEmail={currentAppraisal?.borrowersEmail}
            tamariskURL={currentAppraisal?.tamariskUrl}
            entityId={currentAppraisal?.loanId}
            paymentStatus={currentAppraisal?.paymentStatus}
            type={APPRAISAL_PAYMENT_LOAN}
          />
        }
      />
    </>
  );
});
