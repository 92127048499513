import { GlobalStore } from '@roc/feature-app-core';
import {
  ScopeOfWorkV2LinkBaseStore,
  ScopeOfWorkV2Option,
} from './scopeOfWorkV2LinkBaseStore';
import { action, flow, makeObservable, observable } from 'mobx';
import { ScopeOfWorkV2Store } from './scopeOfWorkV2Store';

export class ScopeOfWorkV2LinkStore extends ScopeOfWorkV2LinkBaseStore {
  private scopeOfWorkV2Store: ScopeOfWorkV2Store;
  isFormDialogOpen = false;

  constructor(globalStore: GlobalStore, scopeOfWorkV2Store?: ScopeOfWorkV2Store) {
    super(
      globalStore,
      false,
      () => this.fetchScopesOfWorkData(),
      () => this.fetchScopesOfWorkCopyData(),
    );

    this.scopeOfWorkV2Store = scopeOfWorkV2Store;

    makeObservable(this, {
      tryAndLinkScopeOfWork: flow,
      linkScopeOfWork: flow,
      isFormDialogOpen: observable,
      openFormDialog: action,
      closeFormDialog: action,
      linkOrCreateStandAloneSOW: flow,
      handleNewScopeOfWork: action,
      handleError: action,
    });
  }

  *tryAndLinkScopeOfWork() {
    if (this.scopeOfWorkOption === ScopeOfWorkV2Option.LINK_EXISTING_SCOPE_OF_WORK && this.propertyAddress !== this.selectedScopeOfWork?.address) {
      this.addressMismatchDialogOpen = true;
    } else if (this.loanId && this.propertyId) {
      yield this.linkScopeOfWork();
    } else {
      yield this.linkOrCreateStandAloneSOW();
    }
  }

  *linkScopeOfWork() {
    try {
      let data;

      if (
        this.scopeOfWorkOption === ScopeOfWorkV2Option.LINK_EXISTING_SCOPE_OF_WORK
      ) {
        const {
          data: response,
        } = yield this.scopeOfWorkService.linkStandaloneScopeOfWork(
          this.loanId,
          this.propertyId,
          this.selectedScopeOfWork.scopeOfWorkId
        );
        data = JSON.parse(response.data);
      } else if (
        this.scopeOfWorkOption === ScopeOfWorkV2Option.COPY_SCOPE_OF_WORK
      ) {
        const {
          data: response,
        } = yield this.scopeOfWorkService.linkCopyScopeOfWork(
          this.loanId,
          this.propertyId,
          this.selectedScopeOfWork.scopeOfWorkId
        );
        data = JSON.parse(response.data);
      } else {
        const {
          data: response,
        } = yield this.scopeOfWorkService.linkNewScopeOfWork(
          this.loanId,
          this.propertyId
        );
        data = JSON.parse(response.data);
      }

      this.globalStore.notificationStore.showSuccessNotification({
        message:
          this.scopeOfWorkOption === ScopeOfWorkV2Option.COPY_SCOPE_OF_WORK
            ? 'Scope Of Work successfully copied'
            : 'Scope Of Work successfully linked',
      });

      if (!this.onSuccessLink) return;

      this.onSuccessLink(data);
    } catch (err) {
      this.globalStore.notificationStore.showErrorNotification({
        message:
          this.scopeOfWorkOption === ScopeOfWorkV2Option.COPY_SCOPE_OF_WORK
            ? 'An error occurred while trying to copy the Scope Of Work'
            : 'An error occurred while trying to link the Scope Of Work',
      });
    }
  }

  *linkOrCreateStandAloneSOW() {
    try {
      let data;

      if (this.scopeOfWorkOption === ScopeOfWorkV2Option.COPY_SCOPE_OF_WORK) {
        const { data: response } = yield this.scopeOfWorkService.linkCopyScopeOfWorkForStandAlone(this.selectedScopeOfWork.scopeOfWorkId);
        data = response.data;
        this.globalStore.notificationStore.showSuccessNotification({
          message: 'Scope Of Work successfully copied',
        });
      } else {
        this.handleNewScopeOfWork();
        return;
      }

      if (this.onSuccessLink && data) {
        this.onSuccessLink(data);
      }
    } catch (err) {
      this.handleError(err)
    }
  }

  openFormDialog() {
    this.isFormDialogOpen = true;
  }

  closeFormDialog() {
    this.isFormDialogOpen = false;
  }

  handleNewScopeOfWork() {
    this.scopeOfWorkV2Store?.setScopeOfWorkLinkFormDialogOpen(false);
    this.openFormDialog();
  }

  handleError(err: any) {
    console.error('Error in linkOrCreateStandAloneSOW:', err);

    const errorMessage =
      this.scopeOfWorkOption === ScopeOfWorkV2Option.COPY_SCOPE_OF_WORK
        ? 'An error occurred while trying to copy the Scope Of Work'
        : 'An error occurred while trying to create the New Scope Of Work';

    this.globalStore.notificationStore.showErrorNotification({
      message: errorMessage,
    });
  }

}
