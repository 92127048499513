import React from 'react';
import { Box, Grid as MuiGrid, Typography } from '@material-ui/core';
import { formatCurrency } from '@roc/ui/utils';
import { observer } from 'mobx-react';
import {
  Button,
  ConfirmationButton,
  DataGrid as Grid,
  IconButton,
} from '@roc/ui';
import { insertIf } from '@roc/feature-utils';
import { Add, Delete, Edit, PlusOne, Remove } from '@material-ui/icons';

export type OtherQuotesGridProps = {
  quotes: any[];
  disableActions?: boolean;
  addQuote: () => void;
  editQuote: (quote) => void;
  removeQuote: (quote) => void;
};

export const OtherQuotesGrid = observer((props: OtherQuotesGridProps) => {
  const { quotes, disableActions } = props;

  const columns = [
    {
      field: 'quoteType',
      headerName: 'Quote Type',
      minWidth: 260,
    },
    {
      field: 'cost',
      headerName: 'Cost',
      cellRenderer: 'currencyCellRenderer',
    },
    {
      field: 'paidAlreadyAmount',
      headerName: 'Paid Already Amount',
      cellRenderer: 'currencyCellRenderer',
    },
    {
      field: 'remainingAmount',
      headerName: 'Remaining Amount',
      cellRenderer: 'currencyCellRenderer',
    },
    {
      field: 'paymentType',
      headerName: 'Payment Type',
    },
    {
      field: 'policyExpirationDate',
      headerName: 'Policy Expiration Date',
    },
    ...insertIf(!disableActions, [
      {
        field: 'actions',
        maxWidth: 130,
        headerName: '',
        cellRenderer: 'actionsCellRenderer',
      },
    ]),
  ];

  const frameworkComponents = {
    actionsCellRenderer: params => {
      return (
        <>
          <IconButton
            testId="remove"
            color="primary"
            onClick={() => props.editQuote(params.node.data)}
          >
            <Edit />
          </IconButton>
          <ConfirmationButton
            confirmDialogProps={{
              title: 'Remove quote',
              body: 'Are you sure you want to remove the selected quote?',
            }}
            testId="edit"
            color="primary"
            onClick={() => {
              props.removeQuote(params.node.data);
            }}
          >
            <Delete />
          </ConfirmationButton>
        </>
      );
    },
    currencyCellRenderer: params => {
      return formatCurrency(params.value);
    },
  };

  return (
    <MuiGrid container spacing={1}>
      <MuiGrid item xs={8}>
        <Typography color="primary">Other</Typography>
      </MuiGrid>
      <MuiGrid item xs={4}>
        <Box display={'flex'} justifyContent={'flex-end'}>
          {!disableActions && (
            <Button
              testId="add-quote"
              onClick={() => props.addQuote()}
              size="small"
              color="primary"
              variant="contained"
            >
              Add quote
            </Button>
          )}
        </Box>
      </MuiGrid>
      <MuiGrid item xs={12}>
        <Grid
          columns={columns}
          rows={quotes}
          frameworkComponents={frameworkComponents}
          domLayout="autoHeight"
        />
      </MuiGrid>
    </MuiGrid>
  );
});

export default OtherQuotesGrid;
