import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, Typography } from '@material-ui/core';
import {
  Grid as DataGrid,
  GridColumnProps,
} from '@roc/client-portal-shared/components';
import { useStore } from '@roc/client-portal-shared/hooks';
import { Paper, GoogleMap } from '@roc/ui';
import { observer } from 'mobx-react-lite';
import {
  FormSection,
  LabeledText,
  NextBackButtons,
} from '../../lenderDetails/components/lenderDetailsFormComponents';
import {
  residentialProductsOptions,
} from '../../lenderDetails/utils/constants';
import { useEffect } from 'react';
import { CompanyProfileStore } from '../../companyProfile/stores/companyProfileStore';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(2),
  },
  mapStyles: {
    position: `relative`,
    width: `100%`,
    height: `150px`,
  },
}));


const documentsGridColumns: GridColumnProps[] = [
  {
    field: 'documentLabel',
    headerName: 'Document Type',
    minWidth: 300,
  },
];

interface CompanyDetailsProps {
  companyProfileStore: CompanyProfileStore;
}

export const Summary = observer(({ companyProfileStore }: CompanyDetailsProps) => {
  const { lenderDetailsStore } = companyProfileStore;
  const {
    lenderDetailsFormStore,
    lenderDocumentsStore,
    salesforceLenderStore,
  } = lenderDetailsStore;

  const classes = useStyles();

  const coordinates = lenderDetailsFormStore.coordinates;
  const lender = salesforceLenderStore.salesForceLender;
  const formValues = lenderDetailsFormStore.getFormValues();

  useEffect(() => {
    lenderDocumentsStore.loadData();
  }, []);

  const documents = lenderDocumentsStore.gridStore.gridData.data.rows ?? [];


  return (
    <Paper className={classes.paper}>
      <Box p={4}>
        <Grid container spacing={2}>
          <FormSection label="Company Details">
            <LabeledText label="Company Name" value={lender.company.name} />
            <LabeledText
              label="Tax Id / EIN # for the Participant Entity"
              value={lender.company.einNumber || ''}
            />
            <LabeledText label="Address" value={formValues.address} />
            {coordinates ? (
              <Grid item xs={12} md={6}>
                <GoogleMap
                  mapStyles={classes.mapStyles}
                  latitude={coordinates.lat}
                  longitude={coordinates.lng}
                />
              </Grid>
            ) : null}
          </FormSection>
          <FormSection label="Products">
            <LabeledText
              label="Residential products offered"
              value={
                lender.products
                  ? lender.products
                    .split(';')
                    .filter(p => residentialProductsOptions.includes(p))
                    .join(', ')
                  : ''
              }
            />
          </FormSection>

          <FormSection label="Documents">
            <DataGrid
              columns={documentsGridColumns}
              rows={documents.filter(d => d.uploaded)}
              domLayout="autoHeight"
            />
            <Typography color="error">
              *Missing:{' '}
              {documents
                .filter(d => !d.uploaded)
                .map(d => d.documentLabel)
                .join(', ')}
            </Typography>
          </FormSection>
          <Grid item xs={12}>
            <NextBackButtons
              renderBack
              renderSubmit
              companyProfileStore={companyProfileStore}
            />
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
});
