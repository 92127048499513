import { Service } from '@roc/feature-app-core';

const url = {
  LOAN_DETAILS: '/api/v1/assetm/loan/getLoanById',
  GET_TRADE_ECONOMICS: '/api/v1/assetm/trade/getLoanEconomics',
  SAVE_TRADE_ECONOMICS: '/api/v1/assetm/trade/saveLoanEconomics',
  GET_FUNDING_AMOUNT_LIST: '/api/v1/assetm/loan/getLoanFundingAmountList',
  EXPORT_FUNDING_AMOUNT_FILE: '/api/v1/assetm/loan/exportFundingAmountFile',
  IMPORT_FUNDING_AMOUNT_FILE: '/api/v1/assetm/loan/importFundingAmountFile'
};

export class LoanService extends Service {

  getLoanDetails(id: string) {
    return this.get(`${url.LOAN_DETAILS}?loanId=${id}`, {
      id,
    }, {
      disableGlobalLoading: false
    });
  }

  getTradeEconomics(tradeId: any, loanId: any) {
    return this.get(`${url.GET_TRADE_ECONOMICS}?tradeId=${tradeId}&loanId=${loanId}`);
  }

  saveTradeEconomics(loanEconomics: any) {
    return this.post(url.SAVE_TRADE_ECONOMICS, loanEconomics);
  }

  getLoanFundingAmountList(
    investorId?: number,
    asOfDate?: string,
    filters?: object,
    page?: number,
    pageSize?: number,
    sortBy?: string,
    sortDir?: string,
  ) {
    return this.get(
      url.GET_FUNDING_AMOUNT_LIST,
      {
        investorId,
        asOfDate,
        filters,
        page,
        pageSize,
        sortBy,
        sortDir,
      },
      {
        disableGlobalLoading: true,
      }
    );
  };

  exportFundingAmountFile(investorId: number, asOfDate?: string) {
    return this.get(url.EXPORT_FUNDING_AMOUNT_FILE,
      {
        investorId,
        asOfDate,
      },
      {
        responseType: 'arraybuffer'
      });
  };

  importFundingAmountFile(data: any) {
    return this.post(`${url.IMPORT_FUNDING_AMOUNT_FILE}`, data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    });
  };

}
