const mapper = {
  'Interior Partitions, Doors, Platforms, Wall Board, Suspended Ceilings': {
    helperText: 'Please provide the cost and details of the following items',
    sections: [
      {
        title: 'Interior Partitions',
        fields: [
          {
            label: 'Framing',
            name: 'framing',
          },
          {
            label: 'Wall Board',
            name: 'wallBoard',
          },
        ],
      },
      {
        title: 'Interior Doors',
        fields: [
          {
            label: 'Amount',
            name: 'interiorDoorsAmount',
          },
        ],
      },
      {
        title: 'Paint',
        fields: [
          {
            label: 'Amount',
            name: 'paintAmount',
          },
        ],
      },
      {
        title: 'Suspended Ceiling',
        fields: [
          {
            label: 'Amount',
            name: 'suspendedCeilingAmount',
          },
        ],
      },
      {
        title: 'Bathroom Accessories',
        fields: [
          {
            label: 'Amount',
            name: 'bathroomAccessoriesAmount',
          },
        ],
      },
      {
        title: 'Other',
        tooltip: 'Any other relevant items related to this category',
        fields: [
          {
            label: 'Amount',
            name: 'otherAmount',
          },
        ],
      },
    ],
  },
  'Finishes: Walls, Floors, Stairs, Ceilings': {
    helperText: 'Please provide the cost and details of the following items',
    sections: [
      {
        title: 'Wall Finishes',
        fields: [
          {
            label: 'Tile',
            name: 'wallFinishesTile',
          },
          {
            label: 'Paint',
            name: 'wallFinishesPaint',
          },
        ],
      },
      {
        title: 'Flooring',
        fields: [
          {
            label: 'Tile',
            name: 'flooringTile',
          },
          {
            label: 'Wood',
            name: 'wood',
          },
          {
            label: 'Carpet',
            name: 'carpet',
          },
        ],
      },
      {
        title: 'Ceiling Finishes',
        fields: [
          {
            label: 'Paint',
            name: 'ceilingFinishesPaint',
          },
          {
            label: 'Plaster',
            name: 'plaster',
          },
        ],
      },
      {
        title: 'Other',
        tooltip: 'Any other relevant items related to this category',
        fields: [
          {
            label: 'Amount',
            name: 'otherAmount',
          },
        ],
      },
    ],
  },
};

export default mapper;
