import {
  Box, Table, TableBody, TableCell, TableHead, TableRow, Theme, Typography, makeStyles, Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core';
import { Add, Remove } from '@material-ui/icons';
import { observer } from 'mobx-react';
import { BackgroundItemStore } from '../../stores/backgroundItemStore';
import { useCallback, useState } from 'react';


const useStyles = makeStyles((theme: Theme) => ({
  fixedWidthText: {
    fontSize: '1rem',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'left',
    paddingLeft: '10px',
    width: '100%'
  },
  expanded: {
    '&$expanded': {
      margin: '0 0'
    }
  },
  accordionDetails: {
    padding: '0px 0px 0px'
  },
  accordionSummary: {
    height: '41px',
    '&$expanded': {
      minHeight: '41px'
    }
  },
  tableContainer: {
    borderRadius: '10px',
    border: '1px #e0e0e0 solid',
    overflow: 'hidden',
  },
  table: {
    minWidth: 750,
    width: '100%',
    '& td': {
      height: '40px',
      padding: 0,
      paddingTop: '8px',
      paddingBottom: '8px',
      border: '1px solid #e0e0e0',
    },
    '& th:first-child, td[rowSpan], $addNewItemRow td': {
      paddingLeft: theme.spacing(2),
      paddingRight: '3px',
    },
    '& table tr:last-child td, & table td[rowSpan]': {
      borderBottom: 'none',
    },
  },

  editableRow: {
    position: 'relative',
    cursor: 'pointer',
    textAlign: 'center',
    '& td': {
      textAlign: 'center'
    },
  },

  headerCell: {
    height: '41px',
    fontWeight: 'bold',
    color: '#757575',
    padding: 0,
    backgroundColor: '#F0FAFF',
    textAlign: 'center',
    border: '1px solid #e0e0e0',
  },
  title: {
    color: '#757575',
    fontSize: 20
  },
}));

interface AuditLogsProps {
  store: BackgroundItemStore;
}

interface IMenuItem {
  handleClick?: (isOpen: boolean) => void;
  defaultOpen?: boolean;
}

const AuditLogs = observer(({ store }: AuditLogsProps, { defaultOpen, handleClick }: IMenuItem) => {
  const [isOpen, setOpen] = useState(defaultOpen);

  const onClick = useCallback(() => {
    setOpen(!isOpen);
    if (handleClick) handleClick(!isOpen);
  }, [isOpen]);

  const classes = useStyles();
  const columns = [
    {
      name: 'ROC ANALYST',
      field: 'lastUpdatedBy',
      width: '50%',
    },
    {
      name: 'UPDATED DATE',
      field: 'lastUpdatedDate',
      width: '50%',
    },

  ];

  return (
    <Box style={{ paddingTop: '15px' }}>
      <Accordion className={classes.expanded} defaultExpanded={isOpen} elevation={0} onClick={onClick}>
        <AccordionSummary
          expandIcon={isOpen ? <Remove /> : <Add />}
          aria-controls={`Audit logs`}
          id={'Audit logs'}
          className={classes.accordionSummary}
          style={{
            backgroundColor: '#e2f6ff', color: '#757575', border: '1px solid', borderColor: '#d3d3d3', borderCollapse: 'separate',
            borderSpacing: '0', width: '100%', borderTopLeftRadius: '5px', borderTopRightRadius: '5px', overflow: 'hidden', height: '46px', minHeight: '46px'
          }}
        >
          <Typography className={classes.title}>Audit logs</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                {columns.map(({ name, ...props }) => (
                  <TableCell key={name} className={classes.headerCell} style={{ paddingLeft: '8px' }}>
                    {name}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody className={classes.editableRow}>
              {store.updateHistoryAuditLogs?.map((h, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell width={columns[0].width}>
                      <span className={classes.fixedWidthText} style={{ fontSize: '14px' }}>{h.lastUpdatedBy}</span>
                    </TableCell>
                    <TableCell width={columns[1].width}>
                      <span className={classes.fixedWidthText} style={{ fontSize: '14px' }}>
                        {h.lastUpdatedDate}
                      </span>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </AccordionDetails>
      </Accordion>
    </Box >
  );

});

export default AuditLogs;