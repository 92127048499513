export { default as bridgeTier1Badge } from './bridge-tier-1.svg';
export { default as bridgeTier2Badge } from './bridge-tier-2.svg';
export { default as bridgeTier3Badge } from './bridge-tier-3.svg';
export { default as bridgeTier4Badge } from './bridge-tier-4.svg';
export { default as bridgeTier5Badge } from './bridge-tier-5.svg';
export { default as rentalTier0Badge } from './rental-tier-0.svg';
export { default as rentalTier1Badge } from './rental-tier-1.svg';
export { default as rentalTier2Badge } from './rental-tier-2.svg';
export { default as rentalTier3Badge } from './rental-tier-3.svg';
export { default as rentalTier4Badge } from './rental-tier-4.svg';
export { default as rentalTier5Badge } from './rental-tier-5.svg';
