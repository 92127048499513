import React, { ReactNode, useEffect, useState } from 'react';
import { Grid, GridSize } from '@material-ui/core';
import { Group } from '@material-ui/icons';
import { observer } from 'mobx-react';
import { AddOrSelect, BorrowerCard, trueFalseOptions } from '@roc/ui';
import { BorrowerFormDialog } from './borrowerFormDialog';
import { SelectBorrowersDialog } from './selectBorrowersDialog';
import { SelectBorrowersStore } from '../stores/selectBorrowersStore';
import { Borrower, BorrowerCardDisplayFields } from '../types';
import { FormStore, useBaseStore } from '@roc/feature-app-core';
import { prequalificationStatusProperties, isNil } from '@roc/feature-utils';
import { useQualifyBorrowerStore } from '../hooks/useQualifyBorrowerStore';
import { BorrowerWithPreQualificationDialog } from './borrowerWithPreQualificationDialog';
import { useBorrowersStore } from '../hooks/useBorrowersStore';
import { BorrowersAccordion } from './borrowersAccordion';

export enum SelectBorrowerMode {
  NEW,
  EXISTING,
}

export type SelectBorrowersProps = {
  subTitle?: React.ReactNode | string;
  modes?: SelectBorrowerMode[];
  multiple?: boolean;
  borrowerCardGridSize?: GridSize;
  borrowerCardDisplayFields?: BorrowerCardDisplayFields;
  store: SelectBorrowersStore;
  borrowerForm: (store: FormStore, currentBorrower?: Borrower) => ReactNode;
  displayBorrowerCard?: boolean;
  validateBorrowers?: () => void;
  showAccordion?: boolean;
  loanType?: string;
};

export const SelectBorrowers = observer((props: SelectBorrowersProps) => {
  const { globalStore } = useBaseStore();
  const {
    modes = [SelectBorrowerMode.NEW, SelectBorrowerMode.EXISTING],
    multiple = true,
    borrowerCardGridSize = 6,
    borrowerCardDisplayFields,
    store,
    borrowerForm,
    displayBorrowerCard = true,
    validateBorrowers,
    showAccordion,
    loanType,
  } = props;
  const {
    selectBorrowersDialogStore,
    borrowerFormDialogStore,
    canEditSelectedBorrowers,
  } = store;
  const { qualifyBorrowerStore } = useQualifyBorrowerStore();
  const [borrowerFormStep, setBorrowerFormStep] = useState(0);
  const { borrowersStore } = useBorrowersStore();

  const openSelectBorrowersDialog = () => {
    store.setIsSelectBorrowersDialogOpen(true);
  };

  const handleSelectBorrowersDialogClose = () => {
    store.setIsSelectBorrowersDialogOpen(false);
  };

  const openAddBorrowerDialog = () => {
    store.addNewBorrower();
  };

  const handleBorrowerFormDialogClose = () => {
    store.setIsBorrowerFormDialogOpen(false);
    store.setCurrentBorrower(null);
  };

  const handleSelectBorrowers = () => {
    store.setExistingBorrowers();
    if (validateBorrowers) validateBorrowers();
  };

  const handleSaveBorrower = (onSuccess?: () => void) => {
    borrowerFormDialogStore.validate();
    if (!borrowerFormDialogStore.isValid) return;
    store.saveBorrower(onSuccess);
    if (validateBorrowers) validateBorrowers();
  };

  const handleSaveBorrowerForPreQualification = (onSuccess?: () => void) => {
    borrowerFormDialogStore.validate();
    if (!borrowerFormDialogStore.isValid) return;
    if (store.currentBorrower?.borrowerId && isNaN(Number(store.currentBorrower?.borrowerId))) {
      borrowersStore.saveLeadAsBorrower(store.currentBorrower, () => {
        store.addNewBorrowerFromDB(borrowersStore.currentBorrower);
        store.setCurrentBorrower(borrowersStore.currentBorrower);
        handleSaveBorrower(onSuccess);
      });
    } else {
      handleSaveBorrower(onSuccess);
    }
  }

  const onEditBorrower = (borrower: Borrower) => {
    store.editBorrower(borrower);
  };

  const onRemoveBorrower = (borrower: Borrower) => {
    store.removeBorrower(borrower);
  };

  const closeQualifyBorrowerModal = () => {
    store.setCurrentBorrower(null);
    setBorrowerFormStep(0);
    handleBorrowerFormDialogClose();
  }

  const renderBorrower = (borrower: Borrower, index: number) => (
    <Grid item xs={12} md={borrowerCardGridSize} key={index}>
      <BorrowerCard
        borrower={borrower}
        displayFields={borrowerCardDisplayFields}
        onEdit={borrower.showEdit ? onEditBorrower : null}
        onRemove={borrower.showDelete ? onRemoveBorrower : null}
        largeCard={false}
      />
    </Grid>
  );

  useEffect(() => {
    store.setAllowMultiple(multiple);
  }, [store, multiple]);

  const handleQualifyBorrower = () => {
    if (!qualifyBorrowerStore.qualifyBorrowerRequestFormStore.form.fields.creditBackgroundCheck.value &&
      store.currentBorrower?.borrowerId && isNaN(Number(store.currentBorrower?.borrowerId))) {
      borrowersStore.saveLeadAsBorrower(store.currentBorrower, () => {
        store.addNewBorrowerFromDB(borrowersStore.currentBorrower);
        qualifyBorrowerStore.setInitialValues(borrowersStore.currentBorrower);
        qualifyBorrower();
      });
    } else {
      qualifyBorrower();
    }
  };

  const qualifyBorrower = () => {
    qualifyBorrowerStore.prepareDataForLoanSubmission(() => {
      store.handlePreQualifyBorrower(
        qualifyBorrowerStore.borrower,
        qualifyBorrowerStore.qualifyBorrowerRequestFormStore.getFormValues(),
      );
      closeQualifyBorrowerModal();
    });
  }

  return (
    <>
      {!!borrowerCardDisplayFields.preQualificationStatus && (
        <BorrowerWithPreQualificationDialog
          handleClose={closeQualifyBorrowerModal}
          handleSaveBorrower={handleSaveBorrower}
          handlePreQualifyBorrower={handleQualifyBorrower}
          open={store.isBorrowerFormDialogOpen}
          borrower={store.currentBorrower}
          store={qualifyBorrowerStore}
          personalInformation={borrowerForm(
            borrowerFormDialogStore.borrowerFormStore
          )}
          title={
            store.currentBorrower && store.currentBorrower.borrowerId
              ? 'Edit Borrower'
              : 'Add Borrower'
          }
          initialStep={borrowerFormStep}
          borrowersStore={borrowersStore}
        />
      )}
      <AddOrSelect
        entity={`borrower${multiple ? '(s)' : ''}`}
        entities={store.borrowers}
        renderEntity={
          displayBorrowerCard && !showAccordion ? renderBorrower : undefined
        }
        showSelect={modes.includes(SelectBorrowerMode.EXISTING)}
        selectButtonProps={{
          startIcon: <Group />,
          testId: 'selectBorrowerButton',
          onClick: openSelectBorrowersDialog,
          disabled: !store.canAddMore,
        }}
        showAdd={modes.includes(SelectBorrowerMode.NEW)}
        addButtonProps={{
          testId: 'addBorrowerButton',
          onClick: openAddBorrowerDialog,
          disabled: !store.canAddMore,
        }}
      />
      <SelectBorrowersDialog
        subTitle={props.subTitle}
        open={store.isSelectBorrowersDialogOpen}
        multiple={multiple}
        handleClose={handleSelectBorrowersDialogClose}
        handleConfirm={handleSelectBorrowers}
        store={selectBorrowersDialogStore}
      />
      {(modes.includes(SelectBorrowerMode.NEW) || canEditSelectedBorrowers) &&
        (!borrowerCardDisplayFields.preQualificationStatus) ? (
        <BorrowerFormDialog
          open={store.isBorrowerFormDialogOpen}
          handleClose={handleBorrowerFormDialogClose}
          handleConfirm={handleSaveBorrower}
          store={borrowerFormDialogStore}
          borrowerForm={borrowerForm}
        />
      ) : null}
      {showAccordion && (
        <BorrowersAccordion
          selectBorrowersStore={store}
          borrowerForm={borrowerForm}
          onRemove={onRemoveBorrower}
          qualifyBorrowerStore={qualifyBorrowerStore}
          handlePreQualifyBorrower={handleQualifyBorrower}
          borrowersStore={borrowersStore}
          validateBorrowers={validateBorrowers}
          loanType={loanType}
        />
      )}
    </>
  );
});

export default SelectBorrowers;
