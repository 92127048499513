import { useStore } from "@roc/client-portal-shared/hooks";
import { makeStyles } from '@material-ui/core/styles';
import { useEffect } from "react";
import { observer } from "mobx-react";
import { Roles } from "@roc/feature-utils";

const useStyles = makeStyles(() => ({
  iframeContainer: {
    height: '100%',
    width: '100%',
  },
  frame: {
    height: '95%',
    width: '100%',
    border: 0,
  },
}));

export const LoanVolume = observer(() => {
  const { sigmaDashboardsStore, userStore } = useStore();
  const classes = useStyles();

  const getSigmaUrlByRole = () => {
    if (userStore.userInformation.roles[0] == Roles.BACK_OFFICE_ORIGINATOR) {
      return 'sigma_loan_volume';
    }
    return 'sigma_leaderboard';
  }

  useEffect(() => {
    sigmaDashboardsStore.getDashboardEmbededUrl(getSigmaUrlByRole());
  }, []);

  return (
    <div className={classes.iframeContainer}>
      <iframe className={classes.frame} src={sigmaDashboardsStore.embededUrl} />
    </div>);
});