import {
  Card,
  CardContent,
  Typography,
  Avatar,
  makeStyles,
} from "@material-ui/core";
import AccountBox from "@material-ui/icons/AccountBox";
import { darken } from "@material-ui/core/styles";

interface styleProps {
  bgColor: string;
  textColor: string;
}

const useStyles = makeStyles((theme) => ({
  card: (props: styleProps) => ({
    display: "flex",
    alignItems: "center",
    backgroundColor: props.bgColor,
    borderRadius: "8px",
    padding: "3px",
  }),
  cardContent: (props: styleProps) => ({
    padding: "0px 14px 0px 5px !important",
  }),
  icon: (props: styleProps) => ({
    backgroundColor: "transparent",
    color: props.textColor,
  }),
  text: (props: styleProps) => ({
    color: darken(props.textColor, 0.4)
  }),
}));

interface IdCardProps {
  hasIdentification: boolean;
}

export const IdCard = ({ hasIdentification }: IdCardProps) => {
  let bgColor: string, textColor: string;
  let message = "";

  if (hasIdentification) {
    bgColor = "#e3f2fd";
    textColor = "#2196f3";
    message = "Id on File";
  } else {
    bgColor = "#fdecea";
    textColor = "#b71c1c";
    message = "No Id on File";
  }
  const classes = useStyles({ bgColor, textColor });

  return (
    <Card className={classes.card} elevation={0}>
      <Avatar className={classes.icon}><AccountBox /></Avatar>
      <CardContent className={classes.cardContent}>
        <Typography variant="body2" className={classes.text}>
          {message}
        </Typography>
      </CardContent>
    </Card>
  );
}
