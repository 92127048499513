import { Box, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { observer } from "mobx-react";
import { TextField, Button } from '@roc/ui';
import { useState } from "react";
import { DraftLoan } from "@roc/feature-types";

const useStyles = makeStyles(() => ({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginLeft: 24,
  },
}));

interface rejectRequestFormProps {
  onConfirm: () => void;
  onClose: () => void;
  draftLoan: DraftLoan;
}

export const RejectRequestForm = observer(
  ({ onConfirm, onClose, draftLoan }: rejectRequestFormProps) => {
    const classes = useStyles();
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

    const setRejectionRequest = value => {
      draftLoan.rejectRequestReason = value
      if (value !== "") {
        setIsSubmitDisabled(false)
      } else {
        setIsSubmitDisabled(true)
      }
    }

    return (
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <TextField
            testId="rejectReason"
            variant="outlined"
            standaloneLabel
            label="Please write down the reason for the rejection"
            multiline
            onChange={reason => setRejectionRequest(reason.target.value)}
            minRows={2}
            required={true}
            fullWidth
          />
        </Grid>
        <Grid item container className={classes.buttonContainer}>
          <Box mr={2}>
            <Button
              className={classes.button}
              size="small"
              variant="contained"
              color="secondary"
              testId="cancel"
              onClick={onClose}
            >
              CANCEL
            </Button>
            <Button
              className={classes.button}
              disabled={isSubmitDisabled}
              size="small"
              variant="contained"
              color="primary"
              testId="save"
              onClick={() => {
                console.log('test');
                onConfirm()
                onClose();
              }}
            >
              SUBMIT
            </Button>
          </Box>
        </Grid>
      </Grid>
    );
  }
)