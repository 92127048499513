import { Service } from '@roc/feature-app-core';

const url = {
  GET_BORROWER_LOANS_GRID: '/api/v1/loan/borrowerLoans/getBorrowerLoansGrid',
  GET_BORROWER_PIPELINE_LOANS_GRID: '/api/v1/loan/borrowerLoans/getBorrowerPipelineLoansGrid',
  GET_BORROWER_LOANS_SUMMARY: '/api/v1/loan/borrowerLoans/getBorrowerLoansSummary',
  GET_BORROWER_LOANS_TODOS_SUMMARY: '/api/v1/loan/borrowerLoans/getBorrowerLoansTodosSummary',
  GET_BORROWER_LOANS_FOR_DRAWS_GRID:
    '/api/v1/loan/borrowerLoans/getBorrowerLoansForDrawsGrid',
  GET_BORROWER_LOAN_DETAILS: '/api/v1/loan/borrowerLoans/getBorrowerLoan',
  GET_BORROWER_LOAN_TASKS: '/api/v1/loan/loanApplications/getBorrowerLoanTasks',
  DOCUMENT_UPLOAD: '/api/v1/loan/loanDocuments/uploadLoanDocument',
};

export class BorrowerLoansService extends Service {
  getBorrowerLoansGrid(
    page: number,
    pageSize: number,
    sortDir: string,
    sortBy: string,
    filters: string,
    dropdownFilters: string
  ) {
    return this.get(
      url.GET_BORROWER_LOANS_GRID,
      {
        page,
        pageSize,
        sortDir,
        sortBy,
        filters,
        dropdownFilters,
      },
      {
        disableGlobalLoading: true,
      }
    );
  }

  getBorrowerPipelineLoansGrid(
    page: number,
    pageSize: number,
    sortDir: string,
    sortBy: string,
    filters: string,
    dropdownFilters: string
  ) {
    return this.get(
      url.GET_BORROWER_PIPELINE_LOANS_GRID,
      {
        page,
        pageSize,
        sortDir,
        sortBy,
        filters,
        dropdownFilters,
      },
      {
        disableGlobalLoading: true,
      }
    );
  }

  getBorrowerLoansSummary() {
    return this.get(
      url.GET_BORROWER_LOANS_SUMMARY,
      {},
      {
        disableGlobalLoading: true,
      }
    );
  }

  getBorrowerLoansTodosSummary() {
    return this.get(
      url.GET_BORROWER_LOANS_TODOS_SUMMARY,
      {},
      {
        disableGlobalLoading: true,
      }
    );
  }

  getBorrowerLoansForDraws(
    page: number,
    pageSize: number,
    sortDir: string,
    sortBy: string,
    filters: string,
    dropdownFilters: string
  ) {
    return this.get(
      url.GET_BORROWER_LOANS_FOR_DRAWS_GRID,
      {
        page,
        pageSize,
        sortDir,
        sortBy,
        filters,
        dropdownFilters,
      },
      {
        disableGlobalLoading: true,
      }
    );
  }

  getBorrowerLoanDetails(loanId) {
    return this.get(url.GET_BORROWER_LOAN_DETAILS, {
      loanId,
    });
  }

  getBorrowerLoanTasks(loanId) {
    return this.get(url.GET_BORROWER_LOAN_TASKS, {
      loanId,
    });
  }

  uploadDocument(file, loanDocId: number) {
    return this.post(`${url.DOCUMENT_UPLOAD}/${loanDocId}`, file);
  }
}
