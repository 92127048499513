import { action, observable, flow, makeObservable } from 'mobx';
import { ApiResponse, GlobalStore, GridStore } from '@roc/feature-app-core';
import { SpecialServicingService } from '../services/specialServicingService';
import { CounterPartyExpensesTab } from '../types/types';
import { SpecialServicingBaseStore } from './specialServicingBaseStore';

export class ReceivablesDashboardStore extends SpecialServicingBaseStore {
  constructor(globalStore: GlobalStore) {
    super(globalStore);
    this.tab = CounterPartyExpensesTab.PENDING_RECEIVABLE;
    makeObservable(this, {
      fetchEntityNames: flow,
      performActions: flow,
      performAction: flow,
    });
  };

  *fetchEntityNames() {
    try {
      const response: ApiResponse = yield this.service.getInvestorNames();
      this.entityList = response.data.data.map(value => ({
        label: value.name,
        value: value.companyId
      }));
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching investor names.',
      });
    }
  };

  reset() {
    this.tab = CounterPartyExpensesTab.PENDING_RECEIVABLE;
    this.counterPartyExpenseGridStore.reset();
    this.recordsIds = [];
  };

  *performActions(status: string) {
    try {
      const response = yield this.service.performReceivableAction(this.recordsIds, status);
      if (response.data.status === 'OK') {
        this.globalStore.notificationStore.showSuccessNotification({
          message: 'Status updated successfully.'
        });
      }
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while updating status.',
      });
    }
  }

  *performAction(id: number, status: string) {
    const idList: number[] = [id];

    try {
      const response = yield this.service.performReceivableAction(idList, status);
      if (response.data.status === 'OK') {
        this.globalStore.notificationStore.showSuccessNotification({
          message: 'Status updated successfully.'
        });
      }
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while updating status.',
      });
    }
  }

}
