import { action, flow, makeObservable, observable } from 'mobx';
import { GlobalStore, GridStore } from '@roc/feature-app-core';
import { BackgroundReviewFormStore } from './backgroundReviewFormStore';
import { BackgroundItemStore } from '../stores/backgroundItemStore';

export class BackgroundReviewStore {
  globalStore: GlobalStore;
  backgroundReviewFormStore: BackgroundReviewFormStore;
  backgroundItemStore: BackgroundItemStore;

  constructor(globalStore: GlobalStore) {
    this.globalStore = globalStore;
    this.backgroundReviewFormStore = new BackgroundReviewFormStore(
      globalStore
    );
    this.backgroundItemStore = new BackgroundItemStore(
      globalStore,
      this.backgroundReviewFormStore
    );
    makeObservable(this, {

    });
  }
};