import { useContext } from 'react';
import { StoreContext } from '@roc/feature-app-core';
import { BackgroundReviewStore } from '../stores/backgroundReviewStore';
import { BackgroundItemBaseStore } from '../stores/backgroundItemBaseStore';
import { BackgroundItemStore } from '../stores/backgroundItemStore';

export const useBackgroundReviewStore = () =>
  useContext<{
    backgroundReviewStore: BackgroundReviewStore;
    backgroundItemStore: BackgroundItemStore;
  }>(StoreContext);