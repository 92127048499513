import {
  Box,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  makeStyles,
  Theme,
  IconButton,
  Chip
} from '@material-ui/core';
import {
  createNumberFormatField,
  createTextField,
  Button,
  Paper
} from '@roc/ui';
import CloseIcon from '@material-ui/icons/Close';
import { Layout } from '@roc/feature-app-core';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { LenderBankInformationVerification } from '../../LenderBankInformation/LenderBankInformationVerification/LenderBankInformationVerification';

const enum Status {
  APPROVED = 'Approved',
  REQUESTED = 'Requested',
  PENDING_APPROVAL = 'Pending Approval',
  NOT_VERIFIED = 'Not Verified',
}

const useStyles = makeStyles((theme: Theme) => ({
  dialogStyle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonStyle: {
    marginRight: '10px',
    height: '40px',
  },
  gridChip: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  approvedChip: {
    backgroundColor: '#49be25',
    letterSpacing: 2,
    color: '#000000',
    fontWeight: 'bold',
    marginLeft: '5px'
  },
  notRequestedChip: {
    backgroundColor: '#be2528',
    letterSpacing: 2,
    color: '#ffffff',
    fontWeight: 'bold',
    marginLeft: '5px'
  },
  pendingApprovalChip: {
    backgroundColor: '#ffcc00',
    letterSpacing: 2,
    color: '#000000',
    fontWeight: 'bold',
    marginLeft: '5px'
  },
  requestedChip: {
    backgroundColor: '#007bff',
    letterSpacing: 2,
    color: '#ffffff',
    fontWeight: 'bold',
    marginLeft: '5px'
  },
  newBankInfoDiv: {
    paddingTop: '60px',
  },
}));

export const CompanyBankInformation = observer(({ companyProfileStore }) => {
  const {
    lenderDetailsStore,
    lenderBankInformationStore, salesforceLenderStore
  } = companyProfileStore;
  const { lenderDetailsFormStore } = lenderDetailsStore;
  const [openModal, setOpenModal] = useState(false);
  const [openEditBankInfoModal, setEditBankInfoModal] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [openApprovalModal, setOpenApprovalModal] = useState(false);
  const { globalStore } = lenderBankInformationStore;
  const { notificationStore } = globalStore;
  const classes = useStyles();
  const [confirmationEmail, setConfirmationEmail] = useState('');
  const { userStore } = salesforceLenderStore

  const companyName = (salesforceLenderStore.portal === 'SalesForce' || salesforceLenderStore.portal === 'Internal') ? salesforceLenderStore.salesForceLender.company.name : globalStore.lenderInfo.companyName

  const role = (salesforceLenderStore.portal === 'SalesForce' || salesforceLenderStore.portal === 'Internal') ? salesforceLenderStore.internalUser.roles[0] : userStore.userInfo?.role

  const status = lenderDetailsFormStore.bankInfo?.bankInfoStatus ?? Status.REQUESTED;

  const getChipAccordingStatus = (status) => {
    const statusToChipClassMap = {
      [Status.APPROVED]: classes.approvedChip,
      [Status.REQUESTED]: classes.requestedChip,
      [Status.PENDING_APPROVAL]: classes.pendingApprovalChip,
      [Status.NOT_VERIFIED]: classes.notRequestedChip,
    };

    return statusToChipClassMap[status] || null;
  }

  const oldBankInfoTitle = (status === Status.REQUESTED || status === Status.PENDING_APPROVAL)
    ? 'OLD BANK INFORMATION'
    : 'BANK INFORMATION';

  const disableRequestButton = status === Status.PENDING_APPROVAL;

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleOpenApprovalModal = () => {
    setOpenApprovalModal(true);
  };

  const handleEditBankInfoModal = () => {
    setEditBankInfoModal(true);
  };

  const handleCloseEditBankInfoModal = () => {
    setEditBankInfoModal(false);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setConfirmationModalOpen(false);
    lenderBankInformationStore.setDefaults();
  };

  const handleCloseApprovalModal = () => {
    setOpenApprovalModal(false);
    setConfirmationModalOpen(false);
    lenderBankInformationStore.setDefaults();
  };

  const handleConfirmation = async () => {
    const companyId = globalStore.selectedCompanyId;
    const currentCompanyEmail = await lenderBankInformationStore.getCurrentCompanyEmail(companyId);
    if (currentCompanyEmail && currentCompanyEmail.length > 0) {
      setConfirmationEmail(currentCompanyEmail);
      setConfirmationModalOpen(true);
    } else {
      notificationStore.showErrorNotification({
        message:
          'Bank information cannot be requested as there is no email associated with this lender',
      });
    }
  };

  const handleRequest = () => {
    const companyId = globalStore.selectedCompanyId;
    lenderBankInformationStore.verifyBankInformation(companyId, false);
    handleCloseModal();
  };

  const handleApprovalRequest = () => {
    const lenderId = globalStore.lenderInfo.lenderId;
    lenderBankInformationStore.approveBankInformationForCRM(lenderId);
    handleCloseApprovalModal();
  };

  useEffect(() => {
    if (lenderBankInformationStore.bankInformationRequested) {
      lenderDetailsFormStore.getLenderBankInformation();
      lenderBankInformationStore.bankInformationRequested = false;
    }
  }, [lenderBankInformationStore.bankInformationRequested]);

  return (
    <>
      <Paper style={{ padding: '16px', marginTop: 16 }}>
        <Grid
          item
          style={{ padding: '16px', marginBottom: 16 }}
          container
          justifyContent="space-between"
        >
          <Grid item xs={6} className={classes.gridChip}>
            <Typography variant="h5">{oldBankInfoTitle}</Typography>
            {(status === Status.APPROVED || status === Status.NOT_VERIFIED) &&
              <Chip
                label={`${status}`}
                className={getChipAccordingStatus(status)}
              />
            }
          </Grid>
          <Grid
            item
            xs={6}
            justifyContent="flex-end"
            style={{ display: 'flex' }}
          >
            {(role === 'backOfficeLead' || role === 'backOfficeOriginator') &&
              <Button
                color="primary"
                variant="contained"
                onClick={handleOpenModal}
                testId="save"
                disabled={disableRequestButton}
              >
                Request Bank Info
              </Button>
            }
            {role === 'lender_full_access_admin' &&
              <Button
                color="primary"
                variant="contained"
                onClick={handleEditBankInfoModal}
                testId="edit"
              >
                Edit
              </Button>
            }
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            {createTextField({
              store: lenderDetailsFormStore,
              testId: 'accountName',
              fieldName: 'accountName',
              label: 'Account Name',
              disabled: true,
            })}
          </Grid>
          <Grid item xs={6}>
            {createTextField({
              store: lenderDetailsFormStore,
              testId: 'bankName',
              fieldName: 'bankName',
              label: 'Bank Name',
              disabled: true,
            })}
          </Grid>
          <Grid item xs={6}>
            {createNumberFormatField({
              store: lenderDetailsFormStore,
              testId: 'routingNumber',
              fieldName: 'routingNumber',
              label: 'Routing Number',
              format: '#########',
              disabled: true,
            })}
          </Grid>
          <Grid item xs={6}>
            {createNumberFormatField({
              store: lenderDetailsFormStore,
              testId: 'accountNumber',
              fieldName: 'accountNumber',
              label: 'Account Number',
              disabled: true,
            })}
          </Grid>
        </Grid>

        {(status === Status.REQUESTED || status === Status.PENDING_APPROVAL) &&
          <div className={classes.newBankInfoDiv}>
            <Grid
              item
              style={{ padding: '16px', marginBottom: 16 }}
              container
              justifyContent="space-between"
            >
              <Grid item xs={6} className={classes.gridChip}>
                <Typography variant="h5">{"NEW BANK INFORMATION"}</Typography>
                {(status === Status.REQUESTED || status === Status.PENDING_APPROVAL) &&
                  <Chip
                    label={`${status}`}
                    className={getChipAccordingStatus(status)}
                  />
                }
              </Grid>
              <Grid
                item
                xs={6}
                justifyContent="flex-end"
                style={{ display: 'flex' }}
              >
                {((role === 'backOfficeLead' || role === 'backOfficeOriginator') && status === 'Pending Approval') &&
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={handleOpenApprovalModal}
                    testId="save"
                  >
                    Approve Bank Info
                  </Button>
                }
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                {createTextField({
                  store: lenderDetailsFormStore,
                  testId: 'newAccountName',
                  fieldName: 'newAccountName',
                  label: 'Account Name',
                  disabled: true,
                })}
              </Grid>
              <Grid item xs={6}>
                {createTextField({
                  store: lenderDetailsFormStore,
                  testId: 'newBankName',
                  fieldName: 'newBankName',
                  label: 'Bank Name',
                  disabled: true,
                })}
              </Grid>
              <Grid item xs={6}>
                {createNumberFormatField({
                  store: lenderDetailsFormStore,
                  testId: 'newRoutingNumber',
                  fieldName: 'newRoutingNumber',
                  label: 'Routing Number',
                  format: '#########',
                  disabled: true,
                })}
              </Grid>
              <Grid item xs={6}>
                {createNumberFormatField({
                  store: lenderDetailsFormStore,
                  testId: 'newAccountNumber',
                  fieldName: 'newAccountNumber',
                  label: 'Account Number',
                  disabled: true,
                })}
              </Grid>
            </Grid>
          </div>
        }
      </Paper>
      <Layout>
        <Dialog
          open={openEditBankInfoModal}
          onClose={handleCloseEditBankInfoModal}
          PaperProps={{
            style: {
              minWidth: '95%',
              maxWidth: '95%',
              minHeight: '95%',
              maxHeight: '95%',
            },
          }}
        >
          <IconButton
            aria-label="close"
            onClick={handleCloseEditBankInfoModal}
            style={{ position: 'absolute', right: 0, top: 0 }}
          >
            <CloseIcon />
          </IconButton>
          <LenderBankInformationVerification companyProfileStore={companyProfileStore} />
        </Dialog>
        <Dialog
          open={openModal}
          onClose={handleCloseModal}
          PaperProps={{
            style: {
              minWidth: '800px',
              maxWidth: '800px',
              minHeight: '270px',
              maxHeight: '270px',
            },
          }}
        >
          <DialogTitle>
            {'Request Lender Bank Information'}
          </DialogTitle>
          <DialogContent className={classes.dialogStyle}>
            <Box py={1} pr={2}>
              <Typography variant="body1">
                Are you sure you want to send a message to request bank verification information
                for: <b> {companyName} </b>?
              </Typography>
            </Box>
          </DialogContent>
          <DialogActions>
            <div>
              <Button
                className={classes.buttonStyle}
                size="small"
                variant="contained"
                type="submit"
                onClick={() => {
                  setConfirmationModalOpen(false);
                  handleCloseModal();
                }}
                color="primary"
                testId="rejectionButton"
              >
                No
              </Button>
              <Button
                className={classes.buttonStyle}
                size="small"
                variant="contained"
                type="submit"
                onClick={handleRequest}
                color="primary"
                testId="acceptanceButton"
              >
                Yes
              </Button>
            </div>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openApprovalModal}
          onClose={handleCloseApprovalModal}
          PaperProps={{
            style: {
              minWidth: '800px',
              maxWidth: '800px',
              minHeight: '270px',
              maxHeight: '270px',
            },
          }}
        >
          <DialogTitle>
            {'Request Lender Bank Information'}
          </DialogTitle>
          <DialogContent className={classes.dialogStyle}>
            <Box py={1} pr={2}>
              <Typography variant="body1">
                Are you sure want to approve the bank information for: <b> {companyName} </b>?
              </Typography>
            </Box>
          </DialogContent>
          <DialogActions>
            <div>
              <Button
                className={classes.buttonStyle}
                size="small"
                variant="contained"
                type="submit"
                onClick={() => {
                  setConfirmationModalOpen(false);
                  handleCloseApprovalModal();
                }}
                color="primary"
                testId="rejectionButton"
              >
                No
              </Button>
              <Button
                className={classes.buttonStyle}
                size="small"
                variant="contained"
                type="submit"
                onClick={handleApprovalRequest}
                color="primary"
                testId="acceptanceButton"
              >
                Yes
              </Button>
            </div>
          </DialogActions>
        </Dialog>

      </Layout>
    </>
  );
});
