import { action, flow, makeObservable, observable } from 'mobx';
import { ApiResponse } from '@roc/client-portal-shared/services';
import { GlobalStore, FormStore, LoginService } from '@roc/feature-app-core';

const form = {
  fields: {
    email: {
      value: '',
      error: null,
      rule: 'required|email',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export class ForgotPasswordStore extends FormStore {
  private globalStore: GlobalStore;
  private loginService: LoginService;
  successUrl: string;

  constructor(globalStore) {
    super({ ...form }, globalStore);
    this.globalStore = globalStore;
    this.loginService = new LoginService();
    makeObservable(this, {
      successUrl: observable,
      onFormSubmit: flow,
      setSuccessUrl: action,
    });
  }
  setSuccessUrl(url) {
    this.successUrl = url;
  }

  *onFormSubmit() {
    try {
      const { email } = this.form.fields;
      const response: ApiResponse = yield this.loginService.forgotPasswordSendEmail(
        email.value,
        this.globalStore.portalConfiguration.id
      );
      if (response.data?.data) {
        window.location.href = this.successUrl;
      } else {
        this.globalStore.notificationStore.showErrorNotification({
          message: 'Invalid user',
        });
      }
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Invalid user',
      });
    }
  }
}
