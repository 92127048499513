import React, { useEffect } from 'react';
import {
  Box,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,

  Chip,
  Typography,
} from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';
import { SubdirectoryArrowRight } from '@material-ui/icons';
import {
  Paper,
  GoogleMap,
  TestDataAttribute,
  DataGrid,
  AgGridColumnProps,
  SelectFilterComponent,
  SelectFloatingFilterComponent,
  StatusChip,
  StatusType,
  Toolbar,
  FilterButton,
} from '@roc/ui';
import {
  filterProps,
  formatCurrency as formatCurrencyUtil,
  selectFilterProps,
} from '@roc/ui/utils';
import { useLoanStore } from '../../../hooks/useLoanStore';
import { observer } from 'mobx-react';
import { roleOptions } from '@roc/feature-utils';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';

const columns: AgGridColumnProps[] = [
  {
    field: 'firstName',
    headerName: 'First Name',
    minWidth: 100,
    ...filterProps(),
  },
  {
    field: 'lastName',
    headerName: 'Last Name',
    minWidth: 100,
    ...filterProps(),
  },
  {
    field: 'emailAddress',
    headerName: 'Email',
    minWidth: 100,
    ...filterProps(),
  },
  {
    field: 'role',
    headerName: 'Role',
    minWidth: 300,
    cellRenderer: 'roleCellRenderer',
    ...selectFilterProps([
      roleOptions.ATTORNEY,
      roleOptions.LENDER,
      roleOptions.LENDER_ADMIN,
      roleOptions.BACK_OFFICE_ORIGINATOR,
      roleOptions.BACK_OFFICE_PROCESSOR,
      roleOptions.LENDER_LEAD,
    ]),
  },
];

const useStyles = makeStyles(theme =>
  createStyles({
    paper: {
      padding: theme.spacing(3),
    },
  })
);

export interface PropertiesProps {
  properties?: any[];
}

export const LoanContacts = observer(() => {
  const { loanStore } = useLoanStore();
  const { loanContactsGridStore: loanOriginatorsGridStore } = loanStore;
  const gridStore = loanOriginatorsGridStore;
  const classes = useStyles();

  useEffect(() => {
    gridStore.fetchGridData();
  }, []);

  const frameworkComponents = {
    selectFilter: SelectFilterComponent,
    selectFloatingFilter: SelectFloatingFilterComponent,
    roleCellRenderer: params => {
      const roleName = Object.values(roleOptions).find(
        option => option.value == params.value
      )?.label;

      return <>{roleName ?? params.value}</>;
    },
  };

  const onFilterChanged = params => {
    const filterModel = params.api.getFilterModel();
    gridStore.setFilterModel(filterModel);
  };

  const onSortChanged = params => {
    const sortModel = params.api.getSortModel();
    gridStore.setSortModel(sortModel);
  };

  return (
    <>
      <Paper className={classes.paper}>
        <DataGrid
          columns={columns}
          rows={gridStore.gridData.data.rows || []}
          frameworkComponents={frameworkComponents}
          onSortChanged={onSortChanged}
          onFilterChanged={onFilterChanged}
          isLoading={gridStore.isLoading}
          showFilters={gridStore.showFilters}
          sortModel={gridStore.sortModel}
          filterModel={gridStore.filterModel}
          paginationData={gridStore.gridData.meta}
          setPageNumber={gridStore.setPageNumber}
          domLayout="autoHeight"
        />
      </Paper>
    </>
  );
});
