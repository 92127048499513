import React from 'react';
import { Box } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

export interface StepperComponentProps {
  disableNavigation?: boolean;
  steps: Array<StepObj>;
  activeStep: number;
  handleStep?: (value: number) => void;
  className?: string;
  alternativeLabel?: boolean;
}

export interface StepObj {
  title: string;
  icon: JSX.Element;
  onBack?: () => void;
  onNext?: () => void;
  onSave?: () => void;
  onReprice?: () => void;
  onReject?: () => void;
  allowBack?: boolean;
  allowNext?: boolean;
  allowSave?: boolean;
  allowReprice?: boolean;
  completed?: boolean;
  nextButtonText?: string;
  saveButtonText?: string;
  allowReject?: boolean;
  disableNextButton?: boolean;
  disabledStep?: boolean;
  info?: string;
  size?: number;
}

const useStyle = makeStyles((theme: Theme) => ({
  stepper: {
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {},
  },
  step: {
    flex: 1,
    marginLeft: theme.spacing(1),
    overflow: 'hidden',
    cursor: 'pointer',
  },
  stepTitle: {
    fontSize: '12px',
    display: 'flex',
    alignItems: 'center',
    height: '23px',
    lineHeight: '23px',
    color: 'rgba(0,0,0,0.6)',
  },
  stepTitleActive: {
    color: theme.palette.primary.dark,
  },
  stepInfo: {
    fontSize: '12px',
    textWrap: 'nowrap',
    textOverflow: 'ellipsis',
    height: '23px',
    lineHeight: '23px',
    overflow: 'hidden',
    color: 'rgba(0,0,0,0.6)',
  },
  stepLine: {
    height: '4px',
    width: '100%',
    backgroundColor: 'rgba(0,0,0,0.38)',
  },
  stepLineActive: {
    backgroundColor: theme.palette.primary.main,
  },
  stepFirst: {
    marginLeft: '0',
  },
}));

export const OneToolStepperComponent: React.FC<StepperComponentProps> = ({
  disableNavigation,
  steps,
  activeStep,
  handleStep,
  className,
}) => {
  const classes = useStyle();

  const onStepClick = index => {
    if (handleStep) {
      handleStep(index);
    }
  };

  return (
    <Stepper activeStep={activeStep}>
      {steps.map((step, index) => {
        return (
          <Step
            key={`${step.title}-${index}`}
            completed={step.completed}
            first={index === 0}
            size={step.size}
            onClick={() => onStepClick(index)}
          >
            <StepInfo>{step.info}</StepInfo>
            <StepLine
              active={activeStep >= index}
              first={index === 0}
              last={index === steps.length - 1}
            />
            <StepTitle active={activeStep >= index}>{step.title}</StepTitle>
          </Step>
        );
      })}
    </Stepper>
  );
};

const Stepper = ({ activeStep, children }) => {
  const classes = useStyle();
  return <Box className={classes.stepper}>{children}</Box>;
};

const Step = ({ completed, size, first, children, onClick }) => {
  const classes = useStyle();
  return (
    <Box
      className={clsx(classes.step, first && classes.stepFirst)}
      style={{ flex: size }}
      onClick={onClick}
    >
      {children}
    </Box>
  );
};

const StepTitle = ({ active, children }) => {
  const classes = useStyle();
  return (
    <Box className={clsx(classes.stepTitle, active && classes.stepTitleActive)}>
      {children}
    </Box>
  );
};

const StepInfo = ({ children }) => {
  const classes = useStyle();
  return <Box className={classes.stepInfo}>{children}</Box>;
};

const StepLine = ({ active, first, last }) => {
  const classes = useStyle();
  const radiusLeft = first ? '2px' : '0';
  const radiusRigth = last ? '2px' : '0';
  const borderRadius = `${radiusLeft} ${radiusRigth} ${radiusRigth} ${radiusLeft}`;
  return (
    <Box
      className={clsx(classes.stepLine, active && classes.stepLineActive)}
      style={{ borderRadius }}
    ></Box>
  );
};
