import {
  Grid,
  Typography,
  Box,
  InputAdornment,
  IconButton,
  ListItemText,
  Menu,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import {
  DataGrid,
  GridColumnProps,
  TextField,
  MenuItem,
  Button,
  LinkColumn
} from '@roc/ui';
import { isNotBlank } from '@roc/client-portal-shared/utils';
import { observer } from 'mobx-react';
import { useNewLeadStore } from '../../hooks/useNewLeadStore';
import { Search, MoreVert } from '@material-ui/icons';
import { useEffect, useState } from 'react';
import { useStore } from '@roc/client-portal-shared/hooks';
import { useLeadDetailsRoutes } from 'libs/feature-backoffice-users/src/backOfficeUsers/routes/loanRoutes';
import { Link } from 'react-router-dom';
import { BulkLeadChangeOwner } from '../leadsDashboard/bulkLeadChangeOwner';
import { ChangeRelationshipManagerModal } from './changeRelationshipManagerModal';
import { useBorrowerDetailsRoutes } from '../../routes/borrowerDetailsRoutes';
import { useTPOContactDetailsRoutes } from '../../routes/tpoContactDetailsRoutes';
import { BaseLeadStore } from 'libs/client-portal-shared/src/app/modules/companyProfile/stores/baseLeadStore/BaseLeadStore';
import { NewLeadBaseStore } from '../../stores/newLeadBaseStore';
import { LenderDetailsFormStore } from 'libs/client-portal-shared/src/app/modules/lenderDetails/stores/lenderDetailsFormStore';
import { AddBrokerRelation } from '../leadsDashboard/addBrokerRelation';
import { LenderDetailsStore } from 'libs/client-portal-shared/src/app/modules/lenderDetails/stores/lenderDetailsStore';
import { AddBrokerAsTPO } from '../leadsDashboard/addBrokerAsTPO';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grid: {
      marginTop: '1.5em',
    },
    toolbar: {
      width: '100%',
      display: 'flex',
      justifyContent: 'start',
      alignItems: 'center',
      backgroundColor: '#E8E8EA',
    },
    input: {
      width: '100%',
      '& .MuiOutlinedInput-root': {
        '&:hover fieldset': {
          borderColor: '#1565c0',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#64b5f6',
        },
      },
    },
    btnFilledAlign: {
      backgroundColor: '#1565c0',
      color: 'white',
      '&:hover': {
        backgroundColor: '#2196f3',
        boxShadow: 'none',
      },
      marginTop: '30px',
    },
    btnOutlined: {
      color: '#1565c0',
    },
    btnFilled: {
      backgroundColor: '#1565c0',
      color: 'white',
      '&:hover': {
        backgroundColor: '#2196f3',
        boxShadow: 'none',
      },
    },
    helperTextUserApproved: {
      color: 'green',
      textAlign: 'end',
    },
    helperTextUserNotApproved: {
      color: '#ff9800',
      textAlign: 'end',
    },
    containerGlobalSearch: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '30px'
    },
    container: {
      display: 'flex',
      justifyContent: 'center',
    },
  })
);

const noOpComparator = () => 0;

const columns: GridColumnProps[] = [
  {
    field: 'name',
    headerName: 'Name',
    minWidth: 100,
    tooltipField: 'name',
    cellRenderer: 'leadNameCellRenderer',
    equals: () => false
  },
  {
    field: 'owner',
    headerName: 'Owners',
    minWidth: 100,
    tooltipField: 'owner',
  },
  {
    field: 'companyName',
    headerName: 'Company Name',
    minWidth: 100,
    tooltipField: 'companyName',
  },
  {
    field: 'createdDate',
    headerName: 'Creation Date',
    minWidth: 100,
    tooltipField: 'createdDate',
  },
  {
    field: 'matchType',
    headerName: 'Match Type',
    minWidth: 200,
    cellStyle: params => {
      if (params.value === 'Full Match') {
        return { color: 'red' };
      }
      return null;
    },
    tooltipField: 'matchType',
  },
  {
    field: '',
    headerName: 'Actions',
    minWidth: 200,
    cellRenderer: 'actionCellRenderer',
    comparator: noOpComparator,
  },
  {
    field: 'id',
    headerName: 'id',
    minWidth: 100,
    tooltipField: 'id',
    hide: true
  },
  {
    field: 'facoOwner',
    headerName: 'facoOwner',
    minWidth: 100,
    tooltipField: 'facoOwner',
    hide: true
  },
  {
    field: 'rocOwner',
    headerName: 'rocOwner',
    minWidth: 100,
    tooltipField: 'rocOwner',
    hide: true
  },
  {
    field: 'civicOwner',
    headerName: 'civicOwner',
    minWidth: 100,
    tooltipField: 'civicOwner',
    hide: true
  },
  {
    field: 'recordType',
    headerName: 'recordType',
    minWidth: 100,
    tooltipField: 'recordType',
    hide: true
  },
];

const getActionCellRendererForSF = (businessUnit, newLeadStore, classes, sessionUser) => {
  return (params) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement>(null);
    const [isOwner, setIsOwner] = useState(params.node.data && params.node.data.owner &&
      isSubstring(params.node.data.owner, sessionUser));

    useEffect(() => {
    }, [isOwner]);

    const openMenu = (e) => {
      setAnchorEl(e.currentTarget);
    };

    const closeMenu = () => {
      setAnchorEl(null);
    };

    function isSubstring(mainString, subString) {
      return mainString.includes(subString);
    }

    function getSFSObject(str) {
      const trimmedStr = str.trim();
      const index = trimmedStr.indexOf('-');

      if (index !== -1) {
        return trimmedStr.slice(0, index).trim();
      }
      return trimmedStr;
    }

    function getSFSObjectRecordType(str) {
      const trimmedStr = str.trim();
      const index = trimmedStr.indexOf('-');

      if (index !== -1) {
        return trimmedStr.slice(index + 1).trim();
      }
      return trimmedStr;
    }

    const makeOwner = async () => {
      if ((getSFSObject(params.node.data.recordType) !== "Account") && (getSFSObjectRecordType(params.node.data.recordType) !== "Lender Principal")) {
        newLeadStore.assignNewOwner(params.node.data.id, businessUnit, getSFSObject(params.node.data.recordType));
        setIsOwner(true);
      } else {
        newLeadStore.sendMakeMeOwnerAlert();
      }

      closeMenu();
    };

    const openDetails = (leadId: string) => {
      return window.parent.postMessage(`open-lead-details-page-${leadId}`, '*')
    }

    return (
      <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
        <IconButton onClick={openMenu}>
          <MoreVert color="primary" />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          keepMounted
          onClose={closeMenu}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <MenuItem onClick={makeOwner} style={{ padding: '8px' }} testId="menuI">
            <ListItemText primary={`Make Me ${businessUnit} Owner`} style={{ cursor: 'pointer' }} />
          </MenuItem>
        </Menu>
      </div>
    );
  };
};

const getActionCellRenderer = (
  businessUnit,
  newLeadStore: NewLeadBaseStore,
  classes,
  sessionUser,
  salesforceLeadStore: BaseLeadStore,
  userInformation: any,
  lenderDetailsFormStore: LenderDetailsFormStore,
  lenderDetailsStore: LenderDetailsStore
) => {
  return params => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement>(null);
    const [isOwner, setIsOwner] = useState(
      params.node.data &&
      params.node.data.owner &&
      isSubstring(params.node.data.owner, sessionUser)
    );

    useEffect(() => { }, [isOwner]);

    const openMenu = e => {
      setAnchorEl(e.currentTarget);
    };

    const closeMenu = () => {
      setAnchorEl(null);
    };

    function isSubstring(mainString, subString) {
      return mainString.includes(subString);
    }

    const setOpenBulkLeadChangeOwner = () => {
      if (params.node.data?.recordType === 'Borrower') {
        salesforceLeadStore.setIsBorrower(true);
      }
      salesforceLeadStore.setRecordType(params.node.data?.recordType);
      salesforceLeadStore.setIsFromPossibleMatches(true);
      salesforceLeadStore.setLeadsIds(params.node.data.id, true);
      salesforceLeadStore.changeOwnerFlow(params, closeMenu);
    };

    const setOpenChangeRM = async () => {
      await lenderDetailsFormStore.loadDataToChangeRM(params.node.data.id);
      newLeadStore.setOpenChangeRmModal(true);
    };

    const addToMyBrokers = async (lenderId) => {
      lenderDetailsFormStore.salesforceLenderStore.addLenderBrokerRelation(userInformation?.userId, lenderId);
      newLeadStore.gridStore.fetchGridData();
    }

    const turnBrokerIntoMyTPO = (brokerId) => {
      lenderDetailsFormStore.salesforceLenderStore.turnBrokerIntoTPO(userInformation?.userId, brokerId);
      newLeadStore.gridStore.fetchGridData();
    }

    const openAddBrokerRelationModal = () => {
      salesforceLeadStore.setIsFromPossibleMatches(true);
      lenderDetailsStore.setAddBrokerRelationModalOpen(params.node.data?.id, true);
    }

    const openAddBrokerAsTPOModal = () => {
      salesforceLeadStore.setIsFromPossibleMatches(true);
      lenderDetailsStore.setAddBrokerAsTPOModalOpen(params.node.data?.id, true);
    }

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        <IconButton onClick={openMenu}>
          <MoreVert color="primary" />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          keepMounted
          onClose={closeMenu}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          {(params.node.data?.recordType?.includes('Borrower') || params.node.data?.recordType?.includes('Lead') || params.node.data?.recordType?.includes('Broker')) &&
            <MenuItem
              onClick={setOpenBulkLeadChangeOwner}
              style={{ padding: '8px' }}
              testId="menuI"
            >
              <ListItemText
                primary={(newLeadStore.globalStore.userFeatures.isBackOfficeProcessor || newLeadStore.globalStore.userFeatures.backOfficeLead) ? 'Change Owner' : `Make Me ${businessUnit} Owner`}
                style={{ cursor: 'pointer' }}
              />
            </MenuItem>}
          {(userInformation?.roles[0] === 'backOfficeLead') && (params.node.data?.recordType === 'TPO') &&
            <MenuItem
              onClick={setOpenChangeRM}
              style={{ padding: '8px' }}
              testId="menuI"
            >
              <ListItemText
                primary={`Change Relationship Manager`}
                style={{ cursor: 'pointer' }}
              />
            </MenuItem>}
          {(userInformation?.roles[0] === 'backOfficeOriginator') && (params.node.data?.recordType === 'TPO') &&
            <MenuItem
              onClick={() => addToMyBrokers(params.node.data?.id)}
              style={{ padding: '8px' }}
              testId="menuI"
            >
              <ListItemText
                primary={`Add To My Brokers`}
                style={{ cursor: 'pointer' }}
              />
            </MenuItem>}
          {(userInformation?.roles[0] === 'backOfficeLead') && (params.node.data?.recordType === 'TPO') &&
            <MenuItem
              onClick={openAddBrokerRelationModal}
              style={{ padding: '8px' }}
              testId="menuI"
            >
              <ListItemText
                primary={`Add Broker Relation`}
                style={{ cursor: 'pointer' }}
              />
            </MenuItem>}
          {(userInformation?.roles[0] === 'backOfficeOriginator') && (params.node.data?.recordType === 'Broker') &&
            <MenuItem
              onClick={() => turnBrokerIntoMyTPO(params.node.data?.id)}
              style={{ padding: '8px' }}
              testId="menuI"
            >
              <ListItemText
                primary={`Add Broker To My TPOs`}
                style={{ cursor: 'pointer' }}
              />
            </MenuItem>}
          {(userInformation?.roles[0] === 'backOfficeLead') && (params.node.data?.recordType === 'Broker') &&
            <MenuItem
              onClick={openAddBrokerAsTPOModal}
              style={{ padding: '8px' }}
              testId="menuI"
            >
              <ListItemText
                primary={`Add Broker As TPO`}
                style={{ cursor: 'pointer' }}
              />
            </MenuItem>}
        </Menu>
      </div>
    );
  };
};

const nameCellRenderer = (params, userStore) => {
  const { palette } = useTheme();
  const { newLeadStore } = useNewLeadStore();
  const isBackofficeLead = userStore.userInfo.role === 'backOfficeLead';
  const userInfo = userStore.userInfo;
  const isOwnerOfTheRecord = isOwner(userInfo, params.node.data)
  const selectedId = params.node.data?.redirectId;
  const routeConfigUrl = getRouteConfigUrlForRecordType(selectedId, params.node.data?.recordType);
  const linkProps = { url: routeConfigUrl };
  if ((isOwnerOfTheRecord || isBackofficeLead)) {
    return newLeadStore.globalSearch ?
      LinkColumn({ ...params, ...linkProps }) :
      <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
        <Link style={{ color: palette.primary.dark }}
          to={routeConfigUrl}
          target="_blank">
          <strong>{params.value}</strong>
        </Link>
      </div>;
  } else {
    return <p>{params.value}</p>;
  }
};
const isOwner = (userInfo, data) => {
  if (data?.recordType && userInfo) {
    if (data.recordType.includes('Lead') || data.recordType.includes('Broker')) {
      return data?.owner?.includes(userInfo?.username);
    } else {
      const ownerIds1 = data.ownerIds1 ? data.ownerIds1.split(',') : [];
      const ownerIds2 = data.ownerIds2 ? data.ownerIds2.split(',') : [];
      const stringId = String(userInfo?.userId);

      return ownerIds1.includes(stringId) || ownerIds2.includes(stringId);
    }
  }

  return false;
}
const getRouteConfigUrlForRecordType = (selectedId, recordType) => {
  const { leadDetailsRoutesConfig } = useLeadDetailsRoutes();
  const { borrowerDetailsRoutesConfig } = useBorrowerDetailsRoutes();
  const { tpoContactDetailsRoutesConfig } = useTPOContactDetailsRoutes();
  if (recordType) {
    if (recordType.includes('Lead')) {
      return leadDetailsRoutesConfig.leadDetails(selectedId).url;
    } else if (recordType.includes('Borrower')) {
      return borrowerDetailsRoutesConfig.borrowerDetails(selectedId).url;
    } else {
      return tpoContactDetailsRoutesConfig.tpoContactDetails(selectedId, recordType).url;
    }
  }

  return '';
}

export const PossibleMatches = observer(() => {
  const classes = useStyles();
  const { newLeadStore } = useNewLeadStore();
  const { userDetailStore } = newLeadStore;
  const { form } = userDetailStore;
  const [borrowers, setBorrowers] = useState([]);
  const [leads, setLeads] = useState([]);
  const [lenders, setLenders] = useState([]);
  const [brokers, setBrokers] = useState([]);
  const { userDetails } = form.fields;
  const { salesforceLeadStore, userStore, lenderDetailsStore } = useStore();


  useEffect(() => {
    const arrayOfRecords = newLeadStore.gridStore.gridData.data.rows;
    setBorrowers(arrayOfRecords.filter(obj => obj?.recordType &&
      !obj.recordType.includes('Lead') &&
      obj.recordType.includes('Borrower')));
    setLeads(arrayOfRecords.filter(obj => obj?.recordType && obj.recordType.includes('Lead')));
    setLenders(arrayOfRecords.filter(obj => obj?.recordType && !obj.recordType.includes('Lead') &&
      obj.recordType.includes('TPO')));
    setBrokers(arrayOfRecords.filter(obj => !obj.recordType?.includes('Lead') && obj.recordType?.includes('Broker')));
    userDetailStore.getUserBusinessUnit();
  }, [newLeadStore.gridStore.gridData.data.rows]);

  useEffect(() => {
    if (!newLeadStore.globalSearch) {
      newLeadStore.resetValues();
      newLeadStore.gridStore.reset();
    }
  }, [newLeadStore.globalSearch]);

  const frameworkComponents = lenderDetailsStore ? {
    ...((({ lenderDetailsFormStore }) => ({
      actionCellRenderer: getActionCellRenderer(
        userDetailStore.businessUnit,
        newLeadStore,
        classes,
        userDetailStore.sessionUser,
        salesforceLeadStore,
        userStore.userInformation,
        lenderDetailsFormStore,
        lenderDetailsStore
      ),
      leadNameCellRenderer: params => nameCellRenderer(params, userStore),
    }))(lenderDetailsStore)),
  } : { actionCellRenderer: getActionCellRendererForSF(userDetailStore.businessUnit, newLeadStore, classes, userDetailStore.sessionUser), };

  function getCleanNumber(str) {
    const cleanNumber = str.replace(/[\s\+\(\)-]/g, '');
    return cleanNumber;
  }

  function containsOnlyDigits(str) {
    return /^[\d\s\(\)-]+$/.test(str);
  }

  function isEmailValidFormat(input) {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(input);
  }

  const cleanDetails = () => {
    userDetailStore.onFieldChange('firstName', '');
    userDetailStore.onFieldChange('lastName', '');
    userDetailStore.onFieldChange('email', '');
    userDetailStore.onFieldChange('phoneNumber', '');
  };

  const setDetails = userDetails => {
    newLeadStore.emailMatch = true;
    newLeadStore.emailMatchMsj = '';
    userDetailStore.onFieldChange('userDetails', userDetails);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      searchDetails();
    }
  }

  const searchDetails = async () => {
    const userInput = userDetails.value.trim();
    console.log(newLeadStore);

    if (userInput === '' || userInput.length < 3) {
      newLeadStore.emailMatchMsj =
        'Please provide at least 3 characters to search';
      return classes.helperTextUserNotApproved;
    }

    cleanDetails();

    if (containsOnlyDigits(userInput)) {
      const cleanNumber = getCleanNumber(userInput);
      if (cleanNumber.length !== 10) {
        newLeadStore.emailMatchMsj =
          'If you are searching for a phone number, please provide 10 digits';
        return classes.helperTextUserNotApproved;
      }
      userDetailStore.onFieldChange('phoneNumber', cleanNumber);
    } else if (userInput.includes('@') && isEmailValidFormat(userInput)) {
      userDetailStore.onFieldChange('email', userInput);
    } else {
      const splittedText = userInput.split(/\s+/);
      userDetailStore.onFieldChange('firstName', splittedText[0]);
      userDetailStore.onFieldChange('lastName', splittedText[1]);
    }

    newLeadStore.searchUserDetails();
  };

  const handlePreviousStep = () => {
    if (newLeadStore.globalSearch) {
      newLeadStore.setShowLeadsDashboard(true);
      newLeadStore.setShowBackButton(false);
    }
    newLeadStore.resetValues();
    newLeadStore.goPrevStep();
  };

  const handleNextStep = () => {
    userDetailStore.disabled = false;
    newLeadStore.emailMatch = false;
    newLeadStore.emailMatchMsj = '';
    newLeadStore.goNextStep();
  };

  const getHelperTextColor = () =>
    newLeadStore.emailMatch
      ? classes.helperTextUserNotApproved
      : classes.helperTextUserApproved;

  const toolbar = title => (
    <Box className={classes.toolbar}>
      <Typography style={{ margin: '0.5em' }} variant="h6">
        {title}
      </Typography>
    </Box>
  );


  const closeModal = () => {
    newLeadStore.setOpenBulkLeadChangeOwnerModal(false);
    newLeadStore.setOpenChangeRmModal(false);
    salesforceLeadStore.resetLeadsIds();
    salesforceLeadStore.setIsBorrower(false);
    salesforceLeadStore.setMoveFundedLoans(false);
    salesforceLeadStore.setMoveNonFundedLoans(false);
    salesforceLeadStore.setIsFromPossibleMatches(false);
    salesforceLeadStore.setRecordType('');
    lenderDetailsStore?.setAddBrokerRelationModalOpen(null, false);
    lenderDetailsStore?.setAddBrokerAsTPOModalOpen(null, false);
  }

  return (
    <Grid container xs={12} alignItems="flex-start">

      {!newLeadStore.globalSearch && <Grid item xs={12}>
        <Typography>
          Please search for the lead's information to check if they're already
          in the database.
        </Typography>
      </Grid>}

      {!newLeadStore.globalSearch && <>
        <Grid
          container
          xs={12}
          spacing={4}
          direction="row"
          style={{ marginTop: '5px' }}
        >
          <Grid container className={classes.container} item xs={10}>
            <TextField
              label={"Lead's Details"}
              standaloneLabel
              required
              testId="userDetailsTop"
              variant="outlined"
              className={classes.input}
              value={userDetails?.value}
              onChange={e => setDetails(e.target.value)}
              onKeyDown={handleKeyPress}
              error={isNotBlank(userDetails.error)}
              helperText={userDetails.error}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              placeholder="Search"
            />
          </Grid>
          <Grid item xs={2}>
            <Button
              testId="searchButton"
              startIcon={<Search />}
              className={classes.btnFilledAlign}
              size="large"
              variant="contained"
              onClick={() => searchDetails()}
            >
              {'Search'}
            </Button>
          </Grid>
        </Grid></>}
      <Typography className={getHelperTextColor()}>
        {newLeadStore.emailMatchMsj}
      </Typography>
      {newLeadStore.gridStore.gridData.data.rows.length > 0 && (
        <Grid container xs={12} alignItems="flex-start">
          <Grid item container>
            <Grid item xs={12} style={{ marginTop: '20px' }}>
              <Typography variant={'h4'}>Possible Matches</Typography>
            </Grid>
            {newLeadStore.globalSearch &&
              <Grid item xs={12} style={{ marginTop: '20px' }}>
                <Typography variant={"subtitle1"}>Results for: <span style={{ fontWeight: 'bold' }}>{" " + userDetails.value.trim()}</span></Typography>
              </Grid>
            }
          </Grid>

          {borrowers.length > 0 && (
            <Grid item xs={12} className={classes.grid}>
              <DataGrid
                frameworkComponents={frameworkComponents}
                toolbar={toolbar('Borrowers')}
                columns={columns}
                rows={borrowers}
                isLoading={newLeadStore.gridStore.isLoading}
                paginationData={newLeadStore.gridStore.gridData.meta}
                setPageNumber={newLeadStore.gridStore.setPageNumber}
                domLayout="autoHeight"
              />
            </Grid>
          )}
          {lenders.length > 0 && (
            <Grid item xs={12} className={classes.grid}>
              <DataGrid
                frameworkComponents={frameworkComponents}
                toolbar={toolbar('TPOs')}
                columns={columns}
                rows={lenders}
                isLoading={newLeadStore.gridStore.isLoading}
                paginationData={newLeadStore.gridStore.gridData.meta}
                setPageNumber={newLeadStore.gridStore.setPageNumber}
                domLayout="autoHeight"
              />
            </Grid>
          )}
          {leads.length > 0 && (
            <Grid item xs={12} className={classes.grid}>
              <DataGrid
                frameworkComponents={frameworkComponents}
                toolbar={toolbar('Leads')}
                columns={columns}
                rows={leads}
                isLoading={newLeadStore.gridStore.isLoading}
                paginationData={newLeadStore.gridStore.gridData.meta}
                setPageNumber={newLeadStore.gridStore.setPageNumber}
                domLayout="autoHeight"
              />
            </Grid>
          )}
          {brokers.length > 0 && (
            <Grid item xs={12} className={classes.grid}>
              <DataGrid
                frameworkComponents={frameworkComponents}
                toolbar={toolbar('Brokers')}
                columns={columns}
                rows={brokers}
                isLoading={newLeadStore.gridStore.isLoading}
                paginationData={newLeadStore.gridStore.gridData.meta}
                setPageNumber={newLeadStore.gridStore.setPageNumber}
                domLayout="autoHeight"
              />
            </Grid>
          )}
        </Grid>
      )}
      <Grid item xs={12}>
        <Box display="flex" pt={8}>
          <Grid container spacing={2} justifyContent="flex-end">
            {!newLeadStore.dissableSubmit && !newLeadStore.emailMatch && !newLeadStore.globalSearch && (
              <Grid item xs={6} md={3}>
                <Button
                  variant="outlined"
                  className={classes.btnFilled}
                  onClick={handleNextStep}
                  fullWidth
                  testId="Next"
                >
                  Next
                </Button>
              </Grid>
            )}
          </Grid>
        </Box>
      </Grid>
      {lenderDetailsStore &&
        <>
          <BulkLeadChangeOwner open={newLeadStore.openBulkLeadChangeOwnerModal} handleClose={closeModal} salesforceLeadStore={salesforceLeadStore} isFromGlobalSearch={true} />
          <ChangeRelationshipManagerModal open={newLeadStore.openChangeRmModal} handleClose={closeModal} salesforceLeadStore={salesforceLeadStore} />
          <AddBrokerRelation open={lenderDetailsStore.isAddBrokerModalOpen} handleClose={closeModal} salesforceLeadStore={salesforceLeadStore} />
          <AddBrokerAsTPO open={lenderDetailsStore.isAddBrokerAsTPOModalOpen} handleClose={closeModal} salesforceLeadStore={salesforceLeadStore} />
        </>
      }
    </Grid>
  );
});