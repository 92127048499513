import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  Slider,
  Box,
  Checkbox,
  List,
  ListItem,
} from '@material-ui/core';
import { useStore } from '../../../../client-portal-shared/src/app/hooks/useStore';
import {
  RadioField,
  TextField,
  useFieldLabelStyle,
  trueFalseOptions,
} from '@roc/ui';
import { observer } from 'mobx-react';
import { isNotBlank } from '@roc/client-portal-shared/utils';
import {
  AuthorizedSignatory,
  CitizenshipStatus,
  EmailAddressDuplicated,
  FirstName,
  LastName,
  OwnershipNotKnown,
  PercentOwnership,
  PersonalGuarantor,
  QualifyingFicoScore,
  RoleInTransaction,

} from '../../../../client-portal-shared/src/app/components/formComponents/borrowerFormComponents';
import { FormStore } from '@roc/feature-app-core';
import { CellPhone } from '../../../../client-portal-shared/src/app/components/formComponents';
import { SelectBorrowersStore } from '@roc/feature-borrowers';
import { StabilizedBridgeStore } from '../stores';
import { prequalificationStatusProperties } from '@roc/feature-utils';
import { Borrower } from '@roc/feature-types';

interface BorrowerFormProps {
  store: FormStore;
  selectBorrowersStore: SelectBorrowersStore;
  stabilizedBridgeStore: StabilizedBridgeStore;
  currentBorrower?: Borrower;
}

const useStyles = makeStyles(theme =>
  createStyles({
    slider: {
      marginTop: '2.5em',
      marginLeft: '2em',
      width: '85%',
    },
    paragraph: {
      marginLeft: '2em',
    },
    textItalic: {
      fontStyle: 'italic',
    },
  })
);

export const BorrowerFormStabilizedBridge = observer(
  ({
    store,
    selectBorrowersStore,
    stabilizedBridgeStore,
    currentBorrower
  }: BorrowerFormProps) => {
    const classes = useStyles();
    const { globalStore } = useStore();

    currentBorrower = currentBorrower ?? selectBorrowersStore.currentBorrower;

    //TODO: how to remove this dependency here
    const checkBorrowerExistingInFunded =
      currentBorrower?.portalProjectsCompleted > 0 ||
      currentBorrower?.portalProjectsActive > 0;
    const disableEdit =
      !globalStore.userFeatures?.addNewBorrowerDuringSubmission ||
      checkBorrowerExistingInFunded;
    const ownershipNotKnown = store.form.fields.ownershipNotKnown.value;

    const validExistingEmail = email => {
      return (
        globalStore.userFeatures?.allowBorrowerDuplicateEmail ||
        !selectBorrowersStore.borrowers
          .filter(borrower => borrower.tempId != currentBorrower?.tempId)
          .some(borrower => borrower.emailAddress === email)
      );
    };

    return (
      <Grid container justifyContent={'center'} spacing={2}>
        <Grid item xs={12}>
          <FirstName store={store} disabled={checkBorrowerExistingInFunded} />
        </Grid>
        <Grid item xs={12}>
          <LastName store={store} disabled={checkBorrowerExistingInFunded} />
        </Grid>
        <Grid item xs={12}>
          <EmailAddressDuplicated
            store={store}
            disabled={disableEdit && !!currentBorrower?.emailAddress}
            onChanged={(name, value) =>
              store.onFieldChange(
                'emailAddressDuplicated',
                validExistingEmail(value)
              )
            }
          />
        </Grid>
        <Grid item xs={12}>
          <CellPhone store={store} disabled={disableEdit} />
        </Grid>
        <Grid item xs={12}>
          <CitizenshipStatus store={store} />
        </Grid>
        <Grid item xs={12}>
          <AuthorizedSignatory store={store} />
        </Grid>
        <Grid item xs={12}>
          <PercentOwnership store={store} disableEdit={ownershipNotKnown} />
        </Grid>
        <Grid item xs={12} justifyContent="flex-end">
          <OwnershipNotKnown store={store} />
        </Grid>
        <Grid item xs={12}>
          <PersonalGuarantor store={store} />
        </Grid>
      </Grid>
    );
  }
);
