import { flow, makeObservable } from 'mobx';
import { BorrowerPfsService } from '../services/borrowerPfsService';
import { ApiResponse } from '@roc/feature-app-core';
import { FormStore } from '@roc/feature-app-core';
import { GlobalStore, UserStore } from '@roc/feature-app-core';

const pfsForm = {
  fields: {
    cashChecking: {
      value: '',
      error: null,
      rule: '',
    },
    cashSavings: {
      value: '',
      error: null,
      rule: '',
    },
    securities: {
      value: '',
      error: null,
      rule: '',
    },
    notesReceivable: {
      value: '',
      error: null,
      rule: '',
    },
    lifeInsurance: {
      value: '',
      error: null,
      rule: '',
    },
    retirementFunds: {
      value: '',
      error: null,
      rule: '',
    },
    realEstateValue: {
      value: '',
      error: null,
      rule: '',
    },
    otherAssets: {
      value: '',
      error: null,
      rule: '',
    },
    totalAssets: {
      value: 0,
      error: null,
      rule: '',
    },
    currentDebt: {
      value: '',
      error: null,
      rule: '',
    },
    notesPayable: {
      value: '',
      error: null,
      rule: '',
    },
    taxesPayable: {
      value: '',
      error: null,
      rule: '',
    },
    realEstateMortgages: {
      value: '',
      error: null,
      rule: '',
    },
    otherLiabilities: {
      value: '',
      error: null,
      rule: '',
    },
    totalLiabilities: {
      value: 0,
      error: null,
      rule: '',
    },
    employmentInformation: {
      value: '',
      error: null,
      rule: '',
    },
    salary: {
      value: '',
      error: null,
      rule: '',
    },
    bonusPayable: {
      value: '',
      error: null,
      rule: '',
    },
    realEstateIncome: {
      value: '',
      error: null,
      rule: '',
    },
    otherIncome: {
      value: '',
      error: null,
      rule: '',
    },
    totalIncome: {
      value: 0,
      error: null,
      rule: '',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};
export class PfsStore extends FormStore {
  globalStore: GlobalStore;
  userStore: UserStore;
  private borrowerPfsService: BorrowerPfsService;
  public saved: boolean;

  constructor(globalStore, userStore) {
    super({ ...pfsForm }, globalStore);
    this.globalStore = globalStore;
    this.userStore = userStore;
    this.borrowerPfsService = new BorrowerPfsService();
    makeObservable(this, {
      loadPfsForm: flow,
      submitForm: flow,
    });
  }

  *loadPfsForm() {
    /*try {
      const user = yield this.userStore.getUser();
      const response: ApiResponse = yield this.borrowerPfsService.getForm(
        user.borrowerId
      );
      if (response.data) {
        for (const key in response.data) {
          this.form.fields[key].value = response.data[key];
        }
      }
    } catch (error) {
      console.error(error);
    }*/
  }

  *submitForm() {
    /*const values = {};
    for (const key in this.form.fields) {
      values[key] = this.form.fields[key].value;
    }
    try {
      const user = yield this.userStore.getUser();
      const response: ApiResponse = yield this.borrowerPfsService.submitForm(
        user.borrowerId,
        { personalFinancialStatement: values }
      );
      if (response.data) {
        this.saved = true;
      }
    } catch (error) {
      console.error(error);
    }*/
  }
}
