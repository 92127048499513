import { makeObservable } from "mobx";
import { FormStore } from "@roc/feature-app-core";
import { GlobalStore } from "@roc/feature-app-core";
import { Trade } from "@roc/feature-types";

export const form = {
  fields: {
    tradeId: { value: null },
    status: { value: null },
    buyerId: { value: null },
    tradeName: { value: null },
    buyerName: { value: null },
    sellerName: { value: null },
    boardedWithServicer: { value: null },
    investorTradeCount: { value: null },
    showDate: {
      value: '',
      error: null,
      rule: '',
    },
    expectedTradeDate: {
      value: '',
      error: null,
      rule: '',
    },
    tradeDate: {
      value: '',
      error: null,
      rule: '',
    },
    entity: {
      value: '',
      error: null,
      rule: '',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export class TradeFormStore extends FormStore {
  constructor(globalStore: GlobalStore) {
    super(form, globalStore);
    makeObservable(this, {});
  }

  setTrade(trade: Trade) {
    this.reset();
    this.loadForm(trade);
  }

  getTrade(): Trade {
    return this.getFormValues();
  }
}
