import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import {
  BorrowerExperienceRealState,
  FirstName,
  LastName,
  QualifyingFicoScore,
} from '@roc/ui/formComponents';
import {
  createTextField,
  createCurrencyField,
} from '@roc/ui/componentBuilder';
import { FormStore, useBaseStore } from '@roc/feature-app-core';
import { observer } from 'mobx-react';
import { SelectBorrowersStore } from '@roc/feature-borrowers';
import { ProposalsDetailsStore } from '../../stores';


interface BorrowerFormProps {
  form: FormStore;
  selectBorrowersStore: SelectBorrowersStore;
  store: ProposalsDetailsStore;
}

const useStyles = makeStyles(theme =>
  createStyles({
    slider: {
      marginTop: '2.5em',
      marginLeft: '2em',
      width: '85%',
    },
    paragraph: {
      marginLeft: '2em',
    },
    textItalic: {
      fontStyle: 'italic',
    },
  })
);

export const BorrowerFormProposal = observer(
  ({ form }: BorrowerFormProps) => {
    const store = form;
    const { globalStore } = useBaseStore();
    const disableEdit =
      !globalStore.userFeatures?.addNewBorrowerDuringSubmission


    return (
      <Grid container justifyContent={'center'} spacing={2}>
        <Grid item xs={12}>
          <FirstName store={form} disabled={disableEdit} />
        </Grid>
        <Grid item xs={12}>
          <LastName store={form} disabled={disableEdit} />
        </Grid>
        <Grid item xs={12}>
          <BorrowerExperienceRealState store={form} />
        </Grid>
        <Grid item xs={12}>
          {createTextField({
            store,
            testId: 'experienceComments',
            fieldName: 'experienceComments',
            label: "Borrower Description (Describe borrower's experience as well as how it relates to the subject deal/project)",
            multiline: true,
            inputProps: { maxLength: 500, rows: 3 }
          })}
        </Grid>
        <Grid item xs={12}>
          {createCurrencyField({
            store: form,
            testId: 'netWorth',
            fieldName: 'netWorth',
            label: 'Net Worth',
          })}
        </Grid>
        <Grid item xs={12}>
          <QualifyingFicoScore store={form} />
        </Grid>
      </Grid>
    );
  }
);
