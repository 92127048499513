import React from 'react';
import { Grid, Link as BorrowersLink, Typography } from '@material-ui/core';
import { StandardDialog, Button } from '@roc/ui';
import { observer } from 'mobx-react';
import { SelectBorrowersDialogStore } from '../stores/selectBorrowersDialogStore';
import { SelectBorrowerGrids } from './selectBorrowerGrids';

export type SelectBorrowersDialogProps = {
  open: boolean;
  subTitle?: React.ReactNode | string;
  multiple?: boolean;
  handleClose: React.ReactEventHandler;
  handleConfirm: React.ReactEventHandler;
  store: SelectBorrowersDialogStore;
  label?: string;
};


export const SelectBorrowersDialog = observer(
  (props: SelectBorrowersDialogProps) => {
    const { open, multiple, handleClose, handleConfirm, store } = props;

    return (
      <StandardDialog
        open={open}
        maxWidth='lg'
        title={`Select borrower${multiple ? 's' : ''}`}
        subTitle={props.subTitle}
        dialogContent={
          <SelectBorrowerGrids multiple={multiple} store={store} />
        }
        dialogActions={
          <Grid container spacing={1} justifyContent="flex-end">
            <Grid item>
              <Button
                onClick={handleClose}
                color="primary"
                variant="outlined"
                testId="cancel"
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={handleConfirm}
                color="primary"
                variant="contained"
                testId="confirm"
              >
                Confirm
              </Button>
            </Grid>
          </Grid>
        }
        handleClose={handleClose}
      />
    );
  }
);

export default SelectBorrowersDialog;
