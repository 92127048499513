import { AddCircleOutlined, Edit } from '@material-ui/icons';
import { Button } from '@roc/ui';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { Divider, Grid, Theme, Typography } from '@material-ui/core';
import { ComparableProperty } from '../../types/propertyTypes';
import { formatCurrency } from '@roc/ui/utils';
import { PropertyDetail } from '../propertyDetails';
import zillowIcon from '../../images/zillowIcon.png'
import googleIcon from '../../images/googleIcon.png'
import { observer } from 'mobx-react';
import { PropertyFeedback } from '../propertyFeedback/propertyFeedback';
import { IndividualPropertyImages } from './individualPropertyImages';
import { IndividualPropertyDetails } from './individualPropertyDetails';
import { TransactionHistory } from './transactionHistory';
import { PriceField } from '../subjectProperty/priceField';
import { segmentAnalytics, SegmentTrackerEventCompTool } from '@roc/feature-utils';
import { useStore } from '@roc/client-portal-shared/hooks';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    compareButton: {
      padding: '10px',
      textAlign: 'center',
    },
    editButton: {
      padding: '10px',
      textAlign: 'center',
    },
    priceField: (props: { isSubjectProperty: boolean }) => ({
      backgroundColor: props.isSubjectProperty ? '#285391' : '#a9a9a9',
      color: '#ffffff',
      borderRadius: '10px',
    }),
  })
);

const nonConfidentValue = ['Can try', 'Impossible to understand']

const confidentValue = ['Probable', 'Definitely']

export interface IndividualPropertyProps {
  property: ComparableProperty;
}

export const IndividualProperty: React.FC<IndividualPropertyProps> = observer(({
  property
}) => {
  const { compToolStore } = useStore();
  const isSubjectProperty = property.is_subject;
  const classes = useStyles({ isSubjectProperty });
  const { editSubjectPropertyFormStore, isInternal, internalUsername, userStore, subjectProperty } = compToolStore;
  const { data } = property;

  const getConfidenceTag = (): string => {
    if (confidentValue.includes(data.condition_confidence_tag)) {
      return 'Confident'
    } else if (nonConfidentValue.includes(data.condition_confidence_tag)) {
      return 'Not Confident'
    }
    return null;
  }


  return (
    <>
      <Grid container spacing={3}>
        <IndividualPropertyImages property={property} />

        <Grid item md={4} xs={12} className={classes.priceField}>
          <PriceField property={property} />
        </Grid>
        <PropertyDetail label="Rm" value={data.rooms} addArrow={!property.is_subject} field="rooms" lg={2} md={2} xs={3} variant={"h3"} />
        <PropertyDetail label="Bd" value={data.bedrooms} addArrow={!property.is_subject} field="bedrooms" lg={1} md={1} xs={3} variant={"h3"} />
        <PropertyDetail label="Ba" value={data.full_bathrooms} addArrow={!property.is_subject} field="full_bathrooms" lg={2} md={2} xs={3} variant={"h3"} />
        <PropertyDetail label="HBa" value={data.half_bathrooms} addArrow={!property.is_subject} field="half_bathrooms" lg={1} md={1} xs={3} variant={"h3"} />
        <PropertyDetail label="Sq.Ft" value={data.area} lg={2} addArrow={!property.is_subject} field="area" md={2} xs={3} variant={"h3"} />

        <Grid item xs={6} md={4} style={{ textAlign: 'center', alignContent: 'center' }}>
          {property.is_subject ?
            <Button
              variant="outlined"
              color="primary"
              testId="addToCompare"
              className={classes.editButton}
              onClick={() => { editSubjectPropertyFormStore.setAreEditFieldsOpened(true) }}
              startIcon={<Edit />}
            >
              EDIT PROPERTY INFO
            </Button>
            :
            <Button
              variant="outlined"
              color="primary"
              testId="addToCompare"
              className={classes.compareButton}
              onClick={() => { compToolStore.addSelectCompareProperties(property) }}
              startIcon={<AddCircleOutlined />}
              disabled={compToolStore.selectedCompareProperties.includes(property)}

            >
              ADD TO COMPARE
            </Button>
          }
        </Grid>
        <PropertyDetail label="Condition" value={data.condition_tag} lg={2} md={2} xs={3} variant={"h5"} />
        <PropertyDetail label="Lot Size" value={data.lot_size} lg={1} md={2} xs={3} variant={"h5"} />
        <PropertyDetail label="Taxes/Yr." value={formatCurrency(data.taxes, 0)} lg={2} md={2} xs={3} variant={"h5"} />
        <PropertyDetail label="Yr. Built" value={data.year_built} lg={1} md={2} xs={6} variant={"h5"} />
        {compToolStore.rentalComps ? (
          <PropertyDetail label="Rent/Sq.Ft" value={formatCurrency(data.sqft_rent, 0)} lg={2} md={1} xs={3} variant={"h5"} />
        ) : (
          <PropertyDetail label="Price/Sq.Ft" value={formatCurrency(data.sqft_price, 0)} lg={2} md={1} xs={3} variant={"h5"} />
        )}

        <Grid item xs={12}>
          <Divider />
        </Grid>

        <IndividualPropertyDetails property={property} />

        <Grid item xs={12} sm={3} style={{ textAlign: 'right' }}>
          <Button testId='googleSearch' variant='text'>
            <img src={googleIcon} style={{ height: '30px', width: '30px', margin: '10px' }} onClick={() => {
              segmentAnalytics.trackEvent({
                name: SegmentTrackerEventCompTool.COMP_TOOL_OPEN_GOOGLE,
                userName: isInternal ? internalUsername : userStore.userInformation?.username,
                subjectAddress: subjectProperty.data.address,
                propertyAddress: property.data.address
              });
              window.open(`http://www.google.com/search?q=${data.address}`)
            }} />
          </Button>

          {data.zillow &&
            <Button testId='propertyZillow' variant='text'>
              <img src={zillowIcon} style={{ height: '30px', width: '30px', margin: '10px' }} onClick={() => {
                segmentAnalytics.trackEvent({
                  name: SegmentTrackerEventCompTool.COMP_TOOL_OPEN_ZILLOW,
                  userName: isInternal ? internalUsername : userStore.userInformation?.username,
                  subjectAddress: subjectProperty.data.address,
                  propertyAddress: property.data.address
                });
                window.open(data.zillow)
              }} />
            </Button>
          }
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        {
          (data.condition_tag || data.condition_confidence_tag) &&
          <>
            <Grid item xs={12} style={{ paddingBottom: '0px' }} >
              <Typography align="left" variant="subtitle1" color="textSecondary">
                Property Condition Info Based on MLS Comment
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} style={{ paddingBottom: '0px' }} >
              <Typography align="left" variant="body1" color="textSecondary">
                Property Condition - <strong>{data.condition_tag}</strong>
              </Typography>

            </Grid>
            <Grid item xs={12} sm={6} style={{ paddingBottom: '0px' }} >
              <Typography align="right" variant="body1" color="textSecondary">
                Confidence Level of Condition Accuracy - <strong> {getConfidenceTag()}</strong>
              </Typography>
            </Grid>
            {data.condition_explanation &&
              <Grid item xs={12}>
                <Typography align="justify" style={{ fontSize: '16px' }}>
                  {data.condition_explanation}
                </Typography>
              </Grid>
            }
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </>
        }

      {
          data.comment &&
          <>
            <Grid item xs={12} >
              <Typography align="left" variant="subtitle1" color="textSecondary">
                About this home:
              </Typography>
              <Typography align="justify" style={{ fontSize: '16px' }}>
                {data.comment}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </>
        }

        {data.transactions_history.transactions.length > 0 &&
          <>
            <Grid item xs={12}>
              <TransactionHistory property={property} />
            </Grid>
          </>}
        <Grid item xs={12}>
          <Divider />
        </Grid>

        {
          !property.is_subject &&
          <PropertyFeedback property={property} />
        }
      </Grid >
    </>
  );
});