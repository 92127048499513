import { useDocumentStore } from '@roc/feature-documents';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Add, CloudDownload, Menu } from '@material-ui/icons';
import { Box } from '@material-ui/core';
import { DropdownMenu, FileUpload, FileUploadModal } from '@roc/ui';
import { useState } from 'react';
import { observer } from 'mobx-react';
import { LoanProcess } from '@roc/feature-utils';

const useStyles = makeStyles((theme: Theme) => ({
  menu: {
    marginRight: theme.spacing(2),
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'rgba(0,0,0,0.15)',
  },
}));

export const SectionActions = observer(
  ({ loanProcess, sectionName, sectionId, loanId, drawId }) => {
    const { documentStore } = useDocumentStore();
    const { loanTodosStore } = documentStore;
    const classes = useStyles();

    const [isUploadOpen, setUploadOpen] = useState(false);

    const onSectionDocumentsUpload = (files: FileUpload[]) => {
      if (loanProcess === LoanProcess.CLOSING) {
        // TODO: Remove this if when implementing todos endpoint for closing tab
        documentStore.uploadSectionDocuments(
          files,
          loanId,
          sectionId,
          sectionName,
          loanProcess,
          null,
          drawId
        );
      } else {
        loanTodosStore.uploadSectionDocuments(
          files,
          loanId,
          sectionId,
          sectionName,
          loanProcess,
          drawId
        );
      }
    };

    const onSectionDownload = () => {
      documentStore.downloadAllFromSection({ loanId, sectionId });
    };

    const closeFileUploadModal = () => {
      setUploadOpen(false);
    };

    const allowUpload = documentStore.isUploadAllowed(sectionName, loanProcess);
    const showDownloadAll = documentStore.showDownloadAll(sectionName);

    const options = [
      allowUpload && {
        name: 'Add New Documents',
        action: () => setUploadOpen(true),
        icon: Add,
      },
      showDownloadAll && {
        name: 'Download All Documents',
        action: () => onSectionDownload(),
        icon: CloudDownload,
      },
    ].filter(Boolean);

    return (
      <>
        {options.length > 0 && (
          <Box
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
            }}
          >
            <DropdownMenu options={options} containerClass={classes.menu}>
              <Menu />
            </DropdownMenu>
          </Box>
        )}
        <FileUploadModal
          title={`ADD NEW DOCUMENTS: ${sectionName}`}
          open={isUploadOpen}
          multiple
          onClose={closeFileUploadModal}
          onUpload={files => onSectionDocumentsUpload(files)}
        />
      </>
    );
  }
);
