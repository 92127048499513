import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {
  CurrencyField,
  SelectField,
  RadioField,
  SubmitButton,
  TextField,
  NumberFormat,
  Slider,
  FileUpload,
  FileUploadArea,
  createTextField
} from '@roc/ui';
import { formatCurrency, formatPercentage } from '@roc/ui/utils';
import { observer } from 'mobx-react';
import { isNotBlank, OTHER, lenderUnderwritingFeeTooltip, requiredLicenseStates, nonReferralFeeStates, Roles } from '@roc/feature-utils';
import { Add } from '@material-ui/icons';
import { InputAdornment } from '@material-ui/core';
import { useBaseStore, useUserStore } from '@roc/feature-app-core';
import CompanyForm from '../../components/companyForm';
import {
  LoanOriginator,
  PreferredClosingAttorney,
  PreferredInsurance,
  PreferredTitleCompany,
  RequestedClosingDate,
  SubmitAppraisalWithTamarisk,
  FastTrackLoan,
  FacoParticipants,
  LenderParticipants
} from '../../components/formComponents';
import { useMultifamilyStore } from '../../hooks/useMultiFamilyStore';
import { Alert, AlertTitle } from '@material-ui/lab';
import { PricingModelTable } from './pricingModelTable';
import { Paper } from '@roc/ui';
import { useStore } from '@roc/client-portal-shared/hooks';
import { getMultipartFile } from '../../utils/fileUtils';
import { StateLicenseUpload } from '../../components/formComponents';
import { normalizeState } from '@roc/feature-utils';
import { useLoanCommissionStore } from 'libs/feature-loan-summary/src/loanSummary/hooks/useCommissionStore';

const useStyles = makeStyles(theme =>
  createStyles({
    slider: {
      marginLeft: theme.spacing(2),
      marginTop: theme.spacing(5.5),
      width: '95%',
    },
    totalAmountCard: {
      marginTop: theme.spacing(4),
    },
    button: {
      marginRight: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  })
);

const percentageMarks = (top) => [
  {
    value: 0,
    label: '0%',
  },
  {
    value: top,
    label: top + '%',
  },
];

const posibleDurations = [
  {
    label: '12 months',
    value: 12,
  },
  {
    label: '18 months',
    value: 18,
  },
  {
    label: '24 months',
    value: 24,
  },
];

const accrualMethods = [
  {
    value: 'Full Boat',
    label: 'Full Boat',
  },
  {
    value: 'As disbursed',
    label: 'As disbursed',
  },
];

const interestReserveOptions = [
  {
    value: 0,
    label: 'None',
  },
  {
    value: 1,
    label: '1 month',
  },
  {
    value: 2,
    label: '2 months',
  },
  {
    value: 3,
    label: '3 months',
  },
  {
    value: 4,
    label: '4 months',
  },
  {
    value: 5,
    label: '5 months',
  },
  {
    value: 6,
    label: '6 months',
  },
  {
    value: 7,
    label: '7 months',
  },
  {
    value: 8,
    label: '8 months',
  },
  {
    value: 9,
    label: '9 months',
  },
  {
    value: 10,
    label: '10 months',
  },
  {
    value: 11,
    label: '11 months',
  },
  {
    value: 12,
    label: '12 months',
  },
  {
    value: 13,
    label: '13 months',
  },
  {
    value: 14,
    label: '14 months',
  },
  {
    value: 15,
    label: '15 months',
  },
  {
    value: 16,
    label: '16 months',
  },
  {
    value: 17,
    label: '17 months',
  },
  {
    value: 18,
    label: '18 months',
  },
];

const rateOptions = [
  {
    value: 9.99,
    label: '9.99%',
  },
  {
    value: 10.0,
    label: '10%',
  },
  {
    value: 10.25,
    label: '10.25%',
  },
  {
    value: 10.5,
    label: '10.5%',
  },
  {
    value: 10.75,
    label: '10.75%',
  },
  {
    value: 10.99,
    label: '10.99%',
  },
  {
    value: 11.0,
    label: '11%',
  },
  {
    value: 11.25,
    label: '11.25%',
  },
  {
    value: 11.5,
    label: '11.5%',
  },
  {
    value: 11.75,
    label: '11.75%',
  },
  {
    value: 11.99,
    label: '11.99%',
  },
  {
    value: 12.0,
    label: '12%',
  },
  {
    value: 12.25,
    label: '12.25%',
  },
  {
    value: 12.5,
    label: '12.5%',
  },
  {
    value: 12.75,
    label: '12.75%',
  },
  {
    value: 12.99,
    label: '12.99%',
  },
  {
    value: 13.0,
    label: '13%',
  },
  {
    value: 13.25,
    label: '13.25%',
  },
  {
    value: 13.5,
    label: '13.5%',
  },
  {
    value: 13.75,
    label: '13.75%',
  },
  {
    value: 13.99,
    label: '13.99%',
  },
  {
    value: 14.0,
    label: '14%',
  },
  {
    value: 14.25,
    label: '14.25%',
  },
  {
    value: 14.5,
    label: '14.5%',
  },
  {
    value: 14.75,
    label: '14.75%',
  },
  {
    value: 14.99,
    label: '14.99%',
  },
  {
    value: 15.0,
    label: '15%',
  },
  {
    value: 15.25,
    label: '15.25%',
  },
  {
    value: 15.5,
    label: '15.5%',
  },
  {
    value: 15.75,
    label: '15.75%',
  },
  {
    value: 15.99,
    label: '15.99%',
  },
  {
    value: 16.0,
    label: '16%',
  },
];
const mfBridgeRateOptions = [
  {
    value: 9.99,
    label: '9.99%',
  },
  {
    value: 10.0,
    label: '10%',
  },
  {
    value: 10.25,
    label: '10.25%',
  },
  {
    value: 10.5,
    label: '10.5%',
  },
  {
    value: 10.75,
    label: '10.75%',
  },
  {
    value: 10.99,
    label: '10.99%',
  },
  {
    value: 11.0,
    label: '11%',
  },
  {
    value: 11.25,
    label: '11.25%',
  },
  {
    value: 11.5,
    label: '11.5%',
  },
  {
    value: 11.75,
    label: '11.75%',
  },
  {
    value: 11.99,
    label: '11.99%',
  },
  {
    value: 12.0,
    label: '12%',
  },
  {
    value: 12.25,
    label: '12.25%',
  },
  {
    value: 12.5,
    label: '12.5%',
  },
  {
    value: 12.75,
    label: '12.75%',
  },
  {
    value: 12.99,
    label: '12.99%',
  },
  {
    value: 13.0,
    label: '13%',
  },
  {
    value: 13.25,
    label: '13.25%',
  },
  {
    value: 13.5,
    label: '13.5%',
  },
  {
    value: 13.75,
    label: '13.75%',
  },
  {
    value: 13.99,
    label: '13.99%',
  },
  {
    value: 14.0,
    label: '14%',
  },
  {
    value: 14.25,
    label: '14.25%',
  },
  {
    value: 14.5,
    label: '14.5%',
  },
  {
    value: 14.75,
    label: '14.75%',
  },
  {
    value: 14.99,
    label: '14.99%',
  },
  {
    value: 15.0,
    label: '15%',
  },
  {
    value: 15.25,
    label: '15.25%',
  },
  {
    value: 15.5,
    label: '15.5%',
  },
  {
    value: 15.75,
    label: '15.75%',
  },
  {
    value: 15.99,
    label: '15.99%',
  },
  {
    value: 16.0,
    label: '16%',
  },
];

const pointFormatOptions = [
  { label: 'Percentage', value: 'percentage' },
  { label: 'Amount', value: 'amount' },
];

const exitFeesOptions = [
  { label: '0%', value: '0' },
  { label: '0.25%', value: '0.25' },
  { label: '0.50%', value: '0.50' },
  { label: '0.75%', value: '0.75' },
  { label: '1%', value: '1' },
  { label: '1.25%', value: '1.25' },
  { label: '1.50%', value: '1.50' },
  { label: '1.75%', value: '1.75' },
  { label: '2%', value: '2' },
  { label: '2.25%', value: '2.25' },
  { label: '2.50%', value: '2.50' },
  { label: '2.75%', value: '2.75' },
  { label: '3%', value: '3.25' },
  { label: '3.25%', value: '3.25' },
  { label: '3.50%', value: '3.50' },
  { label: '3.75%', value: '3.75' },
  { label: '4%', value: '4' },
  { label: '4.25%', value: '4.25' },
  { label: '4.50%', value: '4.50' },
  { label: '4.75%', value: '4.75' },
  { label: '5%', value: '5' },
  { label: '5.25%', value: '5.25' },
  { label: '5.50%', value: '5.50' },
  { label: '5.75%', value: '5.75' },
  { label: '6%', value: '6' },
];

const participationOptions = [
  {
    value: 'Through life of loan',
    label: 'Through life of loan',
  },
  {
    value: 'With subsequent buy-out',
    label: 'With subsequent buy-out',
  },
  {
    value: 'No Participation',
    label: 'No Participation',
  },
];

const participationPercetangeOptions = [
  {
    value: 0.0,
    label: '0%',
  },
  {
    value: 10.0,
    label: '10%',
  },
  {
    value: 15.0,
    label: '15%',
  },
  {
    value: 20.0,
    label: '20%',
  },
  {
    value: 25.0,
    label: '25%',
  },
  {
    value: 30.0,
    label: '30%',
  },
  {
    value: 35.0,
    label: '35%',
  },
  {
    value: 40.0,
    label: '40%',
  },
  {
    value: 45.0,
    label: '45%',
  },
  {
    value: 49.0,
    label: '49%',
  },
];

const pointOptions = [
  {
    value: 0.0,
    label: '0',
  },
  {
    value: 0.25,
    label: '0.25',
  },
  {
    value: 0.5,
    label: '0.5',
  },
  {
    value: 0.75,
    label: '0.75',
  },
  {
    value: 1.0,
    label: '1',
  },
  {
    value: 1.25,
    label: '1.25',
  },
  {
    value: 1.5,
    label: '1.5',
  },
  {
    value: 1.75,
    label: '1.75',
  },
  {
    value: 2.0,
    label: '2',
  },
  {
    value: 2.25,
    label: '2.25',
  },
  {
    value: 2.5,
    label: '2.5',
  },
  {
    value: 2.75,
    label: '2.75',
  },
  {
    value: 3.0,
    label: '3',
  },
  {
    value: 3.25,
    label: '3.25',
  },
  {
    value: 3.5,
    label: '3.5',
  },
  {
    value: 3.75,
    label: '3.75',
  },
  {
    value: 4.0,
    label: '4',
  },
  {
    value: 4.25,
    label: '4.25',
  },
  {
    value: 4.5,
    label: '4.5',
  },
  {
    value: 4.75,
    label: '4.75',
  },
  {
    value: 5.0,
    label: '5',
  },
  {
    value: 5.25,
    label: '5.25',
  },
  {
    value: 5.5,
    label: '5.5',
  },
  {
    value: 5.75,
    label: '5.75',
  },
  {
    value: 6.0,
    label: '6',
  },
];

const InitialLoanAmount = observer(({ store }) => {
  const formFields = store.form.fields;
  return (
    <CurrencyField
      testId="initialLoanAmount"
      standaloneLabel
      required
      fullWidth
      label="Initial Loan Amount"
      variant="outlined"
      value={formFields.initialLoanAmount.value}
      errorText={formFields.initialLoanAmount.error}
      error={isNotBlank(formFields.initialLoanAmount.error)}
      onChange={(name, value) =>
        store.changeInitialLoanAmount('initialLoanAmount', Number(value))
      }
    />
  );
});

const InitialLoanToPurchase = observer(({ store, loanSubtype }) => {
  const formFields = store.form.fields;
  const classes = useStyles();
  let max = 100;
  return (
    <Slider
      testId="initialLoanToPurchaseSlider"
      standaloneLabel
      label="Initial Loan Amount/Purchase Price"
      className={classes.slider}
      value={formFields.initialLoanToPurchase.value}
      valueLabelDisplay="on"
      valueLabelFormat={value => <div>{value}%</div>}
      onChangeCommitted={(event, value) =>
        store.changeInitialLoanToPurchase('initialLoanToPurchase', value)
      }
      marks={percentageMarks(max)}
      step={1}
      min={0}
      max={max}
    />
  );
});

const ConstructionHoldback = observer(({ store }) => {
  const formFields = store.form.fields;
  return (
    <CurrencyField
      testId="constructionHoldback"
      standaloneLabel
      required
      fullWidth
      label="Construction Holdback"
      variant="outlined"
      value={formFields.constructionHoldback.value}
      onChange={(name, value) =>
        store.changeConstructionHoldback('constructionHoldback', Number(value))
      }
    />
  );
});

const ConstructionHoldbackPercentage = observer(({ store, ...rest }) => {
  const formFields = store.form.fields;
  const classes = useStyles();
  return (
    <Slider
      testId="constructionHoldbackPercentageSlider"
      standaloneLabel
      label="Construction Holdback/Renovation Budget"
      className={classes.slider}
      valueLabelDisplay="on"
      valueLabelFormat={value => <div>{value}%</div>}
      marks={percentageMarks(100)}
      value={formFields.constructionHoldbackPercentage.value}
      onChangeCommitted={(event, value) =>
        store.changeConstructionHoldbackPercentage(
          'constructionHoldbackPercentage',
          value
        )
      }
      step={1}
      min={0}
      max={100}
      {...rest}
    />
  );
});

const RecourseGuarantyStructure = ({ value }) => {
  const classes = useStyles();
  return (
    <TextField
      testId="recourseGuarantyStructure"
      disabled
      variant="outlined"
      label="Recourse/Guaranty Structure"
      value={value}
      tooltip={"Loans less than $2MM are full recourse and loans over $2mm are Non-Recourse"}
      fullWidth
      standaloneLabel
    />
  )
}

const InterestRate = observer(({ store, options, rate }) => {
  const formFields = store.form.fields;
  return (
    <NumberFormat
      standaloneLabel
      label={'Interest Rate'}
      name={'rate'}
      value={rate}
      disabled={true}
      variant="outlined"
      fullWidth
      shrink
      testId="rate"
      customInput={TextField}
      decimalScale={2}
    />
  );
});

const Points = observer(({ store }) => {
  const formFields = store.form.fields;

  return (
    <>
      <RadioField
        standaloneLabel
        label="Total Points"
        value={formFields.pointsFormat.value}
        errorText={formFields.pointsFormat.error}
        error={isNotBlank(formFields.pointsFormat.error)}
        name={'pointsFormat'}
        options={pointFormatOptions}
        onChange={value => {
          store.onChange('pointsFormat', value);
          store.changePointsPercentage(
            'points',
            formFields.points.value,
            'pointsNumber'
          );
        }}
        fullWidth
        row
        required
        testId="pointsFormat"
      />
      {formFields.pointsFormat.value === pointFormatOptions[0].value && (
        <NumberFormat
          testId="pointsPercentage"
          required
          customInput={TextField}
          variant="outlined"
          value={formFields.points.value}
          onChange={e =>
            store.changePointsPercentage(
              'points',
              e.target.value,
              'pointsNumber'
            )
          }
          decimalScale={2}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
          error={isNotBlank(formFields.points.error)}
          helperText={formFields.points.error}
          standaloneLabel
          fullWidth
        />
      )}
      {formFields.pointsFormat.value === pointFormatOptions[1].value && (
        <CurrencyField
          variant="outlined"
          standaloneLabel
          value={formFields.pointsNumber.value}
          errorText={formFields.points.error}
          error={isNotBlank(formFields.points.error)}
          onChange={(name, value) =>
            store.changePointsAmount('pointsNumber', value, 'points')
          }
          fullWidth
          required
          testId="pointsNumber"
        />
      )}
    </>
  );
});
const ExitFees = observer(({ store }) => {
  const formFields = store.form.fields;

  return (
    <SelectField
      standaloneLabel
      label={'Exit Fees'}
      name={'exitFees'}
      value={formFields.exitFees.value}
      errorText={formFields.exitFees.error}
      error={isNotBlank(formFields.exitFees.error)}
      options={exitFeesOptions}
      onChange={value => store.onChange('exitFees', value)}
      variant="outlined"
      fullWidth
      shrink
      required
      testId="exitFees"
    />
  );
});
const CapitalProviderExitFees = observer(({ store }) => {
  const formFields = store.form.fields;

  return (
    <SelectField
      standaloneLabel
      label={'Capital Provider Exit Fees'}
      name={'capitalProviderExitFees'}
      value={formFields.capitalProviderExitFees.value}
      errorText={formFields.capitalProviderExitFees.error}
      error={isNotBlank(formFields.capitalProviderExitFees.error)}
      options={exitFeesOptions}
      onChange={value => store.onChange('capitalProviderExitFees', value)}
      variant="outlined"
      fullWidth
      shrink
      required
      testId="capitalProviderExitFees"
      disabled={true}
    />
  );
});

const BrokerPoints = observer(({ store }) => {
  const formFields = store.form.fields;
  const { globalStore } = useStore();

  return (
    <>
      <RadioField
        standaloneLabel
        label={globalStore?.lenderInfo?.isInternal ? "On Hud Broker Fee" : "Broker Points"}
        value={formFields.brokerPointsFormat.value}
        errorText={formFields.brokerPointsFormat.error}
        error={isNotBlank(formFields.brokerPointsFormat.error)}
        name={'brokerPointsFormat'}
        options={pointFormatOptions}
        onChange={value => {
          store.onChange('brokerPointsFormat', value);
          store.changePointsPercentage(
            'brokerPoints',
            formFields.brokerPoints.value,
            'brokerPointsNumber'
          );
        }}
        fullWidth
        row
        required
        testId="brokerPointsFormat"
      />
      {formFields.brokerPointsFormat.value === pointFormatOptions[0].value && (
        <NumberFormat
          testId="brokerPointsPercentage"
          required
          customInput={TextField}
          variant="outlined"
          value={formFields.brokerPoints.value}
          onChange={e =>
            store.changePointsPercentage(
              'brokerPoints',
              e.target.value,
              'brokerPointsNumber'
            )
          }
          decimalScale={2}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
          error={isNotBlank(formFields.brokerPoints.error)}
          helperText={formFields.brokerPoints.error}
          fullWidth
          standaloneLabel
        />
      )}
      {formFields.brokerPointsFormat.value === pointFormatOptions[1].value && (
        <CurrencyField
          variant="outlined"
          standaloneLabel
          value={formFields.brokerPointsNumber.value}
          errorText={formFields.brokerPoints.error}
          error={isNotBlank(formFields.brokerPoints.error)}
          onChange={(name, value) =>
            store.changePointsAmount(
              'brokerPointsNumber',
              value,
              'brokerPoints'
            )
          }
          fullWidth
          required
          testId="brokerPointsNumber"
        />
      )}
    </>
  );
});


const ReferralFees = observer(({ store }) => {
  const formFields = store.form.fields;

  return (
    <>
      <RadioField
        standaloneLabel
        label={"Off Hud Broker Fee"}
        value={formFields.referralFeesFormat.value}
        errorText={formFields.referralFeesFormat.error}
        error={isNotBlank(formFields.referralFeesFormat.error)}
        name={'referralFeesFormat'}
        options={pointFormatOptions}
        onChange={value => {
          store.onChange('referralFeesFormat', value);
          store.changePointsPercentage(
            'referralFee',
            formFields.referralFee.value,
            'referralFeesAmount'
          );
        }}
        fullWidth
        row
        required
        testId="referralFeesFormat"
      />
      {formFields.referralFeesFormat.value === pointFormatOptions[0].value && (
        <NumberFormat
          testId="referralFee"
          required
          customInput={TextField}
          variant="outlined"
          value={formFields.referralFee.value}
          onChange={e =>
            store.changePointsPercentage(
              'referralFee',
              e.target.value,
              'referralFeesAmount'
            )
          }
          decimalScale={2}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
          error={isNotBlank(formFields.referralFee.error)}
          helperText={formFields.referralFee.error}
          fullWidth
          standaloneLabel
        />
      )}
      {formFields.referralFeesFormat.value === pointFormatOptions[1].value && (
        <CurrencyField
          variant="outlined"
          standaloneLabel
          value={formFields.referralFeesAmount.value}
          errorText={formFields.referralFee.error}
          error={isNotBlank(formFields.referralFee.error)}
          onChange={(name, value) =>
            store.changePointsAmount(
              'referralFeesAmount',
              value,
              'referralFee'
            )
          }
          fullWidth
          required
          testId="referralFeesNumber"
        />
      )}
    </>
  );
});


const LenderUnderwritingFee = observer(({ store }) => {
  const formFields = store.form.fields;
  return (
    <CurrencyField
      standaloneLabel
      label="Lender Underwriting Fee"
      value={formFields.lenderUnderwritingFee.value}
      errorText={formFields.lenderUnderwritingFee.error}
      error={isNotBlank(formFields.lenderUnderwritingFee.error)}
      onChange={(name, value) => store.onChange('lenderUnderwritingFee', Number(value))}
      tooltip={lenderUnderwritingFeeTooltip}
      variant="outlined"
      fullWidth
      required
      testId="lenderUnderwritingFee"
    />
  );
});

export const RocPoints = observer(({ store }) => {
  const formFields = store.form.fields;
  return (
    <SelectField
      standaloneLabel
      label={'Capital Provider Points'}
      name={'rocPointsIn'}
      value={formFields.rocPointsIn.value}
      errorText={formFields.rocPointsIn.error}
      error={isNotBlank(formFields.rocPointsIn.error)}
      options={pointOptions}
      onChange={value => store.onChange('rocPointsIn', value)}
      variant="outlined"
      fullWidth
      shrink
      required
      testId="rocPointsIn"
      disabled={true}
    />
  );
});

const TermDuration = observer(({ store }) => {
  const formFields = store.form.fields;
  return (
    <SelectField
      standaloneLabel
      label={'Term (Duration)'}
      name={'termDuration'}
      value={formFields.duration.value}
      errorText={formFields.duration.error}
      error={isNotBlank(formFields.duration.error)}
      options={posibleDurations}
      onChange={value => store.onChange('duration', value)}
      variant="outlined"
      fullWidth
      shrink
      required
      testId="termDuration"
    />
  );
});
const PrepaymentPenalty = observer(({ termDuration }) => {
  const getValue = () => {
    switch (termDuration) {
      case 12:
        return "9 months";
      case 18:
        return "12 months";
      case 24:
        return "18 months";
      default:
        return "3 months";
    }
  }
  return (
    <TextField
      testId="prepaymentPenalty"
      disabled
      variant="outlined"
      label="Prepayment Penalty"
      value={getValue()}
      fullWidth
      standaloneLabel
    />
  );
});

const InterestAccrualMethod = observer(({ store }) => {
  const formFields = store.form.fields;
  return (
    <SelectField
      standaloneLabel
      label={'Interest Accrual Method'}
      name={'interestAccrualMethod'}
      value={formFields.interestAccrualMethod.value}
      errorText={formFields.interestAccrualMethod.error}
      error={isNotBlank(formFields.interestAccrualMethod.error)}
      options={accrualMethods}
      onChange={value => store.onChange('interestAccrualMethod', value)}
      variant="outlined"
      fullWidth
      shrink
      required
      testId="interestAccrualMethod"
    />
  );
});

const InterestReserve = observer(({ store }) => {
  const formFields = store.form.fields;
  return (
    <SelectField
      standaloneLabel
      label={'Interest Reserve'}
      name={'interestReserveMonths'}
      tooltip={"An interest reserve will be sized by the underwriting team based on the in-place and projected cash flow of the property."}
      value={formFields.interestReserveMonths.value}
      errorText={formFields.interestReserveMonths.error}
      error={isNotBlank(formFields.interestReserveMonths.error)}
      options={interestReserveOptions}
      onChange={value => store.onChange('interestReserveMonths', value)}
      variant="outlined"
      fullWidth
      shrink
      required
      testId="interestReserveMonths"
    />
  );
});

function MultiFamilyLoanInformation({ ...props }) {
  const { globalStore } = useBaseStore();
  const { multiFamilyStore } = useMultifamilyStore();
  const {
    loanInformationStore,
    loanTerms,
    loanSubtype,
    isMultifamilyBridge,
    canSubmitAppraisalWithTamarisk,
    pricingModelValues,
    brokersStore
  } = multiFamilyStore;
  const { form } = loanInformationStore;
  const classes = useStyles();
  const { isFacoOriginator } = loanInformationStore.loanParticipantsStore;
  const { lenderDetailsStore } = useStore();
  const [uploads, setUploads] = useState<FileUpload[]>([]);
  const { loanCommissionStore } = useLoanCommissionStore();

  useEffect(() => {
    multiFamilyStore.loanInformationStore.makeInitialCalculations();
    loanInformationStore.getLenderAttorneys();
    setUploads(loanInformationStore.licenseUpload);
    loanInformationStore.resetBrokerPointsBasedOnHud(brokersStore.onTheHud);
  }, []);

  useEffect(() => {
    multiFamilyStore.validateOutOfBoxLoan();
  }, [multiFamilyStore]);

  useEffect(() => {
    if (uploads.length > 0 && uploads !== loanInformationStore.licenseUpload) {
      loanInformationStore.setLicenseUpload(uploads);
      lenderDetailsStore.lenderDocumentsStore.uploadDocument(`State License - ${multiFamilyStore.properties[0].state}`, getMultipartFile(uploads[0]));
    }
  }, [uploads]);

  useEffect(() => {
    const formFields = form?.fields || {};
    loanTerms.commission = loanCommissionStore?.getBridgeCommission({
      loanAmount: formFields.amount.value,
      rocRate: formFields.rate.value,
      rocPointsIn: formFields.rocPointsIn.value,
      fees: formFields.lenderUnderwritingFee.value,
      rocPointsOut: formFields.capitalProviderExitFees.value,
      referralFee: formFields?.referralFee.value,
    });
  }, [form.fields.amount.value, form.fields.rate.value, form.fields.rocPointsIn.value, form.fields.lenderUnderwritingFee.value, form.fields.capitalProviderExitFees.value, form.fields.referralFee.value]);

  const { userStore } = useUserStore();
  const allowLoanPricing = userStore.allowLoanPricing;
  const isInternal = globalStore.lenderInfo?.isInternal;
  const isBackOfficeProcessor = userStore.userInformation?.roles?.includes(Roles.BACK_OFFICE_PROCESSOR);
  const isBackOfficeUser = userStore.isBackOfficeUser;
  const isBrokerUser = userStore.userInformation?.roles?.includes(Roles.BROKER);

  return (
    <>
      <Typography variant="h4">Loan Economics</Typography>
      <br />
      <Typography variant="body1">
        Let's do some math here with the loan terms and pricing
      </Typography>
      <br />

      <Grid container spacing={10} alignItems="center">
        <Grid item xs={12} md={8}>
          <Typography variant="h4">Loan Terms</Typography>
          <br />
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <InitialLoanAmount store={loanInformationStore} />
            </Grid>
            <Grid item xs={12} md={6}>
              <InitialLoanToPurchase store={loanInformationStore} loanSubtype={loanSubtype} />
            </Grid>
            <Grid item xs={12} md={6}>
              <ConstructionHoldback store={loanInformationStore} />
            </Grid>
            <Grid item xs={12} md={6}>
              <ConstructionHoldbackPercentage
                store={loanInformationStore}
                disabled={isMultifamilyBridge}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <RecourseGuarantyStructure
                value={loanInformationStore.recourseGuarantyStructureValue}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card variant="outlined" className={classes.totalAmountCard}>
            <CardContent>
              <Typography align="center" variant="h6" color="primary">
                Total Loan Amount
              </Typography>

              <Typography align="center" variant="h4">
                {form.fields.amount.value
                  ? formatCurrency(form.fields.amount.value)
                  : '$'}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <br />
      <br />

      <Grid container spacing={10}>
        {isBrokerUser ? (
          <Grid item xs={12} md={4}>
            <Typography variant="h4">Pricing</Typography>
            <br />
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12}>
                <BrokerPoints store={loanInformationStore} />
              </Grid>
            </Grid>
          </Grid>
        ) : (
          allowLoanPricing &&
          <Grid item xs={12} md={4}>
            <Typography variant="h4">Pricing</Typography>
            <br />
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12}>
                <InterestRate
                  rate={loanTerms.rate}
                  store={loanInformationStore}
                  options={
                    isMultifamilyBridge ? mfBridgeRateOptions : rateOptions
                  }
                />
              </Grid>
              {globalStore.userFeatures?.allowLenderUnderwritingFee &&
                <Grid item xs={12}>
                  <LenderUnderwritingFee store={loanInformationStore} />
                </Grid>
              }
              <Grid item xs={12}>
                <Points store={loanInformationStore} />
              </Grid>
              <Grid item xs={12}>
                <ExitFees store={loanInformationStore} />
              </Grid>
              <Grid container spacing={2} alignItems="center">
                {brokersStore.onTheHud ?
                  (<Grid item xs={12}>
                    <BrokerPoints store={loanInformationStore} />
                  </Grid>) :
                  (<Grid item xs={12}>
                    <ReferralFees store={loanInformationStore} />
                  </Grid>
                  )
                }
                <Grid item xs={12}>
                  <RocPoints store={loanInformationStore} />
                </Grid>
                <Grid item xs={12}>
                  <CapitalProviderExitFees store={loanInformationStore} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}

        {pricingModelValues ?
          <Grid item xs={12} md={5}>
            <br />
            <Paper>
              <PricingModelTable data={pricingModelValues} />
            </Paper>

          </Grid> : null}
        {isBackOfficeUser && !isBackOfficeProcessor && isInternal && (
          <Grid item xs={12} md={3}>
            <Card variant="outlined" className={classes.totalAmountCard}>
              <CardContent>
                <Typography align="center" variant="h6" color="primary">
                  Your Commission %
                </Typography>
                <br />
                <Typography align="center" variant="h4">
                  {loanCommissionStore?.commissionData?.commissionPercentage
                    ? formatPercentage(loanCommissionStore.commissionData.commissionPercentage, 4)
                    : '%'}
                </Typography>
                <br />
                <Typography align="center" variant="h6" color="primary">
                  Your Closing Commission Amount
                </Typography>
                <br />
                <Typography align="center" variant="h4">
                  {loanCommissionStore?.commissionData?.entryCommissionAmount
                    ? formatCurrency(loanCommissionStore.commissionData.entryCommissionAmount)
                    : '$'}
                </Typography>
                {(loanCommissionStore?.commissionData?.exitCommissionAmount > 0) && (
                  <>
                    <br />
                    <Typography align="center" variant="h6" color="primary">
                      Your Payoff Commission Amount
                    </Typography>
                    <br />
                    <Typography align="center" variant="h4">
                      {formatCurrency(loanCommissionStore.commissionData.exitCommissionAmount)}
                    </Typography>
                  </>
                )}
              </CardContent>
            </Card>
          </Grid>
        )}
      </Grid>

      <br />
      <br />

      <Grid container spacing={10}>
        <Grid item xs={12} md={5}>
          <Typography variant="h4">Interest Mechanics</Typography>
          <br />
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
              <TermDuration store={loanInformationStore} />
            </Grid>
            <Grid item xs={12}>
              <PrepaymentPenalty termDuration={loanInformationStore.form.fields.duration.value} />
            </Grid>
            <Grid item xs={12}>
              <InterestAccrualMethod store={loanInformationStore} />
            </Grid>
            <Grid item xs={12}>
              <InterestReserve store={loanInformationStore} />
            </Grid>
            {!isBrokerUser && (
              <Grid item xs={12}>
                {createTextField({
                  store: loanInformationStore,
                  testId: 'projectDescription',
                  fieldName: 'projectDescription',
                  label: 'Project Description',
                  multiline: true,
                  inputProps: { maxLength: 500, rows: 3 }
                })}
              </Grid>
            )}
          </Grid>
        </Grid>
        {allowLoanPricing &&
          <Grid item xs={12} md={5}>
            <Typography variant="h4">Vendor and Closing Options</Typography>
            <br />
            <Grid container spacing={2} alignItems="center">
              {(canSubmitAppraisalWithTamarisk &&
                !globalStore.userFeatures?.tamariskAppraisal) && (
                  <Grid item xs={12}>
                    <SubmitAppraisalWithTamarisk store={loanInformationStore} />
                  </Grid>
                )}
              <Grid item xs={12}>
                <PreferredClosingAttorney store={loanInformationStore} />
              </Grid>
              <Grid item xs={12}>
                <PreferredInsurance store={loanInformationStore} />
              </Grid>
              {loanTerms.preferredInsurance === OTHER && (
                <Grid item xs={12}>
                  <SubmitButton
                    className={classes.button}
                    variant="contained"
                    color="secondary"
                    startIcon={<Add />}
                    testId="addOtherInsurance"
                    onClick={() =>
                      loanInformationStore.setOtherInsuranceCompany()
                    }
                    submitDialogProps={{
                      title: 'Insurance',
                      body: (
                        <CompanyForm
                          store={loanInformationStore.otherInsuranceFormStore}
                        />
                      ),
                      okButtonText: 'Confirm',
                      submitValidation: () => {
                        loanInformationStore.otherInsuranceFormStore.runFormValidation();
                        return loanInformationStore.otherInsuranceFormStore.form
                          .meta.isValid;
                      },
                    }}
                  >
                    ADD INFO
                  </SubmitButton>
                </Grid>
              )}
              <Grid item xs={12}>
                <PreferredTitleCompany store={loanInformationStore} />
              </Grid>
              {loanTerms.preferredTitle === OTHER && (
                <Grid item xs={12}>
                  <SubmitButton
                    className={classes.button}
                    variant="contained"
                    color="secondary"
                    startIcon={<Add />}
                    testId="addOtherTitle"
                    onClick={() => loanInformationStore.setOtherTitleCompany()}
                    submitDialogProps={{
                      title: 'Title',
                      body: (
                        <CompanyForm
                          store={loanInformationStore.otherTitleFormStore}
                        />
                      ),
                      okButtonText: 'Confirm',
                      submitValidation: () => {
                        loanInformationStore.otherTitleFormStore.runFormValidation();
                        return loanInformationStore.otherTitleFormStore.form.meta
                          .isValid;
                      },
                    }}
                  >
                    ADD INFO
                  </SubmitButton>
                </Grid>
              )}
              <Grid item xs={12}>
                <RequestedClosingDate store={loanInformationStore} />
              </Grid>
              {globalStore.userFeatures.fastTrack &&
                (loanInformationStore.form.fields.preferredTitle.value ||
                  loanInformationStore.form.fields.preferredInsurance.value) && (
                  <Grid item xs={12}>
                    <FastTrackLoan
                      store={loanInformationStore}
                      sendPaymentEmail={() => multiFamilyStore.sendFastTrackPayment()}
                      propertiesQuantity={multiFamilyStore.properties.length}
                    />
                  </Grid>
                )}
            </Grid>
          </Grid>
        }
      </Grid>
      <Grid container spacing={10}>
        {isFacoOriginator ? (
          <FacoParticipants xs={12} md={5} store={loanInformationStore} />
        ) : (
          <LenderParticipants
            xs={12}
            md={5}
            store={loanInformationStore}
            originatorFieldName="loanOriginator"
          />
        )}
      </Grid>
      <Grid container spacing={10}>
        <Grid item xs={12} md={5}>
          {globalStore.lenderInfo?.showTPOLicenseValidation && multiFamilyStore.properties.some(property => requiredLicenseStates.includes(normalizeState(property.state)) &&
            !globalStore.lenderInfo?.stateLicenseList?.includes(normalizeState(property.state))) && (
              <StateLicenseUpload store={loanInformationStore} setUploads={setUploads} property={multiFamilyStore.properties[0]} />
            )}
        </Grid>
      </Grid>
      <br />
      <br />
    </>
  );
}

export default observer(MultiFamilyLoanInformation);
