import { Service } from '@roc/feature-app-core';
import { ThreadMeta } from '@roc/feature-types';
import { capitalize } from '@roc/feature-utils';

const url = {
  GET_DOCUMENT_COMMENTS:
    '/api/v1/loan/documentCommunication/getDocumentComments',
  SAVE_DOCUMENT_COMMENT:
    '/api/v1/loan/documentCommunication/saveDocumentComment',
  MARK_DOCUMENT_COMMENTS_AS_READ:
    '/api/v1/loan/documentCommunication/markDocumentCommentsAsRead',
  GET_DRAW_COMMENTS: '/api/v1/loan/documentCommunication/getDrawComments',
  GET_ALL_DRAW_COMMENTS:
    '/api/v1/loan/documentCommunication/getAllDrawComments',
  MARK_ALL_DRAW_COMMENTS_AS_READ:
    '/api/v1/loan/documentCommunication/markAllDrawCommentsAsRead',
  MARK_DRAW_COMMENTS_AS_READ:
    '/api/v1/loan/documentCommunication/markDrawCommentsAsRead',
  UPLOAD_DOCUMENT_COMMENT_ATTACHMENT:
    '/api/v1/loan/documentCommunication/uploadDocumentCommentAttachment',
};

export class DocumentCommunicationService extends Service {
  getDocumentComments(meta: ThreadMeta) {
    const { loanId, sectionId, borrowerId, collateralId } = meta;
    let params = `sectionId=${sectionId}`;
    params += loanId ? `&loanId=${loanId}` : '';
    params += borrowerId ? `&borrowerId=${borrowerId}` : '';
    params += collateralId ? `&collateralId=${collateralId}` : '';
    return this.get(`${url.GET_DOCUMENT_COMMENTS}?${params}`);
  }

  saveDocumentComment(comment) {
    return this.post(url.SAVE_DOCUMENT_COMMENT, comment);
  }

  markAllDocumentCommentsAsRead(loanProcess: string, meta: ThreadMeta) {
    const { loanId, drawId, sectionId, borrowerId, collateralId } = meta;
    this.post(url.MARK_ALL_DRAW_COMMENTS_AS_READ, {
      loanId,
      loanProcess: capitalize(loanProcess),
    });
  }

  markDocumentCommentsAsRead(meta: ThreadMeta) {
    const { loanId, drawId, sectionId, borrowerId, collateralId } = meta;
    return this.post(url.MARK_DOCUMENT_COMMENTS_AS_READ, {
      loanId,
      drawId,
      sectionId,
      borrowerId,
      collateralId,
    });
  }

  getAllDrawComments(loanProcess: string, meta: ThreadMeta) {
    const { loanId, drawId, sectionId, borrowerId, collateralId } = meta;
    return this.get(url.GET_ALL_DRAW_COMMENTS, {
      loanId,
      loanProcess: capitalize(loanProcess),
      drawId,
    });
  }

  getDrawComments(meta: ThreadMeta) {
    const { loanId, drawId, sectionId, borrowerId, collateralId } = meta;
    let params = `sectionId=${sectionId}`;
    params += loanId ? `&loanId=${loanId}` : '';
    params += drawId ? `&drawId=${drawId}` : '';
    params += borrowerId ? `&borrowerId=${borrowerId}` : '';
    params += collateralId ? `&collateralId=${collateralId}` : '';
    return this.get(`${url.GET_DRAW_COMMENTS}?${params}`);
  }

  uploadDocumentCommentAttachment(file) {
    const formData = new FormData();
    formData.append('file', file);
    return this.post(url.UPLOAD_DOCUMENT_COMMENT_ATTACHMENT, formData);
  }
}
