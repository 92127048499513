import html2canvas, { Options } from 'html2canvas';
import jsPDF from 'jspdf';
import { RefObject, useRef } from 'react';

type HTML2CanvasOptions = Partial<Options>;

const A4 = { width: 446.46, height: 631.4175 };

const getCanvasFromElement = async (
  element: HTMLElement,
  options?: HTML2CanvasOptions
) => {
  return html2canvas(element, options);
};

const generatePdf = async (canvas: HTMLCanvasElement): Promise<jsPDF> => {
  const { height, width } = canvas;
  const orientation = height > width ? 'portrait' : 'landscape';

  const ratio = A4.width / canvas.width;
  const jspdf = new jsPDF(orientation, 'px', [width * ratio, height * ratio]);

  const size = jspdf.internal.pageSize;
  const url = canvas.toDataURL('image/jpeg');
  jspdf.addImage(url, 'JPEG', 0, 0, size.getWidth(), size.getHeight());

  return jspdf;
};

export const downloadAsPdf = async (
  element: HTMLElement,
  filename,
  options?: HTML2CanvasOptions
) => {
  const canvas = await getCanvasFromElement(element, options);
  const jspdf = await generatePdf(canvas);
  jspdf.save(filename);
};

export const getPdfBlobUrl = async (
  element: HTMLElement,
  options?: HTML2CanvasOptions
) => {
  const canvas = await getCanvasFromElement(element, options);
  const jspdf = await generatePdf(canvas);
  return jspdf.output('bloburl');
};
