import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { useHeaderHeight } from "@roc/feature-app-core";


export const useEarningsCardSticky = () => {
  const headerHeight = useHeaderHeight();
  const earningsCardsStickyStyle: CSSProperties = {
    position: "sticky",
    top: headerHeight,
    background: "#fff",
    zIndex: 1000,
    boxShadow: '0px 3px 12px rgb(199 199 199)',
    padding: 0,
    paddingBottom: 0,
    margin: 8,
    borderRadius: 10,
  };
  return { earningsCardsStickyStyle };
};