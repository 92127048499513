import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { Box, Grid, Typography } from '@material-ui/core';
import { useStore } from '@roc/client-portal-shared/hooks';
import { Button } from '@roc/ui';
import { observer } from 'mobx-react';
import { ReactNode } from 'react';
import { CompanyProfileStore } from '../../companyProfile/stores/companyProfileStore';

const useStyles = makeStyles(theme => ({
  labeledTextLabel: {
    fontWeight: 600,
  },
  labeledTextValue: {},

  totalOwnershipWrapper: {
    background: 'rgb(248, 248, 248)',
    border: '1px solid #ddd',
    padding: theme.spacing(2),
    paddingRight: theme.spacing(4),
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
  },
}));

interface NextBackButtonsProps {
  renderNext?: boolean;
  renderBack?: boolean;
  renderSubmit?: boolean;
}
interface CompanyDetailsProps {
  companyProfileStore: CompanyProfileStore;
}

export const NextBackButtons = observer(
  (props: NextBackButtonsProps & CompanyDetailsProps) => {
    const {
      renderNext = false,
      renderBack = false,
      renderSubmit = false,
      companyProfileStore,
    } = props;
  const { lenderDetailsStore } = companyProfileStore;
  return (
    <Box
      pt={4}
      display="flex"
      justifyContent={renderBack ? 'space-between' : 'flex-end'}
    >
      {renderBack && (
        <Button
          color="primary"
          variant="outlined"
          onClick={() => lenderDetailsStore.previousTab()}
          testId="save"
        >
          Back
        </Button>
      )}
      {renderNext && (
        <Button
          color="primary"
          variant="contained"
          onClick={() => lenderDetailsStore.nextTab()}
          testId="save"
        >
          Next
        </Button>
      )}
      {renderSubmit && (
        <Button
          color="primary"
          variant="contained"
          onClick={() => lenderDetailsStore.submitForm()}
          testId="save"
        >
          Submit
        </Button>
      )}
    </Box>
  );
});

interface FormSectionProps {
  label: string;
  children: ReactNode;
}

export const FormSection = (props: FormSectionProps) => {
  const { label, children } = props;
  return (
    <>
      <Grid item xs={12}>
        <Box sx={{ pt: 2 }}>
          <Typography variant="h5">{label}</Typography>
        </Box>
      </Grid>
      {children}
    </>
  );
};

interface LabeledTextProps {
  label: string;
  value: string;
}

export const LabeledText = (props: LabeledTextProps) => {
  const { label, value } = props;
  const classes = useStyles();
  return (
    <Grid item xs={12} container>
      <Grid item xs={12} md={3}>
        <Typography className={classes.labeledTextLabel}>{label}</Typography>
      </Grid>
      <Grid item xs={12} md={9}>
        <Typography className={classes.labeledTextValue}>{value}</Typography>
      </Grid>
    </Grid>
  );
};

export const TotalOwnerShip = ({ total }) => {
  const classes = useStyles();
  return (
    <Box className={classes.totalOwnershipWrapper}>
      <Typography>Total Ownership: {total}</Typography>
    </Box>
  );
};
