import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import {
  AssignmentTurnedIn,
  Business,
  Description,
  MonetizationOn,
  Person,
  Room,
} from '@material-ui/icons';
import { observer } from 'mobx-react';
import EntityInformation from '../components/entityInformation';
import Summary from '../stores/stabilizedBridge/summary';
import BorrowerInformation from '../components/borrowerInformationStbBridge';
import { Paper, StandardDialog } from '@roc/ui';
import { StepsContainer } from '@roc/client-portal-shared/components';
import { useHistory } from 'react-router';
import { DRAFT_LOAN_TYPE_LOAN, insertIf, isNil, LoanSubType, REJECTED_STATE, REQUEST_LOAN_TYPE_LOAN } from '@roc/feature-utils';
import { BrokerInformation } from '../components/brokerInformation';
import LoanInformationStabilizedBridge from './components/loanInformationStabilizedBridge';
import { PropertyStepsStabilizedBridge } from '../components/propertyStepsStabilizedBridge';
import PropertyInformationStbBridge from './propertyInformationStbBridge';
import { LoanSuccess } from '../../loans/components';
import { Layout, useUserStore } from '@roc/feature-app-core';
import { useStore, useRoutes } from '@roc/client-portal-shared/hooks';
import { RejectRequestForm } from '../components/rejectRequestForm';
import LoanRequestSuccess from '../../loans/components/loanSuccess/loanRequestSuccess';
import { LoanSubmissionStep } from '../utils/constants';
import { LoanDocuments } from '../components/loanDocuments';
import SelectPromotionGrid from '../components/selectPromotionGrid';

const useStyles = makeStyles(theme =>
  createStyles({
    container: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    paper: {
      padding: theme.spacing(4),
    },
  })
);

export const StabilizedBridge = observer(() => {
  const { stabilizedBridgeStore, globalStore } = useStore();
  const classes = useStyles();
  const { push } = useHistory();
  const { loanSubmissionRoutesConfig } = useRoutes();
  const { userStore } = useUserStore();
  const { dashboardRoutesConfig } = useRoutes();
  const [openRejectReason, setOpenRejectReason] = useState(false)
  const showBrokerInformation = !globalStore.userFeatures.isBroker;
  const showDocuments = globalStore.userFeatures.uploadDuringSubmissionEnabled;
  const { marketingPromotionsStore } = stabilizedBridgeStore;

  useEffect(() => {
    stabilizedBridgeStore.loadStore();
    stabilizedBridgeStore.setLoanType(LoanSubType.STABILIZED_BRIDGE);
    marketingPromotionsStore.fetchGridData();
  }, []);

  const onSubmit = () => {
    const isLoanDetailsValid =
      stabilizedBridgeStore.loanInformationStore.form.meta.isValid;
    const isAllStepsValid = stabilizedBridgeStore.allErrors.length === 0;
    if (!isLoanDetailsValid) {
      globalStore.notificationStore.showErrorNotification({
        message: 'Please correct the errors in Loan Details',
      });
    } else if (stabilizedBridgeStore.allErrors.length > 0) {
      globalStore.notificationStore.showErrorNotification({
        message: 'Please correct the errors listed above',
      });
    }

    const isValid = isAllStepsValid && isLoanDetailsValid;
    if (isValid) {
      stabilizedBridgeStore.onLoanSubmit();
    }
  };

  const onSave = (showSuccessNotification = true) => {
    return stabilizedBridgeStore.onLoanSave(DRAFT_LOAN_TYPE_LOAN, showSuccessNotification);
  };

  const onSaveRequest = (showSuccessNotification = true) => {
    if (!isNil(stabilizedBridgeStore?.draftLoanInfo?.draftLoanId)) {
      return stabilizedBridgeStore.onLoanSave(REQUEST_LOAN_TYPE_LOAN, showSuccessNotification);
    }
    else {
      return onLoanApplicationSave();
    }
  };

  const onLoanApplicationSave = () => {
    return stabilizedBridgeStore.onLoanApplicationSave();
  }

  const saveDraftLoan = (showSuccessNotification = true) => {
    return stabilizedBridgeStore.isLoanRequest ? onSaveRequest(showSuccessNotification) : onSave(showSuccessNotification)
  }


  const onRejectRequest = () => {
    setOpenRejectReason(true);
  }

  const rejectRequest = () => {
    if (!isNil(stabilizedBridgeStore?.draftLoanInfo?.draftLoanId)) {
      stabilizedBridgeStore.draftLoanInfo.status = REJECTED_STATE
      stabilizedBridgeStore.onLoanSave(REQUEST_LOAN_TYPE_LOAN);
    }
    else {
      stabilizedBridgeStore.rejectLoanApplication();
    }
    push(
      dashboardRoutesConfig.dashboard.url
    )
  }

  const goNextStep = () => {
    const currentStep = loanSubmissionSteps[stabilizedBridgeStore.activeStep].type;
    stabilizedBridgeStore.goNextStep(currentStep);
  }

  const goPrevStep = () => {
    const currentStep = loanSubmissionSteps[stabilizedBridgeStore.activeStep].type;
    stabilizedBridgeStore.goPrevStep(currentStep);
  }

  const moveToStep = (stepIndex) => {
    const currentStep = loanSubmissionSteps[stabilizedBridgeStore.activeStep].type;
    stabilizedBridgeStore.moveToStep(stepIndex, currentStep);
  }

  const setPromotion = (promotionId: number) => {
    stabilizedBridgeStore.loanInformationStore.onChange('marketingPromotionId', promotionId);
  }

  const loanSubmissionSteps = [
    ...insertIf(showBrokerInformation, [{
      title: 'Broker Information',
      icon: <Person color="primary" fontSize="large" />,
      type: LoanSubmissionStep.BROKER_INFORMATION,
      onBack: () => {
        push(loanSubmissionRoutesConfig.submitloan.url);
      },
      onNext: () => goNextStep(),
      onSave: () => saveDraftLoan(),
      onReject: () => onRejectRequest(),
      allowBack: true,
      allowNext: true,
      allowSave: true,
      allowReject: stabilizedBridgeStore.isLoanRequest,
    }]),
    {
      title: 'Entity Information',
      icon: <Business color="primary" fontSize="large" />,
      type: LoanSubmissionStep.ENTITY_INFORMATION,
      onBack: () => { goPrevStep() },
      onNext: () => {
        goNextStep();
      },
      onSave: () => saveDraftLoan(),
      onReject: () => onRejectRequest(),
      allowBack: true,
      allowNext: true,
      allowSave: true,
      allowReject: stabilizedBridgeStore.isLoanRequest,
    },
    {
      title: 'Borrower Information',
      icon: <Person color="primary" fontSize="large" />,
      type: LoanSubmissionStep.BORROWER_INFORMATION,
      onBack: () => goPrevStep(),
      onNext: () => goNextStep(),
      onSave: () => saveDraftLoan(),
      onReject: () => onRejectRequest(),
      allowBack: true,
      allowNext: true,
      allowSave: true,
      allowReject: stabilizedBridgeStore.isLoanRequest,
    },
    {
      title: 'Property Information',
      icon: <Room color="primary" fontSize="large" />,
      type: LoanSubmissionStep.PROPERTY_INFORMATION,
      onBack: () => goPrevStep(),
      onNext: () => goNextStep(),
      onSave: () => saveDraftLoan(),
      onReject: () => onRejectRequest(),
      allowBack: true,
      allowNext: true,
      allowSave: true,
      hidden: false,
      allowReject: stabilizedBridgeStore.isLoanRequest,
    },
    {
      title: 'Loan Details',
      icon: <MonetizationOn color="primary" fontSize="large" />,
      type: LoanSubmissionStep.LOAN_DETAILS,
      onBack: () => goPrevStep(),
      onNext: () => {
        goNextStep()
      },
      onSave: () => saveDraftLoan(),
      onReject: () => onRejectRequest(),
      allowBack: true,
      allowNext: true,
      allowSave: true,
      allowReject: stabilizedBridgeStore.isLoanRequest,
    },
    ...insertIf(globalStore.userFeatures.uploadDuringSubmissionEnabled, [{
      title: 'Documents',
      icon: <Description color="primary" fontSize="large" />,
      type: LoanSubmissionStep.LOAN_DOCUMENTS,
      onBack: () => {
        goPrevStep();
      },
      onNext: () => {
        goNextStep();
      },
      onReject: () => onRejectRequest(),
      onSave: () => saveDraftLoan(),
      allowBack: true,
      allowNext: true,
      allowSave: true,
      allowReject: stabilizedBridgeStore.isLoanRequest,
    }]),
    ...insertIf(marketingPromotionsStore.showPromotions, [{
      title: 'Promotions',
      icon: <Description color="primary" fontSize="large" />,
      type: LoanSubmissionStep.PROMOTIONS,
      onBack: () => {
        goPrevStep();
      },
      onNext: () => {
        goNextStep();
      },
      onReject: () => onRejectRequest(),
      onSave: () => saveDraftLoan(),
      allowBack: true,
      allowNext: true,
      allowSave: true,
      allowReject: stabilizedBridgeStore.isLoanRequest,
    }]),
    {
      title: 'Summary',
      icon: <AssignmentTurnedIn color="primary" fontSize="large" />,
      type: LoanSubmissionStep.SUMMARY,
      onBack: () => goPrevStep(),
      onNext: () => userStore?.allowLoanPricing ? onSubmit() : onLoanApplicationSave(),
      onSave: () => saveDraftLoan(),
      onReject: () => onRejectRequest(),
      allowBack: true,
      allowNext: true,
      allowSave: true,
      allowReject: stabilizedBridgeStore.isLoanRequest,
      disableNextButton: stabilizedBridgeStore.disableSubmitButton,
    },
  ];

  const { loanInformationStore } = stabilizedBridgeStore;

  return (
    <Layout title="Submit Stabilized Bridge Loan">
      <Paper className={classes.paper}>
        {stabilizedBridgeStore.saved ? (
          <>
            <LoanSuccess
              loanId={stabilizedBridgeStore.savedLoanId}
              showDocsAndNewLoanButtons
              onStartAnotherLoan={() => {
                stabilizedBridgeStore.reset();
                stabilizedBridgeStore.pricerStore.resetStores();
                push(loanSubmissionRoutesConfig.submitloan.children.stabilizedBridge.url);
              }}
              hasFastTrack={stabilizedBridgeStore.hasFastTrack}
              isFastTrackPaymentComplete={stabilizedBridgeStore.isFastTrackPaymentComplete}
              fastTrackStore={loanInformationStore}
              sendPaymentEmail={() => stabilizedBridgeStore.sendFastTrackPayment()}
              propertiesQuantity={stabilizedBridgeStore.properties.length}
              fastTrackEnabled={globalStore.userFeatures.fastTrack}
              areBorrowersPreQualified={stabilizedBridgeStore.areBorrowersPreQualified}
            />
          </>
        ) : stabilizedBridgeStore.requestSaved ? (
          <LoanRequestSuccess
            loanRequestId={stabilizedBridgeStore?.savedLoanId ?? stabilizedBridgeStore?.draftLoanInfo?.draftLoanId}
            onStartAnotherLoan={() => {
              stabilizedBridgeStore.reset();
              stabilizedBridgeStore.pricerStore.resetStores();
              push(loanSubmissionRoutesConfig.submitloan.children.stabilizedBridge.url);
            }}
          />
        ) : (
          <>
            <StepsContainer
              disableNavigation={false}
              activeStep={stabilizedBridgeStore.activeStep}
              handleStep={moveToStep}
              steps={loanSubmissionSteps}
            >
              {[
                ...insertIf(showBrokerInformation, [<BrokerInformation />]),
                <EntityInformation store={stabilizedBridgeStore} />,
                <BorrowerInformation store={stabilizedBridgeStore} />,
                <PropertyInformationStbBridge
                  renderSteps={(modalState, handleAdd, handleEdit) =>
                    <PropertyStepsStabilizedBridge
                      modalState={modalState}
                      handleAdd={handleAdd}
                      handleEdit={handleEdit}
                    />
                  } />,
                <LoanInformationStabilizedBridge />,
                ...insertIf(showDocuments, [<LoanDocuments store={stabilizedBridgeStore} saveDraftLoan={() => saveDraftLoan(false)} />]),
                ...insertIf(marketingPromotionsStore.showPromotions, [<SelectPromotionGrid store={marketingPromotionsStore.marketingPromotionsGridStore} promotionData={{ setPromotion: setPromotion, promotionId: stabilizedBridgeStore.loanInformationStore.getValue('marketingPromotionId') }} />]),
                <Summary />
              ]}
            </StepsContainer>
          </>
        )}
      </Paper>
      <StandardDialog
        open={openRejectReason}
        title="Reject Request Reason"
        maxWidth="sm"
        handleClose={() => setOpenRejectReason(false)}
        dialogContent={
          <RejectRequestForm onConfirm={() => rejectRequest()} onClose={() => setOpenRejectReason(false)} draftLoan={stabilizedBridgeStore.draftLoanInfo} />
        }
      />
    </Layout>
  );
});
