import { Service } from '@roc/feature-app-core';

const url = {
  LOAN_DETAILS: '/nportal/rest/priv/loan/getLoanByIdInternalPortal',
  DOCUMENT_DETIALS: '/nportal/rest/priv/loanDocs/getLoanDocumentById',
};

export class AppraisalCdaService extends Service {

  getLoanDetails(loanId: string) {
    return this.get(url.LOAN_DETAILS, {
      loanId,
    });
  }

  getDocumentDetails(loanDocumentId: number) {
    return this.get(url.DOCUMENT_DETIALS, {
      loanDocumentId
    });
  }
}