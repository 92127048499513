import React, { useEffect, useState } from 'react';
import { Box, Divider, Grid, Typography, Paper, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import { createRadioGroupField, createRadioGroupFieldWithParameters } from 'libs/client-portal-shared/src/app/components/formComponents/common/componentBuilder';
import { Card } from '@roc/ui';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    borderRadius: '8px',
  },
  gridStyle: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  buttonStyle: {
    margin: theme.spacing(1),
  },
  radioGroupStyle: {
    margin: theme.spacing(2, 0),
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  contentContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  card: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));



export const DesktopAppraisalPreferences = observer(({ companyProfileStore }) => {
  const { lenderDetailsStore } = companyProfileStore;
  const { lenderDetailsFormStore } = lenderDetailsStore;
  const [editTamariskPreferences, setEditTamariskPreferences] = useState(false);
  const classes = useStyles();

  const handleSave = () => {
    lenderDetailsStore.saveDesktopAppraisalPreferences();
    setEditTamariskPreferences(false);
  };

  const handleCancel = () => {
    setEditTamariskPreferences(false);
  };

  const isLead = lenderDetailsStore.globalStore.userFeatures.backOfficeLead;
  const cardProps = isLead ? { onEdit: () => setEditTamariskPreferences(true) } : {};

  return (
    <Card title='CDA/ARR PREFERENCES' cardContentProps={{ style: { padding: 0 } }} className={classes.card} {...cardProps}>
      <Box p={4}>
        <Box display="flex" flexDirection="row" flexWrap="wrap" justifyContent="space-between">
          <Box flex={1} mr={2}>
            {createRadioGroupFieldWithParameters({
              store: lenderDetailsFormStore,
              fieldName: 'desktopAppraisalPreference',
              label: 'What is the standard report type utilized for desktop appraisal?',
              disabled: !editTamariskPreferences,
              options: [{ label: 'CDA: (Desktop Appraisal)', value: 'CDA' }, { label: 'ARR', value: 'ARR' }]
            })}
          </Box>
        </Box>
        {editTamariskPreferences && (
          <Grid item xs={12} container justifyContent="flex-end">
            <Button
              style={{ marginRight: 16 }}
              variant="outlined"
              onClick={handleCancel}
              color="default"
              className={classes.buttonStyle}
            >
              Cancel
            </Button>
            <Button
              onClick={handleSave}
              color="primary"
              variant="contained"
              className={classes.buttonStyle}
            >
              Save
            </Button>
          </Grid>
        )}
      </Box>
    </Card>
  );
});

export default DesktopAppraisalPreferences;
