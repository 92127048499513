import { Business } from '@material-ui/icons';
import { Button } from '@roc/ui';
import { observer } from 'mobx-react';
import {
  PublicLoanCard,
  PublicLoanCardDividers,
  PublicLoanCardField,
} from './loanSummaryComponents';

export const EntityCard = observer(({ store }) => {
  const { borrowerEntityInformationStore } = store;
  const { mainEntity } = borrowerEntityInformationStore;

  return (
    <PublicLoanCard icon={<Business />} title={'Entity'}>
      <PublicLoanCardDividers px={2} py={2}>
        <PublicLoanCardField
          label="Entity Name"
          value={mainEntity?.name || '-'}
        />
        <PublicLoanCardField
          label="Entity EIN"
          value={mainEntity?.ein || '-'}
        />
        <PublicLoanCardField
          label="Entity Type"
          value={mainEntity?.type || '-'}
        />
      </PublicLoanCardDividers>
    </PublicLoanCard>
  );
});