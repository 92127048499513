import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import { useBaseStore } from "./useBaseStore";

export const useBackwardForwardNavigation = () => {

  const { globalStore } = useBaseStore();
  const history = useHistory();
  const [canGoBack, setCanGoBack] = useState(false);
  const [canGoForward, setCanGoForward] = useState(false);
  const [historyCount, setHistoryCount] = useState(0);
  const [currentHistoryIndex, setCurrentHistoryIndex] = useState(-1);
  const currentHistoryIndexRef = useRef(currentHistoryIndex);

  useEffect(() => {
    currentHistoryIndexRef.current = currentHistoryIndex;
  }, [currentHistoryIndex]);

  useEffect(() => {
    globalStore.history.listen((location, action) => {
      if (action == 'PUSH') {
        const _newHistoryIndex = currentHistoryIndexRef.current + 1;
        const _newHistoryCount = _newHistoryIndex + 1;
        setHistoryCount(_newHistoryCount);
        setCurrentHistoryIndex(Math.max(0, _newHistoryIndex));
      }
    });
  }, []);

  useEffect(() => {
    setCanGoBack(currentHistoryIndex > -1);
    setCanGoForward(historyCount > (currentHistoryIndex + 1));
  }, [historyCount, currentHistoryIndex])

  const goBack = () => {
    if (canGoBack) {
      const newHistoryIndex = currentHistoryIndex - 1;
      setCurrentHistoryIndex(Math.max(-1, newHistoryIndex));
      history.goBack();
    }
  }

  const goForward = () => {
    if (canGoForward) {
      const newHistoryIndex = currentHistoryIndex + 1;
      setCurrentHistoryIndex(Math.min(newHistoryIndex, historyCount - 1));
      history.goForward();
    }
  }

  return {
    canGoBack,
    canGoForward,
    goBack,
    goForward
  };

}