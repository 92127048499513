import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  AccordionSummaryProps,
  Box,
  Container,
  Divider,
  DividerProps,
  Grid,
  GridProps,
  LinearProgress,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowDropDown, Person } from '@material-ui/icons';
import { Alert, AlertTitle } from '@material-ui/lab';
import { isNumber } from '@roc/feature-utils';
import { Button, StandardDialog, StepObj, StepperComponent } from '@roc/ui';
import clsx from 'clsx';
import React from 'react';
import { useNewLoanApplicationStore } from '../hooks/useNewLoanApplicationStore';
import { LoanApplicationStep } from '../utils/loanApplicationConstants';

const useStyles = makeStyles(theme => ({
  card: {
    border: '1px solid #C4C4C4',
    borderRadius: theme.spacing(2),
  },
  cardError: {
    borderColor: theme.palette.error.main,
  },
  cardTitle: {
    fontSize: '24px',
  },
  cardIcon: {
    backgroundColor: '#E4E8EB',
    borderRadius: 50,
    flexShrink: 0,
    width: '50px',
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  cardDivider: {
    backgroundColor: '#C4C4C4',
  },
  cardField: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '36px',
    paddingTop: theme.spacing(1),
  },
  cardActions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

export const LoanStep = ({
  title,
  subtitle,
  children,
  maxWidth = '780px',
  percentageComplete,
  continueButtonText = 'Next',
  disableContinue,
  onBack,
  onContinue,
}: {
  percentageComplete?: number;
  maxWidth?: any;
  title?: string;
  subtitle?: string;
  children: any;
  continueButtonText?: string;
  disableContinue?: boolean;
  onBack?: () => void;
  onContinue?: () => void;
}) => {
  return (
    <Container style={{ maxWidth }}>
      <LoanApplicationStepper />
      {title && (
        <Box pt={5} pb={2}>
          <Typography variant="h4" style={{ fontWeight: 400 }}>
            {title}
          </Typography>
        </Box>
      )}
      {subtitle && (
        <Box pb={2}>
          <Typography style={{ fontSize: 16 }}>{subtitle}</Typography>
        </Box>
      )}
      <Box pt={2} pb={2}>
        {children}
      </Box>
      <Box pt={2} pb={2} display={'flex'}>
        <Box flex={1} pr={2}>
          {onBack && (
            <LoanStepButton variant="outlined" onClick={onBack}>
              Back
            </LoanStepButton>
          )}
        </Box>
        {onContinue && (
          <Box flex={1}>
            <LoanStepButton
              variant="contained"
              disabled={disableContinue}
              onClick={onContinue}
            >
              {continueButtonText}
            </LoanStepButton>
          </Box>
        )}
      </Box>
    </Container>
  );
};

export const LoanStepButton = ({
  variant = 'contained',
  disabled = false,
  onClick,
  children,
}) => {
  return (
    <Button
      testId="stepButton"
      onClick={onClick}
      disabled={disabled}
      color="primary"
      fullWidth
      style={{ textTransform: 'uppercase' }}
      variant={variant as any}
    >
      {children}
    </Button>
  );
};

export const LoanApplicationCard = ({
  title,
  icon,
  children,
  error = false,
}) => {
  const classes = useStyles();
  return (
    <Box className={clsx(classes.card, error && classes.cardError)}>
      <Box p={2} pb={0}>
        <LoanApplicationCardTitle title={title} icon={icon} />
      </Box>
      {children}
    </Box>
  );
};

export const LoanApplicationCardDividers = ({ children, px = 0, py = 0 }) => {
  const classes = useStyles();
  return (
    <Box px={px}>
      {React.Children.map(children, (child, i) => (
        <>
          {i !== 0 && child && (
            <Box>
              <Divider className={classes.cardDivider} />
            </Box>
          )}
          <Box py={py}>{child}</Box>
        </>
      ))}
    </Box>
  );
};

export const LoanApplicationCardTitle = ({ title, icon }) => {
  const classes = useStyles();
  return (
    <Box display={'flex'} alignItems={'center'}>
      <Box mr={2} boxShadow={2} className={classes.cardIcon}>
        {icon}
      </Box>
      <Typography className={classes.cardTitle}>{title}</Typography>
    </Box>
  );
};

export const LoanApplicationCardField = ({ label, value }) => {
  const classes = useStyles();
  return (
    <Box className={classes.cardField}>
      <Typography>
        <b>{label}</b>
      </Typography>
      <Typography>{value}</Typography>
    </Box>
  );
};

export const LoanApplicationCardActions = ({ children }) => {
  const classes = useStyles();
  return <Box className={classes.cardActions}>{children}</Box>;
};

const useAccordionStyles = makeStyles(theme => ({
  accordion: {
    '&::before': {
      content: 'none',
    },
    '& .MuiIconButton-root': {
      marginRight: '0',
      color: 'black',
      paddingLeft: 0,
      paddingRight: 0,
    },
    '& .MuiInputBase-root': {
      backgroundColor: 'white',
    },
    '&.Mui-expanded': {
      margin: 0,
    },

    backgroundColor: 'transparent',
    borderRadius: '0 !important',
    padding: theme.spacing(2),
    transition: 'background-color 0.5s',
  },
  expanded: {
    backgroundColor: '#F4F6F9',
  },
  summary: {
    '& .MuiAccordionSummary-content': {
      margin: 0,
    },
    '& svg': {
      fontSize: '2rem',
    },

    backgroundColor: 'transparent !important',
    padding: 0,
    minHeight: '46px',
    fontSize: '20px',
    fontWeight: 'bold',
    flexDirection: 'row-reverse',
  },
  overflowBackground: {
    margin: theme.spacing(0, -100),
    padding: theme.spacing(0, 100),
  },
  details: {
    padding: 0,
    paddingTop: theme.spacing(2),
    display: 'block',
  },
}));

export const LoanApplicationAccordion = ({
  className = undefined,
  expanded = undefined,
  onChange = undefined,
  overflowExpandedBackground = false,
  summary,
  children,
}) => {
  const classes = useAccordionStyles();
  return (
    <Box
      className={clsx(
        expanded && classes.expanded,
        overflowExpandedBackground && classes.overflowBackground
      )}
    >
      <Accordion
        className={classes.accordion}
        expanded={expanded}
        onChange={onChange}
      >
        <AccordionSummary
          className={classes.summary}
          expandIcon={<ArrowDropDown />}
        >
          {summary}
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          {children}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

const useStepperStyles = makeStyles(theme => ({
  stepper: {
    padding: theme.spacing(2, 0),
    '& span': {
      marginTop: '0 !important',
      fontSize: 14,
    },
    '& .MuiStepLabel-label': {
      paddingTop: theme.spacing(1),
    },
  },
}));

export const LoanApplicationStepper = () => {
  const classes = useStepperStyles();
  const { newLoanApplicationStore } = useNewLoanApplicationStore();
  const { step } = newLoanApplicationStore;

  const activeStep = {
    [LoanApplicationStep.LOAN_TYPE]: 0,
    [LoanApplicationStep.ENTITY_INFORMATION]: 0,
    [LoanApplicationStep.PROPERTY_INFORMATION]: 1,
    [LoanApplicationStep.SUMMARY]: 2,
  }[step];

  const stepperSteps: StepObj[] = [
    { title: 'Entity Information', completed: activeStep > 0, icon: null },
    { title: 'Property Information', completed: activeStep > 1, icon: null },
    { title: 'Summary', icon: null },
  ];

  if (!isNumber(activeStep)) {
    return null;
  }

  return (
    <StepperComponent
      disableNavigation
      alternativeLabel
      activeStep={activeStep}
      steps={stepperSteps}
      className={classes.stepper}
    />
  );
};

const useDialogStyles = makeStyles(theme => ({
  dialog: {
    '& .dialog-content-box': {
      margin: theme.spacing(2),
    },
    '& .MuiDialogTitle-root': {
      paddingTop: theme.spacing(4),
    },
    '& .MuiDialogContent-root': {
      overflowX: 'hidden'
    },
    '& .MuiDialogActions-root': {
      paddingBottom: theme.spacing(4),
    },
  },
}));

export const LoanApplicationDialog = ({
  title,
  dialogState,
  handleClose,
  dialogContent,
  handleSave,
}) => {
  const classes = useDialogStyles();
  return (
    <StandardDialog
      title={title}
      className={classes.dialog}
      open={isNumber(dialogState)}
      handleClose={handleClose}
      dialogContent={dialogContent}
      dialogActions={
        <>
          <Button
            style={{ marginRight: '8px' }}
            onClick={handleClose}
            color="primary"
            variant="outlined"
            testId="cancel"
          >
            CLOSE
          </Button>
          <Button
            onClick={handleSave}
            color="primary"
            variant="contained"
            testId="save"
          >
            SAVE
          </Button>
        </>
      }
    />
  );
};

export const TotalEntityOwnershipWarning = () => {
  return (
    <Alert severity="error">
      <AlertTitle>Entity Ownership Percentages</AlertTitle>
      The Entity’s Ownership Percentages are not adding up to a 100%. Please
      make sure the percentages are correct.
    </Alert>
  );
};
