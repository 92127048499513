
import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { StandardDialog, Button } from '@roc/ui';
import { observer } from 'mobx-react';
import { ScopeOfWorkV2Link } from './scopeOfWorkV2Link';
import {
  ScopeOfWorkV2LinkStore,
  ScopeOfWorkV2Option,
} from '@roc/feature-sow-shared/stores/v2';
import { ScopeOfWorkV2FormDialog } from '../scopeOfWorkV2Form';
import { useScopeOfWorkV2Store } from '@roc/feature-sow-shared/hooks';
import ScopeOfWorkCreateStandalone from './scopeOfWorkCreateStandalone';

const buttonTextMapping = {
  [ScopeOfWorkV2Option.COPY_SCOPE_OF_WORK]: 'Copy and Link Scope of Work',
  [ScopeOfWorkV2Option.LINK_EXISTING_SCOPE_OF_WORK]: 'Link Existing Scope of Work',
  [ScopeOfWorkV2Option.NEW_SCOPE_OF_WORK]: 'Create New Scope of Work'
};

const buttonTextMappingForStandalone = {
  [ScopeOfWorkV2Option.COPY_SCOPE_OF_WORK]: 'Create New Copy of Scope of Work',
  [ScopeOfWorkV2Option.LINK_EXISTING_SCOPE_OF_WORK]: 'Link Existing Scope of Work',
  [ScopeOfWorkV2Option.NEW_SCOPE_OF_WORK]: 'Create New Scope of Work'
};

export type ScopeOfWorkV2LinkExternalDialog = {
  open?: boolean;
  onClose?: () => void;
  loanId: number;
  propertyId: number;
  propertyAddress: string;
  store: ScopeOfWorkV2LinkStore;
};

export const ScopeOfWorkV2LinkExternalDialog = observer(
  (props: ScopeOfWorkV2LinkExternalDialog) => {
    const { scopeOfWorkV2Store } = useScopeOfWorkV2Store();
    const { scopeOfWorkV2FormStore } = scopeOfWorkV2Store;

    const { open, onClose, loanId, propertyId, propertyAddress, store } = props;

    const { canSubmit } = store;

    const handleLinkAndOpen = async () => {
      await store.tryAndLinkScopeOfWork();
    };

    const getButtonText = () => {
      if (loanId) {
        return buttonTextMapping[store.scopeOfWorkOption];
      }
      return buttonTextMappingForStandalone[store.scopeOfWorkOption];
    };

    return (
      <>
        <StandardDialog
          open={open}
          title="Link Scope of Work"
          maxWidth="lg"
          handleClose={onClose}
          dialogContent={
            loanId && typeof loanId == 'number' ? (
              <ScopeOfWorkV2Link
                loanId={loanId}
                propertyId={propertyId}
                propertyAddress={propertyAddress}
                store={store}
              />
            ) : (
              <ScopeOfWorkCreateStandalone
                propertyAddress={propertyAddress}
                store={store}
              />
            )
          }
          dialogActions={
            <Grid container spacing={1} justifyContent="flex-end">
              <Grid item>
                <Button
                  onClick={onClose}
                  color="primary"
                  variant="outlined"
                  testId="cancel"
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <Button
                  onClick={handleLinkAndOpen}
                  color="primary"
                  variant="contained"
                  testId="confirm"
                  disabled={!canSubmit}
                >
                  {getButtonText()}
                </Button>
              </Grid>
            </Grid>
          }
        />

        <ScopeOfWorkV2FormDialog
          open={store.isFormDialogOpen}
          onClose={() => store.closeFormDialog()}
          store={scopeOfWorkV2FormStore}
        />
      </>
    );
  }
);