import { useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { Button, RadioField } from '@roc/ui';
import { observer } from 'mobx-react';
import { useDocumentStore } from '@roc/feature-documents';
const useStyles = makeStyles({
  instruction: {
    fontWeight: 600,
    marginTop: '20px',
    marginBottom: '20px',
  },
});
export type CreateAppraisalFormProps = {
  onConfirm: any;
  onClose: () => void;
};
const yesNoOptions = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];
export const CreateAppraisalForm = observer((props: CreateAppraisalFormProps) => {
  const { onConfirm, onClose } = props;
  const classes = useStyles();
  const { documentStore } = useDocumentStore();
  const { loanPaymentsStore } = documentStore.documentFormStore;
  const [gfdOption, setGfdOption] = useState(false);
  const borrowerPayingGfd = loanPaymentsStore.isBorrowerPayingGfd();
  const changeIsGoodFaithDepositVisible = (value: string) => {
    setGfdOption(value === 'true');
  };
  return (
    <Grid container justifyContent="center">
      {borrowerPayingGfd && (
        <Grid item xs={12}>
          <RadioField
            standaloneLabel
            label="Will the borrower be paying good faith deposit?"
            name="goodFaithDepositDecision"
            value={gfdOption}
            options={yesNoOptions}
            onChange={value => changeIsGoodFaithDepositVisible(value)}
            fullWidth
            row
            testId="goodFaithDepositDecision"
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <Typography className={classes.instruction}>
          Are you sure you want to create an appraisal Order?
          This will automatically submit an appraisal with Tamarisk and
          send a payment request email to the borrower(s).
        </Typography>
      </Grid>
      <Grid item container xs={12} spacing={1} justifyContent="flex-end">
        <Grid item>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => onConfirm(gfdOption)}
            testId="confirmAppraisalCreation"
          >
            Confirm
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="secondary"
            onClick={onClose}
            testId="closeAppraisalCreation"
          >
            Close
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
});
export default CreateAppraisalForm;
