import { Service } from '@roc/feature-app-core';

const url = {
  GET_BORROWER_ENTITY_BORROWERS:
    '/api/v1/document/formDocument/getBorrowerEntityBorrowers',
  UPDATE_BORROWER_ENTITY_FORM:
    '/api/v1/document/formDocument/updateBorrowerEntityForm',
  SAVE_ENTITY_BOROWERS:
    '/api/v1/document/formDocument/saveBorrowerEntityBorrowers',
};

export class BorrowerEntityService extends Service {
  getBorrowerEntityBorrowers = (loanId: number) => {
    return this.get(
      url.GET_BORROWER_ENTITY_BORROWERS,
      {
        loanId,
      },
      {
        disableGlobalLoading: true,
      }
    );
  };

  saveEntityBorrowers = (loanId: number, payload: object) => {
    return this.post(url.SAVE_ENTITY_BOROWERS + '?loanId=' + loanId, payload);
  };

  updateBorrowerEntityForm = (payload: object) => {
    return this.put(url.UPDATE_BORROWER_ENTITY_FORM, payload);
  };
}
