import { Box, Grid } from '@material-ui/core';
import { DialogState, EditableDialog } from '@roc/ui';
import {
  createTextField
} from '@roc/ui/formComponents';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { CommunicationStore } from '../stores/communicationStore';

export const CreateNewConversationDialog = observer(({ id, communicationStore, onSuccessfulCreation }: {
  id?: number,
  communicationStore: CommunicationStore,
  onSuccessfulCreation?: (conversationSid: string) => void;
}) => {
  const { createNewConversationStore } = communicationStore;
  useEffect(() => {
    if (createNewConversationStore.open && id) {
      createNewConversationStore.onFieldChange('loanId', id);
    }
  }, [createNewConversationStore.open]);
  return (
    <EditableDialog
      maxWidth={'sm'}
      title={createNewConversationStore.mode === DialogState.ADD ? 'new conversation' : 'conversation'}
      addDialogTitlePrefix='Create '
      open={createNewConversationStore.open}
      handleClose={() => createNewConversationStore.closeDialog()}
      dialogState={createNewConversationStore.mode}
      handleAdd={() => { createNewConversationStore.createNewConversation(onSuccessfulCreation) }}
      disabledButton={!createNewConversationStore.form.meta.isValid}
      removeContentBox={true}
      dialogContent={
        <Box my={4} mx={0} flexGrow={1}>
          <Grid container spacing={2}>
            {
              id ? <></> :
                <Grid item xs={12}>
                  {createTextField({
                    testId: 'loanId',
                    fieldName: 'loanId',
                    label: 'Loan ID#',
                    store: createNewConversationStore
                  })}
                </Grid>
            }
            <Grid item xs={12}>
              {createTextField({
                testId: 'conversation-name',
                fieldName: 'conversationName',
                label: 'Conversation Name',
                store: createNewConversationStore,
              })}
            </Grid>
          </Grid>
        </Box>
      }
    />
  );
});