import { ExpandMore, Work } from '@material-ui/icons';
import { ItemDisplay } from '../../../../components/itemDisplay';
import { useState } from 'react';
import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  SvgIconProps,
  Paper,
  makeStyles,
  Box,
} from '@material-ui/core';
import { observer } from 'mobx-react';
import {
  DetailsTable,
  DetailsTableField,
} from '../../../../components/detailsTable';
import { ViewControls } from '../../../../components/viewControls';
import { RoundedBackgroundIcon } from '../../../../components/roundedBackgroundIcon';
import { EntityFormStore } from 'libs/feature-one-tool/src/quote/stores/entity/entityFormStore';
import { BorrowersStore } from 'libs/feature-one-tool/src/quote/stores/borrowers/borrowersStore';
import { PercentagePicker } from '../../../leverage/components/leverageComponents';
import { TitleDivider } from 'libs/feature-one-tool/src/quote/components/titleDivider';
import {
  getValueOrDefaultDash,
  parseDate,
} from 'libs/feature-one-tool/src/quote/utils/utils';
import { formatDateAndIsoDate } from '@roc/feature-utils';

interface EntityInformationAccordionProps {
  store: EntityFormStore;
  borrowersStore: BorrowersStore;
  onEdit: () => void;
  showActions?: boolean;
  expanded: boolean;
  onExpand: (expanded: boolean) => void;
}

export const EntityInformationAccordion = observer(
  ({
    store,
    onEdit,
    showActions = true,
    borrowersStore,
    expanded = false,
    onExpand,
  }: EntityInformationAccordionProps) => {
    const entity = store.getFormValues();

    const entityDetails: DetailsTableField[] = [
      {
        label: 'Entity Name',
        value: getValueOrDefaultDash(entity.entityName),
        colSpan: 1,
      },
      {
        label: 'Entity EIN',
        value: getValueOrDefaultDash(entity.entityEin),
        colSpan: 1,
      },
      {
        label: 'Entity Type',
        value: getValueOrDefaultDash(entity.entityType),
        colSpan: 1,
      },
      {
        label: 'Date of Corporation',
        value: entity.dateOfIncorporation ? formatDateAndIsoDate(entity.dateOfIncorporation.toString()) : '-',
        colSpan: 1,
      },
      {
        label: 'Entity Address',
        value: getValueOrDefaultDash(entity.address?.address),
        colSpan: 2,
      },
    ];

    return (
      <>
        <Grid container spacing={2} style={{ padding: '12px' }}>
          <Grid direction="column" style={{ width: '100%' }} spacing={4}>
            <Grid
              item
              container
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item xs={6}>
                <Grid
                  container
                  spacing={2}
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Grid item alignItems="center" style={{ height: '100%' }}>
                    <RoundedBackgroundIcon
                      Icon={(props: SvgIconProps) => <Work />}
                      iconColor="#6D6D6D"
                    />
                  </Grid>
                  <Grid item>
                    <ItemDisplay
                      label="Entity Name"
                      value={entity.entityName}
                      alignItems="flex-start"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid
                  container
                  spacing={3}
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Grid item>
                    <ItemDisplay label="Entity EIN" value={entity.entityEin} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {borrowersStore.borrowerFormStores.map(borrowerStore => {
              return (
                <Grid
                  item
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  style={{ marginTop: '24px' }}
                >
                  <Grid item xs={5}>
                    <Box style={{ marginLeft: '65px' }}>
                      <ItemDisplay
                        label="Borrower Name"
                        value={
                          borrowerStore.form.fields.firstName.value +
                          ' ' +
                          borrowerStore.form.fields.lastName.value
                        }
                        alignItems="flex-start"
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={7}>
                    <Grid
                      container
                      spacing={3}
                      justifyContent="flex-end"
                      alignItems="center"
                    >
                      <Grid item>
                        <ItemDisplay
                          label="Estimated FICO Score"
                          value={borrowerStore.form.fields.ficoScore.value}
                        />
                      </Grid>
                      <Grid item>
                        <ItemDisplay
                          label="Personal Guarantor"
                          value={
                            borrowerStore.form.fields.personalGuarantor.value
                              ? 'Yes'
                              : 'No'
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
          <Grid item xs={12}>
            <Accordion
              expanded={expanded}
              onChange={(e, expanded) => onExpand(expanded)}
              elevation={0}
              style={{ border: 'none' }}
            >
              <AccordionSummary
                expandIcon={<ExpandMore fontSize="large" />}
                aria-controls={`entity-information-accordion`}
              >
                <TitleDivider title="Entity Information" />
              </AccordionSummary>
              <AccordionDetails>
                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  style={{ marginLeft: '58px' }}
                >
                  {showActions && (
                    <Grid item xs={12}>
                      <Grid container spacing={2} justifyContent="flex-end">
                        <ViewControls
                          onDeleteClick={() => null}
                          onEditClick={onEdit}
                          removeButtonDisabled={false}
                          showRemoveButton={false}
                        />
                      </Grid>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <DetailsTable fields={entityDetails} />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </>
    );
  }
);
