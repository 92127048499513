import { Button } from '@roc/ui';
import React, { ReactNode, useState } from 'react';
import { StandardDialog } from './dialog';

export interface AlertDialogProps {
  open: boolean;
  title?: string;
  body: string | ReactNode;
  okButtonText?: string;
  handleClose: React.ReactEventHandler<{}>;
  handleOk?: React.MouseEventHandler<HTMLButtonElement>;
}

export const AlertDialog = ({
  open,
  title = 'Information',
  body,
  okButtonText = 'Ok',
  handleOk,
  handleClose,
}: AlertDialogProps) => {
  return (
    <StandardDialog
      open={open}
      title={title}
      handleClose={handleClose}
      dialogContent={body}
      dialogActions={
        <Button
          onClick={handleOk ?? handleClose}
          color="primary"
          variant="contained"
          testId="ok"
        >
          {okButtonText}
        </Button>
      }
    />
  );
};

export interface WithAlertDialogProps {
  onClick: (event: React.MouseEventHandler<HTMLElement>) => void;
  alertDialogProps: {
    title?: string;
    body: string;
    okButtonText?: string;
  };
}

export const withAlertDialog = <P extends object>(
  Component: React.ComponentType<P>
): React.FC<P & WithAlertDialogProps> => ({
  onClick,
  alertDialogProps,
  ...props
}: WithAlertDialogProps) => {
    const { title, body, okButtonText } = alertDialogProps;
    const [open, setOpen] = useState(false);
    const [targetButtonEvent, setTargetButtonEvent] = useState(null);

    const onTargetButtonClick = (
      event: React.MouseEventHandler<HTMLButtonElement>
    ) => {
      setTargetButtonEvent(event);
      setOpen(true);
    };

    return (
      <>
        <AlertDialog
          open={open}
          title={title}
          body={body}
          okButtonText={okButtonText}
          handleClose={() => setOpen(false)}
          handleOk={() => {
            setOpen(false);
            onClick(targetButtonEvent);
          }}
        />
        <Component onClick={onTargetButtonClick} {...(props as P)} />
      </>
    );
  };
