import { Checkbox, FormControlLabel } from '@material-ui/core';
import { isNotBlank } from '@roc/client-portal-shared/utils';
import { CurrencyField, TextField } from '@roc/ui';
import { observer } from 'mobx-react-lite';
import { FormStore } from '@roc/feature-app-core';

interface EmailAddressProps {
  testId: string;
  name: string;
  label: string;
  store: FormStore;
  readonly: boolean;
}
export const EmailAddress = observer((props: EmailAddressProps) => {
  const { label, name, store } = props;
  const fields = store.form.fields;

  return (
    <TextField
      testId={props.testId}
      variant="outlined"
      standaloneLabel
      label={label}
      value={fields[name].value}
      onChange={e => store.onFieldChange(name, e.target.value)}
      error={isNotBlank(fields[name].error)}
      helperText={fields[name].error}
      disabled={props.readonly}
      fullWidth
      required
    />
  );
});
