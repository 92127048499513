import { Box } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: 50,
    height: 50,
    backgroundColor: '#E4E8EB',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50px',
    boxShadow: '0px 3px 5px -1px #00000033',
  },
}));

export const PersonIcon = () => {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="19"
        height="18"
        viewBox="0 0 19 18"
        fill="none"
      >
        <path
          d="M9.5 0C10.6935 0 11.8381 0.474106 12.682 1.31802C13.5259 2.16193 14 3.30653 14 4.5C14 5.69347 13.5259 6.83807 12.682 7.68198C11.8381 8.52589 10.6935 9 9.5 9C8.30653 9 7.16193 8.52589 6.31802 7.68198C5.47411 6.83807 5 5.69347 5 4.5C5 3.30653 5.47411 2.16193 6.31802 1.31802C7.16193 0.474106 8.30653 0 9.5 0ZM9.5 2.25C8.90326 2.25 8.33097 2.48705 7.90901 2.90901C7.48705 3.33097 7.25 3.90326 7.25 4.5C7.25 5.09674 7.48705 5.66903 7.90901 6.09099C8.33097 6.51295 8.90326 6.75 9.5 6.75C10.0967 6.75 10.669 6.51295 11.091 6.09099C11.5129 5.66903 11.75 5.09674 11.75 4.5C11.75 3.90326 11.5129 3.33097 11.091 2.90901C10.669 2.48705 10.0967 2.25 9.5 2.25ZM9.5 10.125C12.5037 10.125 18.5 11.6213 18.5 14.625V18H0.5V14.625C0.5 11.6213 6.49625 10.125 9.5 10.125ZM9.5 12.2625C6.15875 12.2625 2.6375 13.905 2.6375 14.625V15.8625H16.3625V14.625C16.3625 13.905 12.8413 12.2625 9.5 12.2625Z"
          fill="black"
        />
      </svg>
    </Box>
  );
};
