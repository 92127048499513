import { useBaseStore } from '@roc/feature-app-core';
import {
  getBorrowerLoanRoutes,
  getBorrowerLoanRoutesConfig,
} from '../routes/borrowerLoanRoutes';

export const useBorrowerLoansRoutes = () => {
  const { globalStore } = useBaseStore();
  const { routeBasePath, routeBaseUrl } = globalStore;
  return {
    borrowerLoanRoutes: getBorrowerLoanRoutes(routeBasePath, routeBaseUrl),
    borrowerLoanRoutesConfig: getBorrowerLoanRoutesConfig(
      routeBasePath,
      routeBaseUrl
    ),
  };
};
