import { Layout } from '@roc/feature-app-core';
import { observer } from 'mobx-react-lite';
import { useHistory, useParams } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import { useRoutes } from '../../hooks/useRoutes';
import { useEffect, useState } from 'react';
import { useStore } from '@roc/client-portal-shared/hooks';
import { Tabs, Paper } from '@roc/ui';
import { LoanDetailsLayout } from '@roc/feature-loans-shared';
import { BorrowerDetailsWrapper } from './borrowerDetailsWrapper';
import { ContactDetailsWrapper } from '../contactDetails/contactDetailsWrapper';
import { BORROWER_OBJECT_TYPE } from '../leads/constants/constants';
import { LoanVolume } from '../companyProfile/loanVolume/loanVolume';
import { useLeadsStore } from '../../hooks/useLeadsStore';

const TABS = [
  {
    label: 'CONTACT DETAILS',
  },
  {
    label: 'BORROWER DETAILS',
  },
  {
    label: 'LOAN VOLUME',
  },
];

const useStyles = makeStyles(theme => ({
  tabSection: {
    marginBottom: theme.spacing(1),
  },
  paper: {
    width: '100%',
    height: '100%',
  },
}));

export const BorrowerDetailedScreen = observer(() => {
  const classes = useStyles();
  const { borrowersStore, contactDetailsStore, globalStore, } = useStore();

  const { push } = useHistory();
  const { leadsDashboardRoutesConfig } = useRoutes();
  const { borrowerId } = useParams<{ borrowerId: string }>();
  const [contactId, setContactId] = useState(null);
  const [projectsActive, setProjectsActive] = useState(null);
  const { leadsStore } = useLeadsStore();

  const editAllowed = globalStore?.userFeatures?.allowContactEdit;
  const readOnly = projectsActive > 0 ? (editAllowed ? false : true) : false;

  const [selectedTab, setSelectedTab] = useState(0);

  const onTabChange = (e, index) => setSelectedTab(index);

  useEffect(() => {
    initialize();
  }, [borrowerId]);

  const initialize = async () => {
    await borrowersStore.getBorrowerData(borrowerId);
    await leadsStore.leadDetailsStore.fetchLoanVolumeStats(parseInt(borrowerId), "Borrower");
    setContactId(borrowersStore.currentBorrower?.contactId);
    setProjectsActive(borrowersStore.currentBorrower?.portalProjectsActive ? borrowersStore.currentBorrower?.portalProjectsActive : 0);
  };

  const goToLeadsDashboard = () => {
    push(leadsDashboardRoutesConfig.leadsDashboard.url);
  };

  return (
    <Layout title="Borrower Details" onBack={goToLeadsDashboard} maxWidth="xl">
      <Tabs
        className={classes.tabSection}
        tabs={TABS}
        selectedTab={selectedTab}
        onTabChange={onTabChange}
      />
      {selectedTab === 0 && contactId &&
        <ContactDetailsWrapper
          contactId={contactId}
          borrowerId={parseInt(borrowerId)}
          type={BORROWER_OBJECT_TYPE}
          store={contactDetailsStore}
          readOnly={readOnly}
        />
      }
      {selectedTab === 1 &&
        <Paper className={classes.paper}>
          <LoanDetailsLayout height="100%">
            <BorrowerDetailsWrapper
              borrowerId={borrowerId}
              borrowersStore={borrowersStore}
            />
          </LoanDetailsLayout>
        </Paper>
      }
      {selectedTab === 2 &&
        <LoanVolume loanVolumeStats={leadsStore.leadDetailsStore.loanVolumeStats} borrowerId={borrowerId} />
      }
    </Layout>
  );
});
