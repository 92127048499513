import { Service } from '@roc/feature-app-core';

const url = {
  LOAN_APPRAISALS: '/api/v1/loan/appraisal/getLoanAppraisals',
  STANDALONE_APPRAISALS: '/api/v1/loan/appraisal/getStandaloneAppraisals',
  ADD_APPRAISAL_DOCUMENT: '/api/v1/loan/appraisal/addAppraisalDocument',
};

export class AppraisalsService extends Service {
  getLoanAppraisals(
    page: number,
    pageSize: number,
    sortDir: string,
    sortBy: string,
    filters: object,
    dropdownFilters: object
  ) {
    return this.get(
      url.LOAN_APPRAISALS,
      {
        page,
        pageSize,
        sortDir,
        sortBy,
        filters,
        dropdownFilters,
      },
      {
        disableGlobalLoading: true,
      }
    );
  }
  getStandaloneAppraisals(
    page: number,
    pageSize: number,
    sortDir: string,
    sortBy: string,
    filters: object,
    dropdownFilters: object
  ) {
    return this.get(
      url.STANDALONE_APPRAISALS,
      {
        page,
        pageSize,
        sortDir,
        sortBy,
        filters,
        dropdownFilters,
      },
      {
        disableGlobalLoading: true,
      }
    );
  }
  addAppraisalDocument(
    propertyAppraisalId: number,
    isLoanAppraisal: boolean,
    documentType: string,
    file: File
  ) {
    const formData = new FormData();
    formData.append('propertyAppraisalId', propertyAppraisalId.toString());
    formData.append('isLoanAppraisal', isLoanAppraisal.toString());
    formData.append('documentType', documentType);
    formData.append('file', file);
    return this.post(url.ADD_APPRAISAL_DOCUMENT, formData);
  }
}
