import { Divider, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { GetApp } from '@material-ui/icons';
import { observer } from 'mobx-react';
import { CDAOrderGrid } from './components/CDAOrderGrid';
import { useDocumentStore } from '@roc/feature-documents';
import { Button } from '@roc/ui';
import { useEffect } from 'react';
import { SecondCdaArrOrder } from './secondCdaArrOrder';
import { ConfirmCloseButtons } from './confirmCloseComponent';

const useStyles = makeStyles({
  container: {
    paddingTop: 16,
    paddingBottom: 16,
    width: '100%'

  },
  downloadContainer: {
    display: 'flex',
    flexDirection: 'row',
    cursor: 'pointer',
    marginLeft: '17px',
  },
  icon: {
    fontSize: '24px',
    marginLeft: 4,
  },
  closeButtonContainer: {
    paddingTop: 16,
  }
});

export const CDAOrderDetails = observer(({ loanId, isInternal, isCda, isArr, onConfirm, url, borrowerEmail, isArrPaymentDone, isCdaPaymentDone }) => {

  const classes = useStyles();
  const { documentStore } = useDocumentStore();
  const { documentFormStore } = documentStore;
  const { cdaAndAppraisalOrderDetailsStore } = documentFormStore;
  const { propertiesWithDeviation } = cdaAndAppraisalOrderDetailsStore;

  useEffect(() => {
    cdaAndAppraisalOrderDetailsStore.getPropertiesWithDeviation(loanId);
    if (isInternal)
      window.parent.postMessage('cda-dashboard', '*');
  }, [])

  const handleDownloadClick = () => {
    cdaAndAppraisalOrderDetailsStore.downloadTamariskSignedDocument(loanId, isCda ? 'CDA_Loan' : 'ARR_CDA_Loan');
  };

  return (
    <Grid container className={classes.container} justifyContent="center" spacing={3}>
      <Grid item container direction="row" justifyContent="space-between" xs={11}>
        <Grid item>
          <Typography variant="h5" gutterBottom>{isCdaPaymentDone ? 'CDA' : 'ARR'} Order</Typography>
        </Grid>
        <Grid item
          className={classes.downloadContainer}
          onClick={handleDownloadClick}
          data-html2canvas-ignore>
          <Typography variant="body2" gutterBottom>Download Signed document</Typography>
          <GetApp className={classes.icon} />
        </Grid>
      </Grid>
      <Grid item xs={11}>
        <CDAOrderGrid isCda={isCdaPaymentDone} />
      </Grid>
      {propertiesWithDeviation?.length > 0 &&
        <>
          <Grid item xs={12}>
            <Divider variant="fullWidth" />
          </Grid>
          <SecondCdaArrOrder
            isCda={isCda}
            isArr={isArr}
            onConfirm={onConfirm}
            url={url}
            borrowerEmail={borrowerEmail}
            loanId={loanId}
            isInternal={isInternal}
            isCdaPaymentDone={isCdaPaymentDone}
            isArrPaymentDone={isArrPaymentDone}
          />
        </>
      }
      {!propertiesWithDeviation?.length && <ConfirmCloseButtons isInternal={isInternal} hasConfirmButton={false} />}
    </Grid>
  );
});