import { observer } from 'mobx-react';
import { lighten, makeStyles, Theme } from '@material-ui/core/styles';
import { useACHFormStore } from '../hooks/useACHStore';
import { Box, Grid } from '@material-ui/core';
import { LoanDetailsCard } from './loanDetailsCard';
import { icoBank, icoCalendar, icoFeatherPen } from '../assets';

const useStyles = makeStyles((theme: Theme) => {
  return {
    rightColumn: {
      padding: theme.spacing(8, 6),
    },
    leftColumn: {
      padding: theme.spacing(8, 6),
    },
    hello: {
      ...theme.typography.h4,
      fontSize: 48,
    },
    text: {
      padding: theme.spacing(4, 0),
    },
    button: {
      fontWeight: 'normal',
      padding: theme.spacing(2, 8),
    },
  };
});

export const SummaryStep = observer(() => {
  const classes = useStyles();
  const { achFormStore } = useACHFormStore();
  const { achData } = achFormStore;

  return (
    <Grid container spacing={4}>
      <Grid item xs={6}>
        <Box className={classes.hello}>Thank you, {achData?.borrowerFirstName}!</Box>
        <Box py={2}>
          Thank you for completing the ACH consent process. {achData?.lenderName} is happy to be part of your real estate investment journey.
        </Box>
        {/* <Box py={2}>Here's a quick summary of the form.</Box> */}
      </Grid>
      <Grid item xs={6}>
        <LoanDetailsCard />
      </Grid>
      {/* <Grid item xs={4}>
        <Box textAlign="center">
          <img src={icoBank} />
        </Box>
        Your Bank Information has been securely saved for this loan.
      </Grid>
      <Grid item xs={4}>
        <Box textAlign="center">
          <img src={icoCalendar} />
        </Box>
        The Recurring Monthly Payment Date you chose is the 2nd of every month.
      </Grid>
      <Grid item xs={4}>
        <Box textAlign="center">
          <img src={icoFeatherPen} />
        </Box>
        By signing the ACH Consent DocuSign you agreed to our terms and
        conditions.
      </Grid>
      <Grid item xs={12}>
        <Box textAlign="center" fontSize={12}>
          If you have any questions, please contact our support team.
        </Box>
      </Grid> */}
    </Grid>
  );
});
