// ----------------------------------------------------------------------

export default function List(theme) {
  return {
    MuiListItem: {
      root: {
        '&.Mui-focusVisible': {
          background: 'none',
          outline: '1px dashed #dfdfdf'
        }
      }
    },
    MuiListItemIcon: {
      root: {
        minWidth: 36,
      },
    },
  };
}