import { FormStore } from '@roc/feature-app-core';
import { subYears } from 'date-fns';
import { BaseBorrowerInformationStore } from '../baseBorrowerInformationStore';

const borrowerForm = {
  fields: {
    borrowerId: {
      value: '',
    },
    firstName: {
      value: '',
      error: null,
      rule: 'required',
      attribute: 'first name',
    },
    lastName: {
      value: '',
      error: null,
      rule: 'required',
      attribute: 'last name',
    },
    emailAddress: {
      value: '',
      error: null,
      rule: 'required|email',
      attribute: 'email address',
    },
    confirmEmailAddress: {
      value: '',
      error: null,
      rule: 'required|same:emailAddress',
      message: 'The email address does not match.',
    },
    cellPhone: {
      value: '',
      error: null,
      rule: 'required|regex:/^\\d{3}-?\\d{3}-?\\d{4}$/|alpha_dash',
      message: 'Phone must be a number with the format xxx-xxx-xxxx.',
    },
    pctOwnership: {
      value: '',
      error: null,
      rule: '',
    },
    rentalExperience: {
      value: '',
      error: null,
      rule: 'required',
      message: 'This field is required',
    },
    ficoProvidedAtOrigination: {
      value: 660,
      error: null,
      rule: 'min:660',
      message:
        'Sorry we cannot underwrite loans where the borrowers have less than 660 mid-score FICO',
    },
    citizenshipStatus: {
      value: 'US Citizen',
      error: null,
      rule: '',
    },
    bankruptcy: {
      value: 'N',
      error: null,
      rule: '',
    },
    bankruptcyDate: {
      value: null,
      error: null,
      rule: [
        { required_if: ['bankruptcy', 'Y'] },
        { before_or_equal: subYears(new Date(), 3) },
      ],
      customMessages: {
        required_if: 'This field is required',
        before_or_equal: 'Bankruptcy Date must be no less than 3 Years',
      },
    },
    foreclosure: {
      value: 'N',
      error: null,
      rule: '',
    },
    foreclosureDate: {
      value: null,
      error: null,
      rule: [
        { required_if: ['foreclosure', 'Y'] },
        { before_or_equal: subYears(new Date(), 3) },
      ],
      customMessages: {
        required_if: 'This field is required',
        before_or_equal: 'Foreclosure Date must be no less than 3 Years',
      },
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};
export class SinglePropertyBorrowerInformationStore extends BaseBorrowerInformationStore {
  createBorrowerForm(): FormStore {
    return new FormStore(borrowerForm, this.globalStore);
  }
}
