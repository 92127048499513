import { Avatar, Box, ListItemIcon, Menu, Tooltip } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import { Delete, Edit, MoreVert, Person } from '@material-ui/icons';
import { capitalize, formatDate, insertIf } from '@roc/feature-utils';
import {
  ConfirmationMenuItem,
  DataGrid,
  GridColumnProps,
  IconButton,
  LinkColumn,
  MenuItem,
  StatusChip,
  StatusType,
} from '@roc/ui';
import { formatCurrency } from '@roc/ui/utils';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useGeneralTasksStore } from '../hooks/useGeneralTasksStore';
import {
  GeneralTasksFilters,
  GeneralTasksTab,
  TaskGridRow,
  TaskPriority,
  TaskScope,
} from '../types/generalTasksTypes';
import { taskTypeOptions } from '../utils/generalTasksConstants';
import { DescriptionHtmlCell } from './generalTaskDescriptionCell';
import { GeneralTaskLoanTooltip } from './generalTaskLoanTooltip';
import { GeneralTaskLoanFasttrackTooltip } from './taskList/generalTaskLoanFasttrackTooltip';
import { SubtaskIcon } from './taskList/subtaskIcon';
import { useUserStore } from '@roc/feature-app-core';
import { useLoanRoutes } from '@roc/feature-loans-routes-config';



const useStyles = makeStyles(theme => ({}));

export const TaskListView = observer(() => {
  const { generalTasksStore } = useGeneralTasksStore();
  const { taskListGridStore, editTaskStore, tab, loanId} = generalTasksStore;
  const gridStore = taskListGridStore;
  const [menu, setMenu] = useState<{ el: HTMLElement; row: TaskGridRow }>(null);
  const isLoanTasksView = !!loanId;
  const { userStore } = useUserStore();
  const { loanRoutesConfig } = useLoanRoutes();

  useEffect(() => {
    onTabChange();
  }, [tab]);

  const onTabChange = async () => {
    taskListGridStore.reset();
    await generalTasksStore.resetFilters();
    generalTasksStore.fetchLoanDataForPage();
  };

  const onPageChange = async pageNumber => {
    await gridStore.setPageNumber(pageNumber);
    generalTasksStore.fetchLoanDataForPage();
  };

  const taskScopeList: TaskScope[] | undefined =
      generalTasksStore.filters[GeneralTasksFilters.TASK_SCOPE]
      ?.map(option => option.value);

  const showReportedByMe = taskScopeList?.includes(TaskScope.REPORTED_BY_ME) || taskScopeList?.includes(TaskScope.WATCHED_BY_ME);

  const columns: GridColumnProps[] = [
    // {
    //   field: 'parentTaskId',
    //   headerName: 'Type',
    //   minWidth: 110,
    //   maxWidth: 110,
    //   cellRenderer: 'typeCellRenderer',
    // },
    {
      field: 'taskId',
      headerName: '',
      maxWidth: 85,
      cellRenderer: 'taskIdCellRenderer',
    },
    {
      field: 'title',
      headerName: 'Title',
      minWidth: 400,
      cellRenderer: 'titleCellRenderer',
    },
    // {
    //   field: 'description',
    //   headerName: 'Description',
    //   minWidth: 300,
    //   cellRenderer: 'descriptionCellRenderer',
    // },
    {
      field: 'taskType',
      headerName: 'Category',
      minWidth: 180,
      cellRenderer: 'categoryCellRenderer',
    },
    ...insertIf(!isLoanTasksView, [
      {
        field: 'loanId',
        headerName: 'Loan Id',
        cellRenderer: 'loanIdCellRenderer',
        minWidth: 100,
      },
    ]),
    {
      field: 'timeElapsed',
      headerName: 'Time Elapsed',
      cellRenderer: 'timeElapsedCellRenderer',
      minWidth: 100,
    },
    {
      field: 'priority',
      headerName: 'Priority',
      maxWidth: 120,
      cellRenderer: 'priorityCellRenderer',
    },
    {
      field: 'status',
      headerName: 'Status',
      minWidth: 125,
      maxWidth: 125,
      cellRenderer: 'statusCellRenderer',
    },
    {
      field: 'dueDate',
      headerName: 'Due Date',
      minWidth: 125,
      maxWidth: 125,
    },
    ...insertIf(GeneralTasksTab.MY_TASKS !== tab || showReportedByMe, [
      {
        field: 'assigneeNames',
        headerName: 'Assignee',
        minWidth: 200,
        cellRenderer: 'assigneeCellRenderer',
      },
    ]),
    ...insertIf(GeneralTasksTab.MY_TASKS !== tab || !showReportedByMe, [
      {
        field: 'ownerName',
        headerName: 'Reporter',
        minWidth: 200,
        cellRenderer: 'assigneeCellRenderer',
      },
    ]),
    {
      field: 'createdDate',
      headerName: 'Created Date',
      minWidth: 220,
      cellRenderer: 'dateCellRenderer',
    },
    // {
    //   field: 'hasOpenSubtasks',
    //   headerName: 'Has Open Subtasks',
    //   minWidth: 160,
    //   cellRenderer: 'booleanCellRenderer',
    // },
    // {
    //   field: 'createdBy',
    //   headerName: 'Created By',
    //   minWidth: 200,
    //   cellRenderer: 'dateCellRenderer',
    // },
    {
      field: 'lastUpdatedDate',
      headerName: 'Last Updated Date',
      minWidth: 220,
      cellRenderer: 'dateCellRenderer',
    },
    // {
    //   field: 'taskId',
    //   maxWidth: 80,
    //   cellRenderer: 'actionCellRenderer',
    // },
  ];

  const getUrl = (loanId) => {
    return userStore.isNportalUser
      ? '/nportal/#/loan_information/' + loanId
      : loanRoutesConfig.loans(loanId).children.dashboard.url
  }

  const frameworkComponents = {
    dateCellRenderer(params) {
      return <>{formatDate(params.value)}</>;
    },
    currencyCellRenderer(params) {
      return <>{params.value ? formatCurrency(params.value) : null}</>;
    },
    statusCellRenderer(params) {
      return (
        !!params.value && (
          <StatusChip
            statusType={StatusType.GENERAL_TASK_STATUS}
            label={params.value}
            size="small"
          />
        )
      );
    },
    assigneeCellRenderer(params) {
      return (
        <>
          {params.value && (
            <Chip
              style={{ maxWidth: '100%', textOverflow: 'ellipsis' }}
              icon={<Person />}
              variant="outlined"
              size="small"
              label={params.value}
            />
          )}
        </>
      );
    },
    taskIdCellRenderer(params) {
      const row: TaskGridRow = params.node.data;
      return (
        <LinkColumn
          value={params.value}
          url={'#'}
          onClick={() => {
            editTaskStore.openEditTask(row?.taskId);
          }}
        />
      );
    },
    titleCellRenderer(params) {
      const row: TaskGridRow = params.node.data;
      return (
        <Box display="flex" alignItems="center">
          <Box pr={2}>
            <SubtaskIcon isSubtask={!!row?.parentTaskId} />
          </Box>
          <LinkColumn
            value={params.value}
            url={'#'}
            onClick={() => {
              editTaskStore.openEditTask(row?.taskId);
            }}
          />
        </Box>
      );
    },
    descriptionCellRenderer(params) {
      const row: TaskGridRow = params.node.data;
      return <DescriptionHtmlCell html={params.value} />;
    },
    actionCellRenderer(params) {
      const row: TaskGridRow = params.node.data;
      return (
        <IconButton
          testId="open-menu"
          onClick={e =>
            setMenu({
              el: e.currentTarget,
              row: params.node.data,
            })
          }
        >
          <MoreVert color="primary" />
        </IconButton>
      );
    },
    booleanCellRenderer(params) {
      return <>{params.value ? 'Yes' : 'No'}</>;
    },
    loanIdCellRenderer(params) {
      const row = params.node.data;
      return (
        <>
          {params.value ? (
            <Box alignItems={'center'} display="flex">
              <GeneralTaskLoanTooltip loanId={row.loanId}>
                <LinkColumn
                  value={params.value}
                  url={'#'}
                  onClick={e => {
                    open(getUrl(params.value));
                    e.preventDefault();
                  }}
                />
              </GeneralTaskLoanTooltip>
              <GeneralTaskLoanFasttrackTooltip loanId={row.loanId} />
            </Box>
          ) : null}
        </>
      );
    },
    typeCellRenderer(params) {
      const row = params.node.data;
      return row?.parentTaskId ? (
        <Tooltip title="Subtask">
          <Box>S</Box>
        </Tooltip>
      ) : (
        <Tooltip title="Task">
          <Box>T</Box>
        </Tooltip>
      );
    },
    timeElapsedCellRenderer(params) {
      const row = params.node.data;
      const timeElapsed: number = row?.timeElapsed;
      if (timeElapsed) {
        const days = Math.trunc(timeElapsed / 86400);
        const hours = Math.trunc((timeElapsed % 86400) / 3600);
        return (days > 0 ? `${days}D ` : '') + `${hours}H`;
      } else {
        return null;
      }
    },
    categoryCellRenderer(params) {
      const row = params.node.data;
      const taskType = row?.taskType;
      return (
        <Box style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {taskTypeOptions.find(opt => taskType === opt.value)?.label}
        </Box>
      );
    },
    priorityCellRenderer(params) {
      return (
        !!params.value && (
          <StatusChip
            statusType={StatusType.PRIORITY}
            label={params.value}
            size="small"
          />
        )
      );
    },
  };

  const onSortChanged = params => {
    const sortModel = params.api.getSortModel();
    gridStore.setSortModel(sortModel);
  };

  return (
    <Box>
      <DataGrid
        key={`${tab} ${showReportedByMe}`}
        columns={columns}
        rows={gridStore.gridData.data.rows}
        frameworkComponents={frameworkComponents}
        onSortChanged={onSortChanged}
        isLoading={gridStore.isLoading}
        sortModel={gridStore.sortModel}
        filterModel={gridStore.filterModel}
        paginationData={gridStore.gridData.meta}
        setPageNumber={onPageChange}
        domLayout="autoHeight"
      />
      <Menu
        id="menu-appbar"
        getContentAnchorEl={null}
        anchorEl={menu?.el}
        keepMounted
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={!!menu}
        onClose={() => setMenu(null)}
      >
        <MenuItem
          testId="edit-task"
          onClick={() => {
            editTaskStore.openEditTask(menu.row.taskId);
            setMenu(null);
          }}
        >
          <ListItemIcon>
            <Edit fontSize="small" />
          </ListItemIcon>
          Edit Task
        </MenuItem>
        <ConfirmationMenuItem
          testId="delete-task"
          confirmDialogProps={{
            body: 'Are you sure you want to delete this task?',
          }}
          onClick={() => {
            editTaskStore.deleteTask(menu.row.taskId);
            setMenu(null);
          }}
        >
          <ListItemIcon>
            <Delete fontSize="small" />
          </ListItemIcon>
          Remove Task
        </ConfirmationMenuItem>
      </Menu>
    </Box>
  );
});
