export interface ExpectedClosingData {
  columns: ExpectedClosingDataColumn[];
  rows: object[];
  title: string;
  primaryKey: string;
  nextExpectedClosingDate?: string;
  isEmailSent?: boolean;
  gridHasTotalRow?: boolean;
}

export interface ExpectedClosingDataColumn {
  name: string;
  mapping: string;
  type: string;
}

export enum GridName {
  FUNDED_LOANS = 'FundedLoans',
  WAITING_LOANS = 'WaitingLoans',
}
