import { Paper, Toolbar, Typography, TableContainer, Table, TableHead, TableRow, TableCell, Checkbox, TableBody, IconButton, Collapse, TablePagination, Grid } from "@material-ui/core";
import { lighten, makeStyles } from '@material-ui/core/styles';
import clsx from "clsx";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

const useTableStyles = makeStyles((theme) => {
  return {
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === 'light'
        ? {
          color: theme.palette.primary.main,
          backgroundColor: lighten(theme.palette.primary.light, 0.85),
        }
        : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.primary.dark,
        },
    title: {
      flex: '1 1 100%',
    },
    trackRecordGrid: (props: any) => ({
      '& thead': {
        border: 'solid lightgrey 1px',
      },
      '& tbody': {
        border: 'solid lightgrey 1px',
      },
      '& td': {
        border: 'none',
      },
      ...(props.isExpandable && {
        '& tr:nth-child(even)': {
          borderBottom: 'solid lightgrey 1px',
        },
      }),
      ...(!props.isExpandable && {
        '& tr': {
          borderBottom: 'solid lightgrey 1px',
        },
      }),
    }),
  };
});

const MatDataGrid = observer(({
  store,
  onSelectedRowsChange = undefined,
  onExpandToggle = undefined,
  isExpandable = false,
  headers,
  rows,
  rowsPerPageOptions = [10, 20, 30],
  defaultRowsPerPage = 10,
  enablePagination = false,
  enableSelection = false,
  selectActions = undefined,
}) => {
  const classes = useTableStyles({ isExpandable });
  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(
    enablePagination ? defaultRowsPerPage : rows.length
  );

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      store.setSelectedRows(rows);
      onSelectedRowsChange(rows);
      return;
    }
    store.setSelectedRows([]);
    onSelectedRowsChange([]);
  };

  const handleRowClick = (event, row) => {
    const index = store.selectedRows.findIndex(rowItr => row.id === rowItr.id);

    let newSelected = [];

    if (index === -1) {
      newSelected = newSelected.concat(store.selectedRows, row);
    } else {
      newSelected = [
        ...store.selectedRows.slice(0, index),
        ...store.selectedRows.slice(index + 1)
      ];
    }
    store.setSelectedRows(newSelected);
    onSelectedRowsChange(newSelected);
  };

  const isRowSelected = (row) => {
    return store.selectedRows.some(
      rowItr => row.id == rowItr.id
    );
  }

  const handleExpandClick = (row, index) => {
    if (store.currentSelectedIndex === index) {
      store.setCurrentSelectedIndex(null);
    } else {
      store.setCurrentSelectedIndex(index);
    }
    if (onExpandToggle) {
      onExpandToggle(row, store.currentSelectedIndex !== index);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    store.setCurrentSelectedIndex(null);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    store.setCurrentSelectedIndex(null);
  };

  const numSelected = store.selectedRows.length;

  const displayedRows = enablePagination
    ? rows.slice(page * rowsPerPage, (page + 1) * rowsPerPage)
    : rows;

  return (
    <div style={{ width: '100%' }}>
      <Paper style={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
        {enableSelection && numSelected > 0 &&
          <Toolbar
            className={clsx(classes.root, {
              [classes.highlight]: numSelected > 0,
            })}
          >
            {numSelected > 0 && (
              <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
                {numSelected} selected
              </Typography>
            )}

            {numSelected > 0 &&
              <Grid container spacing={3} style={{ display: 'contents' }}>
                {selectActions}
              </Grid>

            }
          </Toolbar>
        }

        <TableContainer className={classes.trackRecordGrid}>
          <Table>
            <TableHead>
              <TableRow>
                {enableSelection && (
                  <TableCell padding="checkbox">
                    <Checkbox
                      color='primary'
                      indeterminate={store.selectedRows.length > 0 && store.selectedRows.length < rows.length}
                      checked={rows.length > 0 && store.selectedRows.length === rows.length}
                      onChange={handleSelectAllClick}
                    />
                  </TableCell>
                )}
                {isExpandable && <TableCell style={{ padding: '3px' }} />}
                {headers.map((header, index) => (
                  <TableCell key={index}>
                    <strong>{header}</strong>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>

              {displayedRows.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={headers.length + (enableSelection ? 1 : 0) + (isExpandable ? 1 : 0)} align="center">
                    No records found
                  </TableCell>
                </TableRow>
              )
                : (

                  displayedRows.map((row, index) => (
                    <React.Fragment key={index}>
                      <TableRow>
                        {enableSelection && (
                          <TableCell padding="checkbox">
                            <Checkbox
                              color='primary'
                              checked={isRowSelected(row)}
                              onClick={(event) => handleRowClick(event, row)}
                            />
                          </TableCell>
                        )}

                        {isExpandable && row.expandableContent && (
                          <TableCell style={{ padding: '3px' }}>
                            <IconButton
                              aria-label="expand row"
                              size="small"
                              onClick={() => handleExpandClick(row, index)}
                            >
                              {store.currentSelectedIndex === index ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            </IconButton>
                          </TableCell>
                        )}

                        {row.cells.map((cell, cellIndex) => (
                          <TableCell key={cellIndex}>{cell}</TableCell>
                        ))}
                      </TableRow>
                      {isExpandable && row.expandableContent && (
                        <TableRow>
                          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} />
                          <TableCell
                            style={{ paddingBottom: 0, paddingTop: 0 }}
                            colSpan={headers.length + 2}
                          >
                            <Collapse in={store.currentSelectedIndex === index} timeout="auto" unmountOnExit>
                              {row.expandableContent}
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      )}
                    </React.Fragment>
                  ))

                )
              }

            </TableBody>
          </Table>
          {enablePagination && (
            <TablePagination
              rowsPerPageOptions={rowsPerPageOptions}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </TableContainer>
      </Paper>
    </div>

  );
});


export default MatDataGrid;