import { Service } from '@roc/feature-app-core';

const url = {
  GET_2FA_INFO: '/api/v1/user/2fa/info',
  GET_QR_IMAGE_BASE64: '/api/v1/user/2fa/qr',
  GET_2FA_CODE: '/api/v1/user/2fa/code',
  ENABLE_2FA: '/api/v1/user/2fa/enable',
  DISABLE_2FA: '/api/v1/user/2fa/disable',
  ACTIVATE_2FA: '/api/v1/user/2fa/activate',
  VERIFY_2FA: '/api/v1/user/2fa/verify',
};

export class TwoFactorService extends Service {
  getTwoFactorInfo(twoFactorType: string) {
    return this.get(
      url.GET_2FA_INFO,
      {
        twoFactorType
      },
      {
        disableGlobalLoading: true,
      }
    );
  }
  getTwoFactorCode(twoFactorType: string) {
    return this.get(
      url.GET_2FA_CODE,
      {
        twoFactorType
      },
      {
        disableGlobalLoading: true,
      }
    );
  }
  getQrImage(twoFactorType: string) {
    return this.get(
      url.GET_QR_IMAGE_BASE64,
      {
        twoFactorType
      },
      {
        disableGlobalLoading: true,
      }
    );
  }
  enableTwoFactor(twoFactorType: string) {
    return this.post(
      url.ENABLE_2FA,
      {
        twoFactorType
      }, {},
      {
        disableGlobalLoading: true,
      }
    );
  }
  disableTwoFactor(twoFactorType: string, code: string) {
    return this.post(
      url.DISABLE_2FA,
      {
        twoFactorType,
        code
      }, {},
      {
        disableGlobalLoading: true,
      }
    );
  }
  activateTwoFactor(twoFactorType: string, code: string) {
    return this.post(
      url.ACTIVATE_2FA,
      {
        twoFactorType,
        code
      }, {},
      {
        disableGlobalLoading: true,
      }
    );
  }
  verifyTwoFactor(twoFactorType: string, code: string) {
    return this.post(
      url.VERIFY_2FA,
      {
        twoFactorType,
        code
      }, {},
      {
        disableGlobalLoading: true,
      }
    );
  }
}
