import { makeObservable, observable, override } from 'mobx';
import { ClientInternalPricerService } from '../services/clientInternalPricerService';
import { InternalPricerFormValues } from '../types/internalPricerTypes';
import { RateTypeOptions } from '../utils/internalPricerConstants';
import { mapInvestorToName, mapLtvDecimalToRange } from '../utils/internalPricerUtils';
import { InternalPricerBaseStore } from './internalPricerBaseStore';
import { LoanService } from '@roc/feature-loans';
import { UserService } from '@roc/feature-app-core';

export class ClientInternalPricerStore extends InternalPricerBaseStore {
  private clientInternalPricerService: ClientInternalPricerService;
  private loanService: LoanService;
  private user: UserService;
  public lenderNames: { label: string; value: string }[] = [];

  constructor(globalStore) {
    super(globalStore);
    this.clientInternalPricerService = new ClientInternalPricerService();
    this.loanService = new LoanService();
    this.isClientPortal = true;
    this.user = new UserService();

    makeObservable(this, {
      getRatesDictionary: override,
      getLoanValues: override,
      getAutocompleteOptions: override,
      getDefaultValues: override,
      getMonthlyPayment: override,
      getPricerValues: override,
      getMarketTier: override,
    });
  }

  *getRatesDictionary(data: InternalPricerFormValues) {
    const userInfo = yield this.user.getUserInformation()
      .then(result => result.data.data.user);
    const response = yield this.clientInternalPricerService.getRatesDictionary
      ({ ...data, userId: userInfo.userId });
    return response.data;
  }

  *getLoanValues(loanId: string, isDraft: boolean): InternalPricerFormValues {
    const response = yield this.clientInternalPricerService.getLoanValues(loanId, isDraft);
    return response.data.data;
  }

  *getAutocompleteOptions(searchTerm: string) {
    const filters = {
      dealName: searchTerm,
    };
    const response = yield this.loanService.getRentalDealNames(
      1,
      25,
      null,
      null,
      filters
    );
    const rows = response.data.data.rows;
    this.loanSearchOptions = rows.map(row => ({
      label: row.dealName,
      value: row.dealid ? row.dealid : 'DRAFT' + row.draftid,
    }));
  }

  *getDefaultValues() {
    const response = yield this.clientInternalPricerService.getDefaultValues();
    return response.data.data;
  }

  *getStateCounties() {
    const response = yield this.clientInternalPricerService.getStateCounties();
    return response.data;
  }

  *getMonthlyPayment(data: any) {
    const response = yield this.clientInternalPricerService.getMonthlyPayment(data);
    return response.data;
  }

  *getPricerValues(formValues) {
    let rates = this.ltvRates[formValues["rateType"]][formValues["amortizationType"]].baseRates;
    let allInvestorBuydowns = yield this.clientInternalPricerService.getInvestorBuydowns();

    this.calculatedBestLtv = {
      spreadRate: "",
      swapRate: "",
      swapIndex: "",
      monthlyPayment: "",
      buydownFee: 0
    };
    const ltvRange = mapLtvDecimalToRange(formValues["requestedLtv"]);
    //this.maxYsp = this.ltvRates.maxYspBasedonAmortizationType[formValues["amortizationType"]];
    if (rates[ltvRange]) {
      this.calculatedBestLtv.spreadRate = rates[ltvRange].spreadRate ? rates[ltvRange].spreadRate + '%' : rates[ltvRange].errorMessage;
      this.calculatedBestLtv.swapRate = rates[ltvRange].swapRate ? rates[ltvRange].swapRate + '%' : rates[ltvRange].errorMessage;
      this.calculatedBestLtv.swapIndex = rates[ltvRange].swapIndex;

      if (rates[ltvRange].swapRate && rates[ltvRange].spreadRate) {
        const totalRate = rates[ltvRange].swapRate + rates[ltvRange].spreadRate;
        const payment = yield this.fetchMonthlyPayment(totalRate);
        this.calculatedBestLtv.grossRate = parseFloat(totalRate).toFixed(4) + '%';
        this.calculatedBestLtv.monthlyPayment = payment;
      }
      else {
        this.calculatedBestLtv.grossRate = '-';
        this.calculatedBestLtv.monthlyPayment = '-';
      }

      let buydownRatio = parseFloat(this.getBuydown(allInvestorBuydowns, rates[ltvRange].investorId));
      this.calculatedBestLtv.buydownFee = buydownRatio != 0 ? (parseFloat(formValues["currentBuydown"]) / buydownRatio).toFixed(4) + '%' : 0;
    }
  }

  *getMarketTier(stateCounty) {
    const response = yield this.clientInternalPricerService.getMarketTier(stateCounty);
    return response;
  }

  getBuydown(investorBuydowns, investor) {
    const investorId = investor + '.0';
    let investorBuydown = investorBuydowns?.data[investorId];

    for (let rateTypeBuydown of investorBuydown) {
      if (rateTypeBuydown.rateTerm == this.form.fields.rateType.value) {
        return rateTypeBuydown.buydownRatio;
      }
    }
    return 0;
  }

  *getLenderNames() {

  }
}