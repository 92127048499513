import { Grid, makeStyles } from "@material-ui/core";
import { isNil } from "@roc/feature-utils";
import { DataGrid, Paper, VerticalBarLoader } from "@roc/ui";
import { observer } from "mobx-react";
import { useEffect } from "react";


const useStyles = makeStyles(theme => ({
  paper: {
    margin: theme.spacing(2),
    size: '5px',
    '& h5': {
      fontSize: '1.2rem',
    },
    borderRadius: '1px'
  },
  containerSmall: {
    maxHeight: '220px',
    overflow: 'auto'
  },
  containerLarge: {
    maxHeight: '900px',
    overflow: 'auto'
  }

}));

export const MonthlyClosingDataGrids = observer(({ store }) => {
  const classes = useStyles();
  const today = new Date();
  const completeDate = today.toDateString();

  useEffect(() => {
    store.fetchMonthlyClosingData();
  }, []);

  const formatLast15DaysGridData = (last15Days) => {
    if (!last15Days) return;
    const { rows } = last15Days;
    const rowsByDay = groupRowsByDay(rows);
    return {
      ...last15Days,
      rows: rowsByDay,
    };
  }

  const groupRowsByDay = (rows) => {
    return rows.reduce((key, value) => {
      const index = key.findIndex(e => e.closingDate === value.closingDate);
      if (index === -1) {
        key.push(value);
      } else {
        const prevEntry = key[index];
        key[index] = {
          closingDate: prevEntry.closingDate,
          fundDisbursed: prevEntry.fundDisbursed + value.fundDisbursed,
          fundProjected: prevEntry.fundProjected + value.fundProjected,
          totalLoanAmount: prevEntry.totalLoanAmount + value.totalLoanAmount,
        }
      }
      return key;
    }, []);
  }

  const { monthlyClosingColumns, loading } = store;
  const renderTable = (title, data, tableColumns, smallGrid = false, pinnedRow = []) => {
    return (
      <>
        <>
          <Grid item xs={6}>
            <Paper title={title} className={classes.paper}>
              <Grid container className={smallGrid ? classes.containerSmall : classes.containerLarge}>
                <DataGrid
                  columns={tableColumns}
                  rows={isNil(data) ? [] : data.rows}
                  domLayout="autoHeight"
                  pinnedBottomRowData={pinnedRow}
                />
              </Grid>
            </Paper>
          </Grid>
        </>
      </>
    );
  };

  return (
    <>
      {store.monthlyClosingData.length == 0 || loading ? (
        <>
          <VerticalBarLoader />
        </>
      ) : (
        <Grid container spacing={2}>
          {
            renderTable(store.monthlyClosingData.monthlyLoanStatistics.title, store.monthlyClosingData.monthlyLoanStatistics, monthlyClosingColumns.monthlyLoanStatistics, true)}
          {
            renderTable(store.monthlyClosingData.monthlyStatsiticsForLastMonthGrid.title, store.monthlyClosingData.monthlyStatsiticsForLastMonthGrid, monthlyClosingColumns.monthlyStatsiticsForLastMonthGrid, true)}
          {/* {monthlyClosingColumns.last15DaysGrid &&
        renderTable(store.monthlyClosingData.last15DaysGrid.title, formatLast15DaysGridData(store.monthlyClosingData.last15DaysGrid), monthlyClosingColumns.last15DaysGrid, false, store.totalLoansForLast15Days)} */}
          {
            renderTable(store.monthlyClosingData.lastMonthRetractedLoans.title, store.monthlyClosingData.lastMonthRetractedLoans, monthlyClosingColumns.lastMonthRetractedLoans, false, store.totalLastMonthRetractedLoans)}
        </Grid>
      )}
    </>
  );
});