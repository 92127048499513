import {
  useBaseStore
} from '@roc/feature-app-core';
import {
  getRentalPricerRoutes,
  getRentalPricerRoutesConfig
} from './../routes/rentalPricerRoutes';

export const useRentalPricerRoutes = () => {
  const { globalStore } = useBaseStore();
  const { routeBasePath, routeBaseUrl } = globalStore;

  return {
    rentalPricerRoutes: getRentalPricerRoutes(routeBasePath, routeBaseUrl),
    rentalPricerRoutesConfig: getRentalPricerRoutesConfig(
      routeBasePath,
      routeBaseUrl
    ),
  };
};
