import { Service } from '../../../services';

const url = {
  GET_MY_SIZERS_GRID: '/api/v1/loan/sizer/getMySizersGrid',
  GET_SIZER_BY_ID: '/api/v1/loan/sizer/getSizerById',
  SAVE_SIZER: '/api/v1/loan/sizer/saveSizer',
  DELETE_SIZER: '/api/v1/loan/sizer/removeSizer',
  EMAIL_SIZER: '/api/v1/loan/sizer/emailSizer',
};

export class FixFlipSizerService extends Service {
  getMySizersGrid(
    page: number,
    pageSize: number,
    sortDir: string,
    sortBy: string,
    filters: string
  ) {
    return this.get(
      url.GET_MY_SIZERS_GRID,
      {
        page,
        pageSize,
        sortDir,
        sortBy,
        filters,
      },
      {
        disableGlobalLoading: true,
      }
    );
  }

  getSizerById(sizerId) {
    return this.get(url.GET_SIZER_BY_ID, { sizerId });
  }

  saveSizer(data) {
    return this.post(url.SAVE_SIZER, data);
  }

  removeSizer(sizerId) {
    return this.delete(url.DELETE_SIZER, { sizerId });
  }

  emailSizer(data) {
    return this.post(url.EMAIL_SIZER, data);
  }
}
