import { Page } from '../../../components';
import { Route } from 'react-router';
import { useStore } from '@roc/client-portal-shared/hooks';
import { StabilizedBridgeAnalyzer } from '../stabilizedBridgeAnalyzer';

export const getStabilizedBridgeAnalyzerRoutesConfig = (
  basePath: string,
  baseUrl: string
) => {
  return {
    stabilizedBridgeAnalyzer: {
      path: `${basePath}/stabilized-bridge-analyzer`,
      url: `${baseUrl}/stabilized-bridge-analyzer`,
      documentTitle: 'Stabilized Bridge Analyzer',
    },
  };
};

export const getStabilizedBridgeAnalyzerRoutes = (
  basePath: string,
  baseUrl: string
) => {
  const config = getStabilizedBridgeAnalyzerRoutesConfig(basePath, baseUrl);
  const { stabilizedBridgeAnalyzerStore } = useStore();

  return {
    stabilizedBridgeAnalyzer: (
      <Route exact path={config.stabilizedBridgeAnalyzer.path}>
        <Page routeDefinition={config.stabilizedBridgeAnalyzer}>
          <StabilizedBridgeAnalyzer store={stabilizedBridgeAnalyzerStore} />
        </Page>
      </Route>
    ),
  };
};
