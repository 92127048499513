import React, { ReactNode } from 'react';
import { Grid, Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { Add } from '@material-ui/icons';

import PropertyModal, { PropertyModalState } from './components/propertyModal';
import { Button, Card, GoogleMap } from '@roc/ui';
import { observer } from 'mobx-react';
import { GlobalStore } from '@roc/feature-app-core';
import { LoanSubType } from '@roc/feature-utils';
import { getCompletePropertyAddress } from '@roc/ui/utils';
import { ErrorsAlert, WarningsAlert } from '../components/alerts';

const useStyles = makeStyles(theme =>
  createStyles({
    cardSpacing: {
      paddingRight: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    card: {
      background: theme.palette.background.default,
    },
    mapStyles: {
      position: `relative`,
      width: `100%`,
      height: `150px`,
    },
  })
);

interface PropertyInformationProps {
  renderSteps: ReactNode;
  store;
}

const PropertyInformation = (props: PropertyInformationProps) => {
  const { renderSteps, store } = props;
  const classes = useStyles();
  const { propertyStore } = store;
  const { stepErrors, stepWarnings } = store;

  const [modalState, setModalState] = React.useState<PropertyModalState>(null);

  const closeModal = () => {
    setModalState(null);
    propertyStore.reset();
  };

  const displayRenoWarning = () => {
    store.globalStore.notificationStore.showWarningNotification({
      message:
        'Please resubmit this deal as a Stabilized Bridge loan, the program designed for bridge loans with no renovations needed',
    });
  };




  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4">Property Information</Typography>
          <br />
          <Typography variant="body1">
            Please tell us more about the property. If you are submitting a portfolio loan, you can add multiple properties here.
          </Typography>
        </Grid>
      </Grid>

      <br />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="secondary"
            startIcon={<Add />}
            onClick={() => setModalState(PropertyModalState.ADD)}
            testId="add"
            disabled={store.isDealRoom}
          >
            ADD NEW PROPERTY
          </Button>
        </Grid>
      </Grid>
      <br />

      {store.properties.length > 0 && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container>
              {store.properties.map(property => (
                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={4}
                  className={classes.cardSpacing}
                  key={property.propertyId}
                >
                  <Card
                    color="secondary"
                    title={getCompletePropertyAddress(property)}
                    value={property}
                    className={classes.card}
                    onEdit={
                      !property.appraisalId
                        ? property => {
                          setModalState(PropertyModalState.EDIT);
                          store.propertyStore.loadProperty(property);
                        }
                        : null
                    }
                    onView={
                      property.appraisalId
                        ? property => {
                          setModalState(PropertyModalState.VIEW);
                          store.propertyStore.loadProperty(property);
                        }
                        : null
                    }
                    onRemove={
                      store.isDealRoom ? null : property => {
                        store.deleteProperty(property.propertyId);
                      }}
                  >
                    {property.latitude && property.longitude ? (
                      <GoogleMap
                        mapStyles={classes.mapStyles}
                        latitude={property.latitude}
                        longitude={property.longitude}
                      />
                    ) : null}
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      )}

      <PropertyModal
        modalState={modalState}
        open={modalState != null}
        handleClose={closeModal}
        handleAdd={() => {
          if (propertyStore.getProperty().totalRenovationBudget === 0 && (store.loanSubtype === LoanSubType.FIX_AND_FLIP || store.loanSubtype === LoanSubType.FIX_AND_FLIP_PRO)) {
            displayRenoWarning()
          }
          store.addProperty(propertyStore.getProperty());
          propertyStore.reset();
          closeModal();
        }}
        handleEdit={() => {
          if (propertyStore.getProperty().totalRenovationBudget === 0 && (store.loanSubtype === LoanSubType.FIX_AND_FLIP || store.loanSubtype === LoanSubType.FIX_AND_FLIP_PRO)) {
            displayRenoWarning()
          }
          store.updateProperty(propertyStore.getProperty());
          propertyStore.reset();
          closeModal();
        }}
        renderSteps={renderSteps}
        store={store}
      />
      <Grid>
        <Box>
          <ErrorsAlert errors={stepErrors} />
        </Box>
        <Box marginTop={'16px'}>
          <WarningsAlert warnings={stepWarnings} />
        </Box>
      </Grid>
    </>
  );
};

export default observer(PropertyInformation);
