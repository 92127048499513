import { action, flow, observable, makeObservable } from 'mobx';
import { GlobalStore } from '@roc/feature-app-core';
import { LoanStore } from '@roc/feature-loans';
import { ExceptionsService } from './../../../services/documentForms/insuranceApproval/exceptionsService';

export class ExceptionsFormStore {
  private loanStore: LoanStore;
  private globalStore: GlobalStore;
  private exceptionsService: ExceptionsService;
  ignoreFloodCheck: boolean;
  ignoreFloodCheckReason: string;

  constructor(loanStore: LoanStore, globalStore: GlobalStore) {
    this.loanStore = loanStore;
    this.globalStore = globalStore;
    this.exceptionsService = new ExceptionsService();

    this.ignoreFloodCheck = false;
    this.ignoreFloodCheckReason = '';

    makeObservable(this, {
      setIgnoreFloodCheck: action,
      ignoreFloodCheck: observable,
      setIgnoreFloodCheckReason: action,
      ignoreFloodCheckReason: observable,
      saveFloodException: flow,
      reset: action,
    });
  }

  setIgnoreFloodCheck(ignoreFloodCheck: boolean) {
    this.ignoreFloodCheck = ignoreFloodCheck;
    this.ignoreFloodCheckReason = '';
  }

  setIgnoreFloodCheckReason(ignoreFloodCheckReason: string) {
    this.ignoreFloodCheckReason = ignoreFloodCheckReason;
  }

  *saveFloodException(onSave: () => void) {
    try {
      const { loanId } = this.loanStore.loanDetails;
      const reason = this.ignoreFloodCheckReason;

      yield this.exceptionsService.saveFloodExceptionReason(loanId, reason);

      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Information saved successfully.',
      });
      if (!onSave) return;
      onSave();
    } catch (err) {
      this.globalStore.notificationStore.showErrorNotification({
        message:
          'An error occurred while updating the information. Please try again.',
      });
    }
  }

  reset() {
    this.ignoreFloodCheck = false;
    this.ignoreFloodCheckReason = '';
  }
}

export default ExceptionsFormStore;
