import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { CheckCircle } from '@material-ui/icons';
import { useLoanStore } from '@roc/feature-loans';
import { LoanSummaryBrokerContacts } from '@roc/feature-loans-shared';
import { isFundedLoan } from '@roc/feature-utils';
import { InformationButton } from '@roc/ui';
import { formatCurrency, formatPercentage } from '@roc/ui/utils';
import { observer } from 'mobx-react';
import { CustomCard, CustomCardDividers, CustomCardRow } from '../customCard';
import {
  LoanDetailsCustomCard,
  LoanDetailsInfoItem,
} from '../loanDetailsCustomCard';
import { useUserStore } from '@roc/feature-app-core';

export const BrokerCard = observer(() => {
  const { userStore, } = useUserStore();
  const { loanStore } = useLoanStore();
  const { loanDetails } = loanStore;
  const { isLenderUser, isSalesUser } = userStore;

  return (
    <CustomCard title="Broker" paperStyle={{
      height: '100%'
    }}>
      <CustomCardDividers>
        <CustomCardRow
          label="Company Name"
          value={loanDetails?.broker?.company?.name}
        />
        <CustomCardRow
          label="Company EIN Number"
          value={loanDetails?.broker?.company?.einNumber}
        />

        {isLenderUser && isSalesUser && (
          <Box
            mr={2}
            mb={2}
            sx={{ marginTop: '15px' }}
            display="flex"
            justifyContent="flex-end"
          >
            <InformationButton
              size="small"
              variant="contained"
              color="secondary"
              disabled={loanDetails?.broker?.company?.name == undefined}
              onClick={() => console.log('here', loanDetails?.broker)}
              testId="brokerContactsInformation"
              informationDialogProps={{
                title: `${loanDetails?.broker?.company?.name} Contacts`,
                body: <LoanSummaryBrokerContacts broker={loanDetails?.broker} />,
                closeButtonText: 'Close',
              }}
            >
              Contacts
            </InformationButton>
          </Box>
        )}
      </CustomCardDividers>
    </CustomCard>
  );
});
