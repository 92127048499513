
import { Box, Grid } from "@material-ui/core"
import { IdVerificationResult } from "./idVerificationResult"
import { IdVerificationDetails } from "./idVerificationDetails"
import { IdInformation } from "../idInformation";
import { useUserStore } from "@roc/feature-app-core";
import { TodoStatus } from "@roc/feature-loan-details";
import { useEffect } from "react";
import { observer } from "mobx-react";

export const IdVerificationSummary = observer(({ store }) => {
  const { userStore } = useUserStore();

  useEffect(() => {
    store.getIdInformationBorrowerDataCollection();
    store.getBorrowerIdvIdentityResultById();
    store.downloadIDInformationWithIdVerificationDocuments();
  }, []);

  const failed = store.borrowerIdentificationDocumentData?.status != TodoStatus.ACCEPTED || store.borrowerIdvResultsResponse?.verificationResult != 'PASSED';

  return (
    <Box width={'100%'} maxWidth={1200} mx={'auto'}>
      <Grid container spacing={2} style={{
        width: '100%',
        margin: 'auto',
        padding: 8,
      }}>
        {(!failed || userStore.isNportalUser) &&
          <Grid item xs={12}>
            <Box mb={2}>
              <IdVerificationResult store={store} failed={failed} isNportaUser={userStore.isNportalUser} />
            </Box>
            <Box mb={2}>
              <IdVerificationDetails store={store} />
            </Box>
          </Grid>
        }
        {failed &&
          <Grid item xs={12}>
            <IdInformation store={store} />
          </Grid>
        }
      </Grid>
    </Box>
  );
});