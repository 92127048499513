import {


  Typography,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Grid
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { ScreenshotGrid } from '@roc/ui';
import { useParams } from 'react-router';
import GetApp from '@material-ui/icons/GetApp';
import { useState, useRef, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Paper } from '@roc/ui';
import { formatCurrency } from '@roc/ui/utils';
import PreviousDrawInformationCategoryTable from './previousDrawInformationCategoryTable';

const totalColumns: any[] = [
  {
    name: '',
    width: '25%',
    align: 'left',
  },
  {
    name: 'ORIGINAL COST',
    width: '8%',
    align: 'center',
  },
  {
    name: 'TOTAL DISBURSED',
    width: '8%',
    align: 'center',
  },
];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      marginBottom: 8,
    },
    topHeaderSection: {
      marginBottom: 16,
    },
    loanValuesItem: {
      paddingRight: 24,
    },
    paper: {
      padding: theme.spacing(3, 0, 4),
    },
    headerGrid: {
      padding: '12px 48px 12px 48px',
    },
    leftHeaderData: {
      display: 'flex',
      flexDirection: 'row',
    },
    drawNumber: {
      paddingLeft: 24,
    },
    title: {
      color: '#333333',
      fontSize: 20,
      fontWeight: 'bold',
      textTransform: 'uppercase',
    },
    table: {
      minWidth: 750,
      border: '2px #e0e0e0 solid',
      '& td': {
        height: '40px',
        padding: 0,
        paddingTop: '8px',
        paddingBottom: '8px',
      },
      '& td:first-child': {
        height: '40px',
        paddingLeft: '16px',
        paddingTop: '8px',
        paddingBottom: '8px',
      },
      '& th:first-child, td[rowSpan]': {
        paddingLeft: theme.spacing(2),
        paddingRight: '3px',
      },
      '& table tr:last-child td, & table td[rowSpan]': {
        borderBottom: 'none',
      },
    },
    headerCell: {
      height: '41px',
      fontWeight: 'bold',
      padding: '0 4px 0 0',
    },
    totalRow: {
      backgroundColor: '#FDEFBF',
    },
    shareDownloadContainer: {
      paddingRight: 24,
      display: 'flex',
      justifyContent: 'flex-end',
    },
    buttonContainer: {
      marginTop: 32,
    },
    container: {
      padding: 36,
    },
    downloadContainer: {
      display: 'flex',
      flexDirection: 'row',
      cursor: 'pointer',
      marginLeft: '17px',
    },
    buttonLabel: {
      fontSize: '14px',
      paddingLeft: '5px',
    },
    icon: {
      fontSize: '24px',
    },
  })
);

export const PreviousDrawInformation = observer(props => {
  const store = props.store;
  const classes = useStyles();
  const screenshotContainerRef = useRef(null);
  const { drawId } = useParams<{ drawId: string }>();

  const handleSubmit = () => {
    store.submitDrawInformation(drawId);
  };

  const handleSave = () => {
    store.saveDrawInformation(drawId);
  }

  const handleDownload = () => {
    screenshotContainerRef.current.download();
  };

  useEffect(() => {
    store.reset();
    store.loadTableData(drawId);
  }, []);

  if (!store.tableData) {
    return <></>;
  }

  return (
    <Grid container spacing={2} className={classes.container}>
      <Grid item xs={12} className={classes.header}>
        <div className={classes.shareDownloadContainer}>
          <div className={classes.downloadContainer} onClick={handleDownload}>
            <GetApp className={classes.icon} />
            <p className={classes.buttonLabel}>DOWNLOAD AS PDF</p>
          </div>
        </div>
      </Grid>
      <ScreenshotGrid
        fileName="Draw_Review_Report.pdf"
        ref={screenshotContainerRef}
      >
        <Grid item container spacing={2}>
          <Grid item xs={12} md={12}>
            <Paper className={classes.paper}>
              <Grid container spacing={2} className={classes.headerGrid}>
                <Grid item xs={12} md={12}>
                  <Typography variant="h4">
                    DRAW DISBURSED INFORMATION
                  </Typography>
                </Grid>
                {
                  store && store.tableData && store.tableData.map(category => {
                    if (category.subCategories.length) {
                      return (<PreviousDrawInformationCategoryTable
                        category={category}
                        store={store}
                        key={category.name}
                      />);
                    }
                  })
                }
                <Grid item xs={12} md={12}>
                  <Table className={classes.table}>
                    <TableHead>
                      <TableRow>
                        {totalColumns.map(({ name, ...props }, index) => (
                          <TableCell key={`${index}_${name}`} className={classes.headerCell} {...props}>
                            {name}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow className={classes.totalRow}>
                        <TableCell>
                          <Typography variant="h6">
                            Project Total
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography variant="h6">
                            {store?.projectTotal ? formatCurrency(store.projectTotal, 0) : ''}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography variant="h6">
                            {`${store.totalDrawDisbursed.percentage}% | ${formatCurrency(store.totalDrawDisbursed.amount, 0)}`}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </ScreenshotGrid>
      <Grid item xs={12} className={classes.buttonContainer}>
        <Grid container justifyContent="center" spacing={2}>
          <Grid item xs={6} md={2}>
            <Button
              onClick={handleSave}
              color="primary"
              size="large"
              variant="outlined"
              fullWidth
            >
              SAVE
            </Button>
          </Grid>
          <Grid item xs={6} md={2}>
            <Button
              disabled={!store.isFilled}
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              onClick={handleSubmit}
            >
              SUBMIT
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid >
  );
});
