export const formatSectionName = (section: string) =>
  section?.replace(/\s/g, '-')?.toLowerCase();

export const getDocumentSection = (url: string) => {
  const splittedUrl = url.split('/');
  return splittedUrl[splittedUrl.length - 1];
};

export const getDrawId = (url: string) => {
  const str = url.match('[^draws/]?(\\d+)$');
  return str ? str[0] : undefined;
};
export const getExtensionId = (url: string) => {
  const str = url.match('[^extensions/]?(\\d+)$');
  return str ? str[0] : undefined;
};

export const removeFileExtension = (filename: string) =>
  filename.replace(/\.\w+$/, '');

export const capitalize = (text: string) =>
  text
    .toLowerCase()
    .replace(/(^[a-z]| [a-z])/g, (match, letter) => letter.toUpperCase());

export const compareStrings = (strA: string, strB: string) => {
  if (strA < strB) return -1;
  if (strA > strB) return 1;
  return 0;
};
