import { flow, makeObservable } from 'mobx';
import { GlobalStore } from '@roc/feature-app-core';
import { DocumentStore } from '@roc/feature-documents';
import { LoanStore } from '@roc/feature-loans';
import { FormStore } from '@roc/feature-app-core';
import { ExpectedWireReleaseDateService } from '../../services/documentForms/expectedWireReleaseDateService';
import { format, isValid } from 'date-fns';
import { DocumentName } from '../../constants';
import { shouldBeAbleToSubmitSpecificForm } from '../../utils/documentForms';
import { formatDate } from '@roc/feature-utils';

export const expectedWireReleaseDateForm = {
  fields: {
    date: {
      value: null,
      error: null,
      rule: 'required',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export class ExpectedWireReleaseDateStore extends FormStore {
  private documentStore: DocumentStore;
  globalStore: GlobalStore;
  loanStore: LoanStore;
  private expectedWireReleaseDateService: ExpectedWireReleaseDateService;

  constructor(
    documentStore: DocumentStore,
    loanStore: LoanStore,
    globalStore: GlobalStore
  ) {
    super({ ...expectedWireReleaseDateForm }, globalStore);
    this.loanStore = loanStore;
    this.documentStore = documentStore;
    this.globalStore = globalStore;
    this.expectedWireReleaseDateService = new ExpectedWireReleaseDateService();

    makeObservable(this, {
      submitexpectedWireReleaseDateState: flow,
      initialize: flow,
    });
  }

  *initialize() {
    this.reset();
    this.loadForm({
      date: this.loanStore.loanDetails.expectedWireReleaseDate ?? null,
    });
  }

  get canEdit() {
    const entitlements = this.globalStore.userFeatures.closingEntitlements;
    return shouldBeAbleToSubmitSpecificForm(
      entitlements?.isSubmissionAllowed,
      entitlements?.isSubmissionNotAllowedOnly,
      entitlements?.isSubmissionAllowedOnly,
      DocumentName.EXPECTED_WIRE_RELEASE_DATE
    );
  }
  shouldBeAbleToSubmitExpectedWireReleaseDate() {
    return this.canEdit;
  }

  *submitexpectedWireReleaseDateState() {
    try {
      const values = this.getFormValues();
      console.log(values);
      yield this.expectedWireReleaseDateService.submitExpectedWireReleaseDate(
        this.loanStore.loanDetails.loanId,
        values.date ? formatDate(values.date, 'yyyy-MM-dd') : null
      );
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Request made successful.',
      });
      this.documentStore.documentFormStore.closeCurrentForm();
      yield this.loanStore.fetchLoanDetails(this.loanStore.loanDetails.loanId);
    } catch (e) {
      console.log(e);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'An error occurred while submitting the form.',
      });
      this.documentStore.documentFormStore.closeCurrentForm();
    }
  }

  closeModal() {
    return this.documentStore.documentFormStore.setCurrentForm(null);
  }

  manageDate = (fieldName, date) => {
    let formattedDate = null;
    if (isValid(date)) {
      formattedDate = format(date, 'yyyy-MM-dd');
    }
    this.onFieldChange(fieldName, formattedDate);
  };
}

export default ExpectedWireReleaseDateStore;
