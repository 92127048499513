import { makeObservable, override } from 'mobx';
import { LoanService } from '@roc/feature-loans';
import { DscrCalculatorService } from '../services/dscrCalculatorService';
import {
  DscrCalculatorFormValues,
  CalculatedDscrValues,
} from '../types/dscrCalculatorTypes';
import { DscrCalculatorBaseStore } from './dscrCalculatorBaseStore';

export class DscrCalculatorStore extends DscrCalculatorBaseStore {
  private dscrCalculatorService: DscrCalculatorService;
  private loanService: LoanService;

  constructor(globalStore) {
    super(globalStore);
    this.dscrCalculatorService = new DscrCalculatorService();
    this.loanService = new LoanService();
    makeObservable(this, {
      getDefaultValues: override,
      getLoanValues: override,
      getCalculatedValues: override,
      getAutocompleteOptions: override,
    });
  }

  *getDefaultValues() {
    const response = yield this.dscrCalculatorService.getDefaultValues();
    return response.data.data;
  }

  *getLoanValues(loanId: string, isDraft: boolean): DscrCalculatorFormValues {
    const response = yield this.dscrCalculatorService.getLoanValues(loanId, isDraft);
    return response.data.data;
  }

  *getCalculatedValues(data: DscrCalculatorFormValues) {
    const response = yield this.dscrCalculatorService.getCalculatedValues(data);
    return response.data.data;
  }

  *getAutocompleteOptions(searchTerm: string) {
    const filters = { dealName: searchTerm };
    const response = yield this.loanService.getRentalDealNames(
      1,
      25,
      null,
      null,
      filters
    );
    const rows = response.data.data.rows;
    this.loanSearchOptions = rows.map(row => ({
      label: row.dealName,
      value: row.dealid ? row.dealid : 'DRAFT' + row.draftid,
    }));
  }
}
