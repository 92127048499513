import { flow, makeObservable, action, observable, computed } from 'mobx';
import { DrawStore } from '../../stores/drawStore';
import { ApiResponse, GlobalStore } from '@roc/feature-app-core';
import { roundHalf } from '@roc/feature-utils';

export abstract class DrawRequestBaseStore {
  globalStore: GlobalStore;
  drawStore: DrawStore;
  saved: boolean;

  drawDetails: any;

  tableData: any;

  drawDetailsData: any;

  url: string;

  property: any;

  propertyPhotosUrl: string;

  isSowAvailable: boolean;

  showLoansForDrawRequestModal: boolean;

  isPublic: boolean = false;

  constructor(globalStore: GlobalStore, drawStore: DrawStore) {
    this.globalStore = globalStore;
    this.drawStore = drawStore;

    makeObservable(this, {
      tableData: observable,
      property: observable,
      drawDetailsData: observable,
      drawDetails: observable,
      getPropertyPhotosURL: action,
      onTableContentChange: action,
      isFilled: computed,
      totalPreviouslyDisbursed: computed,
      totalCapex: computed,
      totalCompleteToDate: computed,
      totalRequestedThisDraw: computed,
      saveForm: flow,
      submitForm: flow,
      url: observable,
      propertyPhotosUrl: observable,
      saved: observable,
      showLoansForDrawRequestModal: observable,
      setShowLoansForDrawRequestModal: action,
      getURL: action,
      isPublic: observable,
      validateRequestedThisDraw: action,
    });
  }

  reset() {
    this.tableData = null;
    this.drawDetailsData = null;
    this.url = '';
    this.propertyPhotosUrl = '';
    this.saved = false;
    this.property = null;
  }

  abstract saveForm(drawId: any);
  abstract submitForm(drawId: any)
  abstract getURL();
  abstract loadTableData(drawId: any);

  setShowLoansForDrawRequestModal(flag: boolean) {
    this.showLoansForDrawRequestModal = flag;
  }

  showResponse(response: ApiResponse, successMessage: string, errorMessage: string) {
    if (response.data?.status === 'OK') {
      this.globalStore.notificationStore.showSuccessNotification({
        message: successMessage,
      });
    } else {
      this.globalStore.notificationStore.showErrorNotification({
        message: errorMessage,
      });
    }
  }

  initializeDrawRequest(drawRequest, drawDTO) {
    drawRequest.dataContent = JSON.parse(drawRequest.dataContent);
    const categoryMap = {};
    for (const category of drawDTO.scopeOfWorkCategories) {
      categoryMap[category.categoryId] = category.name;
    }
    for (const category of drawRequest.dataContent) {
      category.name = categoryMap[category.id];
    }
  }

  onTableContentChange(
    categoryName: string,
    lineItemName: string,
    detailIndex: any,
    value: any,
    fieldName: string) {
    const selectedCategory = this.tableData.find(
      elem => elem.name === categoryName
    );
    const selectedSubCategory = selectedCategory.subCategories.find(
      elem => elem.name === lineItemName
    );
    if (detailIndex != null) {
      const selectedDetail = selectedSubCategory.itemized[detailIndex];
      selectedDetail[fieldName] = value;
    } else {
      selectedSubCategory.general[fieldName] = value;
    }
  }

  async getPropertyPhotosURL() {
    this.propertyPhotosUrl = 'https://vision.app.link/RQryOleLujb';
  }

  get isFilled() {
    return true;
  }

  get totalCapex() {
    let total = 0;
    if (this.tableData && this.tableData.length) {
      this.tableData.forEach(data => {
        data.subCategories.forEach(category => {
          if (category.itemized.length) {
            category.itemized.forEach(item => {
              if (item.completedRenovations) {
                const capexAmount = item.completedRenovations * item.cost / 100;
                total += capexAmount;
              }
            });
          } else {
            if (category.general.capex) {
              const capexAmount = category.general.capex * category.general.cost / 100;
              total += capexAmount;
            }
          }
        });
      });
    }
    return {
      percentage: total > 0 ? 100 : 0,
      amount: total
    };
  }

  get totalPreviouslyDisbursed() {
    let total = 0;
    if (this.tableData && this.tableData.length) {
      this.tableData.forEach(data => {
        data.subCategories.forEach(category => {
          if (category.itemized.length) {
            category.itemized.forEach(item => {
              if (item.previouslyDisbursed) total += item.previouslyDisbursed;
            });
          } else {
            if (category.general.previouslyDisbursed)
              total += category.general.previouslyDisbursed;
          }
        });
      });
    }
    return {
      percentage: roundHalf(
        (total / this.drawDetailsData?.constructionHoldback) * 100
      ),
      amount: total,
    };
  }

  get totalRequestedThisDraw() {
    let cost = 0;
    if (this.tableData && this.tableData.length) {
      this.tableData.forEach(data => {
        data.subCategories.forEach(category => {
          if (category.itemized.length) {
            category.itemized.forEach(item => {
              if (item.percentageCompleteToDate) {
                cost +=
                  roundHalf((item.percentageCompleteToDate / 100) * item.cost) -
                  (item.previouslyDisbursed || 0);
              }
            });
          } else {
            if (category.general.percentageCompleteToDate) {
              cost +=
                roundHalf(
                  (category.general.percentageCompleteToDate / 100) *
                  category.general.cost
                ) - (category.general.previouslyDisbursed || 0);
            }
          }
        });
      });
    }
    return {
      percentage: roundHalf(
        (cost / this.drawDetailsData?.constructionHoldback) * 100
      ),
      amount: cost,
    };
  }



  get totalCompleteToDate() {
    const completeToDate =
      this.totalRequestedThisDraw.amount + this.totalPreviouslyDisbursed.amount;
    return {
      amount: completeToDate,
      percentage: roundHalf(
        (completeToDate / this.drawDetailsData?.constructionHoldback) *
        100
      ),
    };
  }

  validateRequestedThisDraw = () => {
    if (this.totalRequestedThisDraw.amount > 0) {
      return true;
    } else {
      this.globalStore.notificationStore.showErrorNotification({ message: 'Requested this draw amount should be greater than 0 in order to submit the form' });
    };
  }
}