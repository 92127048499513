
import { Box, Button, Chip, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';

import { CheckCircle } from '@material-ui/icons';
import { observer } from 'mobx-react';
import two_factor_logo from './../../assets/2fa.jpg';
import { Paper } from '@roc/ui';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100px',
    maxHeight: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(2),
  }
}));

const TwoFactorStatus = ({ alertMsg = '', isEnabled, canDisable = false, onEnable, onDisable = () => { } }) => {
  const classes = useStyles();

  if (isEnabled === undefined) {
    return <></>;
  }

  return (
    <div className={classes.root}>
      {alertMsg.length != 0 && <Box m="auto" style={{ maxWidth: 800 }}>
        <Alert severity="warning">{alertMsg}</Alert>
      </Box>}
      <Paper className={classes.paper}>
        <Grid container spacing={2}>
          <Grid item>
            <img className={classes.img} alt="Google Authenticator" src={two_factor_logo} />
          </Grid>
          <Grid item xs={12} sm container>
            <Grid item xs container direction="column" spacing={2}>
              <Grid item xs>
                <Typography gutterBottom variant="h5" style={{
                  marginBottom: 16
                }}>
                  Two-Factor Authentication
                </Typography>
                <Typography gutterBottom variant="body2">
                  2-step verification protects your account with an extra layer of security.
                </Typography>
              </Grid>
              <Grid item>
                {isEnabled ? <Chip
                  icon={<CheckCircle style={{
                    color: 'white'
                  }} />}
                  label="ENABLED"
                  style={{
                    backgroundColor: 'green',
                    letterSpacing: 2,
                    color: 'white',
                    fontWeight: 'bold'
                  }}
                /> : <Chip
                  label="DISABLED"
                  style={{
                    backgroundColor: '#b3b3b3',
                    letterSpacing: 2,
                    color: '#777',
                    fontWeight: 'bold'
                  }}
                />}
                {!isEnabled && <Button
                  variant="outlined"
                  color="primary"
                  onClick={onEnable}
                  className={classes.button}>
                  Turn On
                </Button>}
                {canDisable && isEnabled && <Button
                  variant="outlined"
                  color="default"
                  onClick={onDisable}
                  className={classes.button}>
                  Turn Off
                </Button>}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </div>
  )
}

export default observer(TwoFactorStatus);