import React, { useEffect } from 'react';
import { Grid, Box, List, ListItem, ListItemText, Chip } from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';
import { observer } from 'mobx-react';
import { formatCurrency } from '@roc/ui/utils';
import { isNil } from '@roc/feature-utils';

const formatCurrencyIfNotNil = value => {
  return isNil(value) ? '' : formatCurrency(value);
};

const listItem = (label, value) => {
  return (
    <Grid container direction="row" spacing={2}>
      <Grid item xs>
        {label}
      </Grid>
      <Grid item>{value}</Grid>
    </Grid>
  );
};

const BooleanChip = ({ value }) => {
  const color = value ? green : red;
  return (
    <Chip
      label={value ? 'Yes' : 'No'}
      style={{
        fontWeight: 0,
        backgroundColor: color[200],
        color: color[900],
      }}
    />
  );
};

export type InsuranceDetailsProps = {
  quote: any;
};

export const InsuranceDetails = observer((props: InsuranceDetailsProps) => {
  const { quote } = props;

  return (
    <Grid container spacing={4} direction="row">
      <Grid item xs={4}>
        {listItem('Payment Type:', quote?.billingPaymentType)}
      </Grid>
      <Grid item xs={4}>
        {listItem(
          'Medical Expense Limit:',
          formatCurrencyIfNotNil(quote?.medicalExpenseLimit)
        )}
      </Grid>
      <Grid item xs={4}>
        {listItem(
          'General Liability Occurrence Limit:',
          formatCurrencyIfNotNil(quote?.generalLiabiltyOccurenceLimit)
        )}
      </Grid>
      <Grid item xs={4}>
        {listItem(
          'General Liability Aggregate Limit:',
          formatCurrencyIfNotNil(quote?.generalLiabilityAggregateLimit)
        )}
      </Grid>
      <Grid item xs={4}>
        {listItem(
          'Personal And Advertising Injury Limit:',
          formatCurrencyIfNotNil(quote?.personalAndAdvertisingInjuryLimit)
        )}
      </Grid>
      <Grid item xs={4}>
        {listItem('Flood Limit:', formatCurrencyIfNotNil(quote?.floodLimit))}
      </Grid>
      <Grid item xs={4}>
        {listItem(
          'Damage To Premises Rented To You Limit:',
          formatCurrencyIfNotNil(quote?.damageToPremisesLimit)
        )}
      </Grid>
      <Grid item xs={4}>
        {listItem(
          'Building Limit of Insurance:',
          formatCurrencyIfNotNil(quote?.buildingLimitOfInsurance)
        )}
      </Grid>
      <Grid item xs={4}>
        {listItem(
          'Windstorm Or Hail Deductible:',
          formatCurrencyIfNotNil(quote?.windstormDeductible)
        )}
      </Grid>
      <Grid item xs={4}>
        {listItem(
          'All Other Perils Deductible:',
          formatCurrencyIfNotNil(quote?.allOtherPerilsDeductible)
        )}
      </Grid>
      <Grid item xs={4}>
        {listItem(
          'Liability Deductible:',
          formatCurrencyIfNotNil(quote?.liabilityDeductible)
        )}
      </Grid>
      <Grid item xs={4}>
        {listItem('Causes Of Loss:', quote?.causeOfLoss)}
      </Grid>
      <Grid item xs={4}>
        {listItem('Valuation:', quote?.valuation)}
      </Grid>
      <Grid item xs={4}>
        {listItem('Coinsurance:', formatCurrencyIfNotNil(quote?.coinsurance))}
      </Grid>
      <Grid item xs={4}>
        {listItem(
          'Cost Of Policy:',
          formatCurrencyIfNotNil(quote?.costOfPolicy)
        )}
      </Grid>
      <Grid item xs={4}>
        {listItem(
          'Docusign Completed:',
          <BooleanChip value={quote?.docusignCompleted} />
        )}
      </Grid>
      <Grid item xs={4}>
        {listItem('Tax:', formatCurrencyIfNotNil(quote?.tax))}
      </Grid>
      <Grid item xs={4}>
        {listItem(
          'Gross Premium:',
          formatCurrencyIfNotNil(quote?.grossPremium)
        )}
      </Grid>
      <Grid item xs={4}>
        {listItem('Quote Owner:', quote?.quoteOwner)}
      </Grid>
    </Grid>
  );
});

export default InsuranceDetails;
