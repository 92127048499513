import { useContext } from 'react';
import { StoreContext } from '@roc/feature-app-core';
import { DrawStore } from '../stores/drawStore';
import { PointOfContactStore } from '../pointOfContact/stores/pointOfContactStore';
import { ScopeOfWorkRevisionStore } from '../scopeOfWorkRevision/stores';
import { DrawReviewReportStore } from '../drawReviewReport/stores/drawReviewReportStore';
import { DrawRequestStoreExternal } from '../drawRequests/stores/drawRequestStoreExternal';

export const useDrawsStore = () => useContext<{
  drawStore: DrawStore,
  drawRequestStore: DrawRequestStoreExternal,
  pointOfContactStore: PointOfContactStore,
  scopeOfWorkRevisionStore: ScopeOfWorkRevisionStore,
  drawReviewReportStore: DrawReviewReportStore
}>(StoreContext);
