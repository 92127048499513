import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import { Paper } from '@roc/ui';
import { observer } from 'mobx-react';
import { DocumentItem, DocumentItemType } from './documentItem';

const useStyles = makeStyles({
  table: {
    minWidth: 350,
  },
});

export const DocumentList = observer(({ items, onDocumentItemClick }) => {
  const classes = useStyles();
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size="medium">
        <TableBody>
          {items.map((item) => (
            <TableRow key={item.name}>
              <TableCell scope="row" padding={'none'}>
                <DocumentItem
                  type={(() => {
                    if (item.folder) {
                      return DocumentItemType.FOLDER;
                    }
                    switch (item.fileType) {
                      case "pdf":
                        return DocumentItemType.PDF;
                      case "excel":
                        return DocumentItemType.EXCEL;
                      case "html":
                        return DocumentItemType.HTML;
                      case "video":
                        return DocumentItemType.VIDEO;
                      case "link":
                        return DocumentItemType.LINK;
                      default:
                        return DocumentItemType.OTHER;
                    }
                  })()}
                  name={item.name}
                  onClick={() => onDocumentItemClick(item)}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
});
