import { Box } from "@material-ui/core";
import { useEffect, useState } from "react"

export const CurrencyFormatter = ({ amount }: {
  amount: string
}) => {
  const [negative, setNegative] = useState(false);
  const [decimal, setDecimal] = useState('');
  const [wholeNumber, setWholeNumber] = useState('');
  const [parsedCorrectly, setParsedCorrectly] = useState(false);

  useEffect(() => {
    if (amount && amount.includes('$')) {
      if (amount.includes('-')) {
        setNegative(true);
      }
      const _amount = amount.split('$')[1];
      if (_amount.includes('.')) {
        const _amountParts = _amount.split('.');
        setWholeNumber(_amountParts[0]);
        setDecimal(_amountParts[1]);
      }
      else {
        setWholeNumber(_amount);
      }
      setParsedCorrectly(true);
    }
  }, []);

  return (
    parsedCorrectly ?
      <Box component={'span'}>
        {
          negative && <span style={{
            paddingRight: 2,
          }}>-</span>
        }
        <span style={{
          paddingRight: 2,
        }}>$</span>
        <span>
          {wholeNumber}
        </span>
        <span style={{
          opacity: 0.5,
          fontSize: '65%'
        }}>.{decimal}</span>
      </Box> :
      <Box component={'span'}>
        {amount}
      </Box>
  )
}