import { commonPalette } from "libs/feature-app-core/src/modules/portalConfiguration/theme/common";
import { Theme, lighten, makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Box } from "@material-ui/core";
import { Button } from "@roc/ui";
import { observer } from "mobx-react";

const useStyles = makeStyles((theme: Theme) => {
  return {
    paper: {
      overflow: 'hidden',
    },
    leftColumn: {
      fontFamily: theme.typography.body2.fontFamily,
      backgroundColor: lighten(commonPalette.primary.main, 0.1),
      padding: theme.spacing(8, 6),
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      marginBottom: '30px'
    },
    rightColumn: {
      padding: theme.spacing(8, 6),
      textAlign: 'center',
    },
    title: {
      ...theme.typography.h1,
      paddingBottom: theme.spacing(1),
      fontSize: '4rem',
      color: theme.palette.getContrastText(commonPalette.primary.main),
    },
    subtitle: {
      ...theme.typography.body2,
      fontWeight: 'bold',
      paddingBottom: theme.spacing(2),
      color: theme.palette.getContrastText(commonPalette.primary.main),
    },
    hello: {
      ...theme.typography.h4,
    },
    text: {
      padding: theme.spacing(8),
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(4, 0)
      }
    },
    button: {
      width: '160px'
    },
  }
});

type ReferralFeeAuthProps = {
  onStart: () => void;
}

export const ReferralAuthWelcome = observer((props: ReferralFeeAuthProps) => {
  const classes = useStyles();
  return (
    <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
      <Grid container>
        <Grid item xs={12} md={12} lg={4} className={classes.leftColumn}>
          <Typography className={classes.title}>Welcome</Typography>
          <Typography className={classes.subtitle}>
            Referral Fee Payment
          </Typography>
        </Grid>
        <Grid item md={12} lg={8} className={classes.rightColumn}>
          <Typography className={classes.hello}>
            Click below to start the referral fee payment authorization
          </Typography>
          <Typography className={classes.text}>
            We have simplified the process to just a few steps to allow you to easily enter your bank information
          </Typography>
          <Button
            testId="start"
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={props.onStart}
          >
            START
          </Button>
        </Grid>
      </Grid>
    </Box >
  )
});
