import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { StandardDialog } from '@roc/ui';
import { observer } from 'mobx-react';
import { MyChatContainer, SelectConversation } from './myChatContainer';
import { useUserStore } from '@roc/feature-app-core';
import { FilterType } from './types/communicationTypes';
import { useTechSupportCommunicationStore } from './hooks/useTechSupportCommunicationStore';
import { useEffect } from 'react';
import { useStore } from '@roc/client-portal-shared/hooks';

const useStyles = makeStyles((theme: Theme) => ({
  chatContainer: {
    flex: 1,
  },
}));

interface TechSupportConversationProps {
  showEmailAddress?: boolean;
  conversationSid?: string
}

export const TechSupportConversation = observer(
  ({
    showEmailAddress = false,
    conversationSid
  }: TechSupportConversationProps) => {
    const classes = useStyles();
    const { userStore } = useUserStore();
    const { globalStore } = useStore();
    const { techSupportCommunicationStore } = useTechSupportCommunicationStore();
    const companyId = userStore.userInformation.companyId ?? globalStore?.selectedCompanyId;

    useEffect(() => {
      if (conversationSid) {
        techSupportCommunicationStore.setCurrentConversationId(conversationSid)
      }
    }, [techSupportCommunicationStore.currentConversationSid])

    return (
      <>
        <StandardDialog
          open={techSupportCommunicationStore.openModal}
          maxWidth="lg"
          handleClose={() => {
            techSupportCommunicationStore.setModalOpen(false)
          }}
          dialogContent={
            <>
              <Box display="flex" height={'80vh'}>
                <MyChatContainer
                  id={Number(companyId)}
                  communicationStore={techSupportCommunicationStore}
                  allowNewConversations={false}
                  allowJoinConversation={true}
                  showEmailAddress={showEmailAddress}
                  showSnippet={false}
                  className={classes.chatContainer}
                  allowRemoveParticipants={true}
                  allowManageParticipants={true}
                  allowAddInternalParticipants={true}
                  filterType={FilterType.SUPPORT}
                  onConversationSelect={conversationId => {
                    techSupportCommunicationStore.setSupportConversationSid(conversationId)
                  }
                  }
                  hideSidebar={techSupportCommunicationStore?.responseValues?.length == 1}
                />
                <SelectConversation communicationStore={techSupportCommunicationStore} />
              </Box>
            </>
          }
        />
      </>
    );
  }
);
