import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { observer } from "mobx-react";
import { Box, Grid, Typography, Fab, Badge, Divider } from '@material-ui/core';
import { generalStatsColumns, transformDataV2, protectiveAdvancesColumns, valuationHistoryColumns } from './foreclosureOverviewUtils';
import { useForeclosureSummaryStore } from '../../hooks/useForeclosureSummaryStore';
import { DetailsCard } from './detailsCard';
import { TotalsTable } from './totalsTable';
import { LedgerNotesCard } from '../notes/ledgerNoteCard';
import { Chat } from '@material-ui/icons';
import { FilterType, MyChatContainer, SelectConversation, useLoanCommunicationStore } from '@roc/feature-communication';
import { StandardDialog } from '@roc/ui';
import { ForeclosureReasonStatus, UpdateForeclosureReasonPopup } from './foreclosureReasonComponents';
import { ForeclosureRepurchase } from './foreclosureRepurchase';
import { ForeclosureRemindMe, PickRemindMeDate } from './foreclosureRemindMe';
import { ValuationHistoryTable } from './valuationHistoryTable';
import { RealEstateOwnedDetailsCard } from './realEstateOwnedDetailsCard';
import { ForeclosureStageList } from '../foreclosureStages/foreclosureStagesList';
import { useForeclosureRequestStore } from '../../hooks/useForeclosureRequestStore';
import { LoanDqStageData } from '../../types/loanDqStageData';
import { ForeclosureStepper } from '../foreclosureStages/foreclosureStepper';
import { LoanForeclosureBreakdownDialog } from '../loanForeclosureBreakdown';
import { ForeclosureBreakdown } from './foreclosureBreakdown';
import { BorrowerProfile } from './borrowerProfile';
import LineChart from './lineChart';
import { ForeclosureRedirectLink } from './foreclosureRedirectLink';
import { SoldAtForeclosureDetails } from './soldAtForeclosureDetails';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
      margin: 'auto',
      padding: 8,
    },
    topHeaderSection: {
      marginBottom: 16,
      fontFamily: 'Oswald, sans-serif;',
      fontWeight: 'bold',
      marginLeft: 36,
    },
    root: {
      backgroundColor: 'white',
      marginTop: '0px',
    },
    fab: {
      position: 'fixed',
      bottom: 40,
      right: 50,
      width: 65,
      height: 65,
    },
    containerWrapperInternal: {
      overflow: 'auto',
      flexGrow: 1,
    },
    dialog: {
      overflow: "hidden"
    },
  }),
);

export const ForeclosureDetailsOverview = observer(() => {
  const classes = useStyles();
  const { loanId } = useParams<{ loanId: string }>();
  const { foreclosureSummaryStore } = useForeclosureSummaryStore();
  const { loanCommunicationStore } = useLoanCommunicationStore();
  const { ledgerNoteStore } = foreclosureSummaryStore;
  const { foreclosureSummary } = foreclosureSummaryStore;
  const [chatModalOpen, setChatModalOpen] = useState(false);
  const [reasonCodesModalOpen, setReasonCodesModalOpen] = useState(false);
  const [remindMeModalOpen, setRemindMeModalOpen] = useState(false);
  const [reasonCodeValue, setReasonCodeValue] = useState([])
  const { foreclosureRequestStore } = useForeclosureRequestStore();
  const [openUpdateForeclosureStages, setOpenUpdateForeclosureStages] = useState(false);
  const [isBreakdownModalOpen, setIsBreakdownModalOpen] = useState(false);

  useEffect(() => {
    foreclosureSummaryStore.getSummary(loanId);
    ledgerNoteStore.fetchNotes(parseInt(loanId));
    foreclosureRequestStore.getActiveForeclosureDataByLoanId(loanId);
  }, [loanId, foreclosureSummaryStore]);

  useEffect(() => {
    if (foreclosureSummary?.conversationSid && chatModalOpen && !loanCommunicationStore.currentConversationSid) {
      loanCommunicationStore.setCurrentConversationId(foreclosureSummary.conversationSid);
    }
  }, [foreclosureSummary, chatModalOpen, loanCommunicationStore.currentConversationSid]);

  if (!foreclosureSummary) {
    return <></>;
  };

  const stepIndex = foreclosureSummary?.foreclosureStage == null ? -1 :
    foreclosureSummary.foreclosureStageList.findIndex(
      step => step.trim() === foreclosureSummary.foreclosureStage.trim()
    );

  const loanClosingSteps = foreclosureRequestStore.listForeclosureStages.map((stage, index) => ({
    title: stage.stageName,
    date: stage.stageDate,
    completed: stepIndex > index,
  }))

  const handleReasonCodesModalClose = () => {
    setReasonCodesModalOpen(false);
    setReasonCodeValue([]);
  }

  const handleRemindMeModalClose = () => {
    setRemindMeModalOpen(false);
  }

  const handleReasonCodesModalOpen = () => {
    setReasonCodesModalOpen(true);
  }

  const handleRemindMeModalOpen = () => {
    setRemindMeModalOpen(true);
  }

  const foreclosureReasonOnChange = (_event: any, newValue: any | null) => {
    setReasonCodeValue(newValue);
  }

  const openForeClosureStagesModal = () => {
    setOpenUpdateForeclosureStages(true);
  };

  const closeForeClosureStagesModal = () => {
    setOpenUpdateForeclosureStages(false);
  }

  const closeBreakdownModal = () => {
    setIsBreakdownModalOpen(false);
  };

  const openBreakdownModal = () => {
    setIsBreakdownModalOpen(true);
  };

  const saveForeclosureStages = async (stages: LoanDqStageData) => {
    await foreclosureRequestStore.saveForeclosureStages(loanId, stages);
    await foreclosureSummaryStore.getSummary(loanId);
    closeForeClosureStagesModal();
  }

  return (
    <Box width={'100%'} mt={3} className={classes.root}>
      <Grid container spacing={2} className={classes.container}>
        <Grid item container xs={12} alignItems='center' alignContent='center'>
          <Grid item xs>
            <Typography variant='h4' className={classes.topHeaderSection}>
              {`[${loanId}] ${foreclosureSummary.dealName}`}
            </Typography>
          </Grid>
          <Grid item xs>
            <Box display='flex' justifyContent='right'>
              <ForeclosureRedirectLink loanId={loanId} />
              <Divider orientation='vertical' flexItem />
              <ForeclosureBreakdown handleModalOpen={openBreakdownModal} />
              <Divider orientation='vertical' flexItem />
              <ForeclosureRemindMe
                remindMeDate={foreclosureSummary.followUpDate}
                handleRemindMeModalOpen={handleRemindMeModalOpen}
              />
              <Divider orientation='vertical' flexItem />
              <ForeclosureRepurchase isRepurchaseRequested={!!foreclosureSummary.repurchaseRequested} />
              <Divider orientation='vertical' flexItem />
              <ForeclosureReasonStatus
                foreclosureReason={foreclosureSummary.foreclosureReason}
                handleReasonCodesModalOpen={handleReasonCodesModalOpen}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12}>
          <Box mb={2}>
            <ForeclosureStepper
              activeStep={stepIndex}
              steps={loanClosingSteps}
              alternativeLabel={true}
              handleStep={openForeClosureStagesModal}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={8}>
          <Box mb={2}>
            <DetailsCard title={'Loan Details'} data={transformDataV2(foreclosureSummary, generalStatsColumns)} columnsNum={4} />
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box mb={2}>
            <SoldAtForeclosureDetails />
          </Box>
          <Box mb={2}>
            <RealEstateOwnedDetailsCard />
          </Box>
        </Grid>
        <Grid item xs={12} md={8}>
          <Box mb={2}>
            <TotalsTable
              title={'Paid Protective Advances'}
              columns={protectiveAdvancesColumns}
              total={foreclosureSummary.totalProtectiveAdvances}
              data={foreclosureSummary.protectiveAdvances}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box mb={2}>
            <LedgerNotesCard loanId={parseInt(loanId)} store={ledgerNoteStore} />
          </Box>
          <Box mb={2}>
            <BorrowerProfile />
          </Box>
          <Box mb={2}>
            <LineChart
              dataSet={foreclosureSummary.valuationDataSet}
              title={`Total Exposure vs Valuation`}
              dataLabel={['Exposure', 'Valuation']}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={8}>
          <Box mb={2}>
            <ValuationHistoryTable
              title={'Valuation History'}
              columns={valuationHistoryColumns}
              data={foreclosureSummary.valuationHistory}
            />
          </Box>
        </Grid>
      </Grid>
      <Fab className={classes.fab} color="primary" aria-label="add" onClick={() => { setChatModalOpen(true) }}>
        <Badge color='error' badgeContent={foreclosureSummary.unreadMessages || null}>
          <Chat style={{ fontSize: '32px' }} />
        </Badge>
      </Fab>
      <StandardDialog
        open={chatModalOpen}
        maxWidth="lg"
        handleClose={() => {
          setChatModalOpen(false);
          loanCommunicationStore.setCurrentConversationId(null);
        }}
        dialogContent={
          <Box display="flex" height={'80vh'}>
            <MyChatContainer
              id={Number(loanId)}
              communicationStore={loanCommunicationStore}
              allowNewConversations={false}
              allowJoinConversation={true}
              showEmailAddress={false}
              showSnippet={false}
              className={classes.containerWrapperInternal}
              allowRemoveParticipants={true}
              allowManageParticipants={true}
              filterType={FilterType.LOAN_ID}
              allowAddInternalParticipants={true}
              onConversationSelect={
                conversationId => loanCommunicationStore?.setCurrentConversationId(conversationId)
              }
            />
            <SelectConversation communicationStore={loanCommunicationStore} />
          </Box>
        }
      />
      <StandardDialog
        maxWidth="sm"
        title="Update Foreclosure Reason"
        open={reasonCodesModalOpen}
        handleClose={handleReasonCodesModalClose}
        dialogContent={
          <UpdateForeclosureReasonPopup
            value={reasonCodeValue}
            onChange={foreclosureReasonOnChange}
            loanType={foreclosureSummary.loanType}
            onClose={handleReasonCodesModalClose}
          />
        }
      />
      <StandardDialog
        maxWidth="sm"
        title="Change Follow Up Date"
        open={remindMeModalOpen}
        handleClose={handleRemindMeModalClose}
        dialogContent={
          <PickRemindMeDate
            currentFollowUpDate={foreclosureSummary.followUpDate}
            onClose={handleRemindMeModalClose}
          />
        }
      />
      <StandardDialog
        className={classes.dialog}
        dialogContentclasses={{ root: classes.dialog }}
        open={openUpdateForeclosureStages}
        title={"Foreclosure Stages"}
        maxWidth="lg"
        handleClose={closeForeClosureStagesModal}
        dialogContent={(
          <ForeclosureStageList
            loanId={loanId}
            store={foreclosureRequestStore}
            handleClose={closeForeClosureStagesModal}
            handleSave={saveForeclosureStages}
          />
        )}
      />
      <LoanForeclosureBreakdownDialog
        open={isBreakdownModalOpen}
        onClose={closeBreakdownModal}
      />
    </Box>
  );
});
