import { Box, Grid } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Button, ConfirmDialog, StandardDialog } from '@roc/ui';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useLoanActionsStore } from '../hooks/useLoanActionsStore';

export const GenerateClosingDocumentsModal = observer(
  ({ open, handleClose }) => {
    const { loanActionsStore } = useLoanActionsStore();
    const { generateClosingDocsStore } = loanActionsStore;
    const { checkResult } = generateClosingDocsStore;

    useEffect(() => {
      if (open) {
        generateClosingDocsStore.checkRequirements();
      } else {
        generateClosingDocsStore.resetStore();
      }
    }, [open]);

    const confirm = () => {
      generateClosingDocsStore.generateClosingDocuments();
      handleClose();
    };

    return (
      <StandardDialog
        open={open}
        handleClose={handleClose}
        title={'Generate Closing Documents'}
        maxWidth={'sm'}
        dialogContent={
          <Grid container spacing={2}>
            {checkResult?.canGenerateClosingDocs === false && (
              <Grid item xs={12}>
                <Alert severity="error">
                  <b>Unable to generate closing documents:</b>
                  {checkResult.errors.map(message => (
                    <Box> - {message}</Box>
                  ))}
                </Alert>
              </Grid>
            )}
            <Grid item xs={12}>
              Generating documents is only necessary if you made any changes to
              them recently. They will not be available for a couple of minutes
              if you continue. Do you want to proceed?
            </Grid>
          </Grid>
        }
        dialogActions={
          <>
            <Button
              style={{ marginRight: '8px' }}
              onClick={handleClose}
              color="primary"
              variant="outlined"
              testId="cancel"
            >
              Cancel
            </Button>
            <Button
              disabled={!checkResult?.canGenerateClosingDocs}
              onClick={confirm}
              color="primary"
              variant="contained"
              testId="confirm"
            >
              Yes
            </Button>
          </>
        }
      />
    );
  }
);
