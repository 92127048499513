import { ScopeOfWorkV2FormService } from '@roc/feature-sow-shared/services';
import { addQueryParam } from '@roc/feature-utils';

const baseUrl = '/api/v1/draws/revision';

const url = {
  GET_SCOPE_OF_WORK_SHARE_URL: `${baseUrl}/getScopeOfWorkShareUrl`,
  GET_NEW_SCOPE_OF_WORK: `${baseUrl}/getNewScopeOfWork`,
  GET_SCOPE_OF_WORK: `${baseUrl}/getScopeOfWorkById`,
  SUBMIT_SCOPE_OF_WORK: `${baseUrl}/submitSowRevision`,
  GET_SCOPE_OF_WORK_CATEGORIES: `${baseUrl}/getScopeOfWorkCategories`,
  GET_SCOPE_OF_WORK_REVISION: `${baseUrl}/getSowRevisionById`,
  GET_SPLITTED_CATEGORIES: `${baseUrl}/getSplitedCategoriesRevision`,
};

export class ScopeOfWorkRevisionFormService extends ScopeOfWorkV2FormService {
  constructor() {
    super();
    this.url = url;
  }

  getSplitedCategories(drawId: string){
    return this.get(`${url.GET_SPLITTED_CATEGORIES}?drawId=${drawId}`);
  }

  getScopeOfWorkShareUrl(drawId: string) {
    const params = addQueryParam('', 'drawId', drawId);
    return this.get(
      `${this.url.GET_SCOPE_OF_WORK_SHARE_URL}${params ? `?${params}` : ''}`
    );
  }

  getScopeOfWorkById(drawId: string) {
    const params = addQueryParam('', 'drawId', drawId);
    return this.get(
      `${this.url.GET_SCOPE_OF_WORK_REVISION}${params ? `?${params}` : ''}`
    );
  }

  submitScopeOfWork(drawId: string, data: any, silent = false) {
    const params = addQueryParam('', 'drawId', drawId);
    const options = { disableGlobalLoading: silent };
    return this.post(
      `${this.url.SUBMIT_SCOPE_OF_WORK}?${params}`,
      data,
      {},
      options
    );
  }

}