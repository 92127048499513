import { Box, makeStyles, TableCell, TableRow, Theme } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { EditableDialog } from '@roc/ui';
import { observer } from 'mobx-react';
import { BackgroundItemBaseStore } from '../stores/backgroundItemBaseStore';
import { PublicRecordItemForm } from './publicRecordItemForm';
import { getInfoButton, PUBLIC_RECORD } from './utils/backgroundUtils';
import { CustomTable } from './utils/customTable';
import { getResolutionFlag } from './utils/backgroundUtils';
import { Fragment } from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  fixedWidthText: {
    fontSize: '1rem',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'left',
    paddingLeft: '10px',
    width: '100%',
  },
}));

const EditItemModal = observer(({ store }) => {
  return (
    <EditableDialog
      open={store.publicRecordItemFormStore.open}
      dialogState={store.publicRecordItemFormStore.mode}
      handleClose={() => store.publicRecordItemFormStore.closeDialog()}
      handleAdd={() => store.publicRecordItemFormStore.save()}
      handleEdit={() => store.publicRecordItemFormStore.save()}
      title={'Item - Public Record'}
      dialogContent={
        <PublicRecordItemForm store={store.publicRecordItemFormStore} />
      }
    />
  );
});

interface PublicRecordsProps {
  store: BackgroundItemBaseStore;
  hideIfEmpty: boolean;
  openResolutionInfo?: (data?: any) => void;
}

export const PublicRecords = observer(({ store, hideIfEmpty, openResolutionInfo }: PublicRecordsProps) => {
  const classes = useStyles();
  const columns = [
    {
      name: 'Item',
      width: '35%',
    },
    {
      name: 'Item Description',
      width: '35%',
    },
    {
      name: 'Resolution Flag',
      width: '15%',
    },
    ...(store.isInternal
      ? [
        {
          name: 'ACTIONS',
          width: '15%',
        },
      ]
      : []),
  ];

  return (
    <Box style={{ paddingTop: '15px' }}>
      <EditItemModal store={store} />
      <CustomTable
        title={'Public Record'}
        columns={columns}
        onAddNewItem={() => store.publicRecordItemFormStore.openAddItem()}
        store={store}
      >
        {store.backgroundSectionDetails.backgroundSection?.publicRecords?.map((item, index) => (
          <TableRow key={index}>
            <TableCell>
              <span className={classes.fixedWidthText}>
                {item.item}
              </span>
            </TableCell>
            <TableCell>
              <span className={classes.fixedWidthText}>
                {item.itemDescription.split('\n').map((str, i) => <Fragment key={i}>{str}<br /></Fragment>)}
              </span>
            </TableCell>
            <TableCell>
              <IconButton
                onClick={() => {
                  store.updateRecordItem(index, PUBLIC_RECORD);
                }}
              >
                {getResolutionFlag(item)}
              </IconButton>
              {getInfoButton(item, () => openResolutionInfo(item))}
            </TableCell>
            {store.isInternal &&
              <TableCell>
                <IconButton
                  onClick={() => {
                    store.publicRecordItemFormStore.openEditDitem(item);
                  }}
                >
                  <EditIcon style={{ color: '#2199E0' }} />
                </IconButton>
                <IconButton
                  onClick={() => {
                    store.publicRecordItemFormStore.removeItem(item);
                  }}
                >
                  <DeleteIcon style={{ color: '#2199E0' }} />
                </IconButton>
              </TableCell>
            }
          </TableRow>
        ))}
      </CustomTable>
    </Box>
  );
});
