import { computed, flow, makeObservable, observable } from 'mobx';
import { ApiResponse, LoginService } from './../services';
import { GlobalStore } from './globalStore';
import { FormStore } from './formStore';
import { PortalName, appLocalStorage, LocalStorageKeys, isLocalHost, getPortalContextPath } from '@roc/feature-utils';
import { OAuthResponse } from '@roc/feature-types';
import { addSeconds } from 'date-fns';

const form = {
  fields: {
    username: {
      value: '',
      error: null,
      rule: 'required',
    },
    password: {
      value: '',
      error: null,
      rule: 'required',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export class LoginStore extends FormStore {
  protected globalStore: GlobalStore;
  protected loginService: LoginService;
  forgotpasswordUrl: string;
  correctPortalId: string;
  correctPortalHost: string;
  show2FALogin: boolean;
  redirectUrlAfter2FASuccessful: string;

  constructor(globalStore) {
    super({ ...form }, globalStore);
    this.globalStore = globalStore;
    this.loginService = new LoginService();
    this.show2FALogin = false;
    this.redirectUrlAfter2FASuccessful = "";
    makeObservable(this, {
      forgotpasswordUrl: observable,
      show2FALogin: observable,
      redirectUrlAfter2FASuccessful: observable,
      onFormSubmit: flow,
      correctPortalId: observable,
      correctPortalHost: observable,
      isPrivateLenderPortal: computed,
    });
  }

  setForgotpasswordUrl(url) {
    this.forgotpasswordUrl = url;
  }

  setShow2FALogin(show2FALogin) {
    this.show2FALogin = show2FALogin;
  }

  protected getLocalHostPortalUrl(contextPath: string, portalName: PortalName) {
    let port: number;
    switch (portalName) {
      case PortalName.N_PORTAL:
        port = 3000;
        break;
      case PortalName.LENDER_PORTAL:
        port = 9001;
        break;
      case PortalName.BORROWER_PORTAL:
        port = 9002;
        break;
      case PortalName.BROKER_PORTAL:
        port = 9005;
        break;
      case PortalName.CRM_PORTAL:
        port = 9017;
        break;
      default:
        throw Error(`Localhost Port not mapped for PortalName: ${portalName}`);
    }
    return `http://localhost:${port}/${contextPath}`;
  }

  protected getPortalUrl(contextPath: string, portalName: PortalName) {
    if (this.isPrivateLenderPortal) {
      if (this.correctPortalId == this.globalStore.portalConfiguration.id) {
        return `/${contextPath}`

      }
      return this.correctPortalHost + `/${contextPath}`;
    } else {
      return `/${contextPath}`
    }
  }

  protected getPortalName(portalName) {
    if (Object.values(PortalName).includes(portalName)) {
      return PortalName[portalName];
    }
    return null;
  }

  get isPrivateLenderPortal() {
    return this.globalStore.portalConfiguration.id == "28";
  }

  *onFormSubmit(successCallback?: (oauthResponse: OAuthResponse) => void) {
    this.runFormValidationWithMessage();
    if (!this.form.meta.isValid) return;
    try {
      const { username, password } = this.form.fields;
      if (this.isPrivateLenderPortal) {
        yield this.checkIsPortalValid(username.value);
      }

      let response: ApiResponse;
      if (isLocalHost()) {
        response = yield this.loginService.login(
          username.value,
          password.value,
          this.isPrivateLenderPortal ? this.correctPortalId : this.globalStore.portalConfiguration.id,
        );
      } else {
        response = yield this.loginService.loginWithHost(
          username.value,
          password.value,
          this.isPrivateLenderPortal ? this.correctPortalId : this.globalStore.portalConfiguration.id,
          this.isPrivateLenderPortal ? this.correctPortalHost : this.globalStore.portalConfiguration.host,
        );
      }

      const oauthResponse = response.data as OAuthResponse;
      const portalName: PortalName = this.getPortalName(
        oauthResponse.portal_name
      );
      if (!portalName) {
        this.globalStore.notificationStore.showErrorNotification({
          message: 'No portal mapped!',
        });
      } else if (oauthResponse.access_token) {
        let contextPath = getPortalContextPath(portalName);
        let _url = isLocalHost()
          ? this.getLocalHostPortalUrl(contextPath, portalName)
          : this.getPortalUrl(contextPath, portalName);

        if (portalName == PortalName.N_PORTAL) {
          _url = `${_url}${this.globalStore.hashParams}`
        }
        else {
          // check if the logged in user has access to redirected portal
          if (this.globalStore.redirectPath && this.globalStore.redirectPath.includes(contextPath)) {
            _url = `${_url}?redirectPath=${this.globalStore.redirectPath}`;
          }
        }

        appLocalStorage.setValue(LocalStorageKeys.userName, username.value.toLowerCase());
        appLocalStorage.setValue(LocalStorageKeys.portalContextPath, contextPath);

        successCallback && successCallback(oauthResponse);

        if (portalName == PortalName.N_PORTAL && oauthResponse.two_factor_authorized == false) {
          this.setShow2FALogin(true);
          this.redirectUrlAfter2FASuccessful = _url;
        }
        else {
          this.globalStore.notificationStore.clearAllNotifications().showSuccessNotification({
            message: 'Login Successful.',
          });
          window.location.href = _url;
        }
      } else {
        this.globalStore.notificationStore.showErrorNotification({
          message: 'Invalid username / password.',
        });
      }
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Invalid username / password.',
      });
    }
  }

  async checkIsPortalValid(username) {
    try {
      const response: ApiResponse = await this.loginService.checkCorrectPortal(username);
      let isBorrower = response.data.data.borrower;
      if (isBorrower) {
        this.globalStore.notificationStore.showErrorNotification({
          message: 'Invalid portal for borrower',
        });
        return;
      }
      this.correctPortalId = response?.data?.data?.portalId
      this.correctPortalHost = response?.data?.data?.host
      if (this.correctPortalId === undefined) {
        this.globalStore.notificationStore.showErrorNotification({
          message: 'Invalid user portal.',
        });
      }
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Invalid user portal.',
      });
    }
  }
};
