import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { observer } from "mobx-react";
import { Layout } from "@roc/feature-app-core";
import { useEffect, useState } from 'react';
import { Box, Grid, Paper, Typography } from '@material-ui/core';
import { Button, TextField, createRadioField, createSelectField, createTextField } from '@roc/ui';
import { useCreateListStore } from '../hooks/useCreateListStore';
import { FilterBuilder } from './filterBuilder';
import { insertIf } from '@roc/feature-utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(3, 0, 4),
      borderRadius: theme.spacing(2),
      boxShadow:
        'rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px',
      display: 'flex',
      justifyContent: 'center',
    },
    container: {
      width: '60%',
    },
    modalContainer: {
      width: '100%',
    },
    title: {
      paddingLeft: theme.spacing(2),
    },
    buttonContainer: {
      paddingTop: theme.spacing(4),
    },
    contentContainer: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  })
);

const currentPath = window.location.pathname;

const objectTypeOptions = [
  {
    label: 'Lead',
    value: 'Lead',
  },
  ...insertIf(
    !currentPath.includes("/backoffice"),
    [
      {
        label: 'Contact',
        value: 'Contact',
      },
    ]
  ),

];


const recordTypeLeadOptions = [
  {
    label: 'TPO',
    value: 'TPO',
  },
  {
    label: 'Borrower',
    value: 'Borrower',
  },
];

const recordTypeContactOptions = [
  {
    label: 'Lender Principal',
    value: 'Lender Principal',
  },
  {
    label: 'Borrower',
    value: 'Borrower',
  },
];

export const CreateListForm = observer(({ handleClose = () => { } }) => {
  const classes = useStyles();
  const { createListStore } = useCreateListStore();
  const { createListFormStore } = createListStore;

  async function handleSaveClick() {
    await createListStore.save();
    handleClose();
  }

  useEffect(() => {
    currentPath.includes("/backoffice") && createListFormStore.onFieldChange('objectType', 'Lead');
    createListStore.getFilterCriteriaOptions();
  }, []);

  useEffect(() => {
    createListStore.setFilterCriteriaOptions(createListStore.createListFormStore.form.fields.objectType.value)
  }, [createListStore.createListFormStore.form.fields.objectType.value, createListStore.filterCriteriaOptions]);

  return (
    <>
      <Grid item container className={classes.title}>
        <Grid item>
          <Typography variant="h6">List Name</Typography>
        </Grid>
        <Grid item container justifyContent="center" className={classes.contentContainer}>
          <Grid item xs={10}>
            {createTextField({
              label: '',
              fieldName: 'listName',
              testId: 'listName',
              store: createListStore.createListFormStore,
            })}
          </Grid>
        </Grid>
      </Grid>
      <Grid item container className={classes.title}>
        <Grid item>
          <Typography variant="h6">Object Type</Typography>
        </Grid>
        <Grid item container justifyContent="center" className={classes.contentContainer}>
          <Grid item xs={10}>
            {createRadioField({
              label: '',
              fieldName: 'objectType',
              testId: 'objectType',
              store: createListStore.createListFormStore,
              options: objectTypeOptions,
              row: true,
            })}
          </Grid>
        </Grid>
      </Grid>
      {
        createListStore.createListFormStore.form.fields.objectType.value === 'Contact' ? (
          <Grid item container className={classes.title}>
            <Grid item>
              <Typography variant="h6">Record Type</Typography>
            </Grid>
            <Grid item container justifyContent="center" className={classes.contentContainer}>
              <Grid item xs={10}>
                {createSelectField({
                  label: '',
                  fieldName: 'recordType',
                  testId: 'recordType',
                  options: recordTypeContactOptions,
                  store: createListStore.createListFormStore,
                })}
              </Grid>
            </Grid>
          </Grid>
        ) : null
      }
      {
        createListStore.createListFormStore.form.fields.objectType.value === 'Lead' ? (
          <Grid item container className={classes.title}>
            <Grid item>
              <Typography variant="h6">Record Type</Typography>
            </Grid>
            <Grid item container justifyContent="center" className={classes.contentContainer}>
              <Grid item xs={10}>
                {createSelectField({
                  label: '',
                  fieldName: 'recordType',
                  testId: 'recordType',
                  options: recordTypeLeadOptions,
                  store: createListStore.createListFormStore,
                })}
              </Grid>
            </Grid>
          </Grid>
        ) : null
      }
      {/* <Grid item container className={classes.title}>
        <Grid item>
          <Typography variant="h6">Brand</Typography>
        </Grid>
        <Grid item container justifyContent="center" className={classes.contentContainer}>
          <Grid item xs={10}>
            {createSelectField({
              label: '',
              fieldName: 'brand',
              testId: 'brand',
              options: brandOptions,
              store: createListStore.createListFormStore,
            })}
          </Grid>
        </Grid>
      </Grid> */}
      <Grid item xs={12} className={classes.contentContainer}>
        <FilterBuilder />
      </Grid>
      <Grid item container justifyContent='center' className={classes.buttonContainer}>
        <Grid item sm={8} md={6} xs={8}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleSaveClick}
            testId="next"
            disabled={!createListStore.createListFormStore.form.meta.isValid || createListStore.filters.length === 0}
          >Create</Button>
        </Grid>
      </Grid>
    </>
  )
});


export const CreateList = observer(() => {
  const classes = useStyles();

  return (
    <Layout>
      <Box my={3}>
        <Typography variant="h3" gutterBottom>
          {'Create List'}
        </Typography>
      </Box>
      <Grid container justifyContent="center">
        <Grid item sm={12} md={8}>
          <Paper className={classes.paper}>
            <Grid container justifyContent="center" alignItems='center' className={classes.container}>
              <CreateListForm />
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Layout>
  )
});

export const CreateListModal = observer(({ handleClose }) => {
  const classes = useStyles();

  return (
    <Grid container justifyContent="center" alignItems='center' className={classes.modalContainer}>
      <CreateListForm handleClose={handleClose} />
    </Grid>
  )
});