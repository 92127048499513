import {
  Box,


  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { VerticalBarLoader } from '@roc/ui';
import React, { useEffect, useRef, useState } from 'react';
import { green, orange } from '@material-ui/core/colors';
import { CheckCircle, Error, FindInPage } from '@material-ui/icons';
import { DocumentVerificationState } from '@roc/feature-types';
import { observer } from 'mobx-react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'block',
      '& iframe': {
        maxHeight: 875,
      },
    },
  })
);

const DocumentVerifiedView = () => {
  return (
    <Box mt={4} mx={'auto'} textAlign="center">
      <CheckCircle style={{ fontSize: 60, color: green[500] }} />
      <br />
      <Typography variant="h4" color="textPrimary">
        Success
      </Typography>
      <br />
      <Typography>Your document has been received for verification.</Typography>
      <br />
      <Typography>
        You can proceed to the next step for electronic signature.
      </Typography>
    </Box>
  );
};

const DocumentPendingVerificationView = () => {
  return (
    <Box mt={4} mx={'auto'} textAlign="center">
      <FindInPage style={{ fontSize: 60, color: orange[500] }} />
      <br />
      <Typography variant="h4" color="textPrimary">
        In Review
      </Typography>
      <br />
      <Typography>Your document has been received for verification.</Typography>
      <br />
      <Typography>
        You can proceed to the next step for electronic signature.
      </Typography>
    </Box>
  );
};

const DocumentFailedVerificationView = () => {
  return (
    <Box mt={4} mx={'auto'} textAlign="center">
      <Error style={{ fontSize: 60 }} color="error" />
      <br />
      <Typography variant="h4" color="textPrimary">
        Failure
      </Typography>
      <br />
      <Typography>Your document verification failed!</Typography>
      <br />
      <Typography>
        Please reach out to abc@xyz.com to restart the process.
      </Typography>
    </Box>
  );
};

export const DocumentVerification = observer(
  ({ setAllowNavigationInDocVerifyStep, store }) => {
    const classes = useStyles();
    const [showLoadingMask, setShowLoadingMask] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const trulioIframeHolderRef = useRef(null);
    const { documentVerificationState, accessTokenURL, publicKey } = store;

    const isAllowedToNavigate = () => {
      return (
        documentVerificationState === DocumentVerificationState.VERIFIED ||
        documentVerificationState === DocumentVerificationState.IN_REVIEW ||
        documentVerificationState === DocumentVerificationState.FAILED
      );
    };

    const handleResponse = e => {
      store.setExperienceId(e.experienceTransactionId);
      store.setDocumentVerificationState(
        DocumentVerificationState.VERIFIED
      );
    };

    useEffect(() => {
      if (isAllowedToNavigate()) {
        setAllowNavigationInDocVerifyStep(true);
      }
    }, [documentVerificationState]);

    useEffect(() => {
      if (documentVerificationState === DocumentVerificationState.NOT_STARTED) {
        setShowLoadingMask(true);
        const scriptTag = document.createElement('script');
        scriptTag.src = 'https://js.trulioo.com/latest/main.js';
        scriptTag.addEventListener('load', () => setLoaded(true));
        document.body.appendChild(scriptTag);
      }
    }, []);

    useEffect(() => {
      if (!loaded) return;
      new (window as any).TruliooClient({
        publicKey,
        accessTokenURL,
        handleResponse,
      });
    }, [loaded]);

    useEffect(() => {
      if (!loaded) return;
      const intervalHandler = setInterval(() => {
        const frameHeight = trulioIframeHolderRef.current.offsetHeight;
        if (frameHeight > 0) {
          setShowLoadingMask(false);
          clearInterval(intervalHandler);
        }
      }, 1000);
      return () => {
        clearInterval(intervalHandler);
      };
    }, [loaded]);

    const renderView = () => {
      switch (documentVerificationState) {
        case DocumentVerificationState.NOT_STARTED:
          return (
            <div
              className={classes.container}
              id="trulioo-embedid"
              ref={trulioIframeHolderRef}
            ></div>
          );
        case DocumentVerificationState.VERIFIED:
        case DocumentVerificationState.IN_REVIEW:
        case DocumentVerificationState.FAILED:
          return <DocumentPendingVerificationView />;
        default:
          console.error(
            'No view created for DocumentVerificationState: ',
            documentVerificationState
          );
          return null;
      }
    };

    return (
      <>
        {showLoadingMask && <VerticalBarLoader />}
        {renderView()}
      </>
    );
  }
);
