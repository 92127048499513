import { Service } from '@roc/feature-app-core';

const url = {
  GET_BASIC_USER_DETAILS: '/api/v1/user/getBasicUserDetails',
  SAVE_BASIC_USER_DETAILS: '/api/v1/user/saveBasicUserDetails'
}

export class UserProfileService extends Service {

  getBasicUserDetails() {
    return this.get(url.GET_BASIC_USER_DETAILS);
  }

  saveBasicUserDetails(basicUserDetails) {
    return this.put(url.SAVE_BASIC_USER_DETAILS, basicUserDetails);
  }
}