import { Box } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { observer } from 'mobx-react';
import { BorrowerEntityCard } from './borrowerSection/borrowerEntity/borrowerEntityCard';
import { BorrowersCard } from './borrowerSection/borrower/borrowersCard';
import { SourcesCard } from './bridgeLoanSections/sourcesCard';
import { UsesCard } from './bridgeLoanSections/usesCard';
import { useLoanSummaryStore } from '../hooks/useLoanSummaryStore';
import { mfbDealEconomicsValues, mfbLoanTermValues } from '../utils/ColumnsMfbLoanSummary';
import { borrowerEntityColsBridge } from '../utils/ColumnsBridgeLoanSummary';
import { CollateralsCard } from './collateralsSection/collateralsCard';
import { LoanTermsCard } from './loanTermsSection/loanTermsCard';
import { DealEconomicsMfb } from './loanTermsSection/dealEconomicsMfb';

export const LoanDetailsOverviewMultifamilyBridgeLoans = observer(({ loanDetails }) => {
  const { loanSummaryStore } = useLoanSummaryStore();
  return (
    <>
      <Box m={2}>
        <Grid container>
          <Grid item xs={12}>
            <LoanTermsCard loanDetails={loanDetails} columns={loanSummaryStore.transformData(mfbLoanTermValues, 6, loanDetails)} title="Loan Terms" />
          </Grid>
        </Grid>
      </Box>
      <Box m={2}>
        <Grid container>
          <Grid item xs={12}>
            <BorrowerEntityCard columns={loanSummaryStore.transformDataForBorrowerEntity(borrowerEntityColsBridge, 3, loanDetails)} />
          </Grid>
        </Grid>
      </Box>
      <Box m={2}>
        <Grid container>
          <Grid item xs={12}>
            <BorrowersCard loanDetails={loanDetails} />
          </Grid>
        </Grid>
      </Box>
      <Box m={2}>
        <Grid container>
          <Grid item xs={12}>
            <CollateralsCard loanDetails={loanDetails} />
          </Grid>
        </Grid>
      </Box>
      <Box m={2}>
        <Grid container>
          <Grid item xs={6} lg={6} style={{ paddingRight: '6px' }}>
            <SourcesCard />
          </Grid>
          <Grid item xs={6} lg={6}>
            <UsesCard />
          </Grid>
        </Grid>
      </Box>
      <Box m={2}>
        <Grid container>
          <Grid item xs={12}>
            <DealEconomicsMfb columns={loanSummaryStore.transformData(mfbDealEconomicsValues, 4, loanDetails)} title="Deal Economics" />
          </Grid>
        </Grid>
      </Box>
    </>
  );
});