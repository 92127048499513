import { PPP_3_2_1, PPP_5_4_3_2_1 } from "@roc/client-portal-shared/utils";
import { RequestedLTVOptions, LoanPurposeOptions, PPPOptions, RateTypeOptions, ForeignNationalOptions, BorrowerExpOptions, DebtYieldOptions, GeoTierOptions, DSCROptions, LoanSubtypeOptions } from "./internalPricerConstants";
import { isNil } from "@roc/client-portal-shared/utils";

export const mapNumberOfUnits = propertyType => {
  switch (propertyType) {
    case 'Single Family':
    case 'Condominium':
    case 'Townhome':
    case 'Planned Unit Development':
      return 1;
    case 'Duplex':
    case 'Mixed Use':
      return 2;
    case 'Triplex':
      return 3;
    case 'Quadruplex':
      return 4;
    case '5+ Unit Multifamily':
      return 5;
    default:
      return 1;
  }
};

export const mapLtvDecimalToRange = ltvDecimalValues => {
  if (ltvDecimalValues === RequestedLTVOptions.ONE_TO_FIVE) return "0-5";
  if (ltvDecimalValues === RequestedLTVOptions.FIVE_TO_TEN) return "6-10";
  if (ltvDecimalValues === RequestedLTVOptions.TEN_TO_FIFTEEN) return "11-15";
  if (ltvDecimalValues === RequestedLTVOptions.FIFTEEN_TO_TWENTY) return "16-20";
  if (ltvDecimalValues === RequestedLTVOptions.TWENTY_TO_TWENTY_FIVE) return "21-25";
  if (ltvDecimalValues === RequestedLTVOptions.TWENTY_FIVE_TO_THIRTY) return "26-30";
  if (ltvDecimalValues === RequestedLTVOptions.THIRTY_TO_THIRTY_FIVE) return "31-35";
  if (ltvDecimalValues === RequestedLTVOptions.THIRTY_FIVE_TO_FORTY) return "36-40";
  if (ltvDecimalValues === RequestedLTVOptions.FORTY_TO_FORTY_FIVE) return "41-45";
  if (ltvDecimalValues === RequestedLTVOptions.FORTY_FIVE_TO_FIFTY) return "46-50";
  if (ltvDecimalValues === RequestedLTVOptions.FIFTY_TO_FIFTY_FIVE) return "50-55";
  if (ltvDecimalValues === RequestedLTVOptions.FIFTY_FIVE_TO_SIXTY) return "55-60";
  if (ltvDecimalValues === RequestedLTVOptions.SIXTY_TO_SIXTY_FIVE) return "61-65";
  if (ltvDecimalValues === RequestedLTVOptions.SIXTY_FIVE_TO_SEVENTY) return "66-70";
  if (ltvDecimalValues === RequestedLTVOptions.SEVENTY_TO_SEVENTY_FIVE) return "71-75";
  if (ltvDecimalValues === RequestedLTVOptions.SEVENTY_FIVE_TO_EIGHTY) return "76-80";
  return "";
};

export const mapInvestorToName = investorId => {
  if (investorId == 22) return "Neuberger Berman";
  if (investorId == 23) return "Nomura";
  if (investorId == 26) return "Athene";
  if (investorId == 28) return "Blackstone";
  return "";
};

export const mapLoanToPricerFormValues = loan => {
  const requestedLTV =
    loan.estimatedAsIsValue && loan.amount
      ? (loan.amount / loan.estimatedAsIsValue) * 100
      : '';

  return {
    ...loan,
    requestedLTV,
    loanSubtype: mapLoanSubtype(loan.loanSubType),
  };
};

export const mapLoanSubtype = type => {
  if (type?.toUpperCase().includes('PORTFOLIO')) return LoanSubtypeOptions.PORTFOLIO;
  else return LoanSubtypeOptions.SINGLE_PROPERTY;
}

export const mapPPPMethods = ppp => {
  if (ppp === '5-4-3-2-1%') return PPP_5_4_3_2_1;
  else if (ppp === '3-2-1%') return PPP_3_2_1;
  else if (ppp === '3-0-0%') return PPPOptions.THREE_ZERO_ZERO;
  else if (ppp === '1-0-0%') return PPPOptions.ONE_ZERO_ZERO;
  else return ppp;
}

export const mapAmortization = amortType => {
  if (amortType === 'Fully Amortizing') return 'fullyAmortizing';
  else if (amortType === 'Partial Interest Only') return 'partialInterest';
  else return amortType;
}

export const mapPurpose = purpose => {
  if (purpose?.toUpperCase().includes('RATE')) return 'Rate Term Refinance';
  else if (purpose?.toUpperCase().includes('CASH')) return 'Cash-Out Refinance';
  else return purpose;
}

export const mapRateType = rate => {
  if (rate === '30 YR FRM') return RateTypeOptions.THIRTY_YEAR_FRM;
  else return rate;
}

export const mapForeignNational = foreign => {
  if (foreign) return ForeignNationalOptions.FOREIGN_NATIONAL;
  else return ForeignNationalOptions.CITIZEN;
}

export const mapDSCR = dscr => {
  if (dscr >= 1.40) return DSCROptions.OVER_ONE_POINT_FOUR;
  else if (dscr >= 1.30) return DSCROptions.ONE_POINT_THREE;
  else if (dscr >= 1.20) return DSCROptions.ONE_POINT_TWO;
  else if (dscr >= 1.15) return DSCROptions.ONE_POINT_ONE_FIVE;
  else return DSCROptions.ONE;
}

export const mapCreditScore = credit => {
  if (credit >= 780) return 780;
  else if (credit >= 760) return 760;
  else if (credit >= 740) return 740;
  else if (credit >= 720) return 720;
  else if (credit >= 700) return 700;
  else if (credit >= 680) return 680;
  else return 660;
}

export const mapUPB = amt => {
  if (amt >= 2000000) return 2000000;
  else if (amt >= 1500000) return 1500000;
  else if (amt >= 1000000) return 1000000;
  else if (amt >= 600000) return 600000;
  else if (amt >= 200000) return 200000;
  else if (amt >= 100000) return 100000;
  else return 0;
}

export const mapRecourse = recourse => {
  if (recourse === 'Full Recourse') return 'true';
  else return 'false';
}

export const mapLtv = ltv => {
  if (ltv > 0.75) return RequestedLTVOptions.SEVENTY_FIVE_TO_EIGHTY;
  else if (ltv > 0.7) return RequestedLTVOptions.SEVENTY_TO_SEVENTY_FIVE;
  else if (ltv > 0.65) return RequestedLTVOptions.SIXTY_FIVE_TO_SEVENTY;
  else if (ltv > 0.6) return RequestedLTVOptions.SIXTY_TO_SIXTY_FIVE;
  else if (ltv > 0.55) return RequestedLTVOptions.FIFTY_FIVE_TO_SIXTY;
  else if (ltv > 0.5) return RequestedLTVOptions.FIFTY_TO_FIFTY_FIVE;
  else if (ltv > 0.45) return RequestedLTVOptions.FORTY_FIVE_TO_FIFTY;
  else if (ltv > 0.4) return RequestedLTVOptions.FORTY_TO_FORTY_FIVE;
  else if (ltv > 0.35) return RequestedLTVOptions.THIRTY_FIVE_TO_FORTY;
  else if (ltv > 0.3) return RequestedLTVOptions.THIRTY_TO_THIRTY_FIVE;
  else if (ltv > 0.25) return RequestedLTVOptions.TWENTY_FIVE_TO_THIRTY;
  else if (ltv > 0.2) return RequestedLTVOptions.TWENTY_TO_TWENTY_FIVE;
  else if (ltv > 0.15) return RequestedLTVOptions.FIFTEEN_TO_TWENTY;
  else if (ltv > 0.1) return RequestedLTVOptions.TEN_TO_FIFTEEN;
  else if (ltv > 0.5) return RequestedLTVOptions.FIVE_TO_TEN;
  else return RequestedLTVOptions.ONE_TO_FIVE;
}

export const mapExperience = exp => {
  if (exp === 1) return BorrowerExpOptions.ONE;
  else if (exp === 2) return BorrowerExpOptions.TWO;
  else if (exp === 3) return BorrowerExpOptions.THREE;
  else if (exp === 4) return BorrowerExpOptions.FOUR;
  else if (exp === 5) return BorrowerExpOptions.FIVE;
  else return BorrowerExpOptions.ZERO;
}

export const mapDebtYield = dy => {
  if (dy > 9) return DebtYieldOptions.GREATER_THAN_NINE;
  else return DebtYieldOptions.LESS_EQUAL_THAN_NINE;
}

export const mapGeoTier = tier => {
  switch (tier) {
    case 'Top':
      return GeoTierOptions.TOP;
    case 'Standard':
      return GeoTierOptions.STANDARD;
    case 'Small':
      return GeoTierOptions.SMALL
    case 'Very Small':
      return GeoTierOptions.VERY_SMALL;
    default:
      return GeoTierOptions.NA;
  }
}

export const calcAsIsValue = (loanAmount, ltv) => {
  if (isNil(ltv) || ltv == 0 || isNaN(ltv)) { return 0 };
  return Number(loanAmount) / Number(ltv);
}

export const calcLoanAmount = (asIsValue, ltv) => {
  if (isNil(ltv) || isNil(asIsValue) || isNaN(ltv) || isNaN(asIsValue)) { return 0 };
  return Number(asIsValue) * Number(ltv);
}

export const calcYieldSpreadPremium = (yieldSpread, loanAmount) => {
  if (isNil(yieldSpread) || isNil(loanAmount) || isNaN(yieldSpread) || isNaN(loanAmount)) { return 0 };
  return Number(yieldSpread) * Number(loanAmount) / 100;
}

export const calcPointSplit = (points, loanAmount) => {
  if (isNil(points) || isNil(loanAmount) || isNaN(points) || isNaN(loanAmount)) { return 0 };
  return Number(points) * Number(loanAmount) / 100;
}