import { Grid, Typography } from "@material-ui/core";
import { DateFormat, formatDate, isNil } from "@roc/feature-utils";
import { createTextField, EditableDialog } from "@roc/ui";
import { observer } from "mobx-react";
import { createHtmlField } from '../leadDetails/htmlField'
import { useStore } from "@roc/client-portal-shared/hooks";


export const EditLeadNoteDialog = observer(({ editLeadNoteStore }) => {
  const { leadNote } = editLeadNoteStore;
  const { salesforceLeadStore } = useStore();

  const store = editLeadNoteStore;

  const dialogContent = (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {createTextField({
          store,
          testId: 'subject',
          fieldName: 'subject',
          label: 'Subject',
        })}
      </Grid>
      <Grid item xs={12}>
        {createHtmlField({
          store,
          testId: 'body',
          fieldName: 'body',
          label: 'Note',
          multiline: true,
          minRows: 5
        })}
      </Grid>
      {leadNote?.lastUpdatedDate && (
        <Grid item xs={12}>
          <Typography variant="subtitle2" display="block" align="right">
            Last updated at {formatDate(leadNote?.lastUpdatedDate, DateFormat.MMDDYYYY)}
          </Typography>
        </Grid>
      )}
    </Grid>
  )

  return (
    <EditableDialog
      open={!isNil(editLeadNoteStore.dialogState)}
      dialogState={editLeadNoteStore.dialogState}
      handleClose={() => editLeadNoteStore.closeLeadNoteDialog()}
      handleAdd={() => editLeadNoteStore.saveLeadNote(salesforceLeadStore.gridBusinessUnit)}
      handleEdit={() => editLeadNoteStore.saveLeadNote(salesforceLeadStore.gridBusinessUnit)}
      title={'Note'}
      dialogContent={dialogContent}
    />
  )
})
