import { GlobalStore } from "@roc/feature-app-core";
import { LeadDetailsStore } from "./leadDetails/leadDetailsStore";

export class LeadsStore {
  private globalStore: GlobalStore;

  leadDetailsStore: LeadDetailsStore;

  constructor(globalStore: GlobalStore){
    this.globalStore = globalStore;
    this.leadDetailsStore = new LeadDetailsStore(globalStore);
  }
}