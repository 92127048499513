import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { createCurrencyField } from '@roc/ui';

const useStyles = makeStyles(() => {
  return {
    container: {
      display: 'flex',
      position: 'relative',
      maxWidth: 1300,
      margin: 'auto',
    },
  };
});

export const FinanciallInformation = observer(({ store }) => {

  const { financialInformationFormStore } = store;

  const handleNetWorthFieldsChange = (name, value) => {
    financialInformationFormStore.onFieldChange(name, value);
    const netWorth = financialInformationFormStore.form.fields.assets.value - financialInformationFormStore.form.fields.liabilities.value;
    financialInformationFormStore.onFieldChange('netWorth', netWorth);
  };

  const classes = useStyles();
  return (
    <Grid container spacing={2} className={classes.container}>
      <Grid item xs={12}>
        <Typography variant="h5">Net Worth</Typography>
      </Grid>
      <Grid item xs={12} sm={4}>
        {createCurrencyField({
          label: 'Assets',
          fieldName: 'assets',
          testId: 'assets',
          store: financialInformationFormStore,
          onChange: ((name, value) => handleNetWorthFieldsChange(name, value)),
        })}
      </Grid>
      <Grid item xs={12} sm={4}>
        {createCurrencyField({
          label: 'Liabilities',
          fieldName: 'liabilities',
          testId: 'liabilities',
          store: financialInformationFormStore,
          onChange: ((name, value) => handleNetWorthFieldsChange(name, value)),
        })}
      </Grid>
      <Grid item xs={12} sm={4}>
        {createCurrencyField({
          label: 'Total Net Worth',
          fieldName: 'netWorth',
          testId: 'netWorth',
          store: financialInformationFormStore,
          disabled: true,
        })}
      </Grid>
    </Grid>
  );
});