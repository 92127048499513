import { action, makeObservable, observable } from 'mobx';

export class WorkerStore {

  showNewVersionBanner: boolean;
  isOnline: boolean;

  constructor() {
    this.showNewVersionBanner = false;
    makeObservable(this, {
      showNewVersionBanner: observable,
      isOnline: observable,
      setShowNewVersionBanner: action,
      setIsOnline: action,
    });
  }

  setShowNewVersionBanner(showNewVersionBanner) {
    this.showNewVersionBanner = showNewVersionBanner;
  }

  setIsOnline(isOnline) {
    this.isOnline = isOnline;
  }
}
