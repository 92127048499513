import { Task } from '../types/generalTasksTypes';

export const createEmptyTask = (): Task => {
  return {
    taskDetails: {},
    attributes: {},
    assignees: [],
    comments: [],
    attachments: [],
    subtasks: [],
  } as Task;
};

export const cloneObject = obj => {
  return JSON.parse(JSON.stringify(obj));
};
