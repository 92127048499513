import {
  Box,
  Chip,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { DocumentStatus, useDocumentStore } from '@roc/feature-documents';
import { useLoanStore } from '@roc/feature-loans';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { statusOptions } from '../utils/loanDetailsConstants';
import { TodoStatus } from '../utils/loanDetailsTypes';

const useStyles = makeStyles((theme: Theme) => ({
  chip: {
    width: '100%',
    maxWidth: 240,
    justifyContent: 'flex-start',
    textTransform: 'capitalize',
    fontSize: 16,
  },
  dropdownIcon: {
    color: theme.palette.text.hint,
  },
}));

export const colorByStatus = {
  [TodoStatus.ACCEPTED]: 'rgba(72, 173, 37, 1)',
  [TodoStatus.PENDING]: 'rgba(217, 68, 82, 1)',
  [TodoStatus.MORE_INFO_NEEDED]: 'rgba(217, 68, 82, 1)',
  [TodoStatus.BEING_REVIEWED]: 'rgba(221, 167, 0, 1)',
  [TodoStatus.NOT_APPLICABLE]: '#999',
};

export interface Props {
  status: string;
  onStatusChange?: (status: TodoStatus) => void;
  showDropdown?: boolean;
}

export const TodoStatusChip = observer(
  ({ status, onStatusChange, showDropdown }: Props) => {
    const { loanStore } = useLoanStore();
    const classes = useStyles({ status });

    const [anchorEl, setAnchorEl] = useState(null);

    const openMenu = event => {
      setAnchorEl(event.currentTarget);
    };

    const handleSelect = (event, option) => {
      onStatusChange(option.value);
      setAnchorEl(null);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const dropdownProps = {
      deleteIcon: (
        <Box
          component={'span'}
          display={'flex'}
          justifyContent={'end'}
          flexGrow={1}
        >
          <KeyboardArrowDownIcon className={classes.dropdownIcon} />
        </Box>
      ),
      onDelete: openMenu,
      onClick: openMenu,
    };

    return (
      <>
        <Chip
          variant="outlined"
          label={status}
          className={classes.chip}
          icon={
            <FiberManualRecordIcon style={{ color: colorByStatus[status] }} />
          }
          {...(showDropdown ? dropdownProps : {})}
        />
        <Menu
          id="status-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          {statusOptions.map((option, index) => (
            <MenuItem
              key={option.value}
              selected={option.value === status}
              onClick={event => handleSelect(event, option)}
            >
              <ListItemIcon>
                <FiberManualRecordIcon
                  style={{ color: colorByStatus[option.value] }}
                />
              </ListItemIcon>
              <Typography>{option.label}</Typography>
            </MenuItem>
          ))}
        </Menu>
      </>
    );
  }
);
