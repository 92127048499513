import { Service } from './../../../services';

/*const url = {
  ACCOUNT_DASHBOARD: '/nportal/rest/priv/getAccountDashboard',
};*/

const url = {
  ACCOUNT_DASHBOARD: '',
};

export class DashboardService extends Service {
  getAccountDashboard() {
    return this.get(url.ACCOUNT_DASHBOARD);
  }
}
