import { Grid } from '@material-ui/core';
import { StandardDialog } from '@roc/ui';
import { CreditReportExampleImage } from './creditReportExample';

export type CreditReportExampleProps = {
  open?: boolean;
  onClose?: () => void;
}

export const CreditReportExampleDialog = (
  props: CreditReportExampleProps
) => {
  const { open, onClose } = props;

  return (
    <StandardDialog
      open={open}
      title=""
      maxWidth="md"
      handleClose={onClose}
      dialogContent={<CreditReportExampleImage />}
    />
  );
};