import { Chip } from '@material-ui/core';

export const VerifiedChip = ({ isVerified }) => {
  return (<Chip
    label={isVerified ? 'Verified' : 'Not Verified'}
    style={{
      backgroundColor: isVerified ? 'green' : 'grey',
      color: 'white',
      float: 'right'

    }}
  />)
}