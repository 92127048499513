import { Grid } from '@material-ui/core';
import { observer } from 'mobx-react';
import { useBrokersStore } from '../hooks/useBrokersStore';
import {
  Email,
  FirstName,
  LastName,
  AutocompleteBrokers,
  CompanyName,
  EinNumber,
  Phone
} from './components/manageBrokerFormFields';

export const BrokerForm = observer(({ brokers, readOnly=false }) => {
  const { brokersStore } = useBrokersStore();
  const { manageBrokerFormStore: store } = brokersStore;

  const onSelectExistingBroker = (broker) => {
    if (broker) {
      store.onFieldChange('brokerId', broker.brokerId);
      store.onFieldChange('companyId', broker.companyId);
      store.onFieldChange('companyName', broker.companyName);
      store.onFieldChange('einNumber', broker.entityTaxNumber);
      store.setIsDisabled(true);
    } else {
      store.reset();
      store.setIsDisabled(false);
    }
  }
  const onChangeAutocomplete = (companyName) => {
    store.onFieldChange('companyName', companyName);
  }

  return (
    <Grid container spacing={2}>
      {
        (store.isEdit && <CompanyName store={store} />) ||
        (<AutocompleteBrokers options={brokers}
          onChangeAutocomplete={onChangeAutocomplete}
          onSelectExistingBroker={onSelectExistingBroker}
          existingBroker={brokersStore.brokerFormStore.form}
        />
        )
      }
      <EinNumber store={store} disabled={readOnly} />
      <FirstName store={store} disabled={readOnly} />
      <LastName store={store} disabled={readOnly} />
      <Email store={store} disabled={readOnly} />
      <Phone store={store} disabled={readOnly} />
    </Grid>
  );
});
