import { IdVerificationForm } from './idVerificationForm';

export interface FormInfo {
  isTokenValid: boolean;
  showDocusign: boolean;
  embeddedDocusignLink: string;
  status: FormStatus;
  runCredit: boolean;
  requestState: string;
  savedForm: IdVerificationForm;
  borrowerId: number;
  showExperienceStep: boolean;
  isDriverLicenseFileUploaded: boolean;
  invalidSSN: Array<string>;
  borrowerSetupIdVerificationEnabled: boolean;
}

export enum FormStatus {
  PENDING = 'PENDING',
  UNSIGNED = 'UNSIGNED',
  FINISHED = 'FINISHED',
}