import { observer } from "mobx-react";
import { Button, StandardDialog } from '@roc/ui';
import { useState, useEffect } from 'react';
import { Box, Stepper, Step, StepLabel, StepContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { createRadioGroupField } from 'libs/client-portal-shared/src/app/components/formComponents/common/componentBuilder';
import { FieldContainer } from "@roc/client-portal-shared/components";


const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
}));

export const TamariskPreferencesModal = observer(({ open, setEditTamariskPreferences, companyProfileStore }) => {
  const { lenderDetailsStore } = companyProfileStore;
  const { lenderDetailsFormStore } = lenderDetailsStore;
  const [activeStep, setActiveStep] = useState(0);
  const [visibleSteps, setVisibleSteps] = useState([0]);
  const [allStepsAdded, setAllStepsAdded] = useState(false);
  const classes = useStyles();


  const steps = [
    {
      label: 'Order appraisals only via Tamarisk?',
      field: createRadioGroupField({
        store: lenderDetailsFormStore,
        fieldName: 'tamariskAppraisalModal',
        label: null,
        boolean: true
      }),
    },
    {
      label: 'Default option to order appraisal with Tamarisk?',
      field: createRadioGroupField({
        store: lenderDetailsFormStore,
        fieldName: 'tamariskDefaultOptionModal',
        label: null,
        boolean: true
      }),
    },
    {
      label: 'Can portal send payment email to borrower?',
      field: createRadioGroupField({
        store: lenderDetailsFormStore,
        fieldName: 'tamariskSendInvoiceEmailModal',
        label: null,
        boolean: true
      }),
    },
  ];


  useEffect(() => {
    settingSteps();
  }, [lenderDetailsFormStore.form.fields.tamariskAppraisalModal.value, open]);

  const settingSteps = () => {
    const appraisalValue = lenderDetailsFormStore.form.fields.tamariskAppraisalModal.value;

    if (appraisalValue) {
      lenderDetailsFormStore.onFieldChange('tamariskDefaultOptionModal', true);
      setVisibleSteps([0, 2]);
    } else {
      setVisibleSteps([0, 1, 2]);
    }
    setAllStepsAdded(true);
  }


  const handleClose = () => {
    handleReset();
    setEditTamariskPreferences(false);
  };

  const handleNext = () => {
    if (!checkIfCanSave()) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const checkIfCanSave = () => {
    if (activeStep === visibleSteps.length - 1 && allStepsAdded) {
      handleSave();
      handleReset();
      handleClose();
      return true;
    }
    return false;
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    setVisibleSteps([0]);
    setAllStepsAdded(false);
  };

  const handleSave = () => {
    lenderDetailsStore.saveTamariskInformation();
  };

  return (
    <StandardDialog
      open={open}
      title={"Tamarisk Preferences"}
      maxWidth="sm"
      handleClose={handleClose}
      dialogContent={
        <div className={classes.root}>
          <Stepper activeStep={activeStep} orientation="vertical">
            {visibleSteps.map((stepIndex) => (
              <Step key={steps[stepIndex].label}>
                <StepLabel>{steps[stepIndex].label}</StepLabel>
                <StepContent>
                  <FieldContainer>
                    <Box>{steps[stepIndex].field}</Box>
                    <Box sx={{ mb: 1 }}>
                      {stepIndex > 0 && (
                        <Button
                          disabled={activeStep === 0}
                          onClick={handleBack}
                          testId={`back-${activeStep + 1}`}
                          className={classes.button}
                        >
                          Back
                        </Button>
                      )}
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={handleNext}
                        testId="next"
                        className={classes.button}
                      >
                        {activeStep === visibleSteps.length - 1 && allStepsAdded ? 'Finish' : 'Next'}
                      </Button>
                    </Box>
                  </FieldContainer>
                </StepContent>
              </Step>
            ))}
          </Stepper>
        </div>
      }
    />
  );
});
