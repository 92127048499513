import React from 'react';
import { isNotBlank } from '@roc/feature-utils';
import {
  AutocompleteAddressField,
  TextField,
  GoogleMap,
  NumberFormat,
} from '@roc/ui';
import { observer } from 'mobx-react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';

const useAddressStyles = makeStyles((theme: Theme) => ({
  autocompleteContainer: {
    marginBottom: theme.spacing(2),
  },
  mapStyles: {
    position: 'relative',
    width: '100%',
    height: '150px',
  },
}));

export const CellPhone = observer(
  ({
    store,
    disabled,
    required,
  }: {
    store: any;
    disabled?: boolean;
    required?: boolean;
  }) => {
    const formFields = store.form.fields;

    return (
      <NumberFormat
        testId="cellPhone"
        customInput={TextField}
        format="###-###-####"
        mask="_"
        variant="outlined"
        standaloneLabel
        label="Cell Phone"
        value={formFields.cellPhone.value}
        onChange={e => store.onFieldChange('cellPhone', e.target.value)}
        error={isNotBlank(formFields.cellPhone.error)}
        helperText={formFields.cellPhone.error}
        fullWidth
        required={required}
        disabled={disabled}
      />
    );
  }
);

export const EmailAddress = observer(
  ({
    store,
    disabled,
    required,
  }: {
    store: any;
    disabled?: boolean;
    required?: boolean;
  }) => {
    const formFields = store.form.fields;

    return (
      <TextField
        testId="email"
        disabled={disabled}
        variant="outlined"
        standaloneLabel
        label="Email Address"
        value={formFields.emailAddress.value}
        onChange={e => store.onFieldChange('emailAddress', e.target.value)}
        error={isNotBlank(formFields.emailAddress.error)}
        helperText={formFields.emailAddress.error}
        fullWidth
        required={required}
      />
    );
  }
);

export const Address = observer(({ store, name = "address", ...rest }) => {
  const classes = useAddressStyles();
  const formFields = store.form.fields;

  const handleAddressChange = (event, name) => {
    console.log(store)
    console.log(formFields)
    const { value, fullInfo } = event?.target || {};
    const { address_components, geometry } = fullInfo || {};
    store.saveAddressField(value, address_components, geometry);
  };

  return (
    <>
      <Box className={classes.autocompleteContainer}>
        <AutocompleteAddressField
          value={formFields.address.value}
          name={name}
          label="Address"
          error={isNotBlank(formFields.address.error)}
          errorText={formFields.address.error}
          onChange={event => handleAddressChange(event, 'address')}
          fullWidth
          testId="address"
          {...rest}
        />
      </Box>
      {formFields.latitude.value && formFields.longitude.value ? (
        <GoogleMap
          mapStyles={classes.mapStyles}
          latitude={formFields.latitude.value}
          longitude={formFields.longitude.value}
        />
      ) : null}
    </>
  );
});
