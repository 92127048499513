import { Service } from "@roc/feature-app-core";

const url = {
  FETCH_LOAN_INFO:
    '/nportal/rest/pub/payments/getLoanInfoForTamariskPayment?loanId=',
  FETCH_STANDALONE_INFO:
    '/nportal/rest/pub/payments/getStandaloneInfoForTamariskPayment?propertyAppraisalId=',
  FETCH_LOAN_FASTRACK_INFO:
    '/nportal/rest/pub/payments/getInfoForLoanFastrackPayment?loanId=',
  FETCH_BORROWER_INFO:
    '/nportal/rest/pub/payments/getCardholderInfo?id=',
  FETCH_FAST_TRACK_FEES:
    '/nportal/rest/priv/payments/fastTrackFees',
};

export class LoanFastrackPaymentsService extends Service {
  getLoanInfoForTamariskPayment(loanId: string, borrowerName: string) {
    return this.get(`${url.FETCH_LOAN_INFO}${loanId}&borrowerName=${borrowerName}`);
  }

  getLoanInfoForFastrack(loanId: string, borrowerName: string) {
    return this.get(`${url.FETCH_LOAN_FASTRACK_INFO}${loanId}&borrowerName=${borrowerName}`);
  }

  getStandaloneInfoForTamariskPayment(propertyAppraisalId: string, borrowerName: string) {
    return this.get(`${url.FETCH_STANDALONE_INFO}${propertyAppraisalId}&borrowerName=${borrowerName}`);
  }

  getCardholderInfo(id: string, type: string) {
    return this.get(`${url.FETCH_BORROWER_INFO}${id}&type=${type}`);
  }

  getFastTrackFees() {
    return this.get(url.FETCH_FAST_TRACK_FEES);
  }
}
