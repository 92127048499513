import { Box, Grid, List, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { BorrowerPortalCard, getGridCardStyles } from '@roc/feature-borrower-portal-core';
import { isFundedLoan, isAgreedLoan } from '@roc/feature-utils';
import { StatusChip, StatusType } from '@roc/ui';
import { formatCurrency, formatPercentage } from '@roc/ui/utils';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import { useBorrowerLoansStore } from '../../../hooks/useBorrowerLoansStore';
import {
  Dividers,
  EntityAndBorrowersCard,
  formatDigit,
  listItem
} from './borrowerLoanOverviewComponents';
import { BorrowerTermPropertiesGrid } from './borrowerTermPropertiesGrid';
import { LoanDetailsStepper, stepsByLoanStatus } from '@roc/feature-loan-details';

const useStyles = makeStyles(theme => ({
  paper: {
    marginBottom: theme.spacing(2),
  },
  showPropertiesLink: {
    color: theme.palette.primary.main,
    textDecoration: 'underline',
    fontSize: '16px',
  },
  height100Percent: {
    height: '100%'
  }
}));

export const BorrowerLoanOverviewTermLoans = observer(() => {
  const { borrowerLoansStore } = useBorrowerLoansStore();
  const { loanDetails } = borrowerLoansStore;
  const isFundedOrPaidoffLoan = isFundedLoan(loanDetails.status);
  
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <LoanDetailsStepper
            stepsByStatus={stepsByLoanStatus}
            currentStatus={loanDetails?.status} />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          {isFundedOrPaidoffLoan ? (
            <LoanTermsFundedCard loanDetails={loanDetails} />
          ) : (
            isAgreedLoan(loanDetails.status) ? 
            <LoanTermsAgreedCard loanDetails={loanDetails} /> :
            <LoanTermsNotFundedCard loanDetails={loanDetails} />
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          <GeneralStatsCard loanDetails={loanDetails} />
        </Grid>
      </Grid>
      <Box mt={1}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <EntityAndBorrowersCard entity={loanDetails?.borrowerEntity} borrowers={loanDetails?.borrowers} />
          </Grid>
          <Grid item xs={12} md={6}>
            <PropertiesCardGrid loanDetails={loanDetails} />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
});

const LoanTermsFundedCard = ({ loanDetails }) => {
  const classes = useStyles();
  return (
    <BorrowerPortalCard cardTitle="Loan Terms" cardRootClassName={classes.height100Percent} noPaddingCardContent>
      <List>
        <Dividers variant="fullWidth" component="li">
          {listItem('Loan Type', loanDetails?.loanType)}
          {listItem('Loan Subtype', loanDetails?.loanSubType)}
          {listItem(
            'Status',
            <StatusChip
              statusType={StatusType.LOAN}
              label={loanDetails?.status}
              variant="filled"
            />
          )}
          {listItem('Loan Amount', formatCurrency(loanDetails?.amount))}
          {listItem('Loan Purpose', loanDetails?.loanPurpose)}

          {listItem(
            'Monthly P&I Payment',
            formatCurrency(loanDetails?.interestPayment ?? 0)
          )}
          {listItem('Loan Rate-Type', loanDetails?.rateType)}
          {listItem('Amortization', loanDetails?.amortization)}
          {listItem(
            'Int Only Periods(Months)',
            loanDetails?.interestOnlyMonths
          )}
          {listItem('Loan Guaranty', loanDetails?.recourseStructure)}
          {listItem(
            'Note Interest Rate',
            formatPercentage(loanDetails?.rate ?? 0)
          )}
          {listItem('Original Loan Terms (Months)', loanDetails?.duration)}
          {listItem(
            'Orig. Amortization Terms (Months)',
            loanDetails?.originalAmortizationTerms
          )}
          {listItem(<>
            <Typography>Servicer</Typography>
            <Typography variant='body2' color='textSecondary'>
              {`Servicer Id: ${loanDetails?.servicerId}`}
            </Typography>
          </>, loanDetails?.servicer)}
        </Dividers>
      </List>
    </BorrowerPortalCard>
  );
};

const LoanTermsAgreedCard = ({ loanDetails }) => {
  const classes = useStyles();
  return (
    <BorrowerPortalCard cardTitle="Loan Terms" cardRootClassName={classes.height100Percent} noPaddingCardContent>
      <List>
        <Dividers variant="fullWidth" component="li">
          {listItem('Loan Type', loanDetails?.loanType)}
          {listItem('Loan Subtype', loanDetails?.loanSubType)}
          {listItem('Loan Rate-Type', loanDetails?.rateType)}
          {listItem(
            'Note Interest Rate',
            formatPercentage(loanDetails?.rate ?? 0)
          )}
          {listItem('Amortization', loanDetails?.amortization)}
          {listItem(
            'Int Only Periods(Months)',
            loanDetails?.interestOnlyMonths
          )}
          {listItem('Loan Guaranty', loanDetails?.recourseStructure)}
          {listItem('Original Loan Terms (Months)', loanDetails?.duration)}
          {listItem(
            'Orig. Amortization Terms (Months)',
            loanDetails?.originalAmortizationTerms
          )}
          {listItem(
            'Monthly P&I Payment',
            formatCurrency(loanDetails?.interestPayment ?? 0)
          )}
        </Dividers>
      </List>
    </BorrowerPortalCard>
  );
};

const LoanTermsNotFundedCard = ({ loanDetails }) => {
  const classes = useStyles();
  return (
    <BorrowerPortalCard cardTitle="Loan Terms" cardRootClassName={classes.height100Percent} noPaddingCardContent>
      <List>
        <Dividers variant="fullWidth" component="li">
          {listItem('Loan Type', loanDetails?.loanType)}
          {listItem('Loan Subtype', loanDetails?.loanSubType)}
          {listItem('Loan Rate-Type', loanDetails?.rateType)}
          {listItem('Amortization', loanDetails?.amortization)}
          {listItem(
            'Int Only Periods(Months)',
            loanDetails?.interestOnlyMonths
          )}
          {listItem('Loan Guaranty', loanDetails?.recourseStructure)}
          {listItem('Original Loan Terms (Months)', loanDetails?.duration)}
          {listItem(
            'Orig. Amortization Terms (Months)',
            loanDetails?.originalAmortizationTerms
          )}
        </Dividers>
      </List>
    </BorrowerPortalCard>
  );
};

const GeneralStatsCard = ({ loanDetails }) => {
  const classes = useStyles();
  return (
    <BorrowerPortalCard cardTitle="General Stats" cardRootClassName={classes.height100Percent} noPaddingCardContent>
      <List>
        <Dividers variant="fullWidth" component="li">
          {listItem('Property Count', loanDetails?.propertyCount)}
          {listItem(
            'Most Recent Valuation',
            formatCurrency(loanDetails?.mostRecentValuation)
          )}
          {listItem(
            'Total Cost Basis',
            formatCurrency(loanDetails?.totalCostBasis)
          )}
          {listItem(
            'Total Debt Payoff Amount',
            formatCurrency(loanDetails?.totalDebtPayoffAmount)
          )}
          {listItem(
            'Annual Gross Rent',
            formatCurrency(loanDetails?.annualGrossRent)
          )}
          {listItem('PITIA', formatCurrency(loanDetails?.pitia))}
          {listItem('NOI', formatCurrency(loanDetails?.noi))}
          {listItem('Net Cash Flow', formatCurrency(loanDetails?.netCashFlow))}
          {listItem('NCF DSCR', formatDigit(loanDetails?.netDSCR, 3))}
          {listItem('PITIA DSCR', formatDigit(loanDetails?.pitiaDSCR, 3))}
        </Dividers>
      </List>
    </BorrowerPortalCard>
  );
};

export const PropertiesCardGrid = ({ loanDetails }) => {
  const classes = useStyles();
  const gridClasses = getGridCardStyles();

  return (
    <BorrowerPortalCard
      cardTitle="Properties"
      cardRootClassName={clsx(classes.height100Percent)}
      cardContentClassName={clsx(gridClasses.cardContent)}
      noPaddingCardContent>
      <BorrowerTermPropertiesGrid />
    </BorrowerPortalCard>
  );
};