import { observer } from 'mobx-react';
import React, { ReactNode, useEffect, useState } from 'react';
import { AgGridColumnProps, DataGrid } from '@roc/ui';
// import { useRoutes } from '../../hooks/useRoutes';
import { useStore } from '@roc/client-portal-shared/hooks';
// import { CompanyProfileCRM } from '../companyProfile/companyProfileCRM';
import { Button, Grid } from '@material-ui/core';
import { Layout } from '@roc/feature-app-core';


const noOpComparator = () => 0;

const columns: AgGridColumnProps[] = [
  {
    field: 'companyId',
    headerName: 'Name',
    minWidth: 180,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    hide: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'lenderName',
    headerName: 'Company',
    minWidth: 260,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
    cellRenderer: 'lenderNameCellRenderer',
  },
  {
    field: 'primaryRelationshipManagerEmail',
    headerName: 'Lead Source',
    minWidth: 260,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'secondaryRelationshipManagerEmail',
    headerName: 'Lead Status',
    minWidth: 260,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'status',
    headerName: 'Owner',
    minWidth: 260,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
];

export const LeadListViewGrid = observer(({ toolbar }: { toolbar: ReactNode }) => {
  const { salesforceLenderStore, companyProfileStore, companyDropdownStore } = useStore();

  useEffect(() => {
  }, [companyProfileStore.displayCompanyProfile]);

  const onSortChanged = params => {
    const sortModel = params.api.getSortModel();
    salesforceLenderStore.gridStore.setSortModel(sortModel);
  };

  const onFilterChanged = params => {
    const filterModel = params.api.getFilterModel();
    salesforceLenderStore.gridStore.setFilterModel(filterModel);
  };

  const lenderNameCellRenderer = params => {
    const lenderName = params.value;
    return (
      <Button onClick={() => handleOnclick(params)}>
        {lenderName}
      </Button>
    );
  }

  const handleOnclick = async (params) => {
    await companyDropdownStore.changeCompany(params.data?.companyId);
    companyProfileStore.setDisplayCompanyProfile(true);
  }

  const frameworkComponents = {
    lenderNameCellRenderer: lenderNameCellRenderer,
  };

  return (
    <Layout>
      {/* {companyProfileStore.displayCompanyProfile ? (
        <>
          <CompanyProfileCRM />
        </>
      ) : ( */}
      <DataGrid
        suppressRowClickSelection={false}
        frameworkComponents={frameworkComponents}
        toolbar={toolbar}
        columns={columns}
        rows={salesforceLenderStore.gridStore.gridData.data.rows}
        onSortChanged={onSortChanged}
        onFilterChanged={onFilterChanged}
        isLoading={salesforceLenderStore.gridStore.isLoading}
        showFilters={salesforceLenderStore.gridStore.showFilters}
        sortModel={salesforceLenderStore.gridStore.sortModel}
        filterModel={salesforceLenderStore.gridStore.filterModel}
        paginationData={salesforceLenderStore.gridStore.gridData.meta}
        setPageNumber={salesforceLenderStore.gridStore.setPageNumber}
        rowHeight={45}
      />
      {/* )} */}
    </Layout>
  );
});
