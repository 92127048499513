import React, { useEffect } from 'react';
import {
  Grid as MuiGrid,
  Typography,
  Box,

} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import {
  DataGrid as Grid,
  GridColumnProps,
  TextColumn,
  SelectFilterComponent,
  SelectFloatingFilterComponent,
  FilterButton,
  SelectMode,
} from '@roc/ui';
import { Toolbar } from '@roc/ui';
import { GridStore } from '@roc/feature-app-core';
import { observer } from 'mobx-react';

const filterColumn = {
  filter: 'agTextColumnFilter',
  floatingFilter: true,
  floatingFilterComponentParams: {
    suppressFilterButton: true,
  },
};

const columns: GridColumnProps[] = [
  {
    field: 'scopeOfWorkId',
    headerName: 'Id',
    cellRenderer: 'scopeOfWorkIdCellRenderer',
    ...filterColumn,
  },
  {
    field: 'address',
    headerName: 'Property Address',
    minWidth: 300,
    ...filterColumn,
  },
  {
    field: 'creationDate',
    headerName: 'Creation Date',
    ...filterColumn,
  },
];

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    marginBottom: theme.spacing(3),
    fontSize: 26,
  },
}));

export type SelectScopeOfWorkV2GridProps = {
  onScopeOfWorkSelected: (scopeOfWork) => void;
  onPageChange: () => void;
  store: GridStore;
};

export const SelectScopeOfWorkV2Grid = observer(props => {
  const { onScopeOfWorkSelected, onPageChange, store } = props;

  const classes = useStyles();

  const frameworkComponents = {
    selectFilter: SelectFilterComponent,
    selectFloatingFilter: SelectFloatingFilterComponent,
    scopeOfWorkIdCellRenderer: params => TextColumn(params),
  };

  const handleToggleFilters = () => {
    store.toggleFilters();
  };

  const handleClearFilters = () => {
    store.resetFilters();
  };

  const onFilterChanged = params => {
    const filterModel = params.api.getFilterModel();
    store.setFilterModel(filterModel);
  };

  const onSortChanged = params => {
    const sortModel = params.api.getSortModel();
    store.setSortModel(sortModel);
  };

  const onRowSelected = e => {
    const { node } = e;
    const { data } = node;
    onScopeOfWorkSelected(data, node.isSelected());
  };

  useEffect(() => {
    store.resetAndFetchGridData();
  }, [store]);

  return (
    <MuiGrid container>
      <MuiGrid item xs={12}>
        <Typography className={classes.title} color="primary">
          Existing Scopes of Work
        </Typography>
      </MuiGrid>
      <MuiGrid item xs={12}>
        <Grid
          toolbar={
            <Toolbar>
              <Box>
                <FilterButton
                  onToggleFilters={handleToggleFilters}
                  onClearFilters={handleClearFilters}
                />
              </Box>
            </Toolbar>
          }
          columns={columns}
          rows={store.gridData.data.rows}
          frameworkComponents={frameworkComponents}
          onSortChanged={onSortChanged}
          onFilterChanged={onFilterChanged}
          isLoading={store.isLoading}
          showFilters={store.showFilters}
          sortModel={store.sortModel}
          filterModel={store.filterModel}
          selectMode={SelectMode.SINGLE}
          onRowSelected={onRowSelected}
          domLayout="autoHeight"
          paginationData={store.gridData.meta}
          setPageNumber={(page) => {
            store.setPageNumber(page);
            onPageChange();
          }}
        />
      </MuiGrid>
    </MuiGrid>
  );
});

export default SelectScopeOfWorkV2Grid;
