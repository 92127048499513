import { useBaseStore } from '@roc/feature-app-core';
import { getDocumentLibraryRoutes, getDocumentLibraryRoutesConfig } from './../routes/documentLibraryRoutes';

export const useDocumentLibraryRoutes = () => {
  const { globalStore } = useBaseStore();
  const { routeBasePath, routeBaseUrl } = globalStore;

  return {
    documentLibraryRoutes: getDocumentLibraryRoutes(routeBasePath, routeBaseUrl),
    documentLibraryRoutesConfig: getDocumentLibraryRoutesConfig(routeBasePath, routeBaseUrl),
  };
};