import {
  FormControl,
  InputBase,
  InputBaseComponentProps,
  InputBaseProps,
  InputProps,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { isNotBlank } from '@roc/feature-utils';
import { FieldLabel, TextFieldProps } from '@roc/ui';
import { EditorState, ContentState, Modifier } from 'draft-js';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { convertToRaw, convertFromHTML } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const toolbarHeight = '64px';
const textEditorHeight = '300px';

const toolbar = {
  options: ['inline', 'blockType', 'list'],
  list: {
    inDropdown: false,
    options: ['unordered', 'ordered'],
  },
  blockType: {
    inDropdown: false,
    options: ['Blockquote', 'Code'],
  },
};

const useStyles = makeStyles(theme => ({
  wrapperClassName: {
    width: '100%',
    '& .rdw-editor-toolbar': {
      border:0,
      padding: 0
    },
    '& .rdw-option-wrapper': {
      padding: '14px 6px',
    },
  },
  toolbarClassName: {
    margin: 0,
  },
  editorClassName: {
    minHeight: '300px'
  },
}));

export type HtmlFieldProps = TextFieldProps & {
  standaloneLabel?: boolean;
  subLabel?: string;
  testId: string;
  tooltip?: string;
};

const convertToHtml = editorState => draftToHtml(convertToRaw(editorState.getCurrentContent()))

const getEditorStateFromHTML = html => {
  const blocksFromHTML = convertFromHTML(html);
  const contentState = ContentState.createFromBlockArray(
    blocksFromHTML.contentBlocks,
    blocksFromHTML.entityMap,
  );
  return EditorState.createWithContent(contentState);
}

export const HtmlField = ({
  standaloneLabel,
  subLabel,
  tooltip,
  ...muiComponentProps
}: HtmlFieldProps) => {
  const [currentValue, setCurrentValue] = useState('');
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const classes = useStyles();

  useEffect(() => {
    if(muiComponentProps.value != currentValue){
      const html = muiComponentProps.value as string;
      const newEditorState = getEditorStateFromHTML(html);
      setEditorState(newEditorState);
      setCurrentValue(html);
    }
    //
  },[muiComponentProps.value])

  const handleFiles = files => {
    //  uploadFiles(files);
  };

  const handlePastedText = () => {
    const currentContent = editorState.getCurrentContent();
    const currentSelection = editorState.getSelection();
    const newContent = Modifier.applyInlineStyle(
      currentContent,
      currentSelection,
      'background-color: inherit;'
    );
    setEditorState(
      EditorState.push(editorState, newContent, 'change-inline-style')
    );
  };

  const inputComponent = (props: InputBaseComponentProps) => {
    return (
      <Editor
        toolbar={toolbar}
        handlePastedText={handlePastedText}
        handlePastedFiles={handleFiles}
        handleDroppedFiles={(data, files) => handleFiles(files)}
        editorState={editorState}
        wrapperClassName={classes.wrapperClassName}
        toolbarClassName={classes.toolbarClassName}
        editorClassName={classes.editorClassName}
        onEditorStateChange={(editorState) => {
          const html = convertToHtml(editorState);
          props.onChange(html);
          setEditorState(editorState);
          setCurrentValue(html)
        }}
        onBlur={props.onBlur}
      />
    );
  }

  return (
    <>
      {standaloneLabel && muiComponentProps.label ? (
        <FieldLabel tooltip={tooltip}>
          {muiComponentProps.label}
          {muiComponentProps.required && ' *'}
        </FieldLabel>
      ) : null}
      {subLabel && (
        <FieldLabel style={{ fontSize: '.8rem', color: '#aaa' }}>
          {subLabel}
        </FieldLabel>
      )}
      <div style={{ cursor: 'text' }}>
        <TextField
          multiline={true}
          minRows={5}
          {...muiComponentProps}
          label={standaloneLabel ? undefined : muiComponentProps.label}
          value={currentValue}
          InputProps={{
            inputComponent,
          }}
        />
      </div>
    </>
  );
};

type CreateHtmlFieldProps = Omit<HtmlFieldProps, 'value'> & {
  variant?: 'outlined' | 'standard';
  type?: 'text' | 'number' | 'email' | 'password';
  label: string;
  subLabel?: string;
  fieldName: string;
  testId: string;
  store: any;
  multiline?: boolean;
  disabled?: boolean;
  standaloneLabel?: boolean;
  tooltip?: string;
  placeholder?: string;
  minRows?: number;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};

export const createHtmlField = ({
  subLabel,
  variant = 'outlined',
  type = 'text',
  label,
  fieldName,
  testId,
  store,
  multiline,
  disabled,
  standaloneLabel = true,
  tooltip,
  placeholder,
  inputProps,
  InputProps,
  minRows,
  onKeyDown = () => {},
  onClick = () => {},
}: CreateHtmlFieldProps) => {
  const formFields = store.form.fields;
  const field = formFields[fieldName];
  const required = field.rule?.indexOf('required') > -1;

  return (
    <HtmlField
      type={type}
      testId={testId}
      disabled={disabled}
      variant={variant}
      standaloneLabel={standaloneLabel}
      label={label}
      subLabel={subLabel}
      value={field.value}
      onChange={value => store.onFieldChange(fieldName, value)}
      error={isNotBlank(field.error)}
      helperText={field.error}
      fullWidth
      multiline={multiline}
      required={required}
      tooltip={tooltip}
      placeholder={placeholder}
      onKeyDown={onKeyDown}
      onClick={onClick}
      inputProps={inputProps}
      InputProps={InputProps}
      minRows={minRows}
    />
  );
};
