import { autorun } from 'mobx';
import { observer } from 'mobx-react';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useBaseStore } from '../../hooks';

export const Notification = observer(() => {
  const { globalStore } = useBaseStore();
  const { notificationStore } = globalStore;
  const { notifications, dismissAllNotificationsFlag } = notificationStore;
  const [displayed, setDisplayed] = useState([]);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const storeDisplayed = (key: number | string) => {
    setDisplayed([...displayed, key]);
  };

  useEffect(() => {
    if (dismissAllNotificationsFlag) {
      notificationStore.setDismissAllNotificationsFlag(false);
      autorun(() => {
        closeSnackbar();
      });
    }
  }, [dismissAllNotificationsFlag]);

  useEffect(() => {
    autorun(() => {
      notifications.forEach(({ message, options }) => {
        // Do nothing if snackbar is already displayed
        if (displayed.includes(options.key)) return;
        // Display snackbar using notistack
        enqueueSnackbar(message, options);
        // Keep track of snackbars that we've displayed
        storeDisplayed(options.key);
        // Dispatch action to remove snackbar from mobx store
        globalStore.notificationStore.hideNotification(options.key);
      });
    });
  }, [notifications]);

  return <></>;
});
