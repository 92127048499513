import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { Layout } from '@roc/client-portal-shared/components';
import { Paper } from '@roc/ui';
import { useLocation } from 'react-router';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainContainer: {
      backgroundColor: '#FFFFFF',
      height: '100%',
      overflowX: 'auto',
      overflowY: 'hidden',
    },
    paper: {
      padding: theme.spacing(3, 0, 4),
      borderRadius: theme.spacing(2),
      boxShadow:
        'rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px',
    },
  })
);

export const LoanFastrackDocumentDocusignCallback: React.FC = () => {
  const [signingComplete, setSigningComplete] = useState(false);
  const location = useLocation();
  const classes = useStyles();

  useEffect(() => {
    const { search } = location || {};

    if (search) {
      const { origin } = window.parent.location;
      const parsed = search.replace('?', '').split('=');

      if (parsed[1] === 'signing_complete') {
        setSigningComplete(true);
        window.parent.postMessage(
          'loan-fast-track-document-docusign-done',
          origin
        );
      } else {
        window.parent.postMessage(
          'loan-fast-track-document-docusign-incomplete',
          origin
        );
      }
    }
  }, []);

  return (
    <Grid className={classes.mainContainer}>
      <Layout>
        <Paper className={classes.paper}>
          <Box mt={3} px={4}>
            <Grid container direction="column" justifyContent="center">
              <Box textAlign="center">
                <Typography variant="h4">
                  {signingComplete
                    ? 'Document signed successfully!'
                    : 'Something went wrong'}
                </Typography>
              </Box>
              <Box mt={6} textAlign="center">
                <Typography variant="body1">
                  {signingComplete
                    ? 'Thank you for completing the document sign, please close the dialog to proceed the next step.'
                    : 'Document sign could not be completed. Please reload the page and try again.'}
                </Typography>
              </Box>
            </Grid>
          </Box>
        </Paper>
      </Layout>
    </Grid>
  );
};

export default LoanFastrackDocumentDocusignCallback;
