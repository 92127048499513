import { observer } from 'mobx-react';
import { ReactNode, useEffect, useState } from 'react';
import { AgGridColumnProps, DataGrid, LinkColumn, Paper, SubmitMenuItem } from '@roc/ui';
import { useStore } from '@roc/client-portal-shared/hooks';
import { Button, IconButton, ListItemIcon, ListItemText, Menu } from '@material-ui/core';
import { Layout } from '@roc/feature-app-core';
import { useHistory } from 'react-router';
import { SelectMode } from 'libs/client-portal-shared/src/app/components/grid';
import { useCRMPortalStore } from 'apps/crm-portal/src/app/hooks/useCRMPortalStore';
import { useLeadDetailsRoutes } from '../../../routes/loanRoutes';
import { ImportExport, MoreVert } from '@material-ui/icons';
import { ConvertLeadToLenderModal } from '../../leadsDashboard/convertLeadToLenderModal';


const noOpComparator = () => 0;

const columns = (recordType): AgGridColumnProps[] => {
  let cols = [
    {
      field: 'id',
      headerName: 'id',
      minWidth: 180,
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      hide: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    {
      field: 'ownerEmailC',
      headerName: 'Owner Email',
      minWidth: 180,
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      hide: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    {
      field: 'firstName',
      headerName: 'First Name',
      minWidth: 260,
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      cellRenderer: 'firstNameCellRenderer',
    },
    {
      field: 'lastName',
      headerName: 'Last Name',
      minWidth: 260,
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      cellRenderer: 'lastNameCellRenderer',
    },
    {
      field: 'company',
      headerName: 'Company',
      minWidth: 260,
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      cellRenderer: 'companyCellRenderer',
    },
    {
      field: 'leadSource',
      headerName: 'Lead Source',
      minWidth: 260,
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      minWidth: 260,
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    {
      field: 'subStatus',
      headerName: 'Lead Sub-Status',
      minWidth: 260,
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    // {
    //   headerName: 'Action',
    //   minWidth: 80,
    //   cellRenderer: 'actionCellRenderer',
    //   comparator: noOpComparator,
    //   width: 80,
    //   pinned: 'right',
    //   lockPinned: true
    // },
  ];
  if (recordType === 'Borrower') {
    cols = cols.filter(column => column.headerName !== 'Action' && column.headerName !== 'Company');
  }
  return cols;
}

export const NotYetContactedLeadsDashboardGrid = observer(({ toolbar, recordType }: { toolbar: ReactNode, recordType: String }) => {
  const { salesforceLeadStore, companyProfileStore, companyDropdownStore } = useStore();
  const { newLeadStore, ringCentralStore } = useCRMPortalStore();
  const { push } = useHistory();
  const [anchorEl, setAnchorEl] = useState<HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const [record, setRecord] = useState({});
  const { leadDetailsRoutesConfig } = useLeadDetailsRoutes();

  const data = {
    checkboxSelection: true,
    disableRowSelectionOnClick: true,
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };


  let gridStore;
  if (recordType === 'TPO') {
    gridStore = salesforceLeadStore.notYetContactedTPOLeadsGridStore;
  } else {
    gridStore = salesforceLeadStore.notYetContactedBorrowerLeadsGridStore;

  }

  useEffect(() => {
    gridStore.resetAndFetchGridData();
  }, [salesforceLeadStore]);

  const onSortChanged = params => {
    const sortModel = params.api.getSortModel();
    gridStore.setSortModel(sortModel);
  };

  const onFilterChanged = params => {
    const filterModel = params.api.getFilterModel();
    gridStore.setFilterModel(filterModel);
  };

  const leadNameCellRenderer = (params, propertyName) => {
    const selectedId = gridStore?.gridData?.data?.rows.find(row => row[propertyName] === params.value)?.leadId;
    const url = leadDetailsRoutesConfig.leadDetails(selectedId).url;
    return LinkColumn({ ...params, url });
  };

  const handleOnclick = async (params) => {
    const selectedId = gridStore?.gridData?.data?.rows.find(row => row.company === params.value)?.id;
    if (selectedId) {
      push(`/crm-portal/backoffice/lead-details/${selectedId}`)
    }

  }

  const frameworkComponents = {
    firstNameCellRenderer: params => leadNameCellRenderer(params, 'firstName'),
    lastNameCellRenderer: params => leadNameCellRenderer(params, 'lastName'),
    companyCellRenderer: params => leadNameCellRenderer(params, 'company'),
    actionCellRenderer: params => {
      return (
        <IconButton onClick={e => openMenu(e, params.node.data)}>
          <MoreVert color="primary" />
        </IconButton>
      );
    },
  };

  const openMenu = (event: React.MouseEvent<HTMLElement>, record) => {
    setRecord(record)
    setAnchorEl(event.currentTarget);
  };

  function onRowSelected(value) {
    const { leadsDashboardStore } = salesforceLeadStore;
    const dashboardType = leadsDashboardStore.currentDashboard?.objectType;
    ringCentralStore.setRecord(value?.data.phone, value?.data, value?.node.selected, dashboardType);
  }

  const unselectAllRows = () => {
    gridStore.sele([]);
  };

  return (
    <Layout>
      <Paper title={"Not Yet Contacted " + recordType + " Leads Dasboard"}>
        <DataGrid
          selectMode={SelectMode.MULTIPLE}
          checkboxSelection disableRowSelectionOnClick {...data}
          onRowSelected={e => onRowSelected(e)}
          suppressRowClickSelection={false}
          frameworkComponents={frameworkComponents}
          toolbar={toolbar}
          columns={columns(recordType)}
          rows={gridStore.gridData.data.rows}
          onSortChanged={onSortChanged}
          onFilterChanged={onFilterChanged}
          isLoading={gridStore.isLoading}
          showFilters={gridStore.showFilters}
          sortModel={gridStore.sortModel}
          filterModel={gridStore.filterModel}
          paginationData={gridStore.gridData.meta}
          setPageNumber={gridStore.setPageNumber}
          rowHeight={45}
          domLayout='autoHeight'
        />
      </Paper>
      <Menu
        id="menu-appbar"
        getContentAnchorEl={null}
        anchorEl={anchorEl}
        keepMounted
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={menuOpen}
        onClose={handleMenuClose}
      >
        <SubmitMenuItem
          testId={`change-loan-status-`}
          onClick={() => {
            salesforceLeadStore.convertLeadToLender(record);
            handleMenuClose();
          }}
          submitDialogProps={{
            title: `Convert Lead to Lender`,
            body: <ConvertLeadToLenderModal record={record} />,
            okButtonText: 'Submit',
            submitValidation: () => {
              salesforceLeadStore.convertLeadToLenderStore.runFormValidation();
              return salesforceLeadStore.convertLeadToLenderStore.form.meta.isValid;
            },
          }}
        >
          <ListItemIcon >
            <ImportExport fontSize="small" />
          </ListItemIcon>
          <ListItemText>Convert Lead</ListItemText>
        </SubmitMenuItem>
      </Menu>
    </Layout>
  );
});
