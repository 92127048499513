import { Box, Grid } from '@material-ui/core';
import { isNil } from '@roc/feature-utils';
import { Button, DialogState, EditableDialog, PDFPreview, Tabs, Paper, ConfirmDialog } from '@roc/ui';
import { observer } from 'mobx-react';
import { useGeneralTasksStore } from '../../hooks/useGeneralTasksStore';
import { AddAttachmentDialog } from './addAttachmentDialog';
import { AttachmentPreview } from './attachmentPreview';
import { useState, useEffect, ReactNode } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { GeneralTaskType, TaskStatus } from '../../types/generalTasksTypes';
import { SummaryTab } from './editTaskBody/summaryTab';
import { CommentsTab } from './editTaskBody/commentsTab';
import { moveToRocCategories } from '../../utils/generalTasksConstants';
import { AppraisalSummaryTab } from './editTaskBody/appraisalSummaryTab';
import { useStore } from '@roc/client-portal-shared/hooks';
import { useUserStore } from '@roc/feature-app-core';
import { AppraisalCommentsTab } from './editTaskBody/appraisalCommentsTab';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    containerWrapperInternal: {
      overflow: 'auto',
      flexGrow: 1,
    },
    container: {
      width: '100%',
    },
    warningButton: {
      color: '#ff0000',
      borderColor: '#ff0000',
      marginRight: '8px',
    },
    additionalButton: {
      marginRight: '8px',
    },
  }),
);

export const PutTaskToTpo = observer(() => {
  const { generalTasksStore } = useGeneralTasksStore();
  const { editTaskStore } = generalTasksStore;
  const store = editTaskStore;
  const classes = useStyles();
  const [putToTpoConfirmModalOpen, setPutToTpoConfirmModalOpen] = useState(false);
  const { globalStore } = useStore();
  const { userStore } = useUserStore();


  const handleConfirmAction = () => {
    store.putTaskToTpo(() => {
      setPutToTpoConfirmModalOpen(false);
      store.closeDialog();
    });
  }

  return (
    <div>
      {(store.dialogState === DialogState.EDIT &&
        editTaskStore.task.attributes.taskType === GeneralTaskType.CMT_REVIEW && globalStore.isInternalPortal && !userStore.isDrawUser
      )
        &&
        <>
          <Button
            onClick={e => setPutToTpoConfirmModalOpen(true)}
            className={classes.additionalButton}
            color="primary"
            variant="outlined"
            testId="putToTpo"
          >
            Send to TPO
          </Button>
          <ConfirmDialog
            open={putToTpoConfirmModalOpen}
            confirmButtonText={'Yes'}
            cancelButtonText={'No'}
            handleClose={() => setPutToTpoConfirmModalOpen(false)}
            handleCancel={() => setPutToTpoConfirmModalOpen(false)}
            handleConfirm={handleConfirmAction}
            title={'Send to TPO?'}
            body={`Are you sure you want to send this task to the TPO?`}
          />
        </>
      }
    </div>
  );
});

export const MoveTaskToRocButton = observer(() => {
  const { generalTasksStore } = useGeneralTasksStore();
  const { editTaskStore } = generalTasksStore;
  const store = editTaskStore;
  const classes = useStyles();
  const [moveToRocConfirmModalOpen, setMoveToRocConfirmModalOpenn] = useState(false);
  const { userStore } = useUserStore();

  const isValidMoveToRocCategory = (taskCategory: string) => {
    return moveToRocCategories.includes(taskCategory)
  }

  const handleMoveToRocConfirmAction = () => {
    store.moveTaskToRoc(() => {
      setMoveToRocConfirmModalOpenn(false);
      store.closeDialog();
    });
  }

  return (
    <div>
      {(store.dialogState === DialogState.EDIT &&
        isValidMoveToRocCategory(store.form.fields.taskType.value) &&
        store?.task?.attributes?.propertyId &&
        store?.task?.attributes?.loanId &&
        userStore.isTamariskUser &&
        editTaskStore.task.attributes.taskType === GeneralTaskType.APPRAISAL_REPORT_REVIEW
      )
        &&
        <>
          <Button
            onClick={e => setMoveToRocConfirmModalOpenn(true)}
            className={classes.additionalButton}
            color="primary"
            variant="outlined"
            testId="moveToRoc"
          >
            Send to Roc
          </Button>
          <ConfirmDialog
            open={moveToRocConfirmModalOpen}
            confirmButtonText={'Yes'}
            cancelButtonText={'No'}
            handleClose={() => setMoveToRocConfirmModalOpenn(false)}
            handleCancel={() => setMoveToRocConfirmModalOpenn(false)}
            handleConfirm={handleMoveToRocConfirmAction}
            title={'Send to Roc?'}
            body={`Are you sure you want to send the task to the Roc team?`}
          />
        </>
      }
    </div>
  );
});

export const MarkTaskAsCompletedButton = observer(() => {
  const { generalTasksStore } = useGeneralTasksStore();
  const { editTaskStore } = generalTasksStore;
  const store = editTaskStore;
  const classes = useStyles();
  const [confirmaModalOpen, setConfirmaModalOpen] = useState(false);
  const { userStore } = useUserStore();
  const { globalStore } = useStore();

  const isExternalPortal = globalStore.isCrmPortal || globalStore.isLenderPortal;
  const isExternalUser = userStore.isSalesUser || userStore.isLenderUser;

  const isValidCategory = (taskCategory: string) => {
    return moveToRocCategories.includes(taskCategory)
  }

  const handleMarkAsCompletedConfirmAction = () => {
    if (isExternalPortal) {
      if (editTaskStore.task.attributes.taskType === GeneralTaskType.CMT_REVIEW) {
        store.moveTaskToCMTSpecialist(() => {
          setConfirmaModalOpen(false);
          store.closeDialog();
        });
      } else {
        store.moveTaskToRoc(() => {
          setConfirmaModalOpen(false);
          store.closeDialog();
        });
      }
    } else {
      store.markTaskAsCompleted(() => {
        setConfirmaModalOpen(false);
        store.closeDialog();
      });
    }

  }

  return (
    <div>
      {(store.dialogState === DialogState.EDIT &&
        isValidCategory(store.form.fields.taskType.value) &&
        store?.task?.attributes?.propertyId &&
        store?.task?.attributes?.loanId &&
        isExternalPortal
      )
        ?
        <>
          <Button
            onClick={e => setConfirmaModalOpen(true)}
            className={classes.additionalButton}
            color="primary"
            variant="outlined"
            testId="markAsCompleted"
          >
            {'Move Task To capital provider'}
          </Button>
          <ConfirmDialog
            open={confirmaModalOpen}
            confirmButtonText={'Yes'}
            cancelButtonText={'No'}
            handleClose={() => setConfirmaModalOpen(false)}
            handleCancel={() => setConfirmaModalOpen(false)}
            handleConfirm={handleMarkAsCompletedConfirmAction}
            title={'Move Task To capital provider?'}
            body={`Are you sure you want to move this task to capital provider?`}
          />
        </>
        :
        (store.dialogState === DialogState.EDIT &&
          isValidCategory(store.form.fields.taskType.value) &&
          store?.task?.attributes?.propertyId &&
          store?.task?.attributes?.loanId &&
          (userStore.isUserAuthorizedToMarkAsComplete ||
            (userStore.isTamariskUser && editTaskStore.task.attributes.taskType === GeneralTaskType.APPRAISAL_REPORT_REVIEW) ||
            (globalStore.isInternalPortal && editTaskStore.task.attributes.taskType === GeneralTaskType.APPRAISAL_DOCUMENT_COLLECTION)
          )
        ) &&
        <>
          <Button
            onClick={e => setConfirmaModalOpen(true)}
            className={classes.additionalButton}
            color="primary"
            variant="outlined"
            testId="markAsCompleted"
          >
            {'Mark as completed'}
          </Button>
          <ConfirmDialog
            open={confirmaModalOpen}
            confirmButtonText={'Yes'}
            cancelButtonText={'No'}
            handleClose={() => setConfirmaModalOpen(false)}
            handleCancel={() => setConfirmaModalOpen(false)}
            handleConfirm={handleMarkAsCompletedConfirmAction}
            title={'Mark as completed?'}
            body={`Are you sure you want to mark this task as completed?`}
          />
        </>
      }
    </div>
  );
});

export const MarkTaskAsWaitingOnExternalButton = observer(() => {
  const { generalTasksStore } = useGeneralTasksStore();
  const { editTaskStore } = generalTasksStore;
  const store = editTaskStore;
  const classes = useStyles();
  const [confirmaModalOpen, setConfirmaModalOpen] = useState(false);
  const { userStore } = useUserStore();
  const { globalStore } = useStore();

  const isExternalPortal = globalStore.isCrmPortal || globalStore.isLenderPortal;
  const isExternalUser = userStore.isSalesUser || userStore.isLenderUser;

  const isValidCategory = (taskCategory: string) => {
    return moveToRocCategories.includes(taskCategory)
  }

  const handleConfirm = () => {
    store.markTaskAsWaitingOnExternal(() => {
      setConfirmaModalOpen(false);
      store.closeDialog();
    });
  }

  return (
    <div>
      {(store.dialogState === DialogState.EDIT &&
        isValidCategory(store.form.fields.taskType.value) &&
        store?.task?.attributes?.propertyId &&
        store?.task?.attributes?.loanId &&
        isExternalPortal
      )
        &&
        <>
          <Button
            onClick={e => setConfirmaModalOpen(true)}
            className={classes.additionalButton}
            color="primary"
            variant="outlined"
            testId="markAsWaitingOnExternal"
          >
            {'Mark Task As Waiting On Borrower'}
          </Button>
          <ConfirmDialog
            open={confirmaModalOpen}
            confirmButtonText={'Yes'}
            cancelButtonText={'No'}
            handleClose={() => setConfirmaModalOpen(false)}
            handleCancel={() => setConfirmaModalOpen(false)}
            handleConfirm={handleConfirm}
            title={'Mark task as waiting on borrower?'}
            body={`Are you sure you want to mark this task as waiting on borrower?`}
          />
        </>
      }
    </div>
  );
});

export const EditGeneralTaskDialog = observer(() => {
  const { generalTasksStore } = useGeneralTasksStore();
  const { editTaskStore } = generalTasksStore;
  const store = editTaskStore;
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const classes = useStyles();
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  useEffect(() => {
    setSelectedTabIndex(0);
  }, [store.dialogState]);

  const getTitle = () => {
    const taskId = editTaskStore?.task?.taskDetails?.taskId;
    const parentTaskId = store.form.fields.parentTaskId.value;

    if (store.dialogState === DialogState.EDIT) {
      return `Task #${editTaskStore?.task?.taskDetails?.taskId}`;
    } else if (store.dialogState === DialogState.ADD && parentTaskId) {
      return 'Subtask';
    } else {
      return `Task`;
    }
  };

  const TABS = [
    {
      label: 'SUMMARY'
    },
    {
      label: 'COMMENTS'
    },
  ];

  const onTabChange = (e, index) => {
    setSelectedTabIndex(index);
    if (index === 1 && editTaskStore.dialogState === DialogState.EDIT && !editTaskStore?.hasConversation) {
      editTaskStore.createTaskChatRoom()
    }
    if (index === 0 && editTaskStore.dialogState === DialogState.EDIT) {
      editTaskStore.reloadTaskById(generalTasksStore.editTaskStore.task.taskDetails.taskId);
    }
  };

  const handleCloseConfirmModal = () => {
    setConfirmModalOpen(false);
  }

  const handleConfirmAction = () => {
    store.escalateTaskToManager();
    setConfirmModalOpen(false);
  }

  const dialogContent = (
    <Box my={1} className={classes.container}>
      <Grid container spacing={4} className={classes.container} style={{
        paddingTop: 0
      }}>
        <Grid item xs={12} md={12}>
          <Tabs
            tabs={TABS}
            selectedTab={selectedTabIndex}
            onTabChange={onTabChange}
          />
        </Grid>
        {selectedTabIndex === 0 && <SummaryTab />}
        {selectedTabIndex === 1 && <CommentsTab />}
        <AttachmentPreview />
        <AddAttachmentDialog />
      </Grid>
    </Box>

  );

  const escalateButton = (
    <>
      {(store.dialogState === DialogState.EDIT &&
        editTaskStore.canEscalateTask &&
        store.task.taskDetails.status !== TaskStatus.DONE)
        &&
        <>
          <Button
            onClick={e => setConfirmModalOpen(true)}
            className={classes.warningButton}
            variant="outlined"
            testId="escalate"
            disabled={!editTaskStore.canEscalateTask}
          >
            Escalate
          </Button>
          <ConfirmDialog
            open={confirmModalOpen}
            confirmButtonText={'Yes'}
            cancelButtonText={'No'}
            handleClose={handleCloseConfirmModal}
            handleCancel={handleCloseConfirmModal}
            handleConfirm={handleConfirmAction}
            title={'Escalate task?'}
            body={`Are you sure you want to escalate this task?`}
          />
        </>
      }
    </>
  );

  return (
    <EditableDialog
      title={getTitle()}
      maxWidth={'lg'}
      open={!isNil(store.dialogState)}
      dialogState={store.dialogState}
      handleAdd={() => store.saveTaskWithMessage()}
      handleEdit={() => store.saveTaskWithMessage()}
      handleClose={() => store.closeDialog()}
      editButtonText={'Save'}
      addButtonText={'Create'}
      disabledButton={false}
      dialogContent={dialogContent}
      additionalActions={[escalateButton, <MarkTaskAsWaitingOnExternalButton />, <MoveTaskToRocButton />, <PutTaskToTpo />, <MarkTaskAsCompletedButton />]}
      removeContentBox
    />
  );
});

export const EditAppraisalTaskDialog = observer(() => {
  const { generalTasksStore } = useGeneralTasksStore();
  const { editTaskStore } = generalTasksStore;
  const store = editTaskStore;
  const { globalStore } = useStore();
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const classes = useStyles();
  const { userStore } = useUserStore();

  useEffect(() => {
    setSelectedTabIndex(0);
  }, [store.dialogState, globalStore.isLenderPortal, globalStore.isCrmPortal]);

  const getTitle = () => {
    const taskId = editTaskStore?.task?.taskDetails?.taskId;
    const parentTaskId = store.form.fields.parentTaskId.value;

    if (store.dialogState === DialogState.EDIT) {
      return `Task #${editTaskStore?.task?.taskDetails?.taskId}`;
    } else if (store.dialogState === DialogState.ADD && parentTaskId) {
      return 'Subtask';
    } else {
      return `Task`;
    }
  };

  const TABS = [
    {
      label: 'SUMMARY'
    },
    {
      label: 'COMMENTS'
    },
  ];

  const onTabChange = (e, index) => {
    setSelectedTabIndex(index);
    if (index === 1 && editTaskStore.dialogState === DialogState.EDIT && !editTaskStore?.hasConversation) {
      if (editTaskStore?.task?.attributes?.taskType === GeneralTaskType.APPRAISAL_REPORT_REVIEW) {
        editTaskStore.createTaskChatRoom()
      } else {
        editTaskStore.setHasConversation(true);
      }
    }
    if (index === 0 && editTaskStore.dialogState === DialogState.EDIT) {
      editTaskStore.reloadTaskById(generalTasksStore.editTaskStore.task.taskDetails.taskId);
    }
  };

  const dialogContent = (
    <Box my={1} className={classes.container}>
      <Grid container spacing={4} className={classes.container} style={{
        paddingTop: 0
      }}>
        <Grid item xs={12} md={12}>
          <Tabs
            tabs={TABS}
            selectedTab={selectedTabIndex}
            onTabChange={onTabChange}
          />
        </Grid>
        {selectedTabIndex === 0 && <AppraisalSummaryTab />}
        {editTaskStore?.task?.attributes?.taskType === GeneralTaskType.APPRAISAL_REPORT_REVIEW ?
          <>
            {globalStore.isInternalPortal ?
              <>
                {editTaskStore.isTamariskAppraisal ?
                  <>
                    {selectedTabIndex === 1 && <CommentsTab />}

                  </>
                  :
                  <>
                    {selectedTabIndex === 1 && <AppraisalCommentsTab />}
                  </>
                }

              </>
              :
              <>
                {selectedTabIndex === 1 && <AppraisalCommentsTab />}
              </>
            }
          </>
          :
          <>
            {selectedTabIndex === 1 && <AppraisalCommentsTab />}
          </>
        }
      </Grid>
    </Box>

  );

  return (
    <EditableDialog
      title={getTitle()}
      maxWidth={'lg'}
      open={!isNil(store.dialogState)}
      dialogState={store.dialogState}
      handleAdd={() => store.saveTaskWithMessage()}
      handleEdit={() => store.saveTaskWithMessage()}
      handleClose={() => store.closeDialog()}
      disabledButton={false}
      editButtonText={'Save'}
      dialogContent={dialogContent}
      additionalActions={[<MarkTaskAsWaitingOnExternalButton />, <MoveTaskToRocButton />, <PutTaskToTpo />, <MarkTaskAsCompletedButton />]}
      showSaveChangesButton={globalStore.isInternalPortal}
      removeContentBox
    />
  );
});

export const EditTaskDialog = observer(() => {
  const { generalTasksStore } = useGeneralTasksStore();
  const { editTaskStore } = generalTasksStore;

  return (
    editTaskStore.isAppraisalTaskType ?
      <EditAppraisalTaskDialog /> :
      <EditGeneralTaskDialog />
  );
});
