import React, { useState, useEffect } from 'react';
import {
  Box,
  FormHelperText,
  Grid,
  List,
  ListItem,
  Typography,
  Divider,
  Checkbox,
  Link,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import HelpIcon from '@material-ui/icons/Help';
import { observer } from 'mobx-react';
import { format, isValid } from 'date-fns';
import {
  AutocompleteAddressField,
  CurrencyField,
  DateField,
  NumberFormat,
  PercentageField,
  RadioField,
  SelectField,
  TextField,
  yesNoOptions,
  trueFalseOptions,
  StandardDialog,
  Button,
  Tooltip,
  ConfirmationButton,
  FieldLabel,
  CFPBRuralOptions,
} from '@roc/ui';
import { CloudUpload, Warning, Launch } from '@material-ui/icons';
import {
  insuranceOptions,
  isNotBlank,
  loanPurpose,
  loanRecourse,
  notLicensedSfr,
  titleOptionsAllowedWimba,
  titleOptions,
  originatorSubLabel,
} from '@roc/client-portal-shared/utils';
import { useStore } from '@roc/client-portal-shared/hooks';
import { formatCurrency } from '@roc/ui/utils';
import {
  FileUpload,
  FileUploadItem,
  FileUploadModal,
} from '@roc/ui';
import { useDropzone } from 'react-dropzone';
import { useLoanStore } from '../../loans';
import { useFixFlipStore } from '../hooks/useFixFlipStore';
import { FileUploadArea } from '@roc/ui';

import {
  PropertyAddressDetails
} from '@roc/ui/formComponents';

const useLabelStyles = makeStyles(() => ({
  clickable: {
    cursor: 'Pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

const useFastTrackStyles = makeStyles(() => ({
  fastTrackLink: {
    fontWeight: 'bold',
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#fdefbf',
    padding: '4px',
  },
  warningIcon: {
    color: '#F2BC16',
    fontSize: '20px',
  },
  fastTrackText: {
    paddingLeft: '8px',
    fontSize: '14px',
  },
}));

export const useFastTrackModalStyles = makeStyles((theme: Theme) => ({
  buttonsSection: {
    marginTop: '50px',
  },
  itemContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  description: {
    fontWeight: 'bold',
    marginRight: '8px',
  },
  filling: {
    flexGrow: 1,
    height: '19px',
    backgroundImage:
      'linear-gradient(to right, #313131 40%, rgba(255, 255, 255, 0) 20%)',
    backgroundPosition: 'bottom',
    backgroundSize: '3px 1px',
    backgroundRepeat: 'repeat-x',
  },
  price: {
    fontWeight: 'bold',
    marginLeft: '8px',
  },
  totalText: {
    fontWeight: 'bold',
  },
  divider: {
    marginTop: '12px',
    marginBottom: '12px',
  },
  tooltipPopper: {
    left: '-80px !important',
    top: '-30px !important',
  },
  helpIcon: {
    height: '18px',
    margin: theme.spacing(0, 0, 0.5, 0.5),
    width: '18px',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#fdefbf',
    padding: '4px',
  },
  footerTextContainer: {
    marginTop: '10px',
    marginBottom: '10px'
  },
  rushFeeDisclaimer: {
    marginLeft: '29px'
  },
  space: {
    width: '50px',
    height: 'auto',
    display: 'inline-block',
  },
  buttonStyle: {
    width: '208px',
    height: '42px',
  },
  subtextBox: {
    paddingLeft: '38px',
    marginTop: '-10px',
  },
  modalDescription: {
    marginTop: '-40px',
    marginBottom: '-30px',
  },
  marginReduction: {
    marginTop: '-20px',
    marginBottom: '20px',
  },
  linksMargin: {
    marginTop: '10px',
    marginBottom: '10px',
  },
  emailLinkMargin: {
    paddingTop: '20px',
    marginBottom: '30px',
  },
  emailButtonStyle: {
    paddingRight: '40px',
  },
  dragArea: {
    border: '2px dotted #427ED1',
    width: '100%',
    minHeight: '10vh',
    backgroundColor: '#EAF4FA',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: 20,
    marginBottom: 10,
    borderRadius: 5,
    cursor: 'pointer',
  },
  icon: {
    minWidth: '20px',
    minHeight: '20px',
    width: '3vw',
    height: '3vw',
    color: '#4B89DA',
  },
  fastTrackOptionsContainer: {
    marginTop: '-15px',
  }
}));

export const GridContainer = ({ children }) => {
  return (
    <Grid item xs={12} sm={6} md={4}>
      {children}
    </Grid>
  );
};

export const LoanPurpose = observer(({ store, ...rest }) => {
  const { form } = store;
  return (
    <SelectField
      standaloneLabel
      label={'Loan Purpose'}
      name={'loanPurpose'}
      value={form.fields.loanPurpose.value}
      errorText={form.fields.loanPurpose.error}
      error={isNotBlank(form.fields.loanPurpose.error)}
      options={loanPurpose}
      onChange={value => store.handleLoanPurposeChange('loanPurpose', value)}
      variant="outlined"
      fullWidth
      shrink
      required
      testId="loanPurpose"
      {...rest}
    />
  );
});

export const PurchasePrice = observer(({ store, ...rest }) => {
  const { form } = store;
  return (
    <CurrencyField
      variant="outlined"
      standaloneLabel
      label="Purchase Price"
      value={form.fields.acquisitionPrice.value}
      onChange={(name, value) =>
        store.onFieldChange('acquisitionPrice', Number(value))
      }
      fullWidth
      testId="acquisitionPrice"
      required
      error={isNotBlank(form.fields.acquisitionPrice.error)}
      errorText={form.fields.acquisitionPrice.error}
      {...rest}
    />
  );
});

export const PurchaseDate = observer(({ store, ...rest }) => {
  const formFields = store.form.fields;
  const manageDate = date => {
    let formattedDate = null;
    if (isValid(date)) {
      formattedDate = format(date, 'MM/dd/yyyy');
    }
    store.onFieldChange('acquisitionDate', formattedDate);
  };

  return (
    <DateField
      inputVariant="outlined"
      testId="acquisitionDate"
      format="MM/dd/yyyy"
      standaloneLabel
      label="Purchase Date"
      value={formFields.acquisitionDate.value}
      onChange={date => manageDate(date)}
      disableFuture={true}
      error={isNotBlank(formFields.acquisitionDate.error)}
      errorText={formFields.acquisitionDate.error}
      fullWidth
      required
      {...rest}
    />
  );
});

export const CapitalImprovements = observer(({ store, ...rest }) => {
  const { form } = store;
  return (
    <CurrencyField
      variant="outlined"
      standaloneLabel
      label="Completed Renovations"
      value={form.fields.capitalImprovements.value}
      onChange={(name, value) =>
        store.onFieldChange('capitalImprovements', Number(value))
      }
      fullWidth
      testId="capitalImprovements"
      required
      error={isNotBlank(form.fields.capitalImprovements.error)}
      errorText={form.fields.capitalImprovements.error}
      {...rest}
    />
  );
});

export const CurrentDebtOnProperty = observer(({ store, ...rest }) => {
  const { form } = store;
  return (
    <RadioField
      key={form.fields.anyDebt.value}
      standaloneLabel
      label="Current Debt on the property"
      value={form.fields.anyDebt.value}
      name={'anyDebt'}
      options={yesNoOptions}
      onChange={value => store.handleAnyDebtChange('anyDebt', value)}
      fullWidth
      row
      required
      error={isNotBlank(form.fields.anyDebt.error)}
      errorText={form.fields.anyDebt.error}
      testId="anyDebt"
      {...rest}
    />
  );
});

export const PropertyLeased = observer(({ store, ...rest }) => {
  const { form } = store;
  return (
    <RadioField
      key={form.fields.isPropertyLeased.value}
      standaloneLabel
      label="Is the property currently occupied with a long term lease in place, or will the property be leased prior to closing?"
      value={form.fields.isPropertyLeased.value}
      name={'isPropertyLeased'}
      options={yesNoOptions}
      onChange={value => store.handleLeaseChange('isPropertyLeased', value)}
      fullWidth
      row
      required
      error={isNotBlank(form.fields.isPropertyLeased.error)}
      errorText={form.fields.isPropertyLeased.error}
      testId=" b"
      {...rest}
    />
  );
});

export const CurrentDebtAmount = observer(({ store, ...rest }) => {
  const { form } = store;

  return (
    <CurrencyField
      variant="outlined"
      standaloneLabel
      label="Current Debt Amount on the property"
      value={form.fields.totalDebtPayoff.value}
      onChange={(name, value) =>
        store.onFieldChange('totalDebtPayoff', Number(value))
      }
      fullWidth
      testId="totalDebtPayoff"
      required
      error={isNotBlank(form.fields.totalDebtPayoff.error)}
      errorText={form.fields.totalDebtPayoff.error}
      {...rest}
    />
  );
});

export const TargetLtv = observer(({ store, ...rest }) => {
  const { form } = store;
  return (
    <PercentageField
      name={'targetLTV'}
      testId="targetLTV"
      required={true}
      variant="outlined"
      label="Target Ltv"
      decimalScale={2}
      value={form.fields.targetLTV.value}
      onChange={(name, value) => store.onFieldChange('targetLTV', value)}
      errorText={form.fields.targetLTV.error}
      error={isNotBlank(form.fields.targetLTV.error)}
      standaloneLabel
      fullWidth
      {...rest}
    />
  );
});

export const LoanRecourse = observer(({ store, ...rest }) => {
  const { form } = store;
  return (
    <SelectField
      standaloneLabel
      label={'Loan Recourse'}
      name={'loanRecourse'}
      value={form.fields.loanRecourse.value}
      errorText={form.fields.loanRecourse.error}
      error={isNotBlank(form.fields.loanRecourse.error)}
      options={loanRecourse}
      onChange={value => store.onFieldChange('loanRecourse', value)}
      variant="outlined"
      fullWidth
      shrink
      required
      testId="loanRecourse"
      tooltip={'All recourse options include a pledge of equity'}
      {...rest}
    />
  );
});

export const PreferredClosingAttorney = observer(({ store, disabled }: { store: any; disabled?: boolean }) => {
  const formFields = store.form.fields;
  return (
    <SelectField
      standaloneLabel
      label={'Preferred Closing Attorney'}
      name={'preferredClosingAttorney'}
      value={formFields.preferredClosingAttorney.value}
      errorText={formFields.preferredClosingAttorney.error}
      error={isNotBlank(formFields.preferredClosingAttorney.error)}
      options={store.lenderAttorneysOptionList}
      onChange={value => store.onChange('preferredClosingAttorney', value)}
      variant="outlined"
      fullWidth
      shrink
      required
      disabled={disabled}
      testId="preferredClosingAttorney"
    />
  );
});

export const PreferredInsurance = observer(({ store }) => {
  const formFields = store.form.fields;
  return (
    <SelectField
      standaloneLabel
      label={'Preferred Insurance Company'}
      name={'preferredInsurance'}
      value={formFields.preferredInsurance.value}
      errorText={formFields.preferredInsurance.error}
      error={isNotBlank(formFields.preferredInsurance.error)}
      options={insuranceOptions}
      onChange={value => store.onChange('preferredInsurance', value)}
      variant="outlined"
      fullWidth
      shrink
      required
      testId="preferredInsurance"
    />
  );
});

export const PreferredTitleCompany = observer(({ store }) => {
  const { form } = store;
  return (
    <SelectField
      standaloneLabel
      label={'Preferred Title Company'}
      name={'preferredTitle'}
      value={form.fields.preferredTitle.value}
      errorText={form.fields.preferredTitle.error}
      error={isNotBlank(form.fields.preferredTitle.error)}
      options={store.showWimba() ? titleOptionsAllowedWimba : titleOptions}
      onChange={value => store.onChange('preferredTitle', value)}
      variant="outlined"
      fullWidth
      shrink
      testId="preferredTitle"
    />
  );
});

export const LoanOriginator = observer(
  ({ store, label = 'Loan Originator', name = 'loanOriginator' }) => {
    const { form } = store;
    const { originators } = store.loanOriginatorStore;
    return (
      <SelectField
        standaloneLabel
        label={label}
        name={name}
        value={form.fields[name].value}
        errorText={form.fields[name].error}
        error={isNotBlank(form.fields[name].error)}
        options={originators.map(originator => ({
          label: `${originator.firstName} ${originator.lastName}`,
          value: originator.userId,
        }))}
        onChange={value => store.onChange(name, value)}
        variant="outlined"
        fullWidth
        shrink
        testId={name}
      />
    );
  }
);

export const Originator = observer(
  ({ store, label = 'Originator', name = 'originatorId' }) => {
    const { form } = store;
    const { originatorOptions } = store.loanParticipantsStore;
    return (
      <SelectField
        standaloneLabel
        label={label}
        name={name}
        value={form.fields[name].value}
        errorText={form.fields[name].error}
        error={isNotBlank(form.fields[name].error)}
        options={originatorOptions}
        onChange={value => store.onChange(name, value)}
        variant="outlined"
        fullWidth
        shrink
        testId={name}
        subLabel={originatorSubLabel}
      />
    );
  }
);

export const LenderParticipants = observer(
  ({
    store,
    xs = undefined,
    md = undefined,
    originatorFieldName = 'originatorId',
  }) => {
    const {
      allowOriginatorSelection,
    } = store.loanParticipantsStore;
    return (
      <>
        {allowOriginatorSelection && (
          <Grid item xs={xs} md={md}>
            <Originator store={store} name={originatorFieldName} />
          </Grid>
        )}
      </>
    );
  }
);

export const FacoParticipants = observer(
  ({ store, xs = undefined, md = undefined }) => {
    const { userCanSelectOriginator } = store.loanOriginatorStore;

    return (
      <>
        {userCanSelectOriginator && (
          <Grid item xs={xs} md={md}>
            <LoanOriginator store={store} />
          </Grid>
        )}
      </>
    );
  }
);

export const RequestedClosingDate = observer(({ store }) => {
  const formFields = store.form.fields;
  const manageDate = date => {
    let formattedDate = null;
    if (isValid(date)) {
      formattedDate = format(date, 'MM/dd/yyyy');
    }
    store.onChange('requestedClosingDate', formattedDate);
  };

  return (
    <DateField
      inputVariant="outlined"
      testId="requestedClosingDate"
      format="MM/dd/yyyy"
      standaloneLabel
      label="Requested Closing Date"
      value={formFields.requestedClosingDate.value}
      onChange={date => manageDate(date)}
      disablePast={true}
      fullWidth
      required
    />
  );
});

export const SubmitAppraisalWithTamarisk = observer(({ store }) => {
  const formFields = store.form.fields;
  return (
    <RadioField
      standaloneLabel
      label="Do you want to order an Appraisal with Tamarisk"
      value={formFields.submitAppraisalWithTamarisk.value}
      errorText={formFields.submitAppraisalWithTamarisk.error}
      error={isNotBlank(formFields.submitAppraisalWithTamarisk.error)}
      name={'submitAppraisalWithTamarisk'}
      options={yesNoOptions}
      onChange={value => store.onChange('submitAppraisalWithTamarisk', value)}
      fullWidth
      row
      required
      testId="submitAppraisalWithTamarisk"
    />
  );
});

const FastTrackModalContent = observer(props => {
  const { globalStore } = useStore();
  const classes = useFastTrackModalStyles();
  const {
    onClose,
    options,
    sendPaymentEmail,
    fastTrackFees,
    propertiesQuantity = 1,
    paymentUrl,
  } = props;
  const [total, setTotal] = useState<number>(0);

  const openPaymentTab = () => {
    window.open(paymentUrl);
  };

  useEffect(() => {
    let calculatedTotal = fastTrackFees.fastTrackFee * propertiesQuantity;

    setTotal(calculatedTotal);
  }, [options]);

  const handleCopy = () => {
    navigator.clipboard.writeText(paymentUrl);
    globalStore.notificationStore.showSuccessNotification({
      message: 'Link was copied to clipboard!',
    });
  };

  const onPayment = () => {
    openPaymentTab();
    onClose();
  };

  return (
    <Grid container spacing={2}>
      <Grid container justifyContent="flex-start">
        {!globalStore.isInternalPortal && (
          <>
            <Grid item className={classes.marginReduction}>
              <Typography>
                Thank you for selecting fast track! Please find the payment
                options below. As a reminder, the fast track process will not
                begin until payment is received. The fast track process
                includes:
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.fastTrackOptionsContainer}>
              <List>
                <ListItem>&#x2022; Insurance</ListItem>
                <ListItem>&#x2022; Title</ListItem>
                <ListItem>&#x2022; Rushed Appraisal</ListItem>
                <Grid
                  container
                  xs={12}
                  direction="row"
                  justifyContent="space-between"
                  className={classes.rushFeeDisclaimer}
                >
                  <Typography variant="caption">
                    Please note, this fee solely covers the expediting of the
                    appraisal. A separate link has been sent for the standard
                    appraisal fee.
                  </Typography>
                </Grid>
              </List>
            </Grid>
            <Grid item xs={12}>
              <Divider className={classes.divider} />
            </Grid>
            <Grid
              container
              xs={12}
              direction="row"
              justifyContent="space-between"
              className={classes.textContainer}
            >
              <div className={classes.totalText}>Total Fee:</div>
              <div className={classes.totalText}>{formatCurrency(total)}</div>
            </Grid>
            <Grid
              container
              xs={12}
              direction="row"
              justifyContent="space-between"
              className={classes.footerTextContainer}
            >
              <Typography variant="caption" color="error">
                All payments made in favor of Fast Track are non-refundable.
              </Typography>
            </Grid>
            <Grid item container justifyContent="center">
              <Box className={classes.itemContainer}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={onPayment}
                  testId="closeFastTrackModal"
                  startIcon={<Launch />}
                >
                  PAY NOW
                </Button>
              </Box>
            </Grid>
          </>
        )}
        <Grid item>
          <Box className={classes.linksMargin}>
            <Typography>Link for Payment</Typography>
          </Box>
        </Grid>
        <Grid item container alignItems="center" spacing={2}>
          <Grid item xs={12} sm>
            <TextField
              testId="fastrackUrl"
              variant="outlined"
              label="Deposit URL"
              value={paymentUrl}
              fullWidth
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleCopy}
              testId="copyLoanFastrackPaymentUrlButton"
            >
              COPY LINK
            </Button>
          </Grid>
        </Grid>
        <Grid
          item
          container
          justifyContent="space-between"
          className={classes.emailLinkMargin}
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={12} sm>
            <Typography className={classes.emailButtonStyle}>
              Or you can send an email to the borrower with the payment
              details
            </Typography>
          </Grid>
          <Grid item xs={12} sm={3}>
            <ConfirmationButton
              variant="contained"
              onClick={sendPaymentEmail}
              color="secondary"
              testId="sendLoanFastrackPaymentButton"
              confirmDialogProps={{
                title: 'Confirmation',
                body: 'Email will be sent out. Please confirm',
              }}
            >
              Email Link
            </ConfirmationButton>
          </Grid>
        </Grid>
        <Grid item container justifyContent="center">
          <Button
            variant="outlined"
            color="primary"
            onClick={onClose}
            testId="closeFastTrackModal"
          >
            Close
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
});

export const FastTrackModal = observer(props => {
  const {
    isOpen,
    onClose,
    onUpload,
    onConfirm,
    sendPaymentEmail,
    options,
    prefInsurance,
    prefTitle,
    onlyPayment,
    fastTrackFees,
    propertiesQuantity,
    paymentUrl,
    readOnly = false,
    canSubmitAppraisalWithTamarisk = false,
  } = props;

  useEffect(() => {
    if (isOpen) {
      const isElmsure = prefInsurance === 'Elmsure';
      const isWimba = prefTitle === 'Wimba';

      onConfirm({
        insuranceReview: !isElmsure,
        titleSearch: isWimba,
        insurance: isElmsure,
        rushedAppraisal: true,
        titleAttorneySearch: !isWimba
      });
    }
  }, [isOpen]);

  return (
    <StandardDialog
      open={isOpen}
      title='Fast Track Payment'
      maxWidth="md"
      handleClose={onClose}
      dialogContent={
        <FastTrackModalContent
          uploadInsuranceDocument={onUpload}
          options={options}
          onClose={onClose}
          onConfirm={onConfirm}
          sendPaymentEmail={sendPaymentEmail}
          prefInsurance={prefInsurance}
          prefTitle={prefTitle}
          onlyPayment={onlyPayment}
          fastTrackFees={fastTrackFees}
          propertiesQuantity={propertiesQuantity}
          paymentUrl={paymentUrl}
          readOnly={readOnly}
          canSubmitAppraisalWithTamarisk={canSubmitAppraisalWithTamarisk}
        />
      }
    />
  );
});

export const FastTrackLoan = observer(({ store, sendPaymentEmail, propertiesQuantity }) => {
  const classes = useFastTrackStyles();
  const formFields = store.form.fields;
  const prefTitle = formFields.preferredTitle.value;
  const prefInsurance = formFields.preferredInsurance.value;
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);

  useEffect(() => {
    store.fetchLoanId();
    store.loadFastTrackFees();
  }, []);

  useEffect(() => {
    if (formFields.fastTrack.value === 'N') {
      store.onChange('fastTrackOptions', {
        insuranceReview: false,
        titleSearch: false,
        insurance: false,
      });
    }
  }, [formFields.fastTrack.value]);

  const handleFastTrackLink = () => {
    if (formFields.fastTrack.value === 'Y') {
      setOpenModal(true);
    }
  };

  const handleOpenModal = () => {
    setShow(true);
  };
  const handleFastTrack = () => {
    setOpenModal(true);
  };
  const handleConfirm = async (options) => {
    store.onChange('fastTrackOptions', options);
    await store.updateLoanFastrackInfo();
  };

  const onFastTrackOptionChange = (value) => {
    store.onChange('fastTrack', value);
    if (value === 'Y') {
      setOpenModal(true);
    }
  };

  return (
    <>
      <RadioField
        standaloneLabel
        label="Do you want to Fast Track this loan?"
        value={formFields.fastTrack.value}
        errorText={formFields.fastTrack.error}
        error={isNotBlank(formFields.fastTrack.error)}
        name={'fastTrack'}
        options={yesNoOptions}
        onChange={onFastTrackOptionChange}
        fullWidth
        row
        required
        testId="fastTrack"
        tooltip={
          'Click here to know more about Fast Track'
        }
        openToolTip={handleOpenModal}
        onClick={handleFastTrack}
      />
      <div className={classes.textContainer}>
        <Warning className={classes.warningIcon} />
        <p className={classes.fastTrackText}>
          The loan can be{' '}
          <span
            className={classes.fastTrackLink}
            onClick={handleFastTrackLink}
          >
            fast tracked
          </span>
          . You can also access the payment link in the Underwriting section
          of the loan after submitting the loan.
        </p>
      </div>
      <FastTrackModal
        isOpen={openModal}
        onClose={() => setOpenModal(false)}
        onUpload={(file) => store.uploadInsuranceDocument(file)}
        options={formFields.fastTrackOptions.value}
        onConfirm={handleConfirm}
        sendPaymentEmail={sendPaymentEmail}
        prefInsurance={prefInsurance}
        prefTitle={prefTitle}
        loanId={store.draftLoanId}
        onlyPayment={false}
        fastTrackFees={store.fastTrackFees}
        propertiesQuantity={propertiesQuantity}
        paymentUrl={store.fastTrackPaymentUrl}
        canSubmitAppraisalWithTamarisk={store.canSubmitAppraisalWithTamarisk}
      />
    </>
  );
});

export const GfdPayment = observer(({ store }) => {
  const formFields = store.form.fields;
  return (
    <RadioField
      standaloneLabel
      label="Do you want the borrower to pay GFD"
      value={formFields.gfdPayment.value}
      errorText={formFields.gfdPayment.error}
      error={isNotBlank(formFields.gfdPayment.error)}
      name={'gfdPayment'}
      options={yesNoOptions}
      onChange={value => store.onChange('gfdPayment', value)}
      fullWidth
      row
      required
      testId="gfdPayment"
    />
  );
});

export const AppraisalPromotion = observer(({ store }) => {
  const formFields = store.form.fields;
  return (
    <RadioField
      standaloneLabel
      label="Does the borrower qualify for the free appraisal promotion?"
      value={formFields.freeAppraisalPromotion.value}
      errorText={formFields.freeAppraisalPromotion.error}
      error={isNotBlank(formFields.freeAppraisalPromotion.error)}
      name={'freeAppraisalPromotion'}
      options={yesNoOptions}
      onChange={value => store.onChange('freeAppraisalPromotion', value)}
      fullWidth
      row
      required
      testId="freeAppraisalPromotion"
    />
  );
});

export const StateLicenseUpload = observer(({ store, setUploads, property }) => {
  return (
    <Grid item>
      <Typography variant='h4'>State Licensing</Typography>
      <Typography>{`Please upload licensing files for ${property.state}`}</Typography>
      <FileUploadArea items={store.licenseUpload} setItems={(items) => {
        setUploads(items);
      }} />
    </Grid>
  );
});

export const Address = observer(({ store }) => {
  const { form } = store;

  const handleAddressChange = (event, name) => {
    const { value, fullInfo } = event?.target || {};
    const { address_components, geometry } = fullInfo || {};
    store.saveAddressField(value, address_components, geometry);
  };
  return (
    <AutocompleteAddressField
      value={form.fields.address.value}
      name="address"
      label="Enter property address"
      error={
        isNotBlank(form.fields.address.error) ||
        isNotBlank(form.fields.__isAddressInLicensedState__.error) ||
        isNotBlank(form.fields.__isAddressInCorrectFormat__.error)
      }
      errorText={
        form.fields.address.error ||
        form.fields.__isAddressInLicensedState__.error ||
        form.fields.__isAddressInCorrectFormat__.error
      }
      onChange={event => handleAddressChange(event, 'address')}
      fullWidth
      required
      testId="address"
    />
  );
});

export const AddressNoLicensedText = observer(({ store }) => {
  const hasError = isNotBlank(
    store.form.fields.address.error ||
    store.form.fields.__isAddressInLicensedState__.error ||
    store.form.fields.__isAddressInCorrectFormat__.error
  );

  return (
    <>
      <PropertyAddressDetails store={store} />
      {!hasError && <FormHelperText>{notLicensedSfr.message}</FormHelperText>}
    </>
  );
});

export const AnnualIncome = observer(({ store }) => {
  const { form } = store.pricerStore;
  return (
    <GridContainer>
      <CurrencyField
        variant="outlined"
        disabled={true}
        standaloneLabel
        label="Total Annual Income"
        value={form.fields.totalAnnualIncome.value}
        fullWidth
        testId="totalAnnualIncome"
        required
      />
    </GridContainer>
  );
});

export const AnnualExpenses = observer(({ store }) => {
  const { form } = store.pricerStore;
  return (
    <GridContainer>
      <CurrencyField
        variant="outlined"
        disabled={true}
        standaloneLabel
        label="Annual Expenses"
        value={form.fields.totalAnnualExpenses.value}
        fullWidth
        testId="totalAnnualExpenses"
        required
      />
    </GridContainer>
  );
});

export const AnnualNoi = observer(({ store, ...rest }) => {
  const { form } = store.pricerStore;
  return (
    <GridContainer>
      <CurrencyField
        variant="outlined"
        standaloneLabel
        disabled={true}
        label="Annual NOI"
        value={form.fields.totalAnnualNOI.value}
        fullWidth
        testId="totalAnnualNOI"
        required
        error={isNotBlank(form.fields.totalAnnualNOI.error)}
        errorText={form.fields.totalAnnualNOI.error}
        {...rest}
      />
    </GridContainer>
  );
});

export const UnitsOccupied = observer(({ store }) => {
  const { form } = store;
  return (
    <NumberFormat
      customInput={TextField}
      required={true}
      variant="outlined"
      label="How many units are occupied?"
      value={form.fields.unitsOccupied.value}
      onChange={e => store.onFieldChange('unitsOccupied', e.target.value)}
      helperText={form.fields.unitsOccupied.error}
      error={isNotBlank(form.fields.unitsOccupied.error)}
      standaloneLabel
      fullWidth
      testId={'unitsOccupied'}
    />
  );
});

export const UnitsOccupiedHave12MonthsLease = observer(({ store }) => {
  const { form } = store;
  return (
    <RadioField
      key={form.fields.unitsOccupiedHave12MonthsLease.value}
      standaloneLabel
      label="Do occupied units have 12+ months leases in place?"
      value={form.fields.unitsOccupiedHave12MonthsLease.value}
      name={'unitsOccupiedHave12MonthsLease'}
      options={yesNoOptions}
      onChange={value =>
        store.onFieldChange('unitsOccupiedHave12MonthsLease', value)
      }
      fullWidth
      row
      required
      error={isNotBlank(form.fields.unitsOccupiedHave12MonthsLease.error)}
      errorText={form.fields.unitsOccupiedHave12MonthsLease.error}
      testId="unitsOccupiedHave12MonthsLease"
    />
  );
});

export const CashOut = observer(({ store, onChange = null, ...rest }) => {
  const formFields = store.form.fields;

  const handleChange = value => {
    store.onFieldChange('seekingCashOut', value);
    onChange && onChange(value);
  };

  return (
    <>
      <RadioField
        standaloneLabel
        label="Is the borrower seeking cash-out?"
        value={formFields.seekingCashOut.value}
        errorText={formFields.seekingCashOut.error}
        error={isNotBlank(formFields.seekingCashOut.error)}
        name={'seekingCashOut'}
        options={yesNoOptions}
        onChange={handleChange}
        fullWidth
        row
        required
        testId="seekingCashOut"
        {...rest}
      />
      {formFields.seekingCashOut.value === yesNoOptions[1].value && (
        <Typography color="primary" variant="caption">
          Seeking to pay off the current debt and closing costs only
        </Typography>
      )}
    </>
  );
});

export const DisclosureLoanDetails = () => {
  const classes = useLabelStyles();
  const { loanStore } = useLoanStore();

  const handleClick = () => {
    loanStore.downloadWimbaDoc();
  };

  return (
    <Grid container spacing={1} lg direction="column">
      <Grid item>
        <Typography variant="h5">
          Affiliated Business Disclosures
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1">
          Products and discounts not available to all persons in all states.
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1">
          ElmSure is a subsidiary of Roc360.
        </Typography>
      </Grid>
      <Grid item container >
        <Grid item>
          <Typography variant="body1">
            For Wimba Disclosure Statement, click
          </Typography>
        </Grid>
        <Grid item>
          <Box ml={0.5}>
            <Typography
              variant="body1"
              color="primary"
              className={classes.clickable}
              onClick={handleClick}
            >
              here.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export const Rural = observer(({ store, defaultValue }) => {
  const formFields = store.form.fields;

  useEffect(() => {
    if (!formFields.rural.value) {
      store.onFieldChange('rural', defaultValue);
    }
  }, []);

  return (
    <>
      <RadioField
        standaloneLabel
        label={<Typography >Please check <Link href="https://www.consumerfinance.gov/rural-or-underserved-tool/" target="_blank">CFPB</Link>, then select the proper pricing below:</Typography>}
        value={formFields.rural.value === null ? null : formFields.rural.value}
        name={'rural'}
        options={CFPBRuralOptions}
        onChange={value => store.onFieldChange('rural', value)}
        fullWidth
        row
        testId={'rural'}
      />
    </>
  )
})
