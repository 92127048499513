import { UserStore } from '@roc/feature-app-core';
import { ExternalLeadStore } from 'libs/client-portal-shared/src/app/modules/companyProfile/stores/externalLeadStore';
import { action, flow, makeObservable } from 'mobx';
import { NewLeadService } from '../services/newLeadService';
import { NewLeadBaseStore } from './newLeadBaseStore';

export class NewLeadStore extends NewLeadBaseStore {
  private externalLeadStore: ExternalLeadStore;
  private userStore: UserStore;

  constructor(globalStore, userStore) {
    super(globalStore, new NewLeadService());
    this.userStore = userStore;
    this.externalLeadStore = new ExternalLeadStore(globalStore, this, this.userStore);

    makeObservable(this, {
      getUserInformation: action,
      getAllActiveOwners: action,
    });
  }

  *fetchAllOwners() {
    yield this.externalLeadStore.getAllOwners();
  }

  getUserInformation() {
    return this.userStore.userInformation as any;
  }

  getAllActiveOwners() {
    return this.externalLeadStore.allActiveOwners;
  }
}
