import { DocumentName, parseAddress } from '@roc/feature-utils';
import { flow, action, makeObservable, observable, computed } from 'mobx';
import { GlobalStore } from '@roc/feature-app-core';
import { DocumentService, DocumentStatus, DocumentStore } from '@roc/feature-documents';
import { LoanStore } from '@roc/feature-loans';
import { FormStore } from '@roc/feature-app-core';
import { BorrowerClosingAgentService } from '../../services/documentForms/borrowerClosingAgentService';
import { Document } from '@roc/feature-types';
import { ApiResponse } from '@roc/feature-app-core';
import { canEditForm } from '../../utils/documentForms';

const borrowerClosingAgentForm = {
  fields: {
    isApplicable: {
      value: true,
      error: null,
      rule: '',
    },
    name: {
      value: '',
      error: null,
      rule: [{ required_if: ['isApplicable', true] }],
      message: 'This field is required.',
    },
    contactName: {
      value: '',
      error: null,
      rule: '',
    },
    cellPhone: {
      value: '',
      error: null,
      rule: 'regex:/^\\(?\\d{3}\\)?[\\s.-]?\\d{3}[\\s.-]?\\d{4}$/',
      message: 'Cellphone must be a number with the format xxx-xxx-xxxx.',
    },
    emailAddress: {
      value: '',
      error: null,
      rule: 'email',
      message: 'Email address format is invalid. ',
    },
    address: {
      value: '',
      error: null,
      rule: '',
    },
    streetNumber: {
      value: '',
      error: null,
      rule: '',
    },
    streetName: {
      value: '',
      error: null,
      rule: '',
    },
    city: {
      value: '',
      error: null,
      rule: '',
    },
    state: {
      value: '',
      error: null,
      rule: '',
    },
    zipCode: {
      value: '',
      error: null,
      rule: '',
    },
    latitude: {
      value: 0,
      error: null,
      rule: '',
    },
    longitude: {
      value: 0,
      error: null,
      rule: '',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export class BorrowerClosingAgentStore extends FormStore {
  address;
  loanStore: any;
  borrowerClosingAgent: any;
  borrowerClosingAgentService: BorrowerClosingAgentService;
  documentService: DocumentService;
  globalStore: GlobalStore;
  documentStore: DocumentStore;

  constructor(
    loanStore: LoanStore,
    documentStore: DocumentStore,
    globalStore: GlobalStore
  ) {
    super({ ...borrowerClosingAgentForm }, globalStore);
    this.borrowerClosingAgent = null;
    this.borrowerClosingAgentService = new BorrowerClosingAgentService();
    this.documentService = new DocumentService();
    this.loanStore = loanStore;
    this.globalStore = globalStore;
    this.documentStore = documentStore;

    makeObservable(this, {
      address: observable,
      resetStore: action,
      saveAddressField: action,
      borrowerClosingAgent: observable,
      submitBorrowerAgent: flow,
      canEdit: computed,
    });
  }

  initialize() {
    const {
      borrowerClosingAgencyBO,
    } = this.loanStore.loanDetails.loanClosingData;
    this.resetStore();
    this.loadForm({
      ...borrowerClosingAgencyBO,
      name: borrowerClosingAgencyBO.name ?? '',
      cellPhone: borrowerClosingAgencyBO.phone ?? '',
      emailAddress: borrowerClosingAgencyBO.email ?? '',
      borrowerAttorneyStreet: borrowerClosingAgencyBO.streetName,
      borrowerAttorneyStreetNumber: borrowerClosingAgencyBO.streetNumber,
      borrowerAttorneyCity: borrowerClosingAgencyBO.city,
      borrowerAttorneyState: borrowerClosingAgencyBO.state,
      borrowerAttorneyZip: borrowerClosingAgencyBO.zipCode,
      borrowerEntityAttorneyAddress: borrowerClosingAgencyBO.address,
      contactName:
        borrowerClosingAgencyBO.contacts && borrowerClosingAgencyBO.contacts[0]
          ? borrowerClosingAgencyBO.contacts[0].firstName
          : '',
    });
    this.address = borrowerClosingAgencyBO.address;
  }

  resetStore() {
    this.reset();
    this.address = null;
    this.borrowerClosingAgent = null;
  }

  saveAddressField(address: string, addressDetails, geometryDetails) {
    const parsedAddress = parseAddress(
      address,
      addressDetails,
      geometryDetails
    );
    this.onFieldChange('address', address);
    this.onFieldChange('streetNumber', parsedAddress.street_number);
    this.onFieldChange('streetName', parsedAddress.street);
    this.onFieldChange('city', parsedAddress.city);
    this.onFieldChange('state', parsedAddress.state);
    this.onFieldChange('zipCode', parsedAddress.zip);
  }

  *submitBorrowerAgent(document: Document) {
    try {
      const { isApplicable } = this.getFormValues();
      if (isApplicable) {
        this.runFormValidationWithMessage();
        if (!this.form.meta.isValid) {
          return;
        }
        const doc = {
          ...document,
        };

        const payload = {
          borrowerClosingAttorneyFormDetails: this.getInformationForSubmit(),
          loanDocument: doc,
          loanId: this.loanStore.loanDetails.loanId,
        };
        const response: ApiResponse = yield this.borrowerClosingAgentService.requestBorrowerDeposit(
          payload
        );
        const parsedResponse = JSON.parse(response.data?.data);

        if (!parsedResponse?.success) {
          throw new Error();
        }
      } else {
        yield this.documentService.updateDocument({
          ...document,
          status: DocumentStatus.NOT_APPLICABLE,
        });
      }
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Borrower Closing Agent saved successfully.',
      });
      yield this.documentStore.documentFormStore.closeCurrentForm();
      yield this.loanStore.refreshLoanDetails();
      yield this.documentStore.refetchDocuments();
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'There was an error making the request.',
      });
    }
  }
  private getInformationForSubmit() {
    const formValues = this.getFormValues();

    return {
      name: formValues.name,
      cellPhone: formValues.cellPhone,
      email: formValues.emailAddress,
      borrowerAttorneyStreet: formValues.streetName,
      borrowerAttorneyStreetNumber: formValues.streetNumber,
      borrowerAttorneyCity: formValues.city,
      borrowerAttorneyState: formValues.state,
      borrowerAttorneyZip: formValues.zipCode,
      borrowerEntityAttorneyAddress: formValues.address,
      borrowerAttorneyContactName: formValues.contactName,
    };
  }

  get canEdit() {
    const entitlements = this.globalStore.userFeatures.closingEntitlements;
    return canEditForm(entitlements, DocumentName.BORROWER_CLOSING_AGENT);
  }
}

export default BorrowerClosingAgentStore;
