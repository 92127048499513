import {
  Grid,
  IconButton,
  InputAdornment,
  Typography,
  ButtonBase
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import LockIcon from '@material-ui/icons/Lock';
import MailIcon from '@material-ui/icons/Mail';
import { Button, TestDataAttribute, TextField } from '@roc/ui';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useStore, useAppRoutes } from '../../hooks';
import { LoginLayout } from './components/loginLayout';
import { observer } from 'mobx-react';
import { isNotBlank } from '@roc/client-portal-shared/utils';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { LoginLogoImg } from '@roc/feature-app-core';

const useStyles = makeStyles(theme =>
  createStyles({
    gridContainer: {
      padding: theme.spacing(4),
      width: '100%',
      maxWidth: 500,
      margin: 'auto',
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(0),
      },
    },
    link: {
      cursor: 'Pointer',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    helperText: {
      marginTop: theme.spacing(2),
      textAlign: 'right',
    },
    loginButton: {
      marginTop: theme.spacing(2),
      color: theme.palette.getContrastText(theme.palette.primary.main)
    },
  })
);

export const Login = observer(() => {
  const classes = useStyles();
  const { push } = useHistory();
  const { publicRoutes } = useAppRoutes();
  const { loginStore, registerStore, authenticationStore } = useStore();
  const { form, show2FALogin } = loginStore;
  const [showPassword, setShowPassword] = useState(false);

  const handleForgot = () => {
    push(publicRoutes.forgotpassword.url);
  };

  // const handleRegister = () => {
  //   if (registerStore.isRegistrationEnabled) {
  //     history.push(publicRoutes.register.url);
  //   }
  // };

  const handleFormSubmit = () => {
    loginStore.onFormSubmit((oauthResponse) => {
      authenticationStore.doPostSuccessfulAuthentication(oauthResponse);
    });
  };

  const keyPress = e => {
    if (e.key === 'Enter') {
      handleFormSubmit();
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = e => {
    e.preventDefault();
  };

  useEffect(() => {
    loginStore.reset();
    loginStore.setForgotpasswordUrl(publicRoutes.forgotpassword.url);
    loginStore.setShow2FALogin(false);
    registerStore.getRegistrationEnabled();
  }, []);

  useEffect(() => {
    if (show2FALogin) {
      push(publicRoutes.twoFactorAuthentication.url);
    }
  }, [show2FALogin]);

  return (
    <LoginLayout>
      <Grid
        container
        spacing={2}
        justifyContent="center"
        className={classes.gridContainer}
      >
        <Grid item xs={12}>
          <LoginLogoImg />
        </Grid>
        <Grid item xs={12}>
          <TextField
            testId="email"
            fullWidth
            variant="outlined"
            label="Email"
            type="email"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <MailIcon />
                </InputAdornment>
              ),
            }}
            value={form.fields.username.value}
            onChange={e => loginStore.onFieldChange('username', e.target.value)}
            onKeyDown={keyPress}
            helperText={form.fields.username.error}
            error={isNotBlank(form.fields.username.error)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            testId="password"
            fullWidth
            variant="outlined"
            label="Password"
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            value={form.fields.password.value}
            onChange={e => loginStore.onFieldChange('password', e.target.value)}
            onKeyDown={keyPress}
            helperText={form.fields.password.error}
            error={isNotBlank(form.fields.password.error)}
          />
          <Grid container justifyContent="flex-end">
            <ButtonBase
              className={`${classes.link} ${classes.helperText}`}
              onClick={handleForgot}
            >
              <Typography variant="body2" color="textSecondary">
                Forgot your password?
              </Typography>
            </ButtonBase>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Button
            fullWidth
            variant="contained"
            type="submit"
            disabled={!form.meta.isValid}
            onClick={handleFormSubmit}
            testId="submit"
            color='primary'
          >
            <span>LOG IN</span>
          </Button>
        </Grid>
      </Grid>
    </LoginLayout>
  );
});
