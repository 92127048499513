import React, { useEffect, useState } from 'react';
import { Box, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Button, CopyText } from '@roc/ui';
import { observer } from 'mobx-react';
import {
  DataGrid as Grid,
  GridColumnProps,
  LinkColumn,
  SelectFilterComponent,
  SelectFloatingFilterComponent,
  FilterButton,
} from '@roc/ui';
import { Toolbar } from '@roc/ui';
import { GridStore } from '@roc/feature-app-core';
import { useLoanRoutes } from '@roc/feature-loans-routes-config';
import CopyUrlModal from './common/scopeOfWorkV2Form/components/copyUrlModal/copyUrlModal';
import { ScopeOfWorkV2FormStore, ScopeOfWorkV2Store } from '../stores';

const useStyles = makeStyles((theme: Theme) => ({
  copyText: {
    cursor: 'pointer',
    position: 'relative',
    padding: '0 4px',
    '&::after': {
      display: 'none',
      content: '""',
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
    },
  },
}));

const filterColumn = {
  filter: 'agTextColumnFilter',
  floatingFilter: true,
  floatingFilterComponentParams: {
    suppressFilterButton: true,
  },
};

const columns: GridColumnProps[] = [
  {
    field: 'scopeOfWorkId',
    headerName: 'Scope Of Work Id',
    cellRenderer: 'scopeOfWorkIdCellRenderer',
    ...filterColumn,
  },
  {
    field: 'loanId',
    headerName: 'Loan Id',
    cellRenderer: 'loanIdCellRenderer',
    ...filterColumn,
  },
  {
    field: 'address',
    headerName: 'Property Address',
    minWidth: 300,
    ...filterColumn,
  },
  {
    field: 'creationDate',
    headerName: 'Creation Date',
    ...filterColumn,
  },
  {
    field: 'originatorName',
    headerName: 'Originator',
    ...filterColumn,
  },
];

export type ScopesOfWorkV2GridProps = {
  onSelectScopeOfWork: (scopeOfWorkId: string, version: number) => void;
  onCreateNewScopeOfWork: () => void;
  store: GridStore;
  scopeOfWorkV2FormStore: ScopeOfWorkV2FormStore;
};

export const ScopesOfWorkGridV2 = observer((props: ScopesOfWorkV2GridProps) => {
  const { onSelectScopeOfWork, onCreateNewScopeOfWork, store, scopeOfWorkV2FormStore } = props;

  const [isCopyUrlModalOpen, setIsCopyUrlModalOpen] = useState(false);

  const { loanRoutesConfig } = useLoanRoutes();

  const classes = useStyles();

  const frameworkComponents = {
    selectFilter: SelectFilterComponent,
    selectFloatingFilter: SelectFloatingFilterComponent,
    scopeOfWorkIdCellRenderer: params => {
      const scopeOfWorkId = params.node.data?.scopeOfWorkId;
      const version = params.node.data?.version;
      return LinkColumn({
        ...params,
        url: '#',
        onClick: () => onSelectScopeOfWork(scopeOfWorkId, version),
      });
    },
    loanIdCellRenderer: params => {
      return LinkColumn({
        ...params,
        url: loanRoutesConfig.loans(params.value).children.dashboard.url,
      });
    },
  };

  const handleToggleFilters = () => {
    store.toggleFilters();
  };

  const handleClearFilters = () => {
    store.resetFilters();
  };

  const onFilterChanged = params => {
    const filterModel = params.api.getFilterModel();
    store.setFilterModel(filterModel);
  };

  const onSortChanged = params => {
    const sortModel = params.api.getSortModel();
    store.setSortModel(sortModel);
  };

  useEffect(() => {
    store.fetchGridData();
  }, [store]);

  const handleCopyUrlModalClose = () => setIsCopyUrlModalOpen(false);

  const handleCopyUrl = () => {
    navigator.clipboard.writeText(scopeOfWorkV2FormStore.publicScopeOfWorkShareUrl);
    handleCopyUrlModalClose();
  };

  const handleShareClick = () => {
    setIsCopyUrlModalOpen(true);
  };

  return (
    <>
      <CopyUrlModal
        handleClose={handleCopyUrlModalClose}
        handleCopy={handleCopyUrl}
        isOpen={isCopyUrlModalOpen}
        url={scopeOfWorkV2FormStore.publicScopeOfWorkShareUrl}
      />
      <Grid
        toolbar={
          <Toolbar>
            <Box display="flex" alignItems="center">
              <FilterButton
                onToggleFilters={handleToggleFilters}
                onClearFilters={handleClearFilters}
              />
            </Box>
            <Box ml={2}>
              <Button
                testId="createScopeOfWorkButton"
                variant="contained"
                size="small"
                color="primary"
                onClick={onCreateNewScopeOfWork}
              >
                Create Scope of Work
              </Button>
            </Box>
            <Box ml={2}>
              <Button
                testId="createScopeOfWorkButton"
                variant="contained"
                size="small"
                color="primary"
                onClick={handleShareClick}
              >
                Public Link
              </Button>
            </Box>
          </Toolbar>
        }
        columns={columns}
        rows={store.gridData.data.rows}
        frameworkComponents={frameworkComponents}
        onSortChanged={onSortChanged}
        onFilterChanged={onFilterChanged}
        isLoading={store.isLoading}
        showFilters={store.showFilters}
        sortModel={store.sortModel}
        filterModel={store.filterModel}
        paginationData={store.gridData.meta}
        setPageNumber={store.setPageNumber}
      />
    </>
  );
});
