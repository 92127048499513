import { SelectField } from "@roc/ui";
import { ICellEditor, ICellEditorParams, ICellEditorComp, ColDef } from "ag-grid-community";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { useReceivablesDashboardStore } from '../../hooks/useReceivablesDasboardStore';
import { usePayablesDashboardStore } from "../../hooks/usePayablesDashboardStore";

const useStyles = makeStyles((theme: Theme) => ({
  selectField: {
    borderRadius: '0',
    '& .MuiSelect-root': {
      paddingTop: '11px',
      paddingBottom: '11px',
      fontSize: '14px',
    },
  },
}));

export const StatusEditorPayables = forwardRef<ICellEditor, ICellEditorParams>((props, ref) => {
  const { value } = props;
  const { payablesDashboardStore } = usePayablesDashboardStore();
  const [editableValue, setEditableValue] = useState(value);
  const inputRef = useRef<HTMLInputElement>(null);
  const classes = useStyles();

  useImperativeHandle(ref, () => ({
    getValue: () => {
      return editableValue;
    },
  }));

  useEffect(() => {
    if (inputRef.current) inputRef.current.focus();
  }, []);

  return (
    <SelectField
      options={[
        { label: 'Pending', value: 'Pending' },
        { label: 'Approved', value: 'Approved' },
        { label: 'Rejected', value: 'Rejected' },
      ]}
      testId={'foundation'}
      value={editableValue}
      onChange={async (value) => {
        await payablesDashboardStore.performAction(props?.data?.recordId, value);
        payablesDashboardStore.counterPartyExpenseGridStore.resetAndFetchGridData()
        setEditableValue(value);
      }}
      fullWidth
      variant="outlined"
      className={classes.selectField}
    />
  );
});
