import React, { useState } from 'react';
import { ComparableProperty } from '../../types/propertyTypes';
import { Card, CardContent, CardHeader, CardMedia, Checkbox, Grid, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import placeHolderImage from "../../images/Placeholder.svg"
import { formatDate, formatPrice } from '../../utils/formatter';
import { segmentAnalytics, SegmentTrackerEventCompTool } from '@roc/feature-utils';
import { useStore } from '@roc/client-portal-shared/hooks';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      border: `1px solid ${theme.palette.primary.light}`,
      width: '340px',
      height: '330px'
    },
    cardText: {
      fontSize: '18px'
    },
    cardBody: {
      fontSize: '13px'
    },
    cardMedia: {
      height: '180px',
      margin: '0px 10px 0px 10px'
    },
    divider: {
      color: '#DBDBDB',
      fontSize: '14px'
    }
  })
);

export interface PropertyCheckBoxProps {
  property: ComparableProperty;
}

export const PropertyCheckBox: React.FC<PropertyCheckBoxProps> = observer(({
  property
}) => {
  const classes = useStyles();
  const { compToolStore } = useStore();

  const { data } = property;
  const { isInternal, internalUsername, userStore, subjectProperty } = compToolStore
  const { address, images, rent, price, bedrooms, full_bathrooms, half_bathrooms, area, rent_date, sale_date } = data;

  const [imageLoaded, setImageLoaded] = useState(false);

  const onLoad = () => {
    setImageLoaded(true);
  };

  const onError = (event) => {
    if (!imageLoaded && event.target.src !== placeHolderImage) {
      event.target.src = placeHolderImage;
    }
  };

  const getPropertyImage = () => {
    const image = images.find(image => image.recommended_preview) ? images.find(image => image.recommended_preview).url : images[0]?.url
    return image || placeHolderImage
  }

  const openDialog = (property) => {
    compToolStore.setSelectedProperty(property);
    compToolStore.setOpenPropertyDialog(true)
    segmentAnalytics.trackEvent({
      name: SegmentTrackerEventCompTool.COMP_TOOL_COMP_PROPERTY_CLICKED_FROM_MAP,
      userName: isInternal ? internalUsername : userStore.userInformation?.username,
      subjectAddress: subjectProperty.data.address,
      propertyAddress: property.data.address
    });
  }

  return (

    <>
      <Card className={classes.card}>
        <CardHeader
          title={address?.split(',')[0].trim()}
          titleTypographyProps={{
            style: { fontSize: '22px', fontWeight: 500 }
          }}
          action={
            <Checkbox
              checked={compToolStore.selectedCompareProperties?.includes(property)}
              onClick={() => { compToolStore.addSelectCompareProperties(property) }}
              color='primary'
            />
          }
        />
        <div style={{ position: "relative" }}>
          <CardMedia onClick={() => openDialog(property)}
            className={classes.cardMedia}
            image={getPropertyImage()}
            onError={onError}
            onLoad={onLoad}
            title="House Image"

          />
          <div style={{
            position: "absolute", bottom: 10, left: 20,
            color: '#000000',
            backgroundColor: 'white',
            display: 'inline',
            padding: '5px',
            borderRadius: '4px'
          }}>
            <Typography gutterBottom style={{ fontSize: '32px', margin: '0px' }}>
              {formatPrice(price, rent, compToolStore.rentalComps)}{compToolStore.rentalComps && <strong><span style={{ fontSize: '16px' }}>/mo</span></strong>}
            </Typography>
          </div>
        </div>
        <CardContent onClick={() => openDialog(property)}>
          <Grid container spacing={1}>
            <Grid item xs={2}>
              <Typography align="left" className={classes.cardText}>
                <strong>{`${bedrooms ? bedrooms : '-'}`} </strong> bd <span className={classes.divider}>|</span>
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography align="left" className={classes.cardText}>
                <strong>{`${full_bathrooms ? full_bathrooms : '-'}`}</strong> ba <span className={classes.divider}>|</span>
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography align="left" className={classes.cardText}>
                <strong>{`${half_bathrooms ? half_bathrooms : '-'}`}</strong> hba
              </Typography>
            </Grid>
            <Grid item lg={5} xs={5}>
              <Typography align="right" className={classes.cardText}>
                <strong>{`${area ? area : '-'}`}</strong> sqft
              </Typography>
            </Grid>
            {compToolStore.rentalComps ?
              (<Grid item xs={12}>
                <Typography align="left" className={classes.cardBody}>
                  Rent Date <strong>{formatDate(rent_date)}</strong>
                </Typography>
              </Grid>) : (<Grid item xs={12}>
                <Typography align="left" className={classes.cardBody}>
                  Last sold on <strong>{formatDate(sale_date)}</strong>
                </Typography>
              </Grid>)}
          </Grid>
        </CardContent>
      </Card>
    </>
  );
});
