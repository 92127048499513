import { ExpandMore, HomeWorkOutlined } from '@material-ui/icons';
import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import React, { ReactNode, useState } from 'react';
import { ItemDisplay } from '../../../components/itemDisplay';
import {
  DetailsTable,
  DetailsTableField,
} from '../../../components/detailsTable';
import { observer } from 'mobx-react';
import { formatCurrency, getCompletePropertyAddress } from '@roc/ui/utils';
import { formatDate, insertIf } from '@roc/feature-utils';
import { ViewControls } from '../../../components/viewControls';
import { TitleDivider } from '../../../components/titleDivider';
import {
  getValueOrDefaultDash,
  getBooleanDisplayValue,
} from '../../../utils/utils';
import { RoundedBackgroundIcon } from '../../../components/roundedBackgroundIcon';
import { propertySourceValues } from '../../../utils/constants';
import { PropertyFormStore } from '../../../stores/properties/propertyFormStore';
import { PropertyDetailsTable } from './propertyDetailsTable';
import { ProjectDetailsTable } from './projectDetailsTable';

interface SupplementalInformationFormProps {
  store: PropertyFormStore;
}

interface PropertyInformationAccordionProps {
  store: PropertyFormStore;
  onDeleteClick?: (propertyKey: string) => void;
  removeButtonDisabled?: boolean;
  showRemoveButton?: boolean;
  showActions?: boolean;
  bottom?: ReactNode;
  expanded: boolean;
  onExpand: (expanded: boolean) => void;
  onEdit: () => void;
}

export const PropertyInformationAccordion = observer(
  ({
    store,
    onDeleteClick = () => null,
    showActions = true,
    showRemoveButton = true,
    removeButtonDisabled = false,
    bottom,
    expanded,
    onExpand,
    onEdit,
  }: PropertyInformationAccordionProps) => {
    const property = store.getFormValues();
    const propertyKey = store.key;

    let completeAddress = getCompletePropertyAddress(property);
    if (
      completeAddress === '' ||
      completeAddress.replace(/,/g, '').trim() === ''
    ) {
      completeAddress = '-';
    }

    return (
      <Accordion
        expanded={expanded}
        onChange={(e, expanded) => onExpand(expanded)}
        elevation={0}
      >
        <AccordionSummary
          expandIcon={<ExpandMore fontSize="large" />}
          aria-controls={`property-information-accordion-${propertyKey}`}
        >
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid
              item
              container
              spacing={2}
              xs={8}
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item alignItems="center" style={{ height: '100%' }}>
                <RoundedBackgroundIcon
                  Icon={HomeWorkOutlined}
                  iconColor="#6D6D6D"
                />
              </Grid>
              <Grid item>
                <ItemDisplay
                  label="Property Address"
                  value={completeAddress}
                  alignItems="flex-start"
                />
              </Grid>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            container
            alignItems="center"
            spacing={2}
            style={{ marginLeft: '58px' }}
          >
            {showActions && (
              <Grid item xs={12}>
                <Grid
                  container
                  spacing={2}
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <ViewControls
                    onDeleteClick={() => onDeleteClick(propertyKey)}
                    onEditClick={onEdit}
                    showRemoveButton={showRemoveButton}
                    removeButtonDisabled={removeButtonDisabled}
                  />
                </Grid>
              </Grid>
            )}
            <Grid item xs={12}>
              <TitleDivider title="Property Details" />
            </Grid>
            <Grid item xs={12}>
              <PropertyDetailsTable store={store} />
            </Grid>
            <Grid item xs={12}>
              <TitleDivider title="Project Details" />
            </Grid>
            <Grid item xs={12}>
              <ProjectDetailsTable store={store} />
            </Grid>
            {bottom}
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  }
);
