import { Grid, Link } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { RadioField } from '@roc/ui';
import { useBaseStore } from '@roc/feature-app-core';
import { Button } from '@roc/ui';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useUserStore } from '@roc/feature-app-core';
import { useBorrowersStore } from '../hooks/useBorrowersStore';

const useStyles = makeStyles(theme => ({
  paper: {
    marginBottom: theme.spacing(2),
    paddingLeft: '10px',
    paddingTop: '4px'
  },
  button: {
    marginTop: '13px',
    marginBottom: theme.spacing(2),
    width: '100px'
  },
  tickSize: {
    transform: "scale(1.2)",
  },
  alerts: {
    fontSize: '1rem',
    paddingLeft: '50px'
  },
  gridAlerts: {
    marginBottom: '15px'
  },
  alertsQuestions: {
    marginBottom: '8px'
  },
  link: {
    cursor: 'pointer',
    textDecoration: 'underline'
  }
}));

export type BorrowerPortalBannerProps = {
  editEnabled?: boolean,
  isRestrictedToBorrowerPortalEnabled?: boolean
};

export const BorrowerPortalBanner = observer((props: BorrowerPortalBannerProps) => {
  const { editEnabled, isRestrictedToBorrowerPortalEnabled } = props;
  const { globalStore } = useBaseStore();
  const { borrowersStore } = useBorrowersStore();
  const { userStore } = useUserStore();
  const classes = useStyles();
  const { automaticBorrowerPortalInvite, lenderTradeName, borrowerPortalEnabled } = borrowersStore;

  useEffect(() => {
    borrowersStore.getBorrowerPortalPreferencesByTpo(userStore.userInformation.companyId ?? globalStore?.selectedCompanyId)
    setAutomaticInvites(automaticBorrowerPortalInvite);
  }, [automaticBorrowerPortalInvite, lenderTradeName, borrowerPortalEnabled]);

  const [automaticInvites, setAutomaticInvites] = useState(false);

  const [enablePortalOptions, setEnablePortalOptions] = useState(false);

  const [showEditSection, setShowEditSection] = useState(true);

  const [showEnablePortal, setShowEnablePortal] = useState(true);

  if (!globalStore.lenderInfo?.allowBorrowerPortalManagement || (isRestrictedToBorrowerPortalEnabled && !borrowerPortalEnabled)) {
    return <></>;
  }

  const setPortalOptions = (value) => {
    if (value.toLowerCase() == 'true') {
      setAutomaticInvites(true)
    } else {
      setAutomaticInvites(false)
    }
  }

  return (
    <Grid className={classes.gridAlerts}>
      {!borrowerPortalEnabled && showEnablePortal &&
        <Alert severity="info" className={classes.alerts}>
          <AlertTitle><b>Borrower Portal is Live!</b></AlertTitle>
          Would you like to enable a borrower facing <b>{lenderTradeName} </b> branded portal where <b>{lenderTradeName}</b> borrowers can log in and request draws, payoffs, or extensions ? <br />
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => {
              setEnablePortalOptions(true);
              setShowEnablePortal(false);
            }}
            testId="enableBorrowerPortal"
            className={classes.button}
            size='small'
          >
            Enable
          </Button>
        </Alert>
      }
      {enablePortalOptions &&
        <Alert severity="info" className={classes.alerts}>
          <AlertTitle><b>Borrower Portal is Live!</b></AlertTitle>
          <p className={classes.alertsQuestions}>Your borrower facing <b>{lenderTradeName} </b> branded portal is enabled.</p>
          <p>Would you like borrowers to automatically receive an invitation to log into the <b>{lenderTradeName} </b> portal ?</p>
          <Grid md={8}>
            <RadioField
              key={'hasEntityInfo'}
              standaloneLabel
              value={automaticInvites}
              name={'hasEntityInfo'}
              options={[{ label: `Yes, automatically invite my borrowers to the ${lenderTradeName} borrower portal.`, value: true }, { label: `No, I'll manually select which ${lenderTradeName} borrowers will receive invites to the borrower portal.`, value: false }]}
              onChange={value => setPortalOptions(value)}
              fullWidth
              row
              testId="hasEntityInfo"
            />
          </Grid>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => {
              borrowersStore.enableBorrowerPortalForBorrowersByTpo(automaticInvites, userStore.userInformation.companyId ?? globalStore?.selectedCompanyId)
              setEnablePortalOptions(false)
              setShowEditSection(true)
            }}
            testId="enableBorrowerPortal"
            className={classes.button}
            size='small'
          >
            Save
          </Button>
        </Alert>
      }
      {borrowerPortalEnabled && showEditSection &&
        <Alert
          action={editEnabled ?
            (<Button
              onClick={() => {
                setEnablePortalOptions(true)
                setShowEditSection(false)
              }}
              color="primary"
              size="small"
              testId="enableBorrowerPortal">
              Edit
            </Button>) : null
          }
          className={classes.alerts}
        >
          {!!automaticInvites ?
            (<>
              <AlertTitle><b>Borrower Portal is Live!</b></AlertTitle>
              <p className={classes.alertsQuestions}>Your borrower facing <b>{lenderTradeName} </b> branded portal is enabled.</p><p>Your borrowers will automatically receive a portal invite. Click&nbsp;
                <Link className={classes.link} onClick={() => borrowersStore.downloadBorrowerHandbook()}>here</Link> to download the borrower portal handbook.</p>
            </>)
            :
            (<>
              <AlertTitle><b>Borrower Portal is Live!</b></AlertTitle>
              <p className={classes.alertsQuestions}>Your borrower facing <b>{lenderTradeName} </b> branded portal is enabled.</p>
              <p>You can select which borrowers will receive invites to the portal. Click&nbsp;
                <Link className={classes.link} onClick={() => borrowersStore.downloadBorrowerHandbook()}>here</Link> to download the borrower portal handbook.</p>
            </>)
          }
        </Alert>
      }
    </Grid>
  );
});
