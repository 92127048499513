import { action, computed, flow, makeObservable, observable } from 'mobx';
import { FormStore, GlobalStore } from '@roc/feature-app-core';
import { parseAddress } from '@roc/feature-utils';
import { BorrowerInfoCardInternalService } from 'apps/roc-internal-portal/src/app/modules/borrowerInfoCard/services/borrowerInfoCardInternalService';
import { format } from 'date-fns';
import { TRACK_RECORD_MLS_DATA, TRACK_RECORD_USER_INPUTTED } from 'libs/feature-utils/src/utils/constants';
import { store } from 'apps/client-portal-public/src/app/stores';

const form = {
  fields: {
    entityName: {
      value: '',
      error: null,
      rule: 'required'
    },
    soldTo: {
      value: '',
      error: null,
      rule: ''
    },
    purchaseAddress: {
      value: '',
      error: null,
      rule: 'required'
    },
    purchaseCity: {
      value: '',
      error: null,
      rule: 'required'
    },
    purchaseState: {
      value: '',
      error: null,
      rule: 'required'
    },
    purchaseZipcode: {
      value: '',
      error: null,
      rule: ''
    },
    purchaseDate: {
      value: null,
      error: null,
      rule: 'required',
      attribute: 'Purchase Date'
    },
    purchasePrice: {
      value: '',
      error: null,
      rule: ''
    },
    mortgageAmount: {
      value: '',
      error: null,
      rule: ''
    },
    purchasedFrom: {
      value: '',
      error: null,
      rule: ''
    },
    lenderName: {
      value: '',
      error: null,
      rule: ''
    },
    saleDate: {
      value: null,
      error: null,
      rule: ''
    },
    salePrice: {
      value: '',
      error: null,
      rule: ''
    },
    rehabBudget: {
      value: '',
      error: null,
      rule: ''
    },
    exitStrategy: {
      value: '',
      error: null,
      rule: ''
    },
    streetName: {
      value: '',
      error: null,
      rule: ''
    },
    streetNumber: {
      value: '',
      error: null,
      rule: ''
    },
    unitNumber: {
      value: '',
      error: null,
      rule: ''
    },
    propertyType: {
      value: '',
      error: null,
      rule: ''
    },
    dealType: {
      value: '',
      error: null,
      rule: ''
    },
    aptNumber: {
      value: '',
      error: null,
      rule: ''
    },
    __isAddressInCorrectFormat__: {
      value: true,
      error: null,
      rule: 'accepted',
      message: 'Invalid Address Format',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export abstract class PropertyFormStore extends FormStore {
  private globalStore: GlobalStore;
  abstract savePropertyInformation(data: any);
  abstract editPropertyInformation(data: any);
  abstract lookupProperty(data: any);
  abstract saveMultiplePropertyInformation(data: any);

  constructor(globalStore) {
    super(form, globalStore);

    makeObservable(this, {
      savePropertyInformation: flow,
      editPropertyInformation: flow,
      lookupProperty: flow,
      saveMultiplePropertyInformation: flow,
    });
  }

  generatePropertyInformationJSON(borrowerId: string, verifiedTrackRecordsId?: number, isVerified?: boolean, recordSourceType?: string, verificationSource?: string, links?: any) {
    this.onFieldChange('purchaseDate', this.form.fields.purchaseDate.value ?
      this.formatDate(this.form.fields.purchaseDate.value) : this.form.fields.purchaseDate.value);
    this.onFieldChange('saleDate', this.form.fields.saleDate.value ?
      this.formatDate(this.form.fields.saleDate.value) : this.form.fields.saleDate.value);
    this.form.fields.purchaseAddress.value = this.form.fields.purchaseAddress.value.split(",")[0];
    const formValues = this.getFormValues();
    return {
      borrowerId: borrowerId, verifiedTrackRecordsId: verifiedTrackRecordsId, isVerified: isVerified ? isVerified : false, isVerificationRequested: false, recordSourceType: recordSourceType ? recordSourceType : TRACK_RECORD_USER_INPUTTED, verificationSource, links,
      purchaseSecondaryAddress: formValues?.aptNumber,
      ...formValues,
    };
  }

  generateTitleLookupJSON(borrowerId: string, row: any) {
    return {
      borrowerId: borrowerId,
      isVerified: false,
      isVerificationRequested: false,
      recordSourceType: TRACK_RECORD_MLS_DATA,
      entityName: row.seller,
      mortgageAmount: row.loanAmount,
      lenderName: row.lender,
      purchaseDate: row.purchaseDate,
      propertyType: row.propertyType,
      saleDate: row.sellDate,
      salePrice: row.sellPrice,
      soldTo: row.buyer,
      purchasedFrom: row.seller,
      purchaseAddress: this.form.fields.purchaseAddress.value.split(",")[0],
      purchaseCity: this.form.fields.purchaseCity.value,
      purchaseState: this.form.fields.purchaseState.value,
      purchaseZipcode: this.form.fields.purchaseZipcode.value,
    };
  }
  generateTitleLookupForMergedRecordsJSON(borrowerId: string, row: any) {
    return {
      borrowerId: borrowerId,
      isVerified: false,
      isVerificationRequested: false,
      recordSourceType: TRACK_RECORD_MLS_DATA,
      entityName: row.seller,
      mortgageAmount: row.loanAmount,
      lenderName: row.lender,
      purchaseDate: row.purchaseDate,
      purchasePrice: row.purchasePrice,
      propertyType: row.propertyType,
      saleDate: row.sellDate,
      salePrice: row.sellPrice,
      soldTo: row.buyer,
      purchasedFrom: row.seller,
      purchaseAddress: this.form.fields.purchaseAddress.value.split(",")[0],
      purchaseCity: this.form.fields.purchaseCity.value,
      purchaseState: this.form.fields.purchaseState.value,
      purchaseZipcode: this.form.fields.purchaseZipcode.value,
    };
  }

  saveAddressField(address: string, addressDetails, geometryDetails) {
    const parsedAddress = parseAddress(
      address,
      addressDetails,
      geometryDetails
    );
    const streetAddress = parsedAddress.street_number === null ? parsedAddress.street : parsedAddress.street_number + " " + parsedAddress.street;
    this.onFieldChange('purchaseAddress', streetAddress);
    this.onFieldChange('purchaseCity', parsedAddress.city);
    this.onFieldChange('purchaseState', parsedAddress.state);
    this.onFieldChange('purchaseZipcode', parsedAddress.zip);
    this.onFieldChange('streetName', parsedAddress.street);
    this.onFieldChange('streetNumber', parsedAddress.street_number);
    this.onFieldChange('aptNumber', parsedAddress.aptNumber);

    const addressInCorrectFormat = parsedAddress.street !== null && parsedAddress.city !== null && parsedAddress.state !== null && parsedAddress.zip !== null;
    this.onFieldChange('__isAddressInCorrectFormat__', addressInCorrectFormat);
  }
  handleAddressChange(event) {
    const { value } = event?.target || {};
    this.saveAddressField(value, null, null);
  }
  handleChangeAptNumber(aptNumber: string) {
    this.onFieldChange('aptNumber', aptNumber);
  }

  formatDate = (date) => {
    return format(new Date(date), 'MM/dd/yyyy');
  };

  resetForm = () => {
    const initialForm = {
      entityName: { value: '', error: null, rule: 'required' },
      soldTo: { value: '', error: null, rule: '' },
      purchaseAddress: { value: '', error: null, rule: 'required' },
      purchaseCity: { value: '', error: null, rule: '' },
      purchaseState: { value: '', error: null, rule: '' },
      purchaseZipcode: { value: '', error: null, rule: '' },
      purchaseDate: { value: '', error: null, rule: '', attribute: 'Purchase Date' },
      purchasePrice: { value: '', error: null, rule: '' },
      propertyType: { value: '', error: null, rule: '' },
      dealType: { value: '', error: null, rule: '' },
      mortgageAmount: { value: '', error: null, rule: '' },
      purchasedFrom: { value: '', error: null, rule: '' },
      lenderName: { value: '', error: null, rule: '' },
      saleDate: { value: '', error: null, rule: '' },
      salePrice: { value: '', error: null, rule: '' },
      rehabBudget: { value: '', error: null, rule: '' },
      exitStrategy: { value: '', error: null, rule: '' },
      streetName: { value: '', error: null, rule: '' },
      streetNumber: { value: '', error: null, rule: '' },
      unitNumber: { value: '', error: null, rule: '' },
    };

    Object.keys(initialForm).forEach((field) => {
      this.form.fields[field].value = initialForm[field].value;
      this.form.fields[field].error = initialForm[field].error;
    });
  }

  fillForm(data: any) {
    this.form.fields.entityName.value = data.entityName;
    this.form.fields.soldTo.value = data.soldTo;
    this.form.fields.purchaseAddress.value = data.purchaseAddress;
    this.form.fields.purchaseCity.value = data.purchaseCity;
    this.form.fields.purchaseState.value = data.purchaseState;
    this.form.fields.purchaseZipcode.value = data.purchaseZipcode;
    this.form.fields.purchaseDate.value = data.purchaseDate;
    this.form.fields.purchasePrice.value = data.purchasePrice;
    this.form.fields.mortgageAmount.value = data.mortgageAmount;
    this.form.fields.purchasedFrom.value = data.purchasedFrom;
    this.form.fields.lenderName.value = data.lenderName;
    this.form.fields.saleDate.value = data.saleDate;
    this.form.fields.salePrice.value = data.salePrice;
    this.form.fields.rehabBudget.value = data.rehabBudget;
    this.form.fields.exitStrategy.value = data.exitStrategy;
    this.form.fields.propertyType.value = data.propertyType;
    this.form.fields.dealType.value = data.dealType;
  }
}