import { ApiResponse, GlobalStore } from '@roc/feature-app-core';
import { computed, flow, makeObservable, override } from 'mobx';
import { BorrowerLoanSubmissionService } from '../../services/borrowerLoanSubmissionService';
import { isNil, segmentAnalytics, SegmentTrackerEvent } from '@roc/feature-utils';
import { LoanService, LoanSubmissionStep } from '@roc/feature-loans';
import { BaseLoanTypeStore } from '../baseLoanTypeStore';
import { SinglePropertyBorrowerInformationStore } from './singlePropertyBorrowerInformationStore';
import { BorrowerEntityInformationStore } from '../borrowerEntityInformationStore';
import { SinglePropertyPropertiesStore } from './singlePropertyPropertiesStore';
import { mapLoanApplicationPayload } from 'libs/feature-borrower-loan-submission/src/modules/newLoanApplication/utils/loanApplicationUtils';
import { OneToolService } from 'libs/feature-one-tool/src/quote/services/oneToolService';
import { getQuoteFromBorrowerLoanApplication } from 'libs/feature-loans/src/loanSubmission/stores/common/mapLoanToQuoteHelper';

export class PublicSinglePropertyStore extends BaseLoanTypeStore {
  private borrowerLoanSubmissionService: BorrowerLoanSubmissionService;
  private oneToolService: OneToolService;

  constructor(globalStore: GlobalStore) {
    super(globalStore);
    this.isPublic = true;
    this.borrowerLoanSubmissionService = new BorrowerLoanSubmissionService();
    this.oneToolService = new OneToolService();

    this.borrowerInformationStore = new SinglePropertyBorrowerInformationStore(
      globalStore
    );
    this.borrowerEntityInformationStore = new BorrowerEntityInformationStore(
      globalStore,
      this.borrowerInformationStore
    );
    this.propertiesStore = new SinglePropertyPropertiesStore(
      globalStore,
      this.borrowerInformationStore
    );

    this.setDefaults();

    makeObservable(this, {
      submitLoan: override,
      submitPublicLoanApplication: flow,
      sendLoanSubmissionErrorEmail: flow,
      saveLoanQuoteForApplication: flow,
    });
  }

  *submitLoan() {
    yield this.submitPublicLoanApplication();
  }

  *submitPublicLoanApplication() {
    try {
      this.newPublicLoanId = null;
      const data = mapLoanApplicationPayload(
        this.loanSubtype,
        this.borrowerEntityInformationStore.mainEntity,
        this.borrowerInformationStore.borrowers,
        this.propertiesStore.getProperties()
      );
      console.log('publicLoanApplicationJson', data);
      const response: ApiResponse = yield this.borrowerLoanSubmissionService.submitPublicTermLoaApplication(
        data,
        this.globalStore.portalConfiguration.host,
        this.borrowerInformationStore.mainBorrower.emailAddress
      );
      if (response.data?.data?.success === false) {
      } else {
        this.newPublicLoanId = response.data?.data?.loanId;
        segmentAnalytics.trackEvent({
          name: SegmentTrackerEvent.PUBLIC_SUBMISSION,
          userName: this.borrowerInformationStore.mainBorrower.emailAddress,
        });
      }
    } catch (error) {
      console.log(error);
      this.sendLoanSubmissionErrorEmail(
        error?.error?.response?.data?.error?.message ||
        error?.error?.message ||
        error
      );
    } finally {
      this.setActiveStep(LoanSubmissionStep.SUCCESS);
      this.saved = true;
    }
    // this.saveLoanQuoteForApplication(this.loanSubtype,
    //   this.borrowerEntityInformationStore.mainEntity,
    //   this.borrowerInformationStore.borrowers,
    //   this.propertiesStore.getProperties());
  }

  sendLoanSubmissionErrorEmail(error: string) {
    this.borrowerLoanSubmissionService.sendErrorEmail(
      'Public Bridge Loan Submission Failed',
      `Error occured while submitting ${this.loanType} (${this.loanSubtype}) loan`,
      error || 'Error occured when submitting loan',
      this.globalStore.portalConfiguration.id
    );
  }

  *saveLoanQuoteForApplication(loanSubtype, entity, borrowers, properties) {
    try {
      if (!isNil(this.newPublicLoanId)) {
        const quoteData = {
          ...getQuoteFromBorrowerLoanApplication(this.newPublicLoanId, loanSubtype, entity, borrowers, properties),
        };
        const response = yield this.oneToolService.savePublicQuote(quoteData, this.borrowerInformationStore.mainBorrower.emailAddress);
        console.log('Got quote with id', response?.data?.data?.quoteId)
      }
      else {
        console.log('Skipping saveLoanQuote for public borrower application because loanId is null');
      }
    }
    catch (error) {
      console.log('Error while saving public borrower application as loan quote');
      console.log(error);
    }
  }
}
