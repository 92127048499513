import { flow, makeObservable } from 'mobx';
import { ApiResponse, GlobalStore } from '@roc/feature-app-core';
import { BILL_TRANSACTION, CounterPartyExpensesTab } from '../types/types';
import { SpecialServicingBaseStore } from './specialServicingBaseStore';
import { downloadDocument } from '@roc/feature-utils';

export class ReceivablesBillsStore extends SpecialServicingBaseStore {
  constructor(globalStore: GlobalStore) {
    super(globalStore);
    this.tab = CounterPartyExpensesTab.BILL_RECEIVABLE;
    makeObservable(this, {
      fetchEntityNames: flow,
      performAction: flow,
      downloadBillDocuments: flow,
      exportBillInvoicesData: flow,
      updateComments: flow,
    });
  };

  *fetchEntityNames() {
    try {
      const response: ApiResponse = yield this.service.getInvestorNames();
      this.entityList = response.data.data.map(value => ({
        label: value.name,
        value: value.companyId
      }));
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching investor names.',
      });
    }
  };


  reset() {
    this.tab = CounterPartyExpensesTab.BILL_RECEIVABLE;
    this.counterPartyExpenseGridStore.reset();
  };

  *downloadBillDocuments(servicingBillId: number) {
    try {
      const response: ApiResponse = yield this.service.downloadBillDocuments(servicingBillId);
      downloadDocument(response.data, response.headers, 'download', `${BILL_TRANSACTION}_DOCUMENT_BUNDLE.zip`);
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while downloading servicing bill document bundle.',
      });
    }
  };

  *exportBillInvoicesData(servicingBillId: string) {
    try {
      const response: ApiResponse = yield this.service.exportServicingDashboardData(
        BILL_TRANSACTION,
        servicingBillId,
        null,
        null
      );
      downloadDocument(response.data, response.headers, 'download', `${BILL_TRANSACTION}_REPORT.xlsx`);
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while exporting special servicing dashboard data.',
      });
    }
  };

  performActions(status: string) {
    throw Error('Not Implemented!');
  }

  *performAction(id: number, status: string) {
    try {
      const response = yield this.service.performReceivableBillsAction(id, status);
      if (response.data.status === 'OK') {
        this.globalStore.notificationStore.showSuccessNotification({
          message: 'Status updated successfully.'
        });
      }
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while updating status.',
      });
    }
  }

  *updateComments(id: number, comments: string) {
    try {
      const response = yield this.service.updateBillsComments(id, comments);
      if (response.data.status === 'OK') {
        this.globalStore.notificationStore.showSuccessNotification({
          message: 'Comments updated successfully.'
        });
      }
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while updating comments.',
      });
    }
  }

}
