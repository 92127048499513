import React, { useEffect } from 'react';
import { Grid, Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';

import { SelectBorrowers, SelectBorrowerMode, useBorrowersRoutes } from '@roc/feature-borrowers';
import { useBaseStore } from '@roc/feature-app-core';
import { ProposalsDetailsStore } from '../../stores';
import { BorrowerFormProposal } from './borrowerForm';

const useStyles = makeStyles(theme =>
  createStyles({
    fieldSpacing: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    button: {
      marginRight: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    rowSpacing: {
      marginBottom: theme.spacing(2),
    },
    cardSpacing: {
      paddingRight: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  })
);

interface BorrowerInformationProps {
  store: ProposalsDetailsStore;
}

const BorrowerInformation = ({ store }: BorrowerInformationProps) => {
  const { globalStore } = useBaseStore();
  const { isNewEntity } = store;
  const { selectBorrowersStore } = store;
  const isMultiple = true;
  const { borrowersRoutesConfig } = useBorrowersRoutes();

  let modes = [SelectBorrowerMode.EXISTING];
  if (globalStore.userFeatures?.addNewBorrowerDuringSubmission) {
    modes = [...modes, SelectBorrowerMode.NEW];
  }
  useEffect(() => {
    store.selectBorrowersStore.canRemoveSelectedBorrowers = true;
  }, []);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4">Borrower information</Typography>
          <br />
          <Typography variant="body1">
            {isNewEntity
              ? 'Please add the borrowers of this loan. '
              : 'Please review the borrowers of this loan. '}
            <>
              To add a new borrower, please navigate to
              <Link
                href={borrowersRoutesConfig.borrowers.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span> View Borrowers.</span>
              </Link>
            </>
          </Typography>
        </Grid>
      </Grid>
      <br />

      <Grid container spacing={2}>
        <SelectBorrowers
          multiple={isMultiple}
          modes={modes}
          store={store.selectBorrowersStore}
          borrowerForm={borrowerFormStore => (
            <BorrowerFormProposal form={borrowerFormStore} selectBorrowersStore={selectBorrowersStore} store={store} ></BorrowerFormProposal>
          )}
          borrowerCardDisplayFields={{
            fullName: true,
            ficoProvidedAtOrigination: true,
            experience: true,
            netWorth: true,
          }}
        />
      </Grid>
    </>
  );
};

export default observer(BorrowerInformation);
