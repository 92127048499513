import { Grid } from '@material-ui/core';
import { RadioField } from '@roc/ui';
import { observer } from 'mobx-react-lite';
import TitleAgentStore from '../../../stores/documentForms/titleAgent/titleAgentStore';
import TitleAgentWimbaForm from './titleAgentWimbaForm';
import TitleAgentOtherForm from './titleAgentOtherForm';
import { useEffect, useState } from 'react';

interface TitleAgentProps {
  store: TitleAgentStore;
}

const TitleAgent = observer((props: TitleAgentProps) => {
  const { store } = props;
  const [showOtherBeforeSubmitting, setShowOtherBeforeSubmitting] = useState<boolean>();

  useEffect(() => {
    setShowOtherBeforeSubmitting(store.isWimba);
  }, []);

  const disabled = !store.canEdit || store.isWimba;
  
  return (
    <Grid container spacing={3}>
      {((store.showWimbaOption && store.isWimba) || showOtherBeforeSubmitting) &&
        <Grid item xs={12}>
          <RadioField
            testId="isWimba"
            value={store.isWimba}
            onChange={val => { store.setIsWimba(val === 'true'); setShowOtherBeforeSubmitting(true)} }
            disabled={!store.canEdit}
            options={[
              { label: 'Wimba', value: true },
              { label: 'Other', value: false },
            ]}
            row
          />
        </Grid>
      }
      {store.isWimba?
        <TitleAgentWimbaForm store={store.titleAgentWimbaFormStore} disabled = {disabled}/>:
        <TitleAgentOtherForm store={store.titleAgentOtherFormStore} disabled = {disabled}/>
      }
    </Grid>
  );
});

export default TitleAgent;
