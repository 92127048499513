import { FormStore } from '@roc/feature-app-core';
import { GlobalStore } from '@roc/feature-app-core';
import { generateUUID } from '@roc/feature-utils';
import { action, computed, makeObservable } from 'mobx';
import { LoanEconomicsStore } from './loanEconomicsStore';

const feeForm = {
  fields: {
    name: {
      value: '',
      error: null,
      rule: '',
    },
    usePercentage: {
      value: false,
      error: null,
      rule: '',
    },
    capitalProviderPoints: {
      value: 0,
      error: null,
      rule: '',
    },
    capitalProviderAmount: {
      value: 0,
      error: null,
      rule: '',
    },
    lenderPoints: {
      value: 0,
      error: null,
      rule: '',
    },
    lenderAmount: {
      value: 0,
      error: null,
      rule: '',
    },
  },
  meta: {
    key: null,
    isValid: false,
    error: null,
  },
};
export class FeeFormStore extends FormStore {
  private globalStore: GlobalStore;
  private loanEconomicsStore: LoanEconomicsStore;

  public key = generateUUID();

  constructor(
    globalStore: GlobalStore,
    loanEconomicsStore: LoanEconomicsStore
  ) {
    super(feeForm, globalStore);
    this.globalStore = globalStore;
    this.loanEconomicsStore = loanEconomicsStore;

    makeObservable(this, {
      toggleTypeOfValue: action,
      getFeeValues: action,
      isEmpty: computed,
      disabledForCapitalProvider: computed,
    });
    this.prepopulateNewFee();
  }

  prepopulateNewFee() {
    this.form.meta.key = generateUUID();
  }
  toggleTypeOfValue() {
    const values = this.getFeeValues();
    this.onFieldChange('usePercentage', !values.usePercentage);
    this.onFieldChange('lenderAmount', values.lenderAmount);
    this.onFieldChange('capitalProviderAmount', values.capitalProviderAmount);
    this.onFieldChange(
      'lenderPoints',
      parseFloat(values.lenderPoints.toFixed(2))
    );
    this.onFieldChange(
      'capitalProviderPoints',
      parseFloat(values.capitalProviderPoints.toFixed(2))
    );
  }

  getFeeValues() {
    const values = this.getFormValues();
    const name = values.name;
    const totalLoanAmount = this.loanEconomicsStore.calculatedFields
      .totalLoanAmount;
    const usePercentage = values.usePercentage;

    let lenderAmount = values.lenderAmount || 0;
    let capitalProviderAmount = values.capitalProviderAmount || 0;
    let lenderPoints = values.lenderPoints || 0;
    let capitalProviderPoints = values.capitalProviderPoints || 0;

    if (usePercentage) {
      lenderAmount = (lenderPoints / 100) * totalLoanAmount;
      capitalProviderAmount = (capitalProviderPoints / 100) * totalLoanAmount;
    } else {
      lenderPoints = (lenderAmount / totalLoanAmount) * 100;
      capitalProviderPoints = (capitalProviderAmount / totalLoanAmount) * 100;
    }

    return {
      name,
      usePercentage,
      lenderAmount,
      capitalProviderAmount,
      lenderPoints,
      capitalProviderPoints,
    };
  }

  get isEmpty() {
    const values = this.getFeeValues();
    return !values.name;
  }

  get disabledForCapitalProvider() {
    return !this.globalStore?.lenderInfo?.isInternal;
  }
}
