import {
  useMiscellaneousRoutes,
} from '@roc/feature-app-core';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';
import {
  useHistory,
  useParams,
} from 'react-router';
import { LoanApplicationDetailsV2 } from './loanApplicationDetailsV2';
import { useLoanStore } from '../hooks/useLoanStore';

export const LoanApplicationDetails = observer(() => {
  const { loanId } = useParams<{ loanId: string }>();
  const { loanStore } = useLoanStore();
  const { push } = useHistory();
  const {
    miscellaneousRoutesConfig,
  } = useMiscellaneousRoutes();

  useEffect(() => {
    if (!loanStore.isLoanDetailsSuccess) {
      push(miscellaneousRoutesConfig.notFound.url);
    }
  }, [loanStore.isLoanDetailsSuccess]);

  useEffect(() => {
    loanStore.fetchLoanDetails(loanId);
  }, [
    loanStore,
    loanId,
  ]);

  if (!loanStore.loanDetails) {
    return <></>;
  }

  return (
    <LoanApplicationDetailsV2 />
  );
});