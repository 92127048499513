import {
  Badge,
  Box,
  Chip,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import {
  GridStore,
  InfiniteScrollGrid
} from '@roc/feature-app-core';
import { PORTAL_COLORS } from '@roc/feature-utils';
import {
  LinkColumn, SelectFilterComponent,
  SelectFloatingFilterComponent, StatusChip,
  StatusType, TextColumn, FastForwardIcon, tooltipHeaderTemplate
} from '@roc/ui';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { useUserStore } from '@roc/feature-app-core';
import { formatCurrency } from '@roc/ui/utils';
import { GridColumnProps } from '@roc/client-portal-shared/components';
import { useLoanStore } from '@roc/feature-loans';
import { useRoutes } from '../../hooks/useRoutes';
const usdAmount: any = params => {
  return params.value && formatCurrency(Number(params.value));
};
const noOpComparator = () => 0;
export const StyledBadge = withStyles(theme => ({
  badge: {
    right: -12,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
    fontSize: '11px',
    backgroundColor: PORTAL_COLORS.unreadMessages,
    color: 'white',
    fontWeight: 700,
    userSelect: 'none',
  },
}))(Badge);
export const getUnreadMessagesCount = unreadMessages => {
  const { userStore } = useUserStore();
  const allowLoanPricing = userStore.allowLoanPricing;
  if (unreadMessages && allowLoanPricing) {
    return unreadMessages > 99 ? '99+' : unreadMessages;
  }
  return undefined;
};


export interface MyLoansBackOfficeGridProps {
  store: GridStore;
}

export const MyLoansBackOfficeGridByContact = observer((props: MyLoansBackOfficeGridProps) => {
  const { loanStore } = useLoanStore();
  const { loanRoutesConfig } = useRoutes();
  const { userStore } = useUserStore();
  const allowLoanPricing = userStore.allowLoanPricing;
  const frameworkComponents = {
    selectFilter: SelectFilterComponent,
    selectFloatingFilter: SelectFloatingFilterComponent,
    loanIdCellRenderer: params =>
      loanStore.isLoanSubtypeSupported(params.node.data?.loanSubType) ? (
        <StyledBadge
          badgeContent={getUnreadMessagesCount(
            params.node.data?.unreadMessages
          )}
        >
          {LinkColumn({
            ...params,
            url: loanRoutesConfig.loans(params.value).children.dashboard.url,
          })}
        </StyledBadge>
      ) : (
        <StyledBadge
          badgeContent={getUnreadMessagesCount(
            params.node.data?.unreadMessages
          )}
        >
          {TextColumn(params)}
        </StyledBadge>
      ),
    pendingDocumentsCellRenderer: ({ value }) => {
      if (!value) {
        return null;
      }
      return (
        <Box pt={1} display="flex" justifyContent="center">
          <Chip
            size="small"
            label={value}
            style={{
              backgroundColor: PORTAL_COLORS.pendingDocuments,
              color: 'white',
              fontWeight: 700,
            }}
          />
        </Box>
      );
    },
    fastTrackCellRenderer: ({ value }) => {
      if (!value) {
        return null;
      }
      return (<FastForwardIcon value={value} />)
    },
    appraisalReportUploadedCellRenderer: ({ value }) => {
      if (!value) {
        return null;
      }
      return (<Chip
        size="small"
        label={value ? "Yes" : "No"}
        style={{
          backgroundColor: '#89C053',
          color: 'white',
          fontWeight: 700,
        }}
      />)
    },
    dealNameCellRenderer: params => {
      const loanId = params.node.data?.dealid;
      return LinkColumn({
        ...params,
        url: loanRoutesConfig.loans(loanId).children.dashboard.url,
      });
    },
    statusCellRenderer: ({ value }) =>
      value ? (
        <StatusChip label={value} size="small" statusType={StatusType.LOAN} />
      ) : null,
  };

  const checkLoanPricingFlag = (columns) => {
    if (!allowLoanPricing) {
      return columns.filter(c => c.field != "interestEconomics" && c.field != "keyRatios");
    } else {
      return columns;
    }
  }

  const columns: GridColumnProps[] = [
    {
      field: 'loanId',
      headerName: 'Loan Id',
      maxWidth: 110,
      cellRenderer: 'loanIdCellRenderer',
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      pinned: 'left',
      lockPinned: true,
    },
    {
      field: 'dealName',
      headerName: 'Deal Name',
      minWidth: 300,
      cellRenderer: 'dealNameCellRenderer',
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    {
      field: 'lenderName',
      headerName: 'Lender',
      minWidth: 200,
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    {
      field: 'loanSubType',
      headerName: 'Loan Type',
      minWidth: 200,
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      minWidth: 170,
      cellRenderer: 'statusCellRenderer',
      comparator: noOpComparator,
      filter: 'selectFilter',
      floatingFilter: true,
      floatingFilterComponent: 'selectFloatingFilter',
      floatingFilterComponentParams: {
        suppressFilterButton: true,
        options: [
          { label: 'In Underwriting', value: 'IN_UNDERWRITING' },
          { label: 'Agreed', value: 'AGREED' },
          { label: 'Pending', value: 'PENDING' },
          { label: 'Pending Decision', value: 'PENDING_DECISION' },
          { label: 'Pending Term Sheet', value: 'PENDING_TERM_SHEET' },
          { label: 'Prescreen', value: 'PRESCREEN' },
          { label: 'Term Sheet Out', value: 'TERM_SHEET_OUT' },
          { label: 'Unknown', value: 'UNKNOWN' },
          { label: 'Declined', value: 'DECLINED' },
          { label: 'Funded', value: 'FUNDED' },
          { label: 'Lost', value: 'LOST' },
          { label: 'On Hold', value: 'ON_HOLD' },
          { label: 'Paid Off', value: 'PAID_OFF' },
          { label: 'Paid Off Buyout', value: 'PAID_OFF_BUYOUT' },
          { label: 'Retracted', value: 'RETRACTED' },
          { label: 'Unknown', value: 'UNKNOWN' },
        ],
      },
    },
    {
      field: 'loanAmount',
      headerName: 'Amount',
      minWidth: 125,
      valueFormatter: usdAmount,
      comparator: noOpComparator,
    },
    {
      field: 'submissionDate',
      headerName: 'Submission Date',
      minWidth: 200,
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    {
      field: 'closingDate',
      headerName: 'Closing Date',
      minWidth: 200,
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
  ];

  return (
    <>
      <InfiniteScrollGrid
        columns={checkLoanPricingFlag(columns)}
        frameworkComponents={frameworkComponents}
        store={props.store}
      />
    </>
  );
});