import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  Divider,
  Box,
  Grid,
} from '@material-ui/core';
import { yesNoOptionsBoolean } from '@roc/feature-utils';
import { createCurrencyField, createDateField, createRadioField, createTextField } from '@roc/ui';
import { observer } from 'mobx-react';
import { PublicRecordItemFormStore } from '../stores/creditReview/publicRecordItemFormStore';
import { useEffect, useState } from 'react';

interface PublicRecordItemFormProps {
  store: PublicRecordItemFormStore;
}

export const PublicRecordItemForm = observer(({ store }: PublicRecordItemFormProps) => {

  const [isItemFlagged, setIsItemFlagged] = useState('no');

  useEffect(() => {
    store.editRecordItemFlag(isItemFlagged);
  })

  const updateItemFlag = (value) => {
    setIsItemFlagged(value);
    store.editRecordItemFlag(value);
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {createTextField({
          store,
          testId: 'item',
          fieldName: 'item',
          label: 'Item',
        })}
      </Grid>
      <Grid item xs={12}>
        {createTextField({
          store,
          testId: 'itemDescription',
          fieldName: 'itemDescription',
          label: 'Description',
          multiline: true,
          minRows:5
        })}
      </Grid>
      <Grid item xs={12}>
        <FormControl component="fieldset" margin="normal">
          <FormLabel component="legend">Is Item Flagged?</FormLabel>
          <RadioGroup
            row
            value={isItemFlagged}
            onChange={(e) => updateItemFlag(e.target.value)}
          >
            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="no" control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl>
      </Grid>
    </Grid>
  );
});
