import { lighten, makeStyles, Theme } from '@material-ui/core/styles';
import { LoginLogoImg } from '@roc/feature-app-core';
import { PrintableArea } from '@roc/ui';

import { observer } from 'mobx-react';
import { forwardRef, useEffect } from 'react';
import { formatCurrency, formatPercentage, getCompletePropertyAddress } from '@roc/ui/utils';
import { Box, Divider } from '@material-ui/core';
import { QuoteTypeStore } from '../../../stores/quoteTypeStore';
import { DateFormat, formatDate, LoanSubType, REFINANCE } from '@roc/feature-utils';
import { calculateThirdPartyCosts, generateLoanDetailsTableData } from '../utils/summaryUtils';
import { visibility } from 'html2canvas/dist/types/css/property-descriptors/visibility';

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontSize: '0.32in',
    fontFamily: 'sans-serif',
    letterSpacing: '-2px',
    paddingBottom: '2px',
  },
  subtitle: {
    fontSize: '0.25in',
    fontFamily: 'sans-serif',
    paddingBottom: '12px',
  },
  light: {
    color: '#595959',
  },

  valuesTable: {
    borderCollapse: 'collapse',
    ['& td ']: {
      width: '20%',
      paddingLeft: '28px',
      paddingRigth: '28px',
      paddingTop: '8px',
      paddingBottom: '8px',
    },
    ['& tr td:nth-child(1), & tr td:nth-child(3)']: {
      color: '#595959',
    },
    ['& tr td:nth-child(2), & tr td:nth-child(4)']: {
      fontWeight: 'bold',
    },
  },

  closingCostsTable: {
    borderCollapse: 'collapse',
    ['& td ']: {
      paddingLeft: '28px',
      paddingRigth: '28px',
      paddingTop: '8px',
      paddingBottom: '8px',
    },
    ['& tr td:nth-child(1)']: {
      fontWeight: 'normal',
    },
    ['& tr td:nth-child(2)']: {
      color: '#595959',
      fontWeight: 'bold',
    },
    ['& tr td:nth-child(3)']: {
      fontWeight: 'bold',
    },
    ['& tr td:nth-child(4)']: {
      color: '#595959',
      fontWeight: 'bold',
    },
    ['& [data-last=true] td']: {
      borderBottom: '1px solid #595959',
    },
    ['& [data-last=true] td:nth-child(1)']: {
      borderBottom: 0,
    },
  },

  closingCostsTableLine: {
    ['& td']: {
      borderBottom: '1px solid #595959',
    },
    ['& td:nth-child(1)']: {
      borderBottom: 0,
    },
  },

  economicsTable: {
    display: 'flex',
    fontSize: '0.12in',
    ['& > div']: {
      flex: 1,
      width: '40%',
      textWrap: 'nowrap',

      border: '1px solid #656565',
      borderRight: 0,
      textAlign: 'center',
      padding: '20px 10px',
      fontWeight: 'bold',
    },
    ['& > div:last-child']: {
      borderRight: '1px solid #656565',
    },
  },
  loanEconomicsValue: {
    color: 'black',
    paddingTop: '4px',
    fontSize: '0.2in',
    fontWeight: 'bold',
  },

  page: {
    color: 'black',
    fontSize: '0.14in',

    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'verdana, arial, sans-serif',
    lineHeight: '1',
    position: 'relative',

    ['& p']: {
      margin: '1em 0',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  table: {
    borderCollapse: 'collapse',
    ['& td']: {
      borderCollapse: 'collapse',
      padding: '5pt',
      border: '0.25pt solid ' + lighten(theme.palette.primary.dark, 0.5),
      width: '25%',
    },
    ['& th']: {
      color: 'black',
      textAlign: 'left',
      padding: '12px 0px',
    },
    ['& td:first-child']: {
      borderLeft: 0,
    },
    ['& td:last-child']: {
      borderRight: 0,
    },
  },
  tableInvis: {
    borderCollapse: 'collapse',
    ['& td']: {
      borderCollapse: 'collapse',
      padding: '5pt',
      border: 'none',
      width: '25%',
    },
    ['& th']: {
      color: 'black',
      textAlign: 'left',
      padding: '12px 0px',
    },
  },
  thickRow: {
    borderTop: '2pt solid ' + lighten(theme.palette.primary.dark, 0.3),
  },
  header: {
    paddingBottom: '0.1in',
  },
  logoWrapper: {
    paddingRight: '8px',
  },
  logoContainer: {
    margin: 0,
  },
  headerLine: {
    display: 'flex',
    paddingBottom: '16px',
    //borderBottom: '4px solid ' + theme.palette.primary.main,
  },
  footer: {
    display: 'flex',
    flexDirection: 'column',
    justfyContent: 'flex-end',
    paddingTop: '0.2in',
  },

  bold: {
    fontWeight: 'bold',
  },
  sectionTitle: {
    paddingTop: '6px',
    paddingBottom: '6px',
  },
  darkBackground: {
    backgroundColor: theme.palette.primary.light,
    fontWeight: 'bold',
    textAlign: 'right',
    color: 'black',
  },
  note: {
    fontSize: '6pt',
    color: 'rgb(102 102 102)',
  },
  section: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    padding: '4pt 0',
  },
  showOnPrint: {
    display: 'none',
  },
  hideOnPrint: {
    display: 'block',
  },
  print: {
    //A4 size page
    width: '8.27in',
    height: '11.69in',

    //Add paddings
    padding: '0 0.65in',
    ['& $header']: {
      paddingTop: '0.6in',
    },

    ['& $footer']: {
      paddingBottom: '0.6in',
    },

    //Display hidden elements
    ['& $showOnPrint']: {
      display: 'block',
    },
    ['& $hideOnPrint']: {
      display: 'none',
    },
  },
}));

interface Props {
  store: QuoteTypeStore;
  isQuote?: boolean;
}

export const DownloadableQuote = observer(
  forwardRef<any, any>(({ store, isQuote = true }: Props, ref) => {
    const classes = useStyles();

    return (
      <PrintableArea printClass={classes.print} ref={ref}>
        <div className={classes.page}>
          <Header quoteId={store.quoteId} isQuote={isQuote} />
          <Content>
            <SectionHeader>BORROWER INFORMATION</SectionHeader>
            <BorrowerInformation store={store} />
            <div style={{ paddingBottom: '30px' }}></div>
            <SectionHeader>CONDITIONAL TERMS</SectionHeader>
            <ConditionalTerms store={store} isQuote />
            <div style={{ paddingBottom: '30px' }}></div>
          </Content>
          <Footer className={classes.showOnPrint} />
        </div>
        <PropertyInformation store={store} isQuote={isQuote} />
        <div className={classes.page}>
          <Header quoteId={store.quoteId} className={classes.showOnPrint} isQuote={isQuote} />
          <Content>
            <SectionHeader>ESTIMATED CLOSING COSTS</SectionHeader>
            <EstimatedClosingCosts store={store} />
          </Content>
          <Footer />
        </div>
      </PrintableArea>
    );
  })
);

const BorrowerInformation = ({ store }: Props) => {
  const classes = useStyles();
  const borrowers = store.borrowersStore.getBorrowers();
  return (
    <table className={classes.valuesTable}>
      {borrowers.map(borrower => (
        <tr>
          <td>Borrower Name</td>
          <td>{borrower.firstName + ' ' + borrower.lastName}</td>
          <td>Estimated FICO Score</td>
          <td>{borrower.ficoScore}</td>
        </tr>
      ))}
    </table>
  );
};

const ConditionalTerms = ({ store, isQuote }: Props) => {
  const classes = useStyles();
  const property: any = store.propertiesStore.getProperties()?.[0];
  const outputs = store.loanEconomicsStore.outputs;
  const economics = store.loanEconomicsStore.getLoanEconomics();
  const showConstructionHoldback = store.loanSubtype === LoanSubType.GROUND_UP
    || store.loanSubtype === LoanSubType.FIX_AND_FLIP;
  const isStabilizedBridge = store.loanSubtype === LoanSubType.STABILIZED_BRIDGE;

  return (
    <table className={classes.valuesTable}>
      <tr>
        <td>Loan Program</td>
        <td>{store.loanSubtype}</td>
        <td>Loan Purpose</td>
        <td>{property.loanPurpose}</td>
      </tr>
      <tr>
        <td style={{ padding: 0 }} colSpan={4}>
          <Box className={classes.economicsTable}>
            <div>
              <div className={classes.light}>Total Loan Amount</div>
              <div className={classes.loanEconomicsValue}>
                {formatCurrency(outputs.totalLoanAmount)}
              </div>
            </div>
            <div>
              <div className={classes.light}>Interest Rate</div>
              <div className={classes.loanEconomicsValue}>
                {formatPercentage(economics.interestRate)}
              </div>
            </div>
            <div>
              <div className={classes.light}>Duration</div>
              <div className={classes.loanEconomicsValue}>
                {economics.loanTermMonths + ' months'}
              </div>
            </div>
            <div>
              <div className={classes.light}>Initial LTC</div>

              <div className={classes.loanEconomicsValue}>
                {formatPercentage(economics.loanToCost)}
              </div>
            </div>
            <div>
              <div className={classes.light}>Borrower Cash to Close</div>
              <div className={classes.loanEconomicsValue}>
                {formatCurrency(outputs?.estimatedBorrowerCashToClose)}
              </div>
            </div>
          </Box>
        </td>
      </tr>
      <tr>
        <td>{isStabilizedBridge ? 'Loan Amount' : 'Initial Advance'}</td>
        <td>{formatCurrency(outputs.initialLoanAmount)}</td>
        <td>Interest Payment Type</td>
        <td>{store.loanEconomicsStore.interestType}</td>
      </tr>
      <tr>
        {showConstructionHoldback &&
          <>
            <td>Construction Holdback</td>
            <td>{formatCurrency(outputs.constructionHoldback)}</td>
          </>
        }
        <td>{`${economics.interestReserveFinanced ? 'Financed Interest Reserve:' : 'Standard Interest Reserve:'}`}</td>
        <td>{formatCurrency(outputs.interestReserve)}</td>
      </tr>
    </table>
  );
};

const PropertyInformation = ({ store, isQuote }: Props) => {
  const classes = useStyles();
  const properties: any[] = store.propertiesStore.getProperties();
  const showTotalRenovationBudget = store.loanSubtype !== LoanSubType.STABILIZED_BRIDGE;
  const showEstimatedAsIsValue = store.loanSubtype === LoanSubType.STABILIZED_BRIDGE;

  const splitInPages = (array, chunkSize) => {
    if (!array) return [];
    return array.reduce((result, item, index) => {
      const chunkIndex = Math.floor(index / chunkSize);
      if (!result[chunkIndex]) {
        result[chunkIndex] = [];
      }
      result[chunkIndex].push(item);
      return result;
    }, []);
  };

  const propertyPages = splitInPages(properties, 3);
  return (
    <>
      {propertyPages.map((chunk, pageIndex) => (
        <div className={classes.page} key={pageIndex}>
          {pageIndex === 0 && <div className={classes.hideOnPrint}><SectionHeader>PROPERTY INFORMATION</SectionHeader></div>}
          <Header quoteId={store.quoteId} isQuote={isQuote} className={classes.showOnPrint} />
          <Content>
            <Box paddingBottom={2}>
              <div className={classes.showOnPrint}><SectionHeader>PROPERTY INFORMATION</SectionHeader></div>
              {chunk.map(property => (
                <Box paddingBottom={2}>
                  <table className={classes.valuesTable} key={property.address}>
                    <tr>
                      <td>Property Address</td>
                      <td colSpan={3}>{getCompletePropertyAddress(property)}</td>
                    </tr>
                    <tr>
                      {REFINANCE === property.loanPurpose &&
                        <>
                          <td>Purchase Date</td>
                          <td>{property.purchaseDate}</td>
                        </>
                      }
                    </tr>
                    <tr>
                      <td>Purchase Price</td>
                      <td>{formatCurrency(property.purchasePrice || 0)}</td>
                      <td>Property Type</td>
                      <td>{property.propertyType}</td>
                    </tr>
                    <tr>
                      {showTotalRenovationBudget &&
                        <>
                          <td>Total Renovation Budget</td>
                          <td>{formatCurrency(property.renovationBudget || 0)}</td>
                        </>
                      }
                      {showEstimatedAsIsValue &&
                        <>
                          <td>Estimated As-Is Value*</td>
                          <td>{formatCurrency(property.currentMarketValue || 0)}</td>
                        </>
                      }
                    </tr>
                    <tr>
                      {REFINANCE === property.loanPurpose &&
                        <>
                          <td>Completed Reno to Date</td>
                          <td>{formatCurrency(property.constructionRenoSpendToDate || 0)}</td>
                        </>
                      }
                      <td>Estimated ARV*</td>
                      <td>{formatCurrency(property.marketValueAfterCompletion || 0)}</td>
                    </tr>
                    {REFINANCE === property.loanPurpose &&
                      <tr>
                        <td>Estimated Payoff Amount</td>
                        <td>{formatCurrency(property.payoffAmount || 0)}</td>
                      </tr>
                    }
                  </table>
                </Box>
              ))}
            </Box>
          </Content>
          <Footer className={classes.showOnPrint} />
        </div>
      ))}
    </>
  );
};

const EstimatedClosingCosts = ({ store }: Props) => {
  const classes = useStyles();
  const property = store.propertiesStore.getProperties()?.[0];
  const outputs = store.loanEconomicsStore.outputs;

  const thirdPartyCosts = calculateThirdPartyCosts(outputs.totalLoanAmount);
  const feesDetails = generateLoanDetailsTableData(outputs, thirdPartyCosts, property.loanPurpose, store.loanSubtype);

  return (
    <table className={classes.valuesTable}>
      {feesDetails.categories.map(category =>
        category.fees.map((fee, i) => (
          <tr
            key={fee.name}
            className={
              i === category.fees.length - 1
                ? classes.closingCostsTableLine
                : ''
            }
          >
            <td>{i === 0 && category.name}</td>
            <td>{fee.name}</td>
            <td align="right">{fee.amount}</td>
            <td align="right">
              {i === category.fees.length - 1 && category.total}
            </td>
          </tr>
        ))
      )}
      <tr>
        <td></td>
        <td></td>
        <td align="right">TOTAL:</td>
        <td align="right">{feesDetails.total}</td>
      </tr>
    </table>
  );
};

const SectionHeader = ({ children }) => {
  return (
    <Box
      style={{
        fontSize: '20px',
        borderBottom: '1px solid #656565',
        paddingBottom: '2px',
        color: '#656565',
        marginBottom: '20px',
        paddingLeft: '8px',
      }}
    >
      {children}
    </Box>
  );
};

const Header = ({ className = '', quoteId, isQuote }) => {
  const classes = useStyles();
  return (
    <div className={`${classes.header} ${className}`}>
      <div className={classes.headerLine}>
        <div className={classes.logoWrapper}>
          <LoginLogoImg
            containerClassName={classes.logoContainer}
            imgStyle={{ height: '85px' }}
          />
        </div>
        <div style={{ flex: 1 }}>
          <div className={classes.title}>
            CONDITIONAL{' '}
            <span style={{ fontWeight: 'bold', color: '#656565' }}>{isQuote ? 'QUOTE' : 'TERM SHEET'}</span>
          </div>
          <div className={classes.subtitle}>BRIDGE LOAN PROGRAM</div>
        </div>
        <div>
          <Box pb={2}>
            <Box pb={1} className={classes.light} fontWeight="bold">
              Date
            </Box>
            <div style={{ fontWeight: 'bold' }}>
              {formatDate(new Date(), DateFormat.MMDDYYYY)}
            </div>
          </Box>
          <div>
            {isQuote &&
              <>
                <Box pb={1} className={classes.light} fontWeight="bold">
                  Quote ID
                </Box>
                <div style={{ fontWeight: 'bold' }}>
                  {('' + quoteId).toUpperCase()}
                </div>
              </>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

const Footer = ({ className = '' }) => {
  const classes = useStyles();
  return (
    <div className={`${classes.footer} ${className}`}>
      <p className={classes.note}>
        Please note this letter of interest serves to outline the terms of the
        proposed financing of the referenced transaction.This letter is merely a
        general proposal, and is neither a binding offer, nor a contract.
        Borrower understands that no such offer will be forthcoming prior to
        completion of appropriate due diligence and underwriting performed
        and/or contracted by Lender. This proposal does not create any legally
        binding obligations on any party hereto. All properties are subject to
        satisfactory Lender due diligence underwriting including: satisfactory
        appraisal, satisfactory credit review of the borrowing entity and Key
        Principals, and satisfactory review of the property’s market and
        submarket.
      </p>
    </div>
  );
};
const Content = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.content}>{children}</div>;
};
