import React, { useState } from 'react';
import { Grid as MuiGrid, Box, Typography, Chip } from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';
import { Launch } from '@material-ui/icons';
import { formatCurrency } from '@roc/ui/utils';
import { IconButton } from '@roc/ui';
import { observer } from 'mobx-react';
import { DataGrid as Grid } from '@roc/ui';
import { InsuranceDetailsDialog } from './insuranceDetails/insuranceDetailsDialog';

const columns = [
  {
    field: 'quoteType',
    headerName: 'Quote Type',
    minWidth: 260,
  },
  {
    field: 'quoteLastSentDate',
    headerName: 'Quote Last Sent Date',
  },
  {
    field: 'cost',
    headerName: 'Cost',
    cellRenderer: 'currencyCellRenderer',
  },
  {
    field: 'paymentType',
    headerName: 'Payment Type',
  },
  {
    field: 'quoteStatus',
    headerName: 'Quote Status',
  },
  {
    field: 'isDocusignRequired',
    headerName: 'Is Docusign Required?',
    cellRenderer: 'yesNoCellRenderer',
  },
  {
    field: 'docusignCompleted',
    headerName: 'Is Docusign Completed?',
    cellRenderer: 'yesNoCellRenderer',
  },
  {
    field: 'action',
    headerName: '',
    minWidth: 80,
    maxWidth: 80,
    cellRenderer: 'actionCellRenderer',
  },
];

export type ElmsureQuotesGridProps = {
  quotes: any[];
};

export const ElmsureQuotesGrid = observer((props: ElmsureQuotesGridProps) => {
  const { quotes } = props;

  const [insuranceDetailsDialogOpen, setInsuranceDetailsDialogOpen] = useState(
    false
  );
  const [insuranceDetails, setInsuranceDetails] = useState(null);

  const onInsuranceDetailsDialogOpen = insuranceDetails => {
    setInsuranceDetails(insuranceDetails);
    setInsuranceDetailsDialogOpen(true);
  };

  const onInsuranceDetailsDialogClose = () => {
    setInsuranceDetailsDialogOpen(false);
    setInsuranceDetails(null);
  };

  const frameworkComponents = {
    currencyCellRenderer: params => {
      return formatCurrency(params.value);
    },
    reasonCellRenderer: params => {
      return params.value ? params.value.replace(/;/g, ', ') : '';
    },
    yesNoCellRenderer: ({ value }) => {
      if (value == null) {
        return null;
      }

      const color = value ? green : red;
      return (
        <Box pt={1} display="flex" justifyContent="center">
          <Chip
            label={value ? 'Yes' : 'No'}
            style={{
              fontWeight: 0,
              backgroundColor: color[200],
              color: color[900],
            }}
          />
        </Box>
      );
    },
    actionCellRenderer: params => {
      return (
        <Box
          display="flex"
          flex={1}
          pt={1}
          alignSelf="center"
          justifyContent="center"
        >
          <IconButton
            testId="detailsButton"
            onClick={() => onInsuranceDetailsDialogOpen(params.node.data)}
            size="small"
            color="primary"
          >
            <Launch />
          </IconButton>
        </Box>
      );
    },
  };

  return (
    <>
      <MuiGrid container spacing={1}>
        <MuiGrid item xs={12}>
          <Typography color="primary">Elmsure</Typography>
        </MuiGrid>
        <MuiGrid item xs={12}>
          <Grid
            columns={columns}
            rows={quotes}
            frameworkComponents={frameworkComponents}
            domLayout="autoHeight"
          />
        </MuiGrid>
      </MuiGrid>
      <InsuranceDetailsDialog
        open={insuranceDetailsDialogOpen}
        onClose={onInsuranceDetailsDialogClose}
        quote={insuranceDetails}
      />
    </>
  );
});

export default ElmsureQuotesGrid;
