import { Service } from '@roc/client-portal-shared/services';

const url = {
  VALUES: '/nportal/rest/priv/bridgePricer/getValues',
  ALL_LENDER_ID_NAMES: '/nportal/rest/priv/lender/getAllLenderIdNames',
  FLOOR_RATE: '/nportal/rest/priv/bridgePricer/filterFloorBySubmissionDate',
  BORROWER_POINTS_OPTION: '/nportal/rest/priv/bridgePricer/getBorrowerPointsValues',
  BRIDGE_DEAL_NAMES: '/nportal/rest/priv/bridgePricer/getBridgeDealNames',
  LOAN_VALUES: '/nportal/rest/priv/bridgePricer/getBridgeLoanDetail',
}

export class BridgePricerService extends Service {
  getValues(data) {
    return this.post(url.VALUES, data);
  }

  getAllLenderIdNames() {
    return this.get(url.ALL_LENDER_ID_NAMES);
  }

  getRateFloor(data) {
    return this.post(url.FLOOR_RATE, data);
  }

  getBorrowerPointsOptions(lenderId, loanTerm, submissionDate) {
    return this.get(url.BORROWER_POINTS_OPTION, { lenderId, loanTerm, submissionDate });
  }

  getBridgeDealNames(
    page: number,
    pageSize: number,
    sortDir: string,
    sortBy: string,
    filters: object
  ) {
    return this.get(url.BRIDGE_DEAL_NAMES, {
      page,
      pageSize,
      sortDir,
      sortBy,
      filters,
    });
  }

  getLoanValues(loanId) {
    return this.get(`${url.LOAN_VALUES}?id=${loanId}`);
  }
}