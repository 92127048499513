
import { FormControlLabel, Grid, Radio, RadioGroup, Typography } from "@material-ui/core";
import { observer } from "mobx-react";
import { useDrawsStore } from "../hooks";

const PreferredProcessContent = observer(({classes})=>{
  const { pointOfContactStore } = useDrawsStore();
  const formFields = pointOfContactStore.pointOfContactFormStore.form.fields;

  return (
    <Grid item container xs={12} md={12} className={classes}>
      <Grid container direction="column" item>
        <Grid item container direction='row' justifyContent='space-between'>
          <Grid item>
            <Typography variant="h6">
              SELECT PREFERED 'INSPECTION PROCESS'
            </Typography>
          </Grid>
        </Grid>
        <Grid item>
          <Typography variant="caption" color="textSecondary">
            Select your preferred inspection process.
          </Typography>
        </Grid>
      </Grid>
      <Grid container direction="column">
        <Grid item xs={12} sm={12}>
          <RadioGroup value={formFields.inspectionProcess.value} onChange={e => pointOfContactStore.pointOfContactFormStore.onFieldChange('inspectionProcess', e.target.value)}>
            <FormControlLabel value="Snap" control={<Radio />} label="Snap" />
            <FormControlLabel value="Third Party" control={<Radio />} label="Third Party"/>
          </RadioGroup>
        </Grid>
      </Grid>
    </Grid>
  )
});

export default PreferredProcessContent;