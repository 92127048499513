import { GlobalStore } from '@roc/feature-app-core';
import { makeObservable, flow, observable } from 'mobx';
import { AssetManagementDocumentService } from '../services/assetManagementDocumentService';
import { downloadDocument } from '@roc/feature-utils';
import { GENERIC_ERROR_MESSAGE } from '@roc/feature-utils';
import { capitalize } from '@roc/feature-utils';
import { ApiResponse } from '@roc/feature-app-core';

export class AssetManagementDocumentStore {
  private assetManagementDocumentService: AssetManagementDocumentService;
  private globalStore: GlobalStore;
  public documents: any;
  public documentPreview: any = null;

  constructor(globalStore: GlobalStore) {
    this.assetManagementDocumentService = new AssetManagementDocumentService();
    this.globalStore = globalStore;
    this.documents = {};
    this.documentPreview;
    makeObservable(this, {
      fetchDocuments: flow,
      downloadTradeDocument: flow,
      uploadTradeDocument: flow,
      documents: observable,
      previewDocument: flow,
      documentPreview: observable
    });
  }

  get getDisplaySections() {
    const documents = this.documents;
    const displaySections = this.getDisplaySectionsFromDocuments(
      documents
    );
    return Object.keys(displaySections).map(displaySection => ({
      key: displaySection.toUpperCase(),
      name: displaySection,
      ...displaySections[displaySection],
    }));
  }

  private getDisplaySectionsFromDocuments(documents) {
    return Object.keys(documents).reduce((previousValue, sectionName) => {
      const displaySectionName = this.getDisplaySectionName(sectionName);
      const displaySection = previousValue[displaySectionName] || {};
      const sections = displaySection.sections || [];
      const section = this.getSectionFromDocuments(
        sectionName,
        documents[sectionName],
      );

      sections.push(section);
      displaySection.sections = sections;
      previousValue[displaySectionName] = displaySection;

      return previousValue;
    }, {});
  }

  private getDisplaySectionName(name: string) {
    return capitalize(name);
  }

  private getSectionFromDocuments(
    sectionName: string,
    documents: Document[],
  ) {
    const tradeId = this.getDocumentProperty('tradeId', documents[0]);
    const sectionId = this.getDocumentProperty('sectionId', documents[0]);
    return {
      tradeId,
      sectionId,
      sectionName
    };
  }

  private getDocumentProperty(property: string, document: Document) {
    return document ? document[property] : null;
  }

  *downloadTradeDocument(document: any) {
    try {
      const response = yield this.assetManagementDocumentService.downloadDocument(document.tradeDocumentId);
      downloadDocument(
        response?.data,
        response?.headers,
        'download',
        document.originalFileName
      );
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Document downloaded.'
      });
    } catch (err) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE
      });
    }
  }

  *uploadTradeDocument(data: any, document: any) {
    try {
      const formData = new FormData();
      formData.append('file', data);
      yield this.assetManagementDocumentService.uploadDocument(document.tradeDocumentId, formData);
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Document uploaded.'
      });
      this.fetchDocuments(document.tradeId);
    } catch (err) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE
      });
    }
  }

  *previewDocument(document: any) {
    try {
      const response = yield this.assetManagementDocumentService.downloadDocument(document.tradeDocumentId);
      this.documentPreview = {
        title: document.documentName,
        data: response.data,
        headers: response.headers,
      };
    } catch (err) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE
      });
    }
  }

  closeDocumentPreview() {
    this.documentPreview = null;
  }

  *fetchDocuments(tradeId: string) {
    try {
      const response: ApiResponse = yield this.assetManagementDocumentService.getDocuments(tradeId);
      const { data } = response;
      this.documents = JSON.parse(data.data);
    } catch (err) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while getting documents.',
      });
    }
  }

  formatDocument(document: any, editableTrade: boolean): Document {
    return {
      canUploadFile: editableTrade,
      canDownloadFile: true,
      ...document,
      documentName: document.documentName + " - " + document.tradeDocumentId
    };
  }
}