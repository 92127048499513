import { ExpandMore } from '@material-ui/icons';
import { ItemDisplay } from '../../../../components/itemDisplay';
import { ReactNode, useState } from 'react';
import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  SvgIconProps,
} from '@material-ui/core';
import { observer } from 'mobx-react';
import { formatPercentage } from '@roc/ui/utils';
import {
  DetailsTable,
  DetailsTableField,
} from '../../../../components/detailsTable';
import { ViewControls } from '../../../../components/viewControls';
import { RoundedBackgroundIcon } from '../../../../components/roundedBackgroundIcon';
import { BrokerFormStore } from 'libs/feature-one-tool/src/quote/stores/broker/brokerFormStore';
import { ReactComponent as HandShakeIcon } from '../../../../../assets/handShakeIcon.svg';
import { getValueOrDefaultDash } from 'libs/feature-one-tool/src/quote/utils/utils';

interface BrokerInformationAccordionProps {
  store: BrokerFormStore;
  onEdit: () => void;
  showActions?: boolean;
  top?: ReactNode;
  expanded: boolean;
  onExpand: (expanded: boolean) => void;
}

export const BrokerInformationAccordion = observer(
  ({
    store,
    onEdit,
    showActions = true,
    expanded = false,
    onExpand,
  }: BrokerInformationAccordionProps) => {
    const broker = store.getFormValues();

    const brokerTypeOptions = {
      ON_THE_HUD: 'On the HUD',
      OFF_THE_HUD: 'Off the HUD',
    }

    const parseBrokerType = (brokerType) => {
      if (brokerType != null) {
        return brokerTypeOptions[brokerType] || "";
      }
      return "";
    }

    const brokerDetails: DetailsTableField[] = [
      {
        label: 'Broker Type',
        value: getValueOrDefaultDash(parseBrokerType(broker.brokerType)),
        colSpan: 1,
      },
      {
        label: 'Broker Points',
        value: getValueOrDefaultDash(formatPercentage(broker.brokerPoints)),
        colSpan: 1,
      },
      {
        label: 'Company Name ',
        value: getValueOrDefaultDash(broker.companyName),
        colSpan: 1,
      },
      {
        label: 'Company EIN ',
        value: getValueOrDefaultDash(broker.companyEin),
        colSpan: 1,
      },
      {
        label: 'Contact First Name ',
        value: getValueOrDefaultDash(broker.contactFirstName),
        colSpan: 1,
      },
      {
        label: 'Contact Last Name ',
        value: getValueOrDefaultDash(broker.contactLastName),
        colSpan: 1,
      },
      {
        label: 'Contact Email',
        value: getValueOrDefaultDash(broker.contactEmail),
        colSpan: 1,
      },
      {
        label: 'Contact Phone Number',
        value: getValueOrDefaultDash(broker.contactPhoneNumber),
        colSpan: 1,
      },
    ];

    return (
      <Accordion
        expanded={expanded}
        onChange={(e, expanded) => onExpand(expanded)}
        elevation={0}
      >
        <AccordionSummary
          expandIcon={<ExpandMore fontSize="large" />}
          aria-controls={`broker-information-accordion`}
          style={{ padding: '0px' }}
        >
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xs={6}>
              <Grid
                container
                spacing={2}
                justifyContent="flex-start"
                alignItems="center"
              >
                <Grid item alignItems="center" style={{ height: '100%' }}>
                  <RoundedBackgroundIcon
                    Icon={(props: SvgIconProps) => <HandShakeIcon />}
                    iconColor="#6D6D6D"
                  />
                </Grid>
                <Grid item>
                  <ItemDisplay
                    label="Broker Information"
                    value={broker.companyName}
                    alignItems="flex-start"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid
                container
                spacing={3}
                justifyContent="flex-end"
                alignItems="center"
              >
                <Grid item>
                  <ItemDisplay label="Broker EIN" value={broker.companyEin} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            container
            spacing={2}
            alignItems="center"
            style={{ marginLeft: '58px' }}
          >
            {showActions && (
              <Grid item xs={12}>
                <Grid container spacing={2} justifyContent="flex-end">
                  <ViewControls
                    onEditClick={onEdit}
                    removeButtonDisabled={false}
                    showRemoveButton={false}
                  />
                </Grid>
              </Grid>
            )}
            <Grid item xs={12}>
              <DetailsTable fields={brokerDetails} />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  }
);
