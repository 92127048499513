import React, { useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import { AppraisalDetailsGrid } from './components/appraisalDetailsGrid';
import { makeStyles } from '@material-ui/core/styles';
import { Button, StandardDialog } from '@roc/ui';
import PropertyAppraisalCheckboxList from './components/propertyAppraisalCheckboxList';
import { useInternalDelinquencyStore } from '@roc/feature-appraisals';

const useStyles = makeStyles(theme => ({
  container: {
    paddingInline: theme.spacing(1),
    paddingBlock: theme.spacing(2),
  },
  buttonContainer: {
    marginTop: '50px'
  }
}));

const ORDER_TYPES = {
  BPO: 1,
  APPRAISAL: 2
}

export const DelinquencyBpoDashboards = observer(({ loanId, isInternal, isTamariskLender }) => {

  const classes = useStyles();

  const [open, setOpen] = React.useState(false)
  const [orderType, setOrderType] = React.useState(null)
  const [state, setState] = React.useState({});

  const { internalDelinquencyBpoStore } = useInternalDelinquencyStore();

  useEffect(() => {
    internalDelinquencyBpoStore?.refreshLoanDetails(loanId);
  }, [])

  useEffect(() => {
    const properties = internalDelinquencyBpoStore?.loanDetails?.data?.properties || [];
    const lengthValidation = properties?.length === 1;
    const newState = properties.reduce((agg, curr) => ({
      ...agg, [curr.propertyId]: { value: curr.address, checked: lengthValidation ? true : false }
    }), {})
    setState(newState);
  }, [internalDelinquencyBpoStore?.loanDetails])

  useEffect(() => {
    if (internalDelinquencyBpoStore?.isLoading === false) {
      internalDelinquencyBpoStore?.refreshLoanForeclosureValuations(loanId);
      handleClose()
    }
  }, [internalDelinquencyBpoStore?.isLoading])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const currentKey = event.target.name;
    const isChecked = event.target.checked;
    setState({
      ...state, [currentKey]: { value: state[currentKey].value, checked: isChecked },
    });
  };

  const handleClose = () => {
    setOpen(false)
  }

  const getSelectedProperties = () => {
    return Object.keys(state).filter((i) => state[i].checked === true).map(i => Number(i))
  }

  const onSubmitOrder = () => {
    orderType === ORDER_TYPES.BPO ?
      internalDelinquencyBpoStore?.requestBpoOrder(loanId, getSelectedProperties()) :
      internalDelinquencyBpoStore?.requestForeclosureAppraisalOrder(loanId, getSelectedProperties())
  }

  return (
    <Grid container className={classes.container} spacing={1} justifyContent="center">

      <Grid item container direction="row" justifyContent="space-between" xs={11}>
        <Grid item>
          <Typography variant="h5" gutterBottom>Property Valuations</Typography>
        </Grid>
      </Grid>

      <Grid item xs={11} style={{ maxHeight: '70vh', overflow: 'auto' }}>
        <AppraisalDetailsGrid loanId={loanId} />
      </Grid>

      <Grid item container spacing={1} xs={11} justifyContent="flex-end" className={classes.buttonContainer}>
        <Grid item>
          <Button
            variant="contained"
            color="secondary"
            testId="create-order-bpo"
            onClick={() => {
              setOrderType(ORDER_TYPES.BPO)
              setOpen(true)
            }}
          >
            Order BPO
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="secondary"
            testId="create-order-appraisal"
            onClick={() => {
              setOrderType(ORDER_TYPES.APPRAISAL)
              setOpen(true)
            }}
          >
            Order Appraisal
          </Button>
        </Grid>
      </Grid>

      <StandardDialog
        open={open}
        handleClose={handleClose}
        title={`Choose Property to Order ${orderType === ORDER_TYPES.BPO ? "BPO" : "Appraisal"}`}
        maxWidth="sm"
        dialogActions={
          <Button
            variant="contained"
            color="secondary"
            testId="submit-order-bpo"
            onClick={onSubmitOrder}
            disabled={orderType === null}
          >
            Submit
          </Button>
        }
        dialogContent={
          <PropertyAppraisalCheckboxList state={state} handleChange={handleChange} />
        }
      />
    </Grid >
  );
});