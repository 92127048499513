import { PlaidService } from "../services/plaidService";
import { GlobalStore } from "@roc/feature-app-core";
import { makeObservable, observable, flow } from "mobx";

export class PlaidStore {
  globalStore: GlobalStore;
  plaidService: PlaidService;
  linkToken: string;
  borrower: string;
  requestSent: boolean;
  registrationConfirmModal: boolean;
  unlinkModalOpen: boolean;
  selectedAccountId: string;
  isPublic: boolean;
  isAlreadyLinked: boolean;
  urlId: string;
  isDisabledLinked: boolean;

  constructor(globalStore) {
    this.globalStore = globalStore;
    this.isPublic = true;
    this.isAlreadyLinked = false;
    this.plaidService = new PlaidService();
    this.urlId = '';
    this.isDisabledLinked = false;

    makeObservable(this, {
      linkToken: observable,
      requestSent: observable,
      unlinkModalOpen: observable,
      fetchLinkToken: flow,
      registrationConfirmModal: observable
    });
  }

  fetchLinkToken = async () => {
    try {
      const statusResponse = await this.plaidService.getRequestStatus(
        this.urlId
      );
      const status = JSON.parse(statusResponse?.data?.data).requestStatus;
      this.isAlreadyLinked = status === 'Request Completed';
      if (!this.isAlreadyLinked) {
        const response = await this.plaidService.getLinkToken(window.location.href);
        this.linkToken = response.data.link_token;
      }
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: `Error while retrieving link token`,
      });
    }
  };

  exchangeToken = async (publicToken) => {
    try {
      const response = await this.plaidService.storePlaidAccountInformation(publicToken, this.urlId);
      this.globalStore.notificationStore.showSuccessNotification({
        message: `Accounts Linked Successfully`,
      });
      this.isDisabledLinked = true;
      return response;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: `Error while exchanging token`,
      });
    }
  }

  setBorrower = async (encodedParams) => {
    this.borrower = encodedParams;
  }


  setUnlinkModalOpen = (param) => {
    this.unlinkModalOpen = param;
  }

  setAccountId = (accountId) => {
    this.selectedAccountId = accountId;
  }
}
