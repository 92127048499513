import { observer } from "mobx-react";
import { Box, Grid, Theme } from "@material-ui/core";
import { ArrowBackIosOutlined } from "@material-ui/icons";
import { Button } from "@roc/ui";
import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import React from "react";
import { CompToolPhotosStore } from "../../stores";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: '85vh',
      overflow: 'hidden',
    },
    imageFull: {
      width: '90%',
      height: '680px',
    },
    imgContainer: {
      textAlign: 'center'
    },
    box: {
      height: '100%',
      overflow: 'hidden',
      width: '100%'
    }
  })
);

export interface IndividualPhotoProps {
  compToolPhotosStore: CompToolPhotosStore;
  onClose: any;
  closeButtonLabel?: string;
}

export const IndividualPhoto: React.FC<IndividualPhotoProps> = observer(({
  compToolPhotosStore, onClose, closeButtonLabel
}) => {
  const classes = useStyles();
  const { selectedPhoto } = compToolPhotosStore;

  return (
    <Box className={classes.box}>
      <Grid container spacing={1} justifyContent="space-between" className={classes.container}>
        <Grid item xs={12}>
          <Button
            variant="text"
            color="primary"
            testId="backToListing"
            onClick={() => {
              onClose();
            }}
            style={{ fontSize: '17px' }}
            startIcon={<ArrowBackIosOutlined />}
          >
            {closeButtonLabel ? closeButtonLabel : 'Back to Images'}
          </Button>
        </Grid>

        <Grid item xs={12} className={classes.imgContainer}>
          <img
            src={selectedPhoto}
            className={classes.imageFull}
          />
        </Grid>
      </Grid>
    </Box>
  );
});
