import {
  Box,


  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { StandardDialog } from '@roc/ui';

interface DocusignDialogProps {
  title: string;
  open: boolean;
  onClose: () => void;
  isStatusAccepted: boolean;
  iframeUrl: string;
  errorMessage: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    docuSignIframe: {
      border: 0,
      height: 'calc(100vh - 270px)',
      width: '100%',
    },
  })
);

export const DocusignDialog = (props: DocusignDialogProps) => {
  const {
    title,
    open,
    onClose,
    isStatusAccepted,
    iframeUrl,
    errorMessage,
  } = props;
  const classes = useStyles();

  return (
    <StandardDialog
      title={title}
      open={open}
      maxWidth={'lg'}
      handleClose={onClose}
      dialogContent={
        <Box minHeight={200}>
          {!isStatusAccepted && iframeUrl ? (
            <iframe className={classes.docuSignIframe} src={iframeUrl} />
          ) : null}
          {errorMessage && (
            <Typography variant="body1">
              Document sign is not applicable now due to {errorMessage}
            </Typography>
          )}
          {isStatusAccepted && (
            <Typography variant="body1">
              The document sign is already completed.
            </Typography>
          )}
        </Box>
      }
    />
  );
};
