import { GlobalStore, UserStore } from '@roc/feature-app-core';
import { LoanSubType } from '@roc/feature-utils';
import { makeObservable, observable } from 'mobx';
import { BaseBorrowerLoanSubmissionStore } from './baseBorrowerLoanSubmissionStore';
import { BorrowerFixFlipStore } from './bridge/borrowerFixFlipStore';
import { BorrowerSinglePropertyStore } from './singleProperty/borrowerSinglePropertyStore';

export class BorrowerLoanSubmissionStore extends BaseBorrowerLoanSubmissionStore {
  constructor(globalStore: GlobalStore, userStore: UserStore) {
    super(globalStore);
    this.isPublic = false;

    this.fixFlipStore = new BorrowerFixFlipStore(globalStore, userStore);
    this.singlePropertyStore = new BorrowerSinglePropertyStore(
      globalStore,
      userStore
    );
  }
}
