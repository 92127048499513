const LOAN_ID_PATH = ':loanId';
const CONVERSATION_ID_PATH = ':conversationId';
const APPRAISAL_ID_PATH = ':appraisalId';
const PROPOSAL_TOPIC_PATH = ':proposalTopicId';
const LOANS_APPLICATION_HOME_BASE_PATH = 'loan-applications';

export const getCommunicationRoutesConfig = (
  basePath: string,
  baseUrl: string
) => {
  return {
    myConversations: {
      path: `${basePath}/my-conversations`,
      url: `${baseUrl}/my-conversations`,
      documentTitle: `My Conversations`,
      children: {
        myConversation: (conversationId?: string) => ({
          path: `${basePath}/my-conversations/${CONVERSATION_ID_PATH}`,
          url: `${baseUrl}/my-conversations/${conversationId}`,
          documentTitle: `My Conversation - ${conversationId}`,
        }),
        mobileMyConversation: (conversationId?: string) => ({
          path: `${basePath}/mobile-my-conversations/${CONVERSATION_ID_PATH}`,
          url: `${baseUrl}/mobile-my-conversations/${conversationId}`,
          documentTitle: `My Conversation - ${conversationId}`,
        }),
        mobileMentionsConversation: (conversationId?: string) => ({
          path: `${basePath}/mobile-mentions-conversations/${CONVERSATION_ID_PATH}`,
          url: `${baseUrl}/mobile-mentions-conversations/${conversationId}`,
          documentTitle: `Mentions Conversation - ${conversationId}`,
        }),
        mobileUnreadConversation: (conversationId?: string) => ({
          path: `${basePath}/mobile-unread-conversations/${CONVERSATION_ID_PATH}`,
          url: `${baseUrl}/mobile-unread-conversations/${conversationId}`,
          documentTitle: `Unread Conversation - ${conversationId}`,
        }),
      },
    },
    starredConversations: {
      path: `${basePath}/starred-conversations`,
      url: `${baseUrl}/starred-conversations`,
      documentTitle: `Starred Conversations`,
      children: {
        starredConversation: (conversationId?: string) => ({
          path: `${basePath}/starred-conversations/${CONVERSATION_ID_PATH}`,
          url: `${baseUrl}/starred-conversations/${conversationId}`,
          documentTitle: `Starred Conversation - ${conversationId}`,
        }),
      },
    },
    mentionsConversations: {
      path: `${basePath}/mentions-conversations`,
      url: `${baseUrl}/mentions-conversations`,
      documentTitle: `Mentions Conversations`,
      children: {
        mentionsConversation: (conversationId?: string) => ({
          path: `${basePath}/mentions-conversations/${CONVERSATION_ID_PATH}`,
          url: `${baseUrl}/mentions-conversations/${conversationId}`,
          documentTitle: `Mentions Conversation - ${conversationId}`,
        }),
      },
    },
    unreadConversations: {
      path: `${basePath}/unread-conversations`,
      url: `${baseUrl}/unread-conversations`,
      documentTitle: `Unread Conversations`,
      children: {
        unreadConversation: (conversationId?: string) => ({
          path: `${basePath}/unread-conversations/${CONVERSATION_ID_PATH}`,
          url: `${baseUrl}/unread-conversations/${conversationId}`,
          documentTitle: `Unread Conversation - ${conversationId}`,
        }),
      },
    },
    conversations: (loanId?: string) => ({
      path: `${basePath}/loans/${LOAN_ID_PATH}/conversations`,
      url: `${baseUrl}/loans/${loanId}/conversations`,
      documentTitle: `Loan Conversations - ${loanId}`,
      children: {
        conversation: (conversationId?: string) => ({
          path: `${basePath}/loans/${LOAN_ID_PATH}/conversations/${CONVERSATION_ID_PATH}`,
          url: `${baseUrl}/loans/${loanId}/conversations/${conversationId}`,
          documentTitle: `Loan Conversation - ${conversationId}`,
        }),
      },
    }),
    loanApplicationConversations: (loanId?: string) => ({
      path: `${basePath}/${LOANS_APPLICATION_HOME_BASE_PATH}/${LOAN_ID_PATH}/conversations`,
      url: `${baseUrl}/${LOANS_APPLICATION_HOME_BASE_PATH}/${loanId}/conversations`,
      documentTitle: `Loan Application Conversations - ${loanId}`,
      children: {
        conversation: (conversationId?: string) => ({
          path: `${basePath}/${LOANS_APPLICATION_HOME_BASE_PATH}/${LOAN_ID_PATH}/conversations/${CONVERSATION_ID_PATH}`,
          url: `${baseUrl}/${LOANS_APPLICATION_HOME_BASE_PATH}/${loanId}/conversations/${conversationId}`,
          documentTitle: `Loan Application Conversation - ${conversationId}`,
        }),
      },
    }),
    appraisalsConversations: {
      path: `${basePath}/appraisals/conversations`,
      url: `${baseUrl}/appraisals/conversations`,
      documentTitle: `Appraisal Conversation Home`,
      children: {
        conversation: (appraisalId?: string) => ({
          path: `${basePath}/appraisals/conversations/${APPRAISAL_ID_PATH}`,
          url: `${baseUrl}/appraisals/conversations/${appraisalId}`,
          documentTitle: `Appraisal Conversation - ${appraisalId}`,
        }),
      },
    },
    dealRoomConversations: (
      proposalId?: string,
      tab?: string,
      status?: string
    ) => ({
      path: `${basePath}/proposals/:proposalId/:tab/conversations`,
      url: `${baseUrl}/proposals/${proposalId}/${tab}/conversations`,
      documentTitle: `Deal Room Conversation Home`,
      children: {
        conversation: (conversationId?: string) => ({
          path: `${basePath}/proposals/:proposalId/:tab/conversations/${CONVERSATION_ID_PATH}`,
          url: `${baseUrl}/proposals/${proposalId}/${tab}/conversations/${conversationId}`,
          documentTitle: `Deal Room Conversation - ${proposalId}`,
        }),
      },
    }),
    dealRoomTopicsConversations:
    {
      path: `${basePath}/proposalTopic/conversations`,
      url: `${baseUrl}/proposalTopic/conversations`,
      documentTitle: `Deal Room Conversation Home`,
      children: {
        conversation: (conversationId?: string) => ({
          path: `${basePath}/proposalTopic/conversations/${CONVERSATION_ID_PATH}`,
          url: `${baseUrl}/proposalTopic/conversations/${conversationId}`,
          documentTitle: `Deal Room Topic Conversation - ${conversationId}`,
        }),
      },
    },
    techSupportConversations: {
      path: `${basePath}/support/conversations`,
      url: `${baseUrl}/support/conversations`,
      documentTitle: `Support Conversations`,
      children: {
        conversation: (conversationId?: string) => ({
          path: `${basePath}/support/conversations/${CONVERSATION_ID_PATH}`,
          url: `${baseUrl}/support/conversations/${conversationId}`,
          documentTitle: `Support Conversations - ${conversationId}`,
        }),
      },
    },
  };
};
