import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { CheckCircle } from '@material-ui/icons';
import { useLoanStore } from '@roc/feature-loans';
import { LoanSummaryBrokerContacts } from '@roc/feature-loans-shared';
import { isFundedLoan } from '@roc/feature-utils';
import { InformationButton } from '@roc/ui';
import { formatCurrency, formatPercentage } from '@roc/ui/utils';
import { observer } from 'mobx-react';
import { CustomCard, CustomCardDividers, CustomCardRow } from '../customCard';
import {
  LoanDetailsCustomCard,
  LoanDetailsInfoItem,
} from '../loanDetailsCustomCard';

export const InsuranceCard = observer(() => {
  const { loanStore } = useLoanStore();
  const { loanDetails } = loanStore;

  return (
    <CustomCard title="Insurance">
      <CustomCardDividers>
        <CustomCardRow
          label="Replacement Cost"
          currency
          value={loanDetails?.replacementCost}
        />
        <CustomCardRow label="Source" value={loanDetails?.source} />
        <CustomCardRow
          label="Property Portion in FEMA High Hazard Flood Zone?"
          value={
            loanDetails?.calculatedLoanData?.floodZone === 'Yes' ? 'Yes' : 'No'
          }
        />
      </CustomCardDividers>
    </CustomCard>
  );
});
