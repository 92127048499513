import { useEffect, useState } from 'react';
import { ExpandMore } from '@material-ui/icons';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@material-ui/core';
import { TitleDivider } from '../../../components/titleDivider';
import {
  DetailsTable,
  DetailsTableField,
} from '../../../components/detailsTable';
import { observer } from 'mobx-react';
import { QuoteTypeStore } from '../../../stores/quoteTypeStore';
import { formatCurrency } from '@roc/ui/utils';
import { LEVERAGE_EXCEPTION_COLOR } from '../../../utils/constants';
import { isStabilizedBridge } from '@roc/feature-utils';
import { insertIf } from '@roc/feature-utils';

interface LoanDetailsAccordionProps {
  store: QuoteTypeStore;
}

export const LoanDetailsAccordion = observer(
  ({ store }: LoanDetailsAccordionProps) => {
    const [expanded, setExpanded] = useState(true);

    const outputs = store.loanEconomicsStore.outputs;

    useEffect(() => {
      store.loanEconomicsStore.setInterestType()
    }, [store.loanEconomicsStore.totalLoanAmount])

    const loanDetails: DetailsTableField[] = [
      {
        label: isStabilizedBridge(store.loanSubtype) ? 'Loan Amount' : 'Initial Advance',
        value: (
          <Typography
            style={{
              color: store.loanEconomicsStore.enableManualQuote
                ? LEVERAGE_EXCEPTION_COLOR
                : undefined,
            }}
          >
            {formatCurrency(isStabilizedBridge(store.loanSubtype) ? outputs?.totalLoanAmount : outputs?.initialLoanAmount)}
          </Typography>
        ),
        colSpan: 1,
      },
      {
        label: 'Interest Payment Type',
        value: store.loanEconomicsStore.interestType,
        colSpan: 1,
      },
      ...insertIf(!isStabilizedBridge(store.loanSubtype), [{
        label: 'Construction Holdback',
        value: formatCurrency(outputs?.constructionHoldback),
        colSpan: 1,
      }]),
      {
        label: 'Interest Reserve',
        value: `${formatCurrency(outputs?.interestReserve)}`,
        colSpan: !isStabilizedBridge(store.loanSubtype) ? 1 : 2,
      },
    ];

    return (
      <Accordion
        expanded={expanded}
        onChange={(e, expanded) => setExpanded(expanded)}
        elevation={0}
      >
        <AccordionSummary
          expandIcon={<ExpandMore fontSize="large" />}
          aria-controls="loan-details-accordion"
        >
          <TitleDivider title="Loan Details" />
        </AccordionSummary>
        <AccordionDetails>
          <DetailsTable fields={loanDetails} />
        </AccordionDetails>
      </Accordion>
    );
  }
);
