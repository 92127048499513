import { Service } from '@roc/feature-app-core';

const url = {
  //Closing Reviewer
  APPROVE_ADDITIONAL_CLOSING_REVIEW:
    '/api/v1/loan/loanActions/approveAdditionalClosingReview',
  //Insurance Reviewer
  REJECT_INSURANCE_APPROVAL: '/api/v1/loan/loanActions/rejectInsuranceApproval',
  //Attorney
  MARK_AS_FUNDED_WIRE_COMPLETED:
    '/api/v1/loan/loanActions/markAsFundedWireCompleted',
  CHECK_WIRE_REQUIREMENTS:
    '/api/v1/loan/loanActions/requestClosingWire/checkRequirements',
  REQUEST_CLOSING_WIRE: '/api/v1/loan/loanActions/requestClosingWire',
  APPROVE_ENTITY_INFORMATION:
    '/api/v1/loan/loanActions/approveEntityInformation',
  APPROVE_TITLE_INFORMATION: '/api/v1/loan/loanActions/approveTitleInformation',
  CREATE_IN_SNAPDOCS: '/api/v1/loan/loanActions/createInSnapdocs',
  UPLOAD_DOCS_TO_SNAPDOCS: '/api/v1/loan/loanActions/uploadDocsToSnapdocs',
  CHECK_GENERATE_CLOSING_DOCS_REQUIREMENTS:
    '/api/v1/loan/loanActions/generateClosingDocs/checkRequirements',
  GENERATE_CLOSING_DOCS: '/api/v1/loan/loanActions/generateClosingDocs',
  UPDATE_FEDEX_TRACKING_NUMBER:
    '/api/v1/loan/loanActions/updateFedexTrackingNumber',
  REMOVE_PREVIOUS_FROM_CLOSING_DOCUMENTS:
    '/api/v1/loan/loanActions/removePreviousFromClosingDocuments',
  UPDATE_UPLOAD_CLOSING_DOCUMENTS_ANSWERS:
    '/api/v1/loan/loanActions/updateUploadClosingDocumentsAnswers',
  REMOVE_PREVIOUS_FROM_SIGNED_CLOSING_DOCUMENTS:
    '/api/v1/loan/loanActions/removePreviousFromSignedClosingDocuments',
  MARK_TITLE_COMPANY_CONTACTED: '/api/v1/loan/loanActions/markTitleCompanyContacted',
  MARK_CLOSING_DOCUMENTS_SENT_FOR_SIGNING: '/api/v1/loan/loanActions/markClosingDocumentsSentForSigning',
  MARK_CLOSING_STAGE_PENDING_APPROVAL: '/api/v1/loan/loanActions/markClosingStagePendingApproval',
  CHECK_CLOSING_STAGE_ACTIONS: '/api/v1/loan/loanActions/checkClosingStageActions',
};

export class LoanActionsService extends Service {
  approveAdditionalClosingReview(loanId) {
    return this.post(`${url.APPROVE_ADDITIONAL_CLOSING_REVIEW}/${loanId}`, {});
  }

  rejectInsurance(loanId) {
    return this.post(`${url.REJECT_INSURANCE_APPROVAL}/${loanId}`, {});
  }

  markAsFundedWireCompleted(loanId) {
    return this.post(
      `${url.MARK_AS_FUNDED_WIRE_COMPLETED}?loanId=${loanId}`,
      {}
    );
  }

  checkRequirementsForRequestClosingWire(loanId: number) {
    return this.get(`${url.CHECK_WIRE_REQUIREMENTS}?loanId=${loanId}`);
  }

  requestClosingWire(loanId: number) {
    return this.post(`${url.REQUEST_CLOSING_WIRE}?loanId=${loanId}`, {});
  }

  approveEntityInformation(loanId: number) {
    return this.post(`${url.APPROVE_ENTITY_INFORMATION}?loanId=${loanId}`, {});
  }
  approveTitleInformation(loanId: number) {
    return this.post(`${url.APPROVE_TITLE_INFORMATION}?loanId=${loanId}`, {});
  }
  createInSnapdocs(loanId: number) {
    return this.post(`${url.CREATE_IN_SNAPDOCS}?loanId=${loanId}`, {});
  }
  uploadDocsToSnapdocs(loanId: number) {
    return this.post(`${url.UPLOAD_DOCS_TO_SNAPDOCS}?loanId=${loanId}`, {});
  }

  checkRequirementsForGeneratingClosingDocs(loanId: number) {
    return this.get(
      `${url.CHECK_GENERATE_CLOSING_DOCS_REQUIREMENTS}?loanId=${loanId}`
    );
  }

  generateClosingDocs(loanId: number) {
    return this.post(`${url.GENERATE_CLOSING_DOCS}?loanId=${loanId}`, {});
  }

  updateFedexTrackingNumber(loanId: number, fedexTrackingNumber: string) {
    return this.post(`${url.UPDATE_FEDEX_TRACKING_NUMBER}?loanId=${loanId}`, {
      fedexTrackingNumber,
    });
  }

  removePreviousFromClosingDocuments(loanId: number) {
    return this.post(
      `${url.REMOVE_PREVIOUS_FROM_CLOSING_DOCUMENTS}?loanId=${loanId}`,
      {}
    );
  }

  updateUploadClosingDocumentsAnswers(loanId: number, data) {
    return this.post(
      `${url.UPDATE_UPLOAD_CLOSING_DOCUMENTS_ANSWERS}?loanId=${loanId}`,
      data
    );
  }

  removePreviousFromSignedClosingDocuments(loanId: number) {
    return this.post(
      `${url.REMOVE_PREVIOUS_FROM_SIGNED_CLOSING_DOCUMENTS}?loanId=${loanId}`,
      {}
    );
  }

  markTitleCompanyContacted(loanId: number) {
    return this.post(
      `${url.MARK_TITLE_COMPANY_CONTACTED}?loanId=${loanId}`,
      {}
    );
  }

  markClosingDocumentsSentForSigning(loanId: number) {
    return this.post(
      `${url.MARK_CLOSING_DOCUMENTS_SENT_FOR_SIGNING}?loanId=${loanId}`,
      {}
    );
  }

  markClosingStagePendingApproval(loanId: number){
    return this.post(
      `${url.MARK_CLOSING_STAGE_PENDING_APPROVAL}?loanId=${loanId}`,
      {}
    )
  }

  checkClosingStageActions(loanId: number){
    return this.get(`${url.CHECK_CLOSING_STAGE_ACTIONS}?loanId=${loanId}`)
  }
}