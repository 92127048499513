import { GlobalStore, UserStore } from '@roc/feature-app-core';
import { LoanSubType } from '@roc/feature-utils';
import { makeObservable, observable } from 'mobx';
import { BaseBorrowerLoanSubmissionStore } from './baseBorrowerLoanSubmissionStore';
import { BorrowerFixFlipStore } from './bridge/borrowerFixFlipStore';
import { PublicFixFlipStore } from './bridge/publicFixFlipStore';
import { BorrowerSinglePropertyStore } from './singleProperty/borrowerSinglePropertyStore';
import { PublicSinglePropertyStore } from './singleProperty/publicSinglePropertyStore';

export class PublicLoanSubmissionStore extends BaseBorrowerLoanSubmissionStore {
  constructor(globalStore: GlobalStore) {
    super(globalStore);
    this.isPublic = true;

    this.fixFlipStore = new PublicFixFlipStore(globalStore);
    this.singlePropertyStore = new PublicSinglePropertyStore(globalStore);
  }
}
