import { Route } from 'react-router';
import { useRocInternalPortalStore } from '../../../hooks';
import { Page } from '@roc/client-portal-shared/components';
import { TermSheetInternalDialog } from '../termSheetInternalDialog';

export const getTermSheetInternalRoutesConfig = (
  basePath: string,
  baseUrl: string
) => {
  return {
    termSheetDialog: {
      path: `${basePath}/term-sheet-dialog`,
      url: `${baseUrl}/term-sheet-dialog`,
      documentTitle: 'Term Sheet',
    },
  };
};

export const getTermSheetInternalRoutes = (
  basePath: string,
  baseUrl: string
) => {
  const config = getTermSheetInternalRoutesConfig(basePath, baseUrl);
  return {
    termSheetDialog: (
      <Route exact path={config.termSheetDialog.path}>
        <Page routeDefinition={config.termSheetDialog}>
          <TermSheetInternalDialog />
        </Page>
      </Route>
    ),
  };
};
