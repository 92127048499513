import { SendgridService } from '@roc/feature-sendgrid';
import { ApiResponse, FormStore, GlobalStore, GridStore } from '@roc/feature-app-core';
import { action, flow, makeObservable, observable } from 'mobx';

const form = {
  fields: {
    daysInterval: {
      value: '',
      error: null,
      rule: 'required',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};
export class SendgridEmailSettingsStore extends FormStore {
  private globalStore: GlobalStore;
  sendgridService: SendgridService;
  constructor(globalStore) {
    super({ ...form }, globalStore);
    this.globalStore = globalStore;
    this.sendgridService = new SendgridService();

    makeObservable(this, {
      getGeneralEmailSettings: flow,
      updateGeneralEmailSettings: flow,
    });
  }

  *getGeneralEmailSettings() {
    try {
      const response = yield this.sendgridService.getGeneralEmailSettingsValues();
      let daysInterval = response?.data?.data;
      this.form.fields.daysInterval.value = daysInterval;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: "Failed to get general email settings",
      });
    }
  }


  *updateGeneralEmailSettings() {
    try {
      const response = yield this.sendgridService.updateGeneralEmailSettingsValues(this.form.fields.daysInterval.value);
      if (response.data.data === true) {
        this.globalStore.notificationStore.showSuccessNotification({
          message: 'Mail settings updated successfully.',
        });
      }
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: "Failed to update general email settings",
      });
    }
  }
}
