import { observer } from "mobx-react";
import { ComparableProperty } from "../../types/propertyTypes";
import { Box, Grid, Theme } from "@material-ui/core";
import { ArrowBackIosOutlined } from "@material-ui/icons";
import { Button } from "@roc/ui";
import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from "react";
import placeHolderImage from "../../images/Placeholder.svg";
import { IndividualPhotoDialog } from './individualPhotoDialog';
import { useStore } from "@roc/client-portal-shared/hooks";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    scrollableContainer: {
      height: '80vh',
      overflowY: 'auto',
      marginTop: '10px',
    },
    imgContainer: {
      margin: '18px 0px',
      textAlign: 'center'
    },
    imageFull: {
      width: '100%',
      height: '600px',
      objectFit: 'cover',
    },
    imageHalf: {
      width: '100%',
      height: '400px',
      objectFit: 'cover',
    },
    box: {
      height: '100%',
      overflow: 'hidden'
    }
  })
);

export interface AllPhotosScreenProps {
  property: ComparableProperty;
  onClose: any;
}

export const AllPhotosScreen: React.FC<AllPhotosScreenProps> = observer(({
  property, onClose
}) => {
  const classes = useStyles();
  const { compToolStore } = useStore();

  const { compToolPhotosStore } = compToolStore
  const { allPhotosImages } = compToolPhotosStore
  const [imagesCarousel, setImagesCarousel] = useState([])

  useEffect(() => {
    compToolPhotosStore.getCompAllPhotos(property);
  }, []);

  useEffect(() => {
    if (allPhotosImages.length > 0) {
      const propertyImages = property.data.images.length > 0 ? property.data.images.map(image => image.url) : [];
      const imagesComp = allPhotosImages.map(image => image.url);
      const allImages = [...propertyImages, ...imagesComp];
      setImagesCarousel(allImages);
    } else {
      const imagesArray = Array(1).fill(placeHolderImage);
      const imagesComp = property.data.images.length > 0 ? property.data.images.map(image => image.url) : imagesArray;
      setImagesCarousel(imagesComp);
    }
  }, [allPhotosImages]);

  const onError = (event) => {
    event.target.src = placeHolderImage;
  };

  const openIndividualPhoto = (image) => {
    compToolPhotosStore.setSelectedPhoto(image)
    compToolPhotosStore.setOpenIndividualPhoto(true)
  }

  return (
    <>
      <Box className={classes.box}>
        <Grid container spacing={1} justifyContent="space-between">
          <Grid item xs={12}>
            <Button
              variant="text"
              color="primary"
              testId="backToListing"
              onClick={() => {
                onClose();
                compToolStore.setOpenPropertyDialog(true);
              }}
              style={{ fontSize: '17px' }}
              startIcon={<ArrowBackIosOutlined />}
            >
              Back to Listing
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={1} justifyContent="space-between" className={classes.scrollableContainer}>
          {imagesCarousel.map((image, index) => (
            <React.Fragment key={index}>
              {index % 3 === 0 && (
                <Grid item xs={12} className={classes.imgContainer}>
                  <img
                    src={image}
                    className={classes.imageFull}
                    onError={onError}
                    onClick={() => {
                      openIndividualPhoto(image)
                    }}
                  />
                </Grid>
              )}
              {index % 3 === 1 && (
                <Grid item xs={6} className={classes.imgContainer}>
                  <img
                    src={image}
                    className={classes.imageHalf}
                    onError={onError}
                    onClick={() => {
                      openIndividualPhoto(image)
                    }}
                  />
                </Grid>
              )}
              {index % 3 === 2 && (
                <Grid item xs={6} className={classes.imgContainer}>
                  <img
                    src={image}
                    className={classes.imageHalf}
                    onError={onError}
                    onClick={() => {
                      openIndividualPhoto(image)
                    }}
                  />
                </Grid>
              )}
            </React.Fragment>
          ))}
        </Grid>
      </Box>
      <IndividualPhotoDialog open={compToolPhotosStore.openIndividualPhoto} onClose={() => compToolPhotosStore.setOpenIndividualPhoto(false)} compToolPhotosStore={compToolPhotosStore} />
    </>

  );
});
