import { observer } from 'mobx-react-lite';
import { ContactDetailsComponent } from './contactDetailsComponent';
import { useEffect } from 'react';
import { ContactDetailsStore } from '../../stores/contactDetails/contactDetailsStore';

interface IContactDetailsWrapper {
  contactId: number;
  borrowerId?: number;
  companyContactId?: number;
  type: string,
  store: ContactDetailsStore,
  readOnly: boolean
}

export const ContactDetailsWrapper = observer(({ contactId, borrowerId, companyContactId, type, store, readOnly }: IContactDetailsWrapper) => {

  useEffect(() => {
    //Might have to initialize currentRole, emailAddress, connectedWithRingCentral, portalId, userId
    return () => {
      store.reset();
    };
  }, []);

  return (
    <ContactDetailsComponent
      readOnly={readOnly}
      contactId={contactId}
      borrowerId={borrowerId}
      companyContactId={companyContactId}
      type={type}
      contactDetailsStore={store}
    />
  );
});
