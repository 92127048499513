import React, { useEffect, useState } from 'react';
import { Paper, AutocompleteAddressField, NumberFormat, TextField } from '@roc/ui';
import { Layout } from '@roc/client-portal-shared/components';
import { Box, Typography, Grid, FormControlLabel, Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import { FindLendersGrid } from './components/findLendersGrid';
import { useStore } from '../../../hooks';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    padding: theme.spacing(4),
  },
  instructionsContainer: {
    marginBottom: theme.spacing(3),
  },
  note: {
    fontWeight: 'bold',
    color: theme.palette.primary.dark,
  },
  headerContainer: {
    marginBottom: theme.spacing(2),
  },
  gridContainer: {
    marginBottom: theme.spacing(3),
  },
}));

export const FindLenders = observer(() => {
  const [address, setAddress] = useState('');
  const [addressInfo, setAddressInfo] = useState('');
  const [radius, setRadius] = useState(1);
  const [sameState, setSameState] = useState(false);
  const [lastTimeOut, setLastTimeOut] = useState(null);
  const { findLendersStore } = useStore();
  const styles = useStyles();

  const refreshGrid = () => {
    findLendersStore.findLenders(addressInfo, radius, sameState);
  };

  const prepareGridRefresh = () => {
    clearTimeout(lastTimeOut);
    setLastTimeOut(setTimeout(refreshGrid, 2000));
  };


  const onAddressChange = event => {
    const fullInfo = event?.target?.fullInfo;
    setAddress(event?.target?.value);
    setAddressInfo(fullInfo);
    if (fullInfo) {
      findLendersStore.findLenders(fullInfo, radius, sameState);
    }
  };

  useEffect(() => {
    if (addressInfo) {
      prepareGridRefresh();
    }
  }, [radius, sameState]);

  return (
    <Layout title="Find Lenders">
      <Paper className={styles.paper}>
        <Grid container direction='row' className={styles.headerContainer} spacing={1}>
          <Grid item xs={8}>
            <AutocompleteAddressField
              label="Address"
              value={address}
              name="address"
              onChange={onAddressChange}
              fullWidth
              testId="address"
            />
          </Grid>
          {renderKMRadius(radius, setRadius, prepareGridRefresh)}
          {renderStateFilter(sameState, setSameState, prepareGridRefresh)}
        </Grid>
        <Box className={styles.gridContainer}>
          <FindLendersGrid />
        </Box>
      </Paper>
    </Layout>
  );
});

const renderStateFilter = (sameState: boolean, setSameState: (value: boolean) => void, prepareGridRefresh: () => void) => {
  return <Grid item container xs={2} justifyContent="center" alignItems="flex-end">
    <Grid item>
      <FormControlLabel
        control={
          <Checkbox
            checked={sameState}
            onChange={e => {
              setSameState(e.target.checked);
              prepareGridRefresh();
            }}
            color="primary"
          />
        }
        label={"Filter by State"}
      />

    </Grid>
  </Grid>;
}

const renderKMRadius = (radius: number, setRadius: (value: number) => void, prepareGridRefresh: () => void) => {
  return <Grid item container xs={2} justifyContent="center" alignItems="flex-start">
    <Grid item>
      <NumberFormat
        customInput={TextField}
        variant="outlined"
        label="Radius in KM"
        value={radius}
        onChange={e => {
          setRadius(+e.target.value);
          prepareGridRefresh();
        }}
        error={radius < 1}
        standaloneLabel
        fullWidth
        testId={'radiusInKM'}
      />
    </Grid>
  </Grid>;
}