import { Box } from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import {
  ConfirmationButton,
  DataGrid,
  GridColumnProps,
  LinkColumn,
  Toolbar,
} from '@roc/ui';
import { formatDate } from '@roc/feature-utils';
import { Button, IconButton, FilterButton } from '@roc/ui';
import { filterProps, formatCurrency } from '@roc/ui/utils';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useRentalPricerRoutes } from './hooks/useRentalPricerRoutes';
import { useRentalPricerStore } from './hooks/useRentalPricerStore';
import { Layout } from '@roc/feature-app-core';

const columns: GridColumnProps[] = [
  {
    field: 'rentalPricerId',
    headerName: 'Id',
    maxWidth: 120,
    cellRenderer: 'addressCellRenderer',
    ...filterProps(),
  },
  {
    field: 'address',
    headerName: 'Property Address',
    minWidth: 225,
    cellRenderer: 'addressCellRenderer',
    ...filterProps(),
  },
  {
    field: 'pricerType',
    headerName: 'Rental Quote Type',
    minWidth: 225,
    cellRenderer: 'typeCellRenderer',
    ...filterProps(),
  },
  {
    field: 'createdDate',
    headerName: 'Creation Date',
    minWidth: 225,
    ...filterProps(),
  },
  {
    field: 'lastUpdate',
    headerName: 'Last Update',
    minWidth: 225,
    ...filterProps(),
  },
  {
    field: 'rentalPricerId',
    headerName: 'Actions',
    minWidth: 225,
    cellRenderer: 'actionCellRenderer',
  },
];

export const PricerDashboard = observer(() => {
  const history = useHistory();
  const { rentalPricerRoutesConfig } = useRentalPricerRoutes();
  const { rentalPricerStore } = useRentalPricerStore();
  const { rentalPricerGridStore } = rentalPricerStore;
  const gridStore = rentalPricerGridStore;

  const createNewPricer= () => {
    const url = rentalPricerRoutesConfig.pricerHome.url;
    history.push(url);
  };

  const editSingleProperty = rentalPricerId => {
    const url = rentalPricerRoutesConfig.singlePropertyEdit(rentalPricerId).url;
    history.push(url);
  };

   const editPortfolio = rentalPricerId => {
    const url = rentalPricerRoutesConfig.portfolioEdit(rentalPricerId).url;
    history.push(url);
  };

  const deletePricer = (singlePropertyRentalId) => {
    rentalPricerStore.deletePricer(singlePropertyRentalId);
    rentalPricerGridStore.resetAndFetchGridData()
  }

  useEffect(() => {
    gridStore.resetAndFetchGridData();
  }, []);

  const toolbar = (
    <Toolbar>
      <Box mr={2}>
        <FilterButton
          onToggleFilters={() => gridStore.toggleFilters()}
          onClearFilters={() => gridStore.resetFilters()}
        />
      </Box>
      <Box>
        <Button
          variant="contained"
          size="small"
          onClick={() => createNewPricer()}
          color="primary"
          testId="create-new-rental-pricer"
        >
          Create New
        </Button>
      </Box>
    </Toolbar>
  );

  const frameworkComponents = {
    addressCellRenderer(params) {
      const rentalPricerId = params.node.data?.rentalPricerId;
      return LinkColumn({
        ...params,
        url: params.node.data?.pricerType == 'singleProperty' ? rentalPricerRoutesConfig.singlePropertyEdit(rentalPricerId).url : rentalPricerRoutesConfig.portfolioEdit(rentalPricerId).url,
      });
    },
    typeCellRenderer(params) {
      return params.value && params.value === 'singleProperty' ? 'Single Property' : 'Portfolio';
    },
    dateCellRenderer(params) {
      return params.value
        ? formatDate(params.value, 'MM/dd/yyyy hh:mm:ss')
        : null;
    },
    actionCellRenderer(params) {
      return (
        <PricerActionsColumn
          params={params}
          editSingleProperty={(rentalPricerId) => editSingleProperty(rentalPricerId)}
          editPortfolio={(rentalPricerId) => editPortfolio(rentalPricerId)}
          deletePricer={(rentalPricerId) => deletePricer(rentalPricerId)}
        />
      );
    },
  };

  const onFilterChanged = params => {
    const filterModel = params.api.getFilterModel();
    gridStore.setFilterModel(filterModel);
  };

  const onSortChanged = params => {
    const sortModel = params.api.getSortModel();
    gridStore.setSortModel(sortModel);
  };

  return (
    <Layout title="Rental Quotes Dashboard">
      <DataGrid
        columns={columns}
        rows={gridStore.gridData.data.rows}
        frameworkComponents={frameworkComponents}
        toolbar={toolbar}
        onSortChanged={onSortChanged}
        onFilterChanged={onFilterChanged}
        isLoading={gridStore.isLoading}
        showFilters={gridStore.showFilters}
        sortModel={gridStore.sortModel}
        filterModel={gridStore.filterModel}
        paginationData={gridStore.gridData.meta}
        setPageNumber={gridStore.setPageNumber}
      />
    </Layout>
  );
});


const PricerActionsColumn = observer(({ params, editSingleProperty, editPortfolio, deletePricer }) => {
  const rentalPricerId = params.node.data?.rentalPricerId;
  return (
    <>
      <IconButton
        testId={`edit-pricer-${rentalPricerId}`}
        onClick={() => {
          params.node.data?.pricerType == 'singleProperty' ? editSingleProperty(rentalPricerId) : editPortfolio(rentalPricerId);
        }}
      >
        <Edit color="primary" />
      </IconButton>

      <ConfirmationButton
        testId={`delete-pricer-${rentalPricerId}`}
        onClick={() => deletePricer(rentalPricerId)}
        confirmDialogProps={{
          body: 'Are you sure you want to remove this Rental Quote?',
        }}
      >
        <Delete color="primary" />
      </ConfirmationButton>
    </>
  )
});