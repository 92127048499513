import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { useBaseStore } from '@roc/feature-app-core';
import {
  fundingPreferenceOptions,
  fundingPreferenceOptionsForLender,
  interestStartMechanismOptions,
  YES,
} from '@roc/feature-utils';
import {
  CurrencyField,
  PercentageField,
  SelectField,
  TextField,
} from '@roc/ui';
import {
  createCurrencyField,
  createRadioGroupField,
} from '@roc/ui/componentBuilder';
import FundingPreferenceStore from 'libs/feature-documents/src/documents/stores/documentForms/fundingPreference/fundingPreferenceStore';
import { observer } from 'mobx-react';

const useStyles = makeStyles((theme: Theme) => ({
  formFields: {
    marginTop: '2rem',
  },
  radioField: {
    paddingLeft: '10px',
  },
}));

interface Props {
  store: FundingPreferenceStore;
}

export const LoanPreferencesTab = observer(({ store }: Props) => {
  const classes = useStyles();
  const { globalStore } = useBaseStore();
  const formFields = store.form.fields;
  const primaryPropertyState = formFields.primaryPropertyState.value;
  const internalInvestorAttorney = store.loanStore.loanDetails.internalInvestorAttorney
  const { isClosingAttorney } = globalStore.userFeatures;
  const disabled = !store.canEdit;
  const canAttorneysEdit = store.canAttorneysEditFundingPreference;
  const getFundingPreferenceOptions = globalStore.lenderInfo ? fundingPreferenceOptionsForLender : fundingPreferenceOptions;
  return (
    <div>
      <Grid container direction="row" justify="space-around">
        <Grid item xs={7} className={classes.formFields}>
          <SelectField
            disabled={!canAttorneysEdit}
            standaloneLabel
            label={'Funding Preference'}
            name={'fundingPreference'}
            value={formFields.fundingPreference.value}
            options={getFundingPreferenceOptions}
            variant="outlined"
            fullWidth
            testId="fundingPreference"
            onChange={value => store.onFieldChange('fundingPreference', value)}
          />
        </Grid>
        <Grid item xs={7} className={classes.formFields}>
          <SelectField
            disabled={disabled}
            standaloneLabel
            label={'Interest Start Mechanism'}
            name={'interestStartMechanism'}
            value={formFields.interestStartMechanism.value}
            options={interestStartMechanismOptions}
            variant="outlined"
            fullWidth
            testId="interestStartMechanism"
            onChange={value =>
              store.onFieldChange('interestStartMechanism', value)
            }
          />
        </Grid>
        <Grid item xs={7} className={classes.formFields}>
          <CurrencyField
            disabled
            standaloneLabel
            label="Attorney Fee"
            value={formFields.attorneyFee.value}
            variant="outlined"
            fullWidth
            testId="attorneyFee"
            onChange={(name, value) =>
              store.onFieldChange('attorneyFee', Number(value))
            }
          />
        </Grid>
        {internalInvestorAttorney &&
          <Grid item xs={7} className={classes.formFields}>
            <CurrencyField
              disabled={disabled}
              standaloneLabel
              label="Roc Legal Fee"
              value={formFields.rocLegalReviewFee.value}
              variant="outlined"
              fullWidth
              testId="rocLegalReviewFee"
              onChange={(name, value) =>
                store.onFieldChange('rocLegalReviewFee', Number(value))
              }
            />
          </Grid>
        }
        <Grid item xs={7} className={classes.formFields}>
          <PercentageField
            disabled={disabled}
            standaloneLabel
            label="Default Rate"
            value={formFields.defaultRate.value}
            variant="outlined"
            fullWidth
            testId="defaultRate"
            onChange={(name, value) =>
              store.onFieldChange('defaultRate', Number(value))
            }
          />
        </Grid>
        <Grid item xs={7} className={classes.formFields}>
          <TextField
            disabled={disabled}
            standaloneLabel
            label="Trustee"
            value={formFields.trustee.value}
            variant="outlined"
            fullWidth
            testId="trustee"
            onChange={({ target }) =>
              store.onFieldChange('trustee', target.value)
            }
          />
        </Grid>
        <Grid item xs={7} className={classes.formFields}>
          <TextField
            disabled={disabled}
            standaloneLabel
            label="Trustee Address"
            value={formFields.trusteeAddress.value}
            variant="outlined"
            fullWidth
            testId="trusteeAddress"
            onChange={({ target }) =>
              store.onFieldChange('trusteeAddress', target.value)
            }
          />
        </Grid>
        <Grid item xs={7} className={classes.formFields}>
          <TextField
            disabled
            standaloneLabel
            label="Number of Borrower Entities"
            value={formFields.numberOfEntities.value}
            variant="outlined"
            fullWidth
            testId="numberOfEntities"
            onChange={({ target }) =>
              store.onFieldChange('numberOfEntities', target.value)
            }
          />
        </Grid>
        <Grid item xs={7} className={classes.radioField}>
          <div className={classes.formFields}>
            {createRadioGroupField({
              store,
              fieldName: 'onlineClosingFeeRequired',
              label: 'Online Closing Fees',
              disabled,
              boolean: true,
            })}
          </div>
        </Grid>
        <Grid item xs={7} className={classes.radioField}>
          <div className={classes.formFields}>
            {createRadioGroupField({
              store,
              fieldName: 'addWitnessLines',
              label: 'Add Witness Lines',
              disabled,
              boolean: true,
            })}
          </div>
        </Grid>
        {primaryPropertyState === 'NY' && (
          <Grid item xs={7} className={classes.radioField}>
            <div className={classes.formFields}>
              {createRadioGroupField({
                store,
                fieldName: 'isCema',
                label: 'CEMA',
                disabled,
                boolean: true,
              })}
            </div>
          </Grid>
        )}
        {primaryPropertyState === 'NY' &&
          (formFields.isCema.value === YES ||
            formFields.isCema.value === true) && (
            <Grid item xs={7} className={classes.formFields}>
              {createCurrencyField({
                store,
                testId: 'newMoneyAmount',
                fieldName: 'newMoneyAmount',
                label: 'New Money Amount',
              })}
            </Grid>
          )}
        {primaryPropertyState === 'NY' && (
          <Grid item xs={7} className={classes.radioField}>
            <div className={classes.formFields}>
              {createRadioGroupField({
                store,
                fieldName: 'shouldHaveBuildingDocs',
                label: 'Building Loan',
                disabled,
                boolean: true,
              })}
            </div>
          </Grid>
        )}
      </Grid>
    </div>
  );
});
