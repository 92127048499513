import { Grid } from '@material-ui/core';
import { observer } from 'mobx-react';
import { useStore } from '../../../hooks';
import { createSelectField, createTextField } from '@roc/client-portal-shared/components';
import { statesNames, statesAbbreviation } from '@roc/feature-utils';


export const WireAuthorizationForm = observer(() => {
  const { wireAuthorizationStore } = useStore();
  const stateOptions = statesAbbreviation.map((abbreviation, i) => ({
    value: abbreviation,
    label: statesNames[i],
  }));

  return (
    <>
      <h2 style={{ marginBottom: '10px' }}>Account Information</h2>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          {createTextField({
            label: 'Account Name/Title',
            fieldName: 'accountName',
            testId: 'accountName',
            store: wireAuthorizationStore,
          })}
        </Grid>
        <Grid item xs={12} sm={6}>
          {createTextField({
            label: 'ABA (Fed Wire) Routing Number',
            fieldName: 'routingNumber',
            testId: 'routingNumber',
            store: wireAuthorizationStore,
          })}
        </Grid>
        <Grid item xs={12} sm={6}>
          {createTextField({
            label: 'Account Number',
            fieldName: 'accountNumber',
            testId: 'accountNumber',
            store: wireAuthorizationStore,
          })}
        </Grid>
        <Grid item xs={12} sm={6}>
          {createTextField({
            label: 'Bank Name',
            fieldName: 'bankName',
            testId: 'bankName',
            store: wireAuthorizationStore,
          })}
        </Grid>
        <Grid item xs={12} sm={6}>
          {createTextField({
            label: 'Entity Street Name',
            fieldName: 'streetName',
            testId: 'streetName',
            store: wireAuthorizationStore,
          })}
        </Grid>
        <Grid item xs={12} sm={6}>
          {createTextField({
            label: 'Entity City',
            fieldName: 'city',
            testId: 'city',
            store: wireAuthorizationStore,
          })}
        </Grid>
        <Grid item xs={12} sm={6}>
          {createTextField({
            label: 'Entity Zip Code',
            fieldName: 'zipCode',
            testId: 'zipCode',
            store: wireAuthorizationStore,
          })}
        </Grid>
        <Grid item xs={12} sm={6}>
          {createSelectField({
            store: wireAuthorizationStore,
            testId: 'state',
            fieldName: 'state',
            label: 'Entity State',
            options: stateOptions,
          })}
        </Grid>
      </Grid>
      <h2 style={{ marginTop: '56px', marginBottom: '10px' }}>The following is only applicable if 'For Further Credit' instructions are required. Please leave blank if not needed for your bank wire</h2>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          {createTextField({
            label: 'FFC Account Name/Title',
            fieldName: 'fccAccountName',
            testId: 'fccAccountName',
            store: wireAuthorizationStore,
          })}
        </Grid>
        <Grid item xs={12} sm={6}>
          {createTextField({
            label: 'FFC Account Number',
            fieldName: 'fccAccountNumber',
            testId: 'fccAccountNumber',
            store: wireAuthorizationStore,
          })}
        </Grid>
        <Grid item xs={12} sm={6}>
          {createTextField({
            label: 'FFC Routing Number',
            fieldName: 'fccRoutingNumber',
            testId: 'fccRoutingNumber',
            store: wireAuthorizationStore,
          })}
        </Grid>
        <Grid item xs={12} sm={6}>
          {createTextField({
            label: 'FFC Bank Name',
            fieldName: 'fccBankName',
            testId: 'fccBankName',
            store: wireAuthorizationStore,
          })}
        </Grid>
        <Grid item xs={12} sm={6}>
          {createTextField({
            label: 'FFC Entity Street Name',
            fieldName: 'fccStreetName',
            testId: 'fccStreetName',
            store: wireAuthorizationStore,
          })}
        </Grid>
        <Grid item xs={12} sm={6}>
          {createTextField({
            label: 'FFC Entity City',
            fieldName: 'fccCity',
            testId: 'fccCity',
            store: wireAuthorizationStore,
          })}
        </Grid>
        <Grid item xs={12} sm={6}>
          {createTextField({
            label: 'FFC Entity Zip Code',
            fieldName: 'fccZipCode',
            testId: 'fccZipCode',
            store: wireAuthorizationStore,
          })}
        </Grid>
        <Grid item xs={12} sm={6}>
          {createSelectField({
            store: wireAuthorizationStore,
            testId: 'fccState',
            fieldName: 'fccState',
            label: 'FFC Entity State',
            options: stateOptions,
          })}
        </Grid>
      </Grid>
    </>
  );
});
