import { Box } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { GridStore } from '@roc/feature-app-core';
import { ColDef, DataGrid, LinkColumn } from '@roc/ui';
import { formatCurrency } from '@roc/ui/utils';
import { observer } from 'mobx-react';
import { ReactNode, useEffect } from 'react';
import {
  ExpectedClosingData,
  ExpectedClosingDataColumn,
} from '../utils/expectedClosingTypes';
import { ExpectedClosingsAccordion } from './expectedClosingsAccordion';

const useStyles = makeStyles((theme: Theme) => createStyles({}));

const TOTAL = 'Total';

const minWidthByField = {
  loanId: 100,
};

interface Props {
  data: ExpectedClosingData;
  gridStore: GridStore;
  toolbar?: ReactNode;
  showTotalRow?: boolean;
  renderAction?: (value: any) => ReactNode;
}

const getColumnDefinitions = (data: ExpectedClosingData, renderAction) => {
  const colDefs: any = data.columns.map(column => ({
    field: column.mapping,
    headerName: column.name,
    minWidth: minWidthByField[column.mapping],
    cellRendererSelector: params => {
      const isTotalRow = params.node.rowPinned;
      if (isTotalRow) {
        return {
          component: 'totalRowCellRenderer',
        };
      } else if (column.type === 'dollarWithDecimal') {
        return {
          component: 'currencyCellRenderer',
        };
      } else if (column.mapping === 'loanId') {
        return {
          component: 'loanIdCellRenderer',
        };
      }
    },
  }));

  if (renderAction) {
    colDefs.push({
      field: data.primaryKey,
      headerName: '',
      cellRenderer: 'actionCellRenderer',
      maxWidth: 120,
    });
  }

  return colDefs;
};

const getTotalRow = (columns, rows) => {
  try {
    let totalRow = {};
    columns.forEach((column, i) => {
      const field = column.mapping;
      const isFirstColumn = i === 0;
      const isCurrencyColumn = column.type?.includes('dollar');

      if (isFirstColumn) {
        totalRow[field] = TOTAL;
      }
      if (isCurrencyColumn) {
        const values = rows.map(row => parseFloat(row[field] ?? 0));
        const sum = values.reduce((total, val) => total + val);
        totalRow[field] = sum;
      }
    });
    return totalRow;
  } catch (e) {
    return undefined;
  }
};

export const ExpectedClosingGrid = observer(
  ({ data, gridStore, toolbar, renderAction, showTotalRow }: Props) => {
    const { title, rows, columns, primaryKey } = data;

    useEffect(() => {
      gridStore.fetchGridData();
    }, []);

    const onSortChanged = params => {
      const sortModel = params.api.getSortModel();
      gridStore.setSortModel(sortModel);
    };

    const onFilterChanged = params => {
      const filterModel = params.api.getFilterModel();
      gridStore.setFilterModel(filterModel);
    };

    const frameworkComponents = {
      loanIdCellRenderer: params => (
        <LinkColumn
          value={params.value}
          url={{ pathname: '/loan_information/' + params.value }}
          onClick={e => {
            open('/nportal/#/loan_information/' + params.value);
            e.preventDefault();
          }}
        />
      ),
      actionCellRenderer: params => {
        return renderAction(params.node.data);
      },
      currencyCellRenderer: params => (
        <Box textAlign="right">{formatCurrency(params.value, 0)}</Box>
      ),
      totalRowCellRenderer: params => {
        if (params.value === TOTAL) {
          return <Box fontWeight="bold">{params.value}</Box>;
        } else {
          return (
            <Box textAlign="right" fontWeight="bold">
              {!!params.value && formatCurrency(params.value, 2)}
            </Box>
          );
        }
      },
    };

    return (
      <ExpectedClosingsAccordion title={title}>
        <DataGrid
          suppressRowClickSelection={false}
          frameworkComponents={frameworkComponents}
          toolbar={toolbar}
          columns={getColumnDefinitions(data, renderAction)}
          rows={gridStore.gridData.data.rows}
          onSortChanged={onSortChanged}
          onFilterChanged={onFilterChanged}
          isLoading={gridStore.isLoading}
          showFilters={gridStore.showFilters}
          sortModel={gridStore.sortModel}
          filterModel={gridStore.filterModel}
          paginationData={gridStore.gridData.meta}
          setPageNumber={gridStore.setPageNumber}
          pinnedBottomRowData={
            showTotalRow && rows.length
              ? [getTotalRow(columns, rows)]
              : undefined
          }
          rowHeight={45}
          domLayout="autoHeight"
        />
      </ExpectedClosingsAccordion>
    );
  }
);
