import React from 'react';
import { observer } from 'mobx-react';
import { TimeoutLoginButtonModal, TimeoutLoginModal } from './sessionTimeout';
import { useBaseStore } from '../../hooks/useBaseStore';

export const RefreshTokenExpired = observer(() => {
  const { globalStore } = useBaseStore();
  const { refreshTokenExpired } = globalStore;
  return <TimeoutLoginModal open={Boolean(refreshTokenExpired)} msg="Your token has expired. Please login again to continue." />;
});

export const RefreshTokenExpiredSimple = observer(({ handleLogin }) => {
  const { globalStore } = useBaseStore();
  const { refreshTokenExpired } = globalStore;
  return <TimeoutLoginButtonModal
    open={Boolean(refreshTokenExpired)}
    msg="Your token has expired. Please login again to continue."
    handleLogin={handleLogin}
  />;
});