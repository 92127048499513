import {
  Avatar,
  Box,
  Hidden,

  Typography,
} from '@material-ui/core';
import { lighten } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { formatDate, parseMMDDYYYYDate } from '@roc/feature-utils';
import { Paper } from '@roc/ui';
import React from 'react';

const useStyles = makeStyles(theme => ({
  avatarBox: {
    minWidth: 40,
  },
  avatarGrey: {
    color: theme.palette.getContrastText(grey[700]),
    backgroundColor: grey[700],
  },
  avatarPrimary: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
    backgroundColor: theme.palette.primary.main,
  },
  messageContainer: {
    '& ul': {
      listStylePosition: 'inside',
    },
    '& img': {
      width: '100%',
    },
  },
  sectionName: {
    fontWeight: 'bold',
    fontSize: 14,
    color: theme.palette.text.secondary,
    paddingBottom: theme.spacing(0.6),
  },
}));

export interface MessageProps {
  name: string;
  date: string;
  message: string;
  isLoggedInUser?: boolean;
  sectionName?: string;
}

export const ChatMessage = ({
  name,
  date,
  message,
  isLoggedInUser,
  sectionName,
}: MessageProps) => {
  const classes = useStyles();
  const theme = useTheme();

  const nameArr = name.split(' ');
  const initials = nameArr
    .map((n, i) => (i === 0 || i === nameArr.length - 1) && n[0])
    .filter(n => n)
    .join('');

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box display="flex" my={2}>
      <Hidden smDown implementation="css">
        <Box className={classes.avatarBox} pt={1}>
          {!isLoggedInUser && (
            <Avatar className={classes.avatarGrey}>{initials}</Avatar>
          )}
        </Box>
      </Hidden>
      <Box flexGrow="1" px={isSmallScreen ? 0 : 2}>
        <Paper
          style={{
            padding: theme.spacing(2),
            background: isLoggedInUser
              ? lighten(theme.palette.primary.main, 0.8)
              : '#ebebed',
            boxShadow: 'none',
          }}
        >
          <Box
            display="flex"
            flexDirection="row"
            alignItems="flex-start"
            justifyContent="space-between"
            pb={1}
          >
            <Box>
              {sectionName ? (
                <Typography className={classes.sectionName}>
                  {sectionName}
                </Typography>
              ) : null}
              <Typography variant="body1">
                <strong>{name}</strong>
              </Typography>
            </Box>
            <Typography
              variant="body1"
              color="textSecondary"
              style={{ fontSize: '.75rem' }}
            >
              {formatDate(parseMMDDYYYYDate(date))}
            </Typography>
          </Box>
          <div
            className={classes.messageContainer}
            dangerouslySetInnerHTML={{ __html: message }}
          />
        </Paper>
      </Box>
      <Hidden smDown implementation="css">
        <Box className={classes.avatarBox} pt={1}>
          {isLoggedInUser && (
            <Avatar className={classes.avatarPrimary}>{initials}</Avatar>
          )}
        </Box>
      </Hidden>
    </Box>
  );
};
