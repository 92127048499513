import { Route } from 'react-router';
import { ScopeOfWorkRevision } from '../components';
import { Page, useBaseStore } from '@roc/feature-app-core';

export const getScopeOfWorkRevisionRoutesConfig = (
  basePath: string,
  baseUrl: string
) => {
  return {
    scopeOfWork: {
      path: `${basePath}/scope-of-work-revision`,
      url: `${baseUrl}/scope-of-work-revision`,
      documentTitle: 'Scope of Work-revision',
    },
  };
};

export const getScopeOfWorkRevisionRoutes = (basePath: string, baseUrl: string) => {
  const config = getScopeOfWorkRevisionRoutesConfig(basePath, baseUrl);
  const { globalStore } = useBaseStore();

  return {
    ...(globalStore.userFeatures?.underwritingEntitlements?.isScopeOfWorkFormEnabled
      ? {
        scopeOfWorkRevision: (
          <Route exact path={config.scopeOfWork.path}>
            <Page routeDefinition={config.scopeOfWork}>
              <ScopeOfWorkRevision />
            </Page>
          </Route>
        ),
      }
      : {}),
  };
};
