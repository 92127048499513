import { Grid } from '@material-ui/core';
import {
  createSelectField,
  createSliderField,
  createTextField,
} from '@roc/ui/componentBuilder';
import { observer } from 'mobx-react';
import React from 'react';
import BorrowerSurveyStore from '../../../stores/documentForms/borrowerSurveyStore';

const encountersOptions = [
  {
    value: 'In person',
    label: 'In person',
  },
  {
    value: 'By phone',
    label: 'By phone',
  },
  {
    value: 'By email',
    label: 'By email',
  },
  {
    value: 'Through broker',
    label: 'Through broker',
  },
];

const realEstateExperienceOptions = [
  {
    label: 'Select an option',
    value: '',
  },
  {
    label: '<1 year',
    value: '<1 year',
  },
  {
    label: '1-2 years',
    value: '1-2 years',
  },
  {
    label: '3-5 years',
    value: '3-5 years',
  },
  {
    label: '5+ years',
    value: '5+ years',
  },
];

const payHabitsOptions = [
  {
    label: 'Select an option',
    value: '',
  },
  {
    label: "N/A - We've never lent to this borrower before",
    value: "N/A - We've never lent to this borrower before",
  },
  {
    label: 'No defaults, always pays on time',
    value: 'No defaults, always pays on time',
  },
  {
    label: 'Late sometimes, but always pays within 30 days',
    value: 'Late sometimes, but always pays within 30 days',
  },
  {
    label: 'Gets the job done, but has gone past 30 days at times',
    value: 'Gets the job done, but has gone past 30 days at times',
  },
  {
    label: 'Very erratic pay history',
    value: 'Very erratic pay history',
  },
  {
    label: 'Has defaulted but ultimately paid us off',
    value: 'Has defaulted but ultimately paid us off',
  },
  {
    label: 'Has defaulted or is in default with us now',
    value: 'Has defaulted or is in default with us now',
  },
];

const yearsOfKnowingOptions = [
  { label: 'Select an option', value: '' },
  { label: '<1 year', value: '<1 year' },
  { label: '1-2 years', value: '1-2 years' },
  { label: '3-5 years', value: '3-5 years' },
  { label: '5+ years', value: '5+ years' },
];

const rentalPropertiesOwnedOptions = [
  { label: 'Select an option', value: '' },
  { label: '0 - No rental properties', value: '0 - No rental properties' },
  { label: '1-2', value: '1-2' },
  { label: '3-5', value: '3-5' },
  { label: '6-10', value: '6-10' },
  { label: '>10', value: '>10' },
];

const loansDoneOptions = [
  { label: 'Select an option', value: '' },
  { label: '0 loans', value: '0 loans' },
  { label: '1-2 loans', value: '1-2 loans' },
  { label: '3-5 loans', value: '3-5 loans' },
  { label: '6-10 loans', value: '6-10 loans' },
  { label: '>10 loans', value: '>10 loans' },
];

const flipsCompletedInLifetimeOptions = [
  {
    label: 'Select an option',
    value: '',
  },
  {
    label: '0 - This is his/her first flip',
    value: '0 - This is his/her first flip',
  },
  { label: '1-2', value: '1-2' },
  { label: '3-5', value: '3-5' },
  { label: '6-10', value: '6-10' },
  { label: '11-24', value: '11-24' },
  { label: '>25', value: '>25' },
];

const flipsCompletedInLastYearOptions = [
  {
    label: 'Select an option',
    value: '',
  },
  {
    label: '0 - This is his/her first flip',
    value: '0 - This is his/her first flip',
  },
  { label: '1-2', value: '1-2' },
  { label: '3-5', value: '3-5' },
  { label: '6-10', value: '6-10' },
  { label: '11-24', value: '11-24' },
  { label: '>25', value: '>25' },
];

const flippingBusinessStyleOptions = [
  { label: 'Select an option', value: '' },
  { label: 'Hobby', value: 'Hobby' },
  { label: 'Primary Job', value: 'Primary Job' },
  { label: 'Side Gig', value: 'Side Gig' },
  { label: 'Other', value: 'Other' },
];

const realEstateLicenseOptions = [
  { label: 'Select an option', value: '' },
  { label: 'Yes', value: 'Yes' },
  { label: 'No', value: 'No' },
];

export type BorrowerSurveyProps = {
  store: BorrowerSurveyStore;
};

export const BorrowerSurvey = observer((props: BorrowerSurveyProps) => {
  const { store } = props;

  const disabled = !store.canEdit;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        {createTextField({
          store,
          testId: 'first-name',
          fieldName: 'firstName',
          label: 'First Name',
          disabled,
        })}
      </Grid>

      <Grid item xs={12} md={6}>
        {createTextField({
          store,
          testId: 'last-name',
          fieldName: 'lastName',
          label: 'Last name',
          disabled,
        })}
      </Grid>

      <Grid item xs={12} md={6}>
        {createSelectField({
          store,
          testId: 'encounters',
          fieldName: 'encounters',
          label: 'Encounters with borrower',
          options: encountersOptions,
          disabled,
        })}
      </Grid>

      <Grid item xs={12} md={6}>
        {createSelectField({
          store,
          testId: 'real-estate-experience',
          fieldName: 'realEstateExperience',
          label:
            'How many years of real estate experience does the borrower have?',
          options: realEstateExperienceOptions,
          disabled,
        })}
      </Grid>

      <Grid item xs={12} md={6}>
        {createSelectField({
          store,
          testId: 'pay-habits',
          fieldName: 'payHabits',
          label:
            "If you have lent to a borrower before, please describe the borrower's pay habits",
          options: payHabitsOptions,
          disabled,
        })}
      </Grid>

      <Grid item xs={12} md={6}>
        {createTextField({
          store,
          testId: 'meeting-info',
          fieldName: 'meetingInfo',
          label: 'Describe how you meet the borrower',
          disabled,
        })}
      </Grid>

      <Grid item xs={12} md={6}>
        {createSelectField({
          store,
          testId: 'years-of-knowing',
          fieldName: 'yearsOfKnowing',
          label: 'How long do you know the borrower?',
          options: yearsOfKnowingOptions,
          disabled,
        })}
      </Grid>

      <Grid item xs={12} md={6}>
        {createSelectField({
          store,
          testId: 'rental-properties-owned',
          fieldName: 'rentalPropertiesOwned',
          label: 'Does the borrower own rental properties? If yes, how many?',
          options: rentalPropertiesOwnedOptions,
          disabled,
        })}
      </Grid>

      <Grid item xs={12} md={6}>
        {createSliderField({
          store,
          label: 'Honesty and transparency',
          fieldName: 'honestyAndTransparancyLevel',
          valueLabelDisplay: 'on',
          min: 0,
          max: 5,
          step: 1,
          marks: true,
          disabled,
          testId: 'honestyAndTransparancyLevelSlider',
        })}
      </Grid>

      <Grid item xs={12} md={6}>
        {createSliderField({
          store,
          label: 'Financial Control',
          fieldName: 'financialControlLevel',
          valueLabelDisplay: 'on',
          min: 0,
          max: 5,
          step: 1,
          marks: true,
          disabled,
          testId: 'financialControlLevelSlider',
        })}
      </Grid>

      <Grid item xs={12} md={6}>
        {createSliderField({
          store,
          label: 'Organization/Record keeping',
          fieldName: 'organizationAndRecordKeepingLevel',
          valueLabelDisplay: 'on',
          min: 0,
          max: 5,
          step: 1,
          marks: true,
          disabled,
          testId: 'organizationAndRecordKeepingLevelSlider',
        })}
      </Grid>

      <Grid item xs={12} md={6}>
        {createSliderField({
          store,
          label: 'Likeability',
          fieldName: 'likeabilityLevel',
          valueLabelDisplay: 'on',
          min: 0,
          max: 5,
          step: 1,
          marks: true,
          disabled,
          testId: 'likeabilityLevelSlider',
        })}
      </Grid>

      <Grid item xs={12} md={6}>
        {createSliderField({
          store,
          label: 'Experience',
          fieldName: 'experienceLevel',
          valueLabelDisplay: 'on',
          min: 0,
          max: 5,
          step: 1,
          marks: true,
          disabled,
          testId: 'experienceLevelSlider',
        })}
      </Grid>

      <Grid item xs={12} md={6}>
        {createSelectField({
          store,
          testId: 'loans-done',
          fieldName: 'loansDone',
          label: 'How many loans have you done with this borrower in the past?',
          options: loansDoneOptions,
          disabled,
        })}
      </Grid>

      <Grid item xs={12} md={6}>
        {createSelectField({
          store,
          testId: 'flips-completed-in-lifetime',
          fieldName: 'flipsCompletedInLifetime',
          label: 'How many flips has the borrower completed (lifetime)?',
          options: flipsCompletedInLifetimeOptions,
          disabled,
        })}
      </Grid>

      <Grid item xs={12} md={6}>
        {createSelectField({
          store,
          testId: 'flips-completed-in-last-year',
          fieldName: 'flipsCompletedInLastYear',
          label: 'How many flips has the borrower completed (Last 12 months)?',
          options: flipsCompletedInLastYearOptions,
          disabled,
        })}
      </Grid>

      <Grid item xs={12} md={6}>
        {createSelectField({
          store,
          testId: 'flippingBusinessStyle',
          fieldName: 'flippingBusinessStyle',
          label:
            'Is flipping a hobby, primary job, or a side gig for the borrower?',
          options: flippingBusinessStyleOptions,
          disabled,
        })}
      </Grid>

      <Grid item xs={12} md={6}>
        {createSelectField({
          store,
          testId: 'realEstateLicense',
          fieldName: 'realEstateLicense',
          label: 'Does the borrower have a real estate agent/broker license?',
          options: realEstateLicenseOptions,
          disabled,
        })}
      </Grid>

      <Grid item xs={12} md={12}>
        {createTextField({
          store,
          testId: 'relavantInfo',
          fieldName: 'relavantInfo',
          multiline: true,
          label:
            'Please describe any other relevant info that you have about the borrower',
          disabled,
        })}
      </Grid>
    </Grid>
  );
});

export default BorrowerSurvey;
