export enum LoanPriority {
  URGENT = 'Urgent',
  HIGH = 'High',
  NORMAL = 'Normal',
  LOW = 'Low',
}

export const loanPriorityOptions = [
  { label: 'Urgent', value: LoanPriority.URGENT },
  { label: 'High', value: LoanPriority.HIGH },
  { label: 'Normal', value: LoanPriority.NORMAL },
  { label: 'Low', value: LoanPriority.LOW },
];

export enum QuoteTypes {
  LIABILITY = 'liabilityOnly',
  PROPERTY = 'propertyOnly',
  FULL_QUOTE = 'fullQuote',
  FLOOD = 'floodOnly',
}

export const quoteTypeOptions = [
  { label: 'Liability Only', value: QuoteTypes.LIABILITY},
  { label: 'Property Only', value: QuoteTypes.PROPERTY},
  { label: 'Full Quote', value:  QuoteTypes.FULL_QUOTE},
  { label: 'Flood Only', value: QuoteTypes.FLOOD },
];

export enum extensionsConstants {
  EMPTY = '',
  THREE_MONTHS = '3 months',
  SIX_MONTHS = '6 months',
  ONE_EXTENSION = "6",
  TWO_OR_MORE_EXTENSIONS = "3",
  ELMSURE = "ElmSure",
  OTHER = "Other"

}
