import { LoanVolumeReportBaseStore } from '../stores/loanVolumeReportBaseStore';
import { LoanVolumeReportService } from '../services/loanVolumeReportService';
import { makeObservable, observable, override } from 'mobx';

export class LoanVolumeReportStore extends LoanVolumeReportBaseStore {
  private loanVolumeReportService: LoanVolumeReportService;
  public lenderNameOptions;
  public allLenderNames;
  public relationshipManagerNameOptions;
  public relationshipManagerAndLenderMap;
  public leadSourceOptions;
  public data;
  public columns;
  public leadSourceMap;
  relationshipManagers = [];

  constructor(globalStore) {
    super(globalStore);
    this.loanVolumeReportService = new LoanVolumeReportService();
    this.lenderNameOptions = [];
    this.allLenderNames = [];
    this.relationshipManagerNameOptions = [];
    this.relationshipManagerAndLenderMap = [];
    this.leadSourceOptions = [];
    this.data = undefined;
    this.columns = undefined;
    this.leadSourceMap = undefined;

    makeObservable(this, {
      fetchLoanVolumeReport: override,
      lenderNameOptions: observable,
      allLenderNames: observable,
      relationshipManagerNameOptions: observable,
      relationshipManagerAndLenderMap: observable,
      leadSourceOptions: observable,
      data: observable,
      columns: observable,
      leadSourceMap: observable,
      fetchRelationshipManagerAndLenderMap: override,
      relationshipManagers: observable,
    });
  }

  *fetchLoanVolumeReport(startDate: any, endDate: any, lender: any, relationshipManager: any, quarterly: any, annually: any, filters: any) {
    try {
      const response = yield this.loanVolumeReportService.loanVolumeData(startDate, endDate, lender, relationshipManager, quarterly, annually, filters);
      const { data, columns, leadSourceMap } = response.data.body;
      this.data = data;
      this.columns = columns;
      this.leadSourceMap = leadSourceMap;
    } catch (err) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while getting loan volume data',
      });
    }
  }

  *fetchRelationshipManagerAndLenderMap() {
    const response = yield this.loanVolumeReportService.getRelationshipManagerAndLenderMap();
    const rmAndLenderMap = response.data;
    
    if (typeof rmAndLenderMap !== 'object' || rmAndLenderMap === null) {
      return;
    }
    this.relationshipManagers = Object.entries(rmAndLenderMap).map(([key, value]) => ({
      label: key,
      value: (typeof value === 'string') ? value.split(',').map(item => ({ label: item, value: item })) : []
    }));
  }

}
