import { Grid } from '@material-ui/core';
import { observer } from 'mobx-react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Button } from '@roc/ui';
import { TrackRecordEntityForm } from './trackRecordEntityForm';
import { TrackRecordEntities } from './trackRecordEntities';

const useStyles = makeStyles((theme: Theme) => {
  return {

  };
});

export const TrackRecordSecondStep = observer(({ store }) => {
  const classes = useStyles();

  return (
    <Grid container direction='row' spacing={2}>
      {store.entities.length > 0 && (
        <Grid item xs={12}>
          <TrackRecordEntities store={store} />
        </Grid>
      )}
      {store.isEntityFormActive && (
        <Grid item xs={12}>
          <TrackRecordEntityForm store={store} />
        </Grid>
      )}
      {!store.isEntityFormActive && (
        <Grid item xs={12}>
          <Button
            variant='outlined'
            color='primary'
            onClick={() => store.handleAddNewTrackRecordEntity()}
            testId={`addNewEntity`}
          >
            Add New Entity
          </Button>
        </Grid>
      )}
    </Grid>
  );
});
