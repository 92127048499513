import { Box, Divider, Grid, ListItem, ListItemText, Typography, makeStyles } from "@material-ui/core";
import { Paper } from "@roc/ui";
import { formatCurrency, formatPercentage } from '@roc/ui/utils';
import { Accordion, AccordionDetails, AccordionSummary } from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import React from "react";
import { observer } from "mobx-react";


const useStyles = makeStyles(theme => ({
  paper: {
    border: '1px solid',
    borderColor: theme.palette.grey['200'],
    width: '100%',
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
  },
  divider: {
    listStyle: 'none',
  },
  label: {
    width: '60%',
  },
  textValue: {
    width: '20%',
  },
  totalText: {
    fontWeight: 'bold',
  },
  accordion: {
    boxShadow: 'none',
  },
  accordionSummary: {
    padding: 0,
    '& .MuiAccordionSummary-content': {
      margin: 0,
    }
  },
  rightBox: {
    marginRight: 36,
  },
  totalRightBox: {
    marginRight: 36,
    fontWeight: 'bold',
  },
  accordionDetails: {
    display: 'flex',
    flexDirection: 'column',
  }
}));

export const PaperList = ({ children }) => {
  const classes = useStyles();
  return <Paper className={classes.paper}>{children}</Paper>;
};

export const PaperListTitle = ({ text }) => {
  return (
    <ListItem>
      <ListItemText>
        <Typography variant="h6">{text}</Typography>
      </ListItemText>
    </ListItem>
  );
};

export const PaperListItem = ({ label, value, secondValue = '', last = false, first = false, total = false }) => {
  const classes = useStyles();
  return (
    <>
      {
        !first ? <Divider variant="middle" component="li" className={classes.divider} /> : null
      }
      <ListItem>
        <ListItemText>
          {
            secondValue !== '' ? (
              <Box display="flex" justifyContent="space-between">
                <Box className={classes.label}>{label}</Box>
                <Box className={classes.textValue}>{value}</Box>
                <Box className={classes.textValue}>{secondValue}</Box>
              </Box>
            ) : (
              <Box display="flex" justifyContent="space-between">
                <Box className={total ? classes.totalText : ''}>{label}</Box>
                <Box className={total ? classes.totalRightBox : classes.rightBox}>{value}</Box>
              </Box>
            )
          }

        </ListItemText>
      </ListItem>
      {
        last ? <Divider variant="middle" component="li" className={classes.divider} /> : null
      }
    </>
  );
};
export const ExpandablePaperListItem = ({ label, value, children }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };
  return (
    <Accordion className={classes.accordion} expanded={expanded === label} onChange={handleChange(label)}>
      <AccordionSummary
        className={classes.accordionSummary}
        expandIcon={<ExpandMore />}
      >
        <ListItem>
          <ListItemText>
            <Box display="flex" justifyContent="space-between">
              <Box>{label}</Box>
              <Box>{expanded ? '' : value}</Box>
            </Box>
          </ListItemText>
        </ListItem>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

export const PaperListHeader = ({ subtitle }) => {
  const classes = useStyles();
  return (
    <>
      <ListItem>
        <ListItemText>
          <Box display="flex" justifyContent="space-between">
            <Box className={classes.label}></Box>
            <Box className={classes.textValue}>$ Amount</Box>
            <Box className={classes.textValue}>{`% ${subtitle}`}</Box>
          </Box>
        </ListItemText>
      </ListItem>
      <Divider variant="middle" component="li" className={classes.divider} />
    </>
  );
};

const formatCurrencyValue = value => {
  if (value === null || value === undefined) {
    return '-';
  }
  return formatCurrency(value, 2);
}

const getFormatDecimals = (value: number) => {
  if (value === null || value === undefined) {
    return '0.00';
  }
  return value.toFixed(2);
}

const formatPercentageValue = value => {
  if (value === null || value === undefined) {
    return '-';
  }
  return formatPercentage(value, 2);
}

export const Summary = observer(({ store }) => {
  const calculatedValues = store.calculatedValues;
  const formStore = store.bridgeProfitAndDscrCalculatorFormStore;

  return (
    <Grid item container xs={12}>
      <PaperList>
        <PaperListTitle text="Loan Terms" />
        <PaperList>
          <PaperListHeader subtitle="LTC" />
          <PaperListItem label="Initial Loan" value={formatCurrencyValue(calculatedValues.initialLoanAmount)} secondValue={formatPercentageValue(calculatedValues.initialLoanLTC)} first />
          <PaperListItem label="Construction Holdback" value={formatCurrencyValue(calculatedValues.constructionHoldback)} secondValue={formatPercentageValue(calculatedValues.constructionHoldbackLTC)} />
          <PaperListItem label="Total Loan Amount" value={formatCurrencyValue(calculatedValues.loanAmount)} secondValue={formatPercentageValue(calculatedValues.loanAmountLTC)} total />
        </PaperList>
        <PaperList>
          <PaperListHeader subtitle="LTV" />
          <PaperListItem label="As Is Value" value={formatCurrencyValue(calculatedValues.value)} secondValue={formatPercentageValue(calculatedValues.asIsValueLTV)} first />
          <PaperListItem label="After Repair Value" value={formatCurrencyValue(calculatedValues.salesPrice)} secondValue={formatPercentageValue(calculatedValues.afterRepairLTV)} />
        </PaperList>
      </PaperList>
      {
        formStore.exitStrategy === 'Sale' ? (
          <>
            <PaperList>
              <PaperListTitle text="Total Project Costs" />
              <PaperListItem label="Acquisition Price" value={formatCurrencyValue(calculatedValues.acquisitionPrice)} first />
              <PaperListItem label="Renovations" value={formatCurrencyValue(calculatedValues.renovations)} />
              {
                formStore.loanPurpose === 'Refi' ? (
                  <ExpandablePaperListItem label="Interest & Origination Points" value={formatCurrencyValue(calculatedValues.interestAndOriginationPoints)} >
                    <PaperListItem label="Interest since purchase date " value={formatCurrencyValue(calculatedValues.interestAndOriginationPointsSincePurchase)} first />
                    <PaperListItem label="Interest from close of this loan" value={formatCurrencyValue(calculatedValues.interestAndOriginationPointsSinceClose)} first={formStore.loanPurpose !== 'Refi'} />
                    <PaperListItem label="Origination Points" value={formatCurrencyValue(calculatedValues.originationPoints)} />
                  </ExpandablePaperListItem>
                ) : <PaperListItem label="Interest & Origination Points" value={formatCurrencyValue(calculatedValues.interestAndOriginationPoints)} />
              }
              <PaperListItem label="Property Tax & Maintenance" value={formatCurrencyValue(calculatedValues.propertyTaxAndMaintenanceAmount)} />
              <ExpandablePaperListItem label="Taxes and Other Closing Costs" value={formatCurrencyValue(calculatedValues.taxesAndOtherCosts)} >
                <PaperListItem label="Legal Fees" value={formatCurrencyValue(calculatedValues.legalFees)} first />
                <PaperListItem label="Mortgage Recording Tax" value={formatCurrencyValue(calculatedValues.mortgageRecordingTax)} />
                <PaperListItem label="Filling Fees" value={formatCurrencyValue(calculatedValues.filingFees)} />
                <PaperListItem label="Appraisal" value={formatCurrencyValue(calculatedValues.appraisal)} />
                <PaperListItem label="PL Legal Fee" value={formatCurrencyValue(calculatedValues.pllegalFee)} />
                <PaperListItem label="Title Insurance" value={formatCurrencyValue(calculatedValues.titleInsurance)} />
                <PaperListItem label="Broker Fee" value={formatCurrencyValue(calculatedValues.brokerFee)} />
                <PaperListItem label="Transfer Tax" value={formatCurrencyValue(calculatedValues.transferTax)} />
                <PaperListItem label="Mansion Tax" value={formatCurrencyValue(calculatedValues.mansionTax)} />
                <PaperListItem label="Recording Fee" value={formatCurrencyValue(calculatedValues.recordingFee)} />
              </ExpandablePaperListItem>
              <PaperListItem label="Total" value={formatCurrencyValue(store.totalCosts)} total />
            </PaperList>
            <PaperList>
              <PaperListTitle text="Flip Economics" />
              <PaperList>
                <PaperListItem label="Sales Price" value={formatCurrencyValue(calculatedValues.salesPrice)} first />
                <PaperListItem label="Total Project Costs" value={formatCurrencyValue(calculatedValues.totalCosts)} />
                <PaperListItem label="Total Profit on Flip" value={formatCurrencyValue(store.totalProfitOnFlip)} total />
              </PaperList>
              <PaperList>
                <PaperListItem label="Borrower Cash in Deal At Purchase" value={formatCurrencyValue(calculatedValues.borrowerCashInDealAtPurchase)} first />
                <PaperListItem label="Borrower Cash in Deal (months)" value={formatCurrencyValue(calculatedValues.borrowerCashInDeal)} />
                <PaperListItem label="Borrower ROI Cash in Deal in (months)" value={formatPercentageValue(calculatedValues.borrowerROIOnCashInDeal)} total />
              </PaperList>
            </PaperList>
          </>) : null
      }
      {
        formStore.exitStrategy === 'Refinance' ? (
          <PaperList>
            <PaperListTitle text="Exit DSCR Calculation" />
            <PaperListItem label="Annual PITIA" value={formatCurrencyValue(calculatedValues.annualPITIA)} first />
            <PaperListItem label="Monthly Principal & Interest Payment" value={formatCurrencyValue(calculatedValues.monthlyPAndIP)} />
            <PaperListItem label="Exit DSCR" value={getFormatDecimals(calculatedValues.dscr)} total />
          </PaperList>
        ) : null
      }

    </Grid>
  )
});