import { FormStore } from '@roc/feature-app-core';
import { ApiResponse } from '@roc/feature-app-core';
import { action, makeObservable, observable } from 'mobx';
import {
  FixFlipStore,
  RentalPortfolioStore,
} from './../../stores';
import {
  GlobalStore,
  GridStore,
} from '@roc/feature-app-core';
import { BorrowerEntityService } from '@roc/feature-borrowers';
import { TermStore } from '../common/termStore';
import { Borrower } from '@roc/feature-types';
import { parseAddress } from '@roc/feature-utils';

const entityForm = {
  fields: {
    borrowerEntityId: {
      value: '',
      error: null,
      rule: '',
    },
    name: {
      value: '',
      error: null,
      rule: 'required',
    },
    type: {
      value: '',
      error: null,
      rule: 'required',
    },
    ein: {
      value: '',
      error: null,
      rule: 'required|regex:/^\\d{2}-?\\d{7}$/|alpha_dash',
      message: 'EIN must be a number with the format xx-xxxxxxx',
    },
    operatingAgreementDate: {
      value: null,
      error: null,
      rule: '',
    },
    address: {
      value: '',
      error: null,
      rule: 'required',
    },
    __isAddressInCorrectFormat__: {
      value: true,
      error: null,
      rule: 'accepted',
      message: 'Invalid Address Format',
    },
    latitude: {
      value: 0,
      error: null,
      rule: '',
      message: '',
    },
    longitude: {
      value: 0,
      error: null,
      rule: '',
      message: '',
    },
    streetNumber: {
      value: '',
      error: null,
      rule: '',
    },
    streetName: {
      value: '',
      error: null,
      rule: '',
    },
    city: {
      value: '',
      error: null,
      rule: '',
    },
    state: {
      value: '',
      error: null,
      rule: '',
    },
    zipcode: {
      value: '',
      error: null,
      rule: '',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

const getEntityForm = (isCustomForm) => {
  if (isCustomForm) {
    const newEntityForm = Object.assign({}, entityForm);

    newEntityForm.fields.ein.rule = newEntityForm.fields.ein.rule.replace('required|', '');
    return newEntityForm;
  }
  return entityForm;
};

export class EntityStore extends FormStore {
  protected globalStore: GlobalStore;
  private parentStore: FixFlipStore | TermStore | RentalPortfolioStore;
  protected borrowerEntityService: BorrowerEntityService;
  private entityBorrowers: Borrower[];
  existentEntitiesGridStore: GridStore;
  hasEntityInfo: boolean;

  constructor(globalStore, parentStore, isCustomForm = false) {
    super({ ...getEntityForm(isCustomForm) }, globalStore);
    this.globalStore = globalStore;
    this.parentStore = parentStore;
    this.borrowerEntityService = new BorrowerEntityService();
    this.existentEntitiesGridStore = new GridStore(
      this.fetchEntities.bind(this),
      this.processEntities.bind(this)
    );
    this.setDefaults();
    makeObservable(this, {
      loadEntity: action,
      getEntity: action,
      saveAddressField: action,
      hasEntityInfo: observable,
      setHasEntityInfo: action,
      resetStore: action,
    });
  }

  private setDefaults() {
    this.hasEntityInfo = true;
    this.entityBorrowers = [];
  }

  private async fetchEntities() {
    try {
      const response: ApiResponse = await this.borrowerEntityService.getAllEntities(
        this.existentEntitiesGridStore.gridData.meta.pageNumber,
        this.existentEntitiesGridStore.gridData.meta.pageSize,
        this.existentEntitiesGridStore.gridData.meta.sortDir,
        this.existentEntitiesGridStore.gridData.meta.sortBy,
        this.existentEntitiesGridStore.gridData.meta.filters
      );
      return response;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching available entities.',
      });
    }
  }

  private async processEntities() {
    this.existentEntitiesGridStore.gridData.data.rows = this.existentEntitiesGridStore.gridData.data.rows.map(
      this.processEntity.bind(this)
    );
  }

  private processEntity(row) {
    row.address = row.company?.address?.trim();
    row.name = row.company?.name;
    row.streetNumber = row.company?.streetNumber;
    row.streetName = row.company?.streetName;
    row.city = row.company?.city;
    row.state = row.company?.state;
    row.zipCode = row.company?.zipCode;
    const index = this.parentStore?.selectedEntities?.findIndex(
      selectedEntity => selectedEntity.borrowerEntityId === row.borrowerEntityId
    );
    row.isSelected = index !== -1;
    return row;
  }

  resetStore() {
    this.reset();
    this.setDefaults();
  }

  saveAddressField(address: string, addressDetails, geometryDetails) {
    const parsedAddress = parseAddress(
      address,
      addressDetails,
      geometryDetails
    );
    this.onFieldChange('address', address);
    this.onFieldChange('streetNumber', parsedAddress.street_number);
    this.onFieldChange('streetName', parsedAddress.street);
    this.onFieldChange('city', parsedAddress.city);
    this.onFieldChange('state', parsedAddress.state);
    this.onFieldChange('zipCode', parsedAddress.zip);

    const addressInCorrectFormat =
      parsedAddress.street_number !== null &&
      parsedAddress.street !== null &&
      parsedAddress.city !== null &&
      parsedAddress.state !== null &&
      parsedAddress.zip !== null;
    this.onFieldChange('__isAddressInCorrectFormat__', addressInCorrectFormat);
  }

  loadEntity(entity) {
    this.entityBorrowers = [...entity.borrowers];
    this.loadForm(entity);
  }

  getEntity() {
    return {
      ...this.getFormValues(),
      borrowers: this.entityBorrowers,
    };
  }

  setHasEntityInfo(value) {
    this.hasEntityInfo = value;
  }
}

export default EntityStore;
