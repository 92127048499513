import { ConversationsFAB, LoanCommunicationStore } from '@roc/feature-communication';
import { observer } from 'mobx-react';

interface BorrowerLoanDetailsFABProps {
  showConversations: boolean;
  loanCommunicationStore: LoanCommunicationStore;
  onOpenConversations: () => void;
}

export const BorrowerLoanDetailsFAB = observer(({
  showConversations,
  loanCommunicationStore,
  onOpenConversations,
}: BorrowerLoanDetailsFABProps) => {
  return (
    <>
      {showConversations && (
        <ConversationsFAB
          loanCommunicationStore={loanCommunicationStore}
          onOpenConversations={onOpenConversations}
          showConversations={showConversations}
          stickyMinWidth={1650}
        />
      )}
    </>
  );
});