import { Add } from '@material-ui/icons';
import { ItemDisplay } from '../../../../components/itemDisplay';
import {
  Grid,
  Accordion,
  AccordionSummary,
  SvgIconProps,
} from '@material-ui/core';
import { observer } from 'mobx-react';
import { RoundedBackgroundIcon } from '../../../../components/roundedBackgroundIcon';
import { ReactComponent as HandShakeIcon } from '../../../../../assets/handShakeIcon.svg'

interface BrokerInformationEmptyCardProps {
  onAddBroker: () => void;
}

export const BrokerInformationEmptyCard = observer(
  ({
    onAddBroker
  }: BrokerInformationEmptyCardProps) => {
    return (
      <Accordion
        expanded={false}
        elevation={0}
        onClick={onAddBroker}
      >
        <AccordionSummary
          expandIcon={<Add fontSize="large"  />}
          aria-controls={`broker-information-empty-card`}
          style={{ padding: '0px'}}
        >
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xs={6}>
              <Grid
                container
                spacing={2}
                justifyContent="flex-start"
                alignItems="center"
              >
                <Grid item alignItems="center" style={{ height: '100%' }}>
                  <RoundedBackgroundIcon
                    Icon={(props: SvgIconProps) => <HandShakeIcon/>}
                    iconColor="#6D6D6D"
                  />
                </Grid>
                <Grid item>
                  <ItemDisplay
                    label="Broker Information"
                    value={'-'}
                    alignItems="flex-start"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid
                container
                spacing={3}
                justifyContent="flex-end"
                alignItems="center"
              >
                <Grid item>
                  <ItemDisplay 
                    label="Broker EIN" 
                    value={'-'} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </AccordionSummary>
      </Accordion>
    );
  }
);
