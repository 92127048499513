import { tooltipHeaderTemplate } from '../components/dataGrid';
import { Option } from './types';

export const noOpComparator = () => 0;

export const filterProps = () => ({
  filter: 'agTextColumnFilter',
  floatingFilter: true,
  floatingFilterComponentParams: {
    suppressFilterButton: true,
  },
});

export const selectFilterProps = (options: Option[]) => ({
  filter: 'selectFilter',
  floatingFilter: true,
  floatingFilterComponent: 'selectFloatingFilter',
  floatingFilterComponentParams: {
    suppressFilterButton: true,
    options,
  },
});

export const headerTooltipProps = (tooltip: string) => ({
  headerTooltip: tooltip,
  headerComponentParams: {
    template: tooltipHeaderTemplate,
  },
});
