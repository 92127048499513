import { Box, Grid, Typography } from '@material-ui/core';
import { Theme, makeStyles, useTheme } from '@material-ui/core/styles';
import { useBorrowerPortalMediaQueries } from '@roc/feature-app-core';
import { Button, Paper } from '@roc/ui';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router';
import { thankYouStepBg } from 'libs/feature-borrower-loan-submission/src/modules/newLoanApplication/assets';
import { isNil } from '@roc/feature-utils';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    margin: '40px'
  },
}));

export const SuccessPage = observer(({ store, currentRoutes }) => {
  const { push } = useHistory();
  const { newPublicLoanId } = store;
  const theme = useTheme();

  const { isLargeScreen, isSmallScreen } = useBorrowerPortalMediaQueries();

  const classes = useStyles();
  return (
    <Paper className={classes.paper}>
      <Grid container>
        <Grid xs={12} md={12}>
          <Box
            height="100%"
            maxWidth="412px"
            margin="auto"
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'center'}
          >
            <Box p={2}>
              <Typography style={{ fontSize: '48px' }}>Thank You!</Typography>
            </Box>
            {!isNil(newPublicLoanId) ?
              <Box p={2}>
                <Typography style={{ fontSize: '20px' }}>
                  Your loan submission ID number is {newPublicLoanId}
                </Typography>
              </Box>
              :
              <></>
            }
            <Box p={2}>
              <Typography style={{ fontSize: '20px' }}>
                We will notify you via email once the loan submission is accepted.
              </Typography>
            </Box>
            <Box p={2}>
              <Button
                testId="start-another-loan"
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => {
                  store.resetForNewLoanApplication();
                  push(currentRoutes.url)
                }
                }
              >
                START ANOTHER LOAN
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
});
