import { observer } from 'mobx-react';
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemText,
  Grid,
  Typography,
  Chip
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Button } from '@roc/ui';
import { useEffect } from 'react';
import { useStore } from '@roc/client-portal-shared/hooks';
import { CheckCircle } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  button: {
    margin: '10px',
    minWidth: '150px',
  },
  notAuthtenticatedButton: {
    margin: '10px',
    minWidth: '150px',
    backgroundColor: 'red',
    '&:hover': {
    backgroundColor: 'red',
  },
  },
}));

const divider = <Divider variant="middle" component="li" />;


export const RingCentralAuthCard = observer(() => {

const classes = useStyles();
const { salesforceLeadStore, ringCentralStore, userStore, whiteLabelStore } = useStore();

  async function handleOnClick() {
  const host = await whiteLabelStore.getHostByPortaId(userStore.userInformation.portalId);
  ringCentralStore.retriveTokenWithOAuthToken(userStore.userInformation.userId, host);
}

  return (
<Paper className={classes.paper} style={{ display: 'flex', flexDirection: 'column', padding: '20px' }}>
  <Box display="flex" alignItems="center" mb={2}>
    <img
      src="https://netstorage.ringcentral.com/appext/logo/TFQ9Uh2rTbiYGu9M_R7pAw~yV0_eNqeT6-sWPXFJev-6A/c448bc9c-339e-4d73-b962-59b2c3a96350.png"
      alt="Logo"
      style={{ width: '60%', maxWidth: 80 }}
    />
    <Box flexGrow={1} ml={2}>
      <Typography variant="h6">
        {'RingCentral Connection'}
      </Typography>
      <Typography variant="body2">
        {'Connect your RingCentral account to send messages from Portal'}
      </Typography>
    </Box>
  </Box>
  <Box display="flex" alignItems="center" mb={2}>
    <Box mr={2}>
    {
      userStore.userInformation.connectedWithRingcentral ? (
        <Chip
         icon={<CheckCircle style={{
                    color: 'white'
                  }} />}
          label="CONNECTED"
          style={{
            backgroundColor: '#008f39',
            letterSpacing: 2,
            color: '#FFFFFF',
            fontWeight: 'bold'
          }}
        />
      ) : (
        <Chip
          label="NOT CONNECTED"
          style={{
            backgroundColor: '#b3b3b3',
            letterSpacing: 2,
            color: '#777',
            fontWeight: 'bold'
          }}
        />
      )
    }
    </Box>
    <Button
      className={classes.button}
      variant="contained"
      size="small"
      color="primary"
      testId="Authenticate_With_Ring_Central"
      onClick={handleOnClick}
    >
      Connect RingCentral
    </Button>
  </Box>
</Paper>);
});
