import {
  Box,
  Grid,
  Typography,
  useMediaQuery
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Paper } from '@roc/ui';
import { Draw } from '../../types/draws';
import { BarColors, DrawsDashboardRanger } from './drawsDashboardRanger';
import { LoanValuesTable } from './loanValuesTable';
import { DrawRequestNew } from '../../drawRequests/drawRequestNew';

const useStyles = makeStyles(theme => ({
  container: {
    padding: 32,
  },
  paper: {
    padding: 24,
  },
  graphContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  graph: {
    width: 300
  }
}));

export const LoanValuesCard = ({ draws, chRemaining, chReimbursedToDate }: {
  draws: Draw[],
  chRemaining: number,
  chReimbursedToDate: number,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.sm));

  return (
    <Grid item xs={12} md={12}>
      <Paper>
        <Box p={3} display='flex' justifyContent='space-between'>
          <Typography variant="h6">
            Construction Holdback
          </Typography>
          <DrawRequestNew />
        </Box>
        <Box className={classes.graphContainer} px={isSmallScreen ? 0 : 3} pt={isSmallScreen ? 3 : 11} pb={3}>
          <DrawsDashboardRanger
            totalConstructionHoldback={chRemaining + chReimbursedToDate}
            constructionHoldbackReimbursed={chReimbursedToDate}
            constructionHoldbackRemaining={chRemaining}
          />
        </Box>
        <Box display={'flex'} justifyContent={'center'} p={isSmallScreen ? 0 : 3} maxWidth={600} margin={'auto'}>
          <LoanValuesTable
            totalConstructionHoldback={chRemaining + chReimbursedToDate}
            constructionHoldbackReimbursed={chReimbursedToDate}
            constructionHoldbackReimbursedColor={BarColors.previouslyDisbursed}
            constructionHoldbackRemaining={chRemaining}
            constructionHoldbackRemainingColor={BarColors.remaining}
            draws={draws} />
        </Box>
      </Paper>
    </Grid>
  )
};