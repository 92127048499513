import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { Help, InfoOutlined } from '@material-ui/icons';
import { ReactNode } from 'react';
import { CustomIconTooltip, Tooltip, TooltipProps } from '@roc/ui';
import clsx from 'clsx';

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      marginBottom: theme.spacing(0.5),
      fontSize: '1rem',
      display: 'flex',
      alignItems: 'center',
    },
  })
);

export const useFieldLabelStyle = useStyle;

export interface FieldLabelProps {
  style?: any;
  children: ReactNode;
  tooltip?: string;
  tooltipProps?: TooltipProps;
  showToolTip?: boolean;
  open?: any;
  onClick?: any;
  useCustomTooltip?: boolean;
}

export const FieldLabel = (props: FieldLabelProps) => {
  const { style = {}, children, tooltip, open, onClick, tooltipProps, showToolTip = true, useCustomTooltip = false } = props;
  const classes = useStyle();
  return (
    <Typography style={style} className={clsx(classes.label, 'x-field-label')}>
      {children}
      {showToolTip && tooltip &&
        <>
          {useCustomTooltip ?
            <CustomIconTooltip
              title={tooltip}
              placement="top"
              icon={InfoOutlined}
              style={{ marginLeft: '5px' }}
            />
            :
            <Tooltip title={tooltip} onOpen={open} onClick={onClick} {...tooltipProps} />
          }
        </>
      }
    </Typography>
  );
};
