import { Box, Grid } from '@material-ui/core';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useExpectedClosingsStore } from '../hooks/useExpectedClosingsStore';
import { ExpectedClosingGrid } from './expectedClosingGrid';
import { ExpectedClosingsCard } from './expectedClosingsCard';

export const MonthlyClosingDataTab = observer(() => {
  const { expectedClosingsStore } = useExpectedClosingsStore();
  const { monthlyClosingData } = expectedClosingsStore;

  useEffect(() => {
    expectedClosingsStore.fetchMonthlyClosingLoansData();
  }, []);

  if (!monthlyClosingData) {
    return null;
  }

  return (
    <Box py={2}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <ExpectedClosingsCard
            data={monthlyClosingData.monthlyLoanStatistics}
            showInRed={['totalRetractedLoanAmount']}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <ExpectedClosingsCard
            data={monthlyClosingData.monthlyStatsiticsForLastMonthGrid}
            showInRed={['totalRetractedLoanAmountLastMonth']}
          />
        </Grid>
        <Grid item xs={12}>
          <ExpectedClosingGrid
            data={monthlyClosingData.last15DaysGrid}
            gridStore={expectedClosingsStore.last15DaysGridStore}
            showTotalRow
          />
        </Grid>
        <Grid item xs={12}>
          <ExpectedClosingGrid
            data={monthlyClosingData.lastMonthRetractedLoans}
            gridStore={expectedClosingsStore.lastMonthRetractedLoansGridStore}
            showTotalRow
          />
        </Grid>
      </Grid>
    </Box>
  );
});
