import { ApiResponse } from "@roc/feature-app-core";
import { action, computed, flow, makeObservable, override } from "mobx";
import { ConversationData } from "../types/communicationTypes";
import { LoanCommunicationStore } from "./loanCommunicationStore";
import { EditTaskStore } from "libs/feature-general-tasks/src/generalTasks/stores/editTaskStore";
import { GeneralTasksStore } from "@roc/feature-general-tasks";
import { GeneralTaskType } from "libs/feature-general-tasks/src/generalTasks/types/generalTasksTypes";


export class AppraisalTaskCommunicationStore extends LoanCommunicationStore {

  generalTasksStore: GeneralTasksStore;

  constructor(globalStore, userStore, communicationService, isInternal = false, generalTasksStore) {
    super(globalStore, userStore, communicationService, isInternal);
    this.generalTasksStore = generalTasksStore
    this.preserveUnreadCount = true;
    makeObservable(this, {
      loadMyConversations: override,
      selectChatRoom: action
    });
  }

  async loadMyConversations(
    start: number,
    pageSize: number,
    onSuccess: (totalCount: number, rows: ConversationData[]) => void,
    onError: () => void = () => { },
    silentRefreshMyConversations: boolean = false,
  ) {
    try {
      // Get My Conversations
      let response: ApiResponse = await this.communicationService.getMyConversations({
        userId: this.userId,
        start: start,
        count: pageSize,
        filters: this.filters
      });

      // filter response
      const conversationsData: ConversationData[] = response?.data?.data?.result?.rows ?? [];
      const filteredConversationsData = this.selectChatRoom(conversationsData)
      const totalCount: number = filteredConversationsData.length;
      this._processConversations(filteredConversationsData, totalCount, () => {
        if (filteredConversationsData?.length == 0) {
          this.showNoConversationsMessage = true;
        }
        onSuccess(totalCount, filteredConversationsData);
      }, silentRefreshMyConversations);
    } catch (e) {
      if (!silentRefreshMyConversations) {
        this.globalStore.notificationStore.showErrorNotification({
          message: 'Error fetching my conversations',
        });
      }
      onError();
    }
  }

  selectChatRoom(conversations: ConversationData[]): ConversationData[] | null {
    const keys = []

    if (this.generalTasksStore?.editTaskStore?.task.attributes.taskType == GeneralTaskType.APPRAISAL_DOCUMENT_COLLECTION) {
      keys.push("UNDERWRITING")
    }

    if (this.generalTasksStore?.editTaskStore?.task.attributes.taskType == GeneralTaskType.CMT_REVIEW) {
      keys.push("DRAWS_CONSTRUCTION_DATA_REVIEW")
    }

    if (this.generalTasksStore?.editTaskStore?.task.attributes.taskType == GeneralTaskType.APPRAISAL_REPORT_REVIEW) {
      if (this.globalStore.isInternalPortal) {
        keys.push("UNDERWRITING")
        keys.push("TAMARISK_APPRAISAL")
      } else {
        keys.push("UNDERWRITING")
      }
    }

    const conversationsDataList: ConversationData[] = conversations.filter(conversation => keys.includes(conversation.chatRoomType))

    return conversationsDataList;
  }

}