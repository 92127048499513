import { Box, Link } from '@material-ui/core';
import { Face } from '@material-ui/icons';
import { observer } from 'mobx-react';
import { useUserStore } from '../../hooks';

export const ImpersonateHeader = observer(({ show, onExit }) => {
  const { userStore } = useUserStore();
  const name = userStore?.userInformation?.fullName ?? '';
  const impersonateColor = '#f44336';
  return (
    (show && name) ?
      <>
        <Box position={'fixed'} top={0} width={'100%'} height={5} bgcolor={impersonateColor} zIndex={100000}>
          <Box maxWidth={600} margin={'auto'} borderRadius={8} color={'white'} fontSize={12} height={20} display={'flex'} justifyContent={'center'} alignItems={'center'} bgcolor={impersonateColor} zIndex={100000} fontFamily={'sans-serif'} fontWeight={'bold'}>
            <Face style={{
              fontSize: 14,
              marginLeft: 4,
              marginRight: 4,
              letterSpacing: 0.5
            }} />YOU ARE IMPERSONATING {name.toUpperCase()}.
            <Link style={{
              color: 'white',
              margin: '0 8px',
              textDecoration: 'underline',
              cursor: 'pointer'
            }} onClick={(e) => {
              e.preventDefault();
              onExit();
            }}>
              EXIT VIEW
            </Link>
          </Box>
        </Box>
        <Box position={'fixed'} bottom={0} width={'100%'} height={5} bgcolor={impersonateColor} zIndex={100000}></Box>
        <Box position={'fixed'} top={0} left={0} width={5} height={'100%'} bgcolor={impersonateColor} zIndex={100000}></Box>
        <Box position={'fixed'} top={0} right={0} width={5} height={'100%'} bgcolor={impersonateColor} zIndex={100000}></Box>
      </> : null
  );
});