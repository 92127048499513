import { isNotBlank, originatorSubLabel } from "@roc/feature-utils";
import { SelectField } from "@roc/ui";
import { observer } from "mobx-react";

export const Originator = observer(
    ({ store, label = 'Originator', name = 'originatorId' }) => {
      const { form } = store;
      const { originatorOptions } = store.loanOriginatorStore;
      return (
        <SelectField
          standaloneLabel
          label={label}
          name={name}
          value={form.fields[name].value}
          errorText={form.fields[name].error}
          error={isNotBlank(form.fields[name].error)}
          options={originatorOptions}
          onChange={value => store.onFieldChange(name, value)}
          variant="outlined"
          fullWidth
          shrink
          testId={name}
          subLabel={originatorSubLabel}
        />
      );
    }
  );