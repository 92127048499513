import { Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import { AppraisalDetailsGrid } from './components/appraisalDetailsGrid';
import { AppraiserDetailsGrid } from './components/appraiserDetailsGrid';

export const AppraisalOrderDetails = observer(({ loanId, collateralId, propertyAppraisalId }) => {
  return (
    <>
      <Typography variant="h5">Appraisal Details</Typography>
      <br />
      <AppraisalDetailsGrid
        loanId={loanId}
        collateralId={collateralId}
        propertyAppraisalId={propertyAppraisalId}
      />
      <br />

      <Typography variant="h5">Appraiser Details</Typography>
      <br />
      <AppraiserDetailsGrid />
    </>
  );
});
