import { GlobalStore } from '@roc/feature-app-core';
import {
  DocumentName,
  DocumentService,
  DocumentStatus,
  DocumentStore,
} from '@roc/feature-documents';
import { TodoItem, TodoStatus } from '@roc/feature-loan-details';
import { Document } from '@roc/feature-types';
import {
  capitalize,
  compareStrings,
  flatten,
  formatDate,
  GENERIC_ERROR_MESSAGE,
  LoanProcess,
} from '@roc/feature-utils';
import { FileUpload } from '@roc/ui';
import DocumentSection from 'libs/feature-documents/src/documents/documentSection';
import { action, flow, makeObservable, observable } from 'mobx';
import { LoanService } from '../../../feature-loans/src/loans/services/loanService';

export class LoanTodoStore {
  private globalStore: GlobalStore;
  private loanService: LoanService;
  private documentService: DocumentService;
  private documentStore: DocumentStore;

  todosByLoanProcess: Record<string, TodoItem[]> = {};
  pendingTodosCountByLoanProcess: Record<string, number> = {};
  loadingTodos: boolean;

  constructor(globalStore: GlobalStore, documentStore: DocumentStore) {
    this.globalStore = globalStore;
    this.documentStore = documentStore;
    this.loanService = new LoanService();
    this.documentService = new DocumentService();

    makeObservable(this, {
      loadingTodos: observable,
      todosByLoanProcess: observable,
      pendingTodosCountByLoanProcess: observable,
      reset: action,
      fetchTodos: flow,
      updateTodoStatus: flow,
      mapDocumentToTodo: action,
      renameDocument: flow,
      deleteDocument: flow,
      uploadSectionDocuments: flow,
      moveDocument: flow,
    });
  }

  reset() {
    this.todosByLoanProcess = {};
    this.pendingTodosCountByLoanProcess = {};
  }

  *fetchTodos(loanId: number, loanProcess: LoanProcess) {
    try {
      this.loadingTodos = true;
      this.todosByLoanProcess[loanProcess] = [];
      const response = yield this.loanService.getLoanToDos(
        loanId,
        capitalize(loanProcess)
      );
      const data = JSON.parse(response.data.data);
      const documents = flatten(Object.values(data));
      const sortedDocuments = documents
        .sort((docA, docB) =>
          compareStrings(docA.documentName, docB.documentName)
        )
        .sort(
          (docA, docB) => (docA.displayOrder ?? 0) - (docB.displayOrder ?? 0)
        );

      const todos = sortedDocuments.map(doc =>
        this.mapDocumentToTodo(doc, loanProcess)
      );

      this.todosByLoanProcess[loanProcess] = todos;
      this.pendingTodosCountByLoanProcess[loanProcess] = todos.filter(todo => {
        return [TodoStatus.PENDING, TodoStatus.MORE_INFO_NEEDED].includes(todo.status);
      }).length;

    } catch (e) {
      console.log(e);
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE,
      });
    } finally {
      this.loadingTodos = false;
    }
  }

  *updateTodoStatus(
    loanId: number,
    loanProcess: LoanProcess,
    document: Document,
    status: DocumentStatus
  ) {
    try {
      const response = yield this.documentService.updateDocument({
        ...document,
        status,
      });

      const updatedDocument = JSON.parse(response.data.data);
      const todos = this.todosByLoanProcess[loanProcess];
      const todoIndex = todos.findIndex(todo => todo.document.loanDocumentId === updatedDocument.loanDocumentId);
      
      if (todoIndex !== -1) {
        todos[todoIndex] = {
          ...todos[todoIndex],
          status: updatedDocument.status,
          lastUpdateDate: formatDate(updatedDocument.lastUpdateDate),
        };
      }

      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Status updated successfully.',
      });
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE,
      });
    }
  }

  mapDocumentToTodo(document: Document, loanProcess: LoanProcess) {
    return {
      todoId: document.loanDocumentId,
      title: document.documentName,
      status: document.status,
      lastUpdateDate: formatDate(document.lastUpdateDate),
      category: document.sectionName,
      document: this.documentStore.formatDocument(document, loanProcess),
    } as TodoItem;
  }

  *uploadSectionDocuments(
    fileUploads: FileUpload[],
    loanId: number,
    sectionId: number,
    sectionName: string,
    loanProcess: LoanProcess,
    drawId?: string
  ) {
    try {
      const docs = this.todosByLoanProcess[loanProcess].map(t => t.document);
      const referenceDocument = docs.find(doc =>
        doc.sectionName.includes(sectionName)
      );
      const loanDocuments = this.documentStore.mapFilesToLoanDocs(
        fileUploads,
        loanId.toString(),
        sectionId,
        drawId,
        referenceDocument
      );

      yield this.documentStore.uploadDocumentsBatch(fileUploads, loanDocuments);
      yield this.fetchTodos(loanId, loanProcess);
    } catch (err) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while uploading documents.',
      });
    }
  }

  *renameDocument(document: Document, newName: string) {
    try {
      yield this.documentService.editLoanDocument({
        ...document,
        documentName: newName,
      });
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE,
      });
    }
  }

  *deleteDocument(document: Document) {
    try {
      yield this.documentService.deleteDocument(document);
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE,
      });
    }
  }

  *moveDocument(
    document: Document,
    referenceDocument: Document,
    dropboxPath: string
  ) {
    try {
      yield this.documentService.editLoanDocument({
        ...document,
        customDropboxPath: dropboxPath,
        sectionId: referenceDocument.sectionId,
        borrowerId: referenceDocument.borrowerId,
        collateralId: referenceDocument.collateralId,
        borrowerEntityId: referenceDocument.borrowerEntityId,
      });
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while moving document',
      });
    }
  }
}
