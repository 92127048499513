export const collateralValuesStbBridge = {
  values: [
    {
      fieldname: "Address",
      mapvalue: "address",
      specialtype: "bigvalue",
    },
    {
      fieldname: "",
      mapvalue: "",
      specialtype: "customlink",
      style: "noMargin"
    },
    {
      fieldname: " ",
      mapvalue: "collateralspace11",
      specialtype: "blankspace",
    },
    {
      fieldname: "Property Type",
      mapvalue: "useCode",
      specialtype: ""
    },
    {
      fieldname: "Acquisition",
      mapvalue: "propertyOwnership",
      specialtype: ""
    },
    {
      fieldname: "Acquisition Price",
      mapvalue: "acquisitionPrice",
      specialtype: "currency",
    },
    {
      fieldname: "Appraised As-is Value",
      mapvalue: "propertyValuation",
      specialtype: "currency",
    },
    {
      fieldname: "Number of Units",
      mapvalue: "units",
      specialtype: "numeric",
    },

    {
      fieldname: "WholeSale Fee",
      mapvalue: "wholesaleFee",
      specialtype: "currency",
    },
    {
      fieldname: "Annual Gross Rent",
      mapvalue: "annualGrossRent",
      specialtype: "currency",
    },
    {
      fieldname: "Occupancy (%)",
      mapvalue: "occupancy",
      specialtype: "",
    },
    {
      fieldname: "WholeSale %",
      mapvalue: "wholesalePercentage",
      specialtype: "percentage",
    },
    {
      fieldname: "Annual Debt Service",
      mapvalue: "annualDebtService",
      specialtype: "currency",
    },
    {
      fieldname: "Arms Comment",
      mapvalue: "rentalArmsLengthComment",
      specialtype: "",
      style: "",
    },
    {
      fieldname: "Purchase Type",
      mapvalue: "propertySourcing",
      specialtype: ""
    },
    {
      fieldname: "Acquisition Date",
      mapvalue: "acquisitionDate",
      specialtype: ""
    },
    {
      fieldname: "Completed Renovations",
      mapvalue: "capitalImprovements",
      specialtype: "currency",
    },
    {
      fieldname: "Rural Census",
      mapvalue: "ruralProperty",
      specialtype: "",
    },
    {
      fieldname: "Arms Length",
      mapvalue: "rentalArmsLength",
      specialtype: ""
    },
    {
      fieldname: "Seller's Credit Amount",
      mapvalue: "sellerCreditAmount",
      specialtype: "currency",
    },
    {
      fieldname: "Wholesaler Purchase",
      mapvalue: "wholesalerPurchase",
      specialtype: ""
    },
    {
      fieldname: "AC",
      mapvalue: "approved",
      specialtype: "icon",
    },
    {
      fieldname: "Value %",
      mapvalue: "arvValuePercentage",
      specialtype: "",
      style: "",
    },
    {
      fieldname: " ",
      mapvalue: "collateralspace12",
      specialtype: "blankspace",
    },
    {
      fieldname: " ",
      mapvalue: "collateralspace13",
      specialtype: "blankspace",
    },
    {
      fieldname: " ",
      mapvalue: "collateralspace14",
      specialtype: "blankspace",
    },
    {
      fieldname: "Sales in Zip (730 days) 3",
      mapvalue: "salesInZipDays",
      specialtype: "",
    },
    {
      fieldname: "Appraisal Reviewed",
      mapvalue: "appraisalReviewed",
      htmlfield: "checkbox",
      specialtype: "icon",
    },
    {
      fieldname: "Credit Reviewed",
      mapvalue: "creditReviewed",
      htmlfield: "checkbox",
      specialtype: "icon",
    },
    {
      fieldname: "Background Reviewed",
      mapvalue: "backgroundReviewed",
      htmlfield: "checkbox",
      specialtype: "icon",
    },
  ]
};

export const generalStatsColsStb = {
  values: [
    {
      fieldname: "Most Recent Valuation",
      mapvalue: "mostRecentValuation",
      specialtype: "currency",
    },
    {
      fieldname: "Total Cost Basis",
      mapvalue: "totalCostBasis",
      specialtype: "currency",
    },
    {
      fieldname: "Total Debt Payoff Amount",
      mapvalue: "totalDebtPayoffAmount",
      specialtype: "currency",
    },
    {
      fieldname: "Proj Profit",
      mapvalue: "projectedProfit",
      specialtype: "currency",
    },
    {
      fieldname: "Annual Insurance",
      mapvalue: "annualInsurance",
      specialtype: "currency",
    },
    {
      fieldname: "PITIA",
      mapvalue: "pitia",
      specialtype: "currency",
    },
    {
      fieldname: "NOI",
      mapvalue: "noi",
      specialtype: "currency",
    },
    {
      fieldname: "Exit Rate",
      mapvalue: "exitRate",
      specialtype: "percentage",
    },
    {
      fieldname: "Profit Est",
      mapvalue: "projectedProfitEst",
      specialtype: "currency",
    },
    {
      fieldname: "In place PITIA DSCR",
      mapvalue: "pitiaDSCR",
      specialtype: "TextBox",
    },
    {
      fieldname: "Exit DSCR",
      mapvalue: "pitiaExitDSCR",
      specialtype: "TextBox",
    },
    {
      fieldname: "App. Deal Econ.",
      mapvalue: "approvedExitStrategy",
      specialtype: "IconCheckBox",
    },
    {
      fieldname: "Proj RoI",
      mapvalue: "projectedRoi",
      specialtype: "percentage",
    },
  ],
};
