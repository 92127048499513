import { ApiResponse, GlobalStore } from '@roc/feature-app-core';
import { DialogState } from '@roc/ui';
import { makeObservable, observable, action, flow } from 'mobx';
import { CommunicationService } from './../services/communicationService';
import { CommunicationStore } from './communicationStore';
import { UserOption } from '../types/communicationTypes';

export class StartPrivateConversationStore {
  private globalStore: GlobalStore;
  mode: DialogState;
  open = false;
  communicationService: CommunicationService;
  communicationStore: CommunicationStore;
  users: UserOption[];
  selectedUser: UserOption;

  constructor(globalStore, communicationStore, communicationService) {
    this.communicationService = communicationService;
    this.globalStore = globalStore;
    this.communicationStore = communicationStore;
    makeObservable(this, {
      users: observable,
      selectedUser: observable,
      mode: observable,
      open: observable,
      openDialog: action,
      closeDialog: action,
      setSelectedUser: flow,
      getUsers: flow,
      startPrivateConversation: flow,
    });
  }

  openDialog = () => {
    this.selectedUser = undefined;
    this.users = [];
    this.mode = DialogState.ADD;
    this.open = true;
  };

  closeDialog = () => {
    this.open = false;
  };

  *setSelectedUser(user: UserOption) {
    this.selectedUser = user;
  }

  *getUsers(companyId: number) {
    try {
      const response: ApiResponse = yield this.communicationService.getParticipantsAllowedByCompanyId(companyId);
      const users: UserOption[] = response?.data?.data as UserOption[];
      this.users = users;
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error retrieving users',
      });
    }
  }

  *startPrivateConversation(onSuccessfulCreation?: (conversationSid: string) => void) {
    this.communicationStore.getOrCreatePrivateChatRoom([this.communicationStore.userId, this.selectedUser.userId])
      .then(x => {
        this.closeDialog();
        onSuccessfulCreation(x.conversationSid);
      });
  }

}
