import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { useBaseStore } from '@roc/feature-app-core';
import { useLoanStore } from '@roc/feature-loans';
import { getUniqueId, LoanStatus } from '@roc/feature-utils';
import {
  DialogState,
  EditableDialog,
  SubmitButton,
  TestDataAttribute,
} from '@roc/ui';
import {
  formatCurrency,
  formatPercentage,
  showPercentage,
} from '@roc/ui/utils';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { CustomCard } from '../customCard';

const renderHeading = value => {
  return (
    <Typography variant="body1">
      <strong>{value}</strong>
    </Typography>
  );
};

export const PointsAndFeesCardTerm = observer(() => {
  const { loanStore } = useLoanStore();
  const { globalStore } = useBaseStore();
  const { loanDetails } = loanStore;

  const calculatedLoanData = loanDetails?.calculatedLoanData;
  const loanRetailFees = loanDetails?.loanRetailFees;
  const isInternal = globalStore.lenderInfo?.isInternal;

  const {
    rocPointsIn,
    sfrLenderPointsIn,
    rocUnderwritingFees,
    loanLenderDetails,
    processingFee,
    rocLegalReviewFee,
    rocAdminFee,
    rocCommitmentFee,
    rocBuydownFee,
  } = loanDetails ?? {};

  return (
    <CustomCard title="Points & Fees">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell align="right">{renderHeading('Rate Buydown')}</TableCell>
            <TableCell align="right">
              {isInternal
                ? renderHeading('Add-On')
                : renderHeading('Your Fees')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>{renderHeading('Points')}</TableCell>
            <TestDataAttribute id="roc_points_in">
              <TableCell align="right">
                {formatPercentage(rocPointsIn ?? 0)}
              </TableCell>
            </TestDataAttribute>
            {isInternal ? (
              <TestDataAttribute id="retail_points">
                <TableCell align="right">
                  {formatPercentage(loanRetailFees?.retailPoints || 0)}
                </TableCell>
              </TestDataAttribute>
            ) : (
              <TestDataAttribute id="lender_points">
                <TableCell align="right">
                  {formatPercentage(sfrLenderPointsIn || 0)}
                </TableCell>
              </TestDataAttribute>
            )}
          </TableRow>
          <TableRow>
            <TableCell>{renderHeading('Processing Fee')}</TableCell>
            <TestDataAttribute id="roc_processing">
              <TableCell align="right">
                {formatCurrency(processingFee ?? 0)}
              </TableCell>
            </TestDataAttribute>
            {isInternal ? (
              <TestDataAttribute id="retail_processing">
                <TableCell align="right">
                  {formatCurrency(loanRetailFees?.retailProcessingFee || 0)}
                </TableCell>
              </TestDataAttribute>
            ) : (
              <TestDataAttribute id="lender_processing">
                <TableCell align="right">
                  {formatCurrency(loanLenderDetails.processingFee || 0)}
                </TableCell>
              </TestDataAttribute>
            )}
          </TableRow>
          <TableRow>
            <TableCell>{renderHeading('Admin Fee')}</TableCell>
            <TestDataAttribute id="roc_admin">
              <TableCell align="right">
                {formatCurrency(rocAdminFee ?? 0)}
              </TableCell>
            </TestDataAttribute>

            {isInternal ? (
              <TestDataAttribute id="retail_admin">
                <TableCell align="right">
                  {formatCurrency(loanRetailFees?.retailAdminFee || 0)}
                </TableCell>
              </TestDataAttribute>
            ) : (
              <TestDataAttribute id="lender_admin">
                <TableCell align="right">
                  {formatCurrency(loanLenderDetails.adminFee || 0)}
                </TableCell>
              </TestDataAttribute>
            )}
          </TableRow>
          <TableRow>
            <TableCell>{renderHeading('Underwriting Fee')}</TableCell>
            <TestDataAttribute id="roc_underwriting">
              <TableCell align="right">
                {formatCurrency(rocUnderwritingFees ?? 0)}
              </TableCell>
            </TestDataAttribute>
            {isInternal ? (
              <TestDataAttribute id="retail_underwriting">
                <TableCell align="right">
                  {formatCurrency(loanRetailFees?.retailUnderwritingFee || 0)}
                </TableCell>
              </TestDataAttribute>
            ) : (
              <TestDataAttribute id="lender_underwriting">
                <TableCell align="right">
                  {formatCurrency(loanLenderDetails.underwritingFee || 0)}
                </TableCell>
              </TestDataAttribute>
            )}
          </TableRow>
          <TableRow>
            <TableCell>{renderHeading('Commitment Fee')}</TableCell>
            <TestDataAttribute id="roc_commitment">
              <TableCell align="right">
                {formatCurrency(rocCommitmentFee ?? 0)}
              </TableCell>
            </TestDataAttribute>
            {isInternal ? (
              <TestDataAttribute id="retail_commitment">
                <TableCell align="right">
                  {formatCurrency(loanRetailFees?.retailCommitmentFee || 0)}
                </TableCell>
              </TestDataAttribute>
            ) : (
              <TestDataAttribute id="lender_commitment">
                <TableCell align="right">
                  {formatCurrency(loanLenderDetails.commitmentFee || 0)}
                </TableCell>
              </TestDataAttribute>
            )}
          </TableRow>
          <TableRow>
            <TableCell>{renderHeading('Buydown Fee')}</TableCell>
            <TestDataAttribute id="roc_buydown">
              <TableCell align="right">
                {formatCurrency(rocBuydownFee ?? 0)}
              </TableCell>
            </TestDataAttribute>
            {isInternal ? (
              <TestDataAttribute id="retail_buydown">
                <TableCell align="right">
                  {formatCurrency(loanRetailFees?.retailBuydownFee || 0)}
                </TableCell>
              </TestDataAttribute>
            ) : (
              <TestDataAttribute id="lender_buydown">
                <TableCell align="right">
                  {formatCurrency(loanLenderDetails?.lenderBuydownFee || 0)}
                </TableCell>
              </TestDataAttribute>
            )}
          </TableRow>
        </TableBody>
      </Table>
    </CustomCard>
  );
});
