import { Page } from '@roc/feature-app-core';
import { Route } from 'react-router';
import { BackOfficeHomePage } from '../../backofficeHomePage/backofficeHomePage';
import { Grid, Typography } from '@material-ui/core';
import { LoanVolume } from '../../sigma/loanVolume';

export const getLeadActivityMetricsRoutesConfig = (basePath: string, baseUrl: string) => {
  return {
    leadActivityMetrics: {
      path: `${basePath}/lead-activity-metrics`,
      url: `${baseUrl}/lead-activity-metrics`,
      documentTitle: 'Lead Activity Metrics',
    },
  };
};

export const getLeadActivityMetricsRoutes = (basePath: string, baseUrl: string) => {
  const config = getLeadActivityMetricsRoutesConfig(basePath, baseUrl);
  return {
    leadActivityMetrics: (
      <Route exact path={config.leadActivityMetrics.path}>
        <Page routeDefinition={config.leadActivityMetrics}>
          <Grid container spacing={2} style={{ padding: 32 }}>
            <Grid item style={{ marginLeft: 12 }}>
              <Typography variant="h4">Lead Activity Metrics</Typography>
            </Grid>
            <Grid item xs={12}>
              <BackOfficeHomePage />
            </Grid>
          </Grid>
        </Page>
      </Route>
    ),
  };
};



export const getLoanVolumeRoutesConfig = (basePath: string, baseUrl: string) => {
  return {
    loanVolume: {
      path: `${basePath}/loan-volume`,
      url: `${baseUrl}/loan-volume`,
      documentTitle: 'Loan Volume',
    },
  };
};

export const getLoanVolumeRoutes = (basePath: string, baseUrl: string) => {
  const config = getLoanVolumeRoutesConfig(basePath, baseUrl);
  return {
    loanVolume: (
      <Route exact path={config.loanVolume.path}>
        <Page routeDefinition={config.loanVolume}>
          <Grid container style={{ padding: 32 }} spacing={2}>
            <Grid item xs={12} style={{ marginLeft: 12 }}>
              <Typography variant="h4">Loan Volume</Typography>
            </Grid>
            <Grid item xs={12} style={{ height: '100%' }}>
              <LoanVolume />
            </Grid>
          </Grid>
        </Page>
      </Route>
    ),
  };
};
