import { ApiResponse, GlobalStore, GridStore } from '@roc/feature-app-core';
import { GENERIC_ERROR_MESSAGE, LoanStatus } from '@roc/feature-utils';
import { BorrowerLoansService } from '@roc/feature-borrower-loans';

const PAGE_SIZE = 20;

const fundedStatusList = [
  LoanStatus.FUNDED,
  LoanStatus.PAID_OFF,
  LoanStatus.PAID_OFF_BUYOUT,
];

export class MyFundedLoansForDrawsGridStore extends GridStore {
  globalStore: GlobalStore;
  borrowerLoansService: BorrowerLoansService;

  constructor(globalStore: GlobalStore) {
    super(() => this.fetchBorrowerFundedLoans(), undefined, PAGE_SIZE);
    this.globalStore = globalStore;
    this.borrowerLoansService = new BorrowerLoansService();
  }

  fetchBorrowerFundedLoans = async () => {
    try {
      const response = await this.borrowerLoansService.getBorrowerLoansForDraws(
        this.gridData.meta.pageNumber,
        this.gridData.meta.pageSize,
        this.gridData.meta.sortDir,
        this.gridData.meta.sortBy,
        this.gridData.meta.filters,
        this.gridData.meta.dropdownFilters
      );
      return response;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE
      });
    }
  };
}
