import { FormStore } from '@roc/feature-app-core';
import { action, makeObservable, flow, observable, computed } from 'mobx';
import { Property, PropertyStbBridge, PropertyTerm } from '../../../../../client-portal-shared/src/app/types';
import { format, parse } from 'date-fns';
import {
  generateUUID,
  loanPurpose,
  MIXED_USE,
  MULTIFAMILY_5_PLUS,
  notLicensedSfr,
  PROPERTY_TYPES_RUSHED_APPRAISAL,
  REFINANCE,
} from '@roc/client-portal-shared/utils';
import { GlobalStore } from '@roc/client-portal-shared/stores';
import { parseAddress } from '@roc/client-portal-shared/utils';
import { StabilizedBridgeStore } from '../stabilizedBridge/stabilizedBridgeStore';
import PropertyTypesStore from '../rentalPortfolio/propertyTypesStore';
import { mapLoanPurpose } from '../common/pricerSummaryHelper';
import * as constants from '../common/pricerSummaryHelper';
import { truncate } from 'fs';
import { Address } from '@roc/ui/formComponents';
import { StabilizedBridge } from '../../stabilizedBridge/stabilizedBridge';

const appraisalInformationForm = {
  fields: {
    chooseExistingAppraisal: {
      value: true,
      error: null,
      rule: 'required',
    },
    orderId: {
      value: null,
      error: null,
      rule: [{ required_if: ['chooseExistingAppraisal', true] }],
      message: 'This field is required.',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

const propertyForm = {
  fields: {
    propertyType: {
      value: '',
      error: null,
      rule: '',
      message: 'Required',
      step1: 'required',
    },
    loanPurpose: {
      value: '',
      error: null,
      rule: '',
      message: 'Required',
      step1: 'required',
    },
    acquisitionDate: {
      value: null,
      error: null,
      rule: '',
      message: 'Required',
      refinance: 'required'
    },
    acquisitionPrice: {
      value: '',
      error: null,
      rule: '',
      message: 'Required',
      step1: 'required',
    },
    capitalImprovements: {
      value: '',
      error: null,
      rule: '',
      message: 'Required',
      refinance: 'required'
    },
    anyDebt: {
      value: '',
      error: null,
      rule: '',
      message: 'Required',
      refinance: 'required'
    },
    totalDebtPayoff: {
      value: '',
      error: null,
      rule: '',
      message: 'Required',
      refinance: 'required'
    },
    units: {
      value: '',
      error: null,
      rule: '',
      message: 'Required',
    },
    unitsOccupied: {
      value: '',
      error: null,
      rule: '',
      message: 'Required',
    },
    unitsOccupiedHave12MonthsLease: {
      value: '',
      error: null,
      rule: '',
      message: 'Required',
    },
    propertyValuation: {
      value: '',
      error: null,
      rule: 'required',
      nextStep: 'required',
      message: 'Estimated As Is Value cannot be 0',
    },
    monthlyGrossRent: {
      value: '',
      error: null,
      rule: 'required',
      nextStep: 'required',
      message: 'Required',
    },
    monthlyMarketRent: {
      value: '',
      error: null,
      rule: 'required',
      nextStep: 'required',
      message: 'Required',
    },
    annualTaxes: {
      value: '',
      error: null,
      rule: 'required',
      message: 'Required',
      nextStep: 'required',
    },
    annualInsurance: {
      value: '',
      error: null,
      rule: 'required',
      nextStep: 'required',
      message: 'Required',
    },
    annualHOA: {
      value: '',
      error: null,
      rule: 'required',
      nextStep: 'required',
      message: 'Required',
    },
    occupancy: {
      value: '',
      error: null,
      rule: 'required',
      nextStep: 'required',
      message: 'Required',
      step2: 'required'
    },
    exitStrategy: {
      value: 'Sale',
      error: null,
      rule: '',
      nextStep: 'required',
      message: 'Required',
      step2: 'required'
    },
    totalAnnualUtilities: {
      value: '',
      error: null,
      rule: '',
      message: 'Required',
    },
    totalAnnualRepairsMaintenance: {
      value: '',
      error: null,
      rule: '',
      message: 'Required',
    },
    totalAnnualPropertyManagementFees: {
      value: '',
      error: null,
      rule: '',
      message: 'Required',
    },
    generalAdministrative: {
      value: 0,
      error: null,
      rule: '',
      message: 'Required',
    },
    payroll: {
      value: 0,
      error: null,
      rule: '',
      message: 'Required',
    },
    marketing: {
      value: 0,
      error: null,
      rule: '',
      message: 'Required',
    },
    replacementReserves: {
      value: '',
      error: null,
      rule: '',
      message: 'Required',
    },
    residentialUnits: {
      value: '',
      error: null,
      rule: '',
      message: 'Required',
    },
    commercialUnits: {
      value: '',
      error: null,
      rule: '',
      message: 'Required',
    },
    residentialUnitsSqFtArea: {
      value: '',
      error: null,
      rule: '',
      message: 'Required',
    },
    commercialUnitsSqFtArea: {
      value: '',
      error: null,
      rule: '',
      message: 'Required',
    },
    commercialUnitsCurrentOnRent: {
      value: '',
      error: null,
      rule: '',
      message: 'Required',
    },
    targetLTV: {
      value: '',
      error: null,
      message: 'reqired',
      rule: '',
    },
    totalAnnualNOI: {
      value: '',
      error: null,
      rule: '',
    },
    totalAnnualIncome: {
      value: '',
      error: null,
      rule: '',
    },
    totalAnnualExpenses: {
      value: '',
      error: null,
      rule: '',
    },
    propertyCounty: {
      value: '',
      error: null,
      rule: '',
    },
    borrowerId: {
      value: null,
      error: null,
      rule: '',
    },
    borrowerExp: {
      value: '',
      error: null,
      rule: '',
      message: '',
    },
    ficoProvidedAtOrigination: {
      value: 660,
      error: null,
      rule: '',
    },
    bankruptcy: {
      value: 'N',
      error: null,
      rule: '',
    },
    bankruptcyDate: {
      value: null,
      error: null,
      rule: '',
    },
    foreclosure: {
      value: 'N',
      error: null,
      rule: '',
    },
    foreclosureDate: {
      value: null,
      error: null,
      rule: '',
    },
    citizenshipStatus: {
      value: 'US Citizen',
      error: null,
      rule: '',
    },
    submitRushedAppraisal: {
      value: false,
      error: null,
      rule: '',
    },
    propertyId: {
      value: null,
      error: null,
      rule: '',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

const propertyDetailsForm = {
  fields: {
    aptNumber: {
      value: '',
      error: null
    },
    address: {
      value: '',
      error: null,
      rule: 'required',
      message: 'Invalid Address Format',
    },
    __isAddressInLicensedState__: {
      value: true,
      error: null,
      rule: 'accepted',
      message: notLicensedSfr.message,
    },
    __isAddressInCorrectFormat__: {
      value: true,
      error: null,
      rule: 'accepted',
      message: 'Invalid Address Format',
    },
    __isAcquisitionPriceValid__: {
      value: true,
      error: null,
      rule: 'accepted',
      message: 'Purchase price should be greater than 0',
    },
    propertyType: {
      value: '',
      error: null,
      rule: 'required',
      message: 'Required',
      step1: 'required',
    },
    loanPurpose: {
      value: '',
      error: null,
      rule: 'required',
    },
    acquisitionPrice: {
      value: '',
      error: null,
      rule: 'required',
      message: 'Required',
    },
    submitRushedAppraisal: {
      value: '',
      error: null,
      rule: '',
    },
    streetNumber: {
      value: '',
      error: null,
      rule: '',
    },
    streetName: {
      value: '',
      error: null,
      rule: '',
    },
    city: {
      value: '',
      error: null,
      rule: 'required',
    },
    state: {
      value: '',
      error: null,
      rule: 'required',
    },
    zipCode: {
      value: '',
      error: null,
      rule: 'required',
    },
    latitude: {
      value: 0,
      error: null,
      rule: '',
    },
    longitude: {
      value: 0,
      error: null,
      rule: '',
    },
    propertyCounty: {
      value: '',
      error: null,
      rule: '',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

const refinance_purchaseInformationForm = {
  fields: {
    anyDebt: {
      value: '',
      error: null,
      rule: 'required',
    },
    capitalImprovements: {
      value: '',
      error: null,
      rule: 'required',
    },
    acquisitionDate: {
      value: null,
      error: null,
      rule: 'required',
    },
    totalDebtPayoff: {
      value: '',
      error: null,
      rule: [{ required_if: ['anyDebt', 'Y'] }]
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

const purchaseInformationForm = {
  fields: {
    armsLength: {
      value: '',
      error: null,
      rule: 'required',
    },
    armsLengthComment: {
      value: '',
      error: null,
      rule: [{ required_if: ['armsLength', 'N'] }],
    },
    propertySourcing: {
      value: '',
      error: null,
      rule: 'required',
    },
    propertySourcingExplanation: {
      value: '',
      error: null,
      rule: [
        { required_if: ['propertySourcing', 'Other'] },
        { required_if: ['wholesalerPurchase', 'Other'] },
      ],
    },
    wholesaleAmount: {
      value: '',
      error: null,
      rule: [
        { required_if: ['propertySourcing', 'Wholesaler'] },
        { max: 1000000000000 },
      ],
    },
    wholesalerPurchase: {
      value: '',
      error: null,
      rule: [{ required_if: ['propertySourcing', 'Wholesaler'] }],
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};


export class PricerStoreStbBridge extends FormStore {
  private globalStore: GlobalStore;
  private stabilizedBridgeStore: StabilizedBridgeStore;
  appraisalId: string;
  loanApplicationUuid: string;
  appraisalInformationStore: AppraisalInformationStore;
  propertyDetailsStore: PropertyDetailsStore;
  purchaseInformationStore: PurchaseInformationStore;
  refinance_purchaseInformationStore: Refinance_PurchaseInformationStore;

  constructor(globalStore, stabilizedBridgeStore) {
    super({ ...propertyForm }, globalStore);
    this.globalStore = globalStore;
    this.stabilizedBridgeStore = stabilizedBridgeStore;
    this.appraisalInformationStore = new AppraisalInformationStore(
      this.globalStore
    );
    this.propertyDetailsStore = new PropertyDetailsStore(this.globalStore);
    this.purchaseInformationStore = new PurchaseInformationStore(
      this.globalStore
    );
    this.refinance_purchaseInformationStore = new Refinance_PurchaseInformationStore(
      this.globalStore
    );
    makeObservable(this, {
      onFormSubmit: flow,
      getTotalExpenses: action,
      getTotalAnnualNOI: action,
      handleLoanPurposeChange: action,
      handleAnyDebtChange: action,
      handleIncomeChange: action,
      handleExpenseChange: action,
      getTotalIncome: action,
      handlePropertyTypeChange: action,
      defaultUnitsFromPropertyType: action,
      checkIfRequiredFieldsEmpty: action,
      checkIfRequiredPropertyFieldsEmpty: action,
      checkIfFieldIsEmpty: action,
      checkForPricerStepErrors: action,
      getPricerStepErrors: action,
      checkForPropertyStepErrors: action,
      handlePropertyRefinanceValidations: action,
      getAnyDebt: action,
      getSunkCost: action,
      getProperty: action,
      counties: computed,
      pricerBorrower: computed,
      isValid: action,
      loadPropertyFromAppraisal: action,
      appraisalId: observable,
      warningInsuranceTenant: computed,
    });
  }

  *onFormSubmit() {
    this.form.meta.error = '';
  }

  loadPropertyFromAppraisal(appraisal) {
    const appraisalProperty = JSON.parse(
      appraisal?.appraisalData?.propertyAppraisalOrderJson
    );
    const property = {
      propertyId: appraisal?.appraisalData?.propertyId,
      appraisalId: appraisal?.appraisalData?.propertyAppraisalId,
      address: appraisalProperty?.property?.address,
      streetNumber: appraisalProperty?.property?.streetNumber,
      streetName: appraisalProperty?.property?.streetName,
      city: appraisalProperty?.property?.city,
      state: appraisalProperty?.property?.state,
      zipCode: appraisalProperty?.property?.zipCode,
      latitude: appraisalProperty?.property?.latitude,
      longitude: appraisalProperty?.property?.longitude,
      acquisitionPrice: appraisalProperty?.purchasePrice,
      acquisitionDate: appraisalProperty?.purchaseDate,
      loanPurpose: appraisalProperty?.propertyOwnership,
      anyDebt: appraisalProperty?.property?.anyDebt,
      sunkCost: appraisalProperty?.sunkCost,
      submitRushedAppraisal: appraisalProperty?.property?.submitRushedAppraisal,
      propertyType: appraisalProperty?.property.propertyType,
      armsLength: appraisalProperty?.armsLength,
      armsLengthComment: appraisalProperty?.armsLengthComment,
      propertySourcing: appraisalProperty?.propertySourcing,
      propertySourcingExplanation:
        appraisalProperty?.property?.propertySourcingExplanation,
      wholesaleAmount: appraisalProperty?.wholesaleAmount,
      wholesalerPurchase: appraisalProperty?.wholesalerPurchase,
      //refiAmount:
      totalRenovationBudget: appraisalProperty?.renovationBudget,
      describeRenovation: appraisalProperty?.describeRenovation,
      afterRepairValue: appraisalProperty?.afterRepairValue,
      exitStrategy: appraisalProperty?.exitStrategy,
      additionalComments: appraisalProperty?.property?.additionalComments,
    };
    this.loadProperty(property, false);
  }

  get isSingleProperty() {
    return true;
  }

  get counties() {
    const { allCounties } = this.stabilizedBridgeStore;
    const states = this.form.fields.state.value
      ? [this.form.fields.state.value]
      : [];
    let selectedStateCounties = [];
    if (states && states.length > 0) {
      selectedStateCounties = states.reduce(
        (c, s) => [...c, ...(allCounties[s] ?? [])],
        []
      );
    }

    const counties = [];
    if (selectedStateCounties && selectedStateCounties.length > 0) {
      selectedStateCounties.sort().map(county => {
        const countyParts = county.split(",");
        counties.push({ value: countyParts[0].trim(), label: county });
      });
    }
    return counties;
  }

  onPropertyStateChanged(value) { }

  getTotalAnnualNOI = () => {
    const { totalAnnualIncome, totalAnnualExpenses } = this.form.fields;
    const totalIncome = isNaN(Number(totalAnnualIncome.value))
      ? 0
      : Number(totalAnnualIncome.value);
    const totalExpenses = isNaN(Number(totalAnnualExpenses.value))
      ? 0
      : Number(totalAnnualExpenses.value);
    const totalAnnualNOI = totalIncome - totalExpenses;
    this.onFieldChange('totalAnnualNOI', totalAnnualNOI);
  };

  handleLoanPurposeChange = (name, value) => {
    this.onFieldChange(name, value);

    if (this.form.fields.loanPurpose.value === constants.PURCHASE) {
      this.form.fields.acquisitionDate.value = null;
      this.form.fields.capitalImprovements.value = '';
      this.form.fields.anyDebt.value = '';
      this.form.fields.totalDebtPayoff.value = '';
    }
  };

  handleAnyDebtChange = (name, value) => {
    this.onFieldChange(name, value);

    if (this.form.fields.anyDebt.value === constants.NO) {
      this.form.fields.totalDebtPayoff.value = '';
    }
  };

  handleIncomeChange = (name, value) => {
    this.onFieldChange(name, value);
    this.getTotalIncome();
    this.getTotalAnnualNOI();
  };

  handleExpenseChange = (name, value) => {
    this.onFieldChange(name, value);
    this.getTotalExpenses();
    this.getTotalAnnualNOI();
  };

  getTotalIncome = () => {
    const rent = isNaN(Number(this.form.fields.monthlyGrossRent.value))
      ? 0
      : Number(this.form.fields.monthlyGrossRent.value);
    const totalAnnualIncome = rent * 12;
    this.onFieldChange('totalAnnualIncome', totalAnnualIncome);
  };

  getTotalExpenses = () => {
    const {
      annualTaxes,
      annualInsurance,
      annualHOA,
      totalAnnualUtilities,
      totalAnnualRepairsMaintenance,
      totalAnnualPropertyManagementFees,
      generalAdministrative,
      payroll,
      marketing,
      replacementReserves,
    } = this.form.fields;
    const taxes = isNaN(Number(annualTaxes.value))
      ? 0
      : Number(annualTaxes.value);
    const insurance = isNaN(Number(annualInsurance.value))
      ? 0
      : Number(annualInsurance.value);
    const hoa = isNaN(Number(annualHOA.value)) ? 0 : Number(annualHOA.value);
    const utilities = isNaN(Number(totalAnnualUtilities.value))
      ? 0
      : Number(totalAnnualUtilities.value);
    const repairsMaintenance = isNaN(
      Number(totalAnnualRepairsMaintenance.value)
    )
      ? 0
      : Number(totalAnnualRepairsMaintenance.value);
    const propertyManagementFees = isNaN(
      Number(totalAnnualPropertyManagementFees.value)
    )
      ? 0
      : Number(totalAnnualPropertyManagementFees.value);
    const generalAdministrativeCal = isNaN(Number(generalAdministrative.value))
      ? 0
      : Number(generalAdministrative.value);
    const payrollCal = isNaN(Number(payroll.value)) ? 0 : Number(payroll.value);
    const marketingCal = isNaN(Number(marketing.value))
      ? 0
      : Number(marketing.value);
    const replacementReservesCal = isNaN(Number(replacementReserves.value))
      ? 0
      : Number(replacementReserves.value);

    const totalAnnualExpenses =
      taxes +
      insurance +
      hoa +
      utilities +
      repairsMaintenance +
      propertyManagementFees +
      generalAdministrativeCal +
      payrollCal +
      marketingCal +
      replacementReservesCal;

    this.onFieldChange('totalAnnualExpenses', totalAnnualExpenses);
  };

  getAnyDebt() {
    return this.refinance_purchaseInformationStore.form.fields.anyDebt.value === 'Y';
  }

  getOccupancy() {
    return this.form.fields.occupancy.value === 'Y' ? 'Tenant Occupied' : 'Vacant';
  }

  getExitStrategy() {
    return this.form.fields.exitStrategy.value;
  }

  getArmsLength() {
    return this.purchaseInformationStore.form.fields.armsLength.value === 'Y';
  }
  getSunkCost() {
    const { loanPurpose } = this.propertyDetailsStore.form.fields;
    const { capitalImprovements } = this.refinance_purchaseInformationStore.form.fields;

    return loanPurpose.value === constants.PURCHASE
      ? 0
      : capitalImprovements.value;
  }

  getUnitsOccupiedHave12MonthsLease() {
    return this.form.fields.unitsOccupiedHave12MonthsLease.value === 'Y';
  }

  getAcquisitionDate() {
    const { acquisitionDate } = this.refinance_purchaseInformationStore.form.fields;
    return acquisitionDate.value;
  }

  getLoanPurpose() {
    const { loanPurpose, anyDebt } = this.form.fields;
    const { form } = this.stabilizedBridgeStore.pricerSummaryStore;
    const { seekingCashOut } = form.fields;
    const loanPurposeValue = constants.mapLoanPurpose(loanPurpose.value, seekingCashOut.value, anyDebt.value);
    return constants.loanPurposeDic[loanPurposeValue];
  }

  getPropertyOwnership(value) {
    return value == 'Purchase' ? 'Purchase' : 'Owned By Borrower';
  }

  getProperty() {
    const data = this.getFormValues();
    const appraisal = this.appraisalInformationStore.getFormValues();
    const propertyDetails = this.propertyDetailsStore.getFormValues();
    const purchase = this.purchaseInformationStore.getFormValues();
    const refinance = this.refinance_purchaseInformationStore.getFormValues();

    if (propertyDetails['loanPurpose'] === 'Purchase') {
      return {
        loanApplicationUuid: this.loanApplicationUuid ?? generateUUID(),
        // Appraisal
        chooseExistingAppraisal: appraisal['chooseExistingAppraisal'],
        orderId: appraisal['orderId'],
        // property details
        propertyId: data['propertyId'] ?? Date.now().toString(),
        aptNumber: propertyDetails['aptNumber'],
        address: propertyDetails['address'],
        streetName: propertyDetails['streetName'],
        streetNumber: propertyDetails['streetNumber'],
        city: propertyDetails['city'],
        state: propertyDetails['state'],
        county: propertyDetails['propertyCounty'],
        zipCode: propertyDetails['zipCode'],
        latitude: propertyDetails['latitude'],
        longitude: propertyDetails['longitude'],
        propertyType: propertyDetails['propertyType'],
        loanPurpose: propertyDetails['loanPurpose'],
        propertyOwnership: this.getPropertyOwnership(propertyDetails['loanPurpose']),
        acquisitionPrice: propertyDetails['acquisitionPrice'],
        submitRushedAppraisal: propertyDetails['submitRushedAppraisal'],

        //purchase
        armsLength: this.getArmsLength(),
        armsLengthComment: purchase['armsLengthComment'],
        propertySourcing: purchase['propertySourcing'],
        propertySourcingExplanation: purchase['propertySourcingExplanation'],
        wholesaleAmount: purchase['wholesaleAmount'],
        wholesalerPurchase: purchase['wholesalerPurchase'],

        // refinance
        anyDebt: this.getAnyDebt(),

        // property info store
        propertyValuation: data['propertyValuation'],
        monthlyGrossRent: data['monthlyGrossRent'],
        monthlyMarketRent: data['monthlyMarketRent'],
        annualTaxes: data['annualTaxes'],
        annualInsurance: data['annualInsurance'],
        annualHOA: data['annualHOA'],
        occupancy: this.getOccupancy(),
        exitStrategy: this.getExitStrategy(),

        // not being used
        units: data['units'],
        unitsOccupied: data['unitsOccupied'],
        unitsOccupiedHave12MonthsLease: this.getUnitsOccupiedHave12MonthsLease(),
        totalAnnualUtilities: data['totalAnnualUtilities'],
        totalAnnualRepairsMaintenance: data['totalAnnualRepairsMaintenance'],
        totalAnnualPropertyManagementFees:
          data['totalAnnualPropertyManagementFees'],
        generalAdministrative: data['generalAdministrative'],
        payroll: data['payroll'],
        marketing: data['marketing'],
        replacementReserves: data['replacementReserves'],
        residentialUnits: data['residentialUnits'],
        commercialUnits: data['commercialUnits'],
        residentialUnitsSqFtArea: data['residentialUnitsSqFtArea'],
        commercialUnitsSqFtArea: data['commercialUnitsSqFtArea'],
        commercialUnitsCurrentOnRent: data['commercialUnitsCurrentOnRent'],
        targetLTV: data['targetLTV'],
        totalAnnualNOI: data['totalAnnualNOI'],
        totalAnnualIncome: data['totalAnnualIncome'],
        totalAnnualExpenses: data['totalAnnualExpenses'],
        selectedStateCounties: data['selectedStateCounties'],
        propertyCounties: [data['propertyCounty']],
        propertyCountiesSelectedValues: [],
        sunkCost: this.getSunkCost(),
        loanRecourse: 'Full Recourse'
      };
    }
    else {
      return {
        loanApplicationUuid: this.loanApplicationUuid ?? generateUUID(),
        // Appraisal
        chooseExistingAppraisal: appraisal['chooseExistingAppraisal'],
        orderId: appraisal['orderId'],
        // Property details
        propertyId: data['propertyId'] ?? Date.now().toString(),
        address: propertyDetails['address'],
        streetName: propertyDetails['streetName'],
        streetNumber: propertyDetails['streetNumber'],
        city: propertyDetails['city'],
        state: propertyDetails['state'],
        county: propertyDetails['propertyCounty'],
        zipCode: propertyDetails['zipCode'],
        latitude: propertyDetails['latitude'],
        longitude: propertyDetails['longitude'],
        propertyType: propertyDetails['propertyType'],
        loanPurpose: propertyDetails['loanPurpose'],
        propertyOwnership: this.getPropertyOwnership(propertyDetails['loanPurpose']),
        acquisitionPrice: propertyDetails['acquisitionPrice'],
        submitRushedAppraisal: propertyDetails['submitRushedAppraisal'],

        // refinance
        capitalImprovements: refinance['capitalImprovements'],
        anyDebt: this.getAnyDebt(),
        totalDebtPayoff: refinance['totalDebtPayoff'],
        acquisitionDate: this.getAcquisitionDate(),

        // property info store
        propertyValuation: data['propertyValuation'],
        monthlyGrossRent: data['monthlyGrossRent'],
        monthlyMarketRent: data['monthlyMarketRent'],
        annualTaxes: data['annualTaxes'],
        annualInsurance: data['annualInsurance'],
        annualHOA: data['annualHOA'],
        occupancy: this.getOccupancy(),
        exitStrategy: this.getExitStrategy(),

        // not being used
        units: data['units'],
        unitsOccupied: data['unitsOccupied'],
        unitsOccupiedHave12MonthsLease: this.getUnitsOccupiedHave12MonthsLease(),
        totalAnnualUtilities: data['totalAnnualUtilities'],
        totalAnnualRepairsMaintenance: data['totalAnnualRepairsMaintenance'],
        totalAnnualPropertyManagementFees:
          data['totalAnnualPropertyManagementFees'],
        generalAdministrative: data['generalAdministrative'],
        payroll: data['payroll'],
        marketing: data['marketing'],
        replacementReserves: data['replacementReserves'],
        residentialUnits: data['residentialUnits'],
        commercialUnits: data['commercialUnits'],
        residentialUnitsSqFtArea: data['residentialUnitsSqFtArea'],
        commercialUnitsSqFtArea: data['commercialUnitsSqFtArea'],
        commercialUnitsCurrentOnRent: data['commercialUnitsCurrentOnRent'],
        targetLTV: data['targetLTV'],
        totalAnnualNOI: data['totalAnnualNOI'],
        totalAnnualIncome: data['totalAnnualIncome'],
        totalAnnualExpenses: data['totalAnnualExpenses'],
        selectedStateCounties: data['selectedStateCounties'],
        propertyCounties: [data['propertyCounty']],
        propertyCountiesSelectedValues: [],
        sunkCost: this.getSunkCost(),
        loanRecourse: 'Full Recourse',
      }
    };
  }

  setAppraisalId(appraisalId) {
    this.appraisalId = appraisalId;
  }

  setLoanApplicationUuid(uuid) {
    this.loanApplicationUuid = uuid;
  }

  loadProperty(property, runValidations: boolean = true) {
    if (property.anyDebt === true) {
      property.anyDebt = 'Y'
    } else {
      property.anyDebt = 'N'
    }

    if (property.armsLength === true) {
      property.armsLength = 'Y'
    } else {
      property.armsLength = 'N'
    }

    if (property.occupancy === 'Tenant Occupied') {
      property.occupancy = 'Y'
    } else {
      property.occupancy = 'N'
    }

    if (property.exitStrategy === 'Rent and Refinance') {
      property.exitStrategy = 'Rent and Refinance'
    } else {
      property.exitStrategy = 'Sale'
    }

    this.propertyDetailsStore.loadForm(property);
    this.purchaseInformationStore.loadForm(property);
    this.refinance_purchaseInformationStore.loadForm(property);
    this.loadForm(property);
    this.setLoanApplicationUuid(property.loanApplicationUuid);
  }

  isValid() {
    const fields = this.form.fields;
    if (fields.loanPurpose.value === REFINANCE) {
      return !this.checkIfRequiredFieldsEmpty() && !this.handlePropertyRefinanceValidations()
    }
    else
      return !this.checkIfRequiredFieldsEmpty()
  }

  resetStores() {
    this.propertyDetailsStore.reset();
    this.purchaseInformationStore.reset();
    this.refinance_purchaseInformationStore.reset();
    this.appraisalInformationStore.reset();
    this.appraisalId = null;
  }

  private saveAddressField(address: string, addressDetails, geometryDetails) {
    const parsedAddress = parseAddress(
      address,
      addressDetails,
      geometryDetails
    );
    this.onFieldChange('address', address);
    this.onFieldChange('streetNumber', parsedAddress.street_number);
    this.onFieldChange('streetName', parsedAddress.street);
    this.onFieldChange('city', parsedAddress.city);
    this.onFieldChange('state', parsedAddress.state);
    this.onFieldChange('propertyCounty', parsedAddress.county);
    this.onFieldChange('zipCode', parsedAddress.zip);
    this.onFieldChange('latitude', parsedAddress.latitude);
    this.onFieldChange('longitude', parsedAddress.longitude);

    const addressInLicensedState = !notLicensedSfr.states.includes(
      parsedAddress.state
    );
    this.onFieldChange('__isAddressInLicensedState__', addressInLicensedState);

    const addressInCorrectFormat = parsedAddress.street_number !== null && parsedAddress.street !== null && parsedAddress.city !== null && parsedAddress.state !== null && parsedAddress.zip !== null;
    this.onFieldChange('__isAddressInCorrectFormat__', addressInCorrectFormat);

    this.stabilizedBridgeStore.loanDetailsStore.setDefaultPreferredTitle();
  }

  handlePropertyTypeChange = propertyType => {
    this.defaultUnitsFromPropertyType(propertyType);

    if (!PROPERTY_TYPES_RUSHED_APPRAISAL.includes(propertyType)) {
      this.onFieldChange('submitRushedAppraisal', false);
    }
  };

  defaultUnitsFromPropertyType = value => {
    this.onFieldChange('residentialUnits', '');
    this.onFieldChange('commercialUnits', '');
    switch (value) {
      case 'Single Family':
      case 'Condominium':
        this.onFieldChange('units', 1);
        break;
      case 'Duplex':
        this.onFieldChange('units', 2);
        break;
      case 'Triplex':
        this.onFieldChange('units', 3);
        break;
      case 'Quadruplex':
        this.onFieldChange('units', 4);
        break;
      default:
        this.onFieldChange('units', '');
        break;
    }
  };

  checkIfRequiredFieldsEmpty = () => {
    let requiredFieldEmpty = false;
    const fields = this.form.fields;
    for (let property in fields) {
      if (
        fields[property].nextStep === 'required' &&
        !fields[property].value &&
        this.checkIfFieldIsEmpty(fields, property)
      ) {
        requiredFieldEmpty = true;
      }
    }
    return requiredFieldEmpty;
  };

  checkIfRequiredPropertyFieldsEmpty = () => {
    let requiredFieldEmpty = false;
    const fields = this.form.fields;
    for (let property in fields) {
      if (
        fields[property].nextStep === 'requiredForProperty' &&
        !fields[property].value
      ) {
        requiredFieldEmpty = this.checkIfFieldIsEmpty(fields, property);
      }
    }
    return requiredFieldEmpty;
  };

  checkIfFieldIsEmpty(fields, property) {
    let requiredFieldEmpty = false;
    if (
      typeof fields[property].value === 'number' &&
      fields[property].value === 0
    ) {
      return requiredFieldEmpty;
    } else {
      this.onFieldChange(property, '');
      requiredFieldEmpty = true;
      return requiredFieldEmpty;
    }
  }

  getPricerStepErrors() {
    const fields = this.form.fields;
    let errors = {} as any;
    if (
      notLicensedSfr.states.includes(this.getProperty()?.state?.toUpperCase())
    ) {
      const errorMsg = notLicensedSfr.message;
      this.form.fields.address.error = errorMsg;
      this.stabilizedBridgeStore.stepError = errorMsg;
      return true;
    }
    if (fields.__isAddressInCorrectFormat__.value !== true) {
      this.form.fields.address.error = 'Invalid Address Format';
      this.stabilizedBridgeStore.stepError = 'Invalid Address Format';
    }
    if (fields.propertyType.value === MIXED_USE) {
      if (fields.residentialUnits.value < 1) {
        errors.residentialUnits =
          'Number of Residential units should be greater than 1';
      }
      if (fields.commercialUnits.value < 1) {
        errors.commercialUnits =
          'Number of Commercial units should be greater than 1';
      }
      if (Number(fields.residentialUnitsSqFtArea.value) <= 0) {
        errors.residentialUnitsSqFtArea =
          'Residential Units sq Ft Area should be greater than 0';
      }
      if (Number(fields.commercialUnitsSqFtArea.value) <= 0) {
        errors.commercialUnitsSqFtArea =
          'Commercial Units sq Ft Area should be greater than 0';
      }
      if (
        Number(fields.commercialUnitsSqFtArea.value) >
        Number(fields.residentialUnitsSqFtArea.value)
      ) {
        errors.residentialUnitsSqFtArea =
          'Commercial Units sq Ft Area should be less than Residential Units sq Ft Area';
      }
    } else {
      if (Number(fields.units.value) < 1) {
        errors.residentialUnitsSqFtArea =
          'Number of units should be greater than or equal to 1';
      }
    }
    if (fields.propertyValuation.value <= 0) {
      errors.propertyValuation =
        'Estimated As Is Value should be greater than 0';
    }
    if (fields.monthlyGrossRent.value <= 0) {
      errors.monthlyGrossRent = 'Gross Monthly Rent should be greater than 0';
    }
    if (fields.totalAnnualNOI.value < 0) {
      errors.totalAnnualNOI = 'Property has negative income';
    }
    if (fields.acquisitionPrice.value <= 0) {
      errors.acquisitionPrice = 'Purchase Price should be greater than 0';
    }
    if (fields.targetLTV.value <= 0) {
      errors.targetLTV = 'Target LTV should be greater than 0';
    }
    if (fields.targetLTV.value > 80) {
      errors.targetLTV = 'Maximum target LTV is 80%';
    }
    if (fields.loanPurpose.value === REFINANCE) {
      this.handlePropertyRefinanceValidations();
    }
    if (fields.ficoProvidedAtOrigination.value < 660) {
      errors.ficoProvidedAtOrigination =
        'Sorry we cannot underwrite loans where the borrowers have less than 660 mid-score FICO';
    }
    if (
      fields.bankruptcy.value === 'Y' &&
      !this.stabilizedBridgeStore.isValidBankruptcyDate(
        fields.bankruptcyDate.value
      )
    ) {
      errors.bankruptcyDate = 'Bankruptcy Date must be no less than 3 Years';
    }
    if (
      fields.foreclosure.value === 'Y' &&
      !this.stabilizedBridgeStore.isValidForeclosureDate(
        fields.foreclosureDate.value
      )
    ) {
      errors.foreclosureDate = 'Foreclosure Date must be no less than 3 Years';
    }

    return errors;
  }

  checkForPricerStepErrors() {
    const fields = this.form.fields;

    const errors = this.getPricerStepErrors();
    for (let name in errors) {
      this.stabilizedBridgeStore.stepError = errors[name];
      if (fields[name]) {
        fields[name].error = errors[name];
      }
    }

    if (this.stabilizedBridgeStore.stepError) {
      return true;
    }
  }

  checkForPropertyStepErrors() {
    if (
      notLicensedSfr.states.includes(this.getProperty()?.state?.toUpperCase())
    ) {
      const errorMsg = notLicensedSfr.message;
      this.form.fields.address.error = errorMsg;
      this.stabilizedBridgeStore.stepError = errorMsg;
      return true;
    }
    // if (
    //   this.form.fields.unitsOccupied.value >
    //   this.stabilizedBridgeStore.getNumberOfUnits()
    // )
    {
      const errorMsg = 'Can not be higher than Number of Units';
      this.form.fields.unitsOccupied.error = errorMsg;
      this.stabilizedBridgeStore.stepError = errorMsg;
      return true;
    }
  }

  handlePropertyRefinanceValidations = () => {
    const fields = this.form.fields;

    let errorMsg = '';
    const {
      acquisitionDate,
      capitalImprovements,
      anyDebt,
      totalDebtPayoff,
    } = this.form.fields;
    if (!acquisitionDate.value) {
      errorMsg = 'Purchase date cannot be blank';
      fields.acquisitionDate.error = errorMsg;
      this.stabilizedBridgeStore.stepError = errorMsg;
      return true;
    }
    if (capitalImprovements.value === '') {
      errorMsg = 'Completed Renovations cannot be blank';
      fields.capitalImprovements.error = errorMsg;
      this.stabilizedBridgeStore.stepError = errorMsg;
      return true;
    }
    if (capitalImprovements.value !== '') {
      if (anyDebt.value === '') {
        errorMsg = 'Current Debt On Property cannot be blank';
        fields.anyDebt.error = errorMsg;
        this.stabilizedBridgeStore.stepError = errorMsg;
        return true;
      }
      if (anyDebt.value === 'Y') {
        if (totalDebtPayoff.value === '') {
          errorMsg = 'Current Debt Amount on the property cannot be blank';
          fields.totalDebtPayoff.error = errorMsg;
          this.stabilizedBridgeStore.stepError = errorMsg;
          return true;
        }
        if (totalDebtPayoff.value <= 0) {
          errorMsg = 'Current Debt Amount on the property cannot be 0';
          fields.totalDebtPayoff.error = errorMsg;
          this.stabilizedBridgeStore.stepError = errorMsg;
          return true;
        }
      }
    }
    return false;
  };

  get warningInsuranceTenant() {
    return this.getExitStrategy() === 'Rent and Refinance' || this.getOccupancy() === 'Tenant Occupied';
  }

  get pricerBorrower() {
    return {
      borrowerExp: this.form.fields.borrowerExp.value,
      ficoProvidedAtOrigination: this.form.fields.ficoProvidedAtOrigination
        .value,
      bankruptcy: this.form.fields.bankruptcy.value,
      foreclosure: this.form.fields.foreclosure.value,
      citizenshipStatus: this.form.fields.citizenshipStatus.value,
    };
  }

  get foreignNational() {
    return this.form.fields.citizenshipStatus.value;
  }

  get borrowerExp() {
    return this.form.fields.borrowerExp.value;
  }

  get ficoProvidedAtOrigination() {
    return this.form.fields.ficoProvidedAtOrigination.value;
  }

  get totalMixUseEstimatedAsIsValue() {
    return null;
  }

  get residentialUnits() {
    return this.form.fields.residentialUnits.value ?? 0;
  }

  get commercialUnits() {
    return this.form.fields.commercialUnits.value ?? 0;
  }

}

export class AppraisalInformationStore extends FormStore {
  public globalStore: GlobalStore;
  constructor(globalStore) {
    super({ ...appraisalInformationForm }, globalStore);
    this.globalStore = globalStore;
    makeObservable(this, {
      onFormSubmit: flow,
    });
  }

  *onFormSubmit() {
    this.form.meta.error = '';
  }
}

class Refinance_PurchaseInformationStore extends FormStore {
  private globalStore: GlobalStore;
  constructor(globalStore) {
    super({ ...refinance_purchaseInformationForm }, globalStore);
    this.globalStore = globalStore;
    makeObservable(this, {
      onFormSubmit: flow,
      handleAnyDebtChange: action,
    });
  }

  *onFormSubmit() {
    this.form.meta.error = '';
  }

  handleAnyDebtChange = (name, value) => {
    this.onFieldChange(name, value);

    if (this.form.fields.anyDebt.value === constants.NO) {
      this.form.fields.totalDebtPayoff.value = '0';
    }
  };
}
export default PricerStoreStbBridge;

export class PurchaseInformationStore extends FormStore {
  private globalStore: GlobalStore;
  constructor(globalStore) {
    super({ ...purchaseInformationForm }, globalStore);
    this.globalStore = globalStore;
    makeObservable(this, {
      onFormSubmit: flow,
    });
  }

  *onFormSubmit() {
    this.form.meta.error = '';
  }
}

export class PropertyDetailsStore extends FormStore {
  private globalStore: GlobalStore;
  private stabilizedBridgeStore: StabilizedBridgeStore;
  constructor(globalStore) {
    super({ ...propertyDetailsForm }, globalStore);
    this.globalStore = globalStore;
    makeObservable(this, {
      onFormSubmit: flow,
      handleLoanPurposeChange: action,
      handlePropertyTypeChange: action
    });
  }

  *onFormSubmit() {
    this.form.meta.error = '';
  }

  onAcquisitionPriceChange(value) {
    this.form.fields.acquisitionPrice.value == "" ?
      this.form.fields.acquisitionPrice.value = value : this.form.fields.acquisitionPrice.value = Number(value)

    if (this.form.fields.acquisitionPrice.value <= 0) {
      this.onFieldChange('__isAcquisitionPriceValid__', false);
    }
    else {
      this.onFieldChange('__isAcquisitionPriceValid__', true);
    }
  }

  handleLoanPurposeChange = (name, value) => {
    this.onFieldChange(name, value);

    if (this.form.fields.loanPurpose.value === constants.PURCHASE) {
      this.stabilizedBridgeStore.pricerStore.refinance_purchaseInformationStore.form.fields.acquisitionDate.value = null;
      this.stabilizedBridgeStore.pricerStore.refinance_purchaseInformationStore.form.fields.capitalImprovements.value = '';
      this.stabilizedBridgeStore.pricerStore.refinance_purchaseInformationStore.form.fields.anyDebt.value = '';
      this.stabilizedBridgeStore.pricerStore.refinance_purchaseInformationStore.form.fields.totalDebtPayoff.value = '';
    }
  };

  handlePropertyTypeChange = propertyType => {
    this.defaultUnitsFromPropertyType(propertyType);

    if (!PROPERTY_TYPES_RUSHED_APPRAISAL.includes(propertyType)) {
      this.onFieldChange('submitRushedAppraisal', false);
    }
  };

  defaultUnitsFromPropertyType = value => {
    this.onFieldChange('residentialUnits', '');
    this.onFieldChange('commercialUnits', '');
    switch (value) {
      case 'Single Family':
      case 'Condominium':
        this.onFieldChange('units', 1);
        break;
      case 'Duplex':
        this.onFieldChange('units', 2);
        break;
      case 'Triplex':
        this.onFieldChange('units', 3);
        break;
      case 'Quadruplex':
        this.onFieldChange('units', 4);
        break;
      default:
        this.onFieldChange('units', '');
        break;
    }
  };

  private saveAddressField(address: string, addressDetails, geometryDetails) {
    const parsedAddress = parseAddress(
      address,
      addressDetails,
      geometryDetails
    );

    const streetAddress = parsedAddress.street_number === null ? parsedAddress.street : parsedAddress.street_number + " " + parsedAddress.street;
    this.onFieldChange('address', streetAddress);
    this.onFieldChange('streetNumber', parsedAddress.street_number);
    this.onFieldChange('streetName', parsedAddress.street);
    this.onFieldChange('city', parsedAddress.city);
    this.onFieldChange('state', parsedAddress.state);
    this.onFieldChange('propertyCounty', parsedAddress.county);
    this.onFieldChange('zipCode', parsedAddress.zip);
    this.onFieldChange('latitude', parsedAddress.latitude);
    this.onFieldChange('longitude', parsedAddress.longitude);
    this.onFieldChange('aptNumber', parsedAddress.aptNumber);

    const addressInLicensedState = !notLicensedSfr.states.includes(
      parsedAddress.state
    );
    this.onFieldChange('__isAddressInLicensedState__', addressInLicensedState);

    const addressInCorrectFormat = true;
    this.onFieldChange('__isAddressInCorrectFormat__', addressInCorrectFormat);

    this.stabilizedBridgeStore.loanDetailsStore.setDefaultPreferredTitle();
  }
}