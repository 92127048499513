import { useEffect } from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';

import { DecodedData } from '../tamariskPaymentsUtils';

import TamariskPaymentComplete from './tamariskPaymentComplete';
import { useTamariskPaymentsStore } from '../hooks/useTamariskPaymentsStore';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    creditCardAgreeCheckbox: {
      alignItems: 'flex-start',
    },
    cardKnoxIframe: {
      border: 0,
      height: '1060px',
      width: '100%',
      [theme.breakpoints.down(975)]: {
        height: '2100px',
      },
    },
    hide: {
      display: 'none',
    },
  })
);

interface Props {
  handleDisableStepperNavigation: (boolean: boolean) => void;
  entityData: DecodedData;
}

const TamariskPayment = observer((props: Props) => {
  const { tamariskPaymentsStore } = useTamariskPaymentsStore();
  const { paymentStatus } = tamariskPaymentsStore;
  const { entityData } = props;
  const classes = useStyles();

  useEffect(() => {
    props.handleDisableStepperNavigation(!paymentStatus);
  }, [paymentStatus]);

  return (
    <>
      <Box style={{ maxWidth: 840, margin: 'auto' }}>
        <Box mb={2}>
          <Typography variant="h4">Payment</Typography>
        </Box>
      </Box>

      <Box mt={3} style={{ maxWidth: 840, margin: 'auto' }}>
        {!paymentStatus ? (
          <Box mt={3}>
            {!!entityData && (
              <iframe
                className={classes.cardKnoxIframe}
                onLoad={() => { }}
                src={tamariskPaymentsStore.tamariskDocumentStore.cardKnoxURL}
                scrolling="no"
              />
            )}
          </Box>
        ) : (
          <Box mt={1}>
            <TamariskPaymentComplete />
          </Box>
        )}
      </Box>
    </>
  );
});

export default TamariskPayment;
