
import { GlobalStore, GridStore } from '@roc/feature-app-core';
import { action, flow, makeObservable, observable } from 'mobx';
import { ApiResponse } from '@roc/feature-app-core';
import { SpecialServicingService } from '../services/specialServicingService';
import { VALID_ATTEST_STATUS } from '../utils/constants';

export class AttestationDashboardStore {
  globalStore: GlobalStore;
  specialServicingService: SpecialServicingService;
  specialServicingGridStore: GridStore;
  loanId: number;
  type: string;
  records: any[];
  attestEnabled: boolean;

  constructor(globalStore: GlobalStore) {
    this.globalStore = globalStore;
    this.specialServicingService = new SpecialServicingService();
    this.specialServicingGridStore = new GridStore(() => this.getAttestationDashboard());
    makeObservable(this, {
      reset: flow,
      loanId: observable,
      type: observable,
      setData: action,
      performAction: flow,
      attest: flow,
      attestEnabled: observable,
      setAttestEnabled: action,
    })
  }

  private async getAttestationDashboard() {
    try {
      const filters = {
        ...this.specialServicingGridStore.gridData.meta.filters
      };
      const response: ApiResponse = await this.specialServicingService.getAttestationDashboard(
        this.loanId,
        this.type,
        this.specialServicingGridStore.gridData.meta.pageNumber,
        this.specialServicingGridStore.gridData.meta.pageSize,
        this.specialServicingGridStore.gridData.meta.sortDir,
        this.specialServicingGridStore.gridData.meta.sortBy,
        filters,
        this.specialServicingGridStore.gridData.meta.dropdownFilters
      );
      this.records = response?.data?.data?.rows;
      this.checkIfValidAttest();
      return response;
    } catch (error) {
      console.log("Error", error);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching dashboard.',
      });
    }
  }

  *attest(id: number, type: string) {
    try {
      const response = yield this.specialServicingService.attest(id, type);
      if (response.data.status === 'OK') {
        this.globalStore.notificationStore.showSuccessNotification({
          message: 'Status updated successfully.'
        });
      }
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while updating status.',
      });
    }
  }

  reset() {
    this.specialServicingGridStore.resetAndFetchGridData();
  };

  setData(loanId: number, type: string) {
    this.loanId = loanId;
    this.type = type;
  };

  setAttestEnabled(attestEnabled: boolean) {
    this.attestEnabled = attestEnabled;
  };

  checkIfValidAttest() {
    const validStatus = this.records.every(record => VALID_ATTEST_STATUS.includes(record?.status));
    this.setAttestEnabled(validStatus);
  };

  *performAction(id: number, status: string) {
    const idList: number[] = [id];
    try {
      const response = yield this.specialServicingService.performPayableAction(idList, status);
      if (response.data.status === 'OK') {
        this.globalStore.notificationStore.showSuccessNotification({
          message: 'Status updated successfully.'
        });
      }
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while updating status.',
      });
    }
  }


}