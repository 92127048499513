import { IconButton } from '@material-ui/core';
import { GetApp } from '@material-ui/icons';
import { useInternalDelinquencyStore } from '@roc/feature-appraisals';
import { useDocumentStore } from 'libs/feature-documents/src/documents/hooks/useDocumentStore';
import { DataGrid, GridColumnProps, StatusChip, StatusType } from '@roc/ui';

import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { DateFormat, formatDate } from '@roc/feature-utils';
import { formatCurrency } from '@roc/ui/utils';

const noOpComparator = () => 0;

const columns: GridColumnProps[] = [
  {
    field: 'orderId',
    headerName: 'Order Id',
    comparator: noOpComparator,
    sortable: false,
  },
  {
    field: 'propertyAddress',
    headerName: 'Property Address',
    minWidth: 200,
    comparator: noOpComparator,
    sortable: false,
  },
  {
    field: 'orderType',
    headerName: 'Type',
    comparator: noOpComparator,
    cellRenderer: 'statusCellRenderer',
    sortable: false,
  },
  {
    field: 'orderStatus',
    headerName: 'Status',
    comparator: noOpComparator,
    cellRenderer: 'statusCellRenderer',
    sortable: false,
  },
  {
    field: 'appraisalValue',
    headerName: 'As Is Value',
    sortable: false,
    cellRenderer: 'dollarCellRenderer',
    comparator: noOpComparator,
  },
  {
    field: 'afterRepairValue',
    headerName: 'After Repair Value',
    sortable: false,
    cellRenderer: 'dollarCellRenderer',
    comparator: noOpComparator,
  },
  {
    field: 'creationDate',
    headerName: 'Creation Date',
    sortable: false,
    cellRenderer: 'dateCellRenderer',
    comparator: noOpComparator,
  },
  {
    field: 'effectiveDate',
    headerName: 'Effective Date',
    sortable: false,
    cellRenderer: 'dateCellRenderer',
    comparator: noOpComparator,
  },
  {
    field: 'action',
    headerName: 'Action',
    maxWidth: 80,
    sortable: false,
    cellRenderer: 'downloadButtonCellRenderer',
    comparator: noOpComparator,
  },
];

export const AppraisalDetailsGrid = observer(({ loanId }: { loanId: number }) => {

  const [rows, setRows] = useState([])

  const { internalDelinquencyBpoStore } = useInternalDelinquencyStore();
  const { documentStore } = useDocumentStore();

  useEffect(() => {
    if (internalDelinquencyBpoStore?.loanForeclosureValuations) {
      setRows(internalDelinquencyBpoStore.loanForeclosureValuations.map((item) => item))
    }
  }, [internalDelinquencyBpoStore?.loanForeclosureValuations])

  const frameworkComponents = {
    statusCellRenderer: ({ value }) =>
      value ? (<StatusChip statusType={StatusType.DOCUMENT} label={value} size="small" />) : null,

    downloadButtonCellRenderer: params =>
      <IconButton disabled={!params?.node?.data?.dropboxPath} onClick={() => {
        const document = {
          dropboxPath: params?.node?.data?.dropboxPath,
          dropboxRev: params?.node?.data?.dropboxRev,
          originalFileName: params?.node?.data?.originalFileName
        };
        documentStore.downloadHistoryDocument(document);
      }}>
        <GetApp />
      </IconButton>,

    dateCellRenderer: ({ value }) => formatDate(value, DateFormat.MMDDYYYY) ?? null,
    dollarCellRenderer: ({ value }) => formatCurrency(value) ?? null
  };

  return (
    <DataGrid
      columns={columns}
      rows={rows}
      frameworkComponents={frameworkComponents}
      isLoading={false}
      showFilters={true}
      domLayout="autoHeight"
      rowHeight={80}
    />
  );
});