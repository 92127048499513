import {
  Paper as MuiPaper,
  PaperProps as MuiPaperProps, Typography
} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { Variant } from '@material-ui/core/styles/createTypography';
import clsx from 'clsx';
import React from 'react';
import { TestDataAttribute } from '../testDataAttribute';

export enum PaperBorderType {
  SHADOW_BORDER,
  SOLID_BORDER,
  NO_BORDER,
}

export type PaperProps = MuiPaperProps & {
  titleVariant?: Variant,
  titleClass?: string,
  titleComponent?: React.ReactNode,
  testId?: string,
  borderType?: PaperBorderType,
};

export type PaperTitleProps = {
  titleVariant?: Variant,
  titleClass?: string,
  title?: string,
  testId?: string,
};

export const usePaperStyles = makeStyles(theme => ({
  paper: {
    borderRadius: theme.spacing(2),
  },
  shadowBorder: {
    boxShadow:
      'rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px',
  },
  solidBorder: {
    border:
      '1px solid #eee',
  },
  noBorder: {
    border: 'none',
  },
}));

export const PaperTitle = ({
  titleVariant,
  titleClass,
  title,
  testId
}: PaperTitleProps) => {
  return (
    title ? <Box display="flex" p={2} className={titleClass}>
      <TestDataAttribute id={testId}>
        <Typography variant={titleVariant}>{title}</Typography >
      </TestDataAttribute>
    </Box > : null
  );
};

export const Paper: React.FC<PaperProps> = ({
  title,
  titleComponent,
  titleVariant = 'h5',
  titleClass,
  children,
  className,
  testId,
  borderType = PaperBorderType.SHADOW_BORDER,
  ...muiPaperProps
}) => {
  const classes = usePaperStyles();
  const classNameArr = [classes.paper];
  if (borderType === PaperBorderType.NO_BORDER) {
    classNameArr.push(classes.noBorder);
  }
  else if (borderType === PaperBorderType.SOLID_BORDER) {
    classNameArr.push(classes.solidBorder);
  }
  else if (borderType === PaperBorderType.SHADOW_BORDER) {
    classNameArr.push(classes.shadowBorder);
  }
  if (className) {
    classNameArr.push(className);
  }

  return (
    <MuiPaper
      variant="elevation"
      elevation={0}
      className={clsx(classNameArr)}
      {...muiPaperProps}
    >
      {
        titleComponent ? titleComponent : <PaperTitle title={title} titleClass={titleClass} titleVariant={titleVariant} testId={testId} />
      }
      {children}
    </MuiPaper>
  );
};
