import { Tabs } from '@roc/ui';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { AdditionalBorrowerEntityTab } from './additionalBorrowerEntityTab';
import { BorrowersTab } from './borrowersTab';
import { LoanPreferencesTab } from './loanPreferencesTab';
import { OriginatorTab } from './originatorTab';

const TABS = [
  {
    label: 'Loan Preferences',
  },
  {
    label: 'Additional Borrower Entity Members',
  },
  {
    label: 'Borrowers',
  },
  {
    label: 'Originator',
  },
];

export const FundingPreferenceForm = observer(({ store }) => {
  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <div>
      <Tabs
        tabs={TABS}
        selectedTab={selectedTab}
        onTabChange={(e, index) => setSelectedTab(index)}
      />
      {selectedTab === 0 && <LoanPreferencesTab store={store} />}
      {selectedTab === 1 && <AdditionalBorrowerEntityTab store={store} />}
      {selectedTab === 2 && <BorrowersTab store={store} />}
      {selectedTab === 3 && <OriginatorTab store={store} />}
    </div>
  );
});

export default FundingPreferenceForm;
