import React from 'react';
import { Grid, Typography, makeStyles, Box } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import { PropertyFormStore } from '../../../stores/properties/propertyFormStore';
import { notLicensedinState, requiredLicenseStates, statePropertyTypeRestriction } from '@roc/feature-utils';
import { observer } from 'mobx-react-lite';
import { Error } from '@material-ui/icons';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    backgroundColor: '#FFF4E5',
    borderRadius: '8px',
    marginTop: '16px',
    padding: '14px 16px',
    alignItems: 'center',
  },
  icon: {
    color: '#EF6C00',
    marginRight: '12px',
  },
  title: {
    fontWeight: 'bold',
    color: '#663C00',
  },
  description: {
    color: '#1E4620',
  },
}));

interface Props {
  store: PropertyFormStore;
}

export const PropertyAddressMessages = observer(({ store }: Props) => {
  const state = store.form.fields.state.value;
  const propertyType = store.form.fields.propertyType.value;
  const isAppraisalAvailable = !!store.form.fields.isAppraisalAvailable.value;
  const notLicensedState = notLicensedinState.states.includes(state);
  const licenseRequired = requiredLicenseStates.includes(state);
  const isFloridaCondo = statePropertyTypeRestriction.states.includes(state) && statePropertyTypeRestriction.propertyTypes.includes(propertyType);

  return (
    <>
      {notLicensedState && <NotLicensedInState />}
      {licenseRequired && <LicenseRequired />}
      {isAppraisalAvailable && <AppraisalAvailable />}
      {isFloridaCondo && <IsFloridaCondo />}
    </>
  );
});

const NotLicensedInState = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root} style={{ backgroundColor: '#FDEDED' }}>
      <ErrorOutlineIcon className={classes.icon} style={{ color: '#D32F2F' }} />
      <Typography
        className={classes.title}
        variant="body1"
        style={{ color: '#5F2120' }}
      >
        {notLicensedinState.message}
      </Typography>
    </Box>
  );
};

const LicenseRequired = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <WarningIcon className={classes.icon} />
      <Box>
        <Typography className={classes.title} variant="body1">
          As a lender, it is essential to obtain the necessary licenses to
          operate legally in AZ and NV.
        </Typography>
        <Typography className={classes.description} variant="body2">
          Make sure to check the respective state regulatory agencies for the
          most current information and guidelines to ensure compliance.
        </Typography>
      </Box>
    </Box>
  );
};

const AppraisalAvailable = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root} style={{ backgroundColor: '#EDF7ED' }}>
      <CheckCircleOutlineIcon
        className={classes.icon}
        style={{ color: '#2E7D32' }}
      />
      <Typography
        className={classes.title}
        variant="body1"
        style={{ color: '#1E4620' }}
      >
        Appraisal Available
      </Typography>
    </Box>
  );
};

const IsFloridaCondo = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root} style={{ backgroundColor: '#FDEDED' }}>
      <ErrorOutlineIcon className={classes.icon} style={{ color: '#D32F2F' }} />
      <Typography
        className={classes.title}
        variant="body1"
        style={{ color: '#5F2120' }}
      >
        {statePropertyTypeRestriction.message}
      </Typography>
    </Box>
  );
};