import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { Button, RadioField } from '@roc/ui';
import { useDocumentStore } from '@roc/feature-documents';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { DocumentStatus } from '../../../../constants';
import { GoodFaithDeposit } from '../../goodFaithDeposit/goodFaithDeposit';
import { TamariskPayment } from '../../tamariskPayment/tamariskPayment';
import { Document } from '@roc/feature-types';
import { useBaseStore } from '@roc/feature-app-core';

const APPRAISAL_PAYMENT_LOAN = "Appraisal_Loan";
const GFD_PAYMENT_LOAN = "GFD";

const useStyles = makeStyles({
  instruction: {
    fontWeight: 600,
  },
  paymentDetails: {
    fontWeight: 800,
    marginTop: '20px',
    fontStyle: 'italic',
  }
});

export type LoanPaymentsProps = {
  loan: any;
  document: Document;
  onUpdate?: () => void;
  onSubmitLoanPayments: () => void;
  onClick?: () => void;
};

const yesNoOptions = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

export const LoanPayments = observer((props: LoanPaymentsProps) => {
  const { loan, document, onClick } = props;
  const classes = useStyles();
  const { documentStore } = useDocumentStore();
  const { documentFormStore } = documentStore;
  const { loanPaymentsStore, tamariskPaymentStore } = documentFormStore;
  const { globalStore } = useBaseStore();

  const [gfdVisible, setGfdVisible] = useState(loan.loanDepositData.borrowerPayingGfd !== null ? loan.loanDepositData.borrowerPayingGfd : false);
  const [tamariskVisible, setTamariskVisible] = useState(loan.loanDepositData.tamariskPayment !== null ? loan.loanDepositData.tamariskPayment : false);

  const borrowerPayingGfd = loanPaymentsStore.isBorrowerPayingGfd();
  const tamariskPayment = loan.submitAppraisalWithTamarisk !== null ? loan.submitAppraisalWithTamarisk : false;
  const paymentStatus = loan.loanDepositData.depositReceived;
  const noPayment = (gfdVisible === false && tamariskVisible === false) || (borrowerPayingGfd === false && tamariskVisible === false) || (gfdVisible === false && tamariskPayment === false);

  const changeIsGoodFaithDepositVisible = (value: string) => {
    setGfdVisible(value === 'true');
  };

  const changeIsTamariskPaymentVisible = (value: string) => {
    setTamariskVisible(value === 'true');
  };

  const handleClose = () => {
    const paymentOptions = {
      loanId: loan.loanId,
      gfdPayment: gfdVisible,
      tamariskPayment: tamariskVisible,
    };
    loanPaymentsStore.updatePaymentOptions(paymentOptions);
  };

  return (
    <Grid container justifyContent="center">
      {borrowerPayingGfd && (
        <>
          {!paymentStatus && (
            <Grid item xs={12}>
              <RadioField
                standaloneLabel
                label="Will the borrower be paying good faith deposit?"
                name="goodFaithDepositDecision"
                value={gfdVisible}
                options={yesNoOptions}
                onChange={value => changeIsGoodFaithDepositVisible(value)}
                fullWidth
                row
                testId="goodFaithDepositDecision"
              />
            </Grid>
          )}
          {(gfdVisible === true || (paymentStatus && loan.loanDepositData.depositType === GFD_PAYMENT_LOAN)) && (
            <Grid item xs={12}>
              <GoodFaithDeposit
                loanId={loan.loanId}
                paymentStatus={paymentStatus}
              />
            </Grid>
          )}
        </>
      )}
      {tamariskPayment && (gfdVisible === false || !borrowerPayingGfd) && (
        <>
          {!paymentStatus && (
            <Grid item xs={12}>
              <RadioField
                standaloneLabel
                label="Will the borrower be using Tamarisk for Appraisal?"
                name="loanPaymentsDecision"
                value={tamariskVisible}
                options={yesNoOptions}
                onChange={value => changeIsTamariskPaymentVisible(value)}
                fullWidth
                row
                testId="loanPaymentsDecision"
              />
            </Grid>
          )}
          {(tamariskVisible === true || (paymentStatus && loan.loanDepositData.depositType === APPRAISAL_PAYMENT_LOAN)) && (
            <Grid item xs={12}>
              <TamariskPayment
                borrowersEmail={tamariskPaymentStore.borrowersEmail}
                tamariskURL={tamariskPaymentStore.tamariskPaymentURL}
                entityId={loan.loanId}
                type={APPRAISAL_PAYMENT_LOAN}
                paymentStatus={paymentStatus}
              />
            </Grid>
          )}
        </>
      )}
      {(!borrowerPayingGfd && !tamariskPayment) && !paymentStatus && (
        <Grid item xs={12}>
          <Typography align='center' className={classes.instruction}>
            There's no payment option configured for this loan
          </Typography>
        </Grid>
      )}
      {paymentStatus && (
        <Grid item xs={12}>
          <Typography align='center' className={classes.paymentDetails}>
            {`$${loan.loanDepositData.amountDeposited} amount received on ${loan.loanDepositData.amountReceivedDate}`}
          </Typography>
        </Grid>
      )}
      <Grid item container xs={12} spacing={1} justifyContent="flex-end">
        {!paymentStatus && (
          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleClose}
              testId="cancel"
              disabled={globalStore.userFeatures.isInsuranceReviewer}
            >
              Save
            </Button>
          </Grid>
        )}
        {noPayment && document.status !== DocumentStatus.ACCEPTED && (
          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              onClick={onClick}
              testId="acceptLoanPayments"
              disabled={globalStore.userFeatures.isInsuranceReviewer}
            >
              Accept
            </Button>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
});

export default LoanPayments;
