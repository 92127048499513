import { Box, Grid, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import { AutocompleteAddressField, createSelectField, RadioField, TextField } from '@roc/ui';
import { isNotBlank, originatorSubLabel } from '@roc/feature-utils';

import { ScopeOfWorkV2FormBaseStore } from '@roc/feature-sow-shared/stores/v2';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { yesNoRadioOptions } from '@roc/feature-sow-shared/constants/scopeOfWorkV2Form';
import { RenovationDescription } from '@roc/ui/formComponents';
import { renovationDescriptionsByLoanSubtype } from '@roc/feature-utils';
import { LoanSubType } from '@roc/feature-utils';
import { FormControl, FormHelperText } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    radioFieldContainer: {
      '& .MuiFormLabel-root': {
        fontSize: '13px',
      },
    },
    addressWrapper: {
      '& .MuiInputBase-root.Mui-disabled': {
        backgroundColor: theme.palette.grey[50],
      },
    },
    addressGrid: {
      paddingTop: '12px',
    },
  })
);

type GeneralInformationProps = {
  store: ScopeOfWorkV2FormBaseStore;
};

const GeneralInformation = observer((props: GeneralInformationProps) => {
  const { store } = props;
  const classes = useStyles();

  const handlePropertyAddressChange = event => {
    const { value, fullInfo } = event?.target || {};
    const { address_components } = fullInfo || {};
    store.setAddress(value, address_components);
  };

  const handleChange = (field, value) =>
    store.onFieldChange(field, value === 'true');


  return (
    <Box>
      <Box>
        <Box mb={1}>
          <Typography variant="h4">General Information</Typography>
        </Box>
        <Typography variant="body1">
          Please fill out the form below to have better view of the scope of
          work necessary on the property
        </Typography>
      </Box>
      <Box mt={3}>
        <Box mb={2}>
          <Typography variant="h5">Property Information</Typography>
        </Box>
        <Grid container spacing={4} direction="column" className={classes.addressGrid}>
          <Grid className={classes.addressWrapper} item xs={12} md={7}>
            <AutocompleteAddressField
              value={store.form.fields.address.value}
              name="address"
              label="Property Address"
              onChange={handlePropertyAddressChange}
              disabled={store.addressDisabled || store.params.isRevisionSOW}
              fullWidth
              textField
              testId="address"
              required
              error={isNotBlank(store.addressError)}
              errorText={store.addressError}
            />
          </Grid>
          <Grid item container xs={12} md={5} spacing={2}>
            <Grid item md={12} lg={6}>
              <Box className={classes.radioFieldContainer}>
                <RadioField
                  label="Do you already own the property?"
                  value={store.form.fields.alreadyOwn.value}
                  name="alreadyOwn"
                  options={yesNoRadioOptions}
                  onChange={(value) => handleChange('alreadyOwn', value)}
                  fullWidth
                  row
                  testId="alreadyOwn"
                  disabled={store.isDisabled || store.params.isRevisionSOW}
                />
              </Box>
            </Grid>
            <Grid item md={12} lg={6}>
              {store.form.fields.alreadyOwn.value &&
                <Box className={classes.radioFieldContainer}>
                  <RadioField
                    label="Have you already made improvements to the property?"
                    value={store.form.fields.anyImprovements.value}
                    name="anyImprovements"
                    options={yesNoRadioOptions}
                    onChange={(value) => handleChange('anyImprovements', value)}
                    fullWidth
                    row
                    testId="anyImprovements"
                    disabled={store.isDisabled || store.params.isRevisionSOW}
                  />
                </Box>
              }
            </Grid>
          </Grid>
        </Grid>
        {store.showSelectOriginator &&
          store.loanParticipantsStore.allowOriginatorSelection &&
          <>
            <Box mb={2} mt={2}>
              <Typography variant="h5">Participants</Typography>
            </Box>
            <Grid container spacing={4}>
              <Grid item xs={7}>
                {createSelectField({
                  store,
                  testId: 'originatorId',
                  fieldName: 'originatorId',
                  label: 'Originator',
                  subLabel: originatorSubLabel,
                  options: store.loanParticipantsStore.originatorOptions
                })}
              </Grid>
            </Grid>
          </>
        }
      </Box>
      <Box mt={3}>
        <Box mb={2}>
          <Typography variant="h5">Project Information</Typography>
        </Box>
        <Grid container spacing={2} direction="column" className={classes.addressGrid}>
          <Grid className={classes.addressWrapper} item xs={12} md={7}>
            <TextField
              type="text-area"
              value={store.form.fields.projectDescription.value}
              variant="outlined"
              label="Provide a narrative description of the proposed work"
              fullWidth
              multiline={true}
              minRows={4}
              margin="normal"
              testId="description"
              onChange={e => store.changeField('projectDescription', e.target.value)}
              disabled={store.isDisabled || store.params.isRevisionSOW}
            ></TextField>
          </Grid>
          <Grid item xs={12} md={7}>
            <FormControl error={isNotBlank(store.getDescriptionError)} fullWidth>
              <RenovationDescription
                disabled={store.isDisabled || store.params.isRevisionSOW}
                store={store}
                renovationDescriptionOptions={renovationDescriptionsByLoanSubtype(LoanSubType.FIX_AND_FLIP)}
              />
              <FormHelperText>{store.getDescriptionError}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={7}>
            <RadioField
              label="Is there any change to the property's existing square footage or layout?"
              value={store.form.fields.layoutChange.value}
              name="layoutChange"
              options={yesNoRadioOptions}
              onChange={(value) => handleChange('layoutChange', value)}
              fullWidth
              row
              testId="layoutChange"
              disabled={store.isDisabled || store.params.isRevisionSOW}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
});

export default GeneralInformation;