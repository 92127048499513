import { Route } from 'react-router';
import { useRocInternalPortalStore } from '../../../hooks';
import { Page } from '@roc/client-portal-shared/components';
import { BridgeProfitAndDscrCalculator } from '@roc/feature-bridge-profit-and-dscr-calculator';

export const getBridgeProfitAndDscrCalculatorInternalRoutesConfig = (
  basePath: string,
  baseUrl: string
) => {
  return {
    bridgeProfitAndDscrCalculator: {
      path: `${basePath}/bridge-profit-and-dscr-calculator`,
      url: `${baseUrl}/bridge-profit-and-dscr-calculator`,
      documentTitle: 'Bridge Profit & Dscr Calculator',
    },
  };
};

export const getBridgeProfitAndDscrCalculatorInternalRoutes = (
  basePath: string,
  baseUrl: string
) => {
  const config = getBridgeProfitAndDscrCalculatorInternalRoutesConfig(basePath, baseUrl);
  const { bridgeProfitAndDscrCalculatorInternalStore } = useRocInternalPortalStore();

  return {
    bridgeProfitAndDscrCalculator: (
      <Route exact path={config.bridgeProfitAndDscrCalculator.path}>
        <Page routeDefinition={config.bridgeProfitAndDscrCalculator}>
          <BridgeProfitAndDscrCalculator store={bridgeProfitAndDscrCalculatorInternalStore} />
        </Page>
      </Route>
    ),
  };
};
