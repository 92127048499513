import { observer } from 'mobx-react';
import { OneToolQuoteStepper } from './components/oneToolQuoteStepper';
import { useOneToolStore } from './hooks/useOneToolStore';
import { BorrowerInformationStep } from './steps/borrowerInformation/borrowerInformationStep';
import { StabilizedBridgeLeverageCard } from './steps/leverage/components/stabilizedBridgeLeverageCard';
import { LeverageStep } from './steps/leverage/leverageStep';
import { StabilizedBridgeLoanDetailsCard } from './steps/loanEconomics/components/stabilizedBridgeLoanDetailsCard';
import { EconomicsStep } from './steps/loanEconomics/economicsStep';
import { StabilizedBridgePropertyInformationForm } from './steps/propertyInformation/forms/stabilizedBridgePropertyInformationForm';
import { PropertyInformationStep } from './steps/propertyInformation/propertyInformationStep';
import { SummaryStep } from './steps/summary/summaryStep';
import { PreflightStep } from './steps/preflight/preflightStep';
import { LoanSuccessStep } from './steps/common/loanSuccess';
import { PropertySource } from './utils/constants';


export const StabilizedBridgeQuote = observer(() => {
  const { oneToolStore } = useOneToolStore();
  const { stabilizedBridgeQuoteStore } = oneToolStore;

  const propertyCount =
    stabilizedBridgeQuoteStore.propertiesStore.propertyFormStores?.length;
  const isWholesale = stabilizedBridgeQuoteStore.propertiesStore.propertyFormStores?.some?.(
    form =>
      PropertySource.WHOLESALER === form.form.fields.propertySourcing.value
  );

  return (
    <OneToolQuoteStepper
      title={'Create a Quote - Stabilized Bridge Loan'}
      store={stabilizedBridgeQuoteStore}
      preflightStep={
        <PreflightStep
          store={stabilizedBridgeQuoteStore}
          LoanDetailsCard={StabilizedBridgeLoanDetailsCard}
          PropertyInformationForm={StabilizedBridgePropertyInformationForm}
        />
      }
      successStep={<LoanSuccessStep store={stabilizedBridgeQuoteStore} />}
    >
      <BorrowerInformationStep store={stabilizedBridgeQuoteStore} />
      <PropertyInformationStep
        store={stabilizedBridgeQuoteStore}
        PropertyInformationForm={StabilizedBridgePropertyInformationForm}
      />
      <LeverageStep
        store={stabilizedBridgeQuoteStore}
        allowInstaQuote={propertyCount <= 1}
        LeverageCard={StabilizedBridgeLeverageCard}
      />
      <EconomicsStep
        store={stabilizedBridgeQuoteStore}
        LoanDetailsCard={StabilizedBridgeLoanDetailsCard}
      />
      <SummaryStep
        store={stabilizedBridgeQuoteStore}
        LoanDetailsCard={StabilizedBridgeLoanDetailsCard}
      />
    </OneToolQuoteStepper>
  );
});
