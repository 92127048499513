import { Button, StandardDialog, TextField } from '@roc/ui';
import { useStore } from '@roc/client-portal-shared/hooks';
import { useEffect, useState } from 'react';
import { SUBMITTED_FOR_REVIEW_MANUAL } from '@roc/feature-sow-shared/constants/scopeOfWorkForm';
import { observer } from 'mobx-react';

export const SubmitReviewDialog = observer((props) => {
  const { store } = props;
  const [description, setDescription] = useState('');

  useEffect(() => {
    setDescription('');
  }, [store.isSubmitForReviewOpen])

  const isDescriptionLongEnough = () => {
    return description?.replace(/ /g, '').length >= 50;
  }

  return (
    <StandardDialog
      open={store.isSubmitForReviewOpen}
      title="Submit for Review"
      maxWidth="md"
      handleClose={() => store.setOpenSubmitReviewDialog(false)}
      dialogContent={
        <TextField
          testId="submitReviewReason"
          type="text-area"
          value={description}
          variant="outlined"
          label="Description"
          fullWidth
          multiline={true}
          minRows={2}
          margin="normal"
          onChange={(e) => setDescription(e.target.value)}
          helperText="Please provide a description with at least 50 characters"
        />
      }
      dialogActions={
        <>
          <Button
            testId="submitReviewCancel"
            style={{ marginRight: '8px' }}
            onClick={() => store.setOpenSubmitReviewDialog(false)}
            color="primary"
            variant="outlined"
          >
            CANCEL
          </Button>
          <Button
            testId="submitReviewConfirm"
            onClick={() => store.handleMarkAsInReview(SUBMITTED_FOR_REVIEW_MANUAL, description)}
            color="primary"
            variant="outlined"
            disabled={!isDescriptionLongEnough()}
          >
            CONFIRM
          </Button>
        </>
      }
    />
  );
});

export default SubmitReviewDialog;