import { makeStyles } from '@material-ui/core/styles';
import { Layout } from '@roc/feature-app-core';
import { ViewDrawsGrid, ViewDrawsGridPaginated, useDrawsStore } from '@roc/feature-draws';
import { Button, Card, IconFilterButton, Toolbar } from '@roc/ui';
import { observer } from 'mobx-react';

const useStyles = makeStyles(theme => ({
  container: {
    padding: 32,
  },
  card: {
    flex: 1
  }
}));

export const BorrowerDrawsDashboard = observer(() => {
  const classes = useStyles();

  const { drawStore } = useDrawsStore();

  const toolbar = (
    <Toolbar
      toolbarContainerStyle={{
        border: 0,
        padding: 0,
      }}
    >
      <IconFilterButton
        onToggleFilters={() => drawStore.myDrawsGridStore.toggleFilters()}
        onClearFilters={() => drawStore.myDrawsGridStore.resetFilters()}
        showFilters={drawStore.myDrawsGridStore.showFilters}
      />
    </Toolbar>
  );

  return (
    <Layout >
      <Card
        title="My Draws"
        cardHeaderProps={{
          style: {
            paddingBottom: 0,
          },
        }}
        cardContentProps={{
          style: {
            padding: 0,
            height: '100%',
            display: 'flex'
          },
        }}
        className={classes.card}
      >
        <ViewDrawsGrid toolbar={toolbar} />
      </Card>
    </Layout>
  );
});


export const BorrowerDrawsDashboardNew = observer(({
  drawRequestStore
}) => {
  const { drawStore } = useDrawsStore();

  const toolbar = (
    <Toolbar
      toolbarContainerStyle={{
        border: 0,
        borderBottom: '1px solid #eee',
        padding: 0,
        alignItems: 'center',
      }}
    >
      <div>
        <IconFilterButton
          onToggleFilters={() => drawStore.myDrawsGridStore.toggleFilters()}
          onClearFilters={() => drawStore.myDrawsGridStore.resetFilters()}
          showFilters={drawStore.myDrawsGridStore.showFilters}
        />
        <Button
          testId="requestDraw"
          color="primary"
          variant="contained"
          size="small"
          style={{
            marginLeft: 8
          }}
          onClick={() => drawRequestStore.setShowLoansForDrawRequestModal(true)}>
          Request Draw
        </Button>
      </div>
    </Toolbar>
  );
  return (
    <ViewDrawsGridPaginated toolbar={toolbar} />
  );
});
