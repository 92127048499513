import { Service } from '@roc/feature-app-core';

const url = {
  TITLE_AGENT_FORECLOSURE_LOANS: '/api/v1/loan/getTitleAgentForeclosureLoans',
};

export class TitleAgentLoanService extends Service {
  getTitleAgentForeclosureloans(
    page: number,
    pageSize: number,
    sortDir: string,
    sortBy: string,
    filters: object,
    dropdownFilters: object
  ) {
    return this.get(
      url.TITLE_AGENT_FORECLOSURE_LOANS,
      {
        page,
        pageSize,
        sortDir,
        sortBy,
        filters,
        dropdownFilters,
      },
      {
        disableGlobalLoading: true,
      }
    );
  }


}
