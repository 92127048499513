import { Box, Grid } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useLoanStore } from '@roc/feature-loans-shared';
import { Button, ConfirmDialog, StandardDialog } from '@roc/ui';
import { formatCurrency } from '@roc/ui/utils';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useLoanActionsStore } from '../hooks/useLoanActionsStore';

export const RequestClosingWireModal = observer(({ open, handleClose }) => {
  const { loanActionsStore } = useLoanActionsStore();
  const { loanStore } = useLoanStore();
  const { loanDetails } = loanStore;
  const { requestClosingWireStore } = loanActionsStore;
  const { checkResult } = requestClosingWireStore;

  useEffect(() => {
    if (open) {
      requestClosingWireStore.checkRequirements();
    } else {
      requestClosingWireStore.resetStore();
    }
  }, [open]);

  const confirm = () => {
    requestClosingWireStore.requestClosingWire();
    handleClose();
  };

  return (
    <StandardDialog
      open={open}
      handleClose={handleClose}
      title={'Request Closing Wire'}
      maxWidth={'sm'}
      dialogContent={
        <Grid container spacing={2}>
          {checkResult?.canRequestClosingWire === false && (
            <Grid item xs={12}>
              <Alert severity="error">
                <b>Unable to request closing wire:</b>
                {checkResult.messages.map(message => (
                  <Box> - {message}</Box>
                ))}
              </Alert>
            </Grid>
          )}
          {checkResult?.canRequestClosingWire && (
            <Grid item xs={12}>
              Are you sure you want to request a closing wire for loan{' '}
              {loanDetails?.loanId} with an amount of{' '}
              {checkResult?.wireAmount
                ? formatCurrency(checkResult?.wireAmount)
                : null}
              ?
            </Grid>
          )}
        </Grid>
      }
      dialogActions={
        <>
          <Button
            style={{ marginRight: '8px' }}
            onClick={handleClose}
            color="primary"
            variant="outlined"
            testId="cancel"
          >
            Cancel
          </Button>
          <Button
            disabled={!checkResult?.canRequestClosingWire}
            onClick={confirm}
            color="primary"
            variant="contained"
            testId="confirm"
          >
            Yes
          </Button>
        </>
      }
    />
  );
});
