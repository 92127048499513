import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { SelectField, TextField, trueFalseOptions } from '@roc/ui';
import { observer } from 'mobx-react';
import { PropertyFormStore } from '../../../../../stores/documentForms/insuranceProvider/propertyFormStore';
import { exitStrategiesStabilizedBridge, isNotBlank } from '@roc/feature-utils';
import {
  Occupancy,
  occupancyOptions,
  foundationOptions,
} from '@roc/feature-utils';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';

export type PropertyFormProps = {
  readOnly?: boolean;
  store: PropertyFormStore;
};

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
}));

export const PropertyForm = observer((props: PropertyFormProps) => {
  const { store, readOnly } = props;

  const {
    address,
    form: { fields },
  } = store;

  const classes = useStyles();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography className={classes.title}>{address}</Typography>
      </Grid>
      <Grid item container spacing={2}>
        <Grid item xs={12} sm={6}>
          <SelectField
            label="Occupancy"
            standaloneLabel
            variant="outlined"
            value={fields.occupancy.value}
            options={occupancyOptions}
            onChange={value => store.onFieldChange('occupancy', value)}
            error={isNotBlank(fields.occupancy.error)}
            helperText={fields.occupancy.error}
            fullWidth
            testId="occupancy"
            disabled={readOnly}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Stories"
            standaloneLabel
            variant="outlined"
            type="number"
            value={fields.stories.value}
            onChange={e => store.onFieldChange('stories', e.target.value)}
            error={isNotBlank(fields.stories.error)}
            helperText={fields.stories.error}
            fullWidth
            testId="stories"
            disabled={readOnly}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Year built"
            standaloneLabel
            variant="outlined"
            type="number"
            value={fields.yearBuilt.value}
            onChange={e => store.onFieldChange('yearBuilt', e.target.value)}
            error={isNotBlank(fields.yearBuilt.error)}
            helperText={fields.yearBuilt.error}
            fullWidth
            testId="yearBuilt"
            disabled={readOnly}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectField
            label="Foundation"
            standaloneLabel
            variant="outlined"
            value={fields.foundation.value}
            options={foundationOptions}
            onChange={value => store.onFieldChange('foundation', value)}
            error={isNotBlank(fields.foundation.error)}
            helperText={fields.foundation.error}
            fullWidth
            testId="foundation"
            disabled={readOnly}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectField
            label="Exit Strategy"
            standaloneLabel
            variant="outlined"
            value={fields.exitStrategy.value}
            options={exitStrategiesStabilizedBridge}
            onChange={value => store.onFieldChange('exitStrategy', value)}
            error={isNotBlank(fields.exitStrategy.error)}
            helperText={fields.exitStrategy.error}
            fullWidth
            testId="exitStrategy"
            disabled={readOnly}
          />
        </Grid>
        {fields.occupancy.value === Occupancy.TENANT_OCCUPIED && (
          <Grid item xs={12} sm={6}>
            <SelectField
              label="Will tenant vacate before closing?"
              standaloneLabel
              variant="outlined"
              value={fields.tenantVacate.value}
              options={trueFalseOptions}
              onChange={value => store.onFieldChange('tenantVacate', value)}
              error={isNotBlank(fields.tenantVacate.error)}
              helperText={fields.tenantVacate.error}
              fullWidth
              testId="tenantVacate"
              disabled={readOnly}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
});

export default PropertyForm;
