import { RenderApp } from '@roc/feature-render-app';
import { App } from './app/app';
import { store } from './app/stores';
import { getThemeConfiguration } from '@roc/feature-borrower-portal-theme';
import { useAppRoutes } from './app/hooks';

const _getThemeConfiguration = () => {
  const { publicRoutes } = useAppRoutes();
  const pathname = location.pathname;
  // apply new borrower theme for below urls
  if (pathname.includes(publicRoutes.loanApplication.url)) {
    return getThemeConfiguration();
  }
  return undefined;
};

RenderApp({
  store,
  App,
  themeConfiguration: _getThemeConfiguration(),
});
