import {
  Box,
  Chip,
  FormControl,
  MenuItem,
  MenuProps,
  Select,
  Typography,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { capitalize, sanitizeTestId } from '@roc/feature-utils';
import {
  FieldLabel,
  SelectOption,
  StatusChip,
  StatusType,
  TextField,
} from '@roc/ui';
import { Autocomplete, AutocompleteProps } from '@material-ui/lab';

const useStyles = makeStyles((theme: Theme) => ({
  select: {
    '& .description-text': {
      display: 'none',
    },
    '& .MuiOutlinedInput-input': {
      paddingTop: '16px',
      paddingBottom: '16px',
    },
  },
  placeholder: {
    display: 'none',
  },
}));

const menuProps: Partial<MenuProps> = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
  getContentAnchorEl: null,
};

const renderMenuItem = (option, className?: string) => {
  const { description, label, value } = option;

  return (
    <MenuItem key={value} value={value} className={className}>
      <StatusChip
        statusType={StatusType.GENERAL_TASK_STATUS}
        label={value}
        size="small"
      />
    </MenuItem>
  );
};

export const TaskStatusField = ({
  label,
  value,
  options,
  onChange,
  disabled,
}) => {
  const classes = useStyles();
  //const currentOptions = value ?? [];
  //const isEmpty = currentOptions.length === 0;

  return (
    <>
      <FieldLabel>{label}</FieldLabel>
      <FormControl fullWidth variant="outlined">
        <Select
          className={classes.select}
          id={sanitizeTestId(label)}
          name={sanitizeTestId(label)}
          value={value}
          onChange={event => onChange(event, event.target.value)}
          MenuProps={menuProps}
          disabled={disabled}
        >
          {options.map((option: SelectOption) => renderMenuItem(option))}
        </Select>
      </FormControl>
    </>
  );
};
