import {
  Tooltip as MuiTooltip,
  TooltipProps as MuiTooltipProps,
  SvgIconProps,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { ReactElement } from 'react';

interface styleProps {
  iconColor: string
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    tooltip: {
      whiteSpace: 'pre-line',
      fontSize: theme.typography.pxToRem(14),
      maxWidth: '75vw',
    },
    icon: ({ iconColor }: styleProps) => ({
      width: '16px',
      color: iconColor
    }),
    pointerCursor: {
      cursor: 'pointer',
      display: "flex",
      alignItems: "center"
    },
    textWithTooltip: {
      textDecorationLine: 'underline',
      textDecorationStyle: 'dotted',
    },
  })
);

export interface CustomIconTooltipProps extends Omit<MuiTooltipProps, 'children'> {
  children?: ReactElement;
  placement?:
  | 'right'
  | 'left'
  | 'top'
  | 'bottom'
  | 'bottom-end';
  text?: string;
  iconColor?: string;
  icon: React.ElementType<SvgIconProps>;
}

export const CustomIconTooltip = (props: CustomIconTooltipProps) => {
  const { children, placement, text, icon: Icon, iconColor = "inherit", ...muiTooltipProps } = props;
  const classes = useStyle({ iconColor });

  return (
    <MuiTooltip
      placement={placement || "right"}
      classes={{ tooltip: classes.tooltip }}
      {...muiTooltipProps}
    >
      {children ?? (
        <span className={classes.pointerCursor}>
          {text && <span> <span className={classes.textWithTooltip}>{text}</span></span>}
          <Icon className={classes.icon} />
        </span>
      )}
    </MuiTooltip>
  );
};
