import { Grid } from '@material-ui/core';
import { Button, FileUpload, TextField, FileUploadArea } from '@roc/ui';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useResolutionStore } from '../../hooks/useResolutionStore';
import { ResolutionInfo } from './resolutionInfo';
import { BORROWER_BACKGROUND_REVIEW } from '@roc/feature-documents';

export const ResolutionForm = observer(
  ({ handleClose, loanDocumentId, internal = false, readOnly }) => {
    const { resolutionStore } = useResolutionStore();
    const { resolution } = resolutionStore;
    const [uploads, setUploads] = useState<FileUpload[]>([]);

    useEffect(() => {
      if (loanDocumentId) {
        resolutionStore.setInternal(internal);
        resolutionStore.setReadOnly(readOnly);
        resolutionStore.getResolutionByDocumentId(loanDocumentId);
      }
    }, [loanDocumentId]);


    const onSave = async () => {
      await resolutionStore.saveResolution(handleClose);
      if (uploads[0]) {
        await resolutionStore.uploadDocument(uploads[0]);
      }
      else {
        resolutionStore.getResolutionByDocumentId(loanDocumentId);
        if (handleClose) handleClose();
      }
    };

    const disabled = resolution?.objectType === BORROWER_BACKGROUND_REVIEW && resolutionStore?.readOnly;

    return (
      <Grid container spacing={2}>
        <ResolutionInfo resolution={resolution} resolutionStore={resolutionStore} />
        <Grid item xs={12}>
          <TextField
            type="text-area"
            variant="outlined"
            label="Item Explanation"
            value={resolutionStore.resolution?.comment || ''}
            onChange={e => resolutionStore.setResolutionComment(e.target.value)}
            fullWidth
            multiline={true}
            minRows={4}
            margin="normal"
            testId="shareUrl"
            disabled={disabled}
          />
        </Grid>
        {!disabled && <Grid item xs={12}>
          <FileUploadArea
            items={uploads}
            setItems={(items) => setUploads(items)}
            multiple={false} />
        </Grid>}
        <Grid item xs={12}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            testId="save"
            fullWidth
            onClick={onSave}
            disabled={disabled}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    );
  }
);
