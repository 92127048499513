import React from 'react';

import './feature-construction-feasibility.module.scss';

/* eslint-disable-next-line */
export interface FeatureConstructionFeasibilityProps {}

export function FeatureConstructionFeasibility(
  props: FeatureConstructionFeasibilityProps
) {
  return (
    <div>
      <h1>Welcome to feature-construction-feasibility!</h1>
    </div>
  );
}

export default FeatureConstructionFeasibility;
