import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import { BorrowerTrackRecordStore, BorrowerTrackRecordVerification } from '@roc/feature-borrowers';
import { IconButton } from '@roc/ui';
import { CloudDownload } from '@material-ui/icons';

const useStyles = makeStyles(theme =>
  createStyles({
    header: {
      height: '50px',
      alignItems: 'center',
      display: 'flex',
      marginBottom: '2rem',
    },
    verificationStatusCell: {
      textAlign: 'right',
    },

    nonEditableInput: {
      padding: '2% 2% 0 2%',
      backgroundColor: 'transparent',
      border: 'none',
      outline: 'none',
    },
    editableInput: {
      padding: '2% 2% 0 2%',
      backgroundColor: 'transparent',
      border: 'solid lightgrey 1px',
      outline: 'none',
      margin: '20px',
      marginBottom: 0,
    },
    actionButton: {
      marginRight: '1rem',
      height: '40px',
      fontSize: '12px',
      padding: '5px',
    },
    experienceComments: {
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      border: 'solid lightgrey 1px',
      '& textarea': {
        resize: 'none',
        width: '-webkit-fill-available',
      },
      fontFamily: 'Manrope, sans- serif'
    },
    paper: {
      borderRadius: theme.spacing(2),
      boxShadow:
        'rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px',
      marginRight: theme.spacing(2),
      backgroundColor: theme.palette.background.default,
    },
    link: {
      cursor: 'pointer',
      textDecoration: 'none',
      transition: 'color 0.3s ease',
    },
    textField: {
      backgroundColor: 'white',
      shrink: true,
      '& input.Mui-disabled': {
        color: 'black',
      },
    },
    table: {
      marginTop: '1.5em',
      marginBottom: '2em',
    },
    tab: {
      marginBottom: theme.spacing(2),
    },
    buttonsGrid: {
      display: 'flex',
      alignItems: 'center',
      alignContent: 'center',
      paddingTop: '10px',
    },
    closeButton: {
      display: 'flex',
      alignItems: 'center',
      alignContent: 'center',
    },
    addressWrapper: {
      '& .MuiInputBase-root.Mui-disabled': {
        backgroundColor: theme.palette.grey[50],
      },
      padding: '16px',
    },
    addressWrapperTab: {
      '& .MuiInputBase-root.Mui-disabled': {
        backgroundColor: theme.palette.grey[50],
      },
      paddingLeft: '16px',
      paddingRight: '16px',
      paddingBottom: '26px',
    },
  })
);

interface TrackRecordLineItemProps {
  hideTitle?: boolean;
  store: BorrowerTrackRecordStore;
  isInternal?: boolean;
  borrowerId: number;
  onClose: () => void;
}

export const TrackRecordLineItem = observer(({ hideTitle = false, store, isInternal = true, borrowerId, onClose }: TrackRecordLineItemProps) => {

  const classes = useStyles();

  const onDocUploadCallback = () => {
    store.getDocument(store.document.loanDocumentId);
  }

  return (<Grid container style={{
    width: '100%'
  }}>
    <Grid item xs={12} className={classes.header}>
      {
        !hideTitle &&
        <Typography variant={'h4'}>
          {store.document
            ? `${store.document.borrowerName} - Track Record`
            : 'Loading...'}
        </Typography>
      }
      {store.canDownloadFile && (
        <Box display="flex" justifyContent="flex-end">
          <IconButton
            type="submit"
            color="primary"
            testId="save"
            onClick={() => store.downloadDocument()}
          >
            <CloudDownload />
          </IconButton>
        </Box>
      )}
    </Grid>
    <Grid item xs={12} >
      <BorrowerTrackRecordVerification
        token=''
        isInternal={isInternal}
        isTpoFlow={false}
        showBanner={false}
        store={{
          ...store,
          fetchPublicFormInformation: onClose,
        }}
        borrowerId={borrowerId}
        onDocUploadCallback={onDocUploadCallback}
      />
    </Grid>
  </Grid>);
});

export default TrackRecordLineItem;
