import { ApiResponse, FormStore, GlobalStore, UserStore } from "@roc/feature-app-core";
import { LoanService } from "@roc/feature-loans";
import { flow, makeObservable, observable } from "mobx";
import { LoansDataFormService } from "../services/loansDataService";
import { LoanType, isNil, propertyStates } from "@roc/feature-utils";

const loanDataForm = {
  fields: {
    loanId: {
      value: '',
      error: null,
      rule: '',
    },
    attorneyValue: {
      value: '',
      error: null,
      rule: '',
    },
    rate: {
      value: '',
      error: null,
      rule: '',
    },
    pointsIn: {
      value: '',
      error: null,
      rule: '',
    },
    pointsOut: {
      value: '',
      error: null,
      rule: '',
    },
    brokerPointsIn: {
      value: '',
      error: null,
      rule: '',
    },
    brokerPointsOut: {
      value: '',
      error: null,
      rule: '',
    },
    numberOfExtensions: {
      value: '',
      error: null,
      rule: '',
    },
    monthsPerExtension: {
      value: '',
      error: null,
      rule: '',
    },
    rocExtensionFee: {
      value: '',
      error: null,
      rule: '',
    },
    prepaymentDescription: {
      value: '',
      error: null,
      rule: '',
    },
    duration: {
      value: '',
      error: null,
      rule: '',
    },
    underwritingFee: {
      value: '',
      error: null,
      rule: '',
    },
    pledgeOfShares: {
      value: false,
      error: null,
      rule: '',
    },
    rocPointsIn: {
      value: false,
      error: null,
      rule: '',
    },
    rocPointsOut: {
      value: false,
      error: null,
      rule: '',
    },
    referralFeePct: {
      value: '',
      error: null,
      rule: '',
    },
    // isReferred: {
    //   value: false,
    //   error: null,
    //   rule: '',
    // }
  },
  meta: {
    isValid: false,
    error: null,
  },
};


export class LoanDataFormstore extends FormStore {
  private globalStore: GlobalStore;
  private userStore: UserStore;
  private loanService: LoanService;
  private loansDataService: LoansDataFormService

  attorneys: any[] = [];
  selectedAttorney: any;
  isFormValid: boolean;


  constructor(globalStore: GlobalStore, userStore: UserStore) {
    super({ ...loanDataForm }, globalStore);
    this.globalStore = globalStore;
    this.userStore = userStore;
    this.loanService = new LoanService();
    this.loansDataService = new LoansDataFormService();

    makeObservable(this, {
      saveData: flow,
      attorneys: observable,
      fetchAttorneys: flow,
      selectedAttorney: observable,
      setValues: flow,
      validateForm: flow,
      setAttorney: flow,
      isFormValid: observable
    });
  }

  *fetchAttorneys(states, loanAmount) {
    try {
      const response = yield this.loanService.getLenderAttorneysByState(states, this.globalStore.userFeatures?.internalClosingTeamOptionEnabled, loanAmount);
      const lenderAttorneysList = response.data && JSON.parse(response.data?.data);
      this.attorneys = lenderAttorneysList.map(attorney => ({
        label: attorney.company.name,
        value: attorney.investorAttorneyId
      }))
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching attorneys',
      });
    }
  }

  *saveData(onSuccess?: () => void) {
    const isInternal = this.globalStore.lenderInfo?.isInternal;
    this.runFormValidationWithMessage();
    if (this.isFormValid) {
      try {
        let response;
        const {
          loanId,
          attorneyValue,
          rate,
          pointsIn,
          pointsOut,
          brokerPointsIn,
          numberOfExtensions,
          monthsPerExtension,
          rocExtensionFee,
          prepaymentDescription,
          duration,
          underwritingFee,
          pledgeOfShares,
          brokerPointsOut,
          referralFeePct,
          // isReferred
        } = this.getFormValues();
        response = yield this.loansDataService.saveLoanData({
          loanId,
          attorneyValue,
          rate,
          pointsIn,
          pointsOut,
          brokerPointsIn,
          numberOfExtensions,
          monthsPerExtension,
          rocExtensionFee,
          prepaymentDescription,
          duration,
          underwritingFee,
          pledgeOfShares,
          brokerPointsOut,
          referralFeePct,
          // isReferred
        });
        if (onSuccess) {
          if (isInternal) {
            let today = new Date().toISOString().slice(0, 10)
            yield this.loanService.generateCommissions(today, today, '', loanId);
          }
          onSuccess();
        }
        this.globalStore.notificationStore.showSuccessNotification({
          message: 'Data saved successfully',
        });
      } catch (e) {
        console.log(e);
        this.globalStore.notificationStore.showErrorNotification({
          message: e.error.response.data.error.message
        });
      }
    }
  }

  *setValues(data) {
    this.loadForm(data);
    this.setAttorney(data);
  }

  *setAttorney(data) {
    this.fetchAttorneys(this.getStatesFromProperties(data.properties), data?.amount)
    const attorneyFound = this.attorneys.find(attorney => attorney.label === data.attorneyName)
    this.form.fields.attorneyValue.value = attorneyFound?.value;
  }


  getStatesFromProperties(properties) {
    const states = properties
      .filter(property => property.state)
      .map(property => property.state);

    return states.join(',');
  }

  *validateForm(data) {
    this.runFormValidationWithMessage();
    if (!this.form.meta.isValid) {
      this.isFormValid = false;
    }
    const { rocRate, loanType } = data;
    const { rate } = this.getFormValues();
    if (rate < rocRate && loanType === LoanType.RESIDENTIAL_BRIDGE) {
      this.globalStore.notificationStore.showErrorNotification({
        message: `Capital Provider Rate cannot be greater than Interest Rate`,
      });
      this.isFormValid = false;
    } else {
      this.isFormValid = true;
    }
  }

}