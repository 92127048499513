import { useStore } from '@roc/client-portal-shared/hooks';
import { observer } from 'mobx-react';
import { useEffect, useMemo, useState } from 'react';
import { DscrCalculatorBaseStore } from '../stores/dscrCalculatorBaseStore';

interface Props {
  store: DscrCalculatorBaseStore;
}

export const DscrCalculatorFieldCalculations = observer((props: Props) => {
  const dscrCalculatorStore = props.store;
  const { fields } = props.store.form;

  useEffect(() => {
    dscrCalculatorStore.calculateLoanAmount();
  }, [fields.estimatedAsIsValue.value, fields.requestedLTV.value]);

  useEffect(() => {
    dscrCalculatorStore.calculateFullyAmortizingPeriod();
  }, [fields.interestOnlyPeriod.value]);

  useEffect(() => {
    dscrCalculatorStore.calculateDefaultsBasedOnGPR();
  }, [fields.inPlaceMonthlyRent.value, fields.monthlyMarketRent.value]);

  useEffect(() => {
    dscrCalculatorStore.calculateDefaultsBasedOnUnitCount();
  }, [fields.unitCount.value]);

  useEffect(() => {
    dscrCalculatorStore.calculateGrossAnnualIncome();
  }, [
    fields.inPlaceMonthlyRent.value,
    fields.monthlyMarketRent.value,
    fields.vacancy.value,
    fields.concession.value,
    fields.creditLoss.value,
  ]);

  return null;
});
