import { useHistory, useLocation } from 'react-router';
import { LoanDetailsTab } from '../utils/loanDetailsTypes';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Tabs } from '@roc/ui';
import { useLoanStore } from '@roc/feature-loans';
import { useLoanRoutes } from '@roc/feature-loans-routes-config';

const useStyles = makeStyles((theme: Theme) => ({
  tabs: {
    border: '0 !important',
    padding: theme.spacing(0, 2),
  },
}));

export const LoanApplicationDetailsTabs = () => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const { push } = useHistory();
  const { loanRoutesConfig } = useLoanRoutes();
  const { loanStore } = useLoanStore();
  const loanId = loanStore.loanDetails?.loanId;

  const TABS = [
    {
      label: 'LOAN SUMMARY',
      value: LoanDetailsTab.LOAN_SUMMARY,
      url: loanRoutesConfig.loanApplications(loanId).children.dashboard.url,
    },
    {
      label: 'TO-DOS',
      value: LoanDetailsTab.UNDERWRITING,
      url: loanRoutesConfig.loanApplications(loanId).children.todos.url,
    },
  ];

  const onTabChange = (e, index) => {
    const tab = TABS[index];
    push(tab.url);
  };

  const tabIndex = TABS.findIndex(tab => {
    return (
      pathname.indexOf(tab.url) !== -1
    );
  });

  return (
    <Tabs
      tabs={TABS}
      className={classes.tabs}
      selectedTab={tabIndex === -1 ? 0 : tabIndex}
      onTabChange={onTabChange}
    />
  );
};
