export enum LoanSubmissionStep {
  LOAN_PRICER = 'LOAN_PRICER',
  LOAN_PRICER_SUMMARY = 'LOAN_PRICER_SUMMARY',
  LOAN_PRICER_SUMMARY_NEW = 'LOAN_PRICER_SUMMARY_NEW',
  ENTITY_INFORMATION = 'ENTITY_INFORMATION',
  BROKER_INFORMATION = 'BROKER_INFORMATION',
  BORROWER_INFORMATION = 'BORROWER_INFORMATION',
  PROPERTY_INFORMATION = 'PROPERTY_INFORMATION',
  LOAN_DETAILS = 'LOAN_DETAILS',
  LOAN_DOCUMENTS = 'LOAN DOCUMENTS',
  PROMOTIONS = 'PROMOTIONS',
  SUMMARY = 'SUMMARY',
  SUCCESS = 'SUCCESS'
}

export const DEFAULT_FEE_RETAIL_SERVICING_SETUP = 30;
export const DEFAULT_FEE_LEGAL_REVIEW = 995;
export const DEFAULT_FEE_CORELOGIX = 225;