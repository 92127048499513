
import { Box, Typography, Button, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { CLOSING, PROPOSAL, SERVICING, UNDERWRITING } from '@roc/feature-utils';
import { useBaseStore, Layout } from '@roc/feature-app-core';
import { Document } from '@roc/feature-types';
import { FileUpload, Tabs, ScreenshotGrid, SelectField } from '@roc/ui';
import { observer } from 'mobx-react';
import { useCallback, useEffect, useState, useRef } from 'react';
import DocumentSection from 'libs/feature-documents/src/documents/documentSection';
import { useDocumentStore } from '@roc/feature-documents';
import { DocumentForms } from '@roc/feature-documents';
import GetAppIcon from '@material-ui/icons/GetApp';
import { useParams } from 'react-router-dom';
import { PDFPreview } from '@roc/ui';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerButton: {
      border: 0,
      color: theme.palette.grey[600],
      '&:hover': {
        border: 0,
      },
      '&.Mui-disabled': {
        border: 0,
      },
    },
    paper: {
      padding: theme.spacing(2),
    },
    iconButton: {
      color: theme.palette.grey[600],
      marginRight: theme.spacing(1),
      padding: theme.spacing(0.75),
    },
    tab: {
      marginBottom: theme.spacing(2),
    },
  })
);

const PRESCREEN = 'Prescreen';
const COMMITTEE = 'Committee';

const processes = [UNDERWRITING, CLOSING, SERVICING];
type DocumentUpload = {
  loanId: string;
};

const documentOptions = [
  { label: 'Pending Documents', value: 'Pending Documents' },
  { label: 'All Documents', value: 'All Documents' },
];

export const DocumentUpload = observer((props) => {
  const screenshotContainerRef = useRef(null);
  console.log(props);
  const { proposalId } = useParams<{ proposalId: string }>();
  const classes = useStyles();
  const { headerButton, iconButton, tab } = classes;
  const [selectedTab, setSelectedTab] = useState(0)

  const { globalStore } = useBaseStore();
  const { documentStore } = useDocumentStore();
  const { pendingDocuments, showServicing, documents, underwritingDocuments, closingDocuments, servicingDocuments } = documentStore;

  const tabs = [
    {
      label: 'UNDERWRITING',
    },
    {
      label: 'CLOSING',
    },
    showServicing && {
      label: 'SERVICING',
    },
  ].filter(Boolean);

  const fetchAllDocuments = useCallback(
    () => {
      documentStore.fetchSections();
      documentStore.fetchProposalsDocuments(proposalId);
    },
    [proposalId]
  );

  useEffect(() => {
    documentStore.updateIsProposal(true);
    fetchAllDocuments();
  }, []);


  const getSections = (value) => {
    switch (value) {
      case documentOptions[0].value:
        return getPendingSections();
      case documentOptions[1].value:
        return getAllDocuments();
    }
  }

  const getAllDocuments = () => {
    const { displaySections } = documentStore;
    const displaySectionsForLoanProcess =
      displaySections[processes[selectedTab]] ?? [];
    const allSections = [];

    displaySectionsForLoanProcess.forEach(displaySection => {
      displaySection.sections.forEach(section => {
        if (section.sectionName !== PRESCREEN && section.sectionName !== COMMITTEE) {
          allSections.push(section);
        }
      });
    });
    return allSections;
  }

  const getPendingSections = () => {
    const { displaySections } = documentStore;
    const displaySectionsForLoanProcess =
      displaySections[processes[selectedTab]] ?? [];
    const sectionsWithPendingDocs = Object.keys(pendingDocuments);
    const pendingSections = [];

    displaySectionsForLoanProcess.forEach(displaySection => {
      displaySection.sections.forEach(section => {
        if (sectionsWithPendingDocs.includes(section.sectionName) && section.sectionId !== 9 && section.sectionId !== 19) {
          pendingSections.push(section);
        }
      });
    });

    return pendingSections;
  };

  const onSectionDocumentsUpload = (section, files: FileUpload[]) => {
    const { sectionId, sectionName } = section;
    documentStore.uploadSectionPendingDocuments(
      files,
      proposalId,
      sectionId,
      sectionName,
      processes[selectedTab]
    );
  };

  const onDocumentUpload = (file, doc) => {
    documentStore.uploadPendingDocument(file, doc, PROPOSAL, proposalId);
  };

  const processDocuments = useCallback(
    section => {
      let docs = [];

      docs = underwritingDocuments[section.sectionName] || [];

      return docs.map((document: Document) =>
        documentStore.formatDocument(document, processes[selectedTab])
      );
    },
    [documentStore, pendingDocuments, selectedTab, underwritingDocuments]
  );


  return (
    <Layout >
      <ScreenshotGrid
        fileName={`Loan_${proposalId}_Documents.pdf`}
        ref={screenshotContainerRef}
      >
        <Box display="flex" justifyContent="flex-end">
          <Button data-html2canvas-ignore
            className={headerButton}
            startIcon={<GetAppIcon />}
            variant="outlined"
            onClick={() => screenshotContainerRef.current.download()}
          >
            Capture As PDF
          </Button>
        </Box>

        {getSections(documentOptions[1].value).length == 0 && (
          <Box m={2}>
            <Typography color="textSecondary">{`No ${tabs[
              selectedTab
            ].label.toLowerCase()} documents.`}</Typography>
          </Box>
        )}
        {
          getSections(documentOptions[1].value).map((section, index) => (
            <DocumentSection
              key={`${section.sectionName}-${index}`}
              loanId={proposalId}
              section={section}
              loanProcess={UNDERWRITING}
              documents={processDocuments(section)}
              onDocumentUpload={onDocumentUpload}
              onSectionDocumentsUpload={onSectionDocumentsUpload}

            />
          ))}
        <DocumentForms loanId={proposalId} />
        <PDFPreview
          open={documentStore.documentPreview != null}
          title={documentStore.documentPreview?.title}
          data={documentStore.documentPreview?.data}
          headers={documentStore.documentPreview?.headers}
          onClose={() => documentStore.closeDocumentPreview()}
        />
      </ScreenshotGrid>
    </Layout>
  );
});
