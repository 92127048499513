import { GlobalStore } from '@roc/feature-app-core';
import { flow, makeObservable, observable } from 'mobx';
import { ApiResponse } from '@roc/feature-app-core';
import { FindBorrowersService } from '../services/findBorrowersService';

export class FindBorrowersStore {
  globalStore: GlobalStore;
  findBorrowersService: FindBorrowersService;
  borrowers = [];

  constructor(globalStore) {
    this.globalStore = globalStore;
    this.findBorrowersService = new FindBorrowersService();
    makeObservable(this, {
      borrowers: observable,
      findBorrowers: flow,
    });
  }

  *findBorrowers(address, radius: number, sameState: boolean) {
    try {
      const { lat, lng, state } = this.parseAddressValues(address);
      const response: ApiResponse = yield this.findBorrowersService.findBorrowers(
        lat,
        lng,
        sameState ? state : '',
        radius
      );
      this.borrowers = response.data;
    } catch (error) {
      console.log({ error });
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Unable to retrieve the data',
      });
    }
  }

  private parseAddressValues(value) {
    const {
      geometry: { location },
      address_components,
    } = value;
    const state = address_components.find(item =>
      item.types.includes('administrative_area_level_1')
    )?.short_name;
    return {
      lat: location.lat(),
      lng: location.lng(),
      state,
    };
  }
}
