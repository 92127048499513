import { observer } from 'mobx-react';
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemText,
  Chip,
  Grid,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { green, red } from '@material-ui/core/colors';
import { Paper } from '@roc/ui';
import { formatCurrency } from '@roc/ui/utils';
import { formatDate, isNil } from '@roc/client-portal-shared/utils';
import { LoanEconomicTerm } from './loanEconomicTerm';
import { LoanWarningMessages } from './loanWarningMessages';

const useStyles = makeStyles(theme => ({
  paper: {
    marginBottom: theme.spacing(2),
  },
}));

const listItem = (label, value, icon = null) => {
  return (
    <ListItem>
      <ListItemText>
        <Box display="flex">
          {icon && <Box ml={1}>{icon}</Box>}
          <Box flexGrow={1}>{label}</Box>
          <Box>{value}</Box>
        </Box>
      </ListItemText>
    </ListItem>
  );
};

const BooleanChip = ({ value }) => {
  const color = value ? green : red;
  return (
    <Chip
      label={value ? 'Yes' : 'No'}
      style={{
        fontWeight: 0,
        backgroundColor: color[200],
        color: color[900],
      }}
    />
  );
};

const divider = <Divider variant="middle" component="li" />;

const formatPercentage = (value, digits = 2) =>
  `${value != null ? Number(value).toFixed(digits) : ''}%`;

const usdAmount: any = value => {
  return value && formatCurrency(Number(value));
};

export const LoanKeyInformationTerm = observer(
  ({ loanDetails, closingRequirements }) => {
    const classes = useStyles();
    const {
      amount,
      expectedClosingDate,
      interestReserve,
      pledgeOfShares,
      loanPurpose,
      recourseStructure,
      duration,
      aggregateLoanToAsIs,
      interestOnlyMonths,
      interestPayment,
      rateType,
      prepaymentDescription,
      properties,
      amortizationATF,
      cashManagement,
      taxEscrowAtClose,
      taxEscrowOngoingMonthly,
      insuranceEscrowAtClose,
      insuranceEscrowOngoingMonthly,
      aggregateLoanToCurrentCost,
      loanClosingData: {
        readyToClose,
      },
      loanPricingData
    } = loanDetails;

    const getLoanTermYears = () => {
      return `${duration / 12} years`;
    };

    const { closingStatusMessages, creditExpiryMessages, appraisalWarningMessages } = closingRequirements;

    return (
      <Paper title="KEY INFORMATION" className={classes.paper}>
        <Grid container>
          <Grid item xs={12} lg={6}>
            <Box p={2}>
              <List>
                <ListItem>
                  <Typography variant="h5">Loan Terms</Typography>
                </ListItem>
                {listItem('Loan Amount', usdAmount(amount))}
                {divider}
                {listItem('Expected Closing Date', expectedClosingDate)}
                {divider}
                {listItem('Ok to close',
                <BooleanChip value={readyToClose}/>
                )}
                {divider}
                {properties?.length > 1 && (
                  <>
                    {listItem('Property Count', properties.length)}
                    {divider}
                  </>
                )}
                {listItem('Loan Term (years)', getLoanTermYears())}
                {divider}
                {listItem('Loan Rate-Type', rateType)}
                {divider}
                {listItem('Loan Amortization', amortizationATF)}
                {divider}
                {listItem(
                  'Prepay Penalty (Years/Stepdown)',
                  prepaymentDescription
                )}
                {divider}
                {listItem(
                  'Maximum Loan to Value',
                  formatPercentage(aggregateLoanToAsIs, 3)
                )}
                {divider}
                {listItem('Loan Purpose', loanPurpose)}
                {divider}
                {listItem('Recourse', recourseStructure)}
                {divider}
                {listItem(
                  'Pledge of Shares',
                  <BooleanChip value={pledgeOfShares} />
                )}
                {divider}
                {listItem(
                  'Cash Management',
                  <BooleanChip value={cashManagement} />
                )}
                {listItem(
                  'Loan LTC at Origination',
                  formatPercentage(aggregateLoanToCurrentCost)
                )}
                {divider}
                {listItem('Int Only Periods (Months)', interestOnlyMonths)}
                {divider}
                {listItem(
                  'Monthly Principal And Interest Payment',
                  usdAmount(interestPayment)
                )}
                {divider}
                {listItem(
                  'Borrower Gross Rate Lock Expiry Date',
                  !isNil(loanPricingData.borrowerGrossRateLockExpiryDate) &&
                  formatDate(
                    loanPricingData.borrowerGrossRateLockExpiryDate,
                    'MMMM d, yyyy'
                  )
                )}
                {divider}
                {listItem(
                  'Borrower Rate Lock Extension 1 Expiry Date',
                  !isNil(loanPricingData.firstPaidBorrowerGrossRateLockExpiryDate) &&
                  formatDate(
                    loanPricingData.firstPaidBorrowerGrossRateLockExpiryDate,
                    'MMMM d, yyyy'
                  )
                )}
                {divider}
                {listItem(
                  'Borrower Rate Lock Extension 2 Expiry Date',
                  !isNil(loanPricingData.secondPaidBorrowerGrossRateLockExpiryDate) &&
                  formatDate(
                    loanPricingData.secondPaidBorrowerGrossRateLockExpiryDate,
                    'MMMM d, yyyy'
                  )
                )}
                {divider}
              </List>
            </Box>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Box p={2}>
              <List>
                {listItem(
                  'Interest Reserve (at closing)',
                  usdAmount(interestReserve)
                )}
                {divider}
                {listItem(
                  'Property Taxes Reserve (at closing)',
                  usdAmount(taxEscrowAtClose)
                )}
                {divider}
                {listItem(
                  'Property Tax Escrow (ongoing monthly amount)',
                  usdAmount(taxEscrowOngoingMonthly)
                )}
                {divider}
                {listItem(
                  'Insurance Reserve (at closing)',
                  usdAmount(insuranceEscrowAtClose)
                )}
                {divider}
                {listItem(
                  'Insurance Escrow (ongoing monthly amount)',
                  usdAmount(insuranceEscrowOngoingMonthly)
                )}
              </List>
            </Box>
            <Box p={2}>
              <List>
                <ListItem>
                  <Typography variant="h5">Splits</Typography>
                </ListItem>
                <LoanEconomicTerm loanDetails={loanDetails} />
              </List>
            </Box>
          </Grid>
          {!readyToClose ? (
            <LoanWarningMessages warnings={closingStatusMessages} title={"Why loan can't be closed?"} />
          ) : null}
          {creditExpiryMessages?.length > 0 && (
            <LoanWarningMessages warnings={creditExpiryMessages} title={"Credit Expiry Warnings"} />
          )}
          {appraisalWarningMessages?.length > 0 && (
            <LoanWarningMessages warnings={appraisalWarningMessages} title={"Appraisal Warnings"} />
          )}
        </Grid>
      </Paper>
    );
  }
);
