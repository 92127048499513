import {
  GlobalStore,
  GridStore,
  ProcessEntitlements,
} from '@roc/feature-app-core';
import { CLOSING, DRAWS, SERVICING, UNDERWRITING } from '@roc/feature-utils';
import { action, computed, makeObservable, observable } from 'mobx';
import { LoanTodoStore } from './loanTodoStore';

export abstract class LoanBaseStore {
  protected globalStore: GlobalStore;

  abstract myFundedLoansForDrawsGridStore: GridStore;
  abstract fundedLoansForPayoffGridStore: GridStore;
  abstract fundedLoansForExtensionsGridStore: GridStore;
  abstract loanDetails;

  constructor(globalStore: GlobalStore) {
    this.globalStore = globalStore;

    makeObservable(this, {
      getLoanProcessEntitlements: action,
      loanDetails: observable,
    });
  }

  getLoanProcessEntitlements(loanProcess: string): ProcessEntitlements {
    const { userFeatures } = this.globalStore;
    if (!userFeatures) return null;
    if (loanProcess === UNDERWRITING) {
      return userFeatures.underwritingEntitlements;
    } else if (loanProcess === CLOSING) {
      return userFeatures.closingEntitlements;
    } else if (loanProcess === DRAWS) {
      return userFeatures.drawsEntitlements;
    } else if (loanProcess === SERVICING) {
      return userFeatures.servicingEntitlements;
    }
  }
}
