import { Page } from '../../../components';
import { Route } from 'react-router';
import { RocUniversity } from '../rocUniversity';
import { useStore } from '../../../hooks/useStore';

export const getRocUniversityRoutesConfig = (
  basePath: string,
  baseUrl: string
) => {
  return {
    rocUniversity: {
      path: `${basePath}/roc-university`,
      url: `${baseUrl}/roc-university`,
      documentTitle: 'Roc University',
    },
  };
};

export const getRocUniversityRoutes = (basePath: string, baseUrl: string) => {
  const config = getRocUniversityRoutesConfig(basePath, baseUrl);
  const { rocUniversityStore } = useStore();
  return {
    rocUniversity: (
      <Route exact path={config.rocUniversity.path}>
        <Page routeDefinition={config.rocUniversity}>
          <RocUniversity rocUniversityStore={rocUniversityStore} />
        </Page>
      </Route>
    ),
  };
};
