import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { LoginLayout } from './components/loginLayout';
import { useAppConfigurations } from '@roc/client-portal-shared/hooks';
import { createStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { LoginLogoImg } from '@roc/feature-app-core';

const useStyles = makeStyles(theme =>
  createStyles({
    gridContainer: {
      padding: theme.spacing(4),
      maxWidth: 500,
      margin: 'auto',
    },
  })
);

export const SuccessNewUserRegistration = () => {
  const classes = useStyles();
  const { data } = useAppConfigurations();
  return (
    <LoginLayout>
      <Grid container justifyContent="center" className={classes.gridContainer}>
        <Grid container item xs={12} spacing={4}>
          <Grid item xs={12}>
            <LoginLogoImg />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4" align="center">
              Registration successful!
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" align="center">
              Thank you for using our platform, we received your information.
              Shortly you’ll receive an email with a link to verify your
              account.
            </Typography>
          </Grid>
          {data.phoneNumber && (
            <>
              <Grid item xs={12}>
                <Typography variant="body1" align="center">
                  If you need further assistance, please contact us at:
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6" align="center">
                  {data.phoneNumber}
                </Typography>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </LoginLayout>
  );
};
