import { useLogoutStore } from "@roc/feature-app-core";
import { useEffect } from "react";

export const Logout = () => {

  const { logoutStore } = useLogoutStore();

  useEffect(() => {
    logoutStore.logout();
  }, []);

  return null;
}