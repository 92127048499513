import softCosts from './softCosts';
import demolition from './demolition';
import substructure from './substructure';
import exteriorShell from './exteriorShell';
import interiors from './interiors';
import services from './services';
import appliancesCabinetryAndMillworkCountertops from './appliancesCabinetryAndMillworkCountertops';
import specialConstruction from './specialConstruction';
import siteWork from './siteWork';
import contingency from './contingency';

export const dataMap = {
  'Soft Costs': softCosts,
  Demolition: demolition,
  Substructure: substructure,
  'Exterior/Shell': exteriorShell,
  Interiors: interiors,
  Services: services,
  'Appliances, Cabinetry & Millwork, Countertops': appliancesCabinetryAndMillworkCountertops,
  'Special Construction': specialConstruction,
  'Site Work': siteWork,
  Contingency: contingency,
};

export default dataMap;
