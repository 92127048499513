import { Box, Typography } from '@material-ui/core';
import { useBaseStore } from '@roc/feature-app-core';
import { useLoanStore } from '@roc/feature-loans';
import { Paper } from '@roc/ui';
import { observer } from 'mobx-react';

export const RelationshipManagerInfo = observer(() => {
  const { globalStore } = useBaseStore();
  const { loanStore } = useLoanStore();
  const { relationshipManagerData } = loanStore;
  const { isLenderOriginator } = globalStore.userFeatures;

  return (
    <>
      {!isLenderOriginator && (
        <Paper style={{ marginBottom: 32 }}>
          <Box p={2}>
            <Typography
              component="span"
              style={{ display: 'inline-block', marginRight: 100 }}
            >
              <Typography component="span" color="textSecondary">
                Primary RM:{' '}
              </Typography>
              <strong>
                {relationshipManagerData?.primaryRelationshipManager}
              </strong>
            </Typography>
            <Typography component="span" style={{ display: 'inline-block' }}>
              <Typography component="span" color="textSecondary">
                Secondary RM:{' '}
              </Typography>
              <strong>
                {relationshipManagerData?.secondaryRelationshipManager}
              </strong>
            </Typography>
          </Box>
        </Paper>
      )}
    </>
  );
});

export default RelationshipManagerInfo;
