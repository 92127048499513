import { Service } from './service';

const url = {
  GET_ONLINE_USERS: '/api/v1/vendor/websocket-rest/getOnlineUsers',
};

export class WebSocketService extends Service {
  getOnlineUsers() {
    return this.get(
      url.GET_ONLINE_USERS, {},
      {
        disableGlobalLoading: true,
      }
    );
  }
}
