import { observable, makeObservable } from 'mobx';
import { GlobalStore } from '@roc/feature-app-core';
import { FormStore } from '@roc/feature-app-core';
import { Property } from '@roc/feature-types';

const propertyForm = {
  fields: {
    occupancy: {
      value: '',
      error: null,
      rule: '',
    },
    stories: {
      value: '',
      error: null,
      rule: '',
    },
    yearBuilt: {
      value: '',
      error: null,
      rule: '',
    },
    foundation: {
      value: '',
      error: null,
      rule: '',
    },
    tenantVacate: {
      value: '',
      error: null,
      rule: '',
    },
    exitStrategy: {
      value: '',
      error: null,
      rule: '',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export class PropertyFormStore extends FormStore {
  propertyId: string;
  address: string;
  loanPropertyId: number;

  constructor(property: Property, globalStore: GlobalStore) {
    super({ ...propertyForm }, globalStore);
    makeObservable(this, {
      address: observable,
    });
    this.initialize(property);
  }

  private initialize(property: Property) {
    this.propertyId = property.propertyId;
    this.loanPropertyId = property.loanPropertyId;
    this.address = property.address;
    this.loadForm(property);
  }
}

export default PropertyFormStore;
