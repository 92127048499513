import { Chip, Theme } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { AUTOMATED_EDIT_APPRAISAL_REVIEW } from './constants';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chipManual: {
      backgroundColor: theme.palette.success.main,
      color: 'white',
      marginLeft: theme.spacing(2),
    },
    chipAutomated: {
      backgroundColor: theme.palette.warning.main,
      color: 'white',
      marginLeft: theme.spacing(2),
    },
  })
);

export const ManualOrAutomatedChip = ({ status }) => {
  const classes = useStyles();
  return (
    <Chip
      size="small"
      className={status === AUTOMATED_EDIT_APPRAISAL_REVIEW ? classes.chipAutomated : classes.chipManual}
      label={status}
    />
  );
};
