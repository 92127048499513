import React, { useEffect } from 'react';
import { Trulioo, event } from '@trulioo/docv'; // Import the Trulioo package
import '@trulioo/docv/style.css';


const MobileTruliooVerification = () => {
  const elementID = 'trulioo-sdk'; // The HTML element id to attach to
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const shortCodeParam = urlParams.get("code") // Obtained the short code from the url param if there is any

  let selectedShortCode = shortCodeParam // Use the obtained short code from the url param


  useEffect(() => {
    const workflowTheme = Trulioo.workflowTheme() // Create the WorkflowTheme object
      .setPrimaryButtonColor("#FF9800")
      .setPrimaryButtonTextColor("#007f5c")
      .build()

    const workflowOption = Trulioo.workflow().setShortCode(selectedShortCode)
      .setTheme(workflowTheme)

  
    const callbacks = new event.adapters.ListenerCallback({
      onComplete: (success) => {
        console.info(`Verification Successful: ${success.transactionId} `)
      },
      onError: (error) => {
        console.error(`Verification Failed with Error Code: ${error.code}, TransactionID: ${error.transactionId}, Reason: ${error.message}`)
      },
      onException: (exception) => {
        console.error("Verification Failed with Exception:", exception)
      }
    })
    const callbackOption = Trulioo.event().setCallbacks(callbacks);
    // if (shortCodeParam === null ){
    Trulioo.initialize(workflowOption)
      .then((complete) => {
        console.info('Initialize complete:', complete);
        Trulioo.launch(elementID, callbackOption)
          .then((success) => {
            console.info('Launch success:', success);
          });
      })
      .catch((error) => {
        console.error('Error:', error);
      })
    //} else
  }, []);
  return <div id={elementID}></div>;
};
export default MobileTruliooVerification;