import { GlobalStore } from '@roc/feature-app-core';
import { action, flow, makeObservable } from 'mobx';
import { TwoFactorService } from '../services/twoFactorService';
import { TwoFactorInfoResponse } from '../types';

export interface SearchResult {
  loanId: number;
  lenderCompanyId: number;
  dealName: string;
  lender: string;
}

export class TwoFactorStore {
  private globalStore: GlobalStore;
  private twoFactorService: TwoFactorService;

  constructor(globalStore) {
    this.globalStore = globalStore;
    this.twoFactorService = new TwoFactorService();
    makeObservable(this, {
      getTwoFactorInfo: action,
      getQrImage: action,
      getTwoFactorCode: action,
      enableTwoFactor: action,
      disableTwoFactor: action,
      activateTwoFactor: action,
      verifyTwoFactor: action,
    });
  }

  async getTwoFactorInfo(twoFactorType: string) {
    try {
      const response = await this.twoFactorService.getTwoFactorInfo(twoFactorType);
      return response.data.data as TwoFactorInfoResponse;
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error fetching two factor info',
      });
      throw e;
    }
  }

  async getQrImage(twoFactorType: string) {
    try {
      const response = await this.twoFactorService.getQrImage(twoFactorType);
      return response.data.data as string;
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error fetching QR image',
      });
      throw e;
    }
  }

  async getTwoFactorCode(twoFactorType: string) {
    try {
      const response = await this.twoFactorService.getTwoFactorCode(twoFactorType);
      return response.data.data as string;
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error fetching two factor code',
      });
      throw e;
    }
  }

  async enableTwoFactor(twoFactorType: string) {
    try {
      const response = await this.twoFactorService.enableTwoFactor(twoFactorType);
      return response.data.data;
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error enabling two factor',
      });
      throw e;
    }
  }

  async disableTwoFactor(twoFactorType: string, code: string) {
    try {
      const response = await this.twoFactorService.disableTwoFactor(twoFactorType, code);
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Two-Factor Authentication is turned OFF!'
      });
      return response.data.data;
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Invalid code',
      });
      throw e;
    }
  }

  async activateTwoFactor(twoFactorType: string, code: string) {
    try {
      const response = await this.twoFactorService.activateTwoFactor(twoFactorType, code);
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Two-Factor Authentication is turned ON!'
      });
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error activating two factor',
      });
      throw e;
    }
  }

  async verifyTwoFactor(twoFactorType: string, code: string) {
    try {
      const response = await this.twoFactorService.verifyTwoFactor(twoFactorType, code);
      return true;
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error verifying two factor',
      });
      throw e;
    }
  }
}
