import { Checkbox } from '@material-ui/core';
import { CheckBoxOutlineBlankSharp, CheckBoxSharp } from '@material-ui/icons';
import { Autocomplete, AutocompleteProps } from '@material-ui/lab';
import React from 'react';
import { FieldLabel } from '../fieldLabel/fieldLabel';
import { TestDataAttribute } from '../testDataAttribute';

export interface MultiSelectFieldProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> extends AutocompleteProps<T, Multiple, DisableClearable, FreeSolo> {
  standaloneLabel?: boolean;
  name?: string;
  label?: string;
  required?: boolean;
  testId: string;
  disabled?: boolean;
}

const icon = <CheckBoxOutlineBlankSharp fontSize="small" />;
const checkedIcon = <CheckBoxSharp fontSize="small" />;

const _multiSelectField = (
  props: MultiSelectFieldProps<any, any, any, any>
) => {
  const { testId, ...rest } = props;

  const renderOption = (option, { selected }) => (
    <React.Fragment>
      <Checkbox
        icon={icon}
        checkedIcon={checkedIcon}
        style={{ marginRight: 8 }}
        checked={selected}
      />
      {option.label}
    </React.Fragment>
  )

  return (
    <TestDataAttribute id={testId}>
      <Autocomplete
        {...rest}
        multiple
        disabled={props?.disabled || false}
        disableCloseOnSelect
        getOptionLabel={option => option.label}
        renderOption={props.renderOption ?? renderOption}
        value={props.value}
      />
    </TestDataAttribute>
  );
};

export const MultiSelectField = (
  props: MultiSelectFieldProps<any, any, any, any>
) => {
  const { standaloneLabel, ...muiComponentProps } = props;
  return (
    <>
      {standaloneLabel && muiComponentProps.label ? (
        <FieldLabel>
          {muiComponentProps.label}
          {muiComponentProps.required && ' *'}
        </FieldLabel>
      ) : null}
      <_multiSelectField
        {...muiComponentProps}
        label={standaloneLabel ? undefined : muiComponentProps.label}
      />
    </>
  );
};
