import { ApiResponse, GlobalStore, UserStore } from "@roc/feature-app-core";
import { DialogState } from "@roc/ui";
import { action, flow, makeObservable, observable } from "mobx";
import { Broker } from "../types/brokerTypes";
import { BrokersStore } from "./brokersStore";
import { OTHER } from "@roc/feature-utils";

export class ManageBrokersStore {
  private globalStore: GlobalStore;
  userStore: UserStore;
  brokersStore: BrokersStore;

  selectDialogState: DialogState = null;
  editDialogState: DialogState = null;

  brokers: Broker[];

  allowMultiple: boolean;

  requestSuccess: boolean;

  public lenderAttorneysOptionList: any[];
  public brokerVendorClosingSettings;

  constructor(brokersStore: BrokersStore, globalStore: GlobalStore) {
    this.globalStore = globalStore;
    this.brokersStore = brokersStore;
    this.brokers = [];
    this.requestSuccess = false;
    this.setDefaults();

    makeObservable(this, {
      saveBroker: flow,
      createBrokerUserAccount: flow,
      requestSuccess: observable,
      setRequestSuccess: action,
      resendInvite: flow,
      getBrokerLoginLink: flow,
      deactivateUser: flow,
      getAttorneysForBrokers: flow,
      lenderAttorneysOptionList: observable,
      getBrokerVendorClosingSettingsByBrokerId: flow,
      brokerVendorClosingSettings: observable
    });
  }

  setDefaults() {
    this.lenderAttorneysOptionList = [];
  }

  *saveBroker() {
    const { manageBrokerFormStore, brokerService, brokerContactsGridStore, selectBrokersStore, brokerFeesStore, brokerVendorClosingSettingsFormStore } = this.brokersStore;
    manageBrokerFormStore.runFormValidationWithMessage();
    if (manageBrokerFormStore.form.meta.isValid) {
      try {
        const broker = manageBrokerFormStore.getBroker();
        const data = [brokerFeesStore?.brokerFees?.bridgeFees, brokerFeesStore?.brokerFees?.termFees];
        const brokerEconomicsString = JSON.stringify(data);

        if (this.brokersStore.brokerVendorClosingSettingsFormStore.form.fields.preferredInsuranceCompany.value === OTHER) {
          this.brokersStore.brokerVendorClosingSettingsFormStore.form.fields.preferredInsuranceCompany.value =
            JSON.stringify(this.brokersStore.otherInsuranceFormStore.getFormValues())
        }

        if (this.brokersStore.brokerVendorClosingSettingsFormStore.form.fields.preferredTitleCompany.value === OTHER) {
          this.brokersStore.brokerVendorClosingSettingsFormStore.form.fields.preferredTitleCompany.value =
            JSON.stringify(this.brokersStore.otherTitleFormStore.getFormValues())
        }

        const brokerVendorClosingSettingsString = JSON.stringify(brokerVendorClosingSettingsFormStore.getBrokerVendorClosingSettings())
        const brokerWithEconomics = {
          ...broker,
          brokerEconomics: brokerEconomicsString,
          brokerVendorClosingSettings: brokerVendorClosingSettingsString
        };

        // Save the broker with the attached economics data
        yield brokerService.saveBroker(brokerWithEconomics);
        this.globalStore.notificationStore.showSuccessNotification({
          message: 'Broker saved successfully.',
        });

        selectBrokersStore.reset();
        brokerVendorClosingSettingsFormStore.reset();
        brokerContactsGridStore.resetAndFetchGridData();
      } catch (e) {
        this.globalStore.notificationStore.showErrorNotification({
          message: 'Error while saving broker information.',
        });
      }
      selectBrokersStore.closeEditBrokerDialog();
      manageBrokerFormStore.reset();
    }
  }

  *createBrokerUserAccount(contactId, portalId) {
    try {
      const { brokerService, brokerContactsGridStore } = this.brokersStore;
      const response: ApiResponse = yield brokerService.createBrokerUser(
        {
          contactId,
          portalId
        }
      );
      brokerContactsGridStore.refresh();
      this.setRequestSuccess(true)
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Account created successfully.',
      });
    }
    catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while creating the broker portal account.',
      });
    }
  }

  *resendInvite(userId) {
    try {
      const { brokerService } = this.brokersStore;
      const response: ApiResponse = yield brokerService.resendInvite(userId);
      this.setRequestSuccess(true);
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Invite sent successfully.',
      });
    }
    catch (error) {
      this.setRequestSuccess(true);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while resending the invite.',
      });
    }
  }

  *deactivateUser(userId, username) {
    try {
      const { brokerService, brokerContactsGridStore } = this.brokersStore;
      const response: ApiResponse = yield brokerService.deactivateUser({ userId, username });
      brokerContactsGridStore.refresh();
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'User deactivated successfully.',
      });
    }
    catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while deactivating the user.',
      });
    }
  }

  setRequestSuccess(requestSuccess: boolean) {
    this.requestSuccess = requestSuccess;
  }

  *getBrokerLoginLink(userId) {
    try {
      const { brokerService } = this.brokersStore;
      const response: ApiResponse = yield brokerService.getBrokerLoginLink(userId);
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Link copied successfully!',
      });
      return response.data.data.passwordLink;
    }
    catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while getting broker login link.',
      });
    }
  }

  *getAttorneysForBrokers() {
    try {
      const { brokerService } = this.brokersStore;
      const response: ApiResponse = yield brokerService.getAttorneysForBrokers();
      const lenderAttorneysList = response.data && JSON.parse(response.data?.data);
      this.lenderAttorneysOptionList.length = 0;
      lenderAttorneysList
        .forEach((attorney) => {
          const option = { id: '', value: '', label: '' };
          option.id = attorney.investorAttorneyId;
          option.value = attorney.company.email;
          option.label = attorney.company.name;
          this.lenderAttorneysOptionList.push(option);
        });
    }
    catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while getting attorneys for brokers.',
      });
    }
  }

  *getBrokerVendorClosingSettingsByBrokerId(brokerId) {
    try {
      const { brokerService } = this.brokersStore;
      const lenderId = this.globalStore.lenderInfo?.lenderId;
      const response: ApiResponse = yield brokerService.getBrokerVendorClosingSettingsByBrokerId(brokerId, lenderId);
      this.brokerVendorClosingSettings = response.data.data;
      return response.data.data;
    }
    catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while getting attorneys for brokers.',
      });
    }
  }

}