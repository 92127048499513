import { Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import { ScopeOfWorkV2FormBaseStore, ScopeOfWorkV2FormStore } from '@roc/feature-sow-shared/stores/v2';
import { SOWTableCard } from '../../../scopeOfWorkV2TableCard/scopeOfWorkV2TableCard';
import { SOWLTCTableCard } from '../../../scopeOfWorkV2TableCard/scopeOfWorkLtcCatchupTable';
import SubcategoryItemizedDetailsModal from './detailsModal/subcategoryItemizedDetailsModal';
import { Grid, Button } from '@material-ui/core';

interface SubcategoriesProps {
  store: ScopeOfWorkV2FormBaseStore;
}

const Subcategories = observer((props: SubcategoriesProps) => {
  const { categoryStore } = props.store;
  const store = props.store;

  const columns = [
    {
      name: 'LINE ITEM',
      width: '15%',
    },
    {
      name: 'DETAIL',
      width: '15%',
    },
    {
      name: 'COST',
      width: '10%',
    },
    {
      name: '% COMPLETED RENOVATIONS',
      width: '20%',
      align: 'center',
    },
    {
      name: 'RENO BUDGET',
      width: '10%',
      align: 'center',
    },
    {
      name: 'DESCRIPTION',
      width: '30%',
    },
  ];

  return (
    <>
      <Grid container>
        <Grid item xs={4}>
          <Typography variant="h4">Subcategories</Typography>
        </Grid>
      </Grid>
      <br />
      <Typography variant="body2">
        Please fill out the information below to describe the Scope of Work
      </Typography>
      <br />

      {categoryStore.sortedCategories.map(selectedCategory => (
        <>
          {selectedCategory.name !== 'LTC Catchup' && (
            <SOWTableCard
              store={props.store}
              title={selectedCategory.name}
              rows={selectedCategory.subCategories}
              columns={columns}
              addNewItemRows={
                categoryStore.subcategoryOptions[selectedCategory.categoryId]
              }
              category={selectedCategory}
              readonly={
                props.store.isDisabled ||
                selectedCategory.name === 'LTC Catchup'
              }
              tooltip={selectedCategory.tooltip}
            />
          )}
          {store?.ltcCatchupAmount &&
            selectedCategory.name === 'LTC Catchup' && (
              <SOWLTCTableCard
                store={props.store}
                title={selectedCategory.name}
                rows={selectedCategory.subCategories}
                addNewItemRows={
                  categoryStore.subcategoryOptions[selectedCategory.categoryId]
                }
                category={selectedCategory}
                readonly={true}
                tooltip={selectedCategory.tooltip}
              />
            )}
          <br />
        </>
      ))}
      <SubcategoryItemizedDetailsModal store={props.store} />
    </>
  );
});

export default Subcategories;
