import { isNil } from "@roc/feature-utils";
import { formatCurrency } from "@roc/ui/utils";
import { format } from 'date-fns';


export const formatAbbreviatedCurrency = (num) => {
  if (isNil(num) || num == '' || num == 0) {
    return 'N/A'
  }
  const suffixes = ['', 'k', 'M', 'B', 'T'];

  if (num < 1000) return `$${num}`;

  const suffixIndex = Math.floor(Math.log10(num) / 3);
  const shortNum = num / Math.pow(1000, suffixIndex);

  const formattedShortNum = shortNum % 1 === 0 ? shortNum.toFixed(0) : shortNum.toFixed(1);

  return `$${formattedShortNum}${suffixes[suffixIndex]}`;
};

export const formatPrice = (price: number, rent: number = 0, rentalComps: boolean = false) => {
  const formattedPrice = formatCurrency(rentalComps ? rent : price, 0);
  return formattedPrice === '$0' ? 'N/A' : formattedPrice;
}

export const formatDate = (date) => {
  if (isNil(date) || date == '') {
    return '-'
  }
  return format(new Date(date), 'MMM dd, yyyy');
}
