import { Service } from './service';

const url = {
  LOGIN: '/api/v1/oauth2/token',
  FORGOT_PASSWORD: '/api/v1/user/public/forgotPassword',
  FORGOT_PASSWORD_MOBILE: '/api/v1/user/public/forgotPasswordMobile',
  CHECK_CORRECT_PORTAL: '/api/v1/loan/public/portalconfiguration/getPortalConfigurationByUserName',
};

export class LoginService extends Service {
  mobileLogin(username: string, password: string) {
    return this.post(
      url.LOGIN,
      {
        grant_type: 'password_mobile',
        username,
        password,
      }
    );
  }
  loginWithHost(username: string, password: string, portalId: string, correctPortalHost: string, grant_type: string = 'password') {
    return this.post(
      correctPortalHost + url.LOGIN,
      {
        grant_type,
        username,
        password,
      },
      {
        'x-portal-id': portalId,
      }
    );
  }
  login(username: string, password: string, portalId: string, grant_type: string = 'password') {
    return this.post(
      url.LOGIN,
      {
        grant_type,
        username,
        password,
      },
      {
        'x-portal-id': portalId,
      }
    );
  }
  forgotPasswordSendEmail(username: string, portalId: string) {
    return this.post(url.FORGOT_PASSWORD, {
      username,
      portalId,
    });
  }
  forgotPasswordSendEmailMobile(username: string) {
    return this.post(url.FORGOT_PASSWORD_MOBILE, {
      username
    });
  }
  checkCorrectPortal(username: string) {
    return this.post(url.CHECK_CORRECT_PORTAL, {
      username
    });
  }
}
