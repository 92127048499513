import { Page } from '@roc/feature-app-core';
import { Route } from 'react-router';

import { BorrowerLoansDashboard } from '../modules/borrowerLoans/borrowerLoansDashboard';
import { getLoanRoutesConfig } from '@roc/feature-loans-routes-config';
import { BorrowerLoanDetailsV2 } from '../modules/borrowerLoanDetails/borrowerLoanDetailsV2';

const LOANS_HOME_BASE_PATH = 'loans';
const LOAN_BASE_PATH = ':loanId';

export const getBorrowerLoanRoutesConfig = (
  basePath: string,
  baseUrl: string
) => {
  return getLoanRoutesConfig(basePath, baseUrl);
};

export const getBorrowerLoanRoutes = (basePath: string, baseUrl: string) => {
  const config = getBorrowerLoanRoutesConfig(basePath, baseUrl);
  return {
    loansDashboard: (
      <Route exact path={config.loansHome.path}>
        <Page routeDefinition={config.loansHome}>
          <BorrowerLoansDashboard />
        </Page>
      </Route>
    ),
    loanDetails: (
      <Route
        path={[config.loans().children.drawDetails().path, config.loans().path]}
      >
        <BorrowerLoanDetailsV2 />
      </Route>
    ),
  };
};
