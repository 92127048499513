import { action, makeObservable, observable } from 'mobx';
import { GlobalStore } from '@roc/feature-app-core';
import { FormStore } from '@roc/feature-app-core';
import { Broker } from './../types/brokerTypes';

export const form = {
  fields: {
    brokerId: { value: null },
    lenderId: { value: null },
    userId: { value: null },
    companyId: { value: null },
    contactId: { value: null },
    firstName: {
      value: '',
      error: null,
      rule: 'required',
      attribute: 'first name',
    },
    lastName: {
      value: '',
      error: null,
      rule: 'required',
      attribute: 'last name',
    },
    email: {
      value: '',
      error: null,
      rule: 'required|email',
    },
    phone: {
      value: '',
      error: null,
      rule: 'phone',
      attribute: 'phone number',
    },
    companyName: {
      value: '',
      error: null,
      rule: 'required',
      attribute: 'entity name',
    },
    einNumber: {
      value: '',
      error: null,
      rule: '',
      attribute: 'entity tax number',
    },
    portalStatus: {
      value: '',
      error: null,
      attribute: 'User Portal Status',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export class ManageBrokerFormStore extends FormStore {
  isEdit: boolean;
  isDisabled: boolean;

  constructor(globalStore: GlobalStore) {
    super(form, globalStore);
    this.isEdit = false;
    this.isDisabled = false;
    makeObservable(this, {
      setIsEdit: action,
      setIsDisabled: action,
      isEdit: observable,
      isDisabled: observable,
    });
  }

  setBroker(broker: Broker) {
    this.reset();
    this.loadForm(broker);
  }

  getBroker(): Broker {
    return this.getFormValues();
  }

  setIsEdit(value: boolean) {
    this.isEdit = value;
  }

  setIsDisabled(value: boolean) {
    this.isDisabled = value;
  }
}
