import { TextField, Divider } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { ExternalLeadStore } from 'libs/client-portal-shared/src/app/modules/companyProfile/stores/externalLeadStore';
import { LEAD_OBJECT_TYPE } from '../leads/constants/constants';

interface IDashboardAutocompleteField {
  store: ExternalLeadStore;
}

export const DashboardAutocompleteField = observer(({ store }: IDashboardAutocompleteField) => {
  const { sendEmailsStore, ringCentralStore, leadsDashboardStore } = store;
  const { dashboards, standardDashboards, currentDashboardId } = leadsDashboardStore;

  const onSelect = (opt) => {
    leadsDashboardStore.selectDashboard(opt.value);
  }

  const customOptions = dashboards?.map(dashboard => ({
    label: dashboard.name,
    value: dashboard.id,
  }))

  const standardOptions = standardDashboards.map(standardDashboard => ({
    label: standardDashboard.name,
    value: standardDashboard.dashboardListId,
    isStandard: true,
    type: standardDashboard?.objectType
  }))

  const options = standardOptions.concat(customOptions)
  const selectedOption = options.find(o => o.value === currentDashboardId);

  useEffect(() => {
    ringCentralStore.resetLists();
    sendEmailsStore.resetbulkEmails();
    store.resetLeadsIds();
    store.resetBorrowerIds();
    store.resetCompanyContactIds();
    store.option = selectedOption;
  }, [currentDashboardId]);

  return (
    <Autocomplete
      id="leads-dashboard-autocomplete"
      selectOnFocus
      handleHomeEndKeys
      options={options}
      defaultValue={options.find(o => o.value === currentDashboardId) || {}}
      inputValue={store.searchText}
      size="small"
      blurOnSelect={true}
      onInputChange={(e, value) => (store.searchText = value)}
      renderInput={params => <TextField variant={'outlined'} {...params} />}
      getOptionLabel={option => option?.label ?? ''}
      getOptionSelected={(option, selected) => option?.value === selected?.value}
      onChange={(event, option) => onSelect(option)}
      placeholder={'Select lead dashboard name'}
      fullWidth
      groupBy={(option: any) => {
        return option.isStandard && option.type === LEAD_OBJECT_TYPE ? 'Standard Lead Views' : option.isStandard ? 'Standard Contacts Views' : 'Custom Views';
      }}
    />
  );
});
