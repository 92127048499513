import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { SelectField } from '@roc/ui';

import {
  FileUploadModal,
  FileUpload,
} from '@roc/ui';

interface IAppraisalFileUploadModalProps {
  open: boolean;
  values: string[];
  onClose: () => void;
  onUpload: (documentType: string, fileUpload: FileUpload) => void;
}

export const AppraisalFileUploadModal = ({
  open,
  values,
  onClose,
  onUpload,
}: IAppraisalFileUploadModalProps) => {
  const [selectedOption, setSelectedOption] = useState('');
  const [options, setOptions] = useState([]);

  const _onUpload = (uploads: FileUpload[]) => {
    if (!onUpload) return;
    onUpload(selectedOption, uploads[0]);
  };

  useEffect(() => {
    setSelectedOption('');
  }, [open]);

  useEffect(() => {
    if (values) {
      const options = values.map(item => ({ label: item, value: item }));
      setOptions(options);
    } else {
      setOptions([]);
    }
  }, [values]);

  return (
    <FileUploadModal
      title="ADD APPRAISAL DOCUMENT"
      open={open}
      top={
        <Box>
          <SelectField
            required={true}
            variant="outlined"
            label="Document Type"
            value={selectedOption}
            options={options}
            onChange={setSelectedOption}
            fullWidth
            testId="documentType"
          />
        </Box>
      }
      multiple={false}
      disabled={!selectedOption}
      onClose={onClose}
      onUpload={_onUpload}
    />
  );
};
