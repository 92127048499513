import { Grid, Box, Typography } from "@material-ui/core";
import { Card } from "@roc/ui";
import { observer } from "mobx-react";
import { useLoanSummaryStore } from "../../hooks/useLoanSummaryStore";


export const BorrowerExperienceComments = observer(() => {
  const { loanSummaryStore } = useLoanSummaryStore();
  const { loanSummaryModalStore } = loanSummaryStore;

  const experienceComments = loanSummaryModalStore?.currentBorrower?.experienceComments;

  return (
    <Card
      title="Comments"
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box>
            {experienceComments ? (
              <Typography variant="body1">{experienceComments}</Typography>
            ) : (
              <Typography variant="body1">No comments available</Typography>
            )}
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
});