import { ApiResponse, GlobalStore } from "@roc/feature-app-core";
import { makeObservable, flow, observable } from "mobx";
import { ManageNotificationsService } from "../services/manageNotificationsService";

export class ManageNotificationsStore {
  manageNotificationsService: ManageNotificationsService;
  globalStore: GlobalStore;
  public unsubscribeSuccessful: boolean;

  constructor(globalStore: GlobalStore) {
    this.globalStore = globalStore;
    this.manageNotificationsService = new ManageNotificationsService();
    this.unsubscribeSuccessful = false;
    makeObservable(this, {
      optOutFromNotifications: flow,
      unsubscribeSuccessful: observable,
    });
  }

  *optOutFromNotifications(param: string) {
    try {
      yield this.manageNotificationsService.optOutFromNotifications(param)
        .then(response => {
          this.unsubscribeSuccessful = true;
        });
    } catch (e) {
      this.unsubscribeSuccessful = false;
      this.globalStore.notificationStore.showErrorNotification({
        message: `Error while unsubscribing`,
      });
      console.log(e);
    }
  }
}