import {
  makeStyles,
  Theme,
  lighten,
  createStyles,
} from '@material-ui/core/styles';
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Card,
  IconButton,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { observer } from 'mobx-react';
import { ReactNode } from 'react';
import { Edit } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    accordionSummary: {
      backgroundColor: '#D9DADC',
      '& .MuiAccordionSummary-content': {
        margin: '10px 0px',
      },
    },
    details: {
      borderTop: `1px solid ${lighten(theme.palette.secondary.main, 0.9)}`,
      marginBottom: -1,
    },
    noMarginBottom: {
      marginBottom: '0px',
    },
  })
);

interface Props {
  title: string;
  children: ReactNode;
  onEdit?: (e) => void;
  expanded?: boolean;
}

export const ForeclosureDetailsCard = observer(
  ({ title, children, onEdit, expanded = true }: Props) => {
    const classes = useStyles();

    return (
      <Card elevation={0} variant="outlined">
        <Box display={'flex'} flexDirection={'row'}>
          <Box flexGrow={1}>
            <Accordion defaultExpanded={expanded}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                className={classes.accordionSummary}
              >
                <Typography
                  gutterBottom
                  variant="h5"
                  component="h2"
                  className={classes.noMarginBottom}
                >
                  {title}
                </Typography>
                <Box
                  display="flex"
                  flex="1"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  {!!onEdit && (
                    <IconButton
                      onClick={onEdit}
                      color="primary"
                      style={{ margin: -8 }}
                    >
                      <Edit />
                    </IconButton>
                  )}
                </Box>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                {children}
              </AccordionDetails>
            </Accordion>
          </Box>
        </Box>
      </Card>
    );
  }
);
