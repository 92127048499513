import { Service } from '@roc/feature-app-core';
const url = {
  GET_COMPANIES_LOANS_BACKOFFICE: '/api/v1/loan/getCompaniesLoansForBackOfficeRole',
  GET_COMPANIES_LOANS_BACKOFFICE_BY_LENDER_ID: '/api/v1/loan/getLenderLoansGrid',
  GET_COMPANIES_LOANS_BACKOFFICE_BY_CONTACT_ID: '/api/v1/loan/getBorrowerLoansGrid',
  GET_MY_LOANS: '/api/v1/loan/getMyLoansForBackofficeView',
  GET_APPRAISALTASKS_BACKOFFICE: '/api/v1/loan/getMyPendingAppraisalTasksForBackoffice',

};
export class BackOfficeLoansService extends Service {
  getCompaniesLoansForBackOfficeRole(
    rmType: string,
    page: number,
    pageSize: number,
    sortDir: string,
    sortBy: string,
    filters: string,
    dropdownFilters: string,
    lenderId: number = null,
    borrowerId: number = null
  ) {
    return this.get(
      url.GET_COMPANIES_LOANS_BACKOFFICE,
      {
        rmType,
        page,
        pageSize,
        sortDir,
        sortBy,
        filters,
        dropdownFilters,
        lenderId,
        borrowerId
      },
      {
        disableGlobalLoading: page > 1,
      }
    );
  }

  getCompaniesLoansForBackOfficeRoleByLenderId(
    rmType: string,
    page: number,
    pageSize: number,
    sortDir: string,
    sortBy: string,
    filters: string,
    dropdownFilters: string,
    lenderId: number = null,
  ) {
    return this.get(
      url.GET_COMPANIES_LOANS_BACKOFFICE_BY_LENDER_ID,
      {
        rmType,
        page,
        pageSize,
        sortDir,
        sortBy,
        filters,
        dropdownFilters,
        lenderId,
      },
      {
        disableGlobalLoading: page > 1,
      }
    );
  }

  getCompaniesLoansForBackOfficeRoleByBorrowerId(
    rmType: string,
    page: number,
    pageSize: number,
    sortDir: string,
    sortBy: string,
    filters: string,
    dropdownFilters: string,
    borrowerId: number = null
  ) {
    return this.get(
      url.GET_COMPANIES_LOANS_BACKOFFICE_BY_CONTACT_ID,
      {
        rmType,
        page,
        pageSize,
        sortDir,
        sortBy,
        filters,
        dropdownFilters,
        borrowerId
      },
      {
        disableGlobalLoading: page > 1,
      }
    );
  }

  getMyLoans(
    page: number,
    pageSize: number,
    sortDir: string,
    sortBy: string,
    filters: string,
    dropdownFilters: string,
  ) {
    return this.get(
      url.GET_MY_LOANS,
      {
        dropdownFilters,
        page,
        pageSize,
        sortDir,
        sortBy,
        filters,
      },
      {
        disableGlobalLoading: page > 1,
      }
    );
  }

  getAppraisalTasksForBackOfficeRole(
    rmType: string,
    page: number,
    pageSize: number,
    sortDir: string,
    sortBy: string,
    filters: string,
    dropdownFilters: string,
    lenderId: number = null,
    borrowerId: number = null
  ) {
    return this.get(
      url.GET_APPRAISALTASKS_BACKOFFICE,
      {
        rmType,
        page,
        pageSize,
        sortDir,
        sortBy,
        filters,
        dropdownFilters,
        lenderId,
        borrowerId
      },
      {
        disableGlobalLoading: page > 1,
      }
    );
  }
}