import { LogBaseService } from "@roc/feature-app-core";

const url = {
  LOG_EVENT: '/nportal/rest/priv/logger/logEvent',
  LOG_EVENTS_APPRAISAL_REVIEW: '/nportal/rest/priv/logger/logEventsAppraisalReview'
};

export class LogInternalService extends LogBaseService {
  async logEvent(event: any) {
    try {
      this.post(url.LOG_EVENT, event, {}, { disableGlobalLoading: true });
    } catch (error) {
      console.log("An error occurred when logging an event", error);
    }
  }

  logEventsAppraisalReview(events: any[], items, propertyId: number, loanId: number) {
    try {
      const data = {
        sections: items,
        events
      };
      return this.post(`${url.LOG_EVENTS_APPRAISAL_REVIEW}?propertyId=${propertyId}&loanId=${loanId}`, data, {}, { disableGlobalLoading: true })
    } catch (error) {
      console.log("An error occurred when logging the event list", error)
    }
  }
}
