import React, {
  useState,
  useCallback,
  useMemo,
  ReactNode,
  MouseEvent,
  ChangeEvent,
} from 'react';
import {
  alpha,


} from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import Autocomplete, {
  AutocompleteCloseReason,
  AutocompleteRenderOptionState,
} from '@material-ui/lab/Autocomplete';
import { InputBase, Popper } from '@material-ui/core';
import { Button, ButtonProps } from '@roc/ui';
import clsx from 'clsx';
import { withTestDataAttribute } from '../testDataAttribute';

export type CustomSelectorButtonProps = ButtonProps & {
  label: string;
};

export type CustomSelectorAutoCompleteProps = {
  renderOption?: (
    option: any,
    state: AutocompleteRenderOptionState
  ) => ReactNode;
  getOptionLabel?: (option: any) => string;
  getOptionSelected?: (option: any, value: any) => boolean;
};

export interface CustomSelectorProps {
  buttonProps: CustomSelectorButtonProps;
  autoCompleteProps: CustomSelectorAutoCompleteProps;
  multiple?: boolean;
  values: any;
  selectedValues: any;
  optionsLabel: string;
  onSelect: any;
  popperClassName?: string;
  testId: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popper: {
      border: '1px solid rgba(27,31,35,.15)',
      boxShadow: '0 3px 12px rgba(27,31,35,.15)',
      borderRadius: 3,
      width: 500,
      zIndex: 1101,
      fontSize: 13,
      color: '#586069',
      backgroundColor: '#f6f8fa',
    },
    header: {
      borderBottom: '1px solid #e1e4e8',
      padding: '8px 10px',
      fontWeight: 600,
    },
    inputBase: {
      padding: 10,
      width: '100%',
      borderBottom: '1px solid #dfe2e5',
      '& input': {
        borderRadius: 4,
        backgroundColor: theme.palette.common.white,
        padding: 8,
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        border: '1px solid #ced4da',
        fontSize: 14,
        '&:focus': {
          boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
          borderColor: theme.palette.primary.main,
        },
      },
    },
    paper: {
      boxShadow: 'none',
      margin: 0,
      color: '#586069',
      fontSize: 13,
    },
    option: {
      minHeight: 'auto',
      alignItems: 'flex-start',
      padding: 8,
      '&[aria-selected="true"]': {
        backgroundColor: 'transparent',
      },
      '&[data-focus="true"]': {
        backgroundColor: theme.palette.action.hover,
      },
    },
    popperDisablePortal: {
      position: 'relative',
    },
  })
);

export const CustomSelector = withTestDataAttribute(
  ({
    buttonProps,
    autoCompleteProps,
    multiple,
    values,
    selectedValues,
    optionsLabel,
    onSelect,
    popperClassName,
    testId,
  }: CustomSelectorProps) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const defaultValue = multiple ? [] : {};
    const [pendingValue, setPendingValue] = useState<any>(defaultValue); // @TODO - fix any

    const handleClick = (event: MouseEvent<HTMLElement>) => {
      setPendingValue(selectedValues);
      setAnchorEl(event.currentTarget);
    };

    const handleClose = (
      event: ChangeEvent,
      reason: AutocompleteCloseReason
    ) => {
      if (reason === 'toggleInput') {
        return;
      }
      setAnchorEl(null);
      return;
    };

    const handleMultiSelectClose = (
      event: ChangeEvent,
      reason: AutocompleteCloseReason
    ) => {
      if (reason === 'toggleInput') {
        return;
      }
      handleSelection(pendingValue);
    };

    const handleSelection = newValue => {
      onSelect(newValue);
      if (anchorEl) {
        anchorEl.focus();
      }
      setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'custom-selector' : undefined;

    const sortOptions = useCallback(
      options => {
        return options.sort((a, b) => {
          // Display the selected labels first.
          let ai = selectedValues.indexOf(a);
          ai = ai === -1 ? selectedValues.length + values.indexOf(a) : ai;
          let bi = selectedValues.indexOf(b);
          bi = bi === -1 ? selectedValues.length + values.indexOf(b) : bi;
          return ai - bi;
        });
      },
      [selectedValues, values]
    );

    const sortedOptions = useMemo(() => {
      return multiple ? sortOptions(values) : values;
    }, [values, sortOptions, multiple]);

    return (
      <>
        <Button
          className={buttonProps.className}
          disableRipple
          variant="contained"
          color={buttonProps.color}
          onClick={handleClick}
          startIcon={buttonProps.startIcon}
          testId={testId}
        >
          {buttonProps.label}
        </Button>
        <Popper
          id={id}
          open={open}
          anchorEl={anchorEl}
          placement="bottom-start"
          className={clsx(classes.popper, popperClassName)}
        >
          <div className={classes.header}>{optionsLabel}</div>
          <Autocomplete
            open
            onClose={multiple ? handleMultiSelectClose : handleClose}
            multiple={multiple}
            classes={{
              paper: classes.paper,
              option: classes.option,
              popperDisablePortal: classes.popperDisablePortal,
            }}
            value={pendingValue}
            onChange={(event, newValue: any) => {
              if (multiple) {
                setPendingValue(newValue);
              } else {
                handleSelection(newValue);
              }
            }}
            disableCloseOnSelect
            disablePortal
            renderTags={() => null}
            noOptionsText="No records"
            renderOption={autoCompleteProps.renderOption}
            options={sortedOptions}
            getOptionLabel={autoCompleteProps.getOptionLabel}
            getOptionSelected={autoCompleteProps.getOptionSelected}
            renderInput={params => (
              <InputBase
                ref={params.InputProps.ref}
                inputProps={params.inputProps}
                autoFocus
                className={classes.inputBase}
              />
            )}
          />
        </Popper>
      </>
    );
  }
);
