import React, { useState, useEffect } from 'react';
import {
  Grid,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { SelectField, Paper } from '@roc/ui';
import { useLoanStore } from '@roc/feature-loans-shared';
import { useDrawsStore } from '../../hooks';
import { observer } from 'mobx-react';

const useStyles = makeStyles(() => ({
  container: {
    padding: 32,
  },
  paper: {
    padding: 24,
  },
  dropDownSection: {
    marginTop: 8,
    display: 'flex',
    flexDirection: 'column',
  },
  selectContainer: {
    marginTop: 8,
  },
}));

interface PropertyValuesProps {
}

export const PropertyValues = observer((props: PropertyValuesProps) => {
  const {
    loanStore: { loanDetails },
  } = useLoanStore();
  const { drawRequestStore } = useDrawsStore();
  const classes = useStyles();
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    if (loanDetails?.properties?.length) {
      setOptions(loanDetails.properties.map(property => {
        return { label: property.address, value: property }
      }));
    }
  }, [loanDetails])

  useEffect(() => {
    if (selectedOption) {
      drawRequestStore.property = selectedOption;
    }
  }, [selectedOption]);

  const onePropertyLoan = () => {
    drawRequestStore.property = options[0].value;
    return null;
  }
  return (
    <>
      {
        options.length === 1 ? onePropertyLoan() : options.length === 0 ? null : (
          <Grid item container xs={12} md={12} spacing={2}>
            <Grid item xs={12} md={6}>
              <Paper className={classes.paper}>
                <Grid container>
                  <Grid item xs={12} md={12}>
                    <Typography variant="h6">PROPERTY</Typography>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Grid container direction="column">
                      <Grid item>
                        <Typography variant="caption" color="textSecondary">
                          The property you select will get the Draw Request started.
                        </Typography>
                      </Grid>
                      <Grid item className={classes.dropDownSection}>
                        <p>Select A Property for the Draw</p>
                        <div className={classes.selectContainer}>
                          <SelectField
                            label="Property"
                            name="property"
                            value={selectedOption || ''}
                            options={options}
                            onChange={value => setSelectedOption(value)}
                            variant="outlined"
                            fullWidth
                            shrink
                            testId="useCode"
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        )
      }
    </>
  );
});

export default PropertyValues;