import { Box, Grid, InputAdornment, Typography } from "@material-ui/core";
import { Mail } from "@material-ui/icons";
import { VerifyEmailProps } from "@roc/feature-types";
import { Button, TextField } from "@roc/ui";
import { useState } from "react";
import OtpInput from 'react-otp-input';

export const GetCode = ({ onSubmit, fullWidthButton = true }: VerifyEmailProps) => {
  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
    >
      <Grid item xs={12}>
        <Typography variant="body1" align="center" gutterBottom>
          To get a verification code, first confirm the email address that you have in your file
        </Typography>
        <Typography variant="h6" align="center" gutterBottom>
          a****.xyz.com
        </Typography>
      </Grid>
      <Grid item xs={12} style={{
        textAlign: 'center'
      }}>
        <Box textAlign={'center'} my={2}>
          <TextField
            testId="email"
            fullWidth
            variant="outlined"
            label="Email"
            type="email"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Mail />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Grid>
      <Grid item xs={12} style={{
        textAlign: 'center'
      }}>
        <Button
          fullWidth={fullWidthButton}
          variant="contained"
          color="primary"
          onClick={onSubmit}
          testId="getCode"
        >
          <span>Get Code</span>
        </Button>
      </Grid>
    </Grid>
  );
};

export const VerifyEmail = ({ onSubmit, fullWidthButton = true }: VerifyEmailProps) => {
  const [otp, setOtp] = useState('');

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
    >
      <Grid item xs={12}>
        <Typography variant="body1" align="center" gutterBottom>
          We have sent a verification code to <strong>a****.xyz.com</strong>
        </Typography>
        <Typography variant="body1" align="center">
          Enter the code below to confirm your account.
        </Typography>
      </Grid>
      <Grid item xs={12} style={{
        textAlign: 'center'
      }}>
        <Box textAlign={'center'} my={2}>
          <OtpInput
            value={otp}
            onChange={setOtp}
            numInputs={4}
            renderSeparator={<Typography component="span" style={{ color: '#bbb' }}>&#8226;</Typography>}
            containerStyle={{
              justifyContent: 'center'
            }}
            inputStyle={{
              width: 48,
              padding: 4,
              fontSize: 32,
              margin: '0 4px'
            }}
            renderInput={(props) => <input {...props} />}
            shouldAutoFocus
          />
        </Box>
      </Grid>
      <Grid item xs={12} style={{
        textAlign: 'center'
      }}>
        <Button
          fullWidth={fullWidthButton}
          variant="text"
          color="primary"
          onClick={() => { }}
          testId="sendCodeAgain"
        >
          <span>Didn't receive a code? Send again</span>
        </Button>
      </Grid>
      <Grid item xs={12} style={{
        textAlign: 'center'
      }}>
        <Button
          fullWidth={fullWidthButton}
          variant="contained"
          color="primary"
          onClick={onSubmit}
          testId="verify"
        >
          <span>Verify</span>
        </Button>
      </Grid>
    </Grid>
  );
}