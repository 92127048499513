import {
  Box,
  Divider,
  Grid,
  InputAdornment,
  InputBase,

  ListItem,
  ListItemText,
  Paper,
  Typography,
} from '@material-ui/core';
import { lighten } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { Search, UndoRounded } from '@material-ui/icons';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import { useStore } from '@roc/client-portal-shared/hooks';
import { debounce, sanitizeTestId } from '@roc/client-portal-shared/utils';
import { Button, TestDataAttribute, TextField } from '@roc/ui';
import { observer } from 'mobx-react';
import { useEffect, useMemo, useState } from 'react';
import { DscrCalculatorBaseStore } from '../stores/dscrCalculatorBaseStore';
import { AutocompleteField } from './autocompleteField';

const useStyles = makeStyles(theme => ({
  paper: {
    border: '1px solid',
    borderColor: theme.palette.grey['200'],
  },
  divider: {
    listStyle: 'none',
  },
  autocompletePaper: {
    marginLeft: 0,
    marginRight: 0,
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    fontSize: 13,
  },
  autocompleteOption: {
    minHeight: 'auto',
    alignItems: 'flex-start',
    padding: 8,
    '&[data-focus="true"]': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  calculatedValuesBorder: {
    border: '1px solid #BEBEBE',
    borderRadius: '15px',
    padding: 16,
  },
}));

export const PaperList = ({ children }) => {
  const classes = useStyles();
  return <Paper className={classes.paper}>{children}</Paper>;
};

export const PaperListTitle = ({ text, last = false }) => {
  const classes = useStyles();
  return (
    <>
      <ListItem>
        <ListItemText>
          <Typography variant="h6">{text}</Typography>
        </ListItemText>
      </ListItem>
    </>
  );
};

export const PaperListItem = ({ label, value, last = false }) => {
  const classes = useStyles();
  return (
    <>
      <Divider variant="middle" component="li" className={classes.divider} />
      <ListItem>
        <ListItemText>
          <Box display="flex">
            <Box flexGrow={1}>{label}</Box>
            <TestDataAttribute id={sanitizeTestId(label)}>
              <Typography variant="body1">{value}</Typography>
            </TestDataAttribute>
          </Box>
        </ListItemText>
      </ListItem>
    </>
  );
};

export const CalculatorLayout = ({ left, right }) => {
  const classes = useStyles();
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Grid container spacing={2}>
          {left}
        </Grid>
      </Grid>
      <Grid item xs={12} md={6}>
        <Box className={classes.calculatedValuesBorder}>
          <Grid container spacing={2}>
            {right}
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};

export const CalculateButton = ({ onClick }) => {
  return (
    <Grid item xs={12}>
      <Button
        testId="calculate"
        onClick={onClick}
        fullWidth
        color="primary"
        variant="contained"
        size="large"
      >
        CALCULATE
      </Button>
    </Grid>
  );
};

export const SmallCalculateButton = ({ onClick }) => {
  return (
    <Grid item xs={2}>
      <Button
        testId="calculate"
        onClick={onClick}
        fullWidth
        color="primary"
        variant="contained"
        size="large"
      >
        CALCULATE
      </Button>
    </Grid>
  );
};

export const LoanAutocomplete = observer(
  ({ store }: { store: DscrCalculatorBaseStore }) => {
    const fetchOptions = useMemo(
      () => debounce(searchText => store.fetchOptions(searchText), 500),
      []
    );

    return (
      <AutocompleteField
        value={store.loanSearchText}
        options={store.loanSearchOptions}
        onChange={text => {
          store.loanSearchText = text;
          fetchOptions(store.loanSearchText);
        }}
        onSelect={opt => {
          store.loanSearchText = opt.label;
          if (opt.value && typeof opt.value === 'string' && opt.value.includes('DRAFT')) {
            store.fetchLoanValues(opt.value.replace('DRAFT', ''), true);
          }
          else {
            store.fetchLoanValues(opt.value, false);
          };
        }}
      />
    );
  }
);
