import { FormStore } from '@roc/feature-app-core';
import { GlobalStore } from '@roc/feature-app-core';
import { GENERIC_ERROR_MESSAGE } from '@roc/feature-utils';
import { flow, makeObservable } from 'mobx';
import { OneToolService } from '../../services/oneToolService';
import { QuoteTypeStore } from '../quoteTypeStore';
import { getCompletePropertyAddress } from '@roc/ui/utils';

const borrowerEmailForm = {
  fields: {
    yourEmail: {
      value: '',
      error: null,
      rule: '',
    },
    borrowerEmail: {
      value: '',
      error: null,
      rule: 'required',
    },
    emailSubject: {
      value: '',
      error: null,
      rule: 'required',
    },
    emailContent: {
      value: '',
      error: null,
      rule: 'required',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

const defaultBody = (borrowerName, address): string =>
  `Hi ${borrowerName}, \n` +
  `Attached is a conditional quote for: ${address}.\n` +
  `\n` +
  `Please review at your earliest convenience.\n` +
  `\n` +
  `Thank you`;

export class BorrowerEmailFormStore extends FormStore {
  private globalStore: GlobalStore;
  private quoteTypeStore: QuoteTypeStore;
  private oneToolService: OneToolService;

  constructor(globalStore, quoteTypeStore: QuoteTypeStore) {
    super(borrowerEmailForm, globalStore);
    this.globalStore = globalStore;
    this.quoteTypeStore = quoteTypeStore;
    this.oneToolService = new OneToolService();
    makeObservable(this, {
      sendEmail: flow,
    });
  }

  loadDefaultValues() {
    const fromEmail = this.globalStore.portalConfiguration.portalConfiguration
      .generalConfiguration.fromEmail;

    const quoteData = this.quoteTypeStore.getQuoteData();

    const address = quoteData.properties
      .map((property: any) => property.address)
      .join(', ');

    const fullAddress = quoteData.properties
      .map((property: any) => getCompletePropertyAddress(property))
      .join(', ');

    const borrower = quoteData.borrowers.find(
      borrower => !!borrower.emailAddress
    );

    this.reset();
    this.onFieldChange('yourEmail', fromEmail);
    this.onFieldChange('emailSubject', 'Conditional Quote for: ' + address);
    if (borrower) {
      this.onFieldChange('borrowerEmail', borrower.emailAddress);
      this.onFieldChange(
        'emailContent',
        defaultBody(borrower?.firstName, fullAddress)
      );
    }
  }

  *sendEmail(file) {
    try {
      if (this.runFormValidationWithMessage()) {
        const portalId = this.globalStore.portalConfiguration.id;
        yield this.oneToolService.sendQuoteToBorrower(
          file,
          this.getFormValues(),
          portalId
        );
      }
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Email sent successfully.',
      });
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE,
      });
    }
  }
}
