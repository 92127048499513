import { useEffect, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { Paper } from '@roc/ui';
import { observer } from 'mobx-react';
import { useParams } from 'react-router';
import { Layout, StepperComponent } from '@roc/client-portal-shared/components';

import { useStore } from '../../hooks';
import { InformationStep } from './informationStep';
import PaymentStep from './paymentStep';
import AcknowledmentStep from './acknowledmentStep'
import { decodeData } from './cdaPaymentsUtils';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(3, 0, 4),
      borderRadius: theme.spacing(2),
      boxShadow:
        'rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px',
    },
    stepper: {
      maxWidth: 900,
      margin: 'auto',
      [theme.breakpoints.down('xs')]: {
        paddingLeft: theme.spacing(.5),
        paddingRight: theme.spacing(.5),
      },
    },
    stepContentWrapper: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      [theme.breakpoints.down('xs')]: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
      },
    },
  })
);

export type EncodedParams = {
  encodedParams: string;
};

export const CdaPayments = observer(() => {
  const [activeStep, setActiveStep] = useState(0);
  const [disableStepperNavigation, setDisableStepperNavigation] = useState(
    false
  );
  const [entityData, setEntityData] = useState(null);
  const { encodedParams } = useParams<EncodedParams>();
  const { cdaPaymentsStore } = useStore();
  const { docusignStatus } = cdaPaymentsStore;
  const { entityId, tamariskFlowType } = entityData || {};
  const classes = useStyles();

  const loanTermsSteps = [
    {
      title: 'CDA Information',
      icon: null,
      onBack: () => { },
      onNext: () => { },
      allowBack: false,
      allowNext: true,
    },
    {
      title: 'Acknowledgment',
      icon: null,
      onBack: () => { },
      onNext: () => { },
      allowBack: false,
      allowNext: true,
    },
    {
      title: 'Payment',
      icon: null,
      onBack: () => { },
      onNext: () => { },
      allowBack: false,
      allowNext: true,
    },
  ];

  const handleBackStep = () => setActiveStep(activeStep - 1);

  const handleNextStep = () => setActiveStep(activeStep + 1);

  const handleStep = (index: number) => setActiveStep(index);

  useEffect(() => {
    setEntityData(decodeData(encodedParams));
    return () => {
      cdaPaymentsStore.reset();
      cdaPaymentsStore.cdaDocumentStore.reset();
    };
  }, []);

  useEffect(() => {
    if (entityId) {
      cdaPaymentsStore.fetchDocusignAndPaymentStatus(entityId, 'CDA_Loan');
    }
  }, [entityId]);

  useEffect(() => {
    setDisableStepperNavigation(!docusignStatus);
  }, [docusignStatus]);

  if (entityId) {
    return (
      <Layout>
        <Box my={3}>
          <Typography variant="h3" gutterBottom>
            {'CDA Payment'}
          </Typography>
        </Box>
        <Paper className={classes.paper}>
          <StepperComponent
            activeStep={activeStep}
            disableNavigation={disableStepperNavigation}
            handleStep={handleStep}
            steps={loanTermsSteps}
            className={classes.stepper}
          />
          <Box mt={3} className={classes.stepContentWrapper}>
            {activeStep === 0 && (
              <InformationStep
                handleNextStep={handleNextStep}
                entityId={entityId}
                tamariskFlowType={tamariskFlowType}
              />
            )}
            {activeStep === 1 && (
              <AcknowledmentStep
                handleBackStep={handleBackStep}
                handleNextStep={handleNextStep}
                entityId={entityId}
                tamariskFlowType={tamariskFlowType}
              />
            )}
            {activeStep === 2 && (
              <PaymentStep
                handleDisableStepperNavigation={setDisableStepperNavigation}
                entityData={entityData}
              />
            )}
          </Box>
        </Paper>
      </Layout>
    );
  } else {
    return null;
  }
});
