import { DialogState } from '@roc/ui';
import { action, makeObservable, observable } from 'mobx';
import { FormStore } from '@roc/feature-app-core';
import { MailingListSubscription } from '../types/companyProfileTypes';

const form = {
  fields: {
    email: {
      value: '',
      error: null,
      rule: 'required|email',
      message: 'Insert a valid e-mail address.',
    },
    loanStatus: {
      value: false,
      error: null,
      rule: '',
    },
    dailyReport: {
      value: false,
      error: null,
      rule: '',
    },
    extensions: {
      value: false,
      error: null,
      rule: '',
    },
    participationAgreement: {
      value: false,
      error: null,
      rule: '',
    },
    wireConfirm: {
      value: false,
      error: null,
      rule: '',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export class EditMailingListsDialogStore extends FormStore {
  mode: DialogState;
  open = false;

  constructor(globalStore) {
    super(form, globalStore);
    makeObservable(this, {
      mode: observable,
      open: observable,
      showAddMail: action,
      showEditMail: action,
      openDialog: action,
      closeDialog: action,
    });
  }

  showAddMail = () => {
    this.openDialog(DialogState.ADD);
  };

  showEditMail = (values: MailingListSubscription) => {
    this.openDialog(DialogState.EDIT);
    this.loadForm(values);
  };

  openDialog = (mode: DialogState) => {
    this.reset();
    this.mode = mode;
    this.open = true;
  };

  closeDialog = () => {
    this.open = false;
  };
}
