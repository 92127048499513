import { Grid } from '@material-ui/core';
import { observer } from 'mobx-react';
import { TitleDivider } from '../../../components/titleDivider';
import { PropertyFormStore } from '../../../stores/properties/propertyFormStore';
import { QuoteTypeStore } from '../../../stores/quoteTypeStore';
import { OneToolCard, OneToolCardBorder } from '../../common/oneToolCard';
import { PropertyInformationAccordion } from '../../propertyInformation/components/propertyInformationAccordion';
import { PreflightPropertyForm } from '../forms/preflightPropertyForm';
import { PreflightActionButtons } from './preflightActionButtons';
import { PropertySupplementalInformationTable } from './propertySupplementalInformationTable';
import { PrefligthCard } from './preflightCard';
import { PreflightStep } from '../../../utils/constants';

interface PrefligthPropertyCardProps {
  store: QuoteTypeStore;
  propertyFormStore: PropertyFormStore;
  propertyIndex: number;
}

export const PrefligthPropertyCard = observer(
  ({ store, propertyFormStore, propertyIndex }: PrefligthPropertyCardProps) => {
    const step = PreflightStep.PROPERTY;
    return (
      <PrefligthCard
        store={store}
        step={step}
        itemIndex={propertyIndex}
        onEdit={() => store.preflightStore.goToStep(step, propertyIndex)}
        onSave={() => {
          store.preflightStore.goToNextStep();
        }}
        FormComponent={() => (
          <PreflightPropertyForm
            store={store}
            propertyFormStore={propertyFormStore}
          />
        )}
        AccordionComponent={props => (
          <PropertyInformationAccordion
            {...props}
            store={propertyFormStore}
            showActions={!propertyFormStore.isEditing}
            showRemoveButton={false}
            bottom={
              <>
                <Grid item xs={12}>
                  <TitleDivider title="Supplemental Information" />
                </Grid>
                <Grid item xs={12}>
                  <PropertySupplementalInformationTable
                    store={propertyFormStore}
                  />
                </Grid>
              </>
            }
          />
        )}
      />
    );
  }
);
