import { observer } from 'mobx-react';
import { useProposalsStore } from '../hooks/useProposalsStore';
import { Grid, Typography } from '@material-ui/core';
import {
  createTextField,
} from '@roc/ui/componentBuilder';

const AppealTab = observer(() => {
  const { proposalsStore } = useProposalsStore();
  const { detailsStore } = proposalsStore;
  const store = detailsStore.appealForm;
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h4">What is the reason for the appeal? </Typography>
        <br />
      </Grid>
      <Grid item xs={6}>
        {createTextField({
          store,
          testId: 'appealDescription',
          fieldName: 'appealDescription',

          label: 'Please provide additional color around borrower, property, project, etc. to support your appeal.',
          multiline: true,
          inputProps: { maxLength: 500, rows: 4 }
        })}
      </Grid>
    </Grid>
  );
});
export default AppealTab;