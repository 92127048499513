import {
  Stepper,
  Step,
  StepLabel,
  StepContent,


} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import React from 'react';
import { Button } from '@roc/ui';
import { FieldContainer } from '@roc/ui/formComponents';
import PropertyStore from '../../stores/fixFlip/propertyStore';
import {
  OtherDetails,
  PropertyInformation,
  PurchaseInformation,
  Refinance_OtherDetails,
  Refinance_PurchaseInformation,
  Refinance_RenovationDetails,
  RenovationDetails,
} from './propertyForm';
import { PropertyModalState } from './propertyModal';

function getSteps() {
  return [
    'Property information',
    'Purchase information',
    'Renovation details',
    'Other details',
  ];
}

function getStepContent(step: number, isPurchase: boolean, store) {
  switch (step) {
    case 0:
      return <PropertyInformation store={store} />;
    case 1:
      return isPurchase ? (
        <PurchaseInformation store={store} />
      ) : (
        <Refinance_PurchaseInformation store={store} />
      );
    case 2:
      return isPurchase ? (
        <RenovationDetails store={store} />
      ) : (
        <Refinance_RenovationDetails store={store} />
      );
    case 3:
      return isPurchase ? <OtherDetails store={store} /> : <Refinance_OtherDetails store={store} />;
    default:
      return 'Unknown step';
  }
}

function isFormValid(step: number, isPurchase: boolean, store: PropertyStore) {
  switch (step) {
    case 0:
      return store.propertyInformationStore.form.meta.isValid;
    case 1:
      return isPurchase
        ? store.purchaseInformationStore.form.meta.isValid
        : store.refinance_purchaseInformationStore.form.meta.isValid;
    case 2:
      return isPurchase
        ? store.renovationDetailsStore.form.meta.isValid
        : store.refinance_renovationDetailsStore.form.meta.isValid;
    case 3:
      return isPurchase
        ? store.otherDetailsStore.form.meta.isValid
        : store.refinance_otherDetailsStore.form.meta.isValid;
    default:
      return 'Unknown step';
  }
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    button: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    actionsContainer: {
      marginBottom: theme.spacing(2),
    },
    resetContainer: {
      padding: theme.spacing(3),
    },
  })
);

export const PropertySteps = observer(
  ({ modalState, handleAdd, handleEdit, store }) => {
    const { propertyStore } = store;
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps();

    const { propertyInformationStore } = propertyStore;
    const isPurchase =
      propertyInformationStore.form.fields.propertyOwnership.value ===
      'Purchase';

    const handleNext = () => {
      if (activeStep === steps.length - 1) {
        modalState == PropertyModalState.ADD ? handleAdd() : handleEdit();
        return;
      }
      setActiveStep(prevActiveStep => prevActiveStep + 1);
    };

    const handleBack = () => {
      setActiveStep(prevActiveStep => prevActiveStep - 1);
    };

    return (
      <div className={classes.root}>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
              <StepContent TransitionProps={{ unmountOnExit: false }}>
                {getStepContent(index, isPurchase, store)}
                <div className={classes.actionsContainer}>
                  <FieldContainer>
                    <>
                      <Button
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        className={classes.button}
                        testId="back"
                      >
                        Back
                      </Button>
                      <Button
                        variant="contained"
                        disabled={
                          !isFormValid(index, isPurchase, propertyStore)
                        }
                        color="primary"
                        onClick={handleNext}
                        className={classes.button}
                        testId="next"
                      >
                        {activeStep === steps.length - 1
                          ? modalState == PropertyModalState.ADD
                            ? 'Finish & Add Property'
                            : 'Finish'
                          : 'Next'}
                      </Button>
                    </>
                  </FieldContainer>
                </div>
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </div>
    );
  }
);
