import {
  Typography,
  Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import {
  Button,
} from '@roc/ui';

const useStyles = makeStyles(() => ({
  commentContainer: {
    border: '1px #e0e0e0 solid',
  }
}));

interface CommentDialogProps {
  onClose: () => void;
  subTitle: string;
  comment: string;
}

const CommentDialog = ({ onClose, subTitle, comment }: CommentDialogProps) => {
  const classes = useStyles();
  return (
    <Grid container justifyContent="center" spacing={3}>
      <Grid item xs={12}>
        <Typography variant="body2">
          {subTitle}
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.commentContainer}>
        <Typography variant="body2">
          {comment}
        </Typography>
      </Grid>
      <Grid item container justifyContent="center">
        <Grid item xs={4}>
          <Button
            variant="contained"
            size="small"
            onClick={onClose}
            color="primary"
            testId="Comment_Cancel"
            fullWidth={true}
          >
            OK
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
};
export default CommentDialog;