import { Grid, Menu, ListItemText, ListItemIcon, Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowDropDown, Clear, PlaylistAddCheck } from '@material-ui/icons';
import { Button, StandardDialog, Toolbar, ConfirmationMenuItem } from '@roc/ui';
import { observer } from 'mobx-react';
import { useRef, useState } from 'react';
import { BulkChangeStatusModal } from './bulkChangeStatusModal';
import { ProtectiveAdvancesSearchBar } from '../protectiveAdvancesSearchBar';
import { CounterPartyExpensesTab } from '../../types/types';
import { useBaseStore } from '@roc/feature-app-core';

const useStyles = makeStyles(theme => ({
  content: {
    fontWeight: 'bold',
    fontSize: '15px'
  },
  label: {
    fontSize: '13px',
  },
  labelBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  labelBoxCount: {
    marginRight: '25px',
    marginLeft: '25px',
  },
  labelBoxAmount: {
    marginRight: '50px',
    marginLeft: '25px',
  },
  dataPoint: {
    marginTop: '5px',
    textAlign: 'center',
  },
  containerBox: {
    display: 'flex',
  },
}));


export const ReceivablesToolbar = observer(({ receivablesDashboardStore }) => {
  const classes = useStyles();
  const anchorRef = useRef(null);
  const [showBulkActionsMenu, setShowBulkActionsMenu] = useState(false);
  const [showChangeStatusModal, setShowChangeStatusModal] = useState(false);
  const [statusSelected, setStatusSelected] = useState('');
  const { tab, counterPartyExpenseGridStore } = receivablesDashboardStore;
  const { globalStore } = useBaseStore();

  const closeMenu = () => {
    setShowBulkActionsMenu(false);
  };

  const closeChangeStatusModal = () => {
    setStatusSelected('');
    setShowChangeStatusModal(false);
  };

  const openChangeStatusModal = () => {
    closeMenu();
    setShowChangeStatusModal(true);
  }

  const saveStatus = () => {
    closeChangeStatusModal();
  }

  const performActions = async (action) => {
    if (receivablesDashboardStore.recordsIds && receivablesDashboardStore.recordsIds.length > 0) {
      await receivablesDashboardStore.performActions(action);
      counterPartyExpenseGridStore.resetAndFetchGridData();
    } else {
      globalStore.notificationStore.showErrorNotification({
        message: 'Please select an item from the table.',
      });
    }
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Toolbar
          left={
            <ProtectiveAdvancesSearchBar counterPartyExpenseStore={receivablesDashboardStore} label={"Investor"} />
          }>
          {tab === CounterPartyExpensesTab.PENDING_RECEIVABLE &&
            <Box className={classes.containerBox} >
              <Box justifyContent="center" className={`${classes.labelBox} ${classes.labelBoxCount}`}>
                <Typography variant="body2" component="p" className={classes.content}>
                  {receivablesDashboardStore.recordsIds.length}
                </Typography>
                <Typography variant="caption" color="textSecondary" component="p" className={classes.label}>
                  {`Count`}
                </Typography>
              </Box>
              <Box justifyContent="center" className={`${classes.labelBox} ${classes.labelBoxAmount}`}>
                <Typography variant="body2" component="p" className={classes.content}>
                  {`${'$'}${receivablesDashboardStore.totalAmount}`}
                </Typography>
                <Typography variant="caption" color="textSecondary" component="p" className={classes.label}>
                  {`Amount`}
                </Typography>
              </Box>
              <Box style={{ textAlign: 'center' }}>
                <div ref={anchorRef} style={{ display: 'inline-block' }}>
                  <Button
                    id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={() => { setShowBulkActionsMenu(true) }}
                    testId="open_menu"
                    variant="contained"
                    size="small"
                    color="primary"
                    disabled={!receivablesDashboardStore.entity}
                  >
                    Bulk Actions <ArrowDropDown style={{ marginRight: '-6px' }} />
                  </Button>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorRef.current}
                    open={showBulkActionsMenu}
                    onClose={closeMenu}
                    keepMounted={true}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    getContentAnchorEl={null}
                  >
                    <ConfirmationMenuItem
                      testId={`create-bill`}
                      onClick={async () => {
                        performActions('Billed');
                      }}
                      confirmDialogProps={{
                        body: `Are you sure you want to create bill?`,
                      }}
                    >
                      <ListItemIcon>
                        <PlaylistAddCheck fontSize="small" />
                      </ListItemIcon>
                      <ListItemText onClick={closeMenu}>Create Bill</ListItemText>
                    </ConfirmationMenuItem>
                    <ConfirmationMenuItem
                      testId={`create-bill`}
                      onClick={async () => {
                        performActions('Rejected');
                      }}
                      confirmDialogProps={{
                        body: `Are you sure you want to reject the selected invoices?`,
                      }}
                    >
                      <ListItemIcon>
                        <Clear fontSize="small" />
                      </ListItemIcon>
                      <ListItemText onClick={closeMenu}>Reject</ListItemText>
                    </ConfirmationMenuItem>
                  </Menu>
                </div>
              </Box>
            </Box>}
          {tab === CounterPartyExpensesTab.ALL_RECEIVABLE &&
            <Grid container spacing={2}>
              <Grid item>
                <div ref={anchorRef} style={{ display: 'inline-block' }}>
                  <Button
                    id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={() => receivablesDashboardStore.exportServicingDashboardData()}
                    testId="open_menu"
                    variant="contained"
                    size="small"
                    color="primary"
                  >
                    Export as Excel
                  </Button>
                </div>
              </Grid>
            </Grid>}

        </Toolbar>
      </Grid>
      <StandardDialog
        open={showChangeStatusModal}
        title={"Bulk Change Status"}
        maxWidth="sm"
        handleClose={closeChangeStatusModal}
        dialogContent={
          <BulkChangeStatusModal
            options={[
              { label: 'Billed', value: 'Billed' },
              { label: 'Rejected', value: 'Rejected' },
            ]}
            statusSelected={statusSelected}
            handleSelect={setStatusSelected} />
        }
        dialogActions={(
          <>
            <Button
              style={{ marginRight: '8px' }}
              onClick={closeChangeStatusModal}
              color="primary"
              variant="outlined"
              testId="cancel"
            >
              Cancel
            </Button>
            <Button
              onClick={saveStatus}
              color="primary"
              variant="contained"
              testId="confirm"
            >
              Change Status
            </Button>
          </>
        )}
      />
    </Grid>
  );
});
