import { Grid, SvgIconProps, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import { FORM_MAX_WIDTH } from '../../utils/constants';
import { RoundedBackgroundIcon } from '../../components/roundedBackgroundIcon';
import { ItemDisplay } from '../../components/itemDisplay';

export const FormHeader = observer(({ icon, title }) => {
  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      style={{ marginBottom: '16px' }}
    >
      <Grid item xs={6}>
        <Grid
          container
          spacing={2}
          justifyContent="flex-start"
          alignItems="center"
        >
          <Grid item alignItems="center" style={{ height: '100%' }}>
            <RoundedBackgroundIcon Icon={icon} iconColor="#6D6D6D" />
          </Grid>
          <Grid item>
            <ItemDisplay value={title} alignItems="flex-start" />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
});
