import { IconButton } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import {
  DataGrid as Grid, GridColumnProps,
  SelectFilterComponent,
  SelectFloatingFilterComponent,
} from '@roc/ui';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useLenderBankInformationStore } from '../../../hooks/useLenderBankInformationStore';



const columns: GridColumnProps[] = [
  {
    field: 'lender',
    headerName: 'Pending on Lender',
    minWidth: 100,
    cellRenderer: 'loanIdCellRenderer',
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'creationDate',
    headerName: 'Requested on',
    minWidth: 120,
  },
  {
    field: 'email',
    headerName: 'Docusign sent to',
    minWidth: 120,
  },
];

export const LenderBankInfoPendingDocusign = observer(() => {
  const [lenderId, setLenderId] = useState();
  const [lender, setLender] = useState();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);

  const { lenderBankInformationStore } = useLenderBankInformationStore();

  const frameworkComponents = {
    selectFilter: SelectFilterComponent,
    selectFloatingFilter: SelectFloatingFilterComponent,
    actionsCellRenderer: params => {
      return (
        <IconButton onClick={e => openMenu(e, params.node.data)}>
          <MoreVert color="primary" />
        </IconButton>
      );
    },
  };

  const openMenu = (event: React.MouseEvent<HTMLElement>, record) => {
    const { lenderId, lender } = record;
    setLenderId(lenderId);
    setLender(lender);
    setAnchorEl(event.currentTarget);
  };


  const onFilterChanged = params => {
    const filterModel = params.api.getFilterModel();
    lenderBankInformationStore.pendingDocusignLenderBankInfoGridStore.setFilterModel(filterModel);
  };


  const onSortChanged = params => {
    const sortModel = params.api.getSortModel();
    lenderBankInformationStore.pendingDocusignLenderBankInfoGridStore.setSortModel(sortModel);
  };


  useEffect(() => {
    lenderBankInformationStore.pendingDocusignLenderBankInfoGridStore.resetAndFetchGridData();
  }, []);

  return (
    <Grid
      columns={columns}
      rows={lenderBankInformationStore.pendingDocusignRequests}
      frameworkComponents={frameworkComponents}
      onSortChanged={onSortChanged}
      onFilterChanged={onFilterChanged}
      isLoading={lenderBankInformationStore.pendingDocusignLenderBankInfoGridStore.isLoading}
      showFilters={lenderBankInformationStore.pendingDocusignLenderBankInfoGridStore.showFilters}
      sortModel={lenderBankInformationStore.pendingDocusignLenderBankInfoGridStore.sortModel}
      filterModel={lenderBankInformationStore.pendingDocusignLenderBankInfoGridStore.filterModel}
      paginationData={lenderBankInformationStore.pendingDocusignLenderBankInfoGridStore.gridData.meta}
      setPageNumber={lenderBankInformationStore.pendingDocusignLenderBankInfoGridStore.setPageNumber}
    />
  );
});
