import {
  Box,

  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  MenuProps,
  Select,

  ListSubheader,
  Tooltip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { Help } from '@material-ui/icons';
import React from 'react';
import { FieldLabel } from '../fieldLabel/fieldLabel';
import { TestDataAttribute } from '../testDataAttribute';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    select: {
      '& .description-text': {
        display: 'none',
      },
    },
    placeholder: {
      display: 'none',
    },
    indentItem: {
      paddingLeft: '72px',
    },
  })
);

const groupByCategory = array =>
  array.reduce(
    (objectsByKeyValue, obj) => ({
      ...objectsByKeyValue,
      [obj['category']]: (objectsByKeyValue[obj['category']] || []).concat(obj),
    }),
    {}
  );

export interface SelectOption {
  label: string;
  value: any;
  description?: string;
  category?: string;
}

export interface SelectFieldProps {
  label?: string | React.ReactNode;
  name?: string;
  value?: string | boolean | number;
  subLabel?: string;
  onChange?: (value: any) => void;
  options?: Array<SelectOption>;
  disabled?: boolean;
  required?: boolean;
  multiple?: boolean;
  error?: boolean;
  errorText?: string;
  fullWidth?: boolean;
  margin?: any;
  variant?: any;
  shrink?: boolean;
  helperText?: string;
  standaloneLabel?: boolean;
  testId: string;
  placeholder?: string;
  displayCategory?: boolean;
  tooltip?: string;
  style?: React.CSSProperties;
  className?: string;
}

const menuProps: Partial<MenuProps> = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
  getContentAnchorEl: null,
};

const renderMenuItem = (option, className?: string) => {
  const { description, label, value } = option;

  return (
    <MenuItem key={value} value={value} className={className}>
      {!!description ? (
        <Box>
          <Box component="span" display="block">
            {label}
          </Box>
          <Box
            className="description-text"
            component="span"
            display="block"
            fontSize=".65rem"
            whiteSpace="normal"
          >
            {description}
          </Box>
        </Box>
      ) : (
        label
      )}
    </MenuItem>
  );
};

export const _SelectField: React.FC<SelectFieldProps> = ({
  label,
  name,
  value,
  onChange,
  options,
  multiple,
  error,
  errorText,
  disabled,
  required,
  fullWidth,
  margin,
  variant,
  shrink,
  placeholder,
  displayCategory,
  // tooltip,
  testId,
  style,
  className,
}) => {
  const classes = useStyles();

  return (
    <TestDataAttribute id={testId}>
      <FormControl
        fullWidth={fullWidth}
        margin={margin}
        variant={variant}
        disabled={disabled}
        error={error}
      >
        {label && <InputLabel>{required ? label + ' *' : label}</InputLabel>}
        <Select
          className={clsx(classes.select, className)}
          id={name}
          name={name}
          value={value}
          label={label && required ? label + ' *' : label}
          onChange={event => onChange && onChange(event.target.value)}
          multiple={multiple}
          displayEmpty
          defaultValue={'Please choose'}
          MenuProps={menuProps}
          style={style}
        >
          {placeholder && (
            <MenuItem value="" className={classes.placeholder}>
              {placeholder}
            </MenuItem>
          )}

          {displayCategory
            ? Object.keys(groupByCategory(options)).map(categoryKey => [
              <ListSubheader disableSticky={true}>
                {categoryKey}
              </ListSubheader>,
              options
                .filter(x => x.category === categoryKey)
                .map((option: SelectOption) =>
                  renderMenuItem(option, classes.indentItem)
                ),
            ])
            : options.map((option: SelectOption) => renderMenuItem(option))}
        </Select>
        <FormHelperText>{errorText}</FormHelperText>
      </FormControl>
    </TestDataAttribute>
  );
};

export const SelectField = (props: SelectFieldProps) => {
  const { standaloneLabel, subLabel, tooltip, ...muiComponentProps } = props;
  const classes = useStyles();
  return (
    <>
      {standaloneLabel && muiComponentProps.label ? (
        <FieldLabel tooltip={tooltip}>
          {muiComponentProps.label}
          {muiComponentProps.required && ' *'}
        </FieldLabel>
      ) : null}
      {subLabel && (
        <FieldLabel style={{ fontSize: '.8rem', color: '#aaa' }}>
          {subLabel}
        </FieldLabel>
      )}
      <_SelectField
        {...muiComponentProps}
        label={standaloneLabel ? undefined : muiComponentProps.label}
        required={muiComponentProps.required}
      />
    </>
  );
};
