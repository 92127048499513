import { observer } from 'mobx-react';
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemText,
  Grid
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Paper } from '@roc/ui';
import { useContactInformationStore } from '../hooks/useContactInformationStore';
import { useEffect } from 'react';


const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  noContactsMessage: {
    display: "flex", justifyContent: "center", marginTop: theme.spacing(8),
    marginBottom: theme.spacing(2)
  }
}));

const divider = <Divider variant="middle" component="li" />;


const listItem = (label, value, icon = null) => {

  return (
    <ListItem>
      <ListItemText>
        <Box display="flex">
          {icon && <Box ml={1}>{icon}</Box>}
          <Box flexGrow={1}>{label}</Box>
          <Box>{value}</Box>
        </Box>
      </ListItemText>
    </ListItem>
  );
};



export const ContactInformation = observer(() => {

  const { contactInformationStore } = useContactInformationStore();

  useEffect(() => {
    contactInformationStore.getAllLenderAdminUsers();
  }, [contactInformationStore])


  const classes = useStyles();

  return (
    <Grid container spacing={3}>
      {contactInformationStore.lenderAdminContacts.length > 0 ? contactInformationStore.lenderAdminContacts.map((adminUser, index) => (
        <Grid item xs={6} key={index}>
          <Paper title={`Contact #${index + 1}`} className={classes.paper}>
            <Box>
              <List>
                {listItem('Name', adminUser.contact.firstName)} {divider}
                {listItem('Last Name', adminUser.contact.lastName)} {divider}
                {listItem('Email', adminUser.contact.emailAddress)} {divider}
                {listItem('Phone Number', adminUser.contact.cellPhone)}
              </List>
            </Box>
          </Paper>
        </Grid>
      )) : <Grid item xs={12} className={classes.noContactsMessage}> <h4> No admin contact information found.</h4></Grid>}
    </Grid>
  );

});
