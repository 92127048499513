import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Layout } from '@roc/client-portal-shared/components';
import { LoanSubType } from '@roc/feature-utils';
import { Paper } from '@roc/ui';
import { LoanSubmissionStep } from 'libs/feature-loans/src/loanSubmission/utils/constants';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useBorrowerLoanSubmissionStore } from '../hooks/useBorrowerLoanSubmissionStore';
import { SinglePropertyPropertiesStore } from '../stores/singleProperty/singlePropertyPropertiesStore';
import { BorrowerEntityInformationSteps } from '../utils/constants';
import { BorrowerInformationStep } from './borrower/borrowerInformationStep';
import { BorrowerEntityInformationStep } from './borrowerEntity/borrowerEntityInformationStep';
import { LoanSummary } from './loanSummary/loanSummary';
import { SinglePropertyRentalPropertyInformation } from './properties/singlePropertyInformation';
import { VerticalStepsComponent } from './stepper/verticalStepsComponent';
import { SuccessPage } from './successPage';

const useStyles = makeStyles(theme => ({
  paper: {
    borderRadius: theme.spacing(2),
    boxShadow:
      'rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px',
  },
  title: {
    fontSize: '48px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '116.7%',
    padding: theme.spacing(2),
  },
}));

export const BorrowerSinglePropertyRentalLoanApplication = observer(
  ({ currentRoutes }) => {
    const classes = useStyles();
    const { borrowerLoanSubmissionStore } = useBorrowerLoanSubmissionStore();
    const { singlePropertyStore } = borrowerLoanSubmissionStore;
    const {
      borrowerInformationStore,
      borrowerEntityInformationStore,
      propertiesStore,
    } = singlePropertyStore;

    useEffect(() => {
      singlePropertyStore.resetForNewLoanApplication();
      singlePropertyStore.setLoanSubtype(LoanSubType.SINGLE_PROPERTY);
    }, []);

    const steps = [
      {
        label: 'Entity Information',
        icon: null,
        type: LoanSubmissionStep.ENTITY_INFORMATION,
        content: <BorrowerEntityInformationStep store={singlePropertyStore} />,
        onBack: () => handleBack(),
        onNext: () => handleNext(),
        allowBack:
          borrowerEntityInformationStore.borrowerEntityInformationActiveStep !=
          BorrowerEntityInformationSteps.ENTITY_INFORMATION,
        allowNext: true,
        subLabels: borrowerEntityInformationStore.borrowerEntitySubLabels,
        handleSubLabelClick: index =>
          borrowerEntityInformationStore.handleBorrowerEntitySubLabelClick(
            index
          ),
      },
      {
        label: 'Borrower Information',
        icon: null,
        type: LoanSubmissionStep.BORROWER_INFORMATION,
        content: <BorrowerInformationStep store={singlePropertyStore} rental />,
        onBack: () => handleBack(),
        onNext: () => handleNext(),
        allowBack: true,
        allowNext: true,
        subLabels: borrowerInformationStore.borrowerSubLabels,
        handleSubLabelClick: index =>
          borrowerInformationStore.handleBorrowerSubLabelClick(index),
      },
      {
        label: 'Property Information',
        icon: null,
        type: LoanSubmissionStep.PROPERTY_INFORMATION,
        content: (
          <SinglePropertyRentalPropertyInformation
            store={
              singlePropertyStore.propertiesStore as SinglePropertyPropertiesStore
            }
          />
        ),
        onBack: () => handleBack(),
        onNext: () => handleNext(),
        allowBack: true,
        allowNext: true,
        subLabels: singlePropertyStore.propertiesSubLabels,
      },
      {
        label: 'Summary',
        icon: null,
        type: LoanSubmissionStep.SUMMARY,
        content: <LoanSummary store={singlePropertyStore} rental />,
        onBack: () => handleBack(),
        onNext: () => handleNext(),
        allowBack: true,
        allowNext: true,
        nextButtonText: 'Submit',
      },
      {
        icon: null,
        type: LoanSubmissionStep.SUCCESS,
        content: (
          <SuccessPage
            store={singlePropertyStore}
            currentRoutes={currentRoutes}
          />
        ),
        onBack: () => handleBack(),
        onNext: () => handleNext(),
        allowBack: false,
        allowNext: false,
      },
    ];

    const handleNext = () => {
      singlePropertyStore.handleNext(
        steps[singlePropertyStore.activeStep].type
      );
    };

    const handleBack = () => {
      singlePropertyStore.handleBack(
        steps[singlePropertyStore.activeStep].type
      );
    };

    return (
      <Layout>
        <Typography variant="h3" className={classes.title}>
          Apply for Loan: Single Property Rental
        </Typography>

        {steps[singlePropertyStore.activeStep].type ==
        LoanSubmissionStep.SUCCESS ? (
          <SuccessPage
            store={singlePropertyStore}
            currentRoutes={currentRoutes}
          />
        ) : (
          <Paper className={classes.paper}>
            <VerticalStepsComponent
              activeStep={singlePropertyStore.activeStep}
              steps={steps}
            />
          </Paper>
        )}
      </Layout>
    );
  }
);
