import { Service } from '@roc/feature-app-core';

export const url = {
  GET_LEAD_NOTES: '/api/v1/lender/lead/getLeadNotes',
  SAVE_LEAD_NOTE: '/api/v1/lender/lead/saveLeadNote',
  DELETE_LEAD_NOTE: '/api/v1/lender/lead/deleteLeadNote',
  GET_LEAD_ACTIVITIES: '/api/v1/lender/lead/getLeadActivities',
  GET_LOAN_VOLUMES: '/api/v1/lender/lead/getLoanVolume',
  SAVE_LEAD_ACTIVITY: '/api/v1/lender/lead/saveLeadActivity',
  SAVE_LEAD_TASK: '/api/v1/lender/lead/saveLeadTask',
  SAVE_LEAD_TASK_BULK: '/api/v1/lender/lead/saveLeadTasksBulk',
  GET_LEAD_TASKS: '/api/v1/lender/lead/getLeadTasks',
  DELETE_LEAD_ACTIVITY: '/api/v1/lender/lead/deleteLeadActivity',
  GET_ADDITIONAL_CONTACT: '/api/v1/lender/lead/getAdditionalContactInfo',
  DELETE_LEAD_ADDITIONAL_CONTACT: '/api/v1/lender/lead/deleteAdditionalContactInfo',
};

export class LeadService extends Service {
  getLeadNotes(objectId: number, type: string, businessUnit: string) {
    return this.get(url.GET_LEAD_NOTES, { objectId, type, businessUnit });
  }

  saveLeadNote(leadNote) {
    return this.post(url.SAVE_LEAD_NOTE, leadNote);
  }

  deleteLeadNote(leadNoteId) {
    return this.delete(url.DELETE_LEAD_NOTE, { leadNoteId })
  }

  getLeadActivities(objectId: number, type: string, businessUnit: string) {
    return this.get(url.GET_LEAD_ACTIVITIES, { objectId, type, businessUnit });
  }

  saveLeadActivity(leadActivity) {
    return this.post(url.SAVE_LEAD_ACTIVITY, leadActivity);
  }

  saveLeadTask(leadTask) {
    return this.post(url.SAVE_LEAD_TASK, leadTask);
  }

  saveLeadTasksBulk(leadTasks) {
    return this.post(url.SAVE_LEAD_TASK_BULK, leadTasks);
  }


  getLeadTasks(objectId: number, type: string, businessUnit: string) {
    return this.get(url.GET_LEAD_TASKS, { objectId, type, businessUnit });
  }


  deleteLeadActivity(leadActivityId) {
    return this.delete(url.DELETE_LEAD_ACTIVITY, { leadActivityId })
  }

  getLoanVolumeStats(leadId: number, type: string) {
    return this.get(url.GET_LOAN_VOLUMES, { leadId, type });
  }

  getLeadAdditionalContactInfo(objectId: number) {
    return this.get(url.GET_ADDITIONAL_CONTACT, { objectId });
  }

  deleteLeadAdditionalContactInfo(body) {
    return this.post(url.DELETE_LEAD_ADDITIONAL_CONTACT, body)
  }

}
