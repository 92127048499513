export const LEAD_SOURCE_OPTIONS = [
  "BiggerPockets",
  "Bing",
  "Broker",
  "Conference",
  "Datascience",
  "Email Campaign",
  "Facebook",
  "FACo",
  "Google",
  "Green Team",
  "Inbound Call",
  "Inbound Email",
  "Letter In the Mail",
  "LinkedIn",
  "Pinterest",
  "Private Lender Link",
  "Really",
  "Referral",
  "Sales user uploaded",
  "Veezla",
  "VoiceMail",
  "Website"
];
