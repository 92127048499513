import { Route } from 'react-router';
import ProposalDetails from '../proposalsDetails';
import Proposals from '../proposals';
import { Page } from '@roc/feature-app-core';
import ViewProposal from '../viewProposal';
import { DocumentUpload } from '../components/documentComponents/documentUpload';

export const getProposalsRoutesConfig = (
  basePath: string,
  baseUrl: string
) => {
  const proposalsPath = `${basePath}/proposals`;
  const proposalsUrl = `${baseUrl}/proposals`;
  return {
    proposalsHome: {
      path: `${proposalsPath}`,
      url: `${proposalsUrl}`,
      documentTitle: 'Deal Room',
    },
    proposalsBackofficeHome: {
      path: `${basePath}/backoffice/proposals`,
      url: `${baseUrl}/backoffice/proposals`,
      documentTitle: 'Deal Room',
    },
    submitProposal: {
      path: `${proposalsPath}/submit`,
      url: `${proposalsUrl}/submit`,
      documentTitle: 'Deal Room',
    },
    proposalDetails: (proposalId?: string, isAppeal?: boolean) => ({
      path: `${proposalsPath}/:proposalId/details`,
      url: `${proposalsUrl}/${proposalId}/details`,
      documentTitle: 'Deal Room',
    }),
    viewProposal: (proposalId?: string, status?: String) => ({
      path: `${proposalsPath}/:proposalId/view`,
      url: `${proposalsUrl}/${proposalId}/view`,
      documentTitle: 'Deal Room',
    }),
    documentUpload: (proposalId?: string) => ({
      path: `${proposalsPath}/:proposalId/documents`,
      url: `${proposalsUrl}/${proposalId}/documents`,
      documentTitle: 'Document Upload',
    }),
  };
};

export const getProposalsRoutes = (basePath: string, baseUrl: string) => {
  const config = getProposalsRoutesConfig(basePath, baseUrl);
  return {
    proposalsHome: (
      <Route exact path={config.proposalsHome.path}>
        <Page routeDefinition={config.proposalsHome}>
          <Proposals />
        </Page>
      </Route>
    ),
    proposalsBackofficeHome: (
      <Route exact path={config.proposalsBackofficeHome.path}>
        <Page routeDefinition={config.proposalsBackofficeHome}>
          <Proposals />
        </Page>
      </Route>
    ),
    submitProposal: (
      <Route exact path={config.submitProposal.path}>
        <Page routeDefinition={config.submitProposal}>
          <ProposalDetails />
        </Page>
      </Route>
    ),
    proposalDetails: (
      <Route path={config.proposalDetails().path}>
        <Page routeDefinition={config.proposalDetails()}>
          <ProposalDetails />
        </Page>
      </Route>
    ),
    viewProposal: (
      <Route path={config.viewProposal().path}>
        <Page routeDefinition={config.viewProposal()}>
          <ViewProposal />
        </Page>
      </Route>
    ),
    documentUpload: (
      <Route exact path={config.documentUpload().path}>
        <Page routeDefinition={config.documentUpload()}>
          <DocumentUpload />
        </Page>
      </Route>
    ),
  };
};
