export enum VideoRoomStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED'
}

export type VideoRoom = {
  videoRoomId: number,
  videoRoomUniqueId: string,
  roomSid: string,
  videoRoomName: string,
  host: VideoRoomParticipant,
  videoRoomParticipants: VideoRoomParticipant[],
  status: VideoRoomStatus
};

export type VideoRoomParticipant = {
  userId: number,
  firstName: string,
  lastName: string,
};

export type VideoRoomParticipantsMap = Record<number, VideoRoomParticipant>;