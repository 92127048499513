import { Grid } from "@material-ui/core";
import { isNotBlank } from "@roc/feature-utils";
import { CurrencyField } from "@roc/ui";
import { observer } from "mobx-react";
import { GridContainer } from "../../components/formComponents";


export const DSCRForm = observer(
  ({ store, onChange = null, ...rest }) => {
    const formFields = store.form.fields;

    return (
      <Grid container direction="row">
        <Grid item xs={12}>
          <Grid container spacing={2} alignItems="flex-end">
            <GridContainer>
              <CurrencyField
                variant="outlined"
                standaloneLabel
                label="Gross Monthly Rent"
                value={formFields.monthlyGrossRent.value}
                onChange={(name, value) => {
                  store.onFieldChange(
                    'monthlyGrossRent',
                    Number(value)
                  );
                }}
                fullWidth
                testId="monthlyGrossRent"
                required
                error={isNotBlank(formFields.monthlyGrossRent.error)}
                errorText={formFields.monthlyGrossRent.error}
              />
            </GridContainer>
            <GridContainer>
              <CurrencyField
                variant="outlined"
                standaloneLabel
                label="Annual Taxes"
                value={formFields.annualTaxes.value}
                onChange={(name, value) =>
                  store.onFieldChange(
                    'annualTaxes',
                    Number(value)
                  )
                }
                fullWidth
                testId="annualTaxes"
                required
                error={isNotBlank(formFields.annualTaxes.error)}
                errorText={formFields.annualTaxes.error}
              />
            </GridContainer>
            <GridContainer>
              <CurrencyField
                variant="outlined"
                standaloneLabel
                label="Annual Insurance"
                value={formFields.annualInsurance.value}
                onChange={(name, value) =>
                  store.onFieldChange(
                    'annualInsurance',
                    Number(value)
                  )
                }
                fullWidth
                testId="annualInsurance"
                required
                error={isNotBlank(formFields.annualInsurance.error)}
                errorText={formFields.annualInsurance.error}
              />
            </GridContainer>
            <GridContainer>
              <CurrencyField
                variant="outlined"
                standaloneLabel
                label="Annual HOA Dues"
                value={formFields.annualHOA.value}
                onChange={(name, value) =>
                  store.onFieldChange(
                    'annualHOA',
                    Number(value)
                  )
                }
                fullWidth
                testId="annualHOA"
                required
                error={isNotBlank(formFields.annualHOA.error)}
                errorText={formFields.annualHOA.error}
              />
            </GridContainer>
            <GridContainer>
              <CurrencyField
                variant="outlined"
                standaloneLabel
                label="Annual Utilities Expenses"
                value={formFields.totalAnnualUtilities.value}
                onChange={(name, value) =>
                  store.onFieldChange(
                    'totalAnnualUtilities',
                    Number(value)
                  )
                }
                fullWidth
                testId="totalAnnualUtilities"
                required
                error={isNotBlank(formFields.totalAnnualUtilities.error)}
                errorText={formFields.totalAnnualUtilities.error}
              />
            </GridContainer>
            <GridContainer>
              <CurrencyField
                variant="outlined"
                standaloneLabel
                label="Annual Repairs &amp; Maintenance Expenses"
                value={formFields.totalAnnualRepairsMaintenance.value}
                onChange={(name, value) =>
                  store.onFieldChange(
                    'totalAnnualRepairsMaintenance',
                    Number(value)
                  )
                }
                fullWidth
                testId="totalAnnualRepairsMaintenance"
                required
                error={isNotBlank(
                  formFields.totalAnnualRepairsMaintenance.error
                )}
                errorText={formFields.totalAnnualRepairsMaintenance.error}
              />
            </GridContainer>
            <GridContainer>
              <CurrencyField
                variant="outlined"
                standaloneLabel
                label="Annual Property Management Fees"
                value={formFields.totalAnnualPropertyManagementFees.value}
                onChange={(name, value) =>
                  store.onFieldChange(
                    'totalAnnualPropertyManagementFees',
                    Number(value)
                  )
                }
                fullWidth
                testId="totalAnnualPropertyManagementFees"
                required
                error={isNotBlank(
                  formFields.totalAnnualPropertyManagementFees.error
                )}
                errorText={formFields.totalAnnualPropertyManagementFees.error}
              />
            </GridContainer>
            <GridContainer>
              <CurrencyField
                variant="outlined"
                standaloneLabel
                label="Annual Administrative Management Fees"
                value={formFields.generalAdministrative.value}
                onChange={(name, value) =>
                  store.onFieldChange(
                    'generalAdministrative',
                    Number(value)
                  )
                }
                fullWidth
                testId="generalAdministrative"
                required
                error={isNotBlank(
                  formFields.generalAdministrative.error
                )}
                errorText={formFields.generalAdministrative.error}
              />
            </GridContainer>
            <GridContainer>
              <CurrencyField
                variant="outlined"
                standaloneLabel
                label="Annual Payroll Expense"
                value={formFields.payroll.value}
                onChange={(name, value) =>
                  store.onFieldChange(
                    'payroll',
                    Number(value)
                  )
                }
                fullWidth
                testId="payroll"
                required
                error={isNotBlank(
                  formFields.payroll.error
                )}
                errorText={formFields.payroll.error}
              />
            </GridContainer>
            <GridContainer>
              <CurrencyField
                variant="outlined"
                standaloneLabel
                label="Annual Marketing Expense"
                value={formFields.marketing.value}
                onChange={(name, value) =>
                  store.onFieldChange(
                    'marketing',
                    Number(value)
                  )
                }
                fullWidth
                testId="marketing"
                required
                error={isNotBlank(
                  formFields.marketing.error
                )}
                errorText={formFields.marketing.error}
              />
            </GridContainer>
            <GridContainer>
              <CurrencyField
                variant="outlined"
                standaloneLabel
                label="Annual Replacement Reserve"
                value={formFields.replacementReserves.value}
                onChange={(name, value) =>
                  store.onFieldChange(
                    'replacementReserves',
                    Number(value)
                  )
                }
                fullWidth
                testId="replacementReserves"
                required
                error={isNotBlank(
                  formFields.replacementReserves.error
                )}
                errorText={formFields.replacementReserves.error}
              />
            </GridContainer>
          </Grid>
        </Grid>
      </Grid>
    );
  }
);