import { action, makeObservable } from 'mobx';
import { LoanStore } from './loanStore';
import { GlobalStore } from '@roc/feature-app-core';
import { FormStore } from '@roc/feature-app-core';

const form = {
  fields: {
    emailAddress: {
      value: '',
      error: null,
      rule: 'required|email',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export class ResendQuoteStore extends FormStore {
  globalStore: GlobalStore;
  loanStore: LoanStore;

  constructor(globalStore, loanStore) {
    super({ ...form }, globalStore);
    this.loanStore = loanStore;
    makeObservable(this, {
      setDefaultBorrowerEmailAddress: action,
    });
  }

  setDefaultBorrowerEmailAddress() {
    const email = this.loanStore.loanDetails.borrowers[0]?.emailAddress;
    this.onFieldChange('emailAddress', email);
  }
}

export default ResendQuoteStore;
