import { Typography, Grid, Box } from '@material-ui/core';
import {
  createSelectField,
  createTextField,
  createNumberFormatField,
  createDateField,
  createAutocompleteAddressField,
  createRadioField,
} from '@roc/ui/componentBuilder';
import BorrowerEntityStore from '../../../stores/documentForms/borrowerEntity/borrowerEntityStore';
import { observer } from 'mobx-react-lite';
import { borrowerStatesNames, borrowerStatesAbbreviation, borrowerEntityTypeOptions } from '@roc/feature-utils';
import BorrowerEntityGrid from './borrowerEntityGrid';
import { useEffect } from 'react';

interface BorrowerEntityProps {
  store: BorrowerEntityStore;
}

const operatingAgreementOptions = [
  {
    label: 'Yes',
    value: true,
  },
  {
    label: 'No',
    value: false,
  },
];

const stateOptions = borrowerStatesAbbreviation.map((abbreviation, i) => ({
  value: abbreviation,
  label: borrowerStatesNames[i],
}));

const BorrowerEntity = observer((props: BorrowerEntityProps) => {
  const { store } = props;

  const handleAddressChange = event => {
    const { value, fullInfo } = event?.target || {};
    const { address_components, geometry } = fullInfo || {};
    store.saveAddressField(value, address_components, geometry);
  };

  const fields = store.form.fields;
  useEffect(() => {
    store.updateAddressFromFields();
  }, [
    fields.streetNumber.value,
    fields.streetName.value,
    fields.addressLine2.value,
    fields.city.value,
    fields.state.value,
    fields.zip.value,
  ]);

  const disabled = !store.canEdit;
  return (
    <Grid container spacing={3} style={{
      width: '100%'
    }}>
      <Grid item xs={12}>
        <Typography variant="h5">Entity Information</Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        {createTextField({
          store,
          testId: 'name',
          fieldName: 'name',
          label: 'Name',
          disabled: disabled || !store.canEditName,
        })}
      </Grid>
      <Grid item xs={12} md={6}>
        {createSelectField({
          store,
          testId: 'type',
          fieldName: 'type',
          label: 'Type',
          options: borrowerEntityTypeOptions,
          disabled: disabled || !store.canEditType,
        })}
      </Grid>
      <Grid item xs={12} md={6}>
        {createNumberFormatField({
          store,
          testId: 'ein',
          fieldName: 'ein',
          label: 'EIN',
          format: '##-#######',
          disabled: disabled || !store.canEditEin,
        })}
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5">Entity Address</Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        {createAutocompleteAddressField({
          store,
          testId: 'address',
          fieldName: 'address',
          label: 'Entity Address',
          onChange: handleAddressChange,
          disabled,
        })}
      </Grid>
      <Grid item xs={12} md={6}>
        {createTextField({
          store,
          testId: 'streetNumber',
          fieldName: 'streetNumber',
          label: 'Street Number',
          disabled,
        })}
      </Grid>
      <Grid item xs={12} md={6}>
        {createTextField({
          store,
          testId: 'streetName',
          fieldName: 'streetName',
          label: 'Street Name',
          disabled,
        })}
      </Grid>
      <Grid item xs={12} md={6}>
        {createTextField({
          store,
          testId: 'addressLine2',
          fieldName: 'addressLine2',
          label: 'Apt/Unit/Suite/Other',
          disabled,
        })}
      </Grid>
      <Grid item xs={12} md={6}>
        {createSelectField({
          store,
          testId: 'state',
          fieldName: 'state',
          label: 'State',
          options: stateOptions,
          disabled,
        })}
      </Grid>
      <Grid item xs={12} md={6}>
        {createTextField({
          store,
          testId: 'city',
          fieldName: 'city',
          label: 'City',
          disabled,
        })}
      </Grid>
      <Grid item xs={12} md={6}>
        {createTextField({
          store,
          testId: 'zip',
          fieldName: 'zip',
          label: 'Zip Code',
          disabled,
        })}
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5">Entity Details</Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        {createDateField({
          store,
          testId: 'cogsDate',
          fieldName: 'cogsDate',
          label: 'COGS Date',
          disabled,
        })}
      </Grid>
      <Grid item xs={12} md={6}>
        {createSelectField({
          store,
          testId: 'jurisdictionState',
          fieldName: 'jurisdictionState',
          label: 'Jurisdiction State',
          options: stateOptions,
          disabled,
        })}
      </Grid>
      <Grid item xs={12} md={6}>
        {createRadioField({
          store,
          testId: 'operatingAgreement',
          fieldName: 'operatingAgreement',
          label: 'Operating Agreement',
          options: operatingAgreementOptions,
          row: true,
          disabled,
        })}
      </Grid>
      <Grid item xs={12} md={6}>
        {createDateField({
          store,
          testId: 'operatingAgreementDate',
          fieldName: 'operatingAgreementDate',
          label: 'Date of Incorporation',
          disabled,
        })}
      </Grid>
      <Grid item xs={12} md={6}>
        {createTextField({
          store,
          testId: 'childEntityName',
          fieldName: 'childEntityName',
          label: 'Child Entity Name',
          disabled,
        })}
      </Grid>
      <Grid item xs={12} md={6}>
        {createTextField({
          store,
          testId: 'childEntityAddress',
          fieldName: 'childEntityAddress',
          label: 'Child Entity Address',
          disabled,
        })}
      </Grid>
      <Grid item xs={12} md={6}>
        {createTextField({
          store,
          testId: 'childEntityRole',
          fieldName: 'childEntityRole',
          label: 'Child Entity Role',
          disabled,
        })}
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5">Borrowers/Guarantors</Typography>
      </Grid>
      <Grid item xs={12}>
        <BorrowerEntityGrid store={store} />
      </Grid>
    </Grid>
  );
});

export default BorrowerEntity;
