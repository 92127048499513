import { InfiniteScrollGrid } from '@roc/feature-app-core';
import { useLoanRoutes } from '@roc/feature-loans-routes-config';
import { formatDate } from '@roc/feature-utils';
import {
  AgGridColumnProps,
  DataGrid,
  LinkColumn,
  SelectFilterComponent,
  SelectFloatingFilterComponent,
  StatusChip,
  StatusType,
} from '@roc/ui';
import { formatCurrency } from '@roc/ui/utils';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useDrawsStore } from '../hooks';
import { Box } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

const noOpComparator = () => 0;

const columns: AgGridColumnProps[] = [
  {
    field: 'drawNumber',
    headerName: 'Draw #',
    minWidth: 100,
    maxWidth: 160,
    cellRenderer: 'drawNumberCellRenderer',
    comparator: noOpComparator,
    pinned: 'left',
    lockPinned: true,
    sortable: false,
  },
  {
    field: 'loanId',
    headerName: 'Loan Id',
    minWidth: 100,
    maxWidth: 120,
    cellRenderer: 'loanIdCellRenderer',
    filter: 'agTextColumnFilter',
    comparator: noOpComparator,
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'propertyAddress',
    headerName: 'Property Address',
    filter: 'agTextColumnFilter',
    comparator: noOpComparator,
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'stage',
    headerName: 'Status',
    minWidth: 220,
    maxWidth: 240,
    comparator: noOpComparator,
    cellRenderer: 'statusCellRenderer',
    filter: 'selectFilter',
    floatingFilter: true,
    floatingFilterComponent: 'selectFloatingFilter',
    floatingFilterComponentParams: {
      suppressFilterButton: true,
      options: [
        { label: 'Draw Initiated', value: 'Draw Initiated' },
        {
          label: 'Draw Request Form Submitted',
          value: 'Draw Request Form Submitted',
        },
        { label: 'Draw In Review', value: 'Draw In Review' },
        { label: 'Draw Approved', value: 'Draw Approved' },
        { label: 'Wire Initialized', value: 'Wire Initialized' },
        { label: 'Wire Sent/Draw Resolved', value: 'Wire Sent/Draw Resolved' },
      ],
    },
  },
  {
    field: 'creationDate',
    headerName: 'Draw Submission Date',
    minWidth: 120,
    maxWidth: 200,
    cellRenderer: 'dateCellRenderer',
    comparator: noOpComparator,
    filter: 'agDateColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'amount',
    headerName: 'Amount Requested',
    minWidth: 100,
    maxWidth: 200,
    cellRenderer: 'amountRenderer',
    comparator: noOpComparator,
  },
  {
    field: 'wireSentDate',
    headerName: 'Wire Sent Date',
    minWidth: 100,
    maxWidth: 200,
    cellRenderer: 'wireSentDateRenderer',
    comparator: noOpComparator,
    filter: 'agDateColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'wireAmount',
    headerName: 'Wire Amount',
    minWidth: 100,
    maxWidth: 200,
    cellRenderer: 'wireAmountRenderer',
    comparator: noOpComparator,
  },
];

export const ViewDrawsGrid = observer(props => {
  const { drawStore } = useDrawsStore();
  const { loanRoutesConfig } = useLoanRoutes();
  return (
    <InfiniteScrollGrid
      toolbar={props.toolbar}
      columns={columns}
      frameworkComponents={getFrameworkComponents(loanRoutesConfig)}
      store={drawStore.myDrawsGridStore}
    />
  );
});

const getFrameworkComponents = (loanRoutesConfig) => {
  return {
    selectFilter: SelectFilterComponent,
    selectFloatingFilter: SelectFloatingFilterComponent,
    dateCellRenderer: ({ value }) => {
      return value ? formatDate(value, 'MM/dd/yyyy') : '';
    },
    drawNumberCellRenderer: params => {
      if (!params.value) {
        return '';
      }
      const drawId = params.node.data?.drawId;
      const loanId = params.node.data?.loanId;
      const { onClick } = params;
      const value = `${loanId} - Draw ${params.value}`;
      return LinkColumn({
        value,
        onClick,
        url: loanRoutesConfig.loans(loanId).children.drawDetails(drawId).url,
      });
    },
    loanIdCellRenderer: params => {
      if (!params.value) {
        return '';
      }
      const { onClick } = params;
      return LinkColumn({
        value: params.value,
        onClick,
        url: loanRoutesConfig.loans(params.value).children.draws.url,
      });
    },
    amountRenderer: ({ value }) => {
      return value ? formatCurrency(value) : '';
    },
    wireAmountRenderer: ({ value }) => {
      return value && value > 0 ? formatCurrency(value) : '';
    },
    wireSentDateRenderer: params => {
      return (params.node.data?.wireAmount > 0 ? formatDate(params.node.data?.wireSentDate, 'MM/dd/yyyy') : '');
    },
    statusCellRenderer: ({ value }) =>
      value ? (
        <StatusChip statusType={StatusType.DRAWS} label={value} size='small' variant={value == 'Wire Sent/Draw Resolved' ? 'filled' : 'outlined'} />
      ) : null,
  }
};

export const ViewDrawsGridPaginated = observer(props => {
  const { drawStore } = useDrawsStore();
  const { myDrawsGridStore } = drawStore;
  const { loanRoutesConfig } = useLoanRoutes();

  useEffect(() => {
    myDrawsGridStore.resetAndFetchGridData();
  }, []);

  const onSortChanged = params => {
    const sortModel = params.api.getSortModel();
    myDrawsGridStore.setSortModel(sortModel, false);
  };

  const onFilterChanged = params => {
    const filterModel = params.api.getFilterModel();
    myDrawsGridStore.setFilterModel(filterModel, false);
  };

  return (
    <DataGrid
      toolbar={props.toolbar}
      columns={columns}
      rows={myDrawsGridStore.gridData.data.rows}
      frameworkComponents={getFrameworkComponents(loanRoutesConfig)}
      onSortChanged={onSortChanged}
      onFilterChanged={onFilterChanged}
      isLoading={myDrawsGridStore.isLoading}
      showFilters={myDrawsGridStore.showFilters}
      sortModel={myDrawsGridStore.sortModel}
      filterModel={myDrawsGridStore.filterModel}
      paginationData={myDrawsGridStore.gridData.meta}
      setPageNumber={myDrawsGridStore.setPageNumber}
      domLayout="autoHeight"
      isError={myDrawsGridStore.isError}
    />
  );
});



export const RecentDrawsGrid = observer(({
  onDataLoad
}: {
  onDataLoad?: (rows: any[], totalCount: number) => void
}) => {
  const { drawStore } = useDrawsStore();
  const { myDrawsGridStore } = drawStore;
  const { loanRoutesConfig } = useLoanRoutes();
  const [dataRows, setDataRows] = useState([]);

  useEffect(() => {
    myDrawsGridStore.resetAndFetchGridData();
  }, []);

  useEffect(() => {
    if (myDrawsGridStore.gridData?.data?.rows?.length) {
      const slicedData = myDrawsGridStore.gridData?.data?.rows.slice(0, 5);
      setDataRows(slicedData);
      onDataLoad && onDataLoad(slicedData, myDrawsGridStore.gridData?.data?.rows?.length);
    } else {
      setDataRows([]);
      onDataLoad && onDataLoad([], 0)
    }
  }, [myDrawsGridStore.gridData.data.rows]);

  const recentDrawsColumns = columns.filter((col) => {
    return col.field != 'loanId'
  }).map((col) => {
    return {
      ...col,
      sortable: false,
      floatingFilter: false,
      lockPinned: false,
      pinned: false,
    }
  });

  return (
    <>
      {
        myDrawsGridStore.isLoading &&
        <Box m={2}>
          Loading ...
        </Box>
      }
      {
        !myDrawsGridStore.isLoading && dataRows?.length == 0 &&
        <Box m={2}>
          <Alert severity="info">
            You haven't requested a draw for any of your loans recently.
          </Alert>
        </Box>
      }
      {
        !myDrawsGridStore.isLoading && dataRows?.length > 0 &&
        <DataGrid
          columns={recentDrawsColumns}
          rows={dataRows}
          frameworkComponents={getFrameworkComponents(loanRoutesConfig)}
          isLoading={myDrawsGridStore.isLoading}
          domLayout="autoHeight"
          isError={myDrawsGridStore.isError}
        />
      }
    </>
  );
});

export default ViewDrawsGrid;
