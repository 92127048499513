import { LocalStorageKeys, appLocalStorage } from "./appLocalStorage";
import { SessionStorageKeys, appSessionStorage } from "./appSessionStorage";
import { isNil, isNotBlank } from "./utils";

const setSelectedCompanyIdToStorage = (selectedCompanyId) => {
  appSessionStorage.setValue(SessionStorageKeys.selectedCompanyId, selectedCompanyId);
  appLocalStorage.setValue(LocalStorageKeys.selectedCompanyId, selectedCompanyId);
};

const getSelectedCompanyIdFromStorage = () => {
  let selectedCompanyId = appSessionStorage.getValue(SessionStorageKeys.selectedCompanyId);
  return !isNil(selectedCompanyId) ? selectedCompanyId : appLocalStorage.getValue(LocalStorageKeys.selectedCompanyId);
};

const setSelectedCompanyPortalIdToStorage = (portalId) => {
  if (isNotBlank(portalId) && portalId != 'null') {
    appSessionStorage.setValue(SessionStorageKeys.portalId, portalId);
    appLocalStorage.setValue(LocalStorageKeys.portalId, portalId);
  }
};

const getSelectedCompanyPortalIdFromStorage = () => {
  let portalId = appSessionStorage.getValue(SessionStorageKeys.portalId);
  return !isNil(portalId) ? portalId : appLocalStorage.getValue(LocalStorageKeys.portalId);
};

export const selectedCompanyStorage = {
  setSelectedCompanyIdToStorage,
  getSelectedCompanyIdFromStorage,
  setSelectedCompanyPortalIdToStorage,
  getSelectedCompanyPortalIdFromStorage,
};