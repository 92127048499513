import {
  Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import {
  Button,
} from '@roc/ui';
import { CollectCreditReportWithMessageWithCustomOptions } from '@roc/ui/formComponents';

interface BorrowerFormProps {
  onBack: () => void;
  handleSave: () => void;
  borrowerSetupStore: any;
  hideBack?: boolean;
  isInternal?: boolean;
}

export const BorrowerSetupForm = observer(({ onBack, handleSave, borrowerSetupStore, hideBack = false, isInternal = false }: BorrowerFormProps) => {

  const { borrowerSetupFormStore } = borrowerSetupStore;

  return (
    <Grid container spacing={2} justifyContent="flex-end">
      <Grid item xs={12} >
        <CollectCreditReportWithMessageWithCustomOptions
          store={borrowerSetupFormStore}
          hideDetails={true}
          label={`Please choose one of the following:`}
          isInternal={isInternal}
          fullWidth={false}
          row={false}
        />
      </Grid>
      <Grid item xs={12} container justifyContent="flex-end" spacing={1}>
        {!hideBack &&
          <Grid item>
            <Button
              onClick={onBack}
              testId={`cancel-btn`}
              color='primary'
              variant='outlined'
            >
              BACK
            </Button>
          </Grid>
        }
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSave}
            testId={'save-btn'}
          >
            SAVE
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
});

export default BorrowerSetupForm;