import {
  IconButton as MuiIconButton,
  IconButtonProps as MuiIconButtonProps,
} from '@material-ui/core';
import { withConfirmDialog, WithConfirmDialogProps } from '../dialog';
import { withTestDataAttribute } from '../testDataAttribute';

export type IconButtonProps = MuiIconButtonProps & {
  testId: string;
};

export type ConfirmationIconButtonProps = IconButtonProps &
  WithConfirmDialogProps;

export const IconButton = withTestDataAttribute((props: IconButtonProps) => {
  return <MuiIconButton {...props} />;
});

export const ConfirmationIconButton = withConfirmDialog<IconButtonProps>(
  props => {
    return <IconButton {...props} />;
  }
);
