import { observer } from 'mobx-react';
import { BorrowerFormStore } from '../../../stores/borrowers/borrowerFormStore';
import { QuoteTypeStore } from '../../../stores/quoteTypeStore';
import { PreflightStep } from '../../../utils/constants';
import { BorrowerInformationAccordion } from '../../borrowerInformation/components/borrowerInformationAccordion';
import { BorrowerInformationForm } from '../../borrowerInformation/components/borrowerInformationForm';
import { PrefligthBorrowerForm } from '../forms/prefligthBorrowerForm';
import { PrefligthCard } from './preflightCard';
import { useEffect } from 'react';

interface PrefligthBorrowerCardProps {
  store: QuoteTypeStore;
  borrowerFormStore: BorrowerFormStore;
  borrowerIndex?: number;
}

export const PrefligthBorrowerCard = observer(
  ({ store, borrowerFormStore, borrowerIndex }: PrefligthBorrowerCardProps) => {
    const step = PreflightStep.BORROWER;
    const { borrowersStore } = store;
    const { borrowerFormStores } = borrowersStore;
    const removeButtonDisabled = borrowerFormStores.length <= 1;
    const displayPGField = borrowerFormStores?.length > 1;
    const formValues = borrowerFormStore.getFormValues();

    useEffect(() => {
      if (formValues.borrowerId) {
        borrowerFormStore.fetchBorrowerExtraInfo(formValues.borrowerId);
        borrowerFormStore.fetchBorrowerStatus(formValues.borrowerId);
      }
    }, [formValues.borrowerId]);

    return (
      <PrefligthCard
        store={store}
        itemIndex={borrowerIndex}
        step={step}
        onDelete={() =>
          store.borrowersStore.removeBorrower(borrowerFormStore.key)
        }
        disableDelete={removeButtonDisabled}
        onEdit={() => store.preflightStore.goToStep(step, borrowerIndex)}
        onSave={() => {
          store.preflightStore.goToNextStep();
        }}
        FormComponent={() => (
          <PrefligthBorrowerForm
            store={borrowerFormStore}
            showPersonalGuarantorField={displayPGField}
          />
        )}
        AccordionComponent={props => (
          <BorrowerInformationAccordion
            {...props}
            store={borrowerFormStore}
            onDeleteClick={() => { }}
            showActions={true}
            removeButtonDisabled={removeButtonDisabled}
          />
        )}
      />
    );
  }
);
