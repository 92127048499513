import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { Share } from '@material-ui/icons';
import GetApp from '@material-ui/icons/GetApp';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import CopyUrlModal from './copyUrlModal';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    downloadContainer: {
      display: 'flex',
      flexDirection: 'row',
      cursor: 'pointer',
      marginLeft: '17px',
    },
    shareContainer: {
      display: 'flex',
      flexDirection: 'row',
      cursor: 'pointer',
    },
    buttonLabel: {
      fontSize: '14px',
      paddingLeft: '5px',
    },
    icon: {
      fontSize: '24px',
    },
  })
);

interface Props {
  screenshotContainerRef: any;
  url: string;
}

const ShareDownloadButtons = observer((props: Props) => {
  const classes = useStyles();
  const [isCopyUrlModalOpen, setIsCopyUrlModalOpen] = useState(false);

  const handleShareClick = () => {
    setIsCopyUrlModalOpen(true);
  };

  const handleDownload = () => {
    props.screenshotContainerRef.current.download();
  };

  const handleCopyUrlModalClose = () => setIsCopyUrlModalOpen(false);

  const handleCopyUrl = () => {
    navigator.clipboard.writeText(props.url);
    handleCopyUrlModalClose();
  };

  return (
    <>
      <div className={classes.shareContainer} onClick={handleShareClick}>
        <Share className={classes.icon} />
        <p className={classes.buttonLabel}>SHARE</p>
      </div>
      <div className={classes.downloadContainer} onClick={handleDownload}>
        <GetApp className={classes.icon} />
        <p className={classes.buttonLabel}>DOWNLOAD AS PDF</p>
      </div>
      <CopyUrlModal
        handleClose={handleCopyUrlModalClose}
        handleCopy={handleCopyUrl}
        isOpen={isCopyUrlModalOpen}
        url={props.url}
      />
    </>
  );
});

export default ShareDownloadButtons;