import { makeObservable, action, flow, observable } from 'mobx';
import {
  LoanSubType,
  tapeFilePath,
  UNDERWRITING,
} from '@roc/feature-utils';
import { TermStore } from '../common/termStore';
import PricerStore from './pricerStore';
import PropertyTypesStore from './propertyTypesStore';
import { DocumentLibraryStore } from '@roc/feature-document-library';
import { DocumentService } from '@roc/feature-documents';
import { FileUpload } from '@roc/ui';
import { BrokersStore } from '@roc/feature-brokers';
import { PropertyDetailsStore } from './propertyDetailsStore';

const LOAN_RELATED_DOCUMENTS = 'Loan Related Documents';
const LOAN_SUBMISSION_TAPE = 'Loan Submission Tape';

export class RentalPortfolioStore extends TermStore {
  documentLibraryStore: DocumentLibraryStore;
  documentService: DocumentService;
  propertyTypesStore: PropertyTypesStore;
  tapeUploaded: boolean = false;
  propertyDetailsStore: PropertyDetailsStore;

  constructor(
    globalStore,
    userStore,
    documentLibraryStore: DocumentLibraryStore,
    brokerStore: BrokersStore
  ) {
    super(globalStore, userStore, brokerStore);
    this.documentLibraryStore = documentLibraryStore;
    this.pricerStore = new PricerStore(globalStore, this);
    this.propertyTypesStore = new PropertyTypesStore(globalStore);
    this.documentService = new DocumentService();
    this.propertyDetailsStore = new PropertyDetailsStore(this.globalStore);
    this.setRentalPortfolioDefaults();

    makeObservable(this, {
      tapeUploaded: observable,
      uploadLater: action,
      downloadTapeTemplate: flow,
      uploadTape: flow,
    });
  }

  private setRentalPortfolioDefaults() {
    this.loanSubtype = LoanSubType.RENTAL_PORTFOLIO;
    this.tapeUploaded = false;
  }

  *downloadTapeTemplate() {
    try {
      yield this.documentLibraryStore.downloadDocument(tapeFilePath);
    } catch (error) {
      console.log(error);
    }
  }

  *uploadTape(fileUploads: FileUpload[]) {
    const loanId = this.savedLoanId.toString();
    try {
      const [upload] = fileUploads;
      const documentsResponse = yield this.documentService.getDocuments(
        loanId,
        UNDERWRITING
      );
      const data = JSON.parse(documentsResponse?.data?.data);
      const section = data?.[LOAN_RELATED_DOCUMENTS];
      const document = section.find(
        doc => doc.documentName === LOAN_SUBMISSION_TAPE
      );

      const formData = new FormData();
      const newFile = new File([upload.file], upload.name, {
        type: upload.file.type,
      });
      formData.append('file', newFile);
      yield this.documentService.uploadDocument(
        formData,
        document.loanDocumentId
      );
      this.tapeUploaded = true;
    } catch (error) {
      console.log(error);
    }
  }

  uploadLater() {
    this.tapeUploaded = true;
  }
}
