import { TextField } from "@roc/ui";
import { ICellEditor, ICellEditorParams, ICellEditorComp, ColDef } from "ag-grid-community";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { useReceivablesDashboardStore } from '../../hooks/useReceivablesDasboardStore';

const useStyles = makeStyles((theme: Theme) => ({
  selectField: {
    '& .MuiOutlinedInput-root': {
      borderRadius: 0,
      height: '40px',
    },
  },
}));

export const CommentEditorReceivableBills = forwardRef<ICellEditor, ICellEditorParams>((props, ref) => {
  const { value } = props;
  const { receivablesBillsStore } = useReceivablesDashboardStore();
  const [editableValue, setEditableValue] = useState(value);
  const [prevEditableValue, setPrevEditableValue] = useState(value);
  const inputRef = useRef<HTMLInputElement>(null);
  const classes = useStyles();

  useImperativeHandle(ref, () => ({
    getValue: () => {
      return editableValue;
    },
  }));

  useEffect(() => {
    if (inputRef.current) inputRef.current.focus();
    setPrevEditableValue(value);
  }, []);

  return (
    <TextField
      testId="invoice"
      variant="outlined"
      standaloneLabel
      value={editableValue}
      onChange={e => {
        const value = e.target.value;
        setEditableValue(value || '');
      }}
      onBlur={async () => {
        if (editableValue !== prevEditableValue) {
          await receivablesBillsStore.updateComments(props?.data?.recordId, editableValue);
          receivablesBillsStore.counterPartyExpenseGridStore.resetAndFetchGridData();
        }

      }}
      className={classes.selectField}
    />
  );
});
