import { Box, Grid, Tooltip, Typography } from '@material-ui/core';
import { CheckCircle, NotInterested } from '@material-ui/icons';
import { useLoanStore } from '@roc/feature-loans';
import { formatCurrency, formatPercentage } from '@roc/ui/utils';
import { observer } from 'mobx-react';
import { LoandetailsBorrowerEntityBorrowers } from '../loanDetailsBorrowerEntityBorrowers';
import { LoanDetailsCardActionsButton } from '../loanDetailsCardActionsButton';
import {
  LoanDetailsCustomCard,
  LoanDetailsInfoItem,
  useLoanDetailsInfoItemStyles,
} from '../loanDetailsCustomCard';
import { useLoanFlags } from '../../hooks/useLoanFlags';
import { useStore } from 'apps/client-portal-public/src/app/hooks';

export const BridgeLoanAmountHighlighter = () => {
  return (
    <Box p={2} mt={-.25} ml={-1.5} bgcolor={'#d7eeff'} borderRadius={16} position={'absolute'} top={0} width={'100%'} height={'100%'}>
    </Box>
  )
}

const formatPoints = value => {
  return Number((value || 0).toFixed(4));
};


export const LoanDetailsCardFixFlip = observer(() => {
  const { allowLoanPricing } = useLoanFlags();
  const { loanStore, globalStore } = useStore();
  const { loanDetails } = loanStore;
  const classes = useLoanDetailsInfoItemStyles();
  const isInternal = globalStore?.lenderInfo?.isInternal;
  const isOffHudBroker =  loanDetails?.referralFeePct && loanDetails?.referralFeePct > 0 ? true : false;


  const lenderPoints = Math.max(
    (loanDetails?.pointsIn ?? 0) - (loanDetails?.rocPointsIn ?? 0),
    0
  );
  return (
    <LoanDetailsCustomCard contentStyle={{
      minWidth: 1100
    }} cardContentProps={{
      style: {
        overflow: 'auto'
      }
    }}>
      <Grid item xs={3} style={{ position: 'relative' }}>
        <BridgeLoanAmountHighlighter />
        <Grid container spacing={0} style={{ position: 'relative' }}>
          <Grid item xs={12}>
            <LoanDetailsInfoItem
              value={formatCurrency(loanDetails?.amount, 0)}
              label={loanDetails?.loanSubType}
              valueStyle={{
                fontSize: 28,
                marginTop: -6,
              }}
              labelComponent={<>
                <Typography className={classes.loanDetailsCardLabel}>
                  {loanDetails?.loanSubType}
                </Typography>
                <Typography className={classes.loanDetailsCardLabel}>
                  {loanDetails?.loanPurpose}
                </Typography>
              </>}
            />
          </Grid>
          <Grid item xs={12}>
            <Box display={'flex'} height={'100%'}>
              <Box pr={2}>
                <LoanDetailsInfoItem
                  boxStyle={{
                    paddingTop: 2
                  }}
                  value={formatCurrency(loanDetails?.initialLoanAmount, 0)}
                  label={`Initial`}
                  secondaryLabelComponent={
                    <Box fontSize={12}>
                      <span>
                        {`${loanDetails?.aggregateLoanToCurrentCostInitial}% of `}
                      </span>
                      <Tooltip title={`Acquisition Price`} arrow>
                        <span style={{
                          borderBottom: '1px dashed #bbb'
                        }}>
                          {formatCurrency(loanDetails?.calculatedLoanData?.aggregatePurchasePrice, 0)}
                        </span>
                      </Tooltip>
                    </Box>
                  }
                />
              </Box>
              <Box pr={2} my={1} borderLeft={'1px solid #bbb'}>
              </Box>
              <Box pr={2}>
                <LoanDetailsInfoItem
                  boxStyle={{
                    paddingTop: 2
                  }}
                  value={formatCurrency(loanDetails?.constructionHoldback, 0)}
                  label={`Holdback`}
                  secondaryLabelComponent={
                    <Box fontSize={12}>
                      <span>
                        {`${loanDetails?.constructionHoldbackToRenovationBudgetPercent}% of `}
                      </span>
                      <Tooltip title={`Total Renovation Budget`} arrow>
                        <span style={{
                          borderBottom: '1px dashed #bbb'
                        }}>
                          {formatCurrency(loanDetails?.calculatedLoanData?.aggregateRenovationBudget, 0)}
                        </span>
                      </Tooltip>
                    </Box>
                  }
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={9}>
        <Grid container spacing={0} style={{
          position: 'relative'
        }}>
          {allowLoanPricing &&
            <Grid item xs={2}>
              <LoanDetailsInfoItem
                value={`${
                  formatPoints((loanDetails?.brokerPointsIn ?? 0) +
                  (lenderPoints ?? 0) +
                  (loanDetails?.rocPointsIn ?? 0) + (loanDetails?.referralFeePct ?? 0))
                } points`}
                label={
                  <>
                    {isInternal || isOffHudBroker
                      ? (isOffHudBroker
                          ? `${formatPoints(loanDetails?.referralFeePct ?? 0)} Off Hud Broker | `
                          : `${formatPoints(loanDetails?.brokerPointsIn ?? 0)} On Hud Broker | `
                        )
                      : `${formatPoints(loanDetails?.brokerPointsIn ?? 0)} Broker | `}
                    {formatPoints(lenderPoints ?? 0)} Lender <br />
                    {formatPoints(loanDetails?.rocPointsIn ?? 0)} Capital Provider
                  </>
                }
              />
            </Grid>
          }
          <Grid item xs={2}>
            <LoanDetailsInfoItem
              value={formatCurrency(loanDetails?.interestReserve, 2)}
              label="Interest Reserve"
            />
          </Grid>
          <Grid item xs={3}>
            <LoanDetailsInfoItem
              value={!!loanDetails?.aggregateAfterRepairValue
                ? formatCurrency(loanDetails?.aggregateAfterRepairValue, 0)
                : '-'}
              label={`ARV (${loanDetails?.aggregateAfterRepairLoanToValue}% ARLTV)`}
            />
          </Grid>
          <Grid item xs={2}>
            <LoanDetailsInfoItem
              value={`${loanDetails?.duration} Months`}
              label="Loan Term"
            />
          </Grid>
          <Grid item xs={2}>
            <LoanDetailsInfoItem
              value={loanDetails?.pledgeOfShares ? (
                <CheckCircle style={{ color: 'rgb(72, 173, 37)', display: 'flex', margin: '3px 0' }} />
              ) : (
                <NotInterested style={{ color: '#e90000', display: 'flex', margin: '3px 0' }} />
              )}
              label="Pledge of Shares"
            />
          </Grid>
          <Grid item xs={1}>
            <LoanDetailsCardActionsButton />
          </Grid>
          {/* <Grid item xs={2}>
        <LoanDetailsInfoItem value="???" label="??? comm." />
      </Grid> */}
          <Grid item xs={2}>
            <LoanDetailsInfoItem
              value={loanDetails?.rate ? `${loanDetails?.rate}%` : '-'}
              label='Interest Rate'
            />
          </Grid>
          <Grid item xs={8}>
            <LoandetailsBorrowerEntityBorrowers loanDetails={loanDetails} />
          </Grid>
        </Grid>
      </Grid>
    </LoanDetailsCustomCard>
  );
});
