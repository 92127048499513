import { FormStore, GlobalStore } from '@roc/feature-app-core';
import {
  GENERIC_ERROR_MESSAGE,
  getTemporaryId,
  isNumber,
  parseAddress,
} from '@roc/feature-utils';
import { DialogState } from '@roc/ui';
import {
  action,
  computed,
  flow,
  makeObservable,
  observable,
  override,
} from 'mobx';
import { NewLoanApplicationService } from '../services/newLoanApplicationService';
import {
  LoanApplicationBorrowerEntity,
  LoanApplicationBorrowerEntityBorrower,
} from '../utils/loanApplicationTypes';
import { mapEntityResponse } from '../utils/loanApplicationUtils';
import { BorrowersStore } from './borrowerFormStore';

const borrowerEntityForm = {
  fields: {
    borrowerEntityOption: {
      value: null,
    },
    borrowerEntityId: {
      value: null,
    },
    name: {
      value: '',
      error: null,
      rule: 'required',
      attribute: 'entity name'
    },
    type: {
      value: '',
      error: null,
      rule: 'required',
      attribute: 'entity type'
    },
    ein: {
      value: '',
      error: null,
      rule: 'required|regex:/^\\d{2}-?\\d{7}$/|alpha_dash',
      message: 'EIN must be a number with the format xx-xxxxxxx',
    },
    operatingAgreementDate: {
      value: null,
      error: null,
      rule: 'required',
      attribute: 'date of Incorporation'
    },
    address: {
      value: '',
      error: null,
      rule: 'required|valid_address:streetNumber,streetName,city,state,zipCode',
      attribute: 'address'
    },
    latitude: {
      value: 0,
      error: null,
      rule: '',
      message: '',
    },
    longitude: {
      value: 0,
      error: null,
      rule: '',
      message: '',
    },
    streetNumber: {
      value: '',
      error: null,
      rule: '',
    },
    streetName: {
      value: '',
      error: null,
      rule: '',
    },
    city: {
      value: '',
      error: null,
      rule: '',
    },
    state: {
      value: '',
      error: null,
      rule: '',
    },
    zipCode: {
      value: '',
      error: null,
      rule: '',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

const BLANK_BORROWER_ENTITY = {} as LoanApplicationBorrowerEntity;

export class EntityStore extends FormStore {
  private globalStore: GlobalStore;
  private borrowersStore: BorrowersStore;
  private newLoanApplicationService: NewLoanApplicationService;

  dialogState: DialogState;

  borrowerEntities: LoanApplicationBorrowerEntityBorrower[];
  selectedBorrowerEntity: LoanApplicationBorrowerEntity = BLANK_BORROWER_ENTITY;

  constructor(globalStore, borrowersStore) {
    super(borrowerEntityForm, globalStore);
    this.globalStore = globalStore;
    this.borrowersStore = borrowersStore;
    this.newLoanApplicationService = new NewLoanApplicationService();

    makeObservable(this, {
      dialogState: observable,
      borrowerEntities: observable,
      selectedBorrowerEntity: observable,
      openAddEntity: action,
      openEditEntity: action,
      closeDialog: action,
      saveAddressField: action,
      loadEntity: action,
      borrowerEntityOptions: computed,
      fetchBorrowerEntities: flow,
      saveEntity: flow,
    });
  }

  openAddEntity() {
    this.loadEntity(BLANK_BORROWER_ENTITY);
    this.dialogState = DialogState.ADD;
  }

  openEditEntity() {
    this.loadEntity(this.selectedBorrowerEntity);
    this.dialogState = DialogState.EDIT;
  }

  closeDialog() {
    this.dialogState = null;
  }

  setSelectedBorrowerEntity(borrowerEntityId) {
    const borrowerEntity = isNumber(borrowerEntityId)
      ? this.findById(borrowerEntityId).borrowerEntity
      : BLANK_BORROWER_ENTITY;
    this.loadEntity(borrowerEntity);
  }

  onFieldChange(field, value) {
    super.onFieldChange(field, value);
    if(field === 'name' && this.form.fields.borrowerEntityId.value){
      this.reset();
    }
  }

  *fetchBorrowerEntities() {
    try {
      // const filters = JSON.stringify({ name: this.form.fields.name.value });
      const response = yield this.newLoanApplicationService.getAllEntities(
        // filters
      );
      this.borrowerEntities = mapEntityResponse(response.data.data.rows);
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE,
      });
    }
  }

  loadEntity(entity: LoanApplicationBorrowerEntity) {
    this.reset();
    this.loadForm({
      borrowerEntityOption: {
        label: entity.name,
        value: entity.borrowerEntityId,
      },
      ...entity,
    });
  }

  private findById(id): LoanApplicationBorrowerEntityBorrower {
    return this.borrowerEntities.find(
      item => item.borrowerEntity.borrowerEntityId === id
    );
  }

  *saveEntity() {
    const entity = this.getFormValues();
    const isExistingEntity = !!entity.borrowerEntityId;
    if (isExistingEntity || this.runFormValidationWithMessage()) {
      this.selectedBorrowerEntity = entity;
      const borrowers = this.findById(entity.borrowerEntityId)?.borrowers || [];
      this.borrowersStore.setBorrowers(borrowers);
      this.closeDialog();
    }
  }

  get borrowerEntityOptions() {
    const selected = this.selectedBorrowerEntity;

    return (this.borrowerEntities || [])
      .map(item => item.borrowerEntity)
      .filter(entity => entity.borrowerEntityId !== selected?.borrowerEntityId)
      .concat((selected?.name ? [selected] : []) as any)
      .map(entity => ({
        label: entity.name,
        value: entity.borrowerEntityId ?? 'temp',
      }));
  }

  saveAddressField(address: string, addressDetails, geometryDetails) {
    const parsedAddress = parseAddress(
      address,
      addressDetails,
      geometryDetails
    );
    this.onFieldChange('streetNumber', parsedAddress.street_number);
    this.onFieldChange('streetName', parsedAddress.street);
    this.onFieldChange('city', parsedAddress.city);
    this.onFieldChange('state', parsedAddress.state);
    this.onFieldChange('zipCode', parsedAddress.zip);
    this.onFieldChange('address', address);
  }
}
