export interface DecodedData {
  depositUrl: string;
  loanId: string;
  propertyAddress: string;
  borrowerFirstName: string;
  borrowerLastName: string;
}
export interface DecodedGFDData {
  loanId: string;
  propertyAddress: string;
  borrowerFirstName: string;
  borrowerLastName: string;
}

export const decodeData = (data: string): DecodedData => {
  const decoded = window.atob(data.replace('-', '/')).split(';');

  return {
    depositUrl: decodeURIComponent(decoded[2]),
    loanId: decoded[0],
    propertyAddress: decodeURIComponent(decoded[1]),
    borrowerFirstName: decodeURIComponent(decoded[3]),
    borrowerLastName: decodeURIComponent(decoded[4]),
  };
};

export const decodeGFDData = (data: string): DecodedGFDData => {
  const decoded = window.atob(data.replace('-', '/')).split(';');

  return {
    loanId: decoded[0],
    propertyAddress: decodeURIComponent(decoded[1]),
    borrowerFirstName: decodeURIComponent(decoded[2]),
    borrowerLastName: decodeURIComponent(decoded[3]),
  };
};
