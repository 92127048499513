import { ScopeOfWorkV2Service } from "@roc/feature-sow-shared/services";

const url = {
  GET_SCOPES_OF_WORK: '/api/v1/draws/revision',
  GET_SCOPE_OF_WORK: '/api/v1/draws/revision/getScopeOfWork',
  SUBMIT_SOW_REVISION: '/api/v1/draws/revision/createSowRevision',
  GET_SCOPE_OF_WORK_REVISION: '/api/v1/draws/revision/getSowRevisionById',
};

export class ScopeOfWorkRevisionService extends ScopeOfWorkV2Service {
  constructor() {
    super();
    this.url = url;
  }

  submitSOWRevision(body: any) {
    return this.post(`${url.SUBMIT_SOW_REVISION}`, body);
  }

  getSOWRevision(drawId: number) {
    return this.get(`${url.GET_SCOPE_OF_WORK_REVISION}?drawId=${drawId}`);
  }
}