import { Box, Button, Checkbox, Grid, Table, TableBody, TableCell, TableHead, TableRow, Theme, Typography } from '@material-ui/core';
import { alpha, makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import { AppraisalReviewStore } from '../stores/appraisalReviewStore';
import { AddCircleOutline, CloudDownload } from '@material-ui/icons';
import { AutocompleteField, DialogState, EditableDialog, createTextField } from '@roc/ui';
import { APPRAISAL_REPORT_VERSION_3, isNotBlank } from '@roc/feature-utils';
import { useEffect } from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  fixedWidthText: {
    fontSize: '1rem',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'left',
    paddingLeft: '10px',
    width: '100%'
  },
  ssnCell: {
    textAlign: 'center',
    minWidth: '120px',
  },
  tableContainer: {
    borderRadius: '10px',
    border: '1px #e0e0e0 solid',
    overflow: 'hidden',
  },
  table: {
    minWidth: 750,
    width: '100%',
    '& td': {
      height: '40px',
      padding: 0,
      paddingTop: '8px',
      paddingBottom: '8px',
      border: '1px solid #e0e0e0',
    },
    '& th:first-child, td[rowSpan], $addNewItemRow td': {
      paddingLeft: theme.spacing(2),
      paddingRight: '3px',
    },
    '& table tr:last-child td, & table td[rowSpan]': {
      borderBottom: 'none',
    },
  },

  editableRow: {
    position: 'relative',
    cursor: 'pointer',
    textAlign: 'center',
    '& td': {
      textAlign: 'center',
      cursor: 'default',
    },
  },

  headerCell: {
    height: '41px',
    fontWeight: 'bold',
    color: '#757575',
    padding: 0,
    backgroundColor: '#F0FAFF',
    textAlign: 'center',
    border: '1px solid #e0e0e0',
  },

  titleCell: {
    height: '46px',
    padding: 0,
    overflow: 'hidden',
    backgroundColor: '#E2F6FF'
  },

  descriptionCell: {
    whiteSpace: 'pre-wrap',
  },

  inputBase: {
    width: '100%',
    '& input': {
      backgroundColor: '#fdefbf',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      fontSize: 14,
      color: 'black',
      fontWeight: theme.typography.fontWeightBold,
      padding: '8px 4px',
      cursor: 'pointer',
      '&:focus': {
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  autocompletePaper: {
    marginLeft: 0,
    marginRight: 0,
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    fontSize: 13,
  },
  autocompleteOption: {
    minHeight: 'auto',
    alignItems: 'flex-start',
    padding: 8,
    '&[data-focus="true"]': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  addNewItemCell: {
    padding: 0,
    height: '40px',
    backgroundColor: '#fdefbf',
  },
  addNewItemRow: {
    '&:hover': {
      backgroundColor: '#fff',
    },
  },
  rowActions: {
    visibility: 'hidden',
    position: 'absolute',
    right: 0,
    top: 0,
    bottom: 0,
    zIndex: 99,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      visibility: 'visible',
    },
  },
  horizontalScroll: {
    [theme.breakpoints.down('md')]: {
      overflowX: 'scroll',
    },
  },
  clickHereToEdit: {
    color: theme.palette.grey[400],
    fontSize: theme.typography.fontSize * 0.8,
  },
  clickHereToEditHidden: {
    visibility: 'hidden',
  },
  title: {
    color: '#757575',
    fontSize: 20
  },
  checkbox: {
    justifyContent: 'center',
    padding: '0px !important',
  }
}));

const columns_old_versions = [
  {
    name: 'ITEM',
    width: '30%',
  },
  {
    name: 'COMMENTS',
    width: '70%',
  },
];


const columns_version_3 = [
  {
    name: 'ITEM',
    width: '30%',
  },
  {
    name: 'COMMENTS',
    width: '50%',
  },
  {
    name: 'SEND TO REVISION',
    width: '20%',
  },
];

const createOptions = (values) => {
  return values.map(value => ({ value: value.itemName, label: value.itemName }));
}

interface NewItemModalProps {
  store: AppraisalReviewStore;
}

const AddEditItemModal = observer(({ store }: NewItemModalProps) => {
  const { appraisalReviewRevisionItemModalStore, appraisalReviewItemNames } = store;
  const { fields } = appraisalReviewRevisionItemModalStore.form;

  useEffect(() => {
    appraisalReviewRevisionItemModalStore.onFieldChange('nameSearch', fields.name.value || '');
  }, []);

  const handleOnBlur = () => {
    if (!fields.nameSearch.value && fields.name.value) {
      appraisalReviewRevisionItemModalStore.onFieldChange('name', '');
    }
  }

  return (
    <EditableDialog
      open={[DialogState.ADD, DialogState.EDIT].includes(appraisalReviewRevisionItemModalStore.dialogState)}
      title="New Item for Revision"
      dialogState={appraisalReviewRevisionItemModalStore.dialogState}
      handleClose={() => appraisalReviewRevisionItemModalStore.closeDialog()}
      handleAdd={() => store.addNewRevisionListItem()}
      disabledButton={!appraisalReviewRevisionItemModalStore.form.meta.isValid}
      dialogContent={
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <AutocompleteField
              disabled={false}
              label='Name'
              placeHolder='Search'
              required
              options={createOptions(appraisalReviewItemNames || [])}
              error={isNotBlank(fields.name.error)}
              helperText={fields.name.error}
              value={fields.nameSearch.value || ''}
              onChange={(newValue) => {
                appraisalReviewRevisionItemModalStore.onFieldChange('nameSearch', newValue);
              }}
              onSelect={(option: any) => {
                appraisalReviewRevisionItemModalStore.onFieldChange('nameSearch', option.value);
                appraisalReviewRevisionItemModalStore.onFieldChange('name', option.value);
              }}
              onBlur={handleOnBlur}
            />
          </Grid>
        </Grid>
      }
    />
  )
});

interface AppraisalReviewRevisionListProps {
  propertyId: number;
  loanId: number;
  store: AppraisalReviewStore;
}

export const AppraisalReviewRevisionList = observer(({ propertyId, loanId, store }: AppraisalReviewRevisionListProps) => {
  const classes = useStyles();
  const { appraisalReviewRevisionItemModalStore, reportVersion } = store;
  const isReportVersion3 = reportVersion === APPRAISAL_REPORT_VERSION_3;
  const columns = isReportVersion3 ? columns_version_3 : columns_old_versions;

  return (
    <Grid>
      <Grid container item xs={12} justifyContent="flex-end" spacing={2}>
        <Grid item>
          <Button
            variant="outlined"
            color='primary'
            onClick={() => appraisalReviewRevisionItemModalStore.handleAdd()}
            disabled={false}
            startIcon={<AddCircleOutline />}
          >
            ADD NEW ITEM
          </Button>
        </Grid>
      </Grid>
      <Box className={classes.tableContainer} mt={2}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell colSpan={columns.length} className={classes.titleCell}>
                <Typography className={classes.title}>Appraisal Revision</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              {columns.map(({ name, ...props }) => (
                <TableCell key={name} className={classes.headerCell} style={{ paddingLeft: '8px' }}>
                  {name}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody className={classes.editableRow}>
            {store.revisions?.map((value, index) => {
              return isReportVersion3 ? (
                <TableRow key={index}>
                  <TableCell width={columns[0].width}>
                    <span className={classes.fixedWidthText}>{value.itemDisplayName}</span>
                  </TableCell>
                  <TableCell width={columns[1].width}>
                    <span className={classes.fixedWidthText}>{value.reviewerComment}</span>
                  </TableCell>
                  <TableCell width={columns[2].width}>
                    <span className={`${classes.fixedWidthText} ${classes.checkbox}`}>
                      <Checkbox
                        name="runCredit"
                        color="primary"
                        checked={value.sendForRevision}
                        onChange={() => {
                          value.sendForRevision = !value.sendForRevision;
                        }}
                      />
                    </span>
                  </TableCell>
                </TableRow>
              ) : (
                <TableRow key={index}>
                  <TableCell width={columns[0].width}>
                    <span className={classes.fixedWidthText}>{value.revisionText ? value.revisionText : value.itemDisplayName}</span>
                  </TableCell>
                  <TableCell width={columns[0].width}>
                    <span className={classes.fixedWidthText}>{value.reviewerComment}</span>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>
      {<AddEditItemModal store={store} />}
    </Grid>
  );
});