import { FormStore, GlobalStore } from '@roc/feature-app-core';
import { GENERIC_ERROR_MESSAGE } from '@roc/feature-utils';
import { DialogState } from '@roc/ui';
import { flow, makeObservable, observable, override } from 'mobx';
import { LoanForeclosureService } from '../services/loanForeclosureService';
import { LedgerNote } from '../types/ledgerNote';

const form = {
  fields: {
    body: {
      value: '',
      error: null,
      rule: '',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export class LedgerNoteStore extends FormStore {
  private globalStore: GlobalStore;
  protected loanForeclosureService: LoanForeclosureService;

  dialogState: DialogState;
  selectedNote: LedgerNote;
  noteList: LedgerNote[];
  confirmState: boolean;

  constructor(globalStore: GlobalStore) {
    super(form, globalStore);
    this.globalStore = globalStore;
    this.loanForeclosureService = new LoanForeclosureService();

    makeObservable(this, {
      dialogState: observable,
      confirmState: observable,
      selectedNote: observable,
      noteList: observable,
      reset: override,
      fetchNotes: flow,
      openAddNote: flow,
      openEditNote: flow,
      closeNoteDialog: flow,
      openDeleteNote: flow,
      closeConfirmDialog: flow,
      saveNote: flow,
      removeNote: flow,
    });
  };

  reset() {
    super.reset();
    this.selectedNote = null;
    this.noteList = null;
    this.dialogState = null;
    this.confirmState = false;
  };

  resetForm() {
    super.reset();
    this.selectedNote = null;
  };

  *openEditNote(note: LedgerNote) {
    this.resetForm();
    this.selectedNote = note;
    this.loadForm(note);
    this.dialogState = DialogState.EDIT;
  };

  *openDeleteNote(note: LedgerNote) {
    this.resetForm();
    this.selectedNote = note;
    this.confirmState = true;
  };

  *closeConfirmDialog() {
    this.confirmState = false;
  };

  *openAddNote(loanId: number) {
    this.resetForm();
    this.initializeNote(loanId);
    this.dialogState = DialogState.ADD;
  };

  *closeNoteDialog() {
    this.dialogState = null;
  };

  *fetchNotes(loanId: number) {
    try {
      const response = yield this.loanForeclosureService.fetchNotes(loanId);
      this.noteList = response.data;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: "Error while fetching ledger note list.",
      });
    }
  };

  *saveNote() {
    try {
      if (!this.runFormValidationWithMessage()) {
        return;
      }
      const values = this.getFormValues();
      const note = {
        ...this.selectedNote,
        body: values.body,
      };
      yield this.loanForeclosureService.saveNote(note);
      yield this.closeNoteDialog();
      if (this.selectedNote?.loanId) {
        yield this.fetchNotes(this.selectedNote.loanId);
      }
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Note saved successfully!',
      });
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE,
      });
    }
  };

  *removeNote() {
    try {
      yield this.loanForeclosureService.deleteNote(this.selectedNote.ledgerNoteId);
      yield this.closeConfirmDialog();
      if (this.selectedNote?.loanId) {
        yield this.fetchNotes(this.selectedNote.loanId);
      }
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Note removed successfully!',
      });
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE,
      });
    }
  };

  private initializeNote(loanId: number) {
    this.selectedNote = {
      ledgerNoteId: null,
      loanId: loanId,
      body: "",
      createdBy: null,
      createdDate: null,
      lastUpdatedBy: null,
      lastUpdatedDate: null,
    };
  };
}
