import { FormControl, FormGroup, FormControlLabel, Checkbox } from '@material-ui/core';

export default function PropertyAppraisalCheckboxList({ state, handleChange }) {

  return (
    <FormControl component="fieldset" variant="standard">
      <FormGroup>
        {Object.keys(state).map((i, index) => <FormControlLabel
          key={index}
          control={
            <Checkbox checked={state[i].checked} onChange={handleChange} name={i} />
          }
          label={state[i].value}
        />)}
      </FormGroup>
    </FormControl>
  )
}