import { observer } from 'mobx-react';
import {
  ActionsColumn,
  Button,
  ConfirmationButton,
  DataGrid,
  GridColumnProps,
  IconButton,
  Toolbar,
} from '@roc/ui';
import FundingPreferenceStore from 'libs/feature-documents/src/documents/stores/documentForms/fundingPreference/fundingPreferenceStore';
import { EditAdditionalBorrowerEntityMemberDialog } from './editAdditionalBorrowerEntityMemberDialog';
import { Delete, Edit } from '@material-ui/icons';
import { Box } from '@material-ui/core';
import { useBaseStore } from '@roc/feature-app-core';

interface Props {
  store: FundingPreferenceStore;
}

export const AdditionalBorrowerEntityTab = observer(({ store }: Props) => {
  const { editBorrowerEntityMembersStore } = store;
  const { globalStore } = useBaseStore();

  const columns: GridColumnProps[] = [
    {
      field: 'fullName',
      headerName: 'Full Name',
      minWidth: 300,
    },
    {
      field: 'roleInEntity',
      headerName: 'Title',
      minWidth: 300,
    },
    store.canEdit && {
      field: 'actions',
      headerName: '',
      maxWidth: 150,
      cellRenderer: 'actionsCellRenderer',
    },
  ].filter(Boolean);

  const toolbar = (
    <Toolbar>
      <Button
        variant="contained"
        size="small"
        onClick={() => editBorrowerEntityMembersStore.openAdd()}
        color="primary"
        testId="addNew"
      >
        Add Additional Member
      </Button>
    </Toolbar>
  );

  const edit = row => {
    editBorrowerEntityMembersStore.openEdit(row);
  };

  const remove = row => {
    editBorrowerEntityMembersStore.removeMember(row);
  };

  const showToolBar = store.canEdit || globalStore.userFeatures.isClosingReviewer;

  return (
    <>
      <DataGrid
        toolbar={showToolBar ? toolbar : undefined}
        columns={columns}
        frameworkComponents={{
          actionsCellRenderer(params) {
            const member = params.node.data;
            return (
              <Box pr={1}>
                <IconButton testId="edit" onClick={() => edit(member)}>
                  <Edit color="primary" />
                </IconButton>
                <ConfirmationButton
                  testId="delete"
                  onClick={() => remove(member)}
                  confirmDialogProps={{
                    body: 'Are you sure you want to remove this item?',
                  }}
                >
                  <Delete color="error" />
                </ConfirmationButton>
              </Box>
            );
          },
        }}
        rows={store.additionalBorrowerEntityMembers}
        domLayout="autoHeight"
      />
      <EditAdditionalBorrowerEntityMemberDialog
        fundingPreferenceStore={store}
      />
    </>
  );
});
