import { ApiResponse, Service } from '@roc/feature-app-core';

const url = {
  GET_GROUP_EMAIL_SUBSCRIPTION:
    '/api/v1/loan/companyprofile/getCompanyEmailSubscription',
  UPDATE_GROUP_EMAIL_SUBSCRIPTION:
    '/api/v1/loan/companyprofile/updateGroupEmailSubscription',
  GET_USER_NOTIFICATION_INFO:
    '/api/v1/loan/companyprofile/getUserNotificationInfo',
  UPDATE_USER_NOTIFICATION_INFO:
    '/api/v1/loan/companyprofile/userNotificationOpt',
  GET_MY_LENDER: '/api/v1/loan/companyprofile/myLender',
  UPDATE_MY_LENDER_PORTAL: '/api/v1/loan/companyprofile/updateMyLender',
  GET_MY_LENDER_PRINCIPALS: '/api/v1/loan/lendercontacts/myLenderPrincipals',
  GET_SF_LENDER_PRINCIPALS: '/api/v1/loan/lendercontacts/getLenderPrincipals',
  CREATE_LENDER_CONTACT: '/api/v1/loan/lendercontacts/createLenderContact',
  UPDATE_LENDER_CONTACT: '/api/v1/loan/lendercontacts/updateLenderContact',
  DELETE_LENDER_CONTACT: '/api/v1/loan/lendercontacts/unlinkLenderContact',
  GET_ALL_LENDERS: '/api/v1/user/getAllLendersByContactId',
  GET_ALL_LENDERS_WITH_NO_RM: '/api/v1/user/getAllLendersWithNoRM',
  GET_LENDER_DOCUMENTS: '/api/v1/loan/lenderdocuments/getLenderDocuments',
  UPLOAD_LENDER_DOCUMENT: '/api/v1/loan/lenderdocuments/addLenderDocument',
  DOWNLOAD_LENDER_DOCUMENT: '/api/v1/loan/lenderdocuments/downloadDocument',
  DELETE_LENDER_DOCUMENT: '/api/v1/loan/lenderdocuments/deleteLenderDocument',
  GET_LENDER_FUNDING_PREFERENCE: '/api/v1/loan/companyprofile/getFundingPreference',
  UPDATE_LENDER_FUNDING_PREFERENCE: '/api/v1/loan/companyprofile/updateLenderFundingPreference',
  GET_DEFAULT_FEES_TABLE: '/api/v1/loan/companyprofile/getDefaultFeesTable',
  RELATIONSHIP_MANAGERS_FOR_PROCESSOR: '/api/v1/user/getAllRelationshipManagersForProcessor',
  UPDATE_DEFAULT_FEES: '/api/v1/loan/companyprofile/updateDefaultFees',
  RELATIONSHIP_MANAGERS_USERS: '/api/v1/user/getAllRelationshipManagersUsers',
  GET_ENTITY_BANK_INFO_FOR_LENDER: '/api/v1/document/formDocument/getTitleCompanyBankInfoForLender',
  GET_IS_INTERNAL_DECISION_COMMITTEE_ROLE_OR_LEAD: '/nportal/rest/priv/companyProfileNportal/getIsInternalDecisionCommitteeRoleOrLead',
  IS_DUPLICATE_LENDER_ALIAS: '/api/v1/loan/companyprofile/validateDuplicateLenderAlias',
  CHECK_REQUIRED_DOCS_UPLOADED_FOR_LENDER: '/api/v1/loan/companyprofile/checkRequiredDocsUploadedForLender',
  GET_LENDER_BY_ID: '/api/v1/loan/companyprofile/getLenderById',
  UPDATE_LOAN_COMMISSIONS: '/api/v1/loan/companyprofile/updateLoanCommissions',
  GET_LOAN_VOLUME: '/api/v1/lender/lead/getLoanVolume',
  UPDATE_TAMARISK_PREFERENCES: '/api/v1/loan/companyprofile/updateTamariskPreferences',
  UPDATE_DESKTOP_APPRAISAL_PREFERENCES: '/api/v1/loan/companyprofile/updateDesktopAppraisalPreferences',
  ADD_LENDER_BROKER_RELATION: '/api/v1/loan/companyprofile/addLenderBrokerRelation',
  ADD_BROKER_AS_TPO: '/api/v1/loan/companyprofile/addBrokerAsTPO',
};

const mapResponse = response => new ApiResponse({ data: response.data }, response.headers);

export class CompanyProfileService extends Service {
  url;

  constructor() {
    super();
    this.url = url;
  }
  getGroupEmailSubscription(companyId) {
    return this.get(this.url.GET_GROUP_EMAIL_SUBSCRIPTION, { companyId }).then(mapResponse);
  }

  updateGroupEmailSubscription(data) {
    return this.post(this.url.UPDATE_GROUP_EMAIL_SUBSCRIPTION, data);
  }

  getUserNotificationInfo() {
    return this.get(this.url.GET_USER_NOTIFICATION_INFO);
  }

  updateUserNotificationInfo(data) {
    return this.post(this.url.UPDATE_USER_NOTIFICATION_INFO, data);
  }

  getMyLender() {
    return this.get(this.url.GET_MY_LENDER);
  }

  updateLender(data) {
    return this.put(this.url.UPDATE_MY_LENDER_PORTAL, data);
  }

  getAllRelationshipManagersUsers() {
    return this.get(this.url.RELATIONSHIP_MANAGERS_USERS);
  }

  getAllRelationshipManagersForProcessor() {
    return this.get(this.url.RELATIONSHIP_MANAGERS_FOR_PROCESSOR);
  }

  getMyLenderPrincipals() {
    return this.get(this.url.GET_SF_LENDER_PRINCIPALS);
  }

  createLenderContact(data) {
    return this.post(this.url.CREATE_LENDER_CONTACT, data);
  }

  updateLenderContact(contactId, data) {
    return this.put(
      `${this.url.UPDATE_LENDER_CONTACT}?contactId=${contactId}`,
      data
    );
  }

  removeLenderContact(contactId) {
    return this.put(
      `${this.url.DELETE_LENDER_CONTACT}?contactId=${contactId}`,
      null
    );
  }

  getLenderDocuments(companyId) {
    return this.get(`${this.url.GET_LENDER_DOCUMENTS}?companyId=${companyId}`);
  }

  uploadLenderDocument(formData) {
    return this.post(this.url.UPLOAD_LENDER_DOCUMENT, formData);
  }

  downloadLenderDocument(documentId) {
    return this.get(
      `${this.url.DOWNLOAD_LENDER_DOCUMENT}?documentId=${documentId}`,
      {
        document: documentId,
      },
      {
        responseType: 'arraybuffer',
      }
    );
  }

  deleteLenderDocument(documentId) {
    return this.delete(
      `${this.url.DELETE_LENDER_DOCUMENT}?documentId=${documentId}`
    );
  }

  getFundingPreference(companyId) {
    return this.get(this.url.GET_LENDER_FUNDING_PREFERENCE);
  }

  updateLenderFundingPreference(data: string, companyId) {
    return this.put(this.url.UPDATE_LENDER_FUNDING_PREFERENCE, data);
  }

  getDefaultFeesTable(companyId) {
    return this.get(this.url.GET_DEFAULT_FEES_TABLE);
  }

  updateDefaultFees(data) {
    return this.post(this.url.UPDATE_DEFAULT_FEES, data);
  }

  getAllLenders(pageNumber: number, pageSize: number, sortBy: string, sortDir: string, filters: string, dropdownFilters: string) {
    return this.get(this.url.GET_ALL_LENDERS,
      { pageNumber, pageSize, sortBy, sortDir, filters, dropdownFilters },
      { disableGlobalLoading: true }
    );
  }

  getAllLendersWithNoRM(pageNumber: number, pageSize: number, sortBy: string, sortDir: string, filters: string, dropdownFilters: string) {
    return this.get(url.GET_ALL_LENDERS_WITH_NO_RM,
      { pageNumber, pageSize, sortBy, sortDir, filters, dropdownFilters },
      { disableGlobalLoading: true }
    );
  }

  isDuplicateLenderAlias(lenderId: number, lenderName: string) {
    return this.get(url.IS_DUPLICATE_LENDER_ALIAS, { lenderId, lenderName });
  }

  updateCommissions(lenderId: number) {
    return this.get(this.url.UPDATE_LOAN_COMMISSIONS, { lenderId });
  }

  getLenderBankInformationForLender(companyId) {
    return this.get(this.url.GET_ENTITY_BANK_INFO_FOR_LENDER, { companyId });
  }

  getIfIsInternalDecisionCommitteeOrLead() {
    return this.get(this.url.GET_IS_INTERNAL_DECISION_COMMITTEE_ROLE_OR_LEAD);
  }

  checkRequiredDocsUploadedForLender(lenderId) {
    return this.get(this.url.CHECK_REQUIRED_DOCS_UPLOADED_FOR_LENDER, { lenderId });
  }

  getLenderById(lenderId) {
    return this.get(this.url.GET_LENDER_BY_ID + "?lenderId=" + lenderId);
  }

  getLoanVolume(leadId, type) {
    return this.get(this.url.GET_LOAN_VOLUME, { leadId, type });
  }

  updateTamariskPreferences(preferences) {
    return this.post(this.url.UPDATE_TAMARISK_PREFERENCES, preferences);
  }

  updateDesktopAppraisalPreferences(desktopAppraisalPreference, lenderId) {
    return this.post(this.url.UPDATE_DESKTOP_APPRAISAL_PREFERENCES + "?desktopAppraisalPreference=" + desktopAppraisalPreference + "&lenderId=" + lenderId, {});
  }

  addLenderBrokerRelation(newOwnerId: number, brokerId: number) {
    return this.get(this.url.ADD_LENDER_BROKER_RELATION, { newOwnerId, brokerId });
  }

  turnBrokerIntoTPO(newOwnerId: number, brokerId: number) {
    return this.get(this.url.ADD_BROKER_AS_TPO, { newOwnerId, brokerId });
  }
}
