import { Page } from '../components';
import { Route } from 'react-router';
import { useStore } from '@roc/client-portal-shared/hooks';
import { CalculatorSelection } from './calculatorSelection';

export const getCalculatorSelectionRoutesConfig = (
  basePath: string,
  baseUrl: string
) => {
  return {
    calculatorSelection: {
      path: `${basePath}/calculator-selection`,
      url: `${baseUrl}/calculator-selection`,
      documentTitle: 'Calculator Selection',
    },
  };
};

export const getCalculatorSelectionRoutes = (basePath: string, baseUrl: string) => {
  const config = getCalculatorSelectionRoutesConfig(basePath, baseUrl);

  return {
    calculatorSelection: (
      <Route exact path={config.calculatorSelection.path}>
        <Page routeDefinition={config.calculatorSelection}>
          <CalculatorSelection/>
        </Page>
      </Route>
    ),
  };
};