import { FormStore, GlobalStore } from "@roc/feature-app-core";
import { parseAddress } from "@roc/feature-utils";
import { DocumentStore } from "../../documentStore";
import { action, flow, makeObservable } from "mobx";
import { TitleAgentService } from "../../../services/documentForms/titleAgentService";
import { Document } from '@roc/feature-types';
import { LoanStore } from "@roc/feature-loans";

export class TitleAgentFormBaseStore extends FormStore {
  globalStore: GlobalStore;
  titleAgentService: TitleAgentService;
  documentStore: DocumentStore;
  loanStore: LoanStore;
  constructor(
    form: any,
    globalStore: GlobalStore,
    loanStore: LoanStore,
    documentStore: DocumentStore
  ) {
    super(form, globalStore);
    this.titleAgentService = new TitleAgentService();
    this.globalStore = globalStore;
    this.loanStore = loanStore;
    this.documentStore = documentStore;
    makeObservable(this, {
      submit: flow,
      saveAddressField: action,
      closeDialog: action,
    })
  }

  *submit(document: Document, loanId: string, company: any, formValues: any, phone: any) {
    const titleCompanyDetails = {
      titleCompanyId: company?.companyId,
      titleCompanyName: company?.name,
      titleCompanyContactName: company?.contactName,
      titleCompanyCellPhone: company?.phone,
      titleCompanyAddress: company?.address,
      titleCompanyEmail: company?.email,
      titleAgencyId: company?.titleAgencyId,
    };

    const titleAgentFormDetails = {
      ...titleCompanyDetails,
      ...formValues,
      isWimba: false,
      cellPhone: phone,
    };

    yield this.titleAgentService.updateTitleAgentForm({
      loanDocument: document,
      loanId: parseInt(loanId),
      titleAgentFormDetails,
    });
    this.globalStore.notificationStore.showSuccessNotification({
      message: 'Title agent form saved successfully',
    });
    this.closeDialog();
    yield this.loanStore.fetchLoanDetails(document.loanId);
    yield this.documentStore.refetchDocuments();
  }

  saveAddressField(address: string, addressDetails, geometryDetails) {
    const parsedAddress = parseAddress(
      address,
      addressDetails,
      geometryDetails
    );

    this.onFieldChange('state', parsedAddress.state);
    this.onFieldChange('streetName', parsedAddress.street);
    this.onFieldChange('streetNumber', parsedAddress.street_number);
    this.onFieldChange('zipCode', parsedAddress.zip);
    this.onFieldChange('city', parsedAddress.city);
  }

  closeDialog = () => {
    this.documentStore.documentFormStore.setCurrentForm(null);
  };

}