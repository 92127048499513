import { Box, Grid, Typography } from '@material-ui/core';
import { DashboardRounded, Home } from '@material-ui/icons';
import { useBaseStore } from '@roc/feature-app-core';
import {
  useBorrowerLoansRoutes,
  useBorrowerLoansStore,
} from '@roc/feature-borrower-loans';
import {
  LoanDrawsDashboard,
  LoanDrawsDashboardShared,
  LoanDrawsDashboardSharedNew,
  useDrawsStore,
} from '@roc/feature-draws';
import { useLoanRoutes } from '@roc/feature-loans-routes-config';
import { LoanDetailsPaperLayout } from '@roc/feature-loans-shared';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

export const BorrowerLoanDrawsDashboardNew = observer(({ loanId }) => {
  const { globalStore } = useBaseStore();
  const { breadCrumbsStore } = globalStore;

  const { loanRoutesConfig } = useLoanRoutes();

  useEffect(() => {
    breadCrumbsStore.setBreadCrumbItems([
      {
        link: true,
        title: 'Dashboard',
        url: globalStore.routeBaseUrl,
        icon: <DashboardRounded />,
      },
      {
        link: true,
        title: `Loan# ${loanId}`,
        url: loanRoutesConfig.loans(loanId).url,
      },
      {
        link: false,
        title: 'Draws Dashboard',
      },
    ]);
  });

  return (
    <>
      <Grid container direction="column">
        <LoanDrawsDashboardSharedNew loanId={loanId} />
      </Grid>
    </>
  );
});
