import React from 'react';
import Box from '@material-ui/core/Box';
import Room from '@material-ui/icons/Room';
import GoogleMapReact from 'google-map-react';

const MapMarker = props => <Room fontSize="large" />;

export interface GoogleMapProps {
  mapStyles?: any;
  latitude: number;
  longitude: number;
}

export const GoogleMap: React.FC<GoogleMapProps> = ({
  mapStyles,
  latitude,
  longitude,
}) => {
  return (
    <Box className={mapStyles} display="flex">
      <GoogleMapReact center={{ lat: latitude, lng: longitude }} zoom={15}>
        <MapMarker lat={latitude} lng={longitude} />
      </GoogleMapReact>
    </Box>
  );
};
