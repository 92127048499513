import { StepsContainer } from '@roc/ui';
import { observer } from 'mobx-react';
import { PublicPersonalInformationWithIdVerification } from './publicPersonalInformationWithIdVerification';
import { insertIf } from '@roc/feature-utils';
import { IdVerification } from './idVerification';
import { PublicCreditInformation } from '../public/publicCreditInformation';
import { useEffect } from 'react';

export const PublicBorrowerSetupStepsWithIdVerification = observer((props) => {

  const { store } = props;
  const { activeStep } = store;

  useEffect(() => {
    store.generateAu10tixIdVerification();
  }, []);

  const steps = [
    {
      title: 'ID Information',
      icon: null,
      allowBack: false,
      allowNext: false,
    },
    {
      title: 'Personal Information',
      icon: null,
      onBack: () => {
        store.goPrevStep();
      },
      onNext: async () => {
        store.saveSomePersonalFinancialInformation();
      },
      allowBack: false,
      allowNext: true,
    },
    ...insertIf(store.currentBorrower?.runCreditOnCreation, [{
      title: 'Credit Information',
      icon: null,
      onBack: () => {
        store.goPrevStep();
      },
      allowBack: true,
      allowNext: false,
    }]
    ),
  ];

  const getStepsContent = () => {
    return [
      <IdVerification store={store} />,
      <PublicPersonalInformationWithIdVerification store={store} showContactDetailsVerification={false} />,
      ...insertIf(store.currentBorrower?.runCreditOnCreation, [
        <PublicCreditInformation store={store} />
      ]),
    ];
  };

  return (
    <StepsContainer
      activeStep={activeStep}
      disableNavigation={true}
      steps={steps}
    >
      {getStepsContent()}
    </StepsContainer>
  )
});