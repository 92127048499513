import { Button } from '@roc/ui';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { Divider, Grid, Theme, Typography } from '@material-ui/core';
import { ComparableProperty } from '../../types/propertyTypes';
import { observer } from 'mobx-react';
import { EditSubjectProperty } from './editSubjectProperty';
import { IndividualPropertyImages } from './individualPropertyImages';
import { IndividualPropertyDetails } from './individualPropertyDetails';
import { useEffect } from 'react';
import { formatPrice } from '../../utils/formatter';
import { useStore } from '@roc/client-portal-shared/hooks';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    propertyData: {
      marginTop: '5px',
    },
    saveButton: {
      padding: '10px 70px',
      textAlign: 'center',
    },
    container: {
      textAlign: 'center',
      padding: '0px 15px'
    }
  })
);

export interface EditIndividualPropertyProps {
  property: ComparableProperty;
}


export const EditIndividualProperty: React.FC<EditIndividualPropertyProps> = observer(({
  property
}) => {
  const classes = useStyles();
  const { compToolStore } = useStore();

  const { editSubjectPropertyFormStore } = compToolStore;

  useEffect(() => {
    editSubjectPropertyFormStore.loadForm(property.data);
  }, [property])

  return (
    <>
      <Grid container spacing={1}>
        <IndividualPropertyImages property={property} />
        <Grid container spacing={4} className={classes.container} justifyContent="space-between">

          <Grid item sm={2} xs={12} style={{ alignContent: 'center', marginTop: '5px' }}>
            <Typography align="left" gutterBottom>
              <span style={{
                backgroundColor: property.is_subject ? '#285391' : '#a9a9a9',
                color: 'white',
                borderRadius: '4px',
                padding: '10px',
                fontSize: '25px',
                fontFamily: 'Roboto'
              }}>
                {formatPrice(property.data.price, property.data.rent, compToolStore.rentalComps)}
                {compToolStore.rentalComps && <strong><span style={{ fontSize: '16px' }}>/mo</span></strong>}
              </span>
            </Typography>
          </Grid>


          <EditSubjectProperty property={property} />


        </Grid>
        <Grid item lg={12} xs={12} style={{ marginTop: '10px' }}>
          <Divider />
        </Grid>

        <Grid item xs={12} sm={12} lg={12} className={classes.propertyData}>
          <Grid container spacing={4}>
            <IndividualPropertyDetails property={property} />
          </Grid>
        </Grid>

        <Grid item lg={12} xs={12} style={{ marginTop: '10px' }}>
          <Divider />
        </Grid>

        <Grid container spacing={4} style={{ marginTop: '5px' }} >
          <Grid item xs={6} sm={6} style={{ textAlign: 'right' }}>
            <Button
              variant="outlined"
              color="primary"
              testId="addToCompare"
              className={classes.saveButton}
              onClick={() => { editSubjectPropertyFormStore.setAreEditFieldsOpened(false) }}
            >
              RESET
            </Button>
          </Grid>
          <Grid item xs={6} sm={6} style={{ textAlign: 'left' }}>
            <Button
              variant="contained"
              color="primary"
              testId="addToCompare"
              className={classes.saveButton}
              onClick={() => { compToolStore.editSubjectProperty() }}
            >
              SAVE
            </Button>

          </Grid>
        </Grid>
      </Grid >
    </>
  );
});