import { useLoanStore } from '@roc/feature-loans';
import { formatCurrency } from '@roc/ui/utils';
import { observer } from 'mobx-react';
import { Accordion, AccordionDetails, AccordionSummary, Box, darken, Divider, List, ListItem, ListItemText, makeStyles, Tooltip, Typography } from '@material-ui/core';
import { ExpandMore, Help } from '@material-ui/icons';
import { LoanDetailsPaper } from '../loanDetailsPaper';
import { LoanType } from '@roc/feature-utils';

const useStyles = makeStyles(theme => ({
  list: {
    width: '100%'
  },
  tooltip: {
    whiteSpace: 'pre-line',
    fontSize: theme.typography.pxToRem(14),
    maxWidth: 'none',
  },
  helpIcon: {
    height: '18px',
    width: '18px',
    marginLeft: theme.spacing(0.5),
  },
  tooltipStyle: {
    '& li': {
      marginLeft: '25px',
    },
  },
}));

const listItem = (label, value, bold) => {
  return (
    <ListItem>
      <ListItemText>
        <Box display="flex">
          <Box flexGrow={1}>{label}</Box>
          {bold && <Box fontWeight={'bold'}>{value}</Box>}
          {!bold && <Box>{value}</Box>}        </Box>
      </ListItemText>
    </ListItem>
  );
};

const listItemTooltip = (label, value, tooltip, bold) => {
  const classes = useStyles();

  return (
    <ListItem>
      <ListItemText>
        <Box display="flex">
          <Box>{label}</Box>
          <Tooltip
            placement="left"
            title={
              <div
                className={classes.tooltipStyle}
                dangerouslySetInnerHTML={{
                  __html: tooltip,
                }}
              />
            }
            classes={{
              tooltip: classes.tooltip,
            }}
          >
            <Help className={classes.helpIcon} color="primary" />
          </Tooltip>
          <Box flexGrow={1} />
          {bold && <Box fontWeight={'bold'}>{value}</Box>}
          {!bold && <Box>{value}</Box>}
        </Box>
      </ListItemText>
    </ListItem>
  );
}

const divider = <Divider variant="middle" component="li" />;

const ClosingCostEstimates = observer(props => {
  const classes = useStyles();
  const { loanStore, isBridgeLoan } = props;
  return (
    <List className={classes.list}>
      {listItem('Acquisition Price', formatCurrency(loanStore.estimatedClosingCosts?.acquisitionPrice || 0), false)}
      {divider}
      {listItem('Existing Debt Payoff', formatCurrency(loanStore.estimatedClosingCosts?.existingDebtPayoff || 0), false)}
      {divider}
      {listItem('Lender Origination Fees', formatCurrency(loanStore.estimatedClosingCosts?.lenderOriginationFees || 0), false)}
      {divider}
      {listItem('Broker Origination Fees', formatCurrency(loanStore.estimatedClosingCosts?.brokerOriginationFees || 0), false)}
      {divider}
      {listItem('Underwriting Fee', formatCurrency(loanStore.estimatedClosingCosts?.underwritingFee || 0), false)}
      {divider}
      {listItem('Processing Fee', formatCurrency(loanStore.estimatedClosingCosts?.processingFee || 0), false)}
      {divider}
      {listItem('Servicing Setup Fee', formatCurrency(loanStore.estimatedClosingCosts?.servicingSetupFee || 0), false)}
      {divider}
      {listItem('Legal Review Fee', formatCurrency(loanStore.estimatedClosingCosts?.legalReviewFee || 0), false)}
      {divider}
      {!isBridgeLoan && (
        <>
          {listItem('P&I Reserves at Close', formatCurrency(loanStore.estimatedClosingCosts?.interestReserve || 0), false)}
          {divider}
          {listItem('Tax Escrow at Close', formatCurrency(loanStore.estimatedClosingCosts?.taxEscrowAtClose || 0), false)}
          {divider}
          {listItem('Insurance Escrow at Close', formatCurrency(loanStore.estimatedClosingCosts?.insuranceEscrowAtClose || 0), false)}
          {divider}
        </>
      )}
      {listItem('Third Party Closing Costs', formatCurrency(loanStore.estimatedClosingCosts?.thirdPartyEstimatedClosingCost || 0), false)}
      {divider}
      {isBridgeLoan && listItem('Interest Reserve', formatCurrency(loanStore.estimatedClosingCosts?.interestReserve), false)}
      {divider}
      {listItem('Total',formatCurrency(loanStore.estimatedClosingCosts?.closingExpenseTotal || 0), true)}
      {divider}
      {listItemTooltip('Initial Loan Amount', formatCurrency(loanStore.estimatedClosingCosts?.initialLoanAmount || 0), 'Amount released at closing, not including construction holdback', false)}
      {divider}
      {listItemTooltip('Estimated Cash to Borrower at Closing', formatCurrency(loanStore.estimatedClosingCosts?.borrowerCashAtClose || 0), 'Total closing expenses - Initial loan amount', true)}
    </List>
  )
});

export const EstimatedClosingCosts = observer(props => {
  const { loanStore } = useLoanStore();
  const { loanDetails } = loanStore;
  const isBridgeLoan = loanDetails.loanType === LoanType.RESIDENTIAL_BRIDGE;

  const onOpenClosingCostsAccordion = (event, expanded) => {
    if (expanded) {
      switch (loanDetails.loanType) {
        case LoanType.RESIDENTIAL_BRIDGE:
          loanStore.fetchBridgeLoanClosingCosts(loanDetails.loanId);
          break;
        case LoanType.RESIDENTIAL_TERM:
          loanStore.fetchRentalLoanClosingCosts(loanDetails.loanId);
          break;
      }
    }
  }

  return (
    <>
    <LoanDetailsPaper>
        <Accordion elevation={0} onChange={onOpenClosingCostsAccordion}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Box>
              <Typography variant="h5">Closing Expenses</Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
              <ClosingCostEstimates isBridgeLoan={isBridgeLoan} loanStore={loanStore}/> : 
          </AccordionDetails>
      </Accordion>
    </LoanDetailsPaper>
  </>
  );
});
