import { action, flow, makeObservable, observable, toJS } from 'mobx';
import { Property } from '@roc/feature-types';
import { FormStore } from '@roc/feature-app-core';
import { GlobalStore } from '@roc/feature-app-core';
import { LoanSubType, getUniqueId, parseAddress, renovationDescriptionsByLoanSubtype, generateUUID, PropertyOwnership } from '@roc/feature-utils';

const REQUIRED_MESSAGE = 'This field is required.';

const propertyInformationForm = {
  fields: {
    loanSubType: {
      value: '',
      error: null,
      rule: '',
    },
    address: {
      value: '',
      error: null,
      rule: 'required',
    },
    __isAddressInCorrectFormat__: {
      value: true,
      error: null,
      rule: 'accepted',
      message: 'Invalid Address Format',
    },
    streetNumber: {
      value: '',
      error: null,
      rule: '',
    },
    streetName: {
      value: '',
      error: null,
      rule: 'required',
    },
    city: {
      value: '',
      error: null,
      rule: 'required',
    },
    state: {
      value: '',
      error: null,
      rule: 'required',
    },
    zipCode: {
      value: '',
      error: null,
      rule: 'required',
    },
    latitude: {
      value: 0,
      error: null,
      rule: '',
    },
    longitude: {
      value: 0,
      error: null,
      rule: '',
    },
    useCode: {
      value: '',
      error: null,
      rule: 'required',
      message: REQUIRED_MESSAGE
    },
    aptNumber: {
      value: '',
      error: null,
      rule: '',
    },
    propertyOwnership: {
      value: '',
      error: null,
      rule: 'required',
      message: REQUIRED_MESSAGE
    },
    propertyDescription: {
      value: '',
      error: null,
      rule: '',
    },
    parcel: {
      value: '',
      error: null,
      rule: '',
    },
    multipleLotValidator: {
      value: '',
      error: null,
      rule: '',
      message: REQUIRED_MESSAGE
    },
    multipleLots: {
      value: '',
      error: null,
      rule: [{ required_if: ['multipleLotValidator', true] }],
      message: REQUIRED_MESSAGE
    },
    isOriginalAddress: {
      value: '',
      error: null,
      rule: [
        { required_if: ['isOriginalAddressValidator', true] },
      ],
      message: REQUIRED_MESSAGE
    },
    originalAddress: {
      value: '',
      error: null,
      rule: [
        { required_if: ['originalAddressValidator', true] },
      ],
      message: REQUIRED_MESSAGE
    },
    numberOfLots: {
      value: '',
      error: null,
      rule: [
        { required_if: ['lotValidator', true] },
      ],
      message: REQUIRED_MESSAGE
    },
    lotValidator: {
      value: false,
      error: null,
      rule: '',
      message: REQUIRED_MESSAGE
    },
    isOriginalAddressValidator: {
      value: false,
      error: null,
      rule: '',
      message: REQUIRED_MESSAGE
    },
    originalAddressValidator: {
      value: false,
      error: null,
      rule: '',
      message: REQUIRED_MESSAGE
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

const purchaseInformationForm = {
  fields: {
    purchasePrice: {
      value: '',
      error: null,
      rule: 'required|max:1000000000000',
      message: REQUIRED_MESSAGE
    },
    armsLength: {
      value: '',
      error: null,
      rule: 'required',
      message: REQUIRED_MESSAGE
    },
    armsLengthComment: {
      value: '',
      error: null,
      rule: [{ required_if: ['armsLength', 'N'] }],
      message: REQUIRED_MESSAGE
    },
    propertySourcing: {
      value: '',
      error: null,
      rule: 'required',
      message: REQUIRED_MESSAGE
    },
    propertySourcingExplanation: {
      value: '',
      error: null,
      rule: [
        { required_if: ['propertySourcing', 'Other'] },
        { required_if: ['wholesalerPurchase', 'Other'] },
      ],
      message: REQUIRED_MESSAGE
    },
    wholesaleAmount: {
      value: '',
      error: null,
      rule: [
        { required_if: ['propertySourcing', 'Wholesaler'] },
        { max: 1000000000000 },
      ],
      message: REQUIRED_MESSAGE
    },
    wholesalerPurchase: {
      value: '',
      error: null,
      rule: [{ required_if: ['propertySourcing', 'Wholesaler'] }],
      message: REQUIRED_MESSAGE
    },
    sellerCredit: {
      value: false,
      error: null,
      rule: '',
      message: REQUIRED_MESSAGE
    },
    sellerCreditAmount: {
      value: '',
      error: null,
      rule: [{ required_if: ['sellerCredit', true] }],
      message: REQUIRED_MESSAGE
    },
    wholesalerRelationship: {
      value: '',
      error: null,
      rule: '',
      message: REQUIRED_MESSAGE
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

const refinance_purchaseInformationForm = {
  fields: {
    anyDebt: {
      value: '',
      error: null,
      rule: 'required',
      message: REQUIRED_MESSAGE
    },
    refiAmount: {
      value: '',
      error: null,
      rule: '',
    },
    purchaseDate: {
      value: null,
      error: null,
      rule: 'required',
      message: REQUIRED_MESSAGE
    },
    purchasePrice: {
      value: '',
      error: null,
      rule: 'required',
      message: REQUIRED_MESSAGE
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

const rehabRenovationFields = {
  preRehabBedroomsCount: {
    value: '',
    error: null,
    rule: [{ required_if: ['loanSubType', LoanSubType.FIX_AND_FLIP_PRO] }],
    message: REQUIRED_MESSAGE
  },
  postRehabBedroomsCount: {
    value: '',
    error: null,
    rule: [{ required_if: ['loanSubType', LoanSubType.FIX_AND_FLIP_PRO] }],
    message: REQUIRED_MESSAGE
  },
  preRehabBathroomsCount: {
    value: '',
    error: null,
    rule: [{ required_if: ['loanSubType', LoanSubType.FIX_AND_FLIP_PRO] }],
    message: REQUIRED_MESSAGE
  },
  postRehabBathroomsCount: {
    value: '',
    error: null,
    rule: [{ required_if: ['loanSubType', LoanSubType.FIX_AND_FLIP_PRO] }],
    message: REQUIRED_MESSAGE
  },
  preRehabAproxSquareFootageAboveGrade: {
    value: '',
    error: null,
    rule: [{ required_if: ['loanSubType', LoanSubType.FIX_AND_FLIP_PRO] }],
    message: REQUIRED_MESSAGE
  },
  postRehabAproxSquareFootageAboveGrade: {
    value: '',
    error: null,
    rule: [{ required_if_and: ['loanSubType', [LoanSubType.FIX_AND_FLIP_PRO], 'squareFootageAdded', [true]] }],
    message: REQUIRED_MESSAGE
  },
  preRehabAproxSquareFootageBelowGrade: {
    value: '',
    error: null,
    rule: [{ required_if: ['loanSubType', LoanSubType.FIX_AND_FLIP_PRO] }],
    message: REQUIRED_MESSAGE
  },
  postRehabAproxSquareFootageBelowGrade: {
    value: '',
    error: null,
    rule: [{ required_if_and: ['loanSubType', [LoanSubType.FIX_AND_FLIP_PRO], 'squareFootageAdded', [true]] }],
    message: REQUIRED_MESSAGE
  },

};

const renovationDetailsForm = {
  fields: {
    loanSubType: {
      value: '',
      error: null,
      rule: '',
    },
    totalRenovationBudget: {
      value: '',
      error: null,
      rule: 'required|max:1000000000000',
      message: REQUIRED_MESSAGE
    },
    describeRenovation: {
      value: '',
      error: null,
      rule: [{ required_unless: ['totalRenovationBudget', 0] }],
      message: REQUIRED_MESSAGE
    },
    sunkCost: {
      value: 0,
      error: null,
      rule: '',
    },
    renovationRemaining: {
      value: 0,
      error: null,
      rule: '',
    },
    permitsInPlace: {
      value: '',
      error: null,
      rule: [{ required_if: ['loanSubType', LoanSubType.GROUND_UP] }],
      message: REQUIRED_MESSAGE
    },
    fireDamage: {
      value: false,
      error: null,
      rule: '',
      message: REQUIRED_MESSAGE
    },
    squareFootageAdded: {
      value: false,
      error: null,
      rule: '',
      message: REQUIRED_MESSAGE
    },
    ...rehabRenovationFields
  },
  meta: {
    isValid: false,
    error: null,
  },
};

const refinance_renovationDetailsForm = {
  fields: {
    loanSubType: {
      value: '',
      error: null,
      rule: '',
    },
    totalRenovationBudget: {
      value: '',
      error: null,
      rule: 'required|max:1000000000000',
      message: REQUIRED_MESSAGE
    },
    sunkCost: {
      value: 0,
      error: null,
      rule: 'required|max:1000000000000',
      message: REQUIRED_MESSAGE
    },
    renovationBudget: {
      value: '',
      error: null,
      rule: '',
    },
    renovationRemaining: {
      value: '',
      error: null,
      rule: '',
    },
    describeRenovation: {
      value: '',
      error: null,
      rule: [{ required_unless: ['renovationRemaining', 0] }],
      message: REQUIRED_MESSAGE
    },
    permitsInPlace: {
      value: '',
      error: null,
      rule: [{ required_if: ['loanSubType', LoanSubType.GROUND_UP] }],
      message: REQUIRED_MESSAGE
    },
    fireDamage: {
      value: false,
      error: null,
      rule: '',
      message: REQUIRED_MESSAGE
    },
    squareFootageAdded: {
      value: false,
      error: null,
      rule: '',
      message: REQUIRED_MESSAGE
    },
    ...rehabRenovationFields
  },
  meta: {
    isValid: false,
    error: null,
  },
};

const otherDetailsForm = {
  fields: {
    afterRepairValue: {
      value: '',
      error: null,
      rule: 'required|max:1000000000000',
      message: REQUIRED_MESSAGE
    },
    exitStrategy: {
      value: '',
      error: null,
      rule: 'required',
      message: REQUIRED_MESSAGE
    },
    additionalComments: {
      value: '',
      error: null,
      rule: '',
    },
    submitRushedAppraisal: {
      value: false,
      error: null,
      rule: 'required',
      message: REQUIRED_MESSAGE
    },
    monthlyMarketRentProvidedAtOrigination: {
      value: '',
      error: null,
      attribute: 'Monthly Market Rent',
    },
    annualHOAProvidedAtOrigination: {
      value: '',
      error: null,
      attribute: 'Annual HOA Dues',
    },
    annualTaxesProvidedAtOrigination: {
      value: '',
      error: null,
      attribute: 'Annual Taxes',
    },
    annualInsuranceProvidedAtOrigination: {
      value: '',
      error: null,
      attribute: 'Annual Insurance',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

const refinance_otherDetailsForm = {
  fields: {
    asIsValue: {
      value: '',
      error: null,
      rule: 'required|max:1000000000000',
      message: REQUIRED_MESSAGE
    },
    __isAfterRepairValueRequired__: {
      value: true,
      error: null,
      rule: '',
    },
    afterRepairValue: {
      value: '',
      error: null,
      rule: [{ required_if: ['__isAfterRepairValueRequired__', true] }, { max: 1000000000000 }],
    },
    exitStrategy: {
      value: '',
      error: null,
      rule: 'required',
      message: REQUIRED_MESSAGE
    },
    monthlyMarketRentProvidedAtOrigination: {
      value: '',
      error: null,
      attribute: 'Monthly Market Rent',
    },
    annualHOAProvidedAtOrigination: {
      value: '',
      error: null,
      attribute: 'Annual HOA Dues',
    },
    annualTaxesProvidedAtOrigination: {
      value: '',
      error: null,
      attribute: 'Annual Taxes',
    },
    annualInsuranceProvidedAtOrigination: {
      value: '',
      error: null,
      attribute: 'Annual Insurance',
    },
    additionalComments: {
      value: '',
      error: null,
      rule: '',
    },
    submitRushedAppraisal: {
      value: false,
      error: null,
      rule: 'required',
      message: REQUIRED_MESSAGE
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

const appraisalInformationForm = {
  fields: {
    chooseExistingAppraisal: {
      value: true,
      error: null,
      rule: 'required',
    },
    orderId: {
      value: null,
      error: null,
      rule: [{ required_if: ['chooseExistingAppraisal', true] }],
      message: 'This field is required.',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

class PropertyInformationStore extends FormStore {
  private globalStore: GlobalStore;
  constructor(globalStore) {
    super({ ...propertyInformationForm }, globalStore);
    this.globalStore = globalStore;
    makeObservable(this, {
      onFormSubmit: flow,
    });
  }

  onFieldChange(field: any, value: any): void {
    super.onFieldChange(field, value);

    if (['multipleLots', 'propertyOwnership', 'isOriginalAddress'].includes(field)) {

      super.onFieldChange('multipleLotValidator', this.form.fields.propertyOwnership.value === PropertyOwnership.PURCHASED
        && this.form.fields.loanSubType.value === LoanSubType.GROUND_UP);

      const numberLotsRequired = (this.form.fields.propertyOwnership.value === PropertyOwnership.PURCHASED &&
        this.form.fields.multipleLots.value && this.form.fields.loanSubType.value === LoanSubType.GROUND_UP)
        || (this.form.fields.propertyOwnership.value === PropertyOwnership.OWNED_BY_BORROWER &&
          this.form.fields.isOriginalAddress.value === false);
      super.onFieldChange('lotValidator', numberLotsRequired);

      super.onFieldChange('isOriginalAddressValidator', this.form.fields.propertyOwnership.value === PropertyOwnership.OWNED_BY_BORROWER
        && this.form.fields.loanSubType.value === LoanSubType.GROUND_UP);

      super.onFieldChange('originalAddressValidator', this.form.fields.propertyOwnership.value === PropertyOwnership.OWNED_BY_BORROWER
        && this.form.fields.loanSubType.value === LoanSubType.GROUND_UP
        && this.form.fields.isOriginalAddress.value === false);

    }
  }

  *onFormSubmit() {
    this.form.meta.error = '';
  }

  private saveAddressField(address: string, addressDetails, geometryDetails) {
    const parsedAddress = parseAddress(
      address,
      addressDetails,
      geometryDetails
    );
    const streetAddress = parsedAddress.street_number === null ? parsedAddress.street : parsedAddress.street_number + " " + parsedAddress.street;
    this.onFieldChange('address', streetAddress);
    this.onFieldChange('streetNumber', parsedAddress.street_number);
    this.onFieldChange('streetName', parsedAddress.street);
    this.onFieldChange('city', parsedAddress.city);
    this.onFieldChange('state', parsedAddress.state);
    this.onFieldChange('zipCode', parsedAddress.zip);
    this.onFieldChange('latitude', parsedAddress.latitude);
    this.onFieldChange('longitude', parsedAddress.longitude);
    this.onFieldChange('aptNumber', parsedAddress.aptNumber);

    const addressInCorrectFormat = true;
    this.onFieldChange('__isAddressInCorrectFormat__', addressInCorrectFormat);
  }
}

class PurchaseInformationStore extends FormStore {
  private globalStore: GlobalStore;
  constructor(globalStore) {
    super({ ...purchaseInformationForm }, globalStore);
    this.globalStore = globalStore;
    makeObservable(this, {
      onFormSubmit: flow,
    });
  }

  *onFormSubmit() {
    this.form.meta.error = '';
  }
}

class Refinance_PurchaseInformationStore extends FormStore {
  private globalStore: GlobalStore;
  constructor(globalStore) {
    super({ ...refinance_purchaseInformationForm }, globalStore);
    this.globalStore = globalStore;
    makeObservable(this, {
      onFormSubmit: flow,
    });
  }

  *onFormSubmit() {
    this.form.meta.error = '';
  }
}

class RenovationDetailsStore extends FormStore {
  private globalStore: GlobalStore;
  private purchaseInformationStore: PurchaseInformationStore;
  private onRenovationBudgetChanged: (value: number) => void;
  constructor(
    globalStore,
    purchaseInformationStore,
    onRenovationBudgetChanged?: (value: number) => void
  ) {
    super({ ...renovationDetailsForm }, globalStore);
    this.globalStore = globalStore;
    this.purchaseInformationStore = purchaseInformationStore;
    this.onRenovationBudgetChanged = onRenovationBudgetChanged;
    makeObservable(this, {
      onFormSubmit: flow,
      handleRenovationBudgetChange: action,
    });
  }

  handleRenovationBudgetChange(loanSubtype) {
    const { totalRenovationBudget } = this.getFormValues();
    const { purchasePrice } = this.purchaseInformationStore.getFormValues();
    if (LoanSubType.GROUND_UP == loanSubtype) {
      this.onFieldChange('describeRenovation', renovationDescriptionsByLoanSubtype(LoanSubType.GROUND_UP));
    }
    if (!totalRenovationBudget) {
      this.onFieldChange('describeRenovation', '');
    }
    this.onFieldChange('renovationRemaining', totalRenovationBudget);

    if (!this.onRenovationBudgetChanged) return;
    this.onRenovationBudgetChanged(totalRenovationBudget);
  }

  onFieldChange(field: any, value: any): void {
    super.onFieldChange(field, value);

    //if this field is no we clean these fields
    if (field === 'squareFootageAdded' && !value) {
      super.onFieldChange('postRehabAproxSquareFootageAboveGrade', '');
      super.onFieldChange('postRehabAproxSquareFootageBelowGrade', '');
    }
  }

  *onFormSubmit() {
    this.form.meta.error = '';
  }
}

class Refinance_RenovationDetailsStore extends FormStore {
  private globalStore: GlobalStore;
  private refinanceOtherDetailsStore: Refinance_OtherDetailsStore;
  constructor(globalStore, refinanceOtherDetailsStore) {
    super({ ...refinance_renovationDetailsForm }, globalStore);
    this.globalStore = globalStore;
    this.refinanceOtherDetailsStore = refinanceOtherDetailsStore;
    makeObservable(this, {
      onFormSubmit: flow,
      handleRenovationBudgetChange: action,
      handleRenovationSpentToDateChange: action,
    });
  }

  setFormFieldValue(field, value) {
    this.form.fields[field].value = value;
    this.form.fields[field].error = null;
  }

  handleRenovationBudgetChange(loanSubtype) {
    const { sunkCost, totalRenovationBudget } = this.getFormValues();
    if (!sunkCost || totalRenovationBudget >= sunkCost) {
      if (totalRenovationBudget <= 1000000000000) {
        const renovationRemaining = totalRenovationBudget - sunkCost;
        this.updateRenovationRelatedFields(loanSubtype, renovationRemaining);
        if (!totalRenovationBudget) {
          this.refinanceOtherDetailsStore.onFieldChange('afterRepairValue', '');
        }
        this.onFieldChange('renovationRemaining', renovationRemaining);
      }
    } else {
      this.globalStore.notificationStore.showWarningNotification({
        message:
          'The total renovation budget should be greater than the amount already invested.',
      });
    }
  }

  handleRenovationSpentToDateChange(loanSubtype) {
    const { sunkCost, totalRenovationBudget } = this.getFormValues();
    if (!totalRenovationBudget || sunkCost <= totalRenovationBudget) {
      const renovationRemaining = totalRenovationBudget - sunkCost;
      this.updateRenovationRelatedFields(loanSubtype, renovationRemaining);
      if (sunkCost <= 1000000000000) {
        this.onFieldChange('renovationRemaining', renovationRemaining);
      }
    } else {
      this.globalStore.notificationStore.showWarningNotification({
        message:
          'Amount already invested should be lower than the total renovation budget.',
      });
    }
  }

  private updateRenovationRelatedFields(loanSubtype, renovationRemaining) {
    if (renovationRemaining && (LoanSubType.GROUND_UP == loanSubtype)) {
      this.onFieldChange('describeRenovation', renovationDescriptionsByLoanSubtype(LoanSubType.GROUND_UP));
    }
    if (!renovationRemaining) {
      this.onFieldChange('describeRenovation', '');
      this.refinanceOtherDetailsStore.onFieldChange('afterRepairValue', '');
      this.refinanceOtherDetailsStore.onFieldChange('exitStrategy', '');
    }
    if (renovationRemaining <= 0) {
      this.refinanceOtherDetailsStore.onFieldChange('__isAfterRepairValueRequired__', false);
    }
  }

  onFieldChange(field: any, value: any): void {
    super.onFieldChange(field, value);

    //if this field is no we clean these fields
    if (field === 'squareFootageAdded' && !value) {
      super.onFieldChange('postRehabAproxSquareFootageAboveGrade', '');
      super.onFieldChange('postRehabAproxSquareFootageBelowGrade', '');
    }
  }

  *onFormSubmit() {
    this.form.meta.error = '';
  }
}

class OtherDetailsStore extends FormStore {
  private globalStore: GlobalStore;
  constructor(globalStore) {
    super({ ...otherDetailsForm }, globalStore);
    this.globalStore = globalStore;
    makeObservable(this, {
      onFormSubmit: flow,
    });
  }

  *onFormSubmit() {
    this.form.meta.error = '';
  }
}

class Refinance_OtherDetailsStore extends FormStore {
  public globalStore: GlobalStore;
  constructor(globalStore) {
    super({ ...refinance_otherDetailsForm }, globalStore);
    this.globalStore = globalStore;
    makeObservable(this, {
      onFormSubmit: flow,
    });
  }

  *onFormSubmit() {
    this.form.meta.error = '';
  }
}

class AppraisalInformationStore extends FormStore {
  public globalStore: GlobalStore;
  constructor(globalStore) {
    super({ ...appraisalInformationForm }, globalStore);
    this.globalStore = globalStore;
    makeObservable(this, {
      onFormSubmit: flow,
    });
  }

  *onFormSubmit() {
    this.form.meta.error = '';
  }
}

export class PropertyStore {
  private globalStore: GlobalStore;
  propertyId: string;
  appraisalId: string;
  loanApplicationUuid: string;
  propertyInformationStore: PropertyInformationStore;
  purchaseInformationStore: PurchaseInformationStore;
  renovationDetailsStore: RenovationDetailsStore;
  otherDetailsStore: OtherDetailsStore;
  refinance_purchaseInformationStore: Refinance_PurchaseInformationStore;
  refinance_renovationDetailsStore: Refinance_RenovationDetailsStore;
  refinance_otherDetailsStore: Refinance_OtherDetailsStore;
  appraisalInformationStore: AppraisalInformationStore;

  constructor(
    globalStore,
    onRenovationBudgetChanged?: (value: number) => void
  ) {
    this.globalStore = globalStore;
    this.propertyInformationStore = new PropertyInformationStore(
      this.globalStore
    );
    this.purchaseInformationStore = new PurchaseInformationStore(
      this.globalStore
    );
    this.renovationDetailsStore = new RenovationDetailsStore(
      this.globalStore,
      this.purchaseInformationStore,
      onRenovationBudgetChanged
    );
    this.otherDetailsStore = new OtherDetailsStore(this.globalStore);
    this.refinance_purchaseInformationStore = new Refinance_PurchaseInformationStore(
      this.globalStore
    );
    this.refinance_otherDetailsStore = new Refinance_OtherDetailsStore(
      this.globalStore
    );
    this.refinance_renovationDetailsStore = new Refinance_RenovationDetailsStore(
      this.globalStore,
      this.refinance_otherDetailsStore
    );
    this.appraisalInformationStore = new AppraisalInformationStore(
      this.globalStore
    );
    makeObservable(this, {
      propertyId: observable,
      appraisalId: observable,
      setPropertyId: action,
      setLoanApplicationUuid: action,
      reset: action,
      loadProperty: action,
      loadPropertyFromAppraisal: action,
      getProperty: action,
      setAppraisalId: action,
    });
  }

  loadProperty(property: Property, runValidations: boolean = true) {
    this.setAppraisalId(property.appraisalId);
    this.setPropertyId(property.propertyId);
    this.setLoanApplicationUuid(property.loanApplicationUuid);

    this.propertyInformationStore.loadForm(property);
    this.purchaseInformationStore.loadForm(property);
    this.renovationDetailsStore.loadForm(property);
    this.otherDetailsStore.loadForm(property);
    this.refinance_purchaseInformationStore.loadForm(property);
    this.refinance_renovationDetailsStore.loadForm(property);
    this.refinance_otherDetailsStore.loadForm(property);

    if (runValidations) {
      this.runAllFormValidations();
    }
  }

  loadPropertyFromAppraisal(appraisal) {
    const appraisalProperty = JSON.parse(
      appraisal?.appraisalData?.propertyAppraisalOrderJson
    );
    const property: Property = {
      propertyId: appraisal?.appraisalData?.propertyId,
      appraisalId: appraisal?.appraisalData?.propertyAppraisalId,
      address: appraisalProperty?.property?.address,
      streetNumber: appraisalProperty?.property?.streetNumber,
      streetName: appraisalProperty?.property?.streetName,
      city: appraisalProperty?.property?.city,
      state: appraisalProperty?.property?.state,
      zipCode: appraisalProperty?.property?.zipCode,
      latitude: appraisalProperty?.property?.latitude,
      longitude: appraisalProperty?.property?.longitude,
      useCode: appraisalProperty?.property?.propertyType,
      aptNumber: appraisalProperty?.property?.aptNumber,
      propertyOwnership: appraisalProperty?.propertyOwnership,
      purchasePrice: appraisalProperty?.purchasePrice,
      purchaseDate: appraisalProperty?.purchaseDate,
      armsLength: appraisalProperty?.armsLength,
      armsLengthComment: appraisalProperty?.armsLengthComment,
      propertySourcing: appraisalProperty?.propertySourcing,
      propertySourcingExplanation:
        appraisalProperty?.property?.propertySourcingExplanation,
      wholesaleAmount: appraisalProperty?.wholesaleAmount,
      wholesalerPurchase: appraisalProperty?.wholesalerPurchase,
      anyDebt: appraisalProperty?.property?.anyDebt,
      //refiAmount:
      totalRenovationBudget: appraisalProperty?.renovationBudget,
      describeRenovation: appraisalProperty?.describeRenovation,
      sunkCost: appraisalProperty?.sunkCost,
      afterRepairValue: appraisalProperty?.afterRepairValue,
      exitStrategy: appraisalProperty?.exitStrategy,
      additionalComments: appraisalProperty?.property?.additionalComments,
      submitRushedAppraisal: appraisalProperty?.property?.submitRushedAppraisal,
      permitsInPlace: appraisalProperty?.permitsInPlace,
      numberOfLots: appraisalProperty?.numberOfLots,
      parcel: appraisalProperty?.parcel,
      isOriginalAddress: appraisalProperty?.isOriginalAddress,
      originalAddress: appraisalProperty?.originalAddress
    };
    this.loadProperty(property, false);
  }

  runAllFormValidations() {
    this.propertyInformationStore.runFormValidation();
    this.purchaseInformationStore.runFormValidation();
    this.renovationDetailsStore.runFormValidation();
    this.otherDetailsStore.runFormValidation();
    this.refinance_purchaseInformationStore.runFormValidation();
    this.refinance_renovationDetailsStore.runFormValidation();
    this.refinance_otherDetailsStore.runFormValidation();
  }

  setPropertyId(propertyId) {
    this.propertyId = propertyId;
  }

  setAppraisalId(appraisalId) {
    this.appraisalId = appraisalId;
  }

  setLoanApplicationUuid(uuid) {
    this.loanApplicationUuid = uuid;
  }

  getProperty() {
    if (
      this.propertyInformationStore.form.fields.propertyOwnership.value ==
      'Purchase'
    ) {
      return {
        propertyId: this.propertyId ?? getUniqueId(),
        appraisalId: this.appraisalId,
        loanApplicationUuid: this.loanApplicationUuid ?? generateUUID(),
        ...this.propertyInformationStore.getFormValues(),
        ...this.purchaseInformationStore.getFormValues(),
        ...this.renovationDetailsStore.getFormValues(),
        ...this.otherDetailsStore.getFormValues(),

      };
    } else {
      return {
        propertyId: this.propertyId ?? getUniqueId(),
        appraisalId: this.appraisalId,
        loanApplicationUuid: this.loanApplicationUuid ?? generateUUID(),
        ...this.propertyInformationStore.getFormValues(),
        ...this.refinance_purchaseInformationStore.getFormValues(),
        ...this.refinance_renovationDetailsStore.getFormValues(),
        ...this.refinance_otherDetailsStore.getFormValues(),
        ...this.appraisalInformationStore.getFormValues(),
      };
    }
  }

  reset() {
    this.propertyInformationStore.reset();
    this.purchaseInformationStore.reset();
    this.renovationDetailsStore.reset();
    this.otherDetailsStore.reset();
    this.refinance_purchaseInformationStore.reset();
    this.refinance_renovationDetailsStore.reset();
    this.refinance_otherDetailsStore.reset();
    this.appraisalInformationStore.reset();
    this.appraisalId = null;
    this.propertyId = null;
  }
}

export default PropertyStore;
