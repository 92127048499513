import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Chip, Grid } from '@material-ui/core';
import { CancelOutlined, CheckCircleRounded } from '@material-ui/icons';
import { Button } from '@roc/ui';
import { TodoStatus } from '@roc/feature-loan-details';
import { observer } from 'mobx-react';
const useStyles = makeStyles({
  root: {
  },
  carousel: {
    height: '100%',
    background: '#eee',
    '& .CarouselItem': {
      display: 'flex',
      height: '100%',
      alignItems: 'center',
    }
  }
});
export const IdVerificationResult = observer(({ store, failed, isNportaUser }) => {
  const classes = useStyles();
  const identity = store.borrowerIdvResultsResponse?.au10TixIdvResultsResponse?.parsedResults?.sessionResult?.identity;
  const firstName = identity?.firstName ? `${identity?.firstName}` : '';
  const lastName = identity?.lastName ? ` ${identity?.lastName}` : '';
  const fullName = `${firstName}${lastName}`;

  return (
    <Card className={classes.root} elevation={0} variant='outlined'>
      <Box display={'flex'} flexDirection={'row'}>
        <Box position="relative" width={100} display={'flex'} justifyContent={'center'} alignItems={'flex-start'} m={2}>
          <img src={store.photoId?.imgSrc} width={'100%'} height={'auto'} style={{
            border: failed ? '4px solid red' : '4px solid green',
            borderRadius: 16,
          }} />
          <Box position="absolute" top={0} right={0} display={'flex'} margin={0} >
            {
              failed ?
                <CancelOutlined style={{
                  color: 'red',
                  background: '#ffdad7',
                  borderRadius: 24,
                  width: 32,
                  height: 32,
                }} /> :
                <CheckCircleRounded style={{
                  color: 'lightgreen',
                  background: 'green',
                  borderRadius: 24,
                  width: 32,
                  height: 32,
                }} />
            }
          </Box>
        </Box>
        <Box flexGrow={1}>
          <CardContent style={{
            padding: 16
          }}>
            <Grid container spacing={0}>
              <Grid item xs={12} md={4}>
                <Typography gutterBottom variant="h4" component="h4">
                  <strong>{fullName ?? '-'}</strong>
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography gutterBottom variant="body2" color="textSecondary" component="p">
                      Verification Type
                    </Typography>
                    <Typography variant="body1" component="p" style={{
                      fontWeight: 'bold'
                    }}>
                      ID Verification
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={1} style={{
                display: 'flex',
                justifyContent: 'center',
              }}>
                <Box style={{
                  borderLeft: '2px dotted #ccc'
                }}>
                </Box>
              </Grid>
              <Grid item xs={12} md={3}>
                <Typography gutterBottom variant="h6" component="h6">
                  ID Verification Result
                </Typography>
                <Typography variant="body1" component="p" style={{
                  fontWeight: 'bold',
                  marginBottom: 16,
                }}>
                  {
                    failed ?
                      <Chip label="FAILED" style={{
                        fontSize: 20,
                        padding: 16,
                        height: 'auto',
                        background: '#ffdad7',
                        color: 'red',
                        borderRadius: 48,
                        fontWeight: 'bold',
                      }} /> :
                      <Chip label="PASSED" style={{
                        fontSize: 20,
                        padding: 16,
                        height: 'auto',
                        background: 'lightgreen',
                        color: 'green',
                        borderRadius: 48,
                        fontWeight: 'bold',
                      }} />
                  }
                </Typography>
              </Grid>
              {failed && isNportaUser &&
                <>
                  <Grid item md={1} style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}>
                    <Box style={{
                      borderLeft: '2px dotted #ccc'
                    }}>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Typography gutterBottom variant="h6" component="h6">
                      Manual Result
                    </Typography>
                    {store.borrowerIdentificationDocumentData?.status != TodoStatus.ACCEPTED &&
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => store.acceptIdInformation()}
                        testId='acceptIdInformation'
                        disabled={store.borrowerIdentificationDocumentData?.status == TodoStatus.PENDING}
                      >
                        Accept
                      </Button>
                    }
                    {store.borrowerIdentificationDocumentData?.status == TodoStatus.ACCEPTED &&
                      <Chip label="Accepted" style={{
                        fontSize: 20,
                        padding: 16,
                        height: 'auto',
                        background: 'lightgreen',
                        color: 'green',
                        borderRadius: 48,
                        fontWeight: 'bold',
                      }} />
                    }
                  </Grid>
                </>
              }
            </Grid>
          </CardContent>
        </Box>
      </Box>
    </Card>
  );
});