import { Box, Typography } from '@material-ui/core';
import { useBaseStore } from '@roc/feature-app-core';
import { useLoanStore } from '@roc/feature-loans';
import { observer } from 'mobx-react';

export const RelationshipManagerCardContent = observer(() => {
  const { globalStore } = useBaseStore();
  const { loanStore } = useLoanStore();
  const { relationshipManagerData } = loanStore;
  const { isLenderOriginator } = globalStore.userFeatures;

  return (
    <>
      {!isLenderOriginator && (
        <Box pt={2} pb={1} pl={0} pr={1}>
          <Typography
            component="span"
            style={{ display: 'inline-block', marginRight: 100 }}
          >
            <Typography component="span" color="textSecondary">
              Primary RM:{' '}
            </Typography>
            <strong>
              {relationshipManagerData?.primaryRelationshipManager ? relationshipManagerData?.primaryRelationshipManager : 'N/A'}
            </strong>
          </Typography>
          <Typography component="span" style={{ display: 'inline-block' }}>
            <Typography component="span" color="textSecondary">
              Secondary RM:{' '}
            </Typography>
            <strong>
              {relationshipManagerData?.secondaryRelationshipManager ? relationshipManagerData?.secondaryRelationshipManager : 'N/A'}
            </strong>
          </Typography>
        </Box>
      )}
    </>
  );
});
