import React, { useEffect, useState } from 'react';
import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  Divider,
  Box,
  Grid,
} from '@material-ui/core';
import { Button, TextField } from '@roc/ui';
import { BackgroundItemStore } from '../stores/backgroundItemStore';
import { ESCALATED_KEYWORDS_FLAGS, QUICK_ANALYSIS_FLAGS, QUICK_CHECKS } from './utils/backgroundUtils';

interface NewItemFormProps {
  store: BackgroundItemStore;
  handleClose: () => void;
  subtitle: string;
  index?: number;
  record?: string;
  showCheck?: boolean;
}

const NewItemForm = ({ store, handleClose, subtitle, index, record, showCheck }: NewItemFormProps) => {
  const [title, setTitle] = useState('');
  const [itemName, setItemName] = useState('');
  const [itemDescription, setItemDescription] = useState('');
  const [isItemFlagged, setIsItemFlagged] = useState('no');
  const [isItemNameEditable, setIsItemNameEditable] = useState(false);
  const [check, setCheck] = useState('');

  useEffect(() => {
    if (index >= 0) {
      if (![QUICK_CHECKS, QUICK_ANALYSIS_FLAGS, ESCALATED_KEYWORDS_FLAGS].includes(record)) {
        setItemName(store.backgroundSectionDetails.backgroundSection[record][index].item);
        if (!store.itemsMap[record].hideDescription) {
          setItemDescription(store.backgroundSectionDetails.backgroundSection[record][index].itemDescription);
        }
        setIsItemFlagged(store.backgroundSectionDetails.backgroundSection[record][index].loefFlag ? 'yes' : 'no');
        if (showCheck) {
          setCheck(store.backgroundSectionDetails.backgroundSection[record][index].count);
        }
      } else {
        if (QUICK_ANALYSIS_FLAGS === record) {
          setItemName(store.quickAnalysisFlags[index].flagItem);
          setIsItemFlagged(store.quickAnalysisFlags[index].flagCheck ? 'yes' : 'no');
        } else if (ESCALATED_KEYWORDS_FLAGS === record) {
          setItemName(store.escalatedKeywordFlags[index].flagItem);
          setIsItemFlagged(store.escalatedKeywordFlags[index].flagCheck ? 'yes' : 'no');
          if (showCheck) {
            setCheck(store.escalatedKeywordFlags[index].check);
          }
        } else {
          setItemName(store.quickChecks[index].flagItem);
          setIsItemFlagged(store.quickChecks[index].flagCheck ? 'yes' : 'no');
          if (showCheck) {
            setCheck(store.quickChecks[index].check);
          }
        }
      }
      setIsItemNameEditable(!itemName ? true : false);
    }
  }, [])

  const handleSubmit = (e: React.FormEvent, record?: string) => {
    e.preventDefault();

    if (index >= 0) {
      if (![QUICK_CHECKS, QUICK_ANALYSIS_FLAGS, ESCALATED_KEYWORDS_FLAGS].includes(record)) {
        store.editRecordItem(itemName, itemDescription, "", false, setFlagValue(isItemFlagged), record, index, check);
      } else {
        store.editQuickCheckItem(itemName, setFlagValue(isItemFlagged), index, record, check);
      }
    }
    else {
      if (![QUICK_CHECKS, QUICK_ANALYSIS_FLAGS, ESCALATED_KEYWORDS_FLAGS].includes(record)) {
        store.addRecordItem(itemName, itemDescription, "", setFlagValue(isItemFlagged), setFlagValue(isItemFlagged), record, check)
      } else {
        store.addQuickCheckItem(
          itemName,
          setFlagValue(isItemFlagged),
          record,
          check);
      }
    }
    handleClose();
  };

  const setFlagValue = (isItemFlagged: string) => {
    if (isItemFlagged === 'yes') {
      return true
    } else return null;
  }

  const handleCancel = (e: React.FormEvent) => {
    handleClose();
  };

  return (
    <form onSubmit={(e) => { handleSubmit(e, record) }}>
      <Typography variant='h5'>Review Item</Typography>
      <Divider />
      <Typography variant='h6'>{subtitle}</Typography>
      <TextField
        type="text"
        variant="outlined"
        label="Item Name"
        value={itemName}
        onChange={(e) => setItemName(e.target.value)}
        fullWidth
        margin="normal"
        required
        testId="shareUrl"
        disabled={isItemNameEditable}
      />
      {
        subtitle != store.itemsMap[QUICK_CHECKS].name && subtitle != store.itemsMap[QUICK_ANALYSIS_FLAGS].name && !store.itemsMap[record].hideDescription && <TextField
          type="text"
          variant="outlined"
          label="Item Description"
          value={itemDescription}
          onChange={(e) => setItemDescription(e.target.value)}
          fullWidth
          multiline
          rows={4}
          margin="normal"
          testId="shareUrl"
        />}
      {
        showCheck && (
          <TextField
            type="text"
            variant="outlined"
            label="Check"
            value={check}
            onChange={(e) => setCheck(e.target.value)}
            fullWidth
            margin="normal"
            required
            testId="shareUrl"
          />
        )
      }
      {record != QUICK_ANALYSIS_FLAGS && <FormControl component="fieldset" margin="normal">
        <FormLabel component="legend">Is Item Flagged?</FormLabel>
        <RadioGroup
          row
          value={isItemFlagged}
          onChange={(e) => setIsItemFlagged(e.target.value)}
        >
          <FormControlLabel value="yes" control={<Radio />} label="Yes" />
          <FormControlLabel value="no" control={<Radio />} label="No" />
        </RadioGroup>
      </FormControl>}
      <Box marginTop={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Button onClick={handleCancel} variant="outlined" color="primary" testId="cancel" fullWidth>
              Cancel
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button type="submit" variant="contained" color="primary" testId="save" fullWidth>
              Save
            </Button>
          </Grid>
        </Grid>
      </Box>
    </form>
  );
};

export default NewItemForm;