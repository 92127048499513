import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Button, SelectField } from '@roc/ui';
import { useEffect, useState } from 'react';
import { Document } from '@roc/feature-types';
import { RateLockExtensionService } from '../../../services/documentForms/rateLockExtensionService';
import moment from 'moment';
import { BORROWER_RATE_LOCK_EXTENSIONS } from './constants';
import { useUserStore } from '@roc/feature-app-core';

export type RateLockExtensionProps = {
  document: Document;
  downloadRateLockAgreement: () => void;
  loan: any;
  closeModal: () => void;
  showErrorNotification: (error: string) => void;
};

const useStyles = makeStyles({
  instruction: {
    fontWeight: 600,
  },
});

const borrowerRateLockExtension1Options = [
  { label: 'Please Choose', value: '0' },
  { label: '15 days', value: '15' },
  { label: '30 days', value: '30' },
];

const borrowerRateLockExtension2Options = [
  { label: 'N/A', value: '0' },
  { label: '15 days', value: '15' },
];

export const RateLockExtension = (props: RateLockExtensionProps) => {
  const { document, downloadRateLockAgreement, loan, closeModal, showErrorNotification } = props;
  const classes = useStyles();
  const rateLockExtensionService = new RateLockExtensionService;
  const { userStore } = useUserStore();


  const firstInitialValue = loan?.loanPricingData.firstPaidBorrowerExtensionLength
    ? loan?.loanPricingData.firstPaidBorrowerExtensionLength
    : 0;
  const secondInitialValue = loan?.loanPricingData.secondPaidBorrowerExtensionLength
    ? loan?.loanPricingData.secondPaidBorrowerExtensionLength
    : 0;

  const [rateLockFirstExtension, setRateLockFirstExtension] = useState(firstInitialValue);
  const [rateLockSecondExtension, setRateLockSecondExtension] = useState(secondInitialValue);
  const [showFirstExtension, setShowFirstExtension] = useState(true);
  const [firstExtensionReadOnly, setFirstExtensionReadOnly] = useState(false);
  const [showSecondExtension, setShowSecondExtension] = useState(true);
  const [secondExtensionReadOnly, setsecondExtensionReadOnly] = useState(false);
  const [showSubmitButton, setShowSubmitButtonn] = useState(true);
  const [firstShortTermRateLockExtension, setFirstShortTermRateLockExtension] = useState([]);
  const [secondShortTermRateLockExtension, setSecondShortTermRateLockExtension] = useState([]);
  const [longTermRateLockExtension, setLongTermRateLockExtension] = useState([]);

  const handleExtensionChanged = (name: any, value: any) => {
    if (name === 'firstPaidBorrowerExtensionLength') {
      setRateLockFirstExtension(value);
    } else {
      setRateLockSecondExtension(value);
    }
  }

  useEffect(() => {
    let daysBetween;
    const grossRateLockExpiryDate = moment(loan?.loanPricingData.borrowerGrossRateLockExpiryDate);
    const today = moment();
    daysBetween = grossRateLockExpiryDate.diff(today, "days");
    if (loan?.loanPricingData.firstPaidBorrowerExtensionLength == 15 && daysBetween <= 0) {
      setShowSecondExtension(true);
    }
    if (daysBetween > 0) { // <= 15 days from ATF
      setShowSecondExtension(false)
      if (checkIfFirstExtensionEnable()) {
        setFirstExtensionReadOnly(true);
        setShowSubmitButtonn(false);
      }
    } else { // > 15 days from ATF
      if (checkIfSecondExtensionEnable()) {
        setFirstExtensionReadOnly(true);
        const firstPaidExtensionExpiryDay = moment(loan?.loanPricingData.firstPaidBorrowerGrossRateLockExpiryDate);
        daysBetween = firstPaidExtensionExpiryDay.diff(today, "days");
        if (daysBetween < 0) {
          setsecondExtensionReadOnly(true);
          setShowSubmitButtonn(false);
        }
      } else if (loan?.loanPricingData.firstPaidBorrowerExtensionLength == 30) {
        setFirstExtensionReadOnly(true);
        setsecondExtensionReadOnly(true);
        setShowSecondExtension(false)
        setShowSubmitButtonn(false);
      } else {
        setFirstExtensionReadOnly(true);
        setsecondExtensionReadOnly(true);
        setShowSubmitButtonn(false);
      }
    }
    setBorrowerDropdownData(BORROWER_RATE_LOCK_EXTENSIONS, loan?.lenderId, loan?.loanId, loan?.proposedInvestor);
  }, [])

  const setBorrowerDropdownData = async (extensions, lenderId, loanId, investor) => {
    let response;
    if (userStore.isNportalUser) {
      response = await rateLockExtensionService.getRateLockExtensionsNportal(extensions, lenderId, investor ?? null).
        then(res => res.data);
      setFirstShortTermRateLockExtension([
        { label: 'Please Choose', value: '0' },
        { label: response[1].days, value: response[1].days },
        { label: response[3].days, value: response[3].days }
      ]);
      setSecondShortTermRateLockExtension([
        { label: 'Please Choose', value: '0' },
        { label: response[2].days, value: response[2].days }
      ]);
    }
    else {
      response = await rateLockExtensionService.getRateLockExtensions(extensions, lenderId, loanId).
        then(res => res.data);
      setFirstShortTermRateLockExtension([{ label: 'Please Choose', value: '0' },
      { label: response[0].days, value: response[0].days }, { label: response[2].days, value: response[2].days }]);
      setSecondShortTermRateLockExtension([{ label: 'Please Choose', value: '0' }, { label: response[1].days, value: response[1].days }]);
      setLongTermRateLockExtension([{ label: 'Please Choose', value: '0' }, { label: response[2].days, value: response[2].days }]);
    }
  }


  const checkIfFirstExtensionEnable = () => {
    return !(loan?.loanPricingData.firstPaidBorrowerExtensionLength == null
      || loan?.loanPricingData.firstPaidBorrowerExtensionLength == 0)
  }

  const checkIfSecondExtensionEnable = () => {
    return (loan?.loanPricingData.firstPaidBorrowerExtensionLength != null
      && loan?.loanPricingData.firstPaidBorrowerExtensionLength == 15
      && (loan?.loanPricingData.secondPaidBorrowerExtensionLength == null
        || loan?.loanPricingData.secondPaidBorrowerExtensionLength == 0))
  }

  const onSubmit = async () => {
    try {
      const response = await rateLockExtensionService.setRateLockAgreementSfr(loan?.loanId, rateLockFirstExtension, rateLockSecondExtension)
      closeModal();
      window.location.reload();
    } catch (error) {
      showErrorNotification('There was an error while sending rate lock extension request.');
    }
  }


  return (
    <Grid container direction="column" alignItems="center" spacing={2}>
      {showFirstExtension &&
        <SelectField
          standaloneLabel
          label={'Borrower Rate Lock Extension 1 Length'}
          name={'firstPaidBorrowerExtensionLength'}
          value={rateLockFirstExtension}
          options={firstShortTermRateLockExtension}
          onChange={value => handleExtensionChanged('firstPaidBorrowerExtensionLength', value)}
          variant="outlined"
          shrink
          required
          disabled={firstExtensionReadOnly}
          testId="firstPaidBorrowerExtensionLength"
        />
      }
      {
        showSecondExtension && <SelectField
          standaloneLabel
          label={'Borrower Rate Lock Extension 2 Length'}
          name={'secondPaidBorrowerExtensionLength'}
          value={rateLockSecondExtension}
          options={secondShortTermRateLockExtension}
          onChange={value => handleExtensionChanged('secondPaidBorrowerExtensionLength', value)}
          variant="outlined"
          shrink
          required
          disabled={secondExtensionReadOnly}
          testId="secondPaidBorrowerExtensionLength"
        />
      }
      <Grid item>
        {showSubmitButton && <Button
          variant="contained"
          color="secondary"
          onClick={onSubmit}
          testId="downloadTermSheetButton"
        >
          Submit
        </Button>
        }

      </Grid>
      <Grid item>
        <Typography className={classes.instruction}>
          Please click on the button to download the Loan Rate Lock-in Agreement
        </Typography>
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          color="secondary"
          onClick={downloadRateLockAgreement}
          testId="downloadTermSheetButton"
        >
          Download Loan Rate Lock-in Agreement
        </Button>
      </Grid>
    </Grid>
  );
};

export default RateLockExtension;