import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from '@material-ui/core';
import { useStore } from '@roc/client-portal-shared/hooks';
import { Paper } from '@roc/ui';
import { observer } from 'mobx-react-lite';
import { NextBackButtons } from '../../lenderDetails/components/lenderDetailsFormComponents';
import {
  residentialProductsOptions,
} from '../../lenderDetails/utils/constants';
import { CompanyProfileStore } from '../../companyProfile/stores/companyProfileStore';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(2),
  },
}));

interface CompanyDetailsProps {
  companyProfileStore: CompanyProfileStore;
}

export const Products = observer(({ companyProfileStore }: CompanyDetailsProps) => {
  const { lenderDetailsStore } = companyProfileStore;
  const { lenderDetailsFormStore } = lenderDetailsStore;

  const formStore = lenderDetailsFormStore;

  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <Box p={4}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5">
              What kinds of products do you offer?
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ pt: 2 }}>
              <Typography variant="h6">Residential:</Typography>
            </Box>
          </Grid>
          {residentialProductsOptions.map((option, i) => (
            <Grid item xs={12} key={i}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formStore.selectedProducts[option]}
                    onChange={(e, checked) => formStore.toggleProduct(option)}
                    color="secondary"
                    disabled={false}
                  />
                }
                label={option}
              />
            </Grid>
          ))}
          <Grid item xs={12}>
            <NextBackButtons
              renderBack
              renderNext
              companyProfileStore={companyProfileStore}
            />
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
});
