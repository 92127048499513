import react, { useState, useEffect } from 'react';
import {
  Box,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import { ScopeOfWorkV2FormBaseStore } from '@roc/feature-sow-shared/stores/v2';
import { SOWTableCard } from '../../../scopeOfWorkV2TableCard/scopeOfWorkV2TableCard';
import { SOWLTCTableCard } from '../../../scopeOfWorkV2TableCard/scopeOfWorkLtcCatchupTable';
import { formatCurrency } from '@roc/ui/utils';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { Alert, AlertTitle } from '@material-ui/lab';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

const useStyles = makeStyles(theme =>
  createStyles({
    fieldSpacing: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    button: {
      marginRight: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    rowSpacing: {
      marginBottom: theme.spacing(2),
    },
    cardSpacing: {
      paddingRight: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    reviewAndSubmitContainer: {
      padding: theme.spacing(0, 4),
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(0, 1),
      },
    },
    box: {
      border: '2px #e0e0e0 solid',
      paddingLeft: '4px',
      marginTop: '10px',
    },
    boxProjectInfo: {
      border: '2px #e0e0e0 solid',
      paddingLeft: '4px',
      marginTop: '10px',
    },
    propertyLabel: {
      fontWeight: 'bold',
      fontSize: 14,
      paddingLeft: theme.spacing(1),
    },
    propertyText: {
      fontSize: 14,
      paddingRight: theme.spacing(2),
    },
    propertyTitle: {
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(4),
      paddingRight: theme.spacing(1),
    },
    subtitle: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    boxTotalCost: {
      backgroundColor: '#fdefbf',
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '10px',
    },
    projectInfoText: {
      fontSize: 14,
      paddingLeft: theme.spacing(1),
      marginRight: '5px',
    },
    totalSymbolsColor: {
      color: '#FDEFBF',
    },
    totalRow: {
      backgroundColor: '#FDEFBF',
    },
    projectTotalTitlesColor: {
      backgroundColor: '#f8f6f8',
    },
    table: {
      minWidth: 750,
      border: '2px #e0e0e0 solid',
      '& td': {
        height: '40px',
        padding: 0,
        paddingTop: '8px',
        paddingBottom: '8px',
      },
      '& th:first-child, td[rowSpan], $addNewItemRow td': {
        paddingLeft: theme.spacing(2),
        paddingRight: '3px',
      },
      '& table tr:last-child td, & table td[rowSpan]': {
        borderBottom: 'none',
      },
    },
    headerCell: {
      height: '41px',
      fontWeight: 'bold',
      marginRight: '3px',
      padding: 0,
    },
    headerCellProjectTotal: {
      height: '41px',
      fontWeight: 'bold',
      marginLeft: '3px',
    },
    info: {
      fontFamily: 'Oswald, sans-serif;',
      fontWeight: 'bold',
    },
    infoBottom: {
      fontFamily: 'Oswald, sans-serif;',
    },
    boxBudgetSummary: {
      border: '2px #e0e0e0 solid',
      padding: '4px',
    },
    budgetSummaryContainer: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    errorsContainer: {
      marginTop: '20px',
    },
    errorTitle: {
      fontWeight: 'bold'
    }
  })
);

interface ReviewAndSubmitProps {
  store: ScopeOfWorkV2FormBaseStore;
}

const ReviewAndSubmit = observer(({ store }: ReviewAndSubmitProps) => {
  const { categoryStore } = store;
  const classes = useStyles();
  const showReviewColumns = store.params?.isApprovalAllowed || store.params?.isReviewAllowed;
  const shouldShowCatchupInTable =
    categoryStore.sortedCategoriesNotEmpty.some(
      value => value.name === 'LTC Catchup'
    ) && store.ltcCatchupAmount;
  const ltcCatchupAmount = store.ltcCatchupAmount;
  const [errors, setErrors] = useState<any[]>([]);
  const [warnings, setWarnings] = useState<any[]>([]);

  useEffect(() => {
    const fetchErrors = async () => {
      await store.getSubmissionErrors()
      const errorsInStore = store.submissionErrors
      const warningsInStore = store.submissionWarnings
      setErrors(errorsInStore);
      setWarnings(warningsInStore);
    }
    fetchErrors();
  }, [store])

  const columns = [
    {
      name: 'Line Item',
      width: '20%',
    },
    {
      name: 'Detail',
      width: '10%',
    },
    {
      name: 'Cost',
      width: '10%',
    },
    {
      name: 'COMPLETED RENOVATIONS',
      width: '20%',
      align: 'center',
    },
    {
      name: 'RENO BUDGET',
      width: '10%',
      align: 'center',
    },
    {
      name: 'Description',
      width: '60%',
    },
  ];

  const columnsForReview = [
    {
      name: '',
      width: '5%',
    },
    {
      name: 'LINE ITEM',
      width: '10%',
    },
    {
      name: 'DETAIL',
      width: '10%',
    },
    {
      name: 'COST',
      width: '10%',
    },
    {
      name: '% COMPLETED RENOVATIONS',
      width: '20%',
      align: 'center',
    },
    {
      name: 'RENO BUDGET',
      width: '8%',
      align: 'center',
    },
    {
      name: 'DESCRIPTION',
      width: '8%',
    },
    {
      name: 'REVIEW STATUS',
      width: '10%',
    },
    {
      name: 'COMMENTS',
      width: '10%',
    },
  ];

  return (
    <Box className={classes.reviewAndSubmitContainer}>
      <Box>
        <Box>
          <Typography variant="h4">Review & Submit</Typography>
        </Box>
        <Typography variant="body1">
          Please review the information below before you submit this Scope of
          Work
        </Typography>
      </Box>
      <Box>
        <Typography className={classes.subtitle} color="primary" variant="h4">
          General Information
        </Typography>
      </Box>

      <Box className={classes.boxBudgetSummary}>
        <Box pt={2} pb={2}>
          <Typography className={classes.propertyTitle} variant="h5">
            Budget Summary
          </Typography>
          <Grid
            container
            spacing={4}
            className={classes.budgetSummaryContainer}
          >
            <Grid item xs={12} md={4}>
              <Typography variant="body1">Reno Budget</Typography>
              <Divider />
              <Typography variant="h5" className={classes.info}>
                {formatCurrency(
                  categoryStore.remainingReno - (ltcCatchupAmount ?? 0)
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="body1">Completed Renovations</Typography>
              <Divider />
              <Typography variant="h5" className={classes.info}>
                {formatCurrency(categoryStore.totalCapex)}
              </Typography>
            </Grid>
            {shouldShowCatchupInTable && (
              <Grid item xs={12} md={4}>
                <Typography variant="body1">LTC Catchup</Typography>
                <Divider />
                <Typography variant="h5" className={classes.info}>
                  {formatCurrency(ltcCatchupAmount)}
                </Typography>
              </Grid>
            )}
            <Grid item xs={12} md={4}>
              <Typography variant="body1">
                {!shouldShowCatchupInTable
                  ? 'Total Reno Budget'
                  : 'Project Total'}
              </Typography>
              <Divider />
              <Typography variant="h5" className={classes.info}>
                {formatCurrency(categoryStore.totalRemainingReno)}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Box className={classes.box}>
        <Box pt={2} pb={2}>
          <Typography className={classes.propertyTitle} variant="h5">
            Property Information
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={3}>
              <Typography className={classes.propertyLabel} variant="body1">
                Address
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Typography className={classes.propertyText} variant="body1">
                {store.getAddress()}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Typography className={classes.propertyLabel} variant="body1">
                Do you already own the property?
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Typography className={classes.propertyText} variant="body1">
                {store.form.fields.alreadyOwn.value ? 'Yes' : 'No'}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Typography className={classes.propertyLabel} variant="body1">
                Is there any change to the property's existing square footage or
                layout?
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Typography className={classes.propertyText} variant="body1">
                {store.form.fields.layoutChange.value ? 'Yes' : 'No'}
              </Typography>
            </Grid>
            {store.form.fields.describeRenovation.value.length > 0 && (
              <>
                <Grid item xs={12} md={6} lg={3}>
                  <Typography className={classes.propertyLabel} variant="body1">
                    Renovation description
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <Typography className={classes.propertyText} variant="body1">
                    {store.form.fields.describeRenovation.value
                      .map(item => item.value)
                      .join(', ')}
                  </Typography>
                </Grid>
              </>
            )}
            {store.form.fields.alreadyOwn.value && (
              <>
                <Grid item xs={12} md={6} lg={3}>
                  <Typography className={classes.propertyLabel} variant="body1">
                    Have you already made improvements to the property?
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <Typography className={classes.propertyText} variant="body1">
                    {store.form.fields.anyImprovements.value ? 'Yes' : 'No'}
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>
        </Box>
      </Box>

      <Box className={classes.boxProjectInfo}>
        <Box pt={2} pb={2}>
          <Typography className={classes.propertyTitle} variant="h5">
            Project Information
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={3}>
              <Typography className={classes.propertyLabel} variant="body1">
                Provide a narrative description of the proposed work
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={9}>
              <Typography className={classes.projectInfoText} variant="body1">
                {store.form.fields.projectDescription.value}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Box>
        <Typography className={classes.subtitle} color="primary" variant="h4">
          Scope of Work
        </Typography>
      </Box>
      {shouldShowCatchupInTable &&
        categoryStore.sortedCategoriesNotEmpty.map(category => (
          <>
            {category.name === 'LTC Catchup' && (
              <SOWLTCTableCard
                store={store}
                title={category.name}
                rows={category.subCategories}
                category={category}
                readonly={true}
                showReviewColumns={showReviewColumns}
              />
            )}
            <br />
          </>
        ))}
      {categoryStore.sortedCategoriesNotEmpty.map(category => (
        <>
          {category.name !== 'LTC Catchup' && (
            <SOWTableCard
              store={store}
              title={category.name}
              rows={category.subCategories}
              columns={showReviewColumns ? columnsForReview : columns}
              category={category}
              readonly={true}
              showReviewColumns={showReviewColumns}
            />
          )}
          <br />
        </>
      ))}

      <Grid item xs={12} md={12}>
        <Table className={classes.table}>
          <TableHead className={classes.projectTotalTitlesColor}>
            <TableRow>
              <TableCell width="40%" rowSpan={1}></TableCell>
              <TableCell
                align="center"
                className={classes.headerCell}
                width="20%"
              >
                COMPLETED RENOVATIONS
              </TableCell>
              <TableCell
                align="center"
                className={classes.headerCell}
                width="10%"
              >
                RENO BUDGET
              </TableCell>
              <TableCell align="center">
                {!shouldShowCatchupInTable
                  ? 'TOTAL RENO BUDGET'
                  : 'Project Total'}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow className={classes.totalRow}>
              <TableCell
                className={classes.headerCellProjectTotal}
                width="25%"
                rowSpan={1}
              >
                PROJECT TOTAL
              </TableCell>
              <TableCell align="center" width="20%">
                <Typography variant="h5" className={classes.infoBottom}>
                  {formatCurrency(categoryStore.totalCapex)}
                </Typography>
              </TableCell>
              <TableCell align="center" width="10%">
                <Typography variant="h5" className={classes.infoBottom}>
                  {formatCurrency(
                    categoryStore.remainingReno - (ltcCatchupAmount ?? 0)
                  )}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="h5" className={classes.infoBottom}>
                  {formatCurrency(categoryStore.totalRemainingReno)}
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        {
          errors?.length > 0 &&
          <ErrorAndWarningsSubmissionSOW errors={errors} severity={'error'} title={'Errors'} extraText={'These items must be adjusted prior to submitting this Scope of Work:'} />
        }
        {
          warnings?.length > 0 &&
          <ErrorAndWarningsSubmissionSOW errors={warnings} severity={'warning'} title={'Warnings'} extraText={'Please address these items. If you adjust the budget now, it will speed up the budget review process. You may be requested to adjust the budget during the review process if they are not addressed now.'} />
        }
      </Grid >
    </Box >
  );
});

const ErrorAndWarningsSubmissionSOW = observer(({ errors, severity, title, extraText }) => {
  const classes = useStyles();
  return (
    <Alert severity={severity} className={classes.errorsContainer}>
      <AlertTitle className={classes.errorTitle}>{title}</AlertTitle>
      <Grid container direction='column'>
        <Typography style={{ marginBottom: '10px' }}>{extraText}</Typography>
        {errors?.map((error) => {
          return (
            <Grid container spacing={1} direction='row' style={{ marginBottom: '5px', marginLeft: '20px' }}>
              <Grid container direction='row'>
                <FiberManualRecordIcon style={{ fontSize: '10px', marginRight: '10px', marginTop: '7px' }} />
                <Typography> {error.message} </Typography>
              </Grid>
              {error.bulletPointsMessage &&
                error.bulletPointsMessage.map(message => (
                  <Grid container style={{ marginLeft: '1px' }} direction='row'>
                    <ArrowRightIcon style={{ fontSize: '20px', marginTop: '3px' }} />
                    <Typography> {message} </Typography>
                  </Grid>
                ))
              }
            </Grid>
          )
        })}
      </Grid>
    </Alert>
  )
});

export default ReviewAndSubmit;