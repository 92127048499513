import { makeObservable } from "mobx";
import { LiquidityReportBaseStore } from "./LiquidityReportBaseStore";
import { LiquidityReportService } from "../services/liquidityReportService";

export class LiquidityReportStore extends LiquidityReportBaseStore {
  private liquidityReportService: LiquidityReportService;

  constructor(globalStore) {
    super(globalStore);
    this.liquidityReportService = new LiquidityReportService();

    makeObservable(this, {});
  }

  *submitLiquidityReport() {
    try {
      this.runFormValidationWithMessage();
      if (this.form.meta.isValid) {
        const data = this.getDataForSubmit();
        if (this.validateZipCodes(data)) {
          yield this.liquidityReportService.submitLiquidityReport(data);
          this.globalStore.notificationStore.showSuccessNotification({
            message: 'Your report request has been submitted; you will receive the details via email shortly',
          });
          this.resetStore();
        } else {
          this.globalStore.notificationStore.showErrorNotification({
            message: 'Please provide 5 digits zip codes',
          });
        }
      }
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'The report cannot be generated with the provided data',
      });
    }
  }

  *submitLiquidityReportHelp(messageValue: string) {
    try {
      if (messageValue !== "") {
        const data = {
          message: messageValue
        }
        yield this.liquidityReportService.submitLiquidityReportHelp(data);
        this.globalStore.notificationStore.showSuccessNotification({
          message: 'Report has been sent successfully',
        });
      } else {
        this.globalStore.notificationStore.showErrorNotification({
          message: 'Please provide a message',
        });
      }
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'An error occurred while submitting the data',
      });
    }
  }

  private validateZipCodes = (data) => {
    const zipCodes = data.zipcodes ? data.zipcodes.replace(/\s+/g, '').split(",") : [];
    return zipCodes.every(zipCode => zipCode.length === 5);
  }
}