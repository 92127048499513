import { Grid } from '@material-ui/core';
import {
  createTextField, createSliderField
} from '@roc/client-portal-shared/components';
import { FormStore } from '@roc/client-portal-shared/stores';
import { observer } from 'mobx-react';
import { SelectOriginatorsStore } from '../../stores/selectOriginatorStore';

interface OriginatorField {
  store: FormStore;
  selectOriginatorStore: SelectOriginatorsStore;
}

const field = component => {
  const WrappedComponent = observer(component);
  return props => (
    <Grid item xs={12}>
      <WrappedComponent {...props} />
    </Grid>
  );
};

export const LenderName = field(({ store }: OriginatorField) => {
  return createTextField({
    store,
    label: 'Name',
    fieldName: 'lenderName',
    testId: 'lenderName',
    disabled: true
  });
});

export const CompanyName = field(({ store }: OriginatorField) => {
  return createTextField({
    store,
    label: 'Company Name',
    fieldName: 'companyName',
    testId: 'companyName',
    disabled: true
  });
});

export const Percentage = field(({ store }: OriginatorField) => {
  return createSliderField({
    store,
    label: 'Commision Percentage',
    valueLabelDisplay: 'on',
    fieldName: 'percentage',
    testId: 'commission',
    max: 50
  });
});
