import { observer } from "mobx-react"
import { ButtonGroup, Grid, Theme, Typography } from '@material-ui/core';
import { Button } from '@roc/ui';
import { darken, makeStyles, createStyles } from '@material-ui/core/styles';


export interface ButtonGroupFilterProps {
  activeButton: any,
  setActiveButton: any,
  title?: string,
  name: string,
  setValue: any,
  options: any[]
  allowMultipleSelection?: boolean
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    activeButton: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.getContrastText(theme.palette.primary.main),
      '&:hover': {
        backgroundColor: darken(theme.palette.primary.main, 0.3),
        color: theme.palette.getContrastText(darken(theme.palette.primary.main, 0.3)),
      },
      height: '50px',
      fontSize: 'small',
    },
    button: {
      height: '50px',
      fontSize: 'small',
    }

  })
);


export const ButtonGroupFilter: React.FC<ButtonGroupFilterProps> = observer(({
  activeButton,
  setActiveButton,
  title,
  name,
  setValue,
  options,
  allowMultipleSelection = false
}) => {
  const classes = useStyles();

  const getClassName = (optionValue): string => {
    const isSingleValueActive = activeButton === optionValue;
    const isMultipleValuesActive = allowMultipleSelection && Array.isArray(activeButton) && optionValue.some(v => activeButton.includes(v));
    const isExactArrayMatch = Array.isArray(activeButton) &&
      activeButton.length === optionValue.length &&
      activeButton.every((v, i) => v === optionValue[i]);

    if (isSingleValueActive || isMultipleValuesActive || isExactArrayMatch) {
      return classes.activeButton;
    }

    return classes.button;
  };


  return (
    <Grid container spacing={1}>
      {title &&
        <Grid item xs={12} sm={2} style={{ alignContent: 'center' }}>
          <Typography variant='body1' align='left'>
            {title}
          </Typography>
        </Grid>
      }
      <Grid item xs={12} sm={title ? 10 : 12} style={{ textAlign: 'center' }}>
        <ButtonGroup variant="outlined" aria-label="Basic button group">
          {options.map((option) => (
            <Button
              key={option.value}
              testId={`${title}${option.value}`}
              className={getClassName(option.value)}
              onClick={() => setActiveButton(activeButton, option.value, name, setValue)}
            >
              {option.label}
            </Button>
          ))}
        </ButtonGroup>
      </Grid>
    </Grid>
  )
})