import { Box } from '@material-ui/core';
import { SelectField } from '@roc/ui';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useNewLoanApplicationStore } from '../hooks/useNewLoanApplicationStore';
import {
  LoanApplicationStep,
  loanTypeOptions,
} from '../utils/loanApplicationConstants';
import { LoanStep } from './loanApplicationComponents';

export const ChooseLoanTypeStep = observer(() => {
  const { newLoanApplicationStore } = useNewLoanApplicationStore();
  const { loanType } = newLoanApplicationStore;

  useEffect(() => {
    newLoanApplicationStore.reset();
  }, [])

  return (
    <LoanStep
      title={'Start Loan Application'}
      subtitle={'What kind of real estate loan are you considering?'}
      disableContinue={!loanType}
      onContinue={() =>
        newLoanApplicationStore.goToStep(LoanApplicationStep.ENTITY_INFORMATION)
      }
    >
      <SelectField
        label={'Select a Loan Type'}
        value={loanType}
        required={true}
        options={loanTypeOptions}
        onChange={value => newLoanApplicationStore.setLoanType(value)}
        variant={'outlined'}
        fullWidth
        testId={'loanType'}
      />
    </LoanStep>
  );
});
