import { Box, Grid, IconButton, InputAdornment, List, ListItem, ListItemIcon, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { isNotBlank, passwordRequirementFields } from '@roc/feature-utils';
import { Button, Card, TextField, usePaperStyles } from '@roc/ui';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { useChangePasswordStore } from '../hooks/useChangePasswordStore';
import { TwoFactor } from '@roc/feature-2fa';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  cardContainer: {
    border: 'none'
  },
  marginTopPadding: {
    marginTop: theme.spacing(2)
  },
  noPadding: {
    padding: theme.spacing(0)
  },
  success: {
    color: theme.palette.success.main
  }
}));

export const ChangePasswordCardContent = observer(() => {
  const { changePasswordStore } = useChangePasswordStore();
  const { form } = changePasswordStore;
  const { fields } = form;
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const classes = useStyles();
  const paperClasses = usePaperStyles();
  const [updatingPassword, setUpdatingPassword] = useState(false);

  const handleClickShowOldPassword = () => {
    setShowOldPassword(!showOldPassword);
  };

  const handleClickShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const keyPress = event => {
    if (event.key === 'Enter') {
      handleFormSubmit();
    }
  };

  const handleFormSubmit = () => {
    form.meta.isValid && changePasswordStore.onFormSubmit(fields.oldPassword.value, fields.newPassword.value);
  };

  function show() {
    setUpdatingPassword(true);
  }

  function hide() {
    setUpdatingPassword(false);
    changePasswordStore.reset();
  }

  return (
    <>
      <Grid container spacing={2}>
        {updatingPassword
          ? (
            <Grid item xs={12} md={6}>
              <TextField
                testId="oldPassword"
                fullWidth
                variant="outlined"
                label="Old Password"
                type={showOldPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowOldPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showOldPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                value={fields.oldPassword.value}
                onChange={e => changePasswordStore.onFieldChange(
                  'oldPassword',
                  e.target.value
                )}
                helperText={fields.oldPassword.error}
                error={isNotBlank(fields.oldPassword.error)}
              />
            </Grid>
          )
          : (
            <Grid item xs={12} md={12}>
              <Button
                variant="contained"
                color="primary"
                onClick={show}
                testId="update-password">
                Update Password
              </Button>
            </Grid>
          )}
      </Grid>
      {updatingPassword && <Grid container spacing={2} className={classes.marginTopPadding}>
        <Grid item xs={12} md={6}>
          <TextField
            testId="newPassword"
            fullWidth
            variant="outlined"
            label="New Password"
            type={showNewPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowNewPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showNewPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }}
            value={fields.newPassword.value}
            onChange={e => {
              changePasswordStore.onFieldChange(
                'newPassword',
                e.target.value
              );
              passwordRequirementFields.map(field =>
                changePasswordStore.onFieldChange(
                  field,
                  e.target.value
                )
              );
            }}
            onKeyDown={keyPress}
            helperText={fields.newPassword.error}
            error={isNotBlank(fields.newPassword.error)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            testId="confirmPassword"
            fullWidth
            variant="outlined"
            label="Confirm New Password"
            type={showConfirmPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowConfirmPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }}
            value={fields.confirmPassword.value}
            onChange={e => {
              changePasswordStore.onFieldChange(
                'confirmPassword',
                e.target.value
              );
              changePasswordStore.onFieldChange(
                'passwordsMatch',
                e.target.value
              );
            }}
            onKeyDown={keyPress}
            helperText={fields.confirmPassword.error}
            error={isNotBlank(fields.confirmPassword.error)}
          />
        </Grid>
      </Grid>}
      {updatingPassword && fields.newPassword.value !== '' && <Grid item xs={12}>
        <List component="nav" className={classes.marginTopPadding}>
          {passwordRequirementFields.map((field, index) => {
            const isError =
              !form.fields[field].value.replace(/\s/g, '') ||
              form.fields[field].error;
            return (
              <ListItem
                key={`${field}-${index}`}
                className={classes.noPadding}
              >
                <ListItemIcon>
                  {isError ? (
                    <RadioButtonUncheckedIcon fontSize="small" />
                  ) : (
                    <CheckCircleOutlineIcon
                      className={classes.success}
                      fontSize="small"
                    />
                  )}
                </ListItemIcon>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  className={!isError && classes.success}
                >
                  {form.fields[field].message}
                </Typography>
              </ListItem>
            );
          })}
        </List>
      </Grid>}
      {updatingPassword && <Grid item xs={12} container justifyContent="space-between">
        <Button
          variant="contained"
          color="secondary"
          className={classes.marginTopPadding}
          onClick={hide}
          testId="cancel"
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={classes.marginTopPadding}
          disabled={!form.meta.isValid}
          onClick={handleFormSubmit}
          testId="save"
        >
          Save Changes
        </Button>
      </Grid>}
    </>
  );
});

const ChangePassword = observer(({ title = 'Change Password' }) => {
  const { changePasswordStore } = useChangePasswordStore();
  const { form } = changePasswordStore;
  const { fields } = form;
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const classes = useStyles();
  const paperClasses = usePaperStyles();
  const [updatingPassword, setUpdatingPassword] = useState(false);

  const handleClickShowOldPassword = () => {
    setShowOldPassword(!showOldPassword);
  };

  const handleClickShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const keyPress = event => {
    if (event.key === 'Enter') {
      handleFormSubmit();
    }
  };

  const handleFormSubmit = () => {
    form.meta.isValid && changePasswordStore.onFormSubmit(fields.oldPassword.value, fields.newPassword.value);
  };

  function show() {
    setUpdatingPassword(true);
  }

  function hide() {
    setUpdatingPassword(false);
    changePasswordStore.reset();
  }

  return (
    <Card title={title} className={clsx(classes.marginTopPadding, paperClasses.shadowBorder, classes.cardContainer)}>
      <Box pb={3}>
        <ChangePasswordCardContent />
      </Box>
    </Card>
  );
});

export const Security = observer(() => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Box mt={2}>
          <TwoFactor />
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <ChangePassword />
      </Grid>
    </Grid>
  );
});
