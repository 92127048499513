import { Page } from "@roc/feature-app-core";
import { Route } from "react-router";
import { ManageBrokers } from "../manageBrokers";

export const getManageBrokerRoutesConfig = (basePath: string, baseUrl: string) => {
  return {
    manageBrokers: {
      path: `${basePath}/manage-brokers`,
      url: `${baseUrl}/manage-brokers`,
      documentTitle: 'Manage Brokers',
    },
  }
}

export const getManageBrokersRoutes = (basePath: string, baseUrl: string) => {
  const config = getManageBrokerRoutesConfig(basePath, baseUrl);
  return {
    manageBrokers: (
      <Route exact path={config.manageBrokers.path}>
        <Page routeDefinition={config.manageBrokers}>
          <ManageBrokers/>
        </Page>
      </Route>
    )
  }
}