import { observer } from 'mobx-react';
import { DataGrid as Grid } from '@roc/ui';
import { useLoanStore } from '../hooks/useLoanStore';

export const PropertiesGrid = observer(({ columns }) => {
  const { loanStore } = useLoanStore();

  const onSortChanged = params => {
    const sortModel = params.api.getSortModel();
    loanStore.propertiesGridStore.setSortModel(sortModel);
  };

  return (
    <Grid
      columns={columns}
      rows={loanStore.propertiesGridStore.gridData.data.rows}
      onSortChanged={onSortChanged}
      isLoading={loanStore.propertiesGridStore.isLoading}
      sortModel={loanStore.propertiesGridStore.sortModel}
      domLayout="autoHeight"
      paginationData={loanStore.propertiesGridStore.gridData.meta}
      setPageNumber={loanStore.propertiesGridStore.setPageNumber}
    />
  );
});
