import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import {
  ConfirmDialog,
  createCheckboxField,
  FileUpload,
  FileUploadModal,
} from '@roc/ui';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useLoanActionsStore } from '../hooks/useLoanActionsStore';

const useStyles = makeStyles(theme => ({
  container: {
    paddingBottom: theme.spacing(4),
  },
}));

export const UploadSignedClosingDocumentsModal = observer(
  ({ open, handleClose }) => {
    const classes = useStyles();
    const { loanActionsStore } = useLoanActionsStore();
    const { uploadSignedClosingDocsStore } = loanActionsStore;

    useEffect(() => {
      return () => uploadSignedClosingDocsStore.reset();
    }, []);

    const confirm = (uploads: FileUpload[]) => {
      uploadSignedClosingDocsStore.uploadSignedClosingDocuments(uploads);
    };

    return (
      <FileUploadModal
        title={'Upload Signed Closing Documents'}
        open={open}
        onClose={handleClose}
        onUpload={confirm}
        multiple
        top={
          <Grid container spacing={2} className={classes.container}>
            <Grid item xs={12}>
              <Typography>
                <b>
                  This will add a new document category on the "Signed Closing
                  Documents" section
                </b>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {createCheckboxField({
                store: uploadSignedClosingDocsStore,
                fieldName: 'removePreviousSignedClosingDocs',
                label:
                  'Remove previous uploaded documents on the selected section',
              })}
            </Grid>
          </Grid>
        }
      />
    );
  }
);
