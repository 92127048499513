import { Grid } from '@material-ui/core';
import { DashboardRounded, Home } from '@material-ui/icons';
import { useStyles } from '@material-ui/pickers/views/Calendar/SlideTransition';
import { useBaseStore } from '@roc/feature-app-core';
import { useBorrowerLoansStore } from '@roc/feature-borrower-loans';
import { DrawRequestShared, useDrawsStore } from '@roc/feature-draws';
import { useLoanRoutes } from '@roc/feature-loans-routes-config';
import { LoanDetailsPaperLayout } from '@roc/feature-loans-shared';
import { Paper } from '@roc/ui';
import { DrawSuccess } from '@roc/feature-draws';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

export const BorrowerDrawRequest = observer(() => {
  const { loanId } = useParams<{ loanId: string }>();
  const [showSuccessScreen, setShowSuccessScreen] = useState(false);

  const { globalStore } = useBaseStore();
  const { breadCrumbsStore } = globalStore;

  const { loanRoutesConfig } = useLoanRoutes();
  const { push } = useHistory();

  useEffect(() => {
    breadCrumbsStore.setBreadCrumbItems([
      {
        link: true,
        title: 'Dashboard',
        url: globalStore.routeBaseUrl,
        icon: <DashboardRounded />,
      },
      {
        link: true,
        title: `Loan# ${loanId}`,
        url: loanRoutesConfig.loans(loanId).url,
      },
      {
        link: false,
        title: 'Request Draw',
      },
    ]);
  });

  if (showSuccessScreen) {
    return (
      <LoanDetailsPaperLayout>
        <Grid container spacing={2}>
          <DrawSuccess />
        </Grid>
      </LoanDetailsPaperLayout>
    );
  } else {
    return (
      <LoanDetailsPaperLayout
        title="REQUEST DRAW"
        onBack={() => push(loanRoutesConfig.loans(loanId).children.draws.url)}
      >
        <Grid container spacing={2}>
          <DrawRequestShared setShowSuccessScreen={setShowSuccessScreen} />
        </Grid>
      </LoanDetailsPaperLayout>
    );
  }
});
