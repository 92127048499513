import { CloudUpload, PostAdd } from '@material-ui/icons';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import {
  LoanAction,
  LoanActionForms,
  useLoanActionsStore,
} from '@roc/feature-documents';
import { useLoanStore } from '@roc/feature-loans-shared';
import { FabActionsButton, FabActionsMenuItem } from '@roc/ui';
import { observer } from 'mobx-react';

interface ClosingAttorneyLoanActionsProps{
  version?: 'v1' | 'v2';
}

export const ClosingAttorneyLoanActions = observer(({ version = 'v1' }: ClosingAttorneyLoanActionsProps) => {
  const { loanStore } = useLoanStore();
  const { loanActionsStore } = useLoanActionsStore();
  const { loanDetails } = loanStore;

  function setAction(action: LoanAction) {
    loanActionsStore.setCurrentAction(action);
  }

  const { canMarkTitleCompanyContacted, canMarkClosingDocumentsSentForSigning } = loanActionsStore.closingStageActionsInfo || {};

  return (
    <>
      {loanActionsStore?.showActions && (
        <FabActionsButton onOpen={() => loanActionsStore.checkClosingStageActions()} version={version}>
          <FabActionsMenuItem
            icon={<CheckCircleIcon />}
            label={'Confirm title company contacted'}
            onClick={() => setAction(LoanAction.MARK_TITLE_COMPANY_CONTACTED)}
            disabled={!canMarkTitleCompanyContacted}
            tooltip={!canMarkTitleCompanyContacted ? 'Title company already contacted.' : ''}
          />
          <FabActionsMenuItem
            icon={<PostAdd />}
            label={'Estimate Funding Template Numbers'}
            onClick={() => loanActionsStore.openEstimateFundingTemplate()}
          />
          <FabActionsMenuItem
            icon={<CheckCircleIcon />}
            label={'Request Funding Template'}
            onClick={() => loanActionsStore.openExpectedClosingDateForm()}
          />
          <FabActionsMenuItem
            icon={<CloudUpload />}
            label={'Upload HUD'}
            onClick={() => setAction(LoanAction.UPLOAD_HUD)}
          />
          <FabActionsMenuItem
            icon={<CheckCircleIcon />}
            label={'Generate Closing Documents'}
            onClick={() => setAction(LoanAction.GENERATE_CLOSING_DOCUMENTS)}
          />
          <FabActionsMenuItem
            icon={<CloudUpload />}
            label={'Upload Closing Documents'}
            onClick={() => setAction(LoanAction.UPLOAD_CLOSING_DOCUMENTS)}
          />
          <FabActionsMenuItem
            icon={<CheckCircleIcon />}
            label={'Approve Entity Information'}
            onClick={() => setAction(LoanAction.APPROVE_ENTITY_INFORMATION)}
          />
          <FabActionsMenuItem
            icon={<CheckCircleIcon />}
            label={'Approve Title Information'}
            onClick={() => setAction(LoanAction.APPROVE_TITLE_INFORMATION)}
          />
          <FabActionsMenuItem
            icon={<CheckCircleIcon />}
            label={'Create In Snapdocs'}
            onClick={() => setAction(LoanAction.CREATE_IN_SNAPDOCS)}
            disabled={!!loanDetails?.snapdocsClosingData?.snapdocsClosingUuid}
          />
          <FabActionsMenuItem
            icon={<CloudUpload />}
            label={'Upload Docs To Snapdocs'}
            onClick={() => setAction(LoanAction.UPLOAD_DOCS_TO_SNAPDOCS)}
          />
          <FabActionsMenuItem
            icon={<CloudUpload />}
            label={'Confirm closing documents sent for signing'}
            onClick={() => setAction(LoanAction.MARK_CLOSING_DOCUMENTS_SENT_FOR_SIGNING)}
            disabled={!canMarkClosingDocumentsSentForSigning}
            tooltip={!canMarkClosingDocumentsSentForSigning ? 'Closing documents already sent for signing' : ''}
          />
          <FabActionsMenuItem
            icon={<CheckCircleIcon />}
            label={'Update Fedex Tracking Number'}
            onClick={() => setAction(LoanAction.UPDATE_FEDEX_TRACKING_NUMBER)}
          />
          <FabActionsMenuItem
            icon={<CheckCircleIcon />}
            label={'Request Closing Wire'}
            onClick={() => setAction(LoanAction.REQUEST_CLOSING_WIRE)}
          />
          <FabActionsMenuItem
            icon={<CloudUpload />}
            label={'Upload Signed Closing Documents'}
            onClick={() => setAction(LoanAction.UPLOAD_SIGNED_CLOSING_DOCUMENTS)}
          />
          <FabActionsMenuItem
            icon={<CheckCircleIcon />}
            label={'Mark Funded/Wire Completed'}
            onClick={() => setAction(LoanAction.MARK_FUNDED_WIRE_COMPLETED)}
          />
        </FabActionsButton>
      )}
      <LoanActionForms />
    </>
  );
});
