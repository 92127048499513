import { useBaseStore } from '@roc/feature-app-core';
import { getLoanRoutesConfig } from '@roc/feature-loans-routes-config';
import { getDashboardRoutes, getDashboardRoutesConfig } from '../routes/dashboardRoutes';
import { getServicerLoanRoutes } from '../routes/servicerLoanRoutes';

export const useServicerLoansRoutes = () => {
  const { globalStore } = useBaseStore();
  const { routeBasePath, routeBaseUrl } = globalStore;
  return {
    servicerLoanRoutes: getServicerLoanRoutes(
      routeBasePath,
      routeBaseUrl
    ),
    servicerLoanRoutesConfig: getLoanRoutesConfig(
      routeBasePath,
      routeBaseUrl
    ),
    dashboardRoutes: getDashboardRoutes(
      routeBasePath,
      routeBaseUrl
    ),
    dashboardRoutesConfig: getDashboardRoutesConfig(
      routeBasePath,
      routeBaseUrl
    ),
  };
};
