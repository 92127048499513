import { GENERIC_ERROR_MESSAGE } from '@roc/feature-utils';
import { ApiResponse, GlobalStore } from '@roc/feature-app-core';
import { action, computed, flow, makeObservable, observable } from 'mobx';
import { replacePlaceholders } from '../../utils/sendgridUtils';
import { SendgridService } from '../service/sendgridService';
import { SendBulkEmailFormStore } from './SendBulkEmailFormStore';
import {
  BORROWER_OBJECT_TYPE,
  BROKER_OBJECT_TYPE,
  MIXED_OBJECT_TYPE,
  TPO_OBJECT_TYPE,
} from 'libs/feature-backoffice-users/src/backOfficeUsers/components/leads/constants/constants';
import { normalizeHTMLTags } from '@roc/feature-utils';

const HTML_CONTENT_PLACEHOLDER = '{{{senderContent}}}';
const IMAGE_ONE_PLACEHOLDER = '{{{imageOne}}}';
const IMAGE_TWO_PLACEHOLDER = '{{{imageTwo}}}';

export class SendEmailsStore {
  private globalStore: GlobalStore;
  public sendBulkEmailFormStore: SendBulkEmailFormStore;
  sendgridService: SendgridService;
  ownerOptions = [];
  assignedTemplates = [];
  isTemplateAssigned = false;
  public sendToAll: boolean;
  public bulkEmails: any[];
  public listId: string;
  public allTemplates: [];
  public selectedTemplateOption: string;
  public templateOptions = [];
  public templateData;
  public previewHtml: string;
  public mockAttachments = [];
  public imageInsertions = [];
  public emailsSentSuccessfuly: boolean;
  public bombBombVideoLink: string;
  public sendToOptions: Array<{ label: string, value: string }>;
  constructor(globalStore) {
    this.globalStore = globalStore;
    this.sendgridService = new SendgridService();
    this.sendBulkEmailFormStore = new SendBulkEmailFormStore(globalStore);
    this.bulkEmails = [];
    this.listId = '';
    this.sendToAll = false;
    this.allTemplates = [];
    this.templateOptions = [];
    this.emailsSentSuccessfuly = false;
    this.sendToOptions = [];

    makeObservable(this, {
      getAllOwnerOptions: flow,
      sendEmails: flow,
      ownerOptions: observable,
      bulkEmails: observable,
      templateData: observable,
      previewHtml: observable,
      sendCorporateEmails: flow,
      setBulkEmails: action,
      removeBulkEmails: action,
      resetbulkEmails: action,
      setBulkEmailsFromGrid: action,
      setBulkEmailsFromMixedGrid: action,
      setAllBulkEmail: action,
      sendBulkEmail: flow,
      allTemplates: observable,
      getTemplateOptions: flow,
      selectedTemplateOption: observable,
      setSelectedTemplateOption: flow,
      templateOptions: observable,
      updatePreview: flow,
      handleModalClose: action,
      emailsSentSuccessfuly: observable,
      initSentToOptions: observable,
      sendToSelf: flow,
      bombBombVideoLink: observable,
      getContentText: computed
    });
  }

  *initSentToOptions(type) {
    switch (type) {
      case TPO_OBJECT_TYPE:
      case BROKER_OBJECT_TYPE:
      case BORROWER_OBJECT_TYPE:
        this.sendToOptions = [
          { label: 'All', value: 'all' },
          { label: 'Primary email', value: 'primary' },
          { label: 'Secondary email', value: 'secondary' },
        ];
        break;
      default:
        this.sendToOptions = [
          { label: 'All', value: 'all' },
          { label: 'Primary email', value: 'primary' },
          { label: 'Secondary email', value: 'secondary' },
          { label: 'Additional email 1', value: 'additional1' },
          { label: 'Additional email 2', value: 'additional2' },
          { label: 'Additional email 3', value: 'additional3' },
          { label: 'Additional email 4', value: 'additional4' },
          { label: 'Additional email 5', value: 'additional5' },
        ];
    }
  }

  *getAllOwnerOptions() {
    const response = yield this.sendgridService.getAllOwners();
    let ownerOptions = response?.data?.data;
    this.ownerOptions = ownerOptions.map(filteredOwner => ({
      value: filteredOwner.email,
      label: filteredOwner.originatorName,
    }));
    const allOption = {
      value: 'all',
      label: 'All',
    };
    this.ownerOptions.unshift(allOption);
  }

  *sendEmails(owners, audience, brand) {
    const audienceValues = audience.map(item => item.value);
    let response: ApiResponse;
    try {
      if (brand) {
        response = yield this.sendgridService.sendEmailByBrand(
          brand,
          audienceValues
        );
      } else {
        const ownerValues = owners.map(item => item.value);
        response = yield this.sendgridService.sendEmail(
          ownerValues,
          audienceValues
        );
      }
      if (response.data) {
        this.globalStore.notificationStore.showSuccessNotification({
          message:
            'Request is being processed. Results will reflect after a few minutes.',
        });
        this.isTemplateAssigned = true;
      }
    } catch (error) {
      const additionalDescription = 'Failed to send email';
      this.globalStore.notificationStore.showErrorNotification({
        message: additionalDescription,
      });
    }
  }

  *sendCorporateEmails(audience, brand) {
    const audienceValues = audience.map(item => item.value);
    let response: ApiResponse;
    try {
      response = yield this.sendgridService.sendCorporateEmails(
        brand,
        audienceValues
      );
      if (response.data) {
        this.globalStore.notificationStore.showSuccessNotification({
          message: 'Emails sent succesfully',
        });
        this.isTemplateAssigned = true;
      }
    } catch (error) {
      const additionalDescription = 'Failed to send email';
      this.globalStore.notificationStore.showErrorNotification({
        message: additionalDescription,
      });
    }
  }

  setBulkEmails(value, selected) {
    if (value != null && !selected) {
      this.removeBulkEmails(value);
    } else if (value != null) {
      this.bulkEmails.push(value);
    }
  }

  setAllBulkEmail(value) {
    this.bulkEmails = value;
  }

  resetbulkEmails() {
    this.bulkEmails = [];
  }

  setBulkEmailsFromGrid(selectedRecords, type: String) {
    this.bulkEmails = selectedRecords.map(record => {
      return {
        email: record.email,
        secondaryEmail: record.secondaryEmail,
        firstName: record.firstName,
        lastName: record.lastName,
        brokered: record.brokered,
        doNotSendEmail: record.doNotSendEmail
          ? record.doNotSendEmail
          : record.doNotEmail,
        id:
          type === BORROWER_OBJECT_TYPE ||
            type === TPO_OBJECT_TYPE ||
            type === BROKER_OBJECT_TYPE
            ? record.contactId
            : record.leadId,
        type,
      };
    });
  }
  setBulkEmailsFromMixedGrid(selectedRecords) {
    const uniqueIds = new Set();

    this.bulkEmails = selectedRecords
      .filter(record => {
        const recordId = record.leadId ? record.leadId : record.contactId;
        if (uniqueIds.has(recordId)) {
          return false;
        } else {
          uniqueIds.add(recordId);
          return true;
        }
      })
      .map(record => {
        return {
          email: record.email,
          secondaryEmail: record.secondaryEmail,
          firstName: record.firstName,
          lastName: record.lastName,
          brokered: record.brokered,
          doNotSendEmail: record.doNotSendEmail
            ? record.doNotSendEmail
            : record.doNotEmail,
          id: record.leadId ? record.leadId : record.contactId,
          type: MIXED_OBJECT_TYPE,
        };
      });
  }

  addStandaloneRecord(selectedRecord, type: String) {
    this.bulkEmails.push({
      email: selectedRecord.email,
      secondaryEmail: selectedRecord.secondaryEmail,
      firstName: selectedRecord.firstName,
      lastName: selectedRecord.lastName,
      id:
        type === BORROWER_OBJECT_TYPE ||
          type === TPO_OBJECT_TYPE ||
          type === BROKER_OBJECT_TYPE
          ? selectedRecord.contactId
          : selectedRecord.leadId,
      type,
    });
  }

  removeBulkEmails(value) {
    const indices = this.bulkEmails.reduce((acc, email, index) => {
      if (email === value) {
        acc.push(index);
      }
      return acc;
    }, []);
    indices.forEach(index => {
      this.bulkEmails.splice(index, 1);
    });
  }

  *sendBulkEmail(
    allRecords: boolean,
    userEmail: string,
    attachments: File[],
    sendTo: any,
    fromGrid: boolean,
    businessUnit: string
  ) {
    let fields = this.sendBulkEmailFormStore.form.fields;
    let userName = userEmail;
    if (sendTo[0].value == 'all') {
      sendTo = this.sendToOptions.filter(t => t.value != 'all');
    }
    const sendToValues = sendTo.map(option => option.value);
    try {
      const form = new FormData();
      if (attachments.length > 0) {
        attachments.forEach(attachment => form.append('files', attachment));
      }
      form.append('request', JSON.stringify({
        ownerEmail: userName,
        recipientList: this.bulkEmails,
        subject: fields.subject.value,
        contentText: normalizeHTMLTags(this.getContentText),
        listId: this.listId,
        sendToAll: this.sendToAll,
        allRecords: allRecords,
        mockAttachments: this.mockAttachments,
        sendTo: sendToValues,
        businessUnit: businessUnit,
        imageInsertions: this.imageInsertions
      }));
      yield this.sendgridService.sendLOEmails(form);
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Emails sent succesfully',
      });
      if (fromGrid) {
        this.emailsSentSuccessfuly = true;
      }
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Failed to send emails',
      });
    }
  }

  *sendToSelf(userName: string) {
    const userFullName = this.globalStore.lenderInfo
      ?.primaryRelationshipManagerName;
    const fields = this.sendBulkEmailFormStore.form.fields;
    const form = new FormData();
    form.append('request', JSON.stringify({
      ownerEmail: userName,
      recipientList: [{ email: userName, firstName: userFullName.split(' ')[0], lastName: userFullName.split(' ')[1] }],
      subject: fields.subject.value,
      contentText: normalizeHTMLTags(this.getContentText),
      listId: this.listId,
      sendToAll: this.sendToAll,
      allRecords: false,
      mockAttachments: this.mockAttachments,
      sendTo: ["primary"],
      imageInsertions: this.imageInsertions
    }));
    yield this.sendgridService.sendLOEmails(form).then(() =>
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Emails sent succesfully',
      })).catch(() => this.globalStore.notificationStore.showErrorNotification({
        message: 'Failed to send emails',
      }))
  }

  *getTemplateOptions() {
    try {
      const response = yield this.sendgridService.getAllTemplatesByUser();
      this.templateOptions = response.data.filter(element => element.type === 'email');
    } catch (error) {
      console.log(error);
    }
  }

  *SetAllTemplates(value) {
    this.allTemplates = value;
  }

  setSelectedTemplateOption(value) {
    this.selectedTemplateOption = value.label;
    this.sendBulkEmailFormStore.onFieldChange('contentText', value.value);
    this.sendBulkEmailFormStore.onFieldChange('subject', value.subject);
    this.sendBulkEmailFormStore.onFieldChange('templateId', value.templateId);
    this.bombBombVideoLink = value.bombBombVideoLink;
    this.mockAttachments = value?.templateAttachments;
    this.imageInsertions = value?.imageInsertions?.split(',') || [];
  }

  handleModalClose() {
    this.sendBulkEmailFormStore.reset();
    this.mockAttachments = [];
    this.bombBombVideoLink = null;
    this.imageInsertions = [];
  }

  reset() {
    this.sendBulkEmailFormStore.reset();
    this.bulkEmails = [];
    this.mockAttachments = [];
    this.listId = '';
    this.imageInsertions = [];
  }

  replaceImageInsertions = (imageInsertions: any[], htmlContent: string) => {
    if (imageInsertions?.length == 1) {
      return htmlContent.replace(IMAGE_ONE_PLACEHOLDER, imageInsertions[0])
        .replace(IMAGE_TWO_PLACEHOLDER, '');
    } else if (imageInsertions?.length == 2) {
      return htmlContent.replace(IMAGE_ONE_PLACEHOLDER, imageInsertions[0])
        .replace(IMAGE_TWO_PLACEHOLDER, imageInsertions[1]);
    } else {
      return htmlContent.replace(IMAGE_ONE_PLACEHOLDER, '')
        .replace(IMAGE_TWO_PLACEHOLDER, '');
    }
  }

  handleImagesForPreview = (content: string, imageInsertions: string[]) => {
    return content.concat(`<div style="width:100%;display:flex;">
                            ${imageInsertions[0] ? `<div style="flex:1;margin:0 5px;">
                              <img style="width:100%;object-fit:contain;" src="{{{imageOne}}}"/>
                            </div>`: ''}
                            ${imageInsertions[1] ? `<div style="flex:1;margin:0 5px;">
                              <img style="width:100%;object-fit:contain;" src="{{{imageTwo}}}"/>
                            </div>`: ''}
                          </div>`);
  }

  *updatePreview(ownerEmail, html, lead?, jsTemplateInsertions?: any[]) {
    try {
      this.previewHtml = '';
      if (!this.templateData) {
        const response = yield this.sendgridService.getTemplateDataFromSendgrid(
          ownerEmail
        );
        this.templateData = response.data.data;
      }
      let contentHtml = normalizeHTMLTags(html);
      if (contentHtml) {
        if (jsTemplateInsertions?.length > 0) {
          contentHtml = this.handleImagesForPreview(contentHtml, jsTemplateInsertions);
          const imageURLs = jsTemplateInsertions.map(fileUpload => URL.createObjectURL(fileUpload.file));
          contentHtml = this.replaceImageInsertions(imageURLs, contentHtml);
        } else if (this.imageInsertions?.length > 0) {
          contentHtml = this.handleImagesForPreview(contentHtml, this.imageInsertions);
          contentHtml = this.replaceImageInsertions(this.imageInsertions, contentHtml);
        }
        const html = this.templateData.html.replace(
          HTML_CONTENT_PLACEHOLDER,
          contentHtml
        ).replace(/{{#if [^}]+}}[\s\S]*?{{\/if}}/g, '');
        this.previewHtml = replacePlaceholders(html, {
          ...Object.keys(this.templateData).reduce((acc, k) => {
            acc[k.toLowerCase()] = this.templateData[k];
            return acc;
          }, {}),
          firstname: lead?.firstName,
          lastname: lead?.lastName,
          fullname: `${lead?.firstName} ${lead?.lastName}`,
        });
      }
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE,
      });
    }
  }

  get getContentText() {
    return this.bombBombVideoLink ? this.sendBulkEmailFormStore.getValue('contentText')?.concat(`<div style="margin:20px 0">${this.bombBombVideoLink}</div>`) : this.sendBulkEmailFormStore.getValue('contentText');
  }
}
