import { yesNoOptions } from '@roc/ui';

export const FROM_0_TO_5 = '0-5';
export const FROM_6_TO_10 = '6-10';
export const FROM_11_TO_15 = '11-15';
export const FROM_16_TO_20 = '16-20';
export const FROM_21_TO_25 = '21-25';
export const FROM_26_TO_30 = '26-30';
export const FROM_31_TO_35 = '31-35';
export const FROM_36_TO_40 = '36-40';
export const FROM_41_TO_45 = '41-45';
export const FROM_46_TO_50 = '46-50';
export const FROM_50_TO_55 = '50-55';
export const FROM_55_TO_60 = '55-60';
export const FROM_61_TO_65 = '61-65';
export const FROM_66_TO_70 = '66-70';
export const FROM_71_TO_75 = '71-75';
export const FROM_76_TO_80 = '76-80';
export const FROM_81_TO_85 = '81-85';
export const RATE_TYPE_5 = '5/6 ARM';
export const RATE_TYPE_7 = '7/6 ARM';
export const RATE_TYPE_10 = '10/6 ARM';
export const RATE_TYPE_30 = '30 Yr FRM';
export const RATE_TYPE_5_FRM = '5 Yr FRM';
export const RATE_TYPE_10_FRM = '10 Yr FRM';
export const BASE_RATES = 'baseRates';
export const MAX_RATES = 'maxRates';
export const RATES = 'yieldSpreadPremiumRates';
export const FULLY_AMORTIZING = 'fullyAmortizing';
export const FULLY_AMORTIZING_LABEL = 'Fully Amortizing';
export const PARTIAL_INTEREST = 'partialInterest';
export const PARTIAL_INTEREST_LABEL = 'Partial Interest Only';
export const PURCHASE = 'Purchase';
export const REFINANCE = 'Refinance';
export const SHARE_POINTS = 'sharePoints';
export const NO_ROC_POINTS = 'noRocPoints';
export const RATE_TERM_REFINANCE = 'Rate Term Refinance';
export const NO = 'N';
export const DSCR_EXPANDED = 'DSCR Expanded';
export const DSCR_STANDARD = 'DSCR Standard';
export const PRICING = 'Pricing';
export const INDEX = 'Index';
export const FIVE_YEAR_SWAP = '5/6 ARM & 7/6 ARM: 5 Year US Treasury';
export const TEN_YEAR_SWAP = '10/6 ARM & 30 yr FRM: 10 Year US Treasury';
export const FIVE_YEAR_US_TREASURY = 'All rate types: 5 Year US Treasury';
export const RATE_BUY_DOWN_OPTIONS = 'Rate Buy Down Options';
export const RATE_BUY_DOWN_OPTION_AVAILABLE = 'Buy Down Options Available';
export const RATE_BUY_DOWN_OPTION_NOT_AVAILABLE = 'Buy Down Options Not Available';
export const YIELD_SPREAD_PREMIUM = 'Yield Spread Premium';
export const YSP_AVAILABLE = 'YSP Available';
export const LIMITED_YSP_AVAILABLE = 'Limited YSP Available on a Case by Case Basis';
export const RATE_LOCK_TERMS = 'Rate Lock Terms*';
export const THIRTY_DAYS_FREE_GROSS_RATE = '30 day Free Gross Rate Lock Post Loan Approval';
export const SEVEN_DAYS_FREE_GROSS_RATE = '7 day Free Gross Rate Lock Post Loan Approval';
export const FOURTEEN_DAYS_FREE_GROSS_RATE = '14 day Free Gross Rate Lock Post Loan Approval';
export const PRODUCT_BOX = 'Product Box';
export const MIN_FICO = 'Min FICO';
export const DSCR_EXPANDED_MIN_FICO = '660';
export const DSCR_STANDARD_MIN_FICO = '680';
export const MAX_LOAN_AMOUNT = 'Max Loan Amount';
export const MAX_LOAN_AMOUNT_EXPANDED = 'All Property Types: $2,500,000';
export const MAX_LOAN_AMOUNT_STANDARD = 'All Property Types: $1,500,000';
export const MIN_DSCR = 'Min DSCR';
export const DSCR_1_10 = '1.10x';
export const DSCR_1_20 = '1.20x';
export const BORROWER_CITIZENSHIP_REQUIREMENTS = 'Borrower Citizenship Requirements';
export const US_NON_PERMANENT = 'U.S. Non-Permanent Residents/Foreign Nationals Allowed, Max 65% LTV';
export const NO_FOREIGN_NATIONALS_ALLOWED = 'No Foreign Nationals Allowed';
export const CLOSINGS = 'Closings';
export const WHITELABELING = 'Whitelabeling';
export const WHITELABELED_CLOSINGS_AVAILABLE_VIA_MERS = 'Whitelabeled Closings Available via MERS';
export const MERS_UNAVAILABLE = 'MERS unavailable, Closings under Loan Funder LLC';
export const ELIGIBLE_STATES = "Eligible States";
export const ELIGIBLE_STATES_DSCR_EXPANDED = "All US, except MN, ND, SD, UT & VT";
export const ELIGIBLE_STATES_DSCR_STANDARD = "All US, except AK, HI, MN, ND, SD, UT & VT";
export const RENTAL_INCOME_DOCUMENTATION = "Rental Income Documentation";
export const PROOF_OF_RECEIPT_RECOMMENDED = "Proof of Receipt of Rental Income for Most Recent 3 Months RECOMMENDED for refinances with leases >30 days**";
export const PROOF_OF_RECEIPT_REQUIRED = "Proof of Receipt of Rental Income for Most Recent 3 Months REQUIRED for refinances with leases >30 days";

export const rateColumns = [
  { label: 'Loan To Value', value: '' },
  { label: '0.01-5% LTV', value: '0-5' },
  { label: '5.01-10% LTV', value: '6-10' },
  { label: '10.01-15% LTV', value: '11-15' },
  { label: '15.01-20% LTV', value: '16-20' },
  { label: '20.01-25% LTV', value: '21-25' },
  { label: '25.01-30% LTV', value: '26-30' },
  { label: '30.01-35% LTV', value: '31-35' },
  { label: '35.01-40% LTV', value: '36-40' },
  { label: '40.01-45% LTV', value: '41-45' },
  { label: '45.01-50% LTV', value: '46-50' },
  { label: '50.01-55% LTV', value: '50-55' },
  { label: '55.01-60% LTV', value: '55-60' },
  { label: '60.01-65% LTV', value: '61-65' },
  { label: '65.01-70% LTV', value: '66-70' },
  { label: '70.01-75% LTV', value: '71-75' },
  { label: '75.01-80% LTV', value: '76-80' },
];

export const ltvMapper = {
  5: '0-5',
  10: '6-10',
  15: '11-15',
  20: '16-20',
  25: '21-25',
  30: '26-30',
  35: '31-35',
  40: '36-40',
  45: '41-45',
  50: '46-50',
  55: '50-55',
  60: '55-60',
  65: '61-65',
  70: '66-70',
  75: '71-75',
  80: '76-80',
  85: '81-85',
};

export const sfrRateTypesDisplayOrder = {
  [RATE_TYPE_5]: 0,
  [RATE_TYPE_7]: 1,
  [RATE_TYPE_10]: 2,
  [RATE_TYPE_30]: 3,
};

export const rateTypeOptions = [
  { label: RATE_TYPE_5, value: RATE_TYPE_5 },
  { label: RATE_TYPE_7, value: RATE_TYPE_7 },
  { label: RATE_TYPE_10, value: RATE_TYPE_10 },
  { label: RATE_TYPE_30, value: RATE_TYPE_30 },
];

// TODO: Verify this loan terms
export const rateTypeToLoanTerm = {
  [RATE_TYPE_5]: 30,
  [RATE_TYPE_7]: 30,
  [RATE_TYPE_10]: 30,
  [RATE_TYPE_30]: 30,
  [RATE_TYPE_5_FRM]: 30,
  [RATE_TYPE_10_FRM]: 30,
};

export const programTypeOptions = [
  { label: DSCR_EXPANDED, value: DSCR_EXPANDED },
  { label: DSCR_STANDARD, value: DSCR_STANDARD }
];

export const defaultRow = {
  [FROM_0_TO_5]: null,
  [FROM_6_TO_10]: null,
  [FROM_11_TO_15]: null,
  [FROM_16_TO_20]: null,
  [FROM_21_TO_25]: null,
  [FROM_26_TO_30]: null,
  [FROM_31_TO_35]: null,
  [FROM_36_TO_40]: null,
  [FROM_41_TO_45]: null,
  [FROM_46_TO_50]: null,
  [FROM_50_TO_55]: null,
  [FROM_55_TO_60]: null,
  [FROM_61_TO_65]: null,
  [FROM_66_TO_70]: null,
  [FROM_71_TO_75]: null,
  [FROM_76_TO_80]: null,
  [FROM_81_TO_85]: null,
};

export const loanPurposeDic = {
  Purchase: 'Purchase',
  'Rate Term Refinance': 'Rate Term Refi',
  'Cash-Out Refinance': 'Cash Out Refi',
};

export const sfrRateTypesPdf = {
  [RATE_TYPE_5]: 'arm5',
  [RATE_TYPE_7]: 'arm7',
  [RATE_TYPE_10]: 'arm10',
  [RATE_TYPE_30]: 'fix30',
  [RATE_TYPE_5_FRM]: 'frm5',
  [RATE_TYPE_10_FRM]: 'frm10',
};

export const mapAmortization = value => {
  if (value === FULLY_AMORTIZING) {
    return FULLY_AMORTIZING_LABEL;
  } else {
    return PARTIAL_INTEREST_LABEL;
  }
};

export const mapLoanPurpose = (loanPurpose, seekingCashOut, anyDebt) => {
  if (loanPurpose === PURCHASE) {
    return 'Purchase';
  } else if (
    loanPurpose === REFINANCE &&
    anyDebt === yesNoOptions[0].value &&
    seekingCashOut === yesNoOptions[1].value
  ) {
    return RATE_TERM_REFINANCE;
  } else {
    return 'Cash-Out Refinance';
  }
};

export const mapBooleanValue = value => {
  if (value === yesNoOptions[0].value) {
    return true;
  } else {
    return false;
  }
};

export const mapRateType = {
  [RATE_TYPE_5]: RATE_TYPE_5,
  [RATE_TYPE_7]: RATE_TYPE_7,
  [RATE_TYPE_10]: RATE_TYPE_10,
  "30 YR FRM": RATE_TYPE_30,
  "5 YR FRM": RATE_TYPE_5_FRM,
  "10 YR FRM": RATE_TYPE_10_FRM
};

export const CUSTOM_QUOTE_FEE_NAMES = {};

export const CUSTOM_QUOTE_FEE_CATEGORIES = {
  ROC: 'Capital Provider',
  LENDER: 'Lender'
};