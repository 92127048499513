import { Grid, Typography } from '@material-ui/core';
import { useDocumentStore } from '@roc/feature-documents';
import { observer } from 'mobx-react';
import React from 'react';
import { InsuranceForm } from './common/insuranceForm';
import { PropertyForm } from './common/propertyForm';

export const OtherInsurance = observer(() => {
  const {
    documentStore: { documentFormStore },
  } = useDocumentStore();
  const { insuranceProviderStore } = documentFormStore;
  const { selectedInsurance, properties } = insuranceProviderStore;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <InsuranceForm
          readOnly={!insuranceProviderStore.canEdit}
          store={selectedInsurance}
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="button">Collaterals</Typography>
          </Grid>
          {properties.map(property => (
            <Grid key={property.address} item xs={12}>
              <PropertyForm
                readOnly={!insuranceProviderStore.canEdit}
                store={property}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
});

export default OtherInsurance;
