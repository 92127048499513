import { Tooltip } from '@material-ui/core';
import { Help } from '@material-ui/icons';
import { StandardDialog, Button } from '@roc/ui';
import { ResolutionForm } from 'libs/feature-borrower-review/src/components/utils/resolutionForm';
import { useResolutionStore } from 'libs/feature-borrower-review/src/hooks/useResolutionStore';
import { observer } from 'mobx-react';

export type ResolutionItemProps = {
  open?: boolean;
  onClose?: () => void;
  loanDocumentId?: number;
};

export const ResolutionItem = observer((
  props: ResolutionItemProps
) => {
  const { open, onClose, loanDocumentId } = props;
  const { resolutionStore } = useResolutionStore();
  const { tooltTipTitle } = resolutionStore;
  const { readOnly } = resolutionStore;
  return (
    <StandardDialog
      open={open}
      title={
        <div>
          Provide Resolution
          <Tooltip
            placement="right"
            title={<p style={{ fontSize: '12px' }}> {tooltTipTitle} </p>}
          >
            <Help color="primary" style={{ fontSize: '18px', marginLeft: '3px', marginBottom: '10px' }} />
          </Tooltip>
        </div>}
      maxWidth="sm"
      handleClose={onClose}
      dialogContent={<div style={{ marginTop: '-40px' }}><ResolutionForm handleClose={onClose} loanDocumentId={loanDocumentId} readOnly={readOnly} /></div>}
    />
  );
});

export default ResolutionItem;
