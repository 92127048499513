import { GlobalStore } from '@roc/feature-app-core';
import { flow, makeObservable } from 'mobx';
import { OneToolService } from '../../services/oneToolService';
import { QuotesDashboardGridStore } from '../quotesDashboard/quotesDashboardGridStore';

export class QuotesDashboardStore {
  private globalStore: GlobalStore;
  private oneToolService: OneToolService;

  quotesDashboardGridStore: QuotesDashboardGridStore;

  constructor(globalStore: GlobalStore) {
    this.globalStore = globalStore;
    this.oneToolService = new OneToolService();
    this.quotesDashboardGridStore = new QuotesDashboardGridStore(globalStore);
    makeObservable(this, {
      deleteQuote: flow,
    });
  }

  *deleteQuote(quoteId) {
    try {
      yield this.oneToolService.deleteQuote(quoteId);
      yield this.quotesDashboardGridStore.fetchGridData();
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while removing quote',
      });
    }
  }
}
