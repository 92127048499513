export const propertyInfoFields = ['Beds', 'Baths', 'Land Area', 'Gross Living Area', 'Property Type', 'Zoning', 'Last Sold Date', 'Last Sold Price'];

export enum CellType {
  TEXT,
  DOLLAR_AMOUNT,
  NUMERIC,
  DATE
}

export const PropertyDataInfo = [
  { name: 'Beds',
    mapping: 'beds',
    value: 4,
    type: CellType.NUMERIC
  },
  { name: 'Baths',
    mapping: 'baths',
    value: 3,
    type: CellType.NUMERIC
  },
  { name:'Land Area',
    mapping: 'landArea',
    value: 2380,
    type: CellType.NUMERIC
  },
  { name: 'Gross Living Area',
    mapping: 'gla',
    value: 2830,
    type: CellType.NUMERIC
  },
  { name: 'Property Type',
    mapping: 'propertyType',
    value: 'Single Family',
    type: CellType.TEXT
  },
  { name: 'Zoning',
    mapping: 'zoning',
    value: 'zoning',
    type: CellType.TEXT
  },
  { name: 'Last Sold Date',
    mapping: 'lastSoldDate',
    value: '03/12/2023',
    type: CellType.DATE
  },
  { name: 'Last Sold Price',
    mapping: 'lastSoldPrice',
    value: 120000,
    type: CellType.DOLLAR_AMOUNT
  }
]