import {
  Box,


  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { useStore } from '@roc/client-portal-shared/hooks';
import { ThreadType } from '@roc/feature-types';
import { CLOSING, DRAWS, SERVICING, UNDERWRITING } from '@roc/feature-utils';
import { observer } from 'mobx-react';
import React, { useEffect, useMemo, useRef } from 'react';
import { useDocumentStore } from '../../documents/hooks/useDocumentStore';
import { useDocumentCommunicationStore } from '../hooks/useDocumentCommunicationStore';
import { ChatMessage } from './chatMessage';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    emptyMessageContainer: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    emptyMessageText: {
      fontSize: 24,
      color: theme.palette.grey[300],
    },
  })
);

export const ChatMessages = observer(() => {
  const endDivRef = useRef<HTMLDivElement>(null);
  const {
    userStore,
    drawStore,
  } = useStore();
  const { documentCommunicationStore } = useDocumentCommunicationStore();
  const { documentStore } = useDocumentStore();

  const { userInformation } = userStore;
  const { comments, thread } = documentCommunicationStore;
  const { displaySections } = documentStore;
  const drawList = drawStore.drawList ?? [];

  const classes = useStyles();

  const allSections = useMemo(() => {
    return [UNDERWRITING, CLOSING, DRAWS, SERVICING]
      .map(process =>
        documentCommunicationStore.getDisplaySections(process, drawList)
      )
      .reduce((a, b) => a.concat(b));
  }, [displaySections, drawList]);

  const findSectionName = comment => {
    return allSections.find(
      section =>
        section.drawId == comment.drawId &&
        section.sectionId == comment.sectionId &&
        section.borrowerId == comment.borrowerId &&
        section.collateralId == comment.collateralId &&
        section.sectionId == comment.sectionId
    )?.sectionName;
  };

  const findSectionNamesAndRemoveCommentsWithoutSection = comments => {
    return comments
      .map(comment => ({
        ...comment,
        sectionName: findSectionName(comment),
      }))
      .filter(comment => !!comment.sectionName);
  };

  useEffect(() => {
    if (!endDivRef.current) return;
    endDivRef.current.scrollIntoView({
      behavior: 'auto',
      block: 'end',
      inline: 'center',
    });
  }, [comments.length]);

  const filteredComments =
    thread.type === ThreadType.PROCESS
      ? findSectionNamesAndRemoveCommentsWithoutSection(comments)
      : comments;

  return comments.length > 0 ? (
    <>
      {filteredComments.map(comment => (
        <ChatMessage
          sectionName={comment.sectionName}
          key={comment.loanDocumentCommentId}
          name={comment.firstName + ' ' + (comment.lastName && comment.lastName[0] || '')}
          date={comment.creationDate}
          message={comment.commentText}
          isLoggedInUser={
            userInformation?.username === comment.commentUserEmail
          }
        />
      ))}
      <div ref={endDivRef} />
    </>
  ) : (
    <Box className={classes.emptyMessageContainer}>
      <Typography className={classes.emptyMessageText}>
        No messages here yet...
      </Typography>
    </Box>
  );
});
