import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import {
  Login,
  ForgotPassword,
  SuccessForgotPasswordEmail,
  CreateOrResetPassword,
  SuccessCreateOrResetPassword,
} from './../modules/login';
import { Unsubscribe } from '../modules/unsubscribe/unsubscribe';
import { UnsubscribeFromMonthlyReminders } from '../modules/unsubscribeFromReminders/unsubsribeFromMonthlyReminders';
import { LoanTerms, LoanTermsDocusignCallback } from '../modules/loanTerms';
import {
  TamariskAppraisalOrderPaymentContainerPublic,
  TamariskDocumentDocusignCallback,
} from '@roc/feature-tamarisk-payments';
import { ScopeOfWorkV2 } from '../modules/scopeOfWorkV2';
import { useAppRoutes, useStore } from '../hooks';
import { PasswordChangeType } from '../types';
import { BodyLayout, Page } from '@roc/feature-app-core';
import {
  CreditAuthorization,
  CreditAuthorizationDocusignCallback,
} from '../modules/creditAuthorization';
import {
  WireAuthorization,
  WireAuthorizationDocusignCallback,
} from '../modules/wireAuthorization';
import { insertIf, isHausUrl, isLocalHost, isPrivateLenderPortalUrl, isFacoUrl, isCivicUrl } from '@roc/client-portal-shared/utils';
import { isProduction } from '@roc/feature-utils';
import { observer } from 'mobx-react';
import DenialOfCredit from '../modules/denialOfCredit/denialOfCredit';
import DrawRequest from '../modules/drawRequest/drawRequest';
import { CookiePolicy, PrivacyPolicy, TermsAndConditions } from '../modules/policies/policies';
import { AutoLogin } from '../modules/login/components/autoLogin';
import { DocusignCallback } from '../modules/docusignCallback/docusignCallback';
import { useDocumentLibraryRoutes } from '@roc/feature-document-library-contentful';
import { PlaidLiquidityVerificationContainer } from '../modules/plaidLiquidityVerification';
import { ScopeOfWorkRevision } from '../modules/scopeofWorkRevision';
import ManageNotifications from '../modules/manageNotifications/component/manageNotifications';
import { BorrowerDetailsVerification, BorrowerDetailsVerificationDocusignCallback, BorrowerDetailsVerificationReminderEmail } from '@roc/feature-borrowers';
import { TamariskAppraisalOrderPaymentContainer } from '@roc/feature-tamarisk-payments';
import { Login2FA } from '../modules/login/login2FA';
import { Logout } from '../modules/logout/logout';
import { ArrOrderPaymentContainer } from '../modules/arrPaymentModule/arrOrderPaymentContainer';
import { ACHFormPage, ACHDocusign } from '@roc/feature-plaid';
import MobileTruliooVerification from '../modules/login/mobileTruliooVerification';
import { CdaOrderPaymentContainer } from '../modules/cdaPaymentModule';
import { getValuationReviewInternalRoutes } from '@roc/feature-valuation-review-design';
import { LenderBankInfoCallBackSuccess } from '@roc/feature-backoffice-users';
import { ReferralAuthPage, ReferralFeeDocusignCallback } from '@roc/feature-referral';
import { PublicBorrowerSetupDocusignCallback } from 'libs/feature-borrowers/src/borrowerSetupToDo';
import { PublicBorrowerSetupToDo } from '../modules/borrowerSetupToDo/publicBorrowerSetupToDo';
import { LoanFastrackDocumentDocusignCallback, LoanFastrackPayments } from '@roc/feature-loan-fasttrack';
import { BorrowerFixFlipApplication, LandingPage, PublicContainerWithLogo, BorrowerSinglePropertyRentalLoanApplication, BorrowerGroundUpApplication } from '@roc/feature-borrower-loan-submission';
import { PublicScopeOfWorkForm } from '@roc/feature-sow-v2';
import { Appraisal1004dPaymentContainer } from '../modules/appraisal1004dPaymentModule';

const getPublicRoutes = (publicRoutes, isRegistrationEnabled: boolean) => {
  return [
    ...insertIf(isLocalHost() || isHausUrl() || isPrivateLenderPortalUrl() || isFacoUrl() || isCivicUrl(), [
      <Route exact path={publicRoutes.privacyPoicy.path}>
        <Page routeDefinition={publicRoutes.privacyPoicy}>
          <PrivacyPolicy />
        </Page>
      </Route>,
      <Route exact path={publicRoutes.cookiePoicy.path}>
        <Page routeDefinition={publicRoutes.cookiePoicy}>
          <CookiePolicy />
        </Page>
      </Route>,
      <Route exact path={publicRoutes.termsAndConditions.path}>
        <Page routeDefinition={publicRoutes.termsAndConditions}>
          <TermsAndConditions />
        </Page>
      </Route>,
    ]),
    <Route exact path={publicRoutes.login.path}>
      <Page routeDefinition={publicRoutes.login}>
        <Login />
      </Page>
    </Route>,
    <Route exact path={publicRoutes.unsubscribe.path}>
      <Page routeDefinition={publicRoutes.unsubscribe}>
        <Unsubscribe />
      </Page>
    </Route>,
    <Route exact path={publicRoutes.unsubscribeFromMonthlyReminders.path}>
      <Page routeDefinition={publicRoutes.unsubscribeFromMonthlyReminders.path}>
        <UnsubscribeFromMonthlyReminders />
      </Page>
    </Route>,
    <Route exact path={publicRoutes.twoFactorAuthentication.path}>
      <Page routeDefinition={publicRoutes.twoFactorAuthentication}>
        <Login2FA />
      </Page>
    </Route>,
    <Route exact path={publicRoutes.logout.path}>
      <Page routeDefinition={publicRoutes.logout}>
        <Logout />
      </Page>
    </Route>,
    <Route exact path={publicRoutes.forgotpassword.path}>
      <Page routeDefinition={publicRoutes.forgotpassword}>
        <ForgotPassword />
      </Page>
    </Route>,
    // ...insertIf(isRegistrationEnabled, [
    //   <Route exact path={publicRoutes.register.path}>
    //     <Page routeDefinition={publicRoutes.register}>
    //       <Register />
    //     </Page>
    //   </Route>,
    //   <Route exact path={publicRoutes.successRegistration.path}>
    //     <Page routeDefinition={publicRoutes.successRegistration}>
    //       <SuccessNewUserRegistration />
    //     </Page>
    //   </Route>,
    // ]),
    <Route exact path={publicRoutes.successforgotpasswordemail.path}>
      <Page routeDefinition={publicRoutes.successforgotpasswordemail}>
        <SuccessForgotPasswordEmail />
      </Page>
    </Route>,
    <Route exact path={publicRoutes.updatepassword.path}>
      <Page routeDefinition={publicRoutes.updatepassword}>
        <CreateOrResetPassword passwordChangeType={PasswordChangeType.UPDATE} />
      </Page>
    </Route>,
    <Route exact path={publicRoutes.successupdatepassword.path}>
      <Page routeDefinition={publicRoutes.successupdatepassword}>
        <SuccessCreateOrResetPassword
          passwordChangeType={PasswordChangeType.UPDATE}
        />
      </Page>
    </Route>,
    <Route exact path={publicRoutes.createpassword.path}>
      <Page routeDefinition={publicRoutes.createpassword}>
        <CreateOrResetPassword passwordChangeType={PasswordChangeType.CREATE} />
      </Page>
    </Route>,
    <Route exact path={publicRoutes.createpasswordMigratedUser.path}>
      <Page routeDefinition={publicRoutes.createpasswordMigratedUser}>
        <CreateOrResetPassword
          passwordChangeType={PasswordChangeType.CREATE_MIGRATED_USER}
        />
      </Page>
    </Route>,
    <Route exact path={publicRoutes.successcreatepassword.path}>
      <Page routeDefinition={publicRoutes.successcreatepassword}>
        <SuccessCreateOrResetPassword
          passwordChangeType={PasswordChangeType.CREATE}
        />
      </Page>
    </Route>,
    <Route exact path={publicRoutes.loanTerms.path}>
      <Page routeDefinition={publicRoutes.loanTerms}>
        <LoanTerms />
      </Page>
    </Route>,
    <Route exact path={publicRoutes.tamariskPayments.path}>
      <TamariskAppraisalOrderPaymentContainerPublic />
    </Route>,
    <Route exact path={publicRoutes.loanFastrackPayments.path}>
      <LoanFastrackPayments />
    </Route>,
    <Route exact path={publicRoutes.loanTermsDocusignCallback.path}>
      <Page routeDefinition={publicRoutes.loanTermsDocusignCallback}>
        <LoanTermsDocusignCallback />
      </Page>
    </Route>,
    <Route exact path={publicRoutes.tamariskDocumentDocusignCallback.path}>
      <TamariskDocumentDocusignCallback />
    </Route>,
    <Route exact path={publicRoutes.loanFastrackDocumentDocusignCallback.path}>
      <LoanFastrackDocumentDocusignCallback />
    </Route>,
    <Route exact path={publicRoutes.creditAuthorization.path}>
      <Page routeDefinition={publicRoutes.creditAuthorization}>
        <CreditAuthorization />
      </Page>
    </Route>,
    <Route exact path={publicRoutes.wireAuthorization.path}>
      <Page routeDefinition={publicRoutes.wireAuthorization}>
        <WireAuthorization />
      </Page>
    </Route>,
    <Route exact path={publicRoutes.mobileTruliooVerification.path}>
      <Page routeDefinition={publicRoutes.mobileTruliooVerification}>
        <MobileTruliooVerification />
      </Page>
    </Route>,
    <Route exact path={publicRoutes.wireAuthorizationDocusignCallback.path}>
      <Page routeDefinition={publicRoutes.wireAuthorizationDocusignCallback}>
        <WireAuthorizationDocusignCallback />
      </Page>
    </Route>,
    <Route exact path={publicRoutes.creditAuthorizationDocusignCallback.path}>
      <Page routeDefinition={publicRoutes.creditAuthorizationDocusignCallback}>
        <CreditAuthorizationDocusignCallback />
      </Page>
    </Route>,
    <Route exact path={publicRoutes.scopeOfWorkV2.path}>
      <Page routeDefinition={publicRoutes.scopeOfWorkV2}>
        <ScopeOfWorkV2 />
      </Page>
    </Route>,
    <Route exact path={publicRoutes.createScopeOfWork.path}>
      <Page routeDefinition={publicRoutes.createScopeOfWork}>
        <PublicScopeOfWorkForm />
      </Page>
    </Route>,
    <Route exact path={publicRoutes.createScopeOfWorkV2.path}>
      <Page routeDefinition={publicRoutes.createScopeOfWorkV2}>
        <PublicScopeOfWorkForm />
      </Page>
    </Route>,
    <Route exact path={publicRoutes.denialOfCredit.path}>
      <Page routeDefinition={publicRoutes.denialOfCredit}>
        <DenialOfCredit />
      </Page>
    </Route>,
    <Route exact path={publicRoutes.drawRequest.path}>
      <Page routeDefinition={publicRoutes.drawRequest}>
        <DrawRequest />
      </Page>
    </Route>,
    <Route exact path={publicRoutes.docusignCallback.path}>
      <Page routeDefinition={publicRoutes.docusignCallback}>
        <DocusignCallback />
      </Page>
    </Route>,
    <Route exact path={publicRoutes.plaidLiquidityVerification.path}>
      <Page routeDefinition={publicRoutes.plaidLiquidityVerification}>
        <PlaidLiquidityVerificationContainer />
      </Page>
    </Route>,
    <Route exact path={publicRoutes.scopeOfWorkRevision.path}>
      <Page routeDefinition={publicRoutes.scopeOfWorkRevision}>
        <ScopeOfWorkRevision />
      </Page>
    </Route>,
    <Route exact path={publicRoutes.manageEmailSubscriptions.path}>
      <Page routeDefinition={publicRoutes.manageEmailSubscriptions}>
        <ManageNotifications />
      </Page>
    </Route>,
    // <Route exact path={publicRoutes.borrowerDetailsVerification.path}>
    //   <Page routeDefinition={publicRoutes.borrowerDetailsVerification}>
    //     <BorrowerDetailsVerification />
    //   </Page>
    // </Route>,
    // <Route exact path={publicRoutes.borrowerDetailsVerificationDocusignCallback.path}>
    //   <Page routeDefinition={publicRoutes.borrowerDetailsVerificationDocusignCallback}>
    //     <BorrowerDetailsVerificationDocusignCallback />
    //   </Page>
    // </Route>,
    <Route exact path={publicRoutes.unsubscribedFromDailyReminders.path}>
      <Page routeDefinition={publicRoutes.unsubscribedFromDailyReminders}>
        <BorrowerDetailsVerificationReminderEmail />
      </Page>
    </Route>,
    <Route exact path={publicRoutes.loanApplication.path}>
      <Page routeDefinition={publicRoutes.loanApplication}>
        <LandingPage currentRoutes={publicRoutes.loanApplication} />
      </Page>
    </Route>,
    <Route exact path={publicRoutes.loanApplication.children.fixFlip.path}>
      <Page routeDefinition={publicRoutes.loanApplication.children.fixFlip}>
        <BorrowerFixFlipApplication currentRoutes={publicRoutes.loanApplication} />
      </Page>
    </Route>,
    // <Route exact path={publicRoutes.loanApplication.children.groundUp.path}>
    //   <Page routeDefinition={publicRoutes.loanApplication.children.groundUp}>
    //     <BorrowerGroundUpApplication currentRoutes={publicRoutes.loanApplication} />
    //   </Page>
    // </Route>,
    <Route exact path={publicRoutes.loanApplication.children.singleProperty.path}>
      <Page routeDefinition={publicRoutes.loanApplication.children.singleProperty}>
        <BorrowerSinglePropertyRentalLoanApplication currentRoutes={publicRoutes.loanApplication} />
      </Page>
    </Route>,
    <Route exact path={publicRoutes.applyForALoan.path}>
      <Page routeDefinition={publicRoutes.applyForALoan}>
        <PublicContainerWithLogo>
          <LandingPage currentRoutes={publicRoutes.applyForALoan} />
        </PublicContainerWithLogo>
      </Page>
    </Route>,
    <Route exact path={publicRoutes.applyForALoan.children.fixFlip.path}>
      <Page routeDefinition={publicRoutes.applyForALoan.children.fixFlip}>
        <PublicContainerWithLogo>
          <BorrowerFixFlipApplication currentRoutes={publicRoutes.applyForALoan} />
        </PublicContainerWithLogo>
      </Page>
    </Route>,
    <Route exact path={publicRoutes.applyForALoan.children.groundUp.path}>
      <Page routeDefinition={publicRoutes.applyForALoan.children.groundUp}>
        <PublicContainerWithLogo>
          <BorrowerGroundUpApplication currentRoutes={publicRoutes.applyForALoan} />
        </PublicContainerWithLogo>
      </Page>
    </Route>,
    <Route exact path={publicRoutes.applyForALoan.children.singleProperty.path}>
      <Page routeDefinition={publicRoutes.applyForALoan.children.singleProperty}>
        <PublicContainerWithLogo>
          <BorrowerSinglePropertyRentalLoanApplication currentRoutes={publicRoutes.applyForALoan} />
        </PublicContainerWithLogo>
      </Page>
    </Route>,
    <Route exact path={publicRoutes.cdaPayments.path}>
      <CdaOrderPaymentContainer />
    </Route>,
    <Route exact path={publicRoutes.arrPayments.path}>
      <ArrOrderPaymentContainer />
    </Route>,
    <Route exact path={publicRoutes.appraisal1004dPayments.path}>
      <Appraisal1004dPaymentContainer />
    </Route>,
    <Route exact path={publicRoutes.achFormDocusignCallback.path}>
      <ACHDocusign />
    </Route>,
    <Route exact path={publicRoutes.achForm.path}>
      <ACHFormPage />
    </Route>,
    <Route exact path={publicRoutes.referralFeeAuthDocusign.path}>
      <ReferralFeeDocusignCallback />
    </Route>,
    <Route exact path={publicRoutes.referralFeeAuth.path}>
      <ReferralAuthPage />
    </Route>,
    <Route exact path={publicRoutes.borrowerDetailsVerification.path}>
      <Page routeDefinition={publicRoutes.borrowerDetailsVerification}>
        <PublicBorrowerSetupToDo />
      </Page>
    </Route>,
    <Route exact path={publicRoutes.borrowerDetailsVerificationDocusignCallback.path}>
      <Page routeDefinition={publicRoutes.borrowerDetailsVerificationDocusignCallback}>
        <PublicBorrowerSetupDocusignCallback />
      </Page>
    </Route>,
  ];
};
export const UnAuthenticatedView = observer(() => {
  const { publicRoutes } = useAppRoutes();
  const { globalStore, registerStore } = useStore();

  const getRedirectToUrl = () => {
    let _url = publicRoutes.login.url;
    return globalStore.redirectPath
      ? `${_url}?redirectPath=${globalStore.redirectPath}`
      : _url;
  };

  const { documentLibraryRoutes } = useDocumentLibraryRoutes();

  const routes = [
    ...getPublicRoutes(publicRoutes, registerStore.isRegistrationEnabled),
    documentLibraryRoutes.documentLibraryDocument,
    documentLibraryRoutes.documentLibraryDocumentPreview
  ];

  if (!isProduction) {
    const valuationReviewInternalRoutes = getValuationReviewInternalRoutes(globalStore.routeBasePath, globalStore.routeBaseUrl);
    routes.push(valuationReviewInternalRoutes.valuationReview);
    routes.push(valuationReviewInternalRoutes.valuationReview2);
  }

  return (
    <BodyLayout>
      <Switch>
        <Route exact path={["/", "/autologin"]}>
          <AutoLogin />
        </Route>
        {React.Children.toArray(routes.map(x => x))}
        <Route
          render={() => {
            return <Redirect to={getRedirectToUrl()} />;
          }}
        />
      </Switch>
    </BodyLayout>
  );
});
