import {
  Button,
  createSelectField,
  FileUpload,
  FileUploadModal,
  StandardDialog,
} from '@roc/ui';
import { useDocumentStore } from '@roc/feature-documents';
import { Document } from '@roc/feature-types';
import { useEffect, useState } from 'react';
import { Divider, Grid } from '@material-ui/core';
import { observer } from 'mobx-react';
import { Alert } from '@material-ui/lab';
import { UNDERWRITING } from '@roc/feature-utils';

type Props = {
  document: Document;
};

const idTypeOptions = [
  { label: 'Please Select', value: '' },
  {
    label: "US Driver's License / Government ID",
    value: "US Driver's License / Government ID",
  },
  { label: 'US Passport', value: 'US Passport' },
  { label: 'Green Card', value: 'Green Card' },
  { label: 'Visa', value: 'Visa' },
  { label: 'Non-US Passport', value: 'Non-US Passport' },
];

export const PersonalIdentificationForm = observer((props: Props) => {
  const [idType, setIdType] = useState('');
  const { documentStore } = useDocumentStore();
  const [isUploadOpen, setUploadOpen] = useState(false);
  const { documentFormStore } = documentStore;
  const { personalIdentificationFormStore } = documentFormStore;
  const store = personalIdentificationFormStore;
  const document = props.document;

  const canDownload = !!document.dropboxPath;

  const message = !canDownload
    ? `${document.documentName} has not been uploaded`
    : `${document.documentName} was last uploaded at ${document.lastUpdateDate}`;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {createSelectField({
          store,
          fieldName: 'idType',
          testId: 'idType',
          label: 'ID Type',
          options: idTypeOptions,
        })}
      </Grid>
      <Grid item xs={12}>
        <Alert severity="info">{message}</Alert>
      </Grid>
      <Grid item xs={12}>
        <Button
          style={{ marginRight: '8px' }}
          onClick={() => setUploadOpen(true)}
          color="primary"
          variant="outlined"
          testId="upload"
        >
          Upload
        </Button>
        <Button
          variant="outlined"
          color="primary"
          disabled={!canDownload}
          onClick={() => documentStore.downloadDocument(document)}
          testId="download"
        >
          Download
        </Button>
      </Grid>
      <FileUploadModal
        title={`Upload: ${document.documentName}`}
        open={isUploadOpen}
        onClose={() => setUploadOpen(false)}
        onUpload={(uploads: FileUpload[]) =>
          documentStore.uploadDocument(uploads[0].file, document, UNDERWRITING)
        }
      />
    </Grid>
  );
});
