import { Avatar, Box, Grid, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Launch, Room } from '@material-ui/icons';
import { useLoanStore } from '@roc/feature-loans';
import { BooleanChip, GoogleMap, IconButton, StandardDialog } from '@roc/ui';
import { formatCurrency as _formatCurrency, formatNumberWithCommas } from '@roc/ui/utils';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { CustomCard, CustomCardDividers, CustomCardRow } from '../customCard';
import { LoanSubType } from '@roc/feature-utils';

const formatCurrency = value => (value ? _formatCurrency(value) : null);

const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
  icon: {
    color: theme.palette.text.secondary,
    fontSize: '1.25rem',
  },
  mapStyles: {
    position: `relative`,
    width: `100%`,
    height: `400px`,
  },
}));

export const PropertiesCard = observer(() => {
  const { loanStore } = useLoanStore();
  const { loanDetails } = loanStore;
  const properties = loanDetails?.properties ?? [];
  const loanSubType = loanDetails?.loanSubType;

  return (
    <CustomCard title="Properties" paperStyle={{
      height: '100%'
    }}>
      <Grid container spacing={2}>
        {properties.map(property => (
          <Grid item xs={12}>
            <Property property={property} loanSubType={loanSubType} />
          </Grid>
        ))}
      </Grid>
    </CustomCard>
  );
});

const Property = observer(({ property, loanSubType }) => {
  const classes = useStyles();
  const [showDialog, setShowDialog] = useState(false);
  return (
    <Box display="flex" alignItems="center">
      <Avatar className={classes.avatar}>
        <Room />
      </Avatar>
      <Box pl={2} flex={1}>
        <Typography>{property?.address}</Typography>
      </Box>
      <IconButton
        testId="open-property-link"
        onClick={() => setShowDialog(true)}
      >
        <Launch className={classes.icon} />
      </IconButton>
      <PropertyDetailsDialog
        open={showDialog}
        onClose={() => setShowDialog(false)}
        property={property}
        loanSubType={loanSubType}
      />
    </Box>
  );
});

const PropertyDetailsDialog = observer(({ open, onClose, property, loanSubType }) => {
  const classes = useStyles();
  const dialogContent = (
    <Grid container direction="row" spacing={4}>
      {property.latitude && property.longitude ? (
        <Grid item xs={12}>
          <GoogleMap
            mapStyles={classes.mapStyles}
            latitude={property.latitude}
            longitude={property.longitude}
          />
        </Grid>
      ) : null}
      <Grid item xs={12} md={6} lg={4}>
        <CustomCardDividers>
          <CustomCardRow
            label={'Acquisition'}
            value={property.propertyOwnership}
          />
          <CustomCardRow
            label={'Purchase Price'}
            value={formatCurrency(property.purchasePrice)}
          />
          <CustomCardRow
            label={'Reno Budget'}
            value={formatCurrency(property.renovationBudget)}
          />
          <CustomCardRow
            label={'Purchase Date'}
            value={property.purchaseDate}
          />
          <CustomCardRow
            label={'Property sourcing'}
            value={property.propertySourcing}
          />
          <CustomCardRow
            label={'Units'}
            value={property.units}
          />
          {property.wholesalerPurchase && (
            <CustomCardRow
              label={'Sourcing'}
              value={
                <Typography variant="body2" color="textSecondary" component="p">
                  {property.wholesalerPurchase}
                </Typography>
              }
            />
          )}
          {property.propertySourcing === 'Other' && (
            <CustomCardRow
              label={'Explanation'}
              value={
                <Typography variant="body2" color="textSecondary" component="p">
                  {' '}
                  {property.propertySourcingExplanation}{' '}
                </Typography>
              }
            />
          )}
          {property.wholesalerPurchase === 'Other' && (
            <CustomCardRow
              label={'Explanation'}
              value={
                <Typography variant="body2" color="textSecondary" component="p">
                  {property.propertySourcingExplanation}
                </Typography>
              }
            />
          )}
        </CustomCardDividers>
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <CustomCardDividers>
          <CustomCardRow
            label={'Exit Strategy'}
            value={property.exitStrategy}
          />
          <CustomCardRow
            label={'Completed Renovations'}
            value={formatCurrency(property.sunkCost)}
          />
          <CustomCardRow
            label={'Refinance Amount'}
            value={formatCurrency(property.refiAmount)}
          />
          <CustomCardRow
            label={'Describe Renovation'}
            value={property.describeRenovation}
          />
          <CustomCardRow
            label={'Arms Length'}
            value={<BooleanChip value={property.armsLength} />}
          />
        </CustomCardDividers>
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <CustomCardDividers>
          <CustomCardRow
            label={'Arms Comment'}
            value={property.armsLengthComment}
          />
          <CustomCardRow
            label={'WholeSale'}
            value={formatCurrency(property.wholesaleAmount)}
          />
          <CustomCardRow label={'Occupancy'} value={property.occupancy} />
          <CustomCardRow
            label={'Monthly Rent'}
            value={formatCurrency(property.monthlyRent)}
          />
          <CustomCardRow
            label={'As-is-Value'}
            value={formatCurrency(property.asIsValue)}
          />
          <CustomCardRow
            label={'Min Release'}
            value={formatCurrency(property.releaseAmount)}
          />
          <CustomCardRow
            label={'Ins Exp Date'}
            value={property.insuranceExpiryDate}
          />
        </CustomCardDividers>
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <CustomCardDividers>
          <CustomCardRow
            label={'ARV'}
            value={formatCurrency(property.afterRepairValue)}
          />
          <CustomCardRow
            label={'Full Net Proceeds'}
            value={<BooleanChip value={property.fullNetProceeds} />}
          />
          <CustomCardRow
            label={'Release Comment'}
            value={property.releaseComment}
          />
          <CustomCardRow
            label={'Zillow'}
            value={formatCurrency(property.zEstimate)}
          />
          <CustomCardRow
            label={'Zillow Low'}
            value={formatCurrency(property.zEstimateLow)}
          />
          <CustomCardRow
            label={'Zillow High'}
            value={formatCurrency(property.zEstimateHigh)}
          />
          <CustomCardRow
            label={'Median'}
            value={formatCurrency(property.zEstimateMedian)}
          />
        </CustomCardDividers>
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <CustomCardDividers>
          <CustomCardRow
            label={'Additional Comments'}
            value={property.additionalComments}
          />
          <CustomCardRow label={'Lien Pos'} value={property.lienPosition} />
          <CustomCardRow label={'Sr Amt'} value={property.lienAmount} />
          <CustomCardRow label={'Flood Zone'} value={property.floodZone} />
          <CustomCardRow
            label={'Rural Census'}
            value={<BooleanChip value={property.ruralProperty} />}
          />
        </CustomCardDividers>
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <CustomCardDividers>
          <CustomCardRow
            label={'Valuation Review'}
            tooltip={'Internal Valuation Review Required'}
            value={property.internalValuationReviewRequired}
            tooltipIfValueOverflows
          />
          <CustomCardRow
            label={'Reason for Internal Valuation'}
            tooltip={'Internal Valuation Review Required Reason'}
            value={property.internalValuationReviewRequiredReason}
            tooltipIfValueOverflows />
        </CustomCardDividers>
      </Grid>
      {loanSubType === LoanSubType.FIX_AND_FLIP_PRO && (
        <>
          <Grid item xs={12} md={6} lg={4}>
            <CustomCardDividers>
              <CustomCardRow label={'Pre-Rehab'} value="" />
              <CustomCardRow label={'Bedroom count'} value={formatNumberWithCommas(property.preRehabBedroomsCount)} />
              <CustomCardRow label={'Bathroom count'} value={formatNumberWithCommas(property.preRehabBathroomsCount)} />
              <CustomCardRow label={'Approx. sq ft above grade'} value={formatNumberWithCommas(property.preRehabAproxSquareFootageAboveGrade)} />
              <CustomCardRow label={'Approx. sq ft below grade'} value={formatNumberWithCommas(property.preRehabAproxSquareFootageBelowGrade)} />
            </CustomCardDividers>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <CustomCardDividers>
              <CustomCardRow label={'Post-Rehab'} value="" />
              <CustomCardRow label={'Bedrooms Count'} value={formatNumberWithCommas(property.postRehabBedroomsCount)} />
              <CustomCardRow label={'Bathroom Count'} value={formatNumberWithCommas(property.postRehabBathroomsCount)} />
              <CustomCardRow label={'Approx. sq ft above grade'} value={formatNumberWithCommas(property.postRehabAproxSquareFootageAboveGrade)} />
              <CustomCardRow label={'Approx. sq ft below grade'} value={formatNumberWithCommas(property.postRehabAproxSquareFootageBelowGrade)} />
            </CustomCardDividers>
          </Grid>
        </>)
      }
    </Grid>
  );

  return (
    <StandardDialog
      title={`Property - ${property?.address}`}
      open={open}
      onClose={onClose}
      handleClose={onClose}
      dialogContent={dialogContent}
    />
  );
});
