import { useState } from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Radio,
  RadioGroup,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { observer } from 'mobx-react';
import { Button, TextField, CurrencyField } from '@roc/ui';
import { ScopeOfWorkV2FormBaseStore } from '@roc/feature-sow-shared/stores/v2';
import {
  GENERAL_RADIO_OPTION,
  ITEMIZED_RADIO_OPTION,
} from '@roc/feature-sow-shared/stores/v2';
import { roundHalf } from '@roc/feature-utils';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    title: {
      paddingRight: theme.spacing(6),
    },
    dialogContent: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '400px',
      maxHeight: '80vh',
      padding: theme.spacing(1, 3, 3),
    },
    formContainer: {
      flex: 1,
      display: 'flex',
      alignItems: 'center',
    },
    buttonsContainer: {
      marginTop: '50px',
      marginBottom: '8px',
    },
    textOr: {
      textAlign: 'center',
      paddingTop: theme.spacing(3),
      marginBottom: theme.spacing(-3),
    },
    addOther: {
      cursor: 'pointer',
      textDecoration: 'underline',
      color: theme.palette.primary.dark,
      paddingRight: '4px',
    },
  })
);

type SubcategoriesItemizedDetailsModalProps = {
  store: ScopeOfWorkV2FormBaseStore;
};

const DetailFields = observer(
  ({ detail, onCostChange, onDescriptionChange, disabled, onCapexChange, errors, readOnlyCapex = false }) => {
    const isOther = detail.name === 'Other' && !detail.detailId;
    const paidCapex = detail.capex ? ((detail.capex * detail.cost) / 100) : null;
    const [paidAlready, setPaidAlready] = useState<number>(paidCapex);
    const [remainingBudget, setRemainingBudget] = useState<number>(detail.cost - paidCapex);

    const onCapexChangeInternal = (capex: number) => {
      const percentage = (capex && detail.cost) ? roundHalf(((capex / detail.cost) * 100)) : 0;
      setPaidAlready(capex);
      setRemainingBudget(detail.cost - capex);
      onCapexChange(percentage);
    }

    const onCostChangeInternal = (cost: number) => {
      onCostChange(cost);
      const paid = detail.capex ? ((detail.capex * detail.cost) / 100) : 0;
      setRemainingBudget(cost - paid);
    }

    return (
      <>
        <Grid item xs={12} sm={2}>
          <CurrencyField
            variant="outlined"
            label={detail.name ? `${detail.name} - Cost` : 'Cost *'}
            value={detail.cost}
            fullWidth
            testId="cost"
            onChange={(name, value) =>
              onCostChangeInternal(parseFloat(value.toString()) || null)
            }
            disabled={disabled}
            error={!!errors?.cost}
            errorText={errors?.cost}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField
            type="percentage"
            label="% Completed Renovations"
            name="completeToDate"
            value={detail.capex}
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            fullWidth
            testId="completeToDate"
            disabled={true}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <CurrencyField
            variant="outlined"
            label='Completed Renovations'
            value={paidAlready}
            fullWidth
            testId="paidAlready"
            disabled={readOnlyCapex}
            onChange={(name, value) =>
              onCapexChangeInternal(parseFloat(value.toString()) || null)
            }
            error={!!errors?.capex}
            errorText={errors?.capex}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <CurrencyField
            variant="outlined"
            label='Reno Budget'
            value={remainingBudget}
            fullWidth
            testId="remainingBudget"
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            type="text"
            label="Description"
            name="description"
            placeholder="Please describe the proposed work."
            value={detail.description}
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            fullWidth
            testId="description"
            multiline
            maxRows={3}
            disabled={disabled}
            error={!!errors?.description}
            required={isOther}
            helperText={errors?.description}
            onChange={e => onDescriptionChange(e.target.value)}
          />
        </Grid>
      </>
    );
  }
);

const SubcategoryItemizedDetailsModal = observer(
  (props: SubcategoriesItemizedDetailsModalProps) => {
    const { store } = props;
    const modalStore = store.subcategoryItemizedDetailsStore;
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const { option } = modalStore;
    const { readOnlyCapex } = store;

    const hasSubitems = modalStore.isItemized;
    const hasTooltip = modalStore.hasTooltip;

    return (
      <Dialog
        disableEscapeKeyDown
        fullScreen={fullScreen}
        fullWidth
        maxWidth="lg"
        open={modalStore.isOpen}
        scroll="paper"
        onClose={() => modalStore.closeModal()}
        aria-labelledby="area-of-work-dialog"
      >
        <DialogTitle disableTypography>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography className={classes.title} variant="h4">
                {modalStore.categoryName + ' - ' + modalStore.subcategoryName}
              </Typography>
              <IconButton
                aria-label="close"
                className={classes.closeButton}
                onClick={() => modalStore.closeModal()}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
            {hasTooltip &&
              <Grid item xs={12} spacing={2}>
                <Alert severity="info">
                  <span dangerouslySetInnerHTML={{__html: modalStore.subCatTooltip}}/>
                </Alert>
              </Grid>
            }
            {hasSubitems && (
              <Grid item xs={12}>
                <Typography
                  className={classes.title}
                  variant="body1"
                  color="textSecondary"
                >
                  Please choose one of the options, General or Itemized.
                </Typography>
              </Grid>
            )}
          </Grid>
        </DialogTitle>

        <DialogContent className={classes.dialogContent}>
          <Box className={classes.formContainer}>
            {hasSubitems ? (
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                value={option}
                onChange={e => modalStore.setOption(e.target.value)}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormControlLabel
                      value={GENERAL_RADIO_OPTION}
                      control={<Radio />}
                      label="General"
                    />
                  </Grid>

                  <DetailFields
                    detail={modalStore}
                    onCostChange={cost => (modalStore.cost = cost)}
                    onDescriptionChange={desc => (modalStore.description = desc)}
                    onCapexChange={capex => (modalStore.capex = capex)}
                    errors={modalStore.validationErrors['general']}
                    disabled={option !== GENERAL_RADIO_OPTION}
                    readOnlyCapex={readOnlyCapex}
                  />
                </Grid>

                <Typography
                  variant="h4"
                  color="textPrimary"
                  className={classes.textOr}
                >
                  - OR -
                </Typography>

                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormControlLabel
                      value={ITEMIZED_RADIO_OPTION}
                      control={<Radio />}
                      label="Itemized"
                    />
                  </Grid>

                  {modalStore.details.map((detail, i) => (
                    <DetailFields
                      key={detail.detailId + i}
                      detail={detail}
                      onCostChange={cost => (detail.cost = cost)}
                      onDescriptionChange={desc => (detail.description = desc)}
                      onCapexChange={capex => (detail.capex = capex)}
                      errors={modalStore.validationErrors[i]}
                      disabled={option !== ITEMIZED_RADIO_OPTION}
                      readOnlyCapex={readOnlyCapex}
                    />
                  ))}

                  <Grid item xs={12}>
                    <Box display="flex" justifyContent="flex-end">
                      <Link
                        className={classes.addOther}
                        onClick={() => modalStore.addOther()}
                      >
                        + Add Another Item
                      </Link>
                    </Box>
                  </Grid>
                </Grid>
              </RadioGroup>
            ) : (
              <Grid container spacing={2}>
                <DetailFields
                  detail={modalStore}
                  onCostChange={cost => (modalStore.cost = cost)}
                  onDescriptionChange={desc => (modalStore.description = desc)}
                  onCapexChange={capex => (modalStore.capex = capex)}
                  errors={modalStore.validationErrors.general}
                  disabled={option !== GENERAL_RADIO_OPTION}
                  readOnlyCapex={readOnlyCapex}
                />
              </Grid>
            )}
          </Box>
          <Grid
            container
            className={classes.buttonsContainer}
            justifyContent="center"
            spacing={3}
          >
            <Grid item xs={6} md={3}>
              <Button
                variant="outlined"
                color="primary"
                fullWidth
                onClick={() => modalStore.closeModal()}
                testId="previous"
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={6} md={3}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={e => modalStore.handleSave()}
                testId="previous"
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  }
);

export default SubcategoryItemizedDetailsModal;
