import React, { ReactNode, useState } from 'react';
import {
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TablePagination,
  Paper,
  TableHead,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

const useTableStyles = makeStyles((theme) => {
  return {
    trackRecordGrid: (props: any) => ({
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      '& thead': {
        border: 'solid lightgrey 1px',
      },
      '& tbody': {
        border: 'solid lightgrey 1px',
        padding: '12px',
      },
      '& td': {
        border: 'none',
        padding: '12px 0px 12px 12px',
      },
      ...(props.isExpandable && {
        '& tr:nth-child(even)': {
          borderBottom: 'solid lightgrey 1px',
        },
      }),
      ...(!props.isExpandable && {
        '& tr': {
          borderBottom: 'solid lightgrey 1px',
        },
      }),
    }),
  };
});

export type NewTableComponentProps = {
  store: any,
  isExpandable?: boolean,
  headers: any,
  rows: any,
  rowsPerPageOptions?: number[],
  defaultRowsPerPage?: number,
  enablePagination?: boolean,
  filterComponent?: ReactNode,
};

export const NewTableComponent = observer(({
  store,
  isExpandable = false,
  headers,
  rows,
  rowsPerPageOptions = [5, 10, 15],
  defaultRowsPerPage = 5,
  enablePagination = false,
  filterComponent
}: NewTableComponentProps) => {
  const classes = useTableStyles({ isExpandable });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(
    enablePagination ? defaultRowsPerPage : rows.length
  );

  const handleExpandClick = (id, i, type, object) => {
    store.currentTasks = [];
    if (store.currentExpandedRowIndex === i) {
      store.setCurrentExpandedRowIndex(null);
      store.currentProperty = null;
    } else {
      store.setCurrentExpandedRowIndex(i);
      store.currentProperty = object;
      // store.getTasks(id, type);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    store.setCurrentExpandedRowIndex(null);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    store.setCurrentExpandedRowIndex(null);
  };

  const displayedRows = enablePagination
    ? rows.slice(page * rowsPerPage, (page + 1) * rowsPerPage)
    : rows;

  return (
    <div>
      <Paper style={{ backgroundColor: 'transparent' }}>
        <TableContainer className={classes.trackRecordGrid}>
          <Table>
            <TableHead>
              {filterComponent && (
                <TableCell colSpan={headers.length + (isExpandable ? 1 : 0)} style={{ borderBottom: 'none', paddingBottom: '0px' }} >
                  {filterComponent}
                </TableCell>
              )}
              <TableRow>
                {isExpandable && <TableCell />}
                {headers.map((header, index) => (
                  <TableCell key={index}>
                    <strong>{header.headerName}</strong>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {displayedRows.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={headers.length + (isExpandable ? 1 : 0)} align="center">
                    No records found
                  </TableCell>
                </TableRow>
              )
                : (
                  displayedRows.map((row, index) => (
                    <React.Fragment key={index}>
                      <TableRow>
                        {row.cells.map((cell, cellIndex) => (
                          <TableCell
                            key={cellIndex}
                            width={headers[cellIndex].width}
                          >{cell}</TableCell>
                        ))}
                        {isExpandable && row.expandableContent && (
                          <TableCell width='6%'>
                            <IconButton
                              aria-label="expand row"
                              size="small"
                              onClick={() => handleExpandClick(row.id, index, row.type, row.object)}
                            >
                              {store.currentExpandedRowIndex === index ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            </IconButton>
                          </TableCell>
                        )}
                      </TableRow>
                      {isExpandable && row.expandableContent && (
                        <TableRow>
                          <TableCell
                            style={{ padding: '0px 12px 0px 12px' }}
                            colSpan={headers.length + (isExpandable ? 1 : 0)}
                          >
                            <Collapse in={store.currentExpandedRowIndex === index} timeout="auto" unmountOnExit>
                              {row.expandableContent}
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      )}
                    </React.Fragment>
                  ))
                )}
            </TableBody>
          </Table>
          {enablePagination && (
            <TablePagination
              rowsPerPageOptions={rowsPerPageOptions}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </TableContainer>
      </Paper>
    </div>
  );
});