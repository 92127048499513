const mapper = {
  'Drawings, Permits': {
    helperText: 'Please provide the cost and details of the following items',
    sections: [
      {
        title: 'Drawings',
        fields: [
          {
            label: 'Amount',
            name: 'drawingsAmount',
          },
        ],
      },
      {
        title: 'Permits',
        fields: [
          {
            label: 'Amount',
            name: 'permitsAmount',
          },
        ],
      },
      {
        title: 'Other',
        tooltip: 'Any other relevant items related to this category',
        fields: [
          {
            label: 'Amount',
            name: 'otherAmount',
          },
        ],
      },
    ],
  },
};

export default mapper;
