// ----------------------------------------------------------------------

export default function Paper() {
  return {
    MuiPaper: {
      root: {
        backgroundImage: 'none',
      },
    },
  };
}
