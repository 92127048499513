import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme =>
  createStyles({
    typography: {
      padding: theme.spacing(2),
      fontSize: theme.typography.h4.fontSize,
    },
  })
);

export const ErrorText = ({ msg }) => {
  const classes = useStyles();
  return (
    <Box flex="1" display="flex" justifyContent="center">
      <Typography className={classes.typography} align="center" color="error">
        {msg}
      </Typography>
    </Box>
  );
};
