import { useBaseStore } from '@roc/feature-app-core';
import { getEditLoanSubmissionRoutes, getEditLoanSubmissionRoutesConfig } from '../routes/editLoanSubmissionRoutes';


export const useEditLoanSubmissionRoutes = () => {
  const { globalStore } = useBaseStore();
  const { routeBasePath, routeBaseUrl } = globalStore;

  return {
    editLoanSubmissionRoutes: getEditLoanSubmissionRoutes(routeBasePath, routeBaseUrl),
    editLoanSubmissionRoutesConfig: getEditLoanSubmissionRoutesConfig(
      routeBasePath,
      routeBaseUrl
    ),
  };
};
