import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { InputAdornment, TextField } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import { observer } from 'mobx-react';

const useStyles = makeStyles(theme => ({
  searchIcon: {
    color: theme.palette.text.secondary,
  },
}));

interface Option {
  label: string;
  value: string;
}

interface AutocompleteFieldProps {
  value: string;
  onChange: (text: string) => void;
  onSelect: (option: Option) => void;
  options: Option[];
}

const filter = createFilterOptions({
  matchFrom: 'any',
});

export const AutocompleteField = observer((props: AutocompleteFieldProps) => {
  const classes = useStyles();
  const { value, onChange, onSelect, options } = props;

  const filterOptions = (options, params) => {
    const filtered = filter(options, params);
    return filtered;
  };

  const renderInput = params => (
    <TextField
      {...params}
      InputProps={{
        ...params.InputProps,
        endAdornment: undefined,
        startAdornment: (
          <InputAdornment position="start" className={classes.searchIcon}>
            <Search />
          </InputAdornment>
        ),
      }}
      testId="loan-autocomplete"
      onChange={e => onChange(e.target.value)}
      variant="outlined"
      placeholder="Select Loan Ids or Deal Names"
      fullWidth
    />
  );

  return (
    <Autocomplete
      id="loan-autocomplete"
      selectOnFocus
      handleHomeEndKeys
      options={options}
      value={null}
      inputValue={value}
      blurOnSelect={true}
      onChange={(event, option: Option) => onSelect(option)}
      renderInput={renderInput}
      getOptionLabel={(option: Option) => option.label}
      getOptionSelected={(option: Option) => true}
      filterOptions={filterOptions}
      fullWidth
    />
  );
});
