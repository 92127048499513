import { GlobalStore, FormStore } from "@roc/feature-app-core";
import { makeObservable, flow } from "mobx";
import { ChangePasswordService } from "../services/changePasswordService";

const changePasswordForm = {
  fields: {
    oldPassword: {
      value: '',
      error: null,
      rule: 'required',
    },
    newPassword: {
      value: '',
      error: null,
      rule: 'required',
    },
    confirmPassword: {
      value: '',
      error: null,
      rule: 'required',
    },
    passwordsMatch: {
      value: '',
      error: null,
      rule: 'same:newPassword|same:confirmPassword',
      message: 'New password and confirm password match',
    },
    passwordMin: {
      value: '',
      error: null,
      rule: 'min:6',
      message: '6 characters minimum',
    },
    passwordSpecialOrNumber: {
      value: '',
      error: null,
      rule: [
        'regex:/[~`!@#$%^&*()_\\-+={[}\\]|\\\\:;"\'<,>.?\\/  ]/',
        'regex:/\\d/',
      ],
      message: 'One number and one special character',
    },
    passwordLowerUpper: {
      value: '',
      error: null,
      rule: ['regex:/[A-Z]/', 'regex:/[a-z]/'],
      message: 'One lowercase and one capital character',
    },
  },
  meta: {
    isValid: false,
    error: null,
  }
}

export class ChangePasswordStore extends FormStore {
  private globalStore: GlobalStore;
  private changePasswordService: ChangePasswordService;

  constructor(globalStore: GlobalStore) {
    super({ ...changePasswordForm }, globalStore);
    this.globalStore = globalStore;
    this.changePasswordService = new ChangePasswordService();

    makeObservable(this, {
      onFormSubmit: flow,
    })
  }

  *onFormSubmit(oldPassword: string, newPassword: string) {
    this.runFormValidationWithMessage();
    if (this.form.meta.isValid) {
      try {
        if (oldPassword === newPassword) {
          this.globalStore.notificationStore.showErrorNotification({
            message: 'Old password and New password cannot be same.',
          });
          return;
        }
        const response = yield this.changePasswordService.changePassword({ oldPassword, newPassword });
        if (response?.data?.data) {
          this.globalStore.notificationStore.showSuccessNotification({
            message: 'Password changed successfully!',
          });
        }
        else {
          this.globalStore.notificationStore.showErrorNotification({
            message: 'Error while changing password.',
          });
        }
      }
      catch (error) {
        this.globalStore.notificationStore.showErrorNotification({
          message: 'Error while changing password.',
        });
      }
    }
  }

}