const LOANS_HOME_BASE_PATH = 'loan-applications';
const LOAN_BASE_PATH = ':loanId';

export const borrowerLoanApplicationsRoutesConfig = (basePath: string, baseUrl: string) => {
  return {
    loans: (loanId?: string) => ({
      path: `${basePath}/${LOANS_HOME_BASE_PATH}/${LOAN_BASE_PATH}`,
      url: `${baseUrl}/${LOANS_HOME_BASE_PATH}/${loanId}`,
      children: {
        dashboard: {
          path: `${basePath}/${LOANS_HOME_BASE_PATH}/${LOAN_BASE_PATH}/dashboard`,
          url: `${baseUrl}/${LOANS_HOME_BASE_PATH}/${loanId}/dashboard`,
          documentTitle: `${loanId} - Loan Applications Dashboard`,
        },
        borrowerTodos: {
          path: `${basePath}/${LOANS_HOME_BASE_PATH}/${LOAN_BASE_PATH}/todos`,
          url: `${baseUrl}/${LOANS_HOME_BASE_PATH}/${loanId}/todos`,
          documentTitle: `Todos - ${loanId}`,
        },
      },
    }),
  };
};
