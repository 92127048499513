import {
  Badge,
  Box,

  ListItem as MuiListItem,
  ListItemText,

} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { ThreadMeta, ThreadType } from '@roc/feature-types';
import { lighten } from '@material-ui/core/styles';
import {
  capitalize,
  CLOSING,
  DRAWS,
  getDrawId,
  PORTAL_COLORS,
  SERVICING,
  UNDERWRITING,
} from '@roc/feature-utils';
import { Tooltip } from '@roc/ui';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDocumentStore } from '../../documents/hooks/useDocumentStore';
import { useExtractLoanProcessFromUrl } from '../../documents/hooks/useExtractLoanProcessFromUrl';
import { useDocumentCommunicationStore } from '../hooks/useDocumentCommunicationStore';

const useStyles = makeStyles((theme: Theme) => ({
  sectionList: {
    background: '#FDFDFD',
    borderRight: '1px solid rgba(0, 0, 0, 0.12)',
    flexBasis: 200,
    flex: 'none',
    overflowY: 'auto',
  },

  processName: {
    display: 'flex',
    alignItems: 'flex-end',

    minHeight: 48,
    '& .MuiTypography-body1': {
      fontWeight: 700,
      fontSize: 16,
    },
  },

  listItem: {
    padding: theme.spacing(0.5, 2, 0.5, 2),
    paddingLeft: theme.spacing(4),
    justifyContent: 'space-between',
    '& .MuiTypography-body1': {
      fontSize: 14,
    },
  },
  listItemActive: {
    fontWeight: theme.typography.fontWeightBold,
    background: lighten(theme.palette.primary.main, 0.9),
    '&:hover': {
      background: lighten(theme.palette.primary.main, 0.9),
    },
    '&::after': {
      content: '""',
      position: 'absolute',
      left: 0,
      width: theme.spacing(0.5),
      height: '100%',
      background: theme.palette.primary.dark,
    },
  },
  badge: {
    '& .MuiBadge-root': {
      display: 'flex',
    },
    '& .MuiBadge-badge': {
      position: 'relative',
      top: 0,
      right: 0,
      transform: 'initial',
      transformOrigin: 'initial',
      color: theme.palette.common.white,
      backgroundColor: PORTAL_COLORS.unreadMessages,
      fontWeight: 700,
    },
  },
}));

export const ListItem = ({
  text,
  unreadMessages = null,
  isActive = false,
  onClick,
}) => {
  const classes = useStyles();

  const handleClick = event => {
    event.stopPropagation();
    onClick();
  };

  const listItemClass = clsx([
    classes.listItem,
    isActive && classes.listItemActive,
  ]);

  return (
    <MuiListItem button className={listItemClass} onClick={handleClick}>
      <ListItemText primary={text} />
      {Boolean(unreadMessages) && (
        <Tooltip title={`${unreadMessages} unread messages`} arrow>
          <Badge className={classes.badge} badgeContent={unreadMessages} />
        </Tooltip>
      )}
    </MuiListItem>
  );
};

export const SectionList = observer(() => {
  const { documentCommunicationStore } = useDocumentCommunicationStore();
  const { documentStore } = useDocumentStore();
  const { pathname } = useLocation();
  const classes = useStyles();

  //Since we have the same 'Entity Review' section in both closing and underwriting
  //we need to get the slelected process to know which one was selected
  const loanProcess = useExtractLoanProcessFromUrl();
  const [selectedProcess, setSelectedProcess] = useState(loanProcess);

  //const showDraws = loanStore?.loanDetails?.showDraws;
  //Disable draws for now
  const showDraws = false;
  const drawId = showDraws ? getDrawId(pathname) : null;
  const selectedThread = documentCommunicationStore?.thread;

  const processes = [
    documentStore.isCommunicationAllowed(UNDERWRITING) && UNDERWRITING,
    documentStore.isCommunicationAllowed(CLOSING) && CLOSING,
    documentStore.isCommunicationAllowed(SERVICING) && SERVICING,
    showDraws && documentStore.isCommunicationAllowed(DRAWS) && DRAWS,
  ].filter(Boolean) as string[];

  const getSections = process => {
    return (
      documentStore.displaySections[process]
        ?.map(item => item.sections)
        ?.flat() ?? []
    );
  };

  const setSection = (process, section) => {
    const { loanId } = documentStore.loanStore.loanDetails;
    const { sectionName, collateralId, borrowerId, sectionId } = section;
    const meta: ThreadMeta = {
      loanId,
      drawId: selectedProcess === DRAWS ? drawId : null,
      sectionId,
      borrowerId,
      collateralId,
    };
    setSelectedProcess(process);
    documentStore.fetchProcessUnreadComments(loanId, process);
    documentCommunicationStore.reset(true);
    documentCommunicationStore.getDocumentComments(
      sectionName,
      meta,
      ThreadType.DOCUMENT
    );
  };

  return (
    <Box className={classes.sectionList}>
      {processes.map(process => (
        <Box key={process}>
          <MuiListItem>
            <Box className={classes.processName}>
              <ListItemText primary={capitalize(process)} />
            </Box>
          </MuiListItem>
          {getSections(process).map(section => (
            <ListItem
              key={section.sectionId}
              text={section.sectionName}
              unreadMessages={section.unreadComments}
              isActive={
                selectedProcess == process &&
                selectedThread?.title?.toString() == section.sectionName &&
                selectedThread?.meta?.sectionId?.toString() ==
                section.sectionId.toString()
              }
              onClick={() => setSection(process, section)}
            />
          ))}
        </Box>
      ))}
    </Box>
  );
});
