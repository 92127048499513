import { Service } from '@roc/client-portal-shared/services';

const baseUrl = '/nportal/rest/priv/escrowCalculator';

const url = {
  GET_PAYMENT_VALUES_FOR_STATE: `${baseUrl}/getPaymentValuesForState`,
  UPDATE_LOAN: `${baseUrl}/updateLoan`,
  RENTAL_DEAL_NAMES: `${baseUrl}/getRentalDealNames`,
  LOAN_VALUES: `${baseUrl}/getRentalLoanDetails`,
  CALCULATE_NEW_NEXT_PAYMENTS_MONTHS: `${baseUrl}/getNewNextPaymentsMonths`,
  CALCULATE_FIRST_PAYMENT_DUE_DATE: `${baseUrl}/getFirstPaymentDueDate`
};

export class EscrowDepositCalculatorService extends Service {

  getPaymentValuesForState(state) {
    return this.get(`${url.GET_PAYMENT_VALUES_FOR_STATE}?state=${state}`);
  }

  updateLoan(loanId, taxEscrowMonths, loanDocumentId) {
    let returnUrl = `${url.UPDATE_LOAN}?loanId=${loanId}&taxEscrowMonths=${taxEscrowMonths}`
    if (loanDocumentId) {
      return this.put(`${returnUrl}&loanDocumentId=${loanDocumentId}`, {});
    } else {
      return this.put(returnUrl, {});
    }
  }


  getRentalDealNames(
    page: number,
    pageSize: number,
    sortDir: string,
    sortBy: string,
    filters: object
  ) {
    return this.get(url.RENTAL_DEAL_NAMES, {
      page,
      pageSize,
      sortDir,
      sortBy,
      filters,
    });
  }

  getLoanValues(loanId) {
    return this.get(`${url.LOAN_VALUES}?id=${loanId}`);
  }

  calculateNewNumberOfPayments(firstPaymentDueDate, nextTaxPaymentDue){
    return this.get(`${url.CALCULATE_NEW_NEXT_PAYMENTS_MONTHS}?nextTaxPaymentDue=${nextTaxPaymentDue}&firstPaymentDueDate=${firstPaymentDueDate}`)
  }

  calculateFirstPaymentDueDate(expectedClosingDate) {
    return this.get(`${url.CALCULATE_FIRST_PAYMENT_DUE_DATE}?expectedClosingDate=${expectedClosingDate}`)
  }
}
