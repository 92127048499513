import { GlobalStore } from 'libs/feature-app-core/src/stores/index';
import { UserStore } from 'libs/feature-app-core/src/stores/index';
import { makeObservable } from 'mobx';
import { OriginatorService } from '../services/originatorService';
import { OriginatorFormStore } from './originatorFormStore';
import { OriginatorsGridStore } from './originatorGridStore';
import { SelectOriginatorsStore } from './selectOriginatorStore';

export class OriginatorsStore {
  private globalStore: GlobalStore;
  private userStore: UserStore;
  originatorService: OriginatorService;
  selectOriginatorsStore: SelectOriginatorsStore;
  originatorsGridStore: OriginatorsGridStore;
  originatorFormStore: OriginatorFormStore;

  constructor(globalStore: GlobalStore, userStore: UserStore) {
    this.globalStore = globalStore;
    this.userStore = userStore;
    this.originatorService = new OriginatorService();
    this.originatorsGridStore = new OriginatorsGridStore(this, globalStore, userStore);
    this.originatorFormStore = new OriginatorFormStore(globalStore);
    this.selectOriginatorsStore = new SelectOriginatorsStore(this, globalStore);

    makeObservable(this, {});
  }

  getOriginatorFormValues() {
    return this.selectOriginatorsStore.getFormOriginator();
  }

  reset() {
    this.originatorFormStore.reset();
    this.originatorsGridStore.resetSelected();
    this.selectOriginatorsStore.reset();
  }
}
