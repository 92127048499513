import { useBaseStore } from '@roc/feature-app-core';
import {
  getOneToolRoutes,
  getOneToolRoutesConfig
} from './../routes/oneToolRoutes';

export const useOneToolRoutes = () => {
  const { globalStore } = useBaseStore();
  const { routeBasePath, routeBaseUrl } = globalStore;

  return {
    oneToolRoutes: getOneToolRoutes(routeBasePath, routeBaseUrl),
    oneToolRoutesConfig: getOneToolRoutesConfig(
      routeBasePath,
      routeBaseUrl
    ),
  };
};
