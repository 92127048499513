import { Page } from '@roc/feature-app-core';
import { Route } from 'react-router';
import { LoansHome } from '../modules/loans/loansHome';
import { LoanApplicationDetails, LoanDetails } from '@roc/feature-loans';
import { getLoanRoutesConfig } from '@roc/feature-loans-routes-config';
import { LoanSummaryBridge } from '../modules/loans/loanSummaryBridge';
import { LoanSummaryTerm } from '../modules/loans/loanSummaryTerm';
import { LoanSummaryStabilizedBridge } from '../modules/loans/loanSummaryStabilizedBridge';
import { LoanSubType } from '@roc/feature-utils';
import { LoanSummaryBridgeMultifamily } from '../modules/loans/loanSummaryBridgeMultifamily';
import { LoanSummaryExtended } from '@roc/feature-loan-summary';

export enum LoanType {
  RESIDENTIAL_BRIDGE = 'Residential Bridge',
  RESIDENTIAL_TERM = 'Residential Term',
}

export const LoanSummaryBridgeBySubType = ({ data }) => {
  const { loanSubType } = data;

  switch (loanSubType) {
    case LoanSubType.STABILIZED_BRIDGE:
      return <LoanSummaryStabilizedBridge data={data} />;
    case LoanSubType.MULTIFAMILY_BRIDGE_5PLUS:
      return <LoanSummaryBridgeMultifamily data={data} />
    default:
      return <LoanSummaryBridge data={data} />;
  }
};

export const LoanSummary = ({ loanDetails }) => {
  const { loanType } = loanDetails;

  switch (loanType) {
    case LoanType.RESIDENTIAL_BRIDGE:
      return <LoanSummaryBridgeBySubType data={loanDetails} />;
    case LoanType.RESIDENTIAL_TERM:
      return <LoanSummaryTerm data={loanDetails} />;
  }
};

export const getLoanRoutes = (basePath: string, baseUrl: string) => {
  const config = getLoanRoutesConfig(basePath, baseUrl);
  return {
    loansHome: (
      <Route exact path={config.loansHome.path}>
        <Page routeDefinition={config.loansHome}>
          <LoansHome />
        </Page>
      </Route>
    ),
    loanDetails: (
      <Route path={config.loans().path}>
        <LoanDetails
          renderLoanSummary={({ loanDetails }) => (
            <LoanSummary loanDetails={loanDetails} />
          )}
        />
      </Route>
    ),
    loanSummary: (
      <Route exact path={config.loans().children.loanSummary.path}>
        <Page routeDefinition={config.loans().children.loanSummary}>
          <LoanSummaryExtended />
        </Page>
      </Route>
    ),
    commissions: (
      <Route exact path={config.loans().children.commissions.path}>
        <Page routeDefinition={config.loans().children.commissions}>
          <LoanSummaryExtended />
        </Page>
      </Route>
    ),
    loanApplications: (
      <Route path={config.loanApplications().path}>
        <LoanApplicationDetails />
      </Route>
    )
  };
};
