import { action, computed, flow, makeObservable, observable } from 'mobx';
import { GlobalStore } from '@roc/feature-app-core';
import { DocumentStore } from '@roc/feature-documents';
import { LoanStore } from '@roc/feature-loans';
import { TamariskPaymentService } from '../../services/documentForms/tamariskPaymentService';

export class TamariskPaymentStore {
  private loanStore: LoanStore;
  private documentStore: DocumentStore;
  private globalStore: GlobalStore;
  private tamariskPaymentService: TamariskPaymentService;
  public isInternal: boolean;

  constructor(
    loanStore: LoanStore,
    documentStore: DocumentStore,
    globalStore: GlobalStore
  ) {
    this.loanStore = loanStore;
    this.documentStore = documentStore;
    this.globalStore = globalStore;
    this.tamariskPaymentService = new TamariskPaymentService();
    this.isInternal = false;
    makeObservable(this, {
      requestAppraisalPayment: flow,
      reset: action,
      tamariskPaymentURL: computed,
      borrowersEmail: computed,
      isInternal: observable,
      setIsInternal: action,
    });
  }

  *requestAppraisalPayment(entityId: any, type: any) {
    try {
      yield this.tamariskPaymentService.requestAppraisalPayment(this.isInternal, entityId, type);
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Payment email successfully sent',
      });
    } catch (err) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error on sending payment email',
      });
    }
  }

  reset() {
    // No reset logic required yet.
  }

  get tamariskPaymentURL() {
    const { tamariskPaymentURL } = this.loanStore.loanDetails;
    return tamariskPaymentURL;
  }

  get borrowersEmail() {
    return this.loanStore.loanDetails.borrowers
      .filter(
        borrower =>
          borrower.emailAddress != null &&
          borrower.emailAddress != '' &&
          borrower.emailAddress != undefined
      )
      .map(b => b.emailAddress)
      .join('; ');
  }

  setIsInternal(isInternal: boolean) {
    this.isInternal = isInternal;
  }
}

export default TamariskPaymentStore;
