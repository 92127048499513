import { GlobalStore } from '@roc/feature-app-core';
import { LoanSubType } from '@roc/feature-utils';
import { makeObservable, observable } from 'mobx';
import { BaseLoanTypeStore } from './baseLoanTypeStore';

export abstract class BaseBorrowerLoanSubmissionStore {
  public globalStore: GlobalStore;

  public loanSubtype: LoanSubType;
  public isPublic: boolean;

  public fixFlipStore: BaseLoanTypeStore;
  public singlePropertyStore: BaseLoanTypeStore;

  constructor(globalStore: GlobalStore) {
    this.globalStore = globalStore;

    this.reset();

    makeObservable(this, {
      isPublic: observable,
      loanSubtype: observable,
    });
  }

  reset() {
    this.loanSubtype = null;
  }

  setLoanSubtype(loanSubtype: LoanSubType) {
    this.loanSubtype = loanSubtype;
  }
}
