import { useBaseStore } from '@roc/feature-app-core';
import { ScopeOfWorkV2FormStore } from '@roc/feature-sow-shared/stores/v2';
import { observer } from 'mobx-react';
import { ScopeOfWorkV2FormExternalDialog } from './scopeOfWorkV2FormExternalDialog';
import { ScopeOfWorkV2FormInternalDialog } from './scopeOfWorkV2FormInternalDialog';

export type ScopeOfWorkV2FormDialogProps = {
  open?: boolean;
  onClose?: () => void;
  store: ScopeOfWorkV2FormStore;
  title?: string;
};

export const ScopeOfWorkV2FormDialog = observer(
  (props: ScopeOfWorkV2FormDialogProps) => {
    const { globalStore } = useBaseStore();
    if (globalStore.isInternalPortal) {
      return <ScopeOfWorkV2FormInternalDialog {...props} />;
    } else {
      return <ScopeOfWorkV2FormExternalDialog {...props} />;
    }
  }
);

export default ScopeOfWorkV2FormDialog;
