import React, { useEffect } from "react";
import { Grid, Box, Accordion, AccordionSummary, AccordionDetails, Typography, Paper } from "@material-ui/core";
import { Button, createTextField, EditableDialog, ConfirmDialog } from "@roc/ui";
import { observer } from "mobx-react";
import { makeStyles } from '@material-ui/core/styles';
import { Add } from '@material-ui/icons';
import { Note } from './note';
import { DateFormat, formatDate, isNil } from '@roc/client-portal-shared/utils';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(theme => ({
  button: {
    marginLeft: theme.spacing(2),
    minWidth: '150px',
    float: 'right',
  },
  notesScroll: {
    backgroundColor: '#FAFAFA',
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    minHeight: '200px',
    maxHeight: '800px',
    overflowY: 'auto',
    overflowX: 'hidden',
    boxShadow: '0 0 2px #DDD inset'
  },
  accordionSummary: {
    backgroundColor: '#D9DADC',
    '& .MuiAccordionSummary-content': {
      margin: '10px 0px',
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
  noMarginBottom: {
    marginBottom: '0px',
  },
  innerAccordionDetails: {
    marginTop: '0px',
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export const LedgerNotesCard = observer(({ loanId, store }) => {
  const classes = useStyles();

  useEffect(() => {
    return () => {
      store.reset();
    };
  }, []);

  const dialogContent = (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {createTextField({
          store,
          testId: 'body',
          fieldName: 'body',
          label: 'Servicing Ledger Note',
          multiline: true,
          minRows: 4
        })}
      </Grid>
    </Grid>
  );

  const renderAddAction = () => {
    return (
      <Box className={classes.buttonContainer}>
        <Button
          testId="add-lead-note"
          className={classes.button}
          variant="contained"
          size="small"
          color="primary"
          onClick={() => store.openAddNote(loanId)}
        >
          <Add /> Add Note
        </Button>
      </Box>
    );
  };

  return (
    <Paper elevation={0} variant='outlined'>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          className={classes.accordionSummary}
        >
          <Typography gutterBottom variant="h5" component="h2" className={classes.noMarginBottom}>
            {"Servicer Ledger Notes"}
          </Typography>
          {renderAddAction()}
        </AccordionSummary>
        <AccordionDetails className={classes.notesScroll}>
          <Grid container spacing={2} className={classes.innerAccordionDetails}>
            {store.noteList?.length > 0 ? (
              <Grid container spacing={2}>
                {store.noteList.map(note => (
                  <Grid key={note.ledgerNoteId} item xs={12}>
                    <Note
                      title={formatDate(note.createdDate, DateFormat.MMDDYYYY)}
                      onEdit={() => store.openEditNote(note)}
                      onDelete={() => store.openDeleteNote(note)}
                      note={note}
                    >
                      {note.body}
                    </Note>
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Grid item xs={12}>
                <Box textAlign="center">No records.</Box>
              </Grid>
            )}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <EditableDialog
        open={!isNil(store.dialogState)}
        dialogState={store.dialogState}
        handleClose={() => store.closeNoteDialog()}
        handleAdd={() => store.saveNote()}
        handleEdit={() => store.saveNote()}
        title={'Ledger Note'}
        dialogContent={dialogContent}
      />
      <ConfirmDialog
        open={store.confirmState}
        body={'Are you sure you want to delete the note?'}
        handleCancel={() => store.closeConfirmDialog()}
        handleClose={() => store.closeConfirmDialog()}
        handleConfirm={() => store.removeNote()}
        confirmButtonText={'Delete'}
        cancelButtonText={'Cancel'}
      />
    </Paper>
  );
});
