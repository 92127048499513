import { GlobalStore } from "@roc/feature-app-core";
import { action, flow, makeObservable, observable } from "mobx";
import { CompToolPhotosService } from "../services/compToolPhotosService";
import { isNil } from "@roc/feature-utils";

export class CompToolPhotosStore {
  globalStore: GlobalStore;
  compToolPhotosService: CompToolPhotosService;
  allPhotosImages: any[]
  selectedPhoto: any;
  openIndividualPhoto: boolean

  constructor(globalStore) {
    this.globalStore = globalStore;
    this.compToolPhotosService = new CompToolPhotosService();
    this.allPhotosImages = [];
    this.openIndividualPhoto = false;

    makeObservable(this, {
      getCompAllPhotos: flow,
      allPhotosImages: observable,
      setAllPhotosImages: action,
      selectedPhoto: observable,
      setSelectedPhoto: action,
      openIndividualPhoto: observable,
      setOpenIndividualPhoto: action
    });
  }

  setOpenIndividualPhoto(openIndividualPhoto: boolean) {
    this.openIndividualPhoto = openIndividualPhoto;
  }

  setSelectedPhoto(selectedPhoto: any) {
    this.selectedPhoto = selectedPhoto;
  }

  setAllPhotosImages(images) {
    this.allPhotosImages = images;
  }

  *getCompAllPhotos(property) {
    try {
      if (!isNil(property.data.mls_id)) {
        const response = yield this.compToolPhotosService.getCompAllPhotos(property.data.mls_id);
        if (response == null) {
          this.globalStore.notificationStore.showErrorNotification({
            message: 'Error while getting all photos',
          });
          this.setAllPhotosImages([]);
        } else {
          const compImages = response.data && JSON.parse(response.data?.data);
          this.setAllPhotosImages(compImages?.images);
        }
      } else {
        this.setAllPhotosImages([]);
      }
    } catch (e) {
      console.log(e);
      this.setAllPhotosImages([]);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while getting all photos',
      });
      return [];
    }

  }
}
