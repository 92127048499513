import { useEffect, useState } from "react";
import { Slider } from '@material-ui/core';
import { formatPercentage } from '@roc/ui/utils';
import { withStyles } from '@material-ui/core/styles';

export type RateSliderProps = {
  rate: number;
  minRate: number;
  maxRate: number;
  onChange: (value: number) => void;
};

const CustomPricerRateSlider = withStyles((theme) => ({
  valueLabel: {
    '& *': {
      width: '40px',
      height: '40px',
      paddingTop: '10px',
    },
  },
}))(Slider);


export const PricerRateSlider = (props: RateSliderProps) => {
  const { rate, minRate, maxRate, onChange } = props;
  const [sliderValue, setSliderValue] = useState(rate);

  useEffect(() => {
    setSliderValue(rate);
  }, [rate])

  return (
    <>
      <CustomPricerRateSlider
        valueLabelDisplay='auto'
        valueLabelFormat={formatPercentage}
        disabled={false}
        onChange={(_, value) => { setSliderValue(value as number) }}
        onChangeCommitted={() => onChange(sliderValue)}
        value={sliderValue}
        marks={[
          {
            value: minRate,
            label: formatPercentage(minRate),
          },
          {
            value: maxRate,
            label: formatPercentage(maxRate),
          },
        ]}
        step={0.01}
        min={minRate}
        max={maxRate}
      />
    </>
  );
}