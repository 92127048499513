import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';

interface ICustomModal {
  actions: Array<{
    name: string;
    onClick: any;
    [param: string]: any;
  }>;
  buttonContainerClass?: any;
  containerProps?: any;
  children: any;
  handleClose: () => void;
  open: boolean;
  title: string;
}

export const CustomModal = ({
  actions,
  buttonContainerClass,
  children,
  containerProps,
  handleClose,
  open,
  title,
}: ICustomModal) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll={'paper'}
      PaperProps={{ ...containerProps }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions className={buttonContainerClass}>
        {actions.map(({ name, ...buttonProps }) => (
          <Button key={name} {...buttonProps}>
            {name}
          </Button>
        ))}
      </DialogActions>
    </Dialog>
  );
};
