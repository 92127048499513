import { Box } from '@material-ui/core';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useParams } from 'react-router';
import { GeneralTasksComponent } from './components/generalTasksComponent';
import { useGeneralTasksStore } from './hooks/useGeneralTasksStore';
import { GeneralTasksTab } from './types/generalTasksTypes';

export const GeneralLoanTasks = observer(() => {
  const { loanId } = useParams<{ loanId: string }>();
  const { generalTasksStore } = useGeneralTasksStore();

  useEffect(() => {
    generalTasksStore.resetAndFetchOptions();
    generalTasksStore.setTab(GeneralTasksTab.ALL_TASKS);
    generalTasksStore.setLoanId(parseInt(loanId));
  }, []);

  if(!generalTasksStore.loanId){
    return null;
  }

  return (
    <Box p={2} width="100%">
      <GeneralTasksComponent />
    </Box>
  );
});
