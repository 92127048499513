import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem, Select, TextField, Typography } from "@material-ui/core";
import { ConfirmationButton } from "@roc/ui";
import { observer } from "mobx-react";
import { useState } from "react";
import { AppraisalReviewStore } from "../stores";
import { PLEASE_SELECT_AN_OPTION_TO_CONTINUE, SCOPE_OF_WORK_INVALID, TAMARISK_APPRAISAL_AMC, TAMARISK_OPTION_NOT_ALLOWED } from "./constants";
import { useRocInternalPortalStore } from "apps/roc-internal-portal/src/app/hooks";
import { ScopeOfWorkV2FormParams } from "@roc/feature-sow-shared/types";
import { isNil } from "@roc/feature-utils";

interface Props {
  loanId: number;
  propertyId: number;
  store: AppraisalReviewStore;
}

const isTamariskAppraisalAMC = (propertyInfo) => {
  return (propertyInfo?.appraisalAMC || '').toLowerCase().includes(TAMARISK_APPRAISAL_AMC);
}

export const InternalRevisionTextBoxButton = observer((props: Props) => {

  const { scopeOfWorkInternalStore } = useRocInternalPortalStore();

  const { loanId, propertyId, store } = props;
  const [openTextBox, setOpenTextBox] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const isTamarisk = isTamariskAppraisalAMC(store.propertyInformation);

  const handleConfirmationRequestRevisionAction = (valueSelected) => {
    if (valueSelected !== 'Select') {
      if (!isTamarisk && valueSelected === 'TAMARISK') {
        store.showWarningNotification(TAMARISK_OPTION_NOT_ALLOWED);
      } else if (valueSelected === 'CMT') {
        validateScopeOfWork(loanId, propertyId)
          .then(isValidSow => {
            if (isValidSow) {
              store.requestRevision(propertyId, loanId, valueSelected);
            }
            else {
              store.showWarningNotification(SCOPE_OF_WORK_INVALID);
            }
          })
      }

      else {
        store.requestRevision(propertyId, loanId, valueSelected);
      }
    } else {
      setShowErrorMessage(true);
    }
  };

  const validateScopeOfWork = async (loanId, propertyId) => {
    const params: ScopeOfWorkV2FormParams = {
      loanId: loanId.toString(),
      propertyId: propertyId.toString()
    }
    const response = await scopeOfWorkInternalStore.getScopeOfWorkWithParameters(params);
    if (!isNil(response) && !isNil(response['status']) && response['status'] != 'Draft') {
      return true;
    }
    return false;
  }

  const handleOpenModal = () => {
    const count = store.revisions.filter(rev => rev.sendForRevision).length;
    if (count > 0) {
      setOpenTextBox(true);
    } else {
      store.showWarningNotification(PLEASE_SELECT_AN_OPTION_TO_CONTINUE);
    }
  }

  const TextBoxDialog = () => {
    const [valueSelected, setValueSelected] = useState("Select")
    const handleChange = (event) => {
      setValueSelected(event.target.value)
    }

    const handleClose = (_, reason) => {
      if (reason === 'backdropClick') {
        return;
      }
      setOpenTextBox(false);
    }

    return (
      <Dialog
        open={openTextBox}
        onClose={handleClose}
        fullWidth
      >
        <DialogTitle>
          {"Who would you like to send this file to?"}
        </DialogTitle>
        <DialogContent style={{ overflow: 'hidden' }}>
          <Grid container xs={12} direction="column" spacing={2}>
            <Select
              value={valueSelected}
              onChange={handleChange}
              variant="outlined"
              fullWidth
            >
              <MenuItem value={"Select"}>Select</MenuItem>
              <MenuItem value={"TAMARISK"}>Tamarisk</MenuItem>
              <MenuItem value={"TPO"}>Lender</MenuItem>
              <MenuItem value={"UNDERWRITER"}>Underwriter</MenuItem>
            </Select>
            {showErrorMessage && (<Typography variant="caption" style={{ color: 'red' }}> ** Please select one of the options to continue.</Typography>)}
            <Grid container spacing={2} xs={12} justifyContent="flex-end" style={{ marginTop: 20 }} direction="row">
              <Button
                variant="outlined"
                color="primary"
                onClick={() => setOpenTextBox(false)}
                style={{ marginBottom: 20, marginRight: 5 }}
              >
                CANCEL
              </Button>
              <Button
                variant="contained"
                color="primary"
                style={{ marginBottom: 20 }}
                onClick={() => handleConfirmationRequestRevisionAction(valueSelected)}
              >
                SUBMIT
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    )
  }

  return (
    <Box>
      <TextBoxDialog />
      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={handleOpenModal}
      >
        REQUEST REVISION
      </Button>
    </Box>
  )
})