import { lighten } from '@material-ui/core/styles';
import { ThemeOverrides } from '@roc/feature-types';

export const commonPalette = {
  common: {
    black: '#000000DE',
    white: '#fff',
  },
  background: {
    paper: '#fff',
    default: '#f7f7f7',
  },
  success: {
    light: '#61DDBC',
    main: '#46CEAC',
    dark: '#35BA9B',
  },
  error: {
    light: '#F76C82',
    main: '#EB5463',
    dark: '#D94452',
  },
  warning: {
    light: '#FB836F',
    main: '#FA6C51',
    dark: '#E8553E',
  },
  info: {
    light: '#73B1F4',
    main: '#5E9CEA',
    dark: '#4B89DA',
  },
  text: {
    primary: '#313131',
  },
  primary: {
    main: '#21395e',
  },
  secondary: {
    main: '#00B2FF',
  },
};

export const commonMuiThemeProps = {
  MuiButton: {
    disableElevation: true,
  },
  MuiButtonBase: {
    disableRipple: true,
  },
};

export const commonMuiThemeOverrides = {
  MuiTabs: {
    indicator: {
      '& span': {
        backgroundColor: '#FFF',
      },
    },
  },
  MuiButton: {
    root: {
      borderRadius: 8,
    },
  },
  MuiIconButton: {
    root: {
      borderRadius: 8,
    },
  },
  MuiListItemIcon: {
    root: {
      minWidth: 30,
    },
  },
  MuiSelect: {
    select: {
      '&:focus': {
        backgroundColor: 'none',
      },
    },
  },
  MuiPaper: {
    rounded: {
      borderRadius: 8,
    },
  },
  MuiOutlinedInput: {
    root: {
      borderRadius: 8,
    },
  },
};

export const getMuiThemeOverrides = (
  palette,
  themeOverrides: ThemeOverrides
) => {
  return {
    MuiTabs: {
      indicator: {
        '& span': {
          backgroundColor: '#FFF',
        },
      },
    },
    MuiButton: {
      root: {
        borderRadius: themeOverrides?.button?.borderRadius ?? 8,
        '&.x-menu-active': {
          '&::after': {
            background: themeOverrides?.appBar?.color ? lighten(themeOverrides?.appBar?.color, .25) : 'none',
          },
        },
      },
    },
    MuiIconButton: {
      root: {
        borderRadius: 8,
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 30,
      },
    },
    MuiSelect: {
      select: {
        '&:focus': {
          backgroundColor: 'none',
        },
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: 8,
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 8,
      },
    },
    MuiAppBar: {
      root: {
        backgroundColor: themeOverrides?.appBar?.backgroundColor ?? '#FFF',
        '& *': {
          color: themeOverrides?.appBar?.color ?? '#333',
        },
        '& img': {
          backgroundColor:
            themeOverrides?.appBar?.logoBackgroundColor ?? '#FFF',
        },
      },
    },
    MuiMenuItem: {
      root: {
        '&:hover': {
          backgroundColor: lighten(palette?.primary?.main ?? '#333', 0.7),
        },
      },
    },
  };
};
