import { ApiResponse } from "@roc/feature-app-core";
import { action, computed, flow, makeObservable, observable } from "mobx";
import { ConversationAttribute, ResetUnreadCountRequest } from "../types/communicationTypes";
import { CommunicationStore } from "./communicationStore";
import { isNil } from "@roc/feature-utils";

export class TechSupportCommunicationStore extends CommunicationStore {
  responseValues: any[];
  openModal: boolean = false;
  supportConversationSid: string;

  constructor(globalStore, userStore, communicationService, isInternal = false) {
    super(globalStore, userStore, communicationService, isInternal);

    makeObservable(this, {
      fetchUnreadMessagesCount: flow,
      resetUnreadCount: flow,
      createTechSupportChat: flow,
      updateConversationStarredStatus: flow,
      getParticipantsAllowedByLoanId: flow,
      getParticipantsAllowedByChatRoomId: flow,
      setConversationMessageDefaultTag: flow,
      responseValues: observable,
      openModal: observable,
      setModalOpen: action,
      totalTechSupportUnreadMessagesCount: computed,
      supportConversationSid: observable
    });
  }

  setModalOpen(value: boolean) {
    this.openModal = value;
  }

  setSupportConversationSid(value: string) {
    this.supportConversationSid = value;
    this.currentConversationSid = value;
  }

  get totalTechSupportUnreadMessagesCount() {
    return Object.values(this.unreadTechSupportMessagesCount).reduce((p, n) => p + n, 0)
  }

  *fetchUnreadMessagesCount(userId: number, companyId?: number) {
    this.unreadTechSupportMessagesCount = {};
    const response = yield this.communicationService.getUnreadCountByUserId(companyId);
    const data = response.data.data;
    data.forEach(item => this.unreadTechSupportMessagesCount[item.conversationSid] = item.unreadCount)
  }

  *resetUnreadCount(companyId: number) {
    try {
      const request: ResetUnreadCountRequest = {
        chatRoomId: this.currentChatRoomId,
        chatRoomType: this.currentChatRoomType,
        userId: this.userId,
        conversationSid: this.currentConversationSid,
        companyId,
      }
      this.communicationService.resetUnreadCount(request);
    } catch (e) {
      // do nothing
    }
  }

  *updateConversationStarredStatus(isStarred: boolean, chatRoomId: number, conversationSid: string, callBack?: () => void) {
    throw Error('Not Implemented!');
  }

  *getParticipantsAllowedByChatRoomId(chatroomId: number, disableGlobalLoading = false) {
    try {
      const response: ApiResponse = yield this.communicationService.getParticipantsAllowedByChatroomId(chatroomId, disableGlobalLoading);
      const { data } = response;
      return data.data;
    } catch (error) {
      console.error("Error retrieving Participants")
      return {};
    }
  }

  *getParticipantsAllowedByLoanId(id, disableGlobalLoading = false) {
    throw new Error("getParticipantsAllowedByLoanId not implemented for Support Chatrooms");
  }

  *setConversationMessageDefaultTag(conversationAttribute: ConversationAttribute, conversationInfo) {
    if (conversationAttribute?.loanId) {
      const response: ApiResponse = yield this.communicationService.getConversationMessageDefaultTag(conversationAttribute?.loanId, [conversationInfo]);
      if (response?.data?.data) {
        const result = response?.data?.data.result;
        [conversationInfo].forEach(c => {
          if (result[c.conversationSid]) {
            this.conversationMessageDefaultTag[c.conversationSid] = result[c.conversationSid];
          }
        });
      }
    }
  }

  *createTechSupportChat(companyId) {
    try {
      const response = yield this.communicationService.createTechSupportChat(companyId);
      const resolvedResponse = yield response;
      this.responseValues = resolvedResponse?.data?.data;
      if (this.responseValues) {
        this.setSupportConversationSid(this.responseValues[0]?.conversationSid)
        this.setModalOpen(true)
      }
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error loading tech support conversation',
      });
      console.error('Error loading tech support conversation: ', e);
    }
  }

}