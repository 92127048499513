import { makeStyles, Card, Typography, Grid } from '@material-ui/core';
import { formatCurrency, formatPercentage } from '@roc/ui/utils';
import { InfoOutlined } from '@material-ui/icons';
import { CustomIconTooltip } from '@roc/ui';
import { isNil } from '@roc/feature-utils';
import React, { useEffect, useRef, useState } from "react";
import { observer } from 'mobx-react';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#F6EEFF',
    padding: theme.spacing(1, 2, 2),
    textAlign: 'center',
    height: '100%',
    borderRadius: 0,
  },
  leftCard: {
    borderBottomLeftRadius: '10px',
  },
  rightCard: {
    borderBottomRightRadius: '10px',
  },
  title: {
    fontWeight: 'bold',
    fontSize: '16px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  amount: {
    color: '#9B59B6',
    fontSize: '32px',
    fontWeight: 'bold',
    margin: theme.spacing(0),
    transition: "color 0.4s ease, transform 0.4s ease",
  },
  percentageText: {
    fontSize: '12px',
  },
  percentageValue: {
    color: '#9B59B6',
    fontWeight: 'bold',
    fontSize: '12px',
  },
  animate: {
    color: "#5D2C6F",
    transform: "scale(1.2)",
  },
}));

interface EarningsCardProps {
  title: string;
  earnings: number;
  percentage?: number
  percentageDescription?: string;
  position?: 'left' | 'center' | 'right';
  tooltip?: string;
}


const AnimatedTypography = ({ value }) => {
  const classes = useStyles();
  const typographyRef = useRef(null);
  const [isFirstRender, setIsFirstRender] = useState(true);

  const handleAnimation = () => {
    const element = typographyRef.current;
    if (element) {
      element.classList.add(classes.animate);
      setTimeout(() => {
        element.classList.remove(classes.animate);
      }, 400);
    }
  };

  useEffect(() => {
    if (!isFirstRender && value) {
      handleAnimation();
    } else {
      setIsFirstRender(false);
    }
  }, [value]);

  return (
    <Typography
      ref={typographyRef}
      className={classes.amount}
    >
      {value}
    </Typography>
  );
};


export const EarningsCard = ({
  title,
  earnings,
  percentage,
  percentageDescription,
  position = 'center',
  tooltip,
}: EarningsCardProps) => {
  const classes = useStyles();

  let positionClass = '';
  switch (position) {
    case 'left':
      positionClass = classes.leftCard;
      break;
    case 'right':
      positionClass = classes.rightCard;
      break;
  }

  return (
    <Card className={`${classes.root} ${positionClass}`} elevation={0}>
      <Typography variant="subtitle1" className={classes.title}>
        {title}
        {tooltip && (
          <CustomIconTooltip
            style={{
              marginTop: '3px',
              marginLeft: '4px'
            }}
            placement="top"
            title={tooltip}
            icon={InfoOutlined}
          />
        )}
      </Typography>
      <AnimatedTypography value={!isNil(earnings) ? formatCurrency(earnings) : '-'} />
      {!isNil(percentage) && (
        <Grid container alignItems="center" justifyContent="center" spacing={1}>
          <Grid item>
            <Typography className={classes.percentageValue} >
              {formatPercentage(percentage)}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" className={classes.percentageText}>
              {percentageDescription}
            </Typography>
          </Grid>
        </Grid>
      )}
    </Card>
  );
};

export const EarningsCardSymbol = ({
  text,
}: {
  text: string
}) => {
  const classes = useStyles();

  return (
    <Card className={`${classes.root}`} elevation={0}>
      <Typography variant="subtitle1" className={classes.title}>
        &nbsp;
      </Typography>
      <Typography
        className={classes.amount}
        style={{
          fontSize: '40px',
          lineHeight: '40px',
        }}
      >
        {text}
      </Typography>
    </Card>
  );
};
