import { Service } from '@roc/feature-app-core';

const url = {
  SELECT_RATE_LOCK_EXTENSION_LENGHT:
    '/api/v1/document/formDocument/setBorrowerRateLockExtension',
  GET_BORROWER_RATE_LOCK_EXTENSIONS:
    '/api/v1/loan/borrowerRateLock/getBorrowerRateLockExtensions',
  GET_BORROWER_RATE_LOCK_EXTENSIONS_NPORTAL:
    '/api/v1/loan/borrowerRateLock/getBorrowerRateLockExtensionsNportal',
};

export class RateLockExtensionService extends Service {

  setRateLockAgreementSfr(loanId: number, firstBorrowerExtensionLength: number, secondBorrowerExtensionLength: number) {
    return this.get(
      url.SELECT_RATE_LOCK_EXTENSION_LENGHT +
      '?loanId=' + loanId + '&firstBorrowerExtensionLength=' + firstBorrowerExtensionLength + '&secondBorrowerExtensionLength=' + secondBorrowerExtensionLength,
      {},
    );
  }

  getRateLockExtensions(extensions: [], lenderId: number, loanId: number) {
    return this.get(url.GET_BORROWER_RATE_LOCK_EXTENSIONS + '?extensions=' + extensions + '&lenderId=' + lenderId + '&loanId=' + loanId);
  }

  getRateLockExtensionsNportal(extensions: [], lenderId: number, investor: string) {
    return this.get(url.GET_BORROWER_RATE_LOCK_EXTENSIONS_NPORTAL + '?extensions=' + extensions + '&lenderId=' + lenderId + '&investor=' + investor);
  }
}